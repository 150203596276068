import { MeasurementUnits } from 'types/orgs'

export const trimDecimalPlaces = (v, places) => {
  if (isNaN(v)) {
    console.log('isNaN(v)', v)
    return v
  } else {
    return parseFloat(v.toFixed(places).toString())
  }
}

export const inchesToMeters = (v) => {
  //do not format it still typing...
  if (v && v.substr && v.substr(-1) === '.') {
    return v
  }

  var x = v / 39.3701
  if (isNaN(x)) {
    return v
  } else {
    return x
  }
}

export const metersToInches = (v) => {
  if (v && v.substr && v.substr(-1) === '.') {
    return v
  }

  var x = v * 39.3701
  if (isNaN(x)) {
    return v
  } else {
    return x
  }
}

// Warning: trimming decimal places is EXTREMELY delicate. So easy to screw it up.
// Known issue: Trying to enter more than 4 decimal places gives strange behavior but at least it's not unsfae.
export const toMeters = (value: number, measurements: MeasurementUnits) => {
  return measurements === 'imperial' ? trimDecimalPlaces(inchesToMeters(value), 4) : value
}

export const fromMeters = (value: number, measurements: MeasurementUnits) => {
  return measurements === 'imperial' ? trimDecimalPlaces(metersToInches(value), 2) : value
}

export const formatNumber = (n: number) => {
  return Math.round(n * 10) / 10
}

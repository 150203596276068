import React from 'react'
import { NORMAL_CONTACT } from 'types/contacts'
import { ContactButtons } from './ContactButtons'

export const ContactsActions = ({ record, translate }) => (
  <div>
    {record.contacts_data && record.contacts_data.length > 0 && (
      <div style={{ margin: 0, padding: 0 }}>
        {record.contacts_data
          .filter((contact) => contact.type === NORMAL_CONTACT)
          .map((contact) => {
            return (
              <div style={{ clear: 'both', marginRight: 5 }} key={contact.id}>
                <ContactButtons contact={contact} translate={translate} />
              </div>
            )
          })}
      </div>
    )}
  </div>
)

import React from 'react'
import type { ComponentTypes } from 'types/selectComponent'
import type { FilterComponentNode, FilterComponentRendererType, FilterGenericPropsType } from '../type'
import PhaseTypeFilter, { FilterOptionsType } from './PhaseTypeFilter'

export const PHASE_FILTER_KEY = 'phase_type'

const maybeCreatePhaseTypeFilterNode = ({
  componentType,
  RendererComponent,
}: {
  componentType?: ComponentTypes
  RendererComponent: FilterComponentRendererType<FilterOptionsType>
}): FilterComponentNode | undefined => {
  if (componentType !== 'inverter' && componentType !== 'other') {
    return
  }

  const FilterComponent = (props: FilterGenericPropsType) => (
    <PhaseTypeFilter
      componentType={componentType}
      filterKey={PHASE_FILTER_KEY}
      RendererComponent={RendererComponent}
      {...props}
    />
  )

  return {
    key: PHASE_FILTER_KEY,
    component: FilterComponent,
  }
}

export default maybeCreatePhaseTypeFilterNode

import React from 'react'

export const STCIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#4d4d4d" {...props}>
      <path d="M20.5 7.5H2.5V8.5H20.5V7.5Z" />
      <path d="M15.5 19.5V11.5V3.5H14.5V11.5V19.5H15.5Z" />
      <path d="M9.5 19.5V3.5H8.5V19.5H9.5Z" />
      <path d="M20.5 11.5H2.5V12.5H20.5V11.5Z" />
      <path d="M20.5 15.5H2.5V16.5H20.5V15.5Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 3.5C3.39543 3.5 2.5 4.39543 2.5 5.5V18.5C2.5 19.6046 3.39543 20.5 4.5 20.5H19.5C20.6046 20.5 21.5 19.6046 21.5 18.5V5.5C21.5 4.39543 20.6046 3.5 19.5 3.5H4.5ZM20.5 6.5C20.5 5.39543 19.6046 4.5 18.5 4.5H5.5C4.39543 4.5 3.5 5.39543 3.5 6.5V17.5C3.5 18.6046 4.39543 19.5 5.5 19.5H18.5C19.6046 19.5 20.5 18.6046 20.5 17.5V6.5Z"
      />
    </svg>
  )
}

export const ConsumptionIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#4d4d4d" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9167 19C15.7827 19 18.9167 15.866 18.9167 12C18.9167 8.13401 15.7827 5 11.9167 5V12L7 16.9827C8.26405 18.2301 10.0005 19 11.9167 19Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
      />
    </svg>
  )
}

export const BatteryIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#4d4d4d" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 7C2.89543 7 2 7.89543 2 9V16C2 17.1046 2.89543 18 4 18H18C19.1046 18 20 16.1046 20 15V9C20 7.89543 19.1046 7 18 7H4ZM19 9C19 7.89543 18.1046 8 17 8H5C3.89543 8 3 8.89543 3 10V15C3 16.1046 3.89543 17 5 17H17C18.1046 17 19 16.1046 19 15V9Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 9V16H21.5C22.0523 16 22.5 15.5523 22.5 15V10C22.5 9.44772 22.0523 9 21.5 9H19ZM21.5 11C21.5 10.4477 21.0523 10 20.5 10H20V15H20.5C21.0523 15 21.5 14.5523 21.5 14V11Z"
      />
      <path d="M5 15.5V9.5C5 9.22386 5.22386 9 5.5 9C5.77614 9 6 9.22386 6 9.5V15.5C6 15.7761 5.77614 16 5.5 16C5.22386 16 5 15.7761 5 15.5Z" />
      <path d="M8 15.5V9.5C8 9.22386 8.22386 9 8.5 9C8.77614 9 9 9.22386 9 9.5V15.5C9 15.7761 8.77614 16 8.5 16C8.22386 16 8 15.7761 8 15.5Z" />
      <path d="M11 15.5V9.5C11 9.22386 11.2239 9 11.5 9C11.7761 9 12 9.22386 12 9.5V15.5C12 15.7761 11.7761 16 11.5 16C11.2239 16 11 15.7761 11 15.5Z" />
      <path d="M14 15.5V9.5C14 9.22386 14.2239 9 14.5 9C14.7761 9 15 9.22386 15 9.5V15.5C15 15.7761 14.7761 16 14.5 16C14.2239 16 14 15.7761 14 15.5Z" />
    </svg>
  )
}

export const EnergyIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="#4d4d4d" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M8 14.25L13.3333 3V10.5H16L10.6667 21V14.25H8Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 4C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H9V19.0065H6.49346C5.66504 19.0065 4.99346 18.335 4.99346 17.5065V6.49347C4.99346 5.66504 5.66504 4.99346 6.49346 4.99346H11L11 4H6ZM13 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H15V4.99346H17.5065C18.335 4.99346 19.0065 5.66504 19.0065 6.49346V17.5065C19.0065 18.335 18.335 19.0065 17.5065 19.0065H13L13 20Z"
      />
    </svg>
  )
}

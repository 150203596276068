var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import createStandardIcon from './createStandardIcon';
var MoneyIconSvg = function (props) {
    var fill = props.fill, height = props.height, width = props.width;
    return (_jsxs("svg", __assign({ viewBox: "0 0 ".concat(width, " ").concat(height), fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("g", __assign({ id: "monetization_on", clipPath: "url(#clip0_2314_5111)" }, { children: _jsx("path", { id: "Vector", d: "M15.9998 2.66675C8.63984 2.66675 2.6665 8.64008 2.6665 16.0001C2.6665 23.3601 8.63984 29.3334 15.9998 29.3334C23.3598 29.3334 29.3332 23.3601 29.3332 16.0001C29.3332 8.64008 23.3598 2.66675 15.9998 2.66675ZM15.9998 26.6667C10.1198 26.6667 5.33317 21.8801 5.33317 16.0001C5.33317 10.1201 10.1198 5.33341 15.9998 5.33341C21.8798 5.33341 26.6665 10.1201 26.6665 16.0001C26.6665 21.8801 21.8798 26.6667 15.9998 26.6667ZM16.4132 14.8534C14.0532 14.2534 13.2932 13.6001 13.2932 12.6267C13.2932 11.5067 14.3465 10.7201 16.0932 10.7201C17.9332 10.7201 18.6265 11.6001 18.6798 12.9067H20.9598C20.8932 11.1201 19.7998 9.48008 17.6398 8.94675V6.66675H14.5332V8.92008C12.5198 9.34675 10.9065 10.6534 10.9065 12.6667C10.9065 15.0534 12.8932 16.2534 15.7865 16.9467C18.3865 17.5601 18.9065 18.4801 18.9065 19.4401C18.9065 20.1467 18.3865 21.2934 16.1065 21.2934C13.9732 21.2934 13.1332 20.3334 13.0132 19.1067H10.7198C10.8532 21.3734 12.5332 22.6534 14.5332 23.0667V25.3334H17.6532V23.1067C19.6798 22.7201 21.2798 21.5601 21.2932 19.4134C21.2798 16.4801 18.7598 15.4667 16.4132 14.8534Z", fill: "#757575" }) })), _jsx("defs", { children: _jsx("clipPath", __assign({ id: "clip0_2314_5111" }, { children: _jsx("rect", { width: width, height: height, fill: fill }) })) })] })));
};
export var MoneyIcon = createStandardIcon(MoneyIconSvg);

import { orgSelectors } from 'ducks/orgs'
import { styled } from 'opensolar-ui'
import { useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import { ProjectType } from 'types/projects'
import ProjectSelectDropdown from './ProjectSelectDropdown'

const StageSkeletonWrapper = styled('div', { name: 'StageSkeletonWrapper' })({
  width: '100%',
})
interface PropTypes {
  project: ProjectType
}

const StageSelectDropdown: React.FC<PropTypes> = ({ project }) => {
  const orgWorkflows = useSelector(orgSelectors.getWorkflows)
  const workflow = useMemo(() => orgWorkflows?.find((x) => x.id === project?.workflow?.workflow_id), [
    orgWorkflows,
    project?.workflow?.workflow_id,
  ])
  const stageChoices = useMemo(
    () => workflow?.workflow_stages?.filter((x) => !x.is_archived || x.id === project?.workflow?.active_stage_id) || [],
    [workflow, project?.workflow?.active_stage_id]
  )
  if (!workflow)
    return (
      <StageSkeletonWrapper style={{ height: 20 }}>
        <Skeleton height={20} />
      </StageSkeletonWrapper>
    )
  return (
    <ProjectSelectDropdown
      choices={stageChoices}
      source="workflow.active_stage_id"
      submitSource="active_stage_id"
      project={project}
    />
  )
}

export default StageSelectDropdown

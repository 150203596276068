import type { SelectorOptionComponentType } from 'pages/ordering/order/table/quantity/Selector'
import React from 'react'
import Selector from '../Selector'

const renderBulkSelectorValue = (value): React.ReactNode => {
  return <span>{value === 1 ? 'Individual' : `Bulk (${value} units)`}</span>
}

const BulkSelector = ({
  isLoading,
  quantityPerItem,
  optionComponents,
}: {
  isLoading: boolean
  quantityPerItem?: number
  optionComponents: SelectorOptionComponentType[]
}) => {
  return (
    <Selector
      isLoading={isLoading}
      value={quantityPerItem}
      optionComponents={optionComponents}
      renderValue={renderBulkSelectorValue}
    />
  )
}

export default BulkSelector

import appStorage from 'storage/appStorage'
import { MapDataTypes } from 'types/map'
import { JSONstringifySortedKeys } from 'util/misc'

export const setImageryTypeDefault = (_imageryType: MapDataTypes | null) => {
  if (_imageryType === null) {
    appStorage.clear('imageryTypeDefault')
  } else {
    appStorage.setString('imageryTypeDefault', JSONstringifySortedKeys(_imageryType))
  }
}

import { useCallback } from 'react'
import type { FilterComponentRendererType, FilterGenericPropsType, FilterKeysType, OptionGenericType } from '../type'

export type FilterOptionsType = OptionGenericType<number | ''>[]

type FilterProps = FilterGenericPropsType & {
  RendererComponent: FilterComponentRendererType<FilterOptionsType>
  filterKey: FilterKeysType
}

export const MPPT_QUANTITY_OPTIONS: FilterOptionsType = [
  { id: 'default', title: 'All', value: '' },
  { id: '1', title: '1', value: 1 },
  { id: '2', title: '2', value: 2 },
  { id: '3', title: '3', value: 3 },
  { id: '4', title: '4', value: 4 },
]

const MpptQuantityFilter = ({
  filterKey,
  modifiableFilterValues,
  persistentFilterValues,
  allFilterValues,
  setModifiableFilters,
  RendererComponent,
}: FilterProps) => {
  const onSelect = useCallback(
    (value) => {
      setModifiableFilters({ ...modifiableFilterValues, [filterKey]: value })
    },
    [modifiableFilterValues]
  )

  return (
    <RendererComponent
      id={filterKey}
      defaultValue={''}
      label={'MPPT Quantity'}
      options={MPPT_QUANTITY_OPTIONS}
      disabled={false}
      onSelect={onSelect}
      value={allFilterValues[filterKey]}
    />
  )
}

export default MpptQuantityFilter

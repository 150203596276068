import { Grid } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import type { HardwareSupplierPresetConfig } from 'pages/ordering/configs'
import OrderLineItem from 'pages/ordering/OrderLineItem'
import type { DistributorDataType, StockLevelType } from 'pages/ordering/type'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { formatCurrencyWithSymbol } from 'util/misc'
import { PriceFieldLayout } from './PriceField'
import ScheduledStocks from './ScheduledStocks'
import StockMessage from './StockMessage'

const SupplierPrice = memo(
  ({
    currencySymbol,
    distributor,
    lineItem,
    supplierConfig,
  }: {
    currencySymbol: string
    distributor: DistributorDataType
    lineItem: OrderLineItem
    supplierConfig: HardwareSupplierPresetConfig | undefined
  }) => {
    const supplier = useSelector(orgSelectors.getSelectedHardwareSupplier)
    const stockLevelsPlanned: StockLevelType[] =
      distributor.stock_levels?.filter(
        (stockLevel: StockLevelType) => !stockLevel.in_stock && stockLevel.planned_date
      ) || []

    return (
      <PriceFieldLayout
        label={<div>{supplierConfig?.name || ''}</div>}
        value={
          <Grid container item>
            {distributor.price !== undefined && (
              <Grid item xs={12}>
                {formatCurrencyWithSymbol(lineItem.getBestDiscountOffer()?.afterDiscount, currencySymbol)}
              </Grid>
            )}
            <Grid item xs={12}>
              <StockMessage distributor={distributor} showPrice={false} currencySymbol={currencySymbol} />
            </Grid>
            {supplier !== HardwareSupplierEnum.SolarOutlet && stockLevelsPlanned.length > 0 && (
              <Grid item xs={12}>
                <ScheduledStocks stockLevels={stockLevelsPlanned} />
              </Grid>
            )}
          </Grid>
        }
      />
    )
  }
)

export default SupplierPrice

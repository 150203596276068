import { Breadcrumbs, makeStyles, Typography } from '@material-ui/core'
import inflection from 'inflection'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslate } from 'react-admin'
import { Link } from 'react-router-dom'

export const useStyles = makeStyles((theme) => {
  return {
    selected: {
      fontWeight: 'bold',
      color: 'black',
    },
  }
})

export const ControlBreadcrumbLink = {
  link: '/control',
  title: 'Control',
}
export const ProjectsBreadcrumbLink = {
  link: '/projects',
  title: 'Projects',
}

export const ControlBreadCrumbs = ({ style, currentPage = '', resource, detail }) => {
  const breadcrumbs = [
    ControlBreadcrumbLink,
    {
      title:
        resource && !currentPage
          ? `${inflection.humanize(detail)} ${inflection.humanize(inflection.singularize(resource))}`
          : currentPage,
    },
  ]

  return <ListBreadCrumbs style={style} links={breadcrumbs} />
}

ControlBreadCrumbs.propTypes = {
  style: PropTypes.object,
  currentPage: PropTypes.string,
  resource: PropTypes.string,
  detail: PropTypes.string,
}

export const InventoryBreadCrumbs = ({ style, currentPage = '', resource, detail, redirect }) => {
  const breadcrumbs = [
    {
      link: redirect,
      title: `${inflection.humanize(inflection.pluralize(resource))}`,
    },
    {
      title:
        resource && !currentPage
          ? `${inflection.humanize(detail)} ${inflection.humanize(inflection.singularize(resource))}`
          : currentPage,
    },
  ]

  return <ListBreadCrumbs style={style} links={breadcrumbs} />
}

InventoryBreadCrumbs.propTypes = {
  style: PropTypes.object,
  currentPage: PropTypes.string,
  resource: PropTypes.string,
  detail: PropTypes.string,
  redirect: PropTypes.string,
}

export const ProjectSectionBreadCrumbs = ({ currentPage = '', match }) => {
  const projectId = match.params && match.params.id
  const breadcrumbs = [
    ProjectsBreadcrumbLink,
    {
      link: `/projects/${projectId}`,
      title: 'Selected Project',
    },
    {
      title: currentPage,
    },
  ]

  return <ListBreadCrumbs links={breadcrumbs} />
}

ProjectSectionBreadCrumbs.propTypes = {
  match: PropTypes.object.isRequired,
}

export const ListBreadCrumbs = ({ style, links }) => {
  const translate = useTranslate()
  const classes = useStyles()
  return (
    <div style={style}>
      <Breadcrumbs aria-label="breadcrumb">
        {links.map((item, index) =>
          !item?.title ? null : !item.link || index === links.length - 1 ? (
            <Typography key={index} className={classes.selected}>
              {translate(item.title)}
            </Typography>
          ) : (
            <Link key={index} color="inherit" to={item.link}>
              {translate(item.title)}
            </Link>
          )
        )}
      </Breadcrumbs>
    </div>
  )
}
ListBreadCrumbs.propTypes = {
  style: PropTypes.object,
  links: PropTypes.arrayOf(PropTypes.object),
}

export const DefaultBreadCrumbs = ({ hasList, resource, detail, options = {} }) => {
  const listBreadCrumb =
    (options.list && options.list.breadCrumb) || `${inflection.humanize(inflection.pluralize(resource))}`

  const detailBreadCrumb =
    (options[detail] && options[detail].breadCrumb) ||
    `${inflection.humanize(detail)} ${inflection.humanize(inflection.singularize(resource))}`

  const listLink = hasList
    ? {
        title: listBreadCrumb,
        link: detail === 'list' ? null : `/${resource}`,
      }
    : null

  const itemLink = detail !== 'list' ? { title: detailBreadCrumb } : null

  const breadcrumbs = [ControlBreadcrumbLink, listLink, itemLink]

  return <ListBreadCrumbs links={breadcrumbs} />
}

DefaultBreadCrumbs.propTypes = {
  detail: PropTypes.string,
}

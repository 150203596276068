import { Dialog, useMediaQuery } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import { useNotify } from 'ra-core'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import restClient from 'restClient'
import { ProjectType } from 'types/projects'
import { Theme } from 'types/themes'
import PhoenixDownPaymentWarning from '../PhoenixDownPaymentWarning'
import PhoenixContactInfoForm from './PhoenixContactInfoForm'

const restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  isOpen: boolean
  onClose: () => void
  project: ProjectType
  systemUUID: string
  paymentOptionId: number
  existingURL: string | undefined
  pricePayable: number
  orgId: number
}

const PhoenixApplicationDialog: React.FC<PropTypes> = (props) => {
  const [showForm, setShowForm] = useState<boolean>(true)
  const [url, setUrl] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const notify = useNotify()

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))

  useEffect(() => {
    if (url) {
      setShowForm(false)
      logAmplitudeEvent('integrated_finance_application_opened', {
        integration: 'phoenix',
        project_id: props.project.id,
      })
      openAppURL()
    }
  }, [url])

  const confirmContact = (formVals: Object) => {
    setIsLoading(true)
    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${props.orgId}/projects/${props.project.id}/systems/${props.systemUUID}/${props.paymentOptionId}/phoenix/start_app/`,
      data: {
        form_vals: formVals,
      },
    })
      .then((res) => {
        if (res.data.success && res.data.url) {
          setUrl(res.data.url)
          logAmplitudeEvent('phoenix_application_validation_submitted', {
            project_id: props.project.id,
            system_uuid: props.systemUUID,
            payment_option_id: props.paymentOptionId,
          })
        }
      })
      .catch((err) => {
        if (err?.body?.message) {
          notify(err.body.message, 'warning')
          logAmplitudeEvent('phoenix_application_validation_form_error', {
            project_id: props.project.id,
            system_uuid: props.systemUUID,
            payment_option_id: props.paymentOptionId,
            message: err.body.message,
          })
        } else {
          notify('Something went wrong and we were unable to bring you to the Phoenix application', 'warning')
          logAmplitudeEvent('phoenix_application_validation_unhandled_error', {
            project_id: props.project.id,
            system_uuid: props.systemUUID,
            payment_option_id: props.paymentOptionId,
          })
        }
      })
      .finally(() => setIsLoading(false))
  }

  const getInitialValues = () => {
    let contact = props.project?.contacts?.length ? props.project?.contacts[0] : undefined
    return {
      cust_first_name: contact?.first_name,
      cust_last_name: contact?.family_name,
      cust_email: contact?.email,
      cust_telephone: contact?.phone,
      cust_address_1: props.project?.address,
      cust_city: props.project?.locality,
      cust_county: props.project?.county,
      cust_postcode: props.project.zip,
    }
  }

  const openAppURL = () => {
    window.open(url, '_blank')
  }

  const renderRedirectButton = () => {
    return (
      <div
        style={{
          height: '300px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img height="75px" src={`${window.PUBLIC_URL}/images/phoenix-logo.png`} />
        <p style={{ margin: '60px 20px 20px 20px', textAlign: 'center' }}>
          Your application is ready for you on Phoenix's website. Click the button below to get started! Not ready to
          apply yet? No problem, Phoenix has sent you an email with a link to the application for you to submit when
          you're ready.
        </p>
        <ProUXButton type="primary" onClick={openAppURL} label="Go to Phoenix's Application" />
      </div>
    )
  }

  return (
    <Dialog open={props.isOpen} onClose={props.onClose} maxWidth="xl" fullScreen={isMobile} fullWidth={isMobile}>
      <div style={{ margin: '10px' }}>
        <PhoenixDownPaymentWarning pricePayable={props.pricePayable} />
      </div>
      <Form
        onSubmit={confirmContact}
        initialValues={getInitialValues()}
        render={(formProps) =>
          showForm ? (
            <PhoenixContactInfoForm
              {...formProps}
              confirmContact={confirmContact}
              isLoading={isLoading}
              existingURL={props.existingURL}
              onClose={props.onClose}
              disabled={!!props.pricePayable && props.pricePayable > 18000}
            />
          ) : (
            renderRedirectButton()
          )
        }
      />
    </Dialog>
  )
}
export default PhoenixApplicationDialog

import { TransactionConfigData, TransactionTypeMap } from './types'

export const TRANSACTIONS_METHOD_MAP: TransactionTypeMap = {
  credit_card_stripe: 'Credit Card Online (Stripe)',
  offline: 'Offline',
  none: 'None',
  sunlight_loan_application: 'Sunlight Application',
  sunlight_loan_prequal: 'Sunlight Pre-Qualification',
  plenti_loan_application: 'Plenti Loan Application',
  plenti_bnpl_application: 'Plenti BNPL Application',
  energy_ease_application: 'Smart Ease Application',
  mosaic_application: 'Mosaic Application',
  mosaic_loan_agreement: 'Mosaic Loan Agreement',
  commonbond_application: 'CommonBond Application',
  commonbond_loan_agreement: 'CommonBond Loan Agreement',
  docusign: 'DocuSign',
  brighte_application: 'Brighte Application',
  dividend_application: 'Dividend Loan Application',
  dividend_loan_agreement: 'Dividend Loan Agreement',
  phoenix_application: 'Phoenix Application',
  loanpal_application: 'Goodleap Loan Application',
  sungage_application: 'Sungage Loan Application',
  expired_docusign: 'Expired Docusign',
}

export const TRANSACTIONS_TITLE_MAP: TransactionTypeMap = {
  ...TRANSACTIONS_METHOD_MAP,
  credit_card_stripe: 'Payment Request',
  offline: 'Payment Request',
  none: 'Payment Request',
}

export const TRANSACTION_STATUS_MAP = {
  '000': 'Awaiting Response',
  '001': 'Awaiting Response',
  '010': 'Invalid Status',
  '011': 'Invalid Status',
  '100': 'Complete',
  '101': 'Awaiting Payment',
  '110': 'Not Required',
  '111': 'Funds Received',
  expired_docusign: 'Contract Reset',
}

export const LOCKED_TRANSACTION_TYPES: string[] = [
  'mosaic_application',
  'mosaic_loan_agreement',
  'loanpal_application',
  'loanpal_loan_agreement',
  'sungage_application',
  'brighte_application',
  'plenti_bnpl_application',
  'commonbond_application',
  'commonbond_loan_agreement',
  'phoenix_application',
]

export const TRANSACTIONS_CONFIG: TransactionConfigData = {
  method: {
    id: 'method',
    label: 'Method',
    sourceId: 'transactionTypeToValueMap',
    transactionTypeToValueMap: TRANSACTIONS_METHOD_MAP,
    transactionDataKey: null,
    whitelist: ['offline', 'none', 'credit_card_stripe'],
    format: 'translate',
  },
  amount: {
    id: 'amount',
    label: 'Amount',
    sourceId: 'transactionData',
    transactionTypeToValueMap: null,
    transactionDataKey: 'amount',
    whitelist: [
      'offline',
      'none',
      'credit_card_stripe',
      'mosaic_application',
      'sunlight_loan_application',
      'plenti_loan_application',
      'energy_ease_application',
      'dividend_application',
      'brighte_application',
      'phoenix_application',
      'loanpal_application',
      'sungage_application',
      'commonbond_application',
    ],
    format: 'currencyWithTax',
  },
  transactionDatetime: {
    id: 'transactionDatetime',
    label: 'Transaction Datetime',
    sourceId: 'transactionData',
    transactionTypeToValueMap: null,
    transactionDataKey: 'transaction_datetime',
    whitelist: ['offline', 'none', 'docusign'],
    format: 'date',
  },
  amountRequested: {
    id: 'amountRequested',
    label: 'Amount Requested',
    sourceId: 'transactionData',
    transactionTypeToValueMap: null,
    transactionDataKey: 'external_finance_amount_requested',
    whitelist: [
      'dividend_application',
      'brighte_application',
      'phoenix_application',
      'loanpal_application',
      'sungage_application',
      'commonbond_application',
    ],
    format: 'currency',
  },
  amountApproved: {
    id: 'amountApproved',
    label: 'Amount Approved',
    sourceId: 'transactionData',
    transactionTypeToValueMap: null,
    transactionDataKey: 'external_finance_amount_approved',
    whitelist: [
      'dividend_application',
      'brighte_application',
      'phoenix_application',
      'loanpal_application',
      'sungage_application',
      'commonbond_application',
    ],
    format: 'currency',
  },
  lastModified: {
    id: 'lastModified',
    label: 'Last Modified',
    sourceId: 'transactionData',
    transactionTypeToValueMap: null,
    transactionDataKey: 'modified_date',
    whitelist: [
      'dividend_application',
      'dividend_loan_agreement',
      'plenti_bnpl_application',
      'brighte_application',
      'phoenix_application',
      'loanpal_application',
      'sungage_application',
      'mosaic_loan_agreement',
      'commonbond_application',
      'commonbond_loan_agreement',
    ],
    format: 'date',
  },
  initiated: {
    id: 'initiated',
    label: 'Initiated',
    sourceId: 'transactionData',
    transactionTypeToValueMap: null,
    transactionDataKey: 'transaction_datetime',
    whitelist: [
      'dividend_application',
      'brighte_application',
      'phoenix_application',
      'loanpal_application',
      'sungage_application',
      'commonbond_application',
    ],
    format: 'date',
  },
  signature: {
    id: 'signature',
    label: 'Signature',
    sourceId: 'transactionData',
    transactionTypeToValueMap: null,
    transactionDataKey: 'signature_data',
    whitelist: ['offline', 'none'],
    format: 'img',
  },
}

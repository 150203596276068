import { GroupType } from 'elements/input/GroupedSelectInput'
import { NotificationType } from 'ra-core'
import { useMemo } from 'react'
import restClient from 'restClient'
import { ConnectedOrg, ConnectedOrgBrief, OrgType } from 'types/orgs'
import { ProjectShare, ProjectType } from 'types/projects'

const restClientInstance = restClient(window.API_ROOT + '/api')

export const parseIdFromUrl = (url: string) => {
  return parseInt(url.replace(`${window.API_ROOT}/api/orgs/`, '').replace('/', ''))
}

export const getUrl = (url: string) => {
  url = url.replace(`${window.API_ROOT}/api/`, '')
  return restClientInstance('CUSTOM_GET', 'custom', {
    url: url,
  })
}

export const checkMultipleCountries = (
  currOrgCountry: string | undefined,
  sharedWith: ProjectShare[],
  connectedOrgs: ConnectedOrgBrief[],
  projectOwnerId?: number
) => {
  if (projectOwnerId) {
    const ownerOrgCountry = connectedOrgs?.find((org) => org?.org_id === projectOwnerId)?.org_country_iso2
    if (ownerOrgCountry && currOrgCountry !== ownerOrgCountry) return true
  } else {
    for (const i in sharedWith) {
      const org = sharedWith[i]
      const countryCode = connectedOrgs?.find((x) => x.org_id === org?.org_id)?.org_country_iso2
      if (countryCode && currOrgCountry !== countryCode) return true
    }
  }

  return false
}

export type OrgDetails = { org_id: number; org_name: string }

export const useProjectOwnerDetails = (currOrg?: OrgType, ownerId: number = -1): OrgDetails | undefined => {
  return useMemo(() => {
    const findProjectOwner = currOrg?.connected_orgs?.find((x) => x.org_id === ownerId)
    return findProjectOwner
      ? { org_id: findProjectOwner?.org_id, org_name: findProjectOwner?.org_name }
      : currOrg
      ? { org_id: currOrg?.id, org_name: currOrg?.name }
      : undefined
  }, [currOrg, ownerId])
}

export const useOtherOrgIds = (orgId: number, ownerId: number | undefined, sharedWith: ProjectShare[]): number[] => {
  return useMemo(() => findOtherOrgIds(orgId, ownerId, sharedWith), [orgId, ownerId, sharedWith])
}

// Returns a list of org IDs on the project excluding the current org, regardless of whether it is the owner or not
const findOtherOrgIds = (orgId: number, ownerId: number | undefined, sharedWith: undefined | { org_id: number }[]) => {
  if (!ownerId || !sharedWith) return []
  if (orgId === ownerId) return sharedWith.map((s) => s.org_id)
  else return [ownerId].concat(sharedWith.filter((s) => s.org_id !== orgId).map((s) => s.org_id))
}

export const formSharingParams = (orgId: number, projectData, otherFilters = {}) => {
  const sharedWith = projectData?.shared_with
  if (sharedWith?.length > 0) {
    return {
      ...otherFilters,
      visible_to: orgId,
      owned_by:
        `${projectData?.org_id},` +
        sharedWith
          .map((item) => {
            return item.org_id
          })
          .toString(),
    }
  } else {
    return otherFilters
  }
}

export const formSharingParamsString = (orgId: number, projectData, otherFilters = '') => {
  const sharedWith = projectData?.shared_with
  const sharedIds = sharedWith
    ?.map((item) => {
      return item.org_id
    })
    .toString()
  if (sharedWith?.length > 0) {
    return `?${otherFilters}&visible_to=${orgId}&owned_by=${projectData?.org_id},${sharedIds}`
  } else {
    return `?${otherFilters}`
  }
}

export const hasDirtySharedValues = (fields: string[]): boolean => {
  const shareKey = 'shared_with'
  for (const i in fields) {
    if (fields[i].includes(shareKey)) {
      return true
    }
  }
  return false
}

export const getNotifyEmails = (orgId: number) => {
  return restClientInstance('CUSTOM_GET', 'custom', {
    url: `orgs/${orgId}/connected_orgs/?resolve_notify_users=1`,
  })
}

export const openMailTo = (
  connectedOrgs: ConnectedOrg[],
  toOrgId: number,
  project: ProjectType,
  fromOrg: string,
  notify?: (msg: string, type: NotificationType) => void
) => {
  const findOrg = connectedOrgs.find((x) => parseIdFromUrl(x.org_to) === toOrgId)
  if (findOrg?.notify_user_emails) {
    if (findOrg.notify_user_emails.length === 0) {
      if (notify) notify('No users assigned to this connection by other organisation.', 'warning')
      return
    }
    const emailTo = findOrg.notify_user_emails.join(',') || ''
    const subject = `${project.address} - ${fromOrg}`
    const body = `Click here to view the project: ${window.location.origin}/#/projects/${project.id}`
    window.open(`mailto:${emailTo}?subject=${subject}&body=${body}`)
    // window.location.href = `mailto:${emailTo}?subject=${subject}&body=${body}`
  } else {
    console.warn('Failed to find notify email addresses: ', connectedOrgs, toOrgId)
  }
}

export const saveShareSettings = (orgId: number, projectId: number, payload) => {
  const url = `orgs/${orgId}/projects/${projectId}/`
  return restClientInstance('CUSTOM_PATCH', 'custom', {
    url: url,
    data: { shared_with: payload },
  })
}

const parseOrgIdFromUrl = (url: string) => {
  const urlKey = '/orgs/'
  return parseInt(url?.substring(url.indexOf(urlKey) + urlKey.length).replace('/', ''))
}

// const choiceFilter = (item, orgId) => {
//   if (item.hasOwnProperty('org_id')) {
//     return item.org_id === orgId
//   } else {
//     return parseOrgIdFromUrl(item.org) === orgId
//   }
// }

export const getOrgGroups = (
  org_id: number,
  ownerOrg: OrgDetails | undefined,
  sharedWith?: OrgDetails[],
  withDefaultGroup = false,
  defaultTitle?: string
) => {
  const groups: GroupType[] = []

  if (ownerOrg) {
    const allOrgs = [ownerOrg, ...(sharedWith || [])]
    const selfOrg = allOrgs.find((x) => x.org_id === org_id)

    if (selfOrg) {
      groups.push({ id: selfOrg?.org_id + '-shared', name: selfOrg?.org_name || '', chip: 'Shared' })
      groups.push({ id: selfOrg?.org_id + '-unshared', name: selfOrg?.org_name || '', chip: 'Not Shared' })
    }

    for (const item of allOrgs) {
      if (item.org_id !== org_id) {
        groups.push({ id: item.org_id + '-shared', name: item.org_name || '', chip: 'Shared' })
        groups.push({ id: item.org_id + '-unshared', name: item.org_name || '', chip: 'Not Shared' })
      }
    }
  }

  if (withDefaultGroup) groups.push({ id: 'default', name: defaultTitle || '' })
  return groups
}

const isChoiceShared = (share_with_orgs: number[], sharedWith: number[]) => {
  return sharedWith?.every((i) => share_with_orgs.includes(i))
}

export const filterByOrgShare = (choice, orgId: number, otherOrgs: number[]) => {
  const share_with_orgs = choice.share_with_orgs?.length && choice.share_with_orgs.map((x) => parseIdFromUrl(x))
  if (choice.hasOwnProperty('defaultItem') && choice.defaultItem) return 'default'
  const choiceOrgId = choice.hasOwnProperty('org_id') ? choice.org_id : parseOrgIdFromUrl(choice.org)
  const checkShared = choiceOrgId === orgId ? otherOrgs : [orgId]
  return choiceOrgId + (share_with_orgs && isChoiceShared(share_with_orgs, checkShared) ? '-shared' : '-unshared')
}

export const groupChoices = (
  choices: any[],
  orgId: number,
  ownerOrg: OrgDetails,
  sharedWith: OrgDetails[],
  optionText = undefined
) => {
  const groups = getOrgGroups(orgId, ownerOrg, sharedWith)
  const groupMap = {}
  for (const group of groups) {
    groupMap[group.id] = { group, items: [] }
  }
  const otherOrgIds = findOtherOrgIds(orgId, ownerOrg?.org_id, sharedWith)
  for (const choice of choices) {
    const groupId = filterByOrgShare(choice, orgId, otherOrgIds)
    const group = groupMap[groupId]
    if (group) group.items.push(choice)
  }
  let list: object[] = []
  let groupsFound = 0
  for (const i in groups) {
    const group = groups[i]
    const items = groupMap[group.id].items
    if (!items.length) continue
    list.push({
      ...group,
      [optionText || 'title']: group.name,
      heading: true,
    })
    list = list.concat(items)
    groupsFound++
  }
  return groupsFound > 0 ? list : choices
}

//Check if choices only contain instances from external org
export const checkChoicesForOwnItems = (orgId, choices) => {
  const choiceIds = choices.map((x) => (x.hasOwnProperty('org_id') ? x.org_id : parseOrgIdFromUrl(x.org)))
  return choiceIds?.includes(orgId)
}

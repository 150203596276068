import { authReload } from 'actions/authActions'
import { AuthConfigType } from 'pages/auth/types'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { storeAuthRedirectionToLocalStorage } from 'redirections/authRedirectionStore'
import appStorage from 'storage/appStorage'

export const SEND_AUTH_TOKEN_POST_MESSAGE_TYPE = 'send_auth_token'
export const AUTO_REDIRECT_ON_TOKEN_UPDATED = true



const WaitForNewTokenThenAutoLogin = ({
  loginConfig,
  cancel,
  finish,
}: {
  loginConfig: AuthConfigType
  cancel: () => void
  finish: () => void
}) => {
  /* 
    userPassRedirectUri can optionally be provided directly which avoids the need to call getSsoStartLink
    */
  const dispatch = useDispatch()
  // 'waiting_for_token_refresh', 'reloading_auth_with_token'
  const [ssoState, setSsoState] = useState<string>('waiting_for_token_refresh')
  const [ssoTokenInitial, setSsoTokenInitial] = useState<string | null | undefined>(appStorage.getToken())
  const [tokenFound, setTokenFound] = useState<string | null | undefined>('')
  const [orgIdFound, setOrgIdFound] = useState<number | null | undefined>(undefined)

  const doAuthReloadWithNewToken = (token, orgId) => {
    setSsoState('reloading_auth_with_token')
    appStorage.setToken(token)
    appStorage.setOrgId(orgId)
    dispatch(authReload({
      orgId: orgId,
    }))
  }

  useEffect(() => {
    // create a postMessage listener to receive a token from the popup window
    // dispose of it when the token has been received or if we exit this process.
    const listener = (e: MessageEvent) => {
      if (e.origin !== window.location.origin) {
        console.log(`ignore unexpected message origin expected:${window.location.origin} actual:${e.origin}`, e)
      } else if (!e.data || e.data.type !== SEND_AUTH_TOKEN_POST_MESSAGE_TYPE) {
        console.log('ignore unexpected message received', e)
      } else if (!e.data.token) {
        throw new Error('token not found')
      } else {
        setTokenFound(e.data.token)
        setOrgIdFound(e.data.orgId)

        if (AUTO_REDIRECT_ON_TOKEN_UPDATED) {
          doAuthReloadWithNewToken(e.data.token, e.data.orgId)
        }

      }
    }
    window.addEventListener('message', listener)
    return () => {
      window.removeEventListener('message', listener)
    }
  })

  const userPassRedirectUri = !!loginConfig && loginConfig.type === 'sso' ? loginConfig?.userPassRedirectUri : undefined

  const handleRedirect = (e) => {
    e.preventDefault()
    if (userPassRedirectUri) {
      if (window.parent === window) {
        storeAuthRedirectionToLocalStorage()
        window.location.href = userPassRedirectUri
      } else {
        // running in iframe, unable to redirect for SSO login because SalesForce OAuth does not allow entering 
        // username and password in an iframe. In theory, perhaps we could initiate the OAuth flow inside the iframe
        // and if already logged in can proceed inside the iframe, but we would need to detect if it failed and then
        // launch the popup which is a lot more complicated.
        // 
        // 1. launch in popop window
        // 2. show placeholder which will a) poll for new token b) redirect and use token to login once it has been updated
        setSsoState('waiting_for_token_refresh')
        setSsoTokenInitial(appStorage.getToken())
        window.open(userPassRedirectUri)
      }
    }
  }

  if (ssoState === 'reloading_auth_with_token') {
    return <div>
      Authorizing with token...
    </div>
  }

  return (
    <div>
      {(tokenFound && orgIdFound) && (
        <div>
          Token is ready{' '}
          <button
            onClick={() => {
              doAuthReloadWithNewToken(tokenFound, orgIdFound)
            }}
          >
            Continue
          </button>
        </div>
      )}
      {(!tokenFound || !orgIdFound) && <div>Waiting for OAuth flow to complete...</div>}
      <button
        onClick={() => {
          appStorage.setOrgId()
          appStorage.setToken()
          cancel()
        }}
      >
        Cancel
      </button>
    </div>
  )

  // return <div>Error unexpected SSO state</div>
}

export default WaitForNewTokenThenAutoLogin

import { Portal } from '@material-ui/core'
import { useUxVersion } from 'hooks/useUxVersion'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import ProjectNav from '../header3/ProjectNav'
import ExplorePageSubHeader from './ExplorePageSubHeader'
import ProjectSubHeader from './ProjectSubHeader'

type PropsType = {
  submitting: boolean
}

const SubHeader: FC<PropsType> = ({ submitting }) => {
  const ui3Enabled = useUxVersion() === 3
  const isExplorePage = useSelector((state: any) => state.project?.section === 'explore')

  return (
    <Portal container={() => document.getElementById('secondary-appbar')}>
      {isExplorePage ? (
        <ExplorePageSubHeader />
      ) : ui3Enabled ? (
        <ProjectNav submitting={submitting} />
      ) : (
        <ProjectSubHeader submitting={submitting} />
      )}
    </Portal>
  )
}

export default SubHeader

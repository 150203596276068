// @ts-nocheck
import { CircularProgress, FormControlLabel, Radio, RadioGroup, useMediaQuery } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import GenericButton from 'elements/button/GenericButton'
import { Button } from 'opensolar-ui'
import React, { CSSProperties, useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import restClient from 'restClient'
import { SystemDataType } from 'types/systems'
import { ProjectType } from '../../../types/projects'
import SunlightTransferLoadingText from './SunlightTransferLoadingText'
import { PaymentOptionSunlightQuickCreateType, SystemDesignSunlightQuickCreateType } from './types'

let restClientInstance = restClient(window.API_ROOT + '/api')
const CONFIG = {
  logo: 'logo-sunlight-financial.png',
  logo_small: 'logo-sunlight-financial-small.png',
  studio_banner_title: 'Simplify Your Sale!',
  name: 'sunlight',
  themeColor: 'rgb(254, 184, 22)',
  themeColorDark: 'rgb(232, 143, 36)',
}

const buttonsWrapperStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
  width: '100%',
  margin: '15px',
}

const cancelButtonStyle: CSSProperties = {
  backgroundColor: '#f0f0f0',
  textTransform: 'none',
  minWidth: '220px',
  margin: '15px',
}

const primaryButtonStyle: CSSProperties = {
  minWidth: '220px',
  margin: '15px',
}

const errorTextStyle: CSSProperties = {
  textAlign: 'center',
  color: 'red',
  padding: '10px',
}

const formWrapperStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
}

const radioGroupWrapperStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  margin: '0px 30px',
}

type PropTypes = {
  project: ProjectType
  orgId: number
  systemUuid: string | null
  paymentOptionId: number | null
  labelColor: string
  backgroundColor: string
  backgroundColorOnHover: string
  onBack: () => void
  availableSystems?: SystemDataType[]
  systemDesigns?: SystemDesignSunlightQuickCreateType[]
  onFinish: () => void
}

const SunlightTransferCredit: React.FunctionComponent<PropTypes> = (props) => {
  const getAllPaymentOptions = () => {
    if (props.systemDesigns && props.systemDesigns.length > 0) {
      let allOptions: PaymentOptionSunlightQuickCreateType[] = []
      props.systemDesigns.forEach((design) => {
        if (design.paymentOptionsData && design.paymentOptionsData.length > 0) {
          design.paymentOptionsData
            .filter((opt) => opt.integration === 'sunlight')
            .forEach((opt) => allOptions.push(opt))
        }
      })
      return allOptions
    } else return []
  }

  const getSytstemHasSunlightOption = (system: SystemDesignSunlightQuickCreateType) => {
    if (!system || !system.paymentOptionsData) return false
    let sunlightPmtOptions = system.paymentOptionsData.filter((pmtOption) => pmtOption.integration === 'sunlight')
    return sunlightPmtOptions.length > 0
  }

  const [creditIsLoading, setCreditIsLoading] = useState<boolean>(false)
  const [selectedPaymentOptionId, setSelectedPaymentOptionId] = useState<number | null>(null)
  const [selectedSystem, setSelectedSystem] = useState<SystemDesignSunlightQuickCreateType | null>(null)
  const [paymentOptionsForSelectedSystem, setPaymentOptionsForSelectedSystem] = useState<
    PaymentOptionSunlightQuickCreateType[]
  >([])
  const [allPaymentOptions, setAllPaymentOptions] = useState<PaymentOptionSunlightQuickCreateType[]>(
    getAllPaymentOptions()
  )
  const [errorMsg, setErrorMsg] = useState<string | null>(null)

  const translate = useTranslate()
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'))

  //populate paymentOptionsForSelectedSystem with array of pmt options on system user has already selected
  useEffect(() => {
    if (selectedSystem && props.systemDesigns) {
      //dedupe titles present on multiple systems
      const availablePmtOptions = selectedSystem.paymentOptionsData.filter((pmtOption) => {
        return pmtOption.integration === 'sunlight'
      })
      setPaymentOptionsForSelectedSystem(availablePmtOptions)
    } else if (paymentOptionsForSelectedSystem && paymentOptionsForSelectedSystem.length > 0)
      setPaymentOptionsForSelectedSystem([])
  }, [selectedSystem, props.systemDesigns])

  //clear out selected payment option if they change system and their initial payment option is no longer valid
  useEffect(() => {
    if (selectedPaymentOptionId && selectedSystem) {
      let isValid = false
      selectedSystem.paymentOptionsData?.forEach((pmt) => {
        if (pmt.id === selectedPaymentOptionId) isValid = true
      })
      if (!isValid) {
        setSelectedPaymentOptionId(null)
      }
    }
  }, [selectedPaymentOptionId, selectedSystem])

  const LoadingIcon = (
    <CircularProgress
      size={30}
      //@ts-ignore
      left={-5}
      top={-2}
      status="loading"
      style={{
        color: CONFIG.themeColor,
        display: 'inline-block',
        float: 'none',
        position: 'relative',
      }}
    />
  )

  const createCreditApp = () => {
    let pmtOptionId = props.paymentOptionId || selectedPaymentOptionId
    let systemUuid = props.systemUuid || selectedSystem?.uuid
    if (!systemUuid) setErrorMsg(translate('Please select a System Design'))
    else if (!pmtOptionId) setErrorMsg(translate('Please select a Payment Option'))
    else {
      setCreditIsLoading(true)
      if (errorMsg) setErrorMsg(null)
      restClientInstance('CUSTOM_POST', 'custom', {
        url:
          'orgs/' +
          props.orgId +
          '/projects/' +
          props.project.id +
          '/apply_for_finance/' +
          systemUuid +
          '/' +
          pmtOptionId +
          '/sunlight_loan_application/',
      })
        .then((response: any) => {
          if (response.data?.status === true) {
            let successMessage = translate("This Credit Application has been created in Sunlight's Orange Portal")
            window.Designer.showNotification(successMessage, 'success')
            props.onFinish()
          } else {
            window.Designer.showNotification(translate('Unable to create application in Orange Portal'), 'danger')
          }
          let source = 'unknown'
          if (window.location.href?.includes('/projects/')) source = 'projects page'
          else if (window.location.href?.includes('#/info/')) source = 'info page'
          else if (window.location.href?.includes('#/myenergy/')) source = 'my energy'
          logAmplitudeEvent('integrated_finance_application_sent', {
            source: source,
            is_prequal: 'no',
            integration: 'sunlight',
            project_id: props.project.id,
          })
        })
        .catch((err: any) => {
          console.log('err', err)
          var errorDetail = err && err.message ? '. ' + err.message : ''
          window.Designer.showNotification(
            translate('Unable to create application in Orange Portal' + errorDetail),
            'danger'
          )
          let source = 'unknown'
          if (window.location.href?.includes('/projects/')) source = 'projects page'
          else if (window.location.href?.includes('#/info/')) source = 'info page'
          else if (window.location.href?.includes('#/myenergy/')) source = 'my energy'
          logAmplitudeEvent('integrated_finance_application_error', {
            source: source,
            is_prequal: 'no',
            integration: 'sunlight',
          })
        })
        .finally(() => {
          setCreditIsLoading(false)
        })
    }
  }

  const handlePmtOptionChange = (e: any) => {
    setSelectedPaymentOptionId(parseInt(e.target.value))
  }

  const handleSystemChange = (e: any) => {
    if (props.systemDesigns && props.systemDesigns.length > 0) {
      let uuidMatches = props.systemDesigns.filter((system) => system.uuid === e.target.value)
      if (uuidMatches && uuidMatches.length > 0) setSelectedSystem(uuidMatches[0])
    }
  }

  const renderEnabledRadioGroup = () => {
    //to prevent/ease screen jumps when user toggles between systems with different number of pmt options
    const maxPaymentOptionsRows = isMobile ? 0 : allPaymentOptions?.length || 0
    const pmtOptionsMinHeight = maxPaymentOptionsRows * 42
    return (
      <RadioGroup
        style={{ alignItems: 'flex-start', minHeight: pmtOptionsMinHeight }}
        name="pmt-options"
        value={selectedPaymentOptionId}
        onChange={handlePmtOptionChange}
      >
        {paymentOptionsForSelectedSystem.map((pmtOption) => {
          return (
            <FormControlLabel
              key={pmtOption.id}
              value={pmtOption.id}
              control={<Radio />}
              label={<span style={{ fontSize: 13 }}>{pmtOption.title}</span>}
            />
          )
        })}
      </RadioGroup>
    )
  }

  const renderDisabledRadioGroup = () => {
    if (selectedSystem) return null
    return (
      <RadioGroup
        style={{ alignItems: 'flex-start' }}
        name="pmt-options"
        value={selectedPaymentOptionId}
        onChange={() => null}
      >
        {allPaymentOptions.map((pmtOption) => {
          return (
            <FormControlLabel
              key={pmtOption.id}
              value={pmtOption.id}
              control={<Radio disabled />}
              label={<span style={{ fontSize: 13 }}>{pmtOption.title}</span>}
            />
          )
        })}
      </RadioGroup>
    )
  }

  const renderForm = () => {
    if (!props.systemDesigns || props.systemDesigns.length === 0) return null
    return (
      <div style={formWrapperStyle}>
        <div style={radioGroupWrapperStyle}>
          <h2 style={{ marginTop: '30px' }}>{translate('Select a System')}</h2>
          <RadioGroup
            style={{ alignItems: 'flex-start' }}
            name="pmt-options"
            value={selectedSystem?.uuid}
            onChange={handleSystemChange}
          >
            {props.systemDesigns.map((system) => {
              if (!getSytstemHasSunlightOption(system)) return null
              else
                return (
                  <FormControlLabel
                    key={system.uuid}
                    value={system.uuid}
                    control={<Radio />}
                    label={<span style={{ fontSize: 13 }}>{system.title}</span>}
                  />
                )
            })}
          </RadioGroup>
        </div>
        <div style={radioGroupWrapperStyle}>
          <h2 style={{ marginTop: '30px' }}>{translate('Select a Payment Option')}</h2>
          {paymentOptionsForSelectedSystem && paymentOptionsForSelectedSystem.length > 0
            ? renderEnabledRadioGroup()
            : renderDisabledRadioGroup()}
        </div>
      </div>
    )
  }

  if (!props.project?.id || !props.orgId) return null
  return (
    <>
      <p>
        When you click "Proceed" you will create a credit application in the Sunlight portal for the system design and
        payment option selected above.{' '}
        <strong>You will also trigger an email to the customer inviting them to complete the application.</strong>
      </p>
      {(!props.paymentOptionId || !props.systemUuid) && renderForm()}
      {errorMsg && <p style={errorTextStyle}>{errorMsg}</p>}
      <div style={buttonsWrapperStyle}>
        {!creditIsLoading && (
          <Button variant="contained" onClick={() => props.onBack()} style={cancelButtonStyle}>
            <span>{translate('Cancel')}</span>
          </Button>
        )}
        <GenericButton
          onClick={() => createCreditApp()}
          style={primaryButtonStyle}
          startIcon={
            creditIsLoading ? (
              LoadingIcon
            ) : (
              <img
                alt={CONFIG.name}
                src={`${window.PUBLIC_URL}/images/${CONFIG.logo_small}`}
                style={{ maxHeight: 24, maxWidth: 60 }}
              />
            )
          }
          labelWrapperStyle={{ padding: '0 8px', whiteSpace: 'normal' }}
          label={creditIsLoading ? translate('Creating application...') : translate('Proceed')}
          labelColor={props.labelColor}
          backgroundColor={props.backgroundColor}
          backgroundColorOnHover={props.backgroundColorOnHover}
          disabled={creditIsLoading}
          tooltipLabel={null}
        />
      </div>
      <SunlightTransferLoadingText />
    </>
  )
}
export default SunlightTransferCredit

import React from 'react'
import { PrivateFileList, PrivateFileCreate, PrivateFileEdit } from './PrivateFiles'

const PrivateFileName = ({ record, style }) => {
  return <span style={style}>File {record ? `"${record.title}"` : ''}</span>
}

export default {
  list: PrivateFileList,
  create: PrivateFileCreate,
  edit: PrivateFileEdit,
  options: {
    create: {
      title: 'Create Private File',
      subtitle: null,
      breadCrumb: 'Create Private File',
    },
    list: {
      title: 'Private Files',
      subtitle: null,
      breadCrumb: 'Private Files',
    },
    edit: {
      title: <PrivateFileName />,
      subtitle: null,
      breadCrumb: 'Edit Private File',
    },
  },
}

import { Dialog, DialogContent } from '@material-ui/core'
import { push as pushAction } from 'connected-react-router'
import { setStudioSavePrompt as setStudioSavePromptAction } from 'ducks/studioPrompt'
import { Button } from 'opensolar-ui'
import { Component } from 'react'
import { withTranslate } from 'react-admin'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import withStudioSignals from 'Studio/signals/withStudioSignals'
import { getRoleFromState } from '../../util/misc'

class LoginWithNearmapDialog extends Component {
  constructor(props) {
    super(props)
    var state = {
      message: 'Login to access Nearmap imagery.',
      isOpen: false,
      ignore: false,
    }

    var injectState = props.state ? props.state : null

    //Override with any supplied state - for use in storybook
    if (injectState) {
      for (var key in injectState) {
        state[key] = injectState[key]
      }
    }

    this.state = state
  }
  componentDidMount() {
    this.props.useStudioSignals(() => {
      this.setState({
        ...this.state,
        isOpen: true,
      })
    }, ['requestNearmapLogin'])
  }

  handleLogin = () => {
    const skipResponseFromApi = true
    this.props.zone === 'studio'
      ? window.WorkspaceHelper.save(
          undefined,
          (status) => {
            if (status) {
              window.location.href = window.API_BASE_URL + 'nearmap/'
            }
          },
          skipResponseFromApi
        )
      : (window.location.href = window.API_BASE_URL + 'nearmap/')
  }

  render() {
    const { translate, zone } = this.props

    return (
      <Dialog
        title={translate('Login Required')}
        open={this.state.ignore !== true && Boolean(this.props.isPro) && this.state.isOpen}
      >
        <DialogContent>
          <div>
            <div>
              <h2>{translate(this.state.message)}</h2>
              <div style={{ margin: '20px 0', height: 40 }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ float: 'right', marginRight: 10 }}
                  onClick={this.handleLogin}
                >
                  {zone === 'studio' ? 'Save & Login with Nearmap' : 'Login with Nearmap'}
                </Button>
                <Button
                  variant="contained"
                  style={{ float: 'right', marginRight: 10 }}
                  onClick={() => {
                    this.setState({
                      isOpen: false,
                    })
                  }}
                >
                  <span>{translate('Not now')}</span>
                </Button>
                <Button
                  style={{ float: 'right', marginRight: 10 }}
                  variant="contained"
                  onClick={() => {
                    this.setState({
                      isOpen: false,
                      ignore: true,
                    })
                  }}
                >
                  <span>{translate("Don't ask again")}</span>
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    orgId: state.auth ? state.auth.org_id : null,
    projectId: state.projectId,
    recentProjects: state.recentProjects.filter((project) => state.auth && project.org_id === state.auth.org_id),
    zone: state.zone,
    isPro: Boolean(getRoleFromState(state)),
  }
}

export default compose(
  withTranslate,
  withStudioSignals,
  connect(mapStateToProps, { push: pushAction, setStudioSavePrompt: setStudioSavePromptAction })
)(LoginWithNearmapDialog)

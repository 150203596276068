// @ts-nocheck
import React from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { ProjectTransactionType } from 'types/projectTransactions'
import DialogEditor from '../../../../elements/DialogEditor'
import EditTransactionDialogFormWrapper from './EditTransactionDialogFormWrapper'

type PropTypes = {
  showEditDialog: boolean
  setShowEditDialog: (newVal: boolean) => void
  transactionToEdit: any
  onDismiss: () => void
}

const EditTransactionDialog: React.FunctionComponent<PropTypes> = (props) => {
  const translate = useTranslate()
  const notify = useNotify()
  const form = useForm()
  const formState = useFormState()

  const onFailure = () => {}

  const onSuccess = (response: any) => {
    notify('Your changes have been saved', 'success')
    const responseData = response.data

    const index = formState.initialValues.transactions_data.findIndex(
      (transaction: ProjectTransactionType) => transaction.id === responseData.id
    )
    formState.initialValues.transactions_data[index] = responseData
    form.reset({ ...formState.initialValues })
    props.onDismiss()
  }

  const onDelete = (deletedTransactionId: number) => {
    const newArray: ProjectTransactionType[] = form
      .getState()
      .values.transactions_data?.filter(
        (transaction: ProjectTransactionType) => transaction.id !== deletedTransactionId
      )

    form.reset({ ...formState.initialValues, transactions_data: newArray })
    props.onDismiss()
  }

  return props.transactionToEdit ? (
    <DialogEditor
      isOpen={props.showEditDialog}
      handleClose={() => props.setShowEditDialog(false)}
      id={props.transactionToEdit.id}
      title={translate('Edit Transaction')}
      classes={props.classes}
      editorComponent={
        <EditTransactionDialogFormWrapper
          basePath={'/transactions'}
          resource={'transactions'}
          redirect={undefined}
          hideCancelButton={true}
          hideBreadCrumbs={true}
          style={{ padding: 0 }}
          onDismiss={props.onDismiss}
          onSuccess={onSuccess}
          onFailure={onFailure}
          onDelete={onDelete}
        />
      }
    />
  ) : null
}
export default EditTransactionDialog

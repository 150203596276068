import React from 'react'
import { ReferenceInput, useTranslate } from 'react-admin'
import CustomDropdownInput, { CustomDropdownInputProps } from '../../../common/CustomDropdownInput'

interface PropTypes extends CustomDropdownInputProps {
  source: string
  className?: string
}
const roleFilter = {
  role_type: 1,
  with_predefined: 1,
}

const SharePermissionInput: React.FC<PropTypes> = (props) => {
  const { source, className, ...inputProps } = props
  const translate = useTranslate()
  return (
    <ReferenceInput
      className={className}
      allowEmpty={true}
      source={source}
      reference="permissions_role___aliases"
      resource="roles"
      filter={roleFilter}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <CustomDropdownInput emptyText={translate('Full Access')} {...inputProps} />
    </ReferenceInput>
  )
}

export default SharePermissionInput

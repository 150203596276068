import React, { FC, useContext } from 'react'
import PanelContainer from '../PanelContainer'
import RecentProjects from './RecentProjects'
import RecentFilters from './RecentFilters'
import { RecentFiltersSubHeader } from './SubHeader'

const RecentPanel = () => {
  return (
    <PanelContainer title="Recent">
      <div style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
      <RecentProjects />
      <RecentFilters />
      </div>
    </PanelContainer>
  )
}

export default RecentPanel

import { SystemConditionDef, createSystemCondition } from '.'
import { CustomSignal } from '../types'

// This is a utility, not to be used directly (see and/or conditions instead)
export type NestedConditionDef = {
  conditions: SystemConditionDef[]
}

export const nested_condition = (def: NestedConditionDef) => {
  const subConditions = def.conditions.map((c) => ({ def: c, condition: createSystemCondition(c) })) || []
  return {
    subConditions,
    getReduxPaths: () =>
      subConditions.reduce(
        (paths, c) => (c.condition.getReduxPaths ? paths.concat(c.condition.getReduxPaths()) : paths),
        [] as string[]
      ),
    getProjectFields: () =>
      subConditions.reduce(
        (fields, c) => (c.condition.getProjectFields ? fields.concat(c.condition.getProjectFields()) : fields),
        [] as string[]
      ),
    getStudioSignals: () =>
      subConditions.reduce(
        (signals, c) => (c.condition.getStudioSignals ? signals.concat(c.condition.getStudioSignals()) : signals),
        [] as CustomSignal[]
      ),
  }
}

import { CONVERSION_FACTORS_TO_KWH } from './constants'
import { NaturalGasType } from './NaturalGasContent'

const convertKwhValue = (value?: number, unitValue?: string, reverse: boolean = false) => {
  if (!unitValue) return value
  if (value === null || value === undefined) return value

  const conversionFactor = CONVERSION_FACTORS_TO_KWH[unitValue]
  if (reverse) return value / conversionFactor
  return value * conversionFactor
}

export const formatNaturalGasValues = (data): NaturalGasType => {
  return {
    unit_value: data?.unit_value,
    usage_data_source: data?.usage_data_source,
    values: data?.values,
    values_kwh: convertKwhValue(data?.values, data?.unit_value),
    price: data?.price,
    price_per_kwh: convertKwhValue(data?.price, data?.unit_value, true),
  }
}

import { Button } from 'opensolar-ui'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type LyraIntegrationButtonProps = {
  disabled: boolean
  label: string
  onClick(): void
}

const useStyles = makeOpenSolarStyles((theme) => ({
  disabled: {},
  button: {
    color: '#fff',
    background: '#186598',
    border: 'none',
    '&:hover': {
      cursor: 'pointer',
      background: '#186598',
    },
    '&$disabled': {
      background: '#D6D6D6',
    },
  },
}))

const LyraIntegrationButton = ({ disabled, label, onClick }: LyraIntegrationButtonProps) => {
  const classes = useStyles()
  return (
    <Button
      variant="contained"
      classes={{ root: classes.button, disabled: classes.disabled }}
      disabled={disabled}
      onClick={onClick}
      fullWidth={true}
    >
      {label}
    </Button>
  )
}

export default LyraIntegrationButton

// @ts-nocheck
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined'
import { permissionsSelectors } from 'ducks/permissions'
import { Button } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { ToolTipPropsType, TourConfigType } from '../types'
import DefaultTooltip from './DefaultToolTip'

const Tooltip = (props: ToolTipPropsType) => {
  const { allowView, allowCreate, allowEdit, allowDelete } = useSelector(
    permissionsSelectors.getProjectPermissionByKey('energy_usage_tariff')
  )
  const { handleNext, handlePrev, handleCancel } = props
  const translate = useTranslate()
  return (
    <DefaultTooltip
      title={translate('Consumption Info')}
      // onClose={handleCancel}
      icon={EmojiObjectsOutlinedIcon}
      content={
        <div>
          <div>{translate("Enter your customer's annual energy usage.")}</div>
          <div style={{ margin: '10px 0' }}>
            {translate("If you don't have it, leave it blank to use OpenSolar's defaults.")}
          </div>
          <div>
            {translate(
              'If you\'ve got monthly usage data change the data-source to "Monthly Consumption (kWh)" and enter those values.'
            )}
          </div>
        </div>
      }
      actions={
        <div style={{ display: 'flex', textAlign: 'left', alignItems: 'center' }}>
          {!allowEdit && (
            <p style={{ color: 'red', textAlign: 'left', paddingLeft: 10 }}>
              you do not have permission to complete this action, click next to skip
            </p>
          )}
          <Button
            onClick={() => handlePrev && handlePrev()}
            onTouchStart={(e) => {
              e.stopPropagation()
              if (handlePrev) {
                handlePrev()
              }
            }}
          >
            <span>{translate('Back')}</span>
          </Button>
          <Button
            onClick={() => handleNext && handleNext()}
            onTouchStart={(e) => {
              e.stopPropagation()
              if (handleNext) {
                handleNext()
              }
            }}
          >
            <span>{translate('Next')}</span>
          </Button>
        </div>
      }
      arrowPlacement={'left'}
    />
  )
}
//fix targetId

const EnterEnergyUsage: TourConfigType = {
  spotlights: [{ targetId: 'electricity-usage-content-container', disableBackgroundScroll: true, keepObserve: true }],
  tooltips: [{ targetId: 'electricity-usage-content-container', placement: 'right', component: Tooltip }],
}

export default EnterEnergyUsage

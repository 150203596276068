import { logAmplitudeEvent } from 'amplitude/amplitude'
import { getFormsToRenderAsButton } from 'customForm/dialog/getFormsToRenderAsButton'
import useGetCustomForms from 'customForm/hooks/useGetCustomForms'
import RenusolDialog from 'Designer/integrations/renusol/RenusolDialog'
import { FC, useMemo, useState } from 'react'
import { useForm } from 'react-final-form'
import { FormPlacementEnum } from 'resources/customForms/types'
import { StudioSystemType } from 'types/global'
import { MountingID, NativeMountingID } from 'types/mounting'
import { MountingInputs } from './nativeMounting/Inputs'
import getNativeMountingSystems from './nativeMounting/mountingSystems'
import LaunchRenusolButton from './renusol/LaunchRenusolButton'
import ViridianInputs from './viridian/ViridianInputs'

type MountingTabPropsType = {
  mountingId: MountingID
  allowEdit: boolean
  system: StudioSystemType
}

const MountingSettings: FC<MountingTabPropsType> = ({ mountingId, allowEdit, system }) => {
  const form = useForm()
  const customForms = useGetCustomForms({ location: FormPlacementEnum.designpanel_mounting_button })

  const formsToRender = useMemo(() => {
    return getFormsToRenderAsButton([], customForms, { system })
  }, [system, customForms])

  const [renusolOpen, setRenusolOpen] = useState(false)
  const [esdecOpen, setEsdecOpen] = useState(false)

  const openRenusol = () => {
    if (window.editor?.selectedSystem?.moduleQuantity() > 0) {
      setRenusolOpen(true)
      logAmplitudeEvent('integrated_racking_dialog_opened', { integration: 'Renusol' })
    } else {
      alert('Please add panels to the project first')
    }
  }
  const openEsdec = () => {
    if (window.editor?.selectedSystem?.moduleQuantity() > 0) {
      setEsdecOpen(true)
    } else {
      alert('Please add panels to the project first')
    }
  }

  const confirmBeforeclose = () => {
    if (
      window.confirm(
        'Are you sure you want to close this form? All progress will be lost and your BOM will not be saved'
      )
    ) {
      setEsdecOpen(false)
      logAmplitudeEvent('integrated_racking_dialog_dismissed', { integration: 'ESDEC' })
    }
  }

  const nativeMountingSystems = getNativeMountingSystems()

  return (
    <>
      {window.editor.selectedSystem?.integration_json?.viridian && mountingId === 'viridian' && (
        <ViridianInputs allowEdit={allowEdit} />
      )}
      {nativeMountingSystems[mountingId] &&
        window.editor.selectedSystem?.integration_json &&
        window.editor.selectedSystem.integration_json[mountingId] && (
          <MountingInputs
            allowEdit={allowEdit}
            mountingName={window.editor.selectedSystem.mounting as NativeMountingID}
          />
        )}
      {allowEdit && mountingId === 'renusol' && <LaunchRenusolButton openRenusol={openRenusol} system={system} />}
      {(renusolOpen || window.debugRenusolDialogShow) && <RenusolDialog onClose={() => setRenusolOpen(false)} />}
      {formsToRender}
    </>
  )
}

export default MountingSettings

import moment from 'moment'
import OrderLineItem from 'pages/ordering/OrderLineItem'
import CityPlumbingService from 'services/cityPlumbing/CityPlumbingService'
import { formatCurrency } from 'util/misc'
import { FeesType } from '../types'
import { ContactDetailType, DeliveryDetailType, PaymentMethodType } from './types'

const PAYMENT_METHOD_VALUES = {
  credit_card: 'Credit Card',
  trade_credit: 'Trade Credit',
}

const deliveryTimeValues = {
  before_10_30_am: 'Before 10:30 AM',
  am: 'AM (After 10:30 AM)',
  pm: 'PM',
}

class CityPlumbingCheckoutPresenter {
  private service: CityPlumbingService | undefined
  private lineItems: OrderLineItem[]
  private setContactDetail: React.Dispatch<React.SetStateAction<ContactDetailType>>
  private setPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethodType>>
  private setDeliveryDetail: React.Dispatch<React.SetStateAction<DeliveryDetailType>>
  constructor(
    service: CityPlumbingService | undefined,
    lineItems: OrderLineItem[],
    setContactDetail: React.Dispatch<React.SetStateAction<ContactDetailType>>,
    setPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethodType>>,
    setDeliveryDetail: React.Dispatch<React.SetStateAction<DeliveryDetailType>>
  ) {
    this.service = service
    this.lineItems = lineItems
    this.setContactDetail = setContactDetail
    this.setPaymentMethod = setPaymentMethod
    this.setDeliveryDetail = setDeliveryDetail
  }

  updateContactDetail = (contactDetail: ContactDetailType) => {
    this.setContactDetail(contactDetail)
  }

  updatePaymentMethod = (paymentMethod: PaymentMethodType) => {
    this.setPaymentMethod(paymentMethod)
  }

  updateDeliveryDetail = (deliveryDetail: DeliveryDetailType) => {
    this.setDeliveryDetail(deliveryDetail)
  }

  placeOrderViaEmail = async (
    contactDetail: ContactDetailType,
    deliveryDetail: DeliveryDetailType,
    paymentMethod: PaymentMethodType,
    fees: FeesType,
    projectIds: number[]
  ) => {
    const lineItemNodes = this.lineItems.map((item) => {
      const { afterDiscount } = item.getBestDiscountOffer()
      return {
        title: item.data?.short_description,
        code: item.data?.code,
        variant: {
          sku: item.data?.code,
          price: {
            currencyCode: 'EUR',
            amount: formatCurrency(item.pricePerUnit),
          },
          baseSku: item.data?.base_sku,
          imageUrl: item.data?.image_url,
        },
        quantity: item.quantity,
        totalPrice: formatCurrency((item.pricePerUnit || 0) * item.quantity),
        discountedPrice: formatCurrency(afterDiscount),
      }
    })
    const orderData = {
      projectIds,
      totalTax: formatCurrency(fees.tax),
      shippingFee: formatCurrency(fees.shippingFee),
      subtotalPrice: formatCurrency(fees.subTotalCost),
      totalPrice: formatCurrency(fees.totalCost),
      items: lineItemNodes,
      shippingDetail: {
        ...deliveryDetail,
        deliveryDate: moment(deliveryDetail.deliveryDate).format('L'),
        deliveryTime: deliveryDetail.deliveryTime ? deliveryTimeValues[deliveryDetail.deliveryTime] : '',
        deliveryBranch: {
          name: deliveryDetail.deliveryBranch?.name,
          email: deliveryDetail.deliveryBranch?.email,
          manager: deliveryDetail.deliveryBranch?.manager,
          contactNumber: deliveryDetail.deliveryBranch?.contactNumber,
        },
      },
      contactDetail: contactDetail,
      paymentMethod: PAYMENT_METHOD_VALUES[paymentMethod],
    }
    return await this.service?.placeOrderViaEmail(orderData)
  }
}

export default CityPlumbingCheckoutPresenter

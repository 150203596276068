import { Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Help, MailOutlineOutlined } from '@material-ui/icons'
import { orgSelectors } from 'ducks/orgs'
import UserIcon from 'elements/UserIcon'
import { Chip, IconButton } from 'opensolar-ui'
import { getNotifyEmails, openMailTo } from 'projectSections/sections/info/orgSharing/util'
import { useNotify, useTranslate } from 'ra-core'
import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { ProjectType } from 'types/projects'
import { Theme } from 'types/themes'
import { COLOR_PALETTE, getProjectSectionStyles } from '../../../styles'
import OwnerOrgContent from './OwnerOrgContent'

const useStyles = makeStyles((theme: Theme) => ({
  orgName: {
    margin: '0 10px',
  },
  helpIcon: {
    height: 20,
    marginRight: 15,
    fill: COLOR_PALETTE.darkGrey,
  },
  chip: {
    height: 'unset',
    backgroundColor: COLOR_PALETTE.blue,
    color: COLOR_PALETTE.darkBlue,
    fontWeight: 500,
    '& .MuiChip-label': {
      padding: '5px 8px',
    },
  },
  mailBtn: {
    marginLeft: 'auto !important',
  },
}))
interface PropTypes {
  project: ProjectType
}

const ExternalTeams: React.FC<PropTypes> = ({ project }) => {
  const form = useForm()
  const notify = useNotify()
  const classes = useStyles()
  const translate = useTranslate()
  const sectionStyles = getProjectSectionStyles()
  const org = useSelector(orgSelectors.getOrg)
  const orgId = useMemo(() => org?.id, [org])
  const connectedOrgs = useMemo(() => org?.connected_orgs?.filter((x) => x.is_active) || [], [org])
  const isOwnerOrg = useMemo(() => project.org_id === orgId, [orgId, project.org_id])
  const ownerOrg = useMemo(() => connectedOrgs.find((o) => o.org_id === project.org_id), [
    project.org_id,
    connectedOrgs,
  ])

  useEffect(() => {
    form.registerField('shared_with', () => {}, {})
  }, [])

  if (!!!connectedOrgs.length) return null
  return (
    <>
      {isOwnerOrg && org && orgId ? (
        <OwnerOrgContent connectedOrgs={connectedOrgs} project={project} orgId={orgId} orgName={org?.name} />
      ) : (
        <div className={sectionStyles.row}>
          {ownerOrg && <UserIcon org={ownerOrg} />}
          <h3 className={classes.orgName}>{ownerOrg?.org_name}</h3>
          <Chip label="Project Owner" color="info" className={classes.chip} />
          <Tooltip title={translate('This Organisation has shared this project with your own Organisation.')}>
            <Help className={classes.helpIcon} />
          </Tooltip>
          <IconButton
            color="secondary"
            variant="squared"
            className={classes.mailBtn}
            onClick={() => {
              if (!ownerOrg || orgId === undefined || org?.name === undefined) return
              getNotifyEmails(orgId)
                .then((res) => {
                  openMailTo(res.data, ownerOrg.org_id, project, org?.name, notify)
                })
                .catch((err) => {
                  notify('Failed to retrieve connected org data.', 'warning')
                })
            }}
          >
            <MailOutlineOutlined />
          </IconButton>
        </div>
      )}
    </>
  )
}

export default ExternalTeams

import { MenuItem } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { featureConfigSelectors, getPublicFeatureConfig } from 'ducks/featureConfig'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedSystem } from 'reducer/designer/systems'

const getOverrideTariffId = (nem3ToNem2Mapping, currentTariffId, projectZip) => {
  const territoryZips = nem3ToNem2Mapping?.territory_zips
  const tariffMapping = nem3ToNem2Mapping?.tariff_mapping

  for (let mapping of tariffMapping) {
    if (mapping?.nem3_tariff_id === currentTariffId) {
      const nem2_tariff_id = mapping?.nem2_tariffs?.find((data) =>
        territoryZips?.[data?.territory]?.includes(projectZip)
      )?.tariff_id

      if (nem2_tariff_id)
        return {
          id: parseInt(nem2_tariff_id),
          isNem3: false,
        }
    }

    if (mapping?.nem2_tariffs?.some((nem2_tariff) => nem2_tariff?.tariff_id === currentTariffId)) {
      return {
        id: parseInt(mapping?.nem3_tariff_id),
        isNem3: true,
      }
    }
  }
}

const DuplicateSystemOptionNEM3 = ({ handleClose }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getPublicFeatureConfig('nem3_to_nem2_utility_rate_mapping'))
  }, [])

  const nem3ToNem2Mapping = useSelector((state) =>
    featureConfigSelectors.getFeatureConfigData(state, 'nem3_to_nem2_utility_rate_mapping')
  )

  const orgId = useSelector(authSelectors.getOrgId)
  const projectZip = window.WorkspaceHelper.project.zip
  const system = window.editor.selectedSystem
  const currentTariffId = system?.bills?.current?.utility_tariff_id
  const systemName = system.name && system.name.length > 0 ? system.name : 'System with'

  const overrideTariffId = nem3ToNem2Mapping
    ? getOverrideTariffId(nem3ToNem2Mapping, currentTariffId, projectZip)
    : undefined

  if (overrideTariffId) {
    return (
      <MenuItem
        onClick={() => {
          const newSystem = system && window.SceneHelper.duplicateSystem(system)
          newSystem.name = overrideTariffId.isNem3 ? `${systemName} NEM3` : `${systemName} NEM2`

          logAmplitudeEvent('nem3_duplicate_system', {
            orgId: orgId,
            duplicateTo: overrideTariffId.isNem3 ? 'nem3' : 'nem2',
          })
          newSystem.utility_tariff_id_override = overrideTariffId.id
          if (newSystem) dispatch(setSelectedSystem(newSystem.uuid))
          handleClose()
        }}
      >
        {overrideTariffId.isNem3
          ? 'Duplicate System with NEM3 Utility Rate'
          : 'Duplicate System with NEM2 Utility Rate'}
      </MenuItem>
    )
  } else {
    return <></>
  }
}

export default DuplicateSystemOptionNEM3

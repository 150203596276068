import { useDispatch } from 'react-redux'
import { ErrorButtonInfo } from 'reducer/project/projectErrorsReducer'

export function usePerformErrorAction() {
  const dispatch = useDispatch()
  return (action: ErrorButtonInfo) => {
    if (action.callback) {
      action.params = action.params ? action.params : []
      action.callback.apply(null, action.params)
    }
    if (action.docEvent) {
      document.dispatchEvent(new CustomEvent(action.docEvent, { detail: action }))
    }
    if (action.reduxPayload) dispatch(action.reduxPayload)
  }
}

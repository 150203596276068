/* 
  This state object design to hold application level state.
*/

import { RootState } from 'types/state'

// Todo: move loadCount into this state
export type AppStateType = {
  viewVersion: number //which is used to force a re-mount of the app when the view version changes. So that all components will be re-mounted. This is faster and better than reload entire app.
}

const DEFAULT_STATE = {
  viewVersion: 0,
}

const INCREMENT_APP_VIEW_VERSION = 'INCREMENT_APP_VIEW_VERSION'

export const incrementAppViewVersion = () => ({
  type: INCREMENT_APP_VIEW_VERSION,
  payload: null,
})

export default function reducer(previousState = DEFAULT_STATE, { type, payload }: { type: string; payload: any }) {
  if (type === INCREMENT_APP_VIEW_VERSION) {
    return {
      ...previousState,
      viewVersion: previousState.viewVersion + 1,
    }
  }
  return previousState
}

export const appStateSelectors = {
  getAppViewVersion: (state: RootState): number => state.app.viewVersion,
}

import { orderSelectors } from 'ducks/orderComponents'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import { parseQueryStringToDictionary } from 'util/misc'
import HdmCheckoutProvider from './HdmCheckoutProvider'
import PostCheckoutPage from './PostCheckoutPage'
import QuoteOrOrderPage from './QuoteOrOrderPage'

const Checkout = memo(() => {
  const location = useLocation()
  const params = parseQueryStringToDictionary(location.search)
  const lineItemsConfirmed = useSelector(orderSelectors.getOrderableLineItems)

  if (lineItemsConfirmed.length === 0) {
    return <Redirect to={'/shop'} />
  }

  if (params.step === 'post-order') {
    return <PostCheckoutPage quoteOrOrderType="order" />
  }

  return <QuoteOrOrderPage />
})

const HdmCheckout = () => {
  return (
    <HdmCheckoutProvider>
      <Checkout />
    </HdmCheckoutProvider>
  )
}

export default HdmCheckout

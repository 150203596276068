var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Chip as Mui4Chip } from '@material-ui/core';
import { default as Mui5Chip } from '@mui/material/Chip';
import React, { forwardRef } from 'react';
import { Inspector } from '../debug/Inspector';
import { useComponentVersion } from '../hooks/useComponentVersion';
export var Chip = React.forwardRef(function (props, ref) {
    return (_jsx(Inspector, { name: "Chip", propDefs: ChipPropDefs, showExample: true, component: OsChip, props: __assign(__assign({}, props), { ref: ref }) }));
});
export var OsChipVersion;
(function (OsChipVersion) {
    OsChipVersion[OsChipVersion["V1"] = 1] = "V1";
    OsChipVersion[OsChipVersion["V2"] = 2] = "V2";
})(OsChipVersion || (OsChipVersion = {}));
var V2_CHIP_COLORS = ['info', 'error', 'warning', 'success'];
var OsChip = forwardRef(function (props, ref) {
    var version = useComponentVersion('chip');
    switch (version) {
        case OsChipVersion.V2:
            var mui5Props = __assign(__assign({}, props), { variant: props.variant === 'default' ? 'filled' : props.variant, color: !props.color || props.color === 'default' ? 'secondary' : props.color, size: props.size || 'small' });
            return _jsx(Mui5Chip, __assign({ ref: ref }, mui5Props));
        default:
            var mui4Props = __assign(__assign({}, props), { variant: props.variant === 'rounded' ? 'default' : props.variant, color: props.color && V2_CHIP_COLORS.includes(props.color) ? 'default' : props.color });
            return _jsx(Mui4Chip, __assign({ ref: ref }, mui4Props));
    }
});
var ChipPropDefs = {
    variant: { default: 'text' },
    size: { default: 'medium' },
    color: { default: 'default' },
    label: { hidden: true },
    icon: { hidden: true },
    onDelete: { hidden: true }, // shows delete icon if set
};

import type { OpenSolarThemeType } from 'Themes'
import type { Filter } from 'elements/controller/filter/filter'
import useFilter from 'elements/controller/filter/useFilter'
import useQuery from 'elements/controller/query/useQuery'
import useListController from 'elements/controller/useListController'
import React, { memo, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import type { HardwareOrderProjectRecord } from '../type'
import TableContainer from './table/TableContainer'
import ProjectTableContent from './table/TableContent'
import ProjectTableHead from './table/TableHeader'
import ProjectTableTitle from './table/TableTitle'
import ProjectTableToolbar from './toolbar/Toolbar'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  table: {
    marginTop: 25,
    marginLeft: 25,
    marginRight: 25,
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  titleTextAndIcon: {
    marginRight: '5px',
    lineHeight: '24px',
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
  },
  pagenation: {
    justifyContent: 'flex-end',
  },
}))

const defaultQuery = { perPage: 5 }
const defaultInitialFilter = {
  filterValues: { fieldset: 'hardware_ordering', has_sold_system: 1 },
  displayedFilters: { has_sold_system: true },
}

const ProjectTable = () => {
  const classes = useStyles()
  const location = useLocation<{ defaultFilter?: Filter }>()
  const initialFilter = useMemo(
    () => (location.state?.defaultFilter != null ? location.state?.defaultFilter : defaultInitialFilter),
    []
  )
  const { query, queryPresenter } = useQuery(defaultQuery)
  const { filter, filterPresenter } = useFilter({ defaultFilter: initialFilter, queryPresenter })
  const { currentSort, data, ids, loaded, loading, total = 0, version } = useListController<HardwareOrderProjectRecord>(
    {
      // since this project list using a special fieldset
      // projects___aliases added here to avoid override the projects data in redux
      resource: 'projects___aliases',
      query,
      filter,
    }
  )
  return (
    <div className={classes.table}>
      <section className={classes.header}>
        <ProjectTableTitle />
        <ProjectTableToolbar resource={'projects'} filter={filter} filterPresenter={filterPresenter} />
      </section>
      <TableContainer loaded={loaded} loading={loading} total={total} query={query} queryPresenter={queryPresenter}>
        <ProjectTableHead
          currentSortField={currentSort.field}
          currentSortOrder={currentSort.order}
          setSort={queryPresenter.setSort}
          // onSelect={projectTablePresenter.onSelect}
        />
        <ProjectTableContent data={ids.map((id) => data[id]).filter((a) => !!a)} />
      </TableContainer>
    </div>
  )
}

export default memo(ProjectTable)

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
// @ts-nocheck
import difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetMany } from '../../dataProvider';
import useGetMatching from '../../dataProvider/useGetMatching';
import { useTranslate } from '../../i18n';
import identifierAsId from '../../util/identifierAsId';
import { getStatusForArrayInput as getDataStatus } from './referenceDataStatus';
var mapIdentifiersToIds = function (array) { return (array && array.map(function (url) { return identifierAsId(url); })) || []; };
/**
 * Prepare data for the ReferenceArrayInput components
 *
 * @example
 *
 * const { choices, error, loaded, loading } = useReferenceArrayInputController({
 *      basePath: 'resource';
 *      record: { referenceIds: ['id1', 'id2']};
 *      reference: 'reference';
 *      resource: 'resource';
 *      source: 'referenceIds';
 * });
 *
 * @param {Object} option
 * @param {string} option.basePath basepath to current resource
 * @param {Object} option.record The The current resource record
 * @param {string} option.reference The linked resource name
 * @param {string} option.resource The current resource name
 * @param {string} option.source The key of the linked resource identifier
 *
 * @return {Object} controllerProps Fetched data and callbacks for the ReferenceArrayInput components
 */
var useReferenceArrayInputController = function (_a) {
    var defaultFilter = _a.filter, _b = _a.filterToQuery, filterToQuery = _b === void 0 ? defaultFilterToQuery : _b, input = _a.input, _c = _a.perPage, perPage = _c === void 0 ? 20 : _c, _d = _a.sort, defaultSort = _d === void 0 ? { field: 'id', order: 'DESC' } : _d, options = _a.options, reference = _a.reference, resource = _a.resource, source = _a.source;
    var translate = useTranslate();
    // We store the current input value in a ref so that we are able to fetch
    // only the missing references when the input value changes
    var inputValue = useRef(input.value);
    var _e = useState(mapIdentifiersToIds(input.value)), idsToFetch = _e[0], setIdsToFetch = _e[1];
    var _f = useState([]), idsToGetFromStore = _f[0], setIdsToGetFromStore = _f[1];
    var referenceRecordsFromStore = useSelector(function (state) {
        return idsToGetFromStore.map(function (id) { return state.admin.resources[reference].data[id]; });
    });
    var allChoices = useSelector(function (state) { return Object.values(state.admin.resources[reference].data); });
    // optimization: we fetch selected items only once. When the user selects more items,
    // as we already have the past selected items in the store, we don't fetch them.
    useEffect(function () {
        // Only fetch new ids
        var newIdsToFetch = difference(mapIdentifiersToIds(input.value), mapIdentifiersToIds(inputValue.current));
        // Only get from store ids selected and already fetched
        var newIdsToGetFromStore = difference(mapIdentifiersToIds(input.value), newIdsToFetch);
        /*
                input.value (current)
                    +------------------------+
                    | ********************** |
                    | ********************** |  inputValue.current (old)
                    | ********** +-----------------------+
                    | ********** | ooooooooo |           |
                    | ********** | ooooooooo |           |
                    | ********** | ooooooooo |           |
                    | ********** | ooooooooo |           |
                    +---|--------|------|----+           |
                        |        |      |                |
                        |        |      |                |
                        |        +------|----------------+
                        |               |
                newIdsToFetch    newIdsToGetFromStore
            */
        // Change states each time input values changes to avoid keeping previous values no more selected
        if (!isEqual(idsToFetch, newIdsToFetch)) {
            setIdsToFetch(newIdsToFetch);
        }
        if (!isEqual(idsToGetFromStore, newIdsToGetFromStore)) {
            setIdsToGetFromStore(newIdsToGetFromStore);
        }
        inputValue.current = input.value;
    }, [idsToFetch, idsToGetFromStore, input.value, setIdsToFetch, setIdsToGetFromStore]);
    var _g = useState({ page: 1, perPage: perPage }), pagination = _g[0], setPagination = _g[1];
    var _h = useState(defaultSort), sort = _h[0], setSort = _h[1];
    var _j = useState(''), filter = _j[0], setFilter = _j[1];
    // Ensure sort can be updated through props too, not just by using the setSort function
    useEffect(function () {
        if (!isEqual(defaultSort, sort)) {
            setSort(defaultSort);
        }
    }, [setSort, defaultSort, sort]);
    // Ensure pagination can be updated through props too, not just by using the setPagination function
    useEffect(function () {
        var newPagination = {
            page: 1,
            perPage: perPage,
        };
        if (!isEqual(newPagination, pagination)) {
            setPagination(newPagination);
        }
    }, [setPagination, perPage, pagination]);
    // Merge the user filters with the default ones
    var finalFilter = useMemo(function () { return (__assign(__assign({}, defaultFilter), filterToQuery(filter))); }, [defaultFilter, filter, filterToQuery]);
    var _k = useGetMany(reference, idsToFetch || []), referenceRecordsFetched = _k.data, loaded = _k.loaded;
    var referenceRecords = referenceRecordsFetched
        ? referenceRecordsFetched.concat(referenceRecordsFromStore)
        : referenceRecordsFromStore;
    // filter out not found references - happens when the dataProvider doesn't guarantee referential integrity
    var finalReferenceRecords = referenceRecords.filter(Boolean);
    var _l = useGetMatching(reference, pagination, sort, finalFilter, source, resource, options), matchingReferences = _l.data, refreshingData = _l.refreshingData;
    // We merge the currently selected records with the matching ones, otherwise
    // the component displaying the currently selected records may fail
    var finalMatchingReferences = matchingReferences && matchingReferences.length > 0
        ? mergeReferences(matchingReferences, finalReferenceRecords)
        : finalReferenceRecords.length > 0
            ? finalReferenceRecords
            : matchingReferences;
    var dataStatus = getDataStatus({
        input: input,
        matchingReferences: finalMatchingReferences,
        referenceRecords: finalReferenceRecords,
        translate: translate,
    });
    return {
        choices: dataStatus.choices,
        allChoices: allChoices,
        error: dataStatus.error,
        loaded: loaded,
        loading: dataStatus.waiting,
        refreshingData: refreshingData,
        setFilter: setFilter,
        setPagination: setPagination,
        setSort: setSort,
        warning: dataStatus.warning,
    };
};
// concatenate and deduplicate two lists of records
var mergeReferences = function (ref1, ref2) {
    var res = __spreadArray([], ref1, true);
    var ids = ref1.map(function (ref) { return ref.id; });
    ref2.forEach(function (ref) {
        if (!ids.includes(ref.id)) {
            ids.push(ref.id);
            res.push(ref);
        }
    });
    return res;
};
export default useReferenceArrayInputController;
var defaultFilterToQuery = function (searchText) { return ({ q: searchText }); };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Placeholder from './Placeholder';
import { useTimeout } from 'ra-core';
var useStyles = makeStyles(function (theme) { return ({
    primary: {
        width: '30vw',
        display: 'inline-block',
        marginBottom: theme.spacing(),
    },
    tertiary: { float: 'right', opacity: 0.541176, minWidth: '10vw' },
}); }, { name: 'RaSimpleListLoading' });
var times = function (nbChildren, fn) {
    return Array.from({ length: nbChildren }, function (_, key) { return fn(key); });
};
var SimpleListLoading = function (props) {
    var classesOverride = props.classes, className = props.className, hasLeftAvatarOrIcon = props.hasLeftAvatarOrIcon, hasRightAvatarOrIcon = props.hasRightAvatarOrIcon, hasSecondaryText = props.hasSecondaryText, hasTertiaryText = props.hasTertiaryText, _a = props.nbFakeLines, nbFakeLines = _a === void 0 ? 5 : _a, rest = __rest(props, ["classes", "className", "hasLeftAvatarOrIcon", "hasRightAvatarOrIcon", "hasSecondaryText", "hasTertiaryText", "nbFakeLines"]);
    var classes = useStyles(props);
    var oneSecondHasPassed = useTimeout(1000);
    return oneSecondHasPassed ? (_jsx(List, __assign({ className: className }, rest, { children: times(nbFakeLines, function (key) { return (_jsxs(ListItem, { children: [hasLeftAvatarOrIcon && (_jsx(ListItemAvatar, { children: _jsx(Avatar, { children: "\u00A0" }) })), _jsx(ListItemText, { primary: _jsxs("div", { children: [_jsx(Placeholder, { className: classes.primary }), hasTertiaryText && (_jsx("span", __assign({ className: classes.tertiary }, { children: _jsx(Placeholder, {}) })))] }), secondary: hasSecondaryText ? _jsx(Placeholder, {}) : undefined }), hasRightAvatarOrIcon && (_jsx(ListItemSecondaryAction, { children: _jsx(Avatar, { children: "\u00A0" }) }))] })); }) }))) : null;
};
SimpleListLoading.propTypes = {
    className: PropTypes.string,
    hasLeftAvatarOrIcon: PropTypes.bool,
    hasRightAvatarOrIcon: PropTypes.bool,
    hasSecondaryText: PropTypes.bool,
    hasTertiaryText: PropTypes.bool,
    nbFakeLines: PropTypes.number,
};
export default SimpleListLoading;

import { Theme, makeStyles, useMediaQuery } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import Alert from 'elements/Alert'
import moment from 'moment'
import PersonAvatar from 'projectSections/sections/manage/projectHistory/PersonAvatar'
import { useTranslate } from 'ra-core'
import React, { useMemo, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { StageActionEventType } from 'types/projects'

type PropTypes = {
  events: StageActionEventType[]
  isFetching: boolean
}

const useStyles = makeStyles<OpenSolarThemeType, { isMobile: boolean }>((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  eventItem: {
    display: 'flex',
    flexDirection: ({ isMobile }) => (isMobile ? 'column' : 'row'),
    width: '100%',
    alignItems: 'flex-start',
    borderBottom: `1px solid ${theme.greyLight2}`,
    padding: '5px',
    minHeight: '30px',
  },
  avatarAndText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: ({ isMobile }) => (isMobile ? '5px' : undefined),
    flex: 10,
  },
  imgCol: {
    width: '50px',
  },
  textCol: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: 10,
  },
  titleText: {
    fontWeight: 900,
    marginRight: '10px',
  },
  notesText: {
    color: theme.greyMid1,
  },
  dateCol: {
    flex: 2,
    color: theme.greyMid1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  showExpandedLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: theme.blue,
    margin: '15px 0px 0px 0px',
  },
  nothingHereYet: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '30px 0px',
    color: theme.greyMid1,
  },
}))

const MAX_ROWS = 100
const INITIAL_ROW_LIMIT = 10

const CashFlowEventTable: React.FC<PropTypes> = (props) => {
  const [showExpanded, setShowExpanded] = useState<boolean>(false)

  const eventsToShow = useMemo(() => {
    if (showExpanded) return props.events?.slice(0, MAX_ROWS)
    else return props.events?.slice(0, INITIAL_ROW_LIMIT)
  }, [showExpanded, props.events])

  const showTooManyRowsWarning = useMemo(() => {
    return props.events?.length > MAX_ROWS
  }, [props.events?.length])

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const classes = useStyles({ isMobile })
  const translate = useTranslate()

  const toggleShowExpanded = () => {
    setShowExpanded(!showExpanded)
  }

  if (props.isFetching) {
    return <Skeleton height={200} />
  }
  if (!eventsToShow?.length) {
    return (
      <div className={classes.nothingHereYet}>
        <p>{translate('Nothing to show here yet...')}</p>
      </div>
    )
  }
  return (
    <div className={classes.wrapper}>
      {eventsToShow?.map((event) => (
        <div className={classes.eventItem}>
          <div className={classes.avatarAndText}>
            <div className={classes.imgCol}>
              {event.who?.portrait_image_public_url && (
                <PersonAvatar
                  url={event.who?.portrait_image_public_url}
                  personName={event.who?.display}
                  small={true}
                  is_archived={false}
                />
              )}
            </div>
            <div className={classes.textCol}>
              <strong className={classes.titleText}>{event.title}</strong>
              <div className={classes.notesText}>{event.notes}</div>
            </div>
          </div>
          <div className={classes.dateCol}>{moment(event.created_date).format('LLL')}</div>
        </div>
      ))}
      {showExpanded ? (
        <div onClick={toggleShowExpanded} className={classes.showExpandedLink}>
          {translate('Show Less')}
        </div>
      ) : (
        <>
          {eventsToShow?.length > INITIAL_ROW_LIMIT && (
            <div onClick={toggleShowExpanded} className={classes.showExpandedLink}>
              {translate('Show More')}
            </div>
          )}
        </>
      )}
      {showTooManyRowsWarning && (
        <Alert severity="warning">
          {translate('This list of events has been shortened. You can see the complete list in the Manage page')}
        </Alert>
      )}
    </div>
  )
}
export default CashFlowEventTable

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.project_form = void 0;
var action_1 = require("../core/action");
var state_1 = require("../core/state");
exports.project_form = {
    // State
    active: new state_1.Value(false),
    dirty_fields: new state_1.ValueArray([]),
    values: new state_1.DeepState({}),
    system_count: new state_1.Value(0),
    system_summaries: new state_1.DeepState([]),
    save_state: new state_1.Value('none'),
    // Actions
    setValues: new action_1.Action(),
    updateFields: new action_1.Action(),
    getDesignData: new action_1.Action(),
    save: new action_1.Action(),
    discard: new action_1.Action(),
};

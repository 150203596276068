import { CountryMapType, CountryMappingType } from 'pages/mfa/types'

export const countryMapping: CountryMappingType = {
  AF: {
    name: 'Afghanistan',
    iso2: 'AF',
    iso3: 'AFG',
    nationality: 'Afghan',
    code: '004',
    prefix: '93',
    flag: '🇦🇫',
    migrationId: 1,
    mainRegion: false,
  },
  AX: {
    name: 'Aland Islands',
    iso2: 'AX',
    iso3: 'ALA',
    nationality: '',
    code: '248',
    prefix: '358',
    flag: '🇦🇽',
    migrationId: 2,
    mainRegion: false,
  },
  AL: {
    name: 'Albania',
    iso2: 'AL',
    iso3: 'ALB',
    nationality: 'Albanian',
    code: '008',
    prefix: '355',
    flag: '🇦🇱',
    migrationId: 3,
    mainRegion: false,
  },
  DZ: {
    name: 'Algeria',
    iso2: 'DZ',
    iso3: 'DZA',
    nationality: 'Algerian',
    code: '012',
    prefix: '213',
    flag: '🇩🇿',
    migrationId: 4,
    mainRegion: false,
  },
  AS: {
    name: 'American Samoa',
    iso2: 'AS',
    iso3: 'ASM',
    nationality: '',
    code: '016',
    prefix: '1+684',
    flag: '🇦🇸',
    migrationId: 5,
    mainRegion: false,
  },
  AD: {
    name: 'Andorra',
    iso2: 'AD',
    iso3: 'AND',
    nationality: 'Andorran',
    code: '020',
    prefix: '376',
    flag: '🇦🇩',
    migrationId: 6,
    mainRegion: false,
  },
  AO: {
    name: 'Angola',
    iso2: 'AO',
    iso3: 'AGO',
    nationality: 'Angolan',
    code: '024',
    prefix: '244',
    flag: '🇦🇴',
    migrationId: 7,
    mainRegion: false,
  },
  AI: {
    name: 'Anguilla',
    iso2: 'AI',
    iso3: 'AIA',
    nationality: '',
    code: '660',
    prefix: '1+264',
    flag: '🇦🇮',
    migrationId: 8,
    mainRegion: false,
  },
  AQ: {
    name: 'Antarctica',
    iso2: 'AQ',
    iso3: 'ATA',
    nationality: '',
    code: '010',
    prefix: '672',
    flag: '🇦🇶',
    migrationId: 9,
    mainRegion: false,
  },
  AG: {
    name: 'Antigua and Barbuda',
    iso2: 'AG',
    iso3: 'ATG',
    nationality: 'Antiguans, Barbudans',
    code: '028',
    prefix: '1+268',
    flag: '🇦🇬',
    migrationId: 10,
    mainRegion: false,
  },
  AR: {
    name: 'Argentina',
    iso2: 'AR',
    iso3: 'ARG',
    nationality: 'Argentine or Argentinean',
    code: '032',
    prefix: '54',
    flag: '🇦🇷',
    migrationId: 11,
    mainRegion: false,
  },
  AM: {
    name: 'Armenia',
    iso2: 'AM',
    iso3: 'ARM',
    nationality: 'Armenian',
    code: '051',
    prefix: '374',
    flag: '🇦🇲',
    migrationId: 12,
    mainRegion: false,
  },
  AW: {
    name: 'Aruba',
    iso2: 'AW',
    iso3: 'ABW',
    nationality: '',
    code: '533',
    prefix: '297',
    flag: '🇦🇼',
    migrationId: 13,
    mainRegion: false,
  },
  AU: {
    name: 'Australia',
    iso2: 'AU',
    iso3: 'AUS',
    nationality: 'Australian or Ozzie or Aussie',
    code: '036',
    prefix: '61',
    flag: '🇦🇺',
    migrationId: 14,
    mainRegion: true,
  },
  AT: {
    name: 'Austria',
    iso2: 'AT',
    iso3: 'AUT',
    nationality: 'Austrian',
    code: '040',
    prefix: '43',
    flag: '🇦🇹',
    migrationId: 15,
    mainRegion: false,
  },
  AZ: {
    name: 'Azerbaijan',
    iso2: 'AZ',
    iso3: 'AZE',
    nationality: 'Azerbaijani',
    code: '031',
    prefix: '994',
    flag: '🇦🇿',
    migrationId: 16,
    mainRegion: false,
  },
  BS: {
    name: 'Bahamas',
    iso2: 'BS',
    iso3: 'BHS',
    nationality: 'Bahamian',
    code: '044',
    prefix: '1+242',
    flag: '🇧🇸',
    migrationId: 17,
    mainRegion: false,
  },
  BH: {
    name: 'Bahrain',
    iso2: 'BH',
    iso3: 'BHR',
    nationality: 'Bahraini',
    code: '048',
    prefix: '973',
    flag: '🇧🇭',
    migrationId: 18,
    mainRegion: false,
  },
  BD: {
    name: 'Bangladesh',
    iso2: 'BD',
    iso3: 'BGD',
    nationality: 'Bangladeshi',
    code: '050',
    prefix: '880',
    flag: '🇧🇩',
    migrationId: 19,
    mainRegion: false,
  },
  BB: {
    name: 'Barbados',
    iso2: 'BB',
    iso3: 'BRB',
    nationality: 'Barbadian or Bajuns',
    code: '052',
    prefix: '1+246',
    flag: '🇧🇧',
    migrationId: 20,
    mainRegion: false,
  },
  BY: {
    name: 'Belarus',
    iso2: 'BY',
    iso3: 'BLR',
    nationality: 'Belarusian',
    code: '112',
    prefix: '375',
    flag: '🇧🇾',
    migrationId: 21,
    mainRegion: false,
  },
  BE: {
    name: 'Belgium',
    iso2: 'BE',
    iso3: 'BEL',
    nationality: 'Belgian',
    code: '056',
    prefix: '32',
    flag: '🇧🇪',
    migrationId: 22,
    mainRegion: false,
  },
  BZ: {
    name: 'Belize',
    iso2: 'BZ',
    iso3: 'BLZ',
    nationality: 'Belizean',
    code: '084',
    prefix: '501',
    flag: '🇧🇿',
    migrationId: 23,
    mainRegion: false,
  },
  BJ: {
    name: 'Benin',
    iso2: 'BJ',
    iso3: 'BEN',
    nationality: 'Beninese',
    code: '204',
    prefix: '229',
    flag: '🇧🇯',
    migrationId: 24,
    mainRegion: false,
  },
  BM: {
    name: 'Bermuda',
    iso2: 'BM',
    iso3: 'BMU',
    nationality: '',
    code: '060',
    prefix: '1+441',
    flag: '🇧🇲',
    migrationId: 25,
    mainRegion: false,
  },
  BT: {
    name: 'Bhutan',
    iso2: 'BT',
    iso3: 'BTN',
    nationality: 'Bhutanese',
    code: '064',
    prefix: '975',
    flag: '🇧🇹',
    migrationId: 26,
    mainRegion: false,
  },
  BO: {
    name: 'Bolivia',
    iso2: 'BO',
    iso3: 'BOL',
    nationality: 'Bolivian',
    code: '068',
    prefix: '591',
    flag: '🇧🇴',
    migrationId: 27,
    mainRegion: false,
  },
  BA: {
    name: 'Bosnia and Herzegovina',
    iso2: 'BA',
    iso3: 'BIH',
    nationality: 'Bosnian, Herzegovinian',
    code: '070',
    prefix: '387',
    flag: '🇧🇦',
    migrationId: 28,
    mainRegion: false,
  },
  BW: {
    name: 'Botswana',
    iso2: 'BW',
    iso3: 'BWA',
    nationality: 'Motswana , Batswana',
    code: '072',
    prefix: '267',
    flag: '🇧🇼',
    migrationId: 29,
    mainRegion: false,
  },
  BV: {
    name: 'Bouvet Island',
    iso2: 'BV',
    iso3: 'BVT',
    nationality: '',
    code: '074',
    prefix: null,
    flag: '🇧🇻',
    migrationId: 30,
    mainRegion: false,
  },
  BR: {
    name: 'Brazil',
    iso2: 'BR',
    iso3: 'BRA',
    nationality: 'Brazilian',
    code: '076',
    prefix: '55',
    flag: '🇧🇷',
    migrationId: 31,
    mainRegion: false,
  },
  IO: {
    name: 'British Indian Ocean Territory',
    iso2: 'IO',
    iso3: 'IOT',
    nationality: '',
    code: '086',
    prefix: '246',
    flag: '🇮🇴',
    migrationId: 32,
    mainRegion: false,
  },
  VG: {
    name: 'British Virgin Islands',
    iso2: 'VG',
    iso3: 'VGB',
    nationality: '',
    code: '092',
    prefix: '1+284',
    flag: '🇻🇬',
    migrationId: 33,
    mainRegion: false,
  },
  BN: {
    name: 'Brunei Darussalam',
    iso2: 'BN',
    iso3: 'BRN',
    nationality: 'Bruneian',
    code: '096',
    prefix: '673',
    flag: '🇧🇳',
    migrationId: 34,
    mainRegion: false,
  },
  BG: {
    name: 'Bulgaria',
    iso2: 'BG',
    iso3: 'BGR',
    nationality: 'Bulgarian',
    code: '100',
    prefix: '359',
    flag: '🇧🇬',
    migrationId: 35,
    mainRegion: false,
  },
  BF: {
    name: 'Burkina Faso',
    iso2: 'BF',
    iso3: 'BFA',
    nationality: 'Burkinabe',
    code: '854',
    prefix: '226',
    flag: '🇧🇫',
    migrationId: 36,
    mainRegion: false,
  },
  MM: {
    name: 'Burma',
    iso2: 'MM',
    iso3: 'MMR',
    nationality: 'Burmese or Myanmarese',
    code: '104',
    prefix: '95',
    flag: '🇲🇲',
    migrationId: 37,
    mainRegion: false,
  },
  BI: {
    name: 'Burundi',
    iso2: 'BI',
    iso3: 'BDI',
    nationality: 'Burundian',
    code: '108',
    prefix: '257',
    flag: '🇧🇮',
    migrationId: 38,
    mainRegion: false,
  },
  KH: {
    name: 'Cambodia',
    iso2: 'KH',
    iso3: 'KHM',
    nationality: 'Cambodian',
    code: '116',
    prefix: '855',
    flag: '🇰🇭',
    migrationId: 39,
    mainRegion: false,
  },
  CM: {
    name: 'Cameroon',
    iso2: 'CM',
    iso3: 'CMR',
    nationality: 'Cameroonian',
    code: '120',
    prefix: '237',
    flag: '🇨🇲',
    migrationId: 40,
    mainRegion: false,
  },
  CA: {
    name: 'Canada',
    iso2: 'CA',
    iso3: 'CAN',
    nationality: 'Canadian',
    code: '124',
    prefix: '1',
    flag: '🇨🇦',
    migrationId: 41,
    mainRegion: false,
  },
  CV: {
    name: 'Cape Verde',
    iso2: 'CV',
    iso3: 'CPV',
    nationality: 'Cape Verdian or Cape Verdean',
    code: '132',
    prefix: '238',
    flag: '🇨🇻',
    migrationId: 42,
    mainRegion: false,
  },
  KY: {
    name: 'Cayman Islands',
    iso2: 'KY',
    iso3: 'CYM',
    nationality: '',
    code: '136',
    prefix: '1+345',
    flag: '🇰🇾',
    migrationId: 43,
    mainRegion: false,
  },
  CF: {
    name: 'Central African Republic',
    iso2: 'CF',
    iso3: 'CAF',
    nationality: 'Central African',
    code: '140',
    prefix: '236',
    flag: '🇨🇫',
    migrationId: 44,
    mainRegion: false,
  },
  TD: {
    name: 'Chad',
    iso2: 'TD',
    iso3: 'TCD',
    nationality: 'Chadian',
    code: '148',
    prefix: '235',
    flag: '🇹🇩',
    migrationId: 45,
    mainRegion: false,
  },
  CL: {
    name: 'Chile',
    iso2: 'CL',
    iso3: 'CHL',
    nationality: 'Chilean',
    code: '152',
    prefix: '56',
    flag: '🇨🇱',
    migrationId: 46,
    mainRegion: false,
  },
  CN: {
    name: 'China',
    iso2: 'CN',
    iso3: 'CHN',
    nationality: 'Chinese',
    code: '156',
    prefix: '86',
    flag: '🇨🇳',
    migrationId: 47,
    mainRegion: false,
  },
  CX: {
    name: 'Christmas Island',
    iso2: 'CX',
    iso3: 'CXR',
    nationality: '',
    code: '162',
    prefix: '61',
    flag: '🇨🇽',
    migrationId: 48,
    mainRegion: false,
  },
  CC: {
    name: 'Cocos (Keeling) Islands',
    iso2: 'CC',
    iso3: 'CCK',
    nationality: '',
    code: '166',
    prefix: '61',
    flag: '🇨🇨',
    migrationId: 49,
    mainRegion: false,
  },
  CO: {
    name: 'Colombia',
    iso2: 'CO',
    iso3: 'COL',
    nationality: 'Colombian',
    code: '170',
    prefix: '57',
    flag: '🇨🇴',
    migrationId: 50,
    mainRegion: false,
  },
  KM: {
    name: 'Comoros',
    iso2: 'KM',
    iso3: 'COM',
    nationality: 'Comoran',
    code: '174',
    prefix: '269',
    flag: '🇰🇲',
    migrationId: 51,
    mainRegion: false,
  },
  CG: {
    name: 'Congo',
    iso2: 'CG',
    iso3: 'COG',
    nationality: 'Congolese',
    code: '178',
    prefix: '242',
    flag: '🇨🇬',
    migrationId: 52,
    mainRegion: false,
  },
  CK: {
    name: 'Cook Islands',
    iso2: 'CK',
    iso3: 'COK',
    nationality: '',
    code: '184',
    prefix: '682',
    flag: '🇨🇰',
    migrationId: 53,
    mainRegion: false,
  },
  CR: {
    name: 'Costa Rica',
    iso2: 'CR',
    iso3: 'CRI',
    nationality: 'Costa Rican',
    code: '188',
    prefix: '506',
    flag: '🇨🇷',
    migrationId: 54,
    mainRegion: false,
  },
  CI: {
    name: "Cote d'Ivoire",
    iso2: 'CI',
    iso3: 'CIV',
    nationality: 'Ivorian',
    code: '384',
    prefix: '225',
    flag: '🇨🇮',
    migrationId: 55,
    mainRegion: false,
  },
  HR: {
    name: 'Croatia',
    iso2: 'HR',
    iso3: 'HRV',
    nationality: 'Croat or Croatian',
    code: '191',
    prefix: '385',
    flag: '🇭🇷',
    migrationId: 56,
    mainRegion: false,
  },
  CU: {
    name: 'Cuba',
    iso2: 'CU',
    iso3: 'CUB',
    nationality: 'Cuban',
    code: '192',
    prefix: '53',
    flag: '🇨🇺',
    migrationId: 57,
    mainRegion: false,
  },
  CY: {
    name: 'Cyprus',
    iso2: 'CY',
    iso3: 'CYP',
    nationality: 'Cypriot',
    code: '196',
    prefix: '357',
    flag: '🇨🇾',
    migrationId: 58,
    mainRegion: false,
  },
  CZ: {
    name: 'Czech Republic',
    iso2: 'CZ',
    iso3: 'CZE',
    nationality: 'Czech',
    code: '203',
    prefix: '420',
    flag: '🇨🇿',
    migrationId: 59,
    mainRegion: false,
  },
  CD: {
    name: 'Democratic Republic of the Congo',
    iso2: 'CD',
    iso3: 'COD',
    nationality: 'Congolese',
    code: '180',
    prefix: '243',
    flag: '🇨🇩',
    migrationId: 60,
    mainRegion: false,
  },
  DK: {
    name: 'Denmark',
    iso2: 'DK',
    iso3: 'DNK',
    nationality: 'Dane or Danish',
    code: '208',
    prefix: '45',
    flag: '🇩🇰',
    migrationId: 61,
    mainRegion: false,
  },
  DJ: {
    name: 'Djibouti',
    iso2: 'DJ',
    iso3: 'DJI',
    nationality: 'Djibouti',
    code: '262',
    prefix: '253',
    flag: '🇩🇯',
    migrationId: 62,
    mainRegion: false,
  },
  DM: {
    name: 'Dominica',
    iso2: 'DM',
    iso3: 'DMA',
    nationality: 'Dominican',
    code: '212',
    prefix: '1+767',
    flag: '🇩🇲',
    migrationId: 63,
    mainRegion: false,
  },
  DO: {
    name: 'Dominican Republic',
    iso2: 'DO',
    iso3: 'DOM',
    nationality: 'Dominican',
    code: '214',
    prefix: '1+809, 8',
    flag: '🇩🇴',
    migrationId: 64,
    mainRegion: false,
  },
  EC: {
    name: 'Ecuador',
    iso2: 'EC',
    iso3: 'ECU',
    nationality: 'Ecuadorean',
    code: '218',
    prefix: '593',
    flag: '🇪🇨',
    migrationId: 65,
    mainRegion: false,
  },
  EG: {
    name: 'Egypt',
    iso2: 'EG',
    iso3: 'EGY',
    nationality: 'Egyptian',
    code: '818',
    prefix: '20',
    flag: '🇪🇬',
    migrationId: 66,
    mainRegion: false,
  },
  SV: {
    name: 'El Salvador',
    iso2: 'SV',
    iso3: 'SLV',
    nationality: 'Salvadoran',
    code: '222',
    prefix: '503',
    flag: '🇸🇻',
    migrationId: 67,
    mainRegion: false,
  },
  GQ: {
    name: 'Equatorial Guinea',
    iso2: 'GQ',
    iso3: 'GNQ',
    nationality: 'Equatorial Guinean or Equatoguinean',
    code: '226',
    prefix: '240',
    flag: '🇬🇶',
    migrationId: 68,
    mainRegion: false,
  },
  ER: {
    name: 'Eritrea',
    iso2: 'ER',
    iso3: 'ERI',
    nationality: 'Eritrean',
    code: '232',
    prefix: '291',
    flag: '🇪🇷',
    migrationId: 69,
    mainRegion: false,
  },
  EE: {
    name: 'Estonia',
    iso2: 'EE',
    iso3: 'EST',
    nationality: 'Estonian',
    code: '233',
    prefix: '372',
    flag: '🇪🇪',
    migrationId: 70,
    mainRegion: false,
  },
  ET: {
    name: 'Ethiopia',
    iso2: 'ET',
    iso3: 'ETH',
    nationality: 'Ethiopian',
    code: '231',
    prefix: '251',
    flag: '🇪🇹',
    migrationId: 71,
    mainRegion: false,
  },
  FK: {
    name: 'Falkland Islands (Malvinas)',
    iso2: 'FK',
    iso3: 'FLK',
    nationality: '',
    code: '238',
    prefix: '500',
    flag: '🇫🇰',
    migrationId: 72,
    mainRegion: false,
  },
  FO: {
    name: 'Faroe Islands',
    iso2: 'FO',
    iso3: 'FRO',
    nationality: '',
    code: '234',
    prefix: '298',
    flag: '🇫🇴',
    migrationId: 73,
    mainRegion: false,
  },
  FJ: {
    name: 'Fiji',
    iso2: 'FJ',
    iso3: 'FJI',
    nationality: 'Fijian',
    code: '242',
    prefix: '679',
    flag: '🇫🇯',
    migrationId: 74,
    mainRegion: false,
  },
  FI: {
    name: 'Finland',
    iso2: 'FI',
    iso3: 'FIN',
    nationality: 'Finn or Finnish',
    code: '246',
    prefix: '358',
    flag: '🇫🇮',
    migrationId: 75,
    mainRegion: false,
  },
  FR: {
    name: 'France',
    iso2: 'FR',
    iso3: 'FRA',
    nationality: 'French or Frenchman or Frenchwoman',
    code: '250',
    prefix: '33',
    flag: '🇫🇷',
    migrationId: 76,
    mainRegion: false,
  },
  GF: {
    name: 'French Guiana',
    iso2: 'GF',
    iso3: 'GUF',
    nationality: '',
    code: '254',
    prefix: '594',
    flag: '🇬🇫',
    migrationId: 77,
    mainRegion: false,
  },
  PF: {
    name: 'French Polynesia',
    iso2: 'PF',
    iso3: 'PYF',
    nationality: '',
    code: '258',
    prefix: '689',
    flag: '🇵🇫',
    migrationId: 78,
    mainRegion: false,
  },
  TF: {
    name: 'French Southern and Antarctic Lands',
    iso2: 'TF',
    iso3: 'ATF',
    nationality: '',
    code: '260',
    prefix: null,
    flag: '🇹🇫',
    migrationId: 79,
    mainRegion: false,
  },
  GA: {
    name: 'Gabon',
    iso2: 'GA',
    iso3: 'GAB',
    nationality: 'Gabonese',
    code: '266',
    prefix: '241',
    flag: '🇬🇦',
    migrationId: 80,
    mainRegion: false,
  },
  GM: {
    name: 'Gambia',
    iso2: 'GM',
    iso3: 'GMB',
    nationality: 'Gambian',
    code: '270',
    prefix: '220',
    flag: '🇬🇲',
    migrationId: 81,
    mainRegion: false,
  },
  GE: {
    name: 'Georgia',
    iso2: 'GE',
    iso3: 'GEO',
    nationality: 'Georgian',
    code: '268',
    prefix: '995',
    flag: '🇬🇪',
    migrationId: 82,
    mainRegion: false,
  },
  DE: {
    name: 'Germany',
    iso2: 'DE',
    iso3: 'DEU',
    nationality: 'German',
    code: '276',
    prefix: '49',
    flag: '🇩🇪',
    migrationId: 83,
    mainRegion: false,
  },
  GH: {
    name: 'Ghana',
    iso2: 'GH',
    iso3: 'GHA',
    nationality: 'Ghanaian',
    code: '288',
    prefix: '233',
    flag: '🇬🇭',
    migrationId: 84,
    mainRegion: false,
  },
  GI: {
    name: 'Gibraltar',
    iso2: 'GI',
    iso3: 'GIB',
    nationality: '',
    code: '292',
    prefix: '350',
    flag: '🇬🇮',
    migrationId: 85,
    mainRegion: false,
  },
  GR: {
    name: 'Greece',
    iso2: 'GR',
    iso3: 'GRC',
    nationality: 'Greek',
    code: '300',
    prefix: '30',
    flag: '🇬🇷',
    migrationId: 86,
    mainRegion: false,
  },
  GL: {
    name: 'Greenland',
    iso2: 'GL',
    iso3: 'GRL',
    nationality: '',
    code: '304',
    prefix: '299',
    flag: '🇬🇱',
    migrationId: 87,
    mainRegion: false,
  },
  GD: {
    name: 'Grenada',
    iso2: 'GD',
    iso3: 'GRD',
    nationality: 'Grenadian or Grenadan',
    code: '308',
    prefix: '1+473',
    flag: '🇬🇩',
    migrationId: 88,
    mainRegion: false,
  },
  GP: {
    name: 'Guadeloupe',
    iso2: 'GP',
    iso3: 'GLP',
    nationality: '',
    code: '312',
    prefix: '590',
    flag: '🇬🇵',
    migrationId: 89,
    mainRegion: false,
  },
  GU: {
    name: 'Guam',
    iso2: 'GU',
    iso3: 'GUM',
    nationality: '',
    code: '316',
    prefix: '1+671',
    flag: '🇬🇺',
    migrationId: 90,
    mainRegion: false,
  },
  GT: {
    name: 'Guatemala',
    iso2: 'GT',
    iso3: 'GTM',
    nationality: 'Guatemalan',
    code: '320',
    prefix: '502',
    flag: '🇬🇹',
    migrationId: 91,
    mainRegion: false,
  },
  GG: {
    name: 'Guernsey',
    iso2: 'GG',
    iso3: 'GGY',
    nationality: '',
    code: '831',
    prefix: '44',
    flag: '🇬🇬',
    migrationId: 92,
    mainRegion: false,
  },
  GN: {
    name: 'Guinea',
    iso2: 'GN',
    iso3: 'GIN',
    nationality: 'Guinean',
    code: '324',
    prefix: '224',
    flag: '🇬🇳',
    migrationId: 93,
    mainRegion: false,
  },
  GW: {
    name: 'Guinea-Bissau',
    iso2: 'GW',
    iso3: 'GNB',
    nationality: 'Guinea-Bissauan',
    code: '624',
    prefix: '245',
    flag: '🇬🇼',
    migrationId: 94,
    mainRegion: false,
  },
  GY: {
    name: 'Guyana',
    iso2: 'GY',
    iso3: 'GUY',
    nationality: 'Guyanese',
    code: '328',
    prefix: '592',
    flag: '🇬🇾',
    migrationId: 95,
    mainRegion: false,
  },
  HT: {
    name: 'Haiti',
    iso2: 'HT',
    iso3: 'HTI',
    nationality: 'Haitian',
    code: '332',
    prefix: '509',
    flag: '🇭🇹',
    migrationId: 96,
    mainRegion: false,
  },
  HM: {
    name: 'Heard Island and McDonald Islands',
    iso2: 'HM',
    iso3: 'HMD',
    nationality: '',
    code: '334',
    prefix: null,
    flag: '🇭🇲',
    migrationId: 97,
    mainRegion: false,
  },
  VA: {
    name: 'Holy See (Vatican City)',
    iso2: 'VA',
    iso3: 'VAT',
    nationality: 'none',
    code: '336',
    prefix: '39',
    flag: '🇻🇦',
    migrationId: 98,
    mainRegion: false,
  },
  HN: {
    name: 'Honduras',
    iso2: 'HN',
    iso3: 'HND',
    nationality: 'Honduran',
    code: '340',
    prefix: '504',
    flag: '🇭🇳',
    migrationId: 99,
    mainRegion: false,
  },
  HK: {
    name: 'Hong Kong',
    iso2: 'HK',
    iso3: 'HKG',
    nationality: '',
    code: '344',
    prefix: '852',
    flag: '🇭🇰',
    migrationId: 100,
    mainRegion: false,
  },
  HU: {
    name: 'Hungary',
    iso2: 'HU',
    iso3: 'HUN',
    nationality: 'Hungarian',
    code: '348',
    prefix: '36',
    flag: '🇭🇺',
    migrationId: 101,
    mainRegion: false,
  },
  IS: {
    name: 'Iceland',
    iso2: 'IS',
    iso3: 'ISL',
    nationality: 'Icelander',
    code: '352',
    prefix: '354',
    flag: '🇮🇸',
    migrationId: 102,
    mainRegion: false,
  },
  IN: {
    name: 'India',
    iso2: 'IN',
    iso3: 'IND',
    nationality: 'Indian',
    code: '356',
    prefix: '91',
    flag: '🇮🇳',
    migrationId: 103,
    mainRegion: false,
  },
  ID: {
    name: 'Indonesia',
    iso2: 'ID',
    iso3: 'IDN',
    nationality: 'Indonesian',
    code: '360',
    prefix: '62',
    flag: '🇮🇩',
    migrationId: 104,
    mainRegion: false,
  },
  IR: {
    name: 'Iran (Islamic Republic of)',
    iso2: 'IR',
    iso3: 'IRN',
    nationality: 'Iranian',
    code: '364',
    prefix: '98',
    flag: '🇮🇷',
    migrationId: 105,
    mainRegion: false,
  },
  IQ: {
    name: 'Iraq',
    iso2: 'IQ',
    iso3: 'IRQ',
    nationality: 'Iraqi',
    code: '368',
    prefix: '964',
    flag: '🇮🇶',
    migrationId: 106,
    mainRegion: false,
  },
  IE: {
    name: 'Ireland',
    iso2: 'IE',
    iso3: 'IRL',
    nationality: 'Irishman or Irishwoman or Irish',
    code: '372',
    prefix: '353',
    flag: '🇮🇪',
    migrationId: 107,
    mainRegion: false,
  },
  IM: {
    name: 'Isle of Man',
    iso2: 'IM',
    iso3: 'IMN',
    nationality: '',
    code: '833',
    prefix: '44',
    flag: '🇮🇲',
    migrationId: 108,
    mainRegion: false,
  },
  IL: {
    name: 'Israel',
    iso2: 'IL',
    iso3: 'ISR',
    nationality: 'Israeli',
    code: '376',
    prefix: '972',
    flag: '🇮🇱',
    migrationId: 109,
    mainRegion: false,
  },
  IT: {
    name: 'Italy',
    iso2: 'IT',
    iso3: 'ITA',
    nationality: 'Italian',
    code: '380',
    prefix: '39',
    flag: '🇮🇹',
    migrationId: 110,
    mainRegion: false,
  },
  JM: {
    name: 'Jamaica',
    iso2: 'JM',
    iso3: 'JAM',
    nationality: 'Jamaican',
    code: '388',
    prefix: '1+876',
    flag: '🇯🇲',
    migrationId: 111,
    mainRegion: false,
  },
  JP: {
    name: 'Japan',
    iso2: 'JP',
    iso3: 'JPN',
    nationality: 'Japanese',
    code: '392',
    prefix: '81',
    flag: '🇯🇵',
    migrationId: 112,
    mainRegion: false,
  },
  JE: {
    name: 'Jersey',
    iso2: 'JE',
    iso3: 'JEY',
    nationality: '',
    code: '832',
    prefix: '44',
    flag: '🇯🇪',
    migrationId: 113,
    mainRegion: false,
  },
  JO: {
    name: 'Jordan',
    iso2: 'JO',
    iso3: 'JOR',
    nationality: 'Jordanian',
    code: '400',
    prefix: '962',
    flag: '🇯🇴',
    migrationId: 114,
    mainRegion: false,
  },
  KZ: {
    name: 'Kazakhstan',
    iso2: 'KZ',
    iso3: 'KAZ',
    nationality: 'Kazakhstani',
    code: '398',
    prefix: '7',
    flag: '🇰🇿',
    migrationId: 115,
    mainRegion: false,
  },
  KE: {
    name: 'Kenya',
    iso2: 'KE',
    iso3: 'KEN',
    nationality: 'Kenyan',
    code: '404',
    prefix: '254',
    flag: '🇰🇪',
    migrationId: 116,
    mainRegion: false,
  },
  KI: {
    name: 'Kiribati',
    iso2: 'KI',
    iso3: 'KIR',
    nationality: 'I-Kiribati',
    code: '296',
    prefix: '686',
    flag: '🇰🇮',
    migrationId: 117,
    mainRegion: false,
  },
  KP: {
    name: "Korea\t Democratic People's Republic of",
    iso2: 'KP',
    iso3: 'PRK',
    nationality: 'North Korean',
    code: '408',
    prefix: '850',
    flag: '🇰🇵',
    migrationId: 118,
    mainRegion: false,
  },
  KR: {
    name: 'Korea\t Republic of',
    iso2: 'KR',
    iso3: 'KOR',
    nationality: 'South Korean',
    code: '410',
    prefix: '82',
    flag: '🇰🇷',
    migrationId: 119,
    mainRegion: false,
  },
  KW: {
    name: 'Kuwait',
    iso2: 'KW',
    iso3: 'KWT',
    nationality: 'Kuwaiti',
    code: '414',
    prefix: '965',
    flag: '🇰🇼',
    migrationId: 120,
    mainRegion: false,
  },
  KG: {
    name: 'Kyrgyzstan',
    iso2: 'KG',
    iso3: 'KGZ',
    nationality: 'Kyrgyz or Kirghiz',
    code: '417',
    prefix: '996',
    flag: '🇰🇬',
    migrationId: 121,
    mainRegion: false,
  },
  LA: {
    name: "Lao People's Democratic Republic",
    iso2: 'LA',
    iso3: 'LAO',
    nationality: 'Lao or Laotian',
    code: '418',
    prefix: '856',
    flag: '🇱🇦',
    migrationId: 122,
    mainRegion: false,
  },
  LV: {
    name: 'Latvia',
    iso2: 'LV',
    iso3: 'LVA',
    nationality: 'Latvian',
    code: '428',
    prefix: '371',
    flag: '🇱🇻',
    migrationId: 123,
    mainRegion: false,
  },
  LB: {
    name: 'Lebanon',
    iso2: 'LB',
    iso3: 'LBN',
    nationality: 'Lebanese',
    code: '422',
    prefix: '961',
    flag: '🇱🇧',
    migrationId: 124,
    mainRegion: false,
  },
  LS: {
    name: 'Lesotho',
    iso2: 'LS',
    iso3: 'LSO',
    nationality: 'Mosotho',
    code: '426',
    prefix: '266',
    flag: '🇱🇸',
    migrationId: 125,
    mainRegion: false,
  },
  LR: {
    name: 'Liberia',
    iso2: 'LR',
    iso3: 'LBR',
    nationality: 'Liberian',
    code: '430',
    prefix: '231',
    flag: '🇱🇷',
    migrationId: 126,
    mainRegion: false,
  },
  LY: {
    name: 'Libyan Arab Jamahiriya',
    iso2: 'LY',
    iso3: 'LBY',
    nationality: 'Libyan',
    code: '434',
    prefix: '218',
    flag: '🇱🇾',
    migrationId: 127,
    mainRegion: false,
  },
  LI: {
    name: 'Liechtenstein',
    iso2: 'LI',
    iso3: 'LIE',
    nationality: 'Liechtensteiner',
    code: '438',
    prefix: '423',
    flag: '🇱🇮',
    migrationId: 128,
    mainRegion: false,
  },
  LT: {
    name: 'Lithuania',
    iso2: 'LT',
    iso3: 'LTU',
    nationality: 'Lithuanian',
    code: '440',
    prefix: '370',
    flag: '🇱🇹',
    migrationId: 129,
    mainRegion: false,
  },
  LU: {
    name: 'Luxembourg',
    iso2: 'LU',
    iso3: 'LUX',
    nationality: 'Luxembourger',
    code: '442',
    prefix: '352',
    flag: '🇱🇺',
    migrationId: 130,
    mainRegion: false,
  },
  MO: {
    name: 'Macau',
    iso2: 'MO',
    iso3: 'MAC',
    nationality: '',
    code: '446',
    prefix: '853',
    flag: '🇲🇴',
    migrationId: 131,
    mainRegion: false,
  },
  MG: {
    name: 'Madagascar',
    iso2: 'MG',
    iso3: 'MDG',
    nationality: 'Malagasy',
    code: '450',
    prefix: '261',
    flag: '🇲🇬',
    migrationId: 132,
    mainRegion: false,
  },
  MW: {
    name: 'Malawi',
    iso2: 'MW',
    iso3: 'MWI',
    nationality: 'Malawian',
    code: '454',
    prefix: '265',
    flag: '🇲🇼',
    migrationId: 133,
    mainRegion: false,
  },
  MY: {
    name: 'Malaysia',
    iso2: 'MY',
    iso3: 'MYS',
    nationality: 'Malaysian',
    code: '458',
    prefix: '60',
    flag: '🇲🇾',
    migrationId: 134,
    mainRegion: false,
  },
  MV: {
    name: 'Maldives',
    iso2: 'MV',
    iso3: 'MDV',
    nationality: 'Maldivan',
    code: '462',
    prefix: '960',
    flag: '🇲🇻',
    migrationId: 135,
    mainRegion: false,
  },
  ML: {
    name: 'Mali',
    iso2: 'ML',
    iso3: 'MLI',
    nationality: 'Malian',
    code: '466',
    prefix: '223',
    flag: '🇲🇱',
    migrationId: 136,
    mainRegion: false,
  },
  MT: {
    name: 'Malta',
    iso2: 'MT',
    iso3: 'MLT',
    nationality: 'Maltese',
    code: '470',
    prefix: '356',
    flag: '🇲🇹',
    migrationId: 137,
    mainRegion: false,
  },
  MH: {
    name: 'Marshall Islands',
    iso2: 'MH',
    iso3: 'MHL',
    nationality: 'Marshallese',
    code: '584',
    prefix: '692',
    flag: '🇲🇭',
    migrationId: 138,
    mainRegion: false,
  },
  MQ: {
    name: 'Martinique',
    iso2: 'MQ',
    iso3: 'MTQ',
    nationality: '',
    code: '474',
    prefix: '596',
    flag: '🇲🇶',
    migrationId: 139,
    mainRegion: false,
  },
  MR: {
    name: 'Mauritania',
    iso2: 'MR',
    iso3: 'MRT',
    nationality: 'Mauritanian',
    code: '478',
    prefix: '222',
    flag: '🇲🇷',
    migrationId: 140,
    mainRegion: false,
  },
  MU: {
    name: 'Mauritius',
    iso2: 'MU',
    iso3: 'MUS',
    nationality: 'Mauritian',
    code: '480',
    prefix: '230',
    flag: '🇲🇺',
    migrationId: 141,
    mainRegion: false,
  },
  YT: {
    name: 'Mayotte',
    iso2: 'YT',
    iso3: 'MYT',
    nationality: '',
    code: '175',
    prefix: '262',
    flag: '🇾🇹',
    migrationId: 142,
    mainRegion: false,
  },
  MX: {
    name: 'Mexico',
    iso2: 'MX',
    iso3: 'MEX',
    nationality: 'Mexican',
    code: '484',
    prefix: '52',
    flag: '🇲🇽',
    migrationId: 143,
    mainRegion: false,
  },
  FM: {
    name: 'Micronesia\t Federated States of',
    iso2: 'FM',
    iso3: 'FSM',
    nationality: 'Micronesian',
    code: '583',
    prefix: '691',
    flag: '🇫🇲',
    migrationId: 144,
    mainRegion: false,
  },
  MC: {
    name: 'Monaco',
    iso2: 'MC',
    iso3: 'MCO',
    nationality: 'Monegasque or Monacan',
    code: '492',
    prefix: '377',
    flag: '🇲🇨',
    migrationId: 145,
    mainRegion: false,
  },
  MN: {
    name: 'Mongolia',
    iso2: 'MN',
    iso3: 'MNG',
    nationality: 'Mongolian',
    code: '496',
    prefix: '976',
    flag: '🇲🇳',
    migrationId: 146,
    mainRegion: false,
  },
  ME: {
    name: 'Montenegro',
    iso2: 'ME',
    iso3: 'MNE',
    nationality: 'Montenegrin',
    code: '499',
    prefix: '382',
    flag: '🇲🇪',
    migrationId: 147,
    mainRegion: false,
  },
  MS: {
    name: 'Montserrat',
    iso2: 'MS',
    iso3: 'MSR',
    nationality: '',
    code: '500',
    prefix: '1+664',
    flag: '🇲🇸',
    migrationId: 148,
    mainRegion: false,
  },
  MA: {
    name: 'Morocco',
    iso2: 'MA',
    iso3: 'MAR',
    nationality: 'Moroccan',
    code: '504',
    prefix: '212',
    flag: '🇲🇦',
    migrationId: 149,
    mainRegion: false,
  },
  MZ: {
    name: 'Mozambique',
    iso2: 'MZ',
    iso3: 'MOZ',
    nationality: 'Mozambican',
    code: '508',
    prefix: '258',
    flag: '🇲🇿',
    migrationId: 150,
    mainRegion: false,
  },
  NA: {
    name: 'Namibia',
    iso2: 'NA',
    iso3: 'NAM',
    nationality: 'Namibian',
    code: '516',
    prefix: '264',
    flag: '🇳🇦',
    migrationId: 151,
    mainRegion: false,
  },
  NR: {
    name: 'Nauru',
    iso2: 'NR',
    iso3: 'NRU',
    nationality: 'Nauruan',
    code: '520',
    prefix: '674',
    flag: '🇳🇷',
    migrationId: 152,
    mainRegion: false,
  },
  NP: {
    name: 'Nepal',
    iso2: 'NP',
    iso3: 'NPL',
    nationality: 'Nepalese',
    code: '524',
    prefix: '977',
    flag: '🇳🇵',
    migrationId: 153,
    mainRegion: false,
  },
  NL: {
    name: 'Netherlands',
    iso2: 'NL',
    iso3: 'NLD',
    nationality: 'Netherlander, Dutchman, Dutchwoman, Hollander or Dutch',
    code: '528',
    prefix: '31',
    flag: '🇳🇱',
    migrationId: 154,
    mainRegion: false,
  },
  AN: {
    name: 'Netherlands Antilles',
    iso2: 'AN',
    iso3: null,
    nationality: null,
    code: null,
    prefix: '599',
    flag: '🇧🇶',
    migrationId: 155,
    mainRegion: false,
  },
  NC: {
    name: 'New Caledonia',
    iso2: 'NC',
    iso3: 'NCL',
    nationality: '',
    code: '540',
    prefix: '687',
    flag: '🇳🇨',
    migrationId: 156,
    mainRegion: false,
  },
  NZ: {
    name: 'New Zealand',
    iso2: 'NZ',
    iso3: 'NZL',
    nationality: 'New Zealander or Kiwi',
    code: '554',
    prefix: '64',
    flag: '🇳🇿',
    migrationId: 157,
    mainRegion: true,
  },
  NI: {
    name: 'Nicaragua',
    iso2: 'NI',
    iso3: 'NIC',
    nationality: 'Nicaraguan',
    code: '558',
    prefix: '505',
    flag: '🇳🇮',
    migrationId: 158,
    mainRegion: false,
  },
  NE: {
    name: 'Niger',
    iso2: 'NE',
    iso3: 'NER',
    nationality: 'Nigerien',
    code: '562',
    prefix: '227',
    flag: '🇳🇪',
    migrationId: 159,
    mainRegion: false,
  },
  NG: {
    name: 'Nigeria',
    iso2: 'NG',
    iso3: 'NGA',
    nationality: 'Nigerian',
    code: '566',
    prefix: '234',
    flag: '🇳🇬',
    migrationId: 160,
    mainRegion: false,
  },
  NU: {
    name: 'Niue',
    iso2: 'NU',
    iso3: 'NIU',
    nationality: '',
    code: '570',
    prefix: '683',
    flag: '🇳🇺',
    migrationId: 161,
    mainRegion: false,
  },
  NF: {
    name: 'Norfolk Island',
    iso2: 'NF',
    iso3: 'NFK',
    nationality: '',
    code: '574',
    prefix: '672',
    flag: '🇳🇫',
    migrationId: 162,
    mainRegion: false,
  },
  MP: {
    name: 'Northern Mariana Islands',
    iso2: 'MP',
    iso3: 'MNP',
    nationality: '',
    code: '580',
    prefix: '1+670',
    flag: '🇲🇵',
    migrationId: 163,
    mainRegion: false,
  },
  NO: {
    name: 'Norway',
    iso2: 'NO',
    iso3: 'NOR',
    nationality: 'Norwegian',
    code: '578',
    prefix: '47',
    flag: '🇳🇴',
    migrationId: 164,
    mainRegion: false,
  },
  OM: {
    name: 'Oman',
    iso2: 'OM',
    iso3: 'OMN',
    nationality: 'Omani',
    code: '512',
    prefix: '968',
    flag: '🇴🇲',
    migrationId: 165,
    mainRegion: false,
  },
  PK: {
    name: 'Pakistan',
    iso2: 'PK',
    iso3: 'PAK',
    nationality: 'Pakistani',
    code: '586',
    prefix: '92',
    flag: '🇵🇰',
    migrationId: 166,
    mainRegion: false,
  },
  PW: {
    name: 'Palau',
    iso2: 'PW',
    iso3: 'PLW',
    nationality: 'Palauan',
    code: '585',
    prefix: '680',
    flag: '🇵🇼',
    migrationId: 167,
    mainRegion: false,
  },
  PS: {
    name: 'Palestine',
    iso2: 'PS',
    iso3: 'PSE',
    nationality: '',
    code: '275',
    prefix: '970',
    flag: '🇵🇸',
    migrationId: 168,
    mainRegion: false,
  },
  PA: {
    name: 'Panama',
    iso2: 'PA',
    iso3: 'PAN',
    nationality: 'Panamanian',
    code: '591',
    prefix: '507',
    flag: '🇵🇦',
    migrationId: 169,
    mainRegion: false,
  },
  PG: {
    name: 'Papua New Guinea',
    iso2: 'PG',
    iso3: 'PNG',
    nationality: 'Papua New Guinean',
    code: '598',
    prefix: '675',
    flag: '🇵🇬',
    migrationId: 170,
    mainRegion: false,
  },
  PY: {
    name: 'Paraguay',
    iso2: 'PY',
    iso3: 'PRY',
    nationality: 'Paraguayan',
    code: '600',
    prefix: '595',
    flag: '🇵🇾',
    migrationId: 171,
    mainRegion: false,
  },
  PE: {
    name: 'Peru',
    iso2: 'PE',
    iso3: 'PER',
    nationality: 'Peruvian',
    code: '604',
    prefix: '51',
    flag: '🇵🇪',
    migrationId: 172,
    mainRegion: false,
  },
  PH: {
    name: 'Philippines',
    iso2: 'PH',
    iso3: 'PHL',
    nationality: 'Filipino',
    code: '608',
    prefix: '63',
    flag: '🇵🇭',
    migrationId: 173,
    mainRegion: false,
  },
  PN: {
    name: 'Pitcairn Islands',
    iso2: 'PN',
    iso3: 'PCN',
    nationality: '',
    code: '612',
    prefix: null,
    flag: '🇵🇳',
    migrationId: 174,
    mainRegion: false,
  },
  PL: {
    name: 'Poland',
    iso2: 'PL',
    iso3: 'POL',
    nationality: 'Pole or Polish',
    code: '616',
    prefix: '48',
    flag: '🇵🇱',
    migrationId: 175,
    mainRegion: false,
  },
  PT: {
    name: 'Portugal',
    iso2: 'PT',
    iso3: 'PRT',
    nationality: 'Portuguese',
    code: '620',
    prefix: '351',
    flag: '🇵🇹',
    migrationId: 176,
    mainRegion: false,
  },
  PR: {
    name: 'Puerto Rico',
    iso2: 'PR',
    iso3: 'PRI',
    nationality: '',
    code: '630',
    prefix: '1+939',
    flag: '🇵🇷',
    migrationId: 177,
    mainRegion: false,
  },
  QA: {
    name: 'Qatar',
    iso2: 'QA',
    iso3: 'QAT',
    nationality: 'Qatari',
    code: '634',
    prefix: '974',
    flag: '🇶🇦',
    migrationId: 178,
    mainRegion: false,
  },
  MD: {
    name: 'Republic of Moldova',
    iso2: 'MD',
    iso3: 'MDA',
    nationality: 'Moldovan',
    code: '498',
    prefix: '373',
    flag: '🇲🇩',
    migrationId: 179,
    mainRegion: false,
  },
  RE: {
    name: 'Reunion',
    iso2: 'RE',
    iso3: 'REU',
    nationality: '',
    code: '638',
    prefix: '262',
    flag: '🇷🇪',
    migrationId: 180,
    mainRegion: false,
  },
  RO: {
    name: 'Romania',
    iso2: 'RO',
    iso3: 'ROU',
    nationality: 'Romanian',
    code: '642',
    prefix: '40',
    flag: '🇷🇴',
    migrationId: 181,
    mainRegion: false,
  },
  RU: {
    name: 'Russia',
    iso2: 'RU',
    iso3: 'RUS',
    nationality: 'Russian',
    code: '643',
    prefix: '7',
    flag: '🇷🇺',
    migrationId: 182,
    mainRegion: false,
  },
  RW: {
    name: 'Rwanda',
    iso2: 'RW',
    iso3: 'RWA',
    nationality: 'Rwandan',
    code: '646',
    prefix: '250',
    flag: '🇷🇼',
    migrationId: 183,
    mainRegion: false,
  },
  BL: {
    name: 'Saint Barthelemy',
    iso2: 'BL',
    iso3: 'BLM',
    nationality: '',
    code: '652',
    prefix: '590',
    flag: '🇧🇱',
    migrationId: 184,
    mainRegion: false,
  },
  SH: {
    name: 'Saint Helena',
    iso2: 'SH',
    iso3: 'SHN',
    nationality: '',
    code: '654',
    prefix: '290',
    flag: '🇸🇭',
    migrationId: 185,
    mainRegion: false,
  },
  KN: {
    name: 'Saint Kitts and Nevis',
    iso2: 'KN',
    iso3: 'KNA',
    nationality: 'Kittian and Nevisian',
    code: '659',
    prefix: '1+869',
    flag: '🇰🇳',
    migrationId: 186,
    mainRegion: false,
  },
  LC: {
    name: 'Saint Lucia',
    iso2: 'LC',
    iso3: 'LCA',
    nationality: 'Saint Lucian',
    code: '662',
    prefix: '1+758',
    flag: '🇱🇨',
    migrationId: 187,
    mainRegion: false,
  },
  MF: {
    name: 'Saint Martin',
    iso2: 'MF',
    iso3: 'MAF',
    nationality: '',
    code: '663',
    prefix: '590',
    flag: '🇲🇫',
    migrationId: 188,
    mainRegion: false,
  },
  PM: {
    name: 'Saint Pierre and Miquelon',
    iso2: 'PM',
    iso3: 'SPM',
    nationality: '',
    code: '666',
    prefix: '508',
    flag: '🇵🇲',
    migrationId: 189,
    mainRegion: false,
  },
  VC: {
    name: 'Saint Vincent and the Grenadines',
    iso2: 'VC',
    iso3: 'VCT',
    nationality: '',
    code: '670',
    prefix: '1+784',
    flag: '🇻🇨',
    migrationId: 190,
    mainRegion: false,
  },
  WS: {
    name: 'Samoa',
    iso2: 'WS',
    iso3: 'WSM',
    nationality: 'Samoan',
    code: '882',
    prefix: '685',
    flag: '🇼🇸',
    migrationId: 191,
    mainRegion: false,
  },
  SM: {
    name: 'San Marino',
    iso2: 'SM',
    iso3: 'SMR',
    nationality: 'Sammarinese or San Marinese',
    code: '674',
    prefix: '378',
    flag: '🇸🇲',
    migrationId: 192,
    mainRegion: false,
  },
  ST: {
    name: 'Sao Tome and Principe',
    iso2: 'ST',
    iso3: 'STP',
    nationality: 'Sao Tomean',
    code: '678',
    prefix: '239',
    flag: '🇸🇹',
    migrationId: 193,
    mainRegion: false,
  },
  SA: {
    name: 'Saudi Arabia',
    iso2: 'SA',
    iso3: 'SAU',
    nationality: 'Saudi or Saudi Arabian',
    code: '682',
    prefix: '966',
    flag: '🇸🇦',
    migrationId: 194,
    mainRegion: false,
  },
  SN: {
    name: 'Senegal',
    iso2: 'SN',
    iso3: 'SEN',
    nationality: 'Senegalese',
    code: '686',
    prefix: '221',
    flag: '🇸🇳',
    migrationId: 195,
    mainRegion: false,
  },
  RS: {
    name: 'Serbia',
    iso2: 'RS',
    iso3: 'SRB',
    nationality: 'Serbian',
    code: '688',
    prefix: '381',
    flag: '🇷🇸',
    migrationId: 196,
    mainRegion: false,
  },
  SC: {
    name: 'Seychelles',
    iso2: 'SC',
    iso3: 'SYC',
    nationality: 'Seychellois',
    code: '690',
    prefix: '248',
    flag: '🇸🇨',
    migrationId: 197,
    mainRegion: false,
  },
  SL: {
    name: 'Sierra Leone',
    iso2: 'SL',
    iso3: 'SLE',
    nationality: 'Sierra Leonean',
    code: '694',
    prefix: '232',
    flag: '🇸🇱',
    migrationId: 198,
    mainRegion: false,
  },
  SG: {
    name: 'Singapore',
    iso2: 'SG',
    iso3: 'SGP',
    nationality: 'Singaporean',
    code: '702',
    prefix: '65',
    flag: '🇸🇬',
    migrationId: 199,
    mainRegion: false,
  },
  SK: {
    name: 'Slovakia',
    iso2: 'SK',
    iso3: 'SVK',
    nationality: 'Slovak or Slovakian',
    code: '703',
    prefix: '421',
    flag: '🇸🇰',
    migrationId: 200,
    mainRegion: false,
  },
  SI: {
    name: 'Slovenia',
    iso2: 'SI',
    iso3: 'SVN',
    nationality: 'Slovene or Slovenian',
    code: '705',
    prefix: '386',
    flag: '🇸🇮',
    migrationId: 201,
    mainRegion: false,
  },
  SB: {
    name: 'Solomon Islands',
    iso2: 'SB',
    iso3: 'SLB',
    nationality: 'Solomon Islander',
    code: '090',
    prefix: '677',
    flag: '🇸🇧',
    migrationId: 202,
    mainRegion: false,
  },
  SO: {
    name: 'Somalia',
    iso2: 'SO',
    iso3: 'SOM',
    nationality: 'Somali',
    code: '706',
    prefix: '252',
    flag: '🇸🇴',
    migrationId: 203,
    mainRegion: false,
  },
  ZA: {
    name: 'South Africa',
    iso2: 'ZA',
    iso3: 'ZAF',
    nationality: 'South African',
    code: '710',
    prefix: '27',
    flag: '🇿🇦',
    migrationId: 204,
    mainRegion: false,
  },
  GS: {
    name: 'South Georgia South Sandwich Islands',
    iso2: 'GS',
    iso3: 'SGS',
    nationality: '',
    code: '239',
    prefix: '500',
    flag: '🇬🇸',
    migrationId: 205,
    mainRegion: false,
  },
  ES: {
    name: 'Spain',
    iso2: 'ES',
    iso3: 'ESP',
    nationality: 'Spaniard or Spanish',
    code: '724',
    prefix: '34',
    flag: '🇪🇸',
    migrationId: 206,
    mainRegion: false,
  },
  LK: {
    name: 'Sri Lanka',
    iso2: 'LK',
    iso3: 'LKA',
    nationality: 'Sri Lankan',
    code: '144',
    prefix: '94',
    flag: '🇱🇰',
    migrationId: 207,
    mainRegion: false,
  },
  SD: {
    name: 'Sudan',
    iso2: 'SD',
    iso3: 'SDN',
    nationality: 'Sudanese',
    code: '729',
    prefix: '249',
    flag: '🇸🇩',
    migrationId: 208,
    mainRegion: false,
  },
  SR: {
    name: 'Suriname',
    iso2: 'SR',
    iso3: 'SUR',
    nationality: 'Surinamer',
    code: '740',
    prefix: '597',
    flag: '🇸🇷',
    migrationId: 209,
    mainRegion: false,
  },
  SJ: {
    name: 'Svalbard',
    iso2: 'SJ',
    iso3: 'SJM',
    nationality: '',
    code: '744',
    prefix: '47',
    flag: '🇸🇯',
    migrationId: 210,
    mainRegion: false,
  },
  SZ: {
    name: 'Swaziland',
    iso2: 'SZ',
    iso3: 'SWZ',
    nationality: 'Swazi',
    code: '748',
    prefix: '268',
    flag: '🇸🇿',
    migrationId: 211,
    mainRegion: false,
  },
  SE: {
    name: 'Sweden',
    iso2: 'SE',
    iso3: 'SWE',
    nationality: 'Swede or Swedish',
    code: '752',
    prefix: '46',
    flag: '🇸🇪',
    migrationId: 212,
    mainRegion: false,
  },
  CH: {
    name: 'Switzerland',
    iso2: 'CH',
    iso3: 'CHE',
    nationality: 'Swiss',
    code: '756',
    prefix: '41',
    flag: '🇨🇭',
    migrationId: 213,
    mainRegion: false,
  },
  SY: {
    name: 'Syrian Arab Republic',
    iso2: 'SY',
    iso3: 'SYR',
    nationality: 'Syrian',
    code: '760',
    prefix: '963',
    flag: '🇸🇾',
    migrationId: 214,
    mainRegion: false,
  },
  TW: {
    name: 'Taiwan',
    iso2: 'TW',
    iso3: 'TWN',
    nationality: 'Taiwanese',
    code: '158',
    prefix: '886',
    flag: '🇹🇼',
    migrationId: 215,
    mainRegion: false,
  },
  TJ: {
    name: 'Tajikistan',
    iso2: 'TJ',
    iso3: 'TJK',
    nationality: 'Tajik or Tadzhik',
    code: '762',
    prefix: '992',
    flag: '🇹🇯',
    migrationId: 216,
    mainRegion: false,
  },
  TH: {
    name: 'Thailand',
    iso2: 'TH',
    iso3: 'THA',
    nationality: 'Thai',
    code: '764',
    prefix: '66',
    flag: '🇹🇭',
    migrationId: 217,
    mainRegion: false,
  },
  MK: {
    name: 'The former Yugoslav Republic of Macedonia',
    iso2: 'MK',
    iso3: 'MKD',
    nationality: 'Macedonian',
    code: '807',
    prefix: '389',
    flag: '🇲🇰',
    migrationId: 218,
    mainRegion: false,
  },
  TL: {
    name: 'Timor-Leste',
    iso2: 'TL',
    iso3: 'TLS',
    nationality: 'East Timorese',
    code: '626',
    prefix: '670',
    flag: '🇹🇱',
    migrationId: 219,
    mainRegion: false,
  },
  TG: {
    name: 'Togo',
    iso2: 'TG',
    iso3: 'TGO',
    nationality: 'Togolese',
    code: '768',
    prefix: '228',
    flag: '🇹🇬',
    migrationId: 220,
    mainRegion: false,
  },
  TK: {
    name: 'Tokelau',
    iso2: 'TK',
    iso3: 'TKL',
    nationality: '',
    code: '772',
    prefix: '690',
    flag: '🇹🇰',
    migrationId: 221,
    mainRegion: false,
  },
  TO: {
    name: 'Tonga',
    iso2: 'TO',
    iso3: 'TON',
    nationality: 'Tongan',
    code: '776',
    prefix: '676',
    flag: '🇹🇴',
    migrationId: 222,
    mainRegion: false,
  },
  TT: {
    name: 'Trinidad and Tobago',
    iso2: 'TT',
    iso3: 'TTO',
    nationality: 'Trinidadian or Tobagonian',
    code: '780',
    prefix: '1+868',
    flag: '🇹🇹',
    migrationId: 223,
    mainRegion: false,
  },
  TN: {
    name: 'Tunisia',
    iso2: 'TN',
    iso3: 'TUN',
    nationality: 'Tunisian',
    code: '788',
    prefix: '216',
    flag: '🇹🇳',
    migrationId: 224,
    mainRegion: false,
  },
  TR: {
    name: 'Turkey',
    iso2: 'TR',
    iso3: 'TUR',
    nationality: 'Turk or Turkish',
    code: '792',
    prefix: '90',
    flag: '🇹🇷',
    migrationId: 225,
    mainRegion: false,
  },
  TM: {
    name: 'Turkmenistan',
    iso2: 'TM',
    iso3: 'TKM',
    nationality: 'Turkmen',
    code: '795',
    prefix: '993',
    flag: '🇹🇲',
    migrationId: 226,
    mainRegion: false,
  },
  TC: {
    name: 'Turks and Caicos Islands',
    iso2: 'TC',
    iso3: 'TCA',
    nationality: '',
    code: '796',
    prefix: '1+649',
    flag: '🇹🇨',
    migrationId: 227,
    mainRegion: false,
  },
  TV: {
    name: 'Tuvalu',
    iso2: 'TV',
    iso3: 'TUV',
    nationality: 'Tuvaluan',
    code: '798',
    prefix: '688',
    flag: '🇹🇻',
    migrationId: 228,
    mainRegion: false,
  },
  UG: {
    name: 'Uganda',
    iso2: 'UG',
    iso3: 'UGA',
    nationality: 'Ugandan',
    code: '800',
    prefix: '256',
    flag: '🇺🇬',
    migrationId: 229,
    mainRegion: false,
  },
  UA: {
    name: 'Ukraine',
    iso2: 'UA',
    iso3: 'UKR',
    nationality: 'Ukrainian',
    code: '804',
    prefix: '380',
    flag: '🇺🇦',
    migrationId: 230,
    mainRegion: false,
  },
  AE: {
    name: 'United Arab Emirates',
    iso2: 'AE',
    iso3: 'ARE',
    nationality: 'Emirian',
    code: '784',
    prefix: '971',
    flag: '🇦🇪',
    migrationId: 231,
    mainRegion: false,
  },
  GB: {
    name: 'United Kingdom',
    iso2: 'GB',
    iso3: 'GBR',
    nationality: 'Briton or British',
    code: '826',
    prefix: '44',
    flag: '🇬🇧',
    migrationId: 232,
    mainRegion: true,
  },
  TZ: {
    name: 'United Republic of Tanzania',
    iso2: 'TZ',
    iso3: 'TZA',
    nationality: 'Tanzanian',
    code: '834',
    prefix: '255',
    flag: '🇹🇿',
    migrationId: 233,
    mainRegion: false,
  },
  US: {
    name: 'United States',
    iso2: 'US',
    iso3: 'USA',
    nationality: 'American',
    code: '840',
    prefix: '1',
    flag: '🇺🇸',
    migrationId: 234,
    mainRegion: true,
  },
  UM: {
    name: 'United States Minor Outlying Islands',
    iso2: 'UM',
    iso3: 'UMI',
    nationality: '',
    code: '581',
    prefix: null,
    flag: '🇺🇲',
    migrationId: 235,
    mainRegion: false,
  },
  VI: {
    name: 'United States Virgin Islands',
    iso2: 'VI',
    iso3: 'VIR',
    nationality: '',
    code: '850',
    prefix: '1+340',
    flag: '🇻🇮',
    migrationId: 236,
    mainRegion: false,
  },
  UY: {
    name: 'Uruguay',
    iso2: 'UY',
    iso3: 'URY',
    nationality: 'Uruguayan',
    code: '858',
    prefix: '598',
    flag: '🇺🇾',
    migrationId: 237,
    mainRegion: false,
  },
  UZ: {
    name: 'Uzbekistan',
    iso2: 'UZ',
    iso3: 'UZB',
    nationality: 'Uzbek or Uzbekistani',
    code: '860',
    prefix: '998',
    flag: '🇺🇿',
    migrationId: 238,
    mainRegion: false,
  },
  VU: {
    name: 'Vanuatu',
    iso2: 'VU',
    iso3: 'VUT',
    nationality: 'Ni-Vanuatu',
    code: '548',
    prefix: '678',
    flag: '🇻🇺',
    migrationId: 239,
    mainRegion: false,
  },
  VE: {
    name: 'Venezuela',
    iso2: 'VE',
    iso3: 'VEN',
    nationality: 'Venezuelan',
    code: '862',
    prefix: '58',
    flag: '🇻🇪',
    migrationId: 240,
    mainRegion: false,
  },
  VN: {
    name: 'Viet Nam',
    iso2: 'VN',
    iso3: 'VNM',
    nationality: 'Vietnamese',
    code: '704',
    prefix: '84',
    flag: '🇻🇳',
    migrationId: 241,
    mainRegion: false,
  },
  WF: {
    name: 'Wallis and Futuna Islands',
    iso2: 'WF',
    iso3: 'WLF',
    nationality: '',
    code: '876',
    prefix: '681',
    flag: '🇼🇫',
    migrationId: 242,
    mainRegion: false,
  },
  EH: {
    name: 'Western Sahara',
    iso2: 'EH',
    iso3: 'ESH',
    nationality: '',
    code: '732',
    prefix: '212',
    flag: '🇪🇭',
    migrationId: 243,
    mainRegion: false,
  },
  YE: {
    name: 'Yemen',
    iso2: 'YE',
    iso3: 'YEM',
    nationality: 'Yemeni or Yemenite',
    code: '887',
    prefix: '967',
    flag: '🇾🇪',
    migrationId: 244,
    mainRegion: false,
  },
  ZM: {
    name: 'Zambia',
    iso2: 'ZM',
    iso3: 'ZMB',
    nationality: 'Zambian',
    code: '894',
    prefix: '260',
    flag: '🇿🇲',
    migrationId: 245,
    mainRegion: false,
  },
  ZW: {
    name: 'Zimbabwe',
    iso2: 'ZW',
    iso3: 'ZWE',
    nationality: 'Zimbabwean',
    code: '716',
    prefix: '263',
    flag: '🇿🇼',
    migrationId: 246,
    mainRegion: false,
  },
}

export const otherCountry: CountryMapType = {
  name: '(Other)',
  iso2: 'other',
  iso3: null,
  nationality: null,
  code: null,
  prefix: null,
  flag: '🏳️',
  migrationId: 0,
  mainRegion: false,
}

export const countries = Object.keys(countryMapping).map((iso) => {
  return {
    name: countryMapping[iso].name,
    id: iso,
  }
})

export const countriesWithCountryCode = Object.keys(countryMapping).filter((c) => countryMapping[c].prefix !== null)

// export const allCountriesWithDisplayOptions = [...countries, { name: '(Other)', id: 'other' }]

export const countriesWhichRequireTermsOptIn = [
  'BE',
  'EL',
  'LT',
  'PT',
  'BG',
  'ES',
  'LU',
  'RO',
  'CZ',
  'FR',
  'HU',
  'SI',
  'DK',
  'HR',
  'MT',
  'SK',
  'DE',
  'IT',
  'NL',
  'FI',
  'EE',
  'CY',
  'AT',
  'SE',
  'IE',
  'LV',
  'PL',
  'AU',
  'KR',
  'UK',
  'GB',
  'GP',
  'GF',
  'MQ',
  'RE',
  'MF',
]

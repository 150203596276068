import { makeStyles } from '@material-ui/core'
import React, { ReactNode } from 'react'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      fontSize: 12,
      margin: '2px 0',
    },
    label: {
      fontWeight: theme.typography.fontWeightBold,
    },
  }),
  { name: 'Tinylabel' }
)

interface PropTypes {
  label?: ReactNode
}

export const TinyLabel: React.FC<PropTypes> = ({ label, children }) => {
  const classes = useStyles()

  return (
    <p className={classes.root}>
      {label && <span className={classes.label}>{label}&nbsp;</span>}
      {children}
    </p>
  )
}

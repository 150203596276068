import { makeStyles, Tooltip } from '@material-ui/core'
import { useViewShow } from 'ducks/viewMode'
import { IconButton } from 'opensolar-ui'
import { FC } from 'react'
import { TooltipsConfigs } from '../../util/TooltipsConfigs'
/**
 * This component is for the small white icon buttons at the
 * bottom right hand side on the map view.
 */

const useStyles = makeStyles<any, { selected: boolean; disabled: boolean }>({
  iconButton: ({ selected, disabled }) => {
    return {
      background: disabled ? 'rgba(208, 208, 208, 0.8)' : selected ? 'rgba(24, 144, 255,0.9)' : 'rgba(255,255,255, 1)',
      borderRadius: 0,
      color: 'black',
      verticalAlign: 'bottom',
      padding: 0,
      margin: 1,
      width: 30,
      height: 30,
      '&:hover': {
        background: selected ? 'rgba(24, 144, 255,1)' : 'rgba(250,250,250,0.9)',
      },
    }
  },
  disabled: {},
})

type ToolButtonProps = {
  id?: string
  title: string
  selected?: boolean
  disabled?: boolean
  onClick: () => void
  uiKey?: string
}

export const ToolButton: FC<ToolButtonProps> = ({ id, title, children, selected, disabled, onClick, uiKey }) => {
  const classes = useStyles({ selected: !!selected, disabled: !!disabled })

  const show = useViewShow(uiKey)
  if (!show) {
    return null
  }

  return (
    <Tooltip {...TooltipsConfigs} title={title}>
      <span>
        <IconButton
          id={id}
          classes={{
            root: classes.iconButton,
            disabled: classes.disabled,
          }}
          disabled={disabled}
          onClick={onClick}
        >
          {children}
        </IconButton>
      </span>
    </Tooltip>
  )
}

export default ToolButton

import { SystemContext } from 'Designer/designRules/types'
import lodash from 'lodash'
import {
  AssociationType,
  ComponentDependencies,
  NonModuleComponentWithQty,
  ParentComponents,
} from 'types/associatedComponents'
import { EffectFactory } from '.'
import { getRequiredQty } from '../utils'

export type TrackComponentAssociationEffectType = {
  type: 'track-component-association'
  parentComponents: ParentComponents
  component: NonModuleComponentWithQty
  associationType: AssociationType
}

function trackComponentAssociation(def: TrackComponentAssociationEffectType, context: SystemContext) {
  // Update custom_data.component_dependencies ensuring it reflects changes to the component which triggered the effect, and it's associated components
  // If there are multiple parentComponents, they must BOTH be present in the system

  const requiredQty = getRequiredQty(def.parentComponents, def.component, context.system)

  if (!context.system.custom_data['component_dependencies']) context.system.custom_data['component_dependencies'] = []

  const dependencies: ComponentDependencies = context.system.custom_data['component_dependencies']

  let hasChanges = false

  const dependenciesWithSameParents = dependencies.filter((dependency) => {
    if (dependency.source !== context.systemRuleKey) return false

    let parentCodes = dependency.parentComponents.map((comp) => comp.code + '-' + comp.qty).sort()
    let parentCodesToTrack = def.parentComponents.map((comp) => comp.code + '-' + comp.qty).sort()
    const parentsAreSame = lodash.isEqual(parentCodes, parentCodesToTrack)
    return (
      parentsAreSame &&
      (requiredQty !== null ||
        dependency[def.associationType]?.find((component) => component.code === def.component.code))
    )
  })
  if (dependenciesWithSameParents.length) {
    const dependency = dependenciesWithSameParents[0] // There should only ever be one dependency with the exact same parents and parent quantities
    if (requiredQty === null) {
      if (def.associationType === 'require') {
        // Delete the automatically added component
        const association = dependency[def.associationType]?.find(
          (componentAssociation) => componentAssociation.code === def.component.code
        )
        if (association?.componentUuid) {
          const component = window.editor.objectByUuid(association.componentUuid)
          if (component) window.editor.deleteObject(component)
        }
      }
      const newDependencies = dependency[def.associationType]?.filter(
        (componentAssociation) => componentAssociation.code !== def.component.code
      )
      if (newDependencies?.length !== dependency[def.associationType]?.length) {
        hasChanges = true
        dependency[def.associationType] = newDependencies
      }
      if (!dependency['recommend']?.length && !dependency['require']?.length) {
        // Remove dependency if no associations remain
        dependencies.splice(dependencies.indexOf(dependency), 1)
        hasChanges = true
      }
    } else {
      const matchingAssociations = dependency[def.associationType]?.filter(
        (componentAssociation) => componentAssociation.code === def.component.code
      )
      if (matchingAssociations?.length) {
        if (matchingAssociations.length > 1)
          console.warn(
            "Design Rule 'track-component-association' has duplicate entries in the feature config which may lead to unexpected behaviour"
          )
        const matchingAssociation = matchingAssociations[0]
        hasChanges = matchingAssociation.totalCalculatedQty !== requiredQty
        matchingAssociation.totalCalculatedQty = requiredQty
      } else {
        if (!dependency[def.associationType]) dependency[def.associationType] = []
        dependency[def.associationType]?.push({
          ...def.component,
          totalCalculatedQty: requiredQty,
        })
        hasChanges = true
      }
    }
  } else if (requiredQty === null) {
    // Not added and shouldn't add, abort
    return
  } else {
    hasChanges = true
    dependencies.push({
      source: context.systemRuleKey,
      parentComponents: def.parentComponents,
      [def.associationType]: [{ ...def.component, totalCalculatedQty: requiredQty }],
    })
  }
  if (!hasChanges) {
    //no changes, aborting
    return
  }
  const newCustomData = { ...context.system.custom_data, component_dependencies: dependencies }
  window.editor.execute(new window.SetValueCommand(context.system, 'custom_data', newCustomData))
}

export const effect_trackComponentAssociation: EffectFactory<TrackComponentAssociationEffectType> = (def) => {
  return {
    execute: async (context: SystemContext) => {
      trackComponentAssociation(def, context)
    },
  }
}

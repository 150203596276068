import { makeStyles } from '@material-ui/core'
import MuiTooltip, { TooltipProps } from '@material-ui/core/Tooltip'
import { TransitionNone } from 'elements/TransitionNone'
import React from 'react'
import { OpenSolarThemeType } from 'Themes'

export type OsTooltipPropsType = Omit<TooltipProps, 'title'> & TooltipCustomProp

const useStyles = makeStyles<OpenSolarThemeType, { fullWidth?: boolean; color?: 'primary' | 'secondary' }>((theme) => ({
  tooltip: ({ fullWidth, color }) => ({
    maxWidth: fullWidth ? '100%' : 400,
    backgroundColor: color === 'secondary' ? theme.greyDark2 : theme.white,
    color: color === 'secondary' ? theme.white : theme.greyDark2,
  }),
}))

type TooltipCustomProp = {
  fullWidth?: boolean
  disabled?: boolean
  title?: NonNullable<React.ReactNode>
  color?: 'primary' | 'secondary'
}

// This is identical to Material Tooltip, leave the component for now in case we want to do more customization
const Tooltip: React.FC<OsTooltipPropsType> = ({ children, fullWidth, title, color, ...rest }) => {
  const classes = useStyles({ fullWidth, color })
  if (title === undefined) return <>{children}</>
  return (
    <MuiTooltip
      classes={classes}
      title={title}
      enterTouchDelay={10} // for touch device
      arrow
      {...rest}
      TransitionComponent={TransitionNone}
    >
      {children}
    </MuiTooltip>
  )
}

export default Tooltip

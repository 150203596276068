import { styled } from 'opensolar-ui'
import { useForm, useFormState } from 'react-final-form'
import DiscardButton from '../header/actions/DiscardButton'
import SaveButton from '../header/actions/SaveButton'

const StyledDiv = styled('div')(
  {
    height: 50,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  { name: 'MobileDesignPageActions', slot: 'container' }
)
const MobileDesignPageActions = ({ submitting }: { submitting: boolean }) => {
  const form = useForm()
  useFormState() //hacky, but without adding a useFormState() here this cmponent does not reliably listen notice dirty fields if they're changed via form.change()
  const dirtyFields = form.mutators.getFormDirtyFields()
  const disableActions = dirtyFields?.length === 0

  return (
    <StyledDiv>
      {!disableActions && (
        <>
          <DiscardButton submitting={submitting} />
          <SaveButton size="small" submitting={submitting} />
        </>
      )}
    </StyledDiv>
  )
}
export default MobileDesignPageActions

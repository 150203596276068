import { Divider, Grid, TextField, Tooltip } from '@material-ui/core'
import { Info, SaveOutlined } from '@material-ui/icons'
import { orgSelectors } from 'ducks/orgs'
import LoadingDots from 'layout/widgets/LoadingDots'
import { Button } from 'opensolar-ui'
import CashFlowSetupChecklist from 'pages/cashFlow/sharedComponents/CashFlowSetupChecklist'
import { useNotify, useTranslate } from 'ra-core'
import React, { useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { getIsValidEmail } from 'util/misc'
import { saveEmailConfiguration, useGetEmailConfiguration } from '../customerCommunications/utils'
import RecipientToggle from './RecipientToggle'

type PropTypes = {}

type OtherRecipientsString = {
  pmntActionRoundUpRecipients: string
  pmntProcessedDailyOtherRecipients: string
  custViewedOtherRecipients: string
}

const useStyles = makeOpenSolarStyles((theme) => ({
  container: {
    background: theme.white,
    padding: '24px',
    border: '1px solid #e7e7e7',
    borderRadius: '5px',
    margin: '1rem 0',
    lineHeight: 1.5,
    marginBottom: '2rem',
  },

  justifyCenter: {
    justifyContent: 'center',
    lineHeight: 1.5,
  },

  header: {
    marginTop: 20,
    fontSize: 18,
    fontWeight: 400,
  },

  subHeader: {
    margin: 0,
    fontWeight: 500,
    fontSize: 16,
  },

  subHeader2: {
    fontWeight: 500,
    fontSize: 15,
  },

  flexRow: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    margin: '25px 0 5px 0',
  },

  row: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },

  divider: {
    margin: '24px 0',
  },

  field: {
    margin: '8px 0',
  },

  addEmailsContainer: {
    padding: '20px 0 40px',
  },

  infoIcon: {
    fontSize: 18,
    color: theme.greyMid1,
  },

  buttonsContainer: {
    display: 'flex',
    justifyContent: 'end',
    margin: '1.5rem 0',
  },
}))

const InternalNotificationsPage: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const translate = useTranslate()

  const orgId = useSelector(orgSelectors.getOrg)?.id
  const { emailConfiguration, emailConfigLoading } = useGetEmailConfiguration()

  const [custViewedProjRecipients, setCustViewedProjRecipients] = useState<string[]>([])
  const [custViewedOtherRecipients, setCustViewedOtherRecipients] = useState<string[]>([])
  const [pmntProcessedDailyOtherRecipients, setpmntProcessedDailyOtherRecipients] = useState<string[]>([])
  const [pmntActionRoundUpRecipients, setPmntActionRoundupRecipients] = useState<string[]>([])

  const [otherRecipientsString, setOtherRecipientsString] = useState<OtherRecipientsString>({
    pmntActionRoundUpRecipients: '',
    pmntProcessedDailyOtherRecipients: '',
    custViewedOtherRecipients: '',
  })

  useMemo(() => {
    if (!emailConfigLoading && emailConfiguration) {
      const emailConfig = emailConfiguration['email_templates']

      setCustViewedProjRecipients(emailConfig?.customer_viewed_payment_link.project_recipients)
      setCustViewedOtherRecipients(emailConfig?.customer_viewed_payment_link.other_recipients)
      setpmntProcessedDailyOtherRecipients(emailConfig?.payments_processed_daily.other_recipients)
      setPmntActionRoundupRecipients(emailConfig?.payment_action_roundup.other_recipients)

      setOtherRecipientsString({
        pmntActionRoundUpRecipients: (emailConfig?.payment_action_roundup.other_recipients || []).join(', '),
        pmntProcessedDailyOtherRecipients: (emailConfig?.payments_processed_daily.other_recipients || []).join(', '),
        custViewedOtherRecipients: (emailConfig?.customer_viewed_payment_link.other_recipients || []).join(', '),
      })
    }
  }, [emailConfigLoading])

  const onSave = () => {
    const data = {
      email_templates: {
        customer_viewed_payment_link: {
          project_recipients: custViewedProjRecipients,
          other_recipients: custViewedOtherRecipients,
        },
        payments_processed_daily: {
          other_recipients: pmntProcessedDailyOtherRecipients,
        },
        payment_action_roundup: {
          other_recipients: pmntActionRoundUpRecipients,
        },
      },
    }

    if (orgId) {
      saveEmailConfiguration(orgId, data)
        .then((res) => {
          notify(translate('Your updates have been saved'), 'success')
        })
        .catch((err) => {
          if (err?.body?.message) notify(err.body.message, 'warning')
          else if (err?.body?.non_field_errors?.length) notify(err.body.non_field_errors[0], 'warning')
          else notify('Something went wrong', 'error')
        })
    }
  }

  const addOtherRecipients = (emailString, recipientKey, setOtherRecipients) => {
    setOtherRecipientsString((prev) => ({
      ...prev,
      [recipientKey]: emailString,
    }))

    const emailArr = emailString.split(',').map((email) => email.trim())
    const validEmails = emailArr.filter((email) => email !== '' && getIsValidEmail(email))
    setOtherRecipients(validEmails)
  }

  return (
    <Form
      onSubmit={() => {}}
      render={(formProps) => (
        <Grid container spacing={2} className={classes.justifyCenter}>
          {!custViewedProjRecipients ? (
            <LoadingDots />
          ) : (
            <>
              <Grid item xs={11} lg={8}>
                <div>
                  <h1 className={classes.header}>{translate('Internal Project Notifications')}</h1>
                  <p>
                    {translate(
                      'CashFlow automatically sends internal notifications that help your team to manage payments. Use the settings below to configure who at your organization should receive each of these notification types.'
                    )}
                  </p>
                  <div className={classes.container}>
                    <h1 className={classes.subHeader}>{translate('Customer Viewed Payment Link')}</h1>
                    <p>
                      {translate(
                        'Email sent in situation where customer views payment link but does not complete payment after 15 minutes'
                      )}
                    </p>

                    <div className={classes.flexRow}>
                      <p className={classes.subHeader2}>{translate("Select Project Contacts to be BCC'ed")}</p>
                      <Tooltip
                        title={translate(
                          "Select which project contacts should receive emails sent based on project-level activity. Add any email addresses in the 'Add other email addresses' box for contacts who should receive all emails of this type"
                        )}
                        enterDelay={300}
                        className={classes.infoIcon}
                      >
                        <Info />
                      </Tooltip>
                    </div>

                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <RecipientToggle
                          isToggledOn={custViewedProjRecipients?.includes('assigned_team_member')}
                          label="assigned_team_member"
                          setEmailBcc={setCustViewedProjRecipients}
                        />
                        <RecipientToggle
                          isToggledOn={custViewedProjRecipients?.includes('assigned_salesperson')}
                          label="assigned_salesperson"
                          setEmailBcc={setCustViewedProjRecipients}
                        />
                        <RecipientToggle
                          isToggledOn={custViewedProjRecipients?.includes('assigned_installer')}
                          label="assigned_installer"
                          setEmailBcc={setCustViewedProjRecipients}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <RecipientToggle
                          isToggledOn={custViewedProjRecipients?.includes('assigned_designer')}
                          label="assigned_designer"
                          setEmailBcc={setCustViewedProjRecipients}
                        />
                        <RecipientToggle
                          isToggledOn={custViewedProjRecipients?.includes('assigned_site_inspector')}
                          label="assigned_site_inspector"
                          setEmailBcc={setCustViewedProjRecipients}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <p>{translate('Add other email addresses')}</p>
                        <TextField
                          size="small"
                          variant="outlined"
                          name="custViewed_emails"
                          fullWidth
                          multiline={true}
                          rows={3}
                          placeholder={translate('Add email addresses separated by comma')}
                          value={otherRecipientsString.custViewedOtherRecipients}
                          onChange={(e) =>
                            addOtherRecipients(
                              e.target.value,
                              'custViewedOtherRecipients',
                              setCustViewedOtherRecipients
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div>
                  <h1 className={classes.header}>{translate('Internal Admin Notifications')}</h1>
                  <div className={classes.container}>
                    <h1 className={classes.subHeader}>{translate('Payments Processed Daily Digest')}</h1>
                    <p>
                      {translate(
                        'Daily email sent to your team showing all of the payments received for that day. This will only be sent on days where a payment has reached your bank account. By default this will be sent to Admin users. Other email addresses can be added below.'
                      )}
                    </p>
                    <div className={classes.addEmailsContainer}>
                      <p>{translate('Add other email addresses')}</p>
                      <TextField
                        size="small"
                        variant="outlined"
                        fullWidth
                        name="pmntProcessed_emails"
                        multiline={true}
                        rows={3}
                        placeholder={translate('Add email addresses separated by comma')}
                        value={otherRecipientsString.pmntProcessedDailyOtherRecipients}
                        onChange={(e) =>
                          addOtherRecipients(
                            e.target.value,
                            'pmntProcessedDailyOtherRecipients',
                            setpmntProcessedDailyOtherRecipients
                          )
                        }
                      />
                    </div>

                    <Divider className={classes.divider} />

                    <h1 className={classes.subHeader}>{translate('Payment Action Roundup')}</h1>
                    <p>
                      {translate(
                        'Weekly email summarizing all payment activity from the prior week and showing invoices that require attention. By default this will be sent to Admin users. Other email addresses can be added below.'
                      )}
                    </p>
                    <div className={classes.addEmailsContainer}>
                      <p>{translate('Add other email addresses')}</p>
                      <TextField
                        size="small"
                        variant="outlined"
                        fullWidth
                        name="pmntActionRoundup_emails"
                        multiline={true}
                        rows={3}
                        placeholder={translate('Add email addresses separated by comma')}
                        value={otherRecipientsString.pmntActionRoundUpRecipients}
                        onChange={(e) =>
                          addOtherRecipients(
                            e.target.value,
                            'pmntActionRoundUpRecipients',
                            setPmntActionRoundupRecipients
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.buttonsContainer}>
                  <Button variant="contained" color="primary" onClick={onSave}>
                    <SaveOutlined /> {translate('Save')}
                  </Button>
                </div>
              </Grid>
              <Grid item xs={11} lg={3}>
                <CashFlowSetupChecklist activeStep={4} />
              </Grid>
            </>
          )}
        </Grid>
      )}
    />
  )
}

export default InternalNotificationsPage

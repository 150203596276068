import { Grid } from '@material-ui/core'
import { countryMapping } from 'constants/countries'
import { orgSelectors } from 'ducks/orgs'
import CustomField from 'elements/field/CustomField'
import CheckboxInput from 'elements/input/CheckboxInput'
import { useCountryListsTranslated } from 'hooks/useCountryListsTranslated'
import _ from 'lodash'
import { useTranslate } from 'ra-core'
import { SelectInput, TextInput } from 'ra-ui-materialui'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import DateOfBirthInputGroup from './DateOfBirthInputGroup'
import OnboardingAddressAutocomplete from './OnboardingAddressAutocomplete'
import {
  CONTACT_FIELD_MAP,
  businessAddressFields,
  businessOwnerAddressFields,
  companyRepAddressFields,
} from './constants'
import { FieldPrefixFilterType, PSPOnboardingFieldType } from './types'
import { getContactsAreEqual, getNormalizeBlueSnapContact, getValidationError } from './utils'

type PropTypes = {
  field: PSPOnboardingFieldType
  fieldPrefixFilter: FieldPrefixFilterType
}

const useStyles = makeOpenSolarStyles((theme) => ({
  termsWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  fieldWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  countryCode: {
    flex: 2,
  },
  field: {
    flex: 6,
  },
}))

const OnboardingField: React.FC<PropTypes> = (props) => {
  const [fieldHasBeenPrepopulated, setFieldHasBeenPrepoulated] = useState<boolean>(false)
  const [sharedContactPrefix, setSharedContactPrefix] = useState<FieldPrefixFilterType>(null)
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const classes = useStyles()
  const formState = useFormState()
  const form = useForm()
  const fieldValue = _.get(formState.values, props.field.field_name)
  const org = useSelector(orgSelectors.getOrg)
  const translate = useTranslate()
  const { countriesWithPrefix } = useCountryListsTranslated()

  const error = getValidationError()
  const hasBusinessAddressError = businessAddressFields.some((field) => error.includes(field))
  const hasCompanyRepAddressError = companyRepAddressFields.some((field) => error.includes(field))
  const hasOwnnerAddressError = businessOwnerAddressFields.some((field) => error.includes(field))

  // we don't want to initialize the form with these values since the form is broken up into multiple pages
  // and we might add and then save a value to a field that the user doesn't see yet. So instead we'll wait until
  // the field renders and if there is no value already in the field we will pre-populate it with what's saved in the org
  const prepopulatedValue = useMemo(() => {
    if (props.field.initial_value_field_path) {
      if (org && props.field.initial_value_field_path.startsWith('org.')) {
        return _.get(org, props.field.initial_value_field_path.replace('org.', ''))
      }
    }
    return undefined
  }, [org, props.field.default_value, props.field.initial_value_field_path])

  const hideField = useMemo(() => {
    // some fields are always hidden based on their config
    if (props.field.is_hidden) return true
    // some are hidden based on props.fieldPrefixFilter which is populated as a result of user action
    else if (props.fieldPrefixFilter && !props.field.field_name.startsWith(props.fieldPrefixFilter)) {
      return true
    }
    // ownership and companyRep fields are hidden by default UNLESS they match on fieldPrefixFilter
    else if (props.field.field_name.startsWith('companyRep') || props.field.field_name.startsWith('ownershipInfoArr')) {
      if (!props.fieldPrefixFilter) return true
      // if the org is in the US but this contact has chosen another country we should hide the state field which is no longer applicable
      if (props.field.field_name?.includes('.state') || props.field.field_name.includes('companyRepState')) {
        let selectedCountry = _.get(
          formState.values,
          `${props.fieldPrefixFilter.replace('[', '.').replace(']', '.')}country`
        )
        if (!selectedCountry)
          selectedCountry = _.get(
            formState.values,
            `${props.fieldPrefixFilter.replace('[', '.').replace(']', '.')}companyRepCountry`
          )
        if (selectedCountry && selectedCountry !== 'US') return true
      }
    }
    return false
  }, [props.fieldPrefixFilter, props.field.field_name, props.field.is_hidden, formState.values])

  // check to see if this field is for a contact that is linked to another contact (ex owner 1 is the company rep)
  useEffect(() => {
    if (props.fieldPrefixFilter?.startsWith('ownershipInfoArr')) {
      // check the companyRep
      let thisOwnerIndex = props.fieldPrefixFilter.slice(17).replace(']', '')
      const thisContact = getNormalizeBlueSnapContact(formState.values.ownershipInfoArr[thisOwnerIndex])
      const rep = getNormalizeBlueSnapContact(formState.values?.companyRep)
      if (getContactsAreEqual(thisContact, rep)) {
        setSharedContactPrefix('companyRep')
      }
    } else if (props.fieldPrefixFilter === 'companyRep') {
      // check the owners
      if (formState.values?.ownershipInfoArr) {
        const thisContact = getNormalizeBlueSnapContact(formState.values?.companyRep)
        formState.values?.ownershipInfoArr?.forEach((owner, i) => {
          const fieldPrefix = `ownershipInfoArr[${i}]` as FieldPrefixFilterType
          const normalizedOwner = getNormalizeBlueSnapContact(owner)
          if (getContactsAreEqual(thisContact, normalizedOwner)) {
            setSharedContactPrefix(fieldPrefix)
          }
        })
      }
    }
    // only run this on mount, we don't want to link/unlink contacts as names are edited
  }, [])

  useEffect(() => {
    if (sharedContactPrefix && !hideField && isFocused) {
      // if a field is currently being edited on an owner that is also a rep, make sure the new values are brought over to the rep as well
      // it's currently not possible to edit the rep version of a shared contact so we don't have to support that
      let currentFieldVal = undefined
      if (props.field.field_name?.startsWith('ownershipInfoArr')) {
        const ownerIndex = props.field.field_name.slice(17).charAt(0)
        const nestedFieldName = props.field.field_name.slice(20)
        currentFieldVal = formState.values?.ownershipInfoArr?.[ownerIndex]?.[nestedFieldName]
        const fieldOnOtherObject = CONTACT_FIELD_MAP?.find((fieldMap) => fieldMap.owner === nestedFieldName)?.companyRep
        if (fieldOnOtherObject) {
          form.change(`companyRep.${fieldOnOtherObject}`, currentFieldVal)
        }
      }
    }
  }, [isFocused, hideField, sharedContactPrefix, formState?.values])

  useEffect(() => {
    if (!fieldValue && !!prepopulatedValue && !fieldHasBeenPrepopulated && !hideField) {
      setFieldHasBeenPrepoulated(true)
      form.change(props.field.field_name, prepopulatedValue)
    }
  }, [prepopulatedValue, fieldValue, fieldHasBeenPrepopulated, hideField])

  const inputType = useMemo(() => {
    switch (props.field.field_type) {
      case 'boolean':
        return CheckboxInput
      case 'terms':
        return CheckboxInput
      case 'select':
        return SelectInput
      default:
        return TextInput
    }
  }, [props.field.field_type])

  if (hideField) return null
  else if (props.field.field_name.includes('address') || props.field.field_name.includes('companyRepAddress')) {
    return (
      <Grid item xs={12}>
        <OnboardingAddressAutocomplete fieldLabel={props.field.field_label} fieldName={props.field.field_name} />
      </Grid>
    )
  } else if (businessAddressFields.includes(props.field.field_label) && !hasBusinessAddressError) {
    return null
  } else if (businessOwnerAddressFields.includes(props.field.field_label) && !hasOwnnerAddressError) {
    return null
  } else if (companyRepAddressFields.includes(props.field.field_label) && !hasCompanyRepAddressError) {
    return null
  } else if (props.field.field_type === 'terms') {
    return (
      <Grid item xs={12}>
        <div className={classes.termsWrapper}>
          <CustomField
            required={props.field.is_required}
            name={props.field.field_name}
            label={undefined}
            component={CheckboxInput}
            choices={props.field.choices}
            initialValue={!!props.field.default_value}
          />
          {props.field.terms_content && (
            <div
              dangerouslySetInnerHTML={{
                __html: props.field.terms_content,
              }}
            ></div>
          )}
        </div>
      </Grid>
    )
  } else {
    return (
      <Grid item xs={props.field.full_width || props.field.field_type === 'date' ? 12 : 6}>
        <div data-testid="bluesnap-onboarding-form-field">
          {props.field.field_type === 'date' ? (
            <DateOfBirthInputGroup field={props.field} />
          ) : (
            <div className={classes.fieldWrapper}>
              {props.field.field_name === 'businessInfo.phone' && org?.country?.iso2 === 'GB' && (
                <div className={classes.countryCode}>
                  <CustomField
                    name="businessInfo.phoneCountryCode"
                    component={SelectInput}
                    onChange={(value, input) => {
                      input.onChange(value)
                    }}
                    required={false}
                    variant="outlined"
                    label={translate('Country')}
                    renderValue={(v) => `${countryMapping[v].flag} +${countryMapping[v].prefix}`}
                    choices={countriesWithPrefix?.map((c) => ({ id: c.prefix, name: `${c.flag} ${c.name}` }))}
                    style={{
                      width: '100%',
                      maxWidth: '100px',
                      marginTop: '0px',
                      marginBottom: '0px',
                      marginRight: '5px',
                    }}
                    initialValue="44"
                  />
                </div>
              )}
              <div className={classes.field}>
                <CustomField
                  style={{
                    width: '100%',
                    marginTop: props.field.field_label === 'Business address' ? '25px' : '0px',
                    marginBottom: '0px',
                  }}
                  required={props.field.is_required}
                  name={props.field.field_name}
                  label={props.field.field_label}
                  component={inputType}
                  choices={props.field.choices}
                  initialValue={props.field.default_value}
                  variant="outlined"
                  disabled={props.field.is_disabled}
                  inputProps={{ onFocus: () => setIsFocused(true), onBlur: () => setIsFocused(false) }}
                />
              </div>
            </div>
          )}
        </div>
      </Grid>
    )
  }
}
export default OnboardingField

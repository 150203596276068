export const PREDEFINED_MODELS_CONFIG = {
  Project: {
    label: 'Project',
    availableFields: [
      { id: 'custom_project_info_1', name: 'Project Custom Field 1' },
      { id: 'custom_project_info_2', name: 'Project Custom Field 2' },
      { id: 'custom_project_info_3', name: 'Project Custom Field 3' },
      { id: 'custom_project_info_4', name: 'Project Custom Field 4' },
      { id: 'custom_project_info_5', name: 'Project Custom Field 5' },
    ],
    keys: ['field', 'label', 'field_type', 'select_field_choices'],
  },
  Contact: {
    label: 'Contact',
    availableFields: [
      { id: 'custom_contact_info_1', name: 'Contact Custom Field 1' },
      { id: 'custom_contact_info_2', name: 'Contact Custom Field 2' },
    ],
    keys: ['field', 'label', 'field_type', 'select_field_choices'],
  },
  COGS: {
    label: 'COGS',
    availableFields: [
      { id: 'custom_cogs_cost_1', name: 'COGS Buildup Custom Field 1' },
      { id: 'custom_cogs_cost_2', name: 'COGS Buildup Custom Field 2' },
      { id: 'custom_cogs_cost_3', name: 'COGS Buildup Custom Field 3' },
      { id: 'custom_cogs_cost_4', name: 'COGS Buildup Custom Field 4' },
      { id: 'custom_cogs_cost_5', name: 'COGS Buildup Custom Field 5' },
    ],
    keys: ['field', 'label'],
  },
  Acquisition: {
    label: 'Acquisition',
    availableFields: [
      { id: 'custom_acquisition_cost_1', name: 'COGS Acquisition Custom Field 1' },
      { id: 'custom_acquisition_cost_2', name: 'COGS Acquisition Custom Field 2' },
      { id: 'custom_acquisition_cost_3', name: 'COGS Acquisition Custom Field 3' },
    ],
    keys: ['field', 'label'],
  },
  Fulfillment: {
    label: 'Fulfillment',
    availableFields: [
      { id: 'custom_fulfillment_cost_1', name: 'COGS Fulfillment Custom Field 1' },
      { id: 'custom_fulfillment_cost_2', name: 'COGS Fulfillment Custom Field 2' },
      { id: 'custom_fulfillment_cost_3', name: 'COGS Fulfillment Custom Field 3' },
    ],
    keys: ['field', 'label'],
  },
}

export const getModelByFieldName = (fieldName: String) => {
  return PREDEFINED_MODELS_TYPES.find((model: string) => {
    //@ts-ignore
    return PREDEFINED_MODELS_CONFIG[model]?.availableFields?.find((field: any) => field.id === fieldName)
  })
}

export const FIELD_TYPES = [
  { id: 0, name: 'Text' },
  { id: 1, name: 'Dropdown' },
  { id: 4, name: 'Dropdown (Multi-Select)' },
  { id: 2, name: 'Date Time' },
  { id: 3, name: 'Date' },
]

export const PREDEFINED_MODELS_TYPES = Object.keys(PREDEFINED_MODELS_CONFIG)

import { HelpLink } from 'elements/link/HelpLink'
import React from 'react'
import { useTranslate } from 'react-admin'
import { IncentiveCreate, IncentiveEdit, IncentiveList } from './Incentives'

const Subtitle = () => {
  const translate = useTranslate()
  return (
    <span>
      {translate(
        'Ensure that you’re including all relevant incentives for each project by choosing an incentive from the OpenSolar' +
          ' database, or by creating your own. You can auto-apply incentives to all projects, or select them on a per-project' +
          ' basis at the Project Design stage.'
      )}
      '
      <HelpLink href="https://support.opensolar.com/hc/en-us/articles/4407439623065" />
    </span>
  )
}
const IncentiveTitle = ({ record, translate, style }) => {
  return (
    <span style={style}>
      {translate('Incentive')} {record ? `"${record.title}"` : ''}
    </span>
  )
}

const IncentiveCreateTitle = ({ record, translate, style }) => {
  return <span style={style}>{translate('Create Incentive')}</span>
}

export default {
  list: IncentiveList,
  create: IncentiveCreate,
  edit: IncentiveEdit,
  options: {
    create: {
      title: <IncentiveCreateTitle />,
      subtitle: null,
      breadCrumb: 'Create Incentive',
    },
    list: {
      title: 'My Incentives List',
      subtitle: <Subtitle />,
      breadCrumb: 'Incentives',
    },
    edit: {
      title: <IncentiveTitle />,
      subtitle: null,
      breadCrumb: 'Edit Incentive',
    },
  },
}

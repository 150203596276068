import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  useTheme,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddOutlined'
import BackIcon from '@material-ui/icons/ArrowBackOutlined'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import Alert from 'elements/Alert'
import GenericButton from 'elements/button/GenericButton'
import CustomSelectField from 'elements/field/CustomSelectField'
import LoadingDots from 'layout/widgets/LoadingDots'
import _ from 'lodash'
import { Button, IconButton } from 'opensolar-ui'
import { OrderLineItem } from 'pages/ordering/OrderLineItem'
import ProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/projectOrderPresenter'
import useProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/useProjectOrderPresenter'
import QuantityField from 'pages/ordering/order/table/quantity/QuantityField'
import { PureComponent, useCallback, useEffect, useState } from 'react'
import { showNotification as showNotificationAction, useTranslate, withTranslate } from 'react-admin'
import { graphql, withApollo } from 'react-apollo'
import { connect, useDispatch, useSelector } from 'react-redux'
import compose from 'recompose/compose'
import { segenCategories } from 'services/segen/constants'
import { getOrgId } from 'storage/appStorage'
import { currencySymbolForCountry, formatCurrencyWithSymbol } from 'util/misc'
import { getOrgCountryCodeFromState, getOrgFromState } from 'util/org'
import {
  clearLineItems,
  deleteLineItems,
  openOrderCart,
  orderSelectors,
  updateOrderQuantity,
} from '../../ducks/orderComponents'
import restClient from '../../restClient'
import { CREATE_CHECKOUT } from '../../storeFrontQuery'
import { DialogHelper } from '../../util/misc'

const restClientInstance = restClient(window.API_ROOT + '/api')

const segenCategoriesAll = [
  {
    category: 'Categories',
    id: '',
    subCategories: [],
    all: true,
  },
].concat(segenCategories)

const menuItemsForSegenCategories = () => {
  var items = []

  segenCategoriesAll.forEach((segenCategory) => {
    items.push(
      <MenuItem key={segenCategory.id} value={'category_' + segenCategory.id}>
        {segenCategory.category} - All
      </MenuItem>
    )

    segenCategory.subCategories.forEach((segenSubCategory) => {
      items.push(
        <MenuItem key={segenSubCategory.id} value={'subcategory_' + segenSubCategory.id}>
          {segenCategory.category} - {segenSubCategory.subcategory}
        </MenuItem>
      )
    })
  })

  return items
}

const labelForCategory = (id) => {
  return segenCategories.find((category) => category.id === id)?.category || 'Other'
}

const labelForSubCategory = (id) => {
  for (var i = 0; i < segenCategoriesAll.length; i++) {
    for (var j = 0; j < segenCategoriesAll[i].subCategories.length; j++) {
      if (segenCategoriesAll[i].subCategories[j].id === id) {
        return segenCategoriesAll[i].subCategories[j].subcategory
      }
    }
  }
}

const labels = {
  segen: {
    title: 'Segen',
    object_to_create: 'quote',
    object_to_create_title_case: 'Quote',
    order_button_label: 'Create Segen Quote',
    dialog_title: 'Create Quote',
  },
  outlet: {
    title: 'Solar Outlet',
    object_to_create: 'order',
    object_to_create_title_case: 'Order',
    order_button_label: 'Order at Outlet',
    dialog_title: 'Create Order',
  },
}

const AvailableComponentsList = ({ resource, searchText, setComponentDetail, searchHasStarted }) => {
  const { themeColor } = useTheme()

  const renderComponentMenuItem = (choice) => {
    const suggestionText = choice.label
    const matches = match(suggestionText, searchText)
    const parts = parse(suggestionText, matches)
    return (
      <MenuItem
        onClick={() => setComponentDetail(choice)}
        id={choice.label}
        key={choice.label}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <div style={{ display: 'flex', width: 50, height: 60, marginRight: 20 }}>
          {choice.image_url ? (
            <img src={choice.image_url} style={{ display: 'inline-block', maxWidth: 50, maxHeight: 60 }} alt="" />
          ) : (
            <div />
          )}
        </div>
        <div>
          {parts.map((part, index) => {
            return part.highlight ? (
              <span key={part.text + index} style={{ background: themeColor }}>
                {part.text}
              </span>
            ) : (
              <span style={{ fontWeight: 300 }} key={part.text + index}>
                {part.text}
              </span>
            )
          })}
        </div>
      </MenuItem>
    )
  }
  return (
    <DialogContent style={{ minHeight: 350, padding: 0 }}>
      {resource && resource.length > 0 ? (
        resource.map((choice) => renderComponentMenuItem(choice))
      ) : (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <div style={{ margin: 'auto', padding: 50 }}>
            {!searchHasStarted ? 'Search for components above...' : 'No results'}
          </div>
        </div>
      )}
    </DialogContent>
  )
}

const DisplayAvailableComponentDetail = ({ toggleAddingComponent, componentDetail, setComponentDetail }) => {
  const orderingPresenter = useProjectOrderPresenter()
  const orgId = useSelector(authSelectors.getOrgId)
  const supplierFilterKey = useSelector(orgSelectors.getSelectedHardwareSupplierFilterKey)

  const currencySymbol = useSelector((state) => {
    const countryIso2 = getOrgCountryCodeFromState(state)
    return currencySymbolForCountry(countryIso2)
  })

  const [quantity, setQuantity] = useState('')
  const translate = useTranslate()
  const addOrderItemsToCart = () => {
    if (!supplierFilterKey) return
    // old logic check whether it is exist
    orderingPresenter.addLineItemsLegacy([
      new OrderLineItem({
        code: componentDetail.value,
        quantity: quantity,
        // Force add line item not trigger saga
        status: 'loaded',
        orgId: orgId,
        variant_description: null,
        data: componentDetail,
        selectedDistributor: supplierFilterKey,
      }),
    ])
    toggleAddingComponent(false)
  }
  // Nasty hack to handle different type. Seems like a regression from BE data structure
  // Make sure page not crashed for now since the old flow going to be removed very soon
  var distributorItem = Array.isArray(componentDetail?.ordering)
    ? componentDetail?.ordering?.find((orderingItem) => orderingItem.quantity_per_item === 1)
    : componentDetail?.ordering
  var distributorPrice = componentDetail?.ordering?.price || null

  return (
    <DialogContent>
      <div>
        <IconButton style={{ padding: 6 }} onClick={() => setComponentDetail(null)}>
          <BackIcon />
        </IconButton>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 24 }}>
        <div style={{ width: '48%' }}>
          <img
            src={componentDetail.image_url}
            style={{ display: 'inline-block', maxWidth: '100%', maxHeight: 400 }}
            alt=""
          />
        </div>
        <div style={{ width: '48%', padding: 10 }}>
          <h1 style={{ fontSize: 20 }}>{componentDetail.value}</h1>
          <p>{componentDetail.label}</p>

          <TextField
            style={{
              lineHeight: '45px',
              width: 125,
              margin: '10px 0',
              boxSizing: 'border-box',
            }}
            variant={'outlined'}
            label={translate('Quantity')}
            value={quantity}
            onChange={(e) => {
              const quantity = e.target.value
              if (quantity < 0) {
                return
              }
              const newValue = quantity ? Math.floor(quantity) : quantity
              setQuantity(newValue)
            }}
            inputProps={{ step: '1', style: { fontSize: 14 } }}
            type="number"
            size="small"
          />

          {distributorPrice && (
            <div style={{ margin: '10px 0' }}>
              Price: {formatCurrencyWithSymbol(distributorPrice, currencySymbol, window.locale, 2)}
            </div>
          )}

          {distributorItem?.stock_levels?.length > 0 &&
            (distributorItem?.stock_levels[0].in_stock ? (
              <div style={{ margin: '10px 0 20px 0' }}>Stock: {distributorItem?.stock_levels[0].quantity}</div>
            ) : (
              <div style={{ margin: '10px 0 20px 0', color: 'red' }}>Out of stock</div>
            ))}

          <Button
            disabled={!Boolean(quantity)}
            fullWidth={true}
            style={{
              color: quantity ? '#ff9d4d' : '#cdcdcd',
              padding: '10px 15px',
              boxShadow: quantity ? '' : '1px 1px 2px rgba(0, 0, 0, 0.25)',
            }}
            variant="outlined"
            onClick={() => {
              addOrderItemsToCart()
            }}
          >
            <span>{translate('Add to cart')}</span>
          </Button>
        </div>
      </div>
    </DialogContent>
  )
}

const SearchComponents = ({ toggleAddingComponent, wholesaler, selectedCategory, setSelectedCategory }) => {
  const [loading, setLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [componentDetail, setComponentDetail] = useState(null)
  const [resource, setResource] = useState([])
  const translate = useTranslate()
  const searchHasStarted = selectedCategory || searchText
  const searchHash = selectedCategory + '_' + searchText

  useEffect(() => {
    loadResults()
  }, [searchHash])

  const loadResults = () => {
    if (!selectedCategory && !searchText) {
      setResource([])
      return
    }

    if (selectedCategory.all && !searchText) {
      setLoading(true)
      restClientInstance('CUSTOM_GET', 'custom', {
        url: 'orgs/' + getOrgId() + '/search_component_catalog/' + wholesaler,
      })
        .then((response) => {
          setResource(response.data)
        })
        .catch((error) => {
          console.log(error)
          setResource([])
        })
        .finally(() => {
          setLoading(false)
        })
      return
    }

    var categoryQuery = ''
    if (selectedCategory) {
      let categoryType = selectedCategory.startsWith('subcategory_') ? 'subcategory_id' : 'category_id'
      let categoryId = selectedCategory.split('_')[1]
      categoryQuery = `${categoryType}=${categoryId}`
    }

    setLoading(true)

    restClientInstance('CUSTOM_GET', 'custom', {
      url:
        'orgs/' + getOrgId() + '/search_component_catalog/' + wholesaler + '?query=' + searchText + '&' + categoryQuery,
    })
      .then((response) => {
        setResource(response.data)
      })
      .catch((error) => {
        console.log(error)
        setResource([])
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleUpdateCategory = (e) => {
    setSelectedCategory(e.target.value)
  }

  const debounceHandleUpdateInput = useCallback(
    _.debounce((value) => setSearchText(value), 500),
    []
  )

  return (
    <>
      <DialogTitle style={{ padding: 0 }}>
        {componentDetail === null ? (
          <TextField
            id="search_components_input"
            fullWidth={true}
            placeholder={'Search here for ' + labels[wholesaler].title + ' products'}
            inputProps={{ style: { padding: 12, fontSize: 18, backgroundColor: '#eaeaea', margin: 10 } }}
            InputProps={{
              endAdornment: (
                <>
                  <CustomSelectField
                    variant="filled"
                    disableUnderline
                    style={{
                      width: 200,
                      marginRight: 180,
                      marginBottom: 20,
                      padding: 3,
                      borderRadius: 0,
                    }}
                    value={selectedCategory}
                    onChange={(event) => {
                      handleUpdateCategory(event)
                    }}
                  >
                    {menuItemsForSegenCategories()}
                  </CustomSelectField>
                  <Button
                    onClick={() => toggleAddingComponent(false)}
                    variant="contained"
                    color="default"
                    style={{
                      margin: '0 16px',
                      // , color: '#ff9d4d'
                    }}
                  >
                    <span>{translate('Cancel')}</span>
                  </Button>
                </>
              ),
            }}
            // value={searchText}
            onChange={(e) => debounceHandleUpdateInput(e.target.value)}
          />
        ) : (
          <Button
            onClick={() => toggleAddingComponent(false)}
            variant="contained"
            color="default"
            style={{
              marginLeft: '85%',
              marginTop: '2%',
            }}
          >
            <span>{translate('Cancel')}</span>
          </Button>
        )}
      </DialogTitle>
      {Boolean(componentDetail) ? (
        <DisplayAvailableComponentDetail
          toggleAddingComponent={toggleAddingComponent}
          setComponentDetail={setComponentDetail}
          componentDetail={componentDetail}
        />
      ) : loading ? (
        <div>
          <LoadingDots text={'Loading...'} style={{ margin: 100 }} />
        </div>
      ) : (
        <AvailableComponentsList
          resource={resource}
          searchText={searchText}
          searchHasStarted={searchHasStarted}
          setComponentDetail={setComponentDetail}
        />
      )}
    </>
  )
}

const createQuoteCategories = [
  [3, []],
  [4, [292, 206]],
  [5, []],
  [12, [339, 126, 395, 127]],
  [37, [352, 233, 159, 337, 161]],
  [25, []],
  [56, [370, 342, 340, 379, 367]],
  [47, [276]],
  [22, [115, 350, 23, 287, 22, 214, 215]],
  [10, []],
  [9, [8, 9, 109, 112, 113]],
  [51, [304]],
]

const componentNotInAnyCategoriesOrSubcategories = (category_id, subcategory_id) => {
  for (var i = 0; i < segenCategoriesAll.length; i++) {
    if (segenCategoriesAll[i] === category_id) {
      return false
    }
    for (var j = 0; j < segenCategoriesAll[i].subCategories.length; j++) {
      if (segenCategoriesAll[i].subCategories[j].id === subcategory_id) {
        return false
      }
    }
  }
  return true
}

const ComponentsTable = ({ wholesaler, toggleAddingComponent, setSelectedCategory }) => {
  const translate = useTranslate()
  const lineItems = useSelector(orderSelectors.getOrderLineItems)
  // const orgId = useSelector(authSelectors.getOrgId)
  const dispatch = useDispatch()
  const currencySymbol = useSelector((state) => {
    const countryIso2 = getOrgCountryCodeFromState(state)
    return currencySymbolForCountry(countryIso2)
  })

  const updateLineItemQuantity = ({ uuid, quantity }) => {
    dispatch(updateOrderQuantity({ uuid, quantity }))
  }

  const removeOrderItemsFromCart = (uuid) => {
    dispatch(deleteLineItems({ source: 'uuid', identifiers: [uuid] }))
  }

  var renderTableBody = []

  // Add an extra category for "other"
  createQuoteCategories.concat([[null, []]]).forEach((catgorySettings) => {
    var categoryId = catgorySettings[0]
    var subCategoryIds = catgorySettings[1]

    renderTableBody.push(
      <h1 style={{ marginTop: 30 }} key={'heading' + categoryId}>
        {categoryId ? labelForCategory(categoryId) : 'Other'}
      </h1>
    )

    renderTableBody = renderTableBody.concat(
      lineItems
        .filter(
          (component) =>
            component.data?.category_id === categoryId ||
            subCategoryIds.includes(component.data?.subcategory_id) ||
            (!categoryId &&
              componentNotInAnyCategoriesOrSubcategories(component.data?.category_id, component.data?.subcategory_id))
        )
        .map((component, index) => {
          // Nasty hack to handle different type. Seems like a regression from BE data structure
          // Make sure page not crashed for now since the old flow going to be removed very soon
          var distributorItem = Array.isArray(component?.data?.ordering)
            ? component?.data?.ordering?.find((orderingItem) => orderingItem.quantity_per_item === 1)
            : component?.data?.ordering
          var distributorPrice = distributorItem?.price || null

          return (
            <Table
              key={`table${component.code}${categoryId}${index}`}
              style={{ border: '1px solid #DFDFDF', borderRadius: '3px', tableLayout: 'fixed' }}
            >
              <TableBody displayRowCheckbox={false}>
                <TableRow key={component.code}>
                  <TableCell style={{ padding: '0 10px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {component.data?.image_url && (
                        <div style={{ display: 'flex' }}>
                          <img
                            src={component.data?.image_url}
                            style={{ display: 'inline-block', width: 50, maxHeight: 100 }}
                          />
                        </div>
                      )}
                      <div>
                        <p style={{ margin: 0 }}>{component.code}</p>
                        {component.data?.variant_description && (
                          <p style={{ margin: 0 }} className="small">
                            {component.data?.variant_description}
                          </p>
                        )}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell style={{ padding: '0 10px', whiteSpace: '' }}>
                    {component.data?.variant_id ? (
                      <div>
                        <QuantityField
                          lineItem={component}
                          onUpdateQuantity={(quantity) =>
                            updateLineItemQuantity({
                              uuid: component.uuid,
                              quantity,
                            })
                          }
                        />

                        <div style={{ marginTop: 3 }}>
                          {distributorPrice > 0 && (
                            <span style={{ marginRight: 10 }}>
                              Price: {formatCurrencyWithSymbol(distributorPrice, currencySymbol, window.locale, 2)}
                            </span>
                          )}

                          {distributorItem?.stock_levels?.length > 0 &&
                            (distributorItem?.stock_levels[0]?.in_stock &&
                            distributorItem?.stock_levels[0]?.quantity > 0 ? (
                              <span style={{ marginRight: 10 }}>
                                Stock: {distributorItem?.stock_levels[0].quantity}
                              </span>
                            ) : (
                              <span style={{ marginRight: 10, color: 'red' }}>Out of stock</span>
                            ))}
                        </div>
                      </div>
                    ) : (
                      <Alert severity="warning">
                        <div style={{ fontStyle: 'italic' }}>
                          {translate(
                            component.quantity +
                              ' ' +
                              (component.quantity === 1 ? 'item' : 'items') +
                              ' could not be added automatically to this order. Click "Add Item" to manually search ' +
                              labels[wholesaler].title +
                              ' and add ' +
                              (component.quantity === 1 ? 'this item.' : 'these items.')
                          )}
                        </div>
                      </Alert>
                    )}
                  </TableCell>
                  <TableCell style={{ padding: 0, textAlign: 'right', width: 50 }}>
                    <IconButton onClick={() => removeOrderItemsFromCart(component.uuid)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )
        })
    )

    // Add buttons for subcategories

    renderTableBody.push(
      <div style={{ marginTop: 20 }}>
        <GenericButton
          key={`button_category_${categoryId}`}
          style={{
            margin: '2px 5px 2px 0px',
            padding: '0',
            height: 32,
          }}
          labelWrapperStyle={{ fontSize: '12px', padding: '0 2px 2px 0' }}
          variant="outlined"
          color="default"
          backgroundColorOnHover={'#FFFFFF'}
          boxShadowOnHover={'0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)'}
          borderOnHover={'2px solid #0094FF'}
          onClick={() => {
            toggleAddingComponent(true)
            setSelectedCategory('category_' + categoryId)
          }}
          startIcon={<AddIcon />}
          label={translate(labelForCategory(categoryId))}
        />
      </div>
    )

    renderTableBody = renderTableBody.concat(
      subCategoryIds.map((subCategoryId) => (
        <div>
          <GenericButton
            key={`button_subcategory_${subCategoryId}`}
            style={{
              margin: '2px 5px 2px 0px',
              padding: '0',
              height: 32,
            }}
            labelWrapperStyle={{ fontSize: '12px', padding: '0 2px 2px 0' }}
            variant="outlined"
            color="default"
            backgroundColorOnHover={'#FFFFFF'}
            boxShadowOnHover={'0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)'}
            borderOnHover={'2px solid #0094FF'}
            onClick={() => {
              toggleAddingComponent(true)
              setSelectedCategory('subcategory_' + subCategoryId)
            }}
            startIcon={<AddIcon />}
            label={translate(labelForSubCategory(subCategoryId))}
          />
        </div>
      ))
    )
  })

  return renderTableBody
}
const OrdersDetail = ({ wholesaler, toggleAddingComponent, setSelectedCategory }) => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const handleClose = () => {
    DialogHelper.beforeClose()
    dispatch(openOrderCart(false))
  }

  // TODO: fix projects address
  const arrayOfProjectsAddress = []
  return (
    <DialogContent>
      <div>
        <IconButton
          style={{
            top: 10,
            right: 10,
            position: 'absolute',
            minWidth: 40,
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            zIndex: 1,
          }}
          onClick={handleClose}
        >
          {<CloseIcon style={{ color: '#000000' }} />}
        </IconButton>
        <div>
          {arrayOfProjectsAddress.length > 0 && (
            <div>
              <p>
                {labels[wholesaler].object_to_create === 'order'
                  ? translate('Order for projects')
                  : translate('Quote for projects')}
                : {arrayOfProjectsAddress.join(', ')}
              </p>
              <p class="small">
                <strong>{translate('Note')}: </strong>
                <span>
                  {translate(
                    `You can automatically add components from multiple sold projects in a single quote by going to each project and clicking "Create Quote" button.`
                  )}
                </span>
              </p>
            </div>
          )}
          <div>
            <ComponentsTable
              wholesaler={wholesaler}
              toggleAddingComponent={toggleAddingComponent}
              setSelectedCategory={setSelectedCategory}
            />
          </div>
        </div>
      </div>
    </DialogContent>
  )
}

const OrderComponentActions = ({
  handleOrderButton,
  toggleAddingComponent,
  wholesaler,
  isProcessingSegenOrder,
  setSelectedCategory,
}) => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const lineItems = useSelector(orderSelectors.getOrderLineItems)
  const totalItems = ProjectOrderPresenter.getLineItemsTotalQuantity(lineItems)
  const handleClose = () => {
    let answer = window.confirm(translate('Are you sure?'))
    if (answer) {
      DialogHelper.beforeClose()
      dispatch(openOrderCart(false))
      dispatch(clearLineItems())
    } else {
      return false
    }
  }

  return (
    <DialogActions>
      <div
        key={2}
        style={{
          width: '100%',
          justifyContent: 'flex-end',
          marginTop: 6,
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        <GenericButton
          startIcon={<DeleteIcon />}
          variant="contained"
          color="default"
          style={{
            margin: '-2px 5px 2px 16px',
          }}
          backgroundColorOnHover={'#F8F8F8'}
          boxShadowOnHover={'0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)'}
          borderOnHover={'2px solid #B4B4B4'}
          onClick={handleClose}
          label={labels[wholesaler].object_to_create === 'quote' ? translate('Clear Quote') : translate('Clear Order')}
        />

        <GenericButton
          style={{
            margin: '-2px 5px 2px 5px',
          }}
          variant="outlined"
          color="default"
          backgroundColorOnHover={'#FFFFFF'}
          boxShadowOnHover={'0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)'}
          borderOnHover={'2px solid #0094FF'}
          onClick={() => {
            toggleAddingComponent(true)
            setSelectedCategory('category_')
          }}
          startIcon={<AddIcon />}
          label={translate('Add Item')}
        />
        <GenericButton
          style={{
            margin: '-2px 16px 2px 5px',
          }}
          variant="contained"
          color="secondary"
          disabled={totalItems === 0 || isProcessingSegenOrder}
          backgroundColor={
            totalItems > 0
              ? 'linear-gradient(360deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 62.5%), #66B551'
              : '#e4e4e4'
          }
          backgroundColorOnHover={'linear-gradient(360deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 62.5%), #1F9600'}
          onClick={handleOrderButton}
          label={labels[wholesaler].order_button_label}
          boxShadowOnHover={
            '0px 2px 4px -1px rgb(0 0 0 / 5%), 0px 4px 5px rgb(0 0 0 / 14%), 0px 1px 10px rgb(0 0 0 / 12%)'
          }
        />
      </div>
    </DialogActions>
  )
}

class _OrderComponentsDialog extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      selectedCategory: null,
      isAddingComponent: false,
      isProcessingSegenOrder: false,
    }
  }

  setSelectedCategory = (selectedCategory) => {
    this.setState({ selectedCategory })
  }

  handleOrderButtonOnClick = () => {
    if (this.props.wholesaler === 'segen') {
      this.handleOrderButtonOnClickSegen()
      logAmplitudeEvent('hardware_segen_create_order', {})
    } else if (this.props.wholesaler === 'outlet') {
      this.handleOrderButtonOnClickOutlet()
      logAmplitudeEvent('hardware_solar_outlet_create_order', {})
    }
    logAmplitudeEvent('outfitter_create_quote_clicked', { wholesaler: this.props.wholesaler })
  }

  getProjectIds = () => {
    const { orderProjectList } = this.props
    return orderProjectList
  }

  handleOrderButtonOnClickSegen = () => {
    const { showNotification, translate, lineItems } = this.props

    // Track order initiated. We rely on 3rd party to confirm whether order was completed
    // but at least we can record which orders were initiated from OpenSolar

    var orderFormatted = {
      items: lineItems,
      project_ids: this.getProjectIds(),
    }

    // alert('Send Order to Segen, details: ' + JSON.stringify(orderFormatted))
    this.setState({ isProcessingSegenOrder: true })
    restClientInstance('CUSTOM_POST', 'custom', {
      url: 'orgs/' + getOrgId() + '/_orders/segen/create/',
      data: orderFormatted,
    })
      .then((response) => {
        if (response.data.redirect_url) {
          // alert('Order created, launch checkout URL: ' + response.data.redirect_url)
          if (window.RUNNING_AS_APP) {
            // App will block window.open because it was not directly from a button click handler
            // Use workaround
            window.ReactNativeWebView.postMessage(
              JSON.stringify({ type: 'windowOpenHack', url: response.data.redirect_url })
            )
          } else {
            window.open(response.data.redirect_url)
          }
        } else {
          console.error('No redirect_url')
        }
      })
      .catch((error) => {
        const errorDetail = translate(error.message ?? '')
        console.error('Unable to save order before redirecting to checkout', error)
        showNotification('Unable to save order before redirecting to checkout' + errorDetail ? `: ${errorDetail} ` : '')
        logAmplitudeEvent('segen_quote_error', { error_msg: errorDetail })
      })
      .finally(() => {
        this.setState({ isProcessingSegenOrder: false })
      })
  }

  handleOrderButtonOnClickOutlet = () => {
    const { lineItems, orgId } = this.props

    const { createCheckout } = this.props

    const toBase64 = (input) => btoa(unescape(encodeURIComponent(input)))
    const variantIdtoBase64 = (variantId) => toBase64('gid://shopify/ProductVariant/' + variantId)

    createCheckout({
      variables: {
        input: {
          lineItems: lineItems
            .filter((lineItem) => lineItem.variantId && lineItem.quantity > 0)
            .map((lineItem) => ({
              variantId: variantIdtoBase64(lineItem.variantId),
              quantity: lineItem.quantity,
            })),
        },
      },
    }).then((createCheckoutResult) => {
      const checkout = createCheckoutResult.data.checkoutCreate.checkout

      this.setState({
        isOrderComplete: true,
      })

      restClientInstance('CUSTOM_POST', 'custom', {
        url: 'orgs/' + orgId + '/_orders/outlet/create/',
        data: {
          checkout,
          project_ids: this.getProjectIds(),
        },
      }).catch((error) => {
        console.error('Unable to save order before redirecting to checkout', error)
      })

      if (window.RUNNING_AS_APP) {
        // App will block window.open because it was not directly from a button click handler
        // Use workaround
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'windowOpenHack', url: checkout.webUrl }))
      } else {
        window.open(checkout.webUrl)
      }
    })
  }

  render() {
    const { isAddingComponent, isProcessingSegenOrder } = this.state
    const { wholesaler } = this.props
    const toggleAddingComponent = (isAddingComponent) =>
      this.setState({
        isAddingComponent,
      })
    return (
      <div>
        <Dialog
          className="modal"
          id="order_components_dialog"
          open={this.props.isOpen}
          onBackdropClick={() => {
            this.props.closeDialog && this.props.closeDialog()
          }}
        >
          {isAddingComponent ? (
            <SearchComponents
              toggleAddingComponent={toggleAddingComponent}
              wholesaler={wholesaler}
              selectedCategory={this.state.selectedCategory}
              setSelectedCategory={this.setSelectedCategory}
            />
          ) : (
            <>
              <DialogTitle>{labels[wholesaler]?.dialog_title}</DialogTitle>
              <OrdersDetail
                wholesaler={wholesaler}
                toggleAddingComponent={toggleAddingComponent}
                setSelectedCategory={this.setSelectedCategory}
              />
              <OrderComponentActions
                setSelectedCategory={this.setSelectedCategory}
                isProcessingSegenOrder={isProcessingSegenOrder}
                handleOrderButton={this.handleOrderButtonOnClick}
                toggleAddingComponent={toggleAddingComponent}
                wholesaler={wholesaler}
              />
            </>
          )}
          {isProcessingSegenOrder && <LinearProgress />}
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const org = getOrgFromState(state)
  const wholesaler = (org?.ordering_integrations && Object.keys(org.ordering_integrations)[0]) || 'outlet'
  const lineItems = state.orderComponents.lineItems.filter((order) => order.orgId === org?.id)
  const orderProjectList = orderSelectors.getOrderProjectList(state)
  return {
    orgId: state.auth ? state.auth.org_id : null,
    isOpen: state.orderComponents.openDialog,
    lineItems,
    orderProjectList,
    wholesaler,
  }
}

const dispatchActions = {
  showNotification: showNotificationAction,
}

const OrderComponentsDialog = compose(
  withTranslate,
  connect(mapStateToProps, dispatchActions),
  graphql(CREATE_CHECKOUT, { name: 'createCheckout' }),
  withApollo
)(_OrderComponentsDialog)
export default OrderComponentsDialog

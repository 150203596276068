import React from 'react'
import { OPENSOLAR_LOGO_URL_FULL } from './Configs'

const AuthFlowLogo = ({ src, alt }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ textAlign: 'center' }}>
        <img alt={alt} src={src} style={{ width: 200, margin: 20 }} />
      </div>
    </div>
  )
}

export const OpenSolarAuthFlowLogo = () => {
  return <AuthFlowLogo alt={'OpenSolar Logo'} src={OPENSOLAR_LOGO_URL_FULL} />
}

export default AuthFlowLogo

import React from 'react'
import { usePermissions, useTranslate } from 'react-admin'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { pathnameAndParamsWithUrlFragment } from './common'
import RegisterForm from './components/auth/RegisterForm'
import useLogoByUrlFragment from './components/logos/useLogoByUrlFragment'

const RegisterPage = ({ urlFragment }) => {
  const translate = useTranslate()
  const { loaded, permissions } = usePermissions()
  const location = useLocation<{ nextPathname?: string }>()
  const history = useHistory()
  const onSuccess = () => {
    history.push(location.state?.nextPathname || '/')
  }

  const Logo = useLogoByUrlFragment({ urlFragment })

  const onExistingAccountLogin = () => {
    history.push(pathnameAndParamsWithUrlFragment('/login', location, urlFragment))
  }

  // Only auto-redirect already logged-in users to homepage IF they have a URL Fragment specified,
  // otherwise allow them to view the register page because they may want to create a new org for an existing user.

  // Do not redirect any nearmap flows
  const isNearmapUrl = urlFragment && urlFragment.toLowerCase().includes('nearmap')

  if (loaded && urlFragment && !isNearmapUrl) {
    if (permissions.includes('is_user')) {
      return <Redirect to={'/home'} />
    }
  }

  return <RegisterForm Logo={Logo} onExistingAccountLogin={onExistingAccountLogin} onSuccess={onSuccess} />
}

export default RegisterPage

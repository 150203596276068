const ListIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2967_1787)">
      <path
        d="M9.16667 5.83346H14.1667V7.50012H9.16667V5.83346ZM9.16667 9.16679H14.1667V10.8335H9.16667V9.16679ZM9.16667 12.5001H14.1667V14.1668H9.16667V12.5001ZM5.83333 5.83346H7.5V7.50012H5.83333V5.83346ZM5.83333 9.16679H7.5V10.8335H5.83333V9.16679ZM5.83333 12.5001H7.5V14.1668H5.83333V12.5001ZM16.75 2.50012H3.25C2.83333 2.50012 2.5 2.83346 2.5 3.25012V16.7501C2.5 17.0835 2.83333 17.5001 3.25 17.5001H16.75C17.0833 17.5001 17.5 17.0835 17.5 16.7501V3.25012C17.5 2.83346 17.0833 2.50012 16.75 2.50012ZM15.8333 15.8335H4.16667V4.16679H15.8333V15.8335Z"
        fill="#757575"
      />
    </g>
    <defs>
      <clipPath id="clip0_2967_1787">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default ListIcon

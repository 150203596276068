import { logAmplitudeEvent } from 'amplitude/amplitude'
import { Alert } from 'opensolar-ui'
import { useEffect } from 'react'
import { useTranslate } from 'react-admin'

const ActivateMoreBatteriesAlert = ({ isBdaDisabled }: { isBdaDisabled: boolean }) => {
  const translate = useTranslate()

  useEffect(() => {
    if (isBdaDisabled) logAmplitudeEvent('bda_warning_add_more_active_batteries_shown')
  }, [])

  if (!isBdaDisabled) {
    return null
  }

  return (
    <Alert severity="warning">
      {translate(
        'Battery Design Assistant is disabled due to limited active batteries. Please activate more batteries in Control > Design & Hardware > Batteries.'
      )}
    </Alert>
  )
}

export default ActivateMoreBatteriesAlert

import { Alert } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { WorkflowType } from 'types/workflows'

type PropTypes = {
  workflow: WorkflowType
}

const WorkflowNotifs: React.FunctionComponent<PropTypes> = ({ workflow }) => {
  const form = useForm()
  const translate = useTranslate()
  const project = useFormState().values
  const initialValues = form.getState().initialValues
  const statusNotifs = useMemo(() => {
    let notifs: string[] = []
    if (workflow && workflow.org === project?.org) {
      const findStage = workflow?.workflow_stages?.find((x) => x.id === project?.workflow?.active_stage_id)
      if (findStage && findStage.milestone < 2 && project.project_sold !== null) notifs.push('sold_warning')
      if (findStage && findStage.milestone < 3 && project.project_installed !== null) notifs.push('installed_warning')
      if (initialValues.project_sold === null && project.project_sold === 1) {
        notifs.push('sold_info')
      }
      if (initialValues.project_installed === null && project.project_installed === 1) {
        notifs.push('installed_info')
      }
      if (initialValues.project_sold !== null && project.project_sold === null) {
        notifs.push('unsold_info')
      }
      if (initialValues.project_installed !== null && project.project_installed === null) {
        notifs.push('uninstalled_info')
      }
    }
    return notifs
  }, [project.project_sold, project.project_installed, project.workflow, workflow])
  if (!!!statusNotifs.length) return null
  return (
    <div style={{ marginTop: 20, marginBottom: 15 }}>
      {statusNotifs && !!statusNotifs.length && (
        <>
          {statusNotifs.includes('sold_warning') && (
            <div style={{ marginBottom: 5 }}>
              <Alert severity={'warning'}>
                {translate('Your project is marked as sold but your project stage does not reflect a sold project.')}
              </Alert>
            </div>
          )}
          {statusNotifs.includes('installed_warning') && (
            <div style={{ marginBottom: 5 }}>
              <Alert severity={'warning'}>
                {translate(
                  'Your project is marked as installed but your project stage does not reflect an installed project.'
                )}
              </Alert>
            </div>
          )}
          {statusNotifs.includes('sold_info') && statusNotifs.includes('installed_info') ? (
            <div style={{ marginBottom: 5 }}>
              <Alert severity={'info'}>
                {translate('Advancing to the next project stage will mark the project as sold and installed.')}
              </Alert>
            </div>
          ) : (
            <>
              {statusNotifs.includes('sold_info') && (
                <div style={{ marginBottom: 5 }}>
                  <Alert severity={'info'}>
                    {translate('Advancing to the next project stage will mark the project as sold.')}
                  </Alert>
                </div>
              )}
              {statusNotifs.includes('installed_info') && (
                <div style={{ marginBottom: 5 }}>
                  <Alert severity={'info'}>
                    {translate('Advancing to the next project stage will mark the project as installed.')}
                  </Alert>
                </div>
              )}
            </>
          )}

          {statusNotifs.includes('unsold_info') && statusNotifs.includes('uninstalled_info') ? (
            <div style={{ marginBottom: 5 }}>
              <Alert severity={'info'}>
                {translate('Reverting to a previous project stage will mark the project as unsold and uninstalled.')}
              </Alert>
            </div>
          ) : (
            <>
              {statusNotifs.includes('unsold_info') && (
                <div style={{ marginBottom: 5 }}>
                  <Alert severity={'info'}>
                    {translate('Reverting to a previous project stage will mark the project as unsold.')}
                  </Alert>
                </div>
              )}
              {statusNotifs.includes('uninstalled_info') && (
                <div style={{ marginBottom: 5 }}>
                  <Alert severity={'info'}>
                    {translate('Reverting to a previous project stage will mark the project as uninstalled.')}
                  </Alert>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}
export default WorkflowNotifs

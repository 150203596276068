import { Theme, makeStyles } from '@material-ui/core'
import Button from 'elements/button/Button'
import { useTranslate } from 'ra-core'
import React from 'react'
import { AvailableFinanceIntegrationType } from 'types/orgs'

type PropTypes = {
  integration: AvailableFinanceIntegrationType
  onClick: () => void
  loading: boolean
  label: string
}

const useStyles = makeStyles<Theme, { integration: AvailableFinanceIntegrationType }>((theme) => ({
  buttonBase: {
    borderRadius: 4,
    height: 40,
    marginBottom: 10,
    marginTop: 20,
    width: '100%',
    backgroundColor: ({ integration }) => integration?.banner_content?.theme_color || '#ffda00',
    color: ({ integration }) => integration?.banner_content?.font_color || '#fff',
    borderWidth: '0px',
    '&:hover': {
      background: ({ integration }) => integration?.banner_content?.theme_color_dark || '#ffda00',
    },
  },
}))

const IntegrationBrandedButton: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const classes = useStyles({ integration: props.integration })

  return (
    <Button
      variant="text"
      color="secondary"
      className={classes.buttonBase}
      onClick={props.onClick}
      label={translate(props.label)}
      loading={props.loading}
    />
  )
}
export default IntegrationBrandedButton

import { CustomNumberField } from 'elements/field/CustomNumberField'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { withTranslate } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import withStudioSignals from 'Studio/signals/withStudioSignals'
import Panel, { StudioPanelContext } from '../Designer/Panel'
import { refreshPanelGeneric, stripKeyForActiveTextField } from '../Studio/Utils'

import { DistanceNumberField } from 'elements/field/DistanceNumberField'
import { getMeasurementsFromState, metersToFeet, trimDecimalPlaces } from '../util/misc'

const DIMENSION_LABELS = {
  x: 'Length',
  y: 'Width',
  z: 'Height',
}

class PanelClipper extends Component {
  constructor(props) {
    super(props)
    var state = {
      visible: false,
      obj: null,
      allowEdit: true,
    }

    var injectState = props.state ? props.state : null
    if (props.obj) {
      injectState = this.stateFromObject(props.obj)
    }

    //Override with any supplied state - for use in storybook
    if (injectState) {
      for (var key in injectState) {
        state[key] = injectState[key]
      }
    }

    this.state = state
  }

  componentDidMount() {
    this.props.useStudioSignalsLazy(this.refreshPanel, ['objectChanged', 'objectSelected', 'sceneGraphChanged'], this)
  }

  stateFromObject(obj) {
    if (!obj || obj.type !== 'OsClipper') {
      return { visible: false }
    }
    var _state = {
      visible: true,
      obj: obj,
      ...this.getDimensions(obj),
      rotationZ: Math.round(obj.rotation.z * window.THREE.Math.RAD2DEG * 100) / 100,
    }

    stripKeyForActiveTextField.call(this, _state, obj)
    return _state
  }

  refreshPanel() {
    refreshPanelGeneric.call(this, 'OsClipper')
  }

  format = (value) => {
    const { measurements } = this.props
    if (measurements === 'imperial') {
      return trimDecimalPlaces(metersToFeet(value), 4)
    }
    return value
  }

  getDimensions(obj) {
    const result = {
      width: 0,
      height: 0,
      depth: 0,
    }
    if (obj && obj.geometry) {
      if (!obj.geometry.boundingBox) {
        obj.geometry.computeBoundingBox()
      }
      const min = obj.geometry.boundingBox.min
      const max = obj.geometry.boundingBox.max
      const scale = obj.scale
      Object.keys(DIMENSION_LABELS).forEach((dimension) => {
        result[DIMENSION_LABELS[dimension]] = this.format((max[dimension] - min[dimension]) * scale[dimension])
      })
    }
    return result
  }

  disableRotation() {
    const { obj } = this.state
    let disabled = false
    if (obj && obj.rotation) {
      const x = obj.rotation.x
      const y = obj.rotation.y
      if (Math.abs(x, 0.1) > 0.1 || Math.abs(y, 0.1) > 0.1) {
        return true
      }
    }
    return disabled
  }

  applyScaleValue(clipper, scaleAxis, value) {
    if (!clipper.geometry.boundingBox) {
      clipper.geometry.computeBoundingBox()
    }
    const bbox = clipper.geometry.boundingBox
    const scaleValue = value / (bbox.max[scaleAxis] - bbox.min[scaleAxis])
    const newScale = clipper.scale.clone()
    Object.assign(newScale, { [scaleAxis]: scaleValue })
    window.editor.execute(new window.SetScaleCommand(clipper, newScale))
  }

  applyRotationZ(clipper, value) {
    const rotation = clipper.rotation.clone()
    rotation.z = value / window.THREE.Math.RAD2DEG
    window.editor.execute(new window.SetRotationCommand(clipper, rotation))
  }

  render() {
    if (this.state.visible !== true) {
      return null
    } else if (this.state.obj && this.state.obj.ghostMode()) {
      const { translate } = this.props
      return (
        <Panel
          showTools={false}
          selectedObject={this.state.obj}
          title={translate('Placing Clipper')}
          summary={null}
          content={
            <p style={{ marginTop: 0 }}>
              {translate('Click anywhere on the imagery to drop the clipper and denote a location.')}
            </p>
          }
        />
      )
    } else {
      const { obj, rotationZ } = this.state

      const { translate } = this.props
      return (
        <Panel
          showTools={this.state.allowEdit}
          selectedObject={this.state.obj}
          title={translate('Tree Trimmer')}
          summary={null}
          content={null}
          feature={
            <StudioPanelContext.Provider value={{ context: this, object: obj }}>
              <div>
                {Object.keys(DIMENSION_LABELS).map((scaleAxis) => {
                  const key = DIMENSION_LABELS[scaleAxis]
                  const value = this.state[key]
                  return (
                    <DistanceNumberField
                      disabled={!this.state.allowEdit}
                      name={`clipper-${key}`}
                      label={key}
                      value={value}
                      maxDecimalPlaces={3}
                      minValue={0.001}
                      minErrorMsg={translate('Supply a value greater than 0.')}
                      measurementStd={this.props.measurements}
                      convertTo="metric"
                      onChange={(newValue) => {
                        this.applyScaleValue(obj, scaleAxis, newValue)
                      }}
                    />
                  )
                })}
                <CustomNumberField
                  disabled={!this.state.allowEdit || this.disableRotation()}
                  name="clipper-rotation"
                  label="Rotation"
                  value={rotationZ}
                  minValue={0}
                  maxValue={360}
                  maxDecimalPlaces={2}
                  resettable={true}
                  resetValue={0}
                  endAdornment="°"
                  onChange={(newValue) => {
                    this.applyRotationZ(obj, newValue)
                  }}
                />
              </div>
            </StudioPanelContext.Provider>
          }
        />
      )
    }
  }
}

PanelClipper.propTypes = {
  state: PropTypes.object,
}

export default compose(
  withTranslate,
  withStudioSignals,
  connect(
    (state) => ({
      measurements: getMeasurementsFromState(state),
    }),
    {}
  )
)(PanelClipper)

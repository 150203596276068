import { useMediaQuery } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { viewModeSelectors } from 'ducks/viewMode'
import QuickFilter from 'elements/QuickFilter'
import DateInput from 'elements/input/DateInput'
import { useCallback, useEffect, useRef } from 'react'
import {
  AutocompleteInput,
  BooleanInput,
  FilterComp,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
} from 'react-admin'
import { connect, useSelector } from 'react-redux'
import appStorage from 'storage/appStorage'
import { styles as standardStyles } from 'styles'
import { RootState } from 'types/state'
import { Theme } from 'types/themes'
import { dateParser } from 'util/misc'
import { useFeatureFlag } from 'util/split'
import { PRIORITY_CHOICES, PROJECT_TYPE_CHOICES } from '../choices'
import { SelectCommissionInput } from './SelectCommissionInput'

interface FilterData {
  orgId?: number
}

export const _ProjectFilter = (props) => {
  const filters = useRef<FilterData>({})
  filters.current = props.filterValues
  const hardwareFilter = props.filterValues?.fieldset
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const orgId = useSelector(authSelectors.getOrgId)
  const enableWallet = useFeatureFlag('os_wallet', 'on')
  const isOsLite = useSelector(viewModeSelectors.isUserLite)
  const connectedOrgs = useSelector(orgSelectors.getConnectedOrgs)

  const recordRecentFilter = useCallback(() => {
    if (props.context === 'form') {
      if (filters.current && Object.keys(filters.current).length > 0) {
        // Inject orgId so we can show filters only from current org
        filters.current.orgId = orgId
        const max_records = 5
        const filtersString = JSON.stringify(filters.current)
        const recentFilters: string[] = appStorage.getJSON('recentFilters') || []
        if (!recentFilters.includes(filtersString)) {
          recentFilters.unshift(filtersString)
          appStorage.setJSON('recentFilters', recentFilters.slice(0, max_records))
        }
      }
    }
  }, [])

  const trackSelectableInputChanged = useCallback((e) => {
    // Only tracking events that required
    const { name, value } = e.target || {}
    if (name) {
      logAmplitudeEvent('generic_filter_interacted', {
        action: 'updated',
        key: name,
        value,
      })
    }
  }, [])

  useEffect(() => {
    return recordRecentFilter
  }, [])

  const connectedOrgOptions = connectedOrgs.map((o) => ({
    id: o.org_id,
    name: o.org_name,
  }))

  const advancedFilters = [
    <ReferenceInput source="assigned_role_id" reference="roles">
      <AutocompleteInput
        source="email"
        optionText="display"
        optionValue="id"
        options={{ style: standardStyles.FILTER_FIELD_STYLE, variant: 'filled' }}
      />
    </ReferenceInput>,

    <ReferenceInput perPage={1000} source="commissions" label="Commission Used" reference="commissions">
      <SelectCommissionInput />
    </ReferenceInput>,

    <QuickFilter style={standardStyles.FILTER_FIELD_STYLE} source="is_unassigned" defaultValue={1} />,

    <ReferenceInput source="assigned_installer_role_id" reference="roles">
      <AutocompleteInput
        source="email"
        optionText="display"
        optionValue="id"
        options={{ style: standardStyles.FILTER_FIELD_STYLE, variant: 'filled' }}
      />
    </ReferenceInput>,

    <ReferenceInput source="assigned_site_inspector_role_id" reference="roles">
      <AutocompleteInput
        source="email"
        optionText="display"
        optionValue="id"
        options={{ style: standardStyles.FILTER_FIELD_STYLE, variant: 'filled' }}
      />
    </ReferenceInput>,

    <ReferenceInput source="assigned_designer_role_id" reference="roles">
      <AutocompleteInput
        source="email"
        optionText="display"
        optionValue="id"
        options={{ style: standardStyles.FILTER_FIELD_STYLE, variant: 'filled' }}
      />
    </ReferenceInput>,

    <ReferenceInput source="assigned_salesperson_role_id" reference="roles">
      <AutocompleteInput
        source="email"
        optionText="display"
        optionValue="id"
        options={{ style: standardStyles.FILTER_FIELD_STYLE, variant: 'filled' }}
      />
    </ReferenceInput>,

    <ReferenceInput source="tags" reference="tags" perPage={100}>
      <SelectInput
        options={{ style: standardStyles.FILTER_FIELD_STYLE, variant: 'filled' }}
        source="title"
        optionText="title"
        optionValue="id"
      />
    </ReferenceInput>,
  ]

  const connectedOrgFilters = [
    <SelectInput
      options={{ style: standardStyles.FILTER_FIELD_STYLE, variant: 'filled' }}
      source="owner_org_id"
      choices={connectedOrgOptions}
      label="Shared by"
    />,
    <SelectInput
      options={{ style: standardStyles.FILTER_FIELD_STYLE, variant: 'filled' }}
      source="visible_to"
      choices={connectedOrgOptions}
      label="Shared with"
    />,
  ]

  const advancedFilters2 = [
    <TextInput source="lead_source" options={{ variant: 'filled' }} style={standardStyles.FILTER_FIELD_STYLE} />,

    // <ReferenceInput label="Utility" source="utility_tariff_current__utility" reference="utilities">
    //   <AutocompleteInput optionText="name" optionValue="id" />
    // </ReferenceInput>,

    <SelectInput
      options={{ style: standardStyles.FILTER_FIELD_STYLE, variant: 'filled' }}
      source="priority"
      choices={PRIORITY_CHOICES}
      onChange={trackSelectableInputChanged}
      translateChoice={true}
    />,
    //<QuickFilter label="High Priority" source="priority" defaultValue={3} />,
    <BooleanInput style={standardStyles.FILTER_FIELD_STYLE} source="project_sold" label="Project Sold" />,
    <BooleanInput style={standardStyles.FILTER_FIELD_STYLE} source="project_installed" label="Project Installed" />,
    <ReferenceInput source="workflow_id" reference="workflows" filter={{ show_archived: 1 }} label="Project Workflow">
      <SelectInput
        showArchivedChoices={true}
        source="title"
        optionText="title"
        optionValue="id"
        options={{ style: standardStyles.FILTER_FIELD_STYLE, variant: 'filled' }}
      />
    </ReferenceInput>,
    <ReferenceInput source="stage_id" reference="stages" label="Project Stage">
      <SelectInput
        source="title"
        optionText={(value: any) => {
          if (value == null) return ''
          return value.title + (value.workflow_title ? ` (${value.workflow_title})` : '')
        }}
        optionValue="id"
        options={{ style: standardStyles.FILTER_FIELD_STYLE, variant: 'filled' }}
      />
    </ReferenceInput>,
    <ReferenceInput
      source="action_complete"
      reference="actions"
      perPage={1000}
      translateChoice={true}
      filter={{ is_workflow_action: 1 }}
    >
      <SelectInput
        options={{ style: standardStyles.FILTER_FIELD_STYLE, variant: 'filled' }}
        source="title"
        optionText={(value: any) => {
          if (value == null) return ''
          let optionTitle = value.title
          if (!!value.workflows.length) {
            value.workflows.forEach((x, i) => {
              if (i === 0) optionTitle = optionTitle + ' ('
              optionTitle = optionTitle + `${x.stage} - ${x.workflow}${i === value.workflows.length - 1 ? ')' : ', '}`
            })
          }
          return optionTitle
        }}
        optionValue="id"
      />
    </ReferenceInput>,
    <ReferenceInput source="action_incomplete" reference="actions" perPage={1000} translateChoice={true}>
      <SelectInput
        options={{ style: standardStyles.FILTER_FIELD_STYLE, variant: 'filled' }}
        source="title"
        optionText={(value: any) => {
          if (value == null) return ''
          let optionTitle = value.title
          if (!!value.workflows.length) {
            value.workflows.forEach((x, i) => {
              if (i === 0) optionTitle = optionTitle + ' ('
              optionTitle = optionTitle + `${x.stage} - ${x.workflow}${i === value.workflows.length - 1 ? ')' : ', '}`
            })
          }
          return optionTitle
        }}
        optionValue="id"
      />
    </ReferenceInput>,
  ]

  const advancedFilters3 = [
    <ReferenceInput source="systems__pricing_scheme" reference="pricing_schemes">
      <SelectInput
        options={{ style: standardStyles.FILTER_FIELD_STYLE, variant: 'filled' }}
        source="title"
        optionText="title"
        optionValue="id"
      />
    </ReferenceInput>,
    <ReferenceInput source="proposal_template" reference="proposal_templates" filter={{ show_archived: 1 }}>
      <SelectInput
        options={{ style: standardStyles.FILTER_FIELD_STYLE, variant: 'filled' }}
        showArchivedChoices={true}
        source="title"
        optionText="title"
        optionValue="id"
      />
    </ReferenceInput>,
    <BooleanInput style={standardStyles.FILTER_FIELD_STYLE} source="has_systems" />,
    <QuickFilter
      style={standardStyles.FILTER_FIELD_STYLE}
      source="has_sold_system"
      defaultValue={1}
      label={'Has Sold System specified'}
    />,
    <QuickFilter
      style={standardStyles.FILTER_FIELD_STYLE}
      source="has_system_without_pricing_scheme"
      defaultValue={1}
    />,
    <QuickFilter
      style={standardStyles.FILTER_FIELD_STYLE}
      source="has_installed_system"
      defaultValue={1}
      label={'Has Installed System specified'}
    />,
  ]

  const soldOnFilters = [
    <DateInput
      source="sold_on_or_after"
      inputVariant="outlined"
      style={standardStyles.FILTER_FIELD_STYLE}
      parse={dateParser}
    />,
    <DateInput
      source="sold_on_or_before"
      style={standardStyles.FILTER_FIELD_STYLE}
      inputVariant="outlined"
      parse={dateParser}
    />,
  ]

  const installedOnFilters = [
    <DateInput
      source="installation_date_gte"
      style={standardStyles.FILTER_FIELD_STYLE}
      inputVariant="outlined"
      parse={dateParser}
    />,
    <DateInput
      source="installation_date_lte"
      style={standardStyles.FILTER_FIELD_STYLE}
      inputVariant="outlined"
      parse={dateParser}
    />,
  ]

  return (
    <FilterComp className="filter-id" style={{ minWidth: isSmall ? 0 : 135 }} {...props}>
      <SelectInput
        options={{ style: standardStyles.FILTER_FIELD_STYLE, variant: 'filled' }}
        source="is_lite"
        onChange={trackSelectableInputChanged}
        choices={PROJECT_TYPE_CHOICES}
        translateChoice={true}
        label="Project Type"
      />
      {isOsLite ? null : advancedFilters}
      {!connectedOrgs.length ? null : connectedOrgFilters}
      <TextInput
        source="locality"
        options={{ variant: 'filled' }}
        style={standardStyles.FILTER_FIELD_STYLE}
        label="resources.projects.fields.locality"
      />
      <TextInput
        source="zips"
        options={{ variant: 'filled' }}
        style={standardStyles.FILTER_FIELD_STYLE}
        label="resources.projects.fields.zip"
      />
      <TextInput
        source="states"
        options={{ variant: 'filled' }}
        style={standardStyles.FILTER_FIELD_STYLE}
        label="resources.projects.fields.state"
      />
      {isOsLite ? null : advancedFilters2}
      <QuickFilter style={standardStyles.FILTER_FIELD_STYLE} source="starred" defaultValue={1} />
      <QuickFilter
        source="filter"
        style={standardStyles.FILTER_FIELD_STYLE}
        defaultValue={props.recentProjects.map((recentProject) => recentProject.id).join(',')}
      />
      {isOsLite ? null : advancedFilters3}
      <NumberInput style={standardStyles.FILTER_FIELD_STYLE} source="has_system_larger_than" />
      {isOsLite ? null : soldOnFilters}
      <DateInput
        source="created_date_gte"
        style={standardStyles.FILTER_FIELD_STYLE}
        inputVariant="outlined"
        parse={dateParser}
      />
      <DateInput
        source="created_date_lte"
        style={standardStyles.FILTER_FIELD_STYLE}
        inputVariant="outlined"
        parse={dateParser}
      />
      <DateInput
        source="modified_date_gte"
        style={standardStyles.FILTER_FIELD_STYLE}
        inputVariant="outlined"
        parse={dateParser}
      />
      <DateInput
        source="modified_date_lte"
        style={standardStyles.FILTER_FIELD_STYLE}
        inputVariant="outlined"
        parse={dateParser}
      />
      {isOsLite ? null : installedOnFilters}
      {isOsLite || !enableWallet ? null : (
        <QuickFilter
          source="has_premium_imagery_activation"
          style={standardStyles.FILTER_FIELD_STYLE}
          defaultValue={true}
        />
      )}
    </FilterComp>
  )
}

export const ProjectFilter = connect(
  (state: RootState) => ({ recentProjects: state.recentProjects }),
  {}
)(_ProjectFilter)

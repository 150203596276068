import _ from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'

const usePosition = (selector: string, maxWait: number = 5000, elementToObserve?: HTMLElement, keepObserve = false) => {
  const [position, setPosition] = useState(null)
  const observerRef = useRef<any>(null)

  const removeObserver = useCallback(() => {
    if (observerRef.current) {
      // console.log('clear MutationObserver')
      observerRef.current.disconnect()
      observerRef.current = null
    }
  }, [])

  const handleResize = useCallback(() => {
    const element = document.getElementById(selector)
    if (element) {
      // console.log('usePosition resize')
      populatePosition(element)
    }
  }, [])

  const populatePosition = useCallback((element) => {
    setPosition(element.getBoundingClientRect())
  }, [])

  const listenElementChange = useCallback((element) => {
    // console.log('keep observing')
    const debouncedPopulatePosition = _.debounce(populatePosition, 400)
    observerRef.current = new MutationObserver(() => {
      const element = document.getElementById(selector)
      if (element) {
        debouncedPopulatePosition(element)
      }
    })
    observerRef.current.observe(element, { childList: true, subtree: true })
  }, [])

  const handleElementFound = useCallback((element) => {
    if (observerRef.current) {
      removeObserver()
    }
    populatePosition(element)
    if (keepObserve) {
      listenElementChange(element)
    }
  }, [])

  useEffect(() => {
    const element = document.getElementById(selector)
    const handleResizeDebounced = _.debounce(handleResize, 200)
    //known problem, if element found on mounted then keepObserve won't work
    if (element) {
      handleElementFound(element)
    } else {
      // console.log('new MutationObserver:' + selector)
      observerRef.current = new MutationObserver(() => {
        // console.log('MutationObserver change')
        const element = document.getElementById(selector)
        if (element) {
          handleElementFound(element)
        }
      })
      observerRef.current.observe(elementToObserve || document, { childList: true, subtree: true })
    }

    if (!keepObserve) {
      //do not remove observer if we want to keep observing element change
      setTimeout(removeObserver, maxWait)
    }
    window.addEventListener('resize', handleResizeDebounced)
    window.addEventListener('scroll', handleResizeDebounced)

    return () => {
      removeObserver()
      window.removeEventListener('resize', handleResizeDebounced)
      window.addEventListener('scroll', handleResizeDebounced)
    }
  }, [selector])
  return position
}

export default usePosition

import React, { useCallback } from 'react'
import type { ComponentTypes } from 'types/selectComponent'
import { CURRENT_FILTER_KEY } from '../currentType/CurrentTypeFilterNodeFactory'
import { OTHER_COMPONENT_FILTER_KEY } from '../otherComponentType/OtherComponentTypeFilterNodeFactory'
import type {
  FilterComponentRendererType,
  FilterGenericPropsType,
  FilterKeysType,
  FilterValuesType,
  OptionGenericType,
} from '../type'

export type FilterOptionsType = OptionGenericType<string>[]

type FilterProps = FilterGenericPropsType & {
  RendererComponent: FilterComponentRendererType<FilterOptionsType>
  filterKey: FilterKeysType
  componentType: ComponentTypes
}

export const isOtherComponentPhaseFilterAvailable = (filterValues: FilterValuesType) => {
  return (
    (filterValues[OTHER_COMPONENT_FILTER_KEY] === 'isolator' && filterValues[CURRENT_FILTER_KEY] === 'ac') ||
    filterValues[OTHER_COMPONENT_FILTER_KEY] === 'meter'
  )
}

const PHASE_TYPE_OPTIONS: FilterOptionsType = [
  { id: 'default', title: 'All', value: '' },
  { id: 'single_phase', title: 'Single phase', value: 'single_phase' },
  { id: 'three_phase', title: 'Three phase', value: 'three_phase' },
]

const PhaseTypeFilter = ({
  filterKey,
  label,
  componentType,
  modifiableFilterValues,
  persistentFilterValues,
  allFilterValues,
  setModifiableFilters,
  RendererComponent,
}: FilterProps) => {
  const onSelect = useCallback(
    (value) => {
      setModifiableFilters({ ...modifiableFilterValues, [filterKey]: value })
    },
    [modifiableFilterValues]
  )

  const disabled = componentType !== 'inverter' && !isOtherComponentPhaseFilterAvailable(allFilterValues)

  return (
    <RendererComponent
      id={filterKey}
      defaultValue={''}
      label={label || 'Phase'}
      options={PHASE_TYPE_OPTIONS}
      disabled={disabled}
      onSelect={onSelect}
      value={allFilterValues[filterKey]}
    />
  )
}

export default PhaseTypeFilter

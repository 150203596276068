// @ts-nocheck
import { logAmplitudeEvent } from 'amplitude/amplitude'
import GenericButton from 'elements/button/GenericButton'
import React, { useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { ProjectType } from 'types/projects'
import { getRoleIdFromState } from 'util/misc'
import GreenLancerDialog from '.'

const restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  project: ProjectType
  isIronRidge: boolean
}

const GreenLancerCta: React.FunctionComponent<PropTypes> = (props) => {
  const translate = useTranslate()
  const notify = useNotify()
  const greenLancerProjectId: string | undefined = props.project?.greenlancer_project_id

  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [fetchingProjectURL, setFetchingProjectURL] = useState<boolean>(false)
  const [forceShowConnected, setForceShowConnected] = useState<boolean>(false)
  const [forceShowExistingProject, setForceShowExistingProject] = useState<boolean>(false)

  const orgId = props.project?.org_id

  const roleId: number | undefined = useSelector(getRoleIdFromState)
  const projectId = props.project?.id

  const alertToError = () =>
    notify(
      "Sorry, we are unable to bring you to this project on GreenLancer's site. Please be sure your account is connected",
      'warning'
    )

  const getProjectURL = () => {
    logAmplitudeEvent('greenlancer_view_project_clicked', {
      page: window.location.hash?.includes('/manage') ? 'Manage Page' : 'Info Page',
    })

    if (fetchingProjectURL) return
    setFetchingProjectURL(true)
    let url = `orgs/${orgId}/roles/${roleId}/projects/${projectId}/get_greenlancer_project_url/`
    restClientInstance('CUSTOM_GET', 'accept_terms', {
      url,
    })
      .then((res: any) => {
        if (res && res.data && res.data.url) window.open(res.data.url, '_blank')
        else alertToError()
      })
      .catch((err: any) => {
        console.log('err', err)
        alertToError()
      })
      .finally(() => setFetchingProjectURL(false))
  }

  const onClick = () => setShowDialog(true)

  // the dialog loses state when it's dismissed so if the user connects, then dismises, the dialog needs to be told explicitly to render as if it's connected
  // upon refresh this is no longer a problem because redux will be udpated with credentials
  const onAccountConnected = () => {
    setForceShowConnected(true)
  }

  const onProjectCreated = () => {
    setForceShowExistingProject(true)
  }

  return (
    <div>
      {!!greenLancerProjectId || forceShowExistingProject ? (
        <GenericButton
          id="MyEnergyInviteButton"
          style={{ marginTop: 10, width: '100%', border: '1px solid rgb(36, 166, 59)' }}
          //@ts-ignore
          wrapperStyle={{ width: '100%' }}
          labelColor="rgb(36, 166, 59)"
          backgroundColor="#FFF"
          backgroundColorOnHover="#ececec"
          opacityOnHover={0.5}
          labelWrapperStyle={{
            padding: '0 0 0 6px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            textDecoration: 'none',
          }}
          disabled={fetchingProjectURL}
          onClick={getProjectURL}
          label={translate('View Project in GreenLancer')}
          startIcon={<img src={`${window.PUBLIC_URL}/images/GreenLancer-logo.png`} alt="" height={20} width={20} />}
        />
      ) : (
        <GenericButton
          id="MyEnergyInviteButton"
          style={{ marginTop: 10, width: '100%', border: '1px solid rgb(36, 166, 59)' }}
          //@ts-ignore
          wrapperStyle={{ width: '100%' }}
          labelColor="rgb(36, 166, 59)"
          backgroundColor="#FFF"
          backgroundColorOnHover="#ececec"
          labelWrapperStyle={{
            padding: '0 0 0 6px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            textDecoration: 'none',
          }}
          onClick={onClick}
          label={translate('Send Project to GreenLancer')}
          startIcon={<img src={`${window.PUBLIC_URL}/images/GreenLancer-logo.png`} alt="" height={20} width={20} />}
        />
      )}
      {showDialog && (
        <GreenLancerDialog
          dismiss={() => setShowDialog(false)}
          isOpen={showDialog}
          project={props.project}
          onAccountConnected={onAccountConnected}
          forceShowConnected={forceShowConnected}
          onProjectCreated={onProjectCreated}
          isIronRidge={props.isIronRidge}
        />
      )}
    </div>
  )
}
export default GreenLancerCta

import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Inbox from '@material-ui/icons/InboxOutlined'
import inflection from 'inflection'
import { useTranslate } from 'ra-core'
import React from 'react'

const useStyles = makeStyles(
  (theme) => ({
    message: {
      textAlign: 'center',
      opacity: theme.palette.type === 'light' ? 0.5 : 0.8,
      margin: '48px 0px',
      color: theme.palette.type === 'light' ? 'inherit' : theme.palette.text.primary,
    },
    icon: {
      width: '9em',
      height: '9em',
    },
    toolbar: {
      textAlign: 'center',
      marginTop: '2em',
    },
  }),
  { name: 'RaEmpty' }
)

const Empty = (props) => {
  const { resource, basePath } = props
  const classes = useStyles(props)
  const translate = useTranslate()

  const resourceName =
    props.resourceName ||
    inflection.humanize(
      translate(`resources.${resource}.name`, {
        smart_count: 0,
        _: inflection.pluralize(resource),
      }),
      true
    )

  const emptyMessage = translate('ra.page.empty', { name: resourceName })
  const inviteMessage = translate('ra.page.invite')

  return (
    <>
      <div className={classes.message}>
        <Inbox className={classes.icon} />
        <Typography variant="h4" paragraph>
          {translate(`resources.${resource}.empty`, {
            _: emptyMessage,
          })}
        </Typography>
        <Typography variant="body1">
          {translate(`resources.${resource}.invite`, {
            _: inviteMessage,
          })}
        </Typography>
      </div>
      {/* <div className={classes.toolbar}>
                <CreateButton variant="contained" basePath={basePath} />
            </div> */}
    </>
  )
}

export default Empty

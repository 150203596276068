import { Grid, Paper } from '@material-ui/core'
import React from 'react'
import { NumberInput, TextInput, useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'
import CustomField from '../../../elements/field/CustomField'
import { styles } from '../styles.js'

export const LoanParameters = ({ resource, disabled = false, style: disabledStyle = null }) => {
  const formState = useFormState()
  const paymentFactorsEnabled = !!formState.values?.variable_payment_factors_enabled
  const translate = useTranslate()

  return (
    <Paper style={disabled && disabledStyle ? disabledStyle : styles.loanWrapper}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h1>{translate('Loan Parameters')}</h1>
        </Grid>
        <Grid item xs={12}>
          <CustomField
            resource={resource}
            name="variable_lender_name"
            source="variable_lender_name"
            style={disabled ? disabledStyle : styles.inputFieldLessWide}
            label={'Lender Name'}
            disabled={disabled}
            component={TextInput}
          />
        </Grid>
        <Grid item xs={3}>
          <NumberInput
            resource={resource}
            style={disabled ? disabledStyle : styles.inputFieldLessWide}
            label={'Interest Rate'}
            source={'variable_interest_rate'}
            name={'variable_interest_rate'}
            endAdornment={'% p.a.'}
            disabled={disabled || paymentFactorsEnabled}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <NumberInput
            step={1}
            resource={resource}
            style={styles.inputFieldLessWide}
            label={'Loan Term'}
            source={'variable_term_years'}
            name={'variable_term_years'}
            endAdornment={'years'}
            disabled={disabled}
            fullWidth
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

import { CSSProperties } from '@material-ui/core/styles/withStyles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { OpenSolarThemeType } from 'Themes'
import classnames from 'classnames'
import type { StandardOsIconComponentType } from 'opensolar-ui'
import { useSortedProjectErrors } from 'projectSections/errors/useSortedProjectErrors'
import { memo } from 'react'
import { ProjErrorCategory } from 'reducer/project/projectErrorsReducer'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

export const useTabStyle = makeOpenSolarStyles((theme: OpenSolarThemeType) => {
  const overlayIconStyle: CSSProperties = {
    height: 20,
    left: '-2px',
    position: 'absolute',
    top: '-2px',
    width: 20,
  }

  return {
    button: {
      background: 'rgb(255,255,255)',
      border: 'none',
      borderRadius: 8,
      color: '#4F4D55',
      display: 'flex',
      fontSize: 10,
      lineHeight: '12px',
      padding: '8px',
      textTransform: 'none',
      '&:hover': {
        cursor: 'pointer',
        background: theme.greyMid2,
      },
    },
    wrapper: {
      position: 'relative',
    },
    tickIcon: {
      ...overlayIconStyle,
      color: theme.straitPalette.blue[800],
    },
    errorIcon: {
      ...overlayIconStyle,
      color: theme.alertIcon_error,
    },
    warningIcon: {
      ...overlayIconStyle,
      color: theme.alertIcon_warning,
    },
    infoIcon: {
      ...overlayIconStyle,
      color: 'rgb(44, 73, 157)',
    },
    selected: {
      color: theme.straitPalette.blue[900],
      background: theme.straitPalette.blue[400],
      borderRadius: 8,
    },
  }
})

export type TabButtonProps = {
  errorCategories: ProjErrorCategory[]
  showTick?: boolean
  disabled?: boolean
  selected: boolean
  onClick(): void
  tooltipMessage: string
  id?: string
  Icon: StandardOsIconComponentType
}

const TabButton = (props: TabButtonProps) => {
  const classes = useTabStyle()
  const { tooltipMessage, errorCategories, disabled, Icon, id, selected, showTick, onClick } = props

  const sortedErrors = useSortedProjectErrors([
    {
      categories: errorCategories,
      systemIds: [window.editor?.selectedSystem?.uuid],
      severities: ['error', 'warning', 'info'],
    },
  ])
  const highestSeverity = sortedErrors[0]?.severity
  const hasErrors = sortedErrors.length > 0

  const getOutlinedIconClass = () => {
    switch (highestSeverity) {
      case 'error':
        return classes.errorIcon
      case 'warning':
        return classes.warningIcon
      case 'info':
      default:
        return classes.infoIcon
    }
  }

  return (
    <div className={classes.wrapper}>
      <button
        disabled={disabled}
        id={id}
        className={classnames(classes.button, selected ? classes.selected : '')}
        onClick={onClick}
        title={tooltipMessage}
      >
        <Icon width="28" height="28" />
      </button>
      {showTick && !hasErrors && <CheckCircleIcon className={classes.tickIcon} />}
      {hasErrors && (
        <InfoOutlinedIcon
          classes={{
            root: getOutlinedIconClass(),
          }}
        />
      )}
    </div>
  )
}

export default memo(TabButton)

import { Route, Switch } from 'react-router-dom'
import CustomerCommunicationsPage from '../configuration/customerCommunications/CustomerCommunicationsPage'
import Documents from '../configuration/documents'
import FinancedProjectDepositsFormWrapper from '../configuration/financedDeposits/FinancedProjectDeposits'
import InternalNotificationsPage from '../configuration/internalNotifications/InternalNotificationsPage'
import PaymentMilestonesPage from '../configuration/paymentMilestones/PaymentMilestonesPage'
import Registration from '../configuration/registration/Registration'
import Invoices from '../invoices'
import Projects from '../projects'

const CashFlowRoutes = () => {
  return (
    <div>
      <Switch>
        <Route exact path={'/cashflow/configure/milestones'} component={PaymentMilestonesPage} />
        <Route exact path={'/cashflow/configure/communications'} component={CustomerCommunicationsPage} />
        <Route exact path={'/cashflow/configure/financed_deposits'} component={FinancedProjectDepositsFormWrapper} />
        <Route exact path={'/cashflow/configure/notifications'} component={InternalNotificationsPage} />
        <Route exact path={'/cashflow/configure/documents'} component={Documents} />
        <Route exact path={'/cashflow/configure/registration'} component={Registration} />
        <Route exact path={'/cashflow/projects'} component={Projects} />
        <Route exact path={'/cashflow/invoices'} component={Invoices} />
      </Switch>
    </div>
  )
}

export default CashFlowRoutes

import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'
import ProUXButton from '../../../../../elements/proUXButtons/ProUXButton'
import { BecsAgreementDataType } from '../types'

type PropTypes = {
  open: boolean
  onClose: () => void
  agreementData: BecsAgreementDataType | null
}

const BECSAgreementForm: React.FC<PropTypes> = ({ open, onClose, agreementData }) => {
  const translate = useTranslate()

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{translate('Direct Debit Request Service Agreement')}</DialogTitle>
      <DialogContent>
        <Typography variant="body1" align="justify">
          {agreementData?.agreementText || translate('No agreement data available.')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <ProUXButton type="primary" label={translate('Close')} onClick={onClose} size="medium" />
      </DialogActions>
    </Dialog>
  )
}

export default BECSAgreementForm

import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from '@material-ui/core'
import { HelpOutline } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { featureConfigSelectors, getPublicFeatureConfig } from 'ducks/featureConfig'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import LoadingDots from 'layout/widgets/LoadingDots'
import { IconButton } from 'opensolar-ui'
import { useNotify } from 'ra-core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'
import { RootState } from 'types/state'

const restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  isOpen: boolean
  onClose: () => void
  lenderTitle: string
  lenderName: string
  logoURL: string
}

type RateChangeConfigType = {
  [key: string]: {
    paragraph_1: string
    paragraph_2?: string
    show_fee_override: boolean
  }
}

type GetOrgConfigResponseType = {
  data: {
    success: boolean
    data: OrgConfigDataType
  }
}

type OrgConfigDataType = {
  strategy: string
  fee_difference_override_percentage: number
}

const STRATEGY_CHOICES = [
  {
    id: 'closest_fee_same_term',
    description: 'FOR RATE INCREASES - Closest Dealer Fee Without Changing Term',
    tooltip_text:
      'In a rising interest rate environment this will reduce the price changes for a system but will result in higher APRs',
  },
  {
    id: 'closest_dealer_fee',
    description: 'FOR RATE INCREASES - Closest Dealer Fee Across ALL Terms',
    tooltip_text:
      'In a rising interest rate environment this will reduce the price changes for a system but will result in higher APRs. But this strategy finds the closest fee across all terms so it may result in different loan terms',
  },
  {
    id: 'closest_rate_same_term',
    description: 'FOR RATE INCREASES OR DECREASES - Closest Interest Rate Without Changing Term',
    tooltip_text:
      'In a rising interest rate environment this will generally result in a higher system price. If rates are decreasing this will generally result in a lower system price.',
  },
  {
    id: 'maintain_product_change_price',
    description: 'FOR RATE DECREASES - Maintain Margin, Decrease Price',
    tooltip_text:
      'In a decreasing rate environment this allows you to keep a consistent price for your customer while generally increasing margin due to lower dealer fees',
  },
  {
    id: 'lowest_APR_without_raising_fee',
    description: 'FOR RATE DECREASES - Lowest APR Without Increasing Dealer Fee',
    tooltip_text:
      'In a decreasing rate environment this will let you keep the system price as consistent as possible while giving your customer a lower APR',
  },
]

const RateChangeManagementDialog: React.FC<PropTypes> = (props) => {
  const [selectedStrategy, setSelectedStrategy] = useState<string | undefined>(undefined)
  const [selectedDealerFeeOverride, setSelectedDealerFeeOverride] = useState<string>('0')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const notify = useNotify()
  const dispatch = useDispatch()
  const featureConfig: RateChangeConfigType | undefined = useSelector((state: RootState) =>
    featureConfigSelectors.getFeatureConfigData(state, 'rate_changes')
  )
  const isStaff = useSelector(authSelectors.getIsStaff)
  const orgId = useSelector(authSelectors.getOrgId)

  useEffect(() => {
    dispatch(getPublicFeatureConfig('rate_changes'))
    getExistingOrgConfig()
  }, [])

  const getExistingOrgConfig = () => {
    setIsLoading(true)
    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${orgId}/${props.lenderName}/rate_change_strategy/`,
    })
      .then((response: GetOrgConfigResponseType) => {
        if (response?.data?.success && response.data.data) {
          if (response.data.data.strategy) setSelectedStrategy(response.data.data.strategy)
          if (response.data.data.fee_difference_override_percentage)
            setSelectedDealerFeeOverride(`${response.data.data.fee_difference_override_percentage}`)
        }
      })
      .catch((err: any) => {
        console.log('err', err)
      })
      .finally(() => setIsLoading(false))
  }

  const save = () => {
    let feeOverrideNum: number | undefined = undefined
    if (featureConfig && featureConfig[props.lenderName].show_fee_override) {
      try {
        feeOverrideNum = parseFloat(selectedDealerFeeOverride)
        if (feeOverrideNum === undefined || feeOverrideNum < 0 || feeOverrideNum > 100) {
          notify('Dealer Fee Difference must be a valid number between 0 and 100', 'warning')
          return
        }
      } catch (ex) {
        notify('Dealer Fee Difference must be a valid number', 'warning')
        return
      }
    }
    restClientInstance('CUSTOM_PUT', 'custom', {
      url: `orgs/${orgId}/${props.lenderName}/rate_change_strategy/`,
      data: {
        strategy: selectedStrategy,
        fee_difference_override_percentage: feeOverrideNum,
      },
    })
      .then((response) => {
        if (response.data.success) {
          logAmplitudeEvent('rate_change_management_saved', {
            strategy: selectedStrategy,
            dealer_fee_override: selectedStrategy === 'maintain_product_change_price' ? '100' : `${feeOverrideNum}`,
          })
          notify('Your settings have been saved', 'success')
          props.onClose()
        }
      })
      .catch((err: any) => {
        console.log('err', err)
      })
  }
  if (!featureConfig || !featureConfig[props.lenderName]) return null
  return (
    <Dialog open={props.isOpen} onClose={props.onClose}>
      <DialogContent>
        {isLoading ? (
          <div style={{ height: '300px' }}>
            <LoadingDots text="Fetching your current settings..." />
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <img alt={props.lenderName} src={props.logoURL} style={{ height: 35 }} />
            <p style={{ margin: '10px 0px 20px 0px' }}>{featureConfig[props.lenderName].paragraph_1}</p>
            {featureConfig[props.lenderName].paragraph_2 && (
              <p style={{ margin: '10px 0px' }}>{featureConfig[props.lenderName].paragraph_2}</p>
            )}
            <div>
              <div>
                <h4>How should we choose the new payment option?</h4>
                <RadioGroup
                  style={{ alignItems: 'flex-start' }}
                  name="pmt-options"
                  value={selectedStrategy}
                  onChange={(e) => setSelectedStrategy(e.target.value)}
                >
                  {STRATEGY_CHOICES.map((strategy) => {
                    return (
                      <div>
                        <FormControlLabel
                          key={strategy.id}
                          value={strategy.id}
                          control={<Radio />}
                          label={<span style={{ fontSize: 13 }}>{strategy.description}</span>}
                        />
                        <Tooltip
                          title={strategy.tooltip_text}
                          style={{ zIndex: 99999 }}
                          PopperProps={{ style: { zIndex: 9999 } }}
                        >
                          <IconButton>
                            <HelpOutline fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )
                  })}
                  {isStaff && (
                    <FormControlLabel
                      key={'keep_existing'}
                      value={'keep_existing'}
                      control={<Radio />}
                      label={
                        <span style={{ fontSize: 13 }}>STAFF ONLY - Keep the existing archived payment option</span>
                      }
                    />
                  )}
                </RadioGroup>
              </div>
              {featureConfig[props.lenderName].show_fee_override && (
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                  <h4>How should we manage dealer fee changes?</h4>
                  <span style={{ padding: '0px 0px 10px 0px' }}>
                    If you want to limit the change in system price by reducing your margin by a certain percentage you
                    can do so by using the field below:
                  </span>
                  <TextField
                    type="number"
                    value={selectedDealerFeeOverride}
                    onChange={(e) => setSelectedDealerFeeOverride(e.target.value)}
                    label="Absorb Dealer Fee Difference"
                    fullWidth
                    InputProps={{
                      startAdornment: <InputAdornment position="start">%</InputAdornment>,
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div style={{ margin: '10px' }}>
            <ProUXButton label="Cancel" onClick={props.onClose} type="secondary" />
          </div>
          <div style={{ margin: '10px' }}>
            <ProUXButton label="Save" onClick={save} type="primary" />
          </div>
        </div>
      </DialogActions>
    </Dialog>
  )
}
export default RateChangeManagementDialog

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import Button from 'elements/button/Button'
import { memo } from 'react'

type Props = {
  open: boolean
  handleClose: () => void
  handleProceedCheckout: () => void
}

const CustomPricingNotAvailableModal = ({ open, handleClose, handleProceedCheckout }: Props) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Review your payment method</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ color: 'black' }}>
          Custom pricing is not available for non-credit orders. Are you sure you want to continue?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="default">
          Cancel
        </Button>
        <Button onClick={handleProceedCheckout} color="primary">
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default memo(CustomPricingNotAvailableModal)

import { ComponentBatteryCreate, ComponentBatteryEdit, ComponentBatteryList } from './batteryComponents'

import { batteryDataFields, customBatteryDataFields } from './constants'

export default {
  list: ComponentBatteryList,
  edit: ComponentBatteryEdit,
  create: ComponentBatteryCreate,
}
export { batteryDataFields, customBatteryDataFields }

import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { getCountryDefaultSupplier, getSupplierConfig } from 'pages/ordering/configs'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import type { ComponentTypes } from 'types/selectComponent'
import { HardwareSupplierFilterKeyType } from '../type'

type PersistentOrderingQueryParams = {
  catalog: 'full' | 'basic'
  show_exhibited_first: boolean
  require_distributor: HardwareSupplierFilterKeyType | false
  org_id: number | undefined
  country_iso2: string | undefined
  component_type?: ComponentTypes
}

/*
 This hook returns a list of persistent filter value for fetching hardware components data.
 These values are not managed by the user interface but are crucial for formatting the correct query parameters.
*/

const usePersistentOrderingQueryParams = ({
  showExhibitedFirst,
  catalog,
  componentType,
}: {
  showExhibitedFirst: boolean
  catalog: 'full' | 'basic'
  componentType?: ComponentTypes
}): PersistentOrderingQueryParams => {
  const selectedSupplier = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const orgCountry = useSelector(authSelectors.getCurrentOrgCountry)
  const preferenceHardwareSupplierConfig =
    getSupplierConfig(selectedSupplier) || getCountryDefaultSupplier(orgCountry || '')
  const orgId = useSelector(authSelectors.getOrgId)

  return useMemo(() => {
    return {
      catalog,
      show_exhibited_first: showExhibitedFirst,
      require_distributor: preferenceHardwareSupplierConfig?.filterKey || false,
      org_id: orgId,
      country_iso2: orgCountry,
      component_type: componentType,
    }
  }, [showExhibitedFirst, preferenceHardwareSupplierConfig?.filterKey, orgId, orgCountry, componentType])
}

export default usePersistentOrderingQueryParams

import { FormControl, FormControlLabel, makeStyles } from '@material-ui/core'
import useOptimisticValue from 'elements/input/useOptimisticValue'
import { Radio, RadioGroup } from 'opensolar-ui'
import { ChangeEvent } from 'react'
import { Theme } from 'types/themes'
import type { BasicRendererComponentPropsTypeV2 } from '../typeV2'
import useLimitedOptionsController from './useLimitedOptionsController'

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  filterOptions: {
    fontSize: '14px',
    fontWeight: 400,
    textAlign: 'left',
    margin: '0',
    padding: '0px',
    '& .MuiFormControlLabel-label': {
      fontWeight: 400,
      lineHeight: '20px',
    },
    '& .MuiIconButton-root, .OSUI-Radio-root': {
      padding: '3px 12px',
    },
  }
}))

/*
  A radio renderer for hardware filters
*/
const BasicRadioButtonRendererV2 = <OptionValue extends string | number>({
  id,
  label,
  options,
  disabled,
  onSelect,
  defaultValue,
  additionalProps,
  value,
}: BasicRendererComponentPropsTypeV2<OptionValue, OptionValue>) => {
  const classes = useStyles({ disabled: !!disabled })
  const { optimisticValue, updateOptimisticValue } = useOptimisticValue<OptionValue>({ defaultValue, value })
  const { limitedOptions, LimitedOptionsController } = useLimitedOptionsController(options)

  const handleChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    const newValue = event.target.value as OptionValue
    const finalValue = additionalProps?.rendererOnChangeMutator
      ? additionalProps.rendererOnChangeMutator({
          value: newValue,
          oldValue: optimisticValue,
          options: options,
          selectedOption: limitedOptions.find((option) => option.value === newValue)!,
        })
      : newValue
    // TODO: FIX: this is a problem for boolean and number values
    // No need to for updateOptimisticValue() since useOptimisticValue have listeners for any updates. Except when value mapping is not direct (e.g. ViewFromFilterNodeFactoryV2) as specified in the additionalProps
    additionalProps?.shouldForceUpdateOptimisticValue && updateOptimisticValue(finalValue)
    onSelect(finalValue, limitedOptions.find((option) => option.value?.toString() === finalValue)!)
  }

  return (
    <FormControl id={id} disabled={disabled}>
        <RadioGroup value={optimisticValue} onChange={handleChange}>
          {limitedOptions.map((option, i) => {
            return (
              <FormControlLabel
                className={classes.filterOptions}
                value={option.value}
                key={option.id}
                control={
                  <Radio size="small" color="info" checked={optimisticValue?.toString() === option.value?.toString()} />
                }
                label={option.title}
              />
            )
          })}
        </RadioGroup>

      {!disabled && LimitedOptionsController}
    </FormControl>
  )
}

export default BasicRadioButtonRendererV2

import { HelpLink } from 'elements/link/HelpLink'
import React from 'react'
import { useTranslate } from 'react-admin'
import { DocumentTemplateCreate, DocumentTemplateEdit, DocumentTemplateList } from './DocumentTemplates'

const Subtitle = () => {
  const translate = useTranslate()
  return (
    <span>
      {translate(
        "Create document templates to allow you to quickly generate standard documents for each project, from the Info page in the Projects zone. You can use a customisable template for Owner's Manuals or Installation Instructions, or create your own from scratch."
      )}
      <HelpLink href="https://support.opensolar.com/hc/en-us/articles/4407444701977" />
    </span>
  )
}

const DocumentTemplateTitle = ({ record, translate, style }) => {
  return (
    <span style={style}>
      {translate('Document Template')} {record ? `"${record.title}"` : ''}
    </span>
  )
}

export default {
  list: DocumentTemplateList,
  edit: DocumentTemplateEdit,
  create: DocumentTemplateCreate,
  options: {
    create: {
      title: 'Create Template',
      subtitle: null,
      breadCrumb: 'Create Template',
    },
    list: {
      title: 'My Document Templates List',
      subtitle: <Subtitle />,
      breadCrumb: 'Document Templates',
    },
    edit: {
      title: <DocumentTemplateTitle />,
      subtitle: null,
      breadCrumb: 'Edit Template',
    },
  },
}

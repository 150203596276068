import React from 'react'
import { useTranslate } from 'react-admin'
import LogoOrTitle from '../mainContent/components/LogoOrTitle'
import SalesDetail from '../SalesDetail'
import ButtonAction from './ButtonAction'

const ActionButtonRow = ({
  showDialog,
  isMobile,
  myeStyles,
  proposalData,
  testimonials,
  org,
  assigned_team_member,
  hasNextSteps,
  isPro,
}) => {
  const translate = useTranslate()
  const actionButtonWrapperClass = isMobile ? 'action-button-column' : 'action-button-row'
  const actionButtonContactWrapperClass = isMobile ? 'action-button-contact-column' : 'action-button-contact-row'
  const displayContactDetail = assigned_team_member.display
  return (
    <div>
      <hr className="light" style={{ marginTop: 20, marginBottom: 20 }} />
      <div className={actionButtonContactWrapperClass}>
        <div style={{ flex: '1 1 0' }}>
          <LogoOrTitle org={org} assigned_team_member={assigned_team_member} />
        </div>
        {isMobile && displayContactDetail && (
          <div style={{ flex: '1 1 0', textAlign: 'right' }}>
            <SalesDetail
              org={org}
              myeStyles={myeStyles}
              assigned_team_member={assigned_team_member}
              api_key_chat={proposalData.selectedProject.api_key_chat}
              isPro={isPro}
            />
          </div>
        )}
      </div>

      <div className={actionButtonWrapperClass}>
        {testimonials && testimonials.length > 0 && (
          <ButtonAction
            label={proposalData.proposalTemplateSettings.heading_case_studies || translate('Case Studies')}
            action={() => showDialog('CaseStudies')}
            buttonType={'action'}
            myeStyles={myeStyles}
          />
        )}
        <ButtonAction
          label={translate('About') + ' ' + org.name}
          action={() => showDialog('AboutUs')}
          buttonType={'action'}
          myeStyles={myeStyles}
        />
        {hasNextSteps && (
          <ButtonAction
            label={proposalData.proposalTemplateSettings.heading_next_steps || translate('Next Steps')}
            action={() => showDialog('NextSteps')}
            buttonType={'action'}
            myeStyles={myeStyles}
          />
        )}
        <ButtonAction
          label={translate('Download Proposal')}
          action={() => showDialog('GenerateProposal')}
          buttonType={'highlight'}
          myeStyles={myeStyles}
        />
      </div>
    </div>
  )
}

export default ActionButtonRow

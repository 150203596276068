import { permissionsSelectors } from 'ducks/permissions'
import CustomField from 'elements/field/CustomField'
import DependentInput from 'elements/input/DependentInput'
import TextFieldWithSharedEntityIcon from 'elements/TextFieldWithSharedEntityIcon'
import UnresolvableReferenceInput from 'elements/UnresolvableReferenceInput'
import _ from 'lodash'
import { useEffect } from 'react'
import { AutocompleteInput, BooleanInput, NumberInput, SelectInput, TextInput, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { parseJsonSafe } from 'util/misc'

export const inputStyle = {
  width: 256,
}

export const validateTiersInput = (values, errors) => {
  if (values.tiers_data) {
    for (const [field, data] of Object.entries(values.tiers_data)) {
      const tiersData = data?.tiers || []
      for (let i = 0; i < tiersData.length; i++) {
        const tierValuePreviousRow = tiersData[i - 1]?.tier || 0
        const tierValueCurrentRow = tiersData[i].tier
        const tierPriceCurrentRow = tiersData[i].price
        const isLastTier = i === tiersData.length - 1
        //check price
        if (!tierPriceCurrentRow || tierPriceCurrentRow < 0) {
          _.set(errors, `tiers_data.${field}.tiers[${i}].price`, 'Should be greater than 0')
        }

        //check tier
        if (isLastTier && tierValueCurrentRow === null) {
          //do not bother with last tier
        } else if (tierValueCurrentRow < 0) {
          _.set(errors, `tiers_data.${field}.tiers[${i}].tier`, 'Should be greater than 0')
        } else if (tierValueCurrentRow <= tierValuePreviousRow) {
          _.set(errors, `tiers_data.${field}.tiers[${i}].tier`, 'Should be greater than previous tier')
        }
      }
    }
  }
}

export const ManufacturerAndLogo = ({ record }) => {
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: '0 0 48px', textAlign: 'center' }}>
        {record.logo ? (
          <img
            key="logo"
            style={{
              display: 'inline-block',
              maxHeight: 36,
              maxWidth: 48,
              textAlign: 'center',
              verticalAlign: 'middle',
            }}
            src={record.logo}
          />
        ) : (
          <div
            style={{
              display: 'inline-block',
              backgroundColor: '#f7f7f7',
              width: 44,
              height: 32,
              verticalAlign: 'middle',
            }}
          ></div>
        )}
        <i style={{ display: 'inline-block', height: '100%', width: 0, verticalAlign: 'middle' }} />
      </div>
      <div style={{ margin: 'auto auto auto 2px' }}>{record.manufacturer_name}</div>
    </div>
  )
}

export const Cost = ({ record }) => {
  const { allowView } = useSelector(permissionsSelectors.getPermissionByKey('component_pricing_costing'))

  const parseTiersData = record.tiers_data ? parseJsonSafe(record.tiers_data, null) : null

  return (
    <>
      {allowView && !parseTiersData?.cost ? (
        <div> {record.cost}</div>
      ) : allowView && parseTiersData?.cost ? (
        <p>Detailed costing used</p>
      ) : null}
    </>
  )
}

export const CodeFromComponentReferenceOrData = ({ record, source, ...props }) => {
  if (record.code) {
    return <TextFieldWithSharedEntityIcon source={source} record={record} {...props} />
  }

  if (record.data) {
    var data = parseJsonSafe(record.data, null)
    if (data && data.code) {
      return <TextFieldWithSharedEntityIcon source={source} record={data} {...props} />
    }
  }

  return <span>Unknown</span>
}

export const LinksToCustomFields = ({ componentType }) => {
  const translate = useTranslate()
  const form = useForm()
  const formState = useFormState()
  const isComponentFromDatabase = formState.values[componentType]
  return (
    <p
      style={{
        textAlign: 'right',
        marginLeft: 'auto',
        fontStyle: 'italic',
        fontSize: 12,
        padding: '9px 0',
      }}
    >
      {isComponentFromDatabase ? (
        <a
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => {
            form.mutators.updateField('lookup_in_component_database', false)
            form.mutators.updateField(componentType, '')
          }}
        >
          {translate('Customise Specification')}
        </a>
      ) : (
        <span>
          {translate("Can't find?")}{' '}
          <a
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => {
              form.mutators.updateField('lookup_in_component_database', false)
              form.mutators.updateField(componentType, '')
            }}
          >
            {translate('Create Your Own')}
          </a>
        </span>
      )}
    </p>
  )
}

export const LinksToSearchDatabase = ({
  defaultComponentData,
  record,
  isEdit,
  getDefaultValues,
  isComponentFromDatabase,
  componentType,
}) => {
  const translate = useTranslate()
  const form = useForm()
  const formState = useFormState()
  const isSelectedFromDatabase =
    isComponentFromDatabase === undefined ? Boolean(formState.values[componentType]) : isComponentFromDatabase
  return (
    <p style={{ textAlign: 'right', padding: '0 10px', fontStyle: 'italic', fontSize: 12 }}>
      {isSelectedFromDatabase ? (
        <span
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => {
            form.mutators.updateField('lookup_in_component_database', true)
            var componentData
            if (isEdit) {
              componentData = getDefaultValues(defaultComponentData || record)
              delete componentData.lookup_in_component_database
            } else {
              componentData = defaultComponentData
            }
            Object.keys(componentData).forEach((field) => form.mutators.updateField(field, componentData[field]))
          }}
        >
          {translate('Use Defaults')}
        </span>
      ) : (
        <span>
          {translate('or')}{' '}
          <a
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => form.mutators.updateField('lookup_in_component_database', true)}
          >
            {translate('Search OpenSolar Database')}
          </a>
        </span>
      )}
    </p>
  )
}

export const ActiveComponentField = ({ fieldConfig, resource, disabled = false, extra = null }) => {
  let extraProps = fieldConfig.label
  if (extraProps && typeof extraProps === 'string') {
    // For whatever reason, labels are only passed through for Booleans, can't change now...
    if (fieldConfig.component === 'Boolean') extraProps = { label: extraProps }
    else extraProps = undefined // Otherwise, ignore fieldConfig.label as a label
  }
  if (!extraProps) extraProps = {}

  const form = useForm()
  const formState = useFormState()

  // If Show in Your Solution and Show in Quotation Table toggles are turned off in Other Components,
  // also switch off "Show Customer".
  useEffect(() => {
    if (
      formState.values?.custom_data_field_show_in_your_solution === false &&
      formState.values?.custom_data_field_show_in_quotation_table === false &&
      formState.values?.custom_data_field_show_customer === true
    ) {
      form.change('custom_data_field_show_customer', false)
    }
  }, [
    formState.values?.custom_data_field_show_in_your_solution,
    formState.values?.custom_data_field_show_in_quotation_table,
  ])

  const dependentInputParams = fieldConfig.dependentInputParams
    ? fieldConfig.dependentInputParams
    : { resolve: () => true }

  const referenceInputParams = fieldConfig.referenceInputParams
    ? fieldConfig.referenceInputParams
    : { resolve: () => true }

  if (fieldConfig.component && fieldConfig.component === 'Boolean') {
    return (
      <DependentInput {...dependentInputParams}>
        <CustomField
          component={BooleanInput}
          name={fieldConfig.custom_field_prefix_source}
          source={fieldConfig.custom_field_prefix_source}
          resource={resource}
          disabled={disabled}
          options={{
            onChange: (e, value) => {
              form.mutators.updateField(`custom_data_field_${fieldConfig.source}`, value)
              if (fieldConfig.source === 'additional_costs_enabled' && value === false) {
                extra.customOtherDataFieldsForAdditionalCosts &&
                  extra.customOtherDataFieldsForAdditionalCosts.forEach((field) =>
                    form.mutators.updateField(`custom_data_field_${field}`, null)
                  )
              }

              if (fieldConfig.source === 'show_customer') {
                extra.customOtherDataFieldsShowCustomer &&
                  extra.customOtherDataFieldsShowCustomer.forEach((field) =>
                    form.mutators.updateField(`custom_data_field_${field}`, value)
                  )
              }
            },
          }}
          {...extraProps}
        />
      </DependentInput>
    )
  } else if (fieldConfig.component && fieldConfig.component === 'LongTextInput') {
    return (
      <DependentInput {...dependentInputParams}>
        <CustomField
          component={TextInput}
          fullWidth={true}
          multiline={true}
          key={fieldConfig.custom_field_prefix_source}
          name={fieldConfig.custom_field_prefix_source}
          source={fieldConfig.custom_field_prefix_source}
          resource={resource}
          options={{
            disabled,
          }}
          // endAdornment={fieldConfig.endAdornment}
          {...extraProps}
        />
      </DependentInput>
    )
  } else if (fieldConfig.component && fieldConfig.component === 'Select') {
    const choices = fieldConfig.extra?.values
    return (
      <DependentInput {...dependentInputParams}>
        <CustomField
          component={SelectInput}
          resource={resource}
          label={fieldConfig.label}
          key={fieldConfig.custom_field_prefix_source}
          name={fieldConfig.custom_field_prefix_source}
          source={fieldConfig.custom_field_prefix_source}
          choices={choices}
          options={{
            disabled: disabled,
            onChange: (event, child) => {
              form.mutators.updateField(`custom_data_field_${fieldConfig.source}`, event.target.value)

              if (
                extra &&
                extra.customOtherDataFieldsDcOptimizer &&
                fieldConfig.source === 'other_component_type' &&
                event.target.value !== 'dc_optimizer'
              ) {
                extra.customOtherDataFieldsDcOptimizer &&
                  extra.customOtherDataFieldsDcOptimizer.forEach((field) =>
                    form.mutators.updateField(`custom_data_field_${field}`, null)
                  )
              }

              if (
                extra &&
                extra.customOtherDataFieldsIsolator &&
                fieldConfig.source === 'other_component_type' &&
                event.target.value !== 'isolator'
              ) {
                extra.customOtherDataFieldsIsolator &&
                  extra.customOtherDataFieldsIsolator.forEach((field) =>
                    form.mutators.updateField(`custom_data_field_${field}`, null)
                  )
              }

              if (fieldConfig.source === 'current_type') {
                if (event.target.value !== 'ac') {
                  form.mutators.updateField('custom_data_field_current_rating', null)
                }
                if (event.target.value !== 'dc') {
                  form.mutators.updateField('custom_data_field_voltage_to_current_rating', null)
                  form.mutators.updateField('custom_data_field_phase_type', null)
                }
              }

              if (
                extra &&
                extra.customOtherDataFieldsCable &&
                fieldConfig.source === 'other_component_type' &&
                event.target.value !== 'cable'
              ) {
                extra.customOtherDataFieldsCable &&
                  extra.customOtherDataFieldsCable.forEach((field) =>
                    form.mutators.updateField(`custom_data_field_${field}`, null)
                  )
              }

              if (
                extra &&
                extra.customOtherDataFieldsMeter &&
                fieldConfig.source === 'other_component_type' &&
                event.target.value !== 'meter'
              ) {
                extra.customOtherDataFieldsMeter &&
                  extra.customOtherDataFieldsMeter.forEach((field) =>
                    form.mutators.updateField(`custom_data_field_${field}`, null)
                  )
              }
            },
          }}
          {...extraProps}
        />
      </DependentInput>
    )
  } else if (fieldConfig.component && fieldConfig.component === 'AutocompleteInput') {
    const choices = fieldConfig.extra?.values
    const options = {
      disabled,
      containerStyle: { display: 'inline-flex' },
      style: inputStyle,
      ...(fieldConfig.extra?.options || {}),
    }
    return (
      <DependentInput {...dependentInputParams}>
        <UnresolvableReferenceInput {...referenceInputParams} source={fieldConfig.custom_field_prefix_source}>
          <CustomField
            component={AutocompleteInput}
            resource={resource}
            label={fieldConfig.label}
            key={fieldConfig.custom_field_prefix_source}
            name={fieldConfig.custom_field_prefix_source}
            source={fieldConfig.custom_field_prefix_source}
            choices={choices}
            {...fieldConfig?.extra}
            options={options}
            {...extraProps}
          />
        </UnresolvableReferenceInput>
      </DependentInput>
    )
  } else if (fieldConfig.component && fieldConfig.component === 'Number') {
    return (
      <DependentInput {...dependentInputParams}>
        <NumberInput
          key={fieldConfig.custom_field_prefix_source}
          name={fieldConfig.custom_field_prefix_source}
          source={fieldConfig.custom_field_prefix_source}
          resource={resource}
          options={{
            disabled,
            style: inputStyle,
          }}
          parse={fieldConfig.extra?.parse}
          format={fieldConfig.extra?.format}
          endAdornment={fieldConfig.endAdornment}
          startAdornment={fieldConfig.extra?.softWarning}
          InputLabelProps={{
            shrink: true,
          }}
          {...extraProps}
        />
      </DependentInput>
    )
  } else {
    return (
      <DependentInput {...dependentInputParams}>
        <CustomField
          component={TextInput}
          resource={resource}
          key={fieldConfig.custom_field_prefix_source}
          name={fieldConfig.custom_field_prefix_source}
          source={fieldConfig.custom_field_prefix_source}
          options={{
            disabled,
            style: inputStyle,
          }}
          endAdornment={fieldConfig.endAdornment}
          parse={fieldConfig.extra?.parse}
          format={fieldConfig.extra?.format}
          InputLabelProps={{
            shrink: true,
          }}
          {...extraProps}
        />
      </DependentInput>
    )
  }
}

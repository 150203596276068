import useGetCustomForms from 'customForm/hooks/useGetCustomForms'
import React, { FC } from 'react'
import { Create, Edit, SimpleForm } from 'react-admin'
import { FormPlacementEnum } from 'resources/customForms/types'
import { validateContact } from '../util'
import { ContactForms } from './ContactForms'

const ContactPageForm = (props) => {
  const uiKey = 'control.customers.contacts'
  const customForms = useGetCustomForms({ location: FormPlacementEnum.contact_form, isCreateForm: !props?.id })
  const newProps = {
    customForms,
    uiKey,
    ...props,
  }
  return <ContactForms {...newProps} />
}

export const ContactEdit: FC<any> = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm validate={(values) => validateContact(values)}>
        <ContactPageForm id={props.id} />
      </SimpleForm>
    </Edit>
  )
}

export const ContactCreate: FC<any> = (props) => {
  return (
    <Create {...props}>
      <SimpleForm redirect="list" validate={(values) => validateContact(values)}>
        <ContactPageForm />
      </SimpleForm>
    </Create>
  )
}

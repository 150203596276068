import { LoginPayload, userLogin } from 'react-admin'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import appStorage from 'storage/appStorage'
import { parseQueryStringToDictionary } from 'util/misc'
import useLoginStep from './useLoginStep'

const useLoginFormSubmit = ({ onSuccess }: { onSuccess?(): void }) => {
  const dispatch = useDispatch()
  const location = useLocation<{
    nextPathname?: string
  }>()
  const step = useLoginStep()

  const handleSubmit = ({ email, password, token = undefined, remember = undefined, send_sms = undefined }: any) => {
    if (step === 'mfa-wrapper') return // This avoids an issue with nested forms (MFA sub-forms accidentally submit this form)
    const values: LoginPayload = {
      email,
      password,
      token,
      remember,
      skip_org: 'skip_org',
      nearmap_token: undefined,
      send_sms,
    }

    // inject nearmap token if available
    // TODO: clean up this nearmap thing !!!!!
    const queryParams = Reflect.get(location, 'params') || parseQueryStringToDictionary(location.search)
    let nearmap_token = queryParams?.nearmap_token || appStorage.getString('nearmap_token')
    if (nearmap_token === 'undefined') nearmap_token = undefined
    if (nearmap_token) {
      values.nearmap_token = nearmap_token
    }

    // Duplicate of injected emailFromNearmapToken already set?
    dispatch(
      userLogin({
        payload: values,
        state: { nextPathname: location.state?.nextPathname },
        nearmapToken: nearmap_token,
        callbacks: { onSuccess },
      })
    )
  }

  return handleSubmit
}

export default useLoginFormSubmit

import { useMemo } from 'react'
import type { FactoryHookType } from '../type'
import maybeCreateBatteryCompatibilityFilterNode from './BatteryCompatibilityFilterNodeFactory'

const useBatteryCompatibilityFilterNode: FactoryHookType = ({ RendererComponent, options }) => {
  return useMemo(() => {
    return maybeCreateBatteryCompatibilityFilterNode({
      componentType: options.componentType,
      RendererComponent,
    })
  }, [options.componentType, RendererComponent])
}

export default useBatteryCompatibilityFilterNode

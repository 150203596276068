import { makeStyles, useMediaQuery } from '@material-ui/core'
import UploadIcon from '@material-ui/icons/BackupOutlined'
import StarIcon from '@material-ui/icons/StarOutlined'
import { push as pushAction } from 'connected-react-router'
import { authSelectors } from 'ducks/auth'
import SharedEntityIcon from 'elements/connectedOrgs/SharedEntityIcon'
import ProjectName from 'elements/ProjectName'
import { useUxVersion } from 'hooks/useUxVersion.ts'
import { Button, Chip } from 'opensolar-ui'
import {
  Button as RaButton,
  Datagrid,
  FunctionField,
  List,
  PaginationComp,
  showNotification as showNotificationAction,
  useTranslate,
} from 'react-admin'
import { connect, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import compose from 'recompose/compose'
import appStorage from 'storage/appStorage'
import { NORMAL_CONTACT } from 'types/contacts'
import { getRoleFromState } from 'util/misc'
import { PRIORITY_CHOICES } from './choices.js'
import StarButton from './elements/StarButton'
import { ContactButtons } from './projectsList/ContactButtons'
import { ContactsActions } from './projectsList/ContactsActions'
import { ContactsBlock } from './projectsList/ContactsBlock'
import { ProjectFilter } from './projectsList/ProjectFilter'
import { ProjectListActions } from './projectsList/ProjectListActions'
import { ProjectListSearchTitle } from './projectsList/ProjectListSearchTitle'
import ProjectListSelector from './projectsList/ProjectListSelector'
import ProjectSelectDropdown from './projectsList/ProjectSelectDropdown'
import { ProjectStageCount } from './projectsList/ProjectStageCount'
import StageSelectDropdown from './projectsList/StageSelectDropdown'
import { handleCsvDownload } from './util'

const ProjectListRowSmall = (props) => {
  const { record } = props
  const translate = useTranslate()
  const ui3Enabled = useUxVersion() === 3
  return (
    <div style={{ position: 'relative' }}>
      <StarButton record={record} style={{ position: 'absolute', top: -10, right: 0 }} />

      <h3
        style={{
          fontSize: '16px',
          margin: '10px 0px 10px 0px',
          textOverflow: 'ellipsis',
        }}
      >
        <Link to={`/projects/${record.id}/${ui3Enabled ? 'manage' : 'info'}`}>
          <ProjectName business_name={record.business_name} address={record.address} />
        </Link>
      </h3>
      <ProjectListActions record={record} translate={translate} />
      {!record.is_lite && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <div style={{ flex: '0 0 50px', color: '#B1B1B1' }}>{translate('Stage')}</div>
          <div style={{ flex: '1 1 auto' }}>
            <StageSelectDropdown project={record} />
          </div>
          <div style={{ flex: '0 0 50px', color: '#B1B1B1' }}>{translate('Priority')}</div>
          <div style={{ flex: '1 1 auto' }}>
            <ProjectSelectDropdown choices={PRIORITY_CHOICES} source="priority" project={record} />
          </div>
        </div>
      )}
      {record.contacts_data &&
        record.contacts_data
          .filter((contact) => contact.type === NORMAL_CONTACT)
          .map((contact, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                marginTop: 10,
                marginBottom: 10,
                maxWidth: 273,
              }}
            >
              <div style={{ flex: '0 0 50px', color: '#B1B1B1' }}>{translate('Contact')}</div>
              <div style={{ flex: '1 1 auto' }}>{contact.display}</div>
              <div style={{ flex: '0 0 108px' }}>
                <ContactButtons contact={contact} translate={translate} />
              </div>
            </div>
          ))}
    </div>
  )
}

const UpLoadImportCSVButton = (props) => {
  const handleUploadImport = () => props.push('/csv_upload?model=projects')
  return (
    <RaButton label={'Upload & Import CSV'} onClick={handleUploadImport}>
      <UploadIcon />
    </RaButton>
  )
}

const OverridePagination = connect(
  (state) => {
    var role = getRoleFromState(state)

    return { isAdmin: role && role.is_admin }
  },
  { push: pushAction, showNotification: showNotificationAction }
)(({ push, showNotification, ...props }) => {
  const orgId = useSelector(authSelectors.getOrgId)
  const handleCSVDownload = () => {
    handleCsvDownload(
      `${window.API_BASE_URL}orgs/${orgId}/projects/?fieldset=csv&format=csv&ordering=-id`,
      showNotification,
      props.filterValues,
      'OpenSolarProjects'
    )
  }

  const translate = useTranslate()
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>
        {appStorage.getString('feature_preview') && props.isAdmin && <UpLoadImportCSVButton push={push} />}
        <Button style={{ margin: 8 }} onClick={handleCSVDownload} className={'MuiButton-textPrimary'}>
          <span>{translate('Download')}</span>
        </Button>
      </div>
      <PaginationComp {...props} />
    </div>
  )
})

const useStyles = makeStyles((theme) => ({
  addressCell: {
    fontWeight: 'bold',
    fontSize: '1em',
    margin: 0,
    textOverflow: 'ellipsis',
    display: 'flex',
    alignItems: 'center',
  },
  list: {
    [theme.breakpoints.up('sm')]: {
      //expand icon
      // '& th:first-child': {
      //   width: '4%',
      //   minWidth: 40,
      // },

      '& th:nth-child(1)': {
        marginLeft: 2,
        width: 0,
      },
      '& th:nth-child(2)': {
        width: '18%',
      },
      '& th:nth-child(3)': {
        width: 14,
      },
      '& th:nth-child(4)': {
        width: 30,
        boxSizing: 'border-box',
      },
      '& th:nth-child(5)': {
        width: 22,
        boxSizing: 'border-box',
      },
      '& th:nth-child(6)': {
        boxSizing: 'border-box',
      },
      '& th:last-child': {
        maxWidth: 350,
        textAlign: 'center',
        [theme.breakpoints.down('lg')]: { width: 50 },
        [theme.breakpoints.down('xs')]: { width: '30%' },
      },
    },
    [theme.breakpoints.up('xl')]: {
      //expand icon
      // '& th:first-child': {
      //   width: '4%',
      //   minWidth: 40,
      // },

      '& th:nth-child(1)': {
        width: 30,
        marginLeft: 2,
      },
      '& th:nth-child(2)': {
        width: '25%',
      },
      '& th:nth-child(3)': {
        width: '10%',
      },
      '& th:nth-child(4)': {
        width: 120,
        boxSizing: 'border-box',
      },
      '& th:nth-child(5)': {
        width: 120,
        boxSizing: 'border-box',
      },
      '& th:nth-child(6)': {
        width: '120px',
        boxSizing: 'border-box',
      },
      '& th:last-child': {
        width: '20%',
        margin: '0 16px 0 auto',
        textAlign: 'center',
        [theme.breakpoints.down('lg')]: { width: 50 },
        [theme.breakpoints.down('xs')]: { width: '30%' },
      },
    },
    '& .Ra-List-Main': {
      padding: 0,
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    '& .Ra-List-Main .MuiCard-root': {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    '& .MuiTableCell-sizeSmall': {
      padding: 6,
    },
  },
}))

export const ProjectListFull = (props) => {
  const translate = useTranslate()
  const classes = useStyles()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const isXl = useMediaQuery((theme) => theme.breakpoints.up('xl'))
  const ui3Enabled = useUxVersion() === 3
  // Hack only required for project page which is the default page
  // Until we are logged in, avoid trying to load a List page which will make a failed call to back-end
  if (!props.userId) {
    return null
  }

  return (
    <List
      hideBreadCrumbs={true}
      pagination={<OverridePagination rowsPerPageOptions={[5, 10, 20, 25, 50]} />}
      showButtons={true}
      className={classes.list}
      title={<ProjectListSearchTitle endAdornment={<ProjectListSelector />} />}
      filters={<ProjectFilter />}
      perPage={20}
      {...props}
      hasCreate={false}
      aside={<ProjectStageCount />}
    >
      {isMobile ? (
        <Datagrid>
          <ProjectListRowSmall source="address" {...props} />
        </Datagrid>
      ) : (
        <Datagrid>
          <FunctionField
            source="stars"
            label={<StarIcon style={{ color: '#4d4d4d', viewBox: '0 -5 24 29', padding: 8 }} />}
            render={(record) => <StarButton record={record} />}
          />

          <FunctionField
            source="address"
            render={(record) => (
              <p className={classes.addressCell}>
                <Link
                  to={
                    record.is_lite
                      ? `/projects/${record.id}/design`
                      : `/projects/${record.id}/${ui3Enabled ? 'manage' : 'info'}`
                  }
                  style={{}}
                >
                  <ProjectName business_name={record.business_name} address={record.address} />
                </Link>
                {!!record.shared_with?.length && (
                  <SharedEntityIcon
                    owner={record.org_id}
                    owner_name={record.org_name}
                    shared_with={record.shared_with}
                    margin="wide"
                  />
                )}
                {record.is_lite && <Chip style={{ marginLeft: '20px' }} label={translate('Lite')} />}
              </p>
            )}
          />

          {/*@TODO: Add an optional sortBy parameter which uses that field rather than the source field*/}
          <FunctionField
            source="contacts__family_name"
            textAlign={'left'}
            label="Contacts"
            render={(record) => !record.is_lite && <ContactsBlock contactsData={record.contacts_data} />}
          />

          <FunctionField
            source="contacts__family_name"
            textAlign={'left'}
            label={null}
            render={(record) => !record.is_lite && <ContactsActions record={record} translate={translate} />}
          />

          <FunctionField
            label="Stage"
            render={(record) => {
              if (!record.is_lite) {
                return <StageSelectDropdown project={record} />
              }
              return null
            }}
          />

          {isXl ? (
            <FunctionField
              source="priority"
              render={(record) =>
                !record.is_lite && (
                  <ProjectSelectDropdown choices={PRIORITY_CHOICES} source="priority" project={record} />
                )
              }
            />
          ) : null}

          <FunctionField
            source={null}
            label="resources.projects.fields.actions"
            render={(record) => <ProjectListActions record={record} translate={translate} />}
            sortable={false}
          />
        </Datagrid>
      )}
    </List>
  )
}

const mapStateToPropsList = (state) => {
  return {
    userId: state.auth && state.auth.user ? state.auth.user.id : null,
    orgId: state.auth ? state.auth.org_id : null,
    recentProjects: state.recentProjects,
  }
}

export default compose(connect(mapStateToPropsList, {}))(ProjectListFull)

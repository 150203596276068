import { ReduxActionType } from 'types/global'
import { RootState } from 'types/state'
export const AUTO_DESIGN_SET_POLLING = 'AUTO_DESIGN_SET_POLLING'
export const AUTO_DESIGN_SET_RUNNING = 'AUTO_DESIGN_SET_RUNNING'

export type AutoDesignReducerType = {
  polling: boolean
  running: boolean
}

const initialState: AutoDesignReducerType = {
  polling: false,
  running: false,
}

export default function reducer(state = initialState, action: ReduxActionType) {
  switch (action.type) {
    case AUTO_DESIGN_SET_POLLING:
      return {
        ...state,
        polling: action.payload,
      }
    case AUTO_DESIGN_SET_RUNNING:
      return {
        ...state,
        running: action.payload,
      }
    default:
      return { ...state }
  }
}

export const setPolling = (payload) => {
  return {
    type: AUTO_DESIGN_SET_POLLING,
    payload: payload,
  }
}

export const setRunning = (payload) => {
  return {
    type: AUTO_DESIGN_SET_RUNNING,
    payload: payload,
  }
}

export const autoDesignSelectors = {
  isPolling: (state: RootState) => {
    return !!state.autoDesign?.polling
  },
  isRunning: (state: RootState) => {
    return !!state.autoDesign?.running
  },
}

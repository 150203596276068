import React from 'react'
import { TransactionCreate, TransactionEdit, TransactionList } from './Transactions'

const TransactionTitle = ({ record, translate, style }) => {
  return (
    <span style={style}>
      {translate('Transaction')} {record ? `"${record.id}"` : ''}
    </span>
  )
}
export default {
  list: TransactionList,
  edit: TransactionEdit,
  create: TransactionCreate,
  options: {
    create: {
      title: 'Create Transaction',
      subtitle: null,
      breadCrumb: 'Create Transaction',
    },
    list: {
      title: 'Project Transactions',
      subtitle: 'View and add new transactions here for your projects. Track how payments are going for your projects.',
      breadCrumb: 'Transactions',
    },
    edit: {
      title: <TransactionTitle />,
      subtitle: null,
      breadCrumb: 'Edit Transaction',
    },
  },
}

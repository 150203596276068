import type { Filter } from 'elements/controller/filter/filter'
import type { Query } from 'elements/controller/query/query'
import get from 'lodash/get'
import { useMemo } from 'react'
import {
  CRUD_GET_LIST,
  Identifier,
  Record,
  RecordMap,
  ReduxState,
  useGetList,
  useNotify,
  useVersion,
} from 'react-admin'
import { useSelector } from 'react-redux'
import type { QUERY_ORDER } from './query/query'

const defaultData = {}

export interface ListControllerProps<RecordType = Record> {
  currentSort: { field: string | undefined; order: QUERY_ORDER }
  data: RecordMap<RecordType>
  ids: Identifier[]
  loading: boolean
  loaded: boolean
  resource: string
  total: number
  version: number
}

const useListController = <RecordType = Record>({
  resource,
  query,
  filter,
}: {
  resource: string
  query: Query
  filter: Filter
}): ListControllerProps<RecordType> => {
  const notify = useNotify()
  const version = useVersion()

  /**
   * We want the list of ids to be always available for optimistic rendering,
   * and therefore we need a custom action (CRUD_GET_LIST) that will be used.
   */
  const { ids, total, loading, loaded } = useGetList<RecordType>(
    resource,
    {
      page: query.page,
      perPage: query.perPage,
    },
    { field: query.sort, order: query.order },
    { ...filter.filterValues },
    {
      action: CRUD_GET_LIST,
      version,
      onFailure: (error) =>
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning'),
    }
  )

  const data = useSelector(
    (state: ReduxState): RecordMap<RecordType> =>
      get(state.admin.resources, [resource, 'data'], defaultData) as RecordMap<RecordType>
  )

  // When the user changes the page/sort/filter, this controller runs the
  // useGetList hook again. While the result of this new call is loading,
  // the ids and total are empty. To avoid rendering an empty list at that
  // moment, we override the ids and total with the latest loaded ones.
  const defaultIds = useSelector((state: ReduxState): Identifier[] =>
    get(state.admin.resources, [resource, 'list', 'ids'], [])
  )
  const defaultTotal = useSelector((state: ReduxState): number =>
    get(state.admin.resources, [resource, 'list', 'total'], 0)
  )

  const currentSort = useMemo(
    () => ({
      field: query.sort,
      order: query.order,
    }),
    [query.sort, query.order]
  )

  //remove duplicated id
  const finalIds = Array.from(new Set(typeof total === 'undefined' ? defaultIds : ids))

  return {
    currentSort,
    data,
    ids: finalIds,
    loaded: loaded || defaultIds.length > 0,
    loading,
    resource,
    total: typeof total === 'undefined' ? defaultTotal : total,
    version,
  }
}

export default useListController

import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orderSelectors } from 'ducks/orderComponents'
import Button from 'elements/button/Button'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { creditLimitOverride } from './SegenCartActions'
import { AccountContext, OrderContext, QuoteContext, ShowAddressCompletionFormContext } from './SegenCheckoutProvider'

const SegenPlaceOrderButton = ({ onClick, trackSource }: { onClick(): void; trackSource: string }) => {
  const { submitting, isReady, order } = useContext(OrderContext)
  const { loading, quote } = useContext(QuoteContext)

  const { account } = useContext(AccountContext)
  const lineItemsConfirmed = useSelector(orderSelectors.getConfirmedLineItems)
  const showAddressCompletionForm = useContext(ShowAddressCompletionFormContext)
  const totalAmount = quote?.totalsResponse.grossTotal.amount
  const creditLimit = creditLimitOverride() || account?.accountSummary.creditLimit.amount

  return (
    <Button
      color="primary"
      fullWidth={true}
      disabled={loading || showAddressCompletionForm || !quote?.id || submitting}
      loading={submitting}
      onClick={(event) => {
        event.preventDefault()
        onClick()
        logAmplitudeEvent('hardware_place_order_cta_clicked', {
          distributor: 'segen',
          source: trackSource,
          codes: lineItemsConfirmed.map((lineItem) => lineItem.code),
          creditLimit,
          cost: totalAmount,
        })
      }}
    >
      Schedule order
    </Button>
  )
}

export default SegenPlaceOrderButton

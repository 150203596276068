import { TextField } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import { markFieldActive, markFieldInactive } from 'Studio/Utils'
import { useTranslate } from 'ra-core'
import { FC, useState } from 'react'
import { StudioSystemType } from 'types/global'
import {
  handleObjectChangedAsCommand,
  inputValueToFloatOrEmptyStringWhenNull,
  styles,
  valueOrEmptyStringIfNull,
} from '../../PanelSystem'

type Props = {
  label: string
  systemField: string
  system: StudioSystemType
  unit?: string
  multiplier?: number
  errorText?: string

  //TODO: Should remove the need for this
  panelSystem: any
}

export const OffsetableField: FC<Props> = ({
  label,
  system,
  systemField,
  unit,
  panelSystem,
  multiplier = 1,
  errorText,
}) => {
  const translate = useTranslate()

  const [valueRaw, setValueRaw] = useState(system ? valueOrEmptyStringIfNull(system[systemField], multiplier) : '')

  return (
    <TextField
      type="number"
      label={translate(label)}
      InputLabelProps={{
        shrink: true,
        style: styles.textFieldHalfSizeLabel,
      }}
      style={styles.textFieldHalfSize}
      name={label}
      value={valueOrEmptyStringIfNull(valueRaw)}
      onFocus={() => {
        markFieldActive.call(panelSystem, systemField, system)
      }}
      onBlur={() => {
        markFieldInactive.call(panelSystem)
      }}
      InputProps={{
        endAdornment: unit ? <InputAdornment position="end">{unit}</InputAdornment> : undefined,
      }}
      onChange={(event) => {
        const value = event.target.value
        setValueRaw(value)

        var valueAsFloatOrNull = inputValueToFloatOrEmptyStringWhenNull(value)

        handleObjectChangedAsCommand(
          system.uuid,
          systemField,
          valueAsFloatOrNull ? valueAsFloatOrNull / multiplier : null
        )
      }}
      helperText={errorText && valueRaw && isNaN(parseFloat(valueRaw)) ? translate(errorText) : null}
      disabled={false}
    />
  )
}

import { orgSelectors } from 'ducks/orgs'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useFeatureFlag } from 'util/split'
import { HardwareSupplierEnum } from '../type'

const useEnabledHardwareSuppliers = () => {
  const enabledSuppliers = useSelector(orgSelectors.getEnabledHardwareSuppliers)
  const enableHdm = useFeatureFlag('hdm', 'on')

  const filteredSuppliers = useMemo(() => {
    return enabledSuppliers.filter((supplier) => {
      if (supplier === HardwareSupplierEnum.Hdm) {
        return enableHdm
      }
      return true
    })
  }, [enabledSuppliers, enableHdm])

  return filteredSuppliers
}

export default useEnabledHardwareSuppliers

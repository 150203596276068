import { TextField } from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined'
import React from 'react'
import { useNotify } from 'react-admin'

type PropTypes = {
  url: string
}

const CopyLink: React.FunctionComponent<PropTypes> = (props) => {
  const notify = useNotify()
  const copyIt = () => {
    navigator.clipboard.writeText(props.url).then(
      function () {
        notify('URL copied', 'success')
      },
      function (err) {
        notify('unable to copy URL', 'warning')
      }
    )
  }
  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
      <TextField disabled value={props.url} fullWidth />
      <div
        style={{
          marginLeft: '5px',
          padding: '5px',
          marginBottom: '5px',
          borderRadius: '3px',
          boxShadow: '0px 1px 3px rgba(0,0,0,0.3)',
          cursor: 'pointer',
        }}
      >
        <FileCopyIcon onClick={copyIt} />
      </div>
    </div>
  )
}
export default CopyLink

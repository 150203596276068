export const removeCreditAppParams = () => {
  const path = window.location.hash.substring(window.location.hash.indexOf('#') + 2)
  const params = new URLSearchParams(path.substring(path.indexOf('?')))

  const PARAMS_TO_DELETE = ['view_mode', 'target_system', 'target_pmt']
  PARAMS_TO_DELETE.forEach((paramKey) => {
    if (params.get(paramKey)) params.delete(paramKey)
  })
  if (window.location.href.indexOf('?') > 0) {
    window.location.href = window.location.href.substring(0, window.location.href.indexOf('?'))
  }
  //http://localhost:3000/#/projects/10263/proposal?view_mode=application&target_system=6368&target_pmt=6071
}

import { Stepper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Button, styled } from 'opensolar-ui'
import { OpenSolarTheme, OpenSolarThemeType } from 'Themes'
import { COLOR_PALETTE } from '../styles'

interface TagWrapperProps {
  backgroundColor: string
  color: string
}

const spaceToMargin = {
  small: 16,
  medium: 40,
  large: 68,
}

export const Description = styled('div')({
  fontSize: '12px',
  fontWeight: 400,
  color: '#4F4D55',
})

export const BoldDescription = styled('div')({
  fontSize: '12px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  marginTop: '8px',
  color: 'rgba(79, 77, 85, 1)',
  position: 'absolute',
})

export const TitleText = styled('div')({
  fontWeight: 700,
  fontSize: '14px',
})

export const Wrapper = styled('div')({
  justifyContent: 'space-between',
  alignItems: 'center',
})

export const CenterAlign = styled('div')({
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  alignItems: 'center',
  whiteSpace: 'nowrap',
})

export const ButtonWrapper = styled(Button)({
  whiteSpace: 'nowrap',
  marginTop: 68,
})

export const CalculateCostButton = styled(Button)<{ space?: 'small' | 'medium' | 'large' }>(({ space }) => ({
  background: `${COLOR_PALETTE.blue3} !important`,
  color: `#FFF !important`,
  whiteSpace: 'nowrap',
  marginTop: spaceToMargin[space || 'small'],
}))

export const RecalculateCostButton = styled(Button)({
  border: `1px solid ${OpenSolarTheme.greyMid2}`,
  backgroundColor: OpenSolarTheme.white,
  borderRadius: 8,
  fontSize: 14,
  whiteSpace: 'nowrap',
  marginTop: 68,
})

export const TagWrapper = styled(CenterAlign)<TagWrapperProps>(({ backgroundColor, color }) => ({
  backgroundColor,
  color,
  marginTop: '8px',
  padding: '4px 12px',
  borderRadius: '5px',
  height: '20px',
  paddingLeft: '8px',
  paddingRight: '8px',
  fontSize: '12px',
  fontWeight: 600,
  display: 'flex',
}))

export const OrderInfoWrapper = styled(CenterAlign)({
  marginTop: 40,
  fontSize: '10px',
  fontWeight: 550,
})

export const CostDetailsWrapper = styled(CenterAlign)({
  fontSize: '12px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  color: 'rgba(79, 77, 85, 1)',
  position: 'absolute',
  marginTop: 40,
})

export const StepperWrapper = styled(Stepper)({
  '& .MuiStepIcon-active': {
    color: COLOR_PALETTE.darkBlue2,
  },
})

export const useStyles = makeStyles((theme: OpenSolarThemeType) => ({
  description: {
    fontSize: '12px',
    fontWeight: 400,
    color: '#4F4D55',
  },
  wrapper: {
    padding: '20px 0',
    borderBottom: '1px solid ' + COLOR_PALETTE.lightGrey,
    '&:first-child': {
      paddingTop: 0,
    },
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  buttonWrapper: { display: 'flex', justifyContent: 'center' },
  marginTop40: {
    marginTop: 40,
  },
  marginTop55: {
    marginTop: 55,
  },
  marginTop16: {
    marginTop: 16,
  },
  tagClass: {
    marginTop: '8px',
    padding: '4px 12px',
    borderRadius: '5px',
    height: '20px',
    paddingLeft: '8px',
    paddingRight: '8px',
    fontSize: '12px',
    fontWeight: 600,
    display: 'flex',
  },
  centerAlign: {
    position: 'absolute',
    textAlign: 'center',
    left: '50%',
    transform: 'translateX(-50%)',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  innerWrapper: {
    display: 'flex',
  },
  detailWrapper: {
    maxWidth: '75%',
  },
  desc: {
    fontSize: 12,
    marginTop: 8,
    color: COLOR_PALETTE.darkGrey,
    lineHeight: '18px',
  },
  header: {
    fontSize: 16,
    margin: 0,
    fontWeight: 600,
    color: COLOR_PALETTE.black,
  },
  iconWrapper: {
    marginLeft: 'auto',
    border: '1px solid ' + COLOR_PALETTE.lightGrey,
    borderRadius: 6,
    padding: 3,
    alignSelf: 'flex-start',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionBtn: {
    //possibly add as new variant?
    backgroundColor: '#4272DD !important',
    color: '#fff !important',
  },
  actionBtnDisabled: {
    backgroundColor: `${COLOR_PALETTE.neutralGrey500} !important`,
    color: `${COLOR_PALETTE.neutralGrey700} !important`,
  },
}))

import React from 'react'

const EnergyIcon = (props) => (
  <svg {...props} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M50.4431 21.6012C66.6782 21.9571 78.9787 34.9107 78.6242 51.2807C78.2697 66.3695 64.9767 78.8249 49.6278 78.4691C33.6763 78.0776 21.4467 65.0884 21.8367 48.8252C22.1911 33.8787 35.4132 21.2809 50.4431 21.6012ZM46.8983 54.1632C46.7211 55.1241 46.6147 55.7646 46.4729 56.4052C45.7994 59.7148 45.055 63.0243 44.5588 66.3339C44.4524 67.0457 45.055 68.22 45.6576 68.5403C46.1539 68.825 47.1819 68.1844 47.9618 67.8997C48.139 67.8286 48.2453 67.5439 48.3871 67.366C52.7827 61.4229 57.2136 55.4799 61.5737 49.5013C62.07 48.8252 62.4954 47.5441 62.1764 47.0103C61.7864 46.3341 60.6166 45.8359 59.7304 45.7647C57.7099 45.5868 55.6185 45.6935 53.4562 45.6935C54.2715 41.6722 55.0868 37.8288 55.8666 34.021C56.0793 32.9179 56.1502 31.8147 54.945 31.2453C53.6689 30.6759 52.8536 31.352 52.1092 32.3485C47.6782 38.3626 43.2117 44.3057 38.8162 50.3554C38.3199 51.0316 37.93 52.3127 38.249 52.8465C38.639 53.5227 39.8087 53.9853 40.6949 54.092C42.68 54.27 44.7005 54.1632 46.8983 54.1632Z"
      fill="url(#paint0_linear_6249:253486)"
    />
    <path
      d="M52.7472 92.5615C52.7472 94.0561 52.7827 95.5152 52.7472 97.0098C52.7118 98.8247 51.5774 99.9635 49.9114 99.9991C48.2453 100.035 46.9338 98.9671 46.8983 97.1877C46.792 94.0561 46.792 90.8889 46.8983 87.7572C46.9338 86.0135 48.3162 84.8391 49.9114 84.9103C51.542 84.9459 52.7118 86.1558 52.7472 87.9352C52.7827 89.4654 52.7472 90.9956 52.7472 92.5615Z"
      fill="url(#paint1_linear_6249:253486)"
    />
    <path
      d="M7.51573 46.7612C9.00455 46.7612 10.4579 46.7256 11.9467 46.7612C13.8255 46.8324 15.1016 48.0779 15.0661 49.7149C15.0661 51.2807 13.8609 52.5618 12.0885 52.5974C9.07544 52.6686 6.06237 52.7042 3.0493 52.5974C1.13511 52.5263 -0.0346645 51.2095 0.000783411 49.5725C0.0716792 47.9355 1.3478 46.7968 3.26199 46.7612C4.6799 46.7256 6.09782 46.7612 7.51573 46.7612Z"
      fill="url(#paint2_linear_6249:253486)"
    />
    <path
      d="M92.4843 53.0956C90.9955 53.0956 89.5067 53.1668 88.0533 53.0956C86.1037 52.9889 84.8984 51.8501 84.8984 50.1775C84.863 48.4337 86.1037 47.2238 88.1596 47.1882C90.9955 47.117 93.8313 47.117 96.6671 47.1882C98.794 47.2238 99.9638 48.327 99.9992 50.1063C100.035 51.8501 98.8295 52.9889 96.738 53.0956C95.3201 53.1668 93.9022 53.1312 92.4843 53.0956Z"
      fill="url(#paint3_linear_6249:253486)"
    />
    <path
      d="M8.86274 23.0603C9.92617 23.4518 10.5642 23.6297 11.096 23.95C13.5773 25.3379 16.0232 26.7614 18.4337 28.1848C20.2061 29.2524 20.6669 30.7827 19.7452 32.3485C18.859 33.8431 17.193 34.199 15.4206 33.2026C12.8683 31.7435 10.2807 30.2845 7.7993 28.7542C6.59408 28.0069 5.81422 26.7969 6.52318 25.4446C7.0549 24.4838 8.11833 23.8076 8.86274 23.0603Z"
      fill="url(#paint4_linear_6249:253486)"
    />
    <path
      d="M34.1016 82.0634C33.6763 83.1666 33.499 83.8783 33.1446 84.4833C31.7621 86.9388 30.3442 89.3943 28.8908 91.8498C27.8983 93.5223 26.1259 94.0561 24.708 93.202C23.2546 92.3124 22.8292 90.711 23.7863 89.0384C25.2397 86.4761 26.7285 83.9139 28.2173 81.3516C28.9972 79.9993 30.3087 79.4299 31.6557 79.9637C32.6483 80.3552 33.3572 81.3872 34.1016 82.0634Z"
      fill="url(#paint5_linear_6249:253486)"
    />
    <path
      d="M69.6913 20.1066C66.8909 20.0354 65.5085 17.9714 66.5719 15.9785C68.0607 13.1671 69.6559 10.3913 71.3219 7.68675C72.2081 6.22769 74.0159 5.87182 75.363 6.7259C76.6391 7.5444 77.1354 9.14581 76.3555 10.6049C74.8312 13.3451 73.307 16.0852 71.6055 18.7187C71.1092 19.4304 70.0812 19.8219 69.6913 20.1066Z"
      fill="url(#paint6_linear_6249:253486)"
    />
    <path
      d="M93.5477 73.0599C93.5832 75.7645 91.5626 77.3303 89.5776 76.3339C86.5645 74.8392 83.6932 73.0955 80.8574 71.3161C79.4749 70.462 79.3331 68.825 80.1484 67.5439C80.9283 66.334 82.5589 65.7646 83.9059 66.4763C86.8126 68.0421 89.6485 69.7503 92.4488 71.4941C93.0515 71.8855 93.335 72.7752 93.5477 73.0599Z"
      fill="url(#paint7_linear_6249:253486)"
    />
    <path
      d="M69.3014 79.857C69.7622 80.2128 70.8256 80.7111 71.3574 81.5651C72.9525 83.9851 74.335 86.5473 75.7529 89.074C76.6745 90.7466 76.2846 92.4191 74.8667 93.2732C73.4133 94.1273 71.7118 93.6647 70.7547 92.0633C69.195 89.4654 67.6708 86.8676 66.2174 84.2342C65.154 82.1701 66.4301 79.9637 69.3014 79.857Z"
      fill="url(#paint8_linear_6249:253486)"
    />
    <path
      d="M82.5943 34.3058C81.779 33.6296 80.6093 33.0958 80.2902 32.2061C79.9712 31.3521 80.1839 29.6083 80.7865 29.2168C83.7641 27.1884 86.8835 25.3735 90.0738 23.7009C91.3145 23.0603 92.6615 23.7365 93.406 25.0176C94.0795 26.2275 94.0795 27.651 92.9097 28.3983C89.8966 30.3556 86.7063 32.0638 83.5869 33.8431C83.4096 33.9499 83.1261 33.9143 82.8779 33.9855C82.807 34.0922 82.7007 34.199 82.5943 34.3058Z"
      fill="url(#paint9_linear_6249:253486)"
    />
    <path
      d="M34.5625 17.5087C33.7826 18.2916 33.0736 19.466 32.152 19.6795C31.2304 19.9286 29.5643 19.5372 29.068 18.861C27.1539 16.0852 25.5587 13.0959 23.9635 10.1422C23.2191 8.78993 23.7863 7.54439 24.9915 6.7259C26.2677 5.83622 27.8274 5.87181 28.6781 7.11735C30.6632 10.1422 32.3292 13.3451 34.1016 16.4767C34.208 16.6546 34.1725 16.9393 34.2434 17.1529C34.3498 17.2596 34.4561 17.402 34.5625 17.5087Z"
      fill="url(#paint10_linear_6249:253486)"
    />
    <path
      d="M20.0997 69.2521C19.7807 69.6791 19.2844 70.6756 18.5046 71.2094C15.9878 72.8464 13.3646 74.3054 10.7415 75.7645C9.1109 76.6542 7.44485 76.1559 6.62955 74.6969C5.81425 73.2378 6.20417 71.7076 7.79933 70.7467C10.3516 69.1809 12.9393 67.6507 15.5979 66.2272C17.6893 65.0884 20.0643 66.4407 20.0997 69.2521Z"
      fill="url(#paint11_linear_6249:253486)"
    />
    <path
      d="M53.1371 7.65118C53.1371 9.14583 53.1726 10.6049 53.1371 12.0995C53.0662 13.8433 51.7901 14.9821 50.1595 14.9465C48.5644 14.9109 47.3591 13.8077 47.3237 12.1351C47.2528 9.07465 47.2528 5.97859 47.3237 2.91812C47.3591 1.10319 48.4935 0 50.195 0C51.861 0 53.0662 1.13878 53.1371 2.88254C53.1726 4.44836 53.1371 6.04977 53.1371 7.65118Z"
      fill="url(#paint12_linear_6249:253486)"
    />
    <defs>
      <linearGradient id="paint0_linear_6249:253486" x1="50" y1="0" x2="50" y2="100" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
      <linearGradient id="paint1_linear_6249:253486" x1="50" y1="0" x2="50" y2="100" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
      <linearGradient id="paint2_linear_6249:253486" x1="50" y1="0" x2="50" y2="100" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
      <linearGradient id="paint3_linear_6249:253486" x1="50" y1="0" x2="50" y2="100" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
      <linearGradient id="paint4_linear_6249:253486" x1="50" y1="0" x2="50" y2="100" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
      <linearGradient id="paint5_linear_6249:253486" x1="50" y1="0" x2="50" y2="100" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
      <linearGradient id="paint6_linear_6249:253486" x1="50" y1="0" x2="50" y2="100" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
      <linearGradient id="paint7_linear_6249:253486" x1="50" y1="0" x2="50" y2="100" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
      <linearGradient id="paint8_linear_6249:253486" x1="50" y1="0" x2="50" y2="100" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
      <linearGradient id="paint9_linear_6249:253486" x1="50" y1="0" x2="50" y2="100" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
      <linearGradient id="paint10_linear_6249:253486" x1="50" y1="0" x2="50" y2="100" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
      <linearGradient id="paint11_linear_6249:253486" x1="50" y1="0" x2="50" y2="100" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
      <linearGradient id="paint12_linear_6249:253486" x1="50" y1="0" x2="50" y2="100" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
    </defs>
  </svg>
)

export default EnergyIcon

import { Box } from '@material-ui/core'
import DependentInput from 'elements/input/DependentInput'
import { StoredAccordionCard } from 'projectSections/sections/info/AccordionCard'
import { useTranslate } from 'ra-core'
import React from 'react'
import AutoApplyInputs from 'resources/autoApply/AutoApplyInputs'

export const PaymentOptionAutoApply = () => {
  const translate = useTranslate()
  return (
    <StoredAccordionCard
      resource="payment_options"
      name="payment-auto-apply"
      title={translate('Auto-apply')}
      expandedSource="auto_apply_enabled"
    >
      <DependentInput dependsOn="auto_apply_enabled" value={true}>
        <AutoApplyInputs containerComponent={Box} />
      </DependentInput>
    </StoredAccordionCard>
  )
}

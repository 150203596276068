import { Stepper } from '@material-ui/core'
import { styled } from 'opensolar-ui'

export const StyledStepper = styled(Stepper)(({ theme }) => ({
  gap: 12,
  padding: 0,
  paddingTop: 5,
  '& .MuiStep-completed': {
    boxShadow: 'inset 0 5px 0 0 ' + theme.palette.info.contrastText + ' !important',
  },
  '& .MuiStep-horizontal': {
    boxShadow: 'inset 0 5px 0 0 ' + theme.palette.info.main,
  },
  '& .MuiStepConnector-line': {
    display: 'none',
  },
}))

export const StepContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  padding: '32px 18px',
}))

export const StepInputsContainer = styled('div')(({ theme }) => ({}))

export const LabelAndInputContainer = styled('div')(({ theme }) => ({
  border: '3px solid blue',
}))

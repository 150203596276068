import { useMemo } from 'react'
import { ANON_SHARE_CONTACT } from 'types/contacts'
import { useCountryListsTranslated } from './useCountryListsTranslated'

const parsePhonePrefix = (data, countries) => {
  if (!data) return []
  return data.map((x) => {
    let contact = { ...x }
    const phone = x.phone?.trim()
    const match = phone?.match(/\(([^()]+)\)/g)
    if (!!match?.length) {
      const countryCodeMatch = match[0]
      contact.phone = phone.replace(countryCodeMatch, '')
      let prefix = match[0].replace('(', '').replace(')', '').replace('+', '')
      if (!contact?.custom_data?.os_meta?.phone_country_iso2) {
        const countryMatches = countries.filter((y) => y.prefix === prefix)
        if (countryMatches && countryMatches.length === 1) {
          //only add prefix if one match is found
          const match = countryMatches[0]
          contact.custom_data = {
            ...(contact.custom_data || {}),
            os_meta: {
              ...(contact.custom_data?.os_meta || {}),
              phone_country_iso2: match.iso2,
            },
          }
        }
      }
    }
    return contact
  })
}

export const useParsedContactData = (contacts_data) => {
  const { countriesAll } = useCountryListsTranslated()
  const contacts = useMemo(() => contacts_data?.filter((x) => x.type !== ANON_SHARE_CONTACT), [contacts_data])

  return useMemo(() => (!contacts?.length ? [] : parsePhonePrefix(contacts, Object.values(countriesAll))), [
    contacts,
    countriesAll,
  ])
}

import { FormControl, FormControlLabel, Tooltip, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined'
import { Checkbox, IconButton } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useState } from 'react'
import { useStudioSignalsLazy } from 'Studio/signals/useStudioSignalsLazy'
import { ProjectType } from 'types/projects'
import { useStyles } from './styles'

interface PropTypes {
  project: ProjectType
  editMode: boolean
  setEditMode: (editMode: boolean) => void
}

const ImageBoundsPanel: React.FC<PropTypes> = ({ editMode, setEditMode }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const [viewBoxUsed, setViewBoxUsed] = useState(!!window.ViewHelper.selectedView()?.viewBoxParams)
  const [disabled, setDisabled] = useState(false)
  const toolTipDisabled = 'This functionality is not available for the selected imagery.'

  const onViewBoxStatusChange = (mode: string) => {
    setViewBoxUsed(!!window.ViewHelper.selectedView()?.viewBoxParams)
    setEditMode(window.ViewBoxHelper.isEditing())
  }

  const onMapChange = () => {
    // disable this feature for oblique and 3D imagery, for now
    const currentView = window.ViewHelper.selectedView()
    if (!currentView || !currentView.mapData) return
    setDisabled(!window.MapData.isTopDown(currentView.mapData))
  }

  useStudioSignalsLazy(onViewBoxStatusChange, ['viewBoxStatusChanged'])
  useStudioSignalsLazy(onMapChange, ['mapChanged'])

  const onChange = () => {
    window.ViewHelper.setViewBoxEditMode(!editMode)
  }

  const deleteViewBox = () => {
    window.ViewHelper.deleteViewBox()
  }

  return (
    <FormControl className={classes.row} size="small">
      <Tooltip
        title={toolTipDisabled}
        disableFocusListener={!disabled}
        disableHoverListener={!disabled}
        disableTouchListener={!disabled}
      >
        <FormControlLabel
          className={classes.rowLabel + ' ' + classes.fill}
          control={<Checkbox checked={editMode} onChange={onChange} disabled={disabled} />}
          label={<Typography variant="subtitle1">{translate('Set report image boundaries')}</Typography>}
        />
      </Tooltip>
      {viewBoxUsed && (
        <IconButton className={classes.rowAction} disabled={!viewBoxUsed} onClick={deleteViewBox}>
          <Tooltip title={translate('Delete Report Image Bounds')} enterDelay={300}>
            <DeleteIcon style={{ color: '#000000' }} />
          </Tooltip>
        </IconButton>
      )}
    </FormControl>
  )
}

export default ImageBoundsPanel

import { Card, Tooltip } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import Alert from 'elements/Alert'
import CustomField from 'elements/field/CustomField'
import { BooleanInput, SelectInput, TextInput, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useResourceStyles } from 'resources/styles'
import { PaymentOptionType } from '../../types/paymentOptions'
import { parseCommaSeperatedStates } from '../../util/misc'
import { BULK_ACTION_ALL_OR_NOTHING_INTEGRATIONS } from './constants'
import { payment_types_and_variables } from './fields'

const styles = {
  label: {
    minWidth: 100,
    maxWidth: 100,
  },
  fieldWrapper: {
    display: 'flex',
    height: 55,
    alignItems: 'center',
    color: '#a2a0a0',
  },
  field: {
    width: 256,
  },
  loanpalNote: {
    marginBottom: 30,
    color: '#a2a0a0',
  },
  tooltip: {
    marginLeft: 10,
  },
  autoApplyNote: {
    marginLeft: 100,
    fontSize: 12,
    color: 'red',
  },
}

type PropTypes = {
  isEdit: boolean
  record: PaymentOptionType
  heading?: string
  basePath: string
  className: string
  handleSubmitWithRedirect: Function
  resource: 'payment_options'
  saving: boolean
}
const PaymentOptionsOverviewInputs = ({ isEdit, ...props }: PropTypes) => {
  const translate = useTranslate()
  const formState = useFormState()
  const form = useForm()
  const selectedPaymentOption = formState.values['payment_type']
  const showLoanAdvanced = isEdit && selectedPaymentOption === 'loan_advanced'
  const statesAutoApplied = parseCommaSeperatedStates(formState.values['auto_apply_only_specified_states'], translate)
  const configJson = formState.values?.configuration_json && JSON.parse(formState.values.configuration_json)
  const isLoanPal = configJson?.integration === 'loanpal'
  const loanpalChannels = isLoanPal ? configJson?.channels : undefined
  const readOnlyIntegrations = ['sungage', 'mosaic', 'commonbond', 'loanpal', 'phoenix', 'lightreach']
  const readOnlyTitle = configJson && readOnlyIntegrations.includes(configJson?.integration)
  const typeChoices = payment_types_and_variables
    .filter((pf) => pf.title !== 'loan_advanced' || showLoanAdvanced)
    .map((pf) => {
      return { id: pf.title, name: pf.label }
    })
  const isPhoenix = configJson?.integration === 'phoenix'

  var hasIntegration = !!configJson?.integration
  const resourceStyles = useResourceStyles()
  const treatAllOptionsAsOne = BULK_ACTION_ALL_OR_NOTHING_INTEGRATIONS.includes(configJson?.integration)

  return (
    <Card className={resourceStyles.formSection}>
      {props.heading && <h2 style={{ color: 'rgb(0,0,0)' }}>{props.heading || 'Overview'}</h2>}
      <div style={styles.fieldWrapper}>
        <span style={styles.label}>{translate('Title')}</span>
        <CustomField
          name="title"
          source="title"
          style={styles.field}
          label={false}
          component={TextInput}
          disabled={readOnlyTitle}
        />
      </div>
      {isLoanPal && (
        <>
          <div style={styles.fieldWrapper}>
            <span style={styles.label}>{translate('Goodleap Channels')}</span>
            {/*@ts-ignore*/}
            <TextInput value={loanpalChannels} disabled={true} />
          </div>
          <p style={styles.loanpalNote}>
            If channels are applied to this Goodleap payment option then only users that have access to those channels
            can use this payment option. Users can be granted access to channels via via the "Team" tab.
          </p>
        </>
      )}
      <div style={styles.fieldWrapper}>
        <span style={styles.label}>{translate('Type')}</span>
        <SelectInput
          name="payment_type"
          source="payment_type"
          label={false}
          onChange={(e) => {
            if (e.target.value !== 'cash') {
              form.mutators.updateField('variable_payment_milestones_enabled', false)
            }
            form.mutators.updateField('quotation_items', '')
            form.mutators.updateField('quotation_labels', '')
          }}
          style={styles.field}
          choices={typeChoices}
          disabled={hasIntegration}
        />
      </div>
      <div style={styles.fieldWrapper}>
        <span style={styles.label}>{translate('Auto-apply')}</span>
        <CustomField name="auto_apply_enabled" source="auto_apply_enabled" label={false} component={BooleanInput} />
        {treatAllOptionsAsOne && (
          <Tooltip
            title={'FCA regulations require all available products to be displayed to the customer.'}
            style={styles.tooltip}
          >
            <InfoOutlined />
          </Tooltip>
        )}
      </div>
      {treatAllOptionsAsOne && formState.values['auto_apply_enabled'] && (
        <div
          style={styles.autoApplyNote}
        >{`Auto apply will take effect for all ${configJson.integration} products`}</div>
      )}
      {statesAutoApplied && (
        <Alert severity="info">
          {formState.values['auto_apply_enabled']
            ? `${translate('This Payment Option will be auto-applied on projects in ')}${statesAutoApplied}`
            : `${translate('This Payment Option is only available for projects in ')}${statesAutoApplied}`}
        </Alert>
      )}
      <div style={styles.fieldWrapper}>
        <span style={styles.label}>{translate('Description')}</span>
        <CustomField
          name="description"
          source="description"
          label={false}
          fullWidth={true}
          multiline={true}
          component={TextInput}
          disabled={isLoanPal || isPhoenix}
        />
      </div>
    </Card>
  )
}

export default PaymentOptionsOverviewInputs

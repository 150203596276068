var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { default as Mui5MenuItem } from '@mui/material/MenuItem';
import { forwardRef } from 'react';
export var MenuItem = forwardRef(function (props, ref) {
    return _jsx(Mui5MenuItem, __assign({ ref: ref }, props));
});

// @ts-nocheck
import { makeStyles } from '@material-ui/core/styles'
import CustomField from 'elements/field/CustomField'
import ImageFieldClearable from 'elements/field/ImageFieldClearable'
import { ColorInput } from 'elements/input/ColorInput'
import React from 'react'
import {
  AutocompleteInput,
  Edit,
  FormDataConsumer,
  ImageField,
  ImageInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin'
import { useSelector } from 'react-redux'
import { contentFields, FroalaEditorWithAboutContent, ShowHighLightColor } from 'resources/orgContent/ContentEdit'
import { generateDefaultValueFunc, renderDraftEditor, renderRawHtmlEditor } from 'util/misc'
import { validateOrgContentEdit } from 'validations/Orgs'
import CustomizedToolbar from './CustomizedToolbar'

const useStyles = makeStyles((theme) => ({
  inputStyle: {
    width: '100%',
  },
  actionViewRoot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 20px 15px 15px',
  },
  rightButtonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  cancelButton: {
    marginRight: '20px',
  },
  footerView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
  },
  booleanFieldWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
}))

const EnterBusinessInfo = (props: any) => {
  const { onDismiss, onSuccess, onFailure, onDelete, ...rest } = props
  const translate = useTranslate()
  const orgId = useSelector((state: any) => (state.auth ? state.auth.org_id : null))
  const classes = useStyles()
  return (
    <Edit
      hideBreadCrumbs={true}
      resource={'content'}
      style={{ paddingBottom: 0 }}
      match={{
        params: { id: orgId },
        isExact: true,
        path: '/content/:id',
        url: '/content/' + orgId,
      }}
      location={{ pathname: '/content/' + orgId }}
      {...rest}
    >
      <SimpleForm
        validate={(values) => validateOrgContentEdit(translate, values)}
        defaultValue={generateDefaultValueFunc(contentFields)}
        toolbar={
          <CustomizedToolbar onDismiss={onDismiss} onSuccess={onSuccess} onFailure={onFailure} onDelete={onDelete} />
        }
      >
        <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
          <TextInput label="resources.orgs.fields.name" source="name" className={classes.inputStyle} />
          <TextInput
            label="resources.orgs.fields.sales_phone_number"
            source="sales_phone_number"
            type="tel"
            className={classes.inputStyle}
          />
          <TextInput
            label="resources.orgs.fields.company_email"
            source="company_email"
            type="email"
            className={classes.inputStyle}
          />

          <TextInput
            label="resources.orgs.fields.company_website"
            source="company_website"
            className={classes.inputStyle}
          />
          <TextInput label="resources.orgs.fields.address" source="address" className={classes.inputStyle} />
          <TextInput label="resources.orgs.fields.locality" source="locality" className={classes.inputStyle} />

          <div style={{ width: 256, marginRight: 40 }}>
            <CustomField
              component={TextInput}
              label="resources.orgs.fields.state"
              name="state"
              source="state"
              {...rest}
            />
            <CustomField component={TextInput} label="resources.orgs.fields.zip" name="zip" source="zip" {...props} />
          </div>
          <ReferenceInput
            label="resources.orgs.fields.country"
            name="country"
            source="country"
            reference="countries"
            optionValueField="url"
            sort={{ field: 'name', order: 'ASC' }}
            allowEmpty
            {...rest}
          >
            <AutocompleteInput
              optionText="name"
              optionValue="url"
              allowDuplicates={true}
              options={{
                className: classes.inputStyle,
                placeholder: translate('Enter a country'),
              }}
            />
          </ReferenceInput>

          <div style={{ position: 'relative', zIndex: 91 }}>
            <CustomField
              component={ColorInput}
              label={'resources.orgs.fields.color_highlight'}
              name="color_highlight"
              source="color_highlight"
              {...rest}
            />
            <FormDataConsumer>
              {({ formData, ...rest }) => <ShowHighLightColor value={formData.color_highlight} />}
            </FormDataConsumer>
          </div>
        </div>

        <h1>{translate('About Us')}</h1>

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            renderDraftEditor(formData.about_content) && <FroalaEditorWithAboutContent {...rest} />
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            renderRawHtmlEditor(formData.about_content) && (
              <TextInput label="resources.orgs.fields.about_content" multiline source="about_content" {...rest} />
            )
          }
        </FormDataConsumer>

        <div style={{ clear: 'both', height: 20 }} />
        <h1>{translate('Logo')}</h1>
        <ImageFieldClearable
          label="resources.orgs.fields.logo_file_contents"
          source="logo_file_contents"
          maxWidth={200}
        />

        <ImageInput
          source="logo_file_contents"
          label="resources.orgs.fields.logo_file_contents"
          accept="image/*"
          placeholder={<p>{translate('Click to upload or drag & drop an image file here')}</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  )
}

export default EnterBusinessInfo

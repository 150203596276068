import _ from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useStudioSignals } from 'Studio/signals/useStudioSignals'
import { ModuleGridType } from 'types/global'
import useGetModuleGridSettings, { ModuleGridSettings } from './useGetModuleGridSettings'

const useModuleGridGroupSettings = ({
  moduleGrids,
}: {
  moduleGrids: ModuleGridType[]
}): Partial<ModuleGridSettings> => {
  const getModuleGridSettings = useGetModuleGridSettings()
  const [refresh, setRefresh] = useState<number>(0)
  const onObjectChanged = useCallback(
    (obj: any, _prop: string) => {
      if (!moduleGrids.includes(obj)) return
      // force a re-render if the module grid has changed
      // wrap the ctr to keep the value small
      setRefresh((prev) => (prev < 999 ? prev + 1 : 0))
    },
    [moduleGrids]
  )

  useStudioSignals(onObjectChanged, ['objectChanged'], undefined, { trackHandler: true })

  return useMemo(() => {
    if (moduleGrids.length === 1) return getModuleGridSettings({ moduleGrid: moduleGrids[0] })

    const moduleGridSettings = moduleGrids.map((moduleGrid) => getModuleGridSettings({ moduleGrid }))
    return moduleGridSettings.reduce(
      (groupSettings: Partial<ModuleGridSettings>, currentSettings: ModuleGridSettings) => {
        Object.entries(currentSettings).forEach(([key, value]) => {
          if (groupSettings.hasOwnProperty(key)) {
            const otherModuleGridsSettings = groupSettings[key]
            groupSettings[key] = _.isEqual(otherModuleGridsSettings, value) ? value : undefined
          } else {
            groupSettings[key] = value
          }
        })
        return groupSettings
      },
      {} as Partial<ModuleGridSettings>
    )
  }, [moduleGrids, refresh, getModuleGridSettings])
}

export default useModuleGridGroupSettings

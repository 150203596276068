import _ from 'lodash'
import { SCHEDULE_OPTIONS } from 'resources/utilityTariffs/common'
import { UtilityTariff } from 'resources/utilityTariffs/tariffInputs/pricingTable/types'
import { dayNames, monthNames } from 'util/misc'
import { DateRange, ScheduleDayDataType, ScheduleType } from './types'

export const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']

export const timeLabels = [
  '12 am',
  '1 am',
  '2 am',
  '3 am',
  '4 am',
  '5 am',
  '6 am',
  '7 am',
  '8 am',
  '9 am',
  '10 am',
  '11 am',
  '12 pm',
  '1 pm',
  '2 pm',
  '3 pm',
  '4 pm',
  '5 pm',
  '6 pm',
  '7 pm',
  '8 pm',
  '9 pm',
  '10 pm',
  '11 pm',
]
export const MINUTES_PER_HOUR = 60
export function translateTimeLabel(output: string, translateFunc: (s: string) => string) {
  if (output.includes('am')) output = output.replaceAll('am', translateFunc('am'))
  if (output.includes('pm')) output = output.replaceAll('pm', translateFunc('pm'))
  return output
}
export function dateDiffInDays(a: Date, b: Date) {
  const _MS_PER_DAY = 1000 * MINUTES_PER_HOUR * MINUTES_PER_HOUR * 24
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())
  return Math.floor((utc2 - utc1) / _MS_PER_DAY)
}
export const dayOfYear = (date: Date) =>
  Math.floor(
    (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) /
      1000 /
      MINUTES_PER_HOUR /
      MINUTES_PER_HOUR /
      24
  )
export function getDaysInMonth(year: number, month: number) {
  return new Date(year, month, 0).getDate()
}

//new ScheduleDialog validation if all months are selected
export function checkAllYear(dateRanges: DateRange[]) {
  let monthList: string[] = [...monthNames]
  const daysOfYear = Array.from({ length: 365 }, (_, i) => new Date(DEFAULT_YEAR, 0, i + 1))
  dateRanges.forEach((range) => {
    if (range.start_date && range.end_date) {
      const startDate = new Date(DEFAULT_YEAR, range.start_date.getMonth(), range.start_date.getDate())
      const endDate = new Date(DEFAULT_YEAR, range.end_date.getMonth(), range.end_date.getDate())

      for (let day = startDate; day <= endDate; day.setDate(day.getDate() + 1)) {
        const index = daysOfYear.findIndex((d) => d.getTime() === day.getTime())
        if (index !== -1) {
          daysOfYear.splice(index, 1)
        }
      }
    }
  })
  return daysOfYear.length === 0
}

export const formatDayKey = (dayKey: string) => {
  return dayKey.charAt(0).toUpperCase() + dayKey.slice(1)
}

//new ScheduleDialog: format string for selected days summary
const formatDaysString = (daysKeys: string[]) => {
  if (daysKeys.length > 1) {
    let daysInt = daysKeys.map((x) => days.indexOf(x))
    let length = 1
    let currStr = ''
    for (let i = 1; i <= daysInt.length; i++) {
      if (i === daysInt.length || daysInt[i] - daysInt[i - 1] !== 1) {
        if (currStr.length > 0) currStr += ', '
        if (length === 1) {
          currStr += dayNames[daysInt[i - 1]]
        } else {
          currStr += `${dayNames[daysInt[i - length]]} - ${dayNames[daysInt[i - 1]]}`
        }
        length = 1
      } else {
        length++
      }
    }
    return currStr
  } else {
    return formatDayKey(daysKeys.toString())
  }
}

const formTimeString = (hour, minutes) => {
  const getHour = hour.split(' ')[0]
  const getLabel = hour.split(' ')[1]
  const dateObject = new Date(0, 0, 0, getHour, minutes)
  const minuteString =
    dateObject.getMinutes().toString().length > 1 ? dateObject.getMinutes() : '0' + dateObject.getMinutes()
  return `${dateObject.getHours()}:${minuteString} ${getLabel}`
}

const formTimeStringArray = (timeArray) => {
  const frequency = (24 * MINUTES_PER_HOUR) / timeArray.length
  const interval = MINUTES_PER_HOUR / frequency
  let newTimeArray: string[] = []
  let timeLabelIndex = 0
  let minutes = 0
  timeArray.forEach((slot, i) => {
    const isStart = i % interval === 0
    if (i !== 0 && isStart) {
      timeLabelIndex++
      minutes = 0
    }
    if (isStart) {
      newTimeArray.push(formTimeString(timeLabels[timeLabelIndex], 0))
    } else {
      minutes += frequency
      newTimeArray.push(formTimeString(timeLabels[timeLabelIndex], minutes))
    }
  })
  return newTimeArray
}

//new ScheduleDialog: format string for selected times summary
const formatTimeDisplay = (timeArray: Boolean[]) => {
  if (timeArray.filter((x) => x === true).length === timeArray.length) {
    return ['All Hours']
  } else {
    const timeLabelsComplete = formTimeStringArray(timeArray).concat(['12:00 am'])
    const findFirst = timeArray.findIndex((x) => x === true)
    let length = 1
    let currList: string[] = []
    for (let i = findFirst + 1; i <= timeArray.length; i++) {
      if ((i === timeArray.length || timeArray[i] !== timeArray[i - 1]) && timeLabelsComplete[i] && timeArray[i - 1]) {
        currList.push(`${timeLabelsComplete[i - length]} - ${timeLabelsComplete[i]}`)
        length = 1
      } else if (timeArray[i - 1]) {
        length++
      }
    }
    if (currList.length > 0) {
      if (currList[0].startsWith('12:00 am') && currList[currList.length - 1].endsWith('12:00 am')) {
        const startTime = currList[currList.length - 1].split(' - ')
        const endTime = currList[0].split(' - ')
        const newSchedule = `${startTime[0]} - ${endTime[1]}`
        currList.shift()
        currList.pop()
        currList.unshift(newSchedule)
      }
    }
    return currList
  }
}

//new ScheduleDialog: Group the same selected schedules together (for summary)
export function groupSchedules(daysList: ScheduleDayDataType) {
  let scheduleList = {}
  let addedDays: string[] = []
  if (daysList) {
    Object.keys(daysList).forEach((day) => {
      if (!addedDays.includes(day)) {
        let days: string[] = []
        const checkSched = daysList[day].filter((x) => x === true)
        if (!days.includes(day) && checkSched.length > 0) {
          days.push(day)
          Object.keys(daysList).forEach((dayComparison) => {
            if (_.isEqual(daysList[day], daysList[dayComparison]) && day !== dayComparison) {
              days.push(dayComparison)
            }
          })
          if (days.length > 0) {
            addedDays = addedDays.concat(days)
            scheduleList[formatDaysString(days)] = formatTimeDisplay(daysList[day])
          }
        }
      }
    })
  }

  return scheduleList
}
export const DEFAULT_YEAR = 2020
export const firstDayOfCurrentYear = new Date(Date.UTC(DEFAULT_YEAR, 0, 1))
export const lastDayOfCurrentYear = new Date(Date.UTC(DEFAULT_YEAR + 1, 0, 0))

//DateRangeSelector: check if start date is greater than end date
export function isStartDateAfterEndDate(startDate: Date | undefined, endDate: Date | undefined) {
  if (startDate && endDate) {
    const start = Date.UTC(DEFAULT_YEAR, startDate.getMonth(), startDate.getDate())
    const end = Date.UTC(DEFAULT_YEAR, endDate.getMonth(), endDate.getDate())
    return start > end
  } else {
    return false
  }
}

export function defaultSchedule(): ScheduleType {
  let dayLists = {}
  const frequency = MINUTES_PER_HOUR
  const interval = (24 * MINUTES_PER_HOUR) / frequency
  days.forEach((day) => {
    dayLists[day] = [...Array(interval).fill(false)]
  })
  return {
    date_ranges: [{ start_date: undefined, end_date: undefined }],
    applicable_days_and_hours: {
      frequency: frequency,
      data: dayLists,
    },
  }
}

export const parseSchedules = (tariffData: UtilityTariff) => {
  SCHEDULE_OPTIONS.forEach((option) => {
    const optionData = tariffData?.[option]
    if (!!optionData?.length) {
      tariffData[option] = optionData.map((rate) => {
        return {
          ...rate,
          tou_periods: !!rate.tou_periods?.length
            ? rate.tou_periods.map((period) => {
                return {
                  ...period,
                  schedule: period.schedule ? parseScheduleDates(period.schedule) : null,
                }
              })
            : [],
        }
      })
    }
  })
  return tariffData
}

export const parseScheduleDates = (schedule: ScheduleType): ScheduleType => {
  return {
    ...schedule,
    date_ranges: schedule?.date_ranges?.map((range) => {
      if (range?.start_date && range?.end_date) {
        const startDate = new Date(range?.start_date)
        const endDate = new Date(range?.end_date)
        return {
          start_date: new Date(DEFAULT_YEAR, startDate?.getMonth(), startDate?.getDate()),
          end_date: new Date(DEFAULT_YEAR, endDate?.getMonth(), endDate?.getDate()),
        }
      } else {
        return range
      }
    }),
  }
}

export const parseCoordinate = (value: number, limit: number) => {
  return value < 0 ? 0 : value > limit ? limit : value
}

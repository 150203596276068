import DeleteOutlineIcon from '@material-ui/icons/DeleteOutlineOutlined'
import ActionButton from './ActionButton'

const toolIsActive = (toolName) => {
  // @ts-ignore
  const toolsActive = window.editor?.selected?.toolsActive ? window.editor.selected.toolsActive() : true

  if (toolsActive === true) {
    return true
  } else if (toolsActive === false) {
    return false
  } else if (toolsActive.hasOwnProperty(toolName)) {
    return toolsActive[toolName]
  } else {
    //default to `enabled` unless overridden
    return true
  }
}

const DeleteToolButton = () => {
  const handleClicked = () => {
    window.Designer?.requestDelete()
  }

  return (
    <ActionButton
      disabled={!toolIsActive('delete')}
      Icon={DeleteOutlineIcon}
      onClick={handleClicked}
      label={'Delete'}
    />
  )
}

export default DeleteToolButton

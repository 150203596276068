import FullScreenLoading from 'layout/widgets/FullScreenLoading'
import React, { Suspense } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  container: {
    flexGrow: 1,
  },
}))

const ManageOrdersPage = React.lazy(() => import('./ManageOrdersPage'))

const LazyManageOrdersPage = React.memo((props) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Suspense fallback={<FullScreenLoading />}>
        <ManageOrdersPage />
      </Suspense>
    </div>
  )
})

export default LazyManageOrdersPage

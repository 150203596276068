var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { default as Mui4Checkbox } from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { default as Mui5Checkbox } from '@mui/material/Checkbox';
import { Inspector } from '../debug/Inspector';
import { useComponentVersion } from '../hooks/useComponentVersion';
var useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
        color: 'rgba(0, 0, 0, 0.25)',
    },
    checkedIcon: {
        '&.MuiSvgIcon-root': {
            color: '#FFD800',
        },
    },
});
export var Checkbox = function (props) {
    return (_jsx(Inspector, { name: "Checkbox", propDefs: CheckboxPropDefs, showExample: true, component: OsCheckbox, props: props }));
};
var V2_CHECKBOX_COLORS = ['info', 'error', 'warning', 'success'];
export var OsCheckboxVersion;
(function (OsCheckboxVersion) {
    OsCheckboxVersion[OsCheckboxVersion["V1"] = 1] = "V1";
    OsCheckboxVersion[OsCheckboxVersion["V2"] = 2] = "V2";
})(OsCheckboxVersion || (OsCheckboxVersion = {}));
var OsCheckbox = function (props) {
    var classes = useStyles();
    var version = useComponentVersion('checkbox');
    switch (version) {
        case OsCheckboxVersion.V2:
            return _jsx(Mui5Checkbox, __assign({ disableRipple: true }, props));
        default:
            var mui4Props = __assign(__assign({}, props), { color: !props.color || V2_CHECKBOX_COLORS.includes(props.color)
                    ? 'default'
                    : props.color, size: props.size === 'large' ? 'medium' : props.size });
            return (_jsx(Mui4Checkbox, __assign({ className: classes.root, disableRipple: true, checkedIcon: _jsx(CheckBoxIcon, { className: props.checked ? classes.checkedIcon : '' }), inputProps: { 'aria-label': 'decorative checkbox' } }, mui4Props)));
    }
};
var CheckboxPropDefs = {
    defaultChecked: { default: false },
    checked: { hidden: true },
    indeterminate: { hidden: true },
    size: { default: 'medium' },
    color: { default: 'default' },
    icon: { hidden: true },
};

import { Grid, Typography } from '@material-ui/core'
import { orderSelectors } from 'ducks/orderComponents'
import InfoTooltip from 'elements/tooltip/InfoTooltip'
import ProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/projectOrderPresenter'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import type { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import PricingRefreshAlert from '../elements/PricingRefreshAlert'
import AddToCartButton from './AddToCartButton'
import { CartDiscountNotification } from './CartDiscounts'
import OrdersTable from './OrdersTable'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  table: {
    marginTop: 30,
    marginLeft: '25px',
    marginRight: '25px',
  },
  title: ({ hasReadyToOrderLineItem }: { hasReadyToOrderLineItem: boolean }) => ({
    color: hasReadyToOrderLineItem ? theme.alert_success : '',
    marginBottom: '14px',
  }),
  addCartButton: {
    textAlign: 'right',
    marginTop: '20px',
    marginBottom: '20px',
  },
  emptyOrder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #e7e7e7',
    borderRadius: '4px',
    height: '100px',
    width: '100%',
    color: theme.greyLight2,
  },
}))

const Order = () => {
  const lineItems = useSelector(orderSelectors.getOrderLineItems).filter((lineItem) => !lineItem.confirmed)
  const isEmptyOrder = useSelector(orderSelectors.getIsEmptyOrder)
  const hasReadyToOrderLineItem = lineItems.some((lineItem) =>
    ProjectOrderPresenter.isStockLevelReadyToOrder(ProjectOrderPresenter.getStockLevelStatusFromLineItem(lineItem))
  )
  const classes = useStyles({ hasReadyToOrderLineItem })

  return (
    <div className={classes.table}>
      <CartDiscountNotification lineItems={lineItems} />
      <Grid container spacing={0} justify="space-between" className={classes.title}>
        <Grid container alignItems="center" item xs={6}>
          <Typography variant="h5">Review Items</Typography>
          <InfoTooltip
            trackSource="hardware_review_items"
            title={'Review individual items added by project before adding them to your cart.'}
          />
        </Grid>

        <Grid item xs={'auto'}>
          <AddToCartButton />
        </Grid>
      </Grid>
      {<PricingRefreshAlert lineItems={lineItems} />}
      {isEmptyOrder ? (
        <div className={classes.emptyOrder}>Items added by project will appear here</div>
      ) : (
        <OrdersTable lineItems={lineItems} editable={true} />
      )}
      <div className={classes.addCartButton}>
        <AddToCartButton />
      </div>
    </div>
  )
}

export default memo(Order)

import { EventList, EventEdit, EventCreate } from './Events'
export default {
  list: EventList,
  edit: EventEdit,
  create: EventCreate,
  options: {
    create: {
      title: 'Create Activity',
      subtitle: null,
      breadCrumb: 'Create Template',
    },
    list: {
      title: 'All Activities',
      subtitle:
        'View and add activities on this page. Activities help you to track the progress of your solar proposals.',
      breadCrumb: 'Activities',
    },
    edit: {
      title: 'Edit Activity',
      subtitle: null,
      breadCrumb: 'Edit Activity',
    },
  },
}

import { orgSelectors } from 'ducks/orgs'
import CheckboxMultiSelectInput from 'elements/input/CheckboxMultiSelectInput'
import lodash from 'lodash'
import { FC, useEffect, useMemo } from 'react'
import { SelectInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'

interface PropTypes {
  source: string
}

const SHARED_CHOICES = [
  { id: 0, name: 'by' },
  { id: 1, name: 'with' },
]

const ProjectShared: FC<PropTypes> = ({ source }) => {
  const form = useForm()
  const formValues = useFormState().values
  const connectedOrgs = useSelector(orgSelectors.getConnectedOrgs)
  const connectedOrgOptions = useMemo(
    () =>
      connectedOrgs.map((o) => ({
        id: o.org_id.toString(),
        name: o.org_name,
      })),
    [connectedOrgs]
  )
  const filterField = useMemo(() => lodash.get(formValues, `${source}.fields`) || [], [formValues, source])

  useEffect(() => {
    if (filterField?.conditional !== undefined && filterField?.filters) {
      let newValue = {}
      newValue[filterField?.conditional === 0 ? 'owner_org_id' : 'visible_to'] = filterField?.filters
      form.change(`${source}.value`, newValue)
    }
  }, [filterField])
  return (
    <>
      <SelectInput
        choices={SHARED_CHOICES}
        label={false}
        source={`${source}.fields.conditional`}
        variant="outlined"
        margin={'none'}
      />
      <CheckboxMultiSelectInput
        label={false}
        variant={'outlined'}
        source={`${source}.fields.filters`}
        choices={connectedOrgOptions}
        style={{ width: '100%' }}
        color={'secondary'}
      />
    </>
  )
}

export default ProjectShared

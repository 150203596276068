// String length validations

import { Translate } from 'ra-core'

export const lengthExact = (length: number, translate: Translate) => {
  return (value) => {
    if (value && typeof value === 'string' && value.length !== length) {
      return translate('This field should be %{length} characters', { length })
    }
  }
}

export const lengthRange = (min: number, max: number, translate: Translate) => {
  return (value) => {
    if (value && typeof value === 'string' && (value.length < min || value.length > max)) {
      return translate('This field should be between %{min} and %{max} characters', { min, max })
    }
  }
}

import Typography from '@material-ui/core/Typography'
import type { OpenSolarThemeType } from 'Themes'
import React, { memo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  bulkAvailable: {
    color: theme.greyMid1,
    margin: 5,
  },
}))

const VolumeDiscountMessage = () => {
  const classes = useStyles()
  return (
    <Typography className={classes.bulkAvailable} variant="body1" gutterBottom>
      Volume discount available
    </Typography>
  )
}

export default memo(VolumeDiscountMessage)

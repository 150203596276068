import React, { FC, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
type PropsType = {
  pvprosell_template_id: string
  api_key_pvsell: string
}
const PVSellTemplateTitle: FC<PropsType> = ({ pvprosell_template_id, api_key_pvsell }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [title, setTitle] = useState('')
  useEffect(() => {
    fetch(
      `${
        (window as any).SUNWIZ_API_DOMAIN
      }/standalone/canvas/load-mobile-page?id=${pvprosell_template_id}&access-token=${api_key_pvsell}`
    )
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        setTitle(data.name)
        setIsLoading(false)
      })
      .catch((error) => console.log('Failed to fetch PV Sell Proposal data'))
  }, [])
  return isLoading ? (
    <Skeleton height="40px" />
  ) : (
    <i style={{ verticalAlign: 'middle' }}>{title || `#${pvprosell_template_id}`}</i>
  )
}

export default PVSellTemplateTitle

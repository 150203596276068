import { Grid } from '@material-ui/core'
import Button from 'elements/button/Button'
import React, { memo } from 'react'
import useTableStyles from '../useTableStyles'

const InventoryListActions = ({ actionTitle, onClick }: { actionTitle: string; onClick: () => void }) => {
  const classes = useTableStyles()
  return (
    <Grid container className={classes.rowWrapper} alignItems={'center'}>
      <Button size="small" onClick={onClick}>
        {actionTitle}
      </Button>
    </Grid>
  )
}

export default memo(InventoryListActions)

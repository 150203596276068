import React, { useState } from 'react'
import { useField, useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { getRoleFromState } from 'util/misc'
import SectionContainer from '../../SectionContainer'
import ProposalDrawer from '../ProposalDrawer'
import { Preview } from './Preview'
import { PreviewNotAvailable } from './PreviewNotAvailable'

export default () => {
  const form = useForm()
  const [refreshCount, refresh] = useState(1)
  const [requestedPoll, setRequestedPoll] = useState()
  const isPro = useSelector((state) => Boolean(getRoleFromState(state)))
  const projectId = useSelector((state) => state.projectId)
  const dirtyFields = form.mutators.getFormDirtyFields()
  const systemsReady = !useField('simulate_first_year_only', {
    subscription: { value: true },
  }).input.value

  console.log('systemsReady: ', systemsReady)

  const getPreviewError = (dirtyFields, projectId, systemsReady) => {
    if (isNaN(projectId)) {
      return 'Please create project before proceeding'
    } else if (dirtyFields.length > 0) {
      return 'You have unsaved changes, please save project before proceeding'
    } else if (!systemsReady) {
      if (requestedPoll !== projectId && window.WorkspaceHelper.checkLastCalcStatus(projectId)) {
        setRequestedPoll(projectId)
      }
      return 'Calculations in progress, the PDF will be available when they are complete'
    }
  }

  const error = getPreviewError(dirtyFields, projectId, systemsReady)
  return (
    <SectionContainer section="preview" mode="hidden">
      {error ? <PreviewNotAvailable message={error} /> : <Preview projectId={projectId} />}
      {isPro && <ProposalDrawer handleChange={() => refresh(refreshCount + 1)} />}
    </SectionContainer>
  )
}

import { startTourStep, terminateCurrentTour, updateTourStep } from 'actions/tourActions'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { TOUR_KEY } from 'projectSections/sections/payments/constants'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { parseQueryStringToDictionary } from 'util/misc'
import Stepper from '../fuji/Stepper'
import TourRide from '../tools/TourRide'
import { recordUserAction } from '../tools/common'
import { PAGE_ONLY_TOUR_STEPS } from './steps'

export const DISPLAY_NAME = 'payments'

export const FujiTour = () => {
  const location = useLocation()
  const history = useHistory()
  const projectId = useSelector((state: any) => state.projectId) || 'new'
  const userId = useSelector(authSelectors.getCurrentUserId)
  const { tour, step } = useSelector((state: any) => state.tour)
  const stepIndex = PAGE_ONLY_TOUR_STEPS.findIndex((s) => s.step === step)
  const visible = tour === DISPLAY_NAME && stepIndex >= 0

  const handleCancel = useCallback(() => {
    logAmplitudeEvent('payments_tour_cancelled', {})
    history.push(location.pathname)
  }, [location])

  const handleStepChange = useCallback(
    (newStep: number) => {
      if (PAGE_ONLY_TOUR_STEPS[newStep]) {
        const { step, page } = PAGE_ONLY_TOUR_STEPS[newStep]
        let newURL = ''
        if (page === 'home') {
          newURL = `/home?tour=${DISPLAY_NAME}&step=${step}`
        } else {
          newURL = `/projects/${projectId}/${page}?tour=${DISPLAY_NAME}&step=${step}`
        }
        history.push(newURL)
      } else {
        handleCancel()
      }
    },
    [projectId]
  )

  useEffect(() => {
    if (visible && userId) {
      logAmplitudeEvent('payments_tour_started', {})
      recordUserAction(userId, TOUR_KEY)
    }
  }, [visible])

  return (
    <div id="fuji-tour">
      <TourRide
        steps={PAGE_ONLY_TOUR_STEPS}
        visible={visible}
        stepIndex={stepIndex}
        handleNext={() => handleStepChange(stepIndex + 1)}
        handlePrev={() => history.goBack()}
        handleCancel={handleCancel}
      />
      <Stepper />
    </div>
  )
}

const PaymentsTourController = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const tour = useSelector((state: any) => state.tour.tour)
  const clearTour = useCallback(() => {
    //clear a tour
    dispatch(terminateCurrentTour())
  }, [])

  useEffect(() => {
    const query: any = parseQueryStringToDictionary(location.search)
    const tourInQuery = query?.tour
    const stepInQuery = query?.step
    const stepIndex = PAGE_ONLY_TOUR_STEPS.findIndex((s) => s.step === stepInQuery)
    if (tour === null && tourInQuery === DISPLAY_NAME) {
      //prohibit starting from middle of tour
      if (stepIndex === 0) {
        //start a tour
        dispatch(startTourStep(tourInQuery))
      }
    } else if (tour === tourInQuery && tour === DISPLAY_NAME && stepIndex >= 0) {
      dispatch(updateTourStep(DISPLAY_NAME, PAGE_ONLY_TOUR_STEPS[stepIndex].step))
    } else if (tour === DISPLAY_NAME && !tourInQuery) {
      // clear tour if url get cleared
      clearTour()
    }
  }, [location, tour])

  useEffect(() => {
    return clearTour
  }, [])

  return <FujiTour />
}

export default PaymentsTourController

import { FormApi } from 'final-form'
import debounce from 'lodash/debounce'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { systemCalculationsSelectors } from 'reducer/designer/systemCalculation'
import { useAutoSaveProjectEnabled } from './useAutoSaveProjectEnabled'

const AUTO_SAVE_TIME = 6000
const AUTO_SAVE_TIME_MAX = 60000

/**
 * This auto-save logic is currently only in use on lite projects, and in SDK use cases.
 */

export function useAutoSaveProject(form: FormApi) {
  const calculating = useSelector(systemCalculationsSelectors.areAnyQueuedOrProcessing)
  const enabled = useAutoSaveProjectEnabled()

  useEffect(() => {
    if (!enabled) return
    let killed = false
    const save = () => {
      const formState = form.getState()
      if (killed || !formState.dirty || calculating) return
      console.debug('Auto-save: ', form.getState().dirtyFields)
      for (const field in formState.dirtyFields) {
        form.mutators.markFieldAsDirty(field)
      }
      form.submit()
    }
    const debouncedSave = debounce(save, AUTO_SAVE_TIME, {
      leading: false,
      trailing: true,
      maxWait: AUTO_SAVE_TIME_MAX,
    })

    const handler = () => {
      const state = form.getState()

      if (state.submitting || state.active || !enabled) return

      // Don't create a project, only save it
      if (!state.values.id || isNaN(state.values.id)) return

      if (state.dirty && !calculating) {
        let saveF: Function = debouncedSave
        if (state.dirtyFields.is_lite) {
          console.log('Initial save of is_lite project')
          saveF = save
        }
        console.debug(`Auto-save in ${AUTO_SAVE_TIME}ms: `, state.dirtyFields)
        saveF()
        return
      }
    }

    handler()

    const unsub = form.subscribe(handler, { dirty: true, active: true })
    return () => {
      unsub()
      killed = true
    }
  }, [form, calculating])
}

import { Box } from '@material-ui/core'
import React from 'react'

type PropTypes = {}

const PaymentChannels: React.FC<PropTypes> = (props) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-evenly">
      <img src={`${window.PUBLIC_URL}/images/cashflow/visa.svg`} />
      <img src={`${window.PUBLIC_URL}/images/cashflow/mastercard.svg`} />
      <img src={`${window.PUBLIC_URL}/images/cashflow/apple-pay.svg`} />
      <img src={`${window.PUBLIC_URL}/images/cashflow/google-pay.svg`} />
      <img src={`${window.PUBLIC_URL}/images/cashflow/paypal.svg`} />
    </Box>
  )
}

export default PaymentChannels

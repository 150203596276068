import { orgSelectors } from 'ducks/orgs'
import { getDistributorConfigByEnum } from 'pages/ordering/configs'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import useHardwareDetailsDynamic from '../sideDrawer/bom/useHardwareDetailsDynamic'
import useDistributorPricing from '../systems/tabs/pricing/useDistributorPricing'

export const useGetNotStockedItemCodes = () => {
  const hardwareDetails = useHardwareDetailsDynamic()
  const { codesToDistributorPricing, loading } = useDistributorPricing()

  const supplier = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const selectedDistributor = getDistributorConfigByEnum(supplier)?.name
  const selectedDistributorFilterKey = getDistributorConfigByEnum(supplier)?.filterKey

  const notStockedItemCodes = useMemo(() => {
    const unstockedItems: string[] = []
    if (loading) return unstockedItems

    hardwareDetails.forEach(({ code }) => {
      const supplierPrice = codesToDistributorPricing[code]?.find(
        (pricing) => pricing.distributor === selectedDistributorFilterKey
      )
      if (!supplierPrice) unstockedItems.push(code)
    })

    return unstockedItems
  }, [hardwareDetails, codesToDistributorPricing, selectedDistributorFilterKey, loading])
  return { notStockedItemCodes, selectedDistributor }
}

import { logAmplitudeEvent } from 'amplitude/amplitude'
import { addOrderLineItem } from 'ducks/orderComponents'
import type LineItemType from 'pages/ordering/OrderLineItem'
import React, { useCallback } from 'react'
import { useNotify } from 'react-admin'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Confirmation from './Confirmation'
import CtaAction from './CtaAction'
import SupplierDetail from './SupplierDetail'

const PlaceOrderCta = ({
  lineItem,
  updateLineItem,
}: {
  lineItem: LineItemType
  updateLineItem(lineItem: LineItemType): void
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const notify = useNotify()

  const onComplete = useCallback(() => {
    history.push('/shop')
    notify('Added to cart', 'success')
  }, [])

  const handleCtaClick = useCallback(() => {
    lineItem.confirmed = true
    dispatch(addOrderLineItem(lineItem))
    onComplete()
    logAmplitudeEvent('hardware_add_to_cart_button_clicked', { source: 'product_detail', codes: [lineItem.code] })
  }, [lineItem])

  return (
    <Confirmation
      SupplierDetail={<SupplierDetail locations={lineItem.selectedDistributorOrderingData?.locations} />}
      CtaAction={
        <CtaAction
          ctaLabel={'Add to cart'}
          newLineItem={lineItem}
          handleCtaClick={handleCtaClick}
          updateLineItem={updateLineItem}
        />
      }
    />
  )
}

export default PlaceOrderCta

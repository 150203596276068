import { CardElement } from '@stripe/react-stripe-js'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { ActionDataType } from 'myenergy/selectionComponent/loanApplicationButton/types'
import restClient from 'restClient'
import { ProposalDataType } from 'types/proposals'
import { STRIPE_PAYMENT_METHOD } from '../constants'
import { CheckoutRequestData } from '../types'

const restClientInstance = restClient(window.API_ROOT + '/api')

type CardErrorHandler = (errorMessage: string, errorCode: string | undefined, is_stripe_error: boolean) => void

export const checkout = (
  postData: CheckoutRequestData,
  transactionRequestData: ActionDataType,
  proposalData: ProposalDataType,
  onSuccess: () => void,
  handleCardError: CardErrorHandler
) => {
  restClientInstance('CUSTOM_POST', 'custom', {
    url: 'orgs/' + transactionRequestData.org_id + '/projects/' + transactionRequestData.project_id + '/charge/',
    data: postData,
  })
    .then(() => {
      try {
        let signingMode = 'no signature collected'
        if (transactionRequestData?.collect_signature) {
          if (proposalData?.selectedPaymentOption?.contract_base_pdf_url) signingMode = 'Hosted PDF'
          else signingMode = 'Host HTML'
        }
        logAmplitudeEvent('stripe_success', { type: 'checkout', project_id: transactionRequestData?.project_id })
        logAmplitudeEvent('transaction_completed', {
          is_stripe: transactionRequestData?.payment_method === STRIPE_PAYMENT_METHOD,
          is_docusign: false,
          native_signing_mode: signingMode,
          payment_option_type: transactionRequestData?.payment_option_type || 'unknown',
          integration: transactionRequestData?.paymentOptionData?.integration || 'none',
          loan_type: transactionRequestData?.paymentOptionData?.loan_type || 'none',
          rounded_amount: Math.round(transactionRequestData?.total_price_payable),
          project_id: transactionRequestData?.project_id,
        })
        onSuccess()
      } catch (ex) {}
    })
    .catch((error: any) => {
      // the shape of the error response can vary from the /charge endpoint, look for either message or detail and if neitehr is found revert to a generic error message
      let errorMessage = error?.body?.message || error?.body?.detail
      if (!errorMessage)
        errorMessage = 'Something went wrong and we are unable to process this payment with the information provided'
      handleCardError(errorMessage, undefined, false)
    })
}

export const checkoutStripeWith3DSecure = async (
  checkoutData: CheckoutRequestData,
  transactionRequestData: ActionDataType,
  proposalData: ProposalDataType,
  stripePaymentRef: React.MutableRefObject<null>,
  onSuccess: () => void,
  handleCardError: CardErrorHandler
) => {
  const { error: backendError, clientSecret } = await restClientInstance('CUSTOM_POST', 'custom', {
    url:
      'projects/' +
      transactionRequestData.project_id +
      '/create_payment_intent/' +
      transactionRequestData?.paymentOptionData?.id +
      '/',
    data: checkoutData,
  })
    .then((response: { data: any }) => {
      return response.data
    })
    .catch((e: any) => {
      console.warn('unable to create payment intent from server', e)
      let errorMessage = e?.body?.detail || 'Unable to process payment'
      handleCardError(errorMessage, undefined, true)

      throw e
    })

  if (backendError) {
    console.warn(backendError)
    return {
      success: false,
      error_mesage: 'Something went wrong and we are unable to process this payment with the information provided',
      error_code: undefined,
      is_stripe_error: false,
    }
  }

  const { stripe, elements } = stripePaymentRef.current as any

  stripe
    .confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: checkoutData.customer_name,
        },
      },
    })
    .then((response: any) => {
      const { error, paymentIntent = {} } = response
      if (error) {
        let errorMessage =
          'We were unable to process the payment with the information provided. Please double check the payment details you entered.'
        if (error?.message) {
          errorMessage = error.message
        }
        handleCardError(errorMessage, error?.code, true)
      } else {
        handle3DSecureCheckoutSuccess(
          { ...paymentIntent, ...checkoutData },
          transactionRequestData,
          proposalData,
          onSuccess,
          handleCardError
        )
      }
    })
    .catch((e: any) => {
      handleCardError(
        'We were unable to process the payment with the information provided. Please double check the payment details you entered.',
        e?.code,
        true
      )
    })
}

export const handle3DSecureCheckoutSuccess = (
  payload: CheckoutRequestData,
  transactionRequestData: ActionDataType,
  proposalData: ProposalDataType,
  onSuccess: () => void,
  handleCardError: CardErrorHandler
) => {
  restClientInstance('CUSTOM_POST', 'custom', {
    url:
      'projects/' +
      transactionRequestData.project_id +
      '/confirm_card_payment/' +
      transactionRequestData?.paymentOptionData?.id +
      '/',
    data: payload,
  })
    .then((_response: any) => {
      try {
        let signingMode = 'no signature collected'
        if (transactionRequestData?.collect_signature) {
          if (proposalData?.selectedPaymentOption?.contract_base_pdf_url) signingMode = 'Hosted PDF'
          else signingMode = 'Host HTML'
        }
        logAmplitudeEvent('transaction_completed', {
          is_stripe: true,
          is_docusign: false,
          native_signing_mode: signingMode,
          payment_option_type: transactionRequestData?.payment_option_type || 'unknown',
          integration: transactionRequestData?.paymentOptionData?.integration || 'none',
          loan_type: transactionRequestData?.paymentOptionData?.loan_type || 'none',
          rounded_amount: Math.round(transactionRequestData?.total_price_payable),
          project_id: transactionRequestData.project_id,
        })
        onSuccess()
      } catch (ex) {
        handleCardError(
          'We were unable to process the payment with the information provided. Please double check the payment details you entered.',
          undefined,
          true
        )
      }
    })
    .catch((e: any) => {
      console.warn(e)
      handleCardError(
        'We were unable to process the payment with the information provided. Please double check the payment details you entered.',
        undefined,
        true
      )
    })
}

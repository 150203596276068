// in src/users.js
import { List } from 'elements/react-admin/List'
import React from 'react'
import {
  AutocompleteInput,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  FilterComp,
  FunctionField,
  NumberInput,
  ReferenceInput,
  SimpleForm,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'
import { styles as standardStyles } from 'styles'
import PaginationWithActions from '../../elements/PaginationWithActions'

export const UtilityFilter = (props) => (
  <FilterComp {...props}>
    <TextInput label="pos.search" source="q" style={standardStyles.FILTER_FIELD_STYLE} />
    <ReferenceInput source="country" reference="countries" sort={{ field: 'name', order: 'ASC' }}>
      <AutocompleteInput
        options={{ style: standardStyles.FILTER_FIELD_STYLE, placeholder: 'Enter a country' }}
        optionText="name"
        optionValue="id"
      />
    </ReferenceInput>
  </FilterComp>
)

export const UtilityList = (props) => (
  <List
    pagination={<PaginationWithActions uploadModel={'utilities'} />}
    title="Utility"
    hasSearch={true}
    filters={<UtilityFilter />}
    {...props}
  >
    <Datagrid>
      <TextField source="name" />
      <TextField source="phone" sortable={false} />
      <TextField source="website" sortable={false} />
      <FunctionField
        source={null}
        label="Actions"
        render={(record, source, rest) => (
          <div>
            <EditButton useListStyle={true} record={record} basePath={rest.basePath} />
            <DeleteButton
              useListStyle={true}
              undoable={false}
              record={record}
              resource={rest.resource}
              redirect={`/${rest.resource}`}
            />
          </div>
        )}
        sortable={false}
      />
    </Datagrid>
  </List>
)

const UtilityTitle = ({ record, translate, style }) => {
  return (
    <span style={style}>
      {translate('Utility')} {record ? `"${record.name}"` : ''}
    </span>
  )
}
const inputStyle = {
  width: 256,
}

export const UtilityEdit = (props) => {
  const translate = useTranslate()
  return (
    <Edit title={<UtilityTitle />} {...props}>
      <SimpleForm>
        <TextInput source="name" style={inputStyle} />
        <TextInput source="aliases" style={inputStyle} />
        <TextInput source="phone" style={inputStyle} />
        <TextInput source="website" style={inputStyle} />
        <ReferenceInput source="country" reference="countries" sort={{ field: 'name', order: 'ASC' }}>
          <AutocompleteInput
            options={{ style: inputStyle, placeholder: 'Enter a country' }}
            optionText="name"
            optionValue="url"
          />
        </ReferenceInput>
        <TextInput fullWidth={true} multiline={true} source="states_csv" />
        <NumberInput source="eia_id" label={'EIA Utility Id'} style={inputStyle} endAdornment={translate('Optional')} />
      </SimpleForm>
    </Edit>
  )
}

export const UtilityCreate = (props) => {
  const translate = useTranslate()
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" style={inputStyle} />
        <TextInput source="aliases" style={inputStyle} />
        <TextInput source="phone" style={inputStyle} />
        <TextInput source="website" style={inputStyle} />
        <ReferenceInput source="country" reference="countries" sort={{ field: 'name', order: 'ASC' }}>
          <AutocompleteInput
            options={{ style: inputStyle, placeholder: 'Enter a country' }}
            optionText="name"
            optionValue="url"
          />
        </ReferenceInput>
        <TextInput fullWidth={true} multiline={true} source="states_csv" />
        <NumberInput source="eia_id" label={'EIA Utility Id'} style={inputStyle} endAdornment={translate('Optional')} />
      </SimpleForm>
    </Create>
  )
}

import type { FilterType, GetDefaultFilterValuePropsType } from 'types/selectComponent'

export const inverterTypeFilter: FilterType = {
  key: 'inverter_type',
  label: 'Inverter Type',
  options: [
    { title: 'Hybrid', value: 'hybrid' },
    { title: 'Microinverter', value: 'microinverter' },
    { title: 'String / Other', value: 'string' },
  ],
  getDefaultValue({ specsData }: GetDefaultFilterValuePropsType) {
    if (specsData.microinverter === 'Y') {
      return 'microinverter'
    }
    if (specsData.hybrid === 'Y') {
      return 'hybrid'
    }
    return 'string'
  },
}

import type { ComponentTypes } from 'types/selectComponent'
import type { FilterComponentNode, FilterComponentRendererType, FilterGenericPropsType } from '../type'
import ManufacturerFilter, { ManufacturerFilterOptionsType } from './ManufacturerFilter'

export const MANUFACTURER_FILTER_NODE_KEY = 'manufacturer_config_filter'

const maybeCreateManufacturerFilterNode = ({
  RendererComponent,
  componentType,
}: {
  RendererComponent: FilterComponentRendererType<ManufacturerFilterOptionsType>
  componentType?: ComponentTypes
}): FilterComponentNode | undefined => {
  if (!componentType) {
    return undefined
  }

  const FilterComponent = (props: FilterGenericPropsType) => {
    return <ManufacturerFilter RendererComponent={RendererComponent} componentType={componentType} {...props} />
  }
  return {
    key: MANUFACTURER_FILTER_NODE_KEY,
    component: FilterComponent,
  }
}

export default maybeCreateManufacturerFilterNode

import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import { OpenSolarThemeType } from 'Themes'
import BasicInput from 'elements/input/BasicInput'
import { IconButton } from 'opensolar-ui'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  input: {
    width: 350,
    height: 40,
    borderRadius: '6px',
  },
}))

const HeadingSearchBar = ({
  searchValue,
  handleChange,
  onClear,
}: {
  searchValue: string
  handleChange(newValue: string): void
  onClear?(): void
}) => {
  const classes = useStyles()

  return (
    <BasicInput
      value={searchValue}
      placeholder={'Search'}
      onChange={handleChange}
      InputProps={{
        className: classes.input,
        startAdornment: <SearchOutlinedIcon />,
        endAdornment: searchValue && onClear && (
          <IconButton onClick={onClear}>
            <ClearOutlinedIcon />
          </IconButton>
        ),
      }}
      variant="outlined"
    />
  )
}

export default HeadingSearchBar

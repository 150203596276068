var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import createStandardIcon from './createStandardIcon';
/**
 * * Icon Component for: plus-02-filled
 */
var PlusIconSvg = function (props) {
    var fill = props.fill, rest = __rest(props, ["fill"]);
    return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", fill: "none" }, rest, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8 0.5C8.82843 0.5 9.5 1.17157 9.5 2L9.5 6.5H14C14.8284 6.5 15.5 7.17157 15.5 8C15.5 8.82843 14.8284 9.5 14 9.5H9.5V14C9.5 14.8284 8.82843 15.5 8 15.5C7.17157 15.5 6.5 14.8284 6.5 14L6.5 9.5H2C1.17157 9.5 0.5 8.82843 0.5 8C0.5 7.17157 1.17157 6.5 2 6.5H6.5L6.5 2C6.5 1.17157 7.17157 0.5 8 0.5Z", fill: fill }) })));
};
export var PlusIcon = createStandardIcon(PlusIconSvg);

import type { PaginationProps } from 'elements/list/ListTable'
import Pagination from 'elements/pagination/Pagination'
import React, { memo } from 'react'

const ProjectTablePagination = memo(({ total, query, queryPresenter }: PaginationProps) => {
  return (
    <Pagination
      total={total}
      page={query.page}
      perPage={query.perPage}
      setPage={queryPresenter.setPage}
      setPerPage={queryPresenter.setPerPage}
    />
  )
})

export default ProjectTablePagination

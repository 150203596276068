import { StudioSystemType } from 'types/studio/items'

export const hasBatteryInSystem = (system: StudioSystemType | undefined): boolean => {
  if (!system) return false

  return (
    system.batteries().length > 0 ||
    system
      .others()
      .some(({ other_component_type: oCT }) => oCT && ['battery_accessory', 'battery_inverter'].includes(oCT))
  )
}

export const checkIsBdaDisabled = (system: StudioSystemType | undefined, activeBatteryCount: number): boolean => {
  if (!system) return true

  const hasInverter = system.inverters().length > 0
  // This checks for compatible batteries based on system components (like inverter)
  const hasCompatibleBatteries = system.compatibleBatteryCodes().length > 0
  const needsMoreActiveBatteries = activeBatteryCount <= 2

  return (!hasInverter || !hasCompatibleBatteries) && needsMoreActiveBatteries
}

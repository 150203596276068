var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ActionList from '@material-ui/icons/List';
import { Link } from 'react-router-dom';
// import { Button } from '@material-ui/core'
import Button from './Button';
var getLinkPath = function (basePath, lastList) {
    if (lastList && lastList.length > 0) {
        //we have lastList, but only use it if it matches resource of basePath
        var resource = lastList.split('/')[1];
        //strip anything after ?
        resource = resource.split('?')[0];
        if (basePath.startsWith('/' + resource)) {
            return lastList;
        }
    }
    return basePath;
};
var ListButton = function (_a) {
    var _b = _a.basePath, basePath = _b === void 0 ? '' : _b, _c = _a.label, label = _c === void 0 ? 'ra.action.list' : _c, _d = _a.icon, icon = _d === void 0 ? defaultIcon : _d, lastList = _a.lastList, rest = __rest(_a, ["basePath", "label", "icon", "lastList"]);
    return (_jsx(Link, __assign({ style: { textDecoration: 'none' }, to: getLinkPath(basePath, lastList) }, { children: _jsx(Button, __assign({ color: "default", variant: 'contained', size: "medium", label: label, startIcon: icon, style: { marginRight: 0 } }, rest)) })));
};
var defaultIcon = _jsx(ActionList, {});
ListButton.propTypes = {
    basePath: PropTypes.string,
    icon: PropTypes.element,
    label: PropTypes.string,
};
var mapStateToProps = function (state) { return ({
    lastList: state.lastList,
}); };
export default connect(mapStateToProps, {})(ListButton);

import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

function CircularProgressWithLabel(props) {
  var leftMarginPixels = window.editor?.leftMarginPixels || 0
  var widthPixels = 180
  var heightPixels = 180

  return (
    <Box
      id="CircularProgressWithLabel"
      position="absolute"
      display="block"
      style={{
        margin: 'auto',
        left: 'calc(50% + ' + Math.round(leftMarginPixels / 2 - widthPixels / 2) + 'px)',
        top: 'calc(50% + ' + Math.round(-heightPixels / 2) + 'px)',
        padding: 0,
      }}
    >
      <CircularProgress size={widthPixels} variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
          style={{
            fontSize: 14,
            textAlign: 'center',
            textShadow: '1px 1px rgb(255 255 255 / 50%)',
            color: 'rgba(0, 0, 0, 0.8)',
          }}
        >
          {`${Math.round(props.value)}%`}
          {props.label && <div>{props.label}</div>}
        </Typography>
      </Box>
    </Box>
  )
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
}

export default CircularProgressWithLabel

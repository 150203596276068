// in src/users.js
import { List } from 'elements/react-admin/List'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'

import { Box, Dialog, makeStyles, Tab, Tabs, Typography, useTheme } from '@material-ui/core'
import { push as pushAction } from 'connected-react-router'
import {
  selectTariff as selectTariffAction,
  setSelectTariffDialog as setSelectTariffDialogAction,
} from 'ducks/selectTariff'
import { studioSelectors } from 'ducks/studioMode'
import { StyledDialogTitle } from 'layout/StyledDialog'
import { Button } from 'opensolar-ui'
import {
  AutocompleteInput,
  changeListParams as changeListParamsAction,
  Datagrid,
  FilterComp,
  PaginationComp,
  ReferenceInput,
  SelectInput,
  showNotification as showNotificationAction,
  TextInput,
  useTranslate,
} from 'react-admin'
import { Link, useLocation } from 'react-router-dom'
import { UTILITY_TARIFF_SECTOR_CHOICES } from 'resources/utilityTariffs/common'
import { styles as standardStyles } from 'styles'
import { DialogHelper, getRoleFromState } from 'util/misc'
import { getOrgFromState } from 'util/org'

const getDefaultFilterValues = (countryId, projectId) => {
  var filter = {
    q: '',
    country: countryId,
    distributor: null,
    utility: null,
    fieldset: 'full',
    // popularity_filter: 'any',
    // is_latest_filter: 1,
    project_id: 'None',
    tariff_dataset: 'default',
    fav: 0,
  }

  return filter
}

export const getPathForRecentTariffs = (pathname) => {
  var filters = getDefaultFilterValues(null, null)
  filters.fav = 1
  filters.fieldset = 'full'
  filters.tariff_dataset = 'all'
  return pathname + '?filter=' + encodeURIComponent(JSON.stringify(filters))
}

const TariffFilter = connect((state) => {
  var obj = {
    extraFilters: {},
  }

  try {
    obj.extraFilters.projectId = state.selectTariff.projectId
  } catch (e) {
    //
  }

  return obj
}, {})((props) => (
  <FilterComp
    style={{
      margin: '10px 0',
      padding: '10px 16px',
      borderRadius: 4,
      width: '100%',
      border: '1px solid rgb(71, 71, 71)',
    }}
    {...props}
    alignment="left"
    debounce={1000}
  >
    <TextInput style={standardStyles.FILTER_FIELD_STYLE} alwaysOn={true} label="Keyword Search" source="q" />
    {false && (
      <SelectInput
        alwaysOn={true}
        source="is_latest_filter"
        label="Effective Date"
        choices={[
          { id: 'None', name: 'Any' },
          { id: 1, name: 'Most Recent' },
          { id: 0, name: 'Older' },
        ]}
      />
    )}
    {false && (
      <SelectInput
        alwaysOn={true}
        source="popularity_filter"
        label="Popularity"
        choices={[
          { id: 'any', name: 'Any' },
          { id: 'low', name: 'Low' },
          { id: 'high', name: 'High' },
        ]}
      />
    )}
    <SelectInput
      alwaysOn={true}
      options={{ style: standardStyles.FILTER_FIELD_STYLE }}
      source="sector"
      choices={[{ id: 'Any', name: 'Any' }].concat(UTILITY_TARIFF_SECTOR_CHOICES)}
      parse={(v) => (v === 'Any' ? null : v)}
      format={(v) => (!v ? 'Any' : v)}
    />

    <ReferenceInput
      alwaysOn={true}
      source="distributor"
      reference="distributors"
      filter={{ country: props.countryId }}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <AutocompleteInput
        options={{ style: standardStyles.FILTER_FIELD_STYLE }}
        optionText="name"
        optionValue="id"
        emptyLabel="Any"
      />
    </ReferenceInput>

    <ReferenceInput
      alwaysOn={true}
      source="utility"
      reference="utilities"
      filter={{ country: props.countryId }}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <AutocompleteInput
        options={{ style: standardStyles.FILTER_FIELD_STYLE }}
        optionText="name"
        optionValue="id"
        emptyLabel="Any"
      />
    </ReferenceInput>

    <SelectInput
      alwaysOn={true}
      options={{ style: standardStyles.FILTER_FIELD_STYLE }}
      source="project_id"
      choices={[
        { id: props.projectId, name: 'Match Project Location' },
        { id: 'None', name: 'Entire Country' },
      ]}
    />

    {false && (
      <SelectInput
        alwaysOn={true}
        elStyle={{ width: 340, marginTop: -18 }}
        source="tariff_dataset"
        labelEmpty="Public & Custom"
        choices={[
          // { id: 'None', name: 'Public & Custom' },
          // { id: 1, name: 'Public Only' },
          // { id: 0, name: 'Custom Only' },
          { id: 'default', name: 'Current Tariffs + My Tariffs (Default)' },
          { id: 'my', name: 'My Tariffs' },
          { id: 'historical', name: 'Historical Tariffs' },
          { id: 'all', name: 'All' },
        ]}
        // parse={v => (v === 'None' ? null : v)}
        // format={v => (v === 1 || v === 0 ? v : 'None')}
      />
    )}

    {/*
    <ReferenceInput alwaysOn={true} elStyle={{ width: 160 }} label="Country" source="country" reference="countries">
      <AutocompleteInput options={{ fullWidth: true }} optionText="name" optionValue="id" />
    </ReferenceInput>
    */}
  </FilterComp>
))

const TariffRow = ({ projectId, record, onSelect, currentOrProposed, push, changeListParams }) => {
  const translate = useTranslate()
  const muiTheme = useTheme()
  const location = useLocation()
  return (
    <div style={{ display: 'flex', marginTop: 5, marginBottom: 5 }}>
      <div style={{ flex: '1 1 100%', marginRight: 5 }}>
        <div>{record.code}</div>
        <div className="small">{record.name}</div>
      </div>

      <div style={{ flex: '0 0 0' }}>
        <Button
          backgroundColor="#ececec"
          hoverColor={muiTheme.themeColor}
          labelStyle={{ padding: 0, margin: 0 }}
          onClick={() => {
            DialogHelper.beforeClose()
            onSelect(record, currentOrProposed)
            push(location.pathname)
            changeListParams('utility_tariffs', {
              sort: null,
              order: null,
              page: 1,
              perPage: null,
              filter: {},
            })
          }}
        >
          <span>{translate('Select')}</span>
        </Button>
      </div>
      {false && (
        <Link
          id="CustomizeTariff"
          to={'/custom_tariff_' + currentOrProposed + '/' + projectId + '/?utility_tariff_id=' + record.id}
        >
          <Button
            id="SelectTariffDialogClose"
            style={{
              flex: '0 0 0',
            }}
            labelStyle={{ padding: 0, margin: 0 }}
          >
            <span>{translate('Customise')}</span>
          </Button>
        </Link>
      )}
    </div>
  )
}

const TariffList = ({ translate, ...props }) => {
  return (
    <div>
      <List
        actions={null}
        id={props.orgId}
        showButtons={true}
        hasCreate={false}
        hideBreadCrumbs={true}
        format="popup"
        // __title={
        //   'Select ' + (props.currentOrProposed === 'current' ? 'Current' : 'Proposed') + ' ' + translate('Tariff')
        // }
        hideTitle={true}
        filters={props.filters}
        pagination={<PaginationComp widthOverride={1} />}
        perPage={20}
        location={{
          hash: '',
          pathname: '/utility_tariffs',
        }}
        resource="utility_tariffs"
        noResultsMessage={
          props.selectedTabIndex === 0
            ? 'No recently used tariffs were found. Tariffs will appear here after you have used them on a project. Select "Tariffs - Current Rates" above to search for a tariff.'
            : undefined
        }
      >
        <Datagrid headerOptions={{ hidden: true }} stylesOverride={{ marginTop: 10 }}>
          <TariffRow
            projectId={props.projectId}
            onSelect={props.onSelect}
            currentOrProposed={props.currentOrProposed}
            push={props.push}
            changeListParams={props.changeListParams}
          />
        </Datagrid>
      </List>
    </div>
  )
}

const useStyles = makeStyles({
  paper: {
    width: '80%',
    maxWidth: 900,
    position: 'relative',
  },
})

const SelectTariffDialog = (props) => {
  const location = useLocation()
  const translate = useTranslate()
  const classes = useStyles()
  const isStudioMode = useSelector(studioSelectors.isInStudio)
  const handleClose = useCallback(() => {
    DialogHelper.beforeClose()
    props.setSelectTariffDialog(false, null, null, null, isStudioMode)
    props.push(location.pathname)
    props.changeListParams('utility_tariffs', {
      sort: null,
      order: null,
      page: 1,
      perPage: null,
      filter: {},
    })
  }, [])
  if (props.isOpen) {
    DialogHelper.afterOpen('DialogSelectTariff' + props.currentOrProposed)
  }
  return (
    props.isOpen && (
      <Dialog
        key={props.version}
        id="select-tariff-dialog"
        title={null}
        autoDetectWindowHeight={false}
        classes={classes}
        open={props.isOpen}
        onBackdropClick={() => {
          DialogHelper.beforeClose()
          props.setSelectTariffDialog(false, null, null, null, isStudioMode)
          props.push(location.pathname)
          props.changeListParams('utility_tariffs', {
            sort: null,
            order: null,
            page: 1,
            perPage: null,
            filter: {},
          })
        }}
        // actionsContainerStyle={{ backgroundColor: '#e2e2e2', minHeight: 70, paddingTop: 20 }}
      >
        <StyledDialogTitle onClose={handleClose}>
          {props.currentOrProposed === 'current' ? (
            <span>{translate('Select Tariff - Current (Before Solar)')}</span>
          ) : (
            <span>{translate('Select Tariff - Proposed (After Solar)')}</span>
          )}
        </StyledDialogTitle>
        <SelectTariffTabs {...props} translate={translate} />
        {props.actions && React.createElement(props.actions)}
      </Dialog>
    )
  )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

const SelectTariffTabs = (props) => {
  const [selectedTabIndex, setTabIndex] = React.useState(0)
  const location = useLocation()
  useEffect(() => {
    let filters = getDefaultFilterValues(null, null)
    filters.fav = 1
    filters.fieldset = 'full'
    filters.tariff_dataset = 'all'
    // for (var key in filters) {
    // props.updateField(['filterForm', key, filters[key]])
    // }

    props.push(location.pathname + '?filter=' + encodeURIComponent(JSON.stringify(filters)))
  }, [])
  //To do: reduce unnecessary re-render
  const handleChange = (event, newValue) => {
    let filters
    switch (newValue) {
      case 1:
        filters = getDefaultFilterValues(props.countryId, props.projectId)
        filters.fav = 0
        filters.fieldset = 'full'
        filters.tariff_dataset = 'default'
        // filters.project_id = props.projectId
        break
      case 2:
        filters = getDefaultFilterValues(props.countryId, props.projectId)
        filters.fav = 0
        filters.fieldset = 'full'
        filters.tariff_dataset = 'all'
        break
      case 0:
      default:
        filters = getDefaultFilterValues(null, null)
        filters.fav = 1
        filters.fieldset = 'full'
        filters.tariff_dataset = 'all'
      // for (var key in filters) {
      // props.updateField(['filterForm', key, filters[key]])
      // }
    }
    props.push(location.pathname + '?filter=' + encodeURIComponent(JSON.stringify(filters)))
    setTabIndex(newValue)
  }
  const { translate } = props
  return (
    <div>
      <Tabs
        variant="fullWidth"
        tabItemContainerStyle={{ backgroundColor: '#eee' }}
        value={selectedTabIndex}
        onChange={handleChange}
      >
        <Tab
          label={props.layout === 1 ? translate('Recently Used') : translate('Recently Used Tariffs')}
          style={{
            color: selectedTabIndex === 1 ? '#4e4e4e' : '#7d7d7d',
            fontSize: props.layout === 1 ? 11 : 14,
          }}
          wrapped
        />
        <Tab
          label={props.layout === 1 ? translate('Current Rates') : translate('Tariffs - Current Rates')}
          wrapped
          style={{
            color: selectedTabIndex === 1 ? '#4e4e4e' : '#7d7d7d',
            fontSize: props.layout === 1 ? 11 : 14,
          }}
        />
        <Tab
          wrapped
          label={props.layout === 1 ? translate('Historical') : translate('Tariffs - Historical')}
          style={{
            color: selectedTabIndex === 1 ? '#4e4e4e' : '#7d7d7d',
            fontSize: props.layout === 1 ? 11 : 14,
          }}
        />
      </Tabs>
      <TabPanel value={selectedTabIndex} index={0}>
        <TariffList {...props} selectedTabIndex={selectedTabIndex} />
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={1}>
        <TariffList
          {...props}
          selectedTabIndex={selectedTabIndex}
          filters={<TariffFilter countryId={props.countryId} projectId={props.projectId} layout={props.layout} />}
        />
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={2}>
        <TariffList
          {...props}
          selectedTabIndex={selectedTabIndex}
          filters={<TariffFilter countryId={props.countryId} projectId={props.projectId} layout={props.layout} />}
        />
      </TabPanel>
    </div>
  )
}

const mapStateToProps = (state) => {
  var page
  try {
    page = state.admin.resources.utility_tariffs.list.params.page
  } catch (e) {
    page = 1
  }

  var filter
  try {
    filter = state.admin.resources.utility_tariffs.list.params.filter
  } catch (e) {
    filter = {}
  }
  // if (Object.keys(filter).length === 0) {
  //   filter = { q: 'Citi' } //{ q: 'Jem' }
  // }

  var version
  try {
    version = state.selectTariff.version
  } catch (e) {
    version = 1
  }

  var isOpen
  try {
    isOpen = Boolean(state.selectTariff.isOpen)
  } catch (e) {
    isOpen = false
  }

  return {
    version: version,
    orgId: state.auth.org_id,
    projectId: state.selectTariff.projectId,
    currentOrProposed: state.selectTariff.currentOrProposed,
    isOpen: isOpen,
    page: page,
    filter: filter,
    query: {},
    location: state.router.location,
    isAdmin: getRoleFromState(state) ? getRoleFromState(state).is_admin : false,
    org: getOrgFromState(state),
  }
}

// export default connect(mapStateToProps, { crudGetList: crudGetListAction })(
//     CustomRouteLayout
// );
//

// const FormWrappedSelectTariffDialog = (props) => {
//   return <SelectTariffDialog {...props} />
// }
//
// export default connect(mapStateToProps, {
//   showNotification: showNotificationAction,
//   changeListParams: changeListParamsAction,
//   setSelectTariffDialog: setSelectTariffDialogAction,
//   selectTariff: selectTariffAction,
//   push: pushAction,
// })(FormWrappedSelectTariffDialog)

export default connect(mapStateToProps, {
  showNotification: showNotificationAction,
  changeListParams: changeListParamsAction,
  setSelectTariffDialog: setSelectTariffDialogAction,
  selectTariff: selectTariffAction,
  push: pushAction,
})(SelectTariffDialog)

import { SORT_ASC, SORT_DESC } from 'ra-core'
import { QUERY_ORDER, Query } from './query'

export interface IQueryPresenter {
  setPage: (page: number) => void
  setPerPage: (page: number) => void
  setSort: (field: string) => void
  setOrder: (order: QUERY_ORDER) => void
}

const oppositeOrder = (direction) => (direction === SORT_DESC ? SORT_ASC : SORT_DESC)

export class QueryPresenter implements IQueryPresenter {
  private setQuery: React.Dispatch<React.SetStateAction<Query>>
  constructor(setQuery: React.Dispatch<React.SetStateAction<Query>>) {
    this.setQuery = setQuery
  }

  setPage = (page: number) => {
    this.setQuery((currentQuery: Query) => {
      return { ...currentQuery, page }
    })
  }

  setPerPage = (perPage: number) => {
    this.setQuery((currentQuery: Query) => {
      return { ...currentQuery, perPage }
    })
  }

  setSort = (sort: string) => {
    this.setQuery((currentQuery: Query) => {
      return { ...currentQuery, sort, order: oppositeOrder(currentQuery.order) }
    })
  }

  setOrder = (order: QUERY_ORDER) => {
    this.setQuery((currentQuery: Query) => {
      return { ...currentQuery, order }
    })
  }
}

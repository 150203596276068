import React, { memo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { formatCurrencyWithSymbol } from 'util/misc'

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    fontSize: '12px',
    lineHeight: '18px',
    borderRadius: '4px',
    padding: '3px 10px',
    backgroundColor: theme.secondaryColor,
    color: '#ffffff',
    fontWeight: 700,
  },
}))

const SavingDiscountTag = ({ currencySymbol, value }: { currencySymbol: string; value: number }) => {
  const classes = useStyles()
  return <div className={classes.wrapper}>Save {formatCurrencyWithSymbol(value, currencySymbol)}</div>
}

export default memo(SavingDiscountTag)

import React, { useCallback } from 'react'
import type { FilterComponentRendererType, FilterGenericPropsType, FilterKeysType, OptionGenericType } from '../type'

export type ExhibitorFilterOptionsType = OptionGenericType<number | ''>[]

type ExhibitorFilterProps = FilterGenericPropsType & {
  RendererComponent: FilterComponentRendererType<ExhibitorFilterOptionsType>
  filterKey: FilterKeysType
  exhibitorOptions: ExhibitorFilterOptionsType
}

const ExhibitorOrgFilter = ({
  disabled,
  filterKey,
  exhibitorOptions,
  modifiableFilterValues,
  persistentFilterValues,
  allFilterValues,
  setModifiableFilters,
  RendererComponent,
}: ExhibitorFilterProps) => {
  const onSelect = useCallback(
    (value) => {
      const newFilterValues = { ...modifiableFilterValues, [filterKey]: value }
      setModifiableFilters(newFilterValues)
    },
    [modifiableFilterValues]
  )

  return (
    <RendererComponent
      id={filterKey}
      defaultValue={''}
      label={'Exhibitor'}
      options={exhibitorOptions}
      disabled={disabled}
      onSelect={onSelect}
      value={allFilterValues[filterKey]}
    />
  )
}

export default ExhibitorOrgFilter

import {
  AdjustmentRate,
  FixedRate,
  ScheduledRate,
  UtilityTariff,
} from 'resources/utilityTariffs/tariffInputs/pricingTable/types'

export const getItemsByCriteria = (
  items: (ScheduledRate | AdjustmentRate | FixedRate)[],
  selectedCriterias: any = null
) => {
  return items?.filter(
    (item) =>
      !item?.criteria_name || (item?.criteria_name && item.criteria_option === selectedCriterias?.[item.criteria_name])
  )
}

export const getTaxAdjustmentFactor = (tariffData: UtilityTariff): number => {
  if (tariffData?.tax_settings === 'inclusive_tax') {
    return 1.0
  } else {
    const taxPercentage = tariffData?.tax_percentage || 0.0
    return 1.0 + taxPercentage / 100.0
  }
}

export const getAdjustmentsFactor = (tariffData: UtilityTariff, adjustment_type: string): number => {
  const adjustment_discounts = tariffData?.percentage_adjustments_discounts
    ?.filter((item) => item?.adjustment_to && [adjustment_type, 'all_charges'].includes(item?.adjustment_to))
    .map((item) => (item?.value ? item.value / 100 : 0))
  const adjustment_surcharges = tariffData?.percentage_adjustments_charges
    ?.filter((item) => item?.adjustment_to && [adjustment_type, 'all_charges'].includes(item?.adjustment_to))
    .map((item) => (item?.value ? item.value / 100 : 0))

  const adjustment_factor_discount = adjustment_discounts?.reduce((a, b) => a * (1 - b), 1.0) || 1.0
  const adjustment_factor_surcharge = adjustment_surcharges?.reduce((a, b) => a * (1 + b), 1.0) || 1.0

  return adjustment_factor_discount * adjustment_factor_surcharge
}

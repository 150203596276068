import { DropdownInput, IntegrationData, NativeMountingInputs } from 'types/mounting'
import { StudioSystemType } from 'types/studio/items'
import { MountingInputsIntegrationData, Orientation } from './types'

export function getRailDirectionInputs(system: StudioSystemType): NativeMountingInputs {
  let dropdownInputs: DropdownInput[] = []
  let hasLandscapePanels = false
  let hasPortraitPanels = false
  system.moduleGrids().forEach((moduleGrid) => {
    if (moduleGrid.moduleLayout() === 'landscape') {
      hasLandscapePanels = true
    } else {
      hasPortraitPanels = true
    }
  })
  if (hasPortraitPanels) {
    dropdownInputs.push({
      label: 'Rail Direction For Portrait Panels',
      variableName: 'railDirectionPortraitPanels',
      default: 'horizontal',
      options: [
        { label: 'Horizontal', value: 'horizontal' },
        { label: 'Vertical', value: 'vertical' },
      ],
    })
  }
  if (hasLandscapePanels) {
    dropdownInputs.push({
      label: 'Rail Direction For Landscape Panels',
      variableName: 'railDirectionLandscapePanels',
      default: 'vertical',
      options: [
        { label: 'Horizontal', value: 'horizontal' },
        { label: 'Vertical', value: 'vertical' },
      ],
    })
  }
  return { dropdownInputs }
}

export function getRailDirection(
  panelOrientation: Orientation,
  options: MountingInputsIntegrationData
): 'vertical' | 'horizontal' {
  if (
    (panelOrientation === 'portrait' && options.railDirectionPortraitPanels === 'horizontal') ||
    (panelOrientation === 'landscape' && options.railDirectionLandscapePanels === 'horizontal')
  ) {
    return 'horizontal'
  } else {
    return 'vertical'
  }
}

export function getRailOrientationsInSystem(system: StudioSystemType, integrationData: IntegrationData | undefined) {
  let panelOrientationsInSystem = getPanelOrientationsInSystem(system)
  let railOrientationsInSystem = {
    horizontal: false,
    vertical: false,
  }

  if (panelOrientationsInSystem.portrait) {
    if (integrationData?.railDirectionPortraitPanels === 'vertical') {
      railOrientationsInSystem.vertical = true
    } else {
      railOrientationsInSystem.horizontal = true
    }
  }
  if (panelOrientationsInSystem.landscape) {
    if (integrationData?.railDirectionLandscapePanels === 'horizontal') {
      railOrientationsInSystem.horizontal = true
    } else {
      railOrientationsInSystem.vertical = true
    }
  }
  return railOrientationsInSystem
}

export function getPanelOrientationsInSystem(system: StudioSystemType) {
  let panelOrientationsInSystem = {
    portrait: false,
    landscape: false,
  }
  system.moduleGrids().forEach((moduleGrid) => {
    if (moduleGrid.moduleLayout() === 'portrait') {
      panelOrientationsInSystem.portrait = true
    } else {
      panelOrientationsInSystem.landscape = true
    }
  })

  return panelOrientationsInSystem
}

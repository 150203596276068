export const PROJECT_SECTION_UPDATE = 'PROJECT_SECTION_UPDATE'

export const PROJECT_SECTION_CLEAR = 'PROJECT_SECTION_CLEAR'

export const updateProjectSection = (section?: string): any => {
  if (section) {
    return {
      type: PROJECT_SECTION_UPDATE,
      payload: {
        section,
      },
    }
  } else {
    return {
      type: PROJECT_SECTION_CLEAR,
      payload: {},
    }
  }
}

import { authSelectors } from 'ducks/auth'
import { DefaultBreadCrumbs } from 'elements/BreadCrumbs'
import { List } from 'elements/react-admin/List'
import React from 'react'
import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DeleteButton,
  FilterComp,
  FunctionField,
  ReferenceInput,
  SimpleForm,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'
import { useSelector } from 'react-redux'
import { RoleSelectInput } from 'resources/connectedOrgs/RoleSelectInput'
import { styles as standardStyles } from 'styles'
import { required } from 'validations'
import QuickFilter from '../../elements/QuickFilter'

export const GlobalRoleFilter = (props) => (
  <FilterComp {...props} displayedFilters={{ search: true }}>
    <TextInput label="pos.search" source="q" style={standardStyles.FILTER_FIELD_STYLE} />
    <QuickFilter label="User Is Staff" source="user__is_staff" defaultValue={1} />
    <ReferenceInput label="Org" source="org" reference="orgs">
      <AutocompleteInput optionText="name" optionValue="id" />
    </ReferenceInput>
    <TextInput label="User Email" source="search" style={standardStyles.FILTER_FIELD_STYLE} />
  </FilterComp>
)

export const GlobalRoleList = (props) => {
  const translate = useTranslate()
  return (
    <List hasSearch={true} filters={<GlobalRoleFilter />} minQuantityToShowAsList={0} {...props}>
      <Datagrid cardTextStyle={{ padding: 15, height: 150, overflow: 'auto' }}>
        <TextField source="id" />
        <TextField source="org_name" sortable={false} />
        <TextField source="user_email" label={translate('User Email')} sortable={false} />
        <BooleanField source="user_is_staff" sortable={false} />
        <TextField source="display" />
        <TextField source="email" label={translate('Display Email')} sortable={false} />
        <TextField source="phone" sortable={false} />
        <FunctionField
          source={null}
          label={translate('Actions')}
          render={(record, source, rest) => (
            <div>
              <DeleteButton
                useListStyle={true}
                undoable={false}
                record={record}
                resource={rest.resource}
                redirect={`/${rest.resource}`}
                disabled={!record.user_is_staff}
              />
            </div>
          )}
          sortable={false}
        />
      </Datagrid>
    </List>
  )
}

const inputStyle = {
  width: 256,
}

export const GlobalRoleCreate = (props) => {
  const isSuperuser = useSelector(authSelectors.getIsSuperUser)
  const user = useSelector(authSelectors.getCurrentUser)
  const userUrl = user ? `${window.API_ROOT_ABSOLUTE}/auth/users/${user.id}/` : undefined

  return (
    <Create breadCrumbs={<DefaultBreadCrumbs />} {...props} record={{ user: userUrl }}>
      <SimpleForm redirect="list">
        <TextInput style={inputStyle} source="first_name" />
        <TextInput style={inputStyle} source="family_name" />
        {/*
          Email not permitted because we should not specify an invitation email for the role
          because we will instead assign an existing staff user so no invitation is required.

          And a role display email is unnecessary too so remove email to avoid confusion.
          <TextInput style={inputStyle} source="email" />
        */}
        <TextInput style={inputStyle} source="phone" />
        <RoleSelectInput disabled={false} predefined_only={true} />
        <BooleanInput style={inputStyle} source="is_hidden" />
        <ReferenceInput
          source="org"
          reference="orgs"
          sort={{ field: 'name', order: 'ASC' }}
          isRequired={true}
          validate={required}
        >
          <AutocompleteInput optionText="name" optionValue="url" />
        </ReferenceInput>
        {isSuperuser ? (
          <ReferenceInput
            source="user"
            reference="global_users"
            filter={{ is_staff: true }}
            sort={{ field: 'email', order: 'ASC' }}
            isRequired={true}
            validate={required}
          >
            <AutocompleteInput optionText="email" optionValue="url" />
          </ReferenceInput>
        ) : (
          <TextInput
            source="user"
            disabled={true}
            isRequired={true}
            format={(url) => (url === userUrl ? user?.email : '')}
          />
        )}
      </SimpleForm>
    </Create>
  )
}

import { Card, CardActions, Grid, useMediaQuery } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { ControlBreadcrumbLink, ListBreadCrumbs } from 'elements/BreadCrumbs'
import { List } from 'elements/react-admin/List'
import ListActions from 'elements/react-admin/ListActions'
import InfoTooltip from 'elements/tooltip/InfoTooltip'
import { Button } from 'opensolar-ui'
import { useNotify } from 'ra-core'
import { useEffect, useState } from 'react'
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  EmailField,
  FormDataConsumer,
  FunctionField,
  NotFound,
  SimpleForm,
  TextField,
  TextInput,
  Title,
  useTranslate,
} from 'react-admin'
import { connect, useSelector } from 'react-redux'
import compose from 'recompose/compose'
import { RoleSelectInput } from 'resources/connectedOrgs/RoleSelectInput'
import set from 'set-value'
import appStorage from 'storage/appStorage'
import { doNotTranslate } from 'util/misc'
import ResendInvitationButton from '../../elements/button/ResendInvitationButton'
import { getRoleFromState, parseQueryStringToDictionary, reloadEntireApp } from '../../util/misc'
import { getOrgFromState } from '../../util/org'
import { validateRoleCreate } from '../../validations/Roles'
import { RoleFormFields } from './form/RoleFormFields'

const customTableStyle = {
  table: {
    tableLayout: 'fixed',
  },
  tbody: {
    height: 'inherit',
  },
  header: {
    th: {
      padding: 0,
    },
    'th:first-child': {
      padding: '0 0 0 12px',
    },
  },
  cell: {
    td: {
      padding: '0 12px',
      whiteSpace: 'normal',
    },
    'td:first-child': {
      padding: '0 12px 0 16px',
      whiteSpace: 'normal',
    },
  },
}

export const RoleList = ({ accessRights: { allowView, allowCreate, allowEdit, allowDelete }, ...props }) => {
  const isAdmin = useSelector(authSelectors.getIsAdmin)
  const role = useSelector(authSelectors.getCurrentRole)
  const matchMedium = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const matchLarge = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  // if the user just editing their own role then they'll come back here with a reload param. Look for that and reload the page to refresh role data
  useEffect(() => {
    const postHashParams = window.location.hash.substring(window.location.hash.indexOf('?') + 1)
    if (!postHashParams) return null
    const params = new URLSearchParams(postHashParams)
    const reloadParam = params.get('reload')
    if (reloadParam === 'true') {
      // be sure to strip out the param to avoid a loop
      reloadEntireApp('/#/roles')
    }
  }, [])

  return (
    <List
      perPage={20}
      actions={<ListActions hasArchived={false} />}
      hasSearch={true}
      {...props}
      hasCreate={allowCreate}
    >
      <Datagrid styles={customTableStyle}>
        <TextField source="display" sortable={false} />
        <BooleanField source="is_admin" textAlign={'center'} />
        {matchMedium && <EmailField source="user_email" sortable={false} />}
        {matchLarge && <TextField source="phone" sortable={false} />}

        <FunctionField
          source={null}
          label="Actions"
          render={(record, source, rest) => {
            const isNoneAdminRole = !record?.is_admin
            const isCurrentRole = record?.id === role.id
            const managedByPartner = record?.managed_by
            const nonAdminDeletable = allowDelete && isNoneAdminRole
            const nonAdminEditable = (allowEdit && isNoneAdminRole) || isCurrentRole
            const nonAdminViewable = (allowView && isNoneAdminRole) || isCurrentRole
            return (
              <div>
                {(isAdmin || nonAdminEditable || nonAdminViewable) && (
                  <EditButton
                    useListStyle={true}
                    record={record}
                    basePath={rest.basePath}
                    label={isAdmin || nonAdminEditable ? 'Edit' : 'View'}
                  />
                )}

                {(isAdmin || nonAdminDeletable) && !isCurrentRole && (
                  <Grid container wrap="wrap" direction="row" alignItems="center">
                    <DeleteButton
                      useListStyle={true}
                      disabled={!!managedByPartner}
                      undoable={false}
                      record={record}
                      resource={rest.resource}
                      redirect={`/${rest.resource}`}
                    />
                    {!!managedByPartner && (
                      <InfoTooltip
                        title={doNotTranslate(
                          `This team member is managed by ${managedByPartner} and must be deleted from that system`
                        )}
                      />
                    )}
                  </Grid>
                )}

                <ResendInvitationButton useListStyle={true} record={record} />
              </div>
            )
          }}
          sortable={false}
        />
      </Datagrid>
    </List>
  )
}

const inputStyle = { width: 256 }

const mapStateToProps = (state) => {
  const role = getRoleFromState(state)
  return {
    isAdmin: Boolean(role) && role.is_admin,
    users_role_id: role?.id,
  }
}

export const RoleEdit = ({ accessRights: { allowView, allowCreate, allowEdit, allowDelete }, ...props }) => {
  const notify = useNotify()
  const role = useSelector(authSelectors.getCurrentRole)
  const isCurrentRole = props.id === role?.id?.toString()

  // Hack to expose the record at this level to control the actions available
  const [record, setRecord] = useState()
  const managedByOS = !record?.managed_by

  useEffect(() => {
    const path = window.location.hash.substring(window.location.hash.indexOf('#') + 2)
    const params = new URLSearchParams(path.substring(path.indexOf('?')))
    const show_success = params.get('show_brighte_success')
    const show_error = params.get('show_brighte_error')
    if (show_success) {
      setTimeout(() => {
        notify('Your user has been connected to Brighte!', 'success')
        window.location.href = window.location.href.replace('?show_brighte_success=true', '')
      }, 2000)
    } else if (show_error) {
      setTimeout(() => {
        if (show_error === 'vendor_id') {
          notify(
            'The vendor for your Brighte user does not match the vendor saved to your OpenSolar organisation',
            'warning'
          )
          appStorage.clear('brighte_vendor_mismatch')
          window.location.href = window.location.href.replace('?show_brighte_error=vendor_id', '')
        } else if (show_error === 'duplicate_user') {
          notify(
            'The Brighte user you logged in as has already been connected to an OpenSolar user. Please login to a Brighte user that has not yet been used.',
            'warning'
          )
          appStorage.clear('brighte_duplicate_user')
          window.location.href = window.location.href.replace('?show_brighte_error=duplicate_user', '')
        } else {
          notify('We were unable to connect your Brighte user', 'warning')
          window.location.href = window.location.href.replace('?show_brighte_error=generic', '')
        }
      }, 2000)
    }
  }, [])

  return (
    <Edit {...props} hasDelete={allowDelete && !isCurrentRole && managedByOS}>
      <EditForm allowEdit={allowEdit} setRecord={setRecord} {...props} />
    </Edit>
  )
}

const _EditForm = ({ allowEdit, isAdmin, setRecord, ...props }) => {
  const role = useSelector(authSelectors.getCurrentRole)
  const isNoneAdminRole = !props?.record?.is_admin
  const isCurrentRole = props?.record?.id === role.id
  const nonAdminEditable = (allowEdit && isNoneAdminRole) || isCurrentRole
  const editable = isAdmin ? true : nonAdminEditable

  setRecord(props.record)

  // if they are editing their own role we should refresh the page on save to make sure their edited data is discoverable
  const isEditingSelf = `${props.users_role_id}` === props.id
  const redirectPath = isEditingSelf ? '/roles?reload=true' : '/roles'
  return (
    <SimpleForm {...props} redirect={redirectPath} toolbar={props.toolbar} disabled={!editable}>
      <RoleFormFields id={props.id} />
    </SimpleForm>
  )
}

const EditForm = connect(mapStateToProps, {})(_EditForm)

const injectFromQueryString = (search, data) => {
  var fields = {
    email: 'string',
    first_name: 'string',
    family_name: 'string',
    phone: 'string',
    is_admin: 'boolean',
    permissions_role: 'int',
    allow_email_notifications: 'boolean',
    job_title: 'string',
    accreditation: 'string',
    managed_by: 'string',
  }

  var params = parseQueryStringToDictionary(search)
  Object.keys(params).forEach((path) => {
    if (Object.keys(fields).indexOf(path) !== -1) {
      var value
      switch (fields[path]) {
        case 'int':
          value = parseInt(params[path])
          break
        case 'float':
          value = parseFloat(params[path])
          break
        case 'boolean':
          value = Boolean(params[path])
          break
        case 'array_of_strings':
          value = params[path].split('|')
          break
        case 'json':
          value = JSON.parse(params[path])
          break
        case 'string':
        default:
          value = params[path]
          break
      }

      set(data, path, value)
    }
  })
}

const defaultValueFunc = function (search) {
  var data = {}

  if (search) {
    injectFromQueryString(search, data)
  }

  return data
}

const checkValueIsNotUndefined = (value) => typeof value !== 'undefined'

export const RoleCreate = compose(
  connect(
    (state) => ({
      loginAuthority: getOrgFromState(state)?.external_account_id ? 'nearmap' : null,
    }),
    {}
  )
)(({ accessRights, ...props }) => {
  const translate = useTranslate()

  const breadcrumbs = [ControlBreadcrumbLink, { title: 'Team Members List', link: '/roles' }, { title: 'Grant Access' }]

  // This is used to grant the Sunwiz user access to the Org
  // Sunwiz creates a URL including their user details, which opens the form pre-populated
  // The user can then accept and the role is created.
  if (props.location.search && props.location.search.indexOf('override') === -1) {
    return (
      <Create breadCrumbs={<ListBreadCrumbs links={breadcrumbs} />} title="Grant Access" redirect="list" {...props}>
        <SimpleForm
          defaultValue={defaultValueFunc(props.location.search)}
          validate={(values, form) => validateRoleCreate(values, form, translate)}
          toolbar={props.toolbar}
        >
          <h2>
            {translate('Grant full OpenSolar account and data access to the user at the specified email address.')}
          </h2>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              checkValueIsNotUndefined(formData.email) && (
                <TextInput source="email" {...rest} options={{ disabled: true }} />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              checkValueIsNotUndefined(formData.first_name) && (
                <TextInput source="first_name" {...rest} options={{ disabled: true }} />
              )
            }
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData, ...rest }) =>
              checkValueIsNotUndefined(formData.family_name) && (
                <TextInput source="family_name" {...rest} options={{ disabled: true }} />
              )
            }
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData, ...rest }) =>
              checkValueIsNotUndefined(formData.phone) && (
                <TextInput source="phone" {...rest} options={{ disabled: true }} />
              )
            }
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData, ...rest }) =>
              checkValueIsNotUndefined(formData.permissions_role) && <RoleSelectInput disabled={true} />
            }
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData, ...rest }) =>
              checkValueIsNotUndefined(formData.allow_email_notifications) && (
                <BooleanInput
                  source="allow_email_notifications"
                  {...rest}
                  options={{ disabled: true }}
                  style={inputStyle}
                />
              )
            }
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData, ...rest }) =>
              checkValueIsNotUndefined(formData.job_title) && (
                <TextInput source="job_title" {...rest} options={{ disabled: true }} />
              )
            }
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData, ...rest }) =>
              checkValueIsNotUndefined(formData.accreditation) && (
                <TextInput source="accreditation" {...rest} options={{ disabled: true }} />
              )
            }
          </FormDataConsumer>
        </SimpleForm>
      </Create>
    )
  }

  if (props.loginAuthority === 'nearmap' && props.location.search.indexOf('override') === -1) {
    return (
      <Card>
        <Title title="Team Members" />
        <CardActions>
          <Button
            backgroundColor="#D8D8D8"
            onClick={() => {
              window.location.href = window.API_BASE_URL + 'nearmap/welcome/'
            }}
          >
            <span style={{ textTransform: 'none' }}>{translate('Manage Nearmap users')}</span>
          </Button>
        </CardActions>
      </Card>
    )
  }
  //disable the create new role page for anyone that isn't admin
  if (!accessRights.allowCreate) {
    return <NotFound />
  }
  return (
    <Create {...props}>
      <SimpleForm toolbar={props.toolbar} validate={(values, form) => validateRoleCreate(values, form, translate)}>
        <RoleFormFields />
      </SimpleForm>
    </Create>
  )
})

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { TableHead as Mui4TableHead } from '@material-ui/core';
import { default as Mui5TableHead } from '@mui/material/TableHead';
import { forwardRef } from 'react';
import { Inspector } from '../debug/Inspector';
import { useComponentVersion } from '../hooks';
import { OsTableVersion } from './Table';
export var TableHead = forwardRef(function (props, ref) {
    return (_jsx(Inspector, { name: "TableHead", propDefs: TableHeadProps, showExample: true, component: OsTableHead, props: __assign(__assign({}, props), { ref: ref }) }));
});
var OsTableHead = forwardRef(function (props, ref) {
    if (ref === void 0) { ref = null; }
    var version = useComponentVersion('table');
    switch (version) {
        case OsTableVersion.V2:
            return _jsx(Mui5TableHead, __assign({ ref: ref }, props));
        default:
            return _jsx(Mui4TableHead, __assign({ ref: ref }, props));
    }
});
var TableHeadProps = {};

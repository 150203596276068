import { HttpError, useNotify, useTranslate } from 'ra-core'
import { useTaxIdentifierLabel } from './useTaxIdentifierLabel'

export const useNotifyWalletError = (currency: string | undefined, countryIso2: string | undefined) => {
  const notify = useNotify()
  const taxIdentifierLabel = useTaxIdentifierLabel(currency, countryIso2)
  const translate = useTranslate()

  return (err: HttpError) => {
    const keys = err.body ? Object.keys(err.body) : []
    let messages: string[] = []
    keys.forEach((key) => {
      let keyName
      if (key === 'tax_identifier') keyName = taxIdentifierLabel
      else keyName = translate(key)

      const errors: string[] = err.body[key]
      errors.forEach((error) => {
        if (key === 'non_field_errors') messages.push(translate(error))
        else messages.push(`${keyName}: ${translate(error)}`)
      })
    })

    notify(messages.length ? messages.join('\n') : err.message, 'error')
  }
}

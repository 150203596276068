import { Link } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import { Table, TableBody, TableCell, TableHead, TableRow } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { useDateFormatter } from 'util/date'
import { TermsAndConditionsAgreement } from './types'

type PropTypes = {
  agreements: TermsAndConditionsAgreement[]
  isLoading: boolean
}

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '15px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableStyles: {
    '& .MuiTable-root': {
      border: '1px solid #E7E7E7 !important',
    },
    '& .MuiTableHead-root': {
      backgroundColor: theme.greyLight3,
      padding: '8px 10px',
    },
    '& .MuiTableHead-root, & .MuiTableBody-root': {
      border: '1px solid #E7E7E7 !important',
    },
  },
  tableLoader: {
    '& .MuiTableCell-root': {
      padding: '0px',
    },
  },
}))

const TermsAndConditionsTable: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const formatDate = useDateFormatter({ long: true, variant: 'date' })
  const { agreements, isLoading } = props

  return (
    <div className={classes.wrapper}>
      <Table className={classes.tableStyles}>
        <TableHead>
          <TableRow>
            <TableCell>{translate('Agreement')}</TableCell>
            <TableCell>{translate('User')}</TableCell>
            <TableCell>{translate('Agreement Date')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading &&
            new Array(3).fill(1).map((a, idx) => (
              <TableRow key={idx} className={classes.tableLoader}>
                <TableCell colSpan={3}>
                  <Skeleton height="50px" />
                </TableCell>
              </TableRow>
            ))}
          {!isLoading && (
            <>
              {agreements &&
                agreements.map((agreement) => (
                  <TableRow key={agreement.title}>
                    <TableCell>
                      <Link href={agreement.url} target="_blank">
                        {agreement.title}
                      </Link>
                    </TableCell>
                    <TableCell>{agreement.user || '-'}</TableCell>
                    <TableCell>{agreement.date ? formatDate(agreement.date) : '-'}</TableCell>
                  </TableRow>
                ))}
              {!agreements?.length && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <span>{translate('No results found')}</span>
                  </TableCell>
                </TableRow>
              )}
            </>
          )}
        </TableBody>
      </Table>
    </div>
  )
}

export default TermsAndConditionsTable

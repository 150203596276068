import { Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import GenericButton from 'elements/button/GenericButton'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import LoadingDots from 'layout/widgets/LoadingDots'
import React, { useEffect, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import restClient from 'restClient'
import { PaymentOptionType } from 'types/paymentOptions'
import { ActionDataType } from '../loanApplicationButton/types'

var restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  loanType: 'mosaic_application' | 'loanpal_application' | 'dividend_application'
  acceptButtonStyle: CSSProperties
  paymentOptionData: PaymentOptionType
  actionData: ActionDataType
  myeStyles: any
}

const LoanAgreementButton: React.FunctionComponent<PropTypes> = (props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [docUrl, setDocUrl] = useState<string | undefined>(undefined)
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const [pollCount, setPollCount] = useState<number>(0)

  useEffect(() => {
    if (pollCount && pollCount < 10 && showDialog) {
      setTimeout(() => {
        pollForSigningUrl(pollCount, showDialog)
      }, 10000)
    }
  }, [pollCount, showDialog])

  useEffect(() => {
    if (pollCount > 0 && !showDialog) setPollCount(0)
  }, [pollCount, showDialog])

  const translate = useTranslate()
  const notify = useNotify()

  useEffect(() => {
    logAmplitudeEvent('integrated_finance_loan_agreement_shown', { integration: 'mosaic', screen: 'my_energy' })
  }, [])

  const onClick = () => {
    if (props.loanType === 'mosaic_application') handleMosaicClick()
    else if (props.loanType === 'loanpal_application') handleLoanpalClick()
    else if (props.loanType === 'dividend_application') handleDividendClick()
  }

  const handleLoanpalClick = () => {
    setLoading(true)
    restClientInstance('CUSTOM_POST', 'custom', {
      url:
        'orgs/' +
        props.actionData.org_id +
        '/projects/' +
        props.actionData.project_id +
        '/systems/' +
        props.actionData.system_uuid +
        '/' +
        props.paymentOptionData.id +
        '/loanpal_docs/',
    })
      .then((res: any) => {
        console.log('res', res)
        if (res.data?.success) {
          logAmplitudeEvent('integrated_finance_loan_agreement_sent', { integration: 'loanpal', screen: 'my_energy' })
          notify(
            'Your Loan Documents will be sent to your email address shortly. This may take up to two minutes.',
            'success'
          )
        }
      })
      .catch((err: any) => {
        console.log('err', err)
        if (err?.body?.message) {
          notify(err.body.message, 'warning')
        } else {
          notify('Something went wrong and we are unable to send your Loan Agreement', 'warning')
        }
        logAmplitudeEvent('integrated_finance_loan_agreement_error', { integration: 'loanpal', screen: 'my_energy' })
      })
      .finally(() => setLoading(false))
  }

  const handleDividendClick = () => {
    setLoading(true)
    restClientInstance('CUSTOM_POST', 'custom', {
      url:
        'orgs/' +
        props.actionData.org_id +
        '/projects/' +
        props.actionData.project_id +
        '/systems/' +
        props.actionData.system_uuid +
        '/' +
        props.paymentOptionData.id +
        '/dividend/loan_docs/',
    })
      .then((res: any) => {
        console.log('res', res)
        if (res.data?.success) {
          setShowDialog(true)
          setPollCount(1)
        } else {
          notify('Something went wrong and we are unable to send your Loan Agreement', 'warning')
          logAmplitudeEvent('integrated_finance_loan_agreement_error', { integration: 'dividend', screen: 'my_energy' })
        }
      })
      .catch((err: any) => {
        console.log('err', err)
        if (err?.body?.message) {
          notify(err.body.message, 'warning')
        } else {
          notify('Something went wrong and we are unable to send your Loan Agreement', 'warning')
        }
        setShowDialog(false)
        setPollCount(0)
        logAmplitudeEvent('integrated_finance_loan_agreement_error', { integration: 'dividend', screen: 'my_energy' })
        setLoading(false)
      })
  }

  const pollForSigningUrl = (localPollCount: number, localShowDialog: boolean) => {
    if (!localShowDialog) return
    restClientInstance('CUSTOM_GET', 'custom', {
      url:
        'orgs/' +
        props.actionData.org_id +
        '/projects/' +
        props.actionData.project_id +
        '/systems/' +
        props.actionData.system_uuid +
        '/' +
        props.paymentOptionData.id +
        '/dividend/loan_docs/signing_url/?poll_count=' +
        localPollCount,
    })
      .then((res: any) => {
        if (res.data?.success) {
          if (res.data.url) {
            setDocUrl(res.data.url)
            setShowDialog(true)
            setLoading(false)
          } else {
            setPollCount(localPollCount + 1)
          }
        }
      })
      .catch((err: any) => {
        console.log('err', err)
        if (err?.body?.message) {
          notify(err.body.message, 'warning')
        } else {
          notify('Something went wrong and we are unable to send your Loan Agreement', 'warning')
        }
        setLoading(false)
        logAmplitudeEvent('integrated_finance_loan_agreement_error', { integration: 'dividend', screen: 'my_energy' })
      })
  }

  const handleMosaicClick = () => {
    setLoading(true)
    setShowDialog(true)
    logAmplitudeEvent('integrated_finance_loan_agreement_started', { integration: 'mosaic', screen: 'my_energy' })
    restClientInstance('CUSTOM_GET', 'custom', {
      url:
        'orgs/' +
        props.actionData.org_id +
        '/projects/' +
        props.actionData.project_id +
        '/systems/' +
        props.actionData.system_uuid +
        '/' +
        props.paymentOptionData.id +
        '/mosaic/loan_agreement/?method=Link',
    })
      .then((res: any) => {
        console.log('res', res)
        if (res.data?.success && res.data?.url) {
          setDocUrl(res.data.url)
          setErrorMsg(undefined)
          logAmplitudeEvent('integrated_finance_loan_agreement_ready', { integration: 'mosaic', screen: 'my_energy' })
        }
      })
      .catch((err: any) => {
        console.log('err', err)
        if (err?.body?.message) {
          setErrorMsg(err.body.message)
        } else {
          setErrorMsg('Something went wrong and we are unable to bring you to your Loan Agreement')
        }
        logAmplitudeEvent('integrated_finance_loan_agreement_error', { integration: 'mosaic', screen: 'my_energy' })
      })
      .finally(() => setLoading(false))
  }

  const goToUrl = () => {
    if (docUrl) {
      logAmplitudeEvent('integrated_finance_loan_agreement_sent', {
        integration: props.loanType === 'mosaic_application' ? 'mosaic' : 'dividend',
        screen: 'my_energy',
      })
      if (props.loanType === 'mosaic_application') {
        window.location.href = docUrl
      } else {
        window.open(docUrl, '_blank')
        setShowDialog(false)
        setLoading(false)
      }
    } else setErrorMsg('Something went wrong and we are unable to bring you to your Loan Agreement.')
  }

  const closeDialog = () => {
    setShowDialog(false)
    setPollCount(0)
    setLoading(false)
  }

  if (props.actionData.status_code === 'complete') {
    return (
      <div
        style={{
          textAlign: 'center',
          color: props.myeStyles ? props.myeStyles.textColor : 'black',
          margin: '15px 0px 15px 0px',
        }}
      >
        ✓ Your Loan Agreement has been signed!
      </div>
    )
  }

  return (
    <div>
      {/* @ts-ignore */}
      <GenericButton
        wrapperStyle={{ display: 'block', marginTop: '15px' }}
        style={{
          ...props.acceptButtonStyle,
          height: 39,
          fontSize: 16,
          padding: 0,
          width: 'calc(100% - 2px)',
          letterSpacing: 'normal',
          marginBottom: '10px',
        }}
        labelWrapperStyle={{ padding: 0, width: '100%', textTransform: 'none', whiteSpace: 'normal' }}
        label={
          <>
            {props.loanType === 'loanpal_application' && (
              <>{loading ? <LoadingDots color="#fff" /> : <div>{translate('Request Loan Docs Email')}</div>}</>
            )}
            {props.loanType === 'mosaic_application' && <div>{translate('Sign Your Loan Agreement')}</div>}
            {props.loanType === 'dividend_application' && (
              <>{loading ? <LoadingDots color="#fff" /> : <div>{translate('Sign Your Loan Agreement')}</div>}</>
            )}
          </>
        }
        onClick={(e: any) => {
          e.preventDefault()
          onClick()
        }}
      />
      <Dialog open={showDialog} onClose={closeDialog}>
        <DialogTitle>
          {loading
            ? translate('Preparing your Loan Agreement')
            : errorMsg
            ? translate('Something Went Wrong')
            : translate('Your Loan Agreement is Ready!')}
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={3}
            style={{
              minHeight: loading ? '300px' : undefined,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '0px 40px',
            }}
          >
            {loading ? (
              <LoadingDots
                text={
                  props.loanType === 'mosaic_application'
                    ? 'Your Loan Agreement is being prepared. This process can take up to thirty seconds. Thank you for your patience.'
                    : 'Dividend is preparing your Loan Agreement. This process can take up to a minute. Thank you for your patience.'
                }
              />
            ) : (
              <>
                {errorMsg && (
                  <>
                    <Grid item xs={12}>
                      <p style={{ color: 'red', textAlign: 'center' }}>{errorMsg}</p>
                    </Grid>
                    <Grid item xs={12} style={{ margin: '20px 0px', display: 'flex', justifyContent: 'center' }}>
                      <ProUXButton onClick={() => setShowDialog(false)} label="Dismiss" type="secondary" />
                    </Grid>
                  </>
                )}
                {!errorMsg && (
                  <>
                    <Grid item xs={12}>
                      <p>
                        {props.loanType === 'mosaic_application' && (
                          <p>
                            {translate(
                              'Your Loan Agreement is ready for your review and signature. Clicking "View Documents" below will take you to your Loan Agreement on Mosaic\'s website.'
                            )}
                          </p>
                        )}
                      </p>
                      {props.loanType === 'dividend_application' && (
                        <>
                          <p>
                            {translate(
                              'Your Loan Agreement is ready for your review and signature. Clicking "View Documents" below will take you to your Loan Agreement on Dividend\'s Site where you will be asked to create an account. After creating your account you will be brought to Docusign to review and sign your Loan Agreement.'
                            )}
                          </p>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12} style={{ margin: '20px 0px', display: 'flex', justifyContent: 'center' }}>
                      <ProUXButton
                        onClick={goToUrl}
                        label={
                          props.loanType === 'mosaic_application'
                            ? "View Documents on Mosaic's Site"
                            : "View Documents on Dividend's Site"
                        }
                        type="primary"
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  )
}
export default LoanAgreementButton

import Grid from '@material-ui/core/Grid'
import classnames from 'classnames'
import { memo } from 'react'
import { useTranslate } from 'react-admin'
import useTableStyles from '../useTableStyles'

const EditableInventoryTableHeader = ({ editMin, editOnHand }: { editMin?: boolean; editOnHand?: boolean }) => {
  const classes = useTableStyles()
  const translate = useTranslate()

  if (editMin || editOnHand) {
    return (
      <Grid
        container
        xs={12}
        className={classnames(classes.tableHeaderContainer, classes.rowWrapper)}
        alignItems="center"
        justify="center"
      >
        <Grid item xs={1}>
          {translate('Category')}
        </Grid>
        <Grid item xs={4}>
          {translate('Item')}
        </Grid>

        <Grid item xs={1} className={classes.gridItem}>
          <span>{translate(editMin ? 'Min' : 'On hand')}</span>
        </Grid>
        <Grid item xs={1} className={classes.gridItem}>
          <span> {translate('Old Value')}</span>
        </Grid>
        <Grid item xs={1} className={classes.gridItem}></Grid>
        <Grid item xs={2} className={classes.gridItem}></Grid>
        <Grid item xs={2} className={classes.gridItem}></Grid>
      </Grid>
    )
  }

  return (
    <Grid
      container
      xs={12}
      className={classnames(classes.tableHeaderContainer, classes.rowWrapper)}
      alignItems="center"
      justify="center"
    >
      <Grid item xs={1}>
        {translate('Category')}
      </Grid>
      <Grid item xs={5}>
        {translate('Item')}
      </Grid>
      <Grid item xs={1} className={classes.gridItem}>
        <span>{translate('Min')}</span>
      </Grid>
      <Grid item xs={1} className={classes.gridItem}>
        <span> {translate('Old Value')}</span>
      </Grid>
      <Grid item xs={1} className={classes.gridItem}>
        <span>{translate('On hand')}</span>
      </Grid>
      <Grid item xs={1} className={classes.gridItem}>
        <span>{translate('Old Value')}</span>
      </Grid>
      <Grid item xs={2} className={classes.gridItem}>
        <span>{translate('Actions')}</span>
      </Grid>
    </Grid>
  )
}

export default memo(EditableInventoryTableHeader)

import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles<StyleOpts>((theme) => ({
  link: (opts) => ({
    display: 'inline-block',
    cursor: 'pointer',
    textDecoration: 'underline',
    margin: opts.margin === 'dense' ? 0 : '0 4px 1em',
  }),
}))

type StyleOpts = {
  margin?: 'dense'
}

type Props = {
  id: string
  handleClick?: (e?: any) => boolean | void
  label: string
} & StyleOpts

const BasicLink = ({ id, handleClick, label, ...opts }: Props) => {
  const classes = useStyles(opts)
  return (
    <a
      id={id}
      href=""
      className={classes.link}
      onClick={(e) => {
        e.preventDefault()
        if (handleClick) return !!handleClick(e)
      }}
    >
      {label}
    </a>
  )
}

export default BasicLink

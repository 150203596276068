import React, { ReactNode } from 'react'
import { FieldTitle } from 'react-admin'

export const standardInputStyles = {
  label: {
    minWidth: 100,
    maxWidth: 100,
    marginRight: 10,
  },
  field: {
    display: 'flex',
    minHeight: 45,
    alignItems: 'center',
    color: '#a2a0a0',
  },
  input: {
    minWidth: 256,
  },
}

export const StandardInput: React.FC<InputPropTypes> = ({ label, children }) => {
  return (
    <div style={standardInputStyles.field}>
      <span style={standardInputStyles.label}>
        <FieldTitle label={label} />
      </span>
      {children}
    </div>
  )
}

interface InputPropTypes {
  label: string
  children: ReactNode
}

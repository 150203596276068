// @ts-nocheck
import { Grid, TextField } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import Alert from 'elements/Alert'
import DependentInput from 'elements/input/DependentInput'
import { Button } from 'opensolar-ui'
import { FC, useCallback, useEffect } from 'react'
import { BooleanInput, NumberInput, TextInput, useInput, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { styles } from '../styles.js'
import PaymentsConfigurationSelection from './PaymentsConfigurationSelection.tsx'

type PropsType = {
  resource: string
  currencySymbol: string
}
const newMilestone = { title: '', description: '', deposit_percentage: '', deposit_min: '', deposit_max: '' }
export const defaultMilestones = [newMilestone, newMilestone]

const parseValue = (value: any) => {
  if (value === '') {
    return value
  } else {
    return parseFloat(value)
  }
}
const FirstRowNumberInput = ({ name, label, ...rest }: any) => {
  const form = useForm()
  const {
    input,
    meta: { error, touched, submitError },
  } = useInput({
    resource: 'payment_options',
    type: 'number',
    source: `payment_milestones.0.${name}`,
  })
  //the main deposit value outside of milestones
  const depositValue = parseValue(form.getState().values[`variable_${name}`])
  useEffect(() => {
    if (depositValue !== input.value) {
      input.onChange(depositValue)
    }
  }, [depositValue])
  const errorMessage = error || submitError
  return (
    <TextField
      type="number"
      value={depositValue}
      margin={'dense'}
      error={!!errorMessage}
      helperText={errorMessage}
      defaultValue={depositValue}
      label={label}
      InputLabelProps={{ shrink: !!depositValue }}
      disabled={true}
      // onChange={(event: React.FormEvent<HTMLInputElement>) => {
      //   const newValue = parseValue(event.target.value)
      //   input.onChange(newValue)
      //   form.change(`variable_${name}`, newValue)
      // }}
      {...rest}
    />
  )
}

const FirstRowCash: FC<RowPropsType> = ({ index, isEditable, currencySymbol }) => {
  return (
    <Grid key={'milestone' + index} container spacing={1} justifyContent={'space-between'} justify="space-between">
      <Grid item xs={6} lg={2}>
        <TextInput
          name={`payment_milestones.${index}.title`}
          label={`Milestone ${index + 1} Title`}
          fullWidth={true}
          disabled={!isEditable}
        />
      </Grid>
      <Grid item xs={6} lg={2}>
        <TextInput
          name={`payment_milestones.${index}.description`}
          label={`Milestone ${index + 1} Description`}
          fullWidth={true}
          disabled={!isEditable}
        />
      </Grid>
      <Grid item xs={6} lg={2}>
        <FirstRowNumberInput name={'deposit_percentage'} label={'Deposit (%)'} fullWidth={true} />
      </Grid>
      <Grid item xs={6} lg={2}>
        <FirstRowNumberInput name={'deposit_min'} label={`Deposit min (${currencySymbol})`} fullWidth={true} />
      </Grid>
      <Grid item xs={6} lg={2}>
        <FirstRowNumberInput name={'deposit_max'} label={`Deposit max (${currencySymbol})`} fullWidth={true} />
      </Grid>
    </Grid>
  )
}

const LastRow: FC<RowPropsType> = ({ index, isEditable, currencySymbol }) => {
  const translate = useTranslate()
  const form = useForm()
  const addNewMileStone = useCallback(() => {
    const currentValue = form.getState().values['payment_milestones']
    form.mutators.updateField('payment_milestones', [...currentValue, newMilestone])
  }, [])
  return (
    <Grid
      key={'milestone' + index}
      container
      spacing={1}
      alignItems={'center'}
      justifyContent={'space-between'}
      justify="space-between"
    >
      <Grid item xs={6} lg={2}>
        <TextInput
          name={`payment_milestones.${index}.title`}
          label={`Milestone ${index + 1} Title`}
          fullWidth={true}
          disabled={!isEditable}
        />
      </Grid>
      <Grid item xs={6} lg={2}>
        <TextInput
          name={`payment_milestones.${index}.description`}
          label={`Milestone ${index + 1} Description`}
          fullWidth={true}
          disabled={!isEditable}
        />
      </Grid>
      <Grid item xs={12} lg={7}>
        <div>
          <span>{translate('Remaining Balance')} </span>
          {index < 4 && (
            <>
              {translate('or')}{' '}
              <Button
                id="add-additional-milestone"
                disabled={!isEditable}
                size={'small'}
                variant="contained"
                onClick={addNewMileStone}
              >
                <span>{translate('Add Additional Milestone')}</span>
              </Button>
            </>
          )}
        </div>
      </Grid>
    </Grid>
  )
}

type RowPropsType = {
  index: number
  isEditable: boolean
  currencySymbol: string
}

const GeneralRow: FC<RowPropsType> = ({ index, isEditable, currencySymbol }) => {
  console.log('general row', isEditable)
  const translate = useTranslate()
  const form = useForm()
  const removeMileStoneByIndex = useCallback((index) => {
    const currentValue = form.getState().values['payment_milestones']
    form.mutators.updateField('payment_milestones', [...currentValue.slice(0, index), ...currentValue.slice(index + 1)])
  }, [])
  return (
    <>
      <Button
        style={{ marginLeft: 10 }}
        size={'small'}
        variant="contained"
        disabled={!isEditable}
        onClick={() => removeMileStoneByIndex(index)}
      >
        <span>{translate('Remove Milestone')}</span>
      </Button>
      <Grid key={'milestone' + index} container spacing={1} justifyContent={'space-between'} justify="space-between">
        <Grid item xs={6} lg={2}>
          <TextInput
            name={`payment_milestones.${index}.title`}
            label={`Milestone ${index + 1} Title`}
            fullWidth={true}
            disabled={!isEditable}
          />
        </Grid>
        <Grid item xs={6} lg={2}>
          <TextInput
            name={`payment_milestones.${index}.description`}
            label={`Milestone ${index + 1} Description`}
            fullWidth={true}
            disabled={!isEditable}
          />
        </Grid>
        <Grid item xs={6} lg={2}>
          <NumberInput
            name={`payment_milestones.${index}.deposit_percentage`}
            label={`Milestone ${index + 1} amount (%)`}
            fullWidth={true}
            disabled={!isEditable}
          />
        </Grid>
        <Grid item xs={6} lg={2}>
          <NumberInput
            name={`payment_milestones.${index}.deposit_min`}
            label={`Milestone ${index + 1} min (${currencySymbol})`}
            fullWidth={true}
            disabled={!isEditable}
          />
        </Grid>
        <Grid item xs={6} lg={2}>
          <NumberInput
            name={`payment_milestones.${index}.deposit_max`}
            label={`Milestone ${index + 1} max (${currencySymbol})`}
            fullWidth={true}
            disabled={!isEditable}
          />
        </Grid>
      </Grid>
    </>
  )
}

const PaymentMilestones: FC<PropsType> = ({ resource, currencySymbol }) => {
  const translate = useTranslate()
  const formState = useFormState()
  const isStaff: Boolean = useSelector(authSelectors.getIsStaff)
  const cashFlowIsActive = useSelector(orgSelectors.getCashFlowIsActive)
  const paymentMilestonesEnabled = !!formState.values['variable_payment_milestones_enabled']
  const isEditable =
    formState.values['variable_deposit_percentage'] ||
    formState.values['variable_deposit_min'] ||
    formState.values['variable_deposit_max'] ||
    isStaff
  const milestones = formState.values['payment_milestones']
  const isCash = formState.values['payment_type'] === 'cash'
  const isAdmin = useSelector(authSelectors.getIsAdmin)
  const hideLegacyMilestones =
    cashFlowIsActive ||
    formState.values.auto_applied_payments_configuration ||
    (formState.values.payments_configuration && isCash)

  return (
    <div style={paymentMilestonesEnabled ? styles.loanWrapperWithBoxShadow : styles.loanWrapper}>
      {formState.values.auto_applied_payments_configuration && (
        <div>
          <h2>Payment Milestones</h2>
          <Alert severity="info">
            <p>
              Your account is configured with an auto-applied CashFlow configuration. This payment option will
              automatically use this configuration to calculate milestones. If you'd like to use a different
              configuration for this payment option you can do so by selecting a configuration override below.
            </p>
            {isAdmin && (
              <p>
                Or if you'd like to update the configuration that is currently applied to this payment option you can{' '}
                <Link to="/cashflow/configure/milestones" target="_blank" rel="noopener noreferrer">
                  edit the configuration record named
                </Link>{' '}
                {formState.values.auto_applied_payments_configuration}
              </p>
            )}
          </Alert>
        </div>
      )}
      {!hideLegacyMilestones && (
        <div>
          <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', height: 50 }}>
            <BooleanInput
              resource={resource}
              formControlLabelStyle={styles.formControlLabelStyle}
              label="Payment Milestones"
              name={'variable_payment_milestones_enabled'}
              source={'variable_payment_milestones_enabled'}
            />
          </div>
          <DependentInput dependsOn="variable_payment_milestones_enabled" value={true}>
            <div style={{ position: 'relative' }}>
              {!isEditable && (
                <Alert severity="warning">{translate('Milestones can only be edited once the deposit is set')}</Alert>
              )}
              {isStaff && !isCash && (
                <Alert severity="warning">
                  For non-cash payment options we only let staff users add/edit payment milestones and these milestones
                  only impact contract placeholders. They have no impact on the proposal
                </Alert>
              )}
              {milestones?.map((milestone: any, index: number) => {
                const isFirstCash = index === 0 && isCash
                const isFirstAnyType = index === 0
                const isLast = milestones.length === index + 1
                return (
                  <div key={'milestone' + index}>
                    <b>
                      {isFirstAnyType ? `${translate('Deposit')} / ` : ''}
                      {translate('Milestone')} {index + 1}
                    </b>
                    {!isFirstCash && !isLast && (
                      <GeneralRow index={index} isEditable={isEditable} currencySymbol={currencySymbol} />
                    )}
                    {isFirstCash && (
                      <FirstRowCash index={index} isEditable={isEditable} currencySymbol={currencySymbol} />
                    )}
                    {isLast && <LastRow index={index} isEditable={isEditable} currencySymbol={currencySymbol} />}
                  </div>
                )
              })}

              {isCash && (
                <div style={{ marginTop: 20 }}>
                  <BooleanInput
                    resource={resource}
                    style={{ display: 'inline-block' }}
                    label={translate('Show Milestone table with Quotation table')}
                    name={'variable_show_payment_milestones_table'}
                    source={'variable_show_payment_milestones_table'}
                  />
                </div>
              )}
            </div>
          </DependentInput>
        </div>
      )}
      {isCash && hideLegacyMilestones && (
        <div style={{ marginTop: 20 }}>
          <BooleanInput
            resource={resource}
            style={{ display: 'inline-block' }}
            label={translate('Show Milestone table with Quotation table')}
            name={'variable_show_payment_milestones_table'}
            source={'variable_show_payment_milestones_table'}
          />
          <Alert severity="info">
            <p>
              {translate(
                'Milestone table will be shown in proposal below the quotation table when CashFlow payment milestone scheme is applied for this payment option. If no CashFlow payment milestones are being used, the milestone table will not appear.'
              )}
            </p>
          </Alert>
        </div>
      )}
      <PaymentsConfigurationSelection />
    </div>
  )
}

export default PaymentMilestones

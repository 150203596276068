export const validateTextField = (value: any) => {
  return null
}

export const validateSelectField = (value: any, choices: string[] | undefined) => {
  if (value) {
    if (typeof value !== 'string') {
      return 'not a valid choice'
    } else if (!choices) {
      return 'no choices'
    } else if (!choices.includes(value)) {
      return value + ' is not one of choices'
    }
  }
  return null
}

export const validateMultiSelectField = (value: any, choices: string[] | undefined) => {
  if (value) {
    if (typeof value !== 'string') {
      return 'not a valid choice'
    } else if (!choices) {
      return 'no choices'
    } else if (!choices.includes(value)) {
      if (value && value.length > 0) {
        let unmatchedField: string | undefined = undefined
        value.split(';').forEach((subValue) => {
          if (!choices.includes(subValue)) unmatchedField = subValue
        })
        if (unmatchedField) return `${unmatchedField} is not a valid choice`
      }
    }
  }
  return null
}

export const validateDateTimeField = (value: any) => {
  if (value) {
    if (isNaN(Date.parse(value))) {
      return 'not a valid date time value'
    }
  }
  return null
}

import AlertError from '@material-ui/icons/ErrorOutlineOutlined'
import ImportIcon from '@material-ui/icons/PlayForWorkOutlined'
import { Button } from 'opensolar-ui'
import React from 'react'
import { SaveButton, Toolbar, useTranslate } from 'react-admin'
import { Link } from 'react-router-dom'
const CreateEditToolbar = (props) => {
  const translate = useTranslate()
  // const createButtonClasses = makeStyles({
  //   label: {
  //     textTransform: 'capitalize',
  //   },
  // })()
  return (
    <Toolbar style={{ padding: 0 }} {...props}>
      {!props.hideCancelButton && (
        <Link style={{ textDecoration: 'none' }} to={`/${props.resource}`}>
          <Button
            variant="contained"
            color="default"
            // classes={createButtonClasses}
            startIcon={<AlertError />}
            style={{ margin: 10, position: 'relative' }}
            // backgroundColor="#D8D8D8"
          >
            <span>{translate('Cancel')}</span>
          </Button>
        </Link>
      )}
      <SaveButton
        //label="Save"
        redirect={false}
        submitOnEnter={true}
        raised={true}
        disabled={
          props.disabled || (props.record.org && props.record.org.indexOf('/orgs/' + props.org_id + '/') === -1)
        }
      />
      {props.record.org && props.record.org.indexOf('/orgs/' + props.org_id + '/') === -1 && (
        <Button
          startIcon={<ImportIcon />}
          // classes={createButtonClasses}
          redirect={false}
          submitOnEnter={false}
          raised={true}
          onClick={(event) =>
            props.duplicate({
              resource: props.resource,
              id: props.record.id,
            })
          }
        >
          <span>{translate('Import')}</span>
        </Button>
      )}
    </Toolbar>
  )
}
export default CreateEditToolbar

import {
  and,
  ArrayLayoutProps,
  composePaths,
  findUISchema,
  isObjectArray,
  JsonFormsUISchemaRegistryEntry,
  rankWith,
  uiTypeIs,
} from '@jsonforms/core'
import { JsonFormsDispatch, withJsonFormsArrayLayoutProps } from '@jsonforms/react'
import { Hidden, Tab, Tabs, Typography } from '@material-ui/core'
import map from 'lodash/map'
import range from 'lodash/range'
import React, { useMemo, useState } from 'react'

export const MaterialListWithDetailRenderer = ({
  uischemas,
  schema,
  uischema,
  path,
  errors,
  visible,
  label,
  required,
  removeItems,
  addItem,
  data,
  renderers,
  cells,
  config,
  rootSchema,
}: ArrayLayoutProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const onTabChange = (_event: any, value: any) => {
    setSelectedIndex(value)
  }
  const foundUISchema = useMemo(
    () =>
      findUISchema(
        uischemas as JsonFormsUISchemaRegistryEntry[],
        schema,
        uischema.scope,
        path,
        undefined,
        uischema,
        rootSchema
      ),
    [uischemas, schema, uischema.scope, path, uischema, rootSchema]
  )

  React.useEffect(() => {
    setSelectedIndex(0)
  }, [schema])

  return (
    <Hidden xsUp={!visible}>
      <Tabs
        value={selectedIndex}
        onChange={onTabChange}
        textColor="inherit"
        indicatorColor="secondary"
        variant="scrollable"
      >
        {map(range(data), (index) => {
          return <Tab key={index} label={label + ' ' + (index+1)} />
        })}
      </Tabs>
      {selectedIndex !== undefined && selectedIndex < data ? (
        <JsonFormsDispatch
          renderers={renderers}
          cells={cells}
          visible={visible}
          schema={schema}
          uischema={foundUISchema}
          path={composePaths(path, `${selectedIndex}`)}
        />
      ) : (
        <Typography variant="h6">No Selection</Typography>
      )}
    </Hidden>
  )
}

const tester = rankWith(4, and(uiTypeIs('ArrayTabs'), isObjectArray))

const renderer = withJsonFormsArrayLayoutProps(MaterialListWithDetailRenderer)

export default {
  renderer,
  tester,
}

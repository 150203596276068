import { useCallback, useMemo } from 'react'
import type { ComponentTypesV2 } from 'types/selectComponent'
import type {
  ConfigurableFilterOptionsType,
  FilterComponentNodeV2,
  FilterGenericPropsType,
  OptionGenericType,
} from '../typeV2'
import { parseStringifiedListFilterValue } from '../utils/utils'
import {
  RATING_FILTER_GTE_KEY,
  RATING_FILTER_LTE_KEY,
  RatingRangeType,
  getDefaultValue,
  getOptionValueByFilterValues,
  getRangeOptionByValue,
  getRangeOptionsByComponentType,
} from './constants'

export const RATING_FILTER_NODE_KEY_V2 = 'rating'

const defaultLabel = 'Rating'
const defaultOption = {
  title: 'All',
  value: '',
  id: 'default',
}

const mapRangeOptionsToSelectOptions = (rangeOptions: RatingRangeType[]): OptionGenericType<string>[] => {
  const selectOptions = [defaultOption]

  rangeOptions.forEach((range) => {
    selectOptions.push({ title: range.title, value: range.value, id: range.value })
  })

  return selectOptions
}

const createRatingFilterNode = <T,>({
  label = defaultLabel,
  rendererComponent: RendererComponent,
  rendererAdditionalProps,
  ...restConfigs
}: ConfigurableFilterOptionsType<string, string>): FilterComponentNodeV2 => {
  const FilterComponent = ({
    allFilterValues,
    modifiableFilterValues,
    setModifiableFilters,
    disabled,
  }: FilterGenericPropsType) => {
    const types = parseStringifiedListFilterValue<ComponentTypesV2>({
      filterValues: allFilterValues,
      key: 'types',
    })

    const rangeOptions = getRangeOptionsByComponentType(Array.from(types)[0])

    const onSelect = useCallback(
      (value) => {
        const matchedRangeOption = rangeOptions && getRangeOptionByValue(value, rangeOptions)
        const newFilterValues = {
          ...modifiableFilterValues,
          [RATING_FILTER_GTE_KEY]: matchedRangeOption?.min,
          [RATING_FILTER_LTE_KEY]: matchedRangeOption?.max,
        }
        setModifiableFilters(newFilterValues)
      },
      [modifiableFilterValues, rangeOptions]
    )

    const selectOptions = useMemo(() => {
      return rangeOptions && mapRangeOptionsToSelectOptions(rangeOptions)
    }, [rangeOptions])

    // Hide Rating filter for IDA - The combined rating filter should show instead
    const isForInverterDesignAssistant = !!allFilterValues['auto_string']

    if (!selectOptions || !rangeOptions || isForInverterDesignAssistant) {
      return null
    }

    return (
      <RendererComponent
        id={RATING_FILTER_NODE_KEY_V2}
        defaultValue={defaultOption.value}
        additionalProps={rendererAdditionalProps}
        label={label}
        options={selectOptions}
        disabled={!!disabled}
        onSelect={onSelect}
        value={getOptionValueByFilterValues({
          ranges: rangeOptions,
          gte: allFilterValues[RATING_FILTER_GTE_KEY],
          lte: allFilterValues[RATING_FILTER_LTE_KEY],
        })}
      />
    )
  }

  return {
    key: RATING_FILTER_NODE_KEY_V2,
    component: FilterComponent,
    getMatchedValueFromSpecsData: getDefaultValue,
  }
}

export default createRatingFilterNode

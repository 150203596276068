import { useContext, useMemo } from 'react'
import { PromosContextCore } from '../PromosContext'
import { InAppPromoType } from '../types'

export const usePromos = (type: InAppPromoType) => {
  const { state, promos: allPromos } = useContext(PromosContextCore)

  const promos = useMemo(() => {
    return allPromos.filter((promo) => {
      if (promo.bannerConfig) return type === 'banner'
      else if (promo.sponsoredContent) return type === 'sponsored_content'
      else return type === 'popup'
    })
  }, [allPromos])

  return { state, promos, allPromos }
}

import { countryMapping, otherCountry } from 'constants/countries'
import { CountryMapType } from 'pages/mfa/types'
import { useLocale, useTranslate } from 'ra-core'
import { useMemo } from 'react'

type TranslatedCountry = CountryMapType & {
  translatedName: string
}

interface TranslatedCountryWithPrefix extends TranslatedCountry {
  prefix: string
}

interface CountryLists {
  countriesMinimal: TranslatedCountry[]
  countriesAll: TranslatedCountry[]
  countriesWithPrefix: TranslatedCountryWithPrefix[]
  otherCountry: TranslatedCountry
}

export const useCountryListsTranslated = (): CountryLists => {
  const locale = useLocale()
  const translate = useTranslate()
  return useMemo(() => {
    const countriesAll = Object.keys(countryMapping)
      .map((iso2) => {
        const country = countryMapping[iso2]
        return { ...country, translatedName: translate(countryMapping[iso2].name) }
      })
      .sort((a, b) => a.translatedName.localeCompare(b.translatedName))

    return {
      countriesAll,
      countriesMinimal: countriesAll.filter((c) => c.mainRegion),
      countriesWithPrefix: countriesAll.filter((c): c is TranslatedCountryWithPrefix => c.prefix !== null),
      otherCountry: { ...otherCountry, translatedName: translate(otherCountry.name) },
    }
  }, [locale])
}

import { makeStyles, Paper } from '@material-ui/core'
import { ComponentVersions_2_1 } from 'constants/uxVersions'
import { orgSelectors } from 'ducks/orgs'
import { ControlBreadcrumbLink, ListBreadCrumbs } from 'elements/BreadCrumbs'
import Button from 'elements/button/Button'
import LoadingDots from 'layout/widgets/LoadingDots'
import { ComponentVersionsInherit } from 'opensolar-ui'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import React, { useEffect, useMemo, useState } from 'react'
import { useNotify, useRedirect, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import SharedItemsDisplay from 'resources/connectedOrgs/SharedItemsDisplay'
import restClient from 'restClient'
import { OpenSolarThemeType } from 'Themes'

const useStyles = makeStyles((theme: OpenSolarThemeType) => ({
  wrapper: {
    padding: 24,
    height: '100%',
  },
  card: {
    margin: '20px 0',
    padding: 20,
    border: theme.border.default,
  },
  heading: {
    ...theme.typography.h6,
    marginTop: 10,
    marginBottom: 30,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  row: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: 10,
  },
  fieldDisplay: {
    flexGrow: 1,
    marginRight: 'auto',
  },
  chip: {
    padding: '20px 10px',
    marginRight: 5,
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  manageButton: {
    minWidth: 300,
    maxWidth: '30%',
    [theme.breakpoints.down('md')]: {
      minWidth: 'unset',
    },
  },
}))

const SHAREABLE_ENTITIES = [
  { title: 'Project Actions', name: 'actions' },
  { title: 'Project Workflow', name: 'workflows' },
  { title: 'Pricing Schemes', name: 'pricing_schemes' },
  { title: 'Cost Schemes', name: 'costings' },
  { title: 'Adders', name: 'adders' },
  { title: 'Payment Options', name: 'payment_options' },
  { title: 'Modules', name: 'component_module_activations' },
  { title: 'Inverters', name: 'component_inverter_activations' },
  { title: 'Batteries', name: 'component_battery_activations' },
  { title: 'Other Components', name: 'component_other_activations' },
  { title: 'Setbacks & Design Settings', name: 'project_configurations' },
  { title: 'Battery Control Schemes', name: 'battery_schemes' },
  { title: 'Proposal Templates', name: 'proposal_templates' },
  { title: 'Contract Templates', name: 'contracts' },
  { title: 'Case Studies', name: 'testimonials' },
  { title: 'Incentives', name: 'incentives' },
  { title: 'Document Templates', name: 'document_templates' },
]

export type SharedItem = {
  title: string
  timestamp: string
}

type ShareableEntity = {
  title: string
  name: string
  sharedWith?: SharedItem[]
  sharedFrom?: SharedItem[]
}

interface PropTypes {
  match: {
    params: { id: number }
  }
}

const getSharedData = (data, connectedOrg, currOrg) => {
  if (!data)
    return {
      sharedFrom: [],
      sharedWith: [],
    }
  return {
    sharedFrom: data
      ?.filter((x) => (x.org ? x.org === connectedOrg?.org_url : x.org_id === connectedOrg?.org_id))
      .map((x) => {
        const itemTimeStamp = x.org_shared_time?.find((x) => x.to_org_name === currOrg?.name)?.shared_time
        return { title: x.title || x.code || x.name, timestamp: itemTimeStamp }
      }),
    sharedWith: data
      ?.filter((x) => (x.org ? x.org === currOrg?.url : x.org_id === currOrg?.id))
      .map((x) => {
        const itemTimeStamp = x.org_shared_time?.find((x) => x.to_org_name === connectedOrg?.org_name)?.shared_time
        return { title: x.title || x.code || x.name, timestamp: itemTimeStamp }
      }),
  }
}

const formEntityUrl = (entity, org_id, type) => {
  const urlType = type === 'shared_from' ? 'owned_by' : 'shared_with'
  return `/${entity}/?displayedFilters=%7B"${urlType}"%3Atrue%7D&filter=%7B"${urlType}"%3A${org_id}%7D`
}

const restClientInstance = restClient(window.API_ROOT + '/api')
const ShareSummary: React.FC<PropTypes> = (props) => {
  const notify = useNotify()
  const classes = useStyles()
  const redirect = useRedirect()
  const currOrg = useSelector(orgSelectors.getOrg)
  const currOrgId = currOrg?.id
  const connectedOrgId = useMemo(() => {
    return typeof props?.match?.params?.id === 'string' ? parseInt(props?.match?.params?.id) : props?.match?.params?.id
  }, [props?.match?.params?.id])
  const connectedOrgs = useSelector(orgSelectors.getConnectedOrgs)
  const connectedOrg = useMemo(() => {
    const connection = connectedOrgs?.find((x) => x.id === connectedOrgId)
    if (!connection?.is_active) {
      notify('Connection Disabled', 'warning')
      redirect('/connected_orgs')
    } else {
      return connection
    }
  }, [connectedOrgId, connectedOrgs])
  const [sharedData, setSharedData] = useState<ShareableEntity[]>([])
  const connectedOrgName = useMemo(() => connectedOrg?.org_name, [connectedOrg])

  useEffect(() => {
    if (connectedOrg && currOrgId) {
      const externalOrgId = connectedOrg.org_id
      let updatedList: ShareableEntity[] = []
      SHAREABLE_ENTITIES.forEach((entity) => {
        const url = `orgs/${currOrgId}/${entity.name}/?`
        restClientInstance('CUSTOM_GET', 'custom', {
          url: url + `owned_by=${currOrgId},${externalOrgId}&shared_with=${externalOrgId},${currOrgId}`,
        })
          .then((res) => {
            updatedList.push({
              ...entity,
              ...getSharedData(res.data, connectedOrg, currOrg),
            })
            if (updatedList.length === SHAREABLE_ENTITIES.length) {
              setSharedData(updatedList)
            }
          })
          .catch((error) => {
            console.error(error)
            notify('Error trying to retrieve shared entities', 'warning')
          })
      })
    }
  }, [currOrgId, connectedOrg])
  return (
    <div className={classes.wrapper}>
      <ListBreadCrumbs
        links={[
          ControlBreadcrumbLink,
          { title: 'Connected Orgs', link: '/connected_orgs' },
          { title: 'Shared Entities', link: '' },
        ]}
      />
      <Paper variant="outlined" classes={{ root: classes.card }}>
        {connectedOrgName && <h6 className={classes.heading}>{connectedOrgName || ''}</h6>}
        {sharedData?.length === SHAREABLE_ENTITIES?.length ? (
          <>
            {connectedOrg &&
              sharedData
                ?.sort((a, b) => {
                  const entityA = SHAREABLE_ENTITIES.find((y) => y.title === a.title) || SHAREABLE_ENTITIES[0]
                  const entityB = SHAREABLE_ENTITIES.find((y) => y.title === b.title) || SHAREABLE_ENTITIES[0]
                  return SHAREABLE_ENTITIES.indexOf(entityA) - SHAREABLE_ENTITIES.indexOf(entityB)
                })
                .map((entity, index) => {
                  return (
                    <SharedEntitySummary
                      key={index}
                      externalOrg={{ id: connectedOrg?.org_id, name: connectedOrgName }}
                      entity={entity}
                    />
                  )
                })}
          </>
        ) : (
          <div className={classes.loading}>
            <LoadingDots />
          </div>
        )}
      </Paper>
    </div>
  )
}

interface EntityPropTypes {
  entity: ShareableEntity
  externalOrg: {
    id: number
    name?: string
  }
}

const SharedEntitySummary: React.FC<EntityPropTypes> = ({ entity, externalOrg }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const entity_name = translate(entity.title)
  return (
    <ComponentVersionsInherit versions={ComponentVersions_2_1}>
      <AccordionCard
        defaultExpanded={!!entity.sharedWith?.length || !!entity.sharedFrom?.length}
        name={entity.name}
        title={translate(entity.title)}
        content={() => (
          <div className={classes.column}>
            <h1>{translate('Shared from %{org_name}', { org_name: externalOrg?.name })}</h1>
            <div className={classes.row}>
              <SharedItemsDisplay
                items={entity.sharedFrom || []}
                className={classes.fieldDisplay}
                label={translate('Shared %{entity_name}', { entity_name })}
                disabled
              />
              <Link to={formEntityUrl(entity.name, externalOrg?.id, 'shared_from')} className={classes.manageButton}>
                <Button
                  fullWidth={true}
                  translate="no"
                  label={translate('View %{entity_name}', { entity_name })}
                  variant="contained"
                  color="default"
                />
              </Link>
            </div>

            <h1>{translate('Shared with %{org_name}', { org_name: externalOrg?.name })}</h1>
            <div className={classes.row}>
              <SharedItemsDisplay
                items={entity.sharedWith || []}
                className={classes.fieldDisplay}
                label={translate('Shared %{entity_name}', { entity_name })}
                disabled
              />

              <Link to={formEntityUrl(entity.name, externalOrg?.id, 'shared_with')} className={classes.manageButton}>
                <Button
                  fullWidth={true}
                  translate="no"
                  label={translate('Manage %{entity_name}', { entity_name })}
                  variant="contained"
                  color="default"
                />
              </Link>
            </div>
          </div>
        )}
        titleIcon={() => <></>}
      />
    </ComponentVersionsInherit>
  )
}

export default ShareSummary

import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  container: {
    width: '100%',
    maxWidth: 970,
    margin: '0 auto',
    minHeight: '150px',
    padding: 20,
    boxSizing: 'border-box',
  },
  wrapper: {
    border: `1px solid ${theme.greyLight2}`,
    borderRadius: '8px',
  },
}))

const HardwareStandardBannerContainer = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>{children}</div>
    </div>
  )
}

export default HardwareStandardBannerContainer

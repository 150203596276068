import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import ClearIcon from '@material-ui/icons/ClearOutlined'
import SearchIcon from '@material-ui/icons/SearchOutlined'
import { IconButton } from 'opensolar-ui'
import { useCallback } from 'react'
import type { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  outlined: {
    borderRadius: 8,
  },
  textField: {
    width: '70%',
  },
  icon: {},
}))

const HardwareSelctorSearchBar = ({
  placeholder,
  optimisticValue,
  updateOptimisticValue,
  onChange,
}: {
  placeholder: string
  optimisticValue: string
  updateOptimisticValue(value: string): void
  onChange(search: string): void
}) => {
  const classes = useStyles()

  const updateSearchText = useCallback(
    (searchText: string) => {
      updateOptimisticValue(searchText)
      onChange(searchText)
    },
    [updateOptimisticValue, onChange]
  )

  const handleChange = useCallback(
    (e) => {
      const searchText = e.target.value || ''
      updateSearchText(searchText)
    },
    [updateSearchText]
  )

  return (
    <TextField
      variant="outlined"
      fullWidth={true}
      className={classes.textField}
      size="small"
      value={optimisticValue}
      onFocus={() => {
        try {
          window.editor.signals.typingInField.dispatch(true)
        } catch (err) {}
      }}
      onBlur={() => {
        try {
          window.editor.signals.typingInField.dispatch(false)
        } catch (err) {}
      }}
      onKeyPress={(e) => {
        if (e.key === 'Enter') e.preventDefault()
      }}
      onChange={handleChange}
      placeholder={placeholder}
      InputProps={{
        classes: { root: classes.outlined },
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon classes={{ root: classes.icon }} />
          </InputAdornment>
        ),
        endAdornment: optimisticValue && (
          <InputAdornment position="end">
            <IconButton
              size="small"
              onClick={(e) => {
                updateSearchText('')
              }}
            >
              <ClearIcon classes={{ root: classes.icon }} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default HardwareSelctorSearchBar

import { authSelectors } from 'ducks/auth'
import { AvatarIcon, Button, ButtonProps, styled } from 'opensolar-ui'

import { forwardRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { OrgType } from 'types/orgs'

const ACCOUNT_IMAGE_SIZE = 20

const UserImg = styled('img')({
  borderRadius: '50%',
  height: ACCOUNT_IMAGE_SIZE,
  width: ACCOUNT_IMAGE_SIZE,
})
const AccountButton = styled(Button)(() => ({
  minWidth: 0,
  gap: 12,
  '& img': {
    height: ACCOUNT_IMAGE_SIZE,
  },
  '& .OSUI-Button-endIcon': {
    margin: 0,
  },
}))

interface NavigationAccountButtonProps extends ButtonProps {
  logoPublicUrl: OrgType['logo_public_url']
}

const NavigationAccountButton = forwardRef<HTMLButtonElement, NavigationAccountButtonProps>(
  ({ logoPublicUrl, ...buttonProps }, ref) => {
    const [hasOrgImageError, setHasOrgImageError] = useState(false)
    const [hasUserImageError, setHasUserImageError] = useState(false)
    const currentRole = useSelector(authSelectors.getCurrentRole)

    return (
      <AccountButton
        color="secondary"
        endIcon={
          currentRole && !hasUserImageError ? (
            <UserImg
              alt="nav-user-logo"
              onError={() => setHasUserImageError(true)}
              src={currentRole.portrait_image_public_url || ''}
            />
          ) : (
            <AvatarIcon height="20" width="20" />
          )
        }
        ref={ref}
        variant="outlined"
        {...buttonProps}
      >
        {logoPublicUrl && !hasOrgImageError && (
          <img alt="nav-org-logo" onError={() => setHasOrgImageError(true)} src={logoPublicUrl.toString()} />
        )}
      </AccountButton>
    )
  }
)

export default NavigationAccountButton

import ChipsInput from 'elements/input/ChipsInput'
import { ReferenceArrayInput, useTranslate } from 'react-admin'

const FavouriteRoofTypesInput = () => {
  const translate = useTranslate()
  return (
    <div>
      <h2>{translate('Favourite Roof Types')}</h2>
      <div>
        {translate(
          'Set your roof types and it will limit the options that appear for selection when defining a roof type for a project, or in the cost list, to the ones that you have chosen here.'
        )}
      </div>
      {/*@ts-ignore*/}
      <ReferenceArrayInput
        resource="orgs"
        name="fav_roof_types"
        source="fav_roof_types"
        reference="roof_types"
        optionValueField="url"
        label="Favourite Roof Types"
        renderChildrenBeforeRecord={true}
      >
        <ChipsInput
          fullWidth={true}
          margin="dense"
          source="name"
          optionText={(o) => translate(o?.name)}
          optionValue="url"
          useSmallChip={true}
          showSpinnerWhenRefreshing={true}
        />
      </ReferenceArrayInput>
    </div>
  )
}

export default FavouriteRoofTypesInput

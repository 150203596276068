import { Theme, makeStyles, useMediaQuery } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import { Button, Grid } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'
import { Link } from 'react-router-dom'

type PropTypes = {}

const useStyles = makeStyles<OpenSolarThemeType, { isMobile: boolean }>((theme) => ({
  banner: {
    display: 'flex',
    padding: ({ isMobile }) => (isMobile ? '20px 0' : '10px 40px'),
    background:
      'radial-gradient(circle, rgba(98,99,105,1) 0%, rgba(109,110,115,1) 13%, rgba(98,99,105,1) 36%, rgba(68,70,77,1) 55%)',
    color: theme.white,
    justifyContent: 'center',
  },
  leftColumn: {
    display: 'flex',
    alignItems: 'center',
    textAlign: ({ isMobile }) => (isMobile ? 'center' : 'unset'),
  },
  leftHeader: {
    fontSize: 22,
    fontWeight: 300,
    marginBottom: 5,
    marginTop: 0,
  },
  leftSubtext: {
    fontWeight: 300,
    margin: 0,
  },
  cashflowWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: ({ isMobile }) => (isMobile ? '10px 0' : 'unset'),
  },
  imgWrapper: {
    position: 'relative',
    left: ({ isMobile }) => (isMobile ? '-30px' : '-50px'),
    width: ({ isMobile }) => (isMobile ? '100%' : '145%'),
  },
  header: {
    fontSize: 30,
    fontWeight: 500,
    position: 'absolute',
  },
  rightColumn: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  blueBtn: {
    backgroundColor: '#4272DD !important',
    color: 'white !important',
  },
}))

const CashFlowBannerAd: React.FC<PropTypes> = (props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const classes = useStyles({ isMobile })
  const translate = useTranslate()

  return (
    <Grid container spacing={1} className={classes.banner}>
      <Grid item sm={12} md={5} className={classes.leftColumn}>
        <div>
          <h2 className={classes.leftHeader}>{translate('Effortless cash collection is here')}</h2>
          <p className={classes.leftSubtext}>{translate('Improve your CashFlow today')}</p>
        </div>
      </Grid>
      <Grid item sm={12} md={3} className={classes.cashflowWrapper}>
        <img src={`${window.PUBLIC_URL}/images/cashflow/cashflow-banner.svg`} className={classes.imgWrapper} />
        <h1 className={classes.header}>CashFlow</h1>
      </Grid>
      <Grid item sm={12} md={4} className={classes.rightColumn}>
        <Link to="/cashflow" target="_blank" rel="noopener noreferrer">
          <Button className={classes.blueBtn}>{translate('Activate now')}</Button>
        </Link>
      </Grid>
    </Grid>
  )
}
export default CashFlowBannerAd

import { ActionDataType } from 'myenergy/selectionComponent/loanApplicationButton/types'
import restClient from 'restClient'

const restClientInstance = restClient(window.API_ROOT + '/api')

export const getEnvelopeId = (
  transactionRequestData: ActionDataType,
  paymentOptionId: number,
  contractTemplateId: number | undefined
) => {
  return restClientInstance('CUSTOM_GET', 'custom', {
    url:
      'orgs/' +
      transactionRequestData?.org_id +
      '/projects/' +
      transactionRequestData?.project_id +
      '/systems/' +
      transactionRequestData?.system_uuid +
      '/' +
      paymentOptionId +
      `/contract_docusign_envelope_id/?document_template_id=${contractTemplateId}`,
  })
}

export const getEnvelopeURL = (
  transactionRequestData: ActionDataType,
  paymentOptionId: number,
  docusignEnvelopeId: string | undefined,
  selectedDocusignCustomer: string | undefined,
  contractTemplateId: number | undefined
) => {
  let params = `?envelope_id=${docusignEnvelopeId}&document_template_id=${contractTemplateId}`
  if (selectedDocusignCustomer) {
    params += `&customer_index=${selectedDocusignCustomer}`
  }
  return restClientInstance('CUSTOM_GET', 'custom', {
    url:
      'orgs/' +
      transactionRequestData?.org_id +
      '/projects/' +
      transactionRequestData?.project_id +
      '/systems/' +
      transactionRequestData?.system_uuid +
      '/' +
      paymentOptionId +
      `/contract_docusign_envelope_url/${params}`,
  })
}

export const getCompletedRecipients = (transactionRequestData: ActionDataType) => {
  return restClientInstance('CUSTOM_GET', 'custom', {
    url:
      'orgs/' +
      transactionRequestData?.org_id +
      '/projects/' +
      transactionRequestData?.project_id +
      '/systems/' +
      transactionRequestData?.system_uuid +
      '/' +
      transactionRequestData?.paymentOptionData?.id +
      '/contract_docusign/completed_recipients/',
  })
}

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import React from 'react'
import type { EditNotesType } from '../../type'

const InventoryTableEditNote = ({
  state,
  handleChange,
}: {
  state: EditNotesType
  handleChange(arg: { field: string; value: string }): void
}) => {
  return (
    <Grid container justify="flex-start">
      <TextField
        size="small"
        multiline
        fullWidth
        value={state['note']}
        onChange={(e) => handleChange({ field: 'note', value: e.target.value })}
        placeholder="Enter a note"
        variant="standard"
      />
    </Grid>
  )
}

export default InventoryTableEditNote

export const CALCULATOR_OVERRIDE_MAP = {
  2: 'OpenSolar',
} as const

export const DEFAULT_CALCULATOR_OVERRIDE = 1

export const CALCULATOR_OVERRIDES = {
  DEFAULT: 1,
  OPEN_SOLAR: 2,
} as const

export const BATTERY_CONTROL_SCHEME_NAMES = {
  nz_optimized: 'NZ Optimized',
  solar_charged: 'Self-Consumption',
  minimize_grid_costs_tou_no_grid_charging: 'Minimize Grid Import Cost',
  maximize_savings: 'Maximize Savings',
  peak_demand_shaving: 'Peak Demand Shaving',
} as const

export const CALCULATOR_MESSAGES = {
  MCS_BATTERY_SCHEME_MODIFIED: {
    heading: 'MCS Compliance',
    messages: [
      'You may wish to override your self-consumption calculator below, if you wish to use a Battery Control Scheme',
    ] as string[],
  },
  OPENSOLAR_OVERRIDE_WHEN_WITHIN_MCS_BOUNDS: {
    heading: 'MCS Override',
    messages: [
      'This system would usually use MCS (MGD 003) for self-consumption estimates, however you have chosen to override MCS and instead use OpenSolar’s self-consumption calculator.',
    ] as string[],
  },
  recommendation: {
    subHeading: 'Recommendation',
    messages: [
      'You must ensure that you also present the customer with a compliant MCS design in order to fully comply with MCS (MIS 3002) guidelines.',
    ] as string[],
  },
  OPENSOLAR_BATTERY_OVERRIDE: (scheme: string) =>
    `The ${scheme} battery control scheme, has also been applied - as selected in the battery tab.`,
}

export const CALCULATOR_CONFIGS = {
  SAM: {
    calculatorName: 'System Advisor Model',
    yieldCalculator: 'SAM (2020.2.29.r2)',
    selfConsumptionCalculator: 'OpenSolar',
  },
  MCS: {
    calculatorName: 'MCS (Auto)',
    yieldCalculator: 'MCS (MIS 3002)',
    selfConsumptionCalculator: 'MCS (MGD 003)',
  },
  MCS_OUT_OF_BOUNDS: {
    calculatorName: 'MCS (Auto)',
    yieldCalculator: 'MCS (MIS 3002)',
    selfConsumptionCalculator: 'OpenSolar',
  },
  OPENSOLAR_OVERRIDE: {
    calculatorName: 'MCS (With Override)',
    yieldCalculator: 'MCS (MIS 3002)',
    selfConsumptionCalculator: 'OpenSolar',
  },
}

import { orgSelectors } from 'ducks/orgs'
import { paymentOptionSelectionSelectors } from 'ducks/paymentOptionSelection'
import { Grid } from 'opensolar-ui'
import { useGetCurrentAverageBill, useGetMonthlyBillSavings, useSystem } from 'projectSections/sections/payments/hooks'
import { useNotify, useTranslate } from 'ra-core'
import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { RootState } from 'types/state'
import { formatCurrencyWithSymbol, systemNameOrDefault } from 'util/misc'
import useTranslateParse from 'util/useTranslateParse'
import LightReachAdderConfig from './LightReachAdderConfig'
import LightReachPricingBands from './LightReachPricingBands'

type PropTypes = {
  systemUuid: string
  paymentOptionId?: number | null | undefined
  selectedPricing: string
  setSelectedPricing: (string) => void
}

const useStyles = makeOpenSolarStyles((theme) => ({
  container: {
    lineHeight: 1.5,
  },
  systemSummary: {
    background: theme.greyLight3,
    lineHeight: 0.75,
    borderRadius: 10,
    padding: '5px 10px',
  },
}))

const LightReachPricing: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const translateParse = useTranslateParse()
  const notify = useNotify()

  const system = useSystem(props.systemUuid)
  const estimatedUtilitySavings = useGetMonthlyBillSavings(system)
  const currentAverageBill = useGetCurrentAverageBill(system)
  const currencySymbol = useSelector(orgSelectors.getCurrencySymbolFromOrg)
  const isCalculating = useSelector((state: RootState) => {
    return paymentOptionSelectionSelectors.getIsSystemCalculating(state, props.systemUuid)
  })
  const refreshTrigger = useSelector(paymentOptionSelectionSelectors.getSystemRefreshTrigger)
  let overrides = system?.payment_options_settings_overrides || {}

  const lightReachPaymentOptions = useMemo(() => {
    if (props.systemUuid) {
      return system?.payment_options?.filter((pmt) => {
        if (props.paymentOptionId) {
          return pmt.integration === 'lightreach' && pmt.payment_option_id === props.paymentOptionId
        }
        if (pmt.integration === 'lightreach') {
          // Filter out options whose id is present in overrides if paymentOptionId is undefined
          if (props.paymentOptionId === undefined) {
            return !overrides.hasOwnProperty(pmt.id.toString())
          }
          return true
        }
        return false
      })
    }
  }, [props.systemUuid, props.paymentOptionId, overrides, isCalculating, refreshTrigger, system?.payment_options])

  useEffect(() => {
    if (props.selectedPricing && lightReachPaymentOptions?.length) {
      let matchingOption = lightReachPaymentOptions[0]?.pricing_options?.find(
        (opt) => opt.productId === props.selectedPricing
      )
      if (!matchingOption) {
        notify('Your previously selected pricing band is no longer available. Please select another', 'info')
        props.setSelectedPricing(undefined)
      }
    }
  }, [props.selectedPricing, lightReachPaymentOptions])

  if (!system) return null
  return (
    <div className={classes.container}>
      {lightReachPaymentOptions?.map((pmt) => (
        <>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <span>
                {translateParse(
                  'Set the pricing for the <strong> %{title} <strong> payment option you want to add to the Proposal. Note that these price adjustments will affect the available Lightreach pricing, but will not impact system pricing set in OpenSolar.',
                  {
                    strong: (title: string) => <strong>{pmt.title}</strong>,
                  }
                )}
              </span>
            </Grid>
            <Grid item xs={5}>
              <div className={classes.systemSummary}>
                <p>
                  <strong>{translate('System Summary')}</strong>
                </p>
                <p> {systemNameOrDefault(system.userData)}</p>
                <p>
                  {translate('Estimated Utility Savings:')}{' '}
                  {formatCurrencyWithSymbol(estimatedUtilitySavings, currencySymbol)}{' '}
                </p>
                <p>
                  {translate('Current Average Bill: %{amount}/month', {
                    amount: formatCurrencyWithSymbol(currentAverageBill, currencySymbol),
                  })}
                </p>
                <p>
                  {translate('Cash Purchase Price: ')}
                  {formatCurrencyWithSymbol(system.pricing.system_price_including_tax, currencySymbol)}
                </p>
              </div>
            </Grid>
          </Grid>
          <div>
            <LightReachAdderConfig systemUuid={props.systemUuid} />
          </div>
          <div>
            <LightReachPricingBands
              {...props}
              lightReachPaymentOptions={pmt}
              estimatedUtilitySavings={estimatedUtilitySavings}
            />
          </div>
        </>
      ))}
    </div>
  )
}
export default LightReachPricing

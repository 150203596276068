// in src/users.js
import { List } from 'elements/react-admin/List'
import React from 'react'
import {
  ChipField,
  Datagrid,
  FilterComp,
  FunctionField,
  ReferenceField,
  ReferenceInput,
  RefreshButton,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin'
import { Link } from 'react-router-dom'
import { styles as standardStyles } from 'styles'
import { urlToId } from 'util/misc'
import DefaultActions from '../../elements/DefaultActions'
import EditOrImportButton from '../../elements/button/EditOrImportButton'
export const CsvFileUploadRequestFilter = (props) => (
  <FilterComp {...props}>
    <TextInput label="pos.search" style={standardStyles.FILTER_FIELD_STYLE} source="q" />
    <ReferenceInput source="role_id" reference="roles">
      <SelectInput
        source="email"
        options={{ style: standardStyles.FILTER_FIELD_STYLE }}
        sortChoices={true}
        optionText="display"
        optionValue="id"
      />
    </ReferenceInput>
  </FilterComp>
)

export const CsvFileUploadRequestList = (props) => (
  <List
    title="CSV File Uploads"
    hasSearch={true}
    filters={<CsvFileUploadRequestFilter />}
    actions={
      <DefaultActions
        otherActions={[<RefreshButton style={{ marginLeft: 5 }} variant="contained" color="default" size="default" />]}
      />
    }
    {...props}
  >
    <Datagrid>
      <TextField source="filename" />
      <TextField source="type" />
      <TextField source="rows" />
      <FunctionField
        label="Progress"
        source="percentage_complete"
        render={(record) => record.percentage_complete + '%'}
      />
      <FunctionField
        label="Warnings"
        source="warnings"
        render={(record) => (
          <Link to={'/csv_file_upload_logs?filter=%7B%22csv_file_upload_request%22%3A' + record.id + '%7D'}>
            {record.warnings}
          </Link>
        )}
      />
      <TextField source="created_date" />
      <ReferenceField
        label="Role"
        source="role"
        reference="roles"
        getResourceLinkPath={({ record }) => record && record.role && `/roles/${urlToId(record.role)}`}
      >
        <ChipField source="display" />
      </ReferenceField>
      <EditOrImportButton source="actions" allowEdit={false} allowDuplicate={false} sortable={false} />
    </Datagrid>
  </List>
)

import { Card, makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'

import { updateCourseData, updateShowLearn } from 'ducks/learning'
import BannerAd from 'elements/BannerAd'
import UiContainer from 'elements/UiContainer'
import FullScreenLoading from 'layout/widgets/FullScreenLoading'
import { loadAcademyData } from 'pages/home/ActionButtons'
import { FC, memo, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getRoleFromState } from 'util/misc'
import { getOrgFromState } from 'util/org'
import DashboardAssignedActivities from './AssignedActivities'
import DashboardClientActivities from './ClientActivities'
import DashboardCompanyStats from './CompanyStats'
import DashboardLearn from './LearnBlock'
import DashboardWelcomeBlock from './WelcomeBlock'
type PageDashboardType = {
  org?: any
  updateCourseData: (value: any) => void
  updateShowLearn: (value: boolean) => void
  showLearn?: boolean
  courseData?: any
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    maxWidth: 1100,
    margin: '0 auto',
    color: '#4d4d4d',
    boxSizing: 'border-box',
    padding: '50px 20px 0',
  },
  card: {
    marginBottom: 50,
    padding: 20,
    borderWidth: 2,
    borderColor: '#e7e7e7',
    boxSizing: 'border-box',
  },
}))

const PageDashboard: FC<PageDashboardType> = memo(
  ({ org, updateCourseData, updateShowLearn, showLearn, courseData, ...props }) => {
    const classes = useStyles()
    const isAdmin = useSelector((state: any) => getRoleFromState(state)?.is_admin === true)

    const [isLearn, setIsLearn] = useState(showLearn ? showLearn : false)
    const [isLoading, setIsLoading] = useState(true)
    const uiKey = 'control.dashboard'

    useEffect(() => {
      loadAcademyData(org?.country?.iso2, courseData, setIsLearn, setIsLoading, updateCourseData, updateShowLearn)
    }, [org?.id])

    return (
      <UiContainer uiKey={uiKey}>
        {isLoading ? (
          <FullScreenLoading />
        ) : (
          <div className={classes.wrapper}>
            <BannerAd
              formats={['leaderboard']}
              placement="control_top"
              containerStyle={{ marginTop: -20, marginBottom: 20 }}
              requiredWindowSize={{ width: 1040 }}
            />
            <Card variant="outlined" classes={{ root: classes.card }}>
              <DashboardWelcomeBlock />
            </Card>
            {isLearn && (
              <Card variant="outlined" classes={{ root: classes.card }}>
                <DashboardLearn />
              </Card>
            )}
            {isAdmin && (
              <Card variant="outlined" classes={{ root: classes.card }}>
                <DashboardCompanyStats />
              </Card>
            )}
            <Card variant="outlined" classes={{ root: classes.card }}>
              <DashboardAssignedActivities />
            </Card>
            <Card variant="outlined" classes={{ root: classes.card }}>
              <DashboardClientActivities />
            </Card>
            <BannerAd
              formats={['leaderboard']}
              placement="control_bottom"
              containerStyle={{ marginTop: -20 }}
              requiredWindowSize={{ width: 1040 }}
            />
          </div>
        )}
      </UiContainer>
    )
  }
)

const mapStateToProps = (state: any) => {
  return {
    org: getOrgFromState(state),
    showLearn: state.learning.showLearn,
    courseData: state.learning.courseData,
  }
}

const mapDispatchToProps = {
  updateShowLearn: updateShowLearn,
  updateCourseData: updateCourseData,
}

export default connect(mapStateToProps, mapDispatchToProps)(PageDashboard)

import type { OpenSolarThemeType } from 'Themes'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  container: {
    width: '100%',
    padding: 20,
    boxSizing: 'border-box',
  },
  contentWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  main: {
    flexGrow: 1, // auto fill rest space
    margin: '10px',
  },
  actions: {
    margin: '10px',
    width: 300,
    flexShrink: 0,
  },
  title: { margin: 10 },
}))

const PageLayout = ({
  title,
  mainContent,
  sideAction,
}: {
  title: React.ReactNode
  mainContent: React.ReactNode
  sideAction: React.ReactNode
}) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.title}>{title}</div>
      <div className={classes.contentWrapper}>
        <div className={classes.main}>{mainContent}</div>
        <div className={classes.actions}>{sideAction}</div>
      </div>
    </div>
  )
}

export default PageLayout

import React, { useCallback, useLayoutEffect, useState } from 'react'
import { OpenSolarAuthFlowLogo } from './AuthFlowLogo'
import { OPENSOLAR_LOGO_URL_HALF } from './Configs'
import PartnershipLogo from './PartnershipLogo'

const getLandingPageLogo = (urlFragment) =>
  new Promise((resolve) => {
    const baseUrl = window.API_BASE_URL.replace('/api/', '')

    fetch(`${baseUrl}/api/landing_page/${urlFragment}`).then((response) => {
      if (response.ok) {
        return response.json().then((data) => {
          resolve(data.logo_file_contents)
        })
      } else if (response.status === 404) {
        resolve(undefined)
      }
    })
  })

const LazyLoadLandingPageLogo = ({ urlFragment }) => {
  const [initialising, setInitialising] = useState(true)
  const [landingPageLogo, setLandingPageLogo] = useState<unknown>(undefined)

  const init = useCallback(async () => {
    const landingPageLogo = await getLandingPageLogo(urlFragment)
    setLandingPageLogo(landingPageLogo)
    setInitialising(false)
  }, [])

  useLayoutEffect(() => {
    init()
  }, [])

  if (initialising) {
    return null
  }

  if (initialising === false && landingPageLogo === undefined) {
    return <OpenSolarAuthFlowLogo />
  }

  return <PartnershipLogo firstLogoUrl={landingPageLogo} secondLogoUrl={OPENSOLAR_LOGO_URL_HALF} />
}

export default LazyLoadLandingPageLogo

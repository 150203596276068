const formatChangeOrderValues = (submitValues: any, formValues: any, dirtyFields: any[]) => {
  const changeOrders = formValues.change_orders
  if ('change_orders' in submitValues) {
    submitValues.change_orders = submitValues.change_orders
      .map((e, i) => {
        if (e === null) return null
        return changeOrders[i]
      })
      .filter((e: any) => e !== null)
  }
  delete submitValues.initiated_change_order

  return submitValues
}

export default formatChangeOrderValues

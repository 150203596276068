import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  modal: {
    borderRadius: '8px',
  },
  title: {
    borderBottom: '1px solid rgba(230, 230, 230, 1)',
  },
}))

interface ModalProps {
  isOpen: boolean
  header: React.ReactNode
  body: React.ReactNode
  footer: React.ReactNode
  className?: string
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

const Modal: React.FC<ModalProps> = ({ isOpen, header, body, footer, className, maxWidth = 'md', ...rest }) => {
  const classes = useStyles()
  if (!isOpen) {
    return null
  }
  return (
    <Dialog open={isOpen} maxWidth={maxWidth} PaperProps={{ className: `${classes.modal} ${className}` }} {...rest}>
      <DialogTitle className={classes.title}>{header}</DialogTitle>
      <DialogContent>{body}</DialogContent>
      <DialogActions>{footer}</DialogActions>
    </Dialog>
  )
}

export default Modal

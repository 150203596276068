import { Tooltip } from '@material-ui/core'
import { Info } from '@material-ui/icons'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  infoIcon: {
    fontSize: 18,
    color: theme.greyMid1,
  },
}))

const SurchargingTooltip: React.FC = () => {
  const classes = useStyles()
  return (
    <Tooltip
      title="When toggled on, OpenSolar will automatically calculate compliant surcharges to include in customers' credit card purchases. Note that surcharging will only apply for credit card purchases."
      enterDelay={300}
      className={classes.infoIcon}
    >
      <Info />
    </Tooltip>
  )
}
export default SurchargingTooltip

// @ts-nocheck
import { Grid } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { authSelectors } from 'ducks/auth'
import { updateRoleSungageEnabled } from 'ducks/auth_roles'
import { orgSelectors } from 'ducks/orgs'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import { useGetConnectedOrgBeingUsed } from 'projectSections/sections/payments/utils'
import React, { useMemo, useState } from 'react'
import { useNotify } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'
import { ProjectType } from 'types/projects'
import { RoleType } from 'types/roles'
import { getIsValidEmail } from 'util/misc'

let restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  project: ProjectType | undefined
  onClose: () => void
  onSuccess: () => void
  pendingPmtOrgId: number | undefined
  roleToShow?: RoleType | undefined
}

const SungageConnectRoleDialogContent: React.FunctionComponent<PropTypes> = (props) => {
  const dispatch = useDispatch()
  const notify = useNotify()
  const role: RoleType = useSelector(authSelectors.getCurrentRole)
  const org = useSelector(orgSelectors.getOrg)
  const user = useSelector(authSelectors.getCurrentUser)

  const orgId = props.pendingPmtOrgId ? props.pendingPmtOrgId : props.project?.org_id
  const connectedOrgBeingUsed = useGetConnectedOrgBeingUsed(org, orgId)

  //for creating new users
  const [sungageEmail, setSungageEmail] = useState<string | undefined>(props.roleToShow?.email || user?.email)
  const [saving, setSaving] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)

  const onSubmit = () => {
    if (!sungageEmail) {
      setErrorMsg('Email is required')
      return
    } else if (!getIsValidEmail(sungageEmail)) {
      setErrorMsg('Invalid email')
      return
    }
    setSaving(true)
    const orgId = props.pendingPmtOrgId ? props.pendingPmtOrgId : props.project?.org_id
    const roleId = props.roleToShow?.id || role.id
    const url = 'orgs/' + orgId + '/roles/' + roleId + '/sungage/connect_role/'

    restClientInstance('CUSTOM_PUT', 'custom', {
      url: url,
      data: {
        email: sungageEmail,
      },
    })
      .then((res) => {
        console.log('res connect', res)
        if (res.data.success) {
          dispatch(updateRoleSungageEnabled(true, roleId))
          notify('Your Sungage account has been connected!', 'success')
          props.onSuccess()
          props.onClose()
        } else {
          setErrorMsg(
            `Unfortunately, the email address you entered, ${sungageEmail}, does not match any of the emails in your Sungage account.  Please verify the email address and enter the correct one below.`
          )
        }
      })
      .catch((err) => console.log('err', err))
      .finally(() => setSaving(false))
  }

  const descriptionText = useMemo(() => {
    if (props.roleToShow) {
      if (connectedOrgBeingUsed) {
        return `In order to sell Sungage on OpenSolar on behalf of ${connectedOrgBeingUsed.org_name}, please let us know the email address that ${props.roleToShow.display} uses to login to ${connectedOrgBeingUsed.org_name}'s Sungage account.`
      } else {
        return `In order to sell Sungage on OpenSolar, please let us know the email address that ${props.roleToShow.display} uses to login to your company's Sungage account.`
      }
    } else {
      if (connectedOrgBeingUsed) {
        return `In order to sell Sungage on behalf of ${connectedOrgBeingUsed.org_name}, please let us know the email address that you use to login login to ${connectedOrgBeingUsed.org_name}'s Sungage account.`
      } else {
        return "In order to sell Sungage on OpenSolar, please let us know the email address that you use to login to your company's Sungage account."
      }
    }
  }, [props.roleToShow, connectedOrgBeingUsed])

  return (
    <div style={{ width: '90%', height: '90%', padding: '5%' }}>
      <Grid item xs={12}>
        <div>{descriptionText}</div>
      </Grid>
      <Grid item xs={12} style={{ margin: '20px 0px' }}>
        <TextField
          value={sungageEmail}
          label={`Sungage User's Email ${connectedOrgBeingUsed ? `for ${connectedOrgBeingUsed.org_name}` : ''}`}
          onChange={(e) => setSungageEmail(e.target.value)}
          fullWidth
          style={{ width: '100%' }}
        />
      </Grid>
      {errorMsg && (
        <Grid item xs={12}>
          <p style={{ color: 'red', textAlign: 'center' }}>{errorMsg}</p>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        style={{ margin: '20px 0px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
      >
        <div style={{ margin: '0px 20px' }}>
          <ProUXButton type="secondary" label="Close" onClick={props.onClose} />
        </div>
        <div style={{ margin: '0px 20px' }}>
          <ProUXButton
            type="primary"
            label={props.roleToShow ? `Connect ${props.roleToShow.display}'s User` : 'Connect your User'}
            onClick={onSubmit}
            loading={saving}
          />
        </div>
      </Grid>
      <Grid item xs={12} className="small">
        <p>
          {props.roleToShow
            ? `If ${props.roleToShow.display} does not have their own Sungage username and password,  please have a manager create an account for them in the Sungage portal`
            : 'If you do not have your own Sungage username and password,  please have your manager create an account for you in the Sungage portal.'}
        </p>
      </Grid>
    </div>
  )
}
export default SungageConnectRoleDialogContent

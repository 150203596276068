import type { OpenSolarThemeType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import type { OrderType } from 'pages/ordering/type'
import React from 'react'
import { useTranslate } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
    display: 'block',
  },
}))
const OrderCell = ({ orders }: { orders?: OrderType[] }) => {
  const translate = useTranslate()
  const classes = useStyles()
  return (
    <>
      {orders?.length
        ? orders?.map((order) => (
            <a
              key={order.url}
              className={classes.link}
              onClick={(e) => {
                logAmplitudeEvent('hardware_placed_order_link_clicked', { source: 'project_table' })
                window.open(order.url, '_blank', 'noopener, noreferrer')
                e.preventDefault()
              }}
            >
              {translate('Order placed')}
            </a>
          ))
        : translate('Order not placed')}
    </>
  )
}

export default OrderCell

import { Theme, useMediaQuery } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import React, { FC, useContext } from 'react'
import { Button } from 'react-admin'
import { UtilityTariffDialogContext } from '..'
import { DialogHelper } from '../../../../../util/misc'

type PropTypes = {
  currentOrProposed: 'current' | 'proposed'
  context: string
}

const TariffDialogCreateButton: FC<PropTypes> = ({ currentOrProposed, context }) => {
  const { dispatch } = useContext(UtilityTariffDialogContext)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  return (
    <Button
      children={isMobile ? <Add /> : undefined}
      variant="outlined"
      label={'Create New Tariff'}
      onClick={() => {
        DialogHelper.beforeClose()
        dispatch({
          type: 'create',
          payload: {
            isOpen: true,
            variant: currentOrProposed,
            basePath: '/utility_tariffs',
            resource: 'utility_tariffs',
            mode: 'create',
          },
        })
        logAmplitudeEvent('create_tariff_clicked', {
          currentOrProposed,
          context,
        })
      }}
    />
  )
}

export default TariffDialogCreateButton

import { Theme, useMediaQuery } from '@material-ui/core'
import SelectableCard from 'elements/SelectableCard'
import LightbulbIcon from 'icons/LightbulbIcon'
import ListIcon from 'icons/ListIcon'
import { Box, Button } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useState } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import InverterDesignAssistantContainer from '../InverterDesignAssistantContainer'
import { INVERTER_MANUAL_SELECTOR_NODE_KEY, INVERTER_TYPE_SELECTION_NODE_KEY } from '../constants'
import {
  InverterDesignAssistantFlowNodeComponentStandardPropsType,
  InverterSelectionKeyType,
  InverterSelectionType,
} from '../types'

const useStyles = makeOpenSolarStyles(() => ({
  recommendedChip: {
    background: '#ECECED',
    borderRadius: '4px',
    color: '#4F4D55',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
    fontWeight: 500,
    gap: '10px',
    justifyContent: 'center',
    lineHeight: '16px',
    marginLeft: 'auto',
    padding: '2px 8px',
  },
}))

const INVERTER_SELECTIONS: InverterSelectionType[] = [
  {
    description:
      'The Auto-Inverter Selection feature is a smart design assistant that helps installers choose the optimal inverter bundles for their solar module configurations. It recommends the best-suited inverter options based on the module placements.',
    icon: <LightbulbIcon />,
    isRecommended: true,
    key: 'auto',
    title: 'Inverter Design Assistant',
  },
  {
    description:
      'Browse through our catalog of inverter options. Select the one that best aligns with your needs and preferences.',
    icon: <ListIcon />,
    key: 'manual',
    title: 'Manual Inverter Selection',
  },
]

interface InverterSelectionProps extends InverterDesignAssistantFlowNodeComponentStandardPropsType {}

const InverterSelectorModeNode: React.FC<InverterSelectionProps> = ({ flowController, flowStore }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const hasPanels = window.editor?.selectedSystem.moduleGrids().length > 0
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const [selectedSelection, setSelectedSelection] = useState<InverterSelectionKeyType | undefined>(
    flowStore.selection ?? (hasPanels ? 'auto' : undefined)
  )

  const handleContinue = () => {
    if (selectedSelection === 'auto') {
      flowController.goTo({
        title: 'Inverters',
        currentNodeKey: INVERTER_TYPE_SELECTION_NODE_KEY,
        options: {},
      })
    } else {
      flowController.goTo({
        title: 'Select Inverter',
        currentNodeKey: INVERTER_MANUAL_SELECTOR_NODE_KEY,
        options: {},
      })
    }
  }

  const handleSelect = (key: InverterSelectionKeyType) => {
    setSelectedSelection(key)
    flowController.setFlowStore((state) => ({
      ...state,
      selection: key,
    }))
  }

  return (
    <InverterDesignAssistantContainer title="What would you like to do?">
      <Box display="flex" gridGap={16} flexWrap={isSmallScreen ? 'wrap' : 'nowrap'}>
        {INVERTER_SELECTIONS.map(({ description, icon, isRecommended, key, title }) => {
          const shouldDisable = key === 'auto' && !hasPanels
          return (
            <SelectableCard
              cardKey={key}
              description={description}
              disabled={shouldDisable}
              gap={8}
              key={key}
              icon={icon}
              isSelected={selectedSelection === key}
              onSelect={(k) => handleSelect(k as InverterSelectionKeyType)}
              title={
                <Box alignItems="center" display="flex" gridGap={16}>
                  {title}
                  {isRecommended && <span className={classes.recommendedChip}>{translate('Recommended')}</span>}
                </Box>
              }
              warningText={shouldDisable ? 'Please add modules to the system to enable Inverter Design Assistant.' : ''}
            />
          )
        })}
      </Box>
      <Box display="flex" justifyContent="flex-end" marginTop="8px">
        <Button color="primary" disabled={!selectedSelection} variant="contained" onClick={handleContinue}>
          {translate('Continue')}
        </Button>
      </Box>
    </InverterDesignAssistantContainer>
  )
}

export default InverterSelectorModeNode

import { useForm, useFormState } from 'react-final-form'
import { useAutoSaveProjectEnabled } from './useAutoSaveProjectEnabled'

/**
 * Know whether Actions (Save and Discard) is shown.
 */

export const useShowProjectActions = () => {
  const form = useForm()
  useFormState() //hacky, but without adding a useFormState() here this cmponent does not reliably listen notice dirty fields if they're changed via form.change()
  const dirtyFields = form.mutators.getFormDirtyFields()
  const autoSaveEnabled = useAutoSaveProjectEnabled()
  return !(dirtyFields?.length === 0 || autoSaveEnabled)
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import createStandardIcon from './createStandardIcon';
var InverterIconSvg = function (props) {
    var color = props.color, fill = props.fill, stroke = props.stroke;
    return (_jsxs("svg", __assign({ viewBox: "0 0 28 28", xmlns: "http://www.w3.org/2000/svg" }, props, { children: [_jsx("path", { d: "M22.3558 5.01367L4.66602 22.7035L5.49098 23.5285L23.1809 5.83863L22.3558 5.01367Z", fill: fill }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.83333 3.5C4.54467 3.5 3.5 4.54467 3.5 5.83333V22.1667C3.5 23.4554 4.54467 24.5 5.83333 24.5H22.1667C23.4554 24.5 24.5 23.4554 24.5 22.1667V5.83333C24.5 4.54467 23.4554 3.5 22.1667 3.5H5.83333ZM23.3333 7C23.3333 5.71133 22.2887 4.66667 21 4.66667H7C5.71133 4.66667 4.66667 5.71133 4.66667 7V21C4.66667 22.2887 5.71133 23.3333 7 23.3333H21C22.2887 23.3333 23.3333 22.2887 23.3333 21V7Z", fill: fill }), _jsx("path", { d: "M17.6454 18.8988L16.3766 20.2735C16.2923 20.3648 16.1737 20.4167 16.0494 20.4167C15.6841 20.4167 15.4744 20.001 15.6914 19.7071L16.9787 17.9639C17.1942 17.6722 17.5353 17.5 17.8979 17.5C18.2169 17.5 18.5212 17.6332 18.7375 17.8676L19.7525 18.9672C19.8836 19.1092 20.114 19.0868 20.2152 18.9223L20.9507 17.7272C21.0376 17.586 21.1915 17.5 21.3573 17.5C21.7211 17.5 21.9511 17.8905 21.775 18.2088L20.8514 19.8761C20.6666 20.2097 20.3155 20.4167 19.9342 20.4167C19.6416 20.4167 19.3622 20.2944 19.1637 20.0794L18.0741 18.8988C17.9586 18.7738 17.7609 18.7738 17.6454 18.8988Z", fill: fill }), _jsx("path", { d: "M11.0833 7H7.58333C7.26117 7 7 7.26117 7 7.58333C7 7.9055 7.26117 8.16667 7.58333 8.16667H11.0833C11.4055 8.16667 11.6667 7.9055 11.6667 7.58333C11.6667 7.26117 11.4055 7 11.0833 7Z", fill: fill }), _jsx("path", { d: "M11.0833 8.75H7.58333C7.26117 8.75 7 9.01117 7 9.33333C7 9.6555 7.26117 9.91667 7.58333 9.91667H11.0833C11.4055 9.91667 11.6667 9.6555 11.6667 9.33333C11.6667 9.01117 11.4055 8.75 11.0833 8.75Z", fill: fill })] })));
};
export var InverterIcon = createStandardIcon(InverterIconSvg);

import { orgSelectors } from 'ducks/orgs'
import { omit } from 'lodash'
import { getSupplierConfig } from 'pages/ordering/configs'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import BasicFilter from '../basic/BasicFilter'
import { DISTRIBUTOR_BRAND_FILTER_KEY_V2 } from '../manufacturerV2/DistributorBrandFilterNodeFactoryV2'
import {
  EXHIBITOR_FILTER_KEY_V2,
  MANUFACTURER_FILTER_KEY_V2,
} from '../manufacturerV2/FeatureConfigBrandFilterNodeFactoryV2'
import { AVAILABILITY_FILTER_KEY_V2 } from '../stockAvailability/AvailabilityFilterNodeFactoryV2'
import type {
  ConfigurableFilterOptionsType,
  FilterComponentNodeV2,
  FilterGenericPropsType,
  FilterValuesType,
  OptionGenericType,
} from '../typeV2'

export const DISTRIBUTOR_FILTER_KEY_V2 = 'distributors'
const defaultLabel = 'Available from'
const defaultSelectedOptionValue = ''
export const SELECT_ALL_OPTION = { id: 'default', title: 'All', value: defaultSelectedOptionValue }

const getDistributorsOptions = (enabledDistributors: HardwareSupplierEnum[]): OptionGenericType<string>[] => {
  const options = [SELECT_ALL_OPTION]
  enabledDistributors.forEach((distributor) => {
    const config = getSupplierConfig(distributor)
    if (!config) return
    options.push({
      id: config.filterKey,
      title: config.name,
      value: config.filterKey,
    })
  })

  return options
}

const createDistributorFilterNode = <T,>({
  label = defaultLabel,
  ...restConfigs
}: ConfigurableFilterOptionsType<string, T>): FilterComponentNodeV2 => {
  const FilterComponent = (filterProps: FilterGenericPropsType) => {
    const enabledDistributors = useSelector(orgSelectors.getEnabledHardwareSuppliers)
    const distributorOptions = getDistributorsOptions(enabledDistributors)
    const sideEffect = useCallback(
      ({ filterValues }: { filterValues: FilterValuesType; value: T; selectedOption: OptionGenericType<string> }) => {
        // Remove the manufacturer filter when distributor filter is changed
        const newFilterValues = omit(filterValues, [
          EXHIBITOR_FILTER_KEY_V2,
          MANUFACTURER_FILTER_KEY_V2,
          DISTRIBUTOR_BRAND_FILTER_KEY_V2,
        ])

        if (!newFilterValues[DISTRIBUTOR_FILTER_KEY_V2]) {
          delete newFilterValues[AVAILABILITY_FILTER_KEY_V2]
        }

        return newFilterValues
      },
      []
    )
    return (
      <BasicFilter
        label={label}
        defaultSelectedOptionValue={defaultSelectedOptionValue}
        options={distributorOptions}
        filterKey={DISTRIBUTOR_FILTER_KEY_V2}
        sideEffect={sideEffect}
        {...restConfigs}
        {...filterProps}
      />
    )
  }

  return {
    key: DISTRIBUTOR_FILTER_KEY_V2,
    component: FilterComponent,
  }
}

export default createDistributorFilterNode

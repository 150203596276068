// @ts-nocheck
import { makeStyles, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'util/Debounce'
import { formatCurrencyWithSymbol } from 'util/misc'
import { BomLineItemType } from '../nativeMounting/mounting_systems/Esdec/types'

const useStyles = makeStyles({
  bomProductRow: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #ececec',
  },
  bomProductTitleView: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  bomProductFieldsView: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  fieldValuesRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
  },
  bomOtherCol: {
    flex: 1,
    textAlign: 'right',
    opacity: 1.0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bomOtherColInput: {
    flex: 1,
    textAlign: 'right',
    opacity: 1.0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: '1px dotted gray',
  },
  bomTotalCostCol: {
    flex: 2,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bomProductName: {
    fontSize: '18px',
  },
  input: {
    backgroundColor: '#fff',
    maxWidth: '70%',
    borderRadius: 5,
    textAlign: 'center',
    borderColor: '#ffda00',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    boxShadow: '2px 2px #ececec',
  },
})

type PropTypes = {
  item: BomLineItemType
  categoryName: string
  orgComponents: any[]
  integrationConfig: any
  onQuantityChange: (newVal: string, item: BomLineItemType) => void
  i: number
}

const StockLevel = ({ item }) => {
  if (item.ordering?.stock_levels && item.ordering?.stock_levels[0]) {
    var inStock = item.ordering?.stock_levels[0]?.in_stock === true
    var quantity = item.ordering?.stock_levels[0]?.quantity || 0

    if (!inStock) {
      return <span style={{ color: 'red' }}> - Out of stock</span>
    } else {
      return <span> - Stock: {quantity}</span>
    }
  } else {
    return null
  }
}

const EsdecRenusolBomRow: React.FunctionComponent<PropTypes> = (props) => {
  const getInitialUnitPrice = () => {
    var distributorItem = props.item?.ordering
    var distributorPrice = distributorItem?.price || null

    const total = distributorPrice || 0
    const qty = props.item.suggested_count
    if (!isNaN(total) && !isNaN(qty) && qty > 0) {
      return total / qty
    } else return 0
  }

  const classes = useStyles()
  const [initialUnitPrice, setInitialUnitPrice] = useState<number>(getInitialUnitPrice())
  const [quantity, setQuantity] = useState<number>(props.item.optional ? 0 : props.item.suggested_count)

  useEffect(() => {
    setInitialUnitPrice(getInitialUnitPrice())
  }, [props.item])

  useEffect(() => {
    if (props.item.optional) handleFieldChange(0)
  }, [props.item.optional])

  const handleFieldChange = (newVal: string) => {
    setQuantity(newVal)
    doDebouncedOnFieldChange(newVal, props.item)
  }

  const doDebouncedOnFieldChange = useDebouncedCallback((...args) => {
    props.onQuantityChange(...args)
  }, 300)

  const getTotalPriceForItem = (currencySymbol) => {
    var distributorItem = props.item?.ordering
    var distributorPrice = distributorItem?.price || null

    let cost = distributorPrice || 0
    if (cost === 0) return 'Not Received'
    return formatCurrencyWithSymbol(quantity * cost, currencySymbol)
  }

  return (
    <div className={classes.bomProductRow}>
      <div className={classes.bomProductTitleView}>
        <div className={classes.bomProductName}>{props.item.name}</div>
        <span>
          {props.item.description && props.item.description}
          <StockLevel item={props.item} />
        </span>
      </div>
      <div className={classes.bomOtherColInput}>{props.item.suggested_count}</div>
      <div className={classes.bomOtherColInput}>
        <TextField
          value={quantity}
          onChange={(e) => handleFieldChange(e.target.value)}
          fullWidth={false}
          className={classes.input}
          inputProps={{
            style: {
              marginBottom: '2px',
              textAlign: 'center',
            },
          }}
          type="number"
        />
      </div>
      <div className={classes.bomProductTitleView}>
        <div className={classes.bomTotalCostCol} style={{ justifyContent: 'flex-end' }}>
          {getTotalPriceForItem(props.currencySymbol)}
        </div>
      </div>
    </div>
  )
}

export default EsdecRenusolBomRow

import { transactionSelectors } from 'ducks/transaction'
import { ActionDataType } from 'myenergy/selectionComponent/loanApplicationButton/types'
import { useTranslate } from 'ra-core'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { ProposalDataType } from 'types/proposals'
import PDFContractDisplay from './PDFContractDisplay'

const useStyles = makeOpenSolarStyles((theme) => ({
  foldedContractStyle: {
    height: 120,
    overflowX: 'auto',
    overflowY: 'scroll',
  },
  expandedContractStyle: {
    backgroundColor: '#fff',
  },
}))

type PropTypes = {
  proposalData: ProposalDataType
  toggleFullScreen: () => void
  systemId: number | null
}

const CheckoutContractDisplay: React.FC<PropTypes> = (props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const classes = useStyles()
  const translate = useTranslate()

  const transactionRequestData = useSelector(transactionSelectors.getTransactionRequestData) as ActionDataType

  const hasInitiatedChangeOrder = !!props.proposalData?.changeOrderProposal
  const hasNoContractTemplate =
    !props.proposalData?.selectedPaymentOption?.contract_template || transactionRequestData?.docusign_contract_complete
  const hasPdfContract = !!props.proposalData?.selectedPaymentOption?.contract_base_pdf_url

  const onSuccess = () => {}
  if (!props.proposalData || (!hasInitiatedChangeOrder && hasNoContractTemplate)) {
    return null
  } else if (hasPdfContract && !hasInitiatedChangeOrder) {
    return (
      <PDFContractDisplay
        toggleExpand={props.toggleFullScreen}
        onSuccess={onSuccess}
        orgId={props.proposalData.org?.id}
        projectId={props.proposalData.selectedProject?.id}
        systemUuid={props.proposalData.selectedSystem?.uuid}
        paymentOptionId={props.proposalData.selectedPaymentOption?.id}
      />
    )
  } else {
    const initiatedChangeOrder = props.proposalData?.changeOrderProposal
    let template_string = props.proposalData?.selectedPaymentOption?.contract_template

    if (!!initiatedChangeOrder && props.systemId !== null) {
      template_string = initiatedChangeOrder.system_change_orders[props.systemId].change_order_content
    }
    return (
      <div className="transaction-terms-container">
        <div style={isExpanded ? { textAlign: 'right', backgroundColor: 'white' } : { textAlign: 'right' }}>
          <button style={{ margin: 10 }} onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? translate('Fold') : translate('Expand')}
          </button>
        </div>
        <div
          className={isExpanded ? classes.expandedContractStyle : classes.foldedContractStyle}
          dangerouslySetInnerHTML={{
            __html: template_string,
          }}
          data-testid="html-contract-wrapper"
        />
      </div>
    )
  }
}
export default CheckoutContractDisplay

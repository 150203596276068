export const SET_UPLOAD_LOGO = 'SET_UPLOAD_LOGO'

export const setUploadLogo = value => ({
  type: SET_UPLOAD_LOGO,
  payload: value,
})

export default function reducer(previousState = false, { type, payload }) {
  if (type === 'SET_UPLOAD_LOGO') {
    return payload
  }
  return previousState
}

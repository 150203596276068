import { Paper, Step, StepContent, StepLabel, Stepper } from '@material-ui/core'
import { push as pushAction } from 'connected-react-router'
import { setQuickStartAction } from 'ducks/quickStart'
import { ControlBreadCrumbs } from 'elements/BreadCrumbs'
import GenericButton from 'elements/button/GenericButton'
import { HelpLink } from 'elements/link/HelpLink'
import { Button } from 'opensolar-ui'
import { useTranslate } from 'react-admin'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import appStorage from 'storage/appStorage'

const getQuickStartData = (orgId) => ({
  title: 'QUICK START GUIDE',
  steps: [
    {
      title: 'Business Info',
      description:
        'Enter Business Information here, including your company name, logo and contact information so your proposals reflect your brand.',
      help: 'https://support.opensolar.com/hc/en-us/sections/360003261651-Getting-Set-up',
      cards: [
        {
          title: 'Manage Business Info',
          url: '/content/' + orgId,
        },
      ],
    },
    {
      title: 'Price Schemes',
      description:
        'Set your Price Schemes method (e.g. "Cost Plus" or "Price-Per-Watt") so OpenSolar can calculate the right price for each design.',
      help: 'https://support.opensolar.com/hc/en-us/articles/360040571232--How-to-set-up-your-Pricing-and-Costing',
      cards: [
        {
          title: 'Manage Price Schemes',
          url: '/pricing_schemes',
        },
      ],
    },
    {
      title: 'Payment Options',
      description:
        'Configure any Payment Options that you intend to offer (e.g. cash, solar loan, PPA) so you can give your customers a clear picture of the benefits of going solar.',
      help: 'https://support.opensolar.com/hc/en-us/articles/360021495092-How-to-Set-up-Payment-Options',
      cards: [
        {
          title: 'Manage Payment Options',
          url: '/payment_options',
        },
      ],
    },
    {
      title: 'Setbacks & Design Settings',
      description:
        'Configure setback and other design settings, so you can quick, easily and accurately design your projects.',
      help: 'https://support.opensolar.com/hc/en-us/articles/360021494132-How-to-Create-a-Project-Preset',
      cards: [
        {
          title: 'Manage Setbacks & Design Settings',
          url: '/project_configurations',
        },
      ],
    },
    {
      title: 'Proposal Template',
      description:
        'Customise the content, layout and functionality of your solar proposal (we call it "MyEnergy") so that it works well with your sales process.',
      help: 'https://support.opensolar.com/hc/en-us/articles/360041012891--How-to-customise-your-proposal-template',
      cards: [
        {
          title: 'Manage Proposal Templates',
          url: '/proposal_templates',
        },
      ],
    },
  ],
  back: {
    title: 'Go back to Guide',
    url: '/quick-start',
  },
})

const QuickStart = (props) => {
  const {
    orgId,
    push,
    quickStart: { stepIndex, finished },
  } = props
  const translate = useTranslate()
  const handlePrev = () => {
    const { stepIndex } = props.quickStart
    if (stepIndex > 0) {
      props.updateQuickStartProcess({
        ...props.quickStart,
        stepIndex: stepIndex - 1,
      })
    }
  }

  const handleNext = () => {
    const { stepIndex } = props.quickStart

    props.updateQuickStartProcess({
      finished: stepIndex >= getQuickStartData(orgId).steps.length - 1,
      stepIndex: stepIndex + 1,
    })
  }

  const renderStepActions = (step) => {
    return (
      <div style={{ margin: '12px 0 12px 5px' }}>
        <Button style={{ marginLeft: 1, marginRight: 12 }} onClick={handlePrev} disabled={step === 0}>
          <span>{translate('Back')}</span>
        </Button>
        <Button variant="outlined" color="primary" onClick={handleNext}>
          <span>
            {stepIndex === getQuickStartData(orgId).steps.length - 1 ? translate('Finish') : translate('Next')}
          </span>
        </Button>
      </div>
    )
  }

  return (
    <>
      <ControlBreadCrumbs style={{ padding: '24px 24px 0 24px' }} currentPage="Quick Start Guide" />
      <Paper style={{ margin: '12px 24px', color: '#4d4d4d', padding: '0px 16px 0px 16px' }}>
        <h2
          style={{
            fontSize: 20,
            display: 'inline-block',
            fontWeight: 500,
            height: 68,
            lineHeight: '68px',
            margin: 0,
            color: 'rgba(0, 0, 0, 0.87)',
          }}
        >
          {translate('Quick start guide to setting up your account')}
        </h2>
        <div style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
          {translate(
            "Follow the steps below to configure your OpenSolar account and you'll be ready to send out proposals in 15 minutes."
          )}
        </div>
        <div>
          <div style={{ maxWidth: '90%', margin: '20px auto' }}>
            <Stepper activeStep={stepIndex} orientation="vertical">
              {getQuickStartData(orgId).steps.map((step, index) => {
                return (
                  <Step key={step.title}>
                    <StepLabel>
                      <span style={{ marginLeft: 5 }}>{translate(step.title)}</span>
                    </StepLabel>
                    <StepContent>
                      <p style={{ marginLeft: 5 }}>
                        {translate(step.description)} &nbsp;
                        <HelpLink href={step.help} />
                      </p>
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          marginLeft: 5,
                        }}
                      >
                        {step.cards.map((card) => (
                          <div key={card.url} style={{ margin: '5px 25px 15px 0' }}>
                            <Link id="ButtonControlManage" to={card.url}>
                              <GenericButton
                                labelColor="rgb(255, 255, 255)"
                                backgroundColor="rgb(24, 144, 255)"
                                backgroundColorOnHover="rgba(24, 144, 255, 0.8)"
                                label={translate(card.title)}
                              />
                            </Link>
                          </div>
                        ))}
                      </div>
                      {renderStepActions(index)}
                    </StepContent>
                  </Step>
                )
              })}
            </Stepper>
            {finished && (
              <div>
                <p style={{ margin: '20px 0' }}>
                  {translate(
                    'You have successfully completed the quick start guide to setting up your account. You should now be able to quickly design proposals within minutes.'
                  )}
                </p>
                <Button
                  style={{ margin: '0 10px 0 1px' }}
                  onClick={() =>
                    props.updateQuickStartProcess({
                      finished: false,
                      stepIndex: 0,
                    })
                  }
                >
                  <span>{translate('Reset')}</span>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    appStorage.setBool('hideQuickStart', true)
                    push('/control')
                  }}
                  labelColor="rgb(255, 255, 255)"
                >
                  <span>{translate('Finish Guide')}</span>
                </Button>
              </div>
            )}
          </div>
        </div>
      </Paper>
    </>
  )
}
const mapStateToProps = (state) => ({
  orgId: state.auth ? state.auth.org_id : null,
  quickStart: state.quickStart,
})

export default connect(mapStateToProps, { updateQuickStartProcess: setQuickStartAction, push: pushAction })(QuickStart)

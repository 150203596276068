import { orgSelectors } from 'ducks/orgs'
import type LineItemType from 'pages/ordering/OrderLineItem'
import { formatPricePerWattWithUnit } from 'pages/ordering/detail/content/price/PriceDetail'
import useProductSpecsData from 'pages/ordering/detail/useProductSpecsData'
import { getDiscountLabel, getModuleDiscountSavingPerWatt } from 'pages/ordering/discountUtils'
import type { SelectorOptionComponentType } from 'pages/ordering/order/table/quantity/Selector'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { currencySymbolForCountry } from 'util/misc'
import Selector from '../Selector'

const useStyles = makeOpenSolarStyles((theme) => ({
  labelWrapper: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

const SelectorLabel = memo(({ lineItem }: { lineItem: LineItemType }) => {
  const classes = useStyles()
  const orgIso2 = useSelector(orgSelectors.getOrgIso2)
  const currencySymbol = currencySymbolForCountry(orgIso2)
  const productDetailData = useProductSpecsData(lineItem)
  const outputPower = productDetailData.kw_stc && productDetailData.kw_stc * 1000
  const { beforeDiscount, afterDiscount, discount } = lineItem.getBestDiscountOffer()

  const savingPerWatt =
    lineItem.componentType === 'module' &&
    lineItem.selectedDistributorOrderingData &&
    discount &&
    getModuleDiscountSavingPerWatt({
      discount,
      outputPower,
      quantityPerItem: lineItem.selectedDistributorOrderingData.quantity_per_item,
      pricePerItem: lineItem.selectedDistributorOrderingData.price,
    })

  return (
    <div className={classes.labelWrapper}>
      <span>
        {getDiscountLabel({
          savingsOverride: savingPerWatt ? `${formatPricePerWattWithUnit(savingPerWatt, orgIso2)}/W off` : undefined,
          discount,
          currencySymbol,
        })}
      </span>
    </div>
  )
})

const VolumeDiscountSelector = ({
  lineItem,
  optionComponents,
}: {
  lineItem: LineItemType
  optionComponents: SelectorOptionComponentType[]
}) => {
  return (
    <Selector
      isLoading={lineItem.status === 'loading'}
      value={lineItem.quantity}
      optionComponents={optionComponents}
      renderValue={(value) => <SelectorLabel lineItem={lineItem} />}
    />
  )
}

export default VolumeDiscountSelector

import { Item, MountingCalcResult, PanelWithPosition } from '../types'
import { addItems } from '../utils'
import { BomGeneratorAbstract } from './BomGeneratorAbstract'

export abstract class PerPanelBomGeneratorAbstract extends BomGeneratorAbstract {
  abstract generateBomForPanel(panel: PanelWithPosition, blockIndex: number): Item[]

  async generateBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    var itemsToAdd: Item[] = []
    result.panelBlocks.forEach((panelBlock, blockIndex) => {
      panelBlock.rows.forEach((row) => {
        row.forEach((panel) => {
          if (panel.isActive) {
            itemsToAdd.push(...this.generateBomForPanel(panel, blockIndex))
          }
        })
      })
    })
    return addItems(result, itemsToAdd)
  }
}

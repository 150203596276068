import { downloadCSV } from 'react-admin'
import appStorage from 'storage/appStorage'

export const handleCsvDownload = (
  url,
  notify,
  filterValues,
  csvDownloadFilename,
  subs: object | undefined = undefined,
  customDownloadCSV: Function | undefined = undefined
) => {
  let urlWithFilters = url
  for (var key in filterValues) {
    //Hack, not sure why 'q' and 'search' are getting mixed up, other filterValues seem to work just fine
    var paramName = key === 'q' ? 'search' : key
    urlWithFilters += '&' + paramName + '=' + filterValues[key]
  }

  if (subs) {
    for (const key in subs) {
      urlWithFilters = urlWithFilters.replaceAll(`%{${key}}`, subs[key])
    }
  }

  fetch(urlWithFilters, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + appStorage.getToken(),
      'Content-Type': 'text/csv; charset=utf-8',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((data) => {
          throw new Error(data.message)
        })
      } else {
        return response.blob()
      }
    })
    .then((blob) => {
      if (customDownloadCSV) {
        customDownloadCSV(blob, csvDownloadFilename)
      } else {
        downloadCSV(blob, csvDownloadFilename)
      }
    })
    .catch(function (error) {
      notify(error.message, 'error')
    })
}

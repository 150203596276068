import { orgSelectors } from 'ducks/orgs'
import SideDrawer from 'elements/drawer/SideDrawer'
import { Button, Chip, styled } from 'opensolar-ui'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import { memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'types/state'
import { setToggleBom } from '../../../../../reducer/designer/view'
import BomDrawerContentV3 from '../bom3/BomDrawerContentV3'

const Wrapper = styled('div')({
  margin: '10px',
  padding: '10px 0',
  overflowX: 'auto',
  borderBottom: '1px solid ' + COLOR_PALETTE.lightGrey,
  display: 'flex',
})

const ViewButton = styled(Button)(({ theme }) => ({
  fontWeight: 450,
  color: COLOR_PALETTE.iconColor,
  '&.active': {
    color: theme.palette.info.contrastText,
  },
  '& .btnLabel': {
    margin: '0 5px',
  },
}))

const StyledChip = styled(Chip)({
  height: 18,
  fontSize: 10,
  borderRadius: 3,
})

const ButtonContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  marginRight: 15,
  flex: '0 0 auto',
}))

const Highlight = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: -10,
  width: '100%',
  borderBottom: '2px solid ' + theme.palette.info.contrastText,
}))

const tabs = ['Parts List', 'My Distributors Connection']

const BomDrawerV3 = () => {
  const [viewSelected, setViewSelected] = useState(0)

  const enabledDistributors = useSelector(orgSelectors.getEnabledHardwareSuppliers)
  const dispatch = useDispatch()
  const bomToggle = useSelector((state: RootState) => state.designer.view.bomToggle)

  return (
    <SideDrawer
      unmountOnExit={true}
      open={bomToggle}
      setOpen={(open) => dispatch(setToggleBom(open))}
      side={'right'}
      disabled={false}
      DrawerPullLabel={'Parts List'}
    >
      <>
        <Wrapper>
          {tabs.map((tab, index) => {
            return (
              <ButtonContainer key={'parts-list-' + index}>
                <ViewButton
                  onClick={() => {
                    setViewSelected(index)
                  }}
                  variant="text"
                  color="secondary"
                  className={index === viewSelected ? 'active' : undefined}
                >
                  <span className="btnLabel">{tab}</span>
                  {index === 1 && (
                    <StyledChip
                      label={`${enabledDistributors.length}`}
                      color={index === viewSelected ? 'info' : 'default'}
                      style={{ color: index === viewSelected ? undefined : COLOR_PALETTE.iconColor }}
                    />
                  )}
                </ViewButton>
                {index === viewSelected && <Highlight />}
              </ButtonContainer>
            )
          })}
        </Wrapper>
        <BomDrawerContentV3 tab={viewSelected} />
      </>
    </SideDrawer>
  )
}

export default memo(BomDrawerV3)

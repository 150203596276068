import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import { generateDocumentSelectors, setGenerateDocumentDialog } from 'ducks/generateDocument'
import { permissionsSelectors } from 'ducks/permissions'
import WithPermissionsCheck from 'elements/WithPermissionsCheck'
import useGetDirtyFields from 'projectSections/hooks/useGetDirtyFields'
import { useDataProvider, useNotify } from 'ra-core'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useField, useForm } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { PrivateFileType } from 'types/privateFile'
import Button from '../../../../elements/button/Button'
import CRUDRecordDialog from '../../common/CRUDRecordDialog'
import AccordionCard from '../AccordionCard'
import FileRow from './FileRow'
import GenerateDocumentDialog from './GenerateDocumentDialog'
import NewFileForm from './NewFileForm'

const useStyles = makeStyles((theme) => ({
  content: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headingIcon: {
    verticalAlign: 'middle',
  },
  headingTitle: {
    margin: '0 10px',
    verticalAlign: 'middle',
  },
  grid: {
    '& > .MuiGrid-item': {
      padding: '0 12px',
    },
  },
  buttonsRow: {
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  buttonWrapper: {
    margin: '0 10px 10px 0',
  },
}))

type PropTypes = {}

const ProjectFileContent: React.FunctionComponent<PropTypes> = (props) => {
  const { allowCreate } = useSelector(permissionsSelectors.getProjectPermissionByKey('info_documents'))
  const [showCreateFileDialog, setShowCreateFileDialog] = useState<boolean>(false)
  const classes = useStyles()
  const translate = useTranslate()
  const dispatch = useDispatch()
  const form = useForm()
  const dirtyFields = useGetDirtyFields()
  const formState = form.getState()
  const record = formState.values

  // Not sure where '' is coming from, but using 'Enter Address Later' flow results in this
  const filesRaw: PrivateFileType[] | '' | undefined = useField('private_files_data', { subscription: { value: true } })
    ?.input?.value //record?.private_files_data

  const files = !filesRaw ? [] : filesRaw

  // Filter out any design artifacts
  const filteredFiles = useMemo(() => {
    return files?.filter((f) => !f.file_tags_data.some((tag) => tag.title === 'Design Artifact'))
  }, [files])

  const updateEventInForm = (newFile: PrivateFileType) => {
    const newFileArray: PrivateFileType[] = files
      .filter((f) => f.id !== newFile.id)
      .concat(newFile)
      .sort((f1, f2) => f1.title.localeCompare(f2.title))
    formState.initialValues.private_files_data = newFileArray
    form.change('private_files_data', newFileArray)
  }

  // Listen for changes in generated doc to load new private file
  const doc = useSelector(generateDocumentSelectors.getLastDocument)
  const dataProvider = useDataProvider()
  const notify = useNotify()
  useEffect(() => {
    if (doc?.status !== 'success' || !doc.data) return
    dataProvider.CUSTOM_GET(
      'private_files',
      {
        url: `private_files/${doc.data.id}/`,
      },
      {
        onSuccess: (response: { data: PrivateFileType }) => {
          const { data } = response
          updateEventInForm(data)
        },
        onFailure: (e) => {
          console.log(e)
        },
      }
    )
  }, [dataProvider, notify, doc])

  return (
    <Grid container spacing={3} className={classes.grid}>
      <Grid item xs={12} className={classes.grid}>
        <div className={classes.buttonsRow}>
          <div className={classes.buttonWrapper}>
            <Button
              variant="contained"
              color="default"
              disabled={!allowCreate}
              label="Generate Document"
              onClick={() => dispatch(setGenerateDocumentDialog(true))}
              startIcon={<DescriptionOutlinedIcon />}
            />
          </div>
          <div>
            <Button
              variant="contained"
              color="default"
              disabled={!allowCreate}
              label="File"
              onClick={() => setShowCreateFileDialog(true)}
              startIcon={<CloudUploadOutlinedIcon />}
              style={{ marginBottom: 10 }}
            />
          </div>
        </div>
      </Grid>
      <GenerateDocumentDialog
        projectId={record.id}
        orgId={record.org_id}
        projectForm={form}
        projectFormDirtyFields={dirtyFields}
      />
      {showCreateFileDialog && (
        <CRUDRecordDialog
          isOpen={showCreateFileDialog}
          record={{ project: record.url }}
          resource="private_files"
          basePath="/private_files"
          onDismiss={() => setShowCreateFileDialog(false)}
          formContent={<NewFileForm record={undefined} />}
          notificationOnSuccess={true}
          customSuccessNotificationText={translate('Your File has been saved')}
          updateFormValsFn={updateEventInForm}
          dialogTitle={translate('Add Project File')}
        />
      )}
      <div style={{ width: '100%', maxHeight: 400, overflow: 'hidden auto' }}>
        {filteredFiles &&
          filteredFiles.length > 0 &&
          filteredFiles
            ?.sort((a, b) => (a.id < b.id ? 1 : -1))
            .map((file) => <FileRow file={file} key={file.id} recordUrl={record.url} />)}
      </div>
    </Grid>
  )
}

const ProjectFileAccordion = () => {
  const translate = useTranslate()

  return (
    <WithPermissionsCheck permissionToCheck="info_documents" permissions={['allowView']}>
      <AccordionCard
        name={'project_files'}
        title={translate('Project Files')}
        titleIcon={DescriptionOutlinedIcon}
        content={ProjectFileContent}
        defaultExpanded={true}
      />
    </WithPermissionsCheck>
  )
}

export default ProjectFileAccordion

import { useStudioSignalsLazy } from 'Studio/signals/useStudioSignalsLazy'
import { myEnergySelectors } from 'ducks/myEnergy'
import { useCallback, useMemo, useState } from 'react'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { ProjectType } from 'types/projects'
import { StudioSystemType } from 'types/studio/items'
import { urlToId } from 'util/misc'

const useIsSceneLoading = () => {
  const [isLoading, setIsLoading] = useState(window.editor.sceneIsLoading)
  const onSceneLoaded = useCallback(() => {
    setIsLoading(window.editor.sceneIsLoading)
  }, [])
  useStudioSignalsLazy(onSceneLoaded, ['sceneLoaded'])
  return isLoading
}

export const useSoldSystem = () => {
  const soldSystemUuid = useSoldSystemUuid()
  const isSceneLoading = useIsSceneLoading()
  return useMemo(() => {
    if (soldSystemUuid) {
      return window.editor.objectByUuid(soldSystemUuid) as StudioSystemType
    } else {
      return undefined
    }
  }, [soldSystemUuid, isSceneLoading]) // isSceneLoading avoid timing issue
}

export const useSoldSystemUuid = () => {
  const project: Partial<ProjectType> = useFormState().values
  const justSoldSystemUuid = useSelector(myEnergySelectors.getSystemUuidForcedMarkedAsSold)

  return useMemo(() => {
    if (justSoldSystemUuid) {
      return justSoldSystemUuid
    } else if (project.system_sold) {
      const sysId = urlToId(project.system_sold)
      return project?.systems?.find((sys) => sys.id === sysId)?.uuid
    }
  }, [project.system_sold, justSoldSystemUuid])
}

import { Dialog, DialogContent, useMediaQuery } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import React, { useEffect } from 'react'
import { Theme } from 'types/themes'
import PhoenixDownPaymentWarning from './PhoenixDownPaymentWarning'

type PropTypes = {
  url: string
  onClose: () => void
  pricePayable: number
}

const PhoenixCalculatorDialog: React.FC<PropTypes> = (props) => {
  useEffect(() => {
    logAmplitudeEvent('integrated_finance_calculator_opened', { integration: 'phoenix' })
  }, [])
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  if (!props.url) return null
  return (
    <Dialog open={true} onClose={props.onClose} maxWidth="xl" fullScreen={true} fullWidth={isMobile}>
      <DialogContent>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            alignItems: 'center',
            minHeight: '700px',
            position: 'relative',
          }}
        >
          <div
            style={{
              backgroundColor: '#ececec',
              borderRadius: '50px',
              border: '1px solid black',
              cursor: 'pointer',
              width: '40px',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              top: '0px',
              left: '15px',
            }}
            onClick={props.onClose}
          >
            X
          </div>
          <img height="75px" src={`${window.PUBLIC_URL}/images/phoenix-logo.png`} />
          {props.pricePayable > 0 && <PhoenixDownPaymentWarning pricePayable={props.pricePayable} />}
          <span className="small" style={{ margin: '0px 20px 10px 20px' }}>
            The calculator below is meant to help you understand what financing options are available to you for this
            system. If you would like to move forward with financing you can click the green "Apply for Finance" button
            in the proposal and you'll be asked to select your preferred product as part of your credit application.
          </span>
          <iframe src={props.url} style={{ border: 'none', height: '100%', width: '100%', minHeight: '700px' }} />
        </div>
      </DialogContent>
    </Dialog>
  )
}
export default PhoenixCalculatorDialog

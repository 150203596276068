import React from 'react'

type PropTypes = {
  text: string
}

const PremiumProductDescription: React.FC<PropTypes> = (props) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span dangerouslySetInnerHTML={{ __html: props.text }} />
    </div>
  )
}
export default PremiumProductDescription

const SingleTiltIcon = (props: { highlight: boolean }) => {
  const neutralColorHex1 = '#C7CAD1'
  const neutralColorHex2 = '#9499A5'
  const highlightColorHex = '#4272DD'

  return (
    <svg width="64" height="24" viewBox="0 0 64 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M60 23.5H6"
        stroke={props.highlight ? highlightColorHex : neutralColorHex1}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1.5 1.5"
      />
      <path
        d="M48 16.4995L48 22.4995"
        stroke={props.highlight ? highlightColorHex : neutralColorHex1}
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M18 8.5L18 22.45"
        stroke={props.highlight ? highlightColorHex : neutralColorHex1}
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M51 15L15 7"
        stroke={props.highlight ? highlightColorHex : neutralColorHex2}
        strokeWidth="3.74"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SingleTiltIcon

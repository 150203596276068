import { Step, StepButton, Stepper, makeStyles } from '@material-ui/core'
import { Box } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'

type PropTypes = {
  currentPage: string
  completed: {}
}

const useStepperStyles = makeStyles((theme) => ({
  root: {
    padding: '0 0 20px 0',
    '& .MuiButtonBase-root': {
      cursor: 'default',
    },
    '& .MuiStepLabel-label.MuiStepLabel-active': {
      color: '#1751D0',
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiStepIcon-root.MuiStepIcon-completed': {
      color: '#1751D0',
    },
    '& .MuiStep-completed .MuiStepLabel-completed': {
      color: '#4F4D55',
    },
    '& .MuiStepIcon-root.MuiStepIcon-active': {
      color: '#1751D0',
      border: '1px solid #618DF2',
      borderRadius: '100%',
      borderWidth: 2,
      padding: 2,
    },
    '& .MuiStepConnector-root': {
      width: '5%',
      marginLeft: '15px',
      flex: 'unset',
    },
    '& .MuiStepConnector-lineHorizontal': {
      borderTopWidth: 3,
      float: 'right',
      width: '100%',
    },
    '& .MuiStepConnector-root.MuiStepConnector-active .MuiStepConnector-lineHorizontal': {
      borderColor: '#1751D0 !important',
    },
    '& .MuiStepIcon-root.MuiStepIcon-active .MuiStepIcon-text': {
      fill: '#fff',
    },
    '& .MuiStep-horizontal': {
      padding: '0',
    },

    '& .MuiStepLabel-label': {
      display: 'flex',
      alignItems: 'center',
      color: '#ADACB0',
    },

    '& .MuiStepLabel-label .MuiSvgIcon-root': {
      fontSize: '20px',
      margin: '0 10px',
    },
  },
}))

const LightReachStepper: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const stepperClasses = useStepperStyles()

  const steps = ['Confirm Details', 'Select Pricing']

  return (
    <Box>
      <Stepper nonLinear activeStep={props.currentPage === 'equipment' ? 0 : 1} classes={stepperClasses}>
        {steps.map((label, index) => (
          <Step key={label} completed={props.completed[index]}>
            <StepButton color="inherit">{translate(label)}</StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}
export default LightReachStepper

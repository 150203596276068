// @ts-nocheck
import React, { useEffect, useState } from 'react'
import UploadStipulationButton from './UploadStipulationButton'

type StipulationsMapType = {
  created?: any[]
  submitted?: any[]
}

type PropTypes = {
  stipulationsMap: StipulationsMapType
  loanType: any
  myeStyles: {
    highlightColor: string
    highlightColorInteraction: string
    highlightColorLight: string
    textColor: string
    textColorOnHighlight: string
  }
}
const MyEButton: React.FunctionComponent<PropTypes> = (props) => {
  const [directToEmail, setDirectToEmail] = useState<boolean>(false)

  const availableStips = props.stipulationsMap['created']
  const submittedStips = props.stipulationsMap['submitted']

  useEffect(() => {
    if (availableStips?.length > 0) {
      availableStips.forEach((stip) => {
        const docs = stip.stipulation_acceptable_docs ? JSON.parse(stip.stipulation_acceptable_docs) : {}
        if (Object.keys(docs)?.length === 0 && !directToEmail) setDirectToEmail(true)
        else if (Object.keys(docs)?.length > 0 && directToEmail) setDirectToEmail(false)
      })
    }
  }, [availableStips, directToEmail])

  if (!props.stipulationsMap || Object.keys(props.stipulationsMap).length < 1) return null
  if (directToEmail) return null
  else
    return (
      <div>
        {availableStips && (
          <div>
            <p>To progress with your financing please provide the following documentation</p>
            <div>
              {availableStips.map((stip) => {
                return <UploadStipulationButton key={stip.name} stipulation={stip} />
              })}
            </div>
          </div>
        )}
        {submittedStips && (
          <div>
            <div>
              {submittedStips.map((stip) => {
                return (
                  <UploadStipulationButton
                    key={stip.name}
                    stipulation={stip}
                    linkColor={props.myeStyles.highlightColor}
                  />
                )
              })}
            </div>
          </div>
        )}
      </div>
    )
}

export default MyEButton

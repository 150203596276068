// @ts-nocheck
import { makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import classnames from 'classnames'
import _ from 'lodash'
import { IconButton } from 'opensolar-ui'
import React, { FC, memo, useCallback, useEffect, useRef, useState } from 'react'
import { FieldTitle, NumberInput, SelectInput, useInput, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { currencySymbolForCountry } from 'util/misc'
import { getOrgCountryCodeFromState } from 'util/org'

const TIER_TYPES = [
  { key: 'system_size', label: 'System Size (kW)' },
  { key: 'components_quantity', label: 'Number of components' },
]

type PropsType = {
  resource: string
  numberInputSource: string
  tiersInputSource: string
  label?: string
  numberInputOptions?: any
  className?: string
  disabled: boolean
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: 300,
    display: 'inline-block',
  },
  labelWrapper: {
    transform: 'unset',
    fontSize: '12px',
  },
  tierInput: {
    transform: 'unset',
    fontSize: '12px',
    width: 120,
  },
  tierWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  closeIcon: {
    padding: 5,
  },
  label: {
    width: 150,
    marginRight: 20,
    display: 'inline-block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    verticalAlign: 'middle',
  },
  selectInputWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0',
  },
  toggleInLabel: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  tiersContainer: {
    maxHeight: 400,
    overflowY: 'auto',
  },
}))

const ScrollIndicator = memo(({ tiersLength }: any) => {
  const [showIndicator, setVisibility] = useState(false)
  const domRef = useRef()
  const refreshVisibility = useCallback(() => {
    //@ts-ignore
    const container = domRef?.current?.parentElement
    if (container.scrollHeight - container.clientHeight > container.scrollTop) {
      setVisibility(true)
    } else {
      setVisibility(false)
    }
  }, [])

  useEffect(() => {
    refreshVisibility()
  }, [tiersLength])

  useEffect(() => {
    const throttledHandleScroll = _.throttle(refreshVisibility, 300)
    //@ts-ignore
    const container = domRef?.current?.parentElement
    if (container) {
      container.addEventListener('scroll', throttledHandleScroll)
      return () => container.removeEventListener('scroll', throttledHandleScroll)
    }
  }, [])

  return (
    // @ts-ignore
    <div ref={domRef}>
      {showIndicator && (
        <div
          className="flash-animation"
          style={{
            position: 'absolute',
            borderTop: '10px solid rgba(170,170,170,0.6)',
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            bottom: -10,
            left: 110,
          }}
        ></div>
      )}
    </div>
  )
})

const TiersInput: FC<PropsType> = ({ numberInputSource, resource, tiersInputSource, disabled, label }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const currencySymbol = useSelector((state: any) => {
    const countryIso2 = getOrgCountryCodeFromState(state)
    return currencySymbolForCountry(countryIso2)
  })
  const { input: tiersInput } = useInput({
    resource,
    source: tiersInputSource,
  })
  const { onChange: onTiersChange, value: tiersInputValue } = tiersInput
  const clearDetailedPricing = useCallback(() => {
    onTiersChange(null)
  }, [])
  const handleLastTierChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>, index) => {
      //@ts-ignore
      const newValue = event.target.value ? parseInt(event.target.value) : null
      const newTiers = [...tiersInputValue.tiers]
      newTiers[index].tier = newValue

      const defaultTier = { price: null, tier: null }
      newTiers.push(defaultTier)
      tiersInputValue.tiers = newTiers

      onTiersChange(tiersInputValue)
    },
    [tiersInputValue]
  )
  const handleRemoveTier = useCallback(
    (index) => {
      const clonedTiersInputValue = _.cloneDeep(tiersInputValue)
      const cloneTiers = clonedTiersInputValue.tiers
      cloneTiers.splice(index, 1)

      onTiersChange(clonedTiersInputValue)
    },
    [tiersInputValue]
  )
  return (
    <div style={{ position: 'relative' }}>
      <div className={classnames('MuiFormLabel-root', classes.labelWrapper)}>
        <span className={classes.label}>
          <FieldTitle source={numberInputSource} resource={resource} label={label} />
        </span>
        <span
          className={classes.toggleInLabel}
          style={{ pointerEvents: disabled ? 'none' : 'auto' }}
          onClick={clearDetailedPricing}
        >
          {translate('Clear detailed pricing')}
        </span>
      </div>
      <div className={classes.selectInputWrapper}>
        <span className={classes.label}>{translate('Tier defined by:')}</span>
        <SelectInput
          resource={resource}
          label={false}
          disabled={disabled}
          margin={'none'}
          optionValue={'key'}
          optionText={'label'}
          name={`${tiersInputSource}.type`}
          source={`${tiersInputSource}.type`}
          choices={TIER_TYPES}
          initialValue={'system_size'}
        />
      </div>
      <div className={classes.tiersContainer}>
        {tiersInputValue?.tiers?.map((tier: any, index: number) => {
          const isLastTier = index === tiersInputValue.tiers.length - 1
          const isFirstTier = index === 0
          return (
            <div key={'tier' + index} className={classes.tierWrapper}>
              <NumberInput
                name={`${tiersInputSource}.tiers.${index}.price`}
                source={`${tiersInputSource}.tiers.${index}.price`}
                disabled={disabled}
                InputLabelProps={{ shrink: true }}
                startAdornment={currencySymbol}
                InputProps={{ classes: { root: classes.tierInput } }}
                style={{ marginRight: 5, width: 120 }}
                margin={'none'}
                label={'Price'}
                resource={resource}
              />
              <NumberInput
                name={`${tiersInputSource}.tiers.${index}.tier`}
                source={`${tiersInputSource}.tiers.${index}.tier`}
                disabled={disabled}
                style={{ marginRight: 5, width: 120 }}
                InputLabelProps={{ shrink: true }}
                InputProps={{ classes: { root: classes.tierInput } }}
                placeholder={isLastTier && 'Remaining tiers'}
                onChange={(event: React.FormEvent<HTMLInputElement>) => {
                  if (isLastTier) {
                    //When a value is entered into the last Tier field it will automatically add another line (Price and Tier) with Tier being “Remaining tiers” in grey text until it is filled in.
                    handleLastTierChange(event, index)
                  }
                }}
                margin={'none'}
                label={'Tier'}
                resource={resource}
              />
              {!isFirstTier && !isLastTier && (
                <IconButton
                  classes={{ root: classes.closeIcon }}
                  disabled={disabled}
                  onClick={() => handleRemoveTier(index)}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </div>
          )
        })}
        {tiersInputValue?.tiers?.length > 0 && <ScrollIndicator tiersLength={tiersInputValue.tiers.length} />}
      </div>
    </div>
  )
}

const NumberInputWithTiers: FC<PropsType> = ({
  resource,
  numberInputSource,
  tiersInputSource,
  disabled,
  className,
  label,
  numberInputOptions = {},
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const { input: tiersInput } = useInput({
    resource,
    source: tiersInputSource,
  })
  const { onChange: onTiersChange, value: tiersInputValue } = tiersInput

  const enterDetailedPricing = useCallback(() => {
    const defaultTier = { price: null, tier: null }
    const defaultValue = { type: 'system_size', tiers: [defaultTier] }
    onTiersChange(defaultValue)
  }, [])

  return (
    <div className={classnames('number-input-with-tiers', 'MuiFormControl-marginDense', classes.wrapper, className)}>
      {!!tiersInputValue ? (
        <TiersInput
          tiersInputSource={tiersInputSource}
          numberInputSource={numberInputSource}
          resource={resource}
          disabled={disabled}
        />
      ) : (
        <NumberInput
          name={numberInputSource}
          source={numberInputSource}
          disabled={disabled}
          margin={'none'}
          fullWidth={true}
          label={
            <>
              <span className={classes.label}>
                <FieldTitle source={numberInputSource} resource={resource} label={label} />
              </span>
              <span
                className={classes.toggleInLabel}
                style={{ pointerEvents: disabled ? 'none' : 'auto' }}
                onClick={enterDetailedPricing}
              >
                {translate('Enter detailed pricing')}
              </span>
            </>
          }
          resource={resource}
          InputLabelProps={{ classes: { root: classes.labelWrapper } }}
          {...numberInputOptions}
        />
      )}
    </div>
  )
}

export default NumberInputWithTiers

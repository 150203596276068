import NavigationLink from 'elements/navigation/NavigationLink'
import { FC } from 'react'

export const NavigationLinks: FC = () => {
  return (
    <>
      {/* SNIPPET START 1 */}
      <NavigationLink to="/ui_library/navigation">Active Link</NavigationLink>
      <NavigationLink to="/ui_library/button">Inactive Link</NavigationLink>
      {/* SNIPPET END 1 */}
    </>
  )
}

import { SystemContext } from 'Designer/designRules/types'
import { handleObjectChangedAsCommand } from 'projectSections/sections/design/systems/PanelSystem'
import { ComponentAssociation, ComponentDependencies } from 'types/associatedComponents'
import { ComponentTypes } from 'types/selectComponent'
import { EffectFactory } from '.'
import { getSystemItemsByCode } from '../utils'
import { activateAndAddComponentToSystem } from './add_component_by_code'

export type UpdateAssociatedComponentsEffectType = {
  type: 'update-associated-components'
}

export const effect_updateAssociatedComponents: EffectFactory<UpdateAssociatedComponentsEffectType> = (def) => {
  let typeDict = {
    OsModule: 'panel',
    OsInverter: 'inverter',
    OsBattery: 'battery',
    OsOther: 'other',
  }
  return {
    execute: async (context: SystemContext) => {
      const componentCode = context.trigger.studioItem?.code
      const componentType = context.trigger.studioItem?.type && typeDict[context.trigger.studioItem?.type]
      if (componentCode) {
        await updateAssociatedComponentsQuantities(componentCode, componentType, context)
      }
    },
  }
}

// NOTE: component can only be an other component until we add the 'qty' property to more component types
export async function updateAssociatedComponentsQuantities(
  componentCode: string,
  componentType: ComponentTypes,
  context: SystemContext
) {
  const dependencies: ComponentDependencies = context.system.custom_data['component_dependencies']

  function updateAssociatedComponentQuantity(component: ComponentAssociation): Promise<void> {
    return new Promise((resolve, reject) => {
      const addedComponentUuid = component.componentUuid
      const addedComponent = addedComponentUuid ? window.editor.objectByUuid(addedComponentUuid) : null
      const automatedComponentQty = component.totalCalculatedQty
      if (!automatedComponentQty) {
        if (addedComponent) {
          window.editor.deleteObject(addedComponent)
          delete component.componentUuid
          window.editor.execute(new window.SetValueCommand(context.system, 'custom_data', context.system.custom_data))
        }
        resolve()
        return
      }
      const triggeredByAddingComponent = context.trigger.studioSignal?.includes('-added')
      if (!addedComponentUuid || (!addedComponent && triggeredByAddingComponent)) {
        activateAndAddComponentToSystem(
          component.code,
          component.componentType,
          automatedComponentQty,
          context,
          true,
          () => {
            const newComponent = getSystemItemsByCode(component.componentType, component.code, context.system)?.slice(
              -1
            )[0]
            if (newComponent) {
              component.componentUuid = newComponent.uuid
            }
          }
        )
          .then(() => resolve())
          .catch(() => reject())
      } else {
        if (addedComponent) {
          if (addedComponent.quantity !== automatedComponentQty) {
            handleObjectChangedAsCommand(addedComponentUuid, 'quantity', automatedComponentQty)
          }
        } else {
          // User deleted the automated component, so do nothing
        }
        resolve()
      }
    })
  }

  const componentDependencies = dependencies.filter((dependency) => {
    if (dependency.source !== context.systemRuleKey) return false
    return dependency.parentComponents.filter((component) => {
      if (component.code) return component.code === componentCode
      return component.componentType === componentType
    }).length
  })

  for (const dependency of componentDependencies) {
    if (dependency.require) {
      for (const componentAssociation of dependency.require) {
        try {
          await updateAssociatedComponentQuantity(componentAssociation)
        } catch (err) {
          // Error activating component, already handled upstream
        }
      }
    }
  }

  window.editor.execute(new window.SetValueCommand(context.system, 'custom_data', context.system.custom_data))
}

import { DialogActions, DialogContent } from '@material-ui/core'
import { Button } from 'opensolar-ui'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { SimpleInvoiceType } from './types'

type PropTypes = {
  nextPage: () => void
  previousPage: () => void
  invoice: SimpleInvoiceType
}

const useStyles = makeOpenSolarStyles((theme) => ({
  actionsRow: {
    padding: '10px',
    borderTop: `1px solid ${theme.greyLight2}`,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  btnWrapper: {
    padding: '0px 10px',
  },
  pdfContainer: {
    minHeight: '600px',
    height: '100%',
    width: '100%',
  },
}))

const PreviewCustomInvoice: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  return (
    <>
      <DialogContent>
        <div>
          <h1>Preview Invoice</h1>
          <p>Ensure the invoice appears exactly as you want it to. Hit the back button below to make edits.</p>
          {/* IT: we'll have to take the PDF URL as a prop and display it here. If invoice PDFs can be multiple pages use TemplateWizardPDFDisplay as an example of where we handle paginated PDF rendering */}
          {/* RK: I chose not to use the TemplateWizardPDFDisplay because it non-configurably comes with all the drag+drop UI.
          Going to use a minimal browser-native PDF viewer for now. */}
          <object className={classes.pdfContainer} data={props.invoice.download_url} type="application/pdf">
            <p>
              {/* alt text: */}
              <a href={`${props.invoice.download_url}#toolbar=0`} target="_blank" rel="noopener noreferrer">
                Download PDF here
              </a>
            </p>
          </object>
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.actionsRow}>
          <div className={classes.btnWrapper}>
            <Button onClick={props.previousPage}>Back</Button>
          </div>
          <div className={classes.btnWrapper}>
            <Button onClick={props.nextPage} variant="contained" color="primary">
              Next
            </Button>
          </div>
        </div>
      </DialogActions>
    </>
  )
}
export default PreviewCustomInvoice

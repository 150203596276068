import { Grid } from '@material-ui/core'
import CustomField from 'elements/field/CustomField'
import CheckboxInput from 'elements/input/CheckboxInput'
import DependentInput from 'elements/input/DependentInput'
import { useTranslate } from 'ra-core'
import { SelectInput, TextInput } from 'ra-ui-materialui'
import React, { useEffect, useState } from 'react'
import { useFormState } from 'react-final-form'
import { HostedDisclosureType } from '../types'
import { DAYS_BY_MONTH, MONTHS, getYearChoices } from './constants'
import { formatSSN, parseIncome, parseSSN } from './utils'

type PropTypes = {
  onClose: () => void
  disclosures: HostedDisclosureType[]
}

type ChoiceType = {
  name: string
  id: string
}

const EnFinApplicationForm: React.FC<PropTypes> = (props) => {
  const [primaryDOBDayChoices, setPrimaryDOBDayChoices] = useState<ChoiceType[]>([])
  const [secondaryDOBDayChoices, setSecondaryDOBDayChoices] = useState<ChoiceType[]>([])

  const formState = useFormState()

  useEffect(() => {
    setPrimaryDOBDayChoices(getDayChoices(formState?.values?.applicantPersonalInformation?.[0]?.dobMonth))
  }, [formState?.values?.applicantPersonalInformation?.[0]?.dobMonth])

  useEffect(() => {
    setSecondaryDOBDayChoices(getDayChoices(formState?.values?.applicantPersonalInformation?.[1]?.dobMonth))
  }, [formState?.values?.applicantPersonalInformation?.[1]?.dobMonth])

  const getDayChoices = (selectedMonth: string) => {
    return selectedMonth &&
      !isNaN(parseInt(selectedMonth)) &&
      parseInt(selectedMonth) > 0 &&
      parseInt(selectedMonth) < 13
      ? // @ts-ignore
        DAYS_BY_MONTH[selectedMonth]
      : DAYS_BY_MONTH['none']
  }

  const renderBorrowerFields = (index) => {
    return (
      <>
        <Grid item xs={6}>
          <CustomField
            name={`applicantPersonalInformation.${index}.firstName`}
            source={`applicantPersonalInformation.${index}.firstName`}
            label="First Name"
            component={TextInput}
            fullWidth
            style={{ width: '100%', margin: '5px 0px', padding: '0px' }}
            required={true}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomField
            name={`applicantPersonalInformation.${index}.lastName`}
            source={`applicantPersonalInformation.${index}.lastName`}
            label="Last Name"
            component={TextInput}
            fullWidth
            style={{ width: '100%', margin: '5px 0px', padding: '0px' }}
            required={true}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomField
            name={`applicantPersonalInformation.${index}.mobileNumber`}
            source={`applicantPersonalInformation.${index}.mobileNumber`}
            label="Phone Number"
            component={TextInput}
            fullWidth
            style={{ width: '100%', margin: '5px 0px', padding: '0px' }}
            required={true}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomField
            name={`applicantPersonalInformation.${index}.emailAddress`}
            source={`applicantPersonalInformation.${index}.emailAddress`}
            label="Email Address"
            component={TextInput}
            fullWidth
            style={{ width: '100%', margin: '5px 0px', padding: '0px' }}
            required={true}
          />
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <span style={{ fontWeight: 900 }}>Date of Birth</span>
            <div style={{ display: 'flex', width: '100%' }}>
              <div style={{ flex: 1, padding: '0px 4px' }}>
                <CustomField
                  name={`applicantPersonalInformation.${index}.dobMonth`}
                  source={`applicantPersonalInformation.${index}.dobMonth`}
                  label={translate('Month')}
                  component={SelectInput}
                  choices={MONTHS}
                  fullWidth
                  style={{ width: '100%', margin: '5px 0px', padding: '0px' }}
                  required={true}
                />
              </div>
              <div style={{ flex: 1, padding: '0px 4px' }}>
                <CustomField
                  name={`applicantPersonalInformation.${index}.dobDay`}
                  source={`applicantPersonalInformation.${index}.dobDay`}
                  label={translate('Day')}
                  component={SelectInput}
                  choices={index === 0 ? primaryDOBDayChoices : secondaryDOBDayChoices}
                  fullWidth
                  style={{ width: '100%', margin: '5px 0px', padding: '0px' }}
                  required={true}
                />
              </div>
              <div style={{ flex: 1, padding: '0px 4px' }}>
                <CustomField
                  name={`applicantPersonalInformation.${index}.dobYear`}
                  source={`applicantPersonalInformation.${index}.dobYear`}
                  label={translate('Year')}
                  component={SelectInput}
                  choices={getYearChoices()}
                  fullWidth
                  style={{ width: '100%', margin: '5px 0px', padding: '0px' }}
                  required={true}
                />
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomField
            name={`applicantPersonalInformation.${index}.ssn`}
            source={`applicantPersonalInformation.${index}.ssn`}
            label={translate('Social Security Number')}
            component={TextInput}
            fullWidth
            style={{ width: '100%', margin: '5px 0px', padding: '0px' }}
            parse={parseSSN}
            format={formatSSN}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomField
            name={`applicantPersonalInformation.${index}.annualIncome`}
            source={`applicantPersonalInformation.${index}.annualIncome`}
            label={translate('Pre-Tax Annual Income')}
            component={TextInput}
            fullWidth
            parse={parseIncome}
            style={{ width: '100%', margin: '5px 0px', padding: '0px' }}
            required={true}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomField
            name={`applicantPersonalInformation.${index}.lives_somewhere_else`}
            source={`applicantPersonalInformation.${index}.lives_somewhere_else`}
            label="The site of installation is NOT my primary address"
            component={(checkboxProps: any) => <CheckboxInput {...checkboxProps} elStyle={{ maxWidth: '900px' }} />}
            fullWidth
            required
            style={{ width: '100%', margin: '5px 0px', padding: '0px' }}
          />
        </Grid>
        {formState.values?.applicantPersonalInformation?.[index]?.lives_somewhere_else && (
          <>
            <Grid item xs={12}>
              <CustomField
                name={`applicantPersonalInformation.${index}.primaryAddress`}
                source={`applicantPersonalInformation.${index}.primaryAddress`}
                label={'Street Address'}
                component={TextInput}
                fullWidth
                style={{ width: '100%', margin: '5px 0px', padding: '0px' }}
                required={true}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomField
                name={`applicantPersonalInformation.${index}.city`}
                source={`applicantPersonalInformation.${index}.city`}
                label={'City'}
                component={TextInput}
                fullWidth
                style={{ width: '100%', margin: '5px 0px', padding: '0px' }}
                required={true}
              />
            </Grid>
            <Grid item xs={3}>
              <CustomField
                name={`applicantPersonalInformation.${index}.state`}
                source={`applicantPersonalInformation.${index}.state`}
                label="State"
                component={TextInput}
                fullWidth
                style={{ width: '100%', margin: '5px 0px', padding: '0px' }}
                required={true}
              />
            </Grid>
            <Grid item xs={3}>
              <CustomField
                name={`applicantPersonalInformation.${index}.zipCode`}
                source={`applicantPersonalInformation.${index}.zipCode`}
                label="Zip Code"
                component={TextInput}
                fullWidth
                style={{ width: '100%', margin: '5px 0px', padding: '0px' }}
                required={true}
              />
            </Grid>
          </>
        )}
      </>
    )
  }

  const translate = useTranslate()
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <h2>Primary Borrower Information</h2>
      </Grid>
      {renderBorrowerFields(0)}
      <Grid item xs={12}>
        <h2>Co-Borrower Information</h2>
      </Grid>
      <Grid item xs={12}>
        <CustomField
          name="has_coborrower"
          source="has_coborrower"
          label="I'd like to add a co-borrower"
          component={(checkboxProps: any) => <CheckboxInput {...checkboxProps} elStyle={{ maxWidth: '900px' }} />}
          fullWidth
          required
          style={{ width: '100%', margin: '5px 0px', padding: '0px' }}
        />
      </Grid>
      <DependentInput dependsOn="has_coborrower" value={true}>
        {renderBorrowerFields(1)}
      </DependentInput>
      <Grid item xs={12}>
        <h4>Installation Site</h4>
      </Grid>
      <Grid item xs={12}>
        <CustomField
          name="installationAddress.streetAddress"
          source="installationAddress.streetAddress"
          label={'Street Address'}
          component={TextInput}
          fullWidth
          style={{ width: '100%', margin: '5px 0px', padding: '0px' }}
          required={true}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomField
          name="installationAddress.city"
          source="installationAddress.city"
          label={'City'}
          component={TextInput}
          fullWidth
          style={{ width: '100%', margin: '5px 0px', padding: '0px' }}
          required={true}
        />
      </Grid>
      <Grid item xs={3}>
        <CustomField
          name="installationAddress.state"
          source="installationAddress.state"
          label="State"
          component={TextInput}
          fullWidth
          style={{ width: '100%', margin: '5px 0px', padding: '0px' }}
          required={true}
        />
      </Grid>
      <Grid item xs={3}>
        <CustomField
          name="installationAddress.zipCode"
          source="installationAddress.zipCode"
          label="Zip"
          component={TextInput}
          fullWidth
          style={{ width: '100%', margin: '5px 0px', padding: '0px' }}
          required={true}
        />
      </Grid>
      <CustomField
        name="agreed_to_terms"
        source="agreed_to_terms"
        label="I agree to the terms and conditions listed below"
        component={(checkboxProps: any) => <CheckboxInput {...checkboxProps} elStyle={{ maxWidth: '900px' }} />}
        fullWidth
        required
        style={{ width: '100%', margin: '5px 0px', padding: '0px' }}
      />
      <>
        <div>
          {props.disclosures?.map((disc) => (
            <p className="small">{disc.content}</p>
          ))}
        </div>
      </>
    </Grid>
  )
}

export default EnFinApplicationForm

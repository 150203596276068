import { makeStyles } from '@material-ui/core'
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined'
import { Button } from 'opensolar-ui'
import React, { memo } from 'react'
import { useTranslate } from 'react-admin'
import restClient from 'restClient'

const restClientInstance = restClient(window.API_ROOT + '/api')

const useStyles = makeStyles((theme) => ({
  button: {
    position: 'fixed',
    bottom: 60,
    right: 20,
    // color: theme.headerFontColor,
    // background: theme.themeColor,
    // '&:hover': {
    //   background: theme.themeColorDark,
    // },
  },
}))

const downloadPDF = (blob, filename) => {
  const fakeLink = document.createElement('a')
  fakeLink.style.display = 'none'
  document.body.appendChild(fakeLink)
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // Manage IE11+ & Edge
    window.navigator.msSaveOrOpenBlob(blob, filename)
  } else {
    fakeLink.setAttribute('href', URL.createObjectURL(blob))
    fakeLink.setAttribute('download', filename)
    fakeLink.click()
  }
}

function download(url, filename) {
  if (window.RUNNING_AS_APP) {
    window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'downloadFile', url, filename, fileType: 'pdf' }))
  } else {
    fetch(url).then(function (t) {
      return t.blob().then((b) => {
        downloadPDF(b, filename)
      })
    })
  }
}

export const DownloadButton = memo(
  ({ downloadUrl, fileName, orgId, privateFileId, setErrorMessage, onDownloadFinish }) => {
    const translate = useTranslate()
    const classes = useStyles()
    return (
      <Button
        classes={{ root: classes.button }}
        startIcon={<DescriptionIcon />}
        onClick={() => {
          // restClientInstance('UPDATE', 'private_files', {
          //   id: privateFileId,
          //   data: { temporary: 0 },
          // })
          restClientInstance('CUSTOM_PATCH', 'custom', {
            url: 'orgs/' + orgId + '/private_files/' + privateFileId + '/',
            id: privateFileId,
            data: { temporary: 0 },
          })
            .then((response) => {
              //Download a PDF File forcefully instead of opening it in a new tab
              download(downloadUrl, fileName)
              onDownloadFinish()
            })
            .catch((e) => {
              setErrorMessage(e.message)
            })
        }}
        variant="contained"
        color="primary"
      >
        <span>{translate('DOWNLOAD PDF')}</span>
      </Button>
    )
  }
)

const _ = require('lodash')

const formatDesignSubmitValues = (submitValues: any, formValues: any, dirtyFields: any[]) => {
  if (dirtyFields.includes('design')) {
    const designData = window.editor.sceneAsJSON()
    submitValues.design = window.CompressionHelper.compress(JSON.stringify(designData))
  }

  return submitValues
}

export default formatDesignSubmitValues

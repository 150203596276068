import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { addAccordionFormsAndSort } from 'customForm/card/addAccordionFormsAndSort'
import useGetCustomForms from 'customForm/hooks/useGetCustomForms'
import { authSelectors } from 'ducks/auth'
import { permissionsSelectors } from 'ducks/permissions'
import { useMemo } from 'react'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { FormPlacementEnum } from 'resources/customForms/types'
import { RenderablePanel } from 'types/renderablePanel'
import { urlToId } from 'util/misc'
import { useFeatureFlag } from 'util/split'
import getProjectSectionStyles from '../../styles'
import SectionContainer from '../SectionContainer'
import ProjectCTAs from '../info/ProjectCTAs'
import Assignment from './assignment'
import DesignedReplay from './designedReplay'
import HardwarePromo from './hardwarePromo/HardwarePromo'
import PlannedActivity from './plannedActivity'
import ProjectHistory from './projectHistory'
import ProjectManagementAccordion from './projectManagement'
import ScaffoldTile from './scaffoldTile'

const useStyles = makeStyles(getProjectSectionStyles)

const Manage = () => {
  const classes = useStyles()
  const isStaff: boolean = useSelector(authSelectors.getIsStaff)
  const formValues = useFormState().values
  const enablePvf = useFeatureFlag('pvf_integration', 'on')

  const isSuperUser: boolean = useSelector(authSelectors.getIsSuperUser)
  const customForms = useGetCustomForms({ location: FormPlacementEnum.manage_page })

  const { allowView } = useSelector(permissionsSelectors.getProjectPermissionByKey('manage'))

  const sortAndRenderComponents = useMemo(() => {
    let toRender: RenderablePanel[] = [
      {
        component: ProjectManagementAccordion,
        shouldRender: true,
        priority: 10,
        props: {},
      },
      {
        component: ProjectHistory,
        shouldRender: true,
        priority: 20,
        props: {},
      },
    ]
    return addAccordionFormsAndSort(toRender, customForms)
  }, [customForms])

  if (!allowView) {
    return null
  }

  return (
    <SectionContainer section="manage" mode="background">
      <div className={classes.formPage}>
        <div className={classes.sectionWrapper}>
          <Grid container spacing={3}>
            <Grid xs={12} sm={8} item>
              {sortAndRenderComponents}
            </Grid>
            <Grid xs={12} sm={4} item>
              {formValues != null && (
                <HardwarePromo
                  projectId={formValues.id}
                  systems={formValues.systems}
                  soldSystemId={urlToId(formValues.system_sold)}
                />
              )}
              {enablePvf && <ScaffoldTile />}
              <ProjectCTAs />
              <Assignment />
              <PlannedActivity />
              {isStaff && isSuperUser && <DesignedReplay />}
            </Grid>
          </Grid>
        </div>
      </div>
    </SectionContainer>
  )
}
export default Manage

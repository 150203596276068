import { InfoOutlined, LockOutlined } from '@material-ui/icons'
import { Typography } from 'opensolar-ui'
import { FC, memo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type Props = {
  title: string
  subtitle?: string
  severity: Severities
}

type Severities = 'success' | 'info'

type StyleProps = {
  severity: Severities
}

const severityPalette = {
  success: {
    borderColor: '#91DDAD',
    backgroundColor: '#EBFBF1',
    fontColor: '#016626',
    icon: LockOutlined,
  },
  info: {
    borderColor: '#D6E0F7',
    backgroundColor: '#F5F8FD',
    fontColor: '#113B98',
    icon: InfoOutlined,
  },
}

const useStyles = makeOpenSolarStyles((theme) => ({
  mainContainer: (props: StyleProps) => ({
    display: 'flex',
    flexDirection: 'row',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: severityPalette[props.severity].borderColor,
    borderRadius: 4,
    padding: '8px',
    background: severityPalette[props.severity].backgroundColor,
    alignItems: 'flex-start',
  }),
  iconContainer: {
    marginRight: '8px',
  },
  icon: (props: StyleProps) => ({
    fontSize: '20px',
    color: severityPalette[props.severity].fontColor,
  }),
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

const Alert: FC<Props> = ({ title, subtitle, severity }) => {
  const classes = useStyles({ severity })
  const Icon = severityPalette[severity].icon
  return (
    <div className={classes.mainContainer}>
      <div className={classes.iconContainer}>
        <Icon className={classes.icon} />
      </div>
      <div className={classes.textContainer}>
        <Typography textVariant="body1Bold" colorHex={severityPalette[severity].fontColor as `#${string}`}>
          {title}
        </Typography>
        {subtitle && (
          <Typography textVariant="body1" colorHex={severityPalette[severity].fontColor as `#${string}`}>
            {subtitle}
          </Typography>
        )}
      </div>
    </div>
  )
}

export default memo(Alert)

import { Divider, Typography } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import _ from 'lodash'
import { MILESTONE_CHOICES } from 'resources/projects/choices'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import InventoryFilterButton from '../filter/InventoryFilterButton'
import InventoryFilterSelect from '../filter/InventoryFilterSelect'
import { TIME_PERIOD_INVENTORY_FILTER_OPTIONS } from '../filter/constants'
import type { InventoryFilterKeyType, InventoryFiltersType } from '../filter/type'
import type { UpdateFilterValueArgs } from '../filter/useInventoryFilter'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  filter: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  subFilter: { display: 'flex', alignItems: 'center' },
  text: {
    color: theme.greyMid1,
    marginLeft: 5,
  },
}))

const InventoryDashboardFilter = ({
  filterValues,
  onFilterUpdate,
  disabled,
}: {
  filterValues: InventoryFiltersType
  onFilterUpdate<T extends InventoryFilterKeyType>(args: UpdateFilterValueArgs<T>): void
  disabled: boolean
}) => {
  const classes = useStyles()
  const getLogAction = () => {
    if (filterValues['include_forecast_project'] === false) return 'inventory_dashboard_filter_removed'
    else return 'inventory_dashboard_filter_added'
  }

  return (
    <div className={classes.filter}>
      <InventoryFilterSelect
        label={'Time Period'}
        onUpdate={onFilterUpdate}
        value={filterValues['time_period']}
        field={'time_period'}
        options={TIME_PERIOD_INVENTORY_FILTER_OPTIONS}
        disabled={disabled}
      />
      <div className={classes.subFilter}>
        <Typography variant="body2" className={classes.text}>
          Filter
        </Typography>
        {MILESTONE_CHOICES.map((choice) => {
          const selected = !!filterValues['milestones']?.includes(choice.milestoneUrl)
          const handleClick = () => {
            const milestones = filterValues['milestones'] || []

            if (selected) {
              // remove
              onFilterUpdate({ key: 'milestones', value: _.without(milestones, choice.milestoneUrl) })
              logAmplitudeEvent('generic_filter_interacted', {
                action: 'inventory_dashboard_filter_stage_removed',
                name: choice.name,
              })
            } else {
              //add
              if (milestones.length > 0) milestones.shift()
              milestones.push(choice.milestoneUrl)
              onFilterUpdate({ key: 'milestones', value: milestones })
              logAmplitudeEvent('generic_filter_interacted', {
                action: 'inventory_dashboard_filter_stage_added',
                name: choice.name,
              })
            }
          }

          return (
            <InventoryFilterButton
              key={choice.id}
              selected={selected}
              label={choice.name}
              handleClick={handleClick}
              disabled={disabled}
            />
          )
        })}
      </div>

      <Divider orientation="vertical" flexItem />

      <div className={classes.subFilter}>
        <InventoryFilterButton
          selected={filterValues['include_forecast_project'] === false}
          label={'Excluded only'}
          handleClick={() => {
            onFilterUpdate({
              key: 'include_forecast_project',
              value: filterValues['include_forecast_project'] === false ? undefined : false,
            })
            logAmplitudeEvent('generic_filter_interacted', {
              action: getLogAction(),
              name: 'Excluded only',
            })
          }}
          disabled={disabled}
        />
        <InventoryFilterButton
          selected={!!filterValues['has_install_date']}
          label={'Has install date'}
          handleClick={() => {
            onFilterUpdate({ key: 'has_install_date', value: !filterValues['has_install_date'] })
            logAmplitudeEvent('generic_filter_interacted', {
              action: !filterValues['has_install_date']
                ? 'inventory_dashboard_filter_added'
                : 'inventory_dashboard_filter_removed',
              name: 'Has install date',
            })
          }}
          disabled={disabled}
        />
      </div>
    </div>
  )
}

export default InventoryDashboardFilter

import { orgSelectors } from 'ducks/orgs'
import React from 'react'
import { SelectInput } from 'react-admin'
import { useSelector } from 'react-redux'
import styles from 'styles'

interface PropTypes {
  label: string
  source: string
}

const SharedFromInput: React.FC<PropTypes> = ({ label, source }) => {
  const connectedOrgs = useSelector(orgSelectors.getConnectedOrgs)
  if (!!!connectedOrgs.length) {
    return <></>
  }
  return (
    <SelectInput
      label={label || 'Shared From'}
      choices={connectedOrgs}
      optionText={'org_name'}
      optionValue={'org_id'}
      options={{
        style: styles.FILTER_FIELD_STYLE,
        placeholder: 'Enter an org name',
      }}
      source={source || 'owned_by'}
    />
  )
}

export default SharedFromInput

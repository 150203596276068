import { createContext, useEffect, useMemo, useState } from 'react'
import { useDataProvider, useRefresh } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { PrivateFileType } from 'types/privateFile'
import { CRUDFilesDialogs } from './dialogs/CRUDFilesDialogs'

export type FileDialogType =
  | 'upload_file'
  | 'upload_site_photos'
  | 'preview_file'
  | 'edit_file'
  | 'merge_pdfs'
  | 'generate_file'
  | 'share_upload_link'

export interface FilesContextProps {
  dialogSelected: FileDialogType | null
  recordSelected: PrivateFileType | null
  recordsArraySelected: PrivateFileType[]
  setRecordsArraySelected: (rs: PrivateFileType[]) => void
  setDialogSelected: (dialogId: FileDialogType | null) => void
  setRecordSelected: (record: PrivateFileType | null) => void
  onSuccess: (p?: unknown) => void
  onDismiss: () => void
  projectUrl: string
  projectId: string
  orgId: any
}

export const FilesContext = createContext<FilesContextProps | undefined>(undefined)

export const FilesContextWrapper: React.FC = ({ children }) => {
  const form = useForm()
  const [fileListInvalid, setFileListInvalid] = useState<boolean>(false)
  const [dialogSelected, setDialogSelected] = useState<FileDialogType | null>(null)
  const [recordSelected, setRecordSelected] = useState<PrivateFileType | null>(null)
  const [recordsArraySelected, setRecordsArraySelected] = useState<PrivateFileType[]>([])
  const dataProvider = useDataProvider()
  const refresh = useRefresh()
  const { values: formValues } = useFormState() as any
  const { url: projectUrl, id: projectId, org_id: orgId } = formValues

  const onSuccess = (p?: unknown) => {
    setFileListInvalid(true)
  }

  const onDismiss = () => {
    setDialogSelected(null)
    setRecordSelected(null)
  }

  useEffect(() => {
    if (fileListInvalid) {
      const fetchData = async () => {
        try {
          const { data: updatedList } = await dataProvider.getList('private_files', {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'id', order: 'ASC' },
            filter: { project: projectId },
          })
          form.mutators.updateFieldSilently('private_files_data', updatedList)
        } catch (fetchError) {
          console.error(fetchError)
        } finally {
          setFileListInvalid(false)
          refresh()
        }
      }
      fetchData()
    }
    return () => {
      setFileListInvalid(false)
    }
  }, [dataProvider, fileListInvalid])

  const filesContext: FilesContextProps = {
    dialogSelected,
    recordSelected,
    setDialogSelected,
    setRecordSelected,
    onSuccess,
    onDismiss,
    projectUrl,
    projectId,
    orgId,
    recordsArraySelected,
    setRecordsArraySelected,
  }

  const CRUDDialogs = useMemo(() => {
    return <>{dialogSelected && <CRUDFilesDialogs />}</>
  }, [dialogSelected])

  return (
    <FilesContext.Provider value={filesContext}>
      {children}
      {CRUDDialogs}
    </FilesContext.Provider>
  )
}

import { push as pushAction } from 'connected-react-router'
import { Button } from 'opensolar-ui'
import React, { useMemo } from 'react'
import {
  AutocompleteInput,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  FilterComp,
  ReferenceInput,
  SaveButton,
  SelectInput,
  showNotification as showNotificationAction,
  SimpleForm,
  TextField,
  Toolbar,
  withTranslate,
} from 'react-admin'
import { clone, getRoleFromState } from 'util/misc'
import {
  DEFAULT_TARIFF_DATA,
  ExtraFieldByDistributionRule,
  isReadOnly,
  SECTOR_RESIDENTIAL,
  utilityHasNEM3,
  UTILITY_TARIFF_BILL_FREQUENCY_CHOICES,
  UTILITY_TARIFF_SECTOR_CHOICES,
} from './common'

import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'

import AlertError from '@material-ui/icons/ErrorOutlineOutlined'

import { Link } from 'react-router-dom'

import { duplicate as duplicateAction } from '../../actions/restActions'
import EditOrImportButton from '../../elements/button/EditOrImportButton'

import compose from 'recompose/compose'

import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { permissionsSelectors } from 'ducks/permissions'
import { List } from 'elements/react-admin/List'
import { parseSchedules } from 'elements/scheduleDialog/utils'
import { getOrgCountryCodeFromState } from 'util/org'
import PaginationWithActions from '../../elements/PaginationWithActions'
import TariffInputs from './TariffInputs'

const csvFloatsToArray = (csvFloats) => {
  if (!csvFloats) {
    return []
  } else if (typeof csvFloats === 'string') {
    return csvFloats.split(',').map((v) => parseFloat(v.trim()))
  } else {
    return csvFloats
  }
}

export const UtilityTariffFilter = (props) => (
  <FilterComp {...props}>
    <SelectInput
      label={'Tariff Dataset Type'}
      source="tariff_dataset"
      choices={[
        { id: 'my_custom_tariffs', name: 'My Custom Tariffs' },
        { id: 'os_managed_tariffs', name: 'OpenSolar Managed Tariffs' },
        { id: 'all', name: 'All Tariffs' },
      ]}
    />

    <ReferenceInput source="country" label="Country" reference="countries" sort={{ field: 'name', order: 'ASC' }}>
      <AutocompleteInput options={{ placeholder: 'Enter a country' }} optionText="name" optionValue="id" />
    </ReferenceInput>
    <ReferenceInput source="utility" label="Utility" reference="utilities" sort={{ field: 'name', order: 'ASC' }}>
      <AutocompleteInput options={{ placeholder: 'Utility' }} optionText="name" optionValue="id" />
    </ReferenceInput>
    <ReferenceInput
      source="distributor"
      label="Distributor"
      reference="distributors"
      sort={{ field: 'name', order: 'ASC' }}
    >
      <AutocompleteInput options={{ placeholder: 'Distributor' }} optionText="name" optionValue="id" />
    </ReferenceInput>
    <SelectInput
      source="popularity_filter"
      label={'Popularity Filter'}
      choices={[
        { id: 'min', name: 'Min (0)' },
        { id: 'low', name: 'Low (1 - 19)' },
        { id: 'medium', name: 'Medium (20 - 59)' },
        { id: 'high', name: 'High (60 - 99)' },
        { id: 'max', name: 'Max (100)' },
      ]}
    />
    <SelectInput source="sector" label={'Sector'} choices={UTILITY_TARIFF_SECTOR_CHOICES} />
  </FilterComp>
)

export const UtilityTariffList = connect(
  (state) => {
    return {
      org_id: state.auth ? state.auth.org_id : null,
      is_staff: state.auth.is_staff,
    }
  },
  { duplicate: duplicateAction }
)(({ accessRights: { allowView, allowCreate, allowEdit, allowDelete }, ...props }) => {
  const isAdmin = useSelector((state) => Boolean(getRoleFromState(state)?.is_admin))

  return (
    <List
      pagination={
        props.is_staff ? (
          <PaginationWithActions
            uploadModel={'utility_tariffs'}
            downloadUrl={
              window.API_BASE_URL + 'orgs/' + props.org_id + '/utility_tariffs/?fieldset=csv&format=csv&ordering=-id'
            }
            downloadFilename="OpenSolarUtilityTariffs"
          />
        ) : undefined
      }
      hasSearch={true}
      filters={<UtilityTariffFilter is_staff={props.is_staff} />}
      perPage={20}
      {...props}
      hasCreate={allowCreate}
    >
      <Datagrid>
        <TextField source="utility_name" label={'Utility'} />
        <TextField source="name" label={'Name'} />
        <TextField source="code" label={'Code'} />
        <EditOrImportButton
          sortable={false}
          source="actions"
          org_id={props.org_id}
          resource="utility_tariff"
          duplicate={props.duplicate}
          isAdmin={isAdmin}
          allowEdit={allowEdit}
          allowDelete={allowDelete}
        />
      </Datagrid>
    </List>
  )
})

export const UtilityTariffCreate = (props) => {
  const user = useSelector(authSelectors.getCurrentUser)
  const { allowCreate } = useSelector(permissionsSelectors.getPermissionByKey('utility_tariffs'))

  const orgCountry = useSelector(orgSelectors.getOrg)?.country
  const defaultCountry = useMemo(() => {
    return { url: `${window.API_ROOT}/api/countries/${orgCountry?.id}/`, iso2: orgCountry?.iso2 }
  }, [orgCountry])

  return (
    <Create {...props}>
      <SimpleForm
        defaultValue={(a1) => defaultValueFunc(a1, null, defaultCountry)}
        hideCancelButton={!!props.hideCancelButton}
        toolbar={props.toolbar}
        formatSubmitValues={(values) => {
          return formatSubmitValues(values, user.email)
        }}
        disabled={!allowCreate}
      >
        <TariffInputs isEdit={false} />
      </SimpleForm>
    </Create>
  )
}

export const defaultValueFunc = function (record, customisedTariffData, defaultCountry) {
  if (customisedTariffData) {
    record.data = customisedTariffData
  }

  if (!record.country && defaultCountry?.url) {
    record.country = defaultCountry?.url
    record.country_iso2 = defaultCountry?.iso2
  }

  if (!record?.data || record?.data?.length === 0) {
    record.data = JSON.stringify(DEFAULT_TARIFF_DATA)
  }

  var tariffData = typeof record.data === 'string' ? JSON.parse(record.data) : record.data
  //parse schedules
  tariffData = parseSchedules(tariffData)

  if (!tariffData) {
    tariffData = clone(DEFAULT_TARIFF_DATA)
  }

  //get tariff fields from tariffData for customised tariff
  const data = {
    name: record?.name ? record?.name : tariffData?.name,
    code: record?.code ? record?.code : tariffData?.code,
    valid_from: record?.valid_from,
    valid_to: record?.valid_to,
    sector: record?.sector ? record?.sector : tariffData?.applicability?.sector,
    country: record?.country,
    distributor: record?.distributor,
    utility: record?.utility,
    is_latest: record?.is_latest,
    is_public_read_only: record?.is_public_read_only,
    popularity: record?.popularity,
    country_iso2: record?.country_iso2 || defaultCountry?.iso2,
    transition_to_utility_tariff: record?.transition_to_utility_tariff,
    test_cases: record?.test_cases,
    has_minimum_charge: record?.has_minimum_charge ? record?.has_minimum_charge : tariffData?.has_minimum_charge,
    has_minimum_export_credit: record?.has_minimum_export_credit
      ? record?.has_minimum_export_credit
      : tariffData?.has_minimum_export_credit,
  }

  if (tariffData?.solar_compensation_settings?.apply_nem3_export_rates) {
    tariffData.export_credits = []
  }

  data.tariffData = tariffData
  return data
}

export const formatSubmitValues = (values, userEmail = '', stringifyData = true) => {
  const tariffData = {
    version: '1.0',
    name: values.name,
    code: values.code,
    aliases: values.aliases,
    bill_frequency: values['tariffData']
      ? values['tariffData'].bill_frequency
      : UTILITY_TARIFF_BILL_FREQUENCY_CHOICES[0].id,
    description: values['tariffData'] ? values['tariffData'].description : '',
    applicability: {
      ...values['tariffData'].applicability,
      sector: !isNaN(values?.sector) ? values.sector : SECTOR_RESIDENTIAL,
      popularity: !isNaN(values?.popularity) ? values.popularity : 10,
    },
    solar_compensation_settings: values?.['tariffData']?.solar_compensation_settings
      ? values['tariffData']?.solar_compensation_settings
      : {},
    tax_settings: values['tariffData'] ? values['tariffData'].tax_settings : 'inclusive',
    tax_percentage: Math.min(Math.max(values['tariffData']?.tax_percentage ?? 0, 0), 100),
    has_minimum_charge: values?.has_minimum_charge,
    has_minimum_export_credit: values?.has_minimum_export_credit,
    minimum_charge: values['tariffData'] ? values['tariffData'].minimum_charge : null,
    criteria: values['tariffData'] ? values['tariffData'].criteria : null,
    price_tiering_period: values['tariffData'] ? values['tariffData'].price_tiering_period : 'per_billing_period',
    energy_charges: values['tariffData'] ? values['tariffData'].energy_charges : [],
    demand_charges: values['tariffData'] ? values['tariffData'].demand_charges : [],
    fixed_charges: values['tariffData'] ? values['tariffData'].fixed_charges : [],
    export_credits: values['tariffData'] ? values['tariffData'].export_credits : [],
    pv_capacity_charges: values['tariffData'] ? values['tariffData'].pv_capacity_charges : [],
    controlled_load_charges: values['tariffData'] ? values['tariffData'].controlled_load_charges : [],
    percentage_adjustments_charges: values['tariffData'] ? values['tariffData'].percentage_adjustments_charges : [],
    percentage_adjustments_discounts: values['tariffData'] ? values['tariffData'].percentage_adjustments_discounts : [],
    modified_date: new Date(),
    modified_by_role_email: userEmail ? userEmail : null,
    modified_is_public_read_only_by_role_email: userEmail ? userEmail : null,
    // ui_message_key: values?.['tariffData']?.ui_message_key ? values?.['tariffData']?.ui_message_key : '',
  }

  if (!values?.has_minimum_charge) {
    tariffData.minimum_charge = null
  }

  if (!values?.has_minimum_export_credit) {
    tariffData.solar_compensation_settings.minimum_annual_export_credit = null
  }

  for (const key in tariffData.solar_compensation_settings) {
    if (
      (key !== 'distributed_generation_rules' && key !== 'name') &
      !ExtraFieldByDistributionRule[tariffData.solar_compensation_settings.distributed_generation_rules]?.includes(key)
    )
      delete tariffData.solar_compensation_settings[key]
  }

  if (tariffData?.solar_compensation_settings?.apply_nem3_export_rates) {
    tariffData.export_charges = []
  }

  if (tariffData?.solar_compensation_settings?.minimum_annual_export_credit?.value_by_year)
    tariffData.solar_compensation_settings.minimum_annual_export_credit.value_by_year = csvFloatsToArray(
      tariffData.solar_compensation_settings.minimum_annual_export_credit.value_by_year
    )

  if (!utilityHasNEM3(values.utility_name)) {
    if (tariffData?.applicability?.is_low_income) {
      delete tariffData.applicability.is_low_income
    }
    if (tariffData?.solar_compensation_settings?.apply_nem3_export_rates) {
      delete tariffData.solar_compensation_settings.apply_nem3_export_rates
    }
  }

  const dataString = JSON.stringify(tariffData)
  values.data = stringifyData ? dataString : tariffData

  return values
}

const mapStateToPropsEdit = (state) => {
  return {
    is_staff: state.auth ? state.auth.is_staff : false,
    org_id: state.auth ? state.auth.org_id : null,
    orgCountryCode: getOrgCountryCodeFromState(state),
    email: state.auth?.user ? state.auth.user.email : null,
  }
}

export const isCopyingNotYetSaved = () => {
  //@TODO: Remove nasty hack using window.location to detect querystring
  try {
    return window.location.hash.indexOf('utility_tariff_id=') > -1
  } catch (e) {
    //
  }
  return false
}

const CreateEditToolbar = (props) => (
  <Toolbar {...props}>
    {!isCopyingNotYetSaved() &&
      (props.resource === 'custom_tariff_current' || props.resource === 'custom_tariff_proposed') && (
        <DeleteButton
          label={props.translate('Clear Custom Tariff')}
          style={{ color: '#4d4d4d', justifyContent: 'flex-start', margin: 0, padding: 6 }}
          resource={props.resource}
          redirect={'/projects/' + props.record.id}
          undoable={false}
        />
      )}
    {props.resource !== 'custom_tariff_current' && (
      <Link style={{ textDecoration: 'none' }} to={`/${props.resource}`}>
        <Button
          startIcon={<AlertError />}
          style={{ margin: 10, position: 'relative' }}
          variant="contained"
          color="default"
        >
          <span>{props.translate('Cancel')}</span>
        </Button>
      </Link>
    )}
    {isCopyingNotYetSaved() && props.resource === 'custom_tariff_current' && (
      <Link style={{ textDecoration: 'none' }} to={'/projects/' + props.record.id}>
        <Button
          startIcon={<AlertError />}
          variant="contained"
          color="default"
          style={{ margin: 10, position: 'relative' }}
        >
          <span>{props.translate('Cancel')}</span>
        </Button>
      </Link>
    )}
    <SaveButton
      redirect={false}
      submitOnEnter={true}
      raised={true}
      disabled={props.record.is_public_read_only === true && isReadOnly(props.record, props.org_id)}
    />
  </Toolbar>
)

const DisableFormContent = ({ record, org_id }) => {
  return isReadOnly(record, org_id) ? (
    <div style={{ position: 'absolute', width: '100%', height: '100%', top: '2%', cursor: 'not-allowed', zIndex: 2 }} />
  ) : null
}

class _UtilityTariffEdit extends React.Component {
  isCustom() {
    return this.props.resource === 'custom_tariff_current' || this.props.resource === 'custom_tariff_proposed'
  }

  render() {
    const {
      translate,
      toolbar,
      accessRights: { allowEdit, allowDelete },
    } = this.props
    const showToolBar = allowEdit || this.props.resource !== 'utility_tariffs'
    const customToolbar = toolbar || (
      <CreateEditToolbar org_id={this.props.org_id} resource={this.props.resource} translate={translate} />
    )
    return (
      <Edit
        {...this.props}
        hasDelete={(record) => {
          if (!record || this.isCustom()) {
            return false
          } else {
            return allowDelete && !isReadOnly(record, this.props.org_id)
          }
        }}
      >
        <SimpleForm
          defaultValue={defaultValueFunc}
          hideCancelButton={!!this.props.hideCancelButton}
          contentClassName="utility-tariff-form-content"
          formatSubmitValues={(values) => {
            return formatSubmitValues(values, this.props.email)
          }}
          toolbar={showToolBar ? customToolbar : null}
          disabled={!allowEdit}
        >
          {!this.isCustom() && <DisableFormContent org_id={this.props.org_id} />}
          <TariffInputs disabled={!allowEdit} isEdit={true} />
        </SimpleForm>
      </Edit>
    )
  }
}

_UtilityTariffEdit.propTypes = {
  showNotification: PropTypes.func,
  push: PropTypes.func,
  record: PropTypes.object,
}

export const UtilityTariffEdit = compose(
  withTranslate,
  connect(mapStateToPropsEdit, {
    showNotification: showNotificationAction,
    push: pushAction,
  })
)(_UtilityTariffEdit)

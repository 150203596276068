import { SplitFactory } from '@splitsoftware/splitio'
import { authSelectors } from 'ducks/auth'
import { rolesSelectors } from 'ducks/auth_roles'
import { splitSelectors, SPLIT_NAMES } from 'ducks/split'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'types/state'
import { cypressFeatureFlags } from './split.cypress'

const sessionKey = Math.round(Math.random() * 1000000)

const AUTH_KEY = window.SPLIT_IO_AUTH_KEY

export const initSplit = (orgId: number | undefined, isStaff: boolean, isAdmin: boolean) => {
  let key = sessionKey + ''
  if (orgId) {
    key = `${orgId}-${isStaff ? 1 : 0}-${isAdmin ? 1 : 0}`
  }
  const isCypressTest = window.ENV === 'cypress'
  const splitSettings: SplitIO.IBrowserSettings = {
    core: {
      authorizationKey: isCypressTest ? 'localhost' : AUTH_KEY,
      key,
    },
    startup: {
      readyTimeout: 1.5, //seconds
    },
  }
  if (isCypressTest) {
    splitSettings.features = cypressFeatureFlags
  }
  const factory: SplitIO.ISDK = SplitFactory(splitSettings)
  // And get the client instance you'll use
  const client: SplitIO.IClient = factory.client()
  return client
}

export type AttributeMapType = {
  roleId: number | undefined
  userId: number | undefined
  orgId: number | undefined
  orgCountry: string | undefined
  isStaff: boolean
  isAdmin: boolean
  isCustomer: boolean
}

export const useSplitAttributeMap = (): { attributes: AttributeMapType; cache_key: string } => {
  const roleId = useSelector(rolesSelectors.getCurrentRoleId)
  const userId = useSelector(authSelectors.getCurrentUserId) || -1
  const orgId = useSelector(authSelectors.getOrgId) || -1
  const orgCountry = useSelector(authSelectors.getCurrentOrgCountry) || ''
  const isStaff = useSelector(authSelectors.getIsStaff)
  const isAdmin = useSelector(authSelectors.getIsAdmin)

  const attributes = useMemo((): AttributeMapType => {
    return roleId === undefined
      ? {
          roleId: undefined,
          userId: undefined,
          orgId: undefined,
          orgCountry: undefined,
          isStaff: false,
          isAdmin: false,
          isCustomer: true,
        }
      : {
          roleId,
          userId,
          orgId,
          orgCountry,
          isStaff,
          isAdmin,
          isCustomer: false,
        }
  }, [roleId, userId, orgId, orgCountry, isStaff, isAdmin])

  const cache_key = useMemo(() => {
    return JSON.stringify(attributes)
  }, [attributes])

  return {
    attributes,
    cache_key,
  }
}

let last_cache_key: string = ''
let cached_values: Record<string, string> = {}
const checkClientWithCache = (
  client: any,
  splitIsReady: boolean,
  cache_key: string,
  attributes: AttributeMapType,
  split_name: SPLIT_NAMES
): string | undefined => {
  if (last_cache_key !== cache_key) {
    last_cache_key = cache_key
    cached_values = {}
  } else if (cached_values[split_name]) {
    return cached_values[split_name]
  }
  if (splitIsReady && client?.getTreatment) {
    const value = client.getTreatment(split_name, attributes)
    cached_values[split_name] = value
    return value
  }
  return undefined
}
// custom hook for functional components
export const useFeatureFlag = (split_name: SPLIT_NAMES, target_value: string): boolean | undefined => {
  const splitIsReady = useSelector(splitSelectors.getSplitIsReady)
  const { attributes, cache_key } = useSplitAttributeMap()
  const client = useSelector(splitSelectors.getClient)

  let initial_value = checkClientWithCache(client, splitIsReady, cache_key, attributes, split_name)
  const [isEnabled, setIsEnabled] = useState<boolean | undefined>(
    splitIsReady ? initial_value === target_value : undefined
  )

  useEffect(() => {
    if (!splitIsReady) return
    const value = checkClientWithCache(client, splitIsReady, cache_key, attributes, split_name)
    setIsEnabled(value === target_value)
  }, [attributes, split_name, target_value, splitIsReady])

  return isEnabled
}

type FeatureChecker = (split_name: SPLIT_NAMES, target_value?: string) => boolean | undefined

// For immediate resolution of a dynamic split_name (acts as a utility for studio)
export const useFeatureFlagDynamic = (): FeatureChecker => {
  const splitIsReady = useSelector(splitSelectors.getSplitIsReady)
  const { attributes, cache_key } = useSplitAttributeMap()
  const client = useSelector(splitSelectors.getClient)

  return (split_name: SPLIT_NAMES, target_value?: string): boolean | undefined => {
    if (!splitIsReady) return undefined
    if (!target_value) target_value = 'on'

    const value = checkClientWithCache(client, splitIsReady, cache_key, attributes, split_name)
    return value === target_value
  }
}

// For use from as a selector
export const getFeatureFlagSelector = (state: RootState) => {
  const splitIsReady = splitSelectors.getSplitIsReady(state)

  const client = splitSelectors.getClient(state)
  const roleId = rolesSelectors.getCurrentRoleId(state)
  const userId = authSelectors.getCurrentUserId(state) || -1
  const orgId = authSelectors.getOrgId(state) || -1
  const orgCountry = authSelectors.getCurrentOrgCountry(state) || ''
  const isStaff = authSelectors.getIsStaff(state)
  const isAdmin = authSelectors.getIsAdmin(state)

  const attributeMap: AttributeMapType = {
    roleId,
    userId,
    orgId,
    orgCountry,
    isStaff,
    isAdmin,
    isCustomer: roleId == null,
  }

  return (splitName: string, targetValue: string): boolean | undefined => {
    if (!splitIsReady) return undefined
    const value = client?.getTreatment(splitName, attributeMap)
    return targetValue === value
  }
}

// @ts-nocheck
import { Card, CardHeader } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { useCallback } from 'react'
import { useTranslate } from 'react-admin'
import { useHistory } from 'react-router-dom'
import useTranslateParse from 'util/useTranslateParse'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: { margin: '20px 0', width: '100%' },
  heading: { color: '#000000', fontSize: 15, lineHeight: '17px', fontWeight: 700, marginBottom: 10 },
  link: {
    color: theme.blue,
    cursor: 'pointer',
    '&:hover': {
      color: theme.blue,
    },
  },
}))

/* <Card style={{ margin: '20px 0', width: '100%' }}>
          <CardHeader
            title={<h2>{translate('Component Costs')}</h2>}
            subheader={
              <p>
                {translate(
                  'To set component costs, go to the Design & Hardware section and add the costs for each component.'
                )}{' '}
                &nbsp;
                <Link
                  to={'/project_configurations'}
                  style={{ textDecoration: 'none', whiteSpace: 'nowrap', color: 'rgb(24, 144, 255)' }}
                >
                  {translate('Link To Page')}
                </Link>
              </p>
            }
          />
        </Card> */

const GeneralHelperCard = () => {
  const classes = useStyles()
  const history = useHistory()
  const translate = useTranslate()
  const translateParse = useTranslateParse()
  const handleRedirect = useCallback((redirectLink) => {
    history.push(redirectLink)
  }, [])
  return (
    <Card classes={{ root: classes.wrapper }}>
      <CardHeader
        title={<h2>{translate('Component Costs')}</h2>}
        subheader={
          <p>
            {translate(
              'To set component costs, go to the Design & Hardware section and add the costs for each component.'
            )}{' '}
            {translateParse(
              'Link to Page for <1>Modules<1>, <2>Inverters<2>, <3>Batteries<3> and <4>Other Components<4>.',
              {
                1: (label: string) => (
                  <span className={classes.link} onClick={() => handleRedirect('/component_module_activations')}>
                    {label}
                  </span>
                ),
                2: (label: string) => (
                  <span className={classes.link} onClick={() => handleRedirect('/component_inverter_activations')}>
                    {label}
                  </span>
                ),
                3: (label: string) => (
                  <span className={classes.link} onClick={() => handleRedirect('/component_battery_activations')}>
                    {label}
                  </span>
                ),
                4: (label: string) => (
                  <span className={classes.link} onClick={() => handleRedirect('/component_other_activations')}>
                    {label}
                  </span>
                ),
              }
            )}
          </p>
        }
      />
    </Card>
  )
}

export default GeneralHelperCard

// @ts-nocheck
import { Dialog, DialogContent, makeStyles } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { Button } from 'opensolar-ui'
import React, { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import restClient from 'restClient'
import { PaymentOptionType } from '../../../types/paymentOptions'
import { ProjectType } from '../../../types/projects'
import SunlightTransferChoice from './SunlightTransferChoice'
import { PaymentOptionSunlightQuickCreateType, SystemDesignSunlightQuickCreateType } from './types'

let restClientInstance = restClient(window.API_ROOT + '/api')
const CONFIG = {
  logo: 'logo-sunlight-financial.png',
  logo_small: 'logo-sunlight-financial-small.png',
  studio_banner_title: 'Simplify Your Sale!',
  name: 'sunlight',
  themeColor: 'rgb(254, 184, 22)',
  themeColorDark: 'rgb(232, 143, 36)',
}

const useStylesWhiteButton = makeStyles({
  label: {
    padding: '0px 0px 0px 10px',
    fontSize: 13,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'flex',
  },
  endIcon: {
    margin: 0,
  },
  root: {
    border: '1px solid #212121',
    lineHeight: '32px',
    textAlign: 'start',
    height: 35,
    padding: '0 5px',
    marginTop: '10px',
    width: '100%',
    justifyContent: 'flex-start',
  },
})

type PropTypes = {
  project: ProjectType
  designs?: SystemDesignSunlightQuickCreateType[]
}

const SunlightQuickCreateLauncher: React.FunctionComponent<PropTypes> = (props) => {
  const [hasFetchedOptions, setHasFetchedOptions] = useState<boolean>(false)
  const [hasValidDesigns, setHasValidDesigns] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [designs, setDesigns] = useState<SystemDesignSunlightQuickCreateType[] | undefined>(props.designs)
  const [paymentOptionForPrequal, setPaymentOptionForPrequal] = useState<
    PaymentOptionSunlightQuickCreateType | undefined
  >(undefined)
  const [justSubmittedPrequal, setJustSubmittedPrequal] = useState<boolean>(false)
  const [justSubmittedCredit, setJustSubmittedCredit] = useState<boolean>(false)

  useEffect(() => {
    if (props.designs && props.designs?.length > 0) setDesigns(props.designs)
    else {
      const systemsFromStudio = window.editor.getSystems()?.map((sys) => {
        sys.paymentOptionsData = sys.payment_options
        sys.title = sys.name && sys.name.length > 0 ? sys.name : `${sys.moduleQuantity()} ${translate('Panels System')}`
        return sys
      })
      if (systemsFromStudio && systemsFromStudio.length > 0) setDesigns(systemsFromStudio)
    }
  }, [props.designs])

  const getSunlightPaymentOptionFromDesigns = () => {
    if (designs && designs.length > 0) {
      for (let designIndex = 0; designIndex < designs.length; designIndex++) {
        const design = designs[designIndex]
        if (design.uuid && design.paymentOptionsData && design.paymentOptionsData.length > 0) {
          for (let pmtIndex = 0; pmtIndex < design.paymentOptionsData.length; pmtIndex++) {
            if (design.paymentOptionsData[pmtIndex].integration === 'sunlight') {
              return design.paymentOptionsData[pmtIndex]
            }
          }
        }
      }
    }
    return null
  }

  //listen to changes in designs and track whehter there is at least one system design with a sunlight pmt option
  useEffect(() => {
    let hasValid = !!getSunlightPaymentOptionFromDesigns()
    if (hasValidDesigns !== hasValid) setHasValidDesigns(hasValid)
  }, [designs])

  //if there are no system designs yet go fetch the payment options on the org to ensur they have a sunlight option enabled
  useEffect(() => {
    if (!hasFetchedOptions && props.project && props.project.org_id && props.project.id) {
      setHasFetchedOptions(true)
      fetchPaymentOptionsOnOrg()
    }
  }, [props.project, hasFetchedOptions])

  const fetchPaymentOptionsOnOrg = () => {
    //no need to run this if it's already set
    if (paymentOptionForPrequal) return
    //if we have a system design with a valid sunlight option just use that
    const validOptionOnADesign = getSunlightPaymentOptionFromDesigns()
    if (validOptionOnADesign) {
      setPaymentOptionForPrequal(validOptionOnADesign)
      return
    }
    //otherwise fetch options from org
    restClientInstance('CUSTOM_GET', 'custom', {
      url:
        'orgs/' +
        props.project.org_id +
        '/payment_options/?fieldset=list&payment_type=loan&limit=1000&ordering=order&page=1',
    })
      .then((res: any) => {
        if (res.data && res.data.length > 0) {
          let sunlightOptions: PaymentOptionType[] = res.data.filter((pmtOption: PaymentOptionType) => {
            if (pmtOption.configuration_json) {
              let optionConfig = JSON.parse(pmtOption.configuration_json)
              if (optionConfig && optionConfig.integration === 'sunlight') return true
            }
            return false
          })
          if (sunlightOptions && sunlightOptions.length > 0) {
            const option: PaymentOptionSunlightQuickCreateType = {
              id: sunlightOptions[0].id,
              title: sunlightOptions[0].title,
              integration: 'sunlight',
            }
            setPaymentOptionForPrequal(option)
          }
        }
      })
      .catch((err: any) => {
        console.log('err', err)
      })
      .finally(() => null)
  }

  const onFinish = (type: 'credit' | 'prequal') => {
    setShowModal(false)
    type === 'prequal' ? setJustSubmittedPrequal(true) : setJustSubmittedCredit(true)
  }

  const renderDialog = () => {
    return (
      <Dialog
        open={showModal}
        style={{ maxHeight: 'calc(100vh - 56px)', textAlign: 'center' }}
        onBackdropClick={() => setShowModal(false)}
      >
        <DialogContent style={{ paddingBottom: '20px' }}>
          <SunlightTransferChoice
            project={props.project}
            orgId={props.project.org_id}
            systemUuid={null}
            paymentOptionId={null}
            labelColor="black"
            backgroundColor="#fdd502"
            backgroundColorOnHover="#e9c100"
            hideDivider
            allowPrequal={!!paymentOptionForPrequal && !justSubmittedPrequal}
            allowCreditApp={hasValidDesigns && !justSubmittedCredit}
            creditDisabledMsg={
              hasValidDesigns ? undefined : translate('Please design a system with a Sunlight Payment Option first')
            }
            paymentOptionForPrequal={paymentOptionForPrequal}
            systemDesigns={designs}
            onFinish={onFinish}
          />
        </DialogContent>
      </Dialog>
    )
  }

  const handleButtonClick = () => {
    setShowModal(true)
    logAmplitudeEvent('sunglight_quick_create_cta_clicked', {})
  }

  const whiteButtonClasses = useStylesWhiteButton()
  const translate = useTranslate()
  if (!props.project || !props.project.is_residential) return null
  else if (!paymentOptionForPrequal && !hasValidDesigns) return null
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Button
        startIcon={
          <img
            alt={CONFIG.name}
            src={`${window.PUBLIC_URL}/images/${CONFIG.logo_small}`}
            style={{ maxHeight: 24, maxWidth: 60 }}
          />
        }
        classes={whiteButtonClasses}
        className="UploadLogoButton"
        onClick={handleButtonClick}
      >
        <span style={{ textTransform: 'none' }}>{translate('Send project to Sunlight')}</span>
      </Button>
      {renderDialog()}
    </div>
  )
}

export default SunlightQuickCreateLauncher

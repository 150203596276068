import { styled } from 'opensolar-ui'

export const ContentWrapper = styled('div')(({ theme }) => ({
  padding: '32px 18px',
}))

// react-admin fields have a gap between label and field
// this is to match that gap in non react-admin inputs
export const InputRow = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: 10,
}))

export const InputsCol = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  gap: 15,
}))

export const BooleanRow = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 12,
  marginLeft: 5,
  '& p': {
    marginTop: 7,
  },
}))

export const FormSubSection = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  gap: 15,
  '& >h2:first-of-type': {
    padding: '0px',
    margin: '0px',
    marginTop: 5,
  },
}))

export const FormSubSectionGrey = styled(FormSubSection)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  boxSizing: 'border-box',
  '& >h2:first-of-type': {
    textAlign: 'center',
    padding: '0px',
    margin: '0px',
    marginTop: 5,
  },
  '& >div:last-of-type': {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 8,
    margin: '0px 5px',
    padding: 10,
    paddingTop: 20,
    marginBottom: 10,
  },
}))

import { Link } from '@material-ui/core'
import LaunchIcon from '@material-ui/icons/LaunchOutlined'
import { useTranslate } from 'ra-core'
import React, { FC, ReactNode } from 'react'

type Props = {
  href: string
  title?: ReactNode
}

export const HelpLink: FC<Props> = ({ title, href }) => {
  const translate = useTranslate()
  if (!title) title = translate('Help Center')
  return (
    <Link href={href} referrerPolicy="no-referrer" target="_blank">
      <LaunchIcon style={{ width: 15, height: 15, verticalAlign: 'sub' }} />
      &nbsp;{title}
    </Link>
  )
}

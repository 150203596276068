import { FormControlLabel, makeStyles } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import Alert from 'elements/Alert'
import { FormState } from 'final-form'
import { Button, Checkbox } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { CSSProperties, FC, useEffect, useMemo, useState } from 'react'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { getBool, setBool } from 'storage/appStorage'
import { Theme } from 'types/themes'

interface FinanceQRCodeWarningProps {
  projectFormState?: FormState<Record<string, any>, Partial<Record<string, any>>>
  show?: boolean
  classes?: {
    wrapper?: string
  }
  styles?: CSSProperties
  location: 'pdf_proposal_page' | 'document_generation_dialog' | 'proposal_download_dialog'
}

const HIDE_FINCO_WARNING_KEY = 'hide-finco-qr-warning'

const useStyles = makeStyles<Theme, { isMobileDevice: boolean }>(() => {
  return {
    hideCheckboxCont: {
      width: '100%',
      textAlign: 'right',
    },
    hideFincoCheckbox: {
      padding: '0 8px',
    },
    USHelpPaper: {
      position: 'fixed',
      fontSize: 12,
      right: 21,
      bottom: 105,
      width: 158,
      padding: 4,
      backgroundColor: 'rgba(255,255,255,0.7)',
      borderRadius: 5,
    },
  }
})

const ELIGIBLE_FINANCE_ACTIONS = [
  'sunlight_loan_application',
  'sungage_application',
  'dividend_application',
  'mosaic_application',
  'loanpal_application',
  'brighte_application',
  'plenti_loan_application',
  'energy_ease_application',
]

export const FinanceQRCodeWarning: FC<FinanceQRCodeWarningProps> = ({
  projectFormState,
  show,
  classes = {},
  styles,
  location,
}) => {
  const formState = useFormState()
  if (!projectFormState) projectFormState = formState

  const [hasEligibleFincoActions, setHasEligibleFincoActions] = useState(false)
  const [hideFincoWarningPerm, sethideFincoWarningPerm] = useState(false)
  const [showFincoQRCodeWarning, setShowQRCodeFincoWarning] = useState(false)
  const hasUsFinance = useSelector(authSelectors.getHasUsFinanceIntegration)
  const hasAUFinance = useSelector(authSelectors.getHasAUFinanceIntegration)
  const isMobileDevice = useMemo(() => window.Utils?.isTouchDevice(), [])
  const innerClasses = useStyles({ isMobileDevice })
  const translate = useTranslate()
  const projectFields = projectFormState.values
  const includeFinanceQRCode = useMemo(() => projectFields?.proposal_template_settings?.include_finance_qr_code, [
    projectFields,
  ])
  const projectId = projectFields.id

  useEffect(() => {
    if (projectFields?.available_customer_actions?.length && (hasUsFinance || hasAUFinance)) {
      let foundEligible = false
      projectFields?.available_customer_actions?.forEach((systemActions) => {
        systemActions?.actions_available?.forEach((action) => {
          if (action?.status_code === 'available' && ELIGIBLE_FINANCE_ACTIONS.includes(action.payment_method))
            foundEligible = true
        })
      })
      setHasEligibleFincoActions(foundEligible)
    }
  }, [hasUsFinance, hasAUFinance, projectFields?.available_customer_actions])

  const hideFincoWarningPermChange = (event) => {
    sethideFincoWarningPerm(event.target.checked)
  }

  const dismissFincoWarning = () => {
    if (hideFincoWarningPerm) {
      setBool(HIDE_FINCO_WARNING_KEY, true)
      logAmplitudeEvent('finance_qr_code_permanently_hidden', { project_id: projectId, location })
    }
    setShowQRCodeFincoWarning(false)
  }

  useEffect(() => {
    if (hasEligibleFincoActions && show !== false) {
      if (includeFinanceQRCode) {
        if (!getBool(HIDE_FINCO_WARNING_KEY)) {
          setShowQRCodeFincoWarning(true)
        }
        logAmplitudeEvent('finance_qr_code_enabled_in_pdf', { project_id: projectId, location })
      } else if (showFincoQRCodeWarning) {
        setShowQRCodeFincoWarning(false)
        logAmplitudeEvent('finance_qr_code_disabled_in_pdf', { project_id: projectId, location })
      }
    } else {
      setShowQRCodeFincoWarning(false)
    }
  }, [includeFinanceQRCode, show, hasEligibleFincoActions])

  return !showFincoQRCodeWarning ? (
    <></>
  ) : (
    <Alert
      severity="info"
      classes={classes}
      styles={styles}
      actions={
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={hideFincoWarningPerm}
                onChange={hideFincoWarningPermChange}
                className={innerClasses.hideFincoCheckbox}
              />
            }
            label={translate("Don't show again")}
          />
          <Button size="small" onClick={dismissFincoWarning}>
            {translate('Dismiss')}
          </Button>
        </div>
      }
    >
      {translate(
        'QR codes to access the finance application form inside of the online proposal will be automatically included in the generated PDF. This feature can be disabled by disabling "Add QR Code to access finance application form" in the applied proposal template.'
      )}
    </Alert>
  )
}

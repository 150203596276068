import { getCompatibleCodesForSystem } from 'elements/hardwareSelector/SearchToolbar'
import { useMemo } from 'react'
import type { ComponentTypesV2 } from 'types/selectComponent'
import type {
  ConfigurableFilterOptionsType,
  FilterComponentNodeV2,
  FilterGenericPropsType,
  FilterValuesType,
  OptionGenericType,
} from '../typeV2'
import { parseStringifiedListFilterValue } from '../utils/utils'

export const BATTERY_COMPATIBILITY_FILTER_NODE_KEY_V2 = 'battery_compatibility_v2'
export const CODES_FILTER_KEY = 'codes'

const BATTERY_COMPATIBILITY_OPTIONS: OptionGenericType<string>[] = [
  { id: 'default', title: 'All', value: '' },
  { id: 'yes', title: 'Yes', value: 'true' },
]

export const createBatteryCompatibilityFilterNode = <T,>({
  label = 'Compatible with system',
  rendererComponent: RendererComponent,
  ...restConfigs
}: ConfigurableFilterOptionsType<string, string>): FilterComponentNodeV2 => {
  const FilterComponent = ({
    allFilterValues,
    modifiableFilterValues,
    setModifiableFilters,
  }: FilterGenericPropsType) => {
    const compatibleCodes = useMemo(() => getCompatibleCodesForSystem('battery'), [])
    const types = parseStringifiedListFilterValue<ComponentTypesV2>({
      filterValues: allFilterValues,
      key: 'types',
    })
    const displayFilter = types.has('battery') && compatibleCodes.length !== 0

    if (!displayFilter) {
      return null
    }

    const onSelect = (value) => {
      if (value === 'true') {
        setModifiableFilters({
          ...modifiableFilterValues,
          [CODES_FILTER_KEY]: compatibleCodes.join(','),
        })
      } else {
        if (!!modifiableFilterValues[CODES_FILTER_KEY]) {
          const newModifiableFilterValues = { ...modifiableFilterValues }
          delete newModifiableFilterValues[CODES_FILTER_KEY]
          setModifiableFilters(newModifiableFilterValues)
        }
      }
    }

    const getBatteryCompatibilityValue = ({ filterValues }: { filterValues: FilterValuesType }) => {
      const codes = filterValues[CODES_FILTER_KEY]
      const compatible = !!codes && compatibleCodes.join(',') === codes
      return compatible ? 'true' : ''
    }

    return (
      <RendererComponent
        id={BATTERY_COMPATIBILITY_FILTER_NODE_KEY_V2}
        defaultValue={''}
        label={label}
        options={BATTERY_COMPATIBILITY_OPTIONS}
        disabled={false}
        onSelect={onSelect}
        value={getBatteryCompatibilityValue({ filterValues: allFilterValues })}
      />
    )
  }

  return {
    key: BATTERY_COMPATIBILITY_FILTER_NODE_KEY_V2,
    component: FilterComponent,
  }
}

export default createBatteryCompatibilityFilterNode

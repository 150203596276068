import useAdBanner from 'elements/ads/useAdBanner'
import type { ComponentTypesV2 } from 'types/selectComponent'
import HardwareAdBannerContainer from './HardwareAdBannerContainer'

export const getHardwareSelectorAdPlacement = ({
  location,
  searchText = '',
  componentType,
}: {
  location: 'top' | 'bottom'
  searchText?: string
  componentType?: ComponentTypesV2
}): string => {
  if (componentType) {
    return `hardware_selection_${componentType}_${location}`
  } else if (searchText) {
    return `hardware_search_${location}`
  }
  return ''
}

const HardwareSelectorTopBanner = ({
  searchText,
  componentType,
}: {
  searchText: string
  componentType?: ComponentTypesV2
}) => {
  const adPlacementTop = getHardwareSelectorAdPlacement({ location: 'top', searchText, componentType })
  const AdBanner = useAdBanner({
    formats: ['leaderboard'],
    placement: adPlacementTop,
    requiredWindowSize: { width: 960, height: 800 },
  })

  if (AdBanner != null) {
    return <HardwareAdBannerContainer> {AdBanner} </HardwareAdBannerContainer>
  }

  return null
}

export default HardwareSelectorTopBanner

import { debounce } from 'lodash'
import { useCallback, useRef } from 'react'
import { StudioSignalTypes } from 'types/studio/signals'
import { useStudioSignals, UseStudioSignalsOpts } from './useStudioSignals'

// Similar to useStudioSignalsLazy, but collates all arguments into an array of arrays
// This allows the caller to know the details of all signals that were fired during the debounce period
export function useStudioSignalsCollate(
  handler: (args: any[][]) => void,
  signals: StudioSignalTypes[],
  scope?: object,
  opts?: UseStudioSignalsOpts
) {
  if (!opts) opts = {}
  if (opts.debounce === undefined) opts.debounce = 0.05
  if (opts.maxWait === undefined) opts.maxWait = 0.5

  const handlerDebounced = useCallback(
    debounce(
      () => {
        handler(collatedArgs.current)
        collatedArgs.current = []
      },
      opts.debounce,
      opts
    ),
    [opts?.trackHandler ? handler : null]
  )

  const collatedArgs = useRef<any[][]>([])
  const innerHandler = useCallback(
    (...args: any[]) => {
      collatedArgs.current.push(args)
      handlerDebounced()
    },
    [handlerDebounced]
  )

  useStudioSignals(innerHandler, signals, scope)
}

import {
  CALCULATION_DEBOUNCE_QUEUE_ADD,
  CALCULATION_PROCESS_END,
  CALCULATION_PROCESS_START,
  CALCULATION_PROCESS_STATUS_UPDATE,
} from 'actions/designer'
import omit from 'lodash/omit'
import { Reducer } from 'redux'
import { RootState } from 'types/state'

export type StatusType =
  | 'preparingClustersRequest'
  | 'preparingCalculationRequest'
  | 'waitingCalculation'
  | 'waitingCluster'
  | 'handlingClusterSuccess'
  | 'handlingCalculationSuccess'
  | 'error'
export type Status = {
  uuid: string
  requireClusters: boolean
  status: StatusType
}
export interface SystemCalculationsQueuedState {
  debounceQueue: string[]
  processQueue: { [key: string]: Status }
}

const getStatus = (uuid: string, requireClusters: boolean) => {
  return {
    uuid,
    requireClusters,
    status: requireClusters ? 'preparingClustersRequest' : 'preparingCalculationRequest',
  }
}

const defaultState = {
  debounceQueue: [],
  processQueue: {},
}

export const systemCalculationsQueuedReducer: Reducer<SystemCalculationsQueuedState> = (
  previousState = defaultState,
  { type, payload }
) => {
  const { uuid, requireClusters, status } = payload || {}

  if (type === CALCULATION_DEBOUNCE_QUEUE_ADD) {
    const newDebounceQueue = [...previousState.debounceQueue]
    if (!newDebounceQueue.includes(uuid)) {
      newDebounceQueue.push(uuid)
    }
    return {
      ...previousState,
      debounceQueue: newDebounceQueue,
    }
  } else if (type === CALCULATION_PROCESS_START) {
    const newDebounceQueue = [...previousState.debounceQueue]
    const indexInDebounceQueue = newDebounceQueue.indexOf(uuid)
    newDebounceQueue.splice(indexInDebounceQueue, 1)
    return {
      ...previousState,
      processQueue: {
        ...previousState.processQueue,
        [uuid]: getStatus(uuid, requireClusters),
      },
      debounceQueue: newDebounceQueue,
    }
  } else if (type === CALCULATION_PROCESS_STATUS_UPDATE) {
    if (previousState.processQueue[uuid]) {
      return {
        ...previousState,
        processQueue: {
          ...previousState.processQueue,
          [uuid]: { ...previousState.processQueue[uuid], status },
        },
      }
    } else {
      return previousState
    }
  } else if (type === CALCULATION_PROCESS_END) {
    return {
      ...previousState,
      processQueue: omit(previousState.processQueue, [uuid]),
    }
  } else {
    return previousState
  }
}

export const systemCalculationsSelectors = {
  areAnyQueuedOrProcessing(state: RootState) {
    return (
      state.designer.systemCalculation.debounceQueue.length > 0 ||
      Object.keys(state.designer.systemCalculation.processQueue).length > 0
    )
  },
  isSystemQueued(state: RootState) {
    return (uuid: string | undefined) => !!uuid && state.designer.systemCalculation.debounceQueue.includes(uuid)
  },
  isSystemProcessing(state: RootState) {
    return (uuid: string | undefined) => !!uuid && !!state.designer.systemCalculation.processQueue[uuid]
  },
}

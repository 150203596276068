// @ts-nocheck
import { Grid } from '@material-ui/core'
import CustomField from 'elements/field/CustomField'
import React from 'react'
import { AutocompleteInput, NumberInput, ReferenceInput, SelectInput, TextInput, useTranslate } from 'react-admin'

export const TRANSACTION_TYPES = [
  { id: 'deposit', name: 'Deposit' },
  { id: 'adjustment', name: 'Adjustment' },
  { id: 'gift', name: 'Gift' },
  { id: 'purchase', name: 'Purchase' },
  { id: 'refund', name: 'Refund' },
  { id: 'other', name: 'Other' },
]

export const WALLET_TRANSACTION_TAX_TYPES = [
  'tax_combined',
  'tax_country',
  'tax_state',
  'tax_county',
  'tax_city',
  'tax_special',
]

type PropTypes = {}

const StandardWalletTransactionForm: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  return (
    <>
      <Grid item xs={6}>
        <CustomField
          name="transaction_type"
          source="transaction_type"
          label={translate('Type of Credit/Debit')}
          component={SelectInput}
          choices={TRANSACTION_TYPES}
          fullWidth
          style={{ width: '100%' }}
          required={true}
        />
      </Grid>
      <Grid item xs={6}>
        <ReferenceInput
          label="Org Name"
          source="org_id"
          reference="orgs"
          sort={{ field: 'name', order: 'ASC' }}
          fullWidth={true}
          style={{ width: '100%' }}
        >
          <AutocompleteInput optionText="name" optionValue="id" style={{ width: '100%' }} fullWidth={true} />
        </ReferenceInput>
      </Grid>
      <Grid item xs={6}>
        <CustomField
          name="amount_inc_tax"
          source="amount_inc_tax"
          label={translate('Total Amount')}
          component={NumberInput}
          fullWidth
          style={{ width: '100%' }}
          required={true}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomField
          name="currency"
          source="currency"
          label={translate('Currency Override')}
          component={TextInput}
          fullWidth
          style={{ width: '100%' }}
          required={false}
        />
        <span className="small">This will default to the org's country's currency if left blank</span>
      </Grid>
      {WALLET_TRANSACTION_TAX_TYPES.map((tax_type) => (
        <Grid item xs={4}>
          <CustomField
            name={tax_type}
            source={tax_type}
            label={translate(tax_type?.replace('_', ' '))}
            component={NumberInput}
            fullWidth
            style={{ width: '100%' }}
          />
        </Grid>
      ))}
      <Grid item xs={12}>
        <CustomField
          name="notes"
          source="notes"
          label={translate('Notes')}
          component={TextInput}
          fullWidth
          style={{ width: '100%' }}
          required={false}
        />
      </Grid>
    </>
  )
}
export default StandardWalletTransactionForm

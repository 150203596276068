import { populateGeneric } from './components/SystemHardware'

const _buildSystemPlaceholders = (selectedSystem, selectedPaymentOption, inverters, filterOtherCode) => {
  // In order to populate components correctly we have to pass sorted inverters into this function
  if (!selectedSystem.module) {
    return null
  }

  const populateMap = {
    ...selectedSystem,
  }

  if (selectedPaymentOption) {
    Object.assign(populateMap, {
      systemNetPresentValue: window.roundToDecimalPlaces(selectedPaymentOption.net_present_value, 0),
      systemPaybackYear: window.roundToDecimalPlaces(selectedPaymentOption.payback_year, 1),
      systemDiscountedPaybackYear: window.roundToDecimalPlaces(selectedPaymentOption.discounted_payback_period, 1),
      systemReturnOnInvestment:
        window.roundToDecimalPlaces(100 * selectedPaymentOption.total_return_on_investment, 0) + '%',
      systemIrr: window.roundToDecimalPlaces(100 * selectedPaymentOption.internal_rate_of_return, 1) + '%',
      systemLcoe: window.roundToDecimalPlaces(selectedPaymentOption.lcoe, 2),
      systemFinancialDiscountRate: selectedPaymentOption.discount_rate
        ? window.roundToDecimalPlaces(100 * selectedPaymentOption.discount_rate, 2) + '%'
        : 'Not specified',
      systemUtilityInflationAnnual: selectedPaymentOption.utility_inflation_annual
        ? window.roundToDecimalPlaces(100 * selectedPaymentOption.utility_inflation_annual, 2) + '%'
        : 'Not specified',
    })
  }

  return populateMap
}

export const buildSystemPlaceholders = _buildSystemPlaceholders

export const populate = (templateString, selectedSystem, selectedPaymentOption, inverters, filterOtherCode) => {
  if (!templateString) {
    return null
  }

  return populateGeneric(
    templateString,
    buildSystemPlaceholders(selectedSystem, selectedPaymentOption, inverters, filterOtherCode)
  )
}

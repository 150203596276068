import { COMPONENT_TYPE_OPTIONS } from 'resources/components/others/constants'
import type { FilterType, GetDefaultFilterValuePropsType } from 'types/selectComponent'

const FILTER_OPTIONS = COMPONENT_TYPE_OPTIONS.filter((option) => option.id !== 'general').map((option) => ({
  value: option.id,
  title: option.name,
}))

export const otherComponentTypeFilter: FilterType = {
  key: 'other_component_type',
  label: 'Component Type',
  options: FILTER_OPTIONS,
  getDefaultValue({ specsData }: GetDefaultFilterValuePropsType) {
    if (specsData.other_component_type == null) {
      return
    }
    return FILTER_OPTIONS.find((option) => option.value === specsData.other_component_type)?.value
  },
}

import { Divider, Grid, Typography } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { OpenSolarThemeType } from 'Themes'
import { orderSelectors } from 'ducks/orderComponents'
import { orgSelectors } from 'ducks/orgs'
import Button from 'elements/button/Button'
import { memo, useContext } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { currencySymbolForCountry, formatCurrency } from 'util/misc'
import { ShippingDetailContext, ShippingFeeFetchContext } from './CheckoutProvider'
import { useSolarJuiceStyles } from './style'
import { ShippingDetailType } from './type'

type OrderSummaryProps = {
  cost: number
  totalBeforeTax: number
  tax: number
  totalCost: number
  handlePlaceOrder: () => void
}

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  actionWrapper: {
    boxSizing: 'border-box',
    border: theme.border.default,
    borderRadius: '8px',
    flexShrink: 0,
    overflow: 'hidden',
  },
}))

const OrderSummaryBreakdownItem = ({
  label,
  value,
  style,
}: {
  label: string
  value: string
  style?: CSSProperties
}) => {
  const solarJuiceClasses = useSolarJuiceStyles()
  return (
    <Grid item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', ...style }}>
      <Typography className={solarJuiceClasses.font14}>{label}</Typography>
      <Typography className={solarJuiceClasses.font14}>{value}</Typography>
    </Grid>
  )
}

const OrderSummary = ({ cost, totalBeforeTax, tax, totalCost, handlePlaceOrder }: OrderSummaryProps) => {
  const solarJuiceClasses = useSolarJuiceStyles()
  const classes = useStyles()
  const currencySymbol = useSelector((state: any) => currencySymbolForCountry(orgSelectors.getOrgIso2(state)))
  const shippingDetail: ShippingDetailType = useContext(ShippingDetailContext)
  const isFetchingShippingFee = useContext(ShippingFeeFetchContext)
  const usingCustomPricing = useSelector(orderSelectors.getIsCustomPricing)
  return (
    <Grid container direction="column" className={classes.actionWrapper}>
      <Grid container style={{ padding: '20px 20px 10px 20px' }} direction="column">
        <Grid item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography variant="h6" className={solarJuiceClasses.font18}>
            Order Summary
          </Typography>
        </Grid>
        <OrderSummaryBreakdownItem
          label="Shipping & Handling"
          value={
            typeof shippingDetail.fee === 'string'
              ? `${shippingDetail.fee}`
              : `${currencySymbol} ${formatCurrency(shippingDetail.fee)}`
          }
          style={{ marginTop: '24px' }}
        />
        <OrderSummaryBreakdownItem
          label="Items"
          value={usingCustomPricing ? 'TBC' : `${currencySymbol} ${formatCurrency(cost)}`}
          style={{ margin: '8px 0px' }}
        />
        <Grid
          item
          style={{ display: 'flex', margin: '8px 0px', flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <Typography className={solarJuiceClasses.font14}></Typography>
          <Divider style={{ width: '70px' }} />
        </Grid>
        <OrderSummaryBreakdownItem
          label="Total before tax:"
          value={usingCustomPricing ? 'TBC' : `${currencySymbol} ${formatCurrency(totalBeforeTax)}`}
        />
        <OrderSummaryBreakdownItem
          label="GST (10%):"
          value={usingCustomPricing ? 'TBC' : `${currencySymbol} ${formatCurrency(tax)}`}
          style={{ margin: '8px 0px' }}
        />
        <Divider style={{ margin: '8px 0px' }} />
        <Grid
          item
          style={{ display: 'flex', margin: '8px 0px 0px 0px', flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <Typography className={`${solarJuiceClasses.fontWeightMedium} ${solarJuiceClasses.font16}`}>
            Order Total:
          </Typography>
          <Typography
            className={`${solarJuiceClasses.fontWeightMedium} ${solarJuiceClasses.font16}`}
            style={{ color: '#018030' }}
          >
            {usingCustomPricing ? 'TBC' : `${currencySymbol} ${formatCurrency(totalCost)}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '20px',
          backgroundColor: '#F8F8F8',
          gap: '20px',
          borderBottom: '8px',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center', padding: '0px 15px' }}>
          <Button color="primary" fullWidth onClick={handlePlaceOrder} disabled={isFetchingShippingFee}>
            Place Order
          </Button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', padding: '0px 25px' }}>
          <Typography
            style={{
              fontSize: '12px',
              lineHeight: '16px',
            }}
          >
            <span>By placing your order, you agree to Solar Outlet’s </span>
            <a href="https://solarjuice.com.au/terms-conditions/" style={{ color: '#1751D0' }}>
              Privacy Policy
            </a>
            <span> and </span>
            <a href="https://solarjuice.com.au/terms-conditions/" style={{ color: '#1751D0' }}>
              Conditions of Use
            </a>
          </Typography>
        </div>
      </Grid>
    </Grid>
  )
}

export default memo(OrderSummary)

// @ts-nocheck
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined'
import SendIcon from '@material-ui/icons/Send'
import { permissionsSelectors } from 'ducks/permissions'
import { Button } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import useTranslateParse from '../../../util/useTranslateParse'
import { ToolTipPropsType, TourConfigType } from '../types'
import DefaultTooltip from './DefaultToolTip'

const SelectionToolTip = (props: ToolTipPropsType) => {
  const { handleNext, handlePrev, handleCancel } = props
  const translate = useTranslate()
  return (
    <DefaultTooltip
      title={translate('Customer Actions')}
      // onClose={handleCancel}
      icon={AssignmentOutlinedIcon}
      content={
        <div>
          {translate('The right-hand column contains all of the customer actions.')}
          <div style={{ margin: '10px 0' }}>
            {translate(
              'If you add multiple system options or payment options, your customer can toggle among them by clicking the buttons at right.'
            )}
          </div>
          {translate(
            'Note that all the figures and images in the proposal will change as you toggle among the options.'
          )}
        </div>
      }
      actions={
        <>
          <Button
            onClick={() => handlePrev && handlePrev()}
            onTouchStart={(e) => {
              e.stopPropagation()
              if (handlePrev) {
                handlePrev()
              }
            }}
          >
            <span>{translate('Back')}</span>
          </Button>
          <Button
            onClick={() => handleNext && handleNext()}
            onTouchStart={(e) => {
              e.stopPropagation()
              if (handleNext) {
                handleNext()
              }
            }}
          >
            <span>{translate('Next')}</span>
          </Button>
        </>
      }
      arrowPlacement={'right'}
    />
  )
}

const SystemViewsToolTip = (props: ToolTipPropsType) => {
  const { handleNext, handlePrev, handleCancel } = props
  const { allowView, allowCreate, allowEdit, allowDelete } = useSelector(
    permissionsSelectors.getProjectPermissionByKey('proposal')
  )
  const translate = useTranslate()
  const is3D = window.MapData?.is3D(window.MapHelper?.activeMapInstance?.mapData)
  return (
    <DefaultTooltip
      title={translate('System Views')}
      // onClose={handleCancel}
      icon={PhotoLibraryOutlinedIcon}
      content={
        <div>
          {translate(
            'Help your customer picture solar on their home. Show your customer up to 5 different angles of their new solar home.'
          )}
          {is3D && (
            <div style={{ marginTop: 10 }}>
              {translate(
                "This 3D-based view will automatically appear once you've finished designing, but you can replace it or supplement it with high-resolution aerial imagery."
              )}
            </div>
          )}
          <div style={{ marginTop: 10 }}>
            {translate(
              "For the tour we'll stick with showing a single view, but on your next design you can customize the views."
            )}
          </div>
        </div>
      }
      actions={
        <div style={{ display: 'flex', textAlign: 'left', alignItems: 'center' }}>
          {!allowEdit && (
            <p style={{ color: 'red', textAlign: 'left', paddingLeft: 10 }}>
              You do not have permission to customise views in the online proposal.
            </p>
          )}
          <Button
            onClick={() => handlePrev && handlePrev()}
            onTouchStart={(e) => {
              e.stopPropagation()
              if (handlePrev) {
                handlePrev()
              }
            }}
          >
            <span>{translate('Back')}</span>
          </Button>
          <Button
            onClick={() => handleNext && handleNext()}
            onTouchStart={(e) => {
              e.stopPropagation()
              if (handleNext) {
                handleNext()
              }
            }}
          >
            <span>{translate('Next')}</span>
          </Button>
        </div>
      }
      arrowPlacement={'bottom'}
    />
  )
}

export const ToolbarToolTip = (props: ToolTipPropsType) => {
  const { handleNext, handlePrev, handleCancel } = props
  const translate = useTranslate()
  const translateParse = useTranslateParse()
  return (
    <DefaultTooltip
      title={translate('Send Proposal to Customer')}
      // onClose={handleCancel}
      icon={SendIcon}
      content={
        <div>
          {translateParse(
            "Once you have confirmed that you're happy with the proposal, you can send the proposal invite to your customer by clicking the <strong>Send Proposal To Customer<strong> button. (If you haven't entered a valid email address, skip this step.)",
            { strong: (label: string) => <strong>{label}</strong> }
          )}{' '}
          <div style={{ marginTop: 10 }}>
            {translateParse('Click <b>Next<b> to go to the next step.', { b: (label: string) => <b>{label}</b> })}
          </div>
        </div>
      }
      actions={
        <>
          <Button
            onClick={() => handlePrev && handlePrev()}
            onTouchStart={(e) => {
              e.stopPropagation()
              if (handlePrev) {
                handlePrev()
              }
            }}
          >
            <span>{translate('Back')}</span>
          </Button>
          <Button
            onClick={() => handleNext && handleNext()}
            onTouchStart={(e) => {
              e.stopPropagation()
              if (handleNext) {
                handleNext()
              }
            }}
          >
            <span>{translate('Next')}</span>
          </Button>
        </>
      }
      arrowPlacement={'bottom'}
    />
  )
}

export const Selections: TourConfigType = {
  spotlights: [
    { targetId: 'proposal-system-options', disableBackgroundScroll: true },
    { targetId: 'proposal-payment-options' },
  ],
  tooltips: [{ targetId: 'proposal-payment-options', placement: 'left', component: SelectionToolTip }],
}

export const SystemViews: TourConfigType = {
  spotlights: [{ targetId: 'proposal-viewport', keepObserve: true, disableBackgroundScroll: true }],
  tooltips: [{ targetId: 'proposal-viewport', placement: 'top', component: SystemViewsToolTip }],
}

export const ProposalToolbar: TourConfigType = {
  spotlights: [{ targetId: 'proposal-bottom-toolbar', disableBackgroundScroll: true }],
  tooltips: [{ targetId: 'proposal-bottom-toolbar', placement: 'top', component: ToolbarToolTip }],
}

import { withStyles } from '@material-ui/core'
import Tooltip from 'elements/tooltip/Tooltip'
import { Chip, styled } from 'opensolar-ui'
import { SimpleForm } from 'react-admin'

export const SectionHeader = styled('h2', { name: 'SectionHeader' })(({ theme }) => ({
  color: theme.palette.grey[700],
  fontSize: 12,
  fontWeight: 500,
  marginBottom: 20,
  textTransform: 'uppercase',
}))

export const Header = styled('h1', { name: 'Header' })({
  fontSize: 18,
  marginBottom: 30,
})

export const FormStyled = styled(SimpleForm, { name: 'FormStyled' })({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
})

export const ContentWrapper = styled('div', { name: 'ContentWrapper' })({
  padding: 10,
  marginRight: 0,
})

export const StyledChip = styled(Chip, { name: 'StyledChip' })({
  marginRight: 3,
  borderRadius: 24,
})

export const ToolTipRestyle = withStyles(() => ({
  tooltip: {
    backgroundColor: 'white',
    padding: '10px 15px',
  },
}))(Tooltip)

import { OtherComponentTypes } from 'types/otherComponent'
import { positiveOrZero, required, requiredPositiveOrZero, validRange } from 'validations'
import SoftWarning from '../AdornmentWithWarning'
import { convertToLegacyCustomFields } from '../common'
import type { ComponentConfig } from '../types'

type ComponentTypeOption = {
  id: OtherComponentTypes
  name: string
}

export const COMPONENT_TYPE_OPTIONS: ComponentTypeOption[] = [
  { id: 'general', name: 'General' },
  { id: 'dc_optimizer', name: 'DC power optimizer' },
  { id: 'isolator', name: 'Isolator' },
  { id: 'meter', name: 'Meter' },
  { id: 'cable', name: 'Cable' },
  { id: 'battery_accessory', name: 'Battery Accessory' },
  { id: 'battery_inverter', name: 'Battery Inverter' },
  { id: 'inverter_accessory', name: 'Inverter Accessory' },
  { id: 'mounting_rail', name: 'Rail' },
  { id: 'mounting_roof_anchor', name: 'Roof Anchor' },
  { id: 'mounting_clamp', name: 'Clamp' },
  { id: 'mounting_flashing', name: 'Flashing' },
  { id: 'mounting_roof_fixing', name: 'Roof Fixing' },
  { id: 'mounting_other', name: 'Other Mounting Component' },
  { id: 'ev_charger', name: 'EV Charger' },
  { id: 'heat_pump', name: 'Heat Pump' },
]

const CURRENT_TYPE_OPTIONS = [
  { id: 'ac', name: 'AC' },
  { id: 'dc', name: 'DC' },
]

const PHASE_TYPE_OPTIONS = [
  { id: 'single_phase', name: 'Single-Phase' },
  { id: 'three_phase', name: '3-Phase' },
  { id: 'two_phase', name: '2-Phase' },
]

const EV_CHARGER_CONNECTOR_TYPE = [
  { id: 'type_1', name: 'Type 1' },
  { id: 'type_2', name: 'Type 2' },
  { id: 'type_3', name: 'Type 3' },
]

const HEAT_PUMP_TYPE = [
  { id: 'air_source', name: 'Air Source' },
  { id: 'water_source', name: 'Water Source' },
  { id: 'ground_source', name: 'Ground Source' },
]

const HEAT_PUMP_FUNCTION_OPTIONS = [
  { id: 'heating_only', name: 'Heating Only' },
  { id: 'heating_and_cooling', name: 'Heating & Cooling' },
]

const FORMULA_OPTIONS = [
  { id: 'value', name: 'Specify Value' },
  { id: 'system_price_including_tax', name: 'Value x System price including tax' },
  { id: 'system_price_excluding_tax', name: 'Value x System price excluding tax' },
  { id: 'system_price_payable', name: 'Value x System price payable' },
  { id: 'system_kw_stc', name: 'Value x System Size (kW STC)' },
  { id: 'system_panel_quantity', name: 'Value x Panel Quantity' },
  { id: 'inverter_size_combined_kw', name: 'Value x Inverter Combined Size (kW)' },
  { id: 'battery_size_combined_kwh', name: 'Value x Battery Combined (kWh)' },
  { id: 'panel_cogs', name: 'Value x Panel COGS' },
  { id: 'inverter_cogs', name: 'Value x Inverter COGS' },
  { id: 'battery_cogs', name: 'Value x Battery COGS' },
  { id: 'other_cogs', name: 'Value x Other components COGS' },
]

const CYCLE_UNITS_OPTIONS = [
  { id: 'once', name: 'Only Once' },
  { id: 'weekly', name: 'Weekly' },
  { id: 'fortnightly', name: 'Fortnightly' },
  { id: 'monthly', name: 'Monthly' },
  { id: 'quarterly', name: 'Quarterly' },
  { id: 'annual', name: 'Annual' },
]

export const componentFieldPrefix = 'custom_data_field'
export const otherComponentDataConfigs: ComponentConfig[] = [
  {
    source: 'code',
    label: 'Code',
    component: 'String',
    validate: required,
  },
  {
    source: 'manufacturer_name',
    label: 'Manufacturer',
    component: 'AutocompleteInput',
    referenceInputParams: {
      label: 'Manufacturer',
      reference: 'manufacturers',
      allowEmpty: true,
      ignoreWarnings: ['associatedReference'],
    },
    extra: {
      freeSolo: true,
      fullWidth: true,
      optionValue: 'name',
    },
  },
  {
    source: 'title',
    label: 'Title',
    component: 'String',
    validate: required,
  },
  {
    source: 'other_component_type',
    label: 'Type',
    component: 'Select',
    selectOptions: COMPONENT_TYPE_OPTIONS,
  },
  {
    source: 'model',
    label: 'Model',
    component: 'LongTextInput',
    unit: 'code/url',
  },
  {
    source: 'connector_type',
    label: 'Connector Type',
    component: 'Select',
    selectOptions: EV_CHARGER_CONNECTOR_TYPE,
    dependentInputParams: {
      dependsOn: `${componentFieldPrefix}_other_component_type`,
      value: 'ev_charger',
    },
  },
  {
    source: 'supports_single_phase',
    label: 'Supports Single-Phase',
    component: 'Boolean',
    dependentInputParams: {
      dependsOn: `${componentFieldPrefix}_other_component_type`,
      value: 'ev_charger',
    },
  },
  {
    source: 'single_phase_max_output_power',
    label: 'Single-Phase Max Output Power',
    component: 'Number',
    unit: 'kW',
    validate: positiveOrZero,
    dependentInputParams: {
      dependsOn: `${componentFieldPrefix}_supports_single_phase`,
      value: true,
    },
  },
  {
    source: 'supports_three_phase',
    label: 'Supports Three-Phase',
    component: 'Boolean',
    dependentInputParams: {
      dependsOn: `${componentFieldPrefix}_other_component_type`,
      value: 'ev_charger',
    },
  },
  {
    source: 'three_phase_max_output_power',
    label: 'Three-Phase Max Output Power',
    component: 'Number',
    unit: 'kW',
    validate: positiveOrZero,
    dependentInputParams: {
      dependsOn: `${componentFieldPrefix}_supports_three_phase`,
      value: true,
    },
  },
  {
    source: 'heat_pump_type',
    label: 'Heat Pump Type',
    component: 'Select',
    selectOptions: HEAT_PUMP_TYPE,
    dependentInputParams: {
      dependsOn: `${componentFieldPrefix}_other_component_type`,
      value: 'heat_pump',
    },
  },
  {
    source: 'heat_pump_function',
    label: 'Heat Pump Function',
    component: 'Select',
    selectOptions: HEAT_PUMP_FUNCTION_OPTIONS,
    dependentInputParams: {
      dependsOn: `${componentFieldPrefix}_other_component_type`,
      value: 'heat_pump',
    },
  },
  {
    source: 'heat_pump_rated_power',
    label: 'Heat Pump Rated Power',
    component: 'Number',
    unit: 'kW',
    validate: positiveOrZero,
    dependentInputParams: {
      dependsOn: `${componentFieldPrefix}_other_component_type`,
      value: 'heat_pump',
    },
  },
  {
    source: 'heat_pump_rated_current',
    label: 'Heat Pump Rated Current',
    component: 'Number',
    unit: 'A',
    validate: positiveOrZero,
    dependentInputParams: {
      dependsOn: `${componentFieldPrefix}_other_component_type`,
      value: 'heat_pump',
    },
  },
  {
    source: 'dc_optimizer_efficiency',
    label: 'DC Optimizer Efficiency',
    component: 'Number',
    unit: '%',
    validate: validRange(0, 100, true),
    dependentInputParams: {
      dependsOn: `${componentFieldPrefix}_other_component_type`,
      value: 'dc_optimizer',
    },
  },
  {
    source: 'dc_optimizer_max_input_power',
    label: 'DC Optimizer Max Input Power',
    component: 'Number',
    unit: 'W',
    validate: requiredPositiveOrZero,
    dependentInputParams: {
      dependsOn: `${componentFieldPrefix}_other_component_type`,
      value: 'dc_optimizer',
    },
  },
  {
    source: 'dc_optimizer_max_input_voltage',
    label: 'DC Optimizer Max Input Voltage',
    component: 'Number',
    unit: 'V',
    validate: requiredPositiveOrZero,
    dependentInputParams: {
      dependsOn: `${componentFieldPrefix}_other_component_type`,
      value: 'dc_optimizer',
    },
  },
  {
    source: 'dc_optimizer_max_input_current',
    label: 'DC Optimizer Max Input Current',
    component: 'Number',
    unit: 'A',
    validate: requiredPositiveOrZero,
    dependentInputParams: {
      dependsOn: `${componentFieldPrefix}_other_component_type`,
      value: 'dc_optimizer',
    },
  },
  {
    source: 'dc_optimizer_max_output_voltage',
    label: 'DC Optimizer Max Output Voltage',
    component: 'Number',
    unit: 'V',
    validate: requiredPositiveOrZero,
    dependentInputParams: {
      dependsOn: `${componentFieldPrefix}_other_component_type`,
      value: 'dc_optimizer',
    },
  },
  {
    source: 'dc_optimizer_max_output_current',
    label: 'DC Optimizer Max Output Current',
    component: 'Number',
    unit: 'A',
    validate: requiredPositiveOrZero,
    dependentInputParams: {
      dependsOn: `${componentFieldPrefix}_other_component_type`,
      value: 'dc_optimizer',
    },
  },
  {
    source: 'current_type',
    label: 'Current Type',
    component: 'Select',
    validate: required,
    selectOptions: CURRENT_TYPE_OPTIONS,
    dependentInputParams: {
      dependsOn: `${componentFieldPrefix}_other_component_type`,
      resolve: (value) => ['isolator', 'cable'].includes(value),
    },
  },
  {
    source: 'current_rating',
    label: 'Current Rating',
    component: 'Number',
    unit: 'A',
    validate: requiredPositiveOrZero,
    dependentInputParams: {
      dependsOn: `${componentFieldPrefix}_other_component_type`,
      value: 'isolator',
    },
  },
  {
    source: 'voltage_to_current_rating',
    label: 'Voltage to current rating',
    component: 'String',
    unit: 'JSON',
    validate: required,
    dependentInputParams: {
      dependsOn: `${componentFieldPrefix}_other_component_type`,
      value: 'isolator',
    },
  },
  {
    source: 'voltage_rating',
    label: 'Voltage Rating',
    component: 'Number',
    unit: 'V',
    validate: positiveOrZero,
    dependentInputParams: {
      dependsOn: `${componentFieldPrefix}_other_component_type`,
      value: 'isolator',
    },
  },
  {
    source: 'cable_thickness',
    label: 'Cable Thickness',
    component: 'Number',
    unit: 'mm',
    validate: requiredPositiveOrZero,
    dependentInputParams: {
      dependsOn: `${componentFieldPrefix}_other_component_type`,
      value: 'cable',
    },
  },
  {
    source: 'cable_length',
    label: 'Cable Length',
    component: 'Number',
    unit: 'm',
    validate: requiredPositiveOrZero,
    dependentInputParams: {
      dependsOn: `${componentFieldPrefix}_other_component_type`,
      value: 'cable',
    },
  },
  {
    source: 'phase_type',
    label: 'Phase Type',
    component: 'Select',
    validate: required,
    selectOptions: PHASE_TYPE_OPTIONS,
    dependentInputParams: {
      dependsOn: `${componentFieldPrefix}_other_component_type`,
      resolve: (value) => ['isolator', 'meter', 'heat_pump'].includes(value),
    },
  },
  {
    source: 'weight',
    label: 'Weight',
    component: 'Number',
    unit: 'kg',
  },
  {
    source: 'description',
    label: 'Description',
    component: 'LongTextInput',
  },
  {
    source: 'additional_costs_enabled',
    label: 'Enable Additional Costs',
    component: 'Boolean',
  },
  {
    source: 'formula',
    label: 'Formula',
    component: 'Select',
    selectOptions: FORMULA_OPTIONS,
    dependentInputParams: {
      dependsOn: `${componentFieldPrefix}_additional_costs_enabled`,
      value: true,
    },
  },
  {
    source: 'value',
    label: 'Value',
    component: 'Number',
    dependentInputParams: {
      dependsOn: `${componentFieldPrefix}_additional_costs_enabled`,
      value: true,
    },
  },
  {
    source: 'cycle_units',
    label: 'Cycle Units',
    component: 'Select',
    selectOptions: CYCLE_UNITS_OPTIONS,
    dependentInputParams: {
      dependsOn: `${componentFieldPrefix}_additional_costs_enabled`,
      value: true,
    },
  },
  {
    source: 'start',
    label: 'Start Cycle',
    component: 'Number',
    dependentInputParams: {
      dependsOn: `${componentFieldPrefix}_additional_costs_enabled`,
      value: true,
    },
  },
  {
    source: 'end',
    label: 'End Cycle',
    component: 'Number',
    dependentInputParams: {
      dependsOn: `${componentFieldPrefix}_additional_costs_enabled`,
      value: true,
    },
  },
  {
    source: 'annual_adjustment_percentage',
    label: 'Annual Adjustment',
    component: 'Number',
    unit: '%',
    dependentInputParams: {
      dependsOn: `${componentFieldPrefix}_additional_costs_enabled`,
      value: true,
    },
  },
  {
    source: 'product_warranty',
    label: 'Product Warranty',
    component: 'Number',
    unit: 'years',
    validate: positiveOrZero,
    softWarning: <SoftWarning source="custom_data_field_product_warranty" max={30} />,
  },
  {
    source: 'skus',
    label: 'SKUs',
    component: 'String',
    unit: 'csv',
  },
]

const osSpecificOtherDataConfigs: ComponentConfig[] = [
  {
    source: 'show_customer',
    label: 'Show Customer',
    component: 'Boolean',
  },
  {
    source: 'show_in_your_solution',
    label: 'Show in Your Solution Section',
    component: 'Boolean',
  },
  {
    source: 'show_in_quotation_table',
    label: 'Show in Quotation Table',
    component: 'Boolean',
  },
]

export const customOtherDataConfigs: ComponentConfig[] = [...otherComponentDataConfigs, ...osSpecificOtherDataConfigs]

// TODO: to clean up the usage of customOtherDataFields and remove this method
export const customOtherDataFields = convertToLegacyCustomFields(customOtherDataConfigs)

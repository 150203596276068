import appStorage from 'storage/appStorage'
import { PrivateFileType } from 'types/privateFile'

const FILE_TYPE_TO_TYPE_MAP = {
  pdf: { fileType: 'pdf', hasPreview: true },
  jpg: { fileType: 'img', hasPreview: true },
  jpeg: { fileType: 'img', hasPreview: true },
  png: { fileType: 'img', hasPreview: true },
  gif: { fileType: 'img', hasPreview: true },
  svg: { fileType: 'img', hasPreview: true },
  webp: { fileType: 'img', hasPreview: true },
  bmp: { fileType: 'img', hasPreview: true },
  tiff: { fileType: 'img', hasPreview: true },
  tif: { fileType: 'img', hasPreview: true },
  docx: { fileType: 'doc', hasPreview: false },
  doc: { fileType: 'doc', hasPreview: false },
  xlsx: { fileType: 'sheet', hasPreview: false },
  xls: { fileType: 'sheet', hasPreview: false },
  csv: { fileType: 'sheet', hasPreview: false },
  tsv: { fileType: 'sheet', hasPreview: false },
  other: { fileType: 'other', hasPreview: false },
}

const imgOrPdfFileExtensions = Object.keys(FILE_TYPE_TO_TYPE_MAP).filter(
  (t) => FILE_TYPE_TO_TYPE_MAP[t].fileType === 'img' || FILE_TYPE_TO_TYPE_MAP[t].fileType === 'pdf'
)

export const imgOrPdfTypeExtensionsAsString = imgOrPdfFileExtensions.map((e) => '.' + e).join()

export const getFileExtension = ({ file_contents = '' }: PrivateFileType): string => {
  const urlWithoutParams = file_contents.split('?AWS')[0] || ''
  const extension = urlWithoutParams.split('.').pop()
  return extension ?? ''
}

export const enterEscFormSubmitCancel = (event, id) => {
  if (event.key === 'Enter' || event.key === 'Escape') {
    event.preventDefault()
    event.stopPropagation()
    const form = event.target.form
    if (form) {
      const submitButton = form.querySelector(`button[id="save-form-on-enter-btn--${id}"]`)
      if (submitButton && event.key === 'Enter') {
        submitButton.click()
      }
      const calcelButton = form.querySelector(`button[id="close-form-on-esc-btn--${id}"]`)
      if (calcelButton && event.key === 'Escape') {
        calcelButton.click()
      }
    }
  }
}

export const previewInfo = (record: PrivateFileType) => {
  const ext = getFileExtension(record)
  return FILE_TYPE_TO_TYPE_MAP[ext] || FILE_TYPE_TO_TYPE_MAP.other
}

interface GetBlobProps {
  body?: string | null
  method: string
  url: string
}

export const getBlob = async (blobProps: GetBlobProps): Promise<Blob | null> => {
  const { body = null, method, url } = blobProps
  const reqInfo = {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + appStorage.getToken(),
    },
  }
  const formattedInfo = method === 'POST' && body ? { ...reqInfo, body } : reqInfo
  try {
    const res = await fetch(url, formattedInfo)
    const { statusText, ok, status } = res
    if (!ok) {
      throw new Error(`${status} ${statusText}`)
    } else {
      const blob: Blob = await res.blob()
      return blob
    }
  } catch (e) {
    console.error(e)
    return null
  }
}

export const downloadDesktop = (blob: Blob, filename: string) => {
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename)
  document.body.appendChild(link)

  if (typeof link.download !== 'undefined') {
    link.click()
  } else {
    // safari download
    const evt = document.createEvent('MouseEvents')
    evt.initEvent('click', true, true)
    link.dispatchEvent(evt)
  }

  document.body.removeChild(link)
  window.URL.revokeObjectURL(url)
}

const sanitizeFileName = (fileName: string): string => {
  return fileName.substring(0, fileName.lastIndexOf('.')) || fileName
}

interface DownloadInfo extends GetBlobProps {
  filename: string
  fileType: string
}

export const generateLink = (blob, filename) => {
  const url = window.URL.createObjectURL(new Blob([blob]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  //@ts-ignore
  link.parentNode.removeChild(link)
}

const startDownload = async (blobProps: DownloadInfo) => {
  const { url, fileType, method, body = null } = blobProps
  const filename = sanitizeFileName(blobProps.filename)
  if (window.RUNNING_AS_APP) {
    window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'downloadFile', url, filename, fileType }))
  } else {
    // Causing a CORS issue in real envs (dev/staging/prod)
    // const blob: Blob | null = await getBlob({ url, body, method })
    // if (blob) {
    //   downloadDesktop(blob, `${filename}.${fileType}`)
    // }

    fetch(url).then(function (t) {
      return t.blob().then((b) => {
        generateLink(b, `${filename}.${fileType}`)
      })
    })
  }
}

export const downloadFile = async (record: PrivateFileType) => {
  const { file_contents, title } = record
  const ext = getFileExtension(record)
  const downloadInfo: DownloadInfo = {
    url: file_contents,
    filename: title,
    fileType: ext,
    method: 'GET',
  }
  startDownload(downloadInfo)
}

// export const downloadMultipleFilesAsZip = async (file_ids: string[]) => {
//   const projectId = window.WorkspaceHelper.project.id
//   const orgId = window.WorkspaceHelper.project.org_id
//   const body = JSON.stringify({ file_ids })
//   const zipUrl = `${window.API_ROOT}/api/orgs/${orgId}/projects/${projectId}/compress_files/`
//   const timestamp = new Date().toISOString().slice(0, 10)
//   const downloadInfo: DownloadInfo = {
//     url: zipUrl,
//     filename: timestamp,
//     fileType: 'zip',
//     method: 'POST',
//     body,
//   }
//   startDownload(downloadInfo)
// }

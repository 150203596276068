import { makeStyles } from '@material-ui/core'
import { Chip } from 'opensolar-ui'
import { getProjectSectionStyles } from 'projectSections/sections/manage3/styles'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { TASK_STATUS_CHOICES } from 'resources/events/Events'
import { StageActionEventType } from 'types/projects'
import { Theme } from 'types/themes'
import { EVENT_TYPE_CHOICES } from '../formVariants/TaskFormContent'
const useStyles = makeStyles((theme: Theme) => ({
  statusChip: {
    marginRight: 10,
  },
  wrapper: {
    marginTop: 10,
    '&.min': {
      marginTop: 0,
      marginBottom: 5,
    },
  },
}))

interface PropTypes {
  event: StageActionEventType
  minDisplay?: boolean
}

const TaskStatus: React.FC<PropTypes> = ({ event, minDisplay }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const sectionStyles = getProjectSectionStyles()
  const retrieveStatusClass = (status) => {
    if (status === 0) return 'info'
    if (status === 1) return 'error'
    if (status === 2) return 'success'
  }
  const taskStatus = useMemo(() => TASK_STATUS_CHOICES.find((x) => x.id === event.task_status), [event.task_status])
  const taskType = useMemo(() => EVENT_TYPE_CHOICES.find((x) => x.id === event.event_type_id), [event.event_type_id])
  if (!event.categories?.includes(3)) return null
  return (
    <div className={`${sectionStyles.row} ${classes.wrapper} ${minDisplay ? 'min' : ''}`}>
      {taskType && <Chip color="info" label={translate(taskType?.name)} className={classes.statusChip} />}
      {taskStatus && (
        <Chip
          color={retrieveStatusClass(taskStatus?.id)}
          label={translate(taskStatus?.name)}
          className={classes.statusChip}
        />
      )}
    </div>
  )
}

export default TaskStatus

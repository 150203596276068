import { orderSelectors } from 'ducks/orderComponents'
import { useLayoutEffect, useMemo, useState } from 'react'
import { useDataProvider } from 'react-admin'
import { useDispatch, useSelector, useStore } from 'react-redux'
import restClient from 'restClient'
import ProjectOrderPresenter from '../ProjectOrderPresenter/projectOrderPresenter'
import ProjectTablePresenter from './projectTablePresenter'

export type SelectedSystemMapType = {
  [id: number]: number
}

export type ProjectTableStore = {
  selectedSystemMap: SelectedSystemMapType
}

const defaultStoreValue: ProjectTableStore = {
  selectedSystemMap: {},
}

const useProjectTable = (): [ProjectTableStore, ProjectTablePresenter] => {
  const [projectTableStore, setProjectTableStore] = useState<ProjectTableStore>(defaultStoreValue)
  const store = useStore()
  const dispatch = useDispatch()
  const dataProvider = useDataProvider()
  const lineItems = useSelector(orderSelectors.getOrderLineItems)

  const orderingPresenter = useMemo(
    () => new ProjectOrderPresenter(store, dispatch, restClient(window.API_ROOT + '/api')),
    [store, dispatch]
  )

  const projectTablePresenter = useMemo(
    () => new ProjectTablePresenter(setProjectTableStore, orderingPresenter, dataProvider),
    [store, dispatch]
  )

  useLayoutEffect(() => {
    projectTablePresenter.populateSelectedSystemMap(lineItems)
  }, [])

  return [projectTableStore, projectTablePresenter]
}

export default useProjectTable

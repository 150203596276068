const ModuleSpacingPortraitIcon = () => (
  <svg width="100%" height="100%" viewBox="0 0 232 233" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.343018" width="232" height="232" fill="white" />
    <path
      d="M217 57.6716V54.1716L186.311 50.843H185L154 54.1716H147L116 50.843H115L84 54.1716H77L46 50.843H45L14 52.7972V58.0222L45 54.1716H46L77 57.6716H84L115 54.1716H116L147 57.6716H154L185 54.1716H186.311L217 57.6716Z"
      fill="#FFAB1F"
    />
    <path
      d="M217 122.672V119.172L186.311 115.843H185L154 119.172H147L116 115.843H115L84 119.172H77L46 115.843H45L14 117.797V123.022L45 119.172H46L77 122.672H84L115 119.172H116L147 122.672H154L185 119.172H186.311L217 122.672Z"
      fill="#FFAB1F"
    />
    <path
      d="M217 187.672V184.172L186.311 180.843H185L154 184.172H147L116 180.843H115L84 184.172H77L46 180.843H45L14 182.797V188.022L45 184.172H46L77 187.672H84L115 184.172H116L147 187.672H154L185 184.172H186.311L217 187.672Z"
      fill="#FFAB1F"
    />
    <path d="M14 30.4759L45 27.1714V50.8669L14 54.1714V30.4759Z" fill="#727272" />
    <path
      d="M19.8333 40.0881L17.9249 42.1718L19.8333 44.2548"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M46 27.1714L77 30.4759V54.1714L46 50.8669V27.1714Z" fill="#727272" />
    <path
      d="M71.1667 40.0881L73.0751 42.1718L71.1667 44.2548"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14 57.4759L45 54.1714V77.8669L14 81.1714V57.4759Z" fill="#727272" />
    <path d="M46 54.1714L77 57.4759V81.1714L46 77.8669V54.1714Z" fill="#727272" />
    <path
      d="M19.8333 66.0881L17.9249 68.1718L19.8333 70.2548"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71.1667 66.0881L73.0751 68.1718L71.1667 70.2548"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M84 30.4759L115 27.1714V50.8669L84 54.1714V30.4759Z" fill="#727272" />
    <path
      d="M89.8333 40.0881L87.9249 42.1718L89.8333 44.2548"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M116 27.1714L147 30.4759V54.1714L116 50.8669V27.1714Z" fill="#727272" />
    <path
      d="M141.167 40.0881L143.075 42.1718L141.167 44.2548"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M84 57.4759L115 54.1714V77.8669L84 81.1714V57.4759Z" fill="#727272" />
    <path d="M116 54.1714L147 57.4759V81.1714L116 77.8669V54.1714Z" fill="#727272" />
    <path
      d="M89.8333 66.0881L87.9249 68.1718L89.8333 70.2548"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M141.167 66.0881L143.075 68.1718L141.167 70.2548"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M154 30.4759L185 27.1714V50.8669L154 54.1714V30.4759Z" fill="#727272" />
    <path
      d="M159.833 40.0881L157.925 42.1718L159.833 44.2548"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M186 27.1714L217 30.4759V54.1714L186 50.8669V27.1714Z" fill="#727272" />
    <path
      d="M211.167 40.0881L213.075 42.1718L211.167 44.2548"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M154 57.4759L185 54.1714V77.8669L154 81.1714V57.4759Z" fill="#727272" />
    <path d="M186 54.1714L217 57.4759V81.1714L186 77.8669V54.1714Z" fill="#727272" />
    <path
      d="M159.833 66.0881L157.925 68.1718L159.833 70.2548"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M211.167 66.0881L213.075 68.1718L211.167 70.2548"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14 160.476L45 157.171V180.867L14 184.171V160.476Z" fill="#727272" />
    <path
      d="M19.8333 170.088L17.9249 172.172L19.8333 174.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M46 157.171L77 160.476V184.171L46 180.867V157.171Z" fill="#727272" />
    <path
      d="M71.1667 170.088L73.0751 172.172L71.1667 174.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14 187.476L45 184.171V207.867L14 211.171V187.476Z" fill="#727272" />
    <path d="M46 184.171L77 187.476V211.171L46 207.867V184.171Z" fill="#727272" />
    <path
      d="M19.8333 196.088L17.9249 198.172L19.8333 200.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71.1667 196.088L73.0751 198.172L71.1667 200.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M84 160.476L115 157.171V180.867L84 184.171V160.476Z" fill="#727272" />
    <path
      d="M89.8333 170.088L87.9249 172.172L89.8333 174.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M116 157.171L147 160.476V184.171L116 180.867V157.171Z" fill="#727272" />
    <path
      d="M141.167 170.088L143.075 172.172L141.167 174.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M84 187.476L115 184.171V207.867L84 211.171V187.476Z" fill="#727272" />
    <path d="M116 184.171L147 187.476V211.171L116 207.867V184.171Z" fill="#727272" />
    <path
      d="M89.8333 196.088L87.9249 198.172L89.8333 200.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M141.167 196.088L143.075 198.172L141.167 200.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M154 160.476L185 157.171V180.867L154 184.171V160.476Z" fill="#727272" />
    <path
      d="M159.833 170.088L157.925 172.172L159.833 174.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M186 157.171L217 160.476V184.171L186 180.867V157.171Z" fill="#727272" />
    <path
      d="M211.167 170.088L213.075 172.172L211.167 174.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M154 187.476L185 184.171V207.867L154 211.171V187.476Z" fill="#727272" />
    <path d="M186 184.171L217 187.476V211.171L186 207.867V184.171Z" fill="#727272" />
    <path
      d="M159.833 196.088L157.925 198.172L159.833 200.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M211.167 196.088L213.075 198.172L211.167 200.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14 95.4759L45 92.1714V115.867L14 119.171V95.4759Z" fill="#727272" />
    <path
      d="M19.8333 105.088L17.9249 107.172L19.8333 109.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M46 92.1714L77 95.4759V119.171L46 115.867V92.1714Z" fill="#727272" />
    <path
      d="M71.1667 105.088L73.0751 107.172L71.1667 109.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14 122.476L45 119.171V142.867L14 146.171V122.476Z" fill="#727272" />
    <path d="M46 119.171L77 122.476V146.171L46 142.867V119.171Z" fill="#727272" />
    <path
      d="M19.8333 131.088L17.9249 133.172L19.8333 135.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71.1667 131.088L73.0751 133.172L71.1667 135.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M84 95.4759L115 92.1714V115.867L84 119.171V95.4759Z" fill="#727272" />
    <path
      d="M89.8333 105.088L87.9249 107.172L89.8333 109.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M116 92.1714L147 95.4759V119.171L116 115.867V92.1714Z" fill="#727272" />
    <path
      d="M141.167 105.088L143.075 107.172L141.167 109.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M84 122.476L115 119.171V142.867L84 146.171V122.476Z" fill="#727272" />
    <path d="M116 119.171L147 122.476V146.171L116 142.867V119.171Z" fill="#727272" />
    <path
      d="M89.8333 131.088L87.9249 133.172L89.8333 135.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M141.167 131.088L143.075 133.172L141.167 135.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M154 95.4759L185 92.1714V115.867L154 119.171V95.4759Z" fill="#727272" />
    <path
      d="M159.833 105.088L157.925 107.172L159.833 109.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M186 92.1714L217 95.4759V119.171L186 115.867V92.1714Z" fill="#727272" />
    <path
      d="M211.167 105.088L213.075 107.172L211.167 109.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M154 122.476L185 119.171V142.867L154 146.171V122.476Z" fill="#727272" />
    <path d="M186 119.171L217 122.476V146.171L186 142.867V119.171Z" fill="#727272" />
    <path
      d="M159.833 131.088L157.925 133.172L159.833 135.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M211.167 131.088L213.075 133.172L211.167 135.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ModuleSpacingPortraitIcon

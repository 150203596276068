import PropTypes from 'prop-types'
import { Component } from 'react'
import { withTranslate } from 'react-admin'
import compose from 'recompose/compose'
import withStudioSignals from 'Studio/signals/withStudioSignals'
import { refreshPanelGeneric } from '../Studio/Utils'
import HeterogeneousPanelGroup from './PanelGroup/HeterogeneousPanelGroup'
import HomogeneousPanelGroup from './PanelGroup/HomogeneousPanelGroup'
import { isHomogeneousGroup } from './PanelGroup/utils'
class PanelOsGroup extends Component {
  constructor(props) {
    super(props)
    var state = {
      visible: false,
      group: null,
      allowEdit: true,
    }

    var injectState = props.state ? props.state : null
    if (props.group) {
      injectState = this.stateFromObject(props.group)
    }

    //Override with any supplied state - for use in storybook
    if (injectState) {
      for (var key in injectState) {
        state[key] = injectState[key]
      }
    }

    this.state = state

    this.props.useStudioSignalsLazy(this.refreshPanel, ['objectChanged', 'objectSelected', 'sceneGraphChanged'], this)
  }

  stateFromObject(group) {
    if (!group) {
      return { visible: false }
    }
    const allObjectsAreFacets = group.objects.every((child) => child.type === 'OsFacet')
    return {
      title: allObjectsAreFacets ? 'Structure' : 'Selection', // TODO: move to hommo panel
      visible: true,
      group: group,
    }
  }

  refreshPanel() {
    refreshPanelGeneric.call(this, 'OsGroup')
  }

  render() {
    if (!this.state.visible) return null
    if (!this.state.group) return null

    if (isHomogeneousGroup(this.state.group)) {
      return <HomogeneousPanelGroup group={this.state.group} />
    }

    return <HeterogeneousPanelGroup group={this.state.group} />
  }
}

PanelOsGroup.propTypes = {
  state: PropTypes.object,
}

export default compose(withTranslate, withStudioSignals)(PanelOsGroup)

// @ts-nocheck
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import { authSelectors } from 'ducks/auth'
import { removeWalletCreditCardFromDate, walletSettingsSelectors } from 'ducks/wallet'
import { Button, IconButton } from 'opensolar-ui'
import React, { useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'
import { WalletPaymentMethodType } from 'types/global'
const API_URL = window.API_ROOT + '/api'

type PropTypes = {
  paymentMethod: WalletPaymentMethodType
}

const WalletCreditCard: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const notify = useNotify()
  const dispatch = useDispatch()

  const orgId: number = useSelector(authSelectors.getCurrentOrg)?.id
  const walletId: number = useSelector(walletSettingsSelectors.getWallet)?.id

  const [showDialog, setShowDialog] = useState<boolean>(false)

  const doDelete = () => {
    const restClientInstance = restClient(API_URL)
    restClientInstance('CUSTOM_DELETE', 'custom', {
      url: `orgs/${orgId}/wallets/${walletId}/wallet_credit_cards/${props.paymentMethod.id}/`,
    })
      .then((response: any) => {
        notify('Payment Method Deleted', 'success')
        dispatch(removeWalletCreditCardFromDate(props.paymentMethod.id, walletId))
        setShowDialog(false)
      })
      .catch((err: any) => {
        console.log('error deleting wallet credit card', err)
        notify('Unable to delete payment method', 'warning')
        setShowDialog(false)
      })
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
      <div>Credit Card Number: **** **** **** {props.paymentMethod.last_four}</div>
      <div style={{ marginLeft: '20px' }}>Exp: **/ {props.paymentMethod.exp_year}</div>
      <div style={{ marginLeft: '20px' }}>CVV: ***</div>
      <div style={{ marginLeft: '20px' }}>
        <IconButton style={{ color: 'black' }} onClick={() => setShowDialog(true)}>
          <DeleteOutlinedIcon style={{ width: 20, height: 20 }} />
        </IconButton>
      </div>
      {showDialog && (
        <Dialog open={showDialog} onClose={() => setShowDialog(false)} aria-labelledby="confirm-dialog">
          <DialogTitle id="confirm-dialog">{translate('Delete Payment Method?')}</DialogTitle>
          <DialogContent>
            <p>
              {translate(
                'Are you sure you want to delete this payment method? This action cannot be undone and if you have auto top-up enabled we will no longer be able to automatically add funds with this card.'
              )}
            </p>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => setShowDialog(false)}>
              Cancel
            </Button>
            <Button variant="contained" onClick={doDelete} color="primary">
              Yes, Permanently Delete It
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  )
}
export default WalletCreditCard

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles, Paper, Popper } from '@material-ui/core';
var useStyles = makeStyles({
    suggestionsContainer: {
        zIndex: 3000,
        maxWidth: '100%',
    },
    suggestionsPaper: {
        maxHeight: '40vh',
        overflowY: 'auto',
    },
}, { name: 'RaAutocompleteSuggestionList' });
var AutocompleteSuggestionList = function (props) {
    var children = props.children, isOpen = props.isOpen, menuProps = props.menuProps, inputEl = props.inputEl, suggestionsContainerProps = props.suggestionsContainerProps, _a = props.PaperStyle, PaperStyle = _a === void 0 ? {} : _a, _b = props.variant, variant = _b === void 0 ? 'elevation' : _b;
    var classes = useStyles(props);
    return (_jsx(Popper, __assign({ open: isOpen, anchorEl: inputEl, className: classes.suggestionsContainer, modifiers: {} }, suggestionsContainerProps, { children: _jsx("div", __assign({}, (isOpen ? menuProps : {}), { children: _jsx(Paper, __assign({ style: __assign({ marginTop: 8, minWidth: inputEl ? inputEl.clientWidth : null }, PaperStyle), variant: variant, className: classes.suggestionsPaper }, { children: children })) })) })));
};
export default AutocompleteSuggestionList;

import { combineReducers } from 'redux'
import { projectErrorPromptsReducer } from './projectErrorPromptsReducer'
import { projectErrorsReducer } from './projectErrorsReducer'
import { projectInfoReducer } from './projectInfoReducer'
import { projectSectionReducer } from './sectionReducer'

export default combineReducers({
  info: projectInfoReducer,
  errors: projectErrorsReducer,
  errorPrompts: projectErrorPromptsReducer,
  section: projectSectionReducer,
})

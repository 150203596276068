export const SET_SEGEN_ACCESS_TOKEN = 'SET_SEGEN_ACCESS_TOKEN'

export const setSegenAccessToken = (value) => ({
  type: SET_SEGEN_ACCESS_TOKEN,
  payload: value,
})

export default function reducer(previousState = null, { type, payload }) {
  if (!previousState) {
    previousState = {}
  }

  if (type === SET_SEGEN_ACCESS_TOKEN) {
    return { ...previousState, accessToken: payload }
  }

  return previousState
}

export const hasSegenAccessTokenSelector = (state) => {
  return Boolean(state.segen?.accessToken)
}

export const segenSelectors = {
  getAccessToken: (state) => state.segen?.accessToken,
}

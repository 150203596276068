import CallIcon from '@material-ui/icons/CallOutlined'
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined'
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined'
import { IconButton } from 'opensolar-ui'
import React from 'react'

const iconStyle = {
  paddingBottom: 0,
  width: 30,
  height: 30,
  margin: 0,
  padding: 6,
}

export const ContactButtonsHome = ({ contact }) => (
  <div
    style={{
      display: 'inline-block',
      verticalAlign: 'middle',
    }}
  >
    <EmailButtonHome contact={contact} style={{}} />
    <PhoneButtonHome contact={contact} />
    <SmsButtonHome contact={contact} />
  </div>
)

const PhoneButtonHome = ({ contact }) =>
  contact && contact.phone && contact.phone.length > 0 ? (
    <IconButton onClick={() => (window.location.href = 'tel:' + contact.phone)} disabled={false} style={iconStyle}>
      <CallIcon style={{ color: '#4d4d4d', width: 16, height: 16 }} />
    </IconButton>
  ) : (
    <IconButton disabled={true} style={iconStyle}>
      <CallIcon style={{ width: 16, height: 16 }} />
    </IconButton>
  )

const SmsButtonHome = ({ contact }) =>
  contact && contact.phone && contact.phone.length > 0 ? (
    <IconButton
      onClick={() => (window.location.href = 'sms:' + contact.phone)}
      disabled={false}
      style={{ marginTop: 8, ...iconStyle }}
    >
      <MessageOutlinedIcon style={{ color: '#4d4d4d', width: 16, height: 16 }} />
    </IconButton>
  ) : (
    <IconButton disabled={true} style={{ marginTop: 8, ...iconStyle }}>
      <MessageOutlinedIcon style={{ width: 16, height: 16 }} />
    </IconButton>
  )

export const EmailButtonHome = ({ contact, style }) =>
  contact && contact.email && contact.email.length > 0 ? (
    <IconButton onClick={() => window.open('mailto:' + contact.email)} disabled={false} style={iconStyle}>
      <EmailOutlinedIcon style={{ color: '#4d4d4d', width: 16, height: 16 }} />
    </IconButton>
  ) : (
    <IconButton disabled={true} style={iconStyle}>
      <EmailOutlinedIcon style={{ width: 16, height: 16 }} />
    </IconButton>
  )

import Alert from 'elements/Alert'
import CustomField from 'elements/field/CustomField'
import CheckboxInput from 'elements/input/CheckboxInput'
import LoadingDots from 'layout/widgets/LoadingDots'
import { Button, Grid } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { TextInput } from 'ra-ui-materialui'
import React, { useEffect, useMemo, useState } from 'react'
import { useFormState } from 'react-final-form'
import restClient from 'restClient'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { currencySymbolForCountry, formatCurrencyWithSymbol } from 'util/misc'
import { BecsAgreementDataType, BECSPaymentDataType, PaymentRequestType } from '../types'
import BECSAgreementForm from './BECSAgreementForm'

const restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  projectId: string
  paymentRequestData: PaymentRequestType
  doSubmitPayment: (args?: BECSPaymentDataType) => void
  countryIso2: string
  orgName: string
  esignatureText: string
}

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {},
  nextBtn: {
    background: '#4272DD',
    color: theme.white,
    fontSize: 13,
    border: '1px solid #4272DD',
    float: 'right',
    margin: '10px 0',

    '&:hover': {
      background: '#4272DD !important',
      color: theme.white,
      fontSize: 13,
      border: '1px solid #4272DD',
    },
  },
  input: {
    backgroundColor: '#fff',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px',
    width: '100%',
  },
}))

const BECSForm: React.FC<PropTypes> = (props) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [agreementData, setAgreementData] = useState<BecsAgreementDataType | null>(null)
  const [isBankAgreementFormOpen, setIsBankAgreementFormOpen] = useState(false)

  const classes = useStyles()
  const translate = useTranslate()
  const currencySymbol = currencySymbolForCountry(props.countryIso2)
  const formState = useFormState()

  const handleOpenBankAgreementForm = () => {
    setIsBankAgreementFormOpen(true)
  }

  useEffect(() => {
    setIsLoading(true)
    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${props.paymentRequestData?.org_id}/projects/${props.projectId}/becs/create_agreement/`,
    })
      .then((response: any) => {
        setAgreementData(response.data.data)
      })
      .catch((error) => {
        setErrorMessage(error?.body?.message || 'An error occurred while fetching the form data.')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [props.paymentRequestData?.org_id, props.projectId])

  const validatePaymentData = () => {
    let newError: string | undefined = undefined
    const formattedBSB = formState.values.bsb_number.replace(/\D/g, '')
    if (!agreementData?.agreementId) {
      newError =
        'Sorry, we were unable to retrieve the Direct Debit agreement at this time. Please reach out to support@opensolar.com directly'
    } else if (!formState.values?.bsb_number) {
      newError = 'BSB Number is required'
    } else if (formattedBSB.length !== 6) {
      newError = 'BSB number must have exactly 6 digits.'
    } else if (!formState.values?.account_number) {
      newError = 'Account Number is required'
    } else if (!/^\d{1,10}$/.test(formState.values.account_number)) {
      newError = 'Account number must be between 1 and 10 digits.'
    } else if (formState.values.account_number !== formState.values.accountNumberConfirm) {
      newError = 'Account numbers do not match'
    } else if (!formState.values?.first_name) {
      newError = 'First Name is required'
    } else if (!formState.values?.last_name) {
      newError = 'Last Name is required'
    } else if (!formState.values?.zip_code) {
      newError = 'Postcode is required'
    } else if (!/^\d{4}$/.test(formState.values.zip_code)) {
      newError = 'Postcode must have 4 digits.'
    } else if (!formState.values?.authorizedByShopper) {
      newError = 'You must agree to use electronic signature before you can continue'
    }
    // Update the BSB number to formatted one (with the dash)
    if (!newError && formattedBSB) {
      formState.values.bsb_number = `${formattedBSB.slice(0, 3)}-${formattedBSB.slice(3)}`
    }
    setErrorMessage(newError)
    return newError
  }

  const onNext = (e?: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    const error = validatePaymentData()
    if (!error && agreementData?.agreementId) {
      props.doSubmitPayment({
        bsb_number: formState.values.bsb_number,
        account_number: formState.values.account_number,
        authorizedByShopper: formState.values.authorizedByShopper,
        agreementId: agreementData.agreementId,
        agreementText: agreementData.agreementText,
        first_name: formState.values.first_name,
        last_name: formState.values.last_name,
        zip_code: formState.values.zip_code,
      })
    }
  }

  const TranslatedTextWithLink = ({ textArray, handleLinkClick }) => {
    const translatedText = useMemo(() => {
      return textArray
        .map((part, index) => {
          const translatedPart = translate(part)

          if (index === 1) {
            return (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault()
                  handleLinkClick()
                }}
                key={index}
              >
                {translatedPart}
              </a>
            )
          }
          return translatedPart
        })
        .reduce(
          (acc, part, index) => (
            <>
              {acc}
              {index > 0 ? ' ' : ''}
              {part}
            </>
          ),
          null
        )
    }, [textArray])

    return <>{translatedText}</>
  }

  const nextIsDisabled = useMemo(() => {
    return (
      !formState.values?.bsb_number ||
      !formState.values?.account_number ||
      formState.values?.accountNumberConfirm !== formState.values?.account_number ||
      !formState.values?.authorizedByShopper ||
      !formState.values?.first_name ||
      !formState.values?.last_name ||
      !formState.values?.zip_code
    )
  }, [formState.values])

  if (isLoading) {
    return (
      <div className={classes.loadingContainer}>
        <LoadingDots />
      </div>
    )
  }

  return (
    <div className={classes.wrapper}>
      <div>
        {translate('Amount')}: {formatCurrencyWithSymbol(props.paymentRequestData.payment_amount, currencySymbol)}
      </div>
      <div>
        {translate('Frequency of Debits')}: {translate('Single')}
      </div>
      <div style={{ marginBottom: '20px' }}>
        {translate('Date of Authorization')}: {new Date().toLocaleDateString()}
      </div>
      <form action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextInput
              variant="outlined"
              label={translate('BSB Number')}
              name="bsb_number"
              source="bsb_number"
              placeholder="Enter 6 digits"
              style={{ height: '45px', padding: '0px', margin: '0px' }}
              inputProps={{ className: classes.input }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              variant="outlined"
              label={translate('Account Number')}
              name="account_number"
              source="account_number"
              placeholder="0123456789"
              style={{ height: '45px', padding: '0px', margin: '0px' }}
              inputProps={{ className: classes.input }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              variant="outlined"
              label={translate('Confirm Account Number')}
              name="accountNumberConfirm"
              source="accountNumberConfirm"
              placeholder="0123456789"
              style={{ height: '45px', padding: '0px', margin: '0px' }}
              inputProps={{ className: classes.input }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              variant="outlined"
              label={translate('First Name')}
              name="first_name"
              source="first_name"
              style={{ height: '45px', padding: '0px', margin: '0px' }}
              inputProps={{ className: classes.input }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              variant="outlined"
              label={translate('Last Name')}
              name="last_name"
              source="last_name"
              style={{ height: '45px', padding: '0px', margin: '0px' }}
              inputProps={{ className: classes.input }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              variant="outlined"
              label={translate('Zip Code')}
              name="zip_code"
              source="zip_code"
              placeholder="1234"
              style={{ height: '45px', padding: '0px', margin: '0px' }}
              inputProps={{ className: classes.input }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <CustomField
              name="authorizedByShopper"
              source="authorizedByShopper"
              label={
                <TranslatedTextWithLink
                  textArray={props.esignatureText}
                  handleLinkClick={handleOpenBankAgreementForm}
                />
              }
              component={(checkboxProps: any) => <CheckboxInput {...checkboxProps} elStyle={{ maxWidth: '900px' }} />}
              fullWidth
              required
              style={{ width: '100%', margin: '5px 0px', padding: '0px' }}
            />
          </Grid>
          {errorMessage && (
            <Grid item xs={12}>
              <Alert severity="error">{translate(errorMessage)}</Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <div>
              <Button
                className={classes.nextBtn}
                // @ts-ignore this event ends up being overridden by bluesnap's SDK
                onClick={onNext}
                type="button"
                variant="contained"
                disabled={nextIsDisabled}
              >
                {translate('Next')}
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
      {isBankAgreementFormOpen && (
        <BECSAgreementForm
          open={isBankAgreementFormOpen}
          onClose={() => setIsBankAgreementFormOpen(false)}
          agreementData={agreementData}
        />
      )}
    </div>
  )
}
export default BECSForm

import { MenuItem } from '@material-ui/core'
import { CustomNumberField } from 'elements/field/CustomNumberField'
import CustomSelectField from 'elements/field/CustomSelectField'
import { DistanceNumberField } from 'elements/field/DistanceNumberField'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { withTranslate } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import withStudioSignals from 'Studio/signals/withStudioSignals'
import Panel, { StudioPanelContext } from '../Designer/Panel'
import { refreshPanelGeneric, stripKeyForActiveTextField } from '../Studio/Utils'

import { calculateEmptyAsZero } from 'elements/field/transforms'
import { feetToMeters, getMeasurementsFromState, metersToFeet, trimDecimalPlaces } from '../util/misc'

const DIMENSION_LABELS = {
  x: 'Length',
  y: 'Width',
  z: 'Height',
}

const SLOPES_TO_SHOW_BY_MODE = {
  aframe: [0],
  hip: [0],
  shed: [],
  aframe_dormer: [0],
  hip_dormer: [0],
  shed_dormer: [1],
}

const SLOPES_TO_COPY_FIRST_VALUE_BY_MODE = {
  aframe: [0, 2],
  hip: [0, 1, 2, 3],
  shed: [],
  aframe_dormer: [0, 2],
  hip_dormer: [0, 1, 2],
  shed_dormer: [1],
}

class PanelStructure extends Component {
  constructor(props) {
    super(props)
    var state = {
      visible: false,
      object: null,
      invalidSlopeError: null,
      allowEdit: true,
    }

    var injectState = props.state ? props.state : null
    if (props.object) {
      injectState = this.stateFromObject(props.object)
    }

    //Override with any supplied state - for use in storybook
    if (injectState) {
      for (var key in injectState) {
        state[key] = injectState[key]
      }
    }

    this.state = state
    this.hash = null
  }

  componentDidMount() {
    this.props.useStudioSignalsLazy(this.refreshPanel, ['objectChanged', 'objectSelected', 'sceneGraphChanged'], this)
  }

  getHash(newState) {
    if (newState) {
      return JSON.stringify(newState)
    }
    var stateForHash = {}
    Object.keys(this.state).map((key) =>
      key === 'object'
        ? (stateForHash.object_uuid = this.state.object ? this.state.object.uuid : null)
        : (stateForHash[key] = this.state[key])
    )
    return JSON.stringify(stateForHash)
  }

  stateFromObject(object) {
    if (!object || object.type !== 'OsStructure') {
      return { visible: false }
    }
    var _state = {
      visible: true,
      object: object,
      ...this.getDimensions(object),
      slopes: this.getSlopes(object),
      rotationZ: Math.round(object.getAzimuth() * 100) / 100,
      slopesToShow: SLOPES_TO_SHOW_BY_MODE[object.mode] || [0, 1, 2, 3],
    }

    stripKeyForActiveTextField.call(this, _state, object)
    return _state
  }

  refreshPanel() {
    var newState = this.stateFromObject(window.editor.selected)
    var newHash = this.getHash(newState)
    if (this.hash !== newHash) {
      this.hash = newHash
      refreshPanelGeneric.call(this, 'OsStructure')
    }
  }

  format = (value) => {
    const { measurements } = this.props
    if (measurements === 'imperial') {
      return trimDecimalPlaces(metersToFeet(value), 4)
    }
    return value
  }

  parse = (value) => {
    const { measurements } = this.props
    if (measurements === 'imperial') {
      return trimDecimalPlaces(feetToMeters(value), 6)
    }
    return value
  }

  getDimensions(object) {
    const result = {
      width: 0,
      height: 0,
      depth: 0,
    }
    if (object && object.geometry) {
      if (!object.geometry.boundingBox) {
        object.geometry.computeBoundingBox()
      }
      const min = object.geometry.boundingBox.min
      const max = object.geometry.boundingBox.max
      const scale = object.scale
      Object.keys(DIMENSION_LABELS).forEach((dimension) => {
        result[DIMENSION_LABELS[dimension]] = this.format((max[dimension] - min[dimension]) * scale[dimension])
      })
    }
    return result
  }

  getSlopes(object) {
    return object.slopes.map((x) => x.toString())
  }

  disableRotation() {
    const { object } = this.state
    let disabled = false
    if (object && object.rotation) {
      const x = object.rotation.x
      const y = object.rotation.y
      if (Math.abs(x, 0.1) > 0.1 || Math.abs(y, 0.1) > 0.1) {
        return true
      }
    }
    return disabled
  }

  getObjectBoundingBox() {
    if (this.state.object && this.state.object.boundingBoxOverrride) {
      return this.state.object.boundingBoxOverrride()
    } else {
      if (!this.state.object.geometry.boundingBox) {
        this.state.object.geometry.computeBoundingBox()
      }
      return this.state.object.geometry.boundingBox
    }
  }

  applyScaleValue(structure, scaleAxis, value) {
    const bbox = this.getObjectBoundingBox()
    const scaleValue = value / (bbox.max[scaleAxis] - bbox.min[scaleAxis])
    const newScale = structure.scale.clone()
    Object.assign(newScale, { [scaleAxis]: scaleValue })
    window.editor.execute(new window.SetScaleCommand(structure, newScale))
  }

  applySlopeValue(structure, slopeIndex, slopeValue, updateAllMatchingSlopes) {
    if (isNaN(slopeValue)) return
    slopeValue = slopeValue < 0 ? 0 : slopeValue // cap lowest value to 0 degrees

    var oldSlopes = structure.slopes.slice()
    var newSlopes = structure.slopes.slice()

    if (updateAllMatchingSlopes) {
      SLOPES_TO_COPY_FIRST_VALUE_BY_MODE[structure.mode].forEach((slopeIndexToUpdate) => {
        newSlopes[slopeIndexToUpdate] = slopeValue
      })
    } else {
      newSlopes[slopeIndex] = slopeValue
    }

    // attempt to rebuild the structure with the new slope values
    structure.setSlopes(newSlopes)
    if (structure.lastRebuildSuccessful()) {
      // executing SetSlopesCommand will dispatch an 'objectChanged' signal
      // disable objectChanged signal momentarily to prevent redundant call to rebuild()
      window.editor.deactivateSignal('objectChanged')
      window.editor.execute(new window.SetSlopesCommand(structure, newSlopes, oldSlopes))
      window.editor.activateSignal('objectChanged')
    } else {
      // failed to rebuild structure with the new slopes
      // computation error in the rebuild process
      // the new slope values might be invalid, but there can be other reasons
      // in any case, revert to the old slope values
      // debounce to not confuse the user why their input is ignored
      this.setState({ invalidSlopeError: { slopeIndex, error: 'Invalid slope. Reverting value...' } })
      var _this = this
      debounce(() => {
        _this.setState({ slopes: oldSlopes.map((s) => s.toString()), invalidSlopeError: null })
        structure.setSlopes(oldSlopes)
      }, 1000).call()
    }
  }

  applyRotationZ(structure, value) {
    value = -value / window.THREE.Math.RAD2DEG
    const rotation = structure.rotation.clone()
    rotation.z = value
    window.editor.execute(new window.SetRotationCommand(structure, rotation))
  }

  applyNumberOfStories(structure, value) {
    value = value * 4
    const position = structure.position.clone()
    position.z = value
    window.editor.execute(new window.SetPositionCommand(structure, position))
  }

  render() {
    if (this.state.visible !== true) {
      return null
    } else {
      const { object, rotationZ, slopesToShow } = this.state
      const number_of_stories = object.position.z / 4
      const { translate } = this.props

      return (
        <Panel
          showTools={this.state.allowEdit}
          selectedObject={this.state.object}
          title={object.getLabel()}
          summary={null}
          content={null}
          feature={
            <StudioPanelContext.Provider value={{ context: this, object }}>
              <div>
                {['x', 'y'].map((scaleAxis) => {
                  const key = DIMENSION_LABELS[scaleAxis]
                  const value = this.state[key]
                  return (
                    <DistanceNumberField
                      disabled={!this.state.allowEdit}
                      name={`structure-${key}`}
                      label={key}
                      value={value}
                      maxDecimalPlaces={3}
                      minValue={0.001}
                      minErrorMsg={translate('Supply a value greater than 0.')}
                      measurementStd={this.props.measurements}
                      convertTo="metric"
                      onChange={(newValue) => {
                        this.applyScaleValue(object, scaleAxis, newValue)
                      }}
                    />
                  )
                })}

                {slopesToShow.map((slopeIndex, slopesShownCounter) => {
                  const key = slopeIndex
                  const value = this.state.slopes[slopeIndex]
                  const updateAllMatchingSlopes = slopesToShow.length === 4 ? false : true
                  return (
                    <>
                      <CustomNumberField
                        disabled={!this.state.allowEdit}
                        name={`structure-slope-${key}`}
                        label={
                          slopesToShow.length === 1
                            ? translate('Slope')
                            : translate('Slope') + ' ' + (slopesShownCounter + 1)
                        }
                        minValue={0}
                        maxValue={90}
                        maxDecimalPlaces={2}
                        endAdornment="°"
                        minErrorMsg={translate('Slope must be greater than 0°.')}
                        maxErrorMsg={translate('Slope must not exceed 90°.')}
                        value={parseFloat(value)}
                        onChange={(newValue) => {
                          // update the component state
                          const stateSlopes = this.state.slopes.slice()
                          if (updateAllMatchingSlopes) {
                            SLOPES_TO_COPY_FIRST_VALUE_BY_MODE[object.mode].forEach((slopeIndexToUpdate) => {
                              stateSlopes[slopeIndexToUpdate] = newValue.toString()
                            })
                          } else {
                            stateSlopes[slopeIndex] = newValue.toString()
                          }
                          this.setState({ slopes: stateSlopes })
                          // attempt to apply the new slope value to the structure
                          this.applySlopeValue(object, slopeIndex, newValue, updateAllMatchingSlopes)
                        }}
                      />
                      {this.state.invalidSlopeError && this.state.invalidSlopeError.slopeIndex === slopeIndex && (
                        <p style={{ color: 'red' }}>{translate(this.state.invalidSlopeError.error)}</p>
                      )}
                    </>
                  )
                })}

                {slopesToShow.length < 4 && (
                  <a
                    href="#"
                    onClick={(e) => {
                      var newMode = object.modeForConvertToCustom()
                      window.editor.execute(new window.SetValueCommand(object, 'mode', newMode))
                      this.setState({ slopesToShow: SLOPES_TO_SHOW_BY_MODE[newMode] || [0, 1, 2, 3] })
                      e.preventDefault()
                    }}
                    className="small"
                    style={{ display: 'block', marginBottom: 15, marginTop: -5 }}
                  >
                    {translate('Edit slopes individually')}
                  </a>
                )}

                <CustomNumberField
                  disabled={!this.state.allowEdit || this.disableRotation()}
                  name="structure-rotation"
                  label="Rotation"
                  value={rotationZ}
                  minValue={0}
                  maxValue={360}
                  maxDecimalPlaces={2}
                  resettable={true}
                  resetValue={0}
                  endAdornment="°"
                  transformFunc={calculateEmptyAsZero}
                  onChange={(newValue) => {
                    this.applyRotationZ(object, newValue)
                  }}
                />

                {!object.isDormer() && (
                  <CustomNumberField
                    disabled={!this.state.allowEdit}
                    name="structure-stories"
                    value={number_of_stories}
                    minValue={0}
                    maxValue={object.MAX_STORIES}
                    label={translate('Number of Stories')}
                    onChange={(newValue) => {
                      this.applyNumberOfStories(object, newValue)
                    }}
                  />
                )}

                <CustomSelectField
                  style={{ width: 230, marginTop: 20 }}
                  label={translate('Show Customer')}
                  value={object.override_show_customer}
                  defaultValue={null}
                  displayEmpty={true}
                  disabled={!this.state.allowEdit}
                  onChange={(event) =>
                    window.editor.execute(
                      new window.SetValueCommand(
                        object,
                        'override_show_customer',
                        event.target.value,
                        window.Utils.generateCommandUUIDOrUseGlobal()
                      )
                    )
                  }
                >
                  <MenuItem value={null}>{translate('Default')}</MenuItem>
                  <MenuItem value={true}>{translate('Show')}</MenuItem>
                  <MenuItem value={false}>{translate('Hide')}</MenuItem>
                </CustomSelectField>
              </div>
            </StudioPanelContext.Provider>
          }
        />
      )
    }
  }
}

PanelStructure.propTypes = {
  state: PropTypes.object,
}

export default compose(
  withTranslate,
  withStudioSignals,
  connect(
    (state) => ({
      measurements: getMeasurementsFromState(state),
    }),
    {}
  )
)(PanelStructure)

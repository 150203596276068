import { Box } from 'opensolar-ui'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles(() => ({
  text: {
    color: '#212121',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '22px',
    margin: 0,
  },
}))

interface InverterDesignAssistantContainerProps {
  children: React.ReactNode
  title: string
}

const InverterDesignAssistantContainer: React.FC<InverterDesignAssistantContainerProps> = ({ children, title }) => {
  const classes = useStyles()

  return (
    <Box display="flex" flexDirection="column" gridGap={24}>
      <p className={classes.text}>{title}</p>
      {children}
    </Box>
  )
}

export default InverterDesignAssistantContainer

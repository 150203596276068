import InventoryForecastDetailTable from 'pages/inventory/forecast/detail/InventoryForecastDetail'
import InventoryForecastDetailDialog from 'pages/inventory/forecast/detail/InventoryForecastDetailDialog'
import type { ForecastDetailDialogController } from 'pages/inventory/forecast/detail/useForecastDetailDialogController'
import React, { useCallback } from 'react'
import type { InventoryType } from '../type'
import ComponentForecastDialogToolbar from './ComponentForecastDialogToolbar'

const ComponentForecastDialog = ({
  controller,
  inventoryData,
}: {
  inventoryData: InventoryType[]
  controller: ForecastDetailDialogController
}) => {
  const handleCodeUpdate = useCallback((code: string) => {
    controller.setFilter({ code })
  }, [])

  return (
    <InventoryForecastDetailDialog
      title="Component Inventory Forecast"
      toolbar={
        <ComponentForecastDialogToolbar
          selectedCode={controller.code}
          handleCodeUpdate={handleCodeUpdate}
          inventoryData={inventoryData}
        />
      }
      open={controller.open}
      onClose={controller.onClose}
      content={controller.code ? <InventoryForecastDetailTable code={controller.code} /> : null}
    />
  )
}

export default ComponentForecastDialog

import { orgSelectors } from 'ducks/orgs'
import { useMemo } from 'react'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { ProjectType } from 'types/projects'
import { RootState } from 'types/state'

export const useLyraPermitPackEnabled = () => {
  const project = useFormState().values as ProjectType
  const isIronRidgeLinked = useSelector((state: RootState) => orgSelectors.getIsPartnerOrgLinked(state, 'ironridge'))

  // Whether we should show Lyra validations ahead of project being sold
  const eligible = useMemo(() => isIronRidgeLinked && project.country_iso2 === 'US' && project.is_residential, [
    project.country_iso2,
    project.is_residential,
    isIronRidgeLinked,
  ])

  return eligible
}

import { makeStyles, Tooltip, useTheme } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddOutlined'
import { push } from 'connected-react-router'
import { permissionsSelectors } from 'ducks/permissions'
import { Button, IconButton } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'

const defaultWrapperStyle = {
  textAlign: 'center',
  borderRadius: '3px',
  marginRight: 5,
}

const useButtonStyles = makeStyles({
  root: {
    marginLeft: 10,
  },
  startIcon: {
    margin: '-1px 10px -1px 0',
  },
})

const useIconButtonStyles = makeStyles((theme) => ({
  startIcon: {
    margin: 0,
  },
  root: {
    border: theme.border.default,
    '&.MuiIconButton-root:hover': {
      border: '2px solid #0094FF',
      background: '#FFFFFF',
      boxShadow: '0px 4px 5px rgb(0 0 0 / 14%), 0px 1px 10px rgb(0 0 0 / 12%)',
    },
  },
}))

const CreateProjectButton = ({ layout, wrapperStyle = defaultWrapperStyle }) => {
  const theme = useTheme()
  const buttonClasses = useButtonStyles()
  const iconButtonClasses = useIconButtonStyles()
  const translate = useTranslate()
  const { allowCreate } = useSelector(permissionsSelectors.getPermissionByKey('project'))
  const isLargeLayout = layout >= 5
  const dispatch = useDispatch()
  const onClick = () => {
    dispatch(push('/home'))
  }
  return (
    <div style={wrapperStyle}>
      <Tooltip title={translate('Create Project')} enterDelay={300}>
        <div>
          {!isLargeLayout ? (
            <IconButton
              id="NewProjectButton"
              classes={iconButtonClasses}
              disabled={!allowCreate}
              onClick={onClick}
              style={{
                overflow: 'inherit',
                minWidth: layout > 3 ? '' : 10,
                borderRadius: '5px',
                lineHeight: '33px',
                width: 'unset',
                padding: '0 5px',
              }}
            >
              <AddIcon
                style={{
                  fill: theme.headerHighlightFontColor,
                }}
              />
              {layout > 3 && (
                <span
                  style={{
                    padding: '0 4px',
                    color: theme.headerHighlightFontColor,
                    fontSize: 12,
                    display: 'inline-block',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {translate('New Project')}
                </span>
              )}
            </IconButton>
          ) : (
            <Button
              id="NewProjectButton"
              classes={buttonClasses}
              variant="outlined"
              color="default"
              size="small"
              disabled={!allowCreate}
              style={{ width: 'max-content' }}
              startIcon={<AddIcon />}
              onClick={onClick}
            >
              <span style={{ textTransform: 'none' }}>{translate('New Project')}</span>
            </Button>
          )}
        </div>
      </Tooltip>
    </div>
  )
}
export default CreateProjectButton

// @ts-nocheck
import { makeStyles } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { permissionsSelectors } from 'ducks/permissions'
import CustomField from 'elements/field/CustomField'
import DateInput from 'elements/input/DateInput'
import { Alert } from 'opensolar-ui'
import { ProjectToggleField } from 'projectSections/sections/common/ProjectToggleField'
import { autoAdvanceInstalledProject, getAutoAdvanceInstalledLabel } from 'projectSections/utils/autoAdvanceStage'
import { useEffect, useMemo } from 'react'
import { ReferenceInput, SelectInput, TextInput, useTranslate } from 'react-admin'
import { useField, useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import GreenLancerCTA from 'resources/projects/GreenLancer/GreenLancerCTA'
import { ProjectType } from 'types/projects'
import { useDateFormatter } from 'util/date'
import { dateParser, getCurrentDate, systemNameOrDefaultWithSize } from '../../../../util/misc'
import AccordionCard from '../AccordionCard'

const useStyles = makeStyles((theme: OpenSolarThemeType) => ({
  column: { display: 'flex', flexDirection: 'column', width: '100%' },
  dateInput: { width: '100%' },
}))

export const convertUTCDate = (date) => {
  let a = new Date(date)
  const convDate = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
  return new Date(convDate).toISOString()
}

const InstallationContent = () => {
  const translate = useTranslate()
  const form = useForm()
  const formState = useFormState()
  const classes = useStyles()
  const formatter = useDateFormatter()
  // just used to trigger re-render on change, not actually referenced anywhere
  const greenLancerIdFieldState = useField('greenlancer_project_id', { subscription: { value: true } })
  const project = formState.values as ProjectType
  const installationDate = useMemo(() => {
    if (project.installation_date) {
      return formatter(convertUTCDate(project.installation_date)).split(' ')[0]
    }
  }, [project.installation_date])

  const systemsReady: boolean = !useField('simulate_first_year_only', {
    subscription: { value: true },
  }).input.value
  const showPermittingServices: boolean = project && project.country_iso2 === 'US'
  const orgId = useSelector(authSelectors.getOrgId)
  const orgWorkflows = useSelector(orgSelectors.getWorkflows)

  const permittingText = translate(
    "Don't have time to draw up the plan set? We can help! Our partners at GreenLancer can deliver a full, PE stamped set within as little as 3 days."
  )
  useEffect(() => {
    const dirtyFields = form.mutators.getFormDirtyFields()
    if (
      project.project_installed === 1 &&
      ((dirtyFields?.includes('system_installed') && project.system_installed) ||
        (dirtyFields?.includes('serial_numbers_panels') && project.serial_numbers_panels) ||
        (dirtyFields?.includes('serial_numbers_inverters') && project.serial_numbers_inverters) ||
        (dirtyFields?.includes('serial_numbers_batteries') && project.serial_numbers_batteries))
    ) {
      form.mutators.updateField('project_installed', 2)
      if (!project.installation_date) form.mutators.updateField('installation_date', getCurrentDate())
    }
  }, [
    project.system_installed,
    project.serial_numbers_panels,
    project.serial_numbers_inverters,
    project.serial_numbers_batteries,
  ])
  const toggleLabel = useMemo(() => {
    if (project.installation_date && project.project_installed !== null) {
      if (project.project_installed === 1) {
        return translate(
          'This project was marked installed on %{installation_date} through project workflow automation.',
          {
            installation_date: installationDate,
          }
        )
      } else {
        return translate('This project was marked installed on %{installation_date}.', {
          installation_date: installationDate,
        })
      }
    } else if (project.project_installed === 1) {
      return translate('This project was marked installed through project workflow automation.')
    }
  }, [project.installation_date, project.project_installed])

  const toggleAdvanceInstalledLabel = useMemo(() => {
    return getAutoAdvanceInstalledLabel(project, orgId, orgWorkflows, formState, translate)
  }, [project.project_installed, formState.initialValues.project_installed])

  useEffect(() => {
    autoAdvanceInstalledProject(project, orgId, orgWorkflows, formState, form)
  }, [project.project_installed])

  return (
    <div className={classes.column}>
      <ProjectToggleField
        label={translate('Project Installed')}
        subLabel={toggleLabel}
        checked={!!project.project_installed && project.project_installed > 0}
        source={'project_installed'}
        onChange={(value) => {
          if (value === true) {
            form.mutators.updateField('project_installed', 2)
            if (!project.installation_date) form.mutators.updateField('installation_date', getCurrentDate())
          } else {
            form.mutators.updateField('project_installed', null)
            form.change('installation_date', null)
            form.change('system_installed', null)
            form.change('serial_numbers_batteries', '')
            form.change('serial_numbers_inverters', '')
            form.change('serial_numbers_panels', '')
          }
        }}
      />
      {toggleAdvanceInstalledLabel && (
        <div style={{ marginTop: 10 }}>
          <Alert severity="info">{toggleAdvanceInstalledLabel}</Alert>
        </div>
      )}
      <Grid container spacing={3}>
        <Grid item xs={6}>
          {/* @ts-ignore */}
          <DateInput
            //@TODO: Refactor into standard field translation and remove label attribute
            label={'Installation Date'}
            resource={'projects'}
            source={'installation_date'}
            parse={dateParser}
            className={classes.dateInput}
            onChange={(value) => {
              if (project.project_installed === 1 && value) {
                form.mutators.updateField('project_installed', 2)
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          {
            // We do not simply disable ReferenceInput when !project.id
            // beacuse it still attempts to load data which is invalid
            Boolean(project && project.id && !isNaN(project.id)) && systemsReady ? (
              <ReferenceInput
                //@TODO: Refactor into standard field translation and remove label attribute
                label={'Installed System'}
                resource={'projects'}
                reference="systems"
                source="system_installed"
                filter={{
                  project: project ? project.id : null,
                  visible_to: orgId,
                }}
                style={{ width: '100%' }}
                sort={{ field: 'order', order: 'ASC' }}
                allowEmpty
              >
                <SelectInput optionValue="url" optionText={(choice: any) => systemNameOrDefaultWithSize(choice)} />
              </ReferenceInput>
            ) : (
              <SelectInput
                label={
                  <span>
                    {translate('resources.projects.fields.system_installed')}
                    {!systemsReady && (
                      <CircularProgress style={{ marginLeft: 10, verticalAlign: 'middle', height: 18, width: 18 }} />
                    )}
                  </span>
                }
                choices={[]}
                value={null}
                disabled={true}
              />
            )
          }
        </Grid>
        <Grid item xs={12}>
          <CustomField
            //@TODO: Refactor into standard field translation and remove label attribute
            label={'Serial Numbers: Panels'}
            resource="projects"
            component={TextInput}
            name={'serial_numbers_panels'}
            source={'serial_numbers_panels'}
            fullWidth
            style={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomField
            //@TODO: Refactor into standard field translation and remove label attribute
            label={'Serial Numbers: Inverters'}
            resource="projects"
            component={TextInput}
            name={'serial_numbers_inverters'}
            source={'serial_numbers_inverters'}
            fullWidth
            style={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomField
            //@TODO: Refactor into standard field translation and remove label attribute
            label={'Serial Numbers: Batteries'}
            resource="projects"
            component={TextInput}
            name={'serial_numbers_batteries'}
            source={'serial_numbers_batteries'}
            fullWidth
            style={{ width: '100%' }}
          />
        </Grid>
        {showPermittingServices && (
          <>
            <Grid item xs={12}>
              <span style={{ width: '100%', textAlign: 'center' }}>
                {project.greenlancer_project_id
                  ? translate("Click the button below to view your project on GreenLancer's site")
                  : permittingText}
              </span>
              <h4>GreenLancer</h4>
              <GreenLancerCTA project={project} />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  )
}

const InstallationAccordion = () => {
  const translate = useTranslate()
  const { allowView, allowCreate, allowEdit, allowDelete } = useSelector(
    permissionsSelectors.getProjectPermissionByKey('info_installation_info')
  )
  if (!allowView) {
    return null
  }
  return (
    <AccordionCard
      disabled={!allowEdit}
      name={'installation'}
      title={translate('Installation')}
      titleIcon={BuildOutlinedIcon}
      content={InstallationContent}
      lazyRender={true}
    />
  )
}
export default InstallationAccordion

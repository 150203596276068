import { TextField } from '@material-ui/core'
import { authReload } from 'actions/authActions'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import { useNotify, useTranslate } from 'ra-core'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import restClient from 'restClient'
import appStorage from 'storage/appStorage'
import { MFADeviceType } from 'types/mfa'
import { fetchMfaConfig } from './util'

type PropTypes = {
  goToRecovery: () => void
  isLogin?: boolean
  cancel?: () => void
}

const MfaSmsCode: React.FC<PropTypes> = (props) => {
  const notify = useNotify()
  const translate = useTranslate()
  const dispatch = useDispatch()
  const location = useLocation()

  const [smsCode, setSmsCode] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const [device, setDevice] = useState<MFADeviceType>()

  const activeMfaDevices = useSelector(authSelectors.getActiveMfaDevice) || []

  useEffect(
    () => setDevice(activeMfaDevices.find((device) => device.kind === 'sms' && device.status === 'unconfirmed')),
    [activeMfaDevices]
  )

  useEffect(() => {
    setPhoneShort(device?.number?.slice(-4))
  }, [device])

  useEffect(() => {
    logAmplitudeEvent('mfa_sms_code_page_viewed', {})
  }, [])

  useEffect(() => {
    fetchMfaConfig(dispatch, translate, notify, setLoading)
  }, [])

  const onSubmit = (e) => {
    e.preventDefault()

    if (!smsCode || smsCode.length < 4) {
      notify('Invalid or missing verification code', 'warning')
    } else {
      setLoading(true)
      const restClientInstance = restClient(window.API_ROOT + '/api')
      restClientInstance('CUSTOM_POST', 'custom', {
        url: `mfa/devices/sms/confirm/`,
        data: {
          code: smsCode,
        },
      })
        .then((res: { data: { token: string } }) => {
          notify('SMS device successfully activated')
          logAmplitudeEvent('mfa_sms_code_success', {})

          // Replace our old token with the new token because the old token may now be invalid
          // because it is not MFA verified
          if (res.data.token) {
            appStorage.setToken(res.data.token)
          } else {
            console.warn('new token was not updated, unable to replace old token')
          }

          if (props.isLogin) {
            dispatch(authReload({ redirectOnFailure: location.pathname }))
          }
          props.goToRecovery()
        })
        .catch((err: any) => {
          console.warn(err)
          notify('Invalid or expired verification code', 'warning')
          logAmplitudeEvent('mfa_sms_code_error', {})
        })
        .finally(() => setLoading(false))
    }
    return false
  }

  const resend = useCallback(() => {
    if (!device) {
      notify('Your Two-Factor authentication appears to be misconfigured, please start over', 'warning')
      return
    }
    const restClientInstance = restClient(window.API_ROOT + '/api')
    restClientInstance('CUSTOM_POST', 'custom', {
      url: `mfa/devices/sms/`,
      data: {
        phoneNumber: device.number,
      },
    })
      .then(() => {
        logAmplitudeEvent('mfa_sms_code_resent', {})
        notify('Your code has been re-sent', 'success')
      })
      .catch((err: any) => {
        console.warn(err)
        notify('Unable to resend verification code', 'warning')
        logAmplitudeEvent('mfa_sms_resend_error', {})
      })
      .finally(() => setLoading(false))
  }, [device])

  const [phoneShort, setPhoneShort] = useState<string>()

  return (
    <div style={{ width: '100%' }}>
      <p>
        {translate(
          'We just sent an SMS message to the phone number you provided (...phoneNumber). Please enter the verification code from that message below.',
          { phoneNumber: phoneShort }
        )}
      </p>
      <div>
        <TextField
          id="verificationCode"
          onChange={(e) => setSmsCode(e.target.value)}
          value={smsCode}
          style={{ width: '100%' }}
          label="Verification Code"
          inputProps={{
            id: 'mfa-verification-input',
          }}
        />
        <div style={{ cursor: 'pointer', margin: '5px 0px 30px 0px' }}>
          <span className="small" onClick={resend}>
            {translate('Click here to re-send the verification code')}
          </span>
        </div>
        <div style={{ marginTop: '15px', width: '100%' }}>
          <ProUXButton
            type="primary"
            label="Submit"
            loading={loading}
            fullWidth={true}
            submit={true}
            disabled={!device}
            onClick={onSubmit}
          />
        </div>
        {props.cancel && (
          <div style={{ width: '100%', marginTop: '15px' }}>
            <ProUXButton label="Back" onClick={props.cancel} type="text" fullWidth={true} style={{ width: '100%' }} />
          </div>
        )}
      </div>
    </div>
  )
}
export default MfaSmsCode

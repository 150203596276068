import { makeStyles } from '@material-ui/core'
import { Theme } from 'types/themes'

export const useStyles = makeStyles<Theme, {}>((theme) => {
  return {
    // For the rows of buttons
    buttonCont: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-evenly',
      flexWrap: 'wrap',
      gap: 10,
      padding: 0,
    },
    button: {
      flexGrow: 1,
    },
    buttons: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-evenly',
      gap: 10,
    },

    // For the rows of label then input
    rows: {
      boxSizing: 'border-box',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
      padding: 10,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      gap: 10,
      minHeight: 40,
    },
    rowAction: {
      marginLeft: '-10px',
      marginRight: '-5px',
    },
    fill: {
      flexGrow: 1,
      flexShrink: 1,

      [theme.breakpoints.down('sm')]: {
        flexGrow: 'unset',
        minWidth: '50%',
      },
    },
    rowLabel: {
      [theme.breakpoints.down('sm')]: {
        flexGrow: 1,
      },
    },
    rowValue: {
      margin: 0,
      paddingRight: 5,
      width: 160,
    },
    short: {
      paddingRight: 10,
    },
    veryShort: {
      paddingRight: 50,
    },
    fakeEnabled: {
      color: theme.standardFontColor + ' !important',
    },

    // For the 'fake' dropdown button
    selectText: {
      flexGrow: 1,
      textAlign: 'left',
    },
    selectIcon: {
      marginRight: -18,
    },

    // for the title bar
    address: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: 180,
      flexShrink: 1,
    },

    panelRow: {
      display: 'flex',
      alignItems: 'baseline',
    },
    closeIcon: {
      marginLeft: 'auto',
      background: 'none',
      border: 'none',
      cursor: 'pointer',
    },
    panelLabel: {
      fontWeight: 'bold',
      width: 150,
      margin: '10px 0',
    },
    panelInput: {
      margin: '3.5px 0',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
      gap: 5,
      '& .MuiFormControl-marginDense': {
        margin: 0,
        width: 165,
      },
      '& .MuiInputBase-input': {
        fontSize: 14,
      },
      '& .MuiOutlinedInput-input': {
        padding: '10px',
      },
    },
    panelButton: {
      marginTop: 30,
    },
    error: {
      color: '#ff0000',
      margin: 0,
      textAlign: 'right',
    },

    confirmDialogBody: {
      padding: '20px 50px 30px 0px',
      fontSize: 17,
    },
    confirmDialogButtons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }
})

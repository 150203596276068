var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export var PlugIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? '1rem' : _b, _c = _a.color, color = _c === void 0 ? 'currentColor' : _c, rest = __rest(_a, ["size", "color"]);
    var iconAttributes = {
        width: size,
        height: size,
        stroke: color,
    };
    return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 22", fill: "none" }, rest, iconAttributes, { children: _jsx("path", { d: "M7.98182 16.6273V20.7M4.49091 3.24545V1.5M11.4727 3.24545V1.5M14.9636 7.31818H1M2.74545 7.31818H13.2182V11.9727C13.2182 14.5434 11.1343 16.6273 8.56364 16.6273H7.4C4.82937 16.6273 2.74545 14.5434 2.74545 11.9727V7.31818Z", stroke: color, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }) })));
};

import type { ComponentTypesV2 } from 'types/selectComponent'
import { mapComponentTypesV2ToV1 } from 'util/misc'
import BasicFilter from '../basic/BasicFilter'
import { CURRENT_FILTER_KEY_V2 } from '../currentType/CurrentTypeFilterNodeFactoryV2'
import type {
  ConfigurableFilterOptionsType,
  FilterComponentNodeV2,
  FilterGenericPropsType,
  FilterValuesType,
  OptionGenericType,
} from '../typeV2'
import { parseStringifiedListFilterValue } from '../utils/utils'

export const PHASE_FILTER_KEY_V2 = 'phase_type'

export const isOtherComponentPhaseFilterAvailableV2 = (filterValues: FilterValuesType) => {
  const currentTypes = parseStringifiedListFilterValue<ComponentTypesV2>({
    filterValues,
    key: 'types',
  })
  return (currentTypes.has('isolator') && filterValues[CURRENT_FILTER_KEY_V2] === 'ac') || currentTypes.has('meter')
}

const PHASE_TYPE_OPTIONS: OptionGenericType<string>[] = [
  { id: 'default', title: 'All', value: '' },
  { id: 'single_phase', title: 'Single phase', value: 'single_phase' },
  { id: 'three_phase', title: 'Three phase', value: 'three_phase' },
]

export const createPhaseTypeFilterNode = <T,>({
  label = 'Phase',
  ...restConfigs
}: ConfigurableFilterOptionsType<string, T>): FilterComponentNodeV2 => {
  const FilterComponent = (filterProps: FilterGenericPropsType) => {
    const currentTypes = parseStringifiedListFilterValue<ComponentTypesV2>({
      filterValues: filterProps.allFilterValues,
      key: 'types',
    })

    const hasInverterType = Array.from(currentTypes).some((type) => {
      return mapComponentTypesV2ToV1(type) === 'inverter'
    })

    const hasOtherType = Array.from(currentTypes).some((type) => {
      return mapComponentTypesV2ToV1(type) === 'inverter'
    })

    if (!hasInverterType || !hasOtherType) {
      return null
    }

    const disabled = !hasInverterType && !isOtherComponentPhaseFilterAvailableV2(filterProps.allFilterValues)

    return (
      <BasicFilter
        label={label}
        defaultSelectedOptionValue={''}
        options={PHASE_TYPE_OPTIONS}
        filterKey={PHASE_FILTER_KEY_V2}
        {...restConfigs}
        {...filterProps}
        disabled={disabled}
      />
    )
  }

  return {
    key: PHASE_FILTER_KEY_V2,
    component: FilterComponent,
  }
}

export default createPhaseTypeFilterNode

import { makeStyles, Tooltip } from '@material-ui/core'
import { AccountBalanceOutlined } from '@material-ui/icons'
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import DesignerIcon from '@material-ui/icons/FormatShapesOutlined'
import CustomerViewIcon from '@material-ui/icons/ImportantDevicesOutlined'
import OfflineBoltOutlinedIcon from '@material-ui/icons/OfflineBoltOutlined'
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined'
import WithPermissionsCheck from 'elements/WithPermissionsCheck'
import { useUxVersion } from 'hooks/useUxVersion'
import { IconButton } from 'opensolar-ui'
import { Link } from 'react-router-dom'
import { useFeatureFlag } from 'util/split'

const useStyles = makeStyles((theme) => ({
  iconButton: {
    [theme.breakpoints.between('sm', 'sm')]: {
      '& svg': {
        width: 20,
        height: 20,
      },
    },
    padding: 5,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    maxWidth: 220,
    margin: 'auto',
    minWidth: 150,
  },
}))

export const ProjectListActions = ({ record, translate }) => {
  const classes = useStyles()
  const paymentsPageEnabled = useFeatureFlag('payments_tab', 'on')
  const uxVersion = useUxVersion()
  return (
    <div className={classes.wrapper}>
      {!record.is_lite && (
        <>
          {uxVersion < 3 ? (
            <WithPermissionsCheck permissionToCheck="project" permissions={['allowView']} projectMode={false}>
              <InfoPageButton projectId={record.id} buttonClasses={classes.iconButton} translate={translate} />
            </WithPermissionsCheck>
          ) : (
            <WithPermissionsCheck permissionToCheck="manage" permissions={['allowView']} projectMode={false}>
              <ManagePageButton projectId={record.id} buttonClasses={classes.iconButton} translate={translate} />
            </WithPermissionsCheck>
          )}

          <WithPermissionsCheck permissionToCheck="project" permissions={['allowView']} projectMode={false}>
            <EnergyPageButton projectId={record.id} buttonClasses={classes.iconButton} translate={translate} />
          </WithPermissionsCheck>
        </>
      )}
      <WithPermissionsCheck permissionToCheck="design" permissions={['allowView']} projectMode={false}>
        <DesignerButton record={record} buttonClasses={classes.iconButton} translate={translate} />
      </WithPermissionsCheck>
      {!record.is_lite && (
        <>
          {paymentsPageEnabled && (
            <WithPermissionsCheck permissionToCheck="project_payments" permissions={['allowView']} projectMode={false}>
              <PaymentsPageButton projectId={record.id} buttonClasses={classes.iconButton} translate={translate} />
            </WithPermissionsCheck>
          )}
          <WithPermissionsCheck permissionToCheck="proposal" permissions={['allowView']} projectMode={false}>
            <MyEnergyButton record={record} buttonClasses={classes.iconButton} translate={translate} />
          </WithPermissionsCheck>
          <WithPermissionsCheck permissionToCheck="proposal" permissions={['allowView']} projectMode={false}>
            <PDFPageButton projectId={record.id} buttonClasses={classes.iconButton} translate={translate} />
          </WithPermissionsCheck>
          {uxVersion < 3 && (
            <WithPermissionsCheck permissionToCheck="manage" permissions={['allowView']} projectMode={false}>
              <ManagePageButton projectId={record.id} buttonClasses={classes.iconButton} translate={translate} />
            </WithPermissionsCheck>
          )}
        </>
      )}
    </div>
  )
}

const DesignerButton = ({ record, buttonClasses, translate }) => (
  <Link to={`/projects/${record.id}/design`}>
    <Tooltip title={translate('Design')}>
      <IconButton disabled={false} className={buttonClasses}>
        <DesignerIcon style={{ color: '#4d4d4d' }} />
      </IconButton>
    </Tooltip>
  </Link>
)

const InfoPageButton = ({ projectId, buttonClasses, translate }) => (
  <Link to={`/projects/${projectId}/info`}>
    <Tooltip title={translate('Info')}>
      <IconButton disabled={false} className={buttonClasses}>
        <AssignmentOutlinedIcon style={{ color: '#4d4d4d' }} />
      </IconButton>
    </Tooltip>
  </Link>
)
const EnergyPageButton = ({ projectId, buttonClasses, translate }) => (
  <Link to={`/projects/${projectId}/energy`}>
    <Tooltip title={translate('Energy')}>
      <IconButton disabled={false} className={buttonClasses}>
        <OfflineBoltOutlinedIcon style={{ color: '#4d4d4d' }} />
      </IconButton>
    </Tooltip>
  </Link>
)
const PaymentsPageButton = ({ projectId, buttonClasses, translate }) => (
  <Link to={`/projects/${projectId}/payments`}>
    <Tooltip title={translate('Payments')}>
      <IconButton disabled={false} className={buttonClasses}>
        <AccountBalanceOutlined style={{ color: '#4d4d4d' }} />
      </IconButton>
    </Tooltip>
  </Link>
)
const ManagePageButton = ({ projectId, buttonClasses, translate }) => (
  <Link to={`/projects/${projectId}/manage`}>
    <Tooltip title={translate('Manage')}>
      <IconButton disabled={false} className={buttonClasses}>
        <AccountTreeOutlinedIcon style={{ color: '#4d4d4d' }} />
      </IconButton>
    </Tooltip>
  </Link>
)

const PDFPageButton = ({ projectId, buttonClasses, translate }) => (
  <Link to={`/projects/${projectId}/preview`}>
    <Tooltip title={translate('PDF Proposal')}>
      <IconButton disabled={false} className={buttonClasses}>
        <PictureAsPdfOutlinedIcon style={{ color: '#4d4d4d' }} />
      </IconButton>
    </Tooltip>
  </Link>
)

const MyEnergyButton = ({ record, buttonClasses, translate }) =>
  true || (record.systems && record.systems.length > 0) ? (
    <Link to={`/projects/${record.id}/proposal`}>
      <Tooltip title={translate('Online Proposal')}>
        <IconButton disabled={false} className={buttonClasses}>
          <CustomerViewIcon style={{ color: '#4d4d4d' }} />
        </IconButton>
      </Tooltip>
    </Link>
  ) : (
    <IconButton disabled={true} className={buttonClasses}>
      <CustomerViewIcon style={{ color: '#CCCCCC' }} />
    </IconButton>
  )

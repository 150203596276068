import { makeStyles, Theme, Typography, useMediaQuery } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import SaveButton from 'projectSections/elements/header/actions/SaveButton'
import { useTranslate } from 'ra-core'
import React, { CSSProperties, useMemo } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { systemCalculationsSelectors } from 'reducer/designer/systemCalculation'
import { StudioSystemType } from 'types/global'

interface PropTypes {
  calcsPending: boolean
  calculating: boolean
  isCreated?: boolean
  allowButton?: boolean
  style?: CSSProperties
}

const useStyles = makeStyles(
  {
    root: {
      display: 'flex',
      alignItems: 'center',
      color: 'white',
      filter: 'drop-shadow(0px 0px 4px #00000073)',
    },
  },
  { name: 'SaveStatus' }
)

const SaveStatus: React.FC<PropTypes> = ({ calcsPending, calculating, isCreated, allowButton = true, style }) => {
  const form = useForm()
  const formState = useFormState()
  const dirtyFields = form.mutators.getFormDirtyFields()
  const formDirty = dirtyFields.length > 0
  const translate = useTranslate()
  const isStaff = useSelector(authSelectors.getIsStaff)
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const classes = useStyles()
  const showButton = allowButton && ((!isCreated && isSmall) || (isCreated && isStaff && formDirty))

  return (
    <div className={classes.root} style={style}>
      <Typography variant="h6">
        {(calcsPending && translate('Calculations Pending')) ||
          (calculating && translate('Calculating...')) ||
          (formState.submitting && translate('Saving...')) ||
          (formDirty && isCreated && !isStaff && translate('Changes Pending'))}
      </Typography>
      {showButton && (
        <div>
          <SaveButton size="medium" submitting={formState.submitting} label={isCreated ? 'Save' : 'Create Project'} />
        </div>
      )}
    </div>
  )
}

export default SaveStatus

// A wrapper which bakes in the calcs state
export const SaveStatusAuto = (props: { isCreated?: boolean; allowButton?: boolean; style?: CSSProperties }) => {
  const system: StudioSystemType | undefined = useMemo(() => {
    return window.editor.selectedSystem
  }, undefined)

  const calcsPending = useSelector(systemCalculationsSelectors.isSystemQueued)(system?.uuid)
  const calculating = useSelector(systemCalculationsSelectors.isSystemProcessing)(system?.uuid)
  return <SaveStatus {...props} calcsPending={calcsPending} calculating={calculating} />
}

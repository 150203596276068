import { logAmplitudeEvent } from 'amplitude/amplitude'
import HorizontalDropdownWithLabel from 'pages/inventory/components/HorizontalDropdownWithLabel'
import React, { useCallback, useMemo } from 'react'
import type { ComponentTypes } from 'types/selectComponent'
import type { ForecastDetailDialogController } from '../../forecast/detail/useForecastDetailDialogController'
import type { ForecastedProjectInventory } from '../../forecast/type'

const ComponentSelector = ({
  selectedCode,
  currentSelectedForecastProjectInventory,
  updateFilter,
  componentType,
}: {
  selectedCode?: string
  currentSelectedForecastProjectInventory: ForecastedProjectInventory | undefined
  updateFilter: ForecastDetailDialogController['setFilter']
  componentType?: ComponentTypes
}) => {
  const handleComponentSelected = useCallback((code: string) => {
    updateFilter({
      code: code,
    })
    logAmplitudeEvent('generic_dropdown_interacted', {
      source: 'component_selector',
      action: 'inventory_forecast_component_selected',
    })
  }, [])

  const componentOptions = useMemo(() => {
    const selectableItems =
      currentSelectedForecastProjectInventory?.items_required_allocated.filter((item) => {
        return item.component_type === componentType
      }) || []
    return selectableItems.map((InventoryItemData) => ({ value: InventoryItemData.code, name: InventoryItemData.code }))
  }, [currentSelectedForecastProjectInventory, componentType])

  return (
    <HorizontalDropdownWithLabel
      label={'Component'}
      value={selectedCode}
      handleSelect={handleComponentSelected}
      options={componentOptions}
    />
  )
}

export default ComponentSelector

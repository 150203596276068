// @ts-nocheck
import { makeStyles } from '@material-ui/core'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'
import { NoUserActivities } from './NotAvailable'

const useStyles = makeStyles((theme) => ({}))

function dateDiffInDays(start: Date, end: Date) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(start.getFullYear(), start.getMonth(), start.getDate())
  const utc2 = Date.UTC(end.getFullYear(), end.getMonth(), end.getDate())

  return Math.floor((utc2 - utc1) / _MS_PER_DAY)
}

export function formatStartTime(date: Date) {
  var hours = date.getHours()
  var minutes = date.getMinutes()
  var ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  var strTime = hours + ':' + minutes + ' ' + ampm
  return strTime
}

const ActivityRow = ({ record }: any) => {
  const date: any = {}
  if (record.start) {
    const start = new Date(record.start)
    date['startDate'] = start.toLocaleDateString()
    date['startTime'] = formatStartTime(start)
  }
  if (record.end) {
    const end = new Date(record.end)
    date['endDate'] = end.toLocaleDateString()
    date['endTime'] = formatStartTime(end)
    if (record.duration && date.startDate !== date.endDate) {
      const start = new Date(record.start)
      date['dateDiff'] = dateDiffInDays(start, end)
    }
  }
  return (
    <div style={{ display: 'flex', opacity: record.is_archived ? 0.5 : 1.0 }}>
      <div style={{ flex: '1 1 0' }}>
        <div style={{ display: 'flex' }}>
          <Link
            to={`/events/${record.id}`}
            style={{
              textDecoration: 'none',
              cursor: 'pointer',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              flexGrow: 1,
              width: 100,
              color: 'rgb(0,0,0)',
              fontSize: 12,
            }}
          >
            {record.event_type_id !== 0 && record.notes && record.notes.length ? record.notes : record.title}
          </Link>
        </div>
        <div
          className="small"
          style={{
            padding: '4px 2px 15px 2px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {/* {notes && <span>{notes}</span>} */}
          {record.project_id && (
            <Link
              to={`/projects/${record.project_id}/info`}
              style={{
                color: 'rgba(0,0,0,0.54)',
                width: 100,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                flexGrow: 1,
              }}
            >
              {record.project_name}
            </Link>
          )}
          <span style={{ color: 'rgba(0,0,0,0.54)' }}>
            {date.startTime && <span>{date.startTime}</span>}
            {date.endTime && (
              <span style={{ marginLeft: 5 }}>
                to {date.endTime}
                {date.dateDiff && (
                  <span
                    style={{
                      verticalAlign: 'super',
                    }}
                  >
                    +{date.dateDiff}
                  </span>
                )}
              </span>
            )}
            {date.startDate && <span style={{ marginLeft: 5 }}>{date.startDate}</span>}
          </span>
        </div>
      </div>
    </div>
  )
}

const UpComingActivity = ({ data }: any) => {
  if (!data) {
    return (
      <div style={{ margin: '10px 16px' }}>
        <Skeleton height={'100px'} />
      </div>
    )
  }
  return (
    <div style={{ margin: '10px 16px' }}>
      {data.length === 0 ? (
        <NoUserActivities />
      ) : (
        data.map((record: any) => <ActivityRow key={record.id} record={record} />)
      )}
    </div>
  )
}

export default UpComingActivity

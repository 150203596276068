import lodash from 'lodash'
import { FC, useEffect, useMemo } from 'react'
import { SelectInput, TextInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { BOOLEAN_CHOICES } from '../fields'

interface PropTypes {
  source: string
  fieldId: string
}

const ConditionalTextFilter: FC<PropTypes> = ({ source, fieldId }) => {
  const form = useForm()
  const formValues = useFormState().values
  const value = useMemo(() => lodash.get(formValues, `${source}.fields`), [formValues, source])
  useEffect(() => {
    let newValue = {}
    if (value?.conditional !== undefined && value?.query) {
      newValue[fieldId] = (value.conditional ? '' : '-') + value.query
    }
    form.change(`${source}.value`, newValue)
  }, [value])
  return (
    <>
      <SelectInput
        choices={BOOLEAN_CHOICES}
        label={false}
        source={`${source}.fields.conditional`}
        variant="outlined"
        margin={'none'}
      />
      <TextInput label={false} source={`${source}.fields.query`} variant="outlined" margin={'none'} />
    </>
  )
}

export default ConditionalTextFilter

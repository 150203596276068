import { makeStyles } from '@material-ui/core'
import { Button } from 'opensolar-ui'
import React, { useCallback } from 'react'
import { useDataProvider, useNotify } from 'react-admin'
import { useHistory } from 'react-router-dom'
import { FetchProjectsWithTagSubHeader } from './SubHeader'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '10px 12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  decorator: {
    height: 24,
    width: 4,
    display: 'inline-block',
    borderRadius: 4,
    verticalAlign: 'middle',
  },
  figure: {
    fontSize: 24,
    color: 'rgba(0, 0, 0, 0.87)',
    verticalAlign: 'middle',
  },
  label: {
    fontSize: 15,
    color: 'rgba(0, 0, 0, 0.54)',
    marginLeft: 10,
  },
}))

const FetchProjectsWithTags = (props: any) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const history = useHistory()
  const onFailed = (error: any) => {
    notify('ra.notification.http_error', 'warning')
    //@ts-ignore
    console.warn(error)
  }
  const fetchProject = useCallback((id) => {
    return new Promise((resolve, reject) => {
      dataProvider.CUSTOM_GET(
        'projects',
        {
          url: 'first_project_with_tag/?tag=' + id,
        },
        {
          onSuccess: (response: any) => {
            const project = response.data
            resolve(project)
          },
          onFailure: () => {
            reject()
          },
        }
      )
    })
  }, [])
  const handleButtonClick = useCallback((id) => {
    fetchProject(id)
      .then((project: any) => {
        if (project) {
          //invalid url
          const url = `/projects/${project.id}/info`
          history.push(url)
        } else {
          history.push('/projects')
        }
      })
      .catch(onFailed)
  }, [])

  if (!props.data || props.data.length === 0) {
    return null
  }
  return (
    <div>
      <FetchProjectsWithTagSubHeader />
      <div style={{ margin: '0 16px 10px' }}>
        {props.data?.map((tag: any) => {
          return (
            <div key={tag.id} className={classes.wrapper}>
              <Button variant="outlined" onClick={() => handleButtonClick(tag.id)} disabled={!tag.project_count}>
                {tag.title}
              </Button>
              <span className={classes.figure}>{tag.project_count}</span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default FetchProjectsWithTags

import { Hidden, InputAdornment } from '@material-ui/core'
import DependentInput from 'elements/input/DependentInput'
import { Box, Grid, styled } from 'opensolar-ui'
import { useCallback, useMemo } from 'react'
import { NumberInput, SelectInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { ProjectType } from 'types/projects'
import { currencySymbolForCountry } from 'util/misc'
import { UNIT_VALUE_CHOICES, USAGE_CHOICES } from './constants'

const StyledSelectInput = styled(SelectInput)({
  width: '100%',
  margin: '16px 30px 16px 0px',
})

const HiddenNumberInput = styled(NumberInput)({
  display: 'none',
})

// All fields on this section will be nested inside this field
const FIELD_PREFIX = 'natural_gas'

export type NaturalGasType = {
  unit_value?: string
  usage_data_source?: string
  values?: number
  values_kwh?: number
  price?: number
  price_per_kwh?: number
}

const NaturalGasContent = () => {
  const form = useForm()
  const formState = useFormState<ProjectType>()
  const currencySymbol = useMemo(() => {
    const projectCountry = form.getState().values['country_iso2']
    return currencySymbolForCountry(projectCountry)
  }, [formState?.values?.country_iso2])

  const onNaturalGasChange = useCallback(() => {
    // Mutate the parent object as dirty, otherwise need to register every nested field to FIELDS_REQUIRE_RECALC
    form.mutators.markFieldAsDirty(FIELD_PREFIX)
  }, [])

  return (
    <Box width="100%">
      <Grid container id={'natural-gas-content-container'} spacing={3}>
        <Grid item xs={12} sm={6}>
          <StyledSelectInput
            label="Unit Value"
            name={`${FIELD_PREFIX}.unit_value`}
            id={'energy-natural-gas-unit-value-input'}
            source={`${FIELD_PREFIX}.unit_value`}
            resource={'projects'}
            choices={UNIT_VALUE_CHOICES}
            translateChoice={true}
            onChange={onNaturalGasChange}
          />
        </Grid>
        <Hidden xsDown>
          {/* Add empty column to offset the space */}
          <Grid item xs={12} sm={6} />
        </Hidden>

        <Grid item xs={12} sm={6}>
          <StyledSelectInput
            label="Data Source"
            name={`${FIELD_PREFIX}.usage_data_source`}
            id={'energy-natural-gas-usage-data-source-input'}
            source={`${FIELD_PREFIX}.usage_data_source`}
            resource={'projects'}
            choices={USAGE_CHOICES}
            translateChoice={true}
            defaultValue="annual_consumption"
            onChange={onNaturalGasChange}
            disabled
          />
        </Grid>
        <DependentInput dependsOn={`${FIELD_PREFIX}.usage_data_source`} value="annual_consumption">
          <Grid item xs={12} sm={6}>
            <NumberInput
              label="Annual Usage"
              name={`${FIELD_PREFIX}.values`}
              resource={'projects'}
              source={`${FIELD_PREFIX}.values`}
              onChange={onNaturalGasChange}
              fullWidth
              inputProps={{ min: 0 }}
            />
            <HiddenNumberInput
              type="hidden"
              name={`${FIELD_PREFIX}.values_kwh`}
              resource={'projects'}
              source={`${FIELD_PREFIX}.values_kwh`}
            />
          </Grid>
        </DependentInput>

        <Grid item xs={12} sm={6}>
          <NumberInput
            label="Annual - Price of Natural Gas"
            name={`${FIELD_PREFIX}.price`}
            resource={'projects'}
            source={`${FIELD_PREFIX}.price`}
            onChange={onNaturalGasChange}
            fullWidth
            inputProps={{ min: 0 }}
            startAdornment={<InputAdornment position="start">{currencySymbol}</InputAdornment>}
          />
          <HiddenNumberInput
            type="hidden"
            name={`${FIELD_PREFIX}.price_per_kwh`}
            resource={'projects'}
            source={`${FIELD_PREFIX}.price_per_kwh`}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default NaturalGasContent

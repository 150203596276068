// TODO: move to common element
import type { TextFieldProps } from '@material-ui/core/TextField'
import BasicInput from 'elements/input/BasicInput'
import { useCallback } from 'react'

const NumberInput = ({
  className = '',
  value,
  disabled,
  updateValue,
  placeholder,
  inputProps,
  InputProps,
  onBlur,
  onFocus,
}: {
  className?: string
  disabled?: boolean
  value: number
  updateValue(value: number): void
  placeholder?: string
  inputProps?: TextFieldProps['inputProps']
  InputProps?: TextFieldProps['InputProps']
  onBlur?(value: string): void
  onFocus?(): void
}) => {
  const handleChange = () => {}

  const handleBlur = useCallback((value: string) => {
    if (value === '') {
      updateValue(0)
    } else {
      updateValue(Number(value))
    }
    onBlur?.(value)
  }, [])
  return (
    <BasicInput
      className={className}
      disabled={disabled}
      value={value.toString()}
      type="number"
      inputProps={inputProps}
      InputProps={InputProps}
      placeholder={placeholder}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={() => onFocus?.()}
      max={inputProps?.max}
      min={inputProps?.min}
    />
  )
}

export default NumberInput

import React from 'react'
import { HostedDisclosureType } from '../types'
import EnFinApplicationForm from './EnFinApplicationForm'
import { FormJSONType } from './HostedCreditApplicationDialog'
import HostedJSONSchemaCreditApp from './HostedJSONSchemaCreditApp'

const INTEGRATION_TO_FORM = {
  enfin: EnFinApplicationForm,
}

type PropTypes = {
  onClose: () => void
  integration: string
  disclosures: HostedDisclosureType[]
  form_json?: FormJSONType | null
  initialValues: object
}

const HostedCreditAppFormContent: React.FC<PropTypes> = (props) => {
  // any integrations that use JSON schema form use the same component
  if (props.form_json) {
    return <HostedJSONSchemaCreditApp {...props} />
  }
  // other hosted forms have their own form component
  const FormComponent = INTEGRATION_TO_FORM[props.integration]
  if (!FormComponent) return null
  return <FormComponent {...props} />
}
export default HostedCreditAppFormContent

import { Grid } from '@material-ui/core'
import React, { useMemo } from 'react'
import OnboardingField from './OnboardingField'
import { FormRouteInfoPropTypes } from './types'

const BlueSnapOnboardingSection: React.FC<FormRouteInfoPropTypes> = (props) => {
  const sortedFieldsThisPage = useMemo(() => {
    if (props.fieldMap && props.pageNumber) {
      return props.fieldMap[props.pageNumber]?.sort((a, b) => {
        return a.priority - b.priority
      })
    }
  }, [props.pageNumber, props.fieldMap])
  return (
    <div data-testid="bluesnap-onboarding-form-section" style={{ width: '100%' }}>
      <Grid container spacing={2}>
        {sortedFieldsThisPage?.map((field) => (
          <OnboardingField field={field} key={field.field_name} fieldPrefixFilter={props.fieldPrefixFilter} />
        ))}
      </Grid>
    </div>
  )
}
export default BlueSnapOnboardingSection

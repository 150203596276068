import { FormControl, MenuItem, Select, Typography } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import CustomField from 'elements/field/CustomField'
import React, { useCallback } from 'react'
import { ReferenceInput, useInput, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  selector: {
    margin: '10px 0',
    backgroundColor: theme.greyLight3,
  },
}))

const RoofTypeDropdown = (props) => {
  const classes = useStyles()
  const {
    allowEmpty,
    choices,
    emptyValue,
    emptyText,
    format,
    options,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    validate,
    ...rest
  } = props
  const translate = useTranslate()

  const { id, input } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    validate,
    ...rest,
  })

  const renderEmptyItemOption = useCallback(() => {
    return React.isValidElement(emptyText)
      ? React.cloneElement(emptyText)
      : emptyText === ''
      ? ' ' // em space, forces the display of an empty line of normal height
      : translate(emptyText, { _: emptyText })
  }, [emptyText, translate])

  return (
    <Select id={id} {...input} {...options} className={classes.selector} displayEmpty={true}>
      {allowEmpty ? (
        <MenuItem
          value={''}
          key="null"
          aria-label={translate('ra.action.clear_input_value')}
          title={translate('ra.action.clear_input_value')}
        >
          {renderEmptyItemOption()}
        </MenuItem>
      ) : null}
      {choices?.map((choice) => (
        <MenuItem key={choice.url} value={choice.url}>
          {choice.name}
        </MenuItem>
      ))}
    </Select>
  )
}

const RoofTypeSelector = () => {
  const orgId = useSelector(authSelectors.getOrgId)
  const translate = useTranslate()

  return (
    <FormControl variant="outlined" size="small" fullWidth={true}>
      <Typography variant="subtitle2" gutterBottom>
        {translate('Selected Roof Type')}
      </Typography>
      <CustomField
        component={ReferenceInput}
        resource="projects"
        source="roof_type"
        name="roof_type"
        reference="roof_types"
        perPage={1000}
        allowEmpty
        fullWidth
        style={{ width: '100%' }}
        filter={{
          fav: 1,
          org_id: orgId,
        }}
        id="roof_type_field"
        emptyText={<i style={{ color: '#aaa' }}>{translate('Select Roof Type')}</i>}
      >
        <RoofTypeDropdown id="roof_type_select_input" />
      </CustomField>
    </FormControl>
  )
}

export default RoofTypeSelector

// @ts-nocheck
import { Grid } from '@material-ui/core'
import Alert from 'elements/Alert'
import { FC } from 'react'
import { BooleanInput, FormDataConsumer, NumberInput, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { currencySymbolForCountry } from 'util/misc'
import { getOrgCountryCodeFromState } from 'util/org'
import { requiredOrZero } from 'validations'
import { ADDER_FORMULA_CHOICES } from './AddersForm'

type PropsType = {
  resource: string
}
const CostAdderInputs: FC<PropsType> = ({ resource }) => {
  const translate = useTranslate()
  const currencySymbol = useSelector((state: any) => {
    const countryIso2 = getOrgCountryCodeFromState(state)
    return currencySymbolForCountry(countryIso2)
  })
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <h2>{translate('Cost Adder')}</h2>
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <FormDataConsumer>
          {({ formData, ...rest }: any) => {
            const adderFormula = ADDER_FORMULA_CHOICES.find((choice) => choice.id === formData.formula)
            let endAdornmentValue = ''
            if (adderFormula && adderFormula.name !== 'Fixed') {
              endAdornmentValue = adderFormula.name
            }

            return (
              <>
                <NumberInput
                  name={'cost_value'}
                  validate={requiredOrZero}
                  source="cost_value"
                  label={'Cost Adder (ex tax)'}
                  options={{ helperText: 'Discount(-)/Adder(+)' }}
                  resource={resource}
                  startAdornment={currencySymbol}
                  endAdornment={translate(endAdornmentValue)}
                  fullWidth
                  style={{ width: '100%' }}
                />
                <BooleanInput
                  name={'show_customer_cost'}
                  source="show_customer_cost"
                  label={'Show to Customer when using Markup Pricing'}
                  resource={resource}
                  fullWidth
                  style={{ width: '100%' }}
                />
              </>
            )
          }}
        </FormDataConsumer>
      </Grid>
      <Grid item xs={12}>
        <Alert severity="info">
          {translate('Adder cost will not reflect in the system price unless using Markup Pricing Scheme.')}
        </Alert>
      </Grid>
    </Grid>
  )
}

export default CostAdderInputs

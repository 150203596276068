import { Grid, Tooltip } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import type { OpenSolarThemeType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import useFilter from 'elements/controller/filter/useFilter'
import useQuery from 'elements/controller/query/useQuery'
import BasicAutocompleteSearchInput from 'elements/input/BasicAutocompleteSearchInput'
import inflection from 'inflection'
import useOrderComponentsListBasic from 'pages/ordering/hooks/useOrderComponentsListBasic'
import usePersistentOrderingQueryParams from 'pages/ordering/hooks/usePersistentOrderingQueryParams'
import type { LineItemLoadedDataType } from 'pages/ordering/type'
import { useCallback, useState } from 'react'
import { Identifier } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles(
  (theme: OpenSolarThemeType) => ({
    code: {},
    type: {
      color: theme.greyMid1,
    },
    image: {
      maxWidth: 40,
      maxHeight: 32,
      margin: 'auto',
    },
    imageWrapper: {
      width: 40,
      height: 40,
      textAlign: 'center',
    },
    menuItem: {
      cursor: 'pointer',
      boxSizing: 'border-box',
      display: 'flex',
      whiteSpace: 'normal',
    },
  }),
  { name: 'Inventory-Hardware-Search-Menu-Item' }
)

const ComponentAutocompleteInput = ({
  trackingSource,
  onSelect,
  disabled,
}: {
  trackingSource: string
  onSelect({ code, componentType }): void
  disabled?: boolean
}) => {
  const classes = useStyles()
  const [showSuggestion, setShowSuggestion] = useState(false)
  const { query, queryPresenter } = useQuery()
  const { filter, filterPresenter } = useFilter({
    queryPresenter,
  })
  const persistentFilterValues = usePersistentOrderingQueryParams({
    showExhibitedFirst: false,
    catalog: 'basic',
  })
  const { data, ids, total, loading, loaded } = useOrderComponentsListBasic({
    resource: 'components___aliases',
    page: query.page,
    perPage: query.perPage,
    sort: query.sort,
    order: query.order,
    filterValues: {
      ...persistentFilterValues,
      ...filter.filterValues,
      require_distributor: undefined, // Do not filter by distributor
    },
  })

  const options: LineItemLoadedDataType[] = []
  ids?.forEach((id: Identifier) => {
    if (data?.[id] != null) {
      options.push(data[id])
    }
  })

  const handleChange = (value: string, immediate?: boolean) => {
    filterPresenter.setFilters({ ...filter.filterValues, q: value }, undefined, immediate)
  }

  const onFirstTouch = useCallback(() => {
    logAmplitudeEvent('hardware_search_autocomplete_input', {
      action: 'typed',
      source: trackingSource,
    })
  }, [])

  const handleSelect = useCallback(
    (item: LineItemLoadedDataType) => {
      handleChange(item.code, true)
      onSelect({
        code: item.code,
        componentType: item.component_type,
      })
      logAmplitudeEvent('hardware_search_autocomplete_input', {
        action: 'selected',
        context: 'suggest_item',
        source: trackingSource,
        componentType: item.component_type,
      })
    },
    [handleChange]
  )

  const handleBackdropClicked = useCallback(() => {
    setShowSuggestion(false)
  }, [])

  const handleFocus = useCallback(() => {
    setShowSuggestion(true)
  }, [])

  const renderer = (record) => {
    const componentType = record.component_type
    const code = record.code
    const imageUrl = record.image_url
    return (
      <div className={classes.menuItem}>
        <div className={classes.imageWrapper}>
          {imageUrl ? (
            <img className={classes.image} src={imageUrl} />
          ) : (
            <img className={classes.image} src={`${window.PUBLIC_URL}/images/component_photo_holder.svg`} />
          )}
        </div>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.code}>{code}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.type}>{componentType && inflection.capitalize(componentType)}</Typography>
          </Grid>
        </Grid>
      </div>
    )
  }

  return (
    <Tooltip title={disabled ? 'Please select send to & shipping address' : ''}>
      <div>
        <BasicAutocompleteSearchInput
          value={filter.filterValues?.q as string}
          showSuggestion={showSuggestion}
          placeholder={'Search to add an item'}
          loading={loading}
          renderer={renderer}
          options={options}
          InputProps={{
            startAdornment: <div>+</div>,
          }}
          onSelect={handleSelect}
          onChange={handleChange}
          onFirstTouch={onFirstTouch}
          closeSuggestion={handleBackdropClicked}
          onFocus={handleFocus}
          disabled={disabled}
        />
      </div>
    </Tooltip>
  )
}

export default ComponentAutocompleteInput

import { DraggableListColumn } from 'elements/dnd/DraggableList'
import { DraggableListController, useDraggabbleListController } from 'elements/dnd/useDraggabbleListController'
import { Button, ColumnVerticalIcon, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { BooleanInput } from 'ra-ui-materialui'
import React, { useMemo } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { useForm, useFormState } from 'react-final-form'
import { SectionHeader } from '../styles'
import { ProjectsListColumn } from './utils'

const ButtonStyled = styled(Button)({
  justifyContent: 'flex-start',
})

const BooleanStyled = styled(BooleanInput)({
  marginLeft: 'auto',
})

const ColumnSettings: React.FC = (props) => {
  const form = useForm()
  const translate = useTranslate()
  const formValues = useFormState().values
  const columns = useMemo(() => formValues.columns, [formValues.columns])
  const allSelected = useMemo(() => columns.every((x) => x.enabled), [columns])
  const handleToggleAll = (checked) => {
    const updatedCols = columns.map((x) => ({ ...x, enabled: x.required || checked }))
    form.change('columns', updatedCols)
  }
  const onDragComplete = (oldGroups, newGroups: { [key: string]: ProjectsListColumn[] }) => {
    //restrict drag on required items
    let newColumns = [...newGroups.columns]
    newColumns = [...(newColumns.filter((x) => x.required) || []), ...(newColumns.filter((x) => !x.required) || [])]

    form.change('columns', newColumns)
  }
  const groups = useMemo(() => ({ columns }), [columns])
  const controller: DraggableListController<ProjectsListColumn, string> = useDraggabbleListController({
    groups: groups,
    optionId: 'id',
    onDragComplete,
  })
  return (
    <>
      <SectionHeader>{translate('Column Settings')}</SectionHeader>
      <ButtonStyled
        startIcon={<ColumnVerticalIcon />}
        disableRipple
        variant="text"
        color="secondary"
        onClick={() => {
          handleToggleAll(!allSelected)
        }}
      >
        {translate(allSelected ? 'Hide All Columns' : 'Show All Columns')}
      </ButtonStyled>
      <DragDropContext onDragStart={controller.onDragStart} onDragEnd={controller.onDragEnd}>
        <DraggableListColumn
          controller={controller}
          render={(item, { index, dragHandle }) => (
            <ColumnToggleView source={'columns.' + index} dragHandle={dragHandle} {...item} />
          )}
          dragMode="drag-handle"
          isDragDisabled={(item: any) => !!item.required}
          groupId="columns"
        />
      </DragDropContext>
    </>
  )
}

const ToggleWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  margin: '5px 0',
})
const ColumnToggleView = (props) => {
  const { display, source, required, dragHandle } = props
  const translate = useTranslate()
  return (
    <ToggleWrapper key={'column-' + props.id}>
      {dragHandle}
      <span style={{ color: required ? 'grey' : undefined }}>{translate(display)}</span>
      <BooleanStyled disabled={required} source={source + '.enabled'} label={''} options={{ color: 'primary' }} />
    </ToggleWrapper>
  )
}

export default ColumnSettings

// @ts-nocheck
import { makeStyles } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import Alert from 'elements/Alert'
import GenericButton from 'elements/button/GenericButton.js'
import React, { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { OtherComponentType } from 'types/otherComponent'
import { currencySymbolForCountry, systemNameOrDefault } from 'util/misc'
import { getOrgCountryCodeFromState } from 'util/org'
import {
  BomLineItemType,
  BomToSaveLineItemType,
  RackingManufacturerTypes,
} from '../nativeMounting/mounting_systems/Esdec/types'
import EsdecRenusolBomRow from './RenusolBomRow'

declare var editor: editor

const useStyles = makeStyles({
  bomModalContentWrapper: {
    padding: '5px 30px 30px 30px',
    textAlign: 'center',
    position: 'relative',
    justifyContent: 'center',
  },
  bomTable: {
    textAlign: 'left',
  },
  bomId: {
    fontWeight: 900,
  },
  bomProductTitleView: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  bomOtherCol: {
    flex: 1,
    textAlign: 'right',
    opacity: 1.0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bomSection: {
    display: 'flex',
    flexDirection: 'column',
  },
  bomSectionTitleView: {
    display: 'flex',
    backgroundColor: '#ececec',
    padding: '5px 0px',
    margin: '10px 0px 0px 0px',
  },
  bomSectionTitle: {
    flex: 2,
    margin: '2px',
  },
  bomFieldSubLabelWrapper: {
    flex: 3,
    display: 'flex',
  },
  bomFieldSubLabelView: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  bomTableLabelRow: {
    flex: 2,
    fontWeight: 900,
    fontSize: '18px',
    display: 'flex',
    flexDirection: 'row',
  },
  bomFieldLabelsView: {
    flex: 3,
    display: 'flex',
    flexDirection: 'row',
  },
  tooltipIcon: {
    marginLeft: '5px',
    backgroundColor: '#ececec',
    padding: '3px 5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    fontSize: '12px',
  },
  bomButtonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: '10px',
  },
  totalsTableWrapper: {
    margin: '5px 0px 20px 0px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end',
  },
  totalsTable: {
    display: 'flex',
    flexDirection: 'column',
  },
  totalsTableTitle: {
    fontWeight: 900,
    fontSize: '18px',
    width: '100%',
    textAlign: 'center',
    padding: '5px 0px',
  },
  totalsTableRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '5px',
    borderBottom: '1px solid #ececec',
  },
  totalsTitle: {
    fontWeight: 900,
  },
  totalsValue: {
    marginLeft: '50px',
  },
  input: {
    backgroundColor: '#fff',
    maxWidth: '70%',
    borderRadius: 5,
    textAlign: 'center',
    borderColor: '#ffda00',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    boxShadow: '2px 2px #ececec',
  },
  warning: {
    textAlign: 'center',
    color: 'black',
    margin: '0px 10px',
    padding: '4px',
  },
  warningWrapper: {
    borderRadius: '5px',
    backgroundColor: '#fcf4c5',
    opacity: 0.7,
    marginBottom: '15px',
  },
  highPriorityWarningWrapper: {
    borderRadius: '5px',
    backgroundColor: 'rgb(246, 204, 209)',
    opacity: 0.7,
    marginBottom: '15px',
  },
})

type PropTypes = {
  bomLineItems: BomLineItemType[]
  onClose: () => void
  warnings: string[]
  manufacturerName: RackingManufacturerTypes
  onBomAcceptance: (
    newComponents: OtherComponentType[],
    allComponents: OtherComponentType[],
    manufacturerName: RackingManufacturerTypes,
    markAsComplete: () => void
  ) => void
}

const RenusolBOMDialog: React.FC<PropTypes> = (props) => {
  const getSystemName = () => {
    let systemName = undefined
    if (editor.selectedSystem?.name?.length > 0) systemName = editor.selectedSystem.name
    else {
      let systemClone = { ...editor.selectedSystem }
      systemClone.module_quantity = editor.selectedSystem.moduleQuantity()
      systemName = systemNameOrDefault(systemClone)
    }
    return systemName
  }

  const [bomToSave, setBomToSave] = useState<BomToSaveLineItemType[]>(props.bomLineItems)
  const [systemName, setSystemName] = useState<string | undefined>(undefined)
  const [saving, setSaving] = useState<boolean>(false)
  const [requiredItems, setRequiredItems] = useState<BomLineItemType[]>([])
  const [optionalItems, setOptionalItems] = useState<BomLineItemType[]>([])

  useEffect(() => {
    if (props.bomLineItems) {
      logAmplitudeEvent('integrated_racking_dialog_submitted', {
        integration: props.manufacturerName,
        item_count: props.bomLineItems?.length,
      })
      props.bomLineItems.forEach((item: BomLineItemType) => {
        logAmplitudeEvent('uk_racking_item_imported', {
          integration: props.manufacturerName,
          project_id: window.WorkspaceHelper?.project?.id,
          segen_code: item?.segen_code || 'not found',
          is_optional: item?.optional,
          unit_price: item?.unit_price !== undefined ? item.unit_price : 'Not Found',
          suggested_count: item?.suggested_count,
          mfr_code: item?.mfr_code,
        })
      })
    }
  }, [props.bomLineItems])

  useEffect(() => {
    if (props.bomLineItems && props.bomLineItems?.length > 0) {
      setRequiredItems(props.bomLineItems.filter((item: BomLineItemType) => !item.optional))
      setOptionalItems(props.bomLineItems.filter((item: BomLineItemType) => !!item.optional))
    }
  }, [props.bomLineItems])

  useEffect(() => {
    setSystemName(getSystemName())
  }, [])

  const classes = useStyles()
  const translate = useTranslate()

  const existingMfrComponents = editor.selectedSystem?.children?.filter(
    (component: any) => component.type === 'OsOther' && component.manufacturer_name === props.manufacturerName
  )
  const orgComponents = window.AccountHelper.getComponentOtherSpecsAvailable()?.filter(
    (comp: any) => comp.manufacturer_name?.toLowerCase() === props.manufacturerName?.toLowerCase()
  )

  const currencySymbol = useSelector((state: any) => {
    return currencySymbolForCountry(getOrgCountryCodeFromState(state))
  })

  const confirmBeforeClose = () => {
    if (
      window.confirm(
        translate('Are you sure you want to close this form? All progress will be lost and your BOM will not be saved')
      )
    )
      props.onClose()
  }

  const saveValues = () => {
    // we'll do a few things here:
    // 1.) loop over all of the components in the bom that have a quantity above 0. Add them to the allComponentsToAddToSystem array which we'll temporarly place on the system
    // 2.) create another array (newOrgOtherComponents) that contains only those components which don't yet exist on the org

    //first go get any existing other components that match this mfr on the system and remove them
    if (existingMfrComponents && existingMfrComponents.length > 0) {
      existingMfrComponents.forEach((existingComp) => window.editor.deleteObject(existingComp))
    }
    //get list of other components on the org to decide if we need to add any to the org before putting it on the system
    let orgComponentMap: any = {}
    if (orgComponents && orgComponents.length > 0) {
      orgComponents.forEach((component: any) => {
        orgComponentMap[component.code] = component
      })
    }

    let newOrgOtherComponents: any[] = []
    let allComponentsToAddToSystem: any[] = []
    bomToSave.forEach((item: BomToSaveLineItemType) => {
      let codeToUse = item.segen_code ? item.segen_code : item.mfr_code
      let componentId = orgComponentMap[codeToUse] ? orgComponentMap[codeToUse].id : null
      let newOtherComponent = {
        code: codeToUse,
        manufacturer_name: props.manufacturerName,
        title: item.name,
        other_component_type: 'mounting_other',
        dc_optimizer_efficiency: null,
        dc_optimizer_max_input_power: null,
        dc_optimizer_max_input_voltage: null,
        dc_optimizer_max_input_current: null,
        description: item.description || '',
        show_customer: false,
        show_in_your_solution: false,
        show_in_quotation_table: false,
        additional_costs_enabled: false,
        formula: '',
        value: null,
        cycle_units: '',
        start: null,
        end: null,
        annual_adjustment_percentage: null,
        product_warranty: null,
        skus: '',
        quantity: item.quantity_override !== undefined ? item.quantity_override : item.suggested_count,
        other_id: componentId,
        cost: item.unit_price,
        id: componentId,
        ordering: item.ordering || null,
      } as OtherComponentType
      // if the quantity is set to 0 then just skip this component
      if (newOtherComponent.quantity > 0) {
        if (!componentId) newOrgOtherComponents.push(newOtherComponent)
        else newOtherComponent.id = componentId
        allComponentsToAddToSystem.push(newOtherComponent)
      }
    })
    setSaving(true)
    const cleanUpAfterSave = () => {
      setSaving(false)
      logAmplitudeEvent('integrated_racking_BOM_Saved', { integration: props.manufacturerName })
    }
    props.onBomAcceptance(newOrgOtherComponents, allComponentsToAddToSystem, props.manufacturerName, cleanUpAfterSave)
  }
  const onQuantityChange = (newVal: string, itemToMatch: BomLineItemType) => {
    let newBomToSave = [...bomToSave]
    newBomToSave?.forEach((item) => {
      const isMatch =
        (!!item.segen_code && item.segen_code === itemToMatch.segen_code) ||
        item.mfr_code === itemToMatch.mfr_code ||
        item.name === itemToMatch.name

      if (isMatch) {
        item.quantity_override = parseInt(newVal)
      }
    })
    setBomToSave(newBomToSave)
  }

  return (
    <div className={classes.bomModalContentWrapper}>
      <h1>
        Bill of Materials for {systemName} at {window.WorkspaceHelper.project.address || 'this project'}
      </h1>
      {props.warnings?.length > 0 &&
        props.warnings.map((message, messageIndex) => (
          <Alert key={messageIndex} severity="error">
            {translate(message)}
          </Alert>
        ))}
      <div className={classes.bomTable}>
        <div className={classes.bomSection}>
          <div className={classes.bomSectionTitleView}>
            <div style={{ flex: 2 }}>
              <h4 className={classes.bomSectionTitle}>Required Items</h4>
            </div>
            <div style={{ flex: 1, fontWeight: 900, textAlign: 'center' }}>Recommended Quantity</div>
            <div style={{ flex: 1, fontWeight: 900, textAlign: 'center' }}>Quantity</div>
            <div style={{ flex: 2, fontWeight: 900, textAlign: 'right', paddingRight: '5px' }}>
              Total {currencySymbol}
            </div>
          </div>
          {requiredItems.map((item: any, i: number) => (
            <EsdecRenusolBomRow
              item={item}
              categoryName="required"
              i={i}
              onQuantityChange={onQuantityChange}
              currencySymbol={currencySymbol}
            />
          ))}
        </div>
        {optionalItems?.length > 0 && (
          <div className={classes.bomSection}>
            <div className={classes.bomSectionTitleView}>
              <h4 className={classes.bomSectionTitle}>Optional Items</h4>
            </div>
            {optionalItems.map((item: any, i: number) => (
              <EsdecRenusolBomRow
                item={item}
                categoryName="required"
                i={i}
                onQuantityChange={onQuantityChange}
                currencySymbol={currencySymbol}
              />
            ))}
          </div>
        )}
      </div>
      <div className={classes.bomButtonsWrapper}>
        <GenericButton
          onClick={confirmBeforeClose}
          label="Dismiss"
          disabled={false}
          backgroundColor="#ececec"
          labelColor="rgb(77,77,77)"
          tooltipLabel={null}
        />
        <GenericButton
          onClick={saveValues}
          label="Save Changes"
          backgroundColor="#ffda00"
          labelColor="rgb(77,77,77)"
          tooltipLabel={null}
          disabled={saving}
        />
      </div>
    </div>
  )
}

export default RenusolBOMDialog

// @ts-nocheck
import { Dialog, DialogContent, Slide, useMediaQuery } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/CloseOutlined'
import React, { useState } from 'react'
import { useTranslate } from 'react-admin'

type PropTypes = {
  isOpen: boolean
  onClose: () => void
  url: string
  isFullWidth: boolean
}

const SlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const BrighteApplicationDialog: React.FunctionComponent<PropTypes> = (props) => {
  const [isHoveringClose, setIsHoveringClose] = useState<boolean>(false)
  const translate = useTranslate()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const confirmBeforeClose = () => {
    if (window.confirm(translate('Are you sure you want to exit your application? All progress will be lost')))
      props.onClose()
  }

  return (
    <Dialog
      open={props.isOpen}
      onClose={confirmBeforeClose}
      TransitionComponent={SlideUp}
      fullWidth={true}
      fullScreen={isMobile || props.isFullWidth}
      maxWidth={isMobile || props.isFullWidth ? undefined : 'xl'}
    >
      <DialogContent style={{ backgroundColor: 'rgb(244,245,249)' }}>
        <div style={{ height: '90vh' }}>
          <div onClick={confirmBeforeClose} style={{ marginBottom: '10px' }}>
            <div
              style={{
                backgroundColor: '#fff',
                width: '26px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '15px',
                boxShadow: '0px 1px 3px rgba(0,0,0,0.3)',
                opacity: isHoveringClose ? 0.5 : 1.0,
              }}
              onMouseEnter={() => setIsHoveringClose(true)}
              onMouseLeave={() => setIsHoveringClose(false)}
            >
              <CloseIcon style={{ cursor: 'pointer' }} />
            </div>
          </div>
          <iframe src={`${props.url}#cs`} height="100%" width="100%" frameBorder="0" />
        </div>
      </DialogContent>
    </Dialog>
  )
}
export default BrighteApplicationDialog

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { complete, getNotification, hideNotification, undoableEventEmitter } from 'ra-core';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PureNotification from './PureNotification';
var Notification = function (props) {
    var _a;
    var classesOverride = props.classes, type = props.type, className = props.className, defaultAutoHideDuration = props.autoHideDuration, rest = __rest(props, ["classes", "type", "className", "autoHideDuration"]);
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var notification = useSelector(getNotification);
    var dispatch = useDispatch();
    var isInteractive = !!((_a = notification === null || notification === void 0 ? void 0 : notification.buttons) === null || _a === void 0 ? void 0 : _a.length);
    useEffect(function () {
        setOpen(!!notification);
    }, [notification]);
    var handleRequestClose = useCallback(function () {
        setOpen(false);
    }, [setOpen]);
    var getAutoHideDuration = function () {
        if (notification === null || notification === void 0 ? void 0 : notification.hasOwnProperty('autoHideDuration')) {
            return notification === null || notification === void 0 ? void 0 : notification.autoHideDuration;
        }
        return defaultAutoHideDuration || (isInteractive ? undefined : 4000);
    };
    var handleExited = useCallback(function () {
        if (notification === null || notification === void 0 ? void 0 : notification.undoable) {
            dispatch(complete());
            undoableEventEmitter.emit('end', { isUndo: false });
        }
        dispatch(hideNotification());
    }, [dispatch, notification]);
    //   const handleUndo = useCallback(() => {
    //     dispatch(undo())
    //     undoableEventEmitter.emit('end', { isUndo: true })
    //   }, [dispatch])
    var message = (notification === null || notification === void 0 ? void 0 : notification.message) || '';
    var buttons = notification === null || notification === void 0 ? void 0 : notification.buttons;
    var id = (notification === null || notification === void 0 ? void 0 : notification.id) || message.toLowerCase().replace(' ', '_');
    if (id)
        id = 'notification_' + id;
    var handleSnackbarClose = function (_, reason) {
        if (isInteractive && reason == 'clickaway')
            return;
        handleRequestClose();
    };
    return (_jsx(PureNotification, __assign({ id: id, open: open, message: message, type: (notification === null || notification === void 0 ? void 0 : notification.type) || type, onExited: handleExited, onClose: handleSnackbarClose, handleRequestClose: handleRequestClose, buttons: buttons, disableWindowBlurListener: notification && notification.undoable, isInteractive: buttons === null || buttons === void 0 ? void 0 : buttons.length, autoHideDuration: getAutoHideDuration() }, rest)));
};
Notification.propTypes = {
    type: PropTypes.string,
};
Notification.defaultProps = {
    type: 'info',
    autoHideDuration: undefined,
};
export default Notification;

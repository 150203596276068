import type { OpenSolarThemeType } from 'Themes'
import React, { memo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: '20px 0px',
    fontSize: '18px',
    fontWeight: theme.typography.fontWeightRegular,
  },
  text: {
    marginRight: 5,
  },
}))

const ReplacementCatalogTitle = () => {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      <span className={classes.text}>Find replacement hardware</span>
    </div>
  )
}

export default memo(ReplacementCatalogTitle)

import { setOrgId } from 'ducks/auth'
import { clearLineItems, updateSelectedHardwareSupplier } from 'ducks/orderComponents'
import { useDispatch } from 'react-redux'

const useSwitchOrg = () => {
  const dispatch = useDispatch()

  return function switchOrg({ orgId, reloadOrg = true }: { orgId: number; reloadOrg?: boolean }) {
    dispatch(setOrgId({ orgId, reloadOrg }))
    dispatch(updateSelectedHardwareSupplier(undefined))
    dispatch(clearLineItems())
  }
}

export default useSwitchOrg

const FrameSpacingPortraitIcon = () => (
  <svg width="100%" height="100%" viewBox="0 0 232 233" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.343262" width="232" height="232" fill="white" />
    <path
      d="M217 95.6716V81.1716L186 77.6716H185L154 81.1716H147L116 77.6716H115L84 81.1716H77L46 77.6716H45L14 81.1716V95.6716L45 92.1716H46L77 95.6716H84L115 92.1716H116L147 95.6716H154L185 92.1716H186L217 95.6716Z"
      fill="#FFAB1F"
    />
    <path
      d="M217 160.672V146.172L186 142.672H185L154 146.172H147L116 142.672H115L84 146.172H77L46 142.672H45L14 146.172V160.672L45 157.172H46L77 160.672H84L115 157.172H116L147 160.672H154L185 157.172H186L217 160.672Z"
      fill="#FFAB1F"
    />
    <path d="M14 30.4761L45 27.1716V50.8672L14 54.1716V30.4761Z" fill="#727272" />
    <path
      d="M19.8333 40.0884L17.9249 42.1721L19.8333 44.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M46 27.1716L77 30.4761V54.1716L46 50.8672V27.1716Z" fill="#727272" />
    <path
      d="M71.1667 40.0884L73.0751 42.1721L71.1667 44.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14 57.4761L45 54.1716V77.8672L14 81.1716V57.4761Z" fill="#727272" />
    <path d="M46 54.1716L77 57.4761V81.1716L46 77.8672V54.1716Z" fill="#727272" />
    <path
      d="M19.8333 66.0884L17.9249 68.1721L19.8333 70.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71.1667 66.0884L73.0751 68.1721L71.1667 70.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M84 30.4761L115 27.1716V50.8672L84 54.1716V30.4761Z" fill="#727272" />
    <path
      d="M89.8333 40.0884L87.9249 42.1721L89.8333 44.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M116 27.1716L147 30.4761V54.1716L116 50.8672V27.1716Z" fill="#727272" />
    <path
      d="M141.167 40.0884L143.075 42.1721L141.167 44.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M84 57.4761L115 54.1716V77.8672L84 81.1716V57.4761Z" fill="#727272" />
    <path d="M116 54.1716L147 57.4761V81.1716L116 77.8672V54.1716Z" fill="#727272" />
    <path
      d="M89.8333 66.0884L87.9249 68.1721L89.8333 70.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M141.167 66.0884L143.075 68.1721L141.167 70.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M154 30.4761L185 27.1716V50.8672L154 54.1716V30.4761Z" fill="#727272" />
    <path
      d="M159.833 40.0884L157.925 42.1721L159.833 44.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M186 27.1716L217 30.4761V54.1716L186 50.8672V27.1716Z" fill="#727272" />
    <path
      d="M211.167 40.0884L213.075 42.1721L211.167 44.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M154 57.4761L185 54.1716V77.8672L154 81.1716V57.4761Z" fill="#727272" />
    <path d="M186 54.1716L217 57.4761V81.1716L186 77.8672V54.1716Z" fill="#727272" />
    <path
      d="M159.833 66.0884L157.925 68.1721L159.833 70.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M211.167 66.0884L213.075 68.1721L211.167 70.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14 160.476L45 157.172V180.867L14 184.172V160.476Z" fill="#727272" />
    <path
      d="M19.8333 170.088L17.9249 172.172L19.8333 174.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M46 157.172L77 160.476V184.172L46 180.867V157.172Z" fill="#727272" />
    <path
      d="M71.1667 170.088L73.0751 172.172L71.1667 174.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14 187.476L45 184.172V207.867L14 211.172V187.476Z" fill="#727272" />
    <path d="M46 184.172L77 187.476V211.172L46 207.867V184.172Z" fill="#727272" />
    <path
      d="M19.8333 196.088L17.9249 198.172L19.8333 200.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71.1667 196.088L73.0751 198.172L71.1667 200.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M84 160.476L115 157.172V180.867L84 184.172V160.476Z" fill="#727272" />
    <path
      d="M89.8333 170.088L87.9249 172.172L89.8333 174.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M116 157.172L147 160.476V184.172L116 180.867V157.172Z" fill="#727272" />
    <path
      d="M141.167 170.088L143.075 172.172L141.167 174.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M84 187.476L115 184.172V207.867L84 211.172V187.476Z" fill="#727272" />
    <path d="M116 184.172L147 187.476V211.172L116 207.867V184.172Z" fill="#727272" />
    <path
      d="M89.8333 196.088L87.9249 198.172L89.8333 200.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M141.167 196.088L143.075 198.172L141.167 200.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M154 160.476L185 157.172V180.867L154 184.172V160.476Z" fill="#727272" />
    <path
      d="M159.833 170.088L157.925 172.172L159.833 174.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M186 157.172L217 160.476V184.172L186 180.867V157.172Z" fill="#727272" />
    <path
      d="M211.167 170.088L213.075 172.172L211.167 174.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M154 187.476L185 184.172V207.867L154 211.172V187.476Z" fill="#727272" />
    <path d="M186 184.172L217 187.476V211.172L186 207.867V184.172Z" fill="#727272" />
    <path
      d="M159.833 196.088L157.925 198.172L159.833 200.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M211.167 196.088L213.075 198.172L211.167 200.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14 95.4761L45 92.1716V115.867L14 119.172V95.4761Z" fill="#727272" />
    <path
      d="M19.8333 105.088L17.9249 107.172L19.8333 109.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M46 92.1716L77 95.4761V119.172L46 115.867V92.1716Z" fill="#727272" />
    <path
      d="M71.1667 105.088L73.0751 107.172L71.1667 109.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14 122.476L45 119.172V142.867L14 146.172V122.476Z" fill="#727272" />
    <path d="M46 119.172L77 122.476V146.172L46 142.867V119.172Z" fill="#727272" />
    <path
      d="M19.8333 131.088L17.9249 133.172L19.8333 135.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71.1667 131.088L73.0751 133.172L71.1667 135.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M84 95.4761L115 92.1716V115.867L84 119.172V95.4761Z" fill="#727272" />
    <path
      d="M89.8333 105.088L87.9249 107.172L89.8333 109.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M116 92.1716L147 95.4761V119.172L116 115.867V92.1716Z" fill="#727272" />
    <path
      d="M141.167 105.088L143.075 107.172L141.167 109.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M84 122.476L115 119.172V142.867L84 146.172V122.476Z" fill="#727272" />
    <path d="M116 119.172L147 122.476V146.172L116 142.867V119.172Z" fill="#727272" />
    <path
      d="M89.8333 131.088L87.9249 133.172L89.8333 135.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M141.167 131.088L143.075 133.172L141.167 135.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M154 95.4761L185 92.1716V115.867L154 119.172V95.4761Z" fill="#727272" />
    <path
      d="M159.833 105.088L157.925 107.172L159.833 109.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M186 92.1716L217 95.4761V119.172L186 115.867V92.1716Z" fill="#727272" />
    <path
      d="M211.167 105.088L213.075 107.172L211.167 109.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M154 122.476L185 119.172V142.867L154 146.172V122.476Z" fill="#727272" />
    <path d="M186 119.172L217 122.476V146.172L186 142.867V119.172Z" fill="#727272" />
    <path
      d="M159.833 131.088L157.925 133.172L159.833 135.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M211.167 131.088L213.075 133.172L211.167 135.255"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default FrameSpacingPortraitIcon

import { InverterDataType } from 'types/inverter'
import { RootState } from 'types/state'

export const SET_AUTO_STRING_MODULE_UUIDS = 'SET_AUTO_STRING_MODULE_UUIDS'
export const SET_AUTO_STRING_RECOMMENDATIONS = 'SET_AUTO_STRING_RECOMMENDATIONS'
export const SET_AUTO_STRING_RUNNING = 'SET_AUTO_STRING_RUNNING'
export const SYSTEM_AUTO_STRINGING_REQUEST = 'SYSTEM_AUTO_STRINGING_REQUEST'

export const requestAutoStringing = (
  uuid: string,
  return_type: string,
  moduleUuids?: string[],
  inverterRange?: string | number,
  replaceInverters?: boolean
): any => ({
  type: SYSTEM_AUTO_STRINGING_REQUEST,
  payload: {
    uuid,
    return_type,
    moduleUuids,
    inverterRange,
    replaceInverters,
  },
})

export type AutoStringReducerState = {
  moduleUuids?: string[]
  recommendations: AutoStringRecommendation | undefined
  running: AutoStringRunType | undefined
}
const initialState: AutoStringReducerState = {
  moduleUuids: undefined,
  recommendations: undefined,
  running: undefined,
}

export type AutoStringRunType = 'all' | 'best'

type AutoStringRecommendation = {
  system_uuid: string
  system_designs: AutoStringSysRecommendation[]
}
type AutoStringSysRecommendation = {
  dc_ac_ratio: number
  system_design: AutoStringDesignRecommendation
}
export type AutoStringDesignRecommendation = {
  inverters: AutoStringInverterRecommendation[]
}
type AutoStringInverterRecommendation = InverterDataType & {
  mppts: AutoStringMpptRecommendation[]
}
type AutoStringMpptRecommendation = {
  strings: AutoStringStringRecommendation[]
}
type AutoStringStringRecommendation = {
  modules: AutoStringModuleRecommendation[]
}
type AutoStringModuleRecommendation = {}

export function autoStringReducer(
  state: AutoStringReducerState = initialState,
  action: ActionType
): AutoStringReducerState {
  switch (action.type) {
    case SET_AUTO_STRING_MODULE_UUIDS:
      return {
        ...state,
        moduleUuids: action.moduleUuids,
      }
    case SET_AUTO_STRING_RECOMMENDATIONS:
      return {
        ...state,
        recommendations: action.recommendations,
      }
    case SET_AUTO_STRING_RUNNING:
      return {
        ...state,
        running: action.running,
      }
    default:
      return state
  }
}

interface SetModuleUuids {
  type: typeof SET_AUTO_STRING_MODULE_UUIDS
  moduleUuids: string[] | undefined
}
interface SetAutoStringRecommendations {
  type: typeof SET_AUTO_STRING_RECOMMENDATIONS
  recommendations: AutoStringRecommendation | undefined
}
interface SetAutoStringRunning {
  type: typeof SET_AUTO_STRING_RUNNING
  running: AutoStringRunType | undefined
}

type ActionType = SetModuleUuids | SetAutoStringRecommendations | SetAutoStringRunning

export function setAutoStringModuleUuids(value: string[] | undefined): SetModuleUuids {
  return {
    type: SET_AUTO_STRING_MODULE_UUIDS,
    moduleUuids: value,
  }
}

export function setAutoStringRecommendations(
  recommendations: AutoStringRecommendation | undefined
): SetAutoStringRecommendations {
  return {
    type: SET_AUTO_STRING_RECOMMENDATIONS,
    recommendations: recommendations,
  }
}

export function setAutoStringRunning(value: AutoStringRunType | undefined): SetAutoStringRunning {
  return {
    type: SET_AUTO_STRING_RUNNING,
    running: value,
  }
}

export const autoStringSelectors = {
  moduleUuids: (state: RootState) => {
    return state.designer.autoString.moduleUuids
  },
  recommendations: (state: RootState) => {
    return state.designer.autoString.recommendations
  },
  running: (state: RootState) => {
    return state.designer.autoString.running
  },
  getRecommedationsForSystem: (system_uuid: string) => (state: RootState) => {
    const recommedations = autoStringSelectors.recommendations(state)
    return recommedations?.system_uuid === system_uuid ? recommedations : undefined
  },
}

import { formSelectors, setFormDialog } from 'ducks/formDialog'
import { SubFormDialog } from 'elements/react-admin/SubFormDialog'
import { useProjectFormMutators } from 'projectSections/form/mutations'
import { FC, useCallback, useEffect } from 'react'
import { useForm } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { DialogHelper } from 'util/misc'
import CustomFormDialogContent from './CustomFormDialogContent'

const FormDialog: FC = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const isOpen = useSelector(formSelectors.getIsOpen)
  const customForm = useSelector(formSelectors.getForm)
  const systemUuid = useSelector(formSelectors.getSystemUuid)
  const system = systemUuid ? window.editor.objectByUuid(systemUuid) : null
  const configuration = customForm?.form.configuration
  const parentForm = useForm()
  const mutations = useProjectFormMutators()

  useEffect(() => {
    if (isOpen) {
      DialogHelper.afterOpen('DialogForm')
    }
  }, [isOpen])

  const handleClose = useCallback(() => {
    DialogHelper.beforeClose()
    dispatch(setFormDialog(false, null, null))
    history.push(location.pathname)
  }, [location.pathname])

  if (!customForm || !configuration) return <></>

  return (
    <SubFormDialog
      title={customForm.form.title}
      parentForm={parentForm}
      open={isOpen}
      maxWidth="lg"
      onClose={handleClose}
      mutators={mutations}
    >
      <CustomFormDialogContent customForm={customForm.form} isLoading={!configuration} selectedSystem={system} />
    </SubFormDialog>
  )
}

export default FormDialog

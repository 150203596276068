import _ from 'lodash'

// legacy layout preset:
//  const SMALL = 1
//  const MEDIUM = 2
//  const LARGE = 3
//  const HUGE = 4

//  const Size = {
//   HUGE: [1000, 700],
//   LARGE: [850, 525],
//   MEDIUM: [680, 400],
//   SMALL: [0, 0],
// }
export const LAYOUTS = {
  xs: { width: 0, layout: 1 },
  sm: { width: 680, layout: 2 },
  md: { width: 850, layout: 3 },
  lg: { width: 1000, layout: 4 },
  xl: { width: 1280, layout: 5 },
}

export const breakpoints = _.mapValues(LAYOUTS, 'width')

import { Button, MenuItem, PopoutMenu } from 'opensolar-ui'
import { FC } from 'react'

export const MenuBasic: FC = () => {
  return (
    <>
      {/* SNIPPET START 1 */}
      <PopoutMenu anchorEl={<Button>Menu</Button>} menuId="menu-usage-basic">
        <MenuItem>MenuItem</MenuItem>
        <MenuItem divider>MenuItem with Divider</MenuItem>
      </PopoutMenu>
      {/* SNIPPET END 1 */}
    </>
  )
}

import { DialogTitle } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import CloseDialogButton from 'elements/button/CloseDialogButton'
import { ActionDataType } from 'myenergy/selectionComponent/loanApplicationButton/types'
import { useTranslate } from 'ra-core'
import React from 'react'
import { useSelector } from 'react-redux'
import { getAcceptProposalText } from 'util/misc'
import PaymentsPageHeader from '../cashFlow/PaymentsPageHeader'

type PropTypes = {
  onClose: () => void
  actionData: ActionDataType
  showCashFlowDeposit: boolean
}

const TransactionDialogTitle: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const org = useSelector(orgSelectors.getOrg)

  if (props.showCashFlowDeposit) {
    return (
      <PaymentsPageHeader
        logoURL={org?.logo_public_url || undefined}
        orgName={org?.name}
        hideShadow={true}
        closeDialog={props.onClose}
      />
    )
  }
  return (
    <DialogTitle>
      <div>
        <span>
          {translate(
            getAcceptProposalText(
              props.actionData.action_title,
              props.actionData.quote_acceptance_content,
              props.actionData.payment_amount
            )
          )}
        </span>
        <CloseDialogButton onClose={props.onClose} />
      </div>
    </DialogTitle>
  )
}
export default TransactionDialogTitle

import { Theme, useMediaQuery } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import ProUxButton from 'elements/proUXButtons/ProUXButton'
import React, { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { ProjectType } from 'types/projects'
import { SystemDesignSunlightQuickCreateType } from '../sunlight/types'
import MosaicSendAgreementDialog from './MosaicSendAgreementDialog'
import MosaicSendAppDialog from './MosaicSendAppDialog'

type PropTypes = {
  project: ProjectType
  designs?: SystemDesignSunlightQuickCreateType[]
  mosaicAlreadyApplied?: boolean
  hasMosaicOption: boolean
  mode: 'agreement' | 'application'
}

const MosaicShareCTA: React.FunctionComponent<PropTypes> = (props) => {
  const [showDialog, setShowDialog] = useState<boolean>(false)
  // const [systems, setSystems] = useState<SystemDesignSunlightQuickCreateType[]>([])
  const [systems, setSystems] = useState<SystemDesignSunlightQuickCreateType[]>([])
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 967))
  const isXxs = useMediaQuery((theme: Theme) => theme.breakpoints.down(332))
  // system data can come from two different places
  useEffect(() => {
    if (props.designs && props.designs?.length > 0) setSystems(props.designs)
    else {
      const systemsFromStudio = window.editor.getSystems()?.map((sys) => {
        sys.paymentOptionsData = sys.payment_options
        sys.title =
          sys.name && sys.name.length > 0 ? sys.name : `${(sys.moduleQuantity as any)()} ${translate('Panels System')}`
        return sys
      })
      if (systemsFromStudio && systemsFromStudio.length > 0) setSystems(systemsFromStudio as any)
    }
  }, [props.designs])

  useEffect(() => {
    if (showDialog) {
      if (props.mode === 'agreement') {
        logAmplitudeEvent('send_loan_agreement_dialog_opened', {
          page: window.location.hash?.includes('/manage') ? 'Manage Page' : 'Info Page',
          integration: 'mosaic',
        })
      } else if (props.mode === 'application') {
        logAmplitudeEvent('send_loan_application_dialog_opened', {
          page: window.location.hash?.includes('/manage') ? 'Manage Page' : 'Info Page',
          integration: 'mosaic',
        })
      }
    }
  }, [props.mode, showDialog])

  const translate = useTranslate()
  return (
    <div
      style={{
        width: '100%',
        marginTop: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ProUxButton
        label={props.mode === 'application' ? 'Send Mosaic Credit App' : 'Send Loan Agreement'}
        onClick={() => setShowDialog(true)}
        type="secondary"
        backgroundColor="#fff"
        disabled={props.mosaicAlreadyApplied || !props.hasMosaicOption}
        fullWidth
        fullHeight
        isMarginTop={isMd || isXxs ? true : false}
        labelStyle={{
          display: 'flex',
          flexDirection: isMd || isXxs ? 'column' : 'row',
          alignItems: isMd || isXxs ? 'flex-start' : 'end',
        }}
        startIcon={<img width="21" height="21" src={window.PUBLIC_URL + '/images/Mosaic-Logo-Small.png'} />}
        justifyContent="flex-start"
      />
      {props.mosaicAlreadyApplied && props.mode === 'application' && (
        <span style={{ fontSize: '12px', color: 'rgb(177, 177, 177)', marginTop: '5px' }}>
          {translate('Credit application already submitted')}
        </span>
      )}
      {!props.hasMosaicOption && !props.mosaicAlreadyApplied && (
        <span style={{ fontSize: '12px', color: 'rgb(177, 177, 177)', marginTop: '5px' }}>
          {translate('Add a Mosaic payment option first')}
        </span>
      )}
      {showDialog && (
        <>
          {props.mode === 'application' && (
            <MosaicSendAppDialog
              open={showDialog}
              onClose={() => setShowDialog(false)}
              project={props.project}
              designs={systems}
            />
          )}
          {props.mode === 'agreement' && (
            <MosaicSendAgreementDialog
              open={showDialog}
              onClose={() => setShowDialog(false)}
              project={props.project}
              designs={systems}
            />
          )}
        </>
      )}
    </div>
  )
}
export default MosaicShareCTA

import { applyStudioSignals } from 'Studio/signals/utils'

interface ClosableInfo {
  scope?: object
  close: () => void
}
class ClosablePanels {
  constructor() {
    applyStudioSignals(['escapePressed'], () => this.closeAll())
  }

  panels: Record<string, ClosableInfo> = {}
  add(name: string, close: () => void, scope?: object): () => void {
    this.panels[name] = {
      scope,
      close,
    }
    return () => this.remove(name)
  }

  remove(name: string): void {
    delete this.panels[name]
  }

  closeAll() {
    for (const name in this.panels) this.close(name)
  }

  closeAllExcept(name: string) {
    for (const panelName in this.panels) {
      if (panelName !== name) this.close(panelName)
    }
  }

  close(name: string) {
    const info = this.panels[name]
    if (!info) {
      console.warn('Failed to find closable panel: ', name)
      return
    }
    info.close.call(info.scope)
  }
}

export const closablePanels = new ClosablePanels()

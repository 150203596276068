import lodash from 'lodash'
import { useEffect, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'

// components were not reliably re-rendering when just using getFormDirtyFields directly. Moving this to a hook that runs on every change to formState makes this more reliable
// we'll just want to be sure we return the same array unless there's a change to the array otherwise this will cause unnecessary re-renders
const useGetDirtyFields = () => {
  const form = useForm()
  const formState = useFormState()
  const formDirtyFields = form.mutators.getFormDirtyFields()
  const [dirtyFields, setDirtyFields] = useState<string[]>([])
  const values = formState?.values
  const initialValues = formState?.initialValues

  useEffect(() => {
    if (lodash.isEqual(values, initialValues)) {
      setDirtyFields([])
      return
    }
    if (formDirtyFields !== dirtyFields) setDirtyFields(formDirtyFields)
  }, [formState?.values, formState?.initialValues])

  return dirtyFields
}
export default useGetDirtyFields

import { getRange } from 'util/misc'
export const STEPS = ['Designing', 'Selling', 'Installing', 'Maintaining']

export const EVENT_TYPE_CHOICES = [
  { id: 29, name: 'Activity (Miscellaneous)' },
  { id: 0, name: 'Email Invitation Sent' },
  // { id: 1, name: 'Customer Read Invitation Email' },
  // { id: 2, name: 'Customer Viewed Online Proposal' },
  // { id: 3, name: 'Customer Accepted Online Proposal' },
  // { id: 4, name: 'Customer Made a Payment' },
  // { id: 44, name: 'System Changed' },
  { id: 22, name: 'Call (inbound)' },
  { id: 20, name: 'Call (outbound)' },
  { id: 21, name: 'Call (outbound, no answer)' },
  { id: 24, name: 'Email (inbound)' },
  { id: 23, name: 'Email (outbound)' },
  { id: 40, name: 'On-site Installation' },
  { id: 41, name: 'On-site Maintenance Visit' },
  { id: 42, name: 'On-site Sales Visit' },
  // { id: 43, name: 'Task' },
  { id: 30, name: 'Busy' },
  // { id: 45, name: 'Project Assigned' },
]

export const TASK_STATUS_CHOICES = [
  { id: 0, name: 'Not Tracked as Task' },
  { id: 1, name: 'Incomplete' },
  { id: 2, name: 'Complete' },
]

const SECONDS_PER_HOUR = 3600
export const DURATION_CHOICES = [
  { id: 900, name: '15 Minutes' },
  { id: 1800, name: '30 Minutes' },
  { id: 2700, name: '45 Minutes' },
  { id: 3600, name: '1 Hour' },
].concat(
  getRange(22).map((i) => ({
    // increment in half-hour blocks
    id: SECONDS_PER_HOUR + i * (SECONDS_PER_HOUR / 2),
    name: Math.floor((60 + i * 30) / 60) + (i % 2 === 1 ? '.5' : '') + ' Hours',
  }))
)

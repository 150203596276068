// @ts-nocheck
import { makeStyles } from '@material-ui/core'
import ContentCreate from '@material-ui/icons/CreateOutlined'
import { authSelectors } from 'ducks/auth'
import { List } from 'elements/react-admin/List'
import ListActions from 'elements/react-admin/ListActions'
import moment from 'moment'
import { Chip } from 'opensolar-ui'
import React, { useEffect, useState } from 'react'
import { Button, Datagrid, EditButton, FunctionField, useNotify } from 'react-admin'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import restClient from 'restClient'
import { AccessRightsType } from 'types/global'
import { PLACEMENT_CHOICES } from './constants'

const restClientInstance = restClient(window.API_ROOT + '/api')

type ImportResponseType = {
  data: {
    success: boolean
    new_form_id: number | undefined
  }
}

type PropTypes = {
  accessRights: AccessRightsType
  isGlobal: boolean
  match: {
    isExact: boolean
    params: { orgId: string } | undefined
    path: string
    url: string
  }
}

const useStyles = makeStyles({
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  chip: {
    marginLeft: 10,
  },
})

const entityPromiseMap: Record<string, Promise> = {}

const API_URL = window.API_ROOT + '/api'
const EntityInfo: React.FC<{ url: string }> = ({ url }) => {
  const restClientInstance = restClient(API_URL)
  const [name, setName] = useState('')
  const [link, setLink] = useState('')

  useEffect(() => {
    if (!url) {
      setName('')
      return
    }
    let promise: Promise = entityPromiseMap[url]
    if (!promise) {
      promise = entityPromiseMap[url] = restClientInstance('CUSTOM_GET', 'custom', { url })
      promise.catch((e) => {
        console.error('Failed to load entity url: ', url, e)
      })
    }

    promise.then((response) => {
      setName(response.data.name)
      setLink(window.location.origin + '/#/global_orgs/' + response.data.id)
    })
  }, [url])

  return <a href={link}>{name}</a>
}

export const CustomFormList: React.FC<PropTypes> = (props) => {
  const isStaff = useSelector(authSelectors.getIsStaff)
  const orgId = useSelector(authSelectors.getOrgId)
  const history = useHistory()
  const notify = useNotify()

  useEffect(() => {
    moment.locale(window.locale)
  }, [])

  const classes = useStyles()

  const onImport = (formId: number) => {
    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${orgId}/custom_forms/${formId}/import/`,
    })
      .then((res: ImportResponseType) => {
        if (res.data.success && res.data.new_form_id) {
          history.push(`/custom_forms/${res.data.new_form_id}`)
        }
      })
      .catch((err) => {
        if (err?.body?.message) {
          notify(err.body.message, 'warning')
        } else {
          notify('Unable to import this public form', 'warning')
        }
      })
  }

  function getParentId(url: string) {
    const match = url.match(/\/(\d+)\/$/i)
    return match ? parseInt(match[1]) : null
  }

  return (
    <>
      <List
        {...props}
        alwayShowActions={true}
        hasCreate={isStaff}
        hasSearch={false}
        minQuantityToShowAsList={0}
        createButtonLabel="Add Form"
        actions={<ListActions hasArchived={true} />}
      >
        <Datagrid>
          <FunctionField
            source={null}
            label={'Title'}
            render={(record, source, rest) => (
              <div>
                {record.title}{' '}
                {record.parent_form || record.is_public ? (
                  <Chip className={classes.chip} label={translate('Standard Form')} size="small" />
                ) : (
                  ''
                )}
              </div>
            )}
            sortable={false}
          />
          <FunctionField
            source={null}
            label={'Form Placements'}
            render={(record, source, rest) => (
              <>
                {record.placements?.map((place) => (
                  <Chip
                    className={classes.chip}
                    label={
                      PLACEMENT_CHOICES?.find((choice) => choice.id === place.form_location)?.label ||
                      place.form_location
                    }
                    size="small"
                    variant="outlined"
                  />
                ))}
              </>
            )}
            sortable={false}
          />
          {isStaff && (
            <FunctionField
              source={null}
              label={'Org'}
              render={(record, source, rest) => <EntityInfo url={record.org} />}
              sortable={false}
            />
          )}
          <FunctionField
            source={null}
            label={''}
            render={(record, source, rest) => (
              <div className={classes.actionsWrapper}>
                {isStaff && record.parent_form && (
                  <EditButton
                    useListStyle={true}
                    record={{ id: getParentId(record.parent_form) }}
                    basePath={rest.basePath}
                    label="Edit Parent"
                    icon={false}
                    disabled={!isStaff}
                  />
                )}
                {record.is_public && (
                  <Button
                    label="Customise"
                    redirect={false}
                    submitOnEnter={false}
                    raised={true}
                    onClick={() => onImport(record.id)}
                    disabled={!record.is_public && !isStaff}
                  >
                    {record.is_public && <ContentCreate />}
                  </Button>
                )}
                {(isStaff || record.parent_form) && (
                  <EditButton useListStyle={true} record={record} basePath={rest.basePath} label={'Edit'} />
                )}
              </div>
            )}
            sortable={false}
          />
        </Datagrid>
      </List>
    </>
  )
}
export default CustomFormList

import { useCallback, useState } from 'react'
import { useDataProvider } from 'react-admin'

type ToggleForecastProjectOptions = { projectId: number; forecast: boolean }

export type SwitchProjectForecast = (options: ToggleForecastProjectOptions) => Promise<void>

type ToggleForecastProjectResponse = {
  switchingProjectForecast: boolean
  switchProjectForecast: SwitchProjectForecast
}

const useToggleForecastProject = (): ToggleForecastProjectResponse => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dataProvider = useDataProvider()
  const switchProjectForecast = useCallback(async ({ projectId, forecast }: ToggleForecastProjectOptions) => {
    setIsLoading(true)
    await dataProvider
      .CUSTOM_POST('inventory/projects', {
        url: 'inventory/projects/' + projectId + '/forecast/',
        data: { forecast },
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return {
    switchingProjectForecast: isLoading,
    switchProjectForecast,
  }
}

export default useToggleForecastProject

import { Paper } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { getPremiumProducts, premiumProductsSelectors } from 'ducks/premiumProducts'
import { walletSettingsSelectors } from 'ducks/wallet'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import StripePaymentElement from 'myenergy/dialogs/checkout/nativeSigning/payments/StripePaymentElement'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import WalletCreditCard from 'resources/walletTransactions/WalletCreditCard'
import { PremiumProductDetailType } from 'types/global'
import { WalletPaymentMethodType } from 'types/wallet'
import { currencySymbolForCurrencyCode, formatCurrencyNumber } from 'util/misc'
import AddFunds from './AddFunds'
import { TaxDetailsSection, useTaxDetailsStyles } from './TaxDetailsSection'
import TopUpSettings from './TopUpSettings'
import { StripeInfo, StripeInfoAndRefType } from './WalletHome'

export type FundsFormModeType = 'add_funds' | 'topup'
type PropTypes = {
  saveStripeInfo: (newStripeInfo: StripeInfoAndRefType) => void
  payment_stripe_key: string | null
  setErrorMsg: (msg: string | undefined) => void
  stripeInfo: StripeInfo
}

const EditWalletPage: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const stripeRef = useRef(null)
  const form = useForm()
  const history = useHistory()
  const dispatch = useDispatch()
  const taxDetailsClasses = useTaxDetailsStyles()

  const balance: number | undefined = useSelector(walletSettingsSelectors.getBalance)
  const walletCurrency: string | undefined = useSelector(walletSettingsSelectors.getCurrencyCode)
  const paymentMethods: WalletPaymentMethodType[] = useSelector(walletSettingsSelectors.getPaymentMethods)
  const wallet = useSelector(walletSettingsSelectors.getWallet)
  const products: PremiumProductDetailType[] = useSelector(premiumProductsSelectors.getProducts)

  const [modeToShow, setModeToShow] = useState<FundsFormModeType>('topup')
  const [showStripe, setShowStripe] = useState<boolean>(paymentMethods?.length === 0)
  const [initialPaymentMethodCount, setInitialPaymentMethodCount] = useState<number>(paymentMethods?.length)
  const [showActivateProductWarning, setShowActivateProductWarning] = useState<boolean>(false)

  // show stripe UI if user deletes last remaining payment method
  useEffect(() => {
    if (!showStripe && !paymentMethods?.length) {
      setShowStripe(true)
      setInitialPaymentMethodCount(0)
    } else if (showStripe && paymentMethods?.length > initialPaymentMethodCount) {
      setShowStripe(false)
    }
  }, [showStripe, paymentMethods, initialPaymentMethodCount])

  useEffect(() => {
    dispatch(getPremiumProducts())
  }, [])

  useEffect(() => {
    if (products && products.length > 0) {
      let activeProducts = products.filter((prod) => prod.is_active)
      if (activeProducts && activeProducts.length > 0) {
        setShowActivateProductWarning(false)
      } else {
        setShowActivateProductWarning(true)
      }
    }
  }, [products])

  const switchMode = (newMode: FundsFormModeType) => {
    setModeToShow(newMode)
    //reset fields that they are no longer going to see back to initial values to avoid accidentally saving unseen fields
    if (newMode === 'topup') {
      form.change('funds_to_add', undefined)
    }
    form.change('enable_auto_top_up', wallet?.enable_auto_top_up)
    form.change('top_up_amount', wallet?.top_up_amount)
    form.change('top_up_trigger_amount', wallet?.top_up_trigger_amount)
    props.setErrorMsg(undefined)
    logAmplitudeEvent('wallet_page_mode_changed', { new_mode: newMode })
  }

  const handleStripeChange = async (stripeInfo: StripeInfo) => {
    props.saveStripeInfo({ empty: false, ...stripeInfo, ref: stripeRef, showStripe: showStripe })
  }
  useEffect(() => {
    props.saveStripeInfo({ empty: true, ...props.stripeInfo, ref: stripeRef, showStripe: showStripe })
  }, [showStripe])

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <h1 style={{ fontSize: '20px', margin: '20px 0px', fontWeight: 500 }}>{translate('Your Wallet')}</h1>
        <ProUXButton
          id="wallet_activity"
          type="secondary"
          label="Wallet Activity"
          onClick={() => history.push('/wallet_transactions')}
        />
      </div>
      {showActivateProductWarning && (
        <div
          className={taxDetailsClasses.root}
          style={{
            textAlign: 'center',
            borderRadius: '5px',
            backgroundColor: '#fcf4c5',
            opacity: 0.7,
          }}
        >
          <p>
            {translate('Your org does not currently have any Wallet Products enabled.')}
            <br />
            <a href="/#/wallet_products">{translate('Please click here to enable products.')}</a>
          </p>
        </div>
      )}
      <Paper className={taxDetailsClasses.root}>
        <p>
          {translate(
            'Your Wallet can be used to access OpenSolar’s Premium content. High resolution aerial imagery is currently available, with more content and services to come. Your Wallet can be configured below.'
          )}
        </p>
        <div style={{ marginTop: '20px', fontSize: '16px' }}>{translate('Your account balance is')}:</div>
        <div id="your_account_balance" style={{ fontWeight: 900, fontSize: '16px', margin: '10px 0px 20px' }}>
          {walletCurrency ? currencySymbolForCurrencyCode(walletCurrency) : ''}
          {formatCurrencyNumber(balance || 0, 2)}
        </div>
      </Paper>
      <TaxDetailsSection />
      <Paper className={taxDetailsClasses.root}>
        {modeToShow === 'topup' ? (
          <TopUpSettings switchMode={() => switchMode('add_funds')} />
        ) : (
          <AddFunds switchMode={() => switchMode('topup')} />
        )}
      </Paper>
      <div>
        {props.payment_stripe_key && showStripe && (
          <Paper className={taxDetailsClasses.root}>
            <StripePaymentElement
              ref={stripeRef}
              payment_stripe_key={props.payment_stripe_key}
              onChange={handleStripeChange}
              showStripe={showStripe}
            />
          </Paper>
        )}
        {!showStripe && (
          <Paper className={taxDetailsClasses.root}>
            <h2>{translate('Payment details')}</h2>
            {paymentMethods.map((pmt) => (
              <WalletCreditCard paymentMethod={pmt} />
            ))}
          </Paper>
        )}
      </div>
    </div>
  )
}
export default EditWalletPage

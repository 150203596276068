import TourTooltip from 'elements/tooltip/TourTooltip'
import { TourConfigType } from 'persistentContent/tours/types'
import { useTranslate } from 'ra-core'
import { FC } from 'react'

const StepTooltip: FC = (props) => {
  const translate = useTranslate()
  return (
    <TourTooltip
      title={translate('Documents and Transactions')}
      subtext={translate(
        'Simply switch between tabs to manage project documentation and financial transactions with ease. Expand the documents section to unlock brand new document management functionality.'
      )}
      {...props}
    />
  )
}

const DocsAndTransactions: TourConfigType = {
  spotlights: [{ targetId: 'Project-Docs-Section', autoScroll: true }],
  tooltips: [
    {
      targetId: 'Project-Docs-Section',
      placement: 'bottom-start',
      component: StepTooltip,
      options: { accountScroll: true },
    },
  ],
}

export default DocsAndTransactions

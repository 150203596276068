import { TableCell, TableHead, TableRow, Tooltip, makeStyles } from '@material-ui/core'
import { Info } from '@material-ui/icons'
import { OpenSolarThemeType } from 'Themes'
import React from 'react'
import { useTranslate } from 'react-admin'

type PropTypes = {
  filter: string
}

const useStyles = makeStyles<OpenSolarThemeType>((theme) => ({
  row: {
    background: '#E7E7E7',
  },

  cell: {
    padding: '15px 10px',
    textAlign: 'left',
  },

  cellFlex: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },

  icon: {
    fontSize: 13,
    cursor: 'pointer',
  },

  sortIcon: {
    fontSize: 15,
  },

  header15: {
    width: '15%',
  },

  milestoneHeader: {
    width: '35%',
  },

  header10: {
    width: '10%',
  },
}))

const ProjectsTableHeader: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <TableHead className={classes.row}>
      <TableRow>
        <TableCell className={classes.cell + ' ' + classes.header15}>{translate('Project')}</TableCell>
        <TableCell className={classes.cell + ' ' + classes.header15}>{translate('Assigned Team Member')}</TableCell>
        <TableCell className={classes.cell + ' ' + classes.header10}>{translate('Type')}</TableCell>
        <TableCell className={classes.cell + ' ' + classes.milestoneHeader}>{translate('Milestone')}</TableCell>
        <TableCell className={classes.cell + ' ' + classes.header15}>
          <div className={classes.cellFlex}>
            <span>{translate('Amount')}</span>
            <Tooltip title={translate('This is the total amount collected to date.')}>
              <Info className={classes.icon} />
            </Tooltip>
          </div>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}
export default ProjectsTableHeader

import CircularProgress from '@material-ui/core/CircularProgress'
import { useViewShow } from 'ducks/viewMode'
import { Button as CoreButton, OsButtonProps } from 'opensolar-ui'
import React, { FC } from 'react'
import { useTranslate } from 'react-admin'

type LoadingCircularPropsType = {
  buttonSize?: 'small' | 'medium' | 'large'
}

const LoadingCircular: FC<LoadingCircularPropsType> = ({ buttonSize = 'medium' }) => {
  const buttonSizeToCircularSize = {
    small: 16,
    medium: 20,
    large: 24,
  }
  return <CircularProgress color={'inherit'} size={buttonSizeToCircularSize[buttonSize]} />
}

export const sanitizeButtonRestProps = ({ label, ...rest }: any) => rest
type CustomPropsType = {
  label?: string | React.ReactElement<any>
  loading?: Boolean
  uiKey?: string
}

const Button: React.FunctionComponent<OsButtonProps & CustomPropsType> = ({
  children,
  variant = 'contained',
  label,
  startIcon,
  loading,
  uiKey,
  ...props
}) => {
  const show = useViewShow(uiKey)
  const translate = useTranslate()

  const finalLabel = children || label
  return !show ? (
    <></>
  ) : (
    <CoreButton
      variant={variant}
      startIcon={loading ? <LoadingCircular buttonSize={props.size} /> : startIcon}
      {...props}
    >
      <span className={props.classes?.text}>
        {typeof finalLabel === 'string'
          ? props.translate !== 'no'
            ? translate(finalLabel)
            : finalLabel
          : React.isValidElement(finalLabel) && React.cloneElement(finalLabel)}
      </span>
    </CoreButton>
  )
}
export default Button

import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { initializeSplit, SPLIT_NAMES } from 'ducks/split'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFeatureFlagDynamic } from 'util/split'

/**
 * Inits Split.io integration and adds window level utility
 * function for studio to access enabled features
 */
const SplitInit: React.FC = () => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false)

  const dispatch = useDispatch()
  const isFeatureEnabledHook = useFeatureFlagDynamic()

  const orgId = useSelector(orgSelectors.getOrg)?.id
  const isStaff = useSelector(authSelectors.getIsStaff)
  const isAdmin = useSelector(authSelectors?.getIsAdmin)

  window.isFeatureEnabled = (split_name: SPLIT_NAMES, target_value?: string): boolean | undefined => {
    return isFeatureEnabledHook(split_name, target_value)
  }

  useEffect(() => {
    // don't re-run this on dependency change, the args here just assign a user id for Split when the client is initialized they have no impact on the feature flag itself
    if (orgId && !isInitialized) {
      // don't run this if org id is not set. This is to ensure customers don't cause callouts that will run up our total user count with Split
      setIsInitialized(true)
      dispatch(initializeSplit(orgId, isStaff, isAdmin))
    }
  }, [orgId])

  return <></>
}

export default SplitInit

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import createStandardIcon from './createStandardIcon';
var CheckIconSvg = function (props) {
    return (_jsx("svg", __assign({ viewBox: "0 0 8 8", xmlns: "http://www.w3.org/2000/svg" }, props, { children: _jsx("path", { id: "Vector", d: "M1.60736 3.90031C2.09581 4.16253 2.41402 4.38039 2.79287 4.7691C3.77528 3.29661 4.96059 1.65124 6.34721 0.493124L6.48283 0.444458H8C5.96571 2.54837 4.37686 5.0188 2.85682 7.55557C1.94321 6.12188 1.32841 5.19888 0 4.2385L1.60736 3.90031Z", fill: props.fill || 'white' }) })));
};
export var CheckIcon = createStandardIcon(CheckIconSvg);

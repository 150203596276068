import { logAmplitudeEvent } from 'amplitude/amplitude'
import DividendApplicationDialog from 'myenergy/selectionComponent/loanApplicationButton/dividend/DividendApplicationDialog'
import ApplicationIframeDialog from 'myenergy/selectionComponent/loanApplicationButton/generic/ApplicationIframeDialog'
import FormWrappedDialog from 'myenergy/selectionComponent/loanApplicationButton/generic/hostedForms/HostedCreditApplicationDialog'
import { useInitialValuesByIntegration } from 'myenergy/selectionComponent/loanApplicationButton/generic/hostedForms/initialValueSetters'
import { doFormRedirect } from 'myenergy/selectionComponent/loanApplicationButton/generic/utils'
import LoanpalApplicationDialog from 'myenergy/selectionComponent/loanApplicationButton/loanpal/LoanpalApplicationDialog'
import MosaicApplicationDialog from 'myenergy/selectionComponent/loanApplicationButton/mosaic/MosaicApplicationDialog'
import SungageApplicationDialog from 'myenergy/selectionComponent/loanApplicationButton/sungage/SungageApplicationDialog'
import React, { useEffect } from 'react'
import { useFormState } from 'react-final-form'
import { ProjectType } from 'types/projects'
import { HostedAppConfig } from '.'

type PropTypes = {
  integration: string | undefined | null
  onClose: () => void
  paymentOptionId: number
  systemUuid: string | null
  orgId: number
  hostedAppConfig: HostedAppConfig
  paymentOptionTitle: string
}

const LEGACY_IFRAME_INTEGRATIONS = ['sunlight', 'brighte']

const LaunchApplicationDialog: React.FC<PropTypes> = (props) => {
  const project = useFormState().values as ProjectType

  const onClose = () => {
    props.onClose()
  }

  const markOptionAsSoldIfApproved = () => {
    logAmplitudeEvent('integrated_finance_application_submitted', {
      integration: props.integration,
      project_id: project?.id,
      system_uuid: props.systemUuid,
      payment_option_id: props.paymentOptionId,
      placement: 'payments page',
    })
  }

  useEffect(() => {
    if (props.hostedAppConfig.generic?.url && props.hostedAppConfig.generic?.form_request_body) {
      doFormRedirect(props.hostedAppConfig.generic.url, props.hostedAppConfig.generic?.form_request_body)
      props.onClose()
    }
  }, [props.hostedAppConfig])

  const initialFormValues = useInitialValuesByIntegration(props.integration, project)

  if (!props.orgId) return null
  if (!props.systemUuid || !props.integration) {
    return null
  }
  if (props.integration === 'sungage' && props.hostedAppConfig.sungage?.disclosures) {
    return (
      <SungageApplicationDialog
        open={true}
        onClose={onClose}
        disclosures={props.hostedAppConfig.sungage?.disclosures}
        project={project}
        paymentOptionId={props.paymentOptionId}
        systemUuid={props.systemUuid}
        orgId={props.orgId}
        onDecisionRendered={markOptionAsSoldIfApproved}
        isFullWidth={false}
      />
    )
  } else if (props.integration === 'mosaic' && props.hostedAppConfig.mosaic) {
    return (
      <MosaicApplicationDialog
        open={true}
        onClose={onClose}
        disclosures={props.hostedAppConfig.mosaic.disclosures}
        project={project}
        paymentOptionId={props.paymentOptionId}
        systemUuid={props.systemUuid}
        orgId={props.orgId}
        onDecisionRendered={markOptionAsSoldIfApproved}
        isFullWidth={false}
        bundleId={props.hostedAppConfig.mosaic.bundleId}
        productType={props.hostedAppConfig.mosaic.productType}
      />
    )
  } else if (props.integration === 'loanpal' && props.hostedAppConfig.loanpal?.disclosures) {
    return (
      <LoanpalApplicationDialog
        open={true}
        onClose={onClose}
        disclosures={props.hostedAppConfig.loanpal?.disclosures}
        project={project}
        paymentOptionId={props.paymentOptionId}
        systemUuid={props.systemUuid}
        orgId={props.orgId}
        onDecisionRendered={markOptionAsSoldIfApproved}
        isFullWidth={false}
      />
    )
  } else if (props.integration === 'dividend' && props.hostedAppConfig.dividend?.disclosures) {
    return (
      <DividendApplicationDialog
        open={true}
        onClose={onClose}
        disclosureText={props.hostedAppConfig.dividend?.disclosures}
        project={project}
        paymentOptionId={props.paymentOptionId}
        systemUuid={props.systemUuid}
        orgId={props.orgId}
        onDecisionRendered={markOptionAsSoldIfApproved}
        isFullWidth={false}
      />
    )
  } else if (LEGACY_IFRAME_INTEGRATIONS.includes(props.integration) && props.hostedAppConfig[props.integration]?.url) {
    return (
      <ApplicationIframeDialog
        url={props.hostedAppConfig[props.integration].url}
        contents={undefined}
        isOpen={true}
        onClose={onClose}
        paymentOptionTitle={props.paymentOptionTitle}
      />
    )
  } else if (props.hostedAppConfig?.generic?.url) {
    return (
      <ApplicationIframeDialog
        url={props.hostedAppConfig?.generic.url}
        contents={undefined}
        isOpen={true}
        onClose={onClose}
        paymentOptionTitle={props.paymentOptionTitle}
      />
    )
  } else if (props.hostedAppConfig?.generic?.disclosures) {
    return (
      <FormWrappedDialog
        onClose={onClose}
        project={project}
        projectId={project.id}
        paymentOptionId={props.paymentOptionId}
        systemUuid={props.systemUuid}
        orgId={props.orgId}
        integration={props.integration}
        isLoading={false}
        setIsLoading={() => null}
        disclosures={props.hostedAppConfig.generic.disclosures}
        initialValues={initialFormValues}
        form_json={props.hostedAppConfig?.generic?.form_json}
      />
    )
  }
  return null
}
export default LaunchApplicationDialog

import { InputAdornment, TextField } from '@material-ui/core'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import { orgSelectors } from 'ducks/orgs'
import { IconButton } from 'opensolar-ui'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { currencySymbolForCountry, formatCurrency } from 'util/misc'
import { BasicInsight, TableColumn } from '../type'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  unitCostField: {
    minWidth: 150,
    maxWidth: 150,
  },
}))

type UnitCostFieldProps = {
  row: BasicInsight
  column: TableColumn
  action: (params: { code: string; field: string; value: string }) => void
  isTouched: boolean
}

const UnitCostField = ({ row, column, action, isTouched }: UnitCostFieldProps) => {
  const classes = useStyles()
  const countryIso2 = useSelector(orgSelectors.getOrgIso2)
  const currencySymbol = useMemo(() => currencySymbolForCountry(countryIso2), [countryIso2])
  const [fieldState, setFieldState] = useState({ editing: null })

  useEffect(() => {
    if (!isTouched) {
      setFieldState({ editing: null })
    }
  }, [isTouched])

  const toggleEdit = (rowId) => {
    setFieldState((prev) => ({
      ...prev,
      editing: prev.editing === rowId ? null : rowId,
    }))
  }

  if (row.pricing_source === 'overridden') {
    return fieldState.editing === row.code ? (
      <TextField
        value={row[column.id]}
        onChange={(event) => {
          if (column.onChange) {
            column.onChange(row.code, event.target.value)
          }
        }}
        onBlur={(event) => {
          action({ code: row.code, field: 'unit_cost', value: event.target.value })
          setFieldState({ editing: null })
        }}
        InputProps={{
          startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
        }}
      />
    ) : (
      <div className={classes.unitCostField}>
        <span>
          {currencySymbol} {formatCurrency(parseFloat(row[column.id]))}
        </span>
        <IconButton size="small" onClick={() => toggleEdit(row.code)}>
          <EditOutlinedIcon />
        </IconButton>
      </div>
    )
  }

  return (
    <div>
      {currencySymbol} {formatCurrency(parseFloat(row[column.id]))}
    </div>
  )
}

export default UnitCostField

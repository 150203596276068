// in src/users.js
import { Theme, makeStyles } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import PaginationWithCsvActions from 'elements/PaginationWithCsvActions'
import TextFieldWithSharedEntityIcon from 'elements/TextFieldWithSharedEntityIcon'
import EditOrImportButton from 'elements/button/EditOrImportButton'
import { useBulkActionButtons } from 'elements/hooks/useBulkActionButtons'
import { useEditShareable } from 'elements/react-admin/EditShareable'
import { List } from 'elements/react-admin/List'
import ListActions from 'elements/react-admin/ListActions'
import { BooleanField, Create, Datagrid, FunctionField, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import ShareabilityFilters from 'resources/connectedOrgs/ShareabilityFilters'
import AddersForm, { ADDER_TYPE_CHOICES, PRICE_ONLY_ADDER_FORMULA_CHOICES } from './AddersForm'

const useStyles = makeStyles((theme: Theme) => ({
  description: {
    lineHeight: '1.5',
    color: '#626262',
  },
}))

export const AddersList = ({ accessRights: { allowView, allowCreate, allowEdit, allowDelete }, ...props }: any) => {
  const classes = useStyles()
  const translate = useTranslate()
  const isAdmin = useSelector(authSelectors.getIsAdmin)
  const orgId = useSelector(authSelectors.getOrgId)
  const bulkActionButtons = useBulkActionButtons(props, allowEdit, allowDelete, undefined)

  return (
    <List
      hasSearch={true}
      actions={<ListActions hasArchived={true} createButtonLabel="Adder" />}
      perPage={20}
      filters={<ShareabilityFilters {...props} />}
      {...props}
      pagination={
        <PaginationWithCsvActions
          csvUploadDialogTitle="Adders Library File Upload"
          csvUploadDialogDescription={
            <div className={classes.description}>
              <p>
                {translate(
                  'Upload a formatted CSV file to quickly make updates to your adders library. Best practice is to download a CSV file of your adders library, make changes and then upload the updated file.'
                )}
              </p>
              <p>
                {translate(
                  'Note that if you want to add new records, simply leave the ID column blank. You cannot remove existing records via .csv file upload - you must delete them in the table above.'
                )}
              </p>
            </div>
          }
        />
      }
      hasCreate={allowCreate}
      bulkActionButtons={bulkActionButtons}
    >
      <Datagrid>
        <TextFieldWithSharedEntityIcon source="title" label="Title" />
        <FunctionField
          label="Type"
          source="type"
          render={(record) => {
            const type = record['type']
            const config = ADDER_TYPE_CHOICES.find((a) => a.id === type)
            return config?.name
          }}
        />
        <FunctionField
          label="Formula"
          source="formula"
          render={(record) => {
            const type = record['formula']
            const config = PRICE_ONLY_ADDER_FORMULA_CHOICES.find((a) => a.id === type)
            return config?.name
          }}
        />
        <BooleanField source="auto_apply_enabled" label="Auto-apply" />
        <EditOrImportButton
          source="actions"
          label="Actions"
          org_id={orgId}
          resource="adders"
          allowDuplicate={false}
          sortable={false}
          isAdmin={isAdmin}
          allowEdit={allowEdit}
          allowDelete={allowDelete}
        />
      </Datagrid>
    </List>
  )
}

export const AddersEdit = (props: any) => {
  return useEditShareable({
    accessRights: props.accessRights,
    editProps: props,
    render: ({ access }) => <AddersForm disabled={!access.allowEdit} />,
  }).editPage
}

export const AddersCreate = (props: any) => {
  return (
    <Create {...props}>
      <AddersForm />
    </Create>
  )
}

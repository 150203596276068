import { styled, TextField } from 'opensolar-ui'
import { ViewMode } from '../../FilesPage'

export const MainToolbarContainer = styled('div')(({ theme }) => ({
  gap: 20,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: 10,
  width: '100%',
  flexWrap: 'wrap-reverse',
}))

export const ListActionsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  gap: 100,
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  rowGap: 20,
}))

export const SearchAndFilterContainer = styled('div')(({ theme }) => ({
  minHeight: 40,
  flex: 4,
  display: 'flex',
  flexDirection: 'row',
  gap: 10,
  '& > button': {
    '& svg': {
      marginRight: 6,
    },
  },
}))

export const SearchInputStyled = styled(TextField)(({ theme }) => ({
  minWidth: 250,
  width: 250,
  display: 'flex',
  flex: 1,
  backgroundColor: 'white',
  borderRadius: 8,
  '& .OSUI-InputBase-root': {
    height: '100%',
  },
}))

export const ButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  gap: 10,
  justifyContent: 'flex-end',
  flexWrap: 'wrap',
  minWidth: 'fit-content',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

export const ActionBtn = styled('div')(({ theme }) => ({
  flex: 1,
  '&>button': {
    padding: '10px 12px !important',
    fontSize: 14,
    margin: 0,
    whiteSpace: 'nowrap',
    minWidth: 'fit-content',
    width: '100%',
    flex: 1,
    '& svg': {
      marginRight: 6,
    },
  },
}))

export const ToggleContainer = styled('div')(({ theme }) => ({
  width: 'fit-contents',
  display: 'flex',
  minHeight: 40,
  '& svg': {
    marginRight: 5,
  },
  '&>div': {
    flex: 1,
    height: '100%',
  },
  '& button': {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

export interface MainToolBarProps {
  viewMode: ViewMode
  setViewMode: (v: ViewMode) => void
  actionsDisabled: boolean
}

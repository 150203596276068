import React from 'react'
import PropTypes from 'prop-types'
import { addField, FieldTitle } from 'react-admin'
import TextField from '@material-ui/core/TextField'
import * as ReactColor from 'react-color'
import _ from 'lodash'
import pure from 'recompose/pure'

const ColorFieldComponent = ({ source, record = {}, className }) => (
  <div style={{ display: 'flex' }}>
    <div
      style={{
        width: '20px',
        height: '20px',
        background: _.get(record, source),
        marginRight: '5px',
      }}
    />
    <span className={className}>{_.get(record, source)}</span>
  </div>
)

ColorFieldComponent.propTypes = {
  addLabel: PropTypes.bool,
  className: PropTypes.string,
  elStyle: PropTypes.object,
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}

const PureTextField = pure(ColorFieldComponent)

PureTextField.defaultProps = {
  addLabel: true,
}

class ColorInputComponent extends React.Component {
  state = {
    show: false,
  }

  handleOpen = () => this.setState({ show: true })
  handleClose = () => this.setState({ show: false })
  handleChange = ({ hex }) => {
    this.props.input.onChange(hex)
    this.forceUpdate()
  }

  render() {
    const { label, source, meta, className, options, picker, input, resource, helperText, isRequired,validate } = this.props

    const { touched, error } = meta

    const Picker = ReactColor[`${picker}Picker`]

    return (
      <div>
        <TextField
          {...input}
          validate={validate}
          margin="dense"
          style={{ width: '100%' }}
          onFocus={this.handleOpen}
          label={<FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />}
          error={!!(touched && error)}
          helperText={(touched && error) || helperText}
          className={className}
        />
        {this.state.show ? (
          <div
            className="ColorInput-popup"
            style={{
              position: 'absolute',
              zIndex: 2,
            }}
          >
            <div
              style={{
                position: 'fixed',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
              }}
              onClick={this.handleClose}
            />
            <Picker {...options} color={input.value} onChange={this.handleChange} />
          </div>
        ) : null}
      </div>
    )
  }
}

ColorInputComponent.propTypes = {
  label: PropTypes.string,
  options: PropTypes.object,
  source: PropTypes.string,
  input: PropTypes.object,
  helperText: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  className: PropTypes.string,
  picker: (props, propName, componentName) =>
    !ReactColor[`${props[propName]}Picker`] &&
    new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`.`),
}

ColorInputComponent.defaultProps = {
  picker: 'Chrome',
  options: {
    disableAlpha: true,
  },
}

export const ColorField = PureTextField
export const ColorInput = addField(ColorInputComponent)

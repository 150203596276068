import { useLayoutEffect, useState } from 'react'
import type { AccountDetailType } from 'services/segen/type'
import useSegenService from 'services/segen/useSegenServiceV2'

const useSegenAccountDetail = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [segenAccount, setSegenAccount] = useState<AccountDetailType | undefined>()
  const segenService = useSegenService()
  useLayoutEffect(() => {
    setIsLoading(true)
    const init = async () => {
      const accountDetail = await segenService.getAccountDetail()
      setSegenAccount(accountDetail)
      setIsLoading(false)
    }
    init()
  }, [])
  return { isLoading, segenAccount }
}

export default useSegenAccountDetail

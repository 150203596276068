import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import useAdBanner from 'elements/ads/useAdBanner'
import LoadingDots from 'layout/widgets/LoadingDots'
import { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import useDistributorAuthenticationStatus from 'services/useDistributorAuthenticationStatus'
import { HardwareSupplierFilterKeyType } from '../type'
import CityPlumbingConnectBanner from './banners/CityPlumbingConnectBanner'
import HdmConnectBanner from './banners/HdmConnectBanner'
import OrderByProjectBanner from './banners/OrderByProjectBanner'
import SegenConnectBanner from './banners/SegenConnectBanner'
import VtacBanner from './banners/VtacBanner'
import HardwareAdBannerContainer from './HardwareAdBannerContainer'

type TopBannerProps = {
  adBannerPlacement: string
  showOBPBanner?: boolean
}

const TopBanner: React.FC<TopBannerProps> = ({ adBannerPlacement, showOBPBanner = true }) => {
  const adBanner = useAdBanner({
    formats: ['leaderboard'],
    placement: adBannerPlacement,
    requiredWindowSize: { width: 960, height: 800 },
  })
  const orgId = useSelector(authSelectors.getOrgId) || 0
  const distributor = useSelector(orgSelectors.getSelectedHardwareSupplierFilterKey)
  const { isAuthenticated, isChecking } = useDistributorAuthenticationStatus({
    orgId,
    distributor: distributor as HardwareSupplierFilterKeyType,
  })

  const connectBanner = useMemo(() => {
    if (distributor === 'city_plumbing') {
      return <CityPlumbingConnectBanner />
    }
    if (distributor === 'segen') {
      return <SegenConnectBanner />
    }
    if (distributor === 'hdm') {
      return <HdmConnectBanner />
    }
    if (distributor === 'vtac_uk' || distributor === 'vtac_pl') {
      return <VtacBanner distributor={distributor} />
    }
    return null
  }, [distributor])

  if (isChecking) {
    return <LoadingDots style={{ padding: '32px' }} />
  }

  if (!isAuthenticated && connectBanner) {
    return connectBanner
  } else {
    if (adBanner != null) {
      return <HardwareAdBannerContainer> {adBanner} </HardwareAdBannerContainer>
    } else if (showOBPBanner) {
      return <OrderByProjectBanner />
    }
  }
  return null
}

export default memo(TopBanner)

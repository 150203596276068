import _ from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'

const useFindElements = (
  selectors: string[],
  keepObserve = false,
  maxWait: number = 5000,
  elementToObserve?: HTMLElement
) => {
  const [foundElementId, setFoundElementId] = useState('')
  const observerRef = useRef<any>(null)

  const removeObserver = useCallback(() => {
    if (observerRef.current) {
      console.log('clear MutationObserver')
      observerRef.current.disconnect()
      observerRef.current = null
    }
  }, [])

  const findLastVisibleElement = useCallback(() => {
    let lastVisibleId = ''
    selectors?.forEach((id) => {
      if (document.getElementById(id)) {
        lastVisibleId = id
      }
    })
    return lastVisibleId
  }, [])

  const handleResize = useCallback(() => {
    const visibleElementId = findLastVisibleElement()
    setFoundElementId(visibleElementId)
  }, [])

  const handleElementFound = useCallback((id) => {
    setFoundElementId(id)
    if (keepObserve) {
      // keep listening
    } else {
      removeObserver()
    }
  }, [])

  const handleChange = useCallback(() => {
    console.log('MutationObserver change')
    const elementId = findLastVisibleElement()
    handleElementFound(elementId)
  }, [])

  useEffect(() => {
    const handleResizeDebounced = _.debounce(handleResize, 200)
    const handleChangeDebounced = _.debounce(handleChange, 300)
    const foundId = findLastVisibleElement()
    if (foundId) {
      handleElementFound(foundId)
    }

    if (!foundId || keepObserve) {
      console.log('new MutationObserver:', selectors)
      observerRef.current = new MutationObserver(handleChangeDebounced)
      observerRef.current.observe(elementToObserve || document, { childList: true, subtree: true })
    }

    if (!keepObserve) {
      //do not remove observer if we want to keep observing element change
      setTimeout(removeObserver, maxWait)
    }
    window.addEventListener('resize', handleResizeDebounced)

    return () => {
      removeObserver()
      window.removeEventListener('resize', handleResizeDebounced)
    }
  }, [])
  return foundElementId
}

export default useFindElements

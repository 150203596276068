import { authSelectors } from 'ducks/auth'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export type LoginStep = 'login' | 'mfa-challenge' | 'mfa-wrapper'

const useLoginStep = (): LoginStep => {
  const mfaCheckRequired: boolean = useSelector(authSelectors.getMfaCheckRequired)
  const mfaConfigRequired: boolean = useSelector(authSelectors.getMfaConfigRequired)
  const mfaCodesShowing: boolean = useSelector(authSelectors.getMfaCodesShowing)
  const showMfaWrapper = mfaConfigRequired || mfaCodesShowing

  const step = useMemo(() => {
    if (mfaCheckRequired) {
      return 'mfa-challenge'
    }
    if (showMfaWrapper) {
      return 'mfa-wrapper'
    }
    return 'login'
  }, [mfaCheckRequired, showMfaWrapper])

  return step
}

export default useLoginStep

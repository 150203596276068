import { CustomFormTypeEnum } from './types'

export const FORM_TYPE_CHOICES = [
  { id: 'project', label: 'Project' },
  { id: 'system_project', label: 'System' },
  { id: 'role', label: 'Team Member' },
  { id: 'contact', label: 'Contact' },
  { id: 'payment_option', label: 'Payment Option' },
]

export const PLACEMENT_CHOICES: PlacementChoice[] = [
  { id: 'info_page', label: 'Info Page', types: [CustomFormTypeEnum.project], panel: true },
  { id: 'energy_page', label: 'Energy Page', types: [CustomFormTypeEnum.project], panel: true },
  { id: 'manage_page', label: 'Manage Page', types: [CustomFormTypeEnum.project], panel: true },
  {
    id: 'before_document_generation',
    label: 'Before Document Generation',
    types: [CustomFormTypeEnum.project, CustomFormTypeEnum.system_project],
    doc: true,
  },
  {
    id: 'before_contract',
    label: 'Before Contract Signing',
    types: [CustomFormTypeEnum.project, CustomFormTypeEnum.system_project],
    contract: true,
  },
  {
    id: 'designpanel_mounting_button',
    label: 'Design Mounting Tab',
    types: [CustomFormTypeEnum.project, CustomFormTypeEnum.system_project],
  },
  { id: 'role_form', label: 'Team Member Form', types: [CustomFormTypeEnum.role], form: true, panel: true },
  { id: 'contact_form', label: 'Contact Form', types: [CustomFormTypeEnum.contact], form: true, panel: true },
  {
    id: 'info_page_contact',
    label: 'Project Info - Contact',
    types: [CustomFormTypeEnum.contact],
    form: true,
    panel: true,
  },
  {
    id: 'payment_option_form',
    label: 'Payment Option Form - All',
    types: [CustomFormTypeEnum.payment_option],
    form: true,
    panel: true,
  },
  {
    id: 'payment_option_form_cash',
    label: 'Payment Option Form - Cash',
    types: [CustomFormTypeEnum.payment_option],
    form: true,
    panel: true,
  },
  {
    id: 'payment_option_form_loan',
    label: 'Payment Option Form - Loan',
    types: [CustomFormTypeEnum.payment_option],
    form: true,
    panel: true,
  },
  {
    id: 'payment_option_form_ppa',
    label: 'Payment Option Form - PPA',
    types: [CustomFormTypeEnum.payment_option],
    form: true,
    panel: true,
  },
  {
    id: 'payment_option_form_regular',
    label: 'Payment Option Form - Regular',
    types: [CustomFormTypeEnum.payment_option],
    form: true,
    panel: true,
  },
  {
    id: 'payment_option_form_lease',
    label: 'Payment Option Form - Lease',
    types: [CustomFormTypeEnum.payment_option],
    form: true,
    panel: true,
  },
]

type PlacementChoice = {
  id: string
  label: string
  types: CustomFormTypeEnum[]

  // These determine the UI controls which show up for this placement type
  form?: boolean
  panel?: boolean
  doc?: boolean
  contract?: boolean
}

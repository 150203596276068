import { makeStyles, withStyles } from '@material-ui/core'
import React from 'react'
import { useTranslate } from 'react-admin'

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    minHeight: 150,
  },
  description: {
    fontSize: 12,
  },
  warning: {
    fontSize: 12,
    color: '#FF0000',
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
  },
}))

const StyledList = withStyles((theme) => ({
  root: {
    listStyleType: 'none',
    padding: 0,
  },
}))(({ classes, ...restProps }: any) => <ul className={classes.root} {...restProps} />)

const StyledListItem = withStyles((theme) => ({
  root: {
    fontSize: 12,
    '&::before': {
      content: '"✓"',
      display: 'inline',
      color: '#0FB700',
      margin: '0 5px',
    },
  },
}))(({ classes, ...restProps }: any) => <li className={classes.root} {...restProps} />)

export const DesignMode2D = () => {
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>
        <span>{translate('Design on Imagery (2D)')}</span>
      </div>
      <p className={classes.description}>
        {translate(
          'Paint panels onto top-down and oblique imagery. Ideal if you prefer to estimate pitch and shading.'
        )}
      </p>
      <StyledList>
        <StyledListItem>{translate('Auto azimuth and scale detection')}</StyledListItem>
        <StyledListItem>{translate('No need to draw roof facets')}</StyledListItem>
      </StyledList>
    </div>
  )
}

export const DesignMode3D: React.FC<{ warning?: string }> = ({ warning }) => {
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>
        <span>{translate('Automated 3D Model')}</span>
      </div>
      {warning && <p className={classes.warning}>{warning}</p>}
      <p className={classes.description}>
        {translate(
          'The most accurate design tool on the market today. OpenSolar 3D uses artificial intelligence to create a 3D model of your site. All you need to do is add the panels.'
        )}
      </p>
      <StyledList>
        <StyledListItem>{translate('Auto shade calculation')}</StyledListItem>
        <StyledListItem>{translate('Auto pitch detection')}</StyledListItem>
        <StyledListItem>{translate('Auto azimuth and scale determination')}</StyledListItem>
        <StyledListItem>{translate('No need to draw roof facets')}</StyledListItem>
      </StyledList>
    </div>
  )
}

export const DesignModeManual = () => {
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>
        <span>{translate('Manual 3D Model')}</span>
      </div>
      <p className={classes.description}>
        {translate(
          'Build your own 3D model using aerial imagery. Manually model each roof facet and obstructions like trees.'
        )}
      </p>
      <StyledList>
        <StyledListItem>
          {translate('Great for new construction, or other sites where standard imagery is unavailable.')}
        </StyledListItem>
      </StyledList>
    </div>
  )
}

import appStorage from 'storage/appStorage'

const RECORDED_ACTION_LOCAL_STORAGE_KEY = 'cogs_notification_recorded_action'

const MAX_RECORDS_STORED = 50

type ActionType = 'not_show_again'

type NotificationInteractionEntity = {
  userId: number
  projectId: number
  createdAt: number
  action: ActionType
}

const createNewRecord = ({
  userId,
  projectId,
  action,
}: {
  userId: number
  projectId: number
  action: ActionType
}): NotificationInteractionEntity => {
  return {
    userId,
    projectId,
    action,
    createdAt: Date.now(),
  }
}

const addNewRecord = (record: NotificationInteractionEntity) => {
  const existingRecordArray =
    appStorage.getJSON<NotificationInteractionEntity[]>(RECORDED_ACTION_LOCAL_STORAGE_KEY) || []
  existingRecordArray.push(record)
  appStorage.setJSON(RECORDED_ACTION_LOCAL_STORAGE_KEY, existingRecordArray.slice(-MAX_RECORDS_STORED))
}

export const recordDoNotShowAgain = ({ userId, projectId }: { userId: number; projectId: number }) => {
  const action = createNewRecord({ userId, projectId, action: 'not_show_again' })
  addNewRecord(action)
}

export const getDoNotShowAgainRecord = ({
  userId,
  projectId,
}: {
  userId: number
  projectId: number
}): NotificationInteractionEntity | undefined => {
  if (userId) {
    const existingRecordArray =
      appStorage.getJSON<NotificationInteractionEntity[]>(RECORDED_ACTION_LOCAL_STORAGE_KEY) || []
    return existingRecordArray.find(
      (record) => record.userId === userId && record.projectId === projectId && record.action === 'not_show_again'
    )
  }
  return undefined
}

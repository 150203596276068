import React from 'react'
import { Edit, SimpleForm } from 'react-admin'
import { AccessRightsType } from 'types/global'
import CustomFormCRUDFormContent from './CustomFormCRUD'
import { CustomFormRecordType } from './types'

type PropTypes = {
  accessRights: AccessRightsType
  record: CustomFormRecordType
}

const Title = (props) => {
  const record = props.record
  return <h1>{record ? record.title : 'New Custom Form'}</h1>
}

export const CustomFormEdit: React.FC<PropTypes> = (props) => {
  return (
    <div>
      <Edit resource="custom_forms" {...props} title={<Title />}>
        <SimpleForm>
          <CustomFormCRUDFormContent />
        </SimpleForm>
      </Edit>
    </div>
  )
}
export default CustomFormEdit

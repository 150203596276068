import { logAmplitudeEvent } from 'amplitude/amplitude'
import { useCallback } from 'react'
import { StudioSystemType } from 'types/global'
import { MountingID, NativeMountingID } from 'types/mounting'
import { nativeMountingIDs } from '../nativeMounting/constants'
import { mountingSystems } from './MountingSystemCapsule'

const useApplyMountingSystem = () => {
  const handleApplyMountingSystem = useCallback(
    ({ mountingId, system }: { mountingId: MountingID | undefined; system: StudioSystemType }): void => {
      system.mounting = mountingId
      var integration_json = system.integration_json || {}

      mountingSystems.forEach((item) => {
        var mountingSystem = item.id
        if (mountingSystem === mountingId) {
          if (!integration_json[mountingId]) {
            integration_json[mountingId] = {}
          }
          // Do not auto-clear, we may keep old settings even after we switch to something else
          // }else{
          //   if(system.integration_json[mountingSystem]){
          //     delete system.integration_json[mountingSystem]
          //   }
        }
      })

      let logViridianSubmitted = false
      if (mountingId === 'viridian') {
        const newIntegration = system?.integration_json?.viridian?.enabled === undefined
        if (newIntegration) {
          // TODO: This doesn't seems wrong to me, can we either update event name or track it at right place
          logAmplitudeEvent('integrated_racking_dialog_opened', { integration: 'Viridian' })
          logViridianSubmitted = true
        }
      }

      var commandUUID = window.Utils.generateCommandUUIDOrUseGlobal()
      window.editor.execute(new window.SetValueCommand(system, 'mounting', mountingId, commandUUID))
      window.editor.execute(new window.SetValueCommand(system, 'integration_json', integration_json, commandUUID))

      if (logViridianSubmitted) {
        logAmplitudeEvent('integrated_racking_dialog_submitted', { integration: 'Viridian' })
      }

      if (mountingId) {
        const hasNoIntegration = system?.integration_json?.[mountingId]?.enabled === undefined
        if (nativeMountingIDs.includes(mountingId as NativeMountingID) && hasNoIntegration) {
          logAmplitudeEvent('selected_native_mounting_system', { integration: mountingId })
        }
      }

      window.editor.signals.objectChanged.dispatch(system, 'mounting')
    },
    []
  )

  return handleApplyMountingSystem
}

export default useApplyMountingSystem

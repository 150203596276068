import { Dialog, Tooltip } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import { IconButton } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import { populate } from '../mainContent/populate'

export const SystemDetailDialog = (props) => {
  const translate = useTranslate()
  return (
    <div className="ContainerLetter" id="ContainerLetter">
      <Dialog
        open={props.isOpen}
        className="system-dialog"
        autoScrollBodyContent={true}
        onBackdropClick={() => props.closeDialog()}
      >
        <IconButton
          id="CloseLetter"
          style={{
            top: 0,
            right: 10,
            position: 'absolute',
            minWidth: 40,
            backgroundColor: 'rgba(255, 255, 255, 0)',
          }}
          onClick={() => props.closeDialog()}
        >
          {
            <Tooltip title={translate('Close Dialog')} enterDelay={300}>
              <CloseIcon style={{ color: '#000000' }} />
            </Tooltip>
          }
        </IconButton>

        <div
          style={{ padding: 10 }}
          dangerouslySetInnerHTML={{
            __html: props.dialogData ? populate(props.dialogData.productDetails, props.system, null) : '',
          }}
        />
      </Dialog>
    </div>
  )
}
export default SystemDetailDialog

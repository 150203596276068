import { makeStyles } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'

const COLOR_PALETTE = {
  selected: {
    backgroundColor: '#4272DD',
    borderColor: '#4272DD',
  },
  active: {
    backgroundColor: '#019939',
    borderColor: '#019939',
  },
  inactive: {
    backgroundColor: '#F8F8F8',
    borderColor: '#E7E7E7',
  },
}

export const useStyles = makeStyles((theme: OpenSolarThemeType) => ({
  stepButtonLabel: {
    position: 'relative',
    zIndex: 5,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  archiveLabel: {
    fontSize: 10,
    marginLeft: 3,
  },
  stepButtonIcon: {
    height: 20,
    marginRight: 3,

    '&.inactive': {
      opacity: 0,
    },
  },
  stepButtonBase: {
    position: 'relative',
    textAlign: 'center',
    cursor: 'pointer',
    color: '#757575',
    fontWeight: 400,
    margin: 2,
    height: 35,
    flexGrow: 1,
    paddingLeft: 16,
    paddingRight: 30,
    display: 'flex',
    justifyContent: 'center',

    '&:hover': {
      color: '#fff',
    },
    '&.selected': {
      color: '#fff',
      fontWeight: 500,
    },

    '&.active': {
      color: '#fff',
    },
  },
  itemGraphics: {
    inset: 0,
    position: 'absolute',

    '& .circle': {
      width: 33,
      height: 33,
      position: 'absolute',
      borderRadius: 20,
      zIndex: 1,
      border: '1px solid',

      '&.last': {
        right: -12,
      },
    },

    '&:before': {
      zIndex: 2,
      content: `''`,
      position: 'absolute',
      top: 0,
      left: 0,
      height: 'calc(50% + 2px)', //Changed height to prevent border tiny gap when zoomed out / zoomed in
      width: '100%',
      backgroundColor: COLOR_PALETTE.inactive.backgroundColor,
      boxSizing: 'border-box',
      transform: 'skew(45deg, 0deg)',
      borderWidth: '1px 1px 0 1px',
      borderStyle: 'solid',
    },
    '&:after': {
      zIndex: 2,
      color: 'gray',
      content: `''`,
      position: 'absolute',
      bottom: 0,
      left: 0,
      height: 'calc(50% - 2px)', //Changed height to prevent border tiny gap when zoomed out / zoomed in
      width: '100%',
      backgroundColor: COLOR_PALETTE.inactive.backgroundColor,
      boxSizing: 'border-box',
      transform: 'skew(-45deg, 0deg)',
      borderWidth: '0px 1px 1px 1px',
      borderStyle: 'solid',
    },
    '&.first': {
      '&:before': {
        borderLeft: 'none',
      },
      '&:after': {
        borderLeft: 'none',
      },
      '& .circle': {
        left: -23,
      },
    },
    '&.last': {
      minWidth: 130,
      '&:before': {
        borderRight: 'none',
      },
      '&:after': {
        borderRight: 'none',
      },
      '& .circle': {
        right: -13,
      },
    },

    '&:hover': {
      '&:before': {
        backgroundColor: COLOR_PALETTE.selected.backgroundColor,
        borderColor: COLOR_PALETTE.selected.borderColor,
      },
      '&:after': {
        backgroundColor: COLOR_PALETTE.selected.backgroundColor,
        borderColor: COLOR_PALETTE.selected.borderColor,
      },
      '& .circle': {
        backgroundColor: COLOR_PALETTE.selected.backgroundColor,
        borderColor: COLOR_PALETTE.selected.borderColor,
      },
    },
    '&.selected': {
      '& .circle': {
        backgroundColor: COLOR_PALETTE.selected.backgroundColor,
        borderColor: COLOR_PALETTE.selected.borderColor,
      },
      '&:before': {
        backgroundColor: COLOR_PALETTE.selected.backgroundColor,
        borderColor: COLOR_PALETTE.selected.borderColor,
      },
      '&:after': {
        backgroundColor: COLOR_PALETTE.selected.backgroundColor,
        borderColor: COLOR_PALETTE.selected.borderColor,
      },
    },
    '&.inactive': {
      '& .circle': {
        backgroundColor: COLOR_PALETTE.inactive.backgroundColor,
        borderColor: COLOR_PALETTE.inactive.borderColor,
      },
      '&:before': {
        backgroundColor: COLOR_PALETTE.inactive.backgroundColor,
        borderColor: COLOR_PALETTE.inactive.borderColor,
      },
      '&:after': {
        backgroundColor: COLOR_PALETTE.inactive.backgroundColor,
        borderColor: COLOR_PALETTE.inactive.borderColor,
      },
    },

    '&.active': {
      '& .circle': {
        backgroundColor: COLOR_PALETTE.active.backgroundColor,
        borderColor: COLOR_PALETTE.active.borderColor,
      },
      '&:before': {
        backgroundColor: COLOR_PALETTE.active.backgroundColor,
        borderColor: COLOR_PALETTE.active.borderColor,
      },
      '&:after': {
        backgroundColor: COLOR_PALETTE.active.backgroundColor,
        borderColor: COLOR_PALETTE.active.borderColor,
      },
    },
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    overflowX: 'scroll',
  },
  normalRow: {
    backgroundColor: 'white',
    padding: '0 10px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: 12,
    marginRight: 0,
    width: '100%',
  },
  lastButtonWrapper: {
    width: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: 10,
      width: '100%',
      textAlign: 'center',
    },
  },
  otherStages: {
    marginTop: 15,
    marginLeft: 'auto',
  },
  selectedOther: {
    color: '#fff',
    backgroundColor: '#F75757',
    borderColor: '#F75757 !important',
    '&:hover': {
      backgroundColor: '#F75757',
    },
  },
}))

export const workflowStyles = makeStyles((theme: OpenSolarThemeType) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  selectDrop: {
    padding: '5px 10px',
    borderRadius: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    '& span': {
      color: theme.greyMid1,
      marginRight: 10,
    },
    '& .MuiFormControl-root': {
      margin: 0,
      width: 'unset',
      '& .MuiSelect-root': {
        padding: 0,
        paddingRight: 30,
        ...theme.typography.subtitle2,
        lineHeight: 'normal',
      },
      '& .MuiFormHelperText-root': {
        display: 'none',
      },
    },
  },
  projectDate: {
    fontSize: 12,
    marginTop: 20,
    '& .MuiSvgIcon-root': {
      height: 15,
    },
  },
  lockPricing: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  orgDisplay: {
    marginLeft: 'auto',
    padding: '5px 10px',
    borderRadius: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    '& span': {
      color: theme.greyMid1,
      marginRight: 5,
    },
  },
  toggleWrapper: {
    display: 'flex',
    '& .nextBtn': {
      marginLeft: 'auto',
    },
  },
}))

import React from 'react'
import LoadingDots from './LoadingDots'

type PropTypes = {
  text?: string
}
const FullScreenLoading: React.FC<PropTypes> = (props) => {
  return (
    <div style={{ position: 'fixed', inset: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <LoadingDots text={props.text}/>
    </div>
  )
}
export default FullScreenLoading

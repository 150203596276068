import { DialogActions, DialogContent, Divider } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { rolesSelectors } from 'ducks/auth_roles'
import Alert from 'elements/Alert'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import React, { useEffect, useState } from 'react'
import { AutocompleteInput, ReferenceInput, useDataProvider } from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import BrightButtonWrapper from 'resources/roles/BrighteButtonWrapper'
import { CommonPromptPropTypes } from './types'

const BrighteRoleErrorPrompt: React.FC<CommonPromptPropTypes> = (props) => {
  const [roleIsSet, setRoleIsSet] = useState<boolean>(false)
  const [triedToConnect, setTriedToConnect] = useState<boolean>(false)
  const [currentRepName, setCurrentRepName] = useState<string | undefined>(undefined)
  const [currentRoleId, setCurrentRoleId] = useState<number | undefined>(undefined)
  const [disableConnect, setDisableConnect] = useState<boolean>(false)

  const roleId = useSelector(rolesSelectors.getCurrentRoleId) as number
  const form = useForm()
  const assignedRole = form.getState().values.assigned_role
  const dataProvider = useDataProvider()

  useEffect(() => {
    // any time the assignedRole value changes (other than when the var is first set) recalculate
    if (!roleIsSet) setRoleIsSet(true)
    else {
      if (assignedRole) {
        // clear cache to ensure calcs pick up newly assigned rep
        window.editor.selectedSystem.clearHashedArgs()
        window.editor.filter('type', 'OsSystem').forEach((system: any) => {
          window.Designer.requestSystemCalculations(system)
        })
      }
    }
  }, [assignedRole])

  useEffect(() => {
    if (triedToConnect) {
      logAmplitudeEvent('brighte_error_prompt_connect_attempt', {})
    }
  }, [triedToConnect])

  useEffect(() => {
    window.editor.pauseDesignEventListener = true
    return () => {
      window.editor.pauseDesignEventListener = false
    }
  }, [])

  useEffect(() => {
    if (currentRoleId) {
      dataProvider
        .getOne('roles', { id: currentRoleId })
        .then(({ data }) => {
          console.log('data role', data)
          if (data) {
            setCurrentRepName(data.display)
            if (data.brighte_agent_id) {
              setDisableConnect(true)
            } else {
              setDisableConnect(false)
            }
          }
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
  }, [currentRoleId])

  useEffect(() => {
    if (assignedRole) {
      let assignedRoleId: string = assignedRole.substring(assignedRole.indexOf('/roles/') + 7).replace('/', '')
      if (assignedRoleId) setCurrentRoleId(parseInt(assignedRoleId))
    } else {
      setCurrentRepName(undefined)
    }
  }, [assignedRole])

  return (
    <>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <Alert severity="warning">{props.message}</Alert>
          <h2>Option 1</h2>
          <p style={{ textAlign: 'center' }}>
            {currentRepName
              ? `This project is currently assigned to ${currentRepName}. You can connect this user to Brighte by clicking the button below`
              : 'This project is currently unassigned, please assign it below.'}
          </p>
          {currentRepName && (
            <BrightButtonWrapper
              id={roleId}
              thisRoleIsConnected={false}
              brighteConnectedToOrg={true}
              isStudioMode
              buttonLabel={disableConnect ? 'User already connected' : `Connect ${currentRepName} to Brighte`}
              onStudioButtonClock={() => setTriedToConnect(true)}
              disabled={disableConnect}
            />
          )}
          <div style={{ width: '100%', height: '32px', position: 'relative', padding: '15px 0px' }}>
            <Divider style={{ width: '100%', margin: '15px 0px' }} />
            <span
              style={{
                backgroundColor: '#fff',
                left: 'calc(50% - 8px)',
                top: '21px',
                position: 'absolute',
                padding: '0px 5px',
              }}
            >
              OR
            </span>
          </div>
          <h2>Option 2</h2>
          <p style={{ marginTop: '10px', textAlign: 'center' }}>
            If this project should be assigned to someone else you can select from the list of users below that have
            already connected their Brighte user:
          </p>
          <div style={{ width: '95%' }}>
            {/* @ts-ignore these components are finicky. Not sure why it would be required that we pass in class overrides */}
            <ReferenceInput
              name="assigned_role"
              /* Adding a unique value for resources ensures other fields which related to roles in the same page/form do not clash */
              resource="project-assigned_role"
              source="roles"
              reference="roles"
              allowEmpty={true}
              filter={{ require_brighte_agent_id: true }}
              fullWidth={true}
              style={{ width: '100%' }}
              renderChildrenBeforeRecord={true}
              label="Assign the project to:"
            >
              {/* @ts-ignore */}
              <AutocompleteInput
                optionText="display"
                optionValue="url"
                showSpinnerWhenRefreshing={true}
                /* Hack to ensure some choices don't get mysteriously dropped */
                allowDuplicates={true}
              />
            </ReferenceInput>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <div style={{ margin: '15px' }}>
            <ProUXButton label="Submit" type="primary" onClick={props.onClose} />
          </div>
        </div>
      </DialogActions>
    </>
  )
}
export default BrighteRoleErrorPrompt

export const PAYMENT_METHOD_LABEL_MAP = {
  credit_card: 'Credit Card',
  local_bank_transfer: 'Bank transfer',
  ach: 'ACH Debit',
  becs_direct_debit: 'Direct Debit',
  sepa_credit: 'Bank Transfer',
}

export const CREDIT_CARD_METHODS = ['credit_card_standard', 'credit_card_premium', 'credit_card_international']
export const DEBIT_CARD_METHODS = ['debit_card_standard', 'debit_card_premium', 'debit_card_international']
export const THREE_DS_ENABLED_COUNTRIES = ['GB'] // Countries where 3DS is enabled
export const BLUESNAP_PREMIUM_CATEGORIES = ['BUSINESS']

import { CircularProgress } from '@material-ui/core'
import { getSystemName } from 'Designer/hooks/useSystemName'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { permissionsSelectors } from 'ducks/permissions'
import { Alert, WrenchOutlineIcon } from 'opensolar-ui'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import { convertUTCDate } from 'projectSections/sections/info/installation'
import { useTranslate } from 'ra-core'
import { useEffect, useMemo } from 'react'
import { BooleanInput, SelectInput, TextInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import GreenLancerCTA from 'resources/projects/GreenLancer/GreenLancerCTA'
import { ProjectType } from 'types/projects'
import { useDateFormatter } from 'util/date'
import { dateParserYYYYMMDD, getCurrentDate } from 'util/misc'
import { autoAdvanceInstalledProject, getAutoAdvanceInstalledLabel } from '../../../utils/autoAdvanceStage'
import StyledDateInput from '../common/StyledDateInput'
import { getProjectSectionStyles } from '../styles'
import { InputContainer, InputRow, useStyles } from './styles'

const InstallationAccordion = () => {
  const translate = useTranslate()
  const sectionStyles = getProjectSectionStyles()
  const { allowView } = useSelector(permissionsSelectors.getProjectPermissionByKey('info_installation_info'))
  if (!allowView) {
    return null
  }
  return (
    <AccordionCard
      name={'installation'}
      elevation={0}
      title={translate('Installation')}
      className={sectionStyles.accordionReskin}
      titleIcon={WrenchOutlineIcon}
      content={InstallationContent}
      lazyRender={true}
    />
  )
}

const InstallationContent = (props) => {
  const form = useForm()
  const formState = useFormState()
  const classes = useStyles()
  const orgId = useSelector(authSelectors.getOrgId)
  const translate = useTranslate()
  const formatter = useDateFormatter()
  const orgWorkflows = useSelector(orgSelectors.getWorkflows)
  const project = formState.values
  const sectionStyles = getProjectSectionStyles()
  const { allowEdit } = useSelector(permissionsSelectors.getProjectPermissionByKey('info_installation_info'))
  const installationDate = useMemo(() => {
    if (project.installation_date) {
      return formatter(convertUTCDate(project.installation_date)).split(' ')[0]
    }
  }, [project.installation_date])
  const showPermittingServices: boolean = project && project.country_iso2 === 'US'
  const systems = useMemo(() => {
    let getSystems: any = []
    //title and payment option details are not available in project form > systems values
    window.editor.getSystems()?.forEach((sys) => {
      const getSystem = project?.systems?.find((x) => x.uuid === sys.uuid)
      if (getSystem) {
        getSystems.push({ ...sys, url: getSystem.url, module_quantity: sys.moduleQuantity() })
      }
    })
    return getSystems
  }, [window.editor.getSystems(), project.systems])
  useEffect(() => {
    const dirtyFields = form.mutators.getFormDirtyFields()
    if (
      project.project_installed === 1 &&
      ((dirtyFields?.includes('system_installed') && project.system_installed) ||
        (dirtyFields?.includes('serial_numbers_panels') && project.serial_numbers_panels) ||
        (dirtyFields?.includes('serial_numbers_inverters') && project.serial_numbers_inverters) ||
        (dirtyFields?.includes('serial_numbers_batteries') && project.serial_numbers_batteries))
    ) {
      form.mutators.updateField('project_installed', 2)
      if (!project.installation_date) form.mutators.updateField('installation_date', getCurrentDate())
    }
  }, [
    project.system_installed,
    project.serial_numbers_panels,
    project.serial_numbers_inverters,
    project.serial_numbers_batteries,
  ])

  const toggleLabel = useMemo(() => {
    if (project.installation_date && project.project_installed !== null) {
      if (project.project_installed === 1) {
        return translate(
          'This project was marked installed on %{installation_date} through project workflow automation.',
          {
            installation_date: installationDate,
          }
        )
      } else {
        return translate('This project was marked installed on %{installation_date}.', {
          installation_date: installationDate,
        })
      }
    } else if (project.project_installed === 1) {
      return translate('This project was marked installed through project workflow automation.')
    }
  }, [project.installation_date, project.project_installed])

  const toggleAdvanceInstalledLabel = useMemo(() => {
    return getAutoAdvanceInstalledLabel(project, orgId, orgWorkflows, formState, translate)
  }, [project.project_installed, formState.initialValues])

  useEffect(() => {
    autoAdvanceInstalledProject(project, orgId, orgWorkflows, formState, form)
  }, [project.project_installed])

  const disableFields = useMemo(() => {
    const currentWorkflow = orgWorkflows.find((e) => e.id === project.workflow.workflow_id)
    const currentStage = currentWorkflow?.workflow_stages.find((e) => e.id === project.workflow.active_stage_id)
    if (currentStage?.milestone === 4) {
      return true
    }
  }, [project.workflow.active_stage_id])

  return (
    <InputContainer>
      <div className={classes.blueWrapper}>
        <div className={sectionStyles.row}>
          <BooleanInput
            value={project.project_installed !== null}
            options={{ color: 'primary' }}
            source="project_installed"
            label={false}
            onChange={(value) => {
              if (value === true) {
                form.mutators.updateField('project_installed', 2)
                if (!project.installation_date) form.change('installation_date', getCurrentDate())
              } else {
                form.mutators.updateField('project_installed', null)
                form.change('installation_date', null)
                form.change('system_installed', null)
                form.change('serial_numbers_batteries', '')
                form.change('serial_numbers_inverters', '')
                form.change('serial_numbers_panels', '')
              }
            }}
            disabled={disableFields || !allowEdit}
          />
          <span style={{ marginLeft: 20 }}>{translate('Installed')}</span>
        </div>
        {toggleLabel && (
          <div style={{ marginTop: 10 }}>
            <span>{toggleLabel}</span>
          </div>
        )}
        {toggleAdvanceInstalledLabel && (
          <div style={{ marginTop: 10 }}>
            <Alert severity="info">
              <span>{toggleAdvanceInstalledLabel}</span>
            </Alert>
          </div>
        )}
      </div>
      <InputRow>
        <StyledDateInput
          label={'Installation Date'}
          inputVariant="outlined"
          source={'installation_date'}
          parse={dateParserYYYYMMDD}
          onChange={(value) => {
            if (project.project_installed === 1 && value) {
              form.change('project_installed', 2)
            }
          }}
          disabled={disableFields || !allowEdit}
        />
        {!!systems?.length ? (
          <SelectInput
            allowEmpty
            label={'Installed System'}
            choices={systems}
            optionValue="url"
            sort={{ field: 'order', order: 'ASC' }}
            optionText={(choice: any) => getSystemName(choice, translate)}
            resettable={true}
            source={'system_installed'}
            variant="outlined"
            disabled={disableFields || !allowEdit}
            fullWidth
          />
        ) : (
          <div>
            <CircularProgress className={classes.loading} size={18} />
          </div>
        )}
      </InputRow>
      <TextInput
        source={'serial_numbers_panels'}
        variant="outlined"
        label={'Serial Numbers: Panels'}
        disabled={disableFields || !allowEdit}
        fullWidth
      />
      <TextInput
        source={'serial_numbers_inverters'}
        variant="outlined"
        label={'Serial Numbers: Inverters'}
        disabled={disableFields || !allowEdit}
        fullWidth
      />
      <TextInput
        source={'serial_numbers_batteries'}
        variant="outlined"
        label={'Serial Numbers: Batteries'}
        disabled={disableFields || !allowEdit}
        fullWidth
      />
      {showPermittingServices && (
        <div>
          <h3 className={classes.sectionHeader}>GreenLancer</h3>
          <p>
            {project.greenlancer_project_id
              ? translate("Click the button below to view your project on GreenLancer's site")
              : translate(
                  "Don't have time to draw up the plan set? We can help! Our partners at GreenLancer can deliver a full, PE stamped set within as little as 3 days."
                )}
          </p>
          <div className={sectionStyles.column}>
            <div className={classes.solarOutlet}>
              <GreenLancerCTA project={project as ProjectType} isIronRidge={false} />
            </div>
          </div>
        </div>
      )}
    </InputContainer>
  )
}

export default InstallationAccordion

import React from 'react'
import { useForm, useFormState } from 'react-final-form'

const withForm = (BaseComponent: any): any => {
  if (typeof BaseComponent === 'string') throw new Error('Incorrect usage')

  const RefComponent = React.forwardRef((props, ref) => {
    const form = useForm()
    const formState = useFormState()

    return <BaseComponent {...props} form={form} formState={formState} />
  })

  RefComponent.defaultProps = BaseComponent.defaultProps

  return RefComponent
}

export default withForm

import LoadingDots from 'layout/widgets/LoadingDots'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type PropTypes = {
  text?: string
}

const useStyles = makeOpenSolarStyles((theme) => ({
  overlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#ececec',
    opacity: 0.9,
    zIndex: 9000,
  },
  dotsWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 20px',
    zIndex: 9001,
  },
}))

const LoadingOverlay: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.overlay} />
      <div className={classes.dotsWrapper}>
        <LoadingDots
          text={
            props.text ||
            'Your application is being reviewed. This may take up to 30 seconds. Thank you for your patience'
          }
        />
      </div>
    </>
  )
}
export default LoadingOverlay

import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { hideFullScreenLoader, markCreditAppAsOpened } from 'ducks/myEnergy'
import BrighteApplicationDialog from 'myenergy/selectionComponent/loanApplicationButton/brighte/BrighteApplicationDialog'
import { useNotify } from 'ra-core'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'
import { PaymentOptionDataType } from 'types/paymentOptions'
import { logAppOpenedEvent } from 'util/events'

const restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  paymentOptionId: number
  systemUuid: string
  projectId: number
  proposalData: any
  openPlentiDialog: (type: string, data: { url: string }, isFullWidth: boolean) => void
}

const ForceCreditAppWrapper: React.FC<PropTypes> = (props) => {
  const [paymentOption, setPaymentOption] = useState<PaymentOptionDataType | undefined>(undefined)
  const [integration, setIntegration] = useState<undefined | 'plenti' | 'brighte' | 'energy_ease'>(undefined)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [url, setUrl] = useState<string | undefined>(undefined)

  const notify = useNotify()
  const dispatch = useDispatch()
  const termsAccepted = useSelector(authSelectors.getHasAcceptedTerms)
  const orgId: number = useMemo(() => props.proposalData?.org?.id, [props.proposalData])

  useEffect(() => {
    if (props.paymentOptionId && orgId && termsAccepted) getPmt()
  }, [props.paymentOptionId, orgId, termsAccepted])

  const getPmt = () => {
    // to-do, don't hack this, wait until they're logged in and accepted t&cs
    // actually it's failing because it's a custmer
    const url = `orgs/${orgId}/projects/${props.projectId}/systems/${props.systemUuid}/validate_credit_app/${props.paymentOptionId}/`
    restClientInstance('CUSTOM_GET', 'custom', {
      url,
    })
      .then((res) => {
        if (res?.data?.integration) {
          setPaymentOption(res.data)
          setIntegration(res.data.integration)
          if (res.data.integration === 'brighte') openBrighteDialog()
          else if (res.data.integration === 'plenti') openPlentiDialog()
          else if (res.data.integration === 'energy_ease') openEnergyEaseDialog()
        }
      })
      .catch((err) => {
        console.log('err', err)
        let message =
          err?.body?.message ||
          'Unable to find payment option. Please work with your sales representative to submit a credit application.'
        notify(message, 'warning')
      })
      .finally(() => {
        removeParams()
      })
  }

  const openBrighteDialog = () => {
    restClientInstance('CUSTOM_GET', 'custom', {
      url:
        'orgs/' +
        orgId +
        '/projects/' +
        props.projectId +
        '/systems/' +
        props.systemUuid +
        '/' +
        props.paymentOptionId +
        '/brighte/application_url/',
    })
      .then(
        (response: any) => {
          if (response?.data?.success) {
            setShowDialog(true)
            setUrl(response.data.url)
            logAmplitudeEvent('integrated_finance_application_opened', {
              integration: 'brighte',
              url_src: 'next_steps',
            })
          }
        },
        (reject: any) => {
          let message: string = 'Something went wrong and we are unable to create this Brighte application.'
          if (reject.message) {
            message = reject.message
          }
          notify(message, 'warning')

          logAmplitudeEvent('integrated_finance_application_open_error', {
            integration: 'brighte',
            url_src: 'next_steps',
          })
        }
      )
      .catch((err: any) => {
        console.log('err', err.body)
        if (err?.body?.message) {
          notify(err.body.message, 'warning')
          logAmplitudeEvent('integrated_finance_application_open_error', {
            integration: 'brighte',
            url_src: 'next_steps',
          })
        }
      })
      .finally(() => {
        dispatch(hideFullScreenLoader())
        dispatch(markCreditAppAsOpened())
      })
  }

  const openPlentiDialog = () => {
    let actionData = props.proposalData?.selectedProject?.available_customer_actions
      ?.find((systemActions) => systemActions.system_uuid === props.systemUuid)
      ?.actions_available?.find(
        (act) =>
          `${act.payment_option_id}` === `${props.paymentOptionId}` && act.payment_method === 'plenti_loan_application'
      )

    if (actionData) {
      logAppOpenedEvent(actionData, paymentOption?.title || 'Plenti Product')
      props.openPlentiDialog(
        'PlentiLoanApplication',
        {
          url: actionData.application_url,
        },
        true
      )
    }
  }

  const openEnergyEaseDialog = () => {
    let actionData = props.proposalData?.selectedProject?.available_customer_actions
      ?.find((systemActions) => systemActions.system_uuid === props.systemUuid)
      ?.actions_available?.find(
        (act) =>
          `${act.payment_option_id}` === `${props.paymentOptionId}` && act.payment_method === 'energy_ease_application'
      )
    logAppOpenedEvent(actionData, paymentOption?.title || 'Smart Ease Product')
    if (actionData.application_url) {
      logAmplitudeEvent('integrated_finance_application_opened', { integration: 'energy_ease' })
      const listenForIframeResult = (evt) => {
        if (evt.data === 'closed') {
          window.removeEventListener('message', listenForIframeResult)
          var ifrm = document.getElementById('energy-ease-iframe')
          var form = document.getElementById('energy-ease-form')
          document.body.style.setProperty('overflowY', 'none')
          if (ifrm) {
            document.body.removeChild(ifrm)
          }
          if (form) {
            document.body.removeChild(form)
          }
        }
      }

      // Add other available fields here as per API schema
      var params = {}

      // Extract params from actionData.application_url JSON
      var application_params
      var formActionUrl: string
      try {
        application_params = JSON.parse(actionData.application_url)
        for (var key in application_params) {
          // Note: We assume it is safe to include extra fields which are not supported by Energy Ease API
          params[key] = application_params[key]
        }
        formActionUrl = application_params.iFrameFormAction
      } catch (err) {
        console.error('Error detecting application form parameters', err)
        return
      }

      var form = document.createElement('form')
      form.target = 'test-iframe'
      form.setAttribute('allowTransparency', 'true')
      form.method = 'POST'
      form.id = 'energy-ease-form'

      // End point
      form.action = formActionUrl

      var input1 = document.createElement('input')
      input1.type = 'hidden'
      input1.name = 'value'
      input1.value = JSON.stringify(params)
      form.appendChild(input1)

      var doc = document.documentElement
      var topOffset = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
      var ifrm = document.createElement('iframe')
      ifrm.setAttribute('allowTransparency', 'true')
      ifrm.setAttribute('name', 'test-iframe')
      ifrm.id = 'energy-ease-iframe'
      ifrm.style.cssText =
        'z-index: 2000; display: block; border: 0px none transparent; overflow-x: hidden; overflow-y: auto; visibility: visible; margin: 0px; padding: 0px; -webkit-tap-highlight-color: transparent; position: absolute; left: 0px; width: 100%; height: 100%; background-color: rgba(255,255,255, 0.8);top:'
      ifrm.style.cssText += 'top:' + topOffset + 'px'
      document.body.appendChild(ifrm)
      window.addEventListener('message', listenForIframeResult, true)

      document.body.appendChild(form)
      document.body.style.setProperty('overflowY', 'hidden')
      form.submit()
      removeParams()
    }
  }

  const removeParams = () => {
    //  http://localhost:3000/#/projects/10354/proposal/?view_mode=application&target_system=6471&target_pmt=4137&url_src=next_steps
    const path = window.location.hash.substring(window.location.hash.indexOf('#') + 2)
    const params = new URLSearchParams(path.substring(path.indexOf('?')))
    params.delete('target_pmt')
    params.delete('view_mode')
    params.delete('target_system')
    params.delete('url_src')
  }

  if (integration === 'brighte' && url) {
    return (
      <BrighteApplicationDialog isOpen={showDialog} onClose={() => setShowDialog(false)} url={url} isFullWidth={true} />
    )
  }
  return null
}
export default ForceCreditAppWrapper

// @ts-nocheck
import { Divider, makeStyles } from '@material-ui/core'
import { autoLogin } from 'actions/authActions'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import React, { useEffect, useState } from 'react'
import { useNotify } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'
import { LOANPAL_OR_GOODLEAP } from '../loanApplicationButton/loanpal/constants'
import UploadStipulationForm from './UploadStipulationForm'

const useStyles = makeStyles({
  background: {
    backgroundColor: 'rgba(250, 250, 250, 0.9)',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer: {
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: '20px',
    maxWidth: '800px',
    textAlign: 'center',
    boxShadow: '10px 5px 5px #ececec',
  },
  formWrapper: {
    textAlign: 'left',
  },
})

const restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  match: {
    isExact: boolean
    path: string
    url: string
    params: {
      [key: string]: string
    }
  }
}

const StipUploadPage: React.FunctionComponent<PropTypes> = (props) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [stips, setStips] = useState<any[]>([])
  const [logoUrl, setLogoUrl] = useState<string | undefined>(undefined)
  const [orgName, setOrgName] = useState<string | undefined>(undefined)
  const [showInvalidURL, setShowInvalidURL] = useState<boolean>(false)
  const [loginResolved, setLoginResolved] = useState<boolean>(false)
  const [succeeded, setSucceeded] = useState<boolean>(false)

  const notify = useNotify()
  const dispatch = useDispatch()

  useEffect(() => {
    const path = window.location.hash.substring(window.location.hash.indexOf('#') + 2)
    const params = new URLSearchParams(path.substring(path.indexOf('?')))
    const token = params.get('url_auth_token')
    dispatch(autoLogin({ url_auth_token: token, autoLoginProject: props.match?.params?.projectId }))
  }, [])

  const user = useSelector((state: any) => state.auth?.user)

  useEffect(() => {
    if (user && !loginResolved) setLoginResolved(true)
  }, [loginResolved, user])

  useEffect(() => {
    logAmplitudeEvent('stip_upload_form_viewed', { mode: 'link', integration: props.match?.params?.partner })
  }, [])

  useEffect(() => {
    if (loginResolved) {
      restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${props.match?.params?.orgId}/projects/${props.match?.params?.projectId}/${
          props.match?.params?.partnerId
        }/${props.match?.params?.partner || 'loanpal'}/stip_upload_data/`,
      })
        .then((response: any) => {
          if (response?.data?.stips) setStips(response.data.stips)
          if (response?.data?.logo_url) setLogoUrl(response.data.logo_url)
          if (response?.data?.org_name) setOrgName(response.data.org_name)
        })
        .catch((error: any) => {
          notify('Invalid Link', 'warning')
          setShowInvalidURL(true)
          console.log('err', error)
        })
        .finally(() => setLoading(false))
    }
  }, [loginResolved])

  const classes = useStyles()
  return (
    <div className={classes.background}>
      <div>{logoUrl && <img src={logoUrl} alt="" height="100" />}</div>
      <div className={classes.contentContainer}>
        {showInvalidURL ? (
          <>
            <h2>404: Page Not Found</h2>
          </>
        ) : (
          <>
            {orgName ? (
              <p style={{ marginBottom: '30px' }}>
                Your representative at {orgName} has requested that you use the form below to upload documentation
                that's required to continue with your financing.
              </p>
            ) : (
              <p style={{ marginBottom: '30px' }}>
                Use the form below to securely upload the following documentation that is required to continue with your
                financing.
              </p>
            )}
            {!succeeded &&
              stips?.length > 0 &&
              stips.map((stip, i) => (
                <div className={classes.formWrapper} key={i}>
                  <Divider />
                  <UploadStipulationForm
                    key={i}
                    stipulation={stip}
                    showSuccessOnPage={() => setSucceeded(true)}
                    integration={props.match?.params?.partner}
                  />
                </div>
              ))}
            {succeeded && (
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <h2>
                  {props.match?.params?.partner === 'mosaic' ? 'Mosaic' : LOANPAL_OR_GOODLEAP} will review your
                  documentation
                </h2>
                <p>
                  There is no further action required at this time. If would like to upload more documents you can do so
                  by clicking the button below.
                </p>
                <ProUXButton type="secondary" label="Upload Another Document" onClick={() => setSucceeded(false)} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}
export default StipUploadPage

import appStorage from 'storage/appStorage'

export type RedirectionEntity<T extends string> = {
  type: T
  redirect: string
  states?: Record<string, unknown>
}

const REDIRECTION_LOCAL_STORAGE_KEY = 'revisit_redirection'
/**
 * Use this for when you have a redirection flow and you don't have control over the redirection endpoint.
 *
 */

export const storeLocalRedirection = (redirection: RedirectionEntity<string>) => {
  appStorage.setJSON(REDIRECTION_LOCAL_STORAGE_KEY, redirection)
}

export const getLocalRedirection = (): RedirectionEntity<string> | undefined => {
  const redirectionStore = appStorage.getJSON<RedirectionEntity<string>>(REDIRECTION_LOCAL_STORAGE_KEY)
  if (redirectionStore === undefined) {
    return
  }
  if (!redirectionStore.hasOwnProperty('type') || !redirectionStore.hasOwnProperty('redirect')) {
    return
  }

  return redirectionStore
}

export const clearLocalRedirection = () => {
  appStorage.setJSON(REDIRECTION_LOCAL_STORAGE_KEY, undefined)
}

import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Grow from '@material-ui/core/Grow'
import { authSelectors } from 'ducks/auth'
import CustomField from 'elements/field/CustomField'
import _ from 'lodash'
import React, { useCallback } from 'react'
import { NumberInput, SelectInput, TextInput } from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { RootState } from 'types/state'
import { countries } from '../../../../constants/countries'

const restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  justSelectedAddress: boolean
}

const useStyles = makeStyles({
  select: {
    width: '100%',
  },
  textInput: {
    maxWidth: '100%',
  },
})

const AddressForm: React.FunctionComponent<PropTypes> = (props) => {
  const classes = useStyles()
  const form = useForm()
  const orgId = useSelector(authSelectors.getOrgId)
  const projectId = useSelector((state: RootState) => state.projectId)
  const updateTemperatureValue = useCallback(
    _.debounce(() => {
      const projectValue = form.getState().values
      restClientInstance('CUSTOM_POST', 'custom', {
        url: `orgs/${orgId}/projects/${projectId}/temperature_min_max/`,
        data: {
          lon: projectValue['lon'],
          lat: projectValue['lat'],
          temperature_min_override: null,
          temperature_max_override: null,
        },
      })
        .then((response: any) => {
          if (response?.data) {
            const isFieldExist = form.getFieldState('temperature_min_max')
            if (!isFieldExist) {
              form.registerField('temperature_min_max', () => {}, {})
            }
            form.change('temperature_min_max', response.data.temperature_min_max)
          }
        })
        .catch((error: any) => {
          console.warn(error)
        })
    }, 2000),
    []
  )

  return (
    <Grid container spacing={3}>
      <Grow in={true} timeout={props.justSelectedAddress ? 3000 : 0}>
        <>
          <Grid item xs={12} sm={6} lg={3}>
            <CustomField
              className={classes.textInput}
              resource="projects"
              name="address"
              source="address"
              component={TextInput}
              {...props}
              fullWidth={true}
              errorStyle={{ position: 'absolute', bottom: '-16px' }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <CustomField
              className={classes.textInput}
              resource="projects"
              name="locality"
              source="locality"
              component={TextInput}
              {...props}
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <CustomField
              className={classes.textInput}
              resource="projects"
              name="state"
              source="state"
              component={TextInput}
              emptyValue={null}
              {...props}
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <CustomField
              className={classes.textInput}
              resource="projects"
              name="zip"
              source="zip"
              component={TextInput}
              {...props}
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <CustomField
              className={classes.textInput}
              resource="projects"
              name="county"
              source="county"
              emptyValue={null}
              component={TextInput}
              {...props}
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <SelectInput
              className={classes.select}
              label="Country"
              source="country_iso2"
              choices={countries}
              fullWidth
              allowEmpty
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <NumberInput
              className={classes.textInput}
              resource="projects"
              name="lat"
              source="lat"
              onChange={updateTemperatureValue}
              {...props}
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <NumberInput
              className={classes.textInput}
              resource="projects"
              name="lon"
              source="lon"
              onChange={updateTemperatureValue}
              {...props}
              fullWidth={true}
            />
          </Grid>
        </>
      </Grow>
    </Grid>
  )
}
export default AddressForm

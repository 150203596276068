import Grid from '@material-ui/core/Grid'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import Button from 'elements/button/Button'
import ComponentAutocompleteInput from 'pages/inventory/components/ComponentAutocompleteInput'
import NumberInput from 'pages/inventory/components/NumberInput'
import React, { useCallback, useState } from 'react'
import { useNotify } from 'react-admin'
import type { BasicInventory, EditableInventoryFields } from '../../type'
import useTableStyles from '../useTableStyles'

type InlineInventoryCreateProps = {
  disabled: boolean
  onSave(inventory: BasicInventory): void
}

const parseValidInventoryData = (rowValue): BasicInventory | undefined => {
  if (!rowValue.code) {
    return
  }

  if (!rowValue.component_type) {
    // Does all data have component type?
    return
  }
  return rowValue as BasicInventory
}

const defaultInventoryValue = {
  reorder_quantity: 0,
  on_hand: 0,
}

const InlineInventoryCreate = ({ onSave, disabled }: InlineInventoryCreateProps) => {
  const classes = useTableStyles()
  const notify = useNotify()
  const [inventoryFormValue, setInventoryFormValue] = useState(defaultInventoryValue)
  const handleAdd = () => {
    const inventoryToAdd = parseValidInventoryData(inventoryFormValue)
    if (inventoryToAdd !== undefined) {
      onSave(inventoryToAdd)
      // Being lazy here, ideally to clear the code when save
      // But clear code needs to host the value up in ComponentAutocompleteInput
      setInventoryFormValue({
        ...inventoryToAdd,
        ...defaultInventoryValue,
      })
      notify('New inventory added click save button to save the changes')
    } else {
      notify('Please check input values', 'warning')
    }
    logAmplitudeEvent('generic_button_clicked', {
      source: 'add_inventory',
      context: 'inventory_list_table',
    })
  }

  const handleNumberInputUpdate = useCallback((field: EditableInventoryFields) => {
    return (value: number) =>
      setInventoryFormValue((inventory) => {
        return {
          ...inventory,
          [field]: value,
        }
      })
  }, [])

  const handleComponentSelected = useCallback(({ code, componentType }: { code: string; componentType: string }) => {
    setInventoryFormValue((inventory) => {
      return {
        ...inventory,
        code,
        component_type: componentType,
      }
    })
  }, [])

  return (
    <Grid container xs={12} alignItems={'center'} className={classes.rowWrapper}>
      <Grid item xs={6}>
        <ComponentAutocompleteInput trackingSource={'inventory_list'} onSelect={handleComponentSelected} />
      </Grid>
      <Grid item xs={1} className={classes.gridItem}>
        <NumberInput
          value={inventoryFormValue.reorder_quantity}
          updateValue={handleNumberInputUpdate('reorder_quantity')}
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={1} className={classes.gridItem}>
        <NumberInput value={inventoryFormValue.on_hand} updateValue={handleNumberInputUpdate('on_hand')} />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={2} className={classes.gridItem}>
        <Button size="small" onClick={handleAdd} disabled={disabled}>
          Add
        </Button>
      </Grid>
    </Grid>
  )
}

export default InlineInventoryCreate

import { Paper } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import type { OpenSolarThemeType } from 'Themes'
import React, { memo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  table: {
    tableLayout: 'fixed',
  },
  container: {
    border: '1px solid #FFB945',
    borderRadius: '4px',
  },
}))

const OrderTableContainer = ({
  children,
  isAvailable,
}: {
  children?: JSX.Element | JSX.Element[]
  isAvailable?: boolean
}) => {
  const classes = useStyles()

  return isAvailable ? (
    <Paper elevation={1} data-testid="hardware-ordering-table">
      <Table className={classes.table} size="small">
        {children}
      </Table>
    </Paper>
  ) : (
    <div className={classes.container}>
      <Table size="small">{children}</Table>
    </div>
  )
}

export default memo(OrderTableContainer)

import { Chip } from 'opensolar-ui'
import { IconButtonTooltip } from 'projectSections/sections/manage3/styles'
import React, { useContext } from 'react'
import { useTranslate } from 'react-admin'
import { PrivateFileType } from 'types/privateFile'
import { formatTimeStringBaseOnLocale } from 'util/misc'

import { ButtonsRow } from 'projectSections/sections/manage3/common/ButtonsRow'
import { IconBtnWithPermissions } from 'projectSections/sections/manage3/common/IconBtnWithPermissions'
import { FilesContext, FilesContextProps } from '../common/FilesContextWrapper'
import {
  StyledTinnyBox,
  TagsContainer,
  TinnyBoxBody,
  TinnyboxColumn,
  TinnyboxGreyText,
  TinnyBoxTopRow,
} from '../common/styles/styles'
import { downloadFile, previewInfo } from '../common/utils'

type FileRowProps = {
  record: PrivateFileType
}

export const FileRow: React.FunctionComponent<any> = (props) => {
  const translate = useTranslate()
  const { record } = props
  const { hasPreview } = previewInfo(record)

  const { onSuccess, setRecordSelected, setDialogSelected } = useContext(FilesContext) as FilesContextProps

  return (
    <>
      <div>
        <StyledTinnyBox
          title={
            <IconButtonTooltip
              title={translate(hasPreview ? 'Click to preview' : `Preview only available for PDF and image files`)}
              placement={'bottom-start'}
            >
              <TinnyBoxTopRow>
                <div>
                  <div
                    // className={`${classes.fileTitle} ${hasPreview ? '' : classes.noPreview}`}
                    onClick={() => {
                      if (hasPreview) {
                        setRecordSelected(record)
                        setDialogSelected('preview_file')
                      }
                    }}
                  >
                    {record.title}
                  </div>
                </div>
              </TinnyBoxTopRow>
            </IconButtonTooltip>
          }
          key={record.id}
          backgroundColor={'transparent'}
          actions={
            <ButtonsRow>
              <IconBtnWithPermissions
                permissionToCheck="info_documents"
                permissions={['allowView']}
                btnType={'download'}
                resource={'private_files'}
                record={record}
                onClick={() => {
                  downloadFile(record)
                }}
              />
              <IconBtnWithPermissions
                permissionToCheck="info_documents"
                permissions={['allowEdit']}
                btnType={'edit'}
                resource={'private_files'}
                record={record}
                onClick={() => {
                  setRecordSelected(record)
                  setDialogSelected('edit_file')
                }}
              />
              <IconBtnWithPermissions
                permissionToCheck="info_documents"
                permissions={['allowDelete']}
                btnType={'delete'}
                resource={'private_files'}
                record={record}
                onDelete={onSuccess}
              />
            </ButtonsRow>
          }
        >
          <TinnyBoxBody>
            {record.file_tags_data.length > 0 && (
              <TagsContainer>
                {record.file_tags_data.map((file_tag, i) => (
                  <Chip key={file_tag.title} color={'info'} label={translate(file_tag.title)} />
                ))}
              </TagsContainer>
            )}
            <TinnyboxColumn>
              <TinnyboxGreyText>{formatTimeStringBaseOnLocale(record.created_date)}</TinnyboxGreyText>
            </TinnyboxColumn>
          </TinnyBoxBody>
        </StyledTinnyBox>
      </div>
    </>
  )
}

import { AlertBoxList, AlertBoxListPropsTypes } from 'projectSections/errors/AlertBoxList'
import { ProjErrorFilters } from 'projectSections/errors/useFilterProjectErrors'
import { useSharingErrorFilter } from 'projectSections/errors/useSharingErrorFilter'
import { useSortedProjectErrors } from 'projectSections/errors/useSortedProjectErrors'
import React, { memo } from 'react'
import { SeverityType } from 'reducer/project/projectErrorsReducer'

export type ProjectWarningBoxPropsTypes = Omit<AlertBoxListPropsTypes, 'errors'> &
  ProjErrorFilters & {
    setHighestSeverity?: (value: SeverityType | undefined) => void
    maxShown?: number
  }

const ProjectWarningBox: React.FC<ProjectWarningBoxPropsTypes> = ({
  sharingSection,
  setHighestSeverity,
  maxShown,
  ...rest
}) => {
  // Should filter the shown sharing errors based on the section this box relates to
  const filter = useSharingErrorFilter(rest.filter, sharingSection)

  const sortedErrors = useSortedProjectErrors([{ ...rest, filter }])
  setHighestSeverity?.(sortedErrors[0]?.severity)

  return <AlertBoxList errors={sortedErrors} maxShown={maxShown} sharingSection={sharingSection} />
}

export default memo(ProjectWarningBox)

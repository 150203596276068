import { Grid, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CreateIcon from '@material-ui/icons/CreateOutlined'
import { orgSelectors } from 'ducks/orgs'
import { permissionsSelectors } from 'ducks/permissions'
import ClickableIcon from 'elements/ClickableIcon'
import { EVENT_TYPE_CHOICES } from 'projectSections/sections/manage3/details/elements/history/formVariants/TaskFormContent'
import React, { MouseEvent, useMemo, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { StageActionEventType } from 'types/projects'
import { useDateFormatted } from 'util/date'
import CRUDRecordDialog from '../../common/CRUDRecordDialog'
import IncompleteEventForm from '../projectManagement/editEventDialog/IncompleteEventForm'
import ActionCompleteAvatar from './ActionCompleteAvatar'
import PersonAvatar from './PersonAvatar'

const useStyles = makeStyles((theme) => ({
  wrapper: { borderBottom: '2px solid #B4B4B4', margin: '0px 20px' },
  row: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
  },
  avatarWrapper: {
    flex: 1,
    marginRight: 5,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  textWrapper: {
    flex: 6,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  daysAgoText: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '12px',
    lineHeight: '14px',
    marginTop: '5px',
  },
  title: {
    color: 'rgb(0,0,0)',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
  },
  notes: {
    color: '#212121',
    fontSize: '12px',
    lineHeight: '14 px',
    marginTop: '5px',
  },
  buttonWrapper: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

type PropTypes = {
  record: StageActionEventType
  isPlanned?: boolean
  onEdit: () => void
}

const ProjectHistoryEvent: React.FunctionComponent<PropTypes> = (props) => {
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false)
  const form = useForm()
  const { allowView, allowCreate, allowEdit, allowDelete } = useSelector(
    permissionsSelectors.getProjectPermissionByKey('manage_notes_activities_actions')
  )
  const myOrgUrl = useSelector(orgSelectors.getOrgUrl)
  const eventOrgUrl = props.record.org
  const enableAction = myOrgUrl === eventOrgUrl
  const classes = useStyles()
  const translate = useTranslate()

  const date = props.record.end ? props.record.end : props.record.created_date
  const formattedDate = useDateFormatted(date, { long: true })

  const startEdit = (e: MouseEvent) => setShowEditDialog(true)

  const updateEventInForm = (savedRecord: StageActionEventType) => {
    // updates to this record seem to work by archiving the existing record and creating a new one so we dont' swap it out, we just add the new one
    if (savedRecord && savedRecord.id) {
      const updatedArray: StageActionEventType[] = form
        .getState()
        .values?.events_data?.map((ev: StageActionEventType) => {
          if (ev.id === savedRecord.id) return savedRecord
          else return ev
        })
      form.getState().initialValues.events_data = updatedArray
      form.getState().values.events_data = updatedArray
      props.onEdit()
    }
  }

  const removeRecordFromForm = (idToRemove: number) => {
    const newEvents = form.getState().values.events_data.map((e: StageActionEventType) => {
      if (e.id !== idToRemove) {
        e.is_archived = true
        return e
      } else return e
    })
    form.getState().initialValues.events_data = newEvents
    form.getState().values.events_data = newEvents
  }

  const taskEventTitle = useMemo(() => {
    const event = props.record
    const taskEvent = EVENT_TYPE_CHOICES.find((x) => x.id === event?.event_type_id)
    if (event && taskEvent)
      return taskEvent
        ? `${translate('Task')} - ${translate(taskEvent?.name)} ${translate(
            !!event.team_members?.length ? 'assigned to' : 'created by'
          )} ${event.who?.display}`
        : ''
  }, [props.record])
  return (
    <Grid item xs={12} classes={{ root: classes.wrapper }}>
      <div className={classes.row}>
        <div className={classes.avatarWrapper}>
          {props.record.is_complete ? (
            <ActionCompleteAvatar small={props.isPlanned} is_archived={props.record.is_archived} />
          ) : (
            <PersonAvatar
              url={props.record?.who?.portrait_image_public_url}
              personName={props.record?.who?.display}
              small={props.isPlanned}
              is_archived={props.record.is_archived}
            />
          )}
        </div>
        <div className={classes.textWrapper}>
          <span className={classes.title} style={props.record.is_archived ? { textDecoration: 'line-through' } : {}}>
            {taskEventTitle || props.record.title}
          </span>
          <span className={classes.daysAgoText}>{formattedDate}</span>
          {props.record.notes && props.record.notes.length > 0 && (
            <span className={classes.notes}>{props.record.notes}</span>
          )}
        </div>
        <div className={classes.buttonWrapper}>
          <Tooltip title={enableAction ? '' : translate('This item belongs to another organisation')}>
            <div>
              <ClickableIcon onClick={startEdit} disabled={!enableAction}>
                <CreateIcon />
              </ClickableIcon>
            </div>
          </Tooltip>
        </div>
      </div>
      {showEditDialog && (
        <CRUDRecordDialog
          isOpen={showEditDialog}
          record={props.record}
          resource="events"
          basePath="/events"
          onDismiss={() => setShowEditDialog(false)}
          formContent={<IncompleteEventForm />}
          notificationOnSuccess={true}
          customSuccessNotificationText={translate('Your updates have been saved')}
          updateFormValsFn={updateEventInForm}
          removeRecordFromForm={removeRecordFromForm}
          dialogTitle={translate('Edit Activity')}
          deleteDisabled={props.record.is_archived || !allowDelete}
          saveDisabled={props.record.is_archived}
          deleteButtonLabel={translate('Archive')}
        />
      )}
    </Grid>
  )
}
export default ProjectHistoryEvent

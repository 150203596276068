const getNewProjectId = () => {
  return 'new'
}

export default function reducer(previousState = null, { type, payload }) {
  var projectId = previousState

  if (type === '@@router/LOCATION_CHANGE') {
    if (payload.location.pathname) {
      if (
        payload.location.pathname.indexOf('/new') !== -1 ||
        payload.location.pathname.indexOf('/projects/create') !== -1
      ) {
        return getNewProjectId()
      } else if (payload.location.pathname === '/studio') {
        return null
      } else if (payload.location.pathname === '/projects') {
        return null
      } else if (payload.location.pathname === '/myenergy') {
        return null
      }

      if (payload.location.pathname.startsWith('/studio/')) {
        projectId = payload.location.pathname.split('/')[2]
        if (projectId.indexOf('new') !== -1 || projectId.indexOf('create') !== -1) {
          return getNewProjectId()
        } else {
          return parseInt(projectId, 10)
        }
      } else if (payload.location.pathname.startsWith('/projects/')) {
        projectId = payload.location.pathname.split('/')[2]
        return parseInt(projectId, 10)
      } else if (payload.location.pathname.startsWith('/myenergy/')) {
        projectId = payload.location.pathname.split('/')[2]
        if (projectId.indexOf('new') !== -1 || projectId.indexOf('create') !== -1) {
          return getNewProjectId()
        } else {
          return parseInt(projectId, 10)
        }
      }
    }
  }
  return previousState
}

import React, { useMemo } from 'react'
import { useFormState } from 'react-final-form'
import { BlueSnapOwnerType, BlueSnapRepType, FieldPrefixFilterType } from '../types'
import { contactIsNotEmpty, getContactsAreEqual, getNormalizeBlueSnapContact } from '../utils'
import ContactCard from './ContactCard'

type PropTypes = {
  ownersData: BlueSnapOwnerType
  companyRepData: BlueSnapRepType
  setFieldPrefixFilter: (val: FieldPrefixFilterType) => void
  navigateToPage: (routeKey: string) => void
}

const ContactCardWrapper: React.FC<PropTypes> = (props) => {
  const formVals = useFormState().values
  const companyRep = formVals?.companyRep
  const owner1 = formVals.ownershipInfoArr?.[0]
  const owner2 = formVals.ownershipInfoArr?.[1]
  const owner3 = formVals.ownershipInfoArr?.[2]
  const owner4 = formVals.ownershipInfoArr?.[3]

  // if there is a company rep who is NOT an owner, we need to display their contact card by itself
  // but if the company rep is also an owner then we just display the owner card with the company rep toggle enabled
  const repIsUnique = useMemo(() => {
    if (!companyRep) return false
    else if (getContactsAreEqual(getNormalizeBlueSnapContact(companyRep), getNormalizeBlueSnapContact(owner1))) {
      return false
    } else if (getContactsAreEqual(getNormalizeBlueSnapContact(companyRep), getNormalizeBlueSnapContact(owner2))) {
      return false
    } else if (getContactsAreEqual(getNormalizeBlueSnapContact(companyRep), getNormalizeBlueSnapContact(owner3))) {
      return false
    } else if (getContactsAreEqual(getNormalizeBlueSnapContact(companyRep), getNormalizeBlueSnapContact(owner4))) {
      return false
    }
    return true
  }, [companyRep, owner1, owner2, owner3, owner4])

  return (
    <>
      {companyRep && repIsUnique && contactIsNotEmpty(companyRep) && (
        <ContactCard
          contactData={companyRep}
          setFieldPrefixFilter={props.setFieldPrefixFilter}
          fieldPrefix="companyRep"
          isUniqueRep={true}
          navigateToPage={props.navigateToPage}
          hasUniqueRep={repIsUnique}
        />
      )}
      {owner1 && contactIsNotEmpty(owner1) && (
        <ContactCard
          contactData={owner1}
          setFieldPrefixFilter={props.setFieldPrefixFilter}
          fieldPrefix="ownershipInfoArr[0]"
          fieldIndex={0}
          navigateToPage={props.navigateToPage}
          hasUniqueRep={repIsUnique}
        />
      )}
      {owner2 && contactIsNotEmpty(owner2) && (
        <ContactCard
          contactData={owner2}
          setFieldPrefixFilter={props.setFieldPrefixFilter}
          fieldPrefix="ownershipInfoArr[1]"
          fieldIndex={1}
          navigateToPage={props.navigateToPage}
          hasUniqueRep={repIsUnique}
        />
      )}
      {owner3 && contactIsNotEmpty(owner3) && (
        <ContactCard
          contactData={owner3}
          setFieldPrefixFilter={props.setFieldPrefixFilter}
          fieldPrefix="ownershipInfoArr[2]"
          fieldIndex={2}
          navigateToPage={props.navigateToPage}
          hasUniqueRep={repIsUnique}
        />
      )}
      {owner4 && contactIsNotEmpty(owner4) && (
        <ContactCard
          contactData={owner4}
          setFieldPrefixFilter={props.setFieldPrefixFilter}
          fieldPrefix="ownershipInfoArr[3]"
          fieldIndex={3}
          navigateToPage={props.navigateToPage}
          hasUniqueRep={repIsUnique}
        />
      )}
    </>
  )
}
export default ContactCardWrapper

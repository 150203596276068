import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import { IconButton } from 'opensolar-ui'
import NumberInput from 'pages/inventory/components/NumberInput'
import React, { useState } from 'react'

const NumberFieldWithEditButton = ({ value, handleIconClicked }) => {
  return (
    <div>
      <span>{value}</span>
      <IconButton size="small" onClick={handleIconClicked}>
        <EditOutlinedIcon fontSize="small" />
      </IconButton>
    </div>
  )
}

const EditableNumberField = ({
  value,
  handleValueChange,
}: {
  value: number
  handleValueChange(newValue: number): void
}) => {
  const [editing, setEditing] = useState(false)

  return editing ? (
    <NumberInput value={value} placeholder={value.toString()} updateValue={handleValueChange} />
  ) : (
    <NumberFieldWithEditButton value={value} handleIconClicked={() => setEditing(true)} />
  )
}

export default EditableNumberField

import { CircularProgress } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { Button } from 'opensolar-ui'
import { useNotify } from 'ra-core'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {}

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    marginLeft: '15px',
  },
}))

const TriggerDailyPaymentEmailsButton: React.FC<PropTypes> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const isStaff = useSelector(authSelectors.getIsStaff)
  const isSuperUser = useSelector(authSelectors.getIsSuperUser)
  const org = useSelector(orgSelectors.getOrg)
  const classes = useStyles()
  const notify = useNotify()
  const disabled_envs = ['staging', 'production'] // Hide for staging and prod

  const sendDailyPaymentsEmail = () => {
    setIsLoading(true)
    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${org?.id}/comms/daily_payments/send/`,
    })
      .then((res) => {
        notify(res.data?.message, 'info')
      })
      .catch((err) => {
        notify(err.message, 'info')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  if (!isStaff && !isSuperUser) return null
  if (disabled_envs.includes(window.ENV)) return null

  return (
    <div className={classes.wrapper}>
      <Button onClick={sendDailyPaymentsEmail} variant="contained" size="small" disabled={isLoading}>
        {isLoading ? <CircularProgress /> : <span>Trigger Daily Payments Email</span>}
      </Button>
    </div>
  )
}

export default TriggerDailyPaymentEmailsButton

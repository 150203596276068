import type { FilterComponentNode, FilterNodeKeyAndRenderer } from 'elements/hardwareFilter/type'
import type { ComponentTypes } from 'types/selectComponent'
import { FILTER_NODE_KEY_AND_FACTORY } from '../constants'

const useAvailableFilterByRendererConfig = ({
  filtersNodesAndRendererConfig,
  componentType,
}: {
  filtersNodesAndRendererConfig: FilterNodeKeyAndRenderer[]
  componentType?: ComponentTypes
}): FilterComponentNode[] => {
  const nodes = filtersNodesAndRendererConfig.map(({ key, rendererComponent }: FilterNodeKeyAndRenderer) => {
    const hook = FILTER_NODE_KEY_AND_FACTORY[key]
    return hook({ RendererComponent: rendererComponent, options: { componentType } })
  })

  const filterNodes: FilterComponentNode[] = []
  nodes.forEach((node) => {
    if (node !== undefined) {
      filterNodes.push(node)
    }
  })
  return filterNodes
}

export default useAvailableFilterByRendererConfig

import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useTranslate } from "ra-core";

const useStyles = makeStyles(() => ({
    tooltip: {
        paddingTop: 5,
        minWidth: 250,
    },
    contactName: {
        fontWeight: 'bold',
    },
    externalContactName: {
        marginTop: 2,
        fontSize: '0.75rem'
    },
    externalTooltip: {
        minWidth: 100,
        height: 18
    },
    contactDetails: {
        '& > span': {
            marginRight: 5,
            fontWeight: 'normal',
        },
        '& .bullet': {
            fontSize: '1.2em',  
            verticalAlign: 'middle',
        },
    },
}))

const TeamBadgeTooltipContent = ({ activeTeamMember, sharedwith }) => {
    const classes = useStyles();
    const translate = useTranslate();
    const roleMapping = {
        assigned_role: 'Assigned Team Member',
        assigned_installer_role: 'Installer',
        assigned_designer_role: 'Designer',
        assigned_site_inspector_role: 'Site Inspector',
        assigned_salesperson_role: 'Sales Person',
      };
      
    const getRoleDisplayName = (roleId) => {
        return roleMapping[roleId] || roleId;
      };
    
    const name = activeTeamMember ? `${activeTeamMember?.first_name} ${activeTeamMember?.family_name}` : sharedwith?.org_name ?? '' 
    const email = activeTeamMember ? activeTeamMember?.user_email : ''
    const phone = activeTeamMember ? activeTeamMember?.phone : ''
    const role = getRoleDisplayName(activeTeamMember?.projectRoleId ?? '');
    const isShared = !activeTeamMember ? '' : sharedwith?.is_shared;
    const isExternal = !activeTeamMember ? true : false;


    return (
        <Grid container direction="column" className={isExternal ? classes.externalTooltip : classes.tooltip}>
            <Grid item>
                <Typography variant="body1" className={isExternal ? classes.externalContactName : classes.contactName}>
                    {name}
                    {isExternal && (<>
                    <span style={{ margin: '0px 5px 0px 5px' }} className="bullet">&bull;</span>
                    <span>{isShared ? translate('Shown') : translate('Hidden')}</span>
                    </>)}
                </Typography>
                <Typography variant="caption" className={classes.contactDetails}>
                    <span>{email}</span>
                    {phone && (
                    <span className="bullet">&bull;</span>)}
                    <span>{phone}</span>
                    {role && (
                    <span className="bullet">&bull;</span>)}
                    <span>{role && translate(role)}</span>
                </Typography>
            </Grid>
        </Grid>
    )
}
export default TeamBadgeTooltipContent
import { Tooltip, makeStyles } from '@material-ui/core'
import HelpRoundedIcon from '@material-ui/icons/HelpRounded'
import React from 'react'
import { useTranslate } from 'react-admin'

const useStyles = makeStyles((theme) => ({
  header: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    margin: '10px 16px',
  },
  headerLeft: {
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.54)',
    verticalAlign: 'middle',
    fontWeight: 500,
  },
  headerRightText: {
    marginRight: 10,
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 14,
  },
  headerRightIcon: {
    marginRight: 10,
    color: 'rgba(24, 145, 255, 1)',
    fontSize: 14,
    width: 20,
    height: 20,
  },
}))

export const ChartSubHeader = () => {
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <div className={classes.header}>
      <span className={classes.headerLeft}>{translate('Project Activity')}</span>
      <Tooltip
        title={translate(
          'Keep track of your business over time, and act fast when your customers engage with your proposals.'
        )}
        enterDelay={200}
      >
        <HelpRoundedIcon className={classes.headerRightIcon} />
      </Tooltip>
    </div>
  )
}

export const StatisticsSubHeader = () => {
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <div className={classes.header}>
      <span className={classes.headerLeft}>{translate('Projects by Phase')}</span>
    </div>
  )
}

export const FetchProjectsWithTagSubHeader = () => {
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <div className={classes.header}>
      <span className={classes.headerLeft}>{translate('Fetch Projects with Tag')}</span>
      <span className={classes.headerRightText}>{translate('In Queue')}</span>
    </div>
  )
}

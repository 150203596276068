import { Typography } from '@material-ui/core'
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined'
import { styled } from 'opensolar-ui'
import { ORDER_STATUS } from 'resources/inventoryTransfer/constants'

const FlexWrapper = styled('div')({
  padding: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
})

const OrderActivity = ({ orderData }) => {
  const getMsg = (order) => {
    switch (order.status) {
      case ORDER_STATUS.DELIVERED:
        return 'have been successfully delivered to warehouse'
      case ORDER_STATUS.PROCESSING:
        return 'Order is being processed'
      case ORDER_STATUS.ORDERED:
        return 'Order has been placed'
      case ORDER_STATUS.QUOTE_SUBMITTED:
        return 'Quote has been submitted'
      default:
        return ''
    }
  }

  return (
    <div>
      {orderData?.map((order) => (
        <FlexWrapper>
          <LocalShippingOutlinedIcon />
          <Typography variant="subtitle2">{`PO (#${order.order_id}) - ${getMsg(order)}`}</Typography>
        </FlexWrapper>
      ))}
    </div>
  )
}

export default OrderActivity

import { Typography } from '@material-ui/core'
import { styled } from 'opensolar-ui'
import InsightsFlexibleTable from 'pages/inventory/insights/table/InsightsFlexibleTable'
import { TableColumn } from 'pages/inventory/insights/type'
import { getSupplierName } from '../../util'

const Wrapper = styled('div')({
  padding: 20,
})

const OrderParts = ({ orderData }) => {
  const partsOrderedColumns: TableColumn[] = [
    { id: 'code', label: 'Item', type: 'text' },
    { id: 'component_type', label: 'Category', type: 'text' },
    { id: 'quantity', label: 'Quantity', type: 'text' },
  ]

  return (
    <div>
      {orderData
        ?.sort((a, b) => a.order_id - b.order_id)
        .map((order, i) => {
          const orderNumber = order.order_number_display || order.order_number || order.order_id
          return (
            <Wrapper key={i}>
              <Typography variant="subtitle2" gutterBottom>
                {`Order ${order.order_id} (${getSupplierName(order)} ID ${orderNumber})`}
              </Typography>
              <InsightsFlexibleTable
                columns={partsOrderedColumns}
                isTouched={false}
                data={order.line_items}
                action={() => {}}
              />
            </Wrapper>
          )
        })}
    </div>
  )
}

export default OrderParts

import Button from 'elements/button/Button'
import { FC } from 'react'
import { CodeBlock } from '../CodeBlock'
import ComponentPage from '../ComponentPage'
import { FeatureGrid } from '../FeatureGrid'
import { LibraryVersions } from '../LibraryVersions'
import { ButtonColors, ButtonIcons, ButtonSizes, ButtonVariants } from './ButtonUsage'

const ButtonUsage_code = require('!!raw-loader!./ButtonUsage').default

export const DemoButtons: FC = () => {
  return (
    <ComponentPage
      name={'Button'}
      import="import { Button } from 'opensolar-ui'"
      description="The Base Button component which should be used everywhere."
      versions={LibraryVersions}
      links={{ mui4: 'https://v4.mui.com/components/buttons/', mui5: 'https://mui.com/material-ui/react-button/' }}
    >
      <CodeBlock name="Button Variants" code={ButtonUsage_code} codePart={1}>
        <ButtonVariants />
      </CodeBlock>

      <CodeBlock name="Button Colors" code={ButtonUsage_code} codePart={2}>
        <ButtonColors />
      </CodeBlock>

      <CodeBlock name="Button Sizes" code={ButtonUsage_code} codePart={3}>
        <ButtonSizes />
      </CodeBlock>

      <CodeBlock name="Button Icons" code={ButtonUsage_code} codePart={4}>
        <ButtonIcons />
      </CodeBlock>

      <CodeBlock name="Button Feature Grid" hideGrid={true}>
        <FeatureGrid
          component={Button}
          propsGen={(row, col, lastHeader) => {
            return { children: <>Button</>, ...row, ...col, ...lastHeader }
          }}
          colHeaders={[]}
          rowHeaders={[]}
          cols={[
            { color: 'default' },
            { color: 'primary' },
            { color: 'secondary' },
            { color: 'info' },
            { color: 'success' },
            { color: 'warning' },
            { color: 'error' },
          ]}
          rows={[
            { _type: 'heading', _title: 'Medium Size (default)', size: 'medium' },
            { variant: 'contained' },
            { variant: 'outlined' },
            { variant: 'text' },
            { _type: 'heading', _title: 'Small Size', size: 'small' },
            { variant: 'contained' },
            { variant: 'outlined' },
            { variant: 'text' },
            { _type: 'heading', _title: 'Large Size', size: 'large' },
            { variant: 'contained' },
            { variant: 'outlined' },
            { variant: 'text' },
            { _type: 'heading', _title: 'Disabled', disabled: true },
            { variant: 'contained' },
            { variant: 'outlined' },
            { variant: 'text' },
          ]}
        />
      </CodeBlock>
    </ComponentPage>
  )
}

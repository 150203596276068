// @ts-nocheck
//this is going to replace PaginationWithAction.js
import { makeStyles, Theme } from '@material-ui/core'
import classnames from 'classnames'
import React, { FC } from 'react'
import { Pagination } from 'react-admin'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

type PropsType = {
  actions?: any
  className?: any
}

const ProUXPaginationWithActions: FC<PropsType> = ({ actions, className, ...rest }) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.wrapper, className)}>
      {!!actions && React.createElement(actions, rest)}
      <Pagination {...rest} />
    </div>
  )
}

export default ProUXPaginationWithActions

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classnames from 'classnames';
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from 'opensolar-ui';
import PropTypes from 'prop-types';
import { useTimeout } from 'ra-core';
import { memo } from 'react';
import Placeholder from './Placeholder';
var times = function (nbChildren, fn) { return Array.from({ length: nbChildren }, function (_, key) { return fn(key); }); };
var DatagridLoading = function (_a) {
    var _b = _a.classes, classes = _b === void 0 ? {} : _b, className = _a.className, expand = _a.expand, hasBulkActions = _a.hasBulkActions, nbChildren = _a.nbChildren, _c = _a.nbFakeLines, nbFakeLines = _c === void 0 ? 5 : _c, size = _a.size;
    // alway show list loading animation
    var SHOW_LOADING_ANIMATION = true;
    var oneSecondHasPassed = useTimeout(1000) || SHOW_LOADING_ANIMATION;
    return oneSecondHasPassed ? (_jsxs(Table, __assign({ className: classnames(classes.table, className), size: size }, { children: [_jsx(TableHead, { children: _jsxs(TableRow, __assign({ className: classes.row }, { children: [expand && _jsx(TableCell, { padding: "none", className: classes.expandHeader }), hasBulkActions && (_jsx(TableCell, __assign({ padding: "checkbox", className: classes.expandIconCell }, { children: _jsx(Checkbox, { className: "select-all", color: "primary", checked: false }) }))), times(nbChildren, function (key) { return (_jsx(TableCell, __assign({ variant: "head", className: classes.headerCell }, { children: _jsx(Placeholder, {}) }), key)); })] })) }), _jsx(TableBody, { children: times(nbFakeLines, function (key1) { return (_jsxs(TableRow, __assign({ style: { opacity: 1 / (key1 + 1) } }, { children: [expand && (_jsx(TableCell, __assign({ padding: "none", className: classes.expandIconCell }, { children: _jsx(IconButton, __assign({ className: classes.expandIcon, component: "div", "aria-hidden": "true" }, { children: _jsx(ExpandMoreIcon, {}) })) }))), hasBulkActions && (_jsx(TableCell, __assign({ padding: "checkbox", className: classes.expandIconCell }, { children: _jsx(Checkbox, { className: "select-all", color: "primary", checked: false }) }))), times(nbChildren, function (key2) { return (_jsx(TableCell, __assign({ className: classes.rowCell }, { children: _jsx(Placeholder, {}) }), key2)); })] }), key1)); }) })] }))) : null;
};
DatagridLoading.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    expand: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
    hasBulkActions: PropTypes.bool,
    nbChildren: PropTypes.number,
    nbFakeLines: PropTypes.number,
    size: PropTypes.oneOf(['small', 'medium']),
};
export default memo(DatagridLoading);

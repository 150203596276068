import React from 'react'
import SignaturePad from 'react-signature-pad'

type PropTypes = {
  markSignatureAsComplete: (signatureData: string) => void
  sort: number
  disclosureIndex: number
}

type StateTypes = {}

class LoanpalDisclosureSignature extends React.Component<PropTypes, StateTypes> {
  onSignatureFinish = () => {
    //   @ts-ignore
    const signatureData = this.SignaturePad.toDataURL()
    this.props.markSignatureAsComplete(signatureData)
  }

  render() {
    const _this = this
    return (
      <div style={{ width: '100%' }} id={`signature-wrapper${this.props.disclosureIndex}-${this.props.sort}`}>
        <SignaturePad
          clearButton={false}
          ref={(element: any) => {
            //@ts-ignore
            this.SignaturePad = element
            //@ts-ignore
            window.SignaturePad = element
          }}
          onEnd={function () {
            //@ts-ignore
            var pixelsFilled = this._ctx
              //@ts-ignore
              .getImageData(0, 0, this._ctx.canvas.width, this._ctx.canvas.height)
              //@ts-ignore
              .data.filter((v) => v > 0).length
            if (pixelsFilled > 1000) {
              _this.onSignatureFinish()
            }
          }}
        />
      </div>
    )
  }
}

export default LoanpalDisclosureSignature

import { Grid, makeStyles } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orgSelectors } from 'ducks/orgs'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import type { ComponentTypes } from 'types/selectComponent'
import { FilterType, SelectComponentFilters } from 'types/selectComponent'
import type { ComponentType as ComponentItemType } from 'types/studio/items'
import { userHasAppliedFilters } from './Filters'
import { HardwareSelectionPropsType } from './HardwareSelectionDialog'
import ClearFilterButton from './filters/ClearFilterButton'
import ComponentFilter from './filters/ComponentFilter'
import SearchBar from './filters/SearchBar'

const useStyles = makeStyles(
  (theme) => ({
    filtersWrapper: {
      margin: '8px 0px',
    },
  }),
  { name: 'HardwareSearchToolbar' }
)

export const getComponentsWithRestrictions = (restrictionType: 'battery' | 'charger'): ComponentItemType[] => {
  if (!window.editor?.selectedSystem) {
    return []
  }

  if (restrictionType === 'battery') {
    return [
      ...window.editor.selectedSystem
        .others()
        .map((c) => c.getComponentData())
        .filter(Boolean)
        .filter((otherType) => otherType?.compatibleBatteryCodes && otherType.compatibleBatteryCodes()?.length > 0),
      ...window.editor.selectedSystem
        .inverters()
        .map((c) => c.getComponentData())
        .filter(Boolean)
        .filter((inverterType) => inverterType.compatibleBatteryCodes()?.length > 0),
    ]
  } else {
    return window.editor.selectedSystem
      .batteries()
      .map((c) => c.getComponentData())
      .filter(Boolean)
      .filter((batteryType) => batteryType.compatibleChargerCodes()?.length > 0)
  }
}

export const getComponentsWithRestrictionsCodesCsv = (restrictionType: 'battery' | 'charger', separator = ', ') =>
  getComponentsWithRestrictions(restrictionType)
    .map((c) => c.code)
    .join(separator)

export const getCompatibleCodesForSystemCsv = (restrictionType: 'battery' | 'charger', separator = ', ') =>
  getCompatibleCodesForSystem(restrictionType).join(separator)

export const getCompatibleCodesForSystem = (restrictionType: 'battery' | 'charger'): string[] => {
  if (!window.editor?.selectedSystem) {
    return []
  }

  if (restrictionType === 'battery') {
    return window.editor.selectedSystem.compatibleBatteryCodes()
  } else {
    return window.editor.selectedSystem.compatibleChargerCodes()
  }
}

type SearchPropsType = {
  trackSource: string
  filtersSchema: FilterType[]
  setFilters: Function
  filterSettings: SelectComponentFilters
  initialFilterSettings: SelectComponentFilters
  componentType: ComponentTypes
}

const SearchToolbar: FC<SearchPropsType & HardwareSelectionPropsType> = (props) => {
  const {
    trackSource,
    filtersSchema,
    setFilters,
    filterSettings,
    initialFilterSettings,
    sharedWith,
    projectOwnerId,
    componentType,
  } = props
  const classes = useStyles()
  const countryIso2 = useSelector(orgSelectors.getOrgIso2)

  const handleClear = () => {
    logAmplitudeEvent('hardware_select_filters', {
      action: 'clicked',
      context: 'clear_filter_button',
      source: trackSource,
    })
    const newFilterSettings = { ...initialFilterSettings, ...{ q: filterSettings.q } }
    setFilters(newFilterSettings, true)
  }

  return (
    <>
      <SearchBar componentType={componentType} setFilters={setFilters} />
      <Grid className={classes.filtersWrapper} container wrap="nowrap" justify="space-between">
        <Grid container item xs={'auto'} wrap="wrap">
          <ComponentFilter
            trackSource={trackSource}
            filtersSchema={filtersSchema}
            filterSettings={filterSettings}
            setFilters={setFilters}
            sharedWith={sharedWith}
            projectOwnerId={projectOwnerId}
          />
        </Grid>
        {userHasAppliedFilters(filtersSchema, filterSettings, false, countryIso2) && (
          <Grid item xs={'auto'}>
            <ClearFilterButton handleClear={handleClear} />
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default SearchToolbar

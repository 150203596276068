import Typography from '@material-ui/core/Typography'
import { styled } from 'opensolar-ui'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import { useMemo } from 'react'
import type { ComponentTypes } from 'types/selectComponent'
import { getGenericComponentConfigs } from './TechnicalDetails'

const FullWidthWrapper = styled('div')({
  width: '100%',
})

const SectionCardTop = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: 25,
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  borderBottom: '1px solid ' + COLOR_PALETTE.lightGrey,
}))

const SectionCardBottom = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: 25,
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
}))

const TableContainer = styled('div')({
  border: '1px solid #ccc',
  overflow: 'hidden',
  width: '100%',
})

const Table = styled('table')({
  width: '100%',
  borderCollapse: 'collapse',
})

const TableCell = styled('td')<{ isHeader: boolean }>(({ theme, isHeader }) => ({
  padding: '12px 16px',
  color: isHeader ? '#333' : '#555',
  fontWeight: isHeader ? 'bold' : 'normal',
  borderLeft: isHeader ? 'none' : '1px solid #ddd',
  textAlign: isHeader ? 'left' : 'right',
  width: '25%',
  border: '1px solid #eee',
  borderCollapse: 'collapse',
}))

const TechnicalDetails3 = ({
  productDetailData,
  componentType,
  quantityPerItem,
}: {
  productDetailData: { [key in string]: unknown }
  componentType?: ComponentTypes
  quantityPerItem: number | undefined
}) => {
  const optionalDataConfig = useMemo(() => {
    const data: { label: string; value: string | number | unknown }[] = []
    if (quantityPerItem) {
      data.push({
        label: 'Pallet Size',
        value: quantityPerItem,
      })
    }
    if (productDetailData.mcs) {
      data.push({
        label: 'MCS Reference',
        value: productDetailData.mcs,
      })
    }
    return data
  }, [productDetailData.mcs, quantityPerItem])

  if (!componentType) {
    return null
  }
  const customDataConfig = getGenericComponentConfigs(componentType, productDetailData)

  return (
    <FullWidthWrapper>
      <SectionCardTop>
        <Typography variant="h6">Technical Details</Typography>
      </SectionCardTop>
      <SectionCardBottom>
        <TableContainer>
          <Table>
            <tbody>
              {customDataConfig.map((config, index) => {
                const isEvenIndex = index % 2 === 0
                if (!isEvenIndex) return null

                const formattedValue = config.formatter?.format
                  ? config.formatter.format(productDetailData[config.source])
                  : productDetailData[config.source]

                const nextConfig = customDataConfig[index + 1]
                const nextFormattedValue =
                  nextConfig && nextConfig.formatter?.format
                    ? nextConfig.formatter.format(productDetailData[nextConfig.source])
                    : productDetailData[nextConfig?.source]

                return (
                  <tr
                    key={index}
                    style={{
                      backgroundColor: index % 4 === 0 ? '#f9f9f9' : 'white', // Light gray and white alternation
                      border: '1px solid #eee',
                      borderCollapse: 'collapse',
                    }}
                  >
                    <TableCell isHeader={true}>{config.label}</TableCell>
                    <TableCell isHeader={false}>
                      {formattedValue} {config.unit}
                    </TableCell>
                    {nextConfig && (
                      <>
                        <TableCell isHeader={true}>{nextConfig.label}</TableCell>
                        <TableCell isHeader={false}>
                          {nextFormattedValue} {nextConfig.unit}
                        </TableCell>
                      </>
                    )}
                  </tr>
                )
              })}

              {optionalDataConfig.length > 0 && (
                <tr style={{ backgroundColor: '#f9f9f9', border: '1px solid #eee', borderCollapse: 'collapse' }}>
                  <TableCell isHeader={true}>{optionalDataConfig[0].label}</TableCell>

                  <TableCell isHeader={false}>{optionalDataConfig[0].value}</TableCell>
                  {optionalDataConfig[1] && (
                    <>
                      <TableCell isHeader={true}>{optionalDataConfig[1].label}</TableCell>

                      <TableCell isHeader={false}>{optionalDataConfig[1].value}</TableCell>
                    </>
                  )}
                </tr>
              )}
            </tbody>
          </Table>
        </TableContainer>
      </SectionCardBottom>
    </FullWidthWrapper>
  )
}

export default TechnicalDetails3

import { makeStyles, Tooltip } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartOutlined'
import { push } from 'connected-react-router'
import { openOrderCart as openOrderCartAction } from 'ducks/orderComponents'
import { Button } from 'opensolar-ui'

import React from 'react'
import { useTranslate } from 'react-admin'
import { connect, useDispatch } from 'react-redux'
import { DialogHelper } from 'util/misc'

const StyledIconButton = withStyles({
  root: {
    color: '#1890ff',
    padding: 0,
  },
})(IconButton)

const useStyles = makeStyles(() => ({
  startIcon: {
    margin: 0,
  },
}))

const CreateEmptyOrderButton = (props) => {
  const translate = useTranslate()
  const classes = useStyles()
  const dispatch = useDispatch()

  return (
    <div
      style={{
        textAlign: 'center',
        borderRadius: '3px',
        marginRight: 5,
      }}
    >
      {props.layout > 3 ? (
        <Button
          classes={classes}
          variant="outlined"
          color="default"
          size="small"
          startIcon={<ShoppingCartIcon />}
          onClick={(event) => {
            if (props.enableHardwareOrdering) {
              dispatch(push('/shop'))
            } else {
              props.openOrderCart(true)
              DialogHelper.afterOpen()
            }
            event.preventDefault()
          }}
        >
          <span>{translate('Create Segen Quote')}</span>
        </Button>
      ) : (
        <StyledIconButton
          style={{
            overflow: 'inherit',
            minWidth: 10,
            lineHeight: '33px',
            width: 35,
            padding: 5,
          }}
          onClick={(event) => {
            if (props.enableHardwareOrdering) {
              dispatch(push('/shop'))
            } else {
              props.openOrderCart(true)
              DialogHelper.afterOpen()
            }
            event.preventDefault()
          }}
        >
          <Tooltip title={translate('Create Segen Quote')} enterDelay={300}>
            <ShoppingCartIcon style={{ fill: '#1890ff' }} />
          </Tooltip>
        </StyledIconButton>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({})
const dispatchActions = {
  openOrderCart: openOrderCartAction,
}
export default connect(mapStateToProps, dispatchActions)(CreateEmptyOrderButton)

import { Accordion, AccordionDetails, AccordionSummary, FormControlLabel } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreOutlined'
import { Checkbox } from 'opensolar-ui'
import React, { ChangeEvent, useCallback, useState } from 'react'
import { PaymentOptionDataType } from 'types/paymentOptions'
import PaymentOptionCheckList from './PaymentOptionChecklist'

type PropTypes = {
  title: 'Most Used' | 'Favorite' | 'All Payment Options' | 'Phoenix Financial'
  systemUuid: string
  systemPaymentOptionOverride: number[]
  availablePaymentOptions: PaymentOptionDataType[]
  paymentOptions: PaymentOptionDataType[]
  isArchived: boolean
  pricingIsLocked: boolean
  disabled: boolean
  onChange: (
    event: ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    pmt: PaymentOptionDataType
  ) => void
  handleSungageItcChange: (e: ChangeEvent<HTMLInputElement>, pmt: PaymentOptionDataType) => void
  pendingPmtId: number | undefined
  getSelectedIndex: (pmt: PaymentOptionDataType) => number
  defaultSungageIdsForActve18Mo: number[]
  phoenixProductTitle?: string
  phoenixProductId?: string
  onPhoenixToggleChange?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, phoenixProduct: string) => void
}

const TieredPaymentOptionSection: React.FC<PropTypes> = (props) => {
  const getInitialCheckedVal = (): boolean => {
    if (
      props.title === 'Phoenix Financial' &&
      props.systemPaymentOptionOverride?.length &&
      props.paymentOptions?.length
    ) {
      let foundPhoenix = false
      props.paymentOptions?.forEach((pmt) => {
        if (props.systemPaymentOptionOverride.includes(pmt.id)) foundPhoenix = true
      })
      return foundPhoenix
    } else return false
  }

  const [isExpanded, setIsExpanded] = useState<boolean>(props.title === 'Most Used' || props.title === 'Favorite')
  const [checked, setChecked] = useState<boolean>(getInitialCheckedVal())

  const stopClickPropagation = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setChecked(!checked)
    if (props.onPhoenixToggleChange && props.phoenixProductId) props.onPhoenixToggleChange(e, props.phoenixProductId)
    e.stopPropagation()
  }

  const renderPhoenixHeader = () => {
    return (
      <div>
        <FormControlLabel
          control={<Checkbox id="show-customer-checkbox" checked={checked} onClick={stopClickPropagation} />}
          label={<div style={{ fontSize: '12px' }}>{`Include Phoenix ${props.phoenixProductTitle}`}</div>}
        />
      </div>
    )
  }

  const renderNothingHere = useCallback(() => {
    const getMessage = () => {
      switch (props.title) {
        case 'Most Used':
          return 'You do not have enough recent projects to show your most used payment options.'
        case 'Favorite':
          return 'Your account does not have any favorite payment options'
        case 'All Payment Options':
          return 'Your account does not seem to have any valid payment options.'
        default:
          return 'No payment options to show here'
      }
    }

    return (
      <div>
        <p className="small">{getMessage()}</p>
      </div>
    )
  }, [props.title])

  // don't render payment options if the section is expanded. Especially the "all payment options" section can be very long
  return (
    <Accordion defaultExpanded={isExpanded} onChange={() => setIsExpanded(!isExpanded)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${props.title}-content`}
        id={`${props.title}-content`}
      >
        {props.title === 'Phoenix Financial' ? renderPhoenixHeader() : props.title}
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {props.paymentOptions?.length === 0 && renderNothingHere()}
          {isExpanded && (
            <PaymentOptionCheckList
              systemUuid={props.systemUuid}
              disabled={props.disabled}
              pricingIsLocked={props.pricingIsLocked}
              isArchived={props.isArchived}
              paymentOptionsOverride={props.paymentOptions}
              availablePaymentOptions={props.availablePaymentOptions}
              onChange={props.onChange}
              handleSungageItcChange={props.handleSungageItcChange}
              pendingPmtId={props.pendingPmtId}
              defaultSungageIdsForActve18Mo={props.defaultSungageIdsForActve18Mo}
              getSelectedIndex={props.getSelectedIndex}
              paymentOption={undefined}
              isChecked={false}
              selectedIndex={0}
              tieredStyles={true}
            />
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  )
}
export default TieredPaymentOptionSection

import type LineItemType from 'pages/ordering/OrderLineItem'
import { useMemo } from 'react'

const useProductSpecsData = (lineItem: LineItemType | undefined) => {
  return useMemo(() => {
    let data = lineItem?.data?.data
    if (!data || !lineItem) return undefined

    const productDetailData = JSON.parse(data)

    return productDetailData
  }, [lineItem?.data?.data])
}

export default useProductSpecsData

import TableBody from '@material-ui/core/TableBody'
import { orderSelectors } from 'ducks/orderComponents'
import { orgSelectors } from 'ducks/orgs'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import type { HardwareOrderProjectRecord } from '../../type'
import useProjectTable from '../useProjectTable'
import ProjectTableRow from './TableRow'

type ProjectTableContentPropsType = {
  data: HardwareOrderProjectRecord[]
}

const ProjectTableContent = ({ data }: ProjectTableContentPropsType) => {
  const [projectTableStore, projectTablePresenter] = useProjectTable()
  const selectedProjectIds = useSelector(orderSelectors.getUnconfirmedOrderProjectIds)
  const selectedHardwareSupplierKey = useSelector(orgSelectors.getSelectedHardwareSupplierFilterKey)
  if (!selectedHardwareSupplierKey) {
    // TODO: show animation. Hardware supplier not selected
    return null
  }
  return (
    <TableBody>
      {data.map((record) => {
        return (
          <ProjectTableRow
            key={record.id}
            selectedHardwareSupplierKey={selectedHardwareSupplierKey}
            checked={selectedProjectIds.includes(record.id)}
            projectTableStore={projectTableStore}
            projectTablePresenter={projectTablePresenter}
            record={record}
          />
        )
      })}
    </TableBody>
  )
}

export default memo(ProjectTableContent)

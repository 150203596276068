import ListResultNotFound from 'elements/list/ListResultNotFound'
import { Button } from 'opensolar-ui'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { StudioOtherType, StudioSystemType } from 'types/global'
import { doNotTranslate } from 'util/misc'

const useStyles = makeOpenSolarStyles((theme) => ({
  buttonWrapper: {
    width: '100%',
    textAlign: 'center',
  },
  button: {
    margin: 20,
  },
}))

type Props = {
  system: StudioSystemType
  onRemoveOptimizerAndRetry: (optimizers: StudioOtherType[]) => void
}

export const InverterNoResultsFound = ({ system, onRemoveOptimizerAndRetry }: Props) => {
  const classes = useStyles()
  const optimizers = system.others().filter((c) => c.other_component_type === 'dc_optimizer')
  return (
    <>
      <ListResultNotFound />
      {optimizers.length && (
        <div className={classes.buttonWrapper}>
          <Button className={classes.button} variant="contained" onClick={() => onRemoveOptimizerAndRetry(optimizers)}>
            {doNotTranslate('Remove Optimizer and retry')}
          </Button>
        </div>
      )}
    </>
  )
}

export default (componentType) => {

  switch (componentType) {
    case 'module':
      return 'Power (W)'
    case 'inverter':
      return'Kilowatts (kW)'
    case 'battery':
      return 'Kilowatt-hour (kWh)'
  }
}

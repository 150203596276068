export var getMfaStatusFromLoginError = function (err) {
    var result = undefined;
    if (!err || !err.body || !err.body.mfa_status)
        return result;
    else if (err.body.has_sms_device || err.body.has_totp_device || err.body.mfa_status === 'mfa_required')
        return 'check_required';
    else if (err.body.mfa_status === 'mfa_required_but_not_configured')
        return 'config_required';
    else
        return result;
};

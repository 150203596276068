import React from 'react'
import type { ComponentTypes } from 'types/selectComponent'
import type { FilterComponentNode, FilterComponentRendererType, FilterGenericPropsType } from '../type'
import VoltageAndCurrentFilter, { FilterOptionsType } from './VoltageAndCurrentFilter'

export const VOLTAGE_AND_CURRENT_FILTER_KEY = 'voltage_to_current_rating'

const maybeCreateVoltageAndCurrentFilterNode = ({
  componentType,
  RendererComponent,
}: {
  componentType?: ComponentTypes
  RendererComponent: FilterComponentRendererType<FilterOptionsType>
}): FilterComponentNode | undefined => {
  if (componentType !== 'other') {
    return
  }

  const FilterComponent = (props: FilterGenericPropsType) => (
    <VoltageAndCurrentFilter
      filterKey={VOLTAGE_AND_CURRENT_FILTER_KEY}
      RendererComponent={RendererComponent}
      {...props}
    />
  )

  return {
    key: VOLTAGE_AND_CURRENT_FILTER_KEY,
    component: FilterComponent,
  }
}

export default maybeCreateVoltageAndCurrentFilterNode

import { all, put, select, take, takeEvery } from 'redux-saga/effects'
import restClient from 'restClient'
import { ReduxActionType } from 'types/global'
import { ProjectType } from 'types/projects'
import { RootState } from 'types/state'
import { authSelectors } from './auth'
import {
  GET_FEATURE_CONFIG_FAIL,
  GET_FEATURE_CONFIG_SUCCESS,
  featureConfigSelectors,
  getFeatureConfig,
} from './featureConfig'

const API_URL = window.API_ROOT + '/api'
const restClientInstance = restClient(API_URL)

export const SWITCH_NEM2_TO_NEM3 = 'SWITCH_NEM2_TO_NEM3'

export type TariffType = {}

export default function reducer(state: TariffType = {}, action: ReduxActionType): TariffType {
  switch (action.type) {
    case SWITCH_NEM2_TO_NEM3:
      return {
        ...state,
      }
    default:
      return state
  }
}

export const tariffActions = {
  switchNEM2toNEM3(): ReduxActionType {
    return {
      type: SWITCH_NEM2_TO_NEM3,
      payload: {},
    }
  },
}

export const doGetTariffData = (nem3TariffUrl: string) => {
  return new Promise((resolve, reject) => {
    restClientInstance('CUSTOM_GET', 'custom', {
      url: nem3TariffUrl,
    })
      .then((response: any) => {
        resolve(response.data)
      })
      .catch((err: any) => {
        console.log('err', err)
        reject(false)
      })
  })
}

const switchNEM2toNEM3 = async (project: ProjectType, orgId: number, configData: any) => {
  if (!('nem2_to_nem3' in configData))
    throw new Error('Error: "nem2_to_nem3" key not found in Feature Config: "nem3_to_nem2_utility_rate_mapping".')

  // If the proposed tariff is a NEM3 tariff rate, remove the NEM3 proposed rate and leave the proposed tariff as null.
  // We will then apply a NEM3 tariff as the current rate which will contain a tariff transition to appropriately set the
  // proposed tariff.
  const proposedTariffId = project.utility_tariff_proposed_or_guess?.id
  const form = window.projectForm
  if (configData.nem2_to_nem3?.[proposedTariffId]) {
    form.batch(() => {
      form.registerField(`utility_tariff_proposed`, () => {}, {})
      form.mutators.updateField('utility_tariff_proposed', null)

      form.registerField(`utility_tariff_proposed_data`, () => {}, {})
      form.mutators.updateField('utility_tariff_proposed_data', null)

      form.registerField(`utility_tariff_proposed_custom`, () => {}, {})
      form.mutators.updateField('utility_tariff_proposed_custom', null)
    })
  }

  const tariffId = project.utility_tariff_or_guess?.id
  const nem3TariffId = configData.nem2_to_nem3?.[tariffId]
    ? configData.nem2_to_nem3[tariffId]
    : configData.nem2_to_nem3.default

  if (nem3TariffId) {
    const nem3TariffUrl = `${API_URL}/orgs/${orgId}/utility_tariffs/${nem3TariffId}/`
    const nem3TariffData = await doGetTariffData(nem3TariffUrl)

    form.batch(() => {
      form.registerField(`utility_tariff_current_custom`, () => {}, {})
      form.mutators.updateField('utility_tariff_current_custom', null)

      form.registerField(`utility_tariff_current`, () => {}, {})
      form.mutators.updateField('utility_tariff_current', nem3TariffUrl)

      form.registerField(`utility_tariff_current_data`, () => {}, {})
      form.mutators.updateField('utility_tariff_current_data', nem3TariffData)

      form.registerField(`utility_tariff_or_guess`, () => {}, {})
      form.mutators.updateField('utility_tariff_or_guess', nem3TariffData)
    })
  }
}

export function* getNEM2toNEM3(): any {
  if (!window.projectForm) throw new Error('Error: cannot load project form data.')

  const project = window.projectForm.getState().values
  const orgId = yield select(authSelectors.getOrgId)

  yield put(getFeatureConfig('nem3_to_nem2_utility_rate_mapping'))
  yield take([GET_FEATURE_CONFIG_SUCCESS, GET_FEATURE_CONFIG_FAIL])

  const configData = yield select((state: RootState) =>
    featureConfigSelectors.getFeatureConfigData(state, 'nem3_to_nem2_utility_rate_mapping')
  )

  switchNEM2toNEM3(project, orgId, configData)
}

export function* tariffSagas() {
  yield all([takeEvery(SWITCH_NEM2_TO_NEM3, getNEM2toNEM3)])
}

import React from 'react'
import { BooleanInput, NumberInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import DependentInput from '../../../elements/input/DependentInput'
import { toggle_inputs_and_extra_fields } from '../common'
import { styles } from '../styles.js'

export const DownPayment = ({ resource }) => {
  const form = useForm()
  const formState = useFormState()
  const isVariablesEnabled = formState.values['variable_down_payment_enabled']
  return (
    <div style={Boolean(isVariablesEnabled) ? styles.loanWrapperWithBoxShadow : styles.loanWrapper}>
      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', height: 50 }}>
        <BooleanInput
          resource={resource}
          formControlLabelStyle={styles.formControlLabelStyle}
          onChange={(value) => {
            if (!value) {
              const selectedToggle =
                toggle_inputs_and_extra_fields.find((a) => a.key === 'variable_down_payment_enabled') || {}
              const extraFields = selectedToggle.extraFields || []
              extraFields.forEach((field) => {
                form.mutators.updateField(field, null)
              })
            }
          }}
          label="Down Payment"
          name={'variable_down_payment_enabled'}
          source={'variable_down_payment_enabled'}
        />
      </div>
      <DependentInput dependsOn="variable_down_payment_enabled" value={true}>
        <p>
          Enter a value here if you intend to collect a portion of the system cost in cash, and finance the remainder.
        </p>
        <div style={{ display: 'inline-block', alignItems: 'center', flexWrap: 'wrap' }}>
          <NumberInput
            resource={resource}
            style={styles.inputFieldLessWide}
            inputLabelProps={{
              shrink: true,
            }}
            label="Down Payment"
            endAdornment="%"
            helperText="of System Cost"
            source={'variable_down_payment_percentage'}
            name={'variable_down_payment_percentage'}
          />
          <NumberInput
            step={1}
            resource={resource}
            style={styles.inputFieldLessWide}
            inputLabelProps={{
              shrink: true,
            }}
            label={'Minimum'}
            startAdornment="$"
            source={'variable_down_payment_min'}
            name={'variable_down_payment_min'}
          />
          <NumberInput
            step={1}
            resource={resource}
            style={styles.inputFieldLessWide}
            inputLabelProps={{
              shrink: true,
            }}
            label={'Maximum'}
            startAdornment="$"
            source={'variable_down_payment_max'}
            name={'variable_down_payment_max'}
          />
        </div>
      </DependentInput>
    </div>
  )
}

import Typography from '@material-ui/core/Typography'
import { orgSelectors } from 'ducks/orgs'
import type LineItemType from 'pages/ordering/OrderLineItem'
import ProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/projectOrderPresenter'
import StatusMessage, { StatusMessageTypography } from 'pages/ordering/StatusMessage'
import type { StockLevelStatus, StockLevelType } from 'pages/ordering/type'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import type { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

function getCurrentStockLevel(lineItem: LineItemType): StockLevelType | undefined {
  return lineItem.stockLevels?.find((stock: StockLevelType) => stock.in_stock)
}

function getRestockingMessage(lineItem: LineItemType): string {
  let restockingInfo = ''
  lineItem.stockLevels?.forEach((stock: StockLevelType) => {
    if (!stock.in_stock && stock.planned_date) {
      const date = new Date(stock.planned_date)
      const dateString = `${date.getDate()}/${date.getMonth() + 1}`
      restockingInfo += ` ${dateString} (${stock.quantity} units)`
    }
  })
  if (restockingInfo) {
    restockingInfo = 'Restocking on ' + restockingInfo
  }
  return restockingInfo
}

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  warning: {
    color: theme.warningYellow,
    lineHeight: '16px',
  },
}))

const LowStockMessage = memo(({ currentStockQuantity }: { currentStockQuantity: number | undefined }) => {
  const classes = useStyles()

  return (
    <div>
      <Typography variant="body1" className={classes.warning}>
        Only {currentStockQuantity} left in stock
      </Typography>
      <Typography variant="body1" className={classes.warning}>
        Order soon
      </Typography>
    </div>
  )
})

const LowStockWarning = ({ lineItem }: { lineItem: LineItemType }) => {
  const distributorRegistrationRequired = useSelector(orgSelectors.distributorRegistrationRequired)
  const supplier = useSelector(orgSelectors.getSelectedHardwareSupplier)

  const stockLevelStatus: StockLevelStatus = useMemo(() => {
    return ProjectOrderPresenter.getStockLevelStatusFromLineItem(lineItem)
  }, [lineItem])

  if (supplier === HardwareSupplierEnum.Segen && lineItem.data?.distributors?.find((d) => !!d.refresh_date) === null) {
    return null
  }
  if (stockLevelStatus === 'loading' || stockLevelStatus === 'available' || !!distributorRegistrationRequired) {
    return null
  }

  const currentStockQuantity = getCurrentStockLevel(lineItem)?.quantity
  const restockingMessage = getRestockingMessage(lineItem)
  if (stockLevelStatus === 'low_stock') {
    return <LowStockMessage currentStockQuantity={currentStockQuantity} />
  }

  if (stockLevelStatus === 'partial_available') {
    return (
      <div>
        {lineItem.quantity != null && currentStockQuantity != null && (
          <StatusMessageTypography type="error" message={`${currentStockQuantity} available`} />
        )}
        {supplier !== HardwareSupplierEnum.SolarOutlet && (
          <StatusMessageTypography type="info" message={restockingMessage} />
        )}
      </div>
    )
  }

  if (stockLevelStatus === 'out_of_stock') {
    return (
      <div>
        <StatusMessage status={stockLevelStatus} />
        {supplier !== HardwareSupplierEnum.SolarOutlet && (
          <StatusMessageTypography type="info" message={restockingMessage} />
        )}
      </div>
    )
  }

  if (stockLevelStatus === 'not_available') {
    return <StatusMessage status={stockLevelStatus} />
  }

  return null
}

export default LowStockWarning

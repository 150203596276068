import { useMediaQuery } from '@material-ui/core'
import { BaseThemeType, styled } from 'opensolar-ui'
import { TagData } from 'types/projects'
import { StyledChip } from '../styles'

interface PropTypes {
  tags: TagData[]
}

const ChipWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const TagsDisplay: React.FC<PropTypes> = ({ tags }) => {
  const isMobile = useMediaQuery((theme: BaseThemeType) => theme.breakpoints.down('xs'))
  const maxChips = isMobile ? 1 : 2
  return !!tags?.length ? (
    <ChipWrapper>
      {tags.slice(0, maxChips).map((tag) => (
        <StyledChip label={tag.title} variant="outlined" />
      ))}
      {tags.length > maxChips && <>+{tags.length - maxChips}</>}
    </ChipWrapper>
  ) : (
    <>{'－'}</>
  )
}

export default TagsDisplay

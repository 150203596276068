import { ReduxActionType } from 'types/global'

export const OPEN_EAGLEVIEW_FORM = 'OPEN_EAGLEVIEW_FORM'
export const CLOSE_EAGLEVIEW_FORM = 'CLOSE_EAGLEVIEW_FORM'
export const UPDATE_EAGLEVIEW_FORM_SUBMITTED = 'UPDATE_EAGLEVIEW_FORM_SUBMITTED'

export type EagleViewReducerType = {
  projectId: number | null
  showDialog: boolean
  address: string | null
  city: string | null
  zip: string | null
  state: string | null
  lon: number | null
  lat: number | null
  submittedProjectIds: number[]
  report_type?: number | null
}

const initialState: EagleViewReducerType = {
  projectId: null,
  showDialog: false,
  address: null,
  city: null,
  zip: null,
  state: null,
  lon: null,
  lat: null,
  submittedProjectIds: [],
  report_type: null,
}

export default function reducer(state = initialState, action: ReduxActionType) {
  switch (action.type) {
    case OPEN_EAGLEVIEW_FORM:
      return {
        ...state,
        showDialog: true,
        projectId: action.payload.projectId,
        address: action.payload.address,
        city: action.payload.city,
        zip: action.payload.zip,
        state: action.payload.state,
        lon: action.payload.lon,
        lat: action.payload.lat,
        report_type: action.payload.report_type,
      }
    case CLOSE_EAGLEVIEW_FORM:
      return {
        ...state,
        showDialog: false,
        projectId: null,
        address: null,
        city: null,
        zip: null,
        state: null,
        lon: null,
        lat: null,
        report_type: null,
      }

    case UPDATE_EAGLEVIEW_FORM_SUBMITTED:
      return { ...state, submittedProjectIds: state.submittedProjectIds.concat([action.payload.submittedProjectId]) }
    default:
      return { ...state }
  }
}

export const openEagleViewDialog = (payload) => {
  return {
    type: OPEN_EAGLEVIEW_FORM,
    payload: payload,
  }
}

export const closeEagleViewDialog = () => {
  return {
    type: CLOSE_EAGLEVIEW_FORM,
  }
}

export const setEagleViewOrderSubmitted = (projectId: number) => {
  return {
    type: UPDATE_EAGLEVIEW_FORM_SUBMITTED,
    payload: {
      submittedProjectId: projectId,
    },
  }
}

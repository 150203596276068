export default async function lookup(token: string, orgId: string, searchText: string) {
  const response = await fetch(
    `${window.API_ROOT}/api/orgs/${orgId}/maps/address/postcoder/?search_term=${searchText}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return await response.json()
}

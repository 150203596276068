import {
  FinanceValidationSetsCreate,
  FinanceValidationSetsEdit,
  FinanceValidationSetsList,
} from './FinanceValidationSets'

export default {
  list: FinanceValidationSetsList,
  create: FinanceValidationSetsCreate,
  edit: FinanceValidationSetsEdit,
}

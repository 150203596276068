import { makeStyles } from '@material-ui/core'
import { Button } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    width: 280,
    marginTop: 0,
    minWidth: 210,
    padding: '0.5em 1em 1em 1em',
    textAlign: 'center',
  },
  button: {
    width: '100%',
  },
}))

export const SsoLoginContent = ({
  brandingName,
  onLogin,
  Logo,
  Actions,
}: {
  brandingName: string
  onLogin(): void
  Logo: React.ReactNode
  Actions: React.ReactNode
}) => {
  const translate = useTranslate()
  const classes = useStyles()
  const isLoading = useSelector((state: any) => Boolean(state.admin && state.admin.loading))
  return (
    <div className={classes.wrapper}>
      <div>{Logo}</div>
      <Button
        classes={{ root: classes.button }}
        disabled={Boolean(isLoading)}
        variant="outlined"
        onClick={(e) => {
          e.preventDefault()
          onLogin()
        }}
      >
        <span style={{ textTransform: 'none' }}>
          {translate('Login with %{name}', {
            name: brandingName,
          })}
        </span>
      </Button>

      {Actions}
    </div>
  )
}

import React, { memo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    fontSize: '12px',
    borderRadius: '4px',
    padding: '3px 10px',
    backgroundColor: theme.palette.error.dark,
    color: '#ffffff',
  },
}))

const PercentageDiscountTag = ({ showUpTo, percentage }: { showUpTo: boolean; percentage: string }) => {
  const classes = useStyles()
  return <div className={classes.wrapper}>{showUpTo ? `UP TO ${percentage}% OFF` : `${percentage}% OFF`}</div>
}

export default memo(PercentageDiscountTag)

import useBrandingConfig from 'branding/useBrandingConfig'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

const useBrandingMetaData = () => {
  const org = useSelector(orgSelectors.getOrg)
  const autoLoginProject = useSelector(authSelectors.getAutoLoginProject)
  const brandingConfig = useBrandingConfig()

  useEffect(() => {
    if (brandingConfig.meta.title) {
      document.title = brandingConfig.meta.title
    } else if (org && !autoLoginProject) {
      document.title = org.name
    }

    if (brandingConfig.meta.favicon) {
      const favicon = document.getElementById('favicon')
      if (favicon) {
        favicon.setAttribute('href', `/images/${brandingConfig.meta.favicon}`)
      }
    }
  }, [org, autoLoginProject, brandingConfig])
}

export default useBrandingMetaData

import { Grid, InputLabel, Link, MenuItem, Select, TextField } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import { useNotify, useTranslate } from 'ra-core'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { getCountryCodeOptions, getDefaultCountryCode, getIso2FromCountryCode } from './util'

type PropTypes = {
  goToSmsCode: () => void
  cancel?: () => void
}

const CountrCodeParser = /\(\+?(.+)\)(.+)/

const MfaPhoneNumber: React.FC<PropTypes> = (props) => {
  const rolePhone: string | undefined = useSelector(authSelectors.getCurrentRole)?.phone
  const iso2 = useSelector(authSelectors.getCurrentOrgCountry)

  const notify = useNotify()
  const translate = useTranslate()

  const [phoneNumber, setPhoneNumber] = useState<string | undefined>()
  const [selectedCode, _setSelectedCode] = useState<string | undefined>()
  const [selectedIso2, _setSelectedIso2] = useState<string | undefined>()

  useEffect(() => {
    if (phoneNumber !== undefined) return
    if (rolePhone) {
      const match = CountrCodeParser.exec(rolePhone || '')
      if (match) {
        // If we managed to parse the country code out of the user's phone number, use it preferentially
        let countryCode = match[1].trim()
        if (countryCode.charAt(0) !== '+') countryCode = '+' + countryCode
        _setSelectedCode(countryCode)
        setPhoneNumber(match[2].trim())
        _setSelectedIso2(getIso2FromCountryCode(countryCode))
        return
      }
    }
    // Otherwise, use the country code from the user's organization
    setPhoneNumber(rolePhone || '')
    _setSelectedCode(iso2 ? getDefaultCountryCode(iso2) : undefined)
    _setSelectedIso2(iso2)
  }, [phoneNumber, rolePhone, iso2])

  const [countryCodeOptions] = useState(getCountryCodeOptions())
  const [showCountryCodeLookup, setShowCountryCodeLookup] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const validateCode = (code: string | undefined) => {
    if (!code) return code
    if (code.charAt(0) !== '+') return '+' + code
    else return code
  }

  const setSelectedCode = (code: string) => {
    _setSelectedCode(validateCode(code))
    _setSelectedIso2(getIso2FromCountryCode(code))
    console.debug('setSelectedCode: ', code, getIso2FromCountryCode(code))
  }
  const setSelectedIso2 = (iso2: string) => {
    _setSelectedIso2(iso2)
    _setSelectedCode(validateCode(getDefaultCountryCode(iso2)))
    console.debug('setSelectedIso2: ', getDefaultCountryCode(iso2), iso2)
  }

  useEffect(() => {
    logAmplitudeEvent('mfa_phone_page_viewed', {})
  }, [])

  const onSubmit = (e) => {
    e.preventDefault()

    if (!phoneNumber || phoneNumber.length < 8) {
      notify('Invalid or missing phone number', 'warning')
    } else if (!selectedCode) {
      notify('Country Code is required')
    } else {
      let code = selectedCode
      if (code.charAt(0) !== '+') code = '+' + code
      setLoading(true)
      const restClientInstance = restClient(window.API_ROOT + '/api')
      restClientInstance('CUSTOM_POST', 'custom', {
        url: `mfa/devices/sms/`,
        data: {
          phoneNumber: `${code}${phoneNumber}`,
        },
      })
        .then(() => {
          logAmplitudeEvent('mfa_sms_code_sent', {})
          props.goToSmsCode()
        })
        .catch((err: any) => {
          if (err.body?.detail === 'This phone number is ready activated') {
            notify('This phone number is already activated', 'warning')
            logAmplitudeEvent('mfa_phone_setup_already_active', {})
            return
          }
          console.warn(err)
          notify(
            translate('Unable to send verification code. Please check that your phone number is correct'),
            'warning'
          )
          logAmplitudeEvent('mfa_phone_setup_error', {})
        })
        .finally(() => setLoading(false))
    }
    return false
  }

  return (
    <div>
      <p>{translate('What phone number would you like us to use for your verification code?')}</p>
      <div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', marginBottom: '20px' }}>
          <div style={{ marginTop: '15px', display: 'flex', alignItems: 'flex-end' }}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                {showCountryCodeLookup ? (
                  <div>
                    <InputLabel shrink={true}>{translate('Country Code')}</InputLabel>
                    <Select value={selectedIso2} onChange={(e) => setSelectedIso2(e.target.value as string)} fullWidth>
                      {countryCodeOptions.map((opt) => (
                        <MenuItem value={opt.iso2}>{opt.display}</MenuItem>
                      ))}
                    </Select>
                  </div>
                ) : (
                  <TextField
                    id="countryCode"
                    onChange={(e) => setSelectedCode(e.target.value)}
                    value={selectedCode}
                    label={translate('Country Code')}
                  />
                )}
              </Grid>
              <Grid item xs={8}>
                <TextField
                  id="phoneNumber"
                  label={translate('Phone Number')}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  value={phoneNumber}
                  style={{ width: '100%' }}
                />
              </Grid>
            </Grid>
          </div>
          <Link
            onClick={() => setShowCountryCodeLookup(!showCountryCodeLookup)}
            style={{ marginTop: '10px', cursor: 'pointer', textAlign: 'left' }}
            color="textPrimary"
          >
            {translate(showCountryCodeLookup ? 'Enter County Code' : 'Show Country Code Options')}
          </Link>
        </div>
        <div style={{ marginTop: '15px', width: '100%' }}>
          <ProUXButton
            onClick={onSubmit}
            type="primary"
            label="Send Verification Code"
            loading={loading}
            fullWidth={true}
            submit={true}
          />
        </div>
        {props.cancel && (
          <div style={{ width: '100%', marginTop: '15px' }}>
            <ProUXButton label="Back" onClick={props.cancel} type="text" fullWidth={true} style={{ width: '100%' }} />
          </div>
        )}
      </div>
    </div>
  )
}
export default MfaPhoneNumber

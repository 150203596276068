import { makeStyles, Menu, MenuItem } from '@material-ui/core'
import ListIcon from '@material-ui/icons/ListOutlined'
import UiContainer from 'elements/UiContainer'
import { IconButton } from 'opensolar-ui'
import React from 'react'
import { changeListParams, useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { RootState } from 'types/state'
import { CUSTOM_REPORT_TITLE, detectSelectedReport, reports } from '../elements/ReportSelector'

const uiKey = 'projects.search'

const useStyles = makeStyles((theme) => ({
  iconButton: {
    width: 24,
    height: 24,
    margin: 5,
    [theme.breakpoints.down('sm')]: {
      '& svg': {
        width: 20,
        height: 20,
      },
    },
  },
}))

const ProjectListSelector = () => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const recentProjects = useSelector((state: RootState) => state.recentProjects)
  const classes = useStyles()
  const translate = useTranslate()
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  var selectedReport = detectSelectedReport(location.pathname, location.search, recentProjects)
  var reportsToShow = reports(recentProjects)

  if (selectedReport.title === CUSTOM_REPORT_TITLE) {
    reportsToShow.push(selectedReport)
  }
  const onChange = (payload) => {
    var report = reportsToShow.filter((r) => r.urls[0] === payload)[0]

    if (report.clearFiltersOnModel) {
      dispatch(
        changeListParams(report.clearFiltersOnModel, {
          sort: null,
          order: null,
          page: 1,
          perPage: 0,
          filter: {},
        })
      )
    }

    setTimeout(function () {
      handleClose()
      history.push(report.urls[0])
    }, 1000)
  }

  return (
    <UiContainer uiKey={uiKey + '.listButton'}>
      <div>
        <IconButton onClick={handleClick} className={classes.iconButton}>
          <ListIcon style={{ color: '#4d4d4d' }} />
        </IconButton>
        <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          {reportsToShow.map((report, index) => {
            return (
              <MenuItem key={report.title} onClick={() => onChange(report.urls[0])}>
                {translate(report.title)}
              </MenuItem>
            )
          })}
        </Menu>
      </div>
    </UiContainer>
  )
}

export default ProjectListSelector

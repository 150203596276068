import { useTranslate } from 'ra-core'
import { useMemo } from 'react'
import { taxNameForCountryIso2 } from 'util/misc'

interface BussinessIdLabelInfo {
  businessIdLabelName: string
  businessIdHelperText: string
}

export const useBusinessIdentifierLabel = (countryIso2: string | undefined): BussinessIdLabelInfo => {
  const translate = useTranslate()

  return useMemo(() => {
    if (countryIso2 === 'AU') {
      return {
        businessIdLabelName: 'Australian business number (ABN)',
        businessIdHelperText: 'ABN must contain 11 digits.',
      }
    } else if (countryIso2 === 'GB') {
      return {
        businessIdLabelName: 'CRN',
        businessIdHelperText: '6 digits (e.g. 123456) or 2 letters valid prefix followed by 6 digits (e.g. AC123456)',
      }
    } else if (countryIso2 === 'NZ') {
      return {
        businessIdLabelName: 'NZBN',
        businessIdHelperText: 'NZBN must contain 11 digits',
      }
    } else {
      return {
        businessIdLabelName: translate('%{taxName} Number', { taxName: taxNameForCountryIso2(countryIso2) }),
        businessIdHelperText: translate('Enter the company identifier'),
      }
    }
  }, [countryIso2])
}

import { useCallback, useState } from 'react'
import { useDataProvider, useNotify } from 'react-admin'

const useAutoCreatePrimaryWarehouse = (): {
  isLoading: boolean
  autoCreatePrimaryWarehouse(): Promise<void>
} => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const autoCreatePrimaryWarehouse = useCallback(async () => {
    setIsLoading(true)
    try {
      await dataProvider
        .CUSTOM_POST(
          'inventory_locations/enable_inventory_if_required',
          {
            url: 'inventory_locations/enable_inventory_if_required/',
          },
          {
            onSuccess: () => {},
          }
        )
        .finally(() => {
          setIsLoading(false)
        })
    } catch (e) {
      notify('Failed to auto create primary warehouse')
    }
  }, [])

  return {
    isLoading,
    autoCreatePrimaryWarehouse,
  }
}

export default useAutoCreatePrimaryWarehouse

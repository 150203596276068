// @ts-nocheck
import React from 'react'
import { useTranslate } from 'react-admin'

const SunlightTransferLoadingText = () => {
  const translate = useTranslate(null)
  return (
    <i style={{ textAlign: 'center' }}>
      {translate(
        'After clicking "Proceed", it will take 15-30 seconds to prepare the application. You do not need to wait for this. You may close the window after hitting "Proceed".'
      )}
    </i>
  )
}

export default SunlightTransferLoadingText

import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

export const useRoleFormStyles = makeOpenSolarStyles(
  {
    fieldCont: {
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
    },
    input: { minWidth: 256, flexGrow: 1, flexShrink: 1 },
  },
  { name: 'RoleForm' }
)

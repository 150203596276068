import { Button, OsButtonProps } from 'opensolar-ui'
import React, { useCallback, useState } from 'react'
import { CRUD_DELETE, useDelete, useNotify } from 'react-admin'
import { ReactElement } from 'react-markdown/lib/react-markdown'
import DeleteConfirmModal from './DeleteConfirmModal'

interface PropTypes {
  btnLabel: string | ReactElement
  title: string
  resource: string
  record: any
  onSuccess?: () => void
  onFailure?: () => void
  btnProps: OsButtonProps
  confirmMode?: string
  isDisabled?: boolean
}

const DeleteButton: React.FC<PropTypes> = (props) => {
  const { btnLabel, title, resource, record, onSuccess, onFailure, btnProps, confirmMode, isDisabled = false } = props
  const notify = useNotify()
  const [deleting, setDeleting] = useState<boolean>(false)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [deleteOne, { loading }] = useDelete(resource, record?.id, record, {
    action: CRUD_DELETE,
    onSuccess: (response) => {
      notify('ra.notification.deleted', 'info', { smart_count: 1 })
      if (onSuccess) onSuccess()
      setDeleting(false)
      setShowDialog(false)
    },
    onFailure: (error: any) => {
      notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning')
      if (onFailure) onFailure()
      setDeleting(false)
    },
    undoable: false,
  })

  const onClick = (e: any) => {
    if (confirmMode === 'dialog') {
      setShowDialog(true)
    } else {
      handleDelete(e)
    }
  }
  const handleDelete = useCallback(
    (e) => {
      setDeleting(true)
      deleteOne()
    },
    [deleteOne]
  )
  return (
    <>
      <Button onClick={onClick} variant="text" color="error" {...btnProps} disabled={isDisabled}>
        {btnLabel}
      </Button>
      {confirmMode === 'dialog' && (
        <DeleteConfirmModal handleDelete={handleDelete} open={showDialog} setOpen={setShowDialog} title={title} />
      )}
    </>
  )
}

export default DeleteButton

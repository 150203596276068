import { TextField } from '@material-ui/core'
import { FileCopyOutlined, InfoOutlined } from '@material-ui/icons'
import { orgSelectors } from 'ducks/orgs'
import { Button } from 'opensolar-ui'
import React from 'react'
import { NotificationType, useNotify, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { styles as makeStyles } from './styles'
import { OrgConnection } from './types'

interface PropTypes {
  record: OrgConnection
}

const copyUrl = (
  url: string,
  notify: (message: string, type?: NotificationType) => void,
  translate: (value: string) => string
) => {
  navigator.clipboard.writeText(url).then(
    function () {
      notify(translate('Link copied to clipboard'), 'success')
    },
    function (err) {
      console.warn(err)
      notify(translate('Failed to copy link to clipboard'), 'warning')
    }
  )
}

const formURL = (id) => {
  return `${window.location.origin}/#/connected_orgs?accept_connection=${id}`
}

const ShareableConnectionLink: React.FC<PropTypes> = ({ record }) => {
  const translate = useTranslate()
  const styles = makeStyles()
  const notify = useNotify()
  const org = useSelector(orgSelectors.getOrg)
  const url = formURL(record.id)

  return (
    <div className={styles.shareLinkContainer}>
      <div className={styles.info}>
        <InfoOutlined />
        <p className={styles.shareLinkText}>Share this link with {record?.org_name} to activate the connection.</p>
      </div>
      <div className={styles.info}>
        <TextField className={styles.shareLinkField} variant="outlined" size="small" defaultValue={url} />
        <Button variant="contained" startIcon={<FileCopyOutlined />} onClick={() => copyUrl(url, notify, translate)}>
          {translate('Copy')}
        </Button>
      </div>
    </div>
  )
}

export default ShareableConnectionLink

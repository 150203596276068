var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import createStandardIcon from './createStandardIcon';
var AvatarIconSvg = function (props) {
    var height = props.height, width = props.width;
    return (_jsxs("svg", __assign({ width: width, height: height, viewBox: "0 0 ".concat(height, " ").concat(width), fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("circle", { cx: "10", cy: "10", r: "10", fill: '#ECECED' }), _jsx("path", { d: "M11.9789 7.07965C11.9789 8.2282 11.0929 9.15929 9.99992 9.15929C8.90698 9.15929 8.02097 8.2282 8.02097 7.07965C8.02097 5.93109 8.90698 5 9.99992 5C11.0929 5 11.9789 5.93109 11.9789 7.07965Z", fill: "#7F7D83" }), _jsx("path", { d: "M14 11.9027V13.2301C13.5271 14.2494 11.9155 15 9.99992 15C8.08438 15 6.4727 14.2494 6 13.2301V11.9027C6 10.9252 7.17895 9.86726 9.87368 9.86726C12.5684 9.86726 14 10.9252 14 11.9027Z", fill: "#7F7D83" })] })));
};
export var AvatarIcon = createStandardIcon(AvatarIconSvg);

import { Button, FormLabel, styled } from 'opensolar-ui'
import { useCallback, useMemo, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import AddressAutocomplete from 'resources/projects/elements/AddressAutocomplete'
import { doNotTranslate } from 'util/misc'
import { EnaTextField } from './EnaFields'
import { EnaInputData, parseInputSchema } from './SchemaFields'

export const AddressFields: React.FunctionComponent<any> = ({ schema, fieldName }) => {
  const { mainAddress, installationPostCode } = useFormState().values.installerCustomerDetails as any
  const [showSearchField, setShowSearchField] = useState(!mainAddress || !installationPostCode)
  const [isManualInput, setIsManualInput] = useState(false)
  const apiKeyGoogle = useSelector((state: any) =>
    state.auth && state.auth.api_key_google ? state.auth.api_key_google : ''
  )
  const form = useForm()

  const handlePlaceSelected = useCallback((placeAsFields) => {
    form.change(`${fieldName}.mainAddress`, placeAsFields.address)
    form.change(`${fieldName}.installationPostCode`, placeAsFields.zip)
    setShowSearchField(false)
  }, [])

  const schemas = useMemo(() => {
    return ['mainAddress', 'installationPostCode', 'mpan'].reduce((acc, i) => {
      const parsedSchema = parseInputSchema(schema.properties[i]) as EnaInputData
      acc[i] = {
        parsedSchema,
        path: `${fieldName}.${i}`,
      }
      return acc
    }, {} as Record<string, { parsedSchema: EnaInputData; path: string }>)
  }, [])

  return (
    <div>
      <EnaTextField
        key={schemas.mpan.path}
        {...schemas.mpan}
        parsedSchema={{
          ...schemas.mpan.parsedSchema,
          required: false,
          validation: '^\\d{13}(\\d{8})?$',
          errorMessage: 'Invalid MPAN',
          tooltip:
            'In GB, we have an MPAN lookup service. This means, if you do not provide an MPAN in the application, we will attempt to look it up for you. In the case where we find it, we will add it to the application for you and proceed. If we cannot find it, the application will return "MPAN not found". If you get this notification when submitting, you must resubmit the application with the MPAN included.  The MPAN lookup service is not available in Northern Ireland.',
        }}
      />
      {!showSearchField && (
        <div>
          <EnaTextField
            key={schemas.mainAddress.path}
            {...schemas.mainAddress}
            parsedSchema={{
              ...schemas.mainAddress.parsedSchema,
              tooltip:
                'If the property has a house number, enter it like this: 21 Victoria Street. If the property has a name, enter it like this (the comma is highly recommended): The Old Barn, Victoria Street.',
            }}
          />
          <EnaTextField
            key={schemas.installationPostCode.path}
            {...schemas.installationPostCode}
            parsedSchema={{
              ...schemas.installationPostCode.parsedSchema,
            }}
          />
        </div>
      )}
      {showSearchField && (
        <>
          <FormLabel style={{ whiteSpace: 'normal' }}>{doNotTranslate('Address')}</FormLabel>
          <StyledAddressField>
            <AddressAutocomplete
              onPlaceSelected={handlePlaceSelected}
              api_key_google={apiKeyGoogle}
              componentRestrictions={null}
              placeholder={'Enter new project address...'}
              orgCountry={'GB'}
              types={[]}
            />
          </StyledAddressField>
        </>
      )}
      <Button
        type="button"
        variant="text"
        color="info"
        size="small"
        onClick={() => {
          if (!showSearchField) {
            setShowSearchField(true)
          }
          if (!isManualInput && showSearchField) {
            setIsManualInput(true)
            setShowSearchField(false)
          }
        }}
      >
        {!showSearchField ? 'Search different address' : !isManualInput ? 'Enter address manually' : ''}
      </Button>
    </div>
  )
}

const StyledAddressField = styled('div')(({ theme }) => ({
  '& #Address-Autocomplete-Input': {
    height: 16,
    backgroundColor: 'white',
    fontWeigth: 100,
    ...theme.mixins.outlineStandard,
    ...theme.typography,
    borderWidth: 1,
  },
}))

import { FC } from 'react'
import { CodeBlock } from '../CodeBlock'
import ComponentPage from '../ComponentPage'
import { SelectBasic, SelectColors, SelectSizes, SelectVariants } from './SelectUsage'

const SelectUsage_code = require('!!raw-loader!./SelectUsage').default

export const SelectDemo: FC = () => {
  return (
    <ComponentPage
      name={'Select'}
      import="import { Select } from 'opensolar-ui'"
      description={'Select is used as an input field that allows a user to select an option from a set of choices.'}
      links={{ mui4: 'https://v4.mui.com/components/selects/', mui5: 'https://mui.com/material-ui/react-select/' }}
    >
      <CodeBlock
        name="Select Basic Usage"
        code={SelectUsage_code}
        codePart={1}
        description="How to setup base structure"
      >
        <SelectBasic />
      </CodeBlock>
      <CodeBlock
        name="Select Sizes"
        code={SelectUsage_code}
        codePart={2}
        description="How to modify the size of the input"
      >
        <SelectSizes />
      </CodeBlock>
      <CodeBlock
        name="Select Variants"
        code={SelectUsage_code}
        codePart={3}
        description="How to modify the variants of the input"
      >
        <SelectVariants />
      </CodeBlock>
      <CodeBlock
        name="Select Colors"
        code={SelectUsage_code}
        codePart={4}
        description="How to modify the colors of the input"
      >
        <SelectColors />
      </CodeBlock>
    </ComponentPage>
  )
}

import { logAmplitudeEvent } from 'amplitude/amplitude'
import { DateInput } from 'elements/input/DateInput'
import { memo, useState } from 'react'
import { useNotify } from 'react-admin'
import Skeleton from 'react-loading-skeleton'
import { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import useUpdateForecastDate from '../hooks/useUpdateForecastDate'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  root: {
    '& .MuiInputBase-root': {
      fontSize: theme.typography.subtitle2.fontSize,
    },
  },
  forecastDateBackground: {
    background: theme.ValidityPalette.warning.background,
  },
}))

const ForecastDateInput = ({
  projectId,
  pickupDate,
  disabled,
  onSuccess,
  isHistorical,
}: {
  projectId: number
  pickupDate?: string
  disabled?: boolean
  onSuccess?(): void
  isHistorical?: boolean
}) => {
  const classes = useStyles()
  const notify = useNotify()

  const [internalValue, setInternalValue] = useState(pickupDate)
  const { updatingForecastDate, updateProjectForecastDate } = useUpdateForecastDate()

  const handleChange = async (date) => {
    setInternalValue(date)
    await updateProjectForecastDate({ projectId, date })
    notify('Forecast date saved', 'success')
    onSuccess?.() // TODO: handle if failed
    logAmplitudeEvent('generic_button_clicked', {
      source: 'forecast_date',
      context: 'inventory_forecast_date',
    })
  }

  return updatingForecastDate ? (
    <Skeleton />
  ) : (
    <div className={isHistorical ? classes.forecastDateBackground : undefined}>
      <DateInput
        size="small"
        value={internalValue}
        onChange={handleChange}
        inputVariant="outlined"
        className={classes.root}
        disabled={disabled}
      />
    </div>
  )
}

export default memo(ForecastDateInput)

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import React, { useEffect } from 'react'

type PropTypes = {
  isOpen: boolean
  onConfirm: () => void
  close: () => void
  loading: boolean
}

const ReapplicationConfirm: React.FC<PropTypes> = (props: PropTypes) => {
  useEffect(() => {
    if (props.isOpen) {
      logAmplitudeEvent('reapplication_confirm_dialog_shown', {})
    }
  }, [props.isOpen])

  const doClose = () => {
    logAmplitudeEvent('reapplication_confirm_dismissed', {})
    props.close()
  }

  const doConfirm = () => {
    logAmplitudeEvent('reapplication_confirmed', {})
    props.onConfirm()
  }

  return (
    <Dialog open={props.isOpen}>
      <DialogTitle>Re-Applying for Financing</DialogTitle>
      <DialogContent>
        Our records show that you have already applied for this financing product and were previously not approved for
        financing. Before continuing, please confirm that you understand you will be submitting a new application.
      </DialogContent>
      <DialogActions>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ margin: '10px' }}>
            <ProUXButton label="Dismiss" onClick={doClose} type="secondary" />
          </div>
          <div style={{ margin: '10px' }}>
            <ProUXButton
              label="I want to start a new application"
              onClick={doConfirm}
              type="primary"
              loading={props.loading}
            />
          </div>
        </div>
      </DialogActions>
    </Dialog>
  )
}
export default ReapplicationConfirm

// @ts-nocheck
import { makeStyles } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined'
import { Button } from 'opensolar-ui'
import React, { memo, useCallback } from 'react'
import { useTranslate } from 'react-admin'
import { ViewTypes } from 'types/view'
import getIconPathByMapType from 'util/iconByMapType'

type PropTypes = {
  view: ViewTypes
  isActiveView: boolean
  anchorEl: HTMLElement | null
  setPopover: Function
  handleSelectView: (viewUuid: string) => void
}
const useStyles = makeStyles((theme: any) => ({
  buttonWrapper: {
    pointerEvents: 'auto',
    display: 'inline-block',
    width: 85,
    marginRight: 5,
    marginTop: 10,
  },
  buttonIcon: {
    maxHeight: 35,
    maxWidth: 35,
    marginLeft: 0,
  },
  button: {
    minWidth: 85,
    width: 85,
    height: 40,
    borderRadius: 0,
    color: 'rgb(0,0,0)',
    background: 'rgba(255, 255, 255, 0.5)',
    '&:hover': {
      background: 'rgba(250,250,250,0.9)',
    },
    '&:first-child': {
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    },
    '&:last-child': {
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
    },
  },
  activeButton: {
    minWidth: 85,
    width: 85,
    height: 40,
    borderRadius: 0,
    color: 'rgb(0,0,0)',
    background: 'rgb(255,255,255)',
    '&:hover': {
      background: 'rgba(250,250,250,0.9)',
    },
    '&:first-child': {
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    },
    '&:last-child': {
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
    },
  },
  buttonLabel: {
    textTransform: 'none',
  },
}))

const useDecoratorStyles = makeStyles((theme: any) => ({
  wrapper: {
    width: 85,
    display: 'inline-block',
    background: theme.blue,
    '&:first-child': {
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    },
  },
  label: {
    fontSize: 10,
    verticalAlign: 'middle',
  },
  icon: {
    width: 14,
    verticalAlign: 'middle',
    marginRight: 5,
  },
}))

const ShowCustomerDecorator = memo(() => {
  const translate = useTranslate()
  const classes = useDecoratorStyles()
  return (
    <div className={classes.wrapper}>
      <VisibilityIcon className={classes.icon} />
      <span className={classes.label}>{translate('Customer')}</span>
    </div>
  )
})

const ToolbarViewButton: React.FC<PropTypes> = ({ view, isActiveView, handleSelectView, anchorEl, setPopover }) => {
  // const [anchorEl, setPopover] = React.useState<HTMLElement | null>(null)
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      try {
        if (!isActiveView) {
          handleSelectView(view.uuid)
        }

        var dummyObject = new window.THREE.Object3D()
        dummyObject.type = 'ViewDummy'
        dummyObject.userData = view
        dummyObject.userData.excludeFromExport = true

        dummyObject.toolsActive = function () {
          return {
            translateXY: false,
            translateZ: false,
            scaleXY: false,
            scaleZ: false,
            scale: false, //legacy
            rotate: false,
            delete: false,
          }
        }

        dummyObject.canDelete = function () {
          if (window.Designer.controlMode && window.Designer.controlMode !== 'both') {
            return 'Unable to delete a view while in align-map mode '
          } else if (window.ViewHelper.views.length > 1) {
            return true
          } else {
            return 'Unable to delete the last remaining view'
          }
        }

        dummyObject.confirmBeforeDelete = function () {
          return 'Are you sure you want to delete this view?'
        }

        dummyObject.onDeleteOverride = function () {
          //Call this instead of deleting the dummy object
          //window.ViewHelper.deleteView(window.ViewHelper.getViewByUuid(activeViewUuid), window.editor)
          window.editor.execute(new window.RemoveViewCommand(view.uuid, window.ViewHelper))
        }

        window.editor.select(dummyObject)
      } catch (err) {
        console.log(err)
      }
      setPopover(event.currentTarget.parentElement, view.uuid)
    },
    [isActiveView, anchorEl]
  )

  const classes = useStyles()
  return (
    <>
      <div className={classes.buttonWrapper}>
        {view.show_customer && <ShowCustomerDecorator />}
        <Button
          startIcon={<img alt="" className={classes.buttonIcon} src={getIconPathByMapType(view.mapType)} />}
          classes={{ root: isActiveView ? classes.activeButton : classes.button, label: classes.buttonLabel }}
          key={view.uuid}
          onClick={handleClick}
        >
          <span>{view.labelMinor}</span>
        </Button>
      </div>
    </>
  )
}

export default ToolbarViewButton

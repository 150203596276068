import { makeStyles, useMediaQuery } from '@material-ui/core'
import { Button } from 'opensolar-ui'
import React, { useMemo } from 'react'
import { useTranslate } from 'react-admin'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    borderRadius: 5,
    margin: '20px auto',
    width: 250,
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.54)',
    border: '1px solid rgba(0, 0, 0, 0.3)',
  },
}))

const NotAvailable = () => {
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'))
  const isMobileDevice = useMemo(() => window.Utils?.isTouchDevice(), [])
  return (
    <div className={classes.container}>
      <span>{translate('No projects created. Enter an address to create your first project')}</span>
      <p>{translate('OR')}</p>
      {!isSmall && !isMobileDevice && (
        <Button variant="contained" color="primary" onClick={() => history.push('/home?tour=fuji&step=start')}>
          <span>{translate('Start TOUR')}</span>
        </Button>
      )}
    </div>
  )
}

export default NotAvailable

import { ConditionFactory, SystemContext } from 'Designer/designRules/types'
import { BaseConditionDef } from '.'
import { checkScope, CheckScopeDef } from '../utils'

export type CheckSessionDataDef = BaseConditionDef &
  CheckScopeDef & {
    type: 'check-session-data'
  }

export const condition_checkSessionData: ConditionFactory<CheckSessionDataDef> = (def) => {
  return {
    getProjectFields: () => [],
    getStudioSignals: () => [],
    check: (context: SystemContext) => {
      const systemRuleData = context.sessionData[context.systemRuleKey] || {}
      return checkScope(systemRuleData, def)
    },
  }
}

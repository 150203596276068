import React from 'react'
import ContactList from './ContactList'
import { ContactCreate, ContactEdit } from './form/ContactPageForm'

const ContactTitle = ({ record, translate, style }) => {
  return (
    <span style={style}>
      {translate('Contact')} {record ? `"${record.display}"` : ''}
    </span>
  )
}

export default {
  list: ContactList,
  edit: ContactEdit,
  create: ContactCreate,
  options: {
    create: {
      title: 'Create Contact',
      subtitle: null,
      breadCrumb: 'Create Contact',
    },
    list: {
      title: 'Customer Contacts',
      subtitle:
        'Add and edit your customer contacts on this page. Customer contacts can be applied to your new and existing projects.',
      breadCrumb: 'Contacts',
    },
    edit: {
      title: <ContactTitle />,
      subtitle: null,
      breadCrumb: 'Edit Contact',
    },
  },
}

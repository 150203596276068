import { makeStyles } from '@material-ui/core'
import { EditOutlined } from '@material-ui/icons'
import { Button } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { ReactElement, ReactNode, useState } from 'react'
import { Theme } from 'types/themes'
import { getProjectSectionStyles } from '../styles'
import EditModal, { ModalProps } from './EditModal'

const useStyles = makeStyles(
  (theme: Theme) => ({
    editBtn: {
      fontSize: '12px !important',
      height: '10px !important',
      margin: '0 !important',
    },
    editIcon: {
      height: 15,
    },
    hideBtn: {
      opacity: 0,
      [theme.breakpoints.down('xs')]: {
        opacity: 1,
      },
    },
  }),
  { name: 'HoverEditWrapper' }
)

interface PropTypes {
  titleElement: ReactElement
  children: ReactElement
  className: string
  modalProps: ModalProps
  emptyToggle?: {
    label: ReactNode
    className?: string
    startIcon?: ReactNode
  }
}

const HoverEditWrapper: React.FC<PropTypes> = ({ titleElement, children, className, modalProps, emptyToggle }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const sectionStyles = getProjectSectionStyles()
  const [showEditBtn, setShowEditBtn] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)

  return (
    <>
      <div
        onMouseOver={() => {
          setShowEditBtn(true)
        }}
        onMouseLeave={() => {
          setShowEditBtn(false)
        }}
        className={className}
      >
        <div className={sectionStyles.row}>
          {titleElement}
          <Button
            size="small"
            color="info"
            startIcon={<EditOutlined className={classes.editIcon} />}
            className={`${classes.editBtn} ${!showEditBtn ? classes.hideBtn : ''}`}
            variant="text"
            onClick={() => {
              setShowEditModal(!showEditModal)
            }}
          >
            {translate('Edit')}
          </Button>
        </div>
        {emptyToggle ? (
          <Button
            variant="text"
            color="info"
            size="small"
            startIcon={emptyToggle.startIcon}
            className={emptyToggle.className}
            onClick={() => {
              setShowEditModal(!showEditModal)
            }}
          >
            {emptyToggle.label}
          </Button>
        ) : (
          children
        )}
      </div>
      <EditModal
        open={showEditModal}
        {...modalProps}
        onClose={() => {
          setShowEditModal(false)
        }}
      />
    </>
  )
}

export default HoverEditWrapper

import { Box } from 'opensolar-ui'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import useCommonStyles from './useCommonStyles'

const useStyles = makeOpenSolarStyles(() => ({
  container: {
    padding: '2px 0px',
  },
}))

interface InverterQuantityBlockProps {
  quantity: number
}

const InverterQuantityBlock: React.FC<InverterQuantityBlockProps> = ({ quantity }) => {
  const commonClasses = useCommonStyles()
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <span className={commonClasses.mainText}>{`${quantity}x`}</span>
    </Box>
  )
}

export default InverterQuantityBlock

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        backgroundColor: theme.palette.grey[300],
        display: 'flex',
    },
}); }, { name: 'RaPlaceholder' });
var Placeholder = function (props) {
    var classes = useStyles(props);
    return (_jsx("div", __assign({ className: classnames(props.className, classes.root) }, { children: "\u00A0" })));
};
export default Placeholder;

import React from 'react'
import { useLocation } from 'react-router-dom'
import { doNotTranslate } from 'util/misc'

const NearMapLoginMessage = () => {
  const location = useLocation()
  if (location.search?.indexOf('NEARMAP_USER_EMAIL_CLASH') !== -1) {
    return (
      <div>
        <p>
          {doNotTranslate(
            'Your email already exists on OpenSolar. Please enter your OpenSolar password to enable Nearmap on OpenSolar for your current user login.'
          )}
        </p>
        <p>
          {doNotTranslate(
            'If you haven\'t generated an OpenSolar password before, you can click the "forgot password" link to generate a new one.'
          )}{' '}
        </p>
      </div>
    )
  } else if (location.search?.indexOf('NEARMAP_ORG_NAME_CLASH') !== -1) {
    return (
      <div>
        <p>
          {doNotTranslate(
            'Your Nearmap Business Name is already taken on OpenSolar. If you do not have access to it, please write to us on '
          )}
          <a href="mailto:support@opensolar.com">support@opensolar.com</a>
        </p>
        <p>
          {doNotTranslate(
            'Otherwise, if your business created this OpenSolar account, ask an existing admin to invite you to the team as an admin, set your password, then enter your OpenSolar email and password below to upgrade to Nearmap on OpenSolar.'
          )}
        </p>
      </div>
    )
  } else {
    return <p>{doNotTranslate('Please login with your OpenSolar credentials to continue.')}</p>
  }
}

export default NearMapLoginMessage

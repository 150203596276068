"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.current_org = void 0;
var action_1 = require("../core/action");
var state_1 = require("../core/state");
exports.current_org = {
    // State
    id: new state_1.Value(undefined),
    // Actions
    reload: new action_1.Action(),
};

import { logAmplitudeEvent } from 'amplitude/amplitude'
import React, { useCallback, useMemo, useState } from 'react'
import ReplaceItemDialog from './ReplaceItemDialog'
import createPlaceholderNode from './placeholderNode'
import type { FlowController, ReplaceItemNode } from './types'

const useReplaceItemFlow = (): FlowController => {
  const [node, setNode] = useState<ReplaceItemNode>(() => createPlaceholderNode())
  const [isOpen, setOpen] = useState<boolean>(false)

  const goTo = useCallback(({ node }: { node: ReplaceItemNode }) => {
    logAmplitudeEvent('hardware_replace_item_flow', {
      step: node.key,
    })
    setNode(node)
  }, [])

  // const goToStep = useCallback(({step}:{step:ReplaceItemNodeKey })=>{
  //   const createNode = REPLACE_ITEM_FLOW[step]
  //   const node = createNode()
  // },[])

  const showFlow = useCallback(() => {
    logAmplitudeEvent('hardware_replace_item_flow', {
      step: 'start',
    })
    setOpen(true)
  }, [])

  const closeFlow = useCallback(() => {
    logAmplitudeEvent('hardware_replace_item_flow', {
      step: 'end',
    })
    setOpen(false)
  }, [])

  const Dialog = useMemo(() => <ReplaceItemDialog isOpen={isOpen} onClose={closeFlow} node={node} />, [isOpen, node])
  const flowController = useMemo(() => ({ goTo, showFlow, closeFlow }), [])

  return {
    flowController,
    Dialog,
  }
}

export default useReplaceItemFlow

import { ComponentTypes } from 'types/selectComponent'
import type { FilterComponentRendererType, FilterGenericPropsType, OptionGenericType } from '../type'
import DistributorBrandManufacturerFilter from './DistributorBrandManufacturerFilter'
import FeatureConfigManufacturerFilter from './FeatureConfigManufacturerFilter'

export const EXHIBITOR_FILTER_KEY = 'exhibitor_org_id' // used for feature config manufacturer filter
export const MANUFACTURER_FILTER_KEY = 'manufacturer' // used for feature config manufacturer filter

export type ManufacturerFilterOptionsType = OptionGenericType<string>[]

export type ManufacturerFilterProps = FilterGenericPropsType & {
  RendererComponent: FilterComponentRendererType<ManufacturerFilterOptionsType>
  componentType: ComponentTypes
}

export const defaultManufacturerOption = {
  title: 'All',
  value: '',
  id: 'default',
}

const ManufacturerFilter = (props: ManufacturerFilterProps) => {
  const isUsingDistributorBrand = props.allFilterValues['require_distributor'] !== undefined
  if (isUsingDistributorBrand) {
    return <DistributorBrandManufacturerFilter {...props} />
  } else {
    return <FeatureConfigManufacturerFilter {...props} />
  }
}

export default ManufacturerFilter

import { authSelectors } from 'ducks/auth'
import React from 'react'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import SungageConnectRoleDialogContent from 'resources/integrations/sungage/SungageConnectRoleDialogContent'
import { useGetOrgIdFromPaymentOptions } from '../utils'

type PropTypes = {
  paymentOptionId: number
  onClose: () => void
  integration: string
  systemUuid: string
}

const SungageConnectRole: React.FC<PropTypes> = (props) => {
  const project = useFormState().values
  const role = useSelector(authSelectors.getCurrentRole)
  const orgId = useGetOrgIdFromPaymentOptions(props.systemUuid, project.org_id, props.paymentOptionId)

  const onClose = () => {
    props.onClose()
  }

  const addPendingPmt = () => {
    const system = window.editor.getSystems()?.find((sys) => sys.uuid === props.systemUuid)
    if (system) {
      // clear cache
      if (system?.output?.hashed_args) {
        system.output.hashed_args = undefined
        system.payment_options.forEach((pmt) => {
          pmt.hashed_args = undefined
        })
      }
      window.Designer.requestSystemCalculations(system)
    }
    props.onClose()
  }

  return (
    <SungageConnectRoleDialogContent
      project={window.WorkspaceHelper.project}
      onClose={onClose}
      onSuccess={addPendingPmt}
      pendingPmtOrgId={orgId}
      roleToShow={role?.id !== project.assigned_role_data?.id ? project.assigned_role_data : undefined}
    />
  )
}
export default SungageConnectRole

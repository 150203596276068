"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.route = void 0;
var action_1 = require("../core/action");
var state_1 = require("../core/state");
exports.route = {
    // State
    route: new state_1.ShallowState(undefined),
    path: new state_1.Value(undefined),
    // Actions
    goto: new action_1.Action(),
};

import type BrandingConfig from './types'

const DefaultBrandingConfig: BrandingConfig = {
  name: 'opensolar',
  header: {
    headerState: 'standard',
  },
  meta: {
    favicon: 'favicon/favicon.png',
  },
}

export default DefaultBrandingConfig

import type { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useTableStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  tableHeaderContainer: {
    backgroundColor: theme.greyLight3,
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
  },
  rowWrapper: {
    minHeight: '48px',
    margin: 0,
    borderBottom: '1px solid #e7e7e7',
    padding: '0px 10px',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}))

export default useTableStyles

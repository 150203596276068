"use strict";
/**
 * This is a logger that allows call-sites to remain ignorant of whether logging is enabled, or at what level.
 * It also allows for group names to be added to the log messages.
 *
 * (Mostly) API-compatible with native `console` API.
 */
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Logger = void 0;
var Logger = /** @class */ (function () {
    function Logger(group, opts) {
        this.group = group;
        this.explicit_level = opts === null || opts === void 0 ? void 0 : opts.level;
        this.color = (opts === null || opts === void 0 ? void 0 : opts.color) || generateColor(group);
    }
    Object.defineProperty(Logger.prototype, "level", {
        get: function () {
            return this.explicit_level || window.os_loglevel;
        },
        enumerable: false,
        configurable: true
    });
    Logger.prototype.debug = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        this.do_log('debug', args);
    };
    Logger.prototype.log = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        this.do_log('info', args);
    };
    Logger.prototype.info = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        this.do_log('info', args);
    };
    Logger.prototype.warn = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        this.do_log('warn', args);
    };
    Logger.prototype.error = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        this.do_log('error', args);
    };
    Logger.prototype.do_log = function (level, args) {
        if (this.should_log(level)) {
            console[level].apply(console, this.format_args(level, args));
        }
    };
    Logger.prototype.should_log = function (level) {
        return LogLevelPrority.indexOf(level) >= LogLevelPrority.indexOf(this.level);
    };
    Logger.prototype.format_args = function (level, args) {
        var styledGroupName = "background: ".concat(this.color, "; border-radius: 2px; color: black; font-size:0.9em; padding: 1px 4px;");
        var prefix = "%c".concat(this.group).padEnd(16);
        var ret = __spreadArray([prefix, styledGroupName], args, true);
        if (this.log_stack || (this.log_stack === undefined && level === 'debug')) {
            ret.push(this.get_formatted_stack());
        }
        return ret;
    };
    Logger.prototype.get_formatted_stack = function () {
        var _a;
        var stack = ((_a = new Error().stack) === null || _a === void 0 ? void 0 : _a.split('\n')) || [];
        // console.log('stack: ', stack)
        stack.shift(); // remove first line 'Error:'
        stack = stack
            .map(function (s) { return s.trim().replace(/^at /, ''); }) // Trim whitespace and remove leading 'at' (Chrome)
            .map(function (s) {
            if (!navigator.userAgent.includes('Firefox'))
                return s;
            // Deal with FF formatting: ../packages/opensolar-sdk/dist/core/peers.js/</Peers.prototype.onMessage/Peers</<@http://localhost:31000/static/js/main.chunk.js:129349:18
            var ff_simple = s.match(/\/<\/[^<](.*)/);
            return ff_simple ? ff_simple[1] : s;
        })
            .filter(function (s) { return !s.startsWith('Logger.'); }); // Filter out call stack items from within this class
        var caller = stack[0];
        var caller_simple = caller.match(/^([\w.]+)/);
        if (caller_simple)
            caller = caller_simple[1];
        return { caller: caller, stack: stack };
    };
    return Logger;
}());
exports.Logger = Logger;
var LogLevelPrority = ['debug', 'log', 'info', 'warn', 'error'];
var generateColor = function (group) {
    var hash = hashString(group);
    var hue = hash % 360;
    return "hsl(".concat(hue, ", 100%, 50%)");
};
var hashString = function (str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
};
if (!window.os_loglevel)
    window.os_loglevel = (localStorage.getItem('os_loglevel') || 'info');

import type { ComponentTypes } from 'types/selectComponent'
import type { FilterComponentNode, FilterComponentRendererType, FilterGenericPropsType } from '../type'
import RatingFilter, { RatingFilterOptionsType } from './RatingFilter'
import {
  RATING_FILTER_GTE_KEY,
  RATING_FILTER_LTE_KEY,
  getDefaultValue,
  getRangeOptionsByComponentType,
} from './constants'

export const RATING_FILTER_NODE_KEY = 'rating'

const maybeCreateRatingFilterNode = ({
  RendererComponent,
  componentType,
}: {
  RendererComponent: FilterComponentRendererType<RatingFilterOptionsType>
  componentType?: ComponentTypes
}): FilterComponentNode | undefined => {
  if (!componentType) {
    return undefined
  }

  const rangeOptions = getRangeOptionsByComponentType(componentType)

  if (!rangeOptions) {
    return undefined
  }

  const FilterComponent = (props: FilterGenericPropsType) => {
    return (
      <RatingFilter
        gteFilterKey={RATING_FILTER_GTE_KEY}
        lteFilterKey={RATING_FILTER_LTE_KEY}
        rangeOptions={rangeOptions}
        RendererComponent={RendererComponent}
        {...props}
      />
    )
  }
  return {
    key: RATING_FILTER_NODE_KEY,
    component: FilterComponent,
    getDefaultValue,
  }
}

export default maybeCreateRatingFilterNode

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import Downshift from 'downshift';
import get from 'lodash/get';
import { Chip, TextField } from 'opensolar-ui';
import { FieldTitle, useInput, useSuggestions, warning } from 'ra-core';
import React, { isValidElement, useCallback, useEffect, useMemo, useRef } from 'react';
import AutocompleteSuggestionItem from './AutocompleteSuggestionItem';
import AutocompleteSuggestionList from './AutocompleteSuggestionList';
import InputHelperText from './InputHelperText';
/**
 * An Input component for an autocomplete field, using an array of objects for the options
 *
 * Pass possible options as an array of objects in the 'choices' attribute.
 *
 * By default, the options are built from:
 *  - the 'id' property as the option value,
 *  - the 'name' property an the option text
 * @example
 * const choices = [
 *    { id: 'M', name: 'Male' },
 *    { id: 'F', name: 'Female' },
 * ];
 * <AutocompleteArrayInput source="gender" choices={choices} />
 *
 * You can also customize the properties to use for the option name and value,
 * thanks to the 'optionText' and 'optionValue' attributes.
 * @example
 * const choices = [
 *    { _id: 123, full_name: 'Leo Tolstoi', sex: 'M' },
 *    { _id: 456, full_name: 'Jane Austen', sex: 'F' },
 * ];
 * <AutocompleteArrayInput source="author_id" choices={choices} optionText="full_name" optionValue="_id" />
 *
 * `optionText` also accepts a function, so you can shape the option text at will:
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 * ];
 * const optionRenderer = choice => `${choice.first_name} ${choice.last_name}`;
 * <AutocompleteArrayInput source="author_id" choices={choices} optionText={optionRenderer} />
 *
 * `optionText` also accepts a React Element, that will be cloned and receive
 * the related choice as the `record` prop. You can use Field components there.
 * Note that you must also specify the `matchSuggestion` prop
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 * ];
 * const matchSuggestion = (filterValue, choice) => choice.first_name.match(filterValue) || choice.last_name.match(filterValue);
 * const FullNameField = ({ record }) => <span>{record.first_name} {record.last_name}</span>;
 * <SelectInput source="gender" choices={choices} optionText={<FullNameField />} matchSuggestion={matchSuggestion} />
 *
 * The choices are translated by default, so you can use translation identifiers as choices:
 * @example
 * const choices = [
 *    { id: 'M', name: 'myroot.gender.male' },
 *    { id: 'F', name: 'myroot.gender.female' },
 * ];
 *
 * However, in some cases (e.g. inside a `<ReferenceInput>`), you may not want
 * the choice to be translated. In that case, set the `translateChoice` prop to false.
 * @example
 * <AutocompleteArrayInput source="gender" choices={choices} translateChoice={false}/>
 *
 * The object passed as `options` props is passed to the material-ui <TextField> component
 *
 * @example
 * <AutocompleteArrayInput source="author_id" options={{ color: 'secondary' }} />
 */
var AutocompleteArrayInput = function (props) {
    var allowDuplicates = props.allowDuplicates, allowEmpty = props.allowEmpty, classesOverride = props.classes, _a = props.choices, choices = _a === void 0 ? [] : _a, emptyText = props.emptyText, emptyValue = props.emptyValue, format = props.format, fullWidth = props.fullWidth, helperText = props.helperText, idOverride = props.id, inputOverride = props.input, isRequiredOverride = props.isRequired, label = props.label, limitChoicesToValue = props.limitChoicesToValue, margin = props.margin, matchSuggestion = props.matchSuggestion, metaOverride = props.meta, onBlur = props.onBlur, onChange = props.onChange, onFocus = props.onFocus, _b = props.options, _c = _b === void 0 ? {} : _b, suggestionsContainerProps = _c.suggestionsContainerProps, labelProps = _c.labelProps, InputProps = _c.InputProps, options = __rest(_c, ["suggestionsContainerProps", "labelProps", "InputProps"]), _d = props.optionText, optionText = _d === void 0 ? 'name' : _d, _e = props.optionValue, optionValue = _e === void 0 ? 'id' : _e, parse = props.parse, resource = props.resource, setFilter = props.setFilter, shouldRenderSuggestionsOverride = props.shouldRenderSuggestions, source = props.source, suggestionLimit = props.suggestionLimit, _f = props.translateChoice, translateChoice = _f === void 0 ? true : _f, validate = props.validate, _g = props.variant, variant = _g === void 0 ? 'filled' : _g, disabled = props.disabled, rest = __rest(props, ["allowDuplicates", "allowEmpty", "classes", "choices", "emptyText", "emptyValue", "format", "fullWidth", "helperText", "id", "input", "isRequired", "label", "limitChoicesToValue", "margin", "matchSuggestion", "meta", "onBlur", "onChange", "onFocus", "options", "optionText", "optionValue", "parse", "resource", "setFilter", "shouldRenderSuggestions", "source", "suggestionLimit", "translateChoice", "validate", "variant", "disabled"]);
    warning(isValidElement(optionText) && !matchSuggestion, "If the optionText prop is a React element, you must also specify the matchSuggestion prop:\n<AutocompleteInput\n    matchSuggestion={(filterValue, suggestion) => true}\n/>\n        ");
    var classes = useStyles(props);
    var inputEl = useRef();
    var anchorEl = useRef();
    var _h = useInput(__assign({ format: format, id: idOverride, input: inputOverride, meta: metaOverride, onBlur: onBlur, onChange: onChange, onFocus: onFocus, parse: parse, resource: resource, source: source, validate: validate }, rest)), id = _h.id, input = _h.input, isRequired = _h.isRequired, _j = _h.meta, touched = _j.touched, error = _j.error;
    var values = input.value || [];
    var _k = React.useState(''), filterValue = _k[0], setFilterValue = _k[1];
    var getSuggestionFromValue = useCallback(function (value) { return choices.find(function (choice) { return get(choice, optionValue) === value; }); }, [
        choices,
        optionValue,
    ]);
    var selectedItems = useMemo(function () { return values.map(getSuggestionFromValue); }, __spreadArray(__spreadArray([], values, true), [getSuggestionFromValue], false));
    var _l = useSuggestions({
        allowDuplicates: allowDuplicates,
        allowEmpty: allowEmpty,
        choices: choices,
        emptyText: emptyText,
        emptyValue: emptyValue,
        limitChoicesToValue: limitChoicesToValue,
        matchSuggestion: matchSuggestion,
        optionText: optionText,
        optionValue: optionValue,
        selectedItem: selectedItems,
        suggestionLimit: suggestionLimit,
        translateChoice: translateChoice,
    }), getChoiceText = _l.getChoiceText, getChoiceValue = _l.getChoiceValue, getSuggestions = _l.getSuggestions;
    var handleFilterChange = useCallback(function (eventOrValue) {
        var event = eventOrValue;
        var value = event.target ? event.target.value : eventOrValue;
        setFilterValue(value);
        if (setFilter) {
            setFilter(value);
        }
    }, [setFilter, setFilterValue]);
    // We must reset the filter every time the value changes to ensure we
    // display at least some choices even if the input has a value.
    // Otherwise, it would only display the currently selected one and the user
    // would have to first clear the input before seeing any other choices
    useEffect(function () {
        handleFilterChange('');
    }, __spreadArray(__spreadArray([], values, true), [handleFilterChange], false));
    var handleKeyDown = useCallback(function (event) {
        // Remove latest item from array when user hits backspace with no text
        if (selectedItems.length && !filterValue.length && event.key === 'Backspace') {
            var newSelectedItems = selectedItems.slice(0, selectedItems.length - 1);
            input.onChange(newSelectedItems.map(getChoiceValue));
        }
    }, [filterValue.length, getChoiceValue, input, selectedItems]);
    var handleChange = useCallback(function (item) {
        var newSelectedItems = !allowDuplicates && selectedItems.includes(item) ? __spreadArray([], selectedItems, true) : __spreadArray(__spreadArray([], selectedItems, true), [item], false);
        setFilterValue('');
        console.log('handleChange');
        input.onChange(newSelectedItems.map(getChoiceValue));
    }, [allowDuplicates, getChoiceValue, input, selectedItems, setFilterValue]);
    var handleDelete = useCallback(function (item) { return function () {
        var newSelectedItems = __spreadArray([], selectedItems, true);
        newSelectedItems.splice(newSelectedItems.indexOf(item), 1);
        input.onChange(newSelectedItems.map(getChoiceValue));
    }; }, [input, selectedItems, getChoiceValue]);
    // This function ensures that the suggestion list stay aligned to the
    // input element even if it moves (because user scrolled for example)
    var updateAnchorEl = function () {
        if (!inputEl.current) {
            return;
        }
        var inputPosition = inputEl.current.getBoundingClientRect();
        // It works by implementing a mock element providing the only method used
        // by the PopOver component, getBoundingClientRect, which will return a
        // position based on the input position
        if (!anchorEl.current) {
            anchorEl.current = { getBoundingClientRect: function () { return inputPosition; } };
        }
        else {
            var anchorPosition = anchorEl.current.getBoundingClientRect();
            if (anchorPosition.x !== inputPosition.x || anchorPosition.y !== inputPosition.y) {
                anchorEl.current = {
                    getBoundingClientRect: function () { return inputPosition; },
                };
            }
        }
    };
    var storeInputRef = function (input) {
        inputEl.current = input;
        updateAnchorEl();
    };
    var handleBlur = useCallback(function (event) {
        setFilterValue('');
        handleFilterChange('');
        input.onBlur(event);
    }, [handleFilterChange, input, setFilterValue]);
    var handleFocus = useCallback(function (openMenu) { return function (event) {
        openMenu(event);
        input.onFocus(event);
    }; }, [input]);
    var handleClick = useCallback(function (openMenu) { return function (event) {
        if (event.target === inputEl.current) {
            openMenu(event);
        }
    }; }, []);
    var shouldRenderSuggestions = function (val) {
        if (shouldRenderSuggestionsOverride !== undefined && typeof shouldRenderSuggestionsOverride === 'function') {
            return shouldRenderSuggestionsOverride(val);
        }
        return true;
    };
    console.log(selectedItems);
    return (_jsx(Downshift, __assign({ inputValue: filterValue, onChange: handleChange, selectedItem: selectedItems, 
        //@ts-ignore
        itemToString: function (item) { return getChoiceValue(item); } }, rest, { children: function (_a) {
            var _b, _c;
            var getInputProps = _a.getInputProps, getItemProps = _a.getItemProps, getLabelProps = _a.getLabelProps, getMenuProps = _a.getMenuProps, isOpen = _a.isOpen, suggestionFilter = _a.inputValue, highlightedIndex = _a.highlightedIndex, openMenu = _a.openMenu;
            var isMenuOpen = isOpen && shouldRenderSuggestions(suggestionFilter);
            var _d = getInputProps({
                onBlur: handleBlur,
                onFocus: handleFocus(openMenu),
                onClick: handleClick(openMenu),
                onKeyDown: handleKeyDown,
            }), idFromDownshift = _d.id, onBlur = _d.onBlur, onChange = _d.onChange, onFocus = _d.onFocus, ref = _d.ref, color = _d.color, size = _d.size, inputProps = __rest(_d, ["id", "onBlur", "onChange", "onFocus", "ref", "color", "size"]);
            return (_jsxs("div", __assign({ className: classes.container }, { children: [_jsx(TextField, __assign({ id: id, fullWidth: fullWidth, InputProps: {
                            inputRef: storeInputRef,
                            classes: {
                                root: classNames(classes.inputRoot, (_b = {},
                                    _b[classes.inputRootFilled] = variant === 'filled',
                                    _b)),
                                input: classes.inputInput,
                            },
                            startAdornment: (_jsx("div", __assign({ className: classNames((_c = {},
                                    _c[classes.chipContainerFilled] = variant === 'filled',
                                    _c)) }, { children: selectedItems.map(function (item, index) { return (_jsx(Chip, { tabIndex: -1, label: getChoiceText(item), className: classes.chip, onDelete: handleDelete(item), disabled: disabled }, index)); }) }))),
                            onBlur: onBlur,
                            onChange: function (event) {
                                handleFilterChange(event);
                                onChange(event);
                            },
                            onFocus: onFocus,
                        }, error: !!(touched && error), label: _jsx(FieldTitle, __assign({ label: label }, labelProps, { source: source, resource: resource, isRequired: typeof isRequiredOverride !== 'undefined' ? isRequiredOverride : isRequired })), InputLabelProps: getLabelProps({
                            htmlFor: id,
                        }), 
                        //@ts-ignore
                        helperText: _jsx(InputHelperText, { touched: touched, error: error, helperText: helperText }), variant: variant, margin: margin, color: color, size: size, disabled: disabled }, inputProps, options)), _jsx(AutocompleteSuggestionList, __assign({ isOpen: isMenuOpen, menuProps: getMenuProps({}, 
                        // https://github.com/downshift-js/downshift/issues/235
                        { suppressRefError: true }), 
                        //@ts-ignore
                        inputEl: inputEl.current, suggestionsContainerProps: suggestionsContainerProps }, { children: getSuggestions(suggestionFilter).map(function (suggestion, index) { return (_jsx(AutocompleteSuggestionItem, __assign({ suggestion: suggestion, index: index, highlightedIndex: highlightedIndex, isSelected: selectedItems.map(getChoiceValue).includes(getChoiceValue(suggestion)), filterValue: filterValue, getSuggestionText: getChoiceText }, getItemProps({
                            item: suggestion,
                        })), getChoiceValue(suggestion))); }) }))] })));
        } })));
};
var useStyles = makeStyles(function (theme) {
    var chipBackgroundColor = theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.09)' : 'rgba(255, 255, 255, 0.09)';
    return {
        root: {
            flexGrow: 1,
            height: 250,
        },
        container: {
            flexGrow: 1,
            position: 'relative',
        },
        paper: {
            position: 'absolute',
            zIndex: 1,
            marginTop: theme.spacing(1),
            left: 0,
            right: 0,
        },
        chip: {
            margin: theme.spacing(0.5, 0.5, 0.5, 0),
        },
        chipContainerFilled: {
            margin: '27px 12px 10px 0',
        },
        inputRoot: {
            flexWrap: 'wrap',
        },
        inputRootFilled: {
            flexWrap: 'wrap',
            '& $chip': {
                backgroundColor: chipBackgroundColor,
            },
        },
        inputInput: {
            width: 'auto',
            flexGrow: 1,
        },
        divider: {
            height: theme.spacing(2),
        },
    };
}, { name: 'RaAutocompleteArrayInput' });
export default AutocompleteArrayInput;

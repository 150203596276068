import { makeStyles, Tooltip } from '@material-ui/core'
import React, { FC, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  title: {
    padding: '0 10px',
  },
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 10,
    marginBottom: 10,
    padding: '0 30px',
  },
}))

const ENABLE_ON_CLICK = true

const COMPONENT_TYPE_AND_INDEX_MAPPER: { [key: string]: number } = {
  module: 0,
  inverter: 1,
  battery: 2,
  other: 3,
}

const EmptyLogo = ({ name = '', handleClick }: any) => (
  <div
    style={{
      height: 60,
      width: 100,
      fontSize: 12,
      lineHeight: '60px',
      overflow: 'hidden',
      display: 'inline-block',
      margin: '0 auto',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      padding: '0 10px',
      boxSizing: 'border-box',
      background: 'rgb(77,77,77)',
      verticalAlign: 'middle',
      borderRadius: 8,
      color: 'rgb(255,255,255)',
      cursor: ENABLE_ON_CLICK ? 'pointer' : 'unset',
    }}
    onClick={handleClick}
  >
    <span>{name}</span>
  </div>
)

const ExhibitorShowcase = ({ exhibitor, handleSelect, activeExhibitor }: any) => {
  const handleClick = useCallback(
    (activeExhibitorID) => {
      if (ENABLE_ON_CLICK) {
        handleSelect(exhibitor.id)
      }
    },
    [handleSelect]
  )
  return (
    <Tooltip title={exhibitor.name} enterDelay={300}>
      <div
        key={exhibitor.id}
        style={{
          height: 60,
          width: 100,
          display: 'inline-block',
          margin: '5px 10px 5px 0px',
          lineHeight: '60px',
          borderRadius: 8,
          border: '1px solid rgba(0,0,0,0.3)',
          cursor: ENABLE_ON_CLICK ? 'pointer' : 'unset',
        }}
        onClick={handleClick}
        className={'exhibitor-showcase' + (activeExhibitor === exhibitor.id ? ' selected' : '')}
      >
        {exhibitor.logo_public_url ? (
          <div
            style={{
              height: 60,
              width: 100,
              lineHeight: '60px',
              boxSizing: 'border-box',
              borderRadius: 8,
            }}
          >
            <img
              src={exhibitor.logo_public_url}
              style={{
                maxHeight: 50,
                maxWidth: 80,
                margin: 'auto 0px',
                verticalAlign: 'middle',
                borderRadius: 8,
              }}
            />
          </div>
        ) : (
          <EmptyLogo name={exhibitor.name} handleClick={handleClick} />
        )}
      </div>
    </Tooltip>
  )
}

type ExhibitedShowcasePropsType = {
  setFilters: Function
  filterValues: any
}

const ExhibitedShowcase: FC<ExhibitedShowcasePropsType> = ({ setFilters, filterValues }) => {
  const activeExhibitorRef = useRef(filterValues.exhibitor_org_id)
  activeExhibitorRef.current = filterValues.exhibitor_org_id
  const classes = useStyles()
  const { exhibitors, componentType } = useSelector((state: any) => {
    return {
      componentType: state.selectComponent?.componentType,
      exhibitors: (state.exhibitors && state.exhibitors.exhibitors) || [],
    }
  })
  const handleSelect = useCallback(
    (exhibitorId: number) => {
      if (activeExhibitorRef.current !== exhibitorId) {
        setFilters({ exhibitor_org_id: exhibitorId })
      } else {
        setFilters({})
      }
    },
    [setFilters]
  )
  const componentTypeIndex = COMPONENT_TYPE_AND_INDEX_MAPPER[componentType]
  const availableExhibitors = exhibitors.filter((exhibitor: any) =>
    exhibitor.component_types.includes(componentTypeIndex)
  )

  if (isNaN(componentTypeIndex) || availableExhibitors.length === 0) return null

  return (
    <div>
      <div className={classes.wrapper}>
        {availableExhibitors.map((exhibitor: any) => (
          <ExhibitorShowcase
            key={exhibitor.id}
            exhibitor={exhibitor}
            handleSelect={handleSelect}
            activeExhibitor={activeExhibitorRef.current}
          />
        ))}
      </div>
    </div>
  )
}

export default ExhibitedShowcase

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
export var AddIcon = function (props) {
    var _a = props.size, size = _a === void 0 ? 24 : _a, _b = props.color, color = _b === void 0 ? 'currentColor' : _b;
    var viewBox = "0 0 ".concat(size, " ").concat(size);
    return (_jsx("svg", __assign({ width: size, height: size, viewBox: viewBox, fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M12.0008 4.7998L12.0008 19.1998M19.2008 11.9998L4.80078 11.9998", stroke: color, strokeWidth: "2", strokeLinecap: "round" }) })));
};

// @ts-nocheck
import { InputAdornment, TextField } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core/styles'
import ClearIcon from '@material-ui/icons/ClearOutlined'
import SearchIcon from '@material-ui/icons/SearchOutlined'
import { setStudioSavePrompt } from 'ducks/studioPrompt'
import Tooltip from 'elements/tooltip/Tooltip'
import { IconButton, IconButtonProps } from 'opensolar-ui'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

const getLocationForSearchResults = (value: string) =>
  '/projects?filter=%7B%22q%22%3A%22' + value + '%22%7D&order=DESC&page=1&perPage=20&sort=id'

const useStyles = makeStyles(
  (theme: any) => ({
    button: {
      minWidth: 36,
      padding: 0,
      height: 36,
    },
    icon: {
      height: 24,
      width: 24,
      color: 'inherit',
    },
    paper: {
      borderRadius: 10,
      top: '90px',
    },
    input: {
      padding: '15px 15px 15px 0px',
      fontWeight: 300,
    },
    outlined: {
      borderRadius: 10,
      '&:hover': {
        borderRadius: 10,
      },
    },
    noBorder: {
      border: 'none',
      '&:focus': {
        border: 'none',
      },
    },
    field: {
      background: '#ECEEF0',
      boxShadow: '0px 10px 50px rgba(0, 0, 0, 0.25)',
    },
    dialogContainer: {
      alignItems: 'flex-start',
    },
  }),
  { name: 'Search-Button' }
)

const emails = ['username@gmail.com', 'user02@gmail.com']

export interface SearchBarProps {
  open: boolean
  onClose: (value: string) => void
}

const SearchBar = (props: SearchBarProps) => {
  const [value, setValue] = useState('')
  const dispatch = useDispatch()
  const history = useHistory()
  const translate = useTranslate()
  const classes = useStyles()
  const myInput = useRef()

  useEffect(() => {}, [])

  const { onClose, open } = props

  const handleClose = () => {
    setValue('')
    onClose(value)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value)
  }

  const handleSubmit = () => {
    const newPath = getLocationForSearchResults(value)
    //if there is unsaved changes in project form
    if (window.projectForm?.mutators.getFormDirtyFields().length > 0) {
      dispatch(
        setStudioSavePrompt({
          show_prompt: true,
          redirect: newPath,
          prompt_heading: `Leave Studio to search: ${value}?`,
        })
      )
    } else {
      history.push(newPath)
    }
    setValue('')
    onClose(value)
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0)' } }}
      classes={{ paper: classes.paper, container: classes.dialogContainer }}
      open={open}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <TextField
          id="Search-Bar-Input"
          variant="outlined"
          autoFocus={true}
          value={value}
          InputProps={{
            classes: { input: classes.input, root: classes.outlined, notchedOutline: classes.noBorder },
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  onClick={(e) => {
                    handleSubmit()
                  }}
                  classes={{ root: classes.button }}
                >
                  <SearchIcon classes={{ root: classes.icon }} />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: value && (
              <InputAdornment position="end">
                {' '}
                <IconButton
                  onClick={(e) => {
                    setValue('')
                  }}
                  classes={{ root: classes.button }}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          fullWidth={true}
          classes={{ root: classes.field }}
          inputRef={myInput}
          onFocus={() => {
            try {
              window.editor.signals.typingInField.dispatch(true)
            } catch (err) {}
          }}
          onBlur={() => {
            try {
              window.editor.signals.typingInField.dispatch(false)
            } catch (err) {}
          }}
          onChange={handleChange}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              ev.preventDefault()
              handleSubmit()
            }
          }}
          placeholder={translate('Search projects or contacts')}
        />
      </form>
    </Dialog>
  )
}

const SearchBarController = ({ triggerIconButton }: { triggerIconButton?: React.ReactElement<IconButtonProps> }) => {
  const [open, setOpen] = React.useState(false)
  const translate = useTranslate()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (value: string) => {
    setOpen(false)
  }

  const classes = useStyles()

  return (
    <div>
      {triggerIconButton ? (
        React.cloneElement(triggerIconButton, {
          onClick: handleClickOpen,
        })
      ) : (
        <IconButton size="medium" color="inherit" onClick={handleClickOpen} classes={{ root: classes.button }}>
          <Tooltip title={translate('Search')} enterDelay={300}>
            <SearchIcon classes={{ root: classes.icon }} />
          </Tooltip>
        </IconButton>
      )}
      <SearchBar open={open} onClose={handleClose} />
    </div>
  )
}

export default SearchBarController

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import ContentCreate from '@material-ui/icons/CreateOutlined';
import PropTypes from 'prop-types';
import { linkToRecord } from 'ra-core';
import { Link } from 'react-router-dom';
import Button from './Button';
var listStyle = { color: '#4d4d4d', justifyContent: 'flex-start', margin: 0 };
var defaultStyle = {};
var EditButton = function (_a) {
    var _b = _a.basePath, basePath = _b === void 0 ? '' : _b, _c = _a.label, label = _c === void 0 ? 'ra.action.edit' : _c, record = _a.record, _d = _a.icon, icon = _d === void 0 ? defaultIcon : _d, _e = _a.useListStyle, useListStyle = _e === void 0 ? false : _e, rest = __rest(_a, ["basePath", "label", "record", "icon", "useListStyle"]);
    return (_jsx(Link, __assign({ style: { textDecoration: 'none' }, to: linkToRecord(basePath, record && record.id) }, { children: _jsx(Button, __assign({ label: label, onClick: stopPropagation, name: 'raEditButton', style: useListStyle ? listStyle : defaultStyle }, rest, { children: icon })) })));
};
var defaultIcon = _jsx(ContentCreate, {});
// useful to prevent click bubbling in a datagrid with rowClick
var stopPropagation = function (e) { return e.stopPropagation(); };
EditButton.propTypes = {
    basePath: PropTypes.string,
    icon: PropTypes.element,
    label: PropTypes.string,
    record: PropTypes.any,
};
export default EditButton;

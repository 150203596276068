// @ts-nocheck
import { Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
// import Button from 'elements/button/Button'

const useStyles = makeStyles((theme) => ({
  descriptionWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: '25px',
    marginTop: '12px',
  },
  cartWrapper: {
    marginRight: '10px',
  },
  description: {
    fontSize: '12px',
    marginRight: '15px',
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonWrapper: {
    marginTop: '15px',
  },
}))

type PropTypes = {
  handleClick: () => void
  disabled?: boolean
  label: string
}

const SectionButtonCTA: React.FunctionComponent<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <div style={{ position: 'relative', width: props.fullWidth ? '100%' : undefined }}>
      <Tooltip title={translate(props.label)} enterDelay={300}>
        <Button
          onClick={props.handleClick}
          variant="contained"
          color="default"
          fullWidth={true}
          disabled={props.disabled}
          // style={{ marginBottom: 10 }}
        >
          <span style={{ textTransform: 'none' }}>{translate(props.label)}</span>
        </Button>
      </Tooltip>
    </div>
  )
}

export default SectionButtonCTA

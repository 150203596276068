import { ComponentContentCreate, ComponentContentEdit, ComponentContentList } from './ComponentContent'

const ComponentContentTitle = ({ record, translate, style }) => (
  <span style={style}>
    {translate('Content')}: {record && record.title}
  </span>
)

export default {
  list: ComponentContentList,
  edit: ComponentContentEdit,
  create: ComponentContentCreate,
  options: {
    create: {
      title: 'Create Showcase Content',
      subtitle: null,
      breadCrumb: 'Create Showcase Content',
    },
    list: {
      title: 'Showcase Content',
      subtitle: null,
      breadCrumb: 'Showcase Content',
    },
    edit: {
      title: <ComponentContentTitle />,
      subtitle: null,
      breadCrumb: 'Edit Showcase Content',
    },
  },
}

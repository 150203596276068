import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
var ReferenceError = function (_a) {
    var label = _a.label, error = _a.error;
    return (_jsx(TextField, { error: true, disabled: true, label: label, value: error, margin: "normal" }));
};
ReferenceError.propTypes = {
    error: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};
export default ReferenceError;

import { ConditionFactory, SystemContext } from 'Designer/designRules/types'
import { BaseConditionDef } from '.'
import { checkScope, CheckScopeDef } from '../utils'

export type CheckProjectValueDef = BaseConditionDef &
  CheckScopeDef & {
    type: 'check-project'
  }

export const condition_checkProject: ConditionFactory<CheckProjectValueDef> = (def) => {
  return {
    getProjectFields: () => [def.prop],
    getStudioSignals: () => [],
    check: (context: SystemContext) => {
      return checkScope(context.project, def)
    },
  }
}

import { ComponentTypesV2 } from 'types/selectComponent'
import BasicFilter from '../basic/BasicFilter'
import type {
  ConfigurableFilterOptionsType,
  FilterComponentNodeV2,
  FilterGenericPropsType,
  OptionGenericType,
} from '../typeV2'
import { parseStringifiedListFilterValue } from '../utils/utils'

export const INVERTER_TYPE_FILTER_NODE_KEY_V2 = 'inverter_type'
const DEFAULT_OPTION = { id: 'default', title: 'All', value: '' }

const INVERTER_TYPE_OPTIONS: OptionGenericType<string>[] = [
  DEFAULT_OPTION,
  {
    id: 'microinverter',
    value: 'microinverter',
    title: 'Microinverter',
  },
  {
    id: 'hybrid',
    value: 'hybrid',
    title: 'Hybrid',
  },
  {
    id: 'string',
    value: 'string',
    title: 'String',
  },
  {
    id: 'power_optimized',
    value: 'power_optimized',
    title: 'Power Optimised',
  },
]

const getDefaultValue = (args?: {
  specsData: { [key: string]: string | number }
  componentType?: ComponentTypesV2
}) => {
  if (!args) {
    return
  }
  const { specsData, componentType } = args
  if (componentType !== 'inverter') {
    return
  }
  if (specsData.microinverter === 'Y') {
    return { [INVERTER_TYPE_FILTER_NODE_KEY_V2]: 'microinverter' }
  }
  if (specsData.hybrid === 'Y') {
    return { [INVERTER_TYPE_FILTER_NODE_KEY_V2]: 'hybrid' }
  }
  return
}

const createInverterFilterNode = <T,>({
  label,
  ...restConfigs
}: ConfigurableFilterOptionsType<string, T>): FilterComponentNodeV2 => {
  const FilterComponent = (filterProps: FilterGenericPropsType) => {
    const types = parseStringifiedListFilterValue<ComponentTypesV2>({
      filterValues: filterProps.allFilterValues,
      key: 'types',
    })

    if (!types.has('inverter')) {
      return null
    }

    return (
      <BasicFilter
        label={label}
        defaultSelectedOptionValue={DEFAULT_OPTION.value}
        options={INVERTER_TYPE_OPTIONS}
        filterKey={INVERTER_TYPE_FILTER_NODE_KEY_V2}
        {...restConfigs}
        {...filterProps}
      />
    )
  }
  return {
    key: INVERTER_TYPE_FILTER_NODE_KEY_V2,
    component: FilterComponent,
    getMatchedValueFromSpecsData: getDefaultValue,
  }
}

export default createInverterFilterNode

import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import OrderLineItem from 'pages/ordering/OrderLineItem'
import type { HardwareSupplierPresetConfig } from 'pages/ordering/configs'
import { HARDWARE_SUPPLIER_CONFIGS, getSupplierConfig } from 'pages/ordering/configs'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { OSComponentType } from 'types/components'
import { currencySymbolForCountry } from 'util/misc'
import ManualPrice from './ManualPrice'
import PriceUnavailable from './PriceUnavailable'
import SupplierPrice from './SupplierPrice'

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    width: '100%',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'flex-start',
  },
  label: {},
  value: {
    flexGrow: 1,
    marginLeft: 10,
    textAlign: 'right',
  },
}))

const usePriceFieldStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export const PriceFieldLayout = ({ label, value }: { label: React.ReactNode; value: React.ReactNode }) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <div className={classes.label}>{label}</div>
      <div className={classes.value}>{value}</div>
    </div>
  )
}

const PriceField = ({
  component,
  selectedSupplierFilterValue,
}: {
  component: OSComponentType
  selectedSupplierFilterValue?: HardwareSupplierPresetConfig['filterKey'] | 'all'
}) => {
  const classes = usePriceFieldStyles()
  const countryIso2 = useSelector(orgSelectors.getOrgIso2)
  const orgId = useSelector(authSelectors.getOrgId)
  const currencySymbol = useMemo(() => currencySymbolForCountry(countryIso2), [countryIso2])
  const defaultSelectedSupplier = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const enabledDistributors = useSelector(orgSelectors.getEnabledHardwareSuppliers)

  const supplierConfig = useMemo(() => {
    const config = HARDWARE_SUPPLIER_CONFIGS.find((c) => c.filterKey === selectedSupplierFilterValue)
    if (config) {
      return config
    } else {
      return getSupplierConfig(defaultSelectedSupplier)
    }
  }, [selectedSupplierFilterValue, defaultSelectedSupplier])

  const orderingItemForSingleItem =
    component.ordering &&
    supplierConfig &&
    OrderLineItem.getDefaultSelectedVariant(component.ordering, supplierConfig.filterKey)

  let output: Array<JSX.Element> = []

  if (orderingItemForSingleItem !== undefined && supplierConfig && enabledDistributors.includes(supplierConfig.type)) {
    const lineItem = new OrderLineItem({
      code: component.code,
      variantId: orderingItemForSingleItem.variant_id,
      selectedDistributor: supplierConfig.filterKey,
      selectedDistributorOrderingData: orderingItemForSingleItem,
      quantityPerItem: orderingItemForSingleItem.quantity_per_item,
      quantity: 1,
      pricePerUnit: orderingItemForSingleItem.price,
      orgId,
      variantDiscounts: orderingItemForSingleItem.volume_discounts,
      stockLevels: orderingItemForSingleItem.stock_levels,
    })
    output.push(
      <SupplierPrice
        key="supplier"
        currencySymbol={currencySymbol}
        distributor={orderingItemForSingleItem}
        lineItem={lineItem}
        supplierConfig={supplierConfig}
      />
    )
  }
  if (component.cost) {
    output.push(<ManualPrice key="manual" cost={component.cost} currencySymbol={currencySymbol} />)
  }

  if (output.length > 0) {
    return <div className={classes.wrapper}>{output}</div>
  } else {
    return <PriceUnavailable />
  }
}

export default PriceField

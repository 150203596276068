import { authSelectors } from 'ducks/auth'
import { ProjErrorFilter } from 'projectSections/errors/useFilterProjectErrors'
import React, { memo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { ProjErrorCategory, ProjectErrorType } from 'reducer/project/projectErrorsReducer'
import ProjectWarningBox from './ProjectWarningBox'

type PropsTypes = {
  categories: ProjErrorCategory[] | 'all'
  extraFilter?: ProjErrorFilter
}

const DesignWarningBoxBasic: React.FC<PropsTypes> = ({ categories, extraFilter }) => {
  const isProjectOwner = useSelector(authSelectors.getOrgId) === window.WorkspaceHelper.project?.org_id

  const filter = useCallback(
    (error: ProjectErrorType) => {
      // Default filter apply to all scenarios
      if (error.severity === 'info' && !isProjectOwner && error.source === 'autoApply') return false

      // Additional filter
      if (extraFilter && !extraFilter(error)) return false
      return true
    },
    [isProjectOwner, extraFilter]
  )

  return (
    <ProjectWarningBox
      // allow all categories when category is undefined
      categories={categories === 'all' ? undefined : categories}
      filter={filter}
      systemIds={[window.editor?.selectedSystem?.uuid]}
    />
  )
}

export default memo(DesignWarningBoxBasic)

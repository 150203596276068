import { Grid, Typography } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import useIsFirstRender from 'hooks/useIsFirstRender'
import { createContext, useCallback, useEffect, useLayoutEffect, useState } from 'react'
import type { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import ActivityList from '../activity/ActivityList'
import InventoryActivityRow from '../activity/renderers/InventoryActivityRow'
import HeadingSearchBar from '../components/search/HeadingSearchBar'
import type { InventoryFilterKeyType } from '../filter/type'
import type { UpdateFilterValueArgs } from '../filter/useInventoryFilter'
import useInventoryFilter from '../filter/useInventoryFilter'
import InventoryForecastDetailTable from '../forecast/detail/InventoryForecastDetail'
import InventoryForecastDetailDialog from '../forecast/detail/InventoryForecastDetailDialog'
import useForecastDetailDialogController from '../forecast/detail/useForecastDetailDialogController'
import ForecastTable from '../forecast/ForecastTable'
import DashboardFigures from './DashboardFigures'
import InventoryDashboardFilter from './InventoryDashboardFilter'
import InventoryTable from './InventoryTable'
import ProjectForecastDialogToolbar from './modal/ProjectForecastDialogToolbar'
import useDashboardPresenter from './useDashboardPresenter'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  colorSection: {
    padding: 25,
    minHeight: 190,
    background: 'linear-gradient(to right, #ffe9c2, #fdd6d6)',
  },
  tableSection: {
    margin: 25,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

const defaultFilter = {
  filterValues: {
    time_period: 28,
  },
  displayedFilters: {},
}

type SearchState = {
  searchState: boolean
}

export const SearchingContext = createContext<SearchState>({ searchState: false })

const InventoryDashboard = () => {
  const classes = useStyles()
  const { filterValues, updateFilterValueByKey } = useInventoryFilter({
    defaultFilter,
  })
  const [initializing, setInitializing] = useState(true)
  const isFirstRender = useIsFirstRender()
  const [isSearching, setIsSearching] = useState(false)
  const [touched, setTouched] = useState(false)

  const {
    forecastingProjectInventory,
    forecastedProjectInventoryData,
    activityData,
    loadingActivityData,
    loadingDashboardStatistics,
    dashboardStatistics,
    inventoryData,
    loadingInventoryData,
    presenter: inventoryForecastPresenter,
  } = useDashboardPresenter()

  const onMounted = useCallback(async () => {
    await inventoryForecastPresenter.init(filterValues)
    setInitializing(false)
  }, []) // as it named only used once
  useLayoutEffect(() => {
    onMounted()
  }, [])

  // Reloads dashboard data whenever filter values changed
  useEffect(() => {
    if (isFirstRender()) {
      return
    }
    if (isSearching) {
      inventoryForecastPresenter.reloadInventoryDashboardData({ search: filterValues.search })
    } else {
      inventoryForecastPresenter.reloadInventoryDashboardData(filterValues)
    }
  }, [filterValues])

  const refreshInventoryDashboard = useCallback(() => {
    setTouched(false)
    inventoryForecastPresenter.reloadInventoryDashboardData(filterValues)
  }, [filterValues])

  // Data may change in the dialog
  const forecastDetailDialogController = useForecastDetailDialogController({ afterClose: refreshInventoryDashboard })

  const onFilterUpdate = useCallback(
    <T extends InventoryFilterKeyType>({ key, value }: UpdateFilterValueArgs<T>) => {
      updateFilterValueByKey<T>({ key, value })
      // Do not call refreshInventoryDashboard() here because setState is async
    },
    [updateFilterValueByKey]
  )

  const handleSearchingOn = () => {
    setIsSearching(true)
  }

  const handleSearchingOff = () => {
    setIsSearching(false)
  }

  const handleChange = (value: string) => {
    handleSearchingOn()
    onFilterUpdate({ key: 'search', value })
    logAmplitudeEvent('generic_filter_interacted', {
      action: 'updated',
      key: 'dashboard_search',
      value: value,
    })
    if (!value) {
      handleSearchingOff()
    }
  }

  const handleTouched = () => {
    setTouched(true)
  }

  return (
    <>
      <DashboardFigures
        loading={initializing || loadingDashboardStatistics} // Add a loaded flag if we want to show the table before initialization
        dashboardStatistics={dashboardStatistics}
      />
      <section className={classes.tableSection}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Typography variant="h5">Project Installation Forecast</Typography>
          </Grid>
          <Grid item>
            <HeadingSearchBar
              searchValue={filterValues.search || ''}
              handleChange={(value) => handleChange(value)}
              onClear={() => {
                onFilterUpdate({ key: 'search', value: '' })
                handleSearchingOff()
              }}
            />
          </Grid>
        </Grid>
        <InventoryDashboardFilter filterValues={filterValues} onFilterUpdate={onFilterUpdate} disabled={isSearching} />
        <SearchingContext.Provider value={{ searchState: isSearching }}>
          <ForecastTable
            loading={initializing || forecastingProjectInventory} // Add a loaded flag if we want to show the table before initialization
            refreshInventoryDashboard={refreshInventoryDashboard}
            forecastDetailDialogController={forecastDetailDialogController}
            forecastedProjectInventoryData={forecastedProjectInventoryData}
          />
        </SearchingContext.Provider>
      </section>
      <section className={classes.tableSection}>
        <InventoryTable
          title={
            <Typography variant="h5" gutterBottom>
              Inventory
            </Typography>
          }
          inventoryDataLoaded={!loadingInventoryData} // Add a loaded flag if we want to show the table before initialization
          inventoryData={inventoryData?.inventory.items}
          location={inventoryData?.location}
          loadingInventoryData={loadingInventoryData}
          reloadInventoryTable={refreshInventoryDashboard}
        />
      </section>
      <section className={classes.tableSection}>
        <Typography variant="h5" gutterBottom>
          Recent activity
        </Typography>
        <ActivityList
          Renderer={InventoryActivityRow}
          activityData={activityData}
          loadingActivityData={loadingActivityData}
          isDashboard={true}
        />
      </section>
      <InventoryForecastDetailDialog
        title="Project Installation Forecast"
        toolbar={
          <ProjectForecastDialogToolbar
            forecastedProjectInventoryData={forecastedProjectInventoryData}
            controller={forecastDetailDialogController}
          />
        }
        open={forecastDetailDialogController.open}
        onClose={touched ? forecastDetailDialogController.onClose : forecastDetailDialogController.onCancel}
        content={
          forecastDetailDialogController.code ? (
            <InventoryForecastDetailTable
              code={forecastDetailDialogController.code}
              forecastEndDate={forecastDetailDialogController.forecastEndDate}
              projectId={forecastDetailDialogController.projectId}
              filters={filterValues}
              handleTouched={handleTouched}
            />
          ) : null
        }
      />
    </>
  )
}

export default InventoryDashboard

import { AuthType } from 'types/auth'
import { OrgType } from 'types/orgs'
import { customStorage } from './customLocalStorage'

const KEY_ORG_ID = 'org_id'
const KEY_ORG = 'org'
const KEY_AUTH = 'auth'
const KEY_ORG_ISO2 = 'org_iso2'
const KEY_LOCALE = 'locale'
const KEY_TOKEN = 'token'
const KEY_PROJECT_IDENTIFIERS_TOKEN = 'project_identifiers_token'

export const getOrgId = () => getNumber(KEY_ORG_ID)
export const setOrgId = (value?: number) => setNumber(KEY_ORG_ID, value)

export const getOrg = () => getJSON<OrgType>(KEY_ORG)
export const setOrg = (value?: OrgType) => setJSON(KEY_ORG, value)

export const getAuth = () => getJSON<AuthType>(KEY_AUTH)
export const setAuth = (value?: AuthType) => setJSON(KEY_AUTH, value)

export const getIso2 = () => getString(KEY_ORG_ISO2)
export const setIso2 = (value?: string) => setString(KEY_ORG_ISO2, value)

export const getLocale = () => getString(KEY_LOCALE)
export const setLocale = (value?: string) => setString(KEY_LOCALE, value)

export const getToken = () => getString(KEY_TOKEN)
export const setToken = (value?: string) => {
  setString(KEY_TOKEN, value)
}
export const getProjectIdentifiersToken = () => getString(KEY_PROJECT_IDENTIFIERS_TOKEN)
export const setProjectIdentifiersToken = (value?: string) => {
  setString(KEY_PROJECT_IDENTIFIERS_TOKEN, value)
}

export function has(key: string): boolean {
  return window.getStorage().getItem(key) !== null
}
export function clear(key: string) {
  window.getStorage().removeItem(key)
}
export function clearAllMatching(regex: RegExp) {
  const storage = window.getStorage()
  let i = 0
  while (i < storage.length) {
    const key = storage.key(i)
    if (key && regex.test(key)) {
      storage.removeItem(key)
    } else {
      i++
    }
  }
}

/// String
export function getString(key: string): string | undefined {
  const value = window.getStorage().getItem(key)
  if (!value || value === 'null') {
    return undefined
  } else {
    return value
  }
}
export function setString(key: string, value?: string) {
  if (value === undefined) {
    window.getStorage().removeItem(key)
  } else {
    window.getStorage().setItem(key, value)
  }
}

/// Boolean
export function getBool(key: string): boolean | undefined {
  const value = window.getStorage().getItem(key)
  if (value === null || value === 'null') {
    return undefined
  } else {
    return value === 'true'
  }
}
export function setBool(key: string, value?: boolean) {
  if (value === undefined) {
    window.getStorage().removeItem(key)
  } else {
    window.getStorage().setItem(key, value ? 'true' : 'false')
  }
}

/// Number
export function getNumber(key: string): number | undefined {
  const value = window.getStorage().getItem(key)
  if (!value || value === 'null' || value === '0') {
    return undefined
  } else {
    return parseInt(value)
  }
}
export function setNumber(key: string, value?: number) {
  setString(key, value === undefined ? undefined : value + '')
}

/// JSON
export function getJSON<T extends object>(key: string): T | undefined {
  const value = window.getStorage().getItem(key)
  if (!value || value === 'null' || value === '') {
    return undefined
  } else {
    try {
      return JSON.parse(value) as T
    } catch (_) {
      return undefined
    }
  }
}
export function setJSON(key: string, value?: object) {
  setString(key, value ? JSON.stringify(value) : undefined)
}

export function keys(): string[] {
  const keys: string[] = []
  for (const key in customStorage.getBrowserStorage()) {
    keys.push(key)
  }
  return keys
}

export default {
  getOrgId,
  setOrgId,
  getOrg,
  setOrg,
  getIso2,
  setIso2,
  getLocale,
  setLocale,
  getToken,
  setToken,
  getProjectIdentifiersToken,
  setProjectIdentifiersToken,
  getAuth,
  setAuth,
  has,
  clear,
  clearAllMatching,
  getBool,
  setBool,
  getNumber,
  setNumber,
  getString,
  setString,
  setJSON,
  getJSON,
  keys,
}

import { orgSelectors } from 'ducks/orgs'
import OrderLineItem from 'pages/ordering/OrderLineItem'
import useOrderComponentDetail from 'pages/ordering/search/useOrderComponentDetail'
import type { ProjectOrderDetail } from 'pages/ordering/type'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

const useProductDetailLineItem = ({
  componentCode,
  defaultQuantity,
  defaultProjectOrder,
  confirmed,
}: {
  componentCode: string
  defaultQuantity?: number
  defaultProjectOrder?: ProjectOrderDetail[]
  confirmed: boolean
}) => {
  const { detailData, loading } = useOrderComponentDetail({ componentCode })
  const [lineItem, setLineItem] = useState<OrderLineItem | undefined>(undefined)
  const supplierFilterKey = useSelector(orgSelectors.getSelectedHardwareSupplierFilterKey)
  const countryIso2 = useSelector(orgSelectors.getOrgIso2)
  // This allows you to view single component pages when there are no enabled hardware suppliers
  // It doesn't really matter which one we pick when we are in the UK.
  const effectiveFilterKey = useMemo(() => {
    if (!supplierFilterKey) {
      if (countryIso2 === 'AU') {
        return 'outlet'
      } else {
        return 'segen'
      }
    }
    return supplierFilterKey
  }, [supplierFilterKey, countryIso2])

  useEffect(() => {
    if (!loading && detailData && effectiveFilterKey) {
      const newLineItem = new OrderLineItem({
        componentType: detailData?.component_type,
        status: 'loaded',
        code: detailData.code,
        data: detailData,
        confirmed: confirmed,
        quantity: defaultQuantity || 1,
        quantityPerItem: 1,
        projectOrder: defaultProjectOrder,
        selectedDistributor: effectiveFilterKey,
      })

      setLineItem(newLineItem)
    }
  }, [detailData, loading, effectiveFilterKey])
  const updateLineItem = useCallback((lineItem: OrderLineItem) => {
    setLineItem(lineItem)
  }, [])
  return { lineItem, loading, updateLineItem }
}

export default useProductDetailLineItem

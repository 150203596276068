import type { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  selectionHeader: {
    height: 20,
    padding: '5px 16px',
    lineHeight: '20px',
    background: theme.greyLight2,
    color: theme.greyMid1,
    fontSize: theme.typography.subtitle2.fontSize,
  },
  menuItem: {
    fontSize: theme.typography.subtitle2.fontSize,
  },
}))

export default useStyles

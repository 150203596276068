import { CircularProgress } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import { paymentOptionSelectionSelectors } from 'ducks/paymentOptionSelection'
import { Button } from 'opensolar-ui'
import { useGetIsCashFlowVisible } from 'pages/cashFlow/utils'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { useIsCalculating } from './projectProgress/cashFlow/utils'

type PropTypes = {}

const ConvertToCashFlowButton: React.FC<PropTypes> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [waitingForCalcsToFinish, setWaitingForCalcsToFinish] = useState<boolean>(false)
  const cashflowIsVisible = useGetIsCashFlowVisible()
  const orgHasCashFlow = useSelector(orgSelectors.getCashFlowIsActive)
  const refreshSystemsTrigger = useSelector(paymentOptionSelectionSelectors.getSystemRefreshTrigger)
  const form = useForm()

  const isCalculating = useIsCalculating(window.editor.selectedSystem?.uuid)

  useEffect(() => {
    if (isCalculating) setWaitingForCalcsToFinish(true)
  }, [isCalculating])

  useEffect(() => {
    if (waitingForCalcsToFinish && !isCalculating) {
      setIsLoading(false)
      setWaitingForCalcsToFinish(false)
    }
  }, [isCalculating, waitingForCalcsToFinish])

  const doConversion = () => {
    setIsLoading(true)
    window.editor.getSystems()?.forEach((system) => {
      window.editor.execute(new window.SetValueCommand(system, 'force_enable_cashflow', true))
    })
    form.mutators.markFieldAsDirty('design')
    form.change('design', 'has unsaved change')
  }

  const hasCashFlowOnThisProject = useMemo(() => {
    // if they don't have CashFlow then just return false
    if (!cashflowIsVisible || !orgHasCashFlow) return false
    const systems = window.editor?.getSystems()
    let foundCashFlow = false
    systems?.forEach((sys) => {
      sys.payment_options?.forEach((pmt) => {
        if (pmt?.expected_milestone_payments?.length > 0) foundCashFlow = true
      })
    })
    return foundCashFlow
  }, [cashflowIsVisible, orgHasCashFlow, refreshSystemsTrigger])

  if (!cashflowIsVisible || !orgHasCashFlow || hasCashFlowOnThisProject) return null
  return (
    <div>
      <Button onClick={doConversion} variant="contained" color="primary" disabled={isLoading}>
        {isLoading ? (
          <div>
            <CircularProgress />
          </div>
        ) : (
          <span>Enable CashFlow Payments</span>
        )}
      </Button>
    </div>
  )
}
export default ConvertToCashFlowButton

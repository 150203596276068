import { StyleWarningLevel } from './types';
var sizeCheck = function (value) {
    if (value === 'max-content' || value === '100%')
        return StyleWarningLevel.info;
    return StyleWarningLevel.warning;
};
export var defaultRules = {
    color: { level: StyleWarningLevel.error },
    fontFamily: { level: StyleWarningLevel.error },
    border: { level: StyleWarningLevel.error },
    borderTop: { level: StyleWarningLevel.error },
    borderBottom: { level: StyleWarningLevel.error },
    borderLeft: { level: StyleWarningLevel.error },
    borderRight: { level: StyleWarningLevel.error },
    background: { level: StyleWarningLevel.error },
    boxShadow: { level: StyleWarningLevel.error },
    height: { level: sizeCheck },
    width: { level: sizeCheck },
    textTransform: { level: StyleWarningLevel.warning },
    filter: { level: StyleWarningLevel.warning },
    margin: { level: StyleWarningLevel.warning },
};

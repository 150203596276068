import { orgSelectors } from 'ducks/orgs'
import { getSupplierConfig } from 'pages/ordering/configs'
import React from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 0',
  },
}))

const useShippingLocationStyles = makeOpenSolarStyles((theme) => ({
  locations: {
    textAlign: 'right',
  },
}))

const ShippingLocation = ({ locations }: { locations: string[] }) => {
  const classes = useShippingLocationStyles()
  return (
    <>
      <div>Ship/Pickup from</div>
      <div className={classes.locations}>{locations.join(', ')}</div>
    </>
  )
}

export const DefaultShippingLocation = ({ supplierName }: { supplierName?: string }) => {
  return (
    <>
      <div>Ships from</div>
      <div>{supplierName}</div>
    </>
  )
}

const SupplierDetail = ({ locations }: { locations: string[] | undefined }) => {
  const classes = useStyles()
  const selectedSupplier = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const supplierConfig = getSupplierConfig(selectedSupplier)
  const supplierName = supplierConfig?.name

  return (
    <>
      <section className={classes.wrapper}>
        {locations !== undefined ? (
          <ShippingLocation locations={locations} />
        ) : (
          <DefaultShippingLocation supplierName={supplierName} />
        )}
      </section>

      <section className={classes.wrapper}>
        <div>Sold from</div>
        <div>{supplierName}</div>
      </section>
    </>
  )
}

export default SupplierDetail

import { authSelectors } from 'ducks/auth'
import { featureConfigSelectors, getPublicFeatureConfig } from 'ducks/featureConfig'
import { useEndpointPoller } from 'hooks/useEndpointPoller'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import appStorage from 'storage/appStorage'
import { RootState } from 'types/state'
import { PromosLoadState } from '../PromosContext'
import { promoFeatureConfigType } from '../types'
import { migratePromos } from '../utils'

const POLL_TIME = 60 * 1000 // 60s
let env = window.ENV === 'staging4' || window.ENV === 'production' ? window.ENV : 'others'
const IN_APP_PROMO_PUBLIC_URL = `https://content.opensolar.com/in_app_promos.${env}.json?cache_bust=`

// Polls the external data sources for Promos, should only be used once in the app.
export const usePollPromos = (setLoadState: (state: PromosLoadState) => void) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const org = useSelector(authSelectors.getCurrentOrg)
  const role = useSelector(authSelectors.getCurrentRole)
  const [publicPromoConfigs, setPromoConfigs] = useState<promoFeatureConfigType | undefined>()
  const isLoggedIn: Boolean = useSelector(authSelectors.getIsLoggedIn)
  const [requestedFeatureConfig, setRequestedFeatureConfig] = useState<boolean>(false)
  const disableRemotePolling = window.ENV === 'ci'

  const { loading } = useEndpointPoller(
    IN_APP_PROMO_PUBLIC_URL,
    POLL_TIME,
    (promos) => {
      setPromoConfigs(migratePromos(promos))
    },
    { disabled: disableRemotePolling, cache_bust: true }
  )

  useEffect(() => {
    if (publicPromoConfigs) {
      if (loading) {
        setLoadState('reloading')
      } else {
        setLoadState('loaded')
      }
    }
  }, [publicPromoConfigs, loading])

  const privatePromoConfigs: promoFeatureConfigType | undefined = useSelector((state: RootState) =>
    migratePromos(featureConfigSelectors.getFeatureConfigData(state, 'in_app_promos'))
  )

  const promoConfigs = useMemo(() => [...(privatePromoConfigs || []), ...(publicPromoConfigs || [])], [
    publicPromoConfigs,
    privatePromoConfigs,
  ])

  const notAtLogin = !!(history?.location?.pathname !== '/login')

  // Attempt to load feature config
  useEffect(() => {
    if (isLoggedIn && org?.id && role?.id && notAtLogin && !requestedFeatureConfig) {
      setTimeout(() => {
        dispatch(getPublicFeatureConfig('in_app_promos'))
        setRequestedFeatureConfig(true)
      }, 1000)
    }
  }, [isLoggedIn, org?.id, role?.id, notAtLogin, requestedFeatureConfig])

  // Allow local hacking of promo configs
  const testPromos = appStorage.getJSON<promoFeatureConfigType>('promoConfigs')
  return testPromos || promoConfigs
}

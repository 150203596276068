import { Typography } from '@material-ui/core'
import { Alert, Grid, styled } from 'opensolar-ui'
import { useState } from 'react'
import { useFormState } from 'react-final-form'
import { ORDER_STATUS } from 'resources/inventoryTransfer/constants'
import IncomingListActionsField from 'resources/inventoryTransfer/fields/IncomingListActionsField'
import { DistributorPortalButton } from 'resources/inventoryTransfer/modals/OrderDistributor'
import { useDateFormatter } from 'util/date'
import OrderStatusStepper from '../../../manage3/hardware/OrderStatusStepper'
import { getSupplierName } from '../../util'

const FlexWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const InfoWrapper = styled('div')({
  width: '100%',
  height: '100%',
  borderRight: '1px solid #e7e7e7',
})

const StepWrapper = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexGrow: 1,
  maxWidth: 850,
})

const ActionWrapper = styled('div')({
  paddingTop: 20,
})

const AlertWrapper = styled('div')({
  paddingRight: 10,
})

const OrderInfo = ({ order }) => {
  const project = useFormState().values
  const formatter = useDateFormatter({ variant: 'date' })
  const [status, setStatus] = useState(order.status)
  const orderNumber = order.order_number_display || order.order_number || order.order_id

  const handleStatus = (status) => {
    setStatus(status)
  }

  const getAlertText = (order) => {
    if (status === ORDER_STATUS.ORDERED && order.distributor === 'segen') {
      return 'Quote submitted to Segen. To convert your quote to an order, continue to the Segen portal.'
    }
    if (status === ORDER_STATUS.PROCESSING && (order.distributor !== 'outlet' || order.distributor !== 'segen')) {
      return 'Your order and payment are pending confirmation from your Account Handler. Once confirmed, you can mark the Processing step as complete.'
    }
    if (status === ORDER_STATUS.PROCESSING && order.distributor === 'segen') {
      return 'Order submitted to Segen. Please complete your order by creating a shipment on the Segen portal.'
    }
    if (status === ORDER_STATUS.PROCESSING && order.distributor === 'outlet') {
      return 'Next steps for your order: Solar Outlet will email you shortly to confirm your order and payment. Once confirmed, you’ll receive tracking or pickup details and an updated order number. Mark the Processing step as complete after confirmation. To change your order, contact Henry Mandorla at Solar Outlet (+61 449 734 823, henry@solarjuice.com.au), or your account manager.'
    }

    return undefined
  }

  const getUrl = () => {
    if (order.distributor === 'segen') {
      if (order.status === ORDER_STATUS.QUOTE_SUBMITTED) {
        return 'https://portal.segen.co.uk/Reseller/MyAccount/Quotes'
      }
      if (
        order.status === ORDER_STATUS.ORDERED ||
        order.status === ORDER_STATUS.PROCESSING ||
        order.status === ORDER_STATUS.DELIVERED
      ) {
        return 'https://portal.segen.co.uk/Reseller/OrderSummary/Summary'
      }
    }

    return ''
  }

  return (
    <FlexWrapper>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <InfoWrapper>
            <Typography variant="subtitle2" gutterBottom>
              {`Your Order ${order.order_id} (${getSupplierName(order)} ID ${orderNumber})`}
            </Typography>
            <Typography variant="body2" gutterBottom>
              Ordered from {getSupplierName(order)} on {formatter(order.created_date)} to{' '}
              {project.address || project.org_name}
            </Typography>
            {getAlertText(order) && (
              <AlertWrapper>
                <Alert severity="info">
                  <Typography variant="body2" gutterBottom>
                    {getAlertText(order)}
                  </Typography>
                  {getUrl() !== '' && (
                    <DistributorPortalButton
                      distributor={order.distributor}
                      url={getUrl()}
                      label="Go to Segen Portal"
                      variant="contained"
                      textDecoration="none"
                    />
                  )}
                </Alert>
              </AlertWrapper>
            )}
            <ActionWrapper>
              <IncomingListActionsField
                transfer={order}
                transferType={'incoming'}
                allowReview={true}
                orderStatus={status}
                onManage={false}
              />
            </ActionWrapper>
          </InfoWrapper>
        </Grid>
        <Grid item xs={12} md={6}>
          <StepWrapper>
            <OrderStatusStepper order={order} handleStatus={handleStatus} />
          </StepWrapper>
        </Grid>
      </Grid>
    </FlexWrapper>
  )
}

export default OrderInfo

import { ComplianceFormType } from './types'
export const complianceForms = [
  {
    heading: "Owner's Manual",
    countries: ['AU'],
    states: ['NSW', 'VIC', 'WA', 'SA', 'TAS', 'QLD', 'NT'],
    groups: [
      {
        heading: 'Relevant Forms',
        items: [
          {
            title: 'Pre Commissioning Form',
            url: window.PUBLIC_URL + '/files/Pre-Commissioning Online Fillable.pdf',
            icon: 'launch',
          },
          {
            title: 'Test and Commission Form',
            url: window.PUBLIC_URL + '/files/Test and Commission.pdf',
            icon: 'launch',
          },
          {
            title: 'Battery Commission Form',
            url: window.PUBLIC_URL + '/files/Battery Commissioning Sheet.pdf',
            icon: 'launch',
          },
        ],
      },
      {
        heading: 'Attached Files',
        items: [
          {
            title: 'Connection Diagram',
            file_tag: "Owner's Manual - Connection Diagram",
            icon: 'file',
          },
          {
            title: 'Post-Install Photograph',
            file_tag: "Owner's Manual - Post-Install Photograph",
            icon: 'file',
          },
          {
            title: 'Pre-commissioning Test Form',
            file_tag: "Owner's Manual - Pre Commissioning Test",
            icon: 'file',
          },
          {
            title: 'Test and Commissioning Form',
            file_tag: "Owner's Manual - Test and Commission",
            icon: 'file',
          },
          {
            title: 'Array Mounting Certificate',
            file_tag: "Owner's Manual - Array Mounting Certificate",
            icon: 'file',
          },
          {
            title: 'Equipment Documentation',
            file_tag: "Owner's Manual - Equipment Documentation",
            icon: 'file',
          },
          {
            title: 'Extra',
            file_tag: "Owner's Manual - Extra File",
            icon: 'file',
          },
          {
            title: "Owner's Manual (Generated)",
            file_tag: "Owner's Manual",
            icon: 'file',
          },
        ],
      },
    ],
  },
  {
    heading: 'Compliance Documents',
    notes:
      'These documents are generated based on your location. There might be other documents that needs to be filled.',
    countries: ['AU'],
    groups: [
      {
        heading: 'Electricity Compliance',

        items: [
          {
            states: ['NSW'],
            title: 'Certificate of Compliance – Electrical Work (CCEW)',
            url: 'https://www.fairtrading.nsw.gov.au/__data/assets/pdf_file/0020/371342/CCEW_template.pdf',
            icon: 'launch',
          },
          {
            states: ['VIC'],
            title: 'Certificate of Electrical Compliance (COES)',
            url: 'https://www.esv.vic.gov.au/licensing-coes/certificates-of-electrical-safety/',
            icon: 'launch',
          },
          {
            states: ['QLD'],
            title: 'ES Testing Compliance Certificate',
            url:
              'https://www.worksafe.qld.gov.au/__data/assets/pdf_file/0005/80573/es-testing-compliance-certificate.pdf',
            icon: 'launch',
          },
          {
            states: ['QLD'],
            title: 'Electrical Work Request (EWR)',
            url:
              'https://apps.sparq.com.au/my-access/sign-in/adfs/ls/?wa=wsignin1.0&wtrealm=https%3a%2f%2fapps.energex.com.au%2fepp%2f&wctx=rm%3d0%26id%3dpassive%26ru%3d%252fepp%252fep&wct=2019-01-16T00%3a49%3a12Z',
            icon: 'launch',
            distributors: ['Energex'],
          },

          {
            states: ['QLD'],
            title: 'Electrical Work Request (EWR)',
            url:
              'https://ee.sign-in.sparq.com.au/my-access/sign-in/adfs/ls/?wa=wsignin1.0&wtrealm=https%3a%2f%2fepp.ergon.com.au%2fepp%2f&wctx=rm%3d0%26id%3dpassive%26ru%3d%252fepp%252f&wct=2019-01-16T00%3a51%3a19Z',
            icon: 'launch',
            distributors: ['Energex'],
          },
          {
            states: ['SA'],
            title: 'Certificates of compliance - electrical work (eCOC)',
            url: 'https://portal.statedevelopment.sa.gov.au/eCoC',
            icon: 'launch',
          },
          {
            states: ['SA'],
            title: 'Verification of Electrical Installation Form',
            url: 'https://www.sa.gov.au/__data/assets/pdf_file/0012/21117/Electrical-verification.pdf',
            icon: 'launch',
          },
          {
            states: ['SA'],
            title: 'Schedule of Test Results',
            url: 'https://www.sa.gov.au/__data/assets/pdf_file/0003/18435/Schedule-of-test-results.pdf',
            icon: 'launch',
          },
          {
            states: ['TAS'],
            title: 'Certificate of Electrical Compliance (COES)',
            url: 'https://www.esv.vic.gov.au/licensing-coes/certificates-of-electrical-safety',
            icon: 'launch',
          },
          {
            states: ['WA'],
            title: 'Preliminary Notice, Notice of Completion and Electrical Safety Certificate',
            url: 'https://esenotice.commerce.wa.gov.au/es-enotice/public/pages/login/login.xhtml',
            icon: 'launch',
          },
          {
            states: ['WA'],
            title: 'Certificate of Electrical Compliance (City of Greater Geraldton)',
            url:
              'https://www.cgg.wa.gov.au/Profiles/cgg/Assets/ClientData/Forms/Development_and_Community_Services/HW15_-_Certificate_of_Electrical_Compliance.pdf',
            icon: 'launch',
          },
          {
            states: ['WA'],
            title: 'Design and Verification Certificate (Page 47 and 48)',
            url: 'https://www.commerce.wa.gov.au/sites/default/files/atoms/files/waer_2014_0.pdf',
            icon: 'launch',
          },
          {
            states: ['WA'],
            title: 'Electrical Compliance Form (NECA WA) (Appendix E)',
            url:
              'https://neca.asn.au/sites/default/files//media/state_wa/Membership/Technical%20and%20Industry%20Support/Technical%20and%20Industry%20Updates/2015/NECA%20WA%20Contractor%20Guidelines.pdf',
            icon: 'launch',
          },
          {
            states: ['NT'],
            title: 'Electricity Safety Certificate of Compliance',
            url: 'http://www.worksafe.nt.gov.au/PDF%20Conversion/electrical-safety-certificate-of-compliance.pdf',
            icon: 'launch',
          },
          {
            states: ['NT'],
            title: 'Electrical Test Checklist',
            url: 'http://www.worksafe.nt.gov.au/PDF%20Conversion/electrical-test-check-list.pdf',
            icon: 'launch',
          },
          {
            states: ['ACT'],
            title: 'Certificate of Electrical Safety',
            url: 'https://form.act.gov.au/smartforms/servlet/SmartForm.html?formCode=1013',
            icon: 'launch',
          },
        ],
      },
      {
        heading: 'Notification Form',
        items: [
          {
            states: ['NSW'],
            title: 'Notification of Service Work (NoSW)',
            url: 'https://services.ausgrid.com.au/SignIn',
            icon: 'launch',
          },
          {
            states: ['TAS'],
            title: 'Application for Connection',
            url: 'https://www.tasnetworks.com.au/forms/electricity-connection/embedded-generation/',
            icon: 'launch',
          },
          {
            states: ['VIC'],
            title: 'Pre-approval and New Connection (< 5kW single phase or 30kW three phase)',
            url: 'https://econnect.portal.powercor.com.au/customer/loginpage',
            icon: 'launch',
            distributors: ['Powercor', 'CitiPower'],
          },
          {
            states: ['VIC'],
            title: 'Pre-approval and New Connection (>30kW three phase or battery storage <200kWh)',
            url: 'https://customer.portal.powercor.com.au/mysupply/CIAWQuickCalculator#checklist',
            icon: 'launch',
            distributors: ['Powercor', 'CitiPower'],
          },
          {
            states: ['VIC'],
            title: 'Addition or Alteration of Micro Embedded Generator Connection',
            url:
              'https://jemena.com.au/getattachment/dcb133c0-6d63-45b8-ae3a-578f853e5062/Application-for-Micro-Embedded-Generation-Connecti.aspx',
            icon: 'launch',
            distributors: ['Jemena'],
          },
          {
            states: ['VIC'],
            title: 'Preliminary Enquiry Form – Below 5MW Capacity (Chapter 5A) V2',
            url:
              'https://www.unitedenergy.com.au/wp-content/uploads/2016/06/Preliminary-Enquiry-Form-Below-5MW-Capacity-Chapter-5A-V2.pdf',
            icon: 'launch',
            distributors: ['United Energy'],
          },
          {
            states: ['VIC'],
            title: 'Chapter 5 Generator Connection Agreement V2.0',
            url:
              'https://www.unitedenergy.com.au/wp-content/uploads/2018/05/Chapter-5-Generator-Connection-Agreement-V2.0.pdf',
            icon: 'launch',
            distributors: ['United Energy'],
          },
          {
            states: ['VIC'],
            title: 'Pre-approval (>5kW per phase or <30kW inverter capacity)',
            url:
              'https://ausnetservices.com.au/en/New-Connections/Solar-and-Battery-Connections/Complex-small-installations/Embedded-Generator-and-Battery-Storage-Approval-Form',
            icon: 'launch',
            distributors: ['AusNet Services'],
          },
          {
            states: ['VIC'],
            title: 'Pre-approval (<5kW single phase or 15kW three phase)',
            url: 'https://scp.ausnetservices.com.au',
            icon: 'launch',
            distributors: ['AusNet Services'],
          },
          {
            states: ['VIC'],
            title: 'Connection Form',
            url:
              'https://ausnetservices.com.au/-/media/Files/AusNet/New-Connections/Embedded-Generator-EG-and-Battery-Storage-Connection-Agreement-up-to-30kW.ashx?la=en',
            icon: 'launch',
            distributors: ['AusNet Services'],
          },
          {
            states: ['SA'],
            title: 'Approval Form',
            url: 'https://forms.apps.sapowernetworks.com.au/formpv',
            icon: 'launch',
          },
          {
            states: ['SA'],
            title: 'New Connection Form',
            url: 'https://forms.apps.sapowernetworks.com.au/formnws/iwantto/cc/ccnew',
            icon: 'launch',
          },
          {
            states: ['WA'],
            title:
              'Application for Approval to Connect Embedded Generation (Western Power) (<10kVA inverter capacity per phase)',
            url: 'https://westernpower.transactcentral.com/forms/servlet/SmartForm.html?formCode=ega-application',
            icon: 'launch',
          },
          {
            states: ['WA'],
            title: 'Application for Approval to Connect Embedded Generation (Western Power) (>30kVA inverter capacity)',
            url:
              'https://westernpower.com.au/connections/solar-batteries-electric-vehicles/inverter-system-greater-than-30kva-and-no-more-than-150kva/enquiry-for-inverter-system-greater-than-30kva-and-no-more-than-150kva/',
            icon: 'launch',
          },
          {
            states: ['WA'],
            title: 'Renewable energy system application (Synergy)',
            url: 'https://selfserve.synergy.net.au/renewable-energy-system.html',
            icon: 'launch',
          },
          {
            states: ['QLD'],
            title: 'Network Connection Applications (Energex)',
            url:
              'https://apps.sparq.com.au/my-access/sign-in/adfs/ls/?wa=wsignin1.0&wtrealm=https%3a%2f%2fapps.energex.com.au%2fepp%2f&wctx=rm%3d0%26id%3dpassive%26ru%3d%252fepp%252fcp&wct=2018-06-29T05%3a58%3a44Z',
            icon: 'launch',
            distributors: ['Energex'],
          },
          {
            states: ['QLD'],
            title: 'Application for Network Connection of a Micro EG Unit (Ergon) (Offline)',
            url:
              'https://www.ergon.com.au/__data/assets/pdf_file/0007/269890/PW000300F100-Micro-EG-application-form.pdf',
            icon: 'launch',
            distributors: ['Energex'],
          },
          {
            states: ['QLD'],
            title: 'Application for Network Connection of a Micro EG Unit (Energex) (Online)',
            url:
              'https://ee.sign-in.sparq.com.au/my-access/sign-in/adfs/ls/?wa=wsignin1.0&wtrealm=https%3a%2f%2fepp.ergon.com.au%2fepp%2f&wctx=rm%3d0%26id%3dpassive%26ru%3d%252fepp%252f&wct=2019-01-16T00%3a45%3a47Z',
            icon: 'launch',
            distributors: ['Energex'],
          },
          {
            states: ['NT'],
            title: 'Photovoltaic Inverter Network Connection Agreement',
            url:
              'https://www.powerwater.com.au/__data/assets/pdf_file/0015/3291/Photovoltaic_Inverter_Network_Connection_Agreement.pdf',
            icon: 'launch',
          },
          {
            states: ['NT'],
            title: 'Embedded Generation Notice Photovoltaic System',
            url:
              'https://www.powerwater.com.au/__data/assets/pdf_file/0019/143533/20170420_Revised_Embedded_Generation_Notice_for_all_classes.pdf',
            icon: 'launch',
          },
          {
            states: ['ACT'],
            title: 'Special Connection Request Form (Evoenergy)',
            url:
              'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=1&ved=2ahUKEwiPi4LdjPHfAhXLb30KHXvLCY0QFjAAegQIChAC&url=https%3A%2F%2Fwww.evoenergy.com.au%2F-%2Fmedia%2Fevoenergy%2Fdocuments%2Felectricity%2Fspecial-connection-request.pdf&usg=AOvVaw3UKTJu5nbw4xSd4CFaL1Q6',
            icon: 'launch',
            distributors: ['Evoenergy'],
          },
          {
            states: ['ACT'],
            title: 'Application Form (Evoenergy)',
            url:
              'https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=2&ved=2ahUKEwiPi4LdjPHfAhXLb30KHXvLCY0QFjABegQIAxAC&url=https%3A%2F%2Fwww.evoenergy.com.au%2F-%2Fmedia%2Fevoenergy%2Fdocuments%2Felectricity%2Fapplication-for-service-form.pdf&usg=AOvVaw3PB78DVoahVShBD9kX3AoC',
            icon: 'launch',
            distributors: ['Evoenergy'],
          },
          {
            states: ['ACT'],
            title: 'Special Connection Request Form',
            url: window.PUBLIC_URL + '/files/ActewAGLSpecialConnectionRequest.pdf',
            icon: 'launch',
          },
        ],
      },
      {
        heading: 'Attached Files',
        items: [
          {
            title: 'Electricity Compliance',
            file_tag: 'Compliance - Electrical Compliance',
            icon: 'file',
          },
          {
            title: 'Connection Form',
            file_tag: 'Compliance - Connection Form',
            icon: 'file',
          },
          {
            title: 'Other Documents',
            file_tag: 'Compliance - Other Document',
            icon: 'file',
          },
        ],
      },
    ],
  },
  {
    heading: 'STC Generation',
    countries: ['AU'],
    states: ['NSW', 'VIC', 'WA', 'SA', 'TAS', 'QLD', 'NT'],
    groups: [
      {
        heading: 'Relevant Forms',
        items: [
          {
            title: 'Compulsory written statements (CER-RET-014)',
            url:
              'https://www.cleanenergyregulator.gov.au/DocumentAssets/Pages/CER-RET-014-Compulsory-written-statements-for-small-scale-technology-certificate-creation.aspx',
            icon: 'launch',
          },
          {
            title: 'Onsite verification statement (CER-RET-015)',
            url:
              'http://www.cleanenergyregulator.gov.au/DocumentAssets/Documents/CER-RET-015%20Onsite%20verification%20statement.pdf',
            icon: 'launch',
          },
          {
            title: 'Small-scale technology certificate (CER-RET-016)',
            url:
              'https://www.cleanenergyregulator.gov.au/DocumentAssets/Pages/CER-RET-016-Small-scale-technology-certificate-assignment-form.aspx',
            icon: 'launch',
          },
        ],
      },
      {
        heading: 'Attached Files',
        items: [
          {
            title: 'CER-RET-014',
            file_tag: 'Compliance - Compulsory written statements (CER-RET-014)',
            icon: 'file',
          },
          {
            title: 'CER-RET-015',
            file_tag: 'Compliance - Onsite Verification (CER-RET-015)',
            icon: 'file',
          },
          {
            title: 'CER-RET-016',
            file_tag: 'Compliance - Assignment Form (CER-RET-016)',
            icon: 'file',
          },
        ],
      },
    ],
  },
  {
    heading: 'Pre-Mid-Post Installation Checklist',
    countries: ['NZ'],
    states: ['Auckland', 'Hamilton', 'Waikato', 'Napier', 'Wellington', 'Blenheim', 'Christchurch'],
    groups: [
      {
        heading: 'Relevant Forms',
        items: [
          {
            title: 'Health and Safety Checklist',
            url: 'https://drive.google.com/file/d/1zvuz9aNdlI1plyuzWo-eJFsD1guVXUuY/view?usp=sharing',
            icon: 'launch',
          },
          {
            title: 'Installation Checklist',
            url: 'https://drive.google.com/file/d/1eRe5u_fzcUoescCt26hWYENQNqu6z8ei/view?usp=sharing',
            icon: 'launch',
          },
          {
            title: 'End of Job Checklist',
            url: 'https://drive.google.com/file/d/1zvuz9aNdlI1plyuzWo-eJFsD1guVXUuY/view?usp=sharing',
            icon: 'launch',
          },
        ],
      },
      {
        heading: 'Photograph Checklist',
        items: [
          {
            title: 'Serial Numbers',
            file_tag: "Owner's Manual - Connection Diagram",
            icon: 'file',
          },
          {
            title: 'Roof Photograph',
            file_tag: "Owner's Manual - Post-Install Photograph",
            icon: 'file',
          },
          {
            title: 'Meterboard / Switchboard',
            file_tag: "Owner's Manual - Pre Commissioning Test",
            icon: 'file',
          },
          {
            title: 'Cable run and Conduit',
            file_tag: "Owner's Manual - Test and Commission",
            icon: 'file',
          },
          {
            title: 'Battery and Inverter Setup (Interior)',
            file_tag: "Owner's Manual - Array Mounting Certificate",
            icon: 'file',
          },
          {
            title: 'Battery and Inverter Setup (Exterior)',
            file_tag: "Owner's Manual - Equipment Documentation",
            icon: 'file',
          },
          {
            title: 'Carbon Track / Commissioning / Zigbees',
            file_tag: "Owner's Manual - Extra File",
            icon: 'file',
          },
        ],
      },
      {
        heading: 'Attached Files',
        items: [
          {
            title: 'Health and Safety Checklist',
            file_tag: "Owner's Manual - Connection Diagram",
            icon: 'file',
          },
          {
            title: 'Installation Checklist',
            file_tag: "Owner's Manual - Post-Install Photograph",
            icon: 'file',
          },
          {
            title: 'End of Job Checklist',
            file_tag: "Owner's Manual - Pre Commissioning Test",
            icon: 'file',
          },
        ],
      },
    ],
  },
  {
    heading: 'Compliance Documents',
    notes:
      'These documents are generated based on your location. There might be other documents that needs to be filled.',
    countries: ['NZ'],
    states: ['Auckland', 'Hamilton', 'Waikato', 'Napier', 'Wellington', 'Blenheim', 'Christchurch'],
    groups: [
      {
        heading: 'Distributed Generation Forms',
        items: [
          // Link not active anymore
          // {
          //   states: ['Auckland'],
          //   title: 'Vector',
          //   url: 'https://drive.google.com/file/d/1aWpMSy1JyldDOZqBA-1GgOSjRM7CdZV6/view?usp=sharing',
          //   icon: 'launch',
          // },
          {
            states: ['Auckland'],
            title: 'Counties Power',
            url: window.PUBLIC_URL + '/files/Counties Power.pdf',
            icon: 'launch',
          },
          {
            states: ['Hamilton'],
            title: 'WEL Networks',
            url: window.PUBLIC_URL + '/files/WEL Network form.pdf',
            icon: 'launch',
          },
          {
            states: ['Hamilton'],
            title: 'Waipa networks Ltd',
            url: window.PUBLIC_URL + '/files/Waipa Network.pdf',
            icon: 'launch',
          },
          {
            states: ['Waikato'],
            title: 'The Lines Company',
            url: window.PUBLIC_URL + '/files/The Lines Company.pdf',
            icon: 'launch',
          },
          {
            states: ['Napier'],
            title: 'Unison Networks Ltd',
            url: window.PUBLIC_URL + '/files/Unison DG New.pdf',
            icon: 'launch',
          },
          {
            states: ['Wellington'],
            title: 'Wellington Electricity',
            url: window.PUBLIC_URL + '/files/Wellington Electricity.pdf',
            icon: 'launch',
          },
          // Link not active anymore
          // {
          //   states: ['Blenheim'],
          //   title: 'Marlborough Lines Ltd',
          //   url: 'https://drive.google.com/file/d/1vOKnJ-R6S7ekV3YabU-D9xGbDBSX9Rpv/view?usp=sharing',
          //   icon: 'launch',
          // },
          {
            states: ['Christchurch'],
            title: 'Orion New Zealand Ltd',
            url: window.PUBLIC_URL + '/files/Wellington Electricity - DG Notification Less than 10kW - Template.pdf',
            icon: 'launch',
          },
        ],
      },
      // Links not active anymore
      // {
      //   heading: 'Other Compliance',
      //   items: [
      //     {
      //       states: ['Auckland', 'Hamilton', 'Waikato', 'Napier', 'Wellington', 'Blenheim', 'Christchurch'],
      //       title: 'Electrical Certificate Of Compliance & Electrical Safety Certificate',
      //       url:
      //         'https://www.datocms-assets.com/8364/1548109342-cocesc-template-commissioning-sheet-solarzero-battery-new.pdf',
      //       icon: 'launch',
      //     },
      //     {
      //       states: ['Auckland', 'Hamilton', 'Waikato', 'Napier', 'Wellington', 'Blenheim', 'Christchurch'],
      //       title: 'SolarZero PV Array and Battery Commissioning Sheet',
      //       url:
      //         'https://www.datocms-assets.com/8364/1548109342-cocesc-template-commissioning-sheet-solarzero-battery-new.pdf',
      //       icon: 'launch',
      //     },
      //   ],
      // },
      {
        heading: 'Attached Files',
        items: [
          {
            title: 'Distributed Generation Form',
            file_tag: 'Compliance - Electrical Compliance',
            icon: 'file',
          },
          {
            title: 'Electrical Certificate of Compliance',
            file_tag: 'Compliance - Connection Form',
            icon: 'file',
          },
          {
            title: 'SolarZero PV Array and Battery Commissioning Sheet',
            file_tag: 'Compliance - Other Document',
            icon: 'file',
          },
        ],
      },
    ],
  },
  {
    heading: 'Compliance Documents',
    notes:
      'These documents are generated based on your location. There might be other documents that needs to be filled.',
    countries: ['US'],
    states: ['CA'],
    groups: [
      {
        heading: 'Consumer Protection Docs',
        items: [
          {
            states: ['CA'],
            title: 'Solar Consumer Protection Guide',
            url:
              'https://www.cpuc.ca.gov/-/media/cpuc-website/divisions/energy-division/documents/solar-guide/solarguide22_011922.pdf',
            icon: 'launch',
          },
          {
            states: ['CA'],
            title: 'Residential Contract Cover Sheet',
            url: 'http://www.cslb.ca.gov/Resources/Contractors/SolarDisclosureDoc.pdf',
            icon: 'launch',
          },
        ],
      },
    ],
  },
] as ComplianceFormType[]

import { Dialog, DialogContent, DialogTitle, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import type { OpenSolarThemeType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import Alert from 'elements/Alert'
import Button from 'elements/button/Button'
import CloseDialogButton from 'elements/button/CloseDialogButton'
import React, { useCallback } from 'react'
import { DatagridLoading, useNotify } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { saveFileAs } from 'util/misc'
import BomDetailTable from './BomDetailTable'
import type { BomDataResponseType, BomDataType, DownloadBomFileType, DownloadBomPricingType } from './type'

type BomPopupType = {
  isOpen: boolean
  closeDialog(): void
  bomData: BomDataType | undefined
  getBomData: <T extends DownloadBomFileType, W extends DownloadBomPricingType>(
    fileType: T,
    pricingType: W
  ) => Promise<BomDataResponseType<T> | undefined> | undefined
  onUpdatePricingSelector: (pricingType: DownloadBomPricingType) => void
  bomDataLoading: boolean
}

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  downloadButton: {
    padding: '0px 20px',
    marginTop: '16px',
    marginBottom: '16px',
  },
  radioGroup: { display: 'flex', flexDirection: 'row' },
  pricingRadioGroup: { display: 'flex', flexDirection: 'column', marginBottom: '16px' },
}))

const BomPopup = ({
  isOpen,
  closeDialog,
  bomData,
  getBomData,
  onUpdatePricingSelector,
  bomDataLoading,
}: BomPopupType) => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [errorMsg, setErrorMsg] = React.useState<string | undefined>(undefined)
  const [fileType, setFileType] = React.useState<'pdf' | 'csv'>('csv')
  const [pricingType, setPricingType] = React.useState<DownloadBomPricingType>('distributor')
  const notify = useNotify()
  const classes = useStyles()

  const handleDownloadBomSuccess = useCallback(
    (downloadUrl: string, fileType: 'pdf' | 'csv', pricingType: DownloadBomPricingType) => {
      saveFileAs(downloadUrl, 'OpenSolar_BOM.pdf')
      notify("Your' BOM has been generated and saved to the project", 'success')
      logAmplitudeEvent('global_bom_downloaded', { file_type: fileType, pricing_type: pricingType })
    },
    []
  )

  const handleDownloadBomFailed = useCallback((fileType: 'pdf' | 'csv', pricingType: DownloadBomPricingType) => {
    logAmplitudeEvent('global_bom_download_error', { file_type: fileType, handled: 'no', pricing_type: pricingType })
    setErrorMsg('We were unable to generate the Bill of Materials')
  }, [])

  const downloadBom = useCallback(async () => {
    setLoading(true)
    if (errorMsg) setErrorMsg(undefined)
    const bomData = await getBomData(fileType, pricingType)
    setLoading(false)
    if (!bomData?.success || !bomData?.download_url) {
      handleDownloadBomFailed(fileType, pricingType)
    } else {
      handleDownloadBomSuccess(bomData.download_url, fileType, pricingType)
    }
  }, [fileType, pricingType])

  const handleFileType = useCallback((e) => {
    setFileType(e.target.value)
  }, [])

  const handlePricingType = useCallback((e) => {
    const value = e.target.value
    setPricingType(value)
    onUpdatePricingSelector(value)
  }, [])

  return (
    <div>
      <Dialog fullWidth={true} maxWidth="xl" open={isOpen} onBackdropClick={closeDialog}>
        <DialogTitle>
          Bill of Materials
          <CloseDialogButton onClose={closeDialog} />
        </DialogTitle>
        <DialogContent>
          <section>
            {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
            <p>What pricing would you like to download?</p>
            <RadioGroup
              value={pricingType}
              onChange={(e) => handlePricingType(e)}
              className={classes.pricingRadioGroup}
            >
              <FormControlLabel label="Manual Pricing" value="manual" control={<Radio />} />
              <FormControlLabel label="Distributor Pricing" value="distributor" control={<Radio />} />
              <FormControlLabel label="No Pricing" value="none" control={<Radio />} />
            </RadioGroup>
          </section>
          {!bomData || bomDataLoading ? (
            <DatagridLoading hasBulkActions={true} nbChildren={5} size={'small'} />
          ) : (
            <BomDetailTable bomData={bomData} />
          )}
          <section>
            {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
            <p>What file format would you like to download?</p>
            <RadioGroup value={fileType} onChange={(e) => handleFileType(e)} className={classes.radioGroup}>
              <FormControlLabel label="CSV" value="csv" control={<Radio />} />
              <FormControlLabel label="PDF" value="pdf" control={<Radio />} />
            </RadioGroup>
            <div className={classes.downloadButton}>
              <Button color="primary" onClick={downloadBom} loading={loading}>
                Download BOM
              </Button>
            </div>
          </section>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default BomPopup

// @ts-nocheck
import { makeStyles, useMediaQuery } from '@material-ui/core'
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined'
import StarIcon from '@material-ui/icons/StarOutlined'
import { orgSelectors } from 'ducks/orgs'
import ProjectName from 'elements/ProjectName'
import SharedEntityIcon from 'elements/connectedOrgs/SharedEntityIcon'
import { Button, Chip } from 'opensolar-ui'
import React from 'react'
import { Datagrid, DateField, FunctionField, List, PaginationComp, useTranslate } from 'react-admin'
import { connect, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import compose from 'recompose/compose'
import { ProjectType } from 'types/projects'
import { getNMReportsLink, getRoleFromState } from 'util/misc'
import StarButton from './elements/StarButton'
import { ProjectFilter } from './projectsList/ProjectFilter'
import { ProjectListSearchTitle } from './projectsList/ProjectListSearchTitle'
import ProjectListSelector from './projectsList/ProjectListSelector'

const ProjectListRowSmall = (props) => {
  const { record } = props
  const translate = useTranslate()
  return (
    <div style={{ position: 'relative' }}>
      <StarButton record={record} style={{ position: 'absolute', top: -10, right: 0 }} />
      <h3
        style={{
          fontSize: '16px',
          margin: '10px 0px 10px 0px',
          textOverflow: 'ellipsis',
        }}
      >
        <Link to={`/projects/${record.id}/info`}>
          <ProjectName business_name={record.business_name} address={record.address} />
        </Link>
      </h3>
      {record.created_date && record.modified_date && (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              marginTop: 10,
              marginBottom: 10,
              maxWidth: 273,
            }}
          >
            <div style={{ flex: '1 1 50px', color: '#B1B1B1' }}>{translate('Date Created')}</div>
            <DateField record={record} source="created_date" showDate />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              marginTop: 10,
              marginBottom: 10,
              maxWidth: 273,
            }}
          >
            <div style={{ flex: '1 1 50px', color: '#B1B1B1' }}>{translate('Last Modified')}</div>
            <DateField record={record} source="modified_date" showDate />
          </div>
        </>
      )}
    </div>
  )
}

const OverridePagination = connect((state) => {
  var role = getRoleFromState(state)
  return { isAdmin: role && role.is_admin }
})((props) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const isNearmapOrg = useSelector(orgSelectors.getIsNearmapOrg)
  return isMobile || !isNearmapOrg ? (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <PaginationComp {...props} />
    </div>
  ) : (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button
        style={{ margin: 8 }}
        className={'MuiButton-textPrimary'}
        onClick={() => {
          window.open(getNMReportsLink(), '_blank')
        }}
        startIcon={<OpenInNewOutlinedIcon />}
      >
        <span>Historic Nearmap Reports</span>
      </Button>
      <PaginationComp {...props} />
    </div>
  )
})

const useStyles = makeStyles((theme) => ({
  addressCell: {
    fontWeight: 'bold',
    fontSize: '1em',
    margin: 0,
    textOverflow: 'ellipsis',
    display: 'flex',
    alignItems: 'center',
  },
  list: {
    maxWidth: 1200,
    marginLeft: 'auto',
    marginRight: 'auto',

    '& th:nth-child(1)': {
      width: 30,
      marginLeft: 2,
    },
    '& th:nth-child(2)': {},
    '& th:nth-child(3)': {
      width: 150,
    },
    '& th:nth-child(4)': {
      width: 150,
      boxSizing: 'border-box',
    },
    '& .Ra-List-Main': {
      padding: 0,
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    '& .MuiTableCell-sizeSmall': {
      padding: 6,
    },
  },
}))

export const ProjectListLite = (props) => {
  const translate = useTranslate()
  const classes = useStyles()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  // Hack only required for project page which is the default page
  // Until we are logged in, avoid trying to load a List page which will make a failed call to back-end
  if (!props.userId) {
    return null
  }

  return (
    <List
      hideBreadCrumbs={true}
      pagination={<OverridePagination rowsPerPageOptions={[5, 10, 20, 25, 50]} />}
      showButtons={true}
      className={classes.list}
      title={<ProjectListSearchTitle endAdornment={<ProjectListSelector />} />}
      filters={<ProjectFilter />}
      perPage={20}
      {...props}
      hasCreate={false}
    >
      {isMobile ? (
        <Datagrid>
          <ProjectListRowSmall source="address" {...props} />
        </Datagrid>
      ) : (
        <Datagrid>
          <FunctionField
            source="stars"
            label={<StarIcon style={{ color: '#4d4d4d', viewBox: '0 -5 24 29', padding: 8 }} />}
            render={(record: ProjectType) => <StarButton record={record} />}
          />

          <FunctionField
            source="address"
            render={(record: ProjectType) => (
              <p className={classes.addressCell}>
                <Link to={record.is_lite ? `/projects/${record.id}/design` : `/projects/${record.id}/info`} style={{}}>
                  <ProjectName business_name={record.business_name} address={record.address} />
                </Link>
                <SharedEntityIcon owner={record.org_id} shared_with={record.shared_with} margin="wide" />
                {record.is_lite && <Chip style={{ marginLeft: '20px' }} label={translate('Lite')} />}
              </p>
            )}
          />

          <DateField source="created_date" showDate label="Date Created" />
          <DateField source="modified_date" showDate label="Last Modified" />
        </Datagrid>
      )}
    </List>
  )
}

const mapStateToPropsList = (state) => {
  return {
    userId: state.auth && state.auth.user ? state.auth.user.id : null,
    orgId: state.auth ? state.auth.org_id : null,
    recentProjects: state.recentProjects,
  }
}

export default compose(connect(mapStateToPropsList, {}))(ProjectListLite)

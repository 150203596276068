import { logAmplitudeEvent } from 'amplitude/amplitude'
import BannerAd from 'elements/BannerAd'
import { useEffect } from 'react'
import TopBanner from './banner/TopBanner'
import Order from './order/Order'
import ProjectTable from './projectTable/ProjectTable'

const OrderByProjectPage = () => {
  useEffect(() => {
    logAmplitudeEvent('page_viewed', {
      page: 'order_by_project',
    })
  }, [])

  return (
    <div>
      <TopBanner adBannerPlacement="shop_order_by_project_top" showOBPBanner={false} />
      <ProjectTable />
      <Order />
      <BannerAd
        formats={['leaderboard']}
        placement={`shop_order_by_project_bottom`}
        containerStyle={{ marginTop: 10, marginBottom: 20 }}
        requiredWindowSize={{ width: 960 }}
      />
    </div>
  )
}

export default OrderByProjectPage

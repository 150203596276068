import { RootState } from 'types/state'

export const tourSelectors = {
  tour: (state: RootState) => {
    return state.tour.tour
  },
  step: (state: RootState) => {
    return state.tour.step
  },
}

export type FujiTourType = 'main' | 'manage' | 'project_list' | 'payments'

export type ShowTourBanner = {
  [key in FujiTourType]?: {
    shouldShow: boolean
    userHidIt: boolean
  }
}

// @ts-nocheck
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { getShouldRefreshBOM } from 'Designer/integrations/ironRidge/actions'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import restClient from 'restClient'
import { ProjectType } from 'types/projects'
import { SystemDataType } from 'types/systems'
import { parseIntegrationJson } from 'util/misc'

type PropTypes = {
  project: ProjectType
  showDialog: boolean
  onClose: () => void
  mode: 'studio' | 'button'
  hasUnsavedChanges?: boolean
  preselected_uuid?: string
  isIronridge: boolean
}

const DownloadBOMDialog: React.FunctionComponent<PropTypes> = (props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [systemUuid, setSystemUuid] = useState<string | undefined>(props.preselected_uuid || undefined)
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [systems, setSystems] = useState<any[]>([])
  const [IRsystems, setIRSystems] = useState<any[]>([])
  const [fileType, setFileType] = useState<'pdf' | 'csv'>('csv')

  const notify = useNotify()
  const translate = useTranslate()

  useEffect(() => {
    if (props.isIronridge) {
      let shouldRefresh = getShouldRefreshBOM()
      if (shouldRefresh) {
        setDisabled(true)
        logAmplitudeEvent('ironridge_bom_download_dialog_refresh_warning', {})
        setErrorMsg(
          'Changes have been made to your system design(s) that may affect the IronRidge Bill of Materials. Please refresh your IronRidge Bill of Materials in studio before downloading.'
        )
      } else {
        let integratedSystemCount = 0
        window.editor.getSystems()?.forEach((sys) => {
          if (!!sys?.integration_json) {
            try {
              let oldIRData = parseIntegrationJson(sys.integration_json)?.ironridge?.project
              if (oldIRData) integratedSystemCount++
            } catch (ex) {}
          }
        })
        if (integratedSystemCount === 0) {
          setDisabled(true)
          setErrorMsg('Please refresh your Bill of Materials in studio before downloading.')
        }
      }
    }
  }, [])

  // clear out error when dialog is closed
  useEffect(() => {
    if (!props.showDialog && errorMsg) setErrorMsg(undefined)
  }, [props.showDialog, errorMsg])

  useEffect(() => {
    if (props.showDialog) {
      if (props.isIronridge) {
        logAmplitudeEvent('ironridge_bom_download_dialog_opened', {})
      } else {
        logAmplitudeEvent('global_bom_download_dialog_opened', {})
      }
    }
  }, [props.showDialog])

  useEffect(() => {
    if (props.hasUnsavedChanges) {
      setDisabled(true)
      setErrorMsg('Please save your project first.')
    }
  }, [props.hasUnsavedChanges])

  useEffect(() => {
    if (props.isIronridge && systems && systemUuid) {
      let chosenSystem = systems?.find((sys) => sys.uuid === systemUuid)
      if (chosenSystem && chosenSystem.integration_json) {
        if (parseIntegrationJson(chosenSystem.integration_json)?.ironridge?.is_disconnected) {
          setErrorMsg(
            'This system has been disconnected from IronRidge. If you would like to re-activate it you can do so by clicking the IronRidge button in Studio.'
          )
          setDisabled(true)
        }
      }
    }
  }, [systems, systemUuid])

  // for IronRidge - a bit gross, but depending on what's happening in the app the source of truth for systems can be different. Give preference to project.systems but if not found use systems array from editor
  useEffect(() => {
    if (props.showDialog) {
      let foundIronridge = false
      const systemsFromStudio = window.editor.getSystems()
      if (systemsFromStudio && systemsFromStudio.length > 0) {
        //the format of system is different when taken from editor. Format it to match project.systems
        const formattedStudioSystems = systemsFromStudio.map((system) => {
          system.title =
            system.name && system.name.length > 0
              ? system.name
              : `${system.moduleQuantity()} ${translate('Panels System')}`
          return system
        })
        const withIr = filterNonIrSystems(formattedStudioSystems)
        if (props.isIronridge) {
          if (withIr && withIr.length > 0) {
            foundIronridge = true
            setIRSystems(withIr)
          }
        }
        setSystems(formattedStudioSystems)
      } else {
        if (props.isIronridge && !foundIronridge) setIRSystems(filterNonIrSystems(props.project.systems))
        setSystems(props.project.systems)
      }
    }
  }, [props.showDialog, props.project?.systems])

  //if there is only one viable system, select it for them
  useEffect(() => {
    if (!systemUuid && systems && systems.length === 1 && !!systems[0].uuid) setSystemUuid(systems[0].uuid)
  }, [systems, systemUuid])

  const filterNonIrSystems = (allSystems: any[]) => {
    if (!props.isIronridge) return allSystems
    if (!allSystems || allSystems.length === 0) return []
    let withIr = allSystems.filter((system) => {
      if (!system.integration_json) return false
      try {
        return !!parseIntegrationJson(system.integration_json).ironridge
      } catch (ex) {
        return system.integration_json?.includes('ironridge')
      }
    })
    return withIr
  }

  const downloadBOM = () => {
    if (!systemUuid) {
      setErrorMsg('Please select a system')
      return
    } else if (!props.project?.id) {
      setErrorMsg('Please save your project first')
      return
    }
    setLoading(true)
    const restClientInstance = restClient(window.API_ROOT + '/api')
    let url
    let choseIronRidgeSystem = false
    if (props.isIronridge) {
      IRsystems.forEach((sys) => {
        if (sys.uuid === systemUuid) choseIronRidgeSystem = true
      })
    }
    if (props.isIronridge && choseIronRidgeSystem)
      url =
        'orgs/' +
        props.project.org_id +
        '/projects/' +
        props.project.id +
        '/systems/' +
        systemUuid +
        '/ironridge/download_bom/?file_type=' +
        fileType
    else
      url =
        'orgs/' +
        props.project.org_id +
        '/projects/' +
        props.project.id +
        '/systems/' +
        systemUuid +
        '/download_bom/?file_type=' +
        fileType
    restClientInstance('CUSTOM_GET', 'custom', {
      url: url,
    })
      .then((res: any) => {
        console.log('res', res)
        if (!res?.data?.success || !res?.data?.download_url) {
          setErrorMsg('We were unable to generate the Bill of Materials')
          if (props.isIronridge) {
            logAmplitudeEvent('ironridge_bom_download_error', { file_type: fileType, handled: 'no' })
          } else {
            logAmplitudeEvent('global_bom_download_error', { file_type: fileType, handled: 'no' })
          }
        } else {
          if (errorMsg) setErrorMsg(undefined)
          const url = res.data.download_url
          const link = document.createElement('a')
          link.href = url
          if (props.isIronridge) {
            link.setAttribute('download', 'IronRidge_BOM.pdf')
          } else {
            link.setAttribute('download', 'OpenSolar_BOM.pdf')
          }

          link.setAttribute('target', '_blank')
          document.body.appendChild(link)
          link.click()
          notify(
            `${props.isIronridge ? 'Your Ironridge ' : 'Your'} BOM has been generated and saved to the project`,
            'success'
          )
          props.onClose()
          if (props.isIronridge) {
            logAmplitudeEvent('ironridge_bom_downloaded', { file_type: fileType })
          } else {
            logAmplitudeEvent('global_bom_downloaded', { file_type: fileType })
          }
        }
      })
      .catch((err: any) => {
        if (err?.body?.message) {
          setErrorMsg(err.body.message)
          if (props.isIronridge) {
            logAmplitudeEvent('ironridge_bom_download_error', {
              file_type: fileType,
              handled: 'yes',
              error: err.body.message,
            })
          } else {
            logAmplitudeEvent('global_bom_download_error', {
              file_type: fileType,
              handled: 'yes',
              error: err.body.message,
            })
          }
        } else {
          setErrorMsg('We were unable to generate the Bill of Materials')
          if (props.isIronridge) {
            logAmplitudeEvent('ironridge_bom_download_error', { file_type: fileType, handled: 'no' })
          } else {
            logAmplitudeEvent('global_bom_download_error', { file_type: fileType, handled: 'no' })
          }
        }
      })
      .finally(() => setLoading(false))
  }

  //look for a sold system and use that if found
  useEffect(() => {
    if (props.project?.system_sold) {
      let soldSystem: SystemDataType | undefined = undefined
      props.project?.systems?.forEach((system) => {
        if (system.url === props.project.system_sold) soldSystem = system
      })
      if (soldSystem) setSystemUuid(soldSystem.uuid)
    }
  }, [props.project?.system_sold])

  const handleChange = (e: ChangeEvent) => setSystemUuid(e.target.value)

  if (props.mode === 'studio') {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ marginTop: '60px' }}>
          <p>{translate('What file format would you like to download?')}</p>
          <RadioGroup
            value={fileType}
            onChange={(e) => setFileType(e.target.value)}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <FormControlLabel label="CSV" value="csv" control={<Radio />} />
            <FormControlLabel label="PDF" value="pdf" control={<Radio />} />
          </RadioGroup>
        </div>
        {errorMsg && <div style={{ color: 'red', textAlign: 'center', margin: '15px 0px' }}>{errorMsg}</div>}
        {loading && (
          <div
            style={{
              textAlign: 'center',
              marginTop: '20px',
              backgroundColor: '#f2e7a5',
              borderRadius: '5px',
              padding: '5px 20px',
              opacity: 0.7,
            }}
          >
            {translate(
              'Your BOM is being generated. Please make sure your browser will allow popups from OpenSolar. If the popup is blocked you can refresh this tab and go to the Info page to see the file.'
            )}{' '}
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            margin: '20px 0px',
          }}
        >
          <div style={{ padding: '0px 20px' }}>
            <ProUXButton type="secondary" label="Dismiss" onClick={props.onClose} />
          </div>
          <div style={{ padding: '0px 20px' }}>
            <ProUXButton
              type="primary"
              label="Download BOM"
              onClick={downloadBOM}
              loading={loading}
              disabled={!systemUuid || disabled}
            />
          </div>
        </div>
      </div>
    )
  }
  return (
    <div style={{ width: '100%', marginTop: '10px' }}>
      {props.showDialog && (
        <Dialog open={props.showDialog} onClose={props.onClose}>
          <DialogTitle>
            {props.isIronridge
              ? translate(`Download Your IronRidge Bill of Materials`)
              : translate(`Download Your Bill of Materials`)}
          </DialogTitle>
          <DialogContent>
            <p>
              {translate("Use the form below to select the system you'd like to download your Bill of Materials for")}:
            </p>
            <RadioGroup value={systemUuid} onChange={handleChange}>
              {systems?.map((system) => {
                let showNotIntegrated = false
                if (props.isIronridge) {
                  try {
                    if (!system.integration_json || !system.integration_json.ironridge) showNotIntegrated = true
                  } catch (ex) {
                    console.log('ex', ex)
                  }
                }

                return (
                  <div>
                    <FormControlLabel
                      key={system.uuid}
                      label={system.title || `${system.module_quantity} panels`}
                      value={system.uuid}
                      control={<Radio />}
                    />
                    {showNotIntegrated && (
                      <span className="small">({translate('Not currently integrated with IronRidge')})</span>
                    )}
                  </div>
                )
              })}
            </RadioGroup>
            <div style={{ marginTop: '60px' }}>
              <p>{translate('What file format would you like to download?')}</p>
              <RadioGroup
                value={fileType}
                onChange={(e) => setFileType(e.target.value)}
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <FormControlLabel label="CSV" value="csv" control={<Radio />} />
                <FormControlLabel label="PDF" value="pdf" control={<Radio />} />
              </RadioGroup>
            </div>
            {errorMsg && <div style={{ color: 'red', textAlign: 'center', margin: '15px 0px' }}>{errorMsg}</div>}
            {loading && (
              <div
                style={{
                  textAlign: 'center',
                  marginTop: '20px',
                  backgroundColor: '#f2e7a5',
                  borderRadius: '5px',
                  padding: '5px 20px',
                  opacity: 0.7,
                }}
              >
                {translate(
                  'Your BOM is being generated. Please make sure your browser will allow popups from OpenSolar. If the popup is blocked you can refresh this tab and go to the Info page to see the file.'
                )}{' '}
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                margin: '20px 0px',
              }}
            >
              <div style={{ padding: '0px 20px' }}>
                <ProUXButton type="secondary" label="Dismiss" onClick={props.onClose} />
              </div>
              <div style={{ padding: '0px 20px' }}>
                <ProUXButton
                  type="primary"
                  label="Download BOM"
                  onClick={downloadBOM}
                  loading={loading}
                  disabled={!systemUuid || disabled}
                />
              </div>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </div>
  )
}

export default DownloadBOMDialog

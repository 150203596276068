import { Button, ShopCartIcon, styled } from 'opensolar-ui'

const StyledButton = styled(Button)(({ theme }) => ({
  background: '#4272DD',
  borderRadius: '6px',
  fontSize: '15px',
  width: '100%',
  padding: '0 10px',
  minHeight: '40px',
  justifyContent: 'center',
  color: 'white',
  '&:hover': {
    border: 'none',
    background: '#113B98',
  },
}))

export const AddToCartButton = ({ onClick }) => {
  return (
    <StyledButton fullWidth startIcon={<ShopCartIcon size={20} />} onClick={onClick}>
      Add to cart
    </StyledButton>
  )
}

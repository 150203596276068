var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
export var StepperCheckIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? '1rem' : _b, _c = _a.color, color = _c === void 0 ? 'currentColor' : _c, rest = __rest(_a, ["size", "color"]);
    var iconAttributes = {
        width: size,
        height: size,
        stroke: color,
    };
    return (_jsx(_Fragment, { children: _jsx("svg", __assign({ width: "8", height: "9", viewBox: "0 0 8 9", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { id: "Vector", d: "M1.60736 4.40019C2.09581 4.66241 2.41402 4.88027 2.79287 5.26898C3.77528 3.79649 4.96059 2.15112 6.34721 0.993002L6.48283 0.944336H8C5.96571 3.04825 4.37686 5.51867 2.85682 8.05545C1.94321 6.62176 1.32841 5.69876 0 4.73838L1.60736 4.40019Z", fill: "white" }) })) }));
};

import { UiSwitch } from 'elements/UiSwitch'
import jwt from 'jwt-decode'
import getSsoStartLink from 'pages/auth/sso/getSsoStartLink'
import { FC, useMemo, useState } from 'react'
import { usePermissions } from 'react-admin'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { storeAuthRedirectionToLocalStorage } from 'redirections/authRedirectionStore'
import appStorage from 'storage/appStorage'
import { pathnameAndParamsWithUrlFragment } from './common'
import LoginPageActions from './components/auth/actions/LoginPageActions'
import LoginWithPartnerPrompt from './components/auth/actions/LoginWithPartnerPrompt'
import WaitForNewTokenThenAutoLogin from './components/auth/actions/WaitForNewTokenThenAutoLogin'
import LoginForm from './components/auth/LoginForm'
import { SsoLoginPage } from './components/auth/SsoLoginPage'
import useLoginStep from './components/auth/useLoginStep'
import useLogoByUrlFragment from './components/logos/useLogoByUrlFragment'
import { AuthConfigType, SsoAuthConfigType } from './types'

const getEmailFromNearmapToken = () => {
  try {
    var nearmap_token = appStorage.getString('nearmap_token')
    if (nearmap_token) {
      var decodedToken: JWT | undefined = jwt(nearmap_token)
      if (decodedToken && decodedToken.email) {
        return decodedToken.email
      }
    }
  } catch (e) {
    return null
  }
}

interface JWT {
  email: string | undefined
}

// TODO: try to combine urlFragment into loginConfig
const LoginPage: FC<{
  loginConfigs: AuthConfigType[]
  urlFragment?: string
  theme: string
}> = ({ loginConfigs, urlFragment, theme }) => {
  const [explicitlyShown, setExplicitlyShown] = useState<string | undefined>()
  const location = useLocation<{ nextPathname?: string; nextSearch?: string }>()
  const history = useHistory()
  const step = useLoginStep()
  const Logo = useLogoByUrlFragment({ urlFragment })

  const shownLoginConfig: AuthConfigType = useMemo(() => {
    // First look for the auth that the user has explicitly chosen
    if (explicitlyShown !== undefined) {
      const explicitConfig = loginConfigs.find((authConfig) => authConfig.id === explicitlyShown)
      if (explicitConfig) return explicitConfig
    }

    // Otherwise look for the SSO auth that matches the current theme (i.e. nearmap)
    const themeMatched = loginConfigs.find(
      (l) => l.type === 'sso' && l.showIfThemeMatch && l.showIfThemeMatch === theme
    )
    if (themeMatched) return themeMatched

    // Otherwise, fallback to the first auth in the list
    return loginConfigs[0]
  }, [explicitlyShown, theme, loginConfigs])

  const { loaded, permissions } = usePermissions()
  const [loginConfigInUse, setLoginConfigInUse] = useState<any>(null)

  const onSwitchToLogin = (to: AuthConfigType) => {
    setExplicitlyShown(to.id)
  }

  const onCancel = () => {
    history.push('/logout')
  }

  const onMfaFinish = () => {
    history.push('/home')
  }

  const onForgotPassword = () => {
    history.push(pathnameAndParamsWithUrlFragment('/password-reset', location, urlFragment))
  }

  const onRegister = () => {
    history.push(pathnameAndParamsWithUrlFragment('/register', location, urlFragment))
  }

  const onSsoLogin = (loginConfig) => {
    if (loginConfig.sso) {
      const ssoLink = getSsoStartLink({ partnerId: loginConfig.sso.config.partner_id })

      storeAuthRedirectionToLocalStorage()
      if (ssoLink) window.location.href = ssoLink
    }
  }

  // Do not redirect any nearmap flows
  const isNearmapUrl = urlFragment && urlFragment.toLowerCase().includes('nearmap')

  if (loaded && !isNearmapUrl && step === 'login' && permissions.includes('is_user')) {
    console.debug('Login complete, redirecting to /home')
    return (
      <>
        <Redirect to={'/home'} />
      </>
    )
  }

  if (loginConfigInUse) {
    return (
      <UiSwitch uiKey="account_modal.auto_login">
        <WaitForNewTokenThenAutoLogin
          loginConfig={loginConfigInUse}
          finish={() => {
            setLoginConfigInUse(null)
          }}
          cancel={() => {
            setLoginConfigInUse(null)
          }}
        />
      </UiSwitch>
    )
  }

  if (!shownLoginConfig) return <>Something went wrong, incorrect auth config</>

  // Sunnova Catalyst does not currently use SsoLoginPage, allow it to continue and use LoginWithPartnerPrompt below
  if (shownLoginConfig.type === 'sso' && shownLoginConfig.partner_id !== 'salesforce_sdk') {
    return (
      <UiSwitch uiKey="account_modal.sso">
        <SsoLoginPage
          config={shownLoginConfig}
          allLoginConfigs={loginConfigs}
          onRegister={onRegister}
          onSwitchToLogin={onSwitchToLogin}
        />
      </UiSwitch>
    )
  }

  const ssoConfigs = loginConfigs.filter((a) => a.type === 'sso') as SsoAuthConfigType[]

  return (
    <LoginForm
      initialValues={{ email: getEmailFromNearmapToken() }}
      onMfaFinish={onMfaFinish}
      onMfaCancel={onCancel}
      Logo={Logo}
      AdditionalActions={
        <LoginPageActions
          onForgotPassword={onForgotPassword}
          onRegister={onRegister}
          PartnerPrompts={ssoConfigs.map((ssoConfig) => {
            return (
              <LoginWithPartnerPrompt
                partnerId={ssoConfig.partner_id}
                displayName={ssoConfig.displayName}
                userPassRedirectUri={ssoConfig.userPassRedirectUri}
                activate={() => {
                  setLoginConfigInUse(ssoConfig)
                }}
              />
            )
          })}
        />
      }
    />
  )
}

export default LoginPage

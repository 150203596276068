var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import { default as Mui5Alert } from '@mui/material/Alert';
import classnames from 'classnames';
import { forwardRef } from 'react';
import { Inspector } from '../debug/Inspector';
import { useComponentVersion } from '../hooks';
import { AlertIcon } from './AlertIcon';
// * MUI Alert API Spec  https://mui.com/material-ui/react-alert/
export var Alert = function (props) {
    return _jsx(Inspector, { name: "Alert", propDefs: AlertProps, showExample: true, component: OsAlert, props: props });
};
export var OsAlertVersion;
(function (OsAlertVersion) {
    OsAlertVersion[OsAlertVersion["V1"] = 1] = "V1";
    OsAlertVersion[OsAlertVersion["V2"] = 2] = "V2";
})(OsAlertVersion || (OsAlertVersion = {}));
var OsAlert = forwardRef(function (props, ref) {
    var _a = props.severity, severity = _a === void 0 ? 'info' : _a, _b = props.variant, variant = _b === void 0 ? 'standard' : _b, rest = __rest(props, ["severity", "variant"]);
    var version = useComponentVersion('alert');
    switch (version) {
        case OsAlertVersion.V2:
            return (_jsx(Mui5Alert, __assign({ ref: ref, variant: variant, icon: _jsx(AlertIcon, { severity: severity }), severity: severity }, rest)));
        default:
            return _jsx(V1Alert, __assign({ ref: ref, icon: _jsx(AlertIcon, { severity: severity }), severity: severity }, rest));
    }
});
var AlertProps = {
    severity: { default: 'info' },
    variant: { default: 'standard' },
    color: {},
    icon: { hidden: true },
    actions: { hidden: true },
    children: { hidden: true },
};
var v1Styles = makeStyles(function (theme) { return ({
    wrapper: function (_a) {
        var severity = _a.severity;
        return ({
            display: 'flex',
            background: theme['alertBg_' + severity],
            color: theme['alert_' + severity],
            borderRadius: 4,
            padding: 16,
            alignItems: 'center',
        });
    },
    content: {
        paddingTop: 3,
        width: '100%',
    },
}); });
var V1Alert = forwardRef(function (props, ref) {
    var severity = props.severity, children = props.children, icon = props.icon, className = props.className, rest = __rest(props, ["severity", "children", "icon", "className"]);
    var classes = v1Styles({ severity: severity });
    return (_jsxs("div", __assign({ className: classnames(classes.wrapper, className), ref: ref }, rest, { children: [icon, _jsx("div", __assign({ className: classes.content }, { children: children }))] })));
});

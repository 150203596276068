import Button from 'elements/button/Button'
import CustomField from 'elements/field/CustomField'
import { renderInput } from 'pages/auth/common'
import { useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { Form } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import useHdmService from 'services/hdm/useHdmService'
import { composeValidators, emailFormat, required } from 'validations'

const HdmSignupForm = () => {
  const initialValues = {
    email: '',
  }
  const translate = useTranslate()
  const hdmService = useHdmService()
  const history = useHistory()
  const notify = useNotify()
  const [customerId, setCustomerId] = useState<string | null>(null)

  const handleSubmit = async (values) => {
    const { email, firstname, lastname } = values

    const response = await hdmService.createCustomer({ email, firstname, lastname })
    if (response?.status_code === 500) {
      notify(`Could not create HDM customer: ${response.content?.error} `, 'warning')
    } else if (response?.customer_id) {
      setCustomerId(response.customer_id)
      notify('Customer created successfully', 'info')
      history.push('/shop')
    }
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting }) => {
        return (
          <>
            <CustomField
              name="email"
              type="email"
              validate={composeValidators(required, emailFormat)}
              component={renderInput}
              label={translate('email')}
              fullWidth={true}
              inputProps={{
                id: 'hdm-connect-form-email',
              }}
            />
            <CustomField
              name="firstname"
              type="text"
              validate={required}
              component={renderInput}
              label={translate('First Name')}
              fullWidth={true}
              inputProps={{
                id: 'hdm-connect-form-firstname',
              }}
            />
            <CustomField
              name="lastname"
              type="text"
              validate={required}
              component={renderInput}
              label={translate('Last Name')}
              fullWidth={true}
              inputProps={{
                id: 'hdm-connect-form-lastname',
              }}
            />
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              disabled={submitting}
              loading={submitting}
            >
              Signup to HDM
            </Button>
          </>
        )
      }}
    />
  )
}

export default HdmSignupForm

import { Reducer } from 'redux'
import { COMPONENT_ACTIVATION_END, COMPONENT_ACTIVATION_REQUEST, COMPONENT_ACTIVATION_START } from 'actions/designer'

export interface activeComponentReducerState {
  processing: any
}

const defaultState = {
  processing: new Set(),
}

export const activeComponentReducer: Reducer<activeComponentReducerState> = (
  previousState = defaultState,
  { type, payload }
) => {
  if (type === COMPONENT_ACTIVATION_START) {
    const newQueue = new Set(previousState.processing)
    const { codes = [] } = payload
    codes.forEach((code: string) => {
      newQueue.add(code)
    })
    return {
      ...previousState,
      processing: newQueue,
    }
  } else if (type === COMPONENT_ACTIVATION_END) {
    const newQueue = new Set(previousState.processing)
    const { codes = [] } = payload
    codes.forEach((code: string) => {
      newQueue.delete(code)
    })
    return {
      ...previousState,
      processing: newQueue,
    }
  } else {
    return previousState
  }
}

import SuccessIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import ErrorIcon from '@material-ui/icons/ErrorOutlineOutlined'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import WarningIcon from '@material-ui/icons/ReportProblemOutlined'

export const variantIcon = {
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
  success: SuccessIcon,
}

export type Severities = 'error' | 'warning' | 'info' | 'success'

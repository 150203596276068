import { getQueryVariable } from 'util/query'
import { ORG_SWITCHER_PATH } from './QueryParamOrgSwitcher'

const REDIRECT_QUERY_KEY = 'redirect'
const DEFAULT_REDIRECT_PATH = '/home'

export const getOrgSwitcherRedirect = () => {
  return getQueryVariable(REDIRECT_QUERY_KEY) || DEFAULT_REDIRECT_PATH
}

export const convertRedirectToOrgSwitcher = ({ redirect, orgId }: { redirect: string; orgId: number }) => {
  return `/${ORG_SWITCHER_PATH}/${orgId}?${REDIRECT_QUERY_KEY}=${encodeURIComponent(redirect)}`
}

import { Tooltip } from '@material-ui/core'
import { ArchiveOutlined } from '@material-ui/icons'
import { paymentOptionSelectionSelectors } from 'ducks/paymentOptionSelection'
import { Chip } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type PropTypes = {
  id: number
  integration: string | undefined | null
  isSold: boolean
}

const useStyles = makeOpenSolarStyles((theme) => ({
  chipWrapper: {
    padding: '4px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  chipContents: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
}))

const ArchivedPaymentOptionAlert: React.FC<PropTypes> = (props) => {
  const [isArchived, setIsArchived] = useState<boolean>(false)

  const availablePaymentOptions = useSelector(paymentOptionSelectionSelectors.getAvailablePaymentOptions)
  const translate = useTranslate()
  const classes = useStyles()

  useEffect(() => {
    let foundPaymentOption = availablePaymentOptions?.find((pmt) => pmt?.id === props.id)
    if (foundPaymentOption && foundPaymentOption.is_archived) setIsArchived(true)
    else setIsArchived(false)
  }, [props.id, availablePaymentOptions])

  const chipMessage = useMemo(() => {
    if (props.integration && !props.isSold) {
      return translate('Archived - not available')
    } else {
      return translate('Archived')
    }
  }, [props.integration, props.isSold])

  const tooltipMessage = useMemo(() => {
    if (props.integration) {
      if (props.isSold) {
        return translate(
          'This product is no longer available from the lender or your organization. If the customer needs to submit an application for this project you will likely need to select a new payment option from this lender.'
        )
      } else {
        return translate(
          'This product is no longer available from the lender or your organization. If the system is not marked as sold, this payment option will be replaced with a new version when a user views the proposal. If the product is no longer offered by the lender, remove it and add a new version.'
        )
      }
    } else {
      return translate(
        'This product has been archived by someone in your organization. Please ensure this product is still available'
      )
    }
  }, [props.integration, props.isSold])

  if (!isArchived) return null
  return (
    <div className={classes.chipWrapper}>
      <Tooltip title={tooltipMessage}>
        <Chip
          label={
            <div className={classes.chipContents}>
              <div>
                <ArchiveOutlined />
              </div>
              <div>{chipMessage}</div>
            </div>
          }
        />
      </Tooltip>
    </div>
  )
}
export default ArchivedPaymentOptionAlert

import { makeStyles, MenuItem, Select } from '@material-ui/core'
import { debounce } from 'lodash'
import { useExploreLogic } from 'projectSections/sections/explore/useExploreLogic'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { imagerySelectors } from 'reducer/designer/detectImagery'
import { MapDataTypes } from 'types/map'
import { MapType } from 'types/studio/map'
import { Theme } from 'types/themes'
import { ImagerySelectorMenuItem } from './ImagerySelectorMenuItem'
import { MapTypeNode, sortMapTypesIntoTree } from './sortMapTypesIntoTree'

const useStyles = makeStyles<Theme>((theme) => {
  return {
    root: {
      position: 'absolute',
      minWidth: 200,
      bottom: 64,
      right: 18,
      display: 'flex',
      flexDirection: 'column-reverse',
      gap: 12,

      [theme.breakpoints.down('sm')]: {
        bottom: 44,
        right: 8,
        gap: 6,
        minWidth: 196,
      },
    },
    logo: {
      width: 35,
      margin: 7,
    },
    selectedViewText: {
      textAlign: 'center',
      flexGrow: 1,
      padding: '0 6px',
      minHeight: 28,
    },
    field: {
      width: '100%',
      margin: '5px 0 20px',
      height: 40,
    },
    selectWrapper: {
      '&> div': {
        margin: 0,
        background: 'white',
      },
    },
    // timeText: {
    //   opacity: 0.65,
    //   marginLeft: 10,
    // },
  }
})

const headingMap = {
  0: 'North',
  90: 'East',
  180: 'South',
  270: 'West',
}

interface PropTypes {
  isCreated: boolean
}

function matches(mapType: MapDataTypes, view: MapType | undefined): boolean {
  if (!view) return false
  if (mapType.map_type !== view.mapType) return false
  const variation_name = window.MapData.getVariationName(view)
  if (variation_name && variation_name !== mapType.variation_name) return false
  return true
}

const ImagerySelector: React.FC<PropTypes> = ({ isCreated }) => {
  const allMapTypes = useSelector(imagerySelectors.getAvailableImageryTypes)
  const timezoneOffset = useSelector(imagerySelectors.getTimezoneOffset)
  const classes = useStyles()

  const [mapListsToShow, setMapListsToShow] = useState<MapTypeNode[][]>([])
  const [mapTypesSelected, setMapTypesSelected] = useState<MapTypeNode[]>([])

  const [isLastOpen, setIsLastOpen] = useState(false)

  const explore = useExploreLogic(isCreated, false)

  // When map types have loaded, only auto-apply them if they were explicitly selected
  const [selectionHasBeenMade, setSelectionHasBeenMade] = useState<Boolean>(false)

  const mapTypeTree = useMemo(() => {
    return sortMapTypesIntoTree(allMapTypes || [], timezoneOffset || 0)
  }, [allMapTypes])

  const selectNode = (key: string, index: number, fromUser: boolean) => {
    setSelectionHasBeenMade(fromUser)

    let node: MapTypeNode | undefined = mapTypeTree.reverse[key]
    if (node?.type === 'branch') setIsLastOpen(false)
    let selected = mapTypesSelected.slice(0, index)
    while (node) {
      selected.push(node)
      node = node.type === 'branch' && node.children.length ? node.children[0] : undefined
    }
    setMapTypesSelected(selected)
  }

  useEffect(() => {
    const lists = [mapTypeTree.tree]
    for (const node of mapTypesSelected) {
      if (node.type === 'branch' && node.children.length > 1) {
        lists.push(node.children)
      }
    }
    setMapListsToShow(lists)
  }, [mapTypeTree, mapTypesSelected])

  const updateFromModel = useCallback(() => {
    let selectedKey: string | undefined
    const view = window.ViewHelper.selectedView()
    if (!view) {
      return
    }
    const mapData = view.mapData
    selectedKey = mapData?.mapType

    if (mapData?.oblique?.direction) selectedKey += '.' + mapData?.oblique?.direction
    else {
      var heading = mapData?.oblique?.heading !== undefined ? headingMap[mapData?.oblique?.heading] : undefined
      if (heading) selectedKey += '.' + heading
    }

    if (mapData?.oblique?.until) selectedKey += '.Vertical ' + mapData?.oblique?.until
    else if (mapData?.oblique?.createdTime)
      selectedKey +=
        '.' + (mapData?.oblique?.direction ? mapData?.oblique?.direction + ' ' : '') + mapData?.oblique?.createdTime

    // const variation_name = window.MapData.getVariationName(view)

    if (
      !mapTypeTree.reverse[selectedKey] &&
      allMapTypes.length &&
      !allMapTypes.find((m) => m.map_type === mapData?.mapType)
    ) {
      // This condition can happen when Google Source has been selected, and then it gets removed (as coverage fails)
      let defaultMapType = explore.defaultMapType
      if (!mapTypeTree.reverse[defaultMapType]) {
        // This happens when a NM user loads a location with no NM coverage
        defaultMapType = allMapTypes[0].map_type
      }
      console.debug('Changing map type, as selected map type unavailable: ', selectedKey, defaultMapType)
      selectNode(defaultMapType, 0, false)
      return
    }
    if (!selectedKey) {
      console.debug("Couldn't find map selection from model")
      return
    }
    let node = mapTypeTree.reverse[selectedKey]
    if (!node) {
      console.debug('Map type not found yet: ', selectedKey)
      return
    }

    const newSelected: MapTypeNode[] = [node]

    if (node.type === 'branch') {
      // This condition deals with Source imagery in explore, that comes through without full key
      if (mapTypesSelected.includes(node)) return

      // This selects the implictly selected Nearmap vertical variation
      newSelected.push(node.children[0])
    }

    while (node.parent) {
      node = node.parent
      newSelected.unshift(node)
    }
    setMapTypesSelected(newSelected)
  }, [mapTypeTree, isCreated, explore.selectedMapTypeOrFirst])

  useEffect(() => {
    updateFromModel()
    if (isCreated) window.loadAvailableImagery()
  }, [isCreated, updateFromModel])

  useEffect(() => {
    const onViewsChanged = debounce(updateFromModel, 1000, { trailing: true })

    window.editor.signals.viewsChanged.add(onViewsChanged)
    return () => window.editor.signals.viewsChanged.remove(onViewsChanged)
  }, [updateFromModel])

  useEffect(() => {
    if (!selectionHasBeenMade) {
      // Do no apply the selected map type because a selection has not actually been made
      // Otherwise this can fire incorrectly on initial page load when it should only
      // fire when the selector is manually changed.
      return
    }

    const lastNode = mapTypesSelected[mapTypesSelected.length - 1]
    const selectedMapType = lastNode?.type === 'leaf' ? lastNode.mapType : undefined
    if (!selectedMapType) return

    const currentView = window.ViewHelper.selectedView()

    if (!matches(selectedMapType, currentView.mapData)) {
      const currentViewProperties = {
        center: currentView.mapData.center,
        sceneOrigin: currentView.mapData.sceneOrigin,
        zoomTarget: currentView.mapData.zoomTarget,
        cameraParams: currentView.cameraParams,
        viewBoxParams: currentView.viewBoxParams,
      }

      const newView = window.SceneHelper.viewForMapTypeData(selectedMapType, currentViewProperties)

      const currentViewIsTopDownImagery = window.MapData.isTopDown(currentView.mapData)
      const newViewIsTopDownImagery = window.MapData.isTopDown(newView.mapData)

      if (newViewIsTopDownImagery) {
        // when we switch to a top-down view,
        // we copy over the view box params, if the current view has them
        // otherwise we copy the stored viewbox params from ViewBoxHelper
        newView.viewBoxParams = currentViewProperties.viewBoxParams || window.ViewBoxHelper.getStored()
      }

      if (currentViewIsTopDownImagery && !newViewIsTopDownImagery) {
        // when we switch from a top-down to a non-top-down view
        // (e.g. NM to NM Source, NM to None)
        // we store the current viewbox params so we can retrieve it later
        window.ViewBoxHelper.store()
      }

      // clear the previous viewbox, because a new one will be created if the new view has viewbox params
      currentViewProperties.viewBoxParams && window.ViewBoxHelper.clear()
      window.ViewHelper.clearViewsCommand()
      // the second param is to auto-select new view
      // if a view is selected, the viewbox will be rendered automatically
      window.ViewHelper.appendViewCommand(newView, true)
    }
  }, [mapTypesSelected, selectionHasBeenMade, isCreated])

  return (
    <div className={classes.root}>
      {mapListsToShow.map((list, index) => {
        if (!list.length) return null
        const isLast = index !== 0 && list[0].type === 'leaf'
        return (
          <div className={classes.selectWrapper} key={index + '_' + isLast}>
            <Select
              open={isLast ? isLastOpen : undefined}
              variant="outlined"
              classes={{ root: classes.selectRoot }}
              className={classes.field}
              onChange={(e) => selectNode(e.target.value as string, index, true)}
              value={mapTypesSelected[index]?.key || ''}
              onOpen={!isLast ? undefined : () => setIsLastOpen(true)}
              onClose={
                !isLast
                  ? undefined
                  : (event) => {
                      const target = event.target as HTMLElement
                      const targets = [target, target.parentElement, target.parentElement?.parentElement]
                      // Only close if not clicking on the menu
                      if (!target || !targets.find((e) => e?.classList.contains('MuiListItem-root'))) {
                        setIsLastOpen(false)
                      }
                    }
              }
            >
              {list.map((node) => (
                <MenuItem key={node.name} value={node.key}>
                  <ImagerySelectorMenuItem
                    key={node.key}
                    node={node}
                    selected={mapTypesSelected[index]?.key === node.key}
                  />
                </MenuItem>
              ))}
            </Select>
          </div>
        )
      })}
    </div>
  )
}

export default ImagerySelector

import { makeStyles, Paper, Tooltip } from '@material-ui/core'
import { Add, InfoOutlined, LockOutlined } from '@material-ui/icons'
import { DraggableListColumn } from 'elements/dnd/DraggableList'
import { DraggableListController, useDraggabbleListController } from 'elements/dnd/useDraggabbleListController'
import { FieldValidationErrors } from 'elements/react-admin/FieldValidationErrors'
import { Button } from 'opensolar-ui'
import StageStepper from 'projectSections/sections/manage/projectManagement/workflow/StageStepper'
import { useNotify, useTranslate } from 'ra-core'
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { useForm, useFormState } from 'react-final-form'
import { OpenSolarThemeType } from 'Themes'
import { WorkflowActionType, WorkflowStageType, WorkflowType } from 'types/workflows'
import { urlToId } from 'util/misc'
import ActionDialog from './ActionDialog'
import { WORKFLOW_MILESTONES } from './constants'
import Stage from './Stage'
import { WorkflowItem } from './types'
import { getMilestoneIndex, groupStageItems } from './util'

const useStyles = makeStyles((theme: OpenSolarThemeType) => ({
  wrapper: {
    padding: 10,
    backgroundColor: '#F8F8F8',
    marginBottom: 25,
  },
  previewWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  milestoneTitle: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: 15,
    marginBottom: 15,
    '& hr': {
      width: '100%',
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'bold',
      textAlign: 'center',
      margin: '0 10px',
    },
  },
  milestoneDesc: {
    marginTop: 0,
    marginBottom: 25,
    fontSize: 13,
    color: theme.greyDark2,
  },
  milestoneWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& .MuiSvgIcon-root': { marginRight: 5 },
  },
  lockPricingWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    '& p': {
      margin: '15px 0px 25px',
      justifyContent: 'flex-end',
      '& span': {
        margin: '0 10px',
      },
    },
  },
  addStageRow: {
    width: '100%',
    textAlign: 'center',
  },
}))

interface PropTypes {
  record: WorkflowType
  resource?: string
}

interface ActionEditType {
  stageId: number | string
  action: WorkflowActionType
}

const EditTemplate: React.FC<PropTypes> = ({ record, resource }) => {
  const form = useForm()
  const classes = useStyles()
  const translate = useTranslate()
  const formValues = useFormState().values
  const workflowStages: WorkflowStageType[] | undefined = formValues.workflow_stages
  const [selectedStage, setStageSelected] = useState<WorkflowStageType | undefined>(undefined)
  useEffect(() => {
    // Using useEffect here to accomodate create > edit flow, which needs to populate this late
    if (!selectedStage && workflowStages?.length) setStageSelected(workflowStages[0])
  }, [workflowStages, selectedStage])

  const selectedWorkflow = useMemo(() => {
    if (formValues) {
      return {
        org_id: urlToId(formValues.org) || 0,
        org_name: '',
        workflow_id: formValues.id,
        workflow_url: formValues.url,
        active_stage_id: selectedStage?.id,
        active_stage_title: selectedStage?.title,
        share_with_orgs: formValues.share_with_orgs,
      }
    } else return undefined
  }, [formValues, selectedStage])

  const totalUndeletedStages = useMemo(() => {
    return workflowStages ? workflowStages.filter((x) => !x.forDelete && !x.is_archived).length : 0
  }, [workflowStages])

  const moveFirstStageToIndex = (
    from: WorkflowItem[],
    into: WorkflowItem[],
    index: number
  ): WorkflowItem | undefined => {
    const firstStage = from.find((x) => x.type === 'stage')
    if (firstStage) {
      const delIndex = from.indexOf(firstStage)
      from.splice(delIndex, 1)
      into.splice(index, 0, firstStage)
      return firstStage
    }
  }

  const groupedItems = useMemo(() => groupStageItems(workflowStages || []), [workflowStages])
  const onDragComplete = (oldGroups, newGroups: { [key: string]: WorkflowItem[] }) => {
    //at least 1 stage should always be present before price lock
    if (newGroups.stages?.[1]?.type === 'milestone') {
      if (!moveFirstStageToIndex(newGroups.stages, newGroups.stages, 1)) {
        moveFirstStageToIndex(newGroups.others, newGroups.stages, 1)
      }
    }

    const allItems: WorkflowItem[] = newGroups.stages.concat(newGroups.others)

    // Update milestones in stages
    let milestoneIndex = 0
    let stageOrder: (string | number)[] = []
    allItems.forEach((item: WorkflowItem) => {
      if (item.type === 'milestone') {
        milestoneIndex = WORKFLOW_MILESTONES.indexOf(item.milestone)
      } else if (item.type === 'stage') {
        item.stage.milestone = milestoneIndex
        item.stage.order = stageOrder.length
        stageOrder.push(item.stage.id)
      }
    })

    // Sort stages
    let reorderedStages = (workflowStages ? [...workflowStages] : []).sort(
      (stageA, stageB) => stageA.order - stageB.order
    )

    // Save changes to form
    form.mutators.updateField('workflow_stages', reorderedStages)
  }

  const controller: DraggableListController<WorkflowItem, string> = useDraggabbleListController({
    groups: groupedItems,
    optionId: 'id',
    onDragComplete,
  })

  if (!workflowStages) return null
  return (
    <>
      <h1>{translate('Preview')}</h1>
      <p>
        {translate(
          'This is how the stages will appear in the Project Page. You can configure the stages and related actions below.'
        )}
      </p>
      {selectedWorkflow && (
        <div className={classes.previewWrapper}>
          <StageStepper
            //@ts-ignore
            workflow={selectedWorkflow}
            handleChange={(workflow) => {
              const newStage = workflowStages?.find((x) => x.id === workflow.active_stage_id)
              if (newStage) setStageSelected(newStage)
            }}
            workflows={[formValues as WorkflowType]}
            allowEdit={true}
          />
        </div>
      )}
      <h1>{translate('Configure Workflow')}</h1>
      <p>{translate('Create custom stages and actions to track your project progress.')}</p>
      <FieldValidationErrors resource={resource} source="workflow_stages" />
      <DragDropContext onDragStart={controller.onDragStart} onDragEnd={controller.onDragEnd}>
        <WorkflowSectionList groupId="stages" controller={controller} totalUndeletedStages={totalUndeletedStages} />
        <WorkflowSectionList groupId="others" controller={controller} totalUndeletedStages={totalUndeletedStages} />
      </DragDropContext>
    </>
  )
}

type WorkflowSectionListProps = {
  groupId: string
  controller: DraggableListController<WorkflowItem, string>
  totalUndeletedStages: number
}

const WorkflowSectionList = ({ groupId, controller, totalUndeletedStages }: WorkflowSectionListProps) => {
  const form = useForm()
  const notify = useNotify()
  const formValues = useFormState().values
  const [editingAction, setEditingAction] = useState<ActionEditType | undefined>(undefined)
  const workflowStages: WorkflowStageType[] = formValues.workflow_stages
  const classes = useStyles()
  const translate = useTranslate()
  const stateRef = useRef<WorkflowStageType[]>()
  stateRef.current = useMemo(() => workflowStages, [workflowStages])
  const addNewStage = (milestoneId: number) => {
    let newStages = [...workflowStages]
    newStages.push({
      id: `NEW-STAGE-${Math.round(Math.random() * 1000000)}`,
      title: 'New Stage',
      milestone: milestoneId,
      order: 0,
      actions: [],
      is_archived: false,
    })
    newStages = newStages
      .sort((stageA, stageB) => stageA.milestone - stageB.milestone)
      .map((x, i) => ({
        ...x,
        order: i,
      }))
    form.mutators.updateField(`workflow_stages`, newStages)
  }

  const items: WorkflowItem[] = controller.groups[groupId] || []

  const lastMilestone: number = useMemo(() => {
    const lastItem = items[items.length - 1]
    if (!lastItem) return 0
    return getMilestoneIndex(lastItem)
  }, [items])

  return (
    <Paper className={classes.wrapper}>
      <DraggableListColumn
        controller={controller}
        render={(item, { dragHandle }) => (
          <WorkflowItemView
            item={item}
            dragHandle={dragHandle}
            setEditingAction={setEditingAction}
            totalUndeletedStages={totalUndeletedStages}
          />
        )}
        dragMode="drag-handle"
        groupId={groupId}
        isDragDisabled={(item) => item.type !== 'stage'}
        endAnnotation={
          <div className={classes.addStageRow}>
            <Button
              startIcon={<Add />}
              variant="contained"
              color="default"
              onClick={() => {
                addNewStage(lastMilestone)
              }}
            >
              {translate('Stage')}
            </Button>
          </div>
        }
      />
      <ActionDialog
        action={editingAction?.action}
        onClose={() => {
          setEditingAction(undefined)
        }}
        handleSubmit={(values) => {
          if (editingAction?.stageId !== undefined) {
            let newActions = [...formValues.workflow_stages[editingAction?.stageId].actions]
            const existingAction = newActions.find((x) => x.title?.toLowerCase() === values.title?.toLowerCase())
            if (existingAction && values.id !== existingAction.id) {
              notify('Action already exists in this stage.', 'warning')
            } else {
              const action = editingAction?.action
              if (!(typeof action?.id !== 'string' || action?.title !== undefined)) {
                newActions.push({ ...action, title: values.title, share_with_orgs: values.share_with_orgs })
                form.mutators.updateField(`workflow_stages.${editingAction?.stageId}.actions`, newActions)
              } else {
                let updatedStages = formValues.workflow_stages?.map((stage) => {
                  const findAction = stage.actions?.find((x) => x.id === action.id)
                  if (findAction) {
                    let updatedActions = [...stage.actions]
                    updatedActions[stage.actions.indexOf(findAction)] = {
                      ...findAction,
                      title: values.title,
                      share_with_orgs: values.share_with_orgs,
                    }
                    return { ...stage, actions: updatedActions }
                  } else return stage
                })
                form.mutators.updateField('workflow_stages', updatedStages)
              }
            }
          }
          setEditingAction(undefined)
        }}
      />
    </Paper>
  )
}

type WorkflowItemViewProps = {
  item: WorkflowItem
  dragHandle: ReactNode
  setEditingAction: (actionStage: ActionEditType) => void
  totalUndeletedStages: number
}

const WorkflowItemView = (props: WorkflowItemViewProps) => {
  const { item, setEditingAction, dragHandle } = props
  const classes = useStyles()
  const translate = useTranslate()
  if (item.type === 'milestone' && item.milestone.title === 'Lock Pricing') {
    return (
      <div className={classes.lockPricingWrapper}>
        {item.milestone.title && (
          <p className={classes.milestoneTitle}>
            <LockOutlined style={{ color: 'red' }} />
            <span style={{ fontWeight: 'normal' }}>{translate(item.milestone.title)}</span>
            <Tooltip
              title={translate(
                'All the projects from the next stage will have pricing locked automatically. Pricing lock ensures the pricing, incentives and payment options for all the systems are locked to limit un-intentional changes.'
              )}
              PopperProps={{ style: { zIndex: 2001 } }}
            >
              <InfoOutlined />
            </Tooltip>
          </p>
        )}
      </div>
    )
  } else if (item.type === 'milestone') {
    return (
      <div className={classes.milestoneWrapper}>
        {item.milestone.title && (
          <p className={classes.milestoneTitle}>
            <hr />
            <span>
              {item.milestone.icon && React.createElement(item.milestone.icon)}
              {translate(item.milestone.title)}
            </span>
            <hr />
          </p>
        )}
        {item.milestone.desc && <p className={classes.milestoneDesc}>{translate(item.milestone.desc)}</p>}
      </div>
    )
  } else if (item.type === 'stage') {
    return (
      <Stage
        dragHandle={dragHandle}
        stage={item.stage}
        stageId={item.stageIndex}
        setEditingAction={setEditingAction}
        isLastStage={props.totalUndeletedStages === 1}
      />
    )
  } else {
    return <></>
  }
}

export default EditTemplate

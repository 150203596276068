import { CircularProgress, makeStyles } from '@material-ui/core'
import ContentSave from '@material-ui/icons/SaveOutlined'
import { setSelectTariffDialog as setSelectTariffDialogAction } from 'ducks/selectTariff'
import { studioSelectors } from 'ducks/studioMode'
import { Button } from 'opensolar-ui'
import React, { useCallback, useContext } from 'react'
import { FormContext, Toolbar, useNotify, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { isReadOnly } from 'resources/utilityTariffs/common'
import { useFormatTariffValues } from '../hooks'
import { UtilityTariffDialogContext } from '../index'

const useStyles = makeStyles(
  (theme: any) => {
    return {
      button: {
        // '&:hover': {
        //   cursor: 'pointer',
        //   background: theme.themeColorDark,
        // },

        position: 'relative',
        // padding: '4px 10px',
        margin: 10,
        // width: 100,
        // background: theme.themeColor,
        // color: theme.headerFontColor,
      },
      buttonSaving: {
        cursor: 'unset',
        // background: theme.themeColor,
        '&:hover': {
          cursor: 'pointer',
          // background: theme.themeColorDark,
        },
      },
      // leftIcon: {
      //   color: theme.headerFontColor,
      // },
      helperText: {
        display: 'inline-block',
        marginRight: 'auto',
        fontSize: '12px',
      },
      toolbar: {
        background: 'rgba(224, 224, 224, 1)',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
      },
    }
  },
  { name: 'edit-utility-tariff-dialog-toolbar' }
)

const EditTariffToolbar = (props: any) => {
  const { saving } = props
  const translate = useTranslate()
  const location = useLocation()
  const { setOnSave } = useContext(FormContext)
  const orgId = useSelector((state: any) => (state.auth ? state.auth?.org_id : null))
  const history = useHistory()
  const classes = useStyles()
  const tariffForm = useForm()
  const formState = useFormState()
  const projectForm = window.projectForm
  const notify = useNotify()
  const { state, dispatch, handleSelect } = useContext(UtilityTariffDialogContext)
  const { onSuccess, resource, onFailure, variant } = state
  const isStudioMode = useSelector(studioSelectors.isInStudio)
  const dispatchAction = useDispatch()
  const isCustom = resource === 'custom_tariff_current' || resource === 'custom_tariff_proposed'
  const submitValue = useFormatTariffValues(formState?.values)
  const updateOnceOffTariff = useCallback(() => {
    projectForm.batch(() => {
      //@ts-ignore
      projectForm.registerField(`utility_tariff_${variant}`, () => {})
      projectForm.change(`utility_tariff_${variant}`, null)

      //@ts-ignore
      projectForm.registerField(`utility_tariff_${variant}_data`, () => {})
      projectForm.change(`utility_tariff_${variant}_data`, null)

      projectForm.registerField(`utility_tariff_${variant}_custom`, () => {}, {})
      projectForm.change(`utility_tariff_${variant}_custom`, submitValue.data)
    })
    dispatch({ type: 'clear' })
    dispatchAction(setSelectTariffDialogAction(false, null, null, null, isStudioMode))
    history.push(location.pathname)
  }, [location.pathname, submitValue])

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (saving) {
        event.preventDefault()
        return
      }
      updateOnceOffTariff()
    },
    [location.pathname, saving, submitValue]
  )

  return (
    <Toolbar className={classes.toolbar} {...props}>
      <div className={classes.helperText}>
        {isCustom
          ? '(This tariff belongs to this project only.)'
          : isReadOnly(props.record, orgId)
          ? '(This tariff belongs to OpenSolar database. Saving this tariff will create a copy for this project)'
          : '(This tariff belongs to My Utility Tariffs list. Updating this tariff will create a copy for this project)'}
      </div>

      <Button
        variant="contained"
        color="default"
        onClick={() => {
          history.push(location.pathname)
          dispatch({ type: 'clear' })
        }}
        style={{ margin: 10, position: 'relative' }}
      >
        <span>{translate('Discard')}</span>
      </Button>
      <Button
        classes={{ root: saving ? classes.buttonSaving : classes.button }}
        startIcon={
          saving ? (
            <CircularProgress
              size={18}
              thickness={2}
              // className={classes.leftIcon}
            />
          ) : (
            <ContentSave />
          )
        }
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        <span>{translate('Save')}</span>
      </Button>
    </Toolbar>
  )
}

export default EditTariffToolbar

import { makeStyles } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/CheckOutlined'
import React from 'react'

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '40px',
    height: '40px',
    borderRadius: '40px',
    border: '2px solid #4d4d4d',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  smallWrapper: {
    width: '24px',
    height: '24px',
    borderRadius: '24px',
    border: '2px solid #4d4d4d',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

type PropTypes = {
  small?: boolean
  is_archived?: boolean
}

const ActionCompleteAvatar: React.FunctionComponent<PropTypes> = (props) => {
  const classes = useStyles()
  return (
    <div
      className={props.small ? classes.smallWrapper : classes.wrapper}
      style={props.is_archived ? { opacity: 0.2 } : {}}
    >
      <CheckIcon htmlColor="#4d4d4d" fontSize={props.small ? 'default' : 'large'} />
    </div>
  )
}
export default ActionCompleteAvatar

import { makeStyles } from '@material-ui/core'
import React, { ReactNode } from 'react'
import { COLOR_PALETTE } from '../styles'

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: '40px 24px',
  },
  mainText: {
    fontWeight: 500,
    fontSize: 16,
  },
  icon: {
    width: 25,
    height: 'unset',
    padding: 15,
    backgroundColor: COLOR_PALETTE.grey2,
    color: COLOR_PALETTE.darkGrey,
    borderRadius: 8,
  },
  subText: {
    margin: 0,
  },
})

interface PropTypes {
  icon?: ReactNode
  mainText?: string
  subText?: string | ReactNode
}

const Notice: React.FC<PropTypes> = ({ icon, mainText, subText }) => {
  const classes = useStyles()
  if (!icon && !mainText && !subText) return null
  return (
    <div className={classes.content}>
      {icon && <div className={classes.icon}>{icon}</div>}
      {mainText && <p className={classes.mainText}>{mainText}</p>}
      {subText && <>{typeof subText === 'string' ? <p className={classes.subText}>{subText}</p> : subText}</>}
    </div>
  )
}
export default Notice

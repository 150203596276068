import { FormControl, MenuItem, Select, Typography } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orderSelectors, updateIsCustomPricing } from 'ducks/orderComponents'
import Alert from 'elements/Alert'
import OrdersTable from 'pages/ordering/order/OrdersTable'
import { ChangeEvent, memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { useFeatureFlag } from 'util/split'
import OutletCartActions from './OutletCartActions'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  container: {
    width: '100%',
    padding: 20,
    boxSizing: 'border-box',
  },
  orderTable: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  cartActions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  main: {
    flexGrow: 1,
    margin: '10px',
  },
  actions: {
    margin: '10px',
    width: 300,
    flexShrink: 0,
  },
  title: { margin: 10 },
  customPricingHelpText: {
    margin: '16px 0px 8px 0px',
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightMedium,
    color: '#4D4D4D',
    paddingRight: '10px',
  },
}))

const pricingOptions = {
  ACCOUNT_MANAGER_PRICING: 'account_manager_pricing',
  CATALOGUE_PRICING: 'catalogue_pricing',
}

const OutletCartPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const lineItems = useSelector(orderSelectors.getOrderableLineItems)
  const enableCustomPricing = useFeatureFlag('outlet_custom_pricing', 'on')
  const usingCustomPricing = useSelector(orderSelectors.getIsCustomPricing)

  useEffect(() => {
    logAmplitudeEvent('page_viewed', {
      page: 'shopping_cart',
    })
  }, [])

  const handleChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    logAmplitudeEvent('hardware_solar_outlet_pricing_option_updated', {
      distributor: 'outlet',
      source: 'shopping_cart',
      is_cutom_pricing: event.target.value === pricingOptions.ACCOUNT_MANAGER_PRICING,
    })
    dispatch(updateIsCustomPricing(event.target.value === pricingOptions.ACCOUNT_MANAGER_PRICING))
  }

  return (
    <div className={classes.container}>
      {enableCustomPricing && (
        <Alert severity="info">
          Great news! As a Solar Outlet valued customer, you are eligible for custom pricing. Enter your agreed upon
          price at checkout to enjoy this special offer.
        </Alert>
      )}
      <div className={classes.title}>
        <Typography variant="h4" gutterBottom>
          Shopping Cart
        </Typography>
      </div>
      {enableCustomPricing && (
        <div style={{ display: 'flex', flexDirection: 'row', margin: '10px', width: '100%' }}>
          <div
            style={{
              height: '38px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              color: '#4D4D4D',
              paddingRight: '5px',
              width: '240px',
            }}
          >
            <Typography style={{ fontSize: '14px' }}>Choose your preferred pricing option:</Typography>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <FormControl size="small" fullWidth variant="outlined" style={{ width: '300px', padding: 0 }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={usingCustomPricing ? pricingOptions.ACCOUNT_MANAGER_PRICING : pricingOptions.CATALOGUE_PRICING}
                onChange={handleChange}
              >
                <MenuItem value={pricingOptions.CATALOGUE_PRICING}>Catalogue Pricing</MenuItem>
                <MenuItem value={pricingOptions.ACCOUNT_MANAGER_PRICING}>Account Manager Pricing</MenuItem>
              </Select>
            </FormControl>
            <div style={{ maxWidth: '700px' }}>
              <Typography className={classes.customPricingHelpText} style={{ width: '100%' }}>
                {usingCustomPricing
                  ? 'Your Account Manager will set the final prices based on the best negotiated amounts. You will get the chance to confirm your final price before shipment.'
                  : 'Your Solar Outlet agreed pricing, as shown in OpenSolar.'}
              </Typography>
            </div>
          </div>
        </div>
      )}
      <div className={classes.orderTable}>
        <div className={classes.main}>
          <OrdersTable lineItems={lineItems} editable={true} trackSource="checkout" />
        </div>
      </div>
      <div className={classes.cartActions}>
        <div className={classes.actions}>
          <OutletCartActions />
        </div>
      </div>
    </div>
  )
}

export default memo(OutletCartPage)

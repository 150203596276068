import type { QuantitySelectorType } from 'pages/ordering/order/table/quantity/useQuantitySelectorType'
import React from 'react'
import useQuantityMessage from './useQuantityMessage'
import type { SelectorComponentType } from './useQuantitySelector'
import useQuantitySelector from './useQuantitySelector'
import useQuantitySelectorType from './useQuantitySelectorType'
import useQuantityErrorMessage from './useQuantityErrorMessage'

const useQuantityFieldParts = ({
  lineItem,
}): { type?: QuantitySelectorType; Message?: React.FC<{}>; QuantityErrorMessage?: React.FC<{}>; Selector?: SelectorComponentType } => {
  const type = useQuantitySelectorType({ lineItem })
  const Message = useQuantityMessage({ lineItem, type })
  const QuantityErrorMessage = useQuantityErrorMessage({lineItem})
  const Selector = useQuantitySelector({ lineItem, type })
  return {
    type,
    Message,
    QuantityErrorMessage,
    Selector,
  }
}

export default useQuantityFieldParts

import { useMediaQuery } from '@material-ui/core'
import useAdBanner from 'elements/ads/useAdBanner'
import { Box } from 'opensolar-ui'
import { getHardwareSelectorAdPlacement } from 'pages/ordering/banner/HardwareSelectorTopBanner'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { Theme } from 'types/themes'

const useStyles = makeOpenSolarStyles(() => ({
  scrollShadow: {
    background:
      'linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#ffffff',
    backgroundSize: '100% 40px, 100% 40px, 100% 10px, 100% 10px',
    backgroundAttachment: 'local, local, scroll, scroll',
  },
  text: {
    color: '#212121',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '22px',
    margin: 0,
  },
}))

interface BatterySalesAssistantContainerProps {
  children: React.ReactNode
  footerActions?: React.ReactNode
  isAdBannerShown?: boolean
  title?: string
}

const BatterySalesAssistantContainer: React.FC<BatterySalesAssistantContainerProps> = ({
  children,
  footerActions,
  isAdBannerShown = false,
  title,
}) => {
  const classes = useStyles()

  const AdBanner = useAdBanner({
    formats: ['leaderboard'],
    placement: getHardwareSelectorAdPlacement({ location: 'top', componentType: 'battery' }),
    requiredWindowSize: { width: 960, height: 800 },
  })
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  return (
    <>
      <Box
        className={classes.scrollShadow}
        display="flex"
        flexDirection="column"
        gridGap={24}
        {...(footerActions && { overflow: 'scroll' })}
      >
        {!isMobile && isAdBannerShown && AdBanner}
        {title && <p className={classes.text}>{title}</p>}
        {children}
      </Box>
      {footerActions && (
        <Box display="flex" gridGap={12} justifyContent="flex-end" marginTop="8px">
          {footerActions}
        </Box>
      )}
    </>
  )
}

export default BatterySalesAssistantContainer

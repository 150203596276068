import React, { useState } from 'react'
// import { TextInput } from 'react-admin'
import { InputAdornment, makeStyles, Portal, TextField } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/SearchOutlined'

const useStyles = makeStyles((theme: any) => ({
  outlined: {
    padding: 14,
  },
}))

const SearchInput = ({ placeholder = 'Search', filterValues, setFilters, ...rest }: any) => {
  const classes = useStyles()
  const [value, setValue] = useState(filterValues?.q)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const q = event.target.value
    setValue(q)
    setFilters({ ...filterValues, q })
  }
  return (
    <Portal container={() => document.getElementById('Ra-List-Search-Input-Wrapper')}>
      <TextField
        fullWidth={true}
        label={false}
        value={value}
        onChange={handleChange}
        variant={'outlined'}
        style={{ background: 'white' }}
        placeholder={placeholder}
        InputProps={{
          classes: { input: classes.outlined },
          startAdornment: <InputAdornment position="start">{<SearchIcon />}</InputAdornment>,
        }}
      />
    </Portal>
  )
}

export default SearchInput

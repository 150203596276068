import React from 'react'
import { FilterComp } from 'react-admin'
import SharedFromInput from './filters/SharedFromInput'
import SharedWithInput from './filters/SharedWithInput'

interface PropTypes {
  resource: string
}

const ShareabilityFilters: React.FC<PropTypes> = (props) => {
  return (
    <FilterComp {...props}>
      <SharedFromInput source="owned_by" label={'Shared From'} />
      <SharedWithInput source="shared_with" label={'Shared With'} />
    </FilterComp>
  )
}

export default ShareabilityFilters

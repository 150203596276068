import { Theme, makeStyles, useMediaQuery } from '@material-ui/core'
import { Add, ArrowBackIosOutlined } from '@material-ui/icons'
import { OpenSolarThemeType } from 'Themes'
import { push } from 'connected-react-router'
import { authSelectors } from 'ducks/auth'
import Tooltip from 'elements/tooltip/Tooltip'
import SupportButton from 'layout/widgets/SupportButton'
import UserSettingsDropdown from 'layout/widgets/UserSettingsDropdown'
import LogoOrTitle from 'myenergy/mainContent/components/LogoOrTitle'
import { Button, IconButton } from 'opensolar-ui'
import SearchBar from 'persistentContent/elements/SearchBar'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DialogHelper, doNotTranslate } from 'util/misc'
import { NavProps } from './OSHeader'
import StyledNavLink from './elements/StyledNavLink'

const useStyles = makeStyles((theme: OpenSolarThemeType) => ({
  backBtn: {
    border: '2px solid ' + theme.greyMid2,
    borderRadius: 5,
    margin: 10,
    marginLeft: 30,
    padding: 5,
    '& .MuiSvgIcon-root': {
      height: 20,
      width: 20,
    },
    marginRight: 0,
  },
  iconLogo: {
    height: 30,
    marginLeft: 10,
    marginTop: 10,
  },
  logo: {
    height: 25,
    marginLeft: 10,
  },
  logoTitleWrapper: {
    lineHeight: '25px',
  },
  logoTitle: {
    display: 'inline',
    fontSize: 16,
    paddingLeft: 5,
    verticalAlign: 'text-top',
  },
  navLink: {
    color: 'inherit !important',
    textDecoration: 'none',
    display: 'inline-block',
    letterSpacing: 0.5,
    opacity: 0.87,
    '&:hover': {
      opacity: 0.98,
    },
    '&:visited': {
      color: 'inherit',
    },
    padding: 20,
  },
  navLinkActive: {
    borderBottom: '3px solid orange',
  },
  createProject: {
    margin: '0 10px',
    borderRadius: 2,
    backgroundColor: '#52B86A',
    '& .MuiButtonBase-root': {
      border: 'none',
      height: 35,
    },
    '& .MuiSvgIcon-root': {
      fill: theme.white,
    },
  },
  innerButton: {
    backgroundColor: '#3EA753',
    fontSize: 14,
    color: theme.white,
    width: 140,
    '&:hover': {
      backgroundColor: '#3EA753',
    },
  },
  iconBtnClass: { padding: '0 4px', color: theme.white, fontSize: 12, display: 'inline-block', whiteSpace: 'nowrap' },
  end: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexGrow: 1,
    gap: 5,
    height: 50,
    paddingRight: 10,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
  },
}))

const IronRidgeHeader: React.FC<NavProps> = (props) => {
  const { isUserLite, layout, org, setSelectOrgVisible, setAvailableOrgs } = props

  const classes = useStyles()
  const uiKey = useMemo(() => 'nav.buttons.', [])
  const briefOrgs = useSelector(authSelectors.getBriefOrgs)
  const cobrandingOrg = isUserLite ? undefined : org?.cobranding_org
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const dispatch = useDispatch()
  const onClick = () => {
    dispatch(push('/home'))
  }

  return (
    <div className={classes.wrapper}>
      {!isSmall && (
        <Tooltip title="Back to Ironridge Projects">
          <IconButton
            aria-label="back"
            className={classes.backBtn}
            onClick={() => {
              window.location.href = `${window.LYRA_BASE_URL}/projects?project_scope_filter=company_projects`
            }}
          >
            <ArrowBackIosOutlined />
          </IconButton>
        </Tooltip>
      )}
      <img
        alt="co-branding"
        src={
          isXs ? `${window.PUBLIC_URL}/images/atlas-logo-only.svg` : `${window.PUBLIC_URL}/images/atlas-main-logo.svg`
        }
        className={isXs ? classes.iconLogo : classes.logo}
      />

      <div className={classes.end}>
        {!isSmall && (
          <>
            <StyledNavLink
              className={classes.navLink}
              uiKey={uiKey + 'projects'}
              to="/projects"
              label="Projects"
              layout={layout}
              activeStyle={classes.navLinkActive}
            />
            <StyledNavLink
              className={classes.navLink}
              uiKey={uiKey + 'control'}
              to="/control"
              label="Control"
              layout={layout}
              activeStyle={classes.navLinkActive}
            />
          </>
        )}
        <div className={classes.createProject} onClick={onClick}>
          <IconButton>
            <Add />
          </IconButton>
          {!isSmall && <Button className={classes.innerButton}>{doNotTranslate('Proposal')}</Button>}
        </div>
        <SearchBar />
        <SupportButton />
        <UserSettingsDropdown
          showOrgSelector={() => {
            DialogHelper.afterOpen()
            setSelectOrgVisible(true)
            setAvailableOrgs(briefOrgs)
          }}
          layout={layout}
        />

        {cobrandingOrg && layout > 1 && (
          <div style={{ display: 'flex', paddingRight: 20 }}>
            <LogoOrTitle
              size={layout > 2 ? 'small' : 'cobrandingsmall'}
              org={cobrandingOrg}
              allowUploadButton={false}
              style={undefined}
              skipTitle={undefined}
              skipTitleStyle={undefined}
              type={undefined}
            />
          </div>
        )}
      </div>
      {!isSmall && <div style={{ marginRight: 20 }}></div>}
    </div>
  )
}
export default IronRidgeHeader

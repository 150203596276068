import { PaymentFrequencyTypeEnum, PaymentTypeEnum } from 'types/paymentOptions'

export enum PAYMENT_OPTION_MESSAGE_TYPE_ENUM {
  error = 'error',
  warning = 'warning',
  disabled = 'disabled',
}

export type CalculatedPaymentOptionMessageType = {
  type: PAYMENT_OPTION_MESSAGE_TYPE_ENUM
  message: string
  text?: string // this is left over from the previous version of payment option warnings. Keeping for backwards compatability
  system_uuid: string
  payment_option_id: number
  error_prompt_id: number | null
}

export type CalculatedPaymentOption = {
  payment_option_id: number
  title: string
  payment_type: PaymentTypeEnum
  integration: string | null | undefined //this can be any string, since integrations can by driven by the DB entirely now
  interest_rate: number | null | undefined
  interest_rate_disclaimer: string | null
  term_years: number | null | undefined
  term_periods: number | null | undefined
  payment_frequency: PaymentFrequencyTypeEnum | undefined
  repayment: number | null | undefined
  system_price: number | null | undefined
  messages: CalculatedPaymentOptionMessageType[]
  is_popular: boolean
}

import { useTranslate } from 'ra-core'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { FormRouteInfoPropTypes } from '../types'

const useStyles = makeOpenSolarStyles((theme) => ({
  header: { fontSize: 20, fontWeight: 500, marginTop: 0, marginBottom: 0 },
  description: {
    lineHeight: 1.5,
  },
}))
const TermsTop: React.FC<FormRouteInfoPropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <div>
      <h1 className={classes.header}>{translate('Accept Terms & Conditions')}</h1>
      <p className={classes.description}>
        {translate(
          'Please read and accept the Merchant Agreement and Pricing Agreement and OpenSolar Cashflow Supplemental Terms & Conditions.'
        )}
        <br />
      </p>
    </div>
  )
}
export default TermsTop

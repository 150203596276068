import { useMemo } from 'react'
import { ContactType } from 'types/contacts'
import { ContactData, ProjectType } from 'types/projects'
import { getFirstRealContact } from 'util/misc'

export const getEnfinInitialValues = (project: ProjectType) => {
  if (!project) return {}
  // get primary contact's info
  let primaryContact: ContactType | ContactData | undefined | null = undefined
  if (project.contacts_data?.length) {
    primaryContact = getFirstRealContact(project.contacts_data)
  } else {
    primaryContact = project.contacts && project.contacts.length > 0 ? project?.contacts[0] : undefined
  }

  return {
    applicantPersonalInformation: [
      {
        firstName: primaryContact?.first_name,
        lastName: primaryContact?.family_name,
        mobileNumber: primaryContact?.phone || undefined,
        emailAddress: primaryContact?.email || undefined,
      },
    ],
    installationAddress: {
      streetAddress: project.address,
      city: project.locality,
      state: project.state,
      zipCode: project.zip,
    },
  }
}

export const getLightReachInitialValues = (project: ProjectType) => {
  if (!project) return {}
  // get primary contact's info
  let primaryContact: ContactType | ContactData | undefined | null = undefined
  if (project.contacts_data?.length) {
    primaryContact = getFirstRealContact(project.contacts_data)
  } else {
    primaryContact = project.contacts && project.contacts.length > 0 ? project?.contacts[0] : undefined
  }

  return {
    applicants: [
      {
        type: 'primary',
        firstName: primaryContact?.first_name,
        lastName: primaryContact?.family_name,
        email: primaryContact?.email || undefined,
        phoneNumber: primaryContact?.phone || undefined,
        address: {
          address1: project.address,
          city: project.locality,
          state: project.state,
          zip: project.zip,
        },
      },
      {
        type: 'secondary',
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        phoneNumber: undefined,
        address: {
          address1: project.address,
          city: project.locality,
          state: project.state,
          zip: project.zip,
        },
      },
    ],
  }
}

export const useInitialValuesByIntegration = (integration: string | undefined | null, project: ProjectType) => {
  return useMemo(() => {
    const initialValueSetter =
      (integration && INITIAL_VALUES_BY_INTEGRATION[integration]) || INITIAL_VALUES_BY_INTEGRATION.default
    return initialValueSetter(project)
  }, [integration, project])
}

const INITIAL_VALUES_BY_INTEGRATION = {
  enfin: getEnfinInitialValues,
  lightreach: getLightReachInitialValues,
  default: () => ({}),
}
export default INITIAL_VALUES_BY_INTEGRATION

import { makeStyles, useMediaQuery } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import { authSelectors } from 'ducks/auth'
import { orderSelectors } from 'ducks/orderComponents'
import CreateProjectButton from 'layout/widgets/CreateProjectButton'
import SupportButton from 'layout/widgets/SupportButton'
import UserSettingsDropdown from 'layout/widgets/UserSettingsDropdown'
import LogoOrTitle from 'myenergy/mainContent/components/LogoOrTitle'
import ShopCartButton from 'pages/ordering/subheader/ShopCartButton'
import SearchBar from 'persistentContent/elements/SearchBar'
import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { OrgType } from 'types/orgs'
import { DialogHelper } from 'util/misc'
import Navigation from './elements/Navigation'

const useStyles = makeStyles((theme: OpenSolarThemeType) => ({
  logo: {
    display: 'flex',
    paddingLeft: theme.name === 'nearmap' ? 10 : 20,
    color: theme.headerFontColor,

    '& img': {
      padding: theme.name === 'nearmap' ? '3px 4px !important' : 'unset',
      backgroundColor: theme.name === 'nearmap' ? 'white' : 'unset',
      borderRadius: theme.name === 'nearmap' ? 2 : 'unset',
    },
  },
  end: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexGrow: 1,
    gap: 5,
    height: 50,
    paddingRight: 10,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
  },
}))

export interface NavProps {
  isUserLite: boolean
  isNearmapSkin: boolean
  layout: number
  org: OrgType
  zone: string
  projectId: number
  setSelectOrgVisible: (value) => void
  setAvailableOrgs: (value) => void
}
const OSHeader: React.FC<NavProps> = (props) => {
  const {
    isUserLite,
    isNearmapSkin,
    layout,
    org,
    zone,
    projectId,
    setSelectOrgVisible,
    setAvailableOrgs,
    ...otherProps
  } = props
  const classes = useStyles()
  const location = useLocation()
  const briefOrgs = useSelector(authSelectors.getBriefOrgs)
  const cartItems = useSelector(orderSelectors.getConfirmedLineItems)
  const cobrandingOrg = isUserLite ? undefined : org?.cobranding_org
  const isMobile = useMediaQuery((theme: OpenSolarThemeType) => theme.breakpoints.down('sm'))
  const isHomePage = location.pathname === '/' || location.pathname === '/home'
  const isExplorePage = location.pathname === '/projects/new/explore'

  return (
    <div className={classes.wrapper}>
      <div className={classes.logo}>
        <LogoOrTitle
          size={layout > 2 ? 'small' : 'cobrandingsmall'}
          org={org}
          allowUploadButton={false}
          style={undefined}
          type={undefined}
          skipTitle={undefined}
          skipTitleStyle={undefined}
        />
      </div>
      {!isMobile && <Navigation {...props} />}
      <div className={classes.end}>
        {cartItems.length > 0 && !isHomePage && !isExplorePage && zone !== 'shop' && (layout > 2 || !isNearmapSkin) && (
          <ShopCartButton isInventory={true} />
        )}
        {!isHomePage && !isExplorePage && (layout > 2 || !isNearmapSkin) && <CreateProjectButton layout={layout} />}
        <SearchBar />
        <SupportButton iconColor="primary" />
        <UserSettingsDropdown
          showOrgSelector={() => {
            DialogHelper.afterOpen()
            setSelectOrgVisible(true)
            setAvailableOrgs(briefOrgs)
          }}
          layout={layout}
        />
        {isNearmapSkin && (
          <img
            alt="co-branding"
            src={window.PUBLIC_URL + '/images/co-branding-logo.png'}
            style={{ width: layout > 1 ? 80 : 55 }}
          />
        )}
        {cobrandingOrg && layout > 1 && (
          <div style={{ display: 'flex', paddingRight: 20 }}>
            <LogoOrTitle
              size={layout > 2 ? 'small' : 'cobrandingsmall'}
              org={cobrandingOrg}
              allowUploadButton={false}
              style={undefined}
              skipTitle={undefined}
              skipTitleStyle={undefined}
              type={undefined}
            />
          </div>
        )}
      </div>
    </div>
  )
}
export default OSHeader

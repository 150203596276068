import { walletSettingsSelectors } from 'ducks/wallet'
import CustomField from 'elements/field/CustomField'
import CheckboxInput from 'elements/input/CheckboxInput'
import React, { useEffect, useState } from 'react'
import { SelectInput, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { WalletType } from 'types/wallet'
import { FundsFormModeType } from './EditWalletPage'
import { getTopUpAmtChoices, getTopUpTriggerChoices } from './constants'

type PropTypes = {
  switchMode: (newMode: FundsFormModeType) => void
}
const TopupWrapper: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const form = useForm()
  const topUpEnabled: boolean = useSelector(walletSettingsSelectors.getIsTopUpEnabled)

  //@ts-ignore TODO: fix
  const wallet: WalletType = useSelector(walletSettingsSelectors.getWallet)

  const [topUpAmtChoices, setTopUpAmtChoices] = useState<any[]>([])
  const [topUpTriggerChoices, setTopUpTriggerChoices] = useState<any[]>([])

  useEffect(() => {
    setTopUpAmtChoices(getTopUpAmtChoices(wallet?.currency))
    setTopUpTriggerChoices(getTopUpTriggerChoices(wallet?.currency))
  }, [wallet?.currency])

  useEffect(() => {
    if (!form.getState().values?.enable_auto_top_up) {
      form.change('top_up_amount', 0)
      form.change('top_up_trigger_amount', 0)
    }
  }, [form.getState().values?.enable_auto_top_up])

  return (
    <div>
      <p>
        Auto Top-up is <strong>{topUpEnabled ? 'Enabled' : 'Disabled'}</strong>
      </p>
      <h2>How auto top-up benefits your business</h2>
      <p>
        {translate(
          'It is recommended that Auto top-up is enabled to ensure that your team has seamless, uninterrupted access to Premium content. This reduces admin time and maximises productivity. Alternatively you can'
        )}
        <span
          id="Add_Funds_Manually"
          style={{
            cursor: 'pointer',
            textDecoration: 'underline',
            color: 'rgb(63, 147, 237)',
            marginLeft: '5px',
          }}
          //@ts-ignore TODO: fix
          onClick={props.switchMode}
        >
          {translate('Add Funds Manually')}
        </span>
      </p>
      <div>
        <div>
          <CustomField
            name="enable_auto_top_up"
            defaultValue={wallet?.enable_auto_top_up ? 1 : 0}
            label="Enable Auto Top-Up"
            component={CheckboxInput}
            fullWidth
            style={{ width: '100%' }}
          />
        </div>
        <div>
          <CustomField
            style={{ minWidth: '400px', marginBottom: '0px' }}
            name="top_up_amount"
            label="Set the auto top-up amount"
            component={SelectInput}
            choices={topUpAmtChoices}
            initialValue={wallet?.top_up_amount || 0}
            type="number"
            disabled={!form.getState()?.values?.enable_auto_top_up}
          />
        </div>
        <div>
          <CustomField
            style={{ minWidth: '400px', marginBottom: '0px' }}
            name="top_up_trigger_amount"
            label="Balance below which the top-up will be initiated"
            component={SelectInput}
            choices={topUpTriggerChoices}
            initialValue={wallet?.top_up_trigger_amount || 0}
            type="number"
            disabled={!form.getState()?.values?.enable_auto_top_up}
          />
        </div>
      </div>
    </div>
  )
}

export default TopupWrapper

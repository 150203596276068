import { Theme, useMediaQuery } from '@material-ui/core'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import React, { useState } from 'react'
import { ProjectType } from 'types/projects'
import { SystemDesignSunlightQuickCreateType } from '../sunlight/types'
import SungageSendAppDialog from './SungageSendAppDialog'

type PropTypes = {
  project: ProjectType
  designs?: SystemDesignSunlightQuickCreateType[]
}

const SungageSendAppButton: React.FunctionComponent<PropTypes> = (props) => {
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 967))
  const isXxs = useMediaQuery((theme: Theme) => theme.breakpoints.down(332))
  const onButtonClick = () => {
    setShowDialog(!showDialog)
  }
  return (
    <div
      style={{
        width: '100%',
        marginTop: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ProUXButton
        label={'Send Sungage Application'}
        onClick={onButtonClick}
        type="secondary"
        backgroundColor="#fff"
        fullWidth
        fullHeight
        isMarginTop={isMd || isXxs ? true : false}
        labelStyle={{
          display: 'flex',
          flexDirection: isMd || isXxs ? 'column' : 'row',
          alignItems: isMd || isXxs ? 'flex-start' : 'end',
        }}
        startIcon={<img width="21" height="21" src={window.PUBLIC_URL + '/images/sungage_logo_small.png'} />}
        justifyContent="flex-start"
      />
      {showDialog && (
        <SungageSendAppDialog
          onClose={() => setShowDialog(false)}
          open={showDialog}
          project={props.project}
          designs={props.designs}
        />
      )}
    </div>
  )
}
export default SungageSendAppButton

import { makeStyles } from '@material-ui/core'
import { Button } from 'opensolar-ui'
import { usePermissions, userLogin, useTranslate } from 'react-admin'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import { Redirect } from 'react-router-dom'
import appStorage from 'storage/appStorage'
import AuthModalWrapper from './AuthModalWrapper'
import { NearmapLogo } from './common'

const Restricted = (props: any) => {
  const { loaded, permissions } = usePermissions()

  if (
    props.authParams.permission !== permissions &&
    permissions &&
    permissions.indexOf(props.authParams.permission) === -1 &&
    loaded === true
  ) {
    return <Redirect to={props.authParams.redirectTo} />
  } else {
    return props.children
  }
}

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    width: 280,
    marginTop: 0,
    minWidth: 210,
    padding: '0.5em 1em 1em 1em',
  },
  button: {
    color: theme.headerFontColor,
    background: theme.themeColor,
    '&:hover': {
      cursor: 'pointer',
      background: theme.themeColorDark,
    },
  },
}))

const LoginNearmapEmailClashLoggedIn = () => {
  const translate = useTranslate()
  const classes = useStyles()
  const location = useLocation()
  const dispatch = useDispatch()
  const loginWithNearmapToken = (
    nearmap_token: string,
    upgrade_action: string,
    upgrade_org_id: number | null,
    sendBearerToken: boolean
  ) => {
    // // Special cases BROKEN because appStorage org_id gets updated by other updates after fixing them here:
    // //
    // // 1. New Account
    // // If we are creating a new org then we intentionally forget any org_id in appStorage
    // // This ensures we will switch to the newly created org id instead of retaining the old org id
    // //
    // // 2. Upgrade Account
    // // Ensure we switch to this account. Probably redundant but may be useful when multiple orgs are linked.
    //
    // if (upgrade_action === 'new_account') {
    //   setOrgId(null)
    // } else if (upgrade_org_id) {
    //   setOrgId(upgrade_org_id)
    // }

    const { state: pathName = '/projects' } = location
    dispatch(
      userLogin({
        payload: {
          nearmap_token: nearmap_token,
          upgrade_action: upgrade_action,
          upgrade_org_id: upgrade_org_id,
          sendBearerToken: true,
        },
        state: { nextPathname: pathName },
        nearmapToken: nearmap_token,
      })
    )
  }
  return (
    <Restricted authParams={{ permission: 'is_pro' }} location={location}>
      <AuthModalWrapper>
        <div className={classes.wrapper}>
          <div style={{ textAlign: 'center' }}>
            <NearmapLogo />
          </div>

          <Button
            classes={{ root: classes.button }}
            onClick={(e) => {
              e.preventDefault()
              loginWithNearmapToken(appStorage.getString('nearmap_token')!, 'upgrade_user', null, true)
            }}
          >
            <span style={{ textTransform: 'none' }}>
              {translate('Enable login with Nearmap for my OpenSolar user login')}
            </span>
          </Button>
        </div>
      </AuthModalWrapper>
    </Restricted>
  )
}
export default LoginNearmapEmailClashLoggedIn

import { BarChartIcon, LeafIcon, MoneyIcon } from 'opensolar-ui'
import BSABackupAppliancesNode from '../nodes/BSABackupAppliancesNode'
import BSACustomerPriorityModeNode from '../nodes/BSACustomerPriorityModeNode'
import BSAManualSelectorNode from '../nodes/BSAManualSelectorNode'
import BSASelectorNode from '../nodes/BSASelectorNode'
import BSAModeNode from '../nodes/modeSelector/BSAModeNode'
import { BSAAppliances, BSACustomerPrioritySelectionType } from './types'

export const BSA_MODE_NODE_KEY = 'BSA_MODE_NODE_KEY'
export const BSA_CUSTOMER_PRIORITY_NODE_KEY = 'BSA_CUSTOMER_PRIORITY_NODE_KEY'
export const BSA_MANUAL_NODE_KEY = 'BSA_MANUAL_NODE_KEY'
export const BSA_BACKUP_APPLIANCES_NODE_KEY = 'BSA_BACKUP_APPLIANCES_NODE_KEY'
export const BSA_SELECTOR_NODE_KEY = 'BSA_SELECTOR_NODE_KEY'

export const BSA_FLOW_CONFIG = {
  [BSA_MODE_NODE_KEY]: BSAModeNode,
  [BSA_CUSTOMER_PRIORITY_NODE_KEY]: BSACustomerPriorityModeNode,
  [BSA_MANUAL_NODE_KEY]: BSAManualSelectorNode,
  [BSA_BACKUP_APPLIANCES_NODE_KEY]: BSABackupAppliancesNode,
  [BSA_SELECTOR_NODE_KEY]: BSASelectorNode,
}

export const BSA_FLOW_STEPS = {
  [BSA_CUSTOMER_PRIORITY_NODE_KEY]: 1,
  [BSA_BACKUP_APPLIANCES_NODE_KEY]: 2,
  [BSA_SELECTOR_NODE_KEY]: 3,
}

export const BSA_NODES_WITH_BREADCRUMBS = [
  BSA_CUSTOMER_PRIORITY_NODE_KEY,
  BSA_BACKUP_APPLIANCES_NODE_KEY,
  BSA_SELECTOR_NODE_KEY,
]

export const BSA_SELECTIONS: BSACustomerPrioritySelectionType[] = [
  {
    description: 'Designed to maximize cost reductions',
    icon: <MoneyIcon height="32" fill="white" width="32" />,
    key: 'maximize_savings',
    title: 'Maximize Savings',
  },
  {
    description: 'Tailored to minimize grid energy usage throughout the day',
    icon: <LeafIcon fill="#757575" height="32" width="32" />,
    key: 'self_consumption',
    title: 'Self-Consumption',
  },
  {
    description: 'Tailored to reduce peak energy usage and associated costs',
    icon: <BarChartIcon height="32" fill="white" width="32" />,
    key: 'peak_demand_shaving',
    title: 'Peak Demand Shaving',
  },
]

export const BSA_APPLIANCES: BSAAppliances = {
  'Ceiling fan': {
    amplitudeKey: 'ceiling_fan',
    continuousPower: 0.09,
    enabled: false,
    peakPower: 0.09,
    quantity: 0,
  },
  'Clothes Washer': {
    amplitudeKey: 'clothes_washer',
    continuousPower: 0.9,
    enabled: false,
    peakPower: 1.8,
    quantity: 0,
  },
  Computer: {
    amplitudeKey: 'computer',
    continuousPower: 0.1,
    enabled: false,
    peakPower: 0.1,
    quantity: 0,
  },
  Freezer: {
    amplitudeKey: 'freezer',
    continuousPower: 0.5,
    enabled: false,
    peakPower: 1,
    quantity: 0,
  },
  'Lighting (Per bulb)': {
    amplitudeKey: 'light',
    continuousPower: 0.015,
    enabled: false,
    peakPower: 0.015,
    quantity: 0,
  },
  Refrigerator: {
    amplitudeKey: 'refrigerator',
    continuousPower: 0.5,
    enabled: false,
    peakPower: 1,
    quantity: 0,
  },
  TV: {
    amplitudeKey: 'tv',
    continuousPower: 0.075,
    enabled: false,
    peakPower: 0.075,
    quantity: 0,
  },
  'Wifi Modem': {
    amplitudeKey: 'wifi',
    continuousPower: 0.005,
    enabled: false,
    peakPower: 0.005,
    quantity: 0,
  },
  'Clothes Dryer': {
    amplitudeKey: 'clothers_dryer',
    continuousPower: 4,
    enabled: false,
    peakPower: 6,
    quantity: 0,
  },
  Dishwasher: {
    amplitudeKey: 'dishwasher',
    continuousPower: 2.4,
    enabled: false,
    peakPower: 3.6,
    quantity: 0,
  },
  Heatpump: {
    amplitudeKey: 'heatpump',
    continuousPower: 3.5,
    enabled: false,
    peakPower: 6.0,
    quantity: 0,
  },
  Hotwater: {
    amplitudeKey: 'hotwater',
    continuousPower: 3.6,
    enabled: false,
    peakPower: 7.0,
    quantity: 0,
  },
  'Pool Pump': {
    amplitudeKey: 'pool_pump',
    continuousPower: 1.1,
    enabled: false,
    peakPower: 2.2,
    quantity: 0,
  },
  'Range Oven': {
    amplitudeKey: 'range_oven',
    continuousPower: 1.2,
    enabled: false,
    peakPower: 2.4,
    quantity: 0,
  },
  'Well Pump': {
    amplitudeKey: 'well_pump',
    continuousPower: 1.2,
    enabled: false,
    peakPower: 2.4,
    quantity: 0,
  },
  'Air Conditioner (AC)': {
    amplitudeKey: 'air_conditioning',
    continuousPower: 3.5,
    enabled: false,
    peakPower: 5.25,
    quantity: 0,
  },
  Other: {
    amplitudeKey: 'other',
    continuousPower: 0,
    enabled: false,
    peakPower: 0,
    quantity: 0,
  },
}

import { RootState } from 'types/state'
import { ExternalWorkflow } from 'types/workflows'

export const SET_HISTORY_OPEN = 'SET_HISTORY_OPEN'
export const SET_WORKFLOW_VIEW = 'SET_WORKFLOW_VIEW'
export const SET_EVENTS_VALID = 'SET_EVENTS_VALID'
export const SET_CLEAR_SETTINGS = 'SET_CLEAR_SETTINGS'

export type WorkflowViewType = {
  view: 'internal' | 'external'
  externalWorkflow?: ExternalWorkflow
}

export const projectViewSettingsActions = {
  setHistoryOpen: (historyOpen: boolean) => ({
    type: SET_HISTORY_OPEN,
    historyOpen,
  }),
  setWorkflowView: (workflowView: WorkflowViewType) => ({
    type: SET_WORKFLOW_VIEW,
    workflowView,
  }),
  setEventsValid: (eventsValid: boolean) => ({
    type: SET_EVENTS_VALID,
    eventsValid,
  }),
  clearState: () => ({
    type: SET_CLEAR_SETTINGS,
  }),
}

interface ProjectHistoryAction {
  type: typeof SET_HISTORY_OPEN
  historyOpen: boolean
}
interface ProjectWorkflowViewAction {
  type: typeof SET_WORKFLOW_VIEW
  workflowView: WorkflowViewType
}
interface ProjectEventsValidAction {
  type: typeof SET_EVENTS_VALID
  eventsValid: boolean
}
interface ProjectClearStateAction {
  type: typeof SET_CLEAR_SETTINGS
}

type ActionType = ProjectHistoryAction | ProjectWorkflowViewAction | ProjectEventsValidAction | ProjectClearStateAction

export type ProjectViewSettingsState = {
  historyOpen: boolean
  workflowView: WorkflowViewType
  eventsValid: boolean
}

const initialState: ProjectViewSettingsState = {
  historyOpen: false,
  workflowView: { view: 'internal' },
  eventsValid: true,
}

export default (previousState: ProjectViewSettingsState = initialState, action: ActionType) => {
  switch (action.type) {
    case SET_HISTORY_OPEN:
      return {
        ...previousState,
        historyOpen: action.historyOpen,
      }
    case SET_WORKFLOW_VIEW:
      return {
        ...previousState,
        workflowView: action.workflowView,
      }
    case SET_EVENTS_VALID:
      return {
        ...previousState,
        eventsValid: action.eventsValid,
      }
    case SET_CLEAR_SETTINGS:
      return {
        ...initialState,
      }

    default:
      return previousState
  }
}

export const projectViewSettingsSelectors = {
  getProjectHistoryOpen(state: RootState): boolean {
    return state.projectViewSettings.historyOpen
  },
  getProjectWorkflowView(state: RootState): WorkflowViewType {
    return state.projectViewSettings.workflowView
  },
  getEventsValid(state: RootState): boolean {
    return state.projectViewSettings.eventsValid
  },
}

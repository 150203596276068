import React, { useEffect, useState } from 'react'
import { AutocompleteInput, useDataProvider } from 'react-admin'
import { useDebouncedCallback } from 'util/Debounce'
import { RoleSelectOptionType, RoleWithDisplay } from './types'
import { convertRolesToOptions } from './util'

type PropTypes = {
  label: string
  name: string
  resource: string
  allowDelete: boolean
  projectId: number | undefined
  sharedWith: unknown[] | undefined
  initialData: RoleSelectOptionType[]
}

const ProjectAssignmentField: React.FC<PropTypes> = (props) => {
  const [options, setOptions] = useState<RoleSelectOptionType[]>([])
  const [search, setSearch] = useState<string | undefined>(undefined)
  const [isFocused, setIsFocused] = useState<boolean>(false)

  const dataProvider = useDataProvider()

  useEffect(() => {
    if (!options?.length && props.initialData?.length && !search) {
      // set initial options
      setOptions(props.initialData)
    } else if (options?.length && !search) {
      // reset options if search is cleared
      setOptions(props.initialData)
    }
  }, [options, props.initialData, search])

  useEffect(() => {
    //TODO: Make this more robust, searching for protocol isn't great
    if (search && !search.includes('http://') && !search.includes('https://') && isFocused) {
      fetchRoles()
    }
  }, [search, isFocused])

  const fetchRoles = () => {
    dataProvider
      .getList('roles', {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'display', order: 'DESC' },
        filter: { shared_project_id: props.projectId, search: search },
      })
      .then((res) => {
        let responseData = res.data as RoleWithDisplay[]
        setOptions(convertRolesToOptions(responseData))
      })
      .catch((err) => setOptions(props.initialData))
  }

  const handleChange = useDebouncedCallback((val: string | undefined) => {
    setSearch(val)
  }, 500)

  const onBlur = () => {
    setIsFocused(false)
  }

  const onFocus = () => {
    setIsFocused(true)
  }

  return (
    <AutocompleteInput
      choices={options}
      label={props.label}
      name={props.name}
      // resource={props.resource}
      allowEmpty={props.allowDelete}
      optionText="display"
      optionValue="url"
      /* Hack to ensure some choices don't get mysteriously dropped */
      allowDuplicates={true}
      source={props.name}
      style={{ width: '100%' }}
      fullWidth={true}
      onInputValueChange={handleChange}
      onBlur={onBlur}
      onFocus={onFocus}
      clearOnBlur={true}
    />
  )
}
export default ProjectAssignmentField

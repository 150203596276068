import { setFormDialog } from 'ducks/formDialog'
import { Button } from 'opensolar-ui'
import React from 'react'
import { useDispatch } from 'react-redux'
import { StudioSystemType } from 'types/global'
import { FormPlacementInfo } from '../util/findFormPlacements'

type PropTypes = {
  customForm: FormPlacementInfo
  system?: StudioSystemType
  fullWidth?: boolean
}

export const CustomFormButton: React.FC<PropTypes> = ({ customForm, system, fullWidth = true }) => {
  const dispatch = useDispatch()
  return (
    <Button
      style={{
        marginTop: 10,
        height: 'fit-content',
      }}
      fullWidth={fullWidth}
      variant="contained"
      size="small"
      onClick={() => {
        dispatch(setFormDialog(true, customForm, system?.uuid || null))
      }}
    >
      {customForm.form.title}
    </Button>
  )
}

import { watchSystemCalculationRequest, watchCalculateSystem } from './calculation'
import { watchAutoStringingRequest } from './autoStringing'
import { watchDetectImagery } from './detectImagery'
import { watchActiveComponentRequest } from './activeComponent'
import { watchProjectSectionClear } from './projectErrors'
import { watchNotifications } from './notifications'

export default [
  watchActiveComponentRequest,
  watchSystemCalculationRequest,
  watchCalculateSystem,
  watchAutoStringingRequest,
  watchDetectImagery,
  watchProjectSectionClear,
  watchNotifications,
]

// @ts-nocheck
import { Grid, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CustomField from 'elements/field/CustomField'
import CheckboxInput from 'elements/input/CheckboxInput'
import React, { useEffect, useState } from 'react'
import { SelectInput, TextInput, useTranslate } from 'react-admin'
import { useField, useForm } from 'react-final-form'
import { getIsValidEmail } from 'util/misc'
import { DAYS_BY_MONTH, EMPLOYMENT_STATUS_CHOICES, MONTHS, YES_NO_CHOICES, getYearChoices } from './constants'
import { DisclosureType } from './types'

type ChoiceType = {
  name: string
  id: string
}

type PropTypes = {
  disclosureText: DisclosureType
}

const useStyles = makeStyles({
  applicantHeaderView: {},
  applicantHeaderText: {
    fontWeight: 900,
    fontSize: '18px',
  },
  applicantSection: {
    border: '1px solid #ececec',
    borderRadius: '5px',
    padding: '10px',
    width: '100%',
  },
})

const DividendApplicationForm: React.FunctionComponent<PropTypes> = (props) => {
  const primaryDobMonth = useField('dob_month', { subscription: { value: true } }).input.value
  const isPrimaryResidence = useField('is_primary_residence', { subscription: { value: true } }).input.value
  const form = useForm()

  const getDayChoices = () => {
    const selectedMonth = primaryDobMonth
    return selectedMonth &&
      !isNaN(parseInt(selectedMonth)) &&
      parseInt(selectedMonth) > 0 &&
      parseInt(selectedMonth) < 13
      ? // @ts-ignore
        DAYS_BY_MONTH[selectedMonth]
      : DAYS_BY_MONTH['none']
  }

  const [primaryAppDayChoices, setPrimaryAppDayChoices] = useState<ChoiceType[]>(getDayChoices())

  useEffect(() => {
    setPrimaryAppDayChoices(getDayChoices())
  }, [primaryDobMonth])

  useEffect(() => {
    if (isPrimaryResidence !== 'no') {
      form.batch(() => {
        form.change('primary_address', undefined)
        form.change('primary_unit', undefined)
        form.change('primary_locality', undefined)
        form.change('primary_state', undefined)
        form.change('primary_zip', undefined)
      })
    }
  }, [isPrimaryResidence])

  const translate = useTranslate()
  const classes = useStyles()
  const isMobile = useMediaQuery('(max-width:900px)')

  const validatePhone = (raw: string, otherFields: any, meta: any) => {
    if (!raw) {
      return 'Phone Number is required'
    } else {
      const justNumbers = raw.replace(/[^0-9]/g, '')
      if (justNumbers.length !== 10) return 'Invalid phone number'
    }
    return undefined
  }

  const validateEmail = (raw: string) => {
    const isValid = getIsValidEmail(raw)
    if (!raw) return 'Required Field'
    else if (!isValid) return 'Invalid Email Address'
    else return undefined
  }

  const parsePhone = (formatted: string) => {
    if (!formatted) return undefined
    else {
      const deletedParens = formatted.length === 4 && formatted.startsWith('(') && !formatted.endsWith(')')
      let justNumbers = formatted.replace(/[^0-9]/g, '')
      if (justNumbers.length > 0) {
        justNumbers = justNumbers.substring(0, 10)
        if (deletedParens) justNumbers = justNumbers.substring(0, 2)
      }
      return justNumbers
    }
  }

  const formatPhone = (phone: string) => {
    if (!phone) return null
    phone = phone.replace(/[^\d]/g, '')

    if (phone.length > 0) {
      let chunk1 = phone.substring(0, 3)
      let chunk2 = phone.substring(3, 6)
      let chunk3 = phone.substring(6)
      if (chunk1 && chunk2 && chunk3) {
        return `(${chunk1}) ${chunk2} - ${chunk3}`
      } else if (chunk1 && chunk2) {
        return `(${chunk1}) ${chunk2}`
      } else if (chunk1 && chunk1.length === 3) {
        return `(${chunk1})`
      } else return chunk1
    }
    return null
  }

  const parseSSN = (formatted: string) => {
    if (!formatted) return undefined
    else {
      let justNumbers = formatted.replace(/[^0-9]/g, '')
      if (justNumbers.length > 0) {
        justNumbers = justNumbers.substring(0, 4)
      }
      return justNumbers
    }
  }

  const formatSSN = (ssn: string) => {
    if (!ssn) return null
    ssn = ssn.replace(/[^\d]/g, '')

    if (ssn.length > 0) {
      return ssn
    }
    return null
  }

  const parseIncome = (formatted: string) => {
    if (!formatted) return undefined
    else {
      let justNumbers = formatted.replace(/[^0-9,$]/g, '')
      if (justNumbers.length > 0) {
        justNumbers = justNumbers.substring(0, 9)
      }
      return justNumbers
    }
  }

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
          <img src={`${window.PUBLIC_URL}/images/dividend-logo-wide.svg`} alt="" height={isMobile ? 25 : 50} />
        </Grid>
        <div className={classes.applicantSection}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className={classes.applicantHeaderView}>
                <span className={classes.applicantHeaderText}>{translate('Borrower Information')}</span>
              </div>
            </Grid>
            <Grid item xs={6}>
              <CustomField
                name="first_name"
                source="first_name"
                component={TextInput}
                fullWidth
                style={{ width: '100%' }}
                required={true}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomField
                name="family_name"
                source="family_name"
                component={TextInput}
                fullWidth
                style={{ width: '100%' }}
                required={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomField
                name="phone_number"
                source="phone_number"
                label={translate('Phone Number')}
                component={TextInput}
                fullWidth
                style={{ width: '100%' }}
                validateOnBlur={validatePhone}
                parse={parsePhone}
                format={formatPhone}
                required={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomField
                name="email"
                source="email"
                label={translate('Email Address')}
                component={TextInput}
                fullWidth
                style={{ width: '100%' }}
                required={true}
                validateOnBlur={validateEmail}
              />
            </Grid>
            <Grid item xs={12}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <span style={{ fontWeight: 900 }}>Date of Birth</span>
                <div style={{ display: 'flex', width: '100%' }}>
                  <div style={{ flex: 1, padding: '0px 4px' }}>
                    <CustomField
                      name="dob_month"
                      source="dob_month"
                      label={translate('Month')}
                      component={SelectInput}
                      choices={MONTHS}
                      fullWidth
                      style={{ width: '100%' }}
                      required={true}
                    />
                  </div>
                  <div style={{ flex: 1, padding: '0px 4px' }}>
                    <CustomField
                      name="dob_day"
                      source="dob_day"
                      label={translate('Day')}
                      component={SelectInput}
                      choices={primaryAppDayChoices}
                      fullWidth
                      style={{ width: '100%' }}
                      required={true}
                      // parse={parseDate}
                    />
                  </div>
                  <div style={{ flex: 1, padding: '0px 4px' }}>
                    <CustomField
                      name="dob_year"
                      source="dob_year"
                      label={translate('Year')}
                      component={SelectInput}
                      choices={getYearChoices()}
                      fullWidth
                      style={{ width: '100%' }}
                      required={true}
                      // parse={parseDate}
                    />
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomField
                name="ssn"
                source="ssn"
                label={translate('Last 4 digits of SSN')}
                component={TextInput}
                fullWidth
                style={{ width: '100%' }}
                parse={parseSSN}
                format={formatSSN}
                required={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomField
                name="income"
                source="income"
                label={translate('Pre-Tax Annual Income')}
                component={TextInput}
                fullWidth
                parse={parseIncome}
                style={{ width: '100%' }}
                required={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomField
                name="employment_status"
                source="employment_status"
                label={translate('Employment Status')}
                component={SelectInput}
                choices={EMPLOYMENT_STATUS_CHOICES}
                fullWidth
                style={{ width: '100%' }}
                required={true}
              />
            </Grid>
          </Grid>
        </div>
        <h4 style={{ margin: '20px 0px 0px 0px' }}>Property Information</h4>
        <div className={classes.applicantSection}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <CustomField
                name="address"
                source="address"
                label={translate('Street Address (no P.O. Boxes)')}
                component={TextInput}
                fullWidth
                style={{ width: '100%' }}
                required={true}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <CustomField
                name="unit"
                source="unit"
                label={translate('Unit')}
                component={TextInput}
                fullWidth
                style={{ width: '100%' }}
                required={false}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomField
                name="locality"
                source="locality"
                label={translate('City')}
                component={TextInput}
                fullWidth
                style={{ width: '100%' }}
                required={true}
              />
            </Grid>
            <Grid item xs={3}>
              <CustomField
                name="state"
                source="state"
                component={TextInput}
                fullWidth
                style={{ width: '100%' }}
                required={true}
              />
            </Grid>
            <Grid item xs={3}>
              <CustomField
                name="zip"
                source="zip"
                component={TextInput}
                fullWidth
                style={{ width: '100%' }}
                required={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomField
                name="monthly_mortgage"
                source="monthly_mortgage"
                label={translate('Monthly Mortgage Payment')}
                component={TextInput}
                fullWidth
                style={{ width: '100%' }}
                required={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomField
                name="is_primary_residence"
                source="is_primary_residence"
                label={translate('Is this your Primary Residence?')}
                component={SelectInput}
                choices={YES_NO_CHOICES}
                fullWidth
                style={{ width: '100%' }}
                required={true}
              />
            </Grid>
            {isPrimaryResidence === 'no' && (
              <>
                <Grid item xs={12}>
                  <span style={{ fontWeight: 900 }}>Primary Address</span>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomField
                    name="primary_address"
                    source="primary_address"
                    label={translate('Street Address (no P.O. Boxes)')}
                    component={TextInput}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <CustomField
                    name="primary_unit"
                    source="primary_unit"
                    label={translate('Unit')}
                    component={TextInput}
                    fullWidth
                    style={{ width: '100%' }}
                    required={false}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomField
                    name="primary_locality"
                    source="primary_locality"
                    label={translate('City')}
                    component={TextInput}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomField
                    name="primary_state"
                    source="primary_state"
                    component={TextInput}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomField
                    name="primary_zip"
                    source="primary_zip"
                    component={TextInput}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </div>
        <div id="disclosures">
          {Object.keys(props.disclosureText).map((disclosureKey) => {
            const disclosureHTML = props.disclosureText[disclosureKey]
            return (
              <Grid item xs={12}>
                <div
                  style={{ whiteSpace: 'pre-wrap' }}
                  dangerouslySetInnerHTML={{
                    __html: disclosureHTML,
                  }}
                />
                <CustomField
                  name={disclosureKey}
                  source={disclosureKey}
                  label="I Agree"
                  component={(checkboxProps: any) => (
                    <CheckboxInput {...checkboxProps} elStyle={{ maxWidth: '900px' }} />
                  )}
                  fullWidth
                  required
                  style={{ width: '100%' }}
                />
              </Grid>
            )
          })}
        </div>
      </Grid>
    </div>
  )
}

export default DividendApplicationForm

import { Box, makeStyles, useMediaQuery } from '@material-ui/core'
import ArrowDownward from '@material-ui/icons/KeyboardArrowDownOutlined'
import Alert from 'elements/Alert'
import { IconButton } from 'opensolar-ui'
import { ExploreAlreadyExistsMessage } from 'projectSections/elements/header/ExploreAlreadyExistsMessage'
import SaveButton from 'projectSections/elements/header/actions/SaveButton'
import { useTranslate } from 'ra-core'
import React, { useCallback, useEffect, useState } from 'react'
import { useFormState } from 'react-final-form'
import { StudioSystemType } from 'types/global'
import { ProjectType } from 'types/projects'
import { Theme } from 'types/themes'
import SystemProgressBar from '../SystemProgressBar'
import ImageBoundsPanel from './panels/ImageBoundsPanel'
import PanelGroupPanel from './panels/PanelGroupPanel'
import PanelsPanel from './panels/PanelsPanel'
import ProjectActionsPanel from './panels/ProjectActionsPanel'
import ReportActionsPanel from './panels/ReportActionsPanel'
import SystemDetailPanel from './panels/SystemDetailPanel'
import SystemSummaryPanel from './panels/SystemSummaryPanel'
import TitlePanel from './panels/TitlePanel'

export const LITE_PANEL_WIDTH = 429

// @ts-ignore
const useStyles = makeStyles<any, { open: boolean }>((theme) => {
  return {
    root: () => ({
      background: 'white',
      width: LITE_PANEL_WIDTH,
      position: 'absolute',
      top: 0,
      left: 0,
      maxHeight: '100%',
      display: 'flex',
      flexDirection: 'column',

      [theme.breakpoints.down('sm')]: ({ open }) => ({
        width: '100%',
        height: open ? '100%' : '',
      }),
    }),
    scrollCont: ({ open }) => {
      return {
        maxHeight: '100%',
        overflowY: 'auto',
        height: !open ? 0 : '',

        [theme.breakpoints.down('sm')]: ({ open }) => ({
          maxHeight: 'unset',
          height: !open ? 0 : '100%',
        }),
      }
    },
    flexCont: {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
      padding: 10,
      paddingTop: 0,

      '& > *': {
        boxSizing: 'border-box',
      },
    },
    icon: ({ open }) => {
      return {
        position: 'absolute',
        top: 10,
        right: 2,
        transform: open ? 'rotate(180deg)' : 'unset',
        transition: 'transform 0.1s ease',
      }
    },
    alert: {
      margin: '5px 10px',
    },
  }
})

interface PropTypes {
  system: StudioSystemType | undefined
  calcsPending: boolean
  calculating: boolean
  isCreated: boolean
  panelOpen: boolean
  setPanelOpen: (value: boolean) => void
}

const PanelSystemLite: React.FC<PropTypes> = ({
  system,
  calcsPending,
  calculating,
  isCreated,
  panelOpen,
  setPanelOpen,
}) => {
  const translate = useTranslate()
  const formValues = useFormState().values
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const [editMode, setEditMode] = useState(window.ViewBoxHelper.isEditing())

  const onToggleOpen = useCallback(() => {
    setPanelOpen(!panelOpen)
  }, [panelOpen, setPanelOpen])

  useEffect(() => {
    if (isSmall || !panelOpen) window.editor.setLeftMarginPixels(0, undefined, true)
    else window.editor.setLeftMarginPixels(LITE_PANEL_WIDTH, undefined, true)
  }, [isSmall, panelOpen])

  const project: ProjectType | undefined = formValues as ProjectType

  const classes = useStyles({ open: panelOpen })
  return !project || (!isCreated && isSmall) ? (
    <></>
  ) : (
    <div className={classes.root}>
      <SystemProgressBar isLoading={calcsPending} isCalculating={calculating} />
      {!isCreated && project.address && (
        <ExploreAlreadyExistsMessage classes={{ wrapper: classes.alert }} address={project.address} variant="alert" />
      )}
      {!isCreated && (
        <Alert classes={{ wrapper: classes.alert }} severity="info">
          {translate('Explore the site before creating the project')}
        </Alert>
      )}
      <TitlePanel project={project} />
      {!isCreated && <SaveButton style={{}} label="Create Project" />}
      {isCreated && (
        <>
          <IconButton className={classes.icon} onClick={onToggleOpen} disableRipple={true}>
            <ArrowDownward />
          </IconButton>
          <Box className={classes.scrollCont}>
            {system ? (
              <div className={classes.flexCont}>
                <PanelsPanel project={project} system={system} />
                <ImageBoundsPanel project={project} editMode={editMode} setEditMode={setEditMode} />
                <SystemSummaryPanel project={project} system={system} />
                <PanelGroupPanel project={project} system={system} />
                <SystemDetailPanel project={project} system={system} calcsValid={!calcsPending && !calculating} />
                <ReportActionsPanel project={project} system={system} editMode={editMode} />
                <ProjectActionsPanel project={project} />
              </div>
            ) : (
              <></>
            )}
          </Box>
        </>
      )}
    </div>
  )
}

export default PanelSystemLite

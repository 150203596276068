import { ORG_ROLE_TYPE, USER_ROLE_TYPE } from 'types/customRole'

export const ROLE_TYPE_CHOICES: RoleType[] = [
  { id: USER_ROLE_TYPE, name: 'User', disabled: [] },
  {
    id: ORG_ROLE_TYPE,
    name: 'Connected Org',
    hide_project_access: true,
    hide_templates: false,
    hide_perms_control: true,
    disabled: [
      'project.project.view',
      'project.info_sharing.edit',
      'project.info_sharing.create',
      'project.info_sharing.delete',
    ],
  },
]

export interface RoleType {
  id: number
  name: string
  hide_templates?: boolean
  hide_project_access?: true
  hide_perms_control?: true
  hide_perms_project?: true
  disabled: string[]
}

export function getRoleType(id: number): RoleType | undefined {
  return ROLE_TYPE_CHOICES.find((rt) => rt.id === id)
}

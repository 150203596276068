// @ts-nocheck
import { makeStyles, Paper, useMediaQuery } from '@material-ui/core'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined'
import { myEnergySelectors } from 'ducks/myEnergy'
import { setViewAsCustomer as setViewAsCustomerAction } from 'ducks/viewAsCustomer'
import React, { useCallback, useEffect } from 'react'
import { useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { MapDataTypes } from 'types/map'
import { parseQueryStringToDictionary } from 'util/misc'
import DiscardButton from '../../elements/header/actions/DiscardButton'
import SaveButton from '../../elements/header/actions/SaveButton'
import ProposalToolbarDialogCTA from './financeCTAs/proposalToolbar/ToolbarCTAButton'
import SendCreditAppButton from './sendCreditAppCta/SendCreditAppButton'
import SendProposal from './SendProposalButton'
import ViewAsCustomerToggle from './ViewAsCustomerToggle'

type PropTypes = {
  availableImageryTypes?: MapDataTypes[] | []
}
const useStyles = makeStyles((theme: any) => ({
  paper: {
    position: 'fixed',
    width: '100%',
    zIndex: 1,
    height: 50,
    bottom: 0,
    left: 0,
    right: 0,
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 20px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]: {
      bottom: 50,
    },
  },
  paperViewAsCustomer: {
    position: 'fixed',
    width: '100%',
    zIndex: 1,
    padding: 0,
    height: 50,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    background: 'rgba(255,255,255,0.9)',
    justifyContent: 'center',
  },
  formActions: {
    width: 185,
  },
  actionWrapper: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
}))

const ProposalToolbar: React.FC<PropTypes> = () => {
  const classes = useStyles()
  const translate = useTranslate()
  const form = useForm()
  const location = useLocation()
  const { search } = location
  const history = useHistory()
  const disableActions = form.mutators.getFormDirtyFields()?.length === 0
  const dispatch = useDispatch()
  const smallLayout = useMediaQuery((theme: any) => theme.breakpoints.down('sm'))
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('xs'))
  const viewAsCustomer = useSelector((state: any) => state.viewAsCustomer)
  const projectCountry = useSelector(myEnergySelectors.getProposalProjectCountry)

  const showFormActions = !disableActions && smallLayout

  const toggleViewAsCustomer = useCallback(
    (isChecked: boolean) => {
      if (isChecked) {
        //add mode=customerView in query
        history.push({
          pathname: location.pathname,
          search: search ? `${search}&mode=customerView` : 'mode=customerView',
        })
      } else {
        //remove mode=customerView from query
        const query: Record<string, string> = parseQueryStringToDictionary(search)
        const newSearch = Object.keys(query)
          .filter((key) => key !== 'mode')
          .reduce(function (previous: string, key: string, index: number) {
            return previous + (index > 0 ? '&' : '') + `${key}=${query[key]}`
          }, '')
        history.push({
          pathname: location.pathname,
          search: newSearch,
        })
      }
    },
    [search]
  )

  useEffect(() => {
    // query controlled
    const query: Record<string, string> = parseQueryStringToDictionary(search)
    const isViewAsCustomer = !!(query.mode === 'customerView')
    form.mutators.recordFormDirtyFields()
    dispatch(setViewAsCustomerAction(isViewAsCustomer))
  }, [search])

  useEffect(() => {
    return () => {
      dispatch(setViewAsCustomerAction(false))
    }
  }, [])

  if (isMobile && viewAsCustomer) {
    return (
      <Paper id="proposal-bottom-toolbar" elevation={1} classes={{ root: classes.paperViewAsCustomer }}>
        <VisibilityOffIcon
          style={{ cursor: 'pointer', color: '#1976d2', marginRight: 5 }}
          onClick={() => toggleViewAsCustomer(false)}
        />
        {translate('Viewing as Customer')}
      </Paper>
    )
  }

  return (
    <Paper id="proposal-bottom-toolbar" elevation={1} classes={{ root: classes.paper }}>
      <ViewAsCustomerToggle toggleViewAsCustomer={toggleViewAsCustomer} />
      <div className={classes.actionWrapper}>
        <ProposalToolbarDialogCTA />
        {projectCountry === 'US' && <SendCreditAppButton />}
        <SendProposal />
        {showFormActions && (
          <div className={classes.formActions}>
            <DiscardButton />
            <SaveButton />
          </div>
        )}
      </div>
    </Paper>
  )
}

export default ProposalToolbar

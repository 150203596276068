import { FormControl, MenuItem, Select, makeStyles } from '@material-ui/core'
import useOptimisticValue from 'elements/input/useOptimisticValue'
import { ChangeEvent, useState } from 'react'
import { useTranslate } from 'react-admin'
import { Theme } from 'types/themes'
import type { BasicRendererComponentPropsType, OptionGenericType } from '../type'

const useStyles = makeStyles(
  (theme: Theme) => ({
    filter: ({ disabled }: { disabled: boolean }) => ({
      border: theme.border.default,
      borderRadius: '4px',
      margin: '2px',
      flexFlow: 'row',
      alignItems: 'center',
      columnGap: '4px',
      cursor: disabled ? 'default' : 'pointer',
    }),
    filterLabel: {
      position: 'static',
      margin: 0,
      transform: 'unset',
      paddingLeft: '5px',
      fontSize: '14px',
      color: 'inherit',
      lineHeight: '16px',
    },

    filterSelect: {
      position: 'static',
      margin: 0,
      color: theme.secondaryColor,
      lineHeight: '16px',
    },
    filterSelectDisabled: {
      color: 'inherit',
    },
    filterMenu: {
      minHeight: 'auto',
    },
  }),
  { name: 'HardwareSelectFilterV2' }
)

/*
  A dropdown renderer for hardware filters
*/
const BasicSelectorRenderer = <T extends OptionGenericType<string | number>[]>({
  id,
  label,
  options,
  disabled,
  onSelect,
  defaultValue,
  value,
}: BasicRendererComponentPropsType<T>) => {
  const classes = useStyles({ disabled: !!disabled })
  const translate = useTranslate()
  const [open, setOpen] = useState(false)

  const { optimisticValue, updateOptimisticValue } = useOptimisticValue<T[number]['value']>({ defaultValue, value })

  const handleChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    const newValue = event.target.value as T[number]['value']
    updateOptimisticValue(newValue)
    onSelect(newValue)
  }

  return (
    <FormControl id={id} classes={{ root: classes.filter }} onClick={() => !disabled && setOpen(!open)}>
      <div className={classes.filterLabel}>{translate(label)}</div>
      <Select
        classes={{
          select: classes.filterSelect,
          selectMenu: classes.filterMenu,
          disabled: classes.filterSelectDisabled,
        }}
        open={open}
        displayEmpty
        disabled={disabled}
        onChange={handleChange}
        value={optimisticValue}
        defaultValue={defaultValue}
        disableUnderline={true}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }}
      >
        {options.map((option, i) => {
          return (
            <MenuItem disabled={option.disabled} key={option.id} value={option.value}>
              {option.title}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default BasicSelectorRenderer

const getDefaultPathForSelectTariffsDialog = (pathname: string, projectId: number, isResidential: boolean) => {
  var filters = {
    q: '',
    tariff_dataset: 'default',
    project_id: projectId || 'None',
  }
  return pathname + '?filter=' + encodeURIComponent(JSON.stringify(filters))
}

export default getDefaultPathForSelectTariffsDialog

import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import AuthModalWrapper from './AuthModalWrapper'
import { pathnameAndParamsWithUrlFragment } from './common'
import PasswordResetRequested from './components/reset/PasswordResetRequested'

const PasswordResetRequestedPage = () => {
  const history = useHistory()
  const location = useLocation()

  const onCreateAccountClick = useCallback(() => {
    //@ts-ignore
    history.push(pathnameAndParamsWithUrlFragment('/register', location, location.params?.urlFragment))
  }, [])

  const onSignInClick = useCallback(() => {
    //@ts-ignore
    history.push(pathnameAndParamsWithUrlFragment('/login', location, location.params?.urlFragment))
  }, [])
  return (
    <AuthModalWrapper>
      <PasswordResetRequested onCreateAccount={onCreateAccountClick} onSignIn={onSignInClick} />
    </AuthModalWrapper>
  )
}
export default PasswordResetRequestedPage

import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { useMemo } from 'react'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'

export const useProjectExternalOrg = () => {
  const project = useFormState().values
  const currOrgId = useSelector(authSelectors.getOrgId)
  const connectedOrgs = useSelector(orgSelectors.getConnectedOrgs)
  const externalOrg = useMemo(
    () =>
      currOrgId === project.org_id && !!project.shared_with?.length
        ? project?.shared_with[0]?.org_name
        : connectedOrgs.find((x) => x.org_id === project.org_id)?.org_name,
    [connectedOrgs, project.shared_with, project.org_id, currOrgId]
  )
  return externalOrg?.toString()
}

import { styled } from 'opensolar-ui'
import { FC } from 'react'

const PrioSquare = styled('div')({
  width: 10,
  height: 10,
  marginRight: 5,
  borderRadius: 2,
})

const PRIORITY_CHOICE_COLORS = {
  1: '#005D7A',
  2: '#C47E0A',
  3: '#961616',
}

interface PropTypes {
  priority: number
}

const PrioritySquare: FC<PropTypes> = ({ priority }) => {
  return <PrioSquare style={{ backgroundColor: PRIORITY_CHOICE_COLORS[priority] }} />
}

export default PrioritySquare

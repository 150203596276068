import { FormControlLabel, makeStyles } from '@material-ui/core'
import Tooltip from 'elements/tooltip/Tooltip'
import { Switch } from "opensolar-ui"
import React from 'react'
import { useTranslate } from 'react-admin'
import { ViewType } from 'types/studio/helpers'
import { ViewTypes } from 'types/view'

type PropTypes = {
  view: ViewTypes
}

const useStyles = makeStyles((theme: any) => ({
  switchBase: {
    color: theme.blue,
    '&$checked': {
      color: theme.blue,
    },
    '&$checked + $track': {
      backgroundColor: theme.blue,
    },
  },
  checked: {},
  track: {},
}))

const ShowCustomerToggle: React.FC<PropTypes> = ({ view }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const allViewsCount = window.ViewHelper.views.length
  const viewsForCustomerCount = window.ViewHelper.getCustomerViews().length
  const currentViewIsForCustomer = view.show_customer
  const isDisabled = (allViewsCount === 1 || viewsForCustomerCount === 1) && currentViewIsForCustomer

  return (
    <Tooltip
      title={isDisabled ? translate('At least one view must be visible to the customer.') : undefined}
      enterDelay={300}
      placement="top"
    >
      <FormControlLabel
        control={
          <Switch
            id="show-customer-checkbox"
            classes={classes}
            checked={currentViewIsForCustomer}
            disabled={isDisabled}
            onChange={(e, isChecked) =>
              window.ViewHelper.toggleShowCustomerForView(
                window.ViewHelper.getViewByUuid(view.uuid) as ViewType,
                isChecked
              )
            }
          />
        }
        label={translate('Show Customer')}
      />
    </Tooltip>
  )
}

export default ShowCustomerToggle

import type LineItemType from 'pages/ordering/OrderLineItem'
import ProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/projectOrderPresenter'
import React, { FC, memo } from 'react'
import { useGetMany } from 'react-admin'
import Skeleton from 'react-loading-skeleton'
import type { ProjectOrderDetail } from '../../../type'
import RedirectChip from './RedirectChip'
import SwitchButton from './SwitchButton'
import UnallocatedChip from './UnallocatedChip'

interface PropTypes {
  lineItem: LineItemType
  groupedLineItemsQuantity: number
  ReplaceItem?: React.ReactNode
}

const MIN_QUANTITY_SHOW_SWITCH_BUTTON = 2

const Allocations: FC<PropTypes> = memo(({ lineItem, groupedLineItemsQuantity, ReplaceItem }) => {
  const [viewMore, setViewMore] = React.useState<boolean>(false)
  const projectIds = lineItem.projectOrder?.map((detail) => detail.projectId) || []
  const { data, error, loading, loaded } = useGetMany('projects', projectIds)
  const minRequiredQuantity = lineItem.projectOrder?.reduce((sum, cur) => (sum += cur.requiredQuantity), 0)

  if (lineItem.projectOrder == null) {
    return null
  }

  if (loading) {
    return <Skeleton />
  }

  const stockLevelStatus = ProjectOrderPresenter.getStockLevelStatusFromLineItem(lineItem)
  const isAvailableToOrder = ProjectOrderPresenter.isStockLevelReadyToOrder(stockLevelStatus)
  const projectsToShow = viewMore
    ? lineItem.projectOrder
    : lineItem.projectOrder.slice(0, MIN_QUANTITY_SHOW_SWITCH_BUTTON)

  return (
    <div>
      {projectsToShow.map((detail: ProjectOrderDetail) => {
        const record = data.filter((a) => !!a).find((record) => record.id === detail.projectId)
        if (!record) return null
        return (
          <RedirectChip
            variant={isAvailableToOrder ? 'default' : 'warning'}
            key={detail.projectId}
            loading={loading}
            redirectLink={`/projects/${record.id}/design`}
            label={`${record.title} (${detail.requiredQuantity})`}
          />
        )
      })}
      {minRequiredQuantity != null && groupedLineItemsQuantity > minRequiredQuantity && (
        <UnallocatedChip unallocatedQuantity={groupedLineItemsQuantity - minRequiredQuantity} loading={loading} />
      )}
      {projectIds.length > MIN_QUANTITY_SHOW_SWITCH_BUTTON && (
        <SwitchButton
          viewMore={viewMore}
          setViewMore={setViewMore}
          numberOfHiddenProjects={projectIds.length - MIN_QUANTITY_SHOW_SWITCH_BUTTON}
        />
      )}
      {!isAvailableToOrder && ReplaceItem}
    </div>
  )
})

export default Allocations

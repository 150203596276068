import { addUpdateCssRule } from '../util/misc'

export function getCustomizedStyles(proposalDataColors) {
  var colors = {
    textColor: 'black',
    highlightColor: '#fdd502',
    highlightColorLight: '#fdd502',
    highlightColorInteraction: '#fdd502',
    textColorOnHighlight: 'black',
  }

  Object.keys(colors).forEach((key) => {
    if (proposalDataColors && proposalDataColors[key]) {
      colors[key] = proposalDataColors[key]
    }
  })

  return colors
}

export const updateCssRules = (myeStyles) => {
  addUpdateCssRule('.BillSavingsChart .NewBill .ct-bar', '{ stroke: ' + myeStyles.highlightColor + ' !important; }')

  addUpdateCssRule('.BillSavingsChart .NewBill .ct-bar-label', '{ fill: ' + myeStyles.textColorOnHighlight + '; }')

  addUpdateCssRule('.LifeTimeSavingsGraph .savings .ct-bar', '{ stroke: ' + myeStyles.highlightColor + ' !important; }')

  addUpdateCssRule(
    '.LifeTimeSavingsGraph .net_savings .ct-bar',
    '{ stroke: ' + myeStyles.highlightColor + ' !important; }'
  )

  addUpdateCssRule(
    '.CashFlowChart .CashFlowChartBar .ct-bar',
    '{ stroke: ' + myeStyles.highlightColor + ' !important; }'
  )

  addUpdateCssRule(
    '.EnergyConsumptionChartSolarGeneration .ct-bar',
    '{ stroke: ' + myeStyles.highlightColor + ' !important; }'
  )

  addUpdateCssRule('.legendBillSavings', '{ background-color: ' + myeStyles.highlightColor + ' !important; }')

  addUpdateCssRule('.legendNetSavings', '{ background-color: ' + myeStyles.highlightColor + ' !important; }')

  addUpdateCssRule('.LegendEnergyConsumption', '{ background-color: ' + myeStyles.highlightColor + ' !important; }')

  addUpdateCssRule('.LegendEnergyConsumption', '{ background-color: ' + myeStyles.highlightColor + ' !important; }')
}

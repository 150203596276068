import ErrorIcon from '@material-ui/icons/ErrorOutlineOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import React, { useEffect } from 'react'
import { useTranslate } from 'react-admin'
import { PremiumImageryBlockReasonType } from 'types/global'

const WARNING_MAP = {
  permissions: 'To activate Premium Imagery for this project, please contact an admin.',
  insufficient_funds: 'Your account has insufficient funds to activate premium imagery for this project.',
  unsupported_location: 'No Premium Imagery is available for this site.',
  unsupported_location_3d: 'Only vertical Premium Imagery is available for this site (3D DSM is not available).',
}

type PropTypes = {
  message: PremiumImageryBlockReasonType
}

const PremiumImageryWarning: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  useEffect(() => {
    if (props.message) logAmplitudeEvent('premium_imagery_warning_shown', { message: props.message })
  }, [props.message])

  if (!props.message || !WARNING_MAP[props.message]) return null
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px',
        backgroundColor: 'rgb(255, 241, 230)',
        borderRadius: '4px',
        color: 'rgb(187,89, 41)',
        margin: '5px 0px',
      }}
    >
      <div style={{ marginRight: '5px' }}>
        <ErrorIcon fontSize="small" htmlColor="rgb(187,89, 41)" />
      </div>
      <span>{translate(WARNING_MAP[props.message])}</span>
    </div>
  )
}
export default PremiumImageryWarning

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import { Avatar, Card, makeStyles } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { ThemeProvider } from '@material-ui/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useCheckAuth } from 'ra-core';
import { useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { default as createMuiTheme } from '../createMyTheme';
import defaultTheme from '../defaultTheme';
import Notification from '../layout/Notification';
import DefaultLoginForm from './LoginForm';
var useStyles = makeStyles(function (theme) { return ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        height: '1px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundImage: 'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',
    },
    card: {
        minWidth: 300,
        marginTop: '6em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary[500],
    },
}); }, { name: 'RaLogin' });
/**
 * A standalone login page, to serve as authentication gate to the admin
 *
 * Expects the user to enter a login and a password, which will be checked
 * by the `authProvider.login()` method. Redirects to the root page (/)
 * upon success, otherwise displays an authentication error message.
 *
 * Copy and adapt this component to implement your own login logic
 * (e.g. to authenticate via email or facebook or anything else).
 *
 * @example
 *     import MyLoginPage from './MyLoginPage';
 *     const App = () => (
 *         <Admin loginPage={MyLoginPage} authProvider={authProvider}>
 *             ...
 *        </Admin>
 *     );
 */
var Login = function (props) {
    var theme = props.theme, classesOverride = props.classes, className = props.className, children = props.children, staticContext = props.staticContext, backgroundImage = props.backgroundImage, rest = __rest(props, ["theme", "classes", "className", "children", "staticContext", "backgroundImage"]);
    var containerRef = useRef();
    var classes = useStyles(props);
    var muiTheme = useMemo(function () { return createMuiTheme(theme); }, [theme]);
    var backgroundImageLoaded = false;
    var checkAuth = useCheckAuth();
    var history = useHistory();
    useEffect(function () {
        checkAuth({}, false)
            .then(function () {
            // already authenticated, redirect to the home page
            history.push('/');
        })
            .catch(function () {
            // not authenticated, stay on the login page
        });
    }, [checkAuth, history]);
    var updateBackgroundImage = function () {
        if (!backgroundImageLoaded && containerRef.current) {
            containerRef.current.style.backgroundImage = "url(".concat(backgroundImage, ")");
            backgroundImageLoaded = true;
        }
    };
    // Load background image asynchronously to speed up time to interactive
    var lazyLoadBackgroundImage = function () {
        if (backgroundImage) {
            var img = new Image();
            img.onload = updateBackgroundImage;
            img.src = backgroundImage;
        }
    };
    useEffect(function () {
        if (!backgroundImageLoaded) {
            lazyLoadBackgroundImage();
        }
    });
    return (_jsx(ThemeProvider, __assign({ theme: muiTheme }, { children: _jsxs("div", __assign({ className: classnames(classes.main, className) }, rest, { ref: containerRef }, { children: [_jsxs(Card, __assign({ className: classes.card }, { children: [_jsx("div", __assign({ className: classes.avatar }, { children: _jsx(Avatar, __assign({ className: classes.icon }, { children: _jsx(LockIcon, {}) })) })), children] })), _jsx(Notification, {})] })) })));
};
Login.propTypes = {
    backgroundImage: PropTypes.string,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    theme: PropTypes.object,
    staticContext: PropTypes.object,
};
Login.defaultProps = {
    theme: defaultTheme,
    children: _jsx(DefaultLoginForm, {}),
};
export default Login;

import { Card, CardActions, CardHeader } from '@material-ui/core'
import { ControlBreadCrumbs } from 'elements/BreadCrumbs'
import { Button } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import { Link } from 'react-router-dom'
import { featureDescription } from 'resources/connectedOrgs'

const ConnectedOrgsHome = () => {
  const translate = useTranslate()
  return (
    <>
      <ControlBreadCrumbs style={{ padding: '24px 24px 0 24px' }} currentPage="Teams" />
      <div style={{ padding: '0px 24px 0px 24px' }}>
        <h1
          style={{
            fontSize: 20,
            display: 'inline-block',
            fontWeight: 500,
            height: 68,
            lineHeight: '68px',
            margin: 0,
            color: 'rgba(0, 0, 0, 0.87)',
          }}
        >
          {translate('Teams')}
        </h1>
        <Card style={{ marginTop: 40 }}>
          <CardHeader
            title={<h2>{translate('Connected Orgs')}</h2>}
            subheader={<p>{translate(featureDescription)}</p>}
          />
          <CardActions style={{ padding: '0 0 26px 16px' }}>
            <Link to={'/connected_orgs'} style={{ textDecoration: 'none' }}>
              <Button variant="outlined" color="default" id="manageConnectedOrgsBtn" name="projectSharing">
                <span>{translate('Manage Connected Orgs')}</span>
              </Button>
            </Link>
          </CardActions>
        </Card>
        <Card style={{ marginTop: 40 }}>
          <CardHeader
            title={<h2>{translate('Sharing Permissions')}</h2>}
            subheader={<p>{translate('Permission sets for limiting access to shared projects.')}</p>}
          />
          <CardActions style={{ padding: '0 0 26px 16px' }}>
            <Link to={'/connected_orgs_roles'} style={{ textDecoration: 'none' }}>
              <Button variant="outlined" color="default" id="manageSharePermissionsBtn" name="projectSharing">
                <span>{translate('Manage Sharing Permissions')}</span>
              </Button>
            </Link>
          </CardActions>
        </Card>
      </div>
    </>
  )
}

export default ConnectedOrgsHome

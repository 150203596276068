import React, { memo, useCallback } from 'react'
import { ProjErrorCategory, ProjectErrorType } from 'reducer/project/projectErrorsReducer'
import DesignWarningBoxBasic from '../../../common/DesignWarningBoxBasic'

type PropsTypes = {
  category: ProjErrorCategory
}

export const usePanelGenericWarningFilter = () => {
  return useCallback((error: ProjectErrorType) => {
    // Errors with componentId will be displayed in ComponentWarningBox separately.
    // Avoiding display same error twice inside a panel. See comment: https://github.com/open-solar/sunsuite/pull/7195#discussion_r1518993635
    if (error.componentId) return false
    return true
  }, [])
}

const PanelGenericWarningBox: React.FC<PropsTypes> = ({ category }) => {
  const panelGenericWarningFilter = usePanelGenericWarningFilter()

  return <DesignWarningBoxBasic categories={[category]} extraFilter={panelGenericWarningFilter} />
}

export default memo(PanelGenericWarningBox)

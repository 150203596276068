import { Typography } from '@material-ui/core'
import { useContext } from 'react'
import { useFormState } from 'react-final-form'
import { useFeatureFlag } from 'util/split'
import { OrderContext } from '../..'
import {
  FullWidthWrapper,
  SectionCardBottom,
  SectionCardMiddle,
  SectionCardTop,
} from '../orderActivity/OrderActivitySection'
import OrderInfo from './OrderInfo'

const OrderInfoSection = () => {
  const { orderData } = useContext(OrderContext)
  const project = useFormState().values
  const enableProjectInv = useFeatureFlag('hardware_3_0_projects_tab_inventory', 'on')

  return (
    <FullWidthWrapper>
      <SectionCardTop>
        <Typography variant="h6" gutterBottom>
          {`Track your ${orderData.length > 1 ? 'orders' : 'order'} for ${project.address || project.org_name}`}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Manage your purchase orders for this project
        </Typography>
      </SectionCardTop>
      {orderData
        ?.sort((a, b) => a.order_id - b.order_id)
        .map((order) => (
          <SectionCardMiddle>
            <OrderInfo key={order.id} order={order} />
          </SectionCardMiddle>
        ))}
      {enableProjectInv ? <SectionCardBottom>Inventory Allocation</SectionCardBottom> : <SectionCardBottom />}
    </FullWidthWrapper>
  )
}

export default OrderInfoSection

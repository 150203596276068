import { useEffect, useMemo } from 'react'
import { useFormState } from 'react-final-form'
import { useFeatureFlag } from 'util/split'

export const useStudioExtensions = () => {
  const isLiteProject = !!useFormState().values.is_lite
  const NSFExtensionFlag = useFeatureFlag('non_spatial_facets', 'on')
  const NSFAutoGenerateFlag = useFeatureFlag('non_spatial_facets_auto_create', 'on')
  const studioExtensions = useMemo(() => window.editor.extensions, [])

  useEffect(() => {
    const enableNSFExtension = !isLiteProject && NSFExtensionFlag
    if (enableNSFExtension && !studioExtensions.NonSpatialFacets.isActive) {
      studioExtensions.NonSpatialFacets.activate()
    }

    if (!enableNSFExtension && studioExtensions.NonSpatialFacets.isActive) {
      // note this will reset the flags like `autoGenerate` to default
      // next time the extension is activated, the extension will start with the default
      // make sure to override the default flags after activation if necessary
      studioExtensions.NonSpatialFacets.deactivate()
    }
  }, [NSFExtensionFlag, isLiteProject])

  useEffect(() => {
    if (studioExtensions.NonSpatialFacets.isActive) {
      studioExtensions.NonSpatialFacets.autoGenerate = NSFAutoGenerateFlag || false
    }
  }, [NSFAutoGenerateFlag])
}

import React from 'react'
import { Create, SimpleForm } from 'react-admin'
import { AccessRightsType } from 'types/global'
import CustomFormCRUDFormContent from './CustomFormCRUD'

type PropTypes = {
  accessRights: AccessRightsType
}

export const CustomFormCreate: React.FC<PropTypes> = (props) => {
  return (
    <div>
      <Create resource="custom_forms" {...props} hasList={false}>
        <SimpleForm>
          <CustomFormCRUDFormContent />
        </SimpleForm>
      </Create>
    </div>
  )
}
export default CustomFormCreate

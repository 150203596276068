import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { designViewSelectors, setStudioPortalID } from 'reducer/designer/view'

type Props = { id?: string; zIndex?: number } & React.HTMLAttributes<HTMLDivElement>

export function StudioContainer({ id, zIndex, ...props }: Props) {
  const realId = useMemo(() => {
    return id || 'studio-container-' + Math.random().toString().substring(2, 9)
  }, [id])

  const dispatch = useDispatch()
  const studioPortalID = useSelector(designViewSelectors.studioPortalId)
  useEffect(() => {
    dispatch(setStudioPortalID(realId, zIndex))

    return () => {
      if (studioPortalID === realId) dispatch(setStudioPortalID(undefined))
    }
  }, [])

  return <div id={realId} {...props} />
}

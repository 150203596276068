import BusinessIcon from '@material-ui/icons/BusinessOutlined'
import React, { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useField, useForm } from 'react-final-form'
import AccordionCard from '../AccordionCard'
import AddressAutoCompleteWrapper from './AddressAutoCompleteWrapper'
import AddressForm from './AddressForm'
import ToggleAddressDetail from './AddressToggleLink'

const AddressContent: React.FunctionComponent = () => {
  const form = useForm()
  const formState = form.getState()
  const search_address: any = useField('search_address', { subscription: { value: true } })?.input?.value

  const getHasAddress = () => {
    if (!formState?.values || Object.keys(formState.values).length === 0) return true
    else
      return (
        formState.values.address ||
        formState.values.locality ||
        formState.values.zip ||
        formState.values.state ||
        (formState.values.lat && formState.values.lon)
      )
  }

  const [justSelectedAddress, setJustSelectedAddress] = useState<boolean>(false)
  const [showAutocomplete, setShowAutocomplete] = useState<boolean>(!getHasAddress())
  const [mounted, setMounted] = useState<boolean>(false) // used to prevent search_address hook from having an impact when search_address is first populated. Should only have impact on subsequent updates

  useEffect(() => {
    if (mounted) {
      if (search_address) setShowAutocomplete(true)
      else setShowAutocomplete(false)
    } else {
      setMounted(true)
    }
  }, [search_address])

  return (
    <div style={{ width: '100%' }}>
      {showAutocomplete ? (
        <AddressAutoCompleteWrapper setJustSelectedAddress={() => setJustSelectedAddress(true)} />
      ) : (
        <AddressForm justSelectedAddress={justSelectedAddress} />
      )}
      <ToggleAddressDetail />
    </div>
  )
}

const AddressAccordion: React.FunctionComponent = () => {
  const translate = useTranslate()
  return (
    <AccordionCard
      name={'address'}
      title={translate('Address')}
      titleIcon={BusinessIcon}
      content={AddressContent}
      lazyRender={true}
    />
  )
}
export default AddressAccordion

import { Breadcrumbs } from '@material-ui/core'
import classNames from 'classnames'
import { Box, CheckIcon, getTypographyStyles } from 'opensolar-ui'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles(() => ({
  step: {},
  container: {
    gap: '24px',
  },
  number: {
    borderRadius: '100px',
    fontSize: '14px',
    fontWeight: 500,
    height: '20px',
    lineHeight: '22px',
    padding: '2px',
    width: '20px',
  },
  stepActive: {
    ...getTypographyStyles({ colorHex: '#4F4D55', textVariant: 'subtitle2' }),
  },
  stepInactive: {
    ...getTypographyStyles({ colorHex: '#C9C9CC', textVariant: 'body1' }),
  },
  numberActive: {
    background: '#FFDD52',
    color: '#0A090B',
  },
  numberInactive: {
    background: '#ECECED',
    color: '#C9C9CC',
  },
  separator: {
    color: '#4F4D55',
    margin: '0px',
  },
  stepDone: {
    background: 'linear-gradient(180deg, #66B551 0%, #559744 100%)',
  },
}))

interface BreadcrumbsContainerProps {
  currentStep: number
  steps: [string, string, ...string[]]
}

const BreadcrumbsContainer: React.FC<BreadcrumbsContainerProps> = ({ currentStep, steps }) => {
  const classes = useStyles()

  return (
    <Breadcrumbs classes={{ separator: classes.separator, ol: classes.container }} separator=">">
      {steps.map((step, stepIndex) => {
        const stepNumber = stepIndex + 1
        const isStepDone = stepNumber < currentStep
        const isStepActive = currentStep === stepNumber

        return (
          <Box
            alignItems="center"
            className={classNames([
              classes.step,
              isStepActive || isStepDone ? classes.stepActive : classes.stepInactive,
            ])}
            display="flex"
            gridGap={12}
            key={`${step}-${stepIndex}`}
          >
            <Box
              alignItems="center"
              className={classNames([
                classes.number,
                isStepDone ? classes.stepDone : '',
                isStepActive ? classes.numberActive : classes.numberInactive,
              ])}
              display="inline-flex"
              justifyContent="center"
            >
              {isStepDone ? <CheckIcon fill="white" height="8" width="8" /> : stepNumber}
            </Box>
            {step}
          </Box>
        )
      })}
    </Breadcrumbs>
  )
}

export default BreadcrumbsContainer

import { BomGeneratorAbstract } from '../bom_generators/BomGeneratorAbstract'
import { PartnerAccessoriesAbstract } from '../bom_generators/PartnerAccessoriesAbstract'
import { Edge, PerBlockEdgeBomGeneratorAbstract } from '../bom_generators/PerBlockEdgeBomGeneratorAbstract'
import { PerInnerCornerBomGeneratorAbstract } from '../bom_generators/PerInnerCornerBomGeneratorAbstract'
import { Corner, PerOuterCornerBomGeneratorAbstract } from '../bom_generators/PerOuterCornerBomGeneratorAbstract'
import { Item, MountingCalcResult } from '../types'
import { addItems } from '../utils'

const getCornerKit = (): Item => {
  return { name: 'SSCK', components: [] }
}

class SolaSkirtBomGenerator extends BomGeneratorAbstract {
  async generateBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    const chimneyQty = Number(this.input.options.chimneyQty) || 0

    const resultWithChimneyComponents = addItems(result, Array(chimneyQty).fill({ name: 'SSCHK', components: [] }))

    const resultWithOuterCornerComponents = await this.generateBomFrom(
      SolaSkirtPerOuterCornerBomGenerator,
      resultWithChimneyComponents
    )
    const resultWithInnerCornerComponents = await this.generateBomFrom(
      SolaSkirtPerInnerCornerBomGenerator,
      resultWithOuterCornerComponents
    )

    const resultWithLengthKits = await this.generateBomFrom(
      SolaSkirtPerBlockEdgeBomGenerator,
      resultWithInnerCornerComponents
    )

    let resultWithSpares = addItems(resultWithLengthKits, Array(2).fill({ name: 'SSSLK', components: [] }))

    return resultWithSpares
  }
}

class SolaSkirt extends PartnerAccessoriesAbstract {
  bomGeneratorClass = SolaSkirtBomGenerator
}

class SolaSkirtPerOuterCornerBomGenerator extends PerOuterCornerBomGeneratorAbstract {
  generateBomForTopRightCorner(corner: Corner): Item[] {
    return [getCornerKit()]
  }
  generateBomForTopLeftCorner(corner: Corner): Item[] {
    return [getCornerKit()]
  }
  generateBomForBottomRightCorner(corner: Corner): Item[] {
    return [getCornerKit()]
  }
  generateBomForBottomLeftCorner(corner: Corner): Item[] {
    return [getCornerKit()]
  }
}

class SolaSkirtPerInnerCornerBomGenerator extends PerInnerCornerBomGeneratorAbstract {
  generateBomForInnerCorners(innerCornerQty: number): Item[] {
    return Array(innerCornerQty).fill(getCornerKit())
  }
}

class SolaSkirtPerBlockEdgeBomGenerator extends PerBlockEdgeBomGeneratorAbstract {
  async generateBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    const edges = [...this.getTopEdges(), ...this.getBottomEdges(), ...this.getLeftEdges(), ...this.getRightEdges()]
    const perimeter = this.getEdgesLength(edges) / 1000 // convert to meters

    const cornerKitQty = result.items.filter((item) => item.name === 'SSCK').length
    const chimneyKitQty = result.items.filter((item) => item.name === 'SSCHK').length

    const lengthKitQty = Math.ceil((perimeter - cornerKitQty) / 1.2 - chimneyKitQty)

    if (lengthKitQty > 0) {
      return addItems(
        result,
        Array(lengthKitQty).fill({
          name: 'SSSLK',
          components: [],
        })
      )
    } else return result
  }

  getEdgesLength(edges: Edge[]): number {
    return edges.reduce((totalLength, edge) => totalLength + edge.length, 0)
  }
}

export default SolaSkirt

// @ts-nocheck
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { hideFullScreenLoader, markAsSold, markCreditAppAsOpened, myEnergySelectors } from 'ducks/myEnergy'
import LoadingDots from 'layout/widgets/LoadingDots'
import React, { useEffect, useState } from 'react'
import { useNotify } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'
import { PaymentOptionType } from 'types/paymentOptions'
import { ProjectType } from 'types/projects'
import { getCommonCreditDecision } from 'util/misc'
import { TransactionActionButton } from '../../../elements/TransactionStartButton'
import { removeCreditAppParams } from '../util'
import BrighteApplicationDialog from './BrighteApplicationDialog'

var restClientInstance = restClient(window.API_ROOT + '/api')
const APPROVED_STATUSES = ['Approved', 'Conditionally approved']
const DECISIONS = ['Approved', 'Conditionally approved', 'Pending decision', 'Declined']

type PropTypes = {
  actionData: any
  translate: (raw: string) => string
  showDialog: boolean
  setWaitingForAction: Function
  myeStyles: CSSProperties
  acceptButtonStyle: CSSProperties
  acceptButtonStyleDisabled: CSSProperties
  isPro: boolean
  selectedPaymentOptionName: string
  paymentOptionData: PaymentOptionType
  selectedProject: ProjectType
  onDecisionRendered: () => void
  loanpalDecisionRendered: boolean
  selectedPaymentOptionId: number
}
const LoanpalApplicationButton: React.FunctionComponent<PropTypes> = (props) => {
  const { actionData, translate, myeStyles, acceptButtonStyle, acceptButtonStyleDisabled, paymentOptionData } = props
  const [showApplicationDialog, setShowApplicationDialog] = useState<boolean>(false)
  const [url, setUrl] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const [startedWithoutDecision, setStartedWithoutDecision] = useState<boolean>(
    !actionData.status_title || actionData.status_title === 'creted' || actionData.status_title === 'Submitted'
  )
  const [decisionJustGiven, setDecisionJustGiven] = useState<string | undefined>(undefined)
  const [isFullWidth, setIsFullWidth] = useState<boolean>(true)

  const dispatch = useDispatch()
  const forceOpenCreditApp = useSelector(myEnergySelectors.getShouldForceOpenCreditApp)

  useEffect(() => {
    if (forceOpenCreditApp && props.selectedPaymentOptionId === paymentOptionData?.id) {
      openDialog()
      removeCreditAppParams()
      setIsFullWidth(true)
    }
  }, [forceOpenCreditApp, props.selectedPaymentOptionId])

  useEffect(() => {
    if (startedWithoutDecision && actionData.status_title && actionData.status_title !== 'created') {
      if (APPROVED_STATUSES.includes(actionData.status_title)) {
        dispatch(markAsSold(actionData.system_uuid, actionData.payment_option_id))
      }
      if (DECISIONS.includes(actionData.status_title) && decisionJustGiven !== actionData.status_title) {
        logAmplitudeEvent('integrated_finance_application_submitted', {
          integration: 'brighte',
          decision: getCommonCreditDecision(actionData.status_title),
          project_id: actionData.project_id,
          payment_option_id: actionData.payment_option_id,
        })
        setDecisionJustGiven(actionData.status_title)
      }
    }
  }, [actionData.status_title, startedWithoutDecision, decisionJustGiven])

  const notify = useNotify()

  let buttonLabelText = translate('Apply Now!')

  let extra_actions = []
  const orgId = props.paymentOptionData?.org_id ? props.paymentOptionData?.org_id : actionData.org_id

  if (actionData.status_code === 'available') {
    extra_actions.push(
      <p key={extra_actions.length} className="small">
        Clicking "Apply Now!" will take you to Brighte's credit application. OpenSolar does not view or store any of the
        data you enter in this application.
      </p>
    )
  }

  if (actionData.status_description) {
    // Other statuses where we simply print the status_description
    // Including: OtherOrUnknown
    if (['Approved', 'Conditionally approved'].includes(actionData.status_title)) {
      extra_actions.push(
        <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <CheckCircleIcon htmlColor="rgba(55, 169, 46, 0.9)" style={{ marginRight: '5px' }} fontSize="small" />{' '}
          <span style={{ fontWeight: 900, fontSize: '16px' }}>{actionData.status_title}</span>
        </div>
      )
    }
    extra_actions.push(<p key={extra_actions.length}>{actionData.status_description}</p>)
  }

  if (actionData.status_code === 'complete') {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
          {APPROVED_STATUSES.includes(actionData.status_title) && (
            <CheckCircleIcon htmlColor="rgba(55, 169, 46, 0.9)" style={{ marginRight: '5px' }} />
          )}
          <h2>{actionData.status_title === 'created' ? 'Pending decision' : actionData.status_title}</h2>
        </div>
        <div>{actionData.status_description}</div>
      </div>
    )
  }

  const openDialog = (e?: MouseEvent) => {
    if (actionData.status_code === 'available') {
      setLoading(true)
      restClientInstance('CUSTOM_GET', 'custom', {
        url:
          'orgs/' +
          orgId +
          '/projects/' +
          actionData.project_id +
          '/systems/' +
          actionData.system_uuid +
          '/' +
          paymentOptionData.id +
          '/brighte/application_url/',
      })
        .then(
          (response: any) => {
            if (response?.data?.success) {
              setShowApplicationDialog(true)
              setUrl(response.data.url)
              logAmplitudeEvent('integrated_finance_application_opened', { integration: 'brighte' })
            }
          },
          (reject: any) => {
            let message: string = 'Something went wrong and we are unable to create this Brighte application.'
            if (reject.message) {
              message = reject.message
            }
            notify(message, 'warning')

            logAmplitudeEvent('integrated_finance_application_open_error', { integration: 'brighte' })
          }
        )
        .catch((err: any) => {
          console.log('err', err.body)
          if (err?.body?.message) {
            notify(err.body.message, 'warning')
            logAmplitudeEvent('integrated_finance_application_open_error', { integration: 'brighte' })
          }
        })
        .finally(() => {
          setLoading(false)
          dispatch(hideFullScreenLoader())
          dispatch(markCreditAppAsOpened())
        })
    }
  }

  const closeDialog = () => {
    setShowApplicationDialog(false)
    setUrl(undefined)
  }

  return (
    <>
      <TransactionActionButton
        label={
          <div>
            {loading ? (
              <LoadingDots color="#fff" />
            ) : (
              <>
                {buttonLabelText}
                <div style={{ fontSize: 11 }}>(Takes less than 5 minutes)</div>
              </>
            )}
          </div>
        }
        disabled={actionData.status_code !== 'available' || props.loanpalDecisionRendered || loading}
        myeStyles={myeStyles}
        labelStyle={{ padding: 0 }}
        buttonStyle={{
          height: 39,
          ...(actionData.status_code === 'available' && !props.loanpalDecisionRendered
            ? acceptButtonStyle
            : acceptButtonStyleDisabled),
        }}
        action={openDialog}
        extra_actions={extra_actions}
      />
      {url && showApplicationDialog && (
        <BrighteApplicationDialog
          onClose={closeDialog}
          isOpen={showApplicationDialog}
          url={url}
          isFullWidth={isFullWidth}
        />
      )}
    </>
  )
}
export default LoanpalApplicationButton

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { default as Mui4ButtonGroup } from '@material-ui/core/ButtonGroup';
import { default as Mui5ButtonGroup } from '@mui/material/ButtonGroup';
import { forwardRef } from 'react';
import { useComponentVersion } from '../hooks';
import { OsButtonVersion } from './Button';
var V3_BUTTON_COLORS = ['info', 'error', 'warning', 'success'];
export var ButtonGroup = forwardRef(function (props, ref) {
    var version = useComponentVersion('button');
    switch (version) {
        case OsButtonVersion.V3:
            return _jsx(Mui5ButtonGroup, __assign({ ref: ref }, props));
        default:
            var mui4Props = __assign(__assign({}, props), { color: !props.color || V3_BUTTON_COLORS.includes(props.color)
                    ? 'secondary'
                    : props.color });
            return _jsx(Mui4ButtonGroup, __assign({ ref: ref }, mui4Props));
    }
});

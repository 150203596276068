import { Dialog, DialogActions, DialogContent, DialogTitle, useTheme } from '@material-ui/core'
import GenericButton from 'elements/button/GenericButton.js'
import { getPvsellAccessCredentialsFromProposalData } from 'myenergy/mainContent/components/IframeWithPost.js'
import { Button } from 'opensolar-ui'
import { translate } from 'ra-core'
import React from 'react'
import { useSelector } from 'react-redux'
import PVSellTemplateTitle from 'resources/proposalTemplates/PVSellTemplateTitle'
import restClient from 'restClient'
import { getRoleFromState } from 'util/misc'

const pvsellDomain = window.SUNWIZ_API_DOMAIN

// const getProposalWithId = (proposals, selected_proposal_id) =>
//   proposals.filter(
//     (item) => !selected_proposal_id || item.pdf_url.indexOf('=' + selected_proposal_id + '&project_id=') !== -1
//   )[0]

export const ajaxPost = (url, data, handlers) => {
  window.$.ajax({
    type: 'POST',
    url: url,
    // xhrFields: {
    //   withCredentials: true,
    // },
    crossDomain: true,
    data: data,
    success: handlers.success,
    error: handlers.error,
  })
}

export const listProposals = (project_id, api_key_pvsell, handlers) => {
  window.$.ajax({
    type: 'GET',
    url:
      pvsellDomain +
      '/standalone/project/list-proposals?project_id=' +
      project_id +
      '&access-token=' +
      api_key_pvsell +
      '&per-page=1000',
    xhrFields: {
      withCredentials: true,
    },
    crossDomain: true,
    success: handlers.success,
    error: handlers.error,
  })
}

const EditPvsellProposal = (api_key_pvsell, pvsell_selected_proposal_id) => {
  window.open(
    `${window.SUNWIZ_API_DOMAIN}/standalone/proposal-page/update?id=${pvsell_selected_proposal_id}&access-token=${api_key_pvsell}`,
    '_blank'
  )
}

export const GenerateAndDownloadPvsellProposal = (project_id, pvsell_selected_proposal_id, onComplete, format) => {
  // fetch the data from myEnergy then POST it to Pvsell.

  if (format !== 'pdf') {
    console.error('Format must be specified as pdf')
    return
  }

  // Get OpenSolar Proposal Data
  restClient(window.API_ROOT + '/api')('CUSTOM_GET', 'custom', {
    url: 'user_logins/?project_ids=' + project_id,
  }).then((response) => {
    var proposalData = response.data

    // Create Proposal on PVSell
    // Unable to use restClient due to problems with OPTIONS requsest returning HTTP 405
    // Use jQuery instead
    // restClient(pvsellDomain)('CUSTOM_POST', 'custom', {
    //   url: pvsellPostPath,
    // }).then(response => {
    //   console.log(response.data)
    //
    //   // var createdProposalData = response.data[0].projects[0].proposal_data
    //   // Trigger PDF Generation on PVSell
    //   onComplete()
    // })

    const { pvsellQuerystringParamName, pvsellQuerystringValue } = getPvsellAccessCredentialsFromProposalData(
      proposalData
    )

    var pvsellPostUrl =
      pvsellDomain +
      '/standalone/canvas/save-project?project_id=' +
      project_id +
      '&' +
      pvsellQuerystringParamName +
      '=' +
      pvsellQuerystringValue

    ajaxPost(
      pvsellPostUrl,
      {
        project_id: project_id,
        data: JSON.stringify(proposalData),
      },
      {
        success: function (data) {
          console.log(data)

          var pdfGeneratorEndpoint =
            pvsellDomain +
            '/standalone/canvas/download-pdf?id=' +
            pvsell_selected_proposal_id +
            '&project_id=' +
            project_id +
            '&' +
            pvsellQuerystringParamName +
            '=' +
            pvsellQuerystringValue

          window.open(pdfGeneratorEndpoint, '_self')

          onComplete()
        },
      }
    )
  })
}

const PvsellProposalDialog = ({ isOpen, setOpen, api_key_pvsell, project_id, pvsell_selected_proposal_id }) => {
  const isAdmin = useSelector((state) => getRoleFromState(state)?.is_admin === true)
  const theme = useTheme()
  return (
    <Dialog open={Boolean(isOpen)}>
      <DialogTitle>Generate SunWiz Proposal</DialogTitle>
      <DialogContent>
        <p>Proposal data will be sent to SunWiz for proposal generation.</p>
        <div>
          <span style={{ verticalAlign: 'middle', marginRight: 5 }}>SunWiz Proposal Template:</span>
          <PVSellTemplateTitle pvprosell_template_id={pvsell_selected_proposal_id} api_key_pvsell={api_key_pvsell} />
        </div>
      </DialogContent>
      <DialogActions style={{ flexWrap: 'wrap' }}>
        <Button style={{ margin: 5 }} variant="contained" color="default" onClick={() => setOpen(false)}>
          <span>{translate('Cancel')}</span>
        </Button>
        {isAdmin && (
          <GenericButton
            labelColor="#000000"
            wrapperStyle={{ margin: 5 }}
            backgroundColor={theme.themeColor}
            backgroundColorOnHover={theme.themeColorDark}
            onClick={() => EditPvsellProposal(api_key_pvsell, pvsell_selected_proposal_id, project_id)}
            label={'Edit Template'}
          />
        )}
        <GenericButton
          labelColor="#FFFFFF"
          backgroundColor="#42C310"
          backgroundColorOnHover="#42C310"
          style={{ padding: 5 }}
          wrapperStyle={{ margin: 5 }}
          onClick={() =>
            GenerateAndDownloadPvsellProposal(project_id, pvsell_selected_proposal_id, () => setOpen(false), 'pdf')
          }
          label={'Generate & Download PDF'}
        />
      </DialogActions>
    </Dialog>
  )
}

export default PvsellProposalDialog

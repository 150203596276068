import { useCallback, useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { useTracking } from '../contexts/eventsTracking/TrackingContext'
import { usePublicFeatureConfig } from './usePublicFeatureConfig'
import useUiKey from './useUiKey'

interface UseTrackComponentParams {
  eventKey: string
  props?: Record<string, any>
  state?: Record<string, any>
}

const useTrackComponent = ({ eventKey, props = {}, state = {} }: UseTrackComponentParams) => {
  const logEvent = useTracking()

  const ref = useRef<HTMLElement | null>(null)
  const uiKey = useUiKey(ref)

  const featureConfig = usePublicFeatureConfig('event_config')

  const trackInteraction = useCallback(
    (interactionType: string, additionalData = {}) => {
      logEvent({
        eventKey,
        eventType: interactionType,
        additionalData: {
          uiKey,
          ...additionalData,
        },
        eventConfig: featureConfig?.[eventKey],
      })
    },
    [logEvent, eventKey, uiKey, featureConfig]
  )

  const previousValues = useRef({ ...props, ...state })

  useEffect(() => {
    const combinedValues = { ...props, ...state }

    Object.keys(combinedValues).forEach((key) => {
      if (combinedValues[key] !== previousValues.current[key]) {
        trackInteraction('property_change', {
          key,
          oldValue: previousValues.current[key],
          newValue: combinedValues[key],
        })
      }
    })

    previousValues.current = combinedValues
  }, [props, state, trackInteraction])

  const { ref: inViewRef } = useInView({
    triggerOnce: true,
    threshold: 0.5,
    onChange: (inView) => {
      if (inView) {
        trackInteraction('view')
      }
    },
  })

  const combinedRef = (node: HTMLElement | null) => {
    ref.current = node
    if (inViewRef) inViewRef(node)
  }

  return { trackInteraction, ref: combinedRef }
}

export default useTrackComponent

import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@material-ui/core'
import { memo, useContext } from 'react'
import { useCheckoutStyles } from '../styles'
import { CheckoutPresenterContext, PaymentMethodContext } from './CityPlumbingCheckoutProvider'
import { PaymentMethodType } from './types'

type PaymentMethodOptionType = {
  label: string
  value: PaymentMethodType
}

const PaymentMethod = () => {
  const checkoutClasses = useCheckoutStyles()
  const paymentMethodList: PaymentMethodOptionType[] = [
    {
      label: 'Credit Card',
      value: 'credit_card',
    },
    {
      label: 'Trade Credit',
      value: 'trade_credit',
    },
  ]
  const checkoutPresenter = useContext(CheckoutPresenterContext)
  const paymentMethod = useContext(PaymentMethodContext)

  const handleUpdatePaymentMethod = (event: React.ChangeEvent<HTMLInputElement>) => {
    checkoutPresenter?.updatePaymentMethod(event.target.value as PaymentMethodType)
  }

  return (
    <Grid container direction="row" style={{ padding: '20px 0px' }}>
      <Grid item xs={3}>
        <Typography variant="h6" className={`${checkoutClasses.font18} ${checkoutClasses.fontWeightMedium}`}>
          <span style={{ marginRight: '20px' }}>3</span>
          <span>Payment Option</span>
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Typography className={`${checkoutClasses.font14}`} style={{ color: '#7F7D83' }}>
              Please note that you will not be submitting any payment details in this checkout. This is done separately
              by the City Plumbing team. More information will be provided in the next page.
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '17px' }}>
            <RadioGroup
              name="paymentMethod"
              value={paymentMethod}
              onChange={handleUpdatePaymentMethod}
              style={{ gap: '10px' }}
            >
              {paymentMethodList.map((item: PaymentMethodOptionType) => (
                <FormControlLabel
                  key={`payment-method-${item.value}`}
                  value={item.value}
                  control={<Radio className={checkoutClasses.radio} />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default memo(PaymentMethod)

export type IronRidgeConfigType = {
  tenant: string
  clientId: string
}

const QUERY_PARAMS = {
  nonce: 'defaultNonce',
  scope: 'openid',
  response_type: 'id_token',
  affiliate: 'open_solar',
}

// Following the pattern in segen autheConfig, so one day we can combine them
const getB2cPolicies = (tenant) => {
  return {
    names: {
      signUpSignIn: 'B2C_1A_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
    },
    authority: `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_SEAMLESSMIGRATION_SIGNUPORSIGNIN`,
    authorityDomain: `${tenant}.b2clogin.com`, // Not used
  }
}

// Following the pattern in segen autheConfig, so one day we can combine them
const getIronRidgeMsalConfig = (tenant, clientId) => {
  const b2cPolicies = getB2cPolicies(tenant)

  return {
    auth: {
      clientId: clientId, // This is the ONLY mandatory field that you need to supply.
      authority: b2cPolicies.authority, // Use a sign-up/sign-in user-flow as a default authority
      knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
      redirectUri: encodeURIComponent(`${window.location.origin}/login_with_ironridge.html`), // You must register this URI on enstall platform.
    },
  }
}

export const getIronRidgeSsoLink = ({ tenant, clientId }) => {
  const msalInstance = getIronRidgeMsalConfig(tenant, clientId)
  let ssoLink = `${msalInstance.auth.authority}&client_id=${msalInstance.auth.clientId}&redirect_uri=${msalInstance.auth.redirectUri}`
  for (const [key, value] of Object.entries(QUERY_PARAMS)) {
    ssoLink += `&${key}=${value}`
  }

  return ssoLink
}

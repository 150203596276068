// @ts-nocheck
import restClient from 'restClient'
import { parseIntegrationJson } from 'util/misc'
import { IronRidgeRefreshRequestType } from '../../../types/integrations/ironRidge'
import { getDesignString } from './IronRidgeDialog'

//@ts-ignore
const API_URL = window.API_ROOT + '/api'

export const refreshFromIronRidge = () => {
  let irProjectIds: string[] = []
  if (!window.editor?.selectedSystem?.integration_json) return
  else {
    try {
      let intJSON = parseIntegrationJson(window.editor?.selectedSystem?.integration_json)
      if (intJSON) {
        if (intJSON.ironridge?.project?.id) irProjectIds.push(intJSON.ironridge?.project?.id)
        intJSON.ironridge?.project?.roofSections?.forEach((roof) => {
          if (roof.multi_slope_project_id && !irProjectIds?.includes(roof.multi_slope_project_id)) {
            irProjectIds.push(roof.multi_slope_project_id)
          }
        })
      }
    } catch (ex) {
      console.log('error parsing IR data', ex)
    }
  }
  if (!irProjectIds || irProjectIds.length === 0) return
  return new Promise((resolve, reject) => {
    let requestBody: IronRidgeRefreshRequestType = {
      project: {
        ironridge_project_ids: irProjectIds,
      },
    }
    const restClientInstance = restClient(API_URL)
    restClientInstance('CUSTOM_POST', 'custom', {
      url:
        'orgs/' +
        //@ts-ignore
        WorkspaceHelper.project.org_id +
        '/projects/' +
        //@ts-ignore
        WorkspaceHelper.params.id +
        '/systems/' +
        //@ts-ignore
        editor.selectedSystem.uuid +
        '/ironridge/refresh',
      data: requestBody,
    })
      .then((response: any) => {
        console.log('response from refresh', response)
        const refreshedData = response.data.saved_project
        let currentJson = window.editor?.selectedSystem?.integration_json
          ? parseIntegrationJson(window.editor.selectedSystem?.integration_json)
          : undefined
        let newJSON = currentJson ? { ...currentJson } : {}
        // only add the preoject member to make sure we don't overwrite the component_overrides that is set separately on the client
        newJSON['ironridge']['project'] = refreshedData['project']
        const asString = JSON.stringify(newJSON)
        window.editor.selectedSystem.integration_json = asString
        resolve(newJSON)
      })
      .catch((err: any) => {
        console.log('err', err)
        if (err?.body?.message) reject(err.body.message)
        else reject(null)
      })
  })
}

export const getShouldRefreshBOM = () => {
  let hasChanges = false
  window.editor.getSystems()?.forEach((sys, i) => {
    if (!!sys?.integration_json) {
      let oldIRData = parseIntegrationJson(sys.integration_json)?.ironridge?.project
      if (oldIRData) {
        let newData = getCurrentData(i)
        let oldDesignAndPortairArr: any = []
        oldIRData?.roofSections?.forEach((section, i) => {
          if (
            section.roofSlope &&
            newData?.roofSlopes?.length > i &&
            Math.abs(section.roofSlope - newData?.roofSlopes[i]) > 2
          ) {
            hasChanges = true
            console.log('slope change', section.roofSlope)
            console.log('new', newData?.roofSlopes[i])
          }
          if (section?.layout?.design)
            oldDesignAndPortairArr.push({ design: section.layout.design, isPortrait: section.portrait })
        })

        if (hasChanges) return hasChanges
        else {
          for (let i = 0; i < Math.max(oldDesignAndPortairArr?.length, newData.designAndPortraitArr?.length); i++) {
            let old = oldDesignAndPortairArr.length >= i - 1 ? oldDesignAndPortairArr[i] : undefined
            let current = newData.designAndPortraitArr.length >= i - 1 ? newData.designAndPortraitArr[i] : undefined
            if (old && current) {
              if (old.design !== current.design || old.isPortrait !== current.isPortrait) {
                hasChanges = true
                console.log('design diff')
              }
            } else {
              hasChanges = true
              console.log('array count mismatch')
            }
          }
        }
      }
    }
  })
  return hasChanges
}

const getCurrentData = (systemIndex: number) => {
  let roofSlope = undefined
  let designAndPortraitArr = []
  let roofSlopes: number[] = []
  let systems = window.editor.getSystems()?.filter((sys, i) => i === systemIndex)
  if (systems?.length > 0) {
    let system = systems[0]
    system.children
      .filter((child) => child.name === 'OsModuleGrid')
      .forEach((panelArray) => {
        let [minX = 0, minY = 0, maxX = 0, maxY = 0] = panelArray?.getBounds()
        let isPortrait = true
        if (panelArray?.userData?.moduleLayout === 'portrait') {
          isPortrait = true
        } else isPortrait = false

        let design = getDesignString(panelArray.cellsActive, minX, maxX, minY, maxY)

        designAndPortraitArr.push({ design, isPortrait })

        // set roof slope if found
        if (panelArray?.userData?.slope !== undefined) {
          roofSlopes.push(panelArray.userData.slope)
        }
      })

    return { roofSlopes, designAndPortraitArr }
  }
  return undefined
}

export const refreshIronRidgeAndGetHasDiff = () => {
  return new Promise((resolve, reject) => {
    const localIRData = window.WorkspaceHelper.project.ironridge
    refreshFromIronRidge()
      .then((freshIRData) => {
        if (freshIRData) {
          const hasMismatch = JSON.stringify(localIRData) !== JSON.stringify(freshIRData)
          resolve(hasMismatch)
        } else resolve(true)
      })
      .catch((err) => {
        console.log('error assessing IronRidge difference', err)
        reject(false)
      })
  })
}

export const queryPanels = (orgId: number, moduleId: number) => {
  return new Promise((resolve: (found: boolean) => void, reject: (found: boolean) => void) => {
    const restClientInstance = restClient(API_URL)
    restClientInstance('CUSTOM_GET', 'custom', {
      url: 'orgs/' + orgId + '/ironridge/panel_match?module_id=' + moduleId,
    })
      .then((response: any) => {
        console.log('response from panels', response)
        resolve(response?.data?.match_found)
      })
      .catch((err: any) => {
        reject(false)
        console.log('error from panels', err)
      })
  })
}

import { Theme, makeStyles, useMediaQuery } from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'
import { OpenSolarThemeType } from 'Themes'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import {ACHPaymentDataType, BECSPaymentDataType, BlueSnapCardDataType} from './types'

type PropTypes = {
  orgName?: string
  contact?: string
  savedPaymentMethodData: BlueSnapCardDataType | ACHPaymentDataType | BECSPaymentDataType | undefined
}

const useStyles = makeStyles<OpenSolarThemeType, { isMobile: boolean }>((theme) => ({
  container: {
    border: '1px solid #CDCDCD',
    borderRadius: '10px',
    padding: '0 !important',
  },

  topContainer: {
    textAlign: 'center',
  },

  icon: {
    color: '#019939',
    fontSize: '4rem',
    paddingTop: '2rem',
  },

  header: {
    fontSize: '20px',
    fontWeight: 500,
    margin: 0,
    padding: '1rem 0 2rem',
    borderBottom: '1px solid #CDCDCD',
  },

  bottomContainer: {
    margin: '1rem',
    lineHeight: '1.5',
  },

  bottomHeader: {
    fontSize: '18px',
    fontWeight: 500,
  },
}))

const PaymentSuccesful: React.FC<PropTypes> = ({ contact, orgName, savedPaymentMethodData }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const classes = useStyles({ isMobile })
  const translate = useTranslate()

  const isACH = useMemo(() => {
    // @ts-ignore
    return Boolean(savedPaymentMethodData?.signature_data)
  }, [savedPaymentMethodData])

  return (
    <div className={classes.container}>
      <div className={classes.topContainer}>
        <CheckCircle className={classes.icon} />
        <h1 className={classes.header}>{isACH ? translate('Payment Authorized') : translate('Payment Complete')}</h1>
      </div>

      <div className={classes.bottomContainer}>
        <h2 className={classes.bottomHeader}>{translate('Thank you %{contact}!', { contact })}</h2>
        {isACH ? (
          <>
            <p>{translate('A copy of your payment authorization will been sent to the email address on file.')}</p>
            <p>{translate('%{orgName} will follow up with next steps for your project.', { orgName })}</p>
          </>
        ) : (
          <>
            <p>{translate('A copy of your payment receipt will been sent to the email address on file.')}</p>
            <p>{translate('We will follow up with next steps for your project.')}</p>
            <p>{translate('- %{orgName} Team', { orgName })}</p>
          </>
        )}
      </div>
    </div>
  )
}
export default PaymentSuccesful

import { projectMilestonesSelectors } from 'ducks/projectMilestones'
import { useTranslate } from 'ra-core'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { AuthUserType } from 'types/auth'
import { BaseProjectType } from 'types/projects'
import { CREDIT_CARD_METHODS, DEBIT_CARD_METHODS } from './constants'
import { getOpenSolarCCType } from './creditCards/utils'
import {
  ACHPaymentDataType,
  BECSPaymentDataType,
  BlueSnapCardDataType,
  BlueSnapTokenResponse,
  PaymentExtraFields,
  PaymentMethodType,
  PaymentRequestAndProjectDataType,
  PaymentRequestArgsType,
  PaymentRequestResponseType,
  PricingSummaryResponse,
  PricingSummaryType,
  SelectedPaymentMethodType,
  SubmitPaymentResponseType,
} from './types'

const restClientInstance = restClient(window.API_ROOT + '/api')
const GENERIC_INVALID_LINK_ERROR =
  'This link is invalid. Please work with your sales respresentative to get a new payment link.'

const SDK_LOAD_ERROR =
  'We are unable to load this payment form at the moment. Please refresh the page and try again. If that does not work please contact your sales representative'

const GENERIC_SUBMIT_ERROR =
  'We were unable to process this payment, please ask your sales representative for next steps'

export const useGetPaymentRequestData = (
  paymentRequestArgs: PaymentRequestArgsType,
  user: AuthUserType | undefined
) => {
  const [paymentRequestData, setPaymentRequestData] = useState<PaymentRequestAndProjectDataType | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)

  const translate = useTranslate()

  useEffect(() => {
    if (user) {
      if (!paymentRequestArgs || !paymentRequestArgs.paymentRequestId || !paymentRequestArgs.projectId) {
        setErrorMsg(translate(GENERIC_INVALID_LINK_ERROR))
      } else if (!paymentRequestData && !isLoading) {
        setIsLoading(true)
        restClientInstance('CUSTOM_GET', 'custom', {
          url: `projects/${paymentRequestArgs.projectId}/payments/requests/${paymentRequestArgs.paymentRequestId}/`,
        })
          .then((paymentRequestResponse: PaymentRequestResponseType) => {
            setPaymentRequestData(paymentRequestResponse.data)
          })
          .catch((error) => {
            if (error?.body?.message) {
              setErrorMsg(error.body.message)
            } else {
              setErrorMsg(translate(GENERIC_INVALID_LINK_ERROR))
            }
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    }
  }, [paymentRequestArgs, user])

  return { isLoading, paymentRequestData, errorMsg }
}

export const useLoadBlueSnapPaymentsSDK = () => {
  const [blueSnapIsLoaded, setBlueSnapIsLoaded] = useState<boolean>(false)
  const [loadError, setLoadError] = useState<string | undefined>(undefined)

  const translate = useTranslate()

  useEffect(() => {
    const script = document.createElement('script')
    // DO NOT CHANGE SRC WITHOUT CHANGING THE INTEGRITY HASH
    script.src = 'https://sandpay.bluesnap.com/web-sdk/5.2.11/bluesnap.js'
    script.integrity = 'sha384-LmBOXEb3oelMNGGNElgsj3DesxyMymiqc4fNxgk+iXMX/AlNdUk+KfhtJSsi6bNo'
    script.crossOrigin = 'anonymous'
    script.onload = (e) => {
      setBlueSnapIsLoaded(true)
      setLoadError(undefined)
    }
    script.onerror = (err) => {
      console.error('Error loading BlueSnap SDK, possibly a subresource integrity error')
      setLoadError(translate(SDK_LOAD_ERROR))
    }
    document.head.appendChild(script)
  }, [])

  return { blueSnapIsLoaded, loadError }
}

export const useGetBlueSnapToken = (projectId: string, paymentRequestId: number, orgId: undefined | number) => {
  const [token, setToken] = useState<string | undefined>(undefined)
  const [isFetchingToken, setIsFetchingToken] = useState<boolean>(false)
  const [tokenErrorMsg, setTokenErrorMsg] = useState<string | undefined>(undefined)

  const translate = useTranslate()
  const refreshTokenTrigger = useSelector(projectMilestonesSelectors.getSDKTokenRefreshTrigger)

  // only run once we have an org id and user, the other dependencies are all passed in from the URL so a change should cause a re-render
  useEffect(() => {
    if (orgId) {
      if (!projectId || !paymentRequestId) {
        setTokenErrorMsg(translate(GENERIC_INVALID_LINK_ERROR))
      } else if (!isFetchingToken && (!token || refreshTokenTrigger)) {
        if (!refreshTokenTrigger) setIsFetchingToken(true)
        restClientInstance('CUSTOM_GET', 'custom', {
          url: `orgs/${orgId}/projects/${projectId}/payments/requests/${paymentRequestId}/token/`,
        })
          .then((paymentRequestResponse: BlueSnapTokenResponse) => {
            setToken(paymentRequestResponse.data.token)
          })
          .catch((error) => {
            if (error?.body?.message) {
              setTokenErrorMsg(error.body.message)
            } else {
              setTokenErrorMsg(translate(GENERIC_INVALID_LINK_ERROR))
            }
          })
          .finally(() => {
            setIsFetchingToken(false)
          })
      }
    }
  }, [orgId, refreshTokenTrigger])

  return { isFetchingToken, token, tokenErrorMsg }
}

export const submitPayment = (
  paymentRequestArgs: PaymentRequestArgsType,
  extraFields: PaymentExtraFields,
  token: string | null,
  orgId: number | undefined,
  paymentAmountWithSurcharge: number
) => {
  return new Promise((resolve: (response: SubmitPaymentResponseType) => void, reject: (err: string) => void) => {
    if (!orgId) {
      reject(GENERIC_SUBMIT_ERROR)
    }
    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${orgId}/projects/${paymentRequestArgs.projectId}/payments/requests/${paymentRequestArgs.paymentRequestId}/submit/`,
      data: { token, ...extraFields, payment_amount_with_surcharge: paymentAmountWithSurcharge },
    })
      .then((res: SubmitPaymentResponseType) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err?.body?.message || GENERIC_SUBMIT_ERROR)
      })
  })
}

export const getIsCardType = (pmtMethod: PaymentMethodType) => {
  return pmtMethod && (CREDIT_CARD_METHODS.includes(pmtMethod) || DEBIT_CARD_METHODS.includes(pmtMethod))
}

export const isBlueSnapCardData = (data: any): data is BlueSnapCardDataType => {
  return data && 'ccType' in data && 'cardSubType' in data && 'issuingCountry' in data && 'binCategory' in data
}

export const useGetPaymentSummaryData = (
  pmtData: BlueSnapCardDataType | ACHPaymentDataType | BECSPaymentDataType | undefined,
  projectId: string,
  pmtRequestId: number | undefined,
  orgCountry: string | undefined,
  user: AuthUserType | undefined
) => {
  const [pmtSummaryData, setPmtSummaryData] = useState<PricingSummaryType | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (user && pmtRequestId && projectId) {
      const paymentMethod = isBlueSnapCardData(pmtData)
        ? getOpenSolarCCType(
            pmtData?.ccType,
            pmtData?.cardSubType,
            pmtData?.issuingCountry,
            orgCountry,
            pmtData?.binCategory
          )
        : undefined
      setIsLoading(true)
      restClientInstance('CUSTOM_GET', 'custom', {
        // @ts-ignore
        url: `projects/${projectId}/payments/requests/${pmtRequestId}/pricing_summary/?payment_method=${paymentMethod}&card_brand=${pmtData?.ccType}`,
      })
        .then((res: PricingSummaryResponse) => {
          setPmtSummaryData(res.data)
        })
        .catch((err) => {
          console.log('surcharge err', err)
        })
        .finally(() => setIsLoading(false))
    }
  }, [pmtData, pmtRequestId, orgCountry, projectId, user])

  return { pmtSummaryData, isLoading }
}

export const useGetProjectData = (projectId: string, orgId: undefined | number) => {
  const [projectData, setProjectData] = useState<BaseProjectType | undefined>(undefined)

  // only run once we have an org id and user, the other dependencies are all passed in from the URL so a change should cause a re-render
  useEffect(() => {
    if (orgId && projectId) {
      restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${orgId}/projects/${projectId}/`,
      })
        .then((res) => {
          setProjectData(JSON.parse(res.body))
        })
        .catch((err) => {
          console.log('surcharge err', err)
        })
    }
  }, [orgId, projectId])

  return projectData
}

export const recordPaymentFormView = (projectId: string, paymentRequestId: number) => {
  restClientInstance('CUSTOM_POST', 'custom', {
    url: `projects/${projectId}/payments/requests/${paymentRequestId}/record_view/`,
  }).catch((res) => null)
}

export const isValidUSRoutingNumber = (routingNumber: string) => {
  const routingNumberRegex = /^[0-9]{9}$/
  // needs to be exactly 9 digits

  // bluesnap's testing numbers are invalid, but we need to allow them to pass validation for end-to-end testing
  // https://developers.bluesnap.com/reference/ach-ecp#section-sandbox-testing
  if (['987654321', '998877665'].includes(routingNumber)) return true
  if (routingNumberRegex.test(routingNumber)) {
    let sum = 3 * (parseInt(routingNumber[0]) + parseInt(routingNumber[3]) + parseInt(routingNumber[6]))
    sum += 7 * (parseInt(routingNumber[1]) + parseInt(routingNumber[4]) + parseInt(routingNumber[7]))
    sum += parseInt(routingNumber[2]) + parseInt(routingNumber[5]) + parseInt(routingNumber[8])
    return sum % 10 === 0
  }
  return false
}

export const sortPaymentMethods = (paymentMethods: SelectedPaymentMethodType[]) => {
  const SORT_MAP = {
    credit_card: 1,
    ach: 2,
    becs_direct_debit: 3,
    local_bank_transfer: 4,
  }
  if (!paymentMethods) return []
  return paymentMethods.sort((a, b) => (SORT_MAP[a] || 99) - (SORT_MAP[b] || 99))
}

import { FC } from 'react'
import { CodeBlock } from '../CodeBlock'
import ComponentPage from '../ComponentPage'
import { NavigationLinks } from './NavigationUsage'

const NavigationLinks__code = require('!!raw-loader!./NavigationUsage').NavigationLinks

export const DemoNavigation: FC = () => {
  return (
    <ComponentPage name={'Navigation'} import="import NavigationLink from 'elements/navigation/NavigationLink'">
      <CodeBlock
        code={NavigationLinks__code}
        codePart={1}
        name="NavigationLink Usage"
        description="How to use basic navigation links"
      >
        <NavigationLinks />
      </CodeBlock>
    </ComponentPage>
  )
}

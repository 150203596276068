import { MenuItem } from '@material-ui/core'
import { Chip, Select, SelectProps } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { getProjectSectionStyles } from '../styles'

interface PropTypes<T> {
  choices: T[]
  chipLabel: string
  optionValue: string
  optionText: string | ((option: T) => string)
  renderEmpty?: string
}
/*
This component is a Select input field that includes a Chip component as a prefix label for the displayed value on the field.
Simply add a chipLabel to be able to display the desired text to appear on the Chip. 

*/

const SelectChipField = <T,>(props: PropTypes<T> & SelectProps) => {
  const { choices, chipLabel, optionValue, optionText, renderEmpty, ...rest } = props
  const translate = useTranslate()
  const sectionStyles = getProjectSectionStyles()
  const choiceDisplay = (option) => {
    if (!option) return ''
    let retString = ''
    if (typeof optionText === 'string') retString = option[optionText]
    else retString = optionText(option)
    return (retString += option.is_archived ? ` (${translate('archived')})` : '')
  }
  return (
    <Select
      displayEmpty={!!renderEmpty}
      variant="outlined"
      renderValue={(value) => {
        const selectedOption = choices.find((x) => x[optionValue] === value)
        return (
          <div className={sectionStyles.row}>
            {chipLabel ? <Chip label={chipLabel} size="small" className={sectionStyles.chip} /> : null}
            {selectedOption ? choiceDisplay(selectedOption) : renderEmpty}
          </div>
        )
      }}
      {...rest}
    >
      {choices?.map((item, i) => (
        <MenuItem key={'select-' + i} value={item[optionValue]}>
          {choiceDisplay(item)}
        </MenuItem>
      ))}
    </Select>
  )
}

export default SelectChipField

import React from 'react'
import { useTranslate } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import BasicLink from './BasicLink'

const useStyles = makeOpenSolarStyles((theme: any) => ({
  hint: {
    margin: '30px 5px 5px 10px',
    justifyContent: 'space-between',
    textAlign: 'center',
    marginTop: '1em',
    color: '#4D4D4D',
    fontSize: '11pt',
  },
}))

const LoginModalActions = ({ onForgotPassword, onCancel }: { onForgotPassword(): void; onCancel(): void }) => {
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <div className={classes.hint}>
      <BasicLink
        id="login-forgot-password-action"
        handleClick={onForgotPassword}
        label={translate('Forgot password?')}
      />
      <BasicLink id="login-cancel-action" handleClick={onCancel} label={translate('Cancel')} />
    </div>
  )
}

export default LoginModalActions

import { Tooltip, makeStyles } from '@material-ui/core'
import { CheckOutlined } from '@material-ui/icons'
import { OpenSolarThemeType } from 'Themes'
import { orgSelectors } from 'ducks/orgs'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { currencySymbolForCountry, formatCurrencyWithSymbol } from 'util/misc'

type PropTypes = {
  milestones
  address
  projectId: number
}

const useStyles = makeStyles<OpenSolarThemeType, { isPaid?; isOverdue?; isOutstanding?; progressBar?; isSent? }>(
  (theme) => ({
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      minHeight: '25px',
      padding: '0px 0 24px 0',
    },

    progressIndicator: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      minWidth: '100px',
    },

    iconWrapper: {
      zIndex: 5,
      marginTop: 5,
    },

    label: {
      marginBottom: 0,
    },

    milestoneTitle: {
      color: theme.greyMid1,
      display: 'flex',
      gap: 5,
      alignItems: 'center',
      textWrap: 'noWrap',
    },

    milestoneRow: {
      textAlign: 'left',
      padding: '3px 0px',
    },

    greenCheck: {
      height: '10px',
      width: '10px',
      padding: '3px',
      color: 'white',
      backgroundColor: theme.alertIcon_success,
      borderRadius: '50px',
      position: 'absolute',
      zIndex: 6,
    },

    circle: {
      borderRadius: '50px',
      color: 'white',
      height: '15px',
      width: '15px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '16px',
      fontWight: 600,
      position: 'absolute',
      zIndex: 6,
      backgroundColor: ({ isPaid, isOverdue, isOutstanding }) => {
        if (isPaid) {
          return theme.alertIcon_success
        } else if (isOverdue) {
          return '#C95213'
        } else if (isOutstanding) {
          return '#4272DD'
        } else {
          return theme.greyLight2
        }
      },
    },

    timelineBar: {
      position: 'absolute',
      top: '11px',
      left: '1px',
      right: '-1px',
      height: '4px',
      zIndex: 4,
      backgroundColor: ({ progressBar }) => {
        if (progressBar === 'Paid') {
          return theme.alertIcon_success
        } else if (progressBar === 'Overdue') {
          return '#C95213'
        } else if (progressBar === 'Outstanding') {
          return '#4272DD'
        } else {
          return theme.greyLight2
        }
      },
    },

    paymentStatus: {
      color: ({ isOverdue, isPaid, isSent }) => {
        if (isOverdue) {
          return '#C95213'
        } else if (isPaid) {
          return theme.alertIcon_success
        } else if (!isSent) {
          return theme.greyMid1
        } else {
          return '#4272DD'
        }
      },
    },

    tooltip: {
      lineHeight: '0.75',
    },

    tooltipText: {
      fontSize: 13,
    },

    warningIcon: {
      fontSize: 15,
      color: theme.alertIcon_warning,
      marginTop: 3,
    },
  })
)

const MilestoneStepper: React.FC<PropTypes> = ({ milestones, address, projectId }) => {
  const classes = useStyles({})

  const currentDate = new Date()

  let nextMilestone = milestones.find((milestone) => milestone.is_next === true)
  const nextMilestoneIndex = milestones.findIndex((payment) => payment.is_next)

  // get milestones with overdue payments
  const overduePayments = milestones.filter((payment) => {
    const dateDue = payment.date_due ? new Date(payment.date_due) : null
    return dateDue && dateDue < currentDate && !payment.is_paid
  })

  // check if all milestones is_paid = true
  const allPaid = milestones.every((payment) => payment.is_paid)

  const firstOverduePayment = overduePayments.length > 0 ? overduePayments[0] : null
  const firstOverdueIndex = milestones.findIndex((payment) => payment === firstOverduePayment)

  if (firstOverduePayment) {
    if (nextMilestone) {
      if (firstOverdueIndex < nextMilestoneIndex) {
        nextMilestone = firstOverduePayment
      }
    } else {
      nextMilestone = firstOverduePayment
    }
  }

  // check if there are milestones with oustanding invoices after is_next milestone
  const indexAfterNextWithPaymentRequestId = milestones
    .slice(nextMilestoneIndex + 1)
    .findIndex((payment) => payment.payment_request_id !== null && payment.is_paid === false)

  let hasPaymentRequestIdAfterNext = false

  if (nextMilestoneIndex > 0)
    hasPaymentRequestIdAfterNext = milestones
      .slice(nextMilestoneIndex + 1)
      .some((payment) => payment.payment_request_id !== null)

  const actualOutstandingIndex = nextMilestoneIndex + 1 + indexAfterNextWithPaymentRequestId
  const nextOutstandingInvoice = milestones[actualOutstandingIndex]

  const lastSentUnpaidMilestone = milestones
    .filter((payment) => payment.payment_request_id !== null && !payment.is_paid)
    .pop()

  const stepperMilestone = useMemo(() => {
    if (lastSentUnpaidMilestone) {
      return lastSentUnpaidMilestone
    } else if (hasPaymentRequestIdAfterNext) {
      return nextOutstandingInvoice
    } else if (nextMilestone) {
      return nextMilestone
    } else {
      return milestones[milestones.length - 1]
    }
  }, [lastSentUnpaidMilestone, hasPaymentRequestIdAfterNext, nextMilestone])

  return (
    <div>
      <MilestoneLabel
        milestone={stepperMilestone.title}
        isOverdue={overduePayments.includes(stepperMilestone)}
        isSent={stepperMilestone.payment_request_id}
        isPaid={allPaid}
      />

      <div className={classes.row}>
        {milestones.map((milestone, index) => {
          return (
            <div className={classes.progressIndicator} key={milestone?.payment_request_id || index}>
              <div className={classes.iconWrapper}>
                <MilestoneIndicator
                  isOverdue={overduePayments.includes(milestone)}
                  isOutstanding={index < nextMilestoneIndex}
                  index={index}
                  totalMilestones={milestones.length}
                  milestone={milestone}
                  nextIsOutstanding={index + 1 === actualOutstandingIndex}
                />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const MilestoneLabel = ({ milestone, isOverdue, isPaid, isSent }) => {
  const classes = useStyles({ isOverdue, isPaid, isSent })
  const milestoneStatus = useMemo(() => {
    if (isPaid) {
      return 'Complete'
    } else if (isOverdue) {
      return 'Overdue'
    } else if (!isSent) {
      return 'Not Sent'
    } else return 'Outstanding'
  }, [isPaid, isOverdue])

  return (
    <p className={classes.label}>
      {!isPaid && `${milestone}: `}
      <span className={classes.paymentStatus}>{milestoneStatus}</span>
    </p>
  )
}

const MilestoneIndicator = ({ isOverdue, index, totalMilestones, isOutstanding, milestone, nextIsOutstanding }) => {
  const countryIso2 = useSelector(orgSelectors.getOrgIso2)
  const currencySymbol = currencySymbolForCountry(countryIso2)
  const isPaid = milestone.is_paid
  const notSent = !milestone.payment_request_id
  isOutstanding = !notSent

  const progressBar = useMemo(() => {
    if (isPaid) {
      return 'Paid'
    }
    if (isOverdue && !nextIsOutstanding) {
      return 'Overdue'
    }
    if (!notSent && !nextIsOutstanding) {
      return 'Outstanding'
    }
    return 'Not Sent'
  }, [isPaid, isOverdue, notSent, nextIsOutstanding])

  const milestoneStatus = useMemo(() => {
    if (isPaid) {
      return 'Paid'
    }
    if (isOverdue) {
      return 'Overdue'
    }
    if (notSent) {
      return 'Not Sent'
    }
    return 'Outstanding'
  }, [isPaid, isOverdue, notSent])

  const classes = useStyles({ isPaid, isOverdue, isOutstanding, progressBar })

  return (
    <>
      <Tooltip
        title={
          <div className={classes.tooltip}>
            <p className={classes.tooltipText}>
              {milestone.title} - {milestoneStatus}
            </p>
            <p className={classes.tooltipText}>
              {isPaid ? 'Amount Paid: ' : 'Amount Due: '}
              {formatCurrencyWithSymbol(milestone.payment_amount, currencySymbol)}
            </p>
            {isPaid && (
              <p className={classes.tooltipText}>
                Date Paid: {new Date(milestone.date_completed).toLocaleDateString()}
              </p>
            )}
          </div>
        }
        arrow={true}
      >
        {isPaid ? <CheckOutlined className={classes.greenCheck} /> : <div className={classes.circle}> </div>}
      </Tooltip>
      {index + 1 !== totalMilestones && <div className={classes.timelineBar} />}
    </>
  )
}

export default MilestoneStepper

import { ConditionFactory, SystemContext } from 'Designer/designRules/types'
import { BaseConditionDef } from '.'
import { checkScope, CheckScopeDef } from '../utils'

export type CheckReduxStateDef = BaseConditionDef &
  Omit<CheckScopeDef, 'prop'> & {
    type: 'check-redux-state'
    path: string //TODO: get rid of this
  }

export const condition_checkReduxState: ConditionFactory<CheckReduxStateDef> = (def) => {
  return {
    getReduxPaths: () => [def.path],
    check: (context: SystemContext) => {
      return checkScope(context.state, { ...def, prop: def.path })
    },
  }
}

// in src/users.js
import { List } from 'elements/react-admin/List'
import React from 'react'
import { Datagrid, FilterComp, ReferenceInput, SelectInput, TextField, TextInput } from 'react-admin'
import { styles as standardStyles } from 'styles'

export const CsvFileUploadLogFilter = (props) => (
  <FilterComp {...props}>
    <TextInput label="pos.search" style={standardStyles.FILTER_FIELD_STYLE} source="q" />
    <ReferenceInput source="role_id" reference="roles">
      <SelectInput
        source="email"
        options={{ style: standardStyles.FILTER_FIELD_STYLE }}
        sortChoices={true}
        optionText="display"
        optionValue="id"
      />
    </ReferenceInput>
    <ReferenceInput label="CSV File Upload" source="csv_file_upload_request" reference="csv_file_upload_requests">
      <SelectInput
        source="id"
        options={{ style: standardStyles.FILTER_FIELD_STYLE }}
        optionText="created_date"
        optionValue="id"
      />
    </ReferenceInput>
  </FilterComp>
)

export const CsvFileUploadLogList = (props) => (
  <List title="CSV File Upload Logs" hasSearch={true} filters={<CsvFileUploadLogFilter />} {...props}>
    <Datagrid>
      <TextField source="type" />
      <TextField source="message" />
    </Datagrid>
  </List>
)

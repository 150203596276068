// @ts-nocheck
import { Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import InvitationDialog from 'elements/button/InvitationDialog'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import React, { useEffect, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import {
  PaymentOptionSunlightQuickCreateType,
  SystemDesignSunlightQuickCreateType,
} from 'resources/integrations/sunlight/types'
import { ProjectType } from 'types/projects'

export const AU_INTEGRATIONS = ['brighte', 'plenti', 'energy_ease']

type PropTypes = {
  onClose: () => void
  open: boolean
  project: ProjectType
  designs?: SystemDesignSunlightQuickCreateType[]
}

const AuFinanceCtaDialog: React.FunctionComponent<PropTypes> = (props) => {
  const getIntegratedPaymentOptions = () => {
    if (systems && systems.length > 0) {
      let allOptions: PaymentOptionSunlightQuickCreateType[] = []
      systems.forEach((design) => {
        if (design.paymentOptionsData && design.paymentOptionsData.length > 0) {
          design.paymentOptionsData
            .filter((opt) => AU_INTEGRATIONS.includes(opt.integration))
            .forEach((opt) => allOptions.push(opt))
        }
      })
      return allOptions
    } else return []
  }

  const [availablePaymentOptions, setAvailablePaymentOptions] = useState<PaymentOptionSunlightQuickCreateType[]>([])
  const [selectedPaymentOptionId, setSelectedPaymentOptionId] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [customerEmail, setCustomerEmail] = useState<string | undefined>(undefined)
  const [customerPhone, setCustomerPhone] = useState<string | undefined>(undefined)
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const [systems, setSystems] = useState<any[]>([])
  const [selectedSystem, setSelectedSystem] = useState<SystemDesignSunlightQuickCreateType | undefined>(undefined)
  const [showSendProposalDialog, setShowSendProposalDialog] = useState<boolean>(false)

  const translate = useTranslate()
  const notify = useNotify()

  useEffect(() => {
    logAmplitudeEvent('initiate_application_cta_clicked', {})
  }, [])

  useEffect(() => {
    const systemsFromStudio = window.editor.getSystems()?.map((sys) => {
      sys.paymentOptionsData = sys.payment_options
      sys.title = sys.name && sys.name.length > 0 ? sys.name : `${sys.moduleQuantity()} ${translate('Panels System')}`
      return sys
    })
    if (systemsFromStudio && systemsFromStudio.length > 0) setSystems(systemsFromStudio)
    if (systemsFromStudio?.length === 1) setSelectedSystem(systemsFromStudio[0])
  }, [])

  useEffect(() => {
    if (!selectedPaymentOptionId && availablePaymentOptions && availablePaymentOptions.length === 1) {
      setSelectedPaymentOptionId(`${availablePaymentOptions[0].id}`)
    }
  }, [selectedPaymentOptionId, availablePaymentOptions])

  useEffect(() => {
    if (!selectedSystem && systems && systems.length === 1) {
      setSelectedSystem(systems[0])
    }
  }, [systems, selectedSystem])

  useEffect(() => {
    setAvailablePaymentOptions(getIntegratedPaymentOptions())
  }, [systems])

  useEffect(() => {
    if (selectedSystem && systems) {
      //dedupe titles present on multiple systems
      const availablePmtOptions = selectedSystem.paymentOptionsData.filter((pmtOption) => {
        return AU_INTEGRATIONS.includes(pmtOption.integration)
      })
      setAvailablePaymentOptions(availablePmtOptions)
      if (!availablePmtOptions || availablePmtOptions.length === 0) setSelectedPaymentOptionId(undefined)
    } else if (availablePaymentOptions && availablePaymentOptions.length > 0) setAvailablePaymentOptions([])
  }, [selectedSystem, systems])

  useEffect(() => {
    if (props.project && props.project.contacts_data && props.project?.contacts_data?.length > 0) {
      let email = props?.project?.contacts_data[0]?.email
      let phone = props?.project?.contacts_data[0]?.phone
      setCustomerEmail(email)
      setCustomerPhone(phone)
      if (!email || !phone) setErrorMsg('Customer must have a valid email address and phone number')
    }
  }, [props.project?.contacts_data])

  const handlePmtOptionChange = (e: any) => {
    let selected = availablePaymentOptions.find((pmt) => `${pmt.id}` === e.target.value)
    setSelectedPaymentOptionId(e.target.value)
  }
  const handleSystemChange = (e: any) => {
    if (systems && systems.length > 0) {
      let uuidMatches = systems.filter((system) => system.uuid === e.target.value)
      if (uuidMatches && uuidMatches.length > 0) setSelectedSystem(uuidMatches[0])
      setSelectedPaymentOptionId(undefined)
    }
  }

  const openProView = () => {
    let systemId = selectedSystem?.userData?.id
    if (!systemId) {
      let system = props.project?.systems?.find((sys) => sys.uuid === selectedSystem?.userData?.uuid)
      if (system && system.id) systemId = system.id
    }
    let url = `${window.location.origin}/#/projects/${props.project.id}/proposal?view_mode=application&target_system=${systemId}&target_pmt=${selectedPaymentOptionId}`
    window.open(url, '_blank')
    props.onClose()
    logAmplitudeEvent('finance_application_initiated_as_pro', {})
  }

  const openSendProposalDialog = () => {
    setShowSendProposalDialog(true)
    logAmplitudeEvent('au_send_integrated_credit_app_clicked', {})
  }

  const renderForm = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div>
            {translate('Select which system and payment option you would like to start a finance application for')}
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <h2 style={{ marginTop: '30px' }}>{translate('Select a System')}</h2>
          <RadioGroup
            style={{ alignItems: 'flex-start' }}
            name="pmt-options"
            value={selectedSystem?.uuid}
            onChange={handleSystemChange}
          >
            {systems &&
              systems.map((system) => {
                return (
                  <FormControlLabel
                    key={system.uuid}
                    value={system.uuid}
                    control={<Radio />}
                    label={<span style={{ fontSize: 13 }}>{system.title}</span>}
                  />
                )
              })}
          </RadioGroup>
        </Grid>
        <Grid item xs={12} sm={6}>
          <h2 style={{ marginTop: '30px' }}>{translate('Select a Payment Option')}</h2>
          {selectedSystem && availablePaymentOptions?.length === 0 && (
            <div style={{ color: 'red' }}>
              {translate(
                'You must add an integrated payment option to this system before you can send a credit application'
              )}
            </div>
          )}
          <RadioGroup value={`${selectedPaymentOptionId}`} onChange={handlePmtOptionChange}>
            {availablePaymentOptions.map((pmtOption) => {
              if (!selectedSystem) return null
              return (
                <FormControlLabel
                  key={pmtOption.id}
                  value={`${pmtOption.id}`}
                  control={<Radio disabled={!selectedSystem} checked={`${pmtOption.id}` === selectedPaymentOptionId} />}
                  label={<span style={{ fontSize: 13 }}>{pmtOption.title}</span>}
                />
              )
            })}
          </RadioGroup>
        </Grid>
        {(!customerEmail || !customerPhone) && (
          <Grid item xs={12}>
            <div style={{ color: 'red', textAlign: 'center' }}>
              {translate(
                'Please make sure the project is saved with a customer that has a valid phone number or email address.'
              )}
            </div>
          </Grid>
        )}
        {errorMsg && (
          <Grid item xs={12}>
            <p style={{ color: 'red', textAlign: 'center', width: '100%' }}>{errorMsg}</p>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
        >
          <div style={{ margin: '10px 20px' }}>
            <ProUXButton
              label="Apply for Finance"
              type="secondary"
              fullWidth
              style={{ width: '400px' }}
              onClick={openProView}
              loading={loading}
              disabled={
                loading || !selectedSystem || !selectedPaymentOptionId || disabled || !customerEmail || !customerPhone
              }
            />
          </div>
          <div style={{ margin: '10px 20px' }}>
            <ProUXButton
              label="Send Finance Application Form to Customer"
              type="secondary"
              fullWidth
              style={{ width: '400px' }}
              onClick={openSendProposalDialog}
              loading={loading}
              disabled={
                loading || !selectedSystem || !selectedPaymentOptionId || disabled || !customerEmail || !customerPhone
              }
            />
          </div>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      {showSendProposalDialog ? (
        <InvitationDialog
          contacts={props.project.contacts}
          projectId={props.project.id}
          handleClose={() => setShowSendProposalDialog(false)}
          project={props.project}
          applicationPaymentOptionId={selectedPaymentOptionId}
          applicationSystemUuid={selectedSystem?.uuid}
          onSuccess={props.onClose}
        />
      ) : (
        <Dialog open={props.open} onClose={props.onClose}>
          <DialogTitle>{translate('Initiate Finance Application')}</DialogTitle>
          <DialogContent>{renderForm()}</DialogContent>
        </Dialog>
      )}
    </>
  )
}
export default AuFinanceCtaDialog

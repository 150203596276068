import { FormControlLabel, Typography } from '@material-ui/core'
import { Checkbox } from 'opensolar-ui'
import { useState } from 'react'
import { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import HdmConnectForm from './HdmConnectForm'
import HdmSignupForm from './HdmSignupForm'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  container: {
    width: '100%',
    padding: 20,
    boxSizing: 'border-box',
  },
  contentWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
}))
const HdmConnectPage = () => {
  const classes = useStyles()
  const [isNewAccount, setIsNewAccount] = useState(false)

  return (
    <div className={classes.container}>
      <Typography variant="h5">Connect to HDM</Typography>
      <Typography variant="body1">
        {isNewAccount
          ? 'Enter your details to create your HDM trade account.'
          : 'Use the email address associated with your HDM trade account to connect and get your trade pricing.'}
      </Typography>
      <FormControlLabel
        style={{ marginTop: 20, marginLeft: 0 }}
        control={
          <Checkbox
            id="elevationAutoCheckbox"
            checked={isNewAccount}
            onChange={() => setIsNewAccount(!isNewAccount)}
            style={{ padding: 0, paddingRight: 9 }}
          />
        }
        label={"I don't have a HDM trade account"}
      />
      {isNewAccount ? <HdmSignupForm /> : <HdmConnectForm />}
    </div>
  )
}

export default HdmConnectPage

export const UTILITY_API_DIALOG = 'UTILITY_API_DIALOG'
export const UTILITY_API_SET_METERS = 'UTILITY_API_SET_METERS'

export const setUtilityApiDialog = (isOpen, projectId, callback) => ({
  type: UTILITY_API_DIALOG,
  payload: {
    isOpen,
    projectId,
    callback,
  },
})

export const setUtilityApiMeters = (meters) => ({
  type: UTILITY_API_SET_METERS,
  payload: {
    meters: meters,
  },
})

export default (previousState = {}, { type, meta, payload, requestPayload }) => {
  if (type === '@@router/LOCATION_CHANGE') {
    // Clear all settings on change of location
    if (payload.location.pathname.indexOf('/projects/') === -1) {
      previousState = { ...previousState, isOpen: false }
    }
  }

  if (type === UTILITY_API_DIALOG) {
    var version = previousState && previousState.version ? previousState.version + 1 : 1

    previousState = {
      ...previousState,
      ...{
        isOpen: payload.isOpen,
        projectId: payload.projectId,
        callback: payload.callback,
        version: version,
        meters: [],
      },
    }
  }

  if (type === UTILITY_API_SET_METERS) {
    previousState = {
      ...previousState,
      meters: payload.meters,
    }
  }

  return previousState
}

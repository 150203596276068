import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  container: {
    padding: 15,
    alignItems: 'center',
    boxSizing: 'border-box',
    border: theme.border.default,
    borderRadius: '8px',
    borderColor: theme.greyMid2,
  },
  items: {
    padding: '10px 0',
  },
  deliveryInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  time: {
    padding: '10px 0',
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.greyDark2,
  },
  service: {
    color: theme.greyMid1,
  },
  stock: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.subtitle1.fontSize,
    color: 'green',
  },
  stockCount: {
    color: theme.greyMid1,
  },
}))

type DeliveryInfoType = {
  city?: string
  deliverTime?: string
}

export const ConfirmationContent = ({
  CtaAction,
  SupplierDetail,
  deliverTime,
  city,
}: {
  CtaAction: React.ReactNode
  SupplierDetail: React.ReactNode
  deliverTime?: string
  city?: string
}) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      {!!deliverTime && (
        <section className={classes.items}>
          <div className={classes.service}>Next day delivery eligible</div>
          <div className={classes.time}>{deliverTime}Tomorrow 2PM - 5PM</div>
        </section>
      )}

      {!!city && (
        <section className={classes.deliveryInfo}>
          <LocationOnOutlinedIcon />
          <div>Deliver to {city}</div>
        </section>
      )}

      <section className={classes.items}>{CtaAction}</section>
      <section className={classes.items}>{SupplierDetail}</section>
    </div>
  )
}

const Confirmation = ({
  CtaAction,
  deliveryInfo,
  SupplierDetail,
}: {
  SupplierDetail: React.ReactNode
  CtaAction: React.ReactNode
  deliveryInfo?: DeliveryInfoType
}) => {
  return (
    <ConfirmationContent
      deliverTime={deliveryInfo?.deliverTime}
      city={deliveryInfo?.city}
      CtaAction={CtaAction}
      SupplierDetail={SupplierDetail}
    />
  )
}

export default Confirmation

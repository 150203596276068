import { DialogContent, HourGlassOutlineIcon } from 'opensolar-ui'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import { useTranslate } from 'ra-core'
import React from 'react'
import { EventCategoryEnum } from 'types/events'
import Notice from '../../../common/Notice'

const emptyTexts = {
  all: 'system and customer activities, notes, and tasks',
  activities: 'activities',
  0: 'system activities',
  1: 'customer activities',
  2: 'notes',
  3: 'tasks',
}
interface PropTypes {
  eventFilter: string | EventCategoryEnum
}

const EmptyEventsContent: React.FC<PropTypes> = ({ eventFilter }) => {
  const translate = useTranslate()
  const categoryDisplay = translate(emptyTexts[eventFilter])
  return (
    <DialogContent>
      <Notice
        icon={<HourGlassOutlineIcon variant={3} size={26} color={COLOR_PALETTE.darkGrey} />}
        mainText={translate('No %{categoryDisplay} found', { categoryDisplay: categoryDisplay })}
        subText={translate('You can view all %{categoryDisplay} here.', { categoryDisplay: categoryDisplay })}/>
    </DialogContent>
  )
}

export default EmptyEventsContent
import ButtonDemo from 'pages/uiLibrary/components/button/ButtonDemo'
import ComponentPage from '../ComponentPage'

const ButtonInfo = () => {
  return (
    <ComponentPage name={'Button'}>
      <ButtonDemo />
    </ComponentPage>
  )
}

export default ButtonInfo

import { useMsal } from '@azure/msal-react'
import { useMemo } from 'react'
import { useNotify } from 'react-admin'
import restClient from 'restClient'
import SegenService from 'services/segen/SegenServiceV2'
import type { SegenConfigType } from './SegenAuthenticationService'

const restClientInstance = restClient(window.API_ROOT + '/api')
const useSegenServiceV2 = (): SegenService => {
  const { instance } = useMsal()
  const segenConfig: SegenConfigType = window.getSegenConfig()
  const notify = useNotify()
  const segenService = useMemo(() => new SegenService(segenConfig, restClientInstance, notify, instance), [
    segenConfig,
    restClientInstance,
    notify,
    instance,
  ])

  return segenService
}

export default useSegenServiceV2

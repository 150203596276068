import Pagination from 'elements/pagination/Pagination'
import { styled } from 'opensolar-ui'
import { Datagrid, DatagridCell, FilterComp, SearchInput } from 'react-admin'

export const StyledDatagrid = styled(Datagrid)(({ theme }) => ({
  '& th:first-of-type > span': {
    display: 'none',
  },
  '& th': {
    height: 50,
  },
  '& td:last-of-type, & th:last-of-type': {
    width: 80,
    minWidth: 80,
    zIndex: 9999,
  },
  border: '1px solid',
  borderColor: theme.palette.grey[200],
}))

export const StyledSearchInput = styled(SearchInput)(({ theme }) => ({
  padding: 0,
  width: '100%',
  margin: 0,
  marginTop: 10,
}))

export const StyledFilterComp = styled(FilterComp)(({ theme }) => ({
  padding: 0,
  '& > div': {
    width: '100%',
  },
}))

export const StyledPagination = styled(Pagination)(({ theme }) => ({
  marginTop: 10,
}))

export const StyledDatagridCell = styled(DatagridCell)(({ theme }) => ({
  '& > span > div': {
    height: 40,
    paddingTop: 8,
    paddingBottom: 8,
  },
  '& > span > div > div': {
    height: '100%',
  },
}))

export const TitleField = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
  alignItems: 'center',
}))

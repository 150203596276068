import restClient from 'restClient'
import { DocuSignCountersignerFields, DocumentType } from 'types/redux_generateDocument'
import { SystemDataType } from 'types/systems'
import { getIsValidEmail } from 'util/misc'

export const sendDocusignEnvelope = (
  projectId: number,
  orgId: number,
  selectedSystem: SystemDataType,
  selectedPaymentOptionId: number,
  setErrorMsg: (msg: string) => void,
  documentTemplateId: number,
  doc: DocumentType,
  closeDialog: () => void,
  hasCountersigner: boolean,
  countersignerFields?: DocuSignCountersignerFields
) => {
  if (!selectedSystem) {
    setErrorMsg('Please select a system first')
    return
  } else if (!selectedPaymentOptionId) {
    setErrorMsg('Please select a payment option')
    return
  } else if (hasCountersigner) {
    if (!countersignerFields?.firstName || !countersignerFields?.lastName || !countersignerFields?.email) {
      setErrorMsg('Please provide a full name and email for the countersigner')
      return
    }
    if (countersignerFields?.email && !getIsValidEmail(countersignerFields?.email)) {
      setErrorMsg('The countersigner email you entered is invalid')
      return
    }
  }

  const API_URL = window.API_ROOT + '/api'
  const restClientInstance = restClient(API_URL)
  restClientInstance('CUSTOM_POST', 'custom', {
    url:
      'orgs/' +
      //@ts-ignore
      orgId +
      '/projects/' +
      projectId +
      '/systems/' +
      selectedSystem.uuid +
      '/' +
      selectedPaymentOptionId +
      '/docusign/email_envelope/',
    data: {
      file_url: doc?.downloadUrl,
      template_id: documentTemplateId,
      counter_first_name: countersignerFields?.firstName,
      counter_last_name: countersignerFields?.lastName,
      counter_email: countersignerFields?.email,
    },
  })
    .then((res) => {
      if (res.data.success) {
        closeDialog()
      }
    })
    .catch((err) => console.log('err', err))
}

//ensure all Change Order placeholders are included
export const formatPlaceholderValues = (placeholders, values) => {
  let data = {}
  Object.keys(values).forEach((x) => {
    data[x.replaceAll('-', '.')] = values[x]
  })
  const missingPlaceholders = placeholders?.filter((x) => !Object.keys(data).includes(x.field))
  missingPlaceholders.forEach((x) => {
    data[x.field] = x.value || ''
  })
  return data
}

import { makeStyles, Paper } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { updateWalletInState } from 'ducks/wallet'
import Button from 'elements/button/Button'
import { Checkbox } from 'opensolar-ui'
import WalletTermsAndConditionsDialog from 'pages/auth/WalletTermsAndConditionsDialog'
import React, { useState } from 'react'
import { HttpError, useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'
import { WalletType } from 'types/wallet'
import { TaxDetailsSection, useTaxDetailsStyles } from './TaxDetailsSection'
import { useNotifyWalletError } from './useNotifyWalletError'

type PropTypes = {}

const INITIAL_WALLET_SETTINGS: Partial<WalletType> = {
  balance: 0,
  enable_auto_top_up: false,
  top_up_amount: 0,
  top_up_trigger_amount: 0,
}

const useStyles = makeStyles(
  {
    section: { display: 'flex', justifyContent: 'flex-start', alignItems: 'center', margin: '40px 0px' },
  },
  { name: 'EnableWalletPage' }
)

const EnableWalletPage: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const orgId = useSelector(authSelectors.getOrgId)
  const orgCountry = useSelector(orgSelectors.getOrgIso2)
  const classes = useStyles()
  const taxDetailsClasses = useTaxDetailsStyles()

  const [hasAgreed, setHasAgreed] = useState(false)
  const [showTermsModal, setShowTermsModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const formState = useFormState()

  const notifyWalletError = useNotifyWalletError(undefined, orgCountry)

  const submit = () => {
    setLoading(true)
    const data = { ...INITIAL_WALLET_SETTINGS, tax_identifier: formState.values.tax_identifier }
    const restClientInstance = restClient(window.API_ROOT + '/api')
    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${orgId}/wallets/`,
      data,
    })
      .then(function (res: any) {
        if (res.data) {
          dispatch(updateWalletInState(res.data))
        }
      })
      .catch((err: HttpError) => {
        notifyWalletError(err)
      })
      .finally(() => setLoading(false))
  }

  return (
    <div>
      <h1 style={{ fontSize: '20px', margin: '20px 0px', fontWeight: 500 }}>{translate('Your Wallet')}</h1>
      <Paper className={taxDetailsClasses.root}>
        <p>
          {translate(
            'Your Wallet can be used to access OpenSolar’s Premium content. High resolution aerial imagery is currently available, with more content and services to come. Your Wallet can be configured below.'
          )}
        </p>
        <div className={classes.section}>
          <Checkbox
            checked={!!hasAgreed}
            onChange={() => setHasAgreed(!hasAgreed)}
            id="enable_wallet_term_checkeckbox"
          />
          <span>
            {translate('I agree to the OpenSolar')}{' '}
            <strong onClick={() => setShowTermsModal(true)} style={{ cursor: 'pointer' }}>
              {translate('terms and conditions')}
            </strong>
          </span>
        </div>
      </Paper>
      <TaxDetailsSection />

      <p>{translate('Your wallet has not been setup')}</p>
      <div>
        <Button
          id="Set_up_your_Wallet_btn"
          variant="outlined"
          color="default"
          disabled={!hasAgreed || loading}
          onClick={submit}
          loading={loading}
        >
          {<span style={{ textTransform: 'none' }}>{translate('Set up your Wallet')}</span>}
        </Button>
      </div>
      {showTermsModal && (
        <WalletTermsAndConditionsDialog
          open={showTermsModal}
          hide={() => setShowTermsModal(false)}
          onClose={() => setShowTermsModal(false)}
        />
      )}
    </div>
  )
}
export default EnableWalletPage

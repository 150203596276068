import { HelpLink } from 'elements/link/HelpLink'
import React from 'react'
import useTranslateParse from '../../util/useTranslateParse'
import { PricingSchemeCreate, PricingSchemeEdit, PricingSchemeList } from './PricingSchemes'

const PricingSchemeTitle = ({ record, translate, style }) => {
  return (
    <span style={style}>
      {translate('Pricing Scheme')}: {record ? `"${record.title}"` : ''}
    </span>
  )
}

const Subtitle = () => {
  const translateParse = useTranslateParse()
  return (
    <span>
      {translateParse(
        'Set your price schemes method here. The most common methods are price-per-watt (varying based on size), and \'cost-plus\' pricing. If you have more than one pricing method, you can set one as a default and select an alternative pricing method for a given project in the Design page. Note: If you select "Cost-plus" pricing, you can update your COGS <a>here<a>.',
        { a: (label) => <a href={'#/costings'}>{label}</a> }
      )}{' '}
      <HelpLink href="https://support.opensolar.com/hc/en-us/articles/4407182137113" />
    </span>
  )
}

export default {
  list: PricingSchemeList,
  edit: PricingSchemeEdit,
  create: PricingSchemeCreate,
  options: {
    create: {
      title: 'Create Pricing Scheme',
      subtitle: null,
      breadCrumb: 'Create Pricing Scheme',
    },
    list: {
      title: 'My Pricing Schemes List',
      subtitle: <Subtitle />,
      breadCrumb: 'Pricing Schemes',
    },
    edit: {
      title: <PricingSchemeTitle />,
      subtitle: null,
      breadCrumb: 'Edit Pricing Scheme',
    },
  },
}

import { SORT_ASC, SORT_DESC } from 'ra-core'

const DEFAULT_SORT_FIELD = 'id'
const DEFAULT_PAGE = 1
const DEFAULT_PER_PAGE = 20
export type QUERY_ORDER = typeof SORT_DESC | typeof SORT_ASC

interface IQuery {
  page: number
  perPage: number
  sort: string
  order: QUERY_ORDER
}

export class Query implements IQuery {
  page: number
  perPage: number
  sort: string
  order: QUERY_ORDER
  constructor(defaultQuery?: Partial<IQuery> | undefined) {
    const { page = DEFAULT_PAGE, perPage = DEFAULT_PER_PAGE, sort = DEFAULT_SORT_FIELD, order = SORT_DESC } =
      defaultQuery || {}
    this.page = page
    this.perPage = perPage
    this.sort = sort
    this.order = order
  }
}

import { cloneDeep } from 'lodash'
import { getSupplierConfig } from 'pages/ordering/configs'
import type { ComponentTypes } from 'types/selectComponent'
import { FilterOption, FilterType, HideFilterProps, SelectComponentFilters } from 'types/selectComponent'
import { getFeatureFlagSelector } from 'util/split'
import { getCompatibleCodesForSystem } from './SearchToolbar'
import { inverterTypeFilter } from './filters/InverterTypeFilter'
import { mpptQuantityFilter } from './filters/MpptQuantityFilter'
import { otherComponentTypeFilter } from './filters/OtherComponentTypeFilter'
import { batteryRatingFilter, inverterRatingFilter, moduleRatingFilter } from './filters/RatingFilter'
import { StockAvailabilityFilter } from './filters/StockAvailabilityFilter'
import { productWarrantyFilter } from './filters/WarrantyFilter'

export const hardwareFilterTransformer = ({
  filterValues,
  componentType,
}: {
  filterValues: { [key: string]: unknown }
  componentType?: ComponentTypes
}) => {
  const filter = { ...filterValues }
  if (componentType === 'battery' && filter?.compatibility === 'true') {
    delete filter.compatibility
    filter.codes = getCompatibleCodesForSystem('battery').join(',')
  }
  if (filter?.rating_range && typeof filter.rating_range === 'string' && filter.rating_range.includes(',')) {
    filter.rating__gte = filter?.rating_range.split(',')[0]
    filter.rating__lte = filter?.rating_range.split(',')[1]
    delete filter.rating_range
  }
  if (
    filter?.current_rating_range &&
    typeof filter.current_rating_range === 'string' &&
    filter.current_rating_range.includes(',')
  ) {
    filter.current_rating__gte = filter?.current_rating_range.split(',')[0]
    filter.current_rating__lte = filter?.current_rating_range.split(',')[1]
    delete filter.current_rating_range
  }
  if (filter?.inverter_type) {
    if (filter.inverter_type === 'hybrid') {
      filter.hybrid = 'Y'
    } else if (filter.inverter_type === 'microinverter') {
      filter.microinverter = 'Y'
    } else {
      filter.hybrid = 'N'
      filter.microinverter = 'N'
    }
    delete filter.inverter_type
  }
  return filter
}

// The options are really set in ComponentsFilter as it has access to the redux store.
const options: FilterOption[] = [
  { title: 'Segen', value: 'segen' },
  { title: 'Solar Outlet', value: 'outlet' },
  { title: 'City Plumbing', value: 'city_plumbing' },
]

const commonFilters: FilterType[] = [
  {
    key: 'require_distributor',
    label: 'Available from',
    options: options,
    isHidden() {
      const reduxState = window.reduxStore?.getState()
      if (reduxState == null) {
        return true
      }
      const getFeatureValue = getFeatureFlagSelector(window.reduxStore?.getState())
      const enableNewHardwareOrdering = getFeatureValue('hardware_ordering', 'on')
      if (!enableNewHardwareOrdering) return true

      const segenEnabled = window.reduxStore?.getState()?.orgs?.org?.enable_segen
      const outletEnabled = window.reduxStore?.getState()?.orgs?.org?.enable_outlet
      const cityPlumbingEnabled = window.reduxStore?.getState()?.orgs?.org?.enable_city_plumbing
      const hdmEnabled = window.reduxStore?.getState()?.orgs?.org?.enable_hdm
      return !(segenEnabled || outletEnabled || cityPlumbingEnabled || hdmEnabled)
    },
    getDefaultValue({ specsData }) {
      const selectedSupplier = window.reduxStore?.getState()?.orderComponents?.selectedHardwareSupplier
      const config = getSupplierConfig(selectedSupplier)
      return config?.filterKey || null
    },
  },

  {
    key: 'owner_org',
    label: 'Owner Org',
    options: [],
    isHidden(props: HideFilterProps) {
      if (props.sharedWith && props.sharedWith.length > 0) {
        return false
      } else {
        return true
      }
    },
  },
  StockAvailabilityFilter,
]

const moduleFilters: FilterType[] = [
  {
    key: 'manufacturer_or_exhibitor_org_id',
    label: 'Manufacturer',
    useFeatureConfigAsOptions: { featureConfig: 'manufacturer_filter', key: 'modules' },
    options: [],
  },
  moduleRatingFilter,
  productWarrantyFilter,
  ...commonFilters,
]

const inverterFilters: FilterType[] = [
  {
    key: 'manufacturer_or_exhibitor_org_id',
    label: 'Manufacturer',
    useFeatureConfigAsOptions: { featureConfig: 'manufacturer_filter', key: 'inverters' },
    options: [],
  },
  inverterRatingFilter,
  inverterTypeFilter,
  {
    key: 'phase_type',
    label: 'Phase',
    options: [
      { title: 'Single phase', value: 'single_phase' },
      { title: 'Three phase', value: 'three_phase' },
    ],
  },
  mpptQuantityFilter,
  ...commonFilters,
]

const batteryFilters: FilterType[] = [
  {
    key: 'manufacturer_or_exhibitor_org_id',
    label: 'Manufacturer',
    useFeatureConfigAsOptions: { featureConfig: 'manufacturer_filter', key: 'batteries' },
    options: [],
  },
  batteryRatingFilter,
  {
    key: 'compatibility',
    label: 'Compatible with system',
    options: [{ title: 'Yes', value: 'true' }],
    isHidden() {
      return getCompatibleCodesForSystem('battery').length === 0
    },
  },
  ...commonFilters,
]

const otherFilters: FilterType[] = [
  {
    key: 'manufacturer_or_exhibitor_org_id',
    label: 'Manufacturer',
    useFeatureConfigAsOptions: { featureConfig: 'manufacturer_filter', key: 'others' },
    options: [],
  },
  otherComponentTypeFilter,
  {
    key: 'current_type',
    label: 'Current Type',
    options: [
      { title: 'AC', value: 'ac' },
      { title: 'DC', value: 'dc' },
    ],
    isHidden: (props: HideFilterProps) => {
      // reset and hide filter if not cable or isolator
      if (
        props.filterSettings?.other_component_type === 'cable' ||
        props.filterSettings?.other_component_type === 'isolator'
      ) {
        return false
      } else {
        if (props.filterSettings['current_type'] && props.setFilters) {
          delete props.filterSettings['current_type']
          props.setFilters(props.filterSettings, true)
        }
        return true
      }
    },
  },
  {
    key: 'phase_type',
    label: 'Phase',
    options: [
      { title: 'Single phase', value: 'single_phase' },
      { title: 'Three phase', value: 'three_phase' },
    ],
    isHidden: (props: HideFilterProps) => {
      // reset and hide filter if not AC isolator or meter
      if (
        (props.filterSettings?.other_component_type === 'isolator' && props.filterSettings?.current_type === 'ac') ||
        props.filterSettings?.other_component_type === 'meter'
      ) {
        return false
      } else {
        if (props.filterSettings['phase_type'] && props.setFilters) {
          delete props.filterSettings['phase_type']
          props.setFilters(props.filterSettings, true)
        }
        return true
      }
    },
  },
  {
    key: 'current_rating_range',
    label: 'Current Rating',
    options: [
      { title: String.fromCharCode(8804) + ' 20A', value: '0,20' },
      { title: '20A - 30A', value: '20.001,30' },
      { title: '30A - 40A', value: '30.001,40' },
      { title: '40A - 80A', value: '40.001,80' },
      { title: String.fromCharCode(62) + ' 80A', value: '80.001,1000' },
    ],
    isHidden: (props: HideFilterProps) => {
      // reset and hide filter if not AC Isolator
      if (props.filterSettings?.other_component_type === 'isolator' && props.filterSettings?.current_type === 'ac') {
        return false
      } else {
        if (props.filterSettings['current_rating_range'] && props.setFilters) {
          delete props.filterSettings['current_rating_range']
          props.setFilters(props.filterSettings, true)
        }
        return true
      }
    },
  },
  {
    key: 'cable_length',
    label: 'Cable Length',
    // Cable lengths should be ordered from lowest to highest
    options: [
      { title: '25m', value: '25' },
      { title: '50m', value: '50' },
      { title: '100m', value: '100' },
      { title: '250m', value: '250' },
      { title: '500m', value: '500' },
      { title: '1000m', value: '1000' },
    ],
    isHidden: (props: HideFilterProps) => {
      // reset and hide filter if not cable
      if (props.filterSettings?.other_component_type === 'cable') {
        return false
      } else {
        if (props.filterSettings['cable_length'] && props.setFilters) {
          delete props.filterSettings['cable_length']
          props.setFilters(props.filterSettings, true)
        }
        return true
      }
    },
  },
  {
    key: 'cable_thickness',
    label: 'Cable Thickness',
    // Cable thicknesses should be ordered from lowest to highest
    options: [
      { title: '4mm', value: '4' },
      { title: '6mm', value: '6' },
      { title: '10mm', value: '10' },
    ],
    isHidden: (props: HideFilterProps) => {
      // reset and hide filter if not cable
      if (props.filterSettings?.other_component_type === 'cable') {
        return false
      } else {
        if (props.filterSettings['cable_thickness'] && props.setFilters) {
          delete props.filterSettings['cable_thickness']
          props.setFilters(props.filterSettings, true)
        }
        return true
      }
    },
  },
  {
    // This filter is used to find compatible DC isolators, where options is populated dynamically depending on the system requirements
    key: 'voltage_to_current_rating',
    label: 'Voltage and Current',
    options: [
      // An option would have this format;
      // {title: '> 500V, > 32A', value: '500,32'}
    ],
    isHidden: (props: HideFilterProps) => {
      // reset and hide filter if not DC Isolator
      if (props.filterSettings?.other_component_type === 'isolator' && props.filterSettings?.current_type === 'dc') {
        return false
      } else {
        if (props.filterSettings['voltage_to_current_rating']) {
          delete props.filterSettings['voltage_to_current_rating']
          props.setFilters(props.filterSettings, true)
        }
        return true
      }
    },
  },
  ...commonFilters,
]

function getComponentTypeFiltersSchema(componentType: string): FilterType[] {
  const componentTypeFilters =
    componentType === 'module'
      ? moduleFilters
      : componentType === 'inverter'
      ? inverterFilters
      : componentType === 'battery'
      ? batteryFilters
      : otherFilters
  return componentTypeFilters
}

export function getFiltersSchema(componentType?: string): FilterType[] {
  if (componentType) {
    return cloneDeep(getComponentTypeFiltersSchema(componentType))
  } else return cloneDeep(commonFilters)
}

// basic default database filter settings when searching for hardware, not shown to the user as a filter in the UI
export function getDefaultFilterSettings(countryIso2: string | undefined): SelectComponentFilters {
  return {
    page: 1,
    country_iso2: countryIso2,
    show_exhibited_first: 'true',
    my_list: 'true',
  }
}

export const getAppliedFilters = (filterSettings: SelectComponentFilters): SelectComponentFilters => {
  // Remove filters that are negligible eg. 'all'
  var appliedFilters = Object.fromEntries(
    Object.entries(filterSettings).filter(([k, v]) => v !== 'all' && v !== '')
  ) as SelectComponentFilters
  // Handle manufacturer filter which can filter by manufacturer or exhibitor
  if (appliedFilters.manufacturer_or_exhibitor_org_id) {
    if (appliedFilters.manufacturer_or_exhibitor_org_id.includes('exhibitor_org_id_')) {
      appliedFilters.exhibitor_org_id = Number(
        appliedFilters.manufacturer_or_exhibitor_org_id.split('exhibitor_org_id_')[1]
      )
      delete appliedFilters.manufacturer
    } else if (appliedFilters.manufacturer_or_exhibitor_org_id.includes('manufacturer_')) {
      appliedFilters.manufacturer = Number(appliedFilters.manufacturer_or_exhibitor_org_id.split('manufacturer_')[1])
      delete appliedFilters.exhibitor_org_id
    }
    delete appliedFilters.manufacturer_or_exhibitor_org_id
  }
  return appliedFilters
}

export const userHasAppliedFilters = (
  filtersSchema: FilterType[],
  filterSettings: SelectComponentFilters,
  includeHidden: boolean,
  countryIso2: string | undefined
): boolean => {
  let filters = getAppliedFilters(filterSettings)
  delete filters.owner_org //Remove owner_org filter
  delete filters.q // Only include dropdown filters, remove the search bar
  if (!includeHidden) {
    Object.keys(filters).forEach((filterKey) => {
      const matchingFilters = filtersSchema.filter((filterSchema) => filterSchema.key === filterKey)
      if (matchingFilters.length && matchingFilters[0].isHidden) {
        // @ts-ignore
        if (matchingFilters[0]?.isHidden({ filterSettings })) {
          delete filters[filterKey]
        }
      }
    })
  }
  const filterQty = Object.keys(filters).length
  let defaultFilters = getDefaultFilterSettings(countryIso2)
  const defaultFilterQty = Object.keys(defaultFilters).length
  return filterQty > defaultFilterQty
}

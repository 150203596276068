import { Grow, makeStyles, useMediaQuery } from '@material-ui/core'
import AccountBalanceIcon from '@material-ui/icons/AccountBalanceOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { CurrencySymbolContext } from 'contexts/CurrencySymbolContext'
import { authSelectors } from 'ducks/auth'
import { viewAsCustomerSelectors } from 'ducks/viewAsCustomer'
import { usePermissions } from 'ra-core'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ALL_FINCOS } from 'resources/integrations/constants'
import { PaymentOptionDataType } from 'types/paymentOptions'
import { ProposalDataType } from 'types/proposals'
import { SystemDataType } from 'types/systems'
import ApplyNowPromptDialog from './ApplyNowPromptDialog'

const UN_SUPPORTED_FINCOS = ['phoenix']

type PropTypes = {
  system: SystemDataType
  quotationConfiguration: Object
  layout: Object
  proposalData: ProposalDataType
}

export type QuotationTablePropsType = {
  quotationConfiguration: Object
  layout: Object
  proposalData: ProposalDataType
}

const useStyle = makeStyles((theme) => ({
  wrapper: {
    position: 'fixed',
    left: 'calc(50% - 150px)',
    top: 20,
    width: '300px',
    padding: '15px',
    borderRadius: '5px',
    border: '1px solid rgb(52, 160, 47)',
    background: 'rgb(52, 160, 47)',
    zIndex: 5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '10px 5px 5px rgba(0, 0, 0, 0.2)',
    color: '#fff',
    cursor: 'pointer',
  },
  mobileWrapper: {
    position: 'fixed',
    left: '2%',
    right: '2%',
    bottom: 120,
    padding: '15px',
    borderRadius: '5px',
    border: '1px solid rgb(52, 160, 47)',
    background: 'rgb(52, 160, 47)',
    zIndex: 103,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '10px 5px 5px rgba(0, 0, 0, 0.2)',
    color: '#fff',
    cursor: 'pointer',
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingRight: '20px',
    position: 'absolute',
    left: '10px',
    top: '10px',
  },
  textColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  header: {
    margin: '0px',
    textAlign: 'center',
  },
  paragraph: {
    margin: '0px',
    textAlign: 'center',
  },
  dismissWrapper: {
    position: 'absolute',
    top: 7,
    right: 7,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontWeight: 900,
  },
}))

const ApplyNowPrompt: React.FC<PropTypes> = (props) => {
  const [shouldRender, setShouldRender] = useState<boolean>(false)
  const [hasDownPayment, setHasDownPayment] = useState<boolean>(false)
  const [wasDismissed, setWasDismissed] = useState<boolean>(false)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [supportedPaymentOptions, setSupportedPaymentOptions] = useState<PaymentOptionDataType[]>([])

  const { permissions } = usePermissions()
  const isPro = permissions?.includes('is_pro')
  const isViewAsCustomer: boolean = useSelector(viewAsCustomerSelectors.getViewAsCustomer)
  const isProNormalView = isPro && !isViewAsCustomer
  const hasAcceptedTerms = useSelector(authSelectors.getHasAcceptedTerms)
  const disabled = props.proposalData?.org?.disable_apply_now_prompt
  const currencySymbol = useContext(CurrencySymbolContext)

  const isMobile = useMediaQuery('(max-width:800px)')
  const classes = useStyle()

  const onDismiss = () => {
    setWasDismissed(true)
    logAmplitudeEvent('apply_now_prompt_dismissed', { project_id: props.proposalData?.selectedProject?.id })
  }

  const onClick = () => {
    logAmplitudeEvent('apply_now_prompt_clicked', { project_id: props.proposalData?.selectedProject?.id })
    setShowDialog(true)
  }

  const quotationTableProps: QuotationTablePropsType = {
    quotationConfiguration: props.quotationConfiguration,
    layout: props.layout,
    proposalData: props.proposalData,
  }

  useEffect(() => {
    if (props.system?.payment_options) {
      let supportedPmts: PaymentOptionDataType[] = []
      let foundDownPayment = false
      props.system.payment_options.forEach((pmt) => {
        if (pmt.integration && !UN_SUPPORTED_FINCOS.includes(pmt.integration) && ALL_FINCOS.includes(pmt.integration)) {
          if (pmt.integration !== 'plenti' || pmt.loan_type !== 'buy_now_pay_later') {
            supportedPmts.push(pmt)
            if (pmt.down_payment > 0) foundDownPayment = true
          }
        }
      })
      if (supportedPmts?.length > 0) {
        setTimeout(() => {
          setShouldRender(true)
          setSupportedPaymentOptions(supportedPmts)
          setHasDownPayment(foundDownPayment)
        }, 5 * 1000)
      } else setShouldRender(false)
    }
  }, [props.system?.uuid])

  useEffect(() => {
    if (isMobile) {
      if (shouldRender && props.proposalData?.selectedPaymentOption.integration) {
        setShouldRender(false)
      } else if (
        !shouldRender &&
        supportedPaymentOptions?.length &&
        !props.proposalData?.selectedPaymentOption.integration
      ) {
        setShouldRender(true)
      }
    }
  }, [isMobile, shouldRender, supportedPaymentOptions, props.proposalData?.selectedPaymentOption])

  if (
    !shouldRender ||
    wasDismissed ||
    isProNormalView ||
    !hasAcceptedTerms ||
    !!props.proposalData?.selectedProject?.payment_option_sold ||
    disabled
  )
    return null
  return (
    <Grow in={true}>
      {showDialog ? (
        <ApplyNowPromptDialog
          open={showDialog}
          onClose={() => setShowDialog(false)}
          system={props.system}
          paymentOptions={supportedPaymentOptions}
          quotationTableProps={quotationTableProps}
          setWasDismissed={setWasDismissed}
        />
      ) : (
        <div className={isMobile ? classes.mobileWrapper : classes.wrapper} onClick={onClick}>
          <div className={classes.iconWrapper}>
            <AccountBalanceIcon fontSize="large" />
          </div>
          <div className={classes.textColumn}>
            <h2 className={classes.header}>
              {hasDownPayment ? 'Interested in Financing?' : `Go Solar for ${currencySymbol}0 Down!`}
            </h2>
            <p className={classes.paragraph}>Click here to learn more</p>
          </div>
          <div className={classes.dismissWrapper} onClick={onDismiss}>
            X
          </div>
        </div>
      )}
    </Grow>
  )
}
export default ApplyNowPrompt

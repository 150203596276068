import { orgSelectors } from 'ducks/orgs'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import CityPlumbingCartActions from './cityPlumbing/CityPlumbingCartActions'
import HdmCartActions from './hdm/HdmCartActions'
import SegenCartActions from './segen/SegenCartActions'
import OutletCartActions from './solarOutlet/OutletCartActions'
import VtacCartActions from './vtac/VtacCartActions'

const CartActions = () => {
  const supplier = useSelector(orgSelectors.getSelectedHardwareSupplier)

  if (supplier === HardwareSupplierEnum.Segen) {
    return <SegenCartActions />
  } else if (supplier === HardwareSupplierEnum.SolarOutlet) {
    return <OutletCartActions />
  } else if (supplier === HardwareSupplierEnum.CityPlumbing) {
    return <CityPlumbingCartActions />
  } else if (supplier === HardwareSupplierEnum.Hdm) {
    return <HdmCartActions />
  } else if (supplier === HardwareSupplierEnum.VtacUk || supplier === HardwareSupplierEnum.VtacPoland) {
    return <VtacCartActions />
  }
  return null
}

export default memo(CartActions)

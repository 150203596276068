import { Button, Datagrid } from 'react-admin'
import { previewInfo } from '../../common/utils'

import { DotsVerticalIcon, styled } from 'opensolar-ui'
import { useMemo, useState } from 'react'
import { CrudFileMenu } from '../../common/menus/CrudFileMenu'

const StyledDatagrid = styled(Datagrid)(({ theme }) => ({
  '& thead': {
    display: 'none',
  },
  '& .MuiPaper-elevation1': {
    border: 'none',
  },
  '& .MuiTableBody-root': {
    display: 'grid',
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: 'white',
    padding: 16,
    gap: 16,
    gridTemplateColumns: '1fr 1fr 1fr 1fr ',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr ',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  '& .MuiTableCell-root': {
    border: 'none',
  },
  '& tr': {
    display: 'flex',
    overflow: 'hidden',
  },
  '& td': {
    backgroundColor: theme.palette.grey[100],
    width: '100%',
    padding: '8px !important',
    borderRadius: 4,
  },
  '& .MuiTableCell-paddingCheckbox': {
    position: 'absolute',
  },
}))

const CardTop = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  borderRadius: 4,
}))

const PreviewArea = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  height: 120,
  backgroundColor: 'white',
  '& object': {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  '& img': {
    margin: 'auto',
    height: 50,
  },
}))

const CardBottom = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 16,
  height: 20,
  paddingTop: 8,
  '& > button': {
    minWidth: 5,
    width: 5,
    padding: 0,
  },
}))

const FileTitle = styled('p')(({ theme }) => ({
  height: 20,
  alignItems: 'center',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}))

const FileCard = (props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { hasPreview, fileType } = previewInfo(props.record)
  const showPreview = props.isMobileDevice && fileType === 'pdf' ? false : hasPreview
  return (
    <div key={props.record.id}>
      <div>
        <CardTop>
          <PreviewArea>
            {showPreview ? (
              <object data={props.record?.file_contents} />
            ) : (
              <>
                <img src={`${window.PUBLIC_URL}/images/doc_icons/${fileType}.svg`} alt={`${fileType} icon`} />
              </>
            )}
          </PreviewArea>
        </CardTop>
      </div>
      <CardBottom>
        <FileTitle>{props.record?.title}</FileTitle>
        <Button
          onClick={(e) => {
            setAnchorEl(e.currentTarget)
          }}
          color="secondary"
          variant="text"
          startIcon={<DotsVerticalIcon />}
        />
        <CrudFileMenu record={props.record} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      </CardBottom>
    </div>
  )
}

export const GridViewDatagrid = (props) => {
  const isMobileDevice = useMemo(() => window.Utils?.isTouchDevice(), [])

  return (
    <StyledDatagrid {...props}>
      <FileCard isMobileDevice={isMobileDevice} />
    </StyledDatagrid>
  )
}

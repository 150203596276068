import { MutableRefObject, useMemo } from 'react'
import { CustomFormErrorObject } from '../CustomForm'

// Acts as a bridge between JSON Forms validations and react-admins
export const useJsonSchemaFormValidation = (
  errorsRef: MutableRefObject<CustomFormErrorObject[]>,
  translate: (str: string, params?: object) => string,
  enabled = true
) => {
  return useMemo(() => {
    // It's important for react-final-form to return the same array everytime
    // Otherwise it will re-render recursively
    const errors: string[] = []

    const validator = (values: any) => {
      if (!enabled || !errorsRef.current.length) {
        if (window.debugCustomForms) console.debug('JSON Schema validation passed', errorsRef.current)
        return null
      }

      errors.length = 0

      for (const error of errorsRef.current) {
        if (error.keyword === 'if') {
          // These are normally validation of parent objects, when a child validation isn't matching
          // no need to show these to the user twice
          continue
        }

        let msg = error.message

        let fieldName = error.params?.missingProperty || error.propertyName || error.dataPath

        // Convert to standard OS language
        if (msg === 'is a required property') msg = '%{fieldName} is a required field'
        else msg = `${fieldName} ${msg}` // Not using substitution here as terms likely won't exist, so field won't be resolved.

        const resolved = translate(msg, { fieldName })

        if (!errors.includes(resolved)) errors.push(resolved)
      }
      if (window.debugCustomForms) console.debug('JSON Schema validation failed', errors, errorsRef.current)
      return errors
    }
    return () => {
      return validator
    }
  }, [])
}

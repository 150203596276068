import { permissionsSelectors } from 'ducks/permissions'
import Alert from 'elements/Alert'
import { ControlBreadCrumbs } from 'elements/BreadCrumbs'
import CustomField from 'elements/field/CustomField'
import DependentInput from 'elements/input/DependentInput'
import { JsonFormsInput } from 'elements/jsonForms/JsonFormsInput'
import { HelpLink } from 'elements/link/HelpLink'
import { useEffect } from 'react'
import {
  BooleanInput,
  Edit,
  FormDataConsumer,
  NotFound,
  NumberInput,
  showNotification as showNotificationAction,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin'
import { useForm } from 'react-final-form'
import { connect, useSelector } from 'react-redux'
import { loadStripeIfNotFound } from 'util/misc'
import { getOrgCountryCodeFromState, getOrgFromState } from 'util/org'
import useTranslateParse from 'util/useTranslateParse'
import { authReload as authReloadAction } from '../../actions/authActions'
import EditorComponent from '../../elements/wysiwyg/EditorComponentWrapper'
import { formatSubmitValues, getDefaultSettings } from '../orgs'
import EmailAcceptanceDocsToggle from './EmailAcceptanceDocsToggle'

const styles = {
  inputStyle: {
    width: 256,
  },
  toggleStyle: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    width: 256,
  },
  heading: {
    marginTop: 40,
  },
  quoteAcceptanceButtonText: {
    display: 'flex',
    flexDirection: 'column',
  },
}

const DraftEditorWithContent = (props) => {
  return (
    <EditorComponent
      label={props.label}
      fieldName={props.source}
      allowUploadImage={props.allowUploadImage}
      charCounterMax={props.charCounterMax}
      mentionContext={props.mentionContext ? props.mentionContext : 'org_and_project'}
      {...props}
    />
  )
}

const CheckoutToggle = (props) => {
  const form = useForm()
  const translate = useTranslate()
  const handleToggle = (e, value) => {
    if (value === false) {
      form.mutators.updateField('accept_credit_card_stripe', false)
      form.mutators.updateField('accept_offline', false)
    }
    form.mutators.updateField('enable_checkout', value)
  }
  return (
    <div>
      <CustomField
        style={{ marginTop: 20, marginBottom: 20 }}
        component={BooleanInput}
        formControlLabelStyle={styles.toggleStyle}
        source="enable_checkout"
        options={{ onChange: handleToggle }}
        name="enable_checkout"
        {...props}
      />
      <DependentInput dependsOn="enable_checkout" value={false}>
        <Alert severity="info">
          {translate(
            'If the customer proposal has a supported integrated finance payment option, customers will still be able to apply for finance through the proposal'
          )}
        </Alert>
      </DependentInput>
    </div>
  )
}
const AcceptCreditCardToggle = ({ creditCardSupportInThisCountry, ...props }) => {
  const form = useForm()
  const handleToggle = (e, value) => {
    if (value === false) {
      form.mutators.updateField('credit_card_stripe_surcharge_percentage', 0)
      form.mutators.updateField('credit_card_stripe_surcharge_fixed', 0)
    }
    form.mutators.updateField('accept_credit_card_stripe', value)
  }
  return (
    <CustomField
      component={BooleanInput}
      source="accept_credit_card_stripe"
      name="accept_credit_card_stripe"
      formControlLabelStyle={styles.toggleStyle}
      options={{ onChange: handleToggle }}
      defaultValue={false}
      disabled={creditCardSupportInThisCountry !== true}
      {...props}
    />
  )
}

const AcceptOfflineToggle = ({ creditCardSupportInThisCountry, ...props }) => {
  const form = useForm()
  const handleToggle = (e, value) => {
    if (value === false) {
      form.mutators.updateField('offline_surcharge_percentage', 0)
      form.mutators.updateField('offline_surcharge_fixed', 0)
    }
    form.mutators.updateField('accept_offline', value)
  }

  return (
    <CustomField
      component={BooleanInput}
      formControlLabelStyle={styles.toggleStyle}
      options={{ onChange: handleToggle }}
      source="accept_offline"
      name="accept_offline"
      resource="orgs"
      {...props}
    />
  )
}

const _EditCustomerCheckout = (props) => {
  const translateParse = useTranslateParse()
  const translate = useTranslate()
  const { allowView, allowEdit } = useSelector(permissionsSelectors.getPermissionByKey('checkout_exp'))
  const url = 'https://www.opensolar.com/terms-conditions'

  // load stripe if we haven't already
  useEffect(() => {
    loadStripeIfNotFound()
  }, [])

  if (!allowView) {
    return <NotFound />
  }

  return (
    <Edit
      title={'Customer Checkout Experience'}
      subtitle={
        <div>
          {translate(
            'Enable your customer to accept an online proposal, review and sign their contract online and/or make a down payment via credit card.'
          )}
          <HelpLink href="https://support.opensolar.com/hc/en-us/articles/4407231229593" />
        </div>
      }
      breadCrumbs={<ControlBreadCrumbs currentPage="Checkout Experience" />}
      {...props}
      hasDelete={false}
    >
      <SimpleForm
        hideCancelButton={true}
        redirect="/control"
        defaultValue={getDefaultSettings()}
        disabled={!allowEdit}
        formatSubmitValues={(values) => formatSubmitValues(values)}
      >
        <CheckoutToggle {...props} />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.enable_checkout && (
              <div>
                <h3 style={styles.heading}>{translate('Quote Acceptance Title')}</h3>
                <p>
                  {translate(
                    'This title will appear just below your contract, after the customer has accepted your proposal.'
                  )}
                </p>
                <CustomField
                  component={TextInput}
                  source="quote_acceptance_heading"
                  name="quote_acceptance_heading"
                  //label="Quote Acceptance Title"
                  label={null}
                  defaultValue={''}
                  style={styles.inputStyle}
                  {...rest}
                />

                {/** Review Proposal */}
                <h3 style={styles.heading}>{translate('Review Proposal Buttons')}</h3>
                <p>
                  {translate(
                    'These are the call to action buttons that appear for your customers to review the contract and quotation before accepting the proposal.'
                  )}
                </p>
                <section style={styles.quoteAcceptanceButtonText}>
                  <CustomField
                    component={TextInput}
                    source="review_proposal_text"
                    name="review_proposal_text"
                    label='Review Proposal Label'
                    defaultValue=""
                    fullWidth
                    {...rest}
                  />
                  <CustomField
                    component={TextInput}
                    source="review_pay_deposit_text"
                    name="review_pay_deposit_text"
                    label='Review Proposal & Deposit Label'
                    defaultValue=""
                    fullWidth
                    {...rest}
                  />
                </section>

                {/** Accept Proposal*/}
                <h3 style={styles.heading}>{translate('Accept Proposal Buttons')}</h3>
                <p>
                  {translate(
                    'These are the call to action buttons that appear for your customers while reviewing the contract and quotation, to accept the proposal and pay deposit.'
                  )}
                </p>
                <section style={styles.quoteAcceptanceButtonText}>
                  <CustomField
                    component={TextInput}
                    source="accept_proposal_text"
                    name="accept_proposal_text"
                    label='Accept Proposal Label'
                    defaultValue=""
                    fullWidth
                    {...rest}
                  />
                  <CustomField
                    component={TextInput}
                    source="accept_pay_deposit_text"
                    name="accept_pay_deposit_text"
                    label='Accept Proposal & Pay Deposit Label'
                    defaultValue=""
                    fullWidth
                    {...rest}
                  />
                </section>

                <h3 style={styles.heading}>{translate('Quote Acceptance Content')}</h3>
                <p>
                  {translateParse(
                    'This content will populate next to a box that customers must select in order to accept your proposal. Content could say, for example: I have reviewed and accept the above agreement <a>User Terms and Conditions<a>.',
                    {
                      a: (label) => {
                        return (
                          <a href={url} target="_blank" rel="noreferrer">
                            {label}
                          </a>
                        )
                      },
                    }
                  )}
                </p>
                <JsonFormsInput
                  label={null}
                  source="quote_acceptance_content"
                  schema={{
                    type: 'array',
                    items: {
                      type: 'string',
                    },
                  }}
                  uischema={{
                    type: 'VerticalLayout',
                    elements: [
                      {
                        type: 'Control',
                        scope: '#/',
                        label: '',
                      },
                    ],
                  }}
                  fullWidth={true}
                />
                <EmailAcceptanceDocsToggle DraftEditorWithContent={DraftEditorWithContent} />
                <h1 style={{ marginTop: 75 }}>{translate('Customer Payment Transaction')}</h1>

                <h3 style={styles.heading}>{translate('Accept Credit Card')}</h3>
                <p>
                  {translate(
                    "If you've integrated with the credit card processing company Stripe, you're able to accept a credit card payment on acceptance of a proposal."
                  )}
                </p>
                <AcceptCreditCardToggle {...props} />

                {props.creditCardSupportInThisCountry !== true && (
                  <p className="small">{translate('Credit card payments not currently supported in your country')}</p>
                )}
                <FormDataConsumer>
                  {({ formData, ...rest }) =>
                    formData.accept_credit_card_stripe && (
                      <div
                        style={{
                          backgroundColor: '#f9f9f9',
                          padding: '0px 5px 5px 5px',
                        }}
                      >
                        <CustomField
                          component={TextInput}
                          source="credit_card_stripe_secret_key"
                          name="credit_card_stripe_secret_key"
                          resource="orgs"
                          defaultValue={''}
                          style={styles.inputStyle}
                          {...rest}
                        />
                        <CustomField
                          component={TextInput}
                          source="credit_card_stripe_publishable_key"
                          name="credit_card_stripe_publishable_key"
                          resource="orgs"
                          defaultValue={''}
                          style={styles.inputStyle}
                          {...rest}
                        />
                        <NumberInput
                          source="credit_card_stripe_surcharge_percentage"
                          name="credit_card_stripe_surcharge_percentage"
                          resource="orgs"
                          defaultValue={0}
                          endAdornment="%"
                          style={styles.inputStyle}
                          {...rest}
                        />
                        <NumberInput
                          source="credit_card_stripe_surcharge_fixed"
                          name="credit_card_stripe_surcharge_fixed"
                          resource="orgs"
                          defaultValue={0}
                          startAdornment="$"
                          style={styles.inputStyle}
                          {...rest}
                        />
                      </div>
                    )
                  }
                </FormDataConsumer>
                <br />

                <h3 style={styles.heading}>{translate('resources.orgs.fields.accept_offline')}</h3>
                <p>
                  {translate('Specify any details, terms or surcharges associated with accepting offline payments.')}
                </p>
                <AcceptOfflineToggle {...props} />

                <FormDataConsumer>
                  {({ formData, ...rest }) =>
                    formData.accept_offline && (
                      <div
                        style={{
                          backgroundColor: '#f9f9f9',
                          padding: '0px 5px 5px 5px',
                        }}
                      >
                        <NumberInput
                          source="offline_surcharge_percentage"
                          name="offline_surcharge_percentage"
                          //label="offline surcharge"
                          type="number"
                          defaultValue={0}
                          endAdornment="%"
                          style={styles.inputStyle}
                          {...props}
                        />
                        <NumberInput
                          source="offline_surcharge_fixed"
                          name="offline_surcharge_fixed"
                          // label="Offline Surcharge Fixed"
                          defaultValue={0}
                          startAdornment="$"
                          style={styles.inputStyle}
                          {...props}
                        />
                        <CustomField
                          component={TextInput}
                          source="offline_title"
                          name="offline_title"
                          defaultValue={''}
                          style={styles.inputStyle}
                          {...props}
                        />
                        <CustomField
                          component={DraftEditorWithContent}
                          allowUploadImage={false}
                          mentionContext="empty"
                          name="offline_content"
                          source="offline_content"
                          //label="Offline Payment Details"
                          charCounterMax={2200}
                          {...props}
                        />
                      </div>
                    )
                  }
                </FormDataConsumer>
                <br />
                <CustomField
                  component={BooleanInput}
                  formControlLabelStyle={styles.toggleStyle}
                  source="hide_loan_application_until_prequal"
                  name="hide_loan_application_until_prequal"
                  label={'Pre-qualify before loan application'}
                  {...props}
                />
                <p className="small">
                  {translate(
                    'Require customer to get pre-qualified prior to submitting loan application (when both pre-qualification and application actions are available).'
                  )}
                </p>
                <br />
                <CustomField
                  component={BooleanInput}
                  formControlLabelStyle={styles.toggleStyle}
                  source="org_configs.disable_apply_now_prompt"
                  name="org_configs.disable_apply_now_prompt"
                  label={'Disable Apply Now Prompt'}
                  {...props}
                />
                <p className="small">
                  {translate(
                    'The Apply now prompt appears in the customer view of the online proposal when an integrated finance option is included in the system design.'
                  )}
                </p>
              </div>
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  )
}

const mapStateToProps = (state) => {
  var country_iso2 = getOrgCountryCodeFromState(state)
  var creditCardSupportInThisCountry =
    [
      'AU',
      'US',
      'NZ',
      'GB',
      'AT',
      'BE',
      'BG',
      'HR',
      'CY',
      'CZ',
      'DK',
      'EE',
      'FI',
      'FR',
      'DE',
      'GR',
      'HU',
      'IE',
      'IT',
      'LV',
      'LT',
      'LU',
      'MT',
      'NL',
      'PL',
      'PT',
      'RO',
      'SK',
      'SI',
      'ES',
      'SE',
    ].indexOf(country_iso2) !== -1
  var org = getOrgFromState(state)
  var loginAuthority = org && org.external_account_id ? 'nearmap' : null
  var defaultMeasurementUnits = country_iso2 === 'US' ? 'Imperial' : 'Metric'
  return { country_iso2, creditCardSupportInThisCountry, loginAuthority, org, defaultMeasurementUnits }
}

const mapDispatchToProps = { showNotification: showNotificationAction, authReload: authReloadAction }

export const EditCustomerCheckout = connect(mapStateToProps, mapDispatchToProps)(_EditCustomerCheckout)

// @ts-nocheck
import { useMediaQuery } from '@material-ui/core'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import LoadingDots from 'layout/widgets/LoadingDots'
import React, { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import restClient from 'restClient'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => {
  return {
    wrapper: ({ height }) => ({
      backgroundColor: '#ececec',
      boxShadow: '0px 1px 3px rgba(0,0,0,0.5)',
      height: `${height}px`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      position: 'relative',
      padding: '30px 10px 70px 10px',
    }),
    headerWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: '10px',
    },
    left: { flex: 1, display: 'flex', justifyContent: 'flex-start' },
    center: { flex: 1, display: 'flex', justifyContent: 'center' },
    right: { flex: 1, display: 'flex', justifyContent: 'flex-end' },
    expandWrapper: { height: '20px', paddingBottom: '8px' },
    expandLink: { cursor: 'pointer', color: 'blue', textDecoration: 'underline' },
    canvas: ({ isMobile }) => ({ width: isMobile ? '100%' : undefined }),
  }
})

type InitialResponseType = {
  data: {
    success: boolean
    worker_url: string
  }
}

type DocumentResponseType = {
  data: {
    content: string
    created_date: string
    description: string
    docusign_designated_countersigner_email: null
    docusign_designated_countersigner_family_name: null
    docusign_designated_countersigner_first_name: null
    docusign_designated_countersigner_phone: null
    docusign_require_sales_rep_countersignature: false
    file_contents_url: string
    id: number
    integration: 0 | 1
    is_default: false
    modified_date: string
    org: string
    show_header: false
    title: string
    type: number
    url: string
  }
}

type PropTypes = {
  onSuccess: () => void
  toggleExpand: () => void
  orgId: number
  projectId: number
  systemUuid: string
  paymentOptionId: number
}

const PDFContractDisplay: React.FunctionComponent<PropTypes> = (props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [docLoading, setDocLoading] = useState<boolean>(false)
  const [libraryLoading, setLibraryLoading] = useState<boolean>(false)
  const [contractURL, setContractURL] = useState<string | undefined>(undefined)
  const [pdfObj, setPdfObj] = useState<any>(undefined)
  const [loadingError, setLoadingError] = useState<string | undefined>(undefined)
  const [pageNum, setPageNum] = useState<number>(0)
  const [pageCount, setPageCount] = useState<number | undefined>(undefined)
  const [height, setHeight] = useState<number>(1000)
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const translate = useTranslate()

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const classes = useStyles({ height, isMobile })

  const doExpand = () => {
    props.toggleExpand()
    setIsExpanded(!isExpanded)
  }

  useEffect(() => {
    fetchContractURL()
  }, [])

  // load the PDF rendering library if we haven't yet
  useEffect(() => {
    if (!window['pdfjs-dist/build/pdf']) {
      setLibraryLoading(true)
      const minUrl = 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.7.570/build/pdf'
      // load the pdf.js library here. No need to load this package globally just wait until we need it
      var script = document.createElement('script')
      script.addEventListener('load', (e) => {
        setLibraryLoading(false)
        pdfjsLib.GlobalWorkerOptions.workerSrc = `${minUrl}.worker.min.js`
      })
      script.src = `${minUrl}.min.js`
      script.id = 'temp-target'
      document.head.appendChild(script)
    }
  }, [])

  useEffect(() => {
    if (contractURL) props.onSuccess()
  }, [contractURL])

  useEffect(() => {
    if (loading && !libraryLoading && !docLoading) setLoading(false)
    else if (!loading && (libraryLoading || docLoading)) setLoading(true)
  }, [loading, libraryLoading, docLoading])

  useEffect(() => {
    if (contractURL && !libraryLoading) {
      // @ts-ignore
      var loadingTask = pdfjsLib.getDocument(contractURL)
      loadingTask.promise.then(
        (pdf: any) => {
          setPdfObj(pdf)
          setPageCount(pdf.numPages)
          setPageNum(1)
        },
        function (reason: any) {
          // PDF loading error
          console.error(reason)
        }
      )
    }
  }, [contractURL, libraryLoading])

  useEffect(() => {
    if (pdfObj && pageNum) loadPage()
  }, [pdfObj, pageNum])

  const loadPage = () => {
    // Fetch the first page
    var pageNumber = pageNum
    pdfObj.getPage(pageNumber).then((page: any) => {
      var scale = 1
      var viewport = page.getViewport({ scale: scale })

      // Prepare canvas using PDF page dimensions
      var canvas = document.getElementById('pdf-target')
      var context = canvas.getContext('2d')
      canvas.height = viewport.height
      canvas.width = viewport.width

      setHeight(viewport.height + 60)

      // Render PDF page into canvas context
      var renderContext = {
        canvasContext: context,
        viewport: viewport,
      }
      var renderTask = page.render(renderContext)
      renderTask.promise.then(() => {
        setDocLoading(false)
        props.onSuccess()
      })
    })
  }

  const fetchContractURL = () => {
    setDocLoading(true)
    const API_URL = window.API_ROOT + '/api'
    const restClientInstance = restClient(API_URL)
    restClientInstance('CUSTOM_GET', 'custom', {
      url:
        'orgs/' +
        //@ts-ignore
        props.orgId +
        '/projects/' +
        props.projectId +
        '/systems/' +
        props.systemUuid +
        '/' +
        props.paymentOptionId +
        '/generate_contract_pdf/?action=save&temporary=1',
    })
      .then((res: InitialResponseType) => {
        if (res.data.success && res.data.worker_url) {
          restClientInstance('CUSTOM_GET', 'custom', {
            url: res.data.worker_url.replace('/api/', ''),
          })
            .then((docRes: DocumentResponseType) => {
              if (docRes.data.url) {
                setContractURL(docRes.data.url)
              } else {
                throw new Error('Error generating contract PDF: ', docRes)
              }
            })
            .catch((docErr: any) => {
              setLoadingError('Unable to retrive the contract contents')
            })
        }
      })
      .catch((err: any) => {
        setLoadingError('Unable to retrive the contract contents')
      })
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.headerWrapper}>
        <div className={classes.left}>
          <ProUXButton
            label="Prev Page"
            onClick={() => setPageNum(Math.max(1, pageNum - 1))}
            type="secondary"
            disabled={pageNum <= 1}
          />
        </div>
        <div className={classes.center}>
          <span>
            Page {pageNum} of {pageCount}
          </span>
        </div>
        <div className={classes.right}>
          <ProUXButton
            label="Next Page"
            onClick={() => setPageNum(pageNum + 1)}
            type="secondary"
            disabled={pageNum >= pageCount}
          />
        </div>
      </div>
      <div className={classes.expandWrapper}>
        <a className={classes.expandLink} onClick={doExpand}>
          {isExpanded ? translate('Return to smaller view') : translate('Click here to Expand the contract')}
        </a>
      </div>
      {loading && <LoadingDots text={translate('One moment while we generate your contract...')} />}
      {contractURL && <canvas className={classes.canvas} id="pdf-target"></canvas>}
    </div>
  )
}
export default PDFContractDisplay

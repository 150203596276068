import { TextField } from '@material-ui/core'
import React from 'react'

type PropTypes = {
  fieldName: string
  fieldLabel: string
  errorCode: string | undefined
  inputType: 'div' | 'input' // BlueSnap either requires a div or input element depending on which form you want to use
  value?: string
  isHidden?: boolean
  fullWidth?: boolean
}

const BlueSnapHostedField: React.FC<PropTypes> = (props) => {
  // bluesnap needs these to be divs, the SDK then injects input fields alongside these divs
  // all styling must be done in the initBlueSnapCreditCardForm hook and is subject to the restrictions
  // around supported CSS Properties here: https://developers.bluesnap.com/v8976-Tools/reference/customizing-the-hosted-payment-fields
  return (
    <div>
      {props.inputType === 'div' && (
        <>
          <span>{props.fieldLabel}</span>
          <div className="test-bs-input" data-bluesnap={props.fieldName} style={{ height: '45px' }}></div>
        </>
      )}
      {props.inputType === 'input' && (
        <TextField
          className="test-bs-input"
          name={props.fieldName}
          id={props.fieldName}
          style={{ height: '45px', display: props.isHidden ? 'none' : undefined }}
          fullWidth={props.fullWidth}
          variant="outlined"
          value={props.value}
          label={props.fieldLabel}
          size="small"
          inputProps={{ 'data-bluesnap': props.fieldName, id: props.fieldName, name: props.fieldName }}
        ></TextField>
      )}
      {props.errorCode && <span className="small">placeholder error message</span>}
    </div>
  )
}
export default BlueSnapHostedField

import { ReduxActionType } from "types/global"

export const OPEN_IRONRIDGE_FORM = 'OPEN_IRONRIDGE_FORM'
export const CLOSE_IRONRIDGE_FORM = 'CLOSE_IRONRIDGE_FORM'

export type IronRidgeReducerType = {
    forceShowDialog: boolean
}
const initialState: IronRidgeReducerType = {
    forceShowDialog: false
}

export default function reducer(state = initialState, action: ReduxActionType) {
    switch (action.type) {
        case OPEN_IRONRIDGE_FORM:
            return {
                ...state,
                forceShowDialog: true
            }
        case CLOSE_IRONRIDGE_FORM:
            return {
                ...state,
                forceShowDialog: false
            }
        default:
            return {...state}
    }
}

export const openIronRidgeDialog = () => {
    return {
        type: OPEN_IRONRIDGE_FORM
    }
}

export const closeIronRidgeDialog = () => {
    return {
        type: CLOSE_IRONRIDGE_FORM
    }
}

import Alert from 'elements/Alert'
import React from 'react'

const ActionMessages = ({ messages }) => {
  const getMessageErrorType = (value) => {
    if (value === 'error') {
      return 'error'
    } else if (value === 'info') {
      return 'info'
    } else {
      return 'warning'
    }
  }
  return messages
    ? messages.map((message, index) => (
        <Alert
          key={index}
          severity={getMessageErrorType(message.type)}
          options={{ style: { whiteSpace: 'pre-line' }, className: 'small' }}
        >
          {message.text}
        </Alert>
      ))
    : null
}

export default ActionMessages

import { Radio } from 'opensolar-ui'
import { FC } from 'react'
import { CodeBlock } from '../CodeBlock'
import ComponentPage from '../ComponentPage'
import { FeatureGrid } from '../FeatureGrid'
import { RadioColors, RadioHoriGroup, RadioSizes, RadioStates, RadioVertGroup } from './RadioUsage'

const RadioUsage_code = require('!!raw-loader!./RadioUsage').default

export const RadioDemo: FC = () => {
  return (
    <ComponentPage
      name={'Radio'}
      import="import { Radio } from 'opensolar-ui'"
      description={'Radioes allow the user to select one or more items from a set.'}
      links={{
        mui4: 'https://v4.mui.com/components/radio-buttons/',
        mui5: 'https://mui.com/material-ui/react-radio-button/',
      }}
    >
      <CodeBlock name="Radio Group (Vertical)" code={RadioUsage_code} codePart={1}>
        <RadioVertGroup />
      </CodeBlock>
      <CodeBlock name="Radio Group (Horizontal)" code={RadioUsage_code} codePart={2}>
        <RadioHoriGroup />
      </CodeBlock>
      <CodeBlock name="Radio Sizes" code={RadioUsage_code} codePart={3}>
        <RadioSizes />
      </CodeBlock>
      <CodeBlock name="Radio Colors" code={RadioUsage_code} codePart={4}>
        <RadioColors />
      </CodeBlock>
      <CodeBlock name="Radio States" code={RadioUsage_code} codePart={5}>
        <RadioStates />
      </CodeBlock>

      <CodeBlock name="Radio Feature Grid" hideGrid={true}>
        <FeatureGrid
          component={Radio}
          propsGen={(row, col, lastHeader) => {
            return { defaultChecked: true, ...row, ...col, ...lastHeader }
          }}
          colHeaders={[]}
          rowHeaders={[]}
          cols={[
            { color: 'default' },
            { color: 'primary' },
            { color: 'secondary' },
            { color: 'info' },
            { color: 'success' },
            { color: 'warning' },
            { color: 'error' },
          ]}
          rows={[
            { _type: 'heading', _title: 'Small Size', size: 'small' },
            { variant: 'default' },
            { variant: 'outlined' },
            { variant: 'rounded' },
            { _type: 'heading', _title: 'Large Size (default)', size: 'large' },
            { variant: 'default' },
            { variant: 'outlined' },
            { variant: 'rounded' },
          ]}
        />
      </CodeBlock>
    </ComponentPage>
  )
}

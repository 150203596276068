import { makeStyles } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import GroupedSelectInput, { GroupedSelectInputProps, GroupType } from 'elements/input/GroupedSelectInput'
import {
  checkChoicesForOwnItems,
  filterByOrgShare,
  getOrgGroups,
  parseIdFromUrl,
  useOtherOrgIds,
  useProjectOwnerDetails,
} from 'projectSections/sections/info/orgSharing/util'
import React, { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { ProjectShare } from 'types/projects'

const useStyles = makeStyles(() => ({
  root: {
    marginRight: 0,
  },
  input: {
    padding: '10px 24px 10px 12px',
  },
}))

export type SharedEntitySelectInputProps = Omit<GroupedSelectInputProps, 'groups'> & {
  standardPadding?: boolean
  sharedWith?: ProjectShare[]
  projectOwnerOrg?: number
}

const SharedEntitySelectInput: React.FC<SharedEntitySelectInputProps> = ({ standardPadding, ...props }) => {
  const { children, defaultOptions } = props
  const classes = useStyles()
  const translate = useTranslate()

  const formState = useFormState()
  const ownerOrg = useProjectOwnerDetails(
    useSelector(orgSelectors.getOrg),
    props.projectOwnerOrg || formState.values.org_id
  )
  const sharedWith: ProjectShare[] = props.sharedWith || formState.values.shared_with
  const [optionGroups, setOptionGroups] = useState<GroupType[]>([])
  const [inputChoices, setChoices] = useState(props.choices)
  const orgId = useSelector(authSelectors.getOrgId) as number
  const otherOrgIds = useOtherOrgIds(orgId, ownerOrg?.org_id, sharedWith)
  const unsharedItemId = 'default-unshared-item'

  // Not sure if this is actually required here at all
  useEffect(() => {
    // 'displayEmpty' requires a custom renderValue function
    if (props.displayEmpty && props.emptyText && !props.renderValue) {
      props.renderValue = (value) => {
        let foundItem
        React.Children.forEach(children, (menuItem) => {
          if (menuItem === value || (menuItem && menuItem['props'].value === value)) foundItem = menuItem
        })
        return foundItem || props.emptyText
      }
    }
  }, [props.displayEmpty, props.emptyText, props.renderValue])

  useEffect(() => {
    if (orgId !== undefined && !!sharedWith?.length)
      setOptionGroups(getOrgGroups(orgId, ownerOrg, sharedWith, defaultOptions?.length, props.defaultOptionsTitle))
  }, [props.defaultOptionsTitle, orgId, ownerOrg, sharedWith, defaultOptions?.length])

  useEffect(() => {
    let newChoices = [...(props.choices || [])]
    if (props.name && formState?.values[props.name]) {
      const currValue = formState?.values[props.name]
      const findValue = newChoices.find((x) => x[props.optionValue || 'id'] === currValue)
      if (!findValue) {
        const unsharedItem = { id: 'default-unshared-item', title: 'Unshared Item', org_id: parseIdFromUrl(currValue) }
        if (props.optionValue) {
          newChoices.push({ [props.optionValue]: currValue, ...unsharedItem })
        } else {
          newChoices.push(unsharedItem)
        }
      }
    }
    if (defaultOptions?.length) {
      const updatedChoices = defaultOptions.map((option) => {
        var optionObj = { ...option }
        const optionText = props.optionText
        const optionValue = props.optionValue
        if (optionText) {
          optionObj[optionText] = translate(option[optionText] || option['title'])
        } else {
          optionObj['title'] = translate(option['title'])
        }
        if (optionValue) {
          optionObj[optionValue] = option[optionValue] || option['id']
        } else {
          optionObj['id'] = option['id']
        }
        optionObj['defaultItem'] = true
        return optionObj
      })
      setChoices(newChoices.concat(updatedChoices))
    } else {
      setChoices(newChoices)
    }
  }, [defaultOptions, props.choices, props.optionText, props.optionValue])

  return (
    <GroupedSelectInput
      {...props}
      choices={inputChoices}
      groups={optionGroups}
      showSoloGroupName={!!sharedWith?.length && !checkChoicesForOwnItems(orgId, inputChoices)}
      groupValue={(choice) => {
        return filterByOrgShare(choice, orgId, otherOrgIds)
      }}
      optionText={props.optionText || 'title'}
      optionValue={props.optionValue || 'id'}
      translateChoice={false}
      options={props.classes ? props.classes : standardPadding ? { classes } : undefined}
      MenuProps={{ disableScrollLock: true }}
      renderItemNotFound={() => <i style={{ color: '#aaa' }}>{translate('Unshared Item')}</i>}
      renderCustomMenuItem={(choice, getChoiceText) => {
        if (choice?.id === unsharedItemId) {
          return <i style={{ color: '#aaa' }}>{translate('Unshared Item')}</i>
        } else return getChoiceText
      }}
    />
  )
}

export default SharedEntitySelectInput

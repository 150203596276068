import { doGetPublicFeatureConfig } from 'ducks/featureConfig'
import { useEffect, useMemo, useState } from 'react'
import { useFormState } from 'react-final-form'
import { ProjectErrorType } from 'reducer/project/projectErrorsReducer'

const clearWarnings = (source) => {
  const filter = (error) => error.source === source
  window.WorkspaceHelper.clearProjectError(filter)
}

export const addProjectErrorToReduxStoreDirect = (payload) => {
  const projectErrors = window.reduxStore?.getState()?.project?.errors || []
  const isErrorExist =
    !!(payload.key && projectErrors.find((error) => error.key === payload.key)) ||
    !!projectErrors.find(
      (error) =>
        error.message === payload.message &&
        error.key === payload.key &&
        error.systemId === payload.systemId &&
        error.source === payload.source
    )
  if (!isErrorExist) {
    window.reduxStore?.dispatch({
      type: 'PROJECT_ERRORS_ADD',
      payload: payload,
    })
  }
}
export const amendMessage = (source: string, errorDefs: ErrorMap, messagekey: string | undefined) => {
  clearWarnings(source)
  if (messagekey) {
    const matched = errorDefs[messagekey]
    if (!matched) return
    matched.forEach((warning, index) => {
      const key = `${source}_${messagekey}_${index}`
      addProjectErrorToReduxStoreDirect({ key, source, ...warning })
    })
  }
}

type ErrorMap = { [key: string]: Partial<ProjectErrorType>[] }

/**
 * This hook takes warnings from the project data and updates the project errors/warnings model
 * with these errors.
 */

export const useAddProjectErrors = () => {
  const formValues = useFormState().values
  const [projectErrorDefs, setProjectErrorDefs] = useState<ErrorMap>({})

  useEffect(() => {
    doGetPublicFeatureConfig('project_errors')
      .then((res) => {
        setProjectErrorDefs(JSON.parse(res as string))
      })
      .catch((err) => {
        console.error('Failed to load project errors feature config')
      })
  }, [])

  const tariff =
    formValues.utility_tariff_proposed_data ||
    formValues.utility_tariff_current_data ||
    formValues.utility_tariff_proposed_or_guess

  const tariffMessageKey: string | undefined = useMemo(() => {
    return tariff?.ui_message_key
  }, [tariff])

  useEffect(() => amendMessage('utility-message', projectErrorDefs, tariffMessageKey), [
    tariffMessageKey,
    projectErrorDefs,
  ])
}

import { useCallback, useState } from 'react'
import { useDataProvider } from 'react-admin'
import type { InventoryFiltersType } from '../../filter/type'
import type { ForecastedProjectInventory } from '../type'

type ForecastProjectInventoryOptions = {
  filters?: InventoryFiltersType
}

export type ForecastProjectInventoryType = (options?: ForecastProjectInventoryOptions) => Promise<void>

type ForecastProjectInventoryResponse = {
  forecastingProjectInventory: boolean
  forecastedProjectInventoryData: ForecastedProjectInventory[] | undefined
  forecastProjectInventory: ForecastProjectInventoryType
}

const useForecastProjectInventory = (): ForecastProjectInventoryResponse => {
  const [forecastedProjectInventoryData, setForecastedProjectInventoryData] = useState<
    ForecastedProjectInventory[] | undefined
  >(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dataProvider = useDataProvider()
  const forecastProjectInventory = useCallback(async (options?: ForecastProjectInventoryOptions) => {
    setIsLoading(true)
    const url = options?.filters?.milestones?.[0] || 'inventory/projects/'
    await dataProvider
      .CUSTOM_POST(
        'inventory/projects',
        {
          url: url,
          data: options?.filters,
        },
        {
          onSuccess: ({ data }: { data: any }) => {
            setForecastedProjectInventoryData(data)
          },
          onFailure: (e) => {
            console.warn('e', e)
          },
        }
      )
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return {
    forecastedProjectInventoryData,
    forecastingProjectInventory: isLoading,
    forecastProjectInventory,
  }
}

export default useForecastProjectInventory

import { ReduxActionType } from "types/global"

export const OPEN_RENUSOL_FORM = 'OPEN_RENUSOL_FORM'
export const CLOSE_RENUSOL_FORM = 'CLOSE_RENUSOL_FORM'

export type RenusolReducerType = {
    forceShowDialog: boolean
}
const initialState: RenusolReducerType = {
    forceShowDialog: false
}

export default function reducer(state = initialState, action: ReduxActionType) {
    switch (action.type) {
        case OPEN_RENUSOL_FORM:
            return {
                ...state,
                forceShowDialog: true
            }
        case CLOSE_RENUSOL_FORM:
            return {
                ...state,
                forceShowDialog: false
            }
        default:
            return {...state}
    }
}

export const openRenusolDialog = () => {
    return {
        type: OPEN_RENUSOL_FORM
    }
}

export const closeRenusolDialog = () => {
    return {
        type: CLOSE_RENUSOL_FORM
    }
}

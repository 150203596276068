// @ts-nocheck
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { hideFullScreenLoader, markCreditAppAsOpened, myEnergySelectors } from 'ducks/myEnergy'
import LoadingDots from 'layout/widgets/LoadingDots'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'
import { PaymentOptionType } from 'types/paymentOptions'
import { ProjectType } from 'types/projects'
import { TransactionActionButton } from '../../../elements/TransactionStartButton'
import ReapplicationConfirm from '../ReapplicationConfirm'
import { removeCreditAppParams } from '../util'
import MosaicApplicationDialog from './MosaicApplicationDialog'
import { DisclosuresResponseType, MosaicDisclosuresType } from './types'

var restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  actionData: any
  translate: (raw: string) => string
  showDialog: boolean
  setWaitingForAction: Function
  myeStyles: CSSProperties
  acceptButtonStyle: CSSProperties
  acceptButtonStyleDisabled: CSSProperties
  isPro: boolean
  selectedPaymentOptionName: string
  paymentOptionData: PaymentOptionType
  selectedProject: ProjectType
  onDecisionRendered: () => void
  decisionRendered: boolean
  selectedPaymentOptionId: number
}
const MosaicApplicationButton: React.FunctionComponent<PropTypes> = (props) => {
  const {
    actionData,
    translate,
    myeStyles,
    acceptButtonStyle,
    acceptButtonStyleDisabled,
    selectedProject,
    paymentOptionData,
    selectedPaymentOptionId,
  } = props

  const [disclosuresLoading, setDisclosuresLoading] = useState<boolean>(false)
  const [disclosures, setDisclosures] = useState<MosaicDisclosuresType[]>([])
  const [disclosureBundleId, setDisclosureBundleId] = useState<string | undefined>(undefined)
  const [showApplicationDialog, setShowApplicationDialog] = useState<boolean>(false)
  const [isPowerSwitch, setIsPowerSwitch] = useState<boolean>(false)
  const [showReapplicationConfirm, setShowReapplicationConfirm] = useState<boolean>(false)
  const [isFullWidth, setIsFullWidth] = useState<boolean>(false)

  const forceOpenCreditApp = useSelector(myEnergySelectors.getShouldForceOpenCreditApp)
  const dispatch = useDispatch()

  useEffect(() => {
    if (forceOpenCreditApp && selectedPaymentOptionId === paymentOptionData?.id) {
      openApplication()
      removeCreditAppParams()
      setIsFullWidth(true)
    }
  }, [forceOpenCreditApp, selectedPaymentOptionId])

  useEffect(() => {
    setIsPowerSwitch(props.paymentOptionData?.category_id === 'Zero')
  }, [props.paymentOptionData?.category_id])

  let extra_actions = []
  const orgId = props.paymentOptionData?.org_id ? props.paymentOptionData?.org_id : actionData.org_id

  if (actionData.status_code === 'available') {
    if (isPowerSwitch) {
      extra_actions.push(
        <p key={extra_actions.length} className="small">
          Clicking "Get Pre-Qualified" will take you to Mosaic's platform. OpenSolar does not view or store any of the
          data you enter in this application except name and email address.
        </p>
      )
    } else {
      extra_actions.push(
        <p key={extra_actions.length} className="small">
          Clicking "Get Pre-Qualified" will take you to Mosaic's credit application. OpenSolar does not view or store
          any of the data you enter in this application except name and email address.
        </p>
      )
    }
  } else if (actionData.status_description) {
    // Other statuses where we simply print the status_description
    // Including: OtherOrUnknown
    extra_actions.push(<p key={extra_actions.length}>{actionData.status_description}</p>)
  }

  const openApplication = () => {
    //logAppOpenedEvent(actionData, selectedPaymentOptionName)
    setDisclosuresLoading(true)
    restClientInstance('CUSTOM_GET', 'custom', {
      url:
        'orgs/' +
        orgId +
        '/projects/' +
        actionData.project_id +
        '/' +
        props.paymentOptionData.id +
        '/mosaic/disclosures/',
    })
      .then(
        (response: DisclosuresResponseType) => {
          if (response?.data?.success) {
            setShowReapplicationConfirm(false)
            setShowApplicationDialog(true)
            let disclosureSet = [] as MosaicDisclosuresType[]
            let disclosuresAdded = [] as string[]
            response.data.disclosures.forEach((disc) => {
              if (!disclosuresAdded.includes(disc.assetCode)) {
                disclosureSet.push(disc)
                disclosuresAdded.push(disc.assetCode)
              }
            })
            setDisclosures(disclosureSet)
            setDisclosureBundleId(response.data.bundle_id)
          }
        },
        (reject: any) => {
          console.log(reject)
          window.Designer.showNotification(window.translate(reject.message), 'danger')
          logAmplitudeEvent('integrated_finance_application_open_error', { integration: 'mosaic' })
        }
      )
      .catch((err: any) => {
        if (err?.body?.message) {
          window.Designer.showNotification(window.translate(err.body.message), 'danger')
          logAmplitudeEvent('integrated_finance_application_open_error', { integration: 'mosaic' })
        }
      })
      .finally(() => {
        setDisclosuresLoading(false)
        dispatch(hideFullScreenLoader())
        dispatch(markCreditAppAsOpened())
      })
  }

  return (
    <>
      {actionData.status_code !== 'complete' && (
        <TransactionActionButton
          label={
            <div>
              {disclosuresLoading ? (
                <LoadingDots color="#fff" />
              ) : (
                <>
                  <span>{translate('Get Pre-Qualified')}</span>
                  <div style={{ fontSize: 11 }}>({translate('Takes less than 5 minutes')})</div>
                </>
              )}
            </div>
          }
          disabled={actionData.status_code !== 'available' || props.decisionRendered || disclosuresLoading}
          myeStyles={myeStyles}
          labelStyle={{ padding: 0 }}
          buttonStyle={{
            height: 39,
            ...(actionData.status_code === 'available' && !props.decisionRendered
              ? acceptButtonStyle
              : acceptButtonStyleDisabled),
          }}
          action={openApplication}
          extra_actions={extra_actions}
        />
      )}
      {actionData.status_code === 'complete' && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
            {(actionData.status_title?.includes('approved') || actionData.status_title?.includes('Approved')) && (
              <CheckCircleIcon htmlColor="rgba(55, 169, 46, 0.9)" style={{ marginRight: '5px' }} />
            )}
            <h2>{actionData.status_title}</h2>
          </div>
          <div>{actionData.status_description}</div>
          {actionData.status_title === 'Declined' && (
            <div style={{ margin: '10px 0px', width: '100%', display: 'flex', justifyContent: 'center' }}>
              <div
                style={{
                  color: myeStyles?.highlightColor,
                  textAlign: 'center',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={() => setShowReapplicationConfirm(true)}
              >
                Want to Re-Apply?
              </div>
              <ReapplicationConfirm
                close={() => setShowReapplicationConfirm(false)}
                onConfirm={openApplication}
                isOpen={showReapplicationConfirm}
                loading={disclosuresLoading}
              />
            </div>
          )}
        </div>
      )}
      {disclosures && disclosureBundleId && (
        <MosaicApplicationDialog
          open={showApplicationDialog}
          onClose={() => setShowApplicationDialog(false)}
          disclosures={disclosures}
          project={selectedProject}
          paymentOptionId={paymentOptionData?.id}
          systemUuid={actionData?.system_uuid}
          orgId={orgId}
          onDecisionRendered={props.onDecisionRendered}
          bundleId={disclosureBundleId}
          // @ts-ignore
          productType={paymentOptionData.category_id}
          isFullWidth={isFullWidth}
        />
      )}
    </>
  )
}
export default MosaicApplicationButton

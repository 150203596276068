import { logAmplitudeEvent } from 'amplitude/amplitude'
import { addOrderLineItem, deleteOrderByCode } from 'ducks/orderComponents'
import type LineItemType from 'pages/ordering/OrderLineItem'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Confirmation from './Confirmation'
import CtaAction from './CtaAction'
import SupplierDetail from './SupplierDetail'

const ReplaceItemCta = ({
  lineItem,
  updateLineItem,
  originalLineItem,
  onComplete,
}: {
  lineItem: LineItemType
  updateLineItem(lineItem: LineItemType): void
  originalLineItem: LineItemType
  onComplete(): void
}) => {
  const dispatch = useDispatch()
  const handleCtaClick = useCallback(() => {
    logAmplitudeEvent('hardware_replace_item_cta_clicked')
    dispatch(deleteOrderByCode(originalLineItem.code))
    dispatch(addOrderLineItem(lineItem))
    onComplete()
  }, [lineItem])
  return (
    <Confirmation
      SupplierDetail={<SupplierDetail locations={lineItem.selectedDistributorOrderingData?.locations} />}
      CtaAction={
        <CtaAction
          ctaLabel={'Replace item'}
          newLineItem={lineItem}
          updateLineItem={updateLineItem}
          handleCtaClick={handleCtaClick}
        />
      }
    />
  )
}

export default ReplaceItemCta

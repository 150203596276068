import { getSdk_v1, OSSDK } from 'opensolar-sdk'
import { useEffect, useMemo, useState } from 'react'

/**
 * Returns the SDK, but only once it is ready for use.
 * This relies on the useSdkInit hook being called elsewhere in the host application.
 */
export const useSdkReady = (): OSSDK | undefined => {
  const sdk = useMemo<OSSDK>(() => getSdk_v1(true, true), [])
  const [ready, setReady] = useState(sdk.isReady.value)

  useEffect(() => {
    const readyHandler = (value) => setReady(value)
    sdk.isReady.add(readyHandler)
    return () => sdk.isReady.remove(readyHandler)
  }, [])

  return ready ? sdk : undefined
}

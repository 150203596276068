const designFields = [
  'pricing_schemes',
  'costing_schemes',
  'adders',
  'payment_options',
  'battery_control_scheme',
  'components',
  'incentives',
]

export default designFields

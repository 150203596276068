import lodash from 'lodash'
import { CustomFormTypeEnum } from 'resources/customForms/types'
import { StudioSystemType } from 'types/global'
import { CustomFormAnyData } from '../types'

export const getCustomFormData = (
  type: CustomFormTypeEnum,
  formData: any,
  selectedSystem?: StudioSystemType
): CustomFormAnyData => {
  const values = lodash.cloneDeep(formData)

  switch (type) {
    case 'project':
      return {
        type: 'project',
        project: values,
        project_data: values.custom_data || {},
      }
    case 'system_project':
      return {
        type: 'system_project',
        project: values,
        project_data: values.custom_data || {},
        selected_system: selectedSystem,
      }
    case 'role':
      return {
        type: 'role',
        role: values,
        role_data: values.user_data || {},
      }
    case 'contact':
      return {
        type: 'contact',
        contact: values,
        contact_data: values.custom_data || {},
      }
    case 'payment_option':
      return {
        type: 'payment_option',
        payment_option: values,
        payment_option_data: values.custom_data || {},
      }
    default:
      throw new Error(`Unknown custom form type: ${type}`)
  }
}

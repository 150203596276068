import { permissionsSelectors } from 'ducks/permissions'
import { useSelector } from 'react-redux'
import Panel from '../Panel'
import SelectionChips from './SelectionChips'
import type { PanelGroupType } from './types'

const HeterogeneousPanelGroup: PanelGroupType = ({ group }) => {
  const accessRightsSetting = useSelector(permissionsSelectors.getPermissionsSetting)
  const allowEdit = !!accessRightsSetting?.['design'].edit

  return (
    <Panel
      showTools={allowEdit}
      disableTools={false}
      title={'Selection'}
      summary={null}
      feature={<SelectionChips objects={group.objects} />}
    />
  )
}

export default HeterogeneousPanelGroup

import { Grid } from '@material-ui/core'
import SavingDiscountTag from 'pages/ordering/elements/SavingDiscountTag'
import { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { formatCurrencyWithSymbol } from 'util/misc'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  priceAfterDiscount: {
    fontSize: '20px',
    color: theme.secondaryColor,
    marginTop: '2px',
  },
  originalPrice: {
    fontSize: '16px',
  },
  tag: {},
}))

const PriceFigureWithDiscount = ({
  currencySymbol,
  priceAfterDiscount,
  originalPrice,
}: {
  currencySymbol: string
  priceAfterDiscount: number
  originalPrice: number
}) => {
  const classes = useStyles()
  return (
    <>
      <Grid container spacing={4}>
        <Grid item className={classes.priceAfterDiscount}>{`${formatCurrencyWithSymbol(
          priceAfterDiscount,
          currencySymbol
        )}`}</Grid>
        <Grid item className={classes.tag}>
          <SavingDiscountTag currencySymbol={currencySymbol} value={originalPrice - priceAfterDiscount} />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item className={classes.originalPrice}>{`Was ${formatCurrencyWithSymbol(
          originalPrice,
          currencySymbol
        )}`}</Grid>
      </Grid>
    </>
  )
}

export default PriceFigureWithDiscount

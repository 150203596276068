import TourTooltip from 'elements/tooltip/TourTooltip'
import { TourConfigType } from 'persistentContent/tours/types'
import { useTranslate } from 'ra-core'
import { FC } from 'react'

const StepTooltip: FC = (props) => {
  const translate = useTranslate()
  return (
    <TourTooltip
      title={translate('Integrations')}
      subtext={translate(
        "Activate partner integrations to broaden your project's capabilities and take your business to the next level."
      )}
      {...props}
    />
  )
}

const Integrations: TourConfigType = {
  spotlights: [{ targetId: 'Project-Integrations-Section', autoScroll: true }],
  tooltips: [
    {
      targetId: 'Project-Integrations-Section',
      placement: 'bottom-start',
      component: StepTooltip,
      options: { accountScroll: true },
    },
  ],
}

export default Integrations

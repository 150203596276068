// @ts-nocheck
import { CheckCircle } from '@material-ui/icons'
import ProjectName from 'elements/ProjectName'
import LoadingDots from 'layout/widgets/LoadingDots'
import { Checkbox } from 'opensolar-ui'
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { FunctionField } from 'react-admin'
import { Link } from 'react-router-dom'
import { SystemDataType } from 'types/systems'
import { formatCurrency } from 'util/misc'
import { CalculationStatusType, ProjectRecordType } from './types'

type UuidToNewPriceType = {
  [uuid: string]: number
}

type UuidToNewRegularPayment = {
  [uuid: string]: number
}

type PropTypes = {
  oldPaymentOption: number | undefined
  orgId: number
  unselectProject: (projectId: number) => void
  selectProject: (projectId: ProjectRecordType) => void
  selectedProjectIds: number[]
  calculationStatusMap: CalculationStatusType
  record: ProjectRecordType
  newPaymentOptionId: number
  onSave: (projectId: number) => void
}

const SwapPaymentOptionsRow: React.FC<PropTypes> = (props) => {
  const getOldPaymentOption = () => {
    let oldPaymentOption = null
    let design = props.record.design ? JSON.parse(window.CompressionHelper.decompress(props.record.design)) : null
    if (design) {
      design.object.children
        .filter((child) => child.type === 'OsSystem')
        ?.forEach((sys: SystemDataType) => {
          oldPaymentOption = sys.userData.payment_options?.find((pmt) => pmt.id === props.oldPaymentOption)
        })
    }
    return oldPaymentOption
  }

  const setInitialSystemPrice = () => {
    return getOldPaymentOption()?.pricing?.system_price_including_tax
  }

  const [isSelected, setIsSelected] = useState<boolean>(false)
  const [oldPrice, setOldPrice] = useState<number>(setInitialSystemPrice())
  const [regularPayment, setRegularPayment] = useState<number>(getOldPaymentOption()?.regular_payment)
  const [uuidToNewPrice, setUuidToNewPrice] = useState<UuidToNewPriceType>({})
  const [uuidToNewRegularPayment, setUuidToNewRegularPayment] = useState<UuidToNewRegularPayment>({})
  const [status, setStatus] = useState<string | undefined>(props.calculationStatusMap[props.record.id]?.status)

  useEffect(() => {
    if (props.selectedProjectIds) {
      if (props.selectedProjectIds.includes(props.record.id) && !isSelected) setIsSelected(true)
      else if (!props.selectedProjectIds.includes(props.record.id) && isSelected) setIsSelected(false)
    }
  }, [props.selectedProjectIds])

  // listen for status updates for this project, once calcs are finished parse the new design and store the price for each system
  useEffect(() => {
    if (
      props.calculationStatusMap &&
      props.calculationStatusMap[props.record.id] &&
      props.calculationStatusMap[props.record.id].status === 'complete'
    ) {
      const tempPriceMap = {} as UuidToNewPriceType
      const tempRegularPaymentMap = {} as UuidToNewRegularPayment
      let design = JSON.parse(
        window.CompressionHelper.decompress(props.calculationStatusMap[props.record.id].new_design)
      )
      if (design) {
        design.object.children
          .filter((child) => child.type === 'OsSystem')
          ?.forEach((sys: SystemDataType) => {
            let newPaymentOption = sys.userData.payment_options?.find((pmt) => pmt.id === props.newPaymentOptionId)
            let newPrice: number = newPaymentOption?.pricing?.system_price_including_tax
            tempPriceMap[sys.uuid] = newPrice
            tempRegularPaymentMap[sys.uuid] = newPaymentOption?.regular_payment
          })
      }
      setUuidToNewPrice(tempPriceMap)
      setUuidToNewRegularPayment(tempRegularPaymentMap)
    } else if (Object.keys(uuidToNewPrice).length > 0) {
      // clear it out when user unchecks this project
      setUuidToNewPrice({})
      setUuidToNewRegularPayment({})
    }
    setStatus(props.calculationStatusMap[props.record.id]?.status)
  }, [props.calculationStatusMap[props.record.id]?.status])

  const onChange = (e: ChangeEvent) => {
    if (e.target.checked) props.selectProject(props.record)
    else props.unselectProject(props.record.id)
  }

  const onSave = () => {
    props.onSave(props.record.id)
  }

  const onDelete = () => {
    props.unselectProject(props.record.id)
  }

  const renderButtons = useCallback(() => {
    if (status === 'saved') {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <CheckCircle htmlColor="rgba(55, 169, 46, 0.9)" />
          <span>Update saved!</span>
        </div>
      )
    } else if (uuidToNewPrice && Object.keys(uuidToNewPrice).length > 0) {
      if (['saving', 'running'].includes(status)) {
        return (
          <div>
            <LoadingDots />
          </div>
        )
      } else {
        return (
          <div style={{ display: 'flex' }}>
            {/* <div>
                            <IconButton
                                onClick={onSave}
                            >
                                <SaveOutlined />
                            </IconButton>
                        </div>
                        <div>
                            <IconButton
                                onClick={onDelete}
                            >
                                <UndoOutlined />
                            </IconButton>
                        </div> */}
          </div>
        )
      }
    } else {
      if (status === 'running') {
        return (
          <div>
            <LoadingDots />
          </div>
        )
      } else if (status === 'error' && props.calculationStatusMap[props.record.id]?.error_msg) {
        return <div style={{ color: 'red' }}>{props.calculationStatusMap[props.record.id]?.error_msg}</div>
      }
    }
    return null
  }, [status, uuidToNewPrice])

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ flex: 1 }}>
        <Checkbox
          checked={isSelected}
          onChange={onChange}
          disabled={props.calculationStatusMap[props.record.id]?.status === 'saved'}
        />
      </div>
      <div style={{ flex: 4 }}>
        <FunctionField
          source="address"
          {...props}
          render={(record) => (
            <>
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: '1em',
                  margin: 0,
                  textOverflow: 'ellipsis',
                }}
              >
                <Link to={`/projects/${record.id}/info`} style={{}} target="_blank" rel="noopener noreferrer">
                  <ProjectName business_name={record.business_name} address={record.address} />
                </Link>
              </p>
              <div>Sold System: {record.system_sold ? 'Yes' : 'No'}</div>
              <div>Stage: {record.get_stage_display}</div>
            </>
          )}
        />
      </div>
      <div style={{ flex: 2, display: 'flex', flexDirection: 'column' }}>
        {props.record?.systems?.map((sys) => {
          let systemTitle = sys.name && sys.name !== '' ? sys.name : undefined
          if (!systemTitle) {
            let moduleCount = 0
            sys.modules.forEach((panelGroup) => {
              moduleCount += panelGroup.quantity
            })
            systemTitle = `${moduleCount} Panels`
          }

          const priceIncreased =
            uuidToNewPrice[sys.uuid] && Math.round(uuidToNewPrice[sys.uuid], 2) > Math.round(oldPrice, 2)
          const priceDecreased =
            uuidToNewPrice[sys.uuid] && Math.round(uuidToNewPrice[sys.uuid], 2) < Math.round(oldPrice, 2)
          const regularPaymentChanged =
            uuidToNewRegularPayment[sys.uuid] &&
            Math.round(uuidToNewRegularPayment[sys.uuid], 2) - Math.round(regularPayment, 2)

          let textColor = undefined
          if (priceIncreased) textColor = 'red'
          else if (priceDecreased) textColor = 'green'

          let regularPaymentTtextColor =
            regularPaymentChanged < 0 ? 'red' : regularPaymentChanged > 0 ? 'green' : undefined

          return (
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
              <span>
                <strong>{systemTitle}</strong>
              </span>
              <span>Current Price: {formatCurrency(oldPrice)}</span>
              {uuidToNewPrice && uuidToNewPrice[sys.uuid] && (
                <span style={{ color: textColor }}>New Price: {formatCurrency(uuidToNewPrice[sys.uuid])}</span>
              )}
              <span>Current Regular payments: {formatCurrency(regularPayment)}</span>
              {uuidToNewRegularPayment && uuidToNewRegularPayment[sys.uuid] && (
                <span style={{ color: regularPaymentTtextColor }}>
                  New Regular Payment: {formatCurrency(uuidToNewRegularPayment[sys.uuid])}
                </span>
              )}
            </div>
          )
        })}
      </div>
      <div style={{ flex: 1, flexDirection: 'row' }}>{renderButtons()}</div>
    </div>
  )
}

export default SwapPaymentOptionsRow

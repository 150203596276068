import type { OpenSolarThemeType } from 'Themes'
import type { OsChipPropsType } from 'elements/chip/Chip'
import Chip from 'elements/chip/Chip'
import React, { memo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  chip: {
    marginTop: '5px',
    marginBottom: '5px',
    maxWidth: '175px',
  },
}))

const AllocationBasicChip = memo((props: OsChipPropsType) => {
  const classes = useStyles()
  return (
    <div className={classes.chip}>
      <Chip size="small" {...props} />
    </div>
  )
})

export default AllocationBasicChip

import { NullableParamValueType, ParamValueType } from './types'

const toQueryString = ({
  params,
  optionalParams,
}: {
  params?: { [key in string]: ParamValueType }
  optionalParams?: { [key in string]: NullableParamValueType }
}) => {
  let queryString = ''
  const queryKeyAndValue: string[] = []

  if (params) {
    for (const key in params) {
      if (params[key] === undefined || params[key] === null) {
        throw new TypeError('Mandatory query params should not be undefined or null')
      }
      queryKeyAndValue.push(`${encodeURIComponent(key)}=${encodeURIComponent(params[key].toString())}`)
    }
  }

  if (optionalParams) {
    for (const key in optionalParams) {
      const value = optionalParams[key]
      // Exclude undefined and null values from query params
      if (value === undefined || value === null) {
        continue
      }
      queryKeyAndValue.push(`${encodeURIComponent(key)}=${encodeURIComponent(value.toString())}`)
    }
  }

  if (queryKeyAndValue.length > 0) {
    queryString += '?'
    queryString += queryKeyAndValue.join('&')
  }

  return queryString
}

/**
 * Format URL to request data with query params
 *
 * @param {Object} params - The object containing mandatory key-value pairs to be converted.
 * @param {Object} optionalParams - The object containing nullable value, however nullable values will be removed.
 * @param {string} baseUrl - base url.
 * @returns {string} - The formatted query string.
 */
const composeUrlWithQueryParams = ({
  baseUrl,
  params,
  optionalParams,
}: {
  baseUrl: string
  params?: { [key in string]: ParamValueType }
  optionalParams?: { [key in string]: NullableParamValueType }
}) => {
  if (!baseUrl.endsWith('/')) baseUrl = baseUrl + '/'
  return baseUrl + toQueryString({ params, optionalParams })
}

export default composeUrlWithQueryParams

import { InputAdornment, TextField } from '@material-ui/core'
import { SearchOutlineIcon } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  quickSearchWrapper: {
    height: 36,
    maxHeight: 36,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  input: {
    padding: 0,
    height: 34,
    fontWeight: 400,
    color: theme.greyDark2,
    fontSize: '14px',
    overflowY: 'scroll',
    borderWidth: 0,
    boxSizing: 'border-box',
    '&:focus': {
      border: 'none',
      borderWidth: 0,
      height: 34,
      maxHeight: 34,
    },
  },
  outlined: {
    border: '1px solid',
    height: 34,
    borderRadius: 4,
    paddingLeft: 5,
    boxSizing: 'border-box',
    borderColor: theme.greyLight2,
    '&:focus': {
      borderWidth: 0,
      maxHeight: 34,
    },
  },
  noBorder: {
    display: 'none',
  },
  innerSearchIcon: {
    height: 20,
    paddingLeft: 4,
  },
}))

type FilesSearchFieldProps = {
  isOpen: boolean
  filesSearchTerm: string
  setFilesSearchTerm: (term: string) => void
  setShowFilesSearchField: (isOpen: boolean) => void
}

export const FileSearchField: React.FC<FilesSearchFieldProps> = ({
  isOpen,
  filesSearchTerm,
  setFilesSearchTerm,
  setShowFilesSearchField,
}) => {
  const classes = useStyles()
  const translate = useTranslate()

  const handleBlur = () => {
    if (filesSearchTerm === '') {
      setShowFilesSearchField(false)
    }
  }

  return isOpen ? (
    <div className={classes.quickSearchWrapper}>
      <TextField
        id="File-Search-Input"
        variant="outlined"
        autoFocus={true}
        value={filesSearchTerm}
        InputProps={{
          classes: { input: classes.input, root: classes.outlined, notchedOutline: classes.noBorder },
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlineIcon />
            </InputAdornment>
          ),
        }}
        onChange={(e) => {
          setFilesSearchTerm(e.target.value)
        }}
        onBlur={handleBlur}
        placeholder={translate('Search')}
      />
    </div>
  ) : (
    <></>
  )
}

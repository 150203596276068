import { styled } from 'opensolar-ui'
import ProjectAddress from '../../../manage3/details/elements/ProjectAddress'
import { FullWidthWrapper, SectionCardBottom, SectionCardTop } from '../orderActivity/OrderActivitySection'
import InfoFooter from './InfoFooter'

const FlexWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
})

const ProjectInfoSection = () => {
  return (
    <FullWidthWrapper>
      <SectionCardTop>
        <FlexWrapper>
          <ProjectAddress />
        </FlexWrapper>
      </SectionCardTop>
      <SectionCardBottom>
        <InfoFooter />
      </SectionCardBottom>
    </FullWidthWrapper>
  )
}

export default ProjectInfoSection

import LoadingDots from 'layout/widgets/LoadingDots'
import { useTranslate } from 'ra-core'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type PropTypes = {}

const useStyles = makeOpenSolarStyles((theme) => ({
  container: {
    lineHeight: 1.5,
    textAlign: 'center',
  },
  icon: {
    fontSize: '2rem',
    color: theme.greyMid1,
  },
  header: {
    fontWeight: 500,
    fontSize: '1.5rem',
  },
}))

const AwaitingDecision: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <div className={classes.container}>
      <h2 className={classes.header}>{translate('Your application is being reviewed')}</h2>
      <p>
        {translate(
          'Thank you for submitting your application for CashFlow! Our system is working on getting you a decision now. This should take less than 30 seconds.'
        )}
      </p>
      <LoadingDots />
    </div>
  )
}
export default AwaitingDecision

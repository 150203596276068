import CustomField from 'elements/field/CustomField'
import React from 'react'
import { ActiveComponentField, inputStyle } from 'resources/activeComponents/common'

// Do we really need this React Component? I feel like there are other generic components we have created that might be
// able to do similar or exactly the same thing here...

const GenericComponentField = ({ props, fieldConfig, isStaff, extra = undefined, disabled = false }) => {
  // If user is staff show all fields, otherwise hide staff only fields to non-staff users.
  const showField = isStaff ? true : fieldConfig?.isStaffOnly !== true

  return showField ? (
    <CustomField
      isRequired={true}
      component={ActiveComponentField}
      resource={props.resource}
      disabled={disabled}
      name={fieldConfig.custom_field_prefix_source}
      source={fieldConfig.custom_field_prefix_source}
      key={fieldConfig.custom_field_prefix_source}
      fieldConfig={fieldConfig}
      style={inputStyle}
      endAdornment={fieldConfig.endAdornment}
      parse={fieldConfig.extra?.parse}
      format={fieldConfig.extra?.format}
      extra={extra}
    />
  ) : (
    <></>
  )
}

export default GenericComponentField

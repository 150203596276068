import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import type { OsValidityType } from 'Themes'
import React, { useMemo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { mapForecastStatusToValidity } from '../forecast/constants'
import type { ForecastStatus } from '../forecast/type'

const useStyles = makeOpenSolarStyles((theme) => ({
  icon: ({ validity }: { validity: OsValidityType }) => ({
    color: theme.ValidityPalette[validity].background,
  }),
}))

const ForecastStatusIcon = ({ status }: { status: ForecastStatus }) => {
  const validity = useMemo(() => mapForecastStatusToValidity(status), [status])
  const classes = useStyles({ validity })
  return <FiberManualRecordIcon className={classes.icon} />
}

export default ForecastStatusIcon

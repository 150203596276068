import { Grid, TextField, Typography } from '@material-ui/core'
import { memo, useContext } from 'react'
import { useCheckoutStyles } from '../styles'
import { CheckoutPresenterContext, ContactDetailContext } from './CityPlumbingCheckoutProvider'
import { ContactDetailType } from './types'

const ContactDetail = () => {
  const checkoutClasses = useCheckoutStyles()
  const contactDetail: ContactDetailType = useContext(ContactDetailContext)
  const checkoutPresenter = useContext(CheckoutPresenterContext)
  const requiredFieldMsg = 'This field is required'

  const handleUpdateContactDetail = (key: 'businessName' | 'email' | 'mobileNumber', value: string | undefined) => {
    checkoutPresenter?.updateContactDetail({ ...contactDetail, [key]: value })
  }

  return (
    <Grid container direction="column" style={{ padding: '20px 0px 0px 0px' }}>
      <Grid item xs={12}>
        <Typography variant="h6" className={`${checkoutClasses.font18} ${checkoutClasses.fontWeightMedium}`}>
          <span style={{ marginRight: '20px' }}>1</span>
          <span>Review Contact Details</span>
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ paddingLeft: '30px' }}>
        <Typography className={`${checkoutClasses.font14}`} style={{ color: '#7F7D83', marginTop: '5px' }}>
          Updating your contact information only applies for this order. To update your business information, go to
          Control.
        </Typography>
      </Grid>
      <Grid container direction="row" style={{ gap: '30px', padding: '10px 0px 20px 30px' }}>
        <Grid item xs={2}>
          <TextField
            fullWidth
            variant="standard"
            label={'Busines Name'}
            name="businessName"
            value={contactDetail.businessName}
            onChange={(e) => handleUpdateContactDetail('businessName', e.target.value as string)}
            error={!contactDetail.businessName}
            helperText={!contactDetail.businessName && requiredFieldMsg}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            variant="standard"
            label={'Email Address'}
            name="email"
            value={contactDetail.email}
            onChange={(e) => handleUpdateContactDetail('email', e.target.value as string)}
            error={!contactDetail.email}
            helperText={!contactDetail.email && requiredFieldMsg}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            variant="standard"
            label={'Mobile Number'}
            name="mobileNumber"
            value={contactDetail.mobileNumber}
            onChange={(e) => handleUpdateContactDetail('mobileNumber', e.target.value as string)}
            error={!contactDetail.mobileNumber}
            helperText={!contactDetail.mobileNumber && requiredFieldMsg}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default memo(ContactDetail)

// @ts-nocheck
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import Button from 'elements/proUXButtons/ProUXButton'
import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { OrgType } from 'types/orgs'
import { reloadEntireApp } from 'util/misc'
import QuotationTablePreferencesDialog from '../sharedComponents/QuotationTablePreferencesDialog'
import DividendConnectAccountDialog from './DividendConnectAccountDialog'

type PropTypes = {
  record: OrgType
}

const DividendIntegrationSection: React.FunctionComponent<PropTypes> = (props) => {
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const translate = useTranslate()

  const isConnected: Boolean = useSelector(orgSelectors.getDividendIntegrationStatus) === 'active'
  const orgId = useSelector(authSelectors.getOrgId)
  const isStaff: boolean = useSelector(authSelectors.getIsStaff)
  const showDisconnect = window.location.href?.includes('show_disconnect=true')

  const startDisconnect = () => {
    const confirmed = window.confirm(
      translate(
        'Are you sure you want to disconnect your Dividend account? Any existing proposals with Dividend payment options will no longer be able to apply for credit.'
      )
    )
    if (confirmed) {
      setLoading(true)
      const restClientInstance = restClient(window.API_ROOT + '/api')
      restClientInstance('CUSTOM_PUT', 'custom', {
        url: `orgs/${orgId}/dividend/disconnect/`,
      })
        .then((response: { data?: { success: boolean } }) => {
          if (response.data?.success) {
            logAmplitudeEvent('dividend_account_disconnected', {})
            let currentURL = window.location.href.replace('?show_disconnect=true', '')
            let urlWithoutParams =
              currentURL.indexOf('/success') > 0 ? currentURL.substring(0, currentURL.indexOf('/success')) : currentURL
            window.location.href = urlWithoutParams
            reloadEntireApp()
          }
        })
        .catch((error: any) => {
          console.warn('error', error)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }
  if (!isStaff && !isConnected) return null

  return (
    <div style={{ marginBottom: '30px' }}>
      <h2>Dividend Integration</h2>
      {isConnected ? (
        <div>
          <p>Your Dividend integration is active</p>
          {showDisconnect && (
            <Button
              onClick={startDisconnect}
              label="Disconnect your Dividend account"
              type="secondary"
              loading={loading}
              style={{ width: '400px' }}
            />
          )}
          <QuotationTablePreferencesDialog integration="dividend" />
        </div>
      ) : (
        <div>
          <p>Click the button below to connect your Dividend account</p>
          <Button
            onClick={() => setShowDialog(true)}
            label="Connect your Dividend account"
            style={{ width: '400px' }}
          />
        </div>
      )}
      {showDialog && <DividendConnectAccountDialog isOpen={showDialog} onClose={() => setShowDialog(false)} />}
    </div>
  )
}
export default DividendIntegrationSection

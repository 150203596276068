import { omit } from 'lodash'
import { useCallback } from 'react'
import type {
  ConfigurableFilterOptionsType,
  FilterComponentNodeV2,
  FilterGenericPropsType,
  OptionGenericType,
} from '../typeV2'
import { EXHIBITOR_FILTER_KEY, MY_LIST_FILTER_KEY } from './constants'

export const VIEW_FROM_FILTER_NODE_KEY_V2 = 'view_From_filter_key'
const DEFAULT_OPTION = { id: 'default', title: 'All', value: '' }

const OPTIONS: OptionGenericType<string>[] = [
  DEFAULT_OPTION,
  {
    id: MY_LIST_FILTER_KEY,
    value: MY_LIST_FILTER_KEY,
    title: 'My List',
  },
  {
    id: EXHIBITOR_FILTER_KEY,
    value: EXHIBITOR_FILTER_KEY,
    title: 'Showcase Partners',
  },
]

export const createViewFromFilterNode = <T,>({
  label = 'Component Type',
  rendererComponent: RendererComponent,
  ...restConfigs
}: ConfigurableFilterOptionsType<string, string>): FilterComponentNodeV2 => {
  const FilterComponent = ({
    allFilterValues,
    modifiableFilterValues,
    setModifiableFilters,
  }: FilterGenericPropsType) => {
    const onSelect = useCallback(
      (value) => {
        // Always clear first
        const newFilterValues = omit({ ...modifiableFilterValues }, [MY_LIST_FILTER_KEY, EXHIBITOR_FILTER_KEY])

        if (value === '') {
        } else if (value === MY_LIST_FILTER_KEY) {
          newFilterValues[MY_LIST_FILTER_KEY] = true
        } else if (value === EXHIBITOR_FILTER_KEY) {
          newFilterValues[EXHIBITOR_FILTER_KEY] = true
        }
        setModifiableFilters(newFilterValues)
      },
      [modifiableFilterValues]
    )

    const selectedMyList = allFilterValues[MY_LIST_FILTER_KEY]

    const selectedExhibitor = allFilterValues[EXHIBITOR_FILTER_KEY]

    return (
      <RendererComponent
        id={VIEW_FROM_FILTER_NODE_KEY_V2}
        defaultValue={DEFAULT_OPTION.value}
        label={label}
        options={OPTIONS}
        disabled={false}
        onSelect={onSelect}
        value={selectedMyList || selectedExhibitor}
        additionalProps={{ shouldForceUpdateOptimisticValue: true }}
      />
    )
  }

  return {
    key: VIEW_FROM_FILTER_NODE_KEY_V2,
    component: FilterComponent,
  }
}

export default createViewFromFilterNode

import { Button } from '@material-ui/core'
import ExpandLessOutlinedIcon from '@material-ui/icons/ExpandLessOutlined'
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined'
import type { OpenSolarThemeType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import React, { memo, useCallback } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  expandButton: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '7px',
    color: '#1751D0',
  },
  chipContainer: {},
}))

const SwitchButton = memo(
  ({
    viewMore,
    numberOfHiddenProjects,
    setViewMore,
  }: {
    viewMore: boolean
    numberOfHiddenProjects: number
    setViewMore(viewMore: boolean): void
  }) => {
    const classes = useStyles()
    const handleViewMore = useCallback(() => {
      logAmplitudeEvent('hardware_view_more_switch_button', {
        action: 'clicked',
        context: 'expand',
      })
      setViewMore(true)
    }, [])

    const handleViewLess = useCallback(() => {
      logAmplitudeEvent('hardware_view_more_switch_button', {
        action: 'clicked',
        context: 'collapse',
      })
      setViewMore(false)
    }, [])
    return (
      <section className={classes.expandButton}>
        {viewMore ? (
          <Button size="small" variant="text" onClick={handleViewLess} color="inherit">
            <div>View less</div>
            <ExpandLessOutlinedIcon fontSize="small" />
          </Button>
        ) : (
          <Button size="small" variant="text" onClick={handleViewMore} color="inherit">
            <div>View {numberOfHiddenProjects} more</div>
            <ExpandMoreOutlinedIcon fontSize="small" />
          </Button>
        )}
      </section>
    )
  }
)

export default SwitchButton

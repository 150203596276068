// @ts-nocheck
import { makeStyles } from '@material-ui/core'
import { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import appStorage from 'storage/appStorage'
import { SidePanelContext } from '../SidePanel'
import FilterTag from './FilterTag'
import { RecentFiltersSubHeader } from './SubHeader'
import { getDefaultFilters } from './getDefaultFilters'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

const RecentFilters = () => {
  const classes = useStyles()
  const { state, setState } = useContext(SidePanelContext)
  const orgId = useSelector((state: any) => state.auth?.org_id)
  const defaultFilters = getDefaultFilters()

  useEffect(() => {
    if (!state.recentFilters) {
      const recentFilters = appStorage.getJSON<any[]>('recentFilters') || []
      const max = 5

      const recentFiltersForOrg = recentFilters.filter((recentFilter) => {
        var recentFilterParsed = JSON.parse(recentFilter)
        return recentFilterParsed.orgId === orgId
      })

      for (let i = 0; i < defaultFilters.length; i++) {
        if (recentFiltersForOrg.length < max) {
          const defaultFilter = defaultFilters[i].filter
          if (!recentFiltersForOrg.includes(defaultFilter)) {
            recentFiltersForOrg.unshift(defaultFilter)
          }
        }
      }
      setState({
        ...state,
        recentFiltersForOrg,
      })
    }
  }, [])

  return (
    <div className={classes.wrapper}>
      <RecentFiltersSubHeader />
      {!!state.recentFiltersForOrg && (
        <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: 10 }}>
          <div style={{ margin: '0 16px', display: 'flex', flexWrap: 'wrap', gap: 10 }}>
            {state.recentFiltersForOrg.map((filterString: string, index: number) => {
              return <FilterTag key={index + filterString[0]} filterString={filterString} />
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default RecentFilters

// must include org_id with each shared_with item
const formatSharedWithValues = (submitValues: any, formValues: any, dirtyFields: any[]) => {
  if (submitValues['shared_with']) {
    submitValues['shared_with'] = submitValues['shared_with'].map((item: any, index: number) => {
      const orgId = item.org_id === undefined ? formValues.shared_with?.[index]?.org_id : item.org_id
      return { ...item, org_id: orgId }
    })
  }

  return submitValues
}

export default formatSharedWithValues

import { Paper, Typography } from '@material-ui/core'
import classnames from 'classnames'
import { FC, useMemo } from 'react'
import { useUiLibraryStyles } from './styles'

type CodeBlockProps = {
  name: string
  description?: string
  code?: string
  codePart?: number
  hideGrid?: boolean
}

const SNIPPET_START = /{\/\*\s*SNIPPET\s*START\s*\*\/}/
const SNIPPET_END = /{\/\*\s*SNIPPET\s*END\s*\*\/}/

export const CodeBlock: FC<CodeBlockProps> = ({ name, description, children, code, codePart, hideGrid }) => {
  const classes = useUiLibraryStyles()

  const snippetClipped = useMemo(() => {
    if (!code) return null
    const startRegEx = codePart ? new RegExp(`{\\/\\*\\s*SNIPPET\\s*START\\s*${codePart}\\s*\\*\\/}`) : SNIPPET_START
    const endRegEx = codePart ? new RegExp(`{\\/\\*\\s*SNIPPET\\s*END\\s*${codePart}\\s*\\*\\/}`) : SNIPPET_END
    let startMatch = code.match(startRegEx)
    let start = startMatch ? (startMatch.index || 0) + startMatch[0].length + 1 : 0
    let end = code.search(endRegEx)
    if (start === -1) start = code.length
    const lines = code.substring(start, end).split('\n')
    // remove the same amount of leading spaces from each line
    const leadingSpaces = lines[0].search(/\S/)
    lines.forEach((line, i) => {
      lines[i] = line.substring(leadingSpaces)
    })
    return lines.join('\n')
  }, [code])

  return (
    <Paper classes={{ root: classes.block }}>
      <Typography variant="h4">{name}</Typography>
      {description && (
        <Typography variant="body1" className={classes.bodyText}>
          {description}
        </Typography>
      )}
      <div className={classnames(classes.block_children, !hideGrid ? classes.block_grid : '')}>{children}</div>
      {code && (
        <div>
          <Typography variant="h6">Code for the above:</Typography>
          <div className="code-block">{snippetClipped}</div>
        </div>
      )}
    </Paper>
  )
}

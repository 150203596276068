import { makeStyles } from '@material-ui/styles'
import { FC, ReactNode } from 'react'
import { Theme } from 'types/themes'

const useStyles = makeStyles<Theme, { fullWidth: boolean; hasHeader: boolean; backgroundColor: string }>(
  (theme: Theme) => ({
    actions: {
      flexShrink: 0,
      marginLeft: 'auto',
      display: 'flex',
      alignItems: 'flex-start',
      maxWidth: '50%',

      '& .MuiIconButton-root': {
        padding: 5,
      },
      '& .MuiIconButton-root .MuiSvgIcon-root': {
        fill: theme.greyDark2,
      },
    },
    sidebar: {
      marginTop: 15,
      marginLeft: 15,
      display: 'flex',
      flexDirection: 'column',
    },
    body: {
      flexGrow: 1,
      margin: '5px 10px',
      minWidth: 0,
    },
    box: ({ fullWidth, backgroundColor }) => ({
      backgroundColor: backgroundColor || theme.greyLight1,
      borderRadius: 5,
      display: 'flex',
      width: fullWidth ? '100%' : 'auto',
    }),
    header: ({ hasHeader }) => ({
      display: 'flex',
      height: !hasHeader ? 0 : 'unset', // Allows for actions to float over children, when no header is present
    }),
    titles: {
      wordWrap: 'break-word',
      textAlign: 'left',
      margin: '5px 0',
      minWidth: 0,
    },
    children: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      minWidth: 0,
      margin: '5px 0',
    },
    tagline: {
      ...theme.typography.subtitle2,
      color: theme.greyMid1,
      fontWeight: theme.typography.fontWeightRegular,
    },
    title: {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.subtitle2.fontSize,
      alignItems: 'center',
      display: 'inherit',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      lineHeight: '21px',
    },
    subtitle: {
      color: theme.greyMid1,
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.caption.fontSize,
      lineHeight: '18px',
    },
  }),
  { name: 'TinyBox' }
)

interface PropTypes {
  className?: string
  sidebar?: ReactNode
  tagline?: string
  title?: ReactNode
  subtitle?: string
  actions?: ReactNode
  fullWidth?: boolean
  backgroundColor?: string
}

const TinyBox: FC<PropTypes> = ({
  className,
  children,
  sidebar,
  tagline,
  title,
  subtitle,
  actions,
  fullWidth,
  backgroundColor = '',
}) => {
  const hasHeader = !!(tagline || title || subtitle)
  const classes = useStyles({ fullWidth: !!fullWidth, hasHeader, backgroundColor })

  return (
    <div className={`${className} ${classes.box}`}>
      {sidebar && <div className={classes.sidebar}>{sidebar}</div>}
      <div className={classes.body}>
        {(hasHeader || actions) && (
          <div className={classes.header}>
            {(tagline || title || subtitle) && (
              <div className={classes.titles}>
                {tagline && <div className={classes.tagline}>{tagline}</div>}
                {title && <div className={classes.title}>{title}</div>}
                {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
              </div>
            )}
            {actions && <div className={classes.actions}>{actions}</div>}
          </div>
        )}
        {children && <div className={classes.children}>{children}</div>}
      </div>
    </div>
  )
}

export default TinyBox

import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import type { QUERY_ORDER } from 'elements/controller/query/query'
import { Checkbox } from 'opensolar-ui'
import { useCallback } from 'react'
import { FieldTitle, SORT_ASC } from 'react-admin'
import type { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  tableHeaderContainer: {
    backgroundColor: '#f8f8f8',
    height: '48px',
  },
  header: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
  },
}))

const headCells = [
  {
    source: 'address',
    label: 'Address',
  },
  { source: 'contacts__family_name', label: 'Contacts' },
  {
    source: 'system',
    label: 'System',
  },
  {
    source: 'stage',
    label: 'Stage',
  },
  {
    source: 'installation_date',
    label: 'Install Date',
  },
  {
    source: 'status',
    label: 'Status',
  },
  {
    source: 'action',
    label: '',
  },
]

type ProjectTableHeadPropsType = {
  currentSortField: string | undefined
  currentSortOrder: QUERY_ORDER
  setSort: (sort: string) => void
  // onSelect: (newIds: Identifier[]) => void
}

const ProjectTableHead = ({ currentSortField, currentSortOrder, setSort }: ProjectTableHeadPropsType) => {
  const classes = useStyles()
  const updateSort = useCallback(
    (event) => {
      event.stopPropagation()
      setSort(event.currentTarget.dataset.sort)
    },
    [setSort]
  )

  return (
    <TableHead className={classes.tableHeaderContainer}>
      <TableRow>
        <TableCell padding="checkbox">
          {/* Unable to select all since the system selection could be empty
            Disable select all for now util solve the issue */}
          <Checkbox
            className="select-all"
            color="primary"
            style={{ display: 'none' }}
            disabled={true}
            checked={false}
            onChange={() => {}}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.source} variant="head" className={classes.header}>
            <TableSortLabel
              active={currentSortField === headCell.source}
              direction={currentSortOrder === SORT_ASC ? 'asc' : 'desc'}
              data-sort={headCell.source}
              onClick={updateSort}
            >
              <FieldTitle label={headCell.label} />
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default ProjectTableHead

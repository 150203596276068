// @ts-nocheck
import { makeStyles } from '@material-ui/core'
import { PERFORMANCE_CALCULATORS } from 'constants/calculators'
import Alert from 'elements/Alert'
import React, { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { SystemDataType } from 'types/systems'
import LossTable from './LossTable'

type PropTypes = {
  system: SystemDataType
}

const useStyles = makeStyles((theme) => ({
  selected: {
    fontWeight: 600,
  },
  notSelected: {
    textDecoration: 'underline',
  },
  right: {
    float: 'right',
  },
}))

const SystemLosses: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const classes = useStyles()

  const [showWarning, setShowWarning] = useState<boolean>(false)

  useEffect(() => {
    let isSAM = false
    if (props.system?.calculator) {
      let matchedName = PERFORMANCE_CALCULATORS?.find(
        (calculatorOption) => calculatorOption.id === props.system.calculator
      )?.name
      if (matchedName && matchedName.toLowerCase()?.includes('system advisor model')) {
        isSAM = true
      }
    }
    if (isSAM && showWarning) setShowWarning(false)
    else if (!isSAM && !showWarning) setShowWarning(true)
  }, [props.system?.calculator])

  return (
    <div style={{ margin: '20px 0' }}>
      <span style={{ fontWeight: 600 }}>{translate('System Losses')}</span>
      <span
        className={classes.right}
        style={{ padding: '0 10px', cursor: 'pointer', textDecoration: 'underline' }}
        onClick={() => {
          if (!window.showLosses) {
            window.showLosses = 'system'
            window.editor.signals.sceneGraphChanged.dispatch()
          } else {
            window.showLosses = null
            window.editor.signals.sceneGraphChanged.dispatch()
          }
        }}
      >
        {window.showLosses ? translate('Hide') : translate('Show')}
      </span>

      {window.showLosses && showWarning && (
        <Alert severity="warning">
          {translate('System losses are only shown with the System Advisor Model (SAM) calculator')}
        </Alert>
      )}

      {props.system &&
        props.system.output &&
        props.system.output.details &&
        props.system.output.details.children &&
        props.system.output.details.children.length > 0 && (
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            {window.showLosses === 'system' && <span className={classes.selected}>{translate('Full System')}</span>}
            {Boolean(window.showLosses !== 'system' && window.showLosses) && (
              <a
                className={classes.notSelected}
                onClick={(e) => {
                  e.preventDefault()
                  window.showLosses = 'system'
                  window.editor.signals.sceneGraphChanged.dispatch()
                }}
              >
                {'Full System'}
              </a>
            )}{' '}
            {props.system.output.details.children.length > 1 &&
              props.system.output.details.children.map((inverterDetails, inverterIndex) => {
                if (window.showLosses === inverterDetails.uuid) {
                  return (
                    <span key={inverterDetails.uuid} className={classes.selected}>
                      {Boolean(inverterDetails.uuid)
                        ? translate('Inverter %{number}', { number: inverterIndex + 1 })
                        : translate('Inverter %{number} (Unassigned)')}{' '}
                    </span>
                  )
                } else if (window.showLosses) {
                  return (
                    <span key={inverterDetails.uuid}>
                      <a
                        className={classes.notSelected}
                        onClick={(e) => {
                          e.preventDefault()
                          window.showLosses = inverterDetails.uuid
                          window.editor.signals.sceneGraphChanged.dispatch()
                        }}
                      >
                        {Boolean(inverterDetails.uuid)
                          ? translate('Inverter %{number}', { number: inverterIndex + 1 })
                          : translate('Inverter %{number} (Unassigned)')}
                      </a>{' '}
                    </span>
                  )
                } else {
                  return null
                }
              })}
            {props.system &&
              window.showLosses === 'system' &&
              props.system.output &&
              props.system.output.details &&
              props.system.output.details.system && <LossTable systemLosses={props.system.output.details.system} />}
            {props.system &&
              window.showLosses &&
              window.showLosses !== 'system' &&
              props.system.output &&
              props.system.output.details &&
              props.system.output.details.children &&
              props.system.output.details.children
                .filter((inverterDetails) => inverterDetails.uuid === window.showLosses)
                .map((inverterDetails) => <LossTable key={inverterDetails.uuid} systemLosses={inverterDetails} />)}
          </div>
        )}
    </div>
  )
}
export default SystemLosses

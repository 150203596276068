import { ChevronLeft, ChevronRight } from '@material-ui/icons'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type PropTypes = {
  direction: 'left' | 'right'
  wrapperRef: React.MutableRefObject<null>
}

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    position: 'absolute',
    left: (isLeft) => (isLeft ? '10px' : undefined),
    right: (isLeft) => (isLeft ? undefined : '10px'),
    top: '275px',
    height: '30px',
    width: '30px',
    cursor: 'pointer',
    backgroundColor: theme.greyLight2,
    border: '2px solid white',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: `0px 0px 5px 2px ${theme.greyLight2}`,
    zIndex: 100,
  },
}))

const ScrollButton: React.FC<PropTypes> = (props) => {
  const classes = useStyles(props.direction === 'left')

  const scroll = () => {
    if (props.wrapperRef?.current) {
      if (props.direction === 'left') {
        // @ts-ignore
        props.wrapperRef.current.scrollLeft -= 240
      } else {
        // @ts-ignore
        props.wrapperRef.current.scrollLeft += 240
      }
    }
  }

  return (
    <div onClick={scroll} className={classes.wrapper}>
      {props.direction === 'right' ? <ChevronRight htmlColor="white" /> : <ChevronLeft htmlColor="white" />}
    </div>
  )
}
export default ScrollButton

import { useMediaQuery } from '@material-ui/core'
import React from 'react'
import { getAcceptProposalText } from 'util/misc'
import TransactionStartButton from '../elements/TransactionStartButton'

const styles = {
  mobile: {
    margin: 0,
    display: 'inline-block',
  },
  desktop: {
    textAlign: 'center',
    margin: '15px 10px',
    // margin: '15px 0px 15px 0px',
  },
}

const AcceptProposal = ({
  actionData,
  translate,
  showDialog,
  setWaitingForAction,
  myeStyles,
  acceptButtonStyle,
  acceptButtonStyleDisabled,
  paymentOptionData,
  pricing,
  quotationConfiguration,
  systemSize,
  actionsForSystemAndPaymentOption,
  sequenceNumber,
  variation,
  type,
  loanType,
  additional_costs,
  docusignKBAFailures,
  hasAvailableDocusignAction,
  selectedProject,
  hideCheckoutButton,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const style = isMobile && loanType === 'sunlight_loan_application' ? styles.mobile : styles.desktop
  // Styling for accept proposal button may be either:
  // a) primary full green button or
  // b) text link, when there are specific other action types available
  // var hasPrimaryLoanApplicationAction = detectHasPrimaryLoanApplicationAction(
  //   system_uuid,
  //   actionData.payment_option_id
  // )

  // If two options are the same but differ by the payment_method (e.g. credit_card_stripe and offline)
  // Only show button for first payment option, others can be accessed inside the payment form
  // if (proposalAcceptancePaymentOptionIdsAdded.indexOf(actionData.payment_option_id) === -1) {
  // var acceptProposalButton = (
  //
  // variation is either 'button' or link'

  // @TODO: Need a better way to confirm transaction is completed. Available may be false for other reasons besides being completed.
  if (actionData.status_code === 'complete' && variation === 'link') {
    return (
      <div style={{ color: myeStyles.textColor, ...style }}>
        <input type="checkbox" checked disabled={true} />
        Proposal Accepted
      </div>
    )
  } else if (actionData.status_code !== 'hidden' && (selectedProject?.project_sold || !hideCheckoutButton)) {
    return (
      <div style={{ color: myeStyles.textColor, ...style }}>
        <TransactionStartButton
          elementType={variation}
          label={
            translate(
              getAcceptProposalText(
                actionData.review_action_title,
                actionData.quote_acceptance_content,
                actionData.payment_amount
              )
            ) + (actionData.status_code === 'complete' && variation !== 'link' ? ' ✓' : '')
          }
          disabled={actionData.status_code === 'disabled'}
          project_id={actionData.project_id}
          transactionRequestData={Object.assign({}, actionData, {
            paymentOptionData: paymentOptionData,
            pricing: pricing,
            quotationConfiguration: quotationConfiguration,
            systemSize: systemSize,
            additional_costs,
          })}
          actionsForSystemAndPaymentOption={actionsForSystemAndPaymentOption}
          myeStyles={myeStyles}
          buttonStyle={actionData.status_code === 'available' ? acceptButtonStyle : acceptButtonStyleDisabled}
          actionData={actionData}
          showDialog={showDialog}
          sequenceNumber={sequenceNumber}
          translate={translate}
          docusignKBAFailures={docusignKBAFailures}
        />

        {actionData.status_code === 'complete' && (
          <p>
            <TransactionStartButton
              elementType={'link'}
              label={translate('View Statement')}
              disabled={false}
              project_id={actionData.project_id}
              transactionRequestData={Object.assign({}, actionData, {
                paymentOptionData: paymentOptionData,
                pricing: pricing,
                quotationConfiguration: quotationConfiguration,
                systemSize: systemSize,
                additional_costs,
              })}
              actionsForSystemAndPaymentOption={actionsForSystemAndPaymentOption}
              myeStyles={myeStyles}
              buttonStyle={actionData.status_code === 'available' ? acceptButtonStyle : acceptButtonStyleDisabled}
              actionData={actionData}
              showDialog={showDialog}
              sequenceNumber={sequenceNumber}
              translate={translate}
            />
          </p>
        )}
      </div>
    )
  } else {
    // hidden
    return null
  }
  //
  //   if (hasPrimaryLoanApplicationAction) {
  //     // Show either the action link or the confirmation message
  //     // depending on whether it's available/accepted
  //     if (actionData.available) {
  //       actionsSecondary.push(acceptProposalButton)
  //     } else {
  //       actionsSecondary.push(
  // <div style={{ textAlign: 'center', color: myeStyles.textColor, margin: '15px 0px 15px 0px' }}>
  //   <input type="checkbox" checked disabled={true} />
  //   Proposal Accepted
  // </div>
  //       )
  //     }
  //   } else {
  //     actions.push(acceptProposalButton)
  //   }
  //   proposalAcceptancePaymentOptionIdsAdded.push(actionData.payment_option_id)
  // }
}

export default AcceptProposal

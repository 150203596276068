import { withWidth } from '@material-ui/core'
import { terminateCurrentTour, updateTourStep } from 'actions/tourActions'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors, removeTourBanner } from 'ducks/auth'
import { useShowTourBanner } from 'hooks/useShowTourBanner'
import { recordUserAction } from 'persistentContent/tours/tools/common'
import { useTranslate } from 'ra-core'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BannerDialog from 'resources/projects/projects3/fuji/BannerDialog'
import ManageFujiTour from '.'
import ConfirmModal from '../common/ConfirmModal'
import tourImage from './assets/manage.gif'

interface PropTypes {
  width: string
}

const tourType = 'manage'
const ManageFujiTourBanner: FC<PropTypes> = ({ width }) => {
  const dispatch = useDispatch()
  const translate = useTranslate()
  const { tour } = useSelector((state: any) => state.tour)
  const shouldShowBanner = useShowTourBanner(width, tourType)
  const [displayBanner, setDisplayBanner] = useState(true)
  const [confirmDialog, showConfirm] = useState(false)
  const [endDialog, showEndDialog] = useState(false)
  const userId = useSelector(authSelectors.getCurrentUserId)
  useEffect(() => {
    if (shouldShowBanner.show) logAmplitudeEvent('fuji_banner_shown', { tourType })
    return () => {
      handleHide() // Add this to prevent re opening the tour after user navigates to another page while the tour is still active
    }
  }, [])

  const handleHide = () => {
    if (userId) recordUserAction(userId, 'manage_tour_hidden')
    logAmplitudeEvent('fuji_banner_hidden', { tourType })
    dispatch(removeTourBanner(true, tourType))
    dispatch(terminateCurrentTour(tour))
    showConfirm(false)
    setDisplayBanner(false)
  }

  const handleStart = () => {
    logAmplitudeEvent('fuji_tour_started', { tourType })
    setDisplayBanner(false)
    dispatch(updateTourStep('fuji_manage', 'details'))
  }

  const handleDialogCloseClick = () => {
    logAmplitudeEvent('fuji_confirm_cancel_dialog_shown', { tourType })
    showConfirm(true) // Show the confirmation dialog when the close button is clicked
  }

  const handleEndTour = () => {
    if (userId) recordUserAction(userId, 'manage_tour_finish')
    dispatch(removeTourBanner(true, 'manage'))
    dispatch(terminateCurrentTour(tour))
    showEndDialog(false)
  }

  if (!shouldShowBanner.show) return null
  return (
    <>
      <BannerDialog
        endDialog={false}
        open={displayBanner}
        onClose={handleDialogCloseClick}
        headerText="Your new Manage page is here!"
        subText="Take a tour of the new Manage page combining all key details and tools into one view for easier, more effective project handling."
        actionLabel="Get Started"
        handleAction={handleStart}
        imgSrc={tourImage}
      />
      <ConfirmModal
        open={confirmDialog}
        setOpen={showConfirm}
        title={translate('Are you sure you want to skip?')}
        mainText={translate(
          'The tour is the best way for you to quickly learn your way around the new Manage page on OpenSolar.'
        )}
        submitBtnLabel={translate('Skip Tour')}
        handleSubmit={handleHide}
        submitBtnProps={{ variant: 'contained' }}
      />
      <BannerDialog
        open={endDialog}
        endDialog={endDialog}
        onClose={handleEndTour}
        headerText="Manage Page Tour Complete!"
        subText="Need a refresher? Start the tour anytime from the Support button. For more details on the redesigned pages, explore our Help Center."
        actionLabel="Learn more"
        handleAction={() => {
          window.open('https://support.opensolar.com/hc/en-us/sections/11007475981839-New-Manage-UI', '_blank')
          handleEndTour()
        }}
      />
      <ManageFujiTour
        toggleEndDialog={() => {
          showEndDialog(true)
        }}
      />
    </>
  )
}
export default withWidth()(ManageFujiTourBanner)

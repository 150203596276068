import { Card, CardActions, CardContent } from '@material-ui/core'
import LaunchIcon from '@material-ui/icons/LaunchOutlined'
import { Button } from 'opensolar-ui'
import { Component } from 'react'
import { withTranslate } from 'react-admin'
import { NavLink } from 'react-router-dom'
import { DialogHelper } from '../util/misc'
import AboutUsDialog from './dialogs/AboutUsDialog'

const styles = {
  mainFlexContainer: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    flexDirection: 'column',
    display: 'flex',
    WebkitFlexDirection: 'column',
    zIndex: 1,
  },
  cardOnly: {
    minWidth: 210,
    padding: '0.5em 1em 1em 1em',
  },
}

const ChangeOrderMessage = ({ translate, selectedProject }) => {
  const project = window.projectForm.getState().values
  return (
    <Card style={{ textAlign: 'center' }}>
      <CardContent>
        <h1 style={{ textAlign: 'center' }}>{translate('No eligible change order systems available for viewing.')}</h1>
        <p style={{ textAlign: 'center' }}>
          {translate(
            'Please add a system with %{payment_option} payment option to appear in the Online Proposal page for your customer to re-accept.',
            { payment_option: project.payment_option_sold_title }
          )}
        </p>
      </CardContent>
      <CardActions>
        <NavLink to={`/projects/${selectedProject.id}/design`}>
          <Button variant="contained" size="small" startIcon={<LaunchIcon />}>
            {translate('Review Systems in Design Page')}
          </Button>
        </NavLink>
      </CardActions>
    </Card>
  )
}

class MyEnergyNotAvailable extends Component {
  constructor(props) {
    super(props)
    const initiatedChangeOrder = this.props.selectedProject.proposal_data.initiated_change_order
    const changeOrderMode = !!initiatedChangeOrder

    this.state = {
      activeDialog: null,
      changeOrderMode: changeOrderMode,
    }
  }

  showDialog(dialogName) {
    DialogHelper.afterOpen()
    this.setState({ activeDialog: dialogName })
  }

  closeDialog() {
    DialogHelper.beforeClose()
    this.setState({ activeDialog: null })
  }

  render = () => {
    const { hasBottomToolbar, translate } = this.props
    return (
      <div
        style={Object.assign({}, styles.mainFlexContainer, {
          paddingBottom: hasBottomToolbar ? 56 : 0,
        })}
      >
        {/*
        <MyEnergyHeader
          org={org}
          showDialog={this.showDialog.bind(this)}
        />*/}

        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {!this.state.changeOrderMode ? (
            <Card style={Object.assign({ width: '308px' }, styles.cardOnly)}>
              <h1>{translate('Welcome to MyEnergy')}</h1>
              {this.props.errorMessage && this.props.errorMessage.length > 0 && (
                <h2 style={{ color: '#C1292E' }}>{translate(this.props.errorMessage)}</h2>
              )}
            </Card>
          ) : (
            <ChangeOrderMessage translate={translate} selectedProject={this.props.selectedProject} />
          )}
        </div>
        <AboutUsDialog
          org={this.props.org}
          testimonials={this.props.testimonials}
          isOpen={this.state.activeDialog === 'AboutUsDialog'}
          closeDialog={() => this.closeDialog()}
        />
      </div>
    )
  }
}

export default withTranslate(MyEnergyNotAvailable)

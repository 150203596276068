"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.auth = void 0;
var action_1 = require("../core/action");
var state_1 = require("../core/state");
exports.auth = {
    // State
    // Ideally perhaps we could use the existing RoleType but it looks like perhaps we cannot import it here
    // import { RoleType } from 'types/roles'
    current_role: new state_1.DeepState(undefined),
    // Actions
    attemptAutoLogin: new action_1.Action(),
    loginWithToken: new action_1.Action(),
    refreshToken: new action_1.Action(),
    logout: new action_1.Action(),
    setProjectIdentifiersToken: new action_1.Action(),
};

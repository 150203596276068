import { FormControl, MenuItem, Select } from '@material-ui/core'
import { memo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { MountingSystemType } from 'types/mounting'
import { useGetMountingOptsInCountry } from './MountingSelector'

const useStyles = makeOpenSolarStyles((theme) => ({
  selector: {
    margin: '10px 0',
    backgroundColor: theme.greyLight3,
  },
  divider: {
    margin: '10px 0',
  },
}))

const MountingSystemTypeSelector = memo(
  ({
    disabled,
    selectedType,
    handleSelectMountingType,
  }: {
    disabled: boolean
    selectedType: MountingSystemType
    handleSelectMountingType(value: MountingSystemType): void
  }) => {
    const classes = useStyles()
    const mountingTypeOptions = useGetMountingOptsInCountry()

    return (
      <FormControl variant="outlined" size="small" fullWidth={true} className={classes.selector}>
        <Select
          disabled={disabled}
          value={selectedType}
          displayEmpty={false}
          onChange={(event) => {
            handleSelectMountingType(event.target.value as MountingSystemType)
          }}
        >
          {mountingTypeOptions.map((item) => (
            <MenuItem key={item.type} value={item.type}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }
)

export default MountingSystemTypeSelector

const getDesignString = (cellsActive: string[], rowStart: number, rows: number, colStart: number, cols: number) => {
  if (!cellsActive || rows === undefined || cols === undefined) return
  //first build up the matrix with only 'false'
  let matrix = [] as any
  for (let colNum = colStart; colNum <= cols; colNum++) {
    let temp = [] as any
    for (let rowNum = rowStart; rowNum <= rows; rowNum++) {
      temp.push(false)
    }
    matrix.push(temp)
  }
  //then iterate over cellsActive and drop in a true for each active cell
  cellsActive.forEach((active) => {
    //adjust for situations where the matrix starts negative in either direction
    let activeRow: number = parseInt(active.substring(0, active.indexOf(',')))
    activeRow = activeRow - rowStart
    let activeCol: number = parseInt(active.substring(active.indexOf(',') + 1))
    activeCol = activeCol - colStart
    matrix[activeCol][activeRow] = true
  })
  return matrix
}

export const getDesign = function (panelArray) {
  let bounds = panelArray?.getBounds()
  if (!bounds) return
  let [minX = 0, minY = 0, maxX = 0, maxY = 0] = bounds

  let orientation: 'PORTRAIT' | 'LANDSCAPE' = 'PORTRAIT'
  if (panelArray?.userData?.moduleLayout !== 'portrait') {
    orientation = 'LANDSCAPE'
  }

  let design = getDesignString(panelArray.cellsActive, minX, maxX, minY, maxY)

  return { orientation, design }
}

import { LOCALES_SUPPORTED_CUSTOMER_FACING } from 'App'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import CustomField from 'elements/field/CustomField'
import React, { useEffect, useState } from 'react'
import { SelectInput, TextInput, useLocale, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { DOCUMENT_TEMPLATE_CHOICES } from './DocumentTemplates'
import { getAvailableDocs, getLanguages, groupAndFilterDocumentsByLanguage, matchLocaleFromGrouped } from './util'

type PropTypes = {
  inputStyle: Object
  isEditForm: boolean
  isContractForm: boolean
  disabled?: boolean
}

const styles = {
  label: {
    minWidth: 100,
    maxWidth: 100,
    marginRight: 10,
  },
  field: {
    display: 'flex',
    minHeight: 45,
    alignItems: 'center',
    color: '#a2a0a0',
  },
}

const StyleDocumentTemplateField = (props: { fieldLabel: string; field: any }) => {
  return (
    <div style={styles.field}>
      <span style={styles.label}>{props.fieldLabel}</span>
      {props.field}
    </div>
  )
}

// const languages = getLanguages()
const DocumentTemplateFilterFields: React.FunctionComponent<PropTypes> = (props) => {
  // locale is only used for pre-selecting a language if no language is selected yet
  const locale = useLocale()
  const translate = useTranslate()

  const isStaff = useSelector(authSelectors.getIsStaff)
  const org = useSelector(orgSelectors.getOrg)
  const isFirstOrg = org?.id === 1
  const form = useForm()
  const formState = useFormState()

  const languages = getLanguages(LOCALES_SUPPORTED_CUSTOMER_FACING)
  const [filteredLoc, setFilteredLoc] = useState<any[]>([])
  const [docsAvailable, setDocsAvailable] = useState({})
  const [docsLoaded, setDocsLoaded] = useState<boolean>(false)
  const [templateChoices, setTemplateChoices] = useState<any[]>([])

  const selectedLocale = formState.values.locale

  const getDocsAvailable = () => {
    if (org?.country.iso2 && !docsLoaded) {
      getAvailableDocs(org?.country.iso2, org?.id)
        .then((res) => {
          if (res.data.length > 0) {
            var [groupedDocs, filteredList] = !props.isContractForm
              ? groupAndFilterDocumentsByLanguage(res.data, undefined, true)
              : groupAndFilterDocumentsByLanguage(res.data, true, true)
            setFilteredLoc(languages.filter((x) => Object.values(filteredList).find((y) => y === x['id'])))
            setDocsAvailable(groupedDocs)
            setDocsLoaded(true)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const updateTemplateChoices = (chosenLanguage) => {
    const newSet: any[] = []
    const docsForLocale = matchLocaleFromGrouped(docsAvailable, chosenLanguage, false)

    if (docsForLocale) {
      Object.values(docsForLocale).forEach((x: any) => {
        newSet.push({ id: x.id, name: x.title, type: x.type })
      })
    }
    setTemplateChoices(newSet)
  }

  useEffect(() => {
    if (!isFirstOrg) {
      if (org?.country.iso2) {
        getDocsAvailable()
      }
      if (filteredLoc.length > 0 && !Object.keys(filteredLoc).includes('') && !selectedLocale) {
        // Match locale if possible, otherwise just use the first result
        const defaultVal = filteredLoc.find((loc) => loc.id === locale)?.id || filteredLoc[0].id
        form.change('locale', defaultVal)
        updateTemplateChoices(defaultVal)
      }
    }
  }, [org, docsLoaded])

  const handleLanguageSelect = (e) => {
    if (!isFirstOrg && !props.isEditForm) {
      const selectedCode = e.target.value
      updateTemplateChoices(selectedCode)
    }
  }

  useEffect(() => {
    if (props.isContractForm && templateChoices.length > 0) {
      form.change('prefill_template_id', templateChoices[0]['id'])
    }
  }, [templateChoices])

  const handleTemplateSelect = (e) => {
    const findItem = templateChoices.find((x: any) => x.id === e.target.value)
    if (findItem) {
      form.change('type', findItem['type'])
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <StyleDocumentTemplateField
        fieldLabel={translate('Language')}
        field={
          <CustomField
            component={SelectInput}
            disabled={props.disabled}
            fullWidth={true}
            inputStyle={props.inputStyle}
            choices={isFirstOrg || props.isEditForm ? languages : filteredLoc}
            name="locale"
            source="locale"
            label={false}
            inputProps={{ onChange: handleLanguageSelect }}
          />
        }
      />
      {!props.isEditForm && !props.isContractForm ? (
        <>
          {isFirstOrg ? (
            <StyleDocumentTemplateField
              fieldLabel={translate('Type')}
              field={
                <CustomField
                  component={SelectInput}
                  disabled={props.disabled}
                  name="type"
                  source={'type'}
                  choices={DOCUMENT_TEMPLATE_CHOICES}
                  label={false}
                />
              }
            />
          ) : (
            <StyleDocumentTemplateField
              fieldLabel={translate('Prefill Template')}
              field={
                <CustomField
                  component={SelectInput}
                  disabled={props.disabled}
                  name="prefill_template_id"
                  source={'prefill_template'}
                  choices={templateChoices}
                  label={false}
                  inputProps={{ onChange: handleTemplateSelect }}
                />
              }
            />
          )}
        </>
      ) : (
        ''
      )}

      {isStaff && isFirstOrg ? (
        <>
          <StyleDocumentTemplateField
            fieldLabel="Countries include"
            field={
              <CustomField
                label={false}
                disabled={props.disabled}
                component={TextInput}
                fullWidth={true}
                name="countries_include"
                source="countries_include"
              />
            }
          />
          <StyleDocumentTemplateField
            fieldLabel="Countries exclude"
            field={
              <CustomField
                label={false}
                disabled={props.disabled}
                component={TextInput}
                fullWidth={true}
                name="countries_exclude"
                source="countries_exclude"
              />
            }
          />
        </>
      ) : (
        ''
      )}
    </div>
  )
}
export default DocumentTemplateFilterFields

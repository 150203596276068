import JsonInputField from 'elements/field/JsonInputField'
import lodash from 'lodash'
import { InputProps, useInput } from 'ra-core'
import { Labeled } from 'ra-ui-materialui'
import React, { useMemo, useRef } from 'react'
import { JsonFormsDebuggable, JsonFormsDebuggableProps } from './JsonFormsDebuggable'

type Props = InputProps &
  Omit<JsonFormsDebuggableProps, 'onChange' | 'data' | 'validate'> & {
    noFallback?: boolean
    fullWidth?: boolean
    stringify?: boolean
  }

export const JsonFormsInput = (props: Props) => {
  const errors = useRef<any>(null)
  const validate = () => {
    return errors.current
  }
  const { id, input, meta, isRequired } = useInput({ ...props, validate })

  const realValue = useMemo(() => {
    if (props.stringify && input.value === null) return null
    return props.stringify ? JSON.parse(input.value) : input.value
  }, [input.value])

  if (!props.noFallback && !props.schema) {
    return <JsonInputField source={props.source} resource={props.resource} />
  }

  const onChange = (newData: any, errors: any) => {
    errors.current = errors

    // JSON Forms dispatches onChange whenever realValue is updated, not just when there has been user input
    // It also clones the data object, making it impossible to check by reference
    // Whithout this check, the change handler can be fired recursively, crashing the app
    if (lodash.isEqual(newData, realValue)) return

    const newValue = props.stringify ? JSON.stringify(newData) : newData
    input.onChange(newValue)
  }

  return (
    <Labeled
      id={id}
      label={props.label}
      source={props.source}
      resource={props.resource}
      isRequired={isRequired}
      meta={meta}
      input={input}
      fullWidth={props.fullWidth}
    >
      <JsonFormsDebuggable data={realValue} onChange={onChange} schema={props.schema} uischema={props.uischema} />
    </Labeled>
  )
}

import { Tooltip } from '@material-ui/core'
import { Info, InfoOutlined } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { CurrencySymbolContext } from 'contexts/CurrencySymbolContext'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import { useTranslate } from 'ra-core'
import { Button } from 'ra-ui-materialui'
import React, { FC, ReactElement } from 'react'
import { useFormState } from 'react-final-form'
import { currencySymbolForCountry } from 'util/misc'
import { useStyles } from './styles'
import GeneralInfoFields from './tariffInputs/GeneralInfoFields'
import TaxDetailsFields from './tariffInputs/TaxDetailsFields'

type CustomToolTipTypes = {
  message: string | JSX.Element
  children: ReactElement<any, any> | null
  interactive?: boolean
}

export const InputWithToolTip: FC<CustomToolTipTypes> = ({ children, message, interactive }) => {
  const classes = useStyles()
  const translate = useTranslate()
  if (!message) {
    return children
  }
  return (
    <div className={classes.row}>
      {children}
      <Tooltip
        interactive={interactive}
        placement="right"
        title={typeof message === 'string' ? translate(message) : message}
      >
        <InfoOutlined className={classes.infoIcon} />
      </Tooltip>
    </div>
  )
}

type PropTypes = {
  disabled?: boolean
  isEdit: boolean
  isCustom?: boolean
  defaultCountry?: {
    url: string
    iso2: string
  }
}

const TariffInputs: FC<PropTypes> = ({ disabled, isEdit, isCustom, defaultCountry }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const formState = useFormState()
  const formValues = formState?.values
  const countryIso2 = formValues['country_iso2'] || defaultCountry?.iso2
  const currencySymbol = currencySymbolForCountry(countryIso2)
  return (
    <CurrencySymbolContext.Provider value={currencySymbol}>
      {isEdit && !isCustom && (
        <Button
          variant="contained"
          color="default"
          size="medium"
          label="Report Incorrect Tariff"
          startIcon={<Info />}
          onClick={() => {
            window.open('https://support.opensolar.com/hc/en-us/requests/new', '_blank')
            logAmplitudeEvent('report_incorrect_tariff_clicked', {
              id: formValues?.id,
              code: formValues?.code,
              utilityName: formValues?.utility_name,
            })
          }}
          className={classes.bottomMargin}
        />
      )}

      <AccordionCard
        defaultExpanded={true}
        disabled={disabled}
        name={'general-info'}
        title={translate('General Info')}
        content={GeneralInfoFields}
        titleIcon={() => <></>}
        contentProps={{ isCustom }}
      />
      <AccordionCard
        defaultExpanded={true}
        disabled={disabled}
        name={'tax-details'}
        title={translate('Charges, Credits & Tax Details')}
        content={TaxDetailsFields}
        titleIcon={() => <></>}
        contentProps={{ isCustom, countryIso2: countryIso2 }}
      />
    </CurrencySymbolContext.Provider>
  )
}
export default TariffInputs

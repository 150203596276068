// @ts-nocheck
import { authSelectors } from 'ducks/auth'
import { permissionsSelectors } from 'ducks/permissions'
import CustomField from 'elements/field/CustomField'
import OverviewInputs from 'elements/input/OverviewInputs'
import React from 'react'
import {
  BooleanInput,
  Edit,
  FormDataConsumer,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useTranslate,
} from 'react-admin'
import { useSelector } from 'react-redux'
import { defaultValueFunc, formatSubmitValues, RenderInputs } from 'resources/proposalTemplates/ProposalTemplates'
import PVProSellFormContent from 'resources/proposalTemplates/PVProSellFormContent'
import { getOrgFromState } from 'util/org'

const CustomizedToolbar = (props: any) => {
  return (
    <Toolbar style={{ zIndex: 3, position: 'absolute', bottom: 0, boxSizing: 'border-box', width: '100%' }} {...props}>
      <div>
        <SaveButton
          {...props}
          redirect={false}
          submitOnEnter={true}
          onSave={(values: object, redirect: string, form: any, defaultSaveFunc: Function) => {
            defaultSaveFunc(values, redirect, { onSuccess: props.onSuccess, onFailure: props.onFailure })
          }}
        />
      </div>
    </Toolbar>
  )
}

const validateTitle = (values: any, translate: any) => {
  const errors = {}
  if (!values.title) {
    errors.title = [translate('Title is required.')]
  }
  return errors
}

const ProposalTemplateEditor = ({ handleChange, handleClose, projectId, templateOwner, ...rest }: any) => {
  const translate = useTranslate()
  const isStaff = useSelector((state) => Boolean(state.auth?.is_staff))
  const enableExhibit = useSelector((state) => getOrgFromState(state)?.enable_exhibit)
  const api_key_pvsell = useSelector(authSelectors.getPvSellKey)
  const { allowEdit } = useSelector(permissionsSelectors.getPermissionByKey('proposal_template'))
  const disabled = !templateOwner || !allowEdit

  return (
    <Edit
      style={{ padding: 0 }}
      {...rest}
      hasDelete={false}
      basePath={'/proposal_templates'}
      resource={'proposal_templates'}
      hideBreadCrumbs={true}
    >
      <SimpleForm
        hideCancelButton={true}
        defaultValue={defaultValueFunc}
        redirect={false}
        style={{ marginBottom: 80 }}
        formatSubmitValues={formatSubmitValues}
        //keep dirty otherwise form value get reset after state change in <MyEnergy />
        keepDirtyOnReinitialize={true}
        toolbar={
          !disabled ? (
            <CustomizedToolbar
              onSuccess={() => {
                handleClose()
                handleChange()
              }}
            />
          ) : null
        }
        validate={(values: any) => validateTitle(values, translate)}
      >
        <OverviewInputs>
          <CustomField
            component={TextInput}
            inputStyle={{
              width: 256,
            }}
            name="title"
            source="title"
            disabled={disabled}
          />
          <CustomField component={BooleanInput} name="is_default" source="is_default" disabled={disabled} />
          <CustomField
            component={BooleanInput}
            name="is_archived"
            source="is_archived"
            defaultValue={false}
            disabled={disabled}
          />
          {enableExhibit && (
            <CustomField
              component={BooleanInput}
              name="accessible_to_partners"
              source="accessible_to_partners"
              defaultValue={false}
              disabled={!isStaff || !templateOwner}
            />
          )}
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData?.pvprosell_template_id &&
              api_key_pvsell && (
                <PVProSellFormContent
                  pvprosell_template_id={formData.pvprosell_template_id}
                  api_key_pvsell={api_key_pvsell}
                  disabled={disabled}
                  projectId={projectId}
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
        </OverviewInputs>
        <TextInput
          fullWidth={true}
          multiline={true}
          source="settings"
          style={{ display: 'none' }}
          disabled={disabled}
        />
        <RenderInputs enableExhibit={enableExhibit} disabled={disabled} />
      </SimpleForm>
    </Edit>
  )
}

export default ProposalTemplateEditor

import Button from 'elements/button/Button'
import CustomField from 'elements/field/CustomField'
import DependentInput from 'elements/input/DependentInput'
import Tooltip from 'elements/tooltip/Tooltip'
import EditorComponent from 'elements/wysiwyg/EditorComponentWrapper'
import React from 'react'
import { BooleanInput, FormDataConsumer, SelectArrayInput, TextInput, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type PropTypes = {
  DraftEditorWithContent: React.FunctionComponent
}

const defaultEmailSubject = 'Your Solar Proposal and Signed Contract'
const defaultEmailBody = 'default_email_body_content'

const pdfTypesChoices = [
  { id: 'combined_proposal_contract', name: 'Combined Proposal and Contract PDF' },
  { id: 'proposal_only', name: 'Proposal PDF Only' },
  { id: 'contract_only', name: 'Contract PDF Only' },
]

const useStyles = makeOpenSolarStyles((theme) => ({
  fieldRow: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const EmailAcceptanceDocsToggle: React.FunctionComponent<PropTypes> = (props) => {
  const translate = useTranslate()
  const form = useForm()
  const classes = useStyles()
  // const isEnabled = form?.getState()?.values.email_acceptance_files_enabled

  // const pdfTypesChoicesTrans = useMemo(() => pdfTypesChoices.map((i) => ({ ...i, name: translate(i.name) })), [
  //   pdfTypesChoices,
  // ])
  const emailSubject = form.getState().values?.org_configs?.acceptance_files_email_subject
  // const emailBody = form.getState().values?.org_configs?.acceptance_files_email_body

  // set email body to default if there isn't a body but this feature is enabled
  // Doing this here instead of using defaultValue so that orgs can 'revert' by clearing the field
  // useEffect(() => {
  //   if (isEnabled) {
  //     if (!emailSubject)
  //       form.change('org_configs.acceptance_files_email_subject', translate('Your Solar Proposal and Signed Contract'))
  //     if (!emailBody) form.change('org_configs.acceptance_files_email_body', translate('default_email_body_content'))
  //   }
  // }, [isEnabled, emailSubject, emailBody])

  const defaultEmailSubjectTrans = translate(defaultEmailSubject)

  const resetSubject = () => {
    form.change('org_configs.acceptance_files_email_subject', defaultEmailSubjectTrans)
  }

  return (
    <div style={{ marginTop: '30px' }}>
      <h3>{translate('Managing Files on Proposal Acceptance')}</h3>
      <FormDataConsumer>
        {() => (
          <div>
            <CustomField
              component={SelectArrayInput}
              style={{ width: 256 }}
              fieldName="pdf_types"
              source="pdf_types"
              label="PDF(s) to generate"
              options={{
                listStyle: { overflow: 'auto', display: 'inline-grid', maxHeight: 200 },
              }}
              name={'pdf_types'}
              choices={pdfTypesChoices}
              {...props}
            />
            <CustomField
              component={BooleanInput}
              formControlLabelStyle={{ width: '100%' }}
              source="email_acceptance_files_enabled"
              name="email_acceptance_files_enabled"
              label="Email proposal and contract PDF to customer on proposal acceptance"
              {...props}
            />
            <DependentInput dependsOn="email_acceptance_files_enabled" value={true}>
              <>
                <div className={classes.fieldRow}>
                  <TextInput
                    label="Email Subject"
                    source="org_configs.acceptance_files_email_subject"
                    name="org_configs.acceptance_files_email_subject"
                    defaultValue={defaultEmailSubjectTrans}
                    fullWidth
                  />
                  <Tooltip title="Reset to default">
                    <Button label="Reset" onClick={resetSubject} disabled={defaultEmailSubjectTrans === emailSubject} />
                  </Tooltip>
                </div>
                <p className="small">
                  {translate(
                    'Use the editor below to customize the body of the email that the customer will receieve with their documents.'
                  )}
                </p>
                <EditorComponent
                  allowUploadImage={false}
                  mentionContext="org_project_and_acceptance_doc"
                  name="org_configs.acceptance_files_email_body"
                  source="org_configs.acceptance_files_email_body"
                  label="Completed Proposal and Contract Email Message"
                  defaultValue={translate(defaultEmailBody)}
                  resetToDefault={translate(defaultEmailBody)}
                  charCounterMax={2200}
                />
                {/* <CustomField
                  component={props.DraftEditorWithContent}
                  allowUploadImage={false}
                  mentionContext="org_project_and_acceptance_doc"
                  name="org_configs.acceptance_files_email_body"
                  source="org_configs.acceptance_files_email_body"
                  label="Completed Proposal and Contract Email Message"
                  defaultValue={translate(defaultEmailBody)}
                  charCounterMax={2200}
                /> */}
              </>
            </DependentInput>
          </div>
        )}
      </FormDataConsumer>
    </div>
  )
}

export default EmailAcceptanceDocsToggle

import Alert from 'elements/Alert'
import { useTranslate } from 'ra-core'

import React from 'react'
import { ModulePropTypes, ModuleReleaseAlertWarning } from '../types'

export const IAPModule_ReleaseAlertWarning: React.FC<ModulePropTypes<ModuleReleaseAlertWarning>> = ({
  module,
  translationSubs,
  minsRemaining,
}) => {
  const translate = useTranslate()

  return (
    <>
      <Alert severity="info" styles={{ width: '100%' }}>
        {translate(module.msg || 'Expected %{timeRemaining} remaining before update starts.', translationSubs)}
      </Alert>
    </>
  )
}

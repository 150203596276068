import { List } from 'elements/react-admin/List'
import React from 'react'
import {
  AutocompleteInput,
  Datagrid,
  FilterComp,
  ReferenceInput,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'
import { styles as standardStyles } from 'styles'

export const GlobalProjectFilter = (props) => {
  const translate = useTranslate()
  return (
    <FilterComp {...props}>
      <TextInput label="pos.search" source="q" style={standardStyles.FILTER_FIELD_STYLE} />
      <ReferenceInput
        label={translate('Country')}
        source="country"
        reference="countries"
        sort={{ field: 'name', order: 'ASC' }}
      >
        <AutocompleteInput
          optionText="name"
          optionValue="id"
          options={{ style: standardStyles.FILTER_FIELD_STYLE, placeholder: translate('Enter a country') }}
        />
      </ReferenceInput>
    </FilterComp>
  )
}

export const GlobalProjectList = (props) => {
  return (
    <List hasSearch={true} filters={<GlobalProjectFilter />} minQuantityToShowAsList={0} {...props}>
      <Datagrid cardTextStyle={{ padding: 15, height: 150, overflow: 'auto' }}>
        <TextField source="id" />
        <TextField source="address" />
        <TextField source="org_name" sortable={false} />
        <TextField source="state" sortable={false} />
        <TextField source="zip" sortable={false} />
        <TextField source="country_iso2" sortable={false} />
        <TextField source="business_name" />
      </Datagrid>
    </List>
  )
}

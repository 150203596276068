import React, { useContext } from 'react'
import { Edit, SimpleForm } from 'react-admin'
import { defaultValueFunc, formatSubmitValues } from 'resources/utilityTariffs/UtilityTariff'
import { useDefaultTariffCountry } from '../hooks'
import { UtilityTariffDialogContext } from '../index'
import EditTariffToolbar from './EditTariffToolbar'
import TariffFormContent from './TariffFormContent'

const getCustomisedTariffDataFromProjectForm = (formValues: any, resource: string) => {
  if (!formValues) {
    return undefined
  }

  if (resource === 'custom_tariff_current') {
    return formValues['utility_tariff_current_custom']
  } else if (resource === 'custom_tariff_proposed') {
    return formValues['utility_tariff_proposed_custom']
  } else {
    return undefined
  }
}

const EditTariff = () => {
  const { state } = useContext(UtilityTariffDialogContext)
  const { id, basePath, resource } = state
  const customisedTariffData =
    resource && getCustomisedTariffDataFromProjectForm(window.projectForm?.getState()?.values, resource)
  const defaultCountry = useDefaultTariffCountry()
  return (
    //@ts-ignore
    <Edit title="Edit Applied Tariff Details" id={id} hideBreadCrumbs={true} basePath={basePath} resource={resource}>
      <SimpleForm
        defaultValue={(record: any) => defaultValueFunc(record, customisedTariffData)}
        formatSubmitValues={formatSubmitValues}
        //@ts-ignore
        toolbar={<EditTariffToolbar />}
      >
        {/*@ts-ignore*/}
        <TariffFormContent defaultCountry={defaultCountry} isEdit={true} />
      </SimpleForm>
    </Edit>
  )
}

export default EditTariff

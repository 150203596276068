import CheckCircleOutline from '@material-ui/icons/CheckCircleOutlineOutlined'
import { authSelectors } from 'ducks/auth'
import Alert from 'elements/Alert'
import { ControlBreadCrumbs } from 'elements/BreadCrumbs'
import Button from 'elements/proUXButtons/ProUXButton'
import React, { useEffect, useState } from 'react'
import { useLogoutIfAccessDenied, useNotify, useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'
import { reloadEntireApp } from 'util/misc'
import useTranslateParse from 'util/useTranslateParse'
import { fetchMfaConfig } from './util'

// same component is used for both the org view and the role view
const ConfigureMFA: React.FC = () => {
  const translate = useTranslate()
  const translateParse = useTranslateParse()
  const notify = useNotify()
  const dispatch = useDispatch()
  const logoutIfAccessDenied = useLogoutIfAccessDenied()

  const [loading, setLoading] = useState<boolean>(false)

  const orgId = useSelector(authSelectors.getOrgId)
  const isEnabled: boolean = useSelector(authSelectors.getIsMfaEnabledOnOrg)

  useEffect(() => {
    fetchMfaConfig(dispatch, translate, notify, setLoading, logoutIfAccessDenied)
  }, [])

  const toggleMFA = () => {
    const restClientInstance = restClient(window.API_ROOT + '/api')
    // TODO: type of orgId is number|undefined; probably should not execute this code if orgId is undefined.
    restClientInstance('CUSTOM_PUT', 'custom', {
      url: `org_enable_mfa/${orgId}/`,
      data: {
        require_mfa: !isEnabled,
      },
    })
      .then(() => {
        notify(translate("Your Organisation's 2FA settings have been updated"), 'success')
        reloadEntireApp()
      })
      .catch((err: any) => {
        console.warn(err)
        notify('Unable to update two-factor authentication setting', 'warning')
      })
  }

  return (
    <div style={{ padding: '8px 24px 60px 24px' }}>
      <div style={{ marginBottom: '20px', padding: '10px' }}>
        <ControlBreadCrumbs currentPage="Two-factor authentication" />
      </div>
      <div style={{ padding: '10px 10px 60px 10px' }}>
        <h1 style={{ fontSize: '20px', margin: '20px 0px', fontWeight: 500 }}>
          {translate('Two-Factor Authentication (2FA)')}
        </h1>
        <p>
          {translate(
            '2FA adds an additional layer of security to your account by requiring more than just a password to sign in.'
          )}
          <br />
          <br />
          {translate(
            'To increase the security of your organisation account, you can enforce all members of your organisation to have 2FA enabled.'
          )}
        </p>
        {isEnabled ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CheckCircleOutline style={{ color: '#50C878' }} />
            <p>
              {translateParse(
                'Two-Factor Authentication is enforced on all members of your organisation. <button>Click here<button> to remove it.',
                {
                  button: (label) => (
                    <button
                      onClick={toggleMFA}
                      style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                    >
                      <u>{label}</u>
                    </button>
                  ),
                }
              )}
            </p>
          </div>
        ) : (
          <>
            <Alert severity={'info'} styles={{ maxWidth: '50vw' }}>
              {translateParse(
                'Please note that enforcing 2FA on your organisation will logout all the members (including yourself) who do not have 2FA enabled and take them to the 2FA setup page. If you wish to manage 2FA on your user account only, please <a>click here<a>.',
                {
                  a: (label) => (
                    <a style={{ color: '#2C499D' }} href={'#/mfa/status'} target="_blank" rel="noreferrer">
                      {label}
                    </a>
                  ),
                }
              )}
            </Alert>
            <div style={{ marginTop: '30px' }}>
              <Button
                label={'Enforce two-factor authentication'}
                type="secondary"
                onClick={toggleMFA}
                loading={loading}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
export default ConfigureMFA

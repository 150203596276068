import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orgSelectors } from 'ducks/orgs'
import Alert from 'elements/Alert'
import { Button } from 'opensolar-ui'
import { getSupplierConfig } from 'pages/ordering/configs'
import type LineItemType from 'pages/ordering/OrderLineItem'
import { getLineItemFromHardwareDetail } from 'projectSections/sections/design/sideDrawer/bom/useMapHardwareDetailsToBomLineItems'
import getHardwareDetailFromSystem from 'projectSections/sections/design/systems/tabs/summary/hardware/getHardwareDetailFromSystem'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { doNotTranslate } from 'util/misc'

const DistributorCogsRefreshNotification = ({ onNotShowAgain }: { onNotShowAgain(): void }) => {
  const [showWarning, setShowWarning] = useState(true)
  const selectedDistributor = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const currentDistributorConfig = getSupplierConfig(selectedDistributor)

  useEffect(() => {
    logAmplitudeEvent('cogs_refresh_notification_interacted', {
      action: 'viewed',
      context: 'popup_shown',
      currentDistributor: currentDistributorConfig?.name,
    })
  }, [])

  const handleIgnore = () => {
    logAmplitudeEvent('cogs_refresh_notification_interacted', {
      action: 'clicked',
      context: 'not_show_again',
      currentDistributor: currentDistributorConfig?.name,
    })
    onNotShowAgain()
    setShowWarning(false)
  }

  const handleClose = () => {
    logAmplitudeEvent('cogs_refresh_notification_interacted', {
      action: 'clicked',
      context: 'backdrop_close',
      currentDistributor: currentDistributorConfig?.name,
    })
    setShowWarning(false)
  }

  const handleOverride = () => {
    logAmplitudeEvent('cogs_refresh_notification_interacted', {
      action: 'clicked',
      context: 'override',
      currentDistributor: currentDistributorConfig?.name,
    })
    const systems = window.editor.getSystems()
    systems.forEach((system) => {
      const hardwareDetails = getHardwareDetailFromSystem(system)
      const bomLineItems: LineItemType[] = []
      hardwareDetails.forEach((detail) => {
        const lineItem = getLineItemFromHardwareDetail({
          hardwareDetail: detail,
          supplierFilterKey: currentDistributorConfig?.filterKey,
        })
        if (lineItem) {
          bomLineItems.push(lineItem)
        }
      })
      system.refreshComponentCostOverrides(bomLineItems)
    })
    setShowWarning(false)
  }

  return (
    <Dialog open={showWarning} onClose={handleClose}>
      <DialogTitle>{doNotTranslate('COGS Pricing Update Notification')}</DialogTitle>
      <DialogContent>
        <Alert severity="warning">
          {doNotTranslate(`Our system has detected a recent change in distributor. Would you like to update the COGS pricing to reflect
          the newly selected distributor - ${currentDistributorConfig?.name}?`)}
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOverride} color="primary" variant="contained">
          {doNotTranslate('Override')}
        </Button>
        <Button onClick={handleIgnore}>{doNotTranslate('Ignore for this project')}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default DistributorCogsRefreshNotification

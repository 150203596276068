import { FC } from 'react'
import { CodeBlock } from '../CodeBlock'
import ComponentPage from '../ComponentPage'
import { MenuBasic } from './MenuUsage'

const MenuUsage_code = require('!!raw-loader!./MenuUsage').default

export const DemoMenu: FC = () => {
  return (
    <ComponentPage
      name={'Menu'}
      import="import { MenuItem, PopoutMenu } from 'opensolar-ui'"
      description={'Use Menu to create a context menu'}
    >
      <CodeBlock name="Menu Basic Usage" code={MenuUsage_code} codePart={1} description="How to use the menu">
        <MenuBasic />
      </CodeBlock>
    </ComponentPage>
  )
}

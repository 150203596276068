var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { linkToRecord } from 'ra-core';
import { Children, cloneElement } from 'react';
import Link from '../Link';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: -theme.spacing(1),
        marginBottom: -theme.spacing(1),
    },
    link: {},
}); }, { name: 'RaSingleFieldList' });
// useful to prevent click bubbling in a datagrid with rowClick
var stopPropagation = function (e) { return e.stopPropagation(); };
var sanitizeRestProps = function (_a) {
    var currentSort = _a.currentSort, setSort = _a.setSort, loading = _a.loading, loaded = _a.loaded, props = __rest(_a, ["currentSort", "setSort", "loading", "loaded"]);
    return props;
};
// Our handleClick does nothing as we wrap the children inside a Link but it is
// required by ChipField, which uses a Chip from material-ui.
// The material-ui Chip requires an onClick handler to behave like a clickable element.
var handleClick = function () { };
/**
 * Iterator component to be used to display a list of entities, using a single field
 *
 * @example Display all the books by the current author
 * <ReferenceManyField reference="books" target="author_id">
 *     <SingleFieldList>
 *         <ChipField source="title" />
 *     </SingleFieldList>
 * </ReferenceManyField>
 *
 * By default, it includes a link to the <Edit> page of the related record
 * (`/books/:id` in the previous example).
 *
 * Set the linkType prop to "show" to link to the <Show> page instead.
 *
 * @example
 * <ReferenceManyField reference="books" target="author_id" linkType="show">
 *     <SingleFieldList>
 *         <ChipField source="title" />
 *     </SingleFieldList>
 * </ReferenceManyField>
 *
 * You can also prevent `<SingleFieldList>` from adding link to children by setting
 * `linkType` to false.
 *
 * @example
 * <ReferenceManyField reference="books" target="author_id" linkType={false}>
 *     <SingleFieldList>
 *         <ChipField source="title" />
 *     </SingleFieldList>
 * </ReferenceManyField>
 */
function SingleFieldList(props) {
    var classesOverride = props.classes, className = props.className, ids = props.ids, data = props.data, loaded = props.loaded, resource = props.resource, basePath = props.basePath, children = props.children, linkType = props.linkType, filterFunction = props.filterFunction, _a = props.getResourceLinkPath, getResourceLinkPath = _a === void 0 ? linkToRecord : _a, rest = __rest(props, ["classes", "className", "ids", "data", "loaded", "resource", "basePath", "children", "linkType", "filterFunction", "getResourceLinkPath"]);
    var classes = useStyles(props);
    if (loaded === false) {
        return _jsx(LinearProgress, {});
    }
    return (_jsx("div", __assign({ className: classnames(classes.root, className) }, sanitizeRestProps(rest), { children: ids.map(function (id) {
            if (filterFunction && filterFunction(data[id]) === false) {
                return null;
            }
            var resourceLinkPath = linkType ? getResourceLinkPath(basePath, id, linkType) : false;
            if (resourceLinkPath) {
                return (_jsx(Link, __assign({ className: classes.link, to: resourceLinkPath, onClick: stopPropagation }, { children: cloneElement(Children.only(children), {
                        record: data[id],
                        resource: resource,
                        basePath: basePath,
                        // Workaround to force ChipField to be clickable
                        onClick: handleClick,
                    }) }), id));
            }
            return cloneElement(Children.only(children), {
                key: id,
                record: data[id],
                resource: resource,
                basePath: basePath,
            });
        }) })));
}
SingleFieldList.propTypes = {
    basePath: PropTypes.string,
    children: PropTypes.element.isRequired,
    classes: PropTypes.object,
    className: PropTypes.string,
    data: PropTypes.object,
    ids: PropTypes.array,
    linkType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    resource: PropTypes.string,
    getResourceLinkPath: PropTypes.func,
};
SingleFieldList.defaultProps = {
    classes: {},
    linkType: 'edit',
};
export default SingleFieldList;

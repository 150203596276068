import { Tooltip } from '@material-ui/core'
import { ArrowDownward, ArrowUpward, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { myEnergySelectors } from 'ducks/myEnergy'
import { orgSelectors } from 'ducks/orgs'
import { triggerSystemRefresh } from 'ducks/paymentOptionSelection'
import { Checkbox } from 'opensolar-ui'
import SunlightDealerFeeSwitch from 'projectSections/sections/design/systems/SunlightDealerFeeSwitch'
import { useLocale, useTranslate } from 'ra-core'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { StudioSystemType } from 'types/global'
import { formatCurrencyWithSymbol, systemNameOrDefault, urlToId } from 'util/misc'
import { useGetMonthlyBillSavings } from '../hooks'
import AddPaymentOptionsButton from '../paymentOptionSelection/AddPaymentOptionsButton'
import SoldChip from '../SoldChip'
import SystemSavingsTooltip from './SystemSavingsTooltip'

type PropTypes = {
  system: StudioSystemType
  index: number
  totalSystemCount: number
  toggleIsExpanded: () => void
  isExpanded: boolean
}

const useStyles = makeOpenSolarStyles((theme) => ({
  mainWrapper: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    width: '100%',
    marginTop: '20px',
  },
  titleAndSavingsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    flex: 8,
  },
  checkboxWrapper: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  dragIconsWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  dragIcon: {
    cursor: 'pointer',
  },
  systemNameWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    flex: 7,
  },
  systemSoldChip: {
    marginLeft: '10px',
    backgroundColor: theme.white,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.alert_success,
  },
  systemSoldChipContents: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  soldChipText: {
    marginLeft: '5px',
    color: theme.alert_success,
  },
  systemOrderText: {
    color: theme.greyMid1,
    fontSize: '12px',
  },
  bigText: {
    fontSize: '20px',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    },
    fontWeight: 300,
    tetAlign: 'center',
  },
  savingsWrapper: {
    flex: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tooltipWrapper: {
    marginLeft: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  systemActionWrapper: {
    flex: 4,
  },
  actionsWrapper: {
    flex: 4,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  actionsRow: {},
  buttonCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  expandIcon: {
    cursor: 'pointer',
  },
}))

const SystemRowTitle: React.FC<PropTypes> = (props) => {
  const project = useFormState().values

  const justSoldSystemUuid = useSelector(myEnergySelectors.getSystemUuidForcedMarkedAsSold)

  const getThisSystemIsSold = useCallback(() => {
    if (project.system_sold || justSoldSystemUuid) {
      const systemSoldId = project.system_sold ? urlToId(project.system_sold) : undefined
      const soldSystemUuid = systemSoldId
        ? project?.systems?.find((sys) => sys.id === systemSoldId)?.uuid
        : justSoldSystemUuid
      if (soldSystemUuid && soldSystemUuid === props.system?.uuid) return true
    }
    return false
  }, [justSoldSystemUuid, project.system_sold])

  const [hasSunlightPmt, setHasSunlightPmt] = useState<boolean>(false)
  const [isSold, setIsSold] = useState<boolean>(getThisSystemIsSold())

  useEffect(() => {
    setIsSold(getThisSystemIsSold())
  }, [project.system_sold, justSoldSystemUuid])

  useEffect(() => {
    if (props.system.payment_options?.length) {
      let sunlightPmt = props.system.payment_options.find((pmt) => pmt.integration === 'sunlight')
      if (sunlightPmt) setHasSunlightPmt(true)
      else setHasSunlightPmt(false)
    }
  }, [props.system.payment_options])

  const classes = useStyles()
  const translate = useTranslate()
  const form = useForm()
  const dispatch = useDispatch()

  const currencySymbol = useSelector(orgSelectors.getCurrencySymbolFromOrg)
  const locale = useLocale()
  const billSavings = useGetMonthlyBillSavings(props.system)

  const onSystemVisibilityToggle = () => {
    let newVal = !props.system.show_customer
    if (newVal) {
      logAmplitudeEvent('payments_page_make_system_visible', {
        project_id: project?.id,
        placement: 'system title checkbox',
      })
    } else {
      logAmplitudeEvent('payments_page_make_system_hidden', {
        project_id: project?.id,
        placement: 'system title checkbox',
      })
    }
    const system = window.editor.getSystems()?.find((sys) => sys.uuid === props.system.uuid)
    window.editor.execute(new window.SetValueCommand(system, 'show_customer', newVal))
    form.mutators.markFieldAsDirty('design')
    form.change('design', 'has unsaved change')
    dispatch(triggerSystemRefresh())
    if (newVal && !props.isExpanded) props.toggleIsExpanded()
    else if (!newVal && props.isExpanded) props.toggleIsExpanded()
  }

  const onSystemReorder = (direction: 'up' | 'down') => {
    let allSystems = window.editor.getSystems()
    let currentOrder = props.system.order
    let newOrder = direction === 'up' ? currentOrder - 1 : currentOrder + 1
    let systemToReplace = allSystems.find((sys) => sys.order === newOrder)
    if (systemToReplace) {
      window.editor.execute(new window.SetValueCommand(systemToReplace, 'order', currentOrder))
      window.editor.execute(new window.SetValueCommand(props.system, 'order', newOrder))
      form.mutators.markFieldAsDirty('design')
      form.change('design', 'has unsaved change')
      dispatch(triggerSystemRefresh())
      logAmplitudeEvent('payments_page_system_reordered', { project_id: project?.id })
    }
  }

  return (
    <div className={classes.mainWrapper}>
      <div className={classes.titleAndSavingsWrapper}>
        <div className={classes.checkboxWrapper} id={`system_checkbox_${props.index}`}>
          <div className={classes.dragIconsWrapper}>
            {props.index > 0 && (
              <Tooltip placement="top-end" title="Move System Up">
                <ArrowUpward
                  htmlColor="rgb(117, 117, 117)"
                  className={classes.dragIcon}
                  onClick={() => onSystemReorder('up')}
                />
              </Tooltip>
            )}
            {props.index < props.totalSystemCount - 1 && (
              <Tooltip placement="bottom-end" title="Move System Down">
                <ArrowDownward
                  htmlColor="rgb(117, 117, 117)"
                  className={classes.dragIcon}
                  onClick={() => onSystemReorder('down')}
                />
              </Tooltip>
            )}
          </div>
          <Checkbox checked={props.system.show_customer} onChange={onSystemVisibilityToggle} />
        </div>
        <div className={classes.systemNameWrapper}>
          <div>
            <div className={classes.systemOrderText}>
              {translate('System %{system_index}/%{system_count}', {
                system_index: props.index + 1,
                system_count: props.totalSystemCount,
              })}
            </div>
            <div className={classes.bigText}>{systemNameOrDefault(props.system.userData)}</div>
          </div>
          {isSold && (
            <SoldChip
              tooltipText={translate(
                'Either the customer has accepted the proposal for this system or this system has been manually marked as sold in the Info Page'
              )}
            />
          )}
        </div>
        <div className={classes.savingsWrapper}>
          <span className={classes.bigText}>
            {translate('%{savings}/month Estimated Utility Savings', {
              savings: formatCurrencyWithSymbol(billSavings, currencySymbol, locale, 0),
            })}
          </span>
          <div className={classes.tooltipWrapper}>
            <div>
              <SystemSavingsTooltip />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.actionsWrapper}>
        <div className={classes.actionsRow}>
          {hasSunlightPmt && (
            <SunlightDealerFeeSwitch
              initialValue={!!props.system.pricing.force_apply_dealer_fee_discounts}
              systemUuid={props.system.uuid}
            />
          )}
        </div>
        <div className={classes.buttonCol}>
          <div className={classes.expandIcon} onClick={props.toggleIsExpanded}>
            {props.isExpanded ? (
              <KeyboardArrowUp htmlColor="rgb(117, 117, 117)" />
            ) : (
              <KeyboardArrowDown htmlColor="rgb(117, 117, 117)" />
            )}
          </div>
          <div id={`add-pmt-dialog-open-button-${props.index}`}>
            <AddPaymentOptionsButton system={props.system} systemIndex={props.index} />
          </div>
        </div>
      </div>
    </div>
  )
}
export default SystemRowTitle

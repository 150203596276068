import { authSelectors } from 'ducks/auth'
import type LineItemType from 'pages/ordering/OrderLineItem'
import { useSelector } from 'react-redux'

export type QuantitySelectorType = 'bulk' | 'discount'

const useQuantitySelectorType = ({ lineItem }: { lineItem: LineItemType }): QuantitySelectorType | undefined => {
  // Todo: use getAvailableHardwareSupplier once https://github.com/open-solar/sunsuite/pull/5888 get merged
  const orgCountry = useSelector(authSelectors.getCurrentOrgCountry)
  if (orgCountry === 'AU') {
    const discounts = lineItem.selectedDistributorOrderingData?.volume_discounts
    return discounts && discounts.length > 0 ? 'discount' : undefined
  }
  if (orgCountry === 'GB') {
    const distributors = lineItem.data?.distributors
    const hasBulkOptions = distributors && distributors.length > 2
    return hasBulkOptions ? 'bulk' : undefined
  }
  return undefined
}

export default useQuantitySelectorType

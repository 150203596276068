import { makeStyles, Theme } from '@material-ui/core'
import Alert from 'elements/Alert'
import { Button } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { useForm, useFormState } from 'react-final-form'

export const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
  },
  rightBtn: {
    marginLeft: 'auto',
    width: 200,
  },
  leftText: {
    maxWidth: '70%',
    fontSize: 12,
  },
}))

const SyncActions: React.FC = (props) => {
  const form = useForm()
  const classes = useStyles()
  const translate = useTranslate()
  const formValues = useFormState().values
  const sharedWith = useMemo(() => formValues.share_with_orgs, [formValues])
  const workflowStages = useMemo(() => formValues.workflow_stages, [formValues])
  //find actions that are not shared to orgs specified in workflow share field
  const unsharedActions: (string | number)[] = useMemo(() => {
    let actions: (string | number)[] = []
    if (sharedWith && !!sharedWith.length && !!workflowStages?.length) {
      sharedWith.forEach((org) => {
        workflowStages?.forEach((stage) => {
          const unsharedActions = stage?.actions?.filter(
            (x) => !x.share_with_orgs?.includes(org) && !actions.includes(x.id)
          )
          if (!!unsharedActions.length) actions = actions.concat(unsharedActions.map((x) => x.id))
        })
      })
    }
    return actions
  }, [sharedWith, workflowStages])
  //find actions that are shared to other orgs NOT included in workflow share field
  const externalSharedActions: (string | number)[] = useMemo(() => {
    let actions: (string | number)[] = []
    if (!!workflowStages?.length) {
      workflowStages?.forEach((stage) => {
        stage?.actions?.forEach((action) => {
          const externalShares = action.share_with_orgs?.filter((org) => !sharedWith.includes(org))
          if (!!externalShares?.length && !actions.includes(action.id)) actions.push(action.id)
        })
      })
    }
    return actions
  }, [sharedWith, workflowStages])
  const syncActions = (shareOnly = false, unshareOnly = false) => {
    if (workflowStages) {
      const updatedStages = [...workflowStages].map((stage) => {
        if (!stage.actions) return stage
        const actions = [...stage.actions].map((action) => {
          if (shareOnly && unsharedActions.includes(action.id)) {
            return {
              ...action,
              share_with_orgs: action.share_with_orgs?.concat(
                sharedWith.filter((x) => !action.share_with_orgs.includes(x))
              ),
            }
          } else if (unshareOnly && externalSharedActions.includes(action.id)) {
            return { ...action, share_with_orgs: action.share_with_orgs.filter((x) => sharedWith.includes(x)) }
          } else if (
            !shareOnly &&
            !unshareOnly &&
            (unsharedActions.includes(action.id) || externalSharedActions.includes(action.id))
          ) {
            return { ...action, share_with_orgs: sharedWith }
          } else {
            return action
          }
        })
        return { ...stage, actions: actions }
      })
      form.mutators.updateField(`workflow_stages`, updatedStages)
    }
  }
  if (!!!unsharedActions.length && !!!externalSharedActions.length) return null
  return (
    <Alert severity="info">
      {translate(
        'We noticed that some of the actions in this workflow are not synced with the organisations that the workflow is shared with.'
      )}
      <div className={classes.row}>
        <p className={classes.leftText}>
          {translate(
            'Would you like to sync all the actions in this workflow to reflect the organisations this workflow is shared with? This will also unshare actions from organisations that do not have access to this workflow.'
          )}
        </p>

        <Button
          variant="contained"
          className={classes.rightBtn}
          color="default"
          onClick={() => {
            syncActions()
          }}
          size="medium"
        >
          <span>{translate('Sync All Actions')}</span>
        </Button>
      </div>
      {!!unsharedActions.length && (
        <div className={classes.row}>
          <p className={classes.leftText}>
            {translate(
              'Would you like to share all the actions in this workflow with external organisations specified above?'
            )}
          </p>
          <Button
            variant="contained"
            className={classes.rightBtn}
            color="default"
            onClick={() => {
              syncActions(true)
            }}
            size="medium"
          >
            <span>{translate('Share Actions Only')}</span>
          </Button>
        </div>
      )}
      {!!externalSharedActions.length && (
        <div className={classes.row}>
          <p className={classes.leftText}>
            {translate('Would you like to unshare actions from external organisations not specified above?')}
          </p>
          <Button
            variant="contained"
            className={classes.rightBtn}
            color="default"
            onClick={() => {
              syncActions(false, true)
            }}
            size="medium"
          >
            <span>{translate('Unshare Actions Only')}</span>
          </Button>
        </div>
      )}
    </Alert>
  )
}

export default SyncActions

import { all, call, put, takeEvery } from 'redux-saga/effects'
import { ReduxActionType } from 'types/global'
import { WalletTransactionType } from 'types/wallet'

export const GET_WALLET_ACTIVITIES_FOR_ORG = 'GET_WALLET_ACTIVITIES_FOR_ORG'
export const GET_WALLET_ACTIVITIES_FOR_ORG_SUCCESS = 'GET_WALLET_ACTIVITIES_FOR_ORG_SUCCESS'
export const GET_WALLET_ACTIVITIES_FOR_ORG_FAIL = 'GET_WALLET_ACTIVITIES_FOR_ORG_FAIL'

export type WalletTransactionStoreType = {
  transactions: WalletTransactionType[]
  isLoading: boolean
  pageNum: number
}

const initialState: WalletTransactionStoreType = {
  transactions: [],
  isLoading: false,
  pageNum: 1,
}

export default (state: WalletTransactionStoreType = initialState, action: ReduxActionType) => {
  switch (action.type) {
    case GET_WALLET_ACTIVITIES_FOR_ORG:
      return {
        ...state,
        isLoading: true,
      }
    case GET_WALLET_ACTIVITIES_FOR_ORG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        transactions: action?.payload?.data?.transactions || [],
      }
    case GET_WALLET_ACTIVITIES_FOR_ORG_FAIL:
      return {
        ...state,
        isLoading: false,
      }
  }
  return state
}

export function* getWalletTransactionsSaga(action: ReduxActionType) {
  try {
    const transactions = yield call(doGetWalletTransactions, action.payload.key)
    yield put({ type: GET_WALLET_ACTIVITIES_FOR_ORG_SUCCESS, payload: { data: { transactions: transactions } } })
  } catch (e) {
    yield put({ type: GET_WALLET_ACTIVITIES_FOR_ORG_FAIL, key: action.payload.key })
  }
}

export const doGetWalletTransactions = (orgId: number) => {
  return new Promise((resolve, reject) => {
    // const restClientInstance = restClient(API_URL)
    // restClientInstance('CUSTOM_GET', 'custom', {
    //   url: `feature_config/?name=${key}`,
    // })
    //   .then((response: any) => {
    //     resolve(response.data.config_data)
    //   })
    //   .catch((err: any) => {
    //     console.log('error getting available actions', err)
    //     reject(false)
    //   })
  })
}

export const getWalletTransactions = (orgId: number) => {
  return {
    type: GET_WALLET_ACTIVITIES_FOR_ORG,
    payload: {
      orgId,
    },
  }
}

export const walletTransactionsSelectors = {
  getIsLoading: (state: any): boolean => state.walletTransactions?.isLoading,
  getTransactions: (state: any): WalletTransactionType[] => state.walletTransactions?.transactions,
  getPageNum: (state: any): number => state.walletTransactions?.pageNum,
}

export function* walletTransactionSagas() {
  yield all([takeEvery(GET_WALLET_ACTIVITIES_FOR_ORG, getWalletTransactionsSaga)])
}

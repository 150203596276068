import React, { FC } from 'react'

import TariffInputs from 'resources/utilityTariffs/TariffInputs'

const isCustom = (resource: string) => {
  return resource === 'custom_tariff_current' || resource === 'custom_tariff_proposed'
}

// const DisableFormContent: FC<any> = ({ record, org_id }) => {
//   return isReadOnly(record, org_id) ? (
//     <div style={{ position: 'absolute', width: '100%', height: '100%', cursor: 'not-allowed', zIndex: 2 }} />
//   ) : null
// }

type PropTypes = {
  resource: string
  isEdit: boolean
  defaultCountry: {
    url: string
    iso2: string
  }
}
const TariffFormContent: FC<PropTypes> = ({ resource, isEdit, defaultCountry }) => {
  return (
    <>
      {/* {false && !isCustom(resource) && <DisableFormContent org_id={org_id} record={record} />} */}
      <TariffInputs isEdit={isEdit} isCustom={isCustom(resource)} defaultCountry={defaultCountry} />
    </>
  )
}

export default TariffFormContent

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { setRoleIsLite } from 'ducks/auth_roles'
import { setConfirmationDialog } from 'ducks/liteInterface'
import { viewModeSelectors } from 'ducks/viewMode'
import Alert from 'elements/Alert'
import Button from 'elements/button/Button'
import React from 'react'
import { useTranslate } from 'react-admin'
import { connect, useDispatch, useSelector } from 'react-redux'
import { RootState } from 'types/state'

const SwitchInterfaceConfirmationDialog = (props: { open: boolean }) => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const isOsLite = useSelector(viewModeSelectors.isUserLite)

  const handleClose = () => {
    dispatch(setConfirmationDialog(false))
  }

  const handleConfirm = () => {
    if (isOsLite) {
      dispatch(setRoleIsLite(false))
      logAmplitudeEvent('oslite_disabled', {})
      dispatch(setConfirmationDialog(false))
    } else {
      dispatch(setRoleIsLite(true))
      logAmplitudeEvent('oslite_enabled', {})
      dispatch(setConfirmationDialog(false))
    }
  }
  return (
    <Dialog open={props.open}>
      <DialogTitle>
        {isOsLite ? translate('Switch Interface to OpenSolar?') : translate('Switch Interface to OpenSolar Lite?')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Alert severity="warning">
            {isOsLite
              ? translate(
                  'By switching from OpenSolar Lite to OpenSolar you will gain access to the complete suite of design, sales, and management features provided by OpenSolar. You may switch back at any time.'
                )
              : translate(
                  'By switching from OpenSolar to OpenSolar Lite you will lose access to the complete suite of design, sales, and management features provided by OpenSolar. You may switch back at any time.'
                )}
          </Alert>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()}>Cancel</Button>
        <Button onClick={() => handleConfirm()} autoFocus color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    open: !!state.liteInterface.switchDialogOpen,
  }
}

export default connect(mapStateToProps)(SwitchInterfaceConfirmationDialog)

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { getAvailableActions } from 'ducks/myEnergy'
import { transactionSelectors } from 'ducks/transaction'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import React, { useEffect, useMemo } from 'react'
import { useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { ProposalDataType } from 'types/proposals'

type PropTypes = {
  dismiss: () => void
  proposalData: ProposalDataType
}

const SuccessDialog: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const transactionRequestData = useSelector(transactionSelectors.getTransactionRequestData)

  const salesRepEmail = useMemo(() => {
    return props.proposalData?.selectedProject?.assigned_role?.email || translate('your sales representative')
  }, [props.proposalData?.selectedProject?.assigned_role])

  const willEmailDocs = useMemo(() => {
    return !!props?.proposalData?.org?.email_acceptance_files_enabled
  }, [props.proposalData?.org])

  // after any successful checkout, we should update the list of available customer actions to
  // ensure the UI updates
  useEffect(() => {
    dispatch(getAvailableActions(props.proposalData?.org?.id, props.proposalData?.selectedProject?.id))
  }, [])

  const dialogContent = useMemo(() => {
    const customerAcceptedProposal = !!transactionRequestData?.quote_acceptance_content?.length
    if (transactionRequestData?.payment_amount) {
      if (transactionRequestData.payment_method === 'offline') {
        if (customerAcceptedProposal) {
          if (willEmailDocs) {
            // offline payment, proposal accepted and we will email docs
            return translate(
              'Proposal accepted successfully! Please reach out to %{assigned_salesperson_email} to confirm the deposit has been received. We will email you a copy of this document for your records.',
              {
                assigned_salesperson_email: salesRepEmail,
              }
            )
          } else {
            // offline payment, proposal accepted and we will NOT email any docs
            return translate(
              'Proposal accepted successfully! Please reach out to %{assigned_salesperson_email} to confirm the deposit has been received.',
              {
                assigned_salesperson_email: salesRepEmail,
              }
            )
          }
        } else {
          // offline payment only (so no proposal acceptance)
          return translate(
            'Thank you for confirming that the payment has been initiated. Please reach out to %{assigned_salesperson_email} to confirm the deposit has been received.',
            {
              assigned_salesperson_email: salesRepEmail,
            }
          )
        }
      } else {
        if (customerAcceptedProposal) {
          if (willEmailDocs) {
            // Stripe payment made, proposal accepted and we will email docs
            return translate(
              'Deposit paid and proposal accepted successfully! We will email you a copy of this document for your records.'
            )
          } else {
            // Stripe payment made, proposal accepted and we will NOT email docs
            return translate('Deposit paid and proposal accepted successfully!')
          }
        } else {
          // Stripe payment made without the proposal being accepted
          return translate('Payment made successfully!')
        }
      }
    } else {
      if (willEmailDocs) {
        // Proposal accepted without a payment, we will email the docs
        return translate('Proposal accepted successfully! We will email you a copy of this document for your records.')
      } else {
        // Proposal accepted without a payment, we will NOT email the docs
        return translate('Proposal accepted successfully!')
      }
    }
  }, [
    transactionRequestData?.payment_amount,
    salesRepEmail,
    transactionRequestData?.quote_acceptance_content?.length,
    willEmailDocs,
  ])

  return (
    <Dialog open={true} onClose={props.dismiss}>
      <DialogTitle>{translate('Thank you!')}</DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
      <DialogActions>
        <ProUXButton type="secondary" label="OK" onClick={props.dismiss} />
      </DialogActions>
    </Dialog>
  )
}
export default SuccessDialog

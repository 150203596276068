import CloseIcon from '@material-ui/icons/ClearOutlined'
import { IconButton } from 'opensolar-ui'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

type PropTypes = {
  onClose: () => void
}

const CloseDialogButton: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  return (
    <IconButton aria-label="close" className={classes.closeButton} onClick={props.onClose}>
      <CloseIcon />
    </IconButton>
  )
}
export default CloseDialogButton

import lodash from 'lodash'

export function get(key: string, def?: string): string | undefined {
  let value = lodash.get(window, key)
  if (typeof value !== 'string') value = undefined
  return value || def
}
export function getNumber(key: string, def?: number): number | undefined {
  let value = get(key)
  return (value ? parseFloat(value) : undefined) || def
}

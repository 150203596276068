import { Divider, Paper } from '@material-ui/core'
import { UiSwitch } from 'elements/UiSwitch'
import { styled, ToggleButton, ToggleButtonGroup } from 'opensolar-ui'
import { useState } from 'react'
import { useTranslate } from 'react-admin'
import AdvancedSettingsV2 from './AdvancedSettingsV2'
import BasicSettingsV2 from './BasicSettingsV2'

enum TabKeys {
  Basic = 'basic',
  Advanced = 'advanced',
}

const SectionContainer = styled('div')({
  padding: '10px',
})

const ModuleGridSettings = ({ allowEdit, moduleGrids }) => {
  const [activeTab, setActiveTab] = useState<TabKeys>(TabKeys.Basic)
  const translate = useTranslate()

  return (
    <UiSwitch uiKey="studio.tabs.selected_panel_group.basic_settings">
      <Paper>
        <SectionContainer>
          <ToggleButtonGroup
            fullWidth={true}
            exclusive
            value={activeTab}
            onChange={(e, value) => {
              if (!value) return
              setActiveTab(value)
            }}
          >
            <ToggleButton value="basic">{translate('Basic Settings')}</ToggleButton>
            <UiSwitch uiKey="studio.tabs.selected_panel_group.advanced_settings">
              <ToggleButton value="advanced">{translate('Advanced')}</ToggleButton>
            </UiSwitch>
          </ToggleButtonGroup>
        </SectionContainer>
        <Divider />
        <SectionContainer>
          {activeTab === TabKeys.Basic && <BasicSettingsV2 moduleGrids={moduleGrids} allowEdit={allowEdit} />}
          {activeTab === TabKeys.Advanced && <AdvancedSettingsV2 moduleGrids={moduleGrids} allowEdit={allowEdit} />}
        </SectionContainer>
      </Paper>
    </UiSwitch>
  )
}

export default ModuleGridSettings

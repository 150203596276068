import { Card, Typography } from '@material-ui/core'
import TickIcon from '@material-ui/icons/CheckCircleOutlined'
import { push } from 'connected-react-router'
import { privacyLink, termsLink_external } from 'constants/links'
import { setConfirmationDialog } from 'ducks/liteInterface'
import { orgSelectors } from 'ducks/orgs'
import { UX_PREF_KEY, viewModeSelectors } from 'ducks/viewMode'
import Button from 'elements/button/Button'
import UiContainer from 'elements/UiContainer'
import useTrackComponent from 'hooks/useTrackComponent'
import { useUxVersion } from 'hooks/useUxVersion'
import React, { useCallback } from 'react'
import { Translate, useLocale, useSetLocale, withTranslate } from 'react-admin'
import { connect, useDispatch, useSelector } from 'react-redux'
import CityPlumbingConnectUserButton from 'services/cityPlumbing/CityPlumbingConnectUserButton'
import HdmConnectUserButton from 'services/hdm/HdmConnectUserButton'
import SegenConnectUserButton from 'services/segen/SegenConnectUserButton'
import appStorage from 'storage/appStorage'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { getRoleIdFromState, reloadEntireApp } from 'util/misc'
import { useFeatureFlag } from 'util/split'
import { LOCALES_SUPPORTED } from '../App.js'
import VtacConnectUserButton from '../services/vtac/VtacConnectUserButton'
import { StaffUserSettings } from './settings/StaffUserSettings'

const useStyles = makeOpenSolarStyles((theme) => ({
  page: { padding: 20 },
  label: { minWidth: '120px', display: 'inline-block' },
  button: { margin: 5 },
  wrapper: { display: 'flex', alignItems: 'baseline', margin: '15px 5px', flexWrap: 'wrap' },
  tick: { fill: '#42C310' },
  box: { padding: 10, margin: 10 },
}))

const CONFIGURATION_UI_KEY = 'configuration.'

type PropTypes = {
  translate: Translate
  push: (path: string) => void
  roleId: string
  enableSegen: boolean
  enableCityPlumbing: boolean
  enableHdm: boolean
  enableVtac: boolean
}

const PageConfiguration: React.FunctionComponent<PropTypes> = ({
  translate,
  push,
  roleId,
  enableSegen,
  enableCityPlumbing,
  enableHdm,
  enableVtac,
}) => {
  const currentLocale = useLocale()
  const setLocale = useSetLocale()
  const dispatch = useDispatch()
  const isOsLite = useSelector(viewModeSelectors.isUserLite)
  const classes = useStyles()
  const uxVersion = useUxVersion()
  const ui3Enabled = useFeatureFlag('ux_version_3', 'on')
  const isOnLegacyVersion = uxVersion < 3

  const { trackInteraction } = useTrackComponent({
    eventKey: 'project_ui_version_switch',
    props: {},
    state: { isOnLegacyVersion },
  })

  const handleUXSwitch = useCallback(() => {
    appStorage.setNumber(UX_PREF_KEY, uxVersion === 3 ? 2 : 3)
    trackInteraction('click', { uiKey: `${CONFIGURATION_UI_KEY}settings.ui_switch` })
    reloadEntireApp()
  }, [uxVersion])

  return (
    <>
      <Card className={classes.page}>
        <Typography variant="h5">{translate('pos.configuration')}</Typography>
        <UiContainer uiKey={CONFIGURATION_UI_KEY + 'settings'} className={classes.wrapper}>
          <div className={classes.label}>{translate('User Settings')}:</div>
          <div>
            <Button
              uiKey={CONFIGURATION_UI_KEY + 'settings.manage_nearmap_user'}
              variant="contained"
              className={classes.button}
              onClick={() => {
                window.location.href = window.API_BASE_URL + 'nearmap/welcome/'
              }}
            >
              <span>{translate('Manage Nearmap User Login')}</span>
            </Button>

            <Button
              uiKey={CONFIGURATION_UI_KEY + 'settings.change_password'}
              variant="contained"
              className={classes.button}
              onClick={() => {
                push('/password-change-form')
              }}
            >
              <span>{translate('Change Password')}</span>
            </Button>

            <Button
              uiKey={CONFIGURATION_UI_KEY + 'settings.mfa'}
              variant="contained"
              className={classes.button}
              onClick={() => {
                push(`/mfa/status`)
              }}
            >
              <span>{translate('Two-factor authentication')}</span>
            </Button>

            <Button
              uiKey={CONFIGURATION_UI_KEY + 'settings.change_email'}
              variant="contained"
              className={classes.button}
              onClick={() => {
                push('/email-change-form')
              }}
            >
              <span>{translate('Change Email')}</span>
            </Button>

            <Button
              uiKey={CONFIGURATION_UI_KEY + 'settings.update_details'}
              variant="contained"
              className={classes.button}
              onClick={() => {
                push('/myroles/' + roleId)
              }}
            >
              <span>{translate('Update Personal Details')}</span>
            </Button>
            <Button
              uiKey={CONFIGURATION_UI_KEY + 'settings.email_preferences'}
              variant="contained"
              className={classes.button}
              onClick={() => {
                push('/email-preferences')
              }}
            >
              <span>{translate('Email Preferences')}</span>
            </Button>
            <UiContainer uiKey={CONFIGURATION_UI_KEY + 'settings.oslite'} inline={true}>
              {isOsLite ? (
                <Button
                  uiKey={CONFIGURATION_UI_KEY + 'settings.oslite.disable'}
                  variant="contained"
                  className={classes.button}
                  onClick={() => dispatch(setConfirmationDialog(true))}
                >
                  <span>{translate('Switch to OpenSolar')}</span>
                </Button>
              ) : (
                <Button
                  uiKey={CONFIGURATION_UI_KEY + 'settings.oslite.enable'}
                  variant="contained"
                  className={classes.button}
                  onClick={() => dispatch(setConfirmationDialog(true))}
                >
                  <span>{translate('Switch to OpenSolar Lite')}</span>
                </Button>
              )}
            </UiContainer>
            {ui3Enabled && (
              <UiContainer uiKey={CONFIGURATION_UI_KEY + 'settings.ui3'} inline={true}>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={handleUXSwitch}
                  uiKey={`${CONFIGURATION_UI_KEY}settings.ui_switch`}
                >
                  <span>
                    {translate(isOnLegacyVersion ? 'Switch to new Project UI (beta)' : 'Revert to legacy Project UI')}
                  </span>
                </Button>
              </UiContainer>
            )}
          </div>
        </UiContainer>
        <UiContainer uiKey={CONFIGURATION_UI_KEY + 'links'} className={classes.wrapper}>
          <div className={classes.label}>{translate('Links')}:</div>
          <Button
            className={classes.button}
            uiKey={CONFIGURATION_UI_KEY + 'links.terms'}
            variant="contained"
            onClick={() => {
              window.open(termsLink_external)
            }}
          >
            <span>{translate('User Agreement')}</span>
          </Button>
          <Button
            className={classes.button}
            uiKey={CONFIGURATION_UI_KEY + 'links.privacy'}
            variant="contained"
            onClick={() => {
              window.open(privacyLink)
            }}
          >
            <span>{translate('Privacy Notice')}</span>
          </Button>
        </UiContainer>
        <UiContainer uiKey={CONFIGURATION_UI_KEY + 'locales'} className={classes.wrapper}>
          <div className={classes.label}>{translate('Language')}:</div>
          <div>
            {Object.keys(LOCALES_SUPPORTED).map((locale) => (
              <Button
                key={locale}
                startIcon={currentLocale === locale ? <TickIcon className={classes.tick} /> : null}
                variant="contained"
                className={classes.button}
                onClick={() => {
                  setLocale(locale)
                  appStorage.setLocale(locale)
                }}
              >
                {LOCALES_SUPPORTED[locale]}
              </Button>
            ))}
          </div>
        </UiContainer>
        {(enableSegen || enableCityPlumbing || enableHdm || enableVtac) && (
          <UiContainer uiKey={CONFIGURATION_UI_KEY + 'segen'} className={classes.wrapper}>
            <div className={classes.label}>{translate('Hardware')}:</div>
            {enableSegen && (
              <SegenConnectUserButton
                layout={null}
                allowOrdering={false}
                variant={'contained'}
                trackSource={'configuration_page'}
              />
            )}
            {enableCityPlumbing && (
              <CityPlumbingConnectUserButton
                layout={null}
                allowOrdering={false}
                variant={'contained'}
                trackSource={'configuration_page'}
              />
            )}

            {enableHdm && <HdmConnectUserButton variant={'contained'} trackSource={'configuration_page'} />}
            {enableVtac && <VtacConnectUserButton variant={'contained'} trackSource={'configuration_page'} />}
          </UiContainer>
        )}
      </Card>
      <StaffUserSettings uiKey={CONFIGURATION_UI_KEY + 'staff'} classes={classes} />
    </>
  )
}

const mapStateToProps = (state) => ({
  roleId: getRoleIdFromState(state),
  enableSegen: orgSelectors.getEnableSegen(state),
  enableCityPlumbing: orgSelectors.getEnableCityPlumbing(state),
  enableHdm: orgSelectors.getEnableHdm(state),
  enableVtac: orgSelectors.getEnableVtacUk(state) || orgSelectors.getEnableVtacPoland(state),
})

export default connect(mapStateToProps, {
  push: push,
})(withTranslate(PageConfiguration))

import Chartist from 'chartist'
import ChartistHover from 'chartist-hover'
import React, { Component } from 'react'
import ChartistGraph from 'react-chartist'
import { addUpdateCssRule, formatCurrencyWithSymbol } from '../../util/misc'

// Sadly labels are not working with this simplified method
// see duplicated method below as workaround
//
// const CashFlowChart = props => {
//   return (
//     <div>
//       <ChartistGraph
//         style={{
//           marginTop: 20,
//           marginLeft: 0,
//           marginBottom: 0,
//           marginRight: 0,
//           height: '300px',
//         }}
//         data={window.Designer.CashFlowChartData(
//           props.chartType,
//           props.cashFlows,
//           props.paybackYear,
//           props.currencySymbol
//         )}
//         type={'Bar'}
//       />{' '}
//     </div>
//   )
// }

const CHART_HEIGHT = 300
const CHART_MARGIN_TOP = 20
// Since we want to overlay the inflation area chart on top of the savings bar chart, we currently need to hack this by offsetting the
// bar chart so it overlays on top of the area chart. Otherwise plotting both charts without the offset will result in the charts being
// stacked on top of each other. Is there is better way of handling this in Chartist?
const CHART_OFFSET = CHART_HEIGHT + CHART_MARGIN_TOP

const toolTipDefaultStyle = {
  position: 'absolute',
  background: 'rgba(237, 237, 237, 0.9)',
  borderRadius: 3,
  padding: 4,
}

function ctBarLabels(options) {
  return function ctBarLabels(chart) {
    var defaultOptions = {
      labelClass: 'ct-bar-label',
      labelInterpolationFnc: Chartist.noop,
      labelOffset: {
        x: 0,
        y: 0,
      },
      position: {
        x: null,
        y: null,
      },
      textAnchor: 'middle',
    }

    options = Chartist.extend({}, defaultOptions, options)

    if (chart instanceof Chartist.Bar) {
      chart.on('draw', function (data) {
        if (data.type === 'bar') {
          var label = window.Designer.labelForData(data, 'interactive', options?.hasPhoenix)
          var grey = '#ababab'
          if (data.value.y < 0) {
            data.element.attr({
              style: 'stroke: ' + grey + '!important;',
            })
          }
          data.element.attr({
            id: data.index + '-cash-flow-bar',
            lowerValue: data.series.lowerValuesByYear?.[data.index],
            upperValue: data.series.upperValuesByYear?.[data.index],
          })
          data.group
            .elem(
              'text',
              {
                // This gets the middle point of the bars and then adds the
                // optional offset to them
                x: label.x,
                y: label.y,
                style: label.style,
              },
              options.labelClass
            )
            .text(label.label)
        }
      })
    }
  }
}

const ChartistAreaChart = ({ className, data, minYAxis, maxYAxis }) => {
  return (
    <ChartistGraph
      className={className}
      style={{
        marginTop: CHART_MARGIN_TOP,
        marginLeft: 0,
        marginBottom: 0,
        marginRight: 0,
        height: CHART_HEIGHT,
      }}
      data={data}
      options={{
        referenceValue: 0,
        low: minYAxis,
        high: maxYAxis,
        chartPadding: 15,
        showLabel: false,
        showArea: true,
        showLine: false,
        width: '100%',
        axisX: { showGrid: false },
        axisY: {
          showGrid: true,
          offset: 60,
          showLabel: false,
        },
      }}
      type={'Line'}
    />
  )
}

const ChartistBarChart = ({
  className,
  data,
  minYAxis,
  maxYAxis,
  currencySymbol,
  hasPhoenix,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <ChartistGraph
      className={className}
      style={{
        marginTop: CHART_MARGIN_TOP,
        marginLeft: 0,
        marginBottom: 0,
        marginRight: 0,
        height: CHART_HEIGHT,
      }}
      data={data}
      options={{
        referenceValue: 0,
        low: minYAxis,
        high: maxYAxis,
        seriesBarDistance: 10,
        chartPadding: 15,
        showLabel: true,
        width: '100%',
        plugins: [
          ctBarLabels({
            textAnchor: 'middle',
            hasPhoenix,
          }),
          ChartistHover({
            onMouseEnter,
            onMouseLeave,
            triggerSelector: null,
          }),
        ],
        axisX: { showGrid: false },
        axisY: {
          showGrid: false,
          showLabel: true,
          offset: 60,
          labelOffset: {
            x: 0,
            y: 5,
          },
          labelInterpolationFnc: function (value) {
            return formatCurrencyWithSymbol(parseInt(value, 10), currencySymbol, window.locale, 0)
          },
        },
      }}
      type={'Bar'}
    />
  )
}

const ChartistToolTips = ({
  year,
  value,
  lowerRangeValue,
  upperRangeValue,
  toolTipstyle,
  translate,
  currencySymbol,
}) => {
  return value ? (
    <div className="mye-graph-tooltips" style={toolTipstyle}>
      {year && (
        <p style={{ margin: 0 }}>
          {translate('Year')}: {year}
        </p>
      )}
      <p style={{ margin: 0 }}>
        {translate('Estimated Savings: %{savings}', {
          savings: formatCurrencyWithSymbol(Math.round(value), currencySymbol, window.locale, 0),
        })}
      </p>
      {!isNaN(lowerRangeValue) && !isNaN(upperRangeValue) && (
        <p style={{ margin: 0 }}>
          {translate('Savings Range: %{lowerSavings} - %{upperSavings}', {
            lowerSavings: formatCurrencyWithSymbol(Math.round(lowerRangeValue), currencySymbol, window.locale, 0),
            upperSavings: formatCurrencyWithSymbol(Math.round(upperRangeValue), currencySymbol, window.locale, 0),
          })}
        </p>
      )}
    </div>
  ) : (
    <></>
  )
}

const InflationRangeAreaPlot = ({
  lowerValuesByYear,
  upperValuesByYear,
  hideAreaSavings,
  minYAxis,
  maxYAxis,
  chartType,
}) => {
  return (
    <>
      <div style={{ width: '100%', marginLeft: 15 }}>
        <ChartistAreaChart
          className="InflationChart"
          data={{
            series: [
              {
                name: chartType,
                className: 'InflationRangeArea',
                data: upperValuesByYear || [],
              },
              {
                name: chartType,
                className: 'InflationRangeArea',
                data: lowerValuesByYear || [],
              },
            ],
          }}
          minYAxis={minYAxis}
          maxYAxis={maxYAxis}
        />
      </div>
      <div style={{ width: '100%', marginTop: -CHART_OFFSET, marginLeft: 15 }}>
        <ChartistAreaChart
          className="InflationChart"
          data={{
            series: [
              {
                name: chartType,
                className: 'InflationRangeMask',
                data: hideAreaSavings || [],
              },
            ],
          }}
          minYAxis={minYAxis}
          maxYAxis={maxYAxis}
        />
      </div>
    </>
  )
}

const ChartistCashFlowChart = ({
  valuesByYear,
  lowerValuesByYear,
  upperValuesByYear,
  hideAreaSavings,
  chartState,
  chartType,
  paybackYear,
  paymentType,
  hasPhoenix,
  translate,
  currencySymbol,
  onMouseEnter,
  onMouseLeave,
  toolTipstyle,
  minYAxis,
  maxYAxis,
}) => {
  return (
    <>
      <InflationRangeAreaPlot
        lowerValuesByYear={lowerValuesByYear}
        upperValuesByYear={upperValuesByYear}
        hideAreaSavings={hideAreaSavings}
        minYAxis={minYAxis}
        maxYAxis={maxYAxis}
        chartType={chartType}
      />
      <div style={{ width: '100%', marginTop: -CHART_OFFSET }}>
        <ChartistToolTips
          year={chartState.year}
          value={chartState.value}
          lowerRangeValue={chartState.lowerValue}
          upperRangeValue={chartState.upperValue}
          toolTipstyle={toolTipstyle}
          translate={translate}
          currencySymbol={currencySymbol}
        />
        <ChartistBarChart
          className={'CashFlowChart'}
          data={{
            labels: Array(valuesByYear.length)
              .fill()
              .map((v, i) => (i === 0 || (i + 1) % 5 === 0 ? new Date().getFullYear() + i : '')),
            series: [
              {
                name: chartType,
                className: 'CashFlowChartBar',
                data: valuesByYear,
                lowerValuesByYear: lowerValuesByYear || [],
                upperValuesByYear: upperValuesByYear || [],
                paybackYear: paybackYear,
                paymentType: paymentType,
                currencySymbol: currencySymbol,
              },
            ],
          }}
          minYAxis={minYAxis}
          maxYAxis={maxYAxis}
          currencySymbol={currencySymbol}
          hasPhoenix={hasPhoenix}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      </div>
    </>
  )
}

const getInflationAreaChartMask = (lowerValuesByYear, upperValuesByYear) => {
  const results = []
  const length = Math.min(lowerValuesByYear.length, upperValuesByYear.length)
  for (let i = 0; i < length; i++) {
    if (upperValuesByYear[i] >= 0 && lowerValuesByYear[i] <= 0) {
      results.push(0)
    } else if (upperValuesByYear[i] > 0 && lowerValuesByYear[i] > 0) {
      results.push(lowerValuesByYear[i])
    } else {
      results.push(upperValuesByYear[i])
    }
  }
  return results
}

class CashFlowChart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      chartType: props.chartType,
      toolTipStyle: {},
      year: undefined,
      value: undefined,
      lowerValue: undefined,
      upperValue: undefined,
    }

    addUpdateCssRule(
      '.CashFlowChart .CashFlowChartBar .ct-bar-hover',
      `{ stroke: ${props.myeStyles.highlightColorInteraction} !important; }`
    )
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.chartType !== this.state.chartType) {
      this.setState({
        chartType: nextProps.chartType,
        toolTipStyle: {},
        year: undefined,
        value: undefined,
        lowerValue: undefined,
        upperValue: undefined,
      })
    }
  }

  onMouseLeave = (e) => {
    this.setState({
      toolTipStyle: {},
      year: undefined,
      value: undefined,
      lowerValue: undefined,
      upperValue: undefined,
    })
  }

  onMouseEnter = (e) => {
    if (!Boolean(e.srcElement.getAttribute('ct:value'))) return
    const value = parseFloat(e.srcElement.getAttribute('ct:value')).toFixed(2)
    const index = parseInt(e.srcElement.id)
    const lowerValue = parseFloat(e.srcElement.getAttribute('lowerValue')).toFixed(2)
    const upperValue = parseFloat(e.srcElement.getAttribute('upperValue')).toFixed(2)
    if (value !== this.state.value && index >= 0) {
      const year = new Date().getFullYear() + index
      this.setState({
        toolTipStyle: { top: e.pageY - 60, left: e.pageX - 70 },
        value,
        year,
        lowerValue,
        upperValue,
      })
      //highlight hover line

      const hoverBar = window.$('.CashFlowChart .CashFlowChartBar .ct-bar')[index]
      hoverBar && hoverBar.setAttribute('class', 'ct-bar ct-bar-hover')
      if (hoverBar) hoverBar.style.stroke = 'unset'
    }
  }

  getMaxSystemLifeTime = () => {
    const { systems, cashFlows } = this.props
    const systemLifetimePreset = cashFlows.length
    const arrayOfSystemLifetimes = systems.map((system) => system.system_lifetime || systemLifetimePreset)
    return Math.max(...arrayOfSystemLifetimes)
  }

  getUtilitySavingsPerYearForInflationBounds = () => {
    const billsYearlyCurrent = this.props.selectedSystem.bills.current.bills_yearly
    const billsYearlyProposed = this.props.selectedSystem.bills.proposedSelected.bills_yearly

    const lowerInflationBillSavingsPerYear = []
    const upperInflationBillSavingsPerYear = []
    const avgInflationBillSavingsPerYear = []
    for (let i = 0; i < billsYearlyCurrent.length; i++) {
      lowerInflationBillSavingsPerYear.push(
        billsYearlyProposed[i].annual.inflation_lower_bound_results.annual.total -
          billsYearlyCurrent[i].annual.inflation_lower_bound_results.annual.total
      )
      upperInflationBillSavingsPerYear.push(
        billsYearlyProposed[i].annual.inflation_upper_bound_results.annual.total -
          billsYearlyCurrent[i].annual.inflation_upper_bound_results.annual.total
      )
      avgInflationBillSavingsPerYear.push(billsYearlyProposed[i].annual.total - billsYearlyCurrent[i].annual.total)
    }
    return {
      lowerInflationBillSavingsPerYear,
      upperInflationBillSavingsPerYear,
      avgInflationBillSavingsPerYear,
    }
  }

  getYAxis = (valuesByYear, upperValuesByYear, lowerValuesByYear) => {
    const { lockYAxisAcrossAllOptions, YAxisRange } = this.props

    // Note that Y-axis lock is applied in Proposal Template > Advanced Settings
    if (lockYAxisAcrossAllOptions) {
      // If Y-axis lock is applied, the Y-axis will be consistent across all systems and payment options proposed. We use the
      // Y-axis range that fits the system and payment option with the max Y-axis range required.
      const maxValue = upperValuesByYear
        ? Math.max(Math.max.apply(null, upperValuesByYear), YAxisRange.maxYAxis)
        : YAxisRange.maxYAxis

      const minValue = lowerValuesByYear
        ? Math.min(Math.min.apply(null, lowerValuesByYear), YAxisRange.minYAxis)
        : YAxisRange.minYAxis

      // Keep previous implementation where the Y-axis magnitude is set to the max height of either the negative or positive Y-Axis
      // magnitude. In the future we may want to allow the negative and positive Y-axis to scale accordingly such that the chart
      // will fit better to the data.
      const YAxisMagnitude = Math.max(Math.abs(maxValue), Math.abs(minValue))
      return {
        maxYAxis: YAxisMagnitude,
        minYAxis: -YAxisMagnitude,
      }
    } else {
      // If Y-axis lock is not applied, allow the chart is scale the Y-axis based off the underlying data plotted per system and
      // payment option.
      const maxValue = upperValuesByYear ? Math.max.apply(null, upperValuesByYear) : Math.max.apply(null, valuesByYear)
      const minValue = lowerValuesByYear ? Math.min.apply(null, lowerValuesByYear) : Math.min.apply(null, valuesByYear)
      const marginAdjustment = Math.max(Math.abs(maxValue), Math.abs(minValue)) * 0.2
      return {
        maxYAxis: maxValue + marginAdjustment,
        minYAxis: minValue - marginAdjustment,
      }
    }
  }

  render() {
    const props = this.props
    const { toolTipStyle } = this.state
    const toolTipstyle = { ...toolTipDefaultStyle, ...toolTipStyle }
    const maxSystemLifetime = this.getMaxSystemLifeTime()
    const valuesByYear = props.cashFlows.slice(0, maxSystemLifetime)
    const selectedProposedBill = this.props.selectedSystem.bills.proposedSelected
    const utilityInflationAnnualLowerBound = selectedProposedBill.utility_inflation_annual_lower_bound
    const utilityInflationAnnualUpperBound = selectedProposedBill.utility_inflation_annual_upper_bound

    const lowerCumulativeSavingsByYear = []
    const upperCumulativeSavingsByYear = []
    const lowerCashFlowPerYear = []
    const upperCashFlowPerYear = []

    const hasInflationRanges =
      utilityInflationAnnualLowerBound !== null &&
      utilityInflationAnnualLowerBound !== undefined &&
      utilityInflationAnnualUpperBound !== null &&
      utilityInflationAnnualUpperBound !== undefined

    let lowerValuesByYear
    let upperValuesByYear
    let hideAreaSavings
    if (valuesByYear && hasInflationRanges) {
      const {
        lowerInflationBillSavingsPerYear,
        upperInflationBillSavingsPerYear,
        avgInflationBillSavingsPerYear,
      } = this.getUtilitySavingsPerYearForInflationBounds()

      const lowerBoundSavingsPerYear = lowerInflationBillSavingsPerYear.map(
        (v, i) => v - avgInflationBillSavingsPerYear[i]
      )
      const upperBoundSavingsPerYear = upperInflationBillSavingsPerYear.map(
        (v, i) => v - avgInflationBillSavingsPerYear[i]
      )
      const cumulativeSavingsAdjustmentLower = lowerBoundSavingsPerYear.map(((sum) => (value) => (sum += value))(0))
      const cumulativeSavingsAdjustmentUpper = upperBoundSavingsPerYear.map(((sum) => (value) => (sum += value))(0))

      for (let i = 0; i < valuesByYear.length; i++) {
        lowerCumulativeSavingsByYear[i] = valuesByYear[i] - cumulativeSavingsAdjustmentLower[i]
        upperCumulativeSavingsByYear[i] = valuesByYear[i] - cumulativeSavingsAdjustmentUpper[i]
        lowerCashFlowPerYear[i] = valuesByYear[i] - lowerBoundSavingsPerYear[i]
        upperCashFlowPerYear[i] = valuesByYear[i] - upperBoundSavingsPerYear[i]
      }

      // Since area charts in Chartist shows the area underneath the curve until it intersect with 0 on the y-axis.
      // We need to mask any area outside the bounds between the two lines defined by upper and lower inflation rates.
      // We can do this as follows, define a new area chart with transparent color that overlays the area charts created
      // by the upper and lower inflation lines.
      hideAreaSavings =
        props.chartType === 'bank_balance'
          ? getInflationAreaChartMask(lowerCumulativeSavingsByYear, upperCumulativeSavingsByYear)
          : getInflationAreaChartMask(lowerCashFlowPerYear, upperCashFlowPerYear)
      lowerValuesByYear = props.chartType === 'bank_balance' ? lowerCumulativeSavingsByYear : lowerCashFlowPerYear
      upperValuesByYear = props.chartType === 'bank_balance' ? upperCumulativeSavingsByYear : upperCashFlowPerYear
    }

    const { maxYAxis, minYAxis } = this.getYAxis(valuesByYear, upperValuesByYear, lowerValuesByYear)

    return (
      <div style={{ width: '100%' }}>
        <ChartistCashFlowChart
          valuesByYear={valuesByYear}
          lowerValuesByYear={lowerValuesByYear}
          upperValuesByYear={upperValuesByYear}
          hideAreaSavings={hideAreaSavings}
          chartState={this.state}
          chartType={props.chartType}
          paybackYear={props.paybackYear}
          paymentType={props.paymentType}
          hasPhoenix={props.hasPhoenix}
          translate={props.translate}
          currencySymbol={props.currencySymbol}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          toolTipstyle={toolTipstyle}
          minYAxis={minYAxis}
          maxYAxis={maxYAxis}
        />
      </div>
    )
  }
}

export default CashFlowChart

import { InteractionStatus } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import SegenAuthenticationService from 'services/segen/SegenAuthenticationService'
import useSegenService from 'services/segen/useSegenServiceV2'

type SegenSyncPriceStatus = 'undetermined' | 'finalized' | 'syncing' | 'requireSync'

const getSegenPriceSyncStatus = ({
  inProgress,
  isSyncing,
}: {
  inProgress: InteractionStatus
  isSyncing: boolean
}): SegenSyncPriceStatus => {
  if (isSyncing) {
    return 'syncing'
  }

  if (SegenAuthenticationService.priceSyncRequired) {
    return 'requireSync'
  }

  if (inProgress !== InteractionStatus.None) {
    // still processing msal auth uncertain whether it's require sync or not
    return 'undetermined'
  }

  return 'finalized'
}

// This hook should only be used once in one place!!!
const useAutoSyncSegenPrice = ({ onSyncCallback }: {
  onSyncCallback?: () => void
}): SegenSyncPriceStatus => {
  const segenService = useSegenService()
  const { inProgress } = useMsal()
  const [isSyncing, setIsSyncing] = useState<boolean>(false)

  const status = useMemo(() => {
    return getSegenPriceSyncStatus({ inProgress, isSyncing })
  }, [inProgress, isSyncing])

  const syncPrice = useCallback(async () => {
    setIsSyncing(true)
    await segenService.syncPrices()
    onSyncCallback?.();
    SegenAuthenticationService.priceSyncRequired = false
    setIsSyncing(false)
  }, [])

  useLayoutEffect(() => {
    if (isSyncing) return
    if (inProgress === InteractionStatus.None && SegenAuthenticationService.priceSyncRequired) {
      syncPrice()
    }
  }, [inProgress, isSyncing])

  return status
}

export default useAutoSyncSegenPrice

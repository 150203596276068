import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { INTEGRATION_TO_FIELDS, PRODUCT_CODE_TO_NAME } from './constants'

import { logAmplitudeEvent } from 'amplitude/amplitude'
import LoadingDots from 'layout/widgets/LoadingDots'
import restClient from 'restClient'
const restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  integration: 'dividend'
}

const QuotationTablePreferencesDialog: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const notify = useNotify()

  const orgId = useSelector(authSelectors.getOrgId) as number

  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [orgConfig, setOrgConfig] = useState<Object>({})
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (showDialog) {
      setLoading(true)
      restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${orgId}/${props.integration}/quotation_table/`,
      })
        .then((response) => {
          if (response.data?.config) {
            setOrgConfig(response.data.config)
          } else {
            setDefaultConfig()
          }
        })
        .catch((error) => {
          console.log('error', error)
          setDefaultConfig()
        })
        .finally(() => setLoading(false))
    }
  }, [showDialog])

  const setDefaultConfig = () => {
    let newOrgConfig = { [props.integration]: {} }

    Object.keys(INTEGRATION_TO_FIELDS[props.integration]).forEach((productName: string) => {
      newOrgConfig[props.integration][productName] = {}
      INTEGRATION_TO_FIELDS[props.integration][productName]?.forEach((field, i) => {
        newOrgConfig[props.integration][productName][field.key] = i + 1
      })
    })
    setOrgConfig(newOrgConfig)
  }

  const handleChange = (e: ChangeEvent, productKey: string, fieldKey: string) => {
    // @ts-ignore
    let val = e.target.value || ''
    let newOrgConfig = { ...orgConfig }
    if (!newOrgConfig[props.integration]) {
      newOrgConfig[props.integration] = {
        [productKey]: {
          [fieldKey]: val,
        },
      }
    } else if (!newOrgConfig[props.integration][productKey]) {
      newOrgConfig[props.integration][productKey] = { [fieldKey]: val }
    } else {
      newOrgConfig[props.integration][productKey][fieldKey] = val
    }
    setOrgConfig(newOrgConfig)
  }

  const submit = () => {
    restClientInstance('CUSTOM_PUT', 'custom', {
      url: `orgs/${orgId}/${props.integration}/quotation_table/`,
      data: { org_config: orgConfig },
    })
      .then((response) => {
        if (response.data?.success) {
          notify('Your changes have been saved', 'success')
          setShowDialog(false)
          logAmplitudeEvent('integrated_finance_quotation_table_customized', { integration: props.integration })
        }
      })
      .catch((error) => {
        console.log('error', error)
        notify('We are unable to save your changes', 'warning')
      })
  }

  return (
    <div>
      <ProUXButton type="secondary" onClick={() => setShowDialog(true)} label="customize your quotation table" />
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogTitle>{translate('Customize your integrated finance quotation table')}</DialogTitle>
        <DialogContent>
          {loading ? (
            <div style={{ height: '200px', width: '100%' }}>
              <LoadingDots text="Fetching your current configuration" />
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '80px',
              }}
            >
              <p>
                {translate(
                  'Use the form below to customize the order in which elements appear in your quotation table for this integration. Lower "order" numbers mean the row will appear earlier in the quotation table. Please note that you can only edit the order, not the content itself.'
                )}
              </p>
              <div>
                {Object.keys(INTEGRATION_TO_FIELDS[props.integration]).map((productKey: string) => {
                  return (
                    <div key={productKey}>
                      <h4>{PRODUCT_CODE_TO_NAME[productKey] || 'Unknown Product'}</h4>

                      <div>
                        <div style={{ display: 'flex' }}>
                          <div className="small" style={{ flex: 1 }}>
                            Order
                          </div>
                          <div className="small" style={{ flex: 11 }}>
                            Field Name and Description
                          </div>
                        </div>
                        {INTEGRATION_TO_FIELDS[props.integration][productKey].map((field, i) => {
                          return (
                            <div
                              key={field.key}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                borderBottom: '1px solid #ececec',
                                marginBottom: '5px',
                              }}
                            >
                              <div style={{ flex: 1 }}>
                                <TextField
                                  onChange={(e) => handleChange(e, productKey, field.key)}
                                  value={orgConfig?.[props.integration]?.[productKey]?.[field.key]}
                                  type="number"
                                ></TextField>
                              </div>
                              <div style={{ paddingLeft: '10px', flex: 11, display: 'flex', flexDirection: 'column' }}>
                                <span>{field.name}</span>
                                {field.description && <span className="small">{field.description}</span>}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <div style={{ margin: '10px' }}>
              <ProUXButton type="secondary" label="Cancel" onClick={() => setShowDialog(false)} />
            </div>
            <div style={{ margin: '10px' }}>
              <ProUXButton type="primary" label="Submit" onClick={submit} />
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default QuotationTablePreferencesDialog

import { ConditionFactory, CustomSignal, SystemContext } from 'Designer/designRules/types'
import lodash from 'lodash'
import { ComponentTypes } from 'types/selectComponent'
import { BaseConditionDef } from '.'

export type BaseComponentInSystemDef = BaseConditionDef & {
  type: 'component-in-system'
  quantity?: number
  maxQuantity?: number
  minQuantity?: number
}

type ComponentFilter = { componentType: ComponentTypes; code?: string }

export type ComponentInSystemDef =
  | (BaseComponentInSystemDef & ComponentFilter)
  | (BaseComponentInSystemDef & { oneOf: ComponentFilter[] })
  | (BaseComponentInSystemDef & { allOf: ComponentFilter[] })

export const condition_componentInSystem: ConditionFactory<ComponentInSystemDef> = (def) => {
  return {
    getProjectFields: () => [],
    getStudioSignals: () => {
      let compDefs = getCompDefs(def)
      if (!compDefs) return []

      return lodash.uniq(
        compDefs.defs
          .map((compDef): CustomSignal[] => {
            switch (compDef.componentType) {
              // Need to include 'changed' signals as component code can be changed while added to system
              case 'module':
                return ['system-selected', 'panel-added', 'panel-changed', 'panel-removed']
              case 'inverter':
                return ['system-selected', 'inverter-added', 'inverter-changed', 'inverter-removed']
              case 'battery':
                return ['system-selected', 'battery-added', 'battery-changed', 'battery-removed']
              case 'other':
                return [
                  'system-selected',
                  'other-component-added',
                  'other-component-changed',
                  'other-component-removed',
                ]
              default:
                throw new Error(`Unknown component type: ${compDef.componentType}`)
            }
          }, [])
          .flat()
      )
    },
    check: (context: SystemContext) => {
      let compDefs = getCompDefs(def)
      if (!compDefs) return false
      const system = context.system

      const checkCompDef = (compDef: ComponentFilter) => {
        switch (compDef.componentType) {
          case 'module':
            return checkCodes([system.moduleType()], compDef.code)
          case 'inverter':
            return checkCodes(system.inverters(), compDef.code)
          case 'battery':
            return checkCodes(system.batteries(), compDef.code)
          case 'other':
            return checkCodes(system.others(), compDef.code)
          default:
            throw new Error(`Unknown component type: ${compDef.componentType}`)
        }
      }

      const countCompDef = (compDef: ComponentFilter) => {
        switch (compDef.componentType) {
          case 'module':
            return countCodes([system.moduleType()], compDef.code)
          case 'inverter':
            return countCodes(system.inverters(), compDef.code)
          case 'battery':
            return countCodes(system.batteries(), compDef.code)
          case 'other':
            return countCodes(system.others(), compDef.code)
          default:
            throw new Error(`Unknown component type: ${compDef.componentType}`)
        }
      }
      if (compDefs.all) {
        if (!compDefs.defs.every((d) => checkCompDef(d))) return false
      } else {
        if (!compDefs.defs.some((d) => checkCompDef(d))) return false
      }

      if (isNumber(def.minQuantity) || isNumber(def.maxQuantity) || isNumber(def.quantity)) {
        const quantity = compDefs.defs.reduce((value, d) => value + countCompDef(d), 0)
        if (isNumber(def.quantity) && quantity !== def.quantity) return false
        if (isNumber(def.minQuantity) && quantity < def.minQuantity) return false
        if (isNumber(def.maxQuantity) && quantity > def.maxQuantity) return false
      }

      return true
    },
  }
}
const isNumber = (value: any): value is number => typeof value === 'number'

const getCompDefs = (def: ComponentInSystemDef): { all: boolean; defs: ComponentFilter[] } | undefined => {
  if (def['componentType']) return { all: true, defs: [def as ComponentFilter] }
  else if (def['oneOf']) return { all: false, defs: def['oneOf'] }
  else if (def['allOf']) return { all: true, defs: def['allOf'] }
  else {
    console.warn('Design rule component-in-system has no code, oneOf or allOf: ', def)
    return undefined
  }
}

const checkCodes = (components: { code: string }[], code: string | undefined): boolean => {
  return code ? components.some((c) => c.code === code) : components.length > 0
}

const countCodes = (components: { code: string }[], code: string | undefined): number => {
  return code ? components.filter((c) => c.code === code).length : components.length
}

import { State } from 'opensolar-sdk'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'types/state'

/**
 * Used to expose some part of the redux state to SDK users.
 */

export function useBindSelector<T>(
  selector: (state: RootState) => T,
  state: State<T>,
  dispatcher?: (value: T) => any,
  active: boolean = true
) {
  const value = useSelector<RootState, T>(selector)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!active) return
    state.value = value
  }, [value, active])

  useEffect(() => {
    if (!dispatcher) return

    const callback = (value: T) => {
      dispatch(dispatcher(value))
    }
    state.add(callback)

    return () => state.remove(callback)
  }, [dispatcher])
}

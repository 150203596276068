import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import type { OpenSolarThemeType } from 'Themes'
import { IconButton } from 'opensolar-ui'
import React, { memo, useCallback } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { ReplaceItemNode } from './types'

const useDialogTitleStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: '16px 24px',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  icon: {},
}))

const useDialogStyles = makeOpenSolarStyles((theme) => ({
  dialogPaper: { minHeight: 520 },
  content: {
    padding: '0 16px',
  },
}))

const ReplaceItemDialogTitle = memo(
  ({ title, handleClose }: { title: string | React.ReactNode; handleClose(): void }) => {
    const classes = useDialogTitleStyles()
    return (
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <div>{title}</div>
        <IconButton className={classes.icon} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
    )
  }
)

export const ReplaceItemDialog = ({
  isOpen,
  onClose,
  node,
}: {
  isOpen: boolean
  onClose(): void
  node: ReplaceItemNode
}) => {
  const classes = useDialogStyles()
  const handleClose = useCallback(() => {
    onClose?.()
  }, [onClose])

  return (
    <Dialog classes={{ paper: classes.dialogPaper }} maxWidth="lg" open={isOpen} onBackdropClick={handleClose}>
      <ReplaceItemDialogTitle title={node.title} handleClose={handleClose} />
      <DialogContent className={classes.content}>{node.Content}</DialogContent>
    </Dialog>
  )
}

export default ReplaceItemDialog

import { useMemo } from 'react'
import type { ComponentTypesV2 } from 'types/selectComponent'
import BasicFilter from '../basic/BasicFilter'

import {
  ConfigurableFilterOptionsType,
  FilterComponentNodeV2,
  FilterGenericPropsType,
  OptionGenericType,
} from '../typeV2'
import { parseStringifiedListFilterValue } from '../utils/utils'
import { getDefaultValue, getRangeOptionsByComponentType, RatingRangeType } from './constants'

export const COMBINED_RATING_FILTER_NODE_KEY = 'combined_rating'

const defaultLabel = 'Rating'
const defaultOption = {
  title: 'All',
  value: '',
  id: 'default',
}

const mapRangeOptionsToSelectOptions = (rangeOptions: RatingRangeType[]): OptionGenericType<string>[] => {
  const selectOptions = [defaultOption]

  rangeOptions.forEach((range) => {
    selectOptions.push({ title: range.title, value: range.value, id: range.value })
  })

  return selectOptions
}

const createCombinedRatingFilterNode = <T,>({
  ...restConfigs
}: ConfigurableFilterOptionsType<string, string>): FilterComponentNodeV2 => {
  const FilterComponent = (filterProps: FilterGenericPropsType) => {
    const types = parseStringifiedListFilterValue<ComponentTypesV2>({
      filterValues: filterProps.allFilterValues,
      key: 'types',
    })

    const rangeOptions = getRangeOptionsByComponentType(Array.from(types)[0])

    const selectOptions = useMemo(() => {
      return rangeOptions && mapRangeOptionsToSelectOptions(rangeOptions)
    }, [rangeOptions])

    // auto string is only send by inverter wizard, so we can use it to determine if we are in IDA
    const isForInverterDesignAssistant = !!filterProps.allFilterValues['auto_string']

    if (!selectOptions || !rangeOptions || !isForInverterDesignAssistant) {
      return null
    }

    return (
      <BasicFilter
        filterKey={COMBINED_RATING_FILTER_NODE_KEY}
        defaultSelectedOptionValue={defaultOption.value}
        options={selectOptions}
        {...restConfigs}
        {...filterProps}
      />
    )
  }

  return {
    key: COMBINED_RATING_FILTER_NODE_KEY,
    component: FilterComponent,
    getMatchedValueFromSpecsData: getDefaultValue,
  }
}

export default createCombinedRatingFilterNode

import { logAmplitudeEvent } from 'amplitude/amplitude'
import { ComponentVersions_3_0 } from 'constants/uxVersions'
import { ComponentVersionsInherit } from 'opensolar-ui'
import { useCallback, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useFeatureFlag } from 'util/split'
import { RootContainer } from '../home/OrderingHome'
import PlaceOrderCta from './cta/PlaceOrderCta'
import ProductDetail from './ProductDetail'
import ProductDetail3 from './ProductDetail3'
import useProductDetailLineItem from './useProductDetailLineItem'

const ProductDetailPage = ({ componentCode }: { componentCode: string }) => {
  const history = useHistory<{ from?: string }>()
  useEffect(() => {
    logAmplitudeEvent('page_viewed', {
      page: 'product_detail',
    })
  }, [])
  //decode componentCode from URL to ensure correct matching for api param
  const decodedComponentCode = decodeURIComponent(componentCode)
  const { lineItem, loading, updateLineItem } = useProductDetailLineItem({
    componentCode: encodeURIComponent(decodedComponentCode),
    confirmed: true,
  })
  const CtaAction = useMemo(
    () => (lineItem != null ? <PlaceOrderCta lineItem={lineItem} updateLineItem={updateLineItem} /> : null),
    [lineItem, updateLineItem]
  )
  const previousUrl = history.location.state?.from
  const handleBack = useCallback(() => {
    const redirectUrl = previousUrl || '/shop'
    history.push(redirectUrl)
  }, [previousUrl])
  const enabledHardwareShopV3 = useFeatureFlag('enable_multi_distributor_shop', 'on')

  return enabledHardwareShopV3 ? (
    <RootContainer enabledMultiDistributor={enabledHardwareShopV3}>
      <ComponentVersionsInherit versions={ComponentVersions_3_0}>
        <ProductDetail3 lineItem={lineItem} componentCode={componentCode} loading={loading} goBack={handleBack} />
      </ComponentVersionsInherit>
    </RootContainer>
  ) : (
    <ProductDetail lineItem={lineItem} loading={loading} CtaAction={CtaAction} trackSource="product_detail" />
  )
}

export default ProductDetailPage

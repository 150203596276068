import { makeStyles, Tooltip } from '@material-ui/core'
import { Button } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'

const defaultButtonStyle = {
  textTransform: 'none',
}

const defaultLabelWrapperStyle = {
  padding: '0 16px',
  // overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

const defaultWrapperStyle = { display: 'inline-block' }
const defaultStartIconStyle = { margin: 0 }

const defaultBackgroundColor = 'rgba(250,250,250, 0.95)'
const defaultBackgroundColorOnHover = 'rgba(250,250,250,1)'

const defaultActiveBackgroundColor = 'rgba(24, 144, 255,0.9)'
const defaultActiveBackgroundColorOnHover = 'rgba(24, 144, 255,1)'

const defaultDisabledStyle = { background: 'rgba(208, 208, 208, 0.8)' }
const defaultDisabledLabelColor = 'rgb(77, 77, 77,0.4)'

const useStyles = makeStyles({
  root: ({
    backgroundColor,
    backgroundColorOnHover,
    opacityOnHover,
    boxShadowOnHover,
    borderOnHover,
    disabledStyle,
  }) => ({
    background: backgroundColor,
    '&:hover': {
      background: backgroundColorOnHover,
      opacity: opacityOnHover ? opacityOnHover : undefined,
      boxShadow: boxShadowOnHover,
      border: borderOnHover,
    },
    '&:disabled': disabledStyle,
    '&:hover:disabled': disabledStyle,
  }),
  label: ({ labelStyle, disabled, labelColor }) => ({
    ...labelStyle,
    color: disabled ? defaultDisabledLabelColor : labelColor,
  }),
  startIcon: ({ startIconStyle }) => startIconStyle,
})

const GenericButton = ({
  backgroundColor,
  backgroundColorOnHover = 'rgba(0,0,0,0.08)',
  labelColor = undefined,
  label,
  tooltipLabel = undefined,
  disabledStyle = defaultDisabledStyle,
  wrapperStyle = defaultWrapperStyle,
  startIconStyle = undefined,
  buttonStyle = defaultButtonStyle,
  labelStyle = {},
  labelWrapperStyle = {},
  onClick,
  disabled = false,
  opacityOnHover = undefined,
  boxShadowOnHover = undefined,
  borderOnHover = undefined,

  ...rest
}) => {
  startIconStyle = { ...startIconStyle, ...defaultStartIconStyle }

  const translate = useTranslate()
  const createProjectButtonClasses = useStyles({
    backgroundColor,
    backgroundColorOnHover,
    opacityOnHover,
    boxShadowOnHover,
    borderOnHover,
    disabledStyle,
    labelStyle,
    disabled,
    labelColor,
    startIconStyle,
  })
  return (
    <div style={wrapperStyle}>
      {tooltipLabel ? (
        <Tooltip PopperProps={{ style: { zIndex: 2001 } }} title={translate(tooltipLabel)} enterDelay={300}>
          <Button
            classes={createProjectButtonClasses}
            style={buttonStyle}
            onClick={onClick}
            disableFocusRipple={true}
            disabled={Boolean(disabled)}
            {...rest}
          >
            {label && (
              <span classes={createProjectButtonClasses} style={{ ...defaultLabelWrapperStyle, ...labelWrapperStyle }}>
                {translate(label)}
              </span>
            )}
          </Button>
        </Tooltip>
      ) : (
        <Button
          classes={createProjectButtonClasses}
          style={buttonStyle}
          onClick={onClick}
          disabled={Boolean(disabled)}
          disableFocusRipple={true}
          {...rest}
        >
          {label && (
            <span classes={createProjectButtonClasses} style={{ ...defaultLabelWrapperStyle, ...labelWrapperStyle }}>
              {translate(label)}
            </span>
          )}
        </Button>
      )}
    </div>
  )
}

export default GenericButton

export const PrimaryButton = (props) => {
  const { isActive, ..._props } = props

  return (
    <GenericButton
      backgroundColor={isActive ? defaultActiveBackgroundColor : defaultBackgroundColor}
      backgroundColorOnHover={isActive ? defaultActiveBackgroundColorOnHover : defaultBackgroundColorOnHover}
      labelColor={isActive ? 'rgb(255,255,255)' : 'rgb(0,0,0)'}
      variant="contained"
      wrapperStyle={{ marginTop: 5, display: 'inline-block' }}
      {..._props}
    />
  )
}

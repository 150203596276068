import { Paper } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import type { OpenSolarThemeType } from 'Themes'
import ProjectName from 'elements/ProjectName'
import { Filter } from 'elements/controller/filter/filter'
import { Query } from 'elements/controller/query/query'
import useListController from 'elements/controller/useListController'
import ProjectTablePresenter from 'pages/ordering/projectTable/projectTablePresenter'
import ContactsCell from 'pages/ordering/projectTable/table/ContactsCell'
import type { HardwareOrderProjectRecord, ProjectOrderDetail } from 'pages/ordering/type'
import React, { memo, useMemo } from 'react'
import { FieldTitle, Identifier } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import ViewYourProject from './ViewYourProject'

const useTableStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  tableHeaderContainer: {
    backgroundColor: '#f8f8f8',
    height: '48px',
  },
  headerCell: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
  },
}))

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  table: {
    tableLayout: 'fixed',
  },
  container: {},
}))

const TableHeader = () => {
  const classes = useTableStyles()
  return (
    <TableHead className={classes.tableHeaderContainer}>
      <TableRow>
        <TableCell variant="head" className={classes.headerCell}>
          <FieldTitle label={'Address'} />
        </TableCell>
        <TableCell variant="head" className={classes.headerCell}>
          <FieldTitle label={'Contacts'} />
        </TableCell>
        <TableCell variant="head" className={classes.headerCell}>
          <FieldTitle label={'System'} />
        </TableCell>
        <TableCell variant="head" className={classes.headerCell}>
          <FieldTitle label={'Actions'} />
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

const TableRowItem = ({ data, systemName }: { data: HardwareOrderProjectRecord; systemName: string }) => {
  return (
    <TableRow>
      <TableCell>
        <ProjectName business_name={data.business_name} address={data.address} />
      </TableCell>
      <TableCell>
        <ContactsCell contactsData={data.contacts_data} />
      </TableCell>
      <TableCell>
        <Typography variant="body2">{systemName}</Typography>
      </TableCell>
      <TableCell>
        <ViewYourProject projectId={data.id} />
      </TableCell>
    </TableRow>
  )
}

const ReviewDesignsTable = ({ projectOrder }: { projectOrder: ProjectOrderDetail[] }) => {
  const classes = useStyles()
  const projectIds = projectOrder.map((detail) => detail.projectId)
  const query = useMemo(() => new Query({ perPage: projectIds.length }), [])
  const filter = useMemo(
    () => new Filter({ filterValues: { fieldset: 'hardware_ordering', filter: projectIds.join(',') } }),
    []
  )
  const { currentSort, data, ids, loaded, loading, total, version } = useListController<HardwareOrderProjectRecord>({
    // since this project list using a special fieldset
    // projects___aliases added here to avoid override the projects data in redux
    resource: 'projects___aliases',
    query,
    filter,
  })

  return (
    <div className={classes.container}>
      <Paper elevation={1}>
        <Table className={classes.table} size="small">
          <TableHeader />
          <TableBody>
            {ids.map((id: Identifier) => {
              const projectData = data[id]
              if (projectData == null) return null
              const selectedSystemId = projectOrder.find(
                (detail: ProjectOrderDetail) => detail.projectId === projectData.id
              )?.systemId
              const selectedSystem = projectData.systems?.find((system) => system.id === selectedSystemId)
              if (selectedSystem == null) return null
              return (
                <TableRowItem
                  key={projectData.id}
                  data={projectData}
                  systemName={ProjectTablePresenter.getSystemDisplayName(selectedSystem)}
                />
              )
            })}
          </TableBody>
        </Table>
      </Paper>
    </div>
  )
}

export default memo(ReviewDesignsTable)

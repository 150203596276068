import PrioritySquare from 'elements/PrioritySquare'
import moment from 'moment'
import { StarIcon, styled } from 'opensolar-ui'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import { FunctionFieldType } from 'ra-ui-materialui/src/field/FunctionField'
import { PRIORITY_CHOICES } from 'resources/projects/choices'
import { ContactsBlock } from 'resources/projects/projectsList/ContactsBlock'
import ProjectSelectDropdown from 'resources/projects/projectsList/ProjectSelectDropdown'
import StageSelectDropdown from 'resources/projects/projectsList/StageSelectDropdown'
import { formatDate } from 'util/date'
import AddressDisplay from '../elements/AddressDisplay'
import FavoriteButton from '../elements/FavoriteButton'
import NotesDisplay from '../elements/NotesDisplay'
import TagsDisplay from '../elements/TagsDisplay'
import TeamDisplay from '../elements/TeamDisplay'
import { StyledChip } from '../styles'

const StarIconStyled = styled(StarIcon)({
  paddingLeft: 8,
})

const Row = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const ChoiceSpan = styled('span')({
  //possibly move this as a BaseTheme style?
  fontSize: 14,
  fontWeight: 500,
  display: 'flex',
  alignItems: 'center',
})

const PRIORITY_COLORS = {
  1: 'info',
  2: 'warning',
  3: 'error',
}

export interface ListColumnBase {
  id: string
  enabled: boolean
}
export interface ProjectsListColumn extends ListColumnBase {
  display: string
  props: FunctionFieldType<any>
  required?: boolean
}

export const PROJECTS_LIST_COLUMNS_DEFAULT = (translate: Function): ProjectsListColumn[] => [
  {
    id: 'starred',
    display: 'Starred',
    props: {
      label: (
        <Row>
          <StarIconStyled color={COLOR_PALETTE.orange} />
        </Row>
      ),
      render: (record) => <FavoriteButton record={record} />,
    },
    enabled: true,
    required: true,
  },
  {
    id: 'address',
    display: 'Address',
    props: {
      render: (record) => <AddressDisplay record={record} />,
    },
    enabled: true,
    required: true,
  },
  {
    id: 'id',
    display: 'Project ID',
    props: {
      render: (record) => record.id,
    },
    enabled: true,
  },
  {
    id: 'contact',
    display: 'Contact',
    props: {
      render: (record) => !record.is_lite && <ContactsBlock contactsData={record.contacts_data} />,
    },
    enabled: true,
  },
  {
    id: 'stage',
    display: 'Stage',
    props: {
      render: (record) => (!record.is_lite ? <StageSelectDropdown project={record} /> : null),
    },
    enabled: true,
  },
  {
    id: 'priority',
    display: 'Priority',
    props: {
      render: (record) =>
        !record.is_lite && (
          <ProjectSelectDropdown
            choices={PRIORITY_CHOICES}
            source="priority"
            project={record}
            color={PRIORITY_COLORS[record.priority]}
            renderValue={(choice) => `● ${translate(choice.name)}`}
            renderChoiceValue={(choice) => (
              <ChoiceSpan>
                <PrioritySquare priority={choice.id} />
                {translate(choice.name)}
              </ChoiceSpan>
            )}
          />
        ),
    },
    enabled: true,
  },
  {
    id: 'assignee',
    display: 'Assignee',
    props: {
      render: (record) => <TeamDisplay teamMember={record.assigned_role_data} />,
    },
    enabled: true,
  },
  {
    id: 'project_type',
    display: 'Project Type',
    props: {
      render: (record) => {
        const soldInstalledChip =
          record.project_installed !== null
            ? translate('Installed')
            : record.project_sold !== null
            ? translate('Sold')
            : undefined
        return (
          <Row>
            {record.is_lite && <StyledChip label={'OS Lite'} variant="outlined" />}
            <StyledChip label={translate(record.is_residential ? 'Residential' : 'Commercial')} variant="outlined" />
            {soldInstalledChip && <StyledChip label={soldInstalledChip} variant="outlined" />}
          </Row>
        )
      },
    },
    enabled: true,
  },
  {
    id: 'lead_source',
    display: 'Lead Source',
    props: {
      render: (record) => record.lead_source || '－',
    },
    enabled: false,
  },
  {
    id: 'tags',
    display: 'Tags',
    props: {
      render: (record) => <TagsDisplay tags={record.tags_data} />,
    },
    enabled: false,
  },
  {
    id: 'notes',
    display: 'Notes',
    props: {
      render: (record) => <NotesDisplay notes={record.notes} />,
    },
    enabled: false,
  },
  {
    id: 'created_date',
    display: 'Date Created',
    props: {
      render: (record) => (record.created_date ? formatDate(record.created_date) : '－'),
    },
    enabled: false,
  },
  {
    id: 'modified_date',
    display: 'Last Updated',
    props: {
      render: (record) => (record.modified_date ? moment(record.modified_date).fromNow() : '－'),
    },
    enabled: false,
  },
]

import React from 'react'
import { useTranslate } from 'react-admin'

export type PasswordResetLinksProps = {
  onCreateAccount(): void
  onSignIn(): void
}

const PasswordResetLinks = ({ onCreateAccount, onSignIn }: PasswordResetLinksProps) => {
  const translate = useTranslate()
  return (
    <div style={{ display: 'flex', flexFlow: 'row', justifyContent: 'space-evenly', marginTop: 20 }}>
      <div>
        <a
          href="#"
          style={{
            whiteSpace: 'nowrap',
          }}
          onClick={(e) => {
            e.preventDefault()
            onCreateAccount()
          }}
        >
          {translate('Create free account')}
        </a>
      </div>
      <div>
        <a
          href="#"
          style={{
            whiteSpace: 'nowrap',
          }}
          onClick={(e) => {
            e.preventDefault()
            onSignIn()
          }}
        >
          {translate('Sign in')}
        </a>
      </div>
    </div>
  )
}

export default PasswordResetLinks

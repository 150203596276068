const LightbulbIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2967_1779)">
      <path
        d="M7.49984 17.5001C7.49984 17.9584 7.87484 18.3334 8.33317 18.3334H11.6665C12.1248 18.3334 12.4998 17.9584 12.4998 17.5001V16.6667H7.49984V17.5001ZM9.99984 1.66675C6.78317 1.66675 4.1665 4.28341 4.1665 7.50008C4.1665 9.48341 5.15817 11.2251 6.6665 12.2834V14.1667C6.6665 14.6251 7.0415 15.0001 7.49984 15.0001H12.4998C12.9582 15.0001 13.3332 14.6251 13.3332 14.1667V12.2834C14.8415 11.2251 15.8332 9.48341 15.8332 7.50008C15.8332 4.28341 13.2165 1.66675 9.99984 1.66675ZM11.6665 11.4167V13.3334H8.33317V11.4167C7.0665 10.5251 5.83317 9.60841 5.83317 7.50008C5.83317 5.20008 7.69984 3.33341 9.99984 3.33341C12.2998 3.33341 14.1665 5.20008 14.1665 7.50008C14.1665 9.57508 12.9082 10.5417 11.6665 11.4167Z"
        fill="#757575"
      />
    </g>
    <defs>
      <clipPath id="clip0_2967_1779">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default LightbulbIcon

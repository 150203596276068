import { authSelectors } from 'ducks/auth'
import DependentInput from 'elements/input/DependentInput'
import React from 'react'
import { AutocompleteInput, BooleanInput, ReferenceInput, SelectInput, TextInput, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { getRange } from 'util/misc'
import useTranslateParse from 'util/useTranslateParse'
import { required } from 'validations'
import { InputWithToolTip } from '../TariffInputs'
import {
  SECTOR_RESIDENTIAL,
  UTILITY_TARIFF_BILL_FREQUENCY_CHOICES,
  UTILITY_TARIFF_SECTOR_CHOICES,
  utilityHasNEM3,
} from '../common'
import { useStyles } from '../styles'

type PropTypes = {
  isCustom?: boolean
  disabled?: boolean
  projectCountry?: string
}
const restClientInstance = restClient(window.API_ROOT + '/api')
const GeneralInfoFields: React.FC<PropTypes> = ({ isCustom, disabled }) => {
  const form = useForm()
  const classes = useStyles()
  const translate = useTranslate()
  const formState = useFormState()
  const formValues = formState?.values
  const translateParse = useTranslateParse()
  const utilityName = formValues.utility_name
  const isStaff = useSelector(authSelectors.getIsStaff)
  const isNem3 = formValues.tariffData.solar_compensation_settings.apply_nem3_export_rates

  return (
    <div className={classes.column}>
      <TextInput
        className={classes.textInput}
        variant="outlined"
        label="Name"
        source="name"
        required={true}
        validate={required}
        options={{
          InputLabelProps: {
            shrink: true,
          },
        }}
        disabled={disabled}
      />
      <TextInput
        className={classes.textInput}
        variant="outlined"
        label="Code"
        source="code"
        required={true}
        validate={required}
        options={{
          InputLabelProps: {
            shrink: true,
          },
        }}
        disabled={disabled}
      />
      {!isCustom && (
        <>
          <TextInput
            className={classes.textInput}
            variant="outlined"
            label="Code/Name Aliases"
            source="aliases"
            options={{
              InputLabelProps: {
                shrink: true,
              },
            }}
            disabled={disabled}
            endAdornment={'CSV'}
          />
          <InputWithToolTip
            interactive
            message={translateParse(
              'The energy provider or retailer that sells the electricity. If you cannot find your utility, please let us know by submitting a request <a>here<a>.',
              {
                a: (label: string) => (
                  <a target="_blank" rel="noreferrer" href="https://support.opensolar.com/hc/en-us/requests/new">
                    {label}
                  </a>
                ),
              }
            )}
          >
            <ReferenceInput
              className={classes.fullWidthInput}
              classes={{ container: classes.autoComplete }}
              source="utility"
              reference="utilities"
              label="Utility"
              optionValueField="url"
              sort={{ field: 'name', order: 'ASC' }}
              allowEmpty
              translateChoice={false}
              onChange={(e) => {
                if (e) {
                  restClientInstance('CUSTOM_GET', 'custom', {
                    url: e,
                  })
                    .then((res) => {
                      form.mutators.updateField('utility_name', res?.data?.name)
                    })
                    .catch((err) => {
                      console.log('Failed to fetch utility details: ' + err)
                    })
                } else {
                  form.mutators.updateField('utility_name', null)
                }
              }}
            >
              <AutocompleteInput
                variant="outlined"
                optionText="name"
                optionValue="url"
                options={{
                  InputLabelProps: {
                    shrink: true,
                  },
                  disabled: disabled,
                  InputProps: {
                    endAdornment: undefined,
                  },
                }}
                source={''}
              />
            </ReferenceInput>
          </InputWithToolTip>
          <InputWithToolTip
            interactive
            message={translateParse(
              'The company that owns the poles and wires to deliver the energy to homes and businesses. Depending on the country/location, often the distributor is also the utility company. If you cannot find your distributor, please let us know by submitting a request <a>here<a>.',
              {
                a: (label: string) => (
                  <a target="_blank" rel="noreferrer" href="https://support.opensolar.com/hc/en-us/requests/new">
                    {label}
                  </a>
                ),
              }
            )}
          >
            <ReferenceInput
              className={classes.fullWidthInput}
              classes={{ container: classes.autoComplete }}
              source="distributor"
              reference="distributors"
              label="Distributor"
              optionValueField="url"
              sort={{ field: 'name', order: 'ASC' }}
              allowEmpty
              translateChoice={false}
            >
              <AutocompleteInput
                variant="outlined"
                optionText="name"
                optionValue="url"
                options={{
                  InputLabelProps: {
                    shrink: true,
                  },
                  disabled: disabled,
                }}
                fullWidth
                source={''}
              />
            </ReferenceInput>
          </InputWithToolTip>
          <ReferenceInput
            className={classes.fullWidthInput}
            classes={{ container: classes.autoComplete }}
            isRequired={true}
            validate={required}
            name="country"
            source="country"
            label="Country"
            reference="countries"
            optionValueField="url"
            sort={{ field: 'name', order: 'ASC' }}
            allowEmpty
            onChange={(e) => {
              if (e) {
                restClientInstance('CUSTOM_GET', 'custom', {
                  url: e,
                })
                  .then((res) => {
                    form.mutators.updateField('country_iso2', res?.data?.iso2)
                  })
                  .catch((err) => {
                    console.log('Failed to fetch country details: ' + err)
                  })
              } else {
                form.mutators.updateField('country_iso2', null)
              }
              form.mutators.updateField('tariffData.solar_compensation_settings', {})
            }}
          >
            <AutocompleteInput
              required={true}
              variant="outlined"
              optionText="name"
              optionValue="url"
              options={{
                InputLabelProps: {
                  shrink: true,
                },
                disabled: disabled,
              }}
              fullWidth
              source={''}
            />
          </ReferenceInput>

          <TextInput
            className={classes.textInput}
            variant="outlined"
            disabled={disabled}
            label="Description (Optional)"
            source="tariffData.description"
            multiline
            rows={7}
            options={{
              InputLabelProps: {
                shrink: true,
              },
            }}
          />
        </>
      )}
      <SelectInput
        className={classes.textInput}
        variant="outlined"
        options={{
          InputLabelProps: {
            shrink: true,
          },
        }}
        disabled={disabled}
        fullWidth
        label="Bill Frequency"
        name="tariffData.bill_frequency"
        source="tariffData.bill_frequency"
        choices={UTILITY_TARIFF_BILL_FREQUENCY_CHOICES}
      />
      {!isCustom && (
        <InputWithToolTip message={'Customers on the current tariff will switch to the proposed tariff set here.'}>
          <ReferenceInput
            className={classes.fullWidthInput}
            classes={{ container: classes.autoComplete }}
            label="Transition To Proposed Tariff (Optional)"
            source="transition_to_utility_tariff"
            name="transition_to_utility_tariff"
            reference="transition_to_utility_tariffs"
            optionValueField="url"
            sort={{ field: 'code', order: 'ASC' }}
            allowEmpty
            translateChoice={false}
          >
            <AutocompleteInput
              fullWidth
              variant="outlined"
              optionText="code"
              optionValue="url"
              options={{
                InputLabelProps: {
                  shrink: true,
                },
                disabled: disabled,
              }}
              source={''}
            />
          </ReferenceInput>
        </InputWithToolTip>
      )}
      <hr className="light" />
      <h2>{translate('Applicability')}</h2>
      <SelectInput
        className={classes.textInput}
        variant="outlined"
        options={{
          InputLabelProps: {
            shrink: true,
          },
        }}
        disabled={disabled}
        fullWidth
        name="sector"
        source="sector"
        label="Sector"
        defaultValue={SECTOR_RESIDENTIAL}
        choices={UTILITY_TARIFF_SECTOR_CHOICES}
      />
      {utilityHasNEM3(utilityName) && isNem3 && (
        <DependentInput dependsOn="sector" resolve={(v) => v === SECTOR_RESIDENTIAL}>
          <BooleanInput source="tariffData.applicability.is_low_income" label="Is Low-Income" disabled={disabled} />
        </DependentInput>
      )}
      <SelectInput
        variant="outlined"
        className={classes.textInput}
        source="popularity"
        name="popularity"
        label="Popularity"
        disabled={disabled}
        defaultValue={10}
        options={{
          InputLabelProps: {
            shrink: true,
          },
        }}
        choices={getRange(101).map((i) => ({
          id: 100 - (i - 1),
          name: 100 - (i - 1) ? 100 - (i - 1) : '0',
        }))}
        translateChoice={false}
        fullWidth={true}
      />{' '}
    </div>
  )
}
export default GeneralInfoFields

import { HasPosition, Item, MountingCalcResult } from '../types'
import { addItems } from '../utils'
import { BomGeneratorAbstract } from './BomGeneratorAbstract'

export abstract class PerInnerCornerBomGeneratorAbstract extends BomGeneratorAbstract {
  abstract generateBomForInnerCorners(innerCornerQty: number): Item[]

  async generateBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    const innerCorners = this.countInnerCorners()

    const innerCornerBom = addItems(result, this.generateBomForInnerCorners(innerCorners))

    return innerCornerBom
  }
  countInnerCorners() {
    const panelBlock = this.block
    let innerCorners = 0

    panelBlock.rows.forEach((row, rowIndex) => {
      row.forEach((panel, columnIndex) => {
        if (!panel?.isActive) {
          const panelIsInFirstRow = rowIndex === 0 // no panels above
          const panelIsInLastRow = rowIndex + 1 === panelBlock.rows.length // no panels below

          const panelIsFirstInRow = columnIndex === 0 // no panels to left
          const panelIsLastInRow = columnIndex + 1 === row.length // no panels to right

          const panelBelow = panelIsInLastRow ? null : panelBlock.rows[rowIndex + 1][columnIndex]
          const panelAbove = panelIsInFirstRow ? null : panelBlock.rows[rowIndex - 1][columnIndex]
          const panelRight = panelIsLastInRow ? null : panelBlock.rows[rowIndex][columnIndex + 1]
          const panelLeft = panelIsFirstInRow ? null : panelBlock.rows[rowIndex][columnIndex - 1]

          const isInnerCorner =
            (panelAbove?.isActive && panelLeft?.isActive) ||
            (panelAbove?.isActive && panelRight?.isActive) ||
            (panelBelow?.isActive && panelLeft?.isActive) ||
            (panelBelow?.isActive && panelRight?.isActive)

          if (isInnerCorner) {
            if (panelAbove?.isActive) {
              innerCorners++
            }
            if (panelBelow?.isActive) {
              innerCorners++
            }
          }
        }
      })
    })

    return innerCorners
  }
}

export interface Corner extends HasPosition {}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import pure from 'recompose/pure';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useTranslate } from 'ra-core';
var PaginationLimit = function () {
    var translate = useTranslate();
    return (_jsx(CardContent, { children: _jsx(Typography, __assign({ variant: "body2" }, { children: translate('ra.navigation.no_results') })) }));
};
export default pure(PaginationLimit);

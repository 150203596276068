import { Typography } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import HdmVerifyCodeForm from './HdmVerifyCodeForm'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  container: {
    width: '100%',
    padding: 20,
    boxSizing: 'border-box',
  },
  contentWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
}))
const HdmVerifyCodePage = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Typography variant="h5">Enter HDM verification code</Typography>
      <Typography variant="body1">Please check your email for the verification code and enter it below.</Typography>
      <HdmVerifyCodeForm />
    </div>
  )
}

export default HdmVerifyCodePage

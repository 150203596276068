// @ts-nocheck
import React from 'react'
import { Toolbar, useTranslate } from 'react-admin'
// import Button from 'elements/proUXButtons/ProUXButton'
import { makeStyles } from '@material-ui/core/styles'
import Button from 'elements/button/Button'
import SaveButton from 'elements/proUXButtons/SaveButton'
import { ProjectTransactionType } from 'types/projectTransactions'

const useStylesToolbar = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
  },
})

const useStyles = makeStyles({
  footer: {
    margin: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  rightButtonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  cancelButtonWrapper: {
    marginRight: '15px',
  },
  leftButton: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
})

type PropTypes = {
  onSuccess: Function
  onFailure: Function
  onDelete: Function
  onDismiss: () => void
  record: ProjectTransactionType
  handleSubmitWithRedirect: Function
  handleSubmit: Function
  invalid: boolean
  pristine: boolean
  saving: boolean
}

const ToolBarContents = (props: PropTypes) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <div className={classes.footer}>
      <div className={classes.leftButton}></div>
      <div className={classes.rightButtonGroup}>
        <div className={classes.cancelButtonWrapper}>
          <Button variant="contained" color="default" label="Cancel" onClick={props.onDismiss} />
        </div>
        <div>
          <SaveButton
            label="Save"
            {...props}
            redirect={undefined}
            submitOnEnter={true}
            onSave={(values: object, redirect: string, form: any, defaultSaveFunc: Function) => {
              defaultSaveFunc(values, redirect, { onSuccess: props.onSuccess, onFailure: props.onFailure })
            }}
            saving={props.saving}
          />
        </div>
      </div>
    </div>
  )
}

const CreateTransactionDialogFooter: React.FunctionComponent<PropTypes> = (props) => {
  return (
    <Toolbar {...props} classes={useStylesToolbar()}>
      <ToolBarContents {...props} />
    </Toolbar>
  )
}

export default CreateTransactionDialogFooter

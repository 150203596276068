import { Stack, Typography } from '@mui/material'
import Alert from 'elements/Alert'
import React from 'react'

import { CALCULATOR_OVERRIDE_MAP } from '../constants'
import { useStyles } from '../styles'
import type { SystemMCSInfo } from '../types'

const DynamicCalculatorPanel: React.FC<SystemMCSInfo> = ({
  dynamicCalculatorData,
  alert,
  mcs_self_consumption_calculator_override: mcsOverride,
}) => {
  const classes = useStyles()

  if (!dynamicCalculatorData) {
    return <Typography>No Performance Calculator Data Available</Typography>
  }

  const { calculatorName, yieldCalculator, selfConsumptionCalculator, batteryControlScheme } = dynamicCalculatorData

  return (
    <Stack spacing={2} className={classes.container}>
      <span className={classes.title}>Calculation Summary</span>
      <div>
        <div>
          <span className={classes.left}>Calculator Type</span>
          <span className={classes.right}>{calculatorName}</span>
        </div>
        <div>
          <span className={classes.left}>Yield</span>
          <span className={classes.right}>{yieldCalculator}</span>
        </div>
        <div>
          <span className={classes.left}>Self-Consumption</span>
          <span className={classes.right}>
            {mcsOverride !== undefined
              ? CALCULATOR_OVERRIDE_MAP[mcsOverride ?? 1] ?? selfConsumptionCalculator
              : selfConsumptionCalculator}
          </span>
        </div>
        <div>
          <span className={classes.left}>Battery Control</span>
          <span className={classes.right}>{batteryControlScheme ?? 'Self-Consumption'}</span>
        </div>
      </div>
      {alert && <Alert severity={alert.type === 'Warning' ? 'warning' : 'info'}>{alert.message}</Alert>}
    </Stack>
  )
}

export default DynamicCalculatorPanel

const ValleySpacingPortraitIcon = () => (
  <svg width="100%" height="100%" viewBox="0 0 232 233" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.171631" width="232" height="232" fill="white" />
    <path d="M14 30.3045L45 27V50.6955L14 54V30.3045Z" fill="#727272" />
    <path
      d="M19.8333 39.9167L17.9249 42.0004L19.8333 44.0834"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M46 27L77 30.3045V54L46 50.6955V27Z" fill="#727272" />
    <path
      d="M71.1667 39.9167L73.0751 42.0004L71.1667 44.0834"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14 57.3045L45 54V77.6955L14 81V57.3045Z" fill="#727272" />
    <path d="M46 54L77 57.3045V81L46 77.6955V54Z" fill="#727272" />
    <path
      d="M19.8333 65.9167L17.9249 68.0004L19.8333 70.0834"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71.1667 65.9167L73.0751 68.0004L71.1667 70.0834"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M84 30.3045L115 27V50.6955L84 54V30.3045Z" fill="#727272" />
    <path
      d="M89.8333 39.9167L87.9249 42.0004L89.8333 44.0834"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M116 27L147 30.3045V54L116 50.6955V27Z" fill="#727272" />
    <path
      d="M141.167 39.9167L143.075 42.0004L141.167 44.0834"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M84 57.3045L115 54V77.6955L84 81V57.3045Z" fill="#727272" />
    <path d="M116 54L147 57.3045V81L116 77.6955V54Z" fill="#727272" />
    <path
      d="M89.8333 65.9167L87.9249 68.0004L89.8333 70.0834"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M141.167 65.9167L143.075 68.0004L141.167 70.0834"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M154 30.3045L185 27V50.6955L154 54V30.3045Z" fill="#727272" />
    <path
      d="M159.833 39.9167L157.925 42.0004L159.833 44.0834"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M186 27L217 30.3045V54L186 50.6955V27Z" fill="#727272" />
    <path
      d="M211.167 39.9167L213.075 42.0004L211.167 44.0834"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M154 57.3045L185 54V77.6955L154 81V57.3045Z" fill="#727272" />
    <path d="M186 54L217 57.3045V81L186 77.6955V54Z" fill="#727272" />
    <path
      d="M159.833 65.9167L157.925 68.0004L159.833 70.0834"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M211.167 65.9167L213.075 68.0004L211.167 70.0834"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14 160.304L45 157V180.696L14 184V160.304Z" fill="#727272" />
    <path
      d="M19.8333 169.917L17.9249 172L19.8333 174.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M46 157L77 160.304V184L46 180.696V157Z" fill="#727272" />
    <path
      d="M71.1667 169.917L73.0751 172L71.1667 174.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14 187.304L45 184V207.696L14 211V187.304Z" fill="#727272" />
    <path d="M46 184L77 187.304V211L46 207.696V184Z" fill="#727272" />
    <path
      d="M19.8333 195.917L17.9249 198L19.8333 200.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71.1667 195.917L73.0751 198L71.1667 200.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M84 160.304L115 157V180.696L84 184V160.304Z" fill="#727272" />
    <path
      d="M89.8333 169.917L87.9249 172L89.8333 174.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M116 157L147 160.304V184L116 180.696V157Z" fill="#727272" />
    <path
      d="M141.167 169.917L143.075 172L141.167 174.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M84 187.304L115 184V207.696L84 211V187.304Z" fill="#727272" />
    <path d="M116 184L147 187.304V211L116 207.696V184Z" fill="#727272" />
    <path
      d="M89.8333 195.917L87.9249 198L89.8333 200.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M141.167 195.917L143.075 198L141.167 200.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M154 160.304L185 157V180.696L154 184V160.304Z" fill="#727272" />
    <path
      d="M159.833 169.917L157.925 172L159.833 174.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M186 157L217 160.304V184L186 180.696V157Z" fill="#727272" />
    <path
      d="M211.167 169.917L213.075 172L211.167 174.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M154 187.304L185 184V207.696L154 211V187.304Z" fill="#727272" />
    <path d="M186 184L217 187.304V211L186 207.696V184Z" fill="#727272" />
    <path
      d="M159.833 195.917L157.925 198L159.833 200.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M211.167 195.917L213.075 198L211.167 200.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14 95.3045L45 92V115.696L14 119V95.3045Z" fill="#727272" />
    <path
      d="M19.8333 104.917L17.9249 107L19.8333 109.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M46 92L77 95.3045V119L46 115.696V92Z" fill="#727272" />
    <path
      d="M71.1667 104.917L73.0751 107L71.1667 109.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14 122.304L45 119V142.696L14 146V122.304Z" fill="#727272" />
    <path d="M46 119L77 122.304V146L46 142.696V119Z" fill="#727272" />
    <path
      d="M19.8333 130.917L17.9249 133L19.8333 135.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71.1667 130.917L73.0751 133L71.1667 135.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M84 95.3045L115 92V115.696L84 119V95.3045Z" fill="#727272" />
    <path
      d="M89.8333 104.917L87.9249 107L89.8333 109.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M116 92L147 95.3045V119L116 115.696V92Z" fill="#727272" />
    <path
      d="M141.167 104.917L143.075 107L141.167 109.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M84 122.304L115 119V142.696L84 146V122.304Z" fill="#727272" />
    <path d="M116 119L147 122.304V146L116 142.696V119Z" fill="#727272" />
    <path
      d="M89.8333 130.917L87.9249 133L89.8333 135.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M141.167 130.917L143.075 133L141.167 135.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M154 95.3045L185 92V115.696L154 119V95.3045Z" fill="#727272" />
    <path
      d="M159.833 104.917L157.925 107L159.833 109.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M186 92L217 95.3045V119L186 115.696V92Z" fill="#727272" />
    <path
      d="M211.167 104.917L213.075 107L211.167 109.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M154 122.304L185 119V142.696L154 146V122.304Z" fill="#727272" />
    <path d="M186 119L217 122.304V146L186 142.696V119Z" fill="#727272" />
    <path
      d="M159.833 130.917L157.925 133L159.833 135.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M211.167 130.917L213.075 133L211.167 135.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="77" y="30.3215" width="7" height="180.7" fill="#FFAB1F" />
    <rect x="147" y="30.3215" width="7" height="180.7" fill="#FFAB1F" />
  </svg>
)

export default ValleySpacingPortraitIcon

import { logAmplitudeEvent } from 'amplitude/amplitude'
import React, { useEffect, useMemo } from 'react'
import { useFormState } from 'react-final-form'
import { FormRouteInfoPropTypes } from '../types'
import { usePollOnboardingDecicision } from '../utils'
import AwaitingDecision from './AwaitingDecision'
import ConditionallyApproved from './ConditionallyApproved'
import UnderReview from './UnderReview'

const DecisionDisplayTop: React.FC<FormRouteInfoPropTypes> = (props) => {
  const formState = useFormState()
  const legalBuinessName = formState.values.businessInfo.legalName

  // some envs won't get webhooks but we'll want to test the UI, this lets us do that without depending on the back-end actually getting updated

  const testDecision = useMemo(() => {
    if (legalBuinessName === 'OS TEST APPROVED') {
      return 'approved'
    } else if (legalBuinessName === 'OS TEST CONDITIONALLY APPROVED') {
      return 'conditionally approved'
    } else if (legalBuinessName === 'OS TEST DENIED') {
      return 'denied'
    }
    return null
  }, [legalBuinessName])
  const decision = usePollOnboardingDecicision(testDecision, 'bluesnap')

  useEffect(() => {
    if (decision) {
      logAmplitudeEvent('psp_onboarding_decision_shown', { decision })
    }
  }, [decision])

  // both approved states should show the same screen, any other decision shoul show UnderReview
  if (!decision) return <AwaitingDecision />
  else if (decision === 'approved') return <ConditionallyApproved />
  else if (decision === 'conditionally approved') return <ConditionallyApproved />
  else return <UnderReview closeDialog={props.closeDialog} />
}
export default DecisionDisplayTop

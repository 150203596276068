import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { FormDataConsumer, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'

const useStyles = makeStyles((theme) => ({
  root: {
    textDecoration: 'underline',
    float: 'right',
    fontSize: 12,
    marginBottom: 10,
    cursor: 'pointer',
    marginTop: '10px',
    padding: '10px 0px 10px 10px',
    color: 'rgba(0,0,0,0.54)',
    '&:hover': {
      color: 'rgb(0,0,0)',
    },
  },
}))

const ToggleAddressDetail: React.FunctionComponent = () => {
  const form = useForm()
  const classes = useStyles()
  const translate = useTranslate()
  const handleChange = (newValue: boolean) => form.change('search_address', newValue)
  return (
    <FormDataConsumer>
      {({ formData, ...rest }) => {
        const search_address = Boolean(formData.search_address)
        return (
          <a id="address-detail" className={classes.root} onClick={() => handleChange(!search_address)}>
            {search_address ? translate('Enter Address Details') : translate('Search Address')}
          </a>
        )
      }}
    </FormDataConsumer>
  )
}

export default ToggleAddressDetail

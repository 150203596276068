export const LOAD_COUNT_INCREMENT = 'LOAD_COUNT_INCREMENT'

export const loadCountIncrement = reloadCount => ({
  type: LOAD_COUNT_INCREMENT,
  payload: reloadCount,
})

export default function reducer(previousState = null, { type, payload }) {
  if (!previousState) {
    previousState = 1
  }

  if (type === LOAD_COUNT_INCREMENT || type === 'AOR/CRUD_UPDATE_SUCCESS') {
    return previousState + 1
  }

  return previousState
}

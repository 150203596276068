import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import React from 'react'
import { refreshView as refreshViewAction } from 'react-admin'
import { connect, useSelector } from 'react-redux'
import restClient from 'restClient'
import { getOrgCountryIso2FromState, getOrgFromState } from 'util/org'
import PromoButton from './genericPromoContent/PromoButton'

const API_URL = window.API_ROOT + '/api'
export const restClientInstance = restClient(API_URL)

const FinancialIntegration = (props) => {
  const isStaff = useSelector(authSelectors.getIsStaff)
  const org = useSelector(authSelectors.getCurrentOrg)
  const activeGenericIntegrations = []
  const inactiveGenericIntegrations = []

  // for an integration to be visible it must either be fully public, visible to staff and this user is staff, or visibile to staff and this org has the integration activated
  const availableConfiguredIntegrations = useSelector(orgSelectors.getAvailableFinanceIntegrations)?.filter(
    (integration) =>
      integration.status === 'active_public' ||
      (integration.status === 'active_staff_only' && isStaff) ||
      (integration.status === 'active_staff_only' && org[`enable_${integration.integration_name}`] === 'active')
  )
  availableConfiguredIntegrations?.forEach((integration) => {
    if (org && org[`enable_${integration.integration_name}`]) activeGenericIntegrations.push(integration)
    else inactiveGenericIntegrations.push(integration)
  })

  const renderCards = () => (
    <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
      {activeGenericIntegrations?.map((integration) => (
        <PromoButton integration={integration} key={integration.integration_name} />
      ))}
      {inactiveGenericIntegrations?.map((integration) => (
        <PromoButton integration={integration} key={integration.integration_name} />
      ))}
    </div>
  )

  return renderCards()
}

const mapStateToProps = (state) => ({
  orgData: getOrgFromState(state),
  orgCountry: getOrgCountryIso2FromState(state),
})

export default connect(mapStateToProps, {
  refreshView: refreshViewAction,
})(FinancialIntegration)

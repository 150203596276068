import { PartnerAccessoriesType } from 'types/mounting'
import SolaSkirt from './partner_accessories/SolaSkirt'

export const partnerAccessories: PartnerAccessoriesType = {
  solaskirt: {
    manufacturer: 'SolaSkirt',
    class: SolaSkirt,
    getInputs(projectForm, system) {
      return {
        dropdownInputs: [],
        textFieldInputs: [
          { label: 'Chimney quantity', variableName: 'chimneyQty', type: 'number', min: 0, default: 0 },
        ],
      }
    },
  },
}

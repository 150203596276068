import { authSelectors } from 'ducks/auth'
import { exhibitorsSelectors, loadExhibitors } from 'ducks/exhibitors'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const useLoadExhibitors = () => {
  const orgId = useSelector(authSelectors.getOrgId)
  const dispatch = useDispatch()
  const exhibitors = useSelector(exhibitorsSelectors.getExhibitors)
  const exhibitorLoading = useSelector(exhibitorsSelectors.getIsLoading)
  const exhibitorReady = useSelector(exhibitorsSelectors.getIsReady)
  useEffect(() => {
    if (!exhibitorLoading && !exhibitorReady) {
      dispatch(loadExhibitors(orgId))
    }
  }, [])

  return { exhibitors, exhibitorLoading, exhibitorReady }
}

import { Typography } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { logAmplitudeEvent } from 'amplitude/amplitude'

import { countryMapping } from 'constants/countries'
import { values } from 'lodash'
import React, { memo, useCallback, useContext, useEffect, useState } from 'react'
import { useNotify } from 'react-admin'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import AddressAutocomplete from 'resources/projects/elements/AddressAutocomplete.js'
import type { SegenAddressType, SegenCountryDetailType } from 'services/segen/type'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { RootState } from 'types/state'
import AddressCompletionForm from '../AddressCompletionForm'
import {
  CheckoutFormContext,
  CheckoutPresenterContext,
  QuoteContext,
  ShowAddressCompletionFormContext,
} from './SegenCheckoutProvider'

/*
We must send hasLiftingEquipment but we can assume it is true so there is no need to show the form control
*/
const SHOW_LIFTING_EQUIPMENT_FORM_ELEMENT = false

const useAddressFieldStyles = makeOpenSolarStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  clickableLink: {
    textDecoration: 'underline',
    color: '#1751D0',
    cursor: 'pointer',
  },
}))

const AddressField = ({ handleUpdateShippingAddress, address, selectedAddress, showAddressCompletionForm }) => {
  const { contactName, country, county, postCode, street, town } = address || {}
  const [editing, setEditing] = useState<boolean>(false)
  const classes = useAddressFieldStyles()
  const apiKeyGoogle = useSelector((state: RootState) =>
    state.auth && state.auth.api_key_google ? state.auth.api_key_google : ''
  )
  const segenCheckoutPresenter = useContext(CheckoutPresenterContext)

  useEffect(() => {
    setEditing(showAddressCompletionForm)
  }, [showAddressCompletionForm])

  const handleClick = () => {
    if (editing) {
      segenCheckoutPresenter?.updateShowAddressCompletionForm(false)
    }
    setEditing(!editing)
  }

  const onPlaceSelected = (placeAsFields) => {
    handleUpdateShippingAddress(placeAsFields)
    setEditing(false)
  }

  return (
    <div className={classes.container}>
      <div style={{ flexGrow: 1, paddingRight: editing ? 50 : 0 }}>
        {editing ? (
          showAddressCompletionForm ? (
            <AddressCompletionForm
              handleUpdateAddress={handleUpdateShippingAddress}
              initialValues={{
                address: selectedAddress?.street || '',
                locality: selectedAddress?.town || '',
                state: selectedAddress?.county || '',
                zip: selectedAddress?.postCode || '',
                country_iso2: selectedAddress?.country
                  ? values(countryMapping)?.find((item) => item.name === selectedAddress?.country)?.iso2
                  : 'GB',
              }}
              requiredFields={['address', 'locality', 'zip', 'country_iso2']}
            />
          ) : (
            <AddressAutocomplete onPlaceSelected={onPlaceSelected} api_key_google={apiKeyGoogle} types={['address']} />
          )
        ) : (
          <div>
            {contactName && (
              <Typography variant="body1" gutterBottom>
                {contactName}
              </Typography>
            )}
            <Typography variant="body1" gutterBottom>
              {street}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {county} {town} {postCode} {country}
            </Typography>
          </div>
        )}
      </div>
      <Typography variant="body1" gutterBottom className={classes.clickableLink} onClick={handleClick}>
        {editing ? 'Cancel' : 'Change'}
      </Typography>
    </div>
  )
}

const useRadioGroupStyles = makeOpenSolarStyles((theme) => ({
  container: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  radioGroup: {
    marginLeft: 50,
  },
}))

const LiftingEquipmentRadioGroup = ({
  hasLiftingEquipment,
  handleLiftingEquipmentChange,
}: {
  hasLiftingEquipment?: boolean
  handleLiftingEquipmentChange(value: boolean): void
}) => {
  const classes = useRadioGroupStyles()
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const answer = (event.target as HTMLInputElement).value
      handleLiftingEquipmentChange(answer === 'Yes')
    },
    [handleLiftingEquipmentChange]
  )

  return (
    <div className={classes.container}>
      <Typography variant="h6" gutterBottom>
        Do you have lifting equipment?
      </Typography>
      <FormControl className={classes.radioGroup} component="fieldset">
        <RadioGroup
          aria-label="lifting-equipment"
          name="lifting-equipment"
          value={hasLiftingEquipment}
          onChange={handleChange}
        >
          <FormControlLabel value={'Yes'} control={<Radio />} label="Yes" />
          <FormControlLabel value={'No'} control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
    </div>
  )
}

const ShippingAddress = () => {
  const [selectedAddress, setSelectedAddress] = useState<SegenAddressType>()
  const { address, hasLiftingEquipment, deliveryMethodPreference, segenOptions, initialing } = useContext(
    CheckoutFormContext
  )
  const showAddressCompletionForm = useContext(ShowAddressCompletionFormContext)
  const { loading, quote } = useContext(QuoteContext)
  const segenCheckoutPresenter = useContext(CheckoutPresenterContext)
  const notify = useNotify()

  useEffect(() => {
    setSelectedAddress(address)
  }, [])

  const handleUpdateShippingAddress = (placeAsFields) => {
    const reference = quote?.reference
    if (!reference || segenOptions.countryIds.length === 0) {
      return
    }
    const segenCountryDetail = segenOptions.countryIds.find(
      (country: SegenCountryDetailType) => country.name === countryMapping[placeAsFields.country_iso2]?.name
    )

    if (segenCountryDetail == null) {
      notify('Segen not supported in this area', 'warning')
      return
    }

    const oldShippingAddres = address
    const newShippingAddress = {
      ...(address || {}),
      county: placeAsFields.state,
      postCode: placeAsFields.zip,
      street: placeAsFields.address,
      town: placeAsFields.locality,
      country: segenCountryDetail.name,
      countryId: segenCountryDetail.id,
    }
    const onSuccess = () => {
      // Handle update success
      logAmplitudeEvent('hardware_segen_shipping', {
        action: 'updated',
        context: 'delivery_address',
      })
      if (showAddressCompletionForm) {
        segenCheckoutPresenter?.updateShowAddressCompletionForm(false)
      }
    }
    const onFailed = () => {
      notify(
        'Your preferred address was not able to be automatically populated. Please complete your address manually',
        'warning'
      )
      segenCheckoutPresenter?.updateCheckoutForm<'address'>({ key: 'address', value: oldShippingAddres })
      segenCheckoutPresenter?.updateShowAddressCompletionForm(true)
    }
    setSelectedAddress(newShippingAddress)
    segenCheckoutPresenter?.updateCheckoutForm<'address'>({ key: 'address', value: newShippingAddress })
    segenCheckoutPresenter?.updateShippingDetails({
      reference,
      shippingDetails: {
        address: newShippingAddress,
        deliveryMethodPreference,
        hasLiftingEquipment,
      },
      onSuccess,
      onFailed,
    })
  }

  const handleLiftingEquipmentChange = (value: boolean) => {
    const reference = quote?.reference
    if (!reference) {
      return
    }

    const oldValue = hasLiftingEquipment
    const onSuccess = () => {
      // Handle update success
    }
    const onFailed = () => {
      notify('Failed to update lifting equipment', 'error')
      segenCheckoutPresenter?.updateCheckoutForm<'hasLiftingEquipment'>({ key: 'hasLiftingEquipment', value: oldValue })
    }

    segenCheckoutPresenter?.updateCheckoutForm<'hasLiftingEquipment'>({ key: 'hasLiftingEquipment', value })

    segenCheckoutPresenter?.updateShippingDetails({
      reference,
      shippingDetails: {
        address,
        deliveryMethodPreference,
        hasLiftingEquipment: value,
      },
      onSuccess,
      onFailed,
    })
  }

  if (initialing) {
    return <Skeleton />
  }

  return (
    <div>
      <AddressField
        handleUpdateShippingAddress={handleUpdateShippingAddress}
        address={address}
        selectedAddress={selectedAddress}
        showAddressCompletionForm={showAddressCompletionForm}
      />
      {SHOW_LIFTING_EQUIPMENT_FORM_ELEMENT && (
        <LiftingEquipmentRadioGroup
          hasLiftingEquipment={hasLiftingEquipment}
          handleLiftingEquipmentChange={handleLiftingEquipmentChange}
        />
      )}
    </div>
  )
}

export default memo(ShippingAddress)

import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { StudioSystemType } from 'types/global'
import { PaymentFrequencyTypeEnum, PaymentOptionDataType } from 'types/paymentOptions'
import { CalculatedPaymentOption } from '../paymentOptionSelection/types'
import { convertPaymentToFrequency } from '../utils'
import PaymentOptionCard from './PaymentOptionCard'
import PaymentOptionDragTarget from './PaymentOptionDragTarget'

type PropTypes = {
  isPendingCalcs: boolean
  isCalculating: boolean
  disableDrag: boolean
  systemIndex: number
  pendingPaymentOptions: CalculatedPaymentOption[]
  system: StudioSystemType
  setPmtIdBeingDragged: (pmtId: number | undefined) => void
  pmtIdBeingDragged: number | undefined
  sortedPaymentOptions: PaymentOptionDataType[]
  handlePaymentOptionReorder: (newVal: number) => void
  exceptionPaymentOptions: PaymentOptionDataType[]
}

const useStyles = makeOpenSolarStyles((theme) => ({
  cardWrapper: {
    display: 'flex',
    marginRight: '15px',
    position: 'relative',
  },
}))

const PaymentOptionCardsRenderer: React.FC<PropTypes> = (props) => {
  const classes = useStyles()

  return (
    <>
      {props.isPendingCalcs && props.pendingPaymentOptions?.length ? (
        <>
          {props.pendingPaymentOptions?.map((pmt, i) => (
            <div
              className={classes.cardWrapper}
              key={pmt.payment_option_id}
              id={`payment-option-card-${props.systemIndex}-${i}`}
            >
              <PaymentOptionCard
                title={pmt.title}
                monthlyPayment={pmt.repayment}
                ppaPricePerUnit={0}
                paymentFrequency={pmt.payment_frequency}
                paymentPerPeriod={convertPaymentToFrequency(
                  pmt.repayment,
                  PaymentFrequencyTypeEnum.monthly,
                  pmt.payment_frequency
                )}
                totalPrice={undefined}
                integration={pmt.integration}
                paymentType={pmt.payment_type}
                upfrontPayment={undefined}
                term={pmt.term_years}
                interestRate={pmt.interest_rate}
                id={pmt.payment_option_id}
                isCalculating={props.isCalculating}
                systemUuid={props.system.uuid}
                systemId={props.system.userData?.id}
                quotationLines={[]}
                setPmtIdBeingDragged={props.setPmtIdBeingDragged}
                disableDrag={true}
                messages={pmt.messages}
                isGhost={false}
                interestRateDisclaimer={null}
              />
              {props.pmtIdBeingDragged && (
                <PaymentOptionDragTarget index={i} handlePaymentOptionReorder={props.handlePaymentOptionReorder} />
              )}
            </div>
          ))}
        </>
      ) : (
        <>
          {props.sortedPaymentOptions?.map((pmt, i) => (
            <div className={classes.cardWrapper} key={pmt.id} id={`payment-option-card-${props.systemIndex}-${i}`}>
              <PaymentOptionCard
                title={pmt.title}
                monthlyPayment={convertPaymentToFrequency(
                  pmt.regular_payment,
                  pmt.payment_frequency,
                  PaymentFrequencyTypeEnum.monthly
                )}
                ppaPricePerUnit={pmt?.ppa_dollars_per_kwh}
                paymentPerPeriod={pmt.regular_payment}
                paymentFrequency={pmt.payment_frequency}
                totalPrice={pmt.pricing?.system_price_including_tax}
                integration={pmt.integration}
                paymentType={pmt.payment_type}
                upfrontPayment={pmt.down_payment || pmt.deposit_amount}
                term={pmt.term_years}
                interestRate={pmt.interest_rate}
                id={pmt.id}
                isCalculating={props.isCalculating}
                systemUuid={props.system.uuid}
                systemId={props.system.userData?.id}
                quotationLines={pmt.quotation_lines}
                setPmtIdBeingDragged={props.setPmtIdBeingDragged}
                disableDrag={props.disableDrag}
                messages={pmt.messages}
                isGhost={false}
                interestRateDisclaimer={pmt.interest_rate_disclaimer}
              />
              {props.pmtIdBeingDragged && (
                <PaymentOptionDragTarget index={i} handlePaymentOptionReorder={props.handlePaymentOptionReorder} />
              )}
            </div>
          ))}
          {props.exceptionPaymentOptions?.map((pmt, i) => (
            <div className={classes.cardWrapper} key={pmt.id}>
              <PaymentOptionCard
                title={pmt.title}
                monthlyPayment={0}
                ppaPricePerUnit={0}
                paymentFrequency={null}
                paymentPerPeriod={0}
                totalPrice={0}
                integration={pmt.integration}
                paymentType={pmt.payment_type}
                upfrontPayment={0}
                term={pmt.term_years}
                interestRate={pmt.interest_rate}
                id={pmt.id}
                isCalculating={props.isCalculating}
                systemUuid={props.system.uuid}
                systemId={props.system.userData?.id}
                quotationLines={[]}
                setPmtIdBeingDragged={props.setPmtIdBeingDragged}
                disableDrag={props.disableDrag}
                messages={pmt.messages}
                isGhost={false}
                interestRateDisclaimer={null}
              />
              {props.pmtIdBeingDragged && (
                <PaymentOptionDragTarget index={i} handlePaymentOptionReorder={props.handlePaymentOptionReorder} />
              )}
            </div>
          ))}
        </>
      )}
    </>
  )
}

export default PaymentOptionCardsRenderer

//@ts-nocheck
import { Paper } from '@material-ui/core'
import { getWalletSettings, updateWalletInState, walletSettingsSelectors } from 'ducks/wallet'
import Alert from 'elements/Alert'
import React, { useEffect, useState } from 'react'
import { Toolbar, useNotify } from 'react-admin'
import { Form, FormRenderProps, RenderableProps } from 'react-final-form'
import Skeleton from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'

import { CardElement } from '@stripe/react-stripe-js'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orgSelectors } from 'ducks/orgs'
import { ControlBreadCrumbs } from 'elements/BreadCrumbs'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import SaveButton from 'elements/proUXButtons/SaveButton'
import EditWalletPage from './EditWalletPage'
import EnableWalletPage from './EnableWalletPage'
import { useNotifyWalletError } from './useNotifyWalletError'

export type StripeInfo = {
  brand: string
  complete: boolean
  elementType: 'card'
  error: { type: 'validation_error'; code: string; message: string } | undefined
  value: { postalCode: string | undefined }
}

export type StripeInfoAndRefType = StripeInfo & {
  empty: boolean
  ref: any
  showStripe: boolean
}

type RequestBodyType = {
  payment_method?: any
  funds_to_add?: number
  top_up_amount?: number
  top_up_trigger_amount?: number
  currency: string
  payment_intent_id: string | null
}

const WalletHomeContents: React.FC<RenderableProps<FormRenderProps<Record<string, any>>>> = (props) => {
  const dispatch = useDispatch()
  const notify = useNotify()

  const isActivated: boolean = useSelector(walletSettingsSelectors.getIsWalletActivated) //activated means they have turned it on, it does not mean they have added funds
  const wallet = useSelector(walletSettingsSelectors.getWallet)
  const isLoading: boolean = useSelector(walletSettingsSelectors.getIsLoading)
  const orgCountry = useSelector(orgSelectors.getOrgIso2)

  const notifyWalletError = useNotifyWalletError(wallet?.currency, orgCountry)

  const [stripeInfo, setStripeInfo] = useState<StripeInfoAndRefType | undefined>(undefined)
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    dispatch(getWalletSettings())
  }, [])

  useEffect(() => {
    logAmplitudeEvent('wallet_page_viewed', {})
  }, [])

  const saveStripeInfo = (newStripeInfo: StripeInfoAndRefType) => setStripeInfo(newStripeInfo)

  const getIsValid = (): boolean => {
    const vals = props?.form?.getState()?.values

    let newError: string | undefined = undefined
    if (vals['funds_to_add'] !== undefined) {
      if (vals['funds_to_add'] < 0) newError = 'Please enter 0 or a positive number for funds to add'
      // else if (isNaN(vals['funds_to_add'].replace(/[^0-9.,$]/g, ''))) newError = 'Please make sure the value for funds to add is a valid number'
    }
    if (vals['enable_auto_top_up'] && vals['funds_to_add'] === undefined) {
      if (vals['top_up_amount'] !== undefined) {
        if (vals['top_up_amount'] <= 0) newError = 'Please enter a positive number for the top-up amount'
        // else if (isNaN(vals['top_up_amount'].replace(/[^0-9.,$]/g, ''))) newError = 'Please make sure the value for top-up amount is a valid number'
      }
      if (vals['top_up_trigger_amount'] !== undefined) {
        if (vals['top_up_trigger_amount'] <= 0)
          newError = 'Please enter a positive number for the balance at which top-up will be triggered'
        // else if (isNaN(vals['top_up_trigger_amount'].replace(/[^0-9.,$]/g, ''))) newError = 'Please make sure the value for the balance at which top-up will be triggered is a valid number'
      }
    }

    if ((!wallet.payment_methods || wallet.payment_methods?.length === 0) && !stripeInfo) {
      newError = 'You do not have any saved payment methods. Please enter credit card information below'
    }

    if (newError) {
      setErrorMsg(newError)
      return false
    } else return true
  }

  const submitForm = async () => {
    const vals = props?.form?.getState()?.values
    //console.log('form state', formState.values)
    setLoading(true)
    const request_body: RequestBodyType = wallet
    const API_URL = window.API_ROOT + '/api'

    const isValid = getIsValid()
    if (!isValid) {
      setLoading(false)
      return
    }
    request_body['payment_method'] = null
    request_body['payment_intent_id'] = null
    if (stripeInfo && !stripeInfo.empty && stripeInfo.showStripe) {
      if (stripeInfo.error) {
        let msg = stripeInfo.error.message || 'Please enter all required credit card fields'
        setErrorMsg(msg)
        setLoading(false)
        return
      } else {
        const { stripe, elements } = stripeInfo.ref.current
        const cardElement = elements.getElement(CardElement)
        let { paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        })
        request_body['payment_method'] = paymentMethod
      }
    }

    if (vals?.funds_to_add !== undefined) request_body['funds_to_add'] = vals.funds_to_add //?.replace(/[^0-9.,$]/g, '')
    if (vals.enable_auto_top_up) {
      if (vals?.top_up_amount !== undefined) request_body['top_up_amount'] = vals.top_up_amount //?.replace(/[^0-9.,$]/g, '')
      if (vals?.top_up_trigger_amount !== undefined) request_body['top_up_trigger_amount'] = vals.top_up_trigger_amount //?.replace(/[^0-9.,$]/g, '')
    } else {
      request_body['top_up_amount'] = 0
      request_body['top_up_trigger_amount'] = 0
    }
    request_body['currency'] = wallet.currency
    request_body['tax_identifier'] = vals.tax_identifier

    const handleServerResponse = (response: any) => {
      if (response?.error) {
        notify(response.error, 'error')
      } else if (response?.requires_action && stripeInfo && stripeInfo.ref) {
        // Use Stripe.js to handle required card action
        setLoading(true)
        const { stripe } = stripeInfo.ref.current
        if (response.payment_intent_client_secret.startsWith('seti_')) {
          stripe.confirmCardSetup(response.payment_intent_client_secret).then(handleStripeJsResult)
        } else {
          stripe.handleCardAction(response.payment_intent_client_secret).then(handleStripeJsResult)
        }
      } else {
        // Show success message
      }
    }

    const handleStripeJsResult = (result: any) => {
      if (result?.paymentIntent?.id) {
        request_body['payment_intent_id'] = result.paymentIntent.id
      }
      if (result?.error) {
        // Show error in payment form
        console.log(result.error.message, 'error')
        notify(result.error.message, 'error')
        setLoading(false)
      } else {
        // The card action has been handled
        // The PaymentIntent can be confirmed again on the server
        const restClientInstance = restClient(API_URL)
        console.log(request_body, 'request_body')
        if (request_body?.funds_to_add) {
          logAmplitudeEvent('manual_add_funds_attempted', { amount: request_body.funds_to_add })
        } else {
          logAmplitudeEvent('top_up_settings_change_attempted', {
            top_up_amount: request_body.top_up_amount,
            trigger_amount: request_body.top_up_trigger_amount,
          })
        }
        restClientInstance('UPDATE', 'wallets', {
          id: wallet.id,
          data: request_body,
        })
          .then(function (res: any) {
            if (res?.data?.requires_action) {
              handleServerResponse(res.data)
            } else {
              if (vals?.funds_to_add > 0) {
                notify('Funds have successfully been added to your wallet', 'success')
                logAmplitudeEvent('manual_add_funds_success', { amount: vals?.funds_to_add })
              } else {
                notify('Your Top-Up settings have been saved', 'success')
                logAmplitudeEvent('top_up_settings_change_attempted', {
                  top_up_amount: request_body.top_up_amount,
                  trigger_amount: request_body.top_up_trigger_amount,
                })
              }
              setErrorMsg(undefined)
              // if we just added a payment method the back-end does not pass back the new card in the array upon update, so let's fetch it and update state that way
              if (request_body['payment_method']) {
                restClientInstance('GET_ONE', 'wallets', {
                  id: wallet.id,
                })
                  .then((getRes) => {
                    logAmplitudeEvent('wallet_card_added', {})
                    dispatch(updateWalletInState(getRes.data))
                  })
                  .catch((getErr) => {
                    console.log('getErr', getErr)
                    logAmplitudeEvent('wallet_card_error', {})
                  })
              } else {
                dispatch(updateWalletInState(res.data))
              }
              setLoading(false)
            }
          })
          .then(handleServerResponse)
          .catch((err: HttpError) => {
            notifyWalletError(err)
            setLoading(false)
            logAmplitudeEvent('wallet_save_error', {})
          })
      }
    }

    handleStripeJsResult(null)
  }

  return (
    <div style={{ padding: '8px 24px 60px 24px' }}>
      <ControlBreadCrumbs style={{ marginBottom: '20px', padding: '10px' }} currentPage="Your Wallet" />
      {isLoading ? (
        <Paper>
          <Skeleton width="100%" height="125px" />
          <div style={{ height: '20px' }}></div>
          <Skeleton width="100%" height="300px" />
        </Paper>
      ) : (
        <Paper>
          <div style={{ padding: '10px 10px 60px 10px' }}>
            {isActivated ? (
              <EditWalletPage
                setErrorMsg={setErrorMsg}
                saveStripeInfo={saveStripeInfo}
                payment_stripe_key={wallet.payment_stripe_key}
                stripeInfo={stripeInfo}
              />
            ) : (
              <EnableWalletPage />
            )}
            {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
          </div>
        </Paper>
      )}
      {isActivated && (
        <Toolbar>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <div style={{ marginRight: '20px' }}>
              <ProUXButton
                onClick={() => console.log('cancelled')}
                label="Cancel"
                type="secondary"
                disabled={loading}
              />
            </div>
            <div>
              <SaveButton
                onSave={submitForm}
                handleSubmitWithRedirect={submitForm}
                handleSubmit={submitForm}
                pristine={true}
                saving={loading}
                disabled={loading}
                invalid={false}
                label="Save"
              />
            </div>
          </div>
        </Toolbar>
      )}
    </div>
  )
}
type PropTypes = {}

const WalletHomeWrapper: React.FC<PropTypes> = (props) => (
  <Form onSubmit={(e) => e.preventDefault()} render={WalletHomeContents} />
)
export default WalletHomeWrapper

import { useMemo } from 'react'
import type { FactoryHookType } from '../type'
import maybeCreateManufacturerFilterNode from './ManufacturerFilterNodeFactory'

const useManufacturerFilterNode: FactoryHookType = ({ RendererComponent, options }) => {
  return useMemo(() => {
    return maybeCreateManufacturerFilterNode({
      componentType: options.componentType,
      RendererComponent,
    })
  }, [options.componentType, RendererComponent])
}

export default useManufacturerFilterNode

import {
  DETECT_IMAGERY_CLEAR,
  DETECT_IMAGERY_FAILURE,
  DETECT_IMAGERY_LOADING,
  DETECT_IMAGERY_REMOVE,
  DETECT_IMAGERY_SUCCESS,
  LOAD_PROGRESS_DSM,
  LOAD_PROGRESS_ORTHO,
  LOAD_PROGRESS_RENDER,
  PRELOAD_3D,
  SET_IMAGERY_BLOCK_LIST,
} from 'actions/designer'
import { Reducer } from 'redux'
import { MapDataTypes } from 'types/map'
import { RootState } from 'types/state'

export interface DetectImageryState {
  blockedMapTypes: string[]
  availableMapTypes: MapDataTypes[]
  timezoneOffset: number | null
  status: string
  cacheKey: string | null
  progressDsm: number
  progressOrtho: number
  progressRender: number
  preloadTerrainProvider: unknown //TODO: fix
}

const defaultState = {
  blockedMapTypes: [],
  availableMapTypes: [],
  timezoneOffset: 0,
  status: 'idle',
  cacheKey: null,
  progressDsm: 0,
  progressOrtho: 0,
  progressRender: 0,
  preloadTerrainProvider: undefined,
}

export const detectImageryReducer: Reducer<DetectImageryState> = (previousState = defaultState, { type, payload }) => {
  const { availableMapTypes, timezoneOffset, cacheKey, preloadTerrainProvider, blockedMapTypes } = payload || {}

  if (type === DETECT_IMAGERY_LOADING) {
    return {
      ...previousState,
      availableMapTypes: [],
      timezoneOffset: null,
      status: 'loading',
      cacheKey: cacheKey,
      preloadTerrainProvider: preloadTerrainProvider,
    }
  } else if (type === SET_IMAGERY_BLOCK_LIST) {
    return {
      ...previousState,
      blockedMapTypes: blockedMapTypes,
      availableMapTypes: previousState.availableMapTypes.filter(
        (availableMapType) => !blockedMapTypes.includes(availableMapType.map_type)
      ),
    }
  } else if (type === DETECT_IMAGERY_SUCCESS) {
    return {
      ...previousState,
      availableMapTypes: availableMapTypes.filter(
        (availableMapType) => !previousState.blockedMapTypes.includes(availableMapType.map_type)
      ),
      timezoneOffset: timezoneOffset,
      status: 'idle',
    }
  } else if (type === DETECT_IMAGERY_FAILURE) {
    return {
      ...previousState,
      availableMapTypes: [],
      timezoneOffset: null,
      status: 'idle',
      cacheKey: null,
    }
  } else if (type === DETECT_IMAGERY_CLEAR) {
    return {
      ...previousState,
      availableMapTypes: [],
      timezoneOffset: null,
      status: 'idle',
      cacheKey: null,
    }
  } else if (type === DETECT_IMAGERY_REMOVE) {
    return {
      ...previousState,
      availableMapTypes: previousState.availableMapTypes.filter(
        (availableMapType) =>
          !(
            (payload.map_type === '*' || availableMapType.map_type === payload.map_type) &&
            (payload.variation_name === '*' || availableMapType.variation_name === payload.variation_name)
          )
      ),
    }
  } else if (type === PRELOAD_3D) {
    var { dsm, ortho } = payload || {}

    // Preload DSM
    var terrainLoader = new window.THREE.TerrainLoader()
    //
    // // Need to remove the querystring for google to avoid errors
    // var dsmUrlCleaned = terrainUrlsRequest.dsm.split('?')[0]
    var dsmUrlCleaned = window.SceneHelper.cleanUrlForTerrainEndpoint(dsm)

    try {
      terrainLoader.preload(dsmUrlCleaned)
    } catch (e) {
      console.warn(e)
    }

    window.SceneHelper.preloadOrthoUrl(ortho)

    return previousState
  } else if (type === LOAD_PROGRESS_ORTHO) {
    return {
      ...previousState,
      progressOrtho: payload.fraction,
    }
  } else if (type === LOAD_PROGRESS_DSM) {
    return {
      ...previousState,
      progressDsm: payload.fraction,
    }
  } else if (type === LOAD_PROGRESS_RENDER) {
    return {
      ...previousState,
      progressRender: payload.fraction,
    }
  } else {
    return previousState
  }
}

export const imagerySelectors = {
  getAvailableImageryTypes: (state: RootState) => {
    return state.designer?.detectImagery?.availableMapTypes
  },
  getTimezoneOffset(state: RootState) {
    return state.designer?.detectImagery?.timezoneOffset
  },
  getBlockedMapTypes: (state: RootState): string[] => {
    return state.designer?.detectImagery?.blockedMapTypes || []
  },
}

import React, { CSSProperties } from 'react'

type PropTypes = {
  text?: string
  color?: string
  style?: Partial<CSSProperties>
}

const LoadingDots: React.FunctionComponent<PropTypes> = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        height: 'inherit',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        ...(props.style || {}),
      }}
    >
      <div className="spinner">
        <div className="bounce1" style={{ backgroundColor: props.color ? props.color : undefined }}></div>
        <div className="bounce2" style={{ backgroundColor: props.color ? props.color : undefined }}></div>
        <div className="bounce3" style={{ backgroundColor: props.color ? props.color : undefined }}></div>
      </div>
      {props.text && <span style={{ marginTop: '30px', textAlign: 'center' }}>{props.text}</span>}
    </div>
  )
}
export default LoadingDots

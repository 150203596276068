import { Stack } from '@mui/material'

const MessageContent = ({ children }: { children: React.ReactNode }) => (
  <Stack spacing={0.7}>
    {typeof children === 'string'
      ? children.split('. ').map((sentence, i) => <span key={i}>{sentence.trim()}</span>)
      : children}
  </Stack>
)

export const summaryMessage = (
  messageParts: (string | { heading?: string; subHeading?: string; messages: string[] })[]
): React.ReactNode => (
  <MessageContent>
    {messageParts.map((part, i) => {
      if (typeof part === 'string') {
        return (
          <span key={i} style={{ fontSize: '0.7rem' }}>
            {part}
          </span>
        )
      }
      return (
        <Stack key={i} spacing={0.5}>
          {part.heading && <span style={{ fontSize: '0.8rem', paddingBottom: '2px' }}>{part.heading}</span>}
          {part.subHeading && (
            <span style={{ fontSize: '0.7rem', fontWeight: 'bold', paddingTop: '2px' }}>{part.subHeading}</span>
          )}
          {part.messages.map((message, j) => (
            <span key={j} style={{ fontSize: '0.7rem' }}>
              {message}
            </span>
          ))}
        </Stack>
      )
    })}
  </MessageContent>
)

import { Tab, Tabs } from '@material-ui/core'
import React from 'react'
import { useTranslate } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  tabsWrapper: {
    width: '100%',
    display: 'flex',
  },
  tabs: {
    backgroundColor: theme.palette.grey[300],
    width: 'fit-content',
    minHeight: 24,
    padding: 4,
    borderRadius: 4,
  },
  tab: {
    minWidth: 120,
    minHeight: 16,
    padding: 6,
    borderRadius: 4,
    lineHeight: 1,
    textTransform: 'none',
    fontWeight: 400,
    '&$selected': {
      backgroundColor: 'white',
      opacity: 1,
    },
  },
  indicator: {
    display: 'none',
  },
  selected: {},
}))

interface TabProps {
  label: string
  value: string
}

type PropTypes = {
  onChange: (e, v) => void
  tabs: TabProps[]
  tabSelected: string
  placement?: 'left' | 'center' | 'right'
}

export const TabSelect: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <div className={classes.tabsWrapper} style={{ justifyContent: props?.placement || 'center' }}>
      <Tabs
        value={props.tabSelected}
        onChange={props.onChange}
        classes={{ root: classes.tabs, indicator: classes.indicator }}
        centered
      >
        {props.tabs.map((tab, i) => (
          <Tab
            classes={{ root: classes.tab, selected: classes.selected }}
            label={translate(tab.label)}
            value={tab.value}
            key={tab.value}
            wrapped
          />
        ))}
      </Tabs>
    </div>
  )
}

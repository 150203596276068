import { Dialog, DialogTitle, Tooltip } from '@material-ui/core'
import { CloseOutlined, InfoOutlined, LaunchOutlined } from '@material-ui/icons'
import { authSelectors } from 'ducks/auth'
import Alert from 'elements/Alert'
import { StyledDialogContent } from 'layout/StyledDialog'
import { Button, IconButton } from 'opensolar-ui'
import SaveButton from 'projectSections/elements/header/actions/SaveButton'
import { useNotify, useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { BooleanInput, SelectInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { RoleSelectInput } from 'resources/connectedOrgs/RoleSelectInput'
import { OrgConnection } from 'resources/connectedOrgs/types'
import { useStyles } from './styles'
import { getUrl } from './util'
interface PropTypes {
  close: Function
  isOpen: boolean
  connectedOrgs: OrgConnection[]
  index: number
  projectId: number
}

const OrgSharingDialog: React.FC<PropTypes> = (props) => {
  const form = useForm()
  const formState = useFormState()
  const notify = useNotify()
  const classes = useStyles()
  const translate = useTranslate()
  const orgId = useSelector(authSelectors.getOrgId)

  const fieldsInvalid = useMemo(() => {
    const dirty: string[] = form.mutators.getFormDirtyFields()
    return !!dirty.find((f) => f.startsWith('shared_with'))
  }, [formState.values])

  const handleOrgChange = (e) => {
    const findItem = props.connectedOrgs.find((x) => x.org_name === e.target.value)
    if (findItem) {
      const shareInstance = {
        org_id: findItem['org_id'],
        org_name: e.target.value,
        permission: findItem['permission_url'].replace('/permissions_role/', `/orgs/${orgId}/permissions_role/`),
        is_shared: false,
        permission_role_title: findItem['permission_role_title'],
      }
      form.mutators.updateField(`shared_with[${props.index}]`, shareInstance)
    }
  }

  const handleRoleChange = (e) => {
    getUrl(e.target.value).then((res) => {
      form.mutators.updateField(`shared_with[${props.index}].permission_role_title`, res.data.title)
    })
  }

  /*const onBeforeSave = () => {
    const dirty: string[] = form.mutators.getFormDirtyFields()
    for (const field of dirty) {
      if (field.startsWith('shared_with')) {
        const index = field.split('[')[1].split(']')[0]
        form.mutators.markFieldAsDirty(`shared_with[${index}].org_id`)
      }
    }
  }

  const handleSave = () => {
    const shared_with = formState.values.shared_with
    saveShareSettings(orgId, props.projectId, shared_with)
      .then((res) => {
        form.mutators.updateFieldSilently('shared_with', shared_with)
        const dirty = form.mutators.getFormDirtyFields()
        for (const field of dirty) {
          if (field.startsWith('shared_with')) form.mutators.markFieldAsClean(field)
        }
        window.location.reload()
        notify('Project sharing settings saved.')
        props.close(true)
      })
      .catch((err) => {
        console.log(err)
        notify('Failed to saving project sharing settings. Please try again.', 'warning')
      })
  }*/

  return (
    <Dialog open={props.isOpen}>
      <DialogTitle>
        <div className={classes.dialogTitle}>{translate('Teams Settings')}</div>
        <IconButton className={classes.closeIcon} onClick={() => props.close()}>
          <CloseOutlined />
        </IconButton>
      </DialogTitle>

      <StyledDialogContent className={classes.shareDialog}>
        <div className={classes.row}>
          <p>
            {translate('Share project with')}{' '}
            <a href="#/connected_orgs" target="_blank" rel="noopener noreferrer">
              <IconButton size="small">
                <LaunchOutlined className={classes.linkIcon} />
              </IconButton>
            </a>
            :
          </p>
        </div>
        <div className={classes.inputRow}>
          <SelectInput
            optionText={'org_name'}
            optionValue={'org_name'}
            choices={
              formState.values.shared_with?.[props.index]
                ? props.connectedOrgs
                : props.connectedOrgs.filter((x) => x.is_active)
            }
            source={`shared_with[${props.index}].org_name`}
            className={classes.input}
            label={false}
            onChange={handleOrgChange}
          />
          <Tooltip
            title={translate(
              "Assigning a connected Org allows you to use the other Org's components and settings in your project's design, and/or grant the Org access to the project."
            )}
            PopperProps={{ style: { zIndex: 2001 } }}
          >
            <InfoOutlined className={classes.infoIcon} />
          </Tooltip>
        </div>
        {formState.values.shared_with[props.index] && (
          <>
            <div className={classes.row}>
              <p>
                {translate('Define project access for this organisation')}{' '}
                <a href="#/connected_orgs_roles" target="_blank" rel="noopener noreferrer">
                  <IconButton size="small">
                    <LaunchOutlined className={classes.linkIcon} />
                  </IconButton>
                </a>
                :
              </p>
            </div>

            <div className={classes.inputRow}>
              <RoleSelectInput
                disabled={undefined}
                predefined_only={undefined}
                role_type={1}
                source={`shared_with[${props.index}].permission`}
                label={''}
                className={classes.input}
                onChange={handleRoleChange}
                setIsAdmin={false}
                helperText=""
              />
              <Tooltip
                title={translate('Restrict access to project functionality by selecting a permission set.')}
                PopperProps={{ style: { zIndex: 2001 } }}
              >
                <InfoOutlined className={classes.infoIcon} />
              </Tooltip>
            </div>
            <div className={classes.row}>
              <BooleanInput
                className={classes.input}
                source={`shared_with[${props.index}].is_shared`}
                label={translate('Project visible to %{partner_org}', {
                  partner_org: formState.values.shared_with[props.index]?.org_name || translate('partner org'),
                })}
              />
              <Tooltip
                title={translate('Enable to make this project visible to the connected Org.')}
                PopperProps={{ style: { zIndex: 2001 } }}
              >
                <InfoOutlined className={classes.infoIcon} style={{ marginLeft: 10 }} />
              </Tooltip>
            </div>
            {formState.values.shared_with[props.index].is_shared && (
              <Alert severity="warning">
                {translate(
                  'Warning: Data such as pricing, payment options (and more) may be visible to the connected org when you share it with them.'
                )}
              </Alert>
            )}
          </>
        )}
        <div className={classes.dialogActions}>
          <Button variant="contained" onClick={() => props.close()}>
            {translate('Cancel')}
          </Button>
          <SaveButton disabled={!fieldsInvalid} />

          {/* leaving this in case we find a workaround 
          for not having to reload the page for saving share settings
          <Button
            variant="contained"
            color="primary"
            className={classes.saveBtn}
            onClick={handleSave}
            disabled={!fieldsInvalid}
          >
            {translate('Save')}
          </Button> */}
        </div>
      </StyledDialogContent>
    </Dialog>
  )
}

export default OrgSharingDialog

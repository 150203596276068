import { Card, FormControl, LinearProgress, makeStyles, MenuItem, Select } from '@material-ui/core'
import { LOCALES_SUPPORTED } from 'App'
import UiContainer from 'elements/UiContainer'
import { UiSwitch } from 'elements/UiSwitch'
import React, { FC, useCallback, useEffect } from 'react'
import { useLocale, useSetLocale } from 'react-admin'
import { useSelector } from 'react-redux'
import appStorage from 'storage/appStorage'

type PropsType = {
  children: JSX.Element
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    //CSS3 100vh not constant in mobile browser
    //https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
    minHeight: window.Utils?.iOS() ? '-webkit-fill-available' : '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Roboto, sans-serif',
  },
  container: {
    width: '100%',
    maxWidth: 700,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    borderRadius: 8,
    margin: 10,
    padding: '0.5em 1.5em 1em 1.5em',
    boxShadow: 'none',
    border: '1px solid rgba(0,0,0,0.2)',
  },
  outlined: {
    padding: 5,
    paddingLeft: 20,
    fontSize: 12,
  },
  formControl: {
    marginTop: 5,
    '& fieldset': {
      border: 'none',
    },
  },
}))

const AuthModalWrapper: FC<PropsType> = ({ children }) => {
  const classes = useStyles()
  const isLoading = useSelector((state: any) => Boolean(state.admin && state.admin.loading))
  const currentLocale = useLocale()
  const setLocale = useSetLocale()
  const handleLocaleChange = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
    const locale = event.target.value as string
    setLocale(locale)
    appStorage.setLocale(locale)
  }, [])
  const uiKey = 'account_modal.locales.'

  useEffect(() => {
    //do we still need this hack in UX2?

    // Hack: When we are redirected away from studio due to not logged in, designer component may not be hidden,
    // probably due to a race condition. When we arrive at Login/Register we can be sure that studio should not appear
    if (window.launch) {
      window.launch({}, 'hidden')
    }

    setTimeout(function () {
      if (window.launch) {
        window.launch({}, 'hidden')
      }
    }, 1000)
  }, [])
  return (
    <UiSwitch uiKey="account_modal">
      <div className={classes.wrapper}>
        {Boolean(isLoading) && <LinearProgress style={{ position: 'absolute', bottom: 0 }} />}
        <div className={classes.container}>
          <Card classes={{ root: classes.card }} raised={true}>
            {children}
          </Card>
          <UiContainer uiKey={uiKey + 'language_dropdown'}>
            <div>
              <FormControl variant="outlined" classes={{ root: classes.formControl }}>
                <Select value={currentLocale} classes={{ outlined: classes.outlined }} onChange={handleLocaleChange}>
                  {Object.keys(LOCALES_SUPPORTED).map((key: string) => (
                    <MenuItem key={key} value={key}>
                      {LOCALES_SUPPORTED[key]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </UiContainer>
        </div>
      </div>
    </UiSwitch>
  )
}

export default AuthModalWrapper

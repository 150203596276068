import React, { Component } from 'react'
import ChartistGraph from 'react-chartist'
import { withTranslate } from 'react-admin'
import Chartist from 'chartist'
import ChartistHover from 'chartist-hover'
import { monthlyToDailyPerMonth, addUpdateCssRule } from '../../util/misc'

const MonthLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const toolTipDefaultStyle = {
  position: 'absolute',
  background: 'rgba(237, 237, 237, 0.9)',
  borderRadius: 3,
  textAlign: 'left',
  minWidth: 170,
  padding: 4,
}

function customRound(value) {
  if (value > 100) {
    return Math.round(value).toLocaleString(window.locale)
  } else {
    return (Math.round(10 * value) / 10).toLocaleString(window.locale)
  }
}

function ctBarLabels(options) {
  return function ctBarLabels(chart) {
    var defaultOptions = {
      labelClass: 'ct-bar-label-small',
      labelInterpolationFnc: Chartist.noop,
      labelOffset: {
        x: 0,
        y: 8,
      },
      position: {
        x: null,
        y: null,
      },
      textAnchor: 'middle',
    }

    options = Chartist.extend({}, defaultOptions, options)

    var positionX =
      options.position.x ||
      function (data) {
        return (data.x1 + data.x2) / 2 + options.labelOffset.x
      }

    var positionY =
      options.position.y ||
      function (data) {
        return data.y2 - options.labelOffset.y
      }

    if (chart instanceof Chartist.Bar) {
      chart.on('draw', function (data) {
        if (data.type === 'bar') {
          data.element.attr({
            data_by_month:
              data.index + ',' + data.series.data[data.index] + ',' + options.energyConsumptionData[data.index],
          })
          data.element.attr({ id: data.index + '-energy-generation-bar' })
          data.group
            .elem(
              'text',
              {
                // This gets the middle point of the bars and then adds the
                // optional offset to them
                x: positionX(data),
                y: positionY(data),
                style: 'text-anchor: middle',
              },
              options.labelClass
            )
            // Add bar label with series name if != 0
            .text(Math.round(data.value.y) !== 0 ? customRound(data.value.y) : '')
        }
      })
    }
  }
}

const maxMonthlyOutputForSystem = (system) => Math.max.apply(null, monthlyToDailyPerMonth(system.output.monthly))

export const maxAxisYForAllSystems = (systems, selectedProject) => {
  const maxMonthlyOutputForAllSystems = systems.map((system) => maxMonthlyOutputForSystem(system))

  const maxMonthlyOutput = Math.max.apply(null, maxMonthlyOutputForAllSystems)

  const maxMonthlyGeneration = Math.max.apply(null, selectedProject.usage_normalized.daily_per_month)

  return Math.max(maxMonthlyOutput, maxMonthlyGeneration) * 1.1
}

class EnergyConsumptionAndGenerationChart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      toolTipStyle: {},
      month: undefined,
      generation: undefined,
      consumption: undefined,
    }

    addUpdateCssRule(
      '.EnergyConsumptionChartSolarGenerationChart .EnergyConsumptionChartSolarGeneration .ct-bar-hover',
      `{ stroke: ${props.myeStyles.highlightColorInteraction} !important; }`
    )
  }

  onMouseLeave = (e) => {
    this.setState({
      toolTipStyle: {},
      month: undefined,
      generation: undefined,
      consumption: undefined,
    })
  }

  onMouseEnter = (e) => {
    if (!Boolean(e.srcElement.getAttribute('data_by_month'))) return
    const data_by_month = e.srcElement.getAttribute('data_by_month').split(',')
    const month = data_by_month[0]
    const index = parseInt(e.srcElement.id)
    if (month !== this.state.month && index >= 0) {
      const generation = data_by_month[1]
      const consumption = data_by_month[2]
      this.setState({
        toolTipStyle: { top: e.offsetY - 75, left: e.offsetX - 75 },
        month,
        generation,
        consumption,
      })

      //highlight hover line
      const hoverBar = window.$(
        '.EnergyConsumptionChartSolarGenerationChart .EnergyConsumptionChartSolarGeneration .ct-bar'
      )[index]
      hoverBar && hoverBar.setAttribute('class', 'ct-bar ct-bar-hover')
    }
  }

  render() {
    const { translate } = this.props
    const { month, toolTipStyle, generation, consumption } = this.state
    const { selectedProject, maxMonthlyEnergy, selectedSystem } = this.props
    const style = { ...toolTipDefaultStyle, ...toolTipStyle }
    return (
      <div>
        <div style={{ position: 'relative', textAlign: 'center', marginLeft: 50 }}>
          <div
            className="LegendEnergyConsumption"
            style={{
              marginRight: 10,
              width: 10,
              height: 10,
              display: 'inline-block',
              backgroundColor: '#ffd502',
            }}
          />
          <div
            style={{
              marginRight: 20,
              fontSize: 10,
              display: 'inline-block',
            }}
            className="small"
          >
            {translate('GENERATION')} (kWh/{translate('day')})
          </div>

          <div
            style={{
              marginRight: 10,
              width: 10,
              height: 10,
              display: 'inline-block',
              backgroundColor: '#dbdbdb',
            }}
          />
          <div style={{ marginRight: 20, fontSize: 10, display: 'inline-block' }} className="small">
            {translate('CONSUMPTION')} (kWh/{translate('day')})
          </div>
        </div>

        <div style={{ height: 250, marginBottom: 0 }}>
          <ChartistGraph
            style={{
              marginTop: 0,
              marginLeft: 0,
              marginBottom: 0,
              marginRight: 0,
              width: '100%',
              height: '230px',
            }}
            data={{
              series: [
                {
                  name: translate('Energy Usage'),
                  className: 'EnergyConsumptionChartEnergyUsage',
                  data: selectedProject.usage_normalized.daily_per_month.map(function (value) {
                    return value
                  }),
                },
              ],
            }}
            options={{
              axisX: { showGrid: false },
              axisY: { scaleMinSpace: 30 },
              chartPadding: 20,
              showLabel: false,
              showArea: true,
              showLine: false,
              height: '250px',
              width: '100%',
              showPoint: false,
              lineSmooth: true,
              fullWidth: true,
              onlyInteger: true,
              referenceValue: 0,
              showGrid: false,
              low: 0,
              high: maxMonthlyEnergy ? maxMonthlyEnergy : maxAxisYForAllSystems([selectedSystem], selectedProject),
            }}
            type={'Line'}
            plugins={
              [
                // Chartist.plugins.legend({
                //     position: 'bottom',
                //     classNames: ['ct-blue', 'ct-hidden'],
                // })
              ]
            }
          />
        </div>
        <div style={{ height: 250, marginBottom: 0, position: 'relative' }}>
          {generation && consumption && (
            <div className="mye-graph-tooltips" style={style}>
              <p style={{ margin: 0 }}>
                {translate('Month')}: {translate(MonthLabels[month])}
              </p>
              <p style={{ margin: 0 }}>
                {/*{translate('Generation')}: {parseFloat(generation).toFixed(1)} kWh*/}
                {translate('Generation')}: {(Math.round(parseFloat(generation) * 10) / 10).toLocaleString(window.locale)} kWh
              </p>
              <p style={{ margin: 0 }}>
                {/*{translate('Consumption')}: {parseFloat(consumption).toFixed(1)} kWh*/}
                {translate('Consumption')}: {(Math.round(parseFloat(consumption) * 10) / 10).toLocaleString(window.locale)}{' '}
                kWh
              </p>
            </div>
          )}
          <ChartistGraph
            className={'EnergyConsumptionChartSolarGenerationChart'}
            style={{
              marginTop: -250,
              marginLeft: 0,
              marginBottom: 0,
              marginRight: 0,
              width: '100%',
              height: '230px',
            }}
            data={{
              labels: MonthLabels.map((month) => translate(month)),
              series: [
                {
                  name: translate('Solar Generation'),
                  className: 'EnergyConsumptionChartSolarGeneration',
                  data: monthlyToDailyPerMonth(selectedSystem.output.monthly),
                },
              ],
            }}
            options={{
              axisX: { showGrid: false },
              axisY: { showLabel: false, showGrid: true, scaleMinSpace: 30 },
              chartPadding: 20,
              showLabel: false,
              height: '250px',
              width: '100%',
              showPoint: false,
              lineSmooth: true,
              onlyInteger: true,
              referenceValue: 0,
              low: 0,
              high: maxMonthlyEnergy ? maxMonthlyEnergy : maxAxisYForAllSystems([selectedSystem], selectedProject),
              plugins: [
                // Hack: Not sure why we can't just use Designer.ctBarLabelsBillSavings - labels don't appear
                // Re-implementing within this file fixes labels.
                ctBarLabels({
                  textAnchor: 'middle',
                  energyConsumptionData: selectedProject.usage_normalized.daily_per_month.map(function (value) {
                    return value
                  }),
                }),
                ChartistHover({
                  onMouseEnter: this.onMouseEnter,
                  onMouseLeave: this.onMouseLeave,
                  triggerSelector: null,
                }),
              ],
            }}
            type={'Bar'}
          />
        </div>
      </div>
    )
  }
}

export default withTranslate(EnergyConsumptionAndGenerationChart)

import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles((theme) => ({
  grid: {
    margin: 20,
  },
  defaultFieldStyle: {
    marginBottom: 0,
    marginTop: 0,
    marginRight: 0,
    maxWidth: '100%',
    minWidth: 256,
  },
  toggleField: {
    marginTop: 10,
  },
  selectInputField: {
    display: 'inline-flex',
  },
  container: {
    padding: '10px 20px 20px 20px',
  },
  cell: {
    '&:last-child': {
      flexGrow: 1,
    },
  },
  containerWithBoxShadow: {
    padding: '10px 20px 20px 20px',
    borderRadius: 2,
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
  },
}))

export default useStyle

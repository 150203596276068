import { makeStyles } from '@material-ui/core'
import { SettingsOutlined } from '@material-ui/icons'
import { IconButton } from 'opensolar-ui'
import React, { useState } from 'react'
import { useFeatureFlag } from 'util/split'
import SetOrgConfigDialog from './SetOrgConfigDialog'
import { MANAGE_INTEGRATION_CONFIG_MAP } from './constants'

const useStyles = makeStyles(() => ({
  btnWrapper: {
    flex: 1,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '5px',
  },
}))
type PropTypes = {
  integration: string
  onSuccess?: () => void
}

const ManageIntegrationButton: React.FC<PropTypes> = (props) => {
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const integrationMap = MANAGE_INTEGRATION_CONFIG_MAP[props.integration]
  // @ts-ignore
  const featureFlagIsEnabled = useFeatureFlag(integrationMap.feature_flag_filter, 'on')

  const classes = useStyles()

  if (!featureFlagIsEnabled && integrationMap.feature_flag_filter) return null

  return (
    <div className={classes.btnWrapper}>
      <IconButton onClick={() => setShowDialog(true)} color="primary" size="small">
        <SettingsOutlined />
      </IconButton>
      {showDialog && (
        <SetOrgConfigDialog
          isOpen={showDialog}
          onClose={() => setShowDialog(false)}
          integrationMap={integrationMap}
          onSuccess={props.onSuccess}
        />
      )}
    </div>
  )
}
export default ManageIntegrationButton

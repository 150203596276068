import type { StockLevelStatus } from 'pages/ordering/type'
import { memo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { STOCK_LEVEL_STATUS } from './constants'

const useStatusStyles = makeOpenSolarStyles((theme) => ({
  success: {
    color: theme.palette.success.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  info: {
    textAlign: 'left',
  },
}))

export const StatusMessageTypography = memo(
  ({ message, type }: { message: string; type: 'info' | 'error' | 'success' }) => {
    const classes = useStatusStyles()
    if (type === 'success') {
      return <div className={classes.success}>{message}</div>
    }
    if (type === 'error') {
      return <div className={classes.error}>{message}</div>
    }
    return <div className={classes.info}>{message}</div>
  }
)

const StatusMessage = memo(
  ({
    status,
    size = 'standard',
    plannedDateDays,
  }: {
    status: StockLevelStatus
    size?: 'small' | 'standard'
    plannedDateDays?: number | null
  }) => {
    switch (status) {
      case STOCK_LEVEL_STATUS.AVAILABLE:
      case STOCK_LEVEL_STATUS.LOW_STOCK:
        return <StatusMessageTypography type="success" message="In Stock" />
      case STOCK_LEVEL_STATUS.PARTIAL_AVAILABLE:
        return <StatusMessageTypography type="success" message="Partially Available" />
      case STOCK_LEVEL_STATUS.NOT_AVAILABLE:
        return <StatusMessageTypography type="error" message={size === 'small' ? 'Unavailable' : 'Item Not Stocked'} />
      case STOCK_LEVEL_STATUS.OUT_OF_STOCK:
        return <StatusMessageTypography type="error" message="Out of Stock" />
      case STOCK_LEVEL_STATUS.AVAILABLE_FOR_PRE_ORDER:
        return <StatusMessageTypography type="success" message={`Pre Order is Available`} />
      default:
        return null
    }
  }
)

export default StatusMessage

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDownOutlined'
import Tooltip from 'elements/tooltip/Tooltip'
import { useTranslate } from 'ra-core'
import { FC } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { AnyComponentType } from 'types/components'
import { ComponentTypes } from 'types/selectComponent'

const useStyles = makeOpenSolarStyles<{ disabled: boolean; margin: MarginType; roundLeft: boolean }>(
  (theme) => ({
    container: ({ disabled, margin, roundLeft }) => ({
      margin: margin === 'dense' ? 0 : '10px 5px 10px 0px',
      height: margin === 'dense' ? 43 : 44,
      cursor: 'pointer',
      padding: '0px 5px 0px',
      border: theme.border.default,
      borderRight: 'none',
      borderRadius: roundLeft ? '4px 0 0 4px' : 4,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: disabled ? '#efefef' : '#ffffff',
      opacity: disabled ? 0.7 : 1,
      width: '100%',
      boxSizing: 'border-box',
    }),
    firstRow: ({ margin }) => ({
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: margin === 'dense' ? 110 : 140,
    }),
    secondRow: ({ margin }) => ({
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: 10,
      maxWidth: margin === 'dense' ? 110 : 140,
    }),
  }),
  { name: 'ComponentSelectorDropdown' }
)

export type ComponentType = AnyComponentType & {
  //   other_id?: string
}

type MarginType = 'normal' | 'dense'

type Props = {
  componentType: ComponentTypes
  component?: ComponentType
  select: () => void
  availableComponents: AnyComponentType[]
  showLogo?: boolean
  disabled?: boolean
  roundLeft?: boolean
  margin?: MarginType
}

export const ComponentSelectorDropdown: FC<Props> = ({
  componentType,
  component,
  select,
  availableComponents,
  showLogo,
  disabled = false,
  margin = 'normal',
  roundLeft = false,
}) => {
  const classes = useStyles({ disabled, margin, roundLeft })
  // showLogo is enabled by default, only hidden if showLogo===false

  const translate = useTranslate()

  //@ts-ignore
  const selectedId = componentType === 'other' ? component?.other_id : component?.id
  const isArchived =
    availableComponents &&
    selectedId &&
    !availableComponents.find((availableComponent) => availableComponent.id === selectedId)

  const secondRowParts: string[] = []

  if (component?.manufacturer_name) {
    secondRowParts.push(component.manufacturer_name)
  }

  if (componentType === 'module' && component?.kw_stc) {
    secondRowParts.push(component.kw_stc * 1000 + 'W')
  } else if (componentType === 'inverter' && component?.max_power_rating) {
    secondRowParts.push(component.max_power_rating * 1000 + 'W')
  } else if (componentType === 'battery' && component?.kwh_optimal) {
    secondRowParts.push(component?.kwh_optimal + 'kWh')
  } else if (componentType === 'other' && component?.code) {
    // Only add code to the second row if we also have a title, otherwise code is already showing in the first row
    if (component?.title) {
      secondRowParts.push(component.code)
    }
  }

  const firstRowContent = componentType === 'other' ? component?.title || component?.code : component?.code

  const secondRowContent = secondRowParts.length > 0 ? secondRowParts.join(' ') : ''

  const hasLogo = !!(showLogo !== false && component?.logo && component?.logo.length > 0)

  const toolTipInfo = [firstRowContent, secondRowContent].join('\n')

  return (
    <div
      className={classes.container}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        if (disabled) {
          return
        }
        select()
      }}
    >
      <Tooltip
        data-html={true}
        title={<div style={{ whiteSpace: 'pre-line' }}>{toolTipInfo}</div>}
        placement="bottom-start"
        enterDelay={1000}
      >
        <div>
          <div className={classes.firstRow}>
            {firstRowContent} {isArchived && `(${translate('archived')})`}
          </div>
          {secondRowContent && <div className={classes.secondRow}>{secondRowContent}</div>}
        </div>
      </Tooltip>

      {hasLogo && (
        <div style={{ height: 44, marginLeft: 'auto' }}>
          <img
            style={{ maxHeight: 30, maxWidth: 40, margin: '0 auto', verticalAlign: 'middle' }}
            src={component?.logo}
          />
          <i style={{ display: 'inline-block', height: '100%', width: 0, verticalAlign: 'middle' }} />
        </div>
      )}
      <ArrowDropDownIcon style={hasLogo ? {} : { marginLeft: 'auto' }} />
    </div>
  )
}

// @ts-nocheck
import { makeStyles } from '@material-ui/core'
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined'
import { authSelectors } from 'ducks/auth'
import { Button } from 'opensolar-ui'
import { GenerateAndDownloadPvsellProposal } from 'projectSections/sections/proposal/PvsellProposalDialog'
import React, { FC } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { PreviewNotAvailable } from './PreviewNotAvailable'

type PropsType = {
  pvProSellTemplateId: number
  projectId: number | string
}

const useStyles = makeStyles((theme: any) => ({
  button: {
    position: 'fixed',
    zIndex: 2,
    bottom: 60,
    right: 20,
    color: theme.headerFontColor,
    background: theme.themeColor,
    '&:hover': {
      background: theme.themeColorDark,
    },
  },
}))

const DownloadButton: FC<PropsType> = ({ pvProSellTemplateId, projectId }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const api_key_pvsell = useSelector(authSelectors.getPvSellKey)
  return (
    <Button
      classes={{ root: classes.button }}
      startIcon={<DescriptionIcon />}
      onClick={() => {
        GenerateAndDownloadPvsellProposal(projectId, pvProSellTemplateId, () => {}, 'pdf')
      }}
      variant="outlined"
    >
      <span>{translate('DOWNLOAD PDF')}</span>
    </Button>
  )
}

const PvProSellPreview: FC<PropsType> = ({ pvProSellTemplateId, projectId }) => {
  return (
    <>
      <DownloadButton pvProSellTemplateId={pvProSellTemplateId} projectId={projectId} />
      <PreviewNotAvailable
        message={
          'Preview is not available for PVprosell Template. Please click the “Download PDF” to generate the SunWiz Proposal PDF.'
        }
      />
    </>
  )
}

export default PvProSellPreview

import { GET_LIST } from 'ra-core'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import restClient from 'restClient'
import { ReduxActionType } from 'types/global'
import { WalletPaymentMethodType, WalletTopUpSettingsType, WalletType } from 'types/wallet'

export const GET_WALLET_SETTINGS = 'GET_WALLET_SETTINGS'
export const GET_WALLET_SETTINGS_SUCCESS = 'GET_WALLET_SETTINGS_SUCCESS'
export const GET_WALLET_SETTINGS_FAIL = 'GET_WALLET_SETTINGS_FAIL'
export const CUSTOM_FETCH_SUCCESS = 'CUSTOM_FETCH_SUCCESS'
export const UPDATE_WALLET_IN_STATE = 'UPDATE_WALLET_IN_STATE'
export const REMOVE_DELETED_CARD = 'REMOVE_DELETED_CARD'

type WalletStoreType = {
  loading: boolean
  wallets: WalletType[]
  is_wallet_active: boolean
}

const initialState: WalletStoreType = {
  is_wallet_active: false,
  wallets: [],
  loading: false,
}

export default (state: WalletStoreType = initialState, action: ReduxActionType) => {
  switch (action.type) {
    case GET_WALLET_SETTINGS:
      return {
        ...state,
        loading: true,
      }
    case GET_WALLET_SETTINGS_SUCCESS:
      const isInactive = action?.payload?.data?.length === 0
      return {
        ...state,
        loading: false,
        is_wallet_active: !isInactive,
        wallets: action?.payload?.data,
      }
    case UPDATE_WALLET_IN_STATE:
    case 'CUSTOM_FETCH_SUCCESS':
      if (
        action.type === UPDATE_WALLET_IN_STATE ||
        (action?.meta?.resource === 'wallets' && action?.payload?.data?.id)
      ) {
        let newWallets = state.wallets || []
        let foundExisting = false
        // make sure we are de-duping
        newWallets = newWallets.map((existingWallet: WalletType) => {
          if (existingWallet && existingWallet.id === action.payload.data.id) {
            foundExisting = true
            return action.payload.data
          } else return existingWallet
        })
        if (!foundExisting) newWallets.push(action.payload.data)
        return {
          ...state,
          loading: false,
          is_wallet_active: true,
          wallets: newWallets,
        }
      } else {
        return state
      }
    case GET_WALLET_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
      }
    case REMOVE_DELETED_CARD:
      let newWallets = state.wallets?.map((wallet) => {
        if (wallet.id === action.payload.data.walletId) {
          wallet.payment_methods = wallet.payment_methods?.filter((pmt) => pmt.id !== action.payload.data.cardId)
        }
        return wallet
      })
      return {
        ...state,
        wallets: newWallets,
      }
  }
  return state
}

export function* getWalletSettingsSaga(action: ReduxActionType) {
  try {
    const walletSettings = yield call(doGetWalletSettings)
    yield put({ type: GET_WALLET_SETTINGS_SUCCESS, payload: { data: walletSettings } })
  } catch (e) {
    yield put({ type: GET_WALLET_SETTINGS_FAIL, key: action.payload.key })
  }
}

export const doGetWalletSettings = () => {
  return new Promise((resolve, reject) => {
    const restClientInstance = restClient(window.API_ROOT + '/api')
    restClientInstance(GET_LIST, 'wallets', {})
      .then((response: any) => {
        resolve(response.data)
      })
      .catch((err: any) => {
        console.log('err', err)
        reject(false)
      })
  })
}

export const getWalletSettings = () => {
  return {
    type: GET_WALLET_SETTINGS,
  }
}

export const updateWalletInState = (newWallet: WalletType) => {
  return {
    type: UPDATE_WALLET_IN_STATE,
    payload: {
      data: newWallet,
    },
  }
}

export const removeWalletCreditCardFromDate = (deletedCardId: number, walletId: number) => {
  return {
    type: REMOVE_DELETED_CARD,
    payload: {
      data: {
        cardId: deletedCardId,
        walletId: walletId,
      },
    },
  }
}

export const walletSettingsSelectors = {
  getIsLoading: (state: any): boolean => !!state.wallet?.loading,
  getIsWalletActivated: (state: any): boolean => !!state.wallet?.is_wallet_active,
  getWallet: (state: any): WalletType | undefined => {
    let wallets = state.wallet?.wallets
    if (!wallets || wallets?.length === 0) return undefined
    else return wallets[0]
  },
  getBalance: (state: any): number | undefined => {
    let wallet = walletSettingsSelectors.getWallet(state)
    return wallet?.balance
  },
  getTopUpSettings: (state: any): WalletTopUpSettingsType => {
    let wallet = walletSettingsSelectors.getWallet(state)
    if (!wallet) {
      return {
        enable_auto_top_up: false,
        top_up_amount: undefined,
        top_up_trigger_amount: undefined,
        currency: undefined,
      }
    }
    return {
      enable_auto_top_up: wallet.enable_auto_top_up,
      top_up_amount: wallet.top_up_amount,
      top_up_trigger_amount: wallet.top_up_trigger_amount,
      currency: wallet.currency,
    }
  },
  getIsTopUpEnabled: (state: any): boolean => {
    const topUpSettings: WalletTopUpSettingsType = walletSettingsSelectors.getTopUpSettings(state)
    return !!topUpSettings?.enable_auto_top_up
  },
  getHasWalletBeenEnabed: (state: any): boolean => {
    let wallet = walletSettingsSelectors.getWallet(state)
    return !!wallet?.is_enabled
  },
  getPaymentMethods: (state: any): WalletPaymentMethodType[] => {
    let wallet = walletSettingsSelectors.getWallet(state)
    if (!wallet || !wallet.payment_methods) return []
    return wallet.payment_methods
  },
  getCurrencyCode: (state: any): string | undefined => {
    let wallet = walletSettingsSelectors.getWallet(state)
    if (!wallet || !wallet.payment_methods) return undefined
    return wallet.currency
  },
}

export function* walletSagas() {
  yield all([takeEvery(GET_WALLET_SETTINGS, getWalletSettingsSaga)])
}

// @ts-nocheck
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import SaveIcon from '@material-ui/icons/SaveOutlined'
import { Button } from 'opensolar-ui'
import React, { FC, useMemo } from 'react'
import { useTranslate } from 'react-admin'
import useTranslateParse from '../../../util/useTranslateParse'
import ToolTip from '../tools/MuiToolTip'
import Spotlight from '../tools/Spotlight'
import useFindElements from '../tools/useFindElements'
import { SpotlightConfigType, SpotlightPropsType, ToolTipPropsType, TourConfigType } from '../types'
import DefaultTooltip from './DefaultToolTip'

const ClickSaveButtonToolTip = (props: ToolTipPropsType) => {
  const { handleNext, handlePrev, handleCancel } = props
  const translate = useTranslate()
  const translateParse = useTranslateParse()
  return (
    <DefaultTooltip
      title={translate('SAVE PROJECT')}
      // onClose={handleCancel}
      icon={SaveIcon}
      content={
        <div>
          <div>
            {translateParse('Whenever you make a change to your project, the <i>Save<i> button will appear.', {
              i: (label: string) => <i>{label}</i>,
            })}
            <div style={{ margin: '10px 0' }}>
              {translate(
                'You can choose to save on every step or navigate between different system designs, and even across different tabs (e.g. Info, Energy, Design) without saving, and without losing your work.'
              )}{' '}
            </div>
            {translateParse('You can now <strong>Save<strong> this project to finish the tutorial.', {
              strong: (label: string) => <strong>{label}</strong>,
            })}
          </div>
          <div style={{ color: 'rgba(0, 148, 255, 1)', marginTop: 10, display: 'flex' }}>
            <InfoOutlinedIcon style={{ verticalAlign: 'middle', width: 16, height: 16 }} />
            <span style={{ verticalAlign: 'middle', marginLeft: 5 }}>
              {translate(
                "It's important that you save before switching to a different project, or sending the Online Proposal to the customer."
              )}
            </span>
          </div>
        </div>
      }
      actions={
        <>
          <Button
            onClick={() => handlePrev && handlePrev()}
            onTouchStart={(e) => {
              e.stopPropagation()
              if (handlePrev) {
                handlePrev()
              }
            }}
          >
            <span>{translate('Back')}</span>
          </Button>
          <Button disabled={true}>
            <span>{translate('Next')}</span>
          </Button>
        </>
      }
    />
  )
}

const ClickProposalTooltip = (props: ToolTipPropsType) => {
  const { handleNext, handlePrev, handleCancel } = props
  const translate = useTranslate()
  const translateParse = useTranslateParse()
  return (
    <DefaultTooltip
      title={translate('View Proposal')}
      // onClose={handleCancel}
      content={
        <div>
          {translateParse(
            'Now that you have designed the system, OpenSolar has automatically created a beautiful proposal for your customer. You can see the output of your system, customers savings, payback period and how the proposal looks by jumping to the <b>Online Proposal<b> page.',
            { b: (label: string) => <b>{label}</b> }
          )}{' '}
        </div>
      }
      actions={
        <>
          <Button
            onClick={() => handlePrev && handlePrev()}
            onTouchStart={(e) => {
              e.stopPropagation()
              if (handlePrev) {
                handlePrev()
              }
            }}
          >
            <span>{translate('Back')}</span>
          </Button>
          {/* <Button disabled={true}>Next</Button> */}
        </>
      }
      arrowPlacement={'top'}
    />
  )
}

const ToolTips = [
  { targetId: 'subheader-online-proposal', placement: 'bottom', component: ClickProposalTooltip },
  {
    targetId: 'save-project-button',
    placement: 'bottom',
    component: ClickSaveButtonToolTip,
    options: { style: { left: 'unset', right: 20 } },
  },
]

const SaveProjectToolTip: FC<ToolTipPropsType> = ({ handleNext, handlePrev, handleCancel }) => {
  const foundElementId = useFindElements(
    ToolTips.map((a: any) => a.targetId),
    true
  )
  const tooltip: any = useMemo(() => ToolTips.find((a: any) => a.targetId === foundElementId), [foundElementId])
  return tooltip ? (
    <ToolTip
      key={`tour-step-${tooltip.targetId}-tooltip`}
      handleNext={handleNext}
      handlePrev={handlePrev}
      handleCancel={handleCancel}
      {...tooltip}
    />
  ) : null
}

const Spotlights = [{ targetId: 'subheader-online-proposal' }, { targetId: 'save-project-button' }]

const SaveProjectSpotlight: FC<SpotlightPropsType> = ({ handleHover }) => {
  const foundElementId = useFindElements(
    Spotlights.map((a: any) => a.targetId),
    true
  )
  const spotlight: SpotlightConfigType | undefined = useMemo(
    () => Spotlights.find((a: any) => a.targetId === foundElementId),
    [foundElementId]
  )
  return spotlight ? (
    <Spotlight key={`tour-step-${spotlight.targetId}-spotlight`} handleHover={handleHover} {...spotlight} />
  ) : null
}

const SaveProject: TourConfigType = {
  spotlights: [<SaveProjectSpotlight />],
  tooltips: [<SaveProjectToolTip />],
}

export default SaveProject

import type { OpenSolarThemeType } from 'Themes'
import type { ToolbarProps } from 'elements/list/ListTable'
import React, { memo } from 'react'
import { ProjectFilter } from 'resources/projects/projectsList/ProjectFilter'
import { ProjectListSearchTitle } from 'resources/projects/projectsList/ProjectListSearchTitle'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
  },
}))

// Todo: refactor toolbar to create our own component
const ProjectTableToolbar = memo(({ resource, filter, filterPresenter }: ToolbarProps) => {
  const classes = useStyles()

  return (
    <div className={classes.toolbar}>
      <ProjectListSearchTitle filterValues={filter.filterValues} setFilters={filterPresenter.setFilters} />
      <ProjectFilter
        resource={resource}
        context={'button'}
        filterValues={filter.filterValues}
        displayedFilters={filter.displayedFilters}
        showFilter={filterPresenter.showFilter}
        setFilters={filterPresenter.setFilters}
        hideFilter={filterPresenter.hideFilter}
      />
      <ProjectFilter
        resource={resource}
        context={'form'}
        filterValues={filter.filterValues}
        displayedFilters={filter.displayedFilters}
        showFilter={filterPresenter.showFilter}
        setFilters={filterPresenter.setFilters}
        hideFilter={filterPresenter.hideFilter}
      />
    </div>
  )
})

export default ProjectTableToolbar

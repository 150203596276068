import React from 'react'
import { useTranslate } from 'react-admin'
import { ORG_ROLE_TYPE } from 'types/customRole'
import useTranslateParse from 'util/useTranslateParse'
import { CustomRoleList } from './CustomRoleList'
import { CustomRoleCreate, CustomRoleEdit } from './CustomRoles'

const Subtitle = () => {
  const translate = useTranslate()
  const translateParse = useTranslateParse()
  return (
    <div>
      <span>{translate('Add and edit roles on this page.')}</span>{' '}
      <span>
        {translateParse('For a detailed overview of role permissions see <a>here<a>', {
          a: (label) => (
            <a href={'https://support.opensolar.com/hc/en-us/articles/4416624009497'} target="_blank" rel="noreferrer">
              {label}
            </a>
          ),
        })}
        .
      </span>
    </div>
  )
}

export const CustomRoles_user = {
  list: CustomRoleList,
  edit: CustomRoleEdit,
  create: CustomRoleCreate,
  options: {
    list: {
      title: 'Custom Roles',
      subtitle: <Subtitle />,
      breadCrumb: 'Custom Roles',
    },
    create: {
      title: 'Create Custom Role',
      subtitle: 'Create custom roles with new permissions.',
      breadCrumb: 'Create Custom Role',
    },
    edit: {
      title: 'Edit Custom Role',
      subtitle: null,
      breadCrumb: 'Edit Custom Role',
    },
  },
}

export const CustomRoles_orgs = {
  ...CustomRoles_user,
  list: (props) => <CustomRoleList {...props} roleType={ORG_ROLE_TYPE} />,
  edit: (props) => <CustomRoleEdit {...props} />,
  create: (props) => <CustomRoleCreate {...props} roleType={ORG_ROLE_TYPE} />,
  options: {
    list: {
      title: 'Sharing Permissions',
      subtitle: 'Permission sets for limiting access to shared projects.',
      breadCrumb: 'Sharing Permissions',
    },
    create: {
      title: 'Create Sharing Permissions',
      subtitle: 'Create permissions for shared projects.',
      breadCrumb: 'Create Sharing Permissions',
    },
    edit: {
      title: 'Edit Sharing Permissions',
      subtitle: null,
      breadCrumb: 'Edit Sharing Permissions',
    },
  },
}

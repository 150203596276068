export const ALL_SECTOR_CHOICE = 0
export const RESIDENTIAL_SECTOR_CHOICE = 1
export const COMMERCIAL_SECTOR_CHOICE = 2
export const INDUSTRIAL_SECTOR_CHOICE = 3
export const LIGHTING_SECTOR_CHOICE = 4

export const SECTOR_CHOICES = [
  { id: ALL_SECTOR_CHOICE, name: 'All' },
  { id: RESIDENTIAL_SECTOR_CHOICE, name: 'Residential' },
  { id: COMMERCIAL_SECTOR_CHOICE, name: 'Commercial' },
  { id: INDUSTRIAL_SECTOR_CHOICE, name: 'Industrial' },
  { id: LIGHTING_SECTOR_CHOICE, name: 'Lighting' },
]

export const SERVICE_OFFERING_OPTIONS = [
  {
    id: 'id_1',
    name: 'Solar Sales',
  },
  {
    id: 'id_2',
    name: 'Solar Installation',
  },
  {
    id: 'id_3',
    name: 'Electrification Solutions (EV Chargers, Heat Pumps...)',
  },
  {
    id: 'id_4',
    name: 'Manufacturer',
  },
  {
    id: 'id_5',
    name: 'Distributor',
  },
  {
    id: 'id_6',
    name: 'Consulting',
  },
  {
    id: 'id_7',
    name: 'Student',
  },
  {
    id: 'id_8',
    name: 'Not applicable (e.g. home owner)',
  },
]

// @ts-nocheck
import React from 'react'
import { Toolbar, useTranslate } from 'react-admin'
// import Button from 'elements/proUXButtons/ProUXButton'
import { makeStyles } from '@material-ui/core/styles'
import Button from 'elements/button/Button'
import DeleteButton from 'elements/proUXButtons/DeleteButton'
import SaveButton from 'elements/proUXButtons/SaveButton'
import { FormEvent } from 'react'
import { ProjectTransactionType } from 'types/projectTransactions'

const useStylesToolbar = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
    [theme.breakpoints.down('xs')]: {
      position: 'static',
    },
  },
}))

const useStyles = makeStyles({
  footer: {
    margin: 0,
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  rightButtonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  cancelButtonWrapper: {
    marginRight: '15px',
  },
  leftButton: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
})

type PropTypes = {
  resource: string
  onSuccess: Function
  onFailure: Function
  onDelete: Function
  onDismiss: () => void
  record: ProjectTransactionType
  handleSubmitWithRedirect: (event: FormEvent<Element>) => void
  handleSubmit: (event: FormEvent<Element>) => void
  invalid: boolean
  pristine: boolean
  saving: boolean
  mode: 'create' | 'edit'
  hideCancelButton?: boolean
  deleteButtonLabel?: string
  deleteDisabled?: boolean
  saveDisabled?: boolean
  loading?: boolean
  fetchingUpdatedRecord?: boolean
}

const ToolBarContents = (props: PropTypes) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <div className={classes.footer}>
      <div className={classes.leftButton}>
        {props.mode === 'edit' && !props?.hideDeleteBtn && (
          <DeleteButton
            record={props.record || {}}
            label={translate(props.deleteButtonLabel || 'Delete')}
            resource={props.resource}
            onSuccess={props.onDelete}
            onFailure={props.onFailure}
            invalid={!props.record}
            disabled={props.deleteDisabled}
          />
        )}
      </div>
      <div className={classes.rightButtonGroup}>
        <div className={classes.cancelButtonWrapper}>
          {!props.hideCancelButton && (
            <Button variant="contained" color="default" label="Cancel" onClick={props.onDismiss} />
          )}
        </div>
        <div>
          <SaveButton
            label="Save"
            // variant="contained"
            color="primary"
            {...props}
            redirect={undefined}
            submitOnEnter={true}
            onSave={(values: object, redirect: string, form: any, defaultSaveFunc: Function) => {
              defaultSaveFunc(values, redirect, { onSuccess: props.onSuccess, onFailure: props.onFailure })
            }}
            disabled={props.saveDisabled}
            saving={!!props.saving || !!props.fetchingUpdatedRecord}
          />
        </div>
      </div>
    </div>
  )
}

const CRUDRecordDialogFooter: React.FunctionComponent<PropTypes> = (props) => {
  const classes = useStylesToolbar()
  return (
    <Toolbar {...props} className={classes.toolbar}>
      <ToolBarContents {...props} />
    </Toolbar>
  )
}

export default CRUDRecordDialogFooter

import { CircularProgress, Dialog, DialogContent, Popover, TextField, Tooltip } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import ActionDelete from '@material-ui/icons/DeleteOutlined'
import { push as pushAction } from 'connected-react-router'
import Alert from 'elements/Alert'
import _ from 'lodash'
import { Button, Checkbox, IconButton } from 'opensolar-ui'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Edit,
  ReferenceArrayInput,
  SaveButton,
  showNotification as showNotificationAction,
  SimpleForm,
  useTranslate,
} from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useDebouncedCallback } from 'util/Debounce'
import { parseQueryStringToDictionary } from 'util/misc'
import ClearableChipField from './ClearableChipField'

const ConfirmationPopover = ({ anchorEl, handleDelete, handleCancel, saving }) => {
  const translate = useTranslate()
  const showConfirmation = Boolean(anchorEl)

  return (
    <Popover
      open={showConfirmation}
      anchorEl={anchorEl}
      onClose={handleCancel}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
    >
      <div style={{ padding: '20px 20px 0 20px' }}>
        <Alert severity="warning">
          <p>{translate('Are you sure you want to remove this data sharing authorisation?')}</p>
          <p>
            {translate('Certain exhibitors require data sharing in order to have access to their exhibited content.')}
          </p>
        </Alert>
        <div style={{ textAlign: 'right' }}>
          <Button onClick={handleCancel}>{translate('Cancel')}</Button>
          <SaveButton
            label="Ok"
            icon={saving ? <CircularProgress size={18} thickness={2} /> : <ActionDelete />}
            handleSubmitWithRedirect={handleDelete}
          />
        </div>
      </div>
    </Popover>
  )
}

const Activation = ({ handleAdd, showNotification }) => {
  const translate = useTranslate()
  const inputRef = useRef()
  const formValues = useFormState().values
  const [exhibitorActivationCodeToCheck, setExhibitorActivationCodeToCheck] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [exhibitorActivationCodeOrg, setExhibitorActivationCodeOrg] = useState(null)
  const [isChecked, setChecked] = useState(false)
  const location = useLocation()
  useEffect(() => {
    const params = location.params || parseQueryStringToDictionary(location.search)
    if (params && params.exhibit_activation_code) {
      setExhibitorActivationCodeToCheck(params.exhibit_activation_code)
      inputRef.current.focus()
    }
  }, [])
  const handleExhibitorActivationCodeUpdate = useDebouncedCallback((value) => {
    checkExhibitActivationCode(value)
  }, 500)
  useEffect(() => {
    handleExhibitorActivationCodeUpdate(exhibitorActivationCodeToCheck)
  }, [exhibitorActivationCodeToCheck])
  const checkExhibitActivationCode = (value) => {
    // On first load when supplying code form URL, the form value is equal to null
    // so when value is null we will use params.exhibit_activation_code from URL instead
    if (!value) {
      return
    }

    window.$.ajax({
      url: window.API_BASE_URL + 'check_exhibit_activation_code/?exhibit_activation_code=' + value,
      dataType: 'json',
      context: this,
      success: function (data) {
        if (data && data.org && data.org) {
          if (formValues['exhibit_data_shares'].includes(data.org.url)) {
            setErrorMessage(`You are already sharing data with ${data.org.name}`)
          } else {
            setExhibitorActivationCodeOrg(data.org)
            setErrorMessage('')
          }
        } else {
          setExhibitorActivationCodeOrg(null)
          setErrorMessage('no available exhibitors found')
        }
      },
      error: function (response) {
        setExhibitorActivationCodeOrg(null)
        setErrorMessage('Invalid activation code.')
      },
    })
  }

  const allowSubmit = Boolean(exhibitorActivationCodeOrg && exhibitorActivationCodeOrg.data_sharing_agreement_url)
    ? Boolean(isChecked)
    : Boolean(exhibitorActivationCodeOrg && exhibitorActivationCodeOrg.url)
  return (
    <div style={{ margin: '40px 0px' }}>
      <p>
        {translate(
          'Enter a partner activation code below to review the data sharing authorisation form for that partner. Contact the partner directly if you do not already have their activation code.'
        )}
      </p>
      <TextField
        value={exhibitorActivationCodeToCheck}
        onChange={(e) => setExhibitorActivationCodeToCheck(e.target.value)}
        label={null}
        inputRef={inputRef}
        variant="outlined"
        error={errorMessage}
        helperText={errorMessage}
        inputProps={{ style: { padding: 10 } }}
      />

      {exhibitorActivationCodeOrg && exhibitorActivationCodeOrg.data_sharing_agreement_url && (
        <>
          <div style={{ display: 'flex', alignItems: 'flex-start', margin: '10px 0' }}>
            <Checkbox color="primary" onChange={(e, value) => setChecked(value)} checked={isChecked} />
            <p style={{ lineHeight: '20px', margin: 0 }}>
              {translate('data_sharing_consent', { exhibitName: exhibitorActivationCodeOrg.name || 'this exhibitor' })}
            </p>
          </div>
          <a target="_blank" rel="noreferrer" href={exhibitorActivationCodeOrg.data_sharing_agreement_url}>
            {translate('Download Data Sharing Authorisation')}
          </a>
        </>
      )}

      <Button
        disabled={!allowSubmit}
        style={{ display: 'block', marginTop: 20 }}
        variant="outlined"
        onClick={() => {
          showNotification(`You have successfully authorized data sharing with ${exhibitorActivationCodeOrg.name}`)
          handleAdd(exhibitorActivationCodeOrg.url)
          setExhibitorActivationCodeToCheck('')
          setExhibitorActivationCodeOrg(null)
          setChecked(false)
        }}
      >
        <span>{translate('Submit')}</span>
      </Button>
    </div>
  )
}

const DataSharingDialogContent = ({ prePopulateActivation, ...props }) => {
  const form = useForm()
  const formState = useFormState()
  const [redirect, setRedirect] = useState('/')
  const [isPrePopulated, setPrePopulated] = useState(false)
  const location = useLocation()
  useEffect(() => {
    const params = location.params || parseQueryStringToDictionary(location.search)
    if (params && params.redirect) {
      setRedirect(params.redirect)
    }

    if (params && params.exhibit_activation_code) {
      setPrePopulated(true)
    }
  }, [])
  const [confirmationPopoverAnchorEl, setPopoverAnchorEl] = React.useState(null)
  const [deleteItem, setDeleteItem] = useState(null)

  const translate = useTranslate()
  const popoverRef = useRef()
  const deleteItemRef = useRef()
  const formValuesRef = useRef()

  formValuesRef.current = formState.values
  deleteItemRef.current = deleteItem
  const handleAdd = useCallback(
    (newItem) => {
      const inputValue = formValuesRef.current && formValuesRef.current.exhibit_data_shares
      const newValue = _.clone(inputValue)
      newValue.push(newItem)
      form.change('exhibit_data_shares', newValue)
      const redirect = isPrePopulated ? '/projects' : '/data_sharing'
      props.handleSubmitWithRedirect(redirect)
    },
    [isPrePopulated]
  )
  const handleDelete = useCallback(() => {
    const inputValue = formValuesRef.current && formValuesRef.current.exhibit_data_shares
    const newValue = _.clone(inputValue)
    _.remove(newValue, (item) => item === deleteItemRef.current)
    form.change('exhibit_data_shares', newValue)
    props.handleSubmitWithRedirect('/data_sharing')
    props.showNotification('Data Deleted')
    setPopoverAnchorEl(null)
    setDeleteItem(null)
  }, [])
  const showConfirmationPopover = useCallback((deleteItem, input) => {
    setDeleteItem(deleteItem)
    popoverRef.current && setPopoverAnchorEl(popoverRef.current)
  }, [])
  return (
    <DialogContent ref={popoverRef} style={{ margin: 0, padding: 0 }}>
      <IconButton
        id="DataSharingCloseButton"
        style={{
          top: 10,
          right: 10,
          position: 'absolute',
          minWidth: 40,
          backgroundColor: 'rgba(255, 255, 255, 0)',
        }}
        onClick={() => props.push(redirect)}
      >
        <Tooltip title={translate('Close Dialog')} enterDelay={300}>
          <CloseIcon style={{ color: '#000000' }} />
        </Tooltip>
      </IconButton>
      {!isPrePopulated && (
        <>
          {formState.values.exhibit_data_shares.length > 0 ? (
            <p>{translate('You are currently sharing data with these partners:')}</p>
          ) : (
            <p style={{ fontStyle: 'italic' }}>{translate('You are not currently sharing data with any partners.')}</p>
          )}

          <ConfirmationPopover
            anchorEl={confirmationPopoverAnchorEl}
            handleCancel={() => {
              setPopoverAnchorEl(null)
              setDeleteItem(null)
            }}
            saving={props.saving}
            handleDelete={handleDelete}
          />
          <ReferenceArrayInput
            source="exhibit_data_shares"
            name="exhibit_data_shares"
            reference="public_orgs"
            optionValueField="url"
            {...props}
          >
            <ClearableChipField
              filterFunction={(item) => item && item.enable_exhibit === true}
              source="exhibit_data_shares"
              optionText="name"
              optionValue="url"
              allowDelete={true}
              onDelete={showConfirmationPopover}
            />
          </ReferenceArrayInput>
        </>
      )}
      <Activation handleAdd={handleAdd} showNotification={props.showNotification} />
    </DialogContent>
  )
}

const _DataSharingDialog = (props) => {
  const translate = useTranslate()
  return (
    <Dialog name="DataSharing" open={true} maxWidth="xs" className="data-sharing-dialog">
      <Edit
        {...props}
        basePath="/orgs"
        resource="orgs"
        id={props.orgId}
        hideBreadCrumbs={true}
        title={translate('Data Sharing Authorisations')}
        style={{ padding: 0 }}
      >
        <SimpleForm
          hideCancelButton={true}
          toolbar={<div />}
          initialValues={(record) => {
            return { exhibit_data_shares: record.exhibit_data_shares }
          }}
        >
          <DataSharingDialogContent {...props} />
        </SimpleForm>
      </Edit>
    </Dialog>
  )
}
const mapStateToProps = (state) => {
  return {
    orgId: state.auth ? state.auth.org_id : null,
  }
}

export default connect(mapStateToProps, { push: pushAction, showNotification: showNotificationAction })(
  _DataSharingDialog
)

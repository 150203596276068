import { SystemContext } from 'Designer/designRules/types'
import { NotificationType, SHOW_NOTIFICATION } from 'ra-core'
import { EffectFactory } from '.'

export type ShowMessageEffectType = {
  type: 'show-notification'
  msg: string
  notifType: string
}

export const effect_showNotification: EffectFactory<ShowMessageEffectType> = (def) => {
  return {
    execute: async (context: SystemContext) => {
      // @TODO make JSON def of this type of effect to accept a redux payload instead
      context.dispatch({
        type: SHOW_NOTIFICATION,
        payload: {
          type: def.notifType as NotificationType,
          message: def.msg,
        },
      })
    },
  }
}

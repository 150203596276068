import { Link, makeStyles } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { useState } from 'react'
import { FormDataConsumer, useNotify, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import restClient from 'restClient'

const restClientInstance = restClient(window.API_ROOT + '/api')

const useStyles = makeStyles({
  wrapLink: {
    alignItems: 'center',
    textDecoration: 'underline',
    cursor: 'pointer',
    padding: '0',
    background: 'transparent',
    fontSize: 14,
  },
  disabledLink: {
    color: '#808080 !important',
    alignItems: 'center',
    textDecoration: 'none',
    cursor: 'not-allowed',
    fontSize: 14,
  },
})

type PropTypes = {
  componentId: number
}

const SubmitForApprovalButton: React.FC<PropTypes> = (props) => {
  const org = useSelector(authSelectors.getCurrentOrg)
  const [submittedDate, setSubmittedDate] = useState<string | null>(null)
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false)
  const notify = useNotify()
  const classes = useStyles()
  const translate = useTranslate()

  const handleSubmit = async () => {
    try {
      restClientInstance('CUSTOM_POST', 'custom', {
        url: `orgs/${org?.id}/component_content/${props.componentId}/email_submit_approval/`,
      })
        .then((response) => {
          setSubmittedDate(response.data.submitted_date)
          notify('Request submitted successfully', 'success')
        })
        .catch((error) => {
          console.warn('Error trying to send email: ', error)
        })
    } catch (error) {
      notify('Failed to submit request', 'error')
      setIsButtonDisabled(false)
    }
  }

  const handleResend = async () => {
    if (isButtonDisabled) return
    try {
      setIsButtonDisabled(true)
      restClientInstance('CUSTOM_POST', 'custom', {
        url: `orgs/${org?.id}/component_content/${props.componentId}/email_submit_approval/`,
      })
        .then((response) => {
          setSubmittedDate(response.data.submitted_date)
          notify('Request submitted successfully', 'success')
        })
        .catch((error) => {
          console.warn('Error trying to send email: ', error)
          setIsButtonDisabled(false)
        })
    } catch (error) {
      notify('Failed to resend request', 'error')
      setIsButtonDisabled(false)
    }
  }

  const getSubmittedDateString = (date1: string | null, date2: string | null): string => {
    if (date1) return new Date(date1).toDateString()
    if (date2) return new Date(date2).toDateString()
    return new Date().toDateString()
  }

  return (
    <FormDataConsumer {...props}>
      {({ formData, ...rest }) => (
        <div>
          {formData.submitted_for_approval_date || submittedDate ? (
            <div>
              <p>{`Submitted for approval on ${getSubmittedDateString(
                formData.submitted_for_approval_date,
                submittedDate
              )}. If you haven't received a response within 2 business days, you can resend it for approval.`}</p>
              <Link
                onClick={handleResend}
                className={isButtonDisabled ? classes.disabledLink : classes.wrapLink}
                component="button"
                type="button"
                disabled={isButtonDisabled}
              >
                {translate('Resend for Approval')}
              </Link>
            </div>
          ) : (
            <Link onClick={handleSubmit} className={classes.wrapLink} component="button" type="button">
              {translate('Submit for Approval')}
            </Link>
          )}
        </div>
      )}
    </FormDataConsumer>
  )
}

export default SubmitForApprovalButton

import { orgSelectors } from 'ducks/orgs'
import { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { currencySymbolForCountry } from 'util/misc'
import FiguresBanner from '../components/FiguresBanner'
import type { DashboardStatisticsData } from './type'

type DashboardFiguresProps = {
  loading: boolean
  dashboardStatistics: DashboardStatisticsData | undefined
}

const DashboardFigures = ({ loading, dashboardStatistics }: DashboardFiguresProps) => {
  const countryIso2 = useSelector(orgSelectors.getOrgIso2)
  const currencySymbol = useMemo(() => currencySymbolForCountry(countryIso2), [countryIso2])
  const totalValueInInventory = useMemo(() => {
    const value = parseFloat(dashboardStatistics?.total_value_in_inventory || '0')
    return value?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }, [dashboardStatistics])

  const figureConfigs = useMemo(() => {
    return [
      {
        key: 'sold_not_installed',
        figure: dashboardStatistics?.sold_not_installed?.toLocaleString(),
        description: 'Sold projects not yet installed',
      },
      {
        key: 'additional_items_needed',
        figure: dashboardStatistics?.additional_items_needed?.toLocaleString(),
        description: 'Additional items needed',
      },
      {
        key: 'total_items_in_inventory',
        figure: dashboardStatistics?.total_items_in_inventory?.toLocaleString(),
        description: 'Total items in inventory',
      },
      {
        key: 'total_value_in_inventory',
        figure: `${currencySymbol}${totalValueInInventory}`,
        description: 'Value on hand',
      },
    ]
  }, [dashboardStatistics])

  return <FiguresBanner loading={loading} title={'Inventory Dashboard'} figureConfigs={figureConfigs} />
}

export default memo(DashboardFigures)

import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orgSelectors } from 'ducks/orgs'
import { useNotify, useTranslate } from 'ra-core'
import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { CashFlowConfiguration } from '../../types'
import { sanitizeCashFlowConfigForm } from '../utils'
import ConfigurationForm from './ConfigurationForm'

const restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  config: CashFlowConfiguration
  setIsUpdated?: (val: boolean | undefined) => void
  setNewConfig?: (val: CashFlowConfiguration | undefined) => void
  autoAppliedConfigId?: number | undefined
}

const UpdateConfiguration: React.FC<PropTypes> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const orgId = useSelector(orgSelectors.getOrg)?.id
  const notify = useNotify()
  const translate = useTranslate()

  const onSave = (vals: any) => {
    if (orgId) {
      setIsLoading(true)
      restClientInstance('CUSTOM_PUT', 'custom', {
        url: `orgs/${orgId}/payments/configuration/${vals.id}/`,
        data: sanitizeCashFlowConfigForm(vals),
      })
        .then((res) => {
          props.setIsUpdated?.(true)
          notify(translate('Your updates have been saved', 'success'))
          let amplitudeFriendlyVals = { ...vals }
          amplitudeFriendlyVals.milestone_count = vals.payment_milestone_configurations.length
          vals?.payment_milestone_configurations?.forEach((milestone) => {
            amplitudeFriendlyVals[`milestone.${milestone.payment_number}.percentage`] = milestone.percentage
            amplitudeFriendlyVals[`milestone.${milestone.payment_number}.min_fixed`] = milestone.min_fixed
            amplitudeFriendlyVals[`milestone.${milestone.payment_number}.max_fixed`] = milestone.max_fixed
            amplitudeFriendlyVals[`milestone.${milestone.payment_number}.fixed_amount`] = milestone.fixed_amount
            amplitudeFriendlyVals[`milestone.${milestone.payment_number}.surcharging_enabled`] =
              milestone.surcharging_enabled
            amplitudeFriendlyVals[`milestone.${milestone.payment_number}.remove_from_percentage_calcs`] =
              milestone.remove_from_percentage_calcs
          })
          logAmplitudeEvent('cashflow_milestone_configuration_saved', amplitudeFriendlyVals)
        })
        .catch((err) => {
          if (err?.body?.message) notify(err.body.message, 'warning')
          else if (err?.body?.non_field_errors?.length) notify(err.body.non_field_errors[0], 'warning')
          else notify(translate('Something went wrong'), 'error')
        })
        .finally(() => {
          props.setIsUpdated?.(false)
          setIsLoading(false)
        })
    }
  }

  return (
    <div>
      <Form
        initialValues={props.config}
        onSubmit={onSave}
        render={(formProps) => (
          <ConfigurationForm
            {...formProps}
            onSave={onSave}
            isLoading={isLoading}
            setNewConfig={props.setNewConfig}
            setIsUpdated={props.setIsUpdated}
          />
        )}
      />
    </div>
  )
}
export default UpdateConfiguration

import { CameraIcon, FileIcon, FormControl, FormLabel, styled, UploadIcon } from 'opensolar-ui'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import React from 'react'
import { FileField, FileInput, FunctionField } from 'react-admin'
import { Field } from 'react-final-form'
import { doNotTranslate } from 'util/misc'
import { ListCalloutBox } from '../elements/ListCalloutBox'
import InlineTooltip from './EnaFields'

export type UploadStatus = 'initial' | 'uploading' | 'uploadFailed' | 'uploadComplete'

export interface UploadTips {
  title: string
  listItems: string[]
}

interface FileInputStaticData {
  isRequired: boolean
  isMultiple: boolean
  showImgExample: boolean
  placeholderIcon: React.ReactNode
  title: string | null
  tooltip: string | null
  uploadTips: UploadTips | null
  fileTypesAccepted: string
  maxSizeMb: number
}

type FileInputFieldName = 'exportLimitSLDAttachment' | 'cutOutImages' | 'additionalAttachments'

const FILE_INPUT_STATIC_DATA: Record<FileInputFieldName, FileInputStaticData> = {
  exportLimitSLDAttachment: {
    isRequired: true,
    isMultiple: false,
    showImgExample: false,
    placeholderIcon: <UploadIcon />,
    title: 'Export Limit SLD Attachment',
    tooltip:
      'Please upload a single line diagram of the export limit device. This should show the main components of the export limit device and how they are connected',
    uploadTips: null,
    fileTypesAccepted: '',
    maxSizeMb: 5,
  },
  cutOutImages: {
    isRequired: true,
    isMultiple: false,
    showImgExample: true,
    placeholderIcon: <CameraIcon />,
    title: 'Cut-Out Image',
    tooltip: null,
    uploadTips: {
      title: 'You must upload one image per application. Only one is allowed.',
      listItems: [
        'Is clear and in focus',
        'Is taken within 1m of the cut-out',
        'Contains the whole cut-out',
        'Is centred on the cut-out',
        'Is appropriately lit - use your flash if not',
        'Is no more than 5 mb',
        'PDFs are not accepted. Please convert to an image file such as PNG or JPEG',
      ],
    },
    fileTypesAccepted: '.png,.jpeg,.jpg',
    maxSizeMb: 5,
  },
  additionalAttachments: {
    isRequired: false,
    isMultiple: true,
    showImgExample: false,
    placeholderIcon: <UploadIcon />,
    title: 'Additional Attachment(s)',
    tooltip: null,
    uploadTips: {
      title: 'Please upload any supplementing information relevant to your application.',
      listItems: [
        'This may include technical data sheets related to the devices selected, or any single line diagrams.',
        'A data-sheet is required for any new device which you have defined manually in the new devices section.',
        'Please name your files (prior to upload) in a way that makes it clear what they are',
        'You can upload any number of files, but each must be less than 5MB in size.',
        'If you need to upload larger files, try zipping them and uploading the zip.',
        'No SVGs permitted. Please convert to PNG or JPEG',
      ],
    },
    fileTypesAccepted: '',
    maxSizeMb: 5,
  },
}

const ExamplesContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: '0px 10px 10px',
}))

const Example = styled('div')(({ theme }) => ({
  width: '50%',
  overflow: 'hidden',
  padding: '0px 10px 10px',
  '&>img': {
    width: '100%',
    borderRadius: 8,
  },
}))

export const FilesField: React.FunctionComponent<any> = (props) => {
  const { path, fieldName } = props

  const fileInputStaticData: FileInputStaticData = FILE_INPUT_STATIC_DATA[fieldName]

  return (
    <div>
      {fileInputStaticData?.uploadTips && (
        <div style={{ marginBottom: 10 }}>
          <ListCalloutBox
            isCheckBullet
            listItems={fileInputStaticData?.uploadTips.listItems}
            title={fileInputStaticData?.uploadTips.title}
          >
            {fileInputStaticData.showImgExample && (
              <ExamplesContainer>
                <Example>
                  <p>Good image:</p>
                  <img src={`${window.PUBLIC_URL}/images/ena_good_img_eg.png`} />
                </Example>
                <Example>
                  <p>Bad image:</p>
                  <img src={`${window.PUBLIC_URL}/images/ena_bad_img_eg.png`} />
                </Example>
              </ExamplesContainer>
            )}
          </ListCalloutBox>
        </div>
      )}
      <FileInputContainer>
        <Field
          required={fileInputStaticData.isRequired}
          name={path}
          type="file"
          validate={(v) => {
            if (fileInputStaticData.isRequired) {
              return Array.isArray(v) && !v.length
            }
          }}
        >
          {({ input, meta: { error } }) => {
            return (
              <FormControl fullWidth required={fileInputStaticData.isRequired} margin="dense">
                {fileInputStaticData.title && (
                  <LabelContainer>
                    <FormLabel required={fileInputStaticData.isRequired}>
                      {fileInputStaticData.title}
                      {fileInputStaticData.tooltip && <InlineTooltip tooltip={fileInputStaticData.tooltip} />}
                    </FormLabel>
                  </LabelContainer>
                )}
                <StyledFileInput
                  source={path}
                  accept={fileInputStaticData.fileTypesAccepted}
                  placeholder={
                    <FileInputPlaceholderContainer>
                      <FileInputPlaceholderIcon>{fileInputStaticData.placeholderIcon}</FileInputPlaceholderIcon>
                      <FileInputHelperText>
                        {doNotTranslate('Drag & drop or')}
                        <span> {doNotTranslate('browse files')}</span>
                      </FileInputHelperText>
                    </FileInputPlaceholderContainer>
                  }
                  required
                  multiple={fileInputStaticData.isMultiple}
                  label={false}
                  maxSize={fileInputStaticData.maxSizeMb * 1048576}
                >
                  <FunctionField
                    render={(file) => {
                      const size = file?.rawFile?.size && file.rawFile.size / 1000000
                      return (
                        <SelectedFileContainer>
                          <StyledFileIcon size={24} />
                          <p>
                            {file?.rawFile?.path} {file?.fileUrl}
                          </p>
                          {size && <span>{size.toFixed(2)}MB</span>}
                          <FileField {...file} />
                        </SelectedFileContainer>
                      )
                    }}
                  />
                </StyledFileInput>
              </FormControl>
            )
          }}
        </Field>
      </FileInputContainer>
    </div>
  )
}

export const FileInputPlaceholder = () => {
  return (
    <FileInputPlaceholderContainer>
      <FileInputPlaceholderIcon>
        <UploadIcon />
      </FileInputPlaceholderIcon>
      <FileInputHelperText>
        {doNotTranslate('Drag & drop or')}
        <span> {doNotTranslate('browse files')}</span>
      </FileInputHelperText>
    </FileInputPlaceholderContainer>
  )
}

interface SelectedFileRowProps {
  name: string
  size?: string
}

export const SelectedFileRow: React.FunctionComponent<SelectedFileRowProps> = ({ name, size, children }) => {
  return (
    <SelectedFileContainer>
      <StyledFileIcon size={24} />
      <p>{name}</p>
      {size && <span>{size} MB</span>}
      {children}
    </SelectedFileContainer>
  )
}

const StyledFileInput = styled(FileInput)(({ theme }) => ({
  '& .MuiFormLabel-root': {
    display: 'none !important',
  },
}))

const FileInputContainer = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  marginBottom: -12,
  '& .previews > div': {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignContent: 'baseline',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 12,
    paddingLeft: 12,
    border: '2px solid',
    borderColor: theme.palette.grey[300],
  },
}))

const LabelContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  marginTop: 10,
  marginBottom: -15,
  flexDirection: 'row',
  '& .OSUI-FormLabel-root': {
    width: '100%',
  },
}))

const FileInputPlaceholderContainer = styled('div')(({ theme }) => ({
  border: '3px dashed',
  borderColor: theme.palette.grey[300],
  backgroundColor: 'white',
  margin: -7,
  borderRadius: 5,
  padding: 24,
  textAlign: 'center',
}))

const FileInputPlaceholderIcon = styled('div')(({ theme }) => ({
  '& svg': {
    width: 50,
    height: 50,
    marginTop: 10,
    backgroundColor: theme.palette.grey[100],
    borderRadius: 8,
    padding: 8,
  },
}))

const FileInputHelperText = styled('p')(({ theme }) => ({
  '& span': {
    color: COLOR_PALETTE.blue3,
  },
}))

const SelectedFileContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 10,
  alignItems: 'center',
  '& p': {
    fontWeight: 500,
  },
  '& span': {
    color: theme.palette.grey[700],
    fontWeight: 400,
    fontSize: 12,
  },
}))

const StyledFileIcon = styled(FileIcon)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  padding: 4,
  borderRadius: 5,
  color: theme.palette.grey[700],
}))

import { Tooltip } from '@material-ui/core'
import Button from 'elements/button/Button'
import ClickableIcon from 'elements/ClickableIcon'
import React from 'react'

type PropTypes = {
  title: string
  label?: string
  icon?: any
  clickFunc: any
  disabled: boolean
  loading?: boolean
  buttonStyle?: any
  iconButton?: boolean
  color?: 'primary' | 'secondary' | 'default'
  size?: 'small' | 'medium' | 'large'
  variant?: 'text' | 'outlined' | 'contained'
  classes?: string
}

const TooltipWithDisabledButton: React.FunctionComponent<PropTypes> = ({
  title,
  label,
  icon,
  clickFunc,
  disabled,
  loading,
  buttonStyle,
  iconButton,
  color,
  size,
  classes,
  variant = 'outlined',
}) => {
  return (
    <Tooltip title={title}>
      <div className={buttonStyle || undefined}>
        {iconButton ? (
          <ClickableIcon onClick={() => clickFunc()} disabled={disabled}>
            {icon}
          </ClickableIcon>
        ) : (
          <Button
            className={classes}
            variant={variant}
            label={label}
            loading={loading}
            startIcon={icon}
            onClick={() => clickFunc()}
            disabled={disabled}
            fullWidth
            color={color}
            size={size}
          >
            {label}
          </Button>
        )}
      </div>
    </Tooltip>
  )
}

export default TooltipWithDisabledButton

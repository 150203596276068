import { orgSelectors } from 'ducks/orgs'
import { useGetCashFlowConfigurations } from 'pages/cashFlow/configuration/utils'
import { useGetIsCashFlowVisible } from 'pages/cashFlow/utils'
import { useTranslate } from 'ra-core'
import { SelectInput } from 'ra-ui-materialui'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

type PropTypes = {}
type ChoiceType = { id: string | null; name: string }

const PaymentsConfigurationSelection: React.FC<PropTypes> = (props) => {
  const [configChoices, setConfigChoices] = useState<ChoiceType[]>([])

  const orgId = useSelector(orgSelectors.getOrg)?.id
  const configs = useGetCashFlowConfigurations(orgId, 1, 100)
  const isCashFlowVisible = useGetIsCashFlowVisible()
  const cashFlowActiveOnOrg = useSelector(orgSelectors.getCashFlowIsActive)
  const translate = useTranslate()

  useEffect(() => {
    if (configs) {
      let asChoices: ChoiceType[] = configs
        ?.filter((config) => !config.is_archived)
        ?.map((config) => {
          return { id: config.url, name: config.title }
        })
      asChoices.push({ id: null, name: translate('None') })
      setConfigChoices(asChoices)
    }
  }, [configs])

  if (!isCashFlowVisible || !cashFlowActiveOnOrg) return null
  return (
    <SelectInput
      source="payments_configuration"
      choices={configChoices}
      label="CashFlow Configuration Override"
    ></SelectInput>
  )
}
export default PaymentsConfigurationSelection

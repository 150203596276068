// in src/users.js
import CloudDownloadIcon from '@material-ui/icons/CloudDownloadOutlined'
import { DefaultBreadCrumbs } from 'elements/BreadCrumbs'
import ChipsInput from 'elements/input/ChipsInput'
import { List } from 'elements/react-admin/List'
import ListActions from 'elements/react-admin/ListActions'
import { IconButton } from 'opensolar-ui'
import React from 'react'
import {
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  FileField,
  FileInput,
  FunctionField,
  ReferenceArrayInput,
  SimpleForm,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'

export const PublicFileList = ({ accessRights: { allowCreate, allowEdit, allowDelete }, ...props }) => {
  return (
    <List
      breadCrumbs={<DefaultBreadCrumbs />}
      actions={<ListActions createButtonLabel="Add New File" />}
      titleStyle={{ fontSize: 16, lineHeight: '16px', paddingTop: 0 }}
      title={'Public Files'}
      {...props}
      hasCreate={allowCreate}
    >
      <Datagrid>
        <TextField source="title" />
        <DownloadIconLink />
        <FunctionField
          source={null}
          label="Actions"
          render={(record, source, rest) => (
            <div>
              {allowEdit && <EditButton useListStyle={true} record={record} basePath={rest.basePath} />}
              {allowDelete && (
                <DeleteButton
                  useListStyle={true}
                  undoable={false}
                  record={record}
                  resource={rest.resource}
                  redirect={`/${rest.resource}`}
                />
              )}
            </div>
          )}
          sortable={false}
        />
      </Datagrid>
    </List>
  )
}

const DownloadIconLink = ({ record }) => {
  if (record.file_contents && record.file_contents.length > 0) {
    return (
      <a href={record.file_contents} target="_blank" rel="noreferrer">
        <IconButton>
          <CloudDownloadIcon />
        </IconButton>
      </a>
    )
  } else {
    return (
      <IconButton disabled={true}>
        <CloudDownloadIcon />
      </IconButton>
    )
  }
}

const inputStyle = { width: 256 }

export const PublicFileEdit = ({ accessRights: { allowView, allowCreate, allowEdit, allowDelete }, ...props }) => {
  return (
    <Edit breadCrumbs={<DefaultBreadCrumbs />} {...props} hasDelete={allowDelete}>
      <SimpleForm disabled={!allowEdit}>
        <DownloadIconLink />

        <ReferenceArrayInput
          source="file_tags"
          reference="file_tags"
          optionValueField="url"
          filter={{ type: 'public' }}
          filterToQuery={(searchText) => ({ search: searchText, type: 'public' })}
        >
          <ChipsInput
            optionText="title"
            optionValue="url"
            translateChoice={false}
            options={{ style: inputStyle, placeholder: 'Enter a tag' }}
          />
        </ReferenceArrayInput>

        <TextInput style={inputStyle} source="title" />
      </SimpleForm>
    </Edit>
  )
}

const validateForm = (values, translate) => {
  const errors = {}
  if (!values.file_contents) {
    errors.file_contents = [translate('File is required')]
  }
  return errors
}

export const PublicFileCreate = (props) => {
  const translate = useTranslate()
  return (
    <Create breadCrumbs={<DefaultBreadCrumbs />} {...props}>
      <SimpleForm validate={(values) => validateForm(values, translate)}>
        <FileInput
          source="file_contents"
          placeholder={<p>{translate('Click to upload or drag & drop a file here')}</p>}
        >
          <FileField style={inputStyle} source="src" title="title" />
        </FileInput>

        <ReferenceArrayInput
          source="file_tags"
          reference="file_tags"
          optionValueField="url"
          filter={{ type: 'public' }}
          style={inputStyle}
          filterToQuery={(searchText) => ({ search: searchText, type: 'public' })}
        >
          <ChipsInput
            optionText="title"
            optionValue="url"
            translateChoice={false}
            options={{ style: inputStyle, placeholder: 'Enter a tag' }}
          />
        </ReferenceArrayInput>

        <TextInput style={inputStyle} source="title" />
      </SimpleForm>
    </Create>
  )
}

import { Dialog, Tooltip } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import { IconButton } from 'opensolar-ui'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslate } from 'react-admin'

const ImageDialog = (props) => {
  const translate = useTranslate()
  return (
    <div>
      <Dialog open={props.isOpen} onBackdropClick={() => props.closeDialog()}>
        <IconButton
          id="EditUsageClose"
          style={{
            top: 10,
            right: 10,
            position: 'absolute',
            minWidth: 40,
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
          }}
          onClick={() => props.closeDialog()}
        >
          {
            <Tooltip title={translate('Close Dialog')} enterDelay={300}>
              <CloseIcon style={{ color: '#000000' }} />
            </Tooltip>
          }
        </IconButton>

        {props.title && <h1 style={{ marginTop: 0 }}>{props.title}</h1>}
        <img style={{ width: '100%' }} src={props.src} />
      </Dialog>
    </div>
  )
}

ImageDialog.propTypes = {
  isOpen: PropTypes.bool,
  closeDialog: PropTypes.func.isRequired,
  src: PropTypes.string,
  title: PropTypes.string,
}

ImageDialog.defaultProps = {
  isOpen: false,
  title: null,
}

export default ImageDialog

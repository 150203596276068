import { HasPosition, Item, MountingCalcResult, Panel, PanelColumn, PanelRow, RailComponent } from '../types'
import { addItems, createMountingItem } from '../utils'
import { BomGeneratorAbstract } from './BomGeneratorAbstract'

export abstract class PerColumnRailBomGeneratorAbstract extends BomGeneratorAbstract {
  abstract chooseRail(): RailComponent
  abstract chooseMidClamp(): Item // May one day want to support Item[] as well
  abstract chooseEndClamp(): Item // May one day want to support Item[] as well
  abstract chooseEndCap(): Item | null // May one day want to support Item[] as well
  abstract getRailPositions(panel: Panel): VerticalRailPosition[]

  async generateBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    return getContiguousColumns(this.block.rows).reduce(
      (resultFromLastRow, row) => this.generateRailBomForRow(resultFromLastRow, row),
      result
    )
  }

  generateRailBomForRow(resultFromLastRow: MountingCalcResult, column: PanelColumn): MountingCalcResult {
    const resultWithUncutRails = this.addUncutRails(column, resultFromLastRow)
    const resultWithMidClamps = this.addMidClamps(column, resultWithUncutRails)
    const resultWithEndClamps = this.addEndClamps(column, resultWithMidClamps)
    const resultWithEndCaps = this.addEndCaps(column, resultWithEndClamps)
    return resultWithEndCaps
  }

  // We're going to first add rail lengths that may be longer than the rails actually come
  // Then after the whole bom is done, we'll rejig, cutting and splicing as need
  addUncutRails(column: ContiguousPanelColumn, result: MountingCalcResult): MountingCalcResult {
    if (!column.length) return result

    const railComponents: RailComponent[] = []

    const baseItem = this.chooseRail()
    const firstPanel = column[0]
    const lastPanel = column[column.length - 1]
    const rowStartLeft = firstPanel.left
    const rowStartTop = firstPanel.top
    const rowEndTop = lastPanel.top + lastPanel.height // Distance from top at end of column, not top edge of the last panel
    const rowLength = rowEndTop - rowStartTop

    this.getRailPositions(firstPanel).forEach(({ left, top, bottom }) =>
      railComponents.push({
        ...baseItem,
        length: rowLength - top + bottom,
        top: top + rowStartTop,
        left: left + rowStartLeft,
        blockIndex: this.blockIndex,
      })
    )

    return addItems(
      result,
      railComponents.map((railComponent) => ({
        name: railComponent.name,
        components: [railComponent],
      }))
    )
  }

  addMidClamps(column: ContiguousPanelColumn, result: MountingCalcResult): MountingCalcResult {
    if (column.length < 2) return result

    const midClamps: Item[] = []
    const baseClamp = this.chooseMidClamp()
    const panelHeight = this.block.panel.height
    const offset = (column[1].top - (column[0].top + panelHeight)) / 2 // half the gap between panels, to position clamps right in the middle

    for (let index = 1; index < column.length; index++) {
      // Start with the second panel
      const top = column[index].top - offset // Halfway point between the panels
      midClamps.push(
        ...this.getRailPositions(this.block.panel).map(
          // For each rail...
          ({ left }) => createMountingItem(baseClamp, { top, left, blockIndex: this.blockIndex }) // Add the clamp at the right position
        )
      )
    }

    return addItems(result, midClamps)
  }

  addEndClamps(column: ContiguousPanelColumn, result: MountingCalcResult): MountingCalcResult {
    if (!column.length) return result

    const endClamps: Item[] = []
    const baseClamp = this.chooseEndClamp()

    const columnStart = column[0].top
    const columnEnd = getColumnEnd(column)

    this.getRailPositions(column[0]).forEach(({ left }) => {
      endClamps.push(
        createMountingItem(baseClamp, { top: columnStart, left, blockIndex: this.blockIndex }),
        createMountingItem(baseClamp, { top: columnEnd, left, blockIndex: this.blockIndex })
      )
    })

    return addItems(result, endClamps)
  }

  addEndCaps(column: ContiguousPanelColumn, result: MountingCalcResult): MountingCalcResult {
    const baseCap = this.chooseEndCap()
    if (!baseCap) return result

    const columnLeft = column[0].left
    const columnStart = column[0].top
    const columnEnd = getColumnEnd(column)

    const endCaps = this.getRailPositions(column[0]).flatMap(({ left, top, bottom }) => [
      createMountingItem(baseCap, { top: columnStart + top, left: left + columnLeft, blockIndex: this.blockIndex }),
      createMountingItem(baseCap, { top: columnEnd + bottom, left: left + columnLeft, blockIndex: this.blockIndex }),
    ])

    return addItems(result, endCaps)
  }
}

// A PanelColumn may have gaps. Much easier to work with columns without gaps
// An alternative would be to check each y-coord for a panel, and neighbours, and add to the bom accordingly
function getContiguousColumns(rows: PanelRow[]): ContiguousPanelColumn[] {
  const numColumns = rows[0].length

  const contiguousColumns: ContiguousPanelColumn[] = []
  let currentContiguousColumn: ContiguousPanelColumn = []

  for (let columnIndex = 0; columnIndex < numColumns; columnIndex++) {
    for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
      const row = rows[rowIndex]
      const panel = row[columnIndex]
      if (panel.isActive) {
        currentContiguousColumn.push(panel)

        if (!rows[rowIndex + 1] || !rows[rowIndex + 1][columnIndex] || !rows[rowIndex + 1][columnIndex].isActive) {
          // We've found the end of the contiguous column...
          // ...so we push it and start over
          contiguousColumns.push(currentContiguousColumn)
          currentContiguousColumn = []
        }
      }
    }
  }

  return contiguousColumns
}

function getColumnEnd(column: ContiguousPanelColumn) {
  return column[column.length - 1].top + column[column.length - 1].height
}

type ContiguousPanelColumn = PanelColumn // Not enforceable, but contiguous means no gaps

export interface VerticalRailPosition extends HasPosition {
  // left: number (inherited) // mm, how far into the panel from the left the rail is
  // top: number (inhertied) // mm, how far the start of the rail is displaced from the top edge
  bottom: number // mm, how far the rail extends past the end of the column
}

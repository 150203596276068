import { Grid, makeStyles } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import Tooltip from 'elements/tooltip/Tooltip'
import { useSoldSystem } from 'projectSections/hooks/useSoldSystem'
import ProjectWarningBox from 'projectSections/sections/common/ProjectWarningBox'
import React, { useCallback, useMemo } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { ProjectErrorType, SeverityType } from 'reducer/project/projectErrorsReducer'
import { ProjectType } from 'types/projects'
import { doNotTranslate } from 'util/misc'
import LyraIntegrationButton from './LyraIntegrationButton'
import LyraIntegrationStepper from './LyraIntegrationStepper'

type PropTypes = {}

const useStyles = makeStyles((theme: OpenSolarThemeType) => ({
  wrapper: {},
  description: {
    textAlign: 'center',
  },
  warnings: {
    marginBottom: 16,
  },
}))

const getValidationMessage = ({ isSold }) => {
  if (!isSold) {
    return 'Project must be sold before creating a permit pack'
  }
  return
}

const LyraIntegrationPanel: React.FunctionComponent<PropTypes> = (props) => {
  const form = useForm()
  const classes = useStyles()
  const project: Partial<ProjectType> = useFormState().values
  const [highestSeverity, setHighestSeverity] = React.useState<SeverityType | undefined>(undefined)
  const hasUnsavedChanges = form.mutators.getFormDirtyFields()?.length > 0

  const soldSystem = useSoldSystem()

  const automateFlow = () => {
    window.open(
      `${window.LYRA_BASE_URL}/permit_packs/new?open_solar_project_id=${project.id}&open_solar_system_uuid=${soldSystem?.uuid}`,
      '_blank'
    )
  }

  const handleClick = () => {
    logAmplitudeEvent('generic_button_clicked', {
      source: 'project_info',
      context: 'lyra_automate_button',
    })
    if (hasUnsavedChanges) {
      let ret = form.submit()
      ret?.then(() => {
        automateFlow()
      })
    } else automateFlow()
  }

  const isSold = !!soldSystem

  const exportDisabled = !isSold || highestSeverity === 'error'

  const tooltipMessage = useMemo(() => {
    return getValidationMessage({ isSold })
  }, [isSold])

  const soldSystemFilter = useCallback(
    (error: ProjectErrorType) => {
      if (error.systemId === undefined) {
        // If error is not related to a specific system
        return true
      } else if (soldSystem?.uuid) {
        // If error is related to a specific system, only show errors related to the sold system
        return error.systemId === soldSystem.uuid
      }
      return true
    },
    [soldSystem]
  )

  return (
    <div className={classes.wrapper}>
      {isSold && (
        <div className={classes.warnings}>
          <ProjectWarningBox
            sources={['lyra-export']}
            filter={soldSystemFilter}
            setHighestSeverity={setHighestSeverity}
            maxShown={1}
          />
        </div>
      )}

      <LyraIntegrationStepper />
      <Grid container spacing={1} justify="center">
        <Grid item xs={12}>
          <p className={classes.description}>
            {doNotTranslate(
              'Seamlessly transition to generating a permit pack and get a complete bill of materials for this project.'
            )}
          </p>
        </Grid>
        <Grid item xs={12}>
          <Tooltip title={tooltipMessage} arrow placement="top">
            <div>
              <LyraIntegrationButton
                disabled={exportDisabled}
                onClick={handleClick}
                label={doNotTranslate(
                  !exportDisabled && hasUnsavedChanges ? 'Save and Create Permit Pack' : 'Create Permit Pack'
                )}
              />
            </div>
          </Tooltip>
        </Grid>
      </Grid>
    </div>
  )
}
export default LyraIntegrationPanel

import React from 'react'
import { Accordion, Grow, AccordionDetails } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { ProjectType } from 'types/projects'

const useStyles = makeStyles((theme) => ({
  content: {
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  headingTitle: {
    margin: '0 10px',
  },
}))

type PropTypes = {
  renderComponent: (props: any) => JSX.Element
  title: string
  descriptionTop?: string
  descriptionTopLine2?: string
  project: ProjectType
}

const CTAAccordion: React.FunctionComponent<PropTypes> = (props) => {
  const classes = useStyles()
  return (
    <Accordion expanded={true}>
      <Grow in={true}>
        <AccordionDetails>
          <Grid container spacing={3}>
            {props.descriptionTop && (
              <Grid item xs={12} style={{ paddingBottom: '0px' }}>
                <p style={{ textAlign: 'center', marginTop: '20px' }}>{props.descriptionTop}</p>
                {props.descriptionTopLine2 && <p style={{ textAlign: 'center', marginTop: '20px' }}>{props.descriptionTopLine2}</p>}
              </Grid>
            )}
            <Grid item xs={12}>
              {props.renderComponent({ project: props.project })}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Grow>
    </Accordion>
  )
}

export default CTAAccordion

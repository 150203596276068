import { Grid } from '@material-ui/core'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined'
import type { OpenSolarThemeType } from 'Themes'
import { updateOrderQuantity } from 'ducks/orderComponents'
import { orgSelectors } from 'ducks/orgs'
import ValidityChip from 'elements/chip/ValidityChip'
import inflection from 'inflection'
import { IconButton } from 'opensolar-ui'
import { mapForecastStatusToValidity } from 'pages/inventory/forecast/constants'
import type LineItemType from 'pages/ordering/OrderLineItem'
import ProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/projectOrderPresenter'
import RegisterLink from 'pages/ordering/detail/content/RegisterLink'
import { CostPerUnit, TotalCost } from 'pages/ordering/order/table/Cost'
import { OrderTableLocationType } from 'pages/ordering/order/table/OrderTableContent'
import getQuantitySelectorOptions from 'pages/ordering/order/table/getQuantitySelectorOptions'
import LowStockWarning from 'pages/ordering/order/table/quantity/LowStockWarning'
import QuantityField from 'pages/ordering/order/table/quantity/QuantityField'
import useQuantityFieldParts from 'pages/ordering/order/table/quantity/useQuantityFieldParts'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import { useCallback, useMemo, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  itemCell: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'start',
  },
  singleAllocation: {
    paddingTop: '0px',
  },
  multiAllocation: {
    paddingTop: '10px',
  },
  clickableLink: {
    textDecoration: 'underline',
    color: '#1751D0',
    cursor: 'pointer',
  },
  ellipsisItem: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 100,
  },
  gridContainer: {
    borderBottom: '1px solid #e7e7e7',
  },
  gridLineItem: {
    paddingTop: '16px',
    paddingBottom: '16px',
  },
}))

const BOMLineItemRow = ({
  lineItem,
  editable,
  orderingPresenter,
  location,
}: {
  lineItem: LineItemType
  editable: boolean
  orderingPresenter: ProjectOrderPresenter
  location: OrderTableLocationType
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { type, Message, Selector } = useQuantityFieldParts({ lineItem })
  const {
    uuid,
    quantity,
    data,
    componentType,
    status,
    code,
    selectedDistributor,
    pricePerUnit,
    inventoryForecastData,
  } = lineItem
  const componentTypeLabel = componentType || data?.component_type
  const distributorRegistrationRequired = useSelector(orgSelectors.distributorRegistrationRequired)
  const { beforeDiscount, afterDiscount, discount } = lineItem.getBestDiscountOffer()
  const info = `${inventoryForecastData?.allocatedQuantity} / ${inventoryForecastData?.requiredQuantity}`
  const LowStockWarningImpl = useMemo(() => <LowStockWarning lineItem={lineItem} />, [lineItem])
  const [quantityWasUpdated, setQuantityWasUpdated] = useState(false)

  const handleUpdateQuantity = useCallback(
    (quantity) => {
      setQuantityWasUpdated(false)
      dispatch(updateOrderQuantity({ uuid, quantity }))
    },
    [uuid]
  )

  const handleValidity = () => {
    if (inventoryForecastData?.validity === undefined) {
      return 'default'
    }
    return mapForecastStatusToValidity(inventoryForecastData.validity)
  }

  if (location.isBOMTable) {
    return (
      <Grid container item xs={12} className={classes.gridContainer}>
        <Grid container item xs={12} className={classes.gridLineItem}>
          <Grid container item xs={12} alignItems={'center'} spacing={1}>
            <Grid item xs={1}>
              <div className={classes.ellipsisItem} style={{ paddingLeft: 20 }}>
                {componentTypeLabel && inflection.capitalize(componentTypeLabel)}
              </div>
            </Grid>
            <Grid item xs={2}>
              <span>{code}</span>
            </Grid>
            <Grid item xs={2}>
              <ValidityChip label={info} validity={handleValidity()} />
            </Grid>
            <Grid item xs={2}>
              {inflection.capitalize(selectedDistributor)}
            </Grid>
            <Grid item xs={2}>
              {editable ? (
                <>
                  <QuantityField
                    quantity={quantity}
                    Message={!!Message && <Message />}
                    onEditQuantity={setQuantityWasUpdated}
                    Selector={
                      !!Selector && (
                        <Selector
                          options={getQuantitySelectorOptions({
                            lineItem,
                            orderingPresenter,
                            type,
                            trackSource: 'BOM_table',
                          })}
                        />
                      )
                    }
                    onUpdateQuantity={handleUpdateQuantity}
                  />
                  {LowStockWarningImpl}
                </>
              ) : (
                quantity
              )}
              {distributorRegistrationRequired === HardwareSupplierEnum.SolarOutlet && <RegisterLink />}
            </Grid>
            <Grid item xs={2}>
              {status === 'loading' ? (
                <Skeleton />
              ) : (
                <TotalCost beforeDiscount={beforeDiscount} afterDiscount={afterDiscount} />
              )}
            </Grid>
            <Grid item xs={1}>
              {quantityWasUpdated && (
                <IconButton>
                  <DoneOutlinedIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  if (location.isBOMReviewTable) {
    if (quantity === 0) {
      return null
    }

    return (
      <Grid container item xs={12} className={classes.gridContainer}>
        <Grid container item xs={12} className={classes.gridLineItem}>
          <Grid container item xs={12} alignItems={'center'} spacing={1}>
            <Grid item xs={1}>
              <div className={classes.ellipsisItem} style={{ paddingLeft: 20 }}>
                {componentTypeLabel && inflection.capitalize(componentTypeLabel)}
              </div>
            </Grid>
            <Grid item xs={2}>
              <span>{code}</span>
            </Grid>
            <Grid item xs={2}>
              <div className={classes.ellipsisItem}>{data?.manufacturer_name}</div>
            </Grid>
            <Grid item xs={2}>
              {quantity}
            </Grid>
            <Grid item xs={2}>
              {status === 'loading' ? <Skeleton /> : <CostPerUnit pricePerUnit={pricePerUnit} discount={discount} />}
            </Grid>
            <Grid item xs={2}>
              {status === 'loading' ? (
                <Skeleton />
              ) : (
                <TotalCost beforeDiscount={beforeDiscount} afterDiscount={afterDiscount} />
              )}
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={() => dispatch(updateOrderQuantity({ uuid, quantity: 0 }))}>
                <DeleteOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  return <></>
}

export default BOMLineItemRow

import CustomField from 'elements/field/CustomField'
import { Button } from 'opensolar-ui'
import React, { FC } from 'react'
import { SelectInput, TextInput, useTranslate } from 'react-admin'
import DependentInput from '../../elements/input/DependentInput'
import { renderDraftEditor, renderRawHtmlEditor } from '../../util/misc'
import PVSellTemplateTitle from './PVSellTemplateTitle'
import { DraftEditorWithContent, isLockedByOriginalOwner } from './ProposalTemplates'

type PropsType = {
  pvprosell_template_id: string
  api_key_pvsell: string
  disabled: boolean
  record: any
  projectId: number
}
const fields = [
  {
    fieldName: 'pdf_systems_to_include',
    fieldLabel: 'Systems to include in PDF',
    type: 'SelectInput',
    choices: [
      { id: 'selected', name: 'Selected' },
      { id: 'all', name: 'All' },
    ],
  },
  {
    fieldName: 'pdf_payment_options_to_include',
    fieldLabel: 'Payment Options to include in PDF',
    type: 'SelectInput',
    choices: [
      { id: 'selected', name: 'Selected' },
      { id: 'all', name: 'All' },
    ],
  },
]

const PVProSellFormContent: FC<PropsType> = ({
  pvprosell_template_id,
  api_key_pvsell,
  disabled,
  record,
  projectId,
}) => {
  const translate = useTranslate()

  const projectIdQueryParam = projectId ? `&project_id=${projectId}` : ''

  return (
    <div>
      <p>SunWiz Proposal Template:</p>
      <PVSellTemplateTitle pvprosell_template_id={pvprosell_template_id} api_key_pvsell={api_key_pvsell} />
      <Button
        variant="contained"
        disabled={disabled}
        style={{ height: 35, margin: '10px 0 10px 20px', width: 140, verticalAlign: 'middle' }}
        color="primary"
        onClick={() => {
          window.open(
            `${window.SUNWIZ_API_DOMAIN}/standalone/proposal-page/update?id=${pvprosell_template_id}&access-token=${api_key_pvsell}${projectIdQueryParam}`,
            '_blank'
          )
        }}
      >
        <span>{translate('Edit Template')}</span>
      </Button>
      <h2>PDF Content</h2>
      {fields.map((field) => (
        <CustomField
          key={field.fieldName}
          style={{ top: 0, display: 'table', width: 256 }}
          name={field.fieldName}
          source={field.fieldName}
          component={SelectInput}
          //label={translate(labelOrDefault(fieldDetails))}
          choices={field.choices.map((obj) => {
            obj.name = translate(obj.name)
            return obj
          })}
          options={{
            disabled: isLockedByOriginalOwner(record, field.fieldName) || disabled,
          }}
        />
      ))}

      <div>
        <DependentInput dependsOn={'letter_page_content'} resolve={renderDraftEditor}>
          <CustomField
            component={DraftEditorWithContent}
            fieldName={'letter_page_content'}
            allowUploadImage={false}
            name={'letter_page_content'}
            source={'letter_page_content'}
            charCounterMax={2200}
            mentionContext={'org_and_project_without_project_specific_terms'}
            record={record}
            resource={'proposal_templates'}
            disabled={isLockedByOriginalOwner(record, 'letter_page_content') || disabled}
          />
        </DependentInput>

        <DependentInput dependsOn={'letter_page_content'} resolve={renderRawHtmlEditor}>
          <CustomField
            component={TextInput}
            fullWidth={true}
            multiline={true}
            resource={'proposal_templates'}
            style={{
              width: 256,
            }}
            fieldName={'letter_page_content'}
            name={'letter_page_content'}
            source={'letter_page_content'}
          />
        </DependentInput>
      </div>
    </div>
  )
}

export default PVProSellFormContent

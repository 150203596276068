import { withStyles } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { StyledDialog, StyledDialogContent } from 'layout/StyledDialog'
import { Button } from 'opensolar-ui'
import { clearUnsavedData, getUnsavedData } from 'projectSections/utils/unsavedDataStore'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useHistory, useLocation } from 'react-router-dom'
import { ProjectType } from 'types/projects'
import { useFeatureFlag } from 'util/split'

const StyledButton = withStyles({
  root: {
    // color: '#1976d2',
    marginRight: 10,
  },
})(Button)

type PropTypes = {
  autoSavedProjectData: any
}

const UnsavedDataRecoveryPrompt: React.FC<PropTypes> = ({ autoSavedProjectData }) => {
  // used for oauth, sometimes we need to open a window just for a second to finish oauth then we close it right away
  const windowIsTemporary = () => {
    return (
      window?.location?.href?.includes('close_on_resolution=true') ||
      window?.location?.href?.includes('go_to_role=true')
    )
  }

  const [isOpen, setOpen] = useState(!windowIsTemporary())
  const history = useHistory()
  const location = useLocation()
  const translate = useTranslate()
  const featureEnabled = !!useFeatureFlag('unsaved_data_recovery', 'on')
  const handleDiscard = useCallback(() => {
    setOpen(false)
    logAmplitudeEvent('unsaved_data_discarded')
    clearUnsavedData()
  }, [])
  const handleRecover = useCallback(() => {
    const { pathname, unsavedData } = autoSavedProjectData
    setOpen(false)
    clearUnsavedData()
    logAmplitudeEvent('unsaved_data_recovered')
    if (location.pathname === pathname) {
      history.replace({ pathname, state: unsavedData })
    } else {
      history.push({ pathname, state: unsavedData })
    }
  }, [])
  const { address } = autoSavedProjectData
  return (
    <StyledDialog id="project-form-recovery-prompt" open={isOpen && featureEnabled}>
      <StyledDialogContent>
        <div>
          <div>
            <h2 style={{ fontSize: 20, fontWeight: 700 }}>{translate('Recover Unsaved Changes')}</h2>
            <p>
              {translate('We have noticed unsaved changes in your device for the project - ')}
              {address}
            </p>
            <div>
              <div style={{ textAlign: 'right' }}>
                <StyledButton variant="contained" color="default" onClick={handleDiscard}>
                  <span>{translate('Discard')}</span>
                </StyledButton>
                <Button variant="contained" color="primary" onClick={handleRecover}>
                  <span>{translate('Recover')}</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </StyledDialogContent>
    </StyledDialog>
  )
}

const UnsavedDataRecoveryPromptWrapper = () => {
  const [autoSavedProjectData, setAutoSavedData] = useState<Partial<ProjectType> | undefined>()
  useEffect(() => {
    const unsavedData = getUnsavedData()
    if (unsavedData) {
      setAutoSavedData(unsavedData)
    }
  }, [])

  if (!autoSavedProjectData) {
    return null
  }
  return <UnsavedDataRecoveryPrompt autoSavedProjectData={autoSavedProjectData} />
}

export default UnsavedDataRecoveryPromptWrapper

import { HelpLink } from 'elements/link/HelpLink'
import React from 'react'
import { useTranslate } from 'react-admin'
import { ContractCreate, ContractEdit, ContractList } from './Contracts'

const Subtitle = () => {
  const translate = useTranslate()
  return (
    <span>
      {translate(
        'You can present your contract to the customer for them to review and sign by creating and editing a default contract template, which will be seen by the customer after accepting your proposal. You can also create additional templates that can be applied on a per project basis in the Online Proposal page in the Projects zone.'
      )}
      <HelpLink href="https://support.opensolar.com/hc/en-us/articles/4407231231385" />
    </span>
  )
}

const ContractTitle = ({ record, translate, style }) => {
  return (
    <span style={style}>
      {translate('Contract')} {record ? `"${record.title}"` : ''}
    </span>
  )
}

export default {
  list: ContractList,
  edit: ContractEdit,
  create: ContractCreate,
  options: {
    create: {
      title: 'Create Contract',
      subtitle: null,
      breadCrumb: 'Create Contract',
    },
    list: {
      title: 'My Contracts List',
      subtitle: <Subtitle />,
      breadCrumb: 'Contracts',
    },
    edit: {
      title: <ContractTitle />,
      subtitle: null,
      breadCrumb: 'Edit Contract',
    },
  },
}

import { PROJECT_ERROR_PROMPT_ADD, PROJECT_ERROR_PROMPT_CLEAR, PROJECT_ERROR_PROMPT_REMOVE } from 'actions/project'
import remove from 'lodash/remove'
import { Reducer } from 'redux'
import { RootState } from 'types/state'

export const PROMPT_SUNGAGE_BATTERY_INPUT_TYPE = 1
export const PROMPT_PHOENIX_ROLE_TYPE = 2
export const PROMPT_BRIGHTE_ROLE_ERROR_TYPE = 3
export const PROMPT_SWITCH_PROJECT_TYPE = 8
export const LIGHTEACH_PRICING_BAND_TYPE = 9
export const LIGHTREACH_EQUIPMENT_TYPE = 10

export type ErrorPromptIdType =
  | typeof PROMPT_SUNGAGE_BATTERY_INPUT_TYPE
  | typeof PROMPT_PHOENIX_ROLE_TYPE
  | typeof PROMPT_BRIGHTE_ROLE_ERROR_TYPE
  | typeof PROMPT_SWITCH_PROJECT_TYPE
  | typeof LIGHTEACH_PRICING_BAND_TYPE
  | typeof LIGHTREACH_EQUIPMENT_TYPE

export type ErrorPromptType = {
  message: string
  promptId: ErrorPromptIdType
  systemId?: string
  integration: string | undefined
  paymentOptionId: number | undefined
  pmtId: number | undefined
}

const isExistingError = (payload: ErrorPromptType, previousState: ErrorPromptType[]) => {
  const { promptId, systemId, message } = payload || {}
  return !!previousState.find(
    (error: ErrorPromptType) => error.promptId === promptId && error.systemId === systemId && error.message === message
  )
}

export const removeViewedErrorPrompt = (promptId: ErrorPromptIdType, systemId: string) => {
  return {
    type: PROJECT_ERROR_PROMPT_REMOVE,
    payload: { promptId, systemId },
  }
}

export const projectErrorPromptsReducer: Reducer<ErrorPromptType[]> = (previousState = [], { type, payload }) => {
  const { promptId, systemId } = payload || {}
  if (type === PROJECT_ERROR_PROMPT_ADD) {
    if (isExistingError(payload, previousState)) {
      return previousState
    } else {
      const newState = [...previousState]
      //add/update error
      remove(newState, (error: ErrorPromptType) => error.promptId === promptId && error.systemId === systemId)
      return [...newState, payload]
    }
  } else if (type === PROJECT_ERROR_PROMPT_REMOVE) {
    const isExistingError = previousState.find((error) => error.promptId === promptId && error.systemId === systemId)

    if (isExistingError) {
      const newState = [...previousState]?.filter((error) => {
        // lightreach has related errors that are normally condensed into one user journey. If both are present and the
        // dialog is dismissed then we should clear both
        if (promptId && [LIGHTEACH_PRICING_BAND_TYPE, LIGHTREACH_EQUIPMENT_TYPE].includes(promptId)) {
          return (
            ![LIGHTEACH_PRICING_BAND_TYPE, LIGHTREACH_EQUIPMENT_TYPE].includes(error.promptId) ||
            error.systemId !== systemId
          )
        } else {
          return error.promptId !== promptId || error.systemId !== systemId
        }
      })
      return newState
    } else {
      return previousState
    }
  } else if (type === PROJECT_ERROR_PROMPT_CLEAR) {
    const newState = []
    return newState
  } else {
    return previousState
  }
}

export const projectErrorPromptsSelector = {
  getNextPrompt: (state: RootState): ErrorPromptType | undefined => {
    return state.project?.errorPrompts?.length ? state.project.errorPrompts[0] : undefined
  },
  getAllErrorPrompts: (state: RootState): ErrorPromptType[] => {
    return state.project?.errorPrompts || []
  },
}

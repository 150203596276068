import { DropdownInput } from 'types/mounting'
import { BomGeneratorAbstract } from '../bom_generators/BomGeneratorAbstract'
import {
  PerColumnRailBomGeneratorAbstract,
  VerticalRailPosition,
} from '../bom_generators/PerColumnRailBomGeneratorAbstract'
import {
  HorizontalRailPosition,
  PerRowRailBomGeneratorAbstract,
} from '../bom_generators/PerRowRailBomGeneratorAbstract'
import { ComptibilityRules, findDropdownInput } from '../inputFilters'
import {
  BasicSpacingLayoutAdjusterAbstract,
  SpacingRuleSet,
} from '../layout_adjusters/BasicSpacingLayoutAdjusterAbstract'
import { MountingSystemAbstract } from '../MountingSystemAbstract'
import { CutAndSplicerAbstract } from '../post_processors/CutAndSplicerAbstract'
import { RoofHookAdderAbstract } from '../post_processors/RoofHookAdderAbstract'
import { getRailDirection } from '../railing'
import {
  CompatibilityParameters,
  Component,
  Direction,
  Fastener,
  Item,
  MountingCalcInput,
  MountingCalcResult,
  MountingComponentType,
  Panel,
  PanelBlock,
  ProductCode,
  RailComponent,
  RailType,
  RoofTypeName,
} from '../types'
import { getQuantityOfProducts } from '../utils'

export const allSchletterInputs: DropdownInput[] = [
  {
    label: 'Mounting Rails',
    variableName: 'mountingRail',
    default: '120001-440',
    options: [
      {
        label: 'Eco05 4.4m 120001-440 (Classic)',
        value: '120001-440',
      },
      {
        label: 'Eco05 4.75m 120001-475 (Classic)',
        value: '120001-475',
      },
      { label: 'Solo 3.3m (Classic) 120005-330', value: '120005-330' },
      { label: 'Solo 4.4m (Classic) 120005-440', value: '120005-440' },
      { label: 'Solo 4.75m (Classic) 120005-0475', value: 'SLT-120005-0475' },
    ],
  },
  {
    label: 'Roof Hooks',
    variableName: 'roofHook',
    default: '100020-100',
    options: [
      { label: 'EcoA 45 Max Roof Hook', value: '100020-100' },
      { label: 'Rapid2+ Pro 45 Roof Hook', value: '101001-020' },
      { label: 'Rapid2+ Pro 35 Roof Hook', value: '101002-020' },
      { label: 'Rapid2+ Pro Universal Roof Hook', value: '101004-020' },
      { label: 'RapidA Pro 45 Roof Hook', value: '101020-020' },
      { label: 'RapidA Pro 45 Max Roof Hook', value: '101020-120' },
      { label: 'RapidA 45 Pro Universal Max Roof Hook', value: '101024-120' },
      { label: 'Universal Roof Hook', value: '100001-000' },
      { label: 'Roof Hook for Rapid Pro Aluminium beaver tail', value: '109019-020' },
      { label: 'Rapid2+ Pro Slate125 Roof Hook', value: '109019-024' },
    ],
  },
  {
    label: 'Rail Splices',
    variableName: 'railSplice',
    default: '129001-003',
    options: [
      { label: 'Eco connector', value: '129001-003' },
      { label: 'Solo, SoloPlus, FixZ-7, FixZ-15 connector', value: '129002-002' },
      { label: 'Solo slide-in connector', value: '129060-000-KIT' },
    ],
  },
  {
    label: 'Mid Clamp',
    variableName: 'midClamp',
    default: '131121-001',
    options: [
      { label: 'Center clamp Rapid16 30-40', value: '131121-001' },
      { label: 'Center clamp Rapid16 40-50', value: '131121-002' },
      { label: 'Center clamp Rapid16 30-40 black', value: '131121-901' },
      { label: 'Center clamp Rapid16 40-50 black', value: '131121-902' },
    ],
  },
  {
    label: 'End Clamps For Vertical Rails',
    variableName: 'verticalEndClamp',
    default: '131101-002',
    options: [
      { label: 'End clamp Rapid16 40-50', value: '131101-002' },
      { label: 'End clamp Rapid16 40-50 black', value: '131101-902' },
      { label: 'End clamp Rapid16 30-40', value: '131101-001' },
      { label: 'End clamp Rapid16 30-40 black', value: '131101-901' },
    ],
  },
  {
    label: 'End Clamps For Horizontal Rails',
    variableName: 'horizontalEndClamp',
    default: '131101-002',
    options: [
      { label: 'End clamp Rapid16 40-50', value: '131101-002' },
      { label: 'End clamp Rapid16 40-50 black', value: '131101-902' },
      { label: 'End clamp Rapid16 30 -  40', value: '131101-003' },
    ],
  },
  {
    label: 'Fasteners',
    variableName: 'fastener',
    default: '943208-100',
    options: [
      { label: '8x100 Flat Head Torx wood screw', value: '943208-100' },
      { label: '8x80 Flat Head Torx wood screw', value: '943208-080' },
      { label: '8x80 Galvanised Torx wood screw', value: '942208-080' },
    ],
  },
]

export let schletterCompatibleInputs: Array<DropdownInput> = allSchletterInputs

export const compatibleRoofTypes: RoofTypeName[] = ['Tile Clay', 'Tile Concrete', 'Tile Slate']

export const compatibilityRules: ComptibilityRules = {
  mountingRail: {
    roofTypeCompatibility: {
      '120001-440': ['Tile Clay', 'Tile Concrete', 'Tile Slate'],
      '120001-475': ['Tile Clay', 'Tile Concrete', 'Tile Slate'],
      '120005-330': ['Tile Clay', 'Tile Concrete', 'Tile Slate'],
      '120005-440': ['Tile Clay', 'Tile Concrete', 'Tile Slate'],
      'SLT-120005-0475': ['Tile Clay', 'Tile Concrete', 'Tile Slate'],
    },
  },
  roofHook: {
    roofTypeCompatibility: {
      '100020-100': ['Tile Clay', 'Tile Concrete', 'Tile Slate'],
      '101001-020': ['Tile Clay', 'Tile Concrete', 'Tile Slate'],
      '101002-020': ['Tile Clay', 'Tile Concrete', 'Tile Slate'],
      '101004-020': ['Tile Clay', 'Tile Concrete', 'Tile Slate'],
      '101020-020': ['Tile Clay', 'Tile Concrete', 'Tile Slate'],
      '101020-120': ['Tile Clay', 'Tile Concrete', 'Tile Slate'],
      '101024-120': ['Tile Clay', 'Tile Concrete', 'Tile Slate'],
      '100001-000': ['Tile Clay', 'Tile Concrete', 'Tile Slate'],
      '109019-020': ['Tile Concrete'],
      '109019-024': ['Tile Slate'],
    },
  },
  railSplice: {
    railCompatibility: {
      '129001-003': ['120001-440', '120001-475'],
      '129002-002': ['120005-330', '120005-440'],
      '129060-000-KIT': ['120005-330', '120005-440'],
    },
  },
  midClamp: {
    railCompatibility: {
      '131121-001': ['120001-440', '120001-475'],
      '131121-002': ['120001-440', '120001-475'],
      '131121-901': ['120005-330', '120005-440'],
      '131121-902': ['120005-330', '120005-440'],
    },
  },
}

const spacingRuleSet: SpacingRuleSet = {
  interPanel: { horizontalSpacing: 20, verticalSpacing: 20 },
}
class SchletterSpacingAdjuster extends BasicSpacingLayoutAdjusterAbstract {
  spacingRuleSet = spacingRuleSet
}

const railOverhang = 100 // Schletter only cite that this should not exceed 500

// roofHook : tile
const schletterTilesPerHookRule = {
  '109019-020': '109016-009',
  '109019-024': '109017-010',
}

const getSpeccedExtraItems = (extraProdRule, prodCode, itemsInBom): Item[] => {
  const prodCountInBom = itemsInBom.items.filter((item) => item.name === prodCode).length
  const speccedProdToAdd = {
    name: extraProdRule[prodCode],
    components: [],
  }
  const speccedProdsAsItems = Array.from({ length: prodCountInBom }, () => speccedProdToAdd)
  return speccedProdsAsItems
}

const getResultWithTiles = (
  hooksDropDownInput: DropdownInput,
  resultWithoutTiles: MountingCalcResult,
  specRule
): MountingCalcResult => {
  const hookOptions: string[] = hooksDropDownInput['options'].map((option) => option.value as string)
  const prodsInBoom: string[] = resultWithoutTiles.items.map((prod) => prod.name)
  const hookTypesAdded = hookOptions.filter((value) => prodsInBoom.includes(value))
  const TilesToAdd = hookTypesAdded.map((hookcode) => {
    if (specRule.hasOwnProperty(hookcode)) {
      return getSpeccedExtraItems(specRule, hookcode, resultWithoutTiles)
    }
    return []
  })
  const flattenArray = (arr) => [].concat(...arr)
  const flattenedTiles = flattenArray(TilesToAdd)
  const allItems = resultWithoutTiles.items.concat(flattenedTiles)
  const resultWithTiles: MountingCalcResult = { ...resultWithoutTiles, items: allItems }
  return resultWithTiles
}

class SchletterBomGenerator extends BomGeneratorAbstract {
  async generateBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    if (!this.input.options.mountingRail) return result // prevent BOM generation when no specified inputs
    const starterBom = await this.generateStarterBom(result)
    return this.postProcess(starterBom)
  }

  generateStarterBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    if (getRailDirection(this.block.orientation, this.input.options) === 'horizontal') {
      return this.generateBomFrom(SchletterHorizontalRailBomGenerator, result)
    } else {
      return this.generateBomFrom(SchletterVerticalRailBomGenerator, result)
    }
  }

  postProcess(result: MountingCalcResult): MountingCalcResult {
    const resultWithRoofHooks = new SchletterRoofHookAdder(this.input).process(result)
    const resultWithRailSplicing = new SchletterRailSplicer(this.input).process(resultWithRoofHooks)
    const hooksDropdownInput = findDropdownInput(allSchletterInputs, 'roofHook')
    const resultWithTiles = getResultWithTiles(hooksDropdownInput, resultWithRailSplicing, schletterTilesPerHookRule)

    return resultWithTiles
  }
}

export class Schletter extends MountingSystemAbstract {
  layoutAdjusterClass = SchletterSpacingAdjuster
  bomGeneratorClass = SchletterBomGenerator
  getFastener(): Fastener {
    const railDirection = getRailDirection(this.input.panelBlocks[0].moduleGrid.moduleLayout(), this.input.options)
    return chooseFastener(chooseHook(this.input, null, chooseRail(this.input, railDirection)), this.input)
  }

  getCompatibilityParameters(): CompatibilityParameters {
    return {
      integrations: ['Segen'],
      roofTypes: compatibleRoofTypes,
      roofTypeRequired: true,
    }
  }
}
class SchletterHorizontalRailBomGenerator extends PerRowRailBomGeneratorAbstract {
  chooseRail() {
    return chooseRail(this.input, 'horizontal')
  }
  chooseMidClamp() {
    return chooseMidClamp(this.input)
  }
  chooseEndClamp() {
    return chooseEndClamp(this.input, 'horizontal')
  }
  chooseEndCap() {
    return chooseEndCap()
  }
  getRailPositions(panel: Panel): HorizontalRailPosition[] {
    const distanceFromRailToPanelEdge = (panel.height * 0.6) / 2
    return [
      {
        top: distanceFromRailToPanelEdge,
        left: -railOverhang,
        right: railOverhang,
      },
      {
        top: panel.height - distanceFromRailToPanelEdge,
        left: -railOverhang,
        right: railOverhang,
      },
    ]
  }
}
class SchletterVerticalRailBomGenerator extends PerColumnRailBomGeneratorAbstract {
  chooseRail() {
    return chooseRail(this.input, 'vertical')
  }

  chooseMidClamp() {
    return chooseMidClamp(this.input)
  }

  chooseEndClamp() {
    return chooseEndClamp(this.input, 'vertical')
  }

  chooseEndCap() {
    return chooseEndCap()
  }

  getRailPositions(panel: Panel): VerticalRailPosition[] {
    const distanceFromRailToPanelEdge = (panel.width * 0.6) / 2
    return [
      {
        left: distanceFromRailToPanelEdge,
        top: -railOverhang,
        bottom: railOverhang,
      },
      {
        left: panel.width - distanceFromRailToPanelEdge,
        top: -railOverhang,
        bottom: railOverhang,
      },
    ]
  }
}

function chooseRail(input: MountingCalcInput, direction: Direction): RailComponent {
  const componentCode = input.options.mountingRail as string
  return {
    type: MountingComponentType.RAIL,
    railType: 'continuous',
    direction,
    name: componentCode,
    length: 0,
    top: 0,
    left: 0,
  }
}

function chooseMidClamp(input: MountingCalcInput): Item {
  const componentCode = input.options.midClamp as string
  return {
    name: componentCode,
    components: [
      {
        name: componentCode,
        type: MountingComponentType.CLAMP,
        left: -10,
        top: -10,
      },
    ],
  }
}

function chooseEndClamp(input: MountingCalcInput, direction: Direction): Item {
  const componentCode = (direction === 'horizontal'
    ? input.options.horizontalEndClamp
    : input.options.verticalEndClamp) as string
  return {
    name: componentCode,
    components: [
      {
        name: componentCode,
        type: MountingComponentType.CLAMP,
        left: -10,
        top: -10,
      },
    ],
  }
}

function chooseEndCap(): Item | null {
  return null
}

function chooseHook(input: MountingCalcInput, panelBlock: PanelBlock | null, rail: RailComponent) {
  const componentCode = input.options.roofHook as string
  return [
    {
      name: componentCode,
      components: [
        {
          name: componentCode,
          type: MountingComponentType.ROOF_HOOK,
          left: -10,
          top: -10,
        },
      ],
    },
  ]
}

export type QuantityPerProduct = Record<string, Array<ProductCode>>

const schletterFastenersNeededPerHook: QuantityPerProduct = {
  '1': ['100020-100', '101020-020', '101020-120', '101024-120'],
  '2': ['101001-020', '101002-020', '101004-020', '100001-000', '109019-020', '109019-024'],
}

function chooseFastener(hook: Item[], input: MountingCalcInput): Fastener {
  const hookName = hook[0].name
  const fastenersQty = getQuantityOfProducts(schletterFastenersNeededPerHook, hookName) as number

  const componentCode = input.options.fastener as string
  return {
    includedInRoofHookProduct: false,
    name: componentCode,
    qtyPerRoofHook: fastenersQty,
    length: componentCode === '943208-100' ? 100 : 80,
    diameter: 8,
    components: [],
  }
}

class SchletterRoofHookAdder extends RoofHookAdderAbstract {
  spacingRuleSet = spacingRuleSet
  railOverhang = railOverhang
  getRailType(input: MountingCalcInput): RailType {
    return 'continuous'
  }
  chooseHook(input: MountingCalcInput, panelBlock: PanelBlock, rail: RailComponent) {
    return chooseHook(input, panelBlock, rail)
  }
  chooseFastener(hook: Item[], input: MountingCalcInput) {
    return chooseFastener(hook, input)
  }
}
class SchletterRailSplicer extends CutAndSplicerAbstract {
  isTarget(component: Component): boolean {
    return component?.type === MountingComponentType.RAIL
  }
  chooseSplice(railName: string, input: MountingCalcInput): Item[] {
    const spliceCode = input.options.railSplice as string
    return [
      {
        name: spliceCode,
        components: [
          {
            type: MountingComponentType.SPLICE,
            name: spliceCode,
            top: 0,
            left: 0,
          },
        ],
      },
    ]
  }

  getFullLength(componentCode: string): number {
    if (componentCode === '120005-330') return 3300
    if (componentCode === '120001-440' || componentCode === '120005-440') return 4400
    if (componentCode === '120001-475') return 4750
    if (componentCode === 'SLT-120005-0475') return 4750
    return 0
  }
}

import useEnabledHardwareSuppliers from '../hooks/useEnabledHardwareSuppliers'
import { getDistributorsOptions } from '../../../elements/hardwareFilter/distributor/GlobalDistributorFilterFactory'
import { useFeatureFlag } from '../../../util/split'
import { useSelector } from 'react-redux'
import { orgSelectors } from '../../../ducks/orgs'
import { HardwareSupplierFilterKeyType } from '../type'

export const useMultiDistributors = (): Set<HardwareSupplierFilterKeyType> => {
  const enableMultiDistributorShop = useFeatureFlag('enable_multi_distributor_shop', 'on')

  const enableDistributors = useEnabledHardwareSuppliers()
  const isEmptyEnableDistributors = enableDistributors?.length  === 0

  const selectedDistributor = useSelector(orgSelectors.getSelectedHardwareSupplierFilterKey)
  if (!enableMultiDistributorShop && isEmptyEnableDistributors) {
    return new Set()
  }

  if (!enableMultiDistributorShop && !isEmptyEnableDistributors) {
    return new Set([selectedDistributor as HardwareSupplierFilterKeyType])
  }

  if (enableMultiDistributorShop) {
    return new Set()
  }

  return new Set()
}

import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import FinanceTypeChip from './FinanceTypeChip'

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: '15px',
  },
}))

type PropTypes = {
  supports_commercial: boolean
  supports_residential: boolean
}

const FinanceTypeChipsRow: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      {props.supports_commercial && <FinanceTypeChip type="commercial" />}
      {props.supports_residential && <FinanceTypeChip type="residential" />}
    </div>
  )
}
export default FinanceTypeChipsRow

// @ts-nocheck
import { Card, makeStyles, useMediaQuery } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import { Button } from 'opensolar-ui'
import React, { useState } from 'react'
import { useLocale, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { getContactUsLinkByLocale, getHelpCenterByLocale, getWebinarLinkByCountry } from 'util/misc'
import AppStoreImg from '../../icons/AppStore.svg'
import Info from '../../icons/Info.svg'
import Message from '../../icons/Message.svg'
import Play from '../../icons/Play.svg'
import PlayStoreImg from '../../icons/PlayStore.svg'
import Webinar from '../../icons/Webinar.svg'
import useTranslateParse from '../../util/useTranslateParse'
import OrientationVideoDialog from './OrientationVideoDialog'

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    '& #supportCard': {
      marginTop: 10,
    },
    '& #goMobileCard': {
      marginTop: 10,
      padding: '20px 0px',
    },
  },

  card: {
    padding: '20px 15px',
    margin: 5,
    textAlign: 'center',
    width: '45%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    Width: 400,
    left: 0,
    top: 0,
    background: '#F8F8F8',
    border: theme.border.default,
    boxSizing: 'border-box',
    borderRadius: 3,
    flex: 'none',
    order: 0,
    flexGrow: 1,
    [theme.breakpoints.down('lg')]: {
      flex: '1 1 auto',
      width: '99%',
      // width: '100%',
    },
  },

  h1Title: {
    marginLeft: 5,
  },

  pTitle: {
    marginLeft: 5,
  },

  buttonsWrapper: {
    // display: 'inline-block',
    display: 'flex',
    flexWrap: 'wrap',
    // flexDirection: 'row',
    width: '100%',
    margin: '0 auto',
    flex: 'none',
    order: 0,
    alignSelf: 'stretch',
  },

  button: {
    boxShadow: 'none',
    textTransform: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 10px 10px',
    width: 175,
    height: 100,
    left: 0,
    top: 0,
    background: '#FFFFFF',
    border: theme.border.default,
    borderRadius: 2,
    flex: 'none',
    order: 0,
    alignSelf: 'stretch',
    flexGrow: 1,
    margin: '10px 5px',
    marginBottom: 0,

    '&:hover': {
      boxShadow: 'none',
      background: '#e0e0e0',
      border: '2px solid #e0e0e0',
    },
    [theme.breakpoints.down('lg')]: {
      flex: '1 1 auto',
      width: '46%',
    },
  },

  label: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '0.875rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      lineHeight: '12px',
    },
  },
  startIcon: {
    margin: 0,
    marginBottom: 10,
    width: 'unset',
    height: 'unset',
  },
  contained: {
    background: 'rgb(255,255,255)',
  },

  devicesImg: {
    width: '100%',
    height: 159,
    margin: '0px -15px',
    objectFit: 'contain',
  },

  downloadButtonsWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down('lg')]: {
      flex: '1 1 auto',
    },
  },

  downloadButton: {
    margin: 5,
    marginBottom: 0,
    boxShadow: 'none',
    border: theme.border.default,
    textTransform: 'none',
    boxSizing: 'border-box',

    background: '#fff',
    width: 175,
    height: 50,
    padding: '5px 10px',
    lineHeight: 14,
    fontSize: 12,
    flex: 'none',
    order: 0,
    alignSelf: 'stretch',
    flexGrow: 1,
    '&:hover': {
      boxShadow: 'none',
      background: '#e0e0e0',
      border: '2px solid #e0e0e0',
    },
  },
}))

const SupportCard = () => {
  const [isVideoDialogOpen, setVideoDialogVisibility] = useState(false)
  const isMobile = useMediaQuery((theme) => (theme as any).breakpoints.down('xs'))
  const classes = useStyles()
  const translate = useTranslate()
  const translateParse = useTranslateParse()
  const locale = useLocale()
  const countryIso2 = useSelector(orgSelectors.getOrgIso2)

  return (
    <Card id="supportCard" variant="outlined" classes={{ root: classes.card }}>
      <h3 style={{ marginTop: 0 }}>{translate('Need support?')}</h3>
      <p>
        {translateParse(
          "We're here to help, watch our video content,<b><b>access our help resources or get in touch.",
          { b: () => (isMobile ? <span style={{ marginRight: 5 }} /> : <br />) }
        )}
      </p>
      <div className={classes.buttonsWrapper}>
        <Button
          startIcon={<img src={Play} alt="Play" />}
          classes={{
            root: classes.button,
            label: classes.label,
            contained: classes.contained,
            startIcon: classes.startIcon,
          }}
          variant="contained"
          onClick={() => setVideoDialogVisibility(true)}
        >
          <span>{translate('Orientation Video')}</span>
        </Button>
        <Button
          startIcon={<img src={Webinar} alt="webinar" />}
          classes={{
            root: classes.button,
            label: classes.label,
            contained: classes.contained,
            startIcon: classes.startIcon,
          }}
          variant="contained"
          onClick={() => window.open(getWebinarLinkByCountry(countryIso2), '_blank')}
        >
          <span>{translate('Webinars')}</span>
        </Button>
        <Button
          startIcon={<img src={Info} alt="Info" />}
          classes={{
            root: classes.button,
            label: classes.label,
            contained: classes.contained,
            startIcon: classes.startIcon,
          }}
          variant="contained"
          onClick={() => window.open(getHelpCenterByLocale(locale), '_blank')}
        >
          <span>{translate('Help Centre')}</span>
        </Button>
        <Button
          startIcon={<img src={Message} alt="Message" />}
          classes={{
            root: classes.button,
            label: classes.label,
            contained: classes.contained,
            startIcon: classes.startIcon,
          }}
          variant="contained"
          onClick={() => {
            window.open(getContactUsLinkByLocale(locale), '_blank')
          }}
        >
          <span>{translate('Contact Us')}</span>
        </Button>
      </div>
      <OrientationVideoDialog isOpen={isVideoDialogOpen} closeDialog={() => setVideoDialogVisibility(false)} />
    </Card>
  )
}

const GoMobileCard = () => {
  const classes = useStyles()
  const translate = useTranslate()
  const translateParse = useTranslateParse()
  return (
    <Card id="goMobileCard" variant="outlined" classes={{ root: classes.card }}>
      <h3 style={{ marginTop: 0 }}>{translate('Go Mobile?')}</h3>
      <p>
        {translateParse('Make sure you have the latest <b><b>OpenSolar apps for tablet and mobile.', {
          b: () => <br />,
        })}
      </p>
      <div>
        <div>
          <img src="images/Shadow-min.png" alt="devicesImg" className={classes.devicesImg} />
        </div>
        <div className={classes.downloadButtonsWrapper}>
          <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.opensolar.app">
            <img src={PlayStoreImg} alt="google play" className={classes.downloadButton} />
          </a>
          <a target="_blank" rel="noreferrer" href="https://apps.apple.com/us/app/opensolar/id1410085935">
            <img src={AppStoreImg} alt="app store" className={classes.downloadButton} />
          </a>
        </div>
      </div>
    </Card>
  )
}

const DashboardWelcomeBlock = () => {
  const translate = useTranslate()
  const classes = useStyles()
  return (
    <div>
      <h1 className={classes.h1Title}>{translate('Welcome To Your Dashboard')}</h1>
      <p className={classes.pTitle}>
        {translate(
          "See all your projects and the stage they're at, track your business results, spot trends & keep your team on track."
        )}
      </p>
      <div className={classes.cardWrapper}>
        <SupportCard />
        {window.RUNNING_AS_APP !== true && <GoMobileCard />}
      </div>
    </div>
  )
}

export default DashboardWelcomeBlock

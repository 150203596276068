import { Grid, Typography } from '@material-ui/core'
import { memo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import HardwareStandardBannerContainer from '../HardwareStandardBannerContainer'
import OrderByProjectButton from '../button/OrderByProjectButton'

const useStyles = makeOpenSolarStyles((theme) => ({
  container: {
    padding: 20,
  },
  img: {
    width: 160,
    height: 80,
  },
  text: {
    lineHeight: '20px',
    fontWeight: 'normal',
  },
}))

const OrderByProjectBanner = () => {
  const classes = useStyles()

  return (
    <HardwareStandardBannerContainer>
      <Grid container className={classes.container} wrap={'wrap'} spacing={3}>
        <Grid item xs={12} sm={2} container alignItems="center" justify="center">
          <img className={classes.img} alt="supplier-logo" src={`${window.PUBLIC_URL}/images/component_hardware.png`} />
        </Grid>
        <Grid item xs={12} sm={7}>
          <Typography variant="h6" align="center" gutterBottom>
            Order hardware by project
          </Typography>
          <Typography variant="body1" align="center" className={classes.text}>
            The easiest way to get the hardware you need to complete your jobs.
          </Typography>
        </Grid>

        <Grid item xs={12} sm={3} container alignItems="center" justify="center">
          <OrderByProjectButton />
        </Grid>
      </Grid>
    </HardwareStandardBannerContainer>
  )
}

export default memo(OrderByProjectBanner)

import { ChipProps } from '@material-ui/core'
import { Chip as OsChip } from 'opensolar-ui'
import React, { memo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  skeleton: {
    minWidth: 50,
  },
  chip: ({ size }: { size: ChipProps['size'] }) => ({
    fontSize: size === 'small' ? theme.typography.subtitle2.fontSize : theme.typography.body1.fontSize,
  }),
  chipRoot: { display: 'flex', height: '100%' },
  chipLabel: { overflowWrap: 'break-word', whiteSpace: 'normal', textOverflow: 'clip', padding: '3px 0' },
}))
export type OsChipVariantType = 'default' | 'warning' | 'info' | 'secondary'
export type OsChipPropsType = Omit<ChipProps, 'variant' | 'color'> & { loading?: boolean; variant?: OsChipVariantType }

const DEFAULT_VARIANT = 'default'

const VARIANT_CONFIG: { [key in OsChipVariantType]: ChipProps } = {
  default: {
    color: 'default',
    variant: 'outlined',
  },
  warning: {
    color: 'secondary',
    variant: 'outlined',
  },
  info: {
    color: 'default',
    variant: 'default',
  },
  secondary: {
    color: undefined,
    variant: undefined,
  },
}

const Chip = ({ loading, label, variant = DEFAULT_VARIANT, size = 'small', ...rest }: OsChipPropsType) => {
  const classes = useStyles({ size })
  const variantConfigs = VARIANT_CONFIG[variant]
  // TODO: refine the loading styling (waiting for design)

  return (
    <OsChip
      size={size}
      className={classes.chip}
      classes={{ root: classes.chipRoot, label: classes.chipLabel }}
      label={loading ? <Skeleton className={classes.skeleton} /> : label}
      {...variantConfigs}
      {...rest}
    />
  )
}

export default memo(Chip)

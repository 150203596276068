import { setMfaConfig } from 'ducks/auth_mfa_actions'
import { NotificationType, Translate } from 'ra-core'
import { Dispatch } from 'react'
import restClient from 'restClient'
import { MFAConfigType } from 'types/mfa'

import { countryMapping } from 'constants/countries'
import { CountryMapType } from './types'

export const getDefaultCountryCode = (iso2: string): string | undefined => {
  if (!iso2) return undefined
  const matched = Object.values(countryMapping).find((country: CountryMapType) => country.iso2 === iso2)
  if (matched) return `+${matched.prefix}`
  else return undefined
}

export const getIso2FromCountryCode = (code: string | undefined): string | undefined => {
  if (!code) return undefined
  const matched = Object.values(countryMapping).find((country: CountryMapType) => `+${country.prefix}` === code)
  return matched?.iso2
}

export const getCountryCodeOptions = () => {
  return Object.values(countryMapping)
    .sort((a, b) => a.name.localeCompare(b.name))
    .filter((country) => country.prefix !== null && country.prefix !== 'NONE')
    .map((country: CountryMapType) => {
      return { value: `+${country.prefix}`, iso2: country.iso2, display: `${country.name} (${country.prefix})` }
    })
}

export function fetchMfaConfig(
  dispatch: Dispatch<any>,
  translate: Translate,
  notify: Notify,
  setLoading?: (value: boolean) => void,
  logoutIfAccessDenied?: (err: any, doMfa: boolean) => void
): Promise<any> {
  if (setLoading) setLoading(true)
  const restClientInstance = restClient(window.API_ROOT + '/api')
  const ret = restClientInstance('CUSTOM_GET', 'custom', {
    url: 'mfa/',
  })
    .then((res: { data: MFAConfigType }) => {
      dispatch(setMfaConfig(res.data))
    })
    .catch((err: any) => {
      console.warn(err)
      notify('Failed to get 2FA configuration', 'warning')
      if (logoutIfAccessDenied) logoutIfAccessDenied(err, false)
    })
    .finally(() => {
      if (setLoading) setLoading(false)
    })
  return ret
}

type Notify = (msg: string, type: NotificationType) => void

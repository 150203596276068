export default function reducer(previousState = null, { type, payload }) {
  if (type === '@@router/LOCATION_CHANGE') {
    //Remember any location.pathname which does not specify an id and is in the list of watched resources
    var parts = payload.location.pathname.split('/')

    var numberOfSlashes = parts.length - 1
    var resource = parts[1]
    var resourcesToMatch = ['projects', 'contacts', 'private_files']

    if (payload.location.pathname && numberOfSlashes === 1 && resourcesToMatch.indexOf(resource) !== -1) {
      return payload.search ? payload.location.pathname + payload.search : payload.location.pathname
    }
  }
  return previousState
}

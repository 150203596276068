import { orgSelectors } from 'ducks/orgs'
import { isEqual } from 'lodash'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFeatureFlag } from 'util/split'
import type { FactoryHookType } from '../type'
import maybeCreateDistributorFilterNode from './DistributorFilterNodeFactory'

const useDistributorFilterNode: FactoryHookType = ({ RendererComponent, options }) => {
  const enabledDistributors = useSelector(orgSelectors.getEnabledHardwareSuppliers)
  const [memorizedDistributors, setMemorizedDistributors] = useState(enabledDistributors)
  const selectedDistributor = useSelector(orgSelectors.getSelectedHardwareSupplier)
  // TODO remove this when HDM goes on general release. It's a hack to deal with some
  // bad back-end data.
  const enableHdm = useFeatureFlag('hdm', 'on')

  useEffect(() => {
    if (!isEqual(enabledDistributors, memorizedDistributors)) {
      setMemorizedDistributors(enabledDistributors)
    }
  }, [memorizedDistributors, enabledDistributors])

  return useMemo(() => {
    return maybeCreateDistributorFilterNode({
      selectedDistributor,
      enabledDistributors: memorizedDistributors.filter((supplier) => {
        if (supplier === HardwareSupplierEnum.Hdm) {
          return enableHdm
        }
        return true
      }), // memorizedDistributors is used here avoid re-mounting the component
      RendererComponent,
    })
  }, [selectedDistributor, memorizedDistributors, RendererComponent])
}

export default useDistributorFilterNode

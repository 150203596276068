import { orgSelectors } from 'ducks/orgs'
import { Button, DialogActions, DialogContent } from 'opensolar-ui'
import { PaymentRequestType } from 'pages/cashFlow/types'
import { useSystem } from 'projectSections/sections/payments/hooks'
import { useNotify, useTranslate } from 'ra-core'
import React, { useEffect, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { LightReachAddersType } from 'types/global'
import { parseIntegrationJson } from 'util/misc'
import LightReachConfirmEquipment from './LightReachConfirmEquipment'
import LightReachPricing from './LightReachPricing'
import LightReachStepper from './LightReachStepper'
import { saveLicenseNumber, useGetLightReachVendors, useValidateLightReachEquipment } from './utils'

type PropTypes = {
  onClose: () => void
  paymentRequests?: PaymentRequestType[]
  deletePaymentOption?: () => void
  systemUuid: string | null
  paymentOptionId: number
  isPendingSave: boolean
  promptId?: 9 | 10 | undefined
}

const useStyles = makeOpenSolarStyles((theme) => ({
  titleWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.greyLight1,
  },
  closeButton: {
    color: theme.greyMid1,
  },
  buttonsRow: {
    display: 'flex',
    padding: '16px 24px',
    justifyContent: 'end',
    gap: '10px',
    alignItems: 'center',
    width: '100%',
  },
  buttonsLeftContainer: {
    flex: 1,
    display: 'flex',
    jutifyContent: 'flex-start',
  },
  buttonsRightContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'end',
    gap: '10px',
    alignItems: 'center',
    width: '100%',
  },
  field: {
    margin: '5px 0',
  },
  label: {
    marginTop: 0,
  },
  icon: {
    color: theme.greyMid1,
    fontSize: 20,
    margin: '10px 0px 10px 6px',
    cursor: 'pointer',
  },
  taxIdWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerContainer: {
    marginBottom: '1.5rem',
  },
}))

const LightReachMoreInfo: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const form = useForm()

  const system = useSystem(props.systemUuid)
  const project = useFormState().values
  const orgId = useSelector(orgSelectors.getOrg)?.id
  const newIntegrationJSON = parseIntegrationJson(system?.integration_json) || {}
  let overrides = system?.payment_options_settings_overrides || {}

  const [selectedPricing, setSelectedPricing] = useState<string>(overrides[props.paymentOptionId]?.sub_product_id || '')
  const [initialAddersValue, setInitialAddersValue] = useState<LightReachAddersType | undefined>(
    system?.integration_json?.lightreach?.adders
  )
  const [currentPage, setCurrentPage] = useState<'equipment' | 'pricing'>('equipment')
  const [completed, setCompleted] = useState({})

  const [licenseNumber, setLicenseNumber] = useState<string | undefined | null>(
    system?.integration_json?.lightreach?.salesperson_license_number
  )

  const { vendors, vendorsAreBeingFetched } = useGetLightReachVendors(props.systemUuid)
  const validationErrors = useValidateLightReachEquipment(props.systemUuid, vendors, licenseNumber)

  useEffect(() => {
    if (!licenseNumber && vendors && !vendorsAreBeingFetched) {
      setLicenseNumber(vendors?.salesperson_license_number)
    }
  }, [vendors, vendorsAreBeingFetched, licenseNumber])

  useEffect(() => {
    if (props.promptId === 9) {
      setCurrentPage('pricing')
    }
  }, [props.promptId])

  const onNext = () => {
    if (validationErrors.length === 0) {
      if (licenseNumber) {
        saveLicenseNumber(licenseNumber, project, orgId)
          .then((res) => {})
          .catch((err) => {
            console.error('Failed to save license number:', err)
          })
      }

      window.editor.execute(new window.SetValueCommand(system, 'integration_json', newIntegrationJSON))
      form.mutators.markFieldAsDirty('design')
      form.change('design', 'has unsaved change')

      setCurrentPage('pricing')
      setCompleted({ 0: true })
    }
  }

  const notify = useNotify()
  const validateChanges = () => {
    if (!selectedPricing) {
      notify('Please select a pricing option or click Cancel', 'warning')
      return false
    }
    if (
      system?.integration_json?.lightreach?.adders?.backupBatteryIncluded &&
      !system?.integration_json?.lightreach?.adders?.backupBatteryKwhIncluded &&
      !system?.integration_json?.lightreach?.adders?.backupBatteryCost
    ) {
      notify(
        'Backup battery cost is required if you are using a backup battery. Please either add a cost or remove the battery adder',
        'warning'
      )
      return false
    }
    return true
  }

  const cancelChanges = () => {
    // roll back any adder changes they maded but didn't save
    if (system?.integration_json?.lightreach?.adders !== initialAddersValue) {
      window.editor.execute(
        new window.SetValueCommand(system, 'integration_json', {
          ...system?.integration_json,
          lightreach: { adders: initialAddersValue },
        })
      )
    }
    props?.onClose()
  }

  const onSave = () => {
    if (system && validateChanges()) {
      if (overrides[props.paymentOptionId]) {
        overrides[props.paymentOptionId].sub_product_id = selectedPricing
      } else {
        overrides[props.paymentOptionId] = {
          down_payment: undefined,
          price: undefined,
          sub_product_id: selectedPricing,
        }
      }
      // clear hashed args to ensure pricing is actually run and we don't get a cached response
      system.clearHashedArgs()
      window.editor.execute(new window.SetValueCommand(system, 'payment_options_settings_overrides', overrides))
      form.mutators.markFieldAsDirty('design')
      form.change('design', 'has unsaved change')
      if (props.onClose) props.onClose()
    }
  }

  if (!props.systemUuid) return null
  return (
    <>
      <DialogContent>
        <div>
          <LightReachStepper currentPage={currentPage} completed={completed} />
          {currentPage === 'pricing' ? (
            <LightReachPricing
              systemUuid={props.systemUuid}
              paymentOptionId={props.paymentOptionId}
              selectedPricing={selectedPricing}
              setSelectedPricing={setSelectedPricing}
            />
          ) : null}

          {currentPage === 'equipment' ? (
            <LightReachConfirmEquipment
              systemUuid={props.systemUuid}
              licenseNumber={licenseNumber}
              setLicenseNumber={setLicenseNumber}
              vendors={vendors}
              vendorsAreBeingFetched={vendorsAreBeingFetched}
            />
          ) : null}
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttonsRow}>
          <div className={classes.buttonsLeftContainer}>
            {currentPage === 'pricing' && (
              <div>
                <Button onClick={() => setCurrentPage('equipment')} color="primary" variant="outlined">
                  {translate('Back')}
                </Button>
              </div>
            )}
          </div>
          <div className={classes.buttonsRightContainer}>
            <div>
              <Button onClick={cancelChanges} color="primary" variant="outlined">
                {translate('Cancel')}
              </Button>
            </div>
            {currentPage === 'equipment' ? (
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onNext}
                  disabled={Boolean(validationErrors?.length)}
                >
                  {translate('Next')}
                </Button>
              </div>
            ) : (
              <div>
                <Button color="primary" variant="contained" onClick={onSave}>
                  {translate('Save')}
                </Button>
              </div>
            )}
          </div>
        </div>
      </DialogActions>
    </>
  )
}
export default LightReachMoreInfo

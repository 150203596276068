// @ts-nocheck
import { makeStyles } from '@material-ui/core'
import Chartist from 'chartist'
import { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import ChartistGraph from 'react-chartist'
import Skeleton from 'react-loading-skeleton'
import { monthNames } from 'util/misc'
import { NotAvailable } from './NotAvailable'
import { ChartSubHeader } from './SubHeader'

const ProjectCreatedColor = 'rgba(62, 201, 50, 1)'
const ProjectSoldColor = 'rgba(247, 148, 29, 1)'
const ProjectInstalled = 'rgba(0, 148, 255, 1)'
const ChartColor = [ProjectCreatedColor, ProjectSoldColor, ProjectInstalled]

const onDraw = function () {
  var projectActivityChart
  return (projectActivityChart = function (chart: any) {
    chart.on('draw', function (data: any) {
      if (data.type === 'label') {
        if (typeof data.text === 'number') {
          //axis-y
          if (data.text < 0) {
            data.element.attr({ style: 'display:none' })
          } else {
            data.element.attr({ x: -7, y: data.y + 5 })
          }
        } else if (typeof data.text === 'string') {
          //axis-x
          data.element.attr({ x: data.x - 10, y: data.y + 10 })
        }
      } else if (data.type === 'point') {
        var tooltip = new Chartist.Svg(
          'foreignObject',
          {
            x: data.x,
            y: data.y,
          },
          'ct-tooltip'
        ).text(data.series[data.index])

        if (data.index === data.series.length - 1) {
          let circle = new Chartist.Svg(
            'circle',
            {
              cx: [data.x],
              cy: [data.y],
              r: [2],
              stroke: ChartColor[data.seriesIndex],
              fill: ChartColor[data.seriesIndex],
            },
            'ct-circle'
          )

          data.element.replace(circle)
          data.group.append(tooltip)
        } else {
          let circle = new Chartist.Svg(
            'circle',
            {
              cx: [data.x],
              cy: [data.y],
              r: [3],
              stroke: ChartColor[data.seriesIndex],
              'stroke-width': '2',
              fill: 'none',
            },
            'ct-circle'
          )
          var background = new Chartist.Svg(
            'circle',
            {
              cx: [data.x],
              cy: [data.y],
              r: [3],
              stroke: 'rgb(255,255,255)',
              'stroke-width': '6',
              fill: 'none',
            },
            'ct-circle'
          )

          data.element.replace(background)
          data.group.append(circle)
          data.group.append(tooltip)
        }
      } else if (data.type === 'grid') {
        //stretch x,y axis
        if (data.y1 === data.y2) {
          data.element.attr({ x2: data.x2 + 20 })
        } else {
          data.element.attr({ y1: 0 })
        }
      } else if (data.type === 'line') {
        //have to use 'important' here, otherwise it will be override by designer.css
        //To do: clean unnecessary css hack for chart
        const lastLine = data.path?.pathElements[data.path?.pathElements?.length - 1]
        const previousLine = data.path?.pathElements[data.path?.pathElements?.length - 2]
        data.element.attr({ style: `stroke: ${ChartColor[data.seriesIndex]} !important` })

        if (lastLine && previousLine) {
          data.group.append(
            new Chartist.Svg('path', {
              d: `M${previousLine.x},${previousLine.y} C${lastLine.x1},${lastLine.y1} ${lastLine.x2},${lastLine.y2} ${lastLine.x},${lastLine.y}`,
              class: 'ct-dashed-line',
            })
          )
        }
      }
    })
  })
}

const useStyles = makeStyles(() => ({
  legends: {
    marginBottom: 10,
    padding: '10px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  legend: {
    verticalAlign: 'middle',
    fontSize: 12,
    marginLeft: 5,
  },
  circle: {
    borderRadius: '50%',
    border: '2px solid',
    width: 8,
    height: 8,
    marginRight: 2,
    boxSizing: 'border-box',
    verticalAlign: 'middle',
    display: 'inline-block',
  },
  chart: {
    height: '200px',
    '& .ct-vertical ~ .ct-vertical': {
      //grid
      display: 'none',
    },
    '& .ct-horizontal ~ .ct-horizontal': {
      //grid
      display: 'none',
    },
    '& .ct-dashed-line': {
      strokeDasharray: 2,
      stroke: 'rgb(255,255,255)',
      strokeWidth: 2,
      fill: 'none',
    },
    '& .ct-tooltip': {
      opacity: 0,
      width: 20,
      textAlign: 'center',
      height: 20,
      overflow: 'visible',
      transform: 'translate(-10px, -10px)',
      cursor: 'default',
    },
    '& .ct-tooltip:hover': {
      opacity: 1,
      height: 20,
      transform: 'translate(-10px, -25px)',
      backgroundColor: 'rgba(0,0,0,0.1)',
      lineHeight: '145%',
      borderRadius: 5,
    },
    '& .ct-label': {
      color: '#000000',
    },
    '& .ct-line': {
      strokeWidth: 2,
    },
    '& .ct-chart-line': {
      overflow: 'visible',
    },
    '& .ct-grids': {
      transform: 'translate(-10px, 10px)',
    },
  },
}))

const ProjectChart = ({ data }: any) => {
  const [projectActivityChartData, setChartData] = useState(null)
  const classes = useStyles()
  const translate = useTranslate()
  useEffect(() => {
    const currentMonth = new Date().getMonth()
    const labels = [...monthNames, ...monthNames].slice(currentMonth - 5 + 12, currentMonth + 12 + 1)
    const chartData = {
      labels,
      series: [data.created, data.sold, data.installed],
      options: {
        low: [data.created],
        high: [data.created],
        plugins: [onDraw()],
        fullWidth: true,
        chartPadding: {
          left: -5,
        },
      },
    }
    setChartData(chartData)
  }, [data])

  return (
    <div style={{ margin: '10px 16px' }}>
      {projectActivityChartData ? (
        <ChartistGraph
          className={classes.chart}
          data={projectActivityChartData}
          options={projectActivityChartData.options}
          type={'Line'}
        />
      ) : (
        <div style={{ margin: 20 }}>
          <Skeleton height={'200px'} />
        </div>
      )}
      <div className={classes.legends}>
        <span>
          <span className={classes.circle} style={{ borderColor: ProjectCreatedColor }} />
          <span className={classes.legend}>{translate('Projects Created')}</span>
        </span>
        <span>
          <span className={classes.circle} style={{ borderColor: ProjectSoldColor }} />
          <span className={classes.legend}>{translate('Projects Sold')}</span>
        </span>
        <span>
          <span className={classes.circle} style={{ borderColor: ProjectInstalled }} />
          <span className={classes.legend}>{translate('Projects Installed')}</span>
        </span>
      </div>
    </div>
  )
}

const ProjectActivityChart = ({ data }: any) => {
  const isAvailable = (Object.keys(data).length = 0)
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <ChartSubHeader />
      {isAvailable ? <NotAvailable /> : <ProjectChart data={data} />}
    </div>
  )
}

export default ProjectActivityChart

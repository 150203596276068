import { SwapHoriz } from '@material-ui/icons'
import { authSelectors } from 'ducks/auth'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useFeatureFlag } from 'util/split'

type PropTypes = {}

const SwapPaymentOptionsButton: React.FC<PropTypes> = (props) => {
  const history = useHistory()
  const isAdmin: boolean = useSelector(authSelectors.getIsAdmin)
  const isStaff: boolean = useSelector(authSelectors.getIsStaff)
  const org = useSelector(authSelectors.getCurrentOrg)
  const isUsFincoEnabled =
    org?.enable_loanpal ||
    org?.enable_sungage ||
    org?.enable_sunlight ||
    org?.enable_mosaic ||
    org?.enable_brighte ||
    org?.enable_plenti ||
    org?.enable_energy_ease
  const featureFlagEnabled = useFeatureFlag('pmt_migration', 'on')

  const onClick = () => {
    history.push('/payment_options/swap')
  }

  if (!featureFlagEnabled) return null
  if (!isUsFincoEnabled) return null
  if (!isAdmin && !isStaff) return null
  return (
    <ProUXButton type="secondary" label="Bulk Update Projects (beta)" startIcon={<SwapHoriz />} onClick={onClick} />
  )
}
export default SwapPaymentOptionsButton

import {
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  TablePagination,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import {
  clearPaymentOptionOverrideIds,
  initializeDialog,
  paymentOptionSelectionSelectors,
  setPendingPaymentOptions,
} from 'ducks/paymentOptionSelection'
import Button from 'elements/button/Button'
import { Checkbox } from 'opensolar-ui'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Sort, useNotify, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import PromoDialog from 'resources/paymentOptions/financialIntegrations/genericPromoContent/PromoDialog'
import restClient from 'restClient'
import appStorage from 'storage/appStorage'
import { StudioSystemType } from 'types/global'
import { AvailableFinanceIntegrationType } from 'types/orgs'
import { PaymentOptionDataType } from 'types/paymentOptions'
import { ProjectType } from 'types/projects'
import { Theme } from 'types/themes'
import { convertPmtDataToCalculatedPaymentOption } from '../utils'
import IntegrationFilterButtons from './IntegrationFilterButtons'
import PaymentOptionDialogTitle from './PaymentOptionDialogTitle'
import PaymentOptionSearch from './PaymentOptionSearch'
import PaymentOptionSelectionTable from './PaymentOptionSelectionTable'
import SelectedPaymentOptionChips from './SelectedPaymentOptionChips'
import TeamsOrgFilter from './TeamsOrgFilter'
import { CalculatedPaymentOption, PAYMENT_OPTION_MESSAGE_TYPE_ENUM } from './types'

const restClientInstance = restClient(window.API_ROOT + '/api')
const GEO_FILTER_KEY = 'pmt_filter_ineligible_state'

const useStyles = makeStyles<Theme, { isFullFUJITour: boolean; isMobile: boolean }>((theme) => ({
  dialogPaper: {
    minHeight: ({ isFullFUJITour }) => (isFullFUJITour ? '80vh' : '90vh'),
  },
  dialogContent: { padding: '0px' },
  toolbarWrapper: {
    padding: '0px 5px',
  },
  buttonsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  integrationButtonsWrapper: {
    flex: 7,
  },
  clearFilterWrapper: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer',
    paddingRight: '30px',
    paddingBottom: '20px',
    color: 'rgb(167, 167, 167)',
  },
  actionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '0px',
    paddingBottom: '5px',
    width: '100%',
  },
  actionsRow: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  geoFilterWrapper: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  actionButtonsWrapper: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  buttonWrapper: {
    margin: '0px 15px 15px 15px',
    display: 'flex',
    justifyContent: 'center',
  },
}))

const DEFAULT_PAGE_SIZE = 10

type PropTypes = {
  isOpen: boolean
  onClose: () => void
  system: StudioSystemType
}

const PaymentOptionDialog: React.FC<PropTypes> = (props) => {
  const org = useSelector(orgSelectors.getOrg)

  const getInitialQueryOrgId = () => {
    // in old systems, the calculated payment_options array may not have an org_id. When this is the case, revert to the id of the currently logged in org
    return props.system?.payment_options?.[0]?.org_id || org?.id
  }

  const [targetIntegrations, setTargetIntegrations] = useState<string[]>([])
  const [search, setSearch] = useState<undefined | string>(undefined)
  const [sort, setSort] = useState<Sort | undefined>(undefined)
  const [page, setPage] = useState<number>(0)
  const [totalOptionsAvailable, setTotalOptionsAvailable] = useState<number>(0)
  const [integrationIntakeToShow, setIntegrationIntakeToShow] = useState<AvailableFinanceIntegrationType | undefined>(
    undefined
  )
  const [filterIneligibleStates, setFilterIneligibleStates] = useState<boolean>(!!appStorage.getBool(GEO_FILTER_KEY))

  const [pricedPaymentOptions, setPricedPaymentOptions] = useState<CalculatedPaymentOption[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [queryOrgId, setQueryOrgId] = useState<number | undefined>(getInitialQueryOrgId())

  const translate = useTranslate()
  const isFullFUJITour = window.location.href?.includes('?tour=fuji')
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const classes = useStyles({ isFullFUJITour, isMobile })
  const dispatch = useDispatch()
  const formState = useFormState()
  const form = useForm()
  const project = formState?.values as ProjectType
  const notify = useNotify()

  const roleId = useSelector(authSelectors.getCurrentRole)?.id
  const paymentOptionOverrideIds = useSelector(paymentOptionSelectionSelectors.getPaymentOptionOverrideIds)
  const dialogIsInitialized = useSelector(paymentOptionSelectionSelectors.getIsDialogInitialized)
  const allSelectedPaymentOptions = useSelector(paymentOptionSelectionSelectors.getSelectedPaymentOptions)
  const availablePaymentOptions = useSelector(paymentOptionSelectionSelectors.getAvailablePaymentOptions)

  useEffect(() => {
    fetchRows()
  }, [page])

  useEffect(() => {
    if (page === 0) {
      fetchRows()
    } else {
      setPage(0)
    }
  }, [sort, targetIntegrations, search, filterIneligibleStates, queryOrgId])

  useEffect(() => {
    let currentPaymentOptionsOnSystem: CalculatedPaymentOption[] = []
    props.system.payment_options?.forEach((pmt) => {
      let pricedPaymentOption = convertPmtDataToCalculatedPaymentOption(pmt)
      if (pricedPaymentOption) currentPaymentOptionsOnSystem.push(pricedPaymentOption)
    })
    if (currentPaymentOptionsOnSystem?.length && !dialogIsInitialized) {
      dispatch(initializeDialog(currentPaymentOptionsOnSystem))
    }
  }, [dialogIsInitialized, props.system.payment_options, props.system.payment_options_override])

  useEffect(() => {
    // progress tour once dialog opens
    if (window.location.href?.includes('&step=open_payment_option_dialog')) {
      window.location.href = window.location.href.replace(
        '&step=open_payment_option_dialog',
        '&step=payment_option_dialog'
      )
    }
  }, [])

  const fetchRows = useCallback(() => {
    const sortString = !!sort ? `${sort.order === 'DESC' ? '-' : ''}${sort?.field}` : undefined
    setIsLoading(true)
    const selectedSystem = window.editor.getSystems().find((sys) => sys.uuid === props.system.uuid)
    if (!selectedSystem) {
      notify('Unable to find the selected system. Please save any changes and refresh this page', 'warning')
      return
    }
    let requestStart = new Date()
    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${queryOrgId}/calculated_payment_options/`,
      data: {
        query: {
          fieldset: 'list',
          limit: DEFAULT_PAGE_SIZE,
          ordering: sortString,
          page,
          search,
          integration: targetIntegrations,
          range: `[${page * DEFAULT_PAGE_SIZE}, ${(page + 1) * DEFAULT_PAGE_SIZE}]`,
          request_role_id: roleId,
          request_org_id: org?.id,
          filter_ineligible_states: filterIneligibleStates,
        },
        project: {
          system_price: selectedSystem.pricing?.system_price_including_tax || 0,
          country_iso2: project?.country_iso2 || "",
          state: project.state,
          zip_code: project?.zip,
          system_design: JSON.stringify(selectedSystem),
          project_id: project?.id,
          utility_tariff_id: selectedSystem?.bills?.proposed
            ? Object.values(selectedSystem?.bills?.proposed)?.[0]?.utility_tariff_id
            : null,
        },
      },
    })
      .then((res) => {
        if (res?.data?.calculated_payment_options) {
          setPricedPaymentOptions(res.data.calculated_payment_options)
          setTotalOptionsAvailable(res.data.total_options_available)
        }
        const requestEnd = new Date()
        let secondsToOpen = 'error'
        try {
          // @ts-ignore
          secondsToOpen = `${((requestEnd - requestStart) / 1000).toFixed(0)}`
        } catch (ex) {}
        logAmplitudeEvent('payment_option_explorer_query_submitted', {
          page,
          search,
          ordering: sortString,
          integration: `${targetIntegrations}`,
          filterIneligibleStates,
          timeToResolve: secondsToOpen,
        })
      })
      .catch((err) => {
        logAmplitudeEvent('payments_page_explorer_error', {
          project_id: project.id,
        })
        // if we hit some sort of exception that prevents us from returning any payment options but we knw this org has valid payment options then show a simple
        // list populated by saved payment options on the front-end. To be clear, this is something we never want to show. But I think it's important that if
        // we do have some sort of bug in the payment options explorer we still give pros a way to add payment options
        if (availablePaymentOptions?.length) {
          let matchingResults: PaymentOptionDataType[] = []
          if (search) {
            matchingResults = availablePaymentOptions?.filter((pmt) => {
              return pmt.title?.toLowerCase().includes(search.toLowerCase())
            })
          } else {
            matchingResults = [...availablePaymentOptions]
          }
          matchingResults = matchingResults.splice(page * DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE)

          let asCalculatedPmtsWithError: CalculatedPaymentOption[] = []
          matchingResults.forEach((pmt) => {
            asCalculatedPmtsWithError.push({
              payment_frequency: pmt.payment_frequency,
              repayment: undefined,
              system_price: undefined,
              title: pmt.title,
              payment_option_id: pmt.id,
              integration: pmt.integration,
              term_years: null,
              term_periods: pmt.term,
              interest_rate: null,
              interest_rate_disclaimer: null,
              is_popular: false,
              payment_type: pmt.payment_type,
              messages: [
                {
                  type: PAYMENT_OPTION_MESSAGE_TYPE_ENUM.error,
                  message: translate('We were unable to price this payment option'),
                  error_prompt_id: null,
                  payment_option_id: pmt.id,
                  system_uuid: props.system.uuid,
                },
              ],
            })
          })
          setPricedPaymentOptions(asCalculatedPmtsWithError)
          setTotalOptionsAvailable(availablePaymentOptions.length)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [
    page,
    sort,
    targetIntegrations,
    search,
    filterIneligibleStates,
    queryOrgId,
    availablePaymentOptions,
    pricedPaymentOptions,
  ])

  const handlePageChange = (e, newPage) => {
    // don't fire another query while one is still loading. Each query can result in callouts to lenders and we need to protect against spamming
    if (isLoading) {
    } else {
      setPage(newPage)
    }
  }

  const onClose = () => {
    progressTour()
    dispatch(clearPaymentOptionOverrideIds())
    props.onClose()
  }

  const progressTour = () => {
    if (window.location.href.includes('&step=payment_option_dialog')) {
      window.location.href = window.location.href.replace('&step=payment_option_dialog', '&step=system_configuration')
    }
  }

  const onSave = useCallback(() => {
    progressTour()
    // update the system and initiate calcs
    const system = window.editor.objectByUuid(props.system.uuid)
    let overrideIdsFilterToCurrentOrg: number[] = []
    paymentOptionOverrideIds.forEach((pmtId) => {
      let fullPmt = availablePaymentOptions.find((pmt) => pmt.id === pmtId)
      let shouldAdd = false
      // if we can't find a match it is likely because the payment option is archived and was put on the system before it was archived
      if (!fullPmt) shouldAdd = true
      else {
        if (fullPmt.org_id === queryOrgId) shouldAdd = true
      }

      if (shouldAdd && !overrideIdsFilterToCurrentOrg.includes(pmtId)) {
        overrideIdsFilterToCurrentOrg.push(pmtId)
      }
    })
    window.editor.execute(new window.SetValueCommand(system, 'payment_options_override', overrideIdsFilterToCurrentOrg))
    form.mutators.markFieldAsDirty('design')
    form.change('design', 'has unsaved change')
    props.onClose()
    dispatch(clearPaymentOptionOverrideIds())

    // while calcs are running we need to show that payment options were edited. So we show pending cards by saving the pending payment options in redux
    dispatch(setPendingPaymentOptions(allSelectedPaymentOptions, props.system.uuid))
  }, [availablePaymentOptions, queryOrgId, paymentOptionOverrideIds, allSelectedPaymentOptions])

  const onInactiveClick = (integration: AvailableFinanceIntegrationType) => {
    setIntegrationIntakeToShow(integration)
  }

  const closeIntakeDialog = () => {
    setIntegrationIntakeToShow(undefined)
  }

  const clearFilters = () => {
    setSort(undefined)
    setPage(0)
    setSearch(undefined)
    setTargetIntegrations([])
  }

  const toggleFilterByState = () => {
    appStorage.setBool(GEO_FILTER_KEY, true)
    setFilterIneligibleStates(!filterIneligibleStates)
  }

  const onTeamsOrgChange = (newId: number) => {
    setQueryOrgId(newId)
  }

  const showClearFilters = useMemo(() => {
    return !!sort || !!page || !!search || !!targetIntegrations?.length
  }, [sort, page, search, targetIntegrations])

  if (integrationIntakeToShow) {
    return <PromoDialog integration={integrationIntakeToShow} onClose={closeIntakeDialog} isOpen={true} />
  } else {
    return (
      <Dialog
        open={props.isOpen}
        onClose={onClose}
        maxWidth="lg"
        classes={{ paper: classes.dialogPaper }}
        fullScreen={isMobile}
        fullWidth={isMobile}
      >
        <PaymentOptionDialogTitle onClose={onClose} system={props.system} />
        <DialogContent className={classes.dialogContent}>
          <div id="payment-option-selection-dialog-content">
            <div className={classes.toolbarWrapper}>
              <PaymentOptionSearch setSearch={setSearch} />
              <div className={classes.buttonsRow}>
                <div className={classes.integrationButtonsWrapper}>
                  <IntegrationFilterButtons
                    setTargetIntegrations={setTargetIntegrations}
                    targetIntegrations={targetIntegrations}
                    onInactiveClick={onInactiveClick}
                    hideChecks={true}
                  />
                </div>
                {showClearFilters && (
                  <div className={classes.clearFilterWrapper} onClick={clearFilters}>
                    <Close />
                    <Typography>{translate('Clear filters')}</Typography>
                  </div>
                )}
              </div>
            </div>
            {queryOrgId && <TeamsOrgFilter currentOrgId={queryOrgId} onOrgIdChange={onTeamsOrgChange} />}
            <SelectedPaymentOptionChips queryOrgId={queryOrgId} />
            <PaymentOptionSelectionTable
              rows={pricedPaymentOptions}
              sort={sort}
              setSort={setSort}
              isLoading={isLoading}
              system={props.system}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div className={classes.actionsWrapper}>
            <TablePagination
              rowsPerPageOptions={[DEFAULT_PAGE_SIZE]}
              page={page}
              rowsPerPage={DEFAULT_PAGE_SIZE}
              count={totalOptionsAvailable}
              onChangePage={handlePageChange}
              component="div"
            />
            <div className={classes.actionsRow}>
              <div className={classes.geoFilterWrapper}>
                {project.country_iso2 === 'US' && (
                  <>
                    <Checkbox
                      id="remove_out_of_state"
                      checked={filterIneligibleStates}
                      onChange={toggleFilterByState}
                    />
                    <span>{translate('Remove products that are not eligible for this location')}</span>
                  </>
                )}
              </div>
              <div className={classes.actionButtonsWrapper}>
                <div className={classes.buttonWrapper}>
                  <Button
                    id="dismiss_payment_options_dialogue"
                    color="default"
                    variant="contained"
                    onClick={onClose}
                    label="Dismiss"
                  />
                </div>
                <div className={classes.buttonWrapper}>
                  <Button id="save_payment_options" color="primary" variant="contained" onClick={onSave} label="Save" />
                </div>
              </div>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    )
  }
}
export default PaymentOptionDialog

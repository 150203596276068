import PremiumProductsList from './PremiumProductsList'

export default {
  list: PremiumProductsList,
  // edit: PremiumProductsList,
  //   create: WalletTransactionCreate,
  options: {
    // create: {
    //   title: 'Create Wallet Transaction',
    //   subtitle: null,
    //   breadCrumb: 'Create Wallet Transaction',
    // },
    list: {
      title: 'Connect',
      breadCrumb: 'Connect',
    },
    // edit: {
    //   title: 'Test wallet transaction title',
    //   subtitle: null,
    //   breadCrumb: 'Edit Wallet Transaction',
    // },
  },
}

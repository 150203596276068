//@ts-nocheck
import { Dialog, FormControlLabel, makeStyles } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { updateRoleIronridgeEnabled } from 'ducks/auth_roles'
import { closeIronRidgeDialog } from 'ducks/ironRidge'
import Alert from 'elements/Alert'
import GenericButton from 'elements/button/GenericButton.js'
import CustomField from 'elements/field/CustomField'
import LoadingDots from 'layout/widgets/LoadingDots'
import { Checkbox } from 'opensolar-ui'
import { useEffect, useState } from 'react'
import { TextInput, useTranslate } from 'react-admin'
import { Form } from 'react-final-form'
import { connect, useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'
import { RootState } from 'types/state'
import { getRoleFromState, parseIntegrationJson, systemNameOrDefault } from 'util/misc'
import {
  IronRidgeAccountRequestType,
  IronRidgeAccountResponseType,
  IronRidgeProjectDetailsType,
  IronRidgeProjectRequestType,
  IronRidgeRailType,
  IronRidgeRoofSectionType,
} from '../../../types/integrations/ironRidge'
import IntegrationAPIForm from '../IntegrationAPIForm'
import { queryPanels, refreshFromIronRidge } from './actions'
import { API_URL, WARNING_TO_SEVERITY_MAP } from './constants'
import { ironRidgeAdvancedFields, ironRidgeFields } from './formFields'
import IronRidgeBom from './IronRidgeBom'
import IronRidgePermissionsWarning from './IronRidgePermissionsWarning'

const MULTIPLE_SLOPE_THRESHOLD = 1

type WarningType =
  | 'engineering'
  | 'snowLoad'
  | 'snowSpecial'
  | 'windSpeed'
  | 'windSpecial'
  | 'detail.sDsWarning'
  | 'detail.sDsSpecial'
  | 'detail.elevationWarning'
  | 'detail.elevationSpecial'

declare var editor: editor

export const getDesignString = (
  cellsActive: string[],
  rowStart: number,
  rows: number,
  colStart: number,
  cols: number
) => {
  if (!cellsActive || rows === undefined || cols === undefined) return
  //first build up the matrix with only 'O'
  let matrix = [] as any
  for (let colNum = colStart; colNum <= cols; colNum++) {
    let temp = [] as any
    for (let rowNum = rowStart; rowNum <= rows; rowNum++) {
      temp.push('0')
    }
    matrix.push(temp)
  }
  //then iterate over cellsActive and drop in a '1' for each active cell
  cellsActive.forEach((active) => {
    //adjust for situations where the matrix starts negative in either direction
    let activeRow: number = parseInt(active.substring(0, active.indexOf(',')))
    activeRow = activeRow - rowStart
    let activeCol: number = parseInt(active.substring(active.indexOf(',') + 1))
    activeCol = activeCol - colStart
    matrix[activeCol][activeRow] = '1'
  })
  //return the matrix with '1's and '0's flattened out into a string
  return matrix.join('').replace(/,/g, '')
}

const useStyles = makeStyles({
  modalContentWrapper: {
    padding: '30px 0px',
    textAlign: 'center',
    position: 'relative',
    justifyContent: 'center',
  },
  accountButtonsView: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '15px',
  },
  accountButtonWrapper: {
    margin: '10px',
  },
  accountSuccess: {
    textAlign: 'center',
    backgroundColor: 'rgb(24, 144, 255)',
    opacity: 0.5,
    color: 'white',
    margin: '0px 30px',
    padding: '10px',
    borderRadius: '5px',
  },
  error: {
    color: 'red',
    margin: '20px 0px',
  },
  otherActionsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '10px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px 10px',
    cursor: 'pointer',
  },
  tableWrapper: {
    backgroundColor: 'rgb(239, 239, 239)',
    padding: '5px',
    borderRadius: '5px',
    marginBottom: '10px',
  },
  table: {
    width: '100%',
    marginBottom: '15px',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tableCell: {
    flex: 1,
    textAlign: 'center',
  },
  defaultsTitle: {
    fontWeight: 900,
    textAlign: 'center',
    marginTop: '20px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  defaultsTitleText: {
    marginRight: '20px',
  },
  defaultFieldName: {
    fontWeight: 900,
  },
  expandDefaultsIcon: {},
  minimizeDefaultsIcon: {
    transform: 'rotate(180deg)',
  },
  defaultsDescription: {
    textAlign: 'center',
    margin: '10px 0px',
  },
  primaryButton: {
    backgroundColor: '#ffda00',
    color: 'rgb(77,77,77)',
  },
})

type PropTypes = {
  showDialog: boolean
  onClose: () => void
  roleId: number
  ironridgeEmail?: string
  orgId?: number
}
const IronRidgeDialog = (props: PropTypes) => {
  const getIsExistingIRProject = (): boolean => {
    let ironRidgeProjectConnected = false
    // @ts-ignore
    if (!!window.editor.selectedSystem?.integration_json) {
      // @ts-ignore
      let ironRidgeData = parseIntegrationJson(window.editor.selectedSystem.integration_json)?.ironridge
      if (ironRidgeData && ironRidgeData.project?.id) ironRidgeProjectConnected = true
    }
    return ironRidgeProjectConnected
  }

  const getMaxSlopeDifference = (): number | undefined => {
    let roofSlopes: number[] = []
    editor.selectedSystem.children
      .filter((child) => child.name === 'OsModuleGrid')
      .forEach((panelArray) => {
        // set roof slope if found
        if (panelArray?.userData?.slope !== undefined) {
          roofSlopes.push(panelArray.userData.slope)
        }
      })
    if (roofSlopes && roofSlopes.length > 0) {
      roofSlopes.sort()
      let maxSlopeDiff = Math.abs(roofSlopes[0] - roofSlopes[roofSlopes.length - 1])
      return Math.abs(maxSlopeDiff)
    }
    return undefined
  }

  const [isExistingProject, setIsExistingProject] = useState<boolean>(getIsExistingIRProject())
  const [showAccountButtons, setShowAccountButtons] = useState<boolean>(!isExistingProject && !props.ironridgeEmail)
  const [showForm, setShowForm] = useState<boolean>(!showAccountButtons)
  const [disabledForm, setDisabledForm] = useState<boolean>(false)
  const [showPermissionsWarning, setShowPermissionsWarning] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [refreshing, setRefreshing] = useState<boolean>(false)
  const [hasMultipleSlopes, setHasMultipleSlopes] = useState<boolean>(false)
  const [isTooFlat, setIsTooFlat] = useState<boolean>(false)
  const [isTooSteep, setIsTooSteep] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string | null>(null)
  const [successMessage, setSuccessMessage] = useState<string | null>(null)
  const [accountWarningMessage, setAccountWarningMessage] = useState<string | null>(null)
  const [formError, setFormError] = useState<string | null>(null)
  const [newBOM, setNewBOM] = useState<any | null>(null)
  const [showBOMModal, setShowBOMModal] = useState<boolean>(false)
  const [showStaleDataMessage, setShowStaleDataMessage] = useState<boolean>(false)
  const [panelMatched, setPanelMatched] = useState<boolean>(false)
  const [termsChecked, setTermsChecked] = useState<boolean>(false)
  const [refreshError, setRefreshError] = useState<string | undefined>(undefined)
  const [bomWarnings, setBomWarnings] = useState<string[]>([])
  const [highPriorityBomWarnings, setHighPriorityBomWarnings] = useState<string[]>([])

  const userEmail: string = useSelector((state: any) => {
    return getRoleFromState(state)?.ironridge_email ? getRoleFromState(state)?.ironridge_email : state.auth?.user?.email
  })
  const usersIronRidgeEmail = useSelector(authSelectors.getCurrentRole)?.ironridge_email
  const userAcceptedTerms = useSelector(authSelectors.getCurrentRole)?.ironridge_terms_accepted
  const forceFormOpen = useSelector((state: any) => state.ironRidge.forceShowDialog)

  const dispatch = useDispatch()
  const translate = useTranslate()

  // check arrays to see if there are multiple slopes which are not supported by IR's API
  useEffect(() => {
    const maxSlopeDiff = getMaxSlopeDifference()
    if (maxSlopeDiff !== undefined) {
      setHasMultipleSlopes(maxSlopeDiff > MULTIPLE_SLOPE_THRESHOLD)
    }
  }, [])

  useEffect(() => {
    if (showForm && props.showDialog) {
      setIsExistingProject(getIsExistingIRProject())
      logAmplitudeEvent('ironridge_bom_form_opened', {})
    }
  }, [showForm, props.showDialog])

  useEffect(() => {
    if (showAccountButtons && props.showDialog) {
      logAmplitudeEvent('ironridge_account_form_opened', {})
    }
  }, [showAccountButtons, props.showDialog])

  useEffect(() => {
    if (props.showDialog) {
      const uuid = editor.selectedSystem.uuid
      const system = window.editor.objectByUuid(uuid)
      const moduleId: number = system.moduleType().module_id || system.moduleType().id
      queryPanels(props.orgId as number, moduleId)
        .then((foundOne: boolean) => {
          setPanelMatched(foundOne)
          if (foundOne) logAmplitudeEvent('ironridge_panels_found', { system_uuid: system.uuid, module_id: moduleId })
          else logAmplitudeEvent('ironridge_panels_not_found', { system_uuid: system.uuid, module_id: moduleId })
        })
        .catch(() => setPanelMatched(false))
    }
  }, [props.showDialog])

  //pause event listener when dialog is open, resume them when dialog closes
  useEffect(() => {
    if (props.showDialog || forceFormOpen) editor.pauseDesignEventListener = true
    else editor.pauseDesignEventListener = false
    return () => {
      editor.pauseDesignEventListener = false
    }
  }, [props.showDialog, forceFormOpen])

  //allow a component to update redux in a way that forces the dialog to appear so long as this component is rendered on the dom
  useEffect(() => {
    if (forceFormOpen) {
      if (isExistingProject) {
        setShowForm(true)
        setShowAccountButtons(false)
        setShowStaleDataMessage(true)
      } else {
        setShowForm(false)
        setShowAccountButtons(true)
      }
      setShowBOMModal(false)
      setNewBOM(null)
      setAccountWarningMessage(null)
      setShowPermissionsWarning(false)
      setSuccessMessage(null)
      setErrorMsg(null)
      setFormError(null)
    }
  }, [forceFormOpen])

  // run basic validation to make sure project is in a reasonable state before they waste their time trying to submit
  useEffect(() => {
    let error: string | undefined = undefined
    if (!window.WorkspaceHelper.project.address) {
      error = 'The project address must have a street number'
    } else if (!window.WorkspaceHelper.project.locality) {
      error = 'The project address must have a city'
    } else if (!window.WorkspaceHelper.project.state) {
      error = 'The project address must have a state'
    } else if (!window.WorkspaceHelper.project.country_iso2) {
      error = 'The project address must have a country'
    } else if (!window.WorkspaceHelper.project.zip) {
      error = 'The project address must have a zip code'
    }
    if (error) {
      setFormError(error)
      setDisabledForm(true)
    } else {
      if (disabledForm) setDisabledForm(false)
    }
  }, [])

  const classes = useStyles()
  //@ts-ignore

  useEffect(() => {
    //refresh on mount once everything we need to make this request is available
    refreshAndUpdateValues()
    return () => onDismount()
  }, [])

  const refreshAndUpdateValues = () => {
    setRefreshing(true)
    const itCouldRefresh = refreshFromIronRidge()
      ?.then((prom) => {
        setRefreshing(false)
      })
      .catch((err) => {
        console.log('err', err?.body?.message)
        setRefreshError(
          "Unable to fetch this project from Ironridge. If you Unlocked this project on Ironridge's site we will not be able to retrieve the BOM"
        )
      })
    if (!itCouldRefresh) setRefreshing(false)
  }

  const onDismount = () => {
    setShowAccountButtons(true)
    setShowBOMModal(false)
    setShowForm(false)
  }

  type FormValues = {
    [fieldName: string]: any
  }
  const findOption = (allFields: FormFieldType[], fieldName: string, value: string) => {
    let field = allFields.find((field) => field.name === fieldName)
    if (!field) return undefined
    return field.options.find((option) => option.id === value)
  }
  const handleSubmit = (vals: FormValues) => {
    setLoading(true)

    const railOption = findOption(ironRidgeFields, 'rail_type', vals.rail_type)
    if (
      railOption?.attachment_compatibility &&
      !railOption?.attachment_compatibility.includes(vals.attachment_hardware)
    ) {
      setFormError(`This attachment hardware is not compatible with the selected rail type (${railOption.name})`)
      setLoading(false)
      return
    }

    if (vals.roof_attachment === 'qm_hug_d') {
      if (!vals?.deck_thickness) {
        setFormError('Deck thickness is required when using Halo UltraGrip (Deck attached) roof attachment')
        setLoading(false)
        return
      }
    }

    if (!termsChecked && !userAcceptedTerms) {
      setFormError("Please accept Ironridge's terms and conditions")
      setLoading(false)
      return
    }

    const ironRidgeJson = window.editor.selectedSystem?.integration_json
    const parsed: { project: IronRidgeProjectRequestType } = parseIntegrationJson(ironRidgeJson)?.ironridge
    let placeholderReqBody = { project: {} } as IronRidgeProjectRequestType
    if (termsChecked) placeholderReqBody['accepted_terms'] = true
    let systemName = undefined
    // @ts-ignore
    if (editor.selectedSystem?.name?.length > 0) systemName = editor.selectedSystem.name
    else {
      // @ts-ignore
      let systemClone = { ...editor.selectedSystem }
      // @ts-ignore
      systemClone.module_quantity = editor.selectedSystem.moduleQuantity()
      systemName = systemNameOrDefault(systemClone)
    }
    placeholderReqBody.project.name = window.WorkspaceHelper.project.address
    placeholderReqBody.project.user_email = vals.email
    placeholderReqBody.project.ironridge_project_id = parsed?.project?.id || null
    placeholderReqBody.project.asce = vals.asce
    placeholderReqBody.project.discount = vals.discount
    placeholderReqBody.project.panel_finish = vals.panel_finish
    placeholderReqBody.project.camo = vals.camo
    placeholderReqBody.project.risk_category = vals.risk_category
    placeholderReqBody.project.street = window.WorkspaceHelper.project.address
    placeholderReqBody.project.city = window.WorkspaceHelper.project.locality
    placeholderReqBody.project.state = window.WorkspaceHelper.project.state
    placeholderReqBody.project.country = window.WorkspaceHelper.project.country_iso2
    if (window.WorkspaceHelper.project.country_iso2 === 'US') {
      placeholderReqBody.project.zip = window.WorkspaceHelper.project.zip
    } else {
      placeholderReqBody.project.non_usa_postal_code = window.WorkspaceHelper.project.zip
    }

    placeholderReqBody.project.project_site_attributes = {
      lat: window.WorkspaceHelper.project.lat,
      lng: window.WorkspaceHelper.project.lon,
    }

    placeholderReqBody.project.roof_sections_attributes = []

    let roofSlope = undefined
    let roofSlopes: number[] = []
    let hasMultipleSlopes = false

    // check if this system has multiple arrays with at least one array having a difference of 2 degrees or more from any other array's slope
    setIsTooSteep(false)
    setIsTooFlat(false)
    editor.selectedSystem.children
      .filter((child) => child.name === 'OsModuleGrid')
      .forEach((panelArray) => {
        if (panelArray?.userData?.slope !== undefined) {
          const roundedSlope = Math.round(panelArray.userData.slope * 100) / 100
          if (roundedSlope < 9) {
            setIsTooFlat(true)
            setLoading(false)
          } else if (roundedSlope > 45) {
            setIsTooSteep(true)
            setLoading(false)
          }
          roofSlopes.push(roundedSlope)
        }
      })

    if (roofSlopes && roofSlopes.length > 0) {
      roofSlopes.sort()
      let maxSlopeDiff = Math.abs(roofSlopes[0] - roofSlopes[roofSlopes.length - 1])
      if (maxSlopeDiff > MULTIPLE_SLOPE_THRESHOLD) hasMultipleSlopes = true
    }

    editor.selectedSystem.children
      .filter((child) => child.name === 'OsModuleGrid')
      .forEach((panelArray, arrayIndex) => {
        let bounds = panelArray?.getBounds()
        if (!bounds) return
        let [minX = 0, minY = 0, maxX = 0, maxY = 0] = bounds

        let roof_section = {} as IronRidgeRoofSectionType
        if (panelArray?.userData?.moduleLayout === 'portrait') {
          roof_section.portrait = true
        } else roof_section.portrait = false

        let cols = maxX - minX
        let rows = maxY - minY
        roof_section.layout_attributes = {
          rows: rows + 1,
          columns: cols + 1,
          design: getDesignString(panelArray.cellsActive, minX, maxX, minY, maxY),
        }

        if (!roof_section.layout_attributes.design?.includes('1')) return

        if (panelArray?.userData?.slope !== undefined) {
          roof_section.roof_slope = panelArray.userData.slope
        }

        if (editor.selectedSystem.integration_json) {
          try {
            let project = JSON.parse(editor.selectedSystem.integration_json)?.ironridge?.project
            if (project?.roofSections) {
              let thisRoof: IronRidgeRoofSectionType = project.roofSections[arrayIndex]
              roof_section.multi_slope_project_id = thisRoof.multi_slope_project_id
            }
          } catch (ex) {}
        }

        placeholderReqBody.project.roof_sections_attributes.push(roof_section)
      })

    placeholderReqBody.project.has_multiple_slopes = hasMultipleSlopes

    let foundSlopeError = false
    placeholderReqBody?.project?.roof_sections_attributes?.forEach((roof) => {
      const roundedSlope = Math.round(roof?.roof_slope * 100) / 100
      if (roundedSlope < 9) {
        setIsTooFlat(true)
        setLoading(false)
        foundSlopeError = true
        logAmplitudeEvent('ironridge_min_slope_blocker', {
          mode: window.ViewHelper.has3DView() ? '3D' : '2D',
          slope: roundedSlope,
        })
      } else if (roundedSlope > 45) {
        setIsTooSteep(true)
        setLoading(false)
        foundSlopeError = true
        logAmplitudeEvent('ironridge_max_slope_blocker', {
          mode: window.ViewHelper.has3DView() ? '3D' : '2D',
          slope: roundedSlope,
        })
      }
    })
    if (foundSlopeError) return

    let project_details = {} as IronRidgeProjectDetailsType
    project_details.roof_material = vals.roof_material
    project_details.roof_attachment = vals.roof_attachment
    project_details.attachment_hardware = vals.attachment_hardware
    project_details.building_height = vals.building_height ? parseInt(vals.building_height) : 0
    project_details.modules_exposed = vals.modules_exposed
    project_details.modules_edge = vals.modules_edge
    project_details.rail_type = vals.rail_type
    project_details.roof_shape = vals.roof_shape
    project_details.deck_thickness = vals.deck_thickness

    // In ~May 2023 IronRidge introduced the available_lengths field which accepts an array of ints where each value is the length of rail in inches that the installer is willing to accept
    project_details.available_lengths = []
    if (vals.rail_length_14 !== false) {
      if (vals.rail_type === IronRidgeRailType.AIRE_A1 || vals.rail_type === IronRidgeRailType.AIRE_A2) {
        project_details.available_lengths.push(170)
      } else {
        project_details.available_lengths.push(14 * 12)
      }
    }
    if (vals.rail_length_17 !== false) {
      // don't add available length for 17' if the rail type is AIRE
      if (vals.rail_type !== IronRidgeRailType.AIRE_A1 && vals.rail_type !== IronRidgeRailType.AIRE_A2) {
        project_details.available_lengths.push(17 * 12)
      }
    }

    if (vals.roof_slope) project_details.roof_slope = Math.round(vals.roof_slope)
    else if (roofSlope !== undefined) project_details.roof_slope = Math.round(roofSlope)

    placeholderReqBody.project.detail_attributes = project_details

    const system = window.editor.objectByUuid(editor.selectedSystem.uuid)
    const moduleId: number = system.moduleType().module_id || system.moduleType().id
    placeholderReqBody.module = {}

    if (!panelMatched) {
      if (!vals.panel_manufacturer || !vals.panel_model_number) {
        setFormError('Module Manufacturer and Model are required')
        setLoading(false)
        return
      } else {
        placeholderReqBody.module.ironridge_manufacturer_id = vals.panel_manufacturer
        placeholderReqBody.module.ironridge_id = vals.panel_model_number
      }
    } else {
      placeholderReqBody.module.opensolar_id = moduleId
    }
    if (
      placeholderReqBody.project.detail_attributes.rail_type === IronRidgeRailType.AIRE_A1 ||
      placeholderReqBody.project.detail_attributes.rail_type === IronRidgeRailType.AIRE_A2
    ) {
      placeholderReqBody.project.rail_platform = 'aire'
    }
    const restClientInstance = restClient(API_URL)
    restClientInstance('CUSTOM_POST', 'custom', {
      url:
        'orgs/' +
        window.WorkspaceHelper.project.org_id +
        '/projects/' +
        window.WorkspaceHelper.project.id +
        '/systems/' +
        //@ts-ignore
        editor.selectedSystem.uuid +
        '/ironridge/save',
      data: placeholderReqBody,
    })
      .then((response: any) => {
        setFormError(null)
        setShowPermissionsWarning(false)
        // Designer.showNotification(window.translate('IronRidge Bill of Materials added to project'))
        setShowForm(false)
        setNewBOM(response.data.bom)
        setShowBOMModal(true)
        setSuccessMessage('Your Bill of Materials has been retrieved')
        setShowStaleDataMessage(false)
        let normalWarnings: string[] = []
        let highPriWarnings: string[] = []
        if (response.data.integration_json?.warnings) {
          // @ts-ignore
          Object.keys(response.data.integration_json.warnings).forEach((warningName: WarningType) => {
            // @ts-ignore
            if (
              warningName === 'engineering' ||
              (WARNING_TO_SEVERITY_MAP[warningName] &&
                response.data.integration_json.warnings[WARNING_TO_SEVERITY_MAP[warningName]])
            )
              highPriWarnings.push(response.data.integration_json.warnings[warningName])
            else if (!warningName.includes('Special')) {
              // don't add this if it's the severtiy flag key (eg windSpecial)
              normalWarnings.push(response.data.integration_json.warnings[warningName])
            }
          })
          if (normalWarnings && normalWarnings.length > 0) setBomWarnings(normalWarnings)
          if (highPriWarnings && highPriWarnings.length > 0) setHighPriorityBomWarnings(highPriWarnings)
        }
        let newIntegrationJson = window.editor.selectedSystem.integration_json
          ? parseIntegrationJson(window.editor.selectedSystem.integration_json)
          : {}
        try {
          if (newIntegrationJson.ironridge) {
            newIntegrationJson.ironridge.project = response.data.integration_json['project']
          } else newIntegrationJson.ironridge = response.data.integration_json
          newIntegrationJson.ironridge.is_disconnected = false
        } catch (ex) {
          console.log('ex', ex)
        }
        window.editor.execute(
          new window.SetValueCommand(
            window.editor.selectedSystem,
            'integration_json',
            newIntegrationJson,
            window.Utils.generateCommandUUIDOrUseGlobal()
          )
        )
        logAmplitudeEvent('ironridge_bom_retreived', {
          warning_count: highPriWarnings?.length,
          alert_count: normalWarnings?.length,
          project_state: window.WorkspaceHelper.project.state,
        })
      })
      .catch((err: any) => {
        console.log('error', err?.body)
        if (err?.body?.message) {
          setFormError(err.body.message)
          logAmplitudeEvent('ironridge_bom_fetch_error', { handled: 'yes', message: err.body.message })
        } else {
          //@ts-ignore
          let isPermissionError = true
          if (isPermissionError) setShowPermissionsWarning(true)
          else setFormError('Unable to save data')
          logAmplitudeEvent('ironridge_bom_fetch_error', { handled: 'no' })
        }
      })
      .finally(() => setLoading(false))
  }

  //mocks for IR's account validation endpoint
  const validateAccount = (vals: any) => {
    if (!userAcceptedTerms && !termsChecked) {
      setErrorMsg("Please accept IronRidge's terms and conditions")
      return
    }
    const restClientInstance = restClient(API_URL)
    let requestBody: IronRidgeAccountRequestType = {
      ironridge_email: vals.ironridge_email,
      accepted_terms: termsChecked,
    }
    restClientInstance('CUSTOM_POST', 'custom', {
      url:
        'orgs/' +
        //@ts-ignore
        WorkspaceHelper.project.org_id +
        '/roles/' +
        props.roleId +
        '/ironridge/check_account',
      data: requestBody,
    })
      .then((response: IronRidgeAccountResponseType) => {
        if (response?.data?.user_found === false) {
          setErrorMsg('No user with that email was found')
          setShowPermissionsWarning(false)
          setShowForm(false)
          logAmplitudeEvent('ironridge_email_not_found', {})
        } else if (!response?.data?.api_permissions_granted) {
          setShowPermissionsWarning(true)
          setErrorMsg(null)
          dispatch(updateRoleIronridgeEnabled(vals.ironridge_email, termsChecked || userAcceptedTerms, props.roleId))
          logAmplitudeEvent('ironridge_connected_no_permissions', {})
        } else {
          setErrorMsg(null)
          setShowPermissionsWarning(false)
          setShowAccountButtons(false)
          setShowForm(true)
          setSuccessMessage('Your IronRidge account is now connected!')
          dispatch(updateRoleIronridgeEnabled(vals.ironridge_email, termsChecked || userAcceptedTerms, props.roleId))
          logAmplitudeEvent('ironridge_connected', {})
        }
      })
      .catch((err: any) => {
        console.log('err', err)
      })
    return
  }
  const renderEmailForm = (formProps: any) => {
    return (
      <>
        {showPermissionsWarning && <IronRidgePermissionsWarning />}
        {errorMsg && <div className={classes.error}>{errorMsg}</div>}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid rgba(177, 177, 177, 0.8)',
            boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.25)',
            borderRadius: '5px',
            padding: '10px',
            marginBottom: '40px',
          }}
        >
          <CustomField
            style={{ minWidth: '400px', marginBottom: '0px' }}
            required={true}
            name="ironridge_email"
            label="IronRidge Account Email"
            component={TextInput}
            initialValue={userEmail}
          />
          {!userAcceptedTerms && <div style={{ marginBottom: '20px' }}>{termsAndConditions()}</div>}
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: '20px' }}>
            <GenericButton
              onClick={() => formProps.handleSubmit(validateAccount)}
              label="Connect Account"
              disabled={loading}
              backgroundColor="#ffda00"
              labelColor="rgb(77,77,77)"
              tooltipLabel={null}
            />
          </div>
        </div>
      </>
    )
  }

  const handleNoAccountClick = () => {
    if (!loading) {
      setShowAccountButtons(false)
      setShowForm(true)
      setAccountWarningMessage(
        "To finish your account creation you will need to sign up using this email address on IronRidge's website"
      )
    } else setErrorMsg('One moment while we search for your IronRidge account')
    logAmplitudeEvent('ironridge_no_account_clicked', {})
  }

  if (!props.showDialog && !forceFormOpen) return null
  //@ts-ignore

  const renderAccountButtons = () => {
    //@ts-ignore
    const publicUrl: string = window.PUBLIC_URL
    return (
      <div className={classes.modalContentWrapper}>
        <img src={`${publicUrl}/images/ironridge_logo.png`} width="189px" height="36px" />
        {!showPermissionsWarning && (
          <div style={{ margin: '40px 20% 40px 20%' }}>
            We have partnered with IronRidge to make it easy to get a full Bill of Materials for your OpenSolar
            projects. The first step is to connect your OpenSolar and IronRidge accounts
          </div>
        )}
        <div className={classes.accountButtonWrapper}>
          <div className={classes.accountButtonsView}>
            <Form onSubmit={validateAccount} render={renderEmailForm} />
          </div>
          <div className={classes.accountButtonsView}>
            <a onClick={handleNoAccountClick} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
              Or click here to make an IronRidge account
            </a>
          </div>
        </div>
      </div>
    )
  }

  const renderFormWarningComponent = () => {
    if (showPermissionsWarning) return <IronRidgePermissionsWarning />
    else return null
  }

  const closeAndResetBom = () => {
    handleClose(true)
    setShowBOMModal(false)
    setNewBOM(null)
    setShowForm(true)
    setBomWarnings([])
    setHighPriorityBomWarnings([])
    setShowStaleDataMessage(false)
  }

  const renderBOMModal = () => (
    <IronRidgeBom
      newBOM={newBOM}
      onClose={closeAndResetBom}
      isFirstBOMForSystem={!isExistingProject}
      warnings={bomWarnings}
      highPriorityWarnings={highPriorityBomWarnings}
    />
  )

  const disconnectProject = () => {
    if (window.editor.selectedSystem.integration_json) {
      let newIntegrationJson = parseIntegrationJson(window.editor.selectedSystem.integration_json)
      if (newIntegrationJson.ironridge) {
        newIntegrationJson.ironridge.is_disconnected = true
        window.editor.execute(
          new window.SetValueCommand(
            window.editor.selectedSystem,
            'integration_json',
            newIntegrationJson,
            window.Utils.generateCommandUUIDOrUseGlobal()
          )
        )
        logAmplitudeEvent('ironridge_project_disconnected', {})
      }
    }
    handleClose()
  }

  const renderRefreshing = () => (
    <div style={{ height: '400px', display: 'flex', justifyContent: 'center' }}>
      {refreshError ? (
        <div style={{ display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          {refreshError}
        </div>
      ) : (
        <LoadingDots text="One moment while we load your IronRidge project data..." />
      )}
    </div>
  )

  const renderTermsForNewUser = () => {
    if (userAcceptedTerms) return null
    return <div>{termsAndConditions()}</div>
  }

  const termsAndConditions = () => {
    return (
      <FormControlLabel
        control={<Checkbox checked={termsChecked} onChange={(e) => setTermsChecked(!termsChecked)} />}
        label={
          <span>
            I agree to{' '}
            <a href="https://www.ironridge.com/terms-of-service/" target="_blank" rel="noopener noreferrer">
              Ironridge's Terms and Conditions
            </a>
          </span>
        }
      />
    )
  }

  const renderForm = () => {
    //@ts-ignore
    const publicUrl: string = window.PUBLIC_URL
    return (
      <>
        <div className={classes.modalContentWrapper}>
          <img src={`${publicUrl}/images/ironridge_logo.png`} width="189px" height="36px" />
          {successMessage && <div className={classes.accountSuccess}>{successMessage}</div>}
          {accountWarningMessage && <Alert severity="warning">{accountWarningMessage}</Alert>}
          {showStaleDataMessage ? (
            <Alert severity="warning">
              It looks like changes have been made to this system that may affect the BOM. Please verify that the
              following information is still accurate and click "Get Bill of Materials" to retrieve an updated BOM.
            </Alert>
          ) : (
            <div style={{ margin: '20px 40px 5px 40px' }}>
              Enter the details of the project below and click the "Get Bill of Materials" to automatically include the
              Bill of Materials in your project
            </div>
          )}
          {isTooFlat && (
            <div className={classes.error}>
              This integration does not support roofs with a slope of less than 9 degress. Please{' '}
              <a href={irProjectUrl} target="_blank" rel="noopener noreferrer">
                create this project manually in IronRidge's Design Assistant.
              </a>
            </div>
          )}
          {isTooSteep && (
            <div className={classes.error}>
              This integration does not support roofs with a slope of more than 45 degress. Please{' '}
              <a href={irProjectUrl} target="_blank" rel="noopener noreferrer">
                create this project manually in IronRidge's Design Assistant.
              </a>
            </div>
          )}
          <br />
          <Form
            onSubmit={handleSubmit}
            keepDirtyOnReinitialize={true}
            component={(formProps) => (
              <IntegrationAPIForm
                fields={ironRidgeFields}
                advancedFields={ironRidgeAdvancedFields}
                formError={formError}
                submitButtonLabel="Get Bill of Materials"
                formWarningComponent={renderFormWarningComponent}
                termsAndConditions={renderTermsForNewUser}
                onSubmit={handleSubmit}
                loading={loading}
                initialValues={{
                  module_match: panelMatched,
                }}
                disabledForm={disabledForm}
                values={formProps.values}
              />
            )}
          />
          {showStaleDataMessage && (
            <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={disconnectProject}>
              Or click if you no longer want to use the IronRidge integration on this system
            </span>
          )}
        </div>
      </>
    )
  }

  const handleClose = (justCloseIt: boolean = false) => {
    if (showBOMModal && !justCloseIt) {
      if (
        window.confirm(
          translate(
            'Are you sure you want to close this form? All progress will be lost and your BOM will not be saved'
          )
        )
      ) {
        props.onClose()
        dispatch(closeIronRidgeDialog())
      }
    } else {
      props.onClose()
      dispatch(closeIronRidgeDialog())
    }
  }

  const irProjectUrl = ['production', 'staging4'].includes(window.ENV)
    ? 'https://base.ironridge.com/my_projects'
    : 'https://staging-ridge.com/my_projects'

  return (
    <Dialog
      open={props.showDialog || forceFormOpen}
      onBackdropClick={() => handleClose()}
      // actionsContainerStyle={{ backgroundColor: '#e2e2e2', minHeight: 70, paddingTop: 20 }}
      fullWidth={!showAccountButtons}
      maxWidth={showAccountButtons ? 'sm' : 'xl'}
    >
      {showAccountButtons && renderAccountButtons()}
      {showForm && !refreshing && renderForm()}
      {showForm && refreshing && renderRefreshing()}
      {showBOMModal && renderBOMModal()}
    </Dialog>
  )
}

export default connect((state: RootState) => ({
  orgId: authSelectors.getOrgId(state),
}))(IronRidgeDialog)

import { Paper } from '@material-ui/core'
import { Button, ChevronLeftOutlineIcon, ChevronRightOutlineIcon, CloseIcon, IconButton, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { FC, ReactNode } from 'react'

const TooltipPaper = styled(Paper, { name: 'TooltipPaper' })({
  borderRadius: 8,
  pointerEvents: 'all',
  minWidth: 340,
  padding: '10px 20px',
})
const TooltipHeader = styled('div', { name: 'TooltipHeader' })({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const HeaderText = styled('h1', { name: 'HeaderText' })(({ theme }) => ({
  fontWeight: 450,
  color: theme.palette.grey[800],
  fontSize: 14,
}))

const TooltipSubtext = styled('p', { name: 'TooltipSubtext' })(({ theme }) => ({
  color: theme.palette.grey[800],
  fontWeight: 400,
  marginTop: 0,
}))

const CloseBtn = styled(IconButton, { name: 'CloseBtn' })(({ theme }) => ({
  color: theme.palette.grey[600],
}))

const ActionBtns = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
})

interface PropTypes {
  title: string
  subtext: ReactNode
  handleNext?: () => void
  handlePrev?: () => void
  handleCancel?: () => void
  handleNextLabel?: string
  handlePrevLabel?: string
}

const TourTooltip: FC<PropTypes> = ({
  title,
  subtext,
  handleNext,
  handlePrev,
  handleCancel,
  handleNextLabel = 'Next',
  handlePrevLabel = 'Back',
}) => {
  const translate = useTranslate()
  return (
    <TooltipPaper>
      <TooltipHeader>
        <HeaderText>{title}</HeaderText>
        <CloseBtn onClick={handleCancel}>
          <CloseIcon />
        </CloseBtn>
      </TooltipHeader>
      <TooltipSubtext>{subtext}</TooltipSubtext>
      <ActionBtns>
        {handlePrev && (
          <Button
            onClick={handlePrev}
            variant="text"
            color="secondary"
            startIcon={<ChevronLeftOutlineIcon size={12} />}
          >
            {translate(handlePrevLabel)}
          </Button>
        )}
        {handleNext && (
          <Button onClick={handleNext} variant="text" color="secondary" endIcon={<ChevronRightOutlineIcon size={12} />}>
            {translate(handleNextLabel)}
          </Button>
        )}
      </ActionBtns>
    </TooltipPaper>
  )
}

export default TourTooltip

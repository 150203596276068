// @ts-nocheck
import Grid from '@material-ui/core/Grid'
import React from 'react'
import { ReferenceInput } from 'react-admin'
import { useForm } from 'react-final-form'
import { ContactType } from 'types/contacts'
import ContactAutocomplete from './ContactAutocomplete'

type PropTypes = {
  contact: ContactType | null
  index: number
  onSelection: (selected: ContactType) => void
}

const ContactLinkExisting: React.FunctionComponent<PropTypes> = (props) => {
  const form = useForm()

  const contact = form.getState().values.contacts_new[props.index]
  const getInitialValue = () => {
    if (!contact || !contact.first_name) return null
    if (!contact.family_name) return contact.first_name
    else return `${contact.first_name} ${contact.family_name}`
  }

  return (
    <Grid item xs={12}>
      <ReferenceInput
        label="Search existing contacts..."
        reference="contacts"
        source="existing_contact"
        style={{ width: '100%' }}
        renderChildrenBeforeRecord={true}
        filter={{ type: 0 }}
      >
        <ContactAutocomplete
          onSelection={props.onSelection}
          initialValue={getInitialValue()}
          showSpinnerWhenRefreshing={true}
        />
      </ReferenceInput>
    </Grid>
  )
}
export default ContactLinkExisting

import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined'
import { Button } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import useTranslateParse from '../../../util/useTranslateParse'
import DefaultTooltip from '../fuji/DefaultToolTip'
import { ToolTipPropsType, TourConfigType } from '../types'

const Tooltip = (props: ToolTipPropsType) => {
  const { handleNext, handlePrev, handleCancel } = props
  const translate = useTranslate()
  const translateParse = useTranslateParse()

  const nextStep = () => {
    if (handleNext) handleNext()
  }
  return (
    <DefaultTooltip
      title={translate('View Payment Options')}
      // onClose={handleCancel}
      icon={LayersOutlinedIcon}
      content={
        <div>
          <p>
            {translate(
              'Payment Option cards allow you to compare key information across customer payment options available on your account. Click “See Details” on any card to see even more detail about that option.'
            )}
          </p>
          <p>
            {translate(
              "From this view you can update the upfront customer payment and immediately see the impact on monthly payments. With our integrated finance payment options, your customers can access the loan application right from the payment option card, or through a link you've sent directly to them."
            )}
          </p>
        </div>
      }
      actions={
        <>
          <Button
            onClick={() => handlePrev && handlePrev()}
            onTouchStart={(e) => {
              e.stopPropagation()
              if (handlePrev) {
                handlePrev()
              }
            }}
          >
            <span>{translate('Back')}</span>
          </Button>
          <Button onClick={(e) => nextStep()}>
            <span>{translate('Next')}</span>
          </Button>
        </>
      }
      arrowPlacement={'right'}
    />
  )
}

const CardStep: TourConfigType = {
  spotlights: [{ targetId: 'pmt-option-card-wrapper-0', disableBackgroundScroll: false, padding: 5 }],
  tooltips: [{ targetId: 'pmt-option-card-wrapper-0', placement: 'left', component: Tooltip }],
}

export default CardStep

import { permissionsSelectors } from 'ducks/permissions'
import { Box } from 'opensolar-ui'
import { useSelector } from 'react-redux'
import { ModuleGridType } from 'types/global'
import Panel from '../../Panel'
import ModuleGridSettings from '../../PanelModuleGrid/ModuleGridSettings'
import SelectionChips from '../SelectionChips'
import ActionTools from './ActionTools'

const HomoModuleGridPanelGroup = ({ moduleGrids, group }: { moduleGrids: ModuleGridType[]; group }) => {
  const accessRightsSetting = useSelector(permissionsSelectors.getPermissionsSetting)
  const allowEdit = !!accessRightsSetting?.['design'].edit
  return (
    <Panel
      showTools={false}
      disableTools={false}
      title={'Panel Groups'}
      content={
        <Box display="flex" flexDirection="column" gridGap={12}>
          <SelectionChips objects={moduleGrids} />
          <ModuleGridSettings allowEdit={allowEdit} moduleGrids={moduleGrids} />
          <ActionTools group={group} moduleGrids={moduleGrids} />
        </Box>
      }
    />
  )
}

export default HomoModuleGridPanelGroup

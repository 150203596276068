import CallIcon from '@material-ui/icons/CallOutlined'
import EmailIcon from '@material-ui/icons/EmailOutlined'
import TodayIcon from '@material-ui/icons/TodayOutlined'
import GenericButton from 'elements/button/GenericButton'
import React from 'react'
import { useTranslate } from 'react-admin'
import Olark from 'react-olark-plugin'
import { isIE } from '../util/misc'

const phoneLinkIsAvailable = (org) => org && org.sales_phone_number && org.sales_phone_number.length > 0

const PhoneLink = ({ phoneNumber }) => {
  if (phoneNumber) {
    return (
      <a style={{ marginRight: 10 }} href={'tel:' + phoneNumber.split(' ').join('')}>
        {phoneNumber}
      </a>
    )
  } else {
    return null
  }
}

const PhoneLinkFromOrg = ({ org }) => {
  if (phoneLinkIsAvailable(org)) {
    return <PhoneLink phoneNumber={org.sales_phone_number} />
  } else {
    return null
  }
}
const SalesDetail = (props) => {
  const { org, assigned_team_member, api_key_chat, isPro, myeStyles } = props
  const translate = useTranslate()
  const { schedule_meeting_url, schedule_meeting_label, portrait_image_public_url } = assigned_team_member
  const urlRegExp = new RegExp('^(http|https)://', 'i')
  const highlightColor = org.color_highlight && org.color_highlight.length === 7 ? org.color_highlight : '#fdd502'
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div className="sales-detail-wrapper">
        {portrait_image_public_url ? (
          <div>
            <img className="portrait-image" src={portrait_image_public_url} alt="sales-portrait" />
          </div>
        ) : (
          <div className="default-portrait-icon">
            <CallIcon />
          </div>
        )}
        <div className="contact-detail">
          <div style={{ fontSize: 16, lineHeight: '23px' }}>{assigned_team_member.display}</div>
          <div>{assigned_team_member.job_title || translate('Sales Representative')}</div>
          <div>
            <span>
              {assigned_team_member.phone ? (
                <PhoneLink phoneNumber={assigned_team_member.phone} />
              ) : phoneLinkIsAvailable(org) ? (
                <PhoneLinkFromOrg org={org} />
              ) : null}
            </span>
            {assigned_team_member.email && (
              <span>
                <a href={`mailto:${assigned_team_member.email}`}>
                  {!isIE() && (
                    <EmailIcon style={{ width: 16, height: 'inherit', color: '#b7b7b7', verticalAlign: 'bottom' }} />
                  )}
                  {translate('Email')}
                </a>
              </span>
            )}
          </div>
        </div>
      </div>
      {!isPro && api_key_chat && api_key_chat.length > 0 && (
        <Olark siteId={api_key_chat} systemConfig={{ hb_primary_color: highlightColor }} />
      )}
      {schedule_meeting_url && (
        <GenericButton
          startIcon={
            <TodayIcon
            // style={{ minWidth: 35, width: 30, minHeight: 37, padding: '3px 0 0' }}
            />
          }
          onClick={(e) => {
            e.preventDefault()
            const openUrl = urlRegExp.test(schedule_meeting_url)
              ? schedule_meeting_url
              : `https://${schedule_meeting_url}`
            window.open(openUrl, '_blank')
          }}
          wrapperStyle={{ width: 240 }}
          labelColor={myeStyles.textColorOnHighlight}
          backgroundColor={myeStyles.highlightColor}
          backgroundColorOnHover={myeStyles.highlightColorInteraction}
          labelWrapperStyle={{
            padding: 0,
            fontSize: 14,
            textAlign: 'left',
            paddingLeft: 10,
            whiteSpace: 'normal',
            lineHeight: 'normal',
            textTransform: 'none',
          }}
          style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            borderRadius: 3,
            marginTop: 10,
            padding: '3px 7px',
          }}
          label={translate(schedule_meeting_label || 'Schedule A Time To Discuss This Proposal With Me!')}
        />
      )}
    </div>
  )
}

export default SalesDetail

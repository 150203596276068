import { useCallback } from 'react'
import useSegenServiceV2 from './segen/useSegenServiceV2'
import { HardwareSupplierFilterKeyType } from '../pages/ordering/type'
import { useHistory } from 'react-router-dom'
import useCityPlumbingService from './cityPlumbing/useCityPlumbingService'

export const useDistributorConnect = () => {
  const segenService = useSegenServiceV2()
  const cityPlumbingService = useCityPlumbingService()
  const history = useHistory()

  return useCallback((distributor?: HardwareSupplierFilterKeyType) => {
    if (distributor === 'segen') {
      return segenService.connect({
        enableRedirect: true,
        source: 'SegenConnectUserButton',
      })
    }

    if (distributor === 'hdm') {
      return history.push('/shop/connect_distributor/hdm/email')
    }

    if (distributor === 'city_plumbing') {
      return cityPlumbingService.connect()
    }

    if (distributor === 'vtac_uk' || distributor === 'vtac_pl') {
      return history.push('/shop/connect_distributor/vtac/email')
    }

    console.warn(`No connect implementation for distributor: ${distributor}`)
  }, [])
}

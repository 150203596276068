import { FormHelperText } from '@material-ui/core'
import { InputProps, useInput } from 'ra-core'
import { InputHelperText } from 'ra-ui-materialui'

/**
 * A way of displaying the errors of a particular field without rendering any input.
 * Useful when the UI is heavily customised.
 */

export const FieldValidationErrors = (props: InputProps & { helperText?: string }) => {
  const {
    meta: { touched, error },
  } = useInput(props)

  return (
    <FormHelperText error={!!error}>
      <InputHelperText touched={!!touched} error={error} helperText={props.helperText} />
    </FormHelperText>
  )
}

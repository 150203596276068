import type LineItemType from 'pages/ordering/OrderLineItem'
import type { SelectorOptionComponentType } from 'pages/ordering/order/table/quantity/Selector'
import type { QuantitySelectorType } from 'pages/ordering/order/table/quantity/useQuantitySelectorType'
import React, { memo } from 'react'
import BulkSelector from './bulk/BulkSelector'
import VolumeDiscountSelector from './discount/VolumeDiscountSelector'

export type SelectorComponentType = React.MemoExoticComponent<
  ({ options }: { options: SelectorOptionComponentType[] }) => JSX.Element
>

// Todo: switch type
const useQuantitySelector = ({
  lineItem,
  type,
}: {
  lineItem: LineItemType
  type?: QuantitySelectorType
}): SelectorComponentType | undefined => {
  if (type === 'bulk') {
    const Selector = memo(({ options }: { options: SelectorOptionComponentType[] }) => {
      return (
        <BulkSelector
          isLoading={lineItem.status === 'loading'}
          quantityPerItem={lineItem.quantityPerItem}
          optionComponents={options}
        />
      )
    })
    return Selector
  }
  if (type === 'discount') {
    const Selector = memo(({ options }: { options: SelectorOptionComponentType[] }) => {
      return <VolumeDiscountSelector lineItem={lineItem} optionComponents={options} />
    })
    return Selector
  }
}

export default useQuantitySelector

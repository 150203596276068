import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import { DateInput } from 'elements/input/DateInput'
import moment from 'moment'
import { IconButton } from 'opensolar-ui'
import React, { useState } from 'react'

const EditableDateField = ({ date, handleValueChange }: { date: string; handleValueChange(date): void }) => {
  const [editing, setEditing] = useState(false)

  return editing ? (
    <DateInput size="small" value={date} onChange={handleValueChange} inputVariant="outlined" />
  ) : (
    <div>
      <span>{moment(date).format('DD/MM')}</span>
      <IconButton size="small" onClick={() => setEditing(true)}>
        <EditOutlinedIcon fontSize="small" />
      </IconButton>
    </div>
  )
}

export default EditableDateField

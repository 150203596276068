import { makeStyles, useMediaQuery } from '@material-ui/core'
import PencilIcon from '@material-ui/icons/EditOutlined'
import { permissionsSelectors } from 'ducks/permissions'
import { viewModeSelectors } from 'ducks/viewMode'
import { StudioContainer } from 'elements/StudioContainer'
import InfoIcon from 'icons/InfoIcon'
import useLayout from 'layout/useLayout'
import { Button } from 'opensolar-ui'
import { DISPLAY_NAME as FUJI_TOUR_DISPLAY_NAME } from 'persistentContent/tours/fuji'
import ViewControllers from 'projectSections/sections/design/tools/widgets/ViewControllers'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { Field } from 'react-final-form'
import { useSelector } from 'react-redux'
import ToolbarViews from './ToolbarViews'

const useStyles = makeStyles((theme) => ({
  wrapperLarge: {
    height: 330,
    position: 'relative',
    marginTop: 20,
  },
  wrapperMobile: {
    height: 200,
    position: 'relative',
    marginTop: 15,
  },
  button: {
    marginTop: 10,
    zIndex: 1, //higher than view
    fontWeight: 'normal',
    fontSize: 12,

    background: 'rgba(255,255,255)',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    '&:hover': {
      background: '#dddddd',
    },
  },
  learnMoreButton: {
    top: 5,
    left: 30,
    minWidth: 40,
    position: 'absolute',
    pointerEvents: 'auto',
    marginTop: 10,
    zIndex: 1, //higher than view
    fontWeight: 'normal',
    fontSize: 12,
    background: 'rgba(255,255,255)',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    '&:hover': {
      background: '#dddddd',
    },
  },
  learnMoreLabel: {
    textTransform: 'none',
  },
  learnMoreStartIcon: {
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  buttonWrapper: {
    pointerEvents: 'auto',
    position: 'absolute',
    top: 5,
    right: 30,
  },
  viewControllersWrapper: {
    pointerEvents: 'auto',
    position: 'absolute',
    bottom: '15px',
    right: '35px',
    textAlign: 'right',
    zIndex: 10,
  },
  proposalViewport: {
    position: 'absolute',
    pointerEvents: 'none',
    top: 0,
    height: '100%',
    left: 20,
    right: 20,
  },
}))

const nextView = (props) => {
  const isTheLastView = props.viewUuids.indexOf(props.selectedViewUuid) === props.viewUuids.length - 1
  if (isTheLastView) {
    props.setSelectedViewUuid(props.viewUuids[0])
  } else {
    props.setSelectedViewUuid(props.viewUuids[props.viewUuids.indexOf(props.selectedViewUuid) + 1])
  }
}

const prevView = (props) => {
  const isTheFirstView = props.viewUuids.indexOf(props.selectedViewUuid) === 0
  if (isTheFirstView) {
    props.setSelectedViewUuid(props.viewUuids.slice(-1)[0])
  } else {
    props.setSelectedViewUuid(props.viewUuids[props.viewUuids.indexOf(props.selectedViewUuid) - 1])
  }
}

const Views = () => {
  const views = useMemo(() => window.ViewHelper.views, [])
  const selectedViewUuid = useMemo(() => window.ViewHelper.selectedViewUuid())
  const layout = useLayout()
  return (
    <ToolbarViews
      data={{
        views: views,
        activeViewUuid: selectedViewUuid,
      }}
      layout={layout}
    />
  )
}

const LearnMoreButton = ({ disabled }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const handleClick = () => {
    window.open(
      'https://support.opensolar.com/hc/en-us/articles/4406931209753-Change-Map-Type-and-Include-Views',
      '_blank'
    )
  }
  return (
    <Button
      startIcon={<InfoIcon width="20" height="20" />}
      classes={{ root: classes.learnMoreButton, startIcon: classes.learnMoreStartIcon, label: classes.learnMoreLabel }}
      disabled={disabled}
      onClick={handleClick}
    >
      <span>{!isSmall && translate('Learn how to customise views')}</span>
    </Button>
  )
}

const ViewHandlers = (props) => {
  const [editing, setEditing] = useState(false)
  const { allowEdit } = useSelector(permissionsSelectors.getProjectPermissionByKey('proposal'))
  const classes = useStyles()
  const notify = useNotify()
  //Remove the ability to click "Edit customer views" in Online Proposal in fuji tour.
  const disableEditButton = useSelector((state) => state.tour?.tour === FUJI_TOUR_DISPLAY_NAME) || !allowEdit
  const showProposalDrawer = useSelector(viewModeSelectors.showProposalDrawer)
  const viewAsCustomer = useSelector((state) => state.viewAsCustomer)
  const translate = useTranslate()

  useEffect(() => {
    if (showProposalDrawer && editing) handleClose()
  }, [showProposalDrawer])

  const handleOpen = useCallback(() => {
    // Auto-detect and auto-add oblique images if appropriate
    window.ViewHelper.autoAddViewsOnCustomizeViews()

    //show tools
    setEditing(true)

    //activate editor interactive and map activate
    window.Designer.changeControl('both', true)
  }, [])
  const handleClose = useCallback(() => {
    console.log('ViewPort.handleClose')
    //update mye viewUuids and selectedViewUuid
    const { viewUuids, selectedViewUuid } = window.ViewHelper.viewUuidsForCustomer()

    if (viewUuids.length === 0) {
      notify('No views are available for viewing. (Ensure "Show Customer" for at least one view.)', 'warning')
      return
    }

    //quit align-map mode
    if (window.Designer.controlMode === 'map') {
      window.Designer.changeControl('both')
    }

    props.setSelectedViewUuid(selectedViewUuid, viewUuids)

    //deactivate editor interactive and map interactive
    window.editor.interactive(false)
    window.MapHelper.interactive(false)

    //hide tools
    setEditing(false)
  }, [])

  if (viewAsCustomer) {
    return (
      <div className={props.isMobile ? classes.wrapperMobile : classes.wrapperLarge}>
        <StudioContainer zIndex={0} className={classes.proposalViewport} />
        <ArrowHandlers {...props} />
      </div>
    )
  }

  return (
    <div id="proposal-viewport">
      <div className={props.isMobile ? classes.wrapperMobile : classes.wrapperLarge}>
        <StudioContainer zIndex={0} className={classes.proposalViewport} />
        <div className={classes.buttonWrapper}>
          {editing ? (
            <Button classes={{ root: classes.button }} onClick={handleClose}>
              <span>{translate('close')}</span>
            </Button>
          ) : (
            <Button
              classes={{ root: classes.button }}
              disabled={disableEditButton}
              startIcon={<PencilIcon />}
              onClick={handleOpen}
            >
              <span>{translate('Customer Views')}</span>
            </Button>
          )}

          <Field name="design" component="input" type="text" style={{ display: 'none' }} />
        </div>

        <LearnMoreButton disabled={disableEditButton} />
        {editing ? (
          <div className={classes.viewControllersWrapper}>
            <ViewControllers />
          </div>
        ) : (
          <ArrowHandlers {...props} />
        )}
      </div>
      {editing && <Views />}
    </div>
  )
}

const ArrowHandlers = (props) => {
  return props.viewUuids && props.viewUuids.length > 1 ? (
    <div>
      <div className="mye-arrow-right" onClick={() => nextView(props)} />
      <div className="mye-arrow-left" onClick={() => prevView(props)} />
    </div>
  ) : null
}

const ViewPort = (props) => {
  const classes = useStyles()
  return props.isPro ? (
    <ViewHandlers {...props} />
  ) : (
    <div className={props.isMobile ? classes.wrapperMobile : classes.wrapperLarge}>
      <StudioContainer zIndex={0} className={classes.proposalViewport} />
      <ArrowHandlers {...props} />
    </div>
  )
}
export default ViewPort

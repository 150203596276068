import Alert from 'elements/Alert'
import AuthModalWrapper from '../AuthModalWrapper'
import { OpenSolarAuthFlowLogo } from '../components/logos/AuthFlowLogo'

const GenericErrorPage = ({ message = 'Bad SSO session.', showBackButton = true }: { message?: string, showBackButton?: boolean }) => {
  return (
    <AuthModalWrapper>
      <div>
        <OpenSolarAuthFlowLogo />
        <Alert severity="warning">{message}</Alert>
        {showBackButton && <p>
          Click here to <a href={`/#/login`}>Back to Login page</a>
        </p>}
      </div>
    </AuthModalWrapper>
  )
}

export default GenericErrorPage

import { IconButton } from '@material-ui/core'
import Popper from '@material-ui/core/Popper'
import { CloseOutlined } from '@material-ui/icons'
import { styled } from 'opensolar-ui'
import panzoom from 'panzoom'
import React, { CSSProperties, useEffect, useState } from 'react'

interface ImagePreviewerProps {
  src: string
  isOpen?: boolean
  onClose?: () => void
}

const StyledPopper = styled(Popper)({
  zIndex: 10000,
})

const FullScreenContainer = styled('div')({
  position: 'fixed',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0,0,0,0.5)',
  boxSizing: 'border-box',
  margin: 'auto',
  padding: 50,
  justifyContent: 'center',
  alignItems: 'stretch',
  display: 'flex',
})

const ExpandedImgContainer = styled('div')({
  display: 'flex',
})

const StyledImg = styled('img')({
  objectFit: 'contain',
  width: '100%',
  height: '100%',
})

export const ImagePreviewer: React.FC<ImagePreviewerProps> = ({ src, isOpen, onClose, children }) => {
  const [imgElement, setImgElement] = useState<HTMLElement | null>(null)
  const [expanded, setExpanded] = useState(false)

  const expandedImgStyle: CSSProperties = {
    objectFit: 'contain',
    maxWidth: '100%',
    maxHeight: '100%',
    minWidth: '80%',
    minHeight: '80%',
  }

  useEffect(() => {
    if (isOpen) {
      setExpanded(true)
    }
  }, [isOpen])

  useEffect(() => {
    if (expanded && imgElement) {
      const instance = panzoom(imgElement)
      return () => instance.dispose()
    }
  }, [expanded, imgElement])

  const handleExpand = () => {
    if (!isOpen) {
      setExpanded(true)
    }
  }

  const handleClose = () => {
    setExpanded(false)
    if (onClose) onClose()
  }

  return (
    <>
      <StyledPopper open={isOpen || expanded}>
        <FullScreenContainer onClick={handleClose}>
          <IconButton
            style={{ position: 'fixed', top: 20, right: 20, background: 'white', zIndex: 1000 }}
            onClick={handleClose}
          >
            <CloseOutlined />
          </IconButton>
          <ExpandedImgContainer>
            <StyledImg
              ref={(e) => setImgElement(e)}
              src={src}
              style={expandedImgStyle}
              onClick={(e) => e.stopPropagation()}
            />
          </ExpandedImgContainer>
        </FullScreenContainer>
      </StyledPopper>
      {children && (
        <div style={{ position: 'relative', cursor: 'pointer' }} onClick={handleExpand}>
          {children}
        </div>
      )}
    </>
  )
}

import { HelpLink } from 'elements/link/HelpLink'
import React from 'react'
import { useTranslate } from 'react-admin'
import { RoleCreate, RoleEdit, RoleList } from './Roles'

export const RoleTitle = ({ record, translate, style }) => {
  return (
    <span style={style}>
      {translate('Team Member:')} {record ? `"${record.display}"` : ''}
    </span>
  )
}

const Subtitle = () => {
  const translate = useTranslate()
  return (
    <span>
      {translate('Add team members from your company and set permissions.')}
      <HelpLink href="https://support.opensolar.com/hc/en-us/articles/4407123875225" />
    </span>
  )
}

export default {
  list: RoleList,
  edit: RoleEdit,
  create: RoleCreate,
  options: {
    create: {
      title: 'Invite Team Member',
      subtitle: null,
      breadCrumb: 'Invite Team Member',
    },
    list: {
      title: 'My Team Members',
      subtitle: <Subtitle />,
      breadCrumb: 'Team',
    },
    edit: {
      title: <RoleTitle />,
      subtitle: 'Manage details and permissions for your team member.',
      breadCrumb: 'Edit Team Member',
    },
  },
}

import { FC } from 'react'
import { ReferenceInput, SelectInput } from 'react-admin'
import ConditionalRefFilter from './ConditionalRefFilter'

interface PropTypes {
  source: string
  fieldId: string
}

const Workflow: FC<PropTypes> = (props) => {
  return (
    <ConditionalRefFilter {...props}>
      <ReferenceInput
        source={`${props.source}.fields.itemId`}
        reference="workflows"
        filter={{ show_archived: 1 }}
        label=""
      >
        <SelectInput showArchivedChoices={true} source="title" optionText="title" variant="outlined" margin="none" />
      </ReferenceInput>
    </ConditionalRefFilter>
  )
}

export default Workflow

import TotpIcon from '@material-ui/icons/PhoneAndroidOutlined'
import SmsIcon from '@material-ui/icons/SmsOutlined'
import CodesIcon from '@material-ui/icons/TextFormatOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import Button from 'elements/proUXButtons/ProUXButton'
import { useNotify, useTranslate } from 'ra-core'
import React, { useEffect, useState } from 'react'
import { Confirm } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import restClient from 'restClient'
import appStorage from 'storage/appStorage'
import { MFAStrategy } from 'types/mfa'
import { fetchMfaConfig } from './util'

type PropTypes = {
  goToPhone: () => void
  goToTotp: () => void
  goToMode: () => void
  goToRecovery: () => void
}

const MfaStatus: React.FC<PropTypes> = ({ goToPhone, goToTotp, goToRecovery, goToMode }: PropTypes) => {
  const isEnabled: boolean = useSelector(authSelectors.getIsMfaEnabledOnRole)
  const isEnabledOnOrg: boolean = useSelector(authSelectors.getIsMfaEnabledOnOrg)
  const history = useHistory()
  const translate = useTranslate()
  const dispatch = useDispatch()
  const notify = useNotify()

  const activeMfaDevices = useSelector(authSelectors.getConfirmedMfaDevice) || []

  const [loading, setLoading] = useState(false)
  const [activeSms, setActiveSms] = useState(false)
  const [activeTotp, setActiveTotp] = useState(false)
  const [activeRecovery, setActiveRecovery] = useState(false)
  const [remainingRecovery, setRemainingRecovery] = useState(0)

  const [disableConfirming, setDisableConfirming] = useState(false)

  useEffect(() => {
    let activeSms = false
    let activeTotp = false
    let activeRecovery = false
    let remainingRecovery = 0
    for (const device of activeMfaDevices) {
      switch (device.kind) {
        case 'sms':
          activeSms = true
          break
        case 'totp':
          activeTotp = true
          break
        case 'recovery':
          activeRecovery = true
          remainingRecovery = device.remaining || 0
          break
      }
      setActiveSms(activeSms)
      setActiveTotp(activeTotp)
      setActiveRecovery(activeRecovery)
      setRemainingRecovery(remainingRecovery)
    }
  }, [activeMfaDevices])

  const smsMfaAllowedOnOrg = useSelector(authSelectors.getIsSmsMfaAllowedOnOrg)
  const isStaff = useSelector(authSelectors.getIsStaff)
  const allowAddOrReplaceSms = isStaff || smsMfaAllowedOnOrg

  const getButtonTitle = () => {
    if (isEnabled) return 'Disable Two-factor authentication'
    else return 'Enable Two-factor authentication'
  }

  useEffect(() => {
    fetchMfaConfig(dispatch, translate, notify, setLoading)
  }, [])

  const clearMfaDevices = () => {
    setDisableConfirming(false)
    setLoading(true)
    const restClientInstance = restClient(window.API_ROOT + '/api')
    // TODO: These API interactions should log errors when a non-success HTTP status code is returned. fetch() will
    // pass all status codes to .then(), so they are probably being ignored here.
    restClientInstance('CUSTOM_POST', 'custom', {
      url: 'mfa/clear/',
    })
      .then((res: { data?: { token: string } }) => {
        // Replace our old token with the new token because the old token may now be invalid
        // because it is not MFA verified
        if (res.data?.token) {
          appStorage.setToken(res.data?.token)
        } else {
          console.warn('new token was not updated, unable to replace old token')
        }

        fetchMfaConfig(dispatch, translate, notify, setLoading)
      })
      .catch((err: unknown) => {
        console.warn(err)
        notify('Unable to clear 2FA devices', 'warning')
        logAmplitudeEvent('mfa_clear_error', {})
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const toggleMFA = () => {
    if (isEnabled) {
      setDisableConfirming(true)
    } else {
      goToMode()
    }
  }

  const removeDevice = (strategy: MFAStrategy) => {
    const restClientInstance = restClient(window.API_ROOT + '/api')
    restClientInstance('CUSTOM_DELETE', 'custom', {
      url: `mfa/devices/${strategy}/`,
    })
      .catch((err: any) => {
        console.warn(err)
        notify('Failed to remove Authenticator App', 'warning')
      })
      .finally(() => {
        fetchMfaConfig(dispatch, translate, notify, setLoading)
      })
  }

  return (
    <div>
      {isEnabled ? (
        <p>
          {translate(
            'Your account is already setup with 2FA. If you would like to change your 2FA settings you can do so below.'
          )}
        </p>
      ) : (
        <p>
          {translate(
            '2FA adds an additional layer of security to your account by requiring more than just a password to sign in.'
          )}
        </p>
      )}

      {isEnabled && (
        <div>
          <div className="mfa-device">
            <TotpIcon className={'mfa-device-icon ' + (activeTotp ? 'mfa-device-icon-active' : '')} />
            <span className="mfa-device-inner">
              <h2>{translate('Authenticator App')}</h2>
              <p>
                {activeTotp
                  ? translate('Your Authenticator App is active.')
                  : translate('You have not set up an Authenticator App.')}
              </p>
              <div className="mfa-device-buttons">
                {activeTotp ? (
                  <Button label="Remove" onClick={() => removeDevice('totp')} type="secondary" size="small" />
                ) : (
                  ''
                )}
                <Button
                  label={activeTotp ? 'Replace' : 'Add'}
                  onClick={goToTotp}
                  type="secondary"
                  size="small"
                  disabled={activeTotp}
                />
              </div>
              {activeTotp ? (
                <div className="hint-box">
                  {translate('To replace your Authenticator App, you must remove it first.')}
                </div>
              ) : null}
            </span>
          </div>
          <div className="mfa-device">
            <SmsIcon className={'mfa-device-icon ' + (activeSms ? 'mfa-device-icon-active' : '')} />
            <span className="mfa-device-inner">
              <h2>{translate('SMS')}</h2>
              <p>
                {activeSms
                  ? translate('You have a verified phone number.')
                  : translate('You have not verified a phone number.')}
              </p>
              <div className="mfa-device-buttons">
                {activeSms ? (
                  <Button label="Remove" onClick={() => removeDevice('sms')} type="secondary" size="small" />
                ) : (
                  ''
                )}
                <Button
                  label={activeSms ? 'Replace' : 'Add'}
                  onClick={goToPhone}
                  type="secondary"
                  size="small"
                  disabled={!allowAddOrReplaceSms}
                />
              </div>
            </span>
          </div>
          <div className="mfa-device">
            <CodesIcon className={'mfa-device-icon ' + (activeRecovery ? 'mfa-device-icon-active' : '')} />
            <span className="mfa-device-inner">
              <h2>{translate('Recovery Codes')}</h2>
              <p>
                {activeRecovery
                  ? translate('You have remaining recovery codes remaining.', { remaining: remainingRecovery })
                  : translate('You have not set up recovery codes.')}
              </p>
              <div className="mfa-device-buttons">
                <Button
                  label={activeRecovery ? 'Regenerate' : 'Generate'}
                  onClick={goToRecovery}
                  type="secondary"
                  size="small"
                />
              </div>
            </span>
          </div>
        </div>
      )}
      <div style={{ marginTop: '15px' }}>
        <Button
          label={getButtonTitle()}
          type={isEnabled ? 'warning' : 'secondary'}
          onClick={toggleMFA}
          loading={loading}
          disabled={isEnabled && isEnabledOnOrg}
          fullWidth={true}
        />
        {isEnabledOnOrg && isEnabled && (
          <div className="hint-box" style={{ marginLeft: '5px' }}>
            {translate('2FA is required for your Org, you cannot disable it for your user')}
          </div>
        )}
      </div>

      <div style={{ marginTop: '15px' }}>
        <Button
          label="Back"
          type="text"
          onClick={() => history.push('/configuration')}
          fullWidth={true}
          style={{ width: '100%' }}
        />
      </div>

      <Confirm
        isOpen={disableConfirming}
        loading={loading}
        title="Disable 2FA?"
        content="Disabling 2FA will remove all currently verified authentication methods. Are you sure?"
        onConfirm={clearMfaDevices}
        onClose={() => setDisableConfirming(false)}
      />
    </div>
  )
}
export default MfaStatus

import { Alert } from 'opensolar-ui'
import { FC } from 'react'

export const AlertBasic: FC = () => {
  return (
    <>
      {/* SNIPPET START 1 */}
      <Alert>This is an alert.</Alert>
      {/* SNIPPET END 1 */}
    </>
  )
}

export const AlertSeverities: FC = () => {
  return (
    <>
      {/* SNIPPET START 2 */}
      <Alert severity="info">This is an info alert.</Alert>
      <Alert severity="warning">This is a warning alert.</Alert>
      <Alert severity="error">This is an error alert.</Alert>
      <Alert severity="success">This is a success alert.</Alert>
      {/* SNIPPET END 2 */}
    </>
  )
}

export const AlertVariants: FC = () => {
  return (
    <>
      {/* SNIPPET START 3 */}
      <Alert severity="info" variant="standard">
        This is an info alert.
      </Alert>
      <Alert severity="info" variant="outlined">
        This is an info alert.
      </Alert>
      <Alert severity="info" variant="filled">
        This is an info alert.
      </Alert>
      {/* SNIPPET END 3 */}
    </>
  )
}

import { makeStyles, MenuItem, Select } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import _ from 'lodash'
import { useTranslate } from 'ra-core'
import { useEffect, useMemo } from 'react'
import { Field, useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { NORMAL_CONTACT } from 'types/contacts'
import { handleImplicitToggles } from 'util/misc'
import NavigationMobile from './NavigationMobile'

const styles = makeStyles({
  select: {
    background: '#F0F0F0',
    height: '30px',
    fontSize: 12,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
})

const StageField = ({ layout }) => {
  const form = useForm()
  const classes = styles()
  const translate = useTranslate()
  const formValues = useFormState().values
  const orgWorkflows = useSelector(orgSelectors.getWorkflows)
  const stages = useMemo(() => {
    const workflow = orgWorkflows.find((x) => x.id === formValues.workflow?.workflow_id)
    return workflow?.workflow_stages
      ?.filter((x) => !x.is_archived || x.id === formValues.workflow?.active_stage_id)
      ?.sort((a, b) => a.order - b.order)
  }, [formValues.workflows, orgWorkflows, formValues.workflow])
  const handleChange = (active_stage_id) => {
    if (active_stage_id) {
      const workflow = formValues.workflows?.find((x) => x.id === formValues.workflow?.id)
      if (workflow)
        handleImplicitToggles(
          { ...workflow, active_stage_id: active_stage_id, workflow_stages: stages },
          formValues,
          form
        )
    }
  }
  useEffect(() => {
    form.registerField('project_sold', () => {}, {})
    form.registerField('contract_date', () => {}, {})
    form.registerField('project_installed', () => {}, {})
    form.registerField('installation_date', () => {}, {})
  }, [])

  return (
    <Field name={'workflow.active_stage_id'}>
      {(props) => {
        const updatedInputProps = {
          ...props.input,
          onChange: (e) => {
            props.input.onChange(e)
            handleChange(e.target.value)
          },
        }
        return (
          <Select
            className={classes.select}
            style={{ margin: layout > 3 ? '0 10px' : '0 5px' }}
            variant="outlined"
            {...updatedInputProps}
          >
            {stages &&
              stages.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.title + (option.is_archived ? ` (${translate('archived')})` : '')}
                </MenuItem>
              ))}
          </Select>
        )
      }}
    </Field>
  )
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    maxWidth: 250,
    [theme.breakpoints.down('lg')]: {
      maxWidth: 120,
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 100,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 150,
    },
  },
  info: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

const ProjectSummary = (props) => {
  const form = useForm()
  const projectDetail = form.getState().values
  const classes = useStyles()
  const businessName = projectDetail.is_residential === false && projectDetail.business_name
  const projectId = projectDetail.id
  const firstContact = projectDetail?.contacts_data
    ? projectDetail.contacts_data.find((c) => c?.type === NORMAL_CONTACT)
    : null
  const name = businessName || (firstContact && firstContact.display + ',')
  const address = projectDetail.address
  return (
    <>
      <div className={classes.wrapper} style={{ maxWidth: props.layout === 2 ? 100 : null }}>
        <div
          className={classes.info}
          style={{ fontSize: props.layout === 3 || props.layout === 2 ? 12 : 14 }}
          onClick={() => {
            const dirtyFields = form.mutators.getFormDirtyFields()
            const values = form.getState().values
            console.log(values)
            const submitValue = _.pick(values, dirtyFields)
            console.log(submitValue)
          }}
        >
          {name} {address}
        </div>
      </div>
      {props.layout >= 2 && <StageField projectId={projectId} layout={props.layout} />}
      {props.layout === 1 && <NavigationMobile projectId={projectId} layout={props.layout} />}
    </>
  )
}

export default ProjectSummary

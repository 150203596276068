import { Tooltip } from '@material-ui/core'
import { AddOutlined, DeleteOutlineOutlined, EditOutlined, InfoOutlined, MailOutlineOutlined } from '@material-ui/icons'
import Group from '@material-ui/icons/GroupOutlined'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { permissionsSelectors } from 'ducks/permissions'
import Alert from 'elements/Alert'
import TinyBox from 'elements/TinyBox'
import { TinyLabel } from 'elements/TinyLabel'
import WithPermissionsCheck from 'elements/WithPermissionsCheck'
import _ from 'lodash'
import { Button, IconButton } from 'opensolar-ui'
import Confirm from 'projectSections/elements/dialog/Confirm'
import React, { useEffect, useMemo, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { ConnectedOrgBrief } from 'types/orgs'
import { ProjectType } from 'types/projects'
import AccordionCard from '../AccordionCard'
import OrgSharingDialog from './OrgSharingDialog'
import { useStyles } from './styles'
import { checkMultipleCountries, getNotifyEmails, openMailTo } from './util'

const OrgSharingConnectionContent = ({ ...props }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const notify = useNotify()
  const orgId = useSelector(authSelectors.getOrgId)
  const orgName = useSelector(orgSelectors.getOrg)?.name || ''
  const connectedOrgs = useSelector(orgSelectors.getConnectedOrgs)

  //get project owner
  const formState = useFormState()
  const project = formState.values as ProjectType
  const [ownerOrg, setOwnerOrg] = useState<ConnectedOrgBrief | undefined>()

  useEffect(() => {
    setOwnerOrg(connectedOrgs.find((o) => o.org_id === project.org_id))
  }, [connectedOrgs, project])

  return (
    <div className={classes.orgSharingContainer}>
      <TinyBox
        tagline={translate('Project Owner')}
        title={ownerOrg?.org_name || ''}
        actions={
          <>
            <IconButton
              onClick={() => {
                if (!ownerOrg || orgId === undefined) return
                getNotifyEmails(orgId)
                  .then((res) => {
                    openMailTo(res.data, ownerOrg.org_id, project, orgName, notify)
                  })
                  .catch((err) => {
                    notify('Failed to retrieve connected org data.', 'warning')
                  })
              }}
            >
              <MailOutlineOutlined />
            </IconButton>
            <Tooltip title={translate('This Organisation has shared this project with your own Organisation.')}>
              <InfoOutlined className={classes.infoIcon} />
            </Tooltip>
          </>
        }
        fullWidth={true}
      />
      <CountryCurrencyNotice
        sharedWith={formState.values?.shared_with}
        connectedOrgs={props.connectedOrgs}
        projectOwnerId={ownerOrg?.org_id}
      />
    </div>
  )
}

const OrgSharingOwnerContent = ({ ...props }) => {
  const form = useForm()
  const formState = useFormState()
  const notify = useNotify()
  const classes = useStyles()
  const translate = useTranslate()
  const orgId = useSelector(authSelectors.getOrgId)
  const project = formState.values as ProjectType
  const orgName = useSelector(orgSelectors.getOrg)?.name || ''
  //dialog funcs
  const [showDialog, setShowDialog] = useState(false)
  const [dialogIndex, setDialogIndex] = useState(0)
  const toggleDialog = (index, toggle) => {
    setDialogIndex(index)
    setShowDialog(toggle)
  }
  const shareOrgPermission = useSelector(permissionsSelectors.getProjectPermissionByKey('info_sharing'))
  const originalState = useMemo(() => _.cloneDeep(project?.shared_with), [])
  const sharedWith = project?.shared_with
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const toggleDeleteDialog = (index, toggle) => {
    setDialogIndex(index)
    setShowDeleteDialog(toggle)
  }

  const handleClear = (index: number) => {
    let sharedWith: unknown[] | undefined = form.getState().values['shared_with']
    if (!sharedWith) return
    sharedWith = [...sharedWith]
    sharedWith?.splice(index, 1)
    form.mutators.markFieldAsDirty('shared_with')
    form.mutators.updateField('shared_with', sharedWith)
    setShowDeleteDialog(false)
  }

  const handleClose = (saving) => {
    if (!saving) {
      const dirtyFields = form.mutators.getFormDirtyFields()
      if (dirtyFields.some((X) => X.includes('shared_with'))) {
        form.mutators.updateField('shared_with', originalState)
      }
    }
    setShowDialog(false)
  }

  return (
    <div className={classes.orgSharingContainer}>
      <TinyBox tagline={translate('Project Owner')} title={orgName} fullWidth={true} />
      {sharedWith && sharedWith?.length > 0 ? (
        <>
          {sharedWith?.map((connection: any, connectIndex: number) => {
            return (
              <TinyBox
                tagline={translate('Shared with organisation')}
                title={connection.org_name}
                actions={
                  <>
                    <Tooltip title={translate('Email this organisation')}>
                      <IconButton
                        onClick={() => {
                          if (orgId === undefined) return
                          getNotifyEmails(orgId)
                            .then((res) => {
                              openMailTo(res.data, sharedWith[0].org_id, project, orgName, notify)
                            })
                            .catch((err) => {
                              notify('Failed to retrieve connected org data.', 'warning')
                            })
                        }}
                      >
                        <MailOutlineOutlined />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={translate('Edit')}>
                      <IconButton
                        onClick={() => toggleDialog(connectIndex, true)}
                        disabled={!shareOrgPermission.allowEdit}
                      >
                        <EditOutlined />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={translate('Delete')}>
                      <IconButton
                        onClick={() => toggleDeleteDialog(connectIndex, true)}
                        disabled={!shareOrgPermission.allowDelete}
                      >
                        <DeleteOutlineOutlined />
                      </IconButton>
                    </Tooltip>
                  </>
                }
                fullWidth={true}
              >
                <TinyLabel label={translate('Visibility:')}>
                  {connection.is_shared ? translate('Shown') : translate('Hidden')}
                </TinyLabel>
                {(connection.permission_role_title && (
                  <TinyLabel label={translate('Permission:')}>{connection.permission_role_title}</TinyLabel>
                )) || <TinyLabel label={translate('Permission:')}>{translate('Full Access')}</TinyLabel>}
              </TinyBox>
            )
          })}
          <CountryCurrencyNotice sharedWith={sharedWith} connectedOrgs={props.connectedOrgs} />
        </>
      ) : (
        <>
          <p>{translate('This project is not being shared with any connected organisation.')}</p>
        </>
      )}
      {!(sharedWith && sharedWith?.length > 0) && (
        <Button
          fullWidth={true}
          variant="contained"
          onClick={() => toggleDialog(0, true)}
          disabled={!shareOrgPermission.allowCreate}
          startIcon={<AddOutlined />}
        >
          {translate('Share project')}
        </Button>
      )}
      <OrgSharingDialog
        index={dialogIndex}
        connectedOrgs={props.connectedOrgs}
        isOpen={showDialog}
        close={(saving = false) => handleClose(saving)}
        projectId={project.id}
      />
      <Confirm
        open={showDeleteDialog}
        header={{ title: translate('Remove Project Access') }}
        content={translate('Would you like to remove project access for %{org_name}?', {
          org_name: sharedWith ? sharedWith[dialogIndex]?.org_name : '',
        })}
        buttons={[
          {
            children: 'Cancel',
            onClick: () => setShowDeleteDialog(false),
          },
          {
            color: 'primary',
            children: 'Continue',
            onClick: () => handleClear(dialogIndex),
          },
        ]}
      />
    </div>
  )
}

const OrgSharingAccordion = ({ ...props }) => {
  const translate = useTranslate()
  const formState = useForm().getState()
  const project = formState.values as ProjectType
  //check if project owner
  const userOrgId = useSelector(authSelectors.getCurrentRole)?.org_id
  const isProjectOwner = useMemo(() => userOrgId === project.org_id, [userOrgId, project.org_id])

  return (
    <WithPermissionsCheck permissionToCheck="info_sharing" permissions={['allowView']}>
      <AccordionCard
        {...props}
        name={'connected_org_sharing'}
        title={translate('Teams')}
        titleIcon={Group}
        content={isProjectOwner ? OrgSharingOwnerContent : OrgSharingConnectionContent}
        defaultExpanded={true}
      />
    </WithPermissionsCheck>
  )
}

const CountryCurrencyNotice = ({ sharedWith, connectedOrgs, ...props }) => {
  const translate = useTranslate()
  const orgCountry = useSelector(orgSelectors.getOrg)?.country.iso2
  const [showCurrencyNotice, setCurrencyNotice] = useState(false)

  useEffect(() => {
    setCurrencyNotice(checkMultipleCountries(orgCountry, sharedWith, connectedOrgs, props.projectOwnerId))
  }, [connectedOrgs, props])

  if (showCurrencyNotice) {
    return (
      <Alert severity="warning">
        {translate(
          'The selected Organisations are from different countries. Please be sure that their prices are specified in the same currency.'
        )}
      </Alert>
    )
  } else {
    return <></>
  }
}

export default OrgSharingAccordion

import Step from '@material-ui/core/Step'
import StepConnector from '@material-ui/core/StepConnector'
import { StepIconProps } from '@material-ui/core/StepIcon'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import { withStyles } from '@material-ui/core/styles'
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined'
import classnames from 'classnames'

import React, { useMemo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { doNotTranslate } from 'util/misc'

const useStyles = makeOpenSolarStyles((theme) => ({
  stepper: {
    padding: '10px 0',
  },
}))

const useStepIconStyles = makeOpenSolarStyles((theme) => ({
  root: {
    backgroundColor: '#D6D6D6',
    zIndex: 1,
    color: '#fff',
    width: 36,
    height: 36,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    color: '#fff',
    backgroundColor: '#03A9EA',
    border: 'none',
  },
  completed: {
    color: '#fff',
    backgroundColor: '#03A9EA',
    border: 'none',
  },
}))

const StepIcon = (props: StepIconProps) => {
  const { active, completed } = props
  const classes = useStepIconStyles()
  return (
    <div
      className={classnames(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      <CheckOutlinedIcon />
    </div>
  )
}

const Connector = withStyles({
  alternativeLabel: {
    top: 18, // half of Step Icon
  },
  active: {
    '& $line': {
      backgroundColor: '#03A9EA',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#03A9EA',
    },
  },
  line: {
    height: 5,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector)

const LyraIntegrationStepper = () => {
  const steps = useMemo(() => ['PROPOSAL', 'DESIGN', 'PERMIT'], [])
  const classes = useStyles()
  return (
    <Stepper classes={{ root: classes.stepper }} alternativeLabel connector={<Connector />} activeStep={1}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel StepIconComponent={StepIcon}>{doNotTranslate(label)}</StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

export default LyraIntegrationStepper

import { InputAdornment, TextField } from '@material-ui/core'
import { NumeralThousandGroupStyles, formatNumeral, registerCursorTracker, unformatNumeral } from 'cleave-zen'
import _ from 'lodash'
import React, { CSSProperties, ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { CURRENCY_TO_DECIMAL_PLACES } from './constants'

type PropTypes = {
  input: {
    name: string
    source?: string
    label?: string
  }
  style?: CSSProperties
  disabled?: boolean
  initialValue?: number | string | null | undefined
  fullWidth?: boolean
  variant?: 'filled' | 'outlined' | 'standard' | undefined
  formatAsCurrency: boolean
  currencySymbol: string
  locale?: string
  allowNegative?: boolean
}

const FALLBACK_LOCALE = 'en-US'

const LocalizedCurrencyInput: React.FC<PropTypes> = (props) => {
  const thousandGroupStyle = useMemo(() => {
    let thousandGroup: NumeralThousandGroupStyles = NumeralThousandGroupStyles.THOUSAND
    if (['₹', 'rs.', 'Rs.'].includes(props.currencySymbol)) {
      // indian currency
      thousandGroup = NumeralThousandGroupStyles.LAKH
    } else if (['¥', 'CN¥'].includes(props.currencySymbol)) {
      // chinese currency
      thousandGroup = NumeralThousandGroupStyles.WAN
    }
    return thousandGroup
  }, [])

  const localeToUse = useMemo(() => {
    if (props.locale) return props.locale
    return window.locale || FALLBACK_LOCALE
  }, [props.locale])

  const thousandsSeperator = useMemo(() => {
    let n: number | string = 1000.1
    n = n.toLocaleString(localeToUse).substring(1, 2)
    return n
  }, [])

  const decimalSeperator = useMemo(() => {
    let n: number | string = 1.1
    n = n.toLocaleString(localeToUse).substring(1, 2)
    return n
  }, [])

  const getFormattedValue = (val) => {
    if (!val) return val
    const formatted = formatNumeral(`${val}`, {
      numeralThousandsGroupStyle: thousandGroupStyle,
      numeralPositiveOnly: !props.allowNegative,
      tailPrefix: props.currencySymbol === '€',
      stripLeadingZeroes: true,
      numeralDecimalMark: decimalSeperator,
      delimiter: thousandsSeperator,
      numeralDecimalScale: props.currencySymbol ? CURRENCY_TO_DECIMAL_PLACES[props.currencySymbol] : 0,
    })
    return formatted
  }

  const formState = useFormState()
  const [value, setValue] = useState<string | undefined>(
    getFormattedValue(_.get(formState.values, props.input.name, undefined))
  )

  const inputRef = useRef(null)
  const form = useForm()

  useEffect(() => {
    // @ts-ignore
    return registerCursorTracker({ input: inputRef.current, delimiter: thousandsSeperator })
  }, [])

  useEffect(() => {
    const newValue = _.get(formState.values, props.input.name)
    let formattedNewValue = getFormattedValue(newValue)

    if (formattedNewValue !== value) {
      // there is a tricky scenario where the last char in value is a decimalSeperator
      // in that scenario the numeric values are identical but the string values are not
      // so we don't want to update value state and we let that decimalSeperator remain at the the end of the
      // string. Otherwise you wouldn't be able to add decimals to an int becuase the decimalSeperator would
      // just automatically be cleared out. So we compare the two values as numbers to see if there's a difference
      if (
        value === undefined ||
        newValue !== parseFloat(unformatNumeral(value, { numeralDecimalMark: decimalSeperator }))
      ) {
        setValue(formattedNewValue || '')
      }
    }

    // Ensure it has two decimal places if last decimal place is a 0
    if (typeof formattedNewValue === 'string' && formattedNewValue.includes('.')) {
      formattedNewValue = parseFloat(formattedNewValue.replace(/,/g, '')).toFixed(2)
      formattedNewValue = formattedNewValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      setValue(formattedNewValue || '')
    }
  }, [formState.values, value])

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const formatted: string = getFormattedValue(e.target.value)
    setValue(formatted)

    // udpate the form with the numeric value
    const numeric = parseFloat(
      unformatNumeral(formatted, {
        numeralDecimalMark: decimalSeperator,
      })
    )
    form.change(props.input.name, numeric)
  }
  return (
    <TextField
      label={props.input.label}
      value={value}
      name={props.input.name}
      disabled={props.disabled}
      style={props.style}
      fullWidth={props.fullWidth}
      variant={props.variant || 'outlined'}
      onChange={onChange}
      size="small"
      // @ts-ignore
      ref={inputRef}
      InputProps={{
        startAdornment: <InputAdornment position="start">{props.currencySymbol}</InputAdornment>,
      }}
    />
  )
}
export default LocalizedCurrencyInput

import { ConditionFactory, SystemCondition, SystemContext } from 'Designer/designRules/types'
import { BaseConditionDef, SystemConditionDef } from '.'
import { nested_condition } from './nested_condition'

export type OrConditionDef = BaseConditionDef & {
  type: 'or'
  conditions: SystemConditionDef[]
}

export const condition_or: ConditionFactory<OrConditionDef> = (def: OrConditionDef): SystemCondition => {
  const { subConditions, ...nested } = nested_condition(def)
  return {
    ...nested,
    check: (context: SystemContext) => {
      return subConditions.some((c) => context.runCondition(c.condition, c.def))
    },
  }
}

import Alert from 'elements/Alert'
import React from 'react'
import { BooleanInput, NumberInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import DependentInput from '../../../elements/input/DependentInput'
import { toggle_inputs_and_extra_fields } from '../common'
import { styles } from '../styles.js'

export const PromotionalPeriod = ({ resource, disabled = false }) => {
  const form = useForm()
  const formState = useFormState()
  const isVariablePromotionEnabled = formState.values['variable_promotion_enabled']
  return (
    <div style={Boolean(isVariablePromotionEnabled) ? styles.loanWrapperWithBoxShadow : styles.loanWrapper}>
      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', height: 50 }}>
        <BooleanInput
          resource={resource}
          formControlLabelStyle={styles.formControlLabelStyle}
          onChange={(value) => {
            if (!value) {
              const selectedToggle =
                toggle_inputs_and_extra_fields.find((a) => a.key === 'variable_promotion_enabled') || {}
              const extraFields = selectedToggle.extraFields || []
              extraFields.forEach((field) => {
                form.mutators.updateField(field, null)
              })
            }
          }}
          label="Promotional Period"
          name="variable_promotion_enabled"
          source="variable_promotion_enabled"
          disabled={disabled}
        />
        <DependentInput dependsOn="variable_promotion_enabled" resolve={(value) => !value}>
          <i style={styles.toggleInputHint}>e.g. No Payment or Interest Only</i>
        </DependentInput>
      </div>
      <DependentInput dependsOn="variable_promotion_enabled" value={true}>
        <i style={styles.toggleInputHint}>e.g. No Payment or Interest Only</i>
        <p>
          If loan will begin with a "No Payment", "Interest Only" or "No Interest" period, enter term below (in months)
        </p>
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <NumberInput
            step={1}
            resource={resource}
            style={styles.inputFieldMediumWide}
            label={'No Interest Term'}
            endAdornment="months"
            source={'variable_no_interest_period'}
            name={'variable_no_interest_period'}
            disabled={disabled}
          />
          <NumberInput
            step={1}
            resource={resource}
            style={styles.inputFieldMediumWide}
            label="No Payment Term"
            endAdornment="months"
            source={'variable_no_payment_period'}
            name={'variable_no_payment_period'}
            disabled={disabled}
          />
          <NumberInput
            step={1}
            resource={resource}
            style={styles.inputFieldMediumWide}
            label={'Interest Only Term'}
            endAdornment="months"
            source={'variable_interest_only_period'}
            name={'variable_interest_only_period'}
            disabled={disabled}
          />
        </div>
        <Alert severity="info">
          Any "No Payment" Term will precede any "Interest Only" term. "No Interest" terms start in first month and
          occur in parallel to any "No Payment" and "Interest Only" terms. If there is a Prepayment in addition to a No
          Payment and/or Interest Only period, the No Payment and Interest Only periods must end before the Prepayment
          can be due.
        </Alert>
      </DependentInput>
    </div>
  )
}

import { JsonFormsDebuggable } from 'elements/jsonForms/JsonFormsDebuggable'
import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-final-form'
import { HostedDisclosureType } from '../types'
import { FormJSONType } from './HostedCreditApplicationDialog'

type PropTypes = {
  onClose: () => void
  integration: string
  disclosures: HostedDisclosureType[]
  form_json?: FormJSONType | null
  initialValues: object
}

const HostedJSONSchemaCreditApp: React.FC<PropTypes> = (props) => {
  const [data, setData] = useState<object>(props.initialValues)
  const form = useForm()

  const onChange = (newFormVals, errors) => {
    setData(newFormVals)
    form.reset({ ...newFormVals, disclosures: props.disclosures })
  }

  const formSchemaWithDisclosures = useMemo(() => {
    if (!props.disclosures) return props.form_json?.schema
    let updatedSchema = _.cloneDeep(props.form_json?.schema)
    props.disclosures.forEach((disc) => {
      // @ts-ignore
      updatedSchema.properties[`applicants[0].disclosures.${disc.id}`] = {
        type: 'object',
        properties: {
          ExpandableTerms: {
            // @ts-ignore
            name: disc.name,
            // @ts-ignore
            text: disc.text,
            // @ts-ignore
            id: disc.id,
            // @ts-ignore
            requireConsent: disc.requireConsent,
          },
        },
      }
      //@ts-ignore
      updatedSchema.properties[`applicants[1].disclosures.${disc.id}`] = {
        type: 'object',
        properties: {
          ExpandableTerms: {
            // @ts-ignore
            name: disc.name,
            // @ts-ignore
            text: disc.text,
            // @ts-ignore
            id: disc.id,
            // @ts-ignore
            requireConsent: disc.requireConsent,
          },
        },
      }
    })
    return updatedSchema
  }, [props.form_json?.schema, props.disclosures])

  const uiSchemaWithDisclosures = useMemo(() => {
    if (!props.disclosures) return props.form_json?.uiSchema
    let updatedUiSchema = _.cloneDeep(props.form_json?.uiSchema)

    let disclosuresElementsPrimary = props.disclosures.map((disc) => {
      return {
        type: 'ExpandableTerms',
        //@ts-ignore
        scope: `#/properties/applicants[0].disclosures.${disc.id}`,
        label: 'I agree to the terms and conditions',
      }
    })

    const disclosureLayoutPrimary = {
      type: 'VerticalLayout',
      elements: disclosuresElementsPrimary,
    }
    // @ts-ignore
    updatedUiSchema.elements[0].elements.push(disclosureLayoutPrimary)

    let disclosuresElementsSecondary = props.disclosures.map((disc) => {
      return {
        type: 'ExpandableTerms',
        //@ts-ignore
        scope: `#/properties/applicants[1].disclosures.${disc.id}`,
        label: 'I agree to the terms and conditions',
      }
    })

    const disclosureLayoutSecondary = {
      type: 'VerticalLayout',
      elements: disclosuresElementsSecondary,
    }

    // @ts-ignore
    updatedUiSchema.elements[2].elements.push(disclosureLayoutSecondary)

    return updatedUiSchema
  }, [props.form_json?.uiSchema, props.disclosures])
  if (!formSchemaWithDisclosures || !uiSchemaWithDisclosures) return null
  return (
    <div>
      <JsonFormsDebuggable
        schema={formSchemaWithDisclosures}
        uischema={uiSchemaWithDisclosures}
        data={data}
        onChange={onChange}
      />
    </div>
  )
}
export default HostedJSONSchemaCreditApp

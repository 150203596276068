import Fade from '@material-ui/core/Fade'
import _ from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { SpotlightPropsType } from '../types'
import usePosition from './usePosition'

const Spotlight = (props: SpotlightPropsType) => {
  const {
    padding = 10,
    targetId,
    maxWait,
    index,
    disableBackgroundScroll = false,
    handleHover,
    keepObserve,
    autoScroll = false,
  } = props
  const position: any = usePosition(targetId, maxWait, undefined, keepObserve)
  const { step } = useSelector((state: any) => state.tour)
  const positionRef = useRef(position)
  positionRef.current = position
  const handleMouseMove = useCallback((e: any) => {
    let isMoveOverOnSpotlight = false
    if (!positionRef.current) {
      isMoveOverOnSpotlight = false
    } else {
      const offsetY = e.clientY
      const offsetX = e.clientX
      const inSpotlightHeight = offsetY >= positionRef.current?.y && offsetY <= positionRef.current?.bottom
      const inSpotlightWidth = offsetX >= positionRef.current?.x && offsetX <= positionRef.current?.right
      isMoveOverOnSpotlight = inSpotlightWidth && inSpotlightHeight
    }
    handleHover(targetId, isMoveOverOnSpotlight)
  }, [])

  const [showFade, setShowFade] = useState(false)

  useEffect(() => {
    const fadeDelay = setTimeout(() => setShowFade(true), 200)
    return () => clearTimeout(fadeDelay)
  }, [position])

  const disableScroll = useCallback(() => {
    const rootDom = window.document.body
    if (rootDom && rootDom.style.overflow !== 'hidden') {
      rootDom.style.overflow = 'hidden'
    }
  }, [])
  const enableScroll = useCallback(() => {
    const rootDom = window.document.body
    if (rootDom) {
      rootDom.style.cssText = ''
    }
  }, [])

  useEffect(() => {
    if (disableBackgroundScroll) {
      disableScroll()
    }
  })

  useEffect(() => {
    const handleMouseMoveDebounced = _.throttle(handleMouseMove, 200, { trailing: true, leading: true })
    window.addEventListener('mousemove', handleMouseMoveDebounced, false)

    return () => {
      window.removeEventListener('mousemove', handleMouseMoveDebounced)
      if (disableBackgroundScroll) {
        enableScroll()
      }
    }
  }, [])

  useEffect(() => {
    if (autoScroll) {
      const spotlightElement = document.getElementById(targetId)

      if (spotlightElement) {
        const spotlightOffsetTop = spotlightElement.offsetTop + 10
        const spotlightOffsetLeft = spotlightElement.offsetLeft - padding

        window.scrollTo({
          top: spotlightOffsetTop - 40,
          left: spotlightOffsetLeft,
          behavior: 'auto',
        })
      }
    }
  }, [targetId, step])

  return position ? (
    <Fade in={showFade} mountOnEnter unmountOnExit>
      <div
        style={{
          pointerEvents: 'inherit',
          width: position.width + 2 * padding,
          height: position.height + 2 * padding,
          position: 'absolute',
          top: position.y - padding,
          left: position.x - padding,
          transition: 'opacity 0.2s ease 0s',
          backgroundColor: 'grey',
          borderRadius: 4,
        }}
      />
    </Fade>
  ) : null
}

export default Spotlight

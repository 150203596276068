import { Grid, LinearProgress } from '@material-ui/core'
import type { OpenSolarThemeType } from 'Themes'
import BOMLineItemRow from 'pages/inventory/forecast/modal/BOMLineItemRow'
import LineItemType from 'pages/ordering/OrderLineItem'
import ProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/projectOrderPresenter'
import useProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/useProjectOrderPresenter'
import type { FlowControllerType } from 'pages/ordering/replaceItem/types'
import { memo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import useReplaceItemFlow from '../../replaceItem/useReplaceItemFlow'
import LineItemRow from './LineItemRow'
import getGroupedOrders from './getGroupedOrders'

export type OrderTableLocationType = {
  isBOMTable: boolean
  isBOMReviewTable: boolean
}

const getProjectsRequiredQuantity = (lineItem: LineItemType) => {
  return lineItem.projectOrder?.reduce((sum, current) => sum + current.requiredQuantity, 0) || 0
}

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  warning: {
    marginTop: 10,
    color: theme.warningYellow,
    lineHeight: '16px',
  },
}))

const OrderTableContent = ({
  lineItems,
  editable,
  trackSource,
  location,
}: {
  lineItems: LineItemType[]
  editable: boolean
  trackSource?: string
  location?: OrderTableLocationType
}) => {
  const { flowController: replaceItemFlowController, Dialog: ReplaceItemDialog } = useReplaceItemFlow()
  return (
    <div>
      {lineItems && lineItems.length > 0 && (
        <LineItemsRows
          editable={editable}
          lineItems={lineItems}
          replaceItemFlowController={replaceItemFlowController}
          trackSource={trackSource}
          location={location}
        />
      )}
      {ReplaceItemDialog}
    </div>
  )
}

const LineItemsRows = ({
  lineItems,
  replaceItemFlowController,
  editable,
  trackSource,
  location,
}: {
  lineItems: LineItemType[]
  replaceItemFlowController: FlowControllerType
  editable: boolean
  trackSource?: string
  location?: OrderTableLocationType
}) => {
  const classes = useStyles()
  const orderingPresenter = useProjectOrderPresenter()
  const groupedLineItems = getGroupedOrders(lineItems)

  return (
    <>
      {groupedLineItems.map((lineItems: LineItemType[], i) => {
        const groupedLineItemsQuantity = ProjectOrderPresenter.getLineItemsTotalQuantity(lineItems)
        const projectRequiredQuantity = getProjectsRequiredQuantity(lineItems[0])
        const NeedMoreMessage =
          projectRequiredQuantity > groupedLineItemsQuantity ? (
            <div className={classes.warning}>{projectRequiredQuantity} needed</div>
          ) : null
        return lineItems
          .sort((a, b) => {
            if (a.quantityPerItem == null) {
              return -1
            }
            if (b.quantityPerItem == null) {
              return 1
            }
            return a.quantityPerItem - b.quantityPerItem
          })
          .map((lineItem: LineItemType, index: number) => (
            <>
              {location?.isBOMTable || location?.isBOMReviewTable ? (
                <BOMLineItemRow
                  key={lineItem.code + index}
                  lineItem={lineItem}
                  editable={editable}
                  orderingPresenter={orderingPresenter}
                  location={location}
                />
              ) : (
                <LineItemRow
                  replaceItemFlowController={replaceItemFlowController}
                  key={lineItem.code + index}
                  lineItem={lineItem}
                  groupedLineItemsQuantity={groupedLineItemsQuantity}
                  isFirstItem={index === 0}
                  isLastItem={index === lineItems.length - 1}
                  editable={editable}
                  orderingPresenter={orderingPresenter}
                  NeedMoreMessage={NeedMoreMessage}
                  trackSource={trackSource}
                />
              )}
              {lineItems.some((lineItem) => lineItem.status === 'loading') && (
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              )}
            </>
          ))
      })}
    </>
  )
}

export default memo(OrderTableContent)

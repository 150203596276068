import appStorage from '../storage/appStorage'

export const getWebSocketToken = async (orgId) => {
  const response = await fetch(`${window.API_ROOT}/api/orgs/${orgId}/get_ws_token/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + appStorage.getToken(),
    },
  })
  const data = await response.json()
  let tokenData = data.token
  // appStorage.setItem('webSocketToken', tokenData)
  return tokenData
}

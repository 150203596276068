import { Tooltip } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import { IconButton } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import type { ComponentTypes } from 'types/selectComponent'
import { LABELS_FOR_COMPONENT_TYPES } from './HardwareSelector'

const useStyles = makeOpenSolarStyles((theme) => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 20,
    fontWeight: 'bold',
  },
  header: {
    padding: '8px 12px 8px',
  },
}))

const HardwareSelectionHeader = ({
  handleClose,
  componentType,
}: {
  handleClose(): void
  componentType: ComponentTypes
}) => {
  const translate = useTranslate()
  const classes = useStyles()
  return (
    <div id="hardware-selector-header" className={classes.header}>
      <div className={classes.title}>
        <span>
          {translate('Select')} {translate(LABELS_FOR_COMPONENT_TYPES[componentType]?.singular)}
        </span>
        <IconButton id="SelectComponentDialogClose" onClick={handleClose}>
          <Tooltip title={translate('Close Dialog')} enterDelay={300}>
            <CloseIcon style={{ color: '#000000' }} />
          </Tooltip>
        </IconButton>
      </div>
    </div>
  )
}

export default HardwareSelectionHeader

import React from 'react'
import { connect } from 'react-redux'
import { useTranslate } from 'react-admin'
import { Tooltip } from '@material-ui/core'

const defaultContainerStyle = { margin: '20px 0' }

const COMPONENT_TYPE_AND_INDEX_MAPPER = {
  module: 0,
  inverter: 1,
  battery: 2,
  other: 3,
}

const EmptyLogo = ({ key, name = '' }) => (
  <div
    key={key}
    style={{
      height: 102,
      width: 136,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      padding: '0 10px',
      boxSizing: 'border-box',
      background: 'rgb(77,77,77)',
      verticalAlign: 'middle',
      borderRadius: 8,
      color: 'rgb(255,255,255)',
    }}
  >
    <span>{name}</span>
  </div>
)

const ENABLE_ON_CLICK = false

const ExhibitorShowcase = ({ exhibitor, handleClick }) => (
  <Tooltip title={exhibitor.name} enterDelay={300}>
    <div
      key={exhibitor.id}
      style={{
        height: 102,
        width: 136,
        display: 'inline-block',
        margin: 15,
        lineHeight: '102px',
        borderRadius: 8,
        cursor: ENABLE_ON_CLICK ? 'pointer' : 'unset',
      }}
      onClick={ENABLE_ON_CLICK ? () => handleClick(exhibitor.name) : undefined}
      className="exhibitor-showcase"
    >
      {exhibitor.logo_public_url ? (
        <img
          src={exhibitor.logo_public_url}
          style={{
            maxHeight: 102,
            maxWidth: 136,
            margin: 'auto 0px',
            verticalAlign: 'middle',
            borderRadius: 8,
          }}
        />
      ) : (
        <EmptyLogo key={exhibitor.id} name={exhibitor.name} />
      )}
    </div>
  </Tooltip>
)

const ExhibitedShowcase = ({ handleClick, componentType, exhibitors, containerStyle }) => {
  const translate = useTranslate()
  const componentTypeIndex = COMPONENT_TYPE_AND_INDEX_MAPPER[componentType]
  const availableExhibitors = exhibitors.filter((exhibitor) => exhibitor.component_types.includes(componentTypeIndex))

  if (isNaN(componentTypeIndex) || availableExhibitors.length === 0) return null

  return (
    <div style={containerStyle}>
      <h4 style={{ marginTop: 0, marginBottom: 0 }}>{translate('Exhibiting Manufacturers')}</h4>
      <p>
        {translate(
          'Rich content such as videos and product spec sheets can be automatically included in your proposals when you use hardware from these manufacturers.'
        )}
      </p>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {availableExhibitors.map((exhibitor) => (
          <ExhibitorShowcase exhibitor={exhibitor} handleClick={handleClick} />
        ))}
      </div>
    </div>
  )
}

ExhibitedShowcase.defaultProps = {
  handleClick: () => {},
  containerStyle: defaultContainerStyle,
}

export default connect(
  (state) => ({
    exhibitors: (state.auth && state.auth.exhibitors) || [],
  }),
  {}
)(ExhibitedShowcase)

import React from 'react'

const PartnershipLogo = ({ firstLogoUrl, secondLogoUrl }) => {
  return (
    <div style={{ width: 200, margin: 'auto', minHeight: 100 }}>
      <div style={{ textAlign: 'center' }}>
        <img src={firstLogoUrl} style={{ maxWidth: 200, maxHeight: 100, margin: '10px auto 10px auto' }} />
      </div>
      <hr style={{ borderTop: '1px solid #B3B3B3', margin: 5 }} />
      <div style={{ textAlign: 'center', margin: 'auto', minHeight: 100 }}>
        <img src={secondLogoUrl} style={{ maxWidth: 200, maxHeight: 100, margin: '10px auto 10px auto' }} />
      </div>
    </div>
  )
}

export default PartnershipLogo

import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import AlertError from '@material-ui/icons/ErrorOutline'
import ContentSave from '@material-ui/icons/Save'
import { useConflictedSlots, useFullyUsedSlots } from 'elements/scheduleDialog/hooks'
import { Button } from 'opensolar-ui'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTranslate } from 'react-admin'
import DateRangeSelector from './DateRangeSelector'
import ScheduleSelector from './ScheduleSelector'
import { dialogStyles } from './styles'
import ScheduleSummary from './summary/ScheduleSummary'
import { DateRange, ScheduleType } from './types'
import { defaultSchedule, groupSchedules } from './utils'

type PropsType = {
  isOpen: boolean
  onClose: () => void
  handleSave: (schedule: ScheduleType) => void
  dialogTitle: string
  allSchedules: ScheduleType[]
  editingIndex: number
}

const ScheduleDialog: FC<PropsType> = (props) => {
  const { isOpen, onClose, dialogTitle, handleSave, allSchedules, editingIndex } = props
  const classes = dialogStyles()
  const translate = useTranslate()
  const [state, setState] = useState(defaultSchedule())
  const handleChange = (field: string, newValue: any) => {
    setState((state) => ({ ...state, [field]: newValue }))
  }
  const scheduleValue = allSchedules[editingIndex]
  const conflictedSlots = useConflictedSlots(state, allSchedules, editingIndex)
  const filledSlots = useFullyUsedSlots(allSchedules, editingIndex)
  const unavailableSlots = useMemo(() => {
    return [...conflictedSlots, ...filledSlots]
  }, [conflictedSlots, filledSlots])

  useEffect(() => {
    if (scheduleValue) setState({ ...scheduleValue })
  }, [scheduleValue])

  const groupedSchedules = useMemo(() => groupSchedules(state?.applicable_days_and_hours?.data), [
    state?.applicable_days_and_hours?.data,
  ])
  const schedulesLength = groupedSchedules && Object.keys(groupedSchedules).length
  const invalidDateRanges = state?.date_ranges?.filter((x: DateRange) => !x?.start_date || !x?.end_date)
  const [showSaveError, setShowSaveError] = useState(false)

  useEffect(() => {
    if (showSaveError) setShowSaveError(false)
  }, [state])

  return (
    <Dialog open={isOpen} className={classes.dialogWidth}>
      <DialogContent>
        {!!dialogTitle && (
          <div className={classes.row}>
            <h1 className={classes.dialogHeader}>{dialogTitle}</h1>
          </div>
        )}

        <ScheduleSummary showSaveError={showSaveError} state={state} unavailableSlots={unavailableSlots} />

        <h2>{translate('Applicable Date Range')}</h2>
        <DateRangeSelector
          unavailableRanges={unavailableSlots?.map((x) => x.date_ranges)}
          dateRanges={state.date_ranges}
          onChange={(dateRanges) => {
            handleChange('date_ranges', dateRanges)
          }}
        />

        <h2>{translate('Applicable Weekdays and Hours')}</h2>
        <ScheduleSelector
          data={state.applicable_days_and_hours}
          unavailable={unavailableSlots?.map((x) => x.applicable_days_and_hours?.data)}
          onChange={(daysAndHours) => {
            handleChange('applicable_days_and_hours', daysAndHours)
          }}
        />
      </DialogContent>
      <DialogActions className={classes.removeTopPadding}>
        {showSaveError && (
          <p className={classes.saveError}>{translate('Save failed. Please resolve warnings displayed above.')}</p>
        )}
        <Button
          startIcon={<AlertError />}
          onClick={() => {
            setState(scheduleValue ? scheduleValue : defaultSchedule())
            onClose()
          }}
          variant="contained"
          color="default"
        >
          {translate('Discard')}
        </Button>
        <Button
          className={classes.button}
          startIcon={<ContentSave />}
          onClick={() => {
            if (!!schedulesLength && !!!invalidDateRanges.length) {
              setShowSaveError(false)
              handleSave(state)
            } else {
              setShowSaveError(true)
            }
          }}
        >
          {translate('Save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ScheduleDialog

import { LYRA_AIRE_MODULE_SPACING, LYRA_XR_MOUNT_MODULE_SPACING } from 'Designer/integrations/lyra/constants'
import { useStudioSignals } from 'Studio/signals/useStudioSignals'
import { addProjectError, removeProjectError } from 'actions/project'
import { useDebouncedCallback } from 'ra-core'
import { useEffect, useRef } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { ProjErrorCategory, StdErrorType } from 'reducer/project/projectErrorsReducer'
import { OtherComponentTypes } from 'types/otherComponent'
import { ContactData, ContactDataTypeEnum, ProjectType } from 'types/projects'
import { doNotTranslate } from 'util/misc'
import { ComponentLyraStatus, LyraComponentType, useLyraComponentStatus } from './useLyraComponentStatus'
import { useLyraPermitPackEnabled } from './useLyraPermitPackEnabled'

const SOURCE = 'lyra-export'

type ComponentItem = {
  other_component_type?: OtherComponentTypes
  uuid: string
  code: string
  manufacturer_name: string
  getComponentData: () => LyraComponentType
}

export const useLyraDesignValidation = () => {
  const form = useForm()
  const formState = useFormState()
  const dispatch = useDispatch()
  const lyraEnabled = useLyraPermitPackEnabled()
  const checkLyraComponentStatus = useLyraComponentStatus()
  const pendingSystems = useRef<string[]>([])

  const project: Partial<ProjectType> = formState.values

  const checkSystemDebounced = (uuid: string) => {
    if (!pendingSystems.current.includes(uuid)) {
      pendingSystems.current.push(uuid)
      checkSystemsDebounced()
    }
  }

  useStudioSignals(
    (object) => {
      if (!window.editor.selectedSystem || object.type !== 'OsModule') return
      checkSystemDebounced(window.editor.selectedSystem.uuid)
    },
    ['objectAdded', 'objectRemoved']
  )

  useStudioSignals(
    (object) => {
      if (
        !window.editor.selectedSystem ||
        (object.type !== 'OsInverter' &&
          object.type !== 'OsBattery' &&
          object.type !== 'OsOther' &&
          object.type !== 'OsModuleGrid')
      )
        return
      checkSystemDebounced(window.editor.selectedSystem?.uuid)
    },
    ['objectAdded', 'objectRemoved', 'objectChanged']
  )

  useStudioSignals(
    (object) => {
      if (object.type !== 'OsSystem') return
      checkSystemDebounced(object.uuid)
    },
    ['objectAdded', 'objectRemoved']
  )

  const checkComponentsMapped = (
    systemId: string,
    components: ComponentItem[] | undefined,
    key: string,
    category: ProjErrorCategory,
    label: string,
    { warnOnZero, ...opts }: Partial<StdErrorType & { warnOnZero: boolean }> = {}
  ) => {
    if (!components?.length) {
      if (warnOnZero) {
        const reqRecom = opts?.severity === 'error' ? 'required' : 'recommended'
        updateError(true, key, `At least one ${label} in design ${reqRecom} for permit pack`, {
          category,
          systemId,
          severity: 'warning',
          ...opts,
        })
      }
      return
    }
    for (const component of components) {
      checkComponentMapped(
        component.getComponentData(),
        key,
        `Selected ${label} is not mapped for Permit Pack generation. Choose from mapped options or re-select in permit pack phase.`,
        { category, systemId, componentId: component.uuid, ...opts }
      )
    }
  }

  const checkComponentMapped = (
    component: LyraComponentType | undefined,
    key: string,
    invalid_msg: string,
    opts: Partial<StdErrorType> = {},
    invalid: boolean = true
  ) => {
    const lyraStatus = checkLyraComponentStatus(component)
    updateError(!!(component && invalid && lyraStatus !== ComponentLyraStatus.MAPPED_AND_SUPPORTED), key, invalid_msg, {
      severity: 'warning',
      ...opts,
    })
  }

  const updateError = (invalid: boolean, key: string, invalid_msg: string, opts?: Partial<StdErrorType>) => {
    if (invalid && lyraEnabled) {
      dispatch(
        addProjectError({
          messageType: 'text',
          source: SOURCE,
          key,
          message: doNotTranslate(invalid_msg),

          severity: 'error',
          category: 'standalone',
          ...opts,
        })
      )
    } else {
      dispatch(removeProjectError({ key, source: SOURCE }))
    }
  }

  const checkSystemsDebounced = useDebouncedCallback(() => {
    for (const system of pendingSystems.current) {
      checkSystem(system)
    }
    pendingSystems.current = []
  }, 1000)

  useEffect(() => {
    for (const system of window.editor.getSystems()) {
      checkSystemDebounced(system.uuid)
    }
  }, [])

  useEffect(() => {
    if (!lyraEnabled) {
      // Clear all errors created by this hook
      dispatch(removeProjectError({ key: 'any', source: SOURCE, systemId: 'any', componentId: 'any' }))
    }
  }, [lyraEnabled])

  // Project owner setting & validation
  // Auto-select the first contact with a family name as the property owner contact
  useEffect(() => {
    let projectOwner: ContactData | undefined
    if (lyraEnabled) {
      projectOwner = project.contacts_data?.find(
        (contact) => !!contact.family_name && contact.type === ContactDataTypeEnum.REAL_CONTACT
      )
      form.change('custom_data.lyra.property_owner_contact', projectOwner?.id)
    }

    // Contacts
    const key = `${SOURCE}_contact`
    if (!project.contacts?.length) {
      updateError(true, key, 'At least one project contact is required')
    } else if (project.contacts?.length === 1) {
      updateError(!projectOwner, key, 'Please add a family name to the project contact')
    } else {
      updateError(!projectOwner, key, 'Please add a family name to the project contact who is the property owner')
    }
  }, [lyraEnabled, project.contacts_data])

  // Project validation
  useEffect(() => {
    // Return early if Lyra is not available (will clear project errors above in this case)
    if (!lyraEnabled) return

    // Address
    updateError(!project.address, `${SOURCE}_address`, 'Address is required')

    // Lat/Lon
    updateError(!project.lat || !project.lon, `${SOURCE}_latlon`, 'Lat/Lon is required')

    // Temperature min/max
    const min_max = project.temperature_min_max
    const min = project.temperature_min_override === null ? min_max?.[0] : project.temperature_min_override
    const max = project.temperature_max_override === null ? min_max?.[1] : project.temperature_max_override
    updateError(
      min === undefined || max === undefined || isNaN(min) || isNaN(max),
      `${SOURCE}_temperature`,
      'Temperature min/max is required'
    )
  }, [lyraEnabled, project])

  // System validation
  const checkSystem = (uuid: string) => {
    // Return early if Lyra is not available (will clear system errors above in this case)
    if (!lyraEnabled) return

    const system = window.editor.getSystems().find((x) => x.uuid === uuid)

    const base_key = `${SOURCE}_system_${uuid}`

    // Clear all errors for this system
    dispatch(removeProjectError({ key: 'any', source: SOURCE, systemId: uuid, componentId: 'any' }))

    if (system?.is_current) return // avoid warning for existing system

    // Modules
    const moduleCount = system?.getModules().length
    updateError(!moduleCount, `${base_key}_module_count`, 'At least one module in design required')
    const moduleType = system?.moduleType()
    if (moduleType) {
      checkComponentMapped(
        {
          code: moduleType.code,
          other_component_type: undefined,
          external_data: moduleType.external_data,
        },
        `${base_key}_module_type`,
        'Selected module is not mapped for Permit Pack generation. Choose from mapped options or re-select in permit pack phase.',
        { category: 'module', systemId: uuid },
        !!moduleCount
      )
    } else {
      dispatch(removeProjectError({ key: `${base_key}_module_type`, source: SOURCE }))
    }

    // Inverters
    const inverters = system?.inverters() || []
    let has_string = false
    let has_micro = false
    for (const inverter of inverters) {
      if (inverter.microinverter) has_micro = true
      else has_string = true
    }
    checkComponentsMapped(uuid, inverters, `${base_key}_inverter_type`, 'inverter', 'inverter', {
      warnOnZero: !!moduleCount, // Don't warn about no components until modules are added
    })

    updateError(
      has_string && has_micro,
      `${base_key}_inverter_types`,
      "Lyra doesn't support both micro and string inverters on the same system",
      { category: 'inverter', systemId: uuid, severity: 'error' }
    )

    // Batteries
    checkComponentsMapped(uuid, system?.batteries(), `${base_key}_battery_type`, 'battery', 'battery')

    // DC Optimizers
    const dcOptimizers = system?.others().filter((oc) => oc.other_component_type === 'dc_optimizer')
    checkComponentsMapped(uuid, dcOptimizers, `${base_key}_dcoptimizer_type`, 'other', 'DC optimizer')
    const uniqueDcOptimizers = findUniqueComponents(dcOptimizers)
    updateError(
      uniqueDcOptimizers.length > 1,
      `${base_key}_dcoptimizer_types`,
      "Lyra doesn't support multiple DC Optimizer types on the same system",
      { category: 'other', systemId: uuid, severity: 'warning' }
    )

    // Mounting Rails
    const rails = system?.others().filter((oc) => oc.other_component_type === 'mounting_rail')
    checkComponentsMapped(uuid, rails, `${base_key}_rails_type`, 'mounting', 'rail', {
      severity: 'error',
      warnOnZero: !!moduleCount, // Don't warn about no components until modules are added
    })
    const uniqueRails = filterUniqueIronRidgeRails(findUniqueComponents(rails))

    updateError(
      uniqueRails.length > 1,
      `${base_key}_rail_types`,
      "Lyra doesn't support multiple rail types on the same system",
      { category: 'mounting', systemId: uuid, severity: 'warning' }
    )

    // Mounting Anchors
    const anchors = system?.others().filter((oc) => oc.other_component_type === 'mounting_roof_anchor')
    checkComponentsMapped(uuid, anchors, `${base_key}_anchors_type`, 'mounting', 'anchor', {
      warnOnZero: !!moduleCount, // Don't warn about no components until modules are added
    })

    // Mounting Clamp
    const clamps = system?.others().filter((oc) => oc.other_component_type === 'mounting_clamp')
    checkComponentsMapped(uuid, clamps, `${base_key}_clamps_type`, 'mounting', 'clamp', {
      warnOnZero: !!moduleCount, // Don't warn about no components until modules are added
    })
    const uniqueClamps = findUniqueComponents(clamps)
    updateError(
      uniqueClamps.length > 2,
      `${base_key}_clamp_types`,
      "Lyra doesn't support more than 2 clamp types on the same system",
      { category: 'mounting', systemId: uuid, severity: 'warning' }
    )

    // Module Grids
    // min gap based on rail component
    // Mapping from Lyra:
    //    Aire -> 0.0127m / 0.5 inch
    //    XR Mount -> 0.009525m / 0.375 inch.
    let minGap = {
      value: LYRA_AIRE_MODULE_SPACING,
      label: '0.5 inch',
    }
    if (clamps?.find((r) => r.manufacturer_name.includes('IronRidge') && r.code.includes('XR'))) {
      minGap = {
        value: LYRA_XR_MOUNT_MODULE_SPACING,
        label: '0.375 inch',
      }
    }
    const moduleGrids = system?.moduleGrids() || []
    for (let moduleGrid of moduleGrids) {
      updateError(
        moduleGrid.moduleSpacing[0] < minGap.value,
        `${base_key}_modulegrid_gapX`,
        `Gap X is below the minimum required for Lyra permit pack (${
          minGap.label
        }, ${moduleGrid.moduleQuantity()} Panels)`,
        { category: 'module', systemId: uuid, severity: 'warning', componentId: moduleGrid.uuid }
      )
    }
  }
}

const getIronRidgeRailUniqueCode = (code) => {
  if (code.split('-').length > 2) {
    return code.substring(0, code.lastIndexOf('-'))
  } else {
    return code
  }
}

const filterUniqueIronRidgeRails = (components: ComponentItem[]) => {
  // XR-1000-168A and XR-1000-204A will be considered as same IronRidge rail
  // more detail: https://github.com/open-solar/opensolar-todo/issues/10843#issuecomment-1998671798
  return components.reduce<ComponentItem[]>((list, component) => {
    if (component.other_component_type === 'mounting_rail' && component.manufacturer_name === 'IronRidge') {
      if (!list.find((c) => getIronRidgeRailUniqueCode(c.code) === getIronRidgeRailUniqueCode(component.code))) {
        list.push(component)
      }
    }
    return list
  }, [])
}

const findUniqueComponents = (components: ComponentItem[] | undefined): ComponentItem[] => {
  if (!components) return []

  return components.reduce<ComponentItem[]>((list, component) => {
    if (!list.find((c) => c.code === component.code && c.manufacturer_name === component.manufacturer_name)) {
      list.push(component)
    }
    return list
  }, [])
}

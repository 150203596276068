// @ts-nocheck
import React from 'react'

const IronRidgePermissionsWarning = () => {
  const url = ['production', 'staging4'].includes(window.ENV)
    ? 'https://base.ironridge.com/account/edit'
    : 'https://staging-ridge.com/account/edit'
  return (
    <div className="warning-box-no-icon">
      <h4>Required permissions not yet granted</h4>
      <p>
        Please grant OpenSolar permission to create projects on your behalf in your IronRidge account before moving
        forward. You can do this by:
      </p>
      <ul style={{ padding: '0% 15%', textAlign: 'left', marginTop: '20px' }}>
        <li>
          Logging in to your{' '}
          <a href={url} target="_blank" rel="noopener noreferrer">
            IronRidge account
          </a>
        </li>
        <li>Navigating to the edit account page and allow OpenSolar to create projects</li>
        <li>Once that's done, return here and click "Connect Account" again</li>
      </ul>
    </div>
  )
}
export default IronRidgePermissionsWarning

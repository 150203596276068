import { CircularProgress, Dialog, makeStyles } from '@material-ui/core'
import { Button, DialogTitle } from 'opensolar-ui'
import { COLOR_PALETTE, getProjectSectionStyles } from 'projectSections/sections/manage3/styles'
import { SimpleForm, Toolbar, useTranslate } from 'react-admin'
import { Theme } from 'types/themes'
const useStyles = makeStyles((theme: Theme) => ({
  formWrapper: {
    margin: '0 3px',
    '& .MuiFormLabel-root': {
      fontSize: 14,
      color: COLOR_PALETTE.darkGrey,
    },
  },
  toolBar: {
    borderTop: '1px solid ' + COLOR_PALETTE.lightGrey,
    padding: '0 20px',
    marginTop: '0 !important',
  },
  discardBtn: {
    marginRight: '5px !important',
  },
}))
export interface EventDialogProps {
  open: boolean
  onClose: () => void
  headerText?: string
  content: React.ReactNode
  initialValues: object | null | undefined
  handleSubmit: (values) => void
  formatValues?: (values) => object | null | undefined
  validate?: (values) => object | undefined
  toolbarBtn?: React.ReactNode
  discardBtnLabel?: string
  submitBtnLabel?: string
  loading?: boolean
}

const EventFormDialog: React.FC<EventDialogProps> = (props) => {
  const { open, headerText } = props
  return (
    <Dialog open={open}>
      <DialogTitle>{headerText}</DialogTitle>
      <EventFormContent {...props} />
    </Dialog>
  )
}

const EventFormContent = (props) => {
  const classes = useStyles()
  const { handleSubmit, initialValues, content, validate } = props
  return (
    <SimpleForm
      toolbar={<DialogFormToolbar {...props} />}
      save={handleSubmit}
      initialValues={initialValues}
      formatSubmitValues={props.formatValues}
      validate={validate}
    >
      <div className={classes.formWrapper}>{content}</div>
    </SimpleForm>
  )
}

const DialogFormToolbar = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const sectionStyles = getProjectSectionStyles()

  return (
    <Toolbar className={`${sectionStyles.sectionCardBottom} ${classes.toolBar}`}>
      <>
        {props.toolbarBtn}
        <Button variant="contained" color="default" className={classes.discardBtn} onClick={props.onClose}>
          {props.discardBtnLabel || translate('Discard')}
        </Button>
        <Button
          startIcon={props.loading ? <CircularProgress size={18} color={'inherit'} /> : undefined}
          disabled={props.pristine || props.loading || props.invalid}
          variant="contained"
          color="primary"
          onClick={props.handleSubmit}
        >
          {props.submitBtnLabel || translate('Confirm')}
        </Button>
      </>
    </Toolbar>
  )
}

export default EventFormDialog

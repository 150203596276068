import {
  PROJECT_INFO_CLEAR,
  PROJECT_INFO_SET,
  ProjectInfoActions,
  SET_COMPONENT_SPECS_ORDERING_DATA_READY,
} from 'actions/project/projectInfoActions'
import { Reducer } from 'redux'
import { RootState } from 'types/state'

export interface ProjectInfoState {
  id?: number
  country_iso2?: string
}

export const projectInfoReducer: Reducer<ProjectInfoState> = (previousState = {}, action: ProjectInfoActions) => {
  switch (action.type) {
    case PROJECT_INFO_SET:
      return { ...action.payload }
    case PROJECT_INFO_CLEAR:
      return {}
    case SET_COMPONENT_SPECS_ORDERING_DATA_READY:
      return { ...previousState, componentSpecsOrderingDataReady: action.payload }
    default:
      return previousState
  }
}

export const projectInfoSelector = {
  isInProject: (state: RootState): boolean => {
    return state.project.info.id !== undefined
  },
  getProjectId: (state: RootState): number | undefined => {
    return state.project.info.id
  },
  getProjectCountryIso2: (state: RootState): string | undefined => {
    return state.project.info.country_iso2
  },
}

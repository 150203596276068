import { Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { newSupportTicketLink } from 'constants/links'
import ChipsInput from 'elements/input/ChipsInput'
import UnresolvableReferenceInput from 'elements/UnresolvableReferenceInput'
import { FormDataConsumer, useTranslate } from 'react-admin'
import { COMPONENT_TYPE_CHOICES, FINANCE_CHOICES } from './ComponentContent'

// NOTE:
// the component_type of module is 0 so using logical && for conditional rendering will cause an error for modules
// selecting tariff requires the associated_component_model_codes search type, only finance uses a different search
// the properties in COMPONENT_TYPE_CHOICES cannot be edited as they are used for the search

const useStyles = makeStyles((theme) => ({
  wrapLink: {
    textWrap: 'wrap',
  },
}))

export default function ComponentSearch() {
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <FormDataConsumer>
      {({ formData }) => {
        if (formData?.component_type === undefined) return <></>
        return (
          <div>
            <h3>{translate(COMPONENT_TYPE_CHOICES[formData.component_type].name) + ' ' + translate('Search')}</h3>
            {formData?.component_type === 5 && (
              <ChipsInput
                label="resources.component_content.fields.q"
                fullWidth
                optionText="code"
                optionValue="code"
                source="associated_component_model_codes"
                choices={formData.component_type ? FINANCE_CHOICES : []}
                enableFreeSolo
              />
            )}
            {formData?.component_type !== 5 && formData?.component_type !== 4 && (
              <p>
                {translate(
                  'To activate the showcase for your product, simply input the Component Code in the field below. You can locate this code in the OpenSolar database.'
                )}
              </p>
            )}
            {formData?.component_type !== 5 && (
              //@ts-ignore
              <UnresolvableReferenceInput
                label="resources.component_content.fields.q"
                reference={COMPONENT_TYPE_CHOICES[formData.component_type].reference}
                source="associated_component_model_codes"
                fullWidth
                style={{ width: '100%' }}
                ignoreWarnings={['associatedReference']}
              >
                <ChipsInput
                  optionText="code"
                  optionValue="code"
                  source="associated_component_model_codes"
                  enableFreeSolo
                />
              </UnresolvableReferenceInput>
            )}
            {formData?.component_type !== 5 && formData?.component_type !== 4 && (
              <p>
                <Link
                  target="_blank"
                  rel="noreferrer"
                  href={newSupportTicketLink}
                  style={{ textDecoration: 'underline' }}
                  className={classes.wrapLink}
                >
                  {translate(
                    "Can't find your product? Fill out this form to request it to be uploaded to our database here"
                  )}
                </Link>
              </p>
            )}
          </div>
        )
      }}
    </FormDataConsumer>
  )
}

export const styles = {
  exhibitButtons: {
    container: {
      display: 'flex',
    },
    icon: {
      fontSize: 12,
      color: '#4D4D4D',
      height: 14,
      width: 14,
      verticalAlign: 'text-top',
      paddingTop: 2,
    },
    flatIcon: {
      fontSize: 12,
      fill: '#4d4d4d',
      height: 14,
      width: 14,
      verticalAlign: 'middle',
      marginLeft: 0,
    },
    flatText: {
      color: '#4d4d4d',
      textDecoration: 'none',
      fontSize: 12,
      maxWidth: 40,
      lineHeight: '12px',
      verticalAlign: 'middle',
      textTransform: 'none',
      paddingLeft: '1px',
      paddingRight: 0,
      fontWeight: 400,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    text: {
      display: 'inline-block',
      height: '100%',
      maxWidth: 40,
      marginRight: 10,
      verticalAlign: 'unset',
      fontSize: 12,
      color: '#4D4D4D',
      textDecorationLine: 'underline',
    },
  },
  mobile: {
    isFeatured: {
      container: {
        width: '100%',
        minHeight: 145,
        overflow: 'hidden',
        border: '1px solid #EFEFEF',
        marginBottom: 10,
        display: 'flex',
        borderRadius: 5,
        paddingBottom: 75,
      },
      logo: {
        maxWidth: 150,
        maxHeight: 60,
        marginTop: 5,
        marginBottom: 10,
        position: 'absolute',
        bottom: 0,
      },
      productImage: {
        maxWidth: 130,
        maxHeight: 390,
        margin: 0,
        top: 10,
        right: 0,
        position: 'absolute',
      },
      productImageColumn: { flex: '0 0 130px', position: 'relative' },
    },
    noFeatured: {
      container: {
        width: '100%',
        minHeight: 100,
        overflow: 'hidden',
        border: '1px solid #EFEFEF',
        marginBottom: 10,
        display: 'flex',
        borderRadius: 5,
        paddingBottom: 45,
      },
      logo: {
        maxWidth: 75,
        maxHeight: 30,
        marginTop: 5,
        marginBottom: 10,
        position: 'absolute',
        bottom: 0,
      },
      productImage: {
        maxWidth: 110,
        maxHeight: 270,
        margin: 0,
        top: 10,
        right: 0,
        position: 'absolute',
      },
      productImageColumn: { flex: '0 0 110px', position: 'relative' },
    },
  },
  standard: {
    isFeatured: {
      container: {
        width: 'calc(50% - 12px)',
        minWidth: 300,
        minHeight: 145,
        overflow: 'hidden',
        border: '1px solid #EFEFEF',
        display: 'flex',
        marginBottom: '20px',
        right: 0,
        borderRadius: 5,
        paddingBottom: 75,
      },
      logo: {
        maxWidth: 150,
        maxHeight: 60,
        marginTop: 5,
        marginBottom: 10,
        position: 'absolute',
        bottom: 0,
      },
      productImage: {
        maxWidth: 130,
        maxHeight: 390,
        margin: 0,
        top: 10,
        right: 0,
        position: 'absolute',
      },
      productImageColumn: { flex: '0 0 130px', position: 'relative' },
    },
    noFeatured: {
      container: {
        width: 'calc(50% - 12px)',
        minWidth: 300,
        minHeight: 100,
        overflow: 'hidden',
        border: '1px solid #EFEFEF',
        display: 'flex',
        marginBottom: '20px',
        borderRadius: 5,
        paddingBottom: 45,
      },
      logo: {
        maxWidth: 75,
        maxHeight: 30,
        marginTop: 5,
        marginBottom: 10,
        position: 'absolute',
        bottom: 0,
      },
      productImage: {
        maxWidth: 110,
        maxHeight: 270,
        margin: 0,
        top: 10,
        right: 0,
        position: 'absolute',
      },
      productImageColumn: { flex: '0 0 110px', position: 'relative' },
    },
  },
}
export default styles

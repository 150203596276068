import { MapDataTypes } from 'types/map'

export const directionMap = {
  N: 'North',
  E: 'East',
  W: 'West',
  S: 'South',
  NE: 'North-East',
  NW: 'North-West',
  SE: 'South-East',
  SW: 'South-West',
  V: false,
}

const timeTest = /\d\d:\d\d:\d\d/g
const dateTest = /\d\d\d\d-\d\d-\d\d/g

interface PathPart {
  key: string
  name?: string
  time?: string
}

// Returns an array of strings representing the path that this map data should appear at in the UI
// Returns undefined if this map should be omitted entirely
function getVariationPath(map: MapDataTypes): PathPart[] | undefined {
  let time: string | undefined
  const ret: PathPart[] = [{ key: map.map_type }]
  const direction = map.variation_data?.direction
  if (direction) {
    const mapped = directionMap[direction]
    if (mapped === false) return undefined

    ret.push({ key: direction, name: mapped })
  }

  let variation = map.variation_name
  if (variation) {
    let name: string | undefined
    timeTest.lastIndex = -1
    const timeMatch = timeTest.exec(variation)
    if (timeMatch) {
      time = timeMatch[0]
    }

    dateTest.lastIndex = -1
    const dateMatch = dateTest.exec(variation)
    if (dateMatch) {
      name = dateMatch[0]
    }
    ret.push({ key: variation, name, time })
  }

  return ret
}

function linkNodes(parent: MapTypeBranch | undefined, child: MapTypeNode) {
  if (parent) {
    child.parent = parent
    parent.children.push(child)
  }
}

// Takes a list of map typess returned by coverage check and sorts them into a tree of nodes for the UI
export function sortMapTypesIntoTree(
  mapTypes: MapDataTypes[],
  timezoneOffset = 0
): { tree: MapTypeNode[]; reverse: Record<string, MapTypeNode> } {
  var tree: MapTypeNode[] = []
  var reverse: Record<string, MapTypeNode> = {}
  for (const mapType of mapTypes) {
    const path = getVariationPath(mapType)
    if (!path) continue

    let newNode: MapTypeNode
    let lastBranch: MapTypeBranch | undefined
    let newKey = ''
    for (let i = 0; i < path.length; i++) {
      const pathPart = path[i]
      newKey += pathPart.key
      if (i < path.length - 1) {
        let newBranch = reverse[newKey] as MapTypeNode | undefined
        if (newBranch?.type === 'leaf') throw new Error('Branch/leaf conflict')
        if (!newBranch) {
          newBranch = {
            type: 'branch',
            name: pathPart.name || pathPart.key,
            children: [],
            key: newKey,
          }
          linkNodes(lastBranch, newBranch)
          if (i === 0) tree.push(newBranch)
        }
        lastBranch = newBranch as MapTypeBranch
        newNode = newBranch
      } else {
        let newLeaf: MapTypeLeaf = {
          type: 'leaf',
          name: pathPart.name || pathPart.key,
          mapType,
          key: newKey,
          time: convertTime(pathPart.time, timezoneOffset),
        }
        linkNodes(lastBranch, newLeaf)
        newNode = newLeaf

        if (i === 0) tree.push(newNode)
      }
      reverse[newKey] = newNode
      newKey += '.'
    }
  }
  return { reverse, tree }
}

function convertTime(time: string | undefined, timezoneOffset: number) {
  if (!time || timezoneOffset === 0) return time
  const [hours, minutes, seconds] = time.split(':').map(Number)
  const date = new Date()
  date.setHours(hours, minutes, seconds)
  date.setHours(date.getHours() + timezoneOffset)
  return date.toTimeString().split(' ')[0]
}

export type MapTypeNode = MapTypeBranch | MapTypeLeaf

export interface MapTypeBranch {
  type: 'branch'
  name: string
  children: MapTypeNode[]
  key: string
  parent?: MapTypeBranch
  time?: string
}

export interface MapTypeLeaf {
  type: 'leaf'
  name: string
  mapType: MapDataTypes
  key: string
  parent?: MapTypeBranch
  time?: string
}

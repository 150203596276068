import { incrementAppViewVersion } from 'ducks/app'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

/**
 * Hook for force a remount of the current page. This is faster and better than reload entire app.
 *
 *
 *
 * useRemountApp vs. useRefresh:
 * useRefresh only re-fetch all resources that are managed by react-admin.
 * However not all fetching data is managed by react-admin on the OS platform.
 * We have to force a re-mount of the app. So that all components will be re-mounted and data will be re-fetched.
 */
const useRemountApp = () => {
  const dispatch = useDispatch()
  return useCallback(() => dispatch(incrementAppViewVersion()), [dispatch])
}

export default useRemountApp

import { ModuleGridType } from 'types/global'
import type { BasicAccessorConfig } from './types'

const setter = ({ moduleGrid, value }: { moduleGrid: ModuleGridType; value: unknown }) => {
  window.editor.execute(new window.SetPanelConfigurationCommand(moduleGrid, 'trackingMode', value))
}

const getter = (moduleGrid: ModuleGridType): unknown => {
  return moduleGrid.trackingMode()
}

export const trackingModeAccessorConfig: BasicAccessorConfig<unknown> = {
  get: getter,
  set: setter,
}

import { Grid } from '@material-ui/core'
import type { OpenSolarThemeType } from 'Themes'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { formatCurrencyWithSymbol } from 'util/misc'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  originalPrice: {
    textDecoration: 'line-through',
    fontSize: 12,
    color: '#000000',
    marginLeft: 0,
  },
  actualPrice: {
    marginTop: 5,
    color: theme.secondaryColor,
    fontSize: 20,
  },
}))

const CatalogItemPrice = ({
  originalPrice,
  priceAfterDiscount,
  currencySymbol,
  discountPrefix,
}: {
  originalPrice: number
  priceAfterDiscount?: number
  currencySymbol: string
  discountPrefix?: string
}) => {
  const classes = useStyles()

  if (!!priceAfterDiscount && priceAfterDiscount < originalPrice) {
    return (
      <Grid container direction="column">
        <Grid item className={classes.actualPrice}>
          {!!discountPrefix
            ? `${discountPrefix} ${formatCurrencyWithSymbol(priceAfterDiscount, currencySymbol)}`
            : formatCurrencyWithSymbol(priceAfterDiscount, currencySymbol)}
        </Grid>
        <Grid item className={classes.originalPrice}>
          {formatCurrencyWithSymbol(originalPrice, currencySymbol)}
        </Grid>
      </Grid>
    )
  }
  return <div className={classes.actualPrice}>{formatCurrencyWithSymbol(originalPrice, currencySymbol)}</div>
}

export default CatalogItemPrice

import { makeStyles } from '@material-ui/core/styles'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import React, { useMemo, useState } from 'react'
import { Theme } from 'types/themes'

const useStyles = makeStyles<Theme, { size: number }>(
  (theme) => ({
    img: ({ size }) => ({
      outline: '1px solid white',
      borderRadius: '50%',
      height: size,
      width: size,
      objectFit: 'cover',
    }),
    circle: {
      width: 16,
      height: 16,
      padding: 6,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 500,
      borderRadius: 50,
      outline: '1px solid white',
    },
    user: {
      color: COLOR_PALETTE.darkBlue,
      backgroundColor: COLOR_PALETTE.blue,
    },
    external: {
      color: COLOR_PALETTE.darkGreen,
      backgroundColor: COLOR_PALETTE.green,
      marginLeft: 10,
    },
  }),
  { name: 'UserIcon' }
)

interface PropTypes {
  org?: {
    org_name: string
    logo_public_url?: string
  }
  user?: {
    display: string
    portrait_image_public_url: string
  }
  size?: number
  wrapperClass?: string
  circleClass?: string
}

const getInitials = (nameStr) => {
  if (!nameStr) return ''
  const allNames = nameStr.trim().split(' ')
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`
    }
    return acc
  }, '')
  return initials
}

const UserIcon: React.FC<PropTypes> = ({ org, user, size = 32, wrapperClass, circleClass }) => {
  const classes = useStyles({ size })
  const [renderInitials, setRenderInitials] = useState(false)
  const portraitUrl = useMemo(() => org?.logo_public_url || user?.portrait_image_public_url, [org, user])
  const initials = useMemo(() => getInitials(org?.org_name || user?.display), [user, org])

  if (!org && !user) return null
  return (
    <div className={wrapperClass}>
      {portraitUrl && !renderInitials ? (
        <img
          src={portraitUrl}
          className={classes.img}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            setRenderInitials(true)
          }}
        />
      ) : (
        <div className={`${classes.circle} ${circleClass} ${org ? classes.external : classes.user}`}>{initials}</div>
      )}
    </div>
  )
}

export default UserIcon

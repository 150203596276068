import { ExtendedRequestInfoSafe } from '../types'

export const contentTypeReqMiddleware = async (req: Request, options: ExtendedRequestInfoSafe) => {
  if (options.format === 'json') {
    if (!options.headers.has('Accept')) options.headers.set('Accept', 'application/json')

    if (!options.headers.has('Content-Type') && !(options && options.body && options.body instanceof FormData)) {
      options.headers.set('Content-Type', 'application/json')
    }
  }
}

import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { getCountryDefaultSupplier, getCountrySuppliers } from 'pages/ordering/configs'
import { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import useSegenAuthenticationStatus from 'services/segen/useSegenAuthenticationStatus'
import useDistributorAuthenticationStatus from 'services/useDistributorAuthenticationStatus'
import { useFeatureFlag } from 'util/split'
import { ExtendedHardwareSupplierConfig } from '../DistributorChip'

type GetDistributorConnectionStatusAndConfigResponse = {
  isChecking: boolean
  atLeastOneDistributorEnabled: boolean
  atLeastOneDistributorConnected: boolean
  enabledAndConnectedDistributors: HardwareSupplierFilterKeyType[] | undefined
  distributorConfigs: ExtendedHardwareSupplierConfig[]
}

const useGetDistributorConnectionStatusAndConfig = (
  selectedSupplier?: HardwareSupplierFilterKeyType
): GetDistributorConnectionStatusAndConfigResponse => {
  const orgId = useSelector(authSelectors.getOrgId) || 0
  const orgCountry = useSelector(authSelectors.getCurrentOrgCountry)
  const countryDefaultSupplier = getCountryDefaultSupplier(orgCountry || '')
  const countrySuppliers = getCountrySuppliers(orgCountry || '')
  const segenEnabled = window.reduxStore?.getState()?.orgs?.org?.enable_segen
  const outletEnabled = window.reduxStore?.getState()?.orgs?.org?.enable_outlet
  const cityPlumbingEnabled = window.reduxStore?.getState()?.orgs?.org?.enable_city_plumbing
  const hdmEnabled = window.reduxStore?.getState()?.orgs?.org?.enable_hdm
  const vtacEnabled = window.reduxStore?.getState()?.orgs?.org?.enable_vtac
  const segenConnectStatus = useSegenAuthenticationStatus()
  const cityPlumbingConnectStatus = useDistributorAuthenticationStatus({ orgId, distributor: 'city_plumbing' })
  const hdmConnectStatus = useDistributorAuthenticationStatus({ orgId, distributor: 'hdm' })
  const vtacUKConnectStatus = useDistributorAuthenticationStatus({ orgId, distributor: 'vtac_uk' })
  const vtacPLConnectStatus = useDistributorAuthenticationStatus({ orgId, distributor: 'vtac_pl' })
  const enabledDistributors = useSelector(orgSelectors.getEnabledHardwareSuppliers)
  const enableHdm = useFeatureFlag('hdm', 'on')
  const enableVtac = useFeatureFlag('vtac', 'on')

  const distributorConnectionStatus = useMemo(() => {
    const status = {
      segen: { isAuthenticated: segenConnectStatus === 'authenticated', isEnabled: segenEnabled },
      city_plumbing: { isAuthenticated: cityPlumbingConnectStatus.isAuthenticated, isEnabled: cityPlumbingEnabled },
      hdm: { isAuthenticated: hdmConnectStatus.isAuthenticated, isEnabled: hdmEnabled },
      vtac_uk: { isAuthenticated: vtacUKConnectStatus.isAuthenticated, isEnabled: vtacEnabled },
      vtac_pl: { isAuthenticated: vtacPLConnectStatus.isAuthenticated, isEnabled: vtacEnabled },
    }

    return status
  }, [
    segenConnectStatus,
    cityPlumbingConnectStatus.isAuthenticated,
    hdmConnectStatus.isAuthenticated,
    vtacUKConnectStatus.isAuthenticated,
    vtacPLConnectStatus.isAuthenticated,
    segenEnabled,
    cityPlumbingEnabled,
    hdmEnabled,
    vtacEnabled,
  ])

  const atLeastOneDistributorEnabled = useMemo(() => {
    return (
      (outletEnabled || cityPlumbingEnabled || hdmEnabled || segenEnabled || vtacEnabled) &&
      enabledDistributors.length > 0
    )
  }, [outletEnabled, segenEnabled, cityPlumbingEnabled, hdmEnabled, vtacEnabled, enabledDistributors.length])

  const atLeastOneDistributorConnected = useMemo(() => {
    return (
      (outletEnabled ||
        (cityPlumbingConnectStatus.isAuthenticated && cityPlumbingEnabled) ||
        (hdmConnectStatus.isAuthenticated && hdmEnabled) ||
        (segenConnectStatus === 'authenticated' && segenEnabled) ||
        (vtacUKConnectStatus.isAuthenticated && vtacEnabled) ||
        (vtacPLConnectStatus.isAuthenticated && vtacEnabled)) &&
      enabledDistributors.length > 0
    )
  }, [outletEnabled, distributorConnectionStatus, enabledDistributors.length])

  const enabledAndConnectedDistributors = useMemo(() => {
    const distributors: HardwareSupplierFilterKeyType[] = []

    if (orgCountry === 'AU' || orgCountry === 'PL') return countryDefaultSupplier?.filterKey

    for (const [key, { isAuthenticated, isEnabled }] of Object.entries(distributorConnectionStatus)) {
      if (isAuthenticated && isEnabled) {
        distributors.push(key as HardwareSupplierFilterKeyType)
      }
    }

    if (distributors.includes('vtac_uk') && distributors.includes('vtac_pl')) {
      return orgCountry === 'GB'
        ? distributors.filter((distributor) => distributor !== 'vtac_uk')
        : distributors.filter((distributor) => distributor !== 'vtac_pl')
    } else if (distributors.length > 0) {
      return distributors
    }

    return undefined
  }, [distributorConnectionStatus])

  const getEnabledStatus = (filterKey) => {
    switch (filterKey) {
      case 'segen':
        return segenEnabled
      case 'city_plumbing':
        return cityPlumbingEnabled
      case 'hdm':
        return hdmEnabled
      case 'outlet':
        return outletEnabled
      case 'vtac_uk':
        return vtacEnabled
      case 'vtac_pl':
        return vtacEnabled
      default:
        return false
    }
  }

  const getConnectionStatus = (filterKey) => {
    switch (filterKey) {
      case 'segen':
        return segenConnectStatus === 'authenticated'
      case 'city_plumbing':
        return cityPlumbingConnectStatus.isAuthenticated
      case 'hdm':
        return hdmConnectStatus.isAuthenticated
      case 'outlet':
        return outletEnabled
      case 'vtac_uk':
        return vtacUKConnectStatus.isAuthenticated
      case 'vtac_pl':
        return vtacPLConnectStatus.isAuthenticated
      default:
        return false
    }
  }

  const getVisibleStatus = (filterKey) => {
    switch (filterKey) {
      case 'segen':
        return segenEnabled
      case 'city_plumbing':
        return cityPlumbingEnabled
      case 'hdm':
        return enableHdm
      case 'outlet':
        return outletEnabled
      case 'vtac_uk':
        return enableVtac
      case 'vtac_pl':
        return enableVtac
      default:
        return false
    }
  }

  const registrationUrl = (filterKey) => {
    switch (filterKey) {
      case 'segen':
        return 'https://share.hsforms.com/1r-gtFaxoQj-vksZvB23FFAcne2p'
      case 'city_plumbing':
        return 'https://share.hsforms.com/1ArJCBRFsSReMQzJtbzqw7gcne2p'
      case 'hdm':
        return 'https://share.hsforms.com/1X9RRfLizTHuKYzhJIXtdXwcne2p'
      case 'outlet':
        return 'https://pages.opensolar.com/partners/solar-outlet#Getstarted'
      case 'vtac_uk':
        return '/shop/connect_distributor/vtac/email'
      case 'vtac_pl':
        return '/shop/connect_distributor/vtac/email'
      default:
        return false
    }
  }

  const distributorConfigs = useMemo(() => {
    return countrySuppliers?.map((config) => ({
      ...config,
      isEnabled: getEnabledStatus(config.filterKey),
      isConnected: getConnectionStatus(config.filterKey),
      isSelected: selectedSupplier === config.filterKey || selectedSupplier === undefined,
      isVisible: getVisibleStatus(config.filterKey),
      registrationUrl: registrationUrl(config.filterKey),
    }))
  }, [distributorConnectionStatus, selectedSupplier])

  return {
    isChecking:
      segenConnectStatus === 'processing' &&
      cityPlumbingConnectStatus.isChecking &&
      hdmConnectStatus.isChecking &&
      vtacUKConnectStatus.isChecking &&
      vtacPLConnectStatus.isChecking,
    atLeastOneDistributorEnabled,
    atLeastOneDistributorConnected,
    enabledAndConnectedDistributors: enabledAndConnectedDistributors as HardwareSupplierFilterKeyType[] | undefined,
    distributorConfigs: distributorConfigs as ExtendedHardwareSupplierConfig[],
  }
}

export default useGetDistributorConnectionStatusAndConfig

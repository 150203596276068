import useFetchComponentsData from 'elements/hardwareSelectorV2/fetch/useFetchComponentsData'
import { ComponentLikeType } from 'elements/hardwareSelectorV2/types'
import useHardwareDetailsDynamic from 'projectSections/sections/design/sideDrawer/bom/useHardwareDetailsDynamic'
import { useEffect, useMemo, useState } from 'react'

const useDistributorPricing = () => {
  const hardwareDetails = useHardwareDetailsDynamic()

  const codes = useMemo(
    () =>
      hardwareDetails
        .filter((hardwareDetail) => hardwareDetail.quantity > 0)
        .map((hardwareDetail) => hardwareDetail.code),
    [hardwareDetails]
  )

  const [componentsData, setComponentsData] = useState<ComponentLikeType[]>()

  const { fetchComponentsData, isFetching } = useFetchComponentsData()

  useEffect(() => {
    fetchComponentsData({
      codes,
      onReady: (data: ComponentLikeType[]) => {
        setComponentsData(data)
      },
    })
  }, [codes])

  const codesToDistributorPricing = useMemo(() => {
    if (!componentsData) return {}

    const distributorPricing: {
      [code: string]: { distributor: string; price: number }[]
    } = componentsData.reduce((acc, component) => {
      const distributorPricing = component.ordering_v2?.map((distributor) => ({
        distributor: distributor.distributor,
        price: distributor.price,
      }))

      return {
        ...acc,
        [component.code]: distributorPricing,
      }
    }, {})
    return distributorPricing
  }, [componentsData])

  return { codesToDistributorPricing, loading: isFetching }
}

export default useDistributorPricing

import { useEffect, useMemo, useRef, useState } from 'react'
import type { QueryPresenter } from '../query/queryPresenter'
import { Filter } from './filter'
import { FilterPresenter } from './filterPresenter'
import { isEqual } from 'lodash'

const useFilter = ({
  defaultFilter,
  queryPresenter,
}: {
  defaultFilter?: Filter
  queryPresenter: QueryPresenter
}): { filter: Filter; filterPresenter: FilterPresenter } => {
  const [filter, setFilter] = useState(() => new Filter(defaultFilter))
  const prevDefaultFilterRef = useRef(defaultFilter)

  useEffect(() => {
    if (!isEqual(defaultFilter, prevDefaultFilterRef.current)) {
      setFilter(new Filter(defaultFilter))
      prevDefaultFilterRef.current = defaultFilter
    }
  }, [defaultFilter])

  const filterPresenter = useMemo(() => new FilterPresenter(setFilter, queryPresenter), [])
  return { filter, filterPresenter }
}

export default useFilter

import { viewModeSelectors } from 'ducks/viewMode'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import DefaultBrandingConfig from './DefaultBrandingConfig'
import IronridgeBrandingConfig from './IronRidgeBrandingConfig'

const useBrandingConfig = () => {
  const brandingState = useSelector(viewModeSelectors.brandingState)
  return useMemo(() => {
    if (brandingState === 'ironridge') {
      return IronridgeBrandingConfig
    }
    return DefaultBrandingConfig
  }, [brandingState])
}

export default useBrandingConfig

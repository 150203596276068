import { Dialog, DialogContent, DialogTitle, useMediaQuery } from '@material-ui/core'
import { setUtilityApiDialog as setUtilityApiDialogAction } from 'ducks/utilityApi'
import Alert from 'elements/Alert'
import { Button } from 'opensolar-ui'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import RequestUtilityDataForm from './RequestUtilityDataForm'
import UtilityApiAddressSearch from './UtilityApiAddressSearch'

const UtilityApiDialog = (props) => {
  const upSmall = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  const [isOpen, setIsOpen] = React.useState(false)
  const [selectedInfo, setSelectedInfo] = useState(null)

  const hideSelectedInfo = () => {
    setSelectedInfo(null)
  }

  return (
    props.isOpen && (
      <>
        <Dialog
          key={props.version}
          title={null}
          actions={null}
          fullWidth={true}
          open={props.isOpen}
          onBackdropClick={() => {
            props.setUtilityApiDialog(false, null, null)
          }}
          // actionsContainerStyle={{ backgroundColor: '#e2e2e2' }}
        >
          <DialogTitle>
            <h1
              style={{
                fontSize: 24,
                lineHeight: '32px',
                paddingTop: 20,
                paddingLeft: 16,
                color: '#000000',
                fontWeight: 'normal',
                paddingBottom: 8,
                margin: 0,
                textAlign: 'center',
              }}
            >
              Select UtilityAPI Meter
            </h1>
          </DialogTitle>
          <DialogContent>
            {upSmall && (
              <>
                <Alert severity="info">
                  <div style={{ fontSize: 15 }}>
                    Data unavaliable for this address. Click below to request a new UtilityAPI Meter.
                  </div>
                </Alert>
                <Button variant="outlined" style={{ marginTop: 16 }} onClick={() => setIsOpen(true)}>
                  <span>{`Request Data For ${props.address} ...`}</span>
                </Button>
                <div>
                  <p>Or input alternative address below</p>
                </div>
                <UtilityApiAddressSearch
                  suggestions={props.meters}
                  selectedInfo={selectedInfo}
                  handlehideSelectedInfo={hideSelectedInfo}
                  handleSelectedMeterInfo={(info) => {
                    setSelectedInfo(info)
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingTop: 15,
                    paddingBottom: 10,
                  }}
                >
                  <Button
                    style={{ marginRight: 5 }}
                    variant="contained"
                    onClick={() => {
                      props.setUtilityApiDialog(false, null, null)
                      hideSelectedInfo()
                    }}
                  >
                    <span>Cancel</span>
                  </Button>
                  {selectedInfo && (
                    <Button
                      style={{ marginLeft: 5 }}
                      variant="outlined"
                      id="UtilityApiDialogSelectMeter"
                      onClick={() => {
                        props.selectMeter(selectedInfo)
                        props.setUtilityApiDialog(false, null, null)
                        hideSelectedInfo()
                      }}
                    >
                      <span>Import</span>
                    </Button>
                  )}
                </div>
              </>
            )}
          </DialogContent>
        </Dialog>
        <RequestUtilityDataForm
          isOpen={isOpen}
          isClose={() => {
            setIsOpen(false)
          }}
          name={props.name}
          email={props.email}
          utilities={props.utilities}
          closeDialog={() => {
            props.setUtilityApiDialog(false, null, null)
          }}
        />
      </>
    )
  )
}

const mapStateToProps = (state) => {
  var version
  try {
    version = state.utilityApi.version
  } catch (e) {
    version = 1
  }

  var isOpen
  try {
    isOpen = Boolean(state.utilityApi.isOpen)
  } catch (e) {
    isOpen = false
  }

  return {
    version: version,
    isOpen: isOpen,
  }
}

export default connect(mapStateToProps, {
  setUtilityApiDialog: setUtilityApiDialogAction,
})(UtilityApiDialog)

import { useCallback } from 'react'

const useRerender = () => {
  const rerender = useCallback((form) => {
    //Register fields without having to use the <Field /> component
    form.registerField('form_version', () => {})
    //force form to re-render
    form.change('form_version', 1)
    form.change('form_version', undefined)
  }, [])

  return rerender
}

export default useRerender

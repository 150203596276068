import { gql } from 'apollo-boost'

export const SHOPIFY_STOREFRONT_API_VERSION = '2023-07'

export const CREATE_CHECKOUT = gql`
  mutation checkoutCreate($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      checkoutUserErrors {
        message
        field
        code
      }
      checkout {
        id
        webUrl
        totalTax {
          amount
          currencyCode
        }
        totalPrice {
          amount
          currencyCode
        }
        subtotalPrice {
          amount
          currencyCode
        }
        lineItems(first: 250) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          edges {
            node {
              title
              variant {
                title
                sku
                price {
                  amount
                  currencyCode
                }
              }
              quantity
            }
          }
        }
      }
    }
  }
`

export const APPLY_DISCOUNT_CODE_TO_CHECKOUT = gql`
  mutation applyDiscountCodeToCheckout($checkoutId: ID!, $discountCode: String!) {
    checkoutDiscountCodeApplyV2(checkoutId: $checkoutId, discountCode: $discountCode) {
      checkout {
        discountApplications(first: 10) {
          edges {
            node {
              allocationMethod
              targetSelection
              targetType
            }
          }
        }
      }
      checkoutUserErrors {
        message
        code
        field
      }
    }
  }
`

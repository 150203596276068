import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined'
import { Button } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import DefaultTooltip from '../fuji/DefaultToolTip'
import { ToolTipPropsType, TourConfigType } from '../types'

const Tooltip = (props: ToolTipPropsType) => {
  const { handlePrev } = props
  const translate = useTranslate()
  return (
    <DefaultTooltip
      title={translate('Add Payment Options')}
      // onClose={handleCancel}
      icon={LayersOutlinedIcon}
      content={
        <div>
          <p>
            {translate(
              'From this view, you can search, filter and sort all available payment options. If you haven’t yet configured payment options, we’ll direct you to a helpcenter article on that later in the tour.'
            )}
          </p>
          <p>
            {translate(
              'OpenSolar Integrated Finance Partners provide the best options for homeowners to pay as they go and help OpenSolar stay free for all. When you offer a loan from one of the leading solar finance providers, your customer can apply for financing without ever leaving their online proposal. If integrations are available in your region, you can click the gear icon next to the lender name to learn more.'
            )}
          </p>
          <p>
            {translate(
              'You can add or remove payment options using the checkboxes on the left. When you are done click "Save" or "Dismiss" to continue the tour'
            )}
          </p>
        </div>
      }
      actions={
        <>
          <Button disabled={true}>
            <span>{translate('Next')}</span>
          </Button>
        </>
      }
      arrowPlacement={'left'}
    />
  )
}

const PaymentOptionDialogStep: TourConfigType = {
  spotlights: [{ targetId: 'payment-option-selection-dialog-content', disableBackgroundScroll: false, padding: 5 }],
  tooltips: [{ targetId: 'payment-option-selection-dialog-content', placement: 'left', component: Tooltip }],
}

export default PaymentOptionDialogStep

import { PermissionSet } from 'types/roles'

const PROJECT_PERMISSIONS_UPDATE = 'PROJECT_PERMISSIONS_UPDATE'
const INHERIT_PROJECT_PERMISSIONS = 'INHERIT_PROJECT_PERMISSIONS'

export const projectPermissionsActions = {
  updateProjectPermissions: (permissions: PermissionSet) => {
    return {
      type: PROJECT_PERMISSIONS_UPDATE,
      payload: {
        permissions: permissions,
      },
    }
  },

  inheritProjectPermissions: () => {
    return {
      type: INHERIT_PROJECT_PERMISSIONS,
    }
  },
}

import React, { memo, useCallback } from 'react'
import { ProjectErrorType } from 'reducer/project/projectErrorsReducer'
import DesignWarningBoxBasic from '../../../common/DesignWarningBoxBasic'

const EvergenComplianceMessage: React.FC<{}> = () => {
  const filter = useCallback((error: ProjectErrorType) => {
    if (error.key === 'EVERGEN_COMPLIANCE_BASIC_MESSAGE') return true
    return false
  }, [])
  return <DesignWarningBoxBasic categories="all" extraFilter={filter} />
}

export default memo(EvergenComplianceMessage)

import { Grid, makeStyles } from '@material-ui/core'
import { Checkbox } from 'opensolar-ui'
import { useNotify } from 'ra-core'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { SystemDataType } from 'types/systems'
import { Theme } from 'types/themes'
import { formatCurrencyWithSymbol } from 'util/misc'
import { DisplayRow } from './PaymentOptionCompDialog'

const PMT_RANGE_BPS = 0.02

const useStyles = makeStyles<Theme, { isCalculating: boolean }>((theme) => {
  return {
    savingsRowWrapper: {
      border: '3px solid #ffda00',
      borderRadius: '5px',
      backgroundColor: '#ffda00',
      margin: '10px 0px',
    },
    pmtRowWrapper: {
      margin: '10px 0px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    center: {
      textAlign: 'center',
    },
    paymentWrapper: ({ isCalculating }) => {
      return {
        opacity: isCalculating ? 0.6 : 1,
      }
    },
  }
})

type PropTypes = {
  rowData: DisplayRow
  system: SystemDataType
}

const PaymentOptionCompRow: React.FC<PropTypes> = (props) => {
  const [isSelected, setIsSelected] = useState<boolean>(false)
  const [trueMonthlyPayment, setTrueMonthlyPayment] = useState<number | undefined>(undefined)
  const [isCalculating, setIsCalculating] = useState<boolean>(false)

  const classes = useStyles({ isCalculating })
  const notify = useNotify()

  const onClick = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      if (props.rowData.title?.includes('Sungage')) {
        let sameProductOtherITCVersion = props.system.payment_options?.find((pmt) => {
          return pmt.title.includes(props.rowData.title) || props.rowData.title.includes(pmt.title)
        })

        if (sameProductOtherITCVersion) {
          notify(
            'You cannot add the standard and 18mo DPP version of the same product, please choose just one',
            'warning'
          )
          return
        }
      }
    }
    setIsSelected(e.target.checked)
    let pmtOverrides = props.system.payment_options_override
    if (!pmtOverrides) {
      pmtOverrides = props.system.payment_options?.map((pmt) => pmt.id)
    }
    if (e.target.checked) {
      setIsCalculating(true)
      let newValue = [...pmtOverrides, props.rowData.payment_option_id]
      window.editor.execute(new window.SetValueCommand(props.system, 'payment_options_override', newValue))
    } else {
      let newValue = [...pmtOverrides]
      newValue.splice(pmtOverrides.indexOf(props.rowData.payment_option_id), 1)
      window.editor.execute(new window.SetValueCommand(props.system, 'payment_options_override', newValue))
    }
  }

  useEffect(() => {
    if (props.rowData?.payment_option_id && props.system?.payment_options) {
      let isOnSystem = props.system.payment_options_override?.includes(props.rowData.payment_option_id)
      if (isOnSystem) setIsSelected(true)
    }
  }, [])

  useEffect(() => {
    if (isSelected) {
      let thisPmt = props.system.payment_options?.find((pmt) => pmt.id === props.rowData.payment_option_id)
      if (thisPmt) {
        setTrueMonthlyPayment(thisPmt.regular_payment)
        setIsCalculating(false)
      }
    }
  }, [props.system.payment_options, isSelected])

  if (props.rowData.type === 'bill_savings') {
    return (
      <Grid container spacing={1} className={classes.savingsRowWrapper}>
        <Grid xs={12} className={classes.center}>
          {props.rowData.title} - {formatCurrencyWithSymbol(props.rowData.value, '$')}
        </Grid>
      </Grid>
    )
  } else {
    return (
      <Grid container spacing={1} className={classes.pmtRowWrapper}>
        <Grid xs={1}>
          <Checkbox checked={isSelected} onChange={onClick} />
        </Grid>
        <Grid xs={5} className={classes.paymentWrapper}>
          {props.rowData.title}
        </Grid>
        <Grid xs={3} className={classes.paymentWrapper}>
          {formatCurrencyWithSymbol(props.rowData.total_system_price, '$')}
        </Grid>
        <Grid xs={3} className={classes.paymentWrapper}>
          {trueMonthlyPayment && <span>{formatCurrencyWithSymbol(trueMonthlyPayment, '$')}</span>}
          {!trueMonthlyPayment && !props.rowData.title?.includes('Dividend') && (
            <span>
              {formatCurrencyWithSymbol(Math.floor(props.rowData.value * (1 - PMT_RANGE_BPS)), '$')} -{' '}
              {formatCurrencyWithSymbol(Math.ceil(props.rowData.value * (1 + PMT_RANGE_BPS)), '$')}
            </span>
          )}
          {!trueMonthlyPayment && props.rowData.title?.includes('Dividend') && (
            <span>{formatCurrencyWithSymbol(props.rowData.value, '$')}</span>
          )}
        </Grid>
      </Grid>
    )
  }
}
export default PaymentOptionCompRow

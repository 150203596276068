import { Fab, makeStyles, Theme, useMediaQuery } from '@material-ui/core'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ContentAdd from '@material-ui/icons/AddOutlined'
import AlertError from '@material-ui/icons/ErrorOutlineOutlined'
import classnames from 'classnames'
import { Button } from 'opensolar-ui'
import React, { FC, useCallback, useState } from 'react'
import { SaveButton, Toolbar, useNotify, useRedirect, useTranslate } from 'react-admin'

type ChoiceType = {
  id: string
  label: string
}

type PropsType = {
  id?: string
  label?: string
  dialog: JSX.Element
  choices: ChoiceType[]
}

const useStyles = makeStyles((theme: any) => ({
  floating: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 110,
    left: 'auto',
    position: 'fixed',
    zIndex: 1000,
  },
}))

const CreateButtonWithDialog: FC<PropsType> = ({ id, label = 'Create', choices, dialog }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [dialogMode, setDialogMode] = useState<string>('')
  const translate = useTranslate()
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const classes = useStyles()
  const open = Boolean(anchorEl)
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleDialogClose = () => {
    setDialogMode('')
  }

  return (
    <div>
      {isSmall ? (
        <Fab
          id={id}
          aria-controls="create-button-with-dialog-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleOpenMenu}
          //@ts-ignore
          variant="outlined"
          color="default"
          className={classnames(classes.floating)}
          style={{
            width: 70,
            height: 70,
            borderRadius: '50%',
          }}
          aria-label={label}
        >
          <ContentAdd />
        </Fab>
      ) : (
        <Button
          id={id}
          aria-controls="create-button-with-dialog-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleOpenMenu}
          variant="outlined"
          color="default"
          startIcon={<ContentAdd />}
        >
          <span>{translate(label)}</span>
        </Button>
      )}
      <Menu
        id="create-button-with-dialog-menu"
        aria-labelledby={id}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {choices.map((choice: ChoiceType) => {
          return (
            <MenuItem
              key={choice.id}
              onClick={() => {
                handleMenuClose()
                setDialogMode(choice.id)
              }}
            >
              {translate(choice.label)}
            </MenuItem>
          )
        })}
      </Menu>

      {React.cloneElement(dialog, {
        mode: dialogMode,
        onClose: handleDialogClose,
      })}
    </div>
  )
}

export const CustomizedToolbar = (props: any) => {
  const notify = useNotify()
  const translate = useTranslate()
  const redirect = useRedirect()
  const onSuccess = useCallback((response) => {
    const record = response.data
    if (record) {
      notify('ra.notification.created', 'info')
      redirect('edit', `/${props.resource}`, record.id, record)
    }
  }, [])
  return (
    <Toolbar
      style={{ zIndex: 1, left: 0, position: 'absolute', bottom: 0, boxSizing: 'border-box', width: '100%' }}
      {...props}
    >
      <div>
        <Button
          variant="contained"
          color="default"
          style={{ margin: 10 }}
          onClick={() => {
            if (props.onClose) {
              props.onClose()
            }
          }}
          startIcon={<AlertError />}
        >
          <span>{translate('Cancel')}</span>
        </Button>
        <SaveButton
          {...props}
          redirect={'edit'}
          submitOnEnter={true}
          onSave={(values: object, redirect: string, form: any, defaultSaveFunc: Function) => {
            defaultSaveFunc(values, redirect, { onSuccess })
          }}
        />
      </div>
    </Toolbar>
  )
}

export default CreateButtonWithDialog

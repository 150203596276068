import { HasPosition, Item, MountingCalcResult, PanelNeighbourPosition, PanelWithPosition } from '../types'
import { addItems } from '../utils'
import { BomGeneratorAbstract } from './BomGeneratorAbstract'

// TODO : Make the same file for inner corners

export abstract class PerOuterCornerBomGeneratorAbstract extends BomGeneratorAbstract {
  abstract generateBomForTopRightCorner(corner: Corner): Item[]
  abstract generateBomForTopLeftCorner(corner: Corner): Item[]
  abstract generateBomForBottomRightCorner(corner: Corner): Item[]
  abstract generateBomForBottomLeftCorner(corner: Corner): Item[]

  async generateBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    let newResult = result

    newResult = this.getTopRightCorners().reduce(
      (resultFromLastCorner, corner) => addItems(resultFromLastCorner, this.generateBomForTopRightCorner(corner)),
      newResult
    )
    newResult = this.getTopLeftCorners().reduce(
      (resultFromLastCorner, corner) => addItems(resultFromLastCorner, this.generateBomForTopLeftCorner(corner)),
      newResult
    )
    newResult = this.getBottomRightCorners().reduce(
      (resultFromLastCorner, corner) => addItems(resultFromLastCorner, this.generateBomForBottomRightCorner(corner)),
      newResult
    )
    newResult = this.getBottomLeftCorners().reduce(
      (resultFromLastCorner, corner) => addItems(resultFromLastCorner, this.generateBomForBottomLeftCorner(corner)),
      newResult
    )

    return newResult
  }

  getTopRightCorners(): Corner[] {
    const inactivePanels: PanelNeighbourPosition[] = ['top', 'right', 'topright']
    const cornerPanels = this.getPanelsWithInactiveNeighbours(inactivePanels)
    const corners = cornerPanels.map((panel) => {
      return { top: panel.top, left: panel.left }
    })
    return corners
  }

  getTopLeftCorners(): Corner[] {
    const inactivePanels: PanelNeighbourPosition[] = ['top', 'left', 'topleft']
    const cornerPanels = this.getPanelsWithInactiveNeighbours(inactivePanels)
    const corners = cornerPanels.map((panel) => {
      return { top: panel.top, left: panel.left }
    })
    return corners
  }

  getBottomRightCorners(): Corner[] {
    const inactivePanels: PanelNeighbourPosition[] = ['bottom', 'right', 'bottomright']
    const cornerPanels = this.getPanelsWithInactiveNeighbours(inactivePanels)
    const corners = cornerPanels.map((panel) => {
      return { top: panel.top, left: panel.left }
    })
    return corners
  }

  getBottomLeftCorners(): Corner[] {
    const inactivePanels: PanelNeighbourPosition[] = ['bottom', 'left', 'bottomleft']
    const cornerPanels = this.getPanelsWithInactiveNeighbours(inactivePanels)
    const corners = cornerPanels.map((panel) => {
      return { top: panel.top, left: panel.left }
    })
    return corners
  }

  getPanelsWithInactiveNeighbours(inactivePanels: PanelNeighbourPosition[]): PanelWithPosition[] {
    const panelBlock = this.block
    var panels: PanelWithPosition[] = []
    //iterate through panels
    panelBlock.rows.forEach((row, rowIndex) => {
      for (let columnIndex = 0; columnIndex < row.length; columnIndex++) {
        const currentPanel = row[columnIndex]
        if (currentPanel.isActive) {
          const panelIsInFirstRow = rowIndex === 0 // no panels above
          const panelIsLastInRow = columnIndex + 1 === row.length // no panels to right
          const panelIsInLastRow = rowIndex + 1 === panelBlock.rows.length // no panels below
          const panelIsFirstInRow = columnIndex === 0 // no panels to left

          const panelBelow = panelIsInLastRow ? null : panelBlock.rows[rowIndex + 1][columnIndex]
          const panelAbove = panelIsInFirstRow ? null : panelBlock.rows[rowIndex - 1][columnIndex]
          const panelRight = panelIsLastInRow ? null : panelBlock.rows[rowIndex][columnIndex + 1]
          const panelLeft = panelIsFirstInRow ? null : panelBlock.rows[rowIndex][columnIndex - 1]
          const panelAboveToRight =
            panelIsInFirstRow || panelIsLastInRow ? null : panelBlock.rows[rowIndex - 1][columnIndex + 1]
          const panelAboveToLeft =
            panelIsInFirstRow || panelIsFirstInRow ? null : panelBlock.rows[rowIndex - 1][columnIndex - 1]
          const panelBelowToRight =
            panelIsInLastRow || panelIsLastInRow ? null : panelBlock.rows[rowIndex + 1][columnIndex + 1]
          const panelBelowToLeft =
            panelIsInLastRow || panelIsFirstInRow ? null : panelBlock.rows[rowIndex + 1][columnIndex - 1]

          const bottomExposed = !panelBelow?.isActive
          const topExposed = !panelAbove?.isActive
          const rightExposed = !panelRight?.isActive
          const leftExposed = !panelLeft?.isActive
          const toprightExposed = !panelAboveToRight?.isActive
          const topleftExposed = !panelAboveToLeft?.isActive
          const bottomrightExposed = !panelBelowToRight?.isActive
          const bottomleftExposed = !panelBelowToLeft?.isActive

          var passesConditions = true
          for (const index in inactivePanels) {
            switch (inactivePanels[index]) {
              case 'top':
                if (!topExposed) passesConditions = false
                break
              case 'right':
                if (!rightExposed) passesConditions = false
                break
              case 'left':
                if (!leftExposed) passesConditions = false
                break
              case 'topright':
                if (!toprightExposed) passesConditions = false
                break
              case 'topleft':
                if (!topleftExposed) passesConditions = false
                break
              case 'bottom':
                if (!bottomExposed) passesConditions = false
                break
              case 'bottomleft':
                if (!bottomleftExposed) passesConditions = false
                break
              case 'bottomright':
                if (!bottomrightExposed) passesConditions = false
                break
            }
          }
          if (passesConditions) panels.push(currentPanel)
        }
      }
    })

    return panels
  }
}

export interface Corner extends HasPosition {}

import { Divider, ListItemText, MenuItem } from '@mui/material'
import { SplitButton, Switch } from 'opensolar-ui'
import { FC, useState } from 'react'

export const SplitButtonBasic: FC = () => {
  return (
    <>
      {/* SNIPPET START 1 */}
      <SplitButton
        options={[
          { label: 'Manage', value: 'manage' },
          { label: 'Energy', value: 'energy' },
          { label: 'Design', value: 'design' },
        ]}
        onClick={(item, index) => {
          console.log('Clicked: ', item.value)
        }}
      />
      {/* SNIPPET END 1 */}
    </>
  )
}

export const SplitButtonColours: FC = () => {
  const options = [
    { label: 'Manage', value: 'manage' },
    { label: 'Energy', value: 'energy' },
    { label: 'Design', value: 'design' },
  ]
  return (
    <>
      {/* SNIPPET START 2 */}
      <SplitButton color="default" options={options} />
      <SplitButton color="primary" options={options} />
      <SplitButton color="secondary" options={options} />
      <SplitButton color="info" options={options} />
      <SplitButton color="success" options={options} />
      <SplitButton color="warning" options={options} />
      <SplitButton color="error" options={options} />
      {/* SNIPPET END 2 */}
    </>
  )
}

export const SplitButtonVariants: FC = () => {
  const options = [
    { label: 'Manage', value: 'manage' },
    { label: 'Energy', value: 'energy' },
    { label: 'Design', value: 'design' },
  ]
  return (
    <>
      {/* SNIPPET START 3 */}
      <SplitButton variant="contained" options={options} />
      <SplitButton variant="outlined" options={options} />
      <SplitButton variant="text" options={options} />
      <SplitButton variant="contained" disabled={true} options={options} />
      <SplitButton variant="outlined" disabled={true} options={options} />
      <SplitButton variant="text" disabled={true} options={options} />
      {/* SNIPPET END 3 */}
    </>
  )
}

export const SplitButtonSizes: FC = () => {
  const options = [
    { label: 'Manage', value: 'manage' },
    { label: 'Energy', value: 'energy' },
    { label: 'Design', value: 'design' },
  ]
  return (
    <>
      {/* SNIPPET START 4 */}
      <SplitButton size="small" options={options} />
      <SplitButton size="medium" options={options} />
      <SplitButton size="large" options={options} />
      {/* SNIPPET END 4 */}
    </>
  )
}

export const SplitButtonOptions: FC = () => {
  const [selected, setSelected] = useState(1)
  const options = [
    { label: 'Manage', value: 'manage' },
    { label: 'Energy', value: 'energy' },
    { label: 'Design', value: 'design' },
  ]
  return (
    <>
      {/* SNIPPET START 5 */}
      {/* Won't auto-close when an item is selected */}
      <SplitButton options={options} dontClose={true} />

      {/* Will also show the currently selected item in the Menu */}
      <SplitButton options={options} showSelected={true} />

      {/* Will change the currently selected item when an item is clicked on */}
      <SplitButton options={options} updateSelected={true} />

      {/* Selected item is controlled externally (initial value = 1) */}
      <SplitButton
        options={options}
        selectedIndex={selected}
        onClick={(item, index) => {
          setSelected(index)
        }}
      />
      {/* SNIPPET END 5 */}
    </>
  )
}

export const SplitButtonReactMarkup: FC = () => {
  return (
    <>
      {/* SNIPPET START 6 */}
      <SplitButton
        options={[
          { label: 'Manage', value: 'manage' },
          { label: 'Energy', value: 'energy' },
          <Divider />,
          { label: 'Design', value: 'design' },
          <MenuItem>
            <ListItemText>Home</ListItemText>
            <Switch />
          </MenuItem>,
        ]}
      />
      {/* SNIPPET END 6 */}
    </>
  )
}

import Button from 'elements/button/Button'
import CustomField from 'elements/field/CustomField'
import { renderInput } from 'pages/auth/common'
import { useNotify, useTranslate } from 'react-admin'
import { Form } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import useHdmService from 'services/hdm/useHdmService'
import { required } from 'validations'

const HdmVerifyCodeForm = () => {
  const initialValues = {
    code: '',
  }
  const translate = useTranslate()
  const hdmService = useHdmService()
  const history = useHistory()
  const notify = useNotify()

  // There is probably a way of doing this that doesn't involve the token being
  // passed to the frontend but for consistent UX and time reasons this is how it works now.
  const handleSubmit = async (values) => {
    const { code } = values
    const token = await hdmService.exchangeVerificationCode(code)

    if (token) {
      history.push(`/shop/after_connect/hdm?code=${token}`)
    } else {
      notify('Could not verify customer code', 'warning')
    }
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting }) => {
        return (
          <>
            <CustomField
              name="code"
              type="text"
              validate={required}
              component={renderInput}
              label={translate('Verification code')}
              fullWidth={true}
              inputProps={{
                id: 'hdm-connect-form-email',
              }}
            />
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              disabled={submitting}
              loading={submitting}
            >
              Verify Code
            </Button>
          </>
        )
      }}
    />
  )
}

export default HdmVerifyCodeForm

import { Box } from 'opensolar-ui'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { formatDecimalPlaces } from 'util/misc'
import useCommonStyles from './useCommonStyles'

const useStyles = makeOpenSolarStyles(() => ({
  container: {
    padding: '2px 0px',
  },
}))

interface InverterRatingBlockProps {
  acDcRatio: number
  combinedRating: number
}

const InverterRatingBlock: React.FC<InverterRatingBlockProps> = ({ acDcRatio, combinedRating }) => {
  const commonClasses = useCommonStyles()
  const classes = useStyles()

  return (
    <Box className={classes.container} display="flex" flexDirection="column">
      <span className={commonClasses.mainText}>{`${formatDecimalPlaces(combinedRating, 2)} kW`}</span>
      <span className={commonClasses.lightText}>DC/AC {formatDecimalPlaces(acDcRatio, 2)}</span>
    </Box>
  )
}

export default InverterRatingBlock

import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { TRANSFER_ISSUED_REASON_OPTIONS } from 'resources/inventoryTransfer/constants'
import type { EditNotesType } from '../../type'

const InventoryTableEditTop = ({
  state,
  handleChange,
}: {
  state: EditNotesType
  handleChange(arg: { field: string; value: string }): void
}) => {
  return (
    <Grid container spacing={1} justify="flex-end">
      <Grid item xs={12} lg={5}>
        <TextField
          size="small"
          select
          label="Reason"
          fullWidth
          variant="outlined"
          value={state['reason']}
          onChange={(e) => {
            handleChange({ field: 'reason', value: e.target.value })
            logAmplitudeEvent('generic_dropdown_interacted', {
              source: 'reason_selector',
              action: 'inventory_forecast_reason_selected',
              reason: e.target.value,
            })
          }}
        >
          {TRANSFER_ISSUED_REASON_OPTIONS.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} lg={7}>
        <TextField
          size="small"
          multiline
          fullWidth
          value={state['note']}
          onChange={(e) => handleChange({ field: 'note', value: e.target.value })}
          label="Notes"
          placeholder="Enter a note"
          variant="outlined"
        />
      </Grid>
    </Grid>
  )
}

export default InventoryTableEditTop

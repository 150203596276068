import { FormControl, Grid, MenuItem, Select, Typography } from '@material-ui/core'
import type { OpenSolarThemeType } from 'Themes'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  dropdownWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  label: {
    marginRight: '15px',
  },
}))

type OptionType<T extends string | number | undefined> = { value: T; name: string }

const HorizontalDropdownWithLabel = <T extends string | number | undefined>({
  label,
  value,
  handleSelect,
  options,
}: {
  label: string
  options: OptionType<T>[]
  handleSelect(value: T): void
  value: T
}) => {
  const classes = useStyles()

  return (
    <Grid container className={classes.dropdownWrapper}>
      <Grid item>
        <Typography variant="body2" className={classes.label}>
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <FormControl variant="outlined" size="small">
          <Select
            variant="outlined"
            disabled={options.length === 0}
            value={value}
            onChange={(e) => {
              handleSelect(e.target.value as T)
            }}
          >
            {options.map((option) => {
              return (
                <MenuItem key={option.value} value={option.value}>
                  {option.name}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default HorizontalDropdownWithLabel

import { formatInt } from '../../util/misc'
import React from 'react'

const highLightRed = (value, hasHighlight) => {
  if (hasHighlight) {
    return <span style={{ color: 'red' }}>({enhancedFormatInt(Math.abs(value))})</span>
  } else {
    return <span>({enhancedFormatInt(Math.abs(value))})</span>
  }
}

export const enhancedFormatInt = (value) => {
  if (!isNaN(value)) {
    return formatInt(value, window.locale)
  } else {
    return '--'
  }
}

export const enhancedFormatCurrency = (value, highlight) => {
  if (!isNaN(value)) {
    if (value >= 0) {
      return enhancedFormatInt(value)
    } else {
      return highLightRed(Math.abs(value), highlight)
    }
  }
}

export const formatUsage = (value) => {
  if (!value) {
    return '--'
  } else if (value < 0) {
    return <span style={{ color: 'green' }}>({formatInt(Math.abs(value), window.locale)})</span>
  } else if (value >= 0) {
    return formatInt(value, window.locale)
  }
}

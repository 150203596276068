import { makeStyles } from '@material-ui/core'
import { styled } from 'opensolar-ui'
import { Theme } from 'types/themes'
import { COLOR_PALETTE } from '../styles'

export const InputContainer = styled('div')({
  width: '100%',
  margin: '10px 5px',
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
})

export const InputRow = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 10,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

export const useStyles = makeStyles((theme: Theme) => ({
  blueWrapper: {
    backgroundColor: COLOR_PALETTE.lightGrey3,
    borderRadius: 4,
    padding: 16,
  },
  fullWidth: {
    width: '100%',
  },
  loading: {
    marginTop: 15,
  },
  sectionHeader: {
    fontSize: 16,
    fontWeight: 600,
    color: COLOR_PALETTE.darkGrey,
    marginBottom: 10,
  },
  solarOutlet: {
    alignSelf: 'flex-start',
    '& .subtext': {
      margin: 0,
      marginTop: 10,
      fontSize: 12,
      color: COLOR_PALETTE.iconColor,
    },
  },
  darkGreyColor: {
    color: COLOR_PALETTE.darkGrey,
  },
  formRow: {
    display: 'flex',
    '& .fileCol': {
      width: '35%',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& .fileCol': {
        width: 'unset',
      },
    },
  },
  tableWrapper: {
    width: '100%',
    overflowY: 'auto',
  },
}))

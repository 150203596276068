import { Card, CardActions, makeStyles } from '@material-ui/core'
import { emailChangeForm } from 'actions/authActions'
import CustomField from 'elements/field/CustomField'
import { Button } from 'opensolar-ui'
import { useCallback } from 'react'
import { useTranslate } from 'react-admin'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { composeValidators, emailFormat, required } from 'validations'
import AuthModalWrapper from './AuthModalWrapper'
import { renderInput } from './common'

const useStyles = makeStyles((theme) => ({
  card: {
    width: 308,
    minWidth: 210,
    padding: '0.5em 1em 1em 1em',
  },
  button: {
    color: theme.headerFontColor,
    background: theme.themeColor,
    '&:hover': {
      cursor: 'pointer',
      background: theme.themeColorDark,
    },
  },
  input: {
    display: 'flex',
    margin: '10px 0',
    flexDirection: 'column',
  },
}))

const ChangeEmail = () => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()

  const onChangeSuccess = useCallback(() => {
    history.push('/email-change-complete')
  }, [])

  const handleSubmitForm = ({ current_password, new_email }) => {
    dispatch(
      emailChangeForm({
        payload: {
          current_password,
          new_email,
        },
        callbacks: {
          onSuccess: onChangeSuccess,
        },
      })
    )
  }
  return (
    <AuthModalWrapper>
      <Form
        onSubmit={handleSubmitForm}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <Card elevation={0} classes={{ root: classes.card }}>
            <h1>{translate('Change Email')}</h1>
            <p>{translate('Your email will also be updated for all accounts who have sent you a proposal.')}</p>
            <form onSubmit={handleSubmit}>
              <div>
                <div className={classes.input}>
                  <CustomField
                    name="new_email"
                    component={renderInput}
                    validate={composeValidators(required, emailFormat)}
                    label={translate('New Email')}
                    type="text"
                    fullWidth={true}
                  />
                  <CustomField
                    name="current_password"
                    component={renderInput}
                    validate={required}
                    label={translate('Password')}
                    type="password"
                    fullWidth={true}
                  />
                </div>
              </div>
              <CardActions>
                <Button classes={{ root: classes.button }} type="submit" disabled={submitting} fullWidth>
                  <span>{translate('Set Email')}</span>
                </Button>
              </CardActions>
            </form>
          </Card>
        )}
      />
    </AuthModalWrapper>
  )
}
export default ChangeEmail

import { BuildOutlined, WidgetsOutlined, LocalOfferOutlined, WbSunnyOutlined } from '@material-ui/icons'
import { MilestoneInfo } from './types'

export const WORKFLOW_MILESTONES: MilestoneInfo[] = [
  {
    icon: WbSunnyOutlined,
    title: 'PRESALE',
    desc: 'Add stages that happen BEFORE the project is SOLD',
    section: 0,
  },
  {
    title: 'Lock Pricing',
    section: 0,
  },
  {
    icon: LocalOfferOutlined,
    title: 'SOLD',
    desc: 'Add stages that happen AFTER the project is SOLD and BEFORE the project is INSTALLED',
    section: 0,
  },
  {
    icon: BuildOutlined,
    title: 'INSTALLED',
    desc: 'Add stages that happen AFTER the project is INSTALLED',
    section: 0,
  },
  {
    icon: WidgetsOutlined,
    title: 'OTHERS',
    desc:
      "The Others serve as catch-all category for projects that don't fit into the typical progression as specified above. This includes marking a project on-hold or lost.",
    section: 1,
  },
]

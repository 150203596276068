import React, { FC, useContext } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { UtilityTariffDialogContext } from './index'

type PropsType = {
  tariffId: number
  code: string
  resource: string
  basePath: string
  variant: 'proposed' | 'current'
  disabled?: boolean
}

const UtilityTariffLink: FC<PropsType> = ({ tariffId, code, variant, resource, basePath, disabled }) => {
  const { dispatch } = useContext(UtilityTariffDialogContext)
  const projectId = useSelector((state: any) => state.projectId)
  if (!tariffId || !variant || !resource || !basePath) {
    return <span>{code}</span>
  }
  return disabled ? (
    <>{code}</>
  ) : (
    <Link
      to={`/projects/${projectId}/energy/?utility_tariff_id=${tariffId}`}
      id="customise-proposed-tariff-link"
      onClick={(e) => {
        // e.preventDefault()
        dispatch({
          type: 'edit',
          payload: {
            isOpen: true,
            basePath,
            variant,
            resource,
            mode: 'edit',
            id: tariffId,
          },
        })
      }}
    >
      {code}
    </Link>
  )
}
export default UtilityTariffLink

import { makeStyles, Tooltip } from '@material-ui/core'
import { useViewShow } from 'ducks/viewMode'
import { useTranslate } from 'react-admin'
import { SystemObjectType } from 'types/systems'
import { roundKwStc } from 'util/misc'
import { BatteryIcon, ConsumptionIcon, EnergyIcon, STCIcon } from './OverviewIcons'
import { selectedFontColor, unselectedFontColor } from './SystemAccordion'
import SystemName from './SystemName'

type SystemOverviewPropTypes = {
  isSelectedSystem: boolean
  isWaiting: boolean
  isEditing: boolean
  setEditingSystemUuid: Function
  system: SystemObjectType
  hasShadingCalcsAwaitingTrigger: boolean
}

type OverviewFiguresPropTypes = {
  isWaiting: boolean
  isSelectedSystem: boolean
  system: SystemObjectType
  hasShadingCalcsAwaitingTrigger: boolean
}

const useStyles = makeStyles({
  unit: {
    marginLeft: 2,
    fontSize: 10,
    color: (isSelectedSystem) => (isSelectedSystem ? selectedFontColor : unselectedFontColor),
  },
  icon: {
    width: 16,
    height: 16,
    verticalAlign: 'middle',
    fill: (isSelectedSystem) => (isSelectedSystem ? selectedFontColor : unselectedFontColor),
  },
  label: {
    marginLeft: 2,
    verticalAlign: 'middle',
    fontSize: 12,
    marginRight: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    color: (isSelectedSystem) => (isSelectedSystem ? selectedFontColor : unselectedFontColor),
  },
  figureWrapper: {
    display: 'inline-block',
    height: 20,
  },
  container: {
    display: 'flex',
  },
})

export const OverviewFigures = (props: OverviewFiguresPropTypes) => {
  const { isWaiting, isSelectedSystem, system, hasShadingCalcsAwaitingTrigger } = props
  const classes = useStyles(isSelectedSystem)
  const translate = useTranslate()
  const selectedView = window.ViewHelper.selectedView()
  const mapType = selectedView?.mapData?.mapType

  // Prevent crash if system recently deleted
  if (!system) {
    return null
  }

  const consumptionOffsetPercentage =
    system.consumption && system.consumption.consumption_offset_percentage
      ? system.consumption.consumption_offset_percentage
      : 0
  const stcKw = roundKwStc(system.kwStc())

  const batteryTotalKwh = system.batteryTotalKwh().toFixed(1)
  const annualOutput = system.output?.annual ? Math.round(system.output.annual * 100) / 100 : 0
  return (
    <div className={classes.container}>
      <div className={classes.figureWrapper}>
        <STCIcon className={classes.icon} />
        <span style={{ minWidth: 45, maxWidth: 55 }} className={classes.label}>
          {stcKw}
          <span className={classes.unit}>kW</span>
        </span>
      </div>
      <div className={classes.figureWrapper}>
        <ConsumptionIcon className={classes.icon} />
        <span style={{ width: 30 }} className={classes.label}>
          {isWaiting || consumptionOffsetPercentage === null ? (
            '...'
          ) : (
            <>
              {consumptionOffsetPercentage}
              <span className={classes.unit}>%</span>
            </>
          )}
        </span>
      </div>
      <div className={classes.figureWrapper}>
        <BatteryIcon className={classes.icon} />
        <span style={{ minWidth: 45, maxWidth: 50 }} className={classes.label}>
          {batteryTotalKwh}
          <span className={classes.unit}>kWh</span>
        </span>
      </div>
      <div className={classes.figureWrapper}>
        <EnergyIcon className={classes.icon} />
        <span style={{ minWidth: 45, maxWidth: 70 }} className={classes.label}>
          {isWaiting ? (
            '...'
          ) : hasShadingCalcsAwaitingTrigger ? (
            <>
              <Tooltip title={translate('Recalculate to update value')}>
                <span style={{ textDecoration: 'line-through' }}>
                  {annualOutput > 100000 ? Math.round(annualOutput / 1000).toFixed(1) : annualOutput.toFixed()}
                </span>
              </Tooltip>
              <span className={classes.unit}>{annualOutput > 100000 ? 'mWh' : 'kWh'}</span>
            </>
          ) : (
            <>
              {annualOutput > 100000 ? Math.round(annualOutput / 1000).toFixed(1) : annualOutput.toFixed()}
              <span className={classes.unit}>{annualOutput > 100000 ? 'mWh' : 'kWh'}</span>
            </>
          )}
        </span>
      </div>
    </div>
  )
}

const SystemOverview = (props: SystemOverviewPropTypes) => {
  const showSystemsOverview = useViewShow('studio.systems.overview')
  const { isSelectedSystem, isEditing, setEditingSystemUuid, system, isWaiting, hasShadingCalcsAwaitingTrigger } = props
  return (
    <div style={{ textAlign: 'left' }}>
      <SystemName
        system={system}
        isEditing={isEditing}
        isSelectedSystem={isSelectedSystem}
        setEditingSystemUuid={setEditingSystemUuid}
      />
      {showSystemsOverview && <OverviewFigures
        system={system}
        isSelectedSystem={isSelectedSystem}
        isWaiting={isWaiting}
        hasShadingCalcsAwaitingTrigger={hasShadingCalcsAwaitingTrigger}
      />}
    </div>
  )
}

export default SystemOverview

import { Dialog, DialogContent, DialogTitle, LinearProgress } from '@material-ui/core'
import { Button, styled, TextField, Typography } from 'opensolar-ui'
import React, { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { projectInfoSelector } from 'reducer/project/projectInfoReducer'
import appStorage from 'storage/appStorage'
import { LoadingOverlay } from '../styles/styles'
const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 24,
  paddingBottom: 24,
  alignItems: 'center',
}))

const CopyLinkRow = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'stretch',
  gap: 16,
  width: '100%',
  '& div:nth-of-type(1)': {
    display: 'flex',
    flex: 1,
  },
}))

const QrRow = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: theme.palette.grey[100],
  padding: 16,
  borderRadius: 8,
  gap: 16,
  alignItems: 'center',
}))

const QrImgContainer = styled('div')(({ theme }) => ({}))

type PropTypes = {
  relativeUrl: string
  isOpen: boolean
  onDismiss: () => void
}

export const ShareUrlQrCodeDialog: React.FunctionComponent<PropTypes> = ({ relativeUrl, isOpen, onDismiss }) => {
  const translate = useTranslate()
  const project_id = useSelector(projectInfoSelector.getProjectId)
  const orgId = appStorage.getOrgId()

  const [svgContent, setSvgContent] = useState<string | null>(null)
  const [loading, setLoading] = useState(true)

  const linkCopyUrl = window.location.origin + `/#/` + relativeUrl

  useEffect(() => {
    const fetchQRCode = async () => {
      try {
        const res = await fetch(`${window.API_ROOT}/api/generate_qr_code/?url=${relativeUrl}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${appStorage.getToken()}`,
          },
        })

        if (!res.ok) {
          throw new Error(`${res.status} ${res.statusText}`)
        }

        const svgText = await res.text()

        setSvgContent(svgText)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    fetchQRCode()
  }, [orgId, project_id])

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(linkCopyUrl)
      alert('Link copied to clipboard!')
    } catch (err) {
      console.error('Failed to copy: ', err)
    }
  }

  return (
    <Dialog open={isOpen} onClose={onDismiss}>
      {loading ? (
        <LoadingOverlay>
          <LinearProgress variant="indeterminate" />
        </LoadingOverlay>
      ) : (
        <>
          <DialogTitle>{translate('Share Upload Link')}</DialogTitle>
          <StyledDialogContent dividers>
            <CopyLinkRow>
              <div>
                <TextField variant={'outlined'} fullWidth value={linkCopyUrl} />
              </div>
              <Button color={'secondary'} variant="outlined" onClick={handleCopy}>
                {translate('Copy Link')}
              </Button>
            </CopyLinkRow>
            <div>
              <p>or</p>
            </div>
            <QrRow>
              <Typography>
                {translate('You may also scan this QR code on your phone to access the link, or to share via SMS.')}
              </Typography>
              <QrImgContainer>{svgContent && <div dangerouslySetInnerHTML={{ __html: svgContent }} />}</QrImgContainer>
            </QrRow>
          </StyledDialogContent>
        </>
      )}
    </Dialog>
  )
}

import { Typography } from '@material-ui/core'

import CancelIcon from '@material-ui/icons/CloseOutlined'
import classNames from 'classnames'
import React from 'react'
import { useTranslate } from 'react-admin'
import { Theme } from 'types/themes'
import { makeOpenSolarStyles } from '../../../themes/makeOpenSolarStyles'

type StyleProps = {
  noPadding?: boolean
  handleClear(): void
}

const useStyles = makeOpenSolarStyles<StyleProps>(
  (theme: Theme) => ({
    clearFilters: (props) => ({
      width: 'fit-content',
      display: 'inline-flex',
      alignItems: 'center',
      padding: props.noPadding ? '0' : '10px 30px',
      cursor: 'pointer',
      '& svg': {
        height: '20px',
      },
    }),
    text: {
      textDecoration: 'underline',
      whiteSpace: 'nowrap',
    },
  }),
  { name: 'ClearFilterButton' }
)

const ClearFilterButton = (props: { handleClear(): void; noPadding?: boolean }) => {
  const translate = useTranslate()
  const classes = useStyles(props)

  return (
    <div className={classNames('small', classes.clearFilters)} onClick={props.handleClear}>
      <CancelIcon />
      <Typography className={classes.text}>{translate('Clear filters')}</Typography>
    </div>
  )
}

export default ClearFilterButton

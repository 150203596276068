import { DialogActions, DialogTitleProps, makeStyles, Typography, withStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import { Dialog, DialogContent, DialogTitle, IconButton, useComponentVersion } from 'opensolar-ui'

//TODO: should be moved at some point to /elements

export const StyledDialog = withStyles({
  paper: {
    width: 'auto',
  },
})(Dialog)

export const StyledDialog3 = withStyles({
  paper: {
    width: 624,
  },
})(Dialog)

export const StyledDialogContent = withStyles({
  root: {
    padding: '20px',
    maxHeight: 'calc(100vh - 200px)',
  },
})(DialogContent)

export const StyledDialogActions = withStyles({
  root: {},
})(DialogActions)

const useTitleStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.87)',
  },
}))

type TitleProps = DialogTitleProps & {
  onClose?: () => void
}

export const StyledDialogTitle = (props: TitleProps) => {
  const version = useComponentVersion('dialog')
  const classes = useTitleStyles()
  const { children, onClose, ...other } = props
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      {version && version > 1 ? children : <Typography variant="h6">{children}</Typography>}
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}
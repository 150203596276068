import { orgSelectors } from 'ducks/orgs'
import { useSelector } from 'react-redux'
import BasicFilter from '../basic/BasicFilter'
import type {
  ConfigurableFilterOptionsType,
  FilterComponentNodeV2,
  FilterGenericPropsType,
  OptionGenericType,
} from '../typeV2'

export const AVAILABILITY_FILTER_KEY_V2 = 'in_stock'
const defaultValue = ''
const defaultLabel = 'Stock Availability'

const OPTIONS: OptionGenericType<string>[] = [
  { id: 'default', title: 'All', value: defaultValue },
  { id: 'in_stock', title: 'In stock', value: 'true' },
]

const createAvailabilityFilterNode = <T,>({
  label = defaultLabel,
  ...restConfigs
}: ConfigurableFilterOptionsType<string, T>): FilterComponentNodeV2 => {
  const FilterComponent = (filterProps: FilterGenericPropsType) => {
    const enabledDistributors = useSelector(orgSelectors.getEnabledHardwareSuppliers)

    if (enabledDistributors.length === 0) {
      return null
    }

    return (
      <BasicFilter
        label={label}
        disabled={false}
        defaultSelectedOptionValue={defaultValue}
        options={OPTIONS}
        filterKey={AVAILABILITY_FILTER_KEY_V2}
        {...restConfigs}
        {...filterProps}
      />
    )
  }

  return {
    key: AVAILABILITY_FILTER_KEY_V2,
    component: FilterComponent,
  }
}

export default createAvailabilityFilterNode

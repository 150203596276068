var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import createStandardIcon from './createStandardIcon';
var EvChargerIconSvg = function (props) {
    var color = props.color, fill = props.fill, width = props.width, height = props.height, stroke = props.stroke;
    return (_jsxs("svg", __assign({ viewBox: "0 0 32 32", xmlns: "http://www.w3.org/2000/svg" }, props, { children: [_jsx("mask", __assign({ id: "mask0_5809_39504", maskUnits: "userSpaceOnUse", x: "0", y: "0", width: width, height: height }, { children: _jsx("rect", { width: width, height: height, fill: fill }) })), _jsx("g", __assign({ mask: "url(#mask0_5809_39504)" }, { children: _jsx("path", { d: "M11.5416 24.1507L13.9006 19.8427H12.4906V16.4841L10.1059 20.7917H11.5416V24.1507ZM8.00326 13.9967H16.0033V7.48408C16.0033 7.27874 15.9178 7.09063 15.7469 6.91974C15.576 6.74886 15.3879 6.66341 15.1826 6.66341H8.82392C8.61859 6.66341 8.43048 6.74886 8.25959 6.91974C8.0887 7.09063 8.00326 7.27874 8.00326 7.48408V13.9967ZM8.00326 25.3301H16.0033V15.3301H8.00326V25.3301ZM6.66992 26.6634V7.48408C6.66992 6.8703 6.87548 6.35786 7.28659 5.94674C7.6977 5.53563 8.21014 5.33008 8.82392 5.33008H15.1826C15.7964 5.33008 16.3088 5.53563 16.7199 5.94674C17.131 6.35786 17.3366 6.8703 17.3366 7.48408V15.4841H18.6443C19.2203 15.4841 19.7105 15.6862 20.1149 16.0904C20.5191 16.4946 20.7213 16.9847 20.7213 17.5607V23.7017C20.7213 24.1822 20.9153 24.5993 21.3033 24.9531C21.6913 25.3071 22.134 25.4841 22.6316 25.4841C23.1511 25.4841 23.5994 25.3071 23.9763 24.9531C24.3531 24.5993 24.5416 24.1822 24.5416 23.7017V11.8427H24.0803C23.7707 11.8427 23.5138 11.7406 23.3096 11.5364C23.1054 11.3322 23.0033 11.0754 23.0033 10.7661V8.66341H23.5416V7.17608H24.5673V8.66341H25.6956V7.17608H26.7213V8.66341H27.2596V10.7661C27.2596 11.0754 27.1575 11.3322 26.9533 11.5364C26.749 11.7406 26.4921 11.8427 26.1826 11.8427H25.7213V23.7017C25.7213 24.5155 25.4208 25.2125 24.8199 25.7927C24.219 26.3732 23.4896 26.6634 22.6316 26.6634C21.7956 26.6634 21.0716 26.3732 20.4596 25.7927C19.8476 25.2125 19.5416 24.5155 19.5416 23.7017V17.5607C19.5416 17.2959 19.4583 17.0801 19.2916 16.9134C19.1249 16.7467 18.9091 16.6634 18.6443 16.6634H17.3366V26.6634H6.66992Z", fill: fill }) }))] })));
};
export var EvChargerIcon = createStandardIcon(EvChargerIconSvg);

import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined'
import { authSelectors } from 'ducks/auth'
import { permissionsSelectors } from 'ducks/permissions'
import React, { useEffect, useState } from 'react'
import { ReferenceField, useNotify, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { ProjectType } from 'types/projects'
import { ProjectTransactionType } from 'types/projectTransactions'
import { RootState } from 'types/state'
import { currencySymbolForCountry, reloadEntireApp } from 'util/misc'
import { getOrgCountryCodeFromState, getOrgFromState } from 'util/org'
import Button from '../../../../elements/button/Button'
import CreateDialog from './createTransactionDialog'
import EditDialog from './editTransactionDialog'
import TransactionRow from './TransactionRow'

const TRANSACTION_TYPES_TO_HIDE = ['sunlight_placeholder_project', 'sunlight_contract_upload']

type PropTypes = {
  openEditDialog: (toEdit: ProjectTransactionType) => void
}

const TransactionContent: React.FunctionComponent<PropTypes> = (props) => {
  const [transactionsToShow, setTransactionsToShow] = useState<ProjectTransactionType[]>()
  const isStaff = useSelector(authSelectors.getIsStaff)
  const form = useForm()
  const formState = form.getState()
  const currencySymbol: string = useSelector((state: any) =>
    currencySymbolForCountry(getOrgCountryCodeFromState(state))
  )
  const projectRecord = formState.values as ProjectType

  useEffect(() => {
    if (projectRecord?.transactions_data) {
      const allTransactions =
        projectRecord && projectRecord.transactions_data && projectRecord.transactions_data.length > 0
          ? projectRecord.transactions_data
          : null
      if (allTransactions && allTransactions.length > 0) {
        setTransactionsToShow(
          // @ts-ignore  => see ProjectTransactionType vs ProjectTransactionTypeV3 for discrepancies in data that comes back for transactions
          allTransactions?.filter(
            (t) =>
              !(
                TRANSACTION_TYPES_TO_HIDE.includes(t.transaction_type) ||
                (t.external_status_title?.includes('Offline Approval') && !isStaff)
              )
          )
        )
      }
    }
  }, [projectRecord?.transactions_data])

  const renderTransactionsDataRows = () => {
    if (!transactionsToShow || transactionsToShow.length === 0) return null
    return (
      <div style={{ width: '100%' }}>
        {transactionsToShow.map((transaction) => (
          <ReferenceField
            getResourceLinkPath={() => false}
            record={transaction}
            source="payment_option"
            reference="payment_options"
            renderChildrenBeforeRecord={true}
          >
            <TransactionRow
              transaction={transaction}
              key={transaction.id}
              currencySymbol={currencySymbol}
              projectUrl={projectRecord.url}
              openEditDialog={props.openEditDialog}
            />
          </ReferenceField>
        ))}
      </div>
    )
  }

  return (
    <Grid container spacing={3}>
      {renderTransactionsDataRows()}
    </Grid>
  )
}

const restClientInstance = restClient(window.API_ROOT + '/api')

export const CentrixCreditCheckButton = ({ org_id, project_id, refreshView }) => {
  const translate = useTranslate()
  const notify = useNotify()
  return (
    <Button
      variant="contained"
      color="default"
      size="small"
      style={{ marginLeft: 4 }}
      className={'centrixBtn'}
      onClick={(e) => {
        // Request the email send (which will automatically record an incomplete transaction)
        restClientInstance('CUSTOM_POST', 'custom', {
          url: 'orgs/' + org_id + '/projects/' + project_id + '/centrix_credit_check/',
        }).then(
          (response) => {
            notify(translate('Credit Check Complete'))
            refreshView()
          },
          (reject) => {
            notify(translate('Error Running Credit'), 'error')
            refreshView()
          }
        )
      }}
      disabled={false}
    >
      <span style={{ textTransform: 'none' }}>{translate('Run Centrix Credit Check')}</span>
    </Button>
  )
}

const useStyles = makeStyles((theme) => ({
  content: {
    alignItems: 'center',
    justifyContent: 'space-between',
    '&.Mui-expanded': {
      marginBottom: '-2px',
    },
  },
  wrapper: {
    maxHeight: 300,
    overflowY: 'auto',
  },
  disabled: {
    pointerEvents: 'none',
    cursor: 'not-allowed',
  },
  iconTitleWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 20,
  },
  headingTitle: {
    margin: '0 10px',
  },
}))

const TransactionsAccordion = ({ org_id, project_id }: { org_id?: number; project_id: number }) => {
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false)
  const [showCreateDialog, setShowCreateDialog] = useState<boolean>(false)
  const [transactionToEdit, setTransactionToEdit] = useState<ProjectTransactionType | null>(null)
  const enableCentrixCreditCheck = useSelector(
    (state: RootState) => getOrgFromState(state)?.enable_centrix_credit_check
  )

  const classes = useStyles()
  const translate = useTranslate()
  const { allowView, allowCreate, allowEdit, allowDelete } = useSelector(
    permissionsSelectors.getProjectPermissionByKey('info_transactions')
  )

  const onPaymentRequestClick = () => {
    setShowCreateDialog(true)
    // window.location.href = window.location.hostname + '/#/transactions/create?project=' + projectRecord.url
  }

  const openEditDialog = (record: ProjectTransactionType) => {
    setShowEditDialog(true)
    setTransactionToEdit(record)
  }

  const onDismissEditDialog = () => {
    setShowEditDialog(false)
    setTransactionToEdit(null)
  }

  const onDismissCreateDialog = () => {
    setShowCreateDialog(false)
  }

  if (!allowView) {
    return null
  }
  return (
    <Accordion key="transactions" expanded={true}>
      <AccordionSummary
        aria-controls="expansion-content-transactions"
        id="expansion-header-transactions"
        classes={{ content: classes.content }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className={classes.iconTitleWrapper}>
            <ReceiptOutlinedIcon />
            <span className={classes.headingTitle}>{translate('Transactions')}</span>
          </div>
          {allowCreate && <Button size="small" label="Request Payment" onClick={onPaymentRequestClick} />}
        </div>
      </AccordionSummary>

      <div style={{ margin: 12 }}>
        {enableCentrixCreditCheck ? (
          <CentrixCreditCheckButton
            org_id={org_id}
            project_id={project_id}
            refreshView={() => {
              // We have not implemented a way to refresh the transactions list independently of the rest of the page
              // so we are lazy and we just reload the whole page.
              reloadEntireApp()
            }}
          />
        ) : null}
      </div>

      <AccordionDetails classes={{ root: allowEdit ? classes.wrapper : classes.disabled }}>
        <TransactionContent openEditDialog={openEditDialog} />
      </AccordionDetails>
      {showEditDialog && !showCreateDialog && allowEdit && (
        <EditDialog
          showEditDialog={showEditDialog}
          transactionToEdit={transactionToEdit}
          onDismiss={onDismissEditDialog}
          setShowEditDialog={setShowEditDialog}
        />
      )}
      {showCreateDialog && allowCreate && (
        <CreateDialog
          showEditDialog={showCreateDialog}
          transactionToEdit={{}}
          onDismiss={onDismissCreateDialog}
          setShowEditDialog={setShowCreateDialog}
        />
      )}
    </Accordion>
  )
}

export default TransactionsAccordion

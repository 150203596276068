import { Chip } from 'opensolar-ui'
import { FC } from 'react'
import { CodeBlock } from '../CodeBlock'
import ComponentPage from '../ComponentPage'
import { FeatureGrid } from '../FeatureGrid'
import { ChipBasic, ChipColours, ChipIcons, ChipSizes, ChipVariants } from './ChipUsage'

const ChipUsage_code = require('!!raw-loader!./ChipUsage').default

export const DemoChip: FC = () => {
  return (
    <ComponentPage
      name={'Chip'}
      import="import { Chip } from 'opensolar-ui'"
      description={'Chip is used to add small tags/labels.'}
      links={{ mui4: 'https://v4.mui.com/components/chips/', mui5: 'https://mui.com/material-ui/react-chip/' }}
    >
      <CodeBlock name="Chip Basic Usage" code={ChipUsage_code} codePart={1} description="How to use the label prop">
        <ChipBasic />
      </CodeBlock>

      <CodeBlock name="Chip Colors" code={ChipUsage_code} codePart={2} description="How to use the color prop">
        <ChipColours />
      </CodeBlock>

      <CodeBlock name="Chip Sizes" code={ChipUsage_code} codePart={3} description="How to use the size prop">
        <ChipSizes />
      </CodeBlock>

      <CodeBlock
        name="Chip Icons"
        code={ChipUsage_code}
        codePart={4}
        description="How to use the icon/deleteIcon props"
      >
        <ChipIcons />
      </CodeBlock>

      <CodeBlock name="Chip Variants" code={ChipUsage_code} codePart={5} description="How to use the variant prop">
        <ChipVariants />
      </CodeBlock>

      <CodeBlock name="Chip Feature Grid" hideGrid={true}>
        <FeatureGrid
          component={Chip}
          propsGen={(row, col, lastHeader) => {
            return { label: 'Chip', ...row, ...col, ...lastHeader }
          }}
          colHeaders={[]}
          rowHeaders={[]}
          cols={[
            { color: 'default' },
            { color: 'primary' },
            { color: 'secondary' },
            { color: 'info' },
            { color: 'success' },
            { color: 'warning' },
            { color: 'error' },
          ]}
          rows={[
            { _type: 'heading', _title: 'Small Size (default)', size: 'small' },
            { variant: 'default' },
            { variant: 'outlined' },
            { variant: 'rounded' },
            { _type: 'heading', _title: 'Medium Size', size: 'medium' },
            { variant: 'default' },
            { variant: 'outlined' },
            { variant: 'rounded' },
          ]}
        />
      </CodeBlock>
    </ComponentPage>
  )
}

import React, { useEffect } from 'react'
import { useLogout } from 'react-admin'

export const PageLogout = () => {
  const logout = useLogout()
  useEffect(() => {
    //it's important to not record current path otherwise it creates an infinite login loop
    const recordCurrentPath = false
    logout({}, '/login', recordCurrentPath)
  }, [])

  return (
    <div style={{ position: 'fixed', top: '50%', left: '50%' }} className="processing-animation">
      <div style={{ background: '#000' }}></div>
      <div style={{ background: '#000' }}></div>
      <div style={{ background: '#000' }}></div>
      <p>Logging Out</p>
    </div>
  )
}

export default PageLogout

import { useMediaQuery } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import WbSunnyOutlinedIcon from '@material-ui/icons/WbSunnyOutlined'
import { terminateCurrentTour } from 'actions/tourActions'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { helpCenterActions } from 'ducks/helpCenter'
import { permissionsSelectors } from 'ducks/permissions'
import Alert from 'elements/Alert'
import { Button } from 'opensolar-ui'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { Theme } from 'types/themes'
import useTranslateParse from '../../../util/useTranslateParse'
import { TOUR_Z_INDEX } from '../tools/common'
import { ToolTipPropsType, TourConfigType } from '../types'
import DefaultTooltip from './DefaultToolTip'

type PropsType = {
  open: boolean
  handleClose: (force?: true) => void | boolean
  handleStopTour: (e?: React.MouseEvent<HTMLButtonElement>) => void
  handleStartTour: (e?: React.MouseEvent<HTMLButtonElement>) => void
}

const AlertDialog: FC<PropsType> = ({ open, handleClose, handleStopTour, handleStartTour }) => {
  const translate = useTranslate()

  useEffect(() => {
    if (open) logAmplitudeEvent('fuji_confirm_cancel_dialog_shown', {})
  }, [open])

  return (
    <Dialog
      style={{ zIndex: TOUR_Z_INDEX }}
      maxWidth="xs"
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{translate('Are you sure you want to skip?')}</DialogTitle>

      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{ fontWeight: 'lighter', color: 'rgba(0,0,0,0.87)', lineHeight: '1.4em' }}
        >
          {translate(
            'The tour will take you less than 4 minutes and is the best way for you to quickly learn your way around OpenSolar.'
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => handleClose()}
          onTouchStart={(e) => {
            e.stopPropagation()
            if (handleClose()) {
              handleClose(true)
            }
          }}
        >
          <span>{translate('Cancel')}</span>
        </Button>
        <Button
          id="skip_tour_btn_confirm"
          variant="contained"
          onClick={handleStopTour}
          onTouchStart={(e) => {
            e.stopPropagation()
            if (handleStopTour) {
              handleStopTour()
            }
          }}
          autoFocus
        >
          <span>{translate('Skip Tour')}</span>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const checkPermissionsForTour = (rights: any) => {
  if (rights) {
    const {
      project,
      info_contact_info_basic,
      design_pricing,
      proposal,
      energy_usage_tariff,
      design,
      design_payment_options,
    } = rights
    let hasPermissionsToStartTour = true
    if (!project.view || !project.edit || !project.create) {
      hasPermissionsToStartTour = false
    }

    if (!energy_usage_tariff.view) {
      hasPermissionsToStartTour = false
    }

    if (!proposal.view) {
      hasPermissionsToStartTour = false
    }

    if (!info_contact_info_basic.view || !info_contact_info_basic.edit || !info_contact_info_basic.create) {
      hasPermissionsToStartTour = false
    }

    if (!design.view || !design.edit) {
      hasPermissionsToStartTour = false
    }

    if (!design_pricing.view || !design_pricing.edit) {
      hasPermissionsToStartTour = false
    }

    if (!design_payment_options.view || !design_payment_options.edit) {
      hasPermissionsToStartTour = false
    }

    return hasPermissionsToStartTour
  }
  return false
}

const Tooltip = (props: ToolTipPropsType) => {
  const [showAlertDialog, setAlertDialog] = useState(false)
  const accessRightsSetting = useSelector(permissionsSelectors.getPermissionsSetting)
  const { handleNext, handlePrev, handleCancel } = props
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const isMobileDevice = useMemo(() => window.Utils?.isTouchDevice(), [])
  const dispatch = useDispatch()
  const closeMobile = () => {
    window.location.href = window.location.href.replace('?tour=fuji&step=start', '')
    // solving for a weird little race condition that caused a flash of another dialog while closing
    setTimeout(() => dispatch(terminateCurrentTour()), 500)
  }
  const translate = useTranslate()
  const translateParse = useTranslateParse()
  const hasPermissionsToStart = useMemo(() => checkPermissionsForTour(accessRightsSetting), [accessRightsSetting])
  return (
    <div
      id="start_tooltip"
      style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)', position: 'fixed', zIndex: TOUR_Z_INDEX }}
    >
      <DefaultTooltip
        title={translate('Welcome to OPENSOLAR v2.0!')}
        icon={WbSunnyOutlinedIcon}
        onClose={() => (isSmall ? closeMobile() : setAlertDialog(true))}
        disableDrag={true}
        content={
          isSmall || isMobileDevice ? (
            <p>
              {translate(
                "The OpenSolar 2.0 tour is only available on larger screens. Next time you login on a tablet or a computer you'll be asked if you want to take the tour."
              )}
            </p>
          ) : (
            <>
              <p style={{ lineHeight: '1.4em' }}>
                {translateParse(
                  "This quick, interactive tour will guide you through creating your first customer proposal in less than <strong>four minutes<strong>. Let's get started!",
                  { strong: (label) => <strong>{label}</strong> }
                )}
              </p>
              {!hasPermissionsToStart && (
                <Alert severity="error">
                  <div>
                    Due to your restricted permissions, you would not be able to complete the tour. You can contact your
                    Admin to adjust your permissions. If you have questions about how to use the app, you can visit our
                    HelpCenter at{' '}
                    <a href="https://support.opensolar.com/hc/en-us" target="_blank" rel="noreferrer">
                      https://support.opensolar.com/hc/en-us.
                    </a>
                  </div>
                </Alert>
              )}
            </>
          )
        }
        actions={
          isSmall || isMobileDevice ? (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingLeft: '20px' }}>
              <Button
                onClick={closeMobile}
                onTouchStart={(e) => {
                  e.stopPropagation()
                  closeMobile()
                }}
                style={{ marginRight: 5 }}
              >
                <span>{translate('Dismiss')}</span>
              </Button>
            </div>
          ) : (
            <>
              <Button
                id="skip_tour_btn"
                variant="contained"
                onClick={() => setAlertDialog(true)}
                onTouchStart={(e) => {
                  e.stopPropagation()
                  if (setAlertDialog) {
                    setAlertDialog(true)
                  }
                }}
                style={{ marginRight: 10 }}
              >
                <span>{translate('Skip')}</span>
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={!hasPermissionsToStart}
                onClick={(e) => {
                  e.stopPropagation()
                  dispatch(helpCenterActions.setHelpCenterOpen(false))
                  if (handleNext) {
                    handleNext()
                  }
                }}
                onTouchStart={(e) => {
                  e.stopPropagation()
                  dispatch(helpCenterActions.setHelpCenterOpen(false))
                  if (handleNext) {
                    handleNext()
                  }
                }}
              >
                <span>{translate('Start Tour')}</span>
              </Button>
              <AlertDialog
                open={showAlertDialog}
                handleClose={() => setAlertDialog(false)}
                handleStopTour={() => handleCancel && handleCancel()}
                handleStartTour={() => handleNext && handleNext()}
              />
            </>
          )
        }
      />
    </div>
  )
}

const StartConfig: TourConfigType = {
  spotlights: [],
  tooltips: [<Tooltip key={1} />],
}

export default StartConfig

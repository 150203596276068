import TourTooltip from 'elements/tooltip/TourTooltip'
import { TourConfigType } from 'persistentContent/tours/types'
import { useTranslate } from 'ra-core'
import { FC } from 'react'

const StepTooltip: FC = (props) => {
  const translate = useTranslate()
  return (
    <TourTooltip
      title={translate('Introducing Workflows')}
      subtext={translate(
        'Did you know, you can now customise and add more stages to properly track your project progress? Click on a stage to try it out.'
      )}
      {...props}
    />
  )
}

const Workflows: TourConfigType = {
  spotlights: [{ targetId: 'Project-Workflows-Section', autoScroll: true }],
  tooltips: [
    {
      targetId: 'Project-Workflows-Section',
      placement: 'bottom-start',
      component: StepTooltip,
    },
  ],
}

export default Workflows

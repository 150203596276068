import GenericButton from 'elements/button/GenericButton.js'
import { ajaxPost, GenerateAndDownloadPvsellProposal } from 'projectSections/sections/proposal/PvsellProposalDialog'
import React, { useEffect, useRef, useState } from 'react'

export const getPvsellAccessCredentialsFromProposalData = (proposalDataRaw) => {
  // Use raw access token if available (e.g. for a Pro) otherwise use encrypted access token (for a Customer)
  const pvsellAccessTokenEncrypted = proposalDataRaw[0].projects[0].proposal_data?.pvsell_access_token_encrypted
  const pvsellAccessTokenRaw = proposalDataRaw[0].projects[0].proposal_data?.org?.api_key_pvsell
  const pvsellAccessToken = pvsellAccessTokenRaw ? pvsellAccessTokenRaw : pvsellAccessTokenEncrypted
  const pvsellAccessTokenParamName = pvsellAccessTokenRaw ? 'access-token' : 'data'

  return {
    pvsellQuerystringParamName: pvsellAccessTokenParamName,
    pvsellQuerystringValue: pvsellAccessToken,
  }
}

const IframeWithPost = ({ systemIndex, paymentOptionIndex, proposalDataRaw, proposalId }) => {
  const iframeRef = useRef()
  const projectId = proposalDataRaw[0].projects[0].id
  const [iframeHeight, setIframeHeight] = useState()
  const { pvsellQuerystringParamName, pvsellQuerystringValue } = getPvsellAccessCredentialsFromProposalData(
    proposalDataRaw
  )

  const urls = {
    iframe:
      window.SUNWIZ_API_DOMAIN +
      '/proposal-iframe-v2/?' +
      pvsellQuerystringParamName +
      '=' +
      pvsellQuerystringValue +
      '&project_id=' +
      projectId +
      '&proposal_id=' +
      proposalId,
    saveProject:
      window.SUNWIZ_API_DOMAIN +
      '/standalone/canvas/save-project?' +
      pvsellQuerystringParamName +
      '=' +
      pvsellQuerystringValue +
      '&project_id=' +
      projectId,
    setSystemAndPaymentOption:
      window.SUNWIZ_API_DOMAIN +
      '/standalone/canvas/save-system-index?' +
      pvsellQuerystringParamName +
      '=' +
      pvsellQuerystringValue +
      '&project_id=' +
      projectId,
  }

  useEffect(() => {
    function iframeListener(message) {
      if (message.origin === window.SUNWIZ_API_DOMAIN && message.data?.height) {
        setIframeHeight(message.data?.height + 100)
      }
    }
    window.addEventListener('message', iframeListener)
    return () => window.removeEventListener('message', iframeListener)
  }, [])

  // const [loading, setLoading] = useState(true)
  const [dataSent, setDataSent] = useState(false)
  const [systemAndPaymentOptionSent, setSystemAndPaymentOptionSent] = useState(false)
  useEffect(() => {
    // setLoading(true)
    // setSystemAndPaymentOptionSent(false)

    var saveSystemAndPaymentOption = () => {
      fetch(urls.setSystemAndPaymentOption, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify({ active_system: systemIndex + '', active_payment: paymentOptionIndex }),
      })
        .then((response) => {
          // Do not expect any response content, just check for 200 result
          if (response.status === 200 || response.status === 204) {
            setSystemAndPaymentOptionSent(true)
            const PvsellIFrame = iframeRef
            let iframeWindow = PvsellIFrame.current?.contentWindow
            iframeWindow?.postMessage('proposal.refresh', '*')
          } else {
            throw new Error('Error setting system and payment option to display')
          }
        })
        .catch((error) => {
          console.error('Error:', error)
        })
      // .finally(() => {
      //   setLoading(false)
      // })
    }

    var saveProject = () => {
      ajaxPost(
        urls.saveProject,
        {
          project_id: projectId,
          data: JSON.stringify(proposalDataRaw),
        },
        {
          success: function (response) {
            setDataSent(true)
            saveSystemAndPaymentOption()
          },
          error: (error) => {
            console.error('Error:', error)
          },
        }
      )
    }

    if (!dataSent) {
      saveProject()
    } else {
      saveSystemAndPaymentOption()
    }
  }, [systemIndex, paymentOptionIndex])
  return systemAndPaymentOptionSent ? (
    <div>
      <iframe
        ref={iframeRef}
        style={{ border: 'none', width: '100%', pointerEvents: 'all' }}
        height={iframeHeight === undefined ? 10000 : iframeHeight}
        id="SunWizHtml"
        src={urls.iframe}
        seamless="seamless"
      ></iframe>
      <GenericButton
        labelColor="#FFFFFF"
        backgroundColor="#42C310"
        backgroundColorOnHover="#42C310"
        style={{ padding: 5 }}
        wrapperStyle={{ pointerEvents: 'auto', display: 'block', textAlign: 'center' }}
        onClick={() => {
          // We could also use projects[0].proposal_data.proposal_template_settings.pvprosell_template_id
          // instead of proposalId. We should choose one and remove duplication in future...
          GenerateAndDownloadPvsellProposal(projectId, proposalId, () => {}, 'pdf')
        }}
        label={'Generate & Download PDF'}
      />
    </div>
  ) : (
    <div>Loading</div>
  )
}

export default IframeWithPost

// @ts-nocheck
import { CircularProgress } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import GenericButton from 'elements/button/GenericButton'
import { Button } from 'opensolar-ui'
import React, { CSSProperties, useState } from 'react'
import { useTranslate } from 'react-admin'
import restClient from 'restClient'
import { ProjectType } from '../../../types/projects'
import SunlightTransferLoadingText from './SunlightTransferLoadingText'

let restClientInstance = restClient(window.API_ROOT + '/api')
const CONFIG = {
  logo: 'logo-sunlight-financial.png',
  logo_small: 'logo-sunlight-financial-small.png',
  studio_banner_title: 'Simplify Your Sale!',
  name: 'sunlight',
  themeColor: 'rgb(254, 184, 22)',
  themeColorDark: 'rgb(232, 143, 36)',
}

const buttonsWrapperStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
  width: '100%',
  marginTop: '15px',
}

const cancelButtonStyle: CSSProperties = {
  backgroundColor: '#f0f0f0',
  textTransform: 'none',
  minWidth: '220px',
  margin: '15px',
}

const primaryButtonStyle: CSSProperties = {
  minWidth: '220px',
  margin: '15px',
}

type PropTypes = {
  project: ProjectType
  orgId: number
  systemUuid: string | null
  paymentOptionId: number | null | undefined
  labelColor: string
  backgroundColor: string
  backgroundColorOnHover: string
  onBack: () => void
  onFinish: () => void
}

const SunlightTransferPrequal: React.FunctionComponent<PropTypes> = (props) => {
  const [prequalIsLoading, setPrequalIsLoading] = useState<boolean>(false)

  const translate = useTranslate()

  const LoadingIcon = (
    <CircularProgress
      size={30}
      //@ts-ignore
      left={-5}
      top={-2}
      status="loading"
      style={{
        color: CONFIG.themeColor,
        display: 'inline-block',
        float: 'none',
        position: 'relative',
      }}
    />
  )

  const createPrequal = () => {
    if (!props.paymentOptionId) return
    setPrequalIsLoading(true)
    const systemUuid = props.systemUuid ? props.systemUuid : '0'
    restClientInstance('CUSTOM_POST', 'custom', {
      url:
        'orgs/' +
        props.orgId +
        '/projects/' +
        props.project.id +
        '/apply_for_finance/' +
        systemUuid +
        '/' +
        props.paymentOptionId +
        '/sunlight_loan_prequal/',
    })
      .then((response: any) => {
        if (response.data?.status === true) {
          let successMessage = "This Prequalification Application has been created in Sunlight's Orange Portal"
          window.Designer.showNotification(successMessage, 'success')
          props.onFinish()
        } else {
          window.Designer.showNotification(translate('Unable to create application in Orange Portal'), 'danger')
        }
        let source = 'unknown'
        if (window.location.href?.includes('/projects/')) source = 'projects page'
        else if (window.location.href?.includes('#/info/')) source = 'info page'
        else if (window.location.href?.includes('#/myenergy/')) source = 'my energy'
        logAmplitudeEvent('integrated_finance_application_sent', {
          source: source,
          is_prequal: 'yes',
          integration: 'sunlight',
          project_id: props.project.id,
        })
      })
      .catch((err: any) => {
        console.log('err', err)
        window.Designer.showNotification(translate('Unable to create application in Orange Portal'), 'danger')
        let source = 'unknown'
        if (window.location.href?.includes('/projects/')) source = 'projects page'
        else if (window.location.href?.includes('#/info/')) source = 'info page'
        else if (window.location.href?.includes('#/myenergy/')) source = 'my energy'
        logAmplitudeEvent('integrated_finance_application_error', {
          source: source,
          is_prequal: 'yes',
          integration: 'sunlight',
        })
      })
      .finally(() => {
        setPrequalIsLoading(false)
      })
  }
  if (!props.project?.id || !props.orgId) return null
  return (
    <>
      <p>
        When you click "Proceed" you will create a record for this project in the Sunlight portal - no need to re-enter
        the customer or project information. When this customer is ready to move forward, you can access this
        prequalification application in the Sunlight portal.
      </p>
      <div style={buttonsWrapperStyle}>
        {!prequalIsLoading && (
          <Button variant="contained" onClick={() => props.onBack()} style={cancelButtonStyle}>
            <span>{translate('Cancel')}</span>
          </Button>
        )}
        <GenericButton
          onClick={() => createPrequal()}
          style={primaryButtonStyle}
          startIcon={
            prequalIsLoading ? (
              LoadingIcon
            ) : (
              <img
                alt={CONFIG.name}
                src={`${window.PUBLIC_URL}/images/${CONFIG.logo_small}`}
                style={{ maxHeight: 24, maxWidth: 60 }}
              />
            )
          }
          labelWrapperStyle={{ padding: '0 8px', whiteSpace: 'normal' }}
          label={prequalIsLoading ? translate('Creating prequalification...') : translate('Proceed')}
          labelColor={props.labelColor}
          backgroundColor={props.backgroundColor}
          backgroundColorOnHover={props.backgroundColorOnHover}
          disabled={prequalIsLoading}
          tooltipLabel={null}
        />
      </div>
      <SunlightTransferLoadingText />
    </>
  )
}
export default SunlightTransferPrequal

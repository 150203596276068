// @ts-nocheck
import { makeStyles } from '@material-ui/core/styles'
import Button from 'elements/proUXButtons/ProUXButton'
import SaveButton from 'elements/proUXButtons/SaveButton'
import DialogEditor from 'projectSections/elements/DialogEditor'
import React, { FC, useCallback } from 'react'
import { Create, SimpleForm, Toolbar, useNotify, useTranslate } from 'react-admin'
import CustomFieldInputs from '../customFields/CustomFieldInputs'
import { formatSubmitValues, validateLabel } from '../customFields/CustomFields'

const useStylesToolbar = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
  },
})

const useStyles = makeStyles({
  footer: {
    margin: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  rightButtonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  cancelButtonWrapper: {
    marginRight: '15px',
  },
})

type PropTypes = {
  onSuccess: Function
  onFailure: Function
  onDismiss: () => void
  record: any
  handleSubmitWithRedirect: Function
  handleSubmit: Function
  invalid: boolean
  pristine: boolean
  saving: boolean
}

const ToolBarContents = (props: PropTypes) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <div className={classes.footer}>
      <div className={classes.rightButtonGroup}>
        <div className={classes.cancelButtonWrapper}>
          <Button type="text" label="Cancel" onClick={props.onDismiss} />
        </div>
        <div>
          <SaveButton
            label={translate('Save')}
            {...props}
            redirect={undefined}
            submitOnEnter={true}
            onSave={(values: object, redirect: string, form: any, defaultSaveFunc: Function) => {
              defaultSaveFunc(values, redirect, { onSuccess: props.onSuccess, onFailure: props.onFailure })
            }}
            saving={props.saving}
          />
        </div>
      </div>
    </div>
  )
}

const CreateCustomFieldToolbar: React.FunctionComponent<PropTypes> = (props) => {
  return (
    <Toolbar {...props} classes={useStylesToolbar()}>
      <ToolBarContents {...props} />
    </Toolbar>
  )
}

const CreateCOGSCustomField = (props: any) => {
  const translate = useTranslate()
  return (
    <Create title={'Create Custom Field'} {...props}>
      <SimpleForm
        validate={validateLabel}
        formatSubmitValues={formatSubmitValues}
        toolbar={
          <CreateCustomFieldToolbar
            {...props}
            onDismiss={props.onDismiss}
            onSuccess={props.onSuccess}
            onFailure={props.onFailure}
          />
        }
      >
        <CustomFieldInputs model={props.mode} disableFieldSelector={false} disableLocationSelector={true} />
        <i>{translate('Please save and refresh the page to view the custom fields.')}</i>
      </SimpleForm>
    </Create>
  )
}

type PropsType = {
  mode: string
  handleClose: Function
}

const CreateCustomCOGSFieldDialog: FC<PropsType> = ({ handleClose, mode }) => {
  const notify = useNotify()
  const onSuccess = () => {
    notify('Success. Please save and refresh the page to view the custom fields.', 'success')
    handleClose()
  }
  const onFailure = useCallback((e: any) => notify(e.message, 'warning'), [])
  return (
    <DialogEditor
      //@ts-ignore
      isOpen={!!mode}
      options={{ maxWidth: 'lg' }}
      handleClose={handleClose}
      title={null}
      editorComponent={
        <CreateCOGSCustomField
          basePath={'/custom_fields'}
          resource={'custom_fields'}
          mode={mode}
          redirect={false}
          title={false}
          hideBreadCrumbs={true}
          style={{ padding: 0 }}
          onDismiss={handleClose}
          onSuccess={onSuccess}
          onFailure={onFailure}
        />
      }
    />
  )
}

export default CreateCustomCOGSFieldDialog

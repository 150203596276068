import { GET_LIST } from 'ra-core'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import restClient from 'restClient'
import { PremiumProductDetailType, ReduxActionType } from 'types/global'
export const GET_PREMIUM_PRODUCTS = 'GET_PREMIUM_PRODUCTS'
export const GET_PREMIUM_PRODUCTS_SUCCESS = 'GET_PREMIUM_PRODUCTS_SUCCESS'
export const GET_PREMIUM_PRODUCTS_FAIL = 'GET_PREMIUM_PRODUCTS_FAIL'

export type PremiumProductsStoreType = {
  availableProducts: PremiumProductDetailType[]
  loading: boolean
}

const initialState: PremiumProductsStoreType = {
  availableProducts: [],
  loading: false,
}

export default (state: PremiumProductsStoreType = initialState, action: ReduxActionType) => {
  switch (action.type) {
    case GET_PREMIUM_PRODUCTS:
      return {
        ...state,
        isLoading: true,
      }
    case GET_PREMIUM_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        availableProducts: action?.payload?.data?.available_products,
      }
    case GET_PREMIUM_PRODUCTS_FAIL:
      return {
        ...state,
        isLoading: false,
      }
  }
  return state
}

export function* getPremiumProductsSaga(action: ReduxActionType) {
  try {
    const availableProducts = yield call(doGetPremiumProducts)
    yield put({ type: GET_PREMIUM_PRODUCTS_SUCCESS, payload: { data: { available_products: availableProducts } } })
  } catch (e) {
    yield put({ type: GET_PREMIUM_PRODUCTS_FAIL, key: action.payload.key })
  }
}

export const doGetPremiumProducts = () => {
  return new Promise((resolve, reject) => {
    const restClientInstance = restClient(window.API_ROOT + '/api')
    restClientInstance(GET_LIST, 'wallet_products', {})
      .then((response: any) => {
        resolve(response.data)
      })
      .catch((err: any) => {
        console.log('err', err)
        reject(false)
      })
  })
}

export const getPremiumProducts = () => {
  return {
    type: GET_PREMIUM_PRODUCTS,
  }
}

export const premiumProductsSelectors = {
  getIsLoading: (state: any): boolean => !!state.premiumProducts?.loading,
  getProducts: (state: any): PremiumProductDetailType[] => state.premiumProducts.availableProducts,
}

export function* premiumProductsSagas() {
  yield all([takeEvery(GET_PREMIUM_PRODUCTS, getPremiumProductsSaga)])
}

import { RootState } from 'types/state'

export const MARK_PAYMENT_COMPLETE = 'MARK_PAYMENT_COMPLETE'

export const markPaymentComplete = () => {
  return {
    type: MARK_PAYMENT_COMPLETE,
  }
}

export type PaymentsPageState = {
  paymentIsComplete: boolean
}

const defaultState: PaymentsPageState = {
  paymentIsComplete: false,
}

export default function paymentsPageReducer(
  previousState: PaymentsPageState | undefined = defaultState,
  { type, payload }: { type: string; payload: any }
) {
  switch (type) {
    case MARK_PAYMENT_COMPLETE:
      // Clear all permissions caches when org switches
      return {
        ...previousState,
        paymentIsComplete: true,
      }
  }

  return previousState
}

export const paymentsPageSelectors = {
  getIsPaymentComplete: (state: RootState) => {
    return state.paymentsPage?.paymentIsComplete
  },
}

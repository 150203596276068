import SideDrawer from 'elements/drawer/SideDrawer'
import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'types/state'
import { setToggleBom } from '../../../../../reducer/designer/view'
import BomDrawerContent from './BomDrawerContent'

const BomDrawer = () => {
  const dispatch = useDispatch()
  const bomToggle = useSelector((state: RootState) => state.designer.view.bomToggle)

  return (
    <SideDrawer
      unmountOnExit={true}
      open={bomToggle}
      setOpen={(open) => dispatch(setToggleBom(open))}
      side={'right'}
      disabled={false}
      DrawerPullLabel={'BOM'}
    >
      <BomDrawerContent />
    </SideDrawer>
  )
}

export default memo(BomDrawer)

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import { ThemeProvider } from '@material-ui/styles';
import { createMyTheme as createMuiTheme, Notification } from 'ra-ui-materialui';
import { createElement, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import CoreAdminRouter from './CoreAdminRouter';
var DefaultLayout = function (_a) {
    var children = _a.children;
    return _jsx(_Fragment, { children: children });
};
export var CoreAdminUI = function (_a) {
    var _b = _a.catchAll, catchAll = _b === void 0 ? Noop : _b, children = _a.children, _c = _a.customRoutes, customRoutes = _c === void 0 ? [] : _c, dashboard = _a.dashboard, _d = _a.layout, layout = _d === void 0 ? DefaultLayout : _d, _e = _a.loading, loading = _e === void 0 ? Noop : _e, _f = _a.loginPage, loginPage = _f === void 0 ? false : _f, persistentContent = _a.persistentContent, logout = _a.logout, menu = _a.menu, // deprecated, use a custom layout instead
    theme = _a.theme, _g = _a.title, title = _g === void 0 ? 'React Admin' : _g;
    return (_jsxs("div", { children: [_jsxs(Switch, { children: [loginPage !== false && loginPage !== true ? (_jsx(Route, { exact: true, path: "/login", render: function (props) {
                            return createElement(loginPage, __assign(__assign({}, props), { title: title, theme: theme }));
                        } })) : null, _jsx(Route, { path: "/", render: function (props) { return (_jsx(CoreAdminRouter, __assign({ catchAll: catchAll, customRoutes: customRoutes, dashboard: dashboard, layout: layout, loading: loading, logout: logout && createElement(logout), menu: menu, theme: theme, title: title }, props, { children: children }))); } })] }), persistentContent, _jsx(Notification, {})] }));
};
var Noop = function () { return null; };
var CoreAdminUIWithTheme = function (_a) {
    var theme = _a.theme, themePackages = _a.themePackages, props = __rest(_a, ["theme", "themePackages"]);
    var themeProp = useRef(theme);
    var themeOverride = themePackages && themePackages[theme];
    var _b = useState(createMuiTheme(themeOverride)), MuiTheme = _b[0], setTheme = _b[1];
    useEffect(function () {
        if (themeProp.current !== theme) {
            themeProp.current = theme;
            setTheme(createMuiTheme(themeOverride));
        }
    }, [themeOverride, themeProp, MuiTheme, setTheme]);
    return (_jsx(ThemeProvider, __assign({ theme: MuiTheme }, { children: _jsx(CoreAdminUI, __assign({}, props)) })));
};
var mapStateToProps = function (state) {
    return {
        theme: state.theme || 'opensolar',
    };
};
export default connect(mapStateToProps)(CoreAdminUIWithTheme);

import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import PhoenixRolePrompt from 'Designer/StudioErrorPrompts/PhoenixRolePrompt'
import { Button } from 'opensolar-ui'
import DividendConnectRoleDialog from 'projectSections/sections/design/systems/tabs/paymentOptions/financialIntegrations/dividend/DividendConnectRoleDialog'
import { useNotify } from 'ra-core'
import React, { useEffect, useState } from 'react'
import { useFormState } from 'react-final-form'
import MosaicConnectRoleDialogContent from 'resources/integrations/mosaic/MosaicConnectRoleDialogContent'
import SungageConnectRoleDialogContent from 'resources/integrations/sungage/SungageConnectRoleDialogContent'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { parseJsonSafe, reloadEntireApp } from 'util/misc'

type PropTypes = {
  orgName: string
  orgId: number
  integration: string
}

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    margin: '10px',
    display: 'flex',
    flexDirection: 'column',
    width: '400px',
  },
}))

const FIELD_MAP = {
  mosaic: 'mosaic_sales_rep_id',
  dividend: 'dividend_contact_id',
  phoenix: 'phoenix_user_email',
  sungage: 'sungage_email',
}

const IntegrationRoleConnectionButton: React.FC<PropTypes> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [currentIntegrationContactValue, setCurrentIntegrationContactValue] = useState<string | undefined>(undefined)

  const classes = useStyles()
  const notify = useNotify()
  const thisRole = useFormState().values

  const onSuccess = () => {
    notify('Your user connection has been saved!', 'success')
    setIsOpen(false)
    reloadEntireApp()
  }

  useEffect(() => {
    if (props.orgId && thisRole.integration_json) {
      if (props.orgId) {
        let integrationJSON = parseJsonSafe(thisRole.integration_json)?.[props.integration]
        const fieldName = FIELD_MAP[props.integration]
        if (integrationJSON && integrationJSON[props.orgId]?.[fieldName])
          setCurrentIntegrationContactValue(integrationJSON[props.orgId]?.[fieldName])
      }
    }
  }, [thisRole, props.orgId])

  return (
    <div className={classes.wrapper}>
      <Button onClick={() => setIsOpen(true)} variant="outlined">
        <span>
          {currentIntegrationContactValue ? 'Edit' : 'Set'} user connection for {props.orgName}
        </span>
      </Button>
      {/* dividend stores an id, it'll just be confusing to show this to the user */}
      {props.integration !== 'dividend' && currentIntegrationContactValue && (
        <span className="small">(Currently set as {currentIntegrationContactValue})</span>
      )}
      {isOpen && (
        <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
          <DialogTitle>Connect this User to Your Lender</DialogTitle>
          <DialogContent>
            <>
              {props.integration === 'mosaic' && (
                <MosaicConnectRoleDialogContent
                  pendingPmtOrgId={props.orgId}
                  onClose={() => setIsOpen(false)}
                  onSuccess={onSuccess}
                  project={undefined}
                  //@ts-ignore
                  roleToShow={thisRole}
                />
              )}
              {props.integration === 'dividend' && (
                <DividendConnectRoleDialog
                  onClose={() => setIsOpen(false)}
                  onSuccess={onSuccess}
                  // @ts-ignore
                  role={thisRole}
                  orgId={props.orgId}
                />
              )}
              {props.integration === 'phoenix' && (
                <PhoenixRolePrompt
                  onClose={() => setIsOpen(false)}
                  isRoleEdit={true}
                  roleId={thisRole?.id}
                  systemUuid={undefined}
                  integration={'phoenix'}
                  message={undefined}
                  currentPhoenixEmail={'ianopen'}
                />
              )}
              {props.integration === 'sungage' && (
                <SungageConnectRoleDialogContent
                  pendingPmtOrgId={props.orgId}
                  onClose={() => setIsOpen(false)}
                  onSuccess={onSuccess}
                  project={undefined}
                  //@ts-ignore
                  roleToShow={thisRole}
                />
              )}
            </>
          </DialogContent>
        </Dialog>
      )}
    </div>
  )
}
export default IntegrationRoleConnectionButton

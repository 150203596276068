import { Switch } from "opensolar-ui"
import { useTranslate } from 'ra-core'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type PropTypes = {
  label: string
  isToggledOn: boolean
  setEmailBcc: () => void
}

const useStyles = makeOpenSolarStyles((theme) => ({
  bccToggleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '90%',
  },
}))

const EMAIL_RECIPIENTS_MAP = {
  assigned_team_member: 'Assigned Team Member',
  assigned_salesperson: 'Salesperson',
  assigned_designer: 'Designer',
  assigned_site_inspector: 'Site Inspector',
  assigned_installer: 'Installer',
  connected_team_contact: 'Connected Team Contact',
}

const RecipientToggle = ({ label, isToggledOn, setEmailBcc }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const onToggle = (value, label) => {
    if (value) {
      setEmailBcc((bcc) => [...bcc, label])
    } else {
      setEmailBcc((bcc) => {
        return bcc.filter((contact) => contact !== label)
      })
    }
  }

  return (
    <div className={classes.bccToggleContainer}>
      <label> {translate(EMAIL_RECIPIENTS_MAP[label])} </label>
      <Switch checked={isToggledOn} onChange={(e) => onToggle(e.target.checked, label)} />
    </div>
  )
}

export default RecipientToggle

export const SWITCH_INTERFACE_DIALOG = 'SWITCH_INTERFACE_DIALOG'

export const setConfirmationDialog = (switchDialogOpen: boolean) => ({
  type: SWITCH_INTERFACE_DIALOG,
  switchDialogOpen,
})

interface SwitchDialogOpenAction {
  type: typeof SWITCH_INTERFACE_DIALOG,
  switchDialogOpen: boolean,
}

export default (previousState = {}, action:SwitchDialogOpenAction) => {
  if (action.type === SWITCH_INTERFACE_DIALOG) {
    previousState = {
      ...previousState,
      ...{
        switchDialogOpen: action.switchDialogOpen,
      },
    }
  }
  return previousState
}

export type LiteInterfaceState = {
  switchDialogOpen: boolean
}

import { orgSelectors } from 'ducks/orgs'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import type { FactoryHookType } from '../type'
import maybeCreateDistributorBrandFilterNode from './DistributorBrandFilterNodeFactory'
import useDistributorAvailableBrands from './useDistributorAvailableBrands'

const useDistributorBrandFilterNode: FactoryHookType = ({ RendererComponent, options }) => {
  // change to selected distributor once CP merged in
  const distributor = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const availableBrands = useDistributorAvailableBrands({ distributor, componentType: options.componentType })
  return useMemo(() => {
    return maybeCreateDistributorBrandFilterNode({
      availableBrands,
      RendererComponent,
    })
  }, [RendererComponent, availableBrands])
}

export default useDistributorBrandFilterNode

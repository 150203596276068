import { ComponentVersions_3_0 } from 'constants/uxVersions'
import { ComponentVersionsInherit } from 'opensolar-ui'
import PreOrderPage from './checkoutv3/preOrder'

const CheckoutPage = () => {
  return (
    <ComponentVersionsInherit versions={ComponentVersions_3_0}>
      <PreOrderPage />
    </ComponentVersionsInherit>
  )
}

export default CheckoutPage

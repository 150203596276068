import { logAmplitudeEvent } from 'amplitude/amplitude'
import { Severities, variantIcon } from 'elements/notificationVariants'
import React, { useEffect, useMemo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import type { OsTooltipPropsType } from './Tooltip'
import Tooltip from './Tooltip'

const useStyles = makeOpenSolarStyles<{ size: Sizes; severity: Severities }>((theme) => ({
  icon: ({ size, severity }) => ({
    fontSize: size === 'small' ? '1.2em' : '1.5em',
    color: theme['alertIcon_' + severity],
  }),

  iconEnabled: {
    cursor: 'pointer',
  },
  iconDisabled: {
    opacity: 0.5,
  },
}))

const TooltipTitleWrapper = ({
  children,
  trackSource,
}: {
  children: NonNullable<React.ReactNode>
  trackSource?: string
}) => {
  useEffect(() => {
    if (children == null || trackSource == null) {
      return
    }
    logAmplitudeEvent('generic_tooltip_interacted', {
      action: 'viewed',
      context: 'content',
      source: trackSource,
    })
  }, [])

  return <>{children}</>
}

type Sizes = 'small' | 'normal'

const InfoTooltip: React.FC<
  Omit<OsTooltipPropsType, 'children'> & {
    customIcon?: React.ReactNode
    trackSource?: string
    size?: Sizes
    severity?: Severities
  }
> = ({ customIcon, title, disabled, placement, trackSource, size = 'normal', severity = 'info', ...rest }) => {
  const classes = useStyles({ size, severity })
  const iconElement = useMemo(
    () =>
      React.createElement(variantIcon[severity], {
        className: classes.icon + ' ' + (disabled ? classes?.iconDisabled : classes?.iconEnabled),
      }),
    [severity, disabled]
  )
  return disabled ? (
    <>{iconElement}</>
  ) : (
    <Tooltip
      title={title && <TooltipTitleWrapper trackSource={trackSource}>{title}</TooltipTitleWrapper>}
      interactive={true}
      arrow
      placement={placement}
      {...rest}
    >
      {/* @ts-ignore */}
      {(customIcon as React.ReactNode) || iconElement}
    </Tooltip>
  )
}

export default InfoTooltip

import { SystemContext } from 'Designer/designRules/types'
import lodash from 'lodash'
import { EffectFactory } from '.'

export type SetSessionDataDef = {
  type: 'set-session-data'
  prop: string
  value: unknown
}

export const effect_setSessionData: EffectFactory<SetSessionDataDef> = (def) => {
  return {
    execute: async (context: SystemContext) => {
      setSessionData(context, def.prop, def.value)
    },
  }
}

function setSessionData(context: SystemContext, prop: string | string[], value: unknown, key?: string): void {
  if (!key) key = context.systemRuleKey
  const newSessionRuleData = { ...(context.sessionData[key] || {}) }
  lodash.set(newSessionRuleData, prop, value)
  context.sessionData[key] = newSessionRuleData
}

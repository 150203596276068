import type { AuthConfigType } from '../types'

const OPENSOLAR_AUTH_CONFIG: AuthConfigType = {
  id: 'opensolar-user-pass',
  type: 'user-pass',
  displayName: 'OpenSolar',
  logoUrl: 'opensolar-logo-horizontal.svg',
}

export default OPENSOLAR_AUTH_CONFIG

import { Dialog, DialogContent, DialogTitle, Fab, makeStyles, useMediaQuery } from '@material-ui/core'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ContentAdd from '@material-ui/icons/AddOutlined'
import AlertError from '@material-ui/icons/ErrorOutlineOutlined'
import classnames from 'classnames'
import { Button } from 'opensolar-ui'
import React, { FC, useCallback, useState } from 'react'
import { RedirectionSideEffect, SaveButton, Toolbar, useNotify, useRedirect, useTranslate } from 'react-admin'
import { Theme } from 'types/themes'
import { LeadCaptureFormCreate } from './LeadCaptureForms'

const useStyles = makeStyles(
  () => ({
    floating: {
      margin: 0,
      top: 'auto',
      right: 20,
      bottom: 110,
      left: 'auto',
      position: 'fixed',
      zIndex: 1000,
    },
  }),
  { name: 'LeadCaptureFormCreateButton' }
)

type DialogPropsType = {
  dialogMode: number | undefined
  setDialogMode: (value: number | undefined) => void
}

const CustomizedToolbar = (props: { setDialogMode: (value: number | undefined) => void }) => {
  const translate = useTranslate()
  const notify = useNotify()
  const redirect = useRedirect()
  const onSuccess = useCallback((response) => {
    const record = response.data
    if (record) {
      notify('ra.notification.created', 'info')
      redirect('edit', '/lead_capture_forms', record.id, record)
    }
  }, [])

  return (
    <Toolbar
      // @ts-ignore
      style={{ zIndex: 1, left: 0, position: 'absolute', bottom: 0, boxSizing: 'border-box', width: '100%' }}
      {...props}
    >
      <div>
        <Button
          variant="contained"
          color="default"
          style={{ margin: 10 }}
          onClick={() => {
            props.setDialogMode(undefined)
          }}
          startIcon={<AlertError />}
        >
          <span>{translate('Cancel')}</span>
        </Button>
        <SaveButton
          {...props}
          redirect={'edit'}
          submitOnEnter={true}
          onSave={(values: object, redirect: RedirectionSideEffect, form: any, defaultSaveFunc: Function) => {
            defaultSaveFunc(values, redirect, { onSuccess })
          }}
        />
      </div>
    </Toolbar>
  )
}

const LeadCaptureDialog: FC<DialogPropsType> = ({ dialogMode, setDialogMode }) => {
  const translate = useTranslate()
  return (
    <div>
      <Dialog id="LeadCaptureDialog" open={!!dialogMode}>
        <DialogTitle>{translate('Create Lead Capture')}</DialogTitle>
        <DialogContent style={{ padding: '0 20px 70px' }}>
          <LeadCaptureFormCreate
            // @ts-ignore
            optionId={dialogMode}
            resource="lead_capture_forms"
            basePath="/lead_capture_forms/create"
            hideBreadCrumbs={true}
            hideTitle={true}
            noBorder={true}
            toolbar={<CustomizedToolbar setDialogMode={setDialogMode} />}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}

const LeadCaptureFormCreateButton = (props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [id, setId] = useState<number>()
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const open = Boolean(anchorEl)
  const classes = useStyles()
  const translate = useTranslate()

  const options = props.options

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (options.length > 1) {
      setAnchorEl(event.currentTarget)
    } else {
      setId(options[0].id)
    }
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      {isSmall ? (
        <Fab
          id="leadcapture-create-button"
          aria-controls="leadcapture-create-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          style={{
            width: 70,
            height: 70,
            borderRadius: '50%',
          }}
          color="default"
          className={classnames(classes.floating)}
          aria-label="Add Lead Capture"
        >
          <ContentAdd />
        </Fab>
      ) : (
        <Button
          id="leadcapture-create-button"
          aria-controls="leadcapture-create-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          variant="outlined"
          color="default"
          startIcon={<ContentAdd />}
          style={{ margin: '0 5px' }}
        >
          <span>{translate('Add Lead Capture')}</span>
        </Button>
      )}
      <Menu
        id="leadcapture-create-menu"
        aria-labelledby="leadcapture-create-button"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {options.map((option) => (
          <MenuItem
            onClick={() => {
              handleMenuClose()
              setId(option.id)
            }}
          >
            {translate(option.name)}
          </MenuItem>
        ))}
      </Menu>
      <LeadCaptureDialog dialogMode={id} setDialogMode={setId} />
    </div>
  )
}

export default LeadCaptureFormCreateButton

import useResizeObserver from '@react-hook/resize-observer'
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'

/**
 * This hook allows you to measure the size of a DOM element.
 *
 * https://dev.to/murashow/how-to-use-resize-observer-with-react-5ff5
 *
 * Usage:
 * ```
 * const { target, size } = useElementBox<HTMLDivElement>()
 *
 * useEffect(() => {
 *  console.log("Size is: ", size.width, size.height)
 * }, [size])
 *
 * return <div ref={target}/>
 * ```
 *
 * ## `watchLevelsUp`
 * This prop determines how far up the hierarchy to watch for changes.
 * - Set to zero to disable contextual position detection. Most performant
 * - Set to one (default) to watch the direct parent for contextual position changes
 * - Set to a higher number to watch higher up the hierarchy for contextual position changes. At
 * the cost of worse performance.
 */

type ElementBoxProps = {
  watchLevelsUp?: number
}

export default function useElementBox<T extends HTMLElement = HTMLDivElement>({
  watchLevelsUp = 0,
}: ElementBoxProps = {}): ElementSize<T> {
  const [target, setTarget] = useState<T | undefined>()
  const targetRef = useRef<T | null>(null)
  const bodyRef = useRef(document.body)
  const [viewportBox, setViewportBox] = useState<Box>({ x: 0, y: 0, width: 0, height: 0 })
  const [documentBox, setDocumentBox] = useState<Box>({ x: 0, y: 0, width: 0, height: 0 })
  const [valid, setValid] = useState(false)

  targetRef.current = target || null

  const checkSize = useCallback(() => {
    if (!target) {
      setValid(false)
      return
    }
    setValid(true)
    const bounds = target.getBoundingClientRect()
    setViewportBox({ x: bounds.left, y: bounds.top, width: bounds.width, height: bounds.height })
    setDocumentBox({
      x: bounds.left + document.documentElement.scrollLeft,
      y: bounds.top + document.documentElement.scrollTop,
      width: bounds.width,
      height: bounds.height,
    })
  }, [target])

  // Watch parent/s for changes as a proxy for position changes
  useEffect(() => {
    if (!watchLevelsUp || !target) return

    let iterator = watchLevelsUp
    let current: HTMLElement | null = target.parentElement
    if (!current) {
      console.warn('Failed to find target parent in useElementBox, target not on DOM')
      return
    }
    while (iterator > 1) {
      if (!current.parentElement) break // Allow for there to be not enough parents, just take the top
      current = current.parentElement
      iterator--
    }

    const observer = new MutationObserver(checkSize)
    observer.observe(current, {
      subtree: true,
      childList: true,
    })
    return () => observer.disconnect()
  }, [watchLevelsUp, target])

  useEffect(() => {
    window.addEventListener('scroll', checkSize)
    return () => window.removeEventListener('scroll', checkSize)
  }, [checkSize])

  useLayoutEffect(checkSize, [target])

  useResizeObserver(targetRef, checkSize)

  // This detects when the internal size of the page changes.
  // For example, when the page scrollbar is added/removed.
  useResizeObserver(bodyRef, checkSize)

  return { target, setTarget, viewportBox, documentBox, valid }
}

type ElementSize<T extends HTMLElement> = {
  target: T | undefined
  setTarget: (target: T) => void
  viewportBox: Box
  documentBox: Box
  valid: boolean
}

// For use in legacy class components
export const withElementSize = (propName: string, props?: ElementBoxProps) => (
  BaseComponent: ComponentType
): ComponentType => {
  const ComponentWithElementSize = (compProps: any) => {
    const elementSize = useElementBox(props)
    const props2 = { [propName]: elementSize }
    //@ts-ignore
    return <BaseComponent {...compProps} {...props2} />
  }

  //@ts-ignore
  ComponentWithElementSize.defaultProps = BaseComponent.defaultProps

  //@ts-ignore
  return ComponentWithElementSize
}

interface Box {
  x: number
  y: number
  width: number
  height: number
}

import { makeStyles } from '@material-ui/core'
import { Button } from 'opensolar-ui'
import React from 'react'
import { Theme } from 'types/themes'

const useStyles = makeStyles<
  Theme,
  { textColor: string; bgColor: string; bgColorOnHover: string; height: number; border: string }
>((theme) => ({
  root: ({ textColor, bgColor, bgColorOnHover, height, border }) => ({
    textTransform: 'none',
    background: bgColor,
    color: textColor,
    border: border,
    textAlign: 'left',
    width: '100%',
    height: height,
    justifyContent: 'flex-start',
    '&:hover': {
      background: bgColorOnHover,
    },
    '&:disabled': { background: 'rgba(208, 208, 208, 0.8)' },
    '&:hover:disabled': { background: 'rgba(208, 208, 208, 0.8)' },
  }),
  label: ({ textColor }) => ({
    fontWeight: 'normal',
    textTransform: 'none',
    fontSize: '16px',
    padding: '0px 0px 0px 4px',
    marginRight: 'auto',
    overflowWrap: 'break-word',
    color: textColor,
    '&:disabled': 'rgb(77, 77, 77,0.4)',
  }),
  startIcon: { margin: 0 },
}))

const StyledButton = ({ myeStyles, isSelected, children, ...rest }: any) => {
  const highlightColor = myeStyles?.highlightColor
  const highlightColorOnHover = myeStyles?.highlightColorInteraction
  const bgColor = isSelected ? highlightColor : '#F1F1F1'
  const bgColorOnHover = isSelected ? highlightColorOnHover : 'rgba(0,0,0,0.08)'
  const textColor = isSelected ? myeStyles.textColorOnHighlight : '#000'
  const height = myeStyles?.height ? myeStyles.height : 45
  const border = isSelected ? `1px solid ${highlightColor}` : '1px solid #DFDFDF'
  const classes = useStyles({ textColor, bgColor, bgColorOnHover, height, border })
  return (
    <Button classes={{ root: classes.root, label: classes.label }} {...rest}>
      {children}
    </Button>
  )
}

export default StyledButton

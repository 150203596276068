import React from 'react'
import { Edit } from 'react-admin'
import EditEventDialogFormContent from './EditEventDialogFormContent'

type PropTypes = {
  toolbar: any
  hideCancelButton: boolean
  redirect?: string | false
  onDelete: (eventId?: number) => void
}

const EditEventDialogFormWrapper: React.FunctionComponent<PropTypes> = (props) => {
  return (
    <Edit {...props} renderChildrenBeforeRecord={true}>
      <EditEventDialogFormContent {...props} />
    </Edit>
  )
}

export default EditEventDialogFormWrapper

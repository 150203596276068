import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { Info } from '@material-ui/icons'
import InfoTooltip from 'elements/tooltip/InfoTooltip'
import { ReactNode } from 'react'
import { OpenSolarTheme } from 'Themes'

type PropTypes = {
  style?: Partial<CSSProperties>
  label: string
  field?: ReactNode
  info?: string | ReactNode
}

export const StudioFieldContainer = ({ style, label, field, info }: PropTypes) => {
  return (
    <>
      <div style={style}>
        <div
          style={{
            fontSize: 12,
            color: OpenSolarTheme.greyMid1,
            marginBottom: 5,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div>{label}</div>
          {info && (
            <InfoTooltip
              title={info}
              size="small"
              color="secondary"
              customIcon={
                <Info
                  style={{
                    width: 14,
                    height: 14,
                    borderRadius: 7,
                    marginLeft: 7,
                  }}
                />
              }
            />
          )}
        </div>
        {field}
      </div>
    </>
  )
}

import { useState } from 'react'
import { useStudioSignalsLazy } from 'Studio/signals/useStudioSignalsLazy'
import type { ShadeMetricsLikeType } from 'types/studio/items'

const useSelectedSystemShadeMetrics = () => {
  const [shadeMetrics, setShadeMetrics] = useState<ShadeMetricsLikeType | undefined>(
    window.editor.selectedSystem.output?.shade_metrics
  )

  const onFreshShadeMetrics = () => {
    setShadeMetrics(window.editor.selectedSystem.output?.shade_metrics)
  }

  useStudioSignalsLazy(onFreshShadeMetrics, ['systemCalculationsUpdated'])
  return shadeMetrics
}

export default useSelectedSystemShadeMetrics

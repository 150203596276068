import { SplitButton } from 'opensolar-ui'
import { FC } from 'react'
import { CodeBlock } from '../CodeBlock'
import ComponentPage from '../ComponentPage'
import { FeatureGrid } from '../FeatureGrid'
import {
  SplitButtonBasic,
  SplitButtonColours,
  SplitButtonOptions,
  SplitButtonReactMarkup,
  SplitButtonSizes,
  SplitButtonVariants,
} from './SplitButtonUsage'

const SplitButtonUsage_code = require('!!raw-loader!./SplitButtonUsage').default

const options = [
  { label: 'Manage', value: 'manage' },
  { label: 'Energy', value: 'energy' },
  { label: 'Design', value: 'design' },
]

export const DemoSplitButton: FC = () => {
  return (
    <ComponentPage
      name={'SplitButton'}
      import="import { SplitButton } from 'opensolar-ui'"
      description={'SplitButton is used to group multiple buttons into a dropdown.'}
    >
      <CodeBlock name="SplitButton Basic Usage" code={SplitButtonUsage_code} codePart={1}>
        <SplitButtonBasic />
      </CodeBlock>
      <CodeBlock
        name="SplitButton Colors"
        code={SplitButtonUsage_code}
        codePart={2}
        description="How to use the color prop"
      >
        <SplitButtonColours />
      </CodeBlock>
      <CodeBlock
        name="SplitButton Variants"
        code={SplitButtonUsage_code}
        codePart={3}
        description="How to use the variant prop"
      >
        <SplitButtonVariants />
      </CodeBlock>
      <CodeBlock
        name="SplitButton Sizes"
        code={SplitButtonUsage_code}
        codePart={4}
        description="How to use the size prop"
      >
        <SplitButtonSizes />
      </CodeBlock>

      <CodeBlock
        name="SplitButton Options"
        code={SplitButtonUsage_code}
        codePart={5}
        description="How to use the behavioural options"
      >
        <SplitButtonOptions />
      </CodeBlock>

      <CodeBlock
        name="SplitButton Custom Components"
        code={SplitButtonUsage_code}
        codePart={6}
        description="How to add custom menu items (won't be selectable)"
      >
        <SplitButtonReactMarkup />
      </CodeBlock>

      <CodeBlock name="Radio Feature Grid" hideGrid={true}>
        <FeatureGrid
          component={SplitButton}
          propsGen={(row, col, lastHeader) => {
            return { options, ...row, ...col, ...lastHeader }
          }}
          colHeaders={[]}
          rowHeaders={[]}
          cols={[
            { color: 'default' },
            { color: 'primary' },
            { color: 'secondary' },
            { color: 'info' },
            { color: 'success' },
            { color: 'warning' },
            { color: 'error' },
          ]}
          rows={[
            { _type: 'heading', _title: 'Small Size', size: 'small' },
            { variant: 'contained' },
            { variant: 'outlined' },
            { variant: 'text' },
            { _type: 'heading', _title: 'Medium Size (default)', size: 'medium' },
            { variant: 'contained' },
            { variant: 'outlined' },
            { variant: 'rounded' },
            { _type: 'heading', _title: 'Large Size', size: 'large' },
            { variant: 'contained' },
            { variant: 'outlined' },
            { variant: 'text' },
            { _type: 'heading', _title: 'Disabled', disabled: true },
            { variant: 'contained' },
            { variant: 'outlined' },
            { variant: 'text' },
          ]}
        />
      </CodeBlock>
    </ComponentPage>
  )
}

// @ts-nocheck
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { TextInput, useTranslate } from 'react-admin'
import { formatCurrency, systemNameOrDefault } from 'util/misc'
import { SystemPropInterface } from './types'

const useStyles = makeStyles({
  systemWrapper: {
    textAlign: 'left',
    paddingTop: '10px',
  },
  systemWrapperNoBorder: {
    textAlign: 'left',
  },
  cell: {
    padding: '12px 5px',
  },
})

type PropTypes = {
  system: SystemPropInterface
  index: number
}

const SystemSummaryRow: React.FunctionComponent<PropTypes> = (props) => {
  const translate = useTranslate()
  const classes = useStyles()
  const roundToHundreth = (raw: number) => {
    if (!raw) return raw
    else return Math.round(raw * 100) / 100
  }
  return (
    <div className={props.index === 0 ? classes.systemWrapperNoBorder : classes.systemWrapper}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <h4 style={{ marginBottom: '0px' }}>{systemNameOrDefault(props.system)}</h4>
          <div style={{ color: 'rgba(0, 0, 0, 0.38)' }}>
            <i>{props.system.summary}</i>
          </div>
        </Grid>
        <Grid item xs={3}>
          <TextInput
            label="Show Customer"
            value={props.system.show_customer ? translate('Yes') : translate('No')}
            disabled={true}
            style={{ width: '100%', marginTop: '5px', marginBottom: '5px' }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            label="kW STC"
            value={props.system.kw_stc ? roundToHundreth(props.system.kw_stc) : ''}
            disabled={true}
            style={{ width: '100%', marginTop: '5px', marginBottom: '5px' }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            label="Annual Output"
            value={props.system.output_annual_kwh ? roundToHundreth(props.system.output_annual_kwh) : ''}
            disabled={true}
            style={{ width: '100%', marginTop: '5px', marginBottom: '5px' }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            label="Offset %"
            value={
              props.system?.consumption_offset_percentage
                ? Math.round(props.system.consumption_offset_percentage)
                : 'N/A'
            }
            disabled={true}
            style={{ width: '100%', marginTop: '5px', marginBottom: '5px' }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            label="Battery"
            value={
              props.system && props.system.batteries && props.system.batteries.length > 0
                ? 'Yes (' + props.system.batteries.length + ')'
                : 'No'
            }
            disabled={true}
            style={{ width: '100%', marginTop: '5px', marginBottom: '5px' }}
          />
        </Grid>
        {/*
          Adders removed because when they are populated from database data there is no field yet for (total calculated) adders.
          If that field is added to the system record then this could be re-enabled.
          <Grid item xs={3}>
          <TextInput
            label="Adders"
            value={
              props.system && props.system.pricing && props.system.pricing.adders
                ? Math.round(props.system.pricing.adders).toLocaleString()
                : '0.0'
            }
            disabled={true}
            style={{ width: '100%', marginTop: '5px', marginBottom: '5px' }}
          />
        </Grid>*/}
        <Grid item xs={3}>
          <TextInput
            label="Discount"
            value={props.system ? Math.round(props.system.discount).toLocaleString() : '0.0'}
            disabled={true}
            style={{ width: '100%', marginTop: '5px', marginBottom: '5px' }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            label="Price"
            value={
              props.system && props.system.price_including_tax
                ? formatCurrency(Math.round(props.system.price_including_tax)).toLocaleString()
                : formatCurrency(0).toLocaleString()
            }
            disabled={true}
            style={{ width: '100%', marginTop: '5px', marginBottom: '5px' }}
          />
        </Grid>
      </Grid>
    </div>
  )
}
export default SystemSummaryRow

// @ts-nocheck
import { doAvailableActionsRequest } from 'ducks/myEnergy'
import { orgSelectors } from 'ducks/orgs'
import StipCTAForPros from 'myenergy/selectionComponent/financeStipulations/StipCTAForPros'
import LyraIntegrationPanel from 'projectSections/integrations/lyra/LyraIntegrationPanel'
import React, { useEffect, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { US_FINCOS } from 'resources/integrations/constants'
import DividendSendLoanAgreement from 'resources/integrations/dividend/DividendSendLoanAgreement'
import ManageDocusignContractButton from 'resources/integrations/docusign/ManageDocusignContractButton'
import DownloadBOMButton from 'resources/integrations/ironridge/DownloadBOMButton'
import LoanpalSendDocsProButton from 'resources/integrations/loanpal/LoanpalSendDocsProButton'
import MosaicShareCTA from 'resources/integrations/mosaic/MosaicShareCTA'
import SendChangeOrderCTA from 'resources/integrations/shared/SendChangeOrderCTA'
import SungageSendAppButton from 'resources/integrations/sungage/SungageSendAppButton'
import SunlightLoanDocsCTA from 'resources/integrations/sunlight/SunlightLoanDocsCTA'
import SunlightQuickCreateLauncher from 'resources/integrations/sunlight/SunlightQuickCreateLauncher'
import GreenLancerCTA from 'resources/projects/GreenLancer/GreenLancerCTA'
import { ProjectType } from 'types/projects'
import { parseIntegrationJson } from 'util/misc'
import { useFeatureFlag } from 'util/split'
import { useLyraPermitPackEnabled } from '../../integrations/lyra/useLyraPermitPackEnabled'
import CTAAccordion from './ctaAccordion'
import AuInitiateFinanceAppWrapper from './ctaAccordion/auFinance/AuFinanceCTAWrapper'
import SegenCTA from './segen'

type TemporaryCTAsType = keyof typeof TEMPORARY_CTA_MAP
const MAX_POLL_COUNT = 5

type TemporaryCTAMapType = {
  [ctaType: string]: {
    title: string
    renderComponent: (propsToUse: any) => JSX.Element
    descriptionTop?: string
    descriptionTopLine2?: string
  }
}
const TEMPORARY_CTA_MAP = {
  greenlancer: {
    title: 'Permitting Services',
    renderComponent: (props: any) => <GreenLancerCTA {...props} isIronRidge={false} />,
    descriptionTop:
      'Save time and money, and streamline your permitting process by ordering your plansets from GreenLancer on OpenSolar with just a click.',
  },
  greenlancer_with_ironridge: {
    title: 'Permitting Services',
    renderComponent: (props: any) => <GreenLancerCTA {...props} isIronRidge={true} />,
    descriptionTop:
      'Save time and money, and streamline your permitting process by ordering your plansets from GreenLancer on OpenSolar with just a click.',
    descriptionTopLine2: "As an IronRidge customer, you'll receive deeply discounted GreenLancer pricing!",
  },
  segen: {
    title: 'Order with Segen',
    renderComponent: (props: any) => <SegenCTA {...props} isMainCTA={true} />,
  },
  mosaic_application: {
    title: 'Mosiac Financing',
    renderComponent: (props: any) => <MosaicShareCTA hasMosaicOption mode="application" {...props} />,
  },
  mosaic_agreement: {
    title: 'Mosiac Financing',
    renderComponent: (props: any) => <MosaicShareCTA hasMosaicOption mode="agreement" {...props} />,
  },
  loanpal_stips: {
    title: 'Stipulations',
    renderComponent: (props: any) => <StipCTAForPros {...props} integration="loanpal" />,
  },
  mosaic_stips: {
    title: 'Stipulations',
    renderComponent: (props: any) => <StipCTAForPros {...props} integration="mosaic" />,
  },
  loanpal_docs: {
    title: 'Goodleap Loan Docs',
    renderComponent: (props: any) => <LoanpalSendDocsProButton {...props} />,
  },
  ironridge_lyra: {
    title: 'Ironridge Lyra',
    renderComponent: (props: any) => <LyraIntegrationPanel {...props} />,
  },
  ironridge_bom: {
    title: 'Ironridge BOM',
    renderComponent: (props: any) => <DownloadBOMButton {...props} isIronridge={true} />,
  },
  global_bom: {
    title: 'Global BOM',
    renderComponent: (props: any) => <DownloadBOMButton {...props} isIronridge={false} />,
  },
  sungage_app: {
    title: 'Sungage App',
    renderComponent: (props: any) => <SungageSendAppButton {...props} />,
  },
  sunlight_quick_create: {
    title: 'Send Project to Sunlight',
    renderComponent: (props: any) => <SunlightQuickCreateLauncher {...props} />,
  },
  sunlight_loan_docs: {
    title: 'Send Sunlight Loan Docs',
    renderComponent: (props: any) => <SunlightLoanDocsCTA {...props} />,
  },
  dividend_loan_docs: {
    title: 'Send Dividend Loan Agreement',
    renderComponent: (props: any) => <DividendSendLoanAgreement {...props} />,
  },
  change_order: {
    title: 'Submit Change Order',
    renderComponent: (props: any) => <SendChangeOrderCTA {...props} />,
  },
  docusign_contract: {
    title: 'Docusign Contract',
    renderComponent: (props: any) => <ManageDocusignContractButton {...props} />,
  },
  start_au_application: {
    title: 'Initiate Finance Application',
    renderComponent: (props: any) => <AuInitiateFinanceAppWrapper {...props} />,
  },
} as TemporaryCTAMapType

const ProjectCTAs: React.FunctionComponent = (props) => {
  const form = useForm()
  const project = useFormState().values as ProjectType
  const hasUnsavedChanges = form.mutators.getFormDirtyFields()?.length > 0

  const [CTAsToDisplay, setCTAsToDisplay] = useState<TemporaryCTAsType[]>([])
  const [hasMosaic, setHasMosaic] = useState<boolean>(false)
  const [hasLoanpal, setHasLoanpal] = useState<boolean>(false)
  const [hasSungage, setHasSungage] = useState<boolean>(false)
  const [hasSunlight, setHasSunlight] = useState<boolean>(false)
  const [hasDividend, setHasDividend] = useState<boolean>(false)
  const [hasAuIntegratedFinance, setHasAUIntegratedFinance] = useState<boolean>(false)
  const [loanpalAgreementData, setLoanpalAgreementData] = useState<any>(undefined)
  const [changeOrderIntegration, setChangeOrderIntegration] = useState<typeof US_FINCOS[number] | undefined>(undefined)
  const [actionPollCount, setActionPollCount] = useState<number>(0)
  const [availableActions, setAvailableActions] = useState<any>(project?.available_customer_actions)
  const [availbaleActionsString, setAvailableActionsString] = useState<string>(
    project?.available_customer_actions ? JSON.stringify(project?.available_customer_actions) : ''
  )

  const hasSegen = useSelector(orgSelectors.getEnableSegen)
  const enableNewHardwareOrdering = useFeatureFlag('hardware_ordering', 'on')
  const lyraPermitPackEnabled = useLyraPermitPackEnabled()

  // @ts-ignore
  const systemCalculation = useSelector((state) => state.designer.systemCalculation)
  const { processQueue } = systemCalculation

  const doActionPoll = () => {
    // if records aren't yet saved then don't poll, push counter var to above max and this can poll next time the component mounts
    if (!project?.org_id || !project.id) {
      setActionPollCount(MAX_POLL_COUNT + 1)
      return
    }
    doAvailableActionsRequest(project.org_id, project.id)
      .then((res: any) => {
        if (res.data && JSON.stringify(res.data) !== availbaleActionsString) {
          setAvailableActions(res.data)
          setAvailableActionsString(JSON.stringify(res.data))
        }
      })
      .catch((err) => {
        console.log('err', err)
      })
      .finally(() => setActionPollCount(actionPollCount + 1))
  }

  useEffect(() => {
    if (actionPollCount < MAX_POLL_COUNT) {
      const waitMs = (actionPollCount + 1) * 3000
      setTimeout(() => doActionPoll(), waitMs)
    }
  }, [actionPollCount])

  //if user changes something in studio and does not save then the design field will be marked as dirty but the actual data will live in window
  //listen for changes to the dirty fields to see if design has changed, if so go fetch the updated data and recalculate the system summary field, update it in
  // the initial values in the form. This field is for display only but a change in it signifies a change elsewhere that will need to be saved
  // when done, mark design as clean
  useEffect(() => {
    // give things a bit to load and settle
    const systemsData = window.editor.getSystems()
    if (systemsData) {
      let foundMosaic = false
      let foundLoanpal = false
      let foundSungage = false
      let foundSunglight = false
      let foundDividend = false
      let foundAUIntegratedFinance = false
      systemsData?.forEach((system) => {
        if (system.payment_options && system.payment_options.length > 0) {
          system.payment_options.forEach((pmt) => {
            if (!foundMosaic && pmt.integration === 'mosaic') foundMosaic = true
            if (!foundLoanpal && pmt.integration === 'loanpal') foundLoanpal = true
            if (!foundSungage && pmt.integration === 'sungage') foundSungage = true
            if (!foundSunglight && pmt.integration === 'sunlight') foundSunglight = true
            if (!foundDividend && pmt.integration === 'dividend') foundDividend = true
            if (!foundAUIntegratedFinance && ['brighte', 'plenti', 'energy_ease'].includes(pmt.integration)) {
              foundAUIntegratedFinance = true
            }
          })
        }
      })
      if (foundMosaic) setHasMosaic(true)
      if (foundLoanpal) setHasLoanpal(true)
      if (foundSungage) setHasSungage(true)
      if (foundSunglight) setHasSunlight(true)
      if (foundDividend) setHasDividend(true)
      if (foundAUIntegratedFinance) setHasAUIntegratedFinance(true)
    }
  }, [processQueue])

  useEffect(() => {
    if (project && availableActions) {
      let hasIronRidge = false
      let ironRidgeSystmIsSold = false
      let isBOMEligible = false
      let changeOrderIntegrationToShow = undefined

      let newCTAValue = [...CTAsToDisplay]

      let docusignFound = !!project?.docusign_contract_envelope_id
      for (let sysIndex = 0, sysLen = availableActions.length; sysIndex < sysLen; sysIndex++) {
        for (
          let actIndex = 0, actLen = availableActions[sysIndex].actions_available?.length;
          actIndex < actLen;
          actIndex++
        ) {
          if (availableActions[sysIndex].actions_available[actIndex]?.payment_method === 'docusign') {
            docusignFound = true
            break
          }
        }
        if (docusignFound) break
      }

      if (docusignFound) {
        if (!newCTAValue.includes('docusign_contract')) {
          newCTAValue.push('docusign_contract')
        }
      } else if (newCTAValue.includes('docusign_contract')) {
        newCTAValue = newCTAValue.filter((cta) => cta !== 'docusign_contract')
      }

      project.systems?.forEach((sys) => {
        if (sys.url === project.system_sold && sys.integration_json) {
          try {
            if (parseIntegrationJson(sys.integration_json)?.ironridge) {
              hasIronRidge = true
              ironRidgeSystmIsSold = true
            }
          } catch (ex) {}
        }
        if (sys?.modules && sys?.modules.length > 0) {
          isBOMEligible = true
        }
        try {
          if (parseIntegrationJson(sys.integration_json)?.ironridge) hasIronRidge = true
        } catch (ex) {}
      })
      // check for Lyra
      if (lyraPermitPackEnabled && !newCTAValue.includes('ironridge_lyra')) newCTAValue.push('ironridge_lyra')

      // check for greenlancer
      if (
        !CTAsToDisplay.includes('greenlancer') &&
        !CTAsToDisplay.includes('greenlancer_with_ironridge') &&
        project.country_iso2 === 'US' &&
        !!project.system_sold
      ) {
        if (ironRidgeSystmIsSold && !newCTAValue.includes('greenlancer_with_ironridge'))
          newCTAValue.push('greenlancer_with_ironridge')
        else if (!newCTAValue.includes('greenlancer')) newCTAValue.push('greenlancer')
      }
      if (hasMosaic && project.country_iso2 === 'US') {
        // check for mosaic application
        let appComplete = false
        let foundMosaicAgreement = false
        availableActions?.forEach(
          (act: {
            actions_available: { filter: (arg0: (avail: any) => boolean) => { (): any; new (): any; length: number } }
          }) => {
            let foundCompletedApp =
              act?.actions_available?.filter((avail) => {
                return avail.payment_method === 'mosaic_application' && avail.status_code === 'complete'
              })?.length > 0
            if (foundCompletedApp && !appComplete) appComplete = true
          }
        )
        if (!appComplete && !CTAsToDisplay.includes('mosaic_application')) {
          if (!newCTAValue.includes('mosaic_application')) newCTAValue.push('mosaic_application')
        }
        // check for mosaic loan agrement
        availableActions?.forEach((act: { actions_available: any[] }) => {
          act?.actions_available?.forEach((avail) => {
            if (avail.document_type === 'mosaic_loan_agreement' && avail.status_title === 'available')
              foundMosaicAgreement = true
          })
        })
        if (foundMosaicAgreement && !CTAsToDisplay.includes('mosaic_agreement')) {
          if (!newCTAValue.includes('mosaic_agreement')) newCTAValue.push('mosaic_agreement')
        }
        // check for mosaic stips
        if (!CTAsToDisplay.includes('mosaic_stips')) {
          let hasStips =
            availableActions[0]?.actions_available?.filter(
              (act: { stipulation_acceptable_docs: string; stipulation_review_status: string }) => {
                const docs = act?.stipulation_acceptable_docs ? JSON.parse(act.stipulation_acceptable_docs) : {}
                const hasDocs = docs && Object.keys(docs)?.length > 0
                return hasDocs && ['created'].includes(act.stipulation_review_status)
              }
            )?.length > 0

          if (!newCTAValue.includes('mosaic_stips')) if (hasStips) newCTAValue.push('mosaic_stips')
        }
      }
      // check for loanpal docs
      if (hasLoanpal) {
        let foundLoanpalDocs = false
        availableActions?.forEach((act: { actions_available: any[] }) => {
          act?.actions_available?.forEach((avail) => {
            if (avail.document_type === 'loanpal_loan_agreement' && avail.status_title === 'available')
              foundLoanpalDocs = true
            setLoanpalAgreementData(avail)
          })
        })
        if (foundLoanpalDocs && !CTAsToDisplay.includes('loanpal_docs')) {
          if (!newCTAValue.includes('loanpal_docs')) newCTAValue.push('loanpal_docs')
        }
      }
      // check for loanpal stips
      if (hasLoanpal && !CTAsToDisplay.includes('loanpal_stips')) {
        let hasStips =
          availableActions[0]?.actions_available?.filter(
            (act: { stipulation_acceptable_docs: string; stipulation_review_status: string }) => {
              const docs = act?.stipulation_acceptable_docs ? JSON.parse(act.stipulation_acceptable_docs) : {}
              const hasDocs = docs && Object.keys(docs)?.length > 0
              return hasDocs && ['created'].includes(act.stipulation_review_status)
            }
          )?.length > 0

        if (!newCTAValue.includes('loanpal_stips')) if (hasStips) newCTAValue.push('loanpal_stips')
      }
      // check for dividend loan agreement
      if (hasDividend) {
        let foundDividendAgreement = false
        availableActions?.forEach((act: { actions_available: any[] }) => {
          act?.actions_available?.forEach((avail) => {
            if (avail.document_type === 'dividend_loan_agreement' && avail.status_title === 'available') {
              foundDividendAgreement = true
            }
            if (
              avail.document_type === 'dividend_loan_agreement' &&
              project.payment_option_sold &&
              project.system_sold
            ) {
              changeOrderIntegrationToShow = 'dividend'
            }
          })
        })
        if (foundDividendAgreement && !CTAsToDisplay.includes('dividend_loan_docs')) {
          if (!newCTAValue.includes('dividend_loan_docs')) newCTAValue.push('dividend_loan_docs')
        }
      }
      // check for unsent sungage apps
      if (hasSungage && !CTAsToDisplay.includes('sungage_app')) {
        let hasAvailableSungageApp = false
        availableActions?.forEach((act: { actions_available: any[] }) => {
          act?.actions_available?.forEach((avail) => {
            if (avail.payment_method === 'sungage_application' && avail.status_code === 'available')
              hasAvailableSungageApp = true
          })
        })
        if (!newCTAValue.includes('sungage_app')) if (hasAvailableSungageApp) newCTAValue.push('sungage_app')
      }
      if (hasIronRidge && !CTAsToDisplay.includes('ironridge_bom')) {
        newCTAValue.push('ironridge_bom')
      }
      if (!hasIronRidge && isBOMEligible && !CTAsToDisplay.includes('global_bom')) {
        if (!newCTAValue.includes('global_bom')) newCTAValue.push('global_bom')
      }

      // check for sunlight send app, or loan docs
      let sunlightAppCode: string | undefined = undefined
      let sunlightAppStatus: string | undefined = undefined
      availableActions?.forEach((act: { actions_available: any[] }) => {
        act?.actions_available?.forEach((avail) => {
          if (avail.payment_method === 'sunlight_loan_application') {
            sunlightAppCode = avail.status_code
            sunlightAppStatus = avail.status_title
          }
        })
      })
      if (hasSunlight && !CTAsToDisplay.includes('sunlight_quick_create') && sunlightAppCode === 'available') {
        if (!newCTAValue.includes('sunlight_quick_create')) newCTAValue.push('sunlight_quick_create')
      }
      // if app isn't available, check if it's approved, if so show the loan agreement button
      if (sunlightAppCode !== 'available') {
        const POST_APPROVAL_STATUS_LIST = ['M0', 'M1', 'M2', 'Pending Loan Docs']
        if (
          sunlightAppStatus &&
          POST_APPROVAL_STATUS_LIST.includes(sunlightAppStatus) &&
          !CTAsToDisplay.includes('sunlight_loan_docs')
        ) {
          if (!newCTAValue.includes('sunlight_loan_docs')) newCTAValue.push('sunlight_loan_docs')
        }
      }
      if (hasAuIntegratedFinance && !CTAsToDisplay.includes('start_au_application')) {
        let hasAvailableAUApp = false
        availableActions?.forEach((act: { actions_available: any[] }) => {
          act?.actions_available?.forEach((avail) => {
            if (
              [
                'brighte_application',
                'plenti_bnpl_application',
                'plenti_loan_application',
                'energy_ease_application',
              ].includes(avail.payment_method) &&
              avail.status_code === 'available'
            )
              hasAvailableAUApp = true
          })
        })
        if (!newCTAValue.includes('start_au_application'))
          if (hasAvailableAUApp) newCTAValue.push('start_au_application')
      }
      if (changeOrderIntegrationToShow && !CTAsToDisplay.includes('change_order')) {
        newCTAValue.push('change_order')
        setChangeOrderIntegration(changeOrderIntegrationToShow)
      } else if (!changeOrderIntegrationToShow && CTAsToDisplay.includes('change_order')) {
        newCTAValue = newCTAValue.filter((cta) => cta !== 'change_order')
        setChangeOrderIntegration(undefined)
      }

      // check for segen
      if (
        hasSegen &&
        !enableNewHardwareOrdering &&
        !newCTAValue.includes('segen') &&
        project &&
        project.systems?.length > 0
      )
        newCTAValue.unshift('segen')
      else if (!hasSegen && newCTAValue.includes('segen')) newCTAValue = newCTAValue.filter((val) => val !== 'segen')

      setCTAsToDisplay(newCTAValue)
    }
  }, [
    availableActions,
    hasMosaic,
    hasLoanpal,
    hasSungage,
    hasSunlight,
    hasAuIntegratedFinance,
    hasDividend,
    project?.is_residential,
    project?.country_iso2,
    project?.docusign_contract_envelope_id,
    hasSegen,
  ])

  if (!CTAsToDisplay || CTAsToDisplay.length === 0) return null
  else {
    return (
      <div>
        {CTAsToDisplay.map((s: TemporaryCTAsType) => (
          <CTAAccordion
            key={s}
            // @ts-ignore
            renderComponent={() =>
              TEMPORARY_CTA_MAP[s].renderComponent({
                ...props,
                project,
                hasUnsavedChanges,
                loanpalAgreementData,
                changeOrderIntegration,
              })
            }
            // @ts-ignore
            title={TEMPORARY_CTA_MAP[s].title}
            // @ts-ignore
            descriptionTop={TEMPORARY_CTA_MAP[s].descriptionTop}
            descriptionTopLine2={TEMPORARY_CTA_MAP[s].descriptionTopLine2}
            project={project}
          />
        ))}
      </div>
    )
  }
}

export default ProjectCTAs

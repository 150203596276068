import { Chip as MuiChip, ChipProps, Tooltip } from '@material-ui/core'
import { SearchingContext } from 'pages/inventory/dashboard/InventoryDashboard'
import { memo, useContext } from 'react'
import Skeleton from 'react-loading-skeleton'
import type { OsValidityType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  skeleton: {
    minWidth: 50,
  },
  chip: ({ size, validity }: { size: ChipProps['size']; validity: OsValidityType }) => ({
    fontSize: size === 'small' ? theme.typography.subtitle2.fontSize : theme.typography.body1.fontSize,
    ...theme.ValidityPalette[validity],
    '&:hover': theme.ValidityPalette[validity],
    '&:focus': theme.ValidityPalette[validity],
  }),
}))

export type OsChipPropsType = Omit<ChipProps, 'variant' | 'color'> & {
  loading?: boolean
  validity: OsValidityType
  title?: string
}

const ValidityChip = ({ loading, label, validity, size = 'small', title = '', ...rest }: OsChipPropsType) => {
  const classes = useStyles({ size, validity })
  const { searchState } = useContext(SearchingContext)

  return (
    <Tooltip title={title}>
      <div>
        <MuiChip
          size={size}
          variant={'default'}
          className={classes.chip}
          label={loading ? <Skeleton className={classes.skeleton} /> : label}
          disabled={searchState}
          {...rest}
        />
      </div>
    </Tooltip>
  )
}

export default memo(ValidityChip)

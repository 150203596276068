import { makeStyles, Typography } from '@material-ui/core'
import SocketStatusIcon from 'elements/websocket/SocketStatusIcon'
import { useTranslate } from 'ra-core'
import React from 'react'
import { StudioSystemType } from 'types/global'
import { ProjectType } from 'types/projects'
import { useStyles as useStylesPackage } from './styles'

interface PropTypes {
  project: ProjectType
  system: StudioSystemType
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      padding: '0 10px',
    },
  }
})

const SystemSummaryPanel: React.FC<PropTypes> = ({ system }) => {
  const classes = useStyles()
  const packageClasses = useStylesPackage()
  const translate = useTranslate()
  let panels = 0
  for (const moduleGrid of system.moduleGrids()) panels += moduleGrid.moduleQuantity()
  return (
    <div className={packageClasses.row}>
      <Typography variant="subtitle1" className={classes.root + ' ' + packageClasses.fill}>
        {panels === 1 ? translate('1 Panel') : translate('%{panels} Panels', { panels })}
      </Typography>
      <SocketStatusIcon />
    </div>
  )
}

export default SystemSummaryPanel

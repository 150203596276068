import useQueryFromStore from './useQueryFromStore';
var defaultSort = { field: 'id', order: 'DESC' };
var defaultPagination = {
    page: 1,
    perPage: 20,
};
var useGetListFromStore = function (resource, pagination, sort, filter, options) {
    if (pagination === void 0) { pagination = defaultPagination; }
    if (sort === void 0) { sort = defaultSort; }
    var _a = useQueryFromStore({ type: 'getList', resource: resource, payload: { pagination: pagination, sort: sort, filter: filter } }, options), data = _a.data, total = _a.total, error = _a.error, loading = _a.loading, loaded = _a.loaded;
    return { data: data, total: total, error: error, loading: loading, loaded: loaded };
};
export default useGetListFromStore;

import { makeStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddOutlined'
import { Button } from 'opensolar-ui'
import React, { useCallback } from 'react'
import { useTranslate } from 'react-admin'

type PropTypes = {
  anchorEl: HTMLElement | null
  setPopover: Function
  disabled: boolean
}
const useStyles = makeStyles((theme: any) => ({
  buttonWrapper: {
    pointerEvents: 'auto',
    display: 'inline-block',
    width: 50,
    marginRight: 5,
  },
  labelWrapper: {
    textTransform: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 12,
    lineHeight: '14px',
  },
  buttonIcon: {
    maxHeight: 24,
    maxWidth: 24,
    margin: 0,
  },
  button: {
    minWidth: 50,
    width: 50,
    height: 40,
    borderRadius: 0,
    padding: 0,
    color: 'rgb(0,0,0)',
    border: '1px solid rgba(0,0,0,0.2)',
    background: 'rgba(255, 255, 255, 0.9)',
    '&:hover': {
      background: 'rgba(250,250,250,0.9)',
    },
    '&:first-child': {
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
    },
    '&:last-child': {
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 6,
    },
  },
  buttonLabel: {
    textTransform: 'none',
  },
}))

const AddNewViewButton: React.FC<PropTypes> = ({ anchorEl, setPopover, disabled = false }) => {
  // const [anchorEl, setPopover] = React.useState<HTMLElement | null>(null)
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setPopover(event.currentTarget.parentElement, 'new')
    },
    [anchorEl]
  )
  const translate = useTranslate()
  const classes = useStyles()
  return (
    <>
      <div className={classes.buttonWrapper}>
        <Button
          classes={{ root: classes.button, label: classes.buttonLabel }}
          disabled={disabled}
          onClick={handleClick}
        >
          <div className={classes.labelWrapper}>
            <AddIcon className={classes.buttonIcon} />
            <span>{translate('View')}</span>
          </div>
        </Button>
      </div>
    </>
  )
}

export default AddNewViewButton

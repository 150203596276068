import { OpenSolarThemeType } from 'Themes'
import { orgSelectors } from 'ducks/orgs'
import Alert from 'elements/Alert'
import { Severities } from 'elements/notificationVariants'
import { usePublicFeatureConfig } from 'hooks/usePublicFeatureConfig'
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import OrderLineItem from '../OrderLineItem'
import { HardwareSupplierEnum, HardwareSupplierFilterKeyType } from '../type'
import { hardwareEnumToFilter } from '../utils'

const SEGEN_DESIGN_DISCOUNT = 0.5

export type CartDiscount = {
  discountName: string
  discountPercentage: number
  eligible: boolean
  notification: {
    text: string
    severity: Severities
  }
} | null

export const getCartDiscount = (
  lineItems: OrderLineItem[],
  supplier: HardwareSupplierEnum | undefined,
  callback: Function,
  context?: 'project' | 'cart'
) => {
  var cartDiscount: CartDiscount = null
  if (!lineItems.length || lineItems.some((lineItem) => lineItem.status === 'loading')) {
    callback(cartDiscount)
    return
  }

  if (supplier === HardwareSupplierEnum.Segen) {
    let discountDetails = {
      discountName: 'Design Discount',
      discountPercentage: SEGEN_DESIGN_DISCOUNT,
    }

    getDesignDiscountEligibility(
      lineItems,
      (eligibleForDesignDiscount) => {
        if (eligibleForDesignDiscount) {
          let text =
            context === 'project'
              ? `Congratulations! An additional ${discountDetails.discountName} of ${SEGEN_DESIGN_DISCOUNT}% has been applied to your subtotal`
              : `Congratulations! Your selection is eligible for the ${discountDetails.discountName} of ${SEGEN_DESIGN_DISCOUNT}%`
          cartDiscount = {
            ...discountDetails,
            eligible: true,
            notification: {
              text,
              severity: 'success',
            },
          }
        } else {
          let text =
            context === 'project'
              ? `Your system is ineligible for the ${discountDetails.discountName} of ${SEGEN_DESIGN_DISCOUNT}% because it is missing a module, inverter or mounting equipment`
              : `Your selection is ineligible for the ${discountDetails.discountName} of ${SEGEN_DESIGN_DISCOUNT}% because a project is missing a module, inverter or mounting equipment`
          cartDiscount = {
            ...discountDetails,
            eligible: false,
            notification: {
              text,
              severity: 'error',
            },
          }
        }
        callback(cartDiscount)
      },
      context
    )
  } else {
    callback(cartDiscount)
  }
}

const restClientInstance = restClient(window.API_ROOT + '/api')

export const getDesignDiscountEligibility = (
  lineItems: OrderLineItem[],
  callback: Function,
  context?: 'project' | 'cart'
) => {
  if (!context) context = 'cart'
  restClientInstance('CUSTOM_POST', 'custom', {
    url: `orgs/${window.getStorage().getItem('org_id')}/segen_api/get_design_discount_eligibility/`,
    data: { lineItems, context },
  }).then((result) => {
    callback(result.data)
  })
}

export const getCartDiscountValue = (initialCost: number, cartDiscount: CartDiscount): number => {
  return cartDiscount?.eligible && cartDiscount?.discountPercentage
    ? Math.round(initialCost * cartDiscount.discountPercentage) / 100
    : 0
}

const useNotificationStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  wrapper: {
    width: '100%',
    '& > *': {
      width: '100%',
    },
  },
}))

export const CartDiscountNotification: FC<{ lineItems: OrderLineItem[]; context?: 'project' | 'cart' }> = ({
  lineItems,
  context,
}) => {
  const classes = useNotificationStyles()
  const selectedSupplier = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const [cartDiscount, setCartDiscount] = useState<CartDiscount>(null)

  useEffect(() => {
    if (selectedSupplier) {
      getCartDiscount(
        lineItems,
        selectedSupplier,
        (cartDiscount) => {
          setCartDiscount(cartDiscount)
        },
        context
      )
    }
  }, [lineItems, selectedSupplier])

  if (!cartDiscount || !selectedSupplier) return <></>

  return (
    <div className={classes.wrapper}>
      <Alert severity={cartDiscount.notification.severity}>{cartDiscount.notification.text}</Alert>
    </div>
  )
}

export const FeatureConfigDiscountNotification: FC = () => {
  const classes = useNotificationStyles()
  const selectedSupplier = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const discountConfig = usePublicFeatureConfig('distributor_discounts', true)

  if (!selectedSupplier) return <></>

  const distributorName = hardwareEnumToFilter[selectedSupplier] as HardwareSupplierFilterKeyType
  const discounts = discountConfig?.[distributorName] || []

  return (
    <>
      {discounts.map((discount) => (
        <div className={classes.wrapper}>
          <Alert severity={'info'}>{discount.message}</Alert>
        </div>
      ))}
    </>
  )
}

import { Grid, styled, Typography } from 'opensolar-ui'
import { useState } from 'react'
import {
  ComponentLikeType,
  DistributorDataTypeV2,
  StockLevelTypeV2,
} from '../../../../elements/hardwareSelectorV2/types'
import { COLOR_PALETTE } from '../../../../projectSections/sections/manage3/styles'
import { AddToCartButton } from '../../catalogv3/AddToCartButton'
import DistributorChips from '../../catalogv3/DistributorsChip'
import { getQuantity, PricingText } from '../../catalogv3/ProductTile'
import QuantityButton from '../../catalogv3/QuantityButton'
import { getSupplierConfigByFilterKey } from '../../configs'
import { HardwareSupplierEnum } from '../../type'
import { sortByPriceOrderingV2 } from '../../utils'

export const Wrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-evenly',
  backgroundColor: 'white',
  padding: 20,
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
  height: 305,
})

const Divider = styled('div')(({ theme }) => ({
  borderBottom: '1px solid ' + COLOR_PALETTE.lightGrey,
}))

const getStockLevelLabel = (stockLevel: StockLevelTypeV2 | null, distributor?: DistributorDataTypeV2) => {
  if (!distributor || !stockLevel) {
    return 'Out of stock'
  }

  const distributoConfig = getSupplierConfigByFilterKey(distributor.distributor)
  if (distributoConfig.type === HardwareSupplierEnum.SolarOutlet) {
    return stockLevel.quantity > 0 ? 'In Stock' : 'Out of stock'
  }

  return stockLevel?.quantity && Number(stockLevel.quantity) > 0 ? `In stock` : 'Out of stock'
}

export const ProductDetailCheckoutMultiDistributor = ({ component }: { component: ComponentLikeType | undefined }) => {
  const [selectedDistributor, setSelectedDistributor] = useState<DistributorDataTypeV2 | null>(null)
  const [isInCart, setIsInCart] = useState(false)

  const lowestPriceDistributor = component
    ? sortByPriceOrderingV2(component.ordering_v2).find((ordering) => ordering.price)
    : component

  const displayDetailsDistributor = selectedDistributor || lowestPriceDistributor
  const stockLevel: StockLevelTypeV2 | null =
    getQuantity(selectedDistributor?.stock_levels) || getQuantity(lowestPriceDistributor?.stock_levels)

  if (!component) {
    return <></>
  }

  const handleAddToCart = () => {
    setIsInCart(true)
  }

  const handleDistributorSelect = (distributor) => {
    setSelectedDistributor(distributor)
  }

  return (
    <Wrapper>
      <Grid item container justify="space-evenly" direction="column" spacing={1}>
        <Grid item>
          <Typography
            textVariant="body2"
            colorHex={stockLevel?.quantity && Number(stockLevel.quantity) > 0 ? '#008000' : '#D32F2F'}
          >
            {getStockLevelLabel(stockLevel, displayDetailsDistributor)}
          </Typography>
          {stockLevel?.quantity && (
            <Typography textVariant="helper1"> {`${stockLevel?.quantity} Available`} </Typography>
          )}
        </Grid>
        <Grid item>
          {(selectedDistributor?.price || lowestPriceDistributor?.price) && (
            <PricingText
              hasOneDistributor={component.ordering_v2.length === 1}
              selectedDistributor={selectedDistributor}
              lowestPriceDistributor={lowestPriceDistributor}
            />
          )}
        </Grid>
        <Grid item>
          <Typography textVariant="helper1" colorHex="#757575">
            SELECT AVAILABLE DISTRIBUTOR
          </Typography>
          <DistributorChips
            ordering_v2={component.ordering_v2}
            onSelect={handleDistributorSelect}
            selectedDistributor={selectedDistributor}
          />
        </Grid>

        <br />
        <Divider />
        <Grid item container direction="column" justify="center" alignItems="center">
          {isInCart ? (
            <QuantityButton handleZeroQuantity={() => setIsInCart(false)} />
          ) : (
            <AddToCartButton onClick={handleAddToCart} />
          )}

          <Typography textVariant="helper1" colorHex="#757575">
            Shipping options will be available during checkout.{' '}
          </Typography>
        </Grid>
      </Grid>
    </Wrapper>
  )
}

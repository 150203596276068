import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import ContentCreate from '@material-ui/icons/CreateOutlined'
import { IconButton } from 'opensolar-ui'
import { formatStartTime } from 'pages/home/activityTab/UpComingActivity'
import React, { FC } from 'react'
import { DeleteButton, useReference, useTranslate } from 'react-admin'
import Skeleton from 'react-loading-skeleton'
import { useHistory } from 'react-router-dom'
import { Theme } from 'types/themes'

type PropsType = {
  records: any
  loading: Boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {},
  table: {
    tableLayout: 'fixed',
    // marginTop: 10,
    '& th': {
      padding: 4,

      borderWidth: 2,
      borderColor: '#e7e7e7',
    },
    '& thead': {
      borderTop: theme.border.default,
    },
    '& td': {
      padding: 4,
      borderWidth: 2,
      borderColor: '#e7e7e7',
    },
    '& thead tr': {
      background: '#F8F8F8',
    },
    '& thead th': {
      padding: '16px 4px',
    },
    '& th:nth-child(1)': {
      width: '40%',
      [theme.breakpoints.down('xs')]: { width: '30%' },
    },
  },
  list: {},
}))

const useStyles2 = makeStyles((theme) => ({
  label: {
    display: 'none',
  },
  iconButton: {
    color: 'rgb(77, 77, 77)',
    '&:hover': {
      background: 'rgba(77, 77, 77,0.2)',
    },
  },
  root: {
    borderRadius: '50%',
    padding: '12px',
    minWidth: 'auto',
    color: 'rgb(77, 77, 77)',
    '&:hover': {
      background: 'rgba(77, 77, 77,0.2)',
    },
  },
}))

export const Actions = ({ record }: any) => {
  const history = useHistory()
  const deleteButtonClasses = useStyles2()
  return (
    <>
      <IconButton
        size="medium"
        classes={{ root: deleteButtonClasses.iconButton }}
        onClick={() => {
          history.push(`/events/${record.id}`)
        }}
      >
        <ContentCreate />
      </IconButton>

      <DeleteButton
        undoable={false}
        size="medium"
        record={record}
        resource={'events'}
        classes={{ label: deleteButtonClasses.label }}
        className={deleteButtonClasses.root}
      />
    </>
  )
}
export const getActivity = (record: any) => {
  const date: any = {}
  if (record.start) {
    const start = new Date(record.start)
    date['startDate'] = start.toLocaleDateString()
    date['startTime'] = formatStartTime(start)
  }
  return (
    <span>
      {record.event_type_id !== 0 && record.notes && record.notes.length ? record.notes : record.title} on{' '}
      {date.startTime} {date.startDate}
    </span>
  )
}
export const Address = ({ project, record }: any) => {
  const { referenceRecord, loading, ...rest } = useReference({
    reference: 'projects',
    id: record.id,
  })

  return loading ? <Skeleton height="30px" /> : <span>{referenceRecord?.address}</span>
}
export const getDate = (record: any) => {
  const start = new Date(record.start)
  return start.toLocaleDateString()
}

const AssignedActivitiesTable: FC<PropsType> = ({ records, loading }) => {
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <Table className={classes.table} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>{translate('Activity')}</TableCell>
          <TableCell align="left">{translate('Project')}</TableCell>
          <TableCell align="left">{translate('Date')}</TableCell>
          <TableCell align="left"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {loading ? (
          <Skeleton height="50px" />
        ) : (
          records &&
          Object.values(records).map((row: any) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {getActivity(row)}
              </TableCell>
              <TableCell align="left">
                {row.project && <Address project={row.project} record={row.project_data} />}
              </TableCell>
              <TableCell align="left">{getDate(row)}</TableCell>
              <TableCell align="left">{<Actions record={row} />}</TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  )
}

export default AssignedActivitiesTable

import FullScreenLoading from 'layout/widgets/FullScreenLoading'
import React, { Suspense, useMemo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import useAutoCreatePrimaryWarehouse from '../location/hooks/useAutoCreatePrimaryWarehouse'

const useStyles = makeOpenSolarStyles((theme) => ({
  container: {
    flexGrow: 1,
  },
}))

const LazyInventoryPage = React.memo((props) => {
  const classes = useStyles()
  const { autoCreatePrimaryWarehouse } = useAutoCreatePrimaryWarehouse()

  const MyLazyComponent = useMemo(
    () =>
      React.lazy(async () => {
        // This is a hack to address the issue if org doesn't have primary warehouse
        // https://opensolar.slack.com/archives/C05H10QBVHQ/p1701044333747569
        const results = await Promise.all([import('./InventoryPage'), autoCreatePrimaryWarehouse()])
        return results[0]
      }),
    []
  )

  return (
    <div className={classes.container}>
      <Suspense fallback={<FullScreenLoading />}>
        <MyLazyComponent />
      </Suspense>
    </div>
  )
})

export default LazyInventoryPage

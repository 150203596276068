import { LandingPageCreate, LandingPageEdit, LandingPageList } from './LandingPage'

// const LandingPageTitle = ({ record, translate, style }) => {
//   return (
//     <span style={style}>
//       {translate('LandingPage Name')} {record ? `"${record.name}"` : ''}
//     </span>
//   )
// }

export default {
  list: LandingPageList,
  create: LandingPageCreate,
  edit: LandingPageEdit,
}

import { RootState } from 'types/state'
import { orgSelectors } from './orgs'

// selectors
export const adsSelectors = {
  getEnableAdsForOrg: (state: RootState) => {
    if (state.orgs?.org?.zone_org) {
      return false
    } else if (orgSelectors.getCobrandingOrg(state)) {
      return false
    } else {
      return true
    }
  },
}

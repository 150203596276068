import { useEffect, useState } from 'react'
import restClient from 'restClient'
import { StageActionEventType } from 'types/projects'
import composeOSResourceFetchPath from 'util/fetch/composeOSResourceFetchPath'

const restClientInstance = restClient(window.API_ROOT + '/api')

export const useGetCategorizedEvents = (eventCategories: string[], orgId: number, projectId: number) => {
  const [events, setEvents] = useState<StageActionEventType[]>([])
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [hasQueried, setHasQueried] = useState<boolean>(false)

  useEffect(() => {
    if (!hasQueried && orgId) {
      setIsFetching(true)
      setHasQueried(true)
      restClientInstance('CUSTOM_GET', 'custom', {
        url: composeOSResourceFetchPath({
          orgId,
          resource: 'events',
          params: {
            project: projectId,
            categories: eventCategories,
          },
        }),
      })
        .then((eventsResponse) => {
          setEvents(eventsResponse?.data)
        })
        .catch((err) => console.log('err', err))
        .finally(() => {
          setIsFetching(false)
        })
    }
  }, [eventCategories, orgId])

  return { events, isFetching }
}

import { BillSavings } from './BillSavings'
import { BillFrequencyTypeEnum } from 'types/bills'

export const billFrequencyQuantity: Record<BillFrequencyTypeEnum, number> = {
  monthly: 1,
  every_second_month: 2,
  quarterly: 3,
} as const

export const billFrequencyLabels: Record<BillFrequencyTypeEnum, string> = {
  monthly: 'Monthly',
  every_second_month: 'Every Second Month',
  quarterly: 'Quarterly',
}

export default BillSavings

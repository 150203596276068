import { osLiteSupportLink } from 'constants/links'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { setStudioSavePrompt } from 'ducks/studioPrompt'
import { viewModeSelectors } from 'ducks/viewMode'
import { BaseThemeType, ComponentVersionsInherit, MenuItem, PopoutMenu, styled } from 'opensolar-ui'
import { useLocale, useTranslate } from 'ra-core'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getHelpCenterByLocale } from 'util/misc'
import NavigationAccountButton from './NavigationAccountButton'

const NavSettingsMenuItem = styled(MenuItem)({
  // Top Nav Menu width: 208px, MenuItem margins: 8px horizontally
  width: 'calc(208px - 16px)',
})

interface NavigationSettingsMenuProps {
  showOrgSelector(): void
}

const NavigationSettingsMenu: React.FC<NavigationSettingsMenuProps> = ({ showOrgSelector }) => {
  const isUserLite = useSelector(viewModeSelectors.isUserLite)
  const isStaff = useSelector(authSelectors.getIsStaff)
  const org = useSelector(orgSelectors.getOrg)

  const dispatch = useDispatch()
  const history = useHistory()
  const locale = useLocale()
  const translate = useTranslate()

  const handleRequestClose = (to, label) => {
    if (window.projectForm?.mutators.getFormDirtyFields().length > 0) {
      dispatch(
        setStudioSavePrompt({
          show_prompt: true,
          redirect: to,
          prompt_heading: translate('Leave Project to %{label}?', { label: translate(label) }),
        })
      )
    } else history.push(to)
  }

  if (!org) return null

  return (
    <ComponentVersionsInherit versions={{ button: 3 }}>
      <PopoutMenu
        anchorEl={<NavigationAccountButton logoPublicUrl={org.logo_public_url} />}
        menuId="nav-account-context"
        menuProps={{
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
          transformOrigin: { horizontal: 'right', vertical: 'top' },
        }}
      >
        <NavSettingsMenuItem
          divider
          onClick={() => {
            handleRequestClose('/configuration', 'Settings')
          }}
        >
          {translate('Settings')}
        </NavSettingsMenuItem>
        {isStaff && (
          <NavSettingsMenuItem
            onClick={() => {
              if (window.projectForm?.mutators.getFormDirtyFields().length > 0) {
                const result = window.confirm('Any unsaved data will be lost. Are you sure?')
                if (!result) return
              }
              showOrgSelector()
            }}
          >
            {translate('Select Organisation')}
          </NavSettingsMenuItem>
        )}
        <NavSettingsMenuItem
          divider
          onClick={() => {
            isUserLite ? window.open(osLiteSupportLink, '_blank') : window.open(getHelpCenterByLocale(locale), '_blank')
          }}
        >
          {translate('Help Center')}
        </NavSettingsMenuItem>
        <NavSettingsMenuItem
          onClick={() => {
            handleRequestClose('/logout', 'Logout')
          }}
          sx={(theme: BaseThemeType) => ({ color: theme.palette.red[900] })}
        >
          {translate('Logout')}
        </NavSettingsMenuItem>
      </PopoutMenu>
    </ComponentVersionsInherit>
  )
}

export default NavigationSettingsMenu

// @ts-nocheck
import { RefreshOutlined } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import React, { useEffect, useState } from 'react'
import { AutocompleteInput, ReferenceInput, SimpleForm, useRefresh, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import SwapPaymentOptionsProjectList from './SwapPaymentOptionsProjectList'

type PropTypes = {}

const SwapPaymentOptionsPage: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const form = useForm()
  const refresh = useRefresh()
  const orgId: number = useSelector(authSelectors.getOrgId)
  const isAdmin: boolean = useSelector(authSelectors.getIsAdmin)
  const isStaff: boolean = useSelector(authSelectors.getIsStaff)

  const [oldPaymentOption, setOldPaymentOption] = useState<number | undefined>(undefined)
  const [newPaymentOption, setNewPaymentOption] = useState<number | undefined>(undefined)
  const [refreshing, setRefreshing] = useState<boolean>(false)

  const changeOldPaymentOption = (newVal: number) => {
    setOldPaymentOption(newVal)
    if (!newPaymentOption) {
      form.change('new_payment_option', newVal)
      setNewPaymentOption(newVal)
    }
    logAmplitudeEvent('bulk_update_old_pmt_chosen', { payment_option_id: newVal })
  }

  useEffect(() => {
    logAmplitudeEvent('bulk_update_page_viewed', {})
  }, [])

  useEffect(() => {
    if (newPaymentOption) logAmplitudeEvent('bulk_update_new_pmt_chosen', { payment_option_id: newPaymentOption })
  }, [newPaymentOption])

  const doRefresh = () => {
    refresh()
    // react-admin doesn't seem to give us access to a loading var here, so we'll just be dumb and use a conservative timeout
    setRefreshing(true)
    setTimeout(() => {
      setRefreshing(false)
    }, 5000)
  }

  if (!isAdmin && !isStaff) {
    return (
      <div>
        <h2>Inadequate permissions</h2>
        <p>You do not have the appropriate permissions to access this page</p>
      </div>
    )
  }
  return (
    <div>
      <p>
        This page can be used to replace payment options that are currently being used on your projects and then
        automatically recalculate the system using the new prepayment
      </p>
      <h2>Step 1: Select Payment Options</h2>
      <div>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}>
          <div style={{ width: '400px', margin: '10px' }}>
            <ReferenceInput
              source="old_payment_option"
              label="Payment Option you want to replace"
              reference="payment_options"
              allowEmpty
              resource="payment_options"
              renderChildrenBeforeRecord={true}
              onChange={(newVal: number) => changeOldPaymentOption(newVal)}
            >
              <AutocompleteInput
                sortChoices={true}
                source="title"
                optionText="title"
                optionValue="id"
                options={{ fullWidth: true, placeholder: translate('search for a payment option') }}
                showSpinnerWhenRefreshing={true}
              />
            </ReferenceInput>
          </div>
          <div style={{ width: '400px', margin: '10px' }}>
            <ReferenceInput
              source="new_payment_option"
              label="Payment option you want to switch to"
              reference="payment_options"
              allowEmpty
              resource="payment_options"
              renderChildrenBeforeRecord={true}
              onChange={(newVal: number) => setNewPaymentOption(newVal)}
            >
              <AutocompleteInput
                sortChoices={true}
                source="title"
                optionText="title"
                optionValue="id"
                options={{ fullWidth: true, placeholder: translate('search for a payment option') }}
                showSpinnerWhenRefreshing={true}
              />
            </ReferenceInput>
          </div>
          <div>
            <ProUXButton
              type="secondary"
              startIcon={<RefreshOutlined />}
              label="Refresh Table"
              onClick={doRefresh}
              disabled={!oldPaymentOption}
              loading={refreshing}
            />
          </div>
        </div>
      </div>
      {oldPaymentOption && (
        <SwapPaymentOptionsProjectList
          orgId={orgId}
          oldPaymentOption={oldPaymentOption}
          newPaymentOption={newPaymentOption}
          {...props}
        />
      )}
    </div>
  )
}

const FormWrapper = (props) => {
  return (
    <SimpleForm formatSubmitValues={() => null} hideCancelButton={true} toolbar={<div></div>}>
      <SwapPaymentOptionsPage {...props} />
    </SimpleForm>
  )
}
export default FormWrapper

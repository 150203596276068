import CustomFormCreate from './CustomFormCreate'
import CustomFormEdit from './CustomFormEdit'
import CustomFormList from './CustomFormList'

export default {
  list: CustomFormList,
  edit: CustomFormEdit,
  create: CustomFormCreate,
  options: {
    list: {
      title: 'Custom Forms',
      breadCrumb: 'Custom Forms',
    },
    show: {
      title: 'Custom Form title',
      subtitle: null,
      breadCrumb: 'Custom Form',
    },
  },
}

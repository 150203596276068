import { useViewShow } from 'ducks/viewMode'
import React, { PropsWithChildren } from 'react'

type UiContainerPropsType = {
  uiKey: string
}

/**
 * This component is a basic compntainer with a UI key, to allow it to
 * be hidden/shown using the view mode system.
 */

export const UiSwitch: React.FunctionComponent<PropsWithChildren<UiContainerPropsType>> = ({
  uiKey,
  children,
  ...rest
}) => {
  const show = useViewShow(uiKey)

  if (!show) return <></>
  else if (typeof children === 'function') return children(rest)
  else return children
}

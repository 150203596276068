import { useDataProvider } from 'ra-core'
import { useCallback, useState } from 'react'
import type { Transfer } from 'resources/inventoryTransfer/type'

type OrderDataResponse = {
  loadingOrder: boolean
  loadOrderData: (projectId?: number) => Promise<void>
  loadedOrderData: Transfer[]
}

const useGetOrderDataByProject = (): OrderDataResponse => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [loadedData, setLoadedData] = useState<Transfer[]>()
  const dataProvider = useDataProvider()
  const loadOrderData = useCallback(async (projectId) => {
    setIsLoading(true)
    try {
      const loadedData = await dataProvider.CUSTOM_GET('inventory_transfer', {
        url: `inventory_transfer/incoming/?ordering=-id&projects__id=${projectId}`,
      })
      setLoadedData(loadedData?.data)
    } catch (error) {
      console.error('Failed to load order data', error)
    }
    setIsLoading(false)
  }, [])

  return {
    loadingOrder: isLoading,
    loadOrderData,
    loadedOrderData: loadedData as Transfer[],
  }
}

export default useGetOrderDataByProject

export const STATE_CHOICES = {
  US: [
    {
      id: 'AL',
      name: 'AL',
    },
    {
      id: 'AK',
      name: 'AK',
    },
    {
      id: 'AS',
      name: 'AS',
    },
    {
      id: 'AZ',
      name: 'AZ',
    },
    {
      id: 'AR',
      name: 'AR',
    },
    {
      id: 'CA',
      name: 'CA',
    },
    {
      id: 'CO',
      name: 'CO',
    },
    {
      id: 'CT',
      name: 'CT',
    },
    {
      id: 'DE',
      name: 'DE',
    },
    {
      id: 'DC',
      name: 'DC',
    },
    {
      id: 'FM',
      name: 'FM',
    },
    {
      id: 'FL',
      name: 'FL',
    },
    {
      id: 'GA',
      name: 'GA',
    },
    {
      id: 'GU',
      name: 'GU',
    },
    {
      id: 'HI',
      name: 'HI',
    },
    {
      id: 'ID',
      name: 'ID',
    },
    {
      id: 'IL',
      name: 'IL',
    },
    {
      id: 'IN',
      name: 'IN',
    },
    {
      id: 'IA',
      name: 'IA',
    },
    {
      id: 'KS',
      name: 'KS',
    },
    {
      id: 'KY',
      name: 'KY',
    },
    {
      id: 'LA',
      name: 'LA',
    },
    {
      id: 'ME',
      name: 'ME',
    },
    {
      id: 'MH',
      name: 'MH',
    },
    {
      id: 'MD',
      name: 'MD',
    },
    {
      id: 'MA',
      name: 'MA',
    },
    {
      id: 'MI',
      name: 'MI',
    },
    {
      id: 'MN',
      name: 'MN',
    },
    {
      id: 'MS',
      name: 'MS',
    },
    {
      id: 'MO',
      name: 'MO',
    },
    {
      id: 'MT',
      name: 'MT',
    },
    {
      id: 'NE',
      name: 'NE',
    },
    {
      id: 'NV',
      name: 'NV',
    },
    {
      id: 'NH',
      name: 'NH',
    },
    {
      id: 'NJ',
      name: 'NJ',
    },
    {
      id: 'NM',
      name: 'NM',
    },
    {
      id: 'NY',
      name: 'NY',
    },
    {
      id: 'NC',
      name: 'NC',
    },
    {
      id: 'ND',
      name: 'ND',
    },
    {
      id: 'MP',
      name: 'MP',
    },
    {
      id: 'OH',
      name: 'OH',
    },
    {
      id: 'OK',
      name: 'OK',
    },
    {
      id: 'OR',
      name: 'OR',
    },
    {
      id: 'PW',
      name: 'PW',
    },
    {
      id: 'PA',
      name: 'PA',
    },
    {
      id: 'PR',
      name: 'PR',
    },
    {
      id: 'RI',
      name: 'RI',
    },
    {
      id: 'SC',
      name: 'SC',
    },
    {
      id: 'SD',
      name: 'SD',
    },
    {
      id: 'TN',
      name: 'TN',
    },
    {
      id: 'TX',
      name: 'TX',
    },
    {
      id: 'UT',
      name: 'UT',
    },
    {
      id: 'VT',
      name: 'VT',
    },
    {
      id: 'VI',
      name: 'VI',
    },
    {
      id: 'VA',
      name: 'VA',
    },
    {
      id: 'WA',
      name: 'WA',
    },
    {
      id: 'WV',
      name: 'WV',
    },
    {
      id: 'WI',
      name: 'WI',
    },
    {
      id: 'WY',
      name: 'WY',
    },
  ],
  GB: [
    {
      id: 'England',
      name: 'England',
    },
    {
      id: 'Scotland',
      name: 'Scotland',
    },
    {
      id: 'Wales',
      name: 'Wales',
    },
    {
      id: 'Northern Ireland',
      name: 'Northern Ireland',
    },
  ],
  AU: [
    {
      id: 'NSW',
      name: 'NSW',
    },
    {
      id: 'VIC',
      name: 'VIC',
    },
    {
      id: 'QLD',
      name: 'QLD',
    },
    {
      id: 'WA',
      name: 'WA',
    },
    {
      id: 'SA',
      name: 'SA',
    },
    {
      id: 'TAS',
      name: 'TAS',
    },
  ],
}

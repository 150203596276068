import { FC } from 'react'
import { ReferenceInput, SelectInput } from 'react-admin'
import ConditionalRefFilter from './ConditionalRefFilter'

interface PropTypes {
  source: string
  fieldId: string
}

const ProjectStage: FC<PropTypes> = (props) => {
  return (
    <ConditionalRefFilter {...props}>
      <ReferenceInput source={`${props.source}.fields.itemId`} reference="stages" label="">
        <SelectInput
          source="title"
          optionText={(value: any) => {
            if (value == null) return ''
            return value.title + (value.workflow_title ? ` (${value.workflow_title})` : '')
          }}
          optionValue="id"
          variant="outlined"
          margin="none"
        />
      </ReferenceInput>
    </ConditionalRefFilter>
  )
}

export default ProjectStage

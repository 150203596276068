import appStorage from 'storage/appStorage'
import DefaultHostConfig from './DefaultHostConfig'
import IronridgeHostConfig from './IronridgeHostConfig'
import getHostConfigByName from './getHostConfigByName'
import type HostConfig from './types'

export const AVAILABLE_HOST_CONFIGS = [DefaultHostConfig, IronridgeHostConfig]

const getHostConfigByLocation = (): HostConfig => {
  const hostname = window.location.hostname

  if (DefaultHostConfig.hostnames.includes(hostname)) {
    return DefaultHostConfig
  } else if (IronridgeHostConfig.hostnames.includes(hostname)) {
    return IronridgeHostConfig
  }

  return DefaultHostConfig
}
const useHostConfig = (): HostConfig => {
  const configOverride = appStorage.getString('host_config')
  const localStorageOverride = configOverride && getHostConfigByName(configOverride)
  return localStorageOverride || getHostConfigByLocation()
}

export default useHostConfig

import { Box, Chip } from 'opensolar-ui'
import { useTranslate } from 'ra-core'

// @TODO: Standardize this so the mapping is consistent across the entire UI
const getDisplayStringForObject = (object, translate) => {
  switch (object.type) {
    case 'OsModuleGrid': {
      const panels = object.moduleQuantity()
      return panels === 1 ? translate('1 Panel') : translate('%{panels} Panels', { panels })
    }

    case 'OsFacet': {
      return translate('Roof Facet')
    }

    case 'OsStructure': {
      return translate('Quick Roof')
    }

    default: {
      return object.type || translate('Object')
    }
  }
}

const SelectionChips = ({ objects }: { objects: any[] }) => {
  const translate = useTranslate()
  return (
    <Box display="flex" gridColumnGap={4} gridRowGap={4} flexWrap={'wrap'}>
      {objects.map((object, index) => {
        return <Chip id={`Object-${index}`} key={object.uuid} label={getDisplayStringForObject(object, translate)} />
      })}
    </Box>
  )
}

export default SelectionChips

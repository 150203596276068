// @ts-nocheck

import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutlineOutlined'
import { Button, IconButton } from 'opensolar-ui'
import React, { useCallback, useState } from 'react'
import { CRUD_DELETE, useDelete, useNotify, useTranslate } from 'react-admin'
import { DeleteButtonPropTypes } from '../button/types'

const DeleteButton: React.FunctionComponent<DeleteButtonPropTypes> = (props) => {
  const [deleting, setDeleting] = useState<boolean>(false)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const translate = useTranslate()
  const notify = useNotify()

  const [deleteOne, { loading }] = useDelete(props.resource, props.record.id, props.record, {
    action: CRUD_DELETE,
    onSuccess: (response: any) => {
      notify('ra.notification.deleted', 'info', { smart_count: 1 })
      if (props.onSuccess) props.onSuccess(response)
      setDeleting(false)
    },
    onFailure: (error: any) => {
      notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning')
      if (props.onFailure) props.onFailure()
      setDeleting(false)
    },
    undoable: false,
  })

  const renderConfirmDialog = () => {
    return (
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogTitle>{props.confirmTitle ? props.confirmTitle : translate('Delete this item?')}</DialogTitle>
        <DialogContent>
          <div>
            <p style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
              {props.confirmBody ? props.confirmBody : translate('Are you sure you want to delete this item?')}
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%' }}>
            <div style={{ marginLeft: '25px' }}>
              <Button
                onClick={() => setShowDialog(false)}
                variant="contained"
                color="default"
                startIcon={<ErrorOutlineIcon />}
                disabled={deleting}
              >
                <span>{translate('cancel')}</span>
              </Button>
            </div>
            <div style={{ marginLeft: '25px' }}>
              <Button onClick={() => handleDelete} variant="contained" color="default" startIcon={<CheckCircleIcon />}>
                <span>{translate('delete')}</span>
              </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    )
  }

  const onClick = (e: any) => {
    if (props.confirmMode === 'dialog') {
      setShowDialog(true)
    } else {
      handleDelete(e)
    }
  }

  const handleDelete = useCallback(
    (e) => {
      setDeleting(true)
      deleteOne()
    },
    [deleteOne]
  )

  if (props.hideLabel && props.startIcon) {
    return (
      <div style={{ position: 'relative', width: props.fullWidth ? '100%' : undefined }}>
        <Tooltip title={props.title}>
          <IconButton
            onClick={onClick}
            disabled={props.invalid || props.disabled}
            size={props.size === 'large' ? 'medium' : props.size}
            style={props.style}
          >
            {React.cloneElement(props.startIcon, {
              htmlColor: loading ? 'transparent' : props.startIcon?.props?.htmlColor,
            })}
          </IconButton>
        </Tooltip>
        {loading && (
          <div style={{ position: 'absolute', left: 'calc(50% - 12px)', top: 'calc(50% - 12px)' }}>
            <CircularProgress size={24} />
          </div>
        )}
        {props.confirmMode === 'dialog' && showDialog && renderConfirmDialog()}
      </div>
    )
  } else {
    return (
      <div
        style={{
          position: 'relative',
          width: props.fullWidth ? '100%' : undefined,
          display: props.fullWidth ? undefined : 'table-cell',
        }}
      >
        <Button
          onClick={onClick}
          disabled={props.disabled}
          variant={'contained'}
          startIcon={props.startIcon}
          size={props.size}
          style={{ ...props.style }}
        >
          <span>{translate(props.label)}</span>
        </Button>
        {loading && (
          <div style={{ position: 'absolute', left: 'calc(50% - 12px)', top: 'calc(50% - 12px)' }}>
            <CircularProgress size={24} />
          </div>
        )}
        {props.confirmMode === 'dialog' && showDialog && renderConfirmDialog()}
      </div>
    )
  }
}
export default DeleteButton

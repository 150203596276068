import { Grid } from '@material-ui/core'
import Alert from 'elements/Alert'
import { useTranslate } from 'ra-core'
import React from 'react'
import { useGetCashflowCustomerInfoError } from '../../cashFlowTransactions/utils'

type PropTypes = {}

const RequiredCustomerInfoAlert: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const error = useGetCashflowCustomerInfoError()

  if (!error) return null
  return (
    <>
      <Grid item xs={12}>
        <Alert severity="error">{translate(error)}</Alert>
      </Grid>
    </>
  )
}

export default RequiredCustomerInfoAlert

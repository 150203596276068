// @ts-nocheck
import { Dialog, DialogContent, DialogTitle, Grid, InputLabel } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { orgsActions } from 'ducks/orgs'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import LoadingDots from 'layout/widgets/LoadingDots'
import { default as React, useEffect, useState } from 'react'
import { SelectInput, SimpleForm, TextInput, useNotify, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'

interface PropTypes {
  onClose: () => void
  isOpen: boolean
}

const DocusignCATemplateDialog: React.FunctionComponent<PropTypes> = (props) => {
  const [saving, setSaving] = useState<boolean>(false)
  const [fetching, setFetching] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const [initialValues, setInitialValues] = useState<any>({})
  const translate = useTranslate()
  const dispatch = useDispatch()
  const notify = useNotify()
  const getInitialValues = () => {
    if (initialValues) return initialValues
    else return {}
  }

  const orgId = useSelector(authSelectors.getOrgId) as number
  const enabled: boolean = !!useSelector(authSelectors.getCurrentOrg)?.docusign_config
    ?.enable_california_protection_guide

  useEffect(() => {
    if (enabled && props.isOpen) {
      fetchExistingTemplate()
    }
  }, [enabled, props.isOpen])

  const fetchExistingTemplate = () => {
    setFetching(true)
    const restClientInstance = restClient(window.API_ROOT + '/api')
    restClientInstance('CUSTOM_GET', 'custom', {
      url: 'orgs/' + orgId + '/template_contents/',
      data: {
        template_type: 'CA_consumer_protection',
      },
    })
      .then((response: any) => {
        if (response.data.success) {
          setInitialValues(response.data.field_map)
        }
      })
      .catch((error: any) => {
        console.log('error', error)
      })
      .finally(() => setFetching(false))
  }

  const onSubmit = (vals: any) => {
    setSaving(true)
    const restClientInstance = restClient(window.API_ROOT + '/api')
    restClientInstance('CUSTOM_POST', 'custom', {
      url: 'orgs/' + orgId + '/add_template/',
      data: {
        ...vals,
        template_type: 'CA_consumer_protection',
      },
    })
      .then((response: any) => {
        if (enabled) notify('Your changes have been saved', 'success')
        else notify('Your CA Consumer Protection template is enabled', 'success')
        props.onClose()
        dispatch(orgsActions.updateOrgWithCaTemplate(orgId, true))
        setErrorMsg(undefined)
      })
      .catch((error: any) => {
        console.log('error', error)
        if (error?.body?.message) setErrorMsg(error.body.message)
        else setErrorMsg('Unable to save Docusign template')
      })
      .finally(() => setSaving(false))
  }

  const disconnect = () => {
    setSaving(true)
    const restClientInstance = restClient(window.API_ROOT + '/api')
    restClientInstance('CUSTOM_PUT', 'custom', {
      url: 'orgs/' + orgId + '/disconnect_template/',
      data: {
        template_type: 'CA_consumer_protection',
      },
    })
      .then((response: any) => {
        if (response.data.success) {
          notify('Docusign Template has been disabled', 'success')
          props.onClose()
          dispatch(orgsActions.updateOrgWithCaTemplate(orgId, false))
        }
      })
      .catch((error: any) => {
        console.log('error', error)
      })
      .finally(() => setSaving(false))
  }

  return (
    <Dialog open={props.isOpen} onClose={props.onClose} maxWidth="xl">
      <DialogTitle>{translate('CA Consumer Protection Guide Template')}</DialogTitle>
      <DialogContent>
        <div>
          <p>
            {enabled
              ? 'By filling out the form below and clicking "Save Changes" you will edit your existing CA Consumer Protection Template. These changes will immediately take effect on all new envelopes. Existing envelopes will not be affected.'
              : 'By filling out the form below and clicking "Enable Template" you will enable a cover letter to all Docusign contracts for projects based in California. This cover letter will be signed by the customer first and then will be sent to your designated contract countersigner.'}
          </p>
          <p>
            The fields below are all optional, but if you populate them here then they will be pre-populated for you
            when it comes time to countersign.
          </p>
          {fetching ? (
            <div
              style={{
                height: '150px',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <LoadingDots text="One moment while we fetch your current template..." />
            </div>
          ) : (
            <SimpleForm initialValues={getInitialValues()} toolbar={<div></div>}>
              <FormContents {...props} onSubmit={onSubmit} loading={saving} enabled={enabled} errorMsg={errorMsg} />
            </SimpleForm>
          )}
        </div>
        {enabled && (
          <div
            className="small"
            style={{
              textDecoration: 'underline',
              textAlign: 'center',
              width: '100%',
              margin: '20px 0px',
              cursor: 'pointer',
            }}
            onClick={disconnect}
          >
            Or click here if you want to disable this Docusign template
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

interface ContentProps extends PropTypes {
  onSubmit: (vals: any) => void
  loading: boolean
  enabled: boolean
  errorMsg: string | undefined
}

const FormContents: React.FunctionComponent<ContentProps> = (props) => {
  const form = useForm()

  const onSubmit = () => {
    props.onSubmit(form.getState().values)
  }
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <InputLabel>Consumer Protection Policy Version: </InputLabel>
        <SelectInput
          fullWidth={true}
          source="version"
          choices={[
            { id: '-8-2022', name: '2022 Version' },
            { id: 'initial', name: '2021 Version' },
          ]}
        />
        <div className="small" style={{ marginBottom: '10px' }}>
          Changing the version will not impact Docusign envelopes that have already been completed. If you would like to
          update existing envelopes you can use the Manage Docusign Contract button in the info and/or manage page to
          reset the incomplete contract.
        </div>
      </Grid>
      <Grid item xs={12}>
        <TextInput label="Company Name" source="company_name" fullWidth style={{ width: '100%' }} />
      </Grid>
      <Grid item xs={4}>
        <TextInput label="Company Email" source="company_email" fullWidth style={{ width: '100%' }} />
      </Grid>
      <Grid item xs={4}>
        <TextInput label="Company Phone" source="company_phone" fullWidth style={{ width: '100%' }} />
      </Grid>
      <Grid item xs={4}>
        <TextInput label="Company CSLB License #" source="cslb_number" fullWidth style={{ width: '100%' }} />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          label="Company CSLB License Classification"
          source="cslb_classification"
          fullWidth
          style={{ width: '100%' }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          label="Default HIS Registration Number"
          source="his_registration"
          fullWidth
          style={{ width: '100%', marginBottom: '3px' }}
        />
        <span className="small">
          if you want each envelope to pre-populate with a certain HIS registration number you can enter it above. It
          can always be overwritten by whoever is countersigning the document.
        </span>
      </Grid>
      {}
      <Grid item xs={12}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            marginBottom: '20px',
          }}
        >
          <div style={{ margin: '0px 20px' }}>
            <ProUXButton type="secondary" label="Cancel" onClick={props.onClose} />
          </div>
          <div style={{ margin: '0px 20px' }}>
            <ProUXButton
              type="primary"
              label={props.enabled ? 'Save Changes' : 'Enable Template'}
              onClick={onSubmit}
              loading={props.loading}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

export default DocusignCATemplateDialog

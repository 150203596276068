const formatWorkflowValues = (submitValues: any, formValues: any, dirtyFields: any[]) => {
  if (submitValues.workflow) {
    submitValues['active_stage_id'] = formValues.workflow?.active_stage_id
  }
  //TODO: Update setting of this field (both BE and FE) to handle multiple org shares (SharedWorkflows.tsx > updateProjectWorkflows)
  //possible solution: set both internal and external workflow data through workflows payload
  if (submitValues.external_workflow) {
    submitValues['external_workflow'] = { ...formValues.external_workflow }
  }

  return submitValues
}

export default formatWorkflowValues

import { Redirect, useLocation } from 'react-router-dom'
import { parseQueryStringToDictionary } from 'util/misc'
import { IRONRIDGE_SSO_CONFIG } from './ironridge/Config'
import { SALESFORCE_SDK_SSO_CONFIG } from './salesforce_sdk/Config'
import GenericErrorPage from './sso/GenericErrorPage'
import type { SSOFlowConfigType } from './sso/types'

const AVAILABLE_SSO_CONFIGS: SSOFlowConfigType[] = [IRONRIDGE_SSO_CONFIG, SALESFORCE_SDK_SSO_CONFIG]

/* 
  Ideally, this component should not be restricted solely to Single Sign-On (SSO) but should also accommodate other login scenarios involving query tokens.
  However, currently, it is only utilized for SSO, so it returns SSO partner not found for now.
*/
const LoginWithQueryToken = () => {
  const location = useLocation()
  const queryParams = parseQueryStringToDictionary(location.search)
  const ssoConfig = AVAILABLE_SSO_CONFIGS.find((config) => config.name === queryParams.source || (config.name === 'salesforce_sdk' && queryParams.source?.startsWith('salesforce_sdk.')))

  if (ssoConfig === undefined) {
    return <GenericErrorPage />
  }

  return (
    <Redirect
      to={{
        pathname: '/sso-login',
        state: { ssoConfig: ssoConfig, options: queryParams },
      }}
    />
  )
}

export default LoginWithQueryToken

import { makeStyles } from '@material-ui/core'
import InfoTooltip from 'elements/tooltip/InfoTooltip'
import { ComponentLyraStatus, useLyraComponentStatus } from 'projectSections/integrations/lyra/useLyraComponentStatus'
import React, { useMemo } from 'react'
import { Theme } from 'types/themes'
import { doNotTranslate } from 'util/misc'

const useStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    maxWidth: 220,
    textAlign: 'center',
    backgroundColor: theme.greyMid1,
    color: theme.greyLight3,
  },
}))

interface PropTypes {
  component
}
const LyraComponentWarningTooltip: React.FC<PropTypes> = ({ component }) => {
  const classes = useStyles()
  const lyraStatus = useLyraComponentStatus()(component)
  const lyraWarning = useMemo(() => {
    if (lyraStatus === ComponentLyraStatus.NOT_MAPPED)
      return 'Component not mapped for Permit Pack generation. Choose from mapped options if creating a permit pack.'
    else if (lyraStatus === ComponentLyraStatus.MAPPED_NOT_SUPPORTED)
      return 'Component not supported by Lyra for permit pack creation. Please select a different one.'
  }, [lyraStatus])

  return (
    <>
      {lyraWarning && (
        <InfoTooltip
          severity={'error'}
          title={<span>{doNotTranslate(lyraWarning)}</span>}
          placement="top"
          style={{ marginLeft: 5 }}
          classes={{ tooltip: classes.tooltip }}
        />
      )}
    </>
  )
}
export default LyraComponentWarningTooltip

// @ts-nocheck
import { Dialog, DialogContent, useMediaQuery } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { getAvailableActions, markAsSold, openCreditDecisionDialog } from 'ducks/myEnergy'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import LoadingDots from 'layout/widgets/LoadingDots'
import { Checkbox } from 'opensolar-ui'
import React, { useEffect, useState } from 'react'
import { SimpleForm, useTranslate } from 'react-admin'
import Markdown from 'react-markdown'
import { useDispatch } from 'react-redux'
import restClient from 'restClient'
import { ProjectType } from 'types/projects'
import { getCommonCreditDecision, getFirstRealContact } from 'util/misc'
import { COBORROWER_PREFIX } from './constants'
import MosaicApplicationDialogFooter from './MosaicApplicationDialogFooter'
import MosaicApplicationForm from './MosaicApplicationForm'
import { MosaicDecisionType, MosaicDisclosuresType, MosaicProductType } from './types'

var restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  open: boolean
  onClose: () => void
  disclosures: MosaicDisclosuresType[]
  bundleId: string
  project: ProjectType
  paymentOptionId: number
  systemUuid: string
  orgId: number
  onDecisionRendered: () => void
  productType: MosaicProductType
  isFullWidth: boolean
}

const MosaicApplicationDialog: React.FunctionComponent<PropTypes> = (props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [decision, setDecision] = useState<MosaicDecisionType | undefined>(undefined)
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const [agreedToTerms, setAgreedToTerms] = useState<boolean>(false)
  const [isBlocked, setIsBlocked] = useState<boolean>(false)
  const [blockedMsg, setBlockedMsg] = useState<string | undefined>(undefined)
  const [requireFullSSN, setRequireFullSSN] = useState<boolean>(false)

  const translate = useTranslate()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery('(max-width:800px)')

  useEffect(() => {
    logAmplitudeEvent('integrated_finance_application_opened', {
      integration: 'mosaic',
      project_id: props.project?.id,
      payment_option_id: props?.paymentOptionId,
    })
  }, [])

  useEffect(() => {
    if (!props.project?.contacts || props.project?.contacts?.length === 0) {
      setErrorMsg('Please add a customer to this project before submitting the application')
    }
  }, [props.project?.contacts])

  useEffect(() => {
    if (decision) {
      props.onDecisionRendered()
      if (decision === 'Approved' || decision === 'ApprovedWithStipulations') {
        dispatch(markAsSold(props.systemUuid, props.paymentOptionId))
      }
      // wait a bit, then go fetch new customer actions
      setTimeout(() => {
        dispatch(getAvailableActions(props.orgId, props.project.id))
      }, 500)
    }
  }, [decision])

  const getInitialValues = () => {
    if (!props.project) return {}
    // get primary contact's info
    let primaryContact = undefined
    if (props.project.contacts_data?.length) {
      primaryContact = getFirstRealContact(props.project.contacts_data)
    } else {
      primaryContact =
        props.project.contacts && props.project.contacts.length > 0 ? props.project?.contacts[0] : undefined
    }
    const first_name = primaryContact ? primaryContact.first_name : undefined
    const family_name = primaryContact ? primaryContact.family_name : undefined

    // get secondary contact's info if there is one
    const secondaryContact =
      props.project.contacts && props.project.contacts.length > 1 ? props.project?.contacts[1] : undefined

    return {
      first_name: first_name,
      family_name: family_name,
      address: props.project.address,
      locality: props.project.locality,
      state: props.project.state,
      zip: props.project.zip,
      email: primaryContact ? primaryContact.email : undefined,
      phone: primaryContact ? primaryContact.phone : undefined,
      residence_type: 'Primary',
      [`${COBORROWER_PREFIX}first_name`]: secondaryContact ? secondaryContact.first_name : undefined,
      [`${COBORROWER_PREFIX}family_name`]: secondaryContact ? secondaryContact.family_name : undefined,
      [`${COBORROWER_PREFIX}email`]: secondaryContact ? secondaryContact.email : undefined,
      [`${COBORROWER_PREFIX}phone`]: secondaryContact ? secondaryContact.phone : undefined,
    }
  }

  const getValidationError = (formVals: any) => {
    let applicantConsents: MosaicDisclosuresType[] = props.disclosures.filter(
      (disc) => disc.scope === 'Applicant' && disc.assetCode !== 'COAPP'
    )

    let missingConsentId: string | undefined = undefined
    applicantConsents.forEach((disc) => {
      if (disc.assetType === 'radio button') return
      let primaryKey = `consent_${disc.assetCode}`
      let secondaryKey = `${COBORROWER_PREFIX}consent_${disc.assetCode}`
      if (!formVals[primaryKey]) missingConsentId = primaryKey
      else if (!!formVals.has_coborrower && !formVals[secondaryKey]) missingConsentId = secondaryKey

      // clear out any red text we added
      try {
        let consentDiv = document.getElementById(primaryKey)
        consentDiv.style.color = 'black'

        if (!!formVals.has_coborrower) {
          let consentDiv = document.getElementById(secondaryKey)
          consentDiv.style.color = 'black'
        }
      } catch (ex) {}
    })

    if (missingConsentId) {
      try {
        let consentDiv = document.getElementById(missingConsentId)
        consentDiv?.scrollIntoView()
        consentDiv.style.color = 'red'
      } catch (ex) {}
      if (missingConsentId?.includes(COBORROWER_PREFIX))
        return translate('Please review and check all applicable checkboxes at the bottom of the co-borrower section')
      else
        return translate(
          'Please review and check all applicable checkboxes at the bottom of the primary borrower section'
        )
    } else if (!agreedToTerms) {
      try {
        document.getElementById('mosaic-terms')?.scrollIntoView()
      } catch (ex) {}
      return translate('Please read and agree to the disclosures')
    }
    return undefined
  }

  const submitApplication = (formVals: any) => {
    let error: string | undefined = getValidationError(formVals)
    if (error) {
      logAmplitudeEvent('integrated_finance_application_form_error', {
        integration: 'mosaic',
        error: error,
        project_id: props.project?.id,
        payment_option_id: props?.paymentOptionId,
      })
      setErrorMsg(error)
      return
    }
    setLoading(true)
    // @ts-ignore
    restClientInstance('CUSTOM_POST', 'custom', {
      url:
        'orgs/' +
        props.orgId +
        '/projects/' +
        props.project?.id +
        '/systems/' +
        props.systemUuid +
        '/' +
        props.paymentOptionId +
        '/mosaic/credit_app/',
      data: {
        ...formVals,
        disclosure_bundle_id: props.bundleId,
        disclosures: props.disclosures,
        income: formVals.income ? formVals.income.replace(/[^0-9.,$]/g, '') : undefined,
        coborrower_income: formVals.coborrower_income
          ? formVals.coborrower_income.replace(/[^0-9.,$]/g, '')
          : undefined,
      },
    })
      .then((response: any) => {
        if (response?.data?.success && response?.data?.decision) {
          logAmplitudeEvent('integrated_finance_application_submitted', {
            integration: 'mosaic',
            decision: getCommonCreditDecision(response.data.decision),
            project_id: props.project?.id,
            payment_option_id: props?.paymentOptionId,
          })
          setDecision(response.data.decision)
          props.onClose()
          const decisionDisplayProps = {
            decision: response.data.decision,
            amountRequested: response.data.amount_requested,
            mosaicApplicantMessages: response.data.applicantMessages,
          }
          dispatch(openCreditDecisionDialog('mosaic', decisionDisplayProps))
        } else if (!response?.data?.success) {
          logAmplitudeEvent('integrated_finance_application_submit_error', {
            integration: 'mosaic',
            project_id: props.project?.id,
            payment_option_id: props?.paymentOptionId,
          })
          if (response?.data?.errorMsg) setErrorMsg(response.data.errorMsg)
          else setErrorMsg('We were unable to submit this credit application')
        }
      })
      .catch((err: any) => {
        if (err.body?.require_full_ssn) {
          setRequireFullSSN(true)
          setErrorMsg(err?.body?.message)
          logAmplitudeEvent('mosaic_ssn_flag_disabled', { project_id: props.project?.id })
        } else {
          logAmplitudeEvent('integrated_finance_application_submit_error', {
            integration: 'mosaic',
            project_id: props.project?.id,
            payment_option_id: props?.paymentOptionId,
          })
          if (err?.body?.message) {
            if (err.body.is_blocked) {
              setIsBlocked(true)
              setBlockedMsg(err.body.message)
              setErrorMsg(undefined)
              logAmplitudeEvent('credit_app_blocked', {
                integration: 'mosaic',
                project_id: props.project?.id,
                payment_option_id: props?.paymentOptionId,
              })
            } else {
              setErrorMsg(err?.body?.message)
            }
          } else {
            setErrorMsg('Something went wrong and we were unable to submit this credit application')
          }
        }
      })
      .finally(() => setLoading(false))
    try {
      logAmplitudeEvent('integrated_finance_application_submit_attempt', { integration: 'mosaic' })
    } catch (ex) {}
  }

  const unblock = () => {
    setBlockedMsg(undefined)
    setIsBlocked(false)
    logAmplitudeEvent('reapp_started_after_credit_block', {
      integration: 'mosaic',
      project_id: props.project?.id,
      payment_option_id: props?.paymentOptionId,
    })
  }

  const confirmBeforeClose = () => {
    if (window.confirm(translate('Are you sure you want to exit your application? All progress will be lost')))
      props.onClose()
  }

  const renderFormContent = () => {
    return (
      <>
        {loading && (
          <>
            <div
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundColor: '#ececec',
                opacity: 0.9,
                zIndex: 9000,
              }}
            />
            <div
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9001,
              }}
            >
              <LoadingDots text="Your application is being reviewed. Thank you for your patience" />
            </div>
          </>
        )}
        {isBlocked && (
          <>
            <div
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundColor: '#ececec',
                opacity: 0.95,
                zIndex: 9000,
              }}
            />
            <div
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9001,
                padding: '10%',
              }}
            >
              <h2 style={{ textAlign: 'center' }}>{translate('Mosaic was unable to make a credit decision')}</h2>
              <p>{blockedMsg}</p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '30px',
                }}
              >
                <div style={{ margin: '15px' }}>
                  <ProUXButton type="secondary" label="Dismiss and Re-Apply Later" onClick={props.onClose} />
                </div>
                <div style={{ margin: '15px' }}>
                  <ProUXButton type="primary" label="I'm ready to Re-Apply" onClick={unblock} />
                </div>
              </div>
            </div>
          </>
        )}
        <div style={{ paddingBottom: '250px' }}>
          <SimpleForm initialValues={getInitialValues()} toolbar={<div></div>}>
            <MosaicApplicationForm
              disclosures={props.disclosures}
              productType={props.productType}
              requireFullSSN={requireFullSSN}
            />
            {props.disclosures
              ?.filter((disc) => !['INCOME', 'OTHERINC', 'OWN', 'COAPP'].includes(disc.assetCode))
              ?.map((disc) => {
                return (
                  <div>
                    {disc.contents.map((cont, i) => (
                      <Markdown key={i} children={cont.data} linkTarget="_blank" />
                    ))}
                  </div>
                )
              })}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: '15px' }}>
              <img width="60" height="60" src={window.PUBLIC_URL + '/images/equal-housing-lender-logo.png'} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
              <div>
                <Checkbox checked={agreedToTerms} onChange={(e) => setAgreedToTerms(!agreedToTerms)} />
              </div>
              <div id="mosaic-terms">{translate('I agree to the Terms and Conditions listed above')}</div>
            </div>
            <MosaicApplicationDialogFooter
              submitApplication={submitApplication}
              loading={loading}
              onClose={confirmBeforeClose}
              errorMsg={errorMsg}
            />
          </SimpleForm>
        </div>
      </>
    )
  }

  return (
    <Dialog
      maxWidth={'md'}
      open={props.open}
      onClose={confirmBeforeClose}
      fullWidth={isMobile || props.isFullWidth}
      fullScreen={isMobile || props.isFullWidth}
    >
      <div style={{ textAlign: 'center' }}>
        <h1 style={{ fontSize: isMobile ? undefined : '24px' }}>
          {props.productType === 'Zero' ? (
            <h1 style={{ fontSize: isMobile ? undefined : '24px' }}>
              {translate('Apply for Financing Through Mosaic')}
            </h1>
          ) : (
            <h1 style={{ fontSize: isMobile ? undefined : '24px' }}>
              {translate('Apply for Financing Through Mosaic')}
            </h1>
          )}
        </h1>
      </div>
      <DialogContent dividers>{renderFormContent()}</DialogContent>
    </Dialog>
  )
}

export default MosaicApplicationDialog

import {
  DETECT_IMAGERY,
  DETECT_IMAGERY_FAILURE,
  DETECT_IMAGERY_LOADING,
  DETECT_IMAGERY_SUCCESS,
  PRELOAD_3D,
  storeAvailableImagery,
} from 'actions/designer'
import { imagerySelectors } from 'reducer/designer/detectImagery'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { Location4326 } from 'types/studio/structs'

export interface DetectImagerySideEffect {
  payload: {
    location4326: any
    country_iso2: string
    state: string
    address: string
    premiumImgIsAvailable: boolean
    preloadTerrainProvider: any
    is_lite: boolean
  }
}

export const getMapTypesAtLocation = (
  location4326: Location4326,
  country_iso2: string,
  state: string,
  address: string,
  is_lite: boolean,
  omitMapTypes: string[]
) => {
  return window.AccountHelper.getMapTypesAtLocation(location4326, country_iso2, state, address, is_lite, omitMapTypes)
}

const getImageryToPreload = function (availableMapTypes, preloadTerrainProvider) {
  var availableMapTypes3D = availableMapTypes.filter(
    (m) =>
      m.map_type === 'Nearmap3D' ||
      m.map_type === 'Google3D' ||
      m.map_type === 'GetMapping3D' ||
      m.map_type === 'GetMappingPremium3D'
  )

  var selectedMapType3D

  if (availableMapTypes3D && availableMapTypes3D.length) {
    if (preloadTerrainProvider === 'nearmap') {
      selectedMapType3D = availableMapTypes3D.filter((m) => m.map_type === 'Nearmap3D')[0]
    } else if (preloadTerrainProvider === 'getmapping') {
      // Including both GetMapping3D and GetMappingPremium3D
      selectedMapType3D = availableMapTypes3D.filter(
        (m) => m.map_type === 'GetMapping3D' || m.map_type === 'GetMappingPremium3D'
      )[0]
    } else {
      selectedMapType3D = availableMapTypes3D.filter((m) => m.map_type === 'Google3D')[0]
    }

    // if none found for preferred map type above, then just use first available 3d map type now
    if (!selectedMapType3D) {
      selectedMapType3D = availableMapTypes3D[0]
    }

    if (
      selectedMapType3D &&
      (selectedMapType3D.map_type === 'Google3D' ||
        selectedMapType3D.map_type === 'Nearmap3D' ||
        selectedMapType3D.map_type === 'GetMapping3D' ||
        selectedMapType3D.map_type === 'GetMappingPremium3D')
    ) {
      // Only preload DSM & Ortho for Google, not Nearmap

      // Need to remove the querystring for google to avoid errors
      return {
        dsm: selectedMapType3D.variation_data.dsm,
        ortho: selectedMapType3D.variation_data.ortho,
      }
    }
  }
}

export function* handleDetectImagery({ payload }: DetectImagerySideEffect) {
  const { location4326, country_iso2, state, address, premiumImgIsAvailable, preloadTerrainProvider, is_lite } =
    payload || {}

  var identifier = 'cachedGetMapTypesAtLocationRequest'

  try {
    if (location4326[0] && location4326[1]) {
      yield put({
        type: DETECT_IMAGERY_LOADING,
        payload: {
          cacheKey: window.AccountHelper.terrainUrlsCache.key(
            identifier,
            location4326,
            country_iso2,
            state,
            premiumImgIsAvailable
          ),
          preloadTerrainProvider,
        },
      })

      const blockedMapTypes = yield select(imagerySelectors.getBlockedMapTypes)

      const response = yield call(
        getMapTypesAtLocation,
        location4326,
        country_iso2,
        state,
        address,
        is_lite,
        blockedMapTypes
      )

      var availableMapTypes, timezoneOffset

      if (!response) {
        throw new Error('No response from call!')
      }

      // @TODO: Why is the response format sometimes different? We handle both versions here
      // since we have not standardized them yet.
      if (response.payload) {
        availableMapTypes = response.payload.availableMapTypes
        timezoneOffset = response.payload.timezoneOffset
      } else {
        availableMapTypes = response[0]
        timezoneOffset = response[1]
      }

      if (!availableMapTypes || availableMapTypes.length === 0) {
        throw new Error('No imagery found')
      }

      storeAvailableImagery(availableMapTypes, timezoneOffset)

      yield put({
        type: DETECT_IMAGERY_SUCCESS,
        payload: {
          availableMapTypes: availableMapTypes,
          timezoneOffset: timezoneOffset,
        },
      })

      if (preloadTerrainProvider) {
        var preloadData = getImageryToPreload(availableMapTypes, preloadTerrainProvider)
        if (preloadData) {
          yield put({
            type: PRELOAD_3D,
            payload: preloadData,
          })
        }
      }
    }
  } catch (e) {
    console.error(e)
    yield put({ type: DETECT_IMAGERY_FAILURE, payload: { detail: String(e) } })
  } finally {
    //
  }
}

export function* watchDetectImagery() {
  //@ts-ignore
  yield takeLatest(DETECT_IMAGERY, handleDetectImagery)
}

import { CircularProgress, makeStyles } from '@material-ui/core'
import { triggerSDKTokenRefresh } from 'ducks/projectMilestones'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PaymentExtraFields, PaymentRequestType, PaymentStaticCopy } from '../types'
import { useGetBlueSnapToken } from '../utils'

type PropTypes = {
  paymentRequestData: PaymentRequestType
  projectId: string
  countryIso2: string
  orgName: string
  paymentStaticCopy: PaymentStaticCopy
  showGooglePay?: boolean
  showApplePay?: boolean | null
  doSubmitPayment: (args: PaymentExtraFields) => void
}

const useStyles = makeStyles({
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    width: '133px',
    height: '50px',
    borderRadius: '8px',
  },
})

const PaymentRequestButton: React.FC<PropTypes> = (props) => {
  // bluesnap needs these to be divs, the SDK then injects input fields alongside these divs
  // all styling must be done in the initBlueSnapCreditCardForm hook and is subject to the restrictions
  // https://developers.bluesnap.com/v8976-Tools/reference/payment-request-button

  const classes = useStyles()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { token } = useGetBlueSnapToken(
    props.projectId,
    props.paymentRequestData.payment_request_id,
    props.paymentRequestData?.org_id
  )

  useEffect(() => {
    if (token) {
      const sdkRequest = {
        token: token,
        googlePay: props.showGooglePay,
        applePay: props.showApplePay,
        paymentData: {
          currencyCode: props.paymentRequestData.currency,
          countryCode: props.countryIso2,
          total: {
            label: 'My Total',
            amount: parseFloat(props.paymentRequestData.payment_amount.toString()).toFixed(2),
          },
          theme: 'dark',
          emailRequired: true,
          phoneRequired: true,
          fullBilling: true,
          shippingRequired: false,
        },
        onEvent: {
          paymentAuthorized: async function (success, error) {
            const paymentData = { token: token }
            try {
              props.doSubmitPayment({ payment_method_data: paymentData, payment_method_type: 'apple_google_pay' })
              success()
            } catch (err) {
              error('Transaction failed')
            }
          },
          error: function (event) {
            console.error('Payment Error', event)
          },
          warning: function (event) {
            console.warn('Payment Warning', event)
          },
        },
      }

      // @ts-ignore
      window.bluesnap.walletButtonSetup(sdkRequest)
      setIsLoading(false)
    }
  }, [token])

  useEffect(() => {
    dispatch(triggerSDKTokenRefresh())
  }, [])

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div data-bluesnap="walletButton" className={classes.buttonWrapper}>
        <span>{isLoading ? <CircularProgress /> : ''}</span>
      </div>
    </div>
  )
}
export default PaymentRequestButton

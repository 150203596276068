import { parseSchedules } from 'elements/scheduleDialog/utils'
import React, { useContext } from 'react'
import { useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { urlToId } from 'util/misc'
import { UtilityTariffDialogContext } from '.'
import { permissionsSelectors } from '../../../../ducks/permissions'
import ClearButton from './ClearButton'
import SelectNewTariffButton from './SelectNewTariffButton'
import TariffSummaryDialog from './TariffSummaryDialog'
import UtilityTariffLink from './UtilityTariffLink'
import { useStyles } from './UtilityTariffProposedContent'
import { useCurrentTariffData } from './hooks'
import TariffDialogCreateButton from './selectTariffDialog/TariffDialogCreateButton'

const CurrentTariff = ({ tariffData, disabled }) => {
  const tariffType = tariffData.tariffDataType
  const classes = useStyles()
  const translate = useTranslate()
  const { state, dispatch } = useContext(UtilityTariffDialogContext)
  const formState = useFormState()
  const formValues = formState.values
  const name = tariffData?.name || 'Customised Utility Tariff'

  // customised utility tariff
  if (tariffType === 'custom') {
    return (
      <div className={classes.column}>
        {disabled ? (
          name
        ) : (
          <a
            id="view-current-custom-tariff-link"
            href="#"
            onClick={(e) => {
              e.preventDefault()
              dispatch({
                type: 'edit',
                payload: {
                  isOpen: true,
                  variant: 'current',
                  basePath: '/custom_tariff_current/' + formValues.id,
                  resource: 'custom_tariff_current',
                  mode: 'edit',
                  id: formValues.id,
                },
              })
            }}
          >
            {name}
          </a>
        )}
        <div className="small">({translate('Customised for this project')})</div>
      </div>
    )
    // selected utility tariff from database
  } else if (tariffType === 'current') {
    return (
      <UtilityTariffLink
        basePath={'/utility_tariffs'}
        tariffId={tariffData.id}
        code={tariffData.code}
        variant={'current'}
        resource={'utility_tariffs'}
        disabled={disabled}
      />
    )
    // guess utility tariff
  } else {
    if (tariffData) {
      return (
        <div className={classes.column}>
          <UtilityTariffLink
            basePath={'/utility_tariffs'}
            tariffId={tariffData.id}
            code={tariffData.code}
            variant={'current'}
            resource={'utility_tariffs'}
            disabled={disabled}
          />
          <div className="small">({translate('Automatically applied based on project location')})</div>
        </div>
      )
    } else {
      return <span>{translate('Not set')}</span>
    }
  }
}

const UtilityTariffCurrentContent = () => {
  const translate = useTranslate()
  const formState = useFormState()
  const classes = useStyles()
  const formValues = formState.values
  const showClearButton = formValues?.utility_tariff_current_data || formValues?.utility_tariff_current_custom
  const countryId = urlToId(formValues.country)
  const tariffData = useCurrentTariffData(formValues)
  const { allowEdit, allowCreate, allowDelete } = useSelector(
    permissionsSelectors.getProjectPermissionByKey('energy_tariff')
  )
  const disabled = !allowEdit

  return (
    <div className={classes.tariffContainer}>
      <div className={classes.row}>
        <p className={classes.tariffLabel}>
          {translate('Current Tariff')}
          <span className={classes.subText}>{translate('What the Customer is on right now')}</span>
        </p>
        <div className={`${classes.row} ${classes.spanRight}`}>
          {allowEdit && <SelectNewTariffButton countryId={countryId} currentOrProposed="current" />}
          {allowCreate && <TariffDialogCreateButton currentOrProposed="current" context="energy_page" />}
        </div>
      </div>
      <div className={classes.inputsContainer}>
        <div className={classes.row}>
          <p>{translate('Current Tariff')}:</p>
          <div className={`${classes.input} ${classes.row}`}>
            <>
              <TariffSummaryDialog tariffData={parseSchedules(tariffData.data || tariffData)} />
              <CurrentTariff tariffData={tariffData} disabled={disabled} />
            </>
            {allowDelete && showClearButton && <ClearButton variant="current" />}
          </div>
        </div>
        {/* <div className={classes.row}>
          <p>{translate('Valid From')}:</p>
          <div className={classes.input}>
            <SelectInput
              variant="outlined"
              label=""
              name={'valid_from'}
              resource={'projects'}
              onChange={() => {}}
              optionText={'title'}
              optionValue={'value'}
              choices={[]}
              source={'valid_from'}
            />
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default UtilityTariffCurrentContent

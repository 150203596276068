import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { LineItemLoadedDataType } from 'pages/ordering/type'
import { useLayoutEffect, useState } from 'react'
import { useDataProvider } from 'react-admin'
import { useSelector } from 'react-redux'
import { getSupplierConfig } from '../configs'

const useOrderComponentDetail = ({
  componentCode,
}: {
  componentCode: string
}): {
  detailData: LineItemLoadedDataType | undefined
  loading: boolean
} => {
  const [loading, setLoading] = useState(true)
  const [isReady, setIsReady] = useState(false)
  const [detailData, setDetailData] = useState<LineItemLoadedDataType | undefined>(undefined)
  const dataProvider = useDataProvider()
  const orgCountry = useSelector(authSelectors.getCurrentOrgCountry)
  const orgId = useSelector(authSelectors.getOrgId)
  const hardwareSupplier = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const supplierConfig = getSupplierConfig(hardwareSupplier)
  const hardwareSupplierCode = supplierConfig?.filterKey

  useLayoutEffect(() => {
    if (orgCountry !== null && orgId !== null) {
      setIsReady(true)
    }
  }, [orgCountry, orgId, hardwareSupplier])

  useLayoutEffect(() => {
    if (!isReady) {
      return
    }
    const onSuccess = ({ data }: { data: LineItemLoadedDataType }) => {
      setDetailData(data)
    }
    const onFailed = () => {}
    let url = `components/${componentCode}/?catalog=full&country_iso2=${orgCountry}&org_id=${orgId}`
    if (hardwareSupplierCode) url += `&require_distributor=${hardwareSupplierCode}`

    setLoading(true)
    dataProvider
      .CUSTOM_GET(
        'components',
        {
          url,
        },
        { onSuccess }
      )
      .catch(onFailed)
      .finally(() => {
        setLoading(false)
      })
  }, [componentCode, isReady, hardwareSupplierCode])

  return { detailData, loading }
}

export default useOrderComponentDetail

const parseProjectDatesInitialValues = (formValsOnSave: any, returnedVals: any = {}) => {
  const newInitialValues: any = formValsOnSave

  const dateFields = ['contract_date', 'installation_date', 'identifier']
  dateFields.forEach(dateField => {
    if (returnedVals.hasOwnProperty(dateField)) {
      newInitialValues[dateField] = returnedVals[dateField]
    }
  })
  return newInitialValues
}

export default parseProjectDatesInitialValues

import React from 'react'
import { SelectArrayInput } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type PropTypes = {
  paymentIndex: number
  paymentMethods: { id: string; name: string }[]
}

const useStyles = makeOpenSolarStyles((theme) => ({
  fieldRow: {
    margin: '5px 0',
  },
}))

const AcceptedPaymentMethodsField: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  return (
    <SelectArrayInput
      label="Accepted Payment Methods"
      source={`payment_milestone_configurations[${props.paymentIndex}].accepted_payment_methods`}
      choices={props.paymentMethods}
      fullWidth={true}
      className={classes.fieldRow}
    />
  )
}
export default AcceptedPaymentMethodsField

import { DropdownInput, IntegrationData } from 'types/mounting'
import { PossibleInputVariables, ProductCode, RoofTypeName } from './types'

export const findDropdownInput = (allItems: DropdownInput[], varName: PossibleInputVariables): DropdownInput => {
  return allItems.filter((item) => item.variableName === varName)[0]
}

export type RoofTypeCompatibility = Record<ProductCode, Array<RoofTypeName>>
type RailCompatibility = Record<ProductCode, Array<ProductCode>>

export type ComptibilityRules = {
  [key in PossibleInputVariables]?: {
    roofTypeCompatibility?: RoofTypeCompatibility
    railCompatibility?: RailCompatibility
    profileCompatibility?: Record<string, Array<string>>
    roofHookMaterialCompatibility?: Record<string, Array<string>>
  }
}

export type FilterCriteria = {
  filterType: string
  filterValue: string // Can either be the filter value itself or its path relative to integration data, in the form "#/var"
}

//returns a filtered down version of the dopdownInput and updates the default to the first matching, if no compatibility it throws an error
export const filterOptions = (
  allInputs: DropdownInput[],
  rules: ComptibilityRules,
  criteria: FilterCriteria[],
  integrationData: IntegrationData
) => {
  const filteredInputs = allInputs.map((dropdownInput) => {
    if (!rules.hasOwnProperty(dropdownInput.variableName)) return dropdownInput

    const dropdownInputRules = rules?.[dropdownInput.variableName] as RoofTypeCompatibility | RailCompatibility

    criteria.forEach((filterCriteria) => {
      if (!dropdownInputRules.hasOwnProperty(filterCriteria.filterType)) return
      if (!filterCriteria.filterValue) return
      if (filterCriteria.filterValue.toString()[0] === '#') {
        let ref = filterCriteria.filterValue.toString().slice(2)
        filterCriteria.filterValue = integrationData[ref]
        if (!filterCriteria.filterValue) return
      }

      const inputOptionRules = dropdownInputRules?.[filterCriteria.filterType]

      if (dropdownInput.options.length > Object.keys(inputOptionRules).length) {
        throw new Error(`
            Compatibility settings for ${dropdownInput.label} incomplete. Received ${
          dropdownInput.options.length
        } product options but only ${Object.keys(inputOptionRules).length} rules were found`)
      }

      const checkIfTypeIncluded = (dropdownInputOptionValue: ProductCode, filterValue: string) => {
        const rulesForProduct = inputOptionRules?.[dropdownInputOptionValue] as Array<string>
        const result = rulesForProduct.includes(filterValue)
        return result
      }

      const filteredOptions = dropdownInput.options.filter((option) =>
        checkIfTypeIncluded(option.value, filterCriteria.filterValue)
      )

      if (filteredOptions.length === 0) {
        console.warn(`No compatible options found for ${dropdownInput.variableName} with ${filterCriteria.filterType}`)
        return null
      }

      dropdownInput = { ...dropdownInput, options: filteredOptions, default: filteredOptions[0].value }
    })
    return dropdownInput
  })

  const filteredInputsWithoutNull = filteredInputs.filter((a) => a != null)

  return filteredInputsWithoutNull
}

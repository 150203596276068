import { Theme, useMediaQuery } from '@material-ui/core'
import RateReviewIcon from '@material-ui/icons/RateReviewOutlined'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { SystemDesignSunlightQuickCreateType } from 'resources/integrations/sunlight/types'
import { ProjectType } from 'types/projects'
import AuFinanceCtaDialog from './AuFinanceCtaDialog'

type PropTypes = {
  project: ProjectType
  designs?: SystemDesignSunlightQuickCreateType[]
}

const AuInitiateFinanceAppWrapper: React.FunctionComponent<PropTypes> = (props) => {
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const translate = useTranslate()
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 967))
  const isXxs = useMediaQuery((theme: Theme) => theme.breakpoints.down(332))
  const onButtonClick = () => {
    setShowDialog(!showDialog)
  }
  return (
    <div
      style={{
        width: '100%',
        marginTop: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
          marginBottom: '10px',
        }}
      >
        <RateReviewIcon htmlColor="rgba(0, 0, 0, 0.8)" />
        <span style={{ fontSize: '16px', marginLeft: '10px' }}>{translate('Finance Application')}</span>
      </div>
      <p>{translate('You can use OpenSolar to faciliate the application process for your customer')}</p>
      <ProUXButton
        label={'Initiate Finance Application'}
        onClick={onButtonClick}
        type="secondary"
        backgroundColor="#fff"
        fullWidth
        fullHeight
        isMarginTop={isMd || isXxs ? true : false}
        labelStyle={{
          display: 'flex',
          flexDirection: isMd || isXxs ? 'column' : 'row',
          alignItems: isMd || isXxs ? 'flex-start' : 'end',
        }}
        justifyContent="flex-start"
      />
      {showDialog && (
        <AuFinanceCtaDialog
          onClose={() => setShowDialog(false)}
          open={showDialog}
          project={props.project}
          designs={props.designs}
        />
      )}
    </div>
  )
}
export default AuInitiateFinanceAppWrapper

import { HelpLink } from 'elements/link/HelpLink'
import React from 'react'
import { useTranslate } from 'react-admin'
import { TagCreate, TagEdit, TagList } from './Tags'

const TagTitle = ({ record, translate, style }) => {
  return (
    <span style={style}>
      {translate('Project Tag')}: {record ? `"${record.title}"` : ''}
    </span>
  )
}

const Subtitle = () => {
  const translate = useTranslate()
  return (
    <span>
      {translate(
        'Create your own Project Tags that you can apply to projects to enable easy filtering and sorting. For example: "Family and Friends"'
      )}
      .
      <HelpLink href="https://support.opensolar.com/hc/en-us/articles/4407118190233" />
    </span>
  )
}

export default {
  list: TagList,
  edit: TagEdit,
  create: TagCreate,
  options: {
    create: {
      title: 'Create Tag',
      subtitle: null,
      breadCrumb: 'Create Tag',
    },
    list: {
      title: 'Project Tags',
      subtitle: <Subtitle />,
      breadCrumb: 'Project Tags',
    },
    edit: {
      title: <TagTitle />,
      subtitle: null,
      breadCrumb: 'Edit Tag',
    },
  },
}

import { Dialog, DialogActions, DialogContent, makeStyles, withStyles } from '@material-ui/core'
import { useUxVersion } from 'hooks/useUxVersion'
import { Button } from 'opensolar-ui'
import ConfirmModal from 'projectSections/sections/manage3/common/ConfirmModal'
import { clearUnsavedData } from 'projectSections/utils/unsavedDataStore'
import { FC, useCallback, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { reloadEntireApp } from 'util/misc'
import useRerender from '../../../hooks/useRerender'
// import {Button} from'elements/button/Button'
const useStyles = makeStyles({
  submitting: { margin: '0 5px', textDecoration: 'underline', cursor: 'not-allowed', color: 'rgba(0, 0, 0, 0.26)' },
  button: { margin: 'auto 5px', textDecoration: 'underline', cursor: 'pointer' },
  icon: { verticalAlign: 'middle', width: 16 },
  restyle: {
    margin: '8px !important',
    fontSize: '13px !important',
  },
})

const StyledButton = withStyles({
  root: {
    color: '#1976d2',
    margin: '0 5px',
  },
})(Button)

type DialogPropsType = {
  isOpen: boolean
  setOpen: (boolean) => void
}

const ConfirmDialog: FC<DialogPropsType> = ({ isOpen, setOpen }) => {
  const form = useForm()
  const translate = useTranslate()
  const rerender = useRerender()
  const projectId = useSelector((state: any) => state.projectId)
  const history = useHistory()
  const ui3Enabled = useUxVersion() === 3
  const handleConfirm = useCallback(() => {
    clearUnsavedData()
    if (projectId === 'new') {
      history.push('/projects')
    } else {
      const dirtyFields = form.mutators.getFormDirtyFields()
      if (Object.values(dirtyFields).includes('design')) {
        if (window.RUNNING_AS_APP === true) {
          history.push('/projects')
        } else {
          reloadEntireApp()
        }
      } else {
        rerender(form)
        //workaround to restart form with keepDirtyOnReinitialize set to true
        //more detail see:https://github.com/final-form/final-form/issues/151
        form.setConfig('keepDirtyOnReinitialize', false)
        form.restart()
        form.setConfig('keepDirtyOnReinitialize', true)
        form.mutators.resetFormDirtyFields()
      }
    }
  }, [])
  if (ui3Enabled)
    return (
      <ConfirmModal
        open={isOpen}
        setOpen={setOpen}
        handleSubmit={handleConfirm}
        title={translate('Discard Changes')}
        mainText={translate('Are you sure you want to discard changes?')}
        subText={translate('You will lose all your unapplied changes. Do you wish to continue?')}
        submitBtnLabel={translate('Yes, discard')}
        submitBtnProps={{ variant: 'contained', color: 'error' }}
      />
    )
  return (
    <Dialog
      open={!!isOpen}
      // keepMounted
      onClose={() => setOpen(false)}
    >
      <DialogContent>
        <h2 style={{ fontSize: 20, fontWeight: 400 }}>{translate('Discard unsaved changes?')}</h2>
        <p>{translate('You will lose all your unapplied changes. Do you wish to continue?')}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} variant="contained" color="default">
          <span>{translate('Cancel')}</span>
        </Button>
        <Button onClick={() => form.mutators.discardChanges(form)} variant="contained" color="primary">
          <span>{translate('Confirm')}</span>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const DiscardButton = (props: { layout?: number; submitting?: boolean; size?: 'small' | 'medium' | 'large' }) => {
  const [isOpen, setOpen] = useState(false)
  const form = useForm()
  const classes = useStyles()
  const size = props.size ?? 'small'
  const layout = props.layout ?? 3
  const translate = useTranslate()
  const submitting = props.hasOwnProperty('submitting') ? props.submitting : form.getState().submitting
  const ui3Enabled = useUxVersion() === 3
  const handleClick = useCallback(() => {
    const dirtyFields = form.mutators.getFormDirtyFields()
    if (Object.values(dirtyFields).includes('design')) {
      if (window.RUNNING_AS_APP === true) {
        setOpen(true)
      } else {
        //reload page if design is dirty
        reloadEntireApp()
      }
    } else {
      setOpen(true)
    }
  }, [])

  return (
    <>
      <Button
        className={ui3Enabled ? classes.restyle : undefined}
        style={!ui3Enabled ? { margin: '10px 0', fontSize: layout <= 3 ? 12 : 14 } : undefined}
        variant={ui3Enabled ? 'outlined' : 'contained'}
        size={size}
        disabled={submitting}
        onClick={submitting ? undefined : handleClick}
      >
        {translate('Discard')}
      </Button>

      <ConfirmDialog isOpen={isOpen} setOpen={setOpen} />
    </>
  )
}

export default DiscardButton

import { makeStyles } from '@material-ui/core'
import React, { FC, ReactNode } from 'react'
import { Theme } from 'types/themes'
import { Severities, variantIcon } from './notificationVariants'

type Margins = 'normal' | 'dense'

type PropsType = {
  styles?: any
  severity: Severities
  options?: any
  actions?: ReactNode
  classes?: AlertClasses
  margin?: Margins
}

export type AlertClasses = {
  wrapper?: string
  icon?: string
  iconContainer?: string
  layout?: string
  actions?: string
}

const defaultSeverity = 'warning'

const useStyles = makeStyles<Theme, { severity: Severities; margin: Margins }>(
  (theme) => ({
    wrapper: ({ severity, margin }) => ({
      fontSize: 12,
      background: theme['alertBg_' + severity],
      margin: '5px 0px',
      padding: margin === 'dense' ? '7px 10px' : 16,
      paddingLeft: margin === 'dense' ? 6 : 10,
      lineHeight: '16px',
      boxSizing: 'border-box',
      color: theme['alert_' + severity],
      borderRadius: 4,
    }),
    layout: {
      display: 'flex',
      alignItems: 'start',
    },
    icon: ({ severity }) => ({
      width: 22,
      height: 22,
      display: 'flex',
      color: theme['alertIcon_' + severity],
      marginRight: 10,
    }),
    actions: {
      width: '100%',
      textAlign: 'right',
      paddingTop: 8,
    },
    children: {
      paddingTop: 4,
      width: '100%',
    },
  }),
  { name: 'Alert' }
)

const Alert: FC<PropsType> = ({ severity, options, children, styles, classes, actions, margin = 'normal' }) => {
  const innerClasses = useStyles({ severity, margin })
  const Icon = variantIcon[severity] || variantIcon[defaultSeverity]
  return (
    <div className={innerClasses.wrapper + ' ' + classes?.wrapper} style={styles} {...options}>
      <div className={innerClasses.layout + ' ' + classes?.layout}>
        {React.createElement(Icon, { className: innerClasses.icon + ' ' + classes?.icon })}
        <div className={innerClasses.children}>{children}</div>
      </div>
      {/* TODO: Change how actions are wrapped */}
      {actions && <div className={innerClasses.actions + ' ' + classes?.actions}>{actions}</div>}
    </div>
  )
}

export default Alert

import { terminateCurrentTour, updateTourStep } from 'actions/tourActions'
import { removeTourBanner } from 'ducks/auth'
import TourRide from 'persistentContent/tours/tools/TourRide'
import { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MANAGE_FUJI_TOUR_STEPS } from './steps'
interface PropTypes {
  toggleEndDialog: () => void
}
const ManageFujiTour: FC<PropTypes> = ({ toggleEndDialog }) => {
  const dispatch = useDispatch()
  const { tour, step } = useSelector((state: any) => state.tour)

  const stepIndex = useMemo(() => {
    const findStep = MANAGE_FUJI_TOUR_STEPS.find((x) => x.step === step)
    if (findStep) return MANAGE_FUJI_TOUR_STEPS.indexOf(findStep)
    return 0
  }, [step])

  const handleNext = () => {
    let nextStepIndex = stepIndex + 1
    let element: HTMLElement | null = null

    while (nextStepIndex < MANAGE_FUJI_TOUR_STEPS.length && !element) {
      const configs = MANAGE_FUJI_TOUR_STEPS[nextStepIndex].config
      const spotlights: any = configs.spotlights
      const targetId = spotlights[0].targetId
      element = document.getElementById(targetId)
      if (!element) {
        nextStepIndex += 1
      }
    }
    if (element && nextStepIndex < MANAGE_FUJI_TOUR_STEPS.length) {
      const nextStep = MANAGE_FUJI_TOUR_STEPS[nextStepIndex].step
      if (nextStep === 'history') {
        openDrawer(nextStepIndex)
        dispatch(updateTourStep(tour, nextStep))
      } else if (nextStep === 'workflows') {
        closeDrawer(nextStepIndex - 1)
        dispatch(updateTourStep(tour, nextStep))
      } else {
        dispatch(updateTourStep(tour, nextStep))
      }
    } else if (stepIndex + 1 === MANAGE_FUJI_TOUR_STEPS.length) {
      dispatch(terminateCurrentTour(tour))
      toggleEndDialog()
    }
  }

  const handlePrev = () => {
    let prevStepIndex = stepIndex - 1
    let element: HTMLElement | null = null

    while (prevStepIndex >= 0 && !element) {
      const configs = MANAGE_FUJI_TOUR_STEPS[prevStepIndex].config
      const spotlights: any = configs.spotlights
      const targetId = spotlights[0].targetId
      element = document.getElementById(targetId)
      if (!element) {
        prevStepIndex -= 1
      }
    }

    if (element && prevStepIndex >= 0) {
      const prevStep = MANAGE_FUJI_TOUR_STEPS[prevStepIndex].step
      if (prevStep === 'history') {
        openDrawer(prevStepIndex)
        dispatch(updateTourStep(tour, prevStep))
      } else if (prevStep === 'details') {
        closeDrawer(prevStepIndex + 1)
        dispatch(updateTourStep(tour, prevStep))
      } else {
        dispatch(updateTourStep(tour, prevStep))
      }
    }
  }

  function openDrawer(index) {
    const configs = MANAGE_FUJI_TOUR_STEPS[index].config
    const spotlights: any = configs.spotlights
    const targetId = spotlights[0].targetId
    const id = targetId.split('-Inner')[0]
    const drawer = document.getElementById(id) as HTMLElement
    const drawerF = drawer?.firstElementChild as HTMLElement
    if (drawer && drawerF) {
      drawerF.style.visibility = 'visible'
      drawerF.style.transform = 'translateX(0)'
    }
  }

  function closeDrawer(index) {
    const configs = MANAGE_FUJI_TOUR_STEPS[index].config
    const spotlights: any = configs.spotlights
    const targetId = spotlights[0].targetId
    const id = targetId.split('-Inner')[0]
    const drawer = document.getElementById(id) as HTMLElement
    const drawerF = drawer?.firstElementChild as HTMLElement
    if (drawer && drawerF) {
      drawerF.style.visibility = 'hidden'
    }
  }

  const handleCancel = () => {
    if (MANAGE_FUJI_TOUR_STEPS[stepIndex].step === 'history') {
      closeDrawer(stepIndex)
    }
    dispatch(removeTourBanner(true, 'manage'))
    dispatch(terminateCurrentTour(tour))
  }

  return (
    <TourRide
      steps={MANAGE_FUJI_TOUR_STEPS}
      stepIndex={stepIndex}
      visible={tour === 'fuji_manage'}
      handleNext={handleNext}
      handlePrev={stepIndex === 0 ? undefined : handlePrev}
      handleNextLabel={stepIndex + 1 === MANAGE_FUJI_TOUR_STEPS.length ? 'Finish' : 'Next'}
      handleCancel={handleCancel}
    />
  )
}

export default ManageFujiTour

import { DialogActions, DialogContent, TextField } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import Alert from 'elements/Alert'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import { inputValueToFloat } from 'projectSections/sections/design/systems/PanelSystem'
import React, { useCallback, useEffect, useState } from 'react'
import { CommonPromptPropTypes } from './types'

const SungageBatteryInputPrompt: React.FC<CommonPromptPropTypes> = (props) => {
  const [nonsolarValue, setNonsolarValue] = React.useState<number | undefined>(undefined)
  const [batteryValue, setBatteryValue] = useState<number | undefined>(undefined)

  //pause event listener when dialog is open, resume them when dialog closes
  useEffect(() => {
    window.editor.pauseDesignEventListener = true
    return () => {
      window.editor.pauseDesignEventListener = false
    }
  }, [])

  useEffect(() => {
    if (props.systemUuid) {
      const system = window.editor.getSystems()?.find((sys) => sys.uuid === props.systemUuid)
      if (system) {
        setBatteryValue(system.lender_battery_price_override)
        setNonsolarValue(system.non_solar_price_included)
      }
    }
  }, [props.systemUuid])

  const handleObjectChangedAsCommand = useCallback((uuid, attributeName, newValue) => {
    // @ts-ignore
    if (uuid && !isNaN(newValue) && !window.editor.changingHistory) {
      var object = window.editor.objectByUuid(uuid)
      if (object) {
        window.editor.execute(new window.SetValueCommand(object, attributeName, newValue, undefined, true))
      }
    }
  }, [])

  return (
    <>
      <DialogContent>
        <div>
          <Alert severity="warning">{props.message}</Alert>
          <p>
            If you would like to manually override the roof and/or battery price for this system you may do so below.
          </p>
          <Alert severity="info">
            Editing these values will <strong>not</strong> impact the system price or cost in OpenSolar - it is the
            equivalent of editing the roof or battery price in Sungage’s portal and is only used for validation.
          </Alert>
          <div style={{ width: '100%', marginTop: '20px' }}>
            <TextField
              type="number"
              label="Sungage Total Battery Price Override"
              name="Sungage Total Battery Price Override"
              value={batteryValue}
              style={{ width: '100%' }}
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              onChange={(event) => {
                const value = event.target.value
                var valueAsFloat = inputValueToFloat(value)
                setBatteryValue(valueAsFloat)
                if (!isNaN(valueAsFloat)) {
                  handleObjectChangedAsCommand(props.systemUuid, 'lender_battery_price_override', valueAsFloat)
                }
              }}
            />
          </div>
          <div style={{ width: '100%', marginTop: '20px' }}>
            <TextField
              type="number"
              label="Sungage Roof Price"
              name="Sungage Roof Price"
              value={nonsolarValue}
              style={{ width: '100%' }}
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              onChange={(event) => {
                const value = event.target.value
                var valueAsFloat = inputValueToFloat(value)
                setNonsolarValue(valueAsFloat)
                if (!isNaN(valueAsFloat)) {
                  handleObjectChangedAsCommand(props.systemUuid, 'non_solar_price_included', valueAsFloat)
                }
              }}
            />
            <span className="small">This is the same as the "Non-Solar Price Included" field in the pricing tab</span>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <div style={{ margin: 20 }}>
            <ProUXButton type="primary" label="Continue" onClick={props.onClose} />
          </div>
        </div>
      </DialogActions>
    </>
  )
}
export default SungageBatteryInputPrompt

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOsHost = exports.detectEnvFromHost = exports.getOsUrl = void 0;
function getOsUrl(type, env) {
    var host = getOsHost(type, env);
    if (!host)
        return undefined;
    if (host.includes('localhost')) {
        return "http://".concat(host);
    }
    else {
        return "https://".concat(host);
    }
}
exports.getOsUrl = getOsUrl;
var knownEnvs = ['local', 'sunrise', 'dev', 'dock', 'sdkqa', 'staging', 'production'];
// Used by the SDK script to detect where it is hosted
function detectEnvFromHost(domain, host) {
    for (var _i = 0, knownEnvs_1 = knownEnvs; _i < knownEnvs_1.length; _i++) {
        var env = knownEnvs_1[_i];
        if (host === getOsHost(domain, env))
            return env;
    }
}
exports.detectEnvFromHost = detectEnvFromHost;
function getOsHost(type, env) {
    switch (type) {
        case 'spa':
            switch (env) {
                case 'local':
                    return 'localhost:3000';
                case 'sunrise':
                    return 'localhost:31000';
                case 'dev':
                    return 'dev.sunsuite.com.au';
                case 'dock':
                    return 'dock.sunsuite.com.au';
                case 'sdkqa':
                    return 'sdkqa.sunsuite.com.au';
                case 'staging':
                    return 'staging4.opensolar.com';
                case 'production':
                    return 'app.opensolar.com';
            }
            break;
        case 'api':
            switch (env) {
                case 'local':
                    return 'localhost:8000';
                case 'sunrise':
                    return 'localhost:30000';
                case 'dev':
                    return 'apidev.sunsuite.com.au';
                case 'dock':
                    // Note this is non-standard! stage is "dock2" but environment is still "dock"
                    return 'apidock2.sunsuite.com.au';
                case 'sdkqa':
                    return 'apisdkqa-docker.sunsuite.com.au';
                case 'staging':
                    return 'apistaging4.opensolar.com';
                case 'production':
                    return 'api.opensolar.com';
            }
            break;
    }
}
exports.getOsHost = getOsHost;

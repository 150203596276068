import { Chip } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { UiKey } from 'elements/UiKey'
import { LOANPAL_OR_GOODLEAP } from 'myenergy/selectionComponent/loanApplicationButton/loanpal/constants'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import React, { FC } from 'react'
import { FormDataConsumer, TextInput, useNotify, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { reloadEntireApp } from 'util/misc'
import BrighteButtonWrapper from '../BrighteButtonWrapper'
import DividendButtonWrapper from '../DividendButtonWrapper'
import GenericFinanceRoleConnectionsWrapper from '../GenericFinanceRoleConnectionsWrapper'
import IntegrationRoleConnectionButtons from '../IntegrationRoleConnectionButtons'

type Props = {
  id?: number
  disabled?: boolean
}

const uiKey = 'control.company.team.fields'
const inputStyle = { width: 256 }
const restClientInstance = restClient(window.API_ROOT + '/api')

const RoleIntegrationsContent: FC<Props> = ({ id, disabled }) => {
  const notify = useNotify()
  const translate = useTranslate()

  const orgId = useSelector(authSelectors.getOrgId)
  const orgHasPhoenix = useSelector(authSelectors.getPhoenixConnected)
  const availableFinanceIntegrations = useSelector(orgSelectors.getAvailableFinanceIntegrations)
  const org = useSelector(orgSelectors.getOrg)
  const show_ironridge = useSelector(authSelectors.getCurrentRole)?.ironridge_email
  const show_loanpal = useSelector(authSelectors.getLoanpalConnected)
  const show_mosaic = useSelector(authSelectors.getMosaicConnected)
  const show_sungage = useSelector(authSelectors.getSungageConnected)
  const brighte_connected_to_org = useSelector(authSelectors.getBrighteConnected)
  const dividend_connected_to_org = useSelector(authSelectors.getDividendConnected)

  const disconnectBrighte = () => {
    const confirmed = window.confirm(
      'Warning, you are about to Disconnect the Brighte account for this user. Doing so will break the Brighte integration for any projects that are assigned to this user.'
    )
    if (confirmed) {
      restClientInstance('CUSTOM_PUT', 'custom', {
        url: 'orgs/' + orgId + '/roles/' + id + '/disconnect_brighte_role/',
      })
        .then((response) => {
          if (response.data?.success) {
            notify('Your user has been disconnected from Brighte', 'success')
            setTimeout(reloadEntireApp, 1500)
          }
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
  }

  const inputProps = { id: id + '', resource: 'roles' }

  if (!id) return <></>

  return (
    <div>
      <UiKey uiKey={uiKey + '.meetings'}>
        <>
          <h2>{translate('Schedule Meeting Integration')}</h2>
          <p className="small">
            {translate(
              'Enter details for an external meeting scheduling tool to enable this functionality for customers in MyEnergy'
            )}
            (e.g.{' '}
            <a href="https://www.calendly.com" target="_blank" rel="noreferrer">
              www.calendly.com
            </a>
            ).
          </p>

          <TextInput
            style={inputStyle}
            label="resources.roles.fields.schedule_meeting_url"
            source="schedule_meeting_url"
            {...inputProps}
          />
          <TextInput
            style={inputStyle}
            label="resources.roles.fields.schedule_meeting_label"
            source="schedule_meeting_label"
            options={{ inputProps: { maxLength: 60 } }}
            {...inputProps}
          />
        </>
      </UiKey>
      <UiKey uiKey={uiKey + '.olark'}>
        <>
          <h2> {translate('Olark Chat Integration')}</h2>
          <p className="small">
            {translate('Configure a dedicated')}{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.olark.com/pricing?ref=josephabel1&utm_medium=referral-partner&utm_source=josephabel1&utm_campaign=referral-partner"
            >
              {translate('Olark Chat')}
            </a>{' '}
            {translate(
              'account to appear in MyEnergy when this role is the assigned team member. This overrides any default Olark Chat configuration.'
            )}{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.olark.com/pricing?ref=josephabel1&utm_medium=referral-partner&utm_source=josephabel1&utm_campaign=referral-partner"
            >
              {translate('Create a free Olark Chat account to get started.')}
            </a>
          </p>
          <TextInput style={inputStyle} source="api_key_chat" defaultValue={''} {...inputProps} />
        </>
      </UiKey>
      <UiKey uiKey={uiKey + '.integrations'}>
        <>
          {show_ironridge && (
            <>
              <h2>{translate("IronRidge User's Email Address")}</h2>
              <TextInput
                style={inputStyle}
                label={translate('Ironridge Email')}
                source="ironridge_email"
                {...inputProps}
                fullWidth
              />
            </>
          )}
          {show_mosaic && (
            <div>
              <h2>Mosaic Integration</h2>
              <p>
                Before you can submit credit applications via the Mosaic integration we need to know what email address
                you use in your Mosaic account
              </p>
              <IntegrationRoleConnectionButtons integration="mosaic" />
            </div>
          )}
          {show_loanpal && (
            <>
              <h2>
                {LOANPAL_OR_GOODLEAP} {translate('Channels')}
              </h2>
              <p>
                {translate(
                  'Enter any channels that are applicable for this user in a comma-separated list (eg "channel1,channel2")'
                )}
              </p>
              <TextInput
                style={inputStyle}
                label={translate('Channels')}
                source="loanpal_channels"
                {...inputProps}
                fullWidth
              />
            </>
          )}
          {show_sungage && (
            <>
              <h2>Sungage Integration</h2>
              <IntegrationRoleConnectionButtons integration="sungage" />
            </>
          )}
          {orgHasPhoenix && (
            <div>
              <h2>Phoenix Integration</h2>
              <IntegrationRoleConnectionButtons integration="phoenix" />
            </div>
          )}
          {availableFinanceIntegrations?.map((integration) => {
            return integration.requires_role_connection ? (
              <GenericFinanceRoleConnectionsWrapper integration={integration} key={integration.integration_name} />
            ) : (
              <></>
            )
          })}
          <BrighteButtonWrapper
            brighteConnectedToOrg={brighte_connected_to_org}
            id={id}
            disconnectBrighte={disconnectBrighte}
          />
          {dividend_connected_to_org && <DividendButtonWrapper />}
          {org?.enable_google_calendar && (
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.hasOwnProperty('calendar_shared_emails') &&
                (formData['google_calendar_id'] ? (
                  <div style={{ marginTop: 20, marginBottom: 20 }}>
                    <div>
                      {translate('Calendar Shared Emails')}:{' '}
                      {!formData.calendar_shared_emails || formData.calendar_shared_emails.length === 0 ? (
                        <span>{translate('None')}</span>
                      ) : (
                        ''
                      )}
                      {formData.calendar_shared_emails && formData.calendar_shared_emails.length
                        ? formData.calendar_shared_emails.map((email, index) => (
                            <Chip
                              key={index}
                              onDelete={(e) => {
                                restClientInstance('CUSTOM_DELETE', 'roles', {
                                  url: 'roles/' + formData.id + '/calendar/share/?email=' + email,
                                }).then((response) => {
                                  notify('Ok')
                                })
                              }}
                              label={email}
                            />
                          ))
                        : ''}
                    </div>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault()
                        var email = prompt('Email')

                        restClientInstance('CUSTOM_PUT', 'roles', {
                          url: 'roles/' + formData.id + '/calendar/share/?email=' + email,
                        }).then((response) => {
                          notify('Ok')
                        })
                      }}
                    >
                      {translate('Share Calendar With Email')}
                    </a>
                  </div>
                ) : (
                  <div style={{ marginTop: 20, marginBottom: 20 }}>
                    <span>
                      {translate(
                        'Google Calendar inactive. To enable: Activate Calendars for Org then wait until first sync when calendar will be created. Both your first and last names should be set.'
                      )}
                    </span>
                  </div>
                ))
              }
            </FormDataConsumer>
          )}
        </>
      </UiKey>
    </div>
  )
}

export const RoleIntegrations: FC<Props> = (props) => {
  const translate = useTranslate()

  if (!props.id) return <></>

  return (
    <AccordionCard
      disabled={props.disabled}
      defaultExpanded={true}
      name={'role-integrations'}
      title={translate('User Integrations')}
      content={RoleIntegrationsContent}
      contentProps={props}
    />
  )
}

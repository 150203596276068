import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeCardElementChangeEvent, loadStripe } from '@stripe/stripe-js'
import React, { useEffect, useMemo } from 'react'
import { useTranslate } from 'react-admin'

interface StripePaymentElementWrapperProps {
  payment_stripe_key: string
}

const StripePaymentElementWrapper = React.forwardRef<any, StripePaymentElementWrapperProps & StripePaymentElementProps>(
  ({ payment_stripe_key, ...props }, ref) => {
    const stripePromise = loadStripe(payment_stripe_key)
    return (
      <Elements stripe={stripePromise}>
        <StripePaymentElement ref={ref} {...props} />
      </Elements>
    )
  }
)

interface StripePaymentElementProps {
  onChange?: (e: StripeCardElementChangeEvent) => void
  showStripe?: boolean
}

const StripePaymentElement = React.forwardRef<any, StripePaymentElementProps>(({ onChange, ...props }, ref) => {
  const translate = useTranslate()
  const stripe = useStripe()
  const elements = useElements()

  //@ts-ignore
  ref.current = { stripe, elements }

  useEffect(() => {
    const cardElement = elements?.getElement(CardElement)
    if (cardElement) {
      cardElement.clear()
    }
  }, [props.showStripe])

  const cardElementStyle = useMemo(
    () => ({
      base: {
        color: '#000000',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontSize: '14px',
        '::placeholder': {
          color: '#a2a2a2',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    }),
    []
  )

  return (
    <div style={{ height: 70, marginBottom: 40, display: props.showStripe ? 'block' : 'none' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h2>{translate('Credit Card Details')}</h2>
      </div>
      <div style={{ border: '1px solid #DFDFDF', borderRadius: 5, padding: 10, backgroundColor: '#F3F3F3' }}>
        <CardElement
          onChange={onChange}
          //@ts-ignore
          style={cardElementStyle}
        />
      </div>
    </div>
  )
})

export default StripePaymentElementWrapper

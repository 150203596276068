import { ListSubheader, makeStyles, Theme } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { GroupType } from 'elements/input/GroupedSelectInput'
import { Chip } from 'opensolar-ui'
import { groupChoices, useProjectOwnerDetails } from 'projectSections/sections/info/orgSharing/util'
import { useTranslate } from 'ra-core'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { PaymentOptionDataType } from 'types/paymentOptions'
import PaymentOptionChekbox, { PaymentOptionCheckboxProps } from './PaymentOptionCheckbox'

export const useStyles = makeStyles<Theme, {}>(
  () => ({
    tieredHeader: {
      position: 'static',
    },
    tieredHeaderName: { margin: 0, lineHeight: 0, paddingTop: 10 },
  }),
  { name: 'PaymentOptionCheckList' }
)

type PaymentOptionCheckListProps = {
  getSelectedIndex: Function
  paymentOptionsOverride?: PaymentOptionDataType[]
  defaultSungageIdsForActve18Mo?: number[]
  tieredStyles?: boolean
  onPhoenixChange?: (isEnabled: boolean, sortedPhoenixPmtIds: number[]) => void
} & PaymentOptionCheckboxProps

const PaymentOptionCheckList: React.FC<PaymentOptionCheckListProps> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const { availablePaymentOptions, getSelectedIndex, paymentOptionsOverride } = props
  const referenceList = paymentOptionsOverride ? paymentOptionsOverride : availablePaymentOptions
  const [paymentOptions, setPaymentOptions] = useState<(GroupType | PaymentOptionDataType)[]>(referenceList)

  const form = useForm()
  const orgId = useSelector(authSelectors.getOrgId) as number
  const ownerOrg = useProjectOwnerDetails(useSelector(orgSelectors.getOrg), form.getState().values.org_id)
  const sharedWith = form.getState().values.shared_with

  useEffect(() => {
    if (ownerOrg && !!sharedWith?.length) {
      const newChoices = groupChoices(referenceList, orgId, ownerOrg, sharedWith)
      setPaymentOptions(newChoices)
    } else {
      setPaymentOptions(referenceList)
    }
  }, [sharedWith, referenceList])
  return (
    <>
      {paymentOptions.map((pmt) => {
        if (pmt.hasOwnProperty('heading') && pmt['heading']) {
          const heading = pmt as GroupType
          return (
            <ListSubheader
              key={heading.id}
              className={props.tieredStyles ? classes.tieredHeader : 'GroupedSelectHeading'}
            >
              {props.tieredStyles ? <p className={classes.tieredHeaderName}>{heading.name}</p> : <>{heading.name}</>}
              {heading.chip && (
                <Chip
                  key={heading.id}
                  label={translate(heading.chip)}
                  className={props.tieredStyles ? '' : 'GroupedSelectHeadingChip'}
                  size="small"
                  variant="outlined"
                  disabled={true}
                />
              )}
            </ListSubheader>
          )
        } else {
          const selectedIndex = getSelectedIndex(pmt)
          const parseId = typeof pmt.id === 'string' ? parseInt(pmt.id) : pmt.id
          const sungage18MoVersionSelected = props.defaultSungageIdsForActve18Mo?.includes(parseId)
          return (
            <PaymentOptionChekbox
              {...props}
              key={pmt.id}
              paymentOption={pmt as PaymentOptionDataType}
              isChecked={selectedIndex >= 0}
              selectedIndex={selectedIndex}
              sungage18MoVersionSelected={sungage18MoVersionSelected}
            />
          )
        }
      })}
    </>
  )
}
export default PaymentOptionCheckList

import { Search } from '@material-ui/icons'
import { useMemo } from 'react'
import { AutocompleteInput, ReferenceInput } from 'react-admin'
import { useFormState } from 'react-final-form'
import { ContactType } from 'types/contacts'

const ContactLinkExisting = ({ source, handleAssign }) => {
  return (
    <ReferenceInput
      label="Search existing contacts"
      reference="contacts"
      source={source}
      renderChildrenBeforeRecord={true}
      filter={{ type: 0 }}
      variant={'outlined'}
    >
      <ContactAutocomplete onSelect={handleAssign} />
    </ReferenceInput>
  )
}

const ContactAutocomplete = (props) => {
  const { source, onSelect, choices } = props
  const formValues = useFormState().values
  const currContacts = useMemo(() => formValues?.contacts_data?.filter((x) => x !== undefined).map((x) => x.id), [
    formValues.contacts_data,
  ])
  const filteredChoices = useMemo(() => choices.filter((x) => !currContacts.includes(x.id)), [currContacts, choices])
  const handleChange = (chosenId: number) => {
    if (chosenId && props.choices) {
      let chosenContact = props.choices.find((con: ContactType) => con.id === chosenId)
      if (chosenContact) onSelect(chosenContact)
    }
  }
  return (
    <AutocompleteInput
      options={{
        InputProps: { startAdornment: <Search />, fullWidth: true },
        containerStyle: { width: '100%', margin: '0 8px' },
      }}
      source={source}
      optionText={'display'}
      suggestionLimit={10}
      resettable={true}
      clearAlwaysVisible={true}
      {...props}
      choices={filteredChoices}
      input={{ ...props.input, onChange: handleChange }}
    />
  )
}
export default ContactLinkExisting

import { useMemo } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { ProjectTransactionTypeV3 } from 'types/projectTransactions'
import { currencySymbolForCountry, formatCurrency, formatTimeStringBaseOnLocale } from 'util/misc'
import { getOrgCountryCodeFromState } from 'util/org'
import { TRANSACTIONS_CONFIG, TRANSACTIONS_TITLE_MAP, TRANSACTION_STATUS_MAP } from './consts'
import { StatusKeyType, TransactionDataLine, TransactionRowData, TransactionRowLine, TransactionType } from './types'

export const useFormatTransactionRow = (transaction_data: ProjectTransactionTypeV3): TransactionRowData => {
  const translate = useTranslate()
  const currencySymbol: string = useSelector((state: any) =>
    currencySymbolForCountry(getOrgCountryCodeFromState(state))
  )
  return useMemo(() => {
    const transaction_type: TransactionType = transaction_data.transaction_type || 'none'
    const title = transaction_data?.signature_data ? 'Proposal Acceptance' : TRANSACTIONS_TITLE_MAP[transaction_type]
    const getStatus = () => {
      const statusKey =
        transaction_type === 'expired_docusign'
          ? 'expired_docusign'
          : (((transaction_data.is_complete ? '1' : '0') +
              (transaction_data.funds_confirmed ? '1' : '0') +
              (transaction_data.amount > 0 ? '1' : '0')) as StatusKeyType)

      const statusSuffix: string = transaction_data?.external_status_description
        ? `(${transaction_data.external_status_description})`
        : ''
      return TRANSACTION_STATUS_MAP[statusKey] + statusSuffix
    }

    const status = getStatus()

    const formatImg = (val) => {
      return val ? <img src={val} style={{ width: 200, height: 50, display: 'block' }} /> : null
    }

    const currencyWithSymbol = (val) => {
      return `${currencySymbol} ${formatCurrency(val)}`
    }

    const currencyWithTaxAndSymbol = (val) => {
      const taxSuffix =
        transaction_data?.tax_included && transaction_data.tax_included > 0
          ? `(${translate('Tax Included')}: ${currencySymbol}${formatCurrency(transaction_data.tax_included)})`
          : ''
      return `${currencySymbol}${formatCurrency(val)} ${taxSuffix}`
    }

    const formatFuncs = {
      currencyWithTax: currencyWithTaxAndSymbol,
      translate: translate,
      currency: currencyWithSymbol,
      date: formatTimeStringBaseOnLocale,
      img: formatImg,
    }

    const bodyItems: TransactionRowLine[] = Object.values(TRANSACTIONS_CONFIG)
      .map((i: TransactionDataLine) => {
        const whitelisted = i.whitelist.includes(transaction_type)
        const source = i.transactionTypeToValueMap || transaction_data
        const key = i.transactionDataKey || transaction_type
        const value =
          i.id === 'method' && transaction_data?.signature_data
            ? null
            : whitelisted && source?.[key]
            ? formatFuncs[i.format](source[key])
            : null
        return { label: i?.label ? translate(i.label) : i.label, value }
      })
      .filter((i) => i.value)
    return { title: translate(title), status: translate(status), bodyItems }
  }, [transaction_data])
}

import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import { useTranslate } from 'ra-core'
import React, { ChangeEvent } from 'react'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type PropTypes = {
  currentOrgId: number
  onOrgIdChange: (newId: number) => void
}

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    marginLeft: '15px',
    marginBottom: '5px',
  },
  titleText: {
    color: theme.greyMid1,
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
}))

const TeamsOrgFilter: React.FC<PropTypes> = (props) => {
  const thisOrg = useSelector(orgSelectors.getOrg)
  const project = useFormState().values
  const translate = useTranslate()
  const classes = useStyles()

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newVal: string = e.target.value
    const newValAsInt = parseInt(newVal)
    props.onOrgIdChange(newValAsInt)
  }

  if (!project?.shared_with?.length) return null
  return (
    <div className={classes.wrapper}>
      <div className={classes.titleText}>{translate("Which Org's Payment Options would you like to use?")}</div>
      <RadioGroup defaultValue={`${props.currentOrgId}`} className={classes.radioGroup} onChange={onChange}>
        {thisOrg && <FormControlLabel value={`${thisOrg.id}`} control={<Radio />} label={thisOrg.name} />}
        {project.shared_with?.map((sharedWithOrg) => {
          if (sharedWithOrg.org_id === thisOrg?.id) return null
          return (
            <FormControlLabel
              value={`${sharedWithOrg.org_id}`}
              control={<Radio />}
              label={sharedWithOrg.org_name}
              key={sharedWithOrg.org_id}
            />
          )
        })}
      </RadioGroup>
    </div>
  )
}
export default TeamsOrgFilter

import { Grid } from '@material-ui/core'
import type { OpenSolarThemeType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orderSelectors, updateOrderQuantity } from 'ducks/orderComponents'
import { orgSelectors } from 'ducks/orgs'
import inflection from 'inflection'
import type LineItemType from 'pages/ordering/OrderLineItem'
import ProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/projectOrderPresenter'
import createHardwareSelectNode from 'pages/ordering/replaceItem/hardwareSelect/HardwareSelectNode'
import type { FlowControllerType } from 'pages/ordering/replaceItem/types'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import React, { useCallback, useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import RegisterLink from '../../detail/content/RegisterLink'
import { DeleteAction } from './Action'
import { CodeField } from './CodeField'
import { CostPerUnit, TotalCost } from './Cost'
import Allocations from './allocation/Allocations'
import getQuantitySelectorOptions from './getQuantitySelectorOptions'
import LowStockWarning from './quantity/LowStockWarning'
import QuantityField from './quantity/QuantityField'
import useQuantityFieldParts from './quantity/useQuantityFieldParts'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  gridContainer: {
    borderBottom: '1px solid #e7e7e7',
  },
  gridLineItem: {
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  itemCell: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'start',
  },
  singleAllocation: {
    paddingTop: '0px',
  },
  multiAllocation: {
    paddingTop: '10px',
  },
  clickableLink: {
    textDecoration: 'underline',
    color: '#1751D0',
    cursor: 'pointer',
  },
  ellipsisItem: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 100,
  },
}))

const LineItemRow = ({
  lineItem,
  isFirstItem,
  isLastItem,
  editable,
  replaceItemFlowController,
  groupedLineItemsQuantity,
  orderingPresenter,
  NeedMoreMessage,
  trackSource,
}: {
  lineItem: LineItemType
  isFirstItem: boolean
  isLastItem: boolean
  editable: boolean
  groupedLineItemsQuantity: number
  replaceItemFlowController: FlowControllerType
  orderingPresenter: ProjectOrderPresenter
  NeedMoreMessage?: React.ReactNode
  trackSource?: string
}) => {
  const classes = useStyles({ isFirstItem })
  const dispatch = useDispatch()
  const { type, Message, Selector } = useQuantityFieldParts({ lineItem })
  const { uuid, pricePerUnit, quantity, data, componentType, status } = lineItem
  const componentTypeLabel = componentType || data?.component_type
  const distributorRegistrationRequired = useSelector(orgSelectors.distributorRegistrationRequired)
  const { beforeDiscount, afterDiscount, discount } = lineItem.getBestDiscountOffer()
  const LowStockWarningImpl = useMemo(() => <LowStockWarning lineItem={lineItem} />, [lineItem])
  const usingCustomPricing = useSelector(orderSelectors.getIsCustomPricing)
  const supplier = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const showTBC = trackSource === 'checkout' && supplier === HardwareSupplierEnum.SolarOutlet && usingCustomPricing

  const ReplaceItem = useMemo(() => {
    const handleReplacement = () => {
      const hardwareSelect = createHardwareSelectNode({
        originalLineItem: lineItem,
        flowController: replaceItemFlowController,
        trackSource: 'replace_item_dialog',
      })
      replaceItemFlowController.showFlow()
      replaceItemFlowController.goTo({ node: hardwareSelect })
      logAmplitudeEvent('generic_button_clicked', {
        source: trackSource ? trackSource + '_resolve_stock_issues' : 'hardware_resolve_stock_issues',
        context: 'resolve_stock_issues',
      })
    }
    return (
      <span className={classes.clickableLink} onClick={handleReplacement}>
        Resolve stock issues
      </span>
    )
  }, [lineItem])

  const handleUpdateQuantity = useCallback(
    (quantity) => {
      dispatch(updateOrderQuantity({ uuid, quantity }))
      logAmplitudeEvent('hardware_quantity_field_updated', { source: 'order_table' })
    },
    [uuid]
  )

  return (
    <Grid container item xs={12} className={classes.gridContainer}>
      <Grid container item xs={12} className={classes.gridLineItem}>
        <Grid container item xs={12} alignItems={'center'} spacing={1}>
          <Grid item xs={2}>
            <CodeField lineItem={lineItem} />
          </Grid>
          <Grid item xs={1}>
            {isFirstItem && (
              <div className={classes.ellipsisItem}>
                {componentTypeLabel && inflection.capitalize(componentTypeLabel)}
              </div>
            )}
          </Grid>
          <Grid item xs={1}>
            {isFirstItem && <div className={classes.ellipsisItem}>{data?.manufacturer_name}</div>}
          </Grid>
          <Grid item xs={3}>
            {editable ? (
              <>
                <QuantityField
                  quantity={lineItem.quantity}
                  Message={!!Message && <Message />}
                  Selector={
                    !!Selector && (
                      <Selector
                        options={getQuantitySelectorOptions({
                          lineItem,
                          orderingPresenter,
                          type,
                          trackSource: 'order_table',
                        })}
                      />
                    )
                  }
                  onUpdateQuantity={handleUpdateQuantity}
                />
                {LowStockWarningImpl}
                {isLastItem && NeedMoreMessage}
              </>
            ) : (
              quantity
            )}

            {distributorRegistrationRequired === HardwareSupplierEnum.SolarOutlet && <RegisterLink />}
          </Grid>
          <Grid item xs={2}>
            {isFirstItem && (
              <Allocations
                lineItem={lineItem}
                groupedLineItemsQuantity={groupedLineItemsQuantity}
                ReplaceItem={!distributorRegistrationRequired && ReplaceItem}
              />
            )}
          </Grid>

          <Grid item xs={1}>
            {status === 'loading' ? (
              <Skeleton />
            ) : (
              <CostPerUnit pricePerUnit={showTBC ? 'TBC' : pricePerUnit} discount={discount} />
            )}
          </Grid>
          <Grid item xs={1}>
            {status === 'loading' ? (
              <Skeleton />
            ) : (
              <TotalCost beforeDiscount={showTBC ? 'TBC' : beforeDiscount} afterDiscount={afterDiscount} />
            )}
          </Grid>
          <Grid item xs={1}>
            <DeleteAction disabled={!editable} uuid={uuid} orderingPresenter={orderingPresenter} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LineItemRow

import React, { memo } from 'react'
import { useTranslate } from 'react-admin'
import { formatCurrencyWithSymbol } from 'util/misc'
import { PriceFieldLayout } from './PriceField'

const ManualPrice = memo(({ cost, currencySymbol }: { cost: number; currencySymbol: string }) => {
  const translate = useTranslate()
  return (
    <PriceFieldLayout
      label={<div>{translate('Manual pricing')}</div>}
      value={<div>{formatCurrencyWithSymbol(cost, currencySymbol)}</div>}
    />
  )
})

export default ManualPrice

import { ProjectInfoState } from 'reducer/project/projectInfoReducer'

export const PROJECT_INFO_SET = 'PROJECT_INFO_SET'
export const PROJECT_INFO_CLEAR = 'PROJECT_INFO_CLEAR'
export const SET_COMPONENT_SPECS_ORDERING_DATA_READY = 'SET_COMPONENT_SPECS_ORDERING_DATA_READY'

type ProjectInfoSetAction = {
  type: typeof PROJECT_INFO_SET
  payload: ProjectInfoState
}
type ProjectInfoClearAction = {
  type: typeof PROJECT_INFO_CLEAR
}

type ComponentSpecsOrderingDataReadyAction = {
  type: typeof SET_COMPONENT_SPECS_ORDERING_DATA_READY
  payload: boolean
}

export type ProjectInfoActions = ProjectInfoSetAction | ProjectInfoClearAction | ComponentSpecsOrderingDataReadyAction

export const projectInfoActions = {
  setProjectInfo: (payload: ProjectInfoState): ProjectInfoSetAction => {
    return {
      type: PROJECT_INFO_SET,
      payload,
    }
  },
  clearProjectInfo: (): ProjectInfoClearAction => {
    return {
      type: PROJECT_INFO_CLEAR,
    }
  },
  setComponentSpecsOrderingDataReady: (isReady: boolean): ComponentSpecsOrderingDataReadyAction => {
    return {
      type: SET_COMPONENT_SPECS_ORDERING_DATA_READY,
      payload: isReady,
    }
  },
}

import { Card, CardActions } from '@material-ui/core'
import { passwordChangeForm as passwordChangeFormAction } from 'actions/authActions'
import { useCallback } from 'react'
import { useTranslate } from 'react-admin'
import { Form } from 'react-final-form'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import AuthModalWrapper from './AuthModalWrapper'
import { StyledButton, styles } from './common'
import PasswordInput from './components/fields/PasswordInput'

const ChangePassword = (props) => {
  const translate = useTranslate()
  const history = useHistory()

  const onChangeSuccess = useCallback(() => {
    history.push('/password-change-complete')
  }, [])

  const handleSubmitForm = ({ current_password, new_password }) => {
    props.passwordChangeForm({
      payload: {
        current_password,
        new_password,
      },
      callbacks: {
        onSuccess: onChangeSuccess,
      },
    })
  }
  return (
    <AuthModalWrapper>
      <Form
        onSubmit={handleSubmitForm}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <Card elevation={0} style={Object.assign({ width: '280px', margin: '0 auto' }, styles.cardOnly)}>
            <h1>{translate('Change Password')}</h1>
            <form onSubmit={handleSubmit}>
              <div style={styles.form}>
                <div style={Object.assign({}, styles.input, { display: '' })}>
                  <PasswordInput source={'current_password'} label={'Current Password'} />
                  <PasswordInput source={'new_password'} label={'New Password'} />
                </div>
              </div>
              <CardActions>
                <StyledButton type="submit" disabled={submitting} fullWidth>
                  {translate('Set Password')}
                </StyledButton>
              </CardActions>
            </form>
          </Card>
        )}
      />
    </AuthModalWrapper>
  )
}
export default connect(() => ({}), { passwordChangeForm: passwordChangeFormAction })(ChangePassword)

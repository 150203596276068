import { Grid } from '@material-ui/core'
import type { OpenSolarThemeType } from 'Themes'
import React, { useMemo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import type { ComponentTypes } from 'types/selectComponent'
import type { ForecastDetailDialogController } from '../../forecast/detail/useForecastDetailDialogController'
import type { ForecastedProjectInventory } from '../../forecast/type'
import ComponentSelector from './ComponentSelector'
import ComponentTypeSelector from './ComponentTypeSelector'
import ProjectSelector from './ProjectSelector'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: '15px',
  },
}))

const COMPONENT_TYPE_FILTERS: { componentType: ComponentTypes; label: string }[] = [
  {
    componentType: 'module',
    label: 'Panels',
  },
  {
    componentType: 'inverter',
    label: 'Inverters',
  },
  {
    componentType: 'battery',
    label: 'Batteries',
  },
  {
    componentType: 'other',
    label: 'Others',
  },
]

const ProjectForecastDialogToolbar = ({
  controller,
  forecastedProjectInventoryData,
}: {
  controller: ForecastDetailDialogController
  forecastedProjectInventoryData: ForecastedProjectInventory[] | undefined
}) => {
  const classes = useStyles()

  const currentSelectedForecastProjectInventory = useMemo(() => {
    return forecastedProjectInventoryData?.find((data) => data.project_id === controller.projectId)
  }, [forecastedProjectInventoryData, controller.projectId])

  return (
    <Grid container spacing={1}>
      <Grid container spacing={1} item xs={12}>
        <Grid item>
          <ProjectSelector
            selectedProject={controller.projectId}
            updateFilter={controller.setFilter}
            forecastedProjectInventoryData={forecastedProjectInventoryData}
          />
        </Grid>
        <Grid item>
          <ComponentSelector
            selectedCode={controller.code}
            currentSelectedForecastProjectInventory={currentSelectedForecastProjectInventory}
            updateFilter={controller.setFilter}
            componentType={controller.componentType}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.buttonWrapper}>
        {COMPONENT_TYPE_FILTERS.map(({ componentType, label }) => {
          return (
            <ComponentTypeSelector
              key={controller.code + componentType}
              updateFilter={controller.setFilter}
              currentSelectedForecastProjectInventory={currentSelectedForecastProjectInventory}
              label={label}
              selected={controller.componentType === componentType}
              componentType={componentType}
            />
          )
        })}
      </Grid>
    </Grid>
  )
}

export default ProjectForecastDialogToolbar

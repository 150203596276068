import { LineItem } from './types'

export const DEFAULT_LINE_ITEM: LineItem = {
  product_name: '',
  product_description: '',
  quantity: 1,
  price: 0,
  discount: 0,
  tax_rate: 0,
}

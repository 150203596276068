import { Grid } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  pricePerWattAfterDiscount: {
    fontSize: 16,
  },
  pricePerWatt: () => ({
    textDecoration: 'line-through',
  }),
}))

const PricePerWattWithDiscount = ({
  pricePerWattAfterDiscount,
  pricePerWatt,
}: {
  pricePerWattAfterDiscount: string
  pricePerWatt: string
}) => {
  const classes = useStyles()
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item className={classes.pricePerWattAfterDiscount}>
        {pricePerWattAfterDiscount}
      </Grid>
      <Grid item className={classes.pricePerWatt}>
        {pricePerWatt}
      </Grid>
    </Grid>
  )
}

export default PricePerWattWithDiscount

import { HelpLink } from 'elements/link/HelpLink'
import React from 'react'
import { useTranslate } from 'react-admin'
import { ActionCreate, ActionEdit, ActionList, ActionShow } from './Actions'

const ActionTitle = ({ record, style }) => {
  const translate = useTranslate()
  return (
    <span style={style}>
      {translate('Project Actions')}: {record ? `"${record.title}"` : ''}
    </span>
  )
}

const Subtitle = () => {
  const translate = useTranslate()
  return (
    <span>
      {translate("Customise your team's workflow to match your business' needs.")}{' '}
      <HelpLink href="https://support.opensolar.com/hc/en-us/articles/4407118190233" />
    </span>
  )
}

export default {
  list: ActionList,
  show: ActionShow,
  edit: ActionEdit,
  create: ActionCreate,
  options: {
    create: {
      title: 'Create Action',
      subtitle: null,
      breadCrumb: 'Create Action',
    },
    list: {
      title: 'Project Actions',
      subtitle: <Subtitle />,
      breadCrumb: 'Actions',
    },
    edit: {
      title: <ActionTitle />,
      subtitle: null,
      breadCrumb: 'Edit Action',
    },
  },
}

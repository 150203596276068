import {
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined'
import { IconButton } from 'opensolar-ui'
import React, { ChangeEvent, FocusEvent, useCallback, useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { ModuleGridType, ModuleLayout, StudioSystemType } from 'types/studio/items'

import { applyToModuleGrid } from 'Studio/Utils'
import { ProjectType } from 'types/projects'
import { useStyles } from './styles'

interface PropTypes {
  project: ProjectType
  system: StudioSystemType
}

function round(num: number): number {
  const precision = 10
  return Math.round(num * precision) / precision
}

const PanelGroupPanel: React.FC<PropTypes> = ({ system }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const [selected, setSelected] = useState<ModuleGridType | undefined>()
  const [moduleLayout, setModuleLayout] = useState<ModuleLayout | undefined>()
  const [slope, setSlope] = useState(0)
  const [azimuth, setAzimuth] = useState('')

  const updateFrom = useCallback((selected) => {
    setModuleLayout(selected?.moduleLayout())
    setSlope(selected ? round(selected.getSlope()) : 0)
    const azimuthVal = selected ? round(selected.getAzimuth()) : 0
    setAzimuth(azimuthVal.toString())
  }, [])

  useEffect(() => {
    const handler = (object: any) => {
      if (object !== selected) return
      updateFrom(object)
    }
    window.editor.signals.objectChanged.add(handler)
    return () => window.editor.signals.objectChanged.remove(handler)
  }, [selected])

  useEffect(() => {
    updateFrom(selected)
  }, [selected])

  const selectFromStudio = () => {
    let newSelected = window.editor?.selected?.type === 'OsModuleGrid' ? window.editor.selected : null
    setSelected(newSelected as ModuleGridType | undefined)
  }

  const reselect = () => {
    if (selected) {
      window.editor.selectByUuid(system.uuid)
      window.editor.selectByUuid(selected.uuid)
    }
  }

  const deleteSelected = () => {
    if (!selected) return
    const grids = system.moduleGrids()
    const wasSelected = window.editor.selected === selected
    const index = grids.indexOf(selected)
    window.editor.deleteObject(selected)

    const nextSelected = index === 0 ? grids[index] : grids[index - 1]
    if (nextSelected && wasSelected) window.editor.selectByUuid(nextSelected.uuid)
    else setSelected(nextSelected)
  }

  const onChangeLayout = (e, value) => {
    if (!selected) return
    window.editor.execute(new RedrawModulesCommand(selected, 'moduleLayout', value, selected.moduleLayout()))
    window.editor.signals.objectChanged.dispatch(selected)
    setModuleLayout(value)
    reselect()
  }

  const onChangeSlope = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (!selected) return
    let input = event.target.value
    let value = parseFloat(input)
    if (value > 90) {
      value = 90
    }

    const valueToSave = input === '' ? null : value

    //don't save if value has changed after parseFloat
    //e.g. While adding decimal point ('10.') or typo (10a)
    if (input !== valueToSave + '') {
      console.log('Do not save, raw value different to parsed value', value, valueToSave)
    } else if (valueToSave === null || !isNaN(valueToSave)) {
      applyToModuleGrid(selected, undefined, valueToSave, undefined)
    }

    setSlope(value)
    reselect()
  }

  const calculateAzimuth = (val: number) => {
    val = val % 360
    if (val < 0) val += 360
    return val
  }

  const onChangeAzimuth = (event: ChangeEvent<HTMLTextAreaElement>) => {
    let input = event.target.value
    const valueToApply = calculateAzimuth(parseFloat(input))
    const valueToSave = input === '' ? null : valueToApply
    //don't save if value has changed after parseFloat
    //e.g. While adding decimal point ('10.') or typo (10a)
    if (valueToApply + '' !== valueToSave + '') {
      console.log('Do not save, raw value different to parsed value', valueToApply, valueToSave)
    } else if (valueToSave === null || !isNaN(valueToSave)) {
      applyToModuleGrid(selected, valueToSave, undefined, undefined)
    }

    setAzimuth(input)
    reselect()
  }

  const onBlurAzimuth = (event: FocusEvent<HTMLInputElement>) => {
    let value = calculateAzimuth(parseFloat(event.target.value))
    setAzimuth(value.toString())
  }

  useEffect(() => {
    window.editor.signals.objectSelected.add(selectFromStudio)
    return () => window.editor.signals.objectSelected.remove(selectFromStudio)
  }, [])

  return (
    <div className={classes.rows} style={{ background: '#F8F8F8' }}>
      {/***   Panel Group Selector   ***/}
      <FormControl className={classes.row} size="small" disabled={!selected}>
        <FormLabel className={classes.rowLabel}>{translate('Panel group')}</FormLabel>
        <Select
          variant="outlined"
          autoWidth={false}
          className={classes.fill}
          onChange={(event) => {
            window.editor.selectByUuid(event.target.value as string)
          }}
          onClose={(event) => {
            reselect()
          }}
          value={selected?.uuid || ''}
          displayEmpty
          disabled={system.moduleGrids().length === 0}
        >
          <MenuItem value="">
            {/* {system.moduleGrids().length > 0 ? translate('Select from dropdown') : translate('Please draw panels')} */}
          </MenuItem>
          {system.moduleGrids().map((moduleGrid, index) => {
            const panels = moduleGrid.moduleQuantity()
            return (
              <MenuItem key={index} value={moduleGrid.uuid}>
                {panels === 1 ? translate('1 Panel') : translate('%{panels} Panels', { panels })}
              </MenuItem>
            )
          })}
        </Select>

        <IconButton onClick={deleteSelected} className={classes.rowAction} disabled={!selected}>
          <Tooltip title={translate('Delete Panel Group')} enterDelay={300}>
            <DeleteIcon style={{ color: '#000000' }} />
          </Tooltip>
        </IconButton>
      </FormControl>

      {/***   Slope Input   ***/}
      <FormControl className={classes.row} size="small" disabled={!selected}>
        <FormLabel className={classes.rowLabel + ' ' + classes.fill}>
          {translate('Slope (relative to ground)')}
        </FormLabel>
        <TextField
          type="number"
          variant="outlined"
          size="small"
          className={classes.rowValue}
          label={null}
          disabled={!selected || selected.slopeAuto}
          inputProps={{ max: '90', min: '0' }}
          value={slope}
          onChange={onChangeSlope}
        />
      </FormControl>

      {/***   Azimuth Input   ***/}
      <FormControl className={classes.row} size="small" disabled={!selected}>
        <FormLabel className={classes.rowLabel + ' ' + classes.fill}>
          {translate('Azimuth (relative to North)')}
        </FormLabel>
        <TextField
          type="number"
          variant="outlined"
          size="small"
          className={classes.rowValue}
          label={null}
          disabled={!selected || selected.azimuthAuto}
          value={azimuth}
          onChange={onChangeAzimuth}
          onBlur={onBlurAzimuth}
        />
      </FormControl>

      {/***   Orientation Radios   ***/}
      <FormControl className={classes.row} size="small" disabled={!selected}>
        <FormLabel className={classes.rowLabel + ' ' + classes.fill}>{translate('Orientation')}</FormLabel>
        <RadioGroup row value={moduleLayout || ''} onChange={onChangeLayout}>
          <FormControlLabel value="portrait" control={<Radio color="primary" />} label={translate('Portrait')} />
          <FormControlLabel value="landscape" control={<Radio color="primary" />} label={translate('Landscape')} />
        </RadioGroup>
      </FormControl>
    </div>
  )
}

export default PanelGroupPanel

import { useCallback, useMemo } from 'react'
import type { FilterComponentRendererType, FilterGenericPropsType, OptionGenericType } from '../type'
import type { RatingRangeType } from './constants'
import { getOptionValueByFilterValues, getRangeOptionByValue } from './constants'

export type RatingFilterOptionsType = OptionGenericType<string>[]

type RatingFilterProps = FilterGenericPropsType & {
  RendererComponent: FilterComponentRendererType<RatingFilterOptionsType>
  rangeOptions: RatingRangeType[]
  gteFilterKey: string
  lteFilterKey: string
}

const mapRangeOptionsToSelectOptions = (rangeOptions: RatingRangeType[]): RatingFilterOptionsType => {
  const defaultOption = {
    title: 'All',
    value: '',
    id: 'default',
  }
  const selectOptions: RatingFilterOptionsType = [defaultOption]

  rangeOptions.forEach((range) => {
    selectOptions.push({ title: range.title, value: range.value, id: range.value })
  })

  return selectOptions
}

const RatingFilter = ({
  disabled,
  label,
  rangeOptions,
  gteFilterKey,
  lteFilterKey,
  modifiableFilterValues,
  persistentFilterValues,
  allFilterValues,
  setModifiableFilters,
  RendererComponent,
}: RatingFilterProps) => {
  const onSelect = useCallback(
    (value) => {
      const matchedRangeOption = getRangeOptionByValue(value, rangeOptions)
      const newFilterValues = {
        ...modifiableFilterValues,
        [gteFilterKey]: matchedRangeOption?.min,
        [lteFilterKey]: matchedRangeOption?.max,
      }
      setModifiableFilters(newFilterValues)
    },
    [modifiableFilterValues, rangeOptions]
  )

  const selectOptions = useMemo(() => {
    return mapRangeOptionsToSelectOptions(rangeOptions)
  }, [rangeOptions])

  return (
    <RendererComponent
      id={'rating_filter'}
      defaultValue={''}
      label={label || 'Rating'}
      options={selectOptions}
      disabled={disabled}
      onSelect={onSelect}
      value={getOptionValueByFilterValues({
        ranges: rangeOptions,
        gte: allFilterValues[gteFilterKey],
        lte: allFilterValues[lteFilterKey],
      })}
    />
  )
}

export default RatingFilter

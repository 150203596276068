import { FC } from 'react'
import { ReferenceInput } from 'react-admin'
import { SelectCommissionInput } from 'resources/projects/projectsList/SelectCommissionInput'
import ConditionalRefFilter from './ConditionalRefFilter'

interface PropTypes {
  source: string
  fieldId: string
}

const Commission: FC<PropTypes> = (props) => {
  return (
    <ConditionalRefFilter {...props}>
      <ReferenceInput perPage={1000} source={`${props.source}.fields.itemId`} label={''} reference="commissions">
        <SelectCommissionInput options={{ variant: 'outlined' }} />
      </ReferenceInput>
    </ConditionalRefFilter>
  )
}

export default Commission

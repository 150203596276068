import { FormControlLabel } from '@material-ui/core'
import { Checkbox, Switch } from 'opensolar-ui'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { PaymentOptionDataType } from 'types/paymentOptions'
import { getNumberWithOrdinal, parseJsonSafe } from 'util/misc'

export type PaymentOptionCheckboxProps = {
  systemUuid: string
  paymentOption?: PaymentOptionDataType
  availablePaymentOptions: PaymentOptionDataType[]
  isArchived: boolean
  pricingIsLocked: boolean
  disabled: boolean
  onChange: (eevent: ChangeEvent<HTMLInputElement>, pmt: PaymentOptionDataType) => void
  handleSungageItcChange: (e: ChangeEvent<HTMLInputElement>, pmt: PaymentOptionDataType) => void
  pendingPmtId: undefined | number
  isChecked: boolean
  selectedIndex: number
  sungage18MoVersionSelected?: boolean
}

const PaymentOptionCheckbox: React.FC<PaymentOptionCheckboxProps> = (props) => {
  const getIsDisabled = (): boolean => {
    if (props.disabled) return true
    // integrated finance options will sometimes limit payment options to certain states
    const state = window.WorkspaceHelper.project.state
    if (props.paymentOption?.configuration_json) {
      const config = props.paymentOption.configuration_json
      if (config) {
        const states = parseJsonSafe(config)?.states
        if (states && states.length > 0 && !states.includes(window.WorkspaceHelper.project.state)) return true
      }
    }
    if (!props.paymentOption?.auto_apply_enabled && state && props.paymentOption?.auto_apply_only_specified_states) {
      // auto_apply_only_specified_states are required but current state is not found in the list
      if (props.paymentOption?.auto_apply_only_specified_states.split(',').indexOf(state) === -1) {
        return true
      }
    }
    return props.disabled
  }

  const [isPending, setIsPending] = useState<boolean>(false)
  const [integration, setIntegration] = useState<string | undefined>(
    parseJsonSafe(props.paymentOption?.configuration_json)?.integration
  )
  const [disabled, setDisabled] = useState<boolean>(getIsDisabled())
  const [prepaymentPeriod, setPrepaymentPeriod] = useState<number | undefined>(
    parseJsonSafe(props.paymentOption?.configuration_json)?.prepayment_period
  )

  const translate = useTranslate()

  useEffect(() => {
    if (integration === 'sungage') {
      setPrepaymentPeriod(parseJsonSafe(props.paymentOption?.configuration_json)?.prepayment_period)
    }
  }, [integration, props.paymentOption?.configuration_json])

  useEffect(() => {
    if (props.pendingPmtId && props.pendingPmtId === props.paymentOption?.id && props.isChecked) {
      setIsPending(true)
    } else if (isPending && props.pendingPmtId !== props.paymentOption?.id) {
      setIsPending(false)
    }
  }, [props.pendingPmtId, props.isChecked])

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (props.paymentOption) {
      props.onChange(event, props.paymentOption)
    }
  }

  const paymentOptionIds = window.AccountHelper.loadedData?.paymentOptions?.map((x: any) => x.id)
  const isOwnPayment = paymentOptionIds.includes(props.paymentOption)
  if (
    integration === 'sungage' &&
    !!prepaymentPeriod &&
    !props.paymentOption?.integration_external_reference?.includes('classic_18') &&
    !props.paymentOption?.is_archived
  )
    return null

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
      <FormControlLabel
        key={props.paymentOption?.id}
        style={{ display: 'flex', alignItems: 'flex-start', fontSize: 12 }}
        control={
          <Checkbox
            style={{ padding: '0 9px' }}
            size="small"
            checked={props.isChecked && !isPending}
            onChange={onChange}
            disabled={disabled}
          />
        }
        label={
          <p style={{ marginTop: 4, fontSize: 12 }}>
            {props.paymentOption?.title +
              (props.selectedIndex >= 0 ? ' (' + getNumberWithOrdinal(props.selectedIndex + 1) + ')' : '')}
            {props.paymentOption?.is_archived &&
              !props.paymentOption?.title?.toLowerCase()?.includes('archived') &&
              isOwnPayment && <span>{translate('( archived )')}</span>}
          </p>
        }
      />
      {integration === 'sungage' && !props.paymentOption?.integration_external_reference?.includes('classic_18') && (
        <div
          className="small"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: '5px',
            paddingRight: '10px',
          }}
        >
          <span style={{ fontSize: '12px', marginRight: '5px' }}>{translate('Use 18 month DPP')}</span>
          <Switch
            checked={props.isChecked && props?.sungage18MoVersionSelected}
            onChange={(e) => {
              if (props.paymentOption) {
                props.handleSungageItcChange(e, props.paymentOption)
              }
            }}
            size="small"
            disabled={!props.isChecked}
          />
        </div>
      )}
    </div>
  )
}

// orgs with active integrations can have over 100 payment options and this component really only needs to know if it should be showing a checkbox or not. So let's memoize it and avoid every cehcbkox re-rendering each
// time some other payment option is toggled on/off
const propComparison = (prevProps: PaymentOptionCheckboxProps, nextProps: PaymentOptionCheckboxProps) => {
  const propsToCompare = ['isChecked', 'pendingPmtId', 'selectedIndex', 'sungage18MoVersionSelected']
  let noDiff = true
  propsToCompare.forEach((propField) => {
    if (prevProps[propField] !== nextProps[propField]) {
      noDiff = false
    }
  })
  return noDiff
}
export default React.memo(PaymentOptionCheckbox, propComparison)

import { AnyComponentType } from 'types/components'
import { handleAddComponent } from './handleAddComponent'

export const addDefaultComponentOrChooseFromDialog = (componentType, systemUuid, onManualAdd) => {
  var componentActivationsAvailable: AnyComponentType[] = []
  if (componentType === 'module') {
    componentActivationsAvailable = window.AccountHelper.getComponentModuleSpecsAvailable()
  } else if (componentType === 'inverter') {
    componentActivationsAvailable = window.AccountHelper.getComponentInverterSpecsAvailable()
  } else if (componentType === 'battery') {
    componentActivationsAvailable = window.AccountHelper.getComponentBatterySpecsAvailable()
  }

  var defaultComponents = componentActivationsAvailable.filter((c) => !!c.is_default)

  var componentToAutoAdd: AnyComponentType | undefined

  if (defaultComponents.length > 0) {
    componentToAutoAdd = defaultComponents[0]
  } else if (componentActivationsAvailable.length === 1) {
    // If no default found but we only found one matching component, automatically select it
    componentToAutoAdd = componentActivationsAvailable[0]
  }

  // If none found, launch the hardware selector to choose one
  if (componentToAutoAdd) {
    var params = {}

    // This is a little confusing, but we just need to set the key to either moduleId, inverterId, batteryId
    params[componentType + 'Id'] = componentToAutoAdd.id
    handleAddComponent(componentType, systemUuid, params)
  } else {
    onManualAdd()
  }
}

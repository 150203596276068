import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import Alert from 'elements/Alert'
import ChipsInput from 'elements/input/ChipsInput'
import DependentInput from 'elements/input/DependentInput'
import {
  BooleanInput,
  Create,
  DeleteButton,
  Edit,
  NotFound,
  ReferenceArrayInput,
  SimpleForm,
  TextInput,
  TopToolbar,
  useTranslate,
} from 'react-admin'
import { FormSpy } from 'react-final-form'
import { useSelector } from 'react-redux'
import { ConnectedOrg } from 'types/orgs'
import { SharedEntitiesLink } from './ConnectedOrgsList'
import { RoleSelectInput } from './RoleSelectInput'
import ShareableConnectionLink from './ShareableConnectionLink'
import { styles as makeStyles } from './styles'

const ConnectedOrgForm = (props: any) => {
  const styles = makeStyles()
  const translate = useTranslate()
  const orgId = useSelector(authSelectors.getOrgId)
  const org = useSelector(orgSelectors.getOrg)

  const getDefaultValues = (record) => {
    var newRecord = { is_active: true, ...record }
    if (record.permission) {
      newRecord.permission = record.permission.replace('/permissions_role/', `/orgs/${orgId}/permissions_role/`)
    }
    return newRecord
  }

  const formatSubmitValues = (value: Partial<ConnectedOrg>) => {
    if (!props.isEdit) logAmplitudeEvent('org_connection_created', { org_name: org?.name, org_id: orgId })
    return value
  }

  return (
    <SimpleForm
      defaultValue={getDefaultValues}
      style={{ marginBottom: 50 }}
      {...props}
      formatSubmitValues={formatSubmitValues}
    >
      <p className={styles.helpText}>{translate('Enter the name of the OpenSolar org you want to connect.')}</p>
      <TextInput
        name="org_name"
        source="org_name"
        label="Organisation"
        style={{ width: 300 }}
        disabled={props.isEdit}
      />
      <BooleanInput name="is_active" source="is_active" label="Enabled" />
      <h2 style={{ marginTop: 40 }}>{translate('Project Sharing Settings')}</h2>
      <p className={styles.helpText}>
        {translate(
          'Only one default can be set at a time. When you set a new default, the previous default will be cleared.'
        )}
      </p>
      <FormSpy subscription={{ values: true }}>
        {(props) => (
          <>
            <BooleanInput
              name="auto_share_projects"
              source="auto_share_projects"
              label="Auto-share new projects to connected org"
              disabled={!props.form.getState().values?.is_active}
            />
            <DependentInput dependsOn="auto_share_projects" value={true}>
              <BooleanInput
                style={{ marginLeft: 20 }}
                name="auto_share_projects_visible"
                source="auto_share_projects_visible"
                label="Make auto-shared projects visible"
                disabled={!props.form.getState().values?.is_active}
              />
            </DependentInput>
          </>
        )}
      </FormSpy>
      <h2 style={{ marginTop: 40 }}>{translate('Sharing Permissions')}</h2>
      <p className={styles.helpText}>
        {translate(
          'These settings manage what Project data is allowed to be shared with another connected organisation. Here you can choose from our predefined sharing templates or create your own in the Control > Teams > Sharing Permissions section.'
        )}
      </p>
      <br />
      <RoleSelectInput
        disabled={undefined}
        predefined_only={undefined}
        role_type={1}
        source="permission"
        label={translate('Sharing Permissions')}
      />
      <br />
      <div style={{ marginRight: 40 }}>
        <ReferenceArrayInput
          label={false}
          source="notify_roles"
          reference="roles"
          optionValueField="url"
          fullWidth={true}
          renderChildrenBeforeRecord
          id={undefined}
          onBlur={undefined}
          onChange={undefined}
          onFocus={undefined}
          validate={undefined}
          parse={undefined}
          format={undefined}
          helperText={translate('Notify these users when %{other_org} shares a project with %{curr_org}', {
            other_org: props.record.org_name || translate('partner org'),
            curr_org: org?.name,
          })}
        >
          <ChipsInput
            label="User Notification"
            source="notify_roles"
            optionText="display"
            optionValue="id"
            fullWidth={true}
            showSpinnerWhenRefreshing
          />
        </ReferenceArrayInput>
      </div>
      <br />
      <div style={{ marginRight: 40 }}>
        <Alert severity="info">
          {translate(
            'For Control sharing settings, you can specify within the Control zone items which organisations they are shared with.'
          )}
        </Alert>

        {props.isEdit && !props.record.is_other_active && <ShareableConnectionLink record={props.record} />}
        {props.isEdit && props.record.is_other_active && !props.record.is_other_enabled && (
          <Alert severity="warning">{translate('This connection has been disabled by the other Organisation.')}</Alert>
        )}
      </div>
    </SimpleForm>
  )
}

const sanitizeRestProps = ({ basePath, className, record, hasShow, hasList, resource, ...rest }: any) => rest
const CustomEditActions = ({
  basePath,
  className,
  data,
  hasShow,
  hasList,
  hasDelete,
  resource,
  deleteLabel,
  onDeleteClick,
  ...rest
}) => (
  <TopToolbar className={className} {...sanitizeRestProps(rest)}>
    {<SharedEntitiesLink record={data} basePath={basePath} variant="contained" color="default" size="large" />}
    {data && hasDelete && (
      <DeleteButton
        name={'editDeleteButton'}
        undoable={false}
        basePath={'/' + resource}
        record={data}
        resource={resource}
        label={deleteLabel}
        onClick={onDeleteClick ? () => onDeleteClick(data) : undefined}
      />
    )}
  </TopToolbar>
)

export const ConnectedOrgEdit = (props: any) => {
  const isAdmin = useSelector(authSelectors.getIsAdmin)
  if (!isAdmin) {
    return <NotFound />
  }

  const onDeleteClick = (record) => {
    logAmplitudeEvent('org_connection_deleted', { org_name: record.org_name })
  }

  return (
    <Edit {...props} hasDelete={isAdmin} actions={<CustomEditActions {...props} onDeleteClick={onDeleteClick} />}>
      <ConnectedOrgForm {...props} isEdit={true} />
    </Edit>
  )
}

export const ConnectedOrgCreate = (props: any) => {
  const isAdmin = useSelector(authSelectors.getIsAdmin)
  if (!isAdmin) {
    return <NotFound />
  }
  return (
    <Create {...props}>
      <ConnectedOrgForm {...props} isEdit={false} />
    </Create>
  )
}

import { useCallback } from 'react'
import { useDataProvider, useNotify } from 'react-admin'

type UpdateStepperStatusArgs = { orderId: number; status: string }

const useUpdateOrderStepperStatus = () => {
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const updateOrderStepperStatus = useCallback(async ({ orderId, status }: UpdateStepperStatusArgs) => {
    await dataProvider
      .CUSTOM_PATCH('inventory_transfers', {
        url: `inventory_transfers/${orderId}/`,
        data: {
          status: status,
        },
      })
      .catch((err) => {
        notify('Failed to update order status', 'error')
      })
      .finally(() => {
        notify('Order status updated', 'success')
      })
  }, [])

  return {
    updateOrderStepperStatus,
  }
}

export default useUpdateOrderStepperStatus

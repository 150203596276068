import { makeStyles } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import { orgSelectors } from 'ducks/orgs'
import { useTranslate } from 'ra-core'
import { useMemo } from 'react'
import ChartistGraph from 'react-chartist'
import { useSelector } from 'react-redux'
import { formatCurrencyWithSymbol } from 'util/misc'

type PropTypes = {
  statusValue: number
  totalPayableAmount: number
  statusLabel: string
}

const useStyles = makeStyles<OpenSolarThemeType, { status? }>((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 30,
  },

  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  },

  payable: {
    fill: theme.white,
  },

  label: {
    color: theme.white,
    margin: 0,
    fontWeight: 300,
    marginBottom: 3,
  },

  labelValue: {
    color: theme.white,
    fontWeight: 500,
    fontSize: '1rem',
    margin: 0,
    lineHeight: 1.5,
  },

  filledAmount: {
    fill: ({ status }) => {
      switch (status) {
        case 'Outstanding':
          return '#2C499D'
        case 'Overdue':
          return '#C11B05'
        case 'Paid':
          return '#277D19'
        case 'In Progress':
          return '#E8C376'
        default:
          return 'transparent'
      }
    },
  },
}))

const StatusPieChart = (props) => {
  const classes = useStyles({ status: props.statusLabel })
  const translate = useTranslate()
  const currencySymbol = useSelector(orgSelectors.getCurrencySymbolFromOrg)

  const startAngle = useMemo(() => {
    const { statusValue, totalPayableAmount } = props
    let percentPaid = totalPayableAmount === 0 ? 0 : statusValue / totalPayableAmount
    return percentPaid * 360
  }, [props.statusValue, props.totalPayableAmount])

  if (!props.statusLabel) return null
  return (
    <div className={classes.wrapper}>
      <div className={classes.chartWrapper}>
        <ChartistGraph
          data={{
            labels: ['Payable', `${props.statusLabel} Amount`],
            series: [
              { value: props.totalPayableAmount - (props.statusValue || 0), className: classes.payable },
              { value: props.statusValue, className: classes.filledAmount },
            ],
          }}
          //@ts-ignore
          options={{ showLabel: false, startAngle: startAngle, height: '135px', width: '135px' }}
          type={'Pie'}
        />
      </div>
      <div className={classes.textWrapper}>
        <div>
          <p className={classes.label}> {translate(`${props.statusLabel} Amount`)}</p>
          <p className={classes.labelValue}>
            {formatCurrencyWithSymbol(props.statusValue, currencySymbol, undefined, 0)}
          </p>
        </div>
        <div>
          <p className={classes.label}>{translate('Total Value')}</p>
          <p className={classes.labelValue}>
            {formatCurrencyWithSymbol(props.totalPayableAmount, currencySymbol, undefined, 0)}
          </p>
        </div>
      </div>
    </div>
  )
}

export default StatusPieChart

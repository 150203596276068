import React, { useCallback } from 'react'
import { OTHER_COMPONENT_FILTER_KEY } from '../otherComponentType/OtherComponentTypeFilterNodeFactory'
import type {
  FilterComponentRendererType,
  FilterGenericPropsType,
  FilterKeysType,
  FilterValuesType,
  OptionGenericType,
} from '../type'

export type FilterOptionsType = OptionGenericType<string>[]

type FilterProps = FilterGenericPropsType & {
  RendererComponent: FilterComponentRendererType<FilterOptionsType>
  filterKey: FilterKeysType
}

const defaultValue = ''

export const isCurrentTypeFilterAvailable = (filterValues: FilterValuesType) => {
  return filterValues[OTHER_COMPONENT_FILTER_KEY] === 'cable' || filterValues[OTHER_COMPONENT_FILTER_KEY] === 'isolator'
}

const CURRENT_TYPE_OPTIONS: FilterOptionsType = [
  { id: 'default', title: 'All', value: defaultValue },
  { id: 'ac', title: 'AC', value: 'ac' },
  { id: 'dc', title: 'DC', value: 'dc' },
]

const CurrentTypeFilter = ({
  filterKey,
  modifiableFilterValues,
  persistentFilterValues,
  allFilterValues,
  setModifiableFilters,
  RendererComponent,
}: FilterProps) => {
  const onSelect = useCallback(
    (value) => {
      setModifiableFilters({ ...modifiableFilterValues, [filterKey]: value })
    },
    [modifiableFilterValues]
  )

  const disabled = !isCurrentTypeFilterAvailable(allFilterValues)

  return (
    <RendererComponent
      id={filterKey}
      defaultValue={defaultValue}
      label={'Current Type'}
      options={CURRENT_TYPE_OPTIONS}
      disabled={disabled}
      onSelect={onSelect}
      value={allFilterValues[filterKey]}
    />
  )
}

export default CurrentTypeFilter

export const styles = {
  inputStyle: {
    width: 256,
  },
  formControlLabelStyle: {
    width: 220,
    justifyContent: 'space-between',
    marginLeft: 0,
    marginRight: 0,
  },
  inputFieldLessWide: {
    width: 160,
  },
  inputFieldMediumWide: {
    width: 200,
  },
  h4: { marginTop: 30 },
  loanWrapper: {
    padding: '10px 20px 20px 20px',
    margin: '20px 0',
  },
  toggleInputHint: {
    fontSize: 12,
    opacity: '0.8',
    display: 'block',
  },
  smallLeft: {
    // float: 'left',
    // marginRight: 10,
    // height: 80,
  },
  mediumLeft: {
    // float: 'left',
    // marginRight: 10,
  },
}

export const elStyles = {
  h4: { marginTop: 30 },
  smallLeft: {
    width: 140,
  },
  mediumLeft: {
    width: 290,
  },
  loan: {
    ComponentWrapper: {
      // padding: '10px 20px 20px 20px',
      // margin: '20px 0',
    },
    ComponentBox: {
      // padding: '10px 20px 20px 20px',
      // borderRadius: 2,
      // margin: '20px 0',
      // boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
    },
    InputField: {
      width: 256,
      marginRight: 20,
    },
    InputFieldLessWide: {
      width: 148,
      marginRight: 20,
    },
    InputFieldLessWideFloatingLabel: {
      whiteSpace: 'nowrap',
    },
    ToggleInputStyle: {
      marginRight: 30,
    },
    ToggleInputLabel: {
      color: 'rgb(77, 77, 77)',
      minWidth: 160,
      maxWidth: 160,
    },
    ToggleInputHint: {
      fontSize: 12,
      opacity: '0.8',
      display: 'block',
    },
  },
}

import type { SSOFlowConfigType } from '../sso/types'

// export const NEARMAP_AUTH_CONFIG: AuthConfigType = {
//   name: 'nearmap',
//   displayName: 'Nearmap',
//   logoUrl: 'nearmap-full-logo.jpeg',
// }

export const NEARMAP_SSO_CONFIG: SSOFlowConfigType = {
  id: 'nearmap-sso',
  type: 'sso',
  displayName: 'Nearmap',
  showIfThemeMatch: 'nearmap',
  name: 'nearmap',
  partner_id: 'nearmap',
  brandingLogo: 'nearmap_on_opensolar_logo_837x152.png',
  logoUrl: 'nearmap-full-logo.jpeg',
}

import { FC } from 'react'
import { SelectInput } from 'react-admin'
import { PRIORITY_CHOICES } from 'resources/projects/choices'
import ConditionalRefFilter from './ConditionalRefFilter'

interface PropTypes {
  source: string
  fieldId: string
}

const Priority: FC<PropTypes> = (props) => {
  const handleChange = (value) => {
    let newValue = {}
    if (value?.conditional !== undefined && value?.itemId) {
      newValue[value.conditional ? 'priority' : 'priority_not'] = value?.itemId
    }
    return newValue
  }
  return (
    <ConditionalRefFilter {...props} onChange={handleChange}>
      <SelectInput
        source={`${props.source}.fields.itemId`}
        variant="outlined"
        choices={PRIORITY_CHOICES}
        margin="none"
        label={false}
      />
    </ConditionalRefFilter>
  )
}

export default Priority

import React from 'react'

const ClockIcon = (props) => (
  <svg {...props} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M49.675 99.9932C21.2211 99.5387 -0.644342 76.9805 0.0145039 48.3075C0.673349 21.1633 23.4859 -0.527338 50.9515 0.0097635C78.4171 0.546865 100.406 23.3117 99.9943 50.9517C99.5825 78.0547 76.6465 100.448 49.675 99.9932ZM49.9221 11.1236C28.4684 11.1236 11.0913 28.4348 11.0913 49.9189C11.0913 71.4442 28.5507 88.9207 50.0044 88.8793C71.4169 88.838 88.8763 71.4029 88.8763 50.0428C88.9175 28.5174 71.4581 11.1236 49.9221 11.1236Z"
      fill="url(#paint0_linear_6249:253523)"
    />
    <path
      d="M49.7574 42.5234C53.4222 38.805 56.5517 35.4998 59.8048 32.3598C62.8108 29.3851 67.3403 30.294 69.0286 34.0537C70.0169 36.2848 69.4404 38.3092 67.8345 39.9619C63.1814 44.7544 58.4871 49.4644 53.6693 54.0917C51.4869 56.1988 48.2338 56.0749 46.0102 53.8852C39.5453 47.5226 33.1215 41.0774 26.7802 34.6322C24.3095 32.1119 24.3507 28.848 26.6978 26.5343C28.839 24.4272 32.298 24.5099 34.6451 26.8235C39.2159 31.3269 43.7042 35.8716 48.1926 40.4163C48.7279 40.9121 49.0985 41.6145 49.7574 42.5234Z"
      fill="url(#paint1_linear_6249:253523)"
    />
    <path
      d="M44.4043 22.1549C44.4455 19.0562 47.0397 16.5773 50.128 16.6186C53.0104 16.6599 55.6047 19.3867 55.5223 22.3201C55.4399 25.2535 52.8046 27.7738 49.8398 27.6911C46.8338 27.6911 44.4043 25.1709 44.4043 22.1549Z"
      fill="url(#paint2_linear_6249:253523)"
    />
    <path
      d="M49.8809 83.2605C46.8749 83.2605 44.3631 80.7402 44.4043 77.7655C44.4455 74.6255 46.7514 72.3532 49.8809 72.3119C53.0516 72.3119 55.5223 74.7495 55.4811 77.8895C55.5223 80.8228 52.9281 83.3018 49.8809 83.2605Z"
      fill="url(#paint3_linear_6249:253523)"
    />
    <path
      d="M83.235 49.9188C83.2761 52.9762 80.8878 55.4138 77.8407 55.4551C74.7111 55.4964 72.1169 53.0175 72.1581 49.9601C72.1581 46.9028 74.5876 44.5478 77.7171 44.5478C80.8466 44.5478 83.235 46.8615 83.235 49.9188Z"
      fill="url(#paint4_linear_6249:253523)"
    />
    <path
      d="M22.0859 44.5478C25.2566 44.5065 27.6449 46.7789 27.6861 49.8775C27.7273 53.0175 25.2978 55.4964 22.1271 55.4964C19.0799 55.4964 16.6092 53.0588 16.6092 50.0428C16.6092 46.9854 18.9975 44.5892 22.0859 44.5478Z"
      fill="url(#paint5_linear_6249:253523)"
    />
    <defs>
      <linearGradient id="paint0_linear_6249:253523" x1="50" y1="0" x2="50" y2="100" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
      <linearGradient id="paint1_linear_6249:253523" x1="50" y1="0" x2="50" y2="100" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
      <linearGradient id="paint2_linear_6249:253523" x1="50" y1="0" x2="50" y2="100" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
      <linearGradient id="paint3_linear_6249:253523" x1="50" y1="0" x2="50" y2="100" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
      <linearGradient id="paint4_linear_6249:253523" x1="50" y1="0" x2="50" y2="100" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
      <linearGradient id="paint5_linear_6249:253523" x1="50" y1="0" x2="50" y2="100" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
    </defs>
  </svg>
)
export default ClockIcon

import classnames from 'classnames'
import { memo, useMemo } from 'react'
import useHdmCreditLimit from 'services/hdm/useHdmCreditLimit'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import CreditLimit from '../CreditLimit'
import HdmConnectAccountAlert from './HdmConnectAccountAlert'
import HdmDisclaimer from './HdmDisclaimer'
import HdmPlaceOrderButton from './HdmPlaceOrderButton'
import OrderSummary from './OrderSummary'

const useSideActionStyles = makeOpenSolarStyles((theme) => ({
  sectionWrapper: {
    padding: 15,
    marginBottom: 15,
  },
  container: {
    boxSizing: 'border-box',
    border: theme.border.default,
    borderRadius: '8px',
    marginBottom: 15,
  },
  actionWrapper: {
    borderRadius: '0 0 8px 8px',
    background: theme.greyLight3,
    padding: 15,
  },
  button: {
    textAlign: 'center',
  },
}))

const PlaceOrderSideAction = ({ handlePlaceOrder }: { handlePlaceOrder(): void }) => {
  const classes = useSideActionStyles()

  const { isLoading, creditLimit } = useHdmCreditLimit()

  const currencySymbol = '£'

  const CreditLimitComponent = useMemo(() => {
    return (
      creditLimit?.credit_balance && (
        <CreditLimit
          loading={isLoading}
          currencySymbol={currencySymbol}
          creditLimit={creditLimit?.credit_balance}
          supplierName="HDM"
        />
      )
    )
  }, [isLoading, creditLimit?.credit_balance])

  return (
    <div>
      {creditLimit && (
        <div className={classnames(classes.sectionWrapper, classes.container)}>{CreditLimitComponent}</div>
      )}
      <div className={classes.container}>
        <div className={classes.sectionWrapper}>
          <OrderSummary currencySymbol={currencySymbol} />
        </div>
        <div className={classes.actionWrapper}>
          <div className={classes.button}>
            {<HdmPlaceOrderButton onClick={handlePlaceOrder} trackSource="side_action" />}
          </div>
          <HdmDisclaimer />
        </div>
      </div>
      <HdmConnectAccountAlert />
    </div>
  )
}

export default memo(PlaceOrderSideAction)

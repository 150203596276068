import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { ComponentVersionsInherit } from 'opensolar-ui'
import { useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { ComponentVersionInfo, LibraryVersions } from './components/LibraryVersions'
import { uilib_menu } from './components/UIComponentList'
import MainUIPage from './MainUIPage'
import Navigator from './Navigator'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
    },
  })
)

const versions = LibraryVersions

const UILibrary = () => {
  const classes = useStyles()

  const [currentVersion, setCurrentVersion] = useState<ComponentVersionInfo | undefined>(
    versions?.find((v) => v.default) || versions?.[0]
  )

  return (
    <div className={classes.container}>
      <ComponentVersionsInherit versions={currentVersion?.versions || {}}>
        <Navigator currentVersion={currentVersion} setCurrentVersion={setCurrentVersion} versions={versions} />
        <Switch>
          <Route exact path={'/ui_library'} render={() => <MainUIPage />} />
          {uilib_menu.map((item) =>
            item.children.map((child) => <Route path={child.route} render={() => child.info} />)
          )}
        </Switch>
      </ComponentVersionsInherit>
    </div>
  )
}

export default UILibrary

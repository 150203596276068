// @ts-nocheck
import { Grid, Grow } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import PinDropOutlinedIcon from '@material-ui/icons/PinDropOutlined'
import { authSelectors } from 'ducks/auth'
import CustomField from 'elements/field/CustomField'
import SharedEntitySelectInput from 'elements/field/SharedEntitySelectInput'
import DependentInput from 'elements/input/DependentInput'
import { debounce } from 'lodash'
import { syncMounting } from 'projectSections/sections/design/systems/tabs/mounting/nativeMounting/calculate'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ReferenceInput, SelectInput, TextInput, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { celsiusToFahrenheit, getMeasurementsFromState, getRange, trimDecimalPlaces } from 'util/misc'
import { formatTemperatureValue, parseTemperatureValue } from 'util/temperature'
import AccordionCard from '../AccordionCard'
import { formSharingParams } from '../orgSharing/util'
import { BusinessNameAndIdentifierFields } from './BusinessNameAndIdentifierFields'
import ProjectTypeRatioButtons from './ProjectTypeRadioButtons'
import { CELL_COVERAGE_CHOICES } from './constants'

const SiteDetailsContent = () => {
  const translate = useTranslate()
  const form = useForm()
  const formState = form.getState()
  const orgId = useSelector(authSelectors.getOrgId)
  const [minTemp, setMinTemp] = useState()
  const [maxTemp, setMaxTemp] = useState()
  const [hasCostingOverride, setHasCostingOverride] = useState(false)
  const isFahrenheit = useSelector((state: any) => getMeasurementsFromState(state) === 'imperial')
  useEffect(() => {
    const parsedMinTemp = parseTemperatureValue(formState?.values?.temperature_min_override, isFahrenheit)
    const parsedMaxTemp = parseTemperatureValue(formState?.values?.temperature_max_override, isFahrenheit)
    setMinTemp(parsedMinTemp)
    setMaxTemp(parsedMaxTemp)
  }, [])
  const mountedRef = useRef()
  const roofType = useFormState().values?.roof_type

  const handleTempOverrideChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    if (name === 'temperature_max_override') {
      setMaxTemp(e.target.value)
    } else {
      setMinTemp(e.target.value)
    }
    const isFieldExist = form.getFieldState(name)
    if (!isFieldExist) {
      form.registerField(name, () => {}, {})
    }
    form.change(name, formatTemperatureValue(e.target.value, isFahrenheit))
  }, [])
  const [default_min_temp, default_max_temp] = formState?.values?.temperature_min_max || []
  //used to animate appearance of business fields. No need to animate if it's commercial on render
  const [wasResidential, setWasResidential] = useState<boolean>(formState?.values?.is_residential)

  useEffect(() => {
    if (formState?.values?.is_residential && !wasResidential) setWasResidential(true)
  }, [formState?.values?.is_residential, wasResidential])

  useEffect(() => {
    // Only show/hide costing override based on the initial form state to ensure that changes are saved
    if (formState?.values?.costing_override) {
      setHasCostingOverride(true)
    }
  }, [])

  const syncMountingDebounced = useCallback(debounce(syncMounting, 2000, { leading: false, trailing: true }), [])

  useEffect(() => {
    // prevents this from firing everytime the component mounts
    if (mountedRef.current) {
      window.editor.getSystems().forEach((system) => {
        syncMountingDebounced(form, system)
      })
    } else {
      mountedRef.current = true
    }
  }, [roofType])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ProjectTypeRatioButtons />
      </Grid>
      <BusinessNameAndIdentifierFields />
      <DependentInput dependsOn="is_residential" resolve={(value: boolean) => value === false}>
        {formState.values.country_iso2 === 'US' && (
          <>
            <Grow in={true} timeout={wasResidential ? 300 : 0}>
              <Grid item xs={6}>
                <CustomField
                  resource="projects"
                  name="federal_income_tax_rate_percentage"
                  source="federal_income_tax_rate_percentage"
                  component={TextInput}
                  fullWidth
                  style={{ width: '100%' }}
                  InputProps={{
                    endAdornment: '%',
                  }}
                />
              </Grid>
            </Grow>
            <Grow in={true} timeout={wasResidential ? 300 : 0}>
              <Grid item xs={6}>
                <CustomField
                  resource="projects"
                  name="state_income_tax_rate_percentage"
                  source="state_income_tax_rate_percentage"
                  component={TextInput}
                  fullWidth
                  style={{ width: '100%' }}
                  InputProps={{
                    endAdornment: '%',
                  }}
                />
              </Grid>
            </Grow>
          </>
        )}
      </DependentInput>
      <Grid item xs={12} sm={6} id="roof_type_grid_input">
        <CustomField
          component={ReferenceInput}
          resource="projects"
          source="roof_type"
          name="roof_type"
          reference="roof_types"
          //@TODO: Refactor into standard field translation and remove label attribute
          label="Roof Type"
          perPage={1000}
          allowEmpty
          fullWidth
          style={{ width: '100%' }}
          filter={{
            fav: 1,
            org_id: orgId,
          }}
          id="roof_type_field"
        >
          <SelectInput
            optionText={(choice: any) => translate(choice?.name)}
            optionValue="url"
            fullWidth
            style={{ width: '100%' }}
            id="roof_type_select_input"
          />
        </CustomField>
      </Grid>
      <Grid item xs={6} sm={3}>
        <CustomField
          resource="projects"
          name="meter_identifier"
          source="meter_identifier"
          //@TODO: Refactor into standard field translation and remove label attribute
          label="Meter Identifier"
          component={TextInput}
          fullWidth
          style={{ width: '100%' }}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <CustomField
          component={SelectInput}
          resource="projects"
          source="number_of_storeys"
          name="number_of_storeys"
          //@TODO: Refactor into standard field translation and remove label attribute
          label="Number of Stories"
          allowEmpty
          choices={getRange(100).map((i) => ({ id: i, name: i ? i : '' }))}
          translateChoice={false}
          fullWidth
          style={{ width: '100%' }}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <CustomField
          component={SelectInput}
          resource="projects"
          source="has_cellular_coverage"
          name="has_cellular_coverage"
          allowEmpty={false}
          choices={CELL_COVERAGE_CHOICES}
          translateChoice={true}
          fullWidth
          style={{ width: '100%' }}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <CustomField
          component={TextInput}
          resource="projects"
          name="wind_region"
          source="wind_region"
          //@TODO: Refactor into standard field translation and remove label attribute
          label="Wind Region"
          fullWidth
          style={{ width: '100%' }}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <CustomField
          component={SelectInput}
          resource="projects"
          source="number_of_phases"
          name="number_of_phases"
          //@TODO: Refactor into standard field translation and remove label attribute
          label="Phases"
          allowEmpty
          choices={getRange(3).map((i) => ({ id: i, name: i ? i : '' }))}
          translateChoice={false}
          fullWidth
          style={{ width: '100%' }}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <CustomField
          component={TextInput}
          resource="projects"
          name="power_factor"
          source="power_factor"
          fullWidth
          style={{ width: '100%' }}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomField
          component={TextInput}
          resource="projects"
          name="site_notes"
          source="site_notes"
          //@TODO: Refactor into standard field translation and remove label attribute
          label="Site Notes"
          fullWidth
          style={{ width: '100%', marginTop: '0px' }}
          multiline={true}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ReferenceInput
          resource="projects"
          reference="project_configurations"
          source="configuration_override"
          //@TODO: Refactor into standard field translation and remove label attribute
          label="Setbacks & Design Settings"
          filter={formSharingParams(orgId, formState?.values, { limit: 1000 })}
          style={{ width: '100%' }}
          sort={{ field: 'title', order: 'ASC' }}
          allowEmpty
        >
          <SelectInputProjectConfiguration
            options={{ SelectProps: { displayEmpty: true } }}
            configurationOverride={formState.values?.configuration_override}
            configurationData={formState.values?.configuration}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </ReferenceInput>
      </Grid>
      {hasCostingOverride && (
        <Grid item xs={12} sm={6}>
          <ReferenceInput
            resource="projects"
            reference="costings"
            source="costing_override"
            //@TODO: Refactor into standard field translation and remove label attribute
            label={translate('Costing Scheme')}
            filter={{ limit: 1000 }}
            style={{ width: '100%' }}
            sort={{ field: 'title', order: 'ASC' }}
            allowEmpty
          >
            <SelectInput optionValue="url" optionText={(choice: any) => choice?.title} />
          </ReferenceInput>
        </Grid>
      )}
      <Grid item xs={6}>
        <TextField
          value={minTemp === null || minTemp === undefined ? '' : minTemp}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTempOverrideChange(e, 'temperature_min_override')}
          InputProps={{
            endAdornment: isFahrenheit ? '°F' : '°C',
          }}
          InputLabelProps={{ shrink: true }}
          placeholder={`${translate('default to')} ${
            isFahrenheit
              ? trimDecimalPlaces(celsiusToFahrenheit(default_min_temp), 1)
              : trimDecimalPlaces(default_min_temp, 1)
          }`}
          fullWidth
          label={translate('Min Temperature Override')}
          style={{ marginTop: '16px', marginRight: '30px', marginBottom: '16px' }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          value={maxTemp === null || maxTemp === undefined ? '' : maxTemp}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTempOverrideChange(e, 'temperature_max_override')}
          InputProps={{
            endAdornment: isFahrenheit ? '°F' : '°C',
          }}
          InputLabelProps={{ shrink: true }}
          placeholder={`${translate('default to')} ${
            isFahrenheit
              ? trimDecimalPlaces(celsiusToFahrenheit(default_max_temp), 1)
              : trimDecimalPlaces(default_max_temp, 1)
          }`}
          fullWidth
          label={translate('Max Temperature Override')}
          style={{ marginTop: '16px', marginRight: '30px', marginBottom: '16px' }}
        />
      </Grid>
    </Grid>
  )
}

const SiteDetailsAccordion = () => {
  const translate = useTranslate()
  return (
    <AccordionCard
      name={'site-details'}
      title={translate('Site Details')}
      titleIcon={PinDropOutlinedIcon}
      content={SiteDetailsContent}
      lazyRender={true}
    />
  )
}
export default SiteDetailsAccordion

const SelectInputProjectConfiguration = ({ configurationOverride, configurationData, ...props }) => {
  const translate = useTranslate()
  // Only populate the default title if we are not using an override because the title will be available
  // in project.configuration, populated by the back-end.
  // Unfortunately we cannot know the default configuration if we are using an override unless we calculated this
  // and sent it along in the api response as an extra field, which would be very wasteful, so we will just show
  // "Default" when we are using an override and not include the default title.
  const defaultDesignSettingsTitle = !configurationOverride ? configurationData?.title : null
  const emptyText = defaultDesignSettingsTitle
    ? translate('Default') + ` (${defaultDesignSettingsTitle})`
    : translate('Default')

  return (
    <div>
      <SharedEntitySelectInput
        allowEmpty={true}
        emptyValue=""
        emptyText={<i style={{ color: '#aaa' }}>{emptyText}</i>}
        optionText="title"
        optionValue="url"
        {...props}
      />
      {/* {React.createElement(component, {
        allowEmpty: true,
        emptyValue: '',
        emptyText: <i style={{ color: '#aaa' }}>{emptyText}</i>,
        optionText: 'title',
        optionValue: 'url',
        ...props,
      })} */}
    </div>
  )
}

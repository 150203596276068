import { ConditionFactory, SystemContext } from 'Designer/designRules/types'
import { BaseConditionDef } from '.'
import { checkScope, CheckScopeDef } from '../utils'

export type CheckSystemPropDef = BaseConditionDef &
  CheckScopeDef & {
    type: 'check-system-prop'
  }

export const condition_checkSystemProp: ConditionFactory<CheckSystemPropDef> = (def) => {
  return {
    getProjectFields: () => [],
    getStudioSignals: () => ['system-changed', 'system-selected'],
    check: (context: SystemContext) => {
      return checkScope(context.system, def)
    },
  }
}

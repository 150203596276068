import { orgSelectors } from 'ducks/orgs'
import { usePublicFeatureConfig } from 'hooks/usePublicFeatureConfig'
import { omit } from 'lodash'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { ComponentTypes } from 'types/selectComponent'
import { DISTRIBUTOR_BRAND_FILTER_KEY } from '../distributorBrand/DistributorBrandFilterNodeFactory'
import type { ManufacturerFilterOptionsType, ManufacturerFilterProps } from './ManufacturerFilter'
import { EXHIBITOR_FILTER_KEY, MANUFACTURER_FILTER_KEY, defaultManufacturerOption } from './ManufacturerFilter'

export const getConfigByComponentType = ({
  manufacturerConfig,
  componentType,
}: {
  manufacturerConfig
  componentType: ComponentTypes
}) => {
  switch (componentType) {
    case 'module':
      return manufacturerConfig.modules
    case 'inverter':
      return manufacturerConfig.inverters
    case 'battery':
      return manufacturerConfig.batteries
    case 'other':
      return manufacturerConfig.others
  }
}

const getManufacturerOptions = ({
  configs,
  orgIso2,
}: {
  configs
  orgIso2: string | undefined
}): ManufacturerFilterOptionsType | undefined => {
  if (!configs || configs.length === 0) {
    return undefined
  }

  const options: ManufacturerFilterOptionsType = configs.reduce((result, option) => {
    if (option.include_country && !option.include_country.includes(orgIso2)) {
      return result
    }

    result.push({ title: option.title, value: option.value, id: option.value })

    return result
  }, [])

  if (options.length === 0) {
    return undefined
  }

  options.sort((a, b) => (a.title > b.title ? 1 : -1))

  options.unshift(defaultManufacturerOption)

  return options
}

// TODO: fix the hack to parse value by split.
const FeatureConfigManufacturerFilter = ({
  disabled,
  modifiableFilterValues,
  persistentFilterValues,
  allFilterValues,
  setModifiableFilters,
  RendererComponent,
  componentType,
}: ManufacturerFilterProps) => {
  const manufacturerConfig = usePublicFeatureConfig('manufacturer_filter')
  const orgIso2 = useSelector(orgSelectors.getOrgIso2)

  const manufacturerOptions =
    manufacturerConfig === undefined
      ? undefined
      : getManufacturerOptions({
          configs: getConfigByComponentType({ manufacturerConfig, componentType }),
          orgIso2,
        })
  const onSelect = useCallback(
    (value) => {
      // Always clear first
      const newFilterValues = omit({ ...modifiableFilterValues }, [
        EXHIBITOR_FILTER_KEY,
        MANUFACTURER_FILTER_KEY,
        DISTRIBUTOR_BRAND_FILTER_KEY,
      ])

      if (value === '') {
      } else if (value.includes('manufacturer_')) {
        const manufacturerId = value.split('manufacturer_')[1]
        newFilterValues[MANUFACTURER_FILTER_KEY] = Number(manufacturerId)
      } else if (value.includes('exhibitor_')) {
        const exhibitorId = value.split('exhibitor_org_id_')[1]
        newFilterValues[EXHIBITOR_FILTER_KEY] = Number(exhibitorId)
      }
      setModifiableFilters(newFilterValues)
    },
    [modifiableFilterValues]
  )

  if (manufacturerOptions === undefined) {
    return null
  }

  const selectedManufacturer =
    allFilterValues[MANUFACTURER_FILTER_KEY] !== undefined
      ? `${MANUFACTURER_FILTER_KEY}_${allFilterValues[MANUFACTURER_FILTER_KEY]}`
      : ''
  const selectedExhibitor =
    allFilterValues[EXHIBITOR_FILTER_KEY] !== undefined
      ? `${EXHIBITOR_FILTER_KEY}_${allFilterValues[EXHIBITOR_FILTER_KEY]}`
      : ''

  return (
    <RendererComponent
      id={'manufacturer_filter'}
      defaultValue={''}
      label={'Manufacturer'}
      options={manufacturerOptions}
      disabled={!!disabled}
      onSelect={onSelect}
      value={selectedManufacturer || selectedExhibitor}
    />
  )
}

export default FeatureConfigManufacturerFilter

import { FormControl, FormLabel, TextField } from '@material-ui/core'
import { PERFORMANCE_CALCULATORS } from 'constants/calculators'
import InfoTooltip from 'elements/tooltip/InfoTooltip'
import { useTranslate } from 'ra-core'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useForm } from 'react-final-form'
import { defaultPeriod, defaultScheduledRate } from 'resources/utilityTariffs/common'
import { UtilityTariff } from 'resources/utilityTariffs/tariffInputs/pricingTable/types'
import { StudioSystemType } from 'types/global'
import { ProjectType } from 'types/projects'
import { currencySymbolForCountry, numberWithCommas } from 'util/misc'
import { useStyles } from './styles'

interface PropTypes {
  project: ProjectType
  system: StudioSystemType
  calcsValid: boolean
}

const parseJsonSafely = (data: any): UtilityTariff => {
  if (typeof data === 'string') {
    return JSON.parse(data)
  } else {
    return data
  }
}

const SystemDetailPanel: React.FC<PropTypes> = ({ system, project, calcsValid }) => {
  const form = useForm()
  const classes = useStyles()
  const translate = useTranslate()
  const [systemEfficiency, setSystemEfficiency] = useState(0)
  const [electricityPrice, setElectricityPrice] = useState(0)
  const [annualOutput, setAnnualOutput] = useState(0)

  const currencySymbol = currencySymbolForCountry(project.country_iso2)
  const formValues = form.getState().values
  var customTariff = parseJsonSafely(formValues.utility_tariff_current_custom)
  const calculator = PERFORMANCE_CALCULATORS.find((x) => x.id === system.calculator)?.name || 'unknown'

  useEffect(() => {
    //if Germany initialise with config data else keep the config value
    setElectricityPrice(customTariff?.energy_charges?.[0].tou_periods?.[0].blocks?.[0].value || 0)
  }, [customTariff])

  useEffect(() => {
    setSystemEfficiency(system?.system_efficiency_override || 0)
  }, [system?.system_efficiency_override])

  useEffect(() => {
    setAnnualOutput(system?.output?.annual || 0)
  }, [system?.output?.annual])

  const onChangeSystemEff = (event: ChangeEvent<HTMLTextAreaElement>) => {
    let value = parseFloat(event.target.value)
    window.editor.execute(new window.SetValueCommand(system, 'system_efficiency_override', value))
    window.editor.signals.objectChanged.dispatch(system)
    setSystemEfficiency(value)
  }

  const onChangeElecPrice = (event: ChangeEvent<HTMLTextAreaElement>) => {
    let value = parseFloat(event.target.value)
    try {
      customTariff.energy_charges[0].tou_periods[0].blocks[0].value = value
    } catch {
      customTariff.energy_charges = [defaultScheduledRate(defaultPeriod(false, 'energy_charges'), false)]
    }

    form.change('utility_tariff_current_custom', { ...customTariff })
    setElectricityPrice(value)
  }

  return (
    <div className={classes.rows}>
      <FormControl size="small" className={classes.row}>
        <FormLabel className={classes.rowLabel + ' ' + classes.fill}>{translate('System efficiency')}</FormLabel>
        <TextField
          className={classes.rowValue}
          type="number"
          variant="outlined"
          size="small"
          label={null}
          inputProps={{ max: '100', min: '0' }}
          value={systemEfficiency}
          onChange={onChangeSystemEff}
          InputProps={{
            endAdornment: '%',
          }}
        />
      </FormControl>
      <FormControl size="small" className={classes.row}>
        <FormLabel className={classes.rowLabel + ' ' + classes.fill}>{translate('Electricity price')}</FormLabel>
        <TextField
          className={classes.rowValue}
          type="number"
          variant="outlined"
          size="small"
          label={null}
          inputProps={{ min: '0' }}
          value={electricityPrice}
          onChange={onChangeElecPrice}
          InputProps={{
            endAdornment: currencySymbol + `/kWh`,
          }}
        />
      </FormControl>
      <FormControl size="small" className={classes.row}>
        <FormLabel className={classes.rowLabel + ' ' + classes.fill}>{translate('Annual Output')}</FormLabel>
        <InfoTooltip title={translate(`Calculated using %{calcName} Calculator`, { calcName: calculator })} />
        <TextField
          className={classes.rowValue}
          disabled
          size="small"
          label={null}
          value={calcsValid ? numberWithCommas(annualOutput, true) : translate('Calculating...')}
          InputProps={{
            className: classes.fakeEnabled,
            endAdornment: 'kWh',
          }}
        />
      </FormControl>
      <FormControl size="small" className={classes.row}>
        <FormLabel className={classes.rowLabel + ' ' + classes.fill}>{translate('Annual savings (max)')}</FormLabel>
        <InfoTooltip title={translate('Assuming 100% self consumption')} />
        <TextField
          className={classes.rowValue}
          disabled
          size="small"
          label={null}
          value={
            calcsValid ? currencySymbol + (annualOutput * electricityPrice).toFixed(2) : translate('Calculating...')
          }
          InputProps={{
            className: classes.fakeEnabled,
          }}
        />
      </FormControl>
    </div>
  )
}

export default SystemDetailPanel

import { Dialog, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import Button from 'elements/proUXButtons/ProUXButton'
import { useTranslate } from 'ra-core'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { RoleType } from 'types/roles'
import { getIsValidEmail, reloadEntireApp } from 'util/misc'

type PropTypes = {
  isOpen: boolean
  close: () => void
}

const useStyles = makeStyles({
  root: {},
  checked: {},
  errorText: {
    color: 'red',
    textAlign: 'center',
    width: '100%',
  },
})

const SungageAccountDialog: React.FunctionComponent<PropTypes> = (props) => {
  const orgId = useSelector(authSelectors.getOrgId)

  const role: RoleType = useSelector(authSelectors.getCurrentRole) as RoleType
  const roleEmail: string | undefined | null = getIsValidEmail(role?.display) ? role.display : role.email

  const [loading, setLoading] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const [key, setKey] = useState<string | undefined>(undefined)
  const [email, setEmail] = useState<string | undefined | null>(roleEmail)

  useEffect(() => {
    if (errorMsg) {
      logAmplitudeEvent('sungage_account_dialog_error', { error: errorMsg })
    }
  }, [errorMsg])

  const classes = useStyles()
  const translate = useTranslate()

  const save = () => {
    if (!key) {
      setErrorMsg('API key is required')
      return
    } else if (!email) {
      setErrorMsg('Email is required')
      return
    } else if (!getIsValidEmail(email)) {
      setErrorMsg('Invalid Email')
      return
    }

    setLoading(true)
    const restClientInstance = restClient(window.API_ROOT + '/api')
    restClientInstance('CUSTOM_PUT', 'custom', {
      url: 'orgs/' + orgId + '/roles/' + role.id + '/connect_sungage/',
      data: {
        key,
        email,
      },
    })
      .then((response: any) => {
        if (response.data?.success) {
          logAmplitudeEvent('sungage_account_connected', {})
          let url = '/#/payment_options'
          if (response?.data?.products_added) {
            url += `?products_added=${response.data.products_added}&partner=Sungage`
          }
          reloadEntireApp(url)
        } else {
          setErrorMsg('Incorrect API key and/or email')
        }
      })
      .catch((error: any) => {
        setErrorMsg(`Unable to connect your Sungage account`)
        console.log('error', error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Dialog open={props.isOpen} style={{ textAlign: 'center' }} onClose={props.close}>
      <DialogTitle>{translate(`Connect your Sungage Account`)}</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <p>
              Enter your Sungage Financial API key below to connect your account. If you don't have these credentials
              you can get them by reaching out to Sungage Financial directly.
            </p>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="sungage_api_key"
              label="Sungage API key"
              fullWidth={true}
              value={key}
              onChange={(e) => setKey(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="sungage_email"
              label="Email of your Sungage user"
              fullWidth={true}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          {errorMsg && (
            <Grid item xs={12}>
              <p className={classes.errorText}>{errorMsg}</p>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            style={{ paddingTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
          >
            <Button label="Connect Your Account" type="primary" onClick={save} loading={loading} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
export default SungageAccountDialog

import CloseIcon from '@material-ui/icons/ClearOutlined'
import type { OpenSolarThemeType } from 'Themes'
import { IconButton } from 'opensolar-ui'
import React, { memo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  dialogTitle: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 600,
  },
  icon: {
    width: 20,
    height: 20,
  },
}))

const HardwareSelectorHeader = memo(
  ({ title, handleClose }: { title: string | React.ReactNode; handleClose(): void }) => {
    const classes = useStyles()
    return (
      <div className={classes.dialogTitle}>
        <div>{title}</div>
        <IconButton className={classes.icon} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
    )
  }
)

export default HardwareSelectorHeader

import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import React, { useCallback, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  formControl: {
    width: '100%',
    borderRadius: 'inherit',
    backgroundColor: theme.greyLight3,
  },
  selectionHeader: {
    background: theme.greyLight2,
  },
  select: {
    borderRadius: 'inherit',
    fontSize: theme.typography.subtitle2.fontSize,
  },
}))

export type SelectorOptionComponentType = React.FC<{ closeDropdown(): void }>

const Selector = <T,>({
  onOpen,
  onClose,
  isLoading,
  value,
  optionComponents,
  renderValue,
}: {
  onOpen?(): void
  onClose?(): void
  isLoading: boolean
  value: T
  optionComponents: SelectorOptionComponentType[]
  renderValue(value): React.ReactNode
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const classes = useStyles()
  const handleOpen = useCallback(() => {
    logAmplitudeEvent('hardware_quantity_field_selector', {
      action: 'clicked',
      context: 'open',
    })
    onOpen?.()
    setOpen(true)
  }, [])
  const handleClose = useCallback(() => {
    onClose?.()
    setOpen(false)
    logAmplitudeEvent('hardware_quantity_field_selector', {
      action: 'clicked',
      context: 'close',
    })
  }, [])

  if (isLoading) {
    return <Skeleton />
  }

  return (
    <FormControl variant="outlined" size="small" className={classes.formControl}>
      <Select
        open={open}
        className={classes.select}
        value={value}
        onOpen={handleOpen}
        onClose={handleClose}
        renderValue={renderValue}
      >
        {optionComponents.map((Component, index) => {
          return <Component closeDropdown={handleClose} key={index} />
        })}
      </Select>
    </FormControl>
  )
}

export default Selector

import lodash from 'lodash'

export function resolveScope<T extends object>(object: T, replace: Record<string, string>): T {
  lodash.forEach(object, (value, key) => {
    if (!lodash.isObject(value)) return

    const valScope = lodash.get(value, 'scope')
    for (var token in replace) {
      var path = replace[token]
      if (valScope && typeof valScope === 'string' && valScope.indexOf(token) === 0) {
        value['scope'] = path + valScope.substring(token.length)
        if (window.debugCustomForms) console.debug('Update scope: ', valScope, value['scope'])
      }
    }
    resolveScope(value, replace)
  })
  return object
}

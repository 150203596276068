import { makeStyles, Paper } from '@material-ui/core'
import { PeopleAltOutlined } from '@material-ui/icons'
import { authSelectors } from 'ducks/auth'
import CheckboxSelectField from 'elements/field/CheckboxSelectField'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import { Button } from 'opensolar-ui'
import { default as React, useEffect, useMemo, useState } from 'react'
import { useNotify, useRefresh, useTranslate, useUpdateMany } from 'react-admin'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(
  (theme) => ({
    button: {
      // margin: '0 2px 0 0',
      margin: '0 5px',
    },
    shareWithPaper: {
      padding: '5px 20px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    shareWithInputWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    shareWithInput: {
      width: 200,
      marginRight: 20,
    },
    buttonWrapper: {
      margin: '0px 10px',
    },
  }),
  { name: 'BulkSharingButton' }
)

type PropTypes = {
  selectedIds: number[]
  resource: string
  setSharingExpanded: (val: boolean) => void
}

type OptionType = {
  id: number
  name: string
}

const BulkSharingButton: React.FC<PropTypes> = (props) => {
  const [sharingOptions, setSharingOptions] = useState<OptionType[]>([])
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [shareWithOrgIds, setShareWithOrgIds] = useState<number[]>([])
  const [unshareWithOrgIds, setUnshareWithOrgIds] = useState<number[]>([])
  const [sentRequest, setSentRequest] = useState<boolean>(false)
  const emptyValues = useMemo(() => shareWithOrgIds?.length === 0 && unshareWithOrgIds?.length === 0, [
    shareWithOrgIds,
    unshareWithOrgIds,
  ])

  const translate = useTranslate()
  const classes = useStyles()
  const notify = useNotify()
  const refreshList = useRefresh()

  const connectedOrgs = useSelector(authSelectors.getActiveConnectedOrgs)
  const [updateMany, { data, loading, error, total }] = useUpdateMany(
    props.resource,
    //@ts-ignore
    props.selectedIds,
    {
      share_with_ids: shareWithOrgIds,
      unshare_with_ids: unshareWithOrgIds,
    },
    {
      views: 0,
    }
  )

  useEffect(() => {
    props.setSharingExpanded(isExpanded)
  }, [isExpanded])

  // I'm not able to get react-admins onSuccess param for useUpdateMany to fire, so this will take its place
  useEffect(() => {
    if (loading && !sentRequest) {
      setSentRequest(true)
    }
    if (sentRequest && !loading && !error?.body?.message) {
      let message = `${props.selectedIds.length} ${props.selectedIds.length > 1 ? 'records have' : 'record has'} been `
      if (!!shareWithOrgIds?.length && !!unshareWithOrgIds?.length) {
        message += 'shared/unshared.'
      } else if (!!shareWithOrgIds?.length) {
        message += 'shared.'
      } else {
        message += 'unshared.'
      }
      notify(message, 'success')
      setIsExpanded(false)
      setSentRequest(false)
      refreshList()
    }
  }, [loading, sentRequest, error])

  useEffect(() => {
    if (error?.body?.message) {
      notify(error.body.message, 'warning')
    }
  }, [error?.body?.message])

  useEffect(() => {
    if (connectedOrgs?.length) {
      const options = connectedOrgs.map((org) => {
        return { id: org.org_id, name: org.org_name }
      })
      setSharingOptions(options)
    }
  }, [connectedOrgs])

  const handleClick = () => {
    setIsExpanded(true)
  }
  const handleShareWithChange = (item) => {
    const itemId = item?.id
    if (unshareWithOrgIds?.includes(itemId)) setUnshareWithOrgIds(unshareWithOrgIds.filter((x) => x !== itemId))
    setShareWithOrgIds(
      shareWithOrgIds?.includes(itemId) ? shareWithOrgIds.filter((x) => x !== itemId) : [...shareWithOrgIds, itemId]
    )
  }

  const handleUnshareChange = (item) => {
    const itemId = item?.id
    if (shareWithOrgIds?.includes(itemId)) setShareWithOrgIds(shareWithOrgIds.filter((x) => x !== itemId))
    setUnshareWithOrgIds(
      unshareWithOrgIds?.includes(itemId)
        ? unshareWithOrgIds.filter((x) => x !== itemId)
        : [...unshareWithOrgIds, itemId]
    )
  }

  const onCancel = () => {
    setIsExpanded(false)
    setUnshareWithOrgIds([])
    setShareWithOrgIds([])
  }

  const onSave = () => {
    if (emptyValues) {
      notify(translate('Please select an organization to share or unshare with', 'warning'))
    } else if (!props.selectedIds?.length) {
      notify(translate('Please select at least one record to share or unshare', 'warning'))
    } else {
      try {
        updateMany()
      } catch (ex) {
        console.log('ex', ex)
      }
    }
  }

  if (!connectedOrgs || !connectedOrgs.length) return null
  return isExpanded ? (
    <Paper className={classes.shareWithPaper}>
      <CheckboxSelectField
        label={'Share With...'}
        selected={shareWithOrgIds}
        choices={sharingOptions}
        className={classes.shareWithInput}
        displayLabel={'organisations'}
        onChange={handleShareWithChange}
      />
      <CheckboxSelectField
        label={'Unshare With...'}
        selected={unshareWithOrgIds}
        choices={sharingOptions}
        className={classes.shareWithInput}
        displayLabel={'organisations'}
        onChange={handleUnshareChange}
      />
      <div className={classes.buttonWrapper}>
        <ProUXButton label="Cancel" onClick={onCancel} type="secondary" />
      </div>
      <div className={classes.buttonWrapper}>
        <ProUXButton label="Save Changes" onClick={onSave} type="primary" disabled={emptyValues} loading={loading} />
      </div>
    </Paper>
  ) : (
    <Button
      className={classes.button}
      startIcon={<PeopleAltOutlined />}
      variant="contained"
      color="default"
      onClick={handleClick}
      style={{ margin: '0 5px' }}
    >
      <span>{translate('Manage Sharing')}</span>
    </Button>
  )
}

export default BulkSharingButton

import React from 'react'

const styleForButtonType = (buttonType, myeStyles) => {
  if (buttonType === 'highlight') {
    return {
      backgroundColor: '#F0F0F0',
      border: '1px solid #dfdfdf',
    }
  } else if (buttonType === 'not-selected') {
    return {
      backgroundColor: '#dcdcdc',
      border: '1px solid #dcdcdc',
      color: '#000000',
    }
  } else if (buttonType === 'featured-highlight') {
    return {
      backgroundColor: myeStyles.highlightColor,
      border: 'none',
      color: myeStyles.textColorOnHighlight,
      marginTop: 10,
    }
  } else if (buttonType === 'featured-highlight-disabled') {
    return {
      backgroundColor: '#dcdcdc',
      border: 'none',
      color: '#000000',
      marginTop: 10,
    }
  } else if (buttonType === 'action') {
    return {
      backgroundColor: '#F0F0F0',
      border: '1px solid #dfdfdf',
    }
  } else {
    throw new Error('invalid buttonType: ' + buttonType)
  }
}

const ButtonAction = ({ label, action, buttonType, myeStyles, acceptButtonStyle }) => (
  <button
    className="action wide"
    style={acceptButtonStyle || styleForButtonType(buttonType, myeStyles)}
    onClick={(e) => {
      e.preventDefault()
      action()
    }}
  >
    {label}
  </button>
)

export default ButtonAction

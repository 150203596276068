import { makeStyles } from '@material-ui/core/styles'
import { adsSelectors } from 'ducks/ads'
import { UiSwitch } from 'elements/UiSwitch'
import { useState } from 'react'
import { useLogout, usePermissions } from 'react-admin'
import { useSelector } from 'react-redux'
import { useFeatureFlag } from 'util/split'
import AddressSearch from './AddressSearch'
import HomeAdCluster from './HomeAdCluster'
import Logo from './Logo'
import SidePanel from './SidePanel'
import TourBanner from './TourBanner'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    background: 'rgba(255,255,255)',
    position: 'fixed',
    width: '100%',
    height: 'calc(100vh - 50px)',
    [theme.breakpoints.down('xs')]: { height: 'calc(100vh - 100px)' },

    display: 'flex',
  },
  leftContainer: {
    textAlign: 'center',
    position: 'relative',
    flexGrow: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  rightContainer: {
    maxWidth: 366,
    height: '100%',
    // set same zIndex as AddressSearch Input
    // ensure side panel on top of AddressSearch in mobile layout
    zIndex: 3,
  },
  bottomActions: {
    position: 'absolute',
    textAlign: 'center',
    bottom: 0,
    width: '100%',
    pointerEvents: 'none',
  },
}))

export const PageHome = () => {
  const classes = useStyles()
  const logout = useLogout()
  const enableAdsFeatureFlag = useFeatureFlag('ads', 'on')
  const enableAdsForOrg = useSelector(adsSelectors.getEnableAdsForOrg)
  const enableAds = enableAdsFeatureFlag && enableAdsForOrg
  const [bannerShown, setBannerShown] = useState(false)

  // Special handling of permissions instead of using built-in useAuthenticated because this also handles redirecting
  // from / so we want to avoid spamming not-logged-in messages
  const { loaded, permissions } = usePermissions()

  if (!loaded) {
    return null
  } else if (permissions && permissions.indexOf('is_pro') === -1) {
    //use logout here to record the query so fuji tour can start automatically after successful login
    logout()
    return null
  }
  return (
    <UiSwitch uiKey="homepage">
      <div className={classes.wrapper}>
        <div className={classes.leftContainer}>
          <Logo />
          <AddressSearch />
          {!bannerShown && <TourBanner />}
          {enableAds && <HomeAdCluster onSetShown={setBannerShown} />}
        </div>
        <div className={classes.rightContainer}>
          <SidePanel />
        </div>
        <div className={classes.bottomActions}></div>
      </div>
    </UiSwitch>
  )
}

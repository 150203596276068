import { useCallback, useMemo } from 'react'
import type {
  ConfigurableFilterOptionsType,
  FilterGenericPropsType,
  FilterKeysV2Type,
  OptionGenericType,
  RendererAdditionalProps,
} from '../typeV2'

type BasicFilterProps<OptionValue, RendererValue> = FilterGenericPropsType &
  ConfigurableFilterOptionsType<OptionValue, RendererValue> & {
    label: string
    rendererAdditionalProps?: RendererAdditionalProps<OptionValue, RendererValue>
    options: OptionGenericType<OptionValue>[]
    defaultSelectedOptionValue?: OptionValue | Set<OptionValue>
    filterKey: FilterKeysV2Type
  }

const BasicFilter = <OptionValue, RendererValue>({
  filterKey,
  disabled,
  options,
  defaultSelectedOptionValue,
  rendererAdditionalProps,
  label,
  rendererComponent: RendererComponent,
  setModifiableFilters,
  modifiableFilterValues,
  allFilterValues,
  formatter,
  sideEffect,
}: BasicFilterProps<OptionValue, RendererValue>) => {
  const onSelect = useCallback(
    (value, selectedOption) => {
      const finalValue = formatter
        ? formatter.format({ filterValues: modifiableFilterValues, value, selectedOption })
        : value
      const filterValues = { ...modifiableFilterValues, [filterKey]: finalValue }
      if (!!sideEffect) {
        setModifiableFilters(sideEffect({ filterValues, modifiableFilterValues, value, selectedOption }))
      } else {
        setModifiableFilters(filterValues)
      }
    },
    [modifiableFilterValues, setModifiableFilters, sideEffect, formatter?.format]
  )
  const selectedValue = useMemo(() => {
    return formatter ? formatter.parse(allFilterValues[filterKey]) : allFilterValues[filterKey]
  }, [formatter?.parse, allFilterValues[filterKey]])

  const defaultValue = useMemo(
    () => (formatter ? formatter.parse(defaultSelectedOptionValue) : defaultSelectedOptionValue) as RendererValue,
    []
  )

  return (
    <RendererComponent
      id={filterKey}
      defaultValue={defaultValue}
      additionalProps={rendererAdditionalProps}
      label={label}
      options={options}
      disabled={!!disabled}
      onSelect={onSelect}
      value={selectedValue}
    />
  )
}

export default BasicFilter

import { authSelectors } from 'ducks/auth'
import EditOrImportButton from 'elements/button/EditOrImportButton'
import { List } from 'elements/react-admin/List'
import ListActions from 'elements/react-admin/ListActions'
import inflection from 'inflection'
import React from 'react'
import { Create, Datagrid, Edit, NotFound, TextField, useTranslate } from 'react-admin'
import { connect } from 'react-redux'
import { RootState } from 'types/state'
import { duplicate as duplicateAction } from '../../actions/restActions'
import CommissionsForm from './CommissionsForm'

export const _CommissionsList = ({
  accessRights: { allowView, allowCreate, allowEdit, allowDelete },
  ...props
}: any) => {
  const translate = useTranslate()
  const resourceName = translate(`resources.${props.resource}.name`, {
    smart_count: 1,
    _: inflection.humanize(inflection.singularize(props.resource)),
  })

  return (
    <List
      {...props}
      extraCreateButtonLabel={translate('ra.page.empty_new', { name: resourceName })}
      perPage={20}
      actions={<ListActions hasArchived={true} />}
      hasSearch={true}
      hasCreate={allowCreate}
    >
      <Datagrid>
        <TextField source="title" label="Title" />
        {/* <TextField source="description" /> */}

        <EditOrImportButton
          sortable={false}
          source="actions"
          label="Edit"
          resource="commissions"
          allowDuplicate={false}
          allowEdit={allowEdit}
          allowDelete={allowDelete}
          org_id={props.org_id}
        />
      </Datagrid>
    </List>
  )
}

export const CommissionsEdit = ({
  accessRights: { allowView, allowCreate, allowEdit, allowDelete },
  ...props
}: any) => {
  if (!allowEdit) {
    return <NotFound />
  }
  return (
    <Edit {...props} hasDelete={allowDelete}>
      <CommissionsForm {...props} disabled={!allowEdit} />
    </Edit>
  )
}

export const CommissionsCreate = ({
  accessRights: { allowView, allowCreate, allowEdit, allowDelete },
  ...props
}: any) => {
  if (!allowCreate) {
    return <NotFound />
  }
  return (
    <Create {...props}>
      <CommissionsForm {...props} />
    </Create>
  )
}

export const CommissionsList = connect(
  (state: RootState) => {
    return {
      org_id: authSelectors.getOrgId(state),
    }
  },
  { duplicate: duplicateAction }
)(_CommissionsList)

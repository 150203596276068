import React from 'react'
import { RoleTitle } from '../roles'
import { RoleEdit } from '../roles/Roles'

export default {
  edit: RoleEdit,
  options: {
    edit: {
      title: <RoleTitle />,
      subtitle: 'Manage details and permissions for your team member.',
      breadCrumb: 'Edit Team Member',
    },
  },
}

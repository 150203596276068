import { CreateOrUpdateProjectFlow, OSSDK } from 'opensolar-sdk'

/**
 * Note: these flows are built in a way that they could be used outside
 * of the SDK. This allows them to be used as examples also.
 */

export function createOrUpdateProject(sdk: OSSDK) {
  return async (payload: CreateOrUpdateProjectFlow): Promise<boolean> => {
    // return whether the project was created or loaded
    let isCreated = false

    if (!payload.identifier) throw new Error('Project identifier is required')

    console.log('Update or create project: ', payload)
    // Load projects by `identifier`
    const results = await sdk.resources.projects.list({
      pagination: { perPage: 1 },
      filter: { identifier: payload.identifier },
    })

    console.log('Project list response: ', results)
    if (results?.data?.length) {
      // If project already exists, load it
      const project = results.data[0]
      console.log('Project found, loading...', project)
      await sdk.route.goto(`/projects/${project.id}/design`)
    } else {
      // Otherwise, create a new project
      console.log('Project not found, creating empty project...')

      isCreated = true

      // Approach 1: Load studio before creating the project using projectId="new".
      // Not suitable when premiumim imagery activations is required.
      // await sdk.route.goto('/projects/new/info')
      // await sdk.route.goto('/projects/new/design')

      // Approach 2: Create project then redirect to studio
      const projectData = { identifier: payload.identifier, ...payload.fields }
      await sdk.resources.projects.create({ data: projectData }).then((response) => {
        const projectId = response.data.id
        console.log('Project created, loading...', projectId)
        return sdk.route.goto(`/projects/${projectId}/design`)
      })
    }

    // Populate project fields
    await sdk.project_form.updateFields({ identifier: payload.identifier, ...payload.fields })

    return isCreated
  }
}

import { Dialog, makeStyles, Tooltip } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import { IconButton } from 'opensolar-ui'
import PropTypes from 'prop-types'
import React from 'react'
import {
  Edit,
  ImageField,
  ImageInput,
  showNotification as showNotificationAction,
  SimpleForm,
  useTranslate,
} from 'react-admin'
import { connect, useSelector } from 'react-redux'

import { setUploadLogo as setUploadLogoAction } from '../../ducks/uploadLogo'

const useStyles = makeStyles({
  paper: {
    width: '80%',
    maxWidth: 900,
  },
})

const formatSubmitValues = (values) => {
  const finalValue = {}
  finalValue.logo_file_contents = values['logo_file_contents']
  return finalValue
}

export const EditLogo = (props) => {
  const translate = useTranslate()
  const { redirect, toolbar, ...rest } = props
  const orgId = useSelector((state) => (state.auth ? state.auth.org_id : null))
  const finalRedirect = props.hasOwnProperty('redirect') ? props.redirect : '/'

  return (
    <Edit
      title="Upload Logo"
      resource={'content'}
      style={{ paddingBottom: 0 }}
      hideBreadCrumbs={true}
      match={{
        params: { id: orgId },
        isExact: true,
        path: '/content/:id',
        url: '/content/' + orgId,
      }}
      location={{ pathname: '/content/' + orgId }}
      {...rest}
    >
      <SimpleForm
        redirect={finalRedirect}
        toolbar={toolbar}
        formatSubmitValues={formatSubmitValues}
        hideCancelButton={true}
      >
        <ImageInput
          source="logo_file_contents"
          label="Upload Logo"
          accept="image/*"
          placeholder={<p>{translate('Click to upload or drag & drop an image file here')}</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  )
}

const _UploadLogoDialog = (props) => {
  const translate = useTranslate()
  const classes = useStyles()
  return (
    <div>
      <Dialog
        id="UploadLogoDialog"
        className="upload-logo-dialog"
        open={props.isOpen}
        classes={classes}
        autoScrollBodyContent={true}
      >
        <div style={{ minHeight: 350 }}>
          <EditLogo />
          <IconButton
            id="UploadLogoCloseButton"
            style={{
              top: 10,
              right: 10,
              position: 'absolute',
              minWidth: 40,
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
            }}
            onClick={() => props.setUploadLogo(false)}
          >
            <Tooltip title={translate('Close Dialog')} enterDelay={300}>
              <CloseIcon style={{ color: '#000000' }} />
            </Tooltip>
          </IconButton>
        </div>
      </Dialog>
    </div>
  )
}

_UploadLogoDialog.propTypes = {
  showNotification: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    orgId: state.auth ? state.auth.org_id : null,
    isOpen: state.uploadLogo,
  }
}

export default connect(mapStateToProps, {
  showNotification: showNotificationAction,
  setUploadLogo: setUploadLogoAction,
})(_UploadLogoDialog)

// @ts-nocheck
import { makeStyles, Paper, Popover } from '@material-ui/core'
import Tools from 'projectSections/sections/design/tools/view/PopoverTools'
import PopoverViewSelector from 'projectSections/sections/design/tools/view/PopoverViewSelector'
import ShowCustomerToggle from 'projectSections/sections/design/tools/view/ShowCustomerToggle'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { PremiumImageryBlockReasonType } from 'types/global'
import { ViewTypes } from 'types/view'

const useStyles = makeStyles((theme: any) => ({
  popover: {
    overflow: 'hidden',
    pointerEvents: 'auto',
  },
  root: {
    opacity: 1,
  },
  content: {
    width: 200,
    padding: 10,
  },
}))

type PopupPropTypes = {
  anchorEl: HTMLElement | null
  handleClose: () => void
  view: ViewTypes
  isPremiumImageryAvailable: boolean
  premiumImageryUnavailableReson: PremiumImageryBlockReasonType
}

const ToolbarViewsPopover: React.FC<PopupPropTypes> = (props) => {
  const classes = useStyles()
  const [count, setCount] = useState(0)
  const forcePopoverReposition = useCallback(() => {
    setCount(count + 1)
  }, [count])
  const availableImageryTypes = useSelector((state: any) => {
    return state.designer?.detectImagery?.availableMapTypes || []
  })
  const mode = props.view.uuid === 'new' ? 'add' : 'change'
  return (
    <div>
      <Popover
        open={Boolean(props.anchorEl)}
        disableScrollLock={true}
        id={'toolbar-view-popover' + props.view?.uuid}
        anchorEl={props.anchorEl}
        classes={{ root: classes.root, paper: classes.popover }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={props.handleClose}
      >
        <Paper classes={{ root: classes.content }}>
          {mode === 'change' && (
            <Tools handleClose={props.handleClose} forcePopoverReposition={forcePopoverReposition} />
          )}
          {availableImageryTypes && availableImageryTypes.length > 0 && (
            <PopoverViewSelector
              availableImageryTypes={availableImageryTypes}
              mode={mode}
              forcePopoverReposition={forcePopoverReposition}
              isPremiumImageryAvailable={props.isPremiumImageryAvailable}
              premiumImageryUnavailableReson={props.premiumImageryUnavailableReson}
            />
          )}
          {mode === 'change' && <ShowCustomerToggle view={props.view} />}
        </Paper>
      </Popover>
    </div>
  )
}

export default ToolbarViewsPopover

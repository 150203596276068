import { FormControl } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import type { OpenSolarThemeType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import React, { memo, useCallback } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

export type SystemSelectDropdownOptionType = {
  id: number
  name: string
}

export type SystemSelectDropdownPropsType = {
  selectedSystemId: number | undefined
  disabled?: boolean
  systemOptions: SystemSelectDropdownOptionType[]
  onSelect: (id: number) => void
}

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  dropdownCell: {
    width: '80%',
  },
  dropdownItems: {
    fontSize: theme.typography.subtitle2.fontSize,
  },
}))

const SystemSelectDropdown = memo(
  ({ selectedSystemId, systemOptions, disabled, onSelect }: SystemSelectDropdownPropsType) => {
    const classes = useStyles()
    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      const id = event.target.value as number
      onSelect(id)
      logAmplitudeEvent('hardware_project_system_dropdown', {
        action: 'selected',
        context: 'suggest_item',
      })
    }
    const handleOnOpen = useCallback(() => {
      logAmplitudeEvent('hardware_project_system_dropdown', {
        action: 'opened',
        context: 'dropdown',
      })
    }, [])
    if (systemOptions.length === 0) {
      return <Typography variant="body2">-</Typography>
    }
    if (systemOptions.length === 1) {
      return <Typography variant="body2">{systemOptions[0].name}</Typography>
    }
    return (
      <FormControl size="small" className={classes.dropdownCell}>
        <Select
          variant="outlined"
          value={selectedSystemId || ''}
          disabled={disabled}
          onOpen={handleOnOpen}
          displayEmpty
          onChange={handleChange}
          className={classes.dropdownItems}
        >
          <MenuItem value={''} disabled className={classes.dropdownItems}>
            Choose a system
          </MenuItem>
          {systemOptions.map((option: SystemSelectDropdownOptionType) => {
            return (
              <MenuItem key={option.id} value={option.id} className={classes.dropdownItems}>
                {option.name}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    )
  }
)

export default SystemSelectDropdown

import { DialogActions, DialogContent, TextField } from '@material-ui/core'
import { Send } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orgSelectors } from 'ducks/orgs'
import { triggerMilestoneRefresh } from 'ducks/projectMilestones'
import { Button, Chip } from 'opensolar-ui'
import { InvoicePaymentDetails } from 'pages/cashFlow/invoices/types'
import { PaymentRequestType } from 'pages/cashFlow/types'
import { useNotify, useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { Field, Form, useFormState } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { ProjectType } from 'types/projects'
import InvoiceEmailCustomization from '../InvoiceEmailCustomization'

const restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  paymentRequest: PaymentRequestType | InvoicePaymentDetails
  label: string
  invoiceId?: number
  bodyTemplate: string
  subjectTemplate: string
  setIsLoading: (val: boolean) => void
  disableButtons?: boolean
  setDisableButtons?: (val: boolean) => void
  setActionsAreShown?: (val: boolean) => void
  setDialogIsOpen?: (val: boolean) => void
}

type ContentPropTypes = {
  paymentRequest: PaymentRequestType | InvoicePaymentDetails
  project: ProjectType
  label: string
  invoiceId?: number
  setIsLoading: (val: boolean) => void
  setDisableButtons?: (val: boolean) => void
  setActionsAreShown?: (val: boolean) => void
  setDialogIsOpen?: (val: boolean) => void
}

const useStyles = makeOpenSolarStyles((theme) => ({
  templateContent: {
    paddingTop: 10,
  },

  toRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 20,
  },

  to: {
    marginRight: '6px',
  },

  buttonsRow: {
    display: 'flex',
    padding: '16px',
    justifyContent: 'end',
    gap: '10px',
    alignItems: 'center',
    width: '100%',
  },

  buttonLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },

  icon: {
    width: 15,
    height: 15,
    marginBottom: 2,
  },

  description: {
    lineHeight: 1.5,
  },
}))

const SendEmailDialogContents: React.FC<ContentPropTypes> = (props) => {
  const currentlyLoggedInOrg = useSelector(orgSelectors.getOrg)
  const orgId = props.paymentRequest?.org || currentlyLoggedInOrg?.id
  const classes = useStyles()
  const notify = useNotify()
  const dispatch = useDispatch()
  const translate = useTranslate()
  const formState = useFormState()

  const refreshMilestones = () => {
    dispatch(triggerMilestoneRefresh())
  }

  const onSend = () => {
    props.setIsLoading(true)
    const USE_ENDPOINT = props.label === 'invoice' ? 'issue_and_send' : 'send'

    restClientInstance('CUSTOM_PATCH', 'custom', {
      url: `orgs/${orgId}/invoices/${props.invoiceId}/${USE_ENDPOINT}/`,
      data: {
        email_subject: formState.values.subject,
        email_body: formState.values.message,
      },
    })
      .then((res: any) => {
        refreshMilestones()
        props.setActionsAreShown && props.setActionsAreShown(false)
        notify('Email sent!', 'success')
        logAmplitudeEvent('cashflow_invoice_emailed', {
          org_id: props.paymentRequest?.org,
          project_id: props.paymentRequest?.project,
          payment_number: props.paymentRequest?.payment_number,
        })
      })
      .catch((err: any) => {
        notify(err, 'warning', { autoHideDuration: undefined })
      })
      .finally(() => {
        props.setDisableButtons && props.setDisableButtons(false)
        props.setIsLoading(false)
        props.setDialogIsOpen && props.setDialogIsOpen(false)
      })
  }

  return (
    <>
      <DialogContent>
        {props.label === 'invoice' ? (
          <p className={classes.description}>
            {translate(
              "Customize your invoice emails. This popup lets you review and edit the email sent to your customer with the invoice. The final email will include your company logo and a clear 'Pay' button for easy access to the payment page. You can find more email template options in CashFlow > Configure."
            )}
          </p>
        ) : (
          <p>{translate('Review and send %{label} email to customer(s)', { label: props.label })}</p>
        )}

        <div className={classes.templateContent}>
          <div>
            <div className={classes.toRow}>
              <div className={classes.to}>To:</div>
              {formState.values?.to?.map((email) => (
                <Chip key={email} label={email} />
              ))}
            </div>

            <div>
              <Field name={`subject`}>
                {(fieldProps) => (
                  <div>
                    <TextField
                      name={fieldProps.input.name}
                      label="Subject"
                      value={fieldProps.input.value}
                      onChange={fieldProps.input.onChange}
                      variant="outlined"
                      size="small"
                      fullWidth
                      placeholder=""
                    />
                  </div>
                )}
              </Field>
            </div>
            <div>
              <InvoiceEmailCustomization
                label="Message"
                fieldName="message"
                initialValue={formState?.values?.message}
                hideToolbar={true}
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttonsRow}>
          <Button color="primary" variant="contained" onClick={onSend}>
            <span className={classes.buttonLabel}>
              {translate(`Send ${props.label} email`)}
              <Send className={classes.icon} />
            </span>
          </Button>
        </div>
      </DialogActions>
    </>
  )
}

const SendEmailDialogWrapper: React.FC<PropTypes> = (props) => {
  const project = useFormState().values as ProjectType

  const initialToValue = useMemo(() => {
    return project?.contacts_data
      ?.filter((contact) => !!contact.email && !contact.email.includes('@os.code'))
      ?.map((con) => con.email)
  }, [project?.contacts])

  return (
    <Form
      onSubmit={() => {}}
      initialValues={{ to: initialToValue, subject: props.subjectTemplate, message: props.bodyTemplate }}
    >
      {({ handleSubmit }) => (
        <SendEmailDialogContents {...props} project={project} paymentRequest={props.paymentRequest} />
      )}
    </Form>
  )
}
export default SendEmailDialogWrapper

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from 'opensolar-ui'
import { FC } from 'react'

export const RadioVertGroup: FC = () => {
  return (
    <>
      {/* SNIPPET START 1 */}
      <FormControl>
        <FormLabel>Options</FormLabel>
        <RadioGroup defaultValue="module">
          <FormControlLabel value="module" control={<Radio />} label="Module" />
          <FormControlLabel value="inverter" control={<Radio />} label="Inverter" />
          <FormControlLabel value="battery" control={<Radio />} label="Battery" />
        </RadioGroup>
      </FormControl>
      {/* SNIPPET END 1 */}
    </>
  )
}

export const RadioHoriGroup: FC = () => {
  return (
    <>
      {/* SNIPPET START 2 */}
      <FormControl>
        <FormLabel>Options</FormLabel>
        <RadioGroup row defaultValue="module">
          <FormControlLabel value="module" control={<Radio />} label="Module" />
          <FormControlLabel value="inverter" control={<Radio />} label="Inverter" />
          <FormControlLabel value="battery" control={<Radio />} label="Battery" />
        </RadioGroup>
      </FormControl>
      {/* SNIPPET END 2 */}
    </>
  )
}

export const RadioSizes: FC = () => {
  return (
    <>
      {/* SNIPPET START 3 */}
      <RadioGroup row>
        <Radio defaultChecked size="small" />
        <Radio defaultChecked size="medium" />
      </RadioGroup>
      {/* SNIPPET END 3 */}
    </>
  )
}

export const RadioColors: FC = () => {
  return (
    <>
      {/* SNIPPET START 4 */}
      <RadioGroup row>
        <Radio defaultChecked color="primary" />
        <Radio defaultChecked color="secondary" />
        <Radio defaultChecked color="default" />
        <Radio defaultChecked color="info" />
        <Radio defaultChecked color="success" />
        <Radio defaultChecked color="warning" />
        <Radio defaultChecked color="error" />
      </RadioGroup>
      {/* SNIPPET END 4 */}
    </>
  )
}

export const RadioStates: FC = () => {
  return (
    <>
      {/* SNIPPET START 5 */}
      <RadioGroup row>
        <Radio checked={false} />
        <Radio checked={true} />
        <Radio disabled={true} />
      </RadioGroup>
      {/* SNIPPET END 5 */}
    </>
  )
}

import { makeStyles } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import useNavigationLinks from 'layout/hooks/useNavigationLinks'
import React from 'react'
import StyledNavLink from './StyledNavLink'

const useStyles = makeStyles((theme: OpenSolarThemeType) => ({
  linkWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}))

interface PropTypes {
  layout: number
}

const Navigation: React.FC<PropTypes> = ({ layout }) => {
  const classes = useStyles()

  const navigationLinks = useNavigationLinks()

  return (
    <div className={classes.linkWrapper}>
      {layout >= 2 &&
        navigationLinks.map(({ badgeSeverity, label, key, uiKey }) => (
          <StyledNavLink
            badgeSeverity={badgeSeverity}
            key={key}
            label={label}
            layout={layout}
            to={`/${key}`}
            uiKey={uiKey}
          />
        ))}
    </div>
  )
}
export default Navigation

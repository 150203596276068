var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Switch as Mui4Switch } from '@material-ui/core';
import { default as Mui5Switch } from '@mui/material/Switch';
import { forwardRef } from 'react';
import { Inspector } from '../debug/Inspector';
import { useComponentVersion } from '../hooks';
// * MUI Switch API Spec  https://mui.com/material-ui/api/switch/
export var Switch = function (props) { return (_jsx(Inspector, { name: "Switch", propDefs: SwitchPropsDefs, showExample: true, component: OsSwitch, props: props })); };
export var OsSwitchVersion;
(function (OsSwitchVersion) {
    OsSwitchVersion[OsSwitchVersion["V1"] = 1] = "V1";
    OsSwitchVersion[OsSwitchVersion["V2"] = 2] = "V2";
})(OsSwitchVersion || (OsSwitchVersion = {}));
var OsSwitch = forwardRef(function (props, ref) {
    var version = useComponentVersion('switch');
    switch (version) {
        case OsSwitchVersion.V2:
            return _jsx(Mui5Switch, __assign({ ref: ref }, props));
        default:
            var color = props.color, rest = __rest(props, ["color"]);
            var color4 = color === 'warning' || color === 'info' || color === 'success' || color === 'error' ? 'default' : color;
            return _jsx(Mui4Switch, __assign({ ref: ref }, rest, { color: color4 }));
    }
});
var SwitchPropsDefs = {
    checkedIcon: { default: undefined },
    color: { default: 'primary' },
    disabled: { default: false },
    icon: { default: undefined },
    size: { default: 'medium' },
    value: { default: undefined },
};

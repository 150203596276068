import { ConditionFactory, SystemContext } from 'Designer/designRules/types'
import { ComponentTypes } from 'types/selectComponent'
import { BaseConditionDef } from '.'

export type ComponentChangedDef = BaseConditionDef & {
  type: 'component-changed'
  componentType: ComponentTypes
  code?: string
}

export const condition_componentChanged: ConditionFactory<ComponentChangedDef> = (def) => {
  return {
    getProjectFields: () => [],
    getStudioSignals: () => {
      switch (def.componentType) {
        case 'module':
          return ['panel-added', 'panel-removed', 'panel-changed']
        case 'inverter':
          return ['inverter-added', 'inverter-removed', 'inverter-changed']
        case 'battery':
          return ['battery-added', 'battery-removed', 'battery-changed']
        case 'other':
          return ['other-component-added', 'other-component-removed', 'other-component-changed']
        default:
          throw new Error(`Unknown component type: ${def.componentType}`)
      }
    },
    check: (context: SystemContext) => {
      const trigger = context.trigger
      let typeDict = {
        panel: 'OsModule',
        inverter: 'OsInverter',
        battery: 'OsBattery',
        other: 'OsOther',
      }
      if (trigger.studioItem?.type !== typeDict[def.componentType]) return false
      if (!def.code) return true
      if (trigger.signalPayload?.oldValue?.code === def.code) return true
      if (trigger.signalPayload?.newValue?.code === def.code) return true
      if (trigger.studioItem?.code === def.code) return true
      return false
    },
  }
}

import { makeStyles, Tooltip } from '@material-ui/core'
import { LockOutlined } from '@material-ui/icons'
import { styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { Theme } from 'types/themes'
import { WorkflowType } from 'types/workflows'
import SelectChipField from '../common/SelectChipField'
import { COLOR_PALETTE, getProjectSectionStyles } from '../styles'
import { handleImplicitToggles, isPricingLockedForStage } from './util'
const useStyles = makeStyles((theme: Theme) => ({
  lockPricing: {
    marginLeft: 'auto',
    '& p': {
      margin: 0,
      color: COLOR_PALETTE.darkGrey,
    },
  },
  lockIcon: {
    color: COLOR_PALETTE.red2,
    height: 15,
  },
  fullWidth: {
    width: '100%',
  },
}))
interface PropTypes {
  selectedWorkflow?: WorkflowType
  workflows: WorkflowType[]
  handleChange: (workflow) => void
  disabled?: boolean
}

const FlexRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '10px',
  flex: 1,
})

const WorkflowSelect: React.FC<PropTypes> = ({ workflows, selectedWorkflow, handleChange, disabled }) => {
  const form = useForm()
  const classes = useStyles()
  const translate = useTranslate()
  const project = useFormState().values
  const sectionStyles = getProjectSectionStyles()
  const priceLock = useMemo(() => isPricingLockedForStage(project, workflows), [workflows, project.workflow])

  const onWorkflowChange = (e) => {
    const newId = e.target.value
    //check if new workflow has already been assigned to the project
    const projectWorkflow = project?.workflows?.find((x) => x.workflow_id === newId)
    const workflow_stages = workflows.find((x) => x.id === newId)?.workflow_stages
    if (projectWorkflow) {
      handleImplicitToggles(
        {
          workflow_stages: workflow_stages,
          active_stage_id: projectWorkflow.active_stage_id,
          org_id: projectWorkflow.org_id,
        },
        project,
        form
      )
      handleChange({
        org_id: projectWorkflow.org_id,
        workflow_id: projectWorkflow.workflow_id,
        active_stage_id: projectWorkflow.active_stage_id,
      })
    } else {
      const findWorkflow = workflows.find((x) => x.id === newId)
      if (findWorkflow) {
        const active_stage_id = !!findWorkflow.workflow_stages.length ? findWorkflow.workflow_stages[0].id : undefined
        handleImplicitToggles(
          {
            ...findWorkflow,
            active_stage_id: active_stage_id,
          },
          project,
          form
        )
        handleChange({
          workflow_id: findWorkflow.id,
          active_stage_id: active_stage_id,
        })
      }
    }
  }
  return (
    <FlexRow>
      <SelectChipField
        choices={workflows.filter((x) => !x.is_archived || x.id === selectedWorkflow?.id)}
        chipLabel=""
        optionValue={'id'}
        optionText={'title'}
        value={selectedWorkflow?.id}
        disabled={disabled}
        onChange={onWorkflowChange}
        renderEmpty={translate('No access')}
      />
      {priceLock && (
        <div className={`${sectionStyles.row} ${classes.lockPricing}`}>
          <Tooltip
            title={translate(
              'Based on the Stage of the project, the pricing, incentives and payment options are locked on this project.'
            )}
            PopperProps={{ style: { zIndex: 2001 } }}
          >
            <LockOutlined className={classes.lockIcon} />
          </Tooltip>
          <p>{translate('Pricing is locked')}</p>
        </div>
      )}
    </FlexRow>
  )
}

export default WorkflowSelect

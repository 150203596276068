import { useCallback, useState } from 'react'
import { useDataProvider } from 'react-admin'
import { dateParser } from 'util/misc'

type UpdateForecastDateOptions = { projectId: number; date: Date }

export type UpdateProjectForecastDate = (options: UpdateForecastDateOptions) => Promise<void>

type UpdateForecastDateResponse = {
  updatingForecastDate: boolean
  updateProjectForecastDate: UpdateProjectForecastDate
}

const useUpdateForecastDate = (): UpdateForecastDateResponse => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dataProvider = useDataProvider()
  const updateProjectForecastDate = useCallback(async ({ projectId, date }: UpdateForecastDateOptions) => {
    setIsLoading(true)
    await dataProvider
      .CUSTOM_POST('inventory/projects', {
        url: 'inventory/projects/' + projectId + '/pickup_date/',
        data: { pickup_date: dateParser(date) },
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return {
    updatingForecastDate: isLoading,
    updateProjectForecastDate,
  }
}

export default useUpdateForecastDate

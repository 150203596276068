import linkToRecord from './linkToRecord';
export default (function (redirectTo, basePath, id, data) {
    if (typeof redirectTo === 'function') {
        return redirectTo(basePath, id, data);
    }
    switch (redirectTo) {
        case 'list':
            return basePath;
        case 'create':
            return "".concat(basePath, "/create");
        case 'edit':
            return linkToRecord(basePath, id);
        case 'show':
            return "".concat(linkToRecord(basePath, id), "/show");
        case 'studio':
            return "/studio/".concat(id);
        default:
            return redirectTo;
    }
});

import { Link } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import CustomField from 'elements/field/CustomField'
import { renderCheckbox, renderInput } from 'pages/auth/common'
import { useTranslate } from 'ra-core'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

type PropTypes = {
  onSubmitSms: () => void
}

const MfaChallenge: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const mfaHasTotp = useSelector(authSelectors.getMfaHasTotp)
  const mfaHasSms = useSelector(authSelectors.getMfaHasSms)
  const mfaHasRecovery = useSelector(authSelectors.getMfaHasRecovery)
  const [explicitSms, setExplicitSms] = useState(false)
  const [smsMode, setSmsMode] = useState(false)

  const sendSms = () => {
    setExplicitSms(true)
    props.onSubmitSms()
  }

  useEffect(() => {
    setSmsMode(explicitSms || (mfaHasSms && !mfaHasTotp))
  }, [mfaHasTotp, mfaHasSms, explicitSms])

  return (
    <div>
      <h4>{translate('Further Verification Required')}</h4>
      <p>
        {translate(
          !smsMode
            ? mfaHasTotp
              ? 'Please enter the verification code that can be found in your authenticator app.'
              : ''
            : 'Please enter the verification code that was sent to you via SMS.'
        )}
      </p>
      <p>
        {translate(
          mfaHasRecovery
            ? mfaHasTotp || mfaHasSms
              ? "If you're unable to enter the verification code you can also enter one of your recovery codes below."
              : 'Enter one of your recovery codes below.'
            : 'You have no more recovery codes, generate some new codes after login.'
        )}
      </p>
      {mfaHasSms ? (
        <Link onClick={sendSms} color="textSecondary" style={{ cursor: 'pointer' }}>
          {translate(smsMode ? 'To resend the SMS, click here' : "If you'd like to receive an SMS instead, click here")}
        </Link>
      ) : (
        ''
      )}
      <CustomField
        name="token"
        component={renderInput}
        label={translate('Verification Code')}
        fullWidth={true}
        inputProps={{
          id: 'mfa-verification-input',
        }}
        autocomplete="off"
      />
      <CustomField
        name="remember"
        type="checkbox"
        component={renderCheckbox}
        label={translate('Remember this device for 30 days')}
        fullWidth={true}
      />
    </div>
  )
}
export default MfaChallenge

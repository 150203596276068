import { createContext } from 'react'
import { CashFlowSetupProgress } from './types'

type CashFlowSetupProps = {
    setupProgress: CashFlowSetupProgress | {}
    setSetupProgress: Function,
    isFetchingSetupProgress: Boolean
}

export const CashFlowSetupContext = createContext<CashFlowSetupProps>({
    setupProgress: {},
    setSetupProgress: () => { },
    isFetchingSetupProgress: true,
})
import React, { useCallback, useEffect, useState } from 'react'
import { CURRENT_FILTER_KEY } from '../currentType/CurrentTypeFilterNodeFactory'
import { OTHER_COMPONENT_FILTER_KEY } from '../otherComponentType/OtherComponentTypeFilterNodeFactory'
import type {
  FilterComponentRendererType,
  FilterGenericPropsType,
  FilterKeysType,
  FilterValuesType,
  OptionGenericType,
} from '../type'

export type FilterOptionsType = OptionGenericType<string>[]

type FilterProps = FilterGenericPropsType & {
  RendererComponent: FilterComponentRendererType<FilterOptionsType>
  filterKey: FilterKeysType
}

export const isVoltageAndCurrentFilterAvailable = (filterValues: FilterValuesType) => {
  return filterValues[OTHER_COMPONENT_FILTER_KEY] === 'isolator' && filterValues[CURRENT_FILTER_KEY] === 'dc'
}

const DEFAULT_OPTION = { id: 'default', title: 'All', value: '' }

const getVoltageAndCurrentFilterOption = (value: string) => {
  // The value is injected from spa/app/src/projectSections/sections/design/systems/Slots.tsx
  // consider to refactor it...
  return { id: value, title: 'Compatible with system', value }
}

const VoltageAndCurrentFilter = ({
  filterKey,
  modifiableFilterValues,
  persistentFilterValues,
  allFilterValues,
  setModifiableFilters,
  RendererComponent,
}: FilterProps) => {
  const [options, setOptions] = useState<FilterOptionsType>([DEFAULT_OPTION])
  const value = allFilterValues[filterKey]
  const onSelect = useCallback(
    (value) => {
      setModifiableFilters({ ...modifiableFilterValues, [filterKey]: value })
    },
    [modifiableFilterValues]
  )

  useEffect(() => {
    if (value && !options.find((option: OptionGenericType<string>) => option.value === value)) {
      setOptions((options) => {
        return [...options, getVoltageAndCurrentFilterOption(value)]
      })
    }
  }, [value])

  if (options.length < 2) {
    return null
  }

  return (
    <RendererComponent
      id={filterKey}
      defaultValue={''}
      label={'Voltage and Current'}
      options={options}
      disabled={false}
      onSelect={onSelect}
      value={value}
    />
  )
}

export default VoltageAndCurrentFilter

import { Button, styled } from 'opensolar-ui'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import { useTranslate } from 'ra-core'
import React from 'react'

const TextBtn = styled(Button)({
  paddingLeft: 5,
  minWidth: 0,
})

interface PropTypes {
  label: string
  isActive?: boolean
  onClick: () => void
}

const SortButton: React.FC<PropTypes> = ({ isActive, label, onClick }) => {
  const translate = useTranslate()
  return (
    <TextBtn
      disableRipple
      variant="text"
      color="secondary"
      onClick={onClick}
      style={{ color: isActive ? COLOR_PALETTE.darkBlue2 : undefined }}
    >
      {translate(label)}
    </TextBtn>
  )
}

export default SortButton

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import createStandardIcon from './createStandardIcon';
var AlertTriangleIconSvg = function (props) {
    var fill = props.fill;
    return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" }, props, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4.7459 20.4C3.85446 20.4 3.09625 19.8976 2.6809 19.0345C2.26359 18.1715 2.31454 17.2021 2.81609 16.3953L10.071 4.71944C10.5216 3.99398 11.2014 3.59998 12.0008 3.59998C12.7982 3.59998 13.48 3.99606 13.9286 4.71944L21.1855 16.3953C21.687 17.2021 21.736 18.1715 21.3207 19.0345C20.9034 19.8997 20.1452 20.4 19.2557 20.4H4.7459ZM12.0184 13.8C11.3556 13.8 10.8184 13.2627 10.8184 12.6V7.8C10.8184 7.13726 11.3556 6.6 12.0184 6.6C12.6811 6.6 13.2184 7.13726 13.2184 7.8V12.6C13.2184 13.2627 12.6811 13.8 12.0184 13.8ZM12.0184 18C11.3556 18 10.8184 17.4627 10.8184 16.8C10.8184 16.1373 11.3556 15.6 12.0184 15.6C12.6811 15.6 13.2184 16.1373 13.2184 16.8C13.2184 17.4627 12.6811 18 12.0184 18Z", fill: fill }) })));
};
export var AlertTriangleIcon = createStandardIcon(AlertTriangleIconSvg);

export const SYSTEM_CALCULATION_REQUEST = 'SYSTEM_CALCULATION_REQUEST'

export const CALCULATE_SYSTEM_IMMEDIATE = 'CALCULATE_SYSTEM_IMMEDIATE'

export const CALCULATION_PROCESS_STATUS_UPDATE = 'CALCULATION_PROCESS_STATUS_UPDATE'

export const CALCULATION_PROCESS_END = 'CALCULATION_PROCESS_END'

export const CALCULATION_DEBOUNCE_QUEUE_ADD = 'CALCULATION_DEBOUNCE_QUEUE_ADD'

export const CALCULATION_PROCESS_START = 'CALCULATION_PROCESS_START'

export const clearCalculationStatus = (uuid: string): any => ({
  type: CALCULATION_PROCESS_END,
  payload: {
    uuid,
  },
})

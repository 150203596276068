// @ts-nocheck
import { InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import { AddCircle, RemoveCircle } from '@material-ui/icons'
import { featureConfigSelectors } from 'ducks/featureConfig'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { PlaceholderWhiteListType } from './ProjectPlaceholderWizard'
import { FieldType, MathOperationType, OperandTypes } from './TemplateWizard'
import { formatPlaceholderLabel } from './util'

type PropTypes = {
  row: MathOperationType
  fieldBeingDragged: FieldType | undefined
  rowIndex: number
  updateRow: (newVal: MathOperationType, i: number) => void
  addRow: () => void
  removeRow: (rowIndex: number) => void
}

const PlaceholderTagFormulaLine: React.FunctionComponent<PropTypes> = (props) => {
  const [operator, setOperator] = useState<string | undefined>(props.row?.operator)
  const [operand, setOperand] = useState<OperandTypes | undefined>(props.row?.operand_type)
  const [operandValue, setOperandValue] = useState<number | string | undefined>(props.row?.operand_value)
  const [hasRendered, setHasRendered] = useState<boolean>(false)

  const translate = useTranslate()

  const whitelistVals: PlaceholderWhiteListType | undefined = useSelector((state) =>
    featureConfigSelectors.getFeatureConfigData(state, 'placeholder_whitelist')
  )

  useEffect(() => {
    if (!hasRendered) {
      setHasRendered(true)
    } else if (operandValue !== undefined && operator && operand) {
      let newRow: MathOperationType = {
        operand_value: operandValue,
        operator,
        operand_type: operand,
      }
      props.updateRow(newRow, props.rowIndex)
    }
  }, [hasRendered, operator, operand, operandValue])

  const handleOperatorChange = (e: ChangeEvent) => {
    setOperator(e.target.value)
    e.preventDefault()
    return
  }

  const handleOperandChange = (e: ChangeEvent) => {
    setOperand(e.target.value)
    e.preventDefault()
    return
  }

  const handleOperandTypedChange = (e: ChangeEvent) => {
    setOperandValue(e.target.value)
  }

  const onDrop = (e) => {
    e.preventDefault()
    setOperandValue(props.fieldBeingDragged?.field_path)
  }

  const overrideDefault = (e) => {
    e.preventDefault()
    return false
  }

  const formatDroppedLabel = () => {
    if (!operandValue || !isNaN(operandValue)) return operandValue
    let lastPeriodIndex = operandValue.lastIndexOf('.') + 1
    let fieldName = operandValue.substring(lastPeriodIndex)
    let pathWithoutName = operandValue.substring(0, lastPeriodIndex - 1)
    return formatPlaceholderLabel(fieldName, pathWithoutName, whitelistVals)
  }

  return (
    <div
      style={{
        marginTop: '10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        borderBottom: '1px solid #ececec',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
          flex: 3,
          padding: '3px',
        }}
      >
        <InputLabel id="operator">Operator</InputLabel>
        <Select
          labelId="operator"
          id="operator"
          value={operator}
          label="Operator"
          onChange={handleOperatorChange}
          fullWidth
          MenuProps={{ disablePortal: true }}
        >
          <MenuItem value={'+'}>{translate('Plus')}</MenuItem>
          <MenuItem value={'-'}>{translate('Minus')}</MenuItem>
          <MenuItem value={'x'}>{translate('Multiply by')}</MenuItem>
          <MenuItem value={'/'}>{translate('Divide by')}</MenuItem>
        </Select>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
          flex: 3,
          padding: '3px',
        }}
      >
        <InputLabel id="operand">{translate('Value Type')}</InputLabel>
        <Select
          labelId="operand"
          id="operand"
          value={operand}
          label="Operand"
          onChange={handleOperandChange}
          fullWidth
          MenuProps={{ disablePortal: true }}
        >
          <MenuItem value={'number'}>{translate('Static Number')}</MenuItem>
          <MenuItem value={'field'}>{translate('Another Field')}</MenuItem>
        </Select>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
          flex: 3,
          padding: '3px',
          minHeight: '44px',
        }}
      >
        {operand === 'number' && (
          <TextField value={operandValue} onChange={handleOperandTypedChange} label={translate('Value')} fullWidth />
        )}
        {operand === 'field' && (
          <div
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.05)',
              height: '100%',
              width: '100%',
              borderRadius: '4px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '44px',
            }}
            onDrop={onDrop}
            onDragEnter={overrideDefault}
            onDragOver={overrideDefault}
          >
            {!operandValue ? translate('Drag the other field here') : formatDroppedLabel()}
          </div>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          justifyContent: 'center',
          flex: 1,
          padding: '3px',
          minHeight: '44px',
        }}
      >
        <div onClick={props.addRow}>
          <AddCircle htmlColor="rgba(24, 144, 255, 1)" />
        </div>
        <div onClick={() => props.removeRow(props.rowIndex)}>
          <RemoveCircle htmlColor="rgba(24, 144, 255, 1)" />
        </div>
      </div>
    </div>
  )
}

export default React.memo(PlaceholderTagFormulaLine)

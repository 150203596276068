import { ReduxActivitiesType, FutureOrPast } from '../types/redux_activities';
export const CREATE_ACTIVITY_DIALOG = 'CREATE_ACTIVITY_DIALOG'

export const setCreateActivityDialog = (isOpen: boolean, projectId: string, futureOrPast: FutureOrPast, noteOnly: boolean) => ({
  type: CREATE_ACTIVITY_DIALOG,
  payload: {
    isOpen,
    projectId,
    futureOrPast,
    noteOnly,
  },
})

const initialState: ReduxActivitiesType = {
  isOpen: false,
  noteOnly: false,
  projectId: null,
  futureOrPast: null
}

type Args = {
  type: string,
  meta: object,
  payload: any,
  requestPayload: any,
}

export default (previousState = {} as ReduxActivitiesType, { type, meta, payload, requestPayload }: Args) => {
  if (type === '@@router/LOCATION_CHANGE') {
    // Hide dialog on any change of location
    // if (payload.pathname.indexOf('/projects/') === -1) {
    previousState = { ...previousState, isOpen: false }
    // }
  }

  if (type === CREATE_ACTIVITY_DIALOG) {
    var version = previousState && previousState.version ? previousState.version + 1 : 1

    previousState = {
      ...previousState,
      ...{
        isOpen: payload.isOpen,
        projectId: payload.projectId,
        futureOrPast: payload.futureOrPast,
        noteOnly: payload.noteOnly,
        version: version,
      },
    } as ReduxActivitiesType
  }

  if (type === 'AOR/CRUD_CREATE_SUCCESS') {
    previousState = {
      status: 'success',
      isOpen: false,
    } as ReduxActivitiesType
  }

  if (type === 'AOR/CRUD_CREATE_LOADING') {
    previousState = {
      status: 'loading',
    } as ReduxActivitiesType
  }

  if (type === 'AOR/CRUD_CREATE_FAILURE') {
    previousState = {
      status: 'failure',
    } as ReduxActivitiesType
  }

  return previousState
}

import { MountingCalcInput, MountingCalcResult, PostProcessor } from '../types'

export abstract class PostProcessorAbstract implements PostProcessor {
  protected input: MountingCalcInput

  constructor(input: MountingCalcInput) {
    this.input = input
  }

  abstract process(result: MountingCalcResult): MountingCalcResult
}

import {
  Dialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Typography,
  withStyles,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import { IconButton } from 'opensolar-ui'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'

const DialogContent = withStyles(() => ({
  root: {
    padding: 0,
    '& .MuiPaper-root': {
      marginTop: 0,
      paddingBottom: 0,
    },
  },
}))(MuiDialogContent)

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeButton: {
    color: 'rgba(0, 0, 0, 0.87)',
    zIndex: 1,
  },
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {children && <Typography variant="h6">{children}</Typography>}
      {props.title && <Typography variant="h6">{props.title}</Typography>}
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogEditor = memo(({ id, isOpen, handleClose, editorComponent, editorProps, title, options }) => {
  const orgId = useSelector((state) => (state.auth ? state.auth.org_id : null))
  return (
    <div>
      <Dialog open={isOpen} modal={false} {...options}>
        <DialogTitle onClose={handleClose} title={title} />
        <DialogContent>
          {isOpen &&
            React.cloneElement(editorComponent, {
              org_id: orgId,
              id,
              handleClose,
              ...editorProps,
            })}
        </DialogContent>
      </Dialog>
    </div>
  )
})

export default DialogEditor

// const DialogEditorWithButton = ({ id, buttonLabel, editorComponent }) => {
//   const [isOpen, setOpen] = useState(false)
//   return (
//     <div>
//       {id && (
//         <>
//           <button
//             type="button"
//             onClick={(e) => {
//               e.preventDefault()
//               setOpen(true)
//             }}
//           >
//             {buttonLabel}
//           </button>
//           <DialogEditor isOpen={isOpen} handleClose={() => setOpen(false)} id={id} editorComponent={editorComponent} />
//         </>
//       )}
//     </div>
//   )
// }

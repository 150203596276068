import React, { memo, useCallback } from 'react'
import { ProjectErrorType } from 'reducer/project/projectErrorsReducer'
import DesignWarningBoxBasic from '../../../common/DesignWarningBoxBasic'

const LeaveProjectWarningBox: React.FC<{}> = () => {
  const leaveProjectWarningFilter = useCallback((error: ProjectErrorType) => {
    if (['info', 'success'].includes(error.severity)) return false
    return true
  }, [])

  return <DesignWarningBoxBasic categories="all" extraFilter={leaveProjectWarningFilter} />
}

export default memo(LeaveProjectWarningBox)

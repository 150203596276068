import React from 'react'
import { formatVideoUrl } from '../../util/misc'

const EmbedVideo = props => (
  <div style={{ marginTop: 40 }}>
    {props.proposalTemplateSettings.business_video_link && (
      <div
        style={{ paddingTop: '56.25%', position: 'relative' }}
        dangerouslySetInnerHTML={{
          __html:
            `<iframe
      src="https://www.youtube-nocookie.com/embed/` +
            formatVideoUrl(props.proposalTemplateSettings.business_video_link) +
            `?rel=0"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen="true"
      style="position:absolute;top:0;left:0;width: 100%;height:100%"
    />`,
        }}
      />
    )}
  </div>
)

export default EmbedVideo

import CustomField from 'elements/field/CustomField'
import CheckboxInput from 'elements/input/CheckboxInput'
import { useTranslate } from 'ra-core'
import React from 'react'
import { CheckTermType } from '../types'

type PropTypes = {
  termLine: CheckTermType
  index: number
}

// responsible for displaying the terms and conditions for a payment option. Could be standard, custom, or exhibit-based terms
const CheckoutTermsLine: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const term = props.termLine
  return (
    <div data-testid="checkout-name-field">
      <CustomField
        name={`terms_${props.index}`}
        source={`terms_${props.index}`}
        label={term.isHtml ? <div dangerouslySetInnerHTML={{ __html: term.content }} /> : translate(term.content)}
        defaultValue={false}
        component={(checkboxProps: any) => (
          <CheckboxInput
            {...checkboxProps}
            elStyle={{ maxWidth: '900px' }}
            data-testid={`checkout-name-field-${props.index}`}
          />
        )}
        fullWidth
        required
        style={{ width: '100%' }}
      />
    </div>
  )
}
export default CheckoutTermsLine

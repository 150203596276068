var identifierAsId = function (urlOrId) {
    if (!urlOrId) {
        return null;
    }
    if (Number.isInteger(parseInt(urlOrId, 10))) {
        return urlOrId;
    }
    else if (urlOrId.split) {
        var id = urlOrId.split('/').slice(-2, -1)[0];
        if (isNaN(id)) {
            return null;
        }
        return parseInt(id);
    }
    else {
        console.log('Unexpected format for id:' + urlOrId);
        return null;
    }
};
export default identifierAsId;

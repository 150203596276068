import { FormApi } from 'final-form'

/**
 * This utility takes a lite project and converts it to a
 * full project.
 */

export function upgradeLiteToFullProject(form: FormApi) {
  const system = window.editor.getSystems()[0]

  form.change('is_lite', false)

  // Clear system efficiency
  if (system.system_efficiency === undefined) {
    console.debug(`Clearing Lite project's system efficiency`)
    window.editor.execute(new window.SetValueCommand(system, 'system_efficiency_override', undefined))
  }

  // Clear the system lifetime
  if (system.system_lifetime === 1) {
    console.debug(`Clearing Lite project's system lifetime`)
    window.editor.execute(new window.SetValueCommand(system, 'system_lifetime', 0))
  }

  // clear the image bounds data which is not supported in OS Full
  if (window.ViewHelper.selectedView().viewBoxParams) {
    console.debug(`Removing project's image bounds data`)
    window.ViewHelper.deleteViewBox()
  }

  // Allow things to re-assess the project is case they were skipping lite projects (i.e. studio plugins)
  window.editor.signals.liteProjectUpgraded.dispatch()
}

import { Tooltip } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import React, { useEffect, useState } from 'react'
import { useField } from 'react-final-form'

type PropsType = {
  source: string
  adornment?: string
  validation?: Function
  max?: number
  min?: number
}

const SoftWarning = (props: PropsType) => {
  const { source, validation, max = Infinity, min = -Infinity, adornment = '' } = props
  const [warning, setWarning] = useState('')
  const { input } = useField(source)
  useEffect(() => {
    if (validation) {
      const message = validation(input.value) || ''
      setWarning(message)
    } else if (props.hasOwnProperty('max') && input.value > max) {
      setWarning('This value seems greater than expected. Please double check.')
    } else if (props.hasOwnProperty('min') && input.value < min) {
      setWarning('This value seems less than expected. Please double check.')
    } else {
      setWarning('')
    }
  }, [input.value])

  return warning ? (
    <>
      <span style={{ color: '#878787' }}>{adornment}</span>
      <Tooltip title={warning} enterDelay={100}>
        <InfoIcon
          style={{ color: 'rgba(255,160,0,0.95)', width: 18, verticalAlign: 'middle', height: 18, cursor: 'help' }}
        />
      </Tooltip>
    </>
  ) : (
    <span style={{ color: '#878787' }}>{adornment}</span>
  )
}

export default SoftWarning

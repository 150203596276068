import { useTranslate } from 'ra-core'
import React from 'react'
import { toCapitalise } from 'util/misc'
import ConfirmModal from './ConfirmModal'

interface DialogProps {
  open: boolean
  setOpen: (open: boolean) => void
  title: string
  handleDelete: (e) => void
}

const DeleteConfirmModal: React.FC<DialogProps> = ({ open, setOpen, title, handleDelete }) => {
  const translate = useTranslate()
  return (
    <ConfirmModal
      open={open}
      setOpen={setOpen}
      handleSubmit={handleDelete}
      title={translate('Delete %{object}', { object: toCapitalise(title) })}
      mainText={translate('Are you sure you want to delete this %{object}?', { object: title })}
      subText={translate('This action is permanent and cannot be undone.')}
      submitBtnLabel={translate('Yes, delete')}
      submitBtnProps={{ variant: 'contained', color: 'error' }}
    />
  )
}
export default DeleteConfirmModal

import { InputAdornment } from '@material-ui/core'
import TickIcon from '@material-ui/icons/CheckCircleOutlined'
import PencilIcon from '@material-ui/icons/EditOutlined'
import classNames from 'classnames'
import { orgSelectors } from 'ducks/orgs'
import inflection from 'inflection'
import { Box, MenuItem, Select, styled, TableCell, TableRow, TextField, Typography, useTheme } from 'opensolar-ui'
import { getSupplierConfig } from 'pages/ordering/configs'
import useEnabledHardwareSuppliers from 'pages/ordering/hooks/useEnabledHardwareSuppliers'
import { ComponentCost, CostSource } from 'projectSections/hooks/useComponentCost'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { formatCurrency } from 'util/misc'
import { useFeatureFlag } from 'util/split'
import { HardwareDetailType } from '../summary/hardware/getHardwareDetailFromSystem'

const useStyles = makeOpenSolarStyles((theme) => ({
  supplierImage: {
    height: 20,
    maxWidth: 50,
  },
  sourceSelect: {
    border: 0,
    // height: '100%',
    maxWidth: '100%',
  },
  supplierImg: {
    width: 50,
    height: 32,
    marginRight: 8,
    border: '1px solid #E6E6E6',
    borderRadius: 4,
  },
  supplierName: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
  },
  supplierWrapper: {
    padding: '8px',
  },
}))

const GreyPencilIcon = styled(PencilIcon)(({ theme }) => ({
  color: theme.palette.grey[500],
}))

const GreyTickOutlined = styled(TickIcon)(({ theme }) => ({
  color: theme.palette.grey[500],
}))

type ValueEditProps = {
  pricing: ComponentCost | undefined
  code: string
  currencySymbol: string
  onSetValue: (value: number) => void
  onEditChange: (isEditing: boolean) => void
}

const ValueEdit = ({ pricing, code, currencySymbol, onSetValue, onEditChange }: ValueEditProps) => {
  const [inputValue, setInputValue] = useState<string | undefined>(formatCurrency(pricing?.price) || '')

  const editable = useMemo(() => {
    return pricing?.source === 'manual'
  }, [pricing?.source])

  const [editing, setEditing] = useState<boolean>(false)
  const theme = useTheme()
  const endIcon = editable ? editing ? <GreyTickOutlined /> : <GreyPencilIcon /> : null
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!editable) {
      setInputValue(formatCurrency(pricing?.price) || '')
    }
  }, [editable])

  useEffect(() => {
    onEditChange(editing)
  }, [editing])

  const saveValue = (value: string) => {
    const floatValue = parseFloat(value)
    if (isNaN(floatValue)) {
      setInputValue(formatCurrency(pricing?.price) || '')
    } else if (floatValue > 1000000) {
      alert('Value must be less than 1,000,000')
      return false
    } else if (floatValue < 0) {
      alert('Value must be greater than 0')
      return false
    } else {
      setInputValue(formatCurrency(value))
      onSetValue(floatValue)
    }
    return true
  }

  const onClickEndIcon = () => {
    if (!editing) {
      inputRef.current?.focus()
      setInputValue('')
    } else {
      if (!saveValue(inputValue || '')) return
    }
    setEditing(!editing)
  }

  useEffect(() => {
    setInputValue(formatCurrency(pricing?.price) || '')
  }, [pricing?.price])

  return (
    <Box display="flex" justifyContent="flex-start">
      <TextField
        inputRef={(input) => {
          editing && input?.focus()
        }}
        id={`cogs-override-${code}`}
        disabled={!editing || !editable}
        variant={'standard'}
        fullWidth
        InputProps={{
          disableUnderline: !editing,
          startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
        }}
        style={{ border: 0, color: theme.palette.grey[500], width: 130 }}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />
      <Box style={{ cursor: 'pointer' }} onClick={() => onClickEndIcon()}>
        {endIcon}
      </Box>
    </Box>
  )
}

/// Again there is some funky styling directly on elements because of Mui Table stickyHeader.
const CustomiseHardwareCostTableRow = ({
  hardwareDetail,
  effectiveComponentType,
  pricing,
  isLastRow,
  onCostSourceChange,
  onEditChange,
}: {
  hardwareDetail: HardwareDetailType
  effectiveComponentType: string
  pricing: ComponentCost | undefined
  isLastRow: boolean
  onCostSourceChange: (code: string, costSource: CostSource, value: number) => void
  onEditChange: (isEditing: boolean) => void
}) => {
  const enabledSuppliers = useEnabledHardwareSuppliers()
  const currencySymbol = useSelector(orgSelectors.getCurrencySymbolFromOrg)
  const multiDistributor = useFeatureFlag('design_multi_distributor', 'on')
  const selectedSupplier = useSelector(orgSelectors.getSelectedHardwareSupplier)

  const supplierList = useMemo(() => {
    if (multiDistributor) return enabledSuppliers
    else return [selectedSupplier]
  }, [multiDistributor, enabledSuppliers, selectedSupplier])

  const [costSource, setCostSource] = useState<CostSource>(pricing?.source || 'manual')
  const [overrideValue, setOverrideValue] = useState<number | undefined>(pricing?.price || 0)

  const theme = useTheme()
  const classes = useStyles()

  useEffect(() => {
    setOverrideValue(pricing?.price || 0)
  }, [pricing?.price])

  const SourceSelect = (
    <Select
      value={pricing?.source}
      className={classes.sourceSelect}
      onChange={(e) => {
        setCostSource(e.target.value as CostSource)
        onCostSourceChange(hardwareDetail.code, e.target.value as CostSource, overrideValue || 0)
      }}
    >
      {supplierList.map((supplier) => {
        const supplierConfig = getSupplierConfig(supplier)
        return (
          supplierConfig && (
            <MenuItem key={supplierConfig?.filterKey} value={supplierConfig?.filterKey}>
              <div style={{ display: 'inline-flex', alignContent: 'center', alignItems: 'center' }}>
                <div className={classes.supplierImg}>
                  <img
                    src={supplierConfig?.logoUrl || ''}
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                  />
                </div>
                <Typography className={classes.supplierName}>{supplierConfig?.name}</Typography>
              </div>
            </MenuItem>
          )
        )
      })}
      <MenuItem value="component">
        <div className={classNames(classes.supplierWrapper, classes.supplierName)}>
          Manual (Business Control Settings)
        </div>
      </MenuItem>
      <MenuItem value="manual">
        <div className={classNames(classes.supplierWrapper, classes.supplierName)}>Override (for this project)</div>
      </MenuItem>
    </Select>
  )

  // Again I believe stickyHeader is causing the styles from className to disappear, so they are inlined here.
  const cellBorder = `1px solid ${theme.palette.grey[200]}`

  return (
    <TableRow>
      <TableCell
        style={{
          borderLeft: cellBorder,
          borderRight: cellBorder,
          maxWidth: 150,
          borderBottomLeftRadius: isLastRow ? 8 : 0,
        }}
      >
        {inflection.capitalize(effectiveComponentType)}
      </TableCell>
      <TableCell style={{ borderRight: cellBorder }}>{hardwareDetail.code}</TableCell>
      <TableCell style={{ borderRight: cellBorder, height: 45, width: 210 }}>{SourceSelect}</TableCell>
      <TableCell style={{ borderRight: cellBorder, borderBottomRightRadius: isLastRow ? 8 : 0 }}>
        <ValueEdit
          pricing={pricing}
          code={hardwareDetail.code}
          currencySymbol={currencySymbol}
          onSetValue={(value) => {
            onCostSourceChange(hardwareDetail.code, costSource, value)
          }}
          onEditChange={onEditChange}
        />
      </TableCell>
    </TableRow>
  )
}

export default CustomiseHardwareCostTableRow

import { ListItemText } from '@material-ui/core'
import React from 'react'
import { useTranslate } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  root: {
    margin: 0,
  },
  primary: {
    fontSize: 14,
    paddingLeft: 40,
    fontWeight: 'inherit',
  },
}))

const MenuItemLinkText = ({ label }: { label: string }) => {
  const translate = useTranslate()
  const classes = useStyles()
  return <ListItemText classes={{ root: classes.root, primary: classes.primary }} primary={translate(label)} />
}

export default MenuItemLinkText

import classnames from 'classnames'
import { memo, useContext, useMemo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import SegenPlaceOrderButton from './ButtonCheckoutSegen'

import { Typography } from '@material-ui/core'
import CreditLimit from '../CreditLimit'
import OrderSummary from './OrderSummary'
import { creditLimitOverride } from './SegenCartActions'
import { AccountContext } from './SegenCheckoutProvider'
import SegenDisclaimer from './SegenDisclaimer'

const useSideActionStyles = makeOpenSolarStyles((theme) => ({
  priceBreakdownContainer: {
    padding: 20,
  },
  creditLimitContainer: {
    marginBottom: 15,
    padding: 15,
  },
  container: {
    boxSizing: 'border-box',
    border: theme.border.default,
    borderRadius: '8px',
    overflow: 'hidden',
  },
  actionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.greyLight3,
    borderBottom: '8px',
    marginTop: '20px',
    padding: '20px 30px',
  },
  buttonContainer: {
    textAlign: 'center',
    padding: '0px 20px',
  },
  chipContainer: {
    textAlign: 'center',
    padding: '20px 35px 0px 35px',
  },
  chip: {
    background: '#E1FAEA',
    padding: '8px',
  },
}))

const PlaceOrderSideAction = ({ handlePlaceOrder }: { handlePlaceOrder(): void }) => {
  const { loading, account } = useContext(AccountContext)
  const currencySymbol = account?.currency.symbol
  const classes = useSideActionStyles()
  const creditLimit = creditLimitOverride() || account?.accountSummary.creditLimit.amount
  const CreditLimitComponent = useMemo(
    () => (
      <CreditLimit loading={loading} currencySymbol={currencySymbol} creditLimit={creditLimit} supplierName="Segen" />
    ),
    [loading, account]
  )

  return (
    <div>
      <div className={classnames(classes.creditLimitContainer, classes.container)}>{CreditLimitComponent}</div>
      <div className={classes.container}>
        <div className={classes.priceBreakdownContainer}>
          <OrderSummary currencySymbol={currencySymbol} />
        </div>
        <div className={classes.buttonContainer}>
          <SegenPlaceOrderButton onClick={handlePlaceOrder} trackSource="side_action" />
        </div>
        <div className={classes.chipContainer}>
          <div className={classes.chip}>
            <Typography style={{ color: '#018030' }}>Schedule and pay at dispatch!</Typography>
          </div>
        </div>
        <div className={classes.actionWrapper}>
          <SegenDisclaimer />
        </div>
      </div>
    </div>
  )
}

export default memo(PlaceOrderSideAction)

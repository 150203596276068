import { transactionSelectors } from 'ducks/transaction'
import { ActionDataType } from 'myenergy/selectionComponent/loanApplicationButton/types'
import React, { useEffect, useMemo, useState } from 'react'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { quoteAcceptanceContentItems } from 'resources/utilityTariffs/common'
import { ProposalDataType } from 'types/proposals'
import { compileTerms, getExhibitTerms } from 'util/misc'
import { STRIPE_PAYMENT_METHOD } from '../constants'
import { CheckTermType } from '../types'
import CheckoutContractDisplay from './CheckoutContractDisplay'
import CheckoutTermsLine from './CheckoutTermsLine'
import NativeCheckoutNameInput from './NativeCheckoutNameInput'
import NativeSignaturePad from './NativeSignaturePad'
import CheckoutPayment from './payments/CheckoutPayment'

type PropTypes = {
  proposalData: ProposalDataType
  toggleFullScreen: () => void
}

const NativeCheckoutContents: React.FC<PropTypes> = (props) => {
  const [termsItems, setTermsItems] = useState<CheckTermType[]>([])

  const formState = useFormState()

  const transactionRequestData = useSelector(transactionSelectors.getTransactionRequestData) as ActionDataType
  const hideInputs =
    transactionRequestData?.docusign_contract_complete || transactionRequestData?.status_code === 'complete'

  useEffect(() => {
    let exhibitTerms = [] as any[]
    if (props.proposalData?.selectedProject?.proposal_data?.systems) {
      exhibitTerms = getExhibitTerms(
        props.proposalData?.selectedProject?.proposal_data?.systems?.find(
          // @ts-ignore
          (sys) => sys?.data?.uuid === transactionRequestData.system_uuid
          // @ts-ignore
        )?.data
      )
    }
    const paymentOptionQuoteContent =
      props.proposalData?.selectedPaymentOption?.custom_data?.extra_quote_acceptance_content
    const checkTerms = compileTerms(
      quoteAcceptanceContentItems(transactionRequestData.quote_acceptance_content),
      exhibitTerms,
      paymentOptionQuoteContent
    )
    setTermsItems(checkTerms)
  }, [])

  // The name input is used when agreeing to terms and/or initiating a stripe payment. So if there are terms to agree to
  // or this is a strip payment then we render the name input which is a required field
  const showNameInput = useMemo(() => {
    return (
      (transactionRequestData?.quote_acceptance_content?.length ||
        formState.values?.selected_payment_method === STRIPE_PAYMENT_METHOD) &&
      !hideInputs
    )
  }, [hideInputs, formState.values?.selected_payment_method, transactionRequestData?.quote_acceptance_content])

  return (
    <div id="checkout-form-contents">
      <CheckoutContractDisplay
        proposalData={props.proposalData}
        toggleFullScreen={props.toggleFullScreen}
        systemId={transactionRequestData?.system_id}
      />
      {!hideInputs && termsItems?.map((term, i) => <CheckoutTermsLine termLine={term} index={i} key={i} />)}
      {showNameInput && <NativeCheckoutNameInput />}
      <CheckoutPayment proposalData={props.proposalData} />
      <NativeSignaturePad />
    </div>
  )
}
export default NativeCheckoutContents

import { MenuItem, TextField } from '@material-ui/core'
import { PrimaryButton } from 'elements/button/GenericButton'
import CustomSelectField from 'elements/field/CustomSelectField'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { withTranslate } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import Panel from '../Designer/Panel'
import { markFieldActive, markFieldInactive, refreshPanelGeneric, stripKeyForActiveTextField } from '../Studio/Utils'

import withStudioSignals from 'Studio/signals/withStudioSignals'
import { getMeasurementsFromState } from '../util/misc'

class PanelAnnotation extends Component {
  constructor(props) {
    super(props)
    var state = {
      visible: false,
      annotation: null,
      label: '',
      description: '',
      linkedAnnotations: [],
      unlinkedAnnotationsWithSameLabel: [],
      allowEdit: true,
    }

    var injectState = props.state ? props.state : null
    if (props.annotation) {
      injectState = this.stateFromObject(props.annotation)
    }

    //Override with any supplied state - for use in storybook
    if (injectState) {
      for (var key in injectState) {
        state[key] = injectState[key]
      }
    }

    this.state = state
  }

  componentDidMount() {
    this.props.useStudioSignalsLazy(this.refreshPanel, ['objectChanged', 'objectSelected', 'sceneGraphChanged'], this)
  }

  stateFromObject(annotation) {
    if (!annotation || annotation.type !== 'OsAnnotation') {
      return { visible: false }
    }

    const annotations = window.editor.selectedSystem?.annotations()
    const unlinkedAnnotationsWithSameLabel = annotations.filter(
      (a) =>
        annotation.label &&
        a.label === annotation.label &&
        a.uuid !== annotation.uuid &&
        (a.description !== annotation.description || a.override_show_customer !== annotation.override_show_customer)
    )
    const linkedAnnotations = annotations.filter((a) => annotation.label && a.label === annotation.label)
    var _state = {
      visible: true,
      annotation: annotation,
      label: annotation.label || '',
      description: annotation.description || '',
      linkedAnnotations,
      unlinkedAnnotationsWithSameLabel,
    }

    stripKeyForActiveTextField.call(this, _state, annotation)
    return _state
  }

  refreshPanel() {
    refreshPanelGeneric.call(this, 'OsAnnotation')
  }

  disableRotation() {
    return true
  }

  copyToOtherAnnotations() {
    const { annotation, linkedAnnotations } = this.state
    linkedAnnotations.forEach((a) => {
      window.editor.execute(
        new window.SetValueCommand(
          a,
          'description',
          annotation.description,
          window.Utils.generateCommandUUIDOrUseGlobal()
        )
      )

      window.editor.execute(
        new window.SetValueCommand(
          a,
          'override_show_customer',
          annotation.override_show_customer,
          window.Utils.generateCommandUUIDOrUseGlobal()
        )
      )
    })
  }

  render() {
    if (this.state.visible !== true) {
      return null
    } else if (this.state.annotation && this.state.annotation.ghostMode()) {
      const { translate } = this.props
      return (
        <Panel
          showTools={false}
          selectedObject={this.state.annotation}
          title={translate('Placing Annotation')}
          summary={null}
          content={
            <p style={{ marginTop: 0 }}>
              {translate('Click anywhere on the imagery to drop the annotation marker and denote a location.')}
            </p>
          }
        />
      )
    } else {
      var _this = this
      const { annotation, label, description, linkedAnnotations, unlinkedAnnotationsWithSameLabel } = this.state
      const { translate } = this.props
      const errorMessage = label && label.length > 3 && 'Maximum 3 characters'
      return (
        <Panel
          showTools={this.state.allowEdit}
          selectedObject={this.state.annotation}
          title={translate('Annotation')}
          summary={null}
          content={null}
          feature={
            <div style={{ paddingRight: 10 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: 54 }}>{translate('Label')}</div>
                <TextField
                  type="text"
                  style={{ width: 100 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder={annotation.number}
                  label={null}
                  name={'label'}
                  value={label}
                  disabled={!this.state.allowEdit}
                  inputProps={{
                    maxLength: 3,
                  }}
                  InputProps={{}}
                  onFocus={() => {
                    markFieldActive.call(_this, 'label', annotation)
                  }}
                  onBlur={() => {
                    markFieldInactive.call(this)
                  }}
                  error={!!errorMessage}
                  helperText={errorMessage}
                  onChange={(event) => {
                    const newValue = event.target.value
                    this.setState({ label: newValue })
                    if (newValue && newValue.length > 3) return
                    window.editor.execute(
                      new window.SetValueCommand(
                        annotation,
                        'label',
                        newValue,
                        window.Utils.generateCommandUUIDOrUseGlobal()
                      )
                    )
                  }}
                />
              </div>

              <TextField
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: '100%', marginTop: 30 }}
                label={translate('Description')}
                name={'description'}
                value={description}
                disabled={!this.state.allowEdit}
                inputProps={{
                  maxLength: 200,
                }}
                InputProps={{}}
                onFocus={() => {
                  markFieldActive.call(_this, 'description', annotation)
                }}
                onBlur={() => {
                  markFieldInactive.call(this)
                }}
                onChange={(event) => {
                  const newValue = event.target.value
                  this.setState({ description: newValue })

                  window.editor.execute(
                    new window.SetValueCommand(
                      annotation,
                      'description',
                      newValue,
                      window.Utils.generateCommandUUIDOrUseGlobal()
                    )
                  )
                }}
              />
              <CustomSelectField
                style={{ width: 230, marginTop: 30 }}
                label={translate('Show Customer')}
                value={annotation.override_show_customer}
                defaultValue={null}
                displayEmpty={true}
                disabled={!this.state.allowEdit}
                onChange={(event) =>
                  window.editor.execute(
                    new window.SetValueCommand(
                      annotation,
                      'override_show_customer',
                      event.target.value,
                      window.Utils.generateCommandUUIDOrUseGlobal()
                    )
                  )
                }
              >
                <MenuItem value={false}>{translate('Hide')}</MenuItem>
                <MenuItem value={true}>{translate('Show')}</MenuItem>
              </CustomSelectField>
              {unlinkedAnnotationsWithSameLabel.length > 0 && (
                <>
                  <PrimaryButton
                    disabled={!this.state.allowEdit}
                    variant="contained"
                    style={{
                      border: '1px solid #dcdcdc',
                      width: '100%',
                    }}
                    wrapperStyle={{
                      marginTop: 30,
                    }}
                    labelWrapperStyle={{
                      padding: '0px 4px',
                      fontSize: 13,
                      textTransform: 'none',
                      fontWeight: 'normal',
                      whiteSpace: 'normal',
                      textOverflow: 'normal',
                    }}
                    onClick={this.copyToOtherAnnotations.bind(this)}
                    label={translate('Copy To Other Annotations')}
                  />
                  <p style={{ color: '#808080', margin: '10px 0', fontSize: 12 }}>
                    {/*{`This will update the Description and Show Customer fields for all other annotations (${*/}
                    {/*  linkedAnnotations.length - 1*/}
                    {/*}) with the same label.`}*/}
                    {translate('description_and_show_customer', { linkedLength: linkedAnnotations.length - 1 })}
                  </p>
                </>
              )}
            </div>
          }
        />
      )
    }
  }
}

PanelAnnotation.propTypes = {
  state: PropTypes.object,
}

export default compose(
  withTranslate,
  withStudioSignals,
  connect(
    (state) => ({
      measurements: getMeasurementsFromState(state),
    }),
    {}
  )
)(PanelAnnotation)

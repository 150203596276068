import { Grid, GridJustification } from '@material-ui/core'
import type { OpenSolarThemeType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import ListResultNotFound from 'elements/list/ListResultNotFound'
import LoadingDots from 'layout/widgets/LoadingDots'
import CatalogItem from 'pages/ordering/catalog/CatalogItem'
import React, { useCallback } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { DistributorDataType } from '../type'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  animationContainer: {
    minHeight: 220,
    margin: '0 0 20px 0',
    display: 'flex',
    flexWrap: 'wrap',
    border: '1px',
  },
  catalogItem: {
    flexGrow: 0,
    margin: '20px 0',
    [theme.breakpoints.up('xs')]: {
      flexBasis: '50%',
    },
    [theme.breakpoints.up('sm')]: {
      flexBasis: '50%',
    },
    [theme.breakpoints.up('lg')]: {
      flexBasis: '25%',
    },
    [theme.breakpoints.up('xl')]: {
      flexBasis: '20%',
    },
  },
}))

export type CatalogItemDataType = {
  code: string
  manufacturer_name: string
  short_description?: string
  image_url?: string
  distributors?: DistributorDataType[]
}

const HardwareCatalog = ({
  total,
  trackSource,
  data,
  onNext,
  justify,
  loading,
  Pagination,
}: {
  total: number
  trackSource: string
  data: (CatalogItemDataType | undefined)[]
  onNext(code: string): void
  justify?: GridJustification
  loading: boolean
  Pagination?: React.ReactNode
}) => {
  const classes = useStyles()
  const dataToDisplay: CatalogItemDataType[] = []
  data.forEach((a: CatalogItemDataType | undefined) => {
    if (a != null) dataToDisplay.push(a)
  })
  const handleClick = useCallback((code: string) => {
    logAmplitudeEvent('hardware_catalog_item_clicked', {
      source: trackSource,
    })
    onNext(encodeURIComponent(code))
  }, [])

  if (total === 0) {
    return loading ? (
      <div className={classes.animationContainer}>
        <LoadingDots style={{ margin: '0 auto' }} />
      </div>
    ) : (
      <ListResultNotFound />
    )
  }

  return (
    <>
      {Pagination != null && Pagination}
      <Grid justify={justify} container>
        {dataToDisplay.map((item: CatalogItemDataType) => {
          return (
            <div key={item.code} className={classes.catalogItem}>
              <CatalogItem data={item} handleClick={handleClick} />
            </div>
          )
        })}
      </Grid>
      {Pagination != null && Pagination}
    </>
  )
}

export default HardwareCatalog

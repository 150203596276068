import { MenuItem } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orgSelectors } from 'ducks/orgs'
import type LineItemType from 'pages/ordering/OrderLineItem'
import { OrderLineItem } from 'pages/ordering/OrderLineItem'
import React, { useCallback } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import type ProjectOrderPresenter from '../../../../ProjectOrderPresenter/projectOrderPresenter'
import useStyles from '../styles'

const IndividualQuantityOptions = ({
  lineItem,
  orderingPresenter,
  updateLineItem,
  closeDropdown,
  trackSource,
}: {
  lineItem: LineItemType
  orderingPresenter: ProjectOrderPresenter
  updateLineItem(newLineItem: LineItemType): void
  closeDropdown(): void
  trackSource: string
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const requiredQuantityForProject = lineItem.projectOrder?.reduce((sum, current) => sum + current.requiredQuantity, 0)
  const selectedSupplierFilterKey = useSelector(orgSelectors.getSelectedHardwareSupplierFilterKey)

  const handleClick = useCallback(() => {
    logAmplitudeEvent('hardware_quantity_field_selector', {
      source: trackSource,
      action: 'clicked',
      type: 'bulk',
      context: 'individual_quantity',
    })
    closeDropdown()

    const defaultSelectedIndividualVariant =
      lineItem.data?.distributors &&
      selectedSupplierFilterKey &&
      OrderLineItem.getDefaultSelectedVariant(lineItem.data.distributors, selectedSupplierFilterKey)
    const newLineItem = new OrderLineItem({
      ...lineItem,
      variantId: defaultSelectedIndividualVariant?.variant_id,
      selectedDistributorOrderingData: defaultSelectedIndividualVariant,
      selectedDistributor: defaultSelectedIndividualVariant?.distributor || lineItem.selectedDistributor,
      quantity: requiredQuantityForProject || 1,
      quantityPerItem: 1,
      status: !!lineItem.data ? 'loaded' : 'loading',
    })

    updateLineItem(newLineItem)
  }, [requiredQuantityForProject])

  if (!requiredQuantityForProject) {
    return null
  }
  return (
    <>
      <div className={classes.selectionHeader}>Individual Quantity</div>
      <MenuItem onClick={handleClick} className={classes.menuItem}>
        {requiredQuantityForProject} ({translate('required_for_project', lineItem.projectOrder?.length || 1)})
      </MenuItem>
    </>
  )
}

export const createBulkSelectIndividualOption = (
  trackSource: string,
  lineItem: LineItemType,
  orderingPresenter: ProjectOrderPresenter,
  handleUpdate: (newLineItem: LineItemType) => void
) => {
  return ({ closeDropdown }) => (
    <IndividualQuantityOptions
      trackSource={trackSource}
      closeDropdown={closeDropdown}
      lineItem={lineItem}
      updateLineItem={handleUpdate}
      orderingPresenter={orderingPresenter}
    />
  )
}

export default IndividualQuantityOptions

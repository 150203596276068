import type LineItemType from 'pages/ordering/OrderLineItem'
import OrderLineItem from 'pages/ordering/OrderLineItem'
import type ProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/projectOrderPresenter'
import type { SelectorOptionComponentType } from 'pages/ordering/order/table/quantity/Selector'
import { createBulkQuantityOptions } from 'pages/ordering/order/table/quantity/bulk/BulkQuantityOptions'
import { createBulkSelectIndividualOption } from 'pages/ordering/order/table/quantity/bulk/IndividualQuantityOptions'
import { createDiscountOptions } from 'pages/ordering/order/table/quantity/discount/DiscountOptions'
import type { QuantitySelectorType } from 'pages/ordering/order/table/quantity/useQuantitySelectorType'

const EMPTY_OPTIONS = []

function getBulkSelectOptions({
  lineItem,
  handleSelect,
  orderingPresenter,
  trackSource,
}: {
  lineItem: LineItemType
  handleSelect: (newLineItem: LineItemType) => void
  orderingPresenter: ProjectOrderPresenter
  trackSource: string
}) {
  const IndividualOption = createBulkSelectIndividualOption(trackSource, lineItem, orderingPresenter, handleSelect)
  const BulkQuantityOptions = createBulkQuantityOptions(trackSource, lineItem, handleSelect)
  const optionComponents: SelectorOptionComponentType[] = [IndividualOption, BulkQuantityOptions]
  return optionComponents
}

function getDiscountSelectOptions({
  lineItem,
  handleSelect,
  trackSource,
}: {
  lineItem: LineItemType
  handleSelect: (newLineItem: LineItemType) => void
  trackSource: string
}) {
  const updateLineItemQuantity = (quantity: number) => {
    handleSelect(
      new OrderLineItem({
        ...lineItem,
        quantity,
      })
    )
  }
  const DiscountOptions = createDiscountOptions(trackSource, lineItem, updateLineItemQuantity)
  return [DiscountOptions]
}

const getSelectorOptions = ({
  lineItem,
  handleSelect,
  orderingPresenter,
  type,
  trackSource,
}: {
  lineItem: LineItemType
  handleSelect: (newLineItem: LineItemType) => void
  orderingPresenter: ProjectOrderPresenter
  type?: QuantitySelectorType
  trackSource: string
}) => {
  if (type === 'bulk') {
    return getBulkSelectOptions({ trackSource, lineItem, handleSelect, orderingPresenter })
  }

  if (type === 'discount') {
    return getDiscountSelectOptions({ trackSource, lineItem, handleSelect })
  }

  return EMPTY_OPTIONS
}

export default getSelectorOptions

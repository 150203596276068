import { InputAdornment, makeStyles, TextField } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/ClearOutlined'
import SearchIcon from '@material-ui/icons/SearchOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import lodashDebounce from 'lodash/debounce'
import { IconButton } from 'opensolar-ui'
import React, { useCallback, useMemo } from 'react'
import { useTranslate } from 'react-admin'
import type { ComponentTypes } from 'types/selectComponent'
import { Theme } from 'types/themes'
import { LABELS_FOR_COMPONENT_TYPES } from '../HardwareSelector'

const DEFAULT_DEBOUNCE = 500

const useStyles = makeStyles(
  (theme: Theme) => ({
    input: {
      padding: '12px 0',
    },
    outlined: {},
    supportLink: {
      whiteSpace: 'nowrap',
    },
    searchInput: {
      background: '#e0e0e0',
      flexGrow: 1,
      marginRight: 10,
      textOverflow: 'ellipsis',
    },
    icon: {
      width: 20,
      height: 20,
      color: 'rgba(0,0,0,1)',
    },
    helper: {
      textAlign: 'center',
      display: 'inline-block',
      [theme.breakpoints.down('md')]: {
        marginLeft: 'auto',
        marginTop: 10,
      },
      '& a': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
    searchWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
    },
  }),
  { name: 'HardwareSearchBar' }
)

// TODO: accept value override
const SearchBar = ({ componentType, setFilters }: { componentType: ComponentTypes; setFilters: Function }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const [filterValue, setFilterValue] = React.useState('')

  const trackValueChanged = useCallback(() => {
    logAmplitudeEvent('generic_filter_interacted', {
      action: 'updated',
      key: 'q',
    })
  }, [])

  const debouncedTrackValueChanged = useMemo(() => {
    return lodashDebounce(trackValueChanged, DEFAULT_DEBOUNCE)
  }, [trackValueChanged])

  const handleChange = useCallback(
    (value: string) => {
      debouncedTrackValueChanged()
      setFilterValue(value)
      setFilters({ q: value })
    },
    [setFilters]
  )
  return (
    <div className={classes.searchWrapper}>
      <TextField
        id="Tariff-Search-Bar"
        variant="outlined"
        autoFocus={true}
        value={filterValue}
        classes={{ root: classes.searchInput }}
        InputProps={{
          classes: { input: classes.input, root: classes.outlined },
          startAdornment: (
            <InputAdornment position="start">
              <IconButton size="small">
                <SearchIcon style={{ width: 24, height: 24 }} classes={{ root: classes.icon }} />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: filterValue && (
            <InputAdornment position="end">
              {' '}
              <IconButton
                size="small"
                onClick={(e) => {
                  handleChange('')
                }}
              >
                <ClearIcon classes={{ root: classes.icon }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onFocus={() => {
          try {
            window.editor.signals.typingInField.dispatch(true)
          } catch (err) {}
        }}
        onBlur={() => {
          try {
            window.editor.signals.typingInField.dispatch(false)
          } catch (err) {}
        }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          handleChange(event.target.value)
        }}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            ev.preventDefault()
          }
        }}
        placeholder={translate(LABELS_FOR_COMPONENT_TYPES[componentType]?.searchHint)}
      />
      <div className={classes.helper}>
        {translate(LABELS_FOR_COMPONENT_TYPES[componentType]?.cannotFind)} <br />
        <a
          href={'https://support.opensolar.com/hc/requests/new'}
          target="_blank"
          rel="noreferrer"
          className={classes.supportLink}
        >
          Let us know
        </a>
      </div>
    </div>
  )
}

export default SearchBar

import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip, makeStyles } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import LoadingDots from 'layout/widgets/LoadingDots'
import {} from 'opensolar-ui'
import { useIsCalculating } from 'projectSections/sections/payments/projectProgress/cashFlow/utils'
import { useTranslate } from 'ra-core'
import React from 'react'
import { Theme } from 'types/themes'
import LightReachPricingBandRow from './LightReachPricingBandRow'

type PropTypes = {
  systemUuid: string
  paymentOptionId?: number | null | undefined
  lightReachPaymentOptions
  estimatedUtilitySavings: number
  selectedPricing: string
  setSelectedPricing: (string) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: 35,
  },

  table: {
    tableLayout: 'fixed',
    '& thead': {
      borderTop: '1px solid #e7e7e7',
    },
    '& th:nth-child(1)': {
      borderLeft: '1px solid #e7e7e7',
    },
    '& th:last-child': {
      borderRight: '1px solid #e7e7e7',
    },
    '& td': {
      padding: '10px 16px',
      borderWidth: 1,
      borderColor: '#e7e7e7',
    },
    '& thead tr': {
      background: '#F8F8F8',
    },
    '& thead th': {
      padding: '10px 16px',
    },
    '& td:nth-child(1)': {
      borderLeft: '1px solid #e7e7e7',
    },
    '& td:last-child': {
      borderRight: '1px solid #e7e7e7',
    },
  },

  icon: {
    fontSize: 15,
    marginLeft: 5,
  },
}))

const LightReachPricingBands: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const isCalculating = useIsCalculating(props.systemUuid)

  return (
    <div className={classes.container}>
      <div key={props.lightReachPaymentOptions.id}>
        <h3>{translate('Select Pricing for this payment option from the table below:')}</h3>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">{translate('Solar Rate')}</TableCell>
              <TableCell align="left">{translate('Monthly Payment')}</TableCell>
              <TableCell align="left">
                {translate('Monthly Savings')}
                <Tooltip
                  title={
                    "Monthly Savings show estimated year 1 monthly savings by comparing the system's Estimated Utility Savings to the Monthly Payment for the payment option. The green arrow indicates a monthly payment is estimated to be cashflow positive in year one."
                  }
                >
                  <InfoOutlined className={classes.icon} />
                </Tooltip>
              </TableCell>
              <TableCell align="left">{translate('PPW')}</TableCell>
              <TableCell align="left">{translate('Revenue')}</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isCalculating || !props.lightReachPaymentOptions?.pricing_options ? (
              <TableCell colSpan={6}>
                <LoadingDots text="One moment while we update your pricing bands..." />
              </TableCell>
            ) : (
              props.lightReachPaymentOptions?.pricing_options?.map((opt, i) => (
                <LightReachPricingBandRow
                  key={i}
                  pricingOption={opt}
                  systemUuid={props.systemUuid}
                  paymentOptionId={props.lightReachPaymentOptions.id}
                  estimatedUtilitySavings={props.estimatedUtilitySavings}
                  selectedPricing={props.selectedPricing}
                  setSelectedPricing={props.setSelectedPricing}
                />
              ))
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}
export default LightReachPricingBands

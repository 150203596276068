import { orgSelectors } from 'ducks/orgs'
import _ from 'lodash'
import type LineItemType from 'pages/ordering/OrderLineItem'
import { OrderLineItem } from 'pages/ordering/OrderLineItem'
import type { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { useComponentCost } from 'projectSections/hooks/useComponentCost'
import type { HardwareDetailType } from 'projectSections/sections/design/systems/tabs/summary/hardware/getHardwareDetailFromSystem'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import type { ComponentTypes } from 'types/selectComponent'
import { isMountingComponent } from 'util/misc'

export type BomLineItemsHashMapKey = ComponentTypes | 'mounting' | 'all'

export type BomLineItemsHashMap = { [key in BomLineItemsHashMapKey]: OrderLineItem[] }

export const getComponentDetail = ({ hardwareDetail }: { hardwareDetail: HardwareDetailType }) => {
  switch (hardwareDetail.type) {
    case 'module':
      return _.find(window.AccountHelper.loadedData.componentModuleSpecs, { code: hardwareDetail.code })
    case 'inverter':
      return _.find(window.AccountHelper.loadedData.componentInverterSpecs, { code: hardwareDetail.code })
    case 'battery':
      return _.find(window.AccountHelper.loadedData.componentBatterySpecs, { code: hardwareDetail.code })
    case 'other':
      return _.find(window.AccountHelper.loadedData.componentOtherSpecs, { code: hardwareDetail.code })
    default:
      return undefined
  }
}

export const getLineItemFromHardwareDetail = ({
  hardwareDetail,
  supplierFilterKey,
}: {
  hardwareDetail: HardwareDetailType
  supplierFilterKey: HardwareSupplierFilterKeyType | undefined
}): LineItemType | undefined => {
  const loadedComponentDetail = getComponentDetail({ hardwareDetail })
  if (loadedComponentDetail === undefined || supplierFilterKey === undefined) {
    return undefined
  }
  return new OrderLineItem({
    componentType: hardwareDetail.type,
    otherComponentType: loadedComponentDetail.other_component_type,
    status: 'loaded',
    code: hardwareDetail.code,
    selectedDistributor: supplierFilterKey,
    data: {
      code: hardwareDetail.code,
      component_type: hardwareDetail.type,
      description: loadedComponentDetail.description || '',
      short_description: loadedComponentDetail?.short_description || '',
      distributors: loadedComponentDetail.ordering || [],
      data: JSON.stringify({}),
      // @ts-ignore somehow the id type is inconsistent?? Not a problem from this file
      id: loadedComponentDetail.id,
      manufacturer_name: loadedComponentDetail.manufacturer_name,
      title: loadedComponentDetail.title || '',
    },
    confirmed: true,
    quantity: hardwareDetail.quantity,
  })
}

const useMapHardwareDetailsToBomLineItems = (hardwareDetails: HardwareDetailType[]): BomLineItemsHashMap => {
  const supplierFilterKey = useSelector(orgSelectors.getSelectedHardwareSupplierFilterKey)
  const { componentCosts } = useComponentCost()

  return useMemo(() => {
    const bomLineItemsHashMap: BomLineItemsHashMap = {
      module: [],
      mounting: [],
      inverter: [],
      battery: [],
      other: [],
      all: [],
    }
    if (supplierFilterKey === undefined) {
      return bomLineItemsHashMap
    }

    hardwareDetails.forEach((hardwareDetail: HardwareDetailType) => {
      // If it has a non-distributor cost override, don't include it in the BOM.
      if (
        componentCosts.find(
          (componentCost) => componentCost.code === hardwareDetail.code && componentCost.source !== supplierFilterKey
        )
      ) {
        return
      }

      const lineItem = getLineItemFromHardwareDetail({ hardwareDetail, supplierFilterKey })

      if (lineItem !== undefined) {
        const loadedComponentDetail = getComponentDetail({ hardwareDetail })
        const bomComponentType = isMountingComponent(loadedComponentDetail?.other_component_type)
          ? 'mounting'
          : hardwareDetail.type
        // Look up existing line item and add to quantity if required. This is to handle the case where the same component
        // is added multiple times. This is re-implements the changes in 12036 more safely.
        const existingLineItem = bomLineItemsHashMap['all']?.find((l) => l.code === lineItem.code)

        if (existingLineItem) {
          // Update quantity
          lineItem.quantity = existingLineItem.quantity += lineItem.quantity

          // Remove from 'all' array
          const existingAllIndex = bomLineItemsHashMap['all'].indexOf(existingLineItem)
          bomLineItemsHashMap['all'].splice(existingAllIndex, 1)

          // Remove from specific component type array
          const existingComponentTypeIndex = bomLineItemsHashMap[bomComponentType].indexOf(existingLineItem)
          bomLineItemsHashMap[bomComponentType].splice(existingComponentTypeIndex, 1)
        }

        bomLineItemsHashMap[bomComponentType].push(lineItem)
        bomLineItemsHashMap['all'].push(lineItem)
      }
    })

    return bomLineItemsHashMap
  }, [hardwareDetails, supplierFilterKey, componentCosts])
}

export default useMapHardwareDetailsToBomLineItems

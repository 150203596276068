import { FormControlLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core'
import { authReload } from 'actions/authActions'
import { Button } from 'opensolar-ui'
import { useState } from 'react'
import { usePermissions, userLogin } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import appStorage from 'storage/appStorage'
import { doNotTranslate, parseQueryStringToDictionary } from 'util/misc'
import { getOrgFromState } from 'util/org'
import AuthModalWrapper from './AuthModalWrapper'
import { NearmapLogo } from './common'

const Restricted = (props: any) => {
  const { loaded, permissions } = usePermissions()

  if (
    props.authParams.permission !== permissions &&
    permissions &&
    permissions.indexOf(props.authParams.permission) === -1 &&
    loaded === true
  ) {
    // Do not redirect before permissions have finished loading
    return <Redirect to={props.authParams.redirectTo} />
  } else {
    return props.children
  }
}

const StyledRadio = (props: any) => {
  const classes = useStyles()
  return <Radio color="default" classes={classes} {...props} />
}

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    width: 280,
    marginTop: 0,
    minWidth: 210,
    padding: '0.5em 1em 1em 1em',
  },
  button: {
    color: theme.headerFontColor,
    background: theme.themeColor,
    '&:hover': {
      cursor: 'pointer',
      background: theme.themeColorDark,
    },
  },
  root: {
    '&$checked': {
      //@ts-ignore
      color: theme?.themeColor,
    },
  },
  checked: {},
}))

const LoginNearmapChooseCreateOrUpgrade = () => {
  const [nearmap_upgrade_org_id, setUpgradeOrgId] = useState<number | undefined>()
  const { orgId, orgs, orgName, userLoginAuthority } = useSelector((state: any) => ({
    orgId: state.auth?.org_id,
    orgName: getOrgFromState(state)?.name,
    userLoginAuthority: state.auth && state.auth.user ? state.auth.user.login_authority : null,
    orgs: state.auth?.orgs,
  }))
  const classes = useStyles()
  const dispatch = useDispatch()
  const location = useLocation()

  const loginWithNearmapToken = (nearmap_token: string, upgrade_action: string, upgrade_org_id: number | null) => {
    // // Special cases BROKEN because localStorage org_id gets updated by other updates after fixing them here:
    // //
    // // 1. New Account
    // // If we are creating a new org then we intentionally forget any org_id in localStorage
    // // This ensures we will switch to the newly created org id instead of retaining the old org id
    // //
    // // 2. Upgrade Account
    // // Ensure we switch to this account. Probably redundant but may be useful when multiple orgs are linked.
    //
    // if (upgrade_action === 'new_account') {
    //   setOrgId(null)
    // } else if (upgrade_org_id) {
    //   setOrgId(upgrade_org_id)
    // }

    const { state: pathName = '/projects' } = location
    dispatch(
      userLogin({
        payload: {
          nearmap_token: nearmap_token,
          upgrade_action: upgrade_action,
          upgrade_org_id: upgrade_org_id,
          sendBearerToken: true,
        },
        state: { nextPathname: pathName },
        nearmapToken: nearmap_token,
      })
    )
  }

  return (
    <Restricted authParams={{ permission: 'is_pro', redirectTo: '/login-modal' }} location={location}>
      <AuthModalWrapper>
        <div className={classes.wrapper}>
          <div style={{ textAlign: 'center' }}>
            <NearmapLogo />
          </div>

          {orgName ? (
            <p>
              {doNotTranslate('You are currently logged into OpenSolar Account:')} <strong>{orgName}</strong>
            </p>
          ) : (
            <p>{doNotTranslate('You are not yet logged into an OpenSolar Account.')}</p>
          )}

          {userLoginAuthority !== 'nearmap' && <p>Your user login will have Login With Nearmap enabled.</p>}

          <p>{doNotTranslate('Please make a selection to setup Nearmap on OpenSolar:')}</p>
          <RadioGroup
            name="nearmap_upgrade_org_id"
            value={nearmap_upgrade_org_id || orgs?.filter((org: any) => org?.id === orgId) || null}
            onChange={(event) => {
              const value = event.target.value
              setUpgradeOrgId(parseInt(value))
            }}
          >
            {(orgs
              ? [...orgs]
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((c) => (
                  <FormControlLabel
                    value={c.id}
                    style={{ width: '100%' }}
                    checked={c.id === nearmap_upgrade_org_id}
                    control={<StyledRadio style={{ padding: 5 }} />}
                    label={c.name}
                  />
                ))
              : []
            ).concat(
              <FormControlLabel
                value={-1}
                checked={nearmap_upgrade_org_id === -1}
                style={{ width: '100%' }}
                control={<StyledRadio style={{ padding: 5 }} />}
                label={doNotTranslate('Create New OpenSolar Account')}
              />
            )}
          </RadioGroup>

          <p className="small">
            If you wish to upgrade an existing OpenSolar account that is not visible in the options above, you should
            ask an administrator to invite you to the team as an admin, then{' '}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault()
                dispatch(authReload())
              }}
            >
              click to refresh the options
            </a>
            .
          </p>

          <div style={{ marginBottom: 10 }}>
            <Button
              classes={{ root: classes.button }}
              disabled={!Boolean(nearmap_upgrade_org_id)}
              onClick={() => {
                var action = nearmap_upgrade_org_id === -1 ? 'new_account' : 'upgrade_account'
                var upgrade_org_id =
                  nearmap_upgrade_org_id && nearmap_upgrade_org_id > 0 ? nearmap_upgrade_org_id : null

                const queryParams = parseQueryStringToDictionary(location.search)
                loginWithNearmapToken(
                  // This cast is incorrect, but preserves existing behavior.
                  (queryParams?.nearmap_token || appStorage.getString('nearmap_token')) as string,
                  action,
                  upgrade_org_id
                )
              }}
            >
              <span>{doNotTranslate('Continue')}</span>
            </Button>

            <div style={{ minHeight: 60, marginTop: '1em' }}>
              {nearmap_upgrade_org_id === -1 && (
                <p style={{ margin: 0 }}>
                  {doNotTranslate(
                    'Warning: This will create a new Nearmap on OpenSolar account and add all Nearmap team members. Your other OpenSolar accounts will not be affected.'
                  )}{' '}
                </p>
              )}
              {nearmap_upgrade_org_id && nearmap_upgrade_org_id > 0 && (
                <p style={{ margin: 0 }}>
                  {doNotTranslate(
                    'This will upgrade your selected OpenSolar account to Nearmap on OpenSolar and add all team members from your Nearmap account to OpenSolar.'
                  )}
                </p>
              )}
            </div>
          </div>
        </div>
      </AuthModalWrapper>
    </Restricted>
  )
}
export default LoginNearmapChooseCreateOrUpgrade

import type { OpenSolarThemeType } from 'Themes'
import { orgSelectors } from 'ducks/orgs'
import { permissionsSelectors } from 'ducks/permissions'
import { Grid } from 'opensolar-ui'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  header: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    // this padding is commented out since accordion functionality removed for phase 1
    // 60px for padding right accounts for placement of accordion dropdown
    // padding: '10px 60px 10px 10px',
    padding: '10px',
  },
  overflowItem: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}))

type HeaderPropsType = {}

const InverterAutoListHeader = (props: HeaderPropsType) => {
  const isAU = useSelector(orgSelectors.getOrgIso2) === 'AU'
  const classes = useStyles()
  const translate = useTranslate()
  const distributorPricingRolePermission = useSelector(permissionsSelectors.getPermissionByKey('distributor_pricing'))

  const hasEnabledDistributors = useSelector(orgSelectors.getEnabledHardwareSuppliers).length > 0
  const shouldDisplayPriceAndStocks = hasEnabledDistributors && distributorPricingRolePermission.allowView

  return (
    <Grid container alignItems="center" xs={12} className={classes.header}>
      <Grid item xs={1} style={{ paddingLeft: 20 }}>
        {translate('Item')}
      </Grid>
      <Grid item xs={2} className={classes.overflowItem}>
        {translate('Brand')}
      </Grid>
      <Grid item xs={2} className={classes.overflowItem}>
        {translate('Name')}
      </Grid>
      <Grid item xs={1}>
        {translate('Quantity Needed')}
      </Grid>
      <Grid item xs={shouldDisplayPriceAndStocks ? 1: 4}>
        {translate('Combined Rating')}
      </Grid>
      {shouldDisplayPriceAndStocks && (
        <>
          <Grid item xs={2} className={classes.overflowItem}>
            {translate('Available from')}
          </Grid>
          {!isAU && (
            <Grid item xs={1}>
              {translate('Stock')}
            </Grid>
          )}
          <Grid item xs={1} className={classes.overflowItem}>
            {translate('Total Price')}
          </Grid>
        </>
      )}
      <Grid item xs={1}></Grid>
    </Grid>
  )
}

export default InverterAutoListHeader

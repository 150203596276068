import { orgSelectors } from 'ducks/orgs'
import { usePublicFeatureConfig } from 'hooks/usePublicFeatureConfig'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import type { FactoryHookType } from '../type'
import maybeCreateExhibitorOrgFilterNode from './ExhibitorOrgFilterNodeFactory'

const useExhibitorOrgFilterNode: FactoryHookType = ({ RendererComponent, options }) => {
  const manufacturerConfig = usePublicFeatureConfig('manufacturer_filter')
  const orgIso2 = useSelector(orgSelectors.getOrgIso2)

  return useMemo(() => {
    return maybeCreateExhibitorOrgFilterNode({
      manufacturerConfig,
      orgIso2,
      componentType: options.componentType,
      RendererComponent,
    })
  }, [options.componentType, manufacturerConfig, orgIso2, RendererComponent])
}

export default useExhibitorOrgFilterNode

import { Card, InputAdornment, TextField, Typography } from '@material-ui/core'
import LoadingDots from 'layout/widgets/LoadingDots'
import { Alert, FormControlLabel, Switch } from 'opensolar-ui'
import { useIsCalculating } from 'projectSections/sections/payments/projectProgress/cashFlow/utils'
import React, { useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { parseIntegrationJson } from 'util/misc'
import { useGetLightReachAdderConfig, useGetLightReachAdders } from './utils'

type PropTypes = {
  systemUuid: string | null
}

const useStyles = makeOpenSolarStyles((theme) => ({
  gridContainer: {
    border: '1px solid #E7E7E7',
    borderRadius: 10,
  },

  gridHeader: {
    background: theme.greyLight3,
    padding: '10px !important',
    borderRadius: '10px 10px 0 0',
    fontWeight: 500,
    fontSize: 'small',
  },

  backupBatteryRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  cardContainer: {
    padding: '5px 10px',
    marginBottom: 10,
  },

  toggleContainer: {
    display: 'flex !important',
    flexFlow: 'row-reverse',
    justifyContent: 'space-between',
    margin: '0px !important',
  },

  inputContainer: {
    display: 'flex !important',
    flexFlow: 'row-reverse',
    justifyContent: 'left',
    gap: 10,
    padding: 10,
  },
}))

const LightReachAdderConfig: React.FC<PropTypes> = (props) => {
  const existingAdders = useGetLightReachAdderConfig(props.systemUuid)

  const [showBackupBatteryFields, setShowBackupBatteryFields] = useState(Boolean(existingAdders?.backupBatteryIncluded))
  const form = useForm()
  const isCalculating = useIsCalculating(props.systemUuid)

  const classes = useStyles()
  const project = useFormState()?.values
  const { adders, addersAreBeingFetched } = useGetLightReachAdders(project?.id, props.systemUuid)

  const onFieldChange = (fieldName: string, newVal: boolean | string) => {
    const system = window.editor.getSystems().find((sys) => sys.uuid === props.systemUuid)
    const newIntegrationJSON = parseIntegrationJson(system?.integration_json) || {}
    if (!newIntegrationJSON.lightreach) {
      newIntegrationJSON.lighreach = {}
    }
    if (!newIntegrationJSON.lightreach.adders) {
      newIntegrationJSON.lightreach.adders = {}
    }
    newIntegrationJSON.lightreach.adders = {
      ...newIntegrationJSON.lightreach.adders,
      [fieldName]: newVal,
    }

    if (
      fieldName === 'backupBatteryIncluded' &&
      newVal &&
      hasBackupBatteryPriceCap &&
      !newIntegrationJSON.lightreach.adders.backupBatteryCost
    ) {
      // if user just toggled on backup battery but hasn't entered a cost don't save the change
      return
    } else if (fieldName === 'backupBatteryCost' && !newVal) {
      // if user just zeroed out the backup battery cost but hasn't toggled off the adder, don't save the change
      return
    } else {
      window.editor.selectedSystem.clearHashedArgs()
      window.editor.execute(new window.SetValueCommand(system, 'integration_json', newIntegrationJSON))
      form.mutators.markFieldAsDirty('design')
      form.change('design', 'has unsaved change')
    }
  }

  const toggleBackupBattery = (newVal: boolean) => {
    onFieldChange('backupBatteryIncluded', newVal)
    setShowBackupBatteryFields(newVal)
  }

  const hasArbitrageBattery = adders?.availableAdders?.find((adder) => adder.adderType === 'arbitrageBattery')
  const hasElectricalUpgrade = adders?.availableAdders?.find((adder) => adder.adderType === 'electricalUpgrade')
  const hasBackupBatteryKWH = adders?.availableAdders?.find((adder) => adder.adderType === 'backupBatteryKwh')
  const hasBackupBatteryPriceCap = adders?.availableAdders?.find((adder) => adder.adderType === 'backupBattery')

  return (
    <div>
      <h3>Select applicable price adjustments based on the system design. </h3>
      {addersAreBeingFetched ? (
        <LoadingDots />
      ) : !adders?.availableAdders?.length ? (
        <Alert severity="info"> No adders available. </Alert>
      ) : (
        <>
          {hasArbitrageBattery && (
            <AdderCard
              label={`Arbitrage Battery (+$ ${hasArbitrageBattery.value})`}
              isChecked={existingAdders?.arbitrageBatteryIncluded}
              onFieldChange={(e) => onFieldChange('arbitrageBatteryIncluded', e.target.checked)}
            />
          )}

          {hasElectricalUpgrade && (
            <AdderCard
              label={`Electrical Upgrade (+$ ${hasElectricalUpgrade.value})`}
              isChecked={existingAdders?.electricalUpgradeIncluded}
              onFieldChange={(e) => onFieldChange('electricalUpgradeIncluded', e.target.checked)}
            />
          )}

          {hasBackupBatteryKWH && (
            <AdderCard
              label={`Backup Battery $/kWh (+$ ${hasBackupBatteryKWH.value})`}
              isChecked={existingAdders?.backupBatteryKwhIncluded}
              onFieldChange={(e) => onFieldChange('backupBatteryKwhIncluded', e.target.checked)}
            />
          )}
          {hasBackupBatteryPriceCap && (
            <AdderCard
              label="Backup Battery"
              isChecked={existingAdders?.backupBatteryIncluded}
              onFieldChange={(e) => toggleBackupBattery(e.target.checked)}
            >
              <>
                {showBackupBatteryFields && (
                  <div>
                    {hasBackupBatteryPriceCap && (
                      <FormControlLabel
                        className={classes.inputContainer}
                        label={<Typography variant="body1">Backup Battery Cost</Typography>}
                        control={
                          <TextField
                            variant="outlined"
                            size="small"
                            onChange={(e) => onFieldChange('backupBatteryCost', e.target.value)}
                            defaultValue={existingAdders?.backupBatteryCost}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        }
                      />
                    )}
                  </div>
                )}
              </>
            </AdderCard>
          )}
        </>
      )}
    </div>
  )
}

const AdderCard = ({ children = <></>, label, isChecked, onFieldChange }) => {
  const classes = useStyles()

  return (
    <Card className={classes.cardContainer}>
      <FormControlLabel
        className={classes.toggleContainer}
        label={<Typography variant="body1">{label}</Typography>}
        control={<Switch defaultChecked={isChecked} onChange={onFieldChange} />}
      />
      {children}
    </Card>
  )
}
export default LightReachAdderConfig

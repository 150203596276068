// @ts-nocheck
import { Card, Grow } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { Button } from 'opensolar-ui'
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { docusignTagMap } from './constants'

import { logAmplitudeEvent } from 'amplitude/amplitude'
import Alert from 'elements/Alert'
import { useCallback } from 'react'
import appStorage from 'storage/appStorage'
import ProjectPlaceholderWizard from './ProjectPlaceholderWizard'
import TemplateWizardPDFDisplay from './TemplateWizardPDFDisplay'
import { parseValFromMultiLevelPath } from './util'

const SIZE_LIMIT = 10000000

export type OperandTypes = 'number' | 'field'
export type OperatorTypes = 'x' | '+' | '/' | '-'
export type MathOperationType = {
  operator: OperatorTypes
  operand_type: OperandTypes
  operand_value: number | string
}

export type FormatType = 'MM/DD/YYYY' | 'MMMM Do, YYYY' | 'dddd MMMM Do YYYY' | 'DD/MM/YYYY' | undefined

export interface PlaceHolderSaveConfig {
  x: number
  y: number
  width: number
  height?: number // used for images only, all text based placeholders use lines and font size to dictate height
  font_size: number
  lines: number
  round_digits: number | undefined
  use_int_comma: boolean
  default_value: string | undefined
  format_as_currency: boolean
  math_operations: MathOperationType[]
  date_format_rule: FormatType
  is_checkbox: boolean
}

export interface PlaceholderType extends PlaceHolderSaveConfig {
  field_path: string
  value: string
  field_key: string
  page_num: number
}

type PlaceholderMapTypes = {
  [key: string]: PlaceholderType
}
export type FieldType = {
  field_path: string
  value: string
}

type PropTypes = {
  record: any
}

const TemplateWizard: React.FunctionComponent<PropTypes> = (props) => {
  const form = useForm()
  const translate = useTranslate()

  const getInitialGlobalFontSize = (): number => {
    if (!placeholders || !Object.keys(placeholders)?.length) return 9
    else {
      const sizeMap = {}
      Object.values(placeholders).forEach((item) => {
        if (!sizeMap[item.font_size]) {
          sizeMap[item.font_size] = 1
        } else {
          sizeMap[item.font_size] += 1
        }
      })
      let toReturn = 9 as number | string
      let currentMaxCount = 0
      Object.keys(sizeMap).forEach((size) => {
        if (sizeMap[size] > currentMaxCount) {
          toReturn = size
          currentMaxCount = sizeMap[size]
        }
      })
      if (toReturn && typeof toReturn === 'string') toReturn = parseInt(toReturn)
      return toReturn
    }
  }

  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const [contentsUrl, setContentsUrl] = useState<string | undefined>(form.getState().values.file_contents_url) // this is temporary and only used in the initial session where a doc is given to us. It avoids us needing to make another request
  const [fileUrl, setFileUrl] = useState<string | undefined>(form.getState().values.private_file_id) //this is what we save onto the documentTempalate object
  const [pdfJsLoaded, setPdfJsLoaded] = useState<boolean>(false)
  const [fieldBeingDragged, setFieldBeingDragged] = useState<FieldType | undefined>(undefined)
  const [placeholders, setPlaceholders] = useState<PlaceholderMapTypes>(form.getState().values.content?.tags || {})
  const [pageNum, setPageNum] = useState<number>(0)
  const [selectedProjectId, setSelectedProjectId] = useState<string | undefined>(undefined)
  const [initialProjectURL, setInitialProjectURL] = useState<string | undefined>(undefined)
  const [selectedSystemId, setSelectedSystemId] = useState<string | undefined>(undefined)
  const [initialSystemURL, setInitialSystemURL] = useState<string | undefined>(undefined)
  const [selectedPaymentOptionId, setSelectedPaymentOptionId] = useState<string | undefined>(undefined)
  const [initialPaymentOptionURL, setInitialPaymentOptionURL] = useState<string | undefined>(undefined)
  const [focusedPlaceholders, setFocusedPlaceholders] = useState<PlaceholderType[]>([])
  const [copiedPlaceholders, setCopiedPlaceholders] = useState<PlaceholderType[]>([])
  const [proposalData, setProposalData] = useState<any>(undefined)
  const [activeData, setActiveData] = useState<any>(undefined)
  const [globalFontSize, setGlobalFontSize] = useState<number>(9)
  const [globalFontSizeInitialized, setGlobalFontSizeInitalized] = useState<boolean>(false)

  useEffect(() => {
    if (proposalData) {
      if (placeholders) {
        Object.values(placeholders).forEach((tag) => {
          const fieldPath = tag.field_path?.replace('popular.', '')
          if (tag && tag.field_path && proposalData && !tag.field_path.includes('docusign.')) {
            try {
              let newValue = parseValFromMultiLevelPath(fieldPath, proposalData)
              if (newValue !== undefined) tag.value = `${newValue}`
            } catch (ex) { }
          }
        })
        setPlaceholders({ ...placeholders })
      }
    }
  }, [proposalData])

  useEffect(() => {
    if (placeholders && Object.keys(placeholders).length && !globalFontSizeInitialized) {
      setGlobalFontSize(getInitialGlobalFontSize())
      setGlobalFontSizeInitalized(true)
    }
  }, [placeholders, globalFontSizeInitialized])

  const orgId: number = useSelector(authSelectors.getCurrentOrg)?.id

  useEffect(() => {
    let content = form.getState().values.content
    if (content) {
      try {
        content = JSON.parse(content)
        if (content?.sample_project_url) {
          setInitialProjectURL(content.sample_project_url)
          let projectId = content.sample_project_url
            .substring(content.sample_project_url.indexOf('projects/') + 9)
            ?.replace('/', '')
          setSelectedProjectId(projectId)
          setSelectedSystemId(undefined)
          setSelectedPaymentOptionId(undefined)
        }
        if (content?.sample_system_url) {
          setInitialSystemURL(content.sample_system_url)
          let systemId = content.sample_system_url
            .substring(content.sample_system_url.indexOf('systems/') + 8)
            ?.replace('/', '')
          setSelectedSystemId(systemId)
          setSelectedPaymentOptionId(undefined)
        }
        if (content?.sample_payment_option_url) {
          setInitialPaymentOptionURL(content.sample_payment_option_url)
          let pmtId = content.sample_payment_option_url
            .substring(content.sample_payment_option_url.indexOf('payment_options/') + 16)
            ?.replace('/', '')
          setSelectedPaymentOptionId(pmtId)
        }
      } catch (ex) { }
    }
  }, [])

  useEffect(() => {
    form.change('private_file', fileUrl)
  }, [fileUrl])

  useEffect(() => {
    if (props.record?.content) {
      try {
        let tags = JSON.parse(props.record.content)?.tags
        if (!tags || tags.length === 0) return
        let placeholdersInitialValues: PlaceholderMapTypes = {}
        tags.forEach((tag: PlaceholderType) => {
          placeholdersInitialValues[tag.field_key] = tag
        })
        setPlaceholders(placeholdersInitialValues)
      } catch (ex) { }
    }
  }, [props.record.content])

  useEffect(() => {
    if (placeholders) {
      try {
        let config = form.getState().values?.content ? JSON.parse(form.getState().values?.content) : {}
        if (config) config.tags = Object.values(placeholders)
        form.change('content', JSON.stringify(config))
      } catch (ex) { }
    }
  }, [placeholders])

  useEffect(() => {
    const minUrl = 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.7.570/build/pdf'
    // load the pdf.js library here. No need to load this package globally just wait until we need it
    var script = document.createElement('script')
    script.addEventListener('load', (e) => {
      setPdfJsLoaded(true)
      pdfjsLib.GlobalWorkerOptions.workerSrc = `${minUrl}.worker.min.js`
    })
    script.src = `${minUrl}.min.js`
    script.id = 'test-boi'
    document.head.appendChild(script)
  }, [])

  const onUpload = (files: ChangeEvent<HTMLInputElement>) => {
    const file = files.target.files[0]
    if (!file) {
      console.log('Import File was cancelled by the user')
      return
    }
    if (file.size > SIZE_LIMIT) {
      setErrorMsg('File must be below 10MB')
      return
    }
    const formData = new FormData()
    formData.append('title', 'Sample Template.pdf')
    formData.append('file_contents', file)
    fetch(`${window.API_ROOT}/api/orgs/${orgId}/private_files/`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: 'Bearer ' + appStorage.getToken(),
      },
    })
      .then((res: any) => {
        res.json().then((resp) => {
          if (resp.file_contents) {
            setContentsUrl(resp.file_contents)
            form.change('file_contents_url', resp.file_contents)
          }
          setFileUrl(resp.url)
        })
      })
      .catch((err: any) => console.log('err', err))
  }

  const onFocus = (newlyFocusedPlaceholders: PlaceholderType[]) => {
    setFocusedPlaceholders(newlyFocusedPlaceholders)
  }

  const onCopy = useCallback(
    (e: Event) => {
      if (e?.target?.nodeName === 'SPAN') {
        setCopiedPlaceholders(focusedPlaceholders)
      } else {
        setCopiedPlaceholders([])
      }
    },
    [focusedPlaceholders]
  )

  useEffect(() => {
    window.addEventListener('copy', onCopy)
    return () => {
      window.removeEventListener('copy', onCopy)
    }
  }, [onCopy])

  const onPaste = useCallback(
    (e: Event) => {
      if (!copiedPlaceholders || copiedPlaceholders.length === 0) return
      const newPlaceholders: PlaceholderMapTypes = {}
      try {
        copiedPlaceholders?.forEach((placeholderFromProps) => {
          let placeholder = { ...placeholderFromProps }
          if (placeholder.page_num === pageNum) {
            placeholder.y += 15
            placeholder.x -= 15
          }
          let fieldKey
          if (placeholder.field_path?.includes('docusign.')) {
            let docusignKey: string = placeholder.field_path.split('.')[1]
            // @ts-ignore
            fieldKey = docusignTagMap[docusignKey]
            let i = 0
            while (i < 80) {
              if (!placeholders[`${fieldKey}${i}/`]) {
                fieldKey = `${fieldKey}${i}/`
                i = 80
              }
              i++
            }
            placeholder.value = fieldKey
          } else {
            for (let i = 0; i < 100; i++) {
              let thisKey = `${placeholder?.field_path}/${i}`
              if (!placeholders[thisKey]) {
                fieldKey = thisKey
                break
              }
            }
          }
          let newPlaceholder = {
            x: placeholder.x,
            y: placeholder.y,
            field_path: placeholder?.field_path,
            value: placeholder.value,
            field_key: fieldKey,
            page_num: pageNum,
            width: placeholder.width,
            fontSize: placeholder.font_size,
            lines: placeholder.lines,
            default_value: placeholder.default_value,
          }
          if (fieldKey) newPlaceholders[fieldKey] = newPlaceholder
        })
        if (Object.keys(newPlaceholders)?.length > 0) {
          setPlaceholders({ ...placeholders, ...newPlaceholders })
        }
      } catch (ex) {
        console.log('ex', ex)
        console.log('copiedPlaceholders', copiedPlaceholders)
      }
    },
    [placeholders, copiedPlaceholders]
  )

  useEffect(() => {
    window.addEventListener('paste', onPaste)
    return () => {
      window.removeEventListener('paste', onPaste)
    }
  }, [onPaste])

  const startDrag = (fieldName: string, value: string) => {
    setFieldBeingDragged({ field_path: fieldName, value: value })
  }

  const endDrag = () => setFieldBeingDragged(undefined)

  const saveDropResult = (x: number, y: number) => {
    if (!fieldBeingDragged) return
    // inject docusign tag into value field
    let fieldKey
    if (fieldBeingDragged.field_path?.includes('docusign.')) {
      let docusignKey: string = fieldBeingDragged.field_path.split('.')[1]
      // @ts-ignore
      fieldKey = docusignTagMap[docusignKey]
      let i = 0
      while (i < 40) {
        if (!placeholders[`${fieldKey}${i}/`]) {
          fieldKey = `${fieldKey}${i}/`
          i = 40
        }
        i++
      }
      fieldBeingDragged.value = fieldKey
    } else {
      for (let i = 0; i < 100; i++) {
        let thisKey = `${fieldBeingDragged?.field_path}/${i}`
        if (!placeholders[thisKey]) {
          fieldKey = thisKey
          break
        }
      }
    }
    let width = fieldBeingDragged.value?.length ? fieldBeingDragged.value.length * 7 : 200
    if (fieldBeingDragged?.field_path?.includes('DJANGO_TAGS_request_or_embed_signature')) width = 350
    let lines = Math.ceil(width / 500)
    let newPlaceholder = {
      x,
      y,
      field_path: fieldBeingDragged?.field_path,
      value: fieldBeingDragged.value,
      field_key: fieldKey,
      page_num: pageNum,
      width: Math.min(width, 500),
      font_size: globalFontSize ? globalFontSize : 9,
      lines,
    }
    if (fieldKey) setPlaceholders({ ...placeholders, [fieldKey]: newPlaceholder })
    logAmplitudeEvent('placeholer_dropped', { field_path: fieldBeingDragged.field_path })
  }

  const removePlaceholder = (key: string) => {
    let newPlaceholders = placeholders
    delete newPlaceholders[key]
    setPlaceholders({ ...newPlaceholders })
  }

  const savePlaceholderChange = (key: string, path: string, config: PlaceHolderSaveConfig) => {
    let newPlaceholders = placeholders
    newPlaceholders[key].x = config.x
    newPlaceholders[key].y = config.y
    newPlaceholders[key].width = config.width
    if (config.height) {
      newPlaceholders[key].height = config.height
    }
    newPlaceholders[key].use_int_comma = config.use_int_comma
    newPlaceholders[key].format_as_currency = config.format_as_currency
    newPlaceholders[key].lines = config.lines
    newPlaceholders[key].font_size = config.font_size
    newPlaceholders[key].default_value = config.default_value
    newPlaceholders[key].round_digits = config.round_digits
    newPlaceholders[key].math_operations = config.math_operations
    newPlaceholders[key].date_format_rule = config.date_format_rule
    newPlaceholders[key].is_checkbox = config.is_checkbox
    newPlaceholders[key].page_num = pageNum
    newPlaceholders[key].field_path = path
    setPlaceholders({ ...newPlaceholders })
  }

  const changeProjectId = (newProjectId: string) => {
    setSelectedProjectId(newProjectId)
    setInitialProjectURL(undefined)
  }

  const changeSystemId = (newSystemId: string | undefined) => {
    setSelectedSystemId(newSystemId)
    setInitialSystemURL(undefined)
  }

  const changePaymentOptionId = (newPaymentOptionId: string | undefined) => {
    setSelectedPaymentOptionId(newPaymentOptionId)
    setInitialPaymentOptionURL(undefined)
  }

  const updateAllFontSizes = (newFontSize: number) => {
    setGlobalFontSize(newFontSize)
    let newPlaceholders = { ...placeholders }
    Object.values(newPlaceholders).forEach((item) => (item.font_size = parseInt(newFontSize)))
    setPlaceholders(newPlaceholders)
    logAmplitudeEvent('template_global_font_size_changed', { fontSize: `${newFontSize}` })
  }
  const documentType = useMemo(() => props?.record?.type, [props.record])

  return (
    <Grow in={true}>
      <>
        <Card style={{ marginBottom: '15px' }}>
          <div style={{ marginTop: '15px', padding: '20px' }}>
            <h2>{translate('Step 1: Upload the PDF')}</h2>
            <p>
              {translate('To get started, import the file that you would like to use for the basis of your template.')}
            </p>
            <div
              style={{
                margin: '20px 0px',
                width: '200px',
              }}
            >
              <input
                accept="image/*,application/pdf"
                style={{ display: 'none' }}
                id="raised-button-file"
                onChange={onUpload}
                multiple
                type="file"
              />
              <label htmlFor="raised-button-file">
                <Button variant="outlined" component="span" fullWidth>
                  <span>{props.record?.file_contents_url ? 'Replace File' : 'Import File'}</span>
                </Button>
                {errorMsg && <span style={{ color: 'red', textAlign: 'center' }}>{errorMsg}</span>}
              </label>
            </div>
            <h2 style={{ marginTop: '40px' }}>Step 2: Load Sample Data</h2>
            <p>
              {translate(
                'Pick a project, system, and payment option from your account. Then click "Load Sample Data" so you can drag and drop placeholder fields with real values for a system you have designed.'
              )}
            </p>
            <div>
              <ProjectPlaceholderWizard
                startDrag={startDrag}
                endDrag={endDrag}
                selectedProjectId={selectedProjectId}
                initialProjectURL={initialProjectURL}
                setSelectedProjectId={changeProjectId}
                selectedSystemId={selectedSystemId}
                initialSystemURL={initialSystemURL}
                setSelectedSystemId={changeSystemId}
                selectedPaymentOptionId={selectedPaymentOptionId}
                initialPaymentOptionURL={initialPaymentOptionURL}
                setSelectedPaymentOptionId={changePaymentOptionId}
                proposalData={proposalData}
                setProposalData={setProposalData}
                activeData={activeData}
                setActiveData={setActiveData}
                documentType={documentType}
              />
            </div>
            {!activeData && contentsUrl && (
              <Alert severity="warning">
                {translate(
                  'To access placeholders please select a sample project, system, and payment option and then click the "Load Sample Data" button above.'
                )}
              </Alert>
            )}
            {contentsUrl && pdfJsLoaded && (
              <div>
                <div>
                  <TemplateWizardPDFDisplay
                    url={contentsUrl}
                    saveDropResult={saveDropResult}
                    placeholders={placeholders}
                    removePlaceholder={removePlaceholder}
                    savePlaceholderChange={savePlaceholderChange}
                    pageNum={pageNum}
                    setPageNum={setPageNum}
                    fieldBeingDragged={fieldBeingDragged}
                    onFocus={onFocus}
                    focusedPlaceholders={focusedPlaceholders}
                    updateAllFontSizes={updateAllFontSizes}
                    initialGlobalFontSize={globalFontSize}
                  />
                </div>
              </div>
            )}
          </div>
        </Card>
      </>
    </Grow>
  )
}
export default TemplateWizard

import { ListItemIcon, ListItemText, ListSubheader, MenuItem, Select } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import InfoTooltip from 'elements/tooltip/InfoTooltip'
import inflection from 'inflection'
import { FormControl } from 'opensolar-ui'
import { getSupplierConfig } from 'pages/ordering/configs'
import { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { Category, CategoryMapEntry, Item, Source } from '../type'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  tableCell: {
    fontSize: theme.typography.subtitle2.fontSize,
  },
  renderValue: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    height: 24,
    objectFit: 'fill',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #e7e7e7',
    borderRadius: 4,
    padding: 2,
  },
  formControl: {
    minWidth: 95,
    maxWidth: 100,
    fontSize: theme.typography.subtitle2.fontSize,
  },
  listSubheader: {
    pointerEvents: 'none',
  },
  menuItem: {
    fontSize: theme.typography.subtitle2.fontSize,
    gap: 10,
  },
}))

const categorizePricingSource = (sources: Source[], enabledDistributors: HardwareSupplierFilterKeyType[]): Category => {
  const categories: Category = {
    SOURCE_OPTIONS: [],
    DISTRIBUTOR_PRICING: [],
  }

  const categoryMap: { [key: string]: CategoryMapEntry } = {
    Solar_Outlet: {
      category: 'DISTRIBUTOR_PRICING',
      iconUrl: `${window.PUBLIC_URL}/images/solar_outlet_logo.png`,
      isVisible: enabledDistributors.includes('outlet'),
    },
    Segen: {
      category: 'DISTRIBUTOR_PRICING',
      iconUrl: `${window.PUBLIC_URL}/images/segen-logo.png`,
      isVisible: enabledDistributors.includes('segen'),
    },
    City_Plumbing: {
      category: 'DISTRIBUTOR_PRICING',
      iconUrl: `${window.PUBLIC_URL}/images/city-plumbing-logo.png`,
      isVisible: enabledDistributors.includes('city_plumbing'),
    },
    Hdm: {
      category: 'DISTRIBUTOR_PRICING',
      iconUrl: `${window.PUBLIC_URL}/images/hdm-logo.png`,
      isVisible: enabledDistributors.includes('hdm'),
    },
    Overridden: { category: 'SOURCE_OPTIONS', isVisible: true },
    Manual: { category: 'SOURCE_OPTIONS', isVisible: true },
  }

  sources?.forEach((source) => {
    const mapping = categoryMap[source.label] || { category: 'DISTRIBUTOR_PRICING', isVisible: false }
    const newSource: Item = { ...source }
    if (mapping.iconUrl) {
      newSource.iconUrl = mapping.iconUrl
    }
    if (mapping.isVisible) {
      categories[mapping.category].push(newSource)
    }
  })

  return categories
}

const PricingSourceSelector = ({ row, column }) => {
  const classes = useStyles()
  const enabledDistributors = useSelector(orgSelectors.getEnabledHardwareSuppliers)
  const enabledDistributorsFilterKeys = useMemo(() => {
    const filterKeys: HardwareSupplierFilterKeyType[] = []
    for (const key in enabledDistributors) {
      const distributorConfig = getSupplierConfig(enabledDistributors[key])
      if (distributorConfig) {
        filterKeys.push(distributorConfig.filterKey)
      }
    }
    return filterKeys
  }, [enabledDistributors])
  const categories = useMemo(() => categorizePricingSource(column.options, enabledDistributorsFilterKeys), [
    column.options,
    enabledDistributorsFilterKeys,
  ])

  const renderSelectGroup = (categoryName: string, items: Item[]): React.ReactNode => {
    const options = items.map((item) => (
      <MenuItem key={item.value} value={item.value} className={classes.menuItem}>
        {item.iconUrl && (
          <ListItemIcon className={classes.iconContainer}>
            <img src={item.iconUrl} alt={item.label} className={classes.icon} />
          </ListItemIcon>
        )}
        <ListItemText primary={inflection.titleize(item.label && item.label)} />
        {item.msg && <InfoTooltip title={item.msg} security="info" />}
      </MenuItem>
    ))

    return [
      <ListSubheader key={categoryName} className={classes.listSubheader}>
        {inflection.titleize(categoryName && categoryName)}
      </ListSubheader>,
      ...options,
    ]
  }

  const renderValue = (selected) => {
    const selectedOption = categories.DISTRIBUTOR_PRICING.concat(categories.SOURCE_OPTIONS).find(
      (option) => option.value === selected
    )

    return (
      <div className={classes.renderValue}>
        {selectedOption?.iconUrl && (
          <img src={selectedOption.iconUrl} alt={selectedOption.label} className={classes.icon} />
        )}
        {!selectedOption?.iconUrl && selectedOption?.label}
      </div>
    )
  }

  return (
    <FormControl>
      <Select
        className={classes.formControl}
        value={row[column.id]}
        displayEmpty
        onChange={(event) => column.onChange(row.code, event.target.value)}
        renderValue={renderValue}
        disableUnderline
      >
        {Object.entries(categories)?.map(([categoryName, items]) => renderSelectGroup(categoryName, items))}
      </Select>
    </FormControl>
  )
}

export default PricingSourceSelector

import Launch from '@material-ui/icons/Launch'
import React from 'react'
import { EntityErrorInfo } from 'reducer/project/projectErrorsReducer'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    margin: '5px 0',
  },
  title: {
    verticalAlign: 'middle',
  },
  icon: {
    verticalAlign: 'middle',
    width: 14,
    height: 14,
    margin: 1,
  },
}))

const EntityError = ({ error }: { error: EntityErrorInfo }) => {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      <a href={error.url} target="_blank" rel="noopener noreferrer">
        <span className={classes.title}>{error.title}</span>
        <Launch className={classes.icon} />
      </a>
    </div>
  )
}

export default EntityError

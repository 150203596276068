import { osDisclaimer } from 'constants/links'
import { useTranslate } from 'ra-core'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { OrgType } from 'types/orgs'
import { Theme } from 'types/themes'

const useStyles = makeOpenSolarStyles((theme: Theme) => ({
  message: {
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 170,
    },
    fontSize: 10,
  },
}))

type PropsType = {
  org: OrgType
}

const OpenSolarDisclosure = ({ org }: PropsType) => {
  const translate = useTranslate()
  const classes = useStyles()
  const link = `<a href='https://${osDisclaimer}' target="_blank" rel="noreferrer">
      ${osDisclaimer}
    </a>`
  const msg = translate(
    'This proposal has been prepared by %{org} using tools from OpenSolar. Please visit %{link} for additional disclosures from OpenSolar.',
    { org: org?.name || '', link }
  )
  return <p className={classes.message} dangerouslySetInnerHTML={{ __html: msg }} />
}

export default OpenSolarDisclosure

import InverterManualSelectorNode from './nodes/InverterManualSelectorNode'
import InverterSelectorModeNode from './nodes/InverterSelectorModeNode'
import InverterSetAutoSelectorNode from './nodes/InverterSetAutoSelectorNode'
import InverterTypeSelectionNode from './nodes/InverterTypeSelectionNode'

export const INVERTER_TYPES = ['microinverter', 'hybrid', 'string', 'power_optimized'] as const

export const INVERTER_SELECTOR_MODE_NODE_KEY = 'INVERTER_SELECTOR_MODE'
export const INVERTER_TYPE_SELECTION_NODE_KEY = 'INVERTER_TYPE_SELECTION'
export const INVERTER_MANUAL_SELECTOR_NODE_KEY = 'INVERTER_MANUAL_SELECTOR'
export const INVERTER_SET_AUTO_SELECTOR_NODE_KEY = 'INVERTER_SET_AUTO_SELECTOR'

export const INVERTER_WIZARD_FLOW_CONFIG = {
  [INVERTER_SELECTOR_MODE_NODE_KEY]: InverterSelectorModeNode,
  [INVERTER_TYPE_SELECTION_NODE_KEY]: InverterTypeSelectionNode,
  [INVERTER_MANUAL_SELECTOR_NODE_KEY]: InverterManualSelectorNode,
  [INVERTER_SET_AUTO_SELECTOR_NODE_KEY]: InverterSetAutoSelectorNode,
}

import { GET_LIST } from 'ra-core'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import restClient from 'restClient'
import { ExhibitorType, ReduxActionType } from 'types/global'

export const GET_EXHIBITORS = 'GET_EXHIBITORS'
export const GET_EXHIBITORS_SUCCESS = 'GET_EXHIBITORS_SUCCESS'
export const GET_EXHIBITORS_FAIL = 'GET_EXHIBITORS_FAIL'

export type ExhibitorsStoreType = {
  exhibitors: ExhibitorType[]
  loading: boolean
  ready: boolean
}

const initialState: ExhibitorsStoreType = {
  exhibitors: [],
  loading: false,
  ready: false,
}

export default (state: ExhibitorsStoreType = initialState, action: ReduxActionType) => {
  switch (action.type) {
    case GET_EXHIBITORS:
      return {
        ...state,
        loading: true,
        orgId: action.payload.orgId,
      }
    case GET_EXHIBITORS_SUCCESS:
      return {
        ...state,
        loading: false,
        exhibitors: action?.payload?.exhibitors,
        ready: true,
      }
    case GET_EXHIBITORS_FAIL:
      return {
        ...state,
        loading: false,
        ready: false,
      }
  }
  return state
}

export function* exhibitorsSaga(action: ReduxActionType) {
  try {
    const exhibitors = yield call(doGetExhibitors)
    yield put({ type: GET_EXHIBITORS_SUCCESS, payload: { exhibitors: exhibitors } })
  } catch (e) {
    yield put({ type: GET_EXHIBITORS_FAIL, key: action.payload.key })
  }
}

export const doGetExhibitors = () => {
  return new Promise((resolve, reject) => {
    const restClientInstance = restClient(window.API_ROOT + '/api')
    restClientInstance(GET_LIST, 'exhibitors', {})
      .then((response: any) => {
        resolve(response.data)
      })
      .catch((err: any) => {
        console.log('err', err)
        reject(false)
      })
  })
}

export const loadExhibitors = (orgId) => {
  return {
    type: GET_EXHIBITORS,
    payload: {
      orgId: orgId,
    },
  }
}

export const exhibitorsSelectors = {
  getIsLoading: (state: any): boolean => !!state.exhibitors?.loading,
  getIsReady: (state: any): boolean => !!state.exhibitors?.ready,
  getExhibitors: (state: any): ExhibitorType[] => state.exhibitors.exhibitors,
}

export function* exhibitorsSagas() {
  yield all([takeEvery(GET_EXHIBITORS, exhibitorsSaga)])
}

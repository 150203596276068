export const doFormRedirect = (requestURL: string, requestBody: object) => {
  const form = document.createElement('form') as HTMLFormElement
  form.target = '_blank'
  form.rel = 'noopener noreferrer'
  form.method = 'post'
  form.action = requestURL
  Object.keys(requestBody).forEach((fieldKey) => {
    let inputEl = document.createElement('input')
    inputEl.name = fieldKey
    inputEl.value = requestBody[fieldKey]
    inputEl.type = 'hidden'
    form.appendChild(inputEl)
  })

  document.body.appendChild(form)
  form.submit()
}

import { authSelectors } from 'ducks/auth'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { PremiumProductDetailType } from 'types/global'

const restClientInstance = restClient(window.API_ROOT + '/api')

export const useLoadPremiumProducts = (emptyOnFail = false) => {
  const [products, setProducts] = useState<PremiumProductDetailType[] | undefined>()

  const orgId = useSelector(authSelectors.getOrgId)

  useEffect(() => {
    if (!orgId) return

    restClientInstance('CUSTOM_GET', 'custom', {
      url: 'orgs/' + orgId + '/wallet_products/?fieldset=list',
    })
      .then((response: any) => {
        setProducts(response.data)
      })
      .catch((err: any) => {
        console.warn('Failed to load premium products: ', err)
        if (emptyOnFail) setProducts([])
      })
  }, [orgId])

  return products
}

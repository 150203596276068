export const CURRENT_RATING_FILTER_GTE_KEY = 'current_rating__gte'
export const CURRENT_RATING_FILTER_LTE_KEY = 'current_rating__lte'

export type RatingRangeType = {
  min: number // included
  max: number
  value: string
  title: string
}

export const CURRENT_RATING_RANGE = [
  { title: String.fromCharCode(8804) + ' 20A', value: '0,20', min: 0, max: 20 },
  { title: '20A - 30A', value: '20,30', min: 20, max: 30 },
  { title: '30A - 40A', value: '30,40', min: 30, max: 40 },
  { title: '40A - 80A', value: '40,80', min: 40, max: 80 },
  { title: String.fromCharCode(8805) + ' 80A', value: '80,1000', min: 80, max: 1000 },
]

export const getOptionValueByFilterValues = ({
  ranges,
  gte,
  lte,
}: {
  ranges: RatingRangeType[]
  gte: number
  lte: number
}): string | undefined => {
  for (let i = 0; i < ranges.length; i++) {
    const { min, max, value } = ranges[i]
    if (min === gte && max === lte) {
      return value
    }
  }
  return
}

export const getRangeOptionByValue = (value: string, ranges: RatingRangeType[]): RatingRangeType | undefined => {
  for (let i = 0; i < ranges.length; i++) {
    if (ranges[i].value === value) {
      return ranges[i]
    }
  }
  return
}

export const mapRangeOptionsToSelectOptions = (rangeOptions: RatingRangeType[]) => {
  const defaultOption = {
    title: 'All',
    value: '',
    id: 'default',
  }
  const selectOptions = [defaultOption]

  rangeOptions.forEach((range) => {
    selectOptions.push({ title: range.title, value: range.value, id: range.value })
  })

  return selectOptions
}

import Typography from '@material-ui/core/Typography'
import { orgSelectors } from 'ducks/orgs'
import { styled } from 'opensolar-ui'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import { useSelector } from 'react-redux'
import { HardwareSupplierEnum } from '../../type'
import ProductDetailDescription from './ProductDetailDescription'
import ProductDetailDescriptionActions from './ProductDetailDescriptionActions'

const FullWidthWrapper = styled('div')({
  width: '100%',
})

const SectionCardTop = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: 25,
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  borderBottom: '1px solid ' + COLOR_PALETTE.lightGrey,
}))

const SectionCardBottom = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: 25,
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
}))

const Actions = styled('div')({
  padding: '15px 15px 15px 0',
})

const ProductDetailDescription3 = ({ lineItem }) => {
  const selectedHardwareSupplier = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const description =
    selectedHardwareSupplier === HardwareSupplierEnum.Hdm ||
    selectedHardwareSupplier === HardwareSupplierEnum.VtacUk ||
    selectedHardwareSupplier === HardwareSupplierEnum.VtacPoland ? (
      lineItem?.data?.description === '0' ? (
        <div>No description available.</div>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: lineItem?.data?.description || '' }}></div>
      )
    ) : (
      lineItem?.data?.description
    )
  const productDocuments = lineItem?.selectedDistributorOrderingData?.product_documents

  return (
    <FullWidthWrapper>
      <SectionCardTop>
        <Typography variant="h6">Product Description</Typography>
      </SectionCardTop>
      <SectionCardBottom>
        <div>{description != null && <ProductDetailDescription description={description} />}</div>
        <Actions>{productDocuments && <ProductDetailDescriptionActions productDocuments={productDocuments} />}</Actions>
      </SectionCardBottom>
    </FullWidthWrapper>
  )
}

export default ProductDetailDescription3

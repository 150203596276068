var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button as Mui4Button, makeStyles } from '@material-ui/core';
import { default as Mui5Button } from '@mui/material/Button';
import React, { forwardRef } from 'react';
import { Inspector } from '../debug/Inspector';
import { useComponentVersion } from '../hooks/useComponentVersion';
export var Button = React.forwardRef(function (props, ref) {
    return (_jsx(Inspector, { name: "Button", propDefs: ButtonPropDefs, showExample: true, component: OsButton, props: __assign(__assign({}, props), { ref: ref }) }));
});
export var OsButtonVersion;
(function (OsButtonVersion) {
    OsButtonVersion[OsButtonVersion["V1"] = 1] = "V1";
    OsButtonVersion[OsButtonVersion["V2"] = 2] = "V2";
    OsButtonVersion[OsButtonVersion["V3"] = 3] = "V3";
})(OsButtonVersion || (OsButtonVersion = {}));
var V3_BUTTON_COLORS = ['info', 'error', 'warning', 'success'];
var useStyles = makeStyles({
    v2: {
        height: 'unset',
        padding: '8.5px 15px',
        '&.Mui4Button-sizeSmall': {
            height: 'unset',
            padding: '7px 12px',
        },
        '& .Mui4Button-label': {
            textTransform: 'none',
        },
    },
}, { name: 'OsButton' });
var OsButton = forwardRef(function (props, ref) {
    var classes = useStyles();
    var version = useComponentVersion('button');
    var color = props.color, rest = __rest(props, ["color"]);
    switch (version) {
        case OsButtonVersion.V3:
            var color5 = color === 'default' ? 'secondary' : color;
            return _jsx(Mui5Button, __assign({ ref: ref, color: color5 }, rest));
        default:
            var mui4Props = __assign(__assign({}, props), { className: "".concat(version === OsButtonVersion.V2 ? classes.v2 : '', " ").concat(props.className ? props.className : ''), color: color && V3_BUTTON_COLORS.includes(color) ? 'default' : color });
            return _jsx(Mui4Button, __assign({ ref: ref }, mui4Props));
    }
});
var ButtonPropDefs = {
    variant: { default: 'text' },
    size: { default: 'medium' },
    color: { default: undefined },
    startIcon: { hidden: true },
    endIcon: { hidden: true },
    children: { hidden: true },
    fullWidth: { hidden: true },
};

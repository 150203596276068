import { SystemContext } from 'Designer/designRules/types'
import { NonModuleComponentTypes } from 'types/selectComponent'
import { StudioComponentItem } from 'types/studio/items'
import { EffectFactory } from '.'

export type RemoveComponentEffectType = {
  type: 'remove-component-by-code'
  componentType: NonModuleComponentTypes
  code: string
  hideNotifications?: boolean
  removeQuantity?: number
}

export const effect_removeComponentByCode: EffectFactory<RemoveComponentEffectType> = (def) => {
  return {
    execute: async (context: SystemContext) => {
      const system = context.system
      let components: StudioComponentItem[]
      switch (def.componentType) {
        case 'other':
          components = system.others()
          break
        case 'inverter':
          components = system.inverters()
          break
        case 'battery':
          components = system.batteries()
          break
        default:
          console.error(`Unknown component type: ${def.componentType}`)
          return
      }

      components = components.filter((c) => c.code === def.code)
      if (!components.length) {
        if (window.debugDesignRules)
          console.debug(`\tDesignRules: Skipping remove-component-by-code, no components found with code ${def.code}`)
      }

      let toRemove = def.removeQuantity
      while (components.length && (toRemove === undefined || toRemove > 0)) {
        const component = components.pop() as StudioComponentItem
        const quantity = component['quantity'] || 1
        if (toRemove && quantity > toRemove) {
          window.editor.execute(
            new window.SetValueCommand(
              component,
              'quantity',
              quantity - toRemove,
              window.Utils.generateCommandUUIDOrUseGlobal(),
              true
            )
          )
          return // All done
        } else {
          window.editor.deleteObject(component)
          if (toRemove !== undefined) toRemove -= quantity
        }
      }
    },
  }
}

import { useMemo } from 'react'
import type { FactoryHookType } from '../type'
import maybeCreateCurrentTypeFilterNode from './CurrentTypeFilterNodeFactory'

const useCurrentTypeFilterNode: FactoryHookType = ({ RendererComponent, options }) => {
  return useMemo(() => {
    return maybeCreateCurrentTypeFilterNode({
      componentType: options.componentType,
      RendererComponent,
    })
  }, [options.componentType, RendererComponent])
}

export default useCurrentTypeFilterNode

// @ts-nocheck
import { useMediaQuery } from '@material-ui/core'
import { startTourStep, terminateCurrentTour, updateTourStep } from 'actions/tourActions'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { parseQueryStringToDictionary } from 'util/misc'
import TourRide from '../tools/TourRide'
import { recordUserAction } from '../tools/common'
import Stepper from './Stepper'
import useGetFUJITourSteps from './useGetFUJITourSteps'

export const DISPLAY_NAME = 'fuji'

export const FujiTour = () => {
  const location = useLocation()
  const history = useHistory()
  const projectId = useSelector((state: any) => state.projectId) || 'new'
  const STEPS = useGetFUJITourSteps()
  const userId: number = useSelector(authSelectors.getCurrentUser)?.id
  const { tour, step } = useSelector((state: any) => state.tour)
  const stepIndex = STEPS.findIndex((s) => s.step === step)
  const visible = tour === DISPLAY_NAME && stepIndex >= 0

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const handleCancel = useCallback(() => {
    logAmplitudeEvent('fuji_tour_cancelled', {})
    history.push(location.pathname)
  }, [location])

  const handleStepChange = useCallback(
    (newStep: number) => {
      if (STEPS[newStep]) {
        const { step, page } = STEPS[newStep]
        let newURL = ''
        if (page === 'home') {
          newURL = `/home?tour=${DISPLAY_NAME}&step=${step}`
        } else {
          newURL = `/projects/${projectId}/${page}?tour=${DISPLAY_NAME}&step=${step}`
        }
        history.push(newURL)
      } else {
        handleCancel()
      }
    },
    [projectId]
  )

  useEffect(() => {
    if (visible) {
      if (isSmall) {
        logAmplitudeEvent('fuji_tour_started_on_mobile', {})
      } else {
        logAmplitudeEvent('fuji_tour_started', {})
        recordUserAction(userId, 'tour_started')
      }
    }
  }, [visible])

  return (
    <div id="fuji-tour">
      <TourRide
        steps={STEPS}
        visible={visible}
        stepIndex={stepIndex}
        handleNext={() => handleStepChange(stepIndex + 1)}
        handlePrev={() => history.goBack()}
        handleCancel={handleCancel}
      />
      <Stepper />
    </div>
  )
}

const FujiTourController = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const tour = useSelector((state: any) => state.tour.tour)
  const STEPS = useGetFUJITourSteps()
  const clearTour = useCallback(() => {
    //clear a tour
    dispatch(terminateCurrentTour())
  }, [])

  useEffect(() => {
    const query: any = parseQueryStringToDictionary(location.search)
    const tourInQuery = query?.tour
    const stepInQuery = query?.step
    const stepIndex = STEPS.findIndex((s) => s.step === stepInQuery)
    if (tour === null && tourInQuery === DISPLAY_NAME) {
      //prohibit starting from middle of tour
      if (stepIndex === 0) {
        //start a tour
        dispatch(startTourStep(tourInQuery))
      }
    } else if (tour === tourInQuery && tour === DISPLAY_NAME && stepIndex >= 0) {
      dispatch(updateTourStep(DISPLAY_NAME, STEPS[stepIndex].step))
    } else if (tour === DISPLAY_NAME && !tourInQuery) {
      // clear tour if url get cleared
      clearTour()
    }
  }, [location, tour])

  useEffect(() => {
    return clearTour
  }, [])

  return <FujiTour />
}

export default FujiTourController

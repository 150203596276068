import { createStyles, makeStyles, Theme } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      margin: 16,
    },
  })
)

const MainUIPage = () => {
  const classes = useStyles()

  return (
    <div className={classes.main}>
      Welcome to the UI Library! To see documentation of our UI Components, please select any from the menu on the left
    </div>
  )
}

export default MainUIPage

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export var TableIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? '1rem' : _b, _c = _a.color, color = _c === void 0 ? 'currentColor' : _c, rest = __rest(_a, ["size", "color"]);
    var iconAttributes = {
        width: size,
        height: size,
        stroke: color,
    };
    return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 18 18", fill: "none" }, rest, iconAttributes, { children: _jsx("path", { d: "M7.5 1C7.5 0.447715 7.05228 0 6.5 0C5.94771 0 5.5 0.447715 5.5 1H7.5ZM5.5 17C5.5 17.5523 5.94771 18 6.5 18C7.05228 18 7.5 17.5523 7.5 17H5.5ZM4 2H14V0H4V2ZM16 4V14H18V4H16ZM14 16H4V18H14V16ZM2 14V4H0V14H2ZM4 16C2.89543 16 2 15.1046 2 14H0C0 16.2091 1.79086 18 4 18V16ZM16 14C16 15.1046 15.1046 16 14 16V18C16.2091 18 18 16.2091 18 14H16ZM14 2C15.1046 2 16 2.89543 16 4H18C18 1.79086 16.2091 0 14 0V2ZM4 0C1.79086 0 0 1.79086 0 4H2C2 2.89543 2.89543 2 4 2V0ZM5.5 1V17H7.5V1H5.5ZM1.5 7.5H16.5V5.5H1.5V7.5Z", fill: color, strokeWidth: 0 }) })));
};

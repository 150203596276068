import { Grid } from '@material-ui/core'
import type { StockLevelStatus } from 'pages/ordering/type'
import React, { memo, Suspense } from 'react'
import Skeleton from 'react-loading-skeleton'
import type { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import type { HardwareDetailType } from './getHardwareDetailFromSystem'

type HardwareStockStatusMap = {
  [key: string]: StockLevelStatus
}

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}))

const StatusMessage = React.lazy(() => import('pages/ordering/StatusMessage'))

const HardwareDetail = ({
  code,
  quantity,
  status,
  showStockAvailability,
}: {
  code: string
  quantity: number
  status?: StockLevelStatus
  showStockAvailability: boolean
}) => {
  const classes = useStyles()
  return (
    <Grid container justify={'space-between'}>
      <Grid item xs={7} className={classes.title}>
        {code} {' x '} {quantity}
      </Grid>
      {showStockAvailability && (
        <Grid item container xs={5} justify="flex-end" alignItems="center">
          {status === 'loading' ? (
            <Skeleton width="50px" />
          ) : (
            <Suspense fallback={<div />}>
              {<StatusMessage size={'small'} status={status || 'not_available'} />}
            </Suspense>
          )}
        </Grid>
      )}
    </Grid>
  )
}

const HardwareSummary = ({
  hardwareDetails,
  stockStatusMap,
  loading,
  showStockAvailability,
}: {
  hardwareDetails: HardwareDetailType[]
  stockStatusMap: HardwareStockStatusMap | undefined
  loading: boolean
  showStockAvailability: boolean
}) => {
  return (
    <>
      {hardwareDetails.map((detail: HardwareDetailType, index: number) => {
        const status = loading ? 'loading' : stockStatusMap?.[detail.code]
        return (
          <HardwareDetail
            key={detail.code + index}
            code={detail.code}
            quantity={detail.quantity}
            status={status}
            showStockAvailability={showStockAvailability}
          />
        )
      })}
    </>
  )
}

export default memo(HardwareSummary)

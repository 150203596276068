import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { myEnergySelectors } from 'ducks/myEnergy'
import PaymentOptionWarning from 'elements/PaymentOptionWarning'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useNotify } from 'react-admin'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { systemNameOrDefault } from 'util/misc'
import { SEND_APP_SUPPORTED_INTEGRATIONS } from './constants'

const restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  isOpen: boolean
  onClose: () => void
}

type PaymentOptionMenuItemType = {
  disabled: boolean
  id: number
  title: string
  integration: string | undefined
}

const useStyles = makeStyles((theme: any) => ({
  contentWrapper: {
    padding: 10,
  },
  radioSectionWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    minHeight: '200px',
  },
  radioWrapper: {},
  actionsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    width: '100%',
  },
  buttonWrapper: {
    marginRight: 20,
  },
}))

const SendCreditAppDialog: React.FC<PropTypes> = (props) => {
  const systems = useSelector(myEnergySelectors.getProposalSystems)
  const projectId = useSelector(myEnergySelectors.getProposalProjectId)
  const orgId = useSelector(myEnergySelectors.getProposalOrgId)
  const classes = useStyles()
  const notify = useNotify()

  const [selectedSystemUuid, setSelectedSystemUuid] = useState<undefined | string>(undefined)
  const [selectedPaymentOptionId, setSelectedPaymentOptionId] = useState<string | undefined>(undefined)
  const [paymentOptionMenuItems, setPaymentOptionMenuItems] = useState<PaymentOptionMenuItemType[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const buildPaymentOptionMenuItemList = useCallback(() => {
    if (systems?.length && selectedSystemUuid) {
      let options: PaymentOptionMenuItemType[] | undefined = systems
        ?.find((sys) => sys.uuid === selectedSystemUuid)
        ?.payment_options?.map((pmt) => ({
          disabled: !SEND_APP_SUPPORTED_INTEGRATIONS.includes(pmt.integration),
          title: pmt.title,
          integration: pmt.integration,
          id: pmt.id,
        }))
      if (options) return options
    }
    return []
  }, [selectedSystemUuid, systems])

  useEffect(() => {
    logAmplitudeEvent('send_loan_application_dialog_opened', { page: 'Proposal', project_id: projectId })
  }, [])

  useEffect(() => {
    if (systems && systems?.length > 0 && !selectedSystemUuid) {
      setSelectedSystemUuid(systems[0].uuid)
    }
  }, [systems, selectedSystemUuid])

  useEffect(() => {
    if (selectedSystemUuid) {
      setPaymentOptionMenuItems(buildPaymentOptionMenuItemList())
    }
  }, [selectedSystemUuid])

  const handleSystemChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedSystemUuid(e.target.value)
    setSelectedPaymentOptionId(undefined)
  }

  const handlePmtChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedPaymentOptionId(e.target.value)
  }

  const onSubmit = () => {
    if (!selectedSystemUuid || !selectedPaymentOptionId) {
      notify('Please select a system and a payment option', 'warning')
      return
    }
    const selectedSystem = systems?.find((sys) => sys.uuid === selectedSystemUuid)
    const selectedPaymentOption = selectedSystem?.payment_options?.find(
      (pmt) => `${pmt.id}` === selectedPaymentOptionId
    )
    if (!selectedSystem || !selectedPaymentOption) {
      notify('Please select a valid system and an integrated payment option that applies to that system', 'warning')
      return
    }
    const integration = selectedPaymentOption.integration
    if (!SEND_APP_SUPPORTED_INTEGRATIONS.includes(integration)) {
      notify(
        'The selected payment option is either not integrated or is using an integration that does not support this feature',
        'warning'
      )
      return
    }
    if (!projectId || !orgId) {
      notify('Unable to find project or Org ID', 'warning')
      return
    }

    setIsLoading(true)
    let requestBody = {}
    restClientInstance('CUSTOM_POST', 'custom', {
      url:
        'orgs/' +
        orgId +
        '/projects/' +
        projectId +
        '/systems/' +
        selectedSystemUuid +
        '/' +
        selectedPaymentOptionId +
        `/${integration}/share_credit_app/`,
      data: requestBody,
    })
      .then((res) => {
        console.log('res', res)
        if (res.data.success || res.data.status === true) {
          notify('The credit application has been sent', 'success')
          logAmplitudeEvent('integrated_finance_application_sent', {
            page: 'Proposal',
            integration,
            method: 'Email',
            project_id: projectId,
          })
        }
      })
      .catch((err) => {
        console.log('err', err)
        if (err?.body?.message) {
          notify(err.body.message, 'warning')
        } else {
          notify('Something went wrong and we were unable to send the credit application', 'warning')
        }
        logAmplitudeEvent('integrated_finance_application_error', {
          page: 'Proposal',
          integration,
          method: 'Email',
          project_id: projectId,
        })
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <Dialog open={props.isOpen} onClose={props.onClose}>
      <DialogTitle>Email Credit Application</DialogTitle>
      <DialogContent>
        <div className={classes.contentWrapper}>
          <PaymentOptionWarning />
          <p>
            You can use the form below to send a credit application to your customer directly from the lender. Doing so
            will also automatically create a project in the lender's portal with up-to-date project information.
          </p>
          <div className={classes.radioSectionWrapper}>
            <div className={classes.radioWrapper}>
              <h4>Systems:</h4>
              <RadioGroup
                style={{ alignItems: 'flex-start' }}
                name="systems"
                value={selectedSystemUuid}
                onChange={handleSystemChange}
              >
                {systems?.map((system) => {
                  return (
                    <FormControlLabel
                      key={system.uuid}
                      value={system.uuid}
                      control={<Radio />}
                      label={<span style={{ fontSize: 13 }}>{systemNameOrDefault(system)}</span>}
                    />
                  )
                })}
              </RadioGroup>
            </div>
            <div className={classes.radioWrapper}>
              <h4>Payment Options:</h4>
              <RadioGroup
                style={{ alignItems: 'flex-start' }}
                name="systems"
                value={selectedPaymentOptionId}
                onChange={handlePmtChange}
              >
                {paymentOptionMenuItems?.map((pmt) => {
                  return (
                    <FormControlLabel
                      key={`${pmt.id}`}
                      value={`${pmt.id}`}
                      control={<Radio disabled={pmt.disabled} checked={selectedPaymentOptionId === `${pmt.id}`} />}
                      label={<span style={{ fontSize: 13 }}>{pmt.title}</span>}
                    />
                  )
                })}
              </RadioGroup>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.actionsRow}>
          <div className={classes.buttonWrapper}>
            <ProUXButton onClick={props.onClose} label="Dismiss" type="secondary" />
          </div>
          <div className={classes.buttonWrapper}>
            <ProUXButton
              onClick={onSubmit}
              label="Send Credit App"
              type="primary"
              disabled={!selectedSystemUuid || !selectedPaymentOptionId}
              loading={isLoading}
            />
          </div>
        </div>
      </DialogActions>
    </Dialog>
  )
}
export default SendCreditAppDialog

import type { ComponentTypesV2 } from 'types/selectComponent'
import BasicFilter from '../basic/BasicFilter'
import type {
  ConfigurableFilterOptionsType,
  FilterComponentNodeV2,
  FilterGenericPropsType,
  FilterValuesType,
} from '../typeV2'
import { parseStringifiedListFilterValue } from '../utils/utils'
import { CABLE_THICKNESS_OPTIONS } from './CableThicknessFilter'

export const CABLE_THICKNESS_FILTER_KEY_V2 = 'cable_thickness'

export const isCableThicknessFilterAvailableV2 = (filterValues: FilterValuesType) => {
  const types = parseStringifiedListFilterValue<ComponentTypesV2>({
    filterValues,
    key: 'types',
  })
  return types.has('cable')
}

const createCableThicknessFilterNode = <T,>({
  label = 'Cable Thickness',
  ...restConfigs
}: ConfigurableFilterOptionsType<string, T>): FilterComponentNodeV2 => {
  const FilterComponent = (filterProps: FilterGenericPropsType) => {
    const isAvailable = isCableThicknessFilterAvailableV2(filterProps.allFilterValues)
    if (!isAvailable) {
      return null
    }

    return (
      <BasicFilter
        label={label}
        defaultSelectedOptionValue={''}
        options={CABLE_THICKNESS_OPTIONS}
        filterKey={CABLE_THICKNESS_FILTER_KEY_V2}
        {...restConfigs}
        {...filterProps}
      />
    )
  }

  return {
    key: CABLE_THICKNESS_FILTER_KEY_V2,
    component: FilterComponent,
  }
}

export default createCableThicknessFilterNode

import type { HardwareSupplierEnum } from 'pages/ordering/type'
import React from 'react'
import type { FilterComponentNode, FilterComponentRendererType, FilterGenericPropsType } from '../type'
import AvailabilityFilter, { FilterOptionsType } from './AvailabilityFilter'

export const AVAILABILITY_FILTER_KEY = 'require_stock'

const maybeCreateAvailabilityFilterNode = ({
  distributor,
  RendererComponent,
}: {
  distributor: HardwareSupplierEnum | undefined
  RendererComponent: FilterComponentRendererType<FilterOptionsType>
}): FilterComponentNode | undefined => {
  if (distributor == null) {
    return
  }

  const FilterComponent = (props: FilterGenericPropsType) => (
    <AvailabilityFilter filterKey={AVAILABILITY_FILTER_KEY} RendererComponent={RendererComponent} {...props} />
  )

  return {
    key: AVAILABILITY_FILTER_KEY,
    component: FilterComponent,
  }
}

export default maybeCreateAvailabilityFilterNode

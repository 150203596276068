import { Chip } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'

const QuickFilter = (props) => {
  const { resource, source, label, style = {} } = props
  const translate = useTranslate()
  return (
    <Chip style={{ margin: '8px 0', ...style }} label={translate(label || `resources.${resource}.fields.${source}`)} />
  )
}

export default QuickFilter

import { Tooltip } from '@material-ui/core'
import { permissionsSelectors } from 'ducks/permissions'
import { useTranslate } from 'ra-core'
import React, { ComponentType, FunctionComponent, ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { PermissionKey } from 'types/roles'

export interface WithPermissionsChildrenParams {
  accessRights: any
}

type WithPermissionsChildren = (params: WithPermissionsChildrenParams) => ReactElement

export type permission = 'allowView' | 'allowCreate' | 'allowEdit' | 'allowDelete'

interface Props {
  children: any
  permissionToCheck: PermissionKey
  permissions: permission[]
  projectMode?: boolean
  disabledProp?: string
  disabledTooltip?: string | false
  [key: string]: any
}

const WithPermissionsCheck: FunctionComponent<Props> = ({
  permissions,
  children,
  permissionToCheck,
  projectMode = true,
  disabledTooltip,
  disabledProp,
  ...props
}) => {
  const accessRights = useSelector(
    projectMode
      ? permissionsSelectors.getProjectPermissionByKey(permissionToCheck)
      : permissionsSelectors.getPermissionByKey(permissionToCheck)
  )
  const hasPermissions = permissions?.every((permission) => accessRights[permission])
  const translate = useTranslate()

  if (!children) return null

  if (hasPermissions) {
    return children
  } else if (disabledProp) {
    const elem = React.cloneElement(children, {
      [disabledProp]: true,
    })
    if (disabledTooltip === false) return elem
    else {
      return (
        <Tooltip title={disabledTooltip || translate('No permission')}>
          <span>{elem}</span>
        </Tooltip>
      )
    }
  } else {
    return null
  }
}

export default WithPermissionsCheck as ComponentType<Props>

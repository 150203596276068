import { MenuItem } from '@material-ui/core'
import CustomSelectField from 'elements/field/CustomSelectField'
import { Switch } from "opensolar-ui"
import { useTranslate } from 'ra-core'
import { FC } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const initViridianData = (system) => {
  if (!system.integration_json) {
    system.integration_json = {}
  }
  if (!system.integration_json.viridian) {
    system.integration_json.viridian = {}
  }
}

const handleApplyViridianSetting = (key, value, uuid) => {
  var system = window.editor.selectedSystem
  initViridianData(system)
  if (system.integration_json?.viridian) system.integration_json.viridian[key] = value
  window.editor.signals.objectChanged.dispatch(system, 'mounting')
}

const useStyles = makeOpenSolarStyles((theme) => ({
  switchWrapper: {
    display: 'flex',
    marginTop: '15px',
    maxWidth: '230px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

type ViridianInputsProps = {
  allowEdit: boolean
}

const ViridianInputs: FC<ViridianInputsProps> = ({ allowEdit }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const autoRackingEnabled =
    typeof window.editor.selectedSystem?.integration_json?.viridian?.enabled !== 'undefined'
      ? window.editor.selectedSystem?.integration_json?.viridian?.enabled
      : false

  return (
    <>
      <div className={classes.switchWrapper}>
        <div>{translate('Auto-Racking')}</div>
        <div>
          <Switch
            checked={autoRackingEnabled}
            disabled={!allowEdit}
            onChange={(e) => {
              handleApplyViridianSetting('enabled', e.target.checked, window.editor.selectedSystem.uuid)
            }}
          />
          {autoRackingEnabled ? translate('On') : translate('Off')}
        </div>
      </div>
      <CustomSelectField
        label={translate('Batten Thickness (mm)')}
        value={
          typeof window.editor.selectedSystem?.integration_json?.viridian?.batten_thickness !== 'undefined'
            ? window.editor.selectedSystem?.integration_json?.viridian?.batten_thickness
            : 25
        }
        style={{ flex: '1 1', width: 230 }}
        selectStyle={{ marginTop: 20 }}
        disabled={!allowEdit}
        onChange={(event) =>
          handleApplyViridianSetting('batten_thickness', event.target.value, window.editor.selectedSystem.uuid)
        }
      >
        {[25, 0, 22, 30, 35, 38, 40, 45, 50].map((i, index) => (
          <MenuItem key={index} value={i}>
            {i}
          </MenuItem>
        ))}
      </CustomSelectField>
      <CustomSelectField
        label={translate('ArcBox')}
        value={window.editor.selectedSystem?.integration_json?.viridian?.arc_box || 0}
        style={{ flex: '1 1', width: 230 }}
        selectStyle={{ marginTop: 20 }}
        disabled={!allowEdit}
        onChange={(event) =>
          handleApplyViridianSetting('arc_box', event.target.value, window.editor.selectedSystem.uuid)
        }
      >
        <MenuItem key={0} value={0}>
          None
        </MenuItem>
        <MenuItem key={1} value={1}>
          All Connectors
        </MenuItem>
        <MenuItem key={2} value={2}>
          Site Made Connectors Only
        </MenuItem>
      </CustomSelectField>
    </>
  )
}

export default ViridianInputs

import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import LoadingDots from 'layout/widgets/LoadingDots'
import { Checkbox } from 'opensolar-ui'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useNotify } from 'react-admin'
import restClient from 'restClient'
import { getString, setString } from 'storage/appStorage'
import { OrgType } from 'types/orgs'
import { SystemDataType } from 'types/systems'
import { INTEGRATION_OPTIONS, PRODUCT_TYPE_OPTIONS } from './constants'
import PaymentOptionCompRow from './PaymentOptionCompRow'

const restClientInstance = restClient(window.API_ROOT + '/api')
const DEFAULT_INTEGRATION_KEY = 'pmt-wizard-default-integration'
const DEFAULT_PRODUCT_KEY = 'pmt-wizard-default-product'

type PropTypes = {
  showDialog: boolean
  closeDialog: () => void
  system: SystemDataType
  org: OrgType
  monthlySavings: number
}

export type DisplayRow = {
  title: string
  type: 'bill_savings' | 'payment_option'
  value: number
  payment_option_id: number | undefined
  total_system_price: number | undefined
}

type SupportedIntegrationType = 'loanpal' | 'sunlight' | 'dividend' | 'sungage' | 'mosaic'

type ResponseType = {
  data: {
    display_rows: DisplayRow[]
    success: boolean
  }
}

const useStyles = makeStyles((theme: any) => ({
  loadingWrapper: {
    width: '100%',
    height: '60vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainDialog: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
}))

const PaymentOptionCompDialog: React.FC<PropTypes> = (props) => {
  const classes = useStyles()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [displayRows, setDisplayRows] = useState<DisplayRow[]>([])
  const [integration, setIntegration] = useState<SupportedIntegrationType | undefined>(undefined)
  const [productType, setProductType] = useState<string | undefined>(undefined)
  const [showTable, setShowTable] = useState<boolean>(false)
  const [isDefault, setIsDefault] = useState<boolean>(false)

  const notify = useNotify()

  useEffect(() => {
    if ((props.showDialog && integration, productType)) {
      fetchDisplayRows()
    }
  }, [props.showDialog, integration, productType])

  useEffect(() => {
    const defaultIntegration = getString(`${DEFAULT_INTEGRATION_KEY}-${props.org?.id}`) as
      | undefined
      | SupportedIntegrationType
    const defaultProduct: undefined | string = getString(`${DEFAULT_PRODUCT_KEY}-${props.org?.id}`)
    if (isDefault) {
      if (defaultIntegration !== integration || defaultProduct !== productType) {
        setIsDefault(false)
      }
    }
  }, [integration, productType, isDefault])

  useEffect(() => {
    const defaultIntegration = getString(`${DEFAULT_INTEGRATION_KEY}-${props.org?.id}`) as
      | undefined
      | SupportedIntegrationType
    const defaultProduct: undefined | string = getString(`${DEFAULT_PRODUCT_KEY}-${props.org?.id}`)
    if (
      defaultIntegration &&
      defaultProduct &&
      PRODUCT_TYPE_OPTIONS[defaultIntegration]?.find((opt) => opt.value === defaultProduct)
    ) {
      setIsDefault(true)
      setIntegration(defaultIntegration)
      setProductType(defaultProduct)
    }
  }, [])

  useEffect(() => {
    if (props.showDialog) {
      logAmplitudeEvent('payment_option_comparison_opened', { project_id: window.WorkspaceHelper?.project.id })
    }
  }, [props.showDialog])

  const fetchDisplayRows = () => {
    setIsLoading(true)
    //@ts-ignore
    let totalPaydownIncentives = props.system?.pricing?.incentive_to_customer?.incentives?.reduce((acc, incentive) => {
      if (incentive?.use_for_loan_pay_down) return acc + incentive?.value
      else return acc
    }, 0)

    // if sunlight is on this system but force_apply_dealer_fee_discounts is false then just pass back the current system price
    let forcedSystemCost: number | undefined = undefined
    if (!props.system?.pricing?.force_apply_dealer_fee_discounts && props.system.payment_options?.length) {
      props.system.payment_options?.forEach((pmt) => {
        if (pmt.integration === 'sunlight') forcedSystemCost = pmt?.pricing?.system_price_including_tax
      })
    }

    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${props.org.id}/projects/${window.WorkspaceHelper?.project.id}/systems/${props.system.uuid}/payment_option_wizard/`,
      data: {
        product_type: productType,
        integration: integration,
        price: props.system?.pricing?.system_price_including_tax,
        forced_system_cost: forcedSystemCost,
        monthly_savings: props.monthlySavings,
        total_paydown_incentives: totalPaydownIncentives,
        state: window.WorkspaceHelper.project.state,
      },
    })
      .then((res: ResponseType) => {
        if (res.data.success && res.data.display_rows?.length > 0) {
          setDisplayRows(res.data.display_rows)
          setShowTable(true)
        } else {
          notify('This project does not have access to any active products that meet these criteria', 'warning')
        }
      })
      .catch((err) => {
        console.log('err', err)
        notify('Unable to fetch the estimated monthly payments', 'warning')
      })
      .finally(() => setIsLoading(false))
  }

  const toggleDefault = (e: ChangeEvent<HTMLInputElement>) => {
    setIsDefault(e.target.checked)
    if (e.target.checked && !!integration && !!productType) {
      setString(`${DEFAULT_INTEGRATION_KEY}-${props.org?.id}`, integration)
      setString(`${DEFAULT_PRODUCT_KEY}-${props.org?.id}`, productType)
    } else {
      setString(`${DEFAULT_INTEGRATION_KEY}-${props.org?.id}`, undefined)
      setString(`${DEFAULT_PRODUCT_KEY}-${props.org?.id}`, undefined)
    }
  }

  return (
    <Dialog open={props.showDialog} onClose={props.closeDialog} classes={{ paper: classes.mainDialog }}>
      <DialogTitle>Payment Option Comparison</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <InputLabel id="integration-select">Integration</InputLabel>
            <Select
              labelId="integration-select"
              value={integration}
              label="Integration"
              // @ts-ignore
              onChange={(e) => setIntegration(e.target.value)}
              fullWidth={true}
            >
              {INTEGRATION_OPTIONS?.map((opt) => {
                if (props.org[opt.org_field])
                  return (
                    <MenuItem key={opt.value} value={opt.value}>
                      {opt.label}
                    </MenuItem>
                  )
                else return null
              })}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <InputLabel id="product-type-select">Product Type</InputLabel>
            <Select
              labelId="product-type-select"
              value={productType}
              label="Product Type"
              // @ts-ignore
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setProductType(e.target.value)
              }}
              fullWidth={true}
              disabled={!integration}
            >
              {integration &&
                //@ts-ignore
                PRODUCT_TYPE_OPTIONS[integration]?.map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.label}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox checked={isDefault} disabled={!integration || !productType} onChange={toggleDefault} />
              }
              label="Remember my choice"
            />
          </Grid>
          <Grid item xs={12}>
            <p className="small">
              The Payment Option Comparison allows you to quickly get an estimated monthly payment for all of the
              integrated finance products you have access to. You can then use the list below to compare monthly
              payments against estimated utility savings and add/remove payment options as you see fit.
            </p>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <strong>Payment Option</strong>
          </Grid>
          <Grid item xs={3}>
            <strong>System Price</strong>
          </Grid>
          <Grid item xs={3}>
            <strong>Monthly Payment</strong>
          </Grid>
          {!showTable ? (
            <div className={classes.loadingWrapper}>{isLoading ? <LoadingDots /> : null}</div>
          ) : (
            <Grid item xs={12}>
              {displayRows.map((rowData) => (
                <PaymentOptionCompRow rowData={rowData} key={rowData.payment_option_id} system={props.system} />
              ))}
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
export default PaymentOptionCompDialog

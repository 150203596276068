import { Grid, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CustomField from 'elements/field/CustomField'
import React from 'react'
import { SelectInput, TextInput, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { EAGLEVIEW_PRODUCT_OPTIONS } from './constants'

type ChoiceType = {
  name: string
  id: string
}

type PropTypes = {}

const useStyles = makeStyles({
  applicantHeaderView: {},
  applicantHeaderText: {
    fontWeight: 900,
    fontSize: '18px',
  },
  applicantSection: {
    border: '1px solid #ececec',
    borderRadius: '5px',
    padding: '10px',
    width: '100%',
  },
})

const addressFields = [
  ['address', 6],
  ['city', 3],
  ['zip', 2],
  ['state', 1],
  ['lon', 2],
  ['lat', 2],
]

const EagleViewOrderForm: React.FunctionComponent<PropTypes> = (props) => {
  const translate = useTranslate()
  const form = useForm()
  const classes = useStyles()
  const isMobile = useMediaQuery('(max-width:900px)')

  return (
    <div>
      <Grid container spacing={1}>
        <Grid container spacing={1}>
          {addressFields.map((addressField) => {
            return (
              // TODO: fix this up
              //@ts-ignore
              <Grid item xs={addressField[1]}>
                <CustomField
                  name={addressField[0]}
                  source={addressField[0]}
                  component={TextInput}
                  fullWidth
                  style={{ width: '100%' }}
                  disabled={true}
                />
              </Grid>
            )
          })}
          <Grid item xs={12} sm={6}>
            <CustomField
              name="report_type"
              source="report_type"
              label="Product"
              component={SelectInput}
              choices={EAGLEVIEW_PRODUCT_OPTIONS}
              fullWidth
              style={{ width: '100%' }}
            />
          </Grid>

          <Grid item xs={6}>
            <CustomField
              name="special_instructions"
              source="special_instructions"
              label="Special Instructions (optional)"
              component={TextInput}
              fullWidth
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomField
              name="promo_code"
              source="promo_code"
              label="Promo Code (optional)"
              component={TextInput}
              fullWidth
              style={{ width: '100%' }}
            />
          </Grid>
          <p>By proceeding you agree to be bound by the Terms & Conditions</p>
        </Grid>
      </Grid>
    </div>
  )
}
export default EagleViewOrderForm

import Alert from 'elements/Alert'
import { useTranslate } from 'ra-core'

import React from 'react'
import { ModulePropTypes, ModuleReleaseAlertProgress } from '../types'

export const IAPModule_ReleaseAlertProgress: React.FC<ModulePropTypes<ModuleReleaseAlertProgress>> = ({
  module,
  translationSubs,
  showReload,
  minsRemaining,
}) => {
  const translate = useTranslate()

  return (
    <>
      {showReload && (
        <Alert severity="success" styles={{ width: '100%' }}>
          {translate(module.msgComplete || 'Update complete, please reload the page.', translationSubs)}
        </Alert>
      )}
      {!showReload && minsRemaining !== undefined && minsRemaining > 0 && (
        <Alert severity="info" styles={{ width: '100%' }}>
          {translate(
            module.msgTimeRemaining || 'Expected %{timeRemaining} remaining until update complete.',
            translationSubs
          )}
        </Alert>
      )}
      {!showReload && minsRemaining !== undefined && minsRemaining < 0 && (
        <Alert severity="warning" styles={{ width: '100%' }}>
          {translate(
            module.msgOverTime || 'Update is taking longer than expected, sorry for the inconvenience.',
            translationSubs
          )}
        </Alert>
      )}
    </>
  )
}

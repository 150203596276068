import { logAmplitudeEvent } from 'amplitude/amplitude'
import { Button } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useEffect, useState } from 'react'
import EditInvoiceDetailsDialog from './EditInvoiceDetailsDialog'

type PropTypes = {
  projectId: number
}

const EditInvoiceDetailsButton: React.FC<PropTypes> = (props) => {
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false)

  useEffect(() => {
    if (dialogIsOpen) {
      logAmplitudeEvent('cashflow_edit_invoice_details_launched', {
        source: 'invoices table',
        project_id: props.projectId,
      })
    }
  }, [dialogIsOpen])

  const translate = useTranslate()

  const closeDialog = () => {
    setDialogIsOpen(false)
  }
  return (
    <div>
      <Button onClick={() => setDialogIsOpen(true)} variant="contained" size="small">
        {translate('Edit Invoice Details')}
      </Button>
      {dialogIsOpen && <EditInvoiceDetailsDialog onClose={closeDialog} {...props} />}
    </div>
  )
}
export default EditInvoiceDetailsButton

import { makeStyles } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import PersonIcon from '@material-ui/icons/PersonOutlined'
import React from 'react'

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '40px',
    height: '40px',
    borderRadius: '40px',
    border: '2px solid #4d4d4d',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  smallWrapper: {
    width: '24px',
    height: '24px',
    borderRadius: '24px',
    border: '2px solid #4d4d4d',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

type PropTypes = {
  url: string | null
  personName: string | null
  small?: boolean
  is_archived?: boolean
}

const PersonAvatar: React.FunctionComponent<PropTypes> = (props) => {
  const classes = useStyles()
  return (
    <div
      className={props.small ? classes.smallWrapper : classes.wrapper}
      style={props.is_archived ? { opacity: 0.2 } : {}}
    >
      <Tooltip title={props.personName || ' '}>
        {props.url ? (
          <img
            src={props.url}
            alt="Person"
            className="portrait-image"
            style={{
              height: props.small ? '24px' : '40px',
              width: props.small ? '24px' : '40px',
            }}
          />
        ) : (
          <PersonIcon htmlColor="#4d4d4d" fontSize={props.small ? 'default' : 'large'} />
        )}
      </Tooltip>
    </div>
  )
}
export default PersonAvatar

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Signal = void 0;
var Signal = /** @class */ (function () {
    function Signal() {
        this.handlers = [];
    }
    Signal.prototype.add = function (handler) {
        this.handlers.push(handler);
    };
    Signal.prototype.remove = function (handler) {
        var index = this.handlers.indexOf(handler);
        if (index >= 0) {
            this.handlers.splice(index, 1);
        }
    };
    Signal.prototype.dispatch = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        for (var _a = 0, _b = this.handlers; _a < _b.length; _a++) {
            var handler = _b[_a];
            handler.apply(void 0, args);
        }
    };
    return Signal;
}());
exports.Signal = Signal;

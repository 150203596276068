import LaunchIcon from '@material-ui/icons/Launch'
import { clearRenusolDesign, hasRenusolDesign } from 'Designer/integrations/renusol/actions'
import { RENUSOL_COUNTRIES } from 'Designer/integrations/renusol/constants'
import Button from 'elements/button/Button'
import InfoTooltip from 'elements/tooltip/InfoTooltip'
import { useTranslate } from 'ra-core'
import { default as React, FC, useEffect, useState } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { StudioSystemType } from 'types/global'

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '0!important',
    },
  },
  label: {
    flexGrow: 1,
    marginRight: 10,
  },
}))

type LaunchRenusolButtonPropsType = {
  openRenusol: () => void
  system: StudioSystemType
}

const LaunchRenusolButton: FC<LaunchRenusolButtonPropsType> = ({ openRenusol, system }) => {
  const country = window.WorkspaceHelper?.project?.country_iso2
  const [enableRenusol, setEnableRenusol] = useState(country ? RENUSOL_COUNTRIES.includes(country) : false)
  useEffect(() => setEnableRenusol(country ? RENUSOL_COUNTRIES.includes(country) : false), [country])
  const translate = useTranslate()
  const classes = useStyles()

  if (enableRenusol) {
    return (
      <>
        <Button
          style={{ width: 230, margin: '10px 0', height: 'fit-content' }}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            openRenusol()
          }}
          size="small"
        >
          <span className={classes.wrapper}>
            <span className={classes.label}>{translate('Renusol Configurator')}</span>
            <InfoTooltip
              title={translate(
                'The following data will be sent to Renusol: Module data and orientation, roof slopes, approximate project location, project ID, user ID.'
              )}
            />
            <LaunchIcon />
          </span>
        </Button>
        {hasRenusolDesign(system) && (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault()
              clearRenusolDesign(system)
            }}
          >
            Clear Renusol Design
          </a>
        )}
      </>
    )
  } else {
    return null
  }
}

export default LaunchRenusolButton

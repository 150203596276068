import { TransitionProps } from '@material-ui/core/transitions/transition'
import React from 'react'

/**
 * A transition that does not animate.
 * This can be useful to get rid of the `findDOMNode` errors generated by the default transitions.
 */

export const TransitionNone = React.forwardRef<any, TransitionProps & { children?: React.ReactElement<any, any> }>(
  ({ children }, ref) => {
    return ref ? <div ref={ref}>{children}</div> : <>{children}</>
  }
)

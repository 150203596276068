// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { makeNumericIfPossible, parseValFromMultiLevelPath } from './util'

type PropTypes = {
  label: string
  fieldName: String
  i: number
  path: String
  activeData: Object
  proposalData: Object
  startDrag: (fieldName: string, value: string) => void
  endDrag: () => void
}

const PlaceholderSearchSuggestion: React.FunctionComponent<PropTypes> = (props) => {
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const [value, setValue] = useState<string>(undefined)

  useEffect(() => {
    try {
      let path = `${props.path}.${props.fieldName}`
      const newVal = makeNumericIfPossible(parseValFromMultiLevelPath(path, props.proposalData))
      if (typeof newVal === 'string') setValue(newVal)
    } catch (ex) {}
  }, [props.fieldName, props.path])

  const onHover = () => setIsHovered(true)
  const onBlur = () => setIsHovered(false)

  const onDragStart = () => {
    console.log(`${props.path}.${props.fieldName}`)
    const fullPath = `${props.path}.${props.fieldName}`
    props.startDrag(fullPath, value)
  }

  const onDragEnd = () => {
    props.endDrag()
  }

  return (
    <div
      key={props.i}
      style={{
        padding: '8px 0px',
        width: '100%',
        backgroundColor: isHovered ? '#ececec' : undefined,
        borderBottom: '1px solid #ececec',
        display: 'flex',
        flexDirection: 'column',
      }}
      onMouseEnter={onHover}
      onMouseLeave={onBlur}
      draggable={true}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          height: '20px',
          width: '100%',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          fontSize: '9px',
          textOverflow: 'ellipsis',
        }}
      >
        {props.path &&
          props.path?.split('.')?.map((part, i) => {
            return (
              <span style={{ marginLeft: '3px' }} key={`${part}-${i}`}>
                {i > 0 ? `> ${part}` : `path: ${part}`}
              </span>
            )
          })}
      </div>
      <span style={{ marginLeft: '5px', fontWeight: 900 }}>{props.label}</span>
      <span style={{ marginLeft: '5px' }}>{value}</span>
    </div>
  )
}
export default PlaceholderSearchSuggestion

import { makeStyles } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { setSelectTariffDialog } from 'ducks/selectTariff'
import { studioSelectors } from 'ducks/studioMode'
import { setStudioSavePrompt as setStudioSavePromptAction } from 'ducks/studioPrompt'
import { Button } from 'opensolar-ui'
import SelectTariffDialog from 'projectSections/sections/energy/ElectricityUtility/selectTariffDialog/SelectTariffDialog'
import React, { useCallback, useEffect, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { getPathForRecentTariffs } from 'resources/projects/elements/SelectTariffDialog'
import restClient from 'restClient'
import { SystemDataType } from 'types/systems'
import { RemoveComponentButton } from './tabs/common/RemoveComponentButton'

type PropTypes = {
  system: SystemDataType
  countryId?: number | null
  disabled?: boolean
}

const useStyles = () =>
  makeStyles(
    (theme) => {
      return {
        deleteBtn: { padding: 0, marginLeft: 5 },
        selectBtn: { margin: '0 auto' },
        selectWrapper: { display: 'flex', marginTop: 5 },
        detailsBtn: { textDecoration: 'underline', cursor: 'pointer' },
      }
    },
    { name: 'Tariff-Override' }
  )()

const TariffApplied: React.FC<PropTypes> = ({ system }) => {
  const notify = useNotify()
  const classes = useStyles()
  const dispatch = useDispatch()
  const orgId = useSelector(authSelectors.getOrgId)
  const [tariffName, setTariffName] = useState(undefined)
  const isStudioMode = useSelector(studioSelectors.isInStudio)

  useEffect(() => {
    if (system.utility_tariff_id_override) {
      restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${orgId}/utility_tariffs/${system.utility_tariff_id_override}/`,
      })
        .then((response) => {
          setTariffName(response?.data?.code)
        })
        .catch((err) => {
          notify('Error trying to retrieve tariff override data', 'warning')
        })
    }
  }, [system.utility_tariff_id_override])

  if (!system.utility_tariff_id_override || !tariffName) {
    return null
  }
  return (
    <>
      <Link
        className={classes.detailsBtn}
        onClick={(event) => {
          event.preventDefault()
          dispatch(
            setStudioSavePromptAction({
              show_prompt: true,
              redirect: '/utility_tariffs/' + system.utility_tariff_id_override,
              prompt_heading: 'Leave Project to View Tariff?',
              prompt_message: 'Any unsaved changes will be discarded.',
            })
          )
        }}
        to={''}
      >
        {tariffName}
      </Link>
      <RemoveComponentButton
        onClick={() => {
          window.editor.execute(new window.SetValueCommand(system, 'utility_tariff_id_override', null, undefined, true))
          dispatch(setSelectTariffDialog(false, null, null, null, isStudioMode))
        }}
      />
    </>
  )
}
const restClientInstance = restClient(window.API_ROOT + '/api')
const TariffOverride: React.FC<PropTypes> = ({ system, countryId, disabled = false }) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const translate = useTranslate()
  const isStudioMode = useSelector(studioSelectors.isInStudio)
  const projectId = useSelector((state: any) => state.projectId)
  const handleSelect = useCallback((record) => {
    const utilityTariffId = record.id
    window.editor.execute(
      new window.SetValueCommand(window.editor?.selectedSystem, 'utility_tariff_id_override', utilityTariffId)
    )
    dispatch(setSelectTariffDialog(false, null, null, null, isStudioMode))
  }, [])

  return (
    <div id="tariff-override">
      <div style={{ margin: '20px 0px 20px 0px' }}>
        <p>
          {translate('Tariff Override:')}{' '}
          {system.utility_tariff_id_override ? <TariffApplied system={system} /> : <>{translate('None')}</>}
        </p>
        <div className={classes.selectWrapper}>
          <Button
            className={classes.selectBtn}
            variant="contained"
            color="default"
            size="small"
            onClick={(e) => {
              e.preventDefault()
              history.push(getPathForRecentTariffs(history.location.pathname)) //ensure get params are cleared
              dispatch(setSelectTariffDialog(true, projectId, countryId, 'proposed', isStudioMode))
            }}
            disabled={disabled}
          >
            <span>{translate('Select Tariff')}</span>
          </Button>
        </div>
      </div>
      <SelectTariffDialog
        onSelect={handleSelect}
        perPage={100}
        sort={'name'}
        countryId={countryId}
        hideActions={true}
      />
    </div>
  )
}

export default TariffOverride

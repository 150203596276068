import { FormControlLabel, InputLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core'
import dayjs from 'dayjs'
import { DateInput } from 'elements/input/DateInput'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { FormControl, Grid, MenuItem, Select } from 'opensolar-ui'
import { branchData } from 'pages/ordering/checkout/cityPlumbing/branchData'
import { DeliveryBranchType } from 'pages/ordering/checkout/cityPlumbing/types'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import { ChangeEvent, FC, memo, useContext } from 'react'
import { Theme } from 'types/themes'
import AccordionCardSummaryContent from '../components/AccordionCardSummaryContent'
import AccordionCardTitle from '../components/AccordionCartTitle'
import Alert from '../components/Alert'
import DeliveryInstruction from '../components/DeliveryInstruction'
import DistributorDeliveryDetail from '../components/DistributorDeliveryDetail'
import { ErrorHelperText } from '../components/ErrorHelperText'
import PartsList from '../components/PartsList'
import { SOURCE_CONTEXT_MAPPING } from '../constants'
import { COLOR_PALETTE, getHardwareSectionPreOrderStyles } from '../styles'
import { CartSectionContentPropType, CartSectionPropType } from '../types'
import { useTranslate } from 'ra-core'

const timeOptions = [
  { id: 'before_10_30_am', name: 'Before 10:30 AM' },
  { id: 'am', name: 'AM (After 10:30 AM)' },
  { id: 'pm', name: 'PM' },
]

const computeShippingFee = (date, time) => {
  const diff = moment(new Date(date)).startOf('day').diff(moment().startOf('day'), 'days')
  const numberOfSundays =
    moment(date).day() === 0
      ? Math.floor(diff / 7) + 1
      : moment().day() > moment(date).day()
      ? Math.floor(diff / 7) + 1
      : Math.floor(diff / 7)

  //exclude weekends
  const numberOfDays = diff - numberOfSundays * 2

  if (numberOfDays === 1) {
    return '75.00'
  } else if (numberOfDays >= 2 && numberOfDays < 5) {
    if (time === 'before_10_30_am') {
      return '75.00'
    } else {
      return '40.00'
    }
  } else if (numberOfDays >= 5) {
    if (time === 'before_10_30_am') {
      return '25.00'
    } else {
      return 'FREE'
    }
  }
  return 'TBC'
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    gap: '24px',
    padding: '8px',
  },
  gap: {
    gap: '8px',
  },
  radioGroup: {
    marginTop: '4px',
    gap: '20px',
  },
  radio: {
    padding: 0,
    '&.Mui-checked': {
      color: COLOR_PALETTE.blue2,
    },
  },
  deliveryItemDetailContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    gap: '10px',
    marginLeft: '40px',
  },
  deliveryItemDetailTitle: {
    fontSize: '16px',
    color: COLOR_PALETTE.black,
    lineHeight: '24px',
  },
  deliveryItemDetailSubtitle: {
    fontSize: '14px',
    color: COLOR_PALETTE.grey,
  },
  deliveryItemTitleWrapper: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '8px',
  },
  branchTitle: {
    fontSize: '14px',
    fontWeight: 600,
    color: COLOR_PALETTE.grey5,
  },
  branchInfo: {
    fontSize: '14px',
    color: COLOR_PALETTE.grey5,
  },
  branchMDot: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: COLOR_PALETTE.grey5,
  },
}))

const CityPlumbingCartSection: FC<CartSectionPropType> = ({ sortOrder, checkoutSource, distributorKey }) => {
  const sectionStyles = getHardwareSectionPreOrderStyles()
  const translate = useTranslate()
  const distributorData = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].distributorDataContext)
  const data = distributorData[distributorKey]
  return (
    <AccordionCard
      name={'distributor-cp'}
      defaultExpanded={true}
      elevation={0}
      className={sectionStyles.accordionReskin}
      title={<AccordionCardTitle distributorKey={distributorKey} sortOrder={sortOrder} />}
      summaryContent={
        <AccordionCardSummaryContent
          totalItems={data?.totalItems}
          subtotal={data?.subtotalPrice}
          shippingFee={data?.shippingInfo.fee}
        />
      }
      contentProps={{ distributorKey, checkoutSource }}
      content={CityPlumbingCartSectionContent}
      lazyRender={true}
    />
  )
}

const CityPlumbingCartSectionContent: FC<CartSectionContentPropType> = ({ distributorKey, checkoutSource }) => {
  const preOrderPresenter = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].presenterContext)
  const distributorData = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].distributorDataContext)
  const contactInfo = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].contactInfoContext)
  const deliveryAddressInfo = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].deliveryAddressInfoContext)
  const data = distributorData[distributorKey]
  const translate = useTranslate()
  const classes = useStyles()
  const sectionStyles = getHardwareSectionPreOrderStyles()
  const now = new Date()
  const deliveryMinDate = new Date()
  const clickAndCollectMinDate = new Date()
  deliveryMinDate.setDate(now.getDate() + 1)
  clickAndCollectMinDate.setDate(now.getDate() + 3)

  const isDelivery = data?.shippingInfo?.deliveryMethod === 'delivery'

  const handleUpdateDeliveryMethod = (event: React.ChangeEvent<HTMLInputElement>) => {
    preOrderPresenter?.updateDistributorData({
      ...distributorData,
      [distributorKey]: {
        ...data,
        shippingInfo: {
          ...data?.shippingInfo,
          deliveryMethod: event.target.value,
          deliveryDate: '',
          deliveryTime: '',
          deliveryBranch: {
            name: '',
            manager: '',
            email: '',
            contactNumber: '',
            address: '',
            weekdayHours: '',
            saturdayHours: '',
            sundayHours: '',
          },
          fee: event.target.value === 'delivery' ? 'TBC' : 'FREE',
        },
      },
    })
  }

  const handleUpdateDeliveryDate = (date: any) => {
    const formattedDate = dayjs(date).format('MM/DD/YYYY')
    const shippingFee = computeShippingFee(formattedDate, data?.shippingInfo?.deliveryTime)
    preOrderPresenter?.updateDistributorData({
      ...distributorData,
      [distributorKey]: {
        ...data,
        shippingInfo: {
          ...data?.shippingInfo,
          deliveryDate: formattedDate,
          fee: shippingFee,
        },
      },
    })
  }

  const handleUpdateDeliveryTime = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const time = event.target.value
    const shippingFee = computeShippingFee(data?.shippingInfo?.deliveryDate, time)
    preOrderPresenter?.updateDistributorData({
      ...distributorData,
      [distributorKey]: {
        ...data,
        shippingInfo: {
          ...data?.shippingInfo,
          deliveryTime: time,
          fee: shippingFee,
        },
      },
    })
  }

  const handleUpdateDeliveryInstructions = (event: React.ChangeEvent<HTMLInputElement>) => {
    preOrderPresenter?.updateDistributorData({
      ...distributorData,
      [distributorKey]: {
        ...data,
        shippingInfo: {
          ...data?.shippingInfo,
          deliveryInstructions: event.target.value,
        },
      },
    })
  }

  const handleUpdateBranchData = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const deliveryBranchData: DeliveryBranchType = branchData.find(
      (item) => item.name === event.target.value
    ) as DeliveryBranchType
    preOrderPresenter?.updateDistributorData({
      ...distributorData,
      [distributorKey]: {
        ...data,
        shippingInfo: {
          ...data?.shippingInfo,
          deliveryBranch: deliveryBranchData,
        },
      },
    })
  }

  return (
    <Grid container className={classes.container}>
      <Grid item className={`${sectionStyles.column} ${classes.gap}`} xs={12} md={12}>
        <Alert
          title={translate('What’s next after sending my order?')}
          subtitle={translate('Once sent, your order will be transferred to the City Plumbing’s portal for payment processing and final shipping confirmation. You will then receive a City Plumbing order reference ID for tracking purposes.')}
          severity="info"
        />
      </Grid>
      <Grid item className={`${sectionStyles.column}`} xs={12} md={12}>
        <DistributorDeliveryDetail distributorKey={distributorKey} checkoutSource={checkoutSource} />
      </Grid>
      <Grid item className={`${sectionStyles.column} ${classes.gap}`} xs={12} md={12}>
        <span className={sectionStyles.accordionHeaderTitle}>{translate('Select Delivery Method')}</span>
        <RadioGroup
          className={classes.gap}
          name="deliveryMethod"
          onChange={handleUpdateDeliveryMethod}
          value={data?.shippingInfo?.deliveryMethod}
        >
          <FormControlLabel
            className={classes.deliveryItemTitleWrapper}
            key={'delivery-method-delivery'}
            value={'delivery'}
            control={<Radio className={classes.radio} />}
            label={
              <div className={sectionStyles.column}>
                <span className={classes.deliveryItemDetailTitle}>{translate('Delivery')}</span>
                <span className={classes.deliveryItemDetailSubtitle}>
                  {translate('The delivery cost is calculated after the preferred date and time have been selected.')}
                </span>
              </div>
            }
          />
          <Grid className={classes.deliveryItemDetailContainer} container>
            <Grid item xs={4}>
              <DateInput
                fullWidth
                size="medium"
                value={isDelivery ? dayjs(data?.shippingInfo?.deliveryDate) : null}
                onChange={handleUpdateDeliveryDate}
                inputVariant="outlined"
                label={<InputLabel className={sectionStyles.inputLabel}>{translate('Ship by')}</InputLabel>}
                format="MM/DD/YYYY"
                minDate={dayjs(deliveryMinDate)}
                autoFocus={isDelivery}
                shouldDisableDate={(date) => dayjs(date).day() === 0 || dayjs(date).day() === 6}
                disabled={!isDelivery}
              />
              <ErrorHelperText showText={isDelivery && isEmpty(data?.shippingInfo?.deliveryDate)} />
            </Grid>
            <Grid item xs={4}>
              <FormControl
                fullWidth
                disabled={!isDelivery}
                error={isDelivery && isEmpty(data?.shippingInfo?.deliveryTime)}
              >
                <InputLabel className={sectionStyles.inputLabel}>{translate('Time')}</InputLabel>
                <Select value={data?.shippingInfo?.deliveryTime} onChange={handleUpdateDeliveryTime}>
                  {timeOptions.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <ErrorHelperText showText={isDelivery && isEmpty(data?.shippingInfo?.deliveryTime)} />
              </FormControl>
            </Grid>
          </Grid>
          <FormControlLabel
            className={classes.deliveryItemTitleWrapper}
            key={'delivery-method-click-&-collect'}
            value={'click_and_collect'}
            control={<Radio className={classes.radio} />}
            label={
              <div className={sectionStyles.column}>
                <span className={classes.deliveryItemDetailTitle}>{translate('Click & Collect')}</span>
                <span className={classes.deliveryItemDetailSubtitle}>
                  {translate(
                    'Select your preferred branch for pickup, and City Plumbing will notify you by email and text when your\n' +
                    'order is ready. Please note that a 3-day advance notice is required, so the earliest available\n' +
                    'collection dates will be 3 days from the order date.'
                  )}
                </span>
              </div>
            }
          />
          <Grid className={classes.deliveryItemDetailContainer} container>
            <Grid item xs={4}>
              <DateInput
                fullWidth
                size="medium"
                value={!isDelivery ? dayjs(data?.shippingInfo?.deliveryDate) : null}
                onChange={handleUpdateDeliveryDate}
                inputVariant="outlined"
                label={<InputLabel className={sectionStyles.inputLabel}>{translate('Ship by')}</InputLabel>}
                format="MM/DD/YYYY"
                minDate={dayjs(clickAndCollectMinDate)}
                autoFocus={isDelivery}
                shouldDisableDate={(date) => dayjs(date).day() === 0 || dayjs(date).day() === 6}
                disabled={isDelivery}
              />
              <ErrorHelperText showText={!isDelivery && isEmpty(data?.shippingInfo?.deliveryDate)} />
            </Grid>
            <Grid item xs={4}>
              <FormControl
                fullWidth
                disabled={isDelivery}
                error={!isDelivery && isEmpty(data?.shippingInfo?.deliveryTime)}
              >
                <InputLabel className={sectionStyles.inputLabel}>{translate('Branch')}</InputLabel>
                <Select value={data?.shippingInfo?.deliveryBranch?.name} onChange={handleUpdateBranchData}>
                  {branchData.map((item) => (
                    <MenuItem key={item.name} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <ErrorHelperText showText={!isDelivery && isEmpty(data?.shippingInfo?.deliveryBranch?.name)} />
              </FormControl>
            </Grid>
          </Grid>
          {!isEmpty(data?.shippingInfo?.deliveryBranch?.name) && (
            <Grid className={classes.deliveryItemDetailContainer} container>
              <Grid item xs={12} className={sectionStyles.row}>
                <Grid container>
                  <Grid item xs={4} className={sectionStyles.column}>
                    <span className={classes.branchTitle}>{translate('Branch Manager')}</span>
                    <span className={classes.branchInfo}>{data?.shippingInfo?.deliveryBranch?.manager}</span>
                  </Grid>
                  <Grid item xs={6} className={sectionStyles.column}>
                    <span className={classes.branchTitle}>{translate('Contact Information')}</span>
                    <span
                      className={classes.branchInfo}
                    >{`${data?.shippingInfo?.deliveryBranch?.email} / ${data?.shippingInfo?.deliveryBranch?.contactNumber}`}</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className={sectionStyles.row}>
                <Grid container>
                  <Grid item xs={4} className={sectionStyles.column}>
                    <span className={classes.branchTitle}>{translate('Opening Hours')}</span>
                    <div className={sectionStyles.row} style={{ gap: '8px' }}>
                      <span className={classes.branchMDot}>&middot;</span>
                      <span
                        className={classes.branchInfo}
                      >{`${translate('Weekday')}: ${data?.shippingInfo?.deliveryBranch?.weekdayHours}`}</span>
                    </div>
                    <div className={sectionStyles.row} style={{ gap: '8px' }}>
                      <span className={classes.branchMDot}>&middot;</span>
                      <span
                        className={classes.branchInfo}
                      >{`${translate('Saturday')}: ${data?.shippingInfo?.deliveryBranch?.saturdayHours}`}</span>
                    </div>
                    <div className={sectionStyles.row} style={{ gap: '8px' }}>
                      <span className={classes.branchMDot}>&middot;</span>
                      <span
                        className={classes.branchInfo}
                      >{`${translate('Sunday')}: ${data?.shippingInfo?.deliveryBranch?.sundayHours}`}</span>
                    </div>
                  </Grid>
                  <Grid item xs={6} className={sectionStyles.column}>
                    <span className={classes.branchTitle}>{translate('Contact Information')}</span>
                    <span className={classes.branchInfo}>{translate('Contact Information')}</span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </RadioGroup>
      </Grid>
      <Grid item className={`${sectionStyles.column} ${classes.gap}`} xs={12} md={12}>
        <DeliveryInstruction
          handleUpdateDeliveryInstructions={handleUpdateDeliveryInstructions}
          value={data?.shippingInfo?.deliveryInstructions}
        />
      </Grid>
      <Grid item className={`${sectionStyles.column} ${classes.gap}`} xs={12} md={12}>
        <span className={sectionStyles.headerTitle}>{translate('Review Parts List')}</span>
        <PartsList data={data} />
      </Grid>
      <Grid item className={`${sectionStyles.row} ${classes.gap}`} xs={12} md={12}>
        <span>{translate('By placing your order, you agree to City Plumbing’s Privacy Policy and Conditions of Use')}</span>
      </Grid>
    </Grid>
  )
}

export default memo(CityPlumbingCartSection)

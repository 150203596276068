var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/styles';
import React, { useMemo } from 'react';
var useStyles = makeStyles({
    root: {
        padding: 12,
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // Checkered BG
        backgroundImage: 'linear-gradient(45deg, #f3f3f3 25%, transparent 25%, transparent 75%, #f3f3f3 75%, #f3f3f3 100%),linear-gradient(45deg, #f3f3f3 25%, white 25%, white 75%, #f3f3f3 75%, #f3f3f3 100%)',
        backgroundSize: '20px 20px',
        backgroundPosition: '0px 0px, 10px 10px',
    },
}, { name: 'ExampleComponent' });
export function ExampleComponent(_a) {
    var type = _a.type, props = _a.props, propDefs = _a.propDefs;
    var classes = useStyles();
    var newProps = useMemo(function () {
        var newProps = {};
        if (propDefs) {
            for (var key in propDefs) {
                if (props.hasOwnProperty(key)) {
                    newProps[key] = props[key];
                }
            }
        }
        return newProps;
    }, [propDefs, props]);
    return _jsx("div", __assign({ className: classes.root }, { children: React.createElement(type, newProps) }));
}

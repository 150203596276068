export const updateSystemHardware = (
  componentType: string,
  componentActivationId: number,
  targetUuids: string[],
  params?: Record<string, any>
) => {
  if (componentType === 'module') {
    targetUuids.forEach((targetUuid) => {
      var system = window.editor.objectByUuid(targetUuid)
      window.editor.execute(
        new window.UpdateElectricalsCommand(system, 'setModuleTypeByModuleId', componentActivationId, system.moduleId)
      )
    })
  } else if (componentType === 'inverter') {
    var inverterType = window.AccountHelper.getInverterById(componentActivationId)
    targetUuids.forEach((targetUuid) => {
      var inverter = window.editor.objectByUuid(targetUuid)
      if (!inverter) {
        window.editor.execute(
          new window.AddObjectCommand(
            new OsInverter({ inverter_id: componentActivationId }),
            window.editor.selectedSystem,
            false
          )
        )
      } else if (inverterType) {
        const oldInverterType = inverter.getComponentData()
        window.editor.execute(
          new window.UpdateElectricalsCommand(inverter, 'componentType', inverterType, oldInverterType)
        )
      } else {
        console.error('Error selecting component')
      }
    })
  } else if (componentType === 'battery') {
    var batteryType = window.AccountHelper.getBatteryById(componentActivationId)
    targetUuids.forEach((targetUuid) => {
      var battery = window.editor.objectByUuid(targetUuid)

      if (!battery) {
        window.editor.execute(
          new window.AddObjectCommand(
            new OsBattery({ battery_id: componentActivationId }),
            window.editor.selectedSystem,
            false
          )
        )
      } else if (batteryType) {
        const oldBatteryType = battery.getComponentData()
        window.editor.execute(
          new window.UpdateElectricalsCommand(battery, 'componentType', batteryType, oldBatteryType)
        )
      } else {
        console.error('Error selecting component')
      }
    })
  } else if (componentType === 'other') {
    var otherType = window.AccountHelper.getOtherById(componentActivationId)
    if (otherType?.other_component_type === 'dc_optimizer') {
      window.editor.selectedSystem.dcOptimizer(componentActivationId)
    } else {
      if (!targetUuids?.length) {
        window.editor.execute(
          new window.AddObjectCommand(
            new window.OsOther({ other_id: componentActivationId, ...(params || {}) }),
            window.editor.selectedSystem,
            false
          )
        )
      } else {
        targetUuids.forEach((targetUuid) => {
          var other = window.editor.objectByUuid(targetUuid)
          if (otherType) {
            const oldOtherType = other.getComponentData()
            window.editor.execute(new window.UpdateElectricalsCommand(other, 'componentType', otherType, oldOtherType))
          }
        })
      }
    }
  }
}

import type LineItemType from 'pages/ordering/OrderLineItem'
import type SegenServiceV2 from 'services/segen/SegenServiceV2'
import type { ShippingDetailsType } from 'services/segen/type'
import type { AccountDetail, CheckoutForm, OrderDetail, QuoteDetail } from './SegenCheckoutProvider'

class SegenCheckoutPresenter {
  private segenService: SegenServiceV2
  private setQuoteDetail: React.Dispatch<React.SetStateAction<QuoteDetail>>
  private setAccountDetail: React.Dispatch<React.SetStateAction<AccountDetail>>
  private setOrderDetail: React.Dispatch<React.SetStateAction<OrderDetail>>
  private setCheckoutForm: React.Dispatch<React.SetStateAction<CheckoutForm>>
  private setShowAddressCompletionForm: React.Dispatch<React.SetStateAction<boolean>>

  constructor(
    segenService: SegenServiceV2,
    setQuoteDetail: React.Dispatch<React.SetStateAction<QuoteDetail>>,
    setAccountDetail: React.Dispatch<React.SetStateAction<AccountDetail>>,
    setOrderDetail: React.Dispatch<React.SetStateAction<OrderDetail>>,
    setCheckoutForm: React.Dispatch<React.SetStateAction<CheckoutForm>>,
    setShowAddressCompletionForm: React.Dispatch<React.SetStateAction<boolean>>
  ) {
    this.segenService = segenService
    this.setQuoteDetail = setQuoteDetail
    this.setAccountDetail = setAccountDetail
    this.setOrderDetail = setOrderDetail
    this.setCheckoutForm = setCheckoutForm
    this.setShowAddressCompletionForm = setShowAddressCompletionForm
  }

  refreshAccountDetail = async () => {
    this.setAccountDetail((accountDetail: AccountDetail) => ({
      ...accountDetail,
      loading: true,
    }))
    const account = await this.segenService.getAccountDetail()

    this.setAccountDetail((accountDetail: AccountDetail) => ({
      account,
      loading: false,
    }))
  }

  updateShowAddressCompletionForm = (value: boolean) => {
    this.setShowAddressCompletionForm(value)
  }

  updateCheckoutForm = <T extends keyof CheckoutForm>({ key, value }: { key: T; value: CheckoutForm[T] }) => {
    this.setCheckoutForm((state: CheckoutForm) => ({
      ...state,
      [key]: value,
    }))
  }

  refreshQuoteDetail = async (lineItems: LineItemType[]) => {
    this.setQuoteDetail((quoteDetail: QuoteDetail) => ({
      ...quoteDetail,
      loading: true,
    }))
    const quote = await this.segenService.placeQuote(lineItems)

    this.setQuoteDetail((quoteDetail: QuoteDetail) => ({
      quote,
      loading: false,
    }))

    this.updateCheckoutForm<'address'>({ key: 'address', value: quote?.deliveryAddress })
  }

  updateShippingDetails = async ({
    reference,
    shippingDetails,
    onSuccess,
    onFailed,
  }: {
    reference: string
    shippingDetails: ShippingDetailsType
    onSuccess?(): void
    onFailed?(): void
  }) => {
    this.setQuoteDetail((quoteDetail: QuoteDetail) => ({
      ...quoteDetail,
      loading: true,
    }))
    const quote = await this.segenService.editQuote({ reference, shippingDetails })
    if (!quote) {
      this.setQuoteDetail((quoteDetail: QuoteDetail) => ({
        ...quoteDetail,
        loading: false,
      }))
      onFailed?.()
      return
    }
    this.setQuoteDetail((quoteDetail: QuoteDetail) => ({
      quote,
      loading: false,
    }))
    onSuccess?.()
  }

  getSegenCountryIds = async () => {
    const countryIds = (await this.segenService.getCountryIds()) || []
    this.setCheckoutForm((state: CheckoutForm) => ({
      ...state,
      segenOptions: { ...state.segenOptions, countryIds },
    }))
    return countryIds
  }

  getDeliveryMethods = async () => {
    const deliveryMethodOptions = (await this.segenService.getDeliveryMethodPreference()) || []
    this.setCheckoutForm((state: CheckoutForm) => ({
      ...state,
      segenOptions: { ...state.segenOptions, deliveryMethodOptions },
    }))
    return deliveryMethodOptions
  }

  placeOrder = async (orderId: number, shippingDetails: ShippingDetailsType, stockLocationName?: string) => {
    this.setOrderDetail({
      submitting: true,
      isReady: false,
    })
    const order = await this.segenService.placeOrder(orderId, shippingDetails, stockLocationName)
    if (order?.error) {
      this.setOrderDetail({
        submitting: false,
        isReady: false,
      })
      if (order?.error.includes('ShippingAddress')) {
        this.setShowAddressCompletionForm(true)
      }
    } else if (order !== null) {
      this.setOrderDetail({
        submitting: false,
        isReady: true,
        order,
      })
    }

    return order
  }

  init = async (lineItems: LineItemType[]) => {
    this.getSegenCountryIds()
    this.getDeliveryMethods()
    // have to wait account and quote result
    await Promise.all([this.refreshAccountDetail(), this.refreshQuoteDetail(lineItems)])
    this.updateCheckoutForm<'initialing'>({ key: 'initialing', value: false })
  }
}

export default SegenCheckoutPresenter

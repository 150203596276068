import { ConditionFactory, SystemContext } from 'Designer/designRules/types'
import { BaseConditionDef } from '.'

// Can be useful as a fallback condition in state-machine style rulesets

export type PassConditionDef = BaseConditionDef & {
  type: 'pass'
}

export const condition_pass: ConditionFactory<PassConditionDef> = (def) => {
  return {
    check: (context: SystemContext) => {
      return true
    },
  }
}

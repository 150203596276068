import type { HardwareSupplierFilterKeyType } from 'pages/ordering/type'

type ComponentOverridesType = {
  [key: string]: {
    price: number
    source: 'unknown' | 'manual' | HardwareSupplierFilterKeyType
  }
}

export const isCogsOverrideDistributorChanged = (
  currentSelectedDistributor: HardwareSupplierFilterKeyType | undefined
): boolean => {
  const overrides: ComponentOverridesType | undefined =
    // @ts-ignore
    window.editor.selectedSystem?.getCogsOverride()
  if (!overrides) {
    return false
  }

  const previousDistributors = Object.values(overrides).map((override) => override.source)

  if (
    previousDistributors.length &&
    currentSelectedDistributor &&
    previousDistributors.some(
      (previousDistributor) =>
        previousDistributor !== 'unknown' &&
        previousDistributor !== 'manual' &&
        previousDistributor !== currentSelectedDistributor
    )
  ) {
    return true
  }
  return false
}

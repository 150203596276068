import { Grid } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import type { FilterComponentNode, FilterKeysType, FilterValuesType } from 'elements/hardwareFilter/type'
import ClearFilterButton from 'elements/hardwareSelector/filters/ClearFilterButton'
import React, { memo, useCallback } from 'react'

const HardwareFilterV2 = ({
  trackSource,
  allFilterValues,
  modifiableFilterValues,
  persistentFilterValues,
  filterNodes,
  setModifiableFilters,
}: {
  trackSource: string
  allFilterValues: FilterValuesType
  modifiableFilterValues: FilterValuesType
  persistentFilterValues: FilterValuesType
  filterNodes: FilterComponentNode[]
  setModifiableFilters(
    newFilterValues: FilterValuesType,
    displayedFilters?: { [key in FilterKeysType]?: boolean }
  ): void
}) => {
  const handleClear = useCallback(() => {
    logAmplitudeEvent('hardware_select_filters', {
      action: 'clicked',
      context: 'clear_filter_button',
      source: trackSource,
    })

    const emptyFilterValue = {}
    setModifiableFilters(emptyFilterValue)
  }, [setModifiableFilters, trackSource, filterNodes])

  return (
    <Grid container wrap="nowrap" justify="space-between">
      <Grid container item xs={'auto'} wrap="wrap">
        {filterNodes.map((node: FilterComponentNode | undefined) => {
          if (node === undefined) return null
          const Filter = node.component

          return (
            <Filter
              key={node.key}
              persistentFilterValues={persistentFilterValues}
              modifiableFilterValues={modifiableFilterValues}
              allFilterValues={allFilterValues}
              setModifiableFilters={setModifiableFilters}
            />
          )
        })}
      </Grid>
      <Grid item xs={'auto'}>
        <ClearFilterButton handleClear={handleClear} />
      </Grid>
    </Grid>
  )
}

export default memo(HardwareFilterV2)

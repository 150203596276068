import TourTooltip from 'elements/tooltip/TourTooltip'
import { HourGlassOutlineIcon, styled } from 'opensolar-ui'
import { TourConfigType } from 'persistentContent/tours/types'
import { useTranslate } from 'ra-core'
import { FC } from 'react'

const StyledHourGlassIcon = styled(HourGlassOutlineIcon)({
  marginBottom: -5,
})

const StepTooltip: FC = (props) => {
  const translate = useTranslate()
  return (
    <TourTooltip
      title={translate('Your Activities')}
      subtext={
        <>
          {translate(
            'Track all project activities, tasks, and notes in the Project History. View your project’s complete history or sort updates by type in dedicated tabs.\nLook for this icon to access your project’s activity log:'
          )}
          <StyledHourGlassIcon variant={3} size={20} />
        </>
      }
      {...props}
    />
  )
}

const ProjectHistory: TourConfigType = {
  spotlights: [{ targetId: 'Project-History-Section-Inner', autoScroll: true }],
  tooltips: [
    {
      targetId: 'Project-History-Section-Inner',
      placement: 'left',
      component: StepTooltip,
    },
  ],
}

export default ProjectHistory

import { orgSelectors } from 'ducks/orgs'
import { EmailTemplates, InvoiceSettings } from 'pages/cashFlow/types'
import { useNotify } from 'ra-core'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'

const restClientInstance = restClient(window.API_ROOT + '/api')

export const useGetInvoiceSettings = () => {
  const [invoiceSettings, setInvoiceSettings] = useState<InvoiceSettings[]>([])
  const [invoiceSettingsLoading, setInvoiceSettingsLoading] = useState<boolean>(false)

  const orgId = useSelector(orgSelectors.getOrg)?.id
  const notify = useNotify()

  useEffect(() => {
    if (orgId) {
      setInvoiceSettingsLoading(true)
      restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${orgId}/invoices/invoice_settings/`,
      })
        .then((res) => {
          setInvoiceSettings(res.data)
        })
        .catch((err) => {
          notify('Something went wrong and we are unable retrieve the invoice settings', 'error')
        })
        .finally(() => setInvoiceSettingsLoading(false))
    }
  }, [orgId])

  return { invoiceSettings, invoiceSettingsLoading }
}

export const saveInvoiceSettings = (orgId: number, formVals: InvoiceSettings) => {
  return new Promise<boolean>((resolve, reject) => {
    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${orgId}/invoices/invoice_settings/`,
      data: formVals,
    })
      .then((res) => {
        resolve(true)
      })
      .catch((err) => {
        reject(false)
      })
  })
}

export const useGetEmailConfiguration = () => {
  const [emailConfiguration, setEmailConfiguration] = useState<EmailTemplates[]>([])
  const [emailConfigLoading, setEmailConfigLoading] = useState<boolean>(true)

  const orgId = useSelector(orgSelectors.getOrg)?.id
  const notify = useNotify()

  useEffect(() => {
    if (orgId) {
      setEmailConfigLoading(true)
      restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${orgId}/cashflow/email_configuration/`,
      })
        .then((res) => {
          setEmailConfiguration(res.data)
        })
        .catch((err) => {
          notify('Something went wrong and we are unable retrieve your email configuration settings', 'error')
        })
        .finally(() => setEmailConfigLoading(false))
    }
  }, [orgId])

  return { emailConfiguration, emailConfigLoading }
}

export const saveEmailConfiguration = (orgId: number, formVals: any) => {
  return new Promise<boolean>((resolve, reject) => {
    restClientInstance('CUSTOM_PATCH', 'custom', {
      url: `orgs/${orgId}/cashflow/email_configuration/`,
      data: formVals,
    })
      .then((res) => {
        resolve(true)
      })
      .catch((err) => {
        reject(false)
      })
  })
}

export const NORMAL_CONTACT = 0
export const ANON_SHARE_CONTACT = 1

export interface ContactType {
  id: number | null
  email: string | null
  phone: string
  first_name: string
  family_name: string
  full_name: string
  org_id: number | null
  display: string
  gender?: 0 | 1 | 2
  middle_name?: string
  date_of_birth?: string
  url: string
  passport_number?: string
  licence_number?: string
  type: typeof NORMAL_CONTACT | typeof ANON_SHARE_CONTACT
  custom_data?: any
}

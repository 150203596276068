import { isEmpty } from 'lodash'
import { ContactAddressType } from './types'

export const getDeliveryFeeText = (fee: string, currencySymbol: string) => {
  return ['TBC', 'FREE'].includes(fee) ? fee : `${currencySymbol} ${fee}`
}

export const isValidAddress = (contactAddress: ContactAddressType) => {
  return (
    !isEmpty(contactAddress.address) &&
    !isEmpty(contactAddress.countryIso2) &&
    !isEmpty(contactAddress.countryName) &&
    !isEmpty(contactAddress.locality) &&
    !isEmpty(contactAddress.zip)
  )
}

import ConfirmModal from 'projectSections/sections/manage3/common/ConfirmModal'
import { clearUnsavedData } from 'projectSections/utils/unsavedDataStore'
import { FC, useCallback } from 'react'
import { useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import useRerender from '../../../hooks/useRerender'

type DialogPropsType = {
  isOpen: boolean
  setOpen: (boolean) => void
  parentClose: () => void
}

const ConfirmDialog: FC<DialogPropsType> = ({ isOpen, setOpen, parentClose }) => {
  const form = useForm()
  const translate = useTranslate()
  const rerender = useRerender()
  const handleConfirm = useCallback(() => {
    clearUnsavedData()
    rerender(form)
    form.setConfig('keepDirtyOnReinitialize', false)
    form.restart()
    form.setConfig('keepDirtyOnReinitialize', true)
    form.mutators.resetFormDirtyFields()
    setOpen(false)
    parentClose()
  }, [])
  return (
    <ConfirmModal
      open={isOpen}
      setOpen={setOpen}
      handleSubmit={handleConfirm}
      title={translate('Discard Changes')}
      mainText={translate('Are you sure you want to discard changes?')}
      subText={translate('You will lose all your unapplied changes. Do you wish to continue?')}
      submitBtnLabel={translate('Yes, discard')}
      submitBtnProps={{ variant: 'contained', color: 'error' }}
    />
  )
}

const DiscardDialog = (props: { parentClose: () => void; isOpen: boolean; setOpen: (boolean) => void }) => {
  return (
    <>
      <ConfirmDialog isOpen={props.isOpen} setOpen={props.setOpen} parentClose={props.parentClose} />
    </>
  )
}

export default DiscardDialog

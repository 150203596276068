import { DialogTitle, Tooltip } from '@material-ui/core'
import { ClearOutlined } from '@material-ui/icons'
import { orgSelectors } from 'ducks/orgs'
import { IconButton } from 'opensolar-ui'
import { useLocale, useTranslate } from 'ra-core'
import React from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { StudioSystemType } from 'types/global'
import { formatCurrencyWithSymbol, systemNameOrDefault } from 'util/misc'
import { useGetMonthlyBillSavings } from '../hooks'
import SystemSavingsTooltip from '../systemRow/SystemSavingsTooltip'

type PropTypes = {
  onClose: () => void
  system: StudioSystemType
}

const useStyles = makeOpenSolarStyles((theme) => ({
  titleWrapper: {
    paddingBottom: '5px',
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  titleTextWrapper: {
    flex: 1,
  },
  systemInfoWrapper: {
    flex: 2,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  closeButtonWrapper: {
    position: 'absolute',
    top: '0px',
    right: '10px',
  },
  systemName: {
    fontSize: '12px',
    fontWeight: 400,
    marginRight: '20px',
  },
  savings: {
    fontSize: '12px',
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
  },
  savingsText: {
    marginRight: '10px',
  },
}))

const PaymentOptionDialogTitle: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const currencySymbol = useSelector(orgSelectors.getCurrencySymbolFromOrg)
  const locale = useLocale()
  const billSavings = useGetMonthlyBillSavings(props.system)

  return (
    <DialogTitle className={classes.titleWrapper}>
      <div className={classes.titleRow}>
        <div className={classes.titleTextWrapper}>{translate('Add Payment Options')}</div>
        <div className={classes.systemInfoWrapper}>
          <div className={classes.systemName}>{systemNameOrDefault(props.system.userData)}</div>
          <div className={classes.savings}>
            <div className={classes.savingsText}>
              {translate('%{savings}/month Estimated Utility Savings', {
                savings: formatCurrencyWithSymbol(billSavings, currencySymbol, locale, 0),
              })}
            </div>
            <SystemSavingsTooltip />
          </div>
        </div>
        <div className={classes.closeButtonWrapper}>
          <IconButton
            id="GenerateDialogClose"
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0)',
            }}
            onClick={props.onClose}
          >
            <Tooltip title={translate('Close Dialog')} enterDelay={300}>
              <ClearOutlined style={{ color: '#000000' }} />
            </Tooltip>
          </IconButton>
        </div>
      </div>
    </DialogTitle>
  )
}
export default PaymentOptionDialogTitle

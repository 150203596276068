export const validateRoleCreate = (values, _, translate) => {
  const errors = {}
  if (!values.email) {
    errors.email = [translate('Invitation Email is required!')]
  } else if (values.email) {
    var emailRegex = /^[-a-z0-9A-Z~!$%^&*_=+}{'"?]+(\.[-a-z0-9A-Z~!$%^&*_=+}{'"?]+)*@([a-z0-9A-Z_][-a-z0-9A-Z_]*(\.[-a-z0-9A-Z_]+)*\.((?!web)[a-zA-Z]{1,})|(\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/

    if (!emailRegex.test(values.email)) {
      errors.email = translate('Invalid email address.')
    }
  }
  return errors
}

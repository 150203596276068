import { Tooltip } from '@material-ui/core'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutlineOutlined'
import { IconButton } from 'opensolar-ui'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

import React from 'react'
import { useTranslate } from 'react-admin'
import { INTEGRATION_TO_DISPLAY_NAME } from 'resources/integrations/constants'
import { parseJsonSafe } from 'util/misc'

const useStyles = makeOpenSolarStyles((theme) => ({
  root: {
    cursor: 'default',
  },
  icon: {
    color: theme.alertIcon_success,
  },
}))

interface PropType {
  configuration_json: string
}

const GreenCheckIcon: React.FunctionComponent<PropType> = ({ configuration_json }) => {
  const translate = useTranslate()

  const classes = useStyles()
  const parsedConfigJson = parseJsonSafe(configuration_json)
  const financialIntegration = parsedConfigJson?.integration

  if (financialIntegration) {
    return (
      <Tooltip
        title={translate('This payment option was created automatically by the %{integrationName} integration.', {
          integrationName: INTEGRATION_TO_DISPLAY_NAME[financialIntegration] ?? financialIntegration,
        })}
      >
        <IconButton size="small" className={classes.root}>
          <CheckCircleOutline className={classes.icon} />
        </IconButton>
      </Tooltip>
    )
  }
  return <></>
}

export default GreenCheckIcon

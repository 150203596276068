import React from 'react'
import { SimpleForm } from 'react-admin'
import EditEventDialogFooter from './EditEventDialogFooter'
import Grid from '@material-ui/core/Grid'
import CompletedEventForm from './CompletedEventForm'
import IncompleteEventForm from './IncompleteEventForm'
import LoadingDots from 'layout/widgets/LoadingDots'

import { StageActionEventType } from 'types/projects'

const EditEventDialogFormContent: React.FunctionComponent = (props: any) => {
  const redirect = props.redirect

  const renderLoading = () => (
    <div style={{ width: '100%', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <LoadingDots />
    </div>
  )

  const renderRecord = () => (
    <Grid container spacing={3}>
      {props?.record?.is_complete ? <CompletedEventForm {...props} /> : <IncompleteEventForm {...props} />}
    </Grid>
  )
  return (
    <SimpleForm
      {...props}
      redirect={redirect}
      toolbar={
        <EditEventDialogFooter
          {...props}
          onDismiss={props.onDismiss}
          onSuccess={props.onSuccess}
          onFailure={props.onFailure}
          onDelete={props.onDelete}
        />
      }
      hideCancelButton={!!props.hideCancelButton}
      validate={(values: StageActionEventType) => {}}
      initialValues={props.record}
    >
      <div style={{ width: '100%' }}>{props.record === undefined ? renderLoading() : renderRecord()}</div>
    </SimpleForm>
  )
}

export default EditEventDialogFormContent

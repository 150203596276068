import { HelpLink } from 'elements/link/HelpLink'
import React from 'react'
import useTranslateParse from '../../../util/useTranslateParse'
import { ActiveModulesCreate, ActiveModulesEdit, ActiveModulesList } from './activeModules'

const ActiveModulesName = ({ translate, style }) => {
  return <span style={style}>{translate('Edit Module')}</span>
}

const ModuleSubtitle = () => {
  const translateParse = useTranslateParse()
  return (
    <span>
      {translateParse(
        "Search our extensive database, and add modules to your 'active' list so your team can use them to design. Non-admins do not have the ability to search the database and can only use equipment on this list. If any equipment is missing from the database, email <mailtoLink>support@opensolar.com<mailtoLink> and we will add it, or you can create your own.",
        { mailtoLink: (label) => <a href="mailto:support@opensolar.com">{label}</a> }
      )}{' '}
      <HelpLink href="https://support.opensolar.com/hc/en-us/articles/4407235655065" />
    </span>
  )
}

export default {
  list: ActiveModulesList,
  edit: ActiveModulesEdit,
  create: ActiveModulesCreate,
  options: {
    create: {
      title: 'Module Activation',
      subtitle: null,
      breadCrumb: 'Module Activation',
    },
    list: {
      title: 'My Active Modules List',
      subtitle: <ModuleSubtitle />,
      breadCrumb: 'Modules',
    },
    edit: {
      title: <ActiveModulesName />,
      subtitle: null,
      breadCrumb: 'Edit Module',
    },
  },
}

import { BomGenerator, BomGeneratorClass, MountingCalcInput, MountingCalcResult, PanelBlock } from '../types'

// This class exists just so other BomGenerators can extend it and inherit the constructor

export abstract class BomGeneratorAbstract implements BomGenerator {
  input: MountingCalcInput
  block: PanelBlock
  blockIndex: number

  constructor(input: MountingCalcInput, block: PanelBlock, blockIndex: number) {
    this.input = input
    this.block = block
    this.blockIndex = blockIndex
  }

  abstract generateBom(result: MountingCalcResult): Promise<MountingCalcResult>

  protected async generateBomFrom(
    bomGeneratorClass: BomGeneratorClass,
    result: MountingCalcResult
  ): Promise<MountingCalcResult> {
    return await new bomGeneratorClass(this.input, this.block, this.blockIndex).generateBom(result)
  }
}

import { FormControlLabel, FormHelperText } from '@material-ui/core'
import { Checkbox } from 'opensolar-ui'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { FieldTitle } from 'react-admin'

const styles = {
  block: {
    // margin: '1rem 0',
    maxWidth: 250,
  },
  label: {
    color: 'rgba(0, 0, 0, 0.298039)',
  },
}

class CheckboxInput extends Component {
  handleToggle = (event, value) => {
    this.props.input.onChange(value)
  }

  render() {
    const { input, isRequired, label, source, elStyle, resource, options, disabled, helperText, onChange } = this.props
    return (
      <div style={elStyle ? Object.assign({}, styles.block, elStyle) : styles.block}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={!!input.value}
              onChange={(event, value) => {
                if (onChange) {
                  onChange(value)
                }
                this.handleToggle(event, value)
              }}
              // labelStyle={labelStyle || styles.label}
              disabled={disabled}
              {...options}
            />
          }
          label={
            label === false ? null : (
              <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
            )
          }
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </div>
    )
  }
}

CheckboxInput.propTypes = {
  addField: PropTypes.bool,
  elStyle: PropTypes.object,
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  resource: PropTypes.string,
  source: PropTypes.string,
  options: PropTypes.object,
  disabled: PropTypes.bool,
}

CheckboxInput.defaultProps = {
  addField: true,
  options: {},
  disabled: false,
}

export default CheckboxInput

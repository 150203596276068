import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutlineOutlined'
import Button from 'elements/button/Button'
import { Button as CoreButton } from 'opensolar-ui'
import React, { useCallback, useState } from 'react'
import { CRUD_DELETE, useDelete, useNotify, useTranslate } from 'react-admin'
import { DeleteButtonPropTypes } from './types'

const ProUXDeleteButton: React.FunctionComponent<DeleteButtonPropTypes> = (props) => {
  const [deleting, setDeleting] = useState<boolean>(false)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const translate = useTranslate()
  const notify = useNotify()

  const [deleteOne, { loading }] = useDelete(props.resource, props.record.id, props.record, {
    action: CRUD_DELETE,
    onSuccess: (response) => {
      notify('ra.notification.deleted', 'info', { smart_count: 1 })
      if (props.onSuccess) props.onSuccess(response)
      setDeleting(false)
    },
    onFailure: (error: any) => {
      notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning')
      if (props.onFailure) props.onFailure()
      setDeleting(false)
    },
    undoable: false,
  })

  const renderConfirmDialog = () => {
    return (
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogTitle>{props.confirmTitle ? props.confirmTitle : translate('Delete this item?')}</DialogTitle>
        <DialogContent>
          <div>
            <p style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
              {props.confirmBody ? props.confirmBody : translate('Are you sure you want to delete this item?')}
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%' }}>
            <div style={{ marginLeft: '25px' }}>
              <Button
                label="Cancel"
                onClick={() => setShowDialog(false)}
                variant="contained"
                color="default"
                startIcon={<ErrorOutlineIcon />}
                disabled={deleting}
              />
            </div>
            <div style={{ marginLeft: '25px' }}>
              <CoreButton onClick={handleDelete} variant="contained" color="default" startIcon={<CheckCircleIcon />}>
                <span>{translate('Delete')}</span>
              </CoreButton>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    )
  }

  const onClick = (e: any) => {
    if (props.confirmMode === 'dialog') {
      setShowDialog(true)
    } else {
      handleDelete(e)
    }
  }

  const handleDelete = useCallback(
    (e) => {
      setDeleting(true)
      deleteOne()
    },
    [deleteOne]
  )
  return (
    <>
      <Button
        className={props.className}
        color={props.color}
        variant={props.variant}
        disabled={props.invalid || props.disabled}
        label={props.label}
        onClick={onClick}
        loading={deleting}
        startIcon={props?.startIcon || <DeleteOutlinedIcon />}
        style={props.style}
        title={props.title}
      />
      {props.confirmMode === 'dialog' && showDialog && renderConfirmDialog()}
    </>
  )
}
export default ProUXDeleteButton

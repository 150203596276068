import React from 'react'
import { Dialog, DialogContent } from '@material-ui/core'
import { formatVideoUrl } from '../../util/misc'

export const VideoDialog = (props) => (
  <div className="ContainerLetter" id="ContainerLetter">
    <Dialog
      open={props.isOpen}
      onBackdropClick={() => props.closeDialog()}
      className="video-dialog"
      maxWidth="md"
      fullWidth={true}
    >
      <DialogContent>
        {props.dialogData && props.dialogData.videoUrl && (
          <div
            dangerouslySetInnerHTML={{
              __html:
                `<iframe
            width="560"
            height="315"
            src="https://www.youtube-nocookie.com/embed/` +
                formatVideoUrl(props.dialogData.videoUrl) +
                `?rel=0"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen="true"
            style="width: 100%;"
          />`,
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  </div>
)
export default VideoDialog

// @ts-nocheck
import { Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import CopyLink from 'layout/widgets/CopyLink'
import LoadingDots from 'layout/widgets/LoadingDots'
import React, { useEffect, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import restClient from 'restClient'
import { ProjectType } from 'types/projects'
import { SystemDesignSunlightQuickCreateType } from '../sunlight/types'

let restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  onClose: () => void
  open: boolean
  project: ProjectType
  designs?: SystemDesignSunlightQuickCreateType[]
}

const MosaicSendAgreementDialog: React.FunctionComponent<PropTypes> = (props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [method, setMethod] = useState<'Email' | 'Sms' | 'Link' | undefined>(undefined)
  const [customerEmail, setCustomerEmail] = useState<string | undefined>(undefined)
  const [customerPhone, setCustomerPhone] = useState<string | undefined>(undefined)
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const [checkingEmail, setCheckingEmail] = useState<boolean>(false)
  const [url, setUrl] = useState<string | undefined>(undefined)

  const translate = useTranslate()
  const notify = useNotify()

  useEffect(() => {
    if (props.project && props.project.contacts_data && props.project?.contacts_data?.length > 0) {
      let email = props?.project?.contacts_data[0]?.email
      let phone = props?.project?.contacts_data[0]?.phone?.replace(/\D/g, '')
      if (email) setMethod('Email')
      else if (phone) setMethod('Sms')
      if (phone && phone.length !== 10) {
        setErrorMsg("Customer's phone number must be exactly 10 digits")
        setDisabled(true)
      }
      setCustomerEmail(email)
      setCustomerPhone(phone)
    }
  }, [props.project?.contacts_data])

  const submit = () => {
    if (!props.project.org_id || !props.project.id) {
      setErrorMsg('Unable to share credit app')
      return
    }
    let systemUuid
    let paymentOptionId
    props.project.available_customer_actions?.forEach((act) => {
      act?.actions_available?.forEach((avail) => {
        if (avail.document_type === 'mosaic_loan_agreement') {
          systemUuid = avail.system_uuid
          paymentOptionId = avail.payment_option_id
        }
      })
    })
    setLoading(true)
    const location = window.location.hash?.includes('/manage') ? 'Manage Page' : 'Info Page'
    logAmplitudeEvent('integrated_finance_loan_agreement_started', {
      integration: 'mosaic',
      method: method,
      page: location,
      project_id: props.project.id,
    })
    restClientInstance('CUSTOM_GET', 'custom', {
      url:
        'orgs/' +
        props.project.org_id +
        '/projects/' +
        props.project.id +
        '/systems/' +
        systemUuid +
        '/' +
        paymentOptionId +
        '/mosaic/loan_agreement/?method=' +
        method,
    })
      .then((res: any) => {
        if (res.data.success) {
          if (method === 'Link') {
            setUrl(res.data.url)
          } else {
            notify('Loan Agreement Sent', 'success')
            props.onClose()
          }
          logAmplitudeEvent('integrated_finance_loan_agreement_sent', {
            integration: 'mosaic',
            method: method,
            page: location,
            project_id: props.project.id,
          })
        } else {
          if (res.data.message) setErrorMsg(res.data.message)
          else setErrorMsg('We were unable to send the loan documents')

          logAmplitudeEvent('integrated_finance_loan_agreement_error', {
            integration: 'mosaic',
            method: method,
            page: location,
            project_id: props.project.id,
          })
        }
      })
      .catch((err: any) => {
        console.log('error', err)
        if (err?.body?.message) {
          setErrorMsg(err.body.message)
        } else {
          setErrorMsg('Something went wrong and we were unable to send the loan documents')
        }
      })
      .finally(() => setLoading(false))
  }

  const handleSendMethodChange = (e: any) => {
    setMethod(e.target.value)
  }

  const renderForm = () => {
    return (
      <Grid container spacing={1}>
        {!url && (
          <Grid item xs={12}>
            {customerEmail || customerPhone ? (
              <>
                <h2>How would you like to send the agreement?</h2>
                <RadioGroup value={method} onChange={handleSendMethodChange}>
                  <FormControlLabel
                    label={`Send email to ${customerEmail}`}
                    value="Email"
                    control={<Radio disabled={!customerEmail} />}
                  />
                  <FormControlLabel
                    label={`Send SMS to ${customerPhone}`}
                    value="Sms"
                    control={<Radio disabled={!customerPhone} />}
                  />
                  <FormControlLabel label={`I just want a link to the documents`} value="Link" control={<Radio />} />
                </RadioGroup>
              </>
            ) : (
              <div style={{ color: 'red', textAlign: 'center' }}>
                Customer must have a valid email address or phone number
              </div>
            )}
          </Grid>
        )}
        {errorMsg && (
          <Grid item xs={12}>
            <p style={{ color: 'red', textAlign: 'red' }}>{errorMsg}</p>
          </Grid>
        )}
        {url && (
          <Grid item xs={12}>
            <p style={{ marginTop: '20px' }}>
              {translate('Your customer can use the link below to sign their loan agreement')}
            </p>
            <div style={{ width: '100%', margin: '20px 0px' }}>
              <CopyLink url={url} />
            </div>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
        >
          <div style={{ margin: '10px 20px' }}>
            <ProUXButton label={url ? 'Dismiss' : 'Cancel'} type="secondary" onClick={props.onClose} />
          </div>
          {!url && (
            <div style={{ margin: '10px 20px' }}>
              <ProUXButton
                label={method === 'Link' ? 'Get the Link' : 'Send Agreement'}
                type="primary"
                onClick={submit}
                loading={loading}
                disabled={loading || !method}
              />
            </div>
          )}
        </Grid>
      </Grid>
    )
  }

  const renderLoadingDots = () => {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px', width: '100%' }}
      >
        <LoadingDots />
      </div>
    )
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Send a Mosaic Loan Agreement</DialogTitle>
      <DialogContent>{checkingEmail ? renderLoadingDots() : renderForm()}</DialogContent>
    </Dialog>
  )
}
export default MosaicSendAgreementDialog

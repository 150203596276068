var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useTranslate } from '../i18n';
var ValidationError = function (_a) {
    var error = _a.error;
    var translate = useTranslate();
    if (error.message) {
        var _b = error, message = _b.message, args = _b.args;
        return _jsx(_Fragment, { children: translate(message, __assign({ _: message }, args)) });
    }
    return _jsx(_Fragment, { children: translate(error, { _: error }) });
};
export default ValidationError;

import { styled, Typography } from 'opensolar-ui'

type ContainerVariant = 'info' | 'error' | 'warning' | 'success'

interface Props {
  listItems: string[]
  title: string
  variant?: ContainerVariant
  isCheckBullet?: boolean
}

export const ListCalloutBox: React.FC<Props> = ({
  title,
  variant = 'info',
  listItems,
  isCheckBullet = false,
  children,
}) => {
  return (
    <Wrapper variant={variant} isCheckBullet={isCheckBullet}>
      <div>
        <Typography textVariant="subtitle1">{title}</Typography>
      </div>
      <div>
        {listItems && (
          <ul>
            {listItems.map((b, index) => (
              <li key={index}>{b}</li>
            ))}
          </ul>
        )}
        {children}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'isCheckBullet',
})<{ variant: ContainerVariant; isCheckBullet: boolean }>(({ variant, isCheckBullet, theme }) => ({
  border: '1px solid' + theme.palette[variant].dark,
  backgroundColor: theme.palette?.[variant].light,
  borderRadius: 8,
  '& > div:nth-child(1)': {
    borderBottom: '.5px solid' + theme.palette[variant].dark,
    padding: 14,
  },
  '& > div:nth-child(2)': {
    padding: '0px 8px',
  },

  '& ul': {
    listStyleType: isCheckBullet ? "'✓ '" : 'initial',
  },
  '& li::marker': {
    color: theme.palette.success.contrastText,
  },
}))

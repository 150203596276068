import { makeStyles, Paper } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { paymentOptionSelectionSelectors } from 'ducks/paymentOptionSelection'
import { permissionsSelectors } from 'ducks/permissions'
import { Button } from 'opensolar-ui'
import { useGetSoldRecords } from 'projectSections/sections/payments/cashFlowTransactions/utils'
import { useIsProjectUsingCashFlow } from 'projectSections/sections/payments/hooks'
import { memo, useEffect, useMemo, useState } from 'react'
import { NotFound, useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { FinanceQRCodeWarning } from 'resources/projects/elements/FinanceQRCodeWarning'
import restClient from 'restClient'
import { getIntegrationsStringFromActions } from 'util/misc'
import LoadingSpinner from '../LoadingSpinner'
import { DownloadButton } from './DownloadButton'
import { PreviewNotAvailable } from './PreviewNotAvailable'
import PvProSellPreview from './PvProSellPreview'
const restClientInstance = restClient(window.API_ROOT + '/api')

const useStyles = makeStyles(() => {
  return {
    fincoWarningPaper: ({ isMobileDevice }) => ({
      position: 'fixed',
      left: '50%',
      transform: 'translateX(-50%)',
      width: isMobileDevice ? '90%' : '50%',
      bottom: isMobileDevice ? 60 : 10,
      maxWidth: 750,
    }),
    USHelpPaper: {
      position: 'fixed',
      fontSize: 12,
      right: 21,
      bottom: 105,
      width: 158,
      padding: 4,
      backgroundColor: 'rgba(255,255,255,0.7)',
      borderRadius: 5,
    },
  }
})

const ViewerNativeApp = ({ url, id, fileName }) => {
  const translate = useTranslate()
  return (
    <div id={id}>
      <div
        style={{
          position: 'fixed',
          transform: 'translate(-50%, -50%)',
          top: '50%',
          left: '50%',
          zIndex: 1001,
          padding: 50,
          background: '#f7f7f7',
          border: '2px dashed',
        }}
        onClick={() => window.open(url)}
      >
        <Button variant="contained" color="primary">
          <span>{translate('Click to Preview.')}</span>
        </Button>
      </div>
    </div>
  )
}

export const Preview = memo(({ projectId }) => {
  const [proposalPrivateFileData, setProposalPrivateFileData] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const { allowView } = useSelector(permissionsSelectors.getProjectPermissionByKey('proposal'))
  const formState = useFormState()
  const projectFields = formState.values
  const isMobileDevice = useMemo(() => window.Utils?.isTouchDevice(), [])
  const classes = useStyles({ isMobileDevice })
  const pvProSellTemplateId = projectFields?.proposal_template_settings?.pvprosell_template_id
  const orgId = useSelector(authSelectors.getOrgId)
  const countryIso2 = useSelector(orgSelectors.getOrgIso2)
  const pdfGenPreference = window.pdfGenerationPreference

  const [soldSystem, soldPaymentOption] = useGetSoldRecords(projectFields)
  const refreshSystemsTrigger = useSelector(paymentOptionSelectionSelectors.getSystemRefreshTrigger)
  const isProjectUsingCashflow = useIsProjectUsingCashFlow(soldSystem, soldPaymentOption, refreshSystemsTrigger)

  useEffect(() => {
    let url = 'orgs/' + orgId + '/projects/' + projectId + '/generate_document_pdf/proposal/?temporary=1'

    if (
      pdfGenPreference?.system_uuid &&
      pdfGenPreference?.payment_option_id &&
      pdfGenPreference?.project_id === projectId
    ) {
      url += `&system_uuid=${pdfGenPreference.system_uuid}&payment_option_id=${pdfGenPreference.payment_option_id}`
    }

    restClientInstance('CUSTOM_GET', 'custom', {
      url: url,
    })
      .then((response) => {
        const proposalPrivateFileData = response.data
        setProposalPrivateFileData(proposalPrivateFileData)
      })
      .catch((e) => {
        setErrorMessage(e.message)
      })
  }, [])

  const logDownloadEvent = () => {
    logAmplitudeEvent('proposal_pdf_generated', {
      project_id: `${projectId}`,
      integrated_payment_options: getIntegrationsStringFromActions(projectFields?.available_customer_actions),
      page: 'PDF Proposal',
      has_cashflow: isProjectUsingCashflow,
    })
  }
  if (pvProSellTemplateId) {
    return <PvProSellPreview pvProSellTemplateId={pvProSellTemplateId} projectId={projectId} />
  }

  if (errorMessage) {
    return <PreviewNotAvailable message={errorMessage} />
  }

  if (!allowView) {
    return <NotFound />
  }
  return (
    <div>
      {proposalPrivateFileData ? (
        <>
          {isMobileDevice ? (
            <ViewerNativeApp
              url={proposalPrivateFileData.url}
              fileName={proposalPrivateFileData.title}
              id={proposalPrivateFileData.id}
            />
          ) : (
            <iframe
              src={proposalPrivateFileData.url + '#toolbar=0'}
              frameBorder="0"
              width="100%"
              style={{ height: 'calc(100vh - 103px)' }}
            ></iframe>
          )}
          {proposalPrivateFileData.url && (
            <>
              {countryIso2 === 'US' && (
                <Paper variant="outlined" className={classes.USHelpPaper}>
                  For help configuring your PDF proposal, contact{' '}
                  <a href="mailto:support@opensolar.com">support@opensolar.com</a>
                </Paper>
              )}
              <DownloadButton
                downloadUrl={proposalPrivateFileData.url}
                fileName={proposalPrivateFileData.title}
                orgId={orgId}
                privateFileId={proposalPrivateFileData.id}
                setErrorMessage={setErrorMessage}
                onDownloadFinish={logDownloadEvent}
              />
            </>
          )}
        </>
      ) : (
        <LoadingSpinner />
      )}
      <FinanceQRCodeWarning
        projectFormState={formState}
        show={!!proposalPrivateFileData}
        classes={{ wrapper: classes.fincoWarningPaper }}
        location="pdf_proposal_page"
      />
    </div>
  )
})

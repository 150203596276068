import { Paper, Typography } from '@material-ui/core'
import { ComponentVersions_3_0 } from 'constants/uxVersions'
import { orgSelectors } from 'ducks/orgs'
import { ComponentVersionsInherit, Grid, styled } from 'opensolar-ui'
import useGetDistributorConnectionStatusAndConfig from 'projectSections/sections/manage3/hardware/hooks/useGetDistributorConnectionStatusAndConfig'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import BorderProgress from './BorderProgress'
import DistributorConnectionCard from './DistributorConnectionCard'

const Wrapper = styled('div')(({ theme }) => ({
  padding: 20,
  maxWidth: 1500,
  backgroundColor: theme.palette.common.white,
}))

const ColorPaper = styled(Paper)({
  backgroundColor: '#D6E0F7',
  padding: '16px 24px 16px 24px',
})

const ColorTypography = styled(Typography)({
  color: '#113B98',
})

const DistributorConnection = () => {
  const countryIso2 = useSelector(orgSelectors.getOrgIso2)
  const enabledSuppliers = useSelector(orgSelectors.getEnabledHardwareSuppliers)
  const { enabledAndConnectedDistributors, distributorConfigs } = useGetDistributorConnectionStatusAndConfig()
  const progressValue = useMemo(() => {
    if (enabledAndConnectedDistributors) {
      return (enabledAndConnectedDistributors.length / enabledSuppliers.length) * 100
    }
    return 0
  }, [enabledAndConnectedDistributors, enabledSuppliers])

  if (countryIso2 === 'AU') return null

  return (
    <ComponentVersionsInherit versions={ComponentVersions_3_0}>
      <Wrapper>
        <Typography variant="h6" gutterBottom>
          Distributor Connection
        </Typography>
        <Typography variant="subtitle1" gutterBottom color="textSecondary">
          Gain access to discounted trade prices and stock availability with our distributors for your projects.
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ColorPaper variant="outlined">
              <Grid container item xs={12} alignItems="center">
                <Grid item xs={5}>
                  <ColorTypography variant="body2">
                    Connect your account to all distributors to view best hardware pricing and item availability.
                  </ColorTypography>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={6}>
                  <Grid container item justify="space-between" alignItems="center">
                    <BorderProgress value={progressValue} />
                    <ColorTypography variant="caption">{`${enabledAndConnectedDistributors?.length || 0} OF ${
                      enabledSuppliers.length
                    } CONNECTED`}</ColorTypography>
                  </Grid>
                </Grid>
              </Grid>
            </ColorPaper>
          </Grid>

          <Grid container item spacing={5}>
            {distributorConfigs
              ?.filter((config) => config.isVisible)
              .map((config) => (
                <Grid item xs={4}>
                  <DistributorConnectionCard config={config} />
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Wrapper>
    </ComponentVersionsInherit>
  )
}

export default DistributorConnection

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import { FieldTitle } from 'ra-core';
var FilterButtonMenuItem = forwardRef(function (_a, ref) {
    var filter = _a.filter, onShow = _a.onShow, resource = _a.resource;
    var handleShow = useCallback(function () {
        onShow({ source: filter.source, defaultValue: filter.defaultValue });
    }, [filter.defaultValue, filter.source, onShow]);
    return (_jsx(MenuItem, __assign({ className: "new-filter-item", "data-key": filter.source, "data-default-value": filter.defaultValue, onClick: handleShow, ref: ref }, { children: _jsx(FieldTitle, { label: filter.label, source: filter.source, resource: resource }) }), filter.source));
});
FilterButtonMenuItem.propTypes = {
    filter: PropTypes.object.isRequired,
    onShow: PropTypes.func.isRequired,
    resource: PropTypes.string.isRequired,
};
export default FilterButtonMenuItem;

import { InventoryFiltersType } from 'pages/inventory/filter/type'
import { RootState } from 'types/state'

const SET_FILTER_VALUE = 'SET_FILTER_VALUE'
const SET_ROWS_PER_PAGE = 'SET_ROWS_PER_PAGE'
const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'

export const setFilterValue = (key, value) => ({
  type: SET_FILTER_VALUE,
  payload: { key, value },
})

export const setRowsPerPage = (rows) => ({
  type: SET_ROWS_PER_PAGE,
  payload: rows,
})

export const setCurrentPage = (page) => ({
  type: SET_CURRENT_PAGE,
  payload: page,
})

const initialState = {
  filterValues: {
    time_period: 84,
    exclude_removed: true,
  },
  currentPage: 1,
  rowsPerPage: 10,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER_VALUE:
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [action.payload.key]: action.payload.value,
        },
      }
    case SET_CURRENT_PAGE:
      return { ...state, currentPage: action.payload }
    case SET_ROWS_PER_PAGE:
      return { ...state, rowsPerPage: action.payload }
    default:
      return state
  }
}

export type InventoryFilterState = {
  filterValues: InventoryFiltersType
  currentPage: number
  rowsPerPage: number
}

export const inventoryFilterSelectors = {
  getInventoryFilter(state: RootState): InventoryFiltersType {
    return state.inventoryFilter.filterValues
  },
  getCurrentPage(state: RootState): number {
    return state.inventoryFilter.currentPage
  },
  getRowsPerPage(state: RootState): number {
    return state.inventoryFilter.rowsPerPage
  },
}

import { authSelectors } from 'ducks/auth'
import { useMemo } from 'react'
import { useMutation } from 'react-apollo'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { APPLY_DISCOUNT_CODE_TO_CHECKOUT, CREATE_CHECKOUT } from 'storeFrontQuery'
import SolarOutletService from './SolarOutletService'

const useSolarOutletService = (): SolarOutletService | undefined => {
  const orgId = useSelector(authSelectors.getOrgId)
  const [createCheckout] = useMutation(CREATE_CHECKOUT)
  const [applyDiscountCodeToCheckout] = useMutation(APPLY_DISCOUNT_CODE_TO_CHECKOUT)
  const service = useMemo(() => {
    if (orgId != null) {
      const restClientInstance = restClient(window.API_ROOT + '/api')
      // TODO: refactor this
      const SHOPIFY_STORE_URL = window.OUTLET_STOREFRONT_DOMAIN
      return new SolarOutletService(
        SHOPIFY_STORE_URL,
        createCheckout,
        applyDiscountCodeToCheckout,
        restClientInstance,
        orgId
      )
    }
  }, [orgId])
  return service
}

export default useSolarOutletService

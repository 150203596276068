import React from 'react'

const ViewsIcon = (props) => (
  <svg {...props} viewBox="0 0 100 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M100 32.0651C89.9354 45.2239 78.7141 56.5359 62.6725 61.8841C51.5282 65.6163 40.731 64.385 30.3194 59.3062C18.0568 53.3039 8.57071 43.9927 0.472803 33.1809C-0.182742 32.2959 -0.14418 31.7188 0.511365 30.8338C8.45503 20.2144 17.7098 11.0956 29.7024 5.05481C43.0447 -1.67851 56.5026 -1.67851 69.8449 5.01634C82.2231 11.2495 91.7093 20.83 100 32.0651ZM49.7544 50.2643C59.8189 50.3028 67.994 42.1458 67.994 32.0266C68.0325 21.9458 59.896 13.7504 49.8315 13.6735C39.8441 13.635 31.5919 21.8689 31.5534 31.9112C31.5534 42.0304 39.6898 50.2258 49.7544 50.2643Z"
      fill="url(#paint0_linear_6249:253532)"
    />
    <path
      d="M40.6925 31.9881C40.6925 26.9477 44.7029 22.9462 49.7158 22.9077C54.806 22.8693 58.8935 26.9477 58.8935 32.0651C58.8935 37.1439 54.7674 41.1839 49.6387 41.1454C44.6257 41.03 40.6925 37.0285 40.6925 31.9881Z"
      fill="url(#paint1_linear_6249:253532)"
    />
    <defs>
      <linearGradient id="paint0_linear_6249:253532" x1="50" y1="0" x2="50" y2="64.0523" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
      <linearGradient id="paint1_linear_6249:253532" x1="50" y1="0" x2="50" y2="64.0523" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
    </defs>
  </svg>
)

export default ViewsIcon

import { adsSelectors } from 'ducks/ads'
import BannerAd, { filterAvailableAds } from 'elements/BannerAd'
import useAdFilters from 'elements/ads/useAdFilters'
import usePromoConfigs from 'elements/ads/usePromoConfigs'
import React from 'react'
import { useSelector } from 'react-redux'
import { useFeatureFlag } from 'util/split'

// TODO: further refactor BannerAd
// Create single source of truth that a ad banner would show
const useAdBanner = ({
  formats,
  placement,
  requiredWindowSize,
}: {
  formats: string[]
  placement: string
  requiredWindowSize?: { width: number; height: number }
}) => {
  const promoConfigs = usePromoConfigs()
  const adFilters = useAdFilters({ formats, placement, requireMeetsOne: false })
  const availableAds = filterAvailableAds(promoConfigs, adFilters)
  const enableAdsFeatureFlag = useFeatureFlag('ads', 'on')
  const enableAdsForOrg = useSelector(adsSelectors.getEnableAdsForOrg)
  const enableAds = enableAdsFeatureFlag && enableAdsForOrg
  if (enableAds && availableAds && availableAds.length > 0 && placement) {
    return <BannerAd formats={formats} placement={placement} requiredWindowSize={requiredWindowSize} />
  }
  return null
}

export default useAdBanner

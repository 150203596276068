import { makeStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import CheckIcon from '@material-ui/icons/CheckOutlined'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Alert from 'elements/Alert'
import { updateSlot } from 'projectSections/sections/design/systems/Slots'
import { useTranslate } from 'ra-core'
import React, { FC, useState } from 'react'
import { MpptType, StringType, StudioInverterType } from 'types/global'
import { OtherComponentType } from 'types/otherComponent'
import { SlotType } from 'types/slots'
import { Theme } from 'types/themes'
import { round_number_to_specific_decimal_places } from 'util/misc'

const useStyles = makeStyles((theme: Theme) => ({
  validationResults: {
    display: 'grid',
    gridTemplateColumns: '1fr 0fr 1fr 0fr',
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr 0fr',
    },
    textAlign: 'right',
    lineHeight: 'normal',
    fontSize: 12,
    columnGap: '20px',
    rowGap: '6px',
  },
  validationHeading: {
    marginRight: '-10px',
    minWidth: '110px',
    [theme.breakpoints.down('xs')]: {
      minWidth: '0px',
    },
  },
  validationValue: {
    fontSize: 12,
    whiteSpace: 'nowrap',
    width: '70px',
    [theme.breakpoints.down('xs')]: {
      minWidth: '0px',
    },
  },
  validationInput: {
    fontSize: 12,
    maxHeight: '18px',
    paddingLeft: '4px',
    paddingRight: '4px',
  },
}))

const CompatibilityIcon = ({ compatibility }: { compatibility: 'good' | 'ok' | 'bad' | null }) => {
  if (!compatibility) return <div style={{ width: 12, display: 'inline-block' }}></div>
  return React.createElement(compatibility === 'good' ? CheckIcon : InfoOutlinedIcon, {
    style: {
      marginLeft: 5,
      width: 12,
      height: 12,
      transform: 'scale(1.5)',
      color: compatibility === 'good' ? 'green' : compatibility === 'ok' ? 'orange' : 'red',
    },
  })
}

type resultsType = {
  compatibility: 'good' | 'ok' | 'bad' | null
  data: Record<any, any>
}

export function getSystemVoltageAndCurrent(strings: StringType[]) {
  let longestStringLength = 0
  strings.forEach((string) => {
    if (string.modules.length > longestStringLength) longestStringLength = string.modules.length
  })
  //@ts-ignore
  const voltage = window.editor.selectedSystem.userData.module.voc * longestStringLength
  //@ts-ignore
  const current = window.editor.selectedSystem.userData.module.isc * strings.length
  return { voltage, current }
}

// strings = window.editor.selectedSystem.userData.inverters.mppts[x].strings or an array with containing one string [strings[x]], depending if user has chosen to isolate the MPPT strings in parallel rather than each string separately
function getDCIsolatorCompatibility(isolator: OtherComponentType, strings: StringType[]): resultsType {
  if (!isolator) {
    throw new Error(
      window.translate('Cannot calculate DC isolator compatibility') + ': ' + window.translate('isolator not defined')
    )
  }
  const { voltage, current } = getSystemVoltageAndCurrent(strings)
  if (!isolator.voltage_to_current_rating) {
    return {
      compatibility: null,
      data: {
        current,
        voltage,
        error:
          window.translate('Cannot calculate isolator compatibility') +
          ': ' +
          window.translate('component voltage to current rating not defined'),
      },
    }
  }
  function getVoltageAndCurrentRating(voltageToCurrentRating, systemVoltage) {
    var voltageKey
    Object.keys(voltageToCurrentRating).forEach((voltage: string | number) => {
      // Find the lowest voltage in voltageToCurrentRating that's above our system voltage
      voltage = Number(voltage)
      if (!voltageKey || (voltage < voltageKey && systemVoltage < voltage)) {
        voltageKey = voltage
      }
    })
    return { voltage: voltageKey, current: voltageToCurrentRating[voltageKey] }
  }
  const rating = getVoltageAndCurrentRating(isolator.voltage_to_current_rating, voltage)
  const voltageRating = rating.voltage
  const currentRating = rating.current
  //@ts-ignore
  const voltageCompatibility = voltage < voltageRating ? 'good' : voltage * 1.25 < voltageRating ? 'ok' : 'bad'
  //@ts-ignore
  const currentCompatibility = current < currentRating ? 'good' : current * 1.25 < currentRating ? 'ok' : 'bad'
  const compatibility =
    voltageCompatibility === 'good' && currentCompatibility === 'good'
      ? 'good'
      : voltageCompatibility === 'bad' || currentCompatibility === 'bad'
      ? 'bad'
      : 'ok'
  return {
    compatibility,
    data: {
      current,
      currentRating,
      currentCompatibility,
      voltage,
      voltageRating,
      voltageCompatibility,
    },
  }
}

type DCIsolatorValidationPropsType = {
  isolator: OtherComponentType
  strings: StringType[]
}

export const DCIsolatorValidation: FC<DCIsolatorValidationPropsType> = ({ isolator, strings }) => {
  const validationResults = getDCIsolatorCompatibility(isolator, strings)
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <>
      <div className={classes.validationResults}>
        <div className={classes.validationHeading}>{translate('String Isc')}</div>
        <div className={classes.validationValue}>
          {round_number_to_specific_decimal_places(validationResults.data.current, 2)}A
        </div>
        <div className={classes.validationHeading}>{translate('Isolator current rating')}:</div>
        <div className={classes.validationValue}>
          {round_number_to_specific_decimal_places(validationResults.data.currentRating, 2)}A{' '}
          <CompatibilityIcon compatibility={validationResults.data.currentCompatibility} />
        </div>
        <div className={classes.validationHeading}>
          String V<sub>oc:</sub>
        </div>
        <div className={classes.validationValue}>
          {round_number_to_specific_decimal_places(validationResults.data.voltage, 2)}V
        </div>
        <div className={classes.validationHeading}>{translate('Isolator voltage rating')}:</div>
        <div className={classes.validationValue}>
          {round_number_to_specific_decimal_places(validationResults.data.voltageRating, 2)}V{' '}
          <CompatibilityIcon compatibility={validationResults.data.voltageCompatibility} />
        </div>
      </div>
      {validationResults.data.error && (
        <Alert severity="warning" styles={{ padding: '4px 4px 4px 2px' }}>
          {validationResults.data.error}
        </Alert>
      )}
    </>
  )
}

function getACIsolatorCompatibility(isolator: OtherComponentType, isolatorLocation: any): resultsType {
  const errors: string[] = []
  var currentCompatibility, phaseCompatibility
  const inverter = isolatorLocation.type === 'OsMppt' ? isolatorLocation.parent : isolatorLocation
  const inverterCurrent = inverter?.current_ac_max
  var current
  if (inverter.microinverter) {
    // Note we use 'isolatorLocation' below which can be the inverter or the branch. If we are isolating separate branches, we only combine the output currents of the microinverters within the branch. If we add an isolator on the level above that, we combine the branches.
    const microinverterQty = Math.ceil(isolatorLocation.moduleQuantity() / inverter.mppt_quantity)
    current = inverterCurrent * microinverterQty
  } else {
    current = inverterCurrent
  }
  const currentRating = isolator?.current_rating
  if (current && currentRating) {
    currentCompatibility = current < currentRating ? 'good' : 'bad'
  } else {
    if (!currentRating) errors.push(window.translate('isolator current rating not defined'))
    if (!current) errors.push(window.translate('maximum inverter output current not defined'))
  }

  const phase = inverter?.phase_type
  const isolatorPhase = isolator?.phase_type
  if (phase && isolatorPhase) {
    phaseCompatibility = phase === isolatorPhase ? 'good' : 'bad'
  } else {
    if (!isolatorPhase) errors.push(window.translate('isolator phase type not defined'))
    if (!phase) errors.push(window.translate('inverter phase type not defined'))
  }

  const compatibility = errors.length
    ? null
    : currentCompatibility === 'good' && phaseCompatibility === 'good'
    ? 'good'
    : 'bad'
  return {
    compatibility,
    data: {
      current,
      currentRating,
      currentCompatibility,
      phase,
      isolatorPhase,
      phaseCompatibility,
      error: errors.length
        ? window.translate('Cannot calculate isolator compatibility') + ': ' + errors.join(', ')
        : undefined,
    },
  }
}

type ACIsolatorValidationPropsType = {
  isolator: OtherComponentType
  isolatorLocation: any // Either OsMppt or OsInverter
}

export const ACIsolatorValidation: FC<ACIsolatorValidationPropsType> = ({ isolator, isolatorLocation }) => {
  const inverter = isolatorLocation.type === 'OsMppt' ? isolatorLocation.parent : isolatorLocation
  const validationResults = getACIsolatorCompatibility(isolator, isolatorLocation)
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <>
      <div className={classes.validationResults}>
        <div className={classes.validationHeading}>
          {inverter.microinverter
            ? isolatorLocation.type === 'OsMppt'
              ? translate('Branch output current rating')
              : translate('Combined branches output current rating')
            : translate('Inverter output current rating')}
          :
        </div>
        <div className={classes.validationValue}>
          {round_number_to_specific_decimal_places(validationResults.data.current, 2)}A
        </div>
        <div className={classes.validationHeading}>{translate('Isolator current rating')}:</div>
        <div className={classes.validationValue}>
          {round_number_to_specific_decimal_places(validationResults.data.currentRating, 2)}A
          <CompatibilityIcon compatibility={validationResults.data.currentCompatibility} />
        </div>
        <div className={classes.validationHeading}>{translate('Inverter phases')}:</div>
        <div className={classes.validationValue}>
          {validationResults.data.phase === 'single_phase'
            ? '1'
            : validationResults.data.phase === 'two_phase'
            ? '2'
            : validationResults.data.phase === 'three_phase'
            ? '3'
            : ''}
        </div>
        <div className={classes.validationHeading}>Isolator phases:</div>
        <div className={classes.validationValue}>
          {validationResults.data.isolatorPhase === 'single_phase'
            ? '1'
            : validationResults.data.isolatorPhase === 'two_phase'
            ? '2'
            : validationResults.data.isolatorPhase === 'three_phase'
            ? '3'
            : ''}
          <CompatibilityIcon compatibility={validationResults.data.phaseCompatibility} />
        </div>
      </div>
      {validationResults.data.error && (
        <Alert severity="warning" styles={{ padding: '4px 4px 4px 2px' }}>
          {validationResults.data.error}
        </Alert>
      )}
    </>
  )
}

export function getSupplyVoltage(supplyPhase: string) {
  const supplyVoltage = supplyPhase === 'single_phase' ? 240 : 415
  return supplyVoltage
}
// reference: https://www.12voltplanet.co.uk/voltage-drop-calculator.html
const cableThicknessToResistance = {
  0.35: 0.0544,
  0.5: 0.0371,
  1.0: 0.0185,
  1.5: 0.0127,
  2.0: 0.00942,
  2.5: 0.0076,
  3.0: 0.00615,
  4.0: 0.00471,
  6.0: 0.00314,
  8.5: 0.0022,
  10.0: 0.00182,
  16.0: 0.00126,
  25.0: 0.00077,
  35.0: 0.00056,
  50.0: 0.00039,
  70.0: 0.00029,
  95.0: 0.0002,
}

// Note cable voltage drop for microinverters DC cabling is not necessary, since the cable lengths are so short.
function getCableVoltageDrop(
  cable: OtherComponentType,
  cableLength: number,
  supplyPhase: 'single_phase' | 'three_phase',
  current: number
): resultsType {
  if (!current) {
    return {
      compatibility: null,
      data: {
        error:
          window.translate('Cannot calculate cable voltage drop') +
          ': ' +
          window.translate('component current not defined'),
      },
    }
  }
  if (!cable.cable_thickness) {
    return {
      compatibility: null,
      data: {
        error:
          window.translate('Cannot calculate cable voltage drop') +
          ': ' +
          window.translate('component cable thickness not defined'),
      },
    }
  }
  const supplyVoltage = getSupplyVoltage(supplyPhase)
  const cableResistance = cableThicknessToResistance[cable.cable_thickness]
  const voltageDrop = 2 * cableLength * current * cableResistance
  const voltageDropPercentage = (voltageDrop / supplyVoltage) * 100
  const compatibility = voltageDropPercentage < 1 ? 'good' : voltageDropPercentage < 3 ? 'ok' : 'bad'
  return {
    compatibility,
    data: {
      voltageDropPercentage,
    },
  }
}

export function getCompatibleCableThickness(
  cableLength: number,
  supplyPhase: 'single_phase' | 'three_phase',
  current: number,
  qty: number // 1 for string inverters, or qty of microinverters for microinverters AC cabling
) {
  const maxResistance = getSupplyVoltage(supplyPhase) / (100 * 2 * cableLength * current * qty)
  const resistance = Object.values(cableThicknessToResistance).find((resistance) => resistance < maxResistance)
  const cableThickness = Object.keys(cableThicknessToResistance).find(
    (thickness) => cableThicknessToResistance[thickness] === resistance
  )
  return Number(cableThickness)
}

type CableValidationPropsType = {
  slot: SlotType
  cable: OtherComponentType
  supplyPhase: 'single_phase' | 'three_phase'
  cableLocation: StringType | StudioInverterType | MpptType
}

export function getMaxCableCurrent(cableLocation: any) {
  // Where cableLocation is OsInverter or OsString or OsMppt
  var current
  const module = window.AccountHelper.getModuleById(window.editor.selectedSystem.moduleId)
  // Calculate the highest expected Imp, where module.imp is at STC (25 degrees C)
  // Assume the highest expected location temperature is 40, making the temperature difference 15 degrees C
  // Assume that all modules are the same. If a combination of different modules are used in the future, the code should be updated to use the panel with the lowest current in the string
  const impAt40 = module.imp + (15 * module.temp_coefficient_isc * module.imp) / 100
  // if string, as below. If microinverter, just multiple current by number of modules attached to location.
  const inverter =
    cableLocation.type === 'OsString'
      ? cableLocation.parent.parent
      : cableLocation.type === 'OsMppt'
      ? cableLocation.parent
      : cableLocation
  if (cableLocation.type === 'OsInverter') {
    current = cableLocation.current_ac_max
  } else if (inverter.microinverter) {
    current = impAt40 * cableLocation.moduleQuantity() // Assumes panels conencted to multi input microinverters are connected in parallel
  } else {
    if (cableLocation.type === 'OsString') {
      current = impAt40
    } else {
      // Assume that all modules are the same. If a combination of different modules are used in the future, the code should be updated to add each string's current
      const strings = cableLocation.strings().length
      current = impAt40 * strings
    }
  }
  return current
}

export const CableValidation: FC<CableValidationPropsType> = ({ slot, cable, supplyPhase, cableLocation }) => {
  const stringOrMPPT = cableLocation
  const current = getMaxCableCurrent(cableLocation)
  const productCableLength = cable.cable_length || 0
  const slotCableLength = slot?.properties?.cableLength
  const [cableLength, setCableLength] = useState(slotCableLength || productCableLength)
  const validationResults = getCableVoltageDrop(cable, cableLength, supplyPhase, current)
  const system = cableLocation.getSystem()
  const classes = useStyles()

  return (
    <>
      <div className={classes.validationResults}>
        <div className={classes.validationHeading}>Cable length:</div>
        <TextField
          className={classes.validationValue}
          type="number"
          id="cable-length"
          onChange={(e) => {
            setCableLength(Number(e.target.value))
            if (!slot.properties) slot.properties = {}
            slot.properties.cableLength = Number(e.target.value)
            updateSlot(slot, system)
          }}
          value={round_number_to_specific_decimal_places(cableLength, 2)}
          variant={'outlined'}
          style={{ backgroundColor: 'white' }}
          InputProps={{
            inputProps: {
              min: 0,
              max: productCableLength || null,
            },
            endAdornment: <div className={classes.validationInput}>m</div>,
            classes: { input: classes.validationInput, root: classes.validationInput },
          }}
        />
        <div className={classes.validationHeading}>Voltage drop:</div>
        <div className={classes.validationValue}>
          {validationResults?.data?.voltageDropPercentage
            ? round_number_to_specific_decimal_places(validationResults.data.voltageDropPercentage, 3) + '% '
            : ''}
          <CompatibilityIcon compatibility={validationResults.compatibility} />
        </div>
      </div>
      {validationResults.data.error && (
        <Alert severity="warning" styles={{ padding: '4px 4px 4px 2px' }}>
          {validationResults.data.error}
        </Alert>
      )}
    </>
  )
}

function getMeterCompatibility(meter: OtherComponentType, phase: 'single_phase' | 'three_phase'): resultsType {
  const meterPhase = meter.phase_type
  const compatibility = phase === meterPhase ? 'good' : 'bad'
  return {
    compatibility,
    data: {
      meterPhase,
      phase,
    },
  }
}

const DEFAULT_FILTER_VALUES = {}
const DEFAULT_DISPLAYED_FILTERS = {}

interface IFilter {
  displayedFilters: { [key: string]: boolean }
  filterValues: { [key: string]: unknown }
}

export class Filter implements IFilter {
  displayedFilters: { [key: string]: boolean }
  filterValues: { [key: string]: unknown }

  constructor(defaultFilter?: Partial<IFilter>) {
    const { filterValues = DEFAULT_FILTER_VALUES, displayedFilters = DEFAULT_DISPLAYED_FILTERS } = defaultFilter || {}
    // avoid referencing issue causing unexpected behavior
    this.displayedFilters = { ...displayedFilters }
    // avoid referencing issue causing unexpected behavior
    this.filterValues = { ...filterValues }
  }
}

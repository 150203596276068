import { Grid } from '@material-ui/core'
import { viewModeSelectors } from 'ducks/viewMode'
import { useUxVersion } from 'hooks/useUxVersion'
import { useGetFujiTourNextStepQuery } from 'persistentContent/tours/fuji/useGetFUJITourSteps'
import { useCallback } from 'react'
import { useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import AddressAutocomplete from 'resources/projects/elements/AddressAutocomplete.js'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { Location4326 } from 'types/studio/structs'
import { useFeatureFlag } from 'util/split'
import { detectImagery as detectImageryAction } from '../../actions/designer'
import { LeadPurchaseSelector } from './LeadPurchaseSelector'

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    width: '90%',
    maxWidth: '800px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'center',
  },
  linkWrapper: {
    width: '100%',
    maxWidth: 1024,
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  orText: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0px 10px 0px 0px',
  },
  linkText: {
    textAlign: 'center',
    color: 'rgb(0,0,0,0.54)',
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.87,
      color: 'black',
    },
  },
  icon: {
    height: 18,
    width: 24,
    float: 'right',
    padding: 0,
    margin: 0,
    marginLeft: 0,
    fill: 'rgb(0,0,0,0.54)',
    '&:hover': {
      fill: theme.helpColor,
    },
  },
  textIconWrapper: {
    display: 'flex',
  },
}))

const AddressSearch = () => {
  const classes = useStyles()
  const history = useHistory()
  const solkunderFeatureOn = useFeatureFlag('solkunder', 'on')
  const isUserLite = !!useSelector(viewModeSelectors.isUserLite)
  const translate = useTranslate()
  const apiKeyGoogle = useSelector((state: any) =>
    state.auth && state.auth.api_key_google ? state.auth.api_key_google : ''
  )
  const step = useSelector((state: any) => state.tour.step)
  const dispatch = useDispatch()
  const nextStep = useGetFujiTourNextStepQuery(step)
  const uxVersion = useUxVersion()
  const isOnLegacyVersion = uxVersion < 3

  const handlePlaceSelected = useCallback(
    (placeAsFields) => {
      let position4326: Location4326 = [placeAsFields.lon, placeAsFields.lat]
      let premiumImgResponse = window.AccountHelper.getIsPremiumImageryAvailable(
        position4326,
        placeAsFields.country_iso2,
        placeAsFields.state
      )
      let premiumImgIsAvailable = !!premiumImgResponse.isAvailable

      // Transfer of user's lite setting into project settings
      placeAsFields.is_lite = isUserLite

      // var placeAsFields: any = parseGooglePlace(place)
      history.push({ pathname: '/projects/new/explore', state: placeAsFields, search: nextStep })

      var preferredMapType = window.getDefaultMapType(!isUserLite)

      // Do not preload 3D if using Nearmap
      var preloadTerrainProvider = preferredMapType === 'Nearmap' ? null : window.MAP_TYPES[preferredMapType]

      dispatch(
        detectImageryAction(
          position4326,
          placeAsFields.country_iso2,
          placeAsFields.state,
          placeAsFields.address,
          premiumImgIsAvailable,
          (window.disablePreload3D && preloadTerrainProvider?.mapType) || null,
          isUserLite
        )
      )
    },
    [step, isUserLite]
  )

  const skipAddress = () => {
    history.push({ pathname: isOnLegacyVersion ? '/projects/new/info' : '/projects/new/manage' })
  }
  return (
    <div className={classes.wrapper}>
      <Grid container spacing={0} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Grid item xs={12} lg={12}>
          <AddressAutocomplete
            onPlaceSelected={handlePlaceSelected}
            api_key_google={apiKeyGoogle}
            types={[]}
            componentRestrictions={null}
            placeholder={translate('Enter new project address') + '...'}
            style={{
              width: '100%',
              outline: 'none',
              fontWeight: 300,
              height: 50,
              boxSizing: 'border-box',
              borderRadius: '10px',
              border: '1px solid #CBCBCB',
              paddingLeft: 40,
              background: `url(${window.PUBLIC_URL}/images/home.svg) no-repeat scroll 9px 11px`,
              //why we add this zIndex ???
              zIndex: '3',
              position: 'relative',
            }}
          />
        </Grid>
        {!isUserLite && (
          <Grid item xs={12} lg={12}>
            <div className={classes.linkWrapper}>
              <div>
                {solkunderFeatureOn && (
                  <LeadPurchaseSelector
                    classes={{
                      button: classes.linkText,
                      icon: classes.icon,
                      select: classes.orText,
                      wrapper: classes.textIconWrapper,
                    }}
                  />
                )}
              </div>
              <div>
                <span className={classes.orText}>{translate('or')}</span>
                <a className={classes.linkText} onClick={skipAddress}>
                  {translate('Enter Address Later')}
                </a>
              </div>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default AddressSearch

import { authSelectors } from 'ducks/auth'
import { permissionsSelectors } from 'ducks/permissions'
import { projectViewSettingsActions, projectViewSettingsSelectors } from 'ducks/projectViewSettings'
import { useNotify } from 'ra-core'
import { useEffect } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'
import { StageActionEventType } from 'types/projects'
import composeOSResourceFetchPath from 'util/fetch/composeOSResourceFetchPath'

const restClientInstance = restClient(window.API_ROOT + '/api')
export const useLoadProjectEvents = () => {
  const form = useForm()
  const notify = useNotify()
  const dispatch = useDispatch()
  const project = useFormState().values
  const orgId = useSelector(authSelectors.getOrgId)

  const eventsValid = useSelector(projectViewSettingsSelectors.getEventsValid)
  const { allowView } = useSelector(permissionsSelectors.getProjectPermissionByKey('manage_notes_activities_actions'))

  useEffect(() => {
    if (project.id && project.id !== 'new' && allowView && !eventsValid && orgId) {
      const events = project.events_data || []
      const firstEvent = events[0]

      let lastDate: string | undefined
      if (firstEvent?.modified_date) {
        lastDate = new Date(firstEvent?.modified_date).toISOString().replace(/T/, ' ').replace(/\..+/, '')
      }

      restClientInstance('CUSTOM_GET', 'custom', {
        url: composeOSResourceFetchPath({
          orgId,
          resource: 'events',
          params: {
            project: project.id,
          },
          optionalParams: {
            modified_date_gte: lastDate,
          },
        }),
      })
        .then((response: any) => {
          if (response?.data) {
            const newEventsData = response.data as StageActionEventType[]
            const lastEvent = firstEvent ? newEventsData.find((x) => x.id === firstEvent.id) : undefined

            if (lastEvent) {
              form.mutators.updateFieldSilently('events_data', [
                ...newEventsData.splice(0, newEventsData.indexOf(lastEvent)),
                ...events,
              ])
              dispatch(projectViewSettingsActions.setEventsValid(true))
            }
          }
        })
        .catch((error: any) => {
          notify('Error fetching new events: ' + error.message, 'warning')
        })
    }
  }, [eventsValid, orgId])
}

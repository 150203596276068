import { TextField } from '@material-ui/core'
import { authReload } from 'actions/authActions'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import Button from 'elements/proUXButtons/ProUXButton'
import FullScreenLoading from 'layout/widgets/FullScreenLoading'
import { NotificationType, useNotify, useTranslate } from 'ra-core'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import restClient from 'restClient'
import appStorage from 'storage/appStorage'
import { TotpDataType } from 'types/mfa'

type PropTypes = {
  goToRecovery: () => void
  isLogin?: boolean
  cancel?: () => void
}

const addTotp = (
  setLoading: (value: boolean) => void,
  setTotpData: (data: TotpDataType) => void,
  notify: (message: string, type?: NotificationType) => void,
  translate: (value: string) => string
) => {
  setLoading(true)
  const restClientInstance = restClient(window.API_ROOT + '/api')
  restClientInstance('CUSTOM_POST', 'custom', {
    url: 'mfa/devices/totp/',
  })
    .then((res: { data: TotpDataType }) => {
      setTotpData(res.data)
    })
    .catch((err: any) => {
      console.warn(err)
      notify('Failed to add Authenticator App', 'warning')
    })
    .finally(() => setLoading(false))
}

const copyKey = (
  totpData: TotpDataType | undefined,
  notify: (message: string, type?: NotificationType) => void,
  translate: (value: string) => string
) => {
  if (!totpData?.secretKey) return
  navigator.clipboard.writeText(totpData.secretKey).then(
    function () {
      notify('Key copied to clipboard', 'success')
    },
    function (err: any) {
      console.warn(err)
      notify('Failed to copy key to clipboard', 'warning')
    }
  )
}

const MfaAddTotp: React.FC<PropTypes> = (props) => {
  const dispatch = useDispatch()
  const notify = useNotify()
  const translate = useTranslate()
  const location = useLocation()

  const [loading, setLoading] = useState<boolean>(false)
  const [code, setCode] = useState<string | undefined>(undefined)
  const [verifying, setVerifying] = useState<boolean>(false)
  const [totpData, setTotpData] = useState<TotpDataType | undefined>(undefined)

  useEffect(() => {
    // Generate unconfirmed TOTP device on landing
    addTotp(setLoading, setTotpData, notify, translate)
  }, [setLoading, setTotpData, notify, translate])

  const onSuccess = (token?: string) => {
    notify('Authenticator App successfully activated')
    logAmplitudeEvent('mfa_totp_code_success', {})

    // Replace our old token with the new token because the old token may now be invalid
    // because it is not MFA verified
    if (token) {
      appStorage.setToken(token)
    } else {
      console.warn('new token was not updated, unable to replace old token')
    }

    if (props.isLogin) {
      dispatch(authReload({ redirectOnFailure: location.pathname }))
    }

    props.goToRecovery()
  }

  const onSubmit = (e) => {
    e.preventDefault()

    if (!code) {
      notify('Authentication code is either missing or invalid', 'warning')
      return false
    }
    setVerifying(true)
    const restClientInstance = restClient(window.API_ROOT + '/api')
    restClientInstance('CUSTOM_POST', 'custom', {
      url: `mfa/devices/totp/confirm/`,
      data: {
        code: code,
      },
    })
      .then((res: { data: { token: string } }) => {
        onSuccess(res.data.token)
      })
      .catch((err: any) => {
        console.warn(err)

        if (err.status === 404) {
          // No device found on Backend
          notify('No device found', 'warning')
        } else {
          notify('Invalid or expired verification code', 'warning')
        }

        logAmplitudeEvent('mfa_totp_code_error', {})
      })
      .finally(() => setVerifying(false))

    return false
  }

  if (loading) return <FullScreenLoading />
  else if (!totpData)
    return (
      <div>
        <p>{translate('Something went wrong setting up your Authenticator App, please try again')}</p>
        <div style={{ marginTop: '15px', width: '100%' }}>
          <Button
            label={translate('Retry')}
            type="primary"
            onClick={() => addTotp(setLoading, setTotpData, notify, translate)}
            fullWidth
          />
        </div>
      </div>
    )
  else
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <div>
          <p>
            {translate(
              'Scan the QR code with your preferred Authenticator App then enter the code from the authenticator app in the field below.'
            )}
          </p>
          <div style={{ marginTop: '15px' }}>
            <img src={totpData.qrCodeUrl} height="150" />
          </div>
          <span
            onClick={() => copyKey(totpData, notify, translate)}
            className="small"
            style={{ margin: '10px 0px 30px 0px', cursor: 'pointer' }}
          >
            {translate('Or click here to copy the setup key')}
          </span>
          <div>
            <TextField
              id="authenticationCode"
              onChange={(e) => setCode(e.target.value)}
              value={code}
              label={translate('Authentication Code')}
              style={{ width: '200px' }}
            />
          </div>
          <div style={{ marginTop: '15px', width: '100%' }}>
            <Button label="Submit" type="primary" onClick={onSubmit} submit={true} fullWidth disabled={verifying} />
          </div>
          {props.cancel && (
            <div style={{ width: '100%', marginTop: '15px' }}>
              <Button label="Back" onClick={props.cancel} type="text" fullWidth={true} style={{ width: '100%' }} />
            </div>
          )}
        </div>
      </div>
    )
}
export default MfaAddTotp

import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import { ComponentVersions_2_1 } from 'constants/uxVersions'
import { getSystemItemsByCode } from 'Designer/designRules/utils'
import ComponentOverview from 'elements/hardwareSelector/ComponentOverview'
import { useAddComponentToSystem } from 'hooks/components/useAddComponentToSystem'
import { useComponentDataByCode } from 'hooks/components/useComponentDataByCode'
import { StyledDialog, StyledDialogContent, StyledDialogTitle } from 'layout/StyledDialog'
import lodash from 'lodash'
import { Button, ComponentVersionsInherit } from 'opensolar-ui'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { useTranslate } from 'react-admin'
import { useHistory, useLocation } from 'react-router-dom'
import { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { AssociationType, DependenciesForDisplayByCode, DependencyForDisplay } from 'types/associatedComponents'
import { StudioOtherType } from 'types/global'
import { ComponentTypes } from 'types/selectComponent'
import { DialogHelper } from 'util/misc'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  table: {
    tableLayout: 'fixed',
  },
  tableHeaderContainer: {
    backgroundColor: theme.greyLight3,
    height: '48px',
  },
  headerCell: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
  },
  productNameContainer: {
    display: 'flex',
    columnGap: '10px',
    '& img': {
      width: '60px',
    },
  },
  productTitle: {
    display: 'flex',
    columnGap: '5px',
    '& > *': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  productCode: {
    display: 'flex',
  },
  productTitleAndCode: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  description: {
    overflow: 'auto',
    maxHeight: '200px',
    overflowX: 'hidden',
  },
  addButton: {
    margin: '6px 0',
  },
}))

const humanizeComponentType = (componentType: string) => {
  if (componentType === 'module') {
    return 'Module'
  } else if (componentType === 'inverter') {
    return 'Inverter'
  } else if (componentType === 'battery') {
    return 'Battery'
  } else {
    return 'Other Component'
  }
}

type Props = {
  isOpen: boolean
  associationType: AssociationType
  components: DependenciesForDisplayByCode
  onClose: () => void
}

const AssociatedComponentsDialog: FC<Props> = ({ isOpen, associationType, components, onClose }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const location = useLocation()
  const history = useHistory()
  const title = associationType === 'require' ? 'Required Components' : 'Recommended Components'
  const addComponentToSystem = useAddComponentToSystem()

  useEffect(() => {
    if (isOpen) {
      DialogHelper.afterOpen('DialogAssociatedComponents')
    }
  }, [isOpen])

  const handleClose = useCallback(() => {
    DialogHelper.beforeClose()
    onClose()
    history.push(location.pathname)
  }, [location.pathname])

  if (!components) return <></>

  const TableHeader = () => {
    const titles = [
      'Product Name',
      'Description',
      'Type',
      associationType === 'require' ? 'Requirement for' : 'Compatible with',
      associationType === 'require' ? 'Quantity' : '',
    ]
    return (
      <TableHead className={classes.tableHeaderContainer}>
        <TableRow>
          {titles.map((title) => (
            <TableCell variant="head" className={classes.headerCell}>
              {translate(title)}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }

  const TableRowItem = ({ dependency }: { dependency: DependencyForDisplay }) => {
    const component = dependency.dependentComponent
    var systemComponentToUpdate: StudioOtherType | null

    const items = getSystemItemsByCode(
      component.componentType,
      component.code,
      window.editor.selectedSystem
    ) as StudioOtherType[]

    const currentQuantity = items.reduce(
      (value, item) => value + (item['quantity'] === undefined ? 1 : item['quantity']),
      0
    )

    const unfulfilledQuantity = component.totalCalculatedQty - currentQuantity
    const fulfilled = unfulfilledQuantity <= 0
    var quantityToAdd: number

    // Required components are tracked by specific uuid so the component quantity is automatically updated as the parent quantities change, whereas recommended components are not
    // For recommended products, if the quantity of component is partially fulfilled, we allow the remaining quantity to be added to any existing component
    // However for required products, we only allow the remaining quantity to be added to the tracked uuid or as a new component which we then track. This is to prevent unexpected behaviour that may arise from having the required quantity split between multiple components and the design rules trying to automatically update the quantity when the parent components change
    if (associationType === 'require') {
      systemComponentToUpdate = component.componentUuid ? window.editor.objectByUuid(component.componentUuid) : null
      quantityToAdd = systemComponentToUpdate ? unfulfilledQuantity : component.totalCalculatedQty
    } else {
      systemComponentToUpdate = items.length ? items[0] : null
      quantityToAdd = unfulfilledQuantity
    }

    const componentActivation = useComponentDataByCode({
      type: component.componentType,
      code: component.code,
      useActivated: true,
      useDatabase: false,
    })
    const componentDatabaseOrActivated = useComponentDataByCode({
      type: component.componentType,
      code: component.code,
      useActivated: true,
      useDatabase: true,
    })

    const parentList = useMemo(
      () =>
        dependency.parentComponents.map((pc) => {
          const item = pc?.code
            ? getSystemItemsByCode(pc.componentType, pc.code, window.editor.selectedSystem)[0]
            : null
          return { ...pc, item }
        }),
      [dependency.parentComponents]
    )

    const parentTypes: ComponentTypes[] = useMemo(() => lodash.uniq(parentList.map((pc) => pc.componentType)), [
      parentList,
    ])

    return (
      <ComponentVersionsInherit versions={ComponentVersions_2_1}>
        <TableRow>
          <TableCell>
            <div className={classes.productNameContainer}>
              {/* <img src={`${window.PUBLIC_URL}/images/component_photo_holder.svg`} /> */}

              <div className={classes.productTitleAndCode}>
                <div className={classes.productTitle}>
                  {componentDatabaseOrActivated && (
                    <>
                      <Typography variant="subtitle2">{componentDatabaseOrActivated.title}</Typography>
                      <ComponentOverview component={componentDatabaseOrActivated} />
                    </>
                  )}
                </div>
                <div className={classes.productCode}>
                  <Typography variant="caption">{component.code}</Typography>
                </div>
              </div>
            </div>
          </TableCell>
          <TableCell>
            <div className={classes.description}>{componentDatabaseOrActivated?.description || ''}</div>
          </TableCell>
          <TableCell>{translate(humanizeComponentType(component.componentType))}</TableCell>
          <TableCell>
            <Typography variant="subtitle2">
              {parentTypes.map((type) => translate(humanizeComponentType(type))).join(', ')}
            </Typography>
            {parentList.map(
              (parentComponent) =>
                parentComponent?.code && (
                  <div>
                    <Typography variant="caption">
                      {parentComponent.item?.manufacturer_name + ' ' + parentComponent.code}
                    </Typography>
                  </div>
                )
            )}
          </TableCell>
          <TableCell>
            <Button
              className={classes.addButton}
              variant="contained"
              color="primary"
              size="small"
              disabled={!componentDatabaseOrActivated || fulfilled}
              onClick={() => {
                if (component.componentType === 'other' && systemComponentToUpdate) {
                  // in this case we can just update the quantity of the existing item
                  window.editor.execute(
                    new window.SetValueCommand(
                      systemComponentToUpdate,
                      'quantity',
                      systemComponentToUpdate.quantity + quantityToAdd,
                      undefined,
                      true
                    )
                  )
                  return
                }
                function addComponent(component, activated, id, system) {
                  addComponentToSystem({
                    componentType: component.componentType,
                    quantity: quantityToAdd,
                    ...(activated ? { componentActivationId: id } : { componentId: id as number }),
                  })
                  if (associationType === 'require') {
                    const newComponent = getSystemItemsByCode(component.componentType, component.code, system)?.slice(
                      -1
                    )[0]
                    if (newComponent) {
                      component.componentUuid = newComponent.uuid
                    }
                  }
                }
                if (componentActivation) {
                  addComponent(component, true, componentActivation.id, window.editor.selectedSystem)
                } else if (componentDatabaseOrActivated) {
                  addComponent(component, false, componentDatabaseOrActivated.id, window.editor.selectedSystem)
                }
              }}
            >
              {translate(fulfilled ? '%{count} Added to System' : 'Add %{count} to System', {
                count: fulfilled ? component.totalCalculatedQty : quantityToAdd,
              })}
            </Button>

            {associationType === 'require' && (
              <>
                {parentList.map((parentComponent) => {
                  return (
                    <div>
                      <Typography variant="caption">
                        ({component.qty} /{' '}
                        {`${parentComponent?.qty && parentComponent.qty > 1 ? parentComponent.qty + ' x ' : ''}`}
                        {`${
                          parentComponent?.code
                            ? parentComponent.code
                            : humanizeComponentType(parentComponent.componentType)
                        }`}
                        )
                      </Typography>
                    </div>
                  )
                })}
              </>
            )}
          </TableCell>
        </TableRow>
      </ComponentVersionsInherit>
    )
  }

  return (
    <StyledDialog id="associatedComponents-dialog" maxWidth="lg" open={isOpen} onBackdropClick={handleClose}>
      <div id="associatedComponents-header">
        <StyledDialogTitle onClose={handleClose}>{translate(title)}</StyledDialogTitle>
      </div>
      <StyledDialogContent>
        <Paper>
          <Table className={classes.table} size="small">
            <colgroup>
              <col style={{ width: '30%;' }} />
              <col style={{ width: '25%' }} />
              <col style={{ width: '10%' }} />
              <col style={{ width: '20%' }} />
              <col style={{ width: '15%' }} />
            </colgroup>
            <TableHeader />
            <TableBody>
              {Object.values(components)
                .flat()
                .map((dependency) => {
                  return <TableRowItem dependency={dependency} />
                })}
            </TableBody>
          </Table>
        </Paper>
      </StyledDialogContent>
    </StyledDialog>
  )
}

export default AssociatedComponentsDialog

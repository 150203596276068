import { Grid, Typography } from '@material-ui/core'
import { orderSelectors } from 'ducks/orderComponents'
import { memo, useState } from 'react'
import { useSelector } from 'react-redux'
import ReviewItemTable from './ReviewItemTable'
import { useCheckoutStyles } from './styles'

type Props = {
  itemNumber: number
}

const ReviewItems: React.FC<Props> = ({ itemNumber }) => {
  const checkoutClassess = useCheckoutStyles()
  const lineItems = useSelector(orderSelectors.getOrderableLineItems)
  const [showAllocations, setShowAllocations] = useState<boolean>(false)

  const handleViewAllocations = () => {
    setShowAllocations(!showAllocations)
  }

  return (
    <Grid container direction="column" justify="space-between" style={{ padding: '20px 0px' }}>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
        }}
      >
        <Typography variant="h6" className={`${checkoutClassess.font18} ${checkoutClassess.fontWeightMedium}`}>
          <span style={{ marginRight: '20px' }}>{itemNumber}</span>
          <span>Review Items</span>
        </Typography>
        <div onClick={handleViewAllocations}>
          <Typography
            className={`${checkoutClassess.font14}`}
            style={{ textDecoration: 'underline', color: '#1751D0', cursor: 'pointer' }}
          >
            <span>{`${showAllocations ? 'Hide' : 'View'} allocations`}</span>
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', padding: '20px 30px 0px 30px' }}>
        <ReviewItemTable lineItems={lineItems} showAllocations={showAllocations} />
      </Grid>
    </Grid>
  )
}

export default memo(ReviewItems)

import { LinearProgress, makeStyles } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import Tooltip from 'elements/tooltip/Tooltip'
import React, { useEffect, useState } from 'react'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { Theme } from 'types/themes'
import { MapTypeNode } from './sortMapTypesIntoTree'

const useStyles = makeStyles<Theme>((theme) => {
  return {
    timeText: {
      opacity: 0.65,
      marginLeft: 10,
    },
    error: {
      opacity: 0.35,
      marginLeft: 10,
    },
    loader: {
      display: 'inline-block',
      width: 40,
      margin: '4px 10px',
    },
  }
})

const restClientInstance = restClient(window.API_ROOT + '/api')
const timestampCache: { [key: string]: TimestampData } = {}

const formatTooltip = (data: TimestampData) => {
  if (!data.first_timestamp) {
    return 'No Details Found'
  } else if (data.first_timestamp === data.last_timestamp) {
    return `${data.first_timestamp} (${data.timezone})`
  } else {
    return `${data.first_timestamp} - ${data.last_timestamp} (${data.timezone})`
  }
}
type TimestampData = {
  timestamp: string | undefined
  first_timestamp: string | undefined
  last_timestamp: string | undefined
  timezone: string | undefined
}

export const ImagerySelectorMenuItem = ({ node, selected }: { node: MapTypeNode; selected: boolean }) => {
  const classes = useStyles()
  const [tooltip, setTooltip] = useState<string | undefined>()
  const [time, setTime] = useState<string | undefined>()
  const [hovering, setHovering] = useState(false)
  const orgId = useSelector(authSelectors.getOrgId)
  const projectId = useFormState().values?.id
  const [loading, setLoading] = useState(false)
  const [hadError, setHadError] = useState(false)

  useEffect(() => {
    setTooltip(undefined)
    if (node.time) {
      setTime(node.time)
    } else if (node.type === 'leaf' && node.mapType.map_type === 'Nearmap') {
      const mapData = node.mapType
      let capture_date = mapData.variation_data?.capture_date || ''

      let url =
        'orgs/' +
        orgId +
        //@ts-ignore
        `/maps/get_imagery_timestamp/?lon=${mapData.variation_data.lon}&lat=${mapData.variation_data.lat}&capture_date=${capture_date}&project_id=${projectId}`
      let cacheKey = url

      if (timestampCache[cacheKey]) {
        setHadError(false)
        setTime(timestampCache[cacheKey].timestamp)
        setTooltip(formatTooltip(timestampCache[cacheKey]))
        return
      }

      if (!hovering && !selected) return

      setLoading(true)
      setHadError(false)

      let nearmap_token = window.getStorage().getItem('nearmap_token')
      if (nearmap_token && nearmap_token.length > 0) {
        url += '&nearmap_token=' + encodeURIComponent(nearmap_token)
      }

      restClientInstance('CUSTOM_GET', 'custom', {
        url: url,
      })
        .then((response: any) => {
          timestampCache[cacheKey] = response.data
          setTime(response.data.timestamp)
          setTooltip(formatTooltip(response.data))
          setLoading(false)
        })
        .catch((err: any) => {
          console.error('Failed to load Nearmap timestamp: ', err)
          setHadError(true)
          setLoading(false)

          setTimeout(() => setHadError(false), 5000)
        })
    }
  }, [node.time, hovering, selected])

  return (
    <Tooltip title={tooltip ? <span>{tooltip}</span> : <></>} placement="left" open={tooltip ? hovering : false}>
      <span onMouseOver={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
        {node.name}
        {loading ? (
          <LinearProgress className={classes.loader} />
        ) : time ? (
          <span className={classes.timeText}>{time}</span>
        ) : (
          hadError && <span className={classes.error}>{'Not Found'}</span>
        )}
      </span>
    </Tooltip>
  )
}

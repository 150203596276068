// @ts-nocheck
import { Dialog, DialogContent, useMediaQuery } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { getAvailableActions, markAsSold, openCreditDecisionDialog } from 'ducks/myEnergy'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import LoadingDots from 'layout/widgets/LoadingDots'
import React, { useEffect, useState } from 'react'
import { SimpleForm, useTranslate } from 'react-admin'
import { useDispatch } from 'react-redux'
import restClient from 'restClient'
import { ProjectType } from 'types/projects'
import { getCommonCreditDecision, getFirstRealContact } from 'util/misc'
import DividendApplicatinDialogFooter from './DividendApplicationDialogFooter'
import DividendApplicationForm from './DividendApplicationForm'
import { CommonBondDecisionType, DisclosureType } from './types'

var restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  open: boolean
  onClose: () => void
  disclosureText: DisclosureType
  project: ProjectType
  paymentOptionId: number
  systemUuid: string
  orgId: number
  onDecisionRendered: () => void
  isFullWidth: boolean
}

const DividendApplicationDialog: React.FunctionComponent<PropTypes> = (props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [decision, setDecision] = useState<CommonBondDecisionType | undefined>(undefined)
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const [creditErrorMsg, setCreditErrorMsg] = useState<string | undefined>(undefined)
  const [appSubmittedSuccessfully, setAppSubmittedSuccessfully] = useState<boolean>(false)
  const [pollCount, setPollCount] = useState<number>(0)

  const translate = useTranslate()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery('(max-width:800px)')

  useEffect(() => {
    console.log('pollCount', pollCount)
    if (pollCount > 0) {
      let msToWait = pollCount === 1 ? 10000 : 5000
      setTimeout(() => {
        pollForDecision(pollCount)
      }, msToWait)
    }
  }, [pollCount])

  useEffect(() => {
    logAmplitudeEvent('integrated_finance_application_opened', {
      integration: 'dividend',
      project_id: props.project?.id,
      payment_option_id: props?.paymentOptionId,
    })
  }, [])

  useEffect(() => {
    if (!props.project?.contacts || props.project?.contacts?.length === 0) {
      setErrorMsg('Please add a customer to this project before submitting the application')
    }
  }, [props.project?.contacts])

  useEffect(() => {
    if (decision) {
      props.onDecisionRendered()
      if (
        decision === 'Approved' ||
        decision === 'Conditionally approved' ||
        decision === 'Approved for reduced loan amount'
      ) {
        dispatch(markAsSold(props.systemUuid, props.paymentOptionId))
      }
      // wait a bit, then go fetch new customer actions
      setTimeout(() => {
        dispatch(getAvailableActions(props.orgId, props.project.id))
      }, 500)
    }
  }, [decision])

  const getInitialValues = () => {
    if (!props.project) return {}
    // get primary contact's info
    let primaryContact = undefined
    if (props.project.contacts_data?.length) {
      primaryContact = getFirstRealContact(props.project.contacts_data)
    } else {
      primaryContact =
        props.project.contacts && props.project.contacts.length > 0 ? props.project?.contacts[0] : undefined
    }

    const first_name = primaryContact ? primaryContact.first_name : undefined
    const family_name = primaryContact ? primaryContact.family_name : undefined

    // get secondary contact's info if there is one
    const secondaryContact =
      props.project.contacts && props.project.contacts.length > 1 ? props.project?.contacts[1] : undefined

    return {
      first_name: first_name,
      family_name: family_name,
      address: props.project.address,
      locality: props.project.locality,
      state: props.project.state,
      zip: props.project.zip,
      email: primaryContact ? primaryContact.email : undefined,
      phone_number: primaryContact ? primaryContact.phone : undefined,
    }
  }

  const getValidationError = (formVals: any) => {
    let missingConsent = false
    Object.keys(props.disclosureText).forEach((disclosureKey) => {
      if (!formVals[disclosureKey]) missingConsent = true
    })
    if (missingConsent) {
      let el = document.getElementById('disclosures')
      el.scrollIntoView(true)
      return 'Please read and agree to the terms and conditions at the bottom of the application'
    }
    return undefined
  }

  const handleApplicationResponse = (response: any, localPollCount = 1) => {
    if (response?.data?.success) {
      if (response.data.decision && response.data.decision !== 'unknown') {
        logAmplitudeEvent('integrated_finance_application_submitted', {
          integration: 'dividend',
          decision: getCommonCreditDecision(response.data.decision),
          project_id: props.project?.id,
          payment_option_id: props?.paymentOptionId,
        })
        setDecision(response.data.decision)
        props.onClose()
        const decisionDisplayProps = {
          decision: response.data.decision,
          amountApproved: response.data.amount_approved,
          stips: response.data.stips,
        }
        dispatch(openCreditDecisionDialog('dividend', decisionDisplayProps))
        setLoading(false)
      } else {
        setPollCount(localPollCount + 1)
      }
    } else {
      logAmplitudeEvent('integrated_finance_application_submit_error', {
        integration: 'dividend',
        project_id: props.project?.id,
        payment_option_id: props?.paymentOptionId,
      })
      setLoading(false)
      if (response?.data?.credit_error) {
        setErrorMsg(undefined)
        setCreditErrorMsg(response.data.credit_error_message)
      } else if (response?.data?.errorMsg) setErrorMsg(response.data.errorMsg)
      else setErrorMsg('We were unable to submit this credit application')
    }
  }

  const handleApplicationError = (err: any, requestStart: Date) => {
    console.log('err.body', err.body)
    if (err?.body?.credit_error) {
      setLoading(false)
      setErrorMsg(undefined)
      setCreditErrorMsg(err.body.credit_error_message)
      logAmplitudeEvent('integrated_finance_application_blocked', {
        integration: 'dividend',
        project_id: props.project?.id,
        payment_option_id: props?.paymentOptionId,
      })
    } else if (err?.body?.errorMsg) {
      setErrorMsg(err.body.errorMsg)
      setLoading(false)
    } else {
      setLoading(false)
      logAmplitudeEvent('integrated_finance_application_submit_error', {
        integration: 'dividend',
        project_id: props.project?.id,
        payment_option_id: props?.paymentOptionId,
      })
      setErrorMsg('Something went wrong and we were unable to submit this credit application')
    }
  }

  const submitApplication = (formVals: any) => {
    let error: string | undefined = getValidationError(formVals)
    if (error) {
      logAmplitudeEvent('integrated_finance_application_form_error', {
        integration: 'dividend',
        error: error,
        project_id: props.project?.id,
        payment_option_id: props?.paymentOptionId,
      })
      setErrorMsg(error)
      return
    }
    setLoading(true)
    let requestStart = new Date()
    try {
      logAmplitudeEvent('integrated_finance_application_submit_attempt', { integration: 'dividend' })
    } catch (ex) {}
    // @ts-ignore
    restClientInstance('CUSTOM_POST', 'custom', {
      url:
        'orgs/' +
        props.orgId +
        '/projects/' +
        props.project?.id +
        '/systems/' +
        props.systemUuid +
        '/' +
        props.paymentOptionId +
        '/dividend/application/',
      data: {
        ...formVals,
        income: formVals.income ? formVals.income.replace(/[^0-9.,$]/g, '') : undefined,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setAppSubmittedSuccessfully(true)
          setPollCount(1)
        }
      })
      .catch((err) => handleApplicationError(err, requestStart))
    try {
      logAmplitudeEvent('integrated_finance_application_submit_attempt', { integration: 'dividend' })
    } catch (ex) {}
  }

  // pass the state var pollCount into this, then it uses the localPollCount var so we can get around weird async state issues
  const pollForDecision = (localPollCount = 1) => {
    let requestStart = new Date()
    // @ts-ignore
    restClientInstance('CUSTOM_GET', 'custom', {
      url:
        'orgs/' +
        props.orgId +
        '/projects/' +
        props.project?.id +
        `/dividend/application/status/?poll_count=${localPollCount}`,
    })
      .then((res) => {
        console.log('status res')
        handleApplicationResponse(res, localPollCount)
      })
      .catch((err) => handleApplicationError(err, requestStart))
  }

  const confirmBeforeClose = () => {
    if (window.confirm(translate('Are you sure you want to exit your application? All progress will be lost')))
      props.onClose()
  }

  const unblock = () => {
    setCreditErrorMsg(undefined)
  }

  const renderFormContent = () => {
    return (
      <>
        {loading && (
          <>
            <div
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundColor: '#ececec',
                opacity: 0.9,
                zIndex: 9000,
              }}
            />
            <div
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0px 20px',
                zIndex: 9001,
              }}
            >
              <LoadingDots
                text={
                  appSubmittedSuccessfully
                    ? 'Your application has been submitted! Dividend is now reviewing your application. This process can take up to a minute, thank you for your patience.'
                    : 'One moment while we submit your application to Dividend for review'
                }
              />
            </div>
          </>
        )}
        <div style={{ paddingBottom: '250px' }}>
          {creditErrorMsg && (
            <div
              style={{
                position: 'absolute',
                left: '0px',
                right: '0px',
                bottom: '0px',
                top: '0px',
                backgroundColor: '#fff',
                zIndex: 9000,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: creditErrorMsg,
                  }}
                  style={{ textAlign: 'center' }}
                />

                <p>{translate('Once you have resolved this issue you can re-apply through this proposal.')}</p>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '30px',
                  }}
                >
                  <div style={{ margin: '15px' }}>
                    <ProUXButton type="secondary" label="Dismiss and Re-Apply Later" onClick={props.onClose} />
                  </div>
                  <div style={{ margin: '15px' }}>
                    <ProUXButton type="primary" label="I'm ready to Re-Apply" onClick={unblock} />
                  </div>
                </div>
              </div>
            </div>
          )}
          <SimpleForm initialValues={getInitialValues()} toolbar={<div></div>}>
            <DividendApplicationForm disclosureText={props.disclosureText} />
            <DividendApplicatinDialogFooter
              submitApplication={submitApplication}
              loading={loading}
              onClose={confirmBeforeClose}
              errorMsg={errorMsg}
            />
          </SimpleForm>
        </div>
      </>
    )
  }

  return (
    <Dialog
      maxWidth={props.isFullWidth ? 'xl' : 'md'}
      open={props.open}
      onClose={confirmBeforeClose}
      fullScreen={isMobile || props.isFullWidth}
      fullWidth={isMobile || props.isFullWidth}
    >
      <div style={{ textAlign: 'center' }}>
        <h1 style={{ fontSize: isMobile ? undefined : '24px' }}>
          {creditErrorMsg ? translate('Unable to Submit Application') : translate('Apply for Financing with Dividend')}
        </h1>
      </div>
      <DialogContent dividers>{renderFormContent()}</DialogContent>
    </Dialog>
  )
}

export default DividendApplicationDialog

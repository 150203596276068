import { useCallback } from 'react'
import { getMsalSegenSingletonInstance } from 'services/identity/getMsalSingletonInstance'
import SegenAuthenticationService from 'services/segen/SegenAuthenticationService'
import { ExtendedRequestInfoSafe, RequestMiddleware } from '../types'

const segenAuthenticationService = new SegenAuthenticationService({
  msalInstance: getMsalSegenSingletonInstance(),
  segenConfig: window.getSegenConfig(),
})

const SEGEN_TOKEN_ACCESSIBLE_ENDPOINT_LIST = [
  // searching for components not yet activated, inside Design
  '/components/',
  '/component_modules/',
  '/component_inverters/',
  '/component_batteries/',
  '/component_others/',
  // Reconciling orders
  '/_orders/reconcile/',
  // Loading Components in Design
  '/component_specs/',
  '/_orders/segen/create/',
  // Creating & manually searching/adding components in "Create Quote" dialog
  '/inject_variant_ids/segen/',
  '/search_component_catalog/segen',
  // Renusol & Esdec Racking BOM generators
  '/bom/',
  '/renusol/parts/',
  '/segen_api/',
  // Syncing segen products
  '/ordering/segen/sync/',
  '/segen/sync_prices',

  // Get segen available brands
  'ordering/segen/manufacturer_list/',
]

export const useSegenAuthReqMiddleware = (): RequestMiddleware =>
  useCallback(async (req: Request, options: ExtendedRequestInfoSafe) => {
    if (SEGEN_TOKEN_ACCESSIBLE_ENDPOINT_LIST.some((endpoint) => req.url.includes(endpoint))) {
      const segenAccessToken = await segenAuthenticationService.maybeGetSegenAccessTokenSilently()
      if (segenAccessToken != null) {
        options.headers.set('Authorization-Segen', 'Bearer ' + segenAccessToken)
      }
    }
  }, [])

import classNames from 'classnames'
import { HeatPumpOutlinedIcon } from 'opensolar-ui'
import { useTranslate } from 'react-admin'
import { useField } from 'react-final-form'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import AccordionCard from '../../info/AccordionCard'
import HeatContent from './HeatContent'

const useStyles = makeOpenSolarStyles({
  errorBorder: {
    border: '2px solid rgb(230, 37, 0)',
    borderRadius: 4,
  },
})

const HeatAccordion = () => {
  const {
    meta: { error, submitError },
  } = useField('usage')
  const classes = useStyles()
  const translate = useTranslate()
  const usageErrorMessage = error || submitError

  return (
    <AccordionCard
      className={classNames([usageErrorMessage && classes.errorBorder])}
      defaultExpanded={true}
      name={'heat'}
      title={translate('Heat')}
      titleIcon={() => <HeatPumpOutlinedIcon width="24px" height="24px" stroke="none" />}
      content={HeatContent}
    />
  )
}
export default HeatAccordion

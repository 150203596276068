import { FormControl, Tooltip, Typography } from '@material-ui/core'
import { TextInput } from 'ra-ui-materialui'
import React, { useEffect, useState } from 'react'
import { ProjectType } from 'types/projects'
import { useStyles } from './styles'

interface PropTypes {
  project: ProjectType
}

const TitlePanel: React.FC<PropTypes> = ({ project }) => {
  const classes = useStyles()
  const [address, setAddress] = useState('')

  useEffect(() => {
    const parts = [project.locality, project.state, project.zip].filter((p) => p !== null)
    setAddress(parts.join(', '))
  }, [project])

  return (
    <div className={classes.rows + ' ' + classes.veryShort} style={{ paddingLeft: 20 }}>
      <FormControl className={classes.row} size="small">
        <TextInput
          className={classes.rowValue + ' ' + classes.fill}
          label={false}
          variant="outlined"
          size="small"
          source="address"
          FormHelperTextProps={{
            hidden: true,
          }}
        />
        <Tooltip title={project.address || address}>
          <Typography className={classes.address} variant="subtitle1">
            {address}
          </Typography>
        </Tooltip>
      </FormControl>
    </div>
  )
}

export default TitlePanel

import { makeStyles } from '@material-ui/core/styles'
import PublishIcon from '@material-ui/icons/PublishOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import React, { useEffect, useState } from 'react'
import { StipulationActionType } from './types'
import UploadStipulationDialog from './UploadStipulationDialog'

const useStyle = makeStyles({
  mainView: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 5px',
    margin: '5px 0px',
    backgroundColor: '#ececec',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
      boxShadow: undefined,
    },
    background: 'linear-gradient(180deg, #46BC41 0%, #34A02F 100%)',
    border: '1px solid #30862C',
    borderRadius: '3px',
    boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.25)',
    color: '#FFFFFF',
    fontSize: 15,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    width: '100%',
  },
  nameView: {
    flex: 3,
  },
  iconView: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  linkWrapper: {
    marginTop: '15px',
    textAlign: 'center',
  },
})

type PropTypes = {
  stipulation: StipulationActionType
  linkColor?: string
}

const UploadStipulationButton: React.FunctionComponent<PropTypes> = (props) => {
  const classes = useStyle()
  const [showDialog, setShowDialog] = useState<boolean>(false)
  useEffect(() => {
    if (showDialog) {
      logAmplitudeEvent('stip_upload_form_viewed', { mode: 'proposal', page: 'my_energy' })
    }
  }, [showDialog])

  const openAfterFirstUpload = () => {
    logAmplitudeEvent('stip_upload_form_viewed_again', { integration: 'loanpal', page: 'my_energy' })
    setShowDialog(true)
  }
  return (
    <>
      {props.stipulation.stipulation_review_status === 'created' && (
        <div className={classes.mainView} onClick={() => setShowDialog(true)}>
          <div className={classes.row}>
            <div className={classes.nameView}>{props.stipulation.stipulation_name}</div>
            <div className={classes.iconView}>
              <PublishIcon />
            </div>
          </div>
          {props.stipulation.stipulation_description && (
            <div className={classes.row}>
              <span>{props.stipulation.stipulation_description}</span>
            </div>
          )}
        </div>
      )}
      {props.stipulation.stipulation_review_status === 'submitted' && (
        <div className={classes.linkWrapper}>
          <a
            className={classes.nameView}
            style={{ color: props.linkColor, cursor: 'pointer', textDecoration: 'underline' }}
            onClick={openAfterFirstUpload}
          >
            Need to upload more {props.stipulation.stipulation_name} documents?
          </a>
        </div>
      )}
      {showDialog && (
        <UploadStipulationDialog
          open={showDialog}
          stipulation={props.stipulation}
          onClose={() => setShowDialog(false)}
        />
      )}
    </>
  )
}

export default UploadStipulationButton

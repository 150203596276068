import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined'
import { Button } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import DefaultTooltip from '../fuji/DefaultToolTip'
import { ToolTipPropsType, TourConfigType } from '../types'

const Tooltip = (props: ToolTipPropsType) => {
  const { handleNext, handlePrev, handleCancel } = props
  const translate = useTranslate()
  return (
    <DefaultTooltip
      title={translate('Add Payment Options')}
      // onClose={handleCancel}
      icon={LayersOutlinedIcon}
      content={
        <div>
          <div>{translate('Navigate to the Payments tab to choose the payment options your customer will see.')}</div>
        </div>
      }
      actions={
        <>
          <Button
            onClick={() => handlePrev && handlePrev()}
            onTouchStart={(e) => {
              e.stopPropagation()
              if (handlePrev) {
                handlePrev()
              }
            }}
          >
            <span>{translate('Back')}</span>
          </Button>
          <Button disabled={true}>
            <span>{translate('Next')}</span>
          </Button>
        </>
      }
      arrowPlacement={'right'}
    />
  )
}

const PaymentsTabStep: TourConfigType = {
  spotlights: [{ targetId: 'subheader-payments', disableBackgroundScroll: false, padding: 5 }],
  tooltips: [{ targetId: 'subheader-payments', placement: 'left', component: Tooltip }],
}

export default PaymentsTabStep

import { Item, Orientation, Panel } from '../../types'

type Tray = {
  name: string
  minHeight: number
  maxHeight: number
  minWidth: number
  maxWidth: number
  version: string
  orientation: Orientation
  size: 'full' | 'half'
}

export function getItemFromTray(tray: Tray): Item {
  return {
    name: tray.name,
    components: [
      {
        type: 4,
        name: tray.name,
        top: 0,
        left: 0,
      },
    ],
  }
}

export function getCompatibleTrays(panel: Panel, orientation: Orientation): Tray[] {
  const { width, height } = panel

  return trays.filter(
    (tray) =>
      tray.orientation === orientation &&
      height >= tray.minHeight &&
      height <= tray.maxHeight &&
      width >= tray.minWidth &&
      width <= tray.maxWidth
  )
}

export const trays: Tray[] = [
  // {
  //   name: '1575_1046',
  //   minHeight: 1495,
  //   maxHeight: 1675,
  //   minWidth: 1041,
  //   maxWidth: 1046,
  //   version: '2012',
  //   orientation: 'portrait',
  //   size: 'full',
  // },
  // {
  //   name: '1575_1053',
  //   minHeight: 1495,
  //   maxHeight: 1675,
  //   minWidth: 1048,
  //   maxWidth: 1053,
  //   version: '2012',
  //   orientation: 'portrait',
  //   size: 'full',
  // },
  // {
  //   name: '1575_1082',
  //   minHeight: 1495,
  //   maxHeight: 1675,
  //   minWidth: 1077,
  //   maxWidth: 1082,
  //   version: '2012',
  //   orientation: 'portrait',
  //   size: 'full',
  // },
  // {
  //   name: '1580_808',
  //   minHeight: 1500,
  //   maxHeight: 1680,
  //   minWidth: 803,
  //   maxWidth: 808,
  //   version: '2012',
  //   orientation: 'portrait',
  //   size: 'full',
  // },
  // {
  //   name: '1640_992',
  //   minHeight: 1560,
  //   maxHeight: 1740,
  //   minWidth: 987,
  //   maxWidth: 992,
  //   version: '2012',
  //   orientation: 'portrait',
  //   size: 'full',
  // },
  // {
  //   name: '1640_1001',
  //   minHeight: 1560,
  //   maxHeight: 1740,
  //   minWidth: 996,
  //   maxWidth: 1001,
  //   version: '2012',
  //   orientation: 'portrait',
  //   size: 'full',
  // },
  // {
  //   name: '1640_1001_33',
  //   minHeight: 1560,
  //   maxHeight: 1740,
  //   minWidth: 996,
  //   maxWidth: 1001,
  //   version: '2012',
  //   orientation: 'portrait',
  //   size: 'full',
  // },
  // {
  //   name: '1686-1700-1016',
  //   minHeight: 1605,
  //   maxHeight: 1755,
  //   minWidth: 1011,
  //   maxWidth: 1016,
  //   version: '2012',
  //   orientation: 'portrait',
  //   size: 'full',
  // },
  {
    name: 'DPo_1650_995',
    minHeight: 1570,
    maxHeight: 1800,
    minWidth: 991,
    maxWidth: 995,
    version: '2022',
    orientation: 'portrait',
    size: 'half',
  },
  {
    name: 'DPo_1650_1070',
    minHeight: 1570,
    maxHeight: 1800,
    minWidth: 1066,
    maxWidth: 1070,
    version: '2022',
    orientation: 'portrait',
    size: 'half',
  },
  {
    name: 'ART105155',
    minHeight: 1570,
    maxHeight: 1800,
    minWidth: 1096,
    maxWidth: 1100,
    version: '2022',
    orientation: 'portrait',
    size: 'half',
  },
  {
    name: 'GSE-FRAME-DPO-1650-1135 GSE GSE Frame Portrait -1650/1135',
    minHeight: 1570,
    maxHeight: 1800,
    minWidth: 1131,
    maxWidth: 1135,
    version: '2022',
    orientation: 'portrait',
    size: 'half',
  },
  {
    name: 'GSE-FRAME-DPO-1650-1140 GSE GSE Frame Portrait 1650/1140',
    minHeight: 1570,
    maxHeight: 1800,
    minWidth: 1136,
    maxWidth: 1140,
    version: '2022',
    orientation: 'portrait',
    size: 'half',
  },
  {
    name: 'DPo_1650_1145',
    minHeight: 1570,
    maxHeight: 1800,
    minWidth: 1141,
    maxWidth: 1145,
    version: '2022',
    orientation: 'portrait',
    size: 'half',
  },
  {
    name: 'GSE-FRAME-DPO-1650-1160 GSE GSE Frame Portrait 1650/1160',
    minHeight: 1570,
    maxHeight: 1800,
    minWidth: 1156,
    maxWidth: 1160,
    version: '2022',
    orientation: 'portrait',
    size: 'half',
  },
  {
    name: '1710_995',
    minHeight: 1630,
    maxHeight: 1780,
    minWidth: 990,
    maxWidth: 995,
    version: '2020',
    orientation: 'portrait',
    size: 'full',
  },
  {
    name: 'GSE-FRAMEHC-POR-1710-1000 GSE GSE Frame Half Cell Portrait 1710 / 1000',
    minHeight: 1630,
    maxHeight: 1780,
    minWidth: 995,
    maxWidth: 1000,
    version: '2020',
    orientation: 'portrait',
    size: 'full',
  },
  {
    name: 'GSE-FRAMEHC-POR-1710-1005 GSE GSE Frame Half Cell Portrait 1710 / 1005',
    minHeight: 1630,
    maxHeight: 1780,
    minWidth: 1000,
    maxWidth: 1005,
    version: '2020',
    orientation: 'portrait',
    size: 'full',
  },
  {
    name: '1710_1010',
    minHeight: 1630,
    maxHeight: 1780,
    minWidth: 1005,
    maxWidth: 1010,
    version: '2020',
    orientation: 'portrait',
    size: 'full',
  },
  {
    name: '1710_1020',
    minHeight: 1630,
    maxHeight: 1780,
    minWidth: 1015,
    maxWidth: 1020,
    version: '2020',
    orientation: 'portrait',
    size: 'full',
  },
  {
    name: '1710_1025',
    minHeight: 1630,
    maxHeight: 1780,
    minWidth: 1020,
    maxWidth: 1025,
    version: '2020',
    orientation: 'portrait',
    size: 'full',
  },
  {
    name: 'GSE-FRAMEHC-POR-1710-1030 GSE GSE Frame Half Cell Portrait 1710 / 1030',
    minHeight: 1630,
    maxHeight: 1780,
    minWidth: 1025,
    maxWidth: 1030,
    version: '2020',
    orientation: 'portrait',
    size: 'full',
  },
  {
    name: 'GSE-FRAMEHC-POR-1710-1040 GSE GSE Frame Half Cell Portrait 1710 / 1040',
    minHeight: 1630,
    maxHeight: 1780,
    minWidth: 1035,
    maxWidth: 1040,
    version: '2020',
    orientation: 'portrait',
    size: 'full',
  },
  {
    name: 'GSE-FRAMEHC-POR-1710-1045 GSE GSE Frame Half Cell Portrait 1710 / 1045',
    minHeight: 1630,
    maxHeight: 1780,
    minWidth: 1040,
    maxWidth: 1045,
    version: '2020',
    orientation: 'portrait',
    size: 'full',
  },
  {
    name: 'GSE-FRAMEHC-POR-1710-1050 GSE GSE Frame Half Cell Portrait 1710 / 1050',
    minHeight: 1630,
    maxHeight: 1780,
    minWidth: 1045,
    maxWidth: 1050,
    version: '2020',
    orientation: 'portrait',
    size: 'full',
  },
  {
    name: 'GSE-FRAMEHC-POR-1710-1055 GSE GSE Frame Half Cell Portrait 1710 / 1055',
    minHeight: 1630,
    maxHeight: 1780,
    minWidth: 1050,
    maxWidth: 1055,
    version: '2020',
    orientation: 'portrait',
    size: 'full',
  },
  {
    name: 'DPo_1840_995',
    minHeight: 1760,
    maxHeight: 1990,
    minWidth: 991,
    maxWidth: 995,
    version: '2022',
    orientation: 'portrait',
    size: 'half',
  },
  {
    name: 'GSE-FRAME-DPO-1840-1020 GSE GSE Frame Portrait 1840/1020',
    minHeight: 1760,
    maxHeight: 1990,
    minWidth: 1016,
    maxWidth: 1020,
    version: '2022',
    orientation: 'portrait',
    size: 'half',
  },
  {
    name: 'GSE-FRAME-DPO-1840-1030 GSE GSE Frame Portrait 1840/1030',
    minHeight: 1760,
    maxHeight: 1990,
    minWidth: 1026,
    maxWidth: 1030,
    version: '2022',
    orientation: 'portrait',
    size: 'half',
  },
  {
    name: 'DPo_1840_1040',
    minHeight: 1760,
    maxHeight: 1990,
    minWidth: 1036,
    maxWidth: 1040,
    version: '2022',
    orientation: 'portrait',
    size: 'half',
  },
  {
    name: 'GSE-FRAME-DPO-1840-1045 GSE GSE Frame Portrait 1840/1045',
    minHeight: 1760,
    maxHeight: 1990,
    minWidth: 1041,
    maxWidth: 1045,
    version: '2022',
    orientation: 'portrait',
    size: 'half',
  },
  {
    name: 'DPo_1840_1050',
    minHeight: 1760,
    maxHeight: 1990,
    minWidth: 1046,
    maxWidth: 1050,
    version: '2022',
    orientation: 'portrait',
    size: 'half',
  },
  {
    name: 'GSE-FRAME-DPO-1840-1050 GSE GSE Frame Portrait 1840/1050',
    minHeight: 1760,
    maxHeight: 1990,
    minWidth: 1066,
    maxWidth: 1070,
    version: '2022',
    orientation: 'portrait',
    size: 'half',
  },
  {
    name: 'DPo_1840_1100',
    minHeight: 1760,
    maxHeight: 1990,
    minWidth: 1096,
    maxWidth: 1100,
    version: '2022',
    orientation: 'portrait',
    size: 'half',
  },
  {
    name: 'GSE-FRAME-DPO-1840-1135 GSE GSE Frame Portrait 1840/1135',
    minHeight: 1760,
    maxHeight: 1990,
    minWidth: 1131,
    maxWidth: 1135,
    version: '2022',
    orientation: 'portrait',
    size: 'half',
  },
  {
    name: 'DPo_1840_1140',
    minHeight: 1760,
    maxHeight: 1990,
    minWidth: 1136,
    maxWidth: 1140,
    version: '2022',
    orientation: 'portrait',
    size: 'half',
  },
  {
    name: 'DPo_2030_995',
    minHeight: 1950,
    maxHeight: 2180,
    minWidth: 991,
    maxWidth: 995,
    version: '2022',
    orientation: 'portrait',
    size: 'half',
  },
  {
    name: 'DPo_2030_1050',
    minHeight: 1950,
    maxHeight: 2180,
    minWidth: 1046,
    maxWidth: 1050,
    version: '2022',
    orientation: 'portrait',
    size: 'half',
  },
  {
    name: 'DPo_2030_1055',
    minHeight: 1950,
    maxHeight: 2180,
    minWidth: 1051,
    maxWidth: 1055,
    version: '2022',
    orientation: 'portrait',
    size: 'half',
  },
  {
    name: 'DPo_2030_1135',
    minHeight: 1950,
    maxHeight: 2180,
    minWidth: 1131,
    maxWidth: 1135,
    version: '2022',
    orientation: 'portrait',
    size: 'half',
  },
  {
    name: 'DPo_2030_1305',
    minHeight: 1950,
    maxHeight: 2180,
    minWidth: 1301,
    maxWidth: 1305,
    version: '2022',
    orientation: 'portrait',
    size: 'half',
  },
  // {
  //   name: '1559_1082',
  //   minHeight: 1002,
  //   maxHeight: 1182,
  //   minWidth: 1554,
  //   maxWidth: 1559,
  //   version: '2012',
  //   orientation: 'landscape',
  //   size: 'full',
  // },
  // {
  //   name: '1575_1082',
  //   minHeight: 1002,
  //   maxHeight: 1182,
  //   minWidth: 1570,
  //   maxWidth: 1575,
  //   version: '2012',
  //   orientation: 'landscape',
  //   size: 'full',
  // },
  // {
  //   name: '1580_808',
  //   minHeight: 728,
  //   maxHeight: 908,
  //   minWidth: 1575,
  //   maxWidth: 1580,
  //   version: '2012',
  //   orientation: 'landscape',
  //   size: 'full',
  // },
  // {
  //   name: '1640_992',
  //   minHeight: 912,
  //   maxHeight: 1092,
  //   minWidth: 1635,
  //   maxWidth: 1640,
  //   version: '2012',
  //   orientation: 'landscape',
  //   size: 'full',
  // },
  // {
  //   name: '1650_992',
  //   minHeight: 912,
  //   maxHeight: 1092,
  //   minWidth: 1645,
  //   maxWidth: 1650,
  //   version: '2012',
  //   orientation: 'landscape',
  //   size: 'full',
  // },
  // {
  //   name: '1660_992',
  //   minHeight: 912,
  //   maxHeight: 1092,
  //   minWidth: 1655,
  //   maxWidth: 1660,
  //   version: '2012',
  //   orientation: 'landscape',
  //   size: 'full',
  // },
  // {
  //   name: '1670_992',
  //   minHeight: 912,
  //   maxHeight: 1092,
  //   minWidth: 1665,
  //   maxWidth: 1670,
  //   version: '2012',
  //   orientation: 'landscape',
  //   size: 'full',
  // },
  // {
  //   name: '1675_992_33',
  //   minHeight: 912,
  //   maxHeight: 1092,
  //   minWidth: 1670,
  //   maxWidth: 1675,
  //   version: '2012',
  //   orientation: 'landscape',
  //   size: 'full',
  // },
  // {
  //   name: '1680_992',
  //   minHeight: 912,
  //   maxHeight: 1092,
  //   minWidth: 1675,
  //   maxWidth: 1680,
  //   version: '2012',
  //   orientation: 'landscape',
  //   size: 'full',
  // },
  // {
  //   name: '1686_1016',
  //   minHeight: 912,
  //   maxHeight: 1092,
  //   minWidth: 1681,
  //   maxWidth: 1686,
  //   version: '2012',
  //   orientation: 'landscape',
  //   size: 'full',
  // },
  // {
  //   name: '1700_1016',
  //   minHeight: 912,
  //   maxHeight: 1092,
  //   minWidth: 1695,
  //   maxWidth: 1700,
  //   version: '2012',
  //   orientation: 'landscape',
  //   size: 'full',
  // },
  {
    name: '1665_1020',
    minHeight: 940,
    maxHeight: 1120,
    minWidth: 1660,
    maxWidth: 1665,
    version: '2020',
    orientation: 'landscape',
    size: 'full',
  },
  {
    name: '1675_1020',
    minHeight: 940,
    maxHeight: 1120,
    minWidth: 1670,
    maxWidth: 1675,
    version: '2020',
    orientation: 'landscape',
    size: 'full',
  },
  {
    name: '1680_1020',
    minHeight: 940,
    maxHeight: 1120,
    minWidth: 1675,
    maxWidth: 1680,
    version: '2020',
    orientation: 'landscape',
    size: 'full',
  },
  {
    name: 'GSE-FRAMEHC-LAN-1685-1020 GSE GSE Frame Half Cell Landscape 1685 / 1020',
    minHeight: 940,
    maxHeight: 1120,
    minWidth: 1680,
    maxWidth: 1685,
    version: '2020',
    orientation: 'landscape',
    size: 'full',
  },
  {
    name: 'GSE-FRAMEHC-LAN-1690-1020 GSE GSE Frame Half Cell Landscape 1690 / 1020',
    minHeight: 940,
    maxHeight: 1120,
    minWidth: 1685,
    maxWidth: 1690,
    version: '2020',
    orientation: 'landscape',
    size: 'full',
  },
  {
    name: '1695_1020',
    minHeight: 940,
    maxHeight: 1120,
    minWidth: 1690,
    maxWidth: 1695,
    version: '2020',
    orientation: 'landscape',
    size: 'full',
  },
  {
    name: 'GSE-FRAMEHC-LAN-1700-1020 GSE GSE Frame Half Cell Landscape 1700 / 1020',
    minHeight: 940,
    maxHeight: 1120,
    minWidth: 1695,
    maxWidth: 1700,
    version: '2020',
    orientation: 'landscape',
    size: 'full',
  },
  {
    name: '1705_1020',
    minHeight: 940,
    maxHeight: 1120,
    minWidth: 1700,
    maxWidth: 1705,
    version: '2020',
    orientation: 'landscape',
    size: 'full',
  },
  {
    name: '1720_1020',
    minHeight: 940,
    maxHeight: 1120,
    minWidth: 1715,
    maxWidth: 1720,
    version: '2020',
    orientation: 'landscape',
    size: 'full',
  },
  {
    name: '1740_1020',
    minHeight: 940,
    maxHeight: 1120,
    minWidth: 1735,
    maxWidth: 1740,
    version: '2020',
    orientation: 'landscape',
    size: 'full',
  },
]

import { authSelectors } from 'ducks/auth'
import Alert from 'elements/Alert'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import useDistributorAuthenticationStatus from 'services/useDistributorAuthenticationStatus'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  loginLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}))

const HdmConnectAccountAlert = () => {
  const classes = useStyles()
  const history = useHistory()
  const orgId = useSelector(authSelectors.getOrgId) || 0
  const { isAuthenticated, isChecking } = useDistributorAuthenticationStatus({ orgId, distributor: 'hdm' })

  const HdmLoginLink = (
    <a className={classes.loginLink} onClick={() => history.push('/shop/connect_distributor/hdm/email')}>
      connect to HDM
    </a>
  )

  return (
    <>
      {!isChecking && !isAuthenticated && (
        <Alert severity="warning">
          <div>You are not connected to HDM. Please {HdmLoginLink} to view trade pricing and credit limit.</div>
        </Alert>
      )}
    </>
  )
}

export default HdmConnectAccountAlert

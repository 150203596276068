import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined'
import { OpenSolarThemeType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import React, { useCallback } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  wrapper: { display: 'flex', alignItems: 'center', color: theme.greyDark2 },
}))

const ProductDetailTitle = ({
  goBack,
  manufacturer,
  code,
}: {
  goBack?(): void
  manufacturer: string
  code: string
}) => {
  const classes = useStyles()
  const handleClick = useCallback(() => {
    logAmplitudeEvent('generic_button_clicked', {
      source: 'hardware_detail',
      context: 'go_back_button',
    })
    goBack?.()
  }, [goBack])
  return (
    <div className={classes.wrapper}>
      {!!goBack && (
        <IconButton onClick={handleClick} color="inherit">
          <ArrowBackIosOutlinedIcon />
        </IconButton>
      )}
      <Typography variant="h4">
        {manufacturer} {code}
      </Typography>
    </div>
  )
}

export default ProductDetailTitle

import { Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup } from '@material-ui/core'
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orgSelectors } from 'ducks/orgs'
import CloseDialogButton from 'elements/button/CloseDialogButton'
import { Button, Checkbox, FormControl } from 'opensolar-ui'
import { useNotify, useTranslate } from 'ra-core'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import restClient from 'restClient'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { parseQueryStringToDictionary } from 'util/query'

const restClientInstance = restClient(window.API_ROOT + '/api')

const useStyles = makeOpenSolarStyles((theme) => ({
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  row: {
    float: 'right',
    position: 'relative',
    top: 38,
    right: 25,
  },

  header: {
    fontWeight: 400,
    fontSize: 20,
  },

  paragraph: {
    textAlign: 'center',
    lineHeight: 1.5,
  },

  logosWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '8px',
    border: `1px solid ${theme.palette.divider}`,
    margin: '10px 0px',
  },

  logo: {
    margin: '8px 12px',
  },

  logosRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  plusMore: {
    fontWeight: 800,
    textAlign: 'center',
    margin: '8px 0px',
  },

  actionsRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  btnWrapper: {
    padding: '8px',
  },

  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
}))

type PropTypes = {}

const DownloadInvoiceDataButton: React.FC<PropTypes> = (props) => {
  const org = useSelector(orgSelectors.getOrg)
  const notify = useNotify()
  const classes = useStyles()
  const location = useLocation()
  const translate = useTranslate()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [downloadType, setDownloadType] = React.useState({
    invoice_data: false,
    customer_data: false,
  })

  const { invoice_data, customer_data } = downloadType

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDownloadType({
      ...downloadType,
      [event.target.name]: event.target.checked,
    })
  }
  const isDownloadDisabled = !Object.values(downloadType).some((value) => value)

  const onClose = () => {
    setIsOpen(false)
    setDownloadType({
      invoice_data: false,
      customer_data: false,
    })
  }

  const onOpen = () => {
    setIsOpen(true)
  }

  const doDownload = () => {
    const { filter, order, page, sort } = parseQueryStringToDictionary(location.search)
    const parseFilter = filter && JSON.parse(filter)
    const status = parseFilter?.status
    const searchVal = parseFilter?.q

    const download_type = Object.entries(downloadType)
      .filter(([key, value]) => value)
      .map(([key]) => key)

    setIsLoading(true)

    for (const type of download_type) {
      let params = `?export_type=${type}`
      if (page) params += `&page=${page}`
      if (sort && order) params += `&order_by=${sort},${order.toLowerCase()}`
      if (searchVal) params += `&search=${searchVal}`
      if (status) params += `&status=${status}`

      restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${org?.id}/cashflow_invoices/download/${params}`,
      })
        .then((csvResponse) => {
          if (csvResponse?.data) {
            const url = window.URL.createObjectURL(new Blob([csvResponse.data]))
            const link = document.createElement('a')
            link.href = url
            const now = new Date()
            const nowFormatted = `${now.getMonth() + 1}${now.getDate()}-${now.getFullYear()}`
            link.setAttribute(
              'download',
              `${org?.name || 'OpenSolar'} ${
                type === 'invoice_data' ? 'Invoice Data' : 'Customer Data'
              } Report - ${nowFormatted}.csv`
            )
            document.body.appendChild(link)
            link.click()
            link.remove()
          }
          logAmplitudeEvent('cashflow_report_downloaded', { download_type, org_id: org?.id })
          onClose()
        })
        .catch((err) => {
          notify('We were unable to generate this report', 'warning')
        })
        .finally(() => {
          setIsLoading(false)
          setDownloadType({
            invoice_data: false,
            customer_data: false,
          })
        })
    }
  }

  return (
    <div className={classes.row}>
      <Button onClick={onOpen} variant="contained" color="default">
        <DownloadIcon /> {translate('Download')}
      </Button>
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>
          <CloseDialogButton onClose={onClose} />
        </DialogTitle>
        <DialogContent>
          <div className={classes.mainContent}>
            <h1 className={classes.header}>{translate('Download invoice data')}</h1>
            <p className={classes.paragraph}>
              {translate(
                'Download data from your invoices in CSV format. Filters applied will be reflected in your download. Please update your filter to change the report.'
              )}
            </p>
            <p className={classes.paragraph}>
              {translate(
                'Invoice data download format is compatible with your preferred accounting software providers.'
              )}
            </p>
            <div className={classes.logosWrapper}>
              <div className={classes.logosRow}>
                <div className={classes.logo}>
                  <img src={`${window.PUBLIC_URL}/images/quickbooks_logo.png`} alt="Quickbooks" height="30px" />
                </div>
                <div className={classes.logo}>
                  <img src={`${window.PUBLIC_URL}/images/Xero_software_logo.svg`} alt="Zero" height="30px" />
                </div>
                <div className={classes.logo}>
                  <img src={`${window.PUBLIC_URL}/images/oracle_logo.png`} alt="Oracle" height="30px" />
                </div>
                <div className={classes.logo}>
                  <img src={`${window.PUBLIC_URL}/images/MYOB_logo.png`} alt="MYOB" height="30px" />
                </div>
              </div>
              <div className={classes.plusMore}>+ more!</div>
            </div>
            <FormControl>
              <FormGroup className={classes.flexRow}>
                <FormControlLabel
                  control={<Checkbox checked={invoice_data} onChange={handleChange} name="invoice_data" />}
                  label={translate('Invoice data')}
                />
                <FormControlLabel
                  control={<Checkbox checked={customer_data} onChange={handleChange} name="customer_data" />}
                  label={translate('Customer data')}
                />
              </FormGroup>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <div className={classes.actionsRow}>
            <div className={classes.btnWrapper}>
              <Button disabled={isLoading} onClick={onClose} variant="contained" color="default">
                {translate('Cancel')}
              </Button>
            </div>
            <div className={classes.btnWrapper}>
              <Button
                disabled={isLoading || isDownloadDisabled}
                onClick={doDownload}
                variant="contained"
                color="primary"
              >
                {translate('Download')}
              </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default DownloadInvoiceDataButton

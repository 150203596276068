import { Typography } from '@material-ui/core'
import { List, ListItem } from '@mui/material'
import { OpenSolarThemeType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import Button from 'elements/button/Button'
import { ConfirmCircleIcon } from 'opensolar-ui'
import { memo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  container: {
    width: '100%',
    padding: 20,
    boxSizing: 'border-box',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '32px',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    maxWidth: '64%',
  },
  font18: {
    fontSize: '18px',
    lineHeight: '28.8px',
    textAlign: 'left',
  },
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  fontWeightBold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  checkCircleIcon: {
    color: theme.alertIcon_success,
  },
}))

const CityPlumbingOrderConfirmationPage = ({ orderId }: { orderId: string }) => {
  const classes = useStyles()
  const history = useHistory()
  const user = useSelector(authSelectors.getCurrentUser)

  useEffect(() => {
    logAmplitudeEvent('hardware_order_placed_success', {
      distributor: 'city_plumbing',
    })
  }, [])

  const onClickCTA = () => {
    history.push('/manage_orders/incoming_transfer')
  }

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <ConfirmCircleIcon width={120} height={120} color="rgb(44,157,73)" />
        <Typography className={classes.fontWeightMedium} style={{ fontSize: '32px' }}>
          {`We've received you order!`}
        </Typography>
      </div>
      <div className={classes.main}>
        <Typography className={`${classes.font18} ${classes.fontWeightBold}`} style={{ marginTop: '32px' }}>
          {`Here is your order ID: ${orderId}`}
        </Typography>
        <Typography className={`${classes.font18}`} style={{ marginTop: '35px' }}>
          {`We have sent your order to City Plumbing to process the order. A copy of your order has been sent to ${user?.email}.`}
        </Typography>
        <Typography className={`${classes.font18} ${classes.fontWeightMedium}`} style={{ marginTop: '35px' }}>
          {`What’s next?`}
          <List sx={{ listStyle: 'decimal', pl: 4, paddingTop: 0 }}>
            <ListItem sx={{ display: 'list-item', padding: 0 }}>
              <Typography className={`${classes.font18}`}>
                <span className={classes.fontWeightMedium}>Processing:</span> City Plumbing reviews your order.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item', padding: 0 }}>
              <Typography className={`${classes.font18}`}>
                <span className={classes.fontWeightMedium}>Billing:</span> City Plumbing will contact you to settle your
                payment order:
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item', padding: 0 }}>
              <Typography className={`${classes.font18}`}>
                <span className={classes.fontWeightMedium}>Delivery:</span> Your item will be shipped for delivery!
              </Typography>
            </ListItem>
          </List>
        </Typography>

        <Typography className={`${classes.font18}`} style={{ marginTop: '35px' }}>
          {`All updates will be sent to you by City Plumbing via email or text.`}
        </Typography>
        <Typography className={`${classes.font18}`} style={{ marginTop: '35px' }}>
          {`In the meantime, you can navigate to your purchase order on OpenSolar.`}
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <Button color="primary" style={{ marginTop: '50px' }} onClick={onClickCTA} fullWidth={false}>
            Review Purchase Order
          </Button>
        </div>
      </div>
    </div>
  )
}

export default memo(CityPlumbingOrderConfirmationPage)

import { makeStyles } from '@material-ui/core'
import { PeopleAltOutlined } from '@material-ui/icons'
import { authSelectors } from 'ducks/auth'
import { permissionsSelectors } from 'ducks/permissions'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import React, { useEffect, useMemo, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { OpenSolarThemeType } from 'Themes'
import { ProjectWorkflowType } from 'types/projects'
import { ExternalWorkflow } from 'types/workflows'
import { updateProjectWorkflows, urlToId } from 'util/misc'
import ProjectWorkflow from './workflow/ProjectWorkflow'
interface PropTypes {
  sharedWorkflows: ProjectWorkflowType[]
}

const restClientInstance = restClient(window.API_ROOT + '/api')
const SharedWorkflowsContent: React.FC<PropTypes> = ({ sharedWorkflows }) => {
  const form = useForm()
  const notify = useNotify()
  const translate = useTranslate()
  const project = useFormState().values
  const currOrgId = useSelector(authSelectors.getOrgId)
  const [externalWorkflows, setWorkflows] = useState<ExternalWorkflow[] | undefined>(undefined)
  const sharedWithOrg = useMemo(() => project.shared_with?.map((x) => x.org_name), [project.shared_with])
  const { allowView } = useSelector(permissionsSelectors.getProjectPermissionByKey('manage_workflow_stages'))

  useEffect(() => {
    if (sharedWorkflows && allowView) {
      let externalOrgs: number[] = []
      sharedWorkflows.forEach((x) => {
        if (!externalOrgs.includes(x.org_id)) externalOrgs.push(x.org_id)
      })
      restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${currOrgId}/workflows/?owned_by=${externalOrgs.toString()}`,
      })
        .then((response) => {
          if (response.data && !!response.data.length) {
            let newWorkflows = externalWorkflows ? [...externalWorkflows] : []
            externalOrgs.forEach((org_id) => {
              if (!!!newWorkflows.filter((x) => x.org_id === org_id).length) {
                const orgWorkflows = response.data.filter((x) => urlToId(x.org) === org_id)

                newWorkflows.push({
                  org_id: org_id,
                  selectedWorkflow: sharedWorkflows.find((x) => x.is_selected && x.org_id === org_id),
                  workflows: orgWorkflows,
                })
              }
            })
            setWorkflows(newWorkflows)
          }
        })
        .catch((error) => {
          const errMsg = typeof error === 'string' ? error : error.message || 'ra.notification.http_error'
          notify('There was an issue trying to retrieve shared workflows: ' + errMsg, 'warning')
        })
    }
  }, [])
  if (!externalWorkflows)
    return (
      <p>
        {translate('You do not have access to view the workflow and actions applied on this project by %{orgs}', {
          orgs: sharedWithOrg.toString(),
        })}
      </p>
    )
  return (
    <>
      {externalWorkflows?.map((orgWorkflows, i) => (
        <ProjectWorkflow
          selectedWorkflow={orgWorkflows.selectedWorkflow}
          workflows={orgWorkflows.workflows}
          source={'external_workflow.workflow_id'}
          orgDisplay={
            project.org_id === orgWorkflows.org_id
              ? translate('%{org_name} (Project Owner)', {
                  org_name: project.org_name,
                })
              : orgWorkflows.selectedWorkflow?.org_name
          }
          isExternalWorkflow={true}
          handleChange={(workflow) => {
            updateProjectWorkflows(orgWorkflows.selectedWorkflow, workflow, project, form, 'external_workflow')
            let updatedExternal = [...externalWorkflows]
            updatedExternal[i] = { ...orgWorkflows, selectedWorkflow: { ...workflow } }
            setWorkflows(updatedExternal)
          }}
        />
      ))}
    </>
  )
}

const useStyles = makeStyles((theme: OpenSolarThemeType) => ({
  accordion: {
    width: '100%',
    border: 'none !important',
    '& .MuiAccordionSummary-root': {
      padding: 0,
    },
    '& .MuiAccordionDetails-root': {
      display: 'block',
      padding: 8,
    },
  },
  divider: {
    marginTop: 15,
    marginBottom: 5,
  },
}))

const SharedWorkflows: React.FC<PropTypes> = ({ sharedWorkflows }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const filteredOrgList = useMemo(
    () => sharedWorkflows?.map((x) => x.org_id)?.filter((value, index, array) => array.indexOf(value) === index),
    [sharedWorkflows]
  )
  return (
    <>
      <hr className={classes.divider} />
      <AccordionCard
        className={classes.accordion}
        defaultExpanded={false}
        name={'sharedWith'}
        title={translate('Shared with') + ` (${filteredOrgList?.length})`}
        titleIcon={PeopleAltOutlined}
        content={SharedWorkflowsContent}
        contentProps={{ sharedWorkflows }}
      />
    </>
  )
}
export default SharedWorkflows

import { makeStyles } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import CheckIcon from '@material-ui/icons/Check'
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreOutlined'
import { push as pushAction } from 'connected-react-router'
import { authSelectors } from 'ducks/auth'
import CustomField from 'elements/field/CustomField'
import DependentInput from 'elements/input/DependentInput'
import { Button } from 'opensolar-ui'
import {
  AutocompleteInput,
  BooleanInput,
  ChipField,
  Edit,
  FileField,
  FileInput,
  FormDataConsumer,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  showNotification as showNotificationAction,
  SimpleForm,
  SingleFieldList,
  TextInput,
  usePermissions,
  useTranslate
} from 'react-admin'
import { connect, useSelector } from 'react-redux'
import { doNotTranslate } from 'util/misc'
import { authReload as authReloadAction } from '../../actions/authActions'
import ChipsInput from '../../elements/input/ChipsInput.tsx'
import EditorComponent from '../../elements/wysiwyg/EditorComponentWrapper'
import restClient from '../../restClient'
import { getOrgCountryCodeFromState, getOrgFromState } from '../../util/org'
import { validateOrgEdit } from '../../validations/Orgs'
import ClearableChipField from './ClearableChipField'
import FavouriteRoofTypesInput from './FavouriteRoofTypesInput'
import { getDefaultSettings } from './index'
import ProposalShareability from './ProposalShareability'
import RoundingPreferenceInput from './RoundingPreferenceInput'

const styles = {
  expansionPanel: {},
  inlineInput: { display: 'inline-block', marginRight: 20 },
  inlineInputLessTopMargin: { display: 'inline-block', marginRight: 20, marginTop: -20, marginBottom: 20 },
}

const API_URL = window.API_ROOT + '/api'
const restClientInstance = restClient(API_URL)
const enabledPartnerFeaturesList = [
  { label: 'Showcase', formDataKey: 'enable_showcase' },
  { label: 'Zone', formDataKey: 'enable_zone' },
  { label: 'Cobranding', formDataKey: 'enable_cobranding' },
  { label: 'Showcase Exclusivity', formDataKey: 'exhibit_category_exclusivity' },
  { label: 'Data Sharing', formDataKey: 'enable_data_sharing' },
]

// Hack
const get_measurement_unit_choices = (_default, translate) => {
  return [
    { id: 0, name: translate('Default') + ' (' + translate(_default) + ')' },
    { id: 1, name: translate('Metric') },
    { id: 2, name: translate('Imperial') },
  ]
}

// Deprecated, org.default_project_access could be safely removed after the permissions migration in OS 2.2.0
// const DEFAULT_PROJECT_ACCESS_CHOICES = [
//   { id: 0, name: 'Unrestricted' },
//   { id: 1, name: 'Restricted' },
// ]

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  partnerOrgBox: {
    backgroundColor: '#FAFBFC',
    padding: '16px 24px',
    borderRadius: '8px',
  },
  dataSharingContainer: {
    marginLeft: '60px',
  },
  fileInput: {
    marginBottom: '5px',
    '& label': {
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '22px',
    },
    '& [data-testid="dropzone"]': {
      backgroundColor: '#F8F8F8',
      border: '2px solid lightgrey',
    },
  },
  enabledFeaturesContainer: {
    alignItems: 'center',
    gap: '8px',
    margin: '8px 0',
  },
  enabledPartnerFeaturesHeader: {
    marginTop: '0',
  },
}))

const DraftEditorWithContent = (props) => {
  return (
    <EditorComponent
      label={props.label}
      fieldName={props.source}
      allowUploadImage={props.allowUploadImage}
      charCounterMax={props.charCounterMax}
      mentionContext={props.mentionContext ? props.mentionContext : 'org_and_project'}
      {...props}
    />
  )
}

const ExpansionPanelExhibitContent = (props) => {
  const translate = useTranslate()
  const classes = useStyles()
  const orgId = props.record && props.record.id
  return (
    <div className={classes.root}>
      <Accordion style={styles.expansionPanel} classes={{ expanded: 'ExpansionPanelExpanded' }}>
        <AccordionSummary className="AccordionSummary" expandIcon={<ExpandMoreIcon />}>
          {translate('Manage Partner Content')}
        </AccordionSummary>
        <AccordionDetails className="AccordionDetails" style={{ flexDirection: 'column' }}>
          <p>
            {translate(
              "Some partner content is available to all orgs. To access other content, please enter your partner's activation code below."
            )}
          </p>
          <CustomField
            component={TextInput}
            name="exhibit_activation_code"
            source="exhibit_activation_code"
            label="Partner Activation Code"
            {...props}
          />

          <h2>{translate('Enabled Private Partners')}</h2>

          <FormDataConsumer>
            {({ formData, ...rest }) =>
              Boolean(formData.enable_exhibit) ? (
                <ReferenceArrayField
                  label={null}
                  source="exhibit_activations"
                  reference="public_orgs"
                  optionValueField="url"
                  allowEmpty
                  {...props}
                >
                  <SingleFieldList filterFunction={(item) => item && item.enable_exhibit === true}>
                    <ChipField source="name" />
                  </SingleFieldList>
                </ReferenceArrayField>
              ) : (
                <ReferenceArrayInput
                  source="exhibit_activations"
                  name="exhibit_activations"
                  reference="public_orgs"
                  optionValueField="url"
                  {...props}
                >
                  <ClearableChipField
                    filterFunction={(item) => item && item.enable_exhibit === true}
                    source="exhibit_activations"
                    optionText="name"
                    optionValue="url"
                    allowDelete={true}
                  />
                </ReferenceArrayInput>
              )
            }
          </FormDataConsumer>
          <h2>{translate('Partner Data Sharing')}</h2>
          <p>{translate('You can choose to share your OpenSolar data with selected partners.')}</p>
          <Button
            style={{ width: 300 }}
            variant="contained"
            onClick={() => {
              const redirect = orgId ? `/data_sharing?redirect=/orgs/${orgId}` : '/'
              props.push(redirect)
            }}
          >
            <span>{translate('Manage Partner Data Sharing')}</span>
          </Button>
          <h2 style={{ marginTop: 30 }}>{translate('Account Cobranding')}</h2>
          <p>{translate('If you have available cobranding options, you can choose one from the dropdown below.')}</p>

          <ReferenceInput
            source="exhibit_cobranding"
            reference="public_orgs"
            filter={{ enable_cobranding: true, org_id: orgId }}
            sort={{ field: 'name', order: 'ASC' }}
            allowEmpty
            translateChoice={false}
          >
            <AutocompleteInput
              options={{
                placeholder: translate('Partner'),
                label: translate('Partner'),
                style: { width: 360 },
              }}
              optionText={(choice) => choice && choice.name}
              optionValue="url"
            />
          </ReferenceInput>

          <h2 style={{ marginTop: 30 }}>{translate('Account Zone')}</h2>
          <p>{translate('If you have available zone options, you can choose one from the dropdown below.')}</p>

          <ReferenceInput
            source="exhibit_zone"
            reference="public_orgs"
            filter={{ enable_zone: true, org_id: orgId }}
            sort={{ field: 'name', order: 'ASC' }}
            allowEmpty
            translateChoice={false}
          >
            <AutocompleteInput
              options={{
                placeholder: translate('Partner'),
                label: translate('Partner'),
                style: { width: 360 },
              }}
              optionText={(choice) => choice && choice.name}
              optionValue="url"
            />
          </ReferenceInput>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

const _OrgEdit = ({ accessRights: { allowEdit }, ...props }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const { loading, permissions } = usePermissions()
  const mfaEnabled = useSelector(authSelectors.getIsMfaEnabledOnOrg)
  const reusableProps = { style: { width: '100%' }, translate: false }

  return (
    <Edit {...props}>
      <SimpleForm
        hideCancelButton={true}
        validate={validateOrgEdit}
        redirect="/control"
        initialValues={getDefaultSettings()}
        disabled={!allowEdit}
      >
        {!loading && permissions?.includes('is_staff') && (
          <div className={classes.root}>
            <Accordion
              style={styles.expansionPanel}
              className="Accordion"
              classes={{ expanded: 'ExpansionPanelExpanded' }}
            >
              <AccordionSummary className="AccordionSummary" expandIcon={<ExpandMoreIcon />}>
                {translate('Administrator')} - {translate('Staff Only')}
              </AccordionSummary>
              <AccordionDetails className="AccordionDetails" style={{ flexDirection: 'column' }}>
                <CustomField
                  component={BooleanInput}
                  source="allow_sms_mfa"
                  name="allow_sms_mfa"
                  {...reusableProps}
                  {...props}
                />

                <CustomField
                  component={BooleanInput}
                  source="enable_exhibit"
                  name="enable_exhibit"
                  label={doNotTranslate('Partner Org')}
                  {...reusableProps}
                  {...props}
                />

                <FormDataConsumer>
                  {({ formData, ...rest }) =>
                    Boolean(formData.enable_exhibit) && (
                      <Box className={classes.partnerOrgBox}>
                        <CustomField
                          component={BooleanInput}
                          source="enable_showcase"
                          name="enable_showcase"
                          label={doNotTranslate('Enable Showcase')}
                          {...reusableProps}
                          {...props}
                        />

                        <CustomField
                          component={BooleanInput}
                          source="exhibit_category_exclusivity"
                          name="exhibit_category_exclusivity"
                          label={doNotTranslate('Showcase Exclusivity')}
                          labelPlacement="end"
                          style={{ width: '100%', marginLeft: '45px' }}
                          {...props}
                        />

                        <CustomField
                          component={BooleanInput}
                          source="enable_cobranding"
                          name="enable_cobranding"
                          label={doNotTranslate('Enable Cobranding')}
                          {...reusableProps}
                          {...props}
                        />

                        <CustomField
                          component={BooleanInput}
                          source="enable_zone"
                          name="enable_zone"
                          label={doNotTranslate('Enable Zone')}
                          {...reusableProps}
                          {...props}
                        />

                        {Boolean(formData.enable_zone) && (
                          <TextInput
                            fullWidth={true}
                            name="zone_title"
                            source="zone_title"
                            label={doNotTranslate('Zone Title')}
                            options={{
                              inputProps: {
                                maxLength: 12,
                              },
                            }}
                            {...props}
                          />
                        )}

                        <CustomField
                          component={BooleanInput}
                          source="enable_data_sharing"
                          name="enable_data_sharing"
                          label={doNotTranslate('Enable Data Sharing')}
                          {...reusableProps}
                          {...props}
                        />

                        <DependentInput dependsOn="enable_data_sharing" value={true}>
                          <div className={classes.dataSharingContainer}>
                            <h2>{'Search and add Partners who can access data for this Org'}</h2>
                            <CustomField
                              component={ReferenceArrayInput}
                              name="exhibit_data_shares"
                              source="exhibit_data_shares"
                              label="Organisation names"
                              reference="public_orgs"
                              optionValueField="url"
                              filter={{ enable_exhibit: true }}
                              filterToQuery={(searchText) => ({ search: searchText })}
                              {...props}
                            >
                              <ChipsInput
                                optionText="name"
                                optionValue="url"
                                options={{
                                  style: { minWidth: 256 },
                                  listStyle: { overflow: 'auto', display: 'inline-grid', maxHeight: 225 },
                                  filter: (searchText, key) => true,
                                }}
                              />
                            </CustomField>

                            <FileInput
                              source="exhibit_data_sharing_agreement_file_contents"
                              label="Partner data sharing agreement file contents"
                              placeholder={<p>{translate('Click to upload or drag & drop a file here')}</p>}
                              accept=".pdf"
                              translate={false}
                              className={classes.fileInput}
                            >
                              <FileField source="src" title="title" />
                            </FileInput>
                          </div>
                        </DependentInput>

                        <br />
                        <h2>{doNotTranslate('Assign a manufacturer to this Partner org')}</h2>
                        <CustomField
                          component={ReferenceArrayInput}
                          label={doNotTranslate('Manufacturer Name')}
                          name="manufacturers"
                          source="manufacturers"
                          reference="manufacturers"
                          optionValueField="url"
                          filterToQuery={(searchText) => ({ search: searchText })}
                        >
                          <ChipsInput
                            optionText="name"
                            optionValue="url"
                            options={{
                              style: { minWidth: 256 },
                              listStyle: { overflow: 'auto', display: 'inline-grid', maxHeight: 225 },
                              filter: (searchText, key) => true,
                            }}
                          />
                        </CustomField>
                      </Box>
                    )
                  }
                </FormDataConsumer>
                <br />

                <CustomField
                  component={BooleanInput}
                  source="enable_uk_address_lookup"
                  name="enable_uk_address_lookup"
                  style={{ width: '100%' }}
                  {...props}
                  label={doNotTranslate('Enable Paid Service for UK Address Lookup')}
                />

                <CustomField
                  component={BooleanInput}
                  source="enable_google_calendar"
                  name="enable_google_calendar"
                  style={{ width: '100%' }}
                  {...props}
                  label={doNotTranslate('Enable Google Calendar Integration')}
                />

                <FileField
                  source="exhibit_data_sharing_agreement_file_contents"
                  title="exhibit_data_sharing_agreement_file_contents"
                  {...props}
                />

                <CustomField
                  component={BooleanInput}
                  source="enable_lead_distribution"
                  name="enable_lead_distribution"
                  style={{ width: '100%' }}
                  {...props}
                  label={doNotTranslate('Enable Lead Distribution')}
                />

                <CustomField
                  component={BooleanInput}
                  source="enable_centrix_credit_check"
                  name="enable_centrix_credit_check"
                  style={{ width: '100%' }}
                  {...props}
                  label={doNotTranslate('Enable Centrix Credit Check')}
                />
                <CustomField
                  component={BooleanInput}
                  source="enable_webhooks"
                  name="enable_webhooks"
                  style={{ width: '100%' }}
                  {...props}
                  label={doNotTranslate('Enable Webhooks')}
                />
                <CustomField
                  component={BooleanInput}
                  source="enable_bespoke_proposal"
                  name="enable_bespoke_proposal"
                  style={{ width: '100%' }}
                  {...props}
                  label={doNotTranslate('Enable Bespoke Proposal')}
                />
                <CustomField
                  component={BooleanInput}
                  source="enable_outlet"
                  name="enable_outlet"
                  style={{ width: '100%' }}
                  {...props}
                  label={doNotTranslate('Enable Solar Outlet')}
                />
                <CustomField
                  component={BooleanInput}
                  source="internal"
                  name="internal"
                  style={{ width: '100%' }}
                  {...props}
                  label={doNotTranslate('Internal')}
                />
                <CustomField
                  component={BooleanInput}
                  source="bypass_user_terms"
                  name="bypass_user_terms"
                  style={{ width: '100%' }}
                  {...props}
                  label={doNotTranslate('Bypass User Terms')}
                />
              </AccordionDetails>
            </Accordion>
          </div>
        )}

        <div className={classes.root}>
          <Accordion
            className="Accordion"
            style={styles.expansionPanel}
            classes={{ expanded: 'ExpansionPanelExpanded' }}
          >
            <AccordionSummary className="AccordionSummary" expandIcon={<ExpandMoreIcon />}>
              {translate('Email Settings')}
            </AccordionSummary>
            <AccordionDetails className="AccordionDetails" style={{ flexDirection: 'column' }}>
              <CustomField
                component={TextInput}
                fullWidth={true}
                name="invitation_email_custom_subject"
                source="invitation_email_custom_subject"
                helperText="Optional"
                {...props}
              />
              <CustomField
                component={DraftEditorWithContent}
                allowUploadImage={false}
                mentionContext="invitation_without_system"
                name="invitation_email_custom_message"
                source="invitation_email_custom_message"
                charCounterMax={2200}
                {...props}
              />
              <p>{translate('Invitation Email SMTP Settings (Optional - BETA)')}</p>
              <CustomField
                component={TextInput}
                fullWidth={true}
                name="invitation_email_smtp_host"
                resource="orgs"
                source="invitation_email_smtp_host"
                label="Host"
              />
              <CustomField
                component={TextInput}
                fullWidth={true}
                name="invitation_email_smtp_port"
                resource="orgs"
                source="invitation_email_smtp_port"
                label="Port"
              />
              <CustomField
                component={TextInput}
                fullWidth={true}
                name="invitation_email_smtp_username"
                resource="orgs"
                source="invitation_email_smtp_username"
                label="Username"
                autoComplete="off"
              />
              <CustomField
                component={TextInput}
                type="password"
                fullWidth={true}
                name="invitation_email_smtp_password"
                resource="orgs"
                source="invitation_email_smtp_password"
                label="Password"
                autoComplete="new-password"
              />
            </AccordionDetails>
          </Accordion>
        </div>

        <div className={classes.root}>
          <Accordion
            style={styles.expansionPanel}
            className="Accordion"
            classes={{ expanded: 'ExpansionPanelExpanded' }}
          >
            <AccordionSummary className="AccordionSummary" expandIcon={<ExpandMoreIcon />}>
              {translate('General Preferences')}
            </AccordionSummary>
            <AccordionDetails className="AccordionDetails" style={{ flexDirection: 'column' }}>
              <CustomField
                component={SelectInput}
                name="measurement_units_override"
                source="measurement_units_override"
                resource="orgs"
                choices={get_measurement_unit_choices(props.defaultMeasurementUnits, translate)}
                translateChoice={false}
              />
              {props.loginAuthority === 'nearmap' && (
                <CustomField
                  component={BooleanInput}
                  source="enable_other_imagery"
                  name="enable_other_imagery"
                  resource="orgs"
                // label="Enable Other Imagery"
                />
              )}
              <FavouriteRoofTypesInput />
              <ProposalShareability />
              <RoundingPreferenceInput />
            </AccordionDetails>
          </Accordion>
        </div>

        {/*
        // Deprecated, org.default_project_access could be safely removed after the permissions migration in OS 2.2.0
        <div style={{ width: '100%' }}>
          <Accordion
            style={styles.expansionPanel}
            className="Accordion"
            classes={{ expanded: 'ExpansionPanelExpanded' }}
          >
            <AccordionSummary className="AccordionSummary" expandIcon={<ExpandMoreIcon />}>
              {translate('Project Access Control')}
            </AccordionSummary>
            <AccordionDetails className="AccordionDetails" style={{ flexDirection: 'column' }}>
              <p>
                {translate(
                  'Control whether your team can see all projects or only projects they are assigned to. This setting can be overridden for individual projects.'
                )}
              </p>

              <CustomField
                component={SelectInput}
                {...props}
                name="default_project_access"
                source="default_project_access"
                choices={DEFAULT_PROJECT_ACCESS_CHOICES}
              />
            </AccordionDetails>
          </Accordion>
        </div>
        */}

        <ExpansionPanelExhibitContent push={props.push} />

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            Boolean(formData.enable_exhibit) && (
              <div className={classes.root}>
                <Accordion style={styles.expansionPanel} classes={{ expanded: 'ExpansionPanelExpanded' }}>
                  <AccordionSummary className="AccordionSummary" expandIcon={<ExpandMoreIcon />}>
                    {translate('Partner Access Control')}
                  </AccordionSummary>
                  <AccordionDetails className="AccordionDetails" style={{ display: 'inline-block' }}>
                    <h2 className={classes.enabledPartnerFeaturesHeader}>{translate('enabled_partner_features')}</h2>
                    {enabledPartnerFeaturesList.map((feature) => (
                      <DependentInput dependsOn={feature.formDataKey} value={true}>
                        <Grid container className={classes.enabledFeaturesContainer}>
                          <Grid item>
                            <CheckIcon htmlColor="#66B551" />
                          </Grid>
                          <Grid item>{translate(feature.label)}</Grid>
                        </Grid>
                      </DependentInput>
                    ))}
                    <br />

                    <h2>{translate('Content Access')}</h2>
                    <p>
                      {translate(
                        'By default, all partner content is not publicly accessible by orgs. You can however make your showcase content public.'
                      )}
                    </p>
                    <br />

                    <CustomField
                      component={BooleanInput}
                      name="exhibit_is_public"
                      source="exhibit_is_public"
                      label={translate('Showcase is Public')}
                      {...rest}
                    />
                    <br />

                    <h2>{translate('Search and add Orgs who can access your partner content')}</h2>
                    <CustomField
                      component={ReferenceArrayInput}
                      name="exhibit_activations"
                      source="exhibit_activations"
                      label={translate('Orgs that are approved for your partner content')}
                      reference="public_orgs"
                      optionValueField="url"
                      filterToQuery={(searchText) => ({ search: searchText })}
                      {...rest}
                    >
                      <ChipsInput
                        optionText="name"
                        optionValue="url"
                        options={{
                          style: { minWidth: 256 },
                          listStyle: { overflow: 'auto', display: 'inline-grid', maxHeight: 225 },
                          filter: (searchText, key) => true,
                        }}
                      />
                    </CustomField>
                    <br />

                    <h2>{translate('Private Activation Code')}</h2>
                    <p>
                      {translate(
                        'Enter one or more activation codes which can be shared with other Orgs to automatically grant access to your partner content. Separate multiple codes with commas without spaces.'
                      )}
                    </p>
                    <TextInput
                      source="exhibit_activation_codes"
                      label="Activation Code"
                      style={{ width: 256 }}
                      {...rest}
                    />
                  </AccordionDetails>
                </Accordion>
              </div>
            )
          }
        </FormDataConsumer>
        <div className={classes.root}>
          {props.loginAuthority === 'nearmap' && (
            <Accordion
              style={styles.expansionPanel}
              className="Accordion"
              classes={{ expanded: 'ExpansionPanelExpanded' }}
            >
              <AccordionSummary className="AccordionSummary" expandIcon={<ExpandMoreIcon />}>
                {translate('Nearmap on OpenSolar')}
              </AccordionSummary>

              <AccordionDetails className="AccordionDetails" style={{ display: 'inline-block' }}>
                <Button
                  style={styles.button}
                  onClick={() => {
                    console.log('downgrade')

                    restClientInstance('CUSTOM_POST', 'nearmap_downgrade', {
                      // url: '/orgs/' + props.org.id + '/nearmap/downgrade/',
                      url: 'nearmap/downgrade/',
                    })
                      .then((response) => {
                        props.showNotification('Downgrade complete.')
                        props.authReload()

                        //@TODO: Clear cookie, remove nearmap flags from redux for org/role/user
                      })
                      .catch((error) => {
                        props.showNotification(error.message, 'warning')
                      })
                  }}
                >
                  <span style={{ textTransform: 'none' }}>{translate('Downgrade from Nearmap on OpenSolar')}</span>
                </Button>
              </AccordionDetails>
            </Accordion>
          )}
        </div>
        <div style={{ width: '100%', marginBottom: 30 }}>
          {props.loginAuthority !== 'nearmap' && (
            <Accordion
              style={styles.expansionPanel}
              className="Accordion"
              classes={{ expanded: 'ExpansionPanelExpanded' }}
            >
              <AccordionSummary className="AccordionSummary" expandIcon={<ExpandMoreIcon />}>
                {translate('Nearmap on OpenSolar')}
              </AccordionSummary>

              <AccordionDetails className="AccordionDetails" style={{ display: 'inline-block' }}>
                <p>
                  {translate('Ensure your OpenSolar Account name matches your Nearmap account name then continue.')}
                </p>
                <Button
                  style={styles.button}
                  variant="contained"
                  disabled={mfaEnabled}
                  onClick={() => {
                    window.location.href = window.API_BASE_URL + 'nearmap/login/'
                  }}
                >
                  <span style={{ textTransform: 'none' }}>{translate('Upgrade to Nearmap on OpenSolar')}</span>
                </Button>
                {mfaEnabled ? (
                  <div class="hint-box">{translate("Can't upgrade to Nearmap while MFA is enabled.")}</div>
                ) : null}
              </AccordionDetails>
            </Accordion>
          )}
        </div>
      </SimpleForm>
    </Edit>
  )
}

const mapStateToProps = (state) => {
  var country_iso2 = getOrgCountryCodeFromState(state)
  var creditCardSupportInThisCountry = ['AU', 'US', 'NZ'].indexOf(country_iso2) !== -1
  var org = getOrgFromState(state)
  var loginAuthority = org?.external_account_id ? 'nearmap' : null
  var defaultMeasurementUnits = country_iso2 === 'US' ? 'Imperial' : 'Metric'
  return { country_iso2, creditCardSupportInThisCountry, loginAuthority, org, defaultMeasurementUnits }
}

const mapDispatchToProps = { showNotification: showNotificationAction, authReload: authReloadAction, push: pushAction }

export const OrgEdit = connect(mapStateToProps, mapDispatchToProps)(_OrgEdit)

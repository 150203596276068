import { Card, Grid, makeStyles, Paper } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import Alert from 'elements/Alert'
import ChipsInput from 'elements/input/ChipsInput'
import DependentInput from 'elements/input/DependentInput'
import { DynamicIconInput } from 'elements/input/DynamicIconInput'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import React, { useEffect, useMemo } from 'react'
import {
  AutocompleteInput,
  BooleanInput,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useTranslate,
} from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { FORM_TYPE_CHOICES, PLACEMENT_CHOICES } from './constants'
import { CustomFormRecordType, FormPlacementType } from './types'

type PropTypes = {
  record?: CustomFormRecordType
}

const useStyles = makeStyles({
  placement: {
    padding: 20,
    '&:first-child': {
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    },
    '&:last-child': {
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
    },
  },
  placementRow: {
    display: 'flex',
    flexdirection: 'row',
  },
})

const CustomFormCRUDFormContent: React.FC<PropTypes> = (props) => {
  const form = useForm()
  const formState = useFormState() //useform doesn't reliably cause re-redners, and useFormState() doesn't let you change values so we need both
  const translate = useTranslate()
  const isStaff = useSelector(authSelectors.getIsStaff)
  const type = formState.values.type
  const placements = formState.values.placements
  const classes = useStyles()

  // Convert configuration from object to string on load
  useEffect(() => {
    if (typeof form.getState().values?.configuration === 'object') {
      const formatted = form.getState().values?.configuration
        ? JSON.stringify(form.getState().values?.configuration, null, '\t')
        : ''
      form.change('configuration', formatted)
    }
  }, [form.getState().values?.configuration])

  // Reformat JSON on demand
  const formatJSON = () => {
    let currentVal = form.getState().values.configuration
    if (!validateJSON(currentVal)) {
      let raw = currentVal === undefined ? {} : JSON.parse(currentVal)
      let formatted = JSON.stringify(raw, null, '\t')

      form.change('configuration', formatted)
    }
  }

  const validateJSON = (value) => {
    if (!value) return undefined
    try {
      JSON.parse(value)
      return undefined
    } catch (ex) {
      return 'Invalid JSON'
    }
  }

  const locationsForType = useMemo(() => {
    return PLACEMENT_CHOICES.filter((c) => !c.types || c.types.includes(type))
  }, [type])

  const addPlacement = () => {
    const locations = locationsForType
    let val = form.getState().values.placements ? [...form.getState().values.placements] : []
    val.push({
      priority: null,
      form_location: locations.length ? locations[0].id : undefined,
      id: undefined,
      icon: undefined,
      view_data: {},
    })
    form.change('placements', val)
  }

  const removePlacement = (i: number) => {
    let val = [...form.getState().values.placements]
    val.splice(i, 1)
    form.change('placements', val)
  }

  // Add a placement as soon as type is selected
  useEffect(() => {
    const placements = form.getState().values.placements
    if (type && !placements?.length) {
      addPlacement()
    }
  }, [type])

  // Correct placement locations when type changes
  useEffect(
    () =>
      placements?.forEach((placement, i) => {
        if (!locationsForType.find((l) => l.id === placement.form_location)) {
          form.change(`placements[${i}].form_location`, locationsForType[0].id)
        }
      }),
    [locationsForType]
  )

  if (!isStaff && props?.record?.is_public) {
    return (
      <div>
        <p>You do not have adequate permissions to edit this record</p>
      </div>
    )
  }

  return (
    <div>
      <TextInput source="title" label="Title" fullWidth validate={[required()]} />
      {isStaff && (
        <Card style={{ margin: 2, padding: 10 }}>
          <span>
            <span>
              <SelectInput
                label="Type"
                source="type"
                choices={FORM_TYPE_CHOICES}
                fullWidth={true}
                optionText="label"
                validate={[required()]}
              />
            </span>
            <span>
              <DependentInput dependsOn="parent_form" value={null}>
                <BooleanInput source="is_public" label="Make Public" />
                <span className="small">
                  When the form is still being edited and not yet ready for use, leave "Make Public" as False to ensure
                  draft forms are not being used by orgs
                </span>
              </DependentInput>
            </span>
          </span>
          <DependentInput dependsOn="is_public" value={true}>
            <>
              {/* @ts-ignore */}
              <ReferenceArrayInput
                label="Whitelisted Countries"
                reference="countries"
                source="countries_include"
                fullWidth
                style={{ width: '100%' }}
                disabled={!form.getState().values.is_public}
              >
                <ChipsInput optionText="name" optionValue="url" source="countries_include" />
              </ReferenceArrayInput>
              {/* @ts-ignore - the list of required props for this React admin array is very long and they aren't actually required */}
              <ReferenceArrayInput
                label="Blacklisted Countries"
                reference="countries"
                source="countries_exclude"
                fullWidth
                style={{ width: '100%' }}
              >
                <ChipsInput optionText="name" optionValue="url" source="countries_exclude" />
              </ReferenceArrayInput>

              {/* @ts-ignore */}
              <ReferenceArrayInput
                label="Disabled Orgs"
                reference="orgs"
                source="orgs_exclude"
                fullWidth
                style={{ width: '100%' }}
              >
                <ChipsInput optionText="name" optionValue="url" source="orgs_exclude" />
              </ReferenceArrayInput>
            </>
          </DependentInput>
          {/* @ts-ignore - the list of required props for this React admin array is very long and they aren't actually required */}
        </Card>
      )}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
        <h2>{translate('Form Placements')}</h2>
        <ProUXButton type="secondary" label="Add Form to UI" onClick={addPlacement} />
      </div>
      <p>
        {translate(
          "Use the section below to decide where this custom form should live in the app. If you'd like it to be visible in multiple places you can click the 'Add Form to UI' button to add another row."
        )}
      </p>
      <div>
        {placements?.map((placement: FormPlacementType, i: number) => {
          const placementInfo = PLACEMENT_CHOICES.find((c) => c.id === placement.form_location)
          return (
            <Paper key={placement.id} className={classes.placement} square={true}>
              <Grid container spacing={2} className={classes.placementRow}>
                <Grid item xs={4}>
                  <SelectInput
                    source={`placements[${i}].form_location`}
                    label="Form Placement"
                    choices={locationsForType}
                    fullWidth
                    optionText="label"
                    style={{ width: '100%' }}
                  />
                  {isStaff && (
                    <BooleanInput
                      name={`placements[${i}].staff_only`}
                      source={`placements[${i}].staff_only`}
                      label="Staff Only"
                    />
                  )}
                </Grid>
                <Grid item xs={4}>
                  {placementInfo?.contract && (
                    <>
                      <ReferenceInput
                        name={`placements[${i}].contract_template_id`}
                        source={`placements[${i}].contract_template_id`}
                        label="Select a Contract Template"
                        reference={formState.values?.is_public ? 'global_contracts' : 'contracts'}
                        optionValueField="id"
                        sort={{ field: 'title', order: 'ASC' }}
                        allowEmpty
                        fullWidth
                      >
                        <AutocompleteInput
                          optionText="title"
                          optionValue="id"
                          source={`placements[${i}].contract_template_id`}
                          showSpinnerWhenRefreshing={true}
                          options={{ fullWidth: true }}
                          /* Hack to ensure some choices don't get mysteriously dropped */
                          allowDuplicates={true}
                        />
                      </ReferenceInput>
                    </>
                  )}
                  {placementInfo?.doc && (
                    <ReferenceInput
                      name={`placements[${i}].document_template_id`}
                      source={`placements[${i}].document_template_id`}
                      label="Select a Document Template"
                      reference={
                        formState.values?.is_public ? 'global_document_templates' : 'all_available_document_templates'
                      }
                      optionValueField="id"
                      sort={{ field: 'title', order: 'ASC' }}
                      allowEmpty
                      fullWidth
                    >
                      <AutocompleteInput
                        sortChoices={true}
                        source={`placements[${i}].document_template_id`}
                        optionText="title"
                        optionValue="id"
                        options={{ fullWidth: true }}
                        showSpinnerWhenRefreshing={true}
                      />
                    </ReferenceInput>
                  )}
                  {placementInfo?.panel && (
                    <>
                      <DynamicIconInput
                        name={`placements[${i}].icon`}
                        source={`placements[${i}].icon`}
                        label="Accordion Icon"
                        fullWidth={true}
                        style={{ width: '100%' }}
                      />
                      <BooleanInput
                        name={`placements[${i}].start_open`}
                        source={`placements[${i}].start_open`}
                        label="Panel open by default"
                      />
                    </>
                  )}
                  {placementInfo?.form && (
                    <BooleanInput
                      name={`placements[${i}].view_data.hide_during_create`}
                      source={`placements[${i}].view_data.hide_during_create`}
                      label="Hide during creation"
                    />
                  )}
                </Grid>
                <Grid item xs={2}>
                  <NumberInput
                    source={`placements[${i}].priority`}
                    label="Priority (Optional)"
                    fullWidth
                    style={{ width: '100%' }}
                    helperText="A lower priority value means the form will appear in a more prominent part of the screen."
                  />
                </Grid>
                <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end', alignSelf: 'center' }}>
                  <ProUXButton type="secondary" label="Delete" onClick={() => removePlacement(i)} />
                </Grid>
              </Grid>
            </Paper>
          )
        })}
      </div>
      {isStaff && (
        <>
          <div style={{ marginTop: '40px' }}>
            <DependentInput dependsOn="is_public" value={true}>
              <Alert severity="warning">
                <p className="small">This form is public. Please use caution when editing</p>
              </Alert>
            </DependentInput>
            <div style={{ marginTop: '20px' }}>
              <span style={{ marginRight: '20px' }}>
                <ProUXButton type="secondary" label="Beautify JSON" onClick={formatJSON} />
              </span>
            </div>
            <TextInput
              source="configuration"
              fullWidth
              validate={validateJSON}
              multiline={true}
              rows={30}
              InputProps={{
                classes: { input: 'code-block' },
              }}
            />
          </div>
          <BooleanInput source="is_archived" label="Archive Form" />
        </>
      )}
    </div>
  )
}

export default CustomFormCRUDFormContent

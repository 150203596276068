import { Add, DeleteOutlined } from '@material-ui/icons'
import { CurrencySymbolContext } from 'contexts/CurrencySymbolContext'
import { IconButton } from 'opensolar-ui'
import { Button } from 'ra-ui-materialui'
import { FC, useContext } from 'react'
import { NumberInput, SelectInput } from 'react-admin'
import { useForm } from 'react-final-form'
import { ADJUSTMENT_TO_OPTIONS, SINGLE_TIER_OPTIONS, UNIT_OPTIONS } from 'resources/utilityTariffs/common'
import { tableStyles } from 'resources/utilityTariffs/styles'
import { PeriodTier } from './types'

type PropsType = {
  source: string
  handleAddTier: () => void
  isLast: boolean
  tierSource: string
  tierIndex: number
  disabled?: boolean
}

const TierQuantityField: FC<PropsType> = ({ source, handleAddTier, isLast, tierSource, tierIndex, disabled }) => {
  const classes = tableStyles()
  return (
    <div className={classes.midInputContainer}>
      {!SINGLE_TIER_OPTIONS.includes(source) && (
        <>
          {isLast ? (
            <Button
              onClick={handleAddTier}
              startIcon={<Add />}
              label={'Add Tier'}
              hideLabelAt={null}
              variant="contained"
              color="default"
              fullWidth
              disabled={disabled}
              className={classes.addTierBtn}
            />
          ) : (
            <NumberInput
              label=""
              source={`${tierSource}.${tierIndex}.limit`}
              variant="outlined"
              options={{
                InputLabelProps: {
                  shrink: false,
                },
              }}
              disabled={disabled}
              className={classes.rateInput}
            />
          )}
        </>
      )}
    </div>
  )
}

type TiersProps = {
  tiers: PeriodTier[]
  source: string
  tierSource: string
  adjustment: boolean
  disabled?: boolean
}

const RatePeriodTiers: FC<TiersProps> = ({ tiers, source, tierSource, adjustment, disabled }) => {
  const form = useForm()
  const classes = tableStyles()
  const sourceName = source.replace('tariffData.', '')
  const currencySymbol = useContext(CurrencySymbolContext)
  const tiered = tierSource.includes('blocks')
  const handleAddTier = () => {
    const defaultUnits = !!tiers.length ? tiers[tiers.length - 1].units : null
    form.mutators.updateField(tierSource, [...tiers, { value: 0, units: defaultUnits, limit: 0 }])
  }
  const handleDeleteTier = (index) => {
    let currList = [...tiers]
    currList.splice(index, 1)
    form.mutators.updateField(tierSource, currList)
  }
  return (
    <>
      {tiers?.map((rateTier, i) => {
        const updateSource = tiered ? `${tierSource}.${i}` : tierSource
        return (
          <div className={classes.periodTier}>
            <div className={classes.tierInputWithLabel}>
              {tiers?.length > 1 && <span className={classes.tierLabel}>Tier {i + 1}</span>}
              <NumberInput
                label=""
                startAdornment={!adjustment ? currencySymbol : undefined}
                endAdornment={adjustment ? '%' : undefined}
                source={`${updateSource}.value`}
                className={classes.rateInput}
                variant="outlined"
                options={{
                  InputLabelProps: {
                    shrink: false,
                  },
                }}
                disabled={disabled}
              />
            </div>
            {adjustment ? (
              <>
                <div className={classes.adjustmentContainer}>
                  <SelectInput
                    label=""
                    disabled={disabled}
                    source={`${updateSource}.adjustment_to`}
                    className={classes.rateInput}
                    variant="outlined"
                    options={{
                      InputLabelProps: {
                        shrink: false,
                      },
                    }}
                    optionText={(record: any) => {
                      return `${currencySymbol}/${record?.name}`
                    }}
                    fullWidth
                    choices={ADJUSTMENT_TO_OPTIONS}
                  />
                </div>
              </>
            ) : (
              <>
                <TierQuantityField
                  handleAddTier={handleAddTier}
                  source={sourceName}
                  isLast={i === tiers?.length - 1}
                  tierIndex={i}
                  tierSource={tierSource}
                  disabled={disabled}
                />
                <SelectInput
                  label=""
                  source={`${updateSource}.units`}
                  className={`${classes.rateInput} ${classes.tierInput}`}
                  variant="outlined"
                  options={{
                    InputLabelProps: {
                      shrink: false,
                    },
                  }}
                  optionText={(record: any) => {
                    return `${currencySymbol}/${record?.name}`
                  }}
                  disabled={disabled}
                  fullWidth
                  choices={UNIT_OPTIONS[sourceName]}
                />
              </>
            )}
            <div className={classes.deleteContainer}>
              {tiers?.length > 1 && (
                <IconButton
                  disabled={disabled}
                  onClick={() => {
                    handleDeleteTier(i)
                  }}
                  className={classes.deleteTier}
                >
                  <DeleteOutlined />
                </IconButton>
              )}
            </div>
          </div>
        )
      })}
    </>
  )
}
export default RatePeriodTiers

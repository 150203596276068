import type { SSOFlowConfigType } from '../sso/types'

// userPassRedirectUri is injected through the SDK login config
export const SALESFORCE_SDK_SSO_CONFIG: SSOFlowConfigType = {
  id: 'salesforce_sdk',
  type: 'sso',
  displayName: 'SalesForce SDK',
  name: 'salesforce_sdk',
  partner_id: 'salesforce_sdk',
  brandingLogo: 'opensolar-logo-horizontal.svg',
  logoUrl: 'opensolar-logo-horizontal.svg',
  authSuccessRedirectPath: '/sso-login-complete',
}

import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import Tooltip from 'elements/tooltip/Tooltip'
import { IconButton } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'
import { useComponentActionStyles } from './styles'

export const RemoveComponentButton = ({ onClick }) => {
  const classes = useComponentActionStyles()
  const translate = useTranslate()
  return (
    <Tooltip title={translate('Remove')}>
      <IconButton className={classes.button} onClick={onClick}>
        {<DeleteIcon className={classes.icon} />}
      </IconButton>
    </Tooltip>
  )
}

import DialogTitle from '@material-ui/core/DialogTitle'
import { Button, Dialog, DialogCloseButton, DialogContent, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { FC } from 'react'
import Confetti from 'react-confetti'

const StyledHeader = styled(DialogTitle, { name: 'StyledHeader' })({
  display: 'flex',
  justifyContent: 'flex-end',
  borderBottom: 'none',
})

const ContentWrapper = styled(DialogContent, { name: 'ContentWrapper' })({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  padding: '8px 30px',
})

const Header = styled('h1', { name: 'Header' })({
  fontSize: 18,
  marginTop: 0,
})

const Subheader = styled('p', { name: 'Subheader' })({
  marginTop: 0,
  maxWidth: 450,
  lineHeight: '20px',
  marginBottom: 20,
})

const ActionBtn = styled(Button, { name: 'ActionBtn' })({
  margin: '20px 0',
})

const ModalPreviewContainer = styled('div', { name: 'ModalPreviewContainer' })({
  width: '100%',
  borderRadius: 4,
  overflow: 'hidden',
  pointerEvents: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: '0px 0px 10px 0px rgb(0 0 0 / 25%)',
})

interface PropTypes {
  open: boolean
  endDialog: boolean
  onClose: () => void
  handleAction: () => void
  headerText: string
  subText: string
  actionLabel: string
  imgSrc?: string
}

const BannerDialog: FC<PropTypes> = ({
  open,
  endDialog,
  onClose,
  handleAction,
  headerText,
  subText,
  actionLabel,
  imgSrc,
}) => {
  const translate = useTranslate()
  return (
    <Dialog open={open} disableScrollLock={true}>
      {open && endDialog && <Confetti width={510} height={250} numberOfPieces={250} recycle={false} />}
      <StyledHeader>
        <DialogCloseButton onClick={onClose} />
      </StyledHeader>
      <ContentWrapper>
        {headerText && <Header>{translate(headerText)}</Header>}
        {subText && <Subheader>{translate(subText)}</Subheader>}
        {imgSrc && (
          <ModalPreviewContainer>
            <img
              src={imgSrc}
              alt="Preview"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                pointerEvents: 'none',
              }}
            />
          </ModalPreviewContainer>
        )}
        {actionLabel && (
          <ActionBtn onClick={handleAction} variant="contained">
            {translate(actionLabel)}
          </ActionBtn>
        )}
      </ContentWrapper>
    </Dialog>
  )
}
export default BannerDialog

import { Theme, useMediaQuery } from '@material-ui/core'
import SelectableCard from 'elements/SelectableCard'
import { Box, Button } from 'opensolar-ui'
import { useState } from 'react'
import { useTranslate } from 'react-admin'
import InverterDesignAssistantContainer from '../InverterDesignAssistantContainer'
import { INVERTER_SELECTOR_MODE_NODE_KEY, INVERTER_SET_AUTO_SELECTOR_NODE_KEY } from '../constants'
import {
  InverterDesignAssistantFlowNodeComponentStandardPropsType,
  InverterTypeSelectionKeyType,
  InverterTypeSelectionType,
} from '../types'

const INVERTER_SELECTIONS: InverterTypeSelectionType[] = [
  {
    description:
      'Great for maximizing system output for designs that experience partial shading and/or systems with multiple panel orientations.',
    key: 'microinverter',
    title: 'Microinverter',
  },
  {
    description:
      'Great for maximizing system output for designs that experience partial shading and/or systems with multiple panel orientations.',
    key: 'power_optimized',
    title: 'Power Optimised',
  },
  {
    description:
      'Offers a cost-effective solution and requires less components to install. Production of the entire array of panels can be limited by worst performing panel in the array, such as from a shaded panel',
    key: 'string',
    title: 'String',
  },
  {
    description: 'Perfect for new systems that include both solar plus battery storage.',
    key: 'hybrid',
    title: 'Hybrid (Battery ready)',
  },
]

interface InverterTypeSelectionProps extends InverterDesignAssistantFlowNodeComponentStandardPropsType {}

const InverterTypeSelectionNode: React.FC<InverterTypeSelectionProps> = ({ flowController, flowStore }) => {
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const translate = useTranslate()
  const [selectedType, setSelectedType] = useState<InverterTypeSelectionKeyType | undefined>(flowStore.type)

  // const AdBanner = useAdBanner({
  //   formats: ['leaderboard'],
  //   placement: 'inverter-wizard', // TODO: marketing team to provide the correct placement
  //   requiredWindowSize: { width: 960, height: 800 },
  // })

  const handleBack = () => {
    flowController.goTo({
      title: 'Add Inverter',
      currentNodeKey: INVERTER_SELECTOR_MODE_NODE_KEY,
      options: {},
    })
  }

  const handleSelect = (key: InverterTypeSelectionKeyType) => {
    setSelectedType(key)
    flowController.setFlowStore((state) => ({
      ...state,
      type: key,
    }))
  }

  const handleNext = () => {
    flowController.goTo({
      title: 'Select Inverter',
      currentNodeKey: INVERTER_SET_AUTO_SELECTOR_NODE_KEY,
      options: {},
    })
  }

  return (
    <InverterDesignAssistantContainer title="Which type of inverter aligns best with your solar system?">
      <Box display="flex" gridGap={16} flexWrap={isSmallScreen ? 'wrap' : 'nowrap'}>
        {INVERTER_SELECTIONS.map(({ description, key, title }) => (
          <SelectableCard
            cardKey={key}
            description={description}
            key={key}
            isSelected={selectedType === key}
            onSelect={(k) => handleSelect(k as InverterTypeSelectionKeyType)}
            title={title}
            textAlign="center"
          />
        ))}
      </Box>
      <Box display="flex" gridColumnGap={12} justifyContent="flex-end" marginTop="8px">
        <Button color="default" onClick={handleBack} variant="contained">
          {translate('Back')}
        </Button>
        <Button color="primary" disabled={!selectedType} onClick={handleNext} variant="contained">
          {translate('Next')}
        </Button>
      </Box>
    </InverterDesignAssistantContainer>
  )
}

export default InverterTypeSelectionNode

import React, { FC, ReactNode } from 'react'
import { useUiLibraryStyles } from './styles'

type FeatureGridProps = {
  component: FC<any>
  colHeaders?: { title?: ReactNode }[]
  rowHeaders?: { title?: ReactNode }[]
  cols: PropMap[]
  rows: (RowType & PropMap)[]
  propsGen?: PropsGen
}

type RowType = { _type?: 'heading'; _title?: string }

type PropsGen = (row: PropMap, col: PropMap, lastHeader: PropMap) => PropMap
type PropMap = { [key: string]: unknown }

export const FeatureGrid: FC<FeatureGridProps> = ({ component, cols, rows, propsGen, colHeaders, rowHeaders }) => {
  const classes = useUiLibraryStyles()

  const buildComponent = (component: FC, row: PropMap, col: PropMap, lastHeader: PropMap, propsGen?: PropsGen) => {
    const props = propsGen ? propsGen(row, col, lastHeader) : { ...row, ...col, ...lastHeader }
    return React.createElement(component, props)
  }
  const generateHeader = (props: PropMap) => {
    let parts: string[] = []
    for (let key in props) {
      parts.push(`${key}="${props[key]}"`)
    }
    return <span className="code-block small">{parts.join(' ')}</span>
  }

  let lastHeader: PropMap = {}

  return (
    <div className={classes.featGrid}>
      {colHeaders && (
        <div key={-1} className={classes.featGrid_row}>
          {
            <>
              {rowHeaders && <div key={-1} className={classes.featGrid_header + ' ' + classes.featGrid_cell} />}
              {[
                cols.map((col, index) => {
                  return (
                    <div key={index} className={classes.featGrid_header + ' ' + classes.featGrid_cell}>
                      {colHeaders[index]?.title || generateHeader(cols[index])}
                    </div>
                  )
                }),
              ]}
            </>
          }
        </div>
      )}
      {rows.map((rowData, index) => {
        const { _type, _title, ...row } = rowData
        if (_type === 'heading') {
          lastHeader = row
          return (
            <div key={index} className={classes.featGrid_row}>
              <td key={-1} className={classes.featGrid_cell + ' ' + classes.featGrid_subheader} colSpan={200}>
                {_title ? <span style={{ paddingRight: 20 }}>{_title}</span> : generateHeader(row)}
              </td>
            </div>
          )
        }
        return (
          <div key={index} className={classes.featGrid_row}>
            {rowHeaders && (
              <div key={-1} className={classes.featGrid_header + ' ' + classes.featGrid_cell}>
                {rowHeaders[index]?.title || generateHeader(row)}
              </div>
            )}
            {cols.map((col, index) => (
              <div key={index} className={classes.featGrid_cell}>
                {buildComponent(component, row, col, lastHeader, propsGen)}
              </div>
            ))}
          </div>
        )
      })}
    </div>
  )
}

import { useDataProvider } from 'ra-core'
import { useCallback, useLayoutEffect, useState } from 'react'
import type { Transfer } from 'resources/inventoryTransfer/type'

type OrderDataResponse = {
  loadingOrder: boolean
  loadOrderData: () => Promise<void>
  loadedOrderData: Transfer | undefined
}

const useGetOrderDataByTransfer = ({ transferId, allowReview }): OrderDataResponse => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [loadedData, setLoadedData] = useState<Transfer>()

  const dataProvider = useDataProvider()
  const loadOrderData = useCallback(async () => {
    setIsLoading(true)
    try {
      const loadedData = await dataProvider.CUSTOM_GET('inventory_transfers', {
        url: `inventory_transfers/${transferId}/`,
      })
      setLoadedData(loadedData?.data)
    } catch (error) {
      console.error('Failed to load order data:', error)
    }
    setIsLoading(false)
  }, [])

  useLayoutEffect(() => {
    if (!allowReview) return
    if (transferId) loadOrderData()
  }, [transferId, allowReview])

  return {
    loadingOrder: isLoading,
    loadOrderData,
    loadedOrderData: loadedData,
  }
}

export default useGetOrderDataByTransfer

import Typography from '@material-ui/core/Typography'
import { useSystemName } from 'Designer/hooks/useSystemName'
import React from 'react'

const BomDrawerHeader = ({ systemUuid }: { systemUuid: string | undefined }) => {
  const system = systemUuid && window.editor.objectByUuid(systemUuid)
  const systemName = useSystemName(system)

  return (
    <div>
      <Typography variant="subtitle1">Bill of Materials: {systemName}</Typography>
    </div>
  )
}

export default BomDrawerHeader

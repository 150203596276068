import { Grid, Typography } from '@material-ui/core'
import CustomField from 'elements/field/CustomField'
import DateInput from 'elements/input/DateInput'
import DependentInput from 'elements/input/DependentInput'
import InfoTooltip from 'elements/tooltip/InfoTooltip'
import { ComponentVersionsContext } from 'opensolar-ui'
import { InventoryLocationType } from 'pages/inventory/location/type'
import { useContext } from 'react'
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
} from 'react-admin'
import { useFormState } from 'react-final-form'
import { dateParser } from 'util/misc'
import { DISTRIBUTOR_ORDER_STATUS_OPTIONS, TRANSFER_ISSUED_REASON_OPTIONS, TRANSFER_STATUS_OPTIONS } from './constants'
import WizardDestinationInput from './fields/WizardDestinationInput'
import type { TransferFormIntentionType, TransferFormType } from './type'

/**
 * @intention: some fields may disabled and some special fields may applied for certain intention
 *
 * @transferType: "incoming" | "outgoing" showing different fields based on the type
 * **/
const InventoryTransferFormCore = ({
  intention,
  transferType,
  warehouses,
  action,
}: {
  intention: TransferFormIntentionType
  transferType: TransferFormType
  warehouses?: InventoryLocationType[]
  action?: string
}) => {
  const date = new Date()
  const currentDate = date.toLocaleDateString('en')
  const installationSiteWarehouse = warehouses?.find((warehouse) => warehouse.name === 'Installation Site')
  const primaryWarehouse = warehouses?.find((warehouse) => warehouse.name === 'Primary Warehouse')
  const formState = useFormState()
  const { values } = formState
  const { date_picker } = useContext(ComponentVersionsContext)

  if (transferType === 'incoming') {
    return (
      <Grid container xs={12} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Order information</Typography>
        </Grid>
        <Grid item xs={4}>
          {values?.order_id && (
            <TextInput
              variant={'outlined'}
              name={'order_id'}
              disabled={!!values?.order_id}
              source={'order_id'}
              label={'PO/ID number'}
              fullWidth
            />
          )}
          {!values?.order_id && (
            <TextInput
              variant={'outlined'}
              name={'order_number'}
              disabled={intention === 'accept_po' || intention === 'view_po'}
              source={'order_number'}
              label={'PO/ID number'}
              fullWidth
            />
          )}
        </Grid>
        <Grid item xs={4}>
          {!values?.distributor && (
            <TextInput
              source={'supplier'}
              name={'supplier'}
              variant={'outlined'}
              disabled={intention === 'accept_po' || intention === 'view_po'}
              label={'Supplier'}
              defaultValue={''}
              fullWidth
            />
          )}
          {values?.distributor && (
            <TextInput
              source={'distributor'}
              name={'distributor'}
              variant={'outlined'}
              disabled={intention === 'accept_po' || intention === 'view_po' || !!values?.distributor}
              label={'Supplier'}
              defaultValue={''}
              format={(value) => {
                if (value === 'outlet') {
                  return 'Solar Outlet'
                }
                if (value === 'city_plumbing') {
                  return 'City Plumbing'
                }
                if (value === 'segen') {
                  return 'Segen'
                }
                if (value === 'hdm') {
                  return 'HDM'
                }
              }}
              fullWidth
            />
          )}
        </Grid>
        <Grid container item xs={3} alignItems={date_picker ? 'center' : 'stretch'}>
          <Grid item xs={3}>
            <CustomField
              name={'transfer_date'}
              source={'transfer_date'}
              parse={dateParser}
              disabled={intention === 'accept_po' || intention === 'view_po'}
              inputVariant={'outlined'}
              label={'Estimated Delivery date'}
              component={DateInput}
              required={true}
              options={date_picker ? { format: 'MM/DD/YYYY' } : {}}
            />
          </Grid>
        </Grid>
        {intention !== 'generic' && (
          <Grid container item xs={1} justify="center" alignItems="center">
            <Grid item xs={1}>
              <div style={date_picker ? { position: 'relative', top: 15 } : {}}>
                <InfoTooltip
                  severity="error"
                  title="Unable to fetch estimated delivery date, set manually or leave blank"
                />
              </div>
            </Grid>
          </Grid>
        )}
        <Grid item xs={4}>
          <SelectInput
            variant={'outlined'}
            disabled={intention === 'accept_po' || intention === 'view_po'}
            source={'status'}
            label={'Status'}
            choices={DISTRIBUTOR_ORDER_STATUS_OPTIONS(values.distributor)}
            fullWidth
            style={{ width: '100%' }}
            validate={[required()]}
          />
        </Grid>
        {installationSiteWarehouse && (
          <Grid item xs={4}>
            <ReferenceInput
              source={'transfer_to'}
              name={'transfer_to'}
              variant={'outlined'}
              disabled={intention === 'accept_po' || intention === 'view_po'}
              reference="inventory_locations"
              label={'Send to'}
              filter={{ exclude_public: 1, exclude_inventory_preferences: 1 }}
              defaultValue={primaryWarehouse?.url}
              style={{ width: '100%' }}
            >
              <WizardDestinationInput source={'transfer_to'} installationSiteWarehouse={installationSiteWarehouse} />
            </ReferenceInput>
          </Grid>
        )}
        <Grid item xs={4}>
          <SelectInput
            variant={'outlined'}
            disabled={intention === 'accept_po' || intention === 'view_po'}
            name={'is_linked_project'}
            source={'is_linked_project'}
            label={'Link order to project'}
            choices={[
              { id: 'yes', name: 'Yes' },
              { id: 'no', name: 'No' },
            ]}
            defaultValue={values?.projects?.length > 0 ? 'yes' : 'no'}
            fullWidth
            style={{ width: '100%' }}
            validate={[required()]}
          />
        </Grid>
        <Grid container item xs={4} alignItems={date_picker ? 'center' : 'stretch'}>
          {intention === 'accept_po' && (
            <CustomField
              name={'realistic_delivery_date'}
              source={'realistic_delivery_date'}
              parse={dateParser}
              inputVariant={'outlined'}
              label={'Actual Delivery Date'}
              component={DateInput}
              required={true}
              defaultValue={currentDate}
              options={date_picker ? { format: 'MM/DD/YYYY' } : {}}
            />
          )}
        </Grid>
        <DependentInput dependsOn="transfer_to" value={installationSiteWarehouse?.url}>
          <Grid item xs={12}>
            <ReferenceInput
              name={action === 'edit' ? 'project_id' : 'projects_new'}
              disabled={intention === 'accept_po' || intention === 'view_po'}
              source={action === 'edit' ? 'project_id' : 'projects_new'}
              variant={'outlined'}
              reference={'projects'}
              fullWidth
              allowEmpty={true}
              placeholder={'Enter delivery address'}
              label={'Shipping address'}
            >
              <AutocompleteInput
                name={'projects_new'}
                source={'projects_new'}
                optionText={'address'}
                optionValue={'id'}
                showSpinnerWhenRefreshing={true}
                /* Hack to ensure some choices don't get mysteriously dropped */
                allowDuplicates={true}
              />
            </ReferenceInput>
          </Grid>
        </DependentInput>
        <DependentInput dependsOn="is_linked_project" value={'yes'}>
          <Grid item xs={12}>
            {/*@ts-ignore*/}
            <ReferenceArrayInput
              disabled={intention === 'accept_po' || intention === 'view_po' || values?.projects?.length > 0}
              name={'projects'}
              source={'projects'}
              variant={'outlined'}
              reference={'projects'}
              fullWidth
              allowEmpty={true}
              placeholder={'Search for project'}
              label={'Linked projects'}
            >
              <AutocompleteArrayInput
                name={'projects_new'}
                source={'projects_new'}
                optionText={'address'}
                optionValue={'url'}
                showSpinnerWhenRefreshing={true}
                /* Hack to ensure some choices don't get mysteriously dropped */
                allowDuplicates={true}
                disableClearable
              />
            </ReferenceArrayInput>
          </Grid>
        </DependentInput>
        <Grid item xs={12}>
          <TextInput
            variant={'outlined'}
            name={'note'}
            disabled={intention === 'view_po'}
            source={'note'}
            label={'Notes'}
            multiline
            rows={4}
            fullWidth
            style={{ marginRight: 0 }}
          />
        </Grid>
      </Grid>
    )
  }

  if (transferType === 'outgoing') {
    return (
      <Grid container style={{ marginRight: 0 }}>
        <Grid item xs={12} lg={8}>
          <ReferenceInput
            source={'transfer_to'}
            name={'transfer_to'}
            variant={'outlined'}
            disabled={intention === 'accept_po' || intention === 'view_po'}
            reference={'inventory_locations'}
            label={'Send to'}
            filter={{ exclude_public: 1, exclude_inventory_preferences: 1 }}
            defaultValue={''}
          >
            <WizardDestinationInput source={'transfer_to'} installationSiteWarehouse={installationSiteWarehouse} />
          </ReferenceInput>
          <TextInput
            variant={'outlined'}
            name={'order_number'}
            disabled={intention === 'accept_po' || intention === 'view_po'}
            source={'order_number'}
            label={'PO/ID number'}
            style={{ width: 256 }}
          />
          <CustomField
            name={'transfer_date'}
            source={'transfer_date'}
            parse={dateParser}
            disabled={intention === 'accept_po' || intention === 'view_po'}
            inputVariant={'outlined'}
            label={'Estimated Delivery date'}
            component={DateInput}
            required={true}
          />
          <SelectInput
            required={true}
            variant={'outlined'}
            disabled={intention === 'view_po'}
            name={'status'}
            source={'status'}
            label={'Status'}
            choices={TRANSFER_STATUS_OPTIONS}
          />
          <SelectInput
            variant={'outlined'}
            disabled={intention === 'view_po'}
            name={'reason'}
            source={'reason'}
            label={'Issue'}
            choices={TRANSFER_ISSUED_REASON_OPTIONS}
          />
        </Grid>
        <Grid container item xs={12} lg={4}>
          <DependentInput dependsOn="transfer_to" value={installationSiteWarehouse?.url}>
            <Grid item xs={12}>
              <ReferenceInput
                name={action === 'edit' ? 'project_id' : 'projects_new'}
                disabled={intention === 'accept_po' || intention === 'view_po'}
                source={action === 'edit' ? 'project_id' : 'projects_new'}
                variant={'outlined'}
                reference={'projects'}
                fullWidth
                allowEmpty={true}
                placeholder={'Enter delivery address'}
                label={'Shipping address'}
              >
                <AutocompleteInput
                  name={'projects_new'}
                  source={'projects_new'}
                  optionText={'address'}
                  optionValue={'id'}
                  showSpinnerWhenRefreshing={true}
                  /* Hack to ensure some choices don't get mysteriously dropped */
                  allowDuplicates={true}
                />
              </ReferenceInput>
            </Grid>
          </DependentInput>
          <Grid item xs={12}>
            <TextInput
              variant={'outlined'}
              name={'note'}
              disabled={intention === 'view_po'}
              source={'note'}
              label={'Notes'}
              multiline
              rows={4}
              fullWidth
              style={{ marginRight: 0 }}
            />
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return <></>
}

export default InventoryTransferFormCore

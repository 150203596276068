import { useEffect, useState } from 'react'

import { Grow, makeStyles } from '@material-ui/core'
import CustomField from 'elements/field/CustomField'
import DebouncedAutoComplete from 'elements/input/DebouncedAutoComplete'
import ProUxButton from 'elements/proUXButtons/ProUXButton'
import { BooleanInput, SelectInput, TextInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { IronRidgeRailType } from 'types/integrations/ironRidge'
import { FieldTypeEnum, FormFieldType, SelectOption, SelectorRootEnum } from './ironRidge/formFields'

const useStyles = makeStyles({
  formWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: '5px 20px 20px 20px',
    textAlign: 'left',
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fieldWrapper: {
    margin: '0px 10px',
  },
  advancedFieldsToggle: {
    textDecoration: 'underline',
    width: '100%',
    textAlign: 'center',
    marginBottom: '15px',
    cursor: 'pointer',
  },
})

type InitialValueMap = {
  [fieldName: string]: any
}
type FieldError = {
  [fieldName: string]: string | undefined
}
type FormValues = {
  [fieldName: string]: any
}
type PropTypes = {
  formError: string | null
  fields: FormFieldType[]
  advancedFields?: FormFieldType[]
  submitButtonLabel: string
  formWarningComponent: () => JSX.Element | null
  bottomComponent?: () => JSX.Element | null
  onSubmit: (vals: FormValues) => void
  loading: boolean
  initialValues: { [key: string]: any }
  termsAndConditions?: () => JSX.Element | null
  disabledForm: boolean
  values: any
}
const IntegrationAPIForm = (props: PropTypes) => {
  //@ts-ignore
  const initialValueMap: InitialValueMap = useSelector((state) => {
    let map: InitialValueMap = {}
    props.fields.forEach((field) => {
      if (props.values && props.values[field.name]) {
        map[field.name] = props.values[field.name]
      } else if (field.initialValueSelectorRoot === SelectorRootEnum.state) {
        //@ts-ignore
        if (field.initialValueSelector) map[field.name] = field.initialValueSelector(state)
      } else if (field.initialValueSelectorRoot === SelectorRootEnum.window) {
        //@ts-ignore
        if (field.initialValueSelector) map[field.name] = field.initialValueSelector(window)
      }
    })
    props.advancedFields?.forEach((field) => {
      if (props.values && props.values[field.name]) {
        map[field.name] = props.values[field.name]
      } else if (field.initialValueSelectorRoot === SelectorRootEnum.state) {
        //@ts-ignore
        if (field.initialValueSelector) map[field.name] = field.initialValueSelector(state)
      } else if (field.initialValueSelectorRoot === SelectorRootEnum.window) {
        //@ts-ignore
        if (field.initialValueSelector) map[field.name] = field.initialValueSelector(window)
      }
    })
    return map
  })

  const formState = useFormState()
  const [errorMsgMap, setErrorMsgMap] = useState<FieldError>({})
  const [hiddenFieldMap, setHiddenFieldMap] = useState<string[]>([])
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false)

  const form = useForm()
  const classes = useStyles()

  useEffect(() => {
    props.fields?.forEach((field) => {
      if (field.checkIfShouldRender && !hiddenFieldMap?.includes(field.name) && !field.checkIfShouldRender())
        setHiddenFieldMap([...hiddenFieldMap, field.name])
    })
  }, [props.fields])

  useEffect(() => {
    if (props.initialValues && Object.keys(props.initialValues).length > 0) {
      Object.keys(props.initialValues).forEach((field_name) => {
        form.change(field_name, props.initialValues[field_name])
      })
    }
  }, [props.initialValues])

  const handleSubmit = () => {
    if (getIsValid()) {
      props.onSubmit(formState.values)
    } else {
      console.log('not valid')
    }
  }

  const getIsValid = () => {
    if (!formState || !formState.values) {
      return false
    }
    let errors = {} as FieldError
    props.fields.forEach((field) => {
      //@ts-ignore
      if (field.required && !formState.values[field.name]) {
        //if the field is rendered, is required, and is not populated then throw an error
        if (
          (!field.renderBasedOnField ||
            formState.values[field.renderBasedOnField] === formState.values[field.renderBasedOnValue]) &&
          (!field.checkIfShouldRender || field.checkIfShouldRender())
        )
          errors[field.name] = 'This field is required'
      } else if (field.validator && field.validator(formState.values[field.name]))
        errors[field.name] = field.validator(formState.values[field.name])
    })
    console.log('errors', errors)
    if (Object.keys(errors).length > 0) {
      setErrorMsgMap(errors)
      return false
    } else {
      setErrorMsgMap({})
      return true
    }
  }

  const renderFieldError = (errorMsg: string | null | undefined) => (
    <div style={{ color: 'red', textAlign: 'left', width: '100%', marginTop: 0, marginBlock: 10 }}>
      {errorMsg ? errorMsg : ''}
    </div>
  )

  const renderField = (field: FormFieldType) => {
    if (field.renderBasedOnField && field.renderBasedOnValue !== undefined) {
      if (formState.values[field.renderBasedOnField] !== field.renderBasedOnValue) return null
    }
    let initialValue = initialValueMap && initialValueMap[field.name] ? initialValueMap[field.name] : undefined
    // submits cause a re-render of the form which can make some fields revert to initial value, if we have a value in the form state use it
    if (props.values && props.values[field.name]) initialValue = props.values[field.name]
    if (initialValue === undefined && field.defaultValue !== undefined) initialValue = field.defaultValue
    switch (field.fieldType) {
      case FieldTypeEnum.text:
        if (hiddenFieldMap?.includes(field.name)) return null
        else if (field.checkIfShouldRender && !field.checkIfShouldRender()) return null
        return (
          <div className={classes.fieldWrapper}>
            <CustomField
              style={{ width: '100%' }}
              required={field.required}
              name={field.name}
              label={field.label}
              component={TextInput}
              initialValue={initialValue}
            />
            {renderFieldError(errorMsgMap ? errorMsgMap[field.name] : null)}
            {field.helpText && !errorMsgMap[field.name] && (
              <div style={{ color: 'rgb(177, 177, 177)', marginBottom: '10px', marginTop: '-15px' }}>
                {field.helpText}
              </div>
            )}
          </div>
        )
      case FieldTypeEnum.number:
        return (
          <div className={classes.fieldWrapper}>
            <CustomField
              style={{ width: '100%' }}
              name={field.name}
              required={field.required}
              source={field.source}
              label={field.label}
              component={TextInput}
              initialValue={initialValue}
            />
            {renderFieldError(errorMsgMap ? errorMsgMap[field.name] : null)}
          </div>
        )
      case FieldTypeEnum.select:
        if (!field.options || field.options.length === 0) return null
        return (
          <div className={classes.fieldWrapper}>
            <SelectInput
              style={{ width: '100%' }}
              fullWidth
              label={field.label}
              required={field.required}
              source={field.source}
              choices={field.options}
              initialValue={initialValue}
            />
            {renderFieldError(errorMsgMap ? errorMsgMap[field.name] : null)}
          </div>
        )
      case FieldTypeEnum.dependentSelect:
        if (!field.dependentOptions || Object.keys(field.dependentOptions).length === 0 || !field.dependsOn) return null
        //populate list of viable options given the value in the field this depends on
        let options = [] as SelectOption[]
        if (formState.values[field.dependsOn] !== undefined) {
          options = field.dependentOptions[formState.values[field.dependsOn]]
        }

        //if this field has a value in it already but that value is not present in options, clear the value of this field
        let currentValue = formState.values[field.name]
        if (currentValue !== undefined) {
          let isSetToValidOption = false
          options?.forEach((opt) => {
            if (currentValue === opt.id) isSetToValidOption = true
          })
          if (!isSetToValidOption) {
            form.change(field.name, undefined)
          }
        }

        return (
          <div className={classes.fieldWrapper}>
            <SelectInput
              style={{ width: '100%' }}
              required={field.required}
              source={field.source}
              choices={options}
              disabled={!options || options.length === 0}
              initialValue={initialValue}
            />
            {renderFieldError(errorMsgMap ? errorMsgMap[field.name] : null)}
          </div>
        )
      case FieldTypeEnum.checkbox:
        return (
          <div className={classes.fieldWrapper}>
            <CustomField
              style={{ width: '100%' }}
              name={field.name}
              label={field.label}
              component={BooleanInput}
              initialValue={initialValue}
              disabled={
                field.name === 'rail_length_17' &&
                (props.values?.rail_type === IronRidgeRailType.AIRE_A1 ||
                  props.values?.rail_type === IronRidgeRailType.AIRE_A2)
              }
              onChange={() => console.log('changed')}
            />
            {renderFieldError(errorMsgMap ? errorMsgMap[field.name] : null)}
          </div>
        )
      case FieldTypeEnum.autoComplete:
        return (
          <div className={classes.fieldWrapper}>
            <CustomField
              style={{ width: '100%' }}
              name={field.name}
              label={field.label}
              queryURL={field.queryURL}
              required={field.required}
              component={DebouncedAutoComplete}
              initialValue={initialValue}
            />
            {renderFieldError(errorMsgMap ? errorMsgMap[field.name] : null)}
          </div>
        )
      case FieldTypeEnum.dependentAutoComplete:
        return (
          <div className={classes.fieldWrapper}>
            <CustomField
              style={{ width: '100%' }}
              name={field.name}
              label={field.label}
              required={field.required}
              queryURL={field.queryURL}
              dependentQueryFieldName={field.dependentQueryFieldName}
              component={DebouncedAutoComplete}
              initialValue={initialValue}
            />
            {renderFieldError(errorMsgMap ? errorMsgMap[field.name] : null)}
          </div>
        )
      default:
        return (
          <div className={classes.fieldWrapper}>
            <CustomField
              style={{ width: '100%' }}
              name={field.name}
              required={field.required}
              source={field.source}
              label={field.label}
              component={TextInput}
              initialValue={initialValue}
            />
            {renderFieldError(errorMsgMap ? errorMsgMap[field.name] : null)}
          </div>
        )
    }
  }

  return (
    <div className={classes.formWrapper}>
      {props.fields.map((field) => {
        return (
          <div key={field.name} style={{ flexBasis: field.flexBasisPct || 1, alignSelf: 'center' }}>
            {renderField(field)}
          </div>
        )
      })}
      {props.advancedFields && props.advancedFields.length > 0 && (
        <>
          <div className={classes.advancedFieldsToggle} onClick={() => setShowAdvanced(!showAdvanced)}>
            {showAdvanced ? 'Hide ' : 'Show '}Advanced Fields
          </div>
          {showAdvanced && (
            <Grow in={true}>
              <>
                {props.advancedFields.map((field) => {
                  return (
                    <div key={field.name} style={{ flexBasis: field.flexBasisPct || 1, alignSelf: 'center' }}>
                      {renderField(field)}
                    </div>
                  )
                })}
              </>
            </Grow>
          )}
        </>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        {props.formWarningComponent && props.formWarningComponent}
        {props.termsAndConditions && props.termsAndConditions()}
        <div className={classes.buttonWrapper}>
          {props.formError && (
            <div style={{ color: 'red', textAlign: 'center', width: '100%', marginTop: 0, marginBlock: 10 }}>
              {props.formError}
            </div>
          )}
          <ProUxButton
            onClick={handleSubmit}
            label={props.submitButtonLabel || 'Submit'}
            loading={props.loading}
            type="primary"
            disabled={props.disabledForm}
          />
        </div>
        {props.bottomComponent && props.bottomComponent()}
        <div id="bottom-scroll-target"></div>
      </div>
    </div>
  )
}
export default IntegrationAPIForm

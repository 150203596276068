import { useEffect } from 'react'
import { fetchRequestMiddleware } from 'util/fetch'
import { authHeaderReqMiddleware } from './request_middleware/authHeaderReqMiddleware'
import { contentTypeReqMiddleware } from './request_middleware/contentTypeReqMiddleware'
import { useSegenAuthReqMiddleware } from './request_middleware/useSegenAuthReqMiddleware'

export const useSetupFetchMiddleware = () => {
  const segenMiddleware = useSegenAuthReqMiddleware()
  useEffect(() => {
    // Clear out any existing middleware
    fetchRequestMiddleware.length = 0

    fetchRequestMiddleware.push(contentTypeReqMiddleware)
    fetchRequestMiddleware.push(authHeaderReqMiddleware)
    fetchRequestMiddleware.push(segenMiddleware)
  }, [segenMiddleware])
}

import { useCallback, useState } from 'react'
import { useDataProvider } from 'react-admin'
import type { InventoryFiltersType } from '../../filter/type'
import type { InventoryByLocationResponse, InventoryListData } from '../type'

export type LoadInventoryDataOptions = {
  forecast?: boolean
  warehouseName?: string
  filters?: InventoryFiltersType
}

export type LoadInventoryDataType = (options?: LoadInventoryDataOptions) => Promise<void>

type InventoryDataResponse = {
  loadingInventoryData: boolean
  inventoryData: InventoryListData | undefined
  loadInventoryData: LoadInventoryDataType
}

// bad! update the API so FE can use unique identifier instead of name
const useInventoryData = (): InventoryDataResponse => {
  const [inventoryData, setInventoryData] = useState<InventoryListData | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dataProvider = useDataProvider()
  const loadInventoryData = useCallback(async (options?: LoadInventoryDataOptions) => {
    const forecastResult = options?.forecast ? 1 : 0
    setIsLoading(true)
    await dataProvider
      .CUSTOM_POST(
        'inventory/by_location',
        {
          url: `inventory/by_location/?forecast=${forecastResult}`,
          data: options?.filters,
        },
        {
          onSuccess: ({ data }: { data: InventoryByLocationResponse }) => {
            // TODO: update BE to only return inventory list
            // Such hack to find primary warehouse
            const inventoryPrimary = data.find(
              (inventoryAtLocation) => inventoryAtLocation?.location?.name === 'Primary Warehouse'
            )
            setInventoryData(inventoryPrimary)
          },
          onFailure: (e) => {
            console.warn('e', e)
          },
        }
      )
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return {
    inventoryData,
    loadingInventoryData: isLoading,
    loadInventoryData,
  }
}

export default useInventoryData

import { FormControlLabel, Theme, Tooltip, useMediaQuery } from '@material-ui/core'
import { HelpOutline } from '@material-ui/icons'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import { IconButton, Switch } from "opensolar-ui"
import React, { useState } from 'react'
import { ProjectType } from 'types/projects'
import { useFeatureFlag } from 'util/split'
import { US_FINCOS } from '../constants'

const LENDER_NAME_MAP = {
  dividend: 'Dividend',
}

type PropTypes = {
  changeOrderIntegration: typeof US_FINCOS[number]
  project: ProjectType
  sendChangeOrder: (doChangeOrder: boolean) => void
  isLoading: boolean
}

const DividendChangeOrderCTA: React.FC<PropTypes> = (props) => {
  const [doChangeOrder, setDoChangeOrder] = useState<boolean>(false)

  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 967))
  const isXxs = useMediaQuery((theme: Theme) => theme.breakpoints.down(332))
  const dividendChangeOrderEnabled = useFeatureFlag('dividend_change_order', 'on')

  const sendChangeOrder = () => {
    props.sendChangeOrder(doChangeOrder)
  }

  if (
    !props.project ||
    !props.changeOrderIntegration ||
    !props.project.payment_option_sold ||
    !props.project.system_sold
  )
    return null

  return (
    <div
      style={{
        width: '100%',
        marginTop: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ProUXButton
        label="Update Project in Dividend"
        onClick={sendChangeOrder}
        type="secondary"
        backgroundColor="#fff"
        fullWidth
        fullHeight
        isMarginTop={isMd || isXxs ? true : false}
        labelStyle={{
          display: 'flex',
          flexDirection: isMd || isXxs ? 'column' : 'row',
          alignItems: isMd || isXxs ? 'flex-start' : 'end',
        }}
        startIcon={<img width="21" height="21" src={window.PUBLIC_URL + '/images/dividend-logo-small.jpeg'} />}
        justifyContent="flex-start"
        loading={props.isLoading}
      />
      {dividendChangeOrderEnabled && (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
          <FormControlLabel
            control={<Switch checked={doChangeOrder} onChange={() => setDoChangeOrder(!doChangeOrder)} />}
            label="Send Loan Change Order Docs"
          />
          <Tooltip title="Select this before clicking the button above and Dividend will email their change order docs to your customer after the project is updated">
            <IconButton>
              <HelpOutline fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </div>
  )
}
export default DividendChangeOrderCTA

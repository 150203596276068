import { getPricePerWatt } from 'pages/ordering/detail/content/price/PriceDetail'
import { formatCurrencyWithSymbol, format_percentage_value } from 'util/misc'
import type {
  DiscountType,
  FixedAmountDiscountValueType,
  MinimumAmountRequirementType,
  MinimumRequirementType,
  PercentageDiscountType,
  PercentageDiscountValueType,
} from './type'

export const isPercentageDiscount = (discount: DiscountType): discount is PercentageDiscountType => {
  return discount.customerGets.value.hasOwnProperty('percentage')
}

export const isMinimumAmountRequirement = (
  minimumRequirement: MinimumRequirementType
): minimumRequirement is MinimumAmountRequirementType => {
  return minimumRequirement.hasOwnProperty('greaterThanOrEqualToSubtotal')
}

export const getTotalSavingFromFixedAmountDiscount = ({
  discountValue,
  quantity,
}: {
  discountValue: FixedAmountDiscountValueType
  quantity: number
}): number => {
  const saving = parseFloat(discountValue.amount.amount)
  return discountValue.appliesOnEachItem ? quantity * saving : saving
}

export const getTotalSavingFromPercentageDiscount = (
  discountValue: PercentageDiscountValueType,
  totalAmount: number
): number => {
  return totalAmount * discountValue.percentage
}

export const getMinimumRequiredQuantityForDiscount = ({
  discount,
  pricePerItem, // bulk variant is valid too
}: {
  discount: DiscountType
  pricePerItem: number
}): number => {
  if (isMinimumAmountRequirement(discount.minimumRequirement)) {
    // Handle minimum amount requirement
    const minimumAmount = parseFloat(discount.minimumRequirement.greaterThanOrEqualToSubtotal.amount)
    const minimumRequiredQuantity = Math.ceil(minimumAmount / pricePerItem)
    return minimumRequiredQuantity
  } else {
    // Handle minimum quantity requirement
    return parseInt(discount.minimumRequirement.greaterThanOrEqualToQuantity)
  }
}

// Check discount is available before using it
export const getDiscountSavingPerItem = ({
  discount,
  pricePerItem, // bulk variant is valid too
}: {
  discount: DiscountType
  pricePerItem: number
}) => {
  if (isPercentageDiscount(discount)) {
    return getTotalSavingFromPercentageDiscount(discount.customerGets.value, pricePerItem)
  } else {
    // Fixed amount
    const saving = parseFloat(discount.customerGets.value.amount.amount)
    if (discount.customerGets.value.appliesOnEachItem) {
      return saving
    }
    const minimumRequiredQuantity = getMinimumRequiredQuantityForDiscount({ discount, pricePerItem })
    return saving / minimumRequiredQuantity
  }
}

const getRawPercentageSavingPerItem = ({
  discount,
  pricePerItem, // bulk variant is valid too
}: {
  discount: DiscountType
  pricePerItem: number
}) => {
  if (isPercentageDiscount(discount)) {
    // Percentage discount
    return discount.customerGets.value.percentage
  } else {
    // Fixed amount discount
    const saving = parseFloat(discount.customerGets.value.amount.amount)
    if (discount.customerGets.value.appliesOnEachItem) {
      // Fixed amount on each item
      return saving / pricePerItem
    }

    // Fixed amount with minimum quantity required
    const minimumRequiredQuantity = getMinimumRequiredQuantityForDiscount({ discount, pricePerItem })
    return saving / (pricePerItem * minimumRequiredQuantity)
  }
}

export const getPercentageSavingPerItem = ({
  discount,
  pricePerItem, // bulk variant is valid too
}: {
  discount: DiscountType
  pricePerItem: number
}) => {
  const percentageSaving = getRawPercentageSavingPerItem({ discount, pricePerItem })
  return format_percentage_value(percentageSaving, 2)
}

export const getModuleDiscountSavingPerWatt = ({
  discount,
  outputPower,
  quantityPerItem,
  pricePerItem, // bulk variant is valid too
}: {
  discount: DiscountType
  outputPower: number
  quantityPerItem: number
  pricePerItem: number
}) => {
  const totalSaves = getDiscountSavingPerItem({ discount, pricePerItem })
  return getPricePerWatt({ power: outputPower, price: totalSaves, quantityPerItem })
}

export const getDiscountLabel = ({
  savingsOverride,
  discount,
  currencySymbol,
}: {
  savingsOverride?: string
  discount?: DiscountType
  currencySymbol: string
}): string => {
  if (discount == null) {
    return 'Standard pricing'
  }

  let label = ''

  if (savingsOverride) {
    label += savingsOverride
  } else if (isPercentageDiscount(discount)) {
    // Handle percentage discount
    const percentage = format_percentage_value(discount.customerGets.value.percentage, 2)

    label += `${percentage}% off`
  } else {
    // Handle fixed amount discount
    const discountAmount = discount.customerGets.value.amount.amount
    const appliesOnEachItem = discount.customerGets.value.appliesOnEachItem

    label += appliesOnEachItem
      ? `${formatCurrencyWithSymbol(discountAmount, currencySymbol)}/unit off`
      : `${formatCurrencyWithSymbol(discountAmount, currencySymbol)} off`
  }

  if (isMinimumAmountRequirement(discount.minimumRequirement)) {
    // Handle minimum amount requirement
    label += ` over ${formatCurrencyWithSymbol(
      discount.minimumRequirement.greaterThanOrEqualToSubtotal.amount,
      currencySymbol
    )}`
  } else {
    // Handle minimum quantity requirement
    label += ` over ${discount.minimumRequirement.greaterThanOrEqualToQuantity} units`
  }

  return label
}

export const getBestDiscountOfferPerItem = ({
  discounts,
  pricePerItem, // bulk variant is valid too
}: {
  discounts: DiscountType[]
  pricePerItem: number
}): DiscountType | undefined => {
  if (discounts.length === 0) {
    return undefined
  }
  let bestPossibleDiscountOffer
  let bestSaving = 0
  discounts.forEach((discount: DiscountType) => {
    const discountSaving = getDiscountSavingPerItem({ discount, pricePerItem })
    if (discountSaving > bestSaving) {
      bestSaving = discountSaving
      bestPossibleDiscountOffer = discount
    }
  })
  return bestPossibleDiscountOffer
}

import { Select, Tooltip, useMediaQuery } from '@material-ui/core'
import { DeleteOutline } from '@material-ui/icons'
import {
  paymentOptionSelectionSelectors,
  removeAllPaymentOptionsForIntegration,
  removeSelectedPaymentOption,
} from 'ducks/paymentOptionSelection'
import Alert from 'elements/Alert'
import { Chip } from 'opensolar-ui'
import { useNotify, useTranslate } from 'ra-core'
import React, { useCallback, useEffect, useState } from 'react'
import { useFormState } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { PaymentOptionDataType } from 'types/paymentOptions'
import { Theme } from 'types/themes'
import { parseJsonSafe, urlToId } from 'util/misc'
import { ALL_OR_NOTHING_REMOVED_WARNING } from '../constants'
import { ALL_OR_NOTHING_INTEGRATIONS } from './constants'

type PropTypes = {
  queryOrgId: number | undefined
}

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    marginLeft: '15px',
    marginBottom: '5px',
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
  },
  titleText: {
    color: theme.greyMid1,
  },
  chipsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  chipWrapper: {
    marginTop: '5px',
    marginRight: '10px',
    cursor: 'pointer',
    backgroundColor: 'rgb(222, 222, 222)',
  },
  chipContents: {
    display: 'flex',
    alignItems: 'center',
  },
  chipText: {},
  chipIcon: {
    cursor: 'pointer',
    marginLeft: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '20px',
    backgroundColor: 'rgb(163, 163, 163)',
    width: '15px',
    height: '15px',
  },
  chipX: {
    color: 'rgb(220, 220, 220)',
  },
  mobileSelectWrapper: {
    marginTop: '10px',
  },
  mobileSelectRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
  },
}))

const SelectedPaymentOptionChips: React.FC<PropTypes> = (props) => {
  const [selectedPaymentOptions, setSelectedPaymentOptions] = useState<PaymentOptionDataType[]>([])
  const [dealerFeeWarning, setDealerFeeWarning] = useState<string | undefined>(undefined)

  const paymentOptionOverrideIds = useSelector(paymentOptionSelectionSelectors.getPaymentOptionOverrideIds)
  const availablePaymentOptions = useSelector(paymentOptionSelectionSelectors.getAvailablePaymentOptions)

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const classes = useStyles()
  const dispatch = useDispatch()
  const translate = useTranslate()
  const notify = useNotify()
  const project = useFormState().values

  useEffect(() => {
    if (paymentOptionOverrideIds?.length && availablePaymentOptions?.length) {
      let selected: PaymentOptionDataType[] = []
      paymentOptionOverrideIds?.forEach((pmtId) => {
        let selectedPaymentOption = availablePaymentOptions?.find((pmt) => pmt.id === pmtId)
        if (selectedPaymentOption) selected.push(selectedPaymentOption)
      })
      setSelectedPaymentOptions(selected)
    } else {
      setSelectedPaymentOptions([])
    }
  }, [availablePaymentOptions, paymentOptionOverrideIds])

  useEffect(() => {
    if (selectedPaymentOptions?.length) {
      let foundSunlight = false
      let foundAutoDiscountFalse = false
      selectedPaymentOptions?.forEach((pmt) => {
        let configJson = parseJsonSafe(pmt.configuration_json)
        if (!pmt.auto_discount) foundAutoDiscountFalse = true
        if (configJson?.integration === 'sunlight') {
          foundSunlight = true
        }
      })
      if (foundSunlight && !dealerFeeWarning)
        setDealerFeeWarning('Monthly payments assume this system has "Apply Estimated Discounts" toggled on')
      else if (foundAutoDiscountFalse && !dealerFeeWarning)
        setDealerFeeWarning(
          translate(
            "Monthly payments assume all payment options have the 'Auto-discount excess dealer fees' field enabled"
          )
        )
      else if (!foundSunlight && !foundAutoDiscountFalse && dealerFeeWarning) setDealerFeeWarning(undefined)
    }
  }, [selectedPaymentOptions])

  const handleClick = useCallback(
    (pmt: PaymentOptionDataType) => {
      if (project.payment_option_sold && urlToId(project.payment_option_sold) === pmt?.id) {
        notify(
          translate(
            'Sold payment options cannot be removed from designs. If you want to remove this payment option please clear out the sold payment option field in the info page then save the project.'
          ),
          'warning'
        )
      } else {
        let pmtIntegration = parseJsonSafe(pmt.configuration_json)?.integration
        if (pmtIntegration && ALL_OR_NOTHING_INTEGRATIONS.includes(pmtIntegration)) {
          dispatch(removeAllPaymentOptionsForIntegration(pmtIntegration))
          notify(translate(ALL_OR_NOTHING_REMOVED_WARNING, 'info'))
        } else {
          dispatch(removeSelectedPaymentOption(pmt?.id))
        }
      }
    },
    [project.payment_option_sold]
  )

  return (
    <div className={classes.wrapper}>
      <span className={classes.titleText}>{translate('Payment Options Applied to System')}</span>
      {!isMobile && (
        <div className={classes.chipsWrapper}>
          {selectedPaymentOptions?.map((pmt) => {
            // if the payment option doesn't belong to the org currently selected, then don't display it
            if (pmt.org_id !== props.queryOrgId) return null
            return (
              <Tooltip title={translate('Click the X to remove this payment option from the system design')}>
                <Chip
                  key={pmt.id}
                  label={
                    <div className={classes.chipContents}>
                      <div className={classes.chipText}>{pmt.title}</div>
                    </div>
                  }
                  className={classes.chipWrapper}
                  size="small"
                  onDelete={() => handleClick(pmt)}
                />
              </Tooltip>
            )
          })}
        </div>
      )}
      {isMobile && (
        <div className={classes.mobileSelectWrapper}>
          <Select
            value={undefined}
            onChange={(e) => null}
            fullWidth
            displayEmpty={true}
            renderValue={() => translate('View or Remove Selected Payment Options')}
          >
            {selectedPaymentOptions?.map((pmt) => (
              <div className={classes.mobileSelectRow}>
                <div>{pmt.title}</div>
                <div>
                  <DeleteOutline onClick={() => handleClick(pmt)} />
                </div>
              </div>
            ))}
          </Select>
        </div>
      )}
      {dealerFeeWarning && (
        <Alert severity="info">
          <span>{dealerFeeWarning}</span>
        </Alert>
      )}
    </div>
  )
}
export default SelectedPaymentOptionChips

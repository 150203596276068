var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Select as Mui4Select } from '@material-ui/core';
import { default as Mui5Select } from '@mui/material/Select';
import { forwardRef } from 'react';
import { Inspector } from '../debug/Inspector';
import { useComponentVersion } from '../hooks';
import { ChevronDownOutlineIcon } from '../icons';
export var Select = forwardRef(function (props, ref) {
    return (_jsx(Inspector, { name: "Select", propDefs: SelectPropDefs, showExample: false, component: OsSelect, props: __assign(__assign({}, props), { ref: ref }) }));
});
export var OsSelectVersion;
(function (OsSelectVersion) {
    OsSelectVersion[OsSelectVersion["V1"] = 1] = "V1";
    OsSelectVersion[OsSelectVersion["V2"] = 2] = "V2";
})(OsSelectVersion || (OsSelectVersion = {}));
var OsSelect = forwardRef(function (props, ref) {
    if (ref === void 0) { ref = null; }
    var version = useComponentVersion('select');
    switch (version) {
        case OsSelectVersion.V2:
            var onInvalid = props.onInvalid, onChange = props.onChange, MenuProps = props.MenuProps, rest = __rest(props, ["onInvalid", "onChange", "MenuProps"]);
            var onInvalid5 = onInvalid;
            var onChange5 = onChange;
            var MenuProps5 = MenuProps;
            return (_jsx(Mui5Select, __assign({ ref: ref, IconComponent: ChevronDownOutlineIcon, onInvalid: onInvalid5, onChange: onChange5, MenuProps: MenuProps5 }, rest)));
        default:
            return _jsx(Mui4Select, __assign({ ref: ref }, props));
    }
});
var SelectPropDefs = {};

import { Checkbox } from 'opensolar-ui'
import { FC } from 'react'
import { CodeBlock } from '../CodeBlock'
import ComponentPage from '../ComponentPage'
import { FeatureGrid } from '../FeatureGrid'
import { CheckboxColors, CheckboxLabelled, CheckboxSizes, CheckboxStates } from './CheckboxUsage'

const CheckboxUsage_code = require('!!raw-loader!./CheckboxUsage').default

export const CheckboxDemo: FC = () => {
  return (
    <ComponentPage
      name={'Checkbox'}
      import="import { Checkbox } from 'opensolar-ui'"
      description={'Checkboxes allow the user to select one or more items from a set.'}
      links={{
        mui4: 'https://v4.mui.com/components/checkboxes/',
        mui5: 'https://mui.com/material-ui/react-checkbox/',
      }}
    >
      <CodeBlock name="Checkbox Sizes" code={CheckboxUsage_code} codePart={1}>
        <CheckboxSizes />
      </CodeBlock>
      <CodeBlock name="Checkbox Colors" code={CheckboxUsage_code} codePart={2}>
        <CheckboxColors />
      </CodeBlock>
      <CodeBlock name="Checkbox Labelled" code={CheckboxUsage_code} codePart={3}>
        <CheckboxLabelled />
      </CodeBlock>
      <CodeBlock name="Checkbox States" code={CheckboxUsage_code} codePart={4}>
        <CheckboxStates />
      </CodeBlock>

      <CodeBlock name="Checkbox Feature Grid" hideGrid={true}>
        <FeatureGrid
          component={Checkbox}
          propsGen={(row, col, lastHeader) => {
            return { defaultChecked: true, ...row, ...col, ...lastHeader }
          }}
          colHeaders={[]}
          rowHeaders={[]}
          cols={[
            { color: 'default' },
            { color: 'primary' },
            { color: 'secondary' },
            { color: 'info' },
            { color: 'success' },
            { color: 'warning' },
            { color: 'error' },
          ]}
          rows={[
            { _type: 'heading', _title: 'Small Size', size: 'small' },
            { variant: 'default' },
            { variant: 'outlined' },
            { variant: 'rounded' },
            { _type: 'heading', _title: 'Medium Size (default)', size: 'medium' },
            { variant: 'default' },
            { variant: 'outlined' },
            { variant: 'rounded' },
            { _type: 'heading', _title: 'Large Size', size: 'large' },
            { variant: 'default' },
            { variant: 'outlined' },
            { variant: 'rounded' },
          ]}
        />
      </CodeBlock>
    </ComponentPage>
  )
}

import type LineItemType from 'pages/ordering/OrderLineItem'
import type ProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/projectOrderPresenter'
import type { SelectorOptionComponentType } from 'pages/ordering/order/table/quantity/Selector'
import type { QuantitySelectorType } from 'pages/ordering/order/table/quantity/useQuantitySelectorType'
import { createBulkQuantityOptions } from './quantity/bulk/BulkQuantityOptions'
import { createCustomQuantityOptions } from './quantity/bulk/CustomQuantityOptions'
import { createBulkSelectIndividualOption } from './quantity/bulk/IndividualQuantityOptions'
import { filterOrderableDistributors } from './quantity/bulk/utils'
import { createDiscountOptions } from './quantity/discount/DiscountOptions'

const getBulkSelectOptions = ({
  lineItem,
  orderingPresenter,
  trackSource,
}: {
  lineItem: LineItemType
  orderingPresenter: ProjectOrderPresenter
  trackSource: string
}) => {
  const orderableVariants = lineItem?.data?.distributors && filterOrderableDistributors(lineItem.data.distributors)
  const hasBulkVariants =
    orderableVariants && orderableVariants.some((distributor) => distributor.quantity_per_item > 1)

  const handleUpdate = (newLineItem: LineItemType) => {
    orderingPresenter.deleteLineItemByUuid(lineItem.uuid)
    orderingPresenter.addLineItems([newLineItem])
  }

  const CustomQuantityOptions = createCustomQuantityOptions(trackSource, lineItem, orderingPresenter)
  const IndividualOption = createBulkSelectIndividualOption(trackSource, lineItem, orderingPresenter, handleUpdate)
  const BulkQuantityOptions = createBulkQuantityOptions(trackSource, lineItem, handleUpdate)
  const optionComponents: SelectorOptionComponentType[] =
    lineItem.quantityPerItem != null && lineItem.quantityPerItem > 1
      ? [BulkQuantityOptions]
      : [IndividualOption, BulkQuantityOptions].concat(hasBulkVariants ? [CustomQuantityOptions] : [])
  return optionComponents
}

const getDiscountSelectOptions = ({
  lineItem,
  orderingPresenter,
  trackSource,
}: {
  lineItem: LineItemType
  orderingPresenter: ProjectOrderPresenter
  trackSource: string
}): SelectorOptionComponentType[] => {
  const updateLineItemQuantity = (quantity: number) => {
    orderingPresenter.updateLineItemQuantity({ uuid: lineItem.uuid, quantity })
  }
  const DiscountOptions = createDiscountOptions(trackSource, lineItem, updateLineItemQuantity)
  return [DiscountOptions]
}

const EMPTY_OPTIONS = []

const getSelectorOptions = ({
  lineItem,
  orderingPresenter,
  type,
  trackSource,
}: {
  lineItem: LineItemType
  orderingPresenter: ProjectOrderPresenter
  type?: QuantitySelectorType
  trackSource: string
}) => {
  if (type === 'bulk') {
    return getBulkSelectOptions({ lineItem, orderingPresenter, trackSource })
  }

  if (type === 'discount') {
    return getDiscountSelectOptions({ lineItem, orderingPresenter, trackSource })
  }

  return EMPTY_OPTIONS
}

export default getSelectorOptions

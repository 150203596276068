import EditIcon from '@material-ui/icons/CreateOutlined'
import DuplicateIcon from '@material-ui/icons/FileCopyOutlined'
import ImportIcon from '@material-ui/icons/PlayForWorkOutlined'
import ViewIcon from '@material-ui/icons/VisibilityOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import PropTypes from 'prop-types'
import React from 'react'
import { Button, DeleteButton, EditButton } from 'react-admin'

const buttonStyle = { color: '#4d4d4d', justifyContent: 'flex-start', margin: 0, padding: 6 }
const iconButtonStyle = { color: '#4d4d4d', justifyContent: 'flex-start', margin: 0, padding: 6 }

export const EditOrImportButton = (allProps) => {
  const {
    allowEdit = true,
    allowDelete = true,
    allowDuplicate = true,
    hideShared = false,
    onDeleteClick = null,
    ...props
  } = allProps
  const isAdmin = props.isAdmin !== false
  const logAction = (type) => {
    logAmplitudeEvent('control_crud_action', {
      type: type,
      resource: props.resource,
      variant: 'list_item',
    })
  }
  if (
    (props.record.org && props.record.org.indexOf('/orgs/' + props.org_id + '/') !== -1) ||
    props.record.org_id === props.org_id
  ) {
    const hideLabel = allowDuplicate && isAdmin

    return (
      <div style={{ minWidth: 110 }}>
        {
          <EditButton
            useListStyle={true}
            style={hideLabel ? iconButtonStyle : buttonStyle}
            hideLabelAt={hideLabel ? 'xl' : 'sm'}
            {...props}
            icon={allowEdit ? <EditIcon /> : <ViewIcon />}
            label={allowEdit ? 'Edit' : 'View'}
            onClick={() => {
              logAction(allowEdit ? 'edit' : 'view')
            }}
          />
        }

        {isAdmin && allowDuplicate && (
          <Button
            label={'Duplicate'}
            redirect={false}
            hideLabelAt={'xl'}
            submitOnEnter={false}
            raised={true}
            style={iconButtonStyle}
            onClick={() => {
              props.duplicate({
                resource: props.resource,
                id: props.record.id,
              })
              logAction('duplicate')
            }}
          >
            <DuplicateIcon />
          </Button>
        )}
        {allowDelete && (
          <DeleteButton
            onClick={() => {
              logAction('delete')
              if (onDeleteClick) onDeleteClick(props.record)
            }}
            useListStyle={true}
            hideLabelAt={hideLabel ? 'xl' : 'sm'}
            style={hideLabel ? iconButtonStyle : buttonStyle}
            undoable={false}
            record={props.record}
            resource={props.resource}
            redirect={`/${props.resource}`}
          />
        )}
        {props.customAction && React.cloneElement(props.customAction, props)}
      </div>
    )
  } else if (!hideShared) {
    return (
      <div>
        <EditButton
          useListStyle={true}
          style={buttonStyle}
          {...props}
          icon={<ViewIcon />}
          label="View"
          onClick={() => {
            logAction('view')
          }}
        />

        {isAdmin && allowDuplicate && (
          <Button
            label="Import"
            redirect={false}
            submitOnEnter={false}
            style={buttonStyle}
            raised={true}
            onClick={() => {
              props.duplicate({
                resource: props.resource,
                id: props.record.id,
              })
              logAction('import')
            }}
          >
            <ImportIcon />
          </Button>
        )}
      </div>
    )
  } else {
    return <></>
  }
}

EditOrImportButton.propTypes = {
  sortable: PropTypes.bool,
  source: PropTypes.string,
  label: PropTypes.string,
  resource: PropTypes.string,
  allowDuplicate: PropTypes.bool,
  allowEdit: PropTypes.bool,
  allowDelete: PropTypes.bool,
  onDeleteClick: PropTypes.func,
  duplicate: PropTypes.func,
  org_id: PropTypes.number,
  isAdmin: PropTypes.bool,
  customAction: React.ReactNode,
  record: PropTypes.object,
  basePath: PropTypes.string,
}

export default EditOrImportButton

import { ModuleGridType } from 'types/global'
import { formatNumber } from '../helpers'
import type { BasicAccessorConfig } from './types'

const setter = ({ moduleGrid, value }: { moduleGrid: ModuleGridType; value: number }) => {
  window.editor.execute(
    new window.SetPanelConfigurationCommand(moduleGrid, 'azimuth', value, window.Utils.generateCommandUUIDOrUseGlobal())
  )
}

const getter = (moduleGrid: ModuleGridType) => {
  return formatNumber(moduleGrid.getAzimuth()) % 360
}

export const azimuthAccessorConfig: BasicAccessorConfig<number> = {
  get: getter,
  set: setter,
}

// @ts-nocheck
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { hideFullScreenLoader, markCreditAppAsOpened, myEnergySelectors } from 'ducks/myEnergy'
import LoadingDots from 'layout/widgets/LoadingDots'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'
import { PaymentOptionType } from 'types/paymentOptions'
import { ProjectType } from 'types/projects'
import { getCommonCreditDecision } from 'util/misc'
import { logAppOpenedEvent } from '../../../../util/events'
import { TransactionActionButton } from '../../../elements/TransactionStartButton'
import SunlightLoanApplication from '../../SunlightApplicationLoading'
import ReapplicationConfirm from '../ReapplicationConfirm'
import { ActionDataType } from '../types'
import { removeCreditAppParams } from '../util'

var restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  actionData: ActionDataType
  translate: (raw: string) => string
  showDialog: (transactionType: string, options: { url: string }, isFullWidth?: boolean) => void
  setWaitingForAction: Function
  myeStyles: CSSProperties
  acceptButtonStyle: CSSProperties
  acceptButtonStyleDisabled: CSSProperties
  isPro: boolean
  selectedPaymentOptionName: string
  selectedProject: ProjectType
  selectedPaymentOptionId: number
  paymentOptionData: PaymentOptionType
}
const SunlightApplicationButton: React.FunctionComponent<PropTypes> = (props) => {
  const {
    actionData,
    translate,
    showDialog,
    myeStyles,
    acceptButtonStyle,
    selectedPaymentOptionName,
    selectedProject,
    selectedPaymentOptionId,
    paymentOptionData,
  } = props
  const [showSunlightCreditLoadingDialog, setShowSunlightCreditLoadingDialog] = useState(false)
  const [initialStatus, setInitialStatus] = useState<string>(actionData.status_code)
  const [isLoadingIframe, setIsLoadingIframe] = useState<boolean>(false)
  const [showReapplyDialog, setShowReapplyDialog] = useState<boolean>(false)

  const forceOpenCreditApp = useSelector(myEnergySelectors.getShouldForceOpenCreditApp)
  const dispatch = useDispatch()

  useEffect(() => {
    if (forceOpenCreditApp && selectedPaymentOptionId === paymentOptionData?.id) {
      openDialog(undefined, false, true)
      removeCreditAppParams()
      dispatch(hideFullScreenLoader())
      dispatch(markCreditAppAsOpened())
    }
  }, [forceOpenCreditApp, selectedPaymentOptionId])

  useEffect(() => {
    const isSunlight =
      actionData.payment_method === 'sunlight_loan_application' || actionData.payment_method === 'sunlight_loan_prequal'
    if (isSunlight && initialStatus === 'available' && actionData.status_code !== 'available') {
      logAmplitudeEvent('integrated_finance_application_submitted', {
        integration: 'sunlight',
        decision: getCommonCreditDecision(actionData.status_title),
        is_prequal: actionData.payment_method === 'sunlight_loan_prequal' ? 'yes' : 'no',
        project_id: actionData.project_id,
      })
    }
  }, [initialStatus, actionData.status_code, actionData.payment_method])

  const startReapply = () => openDialog(undefined, true)

  const openDialog = (e: any, isReapplication: boolean, isFullWidth?: boolean) => {
    if (actionData.status_code === 'available' || isReapplication) {
      let hasValidContact = false
      if (selectedProject?.contacts?.length > 0) {
        let firstContact = selectedProject.contacts[0]
        if (firstContact?.first_name && firstContact?.family_name && firstContact?.phone && firstContact?.email) {
          hasValidContact = true
        }
      }
      if (!hasValidContact) {
        window.Designer.showNotification(
          window.translate(
            'Project must have a contact with a first name, last name, and valid phone and email address'
          ),
          'danger'
        )
        return
      }
      logAppOpenedEvent(actionData, selectedPaymentOptionName)
      const requestStart = new Date()
      setIsLoadingIframe(true)
      if (actionData.application_url) {
        // URL already generated which means a sunlight account has been made. But the url might be stale so we will refresh it and use that for the dialog
        restClientInstance('CUSTOM_POST', 'custom', {
          url:
            'orgs/' +
            actionData.org_id +
            '/projects/' +
            actionData.project_id +
            '/refresh_sunlight_iframe_url/' +
            actionData.system_uuid +
            '/' +
            actionData.payment_option_id +
            '/sunlight_loan_application/',
        })
          .then(
            (response) => {
              if (response.data.status && response.data.url) {
                setShowReapplyDialog(false)
                showDialog('SunlightLoanApplication', { url: response.data.url }, isFullWidth)
                const requestEnd = new Date()
                let secondsToOpen = 'error'
                try {
                  secondsToOpen = `${((requestEnd - requestStart) / 1000).toFixed(0)}`
                } catch (ex) {}
                logAmplitudeEvent('integrated_finance_application_opened', {
                  integration: 'sunlight',
                  seconds_to_open: secondsToOpen,
                })
              } else if (!response.data.status && response.data.message) {
                window.Designer.showNotification(window.translate(response.data.message), 'danger')
              } else {
                window.Designer.showNotification(
                  window.translate('Unable to open Sunlight Credit Application'),
                  'danger'
                )
              }
            },
            (reject) => {
              console.log(reject)
              window.Designer.showNotification(window.translate(reject.message), 'danger')
              setShowSunlightCreditLoadingDialog(false)
            }
          )
          .finally(() => setIsLoadingIframe(false))
      } else {
        // Request the email send (which will automatically record an incomplete transaction)
        setShowSunlightCreditLoadingDialog(true)
        restClientInstance('CUSTOM_POST', 'custom', {
          url:
            'orgs/' +
            actionData.org_id +
            '/projects/' +
            actionData.project_id +
            '/start_sunlight_application/' +
            actionData.system_uuid +
            '/' +
            actionData.payment_option_id +
            '/sunlight_loan_application/',
        })
          .then(
            (response) => {
              if (response.data && response.data.data && response.data.data.application_url) {
                showDialog('SunlightLoanApplication', { url: response.data.data.application_url })
                const requestEnd = new Date()
                let secondsToOpen = 'error'
                try {
                  secondsToOpen = `${((requestEnd - requestStart) / 1000).toFixed(0)}`
                } catch (ex) {}
                logAmplitudeEvent('integrated_finance_application_opened', {
                  integration: 'sunlight',
                  project_id: actionData.project_id,
                  is_prequal: actionData.payment_method === 'sunlight_loan_prequal' ? 'yes' : 'no',
                  seconds_to_open: secondsToOpen,
                })
              }
              setShowSunlightCreditLoadingDialog(false)
            },
            (reject) => {
              console.log(reject)
              window.Designer.showNotification(window.translate(reject.message), 'danger')
              setShowSunlightCreditLoadingDialog(false)
            }
          )
          .finally(() => setIsLoadingIframe(false))
      }
    }
  }

  if (actionData.payment_method === 'sunlight_loan_application') {
    let buttonLabelText =
      actionData.status_title === 'InitiatedButIncomplete'
        ? translate('Finish Loan Application')
        : translate('Apply Now')

    let extra_actions = []

    if (actionData.status_code === 'available') {
      extra_actions.push(
        <p key={extra_actions.length} className="small">
          {'Clicking "' +
            buttonLabelText +
            '" or "Get Pre-Qualified Instantly"' +
            " will take you to Sunlight Financial's credit application. OpenSolar does not view or store any of the data you enter in this application."}
        </p>
      )
    }

    if (['ApprovedPendingDocs', 'Pending Loan Docs'].includes(actionData.status_title)) {
      extra_actions.push(
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
            <CheckCircleIcon htmlColor="rgba(55, 169, 46, 0.9)" style={{ marginRight: '5px' }} />
            <h2>Approved</h2>
          </div>
          <div style={{ marginBottom: '10px' }}>You have been approved for financing!</div>
        </div>
      )

      extra_actions.push(
        <TransactionActionButton
          label={
            <div>
              <div>Request Loan Docs</div>
              <div style={{ fontSize: '10px' }}>(can take up to 2 minutes to send)</div>
            </div>
          }
          labelStyle={{ padding: 0 }}
          buttonStyle={{ height: 39, ...acceptButtonStyle }}
          action={() => {
            // Request the email send (which will automatically record an incomplete transaction)
            setIsLoadingIframe(true)
            restClientInstance('CUSTOM_GET', 'custom', {
              url: 'orgs/' + actionData.org_id + '/projects/' + actionData.project_id + '/send_loan_docs/',
            })
              .then(
                (response) => {
                  window.Designer.showNotification(
                    window.translate(
                      'Loan Docs are on their way to your email! It can take up to two minutes for them to arrive.'
                    )
                  )
                },
                (reject) => {
                  console.log(reject)
                  window.Designer.showNotification(window.translate('Error Sending Loan Docs'), 'danger')
                }
              )
              .finally(() => setIsLoadingIframe(false))
          }}
        />
      )
    } else if (actionData.status_title === 'Pending Loan Docs') {
      extra_actions.push(
        <div
          key={extra_actions.length}
          style={{ textAlign: 'center', color: myeStyles.textColor, margin: '15px 0px 15px 0px' }}
        >
          ✓ You're Approved!
        </div>
      )
    } else if (actionData.status_title === 'Credit Declined') {
      extra_actions.push(
        <div
          key={extra_actions.length}
          style={{ textAlign: 'center', color: myeStyles.textColor, margin: '15px 0px 15px 0px' }}
        >
          Your previous application was not approved for financing
        </div>
      )
    } else if (actionData.status_description) {
      // Other statuses where we simply print the status_description
      // Including: OtherOrUnknown
      extra_actions.push(<p key={extra_actions.length}>{actionData.status_description}</p>)
    }

    if (actionData.status_code === 'complete' && extra_actions) return extra_actions
    else
      return (
        <>
          {actionData.status_code === 'available' && (
            <TransactionActionButton
              label={
                isLoadingIframe ? (
                  <LoadingDots color="#fff" />
                ) : (
                  <div>
                    {buttonLabelText}
                    <div style={{ fontSize: 11 }}>(Takes less than 5 minutes)</div>
                  </div>
                )
              }
              disabled={false}
              myeStyles={myeStyles}
              labelStyle={{ padding: 0 }}
              buttonStyle={{
                height: 39,
                ...acceptButtonStyle,
              }}
              action={openDialog}
              extra_actions={extra_actions}
            />
          )}
          <SunlightLoanApplication visible={showSunlightCreditLoadingDialog} type="credit" />
          {actionData.status_title === 'Credit Declined' && actionData.payment_method === 'sunlight_loan_application' && (
            <div
              style={{
                marginTop: '10px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <div style={{ textAlign: 'center' }}>Your previous application was not approved for financing.</div>
              <div
                style={{
                  marginTop: '10px',
                  color: myeStyles.highlightColor,
                  textAlign: 'center',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                onClick={() => setShowReapplyDialog(true)}
              >
                Want to Re-Apply?
              </div>
              <ReapplicationConfirm
                isOpen={showReapplyDialog}
                close={() => setShowReapplyDialog(false)}
                onConfirm={startReapply}
                loading={isLoadingIframe}
              />
            </div>
          )}
          {['disabled', 'complete'].includes(actionData.status_code) &&
            actionData.status_title !== 'Credit Declined' &&
            extra_actions?.length > 0 && <div>{extra_actions}</div>}
        </>
      )
  }
}
export default SunlightApplicationButton

import { Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { closeEagleViewDialog, setEagleViewOrderSubmitted } from 'ducks/eagleViewOrderForm'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import LoadingDots from 'layout/widgets/LoadingDots'
import React, { useState } from 'react'
import { showNotification as showNotificationAction, SimpleForm } from 'react-admin'
import { useForm } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'
import { RootState } from 'types/state'
import EagleViewOrderForm from './EagleViewOrderForm'

var restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {}

const forceOpen = false

const EagleViewOrderSubmitButton = (props) => {
  const form = useForm()
  return (
    <ProUXButton
      onClick={() => {
        const vals = form.getState().values
        console.log(vals)
        props.onSubmit(vals)
      }}
      label={'Order Report'}
      type="primary"
    />
  )
}

const EagleViewOrderDialog: React.FC<PropTypes> = (props) => {
  const dispatch = useDispatch()
  const org = useSelector(authSelectors.getCurrentOrg)
  const isMobile = useMediaQuery('(max-width:800px)')
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const showDialog = useSelector((state: RootState) => state.eagleViewOrderForm?.showDialog)

  const eagleViewOrderFormState = useSelector((state: RootState) => state.eagleViewOrderForm)
  const [eagleViewOrderResponse, setEagleViewOrderResponse] = useState<any>(null)

  const getInitialValues = () => {
    return {
      projectId: eagleViewOrderFormState?.projectId || null,
      address: eagleViewOrderFormState?.address || '',
      city: eagleViewOrderFormState?.city || '',
      zip: eagleViewOrderFormState?.zip || '',
      state: eagleViewOrderFormState?.state || '',
      lon: eagleViewOrderFormState?.lon || '',
      lat: eagleViewOrderFormState?.lat || '',
      report_type: eagleViewOrderFormState.report_type || 62,
    }
  }

  const dismiss = () => {
    setEagleViewOrderResponse(null)
    dispatch(closeEagleViewDialog())
  }

  const onSubmit = (vals) => {
    logAmplitudeEvent('eagleview_order_submit', { projectId: vals.projectId, report_type: vals.report_type })
    submitOrder(vals)
  }

  const getValidationError = (vals) => {
    return undefined
  }

  const handleOrderResponse = (response) => {
    try {
      logAmplitudeEvent('eagleview_order_success', {
        projectId: eagleViewOrderFormState.projectId,
        orderId: response?.data?.OrderReports?.OrderId,
      })
    } catch (ex) {}
    setLoading(false)
    dispatch(setEagleViewOrderSubmitted(eagleViewOrderFormState.projectId as number))

    // e.g. {
    //     "OrderReports": {
    //       "OrderId": 27118830,
    //       "ReportIds": [
    //         43434690
    //       ]
    //     }
    //   }

    setEagleViewOrderResponse(response.data)
  }
  const handleOrderError = (err) => {
    setLoading(false)
    dispatch(showNotificationAction(err?.body?.detail || 'Error', 'error'))
    try {
      logAmplitudeEvent('eagleview_order_error', { projectId: eagleViewOrderFormState.projectId })
    } catch (ex) {}
  }

  const submitOrder = (formVals: any) => {
    setLoading(true)
    let error: string | undefined = getValidationError(formVals)
    if (error) {
      //   logAmplitudeEvent('eagleview_order_error', {
      //     integration: 'commonbond',
      //     error: error,
      //     project_id: props.project?.id,
      //     payment_option_id: props?.paymentOptionId,
      //   })
      setErrorMsg(error)
      return
    }
    setLoading(true)

    // @ts-ignore
    restClientInstance('CUSTOM_POST', 'custom', {
      url: 'orgs/' + org?.id + '/eagleview/place_order/',
      data: {
        ...formVals,
      },
    })
      .then(handleOrderResponse)
      .catch(handleOrderError)
  }

  if (!forceOpen && !showDialog) return null
  return (
    <Dialog open={forceOpen || showDialog} onClose={() => dismiss()}>
      <DialogTitle style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
        Order EagleView Report
      </DialogTitle>

      {loading && (
        <div
          style={{ position: 'absolute', width: '100%', height: '400px', display: 'flex', justifyContent: 'center' }}
        >
          <LoadingDots />
        </div>
      )}

      <DialogContent style={loading ? { opacity: '20%' } : {}}>
        {eagleViewOrderResponse && (
          <div>
            <div>
              Order Submitted. OrderId: {eagleViewOrderResponse?.OrderReports?.OrderId || 'Unknown'}. ReportIds:{' '}
              {eagleViewOrderResponse?.OrderReports?.ReportIds?.length
                ? eagleViewOrderResponse?.OrderReports?.ReportIds.join(', ')
                : 'Unknown'}
            </div>
            <div style={{ marginTop: 15, marginBottom: 15, marginRight: 15 }}>
              <ProUXButton onClick={dismiss} label="OK" type="primary" />
            </div>
          </div>
        )}

        {!eagleViewOrderResponse && (
          <SimpleForm
            initialValues={getInitialValues()}
            //@ts-ignore
            toolbar={
              <DialogActions
                style={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column-reverse' : 'row',
                  justifyContent: 'center',
                }}
              >
                <div style={{ marginBottom: '15px', marginRight: '15px' }}>
                  <ProUXButton onClick={dismiss} label="Cancel" type="secondary" />
                </div>
                <div style={{ marginBottom: '15px' }}>
                  <EagleViewOrderSubmitButton onSubmit={onSubmit} />
                </div>
              </DialogActions>
            }
          >
            <EagleViewOrderForm />
          </SimpleForm>
        )}
      </DialogContent>
    </Dialog>
  )
}
export default EagleViewOrderDialog

// @ts-nocheck
import { Grid } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { updateRoleBrighteEnabled } from 'ducks/auth_roles'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import LoadingDots from 'layout/widgets/LoadingDots'
import React, { useEffect, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import ConnectBrighteButton from 'resources/externalKeys/brighte/ConnectBrighteButton'
import restClient from 'restClient'
import appStorage from 'storage/appStorage'
import { ProjectType } from 'types/projects'
import { RoleType } from 'types/roles'

const MAX_POLL_COUNT = 20
const POLL_WAIT_SECONDS = 3

type PropTypes = {
  project: ProjectType
  onClose: () => void
  onSuccess: () => void
  pendingPmtOrgId: number | undefined
}

const BrighteConnectRoleDialogContent: React.FunctionComponent<PropTypes> = (props) => {
  const translate = useTranslate()
  const role: RoleType = useSelector(authSelectors.getCurrentRole)
  const org: OrgType = useSelector(authSelectors.getCurrentOrg)
  const user = useSelector(authSelectors.getCurrentUser)

  //for creating new users
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const [tabOpened, setTabOpened] = useState<boolean>(false)
  const [timedOut, setTimedOut] = useState<boolean>(false)
  const [pollCount, setPollCount] = useState<number>(0)

  const dispatch = useDispatch()
  const notify = useNotify()

  useEffect(() => {
    if (tabOpened) setPollCount(1)
  }, [tabOpened])

  useEffect(() => {
    if (pollCount < MAX_POLL_COUNT) {
      if (appStorage.getString('brighte_vendor_mismatch') === 'true') {
        appStorage.clear('brighte_vendor_mismatch')
        trySigningInAgain()
        setErrorMsg(
          'The vendor associated with your Brighte user does not match the vendor saved to your OpenSolar organisation. Please login with a Brighte user under the same vendor'
        )
        logAmplitudeEvent('brighte_oauth_error', { error: 'vendor id mismatch' })
      } else if (appStorage.getString('brighte_duplicate_user') === 'true') {
        appStorage.clear('brighte_duplicate_user')
        trySigningInAgain()
        setErrorMsg(
          'The Brighte user you logged in as has already been connected to an OpenSolar user. Please login to a Brighte user that has not yet been used.'
        )
        logAmplitudeEvent('brighte_oauth_error', { error: 'duplicate user' })
      } else {
        setTimeout(() => {
          pollForResult()
        }, POLL_WAIT_SECONDS * 1000)
      }
    } else {
      setTabOpened(false)
      setTimedOut(true)
    }
  }, [pollCount])

  const pollForResult = () => {
    const restClientInstance = restClient(window.API_ROOT + '/api')
    const orgId = props.pendingPmtOrgId ? props.pendingPmtOrgId : org.id
    restClientInstance('CUSTOM_GET', 'custom', {
      url: 'orgs/' + orgId + '/roles/' + role.id + '/brighte/poll_for_oauth_result/',
    })
      .then((response: any) => {
        if (response.data?.success) {
          if (role.id) dispatch(updateRoleBrighteEnabled(role.id))
          props.onSuccess()
          props.onClose()
          notify("Your account is connected and you're ready to start selling Brighte!", 'success')
        } else {
          setPollCount(pollCount + 1)
        }
      })
      .catch((error: any) => {
        console.log('error', error)
        setErrorMsg(
          'Something went wrong when we tried to verify your Brighte account. Please save your project, refresh the page, and try again'
        )
        setTabOpened(false)
      })
  }

  const startAgain = () => {
    setErrorMsg(undefined)
    setTabOpened(true)
    setPollCount(1)
    setTimedOut(false)
  }

  const trySigningInAgain = () => {
    setErrorMsg(undefined)
    setTabOpened(false)
    setPollCount(0)
    setTimedOut(false)
  }

  if (timedOut) {
    return (
      <>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={`${window.PUBLIC_URL}/images/brighte_logo.png`} height="66" />
        </Grid>
        <Grid item xs={12}>
          <div style={{ textAlign: 'center' }}>
            {translate(
              'It looks like it took a while to login to your Brighte account. If you have logged in click "Check Account Status Again" below and we will confirm that your account is connected. If you are not yet ready to login you can always come back and try again later.'
            )}
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ margin: '20px 0px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
        >
          <div style={{ margin: '0px 20px' }}>
            <ProUXButton type="secondary" label="Close" onClick={props.onClose} />
          </div>
          <div style={{ margin: '0px 20px' }}>
            <ProUXButton type="primary" label="Check Account Status Again" onClick={startAgain} />
          </div>
        </Grid>
        <Grid item xs={12} className="small" style={{ textAlign: 'center', cursor: 'pointer' }}>
          <a onClick={trySigningInAgain}>{translate('Or click here to try signing in again')}</a>
        </Grid>
      </>
    )
  } else
    return (
      <>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={`${window.PUBLIC_URL}/images/brighte_logo.png`} height="66" />
        </Grid>
        {!tabOpened && (
          <Grid item xs={12}>
            <div style={{ textAlign: 'center' }}>
              {translate(
                "Let's get you setup to start selling Brighte products on OpenSolar! The first step is to click the button below to sign in to your Brighte account. You will only have to sign in this one time."
              )}
            </div>
          </Grid>
        )}
        {errorMsg && (
          <Grid item xs={12}>
            <p style={{ color: 'red', textAlign: 'center' }}>{errorMsg}</p>
          </Grid>
        )}
        {tabOpened ? (
          <Grid
            item
            xs={12}
            style={{ margin: '20px 0px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
          >
            <LoadingDots text="Verifying your account..." />
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            style={{ margin: '20px 0px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
          >
            <div style={{ margin: '0px 20px' }}>
              <ProUXButton type="secondary" label="Close" onClick={props.onClose} />
            </div>
            <div style={{ margin: '0px 20px' }}>
              <ConnectBrighteButton
                buttonLabel="Sign In to Brighte"
                studioMode={true}
                onTabOpened={() => setTabOpened(true)}
              />
            </div>
          </Grid>
        )}
        {!tabOpened && (
          <Grid item xs={12} className="small">
            <p>
              {translate(
                'If you do not have your own Brighte account, please have your manager create an account for you in the Brighte portal.'
              )}
            </p>
          </Grid>
        )}
      </>
    )
}
export default BrighteConnectRoleDialogContent

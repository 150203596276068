import { useLayoutEffect, useState } from 'react'
import { AnyComponentType } from 'types/components'
import { ComponentTypes } from 'types/selectComponent'

export const getActivatedComponentsByComponentType = (type: ComponentTypes): AnyComponentType[] => {
  switch (type) {
    case 'module':
      return window.AccountHelper.getComponentModuleSpecsAvailable()
    case 'inverter':
      return window.AccountHelper.getComponentInverterSpecsAvailable()
    case 'other':
      return window.AccountHelper.getComponentOtherSpecsAvailable()
    case 'battery':
      return window.AccountHelper.getComponentBatterySpecsAvailable()
  }
}

const useActivatedComponents = ({ type }: { type: ComponentTypes }): AnyComponentType[] => {
  const [activeComponentsData, setActiveComponentsData] = useState<AnyComponentType[]>(() =>
    getActivatedComponentsByComponentType(type)
  )

  useLayoutEffect(() => {
    // Refresh activeComponentsData once ordering data get updated
    setActiveComponentsData(getActivatedComponentsByComponentType(type))
  }, [type])

  return activeComponentsData
}

export default useActivatedComponents

import { authSelectors } from 'ducks/auth'
import { CurveConfig, curvesSelectors, loadCurves } from 'ducks/consumptionCurves'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const useConsumptionCurves = () => {
  const orgId = useSelector(authSelectors.getOrgId)
  const dispatch = useDispatch()
  const consumption_curves: CurveConfig[] = useSelector(curvesSelectors.getCurvesData)
  const curves_loading = useSelector(curvesSelectors.getIsLoading)
  const curves_ready = useSelector(curvesSelectors.getIsReady)

  useEffect(() => {
    if (!curves_loading && !curves_ready) {
      dispatch(loadCurves(orgId))
    }
  }, [curves_ready, orgId])

  return useMemo(() => ({ consumption_curves, curves_loading, curves_ready }), [
    consumption_curves,
    curves_loading,
    curves_ready,
  ])
}

export interface CurvesDataForProject {
  countryCurves: CurveConfig[]
  defaultCurve: CurveConfig
}

export const useGetCountryCurvesData = (projectIso2, isResidential): CurvesDataForProject | any => {
  const { consumption_curves, curves_ready } = useConsumptionCurves()

  return useMemo(() => {
    if (curves_ready) {
      const countryCurves = consumption_curves.filter((c) => {
        const blacklisted = c.country_blacklist.includes(projectIso2)
        const hasWhitelist = !!c.country_whitelist.length
        const whitelisted = c.country_whitelist.includes(projectIso2)
        return !blacklisted && (!hasWhitelist || whitelisted)
      })
      const projectType = isResidential ? 'domestic' : 'commercial'
      const defaultCountryCurve = countryCurves.find((c) => c[`is_default_${projectType}`].includes(projectIso2))
      const defaultCurve =
        defaultCountryCurve || countryCurves.find((c) => c[`is_default_${projectType}`].includes('**'))
      if (!countryCurves.length || !defaultCurve) {
        throw new Error(
          `Incorrect Daily Consumption Curves Configuration for country ${projectIso2} project type ${projectType}`
        )
      } else {
        return { countryCurves, defaultCurve }
      }
    }
  }, [projectIso2, isResidential, curves_ready])
}

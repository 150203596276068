import { Dialog } from '@material-ui/core'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import { authSelectors } from 'ducks/auth'
import { rolesSelectors } from 'ducks/auth_roles'
import { orgSelectors } from 'ducks/orgs'
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import BrighteConnectRoleDialogContent from 'resources/integrations/brighte/BrighteConnectRoleDialogContent'
import MosaicConnectRoleDialogContent from 'resources/integrations/mosaic/MosaicConnectRoleDialogContent'
import SungageConnectRoleDialogContent from 'resources/integrations/sungage/SungageConnectRoleDialogContent'
import restClient from 'restClient'
import appStorage from 'storage/appStorage'
import { PaymentOptionDataType } from 'types/paymentOptions'
import { RootState } from 'types/state'
import { SystemDataType } from 'types/systems'
import { isPricingLockedForStage, parseJsonSafe } from 'util/misc'
import PaymentOptionCheckList from './PaymentOptionChecklist'
import TieredPaymentOptionSection from './TieredPaymentOptionSection'
import { getSungage18MoVersion, sortByIntegration } from './util'

const restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  system: SystemDataType
}

export type PopularPaymentOptionType = {
  id: number
  title: string
  integration: undefined | string
}
type ResponseType = {
  data: {
    success: boolean
    popular_payment_options: PopularPaymentOptionType[]
  }
}

const PaymentOptionsList: React.FC<PropTypes> = (props) => {
  const hidePaymentOptionForRole = (paymentOptionData) => {
    if (paymentOptionData?.configuration_json) {
      const config = parseJsonSafe(paymentOptionData.configuration_json)
      const channels = config?.channels
      if (!ignoreChannels && channels && channels.length > 0) {
        if (!loanpalChannels || loanpalChannels.length < 1) return true
        let hasChannelMatch = false
        loanpalChannels.forEach((roleChannel) => {
          if (channels.includes(roleChannel)) hasChannelMatch = true
        })
        if (hasChannelMatch) return false
        else return true
      }
    }
    return false
  }

  const [showMosaicRoleDialog, setShowMosaicRoleDialog] = useState<boolean>(false)
  const [showSungageRoleDialog, setShowSungageRoleDialog] = useState<boolean>(false)
  const [showBrighteRoleDialog, setShowBrighteRoleDialog] = useState<boolean>(false)
  // when a user clicks a pmt option but isnt' conencted to mosaic we store their selection so once they are connected we can add it to the system
  const [pendingUuid, setPendingUuid] = useState<string | undefined>(undefined)
  const [pendingPmtId, setPendingPmtId] = useState<number | undefined>(undefined)
  const [pendingPmtOrgId, setPendingPmtOrgId] = useState<number | undefined>(undefined)
  const [availablePaymentOptions, setAvailablePaymentOptions] = useState<PaymentOptionDataType[]>([])
  const [favoritePaymentOptions, setFavoritePaymentOptions] = useState<PaymentOptionDataType[]>([])
  const [popularPaymentOptions, setPopularPaymentOptions] = useState<PaymentOptionDataType[]>([])
  const formValues = useFormState().values
  const orgWorkflows = useSelector(orgSelectors.getWorkflows)
  const [pricingIsLocked, setPricingIsLocked] = useState<boolean>(
    isPricingLockedForStage(formValues, orgWorkflows) && props.system.override_price_locking !== true
  )
  const [defaultSungageIdsForActve18Mo, setDefaultSungageIdsForActve18Mo] = useState<number[]>([])

  const orgId = useSelector(authSelectors.getOrgId)
  const roleId = useSelector(rolesSelectors.getCurrentRoleId)
  const hasUsFinance = useSelector(authSelectors.getHasUsFinanceIntegration)
  const sungageConnected = useSelector((state: RootState) => {
    const currentRole = authSelectors.getCurrentRole(state)
    return currentRole?.sungage_enabled
  })
  const phoenixEnabled = useSelector(authSelectors.getPhoenixConnected)

  useEffect(() => {
    if (sungageConnected && props.system?.payment_options_override) {
      const selected18Pmts = availablePaymentOptions?.filter((pmt) => {
        const config = parseJsonSafe(pmt.configuration_json)
        return (
          config?.integration === 'sungage' &&
          !!config?.prepayment_period &&
          props.system?.payment_options_override?.includes(pmt.id)
        )
      })
      const selected18MoIds = selected18Pmts.map((pmt) => pmt.id)
      let defaultVersionOfSelected18s = [] as number[]
      availablePaymentOptions?.forEach((pmt) => {
        const config = parseJsonSafe(pmt.configuration_json)
        if (config?.integration === 'sungage' && !config?.prepayment_period) {
          const version18Mo = getSungage18MoVersion(pmt, availablePaymentOptions)
          if (version18Mo && selected18MoIds?.includes(version18Mo.id)) {
            defaultVersionOfSelected18s.push(pmt.id)
          }
        }
      })
      setDefaultSungageIdsForActve18Mo(defaultVersionOfSelected18s)
    }
  }, [sungageConnected, props.system?.payment_options_override, availablePaymentOptions])

  useEffect(() => {
    // hasPhoenix refers only to whether or not there is an available Phoenix 2.0 payment option. Soon after 2.0 launch we'll be removing all 1.0 related code
    // if phoenix 2.0 is enabled, strip out all but one phoenix payment option
    let hasPhoenix = false
    const allAvailable = window.AccountHelper.getPaymentOptionAvailable()?.filter((pmt) => {
      const config = parseJsonSafe(pmt.configuration_json)
      if (config?.integration === 'phoenix' && pmt.title?.includes('years') && !hasPhoenix) {
        hasPhoenix = true
      }
      return !hidePaymentOptionForRole(pmt)
    })
    const allAvailableIds = allAvailable?.map((pmt) => pmt.id)
    let unsorted: PaymentOptionDataType[] = []
    props.system?.payment_options?.forEach((pmt: PaymentOptionDataType) => {
      if (!allAvailableIds?.includes(pmt?.id)) {
        pmt.is_archived = true
        unsorted.push(pmt)
      }
    })
    unsorted = unsorted.concat(allAvailable)
    if (hasPhoenix) {
      let sorted = sortByIntegration(unsorted)
      setAvailablePaymentOptions(sorted)
    } else if (!hasUsFinance) {
      setAvailablePaymentOptions(unsorted)
    } else {
      let sorted = sortByIntegration(unsorted)
      setAvailablePaymentOptions(sorted)
    }
  }, [hasUsFinance])

  useEffect(() => {
    // when the dialog is open we need to disable hotkeys so people can type
    if (showMosaicRoleDialog || showSungageRoleDialog) window.Designer.typingInField = true
    else window.Designer.typingInField = false
  }, [showMosaicRoleDialog, showSungageRoleDialog])

  useEffect(() => {
    if (hasUsFinance && availablePaymentOptions?.length) {
      const now = new Date()
      const STORAGE_KEY = `${now.getHours()}-${now.getDay()}-${now.getMonth()}-${now.getFullYear()}-${roleId}-popular-pmts`
      const storedData = appStorage.getJSON(STORAGE_KEY) as PopularPaymentOptionType[] | undefined

      const saveData = (data: PopularPaymentOptionType[]) => {
        const availableAndPopular = data
          ?.map((popular) => {
            const found = availablePaymentOptions?.find((pmt) => pmt.id === popular.id)
            if (found !== undefined) return found
            else return undefined
          })
          ?.filter((pmt) => pmt !== undefined) as PaymentOptionDataType[]
        if (availableAndPopular) {
          setPopularPaymentOptions(availableAndPopular)
          appStorage.setJSON(STORAGE_KEY, data)
        }
      }

      if (storedData && storedData.length > 0) {
        let nowMinusOneMonth = new Date()
        nowMinusOneMonth.setDate(nowMinusOneMonth.getMonth() - 1)
        const LAST_MONTH_STORAGE_KEY = `${nowMinusOneMonth.getMonth
          }-${nowMinusOneMonth.getFullYear()}-${roleId}-popular-pmts`
        saveData(storedData)
        // clear last month if found just to prevent this from building up
        if (appStorage.getJSON('LAST_MONTH_STORAGE_KEY')) {
          appStorage.clear(LAST_MONTH_STORAGE_KEY)
        }
      } else {
        restClientInstance('CUSTOM_GET', 'custom', {
          url: `orgs/${orgId}/roles/${roleId}/payment_options/popular/`,
        })
          .then((res) => {
            if (res.data.success) {
              saveData(res.data.popular_payment_options)
            }
          })
          .catch((err) => {
            console.log('err', err)
          })
      }
    }
  }, [availablePaymentOptions, hasUsFinance])

  useEffect(() => {
    if (availablePaymentOptions && hasUsFinance) {
      setFavoritePaymentOptions(availablePaymentOptions?.filter((pmt) => pmt?.auto_apply_enabled))
    }
  }, [availablePaymentOptions, hasUsFinance])

  const loanpalChannels = useSelector(authSelectors.getLoanpalChannels)
  const ignoreChannels = useSelector(authSelectors.getIgnoreLoanpalChannels)

  const mosaicConnected = useSelector((state: RootState) => {
    const currentRole = authSelectors.getCurrentRole(state)
    return currentRole?.mosaic_enabled
  })

  const thisRoleConnectedToBrighte = useSelector(authSelectors.getBrighteConnected)
  const brighteConnected =
    window.WorkspaceHelper.project?.brighte_role_connection_status === 'connected' ||
    (window.WorkspaceHelper?.project.brighte_role_connection_status === 'no_role' && thisRoleConnectedToBrighte)
  const thisUserIsAssigned = roleId === window.WorkspaceHelper.project?.assigned_role_data?.id

  const addPendingPmt = () => {
    handleAddPaymentOptionOverride(pendingUuid, pendingPmtId)
    setPendingPmtId(undefined)
    setPendingPmtOrgId(undefined)
    setPendingUuid(undefined)
  }

  const handleAddPaymentOptionOverride = (uuid, url, ignorePhoenix = false) => {
    const system = window.editor.objectByUuid(uuid)
    var newValue = [...system['payment_options_override'], url]
    if (phoenixEnabled && !ignorePhoenix) {
      // phoenix has a unique requirement where the payment option priority cannot change project-to-project
      // so we force phoenix to always be last and then default to sorting by ascending term. But the pro can set
      // priority on the payment option if they want to change the order of just their phoenix products
      let phoenixPamyentOptions: number[] = []
      let nonPhoenixPaymentOptions: number[] = []
      newValue?.forEach((pmtId) => {
        let pmt = availablePaymentOptions?.find((pmt) => pmt.id === pmtId)
        let isPhoenix = false
        if (pmt) {
          let integration = parseJsonSafe(pmt.configuration_json)?.integration
          if (integration === 'phoenix') {
            isPhoenix = true
          }
        }
        if (pmt?.id) {
          if (isPhoenix) phoenixPamyentOptions.push(pmt.id)
          else nonPhoenixPaymentOptions.push(pmt.id)
        }
      })
      newValue = [...nonPhoenixPaymentOptions, ...phoenixPamyentOptions]
    }
    window.editor.execute(new window.SetValueCommand(system, 'payment_options_override', newValue))
  }

  const handleRemovePaymentOptionOverride = (uuid, url) => {
    const system = window.editor.objectByUuid(uuid)
    var newValue = [...(system['payment_options_override'] || [])]
    newValue.splice(system['payment_options_override'].indexOf(url), 1)
    window.editor.execute(new window.SetValueCommand(system, 'payment_options_override', newValue))
  }

  const handleSungageItcChange = (e: ChangeEvent<HTMLInputElement>, paymentOptionData: PaymentOptionDataType) => {
    let version18Mo = getSungage18MoVersion(paymentOptionData, availablePaymentOptions)
    if (e.target.checked) {
      // remove the June version, add the 18 month version
      if (props.system.payment_options_override?.find((pmtId) => pmtId === paymentOptionData.id)) {
        handleRemovePaymentOptionOverride(props.system.uuid, paymentOptionData.id)
      }
      if (version18Mo) handleAddPaymentOptionOverride(props.system.uuid, version18Mo.id)
    } else {
      // remove the 18 month version, add the June version
      if (version18Mo && props.system.payment_options_override?.find((pmtId) => pmtId === version18Mo?.id)) {
        handleRemovePaymentOptionOverride(props.system.uuid, version18Mo.id)
      }
      handleAddPaymentOptionOverride(props.system.uuid, paymentOptionData.id)
    }
  }

  const getIs18MonthItc = (paymentOptionData: PaymentOptionDataType) => {
    // we don't render the 18 month ITC version unless the org is using Classic 18. So rendered Sungage Advantage paymentOptionData will always be the June version
    // this function is meant to determine which of the two the user has actually selected
    let version18Mo = getSungage18MoVersion(paymentOptionData, availablePaymentOptions)
    let found18MoMatch = false
    props.system.payment_options_override?.forEach((pmtId) => {
      if (version18Mo && pmtId === version18Mo.id) found18MoMatch = true
    })
    return found18MoMatch
  }

  const handleRemovePayment = (paymentOptionData: PaymentOptionDataType, integration: string) => {
    if (integration === 'sungage') {
      if (getIs18MonthItc(paymentOptionData)) {
        handleRemovePaymentOptionOverride(
          props.system.uuid,
          getSungage18MoVersion(paymentOptionData, availablePaymentOptions)?.id
        )
      } else {
        handleRemovePaymentOptionOverride(props.system.uuid, paymentOptionData.id)
      }
    } else handleRemovePaymentOptionOverride(props.system.uuid, paymentOptionData.id)
  }

  const onChange = (
    event: ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    paymentOptionData: PaymentOptionDataType
  ) => {
    const integration = parseJsonSafe(paymentOptionData.configuration_json)?.integration
    // @ts-ignore checked does actually exist on event.target
    if (event.target.checked) {
      if (integration === 'mosaic' && !mosaicConnected) {
        setShowMosaicRoleDialog(true)
        setPendingUuid(props.system.uuid)
        setPendingPmtId(paymentOptionData.id)
        setPendingPmtOrgId(paymentOptionData.org_id)
        return
      }
      if (integration === 'sungage' && !sungageConnected) {
        setShowSungageRoleDialog(true)
        setPendingUuid(props.system.uuid)
        setPendingPmtId(paymentOptionData.id)
        setPendingPmtOrgId(paymentOptionData.org_id)
        return
      }
      if (integration === 'brighte' && !brighteConnected && thisUserIsAssigned) {
        setShowBrighteRoleDialog(true)
        setPendingUuid(props.system.uuid)
        setPendingPmtId(paymentOptionData.id)
        setPendingPmtOrgId(paymentOptionData.org_id)
        return
      }
      // protect against adding two of the same pmt to a system
      if (
        !props.system.payment_options_override ||
        props.system.payment_options_override?.indexOf(paymentOptionData.id) === -1
      ) {
        handleAddPaymentOptionOverride(props.system.uuid, paymentOptionData.id)
      }
    } else {
      handleRemovePayment(paymentOptionData, integration)
    }
  }

  const onPhoenixChange = (isEnabled: boolean, sortedPhoenixPmtIds: number[]) => {
    if (isEnabled) {
      sortedPhoenixPmtIds?.forEach((phoenixPmtId) => {
        //protect against adding two of the same pmt to a system
        if (
          !props.system.payment_options_override ||
          props.system.payment_options_override?.indexOf(phoenixPmtId) === -1
        ) {
          handleAddPaymentOptionOverride(props.system.uuid, phoenixPmtId, true)
        }
      })
    } else {
      sortedPhoenixPmtIds?.forEach((phoenixPmtId) => {
        handleRemovePaymentOptionOverride(props.system.uuid, phoenixPmtId)
      })
    }
  }

  const getSelectedIndex = useCallback(
    (paymentOptionData: PaymentOptionDataType) => {
      if (parseJsonSafe(paymentOptionData.configuration_json)?.integration === 'sungage') {
        let version18Mo = getSungage18MoVersion(paymentOptionData, availablePaymentOptions)
        let version18MoIndex = version18Mo ? props.system.payment_options_override.indexOf(version18Mo.id) : -1
        if (version18MoIndex >= 0) return version18MoIndex // only return this if found, we may find the non-18 mo version
      }
      let selectedIndex = props.system?.payment_options_override?.indexOf(paymentOptionData.id)
      return selectedIndex
    },
    [props.system.payment_options_override, availablePaymentOptions]
  )

  return (
    <>
      <hr style={{ backgroundColor: 'rgba(0,0,0,0.30)', height: '1px', border: 'none' }} />
      {hasUsFinance && props.system.payment_options_override && (
        <div>
          <TieredPaymentOptionSection
            systemUuid={props.system.uuid}
            systemPaymentOptionOverride={props.system.payment_options_override}
            disabled={false}
            pricingIsLocked={pricingIsLocked}
            isArchived={false}
            paymentOptions={favoritePaymentOptions}
            availablePaymentOptions={availablePaymentOptions}
            onChange={onChange}
            handleSungageItcChange={handleSungageItcChange}
            pendingPmtId={pendingPmtId}
            getSelectedIndex={getSelectedIndex}
            title="Favorite"
            defaultSungageIdsForActve18Mo={defaultSungageIdsForActve18Mo}
          />
          <TieredPaymentOptionSection
            systemUuid={props.system.uuid}
            systemPaymentOptionOverride={props.system.payment_options_override}
            disabled={false}
            pricingIsLocked={pricingIsLocked}
            isArchived={false}
            paymentOptions={popularPaymentOptions}
            availablePaymentOptions={availablePaymentOptions}
            onChange={onChange}
            handleSungageItcChange={handleSungageItcChange}
            pendingPmtId={pendingPmtId}
            getSelectedIndex={getSelectedIndex}
            title="Most Used"
            defaultSungageIdsForActve18Mo={defaultSungageIdsForActve18Mo}
          />
          <TieredPaymentOptionSection
            systemUuid={props.system.uuid}
            systemPaymentOptionOverride={props.system.payment_options_override}
            disabled={false}
            pricingIsLocked={pricingIsLocked}
            isArchived={false}
            paymentOptions={availablePaymentOptions}
            availablePaymentOptions={availablePaymentOptions}
            onChange={onChange}
            handleSungageItcChange={handleSungageItcChange}
            pendingPmtId={pendingPmtId}
            getSelectedIndex={getSelectedIndex}
            title="All Payment Options"
            defaultSungageIdsForActve18Mo={defaultSungageIdsForActve18Mo}
          />
        </div>
      )}
      {!hasUsFinance && props.system.payment_options_override && (
        <PaymentOptionCheckList
          systemUuid={props.system.uuid}
          disabled={false}
          pricingIsLocked={pricingIsLocked}
          isArchived={false}
          availablePaymentOptions={availablePaymentOptions}
          onChange={onChange}
          onPhoenixChange={onPhoenixChange}
          handleSungageItcChange={handleSungageItcChange}
          pendingPmtId={pendingPmtId}
          getSelectedIndex={getSelectedIndex}
          paymentOption={undefined}
          isChecked={false}
          selectedIndex={0}
        />
      )}
      {showMosaicRoleDialog && (
        <Dialog open={true} onClose={() => setShowMosaicRoleDialog(false)}>
          <MuiDialogTitle>Start Selling Mosaic</MuiDialogTitle>
          <MuiDialogContent>
            <MosaicConnectRoleDialogContent
              project={window.WorkspaceHelper.project}
              onClose={() => setShowMosaicRoleDialog(false)}
              onSuccess={addPendingPmt}
              pendingPmtOrgId={pendingPmtOrgId}
            />
          </MuiDialogContent>
        </Dialog>
      )}
      {showSungageRoleDialog && (
        <Dialog open={true} onClose={() => setShowSungageRoleDialog(false)}>
          <MuiDialogTitle>Start Selling Sungage</MuiDialogTitle>
          <MuiDialogContent>
            <SungageConnectRoleDialogContent
              project={window.WorkspaceHelper.project}
              onClose={() => setShowSungageRoleDialog(false)}
              onSuccess={addPendingPmt}
              pendingPmtOrgId={pendingPmtOrgId}
            />
          </MuiDialogContent>
        </Dialog>
      )}
      {showBrighteRoleDialog && (
        <Dialog open={true} onClose={() => setShowBrighteRoleDialog(false)}>
          <MuiDialogTitle>Start Selling Brighte</MuiDialogTitle>
          <MuiDialogContent>
            <BrighteConnectRoleDialogContent
              project={window.WorkspaceHelper.project}
              onClose={() => setShowBrighteRoleDialog(false)}
              onSuccess={addPendingPmt}
              pendingPmtOrgId={pendingPmtOrgId}
            />
          </MuiDialogContent>
        </Dialog>
      )}
    </>
  )
}
export default PaymentOptionsList

import useFilter from 'elements/controller/filter/useFilter'
import useQuery from 'elements/controller/query/useQuery'
import { getAppliedFilters, hardwareFilterTransformer } from 'elements/hardwareSelector/Filters'
import type { LineItemLoadedDataType } from 'pages/ordering/type'
import { useMemo } from 'react'
import { useFeatureFlag } from 'util/split'
import type { ControlledCollectionHookLikeType, ControlledCollectionResultType } from './type'
import useOrderComponentsListBasic from './useOrderComponentsListBasic'
import usePersistentOrderingQueryParams from './usePersistentOrderingQueryParams'

const resource = 'components'

/*
  This hook returns a list of hardware component collections along with the associated data and handlers used for obtaining the results.
  The only difference from useQueryControlledCollection is this controller won't response to url change
*/
const useControlledCollection: ControlledCollectionHookLikeType = ({
  catalog,
  showExhibitedFirst,
  componentType,
  limit,
  defaultFilterValues,
}): ControlledCollectionResultType => {
  const { query, queryPresenter } = useQuery({ perPage: limit })
  const { filter, filterPresenter } = useFilter({
    defaultFilter: defaultFilterValues && {
      filterValues: defaultFilterValues,
      displayedFilters: {},
    },
    queryPresenter,
  })
  const enableHardwareFilterV2 = useFeatureFlag('hardware_filter_v2', 'on')

  const persistentFilterValues = usePersistentOrderingQueryParams({
    showExhibitedFirst,
    catalog,
    componentType,
  })

  const modifiableFilterValues = filter.filterValues

  const allFilterValues = useMemo(
    () => ({
      page: query.page,
      perPage: query.perPage,
      sort: query.sort,
      order: query.order,
      ...persistentFilterValues,
      ...modifiableFilterValues,
    }),
    [query, persistentFilterValues, modifiableFilterValues]
  )

  const filterValues = {
    ...persistentFilterValues,
    ...filter.filterValues,
  }

  const { data, ids, total, loading, loaded } = useOrderComponentsListBasic({
    resource,
    page: query.page,
    perPage: query.perPage,
    sort: query.sort,
    order: query.order,

    filterValues: enableHardwareFilterV2
      ? filterValues
      : hardwareFilterTransformer({ filterValues: getAppliedFilters(filterValues), componentType }),
  })

  const productData: LineItemLoadedDataType[] = []
  ids?.forEach((id) => {
    data?.[id] && productData.push(data[id])
  })

  return {
    setPage: queryPresenter.setPage,
    setPerPage: queryPresenter.setPerPage,
    setFilters: filterPresenter.setFilters,
    page: query.page,
    perPage: query.perPage,
    total,
    productData,
    loading,
    persistentFilterValues,
    modifiableFilterValues,
    allFilterValues,
  }
}

export default useControlledCollection

import { makeStyles } from '@material-ui/core'
import { Theme } from 'types/themes'

export const useStyles = makeStyles<Theme, {}>(
  (theme) => {
    return {
      orgSharingContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 10,
        width: '100%',
      },
      inputRow: {
        marginBottom: 20,
        marginTop: 0,
        display: 'flex',
      },
      row: {
        marginTop: 20,
        display: 'flex',
        alignItems: 'center',
        '& p': {
          margin: 0,
        },
      },
      closeIcon: {
        position: 'absolute',
        top: 6,
        right: 6,
      },
      shareDialog: {
        '& h1': {
          fontSize: 20,
          fontWeight: 'normal',
        },
        width: 'auto',
      },
      dialogActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 20,
        alignItems: 'center',
      },
      linkIcon: {
        width: 18,
      },
      infoIcon: {
        padding: 5,
        marginLeft: 10,
      },
      input: {
        margin: 0,
        width: '100%',
        flexGrow: 1,
      },
      choiceWithChip: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      },
    }
  },
  { name: 'ProjectOrgSharing' }
)

import { Typography } from '@material-ui/core'
import { memo } from 'react'

const SegenDisclaimer = () => (
  <Typography>
    <span>By sending the order, you are agreeing to Segen's </span>
    <a href="https://portal.segen.co.uk/Home/PrivacyPolicy" style={{ color: '#1751D0' }}>
      Privacy Policy
    </a>
    <span> and </span>
    <a href=" https://portal.segen.co.uk/reseller/docs/UKPG_Terms_of_Supply.pdf" style={{ color: '#1751D0' }}>
      Conditions of Use
    </a>
    <span>.</span>
  </Typography>
)

export default memo(SegenDisclaimer)

// @ts-nocheck
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { termsLink_external } from 'constants/links'
import React from 'react'
import { useTranslate } from 'react-admin'
import { StyledButton } from './common'

const WalletTermsAndConditionsDialog = (props) => {
  const translate = useTranslate()

  return (
    <Dialog open={props.open} scroll="paper">
      <DialogTitle>{translate('OpenSolar - Terms and conditions for wallet feature')}</DialogTitle>
      <DialogContent style={{ overflow: 'scroll' }}>
        <p>
          Any capitalised terms used in these terms and conditions which are not otherwise defined have the same meaning
          as those terms in the{' '}
          <a href={termsLink_external} target="_blank" rel="noopener noreferrer">
            OpenSolar User Terms and Conditions
          </a>
        </p>
        <ol>
          <li style={{ marginTop: '5px' }}>
            OpenSolar may make available to Users an account balance associated with that User’s Account (the{' '}
            <strong>"OS Wallet"</strong>). The OS Wallet is neither a bank account nor any kind of payment instrument,
            rather it functions as a prepaid balance to enable Users to purchase OpenSolar Services on the OpenSolar
            Site. You may place funds in your OS Wallet up to a maximum amount determined by OpenSolar, by credit card,
            prepaid card, promotional code, or any other payment method accepted by OpenSolar – noting that you may be
            required to agree to the terms of use of any payment processors we may use to facilitate these payments. You
            may be able to schedule automatic top-ups which will credit funds to your OS Wallet on a regular basis.
            There may be a delay between the time you credit funds to your OS Wallet and your ability to use them to
            purchase OpenSolar Services.
          </li>
          <li style={{ marginTop: '5px' }}>
            Certain restrictions may apply when crediting funds to your OS Wallet, for instance we may limit the amount
            you may have in your OS Wallet, the amount you may add per day/month and/or the amount you can spend using
            your OS Wallet over certain periods of time. Attempted use of or deposits into your OS Wallet that exceed
            these thresholds may not work. OpenSolar may change or impose different OS Wallet balance, usage and top-up
            limits from time to time.
          </li>
          <li style={{ marginTop: '5px' }}>
            You will be notified by e-mail of any change to the OS Wallet balance, usage and top-up limits at least
            thirty (30) calendar days before the entry into force of the change. Your continued use of your OS Wallet
            after the entry into force of the changes will constitute your acceptance of the changes. If you don’t agree
            to the changes, your only remedy is to terminate your OpenSolar Account or to cease use of your OS Wallet.
            You can apply for a refund of any remaining balance which will be processed in accordance with clause 9.
          </li>
          <li style={{ marginTop: '5px' }}>
            You may only use OS Wallet funds to purchase OpenSolar Services. Subject to clause 9 and applicable laws,
            funds added to the OS Wallet are non-refundable and non-transferable to another person’s OS Wallet. The OS
            Wallet is not a bank account or a payment instrument and OS Wallet funds:
            <ol type="a">
              <li style={{ marginTop: '5px' }}>do not constitute a personal property right or a deposit;</li>
              <li style={{ marginTop: '5px' }}>
                have no value outside of the OpenSolar Site and can only be used to purchase OpenSolar Services on the
                OpenSolar Site;
              </li>
              <li style={{ marginTop: '5px' }}>
                have no cash value and are not exchangeable for cash or any other type of currency;
              </li>
              <li style={{ marginTop: '5px' }}>
                do not accrue interest, dividends or other earnings and cannot be withdrawn;
              </li>
              <li style={{ marginTop: '5px' }}>
                that are deemed unclaimed property may be turned over to the applicable authority.
              </li>
            </ol>
          </li>
          <li style={{ marginTop: '5px' }}>
            OpenSolar may at any time cancel, pause or refuse to process any transaction into or out of your OS Wallet
            where we reasonably believe you may have breached these terms and conditions, or the terms of use of the
            OpenSolar Site, or where we reasonably believe any transaction to be suspicious, false or unauthorised.
          </li>
          <li style={{ marginTop: '5px' }}>
            OpenSolar may from time to time offer certain promotional credits on terms to be decided by OpenSolar, at
            its sole discretion. Promotional credits may only be used to purchase OpenSolar Services, and cannot be
            withdrawn from your OS Wallet or exchanged for cash.
          </li>
          <li style={{ marginTop: '5px' }}>
            Please note that the OS Wallet may not be available as a payment method for all OpenSolar Services, at our
            sole discretion. If your OS Wallet balance is insufficient to complete a transaction, you will be asked to
            either credit funds to your OS Wallet or choose an alternative payment method to finalize your order.
          </li>
          <li style={{ marginTop: '5px' }}>
            OpenSolar owns all rights, title and interest in the OS Wallet, including all Intellectual Property Rights
            in the OS Wallet. Given that the OS Wallet is a part of the OpenSolar Site, you must agree to the User Terms
            and Conditions which apply to the OpenSolar Site in order to use the OS Wallet.
          </li>
          <li style={{ marginTop: '5px' }}>
            Once activated, you will be able to terminate your OS Wallet by asking for its closure on
            support@opensolar.com. Please note that, upon termination of your OS Wallet, you will no longer be able to
            use your remaining OS Wallet balance, if any, and that, except where required by law, such remaining OS
            Wallet balance may be refunded to you at OpenSolar’s discretion. No promotional credits will be refunded to
            you, and will be forfeited if you close or terminate your OS Wallet.
          </li>
          <li style={{ marginTop: '5px' }}>
            We reserve the right to reduce your OS Wallet, suspend, deactivate and/or terminate your OS Wallet if, after
            investigation, we determine that you have violated these OS Wallet Terms or the OpenSolar Site User Terms
            and Conditions. In such event, you will no longer be able to use your OS Wallet balance, if any, and, except
            where required by law, such remaining OS Wallet balance will not be credited nor refunded to you, in whole
            or in part, and will not be converted into cash nor any other form of reimbursement.
          </li>
          <li style={{ marginTop: '5px' }}>
            We may also decide, at our sole discretion, to stop offering the OS Wallet service. In such event, we:
            <ol type="a">
              <li style={{ marginTop: '5px' }}>
                may provide you with sufficient prior notice to enable you to use your remaining OS Wallet funds;
              </li>
              <li style={{ marginTop: '5px' }}>may prevent you from adding funds to your OS Wallet, and</li>
              <li style={{ marginTop: '5px' }}>
                will provide a refund of your remaining OS Wallet funds, if any, once the termination is effective,
                solely where required by applicable law.
              </li>
            </ol>
          </li>
          <li style={{ marginTop: '5px' }}>
            Please note that you are responsible for all transactions done using your Account and/or your OS Wallet.
            This includes any transaction to credit funds to your OS Wallet and any transaction purchasing products or
            services on the OpenSolar Site using your OS Wallet balance. We strongly advise you not to give personal or
            Account information, including your password, to anyone. If you suspect that your Account has been
            compromised or suspect a potential transaction error on your OS Wallet, please contact us immediately at
            support@opensolar.com
          </li>
          <li style={{ marginTop: '5px' }}>
            Where the User is a VAT registered business in the United Kingdom, they must provide their VAT number upon
            registration to use the OS Wallet and must also notify us within seven (7) days of ceasing to have a valid
            VAT registration. Failure to provide the VAT details at registration, or any change in their VAT status
            within seven (7) days, may result in you being liable for payment of the VAT element on all previous and
            future invoices and you hereby indemnify OpenSolar in respect of all such amounts found to be due, by a
            competent authority, such as HMRC.
          </li>
          <li style={{ marginTop: '5px' }}>
            Where the User is a GST registered business in New Zealand, they must provide their GST number upon
            registration to use the OS Wallet and must also notify us within seven (7) days of ceasing to have a valid
            GST registration. Failure to provide the GST details at registration, or any change in their GST status
            within seven (7) days, may result in you being liable for payment of the GST element on all previous and
            future invoices and you hereby indemnify OpenSolar in respect of all such amounts found to be due, by a
            competent authority.
          </li>
          <li style={{ marginTop: '5px' }}>
            Please note that the OS Wallet may, from time to time, not be accessible or under maintenance. As a
            consequence, you may not be able to credit funds to your OS Wallet and/or purchase products or services on
            the OpenSolar Site using your OS Wallet balance during that period.
          </li>
          <li style={{ marginTop: '5px' }}>
            Titles and headings are for convenience only and do not control the meaning or interpretation of any
            provision of these OS Wallet Terms.
          </li>
          <li style={{ marginTop: '5px' }}>
            If any term of these OS Wallet Terms is to any extent invalid, illegal, or incapable of being enforced, such
            term shall be excluded to the extent of such invalidity, illegality, or unenforceability; all other terms
            hereof shall remain in full force and effect.
          </li>
          <li style={{ marginTop: '5px' }}>
            The failure or neglect by you or us to enforce any of the rights under these OS Wallet Terms will not be
            deemed to be a waiver of your or our rights.
          </li>
          <li style={{ marginTop: '5px' }}>
            Subject to applicable law, we will not be held liable for circumstances beyond our reasonable control.
          </li>
          <li style={{ marginTop: '5px' }}>
            We reserve the right to modify all or any portion of these OS Wallet Terms, at any time, temporarily or
            permanently and entirely at our discretion. The OS Wallet Terms that apply to your purchase, top-up or other
            activity are the ones in force at the time of that activity. Any change of these OS Wallet Terms will not
            apply retroactively to activities that you undertook before they were modified. By adding funds to your OS
            Wallet and/or purchasing a product or service on the OpenSolar Site using your OS Wallet balance after our
            modification of these OS Wallet Terms, you signify your acceptance of the OS Wallet Terms as modified.
          </li>
          <li style={{ marginTop: '5px' }}>
            These OS Wallet Terms will be governed in all respects by the laws of New South Wales, Australia. We
            encourage you to try and resolve disputes using certified mediation (such as online dispute resolution
            processes). If a dispute cannot be resolved then you and OpenSolar irrevocably submit to the non-exclusive
            jurisdiction of the courts of New South Wales, Australia.
          </li>
          <li style={{ marginTop: '5px' }}>
            You must provide a valid VAT registration number upon registering for Wallet. If a VAT registration number
            is no longer valid then you must inform OpenSolar with an email to{' '}
            <a href="mailto:support@opensolar.com">support@opensolar.com</a>.
          </li>
        </ol>
        <br />
        <br />
      </DialogContent>
      <DialogActions>
        <StyledButton
          onClick={() => {
            props.hide()
          }}
        >
          {translate('OK')}
        </StyledButton>
      </DialogActions>
    </Dialog>
  )
}

export default WalletTermsAndConditionsDialog

import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

export const useUiLibraryStyles = makeOpenSolarStyles(
  (theme) => ({
    page: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      width: '100%',
      margin: theme.spacing(2),
      gap: theme.spacing(1),
    },

    page_import: {
      padding: theme.spacing(1) + 'px !important',
      width: '100%',
    },

    page_links: {
      display: 'flex',
      gap: theme.spacing(2),
      margin: '0 4px',
    },

    page_children: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(2),
      flexWrap: 'wrap',

      '&>*': {
        flexGrow: 1,
      },
    },

    block: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      padding: theme.spacing(1),
    },

    block_children: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: theme.spacing(2),
      border: '1px solid ' + theme.palette.grey[100],
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(2),
    },

    block_grid: {
      // Checkered BG
      backgroundImage:
        'linear-gradient(45deg, #fbfbfb 25%, transparent 25%, transparent 75%, #fbfbfb 75%, #fbfbfb 100%),linear-gradient(45deg, #fbfbfb 25%, white 25%, white 75%, #fbfbfb 75%, #fbfbfb 100%)',
      backgroundSize: '20px 20px',
      backgroundPosition: '0px 0px, 10px 10px',
    },

    featGrid: {
      display: 'table',
    },
    featGrid_row: {
      display: 'table-row',

      '&:nth-child(odd) > *:nth-child(odd)': {
        backgroundColor: theme.palette.grey[100],
      },

      '&:nth-child(odd) > *:nth-child(even)': {
        backgroundColor: theme.palette.grey[50],
      },

      '&:nth-child(even) > *:nth-child(even)': {
        backgroundColor: theme.palette.grey[40],
      },

      '&:nth-child(even) > *:nth-child(odd)': {
        backgroundColor: theme.palette.grey[50],
      },
    },
    featGrid_subheader: {
      fontSize: '1.6rem',
      padding: theme.spacing(1.8) + 'px !important',
      paddingTop: theme.spacing(2.2) + 'px !important',
    },
    featGrid_header: {
      backgroundColor: 'unset !important',
    },
    featGrid_cell: {
      display: 'table-cell',
      padding: theme.spacing(1),
      textAlign: 'center',
    },
    bodyText: {
      whiteSpace: 'pre-wrap',
      paddingBottom: theme.spacing(1),
      lineHeight: 1.5,
    },
  }),
  { name: 'UILibrary' }
)

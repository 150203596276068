import { RootState } from 'types/state'

export const SET_STUDIO_MODE = 'SET_STUDIO_MODE'

export const setStudioMode = (value: StudioModeState) => ({
  type: SET_STUDIO_MODE,
  payload: value,
})

const defaultState = 'hidden'

export default function reducer(previousState: StudioModeState = defaultState, { type, payload }) {
  if (type === SET_STUDIO_MODE) {
    const state = Boolean(payload) ? payload : defaultState
    return state
  }
  return previousState
}

export const studioSelectors = {
  isInStudio: (state: RootState): boolean => {
    return state.studioMode === 'studio' || state.studioMode === 'studioLite'
  },
  isInteractive: (state: RootState): boolean => {
    return studioSelectors.isInStudio(state) || state.studioMode === 'explore'
  },
}

export type StudioModeState = 'studio' | 'studioLite' | 'hidden' | 'background' | 'project' | 'myenergy' | 'explore'

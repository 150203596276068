var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @ts-nocheck
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useReferenceArrayFieldController } from 'ra-core';
import React, { Children, cloneElement, memo } from 'react';
import { fieldPropTypes } from './types';
/**
 * A container component that fetches records from another resource specified
 * by an array of *ids* in current record.
 *
 * You must define the fields to be passed to the iterator component as children.
 *
 * @example Display all the products of the current order as datagrid
 * // order = {
 * //   id: 123,
 * //   product_ids: [456, 457, 458],
 * // }
 * <ReferenceArrayField label="Products" reference="products" source="product_ids">
 *     <Datagrid>
 *         <TextField source="id" />
 *         <TextField source="description" />
 *         <NumberField source="price" options={{ style: 'currency', currency: 'USD' }} />
 *         <EditButton />
 *     </Datagrid>
 * </ReferenceArrayField>
 *
 * @example Display all the categories of the current product as a list of chips
 * // product = {
 * //   id: 456,
 * //   category_ids: [11, 22, 33],
 * // }
 * <ReferenceArrayField label="Categories" reference="categories" source="category_ids">
 *     <SingleFieldList>
 *         <ChipField source="name" />
 *     </SingleFieldList>
 * </ReferenceArrayField>
 *
 */
var ReferenceArrayField = function (props) {
    var children = props.children, basePath = props.basePath, reference = props.reference, resource = props.resource, record = props.record, source = props.source;
    if (React.Children.count(children) !== 1) {
        throw new Error('<ReferenceArrayField> only accepts a single child (like <Datagrid>)');
    }
    return (_jsx(PureReferenceArrayFieldView, __assign({}, props, useReferenceArrayFieldController({
        basePath: basePath,
        reference: reference,
        resource: resource,
        record: record,
        source: source,
    }), { children: children })));
};
ReferenceArrayField.propTypes = __assign(__assign({}, fieldPropTypes), { addLabel: PropTypes.bool, basePath: PropTypes.string, classes: PropTypes.object, className: PropTypes.string, children: PropTypes.element.isRequired, label: PropTypes.string, record: PropTypes.any, reference: PropTypes.string.isRequired, resource: PropTypes.string, sortBy: PropTypes.string, source: PropTypes.string.isRequired });
ReferenceArrayField.defaultProps = {
    addLabel: true,
};
var useStyles = makeStyles(function (theme) { return ({
    progress: { marginTop: theme.spacing(2) },
}); }, { name: 'RaReferenceArrayField' });
export var ReferenceArrayFieldView = function (props) {
    var children = props.children, className = props.className, data = props.data, ids = props.ids, loaded = props.loaded, reference = props.reference, referenceBasePath = props.referenceBasePath;
    var classes = useStyles(props);
    if (!loaded) {
        return _jsx(LinearProgress, { className: classes.progress });
    }
    return cloneElement(Children.only(children), {
        className: className,
        resource: reference,
        ids: ids,
        data: data,
        loaded: loaded,
        basePath: referenceBasePath,
        currentSort: {},
    });
};
ReferenceArrayFieldView.propTypes = {
    classes: PropTypes.any,
    className: PropTypes.string,
    data: PropTypes.any,
    ids: PropTypes.array,
    loaded: PropTypes.bool,
    children: PropTypes.element.isRequired,
    reference: PropTypes.string.isRequired,
    referenceBasePath: PropTypes.string,
};
var PureReferenceArrayFieldView = memo(ReferenceArrayFieldView);
export default ReferenceArrayField;

import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import type { OpenSolarThemeType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import moment from 'moment'
import { useNotify } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import EditableNumberField from '../../components/EditableNumberField'
import useUpdateInventory from '../../inventory/hooks/useUpdateInventory'
import usePrimaryWarehouse from '../../location/hooks/usePrimaryWarehouse'
import type { InventoryForecastTransfer } from '../type'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  wrapper: {
    minHeight: 62,
    borderBottom: '1px solid #e7e7e7',
  },
}))

const ForecastDetailSummaryRow = ({
  code,
  transfer,
  reloadForecastTransfers,
  handleTouched,
}: {
  code: string
  transfer: InventoryForecastTransfer
  reloadForecastTransfers(): void
  handleTouched?(): void
}) => {
  const classes = useStyles()
  const notify = useNotify()
  const { isLoading: loadingPrimaryWarehouse, primaryWarehouse } = usePrimaryWarehouse()
  const { updateInventoryData } = useUpdateInventory()
  const handleOnHandChange = async (newValue: number) => {
    handleTouched?.()
    if (loadingPrimaryWarehouse) {
      notify('Loading primary warehouse, please try again', 'warning')
      return
    }
    if (primaryWarehouse === undefined) {
      notify('Unable to find primary warehouse', 'error')
      console.error('Unable to find primary warehouse in InventoryForecastDetail')
      return
    }
    await updateInventoryData({ locationId: primaryWarehouse.id, inventories: [{ code, on_hand: newValue }] })
    reloadForecastTransfers()
    logAmplitudeEvent('generic_button_clicked', {
      source: 'forecast_modal_on_hand',
      context: 'inventory_forecast_modal_on_hand',
    })
  }

  return (
    <Grid container item xs={12} alignItems={'center'} className={classes.wrapper}>
      <Grid item xs={1}>
        {/* icon placeholder */}
      </Grid>
      <Grid container item xs={4} style={{ paddingLeft: 15 }} alignItems={'center'}>
        <Typography variant="body2">Available on hand</Typography>
      </Grid>
      <Grid item xs={3}>
        <div style={{ display: 'flex', alignItems: 'center' }}>{`Today (${moment(new Date()).format('DD/MM')})`}</div>
      </Grid>
      <Grid item xs={2}></Grid>
      <Grid item xs={2}>
        <EditableNumberField value={transfer.on_hand} handleValueChange={handleOnHandChange} />
      </Grid>
    </Grid>
  )
}

export default ForecastDetailSummaryRow

import { logAmplitudeEvent } from 'amplitude/amplitude'
import lodashDebounce from 'lodash/debounce'
import set from 'lodash/set'
import { removeEmpty, removeKey } from 'react-admin'
import type { QueryPresenter } from '../query/queryPresenter'
import { Filter } from './filter'

const DEFAULT_DEBOUNCE = 500

export interface IFilterPresenter {
  hideFilter: (name: string) => void
  showFilter: (name: string, defaultValue: unknown) => void
  setFilters: (filterValues: { [key: string]: unknown }) => void
}

export class FilterPresenter implements IFilterPresenter {
  private debounce: number = DEFAULT_DEBOUNCE
  private queryPresenter: QueryPresenter
  private setFilter: React.Dispatch<React.SetStateAction<Filter>>

  constructor(
    setFilter: React.Dispatch<React.SetStateAction<Filter>>,
    queryPresenter: QueryPresenter,
    debounce?: number
  ) {
    this.queryPresenter = queryPresenter
    this.setFilter = setFilter
    if (debounce != null) {
      this.debounce = debounce
    }
  }

  private handleFilterChange = () => {
    this.queryPresenter.setPage(1)
  }

  hideFilter = (name: string) => {
    logAmplitudeEvent('generic_filter_interacted', {
      action: 'hid',
      key: name,
    })
    this.setFilter(({ displayedFilters, filterValues }) => {
      this.handleFilterChange()
      return {
        filterValues: removeKey(filterValues, name),
        displayedFilters: { ...displayedFilters, [name]: false },
      }
    })
  }

  showFilter = (name: string, defaultValue: unknown) => {
    logAmplitudeEvent('generic_filter_interacted', {
      action: 'showed',
      key: name,
    })
    this.setFilter(({ displayedFilters, filterValues }) => {
      this.handleFilterChange()
      return {
        filterValues: set(filterValues, name, defaultValue),
        displayedFilters: { ...displayedFilters, [name]: true },
      }
    })
  }

  private setNewFilter = (
    newFilterValues: { [key: string]: unknown },
    newDisplayedFilters?: { [key: string]: boolean }
  ) => {
    logAmplitudeEvent('generic_filter_interacted', {
      action: 'new_filter_set',
    })
    this.setFilter(({ displayedFilters }) => {
      this.handleFilterChange()
      return {
        filterValues: newFilterValues,
        displayedFilters: newDisplayedFilters || displayedFilters,
      }
    })
  }

  private debouncedSetNewFilter = lodashDebounce(this.setNewFilter, this.debounce)

  setFilters = (
    newFilterValues: { [key: string]: unknown },
    newDisplayedFilters?: { [key: string]: boolean },
    immediate?: boolean
  ) => {
    if (immediate) {
      this.setNewFilter(removeEmpty(newFilterValues), newDisplayedFilters)
    } else {
      this.debouncedSetNewFilter(removeEmpty(newFilterValues))
    }
  }
}

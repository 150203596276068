import { authSelectors } from 'ducks/auth'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { useSdkReady } from 'sdk/useSdkReady'

/**
 * Know whether auto-save is enabled.
 */

export function useAutoSaveProjectEnabled() {
  const formState = useFormState()
  const isStaff = useSelector(authSelectors.getIsStaff)
  const sdk = useSdkReady()
  return (formState.values.is_lite && !isStaff) || sdk?.resolvedConfig.project_autosave
}

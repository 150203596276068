import { Typography } from '@material-ui/core'
import type { StockLevelType } from 'pages/ordering/type'
import React from 'react'
import { formatDateString } from 'util/misc'

const ScheduledStocks = ({ stockLevels }: { stockLevels: StockLevelType[] }) => {
  return (
    <>
      {stockLevels.map((stockLevel: StockLevelType) => {
        return (
          <Typography variant="caption" key={stockLevel.planned_date}>
            <div>
              {formatDateString(stockLevel.planned_date)} ({stockLevel.quantity})
            </div>
          </Typography>
        )
      })}
    </>
  )
}

export default ScheduledStocks

import listQueryParamsFormatter from 'elements/input/listQueryParamsFormatter'
import type { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { useEffect, useMemo, useState } from 'react'
import { useDataProvider } from 'react-admin'
import { OtherComponentTypes } from '../../../types/otherComponent'
import { ComponentTypesV2 } from '../../../types/selectComponent'

const buildUrl = ({
  distributors,
  componentType,
  otherComponentTypes,
}: {
  distributors?: HardwareSupplierFilterKeyType[]
  componentType?: ComponentTypesV2[]
  otherComponentTypes?: Set<OtherComponentTypes>
}) => {
  let params = {}

  if (distributors && distributors.length > 0) {
    params['distributors'] = listQueryParamsFormatter.format({ value: distributors })
  }

  if (componentType) {
    params['types'] = listQueryParamsFormatter.format({ value: componentType })
  }

  const itemsToRemove = ['module', 'inverter', 'battery']
  componentType = componentType?.filter((item) => !itemsToRemove.includes(item))

  if (componentType && componentType.length > 0 && otherComponentTypes && otherComponentTypes.size > 0) {
    params['types'] = 'other'
    params['other_component_types'] = listQueryParamsFormatter.format({ value: otherComponentTypes })
  }

  const paramString = new URLSearchParams(params).toString()
  return 'manufacturer_list/' + (paramString ? `?${paramString}` : '')
}

const useGetDistributorAvailableBrands = () => {
  const dataProvider = useDataProvider()
  return async ({
    distributors,
    componentType,
    otherComponentTypes,
  }: {
    distributors?: HardwareSupplierFilterKeyType[]
    componentType?: ComponentTypesV2[]
    otherComponentTypes?: Set<OtherComponentTypes>
  }) => {
    const response = await dataProvider.CUSTOM_GET('manufacturer_list', {
      url: buildUrl({ distributors, componentType, otherComponentTypes }),
    })
    return response?.data
  }
}

const useMultiDistributorsAvailableBrands = ({
  distributors,
  componentType,
  otherComponentTypes,
}: {
  distributors: HardwareSupplierFilterKeyType[]
  componentType?: ComponentTypesV2[]
  otherComponentTypes?: Set<OtherComponentTypes>
}) => {
  const [brands, setBrands] = useState<string[]>(() => [])
  const getDistributorAvailableBrands = useGetDistributorAvailableBrands()
  useEffect(() => {
    getDistributorAvailableBrands({ distributors, componentType, otherComponentTypes }).then((brands) => {
      if (brands && brands.length > 0) {
        setBrands(brands)
      }
    })
  }, [listQueryParamsFormatter.format({ value: distributors }), JSON.stringify(componentType)])
  return useMemo(() => brands, [brands])
}

export default useMultiDistributorsAvailableBrands

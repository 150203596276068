import { logAmplitudeEvent } from 'amplitude/amplitude'
import BannerAd from 'elements/BannerAd'
import { styled } from 'opensolar-ui'
import { useEffect, useMemo } from 'react'
import type { ComponentTypes } from 'types/selectComponent'
import { DISTRIBUTOR_FILTER_KEY_V2 } from '../../../elements/hardwareFilter/distributor/DistributorFilterConfigV2Factory'
import useGroupedHardwareSelector from '../../../elements/hardwareSelectorV2/fetch/useGroupHardwareSelector'
import listQueryParamsFormatter from '../../../elements/input/listQueryParamsFormatter'
import { useFeatureFlag } from '../../../util/split'
import ConnectionBanner from '../banner/ConnectionBanner'
import TopBanner from '../banner/TopBanner'
import CollectionBlock from './CollectionBlock'
import DistributorConnection from './DistributorConnection/DistributorConnection'
import ProductGrid from './ProductGrid'
import { AVAILABILITY_FILTER_KEY_V2 } from '../../../elements/hardwareFilter/stockAvailability/AvailabilityFilterNodeFactoryV2'

type ConfigType = {
  title: string
  viewMoreLink: string
  componentType: ComponentTypes
}

const COLLECTION_CONFIG: ConfigType[] = [
  {
    title: 'Panels',
    componentType: 'module',
    viewMoreLink: '/shop/search/modules',
  },
  {
    title: 'Inverters',
    componentType: 'inverter',
    viewMoreLink: '/shop/search/inverters',
  },
  {
    title: 'Batteries',
    componentType: 'battery',
    viewMoreLink: '/shop/search/batteries',
  },
  {
    title: 'Mounting + BOS',
    componentType: 'other',
    viewMoreLink: '/shop/search/others',
  },
]

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
})

interface RootContainerProps {
  enabledMultiDistributor?: boolean
}

export const RootContainer = styled('div', {
  shouldForwardProp: () => true,
})<RootContainerProps>((props) => ({
  background: props.enabledMultiDistributor ? '#F6F9FC' : '',
  padding: '0 24px'
}))

const HomeCatalogMultiDistributor = ({ distributor }) => {
  const defaultFilterValues = useMemo(() => {
    return distributor
      ? {
          [DISTRIBUTOR_FILTER_KEY_V2]: listQueryParamsFormatter.format({ value: new Set([distributor]) }),
          [AVAILABILITY_FILTER_KEY_V2]: 'true',
        }
      : {[AVAILABILITY_FILTER_KEY_V2]: 'true'}
  }, [distributor])

  const { modules, inverters, batteries, mounting, loading } = useGroupedHardwareSelector(defaultFilterValues)

  const panelLink = distributor ? `/shop/search/module/distributor/${distributor}` : '/shop/search/modules'
  const inverterLink = distributor ? `/shop/search/inverters/distributor/${distributor}` : '/shop/search/inverter'
  const batteryLink = distributor ? `/shop/search/battery/distributor/${distributor}` : '/shop/search/battery'
  const otherLink = distributor ? `/shop/search/other/distributor/${distributor}` : '/shop/search/other'

  return (
    <Container>
      <ProductGrid carouselMode={true} componentsData={modules} title={'Shop Panels'} viewAllLink={panelLink} />
      <ProductGrid carouselMode={true} componentsData={inverters} title={'Shop Inverters'} viewAllLink={inverterLink} />
      <ProductGrid carouselMode={true} componentsData={batteries} title={'Shop Batteries'} viewAllLink={batteryLink} />
      <ProductGrid
        carouselMode={true}
        componentsData={mounting}
        title={'Shop Mounting + BOS'}
        viewAllLink={otherLink}
      />
    </Container>
  )
}

const OrderingHome = ({ distributor }: { distributor?: string }) => {
  const enabledHardwareShopV3 = useFeatureFlag('enable_multi_distributor_shop', 'on')

  useEffect(() => {
    logAmplitudeEvent('page_viewed', {
      page: 'hardware_home',
    })
  }, [])

  return (
    <RootContainer enabledMultiDistributor={enabledHardwareShopV3}>
      {enabledHardwareShopV3 ? <ConnectionBanner /> : <TopBanner adBannerPlacement="shop_home_top" />}

      {enabledHardwareShopV3 && <HomeCatalogMultiDistributor distributor={distributor} />}
      {!enabledHardwareShopV3 &&
        COLLECTION_CONFIG.map((config) => (
          <CollectionBlock
            trackSource="hardware_home"
            key={config.title}
            title={config.title}
            componentType={config.componentType}
            viewMoreLink={config.viewMoreLink}
          />
        ))}
      {enabledHardwareShopV3 ? (
        <DistributorConnection />
      ) : (
        <BannerAd
          formats={['leaderboard']}
          placement={`shop_home_bottom`}
          containerStyle={{ marginTop: 10, marginBottom: 20 }}
          requiredWindowSize={{ width: 960 }}
        />
      )}
    </RootContainer>
  )
}

export default OrderingHome

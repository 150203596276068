import { Alert } from 'opensolar-ui'
import { FC } from 'react'
import { CodeBlock } from '../CodeBlock'
import ComponentPage from '../ComponentPage'
import { FeatureGrid } from '../FeatureGrid'
import { AlertBasic, AlertSeverities, AlertVariants } from './AlertUsage'

const AlertUsage_code = require('!!raw-loader!./AlertUsage').default

export const AlertDemo: FC = () => {
  return (
    <ComponentPage
      name={'Alert'}
      import="import { Alert } from 'opensolar-ui'"
      description={'Alert is used to display messages/notifications of different severities'}
      links={{
        mui5: 'https://mui.com/material-ui/react-alert/',
      }}
    >
      <CodeBlock name="Alert Basic Usage" code={AlertUsage_code} codePart={1}>
        <AlertBasic />
      </CodeBlock>
      <CodeBlock name="Alert Severities" code={AlertUsage_code} codePart={2} description="How to use the severity prop">
        <AlertSeverities />
      </CodeBlock>
      <CodeBlock name="Alert Variants" code={AlertUsage_code} codePart={3} description="How to use the variant prop">
        <AlertVariants />
      </CodeBlock>
      <CodeBlock name="Alert Feature Grid" hideGrid={true}>
        <FeatureGrid
          component={Alert}
          propsGen={(row, col, lastHeader) => {
            return { children: <>Alert</>, ...row, ...col, ...lastHeader }
          }}
          colHeaders={[]}
          rowHeaders={[]}
          rows={[{ severity: 'info' }, { severity: 'success' }, { severity: 'warning' }, { severity: 'error' }]}
          cols={[{ variant: 'standard' }, { variant: 'outlined' }, { variant: 'filled' }]}
        />
      </CodeBlock>
    </ComponentPage>
  )
}

import { useCallback, useState } from 'react'
import { useDataProvider } from 'react-admin'
import type { InventoryLocationType } from '../type'

const useInventoryWarehouses = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [warehouses, setWarehouses] = useState<InventoryLocationType[] | undefined>(undefined)
  const dataProvider = useDataProvider()

  const loadWarehouses = useCallback(async () => {
    setIsLoading(true)
    await dataProvider
      .CUSTOM_GET(
        'inventory_locations/',
        {
          url: 'inventory_locations/',
        },
        {
          onSuccess: (response) => {
            setWarehouses(response.data)
          },
        }
      )
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return {
    loadingWarehouses: isLoading,
    warehouses,
    loadWarehouses,
  }
}

export default useInventoryWarehouses

import { Menu, MenuItem } from '@material-ui/core'
import { ArrowLeftSmIcon, ArrowRightSmIcon, Button, DotHorizontalIcon, styled, Typography } from 'opensolar-ui'
import React, { useState } from 'react'

const PageButtons = styled('span')({
  marginLeft: 15,
  display: 'flex',
  alignItems: 'center',
})

const ControlButtonWrapper = styled('div')({
  padding: '2px 12px 3px 12px',
  borderRadius: '7px',
  border: '1px solid #E6E6E6 !important',
})

const EllipsisButton = styled(Button)({
  border: 'none',
})

const PageNumberButton = styled(Button)`
  &.Mui-selected {
    border-radius: 4px;
    border: 2px solid #1751d0;
  }
`

const StyledMenu = styled(Menu)({
  '& .MuiPaper-root': {
    maxHeight: 248,
    width: '156px !important',
  },
})

type PaginationCustomActionsProps = {
  page: number
  totalPages: number
  handlePageChange: (event: React.MouseEvent<HTMLElement>, newPage: number) => void
}

const PaginationCustomActions = ({ page, totalPages, handlePageChange }: PaginationCustomActionsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [ellipsisPages, setEllipsisPages] = useState<number[]>([])

  const generatePageNumbers = () => {
    const displayedPages: number[] = []

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        displayedPages.push(i)
      }
    } else {
      displayedPages.push(1)

      if (page > 3) {
        displayedPages.push(-1) // Ellipsis
      }

      if (page === totalPages) {
        displayedPages.push(totalPages - 2)
        displayedPages.push(totalPages - 1)
      } else if (page === totalPages - 1) {
        displayedPages.push(page - 1)
        displayedPages.push(page)
      } else if (page === 1) {
        displayedPages.push(page + 1)
        displayedPages.push(page + 2)
      } else if (page === 2) {
        displayedPages.push(page)
        displayedPages.push(page + 1)
      } else {
        displayedPages.push(page - 1)
        displayedPages.push(page)
        displayedPages.push(page + 1)
      }

      if (page < totalPages - 2) {
        displayedPages.push(-2) // Ellipsis
      }

      displayedPages.push(totalPages)
    }

    return displayedPages
  }

  const displayedPages = generatePageNumbers()

  const handleEllipsisClick = (event: React.MouseEvent<HTMLElement>, startPage: number, endPage: number) => {
    setAnchorEl(event.currentTarget)
    const pages: number[] = []
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i)
    }
    setEllipsisPages(pages)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <PageButtons>
      <Button color="default" onClick={(event) => handlePageChange(event, page - 2)} disabled={page === 1}>
        <ControlButtonWrapper>
          <ArrowLeftSmIcon size={8} />
        </ControlButtonWrapper>
      </Button>
      {displayedPages.map((pageNumber, index) => (
        <React.Fragment key={index}>
          {pageNumber < 0 ? (
            <div>
              <EllipsisButton
                onClick={(event) =>
                  handleEllipsisClick(
                    event,
                    pageNumber === -1 ? 2 : displayedPages[index - 1] + 1,
                    pageNumber === -1 ? displayedPages[index + 1] - 1 : displayedPages[index + 1] - 1
                  )
                }
              >
                <Typography variant="body2">
                  <DotHorizontalIcon size={14} color="#7F7D83" />
                </Typography>
              </EllipsisButton>
              <StyledMenu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                getContentAnchorEl={null}
              >
                {ellipsisPages.map((ellipsisPage) => (
                  <MenuItem
                    key={ellipsisPage}
                    onClick={(event) => {
                      handlePageChange(event, ellipsisPage - 1)
                      handleClose()
                    }}
                  >
                    {ellipsisPage}
                  </MenuItem>
                ))}
              </StyledMenu>
            </div>
          ) : (
            <PageNumberButton color="default" onClick={(event) => handlePageChange(event, pageNumber - 1)}>
              <Typography variant="body2">{pageNumber}</Typography>
            </PageNumberButton>
          )}
        </React.Fragment>
      ))}
      <Button color="default" onClick={(event) => handlePageChange(event, page)} disabled={page === totalPages}>
        <ControlButtonWrapper>
          <ArrowRightSmIcon size={8} />
        </ControlButtonWrapper>
      </Button>
    </PageButtons>
  )
}

export default PaginationCustomActions

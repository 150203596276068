import { BATTERY_COMPATIBILITY_FILTER_NODE_KEY } from 'elements/hardwareFilter/batteryCompatibility/BatteryCompatibilityFilterNodeFactory'
import useBatteryCompatibilityFilterNode from 'elements/hardwareFilter/batteryCompatibility/useBatteryCompatibilityFilterNode'
import { CABLE_LENGTH_FILTER_KEY } from 'elements/hardwareFilter/cableLength/CableLengthFilterNodeFactory'
import useCableLengthFilterNode from 'elements/hardwareFilter/cableLength/useCableLengthFilterNode'
import { CABLE_THICKNESS_FILTER_KEY } from 'elements/hardwareFilter/cableThickness/CableThicknessFilterNodeFactory'
import useCableThicknessFilterNode from 'elements/hardwareFilter/cableThickness/useCableThicknessFilterNode'
import { CURRENT_RATING_FILTER_NODE_KEY } from 'elements/hardwareFilter/currentRating/CurrentRatingFilterNodeFactory'
import useCurrentRatingFilterNode from 'elements/hardwareFilter/currentRating/useCurrentRatingFilterNode'
import { CURRENT_FILTER_KEY } from 'elements/hardwareFilter/currentType/CurrentTypeFilterNodeFactory'
import useCurrentTypeFilterNode from 'elements/hardwareFilter/currentType/useCurrentTypeFilterNode'
import { DISTRIBUTOR_FILTER_KEY } from 'elements/hardwareFilter/distributor/DistributorFilterNodeFactory'
import useDistributorFilterNode from 'elements/hardwareFilter/distributor/useDistributorFilterNode'
import { DISTRIBUTOR_BRAND_FILTER_KEY } from 'elements/hardwareFilter/distributorBrand/DistributorBrandFilterNodeFactory'
import useDistributorBrandFilterNode from 'elements/hardwareFilter/distributorBrand/useDistributorBrandFilterNode'
import { EXHIBITOR_FILTER_KEY } from 'elements/hardwareFilter/exhibitorOrg/ExhibitorOrgFilterNodeFactory'
import useExhibitorOrgFilterNode from 'elements/hardwareFilter/exhibitorOrg/useExhibitorOrgFilterNode'
import { INVERTER_TYPE_FILTER_NODE_KEY } from 'elements/hardwareFilter/inverterType/InverterTypeFilterNodeFactory'
import useInverterTypeFilterNode from 'elements/hardwareFilter/inverterType/useInverterTypeFilterNode'
import { MANUFACTURER_FILTER_NODE_KEY } from 'elements/hardwareFilter/manufacturer/ManufacturerFilterNodeFactory'
import useManufacturerFilterNode from 'elements/hardwareFilter/manufacturer/useManufacturerFilterNode'
import { MPPT_QUANTITY_FILTER_KEY } from 'elements/hardwareFilter/mpptQuantity/MpptQuantityFilterNodeFactory'
import useMpptQuantityFilterNode from 'elements/hardwareFilter/mpptQuantity/useMpptQuantityFilterNode'
import { OTHER_COMPONENT_FILTER_KEY } from 'elements/hardwareFilter/otherComponentType/OtherComponentTypeFilterNodeFactory'
import useOtherComponentTypeFilter from 'elements/hardwareFilter/otherComponentType/useOtherComponentTypeFilter'
import { OWNER_ORG_FILTER_KEY } from 'elements/hardwareFilter/ownerOrg/OwnerOrgFilterNodeFactory'
import useOwnerOrgFilterNode from 'elements/hardwareFilter/ownerOrg/useOwnerOrgFilterNode'
import { PHASE_FILTER_KEY } from 'elements/hardwareFilter/phaseType/PhaseTypeFilterNodeFactory'
import usePhaseTypeFilterNode from 'elements/hardwareFilter/phaseType/usePhaseTypeFilterNode'
import { RATING_FILTER_NODE_KEY } from 'elements/hardwareFilter/rating/RatingFilterNodeFactory'
import useRatingFilterNode from 'elements/hardwareFilter/rating/useRatingFilterNode'
import { AVAILABILITY_FILTER_KEY } from 'elements/hardwareFilter/stockAvailability/AvailabilityFilterNodeFactory'
import useAvailabilityFilterNode from 'elements/hardwareFilter/stockAvailability/useAvailabilityFilterNode'

import type { FactoryHookType, FilterNodeKeyAndRenderer, FilterNodeKeysType } from 'elements/hardwareFilter/type'
import { VOLTAGE_AND_CURRENT_FILTER_KEY } from 'elements/hardwareFilter/voltageAndCurrent/VoltageAndCurrentFilterNodeFactory'
import useVoltageAndCurrentFilterNode from 'elements/hardwareFilter/voltageAndCurrent/useVoltageAndCurrentFilterNode'
import { WARRANTY_FILTER_KEY } from 'elements/hardwareFilter/warranty/WarrantyFilterNodeFactory'
import useWarrantyFilterNode from 'elements/hardwareFilter/warranty/useWarrantyFilterNode'
import { DISCOUNT_FILTER_KEY } from './discount/DiscountFilterNodeFactory'
import useDiscountTypeFilterNode from './discount/useDiscountTypeFilterNode'

import BasicMultiCheckBoxRenderer from './renderers/BasicMultiCheckBoxRenderer'
import BasicRadioRenderer from './renderers/BasicRadioButtonRenderer'

export const FILTER_NODE_KEY_AND_FACTORY: { [key in FilterNodeKeysType]: FactoryHookType } = {
  [DISTRIBUTOR_FILTER_KEY]: useDistributorFilterNode,
  [AVAILABILITY_FILTER_KEY]: useAvailabilityFilterNode,
  [OWNER_ORG_FILTER_KEY]: useOwnerOrgFilterNode,
  [EXHIBITOR_FILTER_KEY]: useExhibitorOrgFilterNode,
  [MANUFACTURER_FILTER_NODE_KEY]: useManufacturerFilterNode,
  [RATING_FILTER_NODE_KEY]: useRatingFilterNode,
  [WARRANTY_FILTER_KEY]: useWarrantyFilterNode,
  [INVERTER_TYPE_FILTER_NODE_KEY]: useInverterTypeFilterNode,
  [MPPT_QUANTITY_FILTER_KEY]: useMpptQuantityFilterNode,
  [BATTERY_COMPATIBILITY_FILTER_NODE_KEY]: useBatteryCompatibilityFilterNode,
  [OTHER_COMPONENT_FILTER_KEY]: useOtherComponentTypeFilter,
  [PHASE_FILTER_KEY]: usePhaseTypeFilterNode,
  [CURRENT_FILTER_KEY]: useCurrentTypeFilterNode,
  [CURRENT_RATING_FILTER_NODE_KEY]: useCurrentRatingFilterNode,
  [CABLE_LENGTH_FILTER_KEY]: useCableLengthFilterNode,
  [CABLE_THICKNESS_FILTER_KEY]: useCableThicknessFilterNode,
  [VOLTAGE_AND_CURRENT_FILTER_KEY]: useVoltageAndCurrentFilterNode,
  [DISTRIBUTOR_BRAND_FILTER_KEY]: useDistributorBrandFilterNode,
  [DISCOUNT_FILTER_KEY]: useDiscountTypeFilterNode,
}

export const LEFT_HAND_SIDE_HARDWARE_FILTERS: FilterNodeKeyAndRenderer[] = [
  {
    key: DISTRIBUTOR_FILTER_KEY,
    rendererComponent: BasicRadioRenderer,
    label: 'Distributor'
  },
  {
    key: AVAILABILITY_FILTER_KEY,
    rendererComponent: BasicRadioRenderer,
    label: 'Stock Availability'
  },
  {
    key: DISCOUNT_FILTER_KEY,
    rendererComponent: BasicRadioRenderer,
  },
  // MultiBox Renderer after API is ready
  {
    key: DISTRIBUTOR_BRAND_FILTER_KEY,
    rendererComponent: BasicMultiCheckBoxRenderer,
    label: 'Brand'
  },
  {
    key: RATING_FILTER_NODE_KEY,
    rendererComponent: BasicRadioRenderer,
    label: 'Power (W)'
  },
  {
    key: CURRENT_RATING_FILTER_NODE_KEY,
    rendererComponent: BasicRadioRenderer,
  },
  {
    key: MPPT_QUANTITY_FILTER_KEY,
    rendererComponent: BasicRadioRenderer,
  },
  {
    key: OTHER_COMPONENT_FILTER_KEY,
    rendererComponent: BasicRadioRenderer,
    label: 'Category'
  },

  {
    key: WARRANTY_FILTER_KEY,
    rendererComponent: BasicRadioRenderer,
  },
  {
    key: INVERTER_TYPE_FILTER_NODE_KEY,
    rendererComponent: BasicRadioRenderer,
  },
  {
    key: PHASE_FILTER_KEY,
    rendererComponent: BasicRadioRenderer,
    label: 'Phase'
  },

  {
    key: CURRENT_FILTER_KEY,
    rendererComponent: BasicRadioRenderer,
  },
  {
    key: CABLE_LENGTH_FILTER_KEY,
    rendererComponent: BasicRadioRenderer,
  },
  {
    key: CABLE_THICKNESS_FILTER_KEY,
    rendererComponent: BasicRadioRenderer,
  },
]

export const HARDWARE_FILTERS_DESIGN: FilterNodeKeysType[] = [
  MANUFACTURER_FILTER_NODE_KEY,
  DISTRIBUTOR_FILTER_KEY,
  AVAILABILITY_FILTER_KEY,
  RATING_FILTER_NODE_KEY,
  // EXHIBITOR_FILTER_KEY, not used
  WARRANTY_FILTER_KEY,
  INVERTER_TYPE_FILTER_NODE_KEY,
  MPPT_QUANTITY_FILTER_KEY,
  OTHER_COMPONENT_FILTER_KEY,
  PHASE_FILTER_KEY,
  CURRENT_FILTER_KEY,
  CURRENT_RATING_FILTER_NODE_KEY,
  CABLE_LENGTH_FILTER_KEY,
  CABLE_THICKNESS_FILTER_KEY,
  OWNER_ORG_FILTER_KEY,
  BATTERY_COMPATIBILITY_FILTER_NODE_KEY,
  VOLTAGE_AND_CURRENT_FILTER_KEY,
]

export const HARDWARE_FILTERS_ORDER: FilterNodeKeysType[] = [
  DISTRIBUTOR_FILTER_KEY,
  DISTRIBUTOR_BRAND_FILTER_KEY,
  DISCOUNT_FILTER_KEY,
  AVAILABILITY_FILTER_KEY,
  RATING_FILTER_NODE_KEY,
  // EXHIBITOR_FILTER_KEY, not used
  WARRANTY_FILTER_KEY,
  INVERTER_TYPE_FILTER_NODE_KEY,
  MPPT_QUANTITY_FILTER_KEY,
  OTHER_COMPONENT_FILTER_KEY,
  PHASE_FILTER_KEY,
  CURRENT_FILTER_KEY,
  CURRENT_RATING_FILTER_NODE_KEY,
  CABLE_LENGTH_FILTER_KEY,
  CABLE_THICKNESS_FILTER_KEY,
]


import { orgSelectors } from 'ducks/orgs'
import { UiSwitch } from 'elements/UiSwitch'
import { Box } from 'opensolar-ui'
import Panel from 'projectSections/sections/design/Panel'
import BuildablePanels from 'projectSections/sections/design/systems/tabs/panels/BuildablePanels'
import ComponentWarningBox from 'projectSections/sections/design/systems/warning/ComponentWarningBox'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { useStudioSignalsLazy } from 'Studio/signals/useStudioSignalsLazy'
import { ModuleGridDimensions, ModuleGridType, StudioItem } from 'types/global'
import { MeasurementUnits } from 'types/orgs'
import { metersToFeet } from 'util/misc'
import ExpansionPanelShading from '../ExpansionPanelShading'
import { handleSetShadingOverride, shadingTypeFromShadingOverride } from '../ShadingControls'
import { trimDecimalPlaces } from './helpers'
import ModuleGridSettings from './ModuleGridSettings'
import useStyles from './styles'

type PanelModuleGridPropTypes = {
  moduleGrid: ModuleGridType
  allowEdit?: boolean
}

const PanelModuleGrid = ({ moduleGrid, allowEdit = false }: PanelModuleGridPropTypes) => {
  const [_refresh, setRefresh] = useState(0)

  const measurements = useSelector(orgSelectors.getMeasurementUnits) || MeasurementUnits.metric
  const isMetric = measurements === MeasurementUnits.metric
  const [moduleCount, setModuleCount] = useState<number>(moduleGrid.moduleQuantity())
  const [moduleGridDimensions, setModuleGridDimensions] = useState<ModuleGridDimensions | undefined>()
  const currentSystem = moduleGrid.getSystem() || window.editor.selectedSystem
  const moduleGridIndex = currentSystem ? currentSystem.moduleGrids().indexOf(moduleGrid) : 0

  const [shadingOverride, setShadingOverride] = useState<number[]>(moduleGrid.shadingOverride || [])
  const [shadingOverrideType, setShadingOverrideType] = useState<string>(
    shadingTypeFromShadingOverride(moduleGrid.shadingOverride)
  )

  const translate = useTranslate()
  const classes = useStyles()

  const moduleGridWidth: number | null = useMemo(
    () =>
      !moduleGridDimensions ? null : isMetric ? moduleGridDimensions.width : metersToFeet(moduleGridDimensions.width),
    [isMetric, moduleGridDimensions]
  )
  const moduleGridHeight: number | null = useMemo(
    () =>
      !moduleGridDimensions ? null : isMetric ? moduleGridDimensions.height : metersToFeet(moduleGridDimensions.height),
    [isMetric, moduleGridDimensions]
  )

  const refreshShadingData = () => {
    setShadingOverride(moduleGrid.shadingOverride || [])
    setShadingOverrideType(shadingTypeFromShadingOverride(moduleGrid.shadingOverride))
  }

  useEffect(() => {
    setModuleGridDimensions(moduleGrid.getDimensions())
    setModuleCount(moduleGrid.moduleQuantity())
    refreshShadingData()
  }, [moduleGrid])

  const onObjectChanged = useCallback(
    (o: StudioItem, prop: string) => {
      setModuleGridDimensions(moduleGrid.getDimensions())
      if (o === moduleGrid && prop === 'cellsActive') {
        setModuleCount(moduleGrid.moduleQuantity())
      }
    },
    [moduleGrid]
  )

  const onShadingUpdated = useCallback(
    (o: StudioItem) => {
      if (o !== moduleGrid) return
      refreshShadingData()
    },
    [moduleGrid]
  )

  useStudioSignalsLazy(onObjectChanged, ['objectChanged'], undefined, {
    trackHandler: true,
    debounce: 0.5,
    maxWait: undefined,
  })

  useStudioSignalsLazy(onShadingUpdated, ['shadingUpdated'], undefined, { trackHandler: true })

  useStudioSignalsLazy(
    (type: string, animation: string) => {
      if (type === 'tween' && animation === 'animateSun') {
        setRefresh((prev) => (prev < 99 ? prev + 1 : 0)) // force re-render
      }
    },
    ['animationStop']
  )

  const decimalPlaces = isMetric ? 2 : 1
  return (
    <Panel
      showTools={allowEdit}
      state={{ allowEdit: allowEdit }}
      selectedObject={moduleGrid}
      title={translate('Panel Group') + ' ' + (moduleGridIndex + 1)}
      summary={
        moduleGridDimensions && (
          <div>
            {moduleCount} {translate('modules')} -{' '}
            {`${trimDecimalPlaces(moduleGridWidth, decimalPlaces)}${isMetric ? 'm' : 'ft'} x ${trimDecimalPlaces(
              moduleGridHeight,
              decimalPlaces
            )}${isMetric ? 'm' : 'ft'}`}{' '}
            - {`${moduleGridDimensions.moduleCountX} wide, ${moduleGridDimensions.moduleCountY} high`}
          </div>
        )
      }
      content={
        <div>
          <ComponentWarningBox componentIds={[moduleGrid.uuid]} />
          <Box margin={'10px 0'}>
            <ModuleGridSettings allowEdit={allowEdit} moduleGrids={[moduleGrid]} />
          </Box>
          <ExpansionPanelShading
            uiKey="studio.tabs.selected_panel_group.shading"
            // @TODO: this 'setStateParent' prop isn't strictly necessary here
            // it just complicates things needlessly. just handle the state in the subcomponent
            setStateParent={(newState: { shadingOverride: number[] }) => {
              setShadingOverride(newState.shadingOverride)
              setShadingOverrideType(shadingTypeFromShadingOverride(newState.shadingOverride))
            }}
            object={moduleGrid}
            state={{ shadingOverrideType, shadingOverride }}
            handleSetShadingOverride={handleSetShadingOverride}
            modulesForShadingGrid={moduleGrid.getModules()}
            diffuseShading={moduleGrid.diffuseShading}
            allowEdit={allowEdit}
          />
          <UiSwitch uiKey="studio.tabs.selected_panel_group.buildable_panels">
            {moduleGrid.getSystem() && (
              <BuildablePanels disabled={!allowEdit} system={moduleGrid.getSystem()} moduleGrid={moduleGrid} />
            )}
          </UiSwitch>
        </div>
      }
    />
  )
}

export default PanelModuleGrid

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var TextInputOutlineIcon = function (_a) {
    var variant = _a.variant, _b = _a.size, size = _b === void 0 ? '1rem' : _b, _c = _a.color, color = _c === void 0 ? 'currentColor' : _c, rest = __rest(_a, ["variant", "size", "color"]);
    var iconAttributes = {
        width: size,
        height: size,
        stroke: color,
    };
    switch (variant) {
        case 1:
            return (_jsxs("svg", __assign({ viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, rest, iconAttributes, { children: [_jsx("path", { d: "M4.80078 4.7998H8.40078", stroke: color, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M6.60039 8.39981H3.60039C2.93765 8.39981 2.40039 8.93706 2.40039 9.59981V14.3998C2.40039 15.0625 2.93765 15.5998 3.60039 15.5998H6.60039M10.8004 8.39981H20.4004C21.0631 8.39981 21.6004 8.93706 21.6004 9.59981V14.3998C21.6004 15.0625 21.0631 15.5998 20.4004 15.5998H10.8004M6.60039 19.1998V4.7998M4.80039 19.1998H8.40039", stroke: color, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })] })));
        case 2:
            return (_jsx("svg", __assign({ viewBox: "0 0 22 10", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, rest, iconAttributes, { children: _jsx("path", { d: "M5.60039 5.5999V4.3999M20.6004 2.5999V7.3999C20.6004 8.06264 20.0631 8.5999 19.4004 8.5999H2.60039C1.93765 8.5999 1.40039 8.06264 1.40039 7.3999V2.5999C1.40039 1.93716 1.93765 1.3999 2.60039 1.3999H19.4004C20.0631 1.3999 20.6004 1.93716 20.6004 2.5999Z", stroke: color, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }) })));
    }
};

import { InputLabel, makeStyles } from '@material-ui/core'
import { getSystemName } from 'Designer/hooks/useSystemName'
import { FormControl, Grid, MenuItem, Select } from 'opensolar-ui'
import Alert from 'pages/ordering/checkoutv3/preOrder/components/Alert'
import { getHardwareSectionPreOrderStyles } from 'pages/ordering/checkoutv3/preOrder/styles'
import { useTranslate } from 'ra-core'
import { memo, useContext } from 'react'
import { Theme } from 'types/themes'
import { DistributorDataContext, PreOrderPresenterContext, SystemContext } from './PreOrderProvider'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {},
  outletSelectSystemAlert: {
    marginBottom: 16,
  },
}))

const SystemOptionSection = () => {
  const systems = window.editor.getSystems()
  const classes = useStyles()
  const sectionStyles = getHardwareSectionPreOrderStyles()
  const distributorData = useContext(DistributorDataContext)
  const selectedSystem = useContext(SystemContext)
  const isOutlet = Object.keys(distributorData)[0] === 'outlet'
  const preOrderPresenter = useContext(PreOrderPresenterContext)
  const translate = useTranslate()

  const handleUpdateSelectedSystem = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const system = systems.find((item) => item.uuid === event.target.value)
    preOrderPresenter?.updateSystem(system)
  }

  return (
    <div className={`${classes.wrapper} ${sectionStyles.moduleFullWidth}`}>
      <div className={sectionStyles.sectionCardTop}>
        <span className={sectionStyles.headerTitle}>{translate(`1. Select System Option (${systems.length})`)}</span>
      </div>
      <div className={sectionStyles.sectionCardBottom}>
        <Grid container>
          {isOutlet && !selectedSystem && (
            <Grid item xs={12} className={classes.outletSelectSystemAlert}>
              <Alert
                title={''}
                subtitle={translate(
                  'Select a system option from your design to review items and delivery options before proceeding to checkout'
                )}
                severity="info"
              />
            </Grid>
          )}
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel style={{ marginBottom: '10px' }}>{translate('System Option')}</InputLabel>
              <Select value={selectedSystem?.uuid} onChange={handleUpdateSelectedSystem}>
                {systems.map((item) => (
                  <MenuItem value={item?.uuid} key={item?.uuid}>
                    {getSystemName(item, translate)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default memo(SystemOptionSection)

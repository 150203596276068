import type { DesignTabs } from 'reducer/designer/view'
import { isMountingComponent } from 'util/misc'
import { getBillIncentives, getIncentivesAppliedOverTime } from '../incentives/ShowAutoAppliedIncentiveGeneric'
import type { CompleteCheckMethod } from './type'

export const TAB_AND_COMPLETE_CHECK: { [key in DesignTabs]: CompleteCheckMethod } = {
  summary: (system) => true, // always true
  panels: (system) => system.getModules().length > 0,
  inverters: (system) => system.inverters().length > 0,
  batteries: (system) => system.batteries().length > 0,
  others: (system) => system.others().length > 0,
  scaffolding: (system) => !!system.custom_data?.scaffolding,
  pricing: (system) => !!system.pricing,
  incentives: (system) => {
    const billIncentives = getBillIncentives(system)
    const incentivesAppliedOverTime = getIncentivesAppliedOverTime(system)
    const upfrontCustomerIncentives = system?.pricing?.['incentive_to_customer']?.incentives || []
    const upfrontInstallerIncentives = system?.pricing?.['incentive_to_installer']?.incentives || []
    return (
      billIncentives.length > 0 ||
      incentivesAppliedOverTime.length > 0 ||
      upfrontCustomerIncentives.length > 0 ||
      upfrontInstallerIncentives.length > 0
    )
  },
  'payment options': (system) => system.payment_options?.length > 0,
  mounting: (system) => {
    return system.others().filter((x) => isMountingComponent(x.other_component_type)).length > 0
  },
}

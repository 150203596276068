var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @ts-nocheck
import { CoreAdminUI } from 'ra-core';
import { Layout as DefaultLayout, Loading, Login, Logout, NotFound } from 'ra-ui-materialui';
var AdminUI = function (props) { return _jsx(CoreAdminUI, __assign({}, props)); };
AdminUI.defaultProps = {
    layout: DefaultLayout,
    catchAll: NotFound,
    loading: Loading,
    loginPage: Login,
    logout: Logout,
};
export default AdminUI;

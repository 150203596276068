import { useMediaQuery } from '@material-ui/core'
import { styles } from 'Designer/Tools'
import { PrimaryButton } from 'elements/button/GenericButton'
import { BaseThemeType } from 'opensolar-ui'

type ActionButtonProps = {
  Icon: React.ElementType
  onClick(): void
  label: string
  isActive?: boolean
  disabled?: boolean
}

const ActionButton = ({ Icon, isActive = false, disabled = false, onClick, label }: ActionButtonProps) => {
  const isMobile = useMediaQuery((theme: BaseThemeType) => theme.breakpoints.down('xs'))

  const raisedButtonIconStyle = isMobile ? styles.mobileIconStyle : styles.iconStyle
  const raisedButtonButtonStyle = isMobile ? {} : { height: 48 }
  const raisedButtonLabelStyle = isMobile ? styles.mobileButtonLabel : styles.buttonLabel
  const raisedButtonStyle = isMobile ? styles.mobileButton : styles.button
  const startIconStyle = { margin: 0, width: 'unset', height: 'unset' }

  return (
    <PrimaryButton
      startIcon={<Icon style={raisedButtonIconStyle} />}
      buttonStyle={raisedButtonButtonStyle}
      labelWrapperStyle={raisedButtonLabelStyle}
      style={raisedButtonStyle}
      startIconStyle={startIconStyle}
      isActive={isActive}
      disabled={disabled}
      onClick={onClick}
      label={label}
    />
  )
}

export default ActionButton

import { authSelectors } from 'ducks/auth'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { customStorage } from './customLocalStorage'

const useLocalStorage = 'useLocalStorage'

// Add this var so that the session/local versions remain distinct
customStorage.dontCopy.push(useLocalStorage)

const restorePreviousSetting = () => {
  // Prefer value set in sessionStorage, should override localStorage
  let allowLocalStr = window.sessionStorage.getItem(useLocalStorage)
  if (!allowLocalStr) allowLocalStr = window.localStorage.getItem(useLocalStorage)
  customStorage.setAllowLocal(allowLocalStr === '1' && window.parent === window)
}
restorePreviousSetting()

export const useSessionStorageLogic = () => {
  const termsAccepted = useSelector(authSelectors.getHasAcceptedTerms)
  const autoLoginProject = useSelector(authSelectors.getAutoLoginProject)

  useMemo(() => {
    const allowLocal = (termsAccepted || customStorage.allowLocal) && !autoLoginProject && window.parent === window
    customStorage.setAllowLocal(allowLocal)

    window.sessionStorage.setItem(useLocalStorage, allowLocal ? '1' : '0')
    if (allowLocal) window.localStorage.setItem(useLocalStorage, '1')
  }, [termsAccepted, autoLoginProject])
}

import _ from 'lodash'
import { dayNames } from 'util/misc'
import { DateRange, ScheduleType } from '../types'
import { DEFAULT_YEAR, days, defaultSchedule } from '../utils'

export function dateRangeOverlaps(range: DateRange, range2: DateRange) {
  if (!range.start_date || !range.end_date || !range2.start_date || !range2.end_date) return false
  const a_start = Date.UTC(DEFAULT_YEAR, range.start_date.getMonth(), range.start_date.getDate())
  const a_end = Date.UTC(DEFAULT_YEAR, range.end_date.getMonth(), range.end_date.getDate())
  const b_start = Date.UTC(DEFAULT_YEAR, range2.start_date.getMonth(), range2.start_date.getDate())
  const b_end = Date.UTC(DEFAULT_YEAR, range2.end_date.getMonth(), range2.end_date.getDate())

  if (a_start > a_end && b_start > b_end) {
    return true
  } else if (a_start === b_start || a_start === b_end || a_end === b_start || a_end === b_end) {
    return true
  } else if (a_start > a_end && b_end > b_start) {
    if (a_end >= b_start) return true
    if (a_start <= b_end) return true
    return false
  } else if (b_start > b_end && a_end > a_start) {
    if (b_end >= a_start) return true
    if (b_start <= a_end) return true
    return false
  } else {
    if (a_start <= b_start && b_start <= a_end) return true
    if (a_start <= b_end && b_end <= a_end) return true
    if (b_start <= a_start && a_end <= b_end) return true
    return false
  }
}

export const errorMap = {
  noDateRange: 'invalid_date_range',
  noHoursSelected: 'invalid_hours',
  overlapping: 'overlapping',
}

const checkForDateRangeInput = (state: ScheduleType) =>
  !!state?.date_ranges?.find((range) => !!(range.start_date && range.end_date))

const checkForHourInput = (state: ScheduleType) =>
  state?.applicable_days_and_hours &&
  Object.keys(state.applicable_days_and_hours?.data).length > 0 &&
  !_.isEqual(state.applicable_days_and_hours?.data, defaultSchedule().applicable_days_and_hours.data)

const checkConflictDaysAndHour = (a: ScheduleType, b: ScheduleType): boolean => {
  for (const day of days) {
    let dayListA = a?.applicable_days_and_hours?.data[day] || []
    let dayListB = b?.applicable_days_and_hours?.data[day] || []

    for (let i = 0; i < dayListA.length; i++) {
      if (dayListA[i] && dayListB[i]) {
        return true
      }
    }
  }
  return false
}

export function findScheduleConflicts(state: ScheduleType, otherSchedules: ScheduleType[]): ScheduleType[] {
  let conflicts: ScheduleType[] = []
  if (checkForDateRangeInput(state)) {
    otherSchedules.forEach((schedule) => {
      if (!!schedule.date_ranges?.length) {
        state.date_ranges.forEach((range) => {
          if (range.start_date && range.end_date) {
            if (schedule.date_ranges.find((compareRange) => dateRangeOverlaps(range, compareRange))) {
              conflicts.push({ ...schedule, date_ranges: [] })
            }
          }
        })
      }
    })
  }
  if (checkForHourInput(state)) {
    otherSchedules.forEach((schedule) => {
      if (checkConflictDaysAndHour(state, schedule)) {
        conflicts.push({
          ...schedule,
          applicable_days_and_hours: { ...defaultSchedule().applicable_days_and_hours },
        })
      }
    })
  }

  return conflicts
}

function validateSchedule(state: ScheduleType, unavailableSlots: ScheduleType[]) {
  let errors: string[] = []

  if (!!state.date_ranges?.length) {
    state.date_ranges.forEach((range: DateRange) => {
      if (!range?.start_date || !range?.end_date) {
        errors.push(errorMap.noDateRange)
      }
    })
  }

  if (
    state.applicable_days_and_hours?.data &&
    Object.keys(state.applicable_days_and_hours?.data).length > 0 &&
    _.isEqual(state.applicable_days_and_hours?.data, defaultSchedule().applicable_days_and_hours.data)
  ) {
    errors.push(errorMap.noHoursSelected)
  }
  // //prevent invalid schedule error if user has no input yet
  // if (errors.includes(errorMap.noDateRange) && errors.includes(errorMap.noHoursSelected)) {
  //   errors = []
  // }
  let overlapErrors: string[] = []
  if (unavailableSlots && !!unavailableSlots.length) {
    if (checkForDateRangeInput(state) && checkForHourInput(state)) {
      unavailableSlots.forEach((schedule) => {
        if (checkForDateRangeInput(schedule)) {
          state.date_ranges.forEach((range) => {
            if (range.start_date && range.end_date) {
              schedule?.date_ranges?.forEach((compareRange) => {
                if (dateRangeOverlaps(range, compareRange)) {
                  if (!overlapErrors.includes('date_range')) overlapErrors.push('date_range')
                  return
                }
              })
            }
          })
        }
        if (checkForHourInput(schedule)) {
          const dayLists = state.applicable_days_and_hours?.data
          const unavailableList = schedule.applicable_days_and_hours?.data
          dayNames.forEach((day) => {
            const dayList1 = dayLists[day.toLowerCase()]
            const dayList2 = unavailableList[day.toLowerCase()]
            if (dayList1 && dayList2 && !!dayList1.filter((x) => x === true).length) {
              for (let i = 0; i < dayList1.length; i++) {
                if (dayList1[i] === dayList2[i] && dayList1[i] === true) {
                  if (!overlapErrors.includes('day_hours')) overlapErrors.push('day_hours')
                }
              }
            }
          })
        }
      })
    }
  }
  if (overlapErrors.length === 2) {
    errors.push(errorMap.overlapping)
  }

  return errors
}

export default validateSchedule

import getSsoStartLink from 'pages/auth/sso/getSsoStartLink'
import type { PartnerIdType } from 'pages/auth/sso/types'
import { useMemo } from 'react'
import { useTranslate } from 'react-admin'
import { storeAuthRedirectionToLocalStorage } from 'redirections/authRedirectionStore'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import BasicLink from './BasicLink'

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    textAlign: 'center',
    marginTop: '1em',
    fontSize: '11pt',
  },
  link: {
    whiteSpace: 'nowrap',
    marginLeft: 5,
  },
}))

const LoginWithPartnerPrompt = ({ displayName, partnerId, userPassRedirectUri, activate }: { displayName: string; partnerId: PartnerIdType; userPassRedirectUri?: string | undefined; activate?: Function | undefined }) => {
  /* 
  ssoLink can optionally be provided directly which avoids the need to call getSsoStartLink
  */
  const translate = useTranslate()
  const classes = useStyles()
  const resourceLink = useMemo(() => userPassRedirectUri || getSsoStartLink({ partnerId: partnerId }), [userPassRedirectUri, partnerId])
  const handleRedirect = (e?: any) => {
    if (e) {
      e.preventDefault()
    }
    if (resourceLink) {
      if (window.parent === window) {
        storeAuthRedirectionToLocalStorage()
        window.location.href = resourceLink
      } else {
        // running in iframe, unable to redirect for SSO login
        // 1. launch in popop window
        // 2. show placeholder which will a) poll for new token b) redirect and use token to login once it has been updated
        window.open(resourceLink)
        if (activate) {
          activate()
        }
      }
    }
  }
  return (
    <p className={classes.wrapper}>
      {translate('or')}
      <span className={classes.link}>
        <BasicLink
          id="link-login-with-partner"
          handleClick={handleRedirect}
          label={translate('Login with %{name}', {
            name: displayName,
          })}
        />
      </span>
    </p>
  )
}

export default LoginWithPartnerPrompt

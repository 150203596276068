var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import createStandardIcon from './createStandardIcon';
var MountingIconSvg = function (props) {
    var color = props.color, fill = props.fill, stroke = props.stroke;
    return (_jsxs("svg", __assign({ viewBox: "0 0 28 28", xmlns: "http://www.w3.org/2000/svg" }, props, { children: [_jsx("path", { d: "M26.0997 14.4531L2.06836 22.192", stroke: stroke, strokeWidth: "1.5", strokeLinecap: "round" }), _jsx("path", { d: "M19.9279 5.18164L2.61719 10.6482", stroke: stroke, strokeWidth: "1.5", strokeLinecap: "round" }), _jsx("path", { d: "M23.0937 12.2227L17.6758 13.9336", stroke: stroke, strokeWidth: "1.5", strokeLinecap: "round" }), _jsx("path", { d: "M9.53121 16.2891L4.11328 18", stroke: stroke, strokeWidth: "1.5", strokeLinecap: "round" }), _jsx("path", { d: "M6.36353 12.5575C6.21072 12.0991 5.71519 11.8513 5.25674 12.0041C4.79829 12.157 4.55052 12.6524 4.70334 13.1109L6.36353 12.5575ZM7.33223 15.4635L6.36353 12.5575L4.70334 13.1109L5.67203 16.017L7.33223 15.4635Z", fill: fill }), _jsx("path", { d: "M19.7249 8.58096C19.5721 8.12251 19.0765 7.87474 18.6181 8.02756C18.1596 8.18037 17.9119 8.6759 18.0646 9.13436L19.7249 8.58096ZM20.6936 11.487L19.7249 8.58096L18.0646 9.13436L19.0334 12.0405L20.6936 11.487Z", fill: fill })] })));
};
export var MountingIcon = createStandardIcon(MountingIconSvg);

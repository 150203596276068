export const SEND_INVITATIONS = 'SEND_INVITATIONS'
export const SEND_INVITATIONS_LOADING = 'SEND_INVITATIONS_LOADING'
export const SEND_INVITATIONS_FAILURE = 'SEND_INVITATIONS_FAILURE'
export const SEND_INVITATIONS_SUCCESS = 'SEND_INVITATIONS_SUCCESS'

export const sendInvitations = (id, data) => ({
  type: SEND_INVITATIONS,
  payload: { id, url: 'projects/' + id + '/invite_users/', data },
  meta: { resource: 'projects', fetch: 'CUSTOM_POST', cancelPrevious: false },
})

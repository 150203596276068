import { validateTiersInput } from '../common'

export const validateBatteryData = (values, translate) => {
  const errors = {}
  if (values.lookup_in_component_database === false) {
    if (!values.custom_data_field_code) {
      errors.custom_data_field_code = [translate('Code is required.')]
    }
    if (!values.custom_data_field_manufacturer_name) {
      errors.custom_data_field_manufacturer_name = [translate('Manufacturer Name is required.')]
    }
    if (!values.custom_data_field_kwh_optimal) {
      errors.custom_data_field_kwh_optimal = [translate('Total Energy is required.')]
    } else if (isNaN(values.custom_data_field_kwh_optimal)) {
      errors.custom_data_field_kwh_optimal = [translate('Total Energy should be a number.')]
    } else if (values.custom_data_field_kwh_optimal < 0) {
      errors.custom_data_field_kwh_optimal = [translate('Value should be greater than or equal to 0.')]
    }
    if (!values.custom_data_field_end_of_life_capacity) {
      errors.custom_data_field_end_of_life_capacity = [translate('End of Life Capacity is required.')]
    } else if (isNaN(values.custom_data_field_end_of_life_capacity)) {
      errors.custom_data_field_end_of_life_capacity = [translate('End of Life Capacity should be a number.')]
    } else if (values.custom_data_field_end_of_life_capacity < 0 || values.custom_data_field_end_of_life_capacity > 1) {
      errors.custom_data_field_end_of_life_capacity = [translate('Value should be between 0-1 (inclusive).')]
    }

    if (!values.custom_data_field_power_max_continuous) {
      errors.custom_data_field_power_max_continuous = [translate('Maximum Continuous Power cannot be 0.')]
    } else if (isNaN(values.custom_data_field_power_max_continuous)) {
      errors.custom_data_field_power_max_continuous = [translate('Maximum Continuous Power should be a number.')]
    } else if (values.custom_data_field_power_max_continuous < 0) {
      errors.custom_data_field_power_max_continuous = [translate('Value should be greater than or equal to 0.')]
    }

    if (!values.custom_data_field_aging_factor) {
      errors.custom_data_field_aging_factor = [translate('Aging Factor is required.')]
    } else if (isNaN(values.custom_data_field_aging_factor)) {
      errors.custom_data_field_aging_factor = [translate('Aging Factor should be a number.')]
    } else if (values.custom_data_field_aging_factor < 0 || values.custom_data_field_aging_factor > 1) {
      errors.custom_data_field_aging_factor = [translate('Value should be between 0-1 (inclusive).')]
    }

    if (!values.custom_data_field_depth_of_discharge_factor) {
      errors.custom_data_field_depth_of_discharge_factor = [translate('Depth of Discharge is required.')]
    } else if (isNaN(values.custom_data_field_depth_of_discharge_factor)) {
      errors.custom_data_field_depth_of_discharge_factor = [translate('Depth of Discharge should be a number.')]
    } else if (
      values.custom_data_field_depth_of_discharge_factor < 0 ||
      values.custom_data_field_depth_of_discharge_factor > 1
    ) {
      errors.custom_data_field_depth_of_discharge_factor = [translate('Value should be between 0-1 (inclusive).')]
    }

    if (!values.custom_data_field_warranty_kwh_1_cycle_per_day) {
      errors.custom_data_field_warranty_kwh_1_cycle_per_day = [translate('Total Warranted kWh is required.')]
    } else if (isNaN(values.custom_data_field_warranty_kwh_1_cycle_per_day)) {
      errors.custom_data_field_warranty_kwh_1_cycle_per_day = [translate('Total Warranted kWh should be a number.')]
    }

    if (!values.custom_data_field_efficiency_factor) {
      errors.custom_data_field_efficiency_factor = [translate('Roundtrip Efficiency is required.')]
    } else if (isNaN(values.custom_data_field_efficiency_factor)) {
      errors.custom_data_field_efficiency_factor = [translate('Roundtrip Efficiency should be a number.')]
    } else if (values.custom_data_field_efficiency_factor < 0 || values.custom_data_field_efficiency_factor > 1) {
      errors.custom_data_field_efficiency_factor = [translate('Value should be between 0%-100%.')]
    }

    if (!values.custom_data_field_voltage) {
      errors.custom_data_field_voltage = [translate('Nominal Voltage is required.')]
    } else if (isNaN(values.custom_data_field_voltage)) {
      errors.custom_data_field_voltage = [translate('Nominal Voltage should be a number.')]
    } else if (values.custom_data_field_voltage < 0) {
      errors.custom_data_field_voltage = [translate('Value should be greater than or equal to 0.')]
    }
  } else {
    if (!values.battery) {
      errors.battery = [translate('Please select a battery from dropdown.')]
    }
  }
  validateTiersInput(values, errors)
  return errors
}

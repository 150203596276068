import { FormControl, IconButton, InputAdornment, OutlinedInput, Typography } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { useCallback, useMemo, useState } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStylesSearchField = makeOpenSolarStyles((theme) => ({
  root: {
    borderRadius: '8px',
    '& .MuiOutlinedInput-input': {
      padding: '10px 0px',
    },
  },
  notFoundErrorMsg: {
    fontSize: '12px',
    paddingTop: '6px',
    paddingBottom: '6px',
  }
}))

const NOT_FOUND_MESSAGE = 'No results found.'

const SearchInput = ({ disabled, showWarning, searchText, handleChange }) => {
  const searchFieldStyle = useStylesSearchField()
  return (
    <FormControl variant="outlined">
      <OutlinedInput
        disabled={disabled}
        value={searchText}
        onChange={handleChange}
        startAdornment={
          <InputAdornment position="start">
            <IconButton edge="start">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
        className={searchFieldStyle.root}
      />
      {!!showWarning && <Typography color="error" className={searchFieldStyle.notFoundErrorMsg}>{NOT_FOUND_MESSAGE}</Typography>}
    </FormControl>
  )
}

type ReturnType<T> = {
  SearchInputNode: React.ReactNode
  filteredOptions: T[]
}

const useSearchInputController = <T extends { title: string }>({
  options,
  disabled,
}: {
  options: T[]
  disabled: boolean
}): ReturnType<T> => {
  const [searchText, setSearchText] = useState('')

  const filteredOptions = useMemo(() => {
    return options.filter((option) => option.title.toLowerCase().includes(searchText.toLowerCase()))
  }, [options, searchText])

  const showWarning = useMemo(() => {
    return searchText.length && filteredOptions.length === 0
  }, [searchText, filteredOptions])

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value
    setSearchText(searchText)
  }, [])

  const SearchInputNode = (
    <SearchInput showWarning={showWarning} disabled={disabled} searchText={searchText} handleChange={handleChange} />
  )

  return {
    SearchInputNode,
    filteredOptions,
  }
}

export default useSearchInputController

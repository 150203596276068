import Grid from '@material-ui/core/Grid'
import inflection from 'inflection'
import NumberInput from 'pages/inventory/components/NumberInput'
import { memo, useCallback } from 'react'
import { useNotify } from 'react-admin'
import type { BasicInventory, EditableInventoryFields } from '../../type'
import useTableStyles from '../useTableStyles'

const EditableInventoryTableRow = ({
  disabled,
  inventory,
  oldInventory,
  onInventoryUpdated,
  editMin,
  editOnHand,
}: {
  disabled: boolean
  inventory: BasicInventory
  oldInventory: BasicInventory | undefined
  onInventoryUpdated({ code, field, value }: { code: string; field: EditableInventoryFields; value: number }): void
  editMin?: boolean
  editOnHand?: boolean
}) => {
  const classes = useTableStyles()
  const notify = useNotify()
  const componentType = inventory.component_type
  const handleNumberInputUpdate = useCallback(
    (field: EditableInventoryFields) => {
      return (value: number) => {
        if (value < 0) {
          notify('Unable to assign a negative number', 'warning')
        }
        const finalValue = value < 0 ? 0 : value
        onInventoryUpdated({ code: inventory.code, field, value: finalValue })
      }
    },
    [inventory.code]
  )

  if (editMin || editOnHand) {
    return (
      <Grid container xs={12} alignItems={'center'} justify={'center'} className={classes.rowWrapper}>
        <Grid item xs={1}>
          {componentType && inflection.capitalize(componentType)}
        </Grid>
        <Grid item xs={4}>
          {inventory.code}
        </Grid>
        <Grid item xs={1} className={classes.gridItem}>
          <NumberInput
            disabled={disabled}
            value={editMin ? inventory.reorder_quantity : inventory.on_hand}
            placeholder={editMin ? oldInventory?.reorder_quantity.toString() : oldInventory?.on_hand.toString()}
            inputProps={{ min: 0 }}
            updateValue={editMin ? handleNumberInputUpdate('reorder_quantity') : handleNumberInputUpdate('on_hand')}
          />
        </Grid>
        <Grid item xs={1} className={classes.gridItem}>
          <span> {editMin ? oldInventory?.reorder_quantity : oldInventory?.on_hand}</span>
        </Grid>
        <Grid item xs={1} className={classes.gridItem}></Grid>
        <Grid item xs={2} className={classes.gridItem}></Grid>
        <Grid item xs={2} className={classes.gridItem}></Grid>
      </Grid>
    )
  }

  return (
    <Grid container xs={12} alignItems={'center'} justify={'center'} className={classes.rowWrapper}>
      <Grid item xs={1}>
        {componentType && inflection.capitalize(componentType)}
      </Grid>
      <Grid item xs={5}>
        {inventory.code}
      </Grid>
      <Grid item xs={1} className={classes.gridItem}>
        <NumberInput
          disabled={disabled}
          value={inventory.reorder_quantity}
          placeholder={oldInventory?.reorder_quantity.toString()}
          inputProps={{ min: 0 }}
          updateValue={handleNumberInputUpdate('reorder_quantity')}
        />
      </Grid>
      <Grid item xs={1} className={classes.gridItem}>
        <span> {editMin ? oldInventory?.reorder_quantity : oldInventory?.on_hand}</span>
      </Grid>
      <Grid item xs={1} className={classes.gridItem}>
        <NumberInput
          disabled={disabled}
          value={inventory.on_hand}
          placeholder={oldInventory?.on_hand.toString()}
          inputProps={{ min: 0 }}
          updateValue={handleNumberInputUpdate('on_hand')}
        />
      </Grid>
      <Grid item xs={1} className={classes.gridItem}>
        <span>{oldInventory?.on_hand}</span>
      </Grid>
      <Grid item xs={2} className={classes.gridItem}></Grid>
    </Grid>
  )
}

export default memo(EditableInventoryTableRow)

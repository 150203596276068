// @ts-nocheck
import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'

//To Do: add theme type
const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: 'rgba(250,250,250, 0.9)',
    marginTop: 10,
    width: '100%',
    '&:hover': {
      background: 'rgba(250,250,250, 1)',
    },
  },
  mobile: {
    backgroundColor: 'rgba(250,250,250, 0.9)',
    position: 'absolute',
    fontSize: 12,
    left: 20,
    bottom: -40,
    textTransform: 'none',
    '&:hover': {
      background: 'rgba(250,250,250, 1)',
    },
  },
  label: { padding: 0 },
}))

type PropTypes = {
  handleShowAllSystem: Function
}
const ViewAllSystemsButton = ({ handleShowAllSystem }: PropTypes) => {
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <>
      <Button className="view-all-systems-button" classes={{ root: classes.mobile }} onClick={handleShowAllSystem}>
        <span className={classes.label}>{translate('View All Systems')}</span>
      </Button>
    </>
  )
}

export default ViewAllSystemsButton

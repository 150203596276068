import {
  BatteryAltIcon,
  EvChargerAltIcon,
  HeatPumpAltIcon,
  SolarPanelsAltIcon,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  VehicleToGirdAltIcon,
} from 'opensolar-ui'
import { Field, useForm } from 'react-final-form'
import { ENA_LCTs, LctId } from './consts'

const BTN_ICONS: Record<LctId, React.ReactNode> = {
  SOLAR_PV: <SolarPanelsAltIcon />,
  BATTERY: <BatteryAltIcon />,
  EVV2G: <VehicleToGirdAltIcon />,
  HP: <HeatPumpAltIcon />,
  EVCP_AC: <EvChargerAltIcon />,
  EVCP_DC: <EvChargerAltIcon />,
}

export const ApplicationBegin = () => {
  const form = useForm()

  const getSchemaId = (lctsSelected: LctId[]) => {
    const schemasApplicable = new Set(lctsSelected.map((schemaId) => ENA_LCTs[schemaId].applicationClass))
    return schemasApplicable.size > 1 // If there is a mix of lctSchemas
      ? 'GENERATION_DEMAND_GENERAL' // the application class will be GenerationDemandGeneralV1
      : schemasApplicable.size === 1 // if only one type of lctSchema was selected
      ? schemasApplicable.values().next().value // the final schema will be that one
      : null
  }

  return (
    <>
      <Field
        name={'lcts'}
        render={({ input }) => {
          return (
            <StyledToggleButtonGroup
              {...input}
              value={input?.value}
              onChange={(e, value) => {
                if (value) {
                  input.onChange(value)
                  const applicationClass = getSchemaId(value)
                  if (applicationClass) {
                    form.change('applicationClass', applicationClass)
                  }
                }
              }}
            >
              {Object.keys(ENA_LCTs).map((i) => (
                <ToggleButton value={i} key={i}>
                  <LctBtnWrapper>
                    <div> {BTN_ICONS[i]}</div>
                    <div> {ENA_LCTs[i].label}</div>
                  </LctBtnWrapper>
                </ToggleButton>
              ))}
            </StyledToggleButtonGroup>
          )
        }}
      />
    </>
  )
}

const LctBtnWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  gap: 10,
  minWidth: '30%',
}))

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: 12,
  padding: 0,
  paddingTop: 16,
  backgroundColor: 'transparent',
  '& button': {
    padding: '32px 10px',
    border: '1px solid' + theme.palette.grey[300],
    borderLeft: '1px solid' + theme.palette.grey[300] + '!important',
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    minWidth: '30%',
    flex: 1,
    margin: 1,
  },
  '& > .Mui-selected': {
    border: '2px solid ' + theme.palette.info.contrastText + ' !important',
    margin: 0,
  },
}))

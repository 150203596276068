// these are agnostic to environment
export const greenLancerServices = [
  {
    title: 'Solar Permit Planset | G & A Design',
    id: 'OPENSOLAR_PERMIT',
    price: 140,
  },
  {
    title: 'Solar Permit Planset + Storage | G & A Design',
    id: 'OPENSOLAR_PERMIT_STORAGE',
    price: 210,
  },
  {
    title: 'ROOFTOP PE Review & Stamped Structural Pages & Stamped Structural Review Letter | Barun Corp',
    id: 'OPENSOLAR_SE',
    price: 225,
  },
  {
    title: 'PE Review & Stamped Electrical Pages | Barun Corp',
    id: 'OPENSOLAR_EE',
    price: 245,
  },
  {
    title: 'Solar Permit Plan Set | SunSource',
    id: 'OPENSOLAR_SUNSOURCE_PERMIT',
    price: 105,
  },
]

export const ironRidgeGreenLancerServices = [
  {
    title: 'Permit Planset (without PE stamps)',
    id: 'IRONRIDGE_GREENLANCER_PERMIT',
    price: 89,
  },
  {
    title: 'Structural PE Review and electronic stamp',
    id: 'IRONRIDGE_SE',
    price: 149,
  },
  {
    title: 'Electrical PE Review and electronic stamp',
    id: 'IRONRIDGE_EE',
    price: 149,
  },
]

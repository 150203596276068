import { Grid, makeStyles } from '@material-ui/core'
import { AddOutlined, EditOutlined } from '@material-ui/icons'
import ChipsInput from 'elements/input/ChipsInput'
import { List } from 'elements/react-admin/List'
import ListActions from 'elements/react-admin/ListActions'
import React, { useEffect } from 'react'
import {
  BooleanInput,
  Button,
  Create,
  Datagrid,
  Edit,
  FunctionField,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  required,
  useNotify,
  useRedirect,
} from 'react-admin'
import { useHistory } from 'react-router-dom'
import { ROLE_VALIDATION_TYPE_CHOICES } from './constants'

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const getFinancePartnerId = () => {
  const path = window.location.hash.substring(window.location.hash.indexOf('#') + 2)
  const params = new URLSearchParams(path.substring(path.indexOf('?')))
  const paramStr = params.get('finance_partner_id')
  return paramStr ? parseInt(paramStr) : undefined
}

export const FinanceValidationSetsList = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const financePartnerId = getFinancePartnerId()

  useEffect(() => {
    if (!financePartnerId) {
      history.push('/finance_partners')
    }
  }, [financePartnerId])

  const CustomCreateButton = () => (
    <Button
      label="Create a Validation Set"
      onClick={() => history.push(`/finance_validation_sets/create?finance_partner_id=${financePartnerId}`)}
      startIcon={<AddOutlined />}
      variant="outlined"
    />
  )

  const goToEdit = (record) => {
    history.push(`/finance_validation_sets/${record.id}?finance_partner_id=${financePartnerId}`)
  }
  return (
    <List
      hasSearch={true}
      actions={<ListActions CustomButton={CustomCreateButton()} />}
      {...props}
      hasCreate={false}
      filter={{ finance_partner_id: financePartnerId }}
    >
      <Datagrid>
        <TextField source="name" />
        <TextField source="finance_partner_integration_name" label="Integration" />
        <FunctionField
          source="finance_product"
          label="Actions"
          render={(record: any, source, rest) => (
            <div className={classes.row}>
              <Button startIcon={<EditOutlined />} label="Edit" onClick={() => goToEdit(record)} />
            </div>
          )}
          sortable={false}
        />
      </Datagrid>
    </List>
  )
}

const FinanceValidationSetsFields = (props) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <TextInput source="name" validate={[required()]} fullWidth />
      </Grid>
      <Grid item xs={6}>
        {/* @ts-ignore */}
        <ReferenceInput
          label="Finance Partner"
          reference="finance_partners"
          source="finance_partner"
          fullWidth
          style={{ width: '100%' }}
          disabled={true}
        >
          <SelectInput optionText="display_name" optionValue="url" source="finance_partner" disabled={true} />
        </ReferenceInput>
      </Grid>

      <Grid item xs={6}>
        <h4>Finance Amount validation</h4>
      </Grid>
      <Grid item xs={6}>
        <h4>Cost per watt validation</h4>
      </Grid>
      <Grid item xs={3}>
        <TextInput source="min_finance_amount" fullWidth />
      </Grid>
      <Grid item xs={3}>
        <TextInput source="max_finance_amount" fullWidth />
      </Grid>
      <Grid item xs={3}>
        <TextInput source="min_cost_per_watt" fullWidth />
      </Grid>
      <Grid item xs={3}>
        <TextInput source="max_cost_per_watt" fullWidth />
      </Grid>

      <Grid item xs={12}>
        <h4>Down payment / deposit validation</h4>
      </Grid>
      <Grid item xs={3}>
        <TextInput source="min_downpayment_fixed" fullWidth />
      </Grid>
      <Grid item xs={3}>
        <TextInput source="max_downpayment_fixed" fullWidth />
      </Grid>
      <Grid item xs={3}>
        <TextInput source="min_downpayment_pct" fullWidth />
      </Grid>
      <Grid item xs={3}>
        <TextInput source="max_downpayment_pct" fullWidth />
      </Grid>

      <Grid item xs={12}>
        <h4>Battery price validation</h4>
      </Grid>
      <Grid item xs={3}>
        <TextInput source="min_battery_price_fixed" fullWidth />
      </Grid>
      <Grid item xs={3}>
        <TextInput source="max_battery_price_fixed" fullWidth />
      </Grid>
      <Grid item xs={3}>
        <TextInput source="min_battery_price_pct" fullWidth />
      </Grid>
      <Grid item xs={3}>
        <TextInput source="max_battery_price_pct" fullWidth />
      </Grid>

      <Grid item xs={12}>
        <h4>Solar price validation</h4>
      </Grid>
      <Grid item xs={3}>
        <TextInput source="min_solar_price_fixed" fullWidth />
      </Grid>
      <Grid item xs={3}>
        <TextInput source="max_solar_price_fixed" fullWidth />
      </Grid>
      <Grid item xs={3}>
        <TextInput source="min_solar_price_pct" fullWidth />
      </Grid>
      <Grid item xs={3}>
        <TextInput source="max_solar_price_pct" fullWidth />
      </Grid>

      <Grid item xs={12}>
        <h4>Other (non-solar and non-battery) price validation</h4>
      </Grid>
      <Grid item xs={3}>
        <TextInput source="min_nonsolar_price_fixed" fullWidth />
      </Grid>
      <Grid item xs={3}>
        <TextInput source="max_nonsolar_price_fixed" fullWidth />
      </Grid>
      <Grid item xs={3}>
        <TextInput source="min_nonsolar_price_pct" fullWidth />
      </Grid>
      <Grid item xs={3}>
        <TextInput source="max_nonsolar_price_pct" fullWidth />
      </Grid>

      <Grid item xs={12}>
        <h4>Hardware Validation</h4>
      </Grid>
      <Grid item xs={3}>
        <TextInput source="min_solar_size" fullWidth />
      </Grid>
      <Grid item xs={3}>
        <TextInput source="max_solar_size" fullWidth />
      </Grid>
      <Grid item xs={3}>
        <TextInput source="min_battery_size" fullWidth />
      </Grid>
      <Grid item xs={3}>
        <TextInput source="max_battery_size" fullWidth />
      </Grid>
      <Grid item xs={12}>
        {/* @ts-ignore */}
        <ReferenceArrayInput
          label="Module Manufacturers Allowed (leave blank if no restrictions)"
          reference="manufacturers"
          source="module_manufacturers_allowed"
          fullWidth
          style={{ width: '100%' }}
        >
          <ChipsInput optionText="name" optionValue="url" source="module_manufacturers_allowed" />
        </ReferenceArrayInput>
      </Grid>

      <Grid item xs={12}>
        <h4>Utility Inflation Validation</h4>
      </Grid>
      <Grid item xs={6}>
        <TextInput source="max_utility_inflation_pct" fullWidth />
      </Grid>

      <Grid item xs={12}>
        <h4>Geographic Restrictions</h4>
      </Grid>
      <Grid item xs={6}>
        <TextInput
          label="States Allowed (Comma Separated list of abbreviations eg CA,IL)"
          source="states_allowed"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        {/* @ts-ignore */}
        <ReferenceArrayInput
          label="Countried Allowed (leave blank if no restrictions)"
          reference="countries"
          source="countries_allowed"
          fullWidth
          style={{ width: '100%' }}
        >
          <ChipsInput optionText="name" optionValue="url" source="countries_allowed" />
        </ReferenceArrayInput>
      </Grid>
      <Grid item xs={12}>
        <h4>System Type Restrictions</h4>
      </Grid>
      <Grid item xs={6}>
        <BooleanInput source="residential_only" defaultChecked={false} />
      </Grid>
      <Grid item xs={6}>
        <BooleanInput source="commercial_only" defaultChecked={false} />
      </Grid>
      <Grid item xs={12}>
        <h4>Assigned Role/salesperson validation</h4>
      </Grid>
      <Grid item xs={6}>
        <SelectInput source="role_validation_type" choices={ROLE_VALIDATION_TYPE_CHOICES} optionText="label" />
      </Grid>
    </Grid>
  )
}

export const FinanceValidationSetsEdit = (props) => {
  const financePartnerId = getFinancePartnerId()

  return (
    <Edit
      {...props}
      hasDelete={true}
      redirect={(resource, id, data) => `/finance_products?finance_partner_id=${financePartnerId}`}
    >
      <SimpleForm>
        <FinanceValidationSetsFields {...props} />
      </SimpleForm>
    </Edit>
  )
}

export const FinanceValidationSetsCreate = (props) => {
  const financePartnerId = getFinancePartnerId()
  const redirect = useRedirect()
  const notify = useNotify()

  const onSuccess = (data) => {
    notify('Financing Product Created')
    redirect(`/finance_products?finance_partner_id=${financePartnerId}`)
  }
  return (
    <Create {...props} mutationOptions={{ onSuccess }}>
      <SimpleForm initialValues={{ finance_partner_id: financePartnerId }}>
        <FinanceValidationSetsFields {...props} />
      </SimpleForm>
    </Create>
  )
}

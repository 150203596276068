import { Divider, Grid, Typography } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { orderSelectors } from 'ducks/orderComponents'
import { orgSelectors } from 'ducks/orgs'
import { CartDiscount, getCartDiscount, getCartDiscountValue } from 'pages/ordering/order/CartDiscounts'
import { getOrderCost } from 'pages/ordering/utils'
import { useTranslate } from 'ra-core'
import { memo, useContext, useEffect, useMemo, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import type { SegenQuoteItemType } from 'services/segen/type'
import { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { formatCurrency } from 'util/misc'
import { useCheckoutStyles } from '../styles'
import { QuoteContext } from './SegenCheckoutProvider'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  actionWrapper: {
    boxSizing: 'border-box',
    border: theme.border.default,
    borderRadius: '8px',
    flexShrink: 0,
    overflow: 'hidden',
  },
  placeOrderSectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    backgroundColor: '#F8F8F8',
    gap: '20px',
    borderBottom: '8px',
  },
  placeOrderSectionItem: {
    display: 'flex',
    justifyContent: 'center',
  },
  spaceBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  linkText: {
    fontSize: '12px',
    lineHeight: '16px',
  },
  orderSummaryBreakdownItem: {
    margin: '8px 0px',
  },
  orderSummaryBreakdownItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}))

const getTotalCostFromQuoteItems = (quoteItems: SegenQuoteItemType[]) => {
  return quoteItems.reduce((sum, cur) => {
    if (cur.productCode === 'DELIVERY') {
      return sum
    }
    return sum + cur.totalPrice.amount
  }, 0)
}

const getDeliveryCostFromQuoteItems = (quoteItems: SegenQuoteItemType[]) => {
  return quoteItems.reduce((sum, cur) => {
    if (cur.productCode === 'DELIVERY') {
      return sum + cur.totalPrice.amount
    }
    return sum
  }, 0)
}

const TypographyWithAnimation = ({ loading, children }) => {
  const checkoutClasses = useCheckoutStyles()
  if (loading) {
    return <Skeleton />
  }
  return <Typography className={checkoutClasses.font14}>{children}</Typography>
}

const OrderSummaryBreakdownItem = ({
  label,
  value,
  style,
  loading,
}: {
  label: string
  value: string
  style?: CSSProperties
  loading: boolean
}) => {
  const checkoutClasses = useCheckoutStyles()
  const classes = useStyles()
  return (
    <Grid item className={classes.orderSummaryBreakdownItemContainer} style={style}>
      <Typography className={checkoutClasses.font14}>{label}</Typography>
      <TypographyWithAnimation loading={loading}>{value}</TypographyWithAnimation>
    </Grid>
  )
}

const OrderSummary = ({ currencySymbol }: { currencySymbol: string | undefined }) => {
  const { loading, quote } = useContext(QuoteContext)
  const [cartDiscount, setCartDiscount] = useState<CartDiscount>(null)
  const selectedSupplier = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const checkoutClasses = useCheckoutStyles()
  const classes = useStyles()
  const translate = useTranslate()
  const quoteItems = quote?.items
  const orderableLineItems = useSelector(orderSelectors.getOrderableLineItems)
  useEffect(() => {
    getCartDiscount(orderableLineItems, selectedSupplier, (cartDiscount) => {
      setCartDiscount(cartDiscount)
    })
  }, [orderableLineItems, selectedSupplier])

  const itemsCost = useMemo(() => getOrderCost(orderableLineItems, false), [orderableLineItems])
  const itemsCostWithDiscounts: number = useMemo(() => getOrderCost(orderableLineItems, true), [
    orderableLineItems,
  ]) as number
  const tradeDiscountValue = itemsCost - itemsCostWithDiscounts
  const totalsResponse = quote?.totalsResponse
  const deliveryCost = useMemo(() => !!quoteItems && getDeliveryCostFromQuoteItems(quoteItems), [quoteItems])
  const tax = totalsResponse?.tax.value
  const netPrice = totalsResponse?.netPrice.amount
  const total = totalsResponse?.grossTotal.amount
  const cartDiscountValue = useMemo(() => getCartDiscountValue(itemsCostWithDiscounts, cartDiscount), [
    itemsCostWithDiscounts,
    cartDiscount,
  ])

  return (
    <Grid container direction="column">
      <Grid item className={classes.spaceBetweenRow}>
        <Typography variant="h6" className={checkoutClasses.font18}>
          Order Summary
        </Typography>
      </Grid>
      <OrderSummaryBreakdownItem
        label="Shipping & Handling"
        value={`${currencySymbol} ${formatCurrency(deliveryCost)}`}
        style={{ marginTop: '24px' }}
        loading={loading}
      />
      <OrderSummaryBreakdownItem
        label="Items"
        value={`${currencySymbol} ${formatCurrency(itemsCost)}`}
        style={{ marginTop: '8px' }}
        loading={loading}
      />
      {Boolean(tradeDiscountValue) && (
        <OrderSummaryBreakdownItem
          label="Trade Discount"
          value={`-${currencySymbol} ${formatCurrency(tradeDiscountValue)}`}
          style={{ marginTop: '8px', color: '#018030' }}
          loading={loading}
        />
      )}
      {cartDiscount && Boolean(cartDiscountValue) && (
        <OrderSummaryBreakdownItem
          label={translate(cartDiscount?.discountName)}
          value={`-${currencySymbol} ${formatCurrency(cartDiscountValue)}`}
          style={{ marginTop: '8px', color: '#018030' }}
          loading={loading}
        />
      )}

      <Grid item className={`${classes.spaceBetweenRow} ${classes.orderSummaryBreakdownItem}`}>
        <Typography className={checkoutClasses.font14}></Typography>
        <Divider style={{ width: '70px' }} />
      </Grid>
      <OrderSummaryBreakdownItem
        label="Total before tax:"
        value={`${currencySymbol} ${formatCurrency(netPrice)}`}
        loading={loading}
      />
      <OrderSummaryBreakdownItem
        label="VAT (20%):"
        value={`${currencySymbol} ${formatCurrency(tax)}`}
        style={{ marginTop: '8px' }}
        loading={loading}
      />
      <Divider style={{ marginTop: '8px' }} />
      <Grid item className={`${classes.spaceBetweenRow} ${classes.orderSummaryBreakdownItem}`}>
        <Typography className={`${checkoutClasses.fontWeightMedium} ${checkoutClasses.font16}`}>
          Order Total:
        </Typography>
        <Typography
          className={`${checkoutClasses.fontWeightMedium} ${checkoutClasses.font16}`}
          style={{ color: '#018030' }}
        >
          {`${currencySymbol} ${formatCurrency(total)}`}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default memo(OrderSummary)

import { StepConnector, Tooltip, withStyles } from '@material-ui/core'
import Step from '@material-ui/core/Step'
import { StepIconProps } from '@material-ui/core/StepIcon'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import { CheckOutlined } from '@material-ui/icons'
import { Checkbox, FormControlLabel, styled, Typography } from 'opensolar-ui'
import usePartiallyAcceptTransfer from 'pages/inventory/transfer/hooks/usePartiallyAcceptTransfer'
import { OrderContext } from 'projectSections/sections/hardware'
import { FullWidthWrapper } from 'projectSections/sections/hardware/postOrdering/orderActivity/OrderActivitySection'
import { useNotify } from 'ra-core'
import { useContext, useEffect, useState } from 'react'
import { ORDER_STATUS } from 'resources/inventoryTransfer/constants'
import { COLOR_PALETTE } from '../styles'
import useUpdateOrderStepperStatus from './hooks/useUpdateOrderStepperStatus'

const StepLabelWrapper = styled(StepLabel)({
  alignItems: 'flex-start',
})

const StepContentWrapper = styled('div')({
  textAlign: 'left',
})

const StepIconWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
})

const BlueCheckOutlined = styled(CheckOutlined)({
  height: '16px',
  width: '16px',
  color: 'white',
  backgroundColor: '#1751D0',
  borderRadius: '50px',
  padding: '11px',
  marginBottom: '6px',
  zIndex: 2,
})

const NextCircle = styled('div')(({ theme }) => ({
  borderRadius: '50px',
  backgroundColor: '#1751D0',
  color: theme.palette.common.white,
  height: '36px',
  width: '36px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '14px',
  marginTop: '-3px',
  border: '2px solid #618DF2',
  backgroundClip: 'content-box',
  padding: '2px',
  marginBottom: '5px',
  zIndex: 2,
}))

const GreyCircle = styled('div')(({ theme }) => ({
  borderRadius: '50px',
  backgroundColor: COLOR_PALETTE.lightGrey,
  color: theme.palette.grey[600],
  height: '36px',
  width: '36px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '14px',
  marginBottom: '8px',
}))

const Connector = withStyles({
  alternativeLabel: {
    top: 20,
    left: 'calc(-90% + 16px)',
    right: 'calc(85% + 16px)',
    zIndex: 1,
  },
  active: {
    '& $line': {
      borderColor: '#4272DD',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#4272DD',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector)

export const getOrderSteps = (distributor) => {
  switch (distributor) {
    case 'segen':
      return [ORDER_STATUS.QUOTE_SUBMITTED, ORDER_STATUS.ORDERED, ORDER_STATUS.PROCESSING, ORDER_STATUS.DELIVERED]
    default:
      return [ORDER_STATUS.ORDERED, ORDER_STATUS.PROCESSING, ORDER_STATUS.DELIVERED]
  }
}

const StepIcon = (props: StepIconProps) => {
  const { active, completed, icon } = props

  return (
    <StepIconWrapper>
      {completed && <BlueCheckOutlined />}
      {active && <NextCircle>{icon}</NextCircle>}
      {!completed && !active && <GreyCircle>{icon}</GreyCircle>}
    </StepIconWrapper>
  )
}

const OrderStatusStepper = ({ order, handleStatus }) => {
  const notify = useNotify()
  const steps = getOrderSteps(order?.distributor || 'outlet')
  const [activeStep, setActiveStep] = useState(0)
  const { updateOrderStepperStatus } = useUpdateOrderStepperStatus()
  const { partiallyAcceptTransfer } = usePartiallyAcceptTransfer()
  const { reloadOrderData } = useContext(OrderContext)

  const convertStatusToStep = (status) => {
    if (order.distributor === 'segen') {
      switch (status) {
        case ORDER_STATUS.QUOTE_SUBMITTED:
          return 0
        case ORDER_STATUS.ORDERED:
          return 1
        case ORDER_STATUS.PROCESSING:
          return 2
        case ORDER_STATUS.DELIVERED:
          return 3
        default:
          return 0
      }
    }
    switch (status) {
      case ORDER_STATUS.ORDERED:
        return 0
      case ORDER_STATUS.PROCESSING:
        return 1
      case ORDER_STATUS.DELIVERED:
        return 2
      default:
        return 0
    }
  }

  const convertStepToStatus = (step, distributor) => {
    if (distributor === 'segen') {
      switch (step) {
        case 0:
          return ORDER_STATUS.QUOTE_SUBMITTED
        case 1:
          return ORDER_STATUS.ORDERED
        case 2:
          return ORDER_STATUS.PROCESSING
        case 3:
          return ORDER_STATUS.DELIVERED
        case 4:
          return 'Completed'
        default:
          return ORDER_STATUS.QUOTE_SUBMITTED
      }
    }
    switch (step) {
      case 0:
        return ORDER_STATUS.ORDERED
      case 1:
        return ORDER_STATUS.PROCESSING
      case 2:
        return ORDER_STATUS.DELIVERED
      case 3:
        return 'Completed'
      default:
        return ORDER_STATUS.QUOTE_SUBMITTED
    }
  }

  const getTooltipText = (label) => {
    if (activeStep === 3 || (order.distributor === 'segen' && activeStep === 4)) {
      return 'Order has been checked in and marked as stocked. Please contact your Account Handler to update your order, or to process any issues with your order delivered to warehouse.'
    }
    if (label === ORDER_STATUS.PROCESSING) {
      return "Once your order and payment has confirmed, you can tick this box to manually track the order status. Then, tick 'Delivered' to check-in the items and close the order."
    }
    return ''
  }

  useEffect(() => {
    if (order) {
      if (order.is_arrived) {
        setActiveStep(order.distributor === 'segen' ? 4 : 3)
        return
      }
      const step = convertStatusToStep(order.status)
      setActiveStep(step)
    }
  }, [order])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleStep = (step: number) => () => {
    // Step is already completed
    if (activeStep === steps.length) {
      return
    }
    // Mark last step as completed
    if (activeStep === steps.length - 1 && step === activeStep) {
      handleNext()
      // Check in order
      if (!order.is_arrived) {
        checkInOrder()
        handleStatus(convertStepToStatus(activeStep + 1, order.distributor))
      }
      return
    }
    // Can't go back from Ordered to Quote Submitted
    if (step === 0 && step < activeStep && order.distributor === 'segen') {
      return
    }
    setActiveStep(step)
    updateStepper(convertStepToStatus(step, order.distributor))
    handleStatus(convertStepToStatus(step, order.distributor))
  }

  const checkInOrder = async () => {
    await acceptTransfer()
    notify('Items checked in', 'success')
    reloadOrderData()
  }

  const acceptTransfer = async () => {
    const acceptedTransferData = { ...order }
    acceptedTransferData['line_items'] = Array.from(acceptedTransferData['line_items'] || [])

    await partiallyAcceptTransfer({
      transferId: order.id,
      data: acceptedTransferData,
    })
  }

  const updateStepper = async (status) => {
    await updateOrderStepperStatus({
      orderId: order.id,
      status: status,
    })
  }

  return (
    <FullWidthWrapper>
      <Stepper activeStep={activeStep} alternativeLabel connector={<Connector />}>
        {steps.map((label, index) => (
          <Step key={label} onClick={handleStep(index)}>
            <Tooltip key={label} title={getTooltipText(label)} placement="top">
              <StepLabelWrapper StepIconComponent={StepIcon}>
                <StepContentWrapper>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={convertStatusToStep(label) < activeStep}
                        disabled={convertStatusToStep(label) > activeStep}
                        color="info"
                        size="small"
                      />
                    }
                    label={label}
                    labelPlacement="end"
                  />
                  {convertStatusToStep(label) > activeStep && <Typography variant="body1">Not started</Typography>}
                </StepContentWrapper>
              </StepLabelWrapper>
            </Tooltip>
          </Step>
        ))}
      </Stepper>
    </FullWidthWrapper>
  )
}

export default OrderStatusStepper

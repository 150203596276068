import { CRUDListOpts, CRUDScope } from 'opensolar-sdk'
import restClient from 'restClient'

/**
 * This binds a part of the SDK state tree directly into the
 * CRUD actions of the OS rest API.
 *
 * This allows SDK users to use the API directly without
 * having to worry about API authentication (aside from
 * waiting for the user to successfully auth through the OS UI).
 */

const restClientInstance = restClient(window.API_ROOT + '/api')
export function bindCrud(scope: CRUDScope, resource: string) {
  // scope.create.own(async (data: any) => await http.post(data, path))
  // scope.read.own(async (id: string) => await http.get(path + id))
  // scope.update.own(async (id: string, data: any) => await http.put(data, path + id))
  // scope.delete.own(async (id: string) => await http.delete(path + id))
  if (scope.list)
    scope.list.own(async (args: CRUDListOpts) => {
      return restClientInstance('GET_LIST', resource, {
        ...args,
      })
    })

  if (scope.create)
    scope.create.own(async (args: any) => {
      return restClientInstance('CREATE', resource, {
        ...args,
      })
    })
}

import { extractImageryTypeDefaultIfMatchFound } from './extractImageryTypeDefaultIfMatchFound'

export async function restartDesignMode(retainMapType: boolean = false) {
  window.ViewHelper.clean3DUrls()

  const sceneOrigin4326 = window.AccountHelper.sceneOrigin4326FromSceneOrProject()
  const is_lite = !!window.WorkspaceHelper.project?.is_lite

  var [availableImageryTypes, timezoneOffset] = await window.AccountHelper.getMapTypesForProject()

  // Assume these are pre-storted already, most popular first
  var findDefaultImageryType =
    availableImageryTypes.find((x) => x.map_type === 'GoogleTop' || x.map_type === 'Nearmap') ||
    availableImageryTypes[0]

  var firstView = window.ViewHelper.views[0]
  var existingImageryType =
    retainMapType && firstView ? availableImageryTypes.find((x) => x.map_type === firstView.mapData.mapType) : undefined

  var defaultImageryTypeData =
    existingImageryType ||
    extractImageryTypeDefaultIfMatchFound(availableImageryTypes, !is_lite) ||
    findDefaultImageryType

  window.SceneHelper.startDesignMode(
    sceneOrigin4326,
    window.WorkspaceHelper.project?.country_iso2,
    window.WorkspaceHelper.project?.state,
    defaultImageryTypeData,
    timezoneOffset,
    {
      basicMode: is_lite,
      overrideDefaultMapType: is_lite ? window.getDefaultMapType(false) : null,
    }
  )
}

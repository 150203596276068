import { combineReducers } from 'redux'
import { activeComponentReducer, activeComponentReducerState } from './activeComponent'
import { autoStringReducer, AutoStringReducerState } from './autoString'
import { detectImageryReducer, DetectImageryState } from './detectImagery'
import { systemCalculationsQueuedReducer, SystemCalculationsQueuedState } from './systemCalculation'
import { systemsReducer, SystemsReducerState } from './systems'
import { DesignViewReducerState, viewReducer } from './view'

export interface DesignerState {
  systemCalculation: SystemCalculationsQueuedState
  detectImagery: DetectImageryState
  activeComponent: activeComponentReducerState
  systems: SystemsReducerState
  autoString: AutoStringReducerState
  view: DesignViewReducerState
}

export default combineReducers({
  systemCalculation: systemCalculationsQueuedReducer,
  detectImagery: detectImageryReducer,
  activeComponent: activeComponentReducer,
  systems: systemsReducer,
  autoString: autoStringReducer,
  view: viewReducer,
})

import { Grid, makeStyles } from '@material-ui/core'
import { AddOutlined, EditOutlined } from '@material-ui/icons'
import Alert from 'elements/Alert'
import ChipsInput from 'elements/input/ChipsInput'
import DependentInput from 'elements/input/DependentInput'
import { List } from 'elements/react-admin/List'
import ListActions from 'elements/react-admin/ListActions'
import { Chip, Button as CoreButton } from 'opensolar-ui'
import { useEffect } from 'react'
import {
  BooleanInput,
  Button,
  Create,
  Datagrid,
  Edit,
  FunctionField,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  required,
  useNotify,
  useRedirect,
} from 'react-admin'
import { useHistory } from 'react-router-dom'
import { LENDER_STATUS_CHOICES } from 'resources/financeIntegrations/constants'
import RoundingPreferenceInput from 'resources/orgs/RoundingPreferenceInput'
import { FINANCE_PRODUCT_CHOICES, PAYMENT_FREQUENCE_CHOICES, PMT_IMPORT_METHOD_CHOICES } from './constants'

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const getFinancePartnerId = () => {
  const path = window.location.hash.substring(window.location.hash.indexOf('#') + 2)
  const params = new URLSearchParams(path.substring(path.indexOf('?')))
  const paramStr = params.get('finance_partner_id')
  return paramStr ? parseInt(paramStr) : undefined
}

export const FinanceProductList = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const financePartnerId = getFinancePartnerId()

  useEffect(() => {
    if (!financePartnerId) {
      history.push('/finance_partners')
    }
  }, [financePartnerId])

  const CustomCreateButton = () => (
    <Button
      label="Create a Finance Product"
      onClick={() => history.push(`/finance_products/create?finance_partner_id=${financePartnerId}`)}
      startIcon={<AddOutlined />}
      variant="outlined"
    />
  )

  const goToEdit = (record) => {
    history.push(`/finance_products/${record.id}?finance_partner_id=${financePartnerId}`)
  }
  return (
    <List
      hasSearch={true}
      actions={<ListActions CustomButton={CustomCreateButton()} />}
      {...props}
      hasCreate={false}
      filter={{ finance_partner_id: financePartnerId }}
    >
      <Datagrid>
        <TextField source="product_name" label="Internal Name" />
        <TextField source="display_name" label="Display Name" />
        <FunctionField
          source="finance_product"
          label="Orgs"
          sortable={false}
          render={(record: any, source, rest) => {
            return (
              <div className={classes.row}>
                {record.orgs_include_names?.map((orgName) => (
                  <Chip variant="outlined" label={orgName} key={orgName} />
                ))}
              </div>
            )
          }}
        />
        <FunctionField
          source="finance_product"
          label="Countries"
          sortable={false}
          render={(record: any, source, rest) => {
            return (
              <div className={classes.row}>
                {record.countries_include_names?.map((orgName) => (
                  <Chip variant="outlined" label={orgName} key={orgName} />
                ))}
              </div>
            )
          }}
        />
        <FunctionField
          source="finance_product"
          label="Status"
          sortable={false}
          render={(record: any, source, rest) => <div>{record.status?.replaceAll('_', ' ')}</div>}
        />
        <FunctionField
          source="finance_product"
          label="Product Terms"
          sortable={false}
          render={(record: any, source, rest) => {
            return (
              <div className={classes.row}>
                <Button startIcon={<EditOutlined />} label="Edit" onClick={() => goToEdit(record)} />
                {record.payment_option_fetch_method === 'api' ? (
                  <div>Imported via API</div>
                ) : (
                  <div>
                    <CoreButton
                      onClick={() => history.push(`/finance_product_terms?finance_product_category_id=${record.id}`)}
                      variant="outlined"
                    >
                      <span>View terms</span>
                    </CoreButton>
                  </div>
                )}
              </div>
            )
          }}
        />
      </Datagrid>
    </List>
  )
}

const FinanceProductFields = (props) => {
  const financePartnerId = getFinancePartnerId()
  const validateJSON = (value) => {
    if (!value) return undefined
    try {
      JSON.parse(value)
      return undefined
    } catch (ex) {
      return 'Invalid JSON'
    }
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <TextInput source="product_name" validate={[required()]} fullWidth />
      </Grid>
      <Grid item xs={4}>
        <TextInput source="display_name" validate={[required()]} fullWidth />
      </Grid>
      <Grid item xs={4}>
        <SelectInput
          source="payment_option_fetch_method"
          choices={PMT_IMPORT_METHOD_CHOICES}
          defaultValue="import"
          fullWidth={true}
          style={{ width: '100%' }}
          optionText="label"
          validate={[required()]}
        />
      </Grid>
      <DependentInput dependsOn="payment_option_fetch_method" value={'api'}>
        <>
          <Grid item xs={12}>
            <Alert severity="info">
              The Term and Interest Rate fields should only be populated for integrations that fetch payment options via
              API and have either different quotation tables, CTAs or validation rules for payment options of the same
              type but different term/period. An example of this is Plenti which has one set of rules for all of their
              loans EXCEPT their 10 year loan. In that example, we should have two Finance Product Categories. One
              without the interest rate of Term which would serve as the default, then one with the term so we can have
              different validation rules
            </Alert>
          </Grid>
          <Grid item xs={6}>
            <NumberInput source="interest_rate" label="Interest Rate" fullWidth={true} style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={6}>
            <NumberInput
              source="payment_periods"
              label="Term (in payment periods)"
              fullWidth={true}
              style={{ width: '100%' }}
            />
          </Grid>
        </>
      </DependentInput>
      <Grid item xs={12}>
        <TextInput source="description" validate={[required()]} fullWidth />
      </Grid>
      <Grid item xs={3}>
        {/* @ts-ignore */}
        <ReferenceInput
          label="Finance Partner"
          reference="finance_partners"
          source="finance_partner"
          fullWidth
          style={{ width: '100%' }}
          disabled={true}
        >
          <SelectInput optionText="display_name" optionValue="url" source="finance_partner" disabled={true} />
        </ReferenceInput>
      </Grid>
      <Grid item xs={3}>
        <SelectInput
          source="status"
          choices={LENDER_STATUS_CHOICES}
          defaultValue="pending"
          fullWidth={true}
          style={{ width: '100%' }}
          optionText="label"
          validate={[required()]}
        />
      </Grid>
      <Grid item xs={3}>
        <SelectInput
          source="type"
          choices={FINANCE_PRODUCT_CHOICES}
          defaultValue="loan"
          fullWidth={true}
          style={{ width: '100%' }}
          optionText="label"
          validate={[required()]}
        />
      </Grid>
      <Grid item xs={3}>
        <SelectInput
          source="payment_frequency"
          choices={PAYMENT_FREQUENCE_CHOICES}
          defaultValue="monthly"
          fullWidth={true}
          style={{ width: '100%' }}
          optionText="label"
          validate={[required()]}
        />
      </Grid>
      <Grid item xs={6}>
        {/* @ts-ignore */}
        <ReferenceArrayInput
          label="Orgs Included (leave blank if product is open to all integrated orgs)"
          reference="orgs"
          source="orgs_include"
          fullWidth
          style={{ width: '100%' }}
          filter={{ finance_partner_id: financePartnerId }}
        >
          <ChipsInput optionText="name" optionValue="url" source="orgs_include" />
        </ReferenceArrayInput>
      </Grid>
      <Grid item xs={6}>
        {/* @ts-ignore */}
        <ReferenceArrayInput
          label="Orgs Excluded"
          reference="orgs"
          source="orgs_exclude"
          fullWidth
          style={{ width: '100%' }}
          filter={{ finance_partner_id: financePartnerId }}
        >
          <ChipsInput optionText="name" optionValue="url" source="orgs_exclude" />
        </ReferenceArrayInput>
      </Grid>
      <Grid item xs={6}>
        {/* @ts-ignore */}
        <ReferenceArrayInput
          label="Whitelisted Countries (leave blank if product is open to all integrated orgs)"
          reference="countries"
          source="countries_include"
          fullWidth
          style={{ width: '100%' }}
        >
          <ChipsInput optionText="name" optionValue="url" source="countries_include" />
        </ReferenceArrayInput>
      </Grid>
      <Grid item xs={6}>
        {/* @ts-ignore */}
        <ReferenceArrayInput
          label="Finance Validation Sets"
          reference="finance_validation_sets"
          source="finance_validation_sets"
          fullWidth
          style={{ width: '100%' }}
          filter={{ finance_partner_id: financePartnerId }}
        >
          <ChipsInput optionText="name" optionValue="id" source="finance_validation_sets" />
        </ReferenceArrayInput>
      </Grid>
      <Grid item xs={6}>
        {/* @ts-ignore */}
        <ReferenceArrayInput
          label="Finance CTAs"
          reference="finance_ctas"
          source="finance_ctas"
          fullWidth
          style={{ width: '100%' }}
          filter={{ finance_partner_id: financePartnerId }}
        >
          <ChipsInput optionText="title" optionValue="id" source="finance_ctas" />
        </ReferenceArrayInput>
      </Grid>
      <Grid item xs={12}>
        <BooleanInput
          label="Treat all terms as unit"
          source="treat_all_terms_as_unit"
          defaultValue={false}
          defaultChecked={false}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <RoundingPreferenceInput />
      </Grid>
      <Grid item xs={12}>
        <NumberInput source="required_proposal_lifetime" fullWidth />
      </Grid>
      <Grid item xs={12}>
        <TextInput source="proposal_highlight_rows" fullWidth />
      </Grid>
      <Grid item xs={12}>
        <BooleanInput
          label="Orgs can edit this quotation table"
          source="quotation_table_is_editable"
          defaultValue={false}
          defaultChecked={false}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          source="quotation_table_json"
          fullWidth
          validate={validateJSON}
          multiline={true}
          rows={30}
          InputProps={{
            classes: { input: 'code-block' },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <p>
          Pricing Config JSON is used to configured product category level pricing variables that are used by the
          integration's API client. An example of this is Handypay's origination fee
        </p>
        <TextInput
          source="pricing_config_json"
          fullWidth
          validate={validateJSON}
          multiline={true}
          rows={30}
          InputProps={{
            classes: { input: 'code-block' },
          }}
        />
      </Grid>
    </Grid>
  )
}

export const FinanceProductEdit = (props) => {
  const financePartnerId = getFinancePartnerId()

  return (
    <Edit
      {...props}
      hasDelete={true}
      redirect={(resource, id, data) => `/finance_products?finance_partner_id=${financePartnerId}`}
    >
      <SimpleForm>
        <FinanceProductFields {...props} />
      </SimpleForm>
    </Edit>
  )
}

export const FinanceProductCreate = (props) => {
  const financePartnerId = getFinancePartnerId()
  const redirect = useRedirect()
  const notify = useNotify()

  const onSuccess = (data) => {
    notify('Financing Product Created')
    redirect(`/finance_products?finance_partner_id=${financePartnerId}`)
  }
  return (
    <Create {...props} mutationOptions={{ onSuccess }}>
      <SimpleForm initialValues={{ finance_partner_id: financePartnerId }}>
        <FinanceProductFields {...props} />
      </SimpleForm>
    </Create>
  )
}

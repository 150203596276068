import { TextField } from '@material-ui/core/'
import React, { useState } from 'react'
import { usageTypeFromMeterData } from '../../../projectSections/sections/energy/ElectricityUsage/ElectricityUsageContent'
import { SuggestionItem } from './AddressAutocomplete'

const streetNumberCompare = (text, apiAddreesses) => {
  const textToUpperCase = text.toUpperCase()
  const splitedText = textToUpperCase.split(' ')
  const [firstPosition, secondPosition, thirdPosition, fourthPosition, fivthPosition] = splitedText
  const city = firstPosition + ' ' + secondPosition
  const city1 = thirdPosition + ' ' + fourthPosition
  const stateZip = firstPosition + ' ' + secondPosition

  const filteredAddress = apiAddreesses.filter((apiAddress) => {
    const apiAddressToUpperCase = apiAddress.service_address.toUpperCase()
    const splitedFullApiAddress = apiAddressToUpperCase.split(',')
    const trimFullApiAddress = splitedFullApiAddress.map((address) => address.trim())
    const splitedAddress = splitedFullApiAddress[0].split(' ')

    // city (1 word) number,
    // city (2 words) number,
    // number name,
    // zip city (1 word) number,
    // zip city (2 words) number
    return !!(
      (trimFullApiAddress.includes(firstPosition) && splitedAddress.includes(secondPosition)) ||
      (trimFullApiAddress.includes(city) && splitedAddress.includes(thirdPosition)) ||
      (splitedAddress.includes(firstPosition) && splitedAddress.includes(secondPosition)) ||
      (trimFullApiAddress.includes(stateZip) &&
        trimFullApiAddress.includes(thirdPosition) &&
        splitedAddress.includes(fourthPosition)) ||
      (trimFullApiAddress.includes(stateZip) &&
        trimFullApiAddress.includes(city1) &&
        splitedAddress.includes(fivthPosition))
    )
  })
  return filteredAddress
}

const UtilityApiAddressSearch = ({ suggestions, selectedInfo, handleSelectedMeterInfo, handlehideSelectedInfo }) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([])
  const [searchText, setSearchText] = useState('')

  const onChange = (text) => {
    setSearchText(text)
    const result = streetNumberCompare(text, suggestions)
    setFilteredSuggestions(result)
    if (result.length === 0) {
      handlehideSelectedInfo()
    }
  }

  const onSelected = (suggestion) => {
    setSearchText(suggestion.service_address)
    setFilteredSuggestions([])
    handleSelectedMeterInfo(suggestion)
  }

  return (
    <>
      <TextField
        fullWidth
        id="address-field"
        label="Address"
        onChange={(e) => onChange(e.target.value)}
        value={searchText}
      />
      {filteredSuggestions &&
        filteredSuggestions.map((suggestion, index) => (
          <SuggestionItem key={index} suggestion={suggestion} searchText={searchText} onSelected={onSelected}>
            {suggestion.service_address}
          </SuggestionItem>
        ))}
      {selectedInfo && (
        <div style={{ display: 'flex', marginTop: 5, marginBottom: 5 }}>
          <div style={{ flex: '1 1 100%', marginRight: 5 }}>
            {selectedInfo?.status_message && <div className="small">Status: {selectedInfo?.status_message}</div>}
            {selectedInfo?.service_tariff && <div className="small">Tariff: {selectedInfo?.service_tariff}</div>}
            <div className="small">Usage Data Format: {usageTypeFromMeterData(selectedInfo).label}</div>
          </div>
        </div>
      )}
    </>
  )
}

export default UtilityApiAddressSearch

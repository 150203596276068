import { orderSelectors } from 'ducks/orderComponents'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import { parseQueryStringToDictionary } from 'util/misc'
import OrderPage from './OrderPage'
import PostCheckoutPage from './PostCheckoutPage'
import VtacCheckoutProvider from './VtacCheckoutProvider'

const Checkout = memo(() => {
  const location = useLocation()
  const params = parseQueryStringToDictionary(location.search)
  const lineItemsConfirmed = useSelector(orderSelectors.getOrderableLineItems)

  if (lineItemsConfirmed.length === 0) {
    return <Redirect to={'/shop'} />
  }

  if (params.step === 'post-order') {
    return <PostCheckoutPage quoteOrOrderType="order" />
  }

  return <OrderPage />
})

const VtacCheckout = () => {
  return (
    <VtacCheckoutProvider>
      <Checkout />
    </VtacCheckoutProvider>
  )
}

export default VtacCheckout

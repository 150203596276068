import { withTheme } from '@material-ui/core'
import OverlayAzimuth from 'Designer/OverlayAzimuth'
import OverlaySlope from 'Designer/OverlaySlope'
import PanelAnnotation from 'Designer/PanelAnnotation'
import PanelClipper from 'Designer/PanelClipper'
import PanelEdge from 'Designer/PanelEdge'
import PanelFacet from 'Designer/PanelFacet'
import PanelFixSkew from 'Designer/PanelFixSkew'
import PanelGround from 'Designer/PanelGround'
import PanelModuleGrid from 'Designer/PanelModuleGrid/'
import PanelNode from 'Designer/PanelNode'
import PanelObstruction from 'Designer/PanelObstruction'
import PanelOsGroup from 'Designer/PanelOsGroup'
import PanelSequence from 'Designer/PanelSequence'
import PanelStructure from 'Designer/PanelStructure'
import PanelTree from 'Designer/PanelTree'
import StudioErrorPrompt from 'Designer/StudioErrorPrompts'
import { permissionsSelectors } from 'ducks/permissions'
import { UiSwitch } from 'elements/UiSwitch'
import WithPermissionsCheck from 'elements/WithPermissionsCheck'
import withMediaQuery from 'layout/withMediaQuery'
import { Component } from 'react'
import { withTranslate } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import restClient from 'restClient'
import withStudioSignals from 'Studio/signals/withStudioSignals'
import { deleteInitiatedChangeOrder, getInitiatedChangeOrder } from 'util/changeOrder'
import { isNestedWindow } from 'util/isNestedWindow'
import ChangeOrderBanner from '../common/ChangeOrderBanner'
import { SaveStatusAuto } from '../common/SaveStatus'
import CogsAutoOverride from './cogsOverride/CogsAutoOverride'
import DesignSideDrawer from './sideDrawer/DesignSideDrawer'
import PanelSystem from './systems/PanelSystem'
import ToolbarAdvanced from './tools/advanced/ToolbarAdvanced'
import ToolbarDesignMode from './tools/designMode/ToolbarDesignMode'
import ToolbarViews from './tools/view/ToolbarViews'
import ToolbarWidgets from './tools/widgets/ToolbarWidgets'

const hasHeader = () => {
  if (isNestedWindow() && window.ossdk?.config?.view_overrides['forms.project.header']?.show === false) {
    return false
  } else {
    return true
  }
}

class PageDesigner extends Component {
  constructor(props) {
    super(props)

    this.state = {
      projects: null,
      numProjects: null,
      address: null,
      selectedObject: null,
      selectedSystem: null,
      selectedObjectUuid: null, //used to force component refresh
      selectedSystemUuid: null, //used to force component refresh
      systems: [],
      open: false,
      undo: {
        numUndos: 0,
        numRedos: 0,
      },
      overlay: null,
      isPremiumImageryAvailable: false,
      premiumImageryUnavailableReson: undefined,
    }

    window.editor.setShowElements({ viewWidget: true })
    this.restClientInstance = restClient(window.API_ROOT + '/api')
  }

  clearOverlays = () => {
    if (this.state.overlay) {
      if (window.editor && window.editor.selected && window.editor.selected.type === 'OsModuleGrid') {
        //ok, overlay matches selected object
      } else {
        //invalid, clear overlay
        this.setState({ overlay: null })
      }
    }
  }

  refreshSelectedUuids = () => {
    const selectedObjectUuid = window.editor.selected ? window.editor.selected.uuid : ''
    const selectedSystemUuid = window.editor.selectedSystem ? window.editor.selectedSystem.uuid : ''

    if (selectedObjectUuid !== this.state.selectedObjectUuid || selectedSystemUuid !== this.state.selectedSystemUuid) {
      this.setState({ selectedObjectUuid, selectedSystemUuid })
    }
  }

  /*manageSignals(enable) {
    if (!window.editor) {
      throw new Error('Trying to add signals but window.editor not ready')
    }

    if (enable) {
      window.editor.signals.objectSelected.add(this.clearOverlays)
      window.editor.signals.objectSelected.add(this.refreshSelectedUuids)
      window.editor.signals.objectSelected.add(this.refreshMarginBound)
    } else {
      window.editor.signals.objectSelected.remove(this.clearOverlays)
      window.editor.signals.objectSelected.remove(this.refreshSelectedUuids)
      window.editor.signals.objectSelected.remove(this.refreshMarginBound)
    }
  }*/

  refreshMargin() {
    // @TODO: Add better test for whether panel will appear
    var margin

    if (
      this.props.layout > 2 &&
      window.editor.displayMode !== 'presentation' &&
      (window.editor.selectedSystem || window.editor.selected)
    ) {
      margin = 340
    } else {
      margin = 0
    }

    // window.editor.setLeftMarginPixels(margin)
    window.editor.tweenLeftMargin(margin)
  }

  bindToWindow() {
    var _this = this

    // window.manageSignals = _this.manageSignals.bind(this)

    window.setSelectedSystem = function (object) {
      _this.setState({ selectedSystem: object })
    }
    window.setSystems = function (systems) {
      _this.setState({ systems: systems })
    }

    window.setOverlay = function (value) {}

    window.getOverlay = function () {
      return _this.state.overlay
    }
  }

  componentDidMount() {
    console.log('Create/inject window.setSelectedSystem, window.setSystems, window.getOverlay')

    // _this.manageSignals(true)

    this.bindToWindow()

    this.refreshMargin()

    const lonLatArray = window?.MapHelper?.activeMapInstance?.toMapData(true).center
    const projectCountryIso2 = this.props?.projectValues?.country_iso2
    const projectState = this.props?.projectValues?.state
    const premiumImageryResult = window.AccountHelper.getIsPremiumImageryAvailable(
      lonLatArray,
      projectCountryIso2,
      projectState
    )
    if (premiumImageryResult) {
      this.setState({
        isPremiumImageryAvailable: premiumImageryResult.isAvailable,
        premiumImageryUnavailableReson: premiumImageryResult.blockedReason,
      })
    }

    this.props.useStudioSignals(this.onObjectSelected, ['objectSelected'], this)
  }

  onObjectSelected() {
    this.clearOverlays()
    this.refreshSelectedUuids()
    this.refreshMargin()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.layout !== prevProps.layout) {
      this.refreshMargin()
    }
    if (typeof prevState.overlay !== 'undefined' && prevState.overlay !== this.state.overlay) {
      window.editor.signals.overlayChanged.dispatch()
    }
  }

  componentWillUnmount() {
    //stop all animation
    window.editor.signals?.animationStop.dispatch()
  }

  render() {
    const allowEdit = this.props.allowEdit
    const { themeColor = '#ffda00' } = this.props.theme
    const { translate } = this.props

    if (!window.editor) {
      return (
        <div style={{ position: 'fixed', top: '50%', left: '50%' }} className="processing-animation">
          <div style={{ background: themeColor }}></div>
          <div style={{ background: themeColor }}></div>
          <div style={{ background: themeColor }}></div>
          <p>Loading...</p>
        </div>
      )
    }

    const project = this.props.projectValues
    const initiatedChangeOrder = getInitiatedChangeOrder(project)

    let topInset = hasHeader() ? 100 : 0
    if (initiatedChangeOrder) topInset += 40
    const bottomInset = this.props.bottomPadding ? 50 : 0

    return (
      <>
        {!!initiatedChangeOrder && (
          <ChangeOrderBanner
            handleCancelChangeOrder={() =>
              deleteInitiatedChangeOrder(
                initiatedChangeOrder?.id,
                project.org_id,
                project.id,
                this.restClientInstance,
                this.props.form
              )
            }
          />
        )}
        <div
          id="studioMenus"
          className="studio"
          style={{
            position: 'fixed',
            // @TODO: Fix this hack for removing top-padding in iFrame
            // This should ideally just flow automatically from the DOM structure but this does not seem to work
            // for both the iFrame and the standalone version.
            inset: `${topInset}px 0px ${bottomInset}px 0px`,
            zIndex: 1400,
            pointerEvents: 'none',
          }}
        >
          {window.editor && (
            <>
              <DesignSideDrawer />
              <CogsAutoOverride />

              {/* Hide this prompt for 2.21.0 release until we have a better solution */}
              {/* <DesignChangeAfterInstallDatePrompt /> */}

              <div id="menus" style={{ pointerEvents: 'all', background: 'white' }}>
                <div
                  id={'toolbar-advanced-wrapper'}
                  style={{
                    position: 'absolute',
                    top: this.props.layout < 3 ? 'unset' : 10,
                    bottom: this.props.layout < 3 ? 55 : 0,
                    right: '10px',
                    height: 'fit-content',
                    zIndex: 1100,
                    textAlign: 'right',
                  }}
                >
                  <WithPermissionsCheck
                    permissionToCheck="design"
                    disabledTooltip={translate('No permission to change design mode')}
                    disabledProp="disabled"
                    permissions={['allowEdit']}
                  >
                    <ToolbarDesignMode
                      // handleCloseOtherMenus={window.Designer.handleCloseOtherMenus}
                      isPremiumImageryAvailable={this.state.isPremiumImageryAvailable}
                      premiumImageryUnavailableReson={this.state.premiumImageryUnavailableReson}
                      premiumImageryActivations={this.props?.projectValues?.premium_imagery_activations || []}
                    />
                  </WithPermissionsCheck>
                  <UiSwitch uiKey="studio.advanced">
                    <WithPermissionsCheck
                      permissionToCheck="design"
                      disabledTooltip={translate('No permission to edit design')}
                      disabledProp="disabled"
                      permissions={['allowEdit']}
                    >
                      <ToolbarAdvanced
                        // handleCloseOtherMenus={window.Designer.handleCloseOtherMenus}
                        handleClick={window.Designer.startPlacementMode}
                      />
                    </WithPermissionsCheck>
                  </UiSwitch>
                  {this.props.autoSaveEnabled && (
                    <UiSwitch uiKey="forms.project.save_status">
                      <SaveStatusAuto allowButton={false} />
                    </UiSwitch>
                  )}
                </div>

                <UiSwitch uiKey="studio.view_controls">
                  <ToolbarWidgets
                    premiumImageryActivations={this.props?.projectValues?.premium_imagery_activations || []}
                  />
                </UiSwitch>
                <UiSwitch uiKey="studio.view_selector">
                  <ToolbarViews
                    layout={this.props.layout}
                    isPremiumImageryAvailable={this.state.isPremiumImageryAvailable}
                    premiumImageryUnavailableReson={this.state.premiumImageryUnavailableReson}
                    premiumImageryActivations={this.props?.projectValues?.premium_imagery_activations || []}
                  />
                </UiSwitch>

                <div
                  style={
                    this.state.overlay
                      ? {
                          pointerEvents: 'none',
                          opacity: 0.2,
                        }
                      : {}
                  }
                >
                  {/* <PanelView /> */}

                  <PanelSystem showTabs={this.props.showTabs} />
                  <StudioErrorPrompt />
                  <PanelSequence
                    state={
                      window.editor &&
                      window.editor.controllers &&
                      window.editor.controllers.Sequence &&
                      window.editor.controllers.Sequence &&
                      window.editor.controllers.Sequence.getMode()
                        ? window.editor.controllers.Sequence.getState()
                        : null
                    }
                  />

                  {window.editor?.selected?.type === 'OsModuleGrid' && (
                    <PanelModuleGrid moduleGrid={window.editor.selected} allowEdit={allowEdit} />
                  )}
                  <PanelFacet
                    facet={
                      window.editor && window.editor.selected && window.editor.selected.type === 'OsFacet'
                        ? window.editor.selected
                        : null
                    }
                    state={{ allowEdit }}
                  />
                  <PanelNode
                    node={
                      window.editor && window.editor.selected && window.editor.selected.type === 'OsNode'
                        ? window.editor.selected
                        : null
                    }
                    state={{ allowEdit }}
                  />
                  <PanelObstruction
                    obstruction={
                      window.editor && window.editor.selected && window.editor.selected.type === 'OsObstruction'
                        ? window.editor.selected
                        : null
                    }
                    state={{ allowEdit }}
                  />
                  <PanelStructure
                    object={
                      window.editor && window.editor.selected && window.editor.selected.type === 'OsStructure'
                        ? window.editor.selected
                        : null
                    }
                    state={{ allowEdit }}
                  />
                  <PanelClipper
                    obj={
                      window.editor && window.editor.selected && window.editor.selected.type === 'OsClipper'
                        ? window.editor.selected
                        : null
                    }
                    state={{ allowEdit }}
                  />
                  <PanelAnnotation
                    obstruction={
                      window.editor && window.editor.selected && window.editor.selected.type === 'OsAnnotation'
                        ? window.editor.selected
                        : null
                    }
                    state={{ allowEdit }}
                  />
                  <PanelGround
                    obstruction={
                      window.editor && window.editor.selected && window.editor.selected.type === 'OsGround'
                        ? window.editor.selected
                        : null
                    }
                    state={{ allowEdit }}
                  />
                  <PanelTree
                    tree={
                      window.editor && window.editor.selected && window.editor.selected.type === 'OsTree'
                        ? window.editor.selected
                        : null
                    }
                    state={{ allowEdit }}
                  />
                  <PanelEdge
                    edge={
                      window.editor && window.editor.selected && window.editor.selected.type === 'OsEdge'
                        ? window.editor.selected
                        : null
                    }
                    state={{ allowEdit }}
                  />
                  <PanelOsGroup state={{ allowEdit }} />
                </div>

                <div id="camera-trackball-circle-guide" className="">
                  <div id="camera-trackball-circle-guide-holder">
                    <div id="camera-trackball-circle-guide-horizontal-line" />
                    <div id="camera-trackball-circle-guide-vertical-line" />
                  </div>
                </div>
              </div>
              <PanelFixSkew
                visible={false}
                // ref={(element) => {
                //   if (element) referenceSave.call(element, 'PanelFixSkew')
                //   else referenceClear('PanelFixSkew')
                // }}
                style={{
                  position: 'fixed',
                  top: 42,
                  right: 3,
                  width: 300,
                  zIndex: 100000,
                  pointerEvents: 'all',
                }}
              />

              {window.editor &&
                window.editor.selected &&
                window.editor.selected.type === 'OsModuleGrid' &&
                this.state.overlay === 'SetAzimuth' && (
                  <OverlayAzimuth
                    visible={true}
                    moduleGrid={window.editor.selected}
                    key={this.state.selectedObjectUuid}
                  />
                )}

              {window.editor &&
                window.editor.selected &&
                window.editor.selected.type === 'OsModuleGrid' &&
                this.state.overlay === 'SetSlope' && (
                  <OverlaySlope
                    visible={true}
                    moduleGrid={window.editor.selected}
                    key={this.state.selectedObjectUuid}
                  />
                )}
            </>
          )}
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  zone: state.zone,
  projectId: state.projectId,

  // We skip the error here because, when leaving design, this gets re-assessed before
  // the condition in ProjectSections which should prevent this component from rendering, causing an error.
  // This is probably a quirk of how these composition functions work.
  allowEdit: permissionsSelectors.getProjectPermissionByKey('design', true)(state).allowEdit,
})

// export default connect(mapStateToProps, {})(translate(PageDesigner))
const dispatchActions = {
  // setSelectedObject: setSelectedObjectAction,
}

const enhance = compose(
  withTranslate,
  withMediaQuery,
  withStudioSignals,
  withTheme,
  connect(mapStateToProps, dispatchActions)
)

export default enhance(PageDesigner)

import { TextField } from '@material-ui/core'
import { useTranslate } from 'ra-core'
import React, { FC } from 'react'
import { inputValueToFloat, valueOrEmptyStringIfZero } from '../../PanelSystem'

type PropTypes = {
  currencySymbol: string
  pricingIsLocked: boolean
  allowEdit: boolean
  system: any
}

const ESIncentiveField: FC<PropTypes> = ({ currencySymbol, pricingIsLocked, allowEdit, system }) => {
  const translate = useTranslate()
  return (
    <div style={{ width: '100%', margin: '6px 0 10px', position: 'relative' }}>
      <h2>{translate('IBI Reduction Incentive')}</h2>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <p style={{ width: '60%' }}>
          {translate('IBI Amount ')}({currencySymbol}):
        </p>
        <TextField
          type="number"
          label={false}
          name="Value"
          value={valueOrEmptyStringIfZero(system.ibi_reduction_incentive)}
          onChange={(event) => {
            const value = event.target.value
            var valueAsFloat = inputValueToFloat(value)
            if (!isNaN(valueAsFloat)) {
              window.editor.execute(new window.SetValueCommand(system, 'ibi_reduction_incentive', valueAsFloat))
            }
          }}
          style={{ float: 'left', width: '70%', marginLeft: '5%', marginRight: '5%' }}
          disabled={pricingIsLocked || !allowEdit}
        />
      </div>
    </div>
  )
}
export default ESIncentiveField

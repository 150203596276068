import { Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/EditOutlined'
import { orgSelectors } from 'ducks/orgs'
import SharedEntityIcon from 'elements/connectedOrgs/SharedEntityIcon'
import { Checkbox, IconButton } from 'opensolar-ui'
import React, { useEffect, useMemo, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { StageActionEventType, StageActionType } from 'types/projects'
import { Theme } from 'types/themes'
import { useDateFormatter } from 'util/date'

var restClientInstance = restClient(window.API_ROOT + '/api')
const useStyles = makeStyles<Theme>((theme) => ({
  content: {
    width: '100%',
    display: 'flex',
    padding: '2px 0',
  },
  buttonWrapper: {
    display: 'flex',
    flex: 1,
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 12,
  },
  title: {
    fontWeight: 400,
    fontSize: '12px',
  },
  status: {
    fontSize: '10px',
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 300,
  },
  editWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: 1,
  },
}))

type StatusType = {
  title: string
  isComplete: boolean
  eventId: number | null
  event: StageActionEventType | null
}

type PropTypes = {
  action: StageActionType
  projectUrl: string | undefined
  openEditDialog: (ev: StageActionEventType | null, action: StageActionType | null) => void
  onCreate: (newEvent: StageActionEventType, actionId: number) => void
  onDelete: (deletedEventId: number, actionId: number) => void
  disabled?: boolean
}

const StageActionCard: React.FunctionComponent<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const notify = useNotify()
  const projectFormState = useFormState()
  const projectSharedWith = projectFormState.values['shared_with']
  const formatter = useDateFormatter()
  const events = useMemo(() => projectFormState.values.actions?.find((x) => x.id === props.action?.id)?.events, [
    props.action,
    projectFormState.values.actions,
  ])

  const getStatusObject = (): StatusType => {
    let title = translate('Incomplete')
    let isComplete = false
    let eventId: number | null = null
    let event: StageActionEventType | null = null
    if (events && events.length > 0) {
      let completedActions = events.filter((actionEvent: StageActionEventType) => actionEvent.is_complete)
      if (completedActions && completedActions.length > 0) {
        title = translate(`Completed %{datetime}`, { datetime: formatter(completedActions[0].completion_date) })
        isComplete = true
        eventId = completedActions[0].id
        event = completedActions[0]
      }
    }
    if (!eventId && !event && events && events.length > 0) {
      eventId = events[0].id
      event = events[0]
    }
    return { title, isComplete, eventId, event }
  }
  const [statusObject, setStatusObject] = useState<StatusType>(getStatusObject())
  const [checked, setChecked] = useState<boolean>(statusObject.isComplete)
  const [eventId, setEventId] = useState<number | null>(statusObject.eventId)
  const [statusTitle, setStatusTitle] = useState<string | null>(statusObject.title)
  const [existingEvent, setExistingEvent] = useState<StageActionEventType | null>(statusObject.event)
  const myOrgUrl = useSelector(orgSelectors.getOrgUrl)
  const eventOrgUrl = existingEvent?.org
  const enableAction = eventOrgUrl ? eventOrgUrl === myOrgUrl : true
  const connectedOrgs = useSelector(orgSelectors.getConnectedOrgs)
  const eventOrgName = useMemo(() => connectedOrgs.find((x) => x.org_url === eventOrgUrl)?.org_name, [
    connectedOrgs,
    eventOrgUrl,
  ])

  useEffect(() => {
    if (statusObject) {
      setChecked(statusObject.isComplete)
      setEventId(statusObject.eventId)
      setStatusTitle(statusObject.title)
      setExistingEvent(statusObject.event)
    }
  }, [statusObject])

  useEffect(() => {
    setStatusObject(getStatusObject())
  }, [events])
  const toggleCheck = () => {
    const previousCheckedValue = checked

    //if this event has a note, alert user to it before they delete
    if (previousCheckedValue && events) {
      let thisEvent = events.find((event: StageActionEventType) => event.id === eventId)
      if (thisEvent && thisEvent.notes) {
        const confirmed = window.confirm(translate('Unchecking this action will delete the note associated with it'))
        if (!confirmed) {
          return
        }
      }
    }
    setChecked(!checked)
    const method = !!eventId ? 'DELETE' : 'CREATE'
    const data = !!eventId
      ? {}
      : {
          action: props.action.url,
          project: projectFormState.values.url,
          is_complete: true,
          event_type_id: 32,
        }
    const payload = !!eventId ? { id: eventId, data: data } : { data: data }
    restClientInstance(method, 'events', payload)
      .then((response: any) => {
        if (!eventId && response?.data?.id) setEventId(response.data.id)
        if (response?.data?.is_complete) {
          setStatusTitle(translate('Completed just now'))
          setExistingEvent(response.data)
          props.onCreate(response.data, props.action?.id)
        } else {
          setStatusTitle(translate('Incomplete'))
          setExistingEvent(null)
          if (eventId) props.onDelete(eventId, props.action?.id)
        }
        if (method === 'DELETE') notify(translate('Action marked as incomplete'), 'success')
        else notify(translate('Action updated'), 'success')
        // if the id exists in state and the response was success, it was deleted and state should be reset
        if (!!eventId) setEventId(null)
      })
      .catch((err: any) => {
        if (method === 'DELETE') notify(translate('Unable to mark action as incomplete'), 'warning')
        else notify(translate('Unable to update action'), 'warning')
        setChecked(previousCheckedValue)
      })
  }
  return (
    <div className={classes.content}>
      <div className={classes.buttonWrapper}>
        <Checkbox checked={checked} onChange={toggleCheck} name="checkedB" color="primary" disabled={props.disabled} />
      </div>
      <div className={classes.textWrapper}>
        <span className={classes.title}>{props.action.title}</span>
        <span className={classes.status}>{statusTitle}</span>
      </div>
      <div className={classes.editWrapper}>
        {!!projectSharedWith.length && (
          <SharedEntityIcon
            owner_url={props.action.org}
            shared_urls={props.action.share_with_orgs}
            projectSharedOrgs={projectSharedWith}
            shared_with={projectSharedWith}
          />
        )}

        {existingEvent && (
          <Tooltip
            title={
              enableAction
                ? ''
                : eventOrgName
                ? translate(
                    'This action was marked as completed by %{org_name}. Only %{org_name} can edit or mark it as incomplete.',
                    { org_name: eventOrgName }
                  )
                : translate(
                    'This action was marked as completed by an external org. You can not edit or mark it as incomplete.'
                  )
            }
          >
            <div>
              <IconButton
                size="small"
                onClick={() => props.openEditDialog(existingEvent, props.action)}
                disabled={!enableAction}
              >
                <EditIcon />
              </IconButton>
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  )
}
export default StageActionCard

import { List } from 'elements/react-admin/List'
import React from 'react'
import {
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  FilterComp,
  FunctionField,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin'
import { connect } from 'react-redux'

export const ManufacturerFilter = connect((state) => {
  return {}
}, null)((props) => (
  <FilterComp {...props}>
    <TextInput label="pos.search" source="q" />
  </FilterComp>
))

export const ManufacturerList = (props) => (
  <List hasSearch={true} {...props}>
    <Datagrid>
      <TextField source="name" />
      <FunctionField
        source={null}
        label="Actions"
        render={(record, source, rest) => (
          <div>
            <EditButton useListStyle={true} record={record} basePath={rest.basePath} />
            <DeleteButton
              useListStyle={true}
              undoable={false}
              record={record}
              resource={rest.resource}
              redirect={`/${rest.resource}`}
            />
          </div>
        )}
        sortable={false}
      />
    </Datagrid>
  </List>
)

export const ManufacturerEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
)

export const ManufacturerCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Create>
)

import { doNotTranslate } from 'util/misc'

export const PERFORMANCE_CALCULATORS_PVWATTS_V6 = 1
export const PERFORMANCE_CALCULATORS_SAM = 2
export const PERFORMANCE_CALCULATORS_MCS = 3
export const PERFORMANCE_CALCULATORS_PVWATTS_V8 = 4
export const PERFORMANCE_CALCULATORS_3RD_PARTY = 5
export const PERFORMANCE_CALCULATORS_MCS_AND_SAM = 6

export type PERFORMANCE_CALCULATOR_ID =
  | typeof PERFORMANCE_CALCULATORS_PVWATTS_V6
  | typeof PERFORMANCE_CALCULATORS_SAM
  | typeof PERFORMANCE_CALCULATORS_MCS
  | typeof PERFORMANCE_CALCULATORS_PVWATTS_V8
  | typeof PERFORMANCE_CALCULATORS_3RD_PARTY
  | typeof PERFORMANCE_CALCULATORS_MCS_AND_SAM

export const SELF_CONSUMPTION_CALCULATORS_DYNAMIC = 1
export const SELF_CONSUMPTION_CALCULATORS_OPENSOLAR = 2

export type SELF_CONSUMPTION_CALCULATOR_ID =
  | typeof SELF_CONSUMPTION_CALCULATORS_DYNAMIC
  | typeof SELF_CONSUMPTION_CALCULATORS_OPENSOLAR

export const PERFORMANCE_CALCULATORS = [
  {
    id: PERFORMANCE_CALCULATORS_SAM,
    name: 'System Advisor Model 2020.02.29.r2',
    translate: false,
    supports_shading: true,
    supports_automated_shading: true,
  },
  {
    id: PERFORMANCE_CALCULATORS_MCS,
    name: 'MCS (Fixed)',
    translate: false,
    restrict_to_country: ['GB'],
    supports_automated_shading: true,
    hide_from_choices: false,
  },
  {
    id: PERFORMANCE_CALCULATORS_PVWATTS_V8,
    name: 'PVWatts v8',
    translate: false,
    supports_automated_shading: false,
  },
  // PVWatts v6 has been decommissioned in early 2024 and has been replaced by PVWatts v8: https://developer.nrel.gov/docs/solar/pvwatts/v8/
  {
    id: PERFORMANCE_CALCULATORS_PVWATTS_V6,
    name: 'PVWatts v6 (Deprecated)',
    translate: false,
    supports_automated_shading: false,
    hide_from_choices: true,
  },
  {
    id: PERFORMANCE_CALCULATORS_3RD_PARTY,
    name: 'Import 3rd Party',
    translate: true,
    supports_automated_shading: false,
    hide_from_choices: false,
  },
  {
    id: PERFORMANCE_CALCULATORS_MCS_AND_SAM,
    name: 'MCS + SAM (Auto)',
    translate: false,
    restrict_to_country: ['GB'],
    supports_automated_shading: true,
    hide_from_choices: false,
  },
]

export const SELF_CONSUMPTION_CALCULATORS = [
  {
    id: SELF_CONSUMPTION_CALCULATORS_DYNAMIC,
    name: 'Auto (default)',
  },
  {
    id: SELF_CONSUMPTION_CALCULATORS_OPENSOLAR,
    name: 'OpenSolar (Override)',
  },
]

export function supportsAnyShading(calculatorId: PERFORMANCE_CALCULATOR_ID): boolean {
  return calculatorId === 1 || calculatorId === 3 || calculatorId === 4 || calculatorId === 6
}

export const getPerformanceCalculatorChoices = (selectedCalculator: number, country_iso2: string) => {
  return PERFORMANCE_CALCULATORS.filter((calculator) => {
    // Continue showing the calculator option that should be hidden if it was already previously selected,
    // otherwise that calculator option is hidden and cannot be selected.
    if (calculator?.hide_from_choices === true && selectedCalculator !== calculator.id) {
      return false
    } else if (calculator.restrict_to_country) {
      return calculator.restrict_to_country.includes(country_iso2)
    } else {
      return true
    }
  })
}

export const getMCSAndSAMSelfConsumptionCalculatorChoices = () => {
  return SELF_CONSUMPTION_CALCULATORS
}

export const translateCalculators = (calculators, translate: (term: string) => string) => {
  return calculators.map((pc) => ({
    ...pc,
    name: pc.translate ? translate(pc.name) : doNotTranslate(pc.name),
  }))
}

/**
 * Checks if the system is within MCS Self Consumption Boundaries based on specific thresholds.
 * Guidelines:
 * - `kwStc` (system power) must be below 50.
 * - `usage_annual_proposed` (annual consumption) must be below or equal to 5999 kWh
 * - `annual` (system output) must be below or equal to 5999 kWh
 *
 * @param {SystemProps}
 * @returns {boolean} - Returns true if within MCS Self Consumption Boundaries, else false.
 */
export function withinMCSGuidelines(system) {
  return (
    system.userData?.kwStc < 50 && system.consumption?.usage_annual_proposed <= 5999 && system.output?.annual <= 5999
  )
}

import { Box } from '@material-ui/core'
import { useNewProjectsList } from 'hooks/useNewProjectsList'
import { styled } from 'opensolar-ui'
import { List as RaList } from 'react-admin'
import { ExtraCreateButton } from './ExtraCreateButton'
import { ListActions } from './ListActions'

const StyledBox = styled(Box)({
  backgroundColor: 'white',
})

export const List = (props) => {
  const ui3Enabled = useNewProjectsList()
  return (
    <RaList
      {...props}
      actions={props.actions === null ? null : props.actions || <ListActions />}
      extraCreateButton={
        props.extraCreateButton || (
          <ExtraCreateButton
            extraCreateButton={props.extraCreateButton}
            extraCreateButtonLabel={props.extraCreateButtonLabel}
            basePath={props.basePath}
            resource={props.resource}
          />
        )
      }
      component={ui3Enabled ? StyledBox : undefined}
    />
  )
}

List.propTypes = {
  ...RaList.propTypes,
}

List.defaultProps = {
  ...RaList.defaultProps,
}

import { DialogActions, DialogContent, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orgSelectors } from 'ducks/orgs'
import Alert from 'elements/Alert'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import { useGetConnectedOrgBeingUsed, useGetOrgIdFromPaymentOptions } from 'projectSections/sections/payments/utils'
import { useNotify } from 'ra-core'
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import PhoenixCreateRole from './PhoenixCreateRole'
import { CommonPromptPropTypes } from './types'

const restClientInstance = restClient(window.API_ROOT + '/api')

type UserOptionType = {
  label: string
  value: string
}

const PhoenixRolePrompt: React.FC<CommonPromptPropTypes> = (props) => {
  const [userOptions, setUserOptions] = useState<UserOptionType[]>([])
  const [selectedUser, setSelectedUser] = useState<string | undefined>(props.currentPhoenixEmail)
  const [showNewUserForm, setShowNewUserForm] = useState<boolean>(false)

  const form = useForm()
  const assignedRoleId: number = form.getState().values.assigned_role_id
  const roleIdToUse = props.roleId ? props.roleId : assignedRoleId
  const project = useFormState().values
  const currentlyLoggedInOrg = useSelector(orgSelectors.getOrg)
  const orgIdFromProjectsPaymentOptions = useGetOrgIdFromPaymentOptions(props.systemUuid || null, project?.org_id)
  const orgId = orgIdFromProjectsPaymentOptions || currentlyLoggedInOrg?.id
  const thisRolesOrg = useSelector(orgSelectors.getOrg)
  const connectedOrgBeingUsed = useGetConnectedOrgBeingUsed(thisRolesOrg, orgId)

  const notify = useNotify()

  useEffect(() => {
    logAmplitudeEvent('phoenix_role_prompt_shown', {})
  }, [])

  useEffect(() => {
    if (orgId) {
      restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${orgId}/phoenix/users/`,
      })
        .then((res) => {
          if (res.data.success && res.data.users?.length) {
            setUserOptions(res.data.users)
          }
        })
        .catch((err) => {
          console.log('err', err)
          notify(
            'We were unable to get a list of Phoenix users. Please make sure there is at least one active user on your Phoenix account',
            'warning'
          )
        })
    }
  }, [orgId])

  const submitNewUser = () => {}

  const connectExistingUser = () => {
    if (!selectedUser) {
      notify('Please select a user', 'warning')
      return
    }
    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${orgId}/roles/${roleIdToUse}/phoenix/users/connect/`,
      data: {
        phoenix_email: selectedUser,
      },
    })
      .then((res) => {
        if (res.data.success) {
          notify('The user has been connected', 'success')
          props.onClose()
          logAmplitudeEvent('phoenix_role_connected', {})
          // trigger a recalculation to clear this error
          window.editor.filter('type', 'OsSystem').forEach((system: any) => {
            system.output.hashed_args = undefined //clear hashed args to bypass calc cache
            system.payment_options.forEach((pmt) => {
              pmt.hashed_args = null
            })
            window.Designer.requestSystemCalculations(system)
          })
        } else {
          notify(
            "The email you entered does not exist in this company's Phoenix account. Please try another email or make a new user.",
            'warning'
          )
        }
      })
      .catch((err) => {
        console.log('err', err)
        notify(
          'We were unable to get a list of Phoenix users. Please make sure there is at least one active user on your Phoenix account',
          'warning'
        )
      })
  }

  const submit = (formVals) => {
    if (showNewUserForm) submitNewUser()
    else connectExistingUser()
  }

  const descriptionText = useMemo(() => {
    if (props.isRoleEdit) {
      if (connectedOrgBeingUsed) {
        return `To use the Phoenix integration this user must connect their OpenSolar account to their Phoenix user account for ${connectedOrgBeingUsed.org_name}. You can either connect to an existing Phoenix user or you can create a new Phoenix user below.`
      } else {
        return 'To use the Phoenix integration this user must connect their OpenSolar account to their Phoenix user account. You can either connect to an existing Phoenix user or you can create a new Phoenix user below.'
      }
    } else {
      if (connectedOrgBeingUsed) {
        return `To use the Phoenix integration the assigned team member for this project must connect their OpenSolar account to their Phoenix user account for ${connectedOrgBeingUsed.org_name}. Please enter the email address of an existing Phoenix user or create a new Phoenix user via the link below`
      } else {
        return 'To use the Phoenix integration the assigned team member for this project must connect their OpenSolar account to their Phoenix user account. Please connect to an existing Phoenix user by selecting it from the dropdown or create a new Phoenix user via the link below'
      }
    }
  }, [props.isRoleEdit, connectedOrgBeingUsed])

  return (
    <>
      {showNewUserForm ? (
        <PhoenixCreateRole roleId={roleIdToUse} setShowNewUserForm={setShowNewUserForm} isRoleEdit={props.isRoleEdit} />
      ) : (
        <>
          <DialogContent>
            <div>
              {!props.isRoleEdit && (
                <Alert severity="warning">
                  The Assigned team member for this project has not connected their Phoenix User.
                </Alert>
              )}
              <p>{descriptionText}</p>
              <div style={{ width: '100%' }}>
                <InputLabel>Phoenix User:</InputLabel>
                {connectedOrgBeingUsed ? (
                  <TextField
                    fullWidth={true}
                    value={selectedUser}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setSelectedUser(e.target.value)}
                  />
                ) : (
                  <Select
                    value={selectedUser}
                    onChange={(e) => setSelectedUser(e.target.value as string)}
                    style={{ width: '100%' }}
                  >
                    {userOptions?.map((user) => (
                      <MenuItem key={user.value} value={user.value}>
                        {user.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', margin: '40px 0px 10px 0px' }}>
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => setShowNewUserForm(!showNewUserForm)}
                >
                  {showNewUserForm ? 'Connect an existing Phoenix user' : 'Or click here to create a new Phoenix user'}
                </span>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <div style={{ margin: '15px' }}>
                <ProUXButton label="Submit" type="primary" onClick={submit} />
              </div>
            </div>
          </DialogActions>
        </>
      )}
    </>
  )
}
export default PhoenixRolePrompt

import { useTranslate } from 'ra-core'
import React from 'react'
import CustomFields from '../../common/CustomFields'
import EditModal from '../../common/EditModal'
import { InputWrapper } from '../../styles'

interface PropTypes {
  open: boolean
  onClose: () => void
}

const CustomFieldsModal: React.FC<PropTypes> = ({ open, onClose }) => {
  const translate = useTranslate()

  return (
    <EditModal
      open={open}
      onClose={onClose}
      headerText={translate('Custom Fields')}
      content={
        <InputWrapper>
          <CustomFields model={'Project'} inputVariant="outlined" />
        </InputWrapper>
      }
    />
  )
}

export default CustomFieldsModal

import { DeleteOutlined } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import DeleteButton from 'projectSections/sections/manage3/common/DeleteButton'
import { useRefresh, useTranslate } from 'ra-core'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { ProjectType } from 'types/projects'

interface PropTypes {
  project: ProjectType
  btnType?: 'list' | 'page'
}

const ProjectDeleteButton: React.FC<PropTypes> = ({ project, btnType = 'page' }) => {
  const history = useHistory()
  const refresh = useRefresh()
  const translate = useTranslate()
  const onDeleteSuccess = useCallback(() => {
    logAmplitudeEvent('project_deleted', { project_id: project?.id })
    if (btnType === 'list') {
      refresh()
    } else {
      history.push('/projects')
    }
  }, [project?.id])
  return (
    <DeleteButton
      title={'project'}
      btnLabel={translate('Delete Project')}
      confirmMode="dialog"
      resource="projects"
      record={project}
      btnProps={{
        startIcon: <DeleteOutlined />,
        style: { justifyContent: 'flex-start' },
      }}
      onSuccess={onDeleteSuccess}
    />
  )
}
export default ProjectDeleteButton

import { NEARMAP_SSO_CONFIG } from 'pages/auth/nearmap/Config'
import OPENSOLAR_AUTH_CONFIG from 'pages/auth/os/Config'
import type HostConfig from './types'

export const OsHostNames = ['localhost', 'dev.sunsuite.com.au', 'staging4.opensolar.com', 'app.opensolar.com']

const DefaultHostConfig: HostConfig = {
  name: 'opensolar',
  hostnames: OsHostNames,
  loginConfigs: [OPENSOLAR_AUTH_CONFIG, NEARMAP_SSO_CONFIG],
}

export default DefaultHostConfig

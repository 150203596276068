import { DesignData } from 'opensolar-sdk'
import type { ProjectType } from 'types/projects'
import type { SystemDataType } from 'types/systems'
import { SystemsDataFromDesignData } from 'util/misc'

const ProjectHelper = {
  getSoldSystemUuidFromProjectData: (projectData: ProjectType) => {
    if (!projectData.system_sold || !projectData.systems) {
      return
    }

    return projectData.systems.find((system) => system.url === projectData.system_sold)?.uuid
  },

  getSoldSystemData: (projectData: ProjectType, designData: DesignData): SystemDataType | undefined => {
    const soldSystemUuid = ProjectHelper.getSoldSystemUuidFromProjectData(projectData)
    if (!soldSystemUuid) {
      return
    }
    const systemData: SystemDataType[] = SystemsDataFromDesignData(designData)
    return systemData.find((s: SystemDataType) => {
      return s.uuid === soldSystemUuid
    })
  },

  getFirstSystemData: (designData: DesignData): SystemDataType | undefined => {
    const systemData: SystemDataType[] = SystemsDataFromDesignData(designData)
    return systemData[0]
  },
}
export default ProjectHelper

import { authSelectors } from 'ducks/auth'
import { viewModeSelectors } from 'ducks/viewMode'
import React from 'react'
import { ReferenceInput, SelectInput, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { CUSTOM_ROLE_TYPES, CustomRoleType } from 'types/customRole'
import useTranslateParse from 'util/useTranslateParse'
import { required } from 'validations'

interface RoleFilter {
  role_type: number
  predefined_only?: number
  with_predefined?: number
  is_lite?: number
  role_id?: number
}

export const PermissionRoleText = (text, translate) => {
  return text === 'Salesperson'
    ? translate(text) + ' (' + translate('panel only design permissions') + ')'
    : translate(text)
}

interface Props {
  disabled?: boolean
  predefined_only?: boolean
  role_type?: CUSTOM_ROLE_TYPES
  source?: string
  label?: string
  className?: string
  style?: object
  fullWidth?: boolean
  setIsAdmin?: boolean
  onChange?: (event: any) => void
  helperText?: string
}

export const RoleSelectInput: React.FC<Props> = ({
  disabled,
  predefined_only,
  role_type = 0,
  source = 'permissions_role',
  label = 'Role',
  style,
  setIsAdmin = true,
  fullWidth,
  className,
  onChange,
  helperText,
}) => {
  const translateParse = useTranslateParse()
  const translate = useTranslate()
  const isAdmin = useSelector(authSelectors.getIsAdmin)
  const form = useForm()
  const is_lite = useSelector(viewModeSelectors.isUserLite)
  const roleFilter: RoleFilter = { role_type: role_type }
  if (predefined_only) {
    roleFilter.predefined_only = 1
  } else {
    roleFilter.with_predefined = 1
  }
  if (is_lite) {
    roleFilter.is_lite = 1
    roleFilter.role_id = form.getState().values.id
  }
  return (
    <ReferenceInput
      className={className}
      style={style}
      alwaysOn={true}
      allowEmpty={role_type === 1}
      source={source}
      label={label}
      fullWidth={fullWidth}
      resource="roles"
      disabled={disabled}
      validate={(value) => {
        // block non admin from creating an admin role
        if (!isAdmin && value === 1) {
          return translate('A Non-admin is not allowed to create an Admin role')
        }
      }}
      reference="permissions_role___aliases"
      filter={roleFilter}
      onChange={(e) => {
        if (onChange) onChange(e)
        if (setIsAdmin) {
          const newRole = e.target.value
          const isFieldExist = form.getFieldState('is_admin')
          if (!isFieldExist) {
            form.registerField('is_admin', () => {}, {})
          }
          //set is_admin to true if Admin selected
          form.change('is_admin', !!(newRole === 1))
        }
      }}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <CustomSelectInput
        fullWidth={fullWidth}
        source={source}
        emptyText={role_type === 0 ? null : 'Full Access'}
        disabled={disabled}
        validate={role_type === 0 ? required : undefined}
        optionValue={role_type === 0 ? 'id' : 'url'}
        optionText={(choice) => PermissionRoleText((choice as CustomRoleType)?.title, translate)}
        helperText={
          helperText !== undefined ? (
            helperText
          ) : (
            <div>
              {role_type === 0
                ? translateParse('For a detailed overview of role permissions see <a>here<a>', {
                    a: (label) => (
                      <a
                        style={{ color: 'rgb(24, 144, 255)' }}
                        href={'https://support.opensolar.com/hc/en-us/articles/4416624009497'}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {label}
                      </a>
                    ),
                  })
                : translateParse(
                    'Restrict access to project functionality by selecting a <manageOrg>permission set<manageOrg>',
                    {
                      manageOrg: (label) => (
                        <Link to="/connected_orgs_roles" target="_blank" rel="noreferrer">
                          {label}
                        </Link>
                      ),
                    }
                  )}
            </div>
          )
        }
      />
    </ReferenceInput>
  )
}

const CustomSelectInput = (props) => {
  const translate = useTranslate()
  const { choices, allowEmpty, roleType, emptyText, optionValue, label } = props
  if (!emptyText || emptyText === '') {
    return <SelectInput {...props} />
  } else {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <span style={{ fontSize: 12, color: '#757575' }}>{label}</span>
        <SelectInput
          {...props}
          label={false}
          style={{ marginTop: 0 }}
          SelectProps={{
            displayEmpty: allowEmpty,
            native: allowEmpty ? undefined : allowEmpty,
            renderValue: (value) => {
              if (value === '') {
                return translate(emptyText)
              } else {
                const findItem = choices.find((x: any) => x[optionValue] === value)
                return findItem?.title
              }
            },
          }}
        />
      </div>
    )
  }
}

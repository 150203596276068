import Tabs from '@material-ui/core/Tabs'
import { permissionsSelectors } from 'ducks/permissions'
import { isEmbeddedWithSdk } from 'ducks/viewMode'
import { ScaffoldOutlineIcon } from 'opensolar-ui'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { doNotTranslate } from 'util/misc'
import { useFeatureFlag } from 'util/split'
import PanelTabsV2 from './tab/PanelTabsV2'
import { StyledTab } from './TabPanel'

const styles = {
  tableIcon: {
    width: 24,
    height: 24,
    marginBottom: 2,
  },
}

export type PanelTabsProps = {
  hasShadingCalcsAwaitingTrigger: boolean
  selectedTab: string
  onChange(newValue, paymentsTabEnabled): void
}

const PanelTabs = ({ hasShadingCalcsAwaitingTrigger, selectedTab, onChange }: PanelTabsProps) => {
  const paymentsTabEnabled = useFeatureFlag('payments_tab', 'on')
  const showMountingTab = useFeatureFlag('studio_tabs_mounting', 'on')
  const enablePvf = useFeatureFlag('pvf_integration', 'on')
  const translate = useTranslate()
  const accessRightsSetting = useSelector(permissionsSelectors.getProjectPermissionsSetting)
  return (
    <Tabs
      style={{
        background: '#efefef',
        flexShrink: 0,
        boxShadow: selectedTab === 'summary' ? '' : 'inset 0px 4px 2px -2px rgb(0 0 0 / 12%)',
      }}
      orientation="vertical"
      variant="scrollable"
      TabIndicatorProps={{
        //hide selected tab indicator
        style: {
          display: 'none',
        },
      }}
      value={selectedTab}
      onChange={(event, newValue) => {
        onChange(newValue, paymentsTabEnabled)
      }}
    >
      <StyledTab
        label={translate('Summary')}
        value="summary"
        errorCategories={['system']}
        hasShadingCalcsAwaitingTrigger={hasShadingCalcsAwaitingTrigger}
        icon={<img alt="Summary" src={window.PUBLIC_URL + '/images/summary.svg'} style={styles.tableIcon} />}
      />
      <StyledTab
        label={translate('Panels')}
        value="panels"
        errorCategories={['module']}
        icon={<img alt="Panels" src={window.PUBLIC_URL + '/images/panels.svg'} style={styles.tableIcon} />}
      />
      {showMountingTab && (
        <StyledTab
          label={translate('Mounting')}
          value="mounting"
          errorCategories={['mounting']}
          icon={<img alt="Panels" src={window.PUBLIC_URL + '/images/mounting.svg'} style={styles.tableIcon} />}
        />
      )}
      <StyledTab
        label={translate('Inverters')}
        value="inverters"
        errorCategories={['inverter']}
        icon={<img alt="Inverters" src={window.PUBLIC_URL + '/images/inverters.svg'} style={styles.tableIcon} />}
      />
      <StyledTab
        label={translate('Batteries')}
        value="batteries"
        errorCategories={['battery']}
        icon={<img alt="Batteries" src={window.PUBLIC_URL + '/images/batteries.svg'} style={styles.tableIcon} />}
      />
      <StyledTab
        label={translate('Others')}
        value="others"
        errorCategories={!showMountingTab ? ['other', 'mounting'] : ['other']}
        icon={
          <img
            alt="Other Components"
            src={window.PUBLIC_URL + '/images/other_components.svg'}
            style={styles.tableIcon}
          />
        }
      />
      {enablePvf && (
        <StyledTab
          label={doNotTranslate('Scaffolding')}
          value="scaffolding"
          errorCategories={['other']}
          icon={<ScaffoldOutlineIcon size={24} />}
        />
      )}
      <StyledTab
        label={translate('Pricing')}
        value="pricing"
        disabled={!accessRightsSetting?.design_pricing?.view}
        errorCategories={['price']}
        id="system-panel-pricing-tab"
        icon={<img alt="Pricing" src={window.PUBLIC_URL + '/images/pricing.svg'} style={styles.tableIcon} />}
      />
      <StyledTab
        label={translate('Incentives')}
        value="incentives"
        disabled={!accessRightsSetting?.design_incentives?.view}
        errorCategories={['incentive']}
        icon={<img alt="Incentives" src={window.PUBLIC_URL + '/images/incentives.svg'} style={styles.tableIcon} />}
      />
      {!paymentsTabEnabled && (
        <StyledTab
          label={translate('Payment Options')}
          value="payment options"
          disabled={!accessRightsSetting?.design_payment_options?.view}
          errorCategories={['payment_option']}
          id="system-panel-payment-option-tab"
          icon={
            <img
              alt="Payment Options"
              src={window.PUBLIC_URL + '/images/payment_options.svg'}
              style={styles.tableIcon}
            />
          }
        />
      )}
      <div></div>
    </Tabs>
  )
}

const PanelTabsWrapper = (props: PanelTabsProps) => {
  const enablePanelTabsV2 = useFeatureFlag('new_design_tabs', 'on')
  // Force SDK to use PanelTabsV2 regardless of feature flags for the environment
  // Partly because only V2 of the tabs has been fully marked up, partly because we don't want to
  // use the old version which will be removed soon, and partly to avoid unexpected changes between
  // SDK environments.
  if (enablePanelTabsV2 || isEmbeddedWithSdk()) {
    return <PanelTabsV2 {...props} />
  } else {
    return <PanelTabs {...props} />
  }
}

export default PanelTabsWrapper

import type { ComponentTypes, ComponentTypesV2 } from 'types/selectComponent'

export type RatingRangeType = {
  min: number // included
  max: number
  value: string
  title: string
}

export const RATING_FILTER_GTE_KEY = 'rating__gte'
export const RATING_FILTER_LTE_KEY = 'rating__lte'

export const MODULE_RATING_RANGES: RatingRangeType[] = [
  {
    min: 0,
    max: 0.36,
    value: '0,0.360',
    title: String.fromCharCode(8804) + ' 360W',
  },
  {
    min: 0.36,
    max: 0.38,
    value: '0.360,0.380',
    title: '360W  -  380W',
  },
  {
    min: 0.38,
    max: 0.4,
    value: '0.380,0.400',
    title: '380W  -  400W',
  },
  {
    min: 0.4,
    max: 0.42,
    value: '0.40,0.420',
    title: '400W  -  420W',
  },
  {
    min: 0.42,
    max: 0.44,
    value: '0.420,0.440',
    title: '420W  -  440W',
  },
  {
    min: 0.44,
    max: 1.0,
    value: '0.44,1.000',
    title: '≥ 440W',
  },
]

export const INVERTER_RATING_RANGES: RatingRangeType[] = [
  {
    min: 0,
    max: 2,
    value: '0,2',
    title: String.fromCharCode(8804) + ' 2kW',
  },
  {
    min: 2,
    max: 3,
    value: '2,3',
    title: '2kW  -  3kW',
  },
  {
    min: 3,
    max: 4,
    value: '3,4',
    title: '3kW  -  4kW',
  },
  {
    min: 4,
    max: 5,
    value: '4,5',
    title: '4kW  -  5kW',
  },
  {
    min: 5,
    max: 6,
    value: '5,6',
    title: '5kW  -  6kW',
  },
  {
    min: 6,
    max: 7,
    value: '6,7',
    title: '6kW  -  7kW',
  },
  {
    min: 7,
    max: 8,
    value: '7,8',
    title: '7kW  -  8kW',
  },
  {
    min: 8,
    max: 9,
    value: '8,9',
    title: '8kW  -  9kW',
  },
  {
    min: 9,
    max: 10,
    value: '9,10',
    title: '9kW  -  10kW',
  },
  {
    min: 10,
    max: 15,
    value: '10,15',
    title: '10kW  -  15kW',
  },
  {
    min: 15,
    max: 10000,
    value: '15,10000',
    title: '≥ 15kW',
  },
]

export const BATTERY_RATING_RANGES: RatingRangeType[] = [
  {
    min: 0,
    max: 3,
    value: '0,3',
    title: String.fromCharCode(8804) + ' 3kWh',
  },
  {
    min: 3,
    max: 5,
    value: '3,5',
    title: '3kWh  -  5kWh',
  },
  {
    min: 5,
    max: 7,
    value: '5,7',
    title: '5kWh  -  7kWh',
  },
  {
    min: 7,
    max: 9,
    value: '7,9',
    title: '7kWh  -  9kWh',
  },
  {
    min: 9,
    max: 11,
    value: '9,11',
    title: '9kWh  -  11kWh',
  },
  {
    min: 11,
    max: 13,
    value: '11,13',
    title: '11kWh  -  13kWh',
  },
  {
    min: 13,
    max: 15,
    value: '13,15',
    title: '13kWh  -  15kWh',
  },
  {
    min: 15,
    max: 1000,
    value: '15,1000',
    title: '≥ 15kWh',
  },
]

export const getRangeOptionsByComponentType = (componentType: ComponentTypesV2 | ComponentTypes) => {
  if (componentType === 'module') {
    return MODULE_RATING_RANGES
  } else if (componentType === 'inverter') {
    return INVERTER_RATING_RANGES
  } else if (componentType === 'battery') {
    return BATTERY_RATING_RANGES
  }
}

export const getRangeOptionByValue = (value: string, ranges: RatingRangeType[]): RatingRangeType | undefined => {
  for (let i = 0; i < ranges.length; i++) {
    if (ranges[i].value === value) {
      return ranges[i]
    }
  }
  return
}

export const getOptionValueByFilterValues = ({
  ranges,
  gte,
  lte,
}: {
  ranges: RatingRangeType[]
  gte: number
  lte: number
}): string | undefined => {
  for (let i = 0; i < ranges.length; i++) {
    const { min, max, value } = ranges[i]
    if (min === gte && max === lte) {
      return value
    }
  }
  return
}

const getMatchedRange = (target: number, ranges: RatingRangeType[]): RatingRangeType | undefined => {
  for (let i = 0; i < ranges.length; i++) {
    const { min, max, value } = ranges[i]
    if (target >= min && target <= max) {
      return ranges[i]
    }
  }
  return
}

export const getDefaultValue = (args?: {
  specsData: { [key: string]: string | number }
  componentType?: ComponentTypesV2 | ComponentTypes
}) => {
  if (!args) return
  const { specsData, componentType } = args
  let matchedRange: RatingRangeType | undefined
  if (componentType === 'module' && typeof specsData.kw_stc === 'number') {
    matchedRange = getMatchedRange(specsData.kw_stc, MODULE_RATING_RANGES)
  } else if (componentType === 'inverter' && typeof specsData.max_power_rating === 'number') {
    matchedRange = getMatchedRange(specsData.max_power_rating, INVERTER_RATING_RANGES)
  } else if (componentType === 'battery' && typeof specsData.max_power_rating === 'number') {
    matchedRange = getMatchedRange(specsData.max_power_rating, BATTERY_RATING_RANGES)
  }

  if (matchedRange) {
    return { [RATING_FILTER_GTE_KEY]: matchedRange?.min, [RATING_FILTER_LTE_KEY]: matchedRange?.max }
  }

  return
}

import { makeStyles, Paper, Popover, Portal } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import { PremiumImageryBlockReasonType } from 'types/global'
import { ViewTypes } from 'types/view'
import Tools from './PopoverTools'
import PopoverViewSelector from './PopoverViewSelector'
import ShowCustomerToggle from './ShowCustomerToggle'

import { useSelector } from 'react-redux'
const PopoverDecorator = () => {
  return (
    <div
      style={{
        position: 'absolute',
        width: 20,
        height: 20,
        left: '45%',
        bottom: '-20px',
        display: 'inline-block',
        margin: 0,
        background: 'rgb(255,255,255)',
        transform: 'rotate(45deg) translateX(-50%) translateY(-50%)',
      }}
    ></div>
  )
}

const useStyles = makeStyles((theme: any) => ({
  popover: {
    background: 'rgba(255,255,255,0)',
    boxShadow: 'none',
    overflow: 'hidden',
    pointerEvents: 'none',
  },
  root: {
    opacity: 0.9,
  },
  content: {
    width: 200,
    padding: 10,
    position: 'absolute',
    left: 0,
    bottom: 0,
    pointerEvents: 'all',
  },
}))

type PopupPropTypes = {
  anchorEl: HTMLElement | null
  handleClose: () => void
  view: ViewTypes
  isPremiumImageryAvailable: boolean
  premiumImageryUnavailableReson: PremiumImageryBlockReasonType
  premiumImageryActivations: any
}

const ToolbarViewsPopover: React.FC<PopupPropTypes> = (props) => {
  const classes = useStyles()
  const [count, setCount] = useState(0)
  const forcePopoverReposition = useCallback(() => {
    setCount(count + 1)
  }, [count])
  const availableImageryTypes = useSelector((state: any) => {
    return state.designer?.detectImagery?.availableMapTypes || []
  })
  const mode = props.view.uuid === 'new' ? 'add' : 'change'
  const calculatedLeft = (props.anchorEl?.offsetLeft || 0) - 60
  return (
    <Popover
      open={Boolean(props.anchorEl)}
      id={'toolbar-view-popover' + props.view?.uuid}
      anchorEl={props.anchorEl}
      classes={{ root: classes.root, paper: classes.popover }}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      onClose={props.handleClose}
      container={() => document.getElementById('DesignerRootDiv')}
    >
      {Boolean(props.anchorEl) && (
        <Portal container={() => document.getElementById('ToolbarViewsViewButtons')}>
          <Paper classes={{ root: classes.content }} style={{ left: calculatedLeft, bottom: 80 }}>
            {mode === 'change' && (
              <Tools handleClose={props.handleClose} forcePopoverReposition={forcePopoverReposition} />
            )}
            {availableImageryTypes && availableImageryTypes.length > 0 && (
              <PopoverViewSelector
                availableImageryTypes={availableImageryTypes}
                mode={mode}
                forcePopoverReposition={forcePopoverReposition}
                isPremiumImageryAvailable={props.isPremiumImageryAvailable}
                premiumImageryUnavailableReson={props.premiumImageryUnavailableReson}
              />
            )}
            {mode === 'change' && <ShowCustomerToggle view={props.view} />}
            <PopoverDecorator />
          </Paper>
        </Portal>
      )}
    </Popover>
  )
}

export default ToolbarViewsPopover

import { Link } from '@material-ui/core'
import { privacyLink, termsLink_external } from 'constants/links'
import React from 'react'
import useTranslateParse from 'util/useTranslateParse'

export const TermsAndPrivacyLinks = () => {
  const translateParse = useTranslateParse()

  return (
    <>
      {translateParse('By proceeding you agree to the <a1>Terms & Conditions<a1> and <a2>Privacy Notice<a2>', {
        a1: (label) => (
          <Link href={termsLink_external} target="_blank">
            {label}
          </Link>
        ),
        a2: (label) => (
          <Link href={privacyLink} target="_blank">
            {label}
          </Link>
        ),
      })}
    </>
  )
}

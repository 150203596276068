import { Typography } from '@material-ui/core'
import { setSelectComponentDialog } from 'ducks/selectComponent'
import { withHardwareSelectorV2, WithHardwareSelectorV2Props } from 'elements/hardwareSelectorV2/withHardwareSelectorV2'
import type LineItemType from 'pages/ordering/OrderLineItem'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { ComponentTypesV2 } from 'types/selectComponent'

type ProductTitleProps = WithHardwareSelectorV2Props & {
  lineItem: LineItemType
  location: 'cart' | 'design'
}

const useStyles = makeOpenSolarStyles((theme) => ({
  productTitle: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    textDecorationLine: 'underline',
    cursor: 'pointer',
  },
}))

const ProductTitle = ({
  lineItem,
  enableHardwareDialogV2,
  handleOpenHardwareSelector,
  location,
}: ProductTitleProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const handleShowHardwareSelector = () => {
    if (enableHardwareDialogV2) {
      handleOpenHardwareSelector({
        componentTypes: [lineItem?.otherComponentType || (lineItem?.componentType as ComponentTypesV2)],
        title: 'Select Component',
        defaultFilterValues: { other_component_type: lineItem?.otherComponentType },
      })
    } else {
      dispatch(
        setSelectComponentDialog(
          true,
          window.WorkspaceHelper.project.org_id,
          window.WorkspaceHelper.project.id,
          [lineItem?.uuid],
          lineItem?.componentType
        )
      )
    }
  }

  const handleRedirectToProductPage = () => {
    history.push(`/shop/product/${lineItem?.code}`)
  }

  return (
    <div onClick={() => (location === 'design' ? handleShowHardwareSelector() : handleRedirectToProductPage())}>
      <Typography className={classes.productTitle}>{lineItem?.data?.short_description}</Typography>
    </div>
  )
}

export default withHardwareSelectorV2(ProductTitle)

import { makeStyles, Theme, Typography } from '@material-ui/core'
import { StyledDialog, StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'layout/StyledDialog'
import { Button } from 'opensolar-ui'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { FileField, FileInput, useTranslate } from 'react-admin'
import { Form } from 'react-final-form'
import { Link } from 'react-router-dom'
import appStorage from 'storage/appStorage'
import Alert from './Alert'

const useStyles = makeStyles((theme: Theme) => ({
  fileInput: {
    margin: 0,
  },
  errorTitle: {
    margin: 0,
    marginBottom: 10,
  },
}))

type PropsType = {
  isOpen: boolean
  handleClose: Function
  options?: any
  uploadUrl: string
  extraActions?: JSX.Element
  title?: string
  className?: String
  description?: string | JSX.Element
}

type RequestType = {
  message: string
  filename: string
  csv_file_upload_request_id: string
}

type ErrorType = {
  message: string
  filename: string
}

type ResultsType = {
  requests: RequestType[]
  // errors: ErrorType[]
}

const ShowResults: FC<ResultsType> = ({ requests }) => {
  const translate = useTranslate()
  return (
    <div>
      {/* {errors.map((error, index) => {
        return (
          <div key={error.filename + index}>
            <div>{error.filename}</div>
            <Alert severity="error">{error.message}</Alert>
          </div>
        )
      })} */}
      {requests.map((request: RequestType) => {
        return (
          <div key={request.csv_file_upload_request_id}>
            <div>{request.message}</div>
            <div>
              {request.filename}{' '}
              <Link to={`/csv_file_upload_requests/${request.csv_file_upload_request_id}`}>
                {translate('View Details')}
              </Link>
            </div>
            <hr />
          </div>
        )
      })}
    </div>
  )
}

const ShowCSVError = () => {
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <Alert severity="error">
      <p className={classes.errorTitle}>{translate('Error with parsing uploaded file.')}</p>
      <p className={classes.fileInput}>
        {translate(
          'Please reference one of our downloadable sample formats to ensure the data format is valid. Click on the upload button to view sample interval data formats.'
        )}
      </p>
    </Alert>
  )
}
//strings to differentiate interval data csv uploads
const INTERVAL_DATA_IDENTIFIERS = {
  error: 'interval_data_error',
  uploadURL: 'interval_data_upload_to_project',
  success: 'Interval data uploaded successfully',
}

const CSVUploadDialog: FC<PropsType> = ({
  isOpen,
  handleClose,
  uploadUrl,
  className,
  options,
  extraActions,
  title = '',
  description = '',
}) => {
  const [uploadFileRequests, setUploadFileRequests] = useState<RequestType[]>([])
  const [uploadFileErrors, setUploadFileErrors] = useState<ErrorType[]>([])
  const csvError = useMemo(() => {
    return !!uploadFileErrors.filter((x) => x.message === INTERVAL_DATA_IDENTIFIERS.error)?.length
  }, [uploadFileErrors])
  const classes = useStyles()
  const translate = useTranslate()

  const doClose = useCallback((a1, a2) => {
    setUploadFileErrors([])
    handleClose(a1, a2)
  }, [])

  const handleUpload = useCallback((value) => {
    let fileName = value.csv_file[0].title
    const regex = new RegExp('[^.]+$')
    let extension = fileName.match(regex)
    const fileData = value.csv_file && value.csv_file[0]
    if (extension[0] === 'csv') {
      if (fileData) {
        const data = new FormData()
        data.append('file', fileData.rawFile)
        data.append('user', 'hubot')
        fetch(uploadUrl, {
          method: 'POST',
          body: data,
          headers: {
            //@ts-ignore
            Authorization: 'Bearer ' + appStorage.getToken(),
          },
        })
          .then(
            (response) => response.json() // if the response is a JSON object
          )
          .then(
            (response) => {
              if (response.error && response.error.length > 0) {
                setUploadFileErrors((errors: ErrorType[]) => {
                  const newRequestError = { message: response.error, filename: fileData.title }
                  return [newRequestError, ...errors]
                })
              } else if (response.message === INTERVAL_DATA_IDENTIFIERS.success) {
                doClose(response.usage, fileData)
              } else {
                setUploadFileRequests((state: RequestType[]) => {
                  const newRequest = {
                    filename: fileData.title,
                    message: response.message,
                    csv_file_upload_request_id: response.csv_file_upload_request_id,
                  }
                  return [newRequest, ...state]
                })
              }
            } // Handle the success response object
          )
          .catch((error) => {
            if (uploadUrl.includes(INTERVAL_DATA_IDENTIFIERS.uploadURL)) {
              setUploadFileErrors((errors: ErrorType[]) => {
                const newRequestError = { message: INTERVAL_DATA_IDENTIFIERS.error, filename: fileData.title }
                return [newRequestError, ...errors]
              })
            }
            console.log('error:', error)
          })
      }
    } else {
      setUploadFileErrors((errors: ErrorType[]) => {
        const newRequestError = { message: 'Please upload a .csv file in a valid format.', filename: fileData.title }
        return [newRequestError, ...errors]
      })
    }
  }, [])
  return (
    <Form
      onSubmit={handleUpload}
      render={({ handleSubmit, form }) => (
        <StyledDialog className={className} open={isOpen} modal={false} {...options} onClose={doClose}>
          <StyledDialogTitle>{title && translate(title)}</StyledDialogTitle>

          <StyledDialogContent>
            {csvError && <ShowCSVError />}
            {typeof description === 'string' ? (
              <Typography variant="body2" style={{ color: '#626262' }}>
                {translate(description)}
              </Typography>
            ) : (
              description && React.cloneElement(description)
            )}
            <ShowResults requests={uploadFileRequests} />
            <FileInput
              className={classes.fileInput}
              label={null}
              source="csv_file"
              placeholder={<p>{translate('Click to upload or drag & drop a file here')}</p>}
            >
              <FileField source="src" title="title" />
            </FileInput>

            {uploadFileErrors.length > 0 && uploadFileErrors[0]?.message !== INTERVAL_DATA_IDENTIFIERS.error && (
              <div style={{ color: 'red', marginTop: 10, fontSize: 14 }}>{translate(uploadFileErrors[0]?.message)}</div>
            )}
          </StyledDialogContent>
          <StyledDialogActions>
            {extraActions && React.cloneElement(extraActions)}
            {/* @ts-ignore */}
            <Button variant="contained" color="default" onClick={doClose}>
              {translate('Cancel')}
            </Button>
            <Button
              disabled={!!!form.getState().values?.csv_file}
              variant="contained"
              color="primary"
              onClick={(event) => {
                handleSubmit(event)
                form.reset()
              }}
            >
              {translate('Upload')}
            </Button>
          </StyledDialogActions>
        </StyledDialog>
      )}
    />
  )
}

export default CSVUploadDialog

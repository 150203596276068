import React from 'react'
import { useTranslate } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import BasicLink from './BasicLink'

const useStyles = makeOpenSolarStyles((theme: any) => ({
  hint: {
    margin: '30px 5px 5px 10px',
    justifyContent: 'space-between',
    textAlign: 'center',
    marginTop: '1em',
    fontSize: '11pt',
  },
}))

const LoginPageActions = ({
  onForgotPassword,
  onRegister,
  PartnerPrompts,
}: {
  onForgotPassword(): void
  onRegister(): void
  PartnerPrompts?: React.ReactNode[]
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <div>
      {PartnerPrompts?.map((PartnerPrompt, index) => <div key={index}>{PartnerPrompt}</div>)}
      <div className={classes.hint}>
        <BasicLink id="create_account_link" handleClick={onRegister} label={translate('Create free account')} />
        <BasicLink
          id="login-forgot-password-action"
          handleClick={onForgotPassword}
          label={translate('Forgot password?')}
        />
      </div>
    </div>
  )
}

export default LoginPageActions

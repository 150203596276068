import { Grid, makeStyles } from '@material-ui/core'
import CustomField from 'elements/field/CustomField'
import CheckboxInput from 'elements/input/CheckboxInput'
import React from 'react'
import { useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { Theme } from 'types/themes'
import { useFeatureFlag } from 'util/split'
import { RoleType } from './constants'

type PropsType = {
  predefinedAccessRights: any
  roleType: RoleType | undefined
}

const useStyles = makeStyles<Theme, {}>((theme: Theme) => ({
  wrapper: { maxWidth: 500, padding: '20px 0' },
  header: {
    padding: 5,
    fontWeight: 'bold',
  },
  fieldName: { padding: '5px 0', display: 'inline-block' },
  checkbox: {
    padding: 5,
  },
  dividerRight: {
    borderRight: '1px solid' + theme.greyMid1,
    marginRight: 20,
  },
}))

const TableHeading = () => {
  const translate = useTranslate()
  const classes = useStyles()
  return (
    <Grid container spacing={1} wrap="nowrap">
      <Grid item xs={4}>
        <span style={{ fontWeight: 'bold' }}>{translate('Fields')}</span>
      </Grid>
      <Grid item xs={2} className={classes.dividerRight}>
        <span className={classes.header}>{translate('View')}</span>
      </Grid>
      <Grid item xs={2}>
        <span className={classes.header}>{translate('Create')}</span>
      </Grid>
      <Grid item xs={2}>
        <span className={classes.header}>{translate('Edit')}</span>
      </Grid>
      <Grid item xs={2}>
        <span className={classes.header}> {translate('Delete')}</span>
      </Grid>
    </Grid>
  )
}
const accessTypes = ['view', 'create', 'edit', 'delete']
const AccessRightCheckboxTable: React.FC<PropsType> = ({ predefinedAccessRights, roleType }) => {
  const enableWallet = useFeatureFlag('os_wallet', 'on')
  const classes = useStyles()
  const translate = useTranslate()
  const form = useForm()
  const formValue = form.getState().values
  const handleCheck = (category, key, type, value) => {
    const getField = `permissions.${category}.${key}`
    if (predefinedAccessRights[category][key].sync_disabled_to) {
      const sync_to = predefinedAccessRights[category][key].sync_disabled_to
      if (sync_to === type) {
        for (const otherFlag of predefinedAccessRights[category][key].disabled) {
          form.mutators.updateField(getField + '.' + otherFlag, value)
        }
      }
    } else {
      if (type === 'view' && value === false) {
        form.mutators.updateField(getField, { view: false, create: false, edit: false, delete: false })
      } else if (type !== 'view' && value === true) {
        form.mutators.updateField(getField + '.view', true)
      }
    }
  }

  return (
    <div>
      {!roleType?.hide_perms_control && (
        <>
          <h1>{translate('Control')}</h1>
          <div className={classes.wrapper}>
            <TableHeading />
            {predefinedAccessRights.control &&
              Object.keys(predefinedAccessRights.control)?.map((key: string) => {
                const config = predefinedAccessRights.control[key]
                return (
                  <Grid key={key} container spacing={1} wrap="nowrap">
                    <Grid item xs={4}>
                      <span className={classes.fieldName}>{translate(config.label)}</span>
                    </Grid>
                    {accessTypes?.map((type: string) => {
                      return (
                        <Grid item xs={2} className={type === 'view' ? classes.dividerRight : ''}>
                          <CustomField
                            component={CheckboxInput}
                            options={{ classes: { root: classes.checkbox } }}
                            defaultValue={config.disabled.includes(type) ? 0 : 1}
                            resource="permissions_role"
                            name={`permissions.control.${key}.${type}`}
                            source={`permissions.control.${key}.${type}`}
                            label={false}
                            disabled={
                              config.disabled.includes(type) || roleType?.disabled.includes(`control.${key}.${type}`)
                            }
                            onChange={(value) => {
                              handleCheck('control', key, type, value)
                            }}
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
                )
              })}
          </div>
        </>
      )}
      {!roleType?.hide_perms_project && (
        <>
          <h1>{translate('Project')}</h1>
          <div className={classes.wrapper}>
            <TableHeading />
            {predefinedAccessRights.project &&
              Object.keys(predefinedAccessRights.project).map((key: string) => {
                const config = predefinedAccessRights.project[key]
                if (config?.resource === 'purchases_for_projects' && !enableWallet) return null
                return (
                  <Grid key={key} container spacing={1} wrap="nowrap">
                    <Grid item xs={4}>
                      <span className={classes.fieldName}> {translate(config.label)}</span>
                    </Grid>
                    {accessTypes?.map((type: string) => {
                      return (
                        <Grid item xs={2} className={type === 'view' ? classes.dividerRight : ''}>
                          <CustomField
                            component={CheckboxInput}
                            options={{ classes: { root: classes.checkbox } }}
                            defaultValue={config.disabled.includes(type) ? 0 : 1}
                            resource="permissions_role"
                            name={`permissions.project.${key}.${type}`}
                            source={`permissions.project.${key}.${type}`}
                            label={false}
                            disabled={
                              config.disabled.includes(type) || roleType?.disabled.includes(`project.${key}.${type}`)
                            }
                            onChange={(value) => {
                              handleCheck('project', key, type, value)
                            }}
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
                )
              })}
          </div>
        </>
      )}
    </div>
  )
}

export default AccessRightCheckboxTable

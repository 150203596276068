import { Typography } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orderSelectors } from 'ducks/orderComponents'
import Alert from 'elements/Alert'
import Button from 'elements/button/Button'
import SegenLoginButton from 'pages/ordering/banner/button/SegenLoginButton'
import PricingRefreshAlert from 'pages/ordering/elements/PricingRefreshAlert'
import { getOrderCost } from 'pages/ordering/utils'
import { memo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import useSegenAccountDetail from 'services/segen/useSegenAccountDetail'
import useSegenAuthenticationStatus from 'services/segen/useSegenAuthenticationStatus'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { doNotTranslate } from 'util/misc'
import CreditLimit from '../CreditLimit'
import PriceBreakdown from '../PriceBreakdown'

const ToolTipMessage = 'A new tab will open for you to complete your order with the hardware partner.'

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    boxSizing: 'border-box',
    border: theme.border.default,
    borderRadius: '8px',
    padding: 15,
    marginBottom: 15,
  },
  paymentWarningHeader: {
    marginTop: 0,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 0px 16px 0px',
    gap: '16px',
  },
  shippingCalculationFooter: {
    fontSize: '14px',
    lineHeight: '22px',
    color: theme.greyMid1,
  },
  shippingCalculationFooterContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '10px',
  },
}))

export const creditLimitOverride = () => {
  /*
  Quick hack to allow overriding credit limit during testing
  */
  return window['creditLimitOverride']
}

const SegenCartActions = () => {
  const classes = useStyles()
  const history = useHistory()
  const { isLoading, segenAccount } = useSegenAccountDetail()
  const segenConnectStatus = useSegenAuthenticationStatus()
  const currencySymbol = segenAccount?.currency.symbol || '£'
  const creditLimit = creditLimitOverride() || segenAccount?.accountSummary.creditLimit.amount
  const orderableLineItems = useSelector(orderSelectors.getOrderableLineItems)
  const cost = getOrderCost(orderableLineItems)
  const hasEnoughCredit = (creditLimit || 0) > cost

  const handleCtaClick = useCallback(
    (event) => {
      event.preventDefault()

      logAmplitudeEvent('hardware_place_order_cta_clicked', {
        distributor: 'segen',
        source: 'shopping_cart',
        codes: orderableLineItems.map((lineItem) => lineItem.code),
        creditLimit,
        cost,
      })
      // Redirects to <PreOrderPage/>
      history.push('/shop/cart/segen/')
    },
    [hasEnoughCredit, creditLimit]
  )

  return (
    <>
      <div className={classes.wrapper}>
        <CreditLimit
          loading={isLoading}
          currencySymbol={currencySymbol}
          creditLimit={creditLimit}
          supplierName="Segen"
        />
        {segenConnectStatus !== 'authenticated' && !isLoading && (
          <Alert severity="warning">
            {doNotTranslate(
              'You need to be logged into Segen to retrieve your credit limit and place your quote or order.'
            )}
          </Alert>
        )}
      </div>
      <div className={classes.wrapper}>
        <PriceBreakdown vatPercentage={20} />
        <div className={classes.buttonContainer}>
          {segenConnectStatus === 'authenticated' ? (
            <Button
              color="primary"
              disabled={isLoading || orderableLineItems.length === 0}
              fullWidth={true}
              onClick={handleCtaClick}
            >
              {'Review Order'}
            </Button>
          ) : (
            <SegenLoginButton fullWidth={true} returnUrl={'/shop/after_connect/segen/cart'} />
          )}
        </div>
        <div className={classes.shippingCalculationFooterContainer}>
          <Typography className={classes.shippingCalculationFooter}>*Shipping calculated on the next page</Typography>
        </div>
      </div>
      {!isLoading && !hasEnoughCredit && segenConnectStatus === 'authenticated' && (
        <Alert severity="warning">
          <h1 className={classes.paymentWarningHeader}>Upfront payment required</h1>Your purchase exceeds your credit
          limit. Payment will be required on Segen portal after placing your order to confirm shipment.{' '}
        </Alert>
      )}
      <div>{orderableLineItems.length > 0 && <PricingRefreshAlert lineItems={orderableLineItems} />}</div>
    </>
  )
}

export default memo(SegenCartActions)

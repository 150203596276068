import { Tooltip, useMediaQuery } from '@material-ui/core'
import { Button } from 'opensolar-ui'
import useTranslateWithVariable from 'projectSections/hooks/useTranslateWithVariable'
import { FC } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { Theme } from 'types/themes'
import { DialogHelper } from 'util/misc'

type PropsType = {
  enabled: boolean
  isLoading?: boolean
  orgName?: string
  handleSelect(): void
}

const SelectButton: FC<PropsType> = ({ enabled, isLoading = false, orgName, handleSelect }) => {
  const notify = useNotify()
  const translate = useTranslate()
  const translateWithVariable = useTranslateWithVariable()
  const buttonSize = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs')) ? 'small' : 'medium'

  if (enabled) {
    return (
      <Button
        disabled={isLoading}
        variant="contained"
        size={buttonSize}
        onClick={(e) => {
          e.stopPropagation()
          DialogHelper.beforeClose()
          handleSelect()
        }}
      >
        <span>{translate('Select')}</span>
      </Button>
    )
  } else {
    return (
      <Tooltip
        title={translate('Request admin to activate this component or to update your role permissions')}
        PopperProps={{ style: { zIndex: 2001 } }}
        placement="top"
        arrow
      >
        <Button
          variant="outlined"
          style={{ background: 'rgba(0, 0, 0, 0.12)', color: 'rgba(0, 0, 0, 0.26)', cursor: 'unset' }}
          size={buttonSize}
          onClick={(e) => {
            e.stopPropagation()
            // Required for touch devices who will not see the tooltip and will probably click the button
            notify(
              translateWithVariable('Please contact your admin at %{org_name} to activate a component', {
                org_name: orgName || 'your org',
              }),
              'warning'
            )
          }}
        >
          <span>{translate('Select')}</span>
        </Button>
      </Tooltip>
    )
  }
}

export default SelectButton

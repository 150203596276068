export enum Scenario {
  ESTIMATE_COST = 'ESTIMATE_COST',
  RECALCULATE_COST = 'RECALCULATE_COST',
  CREATE_QUOTE = 'CREATE_QUOTE',
  PENDING_REVIEW = 'PENDING_REVIEW',
  MAKE_PAYMENT = 'MAKE_PAYMENT',
  PAYMENT_SETTLED = 'PAYMENT_SETTLED',
  CANCELLED = 'CANCELLED',
  PRICE_NOT_AVAILABLE = 'PRICE_NOT_AVAILABLE',
  CONFIRM_ORDER = 'CONFIRM_ORDER',
}

export const descriptions = {
  ESTIMATE_COST: 'Click "Calculate Cost" to calculate the cost for scaffolding for this project.',
  CREATE_QUOTE: `Click "Create Quote" to quickly create a quote for scaffolding for this project with PVF.`,
  RECALCULATE_COST: `Click "Create Quote" to quickly create a quote for scaffolding for this project with PVF.`,
  PENDING_REVIEW: `We are waiting for PVF to share the cost of the scaffolding with us. This will take around 2 business hours.`,
  MAKE_PAYMENT: '',
  ORDER_SUBMITTED_TEXT_AFTER_48_HOURS: `To cancel your order, please contact PVF at 01271 866777, as it's now past 48 hours since the order was submitted.`,
  CANCELLED: `To get a new cost estimate for this project, tap “Calculate Cost” below.`,
  PAYMENT_SETTLED: '',
}

export const scenarioDetails = {
  PENDING_REVIEW: {
    backgroundColor: 'rgba(255, 241, 218, 1)',
    color: 'rgba(196, 126, 10, 1)',
    text: 'Pending Review',
  },
  MAKE_PAYMENT: {
    backgroundColor: '#FFF1DA',
    color: '#C47E0A',
    text: 'Order Submitted',
  },
  CANCELLED: {
    backgroundColor: '#FFE3E3',
    color: '#961616',
    text: 'Cancelled',
  },
  PAYMENT_SETTLED: {
    backgroundColor: 'rgba(225, 250, 234, 1)',
    color: 'rgba(1, 102, 38, 1)',
    text: 'Payment Confirmed',
  },
  PRICE_NOT_AVAILABLE: {
    backgroundColor: '#FFF1DA',
    color: '#C47E0A',
    text: 'Pending',
  },
  CONFIRM_ORDER: {
    backgroundColor: '#FFF1DA',
    color: '#C47E0A',
    text: 'Confirm Order',
  },
}

export const booleanMap = {
  Yes: 1,
  No: 0,
}

export const heightOptions = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10]
export const lengthOptions = [
  0.5,
  1,
  1.5,
  2,
  2.5,
  3,
  3.5,
  4,
  4.5,
  5,
  5.5,
  6,
  6.5,
  7,
  7.5,
  8,
  8.5,
  9,
  9.5,
  10,
  10.5,
  11,
  11.5,
  12,
  12.5,
  13,
  13.5,
  14,
  14.5,
  15,
  15.5,
  16,
]

export const colorMapping = {
  red: 'red',
  amber: 'orange',
  green: 'green',
}

export const colorMappingText = {
  red: 'Action required',
  amber: 'Attention',
  green: 'All clear',
}

export const costReviewFromTeam =
  "Given the specifications of the project, our team needs to review everything carefully. This review is to make sure we give you the most accurate estimate possible.\n\nAll you need to do is create a quote, and we'll aim to get back to you with the cost within 2 business hours"

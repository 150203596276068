import { Grid, makeStyles } from '@material-ui/core'
import { AddOutlined, EditOutlined } from '@material-ui/icons'
import DependentInput from 'elements/input/DependentInput'
import { List } from 'elements/react-admin/List'
import ListActions from 'elements/react-admin/ListActions'
import { useEffect } from 'react'
import {
  BooleanInput,
  Button,
  Create,
  Datagrid,
  Edit,
  FunctionField,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  required,
  useNotify,
  useRedirect,
} from 'react-admin'
import { useHistory } from 'react-router-dom'
import { FINANCE_CTA_TYPE_CHOICES, FinanceCtaType } from './constants'

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const getFinancePartnerId = () => {
  const path = window.location.hash.substring(window.location.hash.indexOf('#') + 2)
  const params = new URLSearchParams(path.substring(path.indexOf('?')))
  const paramStr = params.get('finance_partner_id')
  return paramStr ? parseInt(paramStr) : undefined
}

export const FinanceCTAList = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const financePartnerId = getFinancePartnerId()

  useEffect(() => {
    if (!financePartnerId) {
      history.push('/finance_partners')
    }
  }, [financePartnerId])

  const CustomCreateButton = () => (
    <Button
      label="Create a Finance CTA"
      onClick={() => history.push(`/finance_ctas/create?finance_partner_id=${financePartnerId}`)}
      startIcon={<AddOutlined />}
      variant="outlined"
    />
  )

  const goToEdit = (record) => {
    history.push(`/finance_ctas/${record.id}?finance_partner_id=${financePartnerId}`)
  }
  return (
    <List
      hasSearch={true}
      actions={<ListActions CustomButton={CustomCreateButton()} />}
      {...props}
      hasCreate={false}
      filter={{ finance_partner_id: financePartnerId }}
    >
      <Datagrid>
        <TextField source="title" />
        <TextField source="finance_partner_integration_name" label="Integration" />
        <FunctionField
          source="type"
          label="Type"
          sortable={false}
          render={(record: any, source, rest) => <div>{record.type?.replaceAll('_', ' ')}</div>}
        />
        <FunctionField
          source="finance_product"
          label="Actions"
          render={(record: any, source, rest) => (
            <div className={classes.row}>
              <Button startIcon={<EditOutlined />} label="Edit" onClick={() => goToEdit(record)} />
              {/* <EditButton useListStyle={true} record={record} basePath={rest.basePath} /> */}
            </div>
          )}
          sortable={false}
        />
      </Datagrid>
    </List>
  )
}

const FinanceCTAFields = (props) => {
  const financePartnerId = getFinancePartnerId()
  const validateJSON = (value) => {
    if (!value) return undefined
    try {
      JSON.parse(value)
      return undefined
    } catch (ex) {
      return 'Invalid JSON'
    }
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <TextInput source="title" validate={[required()]} fullWidth />
      </Grid>
      <Grid item xs={4}>
        <SelectInput
          source="type"
          choices={FINANCE_CTA_TYPE_CHOICES}
          fullWidth={true}
          style={{ width: '100%' }}
          optionText="label"
          validate={[required()]}
        />
      </Grid>
      <Grid item xs={4}>
        <NumberInput source="priority" fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextInput source="cta_main_text" fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextInput source="cta_secondary_text" fullWidth />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput source="is_customer_facing" fullWidth />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput source="is_pro_facing" fullWidth />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput source="has_api_side_effect" fullWidth />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput source="is_archived" fullWidth />
      </Grid>
      <DependentInput dependsOn="type" value={FinanceCtaType.PROPOSAL_TOOLBAR_DIALOG}>
        <>
          <Grid item xs={3}>
            <BooleanInput source="include_for_all_commercial" fullWidth />
          </Grid>
          <Grid item xs={3}>
            <BooleanInput source="include_for_all_residential" fullWidth />
          </Grid>
          <Grid item xs={3}>
            <BooleanInput source="include_logo" fullWidth />
          </Grid>
        </>
      </DependentInput>
      <Grid item xs={4}>
        {/* @ts-ignore */}
        <ReferenceInput
          label="Finance Partner"
          reference="finance_partners"
          source="finance_partner"
          fullWidth
          style={{ width: '100%' }}
          disabled={true}
        >
          <SelectInput optionText="display_name" optionValue="url" source="finance_partner" disabled={true} />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12}>
        <h4>Status to Mapping JSON</h4>
        <div>
          Used to map the transaction's status to a the status of the CTA. An example of this would be to say "Approved"
          transactions should result in "Completed" CTAs
        </div>
        <TextInput
          source="status_mapping_json"
          fullWidth
          validate={validateJSON}
          defaultValue={'{}'}
          multiline={true}
          rows={30}
          InputProps={{
            classes: { input: 'code-block' },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <h4>Status to CTA Description JSON</h4>
        <div>
          Used to map the transaction's status to a descriptin that appears with the CTA. An example of this would be
          showing some text below the application CTA when a customer has been denied.
        </div>
        <TextInput
          source="status_to_description_json"
          fullWidth
          validate={validateJSON}
          defaultValue={'{}'}
          multiline={true}
          rows={30}
          InputProps={{
            classes: { input: 'code-block' },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <h4>Iframe URL Configuration JSON</h4>
        <div>
          Used to dynamically create the iframe URL for a given system/payment option. Only necessary when we don't need
          to call out to the lender to create the iframe url first.
        </div>
        <TextInput
          source="iframe_url_configuration_json"
          defaultValue={'{}'}
          fullWidth
          validate={validateJSON}
          multiline={true}
          rows={30}
          InputProps={{
            classes: { input: 'code-block' },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <h4>Hosted Form JSON</h4>
        <div>Used to dynamically create the hosted credit application form URL for a given system/payment option.</div>
        <TextInput
          source="form_json"
          defaultValue={'{}'}
          fullWidth
          validate={validateJSON}
          multiline={true}
          rows={30}
          InputProps={{
            classes: { input: 'code-block' },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <h4>API Side Effect Configuration</h4>
        <div>Used to dynamically configure the API side effect (if enabled).</div>
        <TextInput
          source="api_side_effect_config_json"
          defaultValue={'{}'}
          fullWidth
          validate={validateJSON}
          multiline={true}
          rows={30}
          InputProps={{
            classes: { input: 'code-block' },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <h4>CTA Dialog Config JSON</h4>
        <div>Used to dynamically configure Dialog that opens from a CTA with a type of "Proposal Toolbar Dialog".</div>
        <TextInput
          source="cta_dialog_config_json"
          defaultValue={'{}'}
          fullWidth
          validate={validateJSON}
          multiline={true}
          rows={30}
          InputProps={{
            classes: { input: 'code-block' },
          }}
        />
      </Grid>
    </Grid>
  )
}

export const FinanceCTAEdit = (props) => {
  const financePartnerId = getFinancePartnerId()

  return (
    <Edit
      {...props}
      hasDelete={true}
      redirect={(resource, id, data) => `/finance_ctas?finance_partner_id=${financePartnerId}`}
    >
      <SimpleForm>
        <FinanceCTAFields {...props} />
      </SimpleForm>
    </Edit>
  )
}

export const FinanceCTACreate = (props) => {
  const financePartnerId = getFinancePartnerId()
  const redirect = useRedirect()
  const notify = useNotify()

  const onSuccess = (data) => {
    notify('Financing Product Created')
    redirect(`/finance_ctas?finance_partner_id=${financePartnerId}`)
  }
  return (
    <Create {...props} mutationOptions={{ onSuccess }}>
      <SimpleForm initialValues={{ finance_partner_id: financePartnerId }}>
        <FinanceCTAFields {...props} />
      </SimpleForm>
    </Create>
  )
}

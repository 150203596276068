import { FormHelperText, Grid, InputLabel } from '@material-ui/core'
import { triggerSDKTokenRefresh } from 'ducks/projectMilestones'
import Alert from 'elements/Alert'
import CustomField from 'elements/field/CustomField'
import { useCountryListsTranslated } from 'hooks/useCountryListsTranslated'
import { Button, FormControl, MenuItem, Select } from 'opensolar-ui'
import { renderInput } from 'pages/auth/common'
import { useNotify, useTranslate } from 'ra-core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { required } from 'validations'
import BlueSnapHostedField from '../commonComponents/BlueSnapHostedField'
import { THREE_DS_ENABLED_COUNTRIES } from '../constants'
import {
  BlueSnapBinCategoryType,
  BlueSnapCardDataType,
  BlueSnapCardSubType,
  BlueSnapError,
  CreditCardPaymentMethodType,
  DebitCardPaymentMethodType,
  PaymentRequestType,
} from '../types'
import { useGetBlueSnapToken } from '../utils'
import { getCCTypeError, getOpenSolarCCType, initBlueSnapCreditCardForm, submitBlueSnapCardData } from './utils'

type PropTypes = {
  projectId: string
  doSubmitPayment: (cardData?: BlueSnapCardDataType) => void
  paymentRequestData: PaymentRequestType
  countryIso2: string
  cancelEdit: () => void
}

type ErrorMapType = {
  ccn: string | undefined
  exp: string | undefined
  cvv: string | undefined
}

const useStyles = makeOpenSolarStyles((theme) => ({
  ccInput: {
    margin: 0,
    marginTop: '5px',
    width: '100%',
    backgroundColor: '#fff',
  },

  nextBtn: {
    background: '#4272DD',
    color: theme.white,
    fontSize: 13,
    border: '1px solid #4272DD',
    float: 'right',
    margin: '10px 0',

    '&:hover': {
      background: '#4272DD !important',
      color: theme.white,
      fontSize: 13,
      border: '1px solid #4272DD',
    },
  },
  cancelBtn: {
    fontSize: 13,
    margin: '10px 0',
  },
  cancelWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
  },
}))

const renderSelectField = ({
  input,
  label,
  required = true,
  meta: { touched, error },
  children,
  onChange,
  ...custom
}) => {
  return (
    <FormControl style={{ width: '100%' }} margin="dense" error={touched && error}>
      <InputLabel id="demo-simple-select-label" required={required}>
        {label}
      </InputLabel>
      <Select
        {...input}
        MenuProps={{ disableScrollLock: true }}
        onChange={(event) => {
          onChange(event.target.value, input)
        }}
        children={children}
        {...custom}
      />
      <FormHelperText>{touched && error}</FormHelperText>
    </FormControl>
  )
}

const CreditCardForm: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const form = useForm()
  const formState = useFormState()
  const translate = useTranslate()
  const firstName = formState.values?.first_name
  const lastName = formState.values?.last_name
  const email = formState.values?.email
  const phone = formState.values?.phone
  const billing_address = formState.values?.billing_address
  const billing_city = formState.values?.billing_city
  const billing_country = formState.values?.billing_country
  const zipCode = formState.values?.zip_code

  const [showAllCountries, setAllCountries] = useState(false)
  const [keepCountrySelectOpen, setKeepCountrySelectOpen] = useState(false)
  const { countriesMinimal, countriesAll, otherCountry } = useCountryListsTranslated()
  const countryList = showAllCountries === true ? [...countriesAll, otherCountry] : countriesMinimal

  const is3DSRequired = THREE_DS_ENABLED_COUNTRIES.includes(props.countryIso2)

  const isEditing = Boolean(formState.values?.saved_payment_method_type)
  const ZIP_OR_POSTAL_CODE = props.countryIso2 === 'US' ? translate('Zip Code') : translate('Postal Code')

  // having the blue snap fields live in an iframe form means we need to some kind of clunky things to figure out if the fields
  // are valid or what error message we should show. errorMap is used to track the errorCode on a field and pass that error code to the
  // child component
  const [errorMap, setErrorMap] = useState<ErrorMapType>({ ccn: undefined, exp: undefined, cvv: undefined })
  const [ccError, setCCError] = useState<string | undefined>(undefined)
  const [openSolarCCType, setOpenSolarCCType] = useState<
    CreditCardPaymentMethodType | DebitCardPaymentMethodType | undefined
  >(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  // then we separately track whether each field is valid. To be valid it most be populated
  const [ccnIsValid, setCcnIsValid] = useState<boolean>(false)
  const [expIsValid, setExpIsValid] = useState<boolean>(false)
  const [cvvIsValid, setCvvIsValid] = useState<boolean>(false)
  const [shouldTriggerTokenRefresh, setShouldTriggerTokenRefresh] = useState<boolean>(false)

  const notify = useNotify()
  const dispatch = useDispatch()
  const { token } = useGetBlueSnapToken(
    props.projectId,
    props.paymentRequestData.payment_request_id,
    props.paymentRequestData?.org_id
  )

  const onFieldValidationChange = useCallback(
    (fieldName: string, errorCode: number | undefined = undefined, errorDescription: string | undefined) => {
      if (errorDescription === 'tokenAlreadyUsed') {
        setShouldTriggerTokenRefresh(true)
      } else {
        switch (fieldName) {
          case 'ccn':
            setCcnIsValid(errorCode === undefined)
            return
          case 'exp':
            setExpIsValid(errorCode === undefined)
            return
          case 'cvv':
            setCvvIsValid(errorCode === undefined)
            return
          default:
            return
        }
      }
    },
    [errorMap]
  )

  const onCardTypeReady = (
    cardType: string,
    cardSubType: BlueSnapCardSubType,
    issuingCountry: string,
    binCategory: BlueSnapBinCategoryType
  ) => {
    const newCCType = getOpenSolarCCType(cardType, cardSubType, issuingCountry, props.countryIso2, binCategory)
    setOpenSolarCCType(newCCType)
  }

  useEffect(() => {
    if (token) {
      initBlueSnapCreditCardForm(token, onFieldValidationChange, onCardTypeReady, props.countryIso2)
      // if the token was refreshed because the current one triggered an error for already being used, setShouldTriggerTokenRefresh to false to restart
      if (shouldTriggerTokenRefresh) {
        setShouldTriggerTokenRefresh(false)
      }
    }
  }, [token])

  useEffect(() => {
    if (shouldTriggerTokenRefresh) {
      dispatch(triggerSDKTokenRefresh())
    }
  }, [shouldTriggerTokenRefresh])

  useEffect(() => {
    let newError = getCCTypeError(openSolarCCType, props.paymentRequestData.payment_methods)
    setCCError(newError)
  }, [openSolarCCType])

  const isReadyForSubmit = (cardData: BlueSnapCardDataType) => {
    return (
      firstName &&
      lastName &&
      zipCode &&
      ((email && phone && billing_address && billing_city && billing_country) || !is3DSRequired) &&
      cardData
    )
  }

  const onSuccess = useCallback(
    (cardData: BlueSnapCardDataType) => {
      setIsLoading(false)
      if (isReadyForSubmit(cardData) && token) {
        cardData.token = token
        props.doSubmitPayment(cardData)
      } else {
        notify(translate('Please fill out all required fields'))
      }
    },
    [firstName, lastName, email, phone, billing_address, billing_city, billing_country, zipCode, token, is3DSRequired]
  )

  const onBlueSnapErrorOnSubmit = (errMsgs: Array<BlueSnapError>) => {
    setIsLoading(false)
    if (errMsgs.some((errMsg) => errMsg?.errorCode === '14101')) {
      notify(translate('3D Secure authentication failed, please try a different payment method.'), 'error', {
        autoHideDuration: undefined,
      })
    } else {
      notify(translate('There was an error processing your payment. Please try again.'), 'error', {
        autoHideDuration: undefined,
      })
    }
  }

  const validateAndSubmitToBlueSnap = useCallback(() => {
    setIsLoading(true)
    if (!firstName) notify(translate('Cardholder first name is required'), 'warning')
    else if (!lastName) notify(translate('Cardholder last name is required'), 'warning')
    else if (!email && is3DSRequired) notify(translate('Cardholder Email is required'), 'warning')
    else if (!phone && is3DSRequired) notify(translate('Cardholder Phone is required'), 'warning')
    else if (!billing_address && is3DSRequired) notify(translate('Cardholder Address is required'), 'warning')
    else if (!billing_city && is3DSRequired) notify(translate('Cardholder City is required'), 'warning')
    else if (!billing_country && is3DSRequired) notify(translate('Cardholder Country is required'), 'warning')
    else if (!zipCode) notify(translate(`%{zip_or_postal} is required`, { zip_or_postal: ZIP_OR_POSTAL_CODE}), 'warning')
    else {
      submitBlueSnapCardData(
        props.paymentRequestData,
        firstName,
        lastName,
        email,
        phone,
        billing_address,
        billing_city,
        billing_country,
        zipCode,
        onSuccess,
        onBlueSnapErrorOnSubmit
      )
    }
  }, [firstName, lastName, email, phone, billing_address, billing_city, billing_country, zipCode, onSuccess])

  const disableButton = useMemo(() => {
    return (
      !ccnIsValid ||
      !expIsValid ||
      !cvvIsValid ||
      !firstName ||
      !lastName ||
      (is3DSRequired && (!email || !phone || !billing_address || !billing_city || !billing_country)) ||
      !zipCode ||
      Boolean(ccError)
    )
  }, [
    ccnIsValid,
    expIsValid,
    cvvIsValid,
    firstName,
    lastName,
    email,
    phone,
    billing_address,
    billing_city,
    billing_country,
    zipCode,
    ccError,
  ])

  return (
    <div>
      <Grid container spacing={1}>
        {ccError && (
          <Grid item xs={12}>
            <Alert severity="error">{translate(ccError)}</Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <BlueSnapHostedField
            inputType="div"
            fieldName="ccn"
            errorCode={errorMap?.ccn}
            fieldLabel={translate('Credit Card Number')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BlueSnapHostedField
            inputType="div"
            fieldName="exp"
            errorCode={errorMap?.exp}
            fieldLabel={translate('Expiration Date')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BlueSnapHostedField
            inputType="div"
            fieldName="cvv"
            errorCode={errorMap?.cvv}
            fieldLabel={translate('CVV')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <span>{translate('Cardholder First Name')}</span>
          <CustomField className={classes.ccInput} name="first_name" component={renderInput} variant="outlined" />
        </Grid>
        <Grid item xs={12} md={6}>
          <span>{translate('Cardholder Last Name')}</span>
          <CustomField className={classes.ccInput} name="last_name" component={renderInput} variant="outlined" />
        </Grid>
        {is3DSRequired && (
          <>
            <Grid item xs={12} md={6}>
              <span>{translate('Cardholder Email')}</span>
              <CustomField className={classes.ccInput} name="email" component={renderInput} variant="outlined" />
            </Grid>
            <Grid item xs={12} md={6}>
              <span>{translate('Cardholder Phone')}</span>
              <CustomField className={classes.ccInput} name="phone" component={renderInput} variant="outlined" />
            </Grid>
            <Grid item xs={12} md={6}>
              <span>{translate('Cardholder Billing Address')}</span>
              <CustomField
                className={classes.ccInput}
                name="billing_address"
                component={renderInput}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <span>{translate('Cardholder Billing City')}</span>
              <CustomField className={classes.ccInput} name="billing_city" component={renderInput} variant="outlined" />
            </Grid>
            <Grid container xs={12} sm={6}>
              <CustomField
                style={{ height: '40px' }}
                className={classes.ccInput}
                variant="outlined"
                id="billing_country"
                name="billing_country"
                validate={is3DSRequired ? required : null}
                required={is3DSRequired}
                component={renderSelectField}
                onChange={(value, input) => {
                  if (value !== 'change_view') {
                    input.onChange(value)
                    setKeepCountrySelectOpen(false)
                  }
                }}
                onClick={(e) => {
                  setKeepCountrySelectOpen(!keepCountrySelectOpen)
                  if (e.target.value === 'change_view') {
                    setAllCountries(!showAllCountries)
                    form.change('country_iso2', '')
                    setKeepCountrySelectOpen(true)
                  } else {
                    setKeepCountrySelectOpen(!keepCountrySelectOpen)
                  }
                }}
                label={translate('Country')}
                open={keepCountrySelectOpen}
              >
                <MenuItem value={''} key={0}></MenuItem>
                {countryList.map((c) => (
                  <MenuItem id={'Country' + c.iso2} value={c.iso2} key={c.iso2}>
                    {c.translatedName}
                  </MenuItem>
                ))}
                <MenuItem value={'change_view'} id={'change_view'}>
                  {showAllCountries ? translate('Show less...') : translate('Show more...')}
                </MenuItem>
              </CustomField>
            </Grid>
          </>
        )}
        <Grid item xs={12} md={6}>
          <span>{translate(ZIP_OR_POSTAL_CODE)}</span>
          <CustomField className={classes.ccInput} name="zip_code" component={renderInput} variant="outlined" />
        </Grid>
        {props.paymentRequestData.surcharging_enabled && (
          <Grid item xs={12}>
            <div className="small">
              {translate(
                'Please note: A credit card processing fee may apply to your payment. Click next to view your final payment amount for this card.'
              )}
            </div>
          </Grid>
        )}
        <Grid item xs={12}>
          <div>
            {isEditing && (
              <Button onClick={props.cancelEdit} variant="contained" color="default" className={classes.cancelBtn}>
                {translate('Cancel')}
              </Button>
            )}
            <Button
              onClick={validateAndSubmitToBlueSnap}
              variant="contained"
              disabled={disableButton || isLoading}
              className={classes.nextBtn}
            >
              {translate('Next')}
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
export default CreditCardForm

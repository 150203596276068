import BannerAd from 'elements/BannerAd'

export const TripleWidescreenAd = () => {
  return (
    <BannerAd
      formats={['triple_widescreen']}
      placement="project_manage"
      requiredWindowSize={{ width: 960 }}
      rounded={true}
    />
  )
}

import { useMemo } from 'react'
import useActivityData from '../activity/hooks/useActivityData'
import type { ActivityDataType } from '../activity/type'
import useForecastProjectInventory from '../forecast/hooks/useForecastProjectInventory'
import type { ForecastedProjectInventory } from '../forecast/type'
import useInventoryData from '../inventory/hooks/useInventoryData'
import type { InventoryListData } from '../inventory/type'
import InventoryForecastPresenter from './InventoryForecastPresenter'
import useDashboardStatistics from './hooks/useDashboardStatistics'
import type { DashboardStatisticsData } from './type'

type InventoryDashboardPresenter = {
  forecastingProjectInventory: boolean
  loadingInventoryData: boolean
  loadingDashboardStatistics: boolean
  loadingActivityData: boolean
  activityData: ActivityDataType | undefined
  dashboardStatistics: DashboardStatisticsData | undefined
  forecastedProjectInventoryData: ForecastedProjectInventory[] | undefined
  inventoryData: InventoryListData | undefined
  presenter: InventoryForecastPresenter
}

const useDashboardPresenter = (): InventoryDashboardPresenter => {
  const {
    forecastedProjectInventoryData,
    forecastingProjectInventory,
    forecastProjectInventory,
  } = useForecastProjectInventory()
  const { activityData, loadingActivityData, loadActivityData } = useActivityData()
  const { dashboardStatistics, loadingDashboardStatistics, loadDashboardStatistics } = useDashboardStatistics()
  const { inventoryData, loadingInventoryData, loadInventoryData } = useInventoryData()
  const presenter = useMemo(
    () =>
      new InventoryForecastPresenter({
        forecastProjectInventory,
        loadInventoryData,
        loadDashboardStatistics,
        loadActivityData,
      }),
    []
  ) // important that above method won't change

  return {
    forecastedProjectInventoryData,
    inventoryData,
    activityData,
    loadingActivityData,
    dashboardStatistics,
    loadingDashboardStatistics,
    forecastingProjectInventory,
    loadingInventoryData,
    presenter,
  }
}

export default useDashboardPresenter

import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type PropTypes = {
  text: string
}

const useStyles = makeOpenSolarStyles((theme) => ({
  main: {
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '3px 6px',
    backgroundColor: theme.greyLight2,
  },
  text: {
    fontSize: '10px',
    fontWeight: 400,
  },
}))

/**
 * Used as part of the CashFlow onboarding flow, not clear if this is going to be used elsewhere though. Arthur has referred to this as a badge
 * but it functions a bit differently than the standard MUI badge
 * @param  {String} text  The text that you'd like to display in the badge
 * @return {Element}
 */
const Badge: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.main}>
      <div className={classes.text}>{props.text}</div>
    </div>
  )
}

export default Badge

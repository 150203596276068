import { useMediaQuery } from '@material-ui/core'
import { useTranslate } from 'ra-core'
import React, { CSSProperties, FC } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { Theme } from 'types/themes'

const styles = {
  large: {
    prefix: {
      fontSize: '14px',
      top: 16,
      marginRight: 2,
    },
    suffix: {
      fontSize: '14px',
    },
    font: {
      fontSize: 30,
      fontWeight: 300,
      whiteSpace: 'nowrap',
    },
    label: {
      wordBreak: 'break-word',
      display: 'inline-block',
      maxWidth: '150px',
      fontSize: 14,
    },
  },
  small: {
    prefix: {
      fontSize: '12px',
      top: 11,
      marginRight: 2,
    },
    suffix: {
      fontSize: '12px',
    },
    font: {
      fontSize: 24,
      fontWeight: 300,
      whiteSpace: 'nowrap',
    },
    label: {
      wordBreak: 'break-word',
      display: 'inline-block',
      maxWidth: '100px',
      fontSize: 10,
    },
  },
  displayTwoRows: {
    mobile: {
      flexContainer: {
        textAlign: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        paddingTop: '10px',
        height: '130px',
      },
    },
    standard: {
      flexContainer: {
        textAlign: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        paddingTop: '20px',
        height: '150px',
      },
    },
    flexItem: {
      flexGrow: '1',
      width: '50%',
      alignSelf: 'flex-start',
      boxSizing: 'border-box',
    },
    contentWrapper: {
      padding: 10,
    },
    prefix: {
      fontSize: '15px',
      top: 18,
      marginRight: 2,
    },
    suffix: {
      fontSize: '15px',
    },
  },
  displayOneRow: {
    mobile: {
      flexContainer: {
        textAlign: 'center',
        display: 'flex',
        flexWrap: 'nowrap',
        paddingTop: '20px',
        height: '130px',
      },
    },
    standard: {
      flexContainer: {
        textAlign: 'center',
        display: 'flex',
        flexWrap: 'nowrap',
        paddingTop: '20px',
        maxHeight: 90,
      },
    },
    flexItem: {
      flexGrow: 1,
      flexBasis: 0,
      alignSelf: 'flex-start',
      flexDirection: 'row',
      flex: 1,
      height: 'auto',
      justifyContent: 'center',
      display: 'flex',
      overflow: 'visible',
    },
    contentWrapper: {
      padding: 10,
      overflow: 'visible',
    },
    prefix: {
      fontSize: '15px',
      top: 18,
      marginRight: 2,
    },
    suffix: {
      fontSize: '15px',
    },
  },
}

const getHighlightFeatures = (proposalTemplateSettings) => {
  return proposalTemplateSettings.highlight_rows && proposalTemplateSettings.highlight_rows.length > 0
    ? proposalTemplateSettings.highlight_rows.split(',')
    : []
}

type FeatureFigureData = {
  label: string
  prefix?: string
  figure?: string
  suffix?: string
  label_notes?: string
  color?: string
  figure2?: string
}

const useStyles = makeOpenSolarStyles(
  (theme) => ({
    figureCont: {
      display: 'flex',
      alignItems: 'end',
      justifyContent: 'center',
      gap: 4,
    },
    suffixCont: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      paddingBottom: 4,
    },
    figure2: {
      marginTop: -3,
      fontSize: '0.85em',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.65em',
      },
    },
  }),
  { name: 'FeatureFigures' }
)

const FeatureFigure: FC<{
  id: string
  row: FeatureFigureData
  style: Record<string, CSSProperties>
  fontStyle: Record<string, CSSProperties>
}> = ({ id, row, style, fontStyle }) => {
  const translate = useTranslate()
  const isMedium = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))
  const classes = useStyles()
  return (
    <div className="feature-figure-cell" style={style.flexItem}>
      <div style={style.contentWrapper}>
        <div className={classes.figureCont}>
          <span style={fontStyle.font} className={'feature-figure' + (row.color ? ' ' + row.color : '')}>
            {row.prefix && <span style={fontStyle.prefix}>{row.prefix}</span>}
            {row.figure === '...' ? (
              <div className="loading-feature-figure" style={{ margin: isMedium ? '14px auto' : '10px auto' }} />
            ) : (
              <span>{row.figure}</span>
            )}
          </span>
          {(row.suffix || row.figure2) && (
            <div className={classes.suffixCont}>
              {row.suffix && <span style={fontStyle.suffix}>{row.suffix}</span>}
              {row.figure2 && <span className={classes.figure2}>{row.figure2}</span>}
            </div>
          )}
        </div>
        <div>
          <span style={fontStyle.label} className={row.color}>
            {translate(row.label)}
          </span>
          {row.label_notes && (
            <span style={fontStyle.label} className={row.color}>
              {row.label_notes}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export function FeatureFigures(props) {
  const classes = useStyles()
  const { proposalData, isMobile } = props
  const isMedium = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))
  const arrayOfHighlightFeatures = getHighlightFeatures(proposalData.proposalTemplateSettings).slice(0, 4)
  const highlightRows = arrayOfHighlightFeatures.map(
    (type) => proposalData.selectedPaymentOption.featured_figures[type]
  )
  const displayTwoRows = highlightRows.length > 3 && isMobile
  //@ts-ignore
  const style: Record<string, CSSProperties> = displayTwoRows ? styles.displayTwoRows : styles.displayOneRow
  //@ts-ignore
  const flexContainerStyle: CSSProperties = isMobile ? style.mobile.flexContainer : style.standard.flexContainer
  //@ts-ignore
  const fontStyle: Record<string, CSSProperties> = isMedium ? styles.large : styles.small

  return (
    <div className="feature-figure-container" style={flexContainerStyle}>
      {highlightRows.map((row, index) =>
        row && row.hasOwnProperty('figure') ? (
          <FeatureFigure
            key={index + arrayOfHighlightFeatures[index]}
            row={row}
            id={arrayOfHighlightFeatures[index]}
            style={style}
            fontStyle={fontStyle}
          />
        ) : (
          [row]
        )
      )}
    </div>
  )
}

export default FeatureFigures

import { Grid, makeStyles } from '@material-ui/core'
import { DeleteOutline } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/AddOutlined'
import { IconButton } from 'opensolar-ui'
import { BooleanInput, NumberInput, SelectInput } from 'ra-ui-materialui'
import React from 'react'
import { PaymentFactorType } from 'types/paymentOptions'

type PropTypes = {
  factor: PaymentFactorType
  addRow: () => void
  removeRow: (indexToRemove: number) => void
  index: number
  variableRoot: string
  disabled: boolean
}

const useStyles = makeStyles(() => ({
  buttonRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '100%',
  },
}))

// NOTE: not supporting no interest period becaus the back-end just forces this value to be 0 in payment_options.py
export const QUOTATION_TABLE_LINE_CHOICES = [
  {
    id: null,
    name: 'None',
  },
  {
    id: 'regular_payment',
    name: 'Initial Loan Payment',
  },
  {
    id: 'loan_repayments_final',
    name: 'Final Loan Payment',
  },
  {
    id: 'no_payment_period_amount',
    name: 'No Payment Period',
  },
  {
    id: 'interest_only_period_amount',
    name: 'Interest Only',
  },
]

const PaymentFactorRow: React.FC<PropTypes> = (props) => {
  const classes = useStyles()

  return (
    <Grid container spacing={1}>
      <Grid item xs={2}>
        <NumberInput
          source={`${props.variableRoot}.${props.index}.start_period`}
          name={`${props.variableRoot}.${props.index}.start_period`}
          label={`First Effective Period`}
          disabled={props.disabled}
          fullWidth={true}
        />
      </Grid>
      <Grid item xs={2}>
        <NumberInput
          source={`${props.variableRoot}.${props.index}.end_period`}
          name={`${props.variableRoot}.${props.index}.end_period`}
          label={`Last Effective Period`}
          disabled={props.disabled}
          fullWidth={true}
        />
      </Grid>
      <Grid item xs={2}>
        <NumberInput
          source={`${props.variableRoot}.${props.index}.value`}
          name={`${props.variableRoot}.${props.index}.value`}
          label={`Payment Factor`}
          disabled={props.disabled}
          fullWidth={true}
        />
      </Grid>
      <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
        <BooleanInput
          source={`${props.variableRoot}.${props.index}.is_display_only`}
          name={`${props.variableRoot}.${props.index}.is_display_only`}
          label={`Is For Display Only`}
          disabled={props.disabled}
          fullWidth={true}
        />
      </Grid>
      <Grid item xs={2}>
        <SelectInput
          source={`${props.variableRoot}.${props.index}.quotation_table_field`}
          name={`${props.variableRoot}.${props.index}.quotation_table_field`}
          label={`Quotation Table Field`}
          disabled={props.disabled}
          fullWidth={true}
          choices={QUOTATION_TABLE_LINE_CHOICES}
        />
      </Grid>
      <Grid item xs={2}>
        <div className={classes.buttonRow}>
          <IconButton onClick={() => props.removeRow(props.index)}>
            <DeleteOutline />
          </IconButton>
          <IconButton onClick={props.addRow}>
            <AddIcon />
          </IconButton>
        </div>
      </Grid>
    </Grid>
  )
}
export default PaymentFactorRow

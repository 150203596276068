import { useMemo } from 'react'
import restClient from 'restClient'
import PreOrderService from '../services/PreOrderService'

const restClientInstance = restClient(window.API_ROOT + '/api')
const usePreOrderService = (): PreOrderService | undefined => {
  const service = useMemo(() => {
    return new PreOrderService(restClientInstance)
  }, [])
  return service
}

export default usePreOrderService

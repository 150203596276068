import { orgSelectors } from 'ducks/orgs'
import { getSupplierConfig, HARDWARE_SUPPLIER_CONFIGS } from 'pages/ordering/configs'
import type LineItemType from 'pages/ordering/OrderLineItem'
import OrderLineItem from 'pages/ordering/OrderLineItem'
import ProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/projectOrderPresenter'
import type {
  HardwareSupplierEnum,
  HardwareSupplierFilterKeyType,
  LineItemLoadedDataType,
  ProjectOrderDetail,
} from 'pages/ordering/type'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import appStorage from 'storage/appStorage'
import { ReduxActionType } from 'types/global'
import { RootState } from 'types/state'
import { authSelectors } from './auth'

const OPEN_ORDER_CART = 'OPEN_ORDER_CART'
const UPDATE_LOADED_PRODUCT_DATA = 'UPDATE_LOADED_PRODUCT_DATA'
const ADD_LINE_ITEMS = 'ADD_LINE_ITEMS'
const DELETE_LINE_ITEMS = 'DELETE_LINE_ITEMS'
const UPDATE_LINE_ITEM = 'UPDATE_LINE_ITEM'
const CLEAR_LINE_ITEMS = 'CLEAR_LINE_ITEMS'
const CONFIRM_LINE_ITEMS = 'CONFIRM_LINE_ITEMS'
const DELETE_LINE_ITEMS_BY_SYSTEM_ID = 'DELETE_LINE_ITEMS_BY_SYSTEM_ID'
const UPDATE_SELECTED_HARDWARE_SUPPLIER = 'CHANGE_HARDWARE_SUPPLIER'
const UPDATE_IS_CUSTOM_PRICING = 'CHANGE_IS_CUSTOM_PRICING'
const UPDATE_LINE_ITEM_SUPPLIER = 'UPDATE_LINE_ITEM_SUPPLIER'
const UPDATE_LINE_ITEM_BY_CODE = 'UPDATE_LINE_ITEM_BY_CODE'

type LineItemIdentifiableKeys = 'uuid' | 'code' | 'orgId' | 'componentType' | 'status' | 'variantId' | 'confirmed'

export const SHOPPING_CART_STORAGE_KEY = 'shopping_cart_line_items'

export function persistCart(lineItems: OrderLineItem[]) {
  appStorage.setJSON(SHOPPING_CART_STORAGE_KEY, lineItems)
}

export function clearPersistedCart() {
  appStorage.setJSON(SHOPPING_CART_STORAGE_KEY, undefined)
}

export type OrderComponentsType = {
  openDialog: boolean
  lineItems: LineItemType[]
  selectedHardwareSupplier: HardwareSupplierEnum | undefined
  usingCustomPricing: boolean
}

const getInitialState = (): OrderComponentsType => {
  const initialHardwareSupplier = appStorage.getString('selected_hardware_supplier')
  const supplierConfig = HARDWARE_SUPPLIER_CONFIGS.find((c) => c.filterKey === initialHardwareSupplier)
  const lineItemsRaw = appStorage.getJSON<OrderLineItem[]>(SHOPPING_CART_STORAGE_KEY) as OrderLineItem[]

  let lineItems = lineItemsRaw
    ? lineItemsRaw.map((li) => {
        return new OrderLineItem({ ...li })
      })
    : []

  return {
    openDialog: false,
    lineItems: lineItems,
    selectedHardwareSupplier: supplierConfig?.type || undefined,
    usingCustomPricing: false,
  }
}

type UpdateLineItemAction<T extends keyof LineItemType> = {
  type: typeof UPDATE_LINE_ITEM
  payload: {
    uuid: string
    source: T
    value: LineItemType[T]
  }
}

type UpdateLineItemActionByCode<T extends keyof LineItemType> = {
  type: typeof UPDATE_LINE_ITEM_BY_CODE
  payload: {
    code: string
    source: T
    value: LineItemType[T]
  }
}

export const openOrderCart = (open: boolean) => ({
  type: OPEN_ORDER_CART,
  payload: open,
})

export const addOrderLineItem = (lineItem: LineItemType) => {
  return addOrderLineItems([lineItem])
}

export const addOrderLineItems = (lineItems: LineItemType[]) => ({
  type: ADD_LINE_ITEMS,
  payload: {
    lineItems,
  },
})

export const clearLineItems = () => ({
  type: CLEAR_LINE_ITEMS,
  payload: {},
})

export const deleteUnconfirmedLineItems = () => {
  return deleteLineItems<'confirmed'>({ source: 'confirmed', identifiers: [false] })
}

export const deleteOrderByVariantId = (variantId: string) => {
  return deleteLineItems<'variantId'>({ source: 'variantId', identifiers: [variantId] })
}

export const deleteOrderByCode = (code: string) => {
  return deleteLineItems<'code'>({ source: 'code', identifiers: [code] })
}

export const deleteOrderBySystemId = ({ systemId, confirmed }: { systemId: number; confirmed: boolean }) => {
  return {
    type: DELETE_LINE_ITEMS_BY_SYSTEM_ID,
    payload: { systemId, confirmed },
  }
}

export const deleteLineItems = <T extends LineItemIdentifiableKeys>({
  source,
  identifiers,
}: {
  source: T
  identifiers: LineItemType[T][]
}) => ({
  type: DELETE_LINE_ITEMS,
  payload: { source, identifiers },
})

export const confirmLineItems = ({ uuids, confirmed }: { uuids: string[]; confirmed: boolean }) => ({
  type: CONFIRM_LINE_ITEMS,
  payload: { uuids, confirmed },
})

export const updateOrderQuantity = ({ uuid, quantity }: { uuid: string; quantity: number }) => {
  return updateLineItem<'quantity'>({
    uuid,
    source: 'quantity',
    value: quantity,
  })
}

export const updateOrderQuantityByCode = ({ code, quantity }: { code: string; quantity: number }) => {
  return updateLineItemByCode<'quantity'>({
    code,
    source: 'quantity',
    value: quantity,
  })
}

export const updateLineItem = <T extends keyof LineItemType>({
  uuid,
  source,
  value,
}: {
  uuid: string
  source: T
  value: LineItemType[T]
}): UpdateLineItemAction<T> => ({
  type: UPDATE_LINE_ITEM,
  payload: {
    uuid,
    source,
    value,
  },
})

export const updateLineItemByCode = <T extends keyof LineItemType>({
  code,
  source,
  value,
}: {
  code: string
  source: T
  value: LineItemType[T]
}): UpdateLineItemActionByCode<T> => ({
  type: UPDATE_LINE_ITEM_BY_CODE,
  payload: {
    code,
    source,
    value,
  },
})

export const updateLoadedProductData = (loadedData: LineItemLoadedDataType[], requestLineItems: LineItemType[]) => ({
  type: UPDATE_LOADED_PRODUCT_DATA,
  payload: { loadedData, requestLineItems },
})

export const updateSelectedHardwareSupplier = (selectedHardwareSupplier: HardwareSupplierEnum | undefined) => ({
  type: UPDATE_SELECTED_HARDWARE_SUPPLIER,
  payload: { selectedHardwareSupplier },
})

export const updateIsCustomPricing = (usingCustomPricing: boolean) => ({
  type: UPDATE_IS_CUSTOM_PRICING,
  payload: usingCustomPricing,
})

export const updateLineItemSupplier = ({
  data,
  code,
  supplier,
}: {
  data: LineItemLoadedDataType
  code: string
  supplier: HardwareSupplierFilterKeyType
}) => ({
  type: UPDATE_LINE_ITEM_SUPPLIER,
  payload: { data, code, supplier },
})

export default function reducer(previousState: OrderComponentsType = getInitialState(), { type, payload }) {
  if (type === OPEN_ORDER_CART) {
    return {
      ...previousState,
      openDialog: payload,
    }
  }
  if (type === UPDATE_IS_CUSTOM_PRICING) {
    return {
      ...previousState,
      usingCustomPricing: payload,
    }
  }
  if (type === ADD_LINE_ITEMS) {
    const lineItemsToAdd = payload.lineItems
    const currentLineItems = previousState.lineItems
    const newLineItems = ProjectOrderPresenter.aggregateLineItems([...currentLineItems, ...lineItemsToAdd])
    persistCart(newLineItems)

    return {
      ...previousState,
      lineItems: newLineItems,
    }
  }

  if (type === DELETE_LINE_ITEMS_BY_SYSTEM_ID) {
    const { systemId, confirmed } = payload
    const currentLineItems = previousState.lineItems
    const newLineItems: LineItemType[] = []
    currentLineItems.forEach((lineItem: LineItemType) => {
      if (lineItem.confirmed !== confirmed) {
        newLineItems.push(lineItem)
      } else if (!lineItem.projectOrder) {
        newLineItems.push(lineItem)
      } else {
        let newQuantity = lineItem.quantity

        const newProjectOrder = lineItem.projectOrder.filter((detail: ProjectOrderDetail) => {
          if (detail.systemId !== systemId) {
            return true
          } else {
            if (lineItem.quantityPerItem != null) {
              const deleteProjectRequiredQuantity = Math.ceil(detail.requiredQuantity / lineItem.quantityPerItem)
              newQuantity -= deleteProjectRequiredQuantity
            }
            return false
          }
        })

        // delete lineItem if there is no associate projects and unallocated stock
        if (newProjectOrder.length === 0 && newQuantity <= 0) return
        newLineItems.push(
          new OrderLineItem({
            ...lineItem,
            projectOrder: newProjectOrder,
            quantity: newQuantity,
          })
        )
      }
    })
    persistCart(newLineItems)
    return {
      ...previousState,
      lineItems: newLineItems,
    }
  }

  if (type === DELETE_LINE_ITEMS) {
    const { source, identifiers } = payload
    const currentLineItems = previousState.lineItems
    const newLineItems: LineItemType[] = []
    currentLineItems.forEach((lineItem: LineItemType) => {
      // can this trigger re-render??
      if (!identifiers.includes(lineItem[source])) {
        newLineItems.push(lineItem)
      }
    })
    persistCart(newLineItems)
    return {
      ...previousState,
      lineItems: newLineItems,
    }
  }

  if (type === CLEAR_LINE_ITEMS) {
    clearPersistedCart()
    return {
      ...previousState,
      lineItems: [],
    }
  }

  if (type === UPDATE_LINE_ITEM) {
    const { uuid, source, value } = payload
    const currentLineItems = previousState.lineItems
    const linItemToUpdate: LineItemType | undefined = currentLineItems.find(
      (lineItem: LineItemType) => lineItem.uuid === uuid
    )
    if (linItemToUpdate) {
      linItemToUpdate[source] = value
    }

    return {
      ...previousState,
      lineItems: ProjectOrderPresenter.aggregateLineItems([...currentLineItems]),
    }
  }

  if (type === CONFIRM_LINE_ITEMS) {
    const { uuids, confirmed } = payload
    const currentLineItems = previousState.lineItems
    uuids.forEach((uuid: string) => {
      const linItemToUpdate: LineItemType | undefined = currentLineItems.find(
        (lineItem: LineItemType) => lineItem.uuid === uuid
      )
      if (linItemToUpdate) {
        linItemToUpdate['confirmed'] = confirmed
      }
    })
    persistCart(currentLineItems)
    return {
      ...previousState,
      lineItems: [...currentLineItems],
    }
  }

  if (type === UPDATE_LOADED_PRODUCT_DATA) {
    const { loadedData, requestLineItems } = payload
    const lineItems = previousState.lineItems
    lineItems.forEach((lineItem: LineItemType, index: number) => {
      const requestLineItem: LineItemLoadedDataType = requestLineItems.find(
        (requestItem: LineItemType) => requestItem.code === lineItem.code
      )

      const matchedData: LineItemLoadedDataType = loadedData.find(
        (data: LineItemLoadedDataType) => data.code === lineItem.code
      )
      if (requestLineItem == null) {
        return
      }

      lineItems[index] = ProjectOrderPresenter.getLineItemByLoadedData(lineItem, matchedData)
    })
    const newLineItems = ProjectOrderPresenter.aggregateLineItems(lineItems)

    return {
      ...previousState,
      lineItems: newLineItems,
    }
  }

  if (type === UPDATE_SELECTED_HARDWARE_SUPPLIER) {
    const supplierConfig = getSupplierConfig(payload.selectedHardwareSupplier)
    if (supplierConfig) {
      appStorage.setString('selected_hardware_supplier', supplierConfig.filterKey)
    } else {
      appStorage.clear('selected_hardware_supplier')
    }
    clearPersistedCart()
    return {
      ...previousState,
      selectedHardwareSupplier: payload.selectedHardwareSupplier,
    }
  }

  if (type === UPDATE_LINE_ITEM_SUPPLIER) {
    const { data, code, supplier } = payload
    const currentLineItems = previousState.lineItems
    const lineItemToUpdate: LineItemType | undefined = currentLineItems.find(
      (lineItem: LineItemType) => lineItem.code === code
    )

    if (lineItemToUpdate) {
      lineItemToUpdate['selectedDistributor'] = supplier
      lineItemToUpdate['stockLevels'] = data?.stockLevels || []
      lineItemToUpdate['data'] = {
        ...data,
        description: data?.description,
        short_description: data?.short_description || '',
        distributors: data ? [data] : [],
        manufacturer_name: data?.manufacturer,
        title: data?.title || '',
        image_url: data?.image_url,
      }
      lineItemToUpdate['selectedDistributorOrderingData'] = {
        ...data,
        description: data?.description,
        short_description: data?.short_description || '',
        distributors: data ? [data] : [],
        manufacturer_name: data?.manufacturer,
        title: data?.title || '',
        image_url: data?.image_url,
      }
    }

    return {
      ...previousState,
      lineItems: ProjectOrderPresenter.aggregateLineItems([...currentLineItems]),
    }
  }

  if (type === UPDATE_LINE_ITEM_BY_CODE) {
    const { code, source, value } = payload
    const currentLineItems = previousState.lineItems
    const linItemToUpdate: LineItemType | undefined = currentLineItems.find(
      (lineItem: LineItemType) => lineItem.code === code
    )
    if (linItemToUpdate) {
      linItemToUpdate[source] = value
    }

    return {
      ...previousState,
      lineItems: ProjectOrderPresenter.aggregateLineItems([...currentLineItems]),
    }
  }

  return previousState
}

export function* injectPriceAndStock({ payload }: ReduxActionType) {
  const orgId = yield select(authSelectors.getOrgId)
  // Abort if data already loaded
  // TODO: refactor the distributor data as resource data
  if (payload.lineItems.every((lineItem: LineItemType) => lineItem.status === 'loaded')) {
    return
  }
  const selectedSupplier = yield select(orgSelectors.getSelectedHardwareSupplier)
  const supplierFilterKey = getSupplierConfig(selectedSupplier)?.filterKey
  const res: LineItemLoadedDataType[] = yield call(ProjectOrderPresenter.getPriceAndStock, {
    orgId,
    lineItems: payload.lineItems,
    supplierFilterKey,
  })
  if (res) {
    yield put({ type: UPDATE_LOADED_PRODUCT_DATA, payload: { loadedData: res, requestLineItems: payload.lineItems } })
  }
}

export function* orderLineItemSagas() {
  yield all([takeEvery(ADD_LINE_ITEMS, injectPriceAndStock)])
}

export const orderSelectors = {
  getOrderLineItems: (state: RootState): LineItemType[] => {
    // Ignoring ordering across different orgs
    return state.orderComponents.lineItems
  },

  getConfirmedLineItems: (state: RootState): LineItemType[] => {
    // Ignoring ordering across different orgs
    return state.orderComponents.lineItems.filter((item) => item.confirmed)
  },

  getUnconfirmedLineItems: (state: RootState): LineItemType[] => {
    // Ignoring ordering across different orgs
    return state.orderComponents.lineItems.filter((item) => !item.confirmed)
  },

  getIsEmptyOrder: (state: RootState): boolean => {
    // Ignoring ordering across different orgs
    return state.orderComponents.lineItems.length === 0
  },

  getProjectListByLineItems: (lineItems: LineItemType[]): number[] => {
    const projectList = new Set<number>()
    lineItems.forEach((lineItem: LineItemType) => {
      const projectOrder = lineItem.projectOrder || []
      projectOrder.forEach((order: ProjectOrderDetail) => {
        projectList.add(order.projectId)
      })
    })
    return Array.from(projectList)
  },

  getOrderProjectList: (state: RootState): number[] => {
    return orderSelectors.getProjectListByLineItems(state.orderComponents.lineItems)
  },

  getOrderProjectIds: (state: RootState): number[] => {
    // TODO: return same array object, if lineItem is empty
    const projectIds: number[] = []
    state.orderComponents.lineItems.forEach((lineItem: LineItemType) => {
      const projectOrderIds = lineItem.projectOrder?.map((detail) => detail.projectId) || []
      projectIds.push(...projectOrderIds)
    })
    return Array.from(new Set(projectIds))
  },

  getUnconfirmedOrderProjectIds: (state: RootState): number[] => {
    const projectIds: number[] = []
    state.orderComponents.lineItems.forEach((lineItem: LineItemType) => {
      if (lineItem.confirmed) {
        return
      }
      const projectOrderIds = lineItem.projectOrder?.map((detail) => detail.projectId) || []
      projectIds.push(...projectOrderIds)
    })
    return Array.from(new Set(projectIds))
  },

  getConfirmedOrderProjectIds: (state: RootState): number[] => {
    // TODO: return same array object, if lineItem is empty
    const projectIds: number[] = []
    orderSelectors.getConfirmedLineItems(state).forEach((lineItem: LineItemType) => {
      const projectOrderIds = lineItem.projectOrder?.map((detail) => detail.projectId) || []
      projectIds.push(...projectOrderIds)
    })
    return Array.from(new Set(projectIds))
  },

  getLineItemsBySystemId: (systemId: number) => (state: RootState): LineItemType[] => {
    return state.orderComponents.lineItems.filter((lineItem) => {
      return lineItem.projectOrder?.some((a) => a.systemId === systemId)
    })
  },

  getLineItemsByCode: (code: string) => (state: RootState): LineItemType[] => {
    return state.orderComponents.lineItems.filter((lineItem) => {
      return lineItem.code === code
    })
  },

  getOrderableLineItems: (state: RootState): LineItemType[] => {
    const orderableLineItems = state.orderComponents.lineItems.filter((lineItem: LineItemType) => {
      if (!lineItem.confirmed) {
        return false
      }
      if (lineItem.status === 'loading') {
        return false
      }

      return ProjectOrderPresenter.getStockLevelStatusFromLineItem(lineItem) !== 'not_available'
    })
    return orderableLineItems
  },

  getIsCustomPricing: (state: RootState): boolean => state.orderComponents.usingCustomPricing,
}

import { authSelectors } from 'ducks/auth'
import { Button, CopyLeftOutlineIcon } from 'opensolar-ui'
import LoadingDialog from 'projectSections/sections/manage3/common/LoadingDialog'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import { FC, useState } from 'react'
import { useNotify, useRefresh, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import restClient from 'restClient'

type PropTypes = {
  projectId: number
}

export const ProjectDuplicateButton: FC<PropTypes> = ({ projectId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const notify = useNotify()
  const translate = useTranslate()
  const orgId = useSelector(authSelectors.getOrgId)
  const refresh = useRefresh()

  const sendDuplicateRequest = () => {
    const restClientInstance = restClient(window.API_ROOT + '/api')
    const url = 'orgs/' + orgId + '/projects/' + projectId + '/duplicate/'
    setIsLoading(true)
    restClientInstance('CUSTOM_POST', 'custom', {
      url,
    })
      .then((content) => {
        setIsLoading(false)
        refresh()
        window.open(`${window.location.origin}/#/projects/${content.data.project_id}/manage`, '_blank')
      })
      .catch(() => {
        setIsLoading(false)
        notify('Project duplication failed', 'warning')
      })
  }

  return (
    <>
      <Button
        variant="text"
        color="secondary"
        startIcon={<CopyLeftOutlineIcon color={COLOR_PALETTE.iconColor} />}
        onClick={sendDuplicateRequest}
      >
        {translate('Duplicate Project')}
      </Button>
      <LoadingDialog displayText={translate('Duplicating project...')} open={isLoading} />
    </>
  )
}

import { makeStyles } from '@material-ui/core/styles'
import Chartist from 'chartist'
import { memo } from 'react'
import ChartistGraph from 'react-chartist'
import Skeleton from 'react-loading-skeleton'
import useTranslateWithVariable from '../../hooks/useTranslateWithVariable'

const MonthLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export function customRound(value) {
  if (value > 100) {
    return Math.round(value).toLocaleString()
  } else {
    return (Math.round(10 * value) / 10).toLocaleString()
  }
}

function ctBarLabels(options) {
  return function ctBarLabels(chart) {
    var defaultOptions = {
      labelClass: 'ct-bar-label-small',
      labelInterpolationFnc: Chartist.noop,
      labelOffset: {
        x: 0,
        y: 8,
      },
      position: {
        x: null,
        y: null,
      },
      textAnchor: 'middle',
    }

    options = Chartist.extend({}, defaultOptions, options)

    var positionX =
      options.position.x ||
      function (data) {
        return (data.x1 + data.x2) / 2 + options.labelOffset.x
      }

    var positionY =
      options.position.y ||
      function (data) {
        return data.y2 - options.labelOffset.y
      }

    if (chart instanceof Chartist.Bar) {
      chart.on('draw', function (data) {
        if (data.type === 'bar') {
          data.element.attr({
            data_by_month:
              data.index + ',' + data.series.data[data.index] + ',' + options.energyConsumptionData[data.index],
          })
          data.element.attr({ id: data.index + '-energy-generation-bar' })
          data.group
            .elem(
              'text',
              {
                // This gets the middle point of the bars and then adds the
                // optional offset to them
                x: positionX(data),
                y: positionY(data),
                style: 'text-anchor: middle',
              },
              options.labelClass
            )
            // Add bar label with series name if !== 0
            .text(Math.round(data.value.y) !== 0 ? customRound(data.value.y) : '')
        }
      })
    }
  }
}

const useStyles = makeStyles(
  (theme) => ({
    chart: {
      '& .ct-bar': {
        stroke: 'url(#gradient-line)',
        strokeWidth: '40px',
        [theme.breakpoints.down('sm')]: {
          strokeWidth: '20px',
        },
      },
      '& .ct-label': {
        color: '#000000',
      },
    },
  }),
  { name: 'MonthlyUsageChart' }
)

const MonthlyUsageChart = ({ usageData, loading }) => {
  const classes = useStyles()
  const translate = useTranslateWithVariable()

  if (!usageData?.normalized?.monthly) {
    return null
  }

  return (
    <div>
      <hr style={{ marginTop: 20 }} className="light" />
      <svg xmlns="http://www.w3.org/2000/svg" version="1" width="0" height="0">
        <defs>
          <linearGradient id="gradient-line" x2="0" y2="200" gradientUnits="userSpaceOnUse">
            <stop style={{ stopColor: '#3AB8FF' }} offset="30%" />
            <stop style={{ stopColor: '#9D9D9D' }} offset="100%" />
          </linearGradient>
        </defs>
      </svg>
      {loading ? (
        <div style={{ padding: 20 }}>
          <Skeleton height={'250px'} />
        </div>
      ) : (
        <>
          <div style={{ textAlign: 'center', marginLeft: 20, marginBottom: 20 }}>
            {translate('Annual Usage: %{kwh} kWh', {
              kwh: loading ? '...' : customRound(usageData?.normalized?.annual || 0),
            })}
          </div>
          <div style={{ fontSize: 10, marginLeft: 30 }} className="small">
            kWh
          </div>
          <div style={{ height: 250 }}>
            <ChartistGraph
              className={classes.chart}
              style={{
                marginTop: 0,
                marginLeft: 0,
                marginBottom: 0,
                marginRight: 0,
                width: '100%',
                height: '250px',
              }}
              data={{
                labels: MonthLabels.map((month) => translate(month)),
                series: [
                  {
                    name: translate('Monthly Usage'),
                    className: 'MonthlyUsage',
                    data: usageData?.normalized?.monthly,
                  },
                ],
              }}
              options={{
                axisX: { showGrid: false },
                axisY: {
                  scaleMinSpace: 30,
                  showGrid: false,
                  labelInterpolationFnc: function (value) {
                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  },
                },
                chartPadding: 20,
                showLabel: false,
                showArea: false,
                showLine: false,
                height: '250px',
                width: '100%',
                showPoint: false,
                lineSmooth: true,
                onlyInteger: true,
                referenceValue: 0,
                low: 0,
                high: Math.max(...usageData.normalized.monthly) * 1.1,
                plugins: [
                  // Hack: Not sure why we can't just use Designer.ctBarLabelsBillSavings - labels don't appear
                  // Re-implementing within this file fixes labels.
                  ctBarLabels({
                    textAnchor: 'middle',
                    energyConsumptionData: usageData.normalized.monthly.map(function (value) {
                      return value
                    }),
                  }),
                ],
              }}
              type={'Bar'}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default memo(MonthlyUsageChart)

import { TextInput } from 'ra-ui-materialui'
import React from 'react'

const validateJsonInput = (value) => {
  try {
    JSON.parse(value)
  } catch (e) {
    return 'Invalid JSON'
  }
}

const JsonInputField = (props) => {
  return (
    <TextInput
      {...props}
      className=""
      InputProps={{ className: 'code-block' }}
      InputLabelProps={{
        shrink: true,
      }}
      multiline
      rows={12}
      fullWidth
      variant="outlined"
      validate={validateJsonInput}
    />
  )
}

export default JsonInputField

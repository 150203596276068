import { Card } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import { useTranslate } from 'ra-core'
import React from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type PropTypes = {
  header: string
  description?: string
}

const useStyles = makeOpenSolarStyles((theme) => ({
  card: {
    backgroundColor: '#EDF3FF',
    border: 'none',
    textAlign: 'center',
    padding: '0.5rem 1rem',
    marginBottom: '1rem',
  },

  cardHeader: {
    color: '#2C499D',
    fontSize: 15,
    fontWeight: 500,
  },

  cardDescription: {
    color: theme.greyMid1,
    fontSize: 11,
  },

  chart: {
    height: '7rem',
  },
}))

const BlueInfoCard: React.FC<PropTypes> = ({ header, description, children }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const currencySymbol = useSelector(orgSelectors.getCurrencySymbolFromOrg)

  return (
    <Card variant="outlined" className={classes.card}>
      <h2 className={classes.cardHeader}>
        {header.includes('%{currencySymbol}') ? translate(header, { currencySymbol }) : translate(header)}
      </h2>
      {children}
      {description && <p className={classes.cardDescription}>{translate(description)}</p>}
    </Card>
  )
}

export default BlueInfoCard

import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import React, { useEffect, useState } from 'react'
import { SelectInput, SimpleForm, useNotify } from 'react-admin'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { FieldType, IntegrationConfigType } from './types'

const restClientInstance = restClient(window.API_ROOT + '/api')

const useStyles = makeStyles(() => ({
  contentWrapper: {},
  actionsWrapper: {
    display: 'flex',
    width: '100%',
  },
  btnWrapper: {
    flex: 1,
    margin: '15px',
    display: 'flex',
    justifyContent: 'center',
  },
}))

type PropTypes = {
  isOpen: boolean
  onClose: () => void
  integrationMap: IntegrationConfigType
  onSuccess?: () => void
}

const SetOrgConfigDialogContents: React.FC<PropTypes> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const isAdmin = useSelector(authSelectors.getIsAdmin)
  const orgId = useSelector(authSelectors.getCurrentOrg)?.id
  const classes = useStyles()
  const formState = useFormState()
  const notify = useNotify()

  if (!isAdmin) return null
  if (!props.integrationMap?.content?.header || !props.integrationMap?.content?.p1) return null

  const renderField = (field: FieldType) => {
    if (field.type === 'select') {
      return (
        <SelectInput
          style={{ width: '95%' }}
          required={true}
          label={field.label}
          source={field.source}
          fullWidth
          choices={field.choices}
        />
      )
    }
  }

  const submit = () => {
    setIsLoading(true)
    restClientInstance('CUSTOM_PUT', 'custom', {
      url: `orgs/${orgId}/configs/`,
      data: { ...formState.values.org_configs },
    })
      .then((res) => {
        props.onClose()
        if (props.onSuccess) {
          props.onSuccess()
          notify('Your changes have been saved and we are refreshing your products', 'success')
        } else {
          notify('Your changes have been saved', 'success')
        }
      })
      .catch((err) => {
        notify('Unable to save changes', 'warning')
      })
      .finally(() => setIsLoading(false))
  }
  return (
    <Dialog open={props.isOpen} onClose={props.onClose}>
      <DialogTitle>{props.integrationMap?.content?.header}</DialogTitle>
      <DialogContent>
        <div className={classes.contentWrapper}>
          <Grid item xs={12}>
            <p>{props.integrationMap?.content?.p1}</p>
          </Grid>
          {props.integrationMap.content.fields?.map((field) => (
            <Grid item xs={12}>
              {renderField(field)}
            </Grid>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.actionsWrapper}>
          <div className={classes.btnWrapper}>
            <ProUXButton type="secondary" onClick={props.onClose} label="Cancel" />
          </div>
          <div className={classes.btnWrapper}>
            <ProUXButton
              type="primary"
              onClick={submit}
              label={props.onSuccess ? 'Save and Refresh Products' : 'Save'}
              loading={isLoading}
            />
          </div>
        </div>
      </DialogActions>
    </Dialog>
  )
}

const SetOrgConfigDialog: React.FC<PropTypes> = (props) => {
  const [initialData, setInitialData] = useState<Object>({})
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const orgId = useSelector(authSelectors.getCurrentOrg)?.id

  const fetchCurrentOrgConfigs = () => {
    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${orgId}/configs/`,
    })
      .then((res) => {
        setInitialData(res.data)
      })
      .catch((err) => {})
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    fetchCurrentOrgConfigs()
  }, [])

  if (isLoading) return null
  return (
    <SimpleForm initialValues={initialData}>
      <SetOrgConfigDialogContents {...props} />
    </SimpleForm>
  )
}
export default SetOrgConfigDialog

const CHECKBOX_INPUTS = ['can_charge_from_grid', 'can_discharge_to_grid', 'can_charge_from_solar', 'can_discharge_to_load']

const VALID_COMBINATIONS = [
  [],
  ['can_charge_from_grid'],
  ['can_discharge_to_grid'],
  ['can_charge_from_solar'],
  ['can_discharge_to_load'],
  ['can_charge_from_grid', 'can_charge_from_solar'],
  ['can_discharge_to_grid', 'can_discharge_to_load'],
  ['can_charge_from_solar', 'can_discharge_to_load'],
]

const VALID_COMBINATION_STRINGS = VALID_COMBINATIONS.map((combination) => combination.join('.'))

export const validateBatteryScheme = (values: any, translate: Function) => {
  //alert('On startup, populate the variable_* fields from configuration_json')
  const errors = {}
  if (values?.strategies?.length > 0) {
    for (let i = 0; i < values.strategies.length; i++) {
      const strategy = values.strategies[i]
      const combination = CHECKBOX_INPUTS.filter((field) => strategy[field]).join('.')
      if (!VALID_COMBINATION_STRINGS.includes(combination)) {
        errors[`strategies.${i}`] = translate('Invalid Battery Logic Combinations')
      }
    }
  }
  return errors
}

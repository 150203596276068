import React from 'react'
import { useTranslate } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Button from '../../../../elements/button/Button'

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: '20px',
  },
  rightButtonView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
}))

type PropTypes = {
  onAddNew: () => void
  buttonLabel: string
}

const ContactBottomActions: React.FunctionComponent<PropTypes> = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.main}>
      <div className={classes.rightButtonView}>
        <Button onClick={props.onAddNew} label={props.buttonLabel} variant="contained" color="default" />
      </div>
    </div>
  )
}
export default ContactBottomActions

import Grid from '@material-ui/core/Grid'
import type { OpenSolarThemeType } from 'Themes'
import { orderSelectors } from 'ducks/orderComponents'
import inflection from 'inflection'
import LineItemType from 'pages/ordering/OrderLineItem'
import ProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/projectOrderPresenter'
import { CodeField } from 'pages/ordering/order/table/CodeField'
import { CostPerUnit, TotalCost } from 'pages/ordering/order/table/Cost'
import OrderTableContainer from 'pages/ordering/order/table/OrderTableContainer'
import Allocations from 'pages/ordering/order/table/allocation/Allocations'
import getGroupedOrders from 'pages/ordering/order/table/getGroupedOrders'
import { memo } from 'react'
import { useTranslate } from 'react-admin'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type Props = {
  lineItems: LineItemType[]
  showAllocations: boolean
}

type LineItemsRowsProps = {
  lineItem: LineItemType
  isFirstItem: boolean
  groupedLineItemsQuantity: number
  showAllocations: boolean
}

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  tableHeaderContainer: {
    backgroundColor: theme.greyLight3,
    borderBottom: '1px solid #e7e7e7',
    height: '48px',
  },
  header: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
  },
  overflowItem: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  gridContainer: {
    borderBottom: '1px solid #e7e7e7',
  },
  gridLineItem: {
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  itemCell: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'start',
  },
  singleAllocation: {
    paddingTop: '0px',
  },
  multiAllocation: {
    paddingTop: '10px',
  },
  clickableLink: {
    textDecoration: 'underline',
    color: '#1751D0',
    cursor: 'pointer',
  },
  ellipsisItem: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 100,
  },
}))

const LineItemRow = ({ lineItem, isFirstItem, groupedLineItemsQuantity, showAllocations }: LineItemsRowsProps) => {
  const usingCustomPricing = useSelector(orderSelectors.getIsCustomPricing)
  const classes = useStyles({ isFirstItem })
  const { pricePerUnit, data, componentType, status, quantity } = lineItem
  const componentTypeLabel = componentType || data?.component_type
  const { beforeDiscount, afterDiscount, discount } = lineItem.getBestDiscountOffer()
  return (
    <Grid container item xs={12} className={classes.gridContainer}>
      <Grid container item xs={12} className={classes.gridLineItem}>
        <Grid container item xs={12} alignItems={'center'} spacing={1}>
          <Grid item xs={showAllocations ? 2 : 3}>
            <CodeField lineItem={lineItem} />
          </Grid>
          <Grid item xs={1}>
            {isFirstItem && (
              <div className={classes.ellipsisItem}>
                {componentTypeLabel && inflection.capitalize(componentTypeLabel)}
              </div>
            )}
          </Grid>
          <Grid item xs={2}>
            {isFirstItem && <div className={classes.ellipsisItem}>{data?.manufacturer_name}</div>}
          </Grid>
          <Grid item xs={1}>
            {quantity}
          </Grid>
          {showAllocations && (
            <Grid item xs={2}>
              {isFirstItem && <Allocations lineItem={lineItem} groupedLineItemsQuantity={groupedLineItemsQuantity} />}
            </Grid>
          )}
          <Grid item xs={2}>
            {status === 'loading' ? (
              <Skeleton />
            ) : (
              <CostPerUnit pricePerUnit={usingCustomPricing ? 'TBC' : pricePerUnit} discount={discount} />
            )}
          </Grid>
          <Grid item xs={2}>
            {status === 'loading' ? (
              <Skeleton />
            ) : (
              <TotalCost beforeDiscount={usingCustomPricing ? 'TBC' : beforeDiscount} afterDiscount={afterDiscount} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const LineItemsRows = ({ lineItems, showAllocations }: Props) => {
  const groupedLineItems = getGroupedOrders(lineItems)
  return (
    <>
      {groupedLineItems.map((lineItems: LineItemType[], i) => {
        const groupedLineItemsQuantity = ProjectOrderPresenter.getLineItemsTotalQuantity(lineItems)
        return lineItems
          .sort((a, b) => {
            if (a.quantityPerItem == null) {
              return -1
            }
            if (b.quantityPerItem == null) {
              return 1
            }
            return a.quantityPerItem - b.quantityPerItem
          })
          .map((lineItem: LineItemType, index: number) => (
            <LineItemRow
              key={lineItem.code + index}
              lineItem={lineItem}
              groupedLineItemsQuantity={groupedLineItemsQuantity}
              isFirstItem={index === 0}
              showAllocations={showAllocations}
            />
          ))
      })}
    </>
  )
}

const ReviewItemTableHeader = ({ showAllocations }: { showAllocations: boolean }) => {
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <Grid container className={classes.tableHeaderContainer} alignItems="center">
      <Grid container item xs={12} className={classes.header} spacing={1}>
        <Grid item xs={showAllocations ? 2 : 3} style={{ paddingLeft: 20 }}>
          {translate('Item')}
        </Grid>
        <Grid item xs={1} className={classes.overflowItem}>
          {translate('Category')}
        </Grid>
        <Grid item xs={2} className={classes.overflowItem}>
          {translate('Brand')}
        </Grid>
        <Grid item xs={1}>
          {translate('Quantity')}
        </Grid>
        {showAllocations && (
          <Grid item xs={2}>
            {translate('Allocations')}
          </Grid>
        )}
        <Grid item xs={2} className={classes.overflowItem}>
          {translate('Cost/Unit')}
        </Grid>
        <Grid item xs={2} className={classes.overflowItem}>
          {translate('Total Cost')}
        </Grid>
      </Grid>
    </Grid>
  )
}

const ReviewItemTableContent = ({ lineItems, showAllocations }: Props) => (
  <div>
    {lineItems && lineItems.length > 0 && <LineItemsRows lineItems={lineItems} showAllocations={showAllocations} />}
  </div>
)

const ReviewItemTable = ({ lineItems, showAllocations }: Props) => {
  return (
    <OrderTableContainer isAvailable={true}>
      <ReviewItemTableHeader showAllocations={showAllocations} />
      <ReviewItemTableContent lineItems={lineItems} showAllocations={showAllocations} />
    </OrderTableContainer>
  )
}

export default memo(ReviewItemTable)

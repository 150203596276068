import { authSelectors } from 'ducks/auth'
import FullScreenLoading from 'layout/widgets/FullScreenLoading'
import { memo, useEffect, useState } from 'react'
import { refreshView } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import BackToHomeCountingDown from './BackToHomeCountingDown'
import useSwitchOrg from './useSwitchOrg'
import { getOrgSwitcherRedirect } from './utils'

export const ORG_SWITCHER_PATH = 'org_correction'

type SwitchStatus = 'loading' | 'success' | 'invalid'

const QueryParamOrgSwitcher = ({ switchId }: { switchId: string }) => {
  const [status, setStatus] = useState<SwitchStatus>('loading')
  const authReloading = useSelector(authSelectors.getAuthReloading)
  const location = useLocation()
  const dispatch = useDispatch()
  const currentSelectedOrgId = useSelector(authSelectors.getOrgId)
  const history = useHistory()
  const availableOrgs = useSelector(authSelectors.getBriefOrgs)
  const switchOrg = useSwitchOrg()

  useEffect(() => {
    const switchIdNumber = parseInt(switchId)
    if (authReloading) {
      // wait reloading result
      return
    }
    if (!availableOrgs?.find((org) => org.id === switchIdNumber)) {
      setStatus('invalid')
      return
    }
    const redirect = getOrgSwitcherRedirect()

    if (currentSelectedOrgId !== switchIdNumber) {
      switchOrg({ orgId: switchIdNumber, reloadOrg: true })
    }
    history.push(redirect)
    dispatch(refreshView())
  }, [authReloading, availableOrgs])

  if (status === 'loading') {
    return <FullScreenLoading />
  }
  if (status === 'invalid') {
    return <BackToHomeCountingDown />
  }
  return <FullScreenLoading />
}

export default memo(QueryParamOrgSwitcher)

import { Theme, makeStyles } from '@material-ui/core'
import { DragIndicatorOutlined } from '@material-ui/icons'
import React, { useCallback, useState } from 'react'

type PropTypes = {
  draggable: boolean
  onDrag: () => void
  onDragEnd: () => void
}

const useStyles = makeStyles<Theme, { isBeingDragged: boolean }>((theme) => ({
  dragShadow: {
    width: ({ isBeingDragged }) => (isBeingDragged ? '280px' : '50px'),
    height: ({ isBeingDragged }) => (isBeingDragged ? '500px' : '50px'),
    backgroundColor: ({ isBeingDragged }) => (isBeingDragged ? '#ececec' : 'inherit'),
    opacity: ({ isBeingDragged }) => (isBeingDragged ? 0.5 : 'inherit'),
    cursor: 'grab',
  },
}))

const DragIcon: React.FC<PropTypes> = (props) => {
  const [isBeingDragged, setIsBeingDragged] = useState<boolean>(false)

  const classes = useStyles({ isBeingDragged })

  const onDragStart = useCallback(() => {
    props.onDrag()
  }, [isBeingDragged])

  const onDragEnd = () => {
    setIsBeingDragged(false)
    props.onDragEnd()
  }

  const onClick = () => setIsBeingDragged(true)

  const onMouseUp = () => {
    setIsBeingDragged(false)
  }

  return (
    <div
      draggable={props.draggable}
      onDrag={onDragStart}
      onDragEnd={onDragEnd}
      className={classes.dragShadow}
      onMouseDown={onClick}
      onMouseUp={onMouseUp}
    >
      <DragIndicatorOutlined htmlColor="rgb(117, 117, 117)" onMouseUp={onMouseUp} />
    </div>
  )
}
export default DragIcon

import { AppBar, Breadcrumbs, makeStyles, useMediaQuery } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNextOutlined'
import { setStudioSavePrompt } from 'ducks/studioPrompt'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import CreateProjectButton from './actions/CreateProjectButton'
import { ExploreAlreadyExistsMessage } from './ExploreAlreadyExistsMessage'

const useStyles = makeStyles({
  appBar: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 50,
    backgroundColor: 'rgb(255,255,255)',
    color: '#000000',
    padding: '0 20px',
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%)',
    borderTop: '1px solid #0000001a',
    justifyContent: 'space-between',
  },
  breadcrumbsRoot: {
    width: 350,
  },
  breadcrumbsOL: {
    flexWrap: 'nowrap',
  },
  link: {
    color: '#4d4d4d',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    overflow: 'hidden',
    width: 270,
  },
  address: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
})

type ProjectDetailType = {
  lat?: number
  lon?: number
}

const ExplorePageSubHeader: FC = () => {
  const [firstLandLocation, setFirstLandLocation] = useState<ProjectDetailType>({})
  const classes = useStyles()
  const form = useForm()
  const formValues = form.getState().values
  const translate = useTranslate()
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('xs'))

  useEffect(() => {
    //record location from the address lookup
    const stateLatLon = location.state as ProjectDetailType | undefined
    const lat = stateLatLon?.lat || formValues.lat
    const lon = stateLatLon?.lon || formValues.lon
    if (lat && lon) {
      setFirstLandLocation({ lat, lon })
    }
  }, [])
  const addressParts = [
    formValues.address,
    `${formValues.locality ?? ''} ${formValues.state ?? ''} ${formValues.zip ?? ''}`,
    formValues.country_iso2,
  ]
  const handleRedirect = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    if (!window.disableAutoSave) {
      dispatch(
        setStudioSavePrompt({
          show_prompt: true,
          redirect: '/home',
          prompt_heading: 'Leave explore page back to Home?',
        })
      )
    } else {
      history.push('/home')
    }
  }, [])

  const resetSceneOrigin = useCallback(() => {
    if (firstLandLocation.lat && firstLandLocation.lon) {
      form.batch(() => {
        form.change('lat', firstLandLocation.lat)
        form.change('lon', firstLandLocation.lon)
      })

      window.editor.scene.sceneOrigin4326 = [firstLandLocation.lon, firstLandLocation.lat]

      var mapData = window.MapHelper.activeMapInstance.toMapData()
      mapData.center = [firstLandLocation.lon, firstLandLocation.lat]
      mapData.sceneOrigin = [firstLandLocation.lon, firstLandLocation.lat]

      window.editor.loadCamera('top')

      window.MapHelper.setView(window.MapHelper.activeMapInstance, mapData, false, false)
    }
  }, [firstLandLocation])
  return (
    <AppBar position={'sticky'} className={classes.appBar}>
      <Breadcrumbs
        classes={{ root: classes.breadcrumbsRoot, ol: classes.breadcrumbsOL }}
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link color="inherit" to={'/home'} onClick={handleRedirect}>
          {translate('Home')}
        </Link>
        <span className={classes.link}>
          <a className={classes.address} onClick={resetSceneOrigin}>
            {addressParts.join(', ')}
          </a>
        </span>
      </Breadcrumbs>
      {formValues.address && !isMobile && <ExploreAlreadyExistsMessage address={formValues.address} />}
      <CreateProjectButton />
    </AppBar>
  )
}

export default ExplorePageSubHeader

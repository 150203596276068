import { Tooltip } from '@material-ui/core'
import CallIcon from '@material-ui/icons/CallOutlined'
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined'
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined'
import { IconButton } from 'opensolar-ui'
import React from 'react'

const iconStyle = {
  paddingBottom: 0,
  width: 30,
  height: 30,
  margin: 0,
  padding: 6,
}

export const ContactButtons = ({ contact, translate }) => (
  <div
    style={{
      display: 'inline-block',
      verticalAlign: 'middle',
    }}
  >
    <EmailButton contact={contact} translate={translate} style={{}} />
    <PhoneButton contact={contact} translate={translate} />
    <SmsButton contact={contact} translate={translate} />
  </div>
)

const PhoneButton = ({ contact, translate }) =>
  contact && contact.phone && contact.phone.length > 0 ? (
    <Tooltip title={translate('Call')}>
      <IconButton onClick={() => (window.location.href = 'tel:' + contact.phone)} disabled={false} style={iconStyle}>
        <CallIcon style={{ color: '#4d4d4d', width: 20, height: 20 }} />
      </IconButton>
    </Tooltip>
  ) : (
    <IconButton disabled={true} style={iconStyle}>
      <CallIcon style={{ width: 20, height: 20 }} />
    </IconButton>
  )

const EmailButton = ({ contact, style, translate }) =>
  contact && contact.email && contact.email.length > 0 ? (
    <Tooltip title={translate('Email')}>
      <IconButton onClick={() => (window.location.href = 'mailto:' + contact.email)} disabled={false} style={iconStyle}>
        <EmailOutlinedIcon style={{ color: '#4d4d4d', width: 20, height: 20 }} />
      </IconButton>
    </Tooltip>
  ) : (
    <IconButton disabled={true} style={iconStyle}>
      <EmailOutlinedIcon style={{ width: 20, height: 20 }} />
    </IconButton>
  )

const SmsButton = ({ contact, translate }) =>
  contact && contact.phone && contact.phone.length > 0 ? (
    <Tooltip title={translate('Message')}>
      <IconButton
        onClick={() => (window.location.href = 'sms:' + contact.phone)}
        disabled={false}
        style={{ marginTop: 8, ...iconStyle }}
      >
        <MessageOutlinedIcon style={{ color: '#4d4d4d', width: 20, height: 20 }} />
      </IconButton>
    </Tooltip>
  ) : (
    <IconButton disabled={true} style={{ marginTop: 8, ...iconStyle }}>
      <MessageOutlinedIcon style={{ width: 20, height: 20 }} />
    </IconButton>
  )

import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import { OpenSolarTheme } from 'Themes'

export const useStyles = makeStyles((theme: Theme) => ({
  dropZone: {
    display: 'flex',
    padding: theme.spacing(4, 3),
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(3),
    alignSelf: 'stretch',
    borderRadius: '8px',
    border: `1px dashed #B0BDC2`,
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: OpenSolarTheme.greyLight3,
    },
    '&:focus': {
      boxShadow: `0px 0px 0px 1px ${OpenSolarTheme.white}, 0px 0px 0px 3px #618DF2`,
    },
  },
  dropZoneSmall: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '6px',
    padding: theme.spacing(0.5),
    gap: theme.spacing(1),
    border: `1px dashed #B0BDC2`,
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: OpenSolarTheme.greyLight3,
    },
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  dragActive: {
    backgroundColor: COLOR_PALETTE.blue,
    border: `2px solid ${COLOR_PALETTE.darkBlue2}`,
  },
  dropZoneError: {
    border: `1px dashed ${theme.palette.error.main}`,
  },
  fileUploadContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1.125),
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
  darkText: {
    color: COLOR_PALETTE.black,
    fontWeight: 400,
  },
  linkText: {
    color: COLOR_PALETTE.darkBlue2,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  miscellaneousText: {
    color: '#6A777A',
    fontWeight: 400,
  },
  fileLimitContainer: {
    marginTop: '10px',
  },
  fileNameContainer: {
    marginTop: '24px',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  file: {
    border: `1px solid ${OpenSolarTheme.greyMid2}`,
    borderRadius: '6px',
    padding: theme.spacing(1),
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '9px',
    alignItems: 'center',
  },
  fileIconContainer: {
    display: 'flex',
    padding: 6,
    backgroundColor: OpenSolarTheme.greyLight3,
    borderRadius: 8,
    alignItems: 'center',
    margin: '0 auto',
  },
  descriptionText: {
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.25px',
  },
  iconContainer: {
    display: 'flex',
    padding: 12,
    backgroundColor: OpenSolarTheme.ValidityPalette.default.background,
    borderRadius: 8,
    alignItems: 'center',
    margin: '0 auto',
  },
  smallContainer: {
    padding: 6,
  },
  errorBackground: {
    backgroundColor: OpenSolarTheme.ValidityPalette.error.background,
  },
  dragActiveIcon: {
    backgroundColor: '#D6E0F7',
  },
  moreOptionsIconContainer: {
    display: 'flex',
    padding: 6,
    borderRadius: 8,
    alignItems: 'center',
    margin: '0 auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  errorText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    color: theme.palette.error.main,
    display: 'flex',
    justifyContent: 'center',
  },
  error: {
    color: theme.palette.error.main,
  },
  fileInfo: {
    marginRight: 'auto',
    display: 'flex',
    gap: theme.spacing(1),
  },
  deleteContainer: {
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    backgroundColor: OpenSolarTheme.white,
    boxShadow: '0px 6px 16px 0px rgba(10, 9, 11, 0.08), 0px 0px 0px 1px rgba(10, 9, 11, 0.05)',
    display: 'flex',
    alignItems: 'center',
    color: '#BC1C1C',
    fontSize: '14px',
    fontWeight: 475,
    gap: theme.spacing(0.5),
    width: '120px',
    justifyContent: 'space-between',
    position: 'absolute',
    right: '10px',
    cursor: 'pointer',
  },
  deleteIconContainer: {
    display: 'flex',
    padding: 6,
    borderRadius: 8,
    alignItems: 'center',
  },
  iconStyle: {
    height: 20,
    width: 20,
    margin: '0 auto',
  },
  autoMarginRight: {
    marginRight: 'auto',
  },
  displayFlex: {
    display: 'flex',
  },
  deleteText: {
    color: '#0A090B',
    marginTop: 16,
  },
  deleteModalWidth: {
    width: '590px',
  },
  modalBody: {
    padding: '24px',
  },
  centerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fieldText: {
    fontSize: '14px',
    fontWeight: 550,
    lineHeight: '20px',
    color: '#2D2B32',
  },
  enableDescription: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    color: '#4F4D55',
    marginTop: 4,
  },
  modalFooter: {
    padding: '20px',
    display: 'flex',
    borderTop: '1px solid rgba(230, 230, 230, 1)',
    justifyContent: 'space-between',
  },
  modalFooterButtonsRight: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    marginLeft: 'auto',
  },
  modalHeader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(230, 230, 230, 1)',
    padding: '20px 16px 20px 24px',
  },
  modalHeaderText: {
    fontWeight: 700,
    fontSize: '18px',
  },
}))

import { Paper } from '@material-ui/core'
import CustomField from 'elements/field/CustomField'
import React, { ReactElement, ReactNode } from 'react'
import { BooleanInput, TextInput } from 'react-admin'
import { useResourceStyles } from 'resources/styles'
import { required } from 'validations'
import GenericInputField, { GenericFieldType } from './GenericInputField'
import { StandardInput, standardInputStyles } from './StandardInput'

/***
 * This adds a set of commonly used inputs to the top of create/edit pages.
 */

interface PropTypes {
  title?: false | string
  autoApply?: false | string
  autoApplyLabel?: string
  archive?: boolean | string
  description?: false | string
  extraFields?: (GenericFieldType | ReactElement)[]
}

export const StandardInputs: React.FC<PropTypes> = ({
  title,
  autoApply,
  autoApplyLabel,
  archive,
  description,
  extraFields,
}) => {
  const resourceStyles = useResourceStyles()
  const titleSource = title || 'title'
  const autoApplySource = autoApply || 'priority'
  const archiveSource = typeof archive === 'boolean' || typeof archive === 'undefined' ? 'is_archived' : archive
  const descriptionSource = description || 'description'

  return (
    <Paper elevation={1} className={resourceStyles.formSection}>
      {title !== false && (
        <StandardInput label="Title">
          <CustomField
            component={TextInput}
            name={titleSource}
            source={titleSource}
            label={false}
            style={standardInputStyles.input}
            validate={required}
          />
        </StandardInput>
      )}
      {extraFields &&
        extraFields.map((field) => {
          if (field['label'] && field['component']) {
            const fieldData = field as GenericFieldType
            return (
              <StandardInput label={fieldData.label}>
                <GenericInputField
                  data={{ ...fieldData, label: '' }}
                  fullWidth={true}
                  style={standardInputStyles.input}
                />
              </StandardInput>
            )
          } else {
            const fieldElem = field as ReactElement
            return (
              <StandardInput label={fieldElem.props.label}>
                {React.cloneElement(fieldElem, {
                  label: false,
                  style: fieldElem.props.inputStyle || standardInputStyles.input,
                })}
              </StandardInput>
            )
          }
        })}
      {autoApply !== false && (
        <StandardInput label={autoApplyLabel || 'Auto-apply'}>
          <CustomField
            component={BooleanInput}
            name={autoApplySource}
            source={autoApplySource}
            label={false}
            style={standardInputStyles.field}
          />
        </StandardInput>
      )}
      {archive !== false && (
        <StandardInput label="Archive (hide component)">
          <CustomField
            component={BooleanInput}
            name={archiveSource}
            source={archiveSource}
            label={false}
            style={standardInputStyles.input}
          />
        </StandardInput>
      )}
      {description !== false && (
        <StandardInput label="Description">
          <CustomField
            component={TextInput}
            fullWidth={true}
            multiline={true}
            name={descriptionSource}
            source={descriptionSource}
            label={false}
            style={standardInputStyles.input}
          />
        </StandardInput>
      )}
    </Paper>
  )
}
interface InputPropTypes {
  label: string
  children: ReactNode
}

import { Tooltip } from '@material-ui/core'
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined'
import React from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type PropTypes = {
  url: string | null | undefined
  method: string
}

const useStyles = makeOpenSolarStyles((theme) => ({
  iconContainer: {
    display: 'inline-block',
  },
  icon: {
    cursor: 'pointer',
    transition: 'color 0.3s ease',
    '&:hover': {
      color: 'blue',
    },
  },
}))

const PaymentRequestCopyLink: React.FunctionComponent<PropTypes> = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const translate = useTranslate()

  const copyUrl = () => {
    if (!props.url) {
      notify(translate('Failed to copy Link to clipboard'), 'warning')
    } else {
      navigator.clipboard.writeText(tagUrlWithDelMethod(props.url)).then(
        function () {
          notify(translate('Link copied to clipboard'), 'success')
        },
        function (err) {
          console.warn(err)
          notify(translate('Failed to copy Link to clipboard'), 'warning')
        }
      )
    }
  }

  const tagUrlWithDelMethod = (url) => {
    return `${url}&delivery_method=copied`
  }

  return (
    <Tooltip title={translate('Copy Invoice link to clipboard')}>
      <div className={classes.iconContainer} onClick={copyUrl}>
        <LinkOutlinedIcon className={classes.icon} />
      </div>
    </Tooltip>
  )
}
export default PaymentRequestCopyLink

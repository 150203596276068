import { Grid, Typography } from '@material-ui/core'
import type { OpenSolarThemeType } from 'Themes'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import FigureCard from './FigureCard'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  colorSection: {
    padding: 25,
    minHeight: 140,
    background: 'linear-gradient(to right, #ffe9c2, #fdd6d6)',
  },
}))

type FigureConfigType = {
  key: string
  figure?: string | number
  description: string
  // consider to move loading to config
}

const FiguresBanner = ({
  title,
  loading,
  figureConfigs,
}: {
  title: string
  loading: boolean
  figureConfigs: FigureConfigType[]
}) => {
  const classes = useStyles()
  return (
    <section className={classes.colorSection}>
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      <Grid container xs={12} spacing={1}>
        {figureConfigs.map((config: FigureConfigType) => (
          <Grid item xs={'auto'}>
            <FigureCard key={config.key} figure={config.figure} description={config.description} loading={loading} />
          </Grid>
        ))}
      </Grid>
    </section>
  )
}

export default FiguresBanner

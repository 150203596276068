import { Step, StepContent, StepLabel, Stepper, makeStyles } from '@material-ui/core'
import { orgSelectors, orgsActions } from 'ducks/orgs'
import { Button } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useEffect, useState } from 'react'
import ConfettiExplosion from 'react-confetti-explosion'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import useTranslateParse from 'util/useTranslateParse'
import PaymentChannels from '../components/PaymentChannels'
import Badge from '../sharedComponents/Badge'

type PropTypes = {}

const useStyles = makeOpenSolarStyles((theme) => ({
  confettiContainer: {
    zIndex: 500,
    position: 'absolute',
    left: '25rem',
  },
  container: {
    width: '70%',
    margin: 'auto',
    lineHeight: 1.5,
  },
  header: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '1.5rem',
    marginTop: 0,
  },
  row: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  rowCenter: {
    display: 'flex',
    justifyContent: 'center',
    margin: '1rem',
  },
  label: {
    marginRight: '10px',
  },
  reviewBtn: {
    fontSize: 13,
  },
  description: {
    margin: 0,
  },
  paymentsWrapper: {
    width: '50%',
    margin: '10px 0',
  },
}))

const useStepperStyles = makeStyles((theme) => ({
  root: {
    '& .MuiButtonBase-root': {
      cursor: 'default',
    },
    '& .MuiStepIcon-root.MuiStepIcon-active': {
      color: '#9F9F9F',
    },
    '& .MuiStepIcon-root.MuiStepIcon-completed': {
      color: '#019939',
    },
    '& .MuiTypography-root': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
}))

const steps = [
  {
    step: 1,
    label: 'Activate CashFlow',
    description: `You can begin collecting customer deposits and payments now. CashFlow will be automatically added to new projects.`,
  },
  {
    step: 2,
    label: 'Customize CashFlow (Optional)',
    chip: '5 min',
    description: `With CashFlow you can customize payment milestones and automated customer emails. We've moved any existing cash payment milestone configurations over to CashFlow to make things easier for you.`,
  },
]

const bigExplodeProps = {
  force: 0.8,
  duration: 3000,
  particleCount: 250,
  width: 1400,
  zIndex: 2100,
}

const ConditionallyApproved: React.FC<PropTypes> = (props) => {
  const [loading, setIsLoading] = useState<boolean>(false)
  const classes = useStyles()
  const stepperClasses = useStepperStyles()
  const translate = useTranslate()
  const translateParse = useTranslateParse()
  const completed = { 0: true }
  const dispatch = useDispatch()
  const history = useHistory()
  const appSubmittedInRedux = useSelector(orgSelectors.getBlueSnapOnboardingIsSubmitted)

  const onClick = () => {
    setIsLoading(true)
    dispatch(orgsActions.reloadOrg())
  }

  useEffect(() => {
    if (appSubmittedInRedux) {
      history.push('/cashflow/configure/milestones')
      setIsLoading(false)
    }
  }, [appSubmittedInRedux])

  return (
    <div className={classes.container}>
      <h1 className={classes.header}>
        {translate("Congratulations! You've activated CashFlow.")}{' '}
        <span role="img" aria-label="hooray">
          🎉
        </span>
      </h1>
      <ConfettiExplosion {...bigExplodeProps} className={classes.confettiContainer} />
      <p>
        {translateParse(
          'You just made selling solar - and getting paid for it - loads easier. We will email you with more information to get your team started selling with CashFlow. <strong>We may require additional documentation before enabling payout - please reply quickly to any requests for documents.<strong>',
          {
            strong: (label: string) => <strong>{label}</strong>,
          }
        )}
      </p>
      <p>{translate('In the meantime...')}</p>

      <div>
        <Stepper orientation="vertical" classes={stepperClasses}>
          {steps.map((step, index) => (
            <Step key={step.step} completed={completed[index]} active={true}>
              <StepLabel>
                <div className={classes.row}>
                  <span className={classes.label}> {translate(step.label)} </span>
                  {step.chip && <Badge text={translate(step.chip)} />}
                </div>
              </StepLabel>
              <StepContent>
                {step.description && <p className={classes.description}>{translate(step.description)}</p>}
                {index === 0 && (
                  <div className={classes.paymentsWrapper}>
                    <PaymentChannels />
                  </div>
                )}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </div>
      <div className={classes.rowCenter}>
        <Button variant="contained" color="primary" className={classes.reviewBtn} onClick={onClick} disabled={loading}>
          Customize
        </Button>
      </div>
    </div>
  )
}
export default ConditionallyApproved

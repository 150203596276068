// @ts-nocheck
import Paper from '@material-ui/core/Paper'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { useCallback } from 'react'
import { useTranslate } from 'react-admin'
import { useHistory } from 'react-router-dom'
import { useResourceStyles } from 'resources/styles'
import useTranslateParse from 'util/useTranslateParse'

const useStyles = makeStyles((theme: Theme) => ({
  heading: { color: '#000000', fontSize: 15, lineHeight: '17px', fontWeight: 700, marginBottom: 10 },
  link: {
    color: theme.blue,
    cursor: 'pointer',
    '&:hover': {
      color: theme.blue,
    },
  },
}))

const GeneralHelperCard = () => {
  const classes = useStyles()
  const resourceStyles = useResourceStyles()
  const history = useHistory()
  const translate = useTranslate()
  const translateParse = useTranslateParse()
  const handleRedirect = useCallback((redirectLink) => {
    history.push(redirectLink)
  }, [])
  return (
    <Paper classes={{ root: resourceStyles.formSection }} elevation={1}>
      <div className={classes.heading}>{translate('General and Component Specific costs')}</div>
      <div>{translate('This markup pricing scheme will apply to the total costs set in your account.')}</div>
      <div>
        {translate('To set component costs, go to the Design & Hardware section and add the costs for each component.')}{' '}
        {translateParse(
          'Link to Page for <1>Modules<1>, <2>Inverters<2>, <3>Batteries<3> and <4>Other Components<4>.',
          {
            1: (label: string) => (
              <span className={classes.link} onClick={() => handleRedirect('/component_module_activations')}>
                {label}
              </span>
            ),
            2: (label: string) => (
              <span className={classes.link} onClick={() => handleRedirect('/component_inverter_activations')}>
                {label}
              </span>
            ),
            3: (label: string) => (
              <span className={classes.link} onClick={() => handleRedirect('/component_battery_activations')}>
                {label}
              </span>
            ),
            4: (label: string) => (
              <span className={classes.link} onClick={() => handleRedirect('/component_other_activations')}>
                {label}
              </span>
            ),
          }
        )}
      </div>

      <div>
        {translate('To set general costs, go to the Pricing & Payments section > Cost schemes.')}{' '}
        <span className={classes.link} onClick={() => handleRedirect('/costings')}>
          {translate('Link To Page')}
        </span>
      </div>
    </Paper>
  )
}

export default GeneralHelperCard

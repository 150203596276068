// @ts-nocheck
import Fade from '@material-ui/core/Fade'
import Grow from '@material-ui/core/Grow'
import withWidth, { WithWidthOptions } from '@material-ui/core/withWidth'
import AvPlayCircleOutline from '@material-ui/icons/PlayCircleOutlineOutlined'
import RemoveIcon from '@material-ui/icons/RemoveOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { addTourBanner, authSelectors, removeTourBanner } from 'ducks/auth'
import { viewModeActions, viewModeSelectors } from 'ducks/viewMode'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import { isOpen as isWelcomeOpenSelector } from 'persistentContent/elements/UserAgreementDialog'
import { recordUserAction } from 'persistentContent/tours/tools/common'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'
import appStorage from 'storage/appStorage'
import { RootState } from 'types/state'

type PropTypes = {
  width: WithWidthOptions
}

const TourBanner: React.FunctionComponent<PropTypes> = (props) => {
  const [isFocused, setIsFocused] = useState<Boolean>(false)
  const [hasQueried, setHasQueried] = useState<boolean>(false)
  const [showBanner, setShowBanner] = useState<boolean>(false)

  const translate = useTranslate()
  const dispatch = useDispatch()

  const isMobileDevice = useMemo(() => window.Utils?.isTouchDevice(), [])
  const tourBanner = useSelector(authSelectors.getShowTourBanner)
  const shouldShowTour = useSelector(viewModeSelectors.showFujiTour)
  const fujiTourEnabled = useSelector(viewModeSelectors.fujiTourEnabled)
  const welcomeDialogOpen: boolean = useSelector(isWelcomeOpenSelector)
  const userId = useSelector(authSelectors.getCurrentUser)?.id
  const roleId = useSelector(authSelectors.getCurrentRole)?.id
  const { tour } = useSelector((state: RootState) => state.tour)

  useEffect(() => {
    if (fujiTourEnabled && tourBanner === undefined && !hasQueried && !tour) {
      setHasQueried(true)
      queryIsVisible()
    }
  }, [fujiTourEnabled, tourBanner, hasQueried, tour])

  useEffect(() => {
    if (roleId !== undefined && fujiTourEnabled && shouldShowTour !== false && !isMobileDevice && !tour) {
      window.location.href = window.location.href + '?tour=fuji&step=start'
      dispatch(viewModeActions.setShowFujiTour(false))
    }
  }, [roleId, fujiTourEnabled, shouldShowTour, isMobileDevice, tour])

  const queryIsVisible = () => {
    if (appStorage.getString('hide_fuji_banner') === 'yes') return

    const API_URL = window.API_ROOT + '/api'
    const restClientInstance = restClient(API_URL)
    restClientInstance('CUSTOM_GET', 'custom', {
      url:
        'users/' +
        //@ts-ignore
        userId +
        '/roles/' +
        //@ts-ignore
        roleId +
        '/fuji_banner_visible/',
    })
      .then((res: any) => {
        if (res.data.is_visible) {
          dispatch(addTourBanner())
          setShowBanner(true)
          // if server says start the tour then open the tour dialog unless the welcome dialog is already open
          if (!shouldShowTour && res.data.start_tour && !welcomeDialogOpen && !isMobileDevice) {
            window.location.href = window.location.href + '?tour=fuji&step=start'
          }
        } else {
          dispatch(removeTourBanner(false))
          setShowBanner(false)
          appStorage.setString('hide_fuji_banner', 'yes')
        }
      })
      .catch((err: any) => {
        console.warn(err)
      })
  }

  useEffect(() => {
    if (showBanner) logAmplitudeEvent('fuji_banner_shown', {})
  }, [showBanner])

  const handleClick = () => {
    window.location.href = window.location.href + '?tour=fuji&step=start'
    dispatch(removeTourBanner(false))
  }
  const handleHide = () => {
    setShowBanner(false)
    dispatch(removeTourBanner(true))
    logAmplitudeEvent('fuji_banner_hidden', {})
    recordUserAction(userId, 'tour_hidden')
  }
  if (!showBanner || tour || welcomeDialogOpen) return null
  if (props.width === 'sm' || props.width === 'xs' || props.width === 'xxs' || isMobileDevice) return null
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        bottom: 150,
        left: '50%',
        transform: 'translateX(-50%)',
        position: 'absolute',
      }}
    >
      <Grow in={true}>
        <div
          style={{
            position: 'relative',
            boxShadow: '3px 10px 10px rgba(0, 0, 0, 0.10), -3px -3px 10px rgba(0, 0, 0, 0.10)',
            borderRadius: '5px',
            padding: '20px 40px',
          }}
          onMouseEnter={() => setIsFocused(true)}
          onMouseLeave={() => setIsFocused(false)}
        >
          {isFocused && (
            <Fade in={true}>
              <div
                style={{
                  position: 'absolute',
                  right: '5px',
                  top: '5px',
                  cursor: 'pointer',
                  border: '1px solid #a2a2a2',
                  borderRadius: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '18px',
                  width: '18px',
                }}
                onClick={handleHide}
              >
                <RemoveIcon htmlColor="#a2a2a2" />
              </div>
            </Fade>
          )}
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <span style={{ marginBottom: '15px' }}>{translate('Take the OpenSolar 2.0 Tour')}</span>
            <ProUXButton type="primary" onClick={handleClick} label="Start Tour" endIcon={<AvPlayCircleOutline />} />
          </div>
        </div>
      </Grow>
    </div>
  )
}
export default withWidth()(TourBanner)

// @ts-nocheck
import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import restClient from 'restClient'
import { ReduxActionType } from 'types/global'
import { RootState } from 'types/state'
const API_URL = window.API_ROOT + '/api'

export const GET_FEATURE_CONFIG = 'GET_FEATURE_CONFIG'
export const GET_FEATURE_CONFIG_START = 'GET_FEATURE_CONFIG_START'
export const GET_PUBLIC_FEATURE_CONFIG = 'GET_PUBLIC_FEATURE_CONFIG'
export const GET_PUBLIC_FEATURE_CONFIG_START = 'GET_PUBLIC_FEATURE_CONFIG_START'
export const GET_FEATURE_CONFIG_SUCCESS = 'GET_FEATURE_CONFIG_SUCCESS'
export const GET_FEATURE_CONFIG_FAIL = 'GET_FEATURE_CONFIG_FAIL'

export type FeatureConfigType = {
  loading: boolean
  not_found: boolean
  data: Object
}

export type FeatureConfigReducerType = {
  [key: string]: FeatureConfigType
}
const initialState: FeatureConfigReducerType = {}

export default function reducer(state = initialState, action: ReduxActionType) {
  switch (action.type) {
    case GET_FEATURE_CONFIG_START:
      return {
        ...state,
        [action.key]: {
          loading: true,
          not_found: false,
          data: undefined,
        },
      }
    case GET_FEATURE_CONFIG_SUCCESS:
      let data = undefined
      try {
        data = JSON.parse((action as any).config_data)
      } catch (ex) {}
      return {
        ...state,
        [(action as any).key]: {
          loading: false,
          not_found: false,
          data: data,
        },
      }
    case GET_FEATURE_CONFIG_FAIL:
      return {
        ...state,
        [action.key]: {
          loading: false,
          not_found: true,
          data: undefined,
        },
      }
    default:
      return { ...state }
  }
}

export const doGetFeatureConfig = (key: string) => {
  return new Promise((resolve, reject) => {
    const restClientInstance = restClient(API_URL)
    restClientInstance('CUSTOM_GET', 'custom', {
      url: `feature_config/?name=${key}`,
    })
      .then((response: any) => {
        resolve(response.data.config_data)
      })
      .catch((err: any) => {
        console.log('error getting available actions', err)
        reject(false)
      })
  })
}

// this is less sensitive and we want to ensure non-admins can GET this info
export const doGetPublicFeatureConfig = (key: string) => {
  return new Promise((resolve, reject) => {
    const restClientInstance = restClient(API_URL)
    restClientInstance('CUSTOM_GET', 'custom', {
      url: `feature_config/public/${key}/`,
    })
      .then((response: any) => {
        resolve(response.data.config_data)
      })
      .catch((err: any) => {
        console.log('error getting available actions', err)
        reject(false)
      })
  })
}

export function* getFeatureConfigSaga(action: any): any {
  if (!action.payload.forceLoad) {
    const value = yield select((state) => featureConfigSelectors.hasFeatureConfigData(state, action.payload.key))
    if (value) return
  }
  try {
    yield put({ type: GET_FEATURE_CONFIG_START, key: action.payload.key })
    const config_data = yield call(doGetFeatureConfig, action.payload.key)
    yield put({ type: GET_FEATURE_CONFIG_SUCCESS, config_data: config_data, key: action.payload.key })
  } catch (e) {
    yield put({ type: GET_FEATURE_CONFIG_FAIL, key: action.payload.key })
  }
}

export function* getPublicFeatureConfigSaga(action: any): any {
  if (!action.payload.forceLoad) {
    const value = yield select((state) => featureConfigSelectors.hasFeatureConfigData(state, action.payload.key))
    if (value) return
  }
  try {
    yield put({ type: GET_FEATURE_CONFIG_START, key: action.payload.key })
    const config_data = yield call(doGetPublicFeatureConfig, action.payload.key)
    yield put({ type: GET_FEATURE_CONFIG_SUCCESS, config_data: config_data, key: action.payload.key })
  } catch (e) {
    yield put({ type: GET_FEATURE_CONFIG_FAIL, key: action.payload.key })
  }
}

export const getFeatureConfig = (key: string, forceLoad?: boolean) => {
  return {
    type: GET_FEATURE_CONFIG,
    payload: {
      key,
      forceLoad,
    },
  }
}

export const getPublicFeatureConfig = (key: string, forceLoad?: boolean) => {
  return {
    type: GET_PUBLIC_FEATURE_CONFIG,
    payload: {
      key,
      forceLoad,
    },
  }
}

export const featureConfigSelectors = {
  hasFeatureConfigData: (state: RootState, key: string) => {
    return !!state?.featureConfig[key]
  },
  getFeatureConfigData: (state: RootState, key: string) => {
    return state?.featureConfig[key]?.data
  },
  getFeatureConfigNotFound: (state: RootState, key: string) => {
    return state?.featureConfig[key]?.not_found
  },
  getFeatureConfigLoading: (state: RootState, key: string) => {
    return state?.featureConfig[key]?.loading
  },
}

export function* featureConfigSagas() {
  yield all([
    takeEvery(GET_FEATURE_CONFIG, getFeatureConfigSaga),
    takeEvery(GET_PUBLIC_FEATURE_CONFIG, getPublicFeatureConfigSaga),
  ])
}

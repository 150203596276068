import { Grid } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import type { OpenSolarThemeType } from 'Themes'
import { StyledDialog, StyledDialogContent, StyledDialogTitle } from 'layout/StyledDialog'
import { IconButton } from 'opensolar-ui'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  toolbar: {
    margin: '10px 0',
  },
  content: {
    margin: '10px 0',
  },
  dialog: {
    width: '100%',
  },
}))

type ProjectForecastDialogProps = {
  open: boolean
  onClose(): void
  title: string
  content: React.ReactNode
  toolbar: React.ReactNode
}

const InventoryForecastDetailDialog = ({ open, onClose, title, content, toolbar }: ProjectForecastDialogProps) => {
  const classes = useStyles()
  return (
    <StyledDialog open={open} fullWidth maxWidth="md" classes={{ paper: classes.dialog }}>
      <Grid container justify="space-between">
        <Grid item>
          <StyledDialogTitle>{title}</StyledDialogTitle>
        </Grid>
        <Grid item>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <StyledDialogContent>
        <div className={classes.toolbar}>{toolbar}</div>
        <div className={classes.content}>{content}</div>
      </StyledDialogContent>
    </StyledDialog>
  )
}

export default InventoryForecastDetailDialog

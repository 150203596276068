import { logAmplitudeEvent } from 'amplitude/amplitude'
import { confirmLineItems, orderSelectors } from 'ducks/orderComponents'
import Button from 'elements/button/Button'
import type LineItemType from 'pages/ordering/OrderLineItem'
import ProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/projectOrderPresenter'
import React, { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const AddToCartButton = ({
  handleCloseAddMissingItem,
  setAdded,
  trackSource,
}: {
  handleCloseAddMissingItem?(): void
  setAdded?(arg: boolean): void
  trackSource?: string
}) => {
  const unconfirmedLineItems = useSelector(orderSelectors.getUnconfirmedLineItems)
  const orderableLineItems = unconfirmedLineItems.filter((lineItem: LineItemType) => {
    return ProjectOrderPresenter.getStockLevelStatusFromLineItem(lineItem) !== 'not_available' && lineItem.quantity > 0
  })
  const lineItems = useSelector(orderSelectors.getOrderLineItems)
  const lineItemsStatus = lineItems.map((lineItem: LineItemType) =>
    ProjectOrderPresenter.getStockLevelStatusFromLineItem(lineItem)
  )

  const dispatch = useDispatch()
  const handleAddToCart = useCallback(() => {
    const lineItemUuids: string[] = orderableLineItems.map((lineItem: LineItemType) => lineItem.uuid)
    dispatch(
      confirmLineItems({
        uuids: lineItemUuids,
        confirmed: true,
      })
    )
    logAmplitudeEvent('hardware_add_to_cart_button_clicked', {
      source: trackSource ? trackSource : 'order_table',
      codes: orderableLineItems.map((lineItem: LineItemType) => lineItem.code),
    })
    setAdded?.(true)
    handleCloseAddMissingItem?.()
  }, [orderableLineItems])

  return (
    <Button
      color="primary"
      disabled={orderableLineItems.length === 0 || lineItemsStatus.includes('loading')}
      onClick={handleAddToCart}
    >
      Add To Cart
    </Button>
  )
}

export default memo(AddToCartButton)

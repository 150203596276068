import { Grow, MenuItem, Select } from '@material-ui/core'
import { featureConfigSelectors } from 'ducks/featureConfig'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'types/state'
import { fieldPathToDisplayMap } from './constants'
import { FieldPathType, PlaceholderWhiteListType } from './ProjectPlaceholderWizard'
import { formatPlaceholderLabel } from './util'

type MenuItemType = {
  label: string
  value: string
}

type PropTypes = {
  fieldPath: FieldPathType
  revertPath: (newPath: string) => void
}

const FieldPathDropdown: React.FunctionComponent<PropTypes> = (props) => {
  const [menuItems, setMenuItems] = useState<MenuItemType[]>([])

  const whitelistVals: PlaceholderWhiteListType | undefined = useSelector((state: RootState) =>
    featureConfigSelectors.getFeatureConfigData(state, 'placeholder_whitelist')
  )

  useEffect(() => {
    if (props.fieldPath) {
      let items: MenuItemType[] = []
      const addItem = (pathNode: FieldPathType) => {
        items.push({
          label: pathNode.display,
          value: pathNode.path,
        })
        if (pathNode.parent) addItem(pathNode.parent)
      }

      addItem(props.fieldPath)
      items.push({
        label: 'Home',
        value: 'root',
      })
      setMenuItems(items)
    }
  }, [props.fieldPath])

  const onChange = (e) => {
    console.log('e.target.value', e.target.value)
    props.revertPath(e.target.value)
  }
  const formatLabel = (raw: string) => {
    let pathToDisplayWithWhitelist = { ...fieldPathToDisplayMap }
    if (whitelistVals && whitelistVals.path_to_display) {
      pathToDisplayWithWhitelist = {
        ...pathToDisplayWithWhitelist,
        ...whitelistVals.path_to_display,
      }
    }
    if (pathToDisplayWithWhitelist[raw]) return pathToDisplayWithWhitelist[raw]

    let parentPath = props.fieldPath?.parent ? props.fieldPath.parent.path : undefined
    return props.fieldPath?.path ? formatPlaceholderLabel(raw, parentPath, whitelistVals) : undefined
  }

  if (!props.fieldPath) return null
  return (
    <Grow in={true}>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={props.fieldPath?.path}
        label="Age"
        onChange={onChange}
        style={{ width: '100%', marginBottom: '10px', backgroundColor: '#ececec', borderRadius: '6px', padding: '3px' }}
      >
        {menuItems &&
          menuItems?.length > 0 &&
          menuItems.map((item: MenuItemType) => (
            <MenuItem key={item.value} value={item.value}>
              {formatLabel(item.label)}
            </MenuItem>
          ))}
      </Select>
    </Grow>
  )
}

export default FieldPathDropdown

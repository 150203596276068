import { DefaultBreadCrumbs } from 'elements/BreadCrumbs'
import PaginationWithActions from 'elements/PaginationWithActions'
import { List } from 'elements/react-admin/List'
import {
  AutocompleteInput,
  BooleanField,
  Datagrid,
  Edit,
  EditButton,
  FilterComp,
  FunctionField,
  ReferenceInput,
  SimpleForm,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'
import restClient from 'restClient'
import { styles as standardStyles } from 'styles'
import QuickFilter from '../../elements/QuickFilter'

var restClientInstance = restClient(window.API_ROOT + '/api')

const validateConfirmation = (orgName, reference) => {
  if (!orgName || !reference) {
    return 'Org Name and reference must be provided'
  } else if (reference.indexOf('@') !== -1 || reference.indexOf(' ') !== -1) {
    // Reject if using @ or space character
    return 'Reference is invalid'
  } else {
    return true
  }
}

export const GlobalOrgFilter = (props) => (
  <FilterComp {...props}>
    <TextInput label="pos.search" source="q" style={standardStyles.FILTER_FIELD_STYLE} />
    <QuickFilter
      label="Partner Org"
      source="enable_exhibit"
      style={standardStyles.FILTER_FIELD_STYLE}
      defaultValue={1}
    />
    <QuickFilter
      label={'Showcase is Public'}
      source="exhibit_is_public"
      style={standardStyles.FILTER_FIELD_STYLE}
      defaultValue={1}
    />
    <QuickFilter
      label="Enable Google Calendar"
      source="enable_google_calendar"
      style={standardStyles.FILTER_FIELD_STYLE}
      defaultValue={1}
    />

    <ReferenceInput label="Country" source="country" reference="countries" sort={{ field: 'name', order: 'ASC' }}>
      <AutocompleteInput
        options={{ style: standardStyles.FILTER_FIELD_STYLE, placeholder: 'Enter a country' }}
        optionText="name"
        optionValue="id"
      />
    </ReferenceInput>
    <TextInput
      label="Enter Partner to see which Orgs enabled their functionalities"
      source="exhibit_activations__name"
      style={standardStyles.FILTER_FIELD_STYLE}
    />
    <TextInput
      label="Enter Org to see who they share data with"
      source="exhibit_data_shares_available__name"
      style={standardStyles.FILTER_FIELD_STYLE}
    />
    <TextInput
      translate={false}
      label="Enter Partner to see which Orgs they can access data"
      source="exhibit_data_shares__name"
      style={standardStyles.FILTER_FIELD_STYLE}
    />
  </FilterComp>
)

export const GlobalOrgList = (props) => {
  const translate = useTranslate()
  return (
    <List
      pagination={
        <PaginationWithActions
          downloadUrl={window.API_BASE_URL + 'orgs/?fieldset=csv&format=csv&ordering=-id'}
          downloadFilename="OpenSolarOrgs"
        />
      }
      hasSearch={true}
      filters={<GlobalOrgFilter />}
      minQuantityToShowAsList={0}
      {...props}
    >
      <Datagrid cardTextStyle={{ padding: 15, height: 150, overflow: 'auto' }}>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="country_iso2" sortable={false} />
        <BooleanField source="enable_exhibit" label="Partner Org" textAlign={'center'} displayInCard={true} />
        <BooleanField
          source="exhibit_is_public"
          label={translate('Showcase is Public')}
          textAlign={'center'}
          displayInCard={true}
        />
        <BooleanField
          source="enable_google_calendar"
          label={translate('Enable Google Calendar')}
          textAlign={'center'}
          sortable={false}
          displayInCard={true}
        />
        <FunctionField
          source={null}
          label={translate('Actions')}
          render={(record, source, rest) => (
            <div>
              <EditButton useListStyle={true} record={record} basePath={rest.basePath} />
            </div>
          )}
          sortable={false}
        />
      </Datagrid>
    </List>
  )
}

const inputStyle = {
  width: 256,
}

export const GlobalOrgEdit = (props) => {
  const translate = useTranslate()

  return (
    <Edit breadCrumbs={<DefaultBreadCrumbs />} {...props}>
      <SimpleForm redirect="edit">
        <TextField style={inputStyle} source="name" />
        <button
          onClick={() => {
            var orgName = prompt('Confirm org name')
            var reference = prompt('Reference for record keeping (no personal info)')

            var isValid = validateConfirmation(orgName, reference)

            if (isValid !== true) {
              alert(isValid)
            } else {
              restClientInstance('CUSTOM_PATCH', 'custom', {
                url: 'orgs/' + props.id + '/anonymize/',
                data: { org_name: orgName, reference: reference },
              }).then(
                (response) => {
                  window.Designer.showNotification(window.translate(response?.data?.message || 'Success'), 'success')
                },
                (reject) => {
                  window.Designer.showNotification(window.translate(reject.message || 'Error'), 'danger')
                }
              )
            }
          }}
        >
          {translate('Anonymize Org')}
        </button>
      </SimpleForm>
    </Edit>
  )
}

import { Step, StepLabel, Stepper } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import Tooltip from 'elements/tooltip/Tooltip'
import { Chip, EditOutlineIcon, IconButton, MenuItem, Select, styled } from 'opensolar-ui'
import React, { ReactNode, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import appStorage from 'storage/appStorage'
import { ProjectType } from 'types/projects'
import { formatDate } from 'util/date'
import { doNotTranslate } from 'util/misc'
import IntegrationRow from '../IntegrationRow'
import { EnaDialog } from './ENADialog/EnaDialog'
interface PropTypes {
  project: ProjectType
}

interface StepContentProps {
  activeStep: number
  index: number
  children: ReactNode
}

const STATUS_TOOLTIP = {
  'Awaiting Assessment': 'Connect Direct is assessing the application.',
  'Scanning Cut-Out Image': 'The cut-out image is being assessed.',
  'Under Assessment': 'Connect Direct is assessing the application.',
  'Escalated to DNO': 'The application has been escalated to a DNO and may not proceed until a response is received.',
  'Auto-Approved': 'The application has been auto-approved by Connect Direct and can proceed.',
  'DNO Approved': 'The application has been approved by the DNO and can proceed.',
  'DNO Approved with Limit':
    'The application has been approved by the DNO but with conditions. Conditions can be accessed by querying the GET Installer applications endpoint.',
  'Further Information Required':
    'The DNO requires further information in order to be able to make a decision on this application. They will contact the installer directly to collect this information. No further information is required to be submitted via Connect Direct.',
  'Failed - Too Large':
    'The application is too large for Connect Direct. Applications must be within the following limits: 1. Total Aggregate Generation Capacity less than 17 kW per phase. 2. Total Property Demand less than 130 Amps.',
  Failed:
    'Connect Direct Failed to Process the Application. Please raise a support ticket via the Connect Direct website if this occurs.',
  'Failed - Invalid Cut-Out Image':
    'The cut out image provide is invalid. Please resubmit the application with a valid image. Images must be within a meter of the cutout.',
  'Image Scan Failed':
    'The image scan failed. Please raise a support ticket via the Connect Direct website if this occurs.',
  'Commissioning Submitted': 'Commissioning information has been sent by the installer to the DNO.',
  'Commissioning Accepted': 'The DNO has accepted the commissioning information.',
  'Commissioning Update Required':
    'The DNO has not accepted the commissioning information. The installer must review DNO feedback and re-submit.',
  '(Re) Auto-Approved':
    'The application was resubmitted due to a change in details post the initial approval. It has been auto-approved by Connect Direct and can proceed.',
  '(Re) Escalated to DNO':
    'The application was resubmitted due to a change in details post the initial approval. The application has been escalated to a DNO, who will re-review and get back to you.',
}

const isTerminal = (status) =>
  [
    'Auto-Approved',
    'DNO Approved',
    'DNO Approved with Limit',
    'Further Information Required',
    'Commissioning Accepted',
    'Failed',
    'Failed - Too Large',
    'Failed - Invalid Cut-Out Image',
    'Image Scan Failed',
  ].includes(status)

const getStep = (status) => {
  return status === 'draft' ? 0 : isTerminal(status) ? 2 : 1
}

const StepInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

const StepContent = ({ title, selectedApplication, index }) => {
  const step = getStep(selectedApplication.status)

  return (
    <StepInfo>
      {title}
      {step === 0 && index === 0 && (
        <StyledChip label={selectedApplication?.created_at.split('T')[0]} variant="outlined" size="small" />
      )}
      {step === 1 && index === 1 && (
        <Tooltip title={STATUS_TOOLTIP?.[selectedApplication.status]}>
          <StyledChip label={selectedApplication.status} variant="default" size="small" color="info" />
        </Tooltip>
      )}
      {step === 2 && index === 2 && (
        <Tooltip title={STATUS_TOOLTIP?.[selectedApplication.status]}>
          <StyledChip
            label={selectedApplication.status}
            variant="default"
            size="small"
            color={selectedApplication.status.includes('Failed') ? 'error' : 'success'}
          />
        </Tooltip>
      )}
    </StepInfo>
  )
}

const ENACta: React.FC<PropTypes> = ({ project }) => {
  const orgId = useSelector(authSelectors.getOrgId)
  const [showDialog, setShowDialog] = useState(false)
  const [submittedApplications, setSubmittedApplications] = useState<any[]>([])
  const [selectedApplication, setSelectedApplication] = useState<any>(null)
  const [isNewApplication, setIsnewApplication] = useState(false)

  const openNewApplication = () => {
    setIsnewApplication(true)
    setShowDialog(true)
  }

  useEffect(() => {
    retrieveApplicationsData().then((r) => {
      setSubmittedApplications(r)
      if (Array.isArray(r) && r.length) {
        setSelectedApplication(r[0])
      }
    })
  }, [showDialog])

  const retrieveApplicationsData = async () => {
    try {
      const url = `${window.API_ROOT}/api/orgs/${orgId}/projects/${project.id}/get_ena_applications_with_status/`
      const res = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + appStorage.getToken(),
        },
      })
      if (!res.ok) {
        const errorDetails = await res.text()
        console.error(`Request failed with status: ${res.status} - ${res.statusText}, Details: ${errorDetails}`)
        throw new Error(`Bad request: ${errorDetails}`)
      }
      const contentType = res.headers.get('content-type')
      if (contentType && contentType.includes('application/json')) {
        const data = await res.json()
        return data.applications
      } else {
        throw new Error('Unexpected response format, expected JSON.')
      }
    } catch (e) {
      console.error('Error retrieveApplicationData application:', e)
    }
  }

  const steps = ['Draft Started', 'In Review', 'Outcome']

  const editDraft = () => {
    setIsnewApplication(false)
    setShowDialog(true)
  }

  return (
    <Wrapper style={{ marginTop: 15 }}>
      <IntegrationRow
        title={'ENA Connect Direct'}
        description={doNotTranslate(
          'Effortlessly submit connection applications and manage documentation with our ENA Connect Direct integration. Simplify your workflow, accelerate your project timelines, and ensure compliance with grid connection requirements, all in one solution.	'
        )}
        btnLabel={doNotTranslate('Send Application to ENA')}
        onClick={openNewApplication}
      />
      {Array.isArray(submittedApplications) && Boolean(submittedApplications.length) && (
        <Wrapper style={{ marginTop: 5 }}>
          <Header>ENA Connect Direct Applications Submitted</Header>

          <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
            <Select
              value={selectedApplication || submittedApplications[0]}
              onChange={(e: any) => {
                setSelectedApplication(e.target.value)
              }}
              id="ena-select-draft"
              fullWidth
            >
              {submittedApplications.map((app, i) => (
                <MenuItem key={app?.created_at} value={app}>
                  {app.status === 'draft'
                    ? `[Draft] ${formatDate(app?.created_at, true)}`
                    : `[Application] ${formatDate(app?.created_at, true)} - ID: ${app?.application_id}`}
                </MenuItem>
              ))}
            </Select>
            <IconButton onClick={editDraft} disabled={selectedApplication?.status !== 'draft'}>
              <EditOutlineIcon variant={2} />
            </IconButton>
          </div>
        </Wrapper>
      )}
      {selectedApplication && (
        <>
          <Stepper
            id={selectedApplication?.created_at + '-stepper'}
            activeStep={getStep(selectedApplication.status)}
            alternativeLabel
          >
            {steps.map((label, index) => {
              const activeStep = getStep(selectedApplication.status)
              return (
                <Step key={index}>
                  <StepLabel key={label}>
                    <div>
                      <StepContent
                        key={activeStep}
                        index={index}
                        title={label}
                        selectedApplication={selectedApplication}
                      />
                    </div>
                  </StepLabel>
                </Step>
              )
            })}
          </Stepper>
        </>
      )}

      {showDialog && (
        <EnaDialog
          isOpen={showDialog}
          onClose={() => setShowDialog(false)}
          existingAppInfo={isNewApplication ? null : selectedApplication}
        />
      )}
    </Wrapper>
  )
}

export default ENACta

export const StyledChip = styled(Chip)(({ theme }) => ({
  minHeight: 'fit-content',
  width: 'fit-content',
  '& span': {
    whiteSpace: 'normal',
  },
  cursor: 'help',
}))

export const WrapperMargin = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  marginTop: 15,
}))

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
}))

export const Header = styled('div')(({ theme }) => ({
  fontSize: 14,
  margin: 0,
  fontWeight: 600,
}))

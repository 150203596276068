import { SystemContext } from 'Designer/designRules/types'
import { NonModuleComponentId } from 'types/associatedComponents'
import { NonModuleComponentTypes } from 'types/selectComponent'
import { EffectFactory } from '.'
import { activateAddAndAdjustQuantity, getComponentQuantityInSystem } from '../utils'

export type AdjustComponentQuantitiesType = {
  type: 'adjust-component-quantities'
  components: NonModuleComponentId[]

  minQuantity?: number
  maxQuantity?: number

  noNotifications?: boolean
  noActivate?: boolean
}

export const effect_adjustComponentQuantities: EffectFactory<AdjustComponentQuantitiesType> = (def) => {
  return {
    execute: async (context: SystemContext) => {
      for (const component of def.components)
        adjustComponentQuantities(component.code, component.componentType, context, def)
    },
  }
}

// This calculates the quantity adjustment required,
// then activates the component if required
// Then adds, removes or adjusts the quantity of the component
// to match quantityAdjustment
const adjustComponentQuantities = (
  code: string,
  type: NonModuleComponentTypes,
  context: SystemContext,
  def: AdjustComponentQuantitiesType
) => {
  let quantityAdjustment: number
  if (def.minQuantity !== undefined || def.maxQuantity !== undefined) {
    const system = context.system
    const currentQuantity = getComponentQuantityInSystem(system, type, code)
    quantityAdjustment = 0
    if (def.maxQuantity !== undefined && currentQuantity > def.maxQuantity) {
      // maxQuantity is 0 if we want to remove all
      quantityAdjustment = def.maxQuantity - currentQuantity
    } else if (def.minQuantity !== undefined && currentQuantity < def.minQuantity) {
      quantityAdjustment = def.minQuantity - currentQuantity
    } else {
      if (window.debugDesignRules)
        console.debug(
          `\tDesignRules: Aborting adjust-component-quantities, quantities satisfied: ${
            currentQuantity + quantityAdjustment
          } <= ${currentQuantity}, code: ${code}`
        )
      return
    }
  } else {
    quantityAdjustment = 1
  }

  activateAddAndAdjustQuantity(code, type, context, quantityAdjustment, {
    noNotifications: def.noNotifications,
    noActivate: def.noActivate,
  })
}

// @ts-nocheck
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import AddIcon from '@material-ui/icons/AddOutlined'
import HistoryIcon from '@material-ui/icons/HistoryOutlined'
import { permissionsSelectors } from 'ducks/permissions'
import Button from 'elements/button/Button'
import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { PaginationComp, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { MaterialUIWidthType } from 'types/misc'
import { ProjectType, StageActionEventType } from 'types/projects'
import composeOSResourceFetchPath from 'util/fetch/composeOSResourceFetchPath'
import CRUDRecordDialog from '../../common/CRUDRecordDialog'
import IncompleteEventForm from '../projectManagement/editEventDialog/IncompleteEventForm'
import AddNoteDialogFormContent from './AddNoteDialogFormContent'
import ProjectHistoryEvent from './ProjectHistoryEvent'
import ProjectHistoryNavBar from './ProjectHistoryNavBar'

const restClientInstance = restClient(window.API_ROOT + '/api')

const useStyles = makeStyles((theme) => ({
  content: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down(330)]: {
      flexDirection: 'column',
      flexWrap: 'wrap',
    },
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down(330)]: {
      marginTop: 16,
      justifyContent: 'space-between',
    },
  },
  buttonWrapper: {
    margin: '0 5px',
  },
  iconTitleWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  headingTitle: {
    margin: '0 10px',
  },
  descriptionWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  updateStageTextWrapper: {
    minWidth: '200px',
  },
  updateStageButtonWrapper: {
    marginLeft: '20px',
  },
  disabled: {
    pointerEvents: 'none',
    cursor: 'not-allowed',
  },
  accordionDetails: {},
}))

const CUSTOMER_EVENT_IDS = [1, 2, 3, 4, 49, 50, 51, 52, 53, 55]

type PropTypes = {
  width: MaterialUIWidthType
}

const getMostRecentModifiedDate = (events: StageActionEventType[]) => {
  if (!events || events.length === 0) return null
  let mostRecentModifiedData = null
  for (let i = 0; i < events.length; i++) {
    const event = events[i]
    if (mostRecentModifiedData) {
      if (new Date(event.modified_date) > mostRecentModifiedData) {
        mostRecentModifiedData = new Date(event.modified_date)
      }
    } else {
      mostRecentModifiedData = new Date(event.modified_date)
    }
  }
  if (!mostRecentModifiedData) return null
  return mostRecentModifiedData
    .toISOString()
    .replace(/T/, ' ') // replace T with a space
    .replace(/\..+/, '') // delete the dot and everything after
}

const ProjectHistoryAccordion: React.FunctionComponent<PropTypes> = (props) => {
  const translate = useTranslate()
  const orgId = useSelector((state: any) => (state.auth ? state.auth.org_id : null))
  const classes = useStyles()
  const form = useForm()
  const { allowView, allowCreate, allowEdit, allowDelete } = useSelector(
    permissionsSelectors.getProjectPermissionByKey('manage_notes_activities_actions')
  )

  const project = form.getState().values as ProjectType
  const eventsDataFromForm = form.getState().values?.events_data as StageActionEventType[]
  const [mostRecentEventModifiedDate, setMostRecentEventModifiedDate] = useState(
    getMostRecentModifiedDate(eventsDataFromForm)
  )
  const [newEventsDataSinceLastUpdate, updateNewEventsData] = useState<StageActionEventType[]>([])

  const disabled = !Boolean(project.url)
  const [total, setTotal] = useState<number>(0)
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0)
  const [eventsToDisplay, setEventsToDisplay] = useState<StageActionEventType[]>([])
  const [showNoteDialog, setShowNoteDialog] = useState<boolean>(false)
  const [showActivityDialog, setShowActivityDialog] = useState<boolean>(false)
  const [forceRerenderToggle, setForceRerenderToggle] = useState<boolean>(false)
  const [page, setPage] = React.useState<number>(1)
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(5)

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (newPerPage: number) => {
    setRowsPerPage(newPerPage)
    setPage(0)
  }

  const getEventsToDisplay = useCallback(
    (eventsData): StageActionEventType[] => {
      if (!eventsData || eventsData.length === 0) return []
      let totalEventsToDisplay: StageActionEventType[] = []
      const allHistoricalEvents: StageActionEventType[] = eventsData?.filter(
        (e: StageActionEventType) => !e.is_planned && !e.is_archived
      )

      if (activeTabIndex === 0) {
        totalEventsToDisplay = allHistoricalEvents
      } else if (activeTabIndex === 1) {
        totalEventsToDisplay = allHistoricalEvents.filter((e) => !!e.action_id)
      } else if (activeTabIndex === 2) {
        totalEventsToDisplay = allHistoricalEvents.filter((e) => CUSTOMER_EVENT_IDS.includes(e.event_type_id))
      } else if (activeTabIndex === 3) {
        totalEventsToDisplay = eventsData
      }

      setTotal(totalEventsToDisplay.length)
      const sliceStart = rowsPerPage * (page - 1)
      const sliceEnd = sliceStart + rowsPerPage
      return totalEventsToDisplay.slice(sliceStart, sliceEnd)
    },
    [activeTabIndex, page, rowsPerPage]
  )

  useEffect(() => {
    const eventsData = _.uniqBy([...newEventsDataSinceLastUpdate, ...(eventsDataFromForm || [])], 'id')
    //@ts-ignore
    eventsData.sort((a, b) => new Date(b.modified_date) - new Date(a.modified_date))

    setEventsToDisplay(getEventsToDisplay(eventsData))
  }, [newEventsDataSinceLastUpdate, eventsDataFromForm, activeTabIndex, page, rowsPerPage])

  const fetchNewEvents = useCallback(() => {
    if (!orgId) {
      console.error('unable to fetch events, orgId is missing')
      return
    }
    if (!project.id) {
      console.error('unable to fetch events, project.id is missing')
      return
    }
    restClientInstance('CUSTOM_GET', 'custom', {
      url: composeOSResourceFetchPath({
        orgId,
        resource: 'events',
        params: {
          project: project.id,
        },
        optionalParams: {
          modified_date_gte: mostRecentEventModifiedDate,
        },
      }),
    })
      .then((response: any) => {
        if (response?.data) {
          const newEventsData = response.data as StageActionEventType[]
          const newModifiedDate = getMostRecentModifiedDate(newEventsData)
          if (newModifiedDate) {
            setMostRecentEventModifiedDate(newModifiedDate)
          }
          updateNewEventsData(newEventsData)
        }
      })
      .catch((error: any) => {
        console.log(error.message)
      })
  }, [mostRecentEventModifiedDate, eventsDataFromForm, orgId])

  useEffect(() => {
    //fetch new events when:
    //1. add new event to form
    //2. land on manage page
    //3. after saving form
    if (form.getState().submitting === false) {
      fetchNewEvents()
    }
  }, [form.getState().submitting])

  const addNote = () => setShowNoteDialog(true)
  const recordActivity = () => setShowActivityDialog(true)

  const addNewEventToForm = (record: any) => {
    fetchNewEvents()
  }

  if (!allowView) {
    return null
  }
  return (
    <Accordion key="history" expanded={true}>
      <AccordionSummary
        aria-controls="expansion-content-history"
        id="expansion-header-history"
        classes={{ content: classes.content }}
      >
        <div className={classes.headerWrapper}>
          <div className={classes.iconTitleWrapper}>
            <HistoryIcon />
            <span className={classes.headingTitle}>{translate('Project History')}</span>
          </div>
          <div className={classes.buttonsWrapper}>
            <div className={classes.buttonWrapper}>
              <Button
                startIcon={<AddIcon />}
                onClick={addNote}
                variant="contained"
                color="default"
                size="small"
                label={'Note'}
                disabled={disabled || !allowCreate}
              />
            </div>
            <div className={classes.buttonWrapper}>
              <Button
                startIcon={<AddIcon />}
                onClick={recordActivity}
                variant="contained"
                color="default"
                size="small"
                label={props.width === 'xs' ? 'Activity' : 'Record Activity'}
                disabled={disabled || !allowCreate}
              />
            </div>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails classes={{ root: allowEdit ? classes.accordionDetails : classes.disabled }}>
        <Grid container spacing={3}>
          <ProjectHistoryNavBar activeTabIndex={activeTabIndex} setActiveTabIndex={setActiveTabIndex} />
          {eventsToDisplay?.map((eventToDisplay) => (
            <ProjectHistoryEvent
              key={eventToDisplay.id}
              record={eventToDisplay}
              onEdit={() => {
                setForceRerenderToggle(!forceRerenderToggle)
                fetchNewEvents()
              }}
            />
          ))}

          <PaginationComp
            style={{ width: '100%' }}
            total={total}
            page={page}
            loading={false}
            perPage={rowsPerPage}
            setPage={handleChangePage}
            setPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </AccordionDetails>
      {showNoteDialog && (
        <CRUDRecordDialog
          isOpen={showNoteDialog}
          record={{ project: project.url, event_type_id: 31 }}
          resource="events"
          basePath="/events/create"
          onDismiss={() => setShowNoteDialog(false)}
          formContent={<AddNoteDialogFormContent />}
          notificationOnSuccess={true}
          customSuccessNotificationText={translate('Your note has been saved')}
          updateFormValsFn={addNewEventToForm}
          dialogTitle={translate('Add Note')}
        />
      )}
      {showActivityDialog && (
        <CRUDRecordDialog
          isOpen={showActivityDialog}
          record={{ project: project.url }}
          resource="events"
          basePath="/events/create"
          onDismiss={() => setShowActivityDialog(false)}
          formContent={<IncompleteEventForm showNotifyTeamMembers={true} />}
          notificationOnSuccess={true}
          customSuccessNotificationText={translate('Your activity has been saved')}
          updateFormValsFn={addNewEventToForm}
          dialogTitle={translate('Add Activity')}
        />
      )}
    </Accordion>
  )
}

export default withWidth()(ProjectHistoryAccordion)

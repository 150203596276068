export const USER_ROLE_TYPE = 0
export const ORG_ROLE_TYPE = 1

export type CUSTOM_ROLE_TYPES = typeof USER_ROLE_TYPE | typeof ORG_ROLE_TYPE

export interface CustomRoleType {
  title: string
  permissions: string // json > CustomRolePermissionsType

  role_type: CUSTOM_ROLE_TYPES

  access_projects: boolean
  access_contacts: boolean
  access_transactions: boolean
  access_files: boolean
  access_activities: boolean
}

export interface CustomRolePermissionsType {
  control: Record<string, CustomRolePermissionRecord>
  project: Record<string, CustomRolePermissionRecord>
}

export interface CustomRolePermissionRecord {
  disabled: CustomRolePermissionKey[]
  label: string
  resource: string
}

export type CustomRolePermissionKey = 'create' | 'delete' | 'edit' | 'view'

import AccountBalanceIcon from '@material-ui/icons/AccountBalanceOutlined'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import { useNotify } from 'ra-core'
import React, { useEffect, useState } from 'react'
import restClient from 'restClient'
import { PaymentOptionDataType } from 'types/paymentOptions'
import PhoenixCalculatorDialog from './PhoenixCalculatorDialog'
import { getIsPhoenixV2 } from './util'

const restClientInstance = restClient(window.API_ROOT + '/api')
export const PHOENIX_SCROLL_TARGET = 'phoenix-banner-wrapper'

type PropTypes = {
  phoenixPaymentOption: PaymentOptionDataType
  projectId: number
  systemUuid: string
  selectedPaymentOptionId: number
  orgId: number
}

const PhoenixBanner: React.FC<PropTypes> = (props) => {
  const [calculatorURL, setCalculatorURL] = useState<string | undefined>(undefined)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [hideCalculator, setHideCalculator] = useState<boolean>(false)

  const notify = useNotify()

  useEffect(() => {
    if (calculatorURL) {
      setShowDialog(true)
    }
  }, [calculatorURL])

  useEffect(() => {
    if (props.phoenixPaymentOption?.integration_external_reference) {
      setHideCalculator(getIsPhoenixV2(props.phoenixPaymentOption.integration_external_reference))
    }
  }, [props.phoenixPaymentOption?.integration_external_reference])

  const closeDialog = () => {
    setShowDialog(false)
    setCalculatorURL(undefined)
  }

  const getURL = () => {
    if (!hideCalculator) {
      restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${props.orgId}/projects/${props.projectId}/systems/${props.systemUuid}/${props.selectedPaymentOptionId}/${props.phoenixPaymentOption.id}/phoenix/calculator_url/?`,
      })
        .then((res) => {
          if (res.data.url) setCalculatorURL(res.data.url)
        })
        .catch((err) => {
          notify('Unable to open calculator', 'warning')
        })
    }
  }

  const imgURL = props.phoenixPaymentOption.banner_image_url

  return (
    <>
      <div
        style={{ width: '100%', cursor: hideCalculator ? undefined : 'pointer' }}
        onClick={getURL}
        id={PHOENIX_SCROLL_TARGET}
      >
        <img
          width="100%"
          src={imgURL}
          style={{
            boxShadow: '3px 3px rgba(132, 76, 134, 0.3)',
            marginBottom: '10px',
            border: '1px solid rgb(132, 76, 134)',
          }}
        />
      </div>
      <p className="small">{props.phoenixPaymentOption.description}</p>
      {!hideCalculator && (
        <>
          {showDialog && calculatorURL && (
            <PhoenixCalculatorDialog
              url={calculatorURL}
              onClose={closeDialog}
              pricePayable={props?.phoenixPaymentOption?.pricing?.system_price_payable}
            />
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <ProUXButton
              startIcon={<AccountBalanceIcon fontSize="large" />}
              type="primary"
              label="View Finance Options"
              onClick={getURL}
              style={{ backgroundColor: 'rgb(132, 76, 134)' }}
              labelStyle={{ color: '#fff' }}
            />
          </div>
        </>
      )}
      <br />
    </>
  )
}
export default PhoenixBanner

// @ts-nocheck
import { logAmplitudeEvent } from 'amplitude/amplitude'
import restClient from 'restClient'
import appStorage from 'storage/appStorage'
import { reloadEntireApp } from 'util/misc'

//this assumes that the url a user is dropped off to after oauth follows this pattern app.opensolar.com/oauth_result/[partner name]
//Docusign doesn't let us use hashes in redirect URI's, and React-admin only lets us navigate to a page if the path is after a hash (eg ...com/#/page_name)
//So we bring the user back to us via a path like ...com/oauth_result/docusign, which RA changes to ...com/oauth_result/docusign/#/projects
//this function is responsible for noticing that the pre-hash route has an oauth partner in it and we can then handle things accordingly
export const handleOauthResultIfFound = (roleId: number, markAsProcessing: () => void) => {
  //get entire path after oauth_result
  const path = window.location.hash.substring(window.location.hash.indexOf('#') + 2)
  const params = new URLSearchParams(path.substring(path.indexOf('?')))
  //then parse out the partner name
  const partnerName = params.get('partner')
  //if it matches an expected partner name with a defined handler, run the handler and mark as processing to avoid dupe requests
  if (partnerName && resultHandlers[partnerName]) {
    markAsProcessing()
    resultHandlers[partnerName](roleId)
  }
}

const handleDocusignResult = (roleId: number) => {
  const orgId = appStorage.getOrgId()
  const path = window.location.hash.substring(window.location.hash.indexOf('#') + 2)
  const params = new URLSearchParams(path.substring(path.indexOf('?')))
  const code = params.get('code')
  /*
        three things to do here:
        1.) call out to server with code to finish oAuth
        2.) redirect user to the api keys page
        3.) show success banner to let them know all is good
    */
  const restClientInstance = restClient(window.API_ROOT + '/api')
  const forceSandbox: boolean = !!appStorage.getBool('docusign_force_sandbox')
  const url = forceSandbox ? 'orgs/' + orgId + '/get_docusign_token/sandbox' : 'orgs/' + orgId + '/get_docusign_token/'
  restClientInstance('CUSTOM_POST', 'custom', {
    url,
    data: { code },
  })
    .then((response: any) => {
      if (response && response.data && response.data.success) {
        logAmplitudeEvent('docusign_account_connected', {})
        window.location.href = getRedirectURL('docusign', orgId, true)
        //need to reload since this the browser won't trigger it automtically on a hash change
        reloadEntireApp()
      }
    })
    .catch((error: any) => {
      logAmplitudeEvent('docusign_account_connection_error', {})
      window.location.href = getRedirectURL('docusign', orgId, false)
      if (error?.body?.message) {
        alert('We were unable to connect your Docusign account : ' + error.body.message)
        reloadEntireApp()
      } else {
        //need to reload since this the browser won't trigger it automtically on a hash change
        reloadEntireApp()
      }
    })
}

const handleBrighteResult = (roleId: number) => {
  const orgId = appStorage.getOrgId()
  const path = window.location.hash.substring(window.location.hash.indexOf('#') + 2)
  const params = new URLSearchParams(path.substring(path.indexOf('?')))
  const code = params.get('code')
  const state = params.get('state')
  const roleIdToUse = state && state.length > 0 && state !== 'null' ? state : roleId
  const closeOnResolution = params.get('close_on_resolution')
  const bringToRolePage = params.get('go_to_role')
  /*
        three things to do here:
        1.) call out to server with code to finish oAuth
        2.) redirect user to the api keys page
        3.) show success banner to let them know all is good
    */
  const restClientInstance = restClient(window.API_ROOT + '/api')
  const url = 'orgs/' + orgId + '/roles/' + roleIdToUse + '/brighte/oauth_code/'
  restClientInstance('CUSTOM_POST', 'custom', {
    url,
    data: { code, state },
  })
    .then((response: any) => {
      appStorage.clear('brighte_vendor_mismatch')
      appStorage.clear('brighte_duplicate_user')
      if (response && response.data && response.data.success) {
        logAmplitudeEvent('brighte_account_connected', { page: 'api_keys' })
        if (closeOnResolution) {
          window.close()
        } else {
          if (bringToRolePage) {
            window.location.href = window.location.origin + '/#/roles/' + state + '?show_brighte_success=true'
          } else {
            window.location.href = getRedirectURL('brighte', orgId, true, response.data.products_added)
            //need to reload since this the browser won't trigger it automtically on a hash change
          }
          reloadEntireApp()
        }
      }
    })
    .catch((error: any) => {
      console.log('err', error)
      logAmplitudeEvent('brighte_oauth_error', { page: 'post_oauth' })
      if (closeOnResolution) {
        if (error?.body?.message_code === 'vendor_id') {
          appStorage.setBool('brighte_vendor_mismatch', true)
        } else if (error?.body?.message_code === 'duplicate_user') {
          appStorage.setBool('brighte_duplicate_user', true)
        }
        window.close()
      } else if (bringToRolePage) {
        if (error?.body?.message_code === 'vendor_id') {
          window.location.href = window.location.origin + '/#/roles/' + state + '?show_brighte_error=vendor_id'
        } else if (error?.body?.message_code === 'duplicate_user') {
          window.location.href = window.location.origin + '/#/roles/' + state + '?show_brighte_error=duplicate_user'
        } else {
          window.location.href = window.location.origin + '/#/roles/' + state + '?show_brighte_error=generic'
        }
      } else {
        window.location.href = getRedirectURL('brighte', orgId, false, 0, error?.body?.message)
        //need to reload since this the browser won't trigger it automtically on a hash change
        reloadEntireApp()
      }
    })
}

type ResultHandlersType = {
  [key: string]: (roleId: number) => void
}
const resultHandlers: ResultHandlersType = {
  docusign: handleDocusignResult,
  brighte: handleBrighteResult,
}

//we do a hard redirect instead of just using history because I want to strip the code out of the url and the hash history makes that hard (maybe not possible?)
const getRedirectURL = (
  partner: 'docusign' | 'brighte',
  orgId: number,
  isSuccess: boolean,
  products_added = undefined,
  errorMsg?: string | undefined
) => {
  if (partner === 'docusign')
    return `${window.location.origin}/#/external_api_keys/${orgId}/${isSuccess ? 'success' : 'error'}/${partner}`
  else {
    let url = `/#/payment_options?result=${isSuccess ? 'success' : 'error'}&partner=Brighte`
    if (products_added) {
      url += `&products_added=${products_added}`
    }
    if (errorMsg) {
      url += `&oauth_error=${errorMsg}`
    }
    return url
  }
}

export const SELECT_COMPONENT_DIALOG = 'SELECT_COMPONENT_DIALOG'
export const SELECT_COMPONENT = 'SELECT_COMPONENT'
export const SELECT_COMPONENT_LOADING = 'SELECT_COMPONENT_LOADING'
export const SELECT_COMPONENT_FAILURE = 'SELECT_COMPONENT_FAILURE'
export const SELECT_COMPONENT_SUCCESS = 'SELECT_COMPONENT_SUCCESS'

export const setSelectComponentDialog = (
  isOpen,
  orgId,
  projectId,
  targetUuids,
  componentType,
  filtersToOverride,
  slotKey
) => ({
  type: SELECT_COMPONENT_DIALOG,
  payload: {
    isOpen,
    orgId,
    projectId,
    targetUuids,
    componentType,
    filtersToOverride,
    slotKey,
  },
})

export default (previousState = {}, { type, meta, payload, requestPayload }) => {
  if (type === '@@router/LOCATION_CHANGE') {
    // Clear all settings on change of location

    if (payload.location.pathname.indexOf('/design') === -1) {
      previousState = {
        ...previousState,
        isOpen: false,
        orgId: null,
        projectId: null,
        targetUuids: null,
        componentType: null,
        filtersToOverride: [],
        slotKey: null,
      }
    }
  }

  if (type === SELECT_COMPONENT_DIALOG) {
    var version = previousState && previousState.version ? previousState.version + 1 : 1

    try {
      if (payload.isOpen === true) {
        var enablePluginsOverride = true
        window.editor && window.editor.interactive(false, undefined, enablePluginsOverride)
        window.MapHelper && window.MapHelper.interactive(false)
      } else {
        window.editor && window.editor.interactive(true)
        window.MapHelper && window.MapHelper.interactive(true)
      }
    } catch (err) {}

    previousState = {
      ...previousState,
      ...{
        isOpen: payload.isOpen,
        orgId: payload.orgId,
        projectId: payload.projectId,
        targetUuids: payload.targetUuids,
        componentType: payload.componentType,
        filtersToOverride: payload.filtersToOverride,
        slotKey: payload.slotKey || null,
        version: version,
      },
    }
  }

  if (type === SELECT_COMPONENT_SUCCESS) {
    previousState = {
      status: 'success',
    }
  }

  if (type === SELECT_COMPONENT_LOADING) {
    previousState = {
      status: 'loading',
    }
  }

  if (type === SELECT_COMPONENT_FAILURE) {
    previousState = {
      status: 'failure',
    }
  }

  return previousState
}

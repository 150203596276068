import type LineItemType from 'pages/ordering/OrderLineItem'

import { getOrderCost } from 'pages/ordering/utils'
import HdmService from 'services/hdm/HdmService'
import { HdmOrderResultType, HdmOrderType, HdmShippingAddressType } from 'services/hdm/type'
import { HdmOrderState } from './HdmCheckoutProvider'

class HdmCheckoutPresenter {
  private hdmService: HdmService
  private setShippingAddress: React.Dispatch<React.SetStateAction<HdmShippingAddressType>>
  private setOrderState: React.Dispatch<React.SetStateAction<HdmOrderState>>

  constructor(
    hdmService: HdmService,
    setShippingAddress: React.Dispatch<React.SetStateAction<HdmShippingAddressType>>,
    setOrderState: React.Dispatch<React.SetStateAction<HdmOrderState>>
  ) {
    this.hdmService = hdmService
    this.setShippingAddress = setShippingAddress
    this.setOrderState = setOrderState
  }

  updateShippingAddress = (shippingAddress: HdmShippingAddressType) => {
    this.setShippingAddress(shippingAddress)
  }

  updateOrderState = <T extends keyof HdmOrderState>({ key, value }: { key: T; value: HdmOrderState[T] }) => {
    this.setOrderState((state: HdmOrderState) => ({
      ...state,
      [key]: value,
    }))
  }

  validateShippingAddress = (shippingAddress: HdmShippingAddressType) => {
    if (
      !shippingAddress.companyName ||
      !shippingAddress.town ||
      !shippingAddress.county ||
      !shippingAddress.country ||
      !shippingAddress.postCode
    ) {
      return false
    }
    return true
  }

  placeOrder = async (lineItems: LineItemType[], shippingAddress: HdmShippingAddressType) => {
    const order: HdmOrderType = {
      items: lineItems
        .filter((lineItem) => !!lineItem.variantId)
        .map((lineItem) => ({
          productCode: lineItem.variantId || '',
          quantity: lineItem.quantity,
        })),
      shippingAddress,
    }
    const orderResult = await this.hdmService.placeOrder(order)
    if (orderResult?.status_code && ![200, 202].includes(orderResult['status_code']) && orderResult !== undefined) {
      const orderError = orderResult as HdmOrderResultType
      console.error(orderError.reason)
      throw new Error(orderError?.content?.error)
    }
    return orderResult
  }

  placeOrderFromCredit = async (lineItems: LineItemType[], shippingAddress: HdmShippingAddressType) => {
    const order: HdmOrderType = {
      items: lineItems
        .filter((lineItem) => !!lineItem.variantId)
        .map((lineItem) => ({
          productCode: lineItem.variantId || '',
          quantity: lineItem.quantity,
        })),
      shippingAddress,
    }

    const orderResult = await this.hdmService.placeOrderFromCredit(order)

    if (orderResult?.status_code && ![200, 202].includes(orderResult['status_code']) && orderResult !== undefined) {
      const orderError = orderResult as HdmOrderResultType
      console.error(orderError.reason)
      throw new Error(orderError?.content?.error)
    }
    return orderResult
  }

  getOrderCost = (lineItems: LineItemType[]) => {
    const itemsCost = !!lineItems ? getOrderCost(lineItems) : 0
    const tax = (itemsCost * 20) / 100
    return {
      itemsCost,
      tax,
    }
  }
}

export default HdmCheckoutPresenter

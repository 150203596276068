import { CRUD_CHANGE_LIST_PARAMS, CRUD_CLEAR_LIST_PARAMS, } from '../../../../actions/listActions';
var defaultListParams = {
    sort: null,
    order: null,
    page: 1,
    perPage: null,
    filter: {},
};
var paramsReducer = function (previousState, action) {
    if (previousState === void 0) { previousState = defaultListParams; }
    switch (action.type) {
        case CRUD_CHANGE_LIST_PARAMS:
            return action.payload;
        case CRUD_CLEAR_LIST_PARAMS:
            return defaultListParams;
        default:
            return previousState;
    }
};
export default paramsReducer;

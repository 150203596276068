import { default as lodash, default as _ } from 'lodash'
import { useMemo, useRef } from 'react'
import { ALL_FIELDS, FIELDS_REQUIRE_RECALC } from './fields'
import { useProjectFormFormatter } from './formatter'

const isBlank = (val) => {
  return val === undefined || val === null || val === ''
}

//don't treat any contacts_new fields as dirty if they are empty. For UI purposes we add elements to this array with empty fields
const sanitizeDirtyFields = (dirtyFields, formState) => {
  Object.keys(dirtyFields)?.forEach((fieldName) => {
    const fieldVal = _.get(formState.formState.values, fieldName)
    if (fieldName.includes('contacts_new')) {
      if (isBlank(fieldVal)) {
        delete dirtyFields[fieldName]
      }
      // the array itself shouldn't be marked as dirty, wait until a field within an element of the array is dirty
      if (fieldName === 'contacts_new') delete dirtyFields['contacts_new']
    } else {
      if (fieldName === 'search_address') delete dirtyFields[fieldName]
      const initialValue = _.get(formState.formState.initialValues, fieldName)
      if (isBlank(fieldVal) && isBlank(initialValue)) {
        delete dirtyFields[fieldName]
      }
    }
  })
  return dirtyFields
}

export const useProjectFormMutators = () => {
  const projectFormFormatter = useProjectFormFormatter()
  const mutators = {
    recordFormDirtyFields: ([section], state, utils) => {
      //record dirty fields in current page
      //To Do: make it robust, categorize dirty fields by sections
      const dirtyFieldsInCurrentSection = state.lastFormState.dirtyFields || {}
      const dirtyFieldsFromOtherSections = state.formState.recordedDirtyFields || {}
      state.formState.recordedDirtyFields = Object.assign({}, dirtyFieldsFromOtherSections, dirtyFieldsInCurrentSection)
    },
    getFormDirtyFields: (fields, state, utils) => {
      //return all dirty fields across whole project sections since last save,
      const dirtyFieldsInCurrentSection = state.lastFormState.dirtyFields || {}
      const dirtyFieldsFromOtherSections = state.formState.recordedDirtyFields || {}
      const dirtyFields = Object.assign({}, dirtyFieldsFromOtherSections, dirtyFieldsInCurrentSection)
      const sanitizedDirtyFields = sanitizeDirtyFields(dirtyFields, state)
      return Object.keys(sanitizedDirtyFields)
    },
    resetFormDirtyFields: (fields, state, utils) => {
      //clear dirty fields after save
      state.formState.recordedDirtyFields = {}
    },
    markFieldAsDirty: ([field], state, utils) => {
      //Explicitly setting an input to dirty
      if (state.formState.recordedDirtyFields) {
        state.formState.recordedDirtyFields[field] = true
      } else {
        state.formState.recordedDirtyFields = { [field]: true }
      }
    },
    markFieldAsClean: ([field], state) => {
      // Explicitly setting an input as clean
      if (!state.formState.recordedDirtyFields) return
      delete state.formState.recordedDirtyFields[field]
    },
    isFormPristine: (fields, state, utils) => {
      // return true if form value exactly same as the project value stored in database
      if (state.formState.recordedDirtyFields) {
        return state.formState.pristine && Object.keys(state.formState.recordedDirtyFields).length === 0
      }
      return state.formState.pristine
    },
    getUnsavedChangeAffectSystemCalcs: (fields, state, utils) => {
      //get all dirty fields that may affect system calcs
      const dirtyFieldsInCurrentSection = state.lastFormState.dirtyFields || {}
      const dirtyFieldsFromOtherSections = state.formState.recordedDirtyFields || {}
      const dirtyFields = Object.assign({}, dirtyFieldsFromOtherSections, dirtyFieldsInCurrentSection)
      const unsavedChanges = projectFormFormatter(state.formState.values, Object.keys(dirtyFields))
      return _.pick(unsavedChanges, FIELDS_REQUIRE_RECALC)
    },
    requireSystemCalcs: ([status], state, utils) => {
      //set to true will trigger system re-calc when studio page mounting again
      if (status !== state.formState.requireSystemCalcs) {
        state.formState.requireSystemCalcs = !!status
      }
    },
    isUnsavedProjectDataRequireSystemCalcs: (fields, state, utils) => {
      // return true if calculation result outdated in unsaved design data
      return !!state.formState.requireSystemCalcs
    },
    clearSubmitErrorsByFieldName: ([field], state, utils) => {
      if (state.formState.submitErrors[field]) {
        delete state.formState.submitErrors[field]
      }
    },
    touchInvalidFields: (args, state) => {
      const { errors, submitErrors } = state.formState
      for (const name in errors) {
        const field = state.fields[name]
        if (field) {
          field.touched = true
        }
      }
      for (const name in submitErrors) {
        if (typeof submitErrors[name] === 'string') {
          const field = state.fields[name]
          if (field) {
            field.touched = true
          }
        } else if (!!submitErrors[name]) {
          //handle nested error message
          const flattenedErrorMessages = window.Utils.flatErrorMessages({ [name]: submitErrors[name] })
          for (const name in flattenedErrorMessages) {
            const field = state.fields[name]
            if (field) {
              field.touched = true
            }
          }
        }
      }
    },
    setSubmitErrors: ([value], state, utils) => {
      const submitErrors = {}
      for (const key in value) {
        if (ALL_FIELDS.includes(key)) {
          if (typeof value[key] === 'string') {
            submitErrors[key] = value[key]
          } else if (Array.isArray(value[key])) {
            if (typeof value[key][0] === 'string') {
              submitErrors[key] = value[key].join('; ')
            } else {
              //example: contact_news
              submitErrors[key] = value[key]
            }
          } else if (value[key] instanceof Object) {
            submitErrors[key] = value[key]
          }
        }
      }
      state.formState.submitErrors = submitErrors
    },
    updateField: ([field, value], state, utils) => {
      utils.changeValue(state, field, () => value)
    },
    updateFieldSilently: ([field, value], state, utils) => {
      //update both initial values and current form value
      //the field won't treat as dirty by using this method to update
      const initialValues = state.formState.initialValues
      initialValues[field] = value
      utils.changeValue(state, field, () => value)
    },

    // Quiet fields are fields that are not considered dirty and do not update the form state
    setQuietField: ([field, value], state) => {
      if (!state.formState.quietFields) state.formState.quietFields = {}
      state.formState.quietFields[field] = value
    },
    getQuietField: ([field], state) => {
      return state.formState.quietFields?.[field]
    },
    fillQuietFields: ([submitValues, clear = false], state) => {
      const ret = { ...submitValues, ...state.formState.quietFields }
      if (clear) state.formState.quietFields = {}
      return ret
    },
  }

  const latestMutators = useRef(mutators)
  latestMutators.current = mutators

  // This hook always returns the same instance
  // Internally though, this points to the latest mutators
  // This is because react-final-form doesn't accept changes to the function over time
  return useMemo(() => {
    return lodash.transform(latestMutators.current, (result, value, key) => {
      result[key] = (...args) => latestMutators.current[key](...args)
    })
  }, [])
}

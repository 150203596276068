const infoFields = [
  'contacts_new.0.first_name',
  'contacts_new.0.family_name',
  'contacts_new.0.phone',
  'contacts_new.0.email',
  'contacts_new',
  'address',
  'locality',
  'state',
  'zip',
  'county',
  'country_iso2',
  'is_lite',
  'lat',
  'lon',
  'search_address',
  'id',
  'identifier',
  'lead_source',
  'tags',
  'notes',
  'is_residential',
  'business_name',
  'business_identifier',
  'roof_types',
  'roof_type',
  'meter_identifier',
  'number_of_storeys',
  'has_cellular_coverage',
  'wind_region',
  'number_of_phases',
  'power_factor',
  'site_notes',
  'configuration_override',
  'costing_override',
  'contract_date',
  'system_sold',
  'payment_option_sold',
  'installation_date',
  'system_installed',
  'serial_numbers_panels',
  'serial_numbers_inverters',
  'serial_numbers_batteries',
  'greenlancer_project_id',
  'private_files_data',
  'simulate_first_year_only',
  'temperature_min_max',
  'custom_project_info_1',
  'custom_project_info_2',
  'custom_project_info_3',
  'custom_project_info_4',
  'custom_project_info_5',
]

export default infoFields

import React from 'react'
import { useTranslate } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles(
  (theme) => ({
    icon: {
      height: 24,
      width: 20,
      marginRight: 10,
      color: 'inherit',
    },
    title: {
      flexGrow: 1,
      margin: 0,
      fontSize: 16,
      fontWeight: 'normal',
    },
    wrapper: {
      padding: '15px 10px',
      position: 'relative',
      display: 'flex',
      borderRadius: 2,
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
      transition: '0.3s',
      zIndex: 1,
      '&:hover': {
        background: '#F5F5F5',
      },
    },
  }),
  { name: 'OSMenuCategoryItem' }
)

export type CategoryItemPropsType = {
  Icon: React.ReactElement
  title: string
  id: string
  handleClick(): void
  ArrowIconNode?: React.ReactNode
}

const CategoryItem = ({ Icon, title, id, handleClick, ArrowIconNode }: CategoryItemPropsType) => {
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <div id={id} className={classes.wrapper} onClick={handleClick}>
      {React.cloneElement(Icon, { className: classes.icon })}
      <h4 className={classes.title}>{translate(title)} </h4>
      {!!ArrowIconNode && <span>{ArrowIconNode}</span>}
    </div>
  )
}

export default CategoryItem

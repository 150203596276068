import { Divider, Typography } from '@material-ui/core'
import type { OpenSolarThemeType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { clearLineItems } from 'ducks/orderComponents'
import Button from 'elements/button/Button'
import React, { memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useDialogContentStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  container: {
    textAlign: 'center',
    padding: '30px 80px',
  },
  divider: {
    margin: '20px 0',
  },
  buttonWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: 50,
  },
  button: {
    margin: '10px 20px',
  },
}))

const SegenPostCheckoutPage = ({
  portalUrl,
  quoteOrOrderType,
}: {
  portalUrl: string
  quoteOrOrderType: 'order' | 'quote'
}) => {
  const classes = useDialogContentStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    logAmplitudeEvent('hardware_order_placed_success', {
      distributor: 'segen',
      type: quoteOrOrderType,
    })
    return () => {
      // clear cart when pros leaving the postcheckout page
      dispatch(clearLineItems())
    }
  }, [])

  const handleViewSegenQuoteOrOrder = () => {
    logAmplitudeEvent('hardware_segen_post_checkout', {
      action: 'clicked',
      context: `view_${quoteOrOrderType}_button`,
      redirect: 'segen_portal',
      type: quoteOrOrderType,
    })
    window.open(portalUrl)
  }

  /* Todo - New feature will be replacing it.
  const handleViewOpenSolarQuoteOrOrder = () => {
    logAmplitudeEvent('hardware_segen_post_checkout', {
      action: 'clicked',
      context: `view_${quoteOrOrderType}_button`,
      redirect: 'opensolar',
      type: quoteOrOrderType,
    })
    if (quoteOrOrderType === 'order') {
      history.push('/ordering_orders/segen')
    } else {
      history.push('/ordering_quotes/segen')
    }

  }
  */

  const title = quoteOrOrderType === 'order' ? 'Order Complete' : 'Quote Complete'
  const typeLabel = quoteOrOrderType

  return (
    <div className={classes.container}>
      <Typography variant="h3" gutterBottom>
        {title}
      </Typography>
      <Divider className={classes.divider} />
      <Typography variant="body1" gutterBottom>
        Thank you for placing your Segen {typeLabel}, please use the navigation links below to view your {typeLabel}{' '}
        details, track your order and contact support.
      </Typography>
      <div className={classes.buttonWrapper}>
        {portalUrl && (
          <Button color={'primary'} className={classes.button} onClick={handleViewSegenQuoteOrOrder}>
            <span>View your {typeLabel} on the Segen portal</span>
          </Button>
        )}
      </div>
    </div>
  )
}

export default memo(SegenPostCheckoutPage)

import { permissionsSelectors } from 'ducks/permissions'
import { UiKey } from 'elements/UiKey'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import React, { FC } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { RoleSelectInput } from 'resources/connectedOrgs/RoleSelectInput'
import AccessProjectsFromUsers from './AccessProjectsFromUsers'
import { useRoleFormStyles } from './useRoleFormStyles'

type Props = {
  id?: number
  disabled?: boolean
}

const uiKey = 'control.company.team.fields'

const RoleAccessFieldsContent: FC<Props> = ({ disabled, id }) => {
  const isCreate = !id
  const classes = useRoleFormStyles()

  const accessProjectsFromUsersPermission = useSelector(
    permissionsSelectors.getPermissionByKey('grant_access_to_projects_from_these_users')
  )
  const accessTeamPermission = useSelector(permissionsSelectors.getPermissionByKey('team'))

  return (
    <div className={classes.fieldCont}>
      <UiKey uiKey={uiKey + '.role'}>
        {accessTeamPermission?.allowView ? (
          <RoleSelectInput disabled={!isCreate && !accessTeamPermission?.allowEdit} />
        ) : undefined}
      </UiKey>

      <UiKey uiKey={uiKey + '.grantAccess'}>
        {isCreate || accessProjectsFromUsersPermission?.allowView ? (
          <AccessProjectsFromUsers
            className={classes.input}
            disabled={!isCreate && !accessProjectsFromUsersPermission?.allowEdit}
          />
        ) : undefined}
      </UiKey>
    </div>
  )
}

export const RoleAccessFields: FC<Props> = (props) => {
  const translate = useTranslate()
  return (
    <AccordionCard
      disabled={props.disabled}
      defaultExpanded={true}
      name={'role-access'}
      title={translate('Access')}
      content={RoleAccessFieldsContent}
      contentProps={props}
    />
  )
}

import { authSelectors } from 'ducks/auth'
import { orderSelectors } from 'ducks/orderComponents'
import { orgSelectors } from 'ducks/orgs'
import { createContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import useCityPlumbingService from 'services/cityPlumbing/useCityPlumbingService'
import CityPlumbingCheckoutPresenter from './CityPlumbingCheckoutPresenter'
import { ContactDetailType, DeliveryDetailType, PaymentMethodType } from './types'

const defaultContactDetail: ContactDetailType = {
  businessName: undefined,
  email: undefined,
  mobileNumber: undefined,
}

const defaultDeliveryDetail: DeliveryDetailType = {
  companyName: '',
  contactName: '',
  contactNumber: '',
  contactAddress: {
    address: '',
    locality: '',
    state: '',
    zip: '',
    countryName: '',
    countryIso2: '',
  },
  deliveryMethod: 'delivery',
  deliveryDate: '',
  deliveryTime: '',
  deliveryBranch: {
    name: '',
    manager: '',
    email: '',
    contactNumber: '',
    address: '',
    weekdayHours: '',
    saturdayHours: '',
    sundayHours: '',
  },
  fee: 'TBC',
  deliveryInstructions: '',
}

export const CheckoutPresenterContext = createContext<CityPlumbingCheckoutPresenter | undefined>(undefined)
export const ContactDetailContext = createContext<ContactDetailType>(defaultContactDetail)
export const DeliveryDetailContext = createContext<DeliveryDetailType>(defaultDeliveryDetail)
export const PaymentMethodContext = createContext<PaymentMethodType>('credit_card')

const CityPlumbingCheckoutProvider = ({ children }) => {
  const org = useSelector(orgSelectors.getOrg)
  const user = useSelector(authSelectors.getCurrentUser)
  const role = useSelector(authSelectors.getCurrentRole)
  const lineItems = useSelector(orderSelectors.getOrderableLineItems)
  const service = useCityPlumbingService()
  let contactName = `${role?.first_name} ${role?.family_name}`
  if (contactName.trim() === '') {
    contactName = user?.email || 'Unknown name'
  }
  const [contactDetail, setContactDetail] = useState<ContactDetailType>({
    businessName: org?.name,
    email: user?.email,
    mobileNumber: role?.phone,
  })
  const [deliveryDetail, setDeliveryDetail] = useState<DeliveryDetailType>({
    ...defaultDeliveryDetail,
    companyName: org?.name || 'Unknown',
    contactName: contactName,
    contactNumber: org?.sales_phone_number || '',
    contactAddress: {
      address: org?.address || '',
      locality: org?.locality || '',
      state: org?.state || '',
      zip: org?.zip || '',
      countryName: org?.country?.name || '',
      countryIso2: org?.country?.iso2 || '',
    },
  })
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodType>('credit_card')

  const checkoutPresenter = useMemo(
    () => new CityPlumbingCheckoutPresenter(service, lineItems, setContactDetail, setPaymentMethod, setDeliveryDetail),
    []
  )
  return (
    <ContactDetailContext.Provider value={contactDetail}>
      <DeliveryDetailContext.Provider value={deliveryDetail}>
        <PaymentMethodContext.Provider value={paymentMethod}>
          <CheckoutPresenterContext.Provider value={checkoutPresenter}>{children}</CheckoutPresenterContext.Provider>
        </PaymentMethodContext.Provider>
      </DeliveryDetailContext.Provider>
    </ContactDetailContext.Provider>
  )
}
export default CityPlumbingCheckoutProvider

import {
  getCompatibleCableThickness,
  getMaxCableCurrent,
  getSystemVoltageAndCurrent,
} from 'elements/electrical/ElectricalValidation'
import { FilterValuesType } from 'elements/hardwareFilter/typeV2'
import { getFiltersSchema } from 'elements/hardwareSelector/Filters'
import { StudioSystemType } from 'types/global'
import type { ComponentTypesV2 } from 'types/selectComponent'
import { FilterOverridesType, SelectComponentFilters } from 'types/selectComponent'
import { SlotType } from 'types/slots'

export function getSlotKey(slot: SlotType): string {
  if (slot.attachToUuid) {
    return `${slot.type}_${slot.attachToUuid}`
  } else {
    return slot.type
  }
}

export function getSlotRestriction(slot: SlotType): boolean {
  const restrictToOne = true
  return restrictToOne
}

export function getSlotTitle(slot: SlotType): string {
  var title = ''
  if (slot.type === 'dc_isolator') {
    title = 'DC Isolator'
  } else if (slot.type === 'ac_isolator') {
    title = 'AC Isolator'
  } else if (slot.type === 'dc_cable') {
    title = 'DC Cabling'
  } else if (slot.type === 'ac_cable') {
    title = 'AC Cabling'
  } else if (slot.type === 'trunk_cable') {
    title = 'Trunk Cabling'
  } else if (slot.type === 'dc_optimizer') {
    title = 'DC Optimizers'
  }
  return title
}

export const initialFilterSettingsForSlot = (slot: SlotType): SelectComponentFilters => {
  var filterSettings = {}
  const filterOverrides = filterOverridesForSlot(slot)
  filterOverrides.forEach((override) => {
    filterSettings[override.key] = override.value
  })
  return filterSettings
}

export const filterOverridesForSlot = (slot: SlotType): FilterOverridesType => {
  var filterOverrides: FilterOverridesType = []
  const slotKey = getSlotKey(slot)

  var componentType = OsSystem.getComponentTypeFromSlotKey(slotKey)
  if (componentType) filterOverrides.push({ key: 'other_component_type', value: componentType })

  if (slot.type.includes('ac_')) filterOverrides.push({ key: 'current_type', value: 'ac' })
  if (slot.type.includes('dc_')) filterOverrides.push({ key: 'current_type', value: 'dc' })

  if (slot.type === 'ac_isolator') {
    const isolatorLocation = window.editor.objectByUuid(slot.attachToUuid)
    const inverter = isolatorLocation.type === 'OsMppt' ? isolatorLocation.parent : isolatorLocation
    const inverterCurrent = inverter?.current_ac_max
    var currentMax
    if (inverter.microinverter) {
      // Note we use 'isolatorLocation' below which can be the inverter or the branch. If we are isolating separate branches, we only combine the output currents of the microinverters within the branch. If we add an isolator on the level above that, we combine the branches.
      const microinverterQty = Math.ceil(isolatorLocation.moduleQuantity() / inverter.mppt_quantity)
      currentMax = inverterCurrent * microinverterQty
    } else {
      currentMax = inverterCurrent
    }
    if (currentMax)
      filterOverrides.push({
        key: 'current_rating_range',
        value: currentMax + 0.1 + ',1000',
        options: [{ title: currentMax + 'A <', value: currentMax + 0.1 + ',1000' }],
      })
    if (inverter.phase_type) filterOverrides.push({ key: 'phase_type', value: inverter.phase_type })
  }

  if (slot.type === 'dc_isolator') {
    const strings =
      window.editor.objectByUuid(slot.attachToUuid).type === 'OsString'
        ? [window.editor.objectByUuid(slot.attachToUuid)]
        : window.editor.objectByUuid(slot.attachToUuid).children
    const { voltage, current } = getSystemVoltageAndCurrent(strings)
    filterOverrides.push({
      key: 'voltage_to_current_rating',
      value: voltage * 1.25 + ',' + current * 1.25,
      options: [{ title: 'Compatible with system', value: voltage * 1.25 + ',' + current * 1.25 }],
    })
  }

  if (slot.type === 'dc_cable') {
    const getCableLength = (cableSlot) => {
      return cableSlot?.properties?.cableLength ?? 25
    }
    const stringOrMPPT = window.editor.objectByUuid(slot.attachToUuid)
    const qty = 1
    const current = getMaxCableCurrent(stringOrMPPT)
    const filtersSchema = getFiltersSchema('other')
    // Find a cable length above the required length that components can be filtered by
    const cableLength = getCableLength(slot)
    const cableLengthFilter = filtersSchema.filter((filter) => filter.key === 'cable_length')[0]
    // assumes cable lengths are ordered lowest to highest
    const bestCableLengthOption = cableLengthFilter.options.find((option) => Number(option.value) >= cableLength)
    if (bestCableLengthOption) {
      filterOverrides.push({
        key: 'cable_length',
        value: bestCableLengthOption.value,
      })
    }
    // Find a cable thickness above the required thickness that components can be filtered by
    const cableThickness = getCompatibleCableThickness(cableLength, 'three_phase', current, qty)
    const cableThicknessFilter = filtersSchema.filter((filter) => filter.key === 'cable_thickness')[0]
    // assumes cable thicknesses are ordered lowest to highest
    const bestCableThicknessOption = cableThicknessFilter.options.find(
      (option) => Number(option.value) >= cableThickness
    )
    if (bestCableThicknessOption) {
      filterOverrides.push({
        key: 'cable_thickness',
        value: bestCableThicknessOption.value,
      })
    }
  }

  return filterOverrides
}

export const getComponentTypeV2BySlot = (slot: SlotType): ComponentTypesV2 | undefined => {
  const slotKey = getSlotKey(slot)

  var componentType = OsSystem.getComponentTypeFromSlotKey(slotKey)
  if (componentType) {
    return componentType
  }
}

export const getDefaultFilterValuesBySlot = (slot: SlotType): FilterValuesType => {
  const defaultValues: FilterValuesType = {}

  if (slot.type.includes('ac_')) defaultValues['current_type'] = 'ac'
  if (slot.type.includes('dc_')) defaultValues['current_type'] = 'dc'

  if (slot.type === 'ac_isolator') {
    const isolatorLocation = window.editor.objectByUuid(slot.attachToUuid)
    const inverter = isolatorLocation.type === 'OsMppt' ? isolatorLocation.parent : isolatorLocation
    const inverterCurrent = inverter?.current_ac_max
    var currentMax
    if (inverter.microinverter) {
      // Note we use 'isolatorLocation' below which can be the inverter or the branch. If we are isolating separate branches, we only combine the output currents of the microinverters within the branch. If we add an isolator on the level above that, we combine the branches.
      const microinverterQty = Math.ceil(isolatorLocation.moduleQuantity() / inverter.mppt_quantity)
      currentMax = inverterCurrent * microinverterQty
    } else {
      currentMax = inverterCurrent
    }

    if (currentMax) {
      defaultValues['current_rating__gte'] = currentMax + 0.1
      defaultValues['current_rating__lte'] = 1000
    }
    if (inverter.phase_type) defaultValues['phase_type'] = inverter.phase_type
  }

  if (slot.type === 'dc_isolator') {
    const strings =
      window.editor.objectByUuid(slot.attachToUuid).type === 'OsString'
        ? [window.editor.objectByUuid(slot.attachToUuid)]
        : window.editor.objectByUuid(slot.attachToUuid).children
    const { voltage, current } = getSystemVoltageAndCurrent(strings)
    defaultValues['voltage_to_current_rating'] = voltage * 1.25 + ',' + current * 1.25
  }

  if (slot.type === 'dc_cable') {
    const getCableLength = (cableSlot) => {
      return cableSlot?.properties?.cableLength ?? 25
    }
    const stringOrMPPT = window.editor.objectByUuid(slot.attachToUuid)
    const qty = 1
    const current = getMaxCableCurrent(stringOrMPPT)
    const filtersSchema = getFiltersSchema('other')
    // Find a cable length above the required length that components can be filtered by
    const cableLength = getCableLength(slot)
    const cableLengthFilter = filtersSchema.filter((filter) => filter.key === 'cable_length')[0]
    // assumes cable lengths are ordered lowest to highest
    const bestCableLengthOption = cableLengthFilter.options.find((option) => Number(option.value) >= cableLength)
    if (bestCableLengthOption) {
      defaultValues['cable_length'] = bestCableLengthOption.value
    }
    // Find a cable thickness above the required thickness that components can be filtered by
    const cableThickness = getCompatibleCableThickness(cableLength, 'three_phase', current, qty)
    const cableThicknessFilter = filtersSchema.filter((filter) => filter.key === 'cable_thickness')[0]
    // assumes cable thicknesses are ordered lowest to highest
    const bestCableThicknessOption = cableThicknessFilter.options.find(
      (option) => Number(option.value) >= cableThickness
    )
    if (bestCableThicknessOption) {
      defaultValues['cable_thickness'] = bestCableThicknessOption.value
    }
  }

  return defaultValues
}

export function getSlotsByLocation(uuid: string, system: StudioSystemType): SlotType[] {
  var slots: SlotType[] = []
  if (system.userData?.slots) slots.push(...system.userData.slots.filter((slot) => slot.attachToUuid === uuid))
  return slots
}

export function updateSlot(slotToUpdate: SlotType, system: StudioSystemType): any {
  if (!system.userData.slots) {
    // error
    return
  }
  var slotsToSave = system.userData.slots
  const slotIndex = slotsToSave.findIndex(
    (slot) => slot.attachToUuid === slotToUpdate.attachToUuid && slot.type === slotToUpdate.type
  )
  if (slotIndex === -1) {
    //error
    return
  }
  slotsToSave[slotIndex] = slotToUpdate
  window.editor.execute(new window.SetValueCommand(system, 'slots', slotsToSave))
}

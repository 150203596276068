import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from 'types/state'

// be careful when using this. This is meant to be used for selectors that are running logic on state that doesn't change frequently
function useMemoizedSelector<T>(selectorFn: (state: RootState) => T): T {
  const immediateResult = useSelector(selectorFn, shallowEqual)
  const [storedResult, setStoredResult] = useState(immediateResult)

  useEffect(() => {
    if (storedResult !== immediateResult) {
      if (typeof storedResult === 'object' && typeof immediateResult === 'object') {
        if (!isEqual(storedResult, immediateResult)) {
          setStoredResult(immediateResult)
        }
      } else {
        setStoredResult(immediateResult)
      }
    }
  }, [immediateResult, storedResult])

  return storedResult
}
export default useMemoizedSelector

// @ts-nocheck
import { Code } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { featureConfigSelectors } from 'ducks/featureConfig'
import React, { useEffect, useState } from 'react'
import { useNotify } from 'react-admin'
import { useSelector } from 'react-redux'
import { PlaceholderWhiteListType } from './ProjectPlaceholderWizard'
import { formatPlaceholderLabel } from './util'

type PropTypes = {
  fieldName: string
  currentPath: string
  shouldShowArrow: boolean
  onClick: () => void
  value: any
  startDrag: (fieldName: string, value: string) => void
  endDrag: () => void
}

const PlaceholderCell: React.FunctionComponent<PropTypes> = (props) => {
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const [isBeingDragged, setIsBeingDragged] = useState<boolean>(false)
  const [cellTitle, setCellTitle] = useState<string | undefined>(undefined)

  const notify = useNotify()

  const whitelistVals: PlaceholderWhiteListType | undefined = useSelector((state) =>
    featureConfigSelectors.getFeatureConfigData(state, 'placeholder_whitelist')
  )

  useEffect(() => {
    setCellTitle(formatCellLabel(props.fieldName))
  }, [props.fieldName])

  const onDragStart = () => {
    setIsBeingDragged(true)
    props.startDrag(`${props.currentPath}.${props.fieldName}`, props.value)
  }

  const onDragEnd = () => {
    setIsBeingDragged(false)
    props.endDrag()
  }

  const formatCellLabel = (raw: string) => formatPlaceholderLabel(raw, props.currentPath, whitelistVals)

  const handleClick = () => {
    // clicking changes the nav which shouldn't be done if the cell clicked is a field with a value. Should only happen for objects and arrays
    if (props.shouldShowArrow) props.onClick()
    else return null
  }

  const roundNumericValues = () => {
    if (isNaN(props.value) || props.value === '' || props.value === ' ') {
      let isHTML = /^/.test(props.value)
      if (isHTML && props.value) return props.value.replace('</p>', '\n').replace(/(<([^>]+)>)/gi, '')
      return props.value
    } else {
      let rounded = parseFloat(props.value) % 1 === 0 ? parseInt(props.value) : parseFloat(props.value).toFixed(2)
      return isNaN(rounded) ? props.value : rounded
    }
  }

  const copyPathToClipboard = () => {
    let pathToCopy: string | undefined = undefined
    if (props.currentPath === 'popular') {
      pathToCopy = props.fieldName
    } else {
      pathToCopy = props.currentPath + '.' + props.fieldName
    }
    if (pathToCopy) {
      navigator.clipboard
        .writeText(pathToCopy)
        .then(() => {
          notify(`${cellTitle} field path copied to clipboard`, 'success')
          logAmplitudeEvent('placeholder_path_copied', { path: pathToCopy })
        })
        .catch((err) => {
          console.log('error', err)
        })
    } else {
      notify('This variable does not have a field path that can be copied', 'warning')
    }
  }

  return (
    <div
      style={{
        margin: '2px',
        padding: '8px',
        border: '1px solid #ececec',
        borderRadius: '6px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: isHovered ? '#ececec' : undefined,
        //opacity: isBeingDragged ? 0.4 : 1.0,
        width: '218px',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onDragStart={onDragStart}
      draggable={!props.shouldShowArrow}
      onDragEnd={onDragEnd}
      onClick={handleClick}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span style={{ fontWeight: 900 }}>{cellTitle}</span>
        {!props.shouldShowArrow && <span>{roundNumericValues()}</span>}
      </div>
      {props.shouldShowArrow && <span style={{ marginLeft: '20px' }}> {'>'}</span>}
      {!props.shouldShowArrow && !props.fieldName?.includes('DJANGO_TAGS') && props.fieldName !== 'FREE_TEXT' && (
        <span style={{ cursor: 'pointer' }}>
          <Code fontSize="small" htmlColor={isHovered ? 'black' : '#ececec'} onClick={copyPathToClipboard} />
        </span>
      )}
    </div>
  )
}
export default PlaceholderCell

import { Grid } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { setMfaCodesShowing } from 'ducks/auth_mfa_actions'
import Button from 'elements/proUXButtons/ProUXButton'
import FullScreenLoading from 'layout/widgets/FullScreenLoading'
import { Chip } from 'opensolar-ui'
import { useNotify, useTranslate } from 'ra-core'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import restClient from 'restClient'

type PropTypes = {
  onFinish?(): void
}

const MfaRecoveryCodes: React.FC<PropTypes> = (props) => {
  const notify = useNotify()
  // const activeMfaDevices: MFADeviceType[] = useSelector(authSelectors.getConfirmedMfaDevice) || []

  const [recoveryCodes, setRecoveryCodes] = useState<string[]>([])
  let [hasCodes, setHasCodes] = useState(true)
  let [remaining, setRemaining] = useState(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [hasAutoFetched, setHasAutoFetched] = useState<boolean>(false)
  const [isThrottled, setIsThrottled] = useState<boolean>(false)
  const [throttleSeconds, setThrottleSeconds] = useState<number>(0)
  const translate = useTranslate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setMfaCodesShowing(true))
    return () => {
      dispatch(setMfaCodesShowing(false))
    }
  }, [])

  // useEffect(() => {
  //   if (hasAutoFetched) return

  //   let found = false
  //   for (const device of activeMfaDevices) {
  //     if (device.kind === 'recovery') {
  //       found = true
  //       setHasCodes(true)
  //       setRemaining(device.remaining || 0)
  //       break
  //     }
  //   }
  //   if (!found && !recoveryCodes?.length && !loading) {
  //     setHasAutoFetched(true)
  //     fetchRecoveryCodes()
  //   }
  // }, [activeMfaDevices])

  useEffect(() => {
    if (hasAutoFetched) return
    setHasAutoFetched(true)
    fetchRecoveryCodes()
  }, [])

  const checkThrottleTime = useCallback(() => {
    if (isThrottled) {
      const newSecs = throttleSeconds - 1
      setThrottleSeconds(newSecs)
      if (newSecs <= 0) {
        setIsThrottled(false)
      }
    }
  }, [isThrottled, throttleSeconds])

  // Throttle countdown
  useEffect(() => {
    const timer = setInterval(() => checkThrottleTime(), 1000)
    return () => clearInterval(timer)
  }, [checkThrottleTime])

  const fetchRecoveryCodes = () => {
    setHasCodes(false)
    setLoading(true)
    const restClientInstance = restClient(window.API_ROOT + '/api')
    restClientInstance('CUSTOM_POST', 'custom', {
      url: 'mfa/devices/recovery/',
    })
      .then((res: { data: { recoveryCodes: string[] } }) => {
        setRecoveryCodes(res.data.recoveryCodes)
        logAmplitudeEvent('mfa_recovery_codes_generated', {})
        setIsThrottled(false)
      })
      .catch((err: any) => {
        console.warn(err)
        notify('Unable to generate recovery codes', 'warning')

        if (err.body?.wait) {
          setIsThrottled(true)
          setThrottleSeconds(err.body?.wait)
        } else {
          setIsThrottled(false)
        }
      })
      .finally(() => setLoading(false))
  }

  const download = () => {
    try {
      var element = document.createElement('a')
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(recoveryCodes.join('\n')))
      element.setAttribute('download', 'OpenSolar Recovery Codes.txt')
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
      logAmplitudeEvent('mfa_recovery_codes_downloaded', {})
      notify('Your recovery codes have been downloaded')
    } catch (err) {
      console.log(err)
      notify('Unable to download recover codes, please copy them manually', 'warning')
    }
  }

  const finished = () => {
    props.onFinish?.()
  }

  if (loading) return <FullScreenLoading />
  else {
    return (
      <>
        {recoveryCodes?.length > 0 ? (
          <div>
            <h4>{translate('Why is saving your recovery codes important?')}</h4>
            <p>
              {translate(
                'If you lose access to your phone you can authenticate to OpenSolar using your recovery codes. We recommend saving them with a secure password manager.'
              )}
            </p>
            <Grid container spacing={3} style={{ marginBottom: '15px' }}>
              {recoveryCodes.map((code, i) => {
                return (
                  <Grid key={code} item xs={6}>
                    <Chip data-cy={i} label={code} style={{ width: '100%', textAlign: 'center', fontSize: '1.2em' }} />
                  </Grid>
                )
              })}
            </Grid>
            <div id="downloadRecoveryCodes" style={{ marginTop: '15px', width: '100%' }}>
              <Button label="Download Recovery Codes" onClick={download} type="secondary" fullWidth />
            </div>
          </div>
        ) : (
          <div>
            <p>
              {hasCodes
                ? translate('You have remaining recovery codes remaining.', { remaining })
                : isThrottled
                ? translate(
                    'Something went wrong and we were unable to generate your recovery codes, please try again soon.',
                    { seconds: throttleSeconds }
                  )
                : translate(
                    'Something went wrong and we were unable to generate your recovery codes, please try again.'
                  )}
            </p>
            <Button
              label="Generate new recovery codes"
              onClick={fetchRecoveryCodes}
              disabled={isThrottled}
              type="secondary"
              fullWidth
            />
          </div>
        )}
        <div id="finish" style={{ marginTop: '15px', width: '100%' }}>
          <Button label="Finish" onClick={finished} type="secondary" fullWidth />
        </div>
      </>
    )
  }
}
export default MfaRecoveryCodes

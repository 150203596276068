import { MenuItem, Select } from '@material-ui/core'
import { AddOutlined } from '@material-ui/icons'
import { orgSelectors } from 'ducks/orgs'
import LaunchPaymentsOnboardingDialog from 'elements/paymentsOnboarding/LaunchPaymentsOnboardingDialog'
import { Button } from 'opensolar-ui'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { CashFlowConfiguration } from '../types'

import CreateConfiguration from './paymentMilestones/CreateConfiguration'
import UpdateConfiguration from './paymentMilestones/UpdateConfiguration'
import { useGetCashFlowConfigurations } from './utils'

type PropTypes = {}

const CashFlowConfigurationPage: React.FC<PropTypes> = (props) => {
  const [showCreate, setShowCreate] = useState<boolean>(false)
  const [selectedConfig, setSelectedConfig] = useState<CashFlowConfiguration | undefined>(undefined)
  const orgId = useSelector(orgSelectors.getOrg)?.id
  const configs = useGetCashFlowConfigurations(orgId, 1, 10)
  const [isUpdated, setIsUpdated] = useState<boolean>(false)

  const onConfigSelection = useCallback(
    (e) => {
      let chosenConfig = configs?.find((config) => config.id === e.target.value)
      setSelectedConfig(chosenConfig)
    },
    [configs]
  )
  return (
    <div>
      <h2>Welcome to CashFlow!</h2>
      <LaunchPaymentsOnboardingDialog />
      <h2>Or create/edit your configurations here</h2>
      <Select value={selectedConfig?.id} onChange={onConfigSelection} style={{ width: '100px' }}>
        {configs.map((config) => (
          <MenuItem value={config.id} key={config.id}>
            {config.title}
          </MenuItem>
        ))}
      </Select>
      <Button variant="outlined" onClick={() => setShowCreate(true)} startIcon={<AddOutlined />}>
        Create
      </Button>
      {selectedConfig && !showCreate && <UpdateConfiguration config={selectedConfig} />}
      {showCreate && <CreateConfiguration />}
    </div>
  )
}
export default CashFlowConfigurationPage

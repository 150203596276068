import { makeStyles } from '@material-ui/core'
import { permissionsSelectors } from 'ducks/permissions'
import { viewModeActions } from 'ducks/viewMode'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { systemCalculationsSelectors } from 'reducer/designer/systemCalculation'
import { OverlayType, StudioSystemType } from 'types/global'
import SaveStatus from '../../common/SaveStatus'
import AddPanelButton from '../systems/accordion/AddPanelButton'
import ViewControllers from '../tools/widgets/ViewControllers'
import { validateLiteProject } from '../util/validateLiteProject'
import AdvancedTools from './AdvancedTools'
import ImagerySelector from './ImagerySelector'
import PanelSystemLite from './PanelSystemLite'

const useStyles = makeStyles((theme: any) => ({
  container: {
    position: 'fixed',
    inset: '50px 0px 0px',
    zIndex: 1400,
    pointerEvents: 'none',

    '& > *': {
      pointerEvents: 'initial',
    },

    [theme.breakpoints.down('xs')]: {
      inset: '50px 0px 50px',
    },
  },
  mapTools: {
    position: 'absolute',
    bottom: 20,
    right: 18,

    [theme.breakpoints.down('sm')]: {
      right: 7,
      bottom: 7,
    },
  },
  saveStatus: {
    position: 'absolute',
    top: 0,
    right: 20,

    [theme.breakpoints.down('sm')]: {
      top: 62,
      right: 9,
    },
  },
}))

interface State {
  overlay: OverlayType | null
}

const PageDesignerLite: React.FC = () => {
  const [panelOpen, setPanelOpen] = useState(true)
  const [state, setState] = useState<State>({
    overlay: null,
  })
  const form = useForm()
  const formState = form.getState()
  const isProjectLite = formState.values?.is_lite
  const dispatch = useDispatch()
  const classes = useStyles()
  const isCreated = useMemo(() => {
    const ret = !!(form.getState().values.id && !isNaN(form.getState().values.id))
    console.log('isCreated: ', ret)
    return ret
  }, [form.getState().values.id])

  const system: StudioSystemType | undefined = useMemo(() => {
    return window.editor.selectedSystem
  }, undefined)

  const calcsPending = useSelector(systemCalculationsSelectors.isSystemQueued)(system?.uuid)
  const calculating = useSelector(systemCalculationsSelectors.isSystemProcessing)(system?.uuid)
  const { allowEdit } = useSelector(permissionsSelectors.getProjectPermissionByKey('design'))

  useEffect(() => {
    window.editor.setShowElements({ viewWidget: false })
    if (isProjectLite) dispatch(viewModeActions.setIsProjectLite(true))
  }, [])

  //TODO: refactor these away
  window.setOverlay = (value: OverlayType | null) => {
    setState({ overlay: value })
  }
  window.getOverlay = (): OverlayType | null => {
    return state.overlay
  }

  useEffect(() => {
    if (isCreated) validateLiteProject(form)
  }, [isCreated])

  return (
    <div className={classes.container}>
      {isCreated && <AddPanelButton disabled={!allowEdit} hideOrientationButton={true} size="normal" />}
      <ImagerySelector isCreated={isCreated} />
      <AdvancedTools />
      <div className={classes.mapTools}>
        <ViewControllers hideAlign={true} hideStreeView={true} />
      </div>
      <div className={classes.saveStatus}>
        <SaveStatus calcsPending={calcsPending} calculating={calculating} isCreated={isCreated} />
      </div>
      <PanelSystemLite
        system={system}
        calcsPending={calcsPending}
        calculating={calculating}
        panelOpen={panelOpen}
        isCreated={isCreated}
        setPanelOpen={setPanelOpen}
      />
    </div>
  )
}

export default PageDesignerLite

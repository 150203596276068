import { useCallback } from 'react'
import { OTHER_COMPONENT_FILTER_KEY } from '../otherComponentType/OtherComponentTypeFilterNodeFactory'
import type {
  FilterComponentRendererType,
  FilterGenericPropsType,
  FilterKeysType,
  FilterValuesType,
  OptionGenericType,
} from '../type'

export type FilterOptionsType = OptionGenericType<string>[]

type FilterProps = FilterGenericPropsType & {
  RendererComponent: FilterComponentRendererType<FilterOptionsType>
  filterKey: FilterKeysType
}

export const isCableLengthFilterAvailable = (filterValues: FilterValuesType) => {
  return filterValues[OTHER_COMPONENT_FILTER_KEY] === 'cable'
}

export const CABLE_LENGTH_OPTIONS: FilterOptionsType = [
  { id: 'default', title: 'All', value: '' },
  { id: '25m', title: '25m', value: '25' },
  { id: '50m', title: '50m', value: '50' },
  { id: '100m', title: '100m', value: '100' },
  { id: '250m', title: '250m', value: '250' },
  { id: '500m', title: '500m', value: '500' },
  { id: '1000m', title: '1000m', value: '1000' },
]

const CableLengthFilter = ({
  filterKey,
  modifiableFilterValues,
  persistentFilterValues,
  allFilterValues,
  setModifiableFilters,
  RendererComponent,
}: FilterProps) => {
  const onSelect = useCallback(
    (value) => {
      setModifiableFilters({ ...modifiableFilterValues, [filterKey]: value })
    },
    [modifiableFilterValues]
  )

  const disabled = !isCableLengthFilterAvailable(allFilterValues)

  return (
    <RendererComponent
      id={filterKey}
      defaultValue={''}
      label={'Cable Length'}
      options={CABLE_LENGTH_OPTIONS}
      disabled={disabled}
      onSelect={onSelect}
      value={allFilterValues[filterKey]}
    />
  )
}

export default CableLengthFilter

const DualTiltIcon = (props: { highlight: boolean }) => {
  const neutralColorHex1 = '#C7CAD1'
  const neutralColorHex2 = '#9499A5'
  const highlightColorHex = '#4272DD'

  return (
    <svg width="66" height="24" viewBox="0 0 66 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M58.8333 17.7998L58.8333 22.8748"
        stroke={props.highlight ? highlightColorHex : neutralColorHex1}
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M6.63333 17.7998L6.63333 22.8748"
        stroke={props.highlight ? highlightColorHex : neutralColorHex1}
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M38.5333 12L38.5333 22.875"
        stroke={props.highlight ? highlightColorHex : neutralColorHex1}
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M26.9333 12L26.9333 22.875"
        stroke={props.highlight ? highlightColorHex : neutralColorHex1}
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M64.6333 23.5996H0.833333"
        stroke={props.highlight ? highlightColorHex : neutralColorHex1}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1.5 1.5"
      />
      <path
        d="M61.7333 17.8L35.6333 12"
        stroke={props.highlight ? highlightColorHex : neutralColorHex2}
        strokeWidth="3.74"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.73333 17.8L29.8333 12"
        stroke={props.highlight ? highlightColorHex : neutralColorHex2}
        strokeWidth="3.74"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DualTiltIcon

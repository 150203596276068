import { Dialog, Tooltip } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import { IconButton } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'

export const NextStepsDialog = (props) => {
  const translate = useTranslate()
  return (
    <div className="ContainerNextSteps" id="ContainerNextSteps">
      <Dialog open={props.isOpen} onBackdropClick={() => props.closeDialog()} className="about-us">
        <IconButton
          id="NextStepsClose"
          style={{
            top: 10,
            right: 10,
            position: 'absolute',
            minWidth: 40,
            backgroundColor: 'rgba(255, 255, 255, 0)',
          }}
          onClick={() => props.closeDialog()}
        >
          {
            <Tooltip title={translate('Close Dialog')} enterDelay={300}>
              <CloseIcon style={{ color: '#000000' }} />
            </Tooltip>
          }
        </IconButton>

        <div
          dangerouslySetInnerHTML={{
            __html: props.content,
          }}
        />
      </Dialog>
    </div>
  )
}
export default NextStepsDialog

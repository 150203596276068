import MenuItem from '@material-ui/core/MenuItem'
import React, { memo } from 'react'
import { AutocompleteSuggestionList } from 'react-admin'
import type { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const DEFAULT_MAX_ITEMS = 50
const useStyles = makeOpenSolarStyles(
  (theme: OpenSolarThemeType) => ({
    backdrop: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    nonClickableItem: {
      cursor: 'default',
    },
  }),
  { name: 'Basic-Autocomplete-Suggestion-List' }
)

const BasicAutocompleteSuggestionList = <T,>({
  maxItems = DEFAULT_MAX_ITEMS,
  width,
  inputEl,
  loading,
  options,
  onClose,
  renderer,
  handleSelect,
}: {
  maxItems?: number
  width?: number
  inputEl: HTMLElement // could have referencing issue to get clientWidth
  loading: boolean
  options: T[]
  onClose(): void
  renderer(record: T): React.ReactNode
  handleSelect(record: T): void
}) => {
  const classes = useStyles()
  // TODO: add translation if needed
  const renderListItem = () => {
    if (loading) {
      return (
        <MenuItem className={classes.nonClickableItem}>
          <span>{'Loading search results...'}</span>
        </MenuItem>
      )
    } else if (options.length === 0) {
      return (
        <MenuItem className={classes.nonClickableItem}>
          <span>{'No results found.'}</span>
        </MenuItem>
      )
    } else {
      return options?.slice(0, maxItems).map((record: T) => {
        if (record == null) return null
        const handleClicked = () => handleSelect(record)
        return (
          <div style={{ width: width || inputEl?.clientWidth }}>
            <MenuItem onClick={handleClicked}>{renderer(record)}</MenuItem>
          </div>
        )
      })
    }
  }

  return (
    <>
      <AutocompleteSuggestionList isOpen={true} menuProps={{}} inputEl={inputEl} variant="outlined">
        {renderListItem()}
      </AutocompleteSuggestionList>
      <div className={classes.backdrop} onClick={onClose}></div>
    </>
  )
}

export default memo(BasicAutocompleteSuggestionList)

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { TableRow as Mui4TableRow } from '@material-ui/core';
import { default as Mui5TableRow } from '@mui/material/TableRow';
import { forwardRef } from 'react';
import { Inspector } from '../debug/Inspector';
import { useComponentVersion } from '../hooks';
import { OsTableVersion } from './Table';
export var TableRow = forwardRef(function (props, ref) {
    return (_jsx(Inspector, { name: "TableRow", propDefs: TableRowProps, showExample: true, component: OsTableRow, props: __assign(__assign({}, props), { ref: ref }) }));
});
var OsTableRow = forwardRef(function (props, ref) {
    if (ref === void 0) { ref = null; }
    var version = useComponentVersion('table');
    switch (version) {
        case OsTableVersion.V2:
            return _jsx(Mui5TableRow, __assign({ ref: ref }, props));
        default:
            return _jsx(Mui4TableRow, __assign({ ref: ref }, props));
    }
});
var TableRowProps = {};

import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { setSelectTariffDialog as setSelectTariffDialogAction } from 'ducks/selectTariff'
import React, { useCallback, useReducer } from 'react'
import { useForm } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import AccordionCard from '../../info/AccordionCard'
import ElectricityRateContent from './ElectricityRateContent'
import SelectTariffDialog from './selectTariffDialog/SelectTariffDialog'
import UtilityTariffDialog from './tariffFormDialog/UtilityTariffDialog'

import { studioSelectors } from 'ducks/studioMode'
import { useNotify, useTranslate } from 'react-admin'

export type DialogStateTypes = {
  isOpen: boolean
  variant: string
  basePath: string | null
  mode: string
  resource: string | null
  id?: number | null
  onSuccess?: Function
  onFailure?: Function
  handleSave?: Function
  handleClear?: Function
  count?: number
}

type ActionType = 'create' | 'edit' | 'clear' | 'refresh'

type ActionTypes = { type: ActionType; payload?: DialogStateTypes }

const dialogReducer = (state: DialogStateTypes, action: ActionTypes): any => {
  switch (action.type) {
    case 'create':
    case 'edit':
      return action.payload
    case 'clear':
      return initialState
    case 'refresh':
      return { ...state, count: state.count && state.count + 1 }
    default:
      throw new Error()
  }
}

export const initialState: DialogStateTypes = {
  isOpen: false,
  basePath: null,
  resource: null,
  mode: '',
  variant: '',
  id: null,
  onSuccess: () => {},
  onFailure: () => {},
  handleSave: () => {},
  handleClear: () => {},
  count: 0,
}

export const UtilityTariffDialogContext = React.createContext<{
  state: DialogStateTypes
  dispatch: React.Dispatch<ActionTypes>
  handleSelect: Function
}>({
  state: initialState,
  dispatch: () => undefined,
  handleSelect: () => undefined,
})

const ElectricityUtilityAccordion = ({ disabled }) => {
  const [state, dispatch] = useReducer(dialogReducer, initialState)
  const form = useForm()
  const dispatchAction = useDispatch()
  const isStudioMode = useSelector(studioSelectors.isInStudio)
  const notify = useNotify()
  const translate = useTranslate()
  const handleSelect = useCallback((record, variant) => {
    form.batch(() => {
      form.registerField(`utility_tariff_${variant}_custom`, () => {}, {})
      form.change(`utility_tariff_${variant}_custom`, null)

      form.registerField(`utility_tariff_${variant}`, () => {}, {})
      form.change(`utility_tariff_${variant}`, record.url)

      form.registerField(`utility_tariff_${variant}_data`, () => {}, {})
      form.change(`utility_tariff_${variant}_data`, record)
    })
    dispatchAction(setSelectTariffDialogAction(false, null, null, null, isStudioMode))
    notify('ra.notification.updated', 'info')
  }, [])
  return (
    <UtilityTariffDialogContext.Provider value={{ state, dispatch, handleSelect }}>
      <AccordionCard
        defaultExpanded={true}
        disabled={disabled}
        name={'electricity-rate'}
        title={translate('Electricity Rate')}
        titleIcon={ListAltOutlinedIcon}
        content={ElectricityRateContent}
        onChange={(isExpanded) => {
          logAmplitudeEvent('energy_electricity_rate_interacted', {
            action: 'container_accordion_clicked',
            // more context
            accordion_state: isExpanded ? 'expanded' : 'collapsed',
          })
        }}
      />
      <SelectTariffDialog onSelect={handleSelect} />
      <UtilityTariffDialog />
    </UtilityTariffDialogContext.Provider>
  )
}
export default ElectricityUtilityAccordion

import { EditorPlaceholderCategory } from 'elements/wysiwyg/types'
import lodash from 'lodash'
import appStorage from 'storage/appStorage'
import { RootState } from 'types/state'
import {
  DocuSignCountersignerFields,
  DocumentInfo,
  DocumentPlaceholder,
  DocumentType,
  ReducerGenerateDocumentType,
} from '../types/redux_generateDocument'
export const GENERATE_DOCUMENT = 'GENERATE_DOCUMENT'
export const GENERATE_DOCUMENT_DIALOG = 'GENERATE_DOCUMENT_DIALOG'
export const GENERATE_DOCUMENT_LOADING = 'GENERATE_DOCUMENT_LOADING'
export const GENERATE_DOCUMENT_FAILURE = 'GENERATE_DOCUMENT_FAILURE'
export const GENERATE_DOCUMENT_SUCCESS = 'GENERATE_DOCUMENT_SUCCESS'
export const SET_IS_DOCUSIGN = 'SET_IS_DOCUSIGN'
export const SET_DISABLE_GENERATION = 'SET_DISABLE_GENERATION'
export const SET_COUNTERSIGNER_FIELDS = 'SET_COUNTERSIGNER_FIELDS'
export const SET_OPEN_DIALOG = 'SET_OPEN_DIALOG'
export const SET_CHANGE_ORDER_PLACEHOLDERS = 'SET_CHANGE_ORDER_PLACEHOLDERS'
const CLEAR_DOWNLOAD_URL = 'CLEAR_DOWNLOAD_URL'

let lastId = 1

type OtherDocumentData = {
  originalSystem: { uuid: string; payment_option_id: number }
  revisedSystem: { uuid: string; payment_option_id: number }
  placeholderData: Object
}

export const generateDocument = (
  orgId: number,
  projectId: number,
  documentType: string,
  documentTemplateId?: string,
  systemUuid?: string,
  selectedPaymentOptionId?: string,
  otherData?: OtherDocumentData
) => {
  const id = lastId++
  return {
    type: GENERATE_DOCUMENT,
    payload: {
      id,
      orgId,
      projectId,
      documentType,
      documentTemplateId,
      data: otherData ? { data: JSON.stringify(otherData) } : undefined,
      url:
        `projects/${projectId}/generate_document_pdf/${documentType}/` +
        (documentTemplateId ? `?document_template_id=${documentTemplateId}&` : '?') +
        `language=${appStorage.getLocale()}` +
        (systemUuid ? '&system_uuid=' + systemUuid : '') +
        (selectedPaymentOptionId ? '&payment_option_id=' + selectedPaymentOptionId : ''),
    },
    meta: { resource: 'projects', fetch: 'CUSTOM_POST', cancelPrevious: false },
  }
}

export const generateLiteReport = (
  orgId: number | undefined,
  projectId: number,
  systemUuid?: string,
  selectedPaymentOptionId?: string
) => {
  const id = lastId++
  return {
    type: GENERATE_DOCUMENT,
    payload: {
      id,
      orgId,
      projectId,
      url:
        'projects/' +
        projectId +
        '/generate_energy_report_lite/?' +
        (systemUuid ? 'system_uuid=' + systemUuid : '') +
        (selectedPaymentOptionId ? '&payment_option_id=' + selectedPaymentOptionId : ''),
    },
    meta: { resource: 'projects', fetch: 'CUSTOM_POST', cancelPrevious: false },
  }
}

export const setGenerateDocumentDialog = (isOpen: boolean) => ({
  type: GENERATE_DOCUMENT_DIALOG,
  payload: {
    isOpen,
  },
})

export const setOpenDialog = (openDialog: string) => ({
  type: SET_OPEN_DIALOG,
  payload: openDialog,
})

export const setChangeOrderPlaceholders = (placeholders: DocumentPlaceholder[]) => ({
  type: SET_CHANGE_ORDER_PLACEHOLDERS,
  payload: placeholders,
})

export const clearDownloadUrl = () => ({
  type: CLEAR_DOWNLOAD_URL,
  payload: {},
})

export const setIsDocusign = (isDocusign: boolean) => ({
  type: SET_IS_DOCUSIGN,
  payload: isDocusign,
})

export const setDisableGeneration = (disableGeneration: boolean) => ({
  type: SET_DISABLE_GENERATION,
  payload: disableGeneration,
})

export const setCountersignerFields = (counterSigner: DocuSignCountersignerFields) => ({
  type: SET_COUNTERSIGNER_FIELDS,
  payload: counterSigner,
})

const defaultState: ReducerGenerateDocumentType = {
  isOpen: false,
  // map: {},
  list: [],
  lastDoc: undefined,
  dialogOptions: {
    openDialog: 'initial',
    isDocusign: false,
    disableGeneration: false,
    changeOrderPlaceholders: [],
  },
  countersigner: {},
}

type args = {
  type: string
  meta: any
  payload: any
  requestPayload: any
}
export default (
  previousState: ReducerGenerateDocumentType = defaultState,
  { type, meta, payload, requestPayload }: args
) => {
  if (type === '@@router/LOCATION_CHANGE') {
    // Clear all records on change of location
    // This is the only way to change the "Download" button to "Generate" again after success
    //@ts-ignore I am not sure how to make the key optional on the inherited type
    previousState = defaultState
  }

  // So we're not modifying the actual default
  if (previousState === defaultState) previousState = lodash.cloneDeep(previousState)

  var documentData: DocumentType = requestPayload ? requestPayload : payload
  var error: string | undefined
  var clearDownloadurl: boolean = false

  const updateDocumentState = (
    documentData,
    status: string,
    data: DocumentInfo | undefined,
    error: string | null | undefined
  ) => {
    //@TODO: To track document generation separately for different systems, we would need to add systemUuid to key
    previousState.list = previousState.list.filter((d) => d.id !== documentData.id)

    const doc = {
      ...documentData,
      status,
      downloadUrl: data?.url,
      data: data,
      error: error,
    }
    previousState.list.push(doc)
    previousState.lastDoc = doc
  }

  switch (type) {
    case GENERATE_DOCUMENT_DIALOG:
      previousState = { ...previousState, isOpen: payload.isOpen } as ReducerGenerateDocumentType
      clearDownloadurl = true
      break

    case CLEAR_DOWNLOAD_URL:
      clearDownloadurl = true
      break

    case SET_OPEN_DIALOG:
      previousState = { ...previousState, dialogOptions: { ...previousState.dialogOptions, openDialog: payload } }
      break

    case SET_CHANGE_ORDER_PLACEHOLDERS:
      previousState = {
        ...previousState,
        dialogOptions: { ...previousState.dialogOptions, changeOrderPlaceholders: payload },
      }
      break

    case SET_IS_DOCUSIGN:
      previousState = { ...previousState, dialogOptions: { ...previousState.dialogOptions, isDocusign: payload } }
      break

    case SET_DISABLE_GENERATION:
      previousState = {
        ...previousState,
        dialogOptions: { ...previousState.dialogOptions, disableGeneration: payload },
      }
      break

    case GENERATE_DOCUMENT_SUCCESS:
      updateDocumentState(documentData, 'success', payload?.data, null)
      break

    case GENERATE_DOCUMENT_LOADING:
      updateDocumentState(documentData, 'loading', payload?.data, null)
      break

    case GENERATE_DOCUMENT_FAILURE:
      error = payload?.detail
      updateDocumentState(documentData, 'failure', payload?.data, error)
      break
    case SET_COUNTERSIGNER_FIELDS:
      return {
        ...previousState,
        countersigner: payload,
      }
  }

  if (clearDownloadurl) {
    previousState.list = previousState.list.filter((d) => !d.downloadUrl)
  }

  return previousState
}

export const generateDocumentSelectors = {
  getLastDocument(state: RootState): DocumentType | undefined {
    return state.generateDocument.lastDoc
  },
  getDocById(state: RootState) {
    return (id: number | undefined): DocumentType | undefined => {
      if (id === undefined) return
      return state.generateDocument.list.find((d) => d.id === id)
    }
  },
  getCountersignerFields(state: RootState): DocuSignCountersignerFields {
    return state.generateDocument.countersigner
  },
}

const CUSTOM_PLACEHOLDER_TYPES = ['text', 'number', 'date', 'richtext']
export const getDocumentPlaceholders = (placeholders, placeholderDefs: EditorPlaceholderCategory[]) => {
  if (!placeholders) return []
  let configItems: DocumentPlaceholder[] = []
  placeholders.forEach((field) => {
    const category = placeholderDefs.find((x) => {
      return !!x.items.find((y) => y.field === field.key || field.key.includes(y.field))
    })
    if (category) {
      let newItem
      const exactItem = category.items?.find((y) => y.field === field.key)
      if (exactItem) {
        newItem = exactItem
      } else {
        const modifiedItem = category.items?.find((y) => field.key.includes(y.field))
        const strippedField = field.key.replace(modifiedItem?.field, '')
        if (modifiedItem && strippedField && strippedField[0] === '|') newItem = modifiedItem
      }
      if (newItem && newItem.type !== 'snippet' && !configItems?.find((x) => x.field === newItem.field)) {
        configItems.push({ ...newItem, value: field.value, field: field.key })
      }
    } else if (field.key.includes('custom_placeholder_')) {
      //probably better to replace this check with regex
      const placeholderAttrs = field.key.split('|')
      if (
        placeholderAttrs.length === 3 &&
        placeholderAttrs[0].startsWith('custom_placeholder_') &&
        placeholderAttrs[1].startsWith('label:') &&
        placeholderAttrs[2].startsWith('type:') &&
        !configItems?.find((x) => x.field === field.key)
      ) {
        const fieldLabel = placeholderAttrs[1].replace('label:', '').trim()
        const fieldType = placeholderAttrs[2].replace('type:', '').trim()
        if (CUSTOM_PLACEHOLDER_TYPES.includes(fieldType)) {
          configItems.push({
            label: fieldLabel,
            desc: 'Custom placeholder - ' + fieldLabel,
            field: field.key,
            type: fieldType,
            customTranslate: undefined,
          })
        }
      }
    }
  })
  return configItems
}

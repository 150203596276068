import React, { useCallback } from 'react'
import type { FilterComponentRendererType, FilterGenericPropsType, FilterKeysType, OptionGenericType } from '../type'

export type DistributorBrandOptionsType = OptionGenericType<string>[]

type DistributorBrandFilterProps = FilterGenericPropsType & {
  RendererComponent: FilterComponentRendererType<DistributorBrandOptionsType>
  filterKey: FilterKeysType
  options: DistributorBrandOptionsType
}

const DistributorBrandFilter = ({
  disabled,
  label,
  options,
  filterKey,
  modifiableFilterValues,
  persistentFilterValues,
  allFilterValues,
  setModifiableFilters,
  RendererComponent,
}: DistributorBrandFilterProps) => {
  const onSelect = useCallback(
    (value) => {
      const newFilterValues = { ...modifiableFilterValues, [filterKey]: value }
      setModifiableFilters(newFilterValues)
    },
    [modifiableFilterValues]
  )

  return (
    <RendererComponent
      id={filterKey}
      defaultValue={''}
      label={label || 'Manufacturer' }
      options={options}
      disabled={disabled}
      onSelect={onSelect}
      value={allFilterValues[filterKey]}
    />
  )
}

export default DistributorBrandFilter

import { Field } from 'react-final-form'
import React from 'react'

/* This override react-final-form's default parse behavior.
 * The identity function ensures empty string can be used
 *
 * See https://final-form.org/docs/react-final-form/types/FieldProps
 *
 */

/*
 * emptyValue: explicitly setting value to an empty input
 * pristine could rely on this value
 */

const identity = (value, emptyValue) => {
  if (typeof value === 'boolean' || typeof value === 'number') {
    return value
  } else {
    if (emptyValue === undefined) {
      return value
    } else {
      return value || emptyValue
    }
  }
}

export default (props) => {
  const { emptyValue, ...rest } = props
  return <Field parse={(value) => identity(value, emptyValue)} {...rest} />
}

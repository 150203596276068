import { ModuleGridType } from 'types/global'
import type { BasicAccessorConfig } from './types'

const setter = ({ moduleGrid, value }: { moduleGrid: ModuleGridType; value: number }) => {
  window.editor.execute(new window.SetPanelConfigurationCommand(moduleGrid, 'modulesPerRow', value))
}

const getter = (moduleGrid: ModuleGridType): number => {
  return moduleGrid.modulesPerRow()
}

export const modulesPerRowAccessorConfig: BasicAccessorConfig<number> = {
  get: getter,
  set: setter,
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export var FileOutlineIcon = function (_a) {
    var variant = _a.variant, _b = _a.size, size = _b === void 0 ? '1rem' : _b, _c = _a.color, color = _c === void 0 ? 'currentColor' : _c, rest = __rest(_a, ["variant", "size", "color"]);
    var iconAttributes = {
        width: size,
        height: size,
        stroke: color,
    };
    switch (variant) {
        case 1:
            return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 18 22", fill: "none" }, rest, iconAttributes, { children: _jsx("path", { d: "M14.7995 1.4H3.59931C2.27383 1.4 1.19932 2.47451 1.19931 3.79999L1.19922 18.1999C1.19921 19.5254 2.27372 20.5999 3.59921 20.5999L14.3993 20.6C15.7247 20.6 16.7992 19.5255 16.7993 18.2001L16.7995 3.40004C16.7996 2.29546 15.9041 1.4 14.7995 1.4Z", stroke: color, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }) })));
        case 2:
            return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 18 22", fill: "none" }, rest, iconAttributes, { children: _jsx("path", { d: "M5.39959 6.20001H12.5996M5.39959 9.80001H12.5996M5.39959 13.4H8.99959M3.59931 1.40001H14.3995C15.725 1.40001 16.7996 2.47455 16.7995 3.80005L16.7993 18.2001C16.7992 19.5255 15.7247 20.6 14.3993 20.6L3.59921 20.6C2.27372 20.5999 1.19921 19.5254 1.19922 18.1999L1.19931 3.8C1.19932 2.47452 2.27383 1.40001 3.59931 1.40001Z", stroke: color, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }) })));
    }
};

import { makeStyles } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import FullScreenLoading from 'layout/widgets/FullScreenLoading'
import AuthModalWrapper from 'pages/auth/AuthModalWrapper'
import getSsoStartLink from 'pages/auth/sso/getSsoStartLink'
import type { PartnerIdType } from 'pages/auth/sso/types'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { OrgBrief } from 'types/orgs'
import { RootState } from 'types/state'
import { doNotTranslate } from 'util/misc'
import PartnerOrgSelect from './PartnerOrgSelect'
import PartnerLinkConfirmation from './components/PartnerLinkConfirmation'
import UserNotPaired from './components/UserNotPaired'
import { IRONRIDGE_CONFIG } from './ironridge/Config'
import type { PartnerConfigType } from './type'

export interface AvailableOrg extends OrgBrief {
  is_linked: boolean
  is_admin: boolean
}

export const AVAILABLE_PARTNERS: PartnerConfigType[] = [IRONRIDGE_CONFIG]

const useStyles = makeStyles((theme: OpenSolarThemeType) => ({
  wrapper: {
    margin: 20,
  },
}))

interface PropTypes {
  partner: PartnerIdType
}

const PartnerOrgLinking: React.FC<PropTypes> = ({ partner }) => {
  const classes = useStyles()
  const briefOrgs = useSelector(authSelectors.getBriefOrgs)
  const authReloading = useSelector(authSelectors.getAuthReloading)
  const userRoles = useSelector(authSelectors.getUserRoles)
  const isUserLinked = useSelector((state: RootState) => authSelectors.getIsPartnerUserPaired(state, partner))
  const ssoLink = useMemo(() => getSsoStartLink({ partnerId: partner }), [partner])

  useEffect(() => {
    logAmplitudeEvent('partner_org_linking_interacted', {
      action: 'viewed',
      context: 'org_linking_page_viewed',
      source: 'org_linking_page',
      partner: partner || 'unknown',
    })
  }, [])

  const partnerConfig = AVAILABLE_PARTNERS.find((config) => config.name === partner)
  const [orgSelected, setOrgSelected] = useState<AvailableOrg | null>(null)

  const availableOrgs: AvailableOrg[] | undefined = useMemo(() => {
    const filteredOrgs = briefOrgs?.map((org) => {
      const userRole = userRoles?.find((x) => x.org_id === org.id)
      return {
        ...org,
        is_linked: !!(partnerConfig?.partner_id && org.linked_partner_orgs?.[partnerConfig.partner_id]),
        is_admin: userRole ? userRole.is_admin : false,
      }
    })
    if (filteredOrgs?.length === 1) {
      const onlyOrg = { ...filteredOrgs[0] }
      if (onlyOrg.is_admin && !onlyOrg.is_linked) setOrgSelected(filteredOrgs[0])
    }
    return filteredOrgs
  }, [briefOrgs, userRoles])

  const handleUnselect = () => {
    logAmplitudeEvent('partner_org_linking_interacted', {
      action: 'cancelled',
      context: 'org_linking_cancelled',
      source: 'org_linking_page',
      partner: partner || 'unknown',
    })
    if (orgSelected) setOrgSelected(null)
  }
  const unlinkedOrgs = useMemo(() => availableOrgs?.filter((x) => x.is_linked === false && x.is_admin === true), [
    availableOrgs,
  ])

  if (authReloading) {
    return <FullScreenLoading />
  }

  if (partnerConfig === undefined) {
    // No translation for now until designer finalize the UI and copy
    return <div>{doNotTranslate(`Unrecognizable partner`)}</div>
  }

  return (
    <AuthModalWrapper>
      <div className={classes.wrapper}>
        {isUserLinked ? (
          <>
            {!!availableOrgs?.length && !!unlinkedOrgs?.length ? (
              <>
                {orgSelected?.id !== undefined ? (
                  <PartnerLinkConfirmation
                    selectedOrgId={orgSelected.id}
                    onCancel={handleUnselect}
                    partnerConfig={partnerConfig}
                  />
                ) : (
                  <PartnerOrgSelect
                    orgs={availableOrgs}
                    selectOrg={setOrgSelected}
                    partnerId={partnerConfig.partner_id}
                    partner={partnerConfig.displayName}
                  />
                )}
              </>
            ) : (
              <>{doNotTranslate(`No Org is available to link with ${partnerConfig.displayName}`)}</>
            )}
          </>
        ) : (
          <UserNotPaired partnerName={partnerConfig.displayName} partner={partnerConfig.partner_id} ssoLink={ssoLink} />
        )}
      </div>
    </AuthModalWrapper>
  )
}
export default PartnerOrgLinking

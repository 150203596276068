import { FIELDS_BY_PAGES } from 'projectSections/form/fields'
import { SharingErrorMsg } from 'reducer/project/projectErrorsReducer'

export const filterShareMessage = (msg: SharingErrorMsg, sharingSection?: string): SharingErrorMsg | undefined => {
  if (!sharingSection) return msg

  let filteredWarnings = {}
  let foundAny = false
  const sectionKey = sharingSection === 'online-proposal' ? 'onlineProposal' : sharingSection
  for (const [key, value] of Object.entries(msg)) {
    if (FIELDS_BY_PAGES[sectionKey]?.includes(key)) {
      filteredWarnings[key] = value
      foundAny = true
    }
  }
  return foundAny ? filteredWarnings : undefined
}

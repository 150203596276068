import { Grid, Theme, makeStyles, useMediaQuery } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import { orgSelectors } from 'ducks/orgs'
import { useTranslate } from 'ra-core'
import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

type PropTypes = {}

const useStyles = makeStyles<OpenSolarThemeType, { isMobile: boolean }>((theme) => ({
  linkText: {
    textDecoration: 'none',
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    padding: '14px',
    letterSpacing: 0.5,
  },

  configureLinksWrapper: {
    display: 'flex',
    marginTop: '26px',
    gap: '1rem',
    marginLeft: ({ isMobile }) => (isMobile ? '1rem' : '3.5rem'),
    overflow: ({ isMobile }) => (isMobile ? 'scroll' : 'unset'),
  },
}))

const CashFlowConfigurationNav: React.FC<PropTypes> = (props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const classes = useStyles({ isMobile })
  const translate = useTranslate()
  const hasApplied = useSelector(orgSelectors.getBlueSnapOnboardingIsSubmitted)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={8}>
        <div className={classes.configureLinksWrapper}>
          <NavLink
            to="/cashflow/configure/milestones"
            className={classes.linkText}
            activeStyle={{
              fontWeight: 'bold',
              background: '#F1F1F1',
              borderRadius: 10,
            }}
          >
            {translate('Payment Milestones')}
          </NavLink>
          <NavLink
            to="/cashflow/configure/financed_deposits"
            className={classes.linkText}
            activeStyle={{
              fontWeight: 'bold',
              background: '#F1F1F1',
              borderRadius: 10,
            }}
          >
            {translate('Financed Project Deposits')}
          </NavLink>
          <NavLink
            to="/cashflow/configure/communications"
            className={classes.linkText}
            activeStyle={{
              fontWeight: 'bold',
              background: '#F1F1F1',
              borderRadius: 10,
            }}
          >
            {translate('Customer Communications')}
          </NavLink>
          <NavLink
            to="/cashflow/configure/notifications"
            className={classes.linkText}
            activeStyle={{
              fontWeight: 'bold',
              background: '#F1F1F1',
              borderRadius: 10,
            }}
          >
            {translate('Internal Notifications')}
          </NavLink>
          {!hasApplied && (
            <NavLink
              to="/cashflow/configure/registration"
              className={classes.linkText}
              activeStyle={{
                fontWeight: 'bold',
                background: '#F1F1F1',
                borderRadius: 10,
              }}
            >
              {translate('Registration')}
            </NavLink>
          )}
          <NavLink
            to="/cashflow/configure/documents"
            className={classes.linkText}
            activeStyle={{
              fontWeight: 'bold',
              background: '#F1F1F1',
              borderRadius: 10,
            }}
          >
            {translate('Documents')}
          </NavLink>
        </div>
      </Grid>
      <Grid item xs={3}></Grid>
    </Grid>
  )
}

export default CashFlowConfigurationNav

import { ArchiveOutlined, MailOutlineOutlined, VisibilityOutlined } from '@material-ui/icons'
import { authSelectors } from 'ducks/auth'
import { generateDocumentSelectors, generateLiteReport } from 'ducks/generateDocument'
import Button from 'elements/button/Button'
import TooltipWithDisabledButton from 'persistentContent/elements/TooltipWithDisabledButton'
import ProjectFileDialog from 'projectSections/sections/info/projectFiles/ProjectFileDialog'
import { useTranslate } from 'ra-core'
import React, { useState } from 'react'
import { useForm } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { StudioSystemType } from 'types/global'
import { ProjectType } from 'types/projects'
import EmailConfirmDialog from '../EmailConfirmDialog'
import { useStyles } from './styles'

interface PropTypes {
  project: ProjectType
  system: StudioSystemType
  editMode: boolean
}

const ReportActionsPanel: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const form = useForm()
  const dirtyFields = form.mutators.getFormDirtyFields()
  const disableActions = dirtyFields?.length === 0
  const orgId = useSelector(authSelectors.getOrgId)
  const dispatch = useDispatch()
  const [docId, setDocId] = useState(0)
  const doc = useSelector(generateDocumentSelectors.getDocById)(docId)
  const translate = useTranslate()
  const [showLiteFiles, setShowLiteFiles] = useState(false)
  const [emailConfirmDialog, showConfirmDialog] = useState(false)

  const handleReportGeneration = () => {
    const doc = generateLiteReport(orgId, props.project.id, props.system.uuid)
    setDocId(doc.payload.id)
    dispatch(doc)
  }

  const handleEmailReport = () => {
    showConfirmDialog(true)
  }

  const handleClose = () => {
    setShowLiteFiles(false)
  }

  return (
    <>
      <div className={classes.buttonCont}>
        {(props.editMode || !disableActions) && (
          <div className={classes.buttons}>
            <TooltipWithDisabledButton
              title={
                props.editMode
                  ? translate('Please uncheck set report image boundaries to be able to generate the report')
                  : translate('Please wait until the project is saved')
              }
              label="Generate report"
              icon={<ArchiveOutlined />}
              clickFunc={handleReportGeneration}
              disabled={props.editMode || !disableActions}
              loading={doc?.status === 'loading'}
              buttonStyle={classes.buttons}
            />
            <TooltipWithDisabledButton
              title={
                props.editMode
                  ? translate('Please uncheck set report image boundaries to be able to email the report')
                  : translate('Please wait until the project is saved')
              }
              label="Email report"
              icon={<MailOutlineOutlined />}
              clickFunc={handleEmailReport}
              disabled={props.editMode || !disableActions}
              loading={undefined}
              buttonStyle={classes.buttons}
            />
          </div>
        )}
        {disableActions && !props.editMode && (
          <div className={classes.buttons}>
            <Button
              variant="outlined"
              startIcon={<ArchiveOutlined />}
              loading={doc?.status === 'loading'}
              label="Generate report"
              onClick={handleReportGeneration}
              disabled={!disableActions || props.editMode}
              fullWidth
            />
            <Button
              variant="outlined"
              startIcon={<MailOutlineOutlined />}
              label="Email report"
              onClick={() => showConfirmDialog(true)}
              disabled={!disableActions || props.editMode}
              fullWidth
            />
          </div>
        )}
        <Button
          className={classes.button}
          variant="contained"
          startIcon={<VisibilityOutlined />}
          label="View List of Generated Files"
          onClick={() => setShowLiteFiles(true)}
        />
      </div>
      {showLiteFiles && <ProjectFileDialog handleClose={handleClose} />}
      {emailConfirmDialog && (
        <EmailConfirmDialog
          system={props.system}
          project={props.project}
          close={() => showConfirmDialog(false)}
          isOpen={emailConfirmDialog}
        />
      )}
    </>
  )
}

export default ReportActionsPanel

import React, { useContext, useState } from 'react'

import { OpenSolarThemeType } from 'Themes'
import CloseDialogButton from 'elements/button/CloseDialogButton'
import { Dialog, DialogContent, DialogTitle, Grid } from 'opensolar-ui'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import UploadDocumentContent from './UploadDocumentContent'
import { BlueSnapOnboardingDocument } from './types'
import { DocumentPageContext } from './utils'
import { useTranslate } from 'ra-core'

type PropTypes = {
  open: boolean
  onClose: () => void
  document: BlueSnapOnboardingDocument | undefined
}

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

const UploadDocumentDialog: React.FC<PropTypes> = (props) => {
  const { open, onClose, document } = props
  const classes = useStyles()
  const translate = useTranslate()
  const { refreshDocuments } = useContext(DocumentPageContext)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleUploadSuccess = () => {
    onClose()
    refreshDocuments(3_000)
  }

  const handleClose = () => {
    if (!isSubmitting) {
      onClose()
    }
  }

  if (!document) return null

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <DialogTitle className={classes.dialogTitle}>
        {translate('Upload Your Document')}
        <CloseDialogButton onClose={handleClose} />
      </DialogTitle>
      <DialogContent>
        <Grid container justify="center">
          <Grid item xs={12} sm={12} md={10} xl={8}>
            <UploadDocumentContent
              document={document}
              onSuccess={handleUploadSuccess}
              isSubmitting={isSubmitting}
              setIsSubmitting={setIsSubmitting}
              isHosted={false}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default UploadDocumentDialog

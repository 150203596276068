import { styled } from 'opensolar-ui'
import { ToolTipRestyle } from '../styles'

interface PropTypes {
  notes: string
}

const NotesWrapper = styled('div')({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 200,
})

const NotesDisplay: React.FC<PropTypes> = ({ notes }) => {
  return notes ? (
    <ToolTipRestyle title={notes}>
      <NotesWrapper>{notes}</NotesWrapper>
    </ToolTipRestyle>
  ) : (
    <>{'－'}</>
  )
}

export default NotesDisplay

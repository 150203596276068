import { InputAdornment, makeStyles, TextField } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InfoTooltip from 'elements/tooltip/InfoTooltip'
import { IconButton } from 'opensolar-ui'
import React, { FC, useState } from 'react'
import { InputProps, useInput, useTranslate } from 'react-admin'
import { Theme } from 'types/themes'
import { required } from 'validations'

const useStyle = makeStyles<Theme>((theme) => ({
  infoIcon: {
    color: theme.infoIconColor,
  },
}))

type PropsType = {
  label?: string
  options?: any
  source?: string
  inputProps?: Partial<InputProps>
  scenario?: 'register'
}

const PasswordInput: FC<PropsType> = ({
  source = 'password',
  label = 'password',
  options = {},
  inputProps,
  scenario,
}) => {
  const translate = useTranslate()
  const classes = useStyle()
  const [showPassword, setPasswordVisibility] = useState(false)

  const validate = (value: string) => {
    const req = required(value)
    if (req) return req

    if (value.length < 8) {
      return 'Your password must be at least 8 characters.'
    }
    if (value.search(/[a-z]/i) < 0) {
      return 'Your password must contain at least one letter.'
    }
  }
  const type = showPassword ? 'text' : 'password'
  const {
    input,
    meta: { error, touched, invalid },
  } = useInput({
    source,
    type,
    validate,
  })

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }
  return (
    <TextField
      label={translate(label)}
      placeholder={translate(label)}
      margin="dense"
      error={touched && invalid}
      helperText={touched && error}
      validate={required}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {scenario === 'register' && (
              <InfoTooltip
                title={
                  <ul>
                    <li>{translate('Your password must be at least 8 characters.')}</li>
                    <li>{translate('Your password must contain at least one letter.')}</li>
                    <li>{translate("Your password can't be a commonly used password.")}</li>
                    <li>{translate("Your password can't be too similar to your other personal information.")}</li>
                  </ul>
                }
              />
            )}

            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setPasswordVisibility(!showPassword)}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
        ...inputProps,
      }}
      fullWidth={true}
      {...input}
      {...options}
    />
  )
}

export default PasswordInput

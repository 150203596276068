import { logAmplitudeEvent } from 'amplitude/amplitude'
import { triggerMilestoneRefresh } from 'ducks/projectMilestones'
import { Button } from 'opensolar-ui'
import { InvoicePaymentDetails } from 'pages/cashFlow/invoices/types'
import { PaymentRequestType } from 'pages/cashFlow/types'
import { cancelPaymentRequest } from 'pages/cashFlow/utils'
import { useNotify, useRefresh, useTranslate } from 'ra-core'
import React, { useState } from 'react'

import { useDispatch } from 'react-redux'
import CancelInvoiceConfirmation from './CancelInvoiceConfirmation'

type PropTypes = {
  paymentRequest: PaymentRequestType | InvoicePaymentDetails
  systemUuid: string
  disableButtons: boolean
  setDisableButtons: (val: boolean) => void
  setActionsAreShown: (val: boolean) => void
}

const CancelInvoiceButton: React.FC<PropTypes> = (props) => {
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false)
  const refresh = useRefresh()
  const translate = useTranslate()
  const notify = useNotify()
  const dispatch = useDispatch()

  const refreshMilestones = () => {
    dispatch(triggerMilestoneRefresh())
  }

  const closeDialog = () => {
    setDialogIsOpen(false)
    props.setActionsAreShown(false)
  }

  const cancelInvoice = () => {
    if (props.paymentRequest && props.paymentRequest.id) {
      props.setDisableButtons(true)
      cancelPaymentRequest(
        props.paymentRequest.org,
        props.paymentRequest.project,
        props.systemUuid,
        props.paymentRequest.payment_option,
        props.paymentRequest.id
      )
        .then(() => {
          refreshMilestones()
          notify(translate(`${props.paymentRequest.invoice_number} cancelled`), 'success')
          props.setActionsAreShown(false)
          logAmplitudeEvent('cashflow_invoice_cancelled', {
            source: 'invoices table',
            org_id: props.paymentRequest?.org,
            project_id: props.paymentRequest?.project,
            payment_request_id: props.paymentRequest?.id,
            payment_number: props.paymentRequest?.payment_number,
          })
        })
        .catch((err) => {
          notify(err, 'warning', { autoHideDuration: undefined })
        })
        .finally(() => {
          refresh()
          props.setDisableButtons(false)
        })
    }
  }

  return (
    <>
      <Button disabled={props.disableButtons} size="small" onClick={() => setDialogIsOpen(true)}>
        {translate('Cancel Invoice')}
      </Button>

      {dialogIsOpen && (
        <CancelInvoiceConfirmation
          paymentRequest={props.paymentRequest}
          cancelInvoice={cancelInvoice}
          closeDialog={closeDialog}
          disableButtons={props.disableButtons}
        />
      )}
    </>
  )
}

export default CancelInvoiceButton

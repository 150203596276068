import React, { memo, useCallback } from 'react'
import { ProjectErrorType } from 'reducer/project/projectErrorsReducer'
import DesignWarningBoxBasic from '../../../common/DesignWarningBoxBasic'

type PropsTypes = {
  componentIds: string[]
}

const ComponentWarningBox: React.FC<PropsTypes> = ({ componentIds }) => {
  const componentWarningFilter = useCallback((error: ProjectErrorType) => {
    if (error.componentId && componentIds.includes(error.componentId)) return true
    return false
  }, [])

  return <DesignWarningBoxBasic categories="all" extraFilter={componentWarningFilter} />
}

export default memo(ComponentWarningBox)

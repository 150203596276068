import HeterogeneousPanelGroup from './HeterogeneousPanelGroup'
import HomoModuleGridPanelGroup from './moduleGrid/HomoModuleGridPanelGroup'
import type { PanelGroupType } from './types'
import { isHomogeneousGroup } from './utils'

const HomogeneousPanelGroup: PanelGroupType = ({ group }) => {
  if (!isHomogeneousGroup(group)) {
    return <HeterogeneousPanelGroup group={group} />
  }

  const objectType = group.objects[0].type
  if (objectType === 'OsModuleGrid') {
    return <HomoModuleGridPanelGroup moduleGrids={group.objects} group={group} />
  }

  // Fall back to normal HeterogeneousPanelGroup if component type not get handled specifically
  return <HeterogeneousPanelGroup group={group} />
}

export default HomogeneousPanelGroup

import { InputAdornment, MenuItem, TextField } from '@material-ui/core'
import FlashIcon from '@material-ui/icons/FlashOnOutlined'
import { permissionsSelectors } from 'ducks/permissions'
import CustomSelectField from 'elements/field/CustomSelectField'
import _ from 'lodash'
import {
  inputValueToFloatOrEmptyStringWhenNull,
  ManageIconButton,
  valueOrEmptyStringIfNull,
} from 'projectSections/sections/design/systems/PanelSystem'
import React, { FC, useCallback, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { useStudioSignalsLazy } from 'Studio/signals/useStudioSignalsLazy'
import { markFieldActive, markFieldInactive } from 'Studio/Utils'
import { CommissionDataType } from 'types/commissions'
import { SystemDataType } from 'types/systems'
import { currencySymbolForCountry } from 'util/misc'
import { getOrgCountryCodeFromState } from 'util/org'

type PropsType = {
  system: SystemDataType
  pricingIsLocked: boolean
}

const getCurrentSelectedCommission = (system: SystemDataType) => {
  if (_.isNumber(system.commission_id) || _.isString(system.commission_id)) {
    return system.commission_id
  } else if (_.isNumber(system.commission_override_manually)) {
    return system.commission_override_manually === 0 ? 'no-commission' : 'manual'
  } else {
    return null
  }
}

//the biggest challenge for this component is both unset and manual are using same value
//the only way to differentiate them is to detect the value of commission_override_manually
//when commission_override_manually is integer then it indicates that it's using manually entered commission
const CommissionInputs: FC<PropsType> = ({ system, pricingIsLocked }) => {
  const { allowView, allowEdit } = useSelector(
    permissionsSelectors.getProjectPermissionByKey('design_commission_override')
  )
  const [selectedCommission, setSelectedCommissionValue] = useState(getCurrentSelectedCommission(system))
  const [manualOverrideValue, setManualOverrideValue] = useState<number | string | null>(
    system.commission_override_manually
  )

  const currencySymbol = useSelector((state: any) => {
    const countryIso2 = getOrgCountryCodeFromState(state)
    return currencySymbolForCountry(countryIso2)
  })

  const handleOverrideValueChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const value = event.target.value as string
      setManualOverrideValue(value)

      const currentSystem = window.editor.objectByUuid(system.uuid)
      var valueAsFloatOrNull = inputValueToFloatOrEmptyStringWhenNull(value)
      if (selectedCommission === 'manual' && valueAsFloatOrNull === null) {
        //always set commission_override_manually to a number value when enter commission manually
        window.editor.execute(new window.SetValueCommand(currentSystem, 'commission_override_manually', 0))
      } else {
        window.editor.execute(
          new window.SetValueCommand(currentSystem, 'commission_override_manually', valueAsFloatOrNull)
        )
      }
    },
    [selectedCommission]
  )

  const handleDropdownChange = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
    let value: string | null = event.target.value as string
    if (value === '') value = null

    setSelectedCommissionValue(value)

    const currentSystem = window.editor.objectByUuid(system.uuid)
    let commissionId: string | null = value

    if (value === 'manual' || value === 'no-commission') {
      const DEFAULT_OVERRIDE_VALUE = 0
      window.editor.execute(
        new window.SetValueCommand(currentSystem, 'commission_override_manually', DEFAULT_OVERRIDE_VALUE)
      )
    } else {
      // a custom commission option  or the auto-apply option
      // clear the commission_override_manually value if set
      if (_.isNumber(currentSystem.commission_override_manually)) {
        window.editor.execute(new window.SetValueCommand(currentSystem, 'commission_override_manually', null))
      }
    }

    // set the commission_id of system
    // if value is null -> auto-apply
    // if value is a number -> custom commission
    // if string -> either manual or no-commission
    window.editor.execute(new window.SetValueCommand(currentSystem, 'commission_id', commissionId))

    // Any manual update should break auto-sync
    currentSystem.autoSync.commission = false
  }, [])

  const refreshState = useCallback(() => {
    const newSelectedCommission = getCurrentSelectedCommission(system)
    if (newSelectedCommission !== selectedCommission) {
      setSelectedCommissionValue(newSelectedCommission)
    }
    if (newSelectedCommission === 'manual' && system.commission_override_manually === 0 && !manualOverrideValue) {
      //do not bother to update manualOverrideValue
      //handle case like deleting last int number from input
    } else if (system.commission_override_manually !== manualOverrideValue) {
      setManualOverrideValue(system.commission_override_manually)
    }
  }, [system, manualOverrideValue, selectedCommission])

  useStudioSignalsLazy(refreshState, ['objectChanged', 'sceneLoaded'], undefined, { trackHandler: true })

  const translate = useTranslate()
  const choices = window.AccountHelper.loadedData.commissions
    .filter((commission: CommissionDataType) => !commission.is_archived || system.commission_id === commission.id)
    .map((commission: CommissionDataType) => ({
      id: commission.id,
      title: commission.is_archived ? commission.title + ' (' + translate('Archived') + ')' : commission.title,
    }))

  if (!allowView) {
    return null
  }
  return (
    <>
      <div style={{ display: 'flex' }}>
        <CustomSelectField
          style={{ flex: '1 1', width: 'auto' }}
          label={
            <span>
              {translate('Commission')}
              {window.editor.selectedSystem?.autoSync?.commission === true && (
                <FlashIcon style={{ height: 20, verticalAlign: 'top' }} />
              )}
            </span>
          }
          MenuProps={{ style: { zIndex: 2500 } }}
          displayEmpty={true}
          value={selectedCommission}
          onChange={handleDropdownChange}
          disabled={pricingIsLocked || !allowEdit}
        >
          <MenuItem value={''} key="null">
            {translate('Auto-apply')}
          </MenuItem>
          <MenuItem value={'no-commission'} key="no-commission">
            {translate('No Commission')}
          </MenuItem>
          {choices.map((choice) => (
            <MenuItem key={choice.id} value={choice.id}>
              {choice.title}
            </MenuItem>
          ))}
          <MenuItem key="manual" className="manual-item" value={'manual'}>
            {translate('Enter Commission Manually')}
          </MenuItem>
        </CustomSelectField>
        {
          <ManageIconButton
            heading={translate('Leave Studio to manage available Commissions?')}
            tooltipLabel={translate('Manage Commissions')}
            url={'/commissions'}
          />
        }
      </div>
      {selectedCommission === 'manual' && (
        <TextField
          id="commission-override-manually-input"
          type="number"
          style={{ margin: '20px 0' }}
          label={translate('Enter Commission')}
          fullWidth={true}
          name="Enter Commission Manually"
          value={valueOrEmptyStringIfNull(manualOverrideValue)}
          onFocus={() => {
            markFieldActive('commissionInputs', system)
          }}
          onBlur={() => {
            markFieldInactive()
          }}
          InputProps={{
            startAdornment: <InputAdornment position="end">{currencySymbol}</InputAdornment>,
          }}
          onChange={handleOverrideValueChange}
        />
      )}
    </>
  )
}

export default CommissionInputs

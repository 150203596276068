import React, { useState } from 'react'
import type { EditNotesType } from '../../type'
import InventoryTableEditNote from './InventoryTableEditNote'
import InventoryTableEditTop from './InventoryTableEditTop'

const DEFAULT_STATE = {
  reason: 'Stocktake',
  note: '',
}

type EditNotesReturnType = {
  InventoryTableEditTopInputs: React.ReactNode
  InventoryTableEditNoteInputs: React.ReactNode
  editNoteState: EditNotesType
}

const useInventoryTableEditNotes = (): EditNotesReturnType => {
  const [state, setState] = useState<EditNotesType>(DEFAULT_STATE)

  const handleChange = ({ field, value }: { field: string; value: string }) => {
    setState((oldState) => {
      return {
        ...oldState,
        [field]: value,
      }
    })
  }

  // This includes reason and note fields
  const InventoryTableEditTopInputs = <InventoryTableEditTop state={state} handleChange={handleChange} />
  // This includes note fields only
  const InventoryTableEditNoteInputs = <InventoryTableEditNote state={state} handleChange={handleChange} />

  return {
    InventoryTableEditTopInputs,
    InventoryTableEditNoteInputs,
    editNoteState: state,
  }
}

export default useInventoryTableEditNotes

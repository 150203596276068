var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import PropTypes from 'prop-types';
import Title, { TitlePropType } from './Title';
import { useTranslate } from 'ra-core';
var TitleForRecord = function (_a) {
    var defaultTitle = _a.defaultTitle, record = _a.record, title = _a.title, subtitle = _a.subtitle;
    var translate = useTranslate();
    return (_jsxs(_Fragment, { children: [record && _jsx(Title, { title: title, record: record, defaultTitle: defaultTitle }), subtitle && (_jsx("p", __assign({ style: {
                    color: 'rgba(0, 0, 0, 0.54)',
                    margin: 0,
                } }, { children: typeof subtitle === 'string' ? translate(subtitle) : subtitle })))] }));
};
TitleForRecord.propTypes = {
    defaultTitle: PropTypes.any,
    record: PropTypes.object,
    title: TitlePropType,
};
export default TitleForRecord;

import { WorkflowType } from 'types/workflows'

export function getAutoAdvanceSoldLabel(
  project: any,
  orgId: number | undefined,
  orgWorkflows: WorkflowType[],
  formState: any,
  translate: (label: string, params?: {}) => string
) {
  // Advance only when the current org is the project owner
  if (project.org_id !== orgId) {
    return null
  }

  if (!('project_sold' in formState.dirtyFields)) {
    return null
  }

  const currentWorkflow = orgWorkflows.find((e) => e.id === project.workflow.workflow_id)
  if (!currentWorkflow?.is_automated) {
    return null
  }
  const currentStage = currentWorkflow?.workflow_stages.find((e) => e.id === project.workflow.active_stage_id)

  if ([1, 2].includes(currentStage?.milestone ?? 0) && !project.project_sold) {
    return translate(
      'Your project stage signifies a sold project but your project is not marked as sold in the Sale section.'
    )
  }

  if (currentStage?.milestone === 3 && !project.project_sold) {
    return translate(
      'Your project stage signifies an installed project but your project is not marked as sold in the Sale section.'
    )
  }

  if (currentStage?.milestone === 4) {
    return translate('Please revert the project stage to active to access this section.')
  }

  if (
    (formState.initialValues.project_sold ?? 0) < 2 &&
    project.project_sold === 2 &&
    (currentStage?.milestone ?? 0) < 2
  ) {
    let nextStage = currentWorkflow?.workflow_stages.find((e) => e.milestone === 2 && !e.is_archived)
    if (nextStage) {
      return translate('Project will be advanced to %{stage_name} stage.', { stage_name: nextStage?.title })
    }

    // No stage for milestone 2, find the next most advanced stage available.
    const checkMilestones = [1, 0]
    for (const i of checkMilestones) {
      nextStage = currentWorkflow?.workflow_stages
        .filter((e) => e.milestone === i && !e.is_archived)
        .sort((a, b) => b.order - a.order)[0]
      if (nextStage) {
        return translate('Project will be advanced to %{stage_name} stage.', { stage_name: nextStage?.title })
      }
    }
  }

  if (
    !project.project_sold &&
    [1, 2].includes(formState.initialValues.project_sold ?? 0) &&
    [2, 3].includes(currentStage?.milestone ?? 0) &&
    !formState.dirtyFields.project_installed
  ) {
    const checkMilestones = [1, 0]
    for (const i of checkMilestones) {
      const revertStage = currentWorkflow?.workflow_stages
        .filter((e) => e.milestone === i && !e.is_archived)
        .sort((a, b) => b.order - a.order)[0]
      if (revertStage) {
        return translate('Project will be reverted to %{stage_name} stage.', { stage_name: revertStage?.title })
      }
    }
  }
}

export function getAutoAdvanceInstalledLabel(
  project: any,
  orgId: number | undefined,
  orgWorkflows: WorkflowType[],
  formState: any,
  translate: (label: string, params?: {}) => string
) {
  // Advance only when the current org is the project owner
  if (project.org_id !== orgId) {
    return null
  }

  if (!('project_installed' in formState.dirtyFields)) {
    return null
  }

  const currentWorkflow = orgWorkflows.find((e) => e.id === project.workflow.workflow_id)
  if (!currentWorkflow?.is_automated) {
    return null
  }
  const currentStage = currentWorkflow?.workflow_stages.find((e) => e.id === project.workflow.active_stage_id)

  if (currentStage?.milestone === 3 && !project.project_installed) {
    return translate(
      'Your project stage signifies an installed project but your project is not marked as installed in the Installation section.'
    )
  }

  if (currentStage?.milestone === 4) {
    return translate('Please revert the project stage to active to access this section.')
  }

  if (
    (formState.initialValues.project_installed ?? 0) < 2 &&
    project.project_installed === 2 &&
    (currentStage?.milestone ?? 0) < 3
  ) {
    let nextStage = currentWorkflow?.workflow_stages.find((e) => e.milestone === 3 && !e.is_archived)
    if (nextStage) {
      return translate('Project will be advanced to %{stage_name} stage.', { stage_name: nextStage?.title })
    }

    // No stage for milestone 3, find the next most advanced stage available.
    const checkMilestones = [2, 1, 0]
    for (const i of checkMilestones) {
      nextStage = currentWorkflow?.workflow_stages
        .filter((e) => e.milestone === i && !e.is_archived)
        .sort((a, b) => b.order - a.order)[0]
      if (nextStage) {
        return translate('Project will be advanced to %{stage_name} stage.', { stage_name: nextStage?.title })
      }
    }
  }

  if (
    [1, 2].includes(formState.initialValues.project_installed ?? 0) &&
    !project.project_installed &&
    (currentStage?.milestone ?? 0) === 3
  ) {
    const checkMilestones = [2, 1, 0]
    for (const i of checkMilestones) {
      const revertStage = currentWorkflow?.workflow_stages
        .filter((e) => e.milestone === i && !e.is_archived)
        .sort((a, b) => b.order - a.order)[0]
      if (revertStage) {
        return translate('Project will be reverted to %{stage_name} stage.', { stage_name: revertStage?.title })
      }
    }
  }
}

function setActiveStageId(form, formState, stageId) {
  // tries keep track of the current value of the workflow field even after we advanced it to the next stage using the automation
  if (formState.dirtyFields.workflow) {
    const field = 'workflow'
    const initialValue = { ...formState.initialValues[field], active_stage_id: stageId }
    const userValue = formState.values[field]
    form.mutators.updateFieldSilently(field, initialValue) // will clear the user value
    form.mutators.updateField(field, userValue) // reset the actual value to whatever the user has selected
  } else {
    form.mutators.updateField('workflow.active_stage_id', stageId)
  }
}

export function autoAdvanceSoldProject(
  project: any,
  orgId: number | undefined,
  orgWorkflows: WorkflowType[],
  formState: any,
  form: any
) {
  // Advance only when the current org is the project owner
  if (project.org_id !== orgId) {
    return
  }

  const currentWorkflow = orgWorkflows.find((e) => e.id === project.workflow.workflow_id)
  if (!currentWorkflow?.is_automated) {
    return
  }

  const currentStage = currentWorkflow?.workflow_stages.find((e) => e.id === project.workflow.active_stage_id)
  const currentMilestone = currentStage?.milestone ?? 0
  const initialProjectSold = formState.initialValues.project_sold ?? 0

  if (project.project_sold === 2 && initialProjectSold < 2 && currentMilestone < 2) {
    // If project has been marked as sold and the current stage's milestone isn't 'Sold', advance to the next stage.
    let nextStage = currentWorkflow?.workflow_stages.find((e) => e.milestone === 2 && !e.is_archived)
    if (nextStage) {
      setActiveStageId(form, formState, nextStage?.id)
    } else {
      // In case there's no stage for milestone 2, find the next most advanced stage available.
      const checkMilestones = [1, 0]
      for (const i of checkMilestones) {
        nextStage = currentWorkflow?.workflow_stages
          .filter((e) => e.milestone === i && !e.is_archived)
          .sort((a, b) => b.order - a.order)[0]
        if (nextStage) {
          setActiveStageId(form, formState, nextStage?.id)
          break
        }
      }
    }
  } else if (
    !project.project_sold &&
    [1, 2].includes(initialProjectSold) &&
    [2, 3].includes(currentMilestone) &&
    !formState.dirtyFields.project_installed
  ) {
    // If project has been marked as unsold and the current stage's milestone is 'Sold', revert to the previous stage.
    const checkMilestones = [1, 0]
    for (const i of checkMilestones) {
      const revertStage = currentWorkflow?.workflow_stages
        .filter((e) => e.milestone === i && !e.is_archived)
        .sort((a, b) => b.order - a.order)[0]
      if (revertStage) {
        setActiveStageId(form, formState, revertStage?.id)
        break
      }
    }
  } else if (formState.dirtyFields.project_sold && formState.initialValues.project_sold === project.project_sold) {
    // If the project_sold field was changed but the value is the same as the initial value, reset the active stage to the initial
    setActiveStageId(form, formState, formState.initialValues.workflow.active_stage_id)
  }
}

export function autoAdvanceInstalledProject(
  project: any,
  orgId: number | undefined,
  orgWorkflows: WorkflowType[],
  formState: any,
  form: any
) {
  // Advance only when the current org is the project owner
  if (project.org_id !== orgId) {
    return
  }

  const currentWorkflow = orgWorkflows.find((e) => e.id === project.workflow.workflow_id)
  if (!currentWorkflow?.is_automated) {
    return
  }

  const currentStage = currentWorkflow?.workflow_stages.find((e) => e.id === project.workflow.active_stage_id)
  const currentMilestone = currentStage?.milestone ?? 0
  const initialProjectInstalled = formState.initialValues.project_installed ?? 0

  if (project.project_installed === 2 && initialProjectInstalled < 2 && currentMilestone < 3) {
    // If project has been marked as installed and the current stage's milestone isn't 'Installed', advance to the next stage.
    let nextStage = currentWorkflow?.workflow_stages.find((e) => e.milestone === 3 && !e.is_archived)
    if (nextStage) {
      setActiveStageId(form, formState, nextStage?.id)
    } else {
      // No stage for milestone 3, find the next most advanced stage available.
      const checkMilestones = [2, 1, 0]
      for (const i of checkMilestones) {
        nextStage = currentWorkflow?.workflow_stages
          .filter((e) => e.milestone === i && !e.is_archived)
          .sort((a, b) => b.order - a.order)[0]
        if (nextStage) {
          setActiveStageId(form, formState, nextStage?.id)
          break
        }
      }
    }
  } else if (!project.project_installed && [1, 2].includes(initialProjectInstalled) && currentMilestone === 3) {
    // If project has been marked as uninstalled and the current stage's milestone is 'Installed', revert to the previous stage.
    const checkMilestones = [2, 1, 0]
    for (const i of checkMilestones) {
      const revertStage = currentWorkflow?.workflow_stages
        .filter((e) => e.milestone === i && !e.is_archived)
        .sort((a, b) => b.order - a.order)[0]
      if (revertStage) {
        setActiveStageId(form, formState, revertStage?.id)
        break
      }
    }
  } else if (
    formState.dirtyFields.project_installed &&
    formState.initialValues.project_installed === project.project_installed
  ) {
    // If the project_installed field was changed but the value is the same as the initial value, reset the active stage to the initial
    setActiveStageId(form, formState, formState.initialValues.workflow.active_stage_id)
  }
}

export function autoAdvanceOnProposalAccepted(project, orgId, orgWorkflows, formState, form) {
  // Advance only when the current org is the project owner
  if (project.org_id !== orgId) {
    return
  }

  const currentWorkflow = orgWorkflows.find((e) => e.id === project.workflow.workflow_id)
  if (!currentWorkflow?.is_automated) {
    return
  }

  const currentStage = currentWorkflow?.workflow_stages.find((e) => e.id === project.workflow.active_stage_id)
  if ((currentStage?.milestone ?? 0) < 2) {
    let nextStage = currentWorkflow?.workflow_stages.find((e) => e.milestone === 2 && !e.is_archived)
    if (nextStage) {
      const workFlowValues = { ...project.workflow, active_stage_id: nextStage?.id }
      form.mutators.updateFieldSilently('workflow', workFlowValues)
    } else {
      // No stage for milestone 2, find the next most advanced stage available.
      const checkMilestones = [1, 0]
      for (const i of checkMilestones) {
        nextStage = currentWorkflow?.workflow_stages
          .filter((e) => e.milestone === i && !e.is_archived)
          .sort((a, b) => b.order - a.order)[0]
        if (nextStage) {
          const workFlowValues = { ...project.workflow, active_stage_id: nextStage?.id }
          form.mutators.updateFieldSilently('workflow', workFlowValues)
          break
        }
      }
    }
  }
}

import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import React from 'react'

type PropTypes = {
  open: boolean
  onClose: () => void
  onAccept?: () => void
  terms: string
  hideDismiss: boolean
}

const PremiumProductTermsDialog: React.FC<PropTypes> = (props) => {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogContent>
        <div dangerouslySetInnerHTML={{ __html: props.terms }} />
      </DialogContent>
      <DialogActions>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {!props.hideDismiss && (
            <div style={{ margin: '20px' }}>
              <ProUXButton type="secondary" label="Dismiss" onClick={props.onClose} />
            </div>
          )}
          {props.onAccept && (
            <div id="i_igree_nearmap_term" style={{ margin: '20px' }}>
              <ProUXButton type="primary" label="I Agree" onClick={props.onAccept} />
            </div>
          )}
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default PremiumProductTermsDialog

import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import _ from 'lodash'
import {
  DAYS_BY_MONTH,
  MONTHS,
  getYearChoices,
} from 'myenergy/selectionComponent/loanApplicationButton/generic/hostedForms/constants'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { PSPOnboardingFieldType } from './types'

type PropTypes = {
  field: PSPOnboardingFieldType
}

type ChoiceType = {
  name: string
  id: string
}

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    display: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginTop: 20,
  },

  sectionLabel: {
    marginBottom: '15px',
  },
  fieldsRow: {
    display: 'flex',
    columnGap: '10px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  field: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}))

const DateOfBirthInputGroup: React.FC<PropTypes> = (props) => {
  const form = useForm()
  const formState = useFormState()
  const classes = useStyles()

  const getDatePart = useCallback(
    (datePart: 'day' | 'month' | 'year') => {
      const valueInForm = _.get(formState.values, props.field.field_name)
      if (valueInForm && valueInForm?.length === 10) {
        const dateParts = valueInForm.split('/')
        if (dateParts && dateParts.length === 3) {
          if (datePart === 'month') return dateParts[0]
          else if (datePart === 'day') return dateParts[1]
          else if (datePart === 'year') return dateParts[2]
        }
      }
      return undefined
    },
    [formState.values?.[props.field.field_name]]
  )

  const [selectedDay, setSelectedDay] = useState<string | undefined>(getDatePart('day'))
  const [selectedMonth, setSelectedMonth] = useState<string | undefined>(getDatePart('month'))
  const [selectedYear, setSelectedYear] = useState<string | undefined>(getDatePart('year'))

  // once all 3 date parts are populated update the form field with the formatted date value
  useEffect(() => {
    if (selectedMonth && selectedDay && selectedYear) {
      const newFormattedDOBValue = `${selectedMonth}/${selectedDay}/${selectedYear}`
      form.change(props.field.field_name, newFormattedDOBValue)
    }
  }, [selectedDay, selectedMonth, selectedYear])

  const daysChoices = selectedMonth ? DAYS_BY_MONTH[selectedMonth] : DAYS_BY_MONTH['none']
  return (
    <div className={classes.wrapper}>
      <div className={classes.sectionLabel}>{props.field.field_label}</div>
      <div className={classes.fieldsRow}>
        <div className={classes.field}>
          <FormControl variant="outlined" size="small" required>
            <InputLabel id="day">Day</InputLabel>
            <Select
              labelId="day"
              label="Day"
              defaultValue={selectedDay}
              value={selectedDay}
              onChange={(e) => setSelectedDay(e.target.value as string)}
              fullWidth
            >
              {daysChoices.map((choice) => (
                <MenuItem value={`${choice.id}`} key={choice.id}>
                  {choice.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.field}>
          <FormControl variant="outlined" size="small" required>
            <InputLabel id="month">Month</InputLabel>
            <Select
              labelId="month"
              label="Month"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value as string)}
              fullWidth
              style={{
                padding: '0px',
              }}
            >
              {MONTHS.map((choice) => (
                <MenuItem value={choice.id} key={choice.id}>
                  {choice.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.field}>
          <FormControl variant="outlined" size="small" required>
            <InputLabel id="year">Year</InputLabel>
            <Select
              labelId="year"
              label="Year"
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value as string)}
              fullWidth
            >
              {getYearChoices()?.map((choice) => (
                <MenuItem value={choice.id} key={choice.id}>
                  {choice.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  )
}
export default DateOfBirthInputGroup

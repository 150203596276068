import { logAmplitudeEvent } from 'amplitude/amplitude'
import { Button } from 'opensolar-ui'
import React, { useEffect, useState } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { ProOnlyFinanceCTAType } from 'types/orgs'
import CTADialog from './CTADialog'

const useStyles = makeOpenSolarStyles((theme) => ({
  mianWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logoWrapper: {
    margin: '0px 10px 0px 0px',
  },
}))
type PropTypes = {
  cta: ProOnlyFinanceCTAType
}

const DialogCTAButton: React.FC<PropTypes> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    if (props.cta?.integration) {
      // carving out deep green to make sure we are consistent with historical events
      if (props.cta.integration === 'deep_green') {
        logAmplitudeEvent('deep_green_cta_shown')
      } else {
        logAmplitudeEvent('finance_proposal_dialog_cta_shown', { integration: props.cta?.integration })
      }
    }
  }, [props.cta?.integration])

  const classes = useStyles()
  return (
    <div>
      <Button variant="outlined" color="primary" size="small" onClick={() => setIsOpen(true)}>
        <div className={classes.mianWrapper}>
          {props.cta?.include_logo && (
            <div className={classes.logoWrapper}>
              <img height="20px" src={props.cta.logo_url} />
            </div>
          )}
          <span style={{ textTransform: 'none' }}>{props.cta.cta_main_text}</span>
        </div>
      </Button>
      {isOpen && <CTADialog cta={props.cta} isOpen={true} onClose={() => setIsOpen(false)} />}
    </div>
  )
}
export default DialogCTAButton

import { FormApi } from 'final-form'

export const silentlyUpdateFormFields = (
  form: FormApi,
  fieldsToUpdate: Object,
  onlyUpdateIfCurrentlyBlank: boolean
) => {
  if (!form || !fieldsToUpdate) return []
  else {
    const currentFormVals = form.getState().values
    const fieldsUpdated: string[] = []
    Object.keys(fieldsToUpdate)?.forEach((fieldName: string) => {
      const newValue = fieldsToUpdate[fieldName]
      const existingValue = currentFormVals[fieldName]

      if (newValue !== existingValue && (!onlyUpdateIfCurrentlyBlank || !existingValue)) {
        form.mutators.updateFieldSilently(fieldName, newValue)
        fieldsUpdated.push(fieldName)
      }
    })
    return fieldsUpdated
  }
}

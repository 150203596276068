import React, { useState, useEffect } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  root: {
    minWidth: 125,
    minHeight: 100,
    maxWidth: 250,
    maxHeight: 340,
  },
}))

const ProductImage = ({ imageUrl }: { imageUrl: string | undefined }) => {
  const placeHolderImage = `${window.PUBLIC_URL}/images/component_photo_holder.svg`
  const initialImage = imageUrl || placeHolderImage
  const [imgSrc, setImageSrc] = useState(initialImage)

  useEffect(() => {
    setImageSrc(imageUrl || placeHolderImage);
  }, [imageUrl]);

  const classes = useStyles()
  return (
    <img
      className={classes.root}
      src={imgSrc}
      onError={(e) => {
        setImageSrc(placeHolderImage)
      }}
    />
  )
}

export default ProductImage

// @ts-nocheck
import { Divider, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import OpenWithIcon from '@material-ui/icons/OpenWithOutlined'
import SchoolIcon from '@material-ui/icons/SchoolOutlined'
import { IconButton } from 'opensolar-ui'
import React, { ReactElement } from 'react'
import Draggable from 'react-draggable'
import { PlacementAndArrows } from '../tools/ToolTipArrows'

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 10px',
    '&:hover .moveIcon': {
      color: 'rgba(0,0,0,0.54)!important',
    },
  },
  title: {
    display: 'inline-block',
    fontSize: 14,
    fontWeight: 500,
    margin: 0,
    verticalAlign: 'middle',
    textTransform: 'uppercase',
  },
  titleWrapper: {
    margin: '10px 0',
  },
  titleIcon: {
    color: theme.blue,
    verticalAlign: 'middle',
    marginRight: 7,
  },
  closeIcon: { color: '#000000' },
  moveIcon: {
    verticalAlign: 'middle',
    height: 44,
    color: 'rgba(0,0,0,0.30)',
  },
  closeButton: { padding: 0, width: 40, height: 44 },
}))

interface ToolTipType extends HeaderType {
  content?: ReactElement
  icon?: any
  actions?: ReactElement
  image?: ReactElement
  arrowPlacement?: string
  disableDrag?: boolean
  // titleIcon: React.FunctionComponent<any>
}

type HeaderType = {
  title?: string | ReactElement
  onClose?: Function
  disableDrag: boolean
}

const Header = ({ title, icon, onClose, disableDrag }: HeaderType) => {
  const classes = useStyles()
  return (
    <div style={{ cursor: disableDrag ? 'default' : 'move' }} className={classes.headerWrapper}>
      {title && (
        <div className={classes.titleWrapper}>
          {icon ? (
            React.createElement(icon, { className: classes.titleIcon })
          ) : (
            <SchoolIcon className={classes.titleIcon} />
          )}
          <span className={classes.title}>{typeof title === 'string' ? title : React.cloneElement(title)}</span>
        </div>
      )}
      {onClose && (
        <IconButton
          className={classes.closeButton}
          onTouchStart={(e) => {
            e.stopPropagation()
            onClose()
          }}
          onClick={() => onClose()}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      )}
      {!disableDrag && <OpenWithIcon className={`moveIcon ${classes.moveIcon}`} />}
    </div>
  )
}

const ToolTip = ({
  title,
  icon,
  onClose,
  content,
  actions,
  image,
  arrowPlacement,
  disableDrag = false,
}: ToolTipType) => {
  return (
    <Draggable disabled={disableDrag}>
      <Paper style={{ borderRadius: 8, maxWidth: 400, pointerEvents: 'all' }}>
        <Header title={title} icon={icon} onClose={onClose} disableDrag={disableDrag} />
        <Divider />
        {image && React.cloneElement(image)}
        {content && <div style={{ padding: '10px 20px', fontWeight: 'lighter' }}>{React.cloneElement(content)}</div>}
        {actions && <div style={{ padding: '0 20px 10px 0', textAlign: 'right' }}>{React.cloneElement(actions)}</div>}
        {arrowPlacement &&
          PlacementAndArrows[arrowPlacement] &&
          React.createElement(PlacementAndArrows[arrowPlacement])}
      </Paper>
    </Draggable>
  )
}

export default ToolTip

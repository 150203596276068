import { ReorderComponentButton } from 'projectSections/sections/design/systems/tabs/common/ReorderComponentButton'
import React, { ReactNode, useCallback, useMemo, useState } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { DragMode } from './DraggableList'

type Props<T> = {
  items: T[]
  render(
    item: T,
    opts: {
      index: number
      dragHandle: ReactNode | undefined
    }
  ): ReactNode
  handleReorder(newItems: T[]): void
  dragMode?: DragMode
}
/*
DraggableFlow is a simplified version of a DraggableList wherein the rendered items 
can still be reordered while list items are wrapped or put in a grid display

IMPORTANT NOTE: While testing on local, seems like this implementation does NOT work for mobile 
*/

const useStyles = makeOpenSolarStyles(
  {
    item: {
      cursor: 'grab',
    },
    dragging: {
      opacity: 0.4,
    },
  },
  { name: 'DraggableFlow' }
)

export function DraggableFlow<T>({ items, render, handleReorder, dragMode = 'drag-handle-if-mobile' }: Props<T>) {
  const classes = useStyles()
  const [temporaryItems, setTemporaryItems] = useState<T[]>()
  const [draggedItem, setDraggedItem] = useState<T>()
  const [isDragging, setIsDragging] = useState<boolean>(false)
  const renderedItems = useMemo(() => temporaryItems || items, [temporaryItems, items])

  let isMobileDevice = useMemo(() => window.Utils?.isTouchDevice(), [])
  const showDragHandle = useMemo(() => {
    switch (dragMode) {
      case 'whole-element':
        return false
      case 'drag-handle-if-mobile':
        if (!isMobileDevice) return false
    }
    return true
  }, [dragMode, isMobileDevice])

  const renderChild = useCallback(
    (item: T, index: number) => {
      const dragHandle = !showDragHandle ? undefined : <DragHandle setIsDragging={setIsDragging} />
      return <>{render(item, { index, dragHandle })}</>
    },
    [render, showDragHandle]
  )
  const dragEnabled = useMemo(
    () =>
      dragMode === 'whole-element' ||
      (showDragHandle && isDragging) ||
      (dragMode === 'drag-handle-if-mobile' && !showDragHandle),
    [isDragging, showDragHandle, dragMode]
  )

  return (
    <>
      {renderedItems.map((item, index) => (
        <div
          key={index}
          className={`${classes.item} ${item === draggedItem ? classes.dragging : ''}`}
          draggable={dragEnabled}
          onDragStart={() => setDraggedItem(item)}
          onDragOver={(e) => {
            e.preventDefault()
            if (!draggedItem || draggedItem === item) {
              return
            }
            const currentIndex = renderedItems.indexOf(draggedItem)
            const targetIndex = renderedItems.indexOf(item)

            if (currentIndex !== -1 && targetIndex !== -1) {
              const newItems = [...renderedItems]
              newItems.splice(currentIndex, 1)
              newItems.splice(targetIndex, 0, draggedItem)
              setTemporaryItems(newItems)
            }
          }}
          onDragEnd={() => {
            handleReorder(renderedItems)
            setDraggedItem(undefined)
            setTemporaryItems(undefined)
          }}
        >
          {renderChild(item, index)}
        </div>
      ))}
    </>
  )
}

const DragHandle = (props) => {
  return (
    <div
      onMouseOver={() => {
        props.setIsDragging(true)
      }}
      onMouseLeave={() => {
        props.setIsDragging(false)
      }}
    >
      <ReorderComponentButton buttonProps={{}} />
    </div>
  )
}

import { Divider, Typography } from '@material-ui/core'
import type { OpenSolarThemeType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { orderSelectors } from 'ducks/orderComponents'
import { orgSelectors } from 'ducks/orgs'
import OrdersTable from 'pages/ordering/order/OrdersTable'
import { useNotify } from 'ra-core'
import { memo, useCallback, useContext, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import useHdmCreditLimit from 'services/hdm/useHdmCreditLimit'
import useDistributorAuthenticationStatus from 'services/useDistributorAuthenticationStatus'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { RootState } from 'types/state'
import { currencySymbolForCountry, formatCurrency } from 'util/misc'
import CheckoutPageLayout from '../../PageLayout'
import CheckoutTitle from '../CheckoutTitle'
import { CheckoutPresenterContext, HdmOrderModeType, ShippingAddressContext } from './HdmCheckoutProvider'
import HdmDisclaimer from './HdmDisclaimer'
import HdmPlaceOrderButton from './HdmPlaceOrderButton'
import PlaceOrderSideAction from './PlaceOrderAction'
import ShippingAddress from './ShippingAddress'

const useContentActionStyles = makeOpenSolarStyles((theme) => ({
  container: {
    display: 'flex',
    padding: 15,
    alignItems: 'center',
    justifyContent: 'space-around',
    boxSizing: 'border-box',
    border: theme.border.default,
    borderRadius: '8px',
    flexWrap: 'wrap',
  },
  infoWrapper: {},
  button: {
    flexGrow: 1,
    padding: 10,
    maxWidth: 300,
  },
  wrapper: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'sp e-between',
  },
  label: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  cost: {
    fontSize: '18px',
    color: '#018030',
  },
}))

const MainContentAction = memo(({ handlePlaceOrder }: { handlePlaceOrder(): void }) => {
  const classes = useContentActionStyles()
  const currencySymbol = useSelector((state: RootState) => {
    return currencySymbolForCountry(orgSelectors.getOrgIso2(state))
  })
  const hdmCheckoutPresenter = useContext(CheckoutPresenterContext)
  const lineItems = useSelector(orderSelectors.getOrderableLineItems)
  const { itemsCost, tax } = hdmCheckoutPresenter?.getOrderCost(lineItems) || { itemsCost: 0, tax: 0 }

  return (
    <div className={classes.container}>
      <div className={classes.button}>
        <HdmPlaceOrderButton onClick={handlePlaceOrder} trackSource={'order_page'} />
      </div>
      <div className={classes.infoWrapper}>
        <div className={classes.wrapper}>
          <span className={classes.label}>Order total:</span>
          <span className={classes.cost}>&nbsp;{`${currencySymbol} ${formatCurrency(itemsCost + tax)}`}</span>
        </div>
        <HdmDisclaimer />
      </div>
    </div>
  )
})

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  section: {
    padding: 20,
  },
  divider: {
    height: 2,
    margin: '20px 0',
  },
}))

const MainContent = memo(({ handlePlaceOrder }: { handlePlaceOrder(): void }) => {
  const classes = useStyles()
  const lineItems = useSelector(orderSelectors.getOrderableLineItems)

  return (
    <div>
      <Divider className={classes.divider} />

      <Typography variant="h6" gutterBottom>
        1 Shipping address
      </Typography>
      <div className={classes.section}>
        <ShippingAddress />
      </div>
      <Divider className={classes.divider} />
      <Typography variant="h6" gutterBottom>
        2 Review items
      </Typography>
      <div className={classes.section}>
        <OrdersTable lineItems={lineItems} editable={false} />
      </div>

      <Divider className={classes.divider} />

      <MainContentAction handlePlaceOrder={handlePlaceOrder} />
    </div>
  )
})

const QuoteOrOrderPage = () => {
  const notify = useNotify()
  const history = useHistory()

  const hdmCheckoutPresenter = useContext(CheckoutPresenterContext)
  const shippingAddress = useContext(ShippingAddressContext)
  const lineItems = useSelector(orderSelectors.getOrderableLineItems)

  const { itemsCost, tax } = hdmCheckoutPresenter?.getOrderCost(lineItems) || { itemsCost: 0, tax: 0 }
  const { isLoading, creditLimit } = useHdmCreditLimit()

  const hasEnoughCredit = useMemo(() => {
    return (creditLimit?.credit_balance || 0) > itemsCost + tax
  }, [creditLimit?.credit_balance, itemsCost])

  const orgId = useSelector(authSelectors.getOrgId) || 0
  const { isAuthenticated, isChecking } = useDistributorAuthenticationStatus({ orgId, distributor: 'hdm' })

  useEffect(() => {
    if (isChecking || isLoading) return
    let orderType: HdmOrderModeType | undefined = undefined
    if (hasEnoughCredit) orderType = 'order_from_credit'
    else if (isAuthenticated) orderType = 'order'
    else orderType = 'order_disconnected'

    hdmCheckoutPresenter?.updateOrderState<'orderType'>({ key: 'orderType', value: orderType })
  }, [hasEnoughCredit, isAuthenticated, isLoading, isChecking])

  useEffect(() => {
    logAmplitudeEvent('hardware_segen_checkout', {
      action: 'viewed',
      context: 'hdm_checkout_page',
    })
  }, [])

  const handlePlaceOrder = useCallback(async () => {
    try {
      hdmCheckoutPresenter?.updateOrderState<'submitting'>({ key: 'submitting', value: true })

      if (hasEnoughCredit) {
        const orderResult = await hdmCheckoutPresenter?.placeOrderFromCredit(lineItems, shippingAddress)
        hdmCheckoutPresenter?.updateOrderState<'orderResult'>({ key: 'orderResult', value: orderResult })
      } else {
        const orderResult = await hdmCheckoutPresenter?.placeOrder(lineItems, shippingAddress)
        hdmCheckoutPresenter?.updateOrderState<'orderResult'>({ key: 'orderResult', value: orderResult })
      }

      notify('Order placed with HDM successfully.', 'success')
      history.push('/shop/cart/hdm/?step=post-order')
    } catch (e: any) {
      console.error(e)
      notify(e.message, 'error')
    } finally {
      hdmCheckoutPresenter?.updateOrderState<'submitting'>({ key: 'submitting', value: false })
    }
  }, [lineItems, shippingAddress, hdmCheckoutPresenter, notify])

  return (
    <CheckoutPageLayout
      title={<CheckoutTitle />}
      mainContent={<MainContent handlePlaceOrder={handlePlaceOrder} />}
      sideAction={<PlaceOrderSideAction handlePlaceOrder={handlePlaceOrder} />}
    />
  )
}

export default memo(QuoteOrOrderPage)

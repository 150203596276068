import { makeStyles } from '@material-ui/core/styles'
import { authSelectors } from 'ducks/auth'
import { permissionsSelectors } from 'ducks/permissions'
import React, { useMemo, useState } from 'react'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { EventCategoryEnum } from 'types/events'
import { Theme } from 'types/themes'
import { urlToId } from 'util/misc'
import { getProjectSectionStyles } from '../styles'
import EmptyEventsContent from './elements/history/EmptyEventsContent'
import EventsDisplay from './elements/history/EventsDisplay'
import HistoryToolbar from './elements/history/HistoryToolbar'
import ProjectEventWrapper from './elements/history/ProjectEventWrapper'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    maxHeight: 400,
  },
  content: { flex: 1, overflowY: 'auto' },
}))
interface PropTypes {
  setHistoryOpen?: (value) => void
}

const ActivitiesSection: React.FC<PropTypes> = ({ setHistoryOpen }) => {
  const classes = useStyles()
  const project = useFormState().values
  const currentOrgId = useSelector(authSelectors.getOrgId)
  const sectionStyles = getProjectSectionStyles()
  const [eventFilter, setEventFilter] = useState<string | EventCategoryEnum>('activities')
  const allEvents = useMemo(() => project.events_data, [project.events_data])
  const { allowView } = useSelector(permissionsSelectors.getProjectPermissionByKey('manage_notes_activities_actions'))
  const filteredEvents = useMemo(() => {
    if (!allEvents) return []
    let events = allEvents
    if (!allowView) {
      events = events.filter((x) => urlToId(x.org) === currentOrgId)
    }
    if (eventFilter === 'activities') return events
    return events.filter((x) => x.categories?.includes(eventFilter)).slice(0, 4)
  }, [eventFilter, allEvents, allowView])

  return (
    <ProjectEventWrapper>
      {({ toggleModal, toggleDelete }) => {
        return (
          <div
            className={`${sectionStyles.column} ${classes.wrapper} ${sectionStyles.moduleHalfWidth}`}
            id="Project-Activities-Section"
          >
            <HistoryToolbar
              minDisplay={true}
              filterEvents={(filter) => {
                setEventFilter(filter === undefined ? 'all' : filter)
              }}
              filterApplied={eventFilter}
              toggleModal={toggleModal}
            />
            <div className={`${sectionStyles.sectionCardBottom} ${classes.content} ${sectionStyles.column}`}>
              {!!!filteredEvents.length ? (
                <EmptyEventsContent eventFilter={eventFilter} />
              ) : (
                <EventsDisplay
                  minDisplay={true}
                  events={filteredEvents}
                  toggleDelete={toggleDelete}
                  toggleModal={toggleModal}
                />
              )}
            </div>
          </div>
        )
      }}
    </ProjectEventWrapper>
  )
}

export default ActivitiesSection

// @ts-nocheck
import { Grid } from '@material-ui/core'
import React, { useMemo, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { StageActionEventType, StageActionType } from 'types/projects'
import { WorkflowActionType } from 'types/workflows'
import StageActionCard from './StageActionCard'
import EditEventDialog from './editEventDialog'

type PropTypes = {
  actions: WorkflowActionType[]
  allowEdit: boolean
}

const StageActions: React.FunctionComponent<PropTypes> = (props) => {
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false)
  const [eventToEdit, setEventToEdit] = useState<StageActionEventType | null>(null)
  const [actionToEdit, setActionToEdit] = useState<StageActionType | null>(null)
  const form = useForm()
  const formValues = useFormState().values

  const openEditDialog = (clickedEvent: StageActionEventType | null, clickedAction: StageActionType | null) => {
    setShowEditDialog(true)
    setEventToEdit(clickedEvent)
    setActionToEdit(clickedAction)
  }

  const onDismiss = () => {
    setShowEditDialog(false)
    setEventToEdit(null)
    setActionToEdit(null)
  }

  // Preferentially use the actions from the form state, as they contain the
  // associated events.
  const projectActions = useMemo(() => {
    return props.actions.map((action: WorkflowActionType) => {
      return formValues.actions.find((a) => a.id === action.id) || action
    })
  }, [props.actions, formValues.actions])

  const onCreate = (newEvent: StageActionEventType, actionId: number) => {
    let newActions = formValues.actions
    if (newActions && newActions.length > 0) {
      newActions = newActions.map((action: StageActionType) => {
        if (action.id === actionId) action.events = [...action.events, newEvent]
        return action
      })
      form.getState().initialValues.actions = newActions
      form.change('actions', newActions)
    }
  }

  const onDelete = (deletedEventId?: number, actionId?: number) => {
    if (!actionId && actionToEdit !== null) actionId = actionToEdit.id
    if (deletedEventId) {
      let newActions = formValues.actions
      if (newActions && newActions.length > 0) {
        newActions = newActions.map((action: StageActionType) => {
          if (action.id === actionId)
            action.events = action.events?.filter((ev: StageActionEventType) => ev?.id !== deletedEventId)
          return action
        })
        form.getState().initialValues.actions = newActions
        form.change('actions', newActions)
      }
    }
    onDismiss()
  }

  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        {!!projectActions.length ? (
          <>
            {projectActions?.map((action) => (
              <StageActionCard
                disabled={!props.allowEdit}
                key={action.id}
                action={action}
                projectUrl={props.projectUrl}
                openEditDialog={openEditDialog}
                onCreate={onCreate}
                onDelete={onDelete}
              />
            ))}
          </>
        ) : (
          <>{translate('No actions to display')}</>
        )}
      </Grid>
      {showEditDialog && eventToEdit && actionToEdit && (
        <EditEventDialog
          eventToEdit={eventToEdit}
          onDismiss={onDismiss}
          onDelete={onDelete}
          showEditDialog={true}
          setShowEditDialog={setShowEditDialog}
          actionToEdit={actionToEdit}
        />
      )}
    </Grid>
  )
}

export default StageActions

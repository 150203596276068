import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import { useNotify } from 'ra-core'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { reloadEntireApp } from 'util/misc'

const restClientInstance = restClient(window.API_ROOT + '/api')

const ConnectPhoenixAccount: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [traderId, setTraderId] = useState<string | undefined>(undefined)

  const isConnected = useSelector(authSelectors.getPhoenixConnected)
  const isStaff = useSelector(authSelectors.getIsStaff)
  const orgId = useSelector(authSelectors.getOrgId)
  const notify = useNotify()
  const showDisconnect = isStaff && window.location.href.includes('show_disconnect=true')

  const confirmDisconnect = () => {
    if (
      window.confirm(
        'Are you sure you want to de-activate this integration? This will break any existing Phoenix-integrated products'
      )
    ) {
      restClientInstance('CUSTOM_POST', 'custom', {
        url:
          'orgs/' +
          //@ts-ignore
          orgId +
          '/phoenix/disconnect_account/',
      })
        .then((response: any) => {
          if (response.data.success) {
            notify('Your Phoenix account has been disconnected', 'success')
            logAmplitudeEvent('phoenix_account_disconnected', {})
            setShowDialog(false)
          }
        })
        .catch((err: any) => {
          setErrorMsg('Unable to disconnect your account')
        })
    }
  }

  const doConnect = () => {
    setIsLoading(true)
    restClientInstance('CUSTOM_POST', 'custom', {
      url:
        'orgs/' +
        //@ts-ignore
        orgId +
        '/phoenix/connect_account/',
      data: {
        trader_id: traderId,
      },
    })
      .then((response: any) => {
        if (response.data.success) {
          logAmplitudeEvent('phoenix_account_connected', {})
          setShowDialog(false)
          window.location.href = window.location.origin + `/#/payment_options?products_added=${1}&partner=Phoenix`
          reloadEntireApp()
        } else {
          setErrorMsg(
            'Your Trader ID appears to be valid but you do not seem to have access to any Phoenix products. Please check your Phoenix portal and try again.'
          )
        }
      })
      .catch((err: any) => {
        console.log('error from panels', err)
        setErrorMsg('Unable to connect your account with the given credentials')
      })
      .finally(() => setIsLoading(false))
  }

  if (showDisconnect) {
    return (
      <div>
        <ProUXButton type="secondary" label="Disconnect Phoenix" onClick={confirmDisconnect} />
      </div>
    )
  } else if (isConnected) {
    return (
      <div>
        <p>Your Phoenix account is already connected</p>
      </div>
    )
  } else
    return (
      <div>
        <ProUXButton type="primary" label="Connect Phoenix Account" onClick={() => setShowDialog(true)} />
        <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
          <DialogTitle>Connect your Phoenix Account</DialogTitle>
          <DialogContent>
            <div>
              <p>Enter your Phoenix Trader Id below to connect your account</p>
              <TextField
                value={traderId}
                onChange={(e) => setTraderId(e.target.value)}
                placeholder="Phoenix Trader Id"
                style={{ width: '100%' }}
              />
              {errorMsg && <p style={{ color: 'red', textAlign: 'center' }}>{errorMsg}</p>}
            </div>
          </DialogContent>
          <DialogActions>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ margin: '15px' }}>
                <ProUXButton type="secondary" label="Cancel" onClick={() => setShowDialog(false)} />
              </div>
              <div style={{ margin: '15px' }}>
                <ProUXButton
                  type="primary"
                  label="Connect Account"
                  onClick={doConnect}
                  disabled={!traderId}
                  loading={isLoading}
                />
              </div>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    )
}
export default ConnectPhoenixAccount

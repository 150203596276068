import { MarkerOutlineIcon, ToggleButton } from 'opensolar-ui'
import { FC } from 'react'
import { CodeBlock } from '../CodeBlock'
import ComponentPage from '../ComponentPage'
import { FeatureGrid } from '../FeatureGrid'
import { ToggleButtonColours, ToggleButtonExclusive, ToggleButtonMultiple, ToggleButtonVert } from './ToggleButtonUsage'

const ToggleButtonUsage_code = require('!!raw-loader!./ToggleButtonUsage').default

export const DemoToggleButton: FC = () => {
  return (
    <ComponentPage
      name={'ToggleButtonGroup & ToggleButton'}
      import="import { ToggleButtonGroup, ToggleButton }  from 'opensolar-ui'"
      description={
        'ToggleButton can be used to group related actions/options. These can be exclusive to each other, or not.'
      }
      links={{
        mui4: 'https://v4.mui.com/components/toggle-button/',
        mui5: 'https://mui.com/material-ui/react-toggle-button/',
      }}
    >
      <CodeBlock
        name="ToggleButton Exclusive Usage"
        code={ToggleButtonUsage_code}
        codePart={1}
        description="How to enforce exclusive selection using the `exclusive` prop."
      >
        <ToggleButtonExclusive />
      </CodeBlock>

      <CodeBlock
        name="ToggleButton Multiple Usage"
        code={ToggleButtonUsage_code}
        codePart={2}
        description="How to allow multiple selection."
      >
        <ToggleButtonMultiple />
      </CodeBlock>

      <CodeBlock
        name="ToggleButton Vertical"
        code={ToggleButtonUsage_code}
        codePart={3}
        description="How to use align buttons vertically"
      >
        <ToggleButtonVert />
      </CodeBlock>

      <CodeBlock
        name="ToggleButton Colors"
        code={ToggleButtonUsage_code}
        codePart={4}
        description="How to use the color prop"
      >
        <ToggleButtonColours />
      </CodeBlock>

      <CodeBlock name="ToggleButton Feature Grid" hideGrid={true}>
        <FeatureGrid
          component={ToggleButton}
          propsGen={(row, col, lastHeader) => {
            return { children: <MarkerOutlineIcon />, selected: true, ...row, ...col, ...lastHeader }
          }}
          colHeaders={[]}
          rowHeaders={[]}
          cols={[
            { color: 'primary' },
            { color: 'secondary' },
            { color: 'info' },
            { color: 'success' },
            { color: 'warning' },
            { color: 'error' },
          ]}
          rows={[{}]}
        />
      </CodeBlock>
    </ComponentPage>
  )
}

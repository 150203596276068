export const parseIncome = (formatted: string) => {
  if (!formatted) return undefined
  else {
    let justNumbers = formatted.replace(/[^0-9,$]/g, '')
    if (justNumbers.length > 0) {
      justNumbers = justNumbers.substring(0, 9)
    }
    return justNumbers
  }
}

export const parseSSN = (formatted: string) => {
  if (!formatted) return undefined
  else {
    let justNumbers = formatted.replace(/[^0-9]/g, '')
    if (justNumbers.length > 0) {
      justNumbers = justNumbers.substring(0, 9)
    }
    return justNumbers
  }
}

export const formatSSN = (ssn: string) => {
  if (!ssn) return null
  ssn = ssn.replace(/[^\d]/g, '')

  if (ssn.length > 0) {
    let chunk1 = ssn.substring(0, 3)
    let chunk2 = ssn.substring(3, 5)
    let chunk3 = ssn.substring(5)
    if (chunk1 && chunk2 && chunk3) {
      return `${chunk1}-${chunk2}-${chunk3}`
    } else if (chunk1 && chunk2) {
      return `${chunk1}-${chunk2}`
    } else return chunk1
  }
  return null
}

export const USER_REGISTER = 'USER_REGISTER'
export const USER_REGISTER_LOADING = 'USER_REGISTER_LOADING'
export const USER_REGISTER_FAILURE = 'USER_REGISTER_FAILURE'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'

export const AUTH_REGISTER = 'AUTH_REGISTER'

export const PASSWORD_RESET = 'PASSWORD_RESET'
export const PASSWORD_RESET_LOADING = 'PASSWORD_RESET_LOADING'
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE'
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS'

export const PASSWORD_RESET_FORM = 'PASSWORD_RESET_FORM'
export const PASSWORD_RESET_FORM_LOADING = 'PASSWORD_RESET_FORM_LOADING'
export const PASSWORD_RESET_FORM_FAILURE = 'PASSWORD_RESET_FORM_FAILURE'
export const PASSWORD_RESET_FORM_SUCCESS = 'PASSWORD_RESET_FORM_SUCCESS'

export const PASSWORD_CHANGE_FORM = 'PASSWORD_CHANGE_FORM'
export const PASSWORD_CHANGE_FORM_LOADING = 'PASSWORD_CHANGE_FORM_LOADING'
export const PASSWORD_CHANGE_FORM_FAILURE = 'PASSWORD_CHANGE_FORM_FAILURE'
export const PASSWORD_CHANGE_FORM_SUCCESS = 'PASSWORD_CHANGE_FORM_SUCCESS'

export const EMAIL_CHANGE_FORM = 'EMAIL_CHANGE_FORM'
export const EMAIL_CHANGE_FORM_LOADING = 'EMAIL_CHANGE_FORM_LOADING'
export const EMAIL_CHANGE_FORM_FAILURE = 'EMAIL_CHANGE_FORM_FAILURE'
export const EMAIL_CHANGE_FORM_SUCCESS = 'EMAIL_CHANGE_FORM_SUCCESS'

export const AUTO_LOGIN = 'AUTO_LOGIN'
export const AUTO_LOGIN_LOADING = 'AUTO_LOGIN_LOADING'
export const AUTO_LOGIN_FAILURE = 'AUTO_LOGIN_FAILURE'
export const AUTO_LOGIN_SUCCESS = 'AUTO_LOGIN_SUCCESS'

export const AUTH_RELOAD = 'AUTH_RELOAD'
export const AUTH_RELOAD_LOADING = 'AUTH_RELOAD_LOADING'
export const AUTH_RELOAD_FAILURE = 'AUTH_RELOAD_FAILURE'
export const AUTH_RELOAD_SUCCESS = 'AUTH_RELOAD_SUCCESS'

export const UPDATE_EMAIL_PREFERENCES = 'UPDATE_EMAIL_PREFERENCES'

export const PROCESS_AUTH = 'PROCESS_AUTH'
export const PROCESS_AUTH_SUCCESS = 'PROCESS_AUTH_SUCCESS'

export const processAuth = ({ payload, callbacks }) => ({
  type: PROCESS_AUTH,
  payload,
  meta: { auth: true },
  callbacks,
})

export const userRegister = ({ payload, callbacks }) => ({
  type: USER_REGISTER,
  payload,
  meta: { auth: true },
  callbacks,
})

export const passwordReset = ({ payload, callbacks }) => ({
  type: PASSWORD_RESET,
  payload,
  meta: { auth: true },
  callbacks,
})

export const passwordResetForm = ({ payload, callbacks }) => ({
  type: PASSWORD_RESET_FORM,
  payload,
  meta: { auth: true },
  callbacks,
})

export const passwordChangeForm = ({ payload, callbacks }) => ({
  type: PASSWORD_CHANGE_FORM,
  payload,
  meta: { auth: true },
  callbacks,
})

export const emailChangeForm = ({ payload, callbacks }) => ({
  type: EMAIL_CHANGE_FORM,
  payload,
  meta: { auth: true },
  callbacks,
})

export const autoLogin = (payload) => ({
  type: AUTO_LOGIN,
  payload,
  meta: { auth: true },
})

export const authReload = (payload) => {
  const { redirectOnFailure, reloadOrg = true } = payload || {}
  return {
    type: AUTH_RELOAD,
    payload: {
      redirectOnFailure,
      reloadOrg,
    },
    meta: { auth: true },
  }
}

export const updateEmailPreferences = (payload) => ({
  type: UPDATE_EMAIL_PREFERENCES,
  payload,
  meta: { auth: true },
})

import { Dialog, withStyles } from '@material-ui/core'
import MuiDialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { Button, IconButton } from 'opensolar-ui'
import React, { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { connect } from 'react-redux'
import { getRoleFromState } from 'util/misc'

const PlentiDialogTitle = () => (
  <div>
    <img alt={'plenti'} src={`${window.PUBLIC_URL}/images/plenti-logo.svg`} style={{ height: 35 }} />
  </div>
)

const IFRAME_CONFIG = {
  PlentiLoanApplication: { title: <PlentiDialogTitle /> },
  SunlightLoanApplication: {
    title: <h1 style={{ fontSize: 20, padding: '0 5px' }}>Apply for loan</h1>,
  },
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const IframeContent = withStyles((theme) => ({
  root: {
    padding: 0,
    margin: '10px 16px',
    height: '50vh',
    overflowY: 'hidden',
    lineHeight: '20px',
    border: '0.5px solid #c4c4c4',
    borderRadius: 4,
  },
}))(MuiDialogContent)

// const DialogActions = withStyles((theme) => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(1),
//   },
// }))(MuiDialogActions)

const DialogTitle = withStyles(styles)((props) => {
  const [openPrompt, setOpen] = useState(false)
  const { children, classes, onClose, ...other } = props
  const translate = useTranslate()
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <h1>{children}</h1>
      <IconButton aria-label="close" className={classes.closeButton} onClick={() => setOpen(true)}>
        <CloseIcon />
      </IconButton>
      <Dialog maxWidth="xs" open={openPrompt}>
        <MuiDialogTitle>{'Close Application?'}</MuiDialogTitle>
        <MuiDialogContent>
          <p>
            Are you sure you want to close the loan application? If the application has not been submitted, all unsaved
            progress will be lost. If the application has been submitted, click confirm.
          </p>
        </MuiDialogContent>
        <MuiDialogActions>
          <Button variant="contained" color="default" onClick={() => setOpen(false)}>
            <span>{translate('Cancel')}</span>
          </Button>
          <Button variant="contained" color="primary" onClick={onClose}>
            <span>{translate('Confirm')}</span>
          </Button>
        </MuiDialogActions>
      </Dialog>
    </MuiDialogTitle>
  )
})

const _IframeDialog = (props) => {
  const [isFullScreen, setIsFullScreen] = useState(props.dialogIsFullWidth || false)
  const translate = useTranslate()
  const activeIframe = IFRAME_CONFIG[props.activeDialog]
  const dialogMaxWidth = ['SunlightLoanApplication', 'PlentiLoanApplication'].includes(props.activeDialog) ? 'xl' : 'sm'
  const allowFullScreen = ['PlentiLoanApplication', 'SunlightLoanApplication'].includes(props.activeDialog)

  useEffect(() => {
    if (props.activeDialog) {
      if (props.activeDialog === 'SunlightLoanApplication') {
        logAmplitudeEvent('integrated_finance_application_opened', { integration: 'sunlight' })
      } else if (props.activeDialog === 'PlentiLoanApplication') {
        logAmplitudeEvent('integrated_finance_application_opened', { integration: 'plenti' })
      }
    }
  }, [props.activeDialog])

  if (activeIframe) {
    return (
      <div className="IframeDialog" id="IframeDialog">
        <Dialog open={activeIframe} className="iframe-dialog" maxWidth={dialogMaxWidth} fullScreen={isFullScreen}>
          <DialogTitle onClose={props.closeDialog}>
            {allowFullScreen && (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                {activeIframe.title}
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                  {!isFullScreen && (
                    <span
                      style={{ cursor: 'pointer', textDecoration: 'underline', color: 'rgb(10, 40, 93)' }}
                      onClick={() => setIsFullScreen(true)}
                    >
                      {translate('Expand to Full Screen')}
                    </span>
                  )}
                  {isFullScreen && (
                    <span
                      style={{ cursor: 'pointer', textDecoration: 'underline', color: 'rgb(10, 40, 93)' }}
                      onClick={() => setIsFullScreen(false)}
                    >
                      {translate('Return to Minimized View')}
                    </span>
                  )}
                </div>
              </div>
            )}
            {!allowFullScreen && activeIframe.title}
          </DialogTitle>
          <IframeContent>
            <iframe
              id="iframe-dialog"
              style={{
                padding: 0,
                border: 0,
                margin: 0,
                width: '100%',
                height: '100%',
              }}
              src={props.dialogData && props.dialogData.url}
            ></iframe>
          </IframeContent>
        </Dialog>
      </div>
    )
  } else {
    // Do not render when there is an activeDialog but it does not match any of the valid states
    return null
  }
}
export const IframeDialog = connect((state) => ({ isEndCustomer: !Boolean(getRoleFromState(state)) }))(_IframeDialog)

import { HelpLink } from 'elements/link/HelpLink'
import React from 'react'
import { useTranslate } from 'react-admin'
import { ProposalTemplateCreate, ProposalTemplateEdit, ProposalTemplateList } from './ProposalTemplates'

const ProposalTemplateTitle = ({ record, translate, style }) => (
  <span style={style}>
    {translate('Proposal Title')} - {record && record.title}
  </span>
)
const Subtitle = () => {
  const translate = useTranslate()
  return (
    <span>
      {translate(
        'Create and edit a default proposal template, and create additional templates that can be applied on a per project basis from the Online Proposal page in the Projects zone. In creating a proposal template you can customise what information is shown to the customer, the sequence in which it is presented and how customers are able to interact with it. If you intend to share proposals via PDF, be sure to check out the PDF settings as well. You can also customise the proposal template in the Online Proposal page in the Projects zone.'
      )}
      <HelpLink href="https://support.opensolar.com/hc/en-us/articles/4407235900953" />
    </span>
  )
}
export default {
  list: ProposalTemplateList,
  create: ProposalTemplateCreate,
  edit: ProposalTemplateEdit,
  options: {
    create: {
      title: 'Create Proposal Template',
      subtitle: null,
      breadCrumb: 'Create Template',
    },
    list: {
      title: 'My Proposal Templates List',
      subtitle: <Subtitle />,
      breadCrumb: 'Proposal Templates',
    },
    edit: {
      title: <ProposalTemplateTitle />,
      subtitle: null,
      breadCrumb: 'Edit Template',
    },
  },
}

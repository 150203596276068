import { orgSelectors } from 'ducks/orgs'
import { uniqBy } from 'lodash'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { Box } from 'opensolar-ui'
import { Popper, Typography } from '@material-ui/core'
import { getSupplierConfigByFilterKey, getSupplierEnumByFilterKey, HardwareSupplierPresetConfig } from 'pages/ordering/configs'
import { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { CostSource } from 'projectSections/hooks/useComponentCost'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { ComponentTypes, ComponentTypesV2 } from 'types/selectComponent'
import { DistributorDataTypeV2 } from '../types'
import classNames from 'classnames'

const useStyles = makeOpenSolarStyles((theme) => ({
  pricingSource: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    color: '#6B6A76',
  },
  wrapper: {
    padding: '4px 8px',
    textAlign: 'left',
    backgroundColor: '#ffffff',
    borderRadius: '6px',
    cursor: 'pointer',
    boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.25)',
    minWidth: '70%',
  },
  popperOptionLabel: {
    fontSize: 10,
    fontWeight: 600,
    lineHeight: '12px',
    color: '#757575',
    paddingLeft: 20,
  },
  selector: {
    border: '1px solid #E6E6E6',
    borderRadius: 4,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px',
    cursor: 'pointer',
    width: '80%',
    minHeight: 28,
  },
  supplierWrapper: {
    padding: '8px',
  },
  supplierName: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
  },
  selectedSupplierName: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    width: '75%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  placeholder: {
    color: '#ADACB0',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
  },
  supplierImg: {
    width: 50,
    height: 32,
    marginRight: 8,
    border: '1px solid #E6E6E6',
    borderRadius: 4,
  },
}))

const SupplierOption = ({
  supplier,
  onSupplierSelected,
}: {
  supplier: HardwareSupplierPresetConfig | undefined
  onSupplierSelected: () => void
}) => {
  const classes = useStyles()

  return (
    <div className={classes.supplierWrapper} onClick={onSupplierSelected}>
      <div style={{ display: 'inline-flex', alignContent: 'center', alignItems: 'center' }}>
        <div className={classes.supplierImg}>
          <img src={supplier?.logoUrl || ''} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
        </div>
        <Typography className={classes.supplierName}>{supplier?.name}</Typography>
      </div>
    </div>
  )
}

interface DistributorBlockV2Props {
  distributors: DistributorDataTypeV2[]
  onUpdateCostBySource: (source: CostSource, price: number) => void
  componentType: ComponentTypesV2
  componentCode: string
}

const DistributorBlockV2: React.FC<DistributorBlockV2Props> = ({
  distributors,
  onUpdateCostBySource,
  componentCode,
  componentType,
}) => {
  const [costSource, setCostSource] = useState<CostSource>()
  const [supplierAnchorEl, setSupplierAnchorEl] = useState<HTMLElement | null>(null)

  const enabledDistributors = useSelector(orgSelectors.getEnabledHardwareSuppliers)
  const filteredDistributors = uniqBy(
    distributors.filter((item: DistributorDataTypeV2, index, self) => {
      const supplierEnum = getSupplierEnumByFilterKey(item.distributor)
      return enabledDistributors.includes(supplierEnum) && self.indexOf(item) === index
    }),
    'distributor'
  )
  const classes = useStyles()

  const updateCostSourceForComponent = (costSource: CostSource) => {
    if (costSource === 'component') {
      const componentActivation = window.AccountHelper.getComponentActivationFromCode(
        componentCode,
        componentType as ComponentTypes
      )
      onUpdateCostBySource(costSource, componentActivation?.cost || 0)
    } else {
      const selectedHardware = filteredDistributors.find((pricing) => pricing.distributor === costSource)
      onUpdateCostBySource(costSource, selectedHardware?.price || 0)
    }
  }

  const handleSelectSource = (costSource: CostSource) => {
    setCostSource(costSource)
    updateCostSourceForComponent(costSource)
    setSupplierAnchorEl(null)
  }

  useEffect(() => {
    if (distributors.length === 0) {
      updateCostSourceForComponent('component')
    }
  }, [distributors])

  return (
    <Box alignItems="center" display="flex" flexDirection="row" flexWrap="wrap" gridGap={8}>
      <span className={classes.pricingSource}>{`${filteredDistributors.length + 1} pricing sources`}</span>
      <div
        className={classes.selector}
        style={Boolean(supplierAnchorEl) ? { border: '2px solid #4272DD', padding: '7px 8px' } : {}}
        onClick={(e) => setSupplierAnchorEl(!supplierAnchorEl ? e.currentTarget : null)}
      >
        {costSource ?
          costSource === 'component' ?
            <span className={classes.selectedSupplierName}>Manual (Business Control Settings)</span>
              : <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <img style={{ height: 28 }} src={getSupplierConfigByFilterKey(costSource as HardwareSupplierFilterKeyType)?.logoUrl} />
                  <span style={{ marginLeft: 8 }}>{getSupplierConfigByFilterKey(costSource as HardwareSupplierFilterKeyType).name}</span>
                </div>
          : <span className={classes.placeholder}>Select Source</span>
        }
        <KeyboardArrowDownIcon htmlColor="#6B6A76" />
      </div>
      <Popper
        open={Boolean(supplierAnchorEl)}
        anchorEl={supplierAnchorEl}
        style={{ zIndex: 2000 }}
        placement="bottom-start"
      >
        <div className={classes.wrapper}>
          {filteredDistributors.map((distributor) => {
            const supplierConfig = getSupplierConfigByFilterKey(distributor.distributor)
            if (supplierConfig) {
              return (
                <SupplierOption
                  supplier={supplierConfig}
                  onSupplierSelected={() => {handleSelectSource(supplierConfig.filterKey as CostSource)}}
                />
              )
            } else return null
          })}
          <div
            className={classNames(classes.supplierWrapper, classes.supplierName)}
            onClick={() => {handleSelectSource('component')}}
          >
            Manual (Business Control Settings)
          </div>
        </div>
      </Popper>
    </Box>
  )
}

export default DistributorBlockV2

import { and, ControlProps, rankWith, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import CustomForm from 'customForm/CustomForm'
import { CustomFormContext } from 'customForm/CustomFormContext'
import useGetCustomForms from 'customForm/hooks/useGetCustomForms'
import * as React from 'react'

const tester = rankWith(100, and(uiTypeIs('CustomForm')))

const Renderer: React.FC<ControlProps> = ({ uischema, data, visible }) => {
  let formTitle = uischema['form_title']

  const customForms = useGetCustomForms({ formTitles: [formTitle] })
  const customFormContext = React.useContext(CustomFormContext)

  const customForm = customForms?.length ? customForms[0].form : undefined

  return !visible || !customFormContext || !customForm?.configuration ? (
    <></>
  ) : (
    <CustomForm {...customFormContext} customForm={customForm} />
  )
}

const renderer = withJsonFormsControlProps(Renderer)

export default {
  tester,
  renderer,
}

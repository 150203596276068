import { setMfaCheckRequired, setMfaConfigRequired } from 'ducks/auth_mfa_actions'
import Button from 'elements/proUXButtons/ProUXButton'
import { useTranslate } from 'ra-core'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ChooseMfaMode from './ChooseMfaMode'
import AddTotp from './MfaAddTotp'
import MfaPhoneNumber from './MfaPhoneNumber'
import MfaRecoveryCodes from './MfaRecoveryCodes'
import MfaSmsCode from './MfaSmsCode'

type PageType = 'mode' | 'phone' | 'sms-code' | 'totp' | 'recovery-codes'
type PropTypes = {
  onFinish?(): void
  onCancel?(): void
}

const MfaLoginWrapper: React.FC<PropTypes> = ({ onFinish, onCancel }) => {
  const history = useHistory()
  const form = useForm()
  const dispatch = useDispatch()
  const translate = useTranslate()

  const [currentPage, setCurrentPage] = useState<PageType>('mode')

  useEffect(() => {
    if (history?.location?.hash) {
      setCurrentPage(history.location.hash.replace('#', '') as PageType)
    }
  }, [history?.location?.hash])

  const handleCancel = () => {
    dispatch(setMfaCheckRequired(false))
    dispatch(setMfaConfigRequired(false))
    onCancel?.()
  }

  const goToPhone = () => setCurrentPage('phone')
  const goToSmsCode = () => setCurrentPage('sms-code')
  const goToTotp = () => setCurrentPage('totp')
  const goToRecovery = () => setCurrentPage('recovery-codes')

  return (
    <div style={{ textAlign: 'center' }}>
      {currentPage === 'mode' && (
        <>
          <p>
            {translate(
              'This organisation requires two-factor authentication for additional security. Please configure your two-factor authentication below.'
            )}
          </p>
          <ChooseMfaMode goToPhone={goToPhone} goToTotp={goToTotp} />
          <div style={{ width: '100%', marginTop: '15px' }}>
            <Button label={translate('Cancel')} onClick={handleCancel} type="text" fullWidth={true} />
          </div>
        </>
      )}
      {currentPage === 'phone' && <MfaPhoneNumber goToSmsCode={goToSmsCode} />}
      {currentPage === 'sms-code' && <MfaSmsCode goToRecovery={goToRecovery} isLogin={true} />}
      {currentPage === 'totp' && <AddTotp goToRecovery={goToRecovery} isLogin={true} />}
      {currentPage === 'recovery-codes' && <MfaRecoveryCodes onFinish={onFinish} />}
    </div>
  )
}
export default MfaLoginWrapper

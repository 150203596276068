import LaunchIcon from '@material-ui/icons/LaunchOutlined'
import { orgSelectors } from 'ducks/orgs'
import { PrimaryButton } from 'elements/button/GenericButton'
import { useNotify } from 'ra-core'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { OrgType } from 'types/orgs'
import { SystemDataType } from 'types/systems'
import PaymentOptionCompDialog from './PaymentOptionCompDialog'

type PropTypes = {
  countryIso2: string
  system: SystemDataType
  pricingIsLocked: boolean
  systemsLoadingUuids: string[]
}

const PaymentOptionCompButton: React.FC<PropTypes> = (props) => {
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [monthlySavings, setMonthlySavings] = useState<number | undefined>(undefined)
  const [hasUSIntegration, setHasUsIntegration] = useState<boolean>(false)

  const org: OrgType | undefined = useSelector(orgSelectors.getOrg)
  const notify = useNotify()

  useEffect(() => {
    if (org) {
      if (org.enable_mosaic || org.enable_dividend || org.enable_sunlight || org.enable_loanpal || org.enable_sungage) {
        setHasUsIntegration(true)
      } else {
        setHasUsIntegration(false)
      }
    }
  }, [org])

  useEffect(() => {
    try {
      const currentAnnualBill = props.system?.bills?.current?.bills_yearly[0]?.annual?.total
      let proposedAnnualBill = currentAnnualBill
      Object.values(props.system?.bills?.proposed)?.forEach((vals, i) => {
        if (i === 0) {
          proposedAnnualBill = vals?.bills_yearly[0]?.annual?.total
        }
      })

      let savings =
        currentAnnualBill !== undefined && proposedAnnualBill !== undefined
          ? Math.round((currentAnnualBill - proposedAnnualBill) / 12)
          : undefined
      if (savings !== undefined) setMonthlySavings(savings)
    } catch (ex) {}
  }, [props.system])

  const handleClick = () => {
    if (props.systemsLoadingUuids?.length > 0) {
      notify('Please wait until calculations are complete', 'warning')
    } else {
      setShowDialog(true)
    }
  }

  if (props.countryIso2 !== 'US' || !hasUSIntegration) return null
  return (
    <>
      <PrimaryButton
        variant="contained"
        wrapperStyle={{ width: '100%' }}
        disabled={props.pricingIsLocked}
        endIcon={<LaunchIcon />}
        labelWrapperStyle={{
          padding: '6px 2px',
          fontSize: 13,
          textTransform: 'none',
          fontWeight: 'normal',
          whiteSpace: 'normal',
          textOverflow: 'normal',
        }}
        onClick={handleClick}
        label="Payment Option Comparison"
        fullWidth={true}
      />
      {showDialog && monthlySavings !== undefined && org && (
        <PaymentOptionCompDialog
          showDialog={showDialog}
          closeDialog={() => setShowDialog(false)}
          system={props.system}
          monthlySavings={monthlySavings}
          org={org}
        />
      )}
    </>
  )
}
export default PaymentOptionCompButton

import { OpenSolarThemeType } from 'Themes'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { useDateFormatter } from 'util/date'
import type { ActivityDataRendererType, InventoryActivity } from '../type'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 10,
    paddingBottom: 10,
  },
  timeStamp: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    minWidth: 200,
    textAlign: 'right',
  },
  description: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    minWidth: 300,
  },
}))

const InventoryActivityRow: ActivityDataRendererType = ({ activity }: { activity: InventoryActivity }) => {
  const formatter = useDateFormatter({ variant: 'date' })
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.description}>
        {activity.event_type_id !== 0 && activity.notes && activity.notes.length ? activity.notes : activity.title}
      </div>
      <div className={classes.timeStamp}>{formatter(activity.created_date)}</div>
    </div>
  )
}

export default InventoryActivityRow

import FullScreenLoading from 'layout/widgets/FullScreenLoading'
import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

const BackToHomeCountingDown = () => {
  const [countingDownInSecond, setCountingDownInSecond] = useState<number>(3)
  useEffect(() => {
    const countingDown = window.setInterval(() => {
      setCountingDownInSecond((rest) => rest - 1)
    }, 1000)
    return () => window.clearInterval(countingDown)
  }, [])
  if (countingDownInSecond <= 0) {
    return <Redirect to={'/home'} />
  }
  return <FullScreenLoading text={`Switching to home page ${countingDownInSecond}...`} />
}

export default BackToHomeCountingDown

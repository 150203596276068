import { Card, CardActions, CardHeader } from '@material-ui/core'
import { ControlBreadCrumbs } from 'elements/BreadCrumbs'
import { HelpLink } from 'elements/link/HelpLink'
import { Button } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import { Link } from 'react-router-dom'
import { useFeatureFlag } from 'util/split'

const BusinessProcess = (props) => {
  const translate = useTranslate()
  const enableCustomForms = useFeatureFlag('custom_forms', 'on')
  return (
    <>
      <ControlBreadCrumbs style={{ padding: '24px 24px 0 24px' }} currentPage="Business Process" />
      <div style={{ padding: '0px 24px 0px 24px' }}>
        <h1
          style={{
            fontSize: 20,
            display: 'inline-block',
            fontWeight: 500,
            height: 68,
            lineHeight: '68px',
            margin: 0,
            color: 'rgba(0, 0, 0, 0.87)',
          }}
        >
          {translate('Business Process')}
        </h1>
        <div style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
          {translate('Use Project Tags and Project Workflows to streamline your project management')}.
        </div>
        <Card style={{ marginTop: 40 }}>
          <CardHeader
            title={<h2>{translate('Project Workflows')}</h2>}
            subheader={
              <p>
                {translate(
                  "Ensure your team is staying on track with their projects by using Project Workflows to customise project stages and actions to match your business' needs."
                )}{' '}
                <HelpLink href="https://support.opensolar.com/hc/en-us/articles/8530529693967" />
              </p>
            }
          />
          <CardActions style={{ padding: '0 0 26px 16px' }}>
            <Link to={'/workflows'} style={{ textDecoration: 'none' }}>
              <Button variant="outlined" color="default" id="manageActionsButton" name="businessProcess">
                <span>{translate('Manage Project Workflows')}</span>
              </Button>
            </Link>
          </CardActions>
        </Card>
        <Card style={{ marginTop: 40 }}>
          <CardHeader
            title={<h2>{translate('Project Tags')}</h2>}
            subheader={
              <p>
                {translate(
                  'Create your own Project Tags that you can apply to projects to enable easy filtering and sorting. For example: "Family and Friends"'
                )}{' '}
                <HelpLink href="https://support.opensolar.com/hc/en-us/articles/4407118190233" />
              </p>
            }
          />
          <CardActions style={{ padding: '0 0 26px 16px' }}>
            <Link to={'/tags'} style={{ textDecoration: 'none' }}>
              <Button variant="outlined" color="default" id="manageTagsButton" name="businessProcess">
                <span>{translate('Manage Project Tags')}</span>
              </Button>
            </Link>
          </CardActions>
        </Card>
        <Card style={{ marginTop: 40 }}>
          <CardHeader
            title={<h2>{translate('Custom Fields')}</h2>}
            subheader={
              <p>
                {translate(
                  'Create multiple custom fields for your projects and customer contacts to suit your business workflow. You can also create new COGS fields that appear in cost information templates that can help you customise your cost buildup labelling.'
                )}{' '}
                &nbsp;
                <HelpLink href="https://support.opensolar.com/hc/en-us/articles/4411889716761" />
              </p>
            }
          />
          <CardActions style={{ padding: '0 0 26px 16px' }}>
            <Link to={'/custom_fields'} style={{ textDecoration: 'none' }}>
              <Button variant="outlined" color="default" id="editCustomFieldButton" name="businessProcess">
                <span>{translate('Edit Custom Fields')}</span>
              </Button>
            </Link>
          </CardActions>
        </Card>
        {enableCustomForms && (
          <Card style={{ marginTop: 40 }}>
            <CardHeader
              title={<h2>{translate('Custom Forms')}</h2>}
              subheader={
                <p>
                  {translate(
                    'Enabling custom forms allows you to collect detailed information in various places throughout OpenSolar.'
                  )}{' '}
                </p>
              }
            />
            <CardActions style={{ padding: '0 0 26px 16px' }}>
              <Link to={'/custom_forms'} style={{ textDecoration: 'none' }}>
                <Button variant="outlined" color="default" id="manageCustomForms" name="businessProcess">
                  <span>{translate('Manage Custom Forms')}</span>
                </Button>
              </Link>
            </CardActions>
          </Card>
        )}
      </div>
    </>
  )
}

export default BusinessProcess

import { SHOW_NOTIFICATION } from 'react-admin'
import { takeEvery } from 'redux-saga/effects'

export function recordShowNotification({ payload }) {
  if (window.ReplayHelper && !window.ReplayHelper.replayInProgress) {
    window.ReplayHelper.recordNotification(payload.message, payload.type)
  }
}

export function* watchNotifications() {
  //@ts-ignore
  yield takeEvery(SHOW_NOTIFICATION, recordShowNotification)
}

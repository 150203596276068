import { Grid } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import Button from 'elements/button/Button'
import type { PartnerIdType } from 'pages/auth/sso/types'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { setAuthRedirection, storeAuthRedirectionToLocalStorage } from 'redirections/authRedirectionStore'
import { doNotTranslate } from 'util/misc'

const UserNotPaired = ({
  partnerName,
  ssoLink,
  partner,
}: {
  partnerName: string
  ssoLink: string | undefined
  partner: PartnerIdType
}) => {
  const location = useLocation()

  return (
    <div>
      <p>{doNotTranslate(`This user is not paired with ${partnerName}.`)}</p>
      {ssoLink && (
        <Grid container justify="center">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setAuthRedirection({
                type: 'AUTH_SUCCESS_REDIRECT',
                redirect: location.pathname + location.search,
              })
              storeAuthRedirectionToLocalStorage()
              logAmplitudeEvent('partner_org_linking_interacted', {
                action: 'redirected',
                context: 'account_pairing',
                source: 'org_linking_page',
                partner: partner || 'unknown',
              })
              window.location.href = ssoLink
            }}
          >
            {doNotTranslate('Pair Account')}
          </Button>
        </Grid>
      )}
    </div>
  )
}

export default UserNotPaired

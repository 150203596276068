import React, { useState } from 'react'
import { Create, SimpleForm } from 'react-admin'
import { useLocation } from 'react-router-dom'
import { defaultValueFunc, formatSubmitValues } from 'resources/utilityTariffs/UtilityTariff'
import { useDefaultTariffCountry } from '../hooks'
import CreateTariffToolbar from './CreateTariffToolbar'
import TariffFormContent from './TariffFormContent'

const CreateTariff = () => {
  const location = useLocation()
  const [saveToDB, setSaveToBD] = useState(false)
  const defaultCountry = useDefaultTariffCountry()
  const resource = 'utility_tariffs'

  return (
    <Create
      //@ts-ignore
      location={location}
      hideBreadCrumbs={true}
      basePath={'/utility_tariffs'}
      resource={resource}
      title="Create Applied Tariff Details"
    >
      <SimpleForm
        defaultValue={() => defaultValueFunc({}, undefined, defaultCountry)}
        record={{}}
        formatSubmitValues={formatSubmitValues}
        //@ts-ignore
        toolbar={<CreateTariffToolbar setSaveToBD={setSaveToBD} saveToDB={saveToDB} />}
      >
        <TariffFormContent resource={resource} defaultCountry={defaultCountry} isEdit={false} />
      </SimpleForm>
    </Create>
  )
}

export default CreateTariff

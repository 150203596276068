import { Theme, useMediaQuery } from '@material-ui/core'
import ProUxButton from 'elements/proUXButtons/ProUXButton'
import React, { useEffect, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import restClient from 'restClient'
import { ProjectType } from 'types/projects'
import { SystemDesignSunlightQuickCreateType } from '../sunlight/types'
let restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  project: ProjectType
  designs?: SystemDesignSunlightQuickCreateType[]
}

const DividendSendLoanAgreement: React.FunctionComponent<PropTypes> = (props) => {
  // const [systems, setSystems] = useState<SystemDesignSunlightQuickCreateType[]>([])

  const notify = useNotify()

  const [systems, setSystems] = useState<SystemDesignSunlightQuickCreateType[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 967))
  const isXxs = useMediaQuery((theme: Theme) => theme.breakpoints.down(332))
  // system data can come from two different places
  useEffect(() => {
    if (props.designs && props.designs?.length > 0) setSystems(props.designs)
    else {
      const systemsFromStudio = window.editor.getSystems()?.map((sys) => {
        sys.paymentOptionsData = sys.payment_options
        sys.title = sys.name && sys.name.length > 0 ? sys.name : `${sys.moduleQuantity()} ${translate('Panels System')}`
        return sys
      })
      if (systemsFromStudio && systemsFromStudio.length > 0) setSystems(systemsFromStudio as any)
    }
  }, [props.designs])

  const sendAgreement = () => {
    setLoading(true)
    restClientInstance('CUSTOM_POST', 'custom', {
      url: 'orgs/' + props.project.org_id + '/projects/' + props.project.id + '/dividend/loan_agreement/',
    })
      .then((res: any) => {
        console.log('res', res)
        notify('The documents are being generated and will be emailed to the customer in about 1 minute', 'success')
      })
      .catch((err) => {
        if (err?.body?.message) {
          notify(err.body.message, 'warning')
        } else {
          notify(
            'Something went wrong and we could not send the loan agreement. Please send the docs from the Dividend portal',
            'warning'
          )
        }
      })
      .finally(() => setLoading(false))
  }

  const translate = useTranslate()
  return (
    <div
      style={{
        width: '100%',
        marginTop: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ProUxButton
        label="Send Loan Agreement"
        onClick={sendAgreement}
        type="secondary"
        backgroundColor="#fff"
        fullWidth
        fullHeight
        isMarginTop={isMd || isXxs ? true : false}
        labelStyle={{
          display: 'flex',
          flexDirection: isMd || isXxs ? 'column' : 'row',
          alignItems: isMd || isXxs ? 'flex-start' : 'end',
        }}
        startIcon={<img width="21" height="21" src={window.PUBLIC_URL + '/images/dividend-logo-small.jpeg'} />}
        justifyContent="flex-start"
        loading={loading}
      />
    </div>
  )
}
export default DividendSendLoanAgreement

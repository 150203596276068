import { getRoleId } from 'util/misc'

export const getDefaultFilters = () => {
  return [
    {
      label: 'Recently Updated Projects',
      filter: JSON.stringify({ status: 1 }),
    },
    {
      label: 'My Assigned Projects',
      filter: JSON.stringify({ assigned_role_id: getRoleId() }),
    },
  ]
}

import { SvgIconProps } from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import React from 'react'

type DynamicIconProps = {
  icon: string
}

export const DynamicIcon: React.FC<DynamicIconProps & SvgIconProps> = ({ icon, ...props }) => {
  const Icon = Icons[icon]

  if (!Icon) {
    return <span>Icon not found: {icon}</span>
  }
  return <Icon {...props} />
}

import React from 'react'
import type { ComponentTypes } from 'types/selectComponent'
import type { FilterComponentNode, FilterComponentRendererType, FilterGenericPropsType } from '../type'
import CurrentTypeFilter, { FilterOptionsType } from './CurrentTypeFilter'

export const CURRENT_FILTER_KEY = 'current_type'

const maybeCreateCurrentTypeFilterNode = ({
  componentType,
  RendererComponent,
}: {
  componentType?: ComponentTypes
  RendererComponent: FilterComponentRendererType<FilterOptionsType>
}): FilterComponentNode | undefined => {
  if (componentType !== 'other') {
    return
  }

  const FilterComponent = (props: FilterGenericPropsType) => (
    <CurrentTypeFilter filterKey={CURRENT_FILTER_KEY} RendererComponent={RendererComponent} {...props} />
  )

  return {
    key: CURRENT_FILTER_KEY,
    component: FilterComponent,
  }
}

export default maybeCreateCurrentTypeFilterNode

import { Divider } from '@material-ui/core'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import React, { useCallback } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { useComponentTypeByRoute } from '../../../pages/ordering/Routes'
import { PHASE_FILTER_KEY } from '../phaseType/PhaseTypeFilterNodeFactory'
import getRatingOverrideLabel from '../rating/getRatingOverrideLabel'
import { RATING_FILTER_NODE_KEY } from '../rating/RatingFilterNodeFactory'
import { addFilterToLastRecentUsedList, removeFilterFromLastRecentUsedList } from '../utils/lruFilterCacheStore'

type AccordionOptions = {
  defaultExpanded?: boolean
  showDivider?: boolean
  recordLru?: boolean
}
const useStyles = makeOpenSolarStyles((theme) => ({
  // TODO: Use disableGutters instead once completed for AccordionCard https://github.com/open-solar/sunsuite/pull/7592
  root: {
    '&.MuiAccordion-root, &.MuiAccordion-root.Mui-expanded, & .MuiAccordion-root, & .MuiAccordionSummary-content.Mui-expanded, & .MuiAccordionSummary-content': {
      margin: '0',
    },
    '& .MuiAccordionDetails-root, .MuiAccordionSummary-root': {
      padding: '0',
    },
    '& .MuiAccordionSummary-content, & .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 'auto',
    },
    paddingBottom: '5px',
  },
  divider: {
    margin: '0 5px',
    background: 'rgba(236, 236, 237, 0.9)',
  },
}))

const createAccordionRenderer = <T,>({
  filterKey,
  filterLabel,
  BasicRenderer,
  options,
}: {
  filterLabel?: string
  BasicRenderer: React.FC<T>
  options?: AccordionOptions
  filterKey: string
}) => {
  return (props) => {
    const classes = useStyles()
    const onValueChanged = useCallback(
      (expanded: boolean) => {
        if (options?.recordLru) {
          if (expanded) {
            addFilterToLastRecentUsedList(filterKey)
          } else {
            removeFilterFromLastRecentUsedList(filterKey)
          }
        }
      },
      [options?.recordLru]
    )

    const componentType = useComponentTypeByRoute()

    // Special case for rating filter label
    let label = filterKey === RATING_FILTER_NODE_KEY ? getRatingOverrideLabel(componentType) : filterLabel

    // Special case for meter phase label
    const isMeterPhaseLabel = componentType && componentType === 'other' && filterKey === PHASE_FILTER_KEY
    if (isMeterPhaseLabel) {
      label = 'Meter Phase'
    }

    return (
      <>
        <AccordionCard
          name={props.id || props.label}
          elevation={0}
          onChange={onValueChanged}
          title={label || props.label}
          disabled={props.disabled}
          defaultExpanded={!!options?.defaultExpanded}
          className={classes.root}
        >
          <BasicRenderer {...props} />
        </AccordionCard>
        {!!options?.showDivider && <Divider className={classes.divider} />}
      </>
    )
  }
}

export default createAccordionRenderer

import React, { ComponentType } from 'react'
import _ from 'lodash'
import { useMediaQuery } from '@material-ui/core'
import { LAYOUTS, breakpoints } from './LayoutConfig'
// import EventListener from 'react-event-listener'

const IntermediaryComponent = (BaseComponent: ComponentType): ComponentType => {
  const ComponentWithMediaQuery = (props: any) => {
    let currentLayout
    const isXS = useMediaQuery((theme: any) => theme.breakpoints.up(LAYOUTS['xs'].width), { noSsr: true })
    const isSM = useMediaQuery((theme: any) => theme.breakpoints.up(LAYOUTS['sm'].width), { noSsr: true })
    const isMD = useMediaQuery((theme: any) => theme.breakpoints.up(LAYOUTS['md'].width), { noSsr: true })
    const isLG = useMediaQuery((theme: any) => theme.breakpoints.up(LAYOUTS['lg'].width), { noSsr: true })
    const isXL = useMediaQuery((theme: any) => theme.breakpoints.up(LAYOUTS['xl'].width), { noSsr: true })
    // Object.values(LAYOUTS).forEach((layout) => {
    //   const isMatched = useMediaQuery((theme: any) => theme.breakpoints.up(layout.width))
    //   if (isMatched) currentLayout = layout.layout
    // })
    if (isXL) {
      currentLayout = LAYOUTS['xl'].layout
    } else if (isLG) {
      currentLayout = LAYOUTS['lg'].layout
    } else if (isMD) {
      currentLayout = LAYOUTS['md'].layout
    } else if (isSM) {
      currentLayout = LAYOUTS['sm'].layout
    } else {
      currentLayout = LAYOUTS['xs'].layout
    }
    return <BaseComponent {...props} layout={currentLayout} />
  }

  ComponentWithMediaQuery.defaultProps = BaseComponent.defaultProps

  return ComponentWithMediaQuery
}

export default IntermediaryComponent

import { authSelectors } from 'ducks/auth'
import { getSupplierEnumByFilterKey } from 'pages/ordering/configs'
import { CartDiscount, getCartDiscount, getCartDiscountValue } from 'pages/ordering/order/CartDiscounts'
import OrderLineItem from 'pages/ordering/OrderLineItem'
import ProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/projectOrderPresenter'
import { LineItemLoadedDataType } from 'pages/ordering/type'
import { getOrderCost } from 'pages/ordering/utils'
import useHardwareDetailsDynamic from 'projectSections/sections/design/sideDrawer/bom/useHardwareDetailsDynamic'
import { BomLineItemsHashMap } from 'projectSections/sections/design/sideDrawer/bom/useMapHardwareDetailsToBomLineItems'
import { HardwareDetailType } from 'projectSections/sections/design/systems/tabs/summary/hardware/getHardwareDetailFromSystem'
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { isMountingComponent } from 'util/misc'

type HardwarePriceAndStockResponse = {
  loadingHardware: boolean
  totalQuantity: number
  availableQuantity: number
  discountedTotal: number
}

const useGetHardwarePriceAndStock = (selectedSupplier): HardwarePriceAndStockResponse => {
  const orgId = useSelector(authSelectors.getOrgId)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [loadedData, setLoadedData] = useState<LineItemLoadedDataType[] | undefined>()
  const hardwareDetails = useHardwareDetailsDynamic()
  const supplierEnum = useMemo(() => {
    if (selectedSupplier) return getSupplierEnumByFilterKey(selectedSupplier)
  }, [selectedSupplier])

  const loadHardwarePriceAndStock = useCallback(
    async (supplierFilterKey) => {
      if (!orgId || hardwareDetails.length === 0) return
      setIsLoading(true)
      try {
        const loadedData = await ProjectOrderPresenter.getPriceAndStock({
          orgId,
          lineItems: hardwareDetails.map(
            (hardware) =>
              new OrderLineItem({
                code: encodeURIComponent(hardware.code),
                quantity: hardware.quantity,
                selectedDistributor: supplierFilterKey,
              })
          ),
          supplierFilterKey,
        })
        setLoadedData(loadedData)
      } catch (error) {
        console.error('Failed to load hardware price and stock:', error)
      }
      setIsLoading(false)
    },
    [orgId, hardwareDetails]
  )

  const getLineItemFromHardwareDetail = useCallback(
    (hardwareDetail) => {
      const loadedComponentDetail = loadedData?.find((data) => {
        return data.code === hardwareDetail.code
      })
      if (loadedComponentDetail === undefined || selectedSupplier === undefined) {
        return undefined
      }
      return new OrderLineItem({
        componentType: hardwareDetail.type,
        status: 'loaded',
        code: hardwareDetail.code,
        selectedDistributor: selectedSupplier,
        data: {
          code: hardwareDetail.code,
          component_type: hardwareDetail.type,
          description: loadedComponentDetail.description || '',
          short_description: loadedComponentDetail?.short_description || '',
          distributors: loadedComponentDetail.distributors || [],
          data: JSON.stringify({}),
          id: loadedComponentDetail.id,
          manufacturer_name: loadedComponentDetail.manufacturer_name,
          title: loadedComponentDetail.title || '',
        },
        confirmed: true,
        quantity: hardwareDetail.quantity,
      })
    },
    [loadedData, selectedSupplier]
  )

  const bomLineItemsHashMap = useMemo(() => {
    const bomLineItemsHashMap: BomLineItemsHashMap = {
      module: [],
      mounting: [],
      inverter: [],
      battery: [],
      other: [],
      all: [],
    }

    if (selectedSupplier === undefined) {
      return bomLineItemsHashMap
    }
    hardwareDetails.forEach((hardwareDetail: HardwareDetailType) => {
      const lineItem = getLineItemFromHardwareDetail(hardwareDetail)

      if (lineItem !== undefined) {
        const loadedComponentDetail = loadedData?.find((data) => data.code === hardwareDetail.code)
        const bomComponentType = isMountingComponent(loadedComponentDetail?.component_type)
          ? 'mounting'
          : hardwareDetail.type
        bomLineItemsHashMap[bomComponentType].push(lineItem)
        bomLineItemsHashMap['all'].push(lineItem)
      }
    })

    return bomLineItemsHashMap
  }, [hardwareDetails, loadedData])

  const [availableLineItems] = useMemo(() => {
    const availableLineItems: OrderLineItem[] = []
    bomLineItemsHashMap['all']?.forEach((lineItem) => {
      const stockLevelStatus = ProjectOrderPresenter.getStockLevelStatusMain({
        quantity: lineItem.quantity,
        stockLevels: lineItem.data?.distributors[0]?.stock_levels,
        isAvailable: !!lineItem.data?.distributors[0]?.is_available,
      })

      if (stockLevelStatus !== 'not_available') {
        availableLineItems.push(lineItem)
      }
    })
    return [availableLineItems]
  }, [bomLineItemsHashMap['all']])

  const [cartDiscount, setCartDiscount] = useState<CartDiscount>(null)
  const subtotal = getOrderCost(availableLineItems)
  const cartDiscountValue = getCartDiscountValue(subtotal, cartDiscount)
  const discountedTotal = subtotal - cartDiscountValue
  const totalQuantity = hardwareDetails.reduce((sum, lineItem) => {
    return sum + (lineItem.quantity || 0)
  }, 0)
  const availableQuantity = availableLineItems.reduce((sum, lineItem) => {
    return sum + (lineItem.quantity || 0)
  }, 0)

  useLayoutEffect(() => {
    if (selectedSupplier) {
      loadHardwarePriceAndStock(selectedSupplier)
    }
  }, [selectedSupplier])

  useEffect(() => {
    if (supplierEnum) {
      getCartDiscount(
        availableLineItems,
        supplierEnum,
        (cartDiscount) => {
          setCartDiscount(cartDiscount)
        },
        'project'
      )
    }
  }, [availableLineItems, supplierEnum])

  return {
    loadingHardware: isLoading,
    totalQuantity,
    availableQuantity,
    discountedTotal,
  }
}

export default useGetHardwarePriceAndStock

import { Grid, Paper, Tooltip, Typography } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import inflection from 'inflection'
import { Checkbox, IconButton } from 'opensolar-ui'
import ComponentAutocompleteInput from 'pages/inventory/components/ComponentAutocompleteInput'
import { NumberInput } from 'react-admin'
import { useField, useForm, useFormState } from 'react-final-form'
import { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { TRANSFER_TYPE } from '../constants'
import { TransferFormIntentionType, TransferFormType } from '../type'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  tableHeaderContainer: {
    backgroundColor: theme.greyLight3,
    borderBottom: '1px solid #e7e7e7',
    height: '48px',
  },
  header: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
  },
  table: {
    tableLayout: 'fixed',
  },
  tableBodyContainer: {
    minHeight: 62,
    borderBottom: '1px solid #e7e7e7',
  },
  componentAutocompleteInputWrapper: {
    maxWidth: 250,
    marginTop: 15,
  },
  title: {
    color: theme.errorRed,
  },
}))

const LineItemRow = ({
  disabled,
  lineItem,
  isItemSelected,
  onCheck,
  transferType,
  source,
  index,
  intention,
  isAutoOrder,
}: {
  disabled?: boolean
  lineItem
  isItemSelected: boolean
  onCheck(): void
  transferType: TransferFormType
  source: string
  index: number
  intention?: TransferFormIntentionType
  isAutoOrder?: boolean
}) => {
  const classes = useStyles()
  const form = useForm()
  const componentType = lineItem.category
    ? inflection.capitalize(lineItem.category && lineItem.category)
    : lineItem.component_type

  const handleDisplayQuantity = () => {
    if (lineItem.quantity_in_bom && lineItem.quantity_in_bom !== 0) {
      return lineItem.quantity_in_bom
    } else if (lineItem.quantity_in_transfers && lineItem.quantity_in_transfers !== 0) {
      return lineItem.quantity_in_transfers
    }
    return lineItem.quantity
  }

  if (lineItem.quantity === 0) return null

  return (
    <Grid container item xs={12} alignItems={'center'} className={classes.tableBodyContainer}>
      <Grid item xs={1}>
        {transferType === TRANSFER_TYPE.OUTGOING ? (
          <Tooltip
            title={
              lineItem.quantity_in_transfers > 0
                ? 'This component was found in this transfer, but not used in project BOM. Remove this component from the transfer if the project does not use these components, to ensure your inventory forecast is accurate.'
                : 'This component was found in project BOM, but has been excluded from this transfer, and so it is not included in inventory forecasts. Add this component to the transfer if the project used these components, to ensure your inventory forecast is accurate.'
            }
          >
            <div>
              <Checkbox
                disabled={disabled || lineItem.quantity_in_transfers > 0}
                checked={isItemSelected}
                onChange={onCheck}
              />
            </div>
          </Tooltip>
        ) : (
          <Checkbox
            disabled={disabled || lineItem.quantity_in_transfers > 0}
            checked={isItemSelected}
            onChange={onCheck}
          />
        )}
      </Grid>
      <Grid item xs={2}>
        {componentType}
      </Grid>
      <Grid item xs={3}>
        {lineItem.code}
      </Grid>
      <Grid item xs={3}>
        {intention === 'edit_po' ? (
          <NumberInput
            label=""
            required
            source={`${source}.${index}.quantity`}
            name={`${source}.${index}.quantity`}
            value={lineItem.quantity}
            disabled={isAutoOrder}
          />
        ) : (
          handleDisplayQuantity()
        )}
      </Grid>
      <Grid item xs={3}>
        {intention === 'edit_po' && (
          <IconButton
            size="small"
            disabled={isAutoOrder}
            onClick={() => {
              form.change(`${source}.${index}.quantity`, 0)
            }}
          >
            <DeleteOutlinedIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  )
}

const AcceptHardwareItems = ({
  title,
  disabled,
  selectedItemSource,
  recordedItemSource,
  newItemSource,
  transferType,
  intention,
  isAutoOrder,
}: {
  title?: string
  disabled?: boolean
  selectedItemSource: string
  recordedItemSource: string
  newItemSource: string
  transferType: TransferFormType
  intention?: TransferFormIntentionType
  isAutoOrder: boolean | undefined
}) => {
  const formState = useFormState()
  const form = useForm()
  useField(selectedItemSource, { subscription: { value: true } })
  const recordedLineItems = formState.values?.[recordedItemSource] || []
  const selectedLineItems = formState.values?.[selectedItemSource] || new Set()
  const newLineItems = formState.values?.[newItemSource] || []
  const classes = useStyles()
  const isAllSelected = selectedLineItems.size > 0 && selectedLineItems.size === recordedLineItems.length
  const isIndeterminateSelected = selectedLineItems.size > 0 && selectedLineItems.size < recordedLineItems.length

  const handleCheck = (lineItem, index) => {
    if (selectedLineItems.has(lineItem)) {
      selectedLineItems.delete(lineItem)
    } else {
      selectedLineItems.add(lineItem)
      if (transferType === TRANSFER_TYPE.OUTGOING) {
        const newItems = [
          ...newLineItems,
          {
            code: lineItem.code,
            component_type: inflection.capitalize(lineItem.category && lineItem.category),
            quantity: lineItem.quantity_in_bom,
          },
        ]
        form.mutators.updateField(newItemSource, newItems)
        recordedLineItems.splice(index, 1)
        form.mutators.updateField(recordedItemSource, recordedLineItems)
      }
    }
    form.change(selectedItemSource, new Set(selectedLineItems))
  }

  const onSelectAllClick = () => {
    if (isAllSelected) {
      form.change(selectedItemSource, new Set())
    } else {
      form.change(selectedItemSource, new Set(recordedLineItems))
    }
  }

  const handleComponentSelected = ({ code, componentType }) => {
    const newItems = [...newLineItems, { code, component_type: inflection.capitalize(componentType && componentType) }]
    form.change(newItemSource, newItems)
  }

  return (
    <>
      <Typography variant="h6" className={title ? classes.title : undefined}>
        {title || 'Order Summary'}
      </Typography>
      <Paper elevation={1}>
        <Table className={classes.table} size="small">
          <Grid container className={classes.tableHeaderContainer} alignItems="center">
            <Grid container item alignItems="center" xs={12} className={classes.header} spacing={1}>
              <Grid item xs={1}>
                <Checkbox
                  disabled={disabled || transferType === TRANSFER_TYPE.OUTGOING}
                  indeterminate={isIndeterminateSelected}
                  checked={isAllSelected}
                  onChange={onSelectAllClick}
                  inputProps={{ 'aria-label': 'select all desserts' }}
                />
              </Grid>
              <Grid item xs={2}>
                {'Category'}
              </Grid>
              <Grid item xs={3}>
                {'Item'}
              </Grid>
              <Grid item xs={3}>
                {'Quantity'}
              </Grid>
              <Grid item xs={3}>
                {intention === 'edit_po' && 'Actions'}
              </Grid>
            </Grid>
          </Grid>
          <TableBody>
            {recordedLineItems.map((lineItem, index) => {
              return (
                <LineItemRow
                  disabled={disabled}
                  key={lineItem.code}
                  lineItem={lineItem}
                  source={recordedItemSource}
                  index={index}
                  isItemSelected={selectedLineItems.has(lineItem)}
                  onCheck={() => handleCheck(lineItem, index)}
                  transferType={transferType}
                  intention={intention}
                  isAutoOrder={isAutoOrder}
                />
              )
            })}
            {newLineItems.map((lineItem, index) => {
              return (
                <LineItemRow
                  disabled={disabled}
                  key={lineItem.code}
                  lineItem={lineItem}
                  source={newItemSource}
                  index={index}
                  isItemSelected={selectedLineItems.has(lineItem)}
                  onCheck={() => handleCheck(lineItem, index)}
                  transferType={transferType}
                  intention={intention}
                  isAutoOrder={isAutoOrder}
                />
              )
            })}
          </TableBody>
        </Table>
      </Paper>
      {intention === 'edit_po' && !isAutoOrder && (
        <div className={classes.componentAutocompleteInputWrapper}>
          <ComponentAutocompleteInput onSelect={handleComponentSelected} disabled={false} trackingSource="edit_po" />
        </div>
      )}
    </>
  )
}

export default AcceptHardwareItems

import AutoStringButton from '../../actionTools/AutoStringButton'
import DeleteToolButton from '../../actionTools/DeleteToolButton'
import DuplicateToolButton from '../../actionTools/DuplicateToolButton'

const ActionTools = ({ group, moduleGrids }) => {
  return (
    <div>
      <DeleteToolButton />
      {group && <DuplicateToolButton object={group} />}
      <AutoStringButton moduleGrids={moduleGrids} />
    </div>
  )
}

export default ActionTools

import { useEffect, useState } from 'react'
import type { ComponentTypesV2 } from 'types/selectComponent'
import BasicFilter from '../basic/BasicFilter'
import { CURRENT_FILTER_KEY_V2 } from '../currentType/CurrentTypeFilterNodeFactoryV2'
import type {
  ConfigurableFilterOptionsType,
  FilterComponentNodeV2,
  FilterGenericPropsType,
  FilterValuesType,
  OptionGenericType,
} from '../typeV2'
import { parseStringifiedListFilterValue } from '../utils/utils'
export const VOLTAGE_AND_CURRENT_FILTER_KEY_V2 = 'voltage_to_current_rating'

const DEFAULT_OPTION = { id: 'default', title: 'All', value: '' }

export const isVoltageAndCurrentFilterAvailableV2 = (filterValues: FilterValuesType) => {
  const currentTypes = parseStringifiedListFilterValue<ComponentTypesV2>({
    filterValues,
    key: 'types',
  })
  return currentTypes.has('isolator') && filterValues[CURRENT_FILTER_KEY_V2] === 'dc'
}

const getVoltageAndCurrentFilterOption = (value: string) => {
  // The value is injected from spa/app/src/projectSections/sections/design/systems/Slots.tsx
  // consider to refactor it...
  return { id: value, title: 'Compatible with system', value }
}

const createVoltageAndCurrentFilterNode = <T,>({
  label = 'Component Type',
  ...restConfigs
}: ConfigurableFilterOptionsType<string, T>): FilterComponentNodeV2 => {
  const FilterComponent = (filterProps: FilterGenericPropsType) => {
    const [options, setOptions] = useState<OptionGenericType<string>[]>([DEFAULT_OPTION])
    const value = filterProps.allFilterValues[VOLTAGE_AND_CURRENT_FILTER_KEY_V2]

    useEffect(() => {
      if (value && !options.find((option: OptionGenericType<string>) => option.value === value)) {
        setOptions((options) => {
          return [...options, getVoltageAndCurrentFilterOption(value)]
        })
      }
    }, [value])

    if (!isVoltageAndCurrentFilterAvailableV2(filterProps.allFilterValues) || options.length < 2) {
      return null
    }

    return (
      <BasicFilter
        label={label}
        defaultSelectedOptionValue={DEFAULT_OPTION.value}
        options={options}
        filterKey={VOLTAGE_AND_CURRENT_FILTER_KEY_V2}
        {...restConfigs}
        {...filterProps}
      />
    )
  }

  return {
    key: VOLTAGE_AND_CURRENT_FILTER_KEY_V2,
    component: FilterComponent,
  }
}

export default createVoltageAndCurrentFilterNode

import { logAmplitudeEvent } from 'amplitude/amplitude'
import type { OsChipVariantType } from 'elements/chip/Chip'
import React, { memo, useCallback, useState } from 'react'
import { Confirm } from 'react-admin'
import { useHistory } from 'react-router-dom'
import AllocationBasicChip from './AllocationBasicChip'

const RedirectChip = memo(
  ({
    label,
    loading,
    redirectLink,
    variant,
  }: {
    label: string
    loading: boolean
    redirectLink: string
    variant: OsChipVariantType
  }) => {
    const [showConfirm, setShowConfirm] = useState(false)
    const history = useHistory()
    const handleChipClicked = useCallback(() => {
      logAmplitudeEvent('hardware_project_redirect', {
        action: 'clicked',
        context: 'redirect_chip',
        source: 'order_table',
      })
      setShowConfirm(true)
    }, [])
    const onConfirm = useCallback(() => {
      logAmplitudeEvent('generic_confirmation_interacted', {
        action: 'confirmed',
      })
      history.push(redirectLink)
    }, [redirectLink])

    const onClose = useCallback(() => {
      setShowConfirm(false)
      logAmplitudeEvent('generic_confirmation_interacted', {
        action: 'closed',
      })
    }, [])
    return (
      <>
        <AllocationBasicChip loading={loading} label={label} onClick={handleChipClicked} variant={variant} />
        {showConfirm && (
          <Confirm
            isOpen={showConfirm}
            title={'Leave order?'}
            content="Your cart progress will be lost if you navigate away from this page. Please finalise any orders if you do not wish to lose your progress."
            confirmColor="warning"
            cancel="Stay on page"
            confirm="Leave this page"
            onConfirm={onConfirm}
            onClose={onClose}
          />
        )}
      </>
    )
  }
)

export default RedirectChip

import { InventoryFiltersType } from '../filter/type'

class InventoryForecastPresenter {
  private forecastProjectInventory
  private loadInventoryData
  private loadDashboardStatistics
  private loadActivityData

  constructor({ forecastProjectInventory, loadInventoryData, loadDashboardStatistics, loadActivityData }) {
    this.forecastProjectInventory = forecastProjectInventory
    this.loadInventoryData = loadInventoryData
    this.loadDashboardStatistics = loadDashboardStatistics
    this.loadActivityData = loadActivityData
  }

  reloadInventoryDashboardData = async (filters?: InventoryFiltersType) => {
    const options = {
      filters,
      forecast: true,
    }
    await Promise.all([
      this.loadInventoryData(options),
      this.forecastProjectInventory(options),
      this.loadDashboardStatistics(options),
      this.loadActivityData(),
    ])
  }

  init = async (filters?: InventoryFiltersType) => {
    await this.reloadInventoryDashboardData(filters)
  }
}

export default InventoryForecastPresenter

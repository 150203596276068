import { Divider } from '@material-ui/core'
import { featureConfigSelectors, getPublicFeatureConfig } from 'ducks/featureConfig'
import Alert from 'elements/Alert'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { round_number_to_specific_decimal_places } from 'util/misc'
import { useStyles } from './styles'

const NEM3ExportRateSummaryPanel = ({ utilityName, tariffData, sector }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getPublicFeatureConfig('nem3_avoided_cost_export_rates'))
  }, [])

  const featureConfigAvoidedCost = useSelector((state) =>
    featureConfigSelectors.getFeatureConfigData(state, 'nem3_avoided_cost_export_rates')
  )

  const currentYear = new Date().getFullYear()
  const isResidential = sector === 1
  const isLowIncome = tariffData?.applicability?.is_low_income
  const avgExportRates = featureConfigAvoidedCost?.export_rates_by_year_avg?.[currentYear]?.[utilityName]

  let avgPlusAdder = 0
  if (isLowIncome && isResidential) {
    avgPlusAdder = featureConfigAvoidedCost?.export_rate_adders_by_year?.[currentYear]?.[utilityName]?.low_income || 0
  } else if (isResidential) {
    avgPlusAdder = featureConfigAvoidedCost?.export_rate_adders_by_year?.[currentYear]?.[utilityName]?.residential || 0
  }

  if (avgExportRates)
    return (
      <>
        <Divider className={classes.dividerLargeGap}></Divider>
        <h1>NEM3.0 Export Rates Applied</h1>
        <Alert severity="info" styles={{ fontSize: '14px' }}>
          You cannot modify or add any export rates while "Apply NEM3.0 Export Rates (California)" is enabled. If you
          would like to set your own export rates please untoggle "Apply NEM3.0 Export Rates (California)".
        </Alert>
        <ul>
          <li>
            Export Compensation rates are based on hourly values calculated from the Avoided Cost Calculator (ACC). The
            export rates consist of hour by month rate averaging, with weekday vs weekend differentiation giving us a
            total of 576 export rates.
          </li>
          <li>
            An export credit is added ontop of the ACC export rates called the Adopted Avoided Cost Calculator Plus
            Adders. This adder amount is only available until 2027 and will decrease by 20% each year until 2027.
          </li>
          <li>
            OpenSolar's bill calculations will take into account of the latest projections of export rates and apply 576
            values year by year for the full simulation of the system's lifetime. You can download the{' '}
            <a href="https://www.cpuc.ca.gov/nemrevisit" target="_blank" rel="noreferrer">
              Avoided Cost Calculator here.
            </a>
          </li>
        </ul>
        <p></p>
        <h2>Average Export Rates</h2>
        <p>
          Rates shown below are only for your reference, as OpenSolar uses the more time-differentiated 576 export
          values in our underlying calculations.
        </p>
        <table className="standard" style={{ width: '50%' }}>
          <tr>
            <th>Time of Use Period</th>
            <th>Average Export Rate ($/kWh)</th>
            <th>Adopted ACC Plus Adders ($/kWh)</th>
            <th>Total ($/kWh)</th>
          </tr>
          <tr>
            <td>
              <b>Summer Peak</b>
            </td>
            <td>{avgExportRates?.summer_peak}</td>
            <td>{avgPlusAdder}</td>
            <td>{round_number_to_specific_decimal_places(avgExportRates?.summer_peak + avgPlusAdder, 4)}</td>
          </tr>
          <tr>
            <td>
              <b>Summer Part-Peak</b>
            </td>
            <td>{avgExportRates?.summer_part_peak}</td>
            <td>{avgPlusAdder}</td>
            <td>{round_number_to_specific_decimal_places(avgExportRates?.summer_part_peak + avgPlusAdder, 4)}</td>
          </tr>
          <tr>
            <td>
              <b>Summer Off-Peak</b>
            </td>
            <td>{avgExportRates?.summer_off_peak}</td>
            <td>{avgPlusAdder}</td>
            <td>{round_number_to_specific_decimal_places(avgExportRates?.summer_off_peak + avgPlusAdder, 4)}</td>
          </tr>
          <tr>
            <td>
              <b>Winter Peak</b>
            </td>
            <td>{avgExportRates?.winter_peak}</td>
            <td>{avgPlusAdder}</td>
            <td>{round_number_to_specific_decimal_places(avgExportRates?.winter_peak + avgPlusAdder, 4)}</td>
          </tr>
          <tr>
            <td>
              <b>Winter Part-Peak</b>
            </td>
            <td>{avgExportRates?.winter_part_peak}</td>
            <td>{avgPlusAdder}</td>
            <td>{round_number_to_specific_decimal_places(avgExportRates?.winter_part_peak + avgPlusAdder, 4)}</td>
          </tr>
          <tr>
            <td>
              <b>Winter Off-Peak</b>
            </td>
            <td>{avgExportRates?.winter_off_peak}</td>
            <td>{avgPlusAdder}</td>
            <td>{round_number_to_specific_decimal_places(avgExportRates?.winter_off_peak + avgPlusAdder, 4)}</td>
          </tr>
        </table>
      </>
    )
  else return <></>
}

export default NEM3ExportRateSummaryPanel

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import React, { useEffect } from 'react'
import { useFormState } from 'react-final-form'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { ProOnlyFinanceCTAType } from 'types/orgs'
import { ProjectType } from 'types/projects'

const useStyles = makeOpenSolarStyles((theme) => ({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageWrapper: {
    marginBottom: '20px',
  },
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  buttonWrapper: {
    margin: '10px 40px',
  },
}))

type PropTypes = {
  cta: ProOnlyFinanceCTAType
  isOpen: boolean
  onClose: () => void
}

const CTADialog: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const project = useFormState().values as ProjectType

  useEffect(() => {
    if (props.isOpen && props.cta.integration) {
      logAmplitudeEvent('finance_proposal_dialog_cta_opened', {
        integration: props.cta.integration,
        project_id: project?.id,
      })
    }
  }, [props.isOpen, props.cta.integration])

  const onClick = () => {
    if (props.cta.integration === 'deep_green') {
      logAmplitudeEvent('deep_green_cta_clicked', { project_id: project?.id })
    } else {
      logAmplitudeEvent('finance_proposal_dialog_cta_submitted', {
        integration: props.cta.integration,
        project_id: project?.id,
      })
    }
    if (props.cta.cta_dialog_config_json.primary_button_redirect_config) {
      // start with the base URL
      let url = props.cta.cta_dialog_config_json.primary_button_redirect_config?.base_url
      // if parameters are present, loop through them to populate the full redirect URL
      props.cta.cta_dialog_config_json.primary_button_redirect_config?.parameters?.forEach((parameterConfig) => {
        // ensure the parameterConfig has both a key and a value
        if (!parameterConfig.key || (!parameterConfig.project_field && !parameterConfig.static_value_or_prefix)) return
        if (url.includes('?')) url += `&${parameterConfig.key}=`
        else url += `?${parameterConfig.key}=`

        // the static prefix and the project field can both be populated for a given parameter, or just one can
        if (parameterConfig.static_value_or_prefix) url += parameterConfig.static_value_or_prefix
        if (parameterConfig?.project_field && project[parameterConfig.project_field])
          url += project[parameterConfig.project_field]
      })
      window.open(url, '_blank', 'noopener noreferrer')
    }
    props.onClose()
  }

  return (
    <Dialog open={props.isOpen} onClose={props.onClose}>
      <DialogTitle>{props.cta.cta_dialog_config_json?.dialog_title}</DialogTitle>
      <DialogContent>
        <div className={classes.contentWrapper}>
          {props.cta.cta_dialog_config_json?.include_logo && (
            <div className={classes.imageWrapper}>
              <img src={props.cta.logo_url} />
            </div>
          )}
          <div dangerouslySetInnerHTML={{ __html: props.cta.cta_dialog_config_json.content_html }}></div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.actionsWrapper}>
          <div className={classes.buttonWrapper}>
            <ProUXButton
              type="secondary"
              onClick={props.onClose}
              label={props.cta.cta_dialog_config_json.dismiss_button_label || 'Dismiss'}
            />
          </div>
          <div className={classes.buttonWrapper}>
            <ProUXButton
              type="primary"
              onClick={onClick}
              label={props.cta.cta_dialog_config_json.primary_button_label || 'Continue'}
            />
          </div>
        </div>
      </DialogActions>
    </Dialog>
  )
}
export default CTADialog

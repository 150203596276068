import { DeleteOutlined } from '@material-ui/icons'
import classnames from 'classnames'
import ScheduleDialog from 'elements/scheduleDialog/ScheduleDialog'
import { defaultSchedule } from 'elements/scheduleDialog/utils'
import _ from 'lodash'
import { IconButton } from 'opensolar-ui'
import { FC, useEffect, useState } from 'react'
import { SelectInput, TextInput, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import Schedule from 'resources/batterySchemes/Schedule'
import { ADJUSTMENT_OPTIONS } from 'resources/utilityTariffs/common'
import { tableStyles } from 'resources/utilityTariffs/styles'
import RatePeriodTiers from './RatePeriodTiers'
import { TOUPeriod } from './types'

type PropsType = {
  rateList: any[]
  source: string
  rateIndex: number
  allowSetSchedule: boolean
  addRatePeriod: () => void
  disabled?: boolean
  chargeCategories?: ChargeCat[]
}

type ChargeCat = {
  id: string
  name: string
}

const RatePeriods: FC<PropsType> = ({
  rateList,
  source,
  rateIndex,
  allowSetSchedule,
  addRatePeriod,
  disabled,
  chargeCategories,
}) => {
  const form = useForm()
  const classes = tableStyles()
  const translate = useTranslate()
  const rate = rateList[rateIndex]
  const rateSource = `${source}.${rateIndex}`
  const ratePeriods = rate?.tou_periods
  const deleteRatePeriod = (periodIndex) => {
    let currList = [...ratePeriods]
    currList.splice(periodIndex, 1)
    form.mutators.updateField(`${rateSource}.tou_periods`, currList)
  }
  useEffect(() => {
    if (
      editingSchedule !== undefined &&
      ratePeriods?.length === 1 &&
      !_.isEqual(ratePeriods[0].schedule, defaultSchedule())
    ) {
      addRatePeriod()
      setEditingSchedule(undefined)
    }
  }, [ratePeriods])

  const [editingSchedule, setEditingSchedule] = useState<number | undefined>(undefined)
  return (
    <div className={classes.periodRow}>
      {ratePeriods ? (
        <>
          {ratePeriods?.map((ratePeriod, periodIndex) => (
            <RateInfo
              rateSource={source}
              dataSource={`${rateSource}.tou_periods.${periodIndex}`}
              infoItem={ratePeriod}
              periodIndex={periodIndex}
              periodList={ratePeriods}
              deleteRatePeriod={() => deleteRatePeriod(periodIndex)}
              allowSetSchedule={allowSetSchedule}
              editSchedule={() => {
                setEditingSchedule(periodIndex)
              }}
              disabled={disabled}
              chargeCategories={chargeCategories}
            />
          ))}
          {editingSchedule !== undefined && (
            <ScheduleDialog
              isOpen={true}
              onClose={() => {
                setEditingSchedule(undefined)
              }}
              handleSave={(state) => {
                let newPeriods = _.cloneDeep(ratePeriods)
                newPeriods[editingSchedule] = { ...newPeriods[editingSchedule], schedule: state }
                form.change(`${rateSource}.tou_periods`, newPeriods)
                if (newPeriods.length !== 1 || _.isEqual(state, defaultSchedule())) {
                  setEditingSchedule(undefined)
                }
              }}
              dialogTitle={translate('Time-of-Use Period - ') + ratePeriods[editingSchedule].name}
              allSchedules={ratePeriods?.map((x) => {
                return x.schedule
              })}
              editingIndex={editingSchedule}
            />
          )}
        </>
      ) : (
        <RateInfo
          allowSetSchedule={false}
          rateSource={source}
          dataSource={rateSource}
          infoItem={rate}
          disabled={disabled}
          chargeCategories={chargeCategories}
        />
      )}
    </div>
  )
}

type RateInfoType = {
  rateSource: string
  dataSource: string
  infoItem?: any
  deleteRatePeriod?: any
  allowSetSchedule: boolean
  periodList?: TOUPeriod[]
  periodIndex?: number
  editSchedule?: any
  disabled?: boolean
  chargeCategories?: ChargeCat[]
}

const RateInfo: FC<RateInfoType> = ({
  dataSource,
  rateSource,
  infoItem,
  deleteRatePeriod,
  allowSetSchedule,
  periodIndex,
  periodList,
  editSchedule,
  disabled,
  chargeCategories,
}) => {
  const classes = tableStyles()
  const adjustment = ADJUSTMENT_OPTIONS.includes(rateSource.replace('tariffData.', ''))

  return (
    <div className={`${classes.ratePeriod} ${periodList && periodList?.length > 1 ? 'periodRow' : ''}`}>
      <div className={`${infoItem?.blocks?.length > 1 ? classes.multiTier : ''} ${classes.rateName}`}>
        <TextInput
          fullWidth
          className={classnames(classes.rateInput, classes.rateRow)}
          variant="outlined"
          label=""
          source={`${dataSource}.name`}
          options={{
            InputLabelProps: {
              shrink: true,
            },
          }}
          fullwidth
          disabled={disabled}
        />
        {chargeCategories && (
          <SelectInput
            className={classnames(classes.rateInput, classes.rateRow)}
            label={false}
            variant="outlined"
            source={`${dataSource}.charge_type`}
            choices={chargeCategories}
            // This parse/format hack is required to force RA to render null/undefined values with a real label
            parse={(value) => (value === 'null' ? undefined : value)}
            format={(value) => value || 'null'}
            translateChoice={false}
          />
        )}
      </div>

      <div className={classes.tierContainer} style={{ marginBottom: infoItem?.blocks?.length > 1 ? 'auto' : 'unset' }}>
        <RatePeriodTiers
          tiers={infoItem.blocks || [{ value: infoItem.value, units: infoItem.units, limit: undefined }]}
          source={rateSource}
          tierSource={infoItem.blocks ? `${dataSource}.blocks` : dataSource}
          adjustment={adjustment}
          disabled={disabled}
        />
      </div>
      <div className={`${classes.row} ${classes.scheduleContainer}`}>
        {allowSetSchedule && periodList && periodIndex !== undefined && (
          <Schedule itemList={periodList} itemIndex={periodIndex} onEdit={editSchedule} disabled={disabled} />
        )}
      </div>
      {allowSetSchedule && periodList?.length !== 1 ? (
        <IconButton onClick={deleteRatePeriod} className={classes.deleteBtn} disabled={disabled}>
          <DeleteOutlined />
        </IconButton>
      ) : (
        <div style={{ width: 32 }} />
      )}
    </div>
  )
}

export default RatePeriods

import { Grid, Typography } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { Button } from 'opensolar-ui'
import { getSupplierConfig } from 'pages/ordering/configs'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import { memo, useCallback } from 'react'
import HdmConnectUserButton from 'services/hdm/HdmConnectUserButton'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import HardwareStandardBannerContainer from '../HardwareStandardBannerContainer'

const useHdmBannerStyles = makeOpenSolarStyles((theme) => ({
  container: {
    padding: 20,
  },
  partner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  badge: {
    width: 110,
    background: theme.themeColorLight,
    padding: '3px 10px',
    borderRadius: '12px',
  },
  img: {
    maxWidth: 100,
  },
  link: { color: theme.blue, textDecoration: 'underline', cursor: 'pointer' },
}))

const HdmConnectBanner = () => {
  const classes = useHdmBannerStyles()

  const handleLearnMoreClicked = useCallback(() => {
    window.open('https://pages.opensolar.com/partners/hdmsolar', '_blank', 'noreferrer')
  }, [])

  const handleClickRegisterLink = () => {
    window.open('https://share.hsforms.com/1X9RRfLizTHuKYzhJIXtdXwcne2p', '_blank')
    logAmplitudeEvent('hardware_banner_register_click', { supplier: 'hdm' })
  }

  return (
    <HardwareStandardBannerContainer>
      <Grid container className={classes.container} wrap={'wrap'} spacing={3}>
        <Grid item xs={12} sm={2} container alignItems="center" justify="center" direction="column">
          <div style={{ maxWidth: 120 }}>
            <img
              className={classes.img}
              alt="supplier-logo"
              src={getSupplierConfig(HardwareSupplierEnum.Hdm)?.logoUrl || ''}
            />
          </div>
          <div className={classes.badge}>
            <Typography align="center" variant="caption">
              OpenSolar Partner
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={7}>
          <div>
            <Typography variant="h6" align="center" gutterBottom>
              Connect your hardware supplier HDM
            </Typography>
            <Typography align="center" variant="body1" gutterBottom>
              Connect your HDM account to OpenSolar to view trade discounts and order using your credit balance.
            </Typography>
            <Typography align="center" variant="body1" className={classes.link} onClick={handleLearnMoreClicked}>
              Learn more
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={3} container alignItems="center" justify="center" direction="column">
          <HdmConnectUserButton variant="contained" trackSource="hardware_banner" color="primary" fullWidth />
          <Typography variant="caption" align="center">
            OR
          </Typography>
          <Button onClick={handleClickRegisterLink} variant="outlined" color="primary" fullWidth>
            Register to View Pricing
          </Button>
        </Grid>{' '}
      </Grid>
    </HardwareStandardBannerContainer>
  )
}

export default memo(HdmConnectBanner)

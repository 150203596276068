import CustomField from 'elements/field/CustomField'
import { List } from 'elements/react-admin/List'
import React from 'react'
import {
  AutocompleteInput,
  Create,
  Datagrid,
  Edit,
  FilterComp,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'
import { connect } from 'react-redux'
import { styles as standardStyles } from 'styles'
import { duplicate as duplicateAction } from '../../actions/restActions'
import EditOrImportButton from '../../elements/button/EditOrImportButton'

const inputStyle = {
  width: 256,
  maxWidth: 256,
  marginRight: 40,
}

export const LeadRecipientFilter = (props) => (
  <FilterComp {...props}>
    <TextInput label="pos.search" source="q" style={standardStyles.FILTER_FIELD_STYLE} />
    <ReferenceInput
      label="Lead Capture Form"
      source="lead_capture_form"
      reference="lead_capture_forms"
      sort={{ field: 'title', order: 'ASC' }}
    >
      <AutocompleteInput
        options={{ style: standardStyles.FILTER_FIELD_STYLE, placeholder: 'Search' }}
        optionText="title"
        optionValue="id"
      />
    </ReferenceInput>
  </FilterComp>
)

export const LeadRecipientList = connect(
  (state) => {
    return {
      org_id: state.auth ? state.auth.org_id : null,
    }
  },
  { duplicate: duplicateAction }
)((props) => (
  <List
    minQuantityToShowAsList={0}
    extraCreateButtonLabel="CREATE LEAD RECIPIENT"
    {...props}
    hasSearch={true}
    filters={<LeadRecipientFilter />}
  >
    <Datagrid duplicate={props.duplicate}>
      <ReferenceField
        source="lead_capture_form"
        label="Lead Capture Form"
        displayInCard={true}
        skipFromGrid={true}
        sortable={false}
        reference="lead_capture_forms"
        linkType="list"
      >
        <TextField source="title" />
      </ReferenceField>
      <ReferenceField
        source="recipient_org"
        label="Recipient"
        displayInCard={true}
        skipFromGrid={true}
        sortable={false}
        reference="public_orgs"
        linkType="list"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="weighting" displayInCard={true} skipFromGrid={true} sortable={true} />
      <ReferenceField
        source="observer"
        label="Observer"
        displayInCard={true}
        skipFromGrid={true}
        sortable={false}
        reference="roles"
        linkType="list"
      >
        <TextField source="display" />
      </ReferenceField>
      <EditOrImportButton sortable={false} source="actions" org_id={props.org_id} resource="lead_recipients" />
    </Datagrid>
  </List>
))

const styles = {
  floatLeft: {
    float: 'left',
    marginRight: 10,
  },
  inputWidth: {
    width: 256,
  },
}

const validateCreateLeadRecipient = (values) => {
  const errors = {}
  if (values.recipient_org === null || typeof values.recipient_org === 'undefined') {
    errors.recipient_org = ['Recipient is required']
  }
  if (values.lead_capture_form === null || typeof values.lead_capture_form === 'undefined') {
    errors.lead_capture_form = ['Lead Capture Form is required']
  }
  // Observer is not required

  return errors
}

const Inputs = (props) => {
  const translate = useTranslate()
  return (
    <div className="lead-recipient-form-wrapper">
      <CustomField
        component={ReferenceInput}
        source="lead_capture_form"
        name="lead_capture_form"
        {...props}
        reference="lead_capture_forms"
        allowEmpty
        style={{}}
      >
        <SelectInput sortChoices={true} optionText="title" optionValue="url" />
      </CustomField>

      <ReferenceInput
        label={translate('Recipient')}
        name="recipient_org"
        source="recipient_org"
        reference="public_orgs"
        optionValueField="url"
        sort={{ field: 'name', order: 'ASC' }}
        allowEmpty
        {...props}
      >
        <AutocompleteInput
          optionText="name"
          optionValue="url"
          options={{
            style: inputStyle,
            containerStyle: inputStyle,
            placeholder: translate('Search Orgs...'),
          }}
        />
      </ReferenceInput>

      <CustomField component={NumberInput} name="weighting" style={styles.inputWidth} source="weighting" {...props} />

      <CustomField
        component={ReferenceInput}
        source="observer"
        name="observer"
        {...props}
        reference="roles"
        allowEmpty
        style={{}}
      >
        <SelectInput sortChoices={true} optionText="display" optionValue="url" />
      </CustomField>
    </div>
  )
}

const LeadRecipientTitle = ({ record, style }) => {
  const translate = useTranslate()
  return (
    <span style={style}>
      {translate('Lead Recipient')} {record ? `"${record.id}"` : ''}
    </span>
  )
}

export const LeadRecipientEdit = (props) => (
  <Edit title={<LeadRecipientTitle />} {...props}>
    <SimpleForm redirect={false}>
      <Inputs {...props} />
    </SimpleForm>
  </Edit>
)

export const LeadRecipientCreate = (props) => (
  <Create {...props}>
    <SimpleForm validate={validateCreateLeadRecipient} defaultValue={{ weighting: 100 }}>
      <Inputs {...props} />
    </SimpleForm>
  </Create>
)

import { styled } from 'opensolar-ui'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import React, { useMemo } from 'react'
import { useField } from 'react-final-form'
import { getReadableLabel } from 'util/misc'
import { ENA_STATIC_DATA, SectionId } from './consts'

interface SectionSummaryContentProps {
  sectionId: SectionId
}

export const SummaryContent = styled('div')(({ theme }) => ({
  '& section': {
    border: '1px solid blue',
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 8,
      border: '1px solid red',
      paddingLeft: 10,
    },
  },
}))

export const StyledSummaryRow = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& > *:first-child': {
    fontWeight: 500,
    color: theme.palette.grey[900],
    fontSize: 14,
  },
  '& > *:last-child': {
    color: theme.palette.grey[800],
    fontSize: 12,
  },
}))

export const StyledSection = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const StyledSectionGap = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
}))

const SummaryRow: React.FC<any> = ({ label, content }) => {
  const readableLabel = useMemo(() => {
    const formatedFilesLabel = label === 'path' ? 'fileUrl' : label
    return formatedFilesLabel && getReadableLabel(formatedFilesLabel)
  }, [label])

  if (label === 'undefined') return null
  return (
    <StyledSummaryRow>
      <div>{readableLabel}</div>
      <div>{content}</div>
    </StyledSummaryRow>
  )
}

const Subsection: React.FC<any> = ({ sectionId, content }) => {
  if (typeof content === 'string' || typeof content === 'number' || typeof content === 'boolean') {
    return <SummaryRow label={sectionId} content={String(content)} />
  }

  if (Array.isArray(content)) {
    return (
      <StyledSectionGap>
        {content.map((item, index) => (
          <Subsection key={index} sectionId={sectionId} content={item} />
        ))}
      </StyledSectionGap>
    )
  }

  if (typeof content === 'object' && content !== null) {
    return (
      <StyledSection>
        {Object.entries(content).map(([key, value]) => (
          <Subsection key={key} sectionId={key} content={value} />
        ))}
      </StyledSection>
    )
  }

  return null
}

export const SummaryWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  width: '100%',
  paddingLeft: 10,
  paddingRight: 10,
}))

export const SectionSummaryContent: React.FunctionComponent<SectionSummaryContentProps> = ({ sectionId }) => {
  const {
    input: { value: sectionValues },
  } = useField(sectionId, { subscription: { value: true, dirty: true, touched: true } })

  return (
    <SummaryWrapper>
      <Subsection content={sectionValues} sectionId={sectionId} />
    </SummaryWrapper>
  )
}

export const ApplicationEnd: React.FunctionComponent = () => {
  return (
    <>
      {ENA_STATIC_DATA.slice(1, 7).map((stepData) => {
        return (
          <AccordionCard
            defaultExpanded={true}
            name={stepData.sectionId}
            title={stepData.title}
            content={SectionSummaryContent}
            contentProps={{
              sectionId: stepData.sectionId,
            }}
          />
        )
      })}
    </>
  )
}

import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreOutlined'
import WithPermissionsCheck from 'elements/WithPermissionsCheck'
import { useUxVersion } from 'hooks/useUxVersion'
import React, { useEffect } from 'react'
import SystemProgressBar from '../../SystemProgressBar'
import AddPanelButton from './AddPanelButton'
import SystemOverview from './SystemOverview'
import ViewAllSystemsButton from './ViewAllSystemsButton'

const useStyles = makeStyles((theme) => ({
  content: {
    alignItems: 'center',
  },
  headingTitle: {
    margin: '0 10px',
  },
  accordion: {
    position: 'relative',
    width: 330,
  },
  expandIcon: {
    margin: 0,
    padding: 0,
  },
  expanded: {},
  focused: {},
  summary: {
    padding: '0px 16px 0px 16px',
    minHeight: 65,
    width: 330,
    boxSizing: 'border-box',
    '&$expanded': {
      minHeight: 65,
    },
    '&$focused': {
      background: 'rgb(255,255,255)',
    },
  },
  summaryWhenDragIconVisible: {
    padding: '0px 16px 0px 30px',
    minHeight: 65,
    '&$expanded': {
      minHeight: 65,
    },
    '&$focused': {
      background: 'rgb(255,255,255)',
    },
  },
  summaryContent: {
    margin: 'auto 0',
    '&$expanded': {
      margin: 'auto 0',
    },
  },
}))

const SystemAccordion = ({
  system,
  content,
  expandedSystem,
  handleChange,
  setEditingSystemUuid,
  editingSystemUuid,
  systemsLoadingUuids,
  systemCalculatingUuids,
  isSelectedSystem,
}) => {
  const classes = useStyles()
  const isEditing = editingSystemUuid === system.uuid
  const isLoading = systemsLoadingUuids.indexOf(system.uuid) !== -1
  const isCalculating = systemCalculatingUuids.indexOf(system.uuid) !== -1
  const expanded = Boolean(expandedSystem === system.uuid) && isSelectedSystem
  return (
    <div id={expanded ? 'system-accordion-expanded' : `system-accordion-expanded-${system.uuid}`}>
      <Accordion
        style={{
          position: 'relative',
          borderRadius: 4,
          zIndex: 1000,
          margin: '0 10px',
          height: 'fit-content',
          background: isSelectedSystem ? 'rgb(255,255,255)' : '#E1E1E1',
        }}
        key={system.uuid}
        TransitionProps={{
          //disable animation
          timeout: 0,
        }}
        expanded={expanded}
        classes={{ root: classes.accordion, expanded: classes.expanded }}
        onChange={handleChange}
      >
        <>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={'expansion-content-system'}
            classes={{
              root: classes.summary,
              content: classes.summaryContent,
              expanded: classes.expanded,
              focused: classes.focused,
            }}
          >
            <SystemProgressBar
              isLoading={isLoading}
              isCalculating={isCalculating}
              style={{ position: 'absolute', width: '100%', left: 0, top: 0 }}
            />
            <SystemOverview
              system={system}
              isEditing={isEditing}
              isLoading={isLoading}
              isSelectedSystem={isSelectedSystem}
              setEditingSystemUuid={setEditingSystemUuid}
              hasShadingCalcsAwaitingTrigger={window.ShadeHelper.systemUuidsAwaitingTrigger().includes(system?.uuid)}
            />
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>{expanded && React.cloneElement(content)}</AccordionDetails>
        </>
      </Accordion>
    </div>
  )
}

const SystemAccordionMobile = (props) => {
  const { systems, handleChange, setShowAllSystemsInMobileLayout, expanded, ...rest } = props
  const uxVersion3 = useUxVersion() === 3
  useEffect(() => {
    const expandedAccordionDom = document.getElementById('system-accordion-expanded')
    if (expandedAccordionDom) {
      expandedAccordionDom.scrollIntoView({ inline: 'center' })
    }
  }, [expanded])
  const handleShowAllSystem = () => {
    if (expanded) {
      const toggleExpand = handleChange(expanded)
      toggleExpand(null, false)
    }
    setShowAllSystemsInMobileLayout(true)
  }
  return (
    <div
      style={{
        position: 'absolute',
        top: uxVersion3 ? 60 : 10,
        left: 0,
        right: 0,
        width: '100%',
      }}
    >
      <div
        style={{
          width: '100%',
          overflow: !!expanded ? 'hidden' : 'auto',
          maxHeight: 'calc(100vh - 120px)',
          display: 'flex',
          padding: '10px 20px',
          boxSizing: 'border-box',
        }}
      >
        {systems.map((system, index) => {
          const isSelectedSystem = window.editor.selectedSystem?.uuid === system.uuid
          return (
            <SystemAccordion
              key={system.uuid}
              system={system}
              systemsLength={systems.length}
              isSelectedSystem={isSelectedSystem}
              handleChange={handleChange(system.uuid)}
              expandedSystem={expanded}
              {...rest}
            />
          )
        })}
      </div>
      {!expanded && <ViewAllSystemsButton handleShowAllSystem={handleShowAllSystem} />}
      <WithPermissionsCheck
        permissionToCheck="design"
        permissions={['allowEdit']}
        disabledProp="disabled"
        disabledTooltip="No permission to edit design."
      >
        {!expanded && <AddPanelButton />}
      </WithPermissionsCheck>
    </div>
  )
}

export default SystemAccordionMobile

import { orgSelectors } from 'ducks/orgs'
import { useNotify } from 'ra-core'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'

const restClientInstance = restClient(window.API_ROOT + '/api')

const useFetchComponentsData = () => {
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const orgId = useSelector(orgSelectors.getOrg)?.id || 0
  const notify = useNotify()

  const fetchComponentsData = ({ codes, onReady }: { codes: string[]; onReady: (data: any) => void }) => {
    if (!codes.length) return {}
    setIsFetching(true)
    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${orgId}/componentsv2/?codes=${codes.join(',')}&limit=${codes.length}`,
      params: {
        codes: codes.join(','),
      },
    })
      .then((response) => {
        if (response?.data) {
          onReady(response.data)
        }
      })
      .catch((err: any) => {
        notify('There was an error when fetching component data.')
        console.error(err)
        console.log(err, err)
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  return { fetchComponentsData, isFetching }
}

export default useFetchComponentsData

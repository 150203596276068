import { Card, makeStyles } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import { useHistory } from 'react-router'
import AuthModalWrapper from './AuthModalWrapper'

const useStyles = makeStyles((theme: any) => ({
  card: {
    width: 308,
    minWidth: 210,
    padding: '0.5em 1em 1em 1em',
  },
  button: {
    color: theme.headerFontColor,
    background: theme.themeColor,
    '&:hover': {
      cursor: 'pointer',
      background: theme.themeColorDark,
    },
  },
}))

const PasswordResetComplete = () => {
  const translate = useTranslate()
  const classes = useStyles()
  const history = useHistory()
  return (
    <AuthModalWrapper>
      <Card elevation={0} classes={{ root: classes.card }}>
        <h1>{translate('Password Reset Complete')}</h1>
        <p>{translate('Your password has been set. You may go ahead and log in now.')}</p>

        <div>
          <a
            href="#"
            style={{
              color: '#4D4D4D',
              whiteSpace: 'nowrap',
            }}
            onClick={(e) => {
              history.push('/login')
              e.preventDefault()
            }}
          >
            {translate('Sign in')}
          </a>
        </div>
      </Card>
    </AuthModalWrapper>
  )
}
export default PasswordResetComplete

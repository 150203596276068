import { useCallback } from 'react'
import { useDataProvider } from 'react-admin'

type RemoveProjectForecastArgs = { projectId: number }

const useRemoveProjectForecast = () => {
  const dataProvider = useDataProvider()
  const removeProject = useCallback(async ({ projectId }: RemoveProjectForecastArgs) => {
    await dataProvider.CUSTOM_POST(`inventory/projects/${projectId}/remove/`, {
      url: `inventory/projects/${projectId}/remove/`,
      data: { removed: true },
    })
  }, [])

  return {
    removeProject,
  }
}

export default useRemoveProjectForecast

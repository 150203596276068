import { useTranslate } from 'ra-core'
import { useCallback, useEffect, useState } from 'react'
import { useStudioSignalsLazy } from 'Studio/signals/useStudioSignalsLazy'
import { StudioSystemType } from 'types/global'

export const useSystemName = (system: StudioSystemType | undefined) => {
  const studioNameState = useState('')
  const translate = useTranslate()

  const updateName = useCallback((system: StudioSystemType | undefined) => {
    studioNameState[1](getSystemName(system, translate))
  }, [])

  const checkName = useCallback(
    (object) => {
      if (object !== system && object.parent !== system) {
        return
      }
      updateName(system)
    },
    [system]
  )

  useStudioSignalsLazy(checkName, ['objectChanged'], undefined, { trackHandler: true })

  useEffect(() => updateName(system), [system])

  return studioNameState[0]
}

export const getSystemName = (sys, translate) => {
  if (!sys) return ''
  else if (sys.name?.length) return sys.name
  return `${typeof sys.moduleQuantity === 'function' ? sys.moduleQuantity() : sys?.module_quantity} ${translate(
    'Panels System'
  )}`
}

import { useNewProjectsList } from 'hooks/useNewProjectsList'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import { memo } from 'react'
import { useTranslate } from 'react-admin'
import { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  container: {
    fontSize: theme.typography.subtitle2.fontSize,
  },
}))

type ProjectNamePropsType = {
  business_name?: string
  address?: string
  id?: number
}

const ProjectName = memo(({ business_name, address, id }: ProjectNamePropsType) => {
  const translate = useTranslate()
  const ui3Enabled = useNewProjectsList()
  const classes = useStyles()

  const addressResolved = address || (id ? `${translate('Project')}: ${id}` : translate('(Address not specified)'))

  return (
    <div className={classes.container}>
      {ui3Enabled ? (
        <>
          {addressResolved}
          <br /> <span style={{ color: COLOR_PALETTE.darkGrey }}>{business_name != null && business_name}</span>
        </>
      ) : (
        <>
          {business_name != null && business_name + ', '}
          {addressResolved}
        </>
      )}
    </div>
  )
})

export default ProjectName

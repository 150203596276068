// @ts-nocheck
import { CircularProgress } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { AutocompleteInput, TextInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import restClient from 'restClient'
const restClientInstance = restClient(window.API_ROOT + '/api')

const DEFAULT_DEBOUNCE = 300
const DEFAULT_SUGGESTION_LIMIT = 10

type ChoiceType = {
  id: string
  name: string
}

type ResponseType = {
  data: {
    choices: {
      [recordId: number]: ChoiceType
    }
  }
}

type PropTypes = {
  queryURL: String
  source: string
  input: {
    name: string
    value: string
  }
  meta: {
    active: boolean
    data: any
    dirty: boolean
    dirtySinceLastSubmit: boolean
    invalid: boolean
    modified: boolean
    modifiedSinceLastSubmit: boolean
    pristine: boolean
    submitFailed: boolean
    submitSucceeded: boolean
    submitting: boolean
    touched: boolean
    valid: boolean
    validating: boolean
    visited: boolean
  }
  label: string
  fullWidth?: boolean
  suggestionLimit?: number
  clearAlwayVisible?: boolean
  debounceMs?: number
  dependentQueryFieldName?: string
}

const DebouncedAutoComplete: React.FunctionComponent<PropTypes> = (props) => {
  const formState = useFormState()
  const form = useForm()
  const dependentValue = props.dependentQueryFieldName ? formState.values[props.dependentQueryFieldName] : undefined
  const [choices, setChoices] = useState<ChoiceType[]>([{ id: 'test', name: 'Test' }])
  const [disabled, setDisabled] = useState<boolean>(!!props.dependentQueryFieldName && !dependentValue)
  const [loading, setLoading] = useState<boolean>(false)
  const [prevDependentValue, setPrevDependentValue] = useState<any>(dependentValue)
  const [hasQueriedAtLeastOnce, setHasQueriedAtLeastOnce] = useState<boolean>(false)

  //   handle first query for independent
  useEffect(() => {
    if (!props.dependentQueryFieldName) {
      let initialValue = props.input.value ? props.input.value : formState.initialValues[props.input?.name]
      getChoices(undefined, initialValue)
    }
  }, [props.dependentQueryFieldName])

  //    handle first query for dependent
  useEffect(() => {
    //   once depedent value is populated go fetch choices
    if (props.dependentQueryFieldName && !!dependentValue) {
      if (!hasQueriedAtLeastOnce) {
        let initialValue = props.input.value ? props.input.value : formState.initialValues[props.input?.name]
        getChoices(undefined, initialValue)
      } else {
        //   when dependent field changes, clear this value so we don't get in an invalid state
        if (dependentValue !== prevDependentValue) form.change(props.input.name, undefined)
        getChoices()
      }
    }
  }, [props.dependentQueryFieldName, dependentValue, hasQueriedAtLeastOnce, prevDependentValue])

  useEffect(() => {
    if (dependentValue !== prevDependentValue) setPrevDependentValue(dependentValue)
  }, [dependentValue])

  const getChoices = (searchText: string | undefined = undefined, recordId?: any) => {
    let url = `${props.queryURL}?search=${searchText}&record_id=${recordId}`
    if (props.dependentQueryFieldName) {
      if (dependentValue) {
        url += '&dependent_field_value=' + dependentValue
      } else {
        setChoices([])
        return
      }
    }
    setLoading(true)
    if (!hasQueriedAtLeastOnce) setHasQueriedAtLeastOnce(true)
    restClientInstance('CUSTOM_GET', 'custom', {
      url: url,
    })
      .then((response: ResponseType) => {
        if (response?.data?.choices) {
          if (recordId && Object.values(response.data.choices)?.length === 1) {
            form.change(props.input.name, Object.values(response.data.choices)[0].id)
          }
          if (disabled) setDisabled(false)
          setChoices(Object.values(response.data.choices))
        }
      })
      .catch((error: any) => {
        console.log('error', error)
      })
      .finally(() => setLoading(false))
  }

  const filterChoices = (searchText: string) => {
    getChoices(searchText)
  }

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      {disabled ? (
        <>
          <TextInput {...props} disabled={true} source={props.source} fullWidth={true} />
        </>
      ) : (
        <>
          <AutocompleteInput
            {...props}
            source={props.source}
            choices={choices}
            fullWidth={true}
            resettable
            clearAlwaysVisible
            required={true}
            setFilter={filterChoices}
            debounce={props.debounceMs || DEFAULT_DEBOUNCE}
          />
          {loading && (
            <div style={{ position: 'absolute', right: '10px', top: 'calc(50%)' }}>
              <CircularProgress style={{ color: 'gray' }} size={16} />
            </div>
          )}
        </>
      )}
    </div>
  )
}
export default DebouncedAutoComplete

export type IronRidgeAccountRequestType = {
  ironridge_email: string
  accepted_terms: boolean
}

export type IronRidgeAccountResponseType = {
  data: {
    email: string
    api_permissions_granted: boolean
    user_found: boolean
  }
}

export type IronRidgeRefreshRequestType = {
  ironridge_project_ids: string[]
}

export type IronRidgeRefreshResponseType = {
  saved_project: any
}

export enum IronRidgeAsceCodeEnum {
  seven10 = '7-10',
  seven16 = '7-16',
}

export enum IronRidgeRoofShapeType {
  hip = 'hip',
  gable = 'gable',
}

export type IronRidgeProjectRequestType = {
  project: {
    id?: number
    name: string
    user_email: string
    ironridge_project_id?: number
    asce: IronRidgeAsceCodeEnum
    discount?: number
    street: string
    city: string
    state: string
    zip: string
    country: string
    non_usa_postal_code: string
    project_site_attributes: IronRidgeProjectSiteEnum
    roof_sections_attributes: IronRidgeRoofSectionType[]
    detail_attributes: IronRidgeProjectDetailsType
    camo: boolean
    panel_finish: 'clear' | 'black'
    risk_category: 'I' | 'II' | 'III'
    has_multiple_slopes: boolean
    rail_platform?: string
  }
  module: {
    opensolar_id?: number
    ironridge_id?: number
    ironridge_manufacturer_id?: string
  }
  accepted_terms?: boolean
}

export type IronRidgeProjectDetailsType = {
  roof_material: IronRidgeRoofMaterialType
  roof_attachment: IronRidgeRoofAttachmentType
  attachment_hardware: IronRidgeAttachmentHardwareType
  building_height: number
  roof_shape?: IronRidgeRoofShapeType
  rail_type: IronRidgeRailType
  modules_exposed?: boolean
  modules_edge?: boolean
  roof_slope?: number
  rail_length_14?: 1 | 0 | boolean
  rail_length_17?: 1 | 0 | boolean
  available_lengths?: number[]
  deck_thickness?: string
}

export enum IronRidgeRoofMaterialType {
  cs = 'cs',
  tile_f = 'tile_f',
  tile_s = 'tile_s',
  tile_w = 'tile_w',
  metal = 'metal',
  other = 'other',
}

export enum IronRidgeRoofAttachmentType {
  ff2 = 'ff2',
  fv = 'fv',
  lfoot = 'lfoot',
  none = 'none',
  ath = 'ath',
  ath_f = 'ath_f',
  ko_f = 'ko_f',
}

export enum IronRidgeAttachmentHardwareType {
  square = 'square',
  tbolt = 't-bolt',
  dock = 'dock',
}

export type IronRidgeRoofSectionType = {
  portrait: boolean
  rail_type: IronRidgeRailType
  layout_attributes: IronRidgeRoofLayoutType
  modules_exposed?: boolean
  modules_edge?: boolean
  roof_slope: number
  multi_slope_project_id?: string
}

export type IronRidgeRoofLayoutType = {
  rows: number
  columns: number
  design: string
}

export enum IronRidgeRailType {
  XR_10 = 'XR_10',
  XR_100 = 'XR_100',
  XR_1000 = 'XR_1000',
  AIRE_A1 = 'A1',
  AIRE_A2 = 'A2',
}

export type IronRidgeProjectSiteEnum = {
  lat: number
  lng: number
}

export type IronRidgeProjectResponse = {}

export type IronRidgeBOMItemType = {
  partId: number
  partNumber: string
  partDescription: string
  quantity: number
  price: string
  totalPrice: string
  msrp: string
  savedPrice?: string
  cogs?: number
}

export type BomResponseType = {
  categories: BomCategoriesType
  projectId: number
  totals: BomTotalsType
}

export type BomCategoriesType = {
  [categoryName: string]: {
    lineItems: IronRidgeBOMItemType[]
  }
}

export type BomTotalsType = {
  discount: string
  dollarPerWatt: string
  msrp: string
  totalPrice: string
  totalWeight: number
}

import { JsonSchema, UISchemaElement } from '@jsonforms/core'
import { materialCells, materialRenderers } from '@jsonforms/material-renderers'
import { JsonForms } from '@jsonforms/react'
import { Menu, MenuItem, makeStyles } from '@material-ui/core'
import { BugReportOutlined } from '@material-ui/icons'
import AlertRenderer from 'customForm/formRenderers/alertRenderer'
import ArrayTabRenderer from 'customForm/formRenderers/arrayTabRenderer'
import CustomFormRenderer from 'customForm/formRenderers/customFormRenderer'
import expandableTermsRenderer from 'customForm/formRenderers/expandableTermsRenderer'
import ImageRenderer from 'customForm/formRenderers/imageRenderer'
import ReduxButtonRenderer from 'customForm/formRenderers/reduxButton'
import ZoomableImageRenderer from 'customForm/formRenderers/zoomableImageRenderer'
import { authSelectors } from 'ducks/auth'
import { IconButton } from 'opensolar-ui'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

const useStyles = makeStyles({
  debugContainer: { position: 'relative' },
  codeBlock: { width: '100%' },
  devButton: { position: 'absolute', top: 0, right: 0 },
})

export interface JsonFormsDebuggableProps {
  header?: string
  header_subtext?: string
  schema: JsonSchema
  uischema?: UISchemaElement
  data: any
  onChange(newData: any, errors: any): void
}

export const JsonFormsDebuggable = (props: JsonFormsDebuggableProps) => {
  const isStaff = useSelector(authSelectors.getIsStaff)
  const classes = useStyles()

  const [showTab, setShowTab] = useState(0)
  const [open, setOpen] = useState(false)
  const [devButton, setDevButton] = useState<HTMLElement | null>(null)

  const selectTab = useCallback((index) => {
    setShowTab(index)
    setOpen(false)
  }, [])

  return (
    <>
      {isStaff && (
        <div className={classes.debugContainer}>
          <>
            <div className={classes.devButton}>
              <IconButton onClick={() => setOpen(!open)} ref={(el) => setDevButton(el)}>
                <BugReportOutlined />
              </IconButton>
              <Menu
                id="basic-menu"
                open={open}
                onClose={() => setOpen(false)}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorEl={devButton}
              >
                <MenuItem disabled={true}>Staff Only</MenuItem>
                <MenuItem onClick={() => selectTab(0)}>Form</MenuItem>
                <MenuItem onClick={() => selectTab(1)}>Schema</MenuItem>
                <MenuItem onClick={() => selectTab(2)}>UI Schema</MenuItem>
                <MenuItem onClick={() => selectTab(3)}>Data</MenuItem>
              </Menu>
            </div>
          </>
        </div>
      )}
      {showTab === 0 && (
        <JsonForms
          schema={props.schema}
          uischema={props.uischema}
          data={props.data}
          //   ajv={ajv}
          renderers={[
            ...materialRenderers,
            ImageRenderer,
            ZoomableImageRenderer,
            AlertRenderer,
            ArrayTabRenderer,
            ReduxButtonRenderer,
            CustomFormRenderer,
            expandableTermsRenderer,
          ]}
          cells={materialCells}
          onChange={({ data, errors }) => props.onChange(data, errors)}
        />
      )}
      {showTab === 1 && (
        <div className={classes.codeBlock + ' code-block'}>{JSON.stringify(props.schema, null, '\t')}</div>
      )}
      {showTab === 2 && (
        <div className={classes.codeBlock + ' code-block'}>{JSON.stringify(props.uischema, null, '\t')}</div>
      )}
      {showTab === 3 && (
        <div className={classes.codeBlock + ' code-block'}>{JSON.stringify(props.data, null, '\t')}</div>
      )}
    </>
  )
}

import { Tooltip } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import { useTranslate } from 'ra-core'
import React from 'react'

type PropTypes = {}

const TOOLTIP_URL =
  'https://support.opensolar.com/hc/en-us/articles/5733939697679-How-Does-OpenSolar-Calculate-Utility-Bill-Savings-'

const SystemSavingsTooltip: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  return (
    <Tooltip
      title={
        <div>
          <span>
            {translate(
              'Estimated Utility Savings show how much the customer can expect to save on their utility bill from the system. Learn more at the helpcenter article here'
            )}
          </span>
          <br />
          <a href={TOOLTIP_URL} target="_blank" rel="noopener noreferrer">
            {translate('Click to learn more')}
          </a>
        </div>
      }
      style={{ cursor: 'pointer' }}
      interactive={true}
      arrow
      placement="top"
    >
      <InfoOutlined htmlColor="rgb(117, 117, 117)" fontSize="small" />
    </Tooltip>
  )
}
export default SystemSavingsTooltip

import { makeStyles } from '@material-ui/core/styles'
import Tooltip from 'elements/tooltip/Tooltip'
import { useDesignArtifacts } from 'projectSections/hooks/useDesignArtifacts'
import { useFeatureFlag } from 'util/split'
import DefaultThumbnail from './default_thumbnail.jpg'

const useStyles = makeStyles({
  screenshot: {
    width: 100,
    height: 75,
    borderRadius: 6,
    marginRight: 20,
    backgroundColor: '#ECECED',
  },
})

export const ProjectThumbnail = () => {
  const classes = useStyles()
  const images = useDesignArtifacts({ tags: ['Project Thumbnail'] })
  const image = images[0]

  const enableThumbnails = useFeatureFlag('project_thumbnails', 'on')
  if (!enableThumbnails) return null

  const data = image ? ('created_date' in image ? image.file_contents : image.file_contents_b64) : DefaultThumbnail

  return (
    <Tooltip title={image ? undefined : 'Design a system to see the system image'}>
      <img className={classes.screenshot} src={data} />
    </Tooltip>
  )
}

import type { OpenSolarThemeType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import Button from 'elements/button/Button'
import React, { useCallback } from 'react'
import { useNotify } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import type { ComponentTypes } from 'types/selectComponent'
import ForecastStatusIcon from '../../components/ForecastStatusIcon'
import type { ForecastDetailDialogController } from '../../forecast/detail/useForecastDetailDialogController'
import type { ForecastStatus, ForecastedProjectInventory } from '../../forecast/type'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  buttonHighlight: {
    backgroundColor: '#fff8cc',
  },
  label: {
    marginRight: '15px',
  },
}))

const getForecastStatus = ({
  currentSelectedForecastProjectInventory,
  componentType,
}: {
  currentSelectedForecastProjectInventory?: ForecastedProjectInventory
  componentType: ComponentTypes
}): ForecastStatus => {
  if (!currentSelectedForecastProjectInventory) return 'not_forecast'
  switch (componentType) {
    case 'module':
      return currentSelectedForecastProjectInventory.modules
    case 'battery':
      return currentSelectedForecastProjectInventory.batteries
    case 'inverter':
      return currentSelectedForecastProjectInventory.inverters
    case 'other':
      return currentSelectedForecastProjectInventory.others
  }
}

const ComponentTypeSelector = ({
  updateFilter,
  currentSelectedForecastProjectInventory,
  label,
  selected,
  componentType,
}: {
  updateFilter: ForecastDetailDialogController['setFilter']
  currentSelectedForecastProjectInventory: ForecastedProjectInventory | undefined
  label: string
  selected: boolean
  componentType: ComponentTypes
}) => {
  const classes = useStyles()
  const notify = useNotify()

  const handleComponentTypeSelected = useCallback(
    (componentType: ComponentTypes) => {
      const selectedItem = currentSelectedForecastProjectInventory?.items_required_allocated.find((item) => {
        return item.component_type === componentType
      })
      if (!selectedItem) {
        notify('No available hardware found', 'warning')
        return
      }
      updateFilter({
        code: selectedItem.code,
        componentType: selectedItem.component_type,
      })
      logAmplitudeEvent('generic_button_clicked', {
        source: selectedItem.component_type,
        context: 'inventory_forecast_component_type_selector',
      })
    },
    [currentSelectedForecastProjectInventory]
  )

  return (
    <Button
      size="small"
      disabled={!currentSelectedForecastProjectInventory}
      onClick={(e) => handleComponentTypeSelected(componentType)}
      startIcon={
        <ForecastStatusIcon status={getForecastStatus({ currentSelectedForecastProjectInventory, componentType })} />
      }
      className={selected ? classes.buttonHighlight : undefined}
    >
      {label}
    </Button>
  )
}

export default ComponentTypeSelector

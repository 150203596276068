import { MenuItem, useMediaQuery } from '@material-ui/core'
import CustomizeIcon from '@material-ui/icons/TuneOutlined'
import CustomSelectField from 'elements/field/CustomSelectField'
import { Button, IconButton } from 'opensolar-ui'
import React from 'react'
import { changeListParams, useTranslate } from 'react-admin'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { getRoleId } from 'util/misc'
export const CUSTOM_REPORT_TITLE = 'Custom Report'

const stripPaginationFromUrl = (url) => {
  if (!url) {
    return url
  }
  var after = url.replace(/[&?]*page=[0-9]+[&?]*/g, '')
  // console.log(url, after)
  return after
}

export const detectSelectedReport = function (pathname, search, recentProjects) {
  /*
    Match URL but first strip pagination because changing to another page is still using a saved report
    */
  var _reports = reports()

  for (var i = 0; i < _reports.length; i++) {
    if (stripPaginationFromUrl(pathname + search) === stripPaginationFromUrl(_reports[i].urls[0])) {
      return _reports[i]
    }

    //Bloody complication where All Projects needs two URLs
    if (_reports[i].urls[1]) {
      if (stripPaginationFromUrl(pathname + search) === stripPaginationFromUrl(_reports[i].urls[1])) {
        return _reports[i]
      }
    }
  }
  return {
    title: CUSTOM_REPORT_TITLE,
    urls: [],
    showCustomizeButton: false,
  }
}

export const ProjectListTitleAndSelector = ({ recentProjects, extraActions }) => {
  const translate = useTranslate()
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()
  const search = location.search
  var selectedReport = detectSelectedReport(location.pathname, location.search, recentProjects)
  var reportsToShow = reports(recentProjects)

  if (selectedReport.title === CUSTOM_REPORT_TITLE) {
    reportsToShow.push(selectedReport)
  }
  return (
    <div>
      <CustomSelectField
        key={search}
        style={{ float: 'left', width: 256 }}
        // style={{ flex: '1 1', width: 230 }}
        value={selectedReport.urls[0]}
        displayEmpty={true}
        onChange={(event) => {
          const payload = event.target.value
          var report = reportsToShow.filter((r) => r.urls[0] === payload)[0]

          if (report.clearFiltersOnModel) {
            dispatch(
              changeListParams(report.clearFiltersOnModel, {
                sort: null,
                order: null,
                page: 1,
                perPage: null,
                filter: {},
              })
            )
          }

          setTimeout(function () {
            history.push(report.urls[0])
          }, 1000)
        }}
      >
        {reportsToShow.map((report, index) => {
          return (
            <MenuItem key={report.title} value={report.urls[0]}>
              {translate(report.title)}
            </MenuItem>
          )
        })}
      </CustomSelectField>
      {extraActions}
    </div>
  )
}

export const ExtraActions = ({ urlOriginal, history, translate }) => {
  var customizeUrl

  //@TODO: Avoid needing this Epic Hack OMFG.
  //AOR freaks out if you pass ANY other parameters.
  //So we instead need redirect to a valid URL
  if (urlOriginal === '/projects') {
    customizeUrl = defaultUrlCustomized
  } else {
    customizeUrl = urlOriginal + (urlOriginal.indexOf('?') === -1 ? '?' : '&') + 'customize=1'
  }
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  return (
    <>
      {isMobile ? (
        <IconButton
          onClick={(e) => {
            e.preventDefault()
            history.push(customizeUrl)
          }}
          style={{ float: 'left', marginTop: 5, marginLeft: 5, minWidth: 50 }}
          // backgroundColor="#ffffff"
        >
          {<CustomizeIcon />}
        </IconButton>
      ) : (
        <Button
          onClick={(e) => {
            e.preventDefault()
            history.push(customizeUrl)
          }}
          style={{ float: 'left', marginTop: 5, marginLeft: 10 }}
          // backgroundColor="#ffffff"
        >
          <span>{translate('Set Filters')}</span>
        </Button>
      )}
    </>
  )
}

export const reports = (recentProjects) => [
  {
    title: 'All Projects',
    urls: ['/projects?filter=%7B%7D&order=DESC&page=1&perPage=20&sort=id', '/projects'],
    showCustomizeButton: true,
  },
  {
    title: 'My Starred Projects',
    urls: ['/projects?filter=%7B%22starred%22%3A1%7D&order=DESC&page=1&perPage=20&sort=id'],
    showCustomizeButton: true,
  },
  {
    title: 'My Assigned Projects',
    urls: [
      '/projects?filter=%7B%22assigned_role_id%22%3A' + getRoleId() + '%7D&order=DESC&page=1&perPage=20&sort=modified',
    ],
    showCustomizeButton: true,
  },
  {
    title: 'Recently Updated Projects',
    urls: ['/projects?filter=%7B%22status%22%3A1%7D&order=DESC&page=1&perPage=20&sort=modified'],
    showCustomizeButton: true,
  },
  {
    title: 'Recently Viewed Projects',
    urls: [
      '/projects?filter=%7B%22filter%22%3A%22' +
        (recentProjects && recentProjects.length > 0
          ? recentProjects.map((recentProject) => recentProject.id).join('%2C')
          : '-1') +
        '%22%7D&order=DESC&page=1&perPage=20&sort=id',
    ],
    showCustomizeButton: true,
  },
  {
    title: 'All Contacts',
    urls: ['/contacts?filter=%7B%7D&order=DESC&page=1&perPage=20&sort=id', '/contacts'],
    showCustomizeButton: true,
  },
  {
    title: 'Private Files',
    urls: ['/private_files', '/private_files'],
    showCustomizeButton: false,
  },
  {
    title: 'Public Files',
    urls: ['/public_files', '/public_files'],
    showCustomizeButton: false,
  },
  {
    title: 'My Assigned Activities',
    urls: [
      '/events?filter=%7B%22team_members%22%3A' +
        getRoleId() +
        '%2C%22task_status%22%3A1%7D&order=DESC&page=1&perPage=20&sort=modified_date',
      '/events',
    ],
    showCustomizeButton: false,
    clearFiltersOnModel: 'events',
  },
  // Switching between "My Calendar" and "All Calendars" does not currently fix filters
  // {
  //   title: 'My Calendar',
  //   urls: [
  //     '/calendar?filter=%7B%22team_members%22%3A' + getRoleId() + '%7D',
  //     '/calendar?filter=%7B%22team_members%22%3A' + getRoleId() + '%7D',
  //   ],
  //   showCustomizeButton: false,
  //   clearFiltersOnModel: 'events',
  // },
  // {
  //   title: 'All Calendars',
  //   urls: ['/calendar', '/calendar'],
  //   showCustomizeButton: false,
  //   clearFiltersOnModel: 'events',
  // },
  {
    title: 'Calendar',
    urls: ['/calendar', '/calendar'],
    showCustomizeButton: false,
    clearFiltersOnModel: 'events',
  },
  // {
  //     title: 'Custom Report',
  //     urls: [null],
  //     showCustomizeButton: false,
  // },
  {
    title: 'Transactions',
    urls: ['/transactions', '/transactions'],
    showCustomizeButton: true,
  },
]

//This is only required due to a bug in a AOR where loading /projects then trying to customize/
//by visiting /projects?customize causes errors, something associated with hiddenFilters
export const defaultUrl = '/projects?filter=%7B%7D&order=DESC&page=1&perPage=20&sort=id'
export const defaultUrlCustomized = '/projects?filter=%7B%7D&order=DESC&page=1&perPage=20&sort=id&customize=1'

import { PROJECT_ERRORS_CLEAR, PROJECT_SECTION_CLEAR } from 'actions/project'
import { put, takeEvery } from 'redux-saga/effects'

function* handleProjectSectionClear() {
  yield put({ type: PROJECT_ERRORS_CLEAR })
}

export function* watchProjectSectionClear() {
  //@ts-ignore
  yield takeEvery(PROJECT_SECTION_CLEAR, handleProjectSectionClear)
}

import { format_percentage_value, kw_to_watt, parse_percentage_value, watt_to_kw } from 'util/misc'
import { convertToLegacyCustomFields } from '../common'
import type { ComponentConfig } from '../types'

export const batteryComponentDataConfigs: ComponentConfig[] = [
  {
    source: 'code',
    label: 'Code',
    component: 'String',
  },
  {
    source: 'manufacturer_name',
    label: 'Manufacturer',
    component: 'AutocompleteInput',
    referenceInputParams: {
      label: 'Manufacturer',
      reference: 'manufacturers',
      allowEmpty: true,
      ignoreWarnings: ['associatedReference'],
    },
    extra: {
      freeSolo: true,
      fullWidth: true,
      optionValue: 'name',
    },
  },
  {
    source: 'kwh_optimal',
    label: 'Total Energy',
    component: 'Number',
    unit: 'kWh',
  },
  {
    source: 'end_of_life_capacity',
    label: 'End of Life Capacity',
    component: 'Number',
    unit: '0-1',
  },
  {
    source: 'power_max_continuous',
    label: 'Max Continuous Power',
    component: 'Number',
    unit: 'Watts',
    formatter: { parse: watt_to_kw, format: kw_to_watt },
  },
  {
    source: 'depth_of_discharge_factor',
    label: 'Depth of Discharge',
    component: 'Number',
    unit: '0-1',
  },
  {
    source: 'warranty_kwh_1_cycle_per_day',
    label: 'Total Warranted kWh',
    component: 'Number',
    unit: '1 cycle/day',
  },
  {
    source: 'efficiency_factor',
    label: 'Roundtrip Efficiency',
    component: 'Number',
    unit: '%',
    formatter: { parse: parse_percentage_value, format: (val) => format_percentage_value(val) },
  },
  {
    source: 'voltage',
    label: 'Nominal Voltage',
    component: 'Number',
    unit: 'V',
  },
  {
    source: 'product_warranty',
    label: 'Product Warranty',
    component: 'Number',
    unit: 'years',
  },
  {
    source: 'skus',
    label: 'SKUs',
    component: 'String',
    unit: 'csv',
  },
]

const osSpecificBatteryDataConfigs: ComponentConfig[] = [
  {
    source: 'aging_factor',
    label: 'Aging Factor',
    component: 'Number',
    unit: '0-1',
  },
]

export const customBatteryDataConfigs: ComponentConfig[] = [
  ...batteryComponentDataConfigs,
  ...osSpecificBatteryDataConfigs,
]

// TODO: to clean up the usage of customBatteryDataFields and remove this method
export const customBatteryDataFields = convertToLegacyCustomFields(customBatteryDataConfigs)

export const batteryDataFields = customBatteryDataFields.filter((item) => item.source !== 'manufacturer_name')

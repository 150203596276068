import { HelpLink } from 'elements/link/HelpLink'
import React from 'react'
import { useTranslate } from 'react-admin'
import { PaymentOptionCreate, PaymentOptionEdit, PaymentOptionList } from './PaymentOptions'
import FinancialIntegration from './financialIntegrations/FinancialIntegration'

const PaymentOptionTitle = ({ record, translate, style }) => {
  return (
    <span style={style}>
      {translate('Payment Option')} {record && record.title ? `"${record.title}"` : ''}
    </span>
  )
}

export const Subtitle = () => {
  const translate = useTranslate()
  return (
    <div>
      {translate(
        'Build out all of your financing and payment options here. Most users have a cash option, and at least one loan option. You can auto-apply pricing options to all proposals (a single proposal can present the customer with multiple payment options), or individually select payment options for a given proposal in the Design page.'
      )}
      <HelpLink href="https://support.opensolar.com/hc/en-us/articles/4407123994649" />
      <FinancialIntegration />
    </div>
  )
}

export default {
  list: PaymentOptionList,
  create: PaymentOptionCreate,
  edit: PaymentOptionEdit,
  options: {
    create: {
      title: <PaymentOptionTitle />,
      subtitle: null,
      breadCrumb: 'Create Payment Option',
    },
    list: {
      title: 'My Payment Options List',
      subtitle: <Subtitle />,
      breadCrumb: 'Payment Options',
    },
    edit: {
      title: <PaymentOptionTitle />,
      subtitle: null,
      breadCrumb: 'Edit Payment Option',
    },
  },
}

import ArrowUp from '@material-ui/icons/ExpandLessOutlined'
import ArrowDown from '@material-ui/icons/ExpandMoreOutlined'
import React, { useMemo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  iconStyle: {
    verticalAlign: 'middle',
    position: 'unset',
    order: 1,
    height: 24,
    width: 24,
    margin: '0 0 0 auto',
  },
}))
const FoldArrows = ({ open, handleClick }) => {
  const classes = useStyles()
  const ArrowIcon = useMemo(() => (open ? ArrowUp : ArrowDown), [open])
  return <ArrowIcon className={classes.iconStyle} onClick={handleClick} />
}

export default FoldArrows

import { Theme, makeStyles, useMediaQuery } from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'
import { OpenSolarThemeType } from 'Themes'
import { PaymentRequestStatusType } from 'pages/cashFlow/types'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'

type PropTypes = {
  orgName?: string
  contact?: string
  initialPaymentRequestStatus: PaymentRequestStatusType | undefined
}

const useStyles = makeStyles<OpenSolarThemeType, { isMobile: boolean }>((theme) => ({
  pagePadding: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    paddingTop: '100px',
  },
  container: {
    border: '1px solid #CDCDCD',
    borderRadius: '10px',
    padding: '0 !important',
    maxWidth: '500px',
  },

  topContainer: {
    textAlign: 'center',
  },

  icon: {
    color: '#019939',
    fontSize: '4rem',
    paddingTop: '2rem',
  },

  header: {
    fontSize: '20px',
    fontWeight: 500,
    margin: 0,
    padding: '1rem 0 2rem',
    borderBottom: '1px solid #CDCDCD',
  },

  bottomContainer: {
    margin: '1rem',
    lineHeight: '1.5',
  },

  bottomHeader: {
    fontSize: '18px',
    fontWeight: 500,
  },
}))

const PaymentAlreadyComplete: React.FC<PropTypes> = ({ contact, orgName, initialPaymentRequestStatus }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const classes = useStyles({ isMobile })
  const translate = useTranslate()

  const isPaid = useMemo(() => {
    const paidStatusValues: PaymentRequestStatusType[] = ['paid in full', 'pending', 'partially paid']
    if (initialPaymentRequestStatus && paidStatusValues?.includes(initialPaymentRequestStatus)) {
      return true
    }
    return false
  }, [initialPaymentRequestStatus])

  const title = useMemo(() => {
    if (isPaid) return translate('Payment Complete')
    else return translate('Payment Unavailable')
  }, [isPaid])

  const message = useMemo(() => {
    if (isPaid)
      return translate('This payment has already been made. Please check your email for a confirmation of payment.')
    else
      return translate(
        'This payment request has either been cancelled or is no longer available. Please contact your sales representative for more information.'
      )
  }, [isPaid])

  return (
    <div className={classes.pagePadding}>
      <div className={classes.container}>
        <div className={classes.topContainer}>
          {isPaid && <CheckCircle className={classes.icon} />}
          <h1 className={classes.header}>{title}</h1>
        </div>

        <div className={classes.bottomContainer}>
          {isPaid && <h2 className={classes.bottomHeader}>{translate('Thank you %{contact}!', { contact })}</h2>}
          <p>{message}</p>
          <p>{translate('- %{orgName} Team', { orgName })}</p>
        </div>
      </div>
    </div>
  )
}
export default PaymentAlreadyComplete

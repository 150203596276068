import { Grid, Link, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined'
import EditIcon from '@material-ui/icons/EditOutlined'
import GetAppIcon from '@material-ui/icons/GetAppOutlined'
import { orgSelectors } from 'ducks/orgs'
import DeleteButton from 'elements/button/DeleteButton'
import CustomField from 'elements/field/CustomField'
import ChipsInput from 'elements/input/ChipsInput'
import TinyBox from 'elements/TinyBox'
import WithPermissionsCheck from 'elements/WithPermissionsCheck'
import { Chip, IconButton } from 'opensolar-ui'
import CRUDRecordDialog from 'projectSections/sections/common/CRUDRecordDialog'
import React, { useState } from 'react'
import { ReferenceArrayInput, TextInput, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { DownloadIconLink } from 'resources/privateFiles/PrivateFiles'
import { PrivateFileType } from 'types/privateFile'
import { formatTimeStringBaseOnLocale } from 'util/misc'

const useStyles = makeStyles((theme) => ({
  row: {
    margin: theme.spacing(1),
  },
  tag: {
    marginBottom: theme.spacing(0.5),
    maxWidth: '100%',
  },
}))

type PropTypes = {
  file: PrivateFileType
  recordUrl: string
}

const FileRow: React.FunctionComponent<PropTypes> = (props) => {
  const classes = useStyles()
  const [deleted, setDeleted] = useState<boolean>(false)
  const form = useForm()
  const translate = useTranslate()
  const [currentFile, setCurrentFile] = useState(props.file)

  if (deleted) return null

  const updateFormOnDelete = (fileToDelete) => {
    const newFiles = form
      .getState()
      .values.private_files_data?.filter((file: PrivateFileType) => file.id !== fileToDelete.id)
    form.getState().initialValues.private_files_data = newFiles
    form.change('private_files_data', newFiles)
    setDeleted(true)
  }

  const updateFormOnEdit = (responseData) => {
    const initialValues = form.getState().initialValues.private_files_data
    const index = initialValues.findIndex((file: PrivateFileType) => file.id === responseData.id)
    initialValues[index] = responseData
    form.change('private_files_data', initialValues)
    setCurrentFile(initialValues[index])
  }

  return (
    <TinyBox
      title={currentFile.title}
      subtitle={formatTimeStringBaseOnLocale(currentFile.created_date)}
      className={classes.row}
      actions={
        <FileRowActions
          file={currentFile}
          recordUrl={props.recordUrl}
          onDelete={updateFormOnDelete}
          updateFormOnEdit={updateFormOnEdit}
        />
      }
    >
      {currentFile.file_tags_data.map((file_tag, i) => (
        <Tooltip title={file_tag.title}>
          <Chip key={i} className={classes.tag} size="small" label={translate(file_tag.title)} />
        </Tooltip>
      ))}
    </TinyBox>
  )
}

type FileRowActionsProps = {
  file: PrivateFileType
  recordUrl: string
  onDelete: (file) => void
  updateFormOnEdit: (file) => void
}

const PrivateFileFields = (props) => {
  const classes = useStyles()
  return (
    <>
      <Grid container alignItems="center" spacing={2} className={classes.row}>
        <Grid item sm={11}>
          <TextInput fullWidth source="title" helperText="optional" record={props.record} />
        </Grid>
        <Grid item sm={1}>
          <DownloadIconLink record={props.record} />
        </Grid>
        <Grid item sm={12}>
          <CustomField
            name="file_tags"
            source="file_tags"
            component={ReferenceArrayInput}
            reference="file_tags"
            resource="file_tags"
            optionValueField="url"
            fullWidth
            style={{ width: '100%' }}
            filterToQuery={(searchText) => ({ search: searchText, type: 'private' })}
          >
            <ChipsInput
              source="file_tags"
              optionText="title"
              optionValue="url"
              translateChoice={false}
              options={{ style: {} }}
            />
          </CustomField>
        </Grid>
      </Grid>
    </>
  )
}

const FileRowActions = ({ file, recordUrl, onDelete, updateFormOnEdit }: FileRowActionsProps) => {
  const translate = useTranslate()
  const orgUrl = useSelector(orgSelectors.getOrgUrl)
  const isFileOwner = orgUrl === file?.org
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false)
  return (
    <>
      <Tooltip title={translate('Download File')}>
        <Link href={file.file_contents} target="_blank" rel="noopener noreferrer">
          <IconButton>
            <GetAppIcon htmlColor="rgba(0,0,0,0.54)" />
          </IconButton>
        </Link>
      </Tooltip>
      {isFileOwner && (
        <>
          <WithPermissionsCheck permissionToCheck="info_documents" permissions={['allowEdit']}>
            <Tooltip title={translate('Edit File')}>
              <IconButton
                onClick={() => {
                  setOpenEditDialog(true)
                }}
              >
                <EditIcon htmlColor="rgba(0,0,0,0.54)" />
              </IconButton>
            </Tooltip>
            <CRUDRecordDialog
              isOpen={openEditDialog}
              record={file}
              resource="private_files"
              basePath="/private_files"
              onDismiss={() => setOpenEditDialog(false)}
              formContent={<PrivateFileFields />}
              updateFormValsFn={updateFormOnEdit}
              notificationOnSuccess={true}
              customSuccessNotificationText={translate('Your File has been saved')}
              dialogTitle={translate('Edit Project File')}
              removeRecordFromForm={onDelete}
              width="sm"
            />
          </WithPermissionsCheck>
          <WithPermissionsCheck permissionToCheck="info_documents" permissions={['allowDelete']}>
            <DeleteButton
              size="small"
              record={file}
              startIcon={<DeleteIcon htmlColor="rgba(0,0,0,0.54)" />}
              resource={'private_files'}
              hideLabel={true}
              fullWidth={true}
              onSuccess={onDelete}
              style={{ padding: '5px' }}
              title={translate('Delete File')}
              label={''}
            />
          </WithPermissionsCheck>
        </>
      )}
    </>
  )
}
export default FileRow

// @ts-nocheck
import { MenuItem } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import CustomSelectField from 'elements/field/CustomSelectField'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import appStorage from 'storage/appStorage'
import { SystemDataType } from 'types/systems'

type PropTypes = {
  updateFieldState: (fieldName: string, value: string) => void
  nonSolarProjectType: string | undefined
  system: SystemDataType
}

const SunlightNonSolarProjectType: React.FC<PropTypes> = (props) => {
  const [options, setOptions] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const orgId: number = useSelector(authSelectors.getCurrentOrg)?.id

  useEffect(() => {
    fetchOptions()
  }, [])

  const fetchOptions = () => {
    let cachedOptions = appStorage.getString('sunlight_product_type_options')

    if (!cachedOptions) {
      const API_URL = window.API_ROOT + '/api'
      const restClientInstance = restClient(API_URL)
      setLoading(true)
      restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${orgId}/sunlight/project_types/`,
      })
        .then((res) => {
          if (res?.data?.options) {
            setOptions(res.data.options)
            appStorage.setString('sunlight_product_type_options', JSON.stringify(res.data.options))
          }
        })
        .catch((err) => {})
        .finally(() => setLoading(false))
    } else {
      setOptions(JSON.parse(cachedOptions))
    }
  }

  const handleChange = (e: ChangeEvent) => {
    props.updateFieldState('nonSolarProjectType', e.target.value)
    window.editor.execute(
      new window.SetValueCommand(props.system, 'non_solar_project_type', e.target.value, undefined, true)
    )
  }

  return (
    <div>
      <CustomSelectField
        style={{ width: '100%' }}
        label="Non-Solar Project Type"
        MenuProps={{ style: { zIndex: 2500 } }}
        displayEmpty={false}
        value={props.nonSolarProjectType}
        onChange={handleChange}
      >
        <MenuItem value={undefined}>None</MenuItem>
        {options?.map((opt) => {
          return (
            <MenuItem key={opt.id} value={opt.id}>
              {opt.name}
            </MenuItem>
          )
        })}
      </CustomSelectField>
    </div>
  )
}
export default SunlightNonSolarProjectType

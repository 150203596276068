// @ts-nocheck
import { permissionsSelectors } from 'ducks/permissions'
import { buildSystemPlaceholders } from 'myenergy/mainContent/populate'
import React, { useEffect } from 'react'
import { NotFound } from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { pure } from 'recompose'
import { SystemDataType } from 'types/systems'
import useMemoizedSelector from 'util/useMemoizedSelector'
import InfoPageRenderer from './InfoPageRenderer'

type PropTypes = {}

const Info: React.FunctionComponent<PropTypes> = (props) => {
  const { allowView } = useMemoizedSelector(permissionsSelectors.getProjectPermissionByKey('project'))
  const formState = useForm().getState()

  // @ts-ignore
  const systemCalculation = useSelector((state) => state.designer.systemCalculation)
  const { processQueue } = systemCalculation

  //if user changes something in studio and does not save then the design field will be marked as dirty but the actual data will live in window
  //listen for changes to the dirty fields to see if design has changed, if so go fetch the updated data and recalculate the system summary field, update it in
  // the initial values in the form. This field is for display only but a change in it signifies a change elsewhere that will need to be saved
  // when done, mark design as clean
  useEffect(() => {
    // give things a bit to load and settle
    const dirtyDesign = formState.dirtyFields && formState.dirtyFields['design']
    const systemsData = window.editor.getSystems()
    if (dirtyDesign) {
      if (systemsData && systemsData.length > 0) {
        const systemSummaries: string[] = []
        systemsData?.forEach((system: any) => {
          systemSummaries.push(buildSystemPlaceholders(system.userData, null, system.userData?.inverters).summary)
        })
        if (formState.initialValues.systems) {
          formState.initialValues.systems.forEach((initialSystem: SystemDataType, i: number) => {
            if (systemSummaries && systemSummaries[i]) {
              initialSystem.summary = systemSummaries[i]
            }
          })
        }
      }
    }
  }, [processQueue])

  if (!allowView) {
    return <NotFound />
  }
  return <InfoPageRenderer />
}

export default pure(Info)

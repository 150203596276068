import { Dialog, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { default as Button, default as ProUXButton } from 'elements/proUXButtons/ProUXButton'
import { useTranslate } from 'ra-core'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { IntegrationActivationType } from 'types/orgs'
import { RootState } from 'types/state'
import { reloadEntireApp } from 'util/misc'

type PropTypes = {
  integrationKey: string
  lenderDisplayName: string
  field1Name: string
  field2Name?: string
  field3Name?: string
  field4Name?: string
  activation_type: IntegrationActivationType
}

const useStyles = makeStyles({
  root: {},
  checked: {},
  errorText: {
    color: 'red',
    textAlign: 'center',
    width: '100%',
  },
  buttonWrapper: {
    marginTop: '20px',
  },
})

const GenericIntegrationAccountDialog: React.FunctionComponent<PropTypes> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const [field1, setField1] = useState<string | undefined>(undefined)
  const [field2, setField2] = useState<string | undefined>(undefined)
  const [field3, setField3] = useState<string | undefined>(undefined)
  const [field4, setField4] = useState<string | undefined>(undefined)

  const isEnabled =
    useSelector((state: RootState) => orgSelectors.getGenericIntegrationStatus(state, props.integrationKey)) ===
    'active'
  const isStaff = useSelector(authSelectors.getIsStaff)

  useEffect(() => {
    if (errorMsg) {
      logAmplitudeEvent('integrated_finance_account_dialog_opened', {
        integration: props.integrationKey,
        error: errorMsg,
      })
    }
  }, [errorMsg])

  const orgId = useSelector(authSelectors.getOrgId)

  const classes = useStyles()
  const translate = useTranslate()

  const openDisableConfirm = () => {
    const confirmed = window.confirm(
      'Are you sure you want to disable this integration? All projects using this integration will break'
    )
    if (confirmed) {
      const restClientInstance = restClient(window.API_ROOT + '/api')
      restClientInstance('CUSTOM_POST', 'custom', {
        url: 'orgs/' + orgId + `/disable_integration/${props.integrationKey}/`,
        data: {
          field1,
          field2,
          field3,
        },
      })
        .then((response: any) => {
          if (response.data?.success) {
            logAmplitudeEvent('integrated_finance_account_disconnected', { integration: props.integrationKey })
            reloadEntireApp(window.location.href)
          }
        })
        .catch((error: any) => {})
    }
  }

  const save = () => {
    let isValid = false
    if (props.activation_type === 'simple') {
      isValid = true
    } else {
      if (!field1) {
        setErrorMsg(`${props.field1Name} is required`)
      } else if (props.field2Name && !field2) {
        setErrorMsg(`${props.field2Name} is required`)
      } else if (props.field3Name && !field3) {
        setErrorMsg(`${props.field3Name} is required`)
      } else if (props.field4Name && !field4) {
        setErrorMsg(`${props.field4Name} is required`)
      } else {
        isValid = true
      }
    }
    if (isValid) {
      setLoading(true)
      const restClientInstance = restClient(window.API_ROOT + '/api')
      restClientInstance('CUSTOM_POST', 'custom', {
        url: 'orgs/' + orgId + `/connect_integration/${props.integrationKey}/`,
        data: {
          field1,
          field2,
          field3,
          field4,
        },
      })
        .then((response: any) => {
          if (response.data?.success) {
            logAmplitudeEvent('integrated_finance_account_connected', { integration: props.integrationKey })
            let url = '/#/payment_options'
            if (response?.data?.products_added !== undefined)
              url += `?products_added=${response.data.products_added}&partner=${props.lenderDisplayName}`
            reloadEntireApp(url)
          } else {
            setErrorMsg('Incorrect credentials')
          }
        })
        .catch((error: any) => {
          if (error?.body?.message) {
            setErrorMsg(error.body.message)
          } else {
            setErrorMsg(`Unable to connect your ${props.lenderDisplayName} account`)
          }
          logAmplitudeEvent('integrated_finance_account_connection_error', { integration: props.integrationKey })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  if (isEnabled && !isOpen) {
    if (!isStaff) {
      return (
        <div className={classes.buttonWrapper}>
          <h2>{props.lenderDisplayName} Integration</h2>
          <p>
            {translate(
              'This integration is active on your account. If you would like to disable it please contact our support team'
            )}
          </p>
        </div>
      )
    } else {
      return (
        <div className={classes.buttonWrapper}>
          <h2>{props.lenderDisplayName} Integration</h2>
          <ProUXButton
            label={`Disable ${props.lenderDisplayName} Integration`}
            type="secondary"
            onClick={openDisableConfirm}
          />
          <p>Staff Only: If you want to change the API keys this org is using click the button below</p>
          <ProUXButton
            label={`Edit Keys for ${props.lenderDisplayName}`}
            type="secondary"
            onClick={() => setIsOpen(true)}
          />
        </div>
      )
    }
  }
  if (!isOpen) {
    return (
      <div className={classes.buttonWrapper}>
        <h2>{props.lenderDisplayName} Integration</h2>
        <ProUXButton
          label={`Enable ${props.lenderDisplayName} Integration`}
          type="primary"
          onClick={() => setIsOpen(true)}
        />
      </div>
    )
  }
  return (
    <Dialog open={isOpen} style={{ textAlign: 'center' }} onClose={() => setIsOpen(false)}>
      <DialogTitle>{translate(`Connect your ${props.lenderDisplayName} Account`)}</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          {props.activation_type === 'simple' ? (
            <Grid item xs={12}>
              <p>
                No credentials are required for this integration. Simply click the button below to activate the
                integration.
              </p>
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <p>
                  Enter your {props.lenderDisplayName} API credentials below to connect your account. If you don't have
                  these credentials you can get them by reaching out to {props.lenderDisplayName} directly
                </p>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={`${props.lenderDisplayName} ${props.field1Name}`}
                  fullWidth={true}
                  value={field1}
                  onChange={(e) => setField1(e.target.value)}
                />
              </Grid>
              {props.field2Name && (
                <Grid item xs={12}>
                  <TextField
                    label={`${props.lenderDisplayName} ${props.field2Name}`}
                    fullWidth={true}
                    value={field2}
                    onChange={(e) => setField2(e.target.value)}
                  />
                </Grid>
              )}
              {props.field3Name && (
                <Grid item xs={12}>
                  <TextField
                    label={`${props.lenderDisplayName} ${props.field3Name}`}
                    fullWidth={true}
                    value={field3}
                    onChange={(e) => setField3(e.target.value)}
                  />
                </Grid>
              )}
              {props.field4Name && (
                <Grid item xs={12}>
                  <TextField
                    label={`${props.lenderDisplayName} ${props.field4Name}`}
                    fullWidth={true}
                    value={field4}
                    onChange={(e) => setField4(e.target.value)}
                  />
                </Grid>
              )}
            </>
          )}
          {errorMsg && (
            <Grid item xs={12}>
              <p className={classes.errorText}>{errorMsg}</p>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            style={{ paddingTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
          >
            <Button label="Connect Your Account" type="primary" onClick={save} loading={loading} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
export default GenericIntegrationAccountDialog

import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { getWebSocketToken } from 'rest/getWebSocketToken'
import { RootState } from 'types/state'
import { authSelectors } from './auth'

export const CHECK_WS_CONNECTION = '@ws/CHECK_WS_CONNECTION'
export const ADD_SUSCRIBER = '@ws/ADD_SUSCRIBER'
export const REMOVE_SUSCRIBER = '@ws/REMOVE_SUSCRIBER'
export const BROADCAST_MESSAGE = '@ws/BROADCAST_MESSAGE'

// Internal
const PATCH = '@ws/PATCH'
const DISPATCH = '@ws/DISPATCH'
const ADD_SUSCRIBER_INNER = '@ws/ADD_SUSCRIBER_INNER'
const REMOVE_SUSCRIBER_INNER = '@ws/REMOVE_SUSCRIBER_INNER'

export type WsState = 'idle' | 'connecting' | 'connected' | 'disconnected'

export type WsSubscriber<T> = (data: T, container: WsContainer<T>, msg: MessageEvent) => void

export type WsReducerType = {
  available: boolean
  state: WsState
  subscribers: WsSubscriber<unknown>[]
}
const initialState: WsReducerType = {
  available: false,
  state: 'idle',
  subscribers: [],
}

export interface WsContainer<T> {
  org_id: number
  project_id: number
  user_id: number
  user_name: string
  arrived: number
  original_data: {
    session_id: number
    is_superuser: boolean
    data: T
  }
  type: 'broadcast'
}

const session_id = Math.round(Math.random() * 100000)
let ws: WebSocket | undefined
let wsOrg: number | undefined

export const wsActions = {
  checkConnection() {
    return { type: CHECK_WS_CONNECTION }
  },
  addSubscriber<T>(subscriber: WsSubscriber<T>): SubscribeType {
    return {
      type: ADD_SUSCRIBER,
      subscriber,
    }
  },
  removeSubscriber<T>(subscriber: WsSubscriber<T>): SubscribeType {
    return {
      type: REMOVE_SUSCRIBER,
      subscriber,
    }
  },
  broadcast(data: any): BroadcastMessageType {
    return {
      type: BROADCAST_MESSAGE,
      data,
    }
  },
}

interface PatchType {
  type: typeof PATCH
  patch: Partial<WsReducerType>
}
interface SubscribeType {
  type: typeof ADD_SUSCRIBER | typeof REMOVE_SUSCRIBER | typeof ADD_SUSCRIBER_INNER | typeof REMOVE_SUSCRIBER_INNER
  subscriber: WsSubscriber<any>
}
interface BroadcastMessageType {
  type: typeof BROADCAST_MESSAGE
  data: any
}
interface DispatchMessageType {
  type: typeof DISPATCH
  args: [unknown, WsContainer<unknown>, MessageEvent]
}

type WsActionsType = SubscribeType | PatchType | BroadcastMessageType | DispatchMessageType

export default function wsReducer(state = initialState, action: WsActionsType): WsReducerType {
  switch (action.type) {
    case PATCH:
      return {
        ...state,
        ...action.patch,
      }
    case DISPATCH:
      setTimeout(() => {
        // Must call out after redux has finished
        for (const sub of state.subscribers) sub.apply(null, action.args)
      }, 0)
      return state
    case ADD_SUSCRIBER_INNER:
      return {
        ...state,
        subscribers: [...state.subscribers, action.subscriber],
      }
    case REMOVE_SUSCRIBER_INNER:
      return {
        ...state,
        subscribers: state.subscribers.filter((s) => s !== action.subscriber),
      }
    default:
      return state
  }
}

export const wsSelectors = {
  getAvailable: (state: RootState) => {
    return state.websocket.available
  },
  getState: (state: RootState) => {
    return state.websocket.state
  },
  getSubscribers: (state: RootState) => {
    return state.websocket.subscribers
  },
}

export function* wsSagas() {
  yield all([takeEvery(ADD_SUSCRIBER, addRemoveSubscriber)])
  yield all([takeEvery(REMOVE_SUSCRIBER, addRemoveSubscriber)])
  yield all([takeEvery(CHECK_WS_CONNECTION, checkSocketConnection)])
  yield all([takeEvery(BROADCAST_MESSAGE, broadcastMessage)])
}

export function* addRemoveSubscriber(action: {
  type: typeof ADD_SUSCRIBER | typeof REMOVE_SUSCRIBER
  subscriber: WsSubscriber<any>
}) {
  console.log('addRemoveSubscriber: ', action.type)
  const subscribers = yield select(wsSelectors.getSubscribers)
  let doCheck = false
  if (action.type === ADD_SUSCRIBER) {
    doCheck = subscribers.length === 0
    yield put({
      type: ADD_SUSCRIBER_INNER,
      subscriber: action.subscriber,
    })
  } else if (action.type === REMOVE_SUSCRIBER) {
    doCheck = subscribers.length === 1
    yield put({
      type: REMOVE_SUSCRIBER_INNER,
      subscriber: action.subscriber,
    })
  }

  if (doCheck) yield put(wsActions.checkConnection())
}

// To get around redux async weirdness
let connecting = false

/**
 * Checks whether endpoint, org and token are available
 * Cleans up old
 */
export function* checkSocketConnection() {
  const orgId = yield select(authSelectors.getOrgId)
  const subscribers = yield select(wsSelectors.getSubscribers)
  if (window.WEBSOCKET_ENDPOINT && orgId !== undefined && subscribers.length) {
    if (wsOrg === orgId && connecting) return
    connecting = true
    wsOrg = orgId

    const token = yield getWebSocketToken(orgId)

    yield put({
      type: PATCH,
      patch: {
        available: !!token,
        state: token ? 'connecting' : 'idle',
      },
    })
    console.log('token: ', token)
    if (!token) {
      connecting = false
      yield call(cleanupWebsocket)
    } else yield call(createWebsocket, token)
  } else {
    connecting = false
    yield call(cleanupWebsocket)
    yield put({
      type: PATCH,
      patch: {
        available: false,
        state: 'idle',
      },
    })
  }
}
export function* broadcastMessage(action: BroadcastMessageType) {
  if (!ws || ws.readyState !== 1) {
    console.log('WebSocket not available to send: ', action)
    return
  }

  const projectId = yield select((state: RootState) => state.projectId)
  const is_superuser = yield select(authSelectors.getIsSuperUser)

  const msg = {
    action: 'broadcast',
    data: {
      session_id,
      is_superuser,
      data: action.data,
    },
    project_id: projectId,
  }

  console.debug('broadcast: ', msg)

  ws.send(JSON.stringify(msg))
}

function reconnect() {
  window.reduxStore.dispatch({
    type: PATCH,
    patch: {
      state: ws ? 'disconnected' : 'idle',
    },
  })
  cleanupWebsocket()
  setTimeout(() => {
    window.reduxStore.dispatch(wsActions.checkConnection())
  }, 1000)
}

function cleanupWebsocket() {
  if (!ws) return
  wsOrg = undefined
  ws.onopen = null
  ws.onmessage = null
  ws.onclose = null
  ws.onerror = null
  ws.close()
  ws = undefined
}

function createWebsocket(token: string) {
  return new Promise((resolve, reject) => {
    ws = new WebSocket(`${window.WEBSOCKET_ENDPOINT}?token=${token}`)

    ws.onopen = () => {
      console.debug('WebScoket open')
      //TODO fix
      window.reduxStore.dispatch({
        type: PATCH,
        patch: {
          state: 'connected',
        },
      })
    }

    ws.onmessage = (msg) => {
      let data
      try {
        data = JSON.parse(msg.data)
      } catch (e) {}
      if (!data) {
        console.warn('Failed to parse ws message data: ', msg)
        return
      }
      if (!data.original_data) {
        console.warn('Error from websocket server: ', data)
        return
      }
      data.arrived = Date.now()
      if (data.original_data?.session_id === session_id) {
        // Message from self, ignore
        return
      }
      console.debug('WS.msg', data)
      const user_data = data.original_data.data
      window.reduxStore.dispatch({
        type: DISPATCH,
        args: [user_data, data, msg],
      })
    }

    ws.onclose = (e: CloseEvent) => {
      console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason)
      reconnect()
    }

    ws.onerror = function (event: Event) {
      console.error('Socket encountered error: ', event, 'Reconnecting')
      reconnect()
    }
  })
}

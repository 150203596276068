import { Divider, Typography } from '@material-ui/core'
import type { OpenSolarThemeType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { clearLineItems } from 'ducks/orderComponents'
import { memo, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { OrderStateContext } from './VtacCheckoutProvider'
import { HardwareSupplierFilterKeyType } from '../../type'
import { orgSelectors } from '../../../../ducks/orgs'

const useDialogContentStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  container: {
    textAlign: 'center',
    padding: '30px 80px',
  },
  divider: {
    margin: '20px 0',
  },
  buttonWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: 50,
  },
  button: {
    margin: '10px 20px',
  },
}))

const VtacPostCheckoutPage = ({ quoteOrOrderType }: { quoteOrOrderType: 'order' | 'quote' }) => {
  const classes = useDialogContentStyles()
  const dispatch = useDispatch()
  const orgCountry = useSelector(orgSelectors.getOrg)?.country.iso2
  const distributor: HardwareSupplierFilterKeyType = orgCountry === 'GB' ? 'vtac_uk' : 'vtac_pl';

  const { orderResult } = useContext(OrderStateContext)

  useEffect(() => {
    logAmplitudeEvent('hardware_order_placed_success', {
      distributor: distributor,
      type: quoteOrOrderType,
    })
    return () => {
      // clear cart when pros leaving the postcheckout page
      dispatch(clearLineItems())
    }
  }, [])

  const title = quoteOrOrderType === 'order' ? 'Order Complete' : 'Quote Complete'
  const typeLabel = quoteOrOrderType

  return (
    <div className={classes.container}>
      <Typography variant="h3" gutterBottom>
        {title}
      </Typography>
      <Divider className={classes.divider} />
      <Typography variant="body1" gutterBottom>
        Thank you for placing your V-Tac {typeLabel}. V-Tac will be in touch soon about fulfilment.
      </Typography>

      {orderResult?.order_id && (
        <Typography variant="body1" gutterBottom>
          Order ID: {orderResult?.order_id}
        </Typography>
      )}
    </div>
  )
}

export default memo(VtacPostCheckoutPage)

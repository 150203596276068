import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import type { OpenSolarThemeType } from 'Themes'
import React, { memo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import type { BomDataType, MaterialDetailType } from './type'

const BomDetailKeyAndTitleMap: { [key in keyof MaterialDetailType]: string } = {
  category: 'Category',
  cost_unit: 'Cost/Unit',
  manufacturer: 'Manufacturer',
  notes: 'Notes',
  part_name: 'Part Name',
  part_number: 'PartNumber',
  qty: 'Qty',
  row: 'Row',
  total_cost: 'Total Cost',
}

const bomDetailKeyOrder: (keyof MaterialDetailType)[] = [
  'row',
  'part_number',
  'part_name',
  'manufacturer',
  'category',
  'qty',
  'cost_unit',
  'total_cost',
  'notes',
]

const TOTAL_COLUMNS = bomDetailKeyOrder.length

const useTableRowStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  tableRow: {
    '&:first-child': {
      fontWeight: 'bold',
      backgroundColor: theme.palette.action.hover,
    },
  },
  tableCell: {
    padding: theme.spacing(1),
    border: '1px solid',
    borderColor: theme.palette.grey[500],
    '&:first-child': {
      fontWeight: 'bold',
      backgroundColor: theme.palette.action.hover,
    },
  },
}))

const autoFillEmptyTableCellItems = (items: string[]) => {
  // Auto fill empty table cell items based on TOTAL_COLUMNS
  const finalItems = [...items]
  finalItems.length = TOTAL_COLUMNS
  finalItems.fill('', items.length)
  return finalItems
}

const BomTableRow = ({ items }: { items: string[] }) => {
  const classes = useTableRowStyles()
  const finalItems = autoFillEmptyTableCellItems(items)
  return (
    <TableRow className={classes.tableRow}>
      {finalItems.map((item: string, index: number) => {
        return (
          <TableCell key={item + index} className={classes.tableCell}>
            {item}
          </TableCell>
        )
      })}
    </TableRow>
  )
}

const MaterialDetailRows = ({ bomData }: { bomData: BomDataType }) => {
  return (
    <>
      <BomTableRow items={bomDetailKeyOrder.map((key: keyof MaterialDetailType) => BomDetailKeyAndTitleMap[key])} />
      {bomData.rows.map((row: MaterialDetailType) => {
        return <BomTableRow key={row['row']['value']} items={bomDetailKeyOrder.map((key) => row[key]['value'])} />
      })}
      <BomTableRow
        items={bomDetailKeyOrder.map((key: keyof MaterialDetailType) => {
          if (key === 'row') {
            return 'Total'
          } else if (key === 'qty') {
            return bomData.total.quantity.value
          } else if (key === 'total_cost') {
            return bomData.total.cost.value
          }
          return ''
        })}
      />
    </>
  )
}

const BomDetailTable = ({ bomData }: { bomData: BomDataType }) => {
  return (
    <Table aria-label="customized table">
      <TableBody>
        <BomTableRow items={['Bill of Materials']} />
        <BomTableRow items={[bomData.company_name.label, bomData.company_name.value]} />
        <BomTableRow items={[bomData.project_address.label, bomData.project_address.value]} />
        <BomTableRow items={[bomData.order_date.label, bomData.order_date.value]} />
        <BomTableRow items={[]} />
        <MaterialDetailRows bomData={bomData} />
      </TableBody>
    </Table>
  )
}

export default memo(BomDetailTable)

import { HelpLink } from 'elements/link/HelpLink'
import React from 'react'
import { LeadRecipientCreate, LeadRecipientEdit, LeadRecipientList } from './LeadRecipients'

const Subtitle = () => {
  return (
    <span>
      Lead Recipients redirect leads from lead capture forms to other Orgs.
      <HelpLink href="https://support.opensolar.com/hc/en-us/articles/tbc" />
    </span>
  )
}

const LeadRecipientTitle = ({ record, translate, style }) => {
  return (
    <span style={style}>
      {translate('Lead Recipient')}: {record ? `"${record.title}"` : ''}
    </span>
  )
}

export default {
  list: LeadRecipientList,
  create: LeadRecipientCreate,
  edit: LeadRecipientEdit,
  options: {
    create: {
      title: 'Create Lead Recipient',
      subtitle: null,
      breadCrumb: 'Create Lead Recipient',
    },
    list: {
      title: 'Lead Recipients',
      subtitle: <Subtitle />,
      breadCrumb: 'Lead Recipients',
    },
    edit: {
      title: <LeadRecipientTitle />,
      subtitle: null,
      breadCrumb: 'Edit Lead Recipient',
    },
  },
}

import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { getAvailableActions, hideFullScreenLoader, markCreditAppAsOpened, myEnergySelectors } from 'ducks/myEnergy'
import Alert from 'elements/Alert'
import LoadingDots from 'layout/widgets/LoadingDots'
import { removeCreditAppParams } from 'myenergy/selectionComponent/loanApplicationButton/util'
import { useNotify } from 'ra-core'
import React, { CSSProperties, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'
import { AvailableCustomerActionsType } from 'types/customerActions'
import { PaymentOptionDataType } from 'types/paymentOptions'
import { ProjectType } from 'types/projects'
import { TransactionActionButton } from '../TransactionStartButton'
import PhoenixApplicationDialog from './phoenixApplication/PhoenixApplicationDialog'

const restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  acceptButtonStyle?: CSSProperties
  onDialogOpen?: () => void
  actionData: {
    project_id: number
    system_uuid: string
    payment_option_id: number
    status_code?: string
    org_id: number
  }
  selectedProject: ProjectType
  available_customer_actions: AvailableCustomerActionsType
  myeStyles: CSSProperties
  attachedPhoenixPaymentOption: PaymentOptionDataType
  selectedPaymentOptionId: number
  paymentOptionData: PaymentOptionDataType
}

const STATUSES_TO_POLL_FOR = ['created', 'sent', 'Pending']
const MAX_POLL_COUNT = 20

type PhoenixStatusType =
  | undefined
  | 'created'
  | 'sent'
  | 'Approved'
  | 'Withdrawn'
  | 'Declined'
  | 'Pending'
  | 'No Finance Needed'

const PhoenixApplicationButton: React.FC<PropTypes> = (props) => {
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [existingURL, setExistingURL] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [currentStatus, setCurrentStatus] = useState<PhoenixStatusType>(undefined)
  const [pollCount, setPollCount] = useState<number>(0)

  const notify = useNotify()
  const dispatch = useDispatch()
  const forceOpenCreditApp = useSelector(myEnergySelectors.getShouldForceOpenCreditApp)
  const isPro = !!useSelector(authSelectors.getCurrentRole)
  const orgId = props.paymentOptionData?.org_id ? props.paymentOptionData?.org_id : props.actionData?.org_id

  useEffect(() => {
    if (forceOpenCreditApp && props.selectedPaymentOptionId === props.paymentOptionData?.id) {
      onClick()
      removeCreditAppParams()
    }
  }, [forceOpenCreditApp, props.selectedPaymentOptionId])

  useEffect(() => {
    if (props.available_customer_actions?.length > 0) {
      let phoenixAction = props.available_customer_actions[0]?.actions_available?.find(
        (act) => act.payment_method === 'phoenix_application'
      )
      let phoenixStatusTitle = phoenixAction?.status_title as PhoenixStatusType
      setCurrentStatus(phoenixStatusTitle)
    }
  }, [props.available_customer_actions])

  useEffect(() => {
    if (currentStatus && STATUSES_TO_POLL_FOR.includes(currentStatus) && pollCount < MAX_POLL_COUNT) {
      doPoll(pollCount)
    }
  }, [currentStatus, pollCount])

  useEffect(() => {
    if (showDialog) {
      if (props.onDialogOpen) props.onDialogOpen()
    }
  }, [showDialog])

  useEffect(() => {
    const payload = {
      payment_type: 'loan',
      payment_option_id: props.actionData.payment_option_id,
      project_id: props.actionData.project_id,
      integration: 'phoenix',
    }
    // no longer adding since we can't log for end customers
    //logAmplitudeEvent('payment_option_in_opened_proposal', payload)
  }, [])

  const doPoll = (currentPollCount: number) => {
    // if this is the first poll we only wait 10 seconds. Just in case they're submitted their app and are coming back to the proposal before our scheduled job updated things
    // then we wait 3 minutes before the second poll. If we didn't get an update on the first poll it means they need to either submit their app or we're waiting for a decision.
    // neither is likely to happen soon
    // after that 3 minute wait we move to 20 second increments in the hopes we can get a decision once they are done with their application
    let waitMs = 1000 * 10
    if (currentPollCount === 1) waitMs = 1000 * 60 * 3
    else waitMs = 1000 * 20

    setTimeout(() => {
      restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${orgId}/projects/${props.actionData.project_id}/phoenix/app_status/`,
      })
        .then((res) => {
          if (res.data.success) {
            if (res.data.decision === currentStatus && !STATUSES_TO_POLL_FOR.includes(res.data.decision))
              setPollCount(currentPollCount + 1)
            else {
              // decision changed, so the poll was successful and we need to refresh available actions to update the UI with the new decision
              setPollCount(MAX_POLL_COUNT + 1)
              setCurrentStatus(res.data.decision)
              setTimeout(() => {
                dispatch(getAvailableActions(orgId, props.actionData.project_id))
              }, 2000)
              logAmplitudeEvent('integrated_finance_application_submitted', {
                project_id: props.actionData.project_id,
                system_uuid: props.actionData.system_uuid,
                payment_option_id: props.actionData.payment_option_id,
                decision: res.data.decision,
                integration: 'phoenix',
              })
            }
          }
        })
        .catch((err) => {
          setPollCount(MAX_POLL_COUNT + 1)
        })
    }, waitMs)
  }

  const onClick = () => {
    if (isPro) {
      notify('Phoenix applications may only be started by the customer', 'warning')
    } else {
      setIsLoading(true)
      restClientInstance('CUSTOM_POST', 'custom', {
        url: `orgs/${orgId}/projects/${props.actionData.project_id}/systems/${props.actionData.system_uuid}/${props.actionData.payment_option_id}/phoenix/validate_app/`,
        data: {},
      })
        .then((res) => {
          if (res.data.success) {
            setShowDialog(true)
            if (res.data.existing_url) setExistingURL(res.data.existing_url)
            logAmplitudeEvent('phoenix_contact_verification_opened', {
              project_id: props.actionData.project_id,
              system_uuid: props.actionData.system_uuid,
              payment_option_id: props.actionData.payment_option_id,
            })
          }
        })
        .catch((err) => {
          let message = err?.body?.message || 'Something went wrong'
          notify(message, 'warning')
        })
        .finally(() => {
          setIsLoading(false)
          dispatch(hideFullScreenLoader())
          dispatch(markCreditAppAsOpened())
        })
    }
  }
  if (currentStatus === 'Approved') {
    return (
      <div style={{ width: '100%' }}>
        <h2 style={{ textAlign: 'center', margin: '10px 0px 0px 0px' }}>Application Approved!</h2>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CheckCircleIcon htmlColor="rgba(55, 169, 46, 0.9)" style={{ marginRight: '5px' }} />
          <p>You've been approved for financing by Phoenix. Please consult your sales representative for next steps</p>
        </div>
      </div>
    )
  } else if (currentStatus === 'Pending') {
    return (
      <div style={{ width: '100%' }}>
        <h2>Under Review</h2>
        <p>Your application for finance is under review. Please consult your sales representative for next steps.</p>
      </div>
    )
  } else if (isPro) {
    return (
      <Alert severity="warning">
        Phoenix applications may only be started by the customer. Please send the proposal to your customer to initiate
        an application.
      </Alert>
    )
  }

  return (
    <div style={{ width: '90%', padding: '0px 5%', marginBottom: '10px' }}>
      {currentStatus === 'Declined' && (
        <span className="small" style={{ textAlign: 'center', margin: '5px 0px 10px 0px' }}>
          Your previous application was declined. If you would like to re-apply click the button below.
        </span>
      )}
      <TransactionActionButton
        label={
          <div>
            {isLoading ? (
              <LoadingDots color="#fff" />
            ) : currentStatus === 'Declined' ? (
              'Re-Apply for Finance'
            ) : (
              'Apply for Finance'
            )}
          </div>
        }
        disabled={isPro || props.actionData.status_code !== 'available'}
        myeStyles={props.myeStyles}
        labelStyle={{ padding: 0 }}
        buttonStyle={{
          height: 39,
          ...props.acceptButtonStyle,
        }}
        action={onClick}
        extra_actions={[]}
      />
      {showDialog && (
        <PhoenixApplicationDialog
          isOpen={showDialog}
          onClose={() => setShowDialog(false)}
          project={props.selectedProject}
          systemUUID={props.actionData.system_uuid}
          paymentOptionId={props.actionData.payment_option_id}
          existingURL={existingURL}
          pricePayable={props?.attachedPhoenixPaymentOption?.pricing?.system_price_payable}
          orgId={orgId}
        />
      )}
    </div>
  )
}

export default PhoenixApplicationButton

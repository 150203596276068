import { authSelectors } from 'ducks/auth'
import Alert from 'elements/Alert'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import useDistributorAuthenticationStatus from 'services/useDistributorAuthenticationStatus'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { orgSelectors } from 'ducks/orgs'

const useStyles = makeOpenSolarStyles((theme) => ({
  loginLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}))

const VtacConnectAccountAlert = () => {
  const classes = useStyles()
  const history = useHistory()
  const orgId = useSelector(authSelectors.getOrgId) || 0
  const countryIso2 = useSelector(orgSelectors.getOrgIso2)
  const { isAuthenticated, isChecking } = useDistributorAuthenticationStatus({ orgId, distributor: countryIso2 === 'GB' ? 'vtac_uk' : 'vtac_pl' })

  const VtacLoginLink = (
    <a className={classes.loginLink} onClick={() => history.push('/shop/connect_distributor/vtac/email')}>
      connect to V-Tac
    </a>
  )

  return (
    <>
      {!isChecking && !isAuthenticated && (
        <Alert severity="warning">
          <div>You are not connected to V-Tac. Please {VtacLoginLink} to view trade pricing and credit limit.</div>
        </Alert>
      )}
    </>
  )
}

export default VtacConnectAccountAlert

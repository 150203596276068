import { Divider, Grid, Typography } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { OpenSolarThemeType } from 'Themes'
import { orgSelectors } from 'ducks/orgs'
import Button from 'elements/button/Button'
import { memo, useContext } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { currencySymbolForCountry, formatCurrency } from 'util/misc'
import { useCheckoutStyles } from '../styles'
import { DeliveryDetailContext } from './CityPlumbingCheckoutProvider'
import { getDeliveryFeeText } from './helper'
import { DeliveryDetailType } from './types'

type OrderSummaryProps = {
  cost: number
  totalBeforeTax: number
  tax: number
  totalCost: number
  disablePlaceOrder: boolean
  handlePlaceOrder: () => void
}

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  actionWrapper: {
    boxSizing: 'border-box',
    border: theme.border.default,
    borderRadius: '8px',
    flexShrink: 0,
    overflow: 'hidden',
  },
  placeOrderSectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    backgroundColor: '#F8F8F8',
    gap: '20px',
    borderBottom: '8px',
  },
  placeOrderSectionItem: {
    display: 'flex',
    justifyContent: 'center',
  },
  spaceBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  linkText: {
    fontSize: '12px',
    lineHeight: '16px',
  },
  orderSummaryBreakdownItem: {
    margin: '8px 0px',
  },
}))

const OrderSummaryBreakdownItem = ({
  label,
  value,
  style,
}: {
  label: string
  value: string
  style?: CSSProperties
}) => {
  const checkoutClasses = useCheckoutStyles()
  return (
    <Grid item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', ...style }}>
      <Typography className={checkoutClasses.font14}>{label}</Typography>
      <Typography className={checkoutClasses.font14}>{value}</Typography>
    </Grid>
  )
}

const OrderSummary = ({
  cost,
  totalBeforeTax,
  tax,
  totalCost,
  disablePlaceOrder,
  handlePlaceOrder,
}: OrderSummaryProps) => {
  const checkoutClasses = useCheckoutStyles()
  const classes = useStyles()
  const currencySymbol = useSelector((state: any) => currencySymbolForCountry(orgSelectors.getOrgIso2(state)))
  const deliveryDetail: DeliveryDetailType = useContext(DeliveryDetailContext)

  return (
    <Grid container direction="column" className={classes.actionWrapper}>
      <Grid container style={{ padding: '20px 20px 10px 20px' }} direction="column">
        <Grid item className={classes.spaceBetweenRow}>
          <Typography variant="h6" className={checkoutClasses.font18}>
            Order Summary
          </Typography>
        </Grid>
        <OrderSummaryBreakdownItem
          label="Shipping & Handling"
          value={getDeliveryFeeText(deliveryDetail.fee, currencySymbol)}
          style={{ marginTop: '24px' }}
        />
        <OrderSummaryBreakdownItem
          label="Items"
          value={`${currencySymbol} ${formatCurrency(cost)}`}
          style={{ marginTop: '8px' }}
        />
        <Grid item className={`${classes.spaceBetweenRow} ${classes.orderSummaryBreakdownItem}`}>
          <Typography className={checkoutClasses.font14}></Typography>
          <Divider style={{ width: '70px' }} />
        </Grid>
        <OrderSummaryBreakdownItem
          label="Total before tax:"
          value={`${currencySymbol} ${formatCurrency(totalBeforeTax)}`}
        />
        <OrderSummaryBreakdownItem
          label="VAT (20%):"
          value={`${currencySymbol} ${formatCurrency(tax)}`}
          style={{ marginTop: '8px' }}
        />
        <Divider style={{ marginTop: '8px' }} />
        <Grid item className={`${classes.spaceBetweenRow} ${classes.orderSummaryBreakdownItem}`}>
          <Typography className={`${checkoutClasses.fontWeightMedium} ${checkoutClasses.font16}`}>
            Order Total:
          </Typography>
          <Typography
            className={`${checkoutClasses.fontWeightMedium} ${checkoutClasses.font16}`}
            style={{ color: '#018030' }}
          >
            {`${currencySymbol} ${formatCurrency(totalCost)}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.placeOrderSectionContainer}>
        <div className={classes.placeOrderSectionItem} style={{ padding: '0px 15px' }}>
          <Button color="primary" fullWidth onClick={handlePlaceOrder} disabled={disablePlaceOrder}>
            Place Order
          </Button>
        </div>
        <div className={classes.placeOrderSectionItem} style={{ padding: '0px 25px' }}>
          <Typography className={classes.linkText}>
            <span>By placing your order, you agree to City Plumbing’s </span>
            <a href="https://www.cityplumbing.co.uk/content/terms-and-conditions-of-sale" style={{ color: '#1751D0' }}>
              Privacy Policy
            </a>
            <span> and </span>
            <a href="https://www.cityplumbing.co.uk/content/terms-and-conditions-of-sale" style={{ color: '#1751D0' }}>
              Conditions of Use
            </a>
          </Typography>
        </div>
      </Grid>
    </Grid>
  )
}

export default memo(OrderSummary)

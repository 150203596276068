import { initSdk_v1, isNestedWindow, OSSDK, SdkConfig } from 'opensolar-sdk'
import { useEffect, useMemo, useState } from 'react'

/**
 * This allows the host application to access and configure the SDK.
 * It should only be used in one location in the host application.
 * Other locations should instead use useSdkReady.
 */

export const useSdkInit = (): { sdk: OSSDK; configResolved: boolean; shouldBind: boolean } => {
  let nested = isNestedWindow()

  const config: SdkConfig = {
    singleton: true,
    master: true,
    explicit_ready: true,
  }
  // When nested, allow the host app to govern these values
  if (!nested) {
    config.hostname_api = window.API_ROOT_ABSOLUTE
    config.hostname_spa = window.location.origin
  }

  const sdk = useMemo<OSSDK>(() => initSdk_v1(config), [])

  const [configResolved, setConfigResolved] = useState<boolean>(!!sdk.resolvedConfig)
  const [shouldBind, setShouldBind] = useState<boolean>(false)

  // Watch configResolved
  useEffect(() => {
    let callback = () => setConfigResolved(!!sdk.resolvedConfig)
    sdk.onConfigResolved.add(callback)
    return () => sdk.onConfigResolved.remove(callback)
  }, [sdk])

  // Watch hasPeers
  useEffect(() => {
    if (!sdk) return

    const handler = () => {
      setShouldBind(sdk.peers.hasPeers.value)
    }
    handler() // update now

    sdk.peers.hasPeers.add(handler)

    return () => {
      sdk.peers.hasPeers.remove(handler)
      setShouldBind(false)
    }
  }, [sdk])

  return { sdk, configResolved, shouldBind }
}

import getNativeMountingSystems from './mountingSystems'

import {
  Component,
  FastenerData,
  Item,
  MountingCalcResult,
  ProductCode,
  QuantityPerProduct,
  RoofTypeName,
} from './types'

const KEY_TOKEN = 'token'

export const getRoofName = (url): RoofTypeName | null => {
  if (url) {
    let roofId = Number(url.split('roof_types/')[1].split('/')[0])
    return window.AccountHelper.getRoofTypeById(roofId)?.name
  } else {
    return null
  }
}

// Mounting system implementations provide MountingItems at various points in their calculations
export function createMountingItem<T extends Item>(
  precursor: T,
  { top = 0, left = 0, blockIndex = undefined as number | undefined } = {}
): T {
  return {
    ...precursor,
    components: precursor.components.map((component) => ({
      ...component,
      left: component.left + left, // precursor comes with relative positions for each component
      top: component.top + top, // so we move everything to its absolute position on the roof
      blockIndex,
    })),
  }
}

export function addItems<T extends MountingCalcResult>(result: T, ...setsOfnNewItems: Item[][]): T {
  return {
    ...result,
    items: [...result.items, ...setsOfnNewItems.flat()],
  }
}

export function getMatchingComponents(result: MountingCalcResult, expectedProperties: Partial<Component>) {
  return result.items
    .flatMap((item) => item.components)
    .filter((component) =>
      Object.keys(expectedProperties).every((propName) => component[propName] === expectedProperties[propName])
    )
}

export function saveFastenerData(system, fastener: FastenerData, roofIndex: number): void {
  var fastenerData: any = {
    fixing_length_screwed_through_timber: fastener.length - 25, // The screw first goes through about 5mm of roof hook, then about 20mm of batten or wooden spacer
    fixing_diameter: fastener.diameter,
  }
  if (fastener.qty) fastenerData.fixing_qty = fastener.qty
  saveDataToRoofFacet(window.projectForm, roofIndex, fastenerData)
}

// TODO: Custom data does not differentiate between systems, so data is constantly getting overwritten
// This should be refactored so mounting data is saved per system per roof facet
export function saveDataToRoofFacet(form, roofIndex: number, roofFacetData: {}): void {
  var customData = form.getState().values?.custom_data
  const previousValueStringified = JSON.stringify(customData)
  if (!customData) customData = {}
  if (!customData._mcs) customData._mcs = {}
  if (!customData._mcs.roof_facets) {
    customData._mcs.roof_facets = []
  }
  if (!customData._mcs.roof_facets[roofIndex]) customData._mcs.roof_facets[roofIndex] = {}
  let roof_facet = customData._mcs.roof_facets[roofIndex]
  Object.keys(roofFacetData).forEach((key) => {
    roof_facet[key] = roofFacetData[key]
  })

  const fieldsAreDirty = JSON.stringify(customData) !== previousValueStringified
  if (fieldsAreDirty) {
    form.mutators.updateField('custom_data', customData)
    if (form.mutators.markFieldAsDirty) form.mutators.markFieldAsDirty('custom_data')
  }
}

export function addItemsToSystem(system, manufacturer: string, result: MountingCalcResult): void {
  var componentsWithQty = {}
  result.items.forEach((item) => {
    if (componentsWithQty[item.name]) {
      componentsWithQty[item.name].qty += 1
    } else {
      componentsWithQty[item.name] = { qty: 1 }
    }
  })

  var otherComponentActivationCodes = window.AccountHelper.getComponentOtherSpecsAvailable().map((c) => c.code)

  var otherComponentCodesToActivate = Object.keys(componentsWithQty).filter((componentCode) => {
    return !otherComponentActivationCodes.includes(componentCode)
  })

  function addComponentsToProject() {
    const nativeMountingSystems = getNativeMountingSystems()
    var otherComponentIdByCode = {}
    var otherComponentShowCustomer = {}
    window.AccountHelper.getComponentOtherSpecsAvailable().forEach((otherComponentType) => {
      otherComponentShowCustomer[otherComponentType.code] = otherComponentType.show_customer
      otherComponentIdByCode[otherComponentType.code] = otherComponentType.id
    })

    const getComponentActivationId = (code) =>
      otherComponentIdByCode.hasOwnProperty(code) ? otherComponentIdByCode[code] : null

    Object.keys(componentsWithQty).forEach((componentCode) => {
      var other = new window.OsOther({
        code: componentCode,
        manufacturer_name: manufacturer,
        show_customer: false,
        other_id: getComponentActivationId(componentCode),
        userData: {
          quantity: componentsWithQty[componentCode].qty,
        },
      })
      other.reloadSpecs()
      window.editor.addObject(other, system)
    })
  }

  if (otherComponentCodesToActivate.length) {
    window.AccountHelper.activateComponents(otherComponentCodesToActivate, addComponentsToProject)
  } else {
    addComponentsToProject()
  }
}

export function handleFastenerResult(system, result: MountingCalcResult): void {
  Object.keys(result.fasteners).forEach((index) => {
    const fasteners: FastenerData[] = Object.values(result.fasteners[index])
    const avgFastenerData = fasteners.reduce(
      (accumulator: FastenerData, currentValue: FastenerData) => ({
        diameter:
          (accumulator.diameter * (accumulator.qty || 0) + currentValue.diameter * (currentValue.qty || 0)) /
          ((accumulator.qty || 0) + (currentValue.qty || 0)),
        length:
          (accumulator.length * (accumulator.qty || 0) + currentValue.length * (currentValue.qty || 0)) /
          ((accumulator.qty || 0) + (currentValue.qty || 0)),
        qty: (accumulator.qty || 0) + (currentValue.qty || 0),
      }),
      { length: 0, diameter: 0, qty: 0 } as FastenerData
    ) as FastenerData
    const roofIndex = result.roofIndex
    saveFastenerData(system, avgFastenerData, roofIndex)
  })
}

export function saveMountingWeight(system, result: MountingCalcResult): void {
  const roofIndex = result.roofIndex
  var mountingWeight = 0
  result.items.forEach((item) => {
    const matchedComponents = window.AccountHelper.getComponentOtherSpecsAvailable().filter(
      (component) => component.code === item.name
    )
    if (matchedComponents.length && matchedComponents[0].weight) mountingWeight += matchedComponents[0].weight
  })
  const mountingData = {
    mounting_weight: Math.round(mountingWeight),
  }
  saveDataToRoofFacet(window.projectForm, roofIndex, mountingData)
}

export function setPanelSpacing(system, result) {
  result.panelBlocks.forEach((panelBlock) => {
    const moduleGrid = window.editor.objectByUuid(panelBlock.uuid)
    if (JSON.stringify(moduleGrid.moduleSpacing) !== JSON.stringify(panelBlock.panelSpacing)) {
      window.editor.execute(
        new window.SetPanelConfigurationCommand(moduleGrid, 'moduleSpacing', panelBlock.panelSpacing)
      )
      window.editor.signals.objectChanged.dispatch(moduleGrid)
    }
  })
}

export const getQuantityOfProducts = (qtyRule: QuantityPerProduct, prodCode: ProductCode): number | null =>
  parseInt(Object.keys(qtyRule).find((key) => qtyRule[key].includes(prodCode)) || '', 10)

interface Errors {
  first_name?: string[]
}

export const validateContact = (values) => {
  const errors: Errors = {}
  if (!values.first_name) {
    errors.first_name = ['First name is required.']
  }
  return errors
}

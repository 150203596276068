// @ts-nocheck
import { Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import Alert from 'elements/Alert'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import CopyLink from 'layout/widgets/CopyLink'
import LoadingDots from 'layout/widgets/LoadingDots'
import React, { useEffect, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { ProjectType } from 'types/projects'
import { PaymentOptionSunlightQuickCreateType, SystemDesignSunlightQuickCreateType } from '../sunlight/types'
import MosaicConnectRoleDialogContent from './MosaicConnectRoleDialogContent'

let restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  onClose: () => void
  open: boolean
  project: ProjectType
  designs?: SystemDesignSunlightQuickCreateType[]
}

const MosaicSendAppDialog: React.FunctionComponent<PropTypes> = (props) => {
  const getMosaicPaymentOptions = () => {
    if (props.designs && props.designs.length > 0) {
      let allOptions: PaymentOptionSunlightQuickCreateType[] = []
      props.designs.forEach((design) => {
        if (design.paymentOptionsData && design.paymentOptionsData.length > 0) {
          design.paymentOptionsData.filter((opt) => opt.integration === 'mosaic').forEach((opt) => allOptions.push(opt))
        }
      })
      return allOptions
    } else return []
  }

  const role = useSelector(authSelectors.getCurrentRole)

  const [availablePaymentOptions, setAvailablePaymentOptions] = useState<PaymentOptionSunlightQuickCreateType[]>([])
  const [selectedPaymentOptionId, setSelectedPaymentOptionId] = useState<string | undefined>(undefined)
  const [selectedSystem, setSelectedSystem] = useState<SystemDesignSunlightQuickCreateType | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [method, setMethod] = useState<'Email' | 'Sms' | 'Link' | undefined>(undefined)
  const [customerEmail, setCustomerEmail] = useState<string | undefined>(undefined)
  const [customerPhone, setCustomerPhone] = useState<string | undefined>(undefined)
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const [checkingEmail, setCheckingEmail] = useState<boolean>(false)
  const [roleEmailNotInMosaic, setRoleEmailNotInMosaic] = useState<boolean>(false)
  const [allowSms, setAllowSms] = useState<boolean>(false)
  const [url, setUrl] = useState<string | undefined>(undefined)
  const [mosaicEmails, setMosaicEmails] = useState<string[]>([])

  const translate = useTranslate()
  const notify = useNotify()

  const checkMosaicEmail = (emailToUse?: string) => {
    setCheckingEmail(true)
    const url = emailToUse
      ? 'orgs/' + props.project.org_id + '/roles/' + role.id + '/mosaic/has_login/?email=' + emailToUse
      : 'orgs/' + props.project.org_id + '/roles/' + role.id + '/mosaic/has_login/'

    restClientInstance('CUSTOM_GET', 'custom', {
      url: url,
    })
      .then((res) => {
        console.log('res', res)
        if (res.data.success) setRoleEmailNotInMosaic(false)
        else {
          setRoleEmailNotInMosaic(true)
          setMosaicEmails(res.data.mosaic_emails)
        }
      })
      .catch((err) => console.log('err', err))
      .finally(() => setCheckingEmail(false))
  }

  useEffect(() => {
    if (role && !role.mosaic_enabled) {
      checkMosaicEmail()
    }
  }, [role])

  useEffect(() => {
    if (!selectedPaymentOptionId && availablePaymentOptions && availablePaymentOptions.length === 1) {
      setSelectedPaymentOptionId(`${availablePaymentOptions[0].id}`)
    }
  }, [selectedPaymentOptionId, availablePaymentOptions])

  useEffect(() => {
    if (!selectedSystem && props.designs && props.designs.length === 1) {
      setSelectedSystem(props.designs[0])
    }
  }, [props.designs, selectedSystem])

  useEffect(() => {
    setAvailablePaymentOptions(getMosaicPaymentOptions())
  }, [props.designs])

  useEffect(() => {
    if (selectedSystem && props.designs) {
      //dedupe titles present on multiple systems
      const availablePmtOptions = selectedSystem.paymentOptionsData.filter((pmtOption) => {
        return pmtOption.integration === 'mosaic'
      })
      setAvailablePaymentOptions(availablePmtOptions)
      if (!availablePmtOptions || availablePmtOptions.length === 0) setSelectedPaymentOptionId(undefined)
    } else if (availablePaymentOptions && availablePaymentOptions.length > 0) setAvailablePaymentOptions([])
  }, [selectedSystem, props.designs])

  useEffect(() => {
    if (props.project && props.project.contacts_data && props.project?.contacts_data?.length > 0) {
      let email = props?.project?.contacts_data[0]?.email
      let phone = props?.project?.contacts_data[0]?.phone?.replace(/\D/g, '')
      if (email) setMethod('Email')
      else setMethod('Link')
      if (phone && phone.length !== 10) {
        setErrorMsg("Customer's phone number must be exactly 10 digits")
        setDisabled(true)
      }
      setCustomerEmail(email)
      setCustomerPhone(phone)
    }
  }, [props.project?.contacts_data])

  const handlePmtOptionChange = (e: any) => {
    let selected = availablePaymentOptions.find((pmt) => `${pmt.id}` === e.target.value)
    if (selected && selected.categoryId === 'Zero') {
      setAllowSms(true)
    } else {
      setAllowSms(false)
    }
    setSelectedPaymentOptionId(e.target.value)
  }
  const handleSystemChange = (e: any) => {
    if (props.designs && props.designs.length > 0) {
      let uuidMatches = props.designs.filter((system) => system.uuid === e.target.value)
      if (uuidMatches && uuidMatches.length > 0) setSelectedSystem(uuidMatches[0])
    }
  }

  const handleSendMethodChange = (e: any) => {
    setMethod(e.target.value)
  }

  const submit = () => {
    if (!selectedSystem || !selectedPaymentOptionId || !props.project.org_id || !props.project.id) {
      setErrorMsg('Unable to share credit app')
      return
    } else if (!props.project?.id || props.project?.id === 'new') {
      setErrorMsg('Please save this project first')
      return
    }
    setLoading(true)
    restClientInstance('CUSTOM_POST', 'custom', {
      url:
        'orgs/' +
        props.project.org_id +
        '/projects/' +
        props.project.id +
        '/systems/' +
        selectedSystem.uuid +
        '/' +
        selectedPaymentOptionId +
        '/mosaic/share_credit_app/',
      data: {
        share_method: method,
        email: customerEmail,
        phone: customerPhone,
      },
    })
      .then((res: any) => {
        console.log('res', res)
        if (res.data.success) {
          setErrorMsg(undefined)
          if (method !== 'Link') {
            notify('Credit Application Sent', 'success')
            props.onClose()
          } else if (res.data.url) {
            setUrl(res.data.url)
          } else {
            setErrorMsg('We were unable to get the link to this credit application')
          }
          logAmplitudeEvent('integrated_finance_application_sent', {
            page: window.location.hash?.includes('/manage') ? 'Manage Page' : 'Info Page',
            integration: 'mosaic',
            method,
            project_id: props.project.id,
          })
        } else {
          if (res.data.message) setErrorMsg(res.data.message)
          else setErrorMsg('We were unable to send this credit application')
          logAmplitudeEvent('integrated_finance_application_error', {
            page: window.location.hash?.includes('/manage') ? 'Manage Page' : 'Info Page',
            integration: 'mosaic',
            method,
            project_id: props.project.id,
          })
        }
      })
      .catch((err: any) => {
        if (err?.body?.message) setErrorMsg(err.body.message)
        else setErrorMsg('Something went wrong and we were unable to send this credit application')
      })
      .finally(() => setLoading(false))
  }

  const renderForm = () => {
    if (roleEmailNotInMosaic) {
      return (
        <MosaicConnectRoleDialogContent
          project={props.project}
          mosaicEmails={mosaicEmails}
          setRoleEmailNotInMosaic={setRoleEmailNotInMosaic}
        />
      )
    }
    if (url) {
      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <p>{translate('Copy the link below and share it with your customer')}:</p>
            <Alert severity="info">
              {translate(
                'This is a one-time use link. It will not work the second time you or your customer tries to use it.'
              )}
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <div style={{ width: '100%', margin: '20px 0px' }}>
              <CopyLink url={url} />
            </div>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <ProUXButton label="Dismiss" type="secondary" onClick={props.onClose} />
          </Grid>
        </Grid>
      )
    }
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div>
            {translate(
              'Use the form below to send a Mosaic Credit application to your customer for a given system and Mosaic Product'
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <h2 style={{ marginTop: '30px' }}>{translate('Select a System')}</h2>
          <RadioGroup
            style={{ alignItems: 'flex-start' }}
            name="pmt-options"
            value={selectedSystem?.uuid}
            onChange={handleSystemChange}
          >
            {props.designs &&
              props.designs.map((system) => {
                return (
                  <FormControlLabel
                    key={system.uuid}
                    value={system.uuid}
                    control={<Radio />}
                    label={<span style={{ fontSize: 13 }}>{system.title}</span>}
                  />
                )
              })}
          </RadioGroup>
        </Grid>
        <Grid item xs={12} sm={6}>
          <h2 style={{ marginTop: '30px' }}>{translate('Select a Payment Option')}</h2>
          {selectedSystem && availablePaymentOptions?.length === 0 && (
            <div style={{ color: 'red' }}>
              {translate(
                'You must add a Mosaic payment option to this system before you can send a credit application'
              )}
            </div>
          )}
          <RadioGroup value={`${selectedPaymentOptionId}`} onChange={handlePmtOptionChange}>
            {availablePaymentOptions.map((pmtOption) => {
              return (
                <FormControlLabel
                  key={pmtOption.id}
                  value={`${pmtOption.id}`}
                  control={<Radio disabled={!selectedSystem} checked={`${pmtOption.id}` === selectedPaymentOptionId} />}
                  label={<span style={{ fontSize: 13 }}>{pmtOption.title}</span>}
                />
              )
            })}
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          {customerEmail || customerPhone ? (
            <>
              <h2>{translate('How would you like to send the application?')}</h2>
              <RadioGroup value={method} onChange={handleSendMethodChange}>
                <FormControlLabel
                  label={`Send email to ${customerEmail}`}
                  value="Email"
                  control={<Radio disabled={!customerEmail} />}
                />
                {allowSms && (
                  <FormControlLabel
                    label={`Send SMS to ${customerPhone}`}
                    value="Sms"
                    control={<Radio disabled={!customerPhone} />}
                  />
                )}
                <FormControlLabel label={`I just want a link to the application`} value="Link" control={<Radio />} />
              </RadioGroup>
            </>
          ) : (
            <div style={{ color: 'red', textAlign: 'center' }}>
              {translate(
                'Please make sure the project is saved with a customer that has a valid phone number or email address.'
              )}
            </div>
          )}
        </Grid>
        {errorMsg && (
          <Grid item xs={12}>
            <p style={{ color: 'red', textAlign: 'center', width: '100%' }}>{errorMsg}</p>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
        >
          <div style={{ margin: '10px 20px' }}>
            <ProUXButton label="Cancel" type="secondary" onClick={props.onClose} />
          </div>
          <div style={{ margin: '10px 20px' }}>
            <ProUXButton
              label="Send App"
              type="primary"
              onClick={submit}
              loading={loading}
              disabled={loading || !selectedSystem || !selectedPaymentOptionId || !method || disabled}
            />
          </div>
        </Grid>
      </Grid>
    )
  }

  const renderLoadingDots = () => {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px', width: '100%' }}
      >
        <LoadingDots />
      </div>
    )
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Send a Mosaic Credit Application</DialogTitle>
      <DialogContent>{checkingEmail ? renderLoadingDots() : renderForm()}</DialogContent>
    </Dialog>
  )
}
export default MosaicSendAppDialog

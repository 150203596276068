import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import type { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getDoNotShowAgainRecord, recordDoNotShowAgain } from './cogsNotificationRecordedAction'
import { isCogsOverrideDistributorChanged } from './detectCogsOverrideDistributorChanged'
import DistributorCogsRefreshNotification from './DistributorCogsRefreshNotification'
import useInjectDistributorCostOverride from './useInjectDistributorCostOverride'

const maybeCreateAndShowCogsNotification = ({
  supplierFilterKey,
  userId,
  projectId,
}: {
  supplierFilterKey: HardwareSupplierFilterKeyType | undefined
  userId: number
  projectId: number
}) => {
  const hasNotShowRecord = getDoNotShowAgainRecord({ userId, projectId })
  const isDistributorChanged = isCogsOverrideDistributorChanged(supplierFilterKey)
  if (!isDistributorChanged) return
  if (hasNotShowRecord) return
  return () => <DistributorCogsRefreshNotification onNotShowAgain={() => recordDoNotShowAgain({ userId, projectId })} />
}

const CogsAutoOverride = () => {
  // This hook is used to inject the distributor cost override into the system
  useInjectDistributorCostOverride()

  const supplierFilterKey = useSelector(orgSelectors.getSelectedHardwareSupplierFilterKey)
  const userId = useSelector(authSelectors.getCurrentUser)?.id
  const projectId = useSelector((state: any) => state.projectId) || 'new'

  const DistributorCogsRefreshNotification = useMemo(
    () => (userId ? maybeCreateAndShowCogsNotification({ supplierFilterKey, userId, projectId }) : undefined),
    [supplierFilterKey, userId, projectId]
  )

  if (DistributorCogsRefreshNotification) {
    return <DistributorCogsRefreshNotification />
  }
  return null
}

const CogsAutoOverrideWrapper = () => {
  const orgCountry = useSelector(authSelectors.getCurrentOrgCountry)
  if (orgCountry && ['GB', 'UK'].includes(orgCountry)) {
    return <CogsAutoOverride />
  }
  return null
}

export default CogsAutoOverrideWrapper

import { makeStyles } from '@material-ui/core'
import { Grid } from 'opensolar-ui'
import { createElement, useContext } from 'react'
import { Theme } from 'types/themes'
import AccountManagerSection from './AccountManagerSection'
import DeliveryDetailSection from './DeliveryDetailSection'
import CityPlumbingCartSection from './distributorCart/CityPlumbingCartSection'
import HdmCartSection from './distributorCart/HdmCartSection'
import OutletCartSection from './distributorCart/OutletCartSection'
import SegenCartSection from './distributorCart/SegenCartSection'
import TrustSolarCartSection from './distributorCart/TrustSolarCartSection'
import VtacCartSection from './distributorCart/VtacCartSection'
import OrderSummarySection from './OrderSummarySection'
import PreOrderProvider, { DistributorDataContext, TotalItemCountContext } from './PreOrderProvider'
import { COLOR_PALETTE, ContentBg } from './styles'
import { CheckoutSourceType } from './types'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '40px 28px',
    width: '100%',
    maxWidth: 1440,
    boxSizing: 'border-box',
    flexGrow: 1,
  },
  columnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    flexGrow: 1,
  },
  leftColumnGap: {
    gap: '12px',
  },
  rightColumnGap: {
    gap: '24px',
  },
  distributorWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100vw',
    padding: '24px 0px',
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerContent: {
    fontSize: '24px',
    fontWeight: 600,
  },
  emptyStateWrapper: {
    diplay: 'flex',
    flexDirection: 'column',
    background: COLOR_PALETTE.white,
  },
}))

const DISTRIBUTOR_CART_MAPPING = {
  segen: SegenCartSection,
  city_plumbing: CityPlumbingCartSection,
  hdm: HdmCartSection,
  vtac_uk: VtacCartSection,
  vtac_pl: VtacCartSection,
  trust_solar: TrustSolarCartSection,
  outlet: OutletCartSection,
}

const PreOrderContent = () => {
  const distributordata = useContext(DistributorDataContext)
  const classes = useStyles()
  const checkoutSource: CheckoutSourceType = 'global'
  let componentSortOrder: number = 1
  return (
    <Grid container spacing={3} className={classes.wrapper}>
      <Grid item xs={8}>
        <Grid container className={`${classes.columnWrapper} ${classes.leftColumnGap}`}>
          <Grid item xs={12}>
            <DeliveryDetailSection sortOrder={componentSortOrder} checkoutSource={checkoutSource} />
          </Grid>
          <Grid item xs={12} className={classes.distributorWrapper}>
            {Object.keys(distributordata).map((item) => {
              componentSortOrder += 1
              return createElement(DISTRIBUTOR_CART_MAPPING[item], {
                distributorKey: item,
                key: item,
                sortOrder: componentSortOrder,
                checkoutSource,
              })
            })}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid container className={`${classes.columnWrapper} ${classes.rightColumnGap}`}>
          <Grid item xs={12}>
            <OrderSummarySection checkoutSource={checkoutSource} />
          </Grid>
          <Grid item xs={12}>
            <AccountManagerSection checkoutSource={checkoutSource} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const PreOrder = () => {
  const itemCount = useContext(TotalItemCountContext)
  return (
    <ContentBg>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100vw',
          padding: '24px 0px',
          backgroundColor: 'white',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <span style={{ fontSize: '24px', fontWeight: 600 }}>{`Review and Place Order (${itemCount} items)`}</span>
      </div>
      <PreOrderContent />
    </ContentBg>
  )
}

const PreOrderPage = () => {
  return (
    <PreOrderProvider>
      <PreOrder />
    </PreOrderProvider>
  )
}

export default PreOrderPage

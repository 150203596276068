const parseChangeOrderInitialValues = (formValsOnSave: any, returnedVals: any = {}) => {
  const newInitialValues: any = formValsOnSave

  const changeOrderFields = ['change_orders', 'initiated_change_order']
  changeOrderFields.forEach((field) => {
    if (returnedVals.hasOwnProperty(field)) {
      newInitialValues[field] = returnedVals[field]
    }
  })
  return newInitialValues
}

export default parseChangeOrderInitialValues

import { JsonSchema } from '@jsonforms/core'
import { makeStyles } from '@material-ui/styles'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { JsonFormsDebuggable } from 'elements/jsonForms/JsonFormsDebuggable'
import { FormApi, FormState } from 'final-form'
import LoadingDots from 'layout/widgets/LoadingDots'
import lodash from 'lodash'
import { useTranslate } from 'ra-core'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CustomFormUsageType } from 'resources/customForms/types'
import { StudioSystemType } from 'types/studio/items'
import { useDebouncedCallback } from 'util/Debounce'
import { generateUUID } from 'util/misc'
import { CustomFormContext } from './CustomFormContext'
import { projectSchema } from './ProjectSchema'
import useCustomFormTransforms from './hooks/useCustomFormTransforms'
import { useJsonSchemaFormValidation } from './hooks/useJsonSchemaFormValidation'
import useOnSaveCustomForm from './hooks/useOnSaveCustomForm'
import { CustomFormAnyData } from './types'
import { applyScopeDefaults } from './util/applyScopeDefaults'
import { difference } from './util/difference'
import { getCustomFormData } from './util/getCustomFormData'
import { resolveScope } from './util/resolveScope'

export type CustomFormProps = {
  customForm: CustomFormUsageType
  parentForm: FormApi
  parentFormState?: FormState<any>
  isLoading?: boolean
  selectedSystem?: StudioSystemType
  unlinkValidation?: boolean
}

const useStyles = makeStyles({
  loading: { width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' },
  container: { width: '100%' },
})

export const CustomForm: React.FC<CustomFormProps> = (props) => {
  const { customForm, selectedSystem, parentForm, parentFormState, unlinkValidation, ...rest } = props
  const classes = useStyles()
  const onSave = useOnSaveCustomForm({ parentForm })
  const transforms = useCustomFormTransforms({ transformsConfig: customForm.configuration?.transforms })
  const errorsRef = useRef<CustomFormErrorObject[]>([])
  const translate = useTranslate()
  const jsonSchemaValidation = useJsonSchemaFormValidation(errorsRef, translate, !unlinkValidation)

  // Keep a ref also so that we can compare changes without reacting to them
  const currentDataRef = useRef<CustomFormAnyData | undefined>()
  const [currentData, setCurrentData] = useState<CustomFormAnyData | undefined>()

  // Create a random key here so that multiple Custom Forms play nicely together
  const uuid = useMemo(() => generateUUID().slice(0, 4), [])

  useEffect(() => {
    let fieldName
    switch (customForm.type) {
      case 'project':
      case 'system_project':
      case 'contact':
      case 'payment_option':
        fieldName = 'custom_data'
        break
      case 'role':
        fieldName = 'user_data'
        break
      default:
        throw new Error('CustomForm: Unknown type ' + customForm.type)
    }

    return parentForm.registerField(fieldName, () => {}, {}, { getValidator: jsonSchemaValidation })
  }, [customForm.type])

  useEffect(
    () => parentForm.registerField('custom_form_' + uuid, () => {}, {}, { getValidator: jsonSchemaValidation }),
    [uuid]
  )

  useEffect(
    () =>
      parentForm.subscribe(
        () => {
          checkData()
        },
        { values: true }
      ),
    [parentForm]
  )

  const checkData = useCallback(() => {
    const formState = parentForm.getState()
    if (window.debugCustomForms) console.log('CustomForm.checkData > start', uuid, formState)
    let data: CustomFormAnyData = getCustomFormData(customForm.type, formState.values, selectedSystem)

    // Apply transforms
    for (const transform of transforms) transform(data)

    // Apply defaults from scopes
    applyScopeDefaults(customForm, data)

    if (currentDataRef.current) {
      const diff = difference(data, currentDataRef.current)
      if (lodash.isEmpty(diff)) {
        // No changes, abort (re-rendering JSON form is expensive)
        if (window.debugCustomForms) console.log('CustomForm.checkData > abort', uuid, data, formState)
        return
      }
    }

    if (window.debugCustomForms) console.log('CustomForm.checkData > end', uuid, data, formState)

    currentDataRef.current = data
    setCurrentData(data)
  }, [transforms, selectedSystem])

  // depending on the context this is either hosted directly in the form we want to update (eg info page)
  // or it's hosted in some other form and we need to pass the form we are hoping to update in via props (eg doc gen)
  const parentFormStateResolved = parentFormState || parentForm.getState()
  useEffect(() => checkData(), [parentFormStateResolved, checkData])

  useEffect(() => {
    logAmplitudeEvent('custom_form_opened', {
      form_id: customForm.id,
      url: window.location.href,
      form_title: customForm.title,
    })
  }, [])

  const mergedSchema: JsonSchema = useMemo(() => {
    const schemas: [any, ...any[]] = [{}, projectSchema]

    const config = customForm.configuration
    if (!config) return {}

    if (config.schema) schemas.push(config.schema)

    if (config.scopes) {
      for (const i in config.scopes) {
        const scope = config.scopes[i]
        if (scope.schema) {
          let schema = scope.schema
          if (scope.path) {
            const parts = scope.path.split('.')
            lodash.forEachRight(parts, (p) => (schema = { [p]: schema }))
          }
          schemas.push(schema)
        }
      }
    }

    return lodash.merge.apply(null, schemas)
  }, [customForm.configuration?.schema, customForm.configuration?.scopes])

  const validatedUiSchema = useMemo(() => {
    const config = customForm.configuration
    if (!config || !config.scopes || !config.uiSchema) return config?.uiSchema

    const replace: Record<string, string> = {}

    for (const i in config.scopes) {
      const scope = config.scopes[i]
      if (!scope.path) continue

      const token = `[${i}]/`
      const path = `#/${scope.path.replaceAll('.', '/')}/`
      replace[token] = path
    }

    return resolveScope(lodash.cloneDeep(config.uiSchema), replace)
  }, [customForm.configuration?.uiSchema, customForm.configuration?.scopes])

  // Add debounce here to avoid performance issues
  const onFormChange = useDebouncedCallback((newData, errors) => {
    errorsRef.current = errors
    const oldData = currentDataRef.current
    if (!oldData || oldData === newData) return
    const hasChangesAndSaved = onSave({ currentData: oldData, newData }) // This will implicitly call into checkData
    if (!hasChangesAndSaved) return

    if (window.debugCustomForms) console.log('CustomForm.onFormChange', uuid, newData)
  }, 1000)

  if (rest.isLoading)
    return (
      <div className={classes.loading} key={'custom-form-loading-' + customForm.id}>
        <LoadingDots text="One moment while we load your custom form..." />
      </div>
    )
  else if (!customForm.configuration) return null
  return (
    <div
      className={classes.container}
      data-testid="custom-form-content-wrapper"
      key={'custom-form-content-' + customForm.id}
    >
      <CustomFormContext.Provider value={props}>
        {customForm.configuration.header && <h2>{customForm.configuration.header}</h2>}
        {customForm.configuration.header_subtext && <p>{customForm.configuration.header_subtext}</p>}
        <JsonFormsDebuggable
          header={customForm.configuration.header}
          header_subtext={customForm.configuration.header_subtext}
          schema={mergedSchema}
          uischema={validatedUiSchema}
          onChange={onFormChange}
          data={currentData}
        />
      </CustomFormContext.Provider>
    </div>
  )
}

export default CustomForm

// copied from avj's `ErrorObject`
export interface CustomFormErrorObject {
  keyword: string
  dataPath: string
  schemaPath: string
  params?: any //ErrorParameters;
  // Added to validation errors of propertyNames keyword schema
  propertyName?: string
  // Excluded if messages set to false.
  message?: string
  // These are added with the `verbose` option.
  schema?: any
  parentSchema?: object
  data?: any
}

import { ModuleGridType } from 'types/global'
import type { BasicAccessorConfig } from './types'

const setter = ({ moduleGrid, value }: { moduleGrid: ModuleGridType; value: boolean }) => {
  window.editor.execute(new window.RedrawModulesCommand(moduleGrid, 'moduleLayoutOffset', value, !value))
}

const getter = (moduleGrid: ModuleGridType): boolean => {
  return moduleGrid.moduleLayoutOffset()
}

export const offsetAccessorConfig: BasicAccessorConfig<boolean> = {
  get: getter,
  set: setter,
}

import { FormControlLabel, Grow, Tooltip } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { Switch } from "opensolar-ui"
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'

type PropTypes = {
  initialValue: boolean
  systemUuid: string
}

const SunlightDealerFeeSwitch: React.FunctionComponent<PropTypes> = (props) => {
  const [checked, setChecked] = useState<boolean>(props.initialValue)

  const form = useForm()

  const translate = useTranslate()

  useEffect(() => {
    logAmplitudeEvent('sunlight_apply_dealer_fee_shown', {})
  }, [])

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked)
    const system = window.editor.objectByUuid(props.systemUuid)
    window.editor.execute(
      new window.SetValueCommand(system, 'pricing.force_apply_dealer_fee_discounts', e.target.checked)
    )
    if (window.editor?.selectedSystem?.pricing)
      window.editor.selectedSystem.pricing.force_apply_dealer_fee_discounts = e.target.checked
    if (e.target.checked) {
      logAmplitudeEvent('sunlight_apply_dealer_fee_enabled', {})
    } else {
      logAmplitudeEvent('sunlight_apply_dealer_fee_disabled', {})
    }
    form.mutators.markFieldAsDirty('design')
    form.change('design', 'has unsaved change')
  }

  return (
    <Grow in={true}>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <FormControlLabel
          label={translate('Apply Estimated Discounts')}
          control={<Switch checked={checked} onChange={onChange} />}
        />
        <Tooltip title="This adjusts the Purchase Price for each system and payment option based on system design, overhead other fees">
          <InfoOutlined fontSize="small" htmlColor="rgb(117, 117, 117)" />
        </Tooltip>
      </div>
    </Grow>
  )
}
export default SunlightDealerFeeSwitch

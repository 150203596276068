export const BlueSnapDocumentTypes = [
  'bankDocument',
  'financialStatements',
  'processingStatements',
  'companyRepresentativeId',
  'miscellaneousRequest',
  'businessArticle',
  'businessTaxId',
  'websiteDescription',
  'owner1Id',
] as const

export type BlueSnapDocumentType = typeof BlueSnapDocumentTypes[number]

export const BlueSnapDocumentTypeMapping = {
  bankDocument: 'Bank Document',
  financialStatements: 'Financial Statements',
  processingStatements: 'Processing Statements',
  companyRepresentativeId: 'Company Representative ID',
  miscellaneousRequest: 'Miscellaneous Request',
  businessArticle: 'Business Article',
  businessTaxId: 'Business Tax ID',
  websiteDescription: 'Website Description',
  owner1Id: 'Owner ID',
}

export const BlueSnapDocumentStatuses = ['Requested', 'Received'] as const

export type BlueSnapDocumentStatus = typeof BlueSnapDocumentStatuses[number]

export const BlueSnapDocumentStatusMapping = {
  requested: 'Requested',
  received: 'Received',
}

export type BlueSnapOnboardingDocument = {
  docType: BlueSnapDocumentType
  docUnderwriterNotes: string | null
  docsReceived: number | null
  docLimit: number
  status: BlueSnapDocumentStatus
  docGenericDescription: string | null
}

// Ref: https://developers.bluesnap.com/v8976-Embedded-Payments/reference/document-upload-apis#file-requirements
export const BlueSnapDocUploadFileTypes = ['pdf', 'png', 'pdf', 'tif', 'tiff', 'xls', 'xlsx', 'jpg', 'jpeg'] as const

export type BlueSnapDocUploadFileType = typeof BlueSnapDocUploadFileTypes[number]

export type BlueSnapDocUploadRequest = {
  docType: BlueSnapDocumentType
  title: string
  fileType: string
  description: string
  content: string
}

export type TermsAndConditionsAgreement = {
  title: string
  user: string | null
  date: string | null
  url: string
}

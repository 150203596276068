import { FormControlLabel, makeStyles, useMediaQuery } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined'
import { Switch } from "opensolar-ui"
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  },
  switch: {
    switchBase: {
      color: theme.blue,
      '&$checked': {
        color: theme.blue,
      },
      '&$checked + $track': {
        backgroundColor: theme.blue,
      },
    },
    checked: {},
    track: {},
  },
  button: {
    height: 32,
    borderRadius: '4px',
    padding: '6px 8px',
    color: 'rgb(0,0,0)',
    background: '#42C310',
    '&:hover': {
      background: '#369811',
    },
    '&$disabled': {
      background: 'rgba(208, 208, 208, 0.8)',
    },
  },
  icon: {
    width: 20,
    height: 20,
    margin: 0,
    paddingRight: 8,
  },
  disabled: {},
  message: {
    verticalAlign: 'middle',
    marginRight: 10,
    fontStyle: 'italic',
  },
}))

const ViewAsCustomerToggle = ({ toggleViewAsCustomer }: any) => {
  const classes = useStyles()
  const translate = useTranslate()
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('xs'))
  const viewAsCustomer = useSelector((state: any) => state.viewAsCustomer)

  return isMobile ? (
    <VisibilityIcon style={{ cursor: 'pointer' }} onClick={() => toggleViewAsCustomer(true)} />
  ) : (
    <FormControlLabel
      control={
        <Switch
          id="view-as-customer"
          classes={{ root: classes.switch }}
          checked={viewAsCustomer}
          onChange={(e, isChecked) => toggleViewAsCustomer(isChecked)}
        />
      }
      label={translate('View As Customer')}
    />
  )
}

export default ViewAsCustomerToggle

export const CONTACT_FIELD_MAP = [
  { owner: 'address', companyRep: 'companyRepAddress' },
  { owner: 'city', companyRep: 'companyRepCity' },
  { owner: 'country', companyRep: 'companyRepCountry' },
  { owner: 'ownerDateOfBirth', companyRep: 'companyRepDateOfBirth' },
  { owner: 'ownerFirstName', companyRep: 'companyRepFirstName' },
  { owner: 'ownerGovID', companyRep: 'companyRepGovID' },
  { owner: 'ownerLastName', companyRep: 'companyRepLastName' },
  { owner: 'ownerPhone', companyRep: 'companyRepPhone' },
  { owner: 'zip', companyRep: 'companyRepZip' },
]

export const businessAddressFields = [
  'Business address',
  'Business city',
  'Business suburb',
  'Business state',
  'Business suburb',
  'Business zip',
  'Business post code',
  'Business country',
]

export const companyRepAddressFields = [
  'Company representative address',
  'Company representative city',
  'Company representative suburb',
  'Company representative state',
  'Company representative suburb',
  'Company representative zip',
  'Company representative country',
]

export const businessOwnerAddressFields = [
  'Business owner address',
  'Business owner city',
  'Business owner suburb',
  'Business owner state',
  'Business owner suburb',
  'Business owner zip',
  'Business owner country',
]

import { Divider, MenuItem, Popover } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'opensolar-ui'
import React, { useCallback } from 'react'
import { useTranslate } from 'react-admin'
import { useDispatch } from 'react-redux'
import { setSelectedSystem } from 'reducer/designer/systems'
import DuplicateSystemOptionNEM3 from './DuplicateSystemOptionNEM3'

//To Do: add theme type
const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: 'rgba(250,250,250, 0.9)',
    marginTop: 10,
    width: '100%',
    '&:hover': {
      background: 'rgba(250,250,250, 1)',
    },
  },
  mobile: {
    backgroundColor: 'rgba(250,250,250, 0.9)',
    position: 'absolute',
    fontSize: 12,
    left: 20,
    bottom: -40,
    textTransform: 'none',
    '&:hover': {
      background: 'rgba(250,250,250, 1)',
    },
  },
  label: { padding: 0 },
}))

const handleAddNewSystem = () => {
  window.Designer.startPlacementMode('System')
}

interface AddSystemButtonProps {
  disabled?: boolean
  allowDuplicate?: boolean
}

const AddSystemButton = ({ disabled = false, allowDuplicate = true }: AddSystemButtonProps) => {
  const translate = useTranslate()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    if (window.editor?.getSystems().length === 0) {
      handleAddNewSystem()
    } else {
      setAnchorEl(event.currentTarget)
    }
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])
  return (
    <>
      <Button
        className="add-new-system-button"
        classes={{ root: classes.root }}
        disabled={disabled}
        onClick={handleClick}
      >
        <span className={classes.label}>{translate('Add New System')}</span>
      </Button>
      <AddSystemPopup anchorEl={anchorEl} handleClose={handleClose} allowDuplicate={allowDuplicate} />
    </>
  )
}

type PopupPropTypes = {
  anchorEl: HTMLButtonElement | null
  handleClose: () => void
  allowDuplicate: boolean
}

const AddSystemPopup: React.FC<PopupPropTypes> = ({ anchorEl, handleClose, allowDuplicate }) => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
      transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      onClose={handleClose}
    >
      {allowDuplicate && window.editor.getSystems().length !== 0 && (
        <>
          <MenuItem
            onClick={() => {
              const system = window.editor.selectedSystem
              const newSystem = system && window.SceneHelper.duplicateSystem(system)
              if (newSystem) dispatch(setSelectedSystem(newSystem.uuid))
              handleClose()
            }}
          >
            {translate('Duplicate System')}
          </MenuItem>
          {window.WorkspaceHelper.project.state === 'CA' && window.WorkspaceHelper.project.country_iso2 === 'US' && (
            <DuplicateSystemOptionNEM3 handleClose={handleClose} />
          )}
        </>
      )}
      <Divider />
      <MenuItem
        onClick={() => {
          handleAddNewSystem()
          handleClose()
        }}
      >
        {translate('New System')}
      </MenuItem>
    </Popover>
  )
}

export default AddSystemButton

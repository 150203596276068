export const TOP_UP_AMT_CHOICES = {
  USD: [
    { id: 100, name: '$100' },
    { id: 500, name: '$500' },
    { id: 1000, name: '$1,000' },
  ],
  AUD: [
    { id: 100, name: '$100' },
    { id: 500, name: '$500' },
    { id: 1000, name: '$1,000' },
  ],
  NZD: [
    { id: 100, name: '$100' },
    { id: 500, name: '$500' },
    { id: 1000, name: '$1,000' },
  ],
  GBP: [
    { id: 100, name: '£100' },
    { id: 500, name: '£500' },
    { id: 1000, name: '£1,000' },
  ],
  default: [
    { id: 100, name: '$100' },
    { id: 500, name: '$500' },
    { id: 1000, name: '$1,000' },
  ],
}

export const getTopUpAmtChoices = (countryCode: string) => {
  if (!countryCode || !TOP_UP_AMT_CHOICES[countryCode]) return TOP_UP_AMT_CHOICES['default']
  else return TOP_UP_AMT_CHOICES[countryCode]
}

export const TOP_UP_TRIGGER_CHOICES = {
  USD: [
    { id: 20, name: '$20' },
    { id: 100, name: '$100' },
  ],
  AUD: [
    { id: 20, name: '$20' },
    { id: 100, name: '$100' },
  ],
  NZD: [
    { id: 20, name: '$20' },
    { id: 100, name: '$100' },
  ],
  GBP: [
    { id: 20, name: '£20' },
    { id: 100, name: '£100' },
  ],
  default: [
    { id: 20, name: '$20' },
    { id: 100, name: '$100' },
  ],
}

export const getTopUpTriggerChoices = (countryCode: string) => {
  if (!countryCode || !TOP_UP_TRIGGER_CHOICES[countryCode]) return TOP_UP_TRIGGER_CHOICES['default']
  else return TOP_UP_TRIGGER_CHOICES[countryCode]
}

import { FormControl, ListSubheader, makeStyles, MenuItem, Select } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import inflection from 'inflection'
import lodash from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { WorkflowStageType } from 'types/workflows'

const restClientInstance = restClient(window.API_ROOT + '/api')

const useStyles = makeStyles((theme) => ({
  outlined: {
    padding: 5,
    paddingLeft: 0,
    fontSize: 12,
  },
  formControl: {
    maxWidth: 110,
    '& fieldset': {
      border: 'none',
    },
  },
  listSubheader: {
    pointerEvents: 'none',
  },
}))

type Categories = {
  [key: string]: WorkflowStageType[]
}

const categorizeStages = (stages: WorkflowStageType[]) => {
  const categories: Categories = {
    Unsold: [],
    Sold_Not_Installed: [],
    Installed: [],
    Other: [],
  }

  stages?.forEach((stage: WorkflowStageType) => {
    const key =
      stage.milestone === 0 || stage.milestone === 1
        ? 'Unsold'
        : stage.milestone === 2
        ? 'Sold_Not_Installed'
        : stage.milestone === 3
        ? 'Installed'
        : 'Other'
    categories[key].push(stage)
  })

  return categories
}

const stagesData = (workflow) => {
  const data = {}
  const workflow_stages = workflow.workflow_stages
  const findStage = workflow_stages?.find((x) => x.id === workflow.active_stage_id)
  if (findStage?.milestone !== undefined) {
    const milestone = findStage?.milestone
    if (milestone > 1 && milestone < 3) {
      data['project_sold'] = 1
    } else if (milestone > 2 && milestone < 4) {
      data['project_sold'] = 1
      data['project_installed'] = 1
    }
  }

  return data
}

export const SelectDropDown = ({
  choices,
  record,
  source,
  submitSource = '',
  isCategory = false,
  autoTick = false,
}) => {
  const recordValue = useMemo(() => lodash.get(record, source), [record, source])
  const [value, setValue] = useState(recordValue)
  const [displayValue, setDisplayValue] = useState('')
  const orgId = useSelector(authSelectors.getOrgId)
  const notify = useNotify()
  const translate = useTranslate()
  const classes = useStyles()
  const categories = useMemo(() => categorizeStages(choices), [choices])
  const sourceToUse = submitSource === '' ? source : submitSource

  useEffect(() => {
    // any time the cached value changes, check if value matches record[source] especially when navigating through pages to ensure the updated value is displayed
    if (value !== recordValue) {
      setValue(recordValue)
    }
  }, [recordValue])

  useEffect(() => {
    const initialDisplayValue = getDisplayValue(choices, value)
    setDisplayValue(initialDisplayValue)
  }, [choices, value, recordValue])

  const handleChange = useCallback((e) => {
    const oldValue = value
    const newValue = e.target.value
    setValue(newValue)
    logAmplitudeEvent('project_list_select_dropdown', {
      action: 'changed',
      source,
      oldValue,
      newValue,
    })
    setDisplayValue(getDisplayValue(choices, newValue))
    if (autoTick) {
      restClientInstance('CUSTOM_PATCH', 'custom', {
        url: `orgs/${orgId}/projects/${record.id}/`,
        data: {
          [sourceToUse]: newValue,
          ...stagesData({ workflow_stages: choices, active_stage_id: newValue, org_id: orgId }),
        },
      })
        .then(() => {
          notify('ra.notification.updated', 'info')
        })
        .catch((e) => {
          notify(`Failed to update ${source} for project: ${record.address}`, 'error')
          setValue(oldValue)
        })
    }
  }, [])

  const getDisplayValue = (stages: WorkflowStageType[], val: number) => {
    const stage = stages?.find((stage) => stage.id === val)
    return stage
      ? stage.milestone === 0 || stage.milestone === 1
        ? 'Unsold'
        : stage.milestone === 2
        ? 'Sold (Not Installed)'
        : stage.milestone === 3
        ? 'Installed'
        : 'Other'
      : 'Select a stage'
  }

  const renderSelectGroup = (categoryName: string, items: WorkflowStageType[]) => {
    const options = items.map((item) => {
      return (
        <MenuItem key={item.id} value={item.id}>
          {item.title}
        </MenuItem>
      )
    })

    return [
      <ListSubheader className={classes.listSubheader}>
        {inflection.titleize(categoryName && categoryName)}
      </ListSubheader>,
      options,
    ]
  }

  if (choices === undefined) {
    return <Skeleton width={60} height={20} />
  }

  return (
    <FormControl variant="outlined" classes={{ root: classes.formControl }}>
      <Select
        value={value}
        classes={{ outlined: classes.outlined }}
        onChange={handleChange}
        renderValue={() => {
          if (isCategory) return translate(displayValue)
          const item = choices?.find((stage) => stage.id === value)
          if (item?.name) return translate(item.name)
          if (item?.title) return translate(item.title)
          return ''
        }}
      >
        {isCategory
          ? Object.entries(categories)?.map(([categoryName, items]) => renderSelectGroup(categoryName, items))
          : choices?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {translate(item.name || item.title)}
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  )
}

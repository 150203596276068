import type { FilterComponentNode, FilterComponentRendererType, FilterGenericPropsType } from '../type'
import DiscountFilter, { DiscountFilterOptionsType } from './DiscountFilter'

export const DISCOUNT_FILTER_KEY = 'discounted_only'

const maybeCreateDiscountFilterNode = ({
  hasDistributor,
  RendererComponent,
}: {
  hasDistributor: boolean
  RendererComponent: FilterComponentRendererType<DiscountFilterOptionsType>
}): FilterComponentNode | undefined => {
  if (!hasDistributor) {
    return undefined
  }

  const FilterComponent = (props: FilterGenericPropsType) => (
    <DiscountFilter filterKey={DISCOUNT_FILTER_KEY} RendererComponent={RendererComponent} {...props} />
  )

  return {
    key: DISCOUNT_FILTER_KEY,
    component: FilterComponent,
  }
}

export default maybeCreateDiscountFilterNode

import { useMemo } from 'react'
import { ProjectErrorType, SeverityOrder } from 'reducer/project/projectErrorsReducer'
import { ProjErrorFilters, useFilterProjectErrors } from './useFilterProjectErrors'

export function useSortedProjectErrors(filters: ProjErrorFilters[]): ProjectErrorType[] {
  const errors = useFilterProjectErrors(filters)
  return useMemo(() => {
    return errors.sort((a, b) => SeverityOrder.indexOf(a.severity) - SeverityOrder.indexOf(b.severity))
  }, [errors])
}

// @ts-nocheck
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined'
import { Button } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import { ToolTipPropsType, TourConfigType } from '../types'
import DefaultTooltip from './DefaultToolTip'

const Tooltip = (props: ToolTipPropsType) => {
  const { handleNext, handlePrev, handleCancel } = props
  const translate = useTranslate()
  return (
    <DefaultTooltip
      title={translate('ENTER PROJECT ADDRESS')}
      // onClose={handleCancel}
      content={<div>{translate('Start typing the address in the search bar and select it from the dropdown.')}</div>}
      icon={RoomOutlinedIcon}
      actions={
        <>
          <Button disabled={true}>
            <span>{translate('Back')}</span>
          </Button>
          <Button disabled={true}>
            <span>{translate('Next')}</span>
          </Button>
        </>
      }
      arrowPlacement={'bottom'}
    />
  )
}

const SearchAddressConfig: TourConfigType = {
  spotlights: [{ targetId: 'Address-Autocomplete-Input' }],
  tooltips: [{ targetId: 'Address-Autocomplete-Input', placement: 'top', component: Tooltip }],
}

export default SearchAddressConfig

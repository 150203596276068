import { InventoryLocationCreate, InventoryLocationEdit, InventoryLocationList } from './InventoryLocations'

const subtitle = 'TBC'

export default {
  list: InventoryLocationList,
  edit: InventoryLocationEdit,
  create: InventoryLocationCreate,
  options: {
    create: {
      title: 'Create Location',
      subtitle: subtitle,
      breadCrumb: 'Create Location',
    },
    list: {
      title: 'Locations',
      subtitle: subtitle,
      breadCrumb: 'Locations',
    },
    edit: {
      title: 'Edit Location',
      subtitle: subtitle,
      breadCrumb: 'Edit Location',
    },
  },
}

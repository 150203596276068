import { makeStyles } from '@material-ui/core'
import { changeTheme } from 'ducks/theme'
import { Button } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import AuthModalWrapper from './AuthModalWrapper'
import { NearmapLogo } from './common'

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    width: 280,
    marginTop: 0,
    minWidth: 210,
    padding: '0.5em 1em 1em 1em',
  },
  button: {
    color: theme.headerFontColor,
    background: theme.themeColor,
    '&:hover': {
      cursor: 'pointer',
      background: theme.themeColorDark,
    },
  },
}))

const LoginNearmapEmailClashed = () => {
  const translate = useTranslate()
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  return (
    <AuthModalWrapper>
      <div className={classes.wrapper}>
        <div style={{ textAlign: 'center' }}>
          <NearmapLogo />
        </div>
        <p>
          {translate(
            'Email for Nearmap User Account cannot be upgraded because an OpenSolar user is already using this email address.'
          )}
        </p>
        <p>
          {translate(
            'To upgrade your existing OpenSolar user account, please login to OpenSolar first, then login with Nearmap to upgrade to Nearmap on OpenSolar.'
          )}{' '}
        </p>
        <p>{translate('Otherwise, change the email on either Nearmap user account or OpenSolar user to proceed.')}</p>
        <Button
          classes={{ root: classes.button }}
          onClick={(e) => {
            e.preventDefault()
            dispatch(changeTheme('opensolar'))
            history.push('/login-modal')
          }}
        >
          <span style={{ textTransform: 'none' }}>{translate('Login to OpenSolar')}</span>
        </Button>
      </div>
    </AuthModalWrapper>
  )
}
export default LoginNearmapEmailClashed

import PropTypes from 'prop-types'
import { Component } from 'react'
import { withTranslate } from 'react-admin'
import { compose } from 'recompose'
import withStudioSignals from 'Studio/signals/withStudioSignals'
import Panel from '../Designer/Panel'

import { refreshPanelGeneric } from '../Studio/Utils'

class PanelNode extends Component {
  constructor(props) {
    super(props)
    var state = {
      visible: false,
      node: null,
      allowEdit: true,
    }

    var injectState = props.state ? props.state : null
    if (props.node) {
      injectState = this.stateFromObject(props.node)
    }

    //Override with any supplied state - for use in storybook
    if (injectState) {
      for (var key in injectState) {
        state[key] = injectState[key]
      }
    }

    this.state = state
  }

  componentDidMount() {
    this.props.useStudioSignalsLazy(this.refreshPanel, ['objectChanged', 'objectSelected', 'sceneGraphChanged'], this)
  }

  stateFromObject(node) {
    if (!node || node.type !== 'OsNode') {
      return { visible: false }
    }
    return {
      visible: true,
      node: node,
    }
  }

  refreshPanel() {
    refreshPanelGeneric.call(this, 'OsNode')
  }

  render() {
    if (this.state.visible !== true) {
      return null
    } else if (this.state.node && this.state.node.ghostMode()) {
      const { translate } = this.props
      return (
        <Panel
          showTools={this.state.allowEdit}
          selectedObject={this.state.node}
          title={translate('Placing Node')}
          summary={null}
          content={
            <p style={{ marginTop: 0 }}>
              {translate('Click anywhere on the imagery to drop the node and denote a location.')}
              <br />
              {translate('Press ESC to stop drawing.')}
            </p>
          }
        />
      )
    } else {
      const { translate } = this.props
      return (
        <Panel
          showTools={this.state.allowEdit}
          selectedObject={this.state.node}
          title={translate('Node')}
          summary={null}
          content={null}
        />
      )
    }
  }
}

PanelNode.propTypes = {
  state: PropTypes.object,
}

export default compose(withTranslate, withStudioSignals)(PanelNode)

import { Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import { Button, IconButton } from 'opensolar-ui'
import { useState } from 'react'
import { useTranslate } from 'react-admin'
import { formatCurrency, formatCurrencyWithSymbol, removeArchivedFromPmtName, titleCase } from '../../util/misc'
import PhoenixBanner from './phoenix/PhoenixBanner'
import { getIsPhoenixV2 } from './phoenix/util'

const frequencyToUnit = {
  monthly: 'months',
  bimonthly: 'periods',
  fortnightly: 'fortnights',
  weekly: 'weeks',
  quarterly: 'quarters',
  yearly: 'years',
}

export const incentiveRow = (incentiveData, currencySymbol, highlight) => ({
  left: incentiveData.title,
  left_extra: incentiveData.description && incentiveData.description.length > 0 && incentiveData.description,
  right: currencySymbol + formatCurrency(incentiveData.value),
  right_extra: incentiveData.value_details && incentiveData.value_details.length > 0 && incentiveData.value_details,
  highlight: highlight,
})

const detectTypesOfAdditionalCosts = (items) => ({
  costs: items.filter((item) => item.value >= 0).length > 0,
  revenues: items.filter((item) => item.value < 0).length > 0,
})

const additionalCostsLabel = (items) => {
  var typesOfAdditionalCosts = detectTypesOfAdditionalCosts(items)
  if (typesOfAdditionalCosts.costs && typesOfAdditionalCosts.revenues) {
    return 'Additional Costs & Revenues'
  } else if (typesOfAdditionalCosts.costs) {
    return 'Additional Costs'
  } else {
    return 'Additional Revenues'
  }
}

export const AdditionalCosts = ({ items, currencySymbol }) => {
  const translate = useTranslate()
  return (
    <div>
      <p style={{ textAlign: 'center', margin: '30px 0 0' }}>{translate(additionalCostsLabel(items))}</p>

      <table className="standard" style={{ minWidth: 300, width: '100%', margin: '20px auto 40px auto' }}>
        <tbody>
          {items.map((item, i) => (
            <ItemTableRow
              key={i}
              data={{
                left: item.title,
                left_extra: item.description,
                right: currencySymbol + formatCurrency(Math.abs(item.value)),
                right_extra: item.value_notes,
                highlight: false,
              }}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

const isRowFullWidth = (row) => {
  return (!row.right || row.right.length === 0) && (!row.right_extra || row.right_extra.length === 0)
}

export const ItemTableRow = ({ data }) => (
  <tr>
    <td
      style={
        data.highlight ? { backgroundColor: '#F3F3F3' } : { width: isRowFullWidth(data) === true ? '100%' : '62.6%' }
      }
      colSpan={isRowFullWidth(data) ? 3 : 2}
    >
      {data.left && (
        <div
          style={Object.assign(
            {},
            data.highlight
              ? { paddingTop: 5, paddingBottom: 5, fontWeight: 'bold' }
              : { paddingTop: 5, paddingBottom: 5 },
            data.leftClassStyle ? data.leftClassStyle : {}
          )}
          className={data.leftClassName ? data.leftClassName : null}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: data.left,
            }}
          />
        </div>
      )}
      {data.left_extra && (
        <div className="small">
          <div
            dangerouslySetInnerHTML={{
              __html: data.left_extra,
            }}
          />
        </div>
      )}
    </td>
    {isRowFullWidth(data) !== true && (
      <td style={data.highlight ? { backgroundColor: '#F3F3F3' } : {}} colSpan={1}>
        <div
          style={
            data.highlight
              ? { paddingTop: 5, paddingBottom: 5, fontWeight: 'bold' }
              : { paddingTop: 5, paddingBottom: 5 }
          }
        >
          <span style={{ display: 'inline-block', marginRight: 10 }}>
            <div
              dangerouslySetInnerHTML={{
                __html: data.right,
              }}
            />
          </span>
          {data.right_extra && (
            <span className="small" style={{ display: 'inline-block', fontWeight: 'normal', paddingTop: 3 }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.right_extra,
                }}
              />
            </span>
          )}
        </div>
      </td>
    )}
  </tr>
)

// const getRowsCash = (paymentOption, pricing, currencySymbol, extraRows, isCommercial, isAUProject, translate) => {
//   var rows = []
//   const taxName = isAUProject ? 'GST' : 'tax'
//   if (extraRows.discount) {
//     rows = rows.concat(extraRows.discount)
//   }

//   if (extraRows.totalSystemPrice) {
//     rows = rows.concat(extraRows.totalSystemPrice)
//   }

//   rows = rows.concat(extraRows.incentivesPaidToInstaller)

//   return rows
// }

// const transactionOnly = ['system_details']

export const getRows = (
  paymentOption,
  pricing,
  currencySymbol,
  isCommercial = false,
  isAUProject = false,
  quotationConfiguration,
  systemSize,
  translate,
  showSystemDetail,
  section
) => {
  var rows = []
  if (!paymentOption) return rows
  const quotationLines = paymentOption.quotation_lines || []
  // Prepare extraRows which can be used by any payment option
  const system_tax = formatCurrency(pricing.system_price_including_tax - pricing.system_price_excluding_tax)
  const taxName = isAUProject ? 'GST' : 'tax'
  quotationLines
    .filter((quotationLine) => {
      if (section === 1) {
        return !quotationLine.section || quotationLine.section === 1
      } else {
        return quotationLine.section === section
      }
    })
    .forEach((quotationLine) => {
      if (quotationLine.key === 'system_details') {
        showSystemDetail && rows.push(quotationLine)
      } else if (quotationLine.key === 'loan_advanced') {
        if (pricing && pricing.discount > 0) {
          rows.push({
            left: 'Discount',
            right: currencySymbol + formatCurrency(pricing.discount),
          })
        }

        const systemPrice =
          isCommercial && isAUProject ? pricing.system_price_excluding_tax : pricing.system_price_including_tax
        const systemPricePerWatt = systemPrice / (systemSize * 1000)
        rows.push({
          left: 'Total System Price',
          left_extra:
            quotationConfiguration.total_system_price &&
            quotationConfiguration.total_system_price.price_per_watt &&
            quotationConfiguration.total_system_price.price_per_watt.value
              ? `${currencySymbol}${formatCurrency(systemPricePerWatt)} per Watt for ${systemSize.toFixed(3)}kW system`
              : '',
          right: currencySymbol + formatCurrency(systemPrice),
          right_extra:
            system_tax !== '0.00'
              ? isCommercial && isAUProject
                ? `${translate('Plus')} ${currencySymbol}${system_tax} ${translate('GST')}`
                : translate('Including tax amount', {
                    currencySymbol,
                    amount: system_tax,
                    taxName: translate(taxName),
                  })
              : '',
        })

        if (paymentOption.pricing.incentive_to_installer.incentives) {
          pricing.incentive_to_installer.incentives
            .filter((incentiveData) => incentiveData.value > 0)
            .forEach((incentiveData, i) => rows.push(incentiveRow(incentiveData, currencySymbol)))
        }

        if (paymentOption.down_payment) {
          rows.push({
            left: paymentOption.delayed_down_payment_duration > 0 ? 'Delayed Down Payment' : 'Down Payment',
            right: currencySymbol + formatCurrency(paymentOption.down_payment),
            highlight: true,
          })
        }

        var breakdown = paymentOption.breakdown

        if (
          breakdown &&
          breakdown.initial_loan_amount > 0 &&
          breakdown.initial_loan_amount !== breakdown.final_loan_amount
        ) {
          rows.push({
            left: 'Initial Loan Amount',
            right: currencySymbol + formatCurrency(breakdown.initial_loan_amount),
          })
        }

        if (breakdown && breakdown.initial_loan_payment > 0) {
          rows.push({
            left: translate('Initial Loan Repayments', {
              term: paymentOption.initial_term,
              frequency: frequencyToUnit[paymentOption.initial_payment_frequency],
            }),
            right: currencySymbol + formatCurrency(breakdown.initial_loan_payment),
            highlight: true,
          })
        }

        if (breakdown && breakdown.initial_bullet_payment_amount > 0) {
          rows.push({
            left: 'Initial Loan Bullet/Balloon Payment',
            right: currencySymbol + formatCurrency(breakdown.initial_bullet_payment_amount),
          })
        }

        if (breakdown && breakdown.final_loan_amount) {
          // Advanced loan
          if (paymentOption.regular_payment > 0 && paymentOption.loan_amount > 0) {
            rows.push({
              left: 'Loan Amount',
              right: currencySymbol + formatCurrency(breakdown.final_loan_amount),
            })
          }
        }

        // Advanced loan
        rows.push({
          left: translate('Frequency Loan Repayments (over term units)', {
            term: Math.ceil(paymentOption.final_term),
            frequency: titleCase(paymentOption.final_payment_frequency),
            units: frequencyToUnit[paymentOption.final_payment_frequency],
          }),
          right: currencySymbol + formatCurrency(paymentOption.regular_payment),
          highlight: true,
        })

        if (breakdown && breakdown.final_bullet_payment_amount > 0) {
          rows.push({
            left: 'Loan Bullet/Balloon Payment',
            right: currencySymbol + formatCurrency(breakdown.final_bullet_payment_amount),
          })
        }

        rows.push({
          left: 'Total Payments',
          left_extra: translate('Over years', { time: Math.ceil(breakdown.final_year_end) }),
          right: currencySymbol + formatCurrency(-1 * paymentOption.cash_flows_payments.reduce((a, b) => a + b)),
          right_extra:
            system_tax !== '0.00' ? (isCommercial && isAUProject ? `Excluding GST` : `Including ${taxName}`) : '',
        })
      } else if (quotationLine.key === 'call_to_action_cash') {
        rows.push({
          left: 'Purchase Price',
          right: currencySymbol + formatCurrency(pricing.system_price_payable),
          right_extra: pricing.tax
            ? `${translate('Including')} ${currencySymbol}${formatCurrency(pricing.tax)} ${translate(taxName)}`
            : null,
          highlight: true,
        })
      } else if (quotationLine.key === 'call_to_action_loan') {
      } else {
        rows.push(quotationLine)
      }
    })
  // quotationItems.forEach(quotationLine => {
  //   let result = {}
  //   switch (quotationLine) {
  //     case 'system_details':
  //       if (selected_system_title && selected_system_summary) {
  //         result = {
  //           left: 'Selected System',
  //           right: selected_system_title,
  //           right_extra: selected_system_summary,
  //         }
  //       }
  //       break
  //     case 'discount':
  //       if (pricing && pricing.discount > 0) {
  //         result = {
  //           left: 'Discount',
  //           right: currencySymbol + formatCurrency(pricing.discount),
  //         }
  //       }
  //       break
  //     case 'system_price_payable':
  //       if (
  //         (quotationConfiguration.total_system_price && quotationConfiguration.total_system_price.value === false) ||
  //         !systemSize
  //       ) {
  //         //hide total system price
  //       } else {
  //         const systemPrice =
  //           isCommercial && isAUProject ? pricing.system_price_excluding_tax : pricing.system_price_including_tax
  //         const systemPricePerWatt = systemPrice / (systemSize * 1000)
  //         result = {
  //           left: 'Total System Price',
  //           left_extra:
  //             quotationConfiguration.total_system_price &&
  //             quotationConfiguration.total_system_price.price_per_watt &&
  //             quotationConfiguration.total_system_price.price_per_watt.value
  //               ? `${currencySymbol}${formatCurrency(systemPricePerWatt)} per Watt for ${systemSize.toFixed(
  //                   3
  //                 )}kW system`
  //               : '',
  //           right: currencySymbol + formatCurrency(systemPrice),
  //           right_extra:
  //             system_tax !== '0.00'
  //               ? isCommercial && isAUProject
  //                 ? `${translate('Plus')} ${currencySymbol}${system_tax} ${translate('GST')}`
  //                 : translate('Including tax amount', {
  //                     currencySymbol,
  //                     amount: system_tax,
  //                     taxName: translate(taxName),
  //                   })
  //               : '',
  //         }
  //       }
  //       break
  //     case 'system_price_per_watt':
  //       if (
  //         (quotationConfiguration.total_system_price && quotationConfiguration.total_system_price.value === false) ||
  //         !systemSize
  //       ) {
  //         //hide total system price
  //       } else {
  //         const systemPrice =
  //           isCommercial && isAUProject ? pricing.system_price_excluding_tax : pricing.system_price_including_tax
  //         const systemPricePerWatt = systemPrice / (systemSize * 1000)
  //         result = {
  //           left: 'Price Per Watt',
  //           left_extra:
  //             quotationConfiguration.total_system_price &&
  //             quotationConfiguration.total_system_price.price_per_watt &&
  //             quotationConfiguration.total_system_price.price_per_watt.value
  //               ? `${currencySymbol}${formatCurrency(systemPricePerWatt)} per Watt for ${systemSize.toFixed(
  //                   3
  //                 )}kW system`
  //               : '',
  //           right: currencySymbol + formatCurrency(systemPricePerWatt),
  //           right_extra: `For ${systemSize.toFixed(3)}kW system`,
  //         }
  //       }
  //       break
  //     case 'incentives_to_installer':
  //       pricing.incentive_to_installer.incentives
  //         .filter(incentiveData => incentiveData.value > 0)
  //         .forEach((incentiveData, i) => (result = incentiveRow(incentiveData, currencySymbol)))
  //       break
  //     case 'incentives_to_customer_for_prepayment':
  //       pricing.incentive_to_customer.incentives
  //         .filter(incentiveData => incentiveData.value > 0)
  //         .filter(
  //           incentiveData =>
  //             incentiveData.use_for_loan_pay_down === true &&
  //             (paymentOption.payment_type === 'loan' || paymentOption.payment_type === 'loan_advanced')
  //         )
  //         .forEach((incentiveData, i) => (result = incentiveRow(incentiveData, currencySymbol)))
  //       break
  //     case 'down_payment':
  //       if (paymentOption.down_payment) {
  //         result = {
  //           left: 'Down Payment',
  //           right: currencySymbol + formatCurrency(paymentOption.down_payment),
  //           highlight: true,
  //         }
  //       }

  //       break
  //     case 'loan_amount':
  //       if (paymentOption.regular_payment > 0 && paymentOption.loan_amount > 0) {
  //         result = {
  //           left: 'Loan Amount',
  //           right: currencySymbol + formatCurrency(paymentOption.loan_amount),
  //         }
  //       }

  //       break
  //     case 'loan_interest_rate':
  //       if (paymentOption.loan_interest_rate > 0) {
  //         result = {
  //           left: 'Loan Interest Rate',
  //           right: paymentOption.loan_interest_rate + '%',
  //         }
  //       }

  //       break
  //     case 'loan_repayments':
  //       // Simple Loan/regular payments/ppa
  //       result = {
  //         left:
  //           titleCase(paymentOption.payment_frequency) +
  //           ' ' +
  //           translate('Loan Repayments (over years)', { time: Math.ceil(paymentOption.loan_term) }),
  //         right: currencySymbol + formatCurrency(paymentOption.regular_payment),
  //         highlight: true,
  //       }

  //       break
  //     case 'loan_repayments_total':
  //       result = {
  //         left: 'Total Payments',
  //         left_extra: translate('Over years', { time: Math.ceil(paymentOption.loan_term) }),
  //         right: currencySymbol + formatCurrency(-1 * paymentOption.cash_flows_payments.reduce((a, b) => a + b)),
  //         right_extra:
  //           system_tax !== '0.00' ? (isCommercial && isAUProject ? `Excluding GST` : `Including ${taxName}`) : '',
  //       }
  //       break
  //     case 'loan_advanced':
  //       if (paymentOption.down_payment) {
  //         result = {
  //           left: paymentOption.delayed_down_payment_duration > 0 ? 'Delayed Down Payment' : 'Down Payment',
  //           right: currencySymbol + formatCurrency(paymentOption.down_payment),
  //           highlight: true,
  //         }
  //       }

  //       var breakdown = paymentOption.breakdown

  //       if (
  //         breakdown &&
  //         breakdown.initial_loan_amount > 0 &&
  //         breakdown.initial_loan_amount !== breakdown.final_loan_amount
  //       ) {
  //         result = {
  //           left: 'Initial Loan Amount',
  //           right: currencySymbol + formatCurrency(breakdown.initial_loan_amount),
  //         }
  //       }

  //       if (breakdown && breakdown.initial_loan_payment > 0) {
  //         result = {
  //           left: translate('Initial Loan Repayments', {
  //             term: paymentOption.initial_term,
  //             frequency: frequencyToUnit[paymentOption.initial_payment_frequency],
  //           }),
  //           right: currencySymbol + formatCurrency(breakdown.initial_loan_payment),
  //           highlight: true,
  //         }
  //       }

  //       if (breakdown && breakdown.initial_bullet_payment_amount > 0) {
  //         result = {
  //           left: 'Initial Loan Bullet/Balloon Payment',
  //           right: currencySymbol + formatCurrency(breakdown.initial_bullet_payment_amount),
  //         }
  //       }

  //       if (breakdown && breakdown.final_loan_amount) {
  //         // Advanced loan
  //         if (paymentOption.regular_payment > 0 && paymentOption.loan_amount > 0) {
  //           result = {
  //             left: 'Loan Amount',
  //             right: currencySymbol + formatCurrency(breakdown.final_loan_amount),
  //           }
  //         }
  //       }

  //       // Advanced loan
  //       result = {
  //         left: translate('Frequency Loan Repayments (over term units)', {
  //           term: Math.ceil(paymentOption.final_term),
  //           frequency: titleCase(paymentOption.final_payment_frequency),
  //           units: frequencyToUnit[paymentOption.final_payment_frequency],
  //         }),
  //         right: currencySymbol + formatCurrency(paymentOption.regular_payment),
  //         highlight: true,
  //       }

  //       if (breakdown && breakdown.final_bullet_payment_amount > 0) {
  //         result = {
  //           left: 'Loan Bullet/Balloon Payment',
  //           right: currencySymbol + formatCurrency(breakdown.final_bullet_payment_amount),
  //         }
  //       }

  //       result = {
  //         left: 'Total Payments',
  //         left_extra: translate('Over years', { time: Math.ceil(breakdown.final_year_end) }),
  //         right: currencySymbol + formatCurrency(-1 * paymentOption.cash_flows_payments.reduce((a, b) => a + b)),
  //         right_extra:
  //           system_tax !== '0.00' ? (isCommercial && isAUProject ? `Excluding GST` : `Including ${taxName}`) : '',
  //       }

  //       break
  //     case 'regular_payment':
  //       result = {
  //         left:
  //           titleCase(paymentOption.payment_frequency) +
  //           translate('Regular Payment Total', { term: paymentOption.term_years }),
  //         right: currencySymbol + formatCurrency(paymentOption.regular_payment),
  //       }
  //       break
  //     case 'regular_payment_total_payments':
  //       break
  //     case 'financed_amount':
  //       result = {
  //         left: 'Financed Amount',
  //         right: currencySymbol + formatCurrency(paymentOption.financed_amount),
  //         right_extra: pricing.tax > 0 ? (isCommercial ? `Excluding ${taxName}` : `Including ${taxName}`) : null,
  //       }
  //       break

  //     case 'financed_amount_total_payments':
  //       result = {
  //         left:
  //           titleCase(paymentOption.payment_frequency) +
  //           translate('Lease Total Payments', {
  //             term: paymentOption.term,
  //             frequency: frequencyToUnit[paymentOption.payment_frequency],
  //           }),
  //         right: currencySymbol + formatCurrency(paymentOption.regular_payment),
  //         right_extra: pricing.tax > 0 ? (isCommercial ? `Excluding ${taxName}` : `Including ${taxName}`) : null,
  //         highlight: true,
  //       }

  //       break
  //     case 'ppa_upfront_payment':
  //       if (paymentOption.ppa_upfront_payment > 0 && paymentOption.ppa_upfront_payment) {
  //         result = {
  //           left: 'Upfront Payment',
  //           right: currencySymbol + formatCurrency(paymentOption.ppa_upfront_payment),
  //         }
  //       }

  //       break

  //     case 'additional_details':
  //       break

  //     case 'ppa_dollars_per_kwh':
  //       if (paymentOption.ppa_dollars_per_kwh > 0) {
  //         if (
  //           paymentOption.ppa_dollars_per_kwh === paymentOption.ppa_dollars_per_kwh_exports &&
  //           paymentOption.ppa_escalator_annual === paymentOption.ppa_escalator_annual_exports
  //         ) {
  //           // Same rate for self-consumption and exports
  //           var leftExtras = []
  //           if (paymentOption.ppa_escalator_annual && paymentOption.ppa_escalator_annual !== 0) {
  //             leftExtras.push(
  //               translate('Escalator: percentage per year', {
  //                 percentage: window.roundToDecimalPlaces(paymentOption.ppa_escalator_annual, 2),
  //               })
  //             )
  //           }

  //           result = {
  //             left: 'Electricity Rate',
  //             left_extra: leftExtras.length > 0 ? leftExtras.join('. ') : null,
  //             right: currencySymbol + formatCurrency(paymentOption.ppa_dollars_per_kwh),
  //             right_extra: pricing.tax ? `${translate('per kWh')}, ${translate('including GST')}` : 'per kWh',
  //           }
  //         } else {
  //           // Separate rates
  //           var leftExtras

  //           // Self-Consumption
  //           leftExtras = []
  //           if (paymentOption.ppa_escalator_annual && paymentOption.ppa_escalator_annual !== 0) {
  //             leftExtras.push(
  //               translate('Escalator: percentage per year', {
  //                 percentage: window.roundToDecimalPlaces(paymentOption.ppa_escalator_annual, 2),
  //               })
  //             )
  //           }

  //           result = {
  //             left: 'Electricity Rate (Self-Consumption)',
  //             left_extra: leftExtras.length > 0 ? leftExtras.join('. ') : null,
  //             right: currencySymbol + formatCurrency(paymentOption.ppa_dollars_per_kwh),
  //             right_extra: pricing.tax ? `${translate('per kWh')}, ${translate('including GST')}` : 'per kWh',
  //           }

  //           // Exports
  //           leftExtras = []
  //           if (paymentOption.ppa_escalator_annual_exports && paymentOption.ppa_escalator_annual_exports !== 0) {
  //             leftExtras.push(
  //               translate('Escalator: percentage per year', {
  //                 percentage: window.roundToDecimalPlaces(paymentOption.ppa_escalator_annual, 2),
  //               })
  //             )
  //           }

  //           result = {
  //             left: 'Electricity Rate (Exports)',
  //             left_extra: leftExtras.length > 0 ? leftExtras.join('. ') : null,
  //             right: currencySymbol + formatCurrency(paymentOption.ppa_dollars_per_kwh_exports),
  //             right_extra: pricing.tax ? `${translate('per kWh')}, ${translate('including GST')}` : 'per kWh',
  //           }
  //         }
  //       }
  //       break

  //     case 'cap_total_kwh':
  //       if (paymentOption.cap_total_kwh) {
  //         result = {
  //           left: 'Total Energy Plan',
  //           right: numberWithCommas(paymentOption.cap_total_kwh, true),
  //           right_extra: 'kWh',
  //         }
  //       }

  //     case 'call_to_action_cash':
  //       result = {
  //         left: 'Purchase Price',
  //         right: currencySymbol + formatCurrency(pricing.system_price_payable),
  //         right_extra: pricing.tax
  //           ? `${translate('Including')} ${currencySymbol}${formatCurrency(pricing.tax)} ${translate(taxName)}`
  //           : null,
  //         highlight: true,
  //       }

  //       break
  //   }
  //   //use customised quotation labels to override default line items
  //   if (quotationLabels[quotationLine]) {
  //     result = { ...result, ...quotationLabels[quotationLine] }
  //   }
  //   Object.keys(result).length > 0 && rows.push(result)
  // })
  return rows
}

const FinancingOptionsTable = ({
  layout,
  onPaymentMethodChange,
  paymentOptions,
  myeStyles,
  selectedPaymentOption = {},
  translate,
  currencySymbol,
}) => {
  const highlightStyle = {
    background: myeStyles.highlightColor,
    color: myeStyles.textColorOnHighlight,
  }
  return (
    <table
      className={layout === 1 ? 'Financing-Table-mobile-layout Financing-Table' : 'Financing-Table'}
      style={{ textAlign: 'center', width: '100%' }}
    >
      <tbody>
        <tr style={{ background: '#f3f3f3' }}>
          <th style={{ padding: '10px 0', fontWeight: 'normal' }} colSpan={1}>
            {''}
          </th>
          <th style={{ padding: '10px 0', fontWeight: 'normal' }} colSpan={6}>
            {translate(
              selectedPaymentOption.payment_type === 'loan'
                ? 'MONTHLY PAYMENT BY TERM'
                : 'MONTHLY PAYMENT BY TERM (EXCL. GST)'
            )}
          </th>
        </tr>
        <tr>
          <th style={{ fontWeight: 'normal' }}>
            <p style={{ borderBottom: '1px solid #c9c9c9', paddingBottom: 10 }}>{translate('TERM (MONTHS)')}</p>
            <p>{translate('MONTHLY PAYMENT')}</p>
          </th>
          {paymentOptions.map((paymentOption) => (
            <td
              key={paymentOption.id}
              onClick={() => onPaymentMethodChange(paymentOption.id)}
              style={paymentOption.id === selectedPaymentOption.id ? highlightStyle : {}}
            >
              <p style={{ borderBottom: '1px solid #c9c9c9', paddingBottom: 10 }}>{paymentOption.term}</p>
              <p>
                {formatCurrencyWithSymbol(
                  paymentOption.regular_payment_ex_tax
                    ? paymentOption.regular_payment_ex_tax
                    : paymentOption.regular_payment,
                  currencySymbol,
                  window.locale,
                  2
                )}
              </p>
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  )
}

const PaymentMilestoneTable = ({ currencySymbol, cash_payment_milestones }) => {
  const translate = useTranslate()
  return (
    <div>
      <p style={{ textAlign: 'center' }}>{translate('Payment Milestones')}</p>
      <table className="standard" style={{ minWidth: 300, width: '100%', margin: '20px auto 40px auto' }}>
        <tbody>
          {cash_payment_milestones.map((row, i) => (
            <ItemTableRow
              key={i}
              data={{
                left: translate(row.title),
                left_extra: row.description,
                highlight: row.highlight,
                right: formatCurrencyWithSymbol(row.raw_amount, currencySymbol, window.locale, 2),
              }}
              translate={translate}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

const getSameTypeFinancingOptions = (paymentOptions, payment_type, integration_type) => {
  return paymentOptions
    .filter(
      (paymentOption) => paymentOption.integration === integration_type && paymentOption.payment_type === payment_type
    )
    .sort((a, b) => a.term - b.term)
}

const TermConditionButton = ({ selectedPaymentOption }) => {
  const [openDialog, setOpen] = useState(false)
  const translate = useTranslate()
  const isPDFContract = !!selectedPaymentOption.contract_base_pdf_url
  if (isPDFContract) return null
  return (
    <div style={{ textAlign: 'center', margin: '20px 0' }}>
      <a style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => setOpen(true)}>
        {translate('Terms')} & {translate('Conditions')}
      </a>
      <Dialog open={openDialog} onBackdropClick={() => setOpen(false)}>
        <DialogTitle>Term and Conditions</DialogTitle>
        <IconButton
          aria-label="close"
          style={{ position: 'absolute', right: 8, top: 8 }}
          onClick={() => setOpen(false)}
        >
          <CloseIcon />
        </IconButton>
        <Divider />
        <DialogContent>
          <div
            className="transaction-terms"
            dangerouslySetInnerHTML={{
              __html: selectedPaymentOption.contract_template,
            }}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={() => setOpen(false)}>
            <span>{translate('Ok')}</span>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export const PaymentOptions = (props) => {
  const translate = useTranslate()
  const EnergyEasePaymentOptions = getSameTypeFinancingOptions(
    props.selectedSystem.payment_options,
    props.selectedPaymentOption.payment_type,
    'energy_ease'
  )
  const PlentiPaymentOptions = getSameTypeFinancingOptions(
    props.selectedSystem.payment_options,
    props.selectedPaymentOption.payment_type,
    'plenti'
  )

  const FinancingOptions = [EnergyEasePaymentOptions, PlentiPaymentOptions]
  const isAUProject =
    props.selectedProject &&
    props.selectedProject.proposal_data &&
    props.selectedProject.proposal_data.project &&
    props.selectedProject.proposal_data.project.country_iso2 === 'AU'
  const isCommercial =
    props.selectedProject &&
    props.selectedProject.proposal_data &&
    props.selectedProject.proposal_data.project &&
    props.selectedProject.proposal_data.project.is_commercial

  const attachedPhoenixPaymentOption = props.selectedProject?.proposal_data?.systems?.find(
    (sys) => sys?.data?.uuid === props.selectedSystem.uuid
  )?.attached_phoenix_payment_option

  return (
    <>
      {attachedPhoenixPaymentOption &&
        (!getIsPhoenixV2(attachedPhoenixPaymentOption.integration_external_reference) ||
          props.selectedPaymentOption?.integration === 'phoenix') && (
          <PhoenixBanner
            phoenixPaymentOption={attachedPhoenixPaymentOption}
            projectId={props.selectedProject.id}
            systemUuid={props.selectedSystem.uuid}
            selectedPaymentOptionId={props.selectedPaymentOption.id}
            orgId={props.org?.id}
          />
        )}
      <div id="MyEnergyQuotationTable">
        <h1 className="mye-section-title">
          {props.proposalTemplateSettings.heading_payment_option}:{' '}
          {removeArchivedFromPmtName(props.selectedPaymentOption.title)}
        </h1>
        {FinancingOptions.map((options, index) => {
          if (
            props.selectedPaymentOption &&
            options.some((option) => option.id === props.selectedPaymentOption.id) &&
            options.length > 1
          ) {
            return (
              <FinancingOptionsTable
                key={index}
                layout={props.layout}
                selectedPaymentOption={props.selectedPaymentOption}
                myeStyles={props.myeStyles}
                paymentOptions={options}
                onPaymentMethodChange={props.onPaymentMethodChange}
                translate={translate}
                currencySymbol={props.currencySymbol}
              />
            )
          } else {
            return null
          }
        })}
        <table className="standard" style={{ minWidth: 300, width: '100%', margin: '20px auto 40px auto' }}>
          <tbody>
            {getRows(
              props.selectedPaymentOption,
              props.selectedSystem.pricing,
              props.currencySymbol,
              isCommercial,
              isAUProject,
              props.quotationConfiguration,
              props.selectedSystem.kw_stc,
              translate,
              true,
              1
            ).map((row, i) => (
              <ItemTableRow key={i} data={row} translate={translate} />
            ))}
          </tbody>
        </table>

        {props.selectedPaymentOption.quotation_lines.some((quotation_line) => quotation_line.section === 2) && (
          <div>
            <p style={{ textAlign: 'center' }}>{translate('Additional Incentives')}</p>
            <table className="standard" style={{ minWidth: 300, width: '100%', margin: '20px auto 40px auto' }}>
              <tbody>
                {getRows(
                  props.selectedPaymentOption,
                  props.selectedSystem.pricing,
                  props.currencySymbol,
                  isCommercial,
                  isAUProject,
                  props.quotationConfiguration,
                  props.selectedSystem.kw_stc,
                  translate,
                  true,
                  2
                ).map((row, i) => (
                  <ItemTableRow key={i} data={row} translate={translate} />
                ))}
              </tbody>
            </table>
          </div>
        )}
        {props.selectedSystem.additional_costs && props.selectedSystem.additional_costs.length > 0 && (
          <AdditionalCosts
            items={props.selectedSystem.additional_costs}
            currencySymbol={props.currencySymbol}
            translate={translate}
          />
        )}

        {props.selectedPaymentOption.payment_type === 'cash' &&
          props.selectedPaymentOption.cash_payment_milestones?.length &&
          props.selectedPaymentOption.show_payment_milestones_table && (
            <PaymentMilestoneTable
              currencySymbol={props.currencySymbol}
              cash_payment_milestones={props.selectedPaymentOption.cash_payment_milestones}
            />
          )}
        {props.selectedPaymentOption.integration && props.selectedPaymentOption && (
          <TermConditionButton selectedPaymentOption={props.selectedPaymentOption} />
        )}
      </div>
    </>
  )
}

export default PaymentOptions

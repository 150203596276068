import { countryMapping } from 'constants/countries'
import { orgSelectors } from 'ducks/orgs'
import React from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import AddressAutocomplete from 'resources/projects/elements/AddressAutocomplete'

type PropTypes = {
  fieldLabel: string | ''
  fieldName: string | ''
}

const OnboardingAddressAutocomplete: React.FC<PropTypes> = (props) => {
  const form = useForm()
  const formState = useFormState()
  const org = useSelector(orgSelectors.getOrg)
  const orgCountry = org?.country?.iso2
  const orgAddress = org?.address
  const apiKeyGoogle = useSelector((state: any) =>
    state.auth && state.auth.api_key_google ? state.auth.api_key_google : ''
  )

  let addressIndex
  let addressType = props.fieldName.split('.')[0]
  const addressArrayMatch = addressType.match(/(\w+)\[(\d+)\]/) // Match array pattern like 'ownershipInfoArr[0]'
  const locality = orgCountry === 'AU' ? 'suburb' : 'city'

  if (orgAddress && addressType === 'businessInfo' && !formState?.values?.businessInfo?.address) {
    form.change(`businessInfo.address`, org.address)
    form.change(`businessInfo.${locality}`, org.locality)
    form.change(`businessInfo.state`, org.state)
    form.change(`businessInfo.zip`, org.zip)
  }

  const getDefaultValue = () => {
    let addressInfo = formState?.values?.[addressType]

    if (addressArrayMatch) {
      addressType = addressArrayMatch[1]
      addressIndex = parseInt(addressArrayMatch[2], 10)
      addressInfo = formState?.values?.[addressType]?.[addressIndex]
    }
    if (!addressInfo?.address && !addressInfo?.companyRepAddress) return ''

    const isCompanyRep = addressType === 'companyRep'
    const address = isCompanyRep ? addressInfo?.companyRepAddress : addressInfo?.address
    const suburb = isCompanyRep ? addressInfo?.companySuburb : addressInfo?.suburb
    const city = isCompanyRep ? addressInfo?.companyRepCity : addressInfo?.city
    const state = isCompanyRep ? addressInfo?.companyRepState : addressInfo?.state
    const zip = isCompanyRep ? addressInfo?.companyRepZip : addressInfo?.zip
    const country = isCompanyRep ? addressInfo?.companyRepCountry : addressInfo?.country
    const localityField = orgCountry === 'AU' ? suburb : city

    let defaultValue = `${address}${localityField ? `, ${localityField},` : ''}`
    if (state) defaultValue += ` ${state}`
    if (zip) defaultValue += ` ${zip}`
    if (country) defaultValue += `, ${countryMapping[country]?.name}`

    return defaultValue
  }

  const handlePlaceSelected = (addressFields) => {
    if (addressType === 'companyRep') {
      if (addressFields.address) form.change(`${addressType}.companyRepAddress`, addressFields.address)
      if (addressFields.locality) form.change(`${addressType}.companyRepCity`, addressFields.locality)
      if (addressFields.state) form.change(`${addressType}.companyRepState`, addressFields.state)
      if (addressFields.zip) form.change(`${addressType}.companyRepZip`, addressFields.zip)
      if (addressFields.country_iso2) form.change(`${addressType}.companyRepCountry`, addressFields.country_iso2)
    } else if (addressType === 'ownershipInfoArr') {
      if (addressFields.address) form.change(`${addressType}[${addressIndex}].address`, addressFields.address)
      if (addressFields.locality) form.change(`${addressType}[${addressIndex}].city`, addressFields.locality)
      if (addressFields.state) form.change(`${addressType}[${addressIndex}].state`, addressFields.state)
      if (addressFields.zip) form.change(`${addressType}[${addressIndex}].zip`, addressFields.zip)
      if (addressFields.country_iso2) form.change(`${addressType}[${addressIndex}].country`, addressFields.country_iso2)
    } else {
      if (addressFields.address) form.change(`${addressType}.address`, addressFields.address)
      if (addressFields.locality) form.change(`${addressType}.${locality}`, addressFields.locality)
      if (addressFields.state) form.change(`${addressType}.state`, addressFields.state)
      if (addressFields.zip) form.change(`${addressType}.zip`, addressFields.zip)
    }
  }

  return (
    <AddressAutocomplete
      onPlaceSelected={handlePlaceSelected}
      api_key_google={apiKeyGoogle}
      types={['address']}
      componentRestrictions={null}
      label={props.fieldLabel}
      defaultValue={getDefaultValue()}
      isCashFlowOnboarding
    />
  )
}
export default OnboardingAddressAutocomplete

import { useMemo } from 'react'
import type { FactoryHookType } from '../type'
import createOwnerOrgFilterNode from './OwnerOrgFilterNodeFactory'

const useOwnerOrgFilterNode: FactoryHookType = ({ RendererComponent, options }) => {
  return useMemo(() => {
    return createOwnerOrgFilterNode({
      RendererComponent,
    })
  }, [RendererComponent])
}

export default useOwnerOrgFilterNode

import { Card, Tooltip } from '@material-ui/core'
import { AccountCircleOutlined } from '@material-ui/icons'
import { useTranslate } from 'ra-core'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import useTranslateParse from 'util/useTranslateParse'
import { CONTACT_FIELD_MAP } from '../constants'
import { FieldPrefixFilterType, FormRouteInfoPropTypes } from '../types'
import { contactIsNotEmpty, getContactsAreEqual, getNormalizeBlueSnapContact } from '../utils'

const useStyles = makeOpenSolarStyles((theme) => ({
  header: { fontSize: 20, fontWeight: 500, marginTop: 0, marginBottom: 0 },
  description: {
    lineHeight: 1.5,
  },
  row: {
    display: 'flex',
    gap: '1.5rem',
    paddingBottom: '1rem',
  },
  card: {
    flex: 1,
    padding: '1rem',
    borderRadius: '8px',
    cursor: 'pointer',
  },
  selectedCard: {
    flex: 1,
    padding: '1rem',
    borderRadius: '8px',
    border: '2px solid #4272DD !important',
  },
  disabledCard: {
    flex: 1,
    padding: '1rem',
    borderRadius: '8px',
    cursor: 'default',
    color: theme.greyMid2,
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    fontSize: '16px',
    margin: 0,
  },
  cardDescription: {
    lineHeight: 1.5,
    margin: '0 0 0 30px',
    fontSize: '13px',
  },
  formHeader: {
    borderTop: '1px solid #DADBE9',
    paddingTop: 25,
    marginTop: 15,
    paddingBottom: 15,
  },
}))

const PersonInfoTop: React.FC<FormRouteInfoPropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const translateParse = useTranslateParse()

  const formState = useFormState()
  const form = useForm()

  const [ownerIndexToShow, setOwnerIndexToShow] = useState<number>(0)
  const [ownerFieldToShow, setOwnerFieldToShow] = useState<FieldPrefixFilterType>(null)
  const [hideContent, setHideContent] = useState<boolean>(false)

  const OWNER_FIELD_PREFIX = {
    0: 'ownershipInfoArr[0]',
    1: 'ownershipInfoArr[1]',
    2: 'ownershipInfoArr[2]',
    3: 'ownershipInfoArr[3]',
  }

  const companyRep = formState?.values?.companyRep
  const owner1 = formState?.values?.ownershipInfoArr?.[0]
  const owner2 = formState?.values?.ownershipInfoArr?.[1]
  const owner3 = formState?.values?.ownershipInfoArr?.[2]
  const owner4 = formState?.values?.ownershipInfoArr?.[3]

  useEffect(() => {
    let indexToShow = formState.values?.ownershipInfoArr?.length ? formState.values?.ownershipInfoArr?.length : 0
    let prevIndex = 0

    if (indexToShow > 0 && prevIndex < 4) {
      while (prevIndex !== indexToShow) {
        const checkPrevIndex = contactIsNotEmpty(formState.values.ownershipInfoArr[prevIndex])
        !checkPrevIndex ? (indexToShow = prevIndex) : prevIndex++
      }
    }

    setOwnerIndexToShow(indexToShow)
    setOwnerFieldToShow(OWNER_FIELD_PREFIX[indexToShow])
  }, [])

  useEffect(() => {
    if (props.fieldPrefixFilter && props.fieldPrefixFilter?.startsWith('ownershipInfoArr')) {
      setHideContent(true)
    }
  }, [])

  const repIsUnique = useMemo(() => {
    if (!companyRep) return false
    else if (getContactsAreEqual(getNormalizeBlueSnapContact(companyRep), getNormalizeBlueSnapContact(owner1))) {
      return false
    } else if (getContactsAreEqual(getNormalizeBlueSnapContact(companyRep), getNormalizeBlueSnapContact(owner2))) {
      return false
    } else if (getContactsAreEqual(getNormalizeBlueSnapContact(companyRep), getNormalizeBlueSnapContact(owner3))) {
      return false
    } else if (getContactsAreEqual(getNormalizeBlueSnapContact(companyRep), getNormalizeBlueSnapContact(owner4))) {
      return false
    }
    return true
  }, [])

  const onChangeContactType = (type: 'owner' | 'rep') => {
    if (type === 'owner') {
      props.setFieldPrefixFilter(ownerFieldToShow)
      const newOwnerFields = {}
      if (formState.values.companyRep && !repIsUnique) {
        CONTACT_FIELD_MAP?.forEach((field) => {
          newOwnerFields[field.owner] = formState.values.companyRep?.[field.companyRep]
        })
      }
      form.change(`ownershipInfoArr[${ownerIndexToShow}]`, newOwnerFields)
      form.change('companyRep', {}) // clear form data copied over when switching contact types to avoid duplicates
    } else {
      props.setFieldPrefixFilter('companyRep')
      const newCompanyRepValue = {}
      CONTACT_FIELD_MAP?.forEach((field) => {
        newCompanyRepValue[field.companyRep] = formState.values.ownershipInfoArr?.[ownerIndexToShow]?.[field.owner]
      })
      form.change('companyRep', newCompanyRepValue)
      // completely remove the owner in this index sinc they are switching to companyrep
      let newOwnershipInfoArr = formState.values?.ownershipInfoArr?.filter((owner, i) => i !== ownerIndexToShow)
      form.change(`ownershipInfoArr`, newOwnershipInfoArr)
    }
  }

  if (hideContent) return null
  else
    return (
      <div>
        <h1 className={classes.header}>{translate('Add owner or company representative')}</h1>
        <p className={classes.description}>
          {translateParse('<strong>Who would you like to add?<strong>', {
            strong: (label: string) => <strong>{label}</strong>,
          })}
          <br />
          {translate(
            'You can add up to 4 contacts. All individuals who own 25% or more of your business must be added.'
          )}
        </p>
        <div className={classes.row}>
          <Card
            className={
              props.fieldPrefixFilter !== 'companyRep' && props.fieldPrefixFilter !== null
                ? classes.selectedCard
                : classes.card
            }
            onClick={() => onChangeContactType('owner')}
          >
            <h2 className={classes.cardHeader}>
              <AccountCircleOutlined /> {translate('Owner')}
            </h2>
            <p className={classes.cardDescription}>
              {translate('This is a person who owns 25% or more of your business')}
            </p>
          </Card>

          {repIsUnique ? (
            <Tooltip
              title={translate(
                '%{name} has already been entered as the designated Company Representative. If you would like to switch the Company Representative please delete %{name}',
                { name: companyRep.companyRepFirstName + ' ' + companyRep.companyRepLastName }
              )}
              enterDelay={300}
            >
              <Card className={classes.disabledCard}>
                <h2 className={classes.cardHeader}>
                  <AccountCircleOutlined /> {translate('Company Representative')}
                </h2>
                <p className={classes.cardDescription}>
                  {translate(
                    'This is an individual who oversees the business and has significant control over the entities'
                  )}
                </p>
              </Card>
            </Tooltip>
          ) : (
            <Card
              className={
                props.fieldPrefixFilter === 'companyRep' && props.fieldPrefixFilter !== null
                  ? classes.selectedCard
                  : classes.card
              }
              onClick={() => onChangeContactType('rep')}
            >
              <h2 className={classes.cardHeader}>
                <AccountCircleOutlined /> {translate('Company Representative')}
              </h2>
              <p className={classes.cardDescription}>
                {translate(
                  'This is an individual who oversees the business and has significant control over the entities'
                )}
              </p>
            </Card>
          )}
        </div>

        {props.fieldPrefixFilter !== null && (
          <p className={classes.formHeader}>
            {translateParse(
              "Please provide contact's <strong>personal information<strong> below. You will be able to add more contacts after saving.",
              {
                strong: (label: string) => <strong>{label}</strong>,
              }
            )}
          </p>
        )}
      </div>
    )
}
export default PersonInfoTop

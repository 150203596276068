import { useMediaQuery } from '@material-ui/core'
import React, { useState } from 'react'
import restClient from 'restClient'
import { logAppOpenedEvent } from '../../util/events'
import { TransactionActionButton } from '../elements/TransactionStartButton'
import SunlightApplicationLoading from './SunlightApplicationLoading'

var restClientInstance = restClient(window.API_ROOT + '/api')

const styles = {
  mobile: {
    margin: 0,
    display: 'inline-block',
  },
  desktop: {
    textAlign: 'center',
    margin: '15px 0px 15px 0px',
  },
}

const Prequal = ({
  actionData,
  translate,
  showDialog,
  myeStyles,
  acceptButtonStyle,
  acceptButtonStyleDisabled,
  loanType,
  isPrimaryAction,
  selectedPaymentOptionName,
}) => {
  const [showLoadingDialog, setShowLoadingDialog] = useState(false)

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const style = isMobile && loanType === 'sunlight_loan_application' ? styles.mobile : styles.desktop
  if (actionData.status_code === 'complete') {
    return <div style={{ color: myeStyles.textColor, ...style }}>✓ You're Pre-Qualified!</div>
  } else if (actionData.status_code === 'available') {
    var buttonLabelText = 'Get Pre-Qualified Instantly'

    var extra_actions = []

    if (actionData.status_code === 'available' && isPrimaryAction) {
      extra_actions.push(
        <p key={extra_actions.length} className="small">
          {'Clicking "' +
            buttonLabelText +
            '" will take you to Sunlight Financial\'s credit application. OpenSolar does not view or store any of the data you enter in this application.'}
        </p>
      )
    }

    const buttonAction = function (e) {
      logAppOpenedEvent(actionData, selectedPaymentOptionName)
      if (e) {
        e.preventDefault()
      }
      if (actionData.application_url) {
        // URL already generated which means a sunlight account has been made. But the url might be stale so we will refresh it and use that for the dialog
        restClientInstance('CUSTOM_POST', 'custom', {
          url:
            'orgs/' +
            actionData.org_id +
            '/projects/' +
            actionData.project_id +
            '/refresh_sunlight_iframe_url/' +
            actionData.system_uuid +
            '/' +
            actionData.payment_option_id +
            '/sunlight_loan_prequal/',
        }).then(
          (response) => {
            if (response.data.status && response.data.url) {
              showDialog('SunlightLoanApplication', { url: response.data.url })
            } else {
              window.Designer.showNotification(
                window.translate('Unable to open Sunlight Prequalification Form'),
                'danger'
              )
            }
          },
          (reject) => {
            console.log(reject)
            window.Designer.showNotification(window.translate(reject.message), 'danger')
            setShowLoadingDialog(false)
          }
        )
      } else {
        setShowLoadingDialog(true)
        // Request the email send (which will automatically record an incomplete transaction)
        restClientInstance('CUSTOM_POST', 'custom', {
          url:
            'orgs/' +
            actionData.org_id +
            '/projects/' +
            actionData.project_id +
            '/apply_for_finance/' +
            actionData.system_uuid +
            '/' +
            actionData.payment_option_id +
            '/sunlight_loan_prequal/',
        }).then(
          (response) => {
            console.log(response.data)
            if (response.data && response.data.data && response.data.data.application_url) {
              showDialog('SunlightLoanApplication', { url: response.data.data.application_url })
            }
            setShowLoadingDialog(false)
          },
          (reject) => {
            console.log(reject)
            window.Designer.showNotification(window.translate(reject.message), 'danger')
            setShowLoadingDialog(false)
          }
        )
      }
    }

    if (isPrimaryAction && actionData.status_code === 'available') {
      return (
        <>
          <TransactionActionButton
            label={<div>{buttonLabelText}</div>}
            disabled={actionData.status_code !== 'available'}
            myeStyles={myeStyles}
            labelStyle={{ padding: 0 }}
            buttonStyle={{
              height: 39,
              ...(actionData.status_code === 'available' ? acceptButtonStyle : acceptButtonStyleDisabled),
            }}
            action={actionData.status_code === 'available' ? buttonAction : null}
            extra_actions={extra_actions}
          />
          <SunlightApplicationLoading visible={showLoadingDialog} />
        </>
      )
    } else {
      return (
        <div key={'extra actions'} style={style}>
          {/* //eslint-disable-line */}
          <a href="#" style={{ color: myeStyles.highlightColor }} onClick={buttonAction}>
            {translate(buttonLabelText)}
          </a>
          {actionData.status_description && <div>{actionData.status_description}</div>}
          {extra_actions}
          <SunlightApplicationLoading visible={showLoadingDialog} />
        </div>
      )
    }
  } else {
    // Nothing to render (should only happen when actionData.status_code === 'hidden' or 'disabled'
    return null
  }
}

export default Prequal

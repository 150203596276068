import Typography from '@material-ui/core/Typography'
import type { OpenSolarThemeType } from 'Themes'
import type LineItemType from 'pages/ordering/OrderLineItem'
import React, { memo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useBulkOrderMessageStyle = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  bulkAvailable: {
    color: theme.greyMid1,
    margin: 5,
  },
}))

export const PureBulkOrderMessage = memo(
  ({
    quantity,
    quantityPerItem,
    variantsAreAllSingle,
  }: {
    quantity: number
    quantityPerItem: number | undefined
    variantsAreAllSingle: boolean
  }) => {
    const label = variantsAreAllSingle ? 'Options' : 'Bulk'
    const classes = useBulkOrderMessageStyle()
    if (quantityPerItem != null && quantityPerItem > 1) {
      return (
        <Typography className={classes.bulkAvailable} variant="body1" gutterBottom>
          {quantity * quantityPerItem} units
        </Typography>
      )
    }
    return (
      <Typography className={classes.bulkAvailable} variant="body1" gutterBottom>
        {label} available
      </Typography>
    )
  }
)

const BulkOrderMessage = ({ lineItem }: { lineItem: LineItemType }) => {
  const distributors = lineItem.data?.distributors
  const variantsAreAllSingle = !!distributors?.every((distributor) => distributor.quantity_per_item === 1)

  return (
    <PureBulkOrderMessage
      quantity={lineItem.quantity}
      quantityPerItem={lineItem.quantityPerItem}
      variantsAreAllSingle={variantsAreAllSingle}
    />
  )
}

export default BulkOrderMessage

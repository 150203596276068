import { Grid } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDownOutlined'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUpOutlined'
import CustomField from 'elements/field/CustomField'
import CheckboxInput from 'elements/input/CheckboxInput'
import { Button } from 'opensolar-ui'
import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import useTranslateParse from 'util/useTranslateParse'

type PropsType = {
  disabled: boolean
}

const AccessControl: React.FC<PropsType> = ({ disabled }) => {
  const [showConfigs, setShowConfigs] = useState(false)
  const translateParse = useTranslateParse()
  const translate = useTranslate()
  return (
    <Grid container spacing={3} style={{ padding: '16px 0px' }}>
      <Grid item xs={12} sm={12} md={6}>
        <CustomField
          component={CheckboxInput}
          disabled={disabled}
          elStyle={{ maxWidth: 300 }}
          resource="roles"
          name="access_projects"
          source="access_projects"
          label="Allow Access to all projects and records OR only allow access to:"
        />
        {showConfigs && (
          <div style={{ marginLeft: 20 }}>
            <CustomField
              component={CheckboxInput}
              resource="roles"
              name="access_contacts"
              source="access_contacts"
              label="All Contacts"
            />
            <CustomField
              component={CheckboxInput}
              resource="roles"
              name="access_transactions"
              source="access_transactions"
              label="All Transactions"
            />
            <CustomField
              component={CheckboxInput}
              resource="roles"
              name="access_files"
              source="access_files"
              label="All Files"
            />
            <CustomField
              component={CheckboxInput}
              resource="roles"
              name="access_activities"
              source="access_activities"
              label="All Activities"
            />
          </div>
        )}
        <Button
          variant="outlined"
          disabled={disabled}
          onClick={() => setShowConfigs((show) => !show)}
          endIcon={showConfigs ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        >
          {translate('Configure access')}
        </Button>
        <p>
          {translateParse('For a detailed overview of role permissions see <a>here<a>', {
            a: (label: string) => (
              <a
                style={{ color: 'rgb(24, 144, 255)' }}
                href={'https://support.opensolar.com/hc/en-us/articles/4416624009497'}
                target="_blank"
                rel="noreferrer"
              >
                {label}
              </a>
            ),
          })}
        </p>
      </Grid>
    </Grid>
  )
}

export default AccessControl

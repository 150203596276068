export const COBORROWER_PREFIX = 'coborrower_'
// human-friendly
export const LOANPAL_OR_GOODLEAP = 'Goodleap'

export const MONTHS = [
  { id: '01', name: 1 },
  { id: '02', name: 2 },
  { id: '03', name: 3 },
  { id: '04', name: 4 },
  { id: '05', name: 5 },
  { id: '06', name: 6 },
  { id: '07', name: 7 },
  { id: '08', name: 8 },
  { id: '09', name: 9 },
  { id: '10', name: 10 },
  { id: '11', name: 11 },
  { id: '12', name: 12 },
]

const MIN_DAYS = [
  { id: '01', name: 1 },
  { id: '02', name: 2 },
  { id: '03', name: 3 },
  { id: '04', name: 4 },
  { id: '05', name: 5 },
  { id: '06', name: 6 },
  { id: '07', name: 7 },
  { id: '08', name: 8 },
  { id: '09', name: 9 },
  { id: '10', name: 10 },
  { id: '11', name: 11 },
  { id: '12', name: 12 },
  { id: '13', name: 13 },
  { id: '14', name: 14 },
  { id: '15', name: 15 },
  { id: '16', name: 16 },
  { id: '17', name: 17 },
  { id: '18', name: 18 },
  { id: '19', name: 19 },
  { id: '20', name: 20 },
  { id: '21', name: 21 },
  { id: '22', name: 22 },
  { id: '23', name: 23 },
  { id: '24', name: 24 },
  { id: '25', name: 25 },
  { id: '26', name: 26 },
  { id: '27', name: 27 },
  { id: '28', name: 28 },
  { id: '29', name: 29 },
]

export const DAYS_BY_MONTH = {
  '01': [...MIN_DAYS, { id: '30', name: 30 }, { id: '31', name: 31 }],
  '02': [...MIN_DAYS],
  '03': [...MIN_DAYS, { id: '30', name: 30 }, { id: '31', name: 31 }],
  '04': [...MIN_DAYS, { id: '30', name: 30 }],
  '05': [...MIN_DAYS, { id: '30', name: 30 }, { id: '31', name: 31 }],
  '06': [...MIN_DAYS, { id: '30', name: 30 }],
  '07': [...MIN_DAYS, { id: '30', name: 30 }, { id: '31', name: 31 }],
  '08': [...MIN_DAYS, { id: '30', name: 30 }, { id: '31', name: 31 }],
  '09': [...MIN_DAYS, { id: '30', name: 30 }],
  '10': [...MIN_DAYS, { id: '30', name: 30 }, { id: '31', name: 31 }],
  '11': [...MIN_DAYS, { id: '30', name: 30 }],
  '12': [...MIN_DAYS, { id: '30', name: 30 }, { id: '31', name: 31 }],
  none: [...MIN_DAYS, { id: '30', name: 30 }, { id: '31', name: 31 }],
}

export const getYearChoices = () => {
  const now = new Date()
  const thisYear = now.getFullYear()
  const maxYear = thisYear - 17
  let years: { id: string; name: string }[] = []

  let yearToAdd = maxYear
  while (yearToAdd >= 1900) {
    const yearString = `${yearToAdd}`
    years.push({ id: yearString, name: yearString })
    yearToAdd--
  }
  return years
}

export const EMPLOYMENT_STATUS = [
  { id: 'Active Military', name: 'Active Military' },
  { id: 'Employed', name: 'Employed' },
  { id: 'Government/DOD', name: 'Government/DOD' },
  { id: 'Homemaker', name: 'Homemaker' },
  { id: 'Other', name: 'Other' },
  { id: 'Owner', name: 'Owner' },
  { id: 'Retired', name: 'Retired' },
  { id: 'Retired Military', name: 'Retired Military' },
  { id: 'Self Employed', name: 'Self Employed' },
  { id: 'Student', name: 'Student' },
  { id: 'Unemployed', name: 'Unemployed' },
]

export const PHONE_TYPES = [
  { id: 'Cell', name: 'Cell Phone' },
  { id: 'Home', name: 'Home Phone' },
]

export const DURATION_CHOICES = [
  { id: '0.5', name: 'Less than 6 months' },
  { id: '1', name: '6 months - 1 year' },
  { id: '2', name: '2 years' },
  { id: '3', name: '3 years' },
  { id: '4', name: '4 years' },
  { id: '5', name: '5 years' },
  { id: '6', name: '6 years' },
  { id: '7', name: '7 years' },
  { id: '8', name: '8 years' },
  { id: '9', name: '9 years' },
  { id: '10', name: '10 year' },
  { id: '11', name: '11 years or more' },
]

export const PROPERTY_TYPE_CHOICES = [
  { id: 'Single-Family', name: 'Single Family' },
  { id: 'Multi-Family (2-4 Units)', name: 'Multi-Family (2-4 Units)' },
  { id: 'Multi-Family (5+ Units)', name: 'Multi-Family (5+ Units)' },
  { id: 'Mobile Home', name: 'Mobile Home' },
  { id: 'Commercial', name: 'Commercial' },
]

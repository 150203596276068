var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import React, { Children, cloneElement, createElement } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import WithPermissions from '../auth/WithPermissions';
var RoutesWithLayout = function (_a) {
    var catchAll = _a.catchAll, children = _a.children, customRoutes = _a.customRoutes, dashboard = _a.dashboard, title = _a.title;
    var childrenAsArray = React.Children.toArray(children);
    var firstChild = childrenAsArray.length > 0 ? childrenAsArray[0] : null;
    return (_jsxs(Switch, { children: [customRoutes && customRoutes.map(function (route, key) { return cloneElement(route, { key: key }); }), Children.map(children, function (child) { return (_jsx(Route, { path: "/".concat(child.props.name), render: function (props) {
                    return cloneElement(child, __assign({ 
                        // The context prop instruct the Resource component to
                        // render itself as a standard component
                        intent: 'route' }, props));
                } }, child.props.name)); }), dashboard ? (_jsx(Route, { exact: true, path: "/", render: function (routeProps) { return (_jsx(WithPermissions, __assign({ authParams: {
                        route: 'dashboard',
                    }, component: dashboard }, routeProps))); } })) : firstChild ? (_jsx(Route, { exact: true, path: "/", 
                //@ts-ignore
                render: function () { return _jsx(Redirect, { to: window.getStorage().getItem('token') ? "/".concat(firstChild.props.name) : 'login' }); } })) : null, _jsx(Route, { render: function (routeProps) {
                    return createElement(catchAll, __assign(__assign({}, routeProps), { title: title }));
                } })] }));
};
export default RoutesWithLayout;

import { Divider, Grid, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CustomField from 'elements/field/CustomField'
import CheckboxInput from 'elements/input/CheckboxInput'
import DependentInput from 'elements/input/DependentInput'
import React, { useEffect, useState } from 'react'
import { SelectInput, TextInput, useTranslate } from 'react-admin'
import { useField, useForm } from 'react-final-form'
import { getIsValidEmail } from 'util/misc'
import { COBORROWER_PREFIX, DAYS_BY_MONTH, MONTHS, getYearChoices } from './constants'
import {
  MosaicDisclosureContentType,
  MosaicDisclosureContentWithCodeType,
  MosaicDisclosuresType,
  MosaicProductType,
} from './types'

type ChoiceType = {
  name: string
  id: string
}

type PropTypes = {
  disclosures: MosaicDisclosuresType[]
  productType: MosaicProductType
  requireFullSSN: boolean
}

const useStyles = makeStyles({
  applicantHeaderView: {},
  applicantHeaderText: {
    fontWeight: 900,
    fontSize: '18px',
  },
  applicantSection: {
    border: '1px solid #ececec',
    borderRadius: '5px',
    padding: '10px',
    width: '100%',
    marginBottom: '15px',
  },
  disclosureText: {
    color: 'rgb(177, 177, 177)',
    fontSize: '12px',
  },
})

const MosaicApplicationForm: React.FunctionComponent<PropTypes> = (props) => {
  const primaryDobMonth = useField('dob_month', { subscription: { value: true } }).input.value
  const secondaryDobMonth = useField(`${COBORROWER_PREFIX}dob_month`, { subscription: { value: true } }).input.value

  const getDayChoices = (isPrimary: boolean) => {
    const selectedMonth = isPrimary ? primaryDobMonth : secondaryDobMonth
    return selectedMonth &&
      !isNaN(parseInt(selectedMonth)) &&
      parseInt(selectedMonth) > 0 &&
      parseInt(selectedMonth) < 13
      ? // @ts-ignore
        DAYS_BY_MONTH[selectedMonth]
      : DAYS_BY_MONTH['none']
  }

  const isMobile = useMediaQuery('(max-width:800px)')
  const [primaryAppDayChoices, setPrimaryAppDayChoices] = useState<ChoiceType[]>(getDayChoices(true))
  const [secondaryAppDayChoices, setSecondaryAppDayChoices] = useState<ChoiceType[]>(getDayChoices(false))

  const translate = useTranslate()
  const form = useForm()
  const classes = useStyles()

  useEffect(() => {
    setPrimaryAppDayChoices(getDayChoices(true))
  }, [primaryDobMonth])

  useEffect(() => {
    setSecondaryAppDayChoices(getDayChoices(false))
  }, [secondaryDobMonth])

  // when we switch to requiring the full SSN, let's clear out the 4 digits they have entered and have them start again
  useEffect(() => {
    if (props.requireFullSSN) {
      form.change('ssn', '')
      if (form.getState().values[COBORROWER_PREFIX + 'ssn']) {
        form.change(COBORROWER_PREFIX + 'ssn', '')
      }
    }
  }, [props.requireFullSSN])

  const validatePhone = (raw: string, otherFields: any, meta: any) => {
    if (!raw) {
      return 'Please provide a phone number'
    } else {
      const justNumbers = raw.replace(/[^0-9]/g, '')
      if (justNumbers.length !== 10) return 'Invalid phone number'
    }
    return undefined
  }

  const validateEmail = (raw: string) => {
    const isValid = getIsValidEmail(raw)
    if (!raw) return 'Required Field'
    else if (!isValid) return 'Invalid Email Address'
    else return undefined
  }

  const parsePhone = (formatted: string) => {
    if (!formatted) return undefined
    else {
      const deletedParens = formatted.length === 4 && formatted.startsWith('(') && !formatted.endsWith(')')
      let justNumbers = formatted.replace(/[^0-9]/g, '')
      if (justNumbers.length > 0) {
        justNumbers = justNumbers.substring(0, 10)
        if (deletedParens) justNumbers = justNumbers.substring(0, 2)
      }
      return justNumbers
    }
  }

  const getCoAppDisclosureText = (): string | undefined => {
    let text
    props.disclosures.forEach((disc) => {
      if (disc.assetCode === 'COAPP') text = disc.contents[0].data
    })
    return text
  }

  const formatPhone = (phone: string) => {
    if (!phone) return null
    phone = phone.replace(/[^\d]/g, '')

    if (phone.length > 0) {
      let chunk1 = phone.substring(0, 3)
      let chunk2 = phone.substring(3, 6)
      let chunk3 = phone.substring(6)
      if (chunk1 && chunk2 && chunk3) {
        return `(${chunk1}) ${chunk2} - ${chunk3}`
      } else if (chunk1 && chunk2) {
        return `(${chunk1}) ${chunk2}`
      } else if (chunk1 && chunk1.length === 3) {
        return `(${chunk1})`
      } else return chunk1
    }
    return null
  }

  const parseSSN = (formatted: string) => {
    if (!formatted) return undefined
    else {
      let justNumbers = formatted.replace(/[^0-9]/g, '')
      if (justNumbers.length > 0) {
        justNumbers = justNumbers.substring(0, 9)
      }
      return justNumbers
    }
  }

  const parseSSNLast4 = (formatted: string) => {
    if (!formatted) return undefined
    else {
      let justNumbers = formatted.replace(/[^0-9]/g, '')
      if (justNumbers.length > 0) {
        justNumbers = justNumbers.substring(0, 4)
      }
      return justNumbers
    }
  }

  const formatSSN = (ssn: string) => {
    if (!ssn) return null
    ssn = ssn.replace(/[^\d]/g, '')

    if (ssn.length > 0) {
      let chunk1 = ssn.substring(0, 3)
      let chunk2 = ssn.substring(3, 5)
      let chunk3 = ssn.substring(5)
      if (chunk1 && chunk2 && chunk3) {
        return `${chunk1}-${chunk2}-${chunk3}`
      } else if (chunk1 && chunk2) {
        return `${chunk1}-${chunk2}`
      } else return chunk1
    }
    return null
  }

  const validateSSN = (ssn: string) => {
    if (!ssn) return 'Required'
    else if (ssn.length !== 9) return 'Invalid Social Security Numer'
    return undefined
  }

  const validateSSNLast4 = (ssn: string) => {
    if (!ssn) return 'Required'
    else if (ssn.length !== 4) return 'Please enter the last 4 digts of your Social Security Numer'
    return undefined
  }

  const parseIncome = (formatted: string) => {
    if (!formatted) return undefined
    else {
      let justNumbers = formatted.replace(/[^0-9,$]/g, '')
      if (justNumbers.length > 0) {
        justNumbers = justNumbers.substring(0, 9)
      }
      return justNumbers
    }
  }

  const getDisclosureTextByType = (disclosureType: string): MosaicDisclosureContentType[] => {
    if (props.disclosures) {
      let matched = props.disclosures.filter((disc) => disc.assetCode === disclosureType)
      return matched && matched.length > 0 ? matched[0].contents : []
    }
    return []
  }

  const getApplicantScopeDisclosures = (isSecondary: boolean): MosaicDisclosureContentWithCodeType[] => {
    if (props.disclosures) {
      let toReturn: MosaicDisclosureContentWithCodeType[] = []
      props.disclosures
        .filter((disc) => disc.scope === 'Applicant' && disc.assetCode !== 'COAPP')
        .forEach((disc) => {
          toReturn.push({
            assetCode: disc.assetCode,
            ...disc.contents[0],
          })
        })
      return toReturn
    }
    return []
  }

  const renderContactFields = (isSecondary: boolean) => {
    const fieldPrefix = isSecondary ? COBORROWER_PREFIX : ''

    return (
      <div className={classes.applicantSection}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div className={classes.applicantHeaderView}>
              <span className={classes.applicantHeaderText}>
                {isSecondary ? 'Co-Borrower Information' : 'Primary Borrower Information'}
              </span>
            </div>
          </Grid>
          <Grid item xs={6}>
            <CustomField
              name={`${fieldPrefix}first_name`}
              source="first_name"
              component={TextInput}
              fullWidth
              style={{ width: '100%' }}
              required={true}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomField
              name={`${fieldPrefix}family_name`}
              source="family_name"
              component={TextInput}
              fullWidth
              style={{ width: '100%' }}
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomField
              name={`${fieldPrefix}email`}
              source="email"
              label={translate('Email Address')}
              component={TextInput}
              fullWidth
              style={{ width: '100%' }}
              required={true}
              validate={validateEmail}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomField
              name={`${fieldPrefix}phone`}
              source="phone"
              label={translate('Mobile Phone')}
              component={TextInput}
              fullWidth
              style={{ width: '100%' }}
              validate={validatePhone}
              required={true}
              parse={parsePhone}
              format={formatPhone}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomField
              name={`${fieldPrefix}project_address_is_primary`}
              source="project_address_is_primary"
              label={translate('The site of installation is my primary address')}
              defaultValue={true}
              component={(checkboxProps: any) => <CheckboxInput {...checkboxProps} elStyle={{ maxWidth: '900px' }} />}
              fullWidth
              style={{ width: '100%' }}
            />
          </Grid>
          <DependentInput dependsOn={`${fieldPrefix}project_address_is_primary`} resolve={(val) => !val}>
            <>
              <Grid item xs={12}>
                <CustomField
                  name={`${fieldPrefix}primary_address`}
                  source="primary_address"
                  label={translate('Primary Street (no P.O. Boxes)')}
                  component={TextInput}
                  fullWidth
                  style={{ width: '100%' }}
                  required={true}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomField
                  name={`${fieldPrefix}primary_locality`}
                  source="primary_locality"
                  label={translate('Primary City')}
                  component={TextInput}
                  fullWidth
                  style={{ width: '100%' }}
                  required={true}
                />
              </Grid>
              <Grid item xs={3}>
                <CustomField
                  name={`${fieldPrefix}primary_state`}
                  source="primary_state"
                  label={translate('Primary State')}
                  component={TextInput}
                  fullWidth
                  style={{ width: '100%' }}
                  required={true}
                />
              </Grid>
              <Grid item xs={3}>
                <CustomField
                  name={`${fieldPrefix}primary_zip`}
                  source="primary_zip"
                  label={translate('Primary Zip')}
                  component={TextInput}
                  fullWidth
                  style={{ width: '100%' }}
                  required={true}
                />
              </Grid>
            </>
          </DependentInput>
          <Grid item xs={12}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <span style={{ fontWeight: 900 }}>Date of Birth</span>
              <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ flex: 1, padding: '0px 4px' }}>
                  <CustomField
                    name={`${fieldPrefix}dob_month`}
                    source="dob_month"
                    label={translate('Month')}
                    component={SelectInput}
                    choices={MONTHS}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                  />
                </div>
                <div style={{ flex: 1, padding: '0px 4px' }}>
                  <CustomField
                    name={`${fieldPrefix}dob_day`}
                    source="dob_day"
                    label={translate('Day')}
                    component={SelectInput}
                    choices={isSecondary ? secondaryAppDayChoices : primaryAppDayChoices}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                  />
                </div>
                <div style={{ flex: 1, padding: '0px 4px' }}>
                  <CustomField
                    name={`${fieldPrefix}dob_year`}
                    source="dob_year"
                    label={translate('Year')}
                    component={SelectInput}
                    choices={getYearChoices()}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomField
              name={`${fieldPrefix}income`}
              source="income"
              label={translate('Personal Income (annual)')}
              component={TextInput}
              fullWidth
              parse={parseIncome}
              style={{ width: '100%' }}
              required={true}
            />
          </Grid>
          {isMobile && (
            <Grid item xs={12}>
              {getDisclosureTextByType('INCOME')?.map((content: MosaicDisclosureContentType, i: number) => {
                return (
                  <div key={`${i}`} className={classes.disclosureText}>
                    {content.data?.replace('## Personal Income (annual)', '')}
                  </div>
                )
              })}
            </Grid>
          )}
          {props.requireFullSSN ? (
            <Grid item xs={12} sm={6}>
              <CustomField
                name={`${fieldPrefix}ssn`}
                source="ssn"
                label={translate('Social Security Number')}
                component={TextInput}
                fullWidth
                style={{ width: '100%' }}
                parse={parseSSN}
                format={formatSSN}
                validate={validateSSN}
                required={true}
              />
            </Grid>
          ) : (
            <Grid item xs={12} sm={6}>
              <CustomField
                name={`${fieldPrefix}ssn`}
                source="ssn"
                label={translate('Last 4 of Social Security Number')}
                component={TextInput}
                fullWidth
                style={{ width: '100%' }}
                parse={parseSSNLast4}
                validate={validateSSNLast4}
                required={true}
              />
            </Grid>
          )}
          {!isMobile && (
            <Grid item xs={12}>
              {getDisclosureTextByType('INCOME')?.map((content: MosaicDisclosureContentType, i: number) => {
                return (
                  <div key={`${i}`} className={classes.disclosureText}>
                    {content.data?.replace('## Personal Income (annual)', '')}
                  </div>
                )
              })}
            </Grid>
          )}
          <Grid item xs={12}>
            {getApplicantScopeDisclosures(isSecondary).map((disc, i: number) => {
              return (
                <div id={`${fieldPrefix}consent_${disc.assetCode}`}>
                  <CustomField
                    name={`${fieldPrefix}consent_${disc.assetCode}`}
                    source={`${fieldPrefix}consent_${disc.assetCode}`}
                    label={disc.data}
                    component={(checkboxProps: any) => (
                      <CheckboxInput {...checkboxProps} elStyle={{ maxWidth: '900px' }} />
                    )}
                    fullWidth
                    required
                    style={{ width: '100%' }}
                    key={i}
                  />
                </div>
              )
            })}
          </Grid>
          <Divider />
        </Grid>
      </div>
    )
  }
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <img width="150" height="27" src={window.PUBLIC_URL + '/images/Mosaic-Logo.png'} />
            <div style={{ margin: '20px 0px' }}>
              Tell us a bit more about yourself. Don't worry, this pre-qualification will not make a hard inquiry on
              your credit.
            </div>
          </div>
        </Grid>
        {renderContactFields(false)}
        {props.productType !== 'Zero' && (
          <Grid item xs={12}>
            <CustomField
              name="has_coborrower"
              source="has_coborrower"
              label={translate('Add a Co-Applicant')}
              component={CheckboxInput}
              fullWidth
              style={{ width: '100%' }}
            />
            <div className="small">{getCoAppDisclosureText()}</div>
          </Grid>
        )}
        <DependentInput dependsOn="has_coborrower" value={true}>
          <>{renderContactFields(true)}</>
        </DependentInput>

        <div className={classes.applicantSection}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className={classes.applicantHeaderView}>
                <span className={classes.applicantHeaderText}>{'Project Site Information'}</span>
              </div>
            </Grid>
            <Grid item xs={12}>
              <CustomField
                name="address"
                source="address"
                label={translate('Street (no P.O. Boxes)')}
                component={TextInput}
                fullWidth
                style={{ width: '100%' }}
                required={true}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomField
                name="locality"
                source="locality"
                label={translate('City')}
                component={TextInput}
                fullWidth
                style={{ width: '100%' }}
                required={true}
              />
            </Grid>
            <Grid item xs={3}>
              <CustomField
                name="state"
                source="state"
                component={TextInput}
                fullWidth
                style={{ width: '100%' }}
                required={true}
              />
            </Grid>
            <Grid item xs={3}>
              <CustomField
                name="zip"
                source="zip"
                component={TextInput}
                fullWidth
                style={{ width: '100%' }}
                required={true}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomField
                name="has_mailing_address"
                source="has_mailing_address"
                label={translate('I prefer to receive mail at a different address')}
                component={(checkboxProps: any) => <CheckboxInput {...checkboxProps} elStyle={{ maxWidth: '500px' }} />}
                fullWidth
                style={{ width: '100%' }}
              />
            </Grid>
            <DependentInput dependsOn="has_mailing_address" value={true}>
              <>
                <Grid item xs={12}>
                  <CustomField
                    name="mailing_address"
                    source="Mailing_address"
                    label={translate('Mailing Street (no P.O. Boxes)')}
                    component={TextInput}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomField
                    name="mailing_locality"
                    source="mailing_locality"
                    label={translate('Mailing City')}
                    component={TextInput}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <CustomField
                    name="mailing_state"
                    source="mailing_state"
                    label={translate('Mailing State')}
                    component={TextInput}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <CustomField
                    name="mailing_zip"
                    source="mailing_zip"
                    label={translate('Mailing Zip')}
                    component={TextInput}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                  />
                </Grid>
                {props.productType !== 'Zero' && (
                  <Grid item xs={12}>
                    <CustomField
                      name="other_income"
                      source="other_income"
                      label={translate('Other Household Income (annual)')}
                      component={TextInput}
                      fullWidth
                      parse={parseIncome}
                      style={{ width: '100%' }}
                      required={true}
                    />
                    {getDisclosureTextByType('OTHERINC')?.map((content: MosaicDisclosureContentType, i: number) => {
                      return (
                        <div key={i} className={classes.disclosureText}>
                          {content.data}
                        </div>
                      )
                    })}
                  </Grid>
                )}
              </>
            </DependentInput>
          </Grid>
        </div>
      </Grid>
    </div>
  )
}
export default MosaicApplicationForm

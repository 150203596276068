import { Alert, Box } from 'opensolar-ui'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type StyleProps = {
  alignItems?: 'center' | 'flex-end' | 'flex-start'
  disabled?: boolean
  isSelected: boolean
  textAlign?: 'left' | 'center' | 'right'
}

const useStyles = makeOpenSolarStyles((theme) => ({
  container: (props: StyleProps) => ({
    alignItems: props.alignItems || 'flex-start',
    alignSelf: 'stretch',
    borderRadius: '8px',
    background: '#FFF',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.18)',
    ...(props.isSelected
      ? {
          border: '2px solid #0094FF',
        }
      : {
          border: '1px solid #E6E6E6',
          margin: '1px',
        }),
    ...(props.disabled
      ? {
          cursor: 'not-allowed',
          opacity: 0.5,
        }
      : { cursor: 'pointer' }),
  }),
  title: {
    color: '#212121',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    margin: 0,
    textAlign: (props: StyleProps) => props.textAlign || 'left',
    width: '100%',
  },
  description: {
    color: '#4D4D4D',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    margin: 0,
    textAlign: (props: StyleProps) => props.textAlign || 'left',
    width: '100%',
  },
  chipText: {
    background: '#EDF3FF',
    borderRadius: '4px',
    color: '#2C499D',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '16px',
    padding: '10px',
  },
  iconContainer: {
    svg: {
      display: 'flex',
      padding: '10px',
    },
  },
}))

export type SelectionType<T = string> = {
  chipText?: string
  description: string
  icon?: React.ReactNode
  key: T
  title: string
}

interface BaseSelectableCardProps {
  alignItems?: 'center' | 'flex-end' | 'flex-start'
  cardKey: string | number
  childGap?: number
  disabled?: boolean
  gap?: number
  isSelected: boolean
  onSelect: (cardKey: string | number) => void
  render?: React.ReactNode
  textAlign?: 'left' | 'center' | 'right'
}

interface SelectableCardProps extends BaseSelectableCardProps {
  chipText?: string
  description?: string
  icon?: React.ReactNode
  title: string | React.ReactNode
  warningText?: string
}

interface CustomSelectableCard extends BaseSelectableCardProps {
  render: React.ReactNode
}

const SelectableCard: React.FC<SelectableCardProps | CustomSelectableCard> = (props) => {
  const classes = useStyles({
    alignItems: props.alignItems,
    disabled: props.disabled,
    isSelected: props.isSelected,
    textAlign: props.textAlign,
  })

  const { cardKey, childGap, disabled = false, gap = 12, onSelect } = props
  let child: React.ReactNode

  if (props.render) {
    child = props.render
  } else {
    const { chipText, description, icon, title, warningText } = props as SelectableCardProps

    child = (
      <>
        {icon && <span className={classes.iconContainer}>{icon}</span>}
        <Box display="flex" flexDirection="column" gridGap={childGap || gap}>
          <p className={classes.title}>{title}</p>
          {description && <p className={classes.description}>{description}</p>}
          {warningText && <Alert severity="warning">{warningText}</Alert>}
          {chipText && <span className={classes.chipText}>{chipText}</span>}
        </Box>
      </>
    )
  }

  return (
    <Box
      className={classes.container}
      display="flex"
      flex={1}
      gridGap={gap}
      padding="16px"
      position="relative"
      onClick={() => !disabled && onSelect(cardKey)}
    >
      {child}
    </Box>
  )
}

export default SelectableCard

import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreOutlined'
import { orgSelectors } from 'ducks/orgs'
import CustomFieldsIcon from 'icons/CustomFields.svg'
import React, { useMemo } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import CustomFieldsContent, { getCustomFields } from './CustomFieldsContent'

const useStyles = makeStyles((theme) => ({
  content: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  wrapper: {
    maxHeight: 300,
    overflowY: 'auto',
    paddingBottom: 40,
  },
  iconTitleWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  headingTitle: {
    margin: '0 10px',
  },
}))

const CustomFieldsAccordion = () => {
  const model = 'Project'
  const org = useSelector(orgSelectors.getOrg)
  const customFields = useMemo(() => getCustomFields(org?.custom_fields, model), [org])
  const classes = useStyles()
  const translate = useTranslate()
  if (customFields.length === 0) {
    return null
  }
  return (
    <Accordion key="custom-fields" defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="expansion-content-custom-fields"
        id="expansion-header-custom-fields"
        classes={{ content: classes.content }}
      >
        <div className={classes.iconTitleWrapper}>
          <img style={{ width: 24, height: 24 }} src={CustomFieldsIcon} alt="Custom-Fields-Icon" />
          <span className={classes.headingTitle}>{translate('Custom Fields')}</span>
        </div>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.wrapper }}>
        <CustomFieldsContent model={model} style={{ margin: 0, width: '100%', marginTop: 10 }} />
      </AccordionDetails>
    </Accordion>
  )
}

export default CustomFieldsAccordion

import OrgContentEdit from './ContentEdit'

export default {
  edit: OrgContentEdit,
  options: {
    edit: {
      title: 'Business Information',
      subtitle: 'Provide key information about your business that will be included in your custom proposal.',
      breadCrumb: 'Business Information',
    },
  },
}

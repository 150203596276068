import { HARDWARE_SUPPLIER_CONFIG_MAPPING } from 'pages/ordering/configs'
import { DISTRBUTOR_KEY_TO_NAME_MAPPING } from '../constants'
import { getHardwareSectionPreOrderStyles } from '../styles'
import DistributorLogo from './DistributorLogo'
import { useTranslate } from 'ra-core'

type Props = {
  distributorKey: string
  sortOrder: number
}

const AccordionCardTitle = ({ distributorKey, sortOrder }: Props) => {
  const sectionStyles = getHardwareSectionPreOrderStyles()
  const config = HARDWARE_SUPPLIER_CONFIG_MAPPING[distributorKey]
  const translate = useTranslate()
  return (
    <div className={sectionStyles.row} style={{ gap: '16px' }}>
      <span
        className={sectionStyles.accordionHeaderTitle}
      >{translate(`${sortOrder}. Supplied by ${DISTRBUTOR_KEY_TO_NAME_MAPPING[distributorKey]}`)}</span>
      <DistributorLogo url={config.logoUrl} />
    </div>
  )
}

export default AccordionCardTitle

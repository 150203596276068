import { Paper, makeStyles } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import { TinyLabel } from 'elements/TinyLabel'
import { Button } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'

interface PropTypes {
  disabled: boolean
  onClick: () => void
}

const useStyles = makeStyles((theme: OpenSolarThemeType) => ({
  wrapper: {
    padding: 10,
    marginBottom: 10,
  },
  heading: {
    color: theme.greyMid1,
    fontSize: 12,
    fontWeight: theme.typography.fontWeightBold,
    margin: 0,
    marginBottom: 10,
  },
  overrideBtn: { marginTop: 10 },
}))

const PriceLock: React.FC<PropTypes> = ({ disabled, onClick }) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Paper className={classes.wrapper}>
      <p className={classes.heading}>{translate('PRICING LOCKED')}</p>
      <TinyLabel>
        {translate(
          'Based on the Stage of the project, the pricing, incentives, and payment options are locked on this project. You can override it for this system by clicking the button below.'
        )}
      </TinyLabel>
      <Button className={classes.overrideBtn} variant="contained" color="default" disabled={disabled} onClick={onClick}>
        <span>{translate('Override Pricing Lock')}</span>
      </Button>
    </Paper>
  )
}

export default PriceLock

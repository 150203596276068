import { FormApi } from 'final-form'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import restClient from 'restClient'
import { LoadZoneData } from 'types/mcs'

// Loading is currently only implemented for UK orgs but this could be extended to other regions in future

export const GET_LOAD_ZONE_DATA = 'GET_LOAD_ZONE_DATA'
export const GET_LOAD_ZONE_DATA_SUCCESS = 'GET_LOAD_ZONE_DATA_SUCCESS'
export const GET_LOAD_ZONE_DATA_FAIL = 'GET_LOAD_ZONE_DATA_FAIL'

export type LoadZoneDataStoreType = {
  loadZoneData: LoadZoneData | undefined
  loading: boolean
  ready: boolean
  error?: string
}

const initialState: LoadZoneDataStoreType = {
  loadZoneData: undefined,
  loading: false,
  ready: false,
  error: undefined,
}

export default (state: LoadZoneDataStoreType = initialState, action: ReduxActionType) => {
  switch (action.type) {
    case GET_LOAD_ZONE_DATA:
      return {
        ...state,
        loading: true,
        orgId: action.payload.orgId,
        projectId: action.payload.projectId,
        error: undefined,
      }
    case GET_LOAD_ZONE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        loadZoneData: action?.payload?.loadZoneData,
        ready: true,
      }
    case GET_LOAD_ZONE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        ready: false,
        error: action.payload,
      }
  }
  return state
}

export interface ReduxActionType {
  type: string
  payload?: any
  form?: FormApi
}

export function* loadZoneDataSaga(action: ReduxActionType) {
  const form: FormApi = action.form || window.projectForm
  try {
    const loadZoneData = yield call(doGetLoadZoneData, form)
    saveLoadZoneData(loadZoneData, form)
    yield put({ type: GET_LOAD_ZONE_DATA_SUCCESS, payload: { loadZoneData: loadZoneData } })
  } catch (e) {
    //@ts-ignore
    const errorMsg = e.message || e.toString?.()
    yield put({ type: GET_LOAD_ZONE_DATA_FAIL, key: action.payload.key, payload: errorMsg })
  }
}

export const doGetLoadZoneData = (form: FormApi) => {
  const projectData = form.getState().values
  // if (projectData.custom_data?._mcs) return projectData.custom_data._mcs
  return new Promise((resolve, reject) => {
    const restClientInstance = restClient(window.API_ROOT + '/api')
    restClientInstance('CUSTOM_GET', 'loadZoneData', {
      url: 'projects/' + projectData.id + '/structural/get_load_zones/',
    })
      .then((response) => {
        resolve(response.data.result)
      })
      .catch((err: any) => {
        console.log('err', err)
        reject(err)
      })
  })
}

export const loadLoadZoneData = (orgId: number, projectId: number) => {
  return {
    type: GET_LOAD_ZONE_DATA,
    payload: {
      orgId,
      projectId,
    },
  }
}

export const loadZoneDataSelectors = {
  getIsLoading: (state: any): boolean => !!state.loadZoneData?.loading,
  getIsReady: (state: any): boolean => !!state.loadZoneData?.ready,
  getLoadZoneData: (state: any): LoadZoneData[] => state.loadZoneData.loadZoneData,
}

export function* loadZoneDataSagas() {
  yield all([takeEvery(GET_LOAD_ZONE_DATA, loadZoneDataSaga)])
}

function saveLoadZoneData(loadZoneData, form: FormApi) {
  const formValues = form.getState().values
  var customData = formValues.custom_data
  if (!customData) customData = formValues.custom_data = {}
  if (!customData._mcs) customData._mcs = {}

  if (loadZoneData) {
    var mcsData = customData._mcs
    if (typeof loadZoneData.distance_to_city !== 'undefined')
      // This is an estimate which users may want to change at their discretion
      mcsData.terrain_category = loadZoneData.distance_to_city < 50 ? 'Urban' : 'Country'
    else mcsData.terrain_category = undefined

    if (typeof loadZoneData.elevation !== 'undefined')
      mcsData.altitude = loadZoneData.elevation && Math.round(loadZoneData.elevation)
    else mcsData.altitude = undefined

    if (typeof loadZoneData.snow_zone !== 'undefined')
      mcsData.snow_zone = loadZoneData.snow_zone && loadZoneData.snow_zone.toString()
    else mcsData.snow_zone = undefined

    if (typeof loadZoneData.wind_zone !== 'undefined')
      mcsData.wind_zone = loadZoneData.wind_zone && loadZoneData.wind_zone.toString()
    else mcsData.wind_zone = undefined

    if (typeof loadZoneData.distance_to_sea !== 'undefined')
      mcsData.distance_from_sea =
        loadZoneData.distance_to_sea && loadZoneData.distance_to_sea < 2
          ? 0
          : loadZoneData.distance_to_sea < 20
          ? 2
          : 20
    else mcsData.distance_from_sea = undefined

    form.mutators.updateField('custom_data._mcs', mcsData)
    if (form.mutators.markFieldAsDirty) form.mutators.markFieldAsDirty('custom_data._mcs')
  }
}

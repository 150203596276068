import _ from 'lodash'
import ApplyNowPrompt from 'projectSections/sections/proposal/applyNowPrompt'
import ForceCreditAppWrapper from 'projectSections/sections/proposal/ForceCreditAppWrapper'
import React, { PureComponent } from 'react'
import { detectBespokeProposalTemplate, DialogHelper } from '../util/misc'
import { Header } from './header'
import MainContentComponent from './mainContent'
import { SelectionComponent } from './selectionComponent'

const Bottom_Navigation_Height = 65

const styles = {
  mobile: {
    header: {
      height: '65px',
    },
    body: {},
    main: {
      flexGrow: 1,
      zIndex: 1,
    },
    unfixedSelection: {
      borderTop: '1px solid #D3D3D3',
      borderBottom: '1px solid #D3D3D3',
      boxShadow: '0px 3px 12px rgba(87, 87, 87, 0.15)',
    },
    fixedSelection: {
      backgroundColor: '#FFFFFF',
      border: '1px solid #D3D3D3',
      boxShadow: '0px 3px 12px rgba(87, 87, 87, 0.15)',
      position: 'fixed',
      width: '100%',
      top: 0,
      zIndex: 101,
    },
  },
  standard: {
    header: {
      height: '100px',
      borderBottom: '1px solid #D3D3D3',
      marginRight: 20,
      marginLeft: 20,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    body: {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    main: {
      flexGrow: 1,
      width: 'calc(100% - 280px)',
      zIndex: 1,
    },
    unfixedSelection: {
      borderLeft: '1px solid #D3D3D3',
      width: 280,
      height: '100%',
      padding: 0,
    },
    fixedSelection: {},
  },
}

export default class BasicLayout extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      activeDialog: props.activeDialog ? props.activeDialog : null,
      dialogData: props.dialogData ? props.dialogData : null,
      dialogIsFullWidth: false,
      isFixedHeader: false,
    }
    this.actionRef = React.createRef()
    this.handleScrollThrottled = _.throttle(this.handleScroll.bind(this), 300)
  }

  showDialog(dialogName, dialogData, isFullWidth = false) {
    DialogHelper.afterOpen()
    this.setState({ activeDialog: dialogName, dialogData: dialogData, dialogIsFullWidth: isFullWidth })
  }

  closeDialog() {
    DialogHelper.beforeClose()
    this.setState({ activeDialog: null, dialogData: null })
  }

  componentDidMount() {
    window.$(window).on('scroll', this.handleScrollThrottled)
  }

  componentWillUnmount() {
    window.$(window).unbind('scroll', this.handleScrollThrottled)
  }

  componentWillReceiveProps(nextProps) {
    // Simplistic method of closing any open dialogs, particularly EditUsage dialog
    // If moving from updating to not-updating, close dialog
    if (this.props.updating === true && nextProps.updating === false) {
      this.setState({ activeDialog: null })
    }
  }

  // TODO: can we fix the header by setting css position to sticky?
  handleScroll = () => {
    const { isMobile } = this.props
    // Handle the case where header is not shown, use first element instead
    const headerElement = document.getElementById('mye-container2')?.firstChild
    if (!headerElement) {
      // header element not found
      return
    }
    const headerOffSetTop = headerElement.getBoundingClientRect().top
    const style = isMobile ? styles.mobile : styles.standard
    const headerClientHeight = parseInt(style.header.height, 10)
    const isFixedHeader = isMobile
      ? headerOffSetTop + headerClientHeight <= -40
      : headerOffSetTop + headerClientHeight <= 0
    if (isFixedHeader !== this.state.isFixedHeader)
      this.setState({
        isFixedHeader,
      })
  }

  render() {
    const {
      isMobile,
      onSystemChange,
      onPaymentMethodChange,
      proposalData,
      proposalDataRaw,
      setSelectedViewUuid,
      layout,
      isPro,
      setWaitingForAction,
      forceCreditAppOpenPmtId,
    } = this.props

    const { isBespoke, showHeader, showSidebar } = detectBespokeProposalTemplate(proposalData.proposalTemplateSettings)

    const style = isMobile ? styles.mobile : styles.standard
    const { isFixedHeader, activeDialog, dialogData, dialogIsFullWidth } = this.state
    const selectionContainerStyle = isFixedHeader ? style.fixedSelection : style.unfixedSelection
    const extraMarginRight = showSidebar && isFixedHeader && !isMobile ? '280px' : 0
    const actionHeight = (this.actionRef.current && this.actionRef.current.clientHeight) || 0
    const marginBottomForMobile = isPro ? actionHeight + Bottom_Navigation_Height : Bottom_Navigation_Height
    const marginBottomForDesktop = isPro ? Bottom_Navigation_Height : 0
    const extraMarginBottom = isMobile ? marginBottomForMobile : marginBottomForDesktop

    const extraHeight = isFixedHeader && isMobile && 140

    const attachedPhoenixPaymentOption = proposalData?.selectedProject?.proposal_data?.systems?.find(
      (sys) => sys?.data?.uuid === proposalData.selectedSystem.uuid
    )?.attached_phoenix_payment_option

    let quotationConfiguration = {}
    try {
      quotationConfiguration =
        (proposalData.proposalTemplateSettings &&
          proposalData.proposalTemplateSettings.quotation_table_configuration &&
          proposalData.proposalTemplateSettings.quotation_table_configuration.length > 0 &&
          JSON.parse(proposalData.proposalTemplateSettings.quotation_table_configuration)) ||
        {}
    } catch (e) {
      console.warn(e)
    }

    return (
      <div id="mye-container2">
        {showHeader && (
          <div id="mye-header" style={style.header}>
            <Header
              proposalData={proposalData}
              isMobile={isMobile}
              phoenixReference={attachedPhoenixPaymentOption?.integration_external_reference}
            />
          </div>
        )}
        <div style={style.body}>
          {showSidebar && <div style={{ height: extraHeight }} />}
          {showSidebar && (
            <div
              style={
                !isMobile && isFixedHeader
                  ? {
                      width: '100vw',
                      maxWidth: isBespoke ? '100%' : 1024,
                      height: '100vh',
                      position: 'fixed',
                      top: 0,
                      left: '50%',
                      transform: 'translate(-50%, 0)',
                    }
                  : {}
              }
            >
              <div
                style={
                  !isMobile && isFixedHeader
                    ? {
                        borderLeft: '1px solid #D3D3D3',
                        position: 'absolute',
                        width: 280,
                        height: '100vh',
                        top: 0,
                        right: 0,
                        padding: 0,
                        zIndex: 101,
                      }
                    : { ...selectionContainerStyle }
                }
              >
                <SelectionComponent
                  isMobile={isMobile}
                  ref={this.actionRef}
                  isPro={isPro}
                  isMobileFixedSelection={isFixedHeader && isMobile}
                  isFixedHeader={isFixedHeader}
                  proposalData={proposalData}
                  onPaymentMethodChange={onPaymentMethodChange}
                  onSystemChange={onSystemChange}
                  quotationConfiguration={quotationConfiguration}
                  showDialog={this.showDialog.bind(this)}
                  setWaitingForAction={setWaitingForAction}
                  attachedPhoenixPaymentOption={attachedPhoenixPaymentOption}
                />
              </div>
            </div>
          )}
          <div
            id="main-content-wrapper"
            style={{
              ...style.main,
              marginRight: extraMarginRight,
              marginBottom: extraMarginBottom,

              // Disable to allow interactivity with embedded studio
              // Beware: We need to re-enable pointer events on all interactive children
              pointerEvents: 'none',
            }}
          >
            <MainContentComponent
              proposalData={proposalData}
              proposalDataRaw={proposalDataRaw}
              onPaymentMethodChange={onPaymentMethodChange}
              activeDialog={activeDialog}
              dialogData={dialogData}
              dialogIsFullWidth={dialogIsFullWidth}
              showDialog={this.showDialog.bind(this)}
              closeDialog={this.closeDialog.bind(this)}
              setSelectedViewUuid={setSelectedViewUuid}
              isMobile={isMobile}
              layout={layout}
              quotationConfiguration={quotationConfiguration}
              isPro={isPro}
            />
          </div>
        </div>
        {forceCreditAppOpenPmtId && (
          <ForceCreditAppWrapper
            paymentOptionId={forceCreditAppOpenPmtId}
            projectId={proposalData.selectedProject.id}
            systemUuid={proposalData.selectedSystem.uuid}
            proposalData={proposalData}
            openPlentiDialog={this.showDialog.bind(this)}
          />
        )}
        {!isMobile && (
          <ApplyNowPrompt
            system={proposalData?.selectedSystem}
            quotationConfiguration={quotationConfiguration}
            layout={this.props}
            proposalData={proposalData}
          />
        )}
      </div>
    )
  }
}

import appStorage from 'storage/appStorage'

const CAPACITY = 20
// Least Recently Used hardware filter cache store
// Since the capacity is very low there is no need to build a complex data structure like double linked list

const LRU_FILTER_LOCAL_STORAGE_KEY = 'lru_filter_cache'

export const getLastRecentUsedFilters = (): string[] => {
  const lruFilters = appStorage.getJSON<string[]>(LRU_FILTER_LOCAL_STORAGE_KEY)
  return lruFilters || []
}

export const removeFilterFromLastRecentUsedList = (key: string) => {
  const lruFilters = getLastRecentUsedFilters()
  const index = lruFilters.indexOf(key)
  if (index !== -1) {
    lruFilters.splice(index, 1)
    appStorage.setJSON(LRU_FILTER_LOCAL_STORAGE_KEY, lruFilters)
  }
}

export const addFilterToLastRecentUsedList = (key: string) => {
  const lruFilters = getLastRecentUsedFilters()
  const index = lruFilters.indexOf(key)
  if (index !== -1) {
    lruFilters.splice(index, 1)
  }
  lruFilters.unshift(key)
  if (lruFilters.length > CAPACITY) {
    lruFilters.length = CAPACITY
  }
  appStorage.setJSON(LRU_FILTER_LOCAL_STORAGE_KEY, lruFilters)
}

import { MenuItem, Select, Tooltip, useMediaQuery } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import { useTranslate } from 'ra-core'
import React, { ChangeEvent } from 'react'
import { useFormState } from 'react-final-form'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { AvailableFinanceIntegrationType } from 'types/orgs'
import { Theme } from 'types/themes'
import { useGetSortedIntegrations } from '../hooks'
import IntegrationFilterButton from './IntergrationFilterButton'

const useStyles = makeOpenSolarStyles((theme) => ({
  headerRow: {
    display: 'flex',
    alignItems: 'center',
  },
  headerText: {
    color: theme.greyMid1,
    marginLeft: '10px',
  },
  tooltipWrapper: {
    marginLeft: '6px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'flex-end',
  },
  promoRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  button: {
    margin: '10px',
    padding: '15px',
    cursor: 'pointer',
  },
  selectedButton: {
    margin: '10px',
    padding: '15px',
    cursor: 'pointer',
    border: '1px solid black',
  },
  mobileSelectionWrapper: {
    width: '250px',
    margin: '10px',
  },
}))

type PropTypes = {
  setTargetIntegrations: (newVal: string[]) => void
  targetIntegrations: string[]
  onInactiveClick: (integration: AvailableFinanceIntegrationType) => void
  hideFilterText?: boolean
  hideChecks?: boolean
  isPromo?: boolean
}

const IntegrationFilterButtons: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const classes = useStyles()
  const project = useFormState().values
  const sortedIntegrations = useGetSortedIntegrations(project.is_residential, !project.is_residential, false, false)
  const sortedActiveIntegrations = useGetSortedIntegrations(
    project.is_residential,
    !project.is_residential,
    false,
    true
  )
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const onClick = (clickedIntegration: string) => {
    let newVal = [...props.targetIntegrations]
    if (!newVal.includes(clickedIntegration)) newVal.push(clickedIntegration)
    else newVal = newVal.filter((targetIntegration) => targetIntegration !== clickedIntegration)

    props.setTargetIntegrations(newVal)
  }

  if (!sortedIntegrations?.length) return null
  return (
    <div>
      <div className={classes.headerRow}>
        {!props.hideFilterText && (
          <>
            <div className={classes.headerText}>{translate('View by Integrated Options')}</div>
            <div className={classes.tooltipWrapper}>
              <Tooltip
                title={translate(
                  'OpenSolar Integrated Finance Partners provide the best options for homebuyers to pay as they go and help OpenSolar stay free for all.'
                )}
                interactive={true}
                arrow
                placement="top"
              >
                <InfoOutlined htmlColor="rgb(117, 117, 117)" fontSize="small" />
              </Tooltip>
            </div>
          </>
        )}
      </div>
      {!isMobile && (
        <div className={classes.promoRow}>
          {sortedIntegrations?.map((integration, i) => (
            <IntegrationFilterButton
              integration={integration}
              key={integration.integration_name}
              onClick={() => onClick(integration.integration_name)}
              onInactiveClick={() => props.onInactiveClick(integration)}
              isSelected={props.targetIntegrations?.includes(integration.integration_name)}
              hideChecks={props.hideChecks}
              index={i}
              isPromo={props.isPromo}
            />
          ))}
        </div>
      )}
      {isMobile && (
        <div className={classes.mobileSelectionWrapper}>
          <Select
            value={props.targetIntegrations?.length ? props.targetIntegrations[0] : undefined}
            onChange={(e: ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
              onClick(e.target.value as string)
            }
            fullWidth={true}
          >
            <MenuItem value={undefined}>---</MenuItem>
            {sortedActiveIntegrations?.map((integration) => (
              <MenuItem key={integration.integration_name} value={integration.integration_name}>
                <div>{integration.display_name}</div>
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
    </div>
  )
}
export default IntegrationFilterButtons

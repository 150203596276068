var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import { SnackbarContent, withStyles } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { CloudDownload } from '@material-ui/icons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import { Button } from 'opensolar-ui';
import { useTranslate } from 'ra-core';
var variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};
var styles = {
    success: {
        backgroundColor: 'rgba(66, 195, 17, 0.95)',
        borderRadius: 5,
        padding: '6px 12px',
    },
    error: {
        backgroundColor: 'rgba(193, 42, 46, 0.95)',
        borderRadius: 5,
        padding: '6px 12px',
    },
    info: {
        backgroundColor: 'rgba(35, 87, 137, 0.95)',
        borderRadius: 5,
        padding: '6px 12px',
    },
    warning: {
        backgroundColor: 'rgba(255,160,0,0.95)',
        borderRadius: 5,
        padding: '6px 12px',
    },
    icon: {
        fontSize: 20,
        opacity: 0.9,
        marginRight: 10,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
};
var StyledSnackbarContent = withStyles({
    message: {
        width: '100%',
    },
})(SnackbarContent);
var PureNotification = function (props) {
    var open = props.open, type = props.type, message = props.message, buttons = props.buttons, id = props.id, isInteractive = props.isInteractive, handleRequestClose = props.handleRequestClose, rest = __rest(props, ["open", "type", "message", "buttons", "id", "isInteractive", "handleRequestClose"]);
    var translate = useTranslate();
    var Icon = variantIcon[type];
    var buttonClick = function (b) {
        b.action();
        if (!b.avoidClose)
            handleRequestClose();
    };
    var getIcon = function (icon) {
        switch (icon) {
            case 'CloudDownload':
                return _jsx(CloudDownload, {});
            default:
                return icon;
        }
    };
    var onCloseButtonClicked = function (e) {
        e.stopPropagation();
        handleRequestClose();
    };
    return (_jsx(Snackbar, __assign({ id: id, open: open, anchorOrigin: { vertical: 'top', horizontal: 'center' }, style: { zIndex: 2600 } }, rest, { children: _jsx(StyledSnackbarContent, { "aria-describedby": "client-snackbar", style: __assign(__assign({}, styles[type]), { display: !!message ? 'flex' : 'none' }), onClick: isInteractive ? null : handleRequestClose, message: _jsxs("span", __assign({ id: "client-snackbar", style: styles.message }, { children: [!!Icon && _jsx(Icon, { style: styles.icon }), !!message && translate(message), _jsx(_Fragment, { children: buttons &&
                            buttons.map(function (button) {
                                return (_jsx(Button, __assign({ style: { marginLeft: '16px', marginRight: '10px' }, size: "small", onClick: function () {
                                        buttonClick(button);
                                    }, variant: button.variant || 'contained', startIcon: getIcon(button.icon) }, { children: button.label })));
                            }) }), _jsx(CloseIcon, { style: { fontSize: 20, cursor: 'pointer', marginLeft: 'auto' }, onClick: onCloseButtonClicked })] })) }) })));
};
export default PureNotification;

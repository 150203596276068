import { TextField } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import React, { ChangeEvent } from 'react'
import { useDebouncedCallback, useTranslate } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  row: {
    padding: '0px 10px 10px 10px',
  },
  filterIcon: {
    color: theme.greyMid1,
  },
}))

type PropTypes = {
  setSearch: (searchString: string) => void
}

const PaymentOptionSearch: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    doDebouncedOnFieldChange(e.target.value)
  }

  const doDebouncedOnFieldChange = useDebouncedCallback((val: string) => {
    props.setSearch(val)
  }, 500)

  return (
    <div className={classes.row}>
      <TextField
        onChange={handleChange}
        placeholder={translate('Search')}
        variant="outlined"
        InputProps={{
          startAdornment: <SearchIcon />,
        }}
        fullWidth
        size="small"
      />
    </div>
  )
}
export default PaymentOptionSearch

import { Tooltip } from '@material-ui/core'
import { CheckCircleOutlined } from '@material-ui/icons'
import { Chip } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type PropTypes = {
  chipText?: string
  tooltipText?: string
}

const useStyles = makeOpenSolarStyles((theme) => ({
  systemSoldChip: {
    marginLeft: 10,
  },
}))

const SoldChip: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Tooltip title={props.tooltipText || ''}>
      <Chip
        className={classes.systemSoldChip}
        label={translate(props.chipText || 'Sold')}
        color="success"
        size="medium"
        icon={<CheckCircleOutlined />}
      />
    </Tooltip>
  )
}
export default SoldChip

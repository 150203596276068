import composeUrlWithQueryParams from './composeUrlWithQueryParams'
import { NullableParamValueType, ParamValueType } from './types'

type OSResourceFetchPathType = {
  resource: string
  orgId: number
  params?: { [key in string]: ParamValueType }
  optionalParams?: { [key in string]: NullableParamValueType }
}

/**
 * Format URL to request os resource data
 *
 * @param {Object} params - The object containing mandatory key-value pairs to be converted.
 * @param {Object} optionalParams - The object containing nullable value, however nullable values will be removed.
 * @param {number} orgId - Current org id.
 * @param {string} resource - OS resource name.
 * @returns {string} - The formatted query string.
 */
const composeOSResourceFetchPath = ({ orgId, resource, params, optionalParams }: OSResourceFetchPathType): string => {
  const baseUrl = `orgs/${orgId}/${resource}/`
  return composeUrlWithQueryParams({ baseUrl, params, optionalParams })
}

export default composeOSResourceFetchPath

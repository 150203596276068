import { StudioSignalTypes } from 'types/studio/signals'

// Allows signal handlers to be added before studio has finished loading
export function applyStudioSignals(signals: StudioSignalTypes[], handler: () => void) {
  const attemptApply = () => {
    if (!window.editor) {
      // Editor still loading
      requestAnimationFrame(attemptApply)
      return
    }
    for (const signal of signals) {
      window.editor.signals[signal].add(handler)
    }
  }
  attemptApply()
}

export function removeStudioSignals(signals: StudioSignalTypes[], handler: () => void) {
  for (const signal of signals) {
    window.editor.signals[signal].remove(handler)
  }
}

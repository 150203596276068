var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export var DataOutlineIcon = function (_a) {
    var variant = _a.variant, _b = _a.size, size = _b === void 0 ? '1rem' : _b, _c = _a.color, color = _c === void 0 ? 'currentColor' : _c, rest = __rest(_a, ["variant", "size", "color"]);
    var iconAttributes = {
        width: size,
        height: size,
        stroke: color,
    };
    switch (variant) {
        case 2:
            return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 22 22", fill: "none" }, rest, iconAttributes, { children: _jsx("path", { d: "M16.7609 10.9999C16.7609 12.0603 17.6205 12.9199 18.6809 12.9199C19.7412 12.9199 20.6009 12.0603 20.6009 10.9999C20.6009 9.93952 19.7412 9.0799 18.6809 9.0799C17.6205 9.0799 16.7609 9.93952 16.7609 10.9999ZM16.7609 10.9999H11.6009M16.7609 3.3199C16.7609 4.38029 17.6205 5.2399 18.6809 5.2399C19.7412 5.2399 20.6009 4.38029 20.6009 3.3199C20.6009 2.25952 19.7412 1.3999 18.6809 1.3999C17.6205 1.3999 16.7609 2.25952 16.7609 3.3199ZM16.7609 3.3199H5.60039M16.7609 18.6799C16.7609 19.7403 17.6205 20.5999 18.6809 20.5999C19.7412 20.5999 20.6009 19.7403 20.6009 18.6799C20.6009 17.6195 19.7412 16.7599 18.6809 16.7599C17.6205 16.7599 16.7609 17.6195 16.7609 18.6799ZM16.7609 18.6799H11.2409C10.7107 18.6799 10.2809 18.2501 10.2809 17.7199V4.2799M5.24039 3.3199C5.24039 4.38029 4.38078 5.2399 3.32039 5.2399C2.26 5.2399 1.40039 4.38029 1.40039 3.3199C1.40039 2.25952 2.26 1.3999 3.32039 1.3999C4.38078 1.3999 5.24039 2.25952 5.24039 3.3199Z", stroke: color, strokeWidth: "2", strokeLinecap: "round" }) })));
        case 3:
            return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", fill: "none" }, rest, iconAttributes, { children: _jsx("path", { d: "M17.7609 4.3199C17.7609 5.38029 18.6205 6.2399 19.6809 6.2399C20.7412 6.2399 21.6009 5.38029 21.6009 4.3199C21.6009 3.25952 20.7412 2.3999 19.6809 2.3999C18.6205 2.3999 17.7609 3.25952 17.7609 4.3199ZM17.7609 4.3199H6.60039M17.7609 19.6799C17.7609 20.7403 18.6205 21.5999 19.6809 21.5999C20.7412 21.5999 21.6009 20.7403 21.6009 19.6799C21.6009 18.6195 20.7412 17.7599 19.6809 17.7599C18.6205 17.7599 17.7609 18.6195 17.7609 19.6799ZM17.7609 19.6799H12.2409C11.7107 19.6799 11.2809 19.2501 11.2809 18.7199V5.2799M17.4009 11.9999H11.4009M21.6009 11.9999C21.6009 13.0603 20.7412 13.9199 19.6809 13.9199C18.6205 13.9199 17.7609 13.0603 17.7609 11.9999C17.7609 10.9395 18.6205 10.0799 19.6809 10.0799C20.7412 10.0799 21.6009 10.9395 21.6009 11.9999ZM6.24039 4.3199C6.24039 5.38029 5.38078 6.2399 4.32039 6.2399C3.26 6.2399 2.40039 5.38029 2.40039 4.3199C2.40039 3.25952 3.26 2.3999 4.32039 2.3999C5.38078 2.3999 6.24039 3.25952 6.24039 4.3199Z", stroke: color, strokeWidth: "2", strokeLinecap: "round" }) })));
    }
};

// @ts-nocheck
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import { Button } from 'opensolar-ui'
import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { useTranslate } from 'react-admin'
import useTranslateParse from '../../../util/useTranslateParse'
import ToolTip from '../tools/MuiToolTip'
import Spotlight from '../tools/Spotlight'
import { TOUR_Z_INDEX } from '../tools/common'
import useFindElements from '../tools/useFindElements'
import { SpotlightConfigType, SpotlightPropsType, ToolTipPropsType, TourConfigType } from '../types'
import DefaultTooltip from './DefaultToolTip'

const OnlineProposalTooltip = (props: ToolTipPropsType) => {
  const { handleNext, handlePrev, handleCancel } = props
  const translate = useTranslate()
  const disableScroll = useCallback(() => {
    const rootDom = window.document.body
    if (rootDom && rootDom.style.overflow !== 'hidden') {
      rootDom.style.overflow = 'hidden'
    }
  }, [])
  const enableScroll = useCallback(() => {
    const rootDom = window.document.body
    if (rootDom) {
      rootDom.style.cssText = ''
    }
  }, [])

  useEffect(() => {
    disableScroll()
    return () => enableScroll()
  }, [])

  return (
    <div
      style={{
        width: 400,
        zIndex: TOUR_Z_INDEX,
      }}
    >
      <DefaultTooltip
        title={translate('View Proposal')}
        // onClose={handleCancel}
        icon={VisibilityOutlinedIcon}
        content={
          <div>
            {translate('Welcome to the dynamic, interactive proposal you just created!')}
            <div style={{ margin: '10px 0' }}>
              {translate(
                'With the click of a button, you can send this to your customer, or, toggle it into Customer View mode and walk your customer through it on a screen share or in-home on a tablet.'
              )}
            </div>
            {translate('You can customise everything here - rearrange it, remove it or add your own content.')}
          </div>
        }
        actions={
          <>
            <Button disabled={true}>
              <span>{translate('Back')}</span>
            </Button>
            <Button
              onClick={() => {
                window.scroll({ top: 0 })
                handleNext && handleNext()
              }}
              onTouchStart={(e) => {
                e.stopPropagation()
                window.scroll({ top: 0 })
                if (handleNext) {
                  handleNext()
                }
              }}
            >
              <span>{translate('Next')}</span>
            </Button>
          </>
        }
      />
    </div>
  )
}

const WelcomeMessageToolTip = (props: ToolTipPropsType) => {
  const { handleNext, handlePrev, handleCancel } = props
  const translate = useTranslate()
  const translateParse = useTranslateParse()
  return (
    <DefaultTooltip
      title={translate('WELCOME MESSAGE')}
      // onClose={handleCancel}
      icon={VisibilityOutlinedIcon}
      content={
        <div style={{ marginBottom: 20 }}>
          <div>{translate('This pop-up will show up when your customer first views their online proposal.')}</div>
          <div style={{ margin: '10px 0' }}>
            {translate('You can show a default message or easily customise it for each customer.')}
          </div>
          <div>{translateParse('Click <b>"Continue to Proposal"<b>', { b: (label: string) => <b>{label}</b> })}</div>
        </div>
      }
    />
  )
}

const ToolTips = [
  {
    targetId: 'root',
    hideArrow: true,
    placement: 'bottom',
    component: OnlineProposalTooltip,
  },
  {
    targetId: 'welcome-message-dialog',
    hideArrow: true,
    placement: 'left',
    component: WelcomeMessageToolTip,
    options: { style: { left: '20px', maxWidth: 280 } },
  },
]

const LandOnlineProposalToolTip: FC<ToolTipPropsType> = ({ handleNext, handlePrev, handleCancel }) => {
  const foundElementId = useFindElements(
    ToolTips.map((a: any) => a.targetId),
    true
  )
  const tooltip: any = useMemo(() => ToolTips.find((a) => a.targetId === foundElementId), [foundElementId])
  return tooltip ? (
    <ToolTip
      key={`tour-step-${tooltip.targetId}-tooltip`}
      handleNext={handleNext}
      handlePrev={handlePrev}
      handleCancel={handleCancel}
      {...tooltip}
    />
  ) : null
}

const SpotlightConfig = [{ targetId: 'welcome-message-dialog' }]

const LandOnlineProposalSpotlight: FC<SpotlightPropsType> = ({ handleHover }) => {
  const foundElementId = useFindElements(
    SpotlightConfig.map((a: any) => a.targetId),
    true
  )
  const spotlight: SpotlightConfigType | undefined = useMemo(
    () => SpotlightConfig.find((a: any) => a.targetId === foundElementId),
    [foundElementId]
  )
  return spotlight ? (
    <Spotlight key={`tour-step-${spotlight.targetId}-spotlight`} handleHover={handleHover} {...spotlight} />
  ) : null
}

export const LandOnlineProposal: TourConfigType = {
  spotlights: [<LandOnlineProposalSpotlight />],
  tooltips: [<LandOnlineProposalToolTip />],
}

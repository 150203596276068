import { logAmplitudeEvent } from 'amplitude/amplitude'
import React, { useEffect, useState } from 'react'
import { CustomTileType, SystemDataType } from 'types/systems'

type PropsType = {
  system: SystemDataType
  categories: CustomTileType[]
  selectedPaymentOption?: any
}

const ExhibitCustomTile = ({ system, categories, selectedPaymentOption }: PropsType) => {
  const tiles =
    system.tile_online_proposals?.filter(
      (tile) =>
        categories.includes(tile.type) &&
        (!selectedPaymentOption || !tile.payment_option_id || selectedPaymentOption?.id === tile.payment_option_id)
    ) || []

  return (
    <>
      {tiles.map((tile, i) => (
        <Tile key={i} system={system} tile={tile} />
      ))}
    </>
  )
}

export default ExhibitCustomTile

const Tile = ({ tile, system }) => {
  const [clicked, setClicked] = useState(false)

  useEffect(() => {
    if (tile) {
      const code = addCodeForTile(tile)
      logAmplitudeEvent('exhibit_custom_tile_mount', { type: tile.type, code: code })
    }
  }, [])

  const addCodeForTile = (tile) => {
    let codes = ''
    switch (tile.type) {
      case 'module':
        codes = system?.moduleCode
        break
      case 'inverter':
        codes = system?.inverterCodes
        break
      case 'battery':
        codes = system?.batteryCodes
        break
      case 'other':
        codes = system?.otherComponentCodes
        break
    }
    return codes
  }

  const sendAnalytics = (data) => {
    if (clicked) return
    const code = addCodeForTile(data)
    logAmplitudeEvent('exhibit_custom_tile_interaction', { type: data.type, code: code })
  }

  const handleTileClick = () => {
    sendAnalytics(tile)
    setClicked(true)
  }

  return (
    <div onClickCapture={handleTileClick} onTouchStartCapture={handleTileClick}>
      <div dangerouslySetInnerHTML={{ __html: tile.value }} />
    </div>
  )
}

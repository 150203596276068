import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import ShoppingBasketOutlinedIcon from '@material-ui/icons/ShoppingBasketOutlined'
import CategoryItem from './elements/CategoryItem'
import MenuItemLink from './elements/MenuItemLink'
import useFoldableMenuItemControl from './hooks/useFoldableMenuItemControl'
import type { CardConfigType, CategoryConfigType } from './type'

export const INVENTORY_DASHBOARD = 'Inventory Dashboard'
export const INVENTORY = 'Inventory'
export const INCOMING_ORDERS = 'Incoming orders'
export const OUTGOING_ORDERS = 'Outgoing orders'
export const ACTIVITY = 'Activity'
export const INSIGHTS = 'Insights'

const HARDWARE_MENU_CONFIG: CategoryConfigType[] = [
  {
    icon: <AccountBalanceOutlinedIcon />,
    title: 'INVENTORY MANAGEMENT',
    cards: [
      {
        title: INVENTORY_DASHBOARD,
        url: '/inventory/dashboard',
      },
      { title: INVENTORY, url: '/inventory/location' },
      {
        title: OUTGOING_ORDERS,
        url: '/inventory/outgoing_transfer',
      },
      {
        title: ACTIVITY,
        url: '/inventory/activity',
      },
      {
        title: INSIGHTS,
        url: '/inventory/Insights',
      },
    ],
  },
  {
    icon: <ShoppingBasketOutlinedIcon />,
    title: 'SHOP',
    cards: [
      { title: 'Catalogue', url: '/shop' },
      { title: 'Order By Project', url: '/shop/order_by_project' },
    ],
  },
  {
    icon: <SettingsOutlinedIcon />,
    title: 'CONFIGURATION',
    cards: [{ title: 'Settings', url: '/inventory/configuration' }],
  },
]

const MenuItem = ({ cardConfig }: { cardConfig: CardConfigType }) => {
  const { title, url, cards } = cardConfig
  const isFoldable = !!cards
  const { ArrowIconNode, onToggle, open } = useFoldableMenuItemControl(true)

  return (
    <>
      <MenuItemLink title={title} id={title} url={url} ArrowIconNode={isFoldable ? ArrowIconNode : undefined} />
      {open &&
        cards?.map((cardConfig: CardConfigType) => {
          return <MenuItem key={cardConfig.title} cardConfig={cardConfig} />
        })}
    </>
  )
}

const MenuCategory = ({ categoryConfig }: { categoryConfig: CategoryConfigType }) => {
  const { ArrowIconNode, onToggle, open } = useFoldableMenuItemControl(true)

  return (
    <>
      <CategoryItem
        key={categoryConfig.title}
        Icon={categoryConfig.icon}
        title={categoryConfig.title}
        id={categoryConfig.title}
        handleClick={onToggle}
        ArrowIconNode={ArrowIconNode}
      />
      {open &&
        categoryConfig.cards.map((cardConfig: CardConfigType) => {
          return <MenuItem key={cardConfig.title} cardConfig={cardConfig} />
        })}
    </>
  )
}

const HardwareMenu = () => {
  return (
    <div>
      {HARDWARE_MENU_CONFIG.map((categoryConfig: CategoryConfigType) => {
        return <MenuCategory key={categoryConfig.title} categoryConfig={categoryConfig} />
      })}
    </div>
  )
}

export default HardwareMenu

import { ActionDataType } from 'myenergy/selectionComponent/loanApplicationButton/types'
import { CustomerActionType } from 'types/customerActions'

export const SET_TRANSACTION_REQUEST_DATA = 'SET_TRANSACTION_REQUEST_DATA'
export const TRANSACTION_FORM_SHOW = 'TRANSACTION_FORM_SHOW'
export const TRANSACTION_FORM_HIDE = 'TRANSACTION_FORM_HIDE'
export const TRANSACTION_FORM_SUBMIT = 'TRANSACTION_FORM_SUBMIT'
export const TRANSACTION_FORM_LOADING = 'TRANSACTION_FORM_LOADING'
export const TRANSACTION_FORM_SUCCESS = 'TRANSACTION_FORM_SUCCESS'
export const TRANSACTION_FORM_FAILURE = 'TRANSACTION_FORM_FAILURE'
export const TRANSACTION_FORM_CLEAR_ERROR = 'TRANSACTION_FORM_CLEAR_ERROR'
export const CLEAR_TRANSACTION_FORM_LOADING = 'CLEAR_TRANSACTION_FORM_LOADING'

export const showTransactionForm = (payload) => ({
  type: TRANSACTION_FORM_SHOW,
  payload: payload,
})

export const hideTransactionForm = () => ({
  type: TRANSACTION_FORM_HIDE,
  payload: {},
})

export const loadingTransactionForm = () => ({
  type: TRANSACTION_FORM_LOADING,
  payload: {},
})

export const clearLoadingTransactionForm = () => ({
  type: CLEAR_TRANSACTION_FORM_LOADING,
  payload: {},
})

export const successTransactionForm = (successMessage) => ({
  type: TRANSACTION_FORM_SUCCESS,
  payload: { successMessage: successMessage },
})

export const failureTransactionForm = (errorMessage) => ({
  type: TRANSACTION_FORM_FAILURE,
  payload: { errorMessage: errorMessage ? errorMessage : 'Error' },
})

export const clearTransactionFormError = () => ({
  type: TRANSACTION_FORM_CLEAR_ERROR,
})

export const setTransactionRequestData = (selectedTransactionRequestData: CustomerActionType) => ({
  type: SET_TRANSACTION_REQUEST_DATA,
  payload: selectedTransactionRequestData,
})

export type TransactionStateType = {
  successMessage: string | undefined
  errorMessage: string | undefined
  loading: boolean
  transactionRequestData: ActionDataType | undefined
}

export default function reducer(previousState: TransactionStateType | {} = {}, { type, payload }) {
  if (!previousState) {
    previousState = {}
  }

  if (type === TRANSACTION_FORM_SHOW) {
    return {
      ...previousState,
      successMessage: undefined,
      errorMessage: undefined,
      transactionRequestData: payload.transactionRequestData,
      loading: false,
    }
  }

  if (type === TRANSACTION_FORM_LOADING) {
    return {
      ...previousState,
      loading: true,
    }
  }

  if (type === CLEAR_TRANSACTION_FORM_LOADING) {
    return {
      ...previousState,
      loading: false,
    }
  }

  if (type === TRANSACTION_FORM_SUCCESS) {
    return {
      ...previousState,
      successMessage: payload.successMessage,
      errorMessage: undefined,
      loading: false,
    }
  }

  if (type === TRANSACTION_FORM_FAILURE) {
    return {
      ...previousState,
      successMessage: undefined,
      errorMessage: payload.errorMessage,
      loading: false,
    }
  }

  if (type === TRANSACTION_FORM_CLEAR_ERROR) {
    return {
      ...previousState,
      errorMessage: undefined,
    }
  }

  if (type === TRANSACTION_FORM_HIDE) {
    return {
      ...previousState,
      transactionRequestData: undefined,
      loading: false,
      successMessage: undefined,
      errorMessage: undefined,
    }
  }

  if (type === SET_TRANSACTION_REQUEST_DATA) {
    return {
      ...previousState,
      transactionRequestData: payload,
    }
  }

  return previousState
}

export const transactionSelectors = {
  getShowTransactionDialog: (state) => !!state.transaction?.transactionRequestData,
  getTransactionRequestData: (state) => state.transaction?.transactionRequestData,
  getIsLoading: (state) => state.transaction.loading,
}

// @ts-nocheck
import { Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import CopyLink from 'layout/widgets/CopyLink'
import LoadingDots from 'layout/widgets/LoadingDots'
import React, { useEffect, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import restClient from 'restClient'
import { ProjectType } from 'types/projects'
import { PaymentOptionSunlightQuickCreateType, SystemDesignSunlightQuickCreateType } from '../sunlight/types'

let restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  onClose: () => void
  open: boolean
  project: ProjectType
  designs?: SystemDesignSunlightQuickCreateType[]
}

const SungageSendAppDialog: React.FunctionComponent<PropTypes> = (props) => {
  const getSungagePaymentOptions = () => {
    if (systems && systems.length > 0) {
      let allOptions: PaymentOptionSunlightQuickCreateType[] = []
      systems.forEach((design) => {
        if (design.paymentOptionsData && design.paymentOptionsData.length > 0) {
          design.paymentOptionsData
            .filter((opt) => opt.integration === 'sungage')
            .forEach((opt) => allOptions.push(opt))
        }
      })
      return allOptions
    } else return []
  }

  const [availablePaymentOptions, setAvailablePaymentOptions] = useState<PaymentOptionSunlightQuickCreateType[]>([])
  const [selectedPaymentOptionId, setSelectedPaymentOptionId] = useState<string | undefined>(undefined)
  const [selectedSystem, setSelectedSystem] = useState<SystemDesignSunlightQuickCreateType | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [customerEmail, setCustomerEmail] = useState<string | undefined>(undefined)
  const [customerPhone, setCustomerPhone] = useState<string | undefined>(undefined)
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const [checkingEmail, setCheckingEmail] = useState<boolean>(false)
  const [systems, setSystems] = useState<any[]>([])
  const [method, setMethod] = useState<'url' | 'email'>('email')
  const [url, setUrl] = useState<string | undefined>(undefined)

  const translate = useTranslate()
  const notify = useNotify()

  useEffect(() => {
    const systemsFromStudio = window.editor.getSystems()?.map((sys) => {
      sys.paymentOptionsData = sys.payment_options
      sys.title = sys.name && sys.name.length > 0 ? sys.name : `${sys.moduleQuantity()} ${translate('Panels System')}`
      return sys
    })
    if (systemsFromStudio && systemsFromStudio.length > 0) setSystems(systemsFromStudio)
  }, [])

  useEffect(() => {
    if (!selectedPaymentOptionId && availablePaymentOptions && availablePaymentOptions.length === 1) {
      setSelectedPaymentOptionId(`${availablePaymentOptions[0].id}`)
    }
  }, [selectedPaymentOptionId, availablePaymentOptions])

  useEffect(() => {
    if (!selectedSystem && systems && systems.length === 1) {
      setSelectedSystem(systems[0])
    }
  }, [systems, selectedSystem])

  useEffect(() => {
    setAvailablePaymentOptions(getSungagePaymentOptions())
  }, [systems])

  useEffect(() => {
    if (selectedSystem && systems) {
      //dedupe titles present on multiple systems
      const availablePmtOptions = selectedSystem.paymentOptionsData.filter((pmtOption) => {
        return pmtOption.integration === 'sungage'
      })
      setAvailablePaymentOptions(availablePmtOptions)
      if (!availablePmtOptions || availablePmtOptions.length === 0) setSelectedPaymentOptionId(undefined)
    } else if (availablePaymentOptions && availablePaymentOptions.length > 0) setAvailablePaymentOptions([])
  }, [selectedSystem, systems])

  useEffect(() => {
    if (props.project && props.project.contacts_data && props.project?.contacts_data?.length > 0) {
      let email = props?.project?.contacts_data[0]?.email
      let phone = props?.project?.contacts_data[0]?.phone
      setCustomerEmail(email)
      setCustomerPhone(phone)
      if (!email || !phone) setErrorMsg('Customer must have a valid email address and phone number')
    }
  }, [props.project?.contacts_data])

  const handlePmtOptionChange = (e: any) => {
    let selected = availablePaymentOptions.find((pmt) => `${pmt.id}` === e.target.value)
    setSelectedPaymentOptionId(e.target.value)
  }
  const handleSystemChange = (e: any) => {
    if (systems && systems.length > 0) {
      let uuidMatches = systems.filter((system) => system.uuid === e.target.value)
      if (uuidMatches && uuidMatches.length > 0) setSelectedSystem(uuidMatches[0])
    }
  }

  const submit = () => {
    if (!selectedSystem || !selectedPaymentOptionId || !props.project.org_id || !props.project.id) {
      setErrorMsg('Unable to share credit app')
      return
    } else if (!props.project?.id || props.project?.id === 'new') {
      setErrorMsg('Please save this project first')
      return
    }
    setLoading(true)
    restClientInstance('CUSTOM_POST', 'custom', {
      url:
        'orgs/' +
        props.project.org_id +
        '/projects/' +
        props.project.id +
        '/systems/' +
        selectedSystem.uuid +
        '/' +
        selectedPaymentOptionId +
        '/sungage/share_credit_app/',
      data: {
        method,
      },
    })
      .then((res: any) => {
        console.log('res', res)
        if (res.data.success) {
          setErrorMsg(undefined)

          logAmplitudeEvent('integrated_finance_application_sent', {
            page: window.location.hash?.includes('/manage') ? 'Manage Page' : 'Info Page',
            integration: 'sungage',
            project_id: props.project.id,
          })
          if (method === 'email') {
            notify('The Sungage application has been emailed to your customer', 'success')
            props.onClose()
          } else if (method === 'url' && res.data.url) {
            setUrl(res.data.url)
          }
        } else {
          if (res.data.message) setErrorMsg(res.data.message)
          else setErrorMsg('We were unable to send this credit application')
          logAmplitudeEvent('integrated_finance_application_error', {
            page: window.location.hash?.includes('/manage') ? 'Manage Page' : 'Info Page',
            integration: 'sungage',
            project_id: props.project.id,
          })
        }
      })
      .catch((err: any) => {
        if (err?.body?.message) setErrorMsg(err.body.message)
        else setErrorMsg('Something went wrong and we were unable to send this credit application')
      })
      .finally(() => setLoading(false))
  }

  const renderForm = () => {
    if (url) {
      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <p>{translate('Copy the link below and share it with your customer')}:</p>
          </Grid>
          <Grid item xs={12}>
            <div style={{ width: '100%', margin: '20px 0px' }}>
              <CopyLink url={url} />
            </div>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <ProUXButton label="Dismiss" type="secondary" onClick={props.onClose} />
          </Grid>
        </Grid>
      )
    }
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div>
            {translate(
              'Use the form below to email a Sungage credit application to your customer for a given system and Sungage product'
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <h2 style={{ marginTop: '30px' }}>{translate('Select a System')}</h2>
          <RadioGroup
            style={{ alignItems: 'flex-start' }}
            name="pmt-options"
            value={selectedSystem?.uuid}
            onChange={handleSystemChange}
          >
            {systems &&
              systems.map((system) => {
                return (
                  <FormControlLabel
                    key={system.uuid}
                    value={system.uuid}
                    control={<Radio />}
                    label={<span style={{ fontSize: 13 }}>{system.title}</span>}
                  />
                )
              })}
          </RadioGroup>
        </Grid>
        <Grid item xs={12} sm={6}>
          <h2 style={{ marginTop: '30px' }}>{translate('Select a Payment Option')}</h2>
          {selectedSystem && availablePaymentOptions?.length === 0 && (
            <div style={{ color: 'red' }}>
              {translate(
                'You must add a Sungage payment option to this system before you can send a credit application'
              )}
            </div>
          )}
          <RadioGroup value={`${selectedPaymentOptionId}`} onChange={handlePmtOptionChange}>
            {availablePaymentOptions.map((pmtOption) => {
              return (
                <FormControlLabel
                  key={pmtOption.id}
                  value={`${pmtOption.id}`}
                  control={<Radio disabled={!selectedSystem} checked={`${pmtOption.id}` === selectedPaymentOptionId} />}
                  label={<span style={{ fontSize: 13 }}>{pmtOption.title}</span>}
                />
              )
            })}
          </RadioGroup>
        </Grid>
        {(!customerEmail || !customerPhone) && (
          <Grid item xs={12}>
            <div style={{ color: 'red', textAlign: 'center' }}>
              {translate(
                'Please make sure the project is saved with a customer that has a valid phone number or email address.'
              )}
            </div>
          </Grid>
        )}
        {errorMsg && (
          <Grid item xs={12}>
            <p style={{ color: 'red', textAlign: 'center', width: '100%' }}>{errorMsg}</p>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
        >
          <div style={{ margin: '10px 20px' }}>
            <ProUXButton label="Cancel" type="secondary" onClick={props.onClose} />
          </div>
          <div style={{ margin: '10px 20px' }}>
            <ProUXButton
              label="Email Application"
              type="primary"
              onClick={submit}
              loading={loading}
              disabled={
                loading || !selectedSystem || !selectedPaymentOptionId || disabled || !customerEmail || !customerPhone
              }
            />
          </div>
        </Grid>
      </Grid>
    )
  }

  const renderLoadingDots = () => {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px', width: '100%' }}
      >
        <LoadingDots />
      </div>
    )
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Send a Sungage Credit Application</DialogTitle>
      <DialogContent>{checkingEmail ? renderLoadingDots() : renderForm()}</DialogContent>
    </Dialog>
  )
}
export default SungageSendAppDialog

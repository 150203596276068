// @ts-nocheck
import ContactsOutlinedIcon from '@material-ui/icons/ContactsOutlined'
import { Button } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import useTranslateParse from '../../../util/useTranslateParse'
import { ToolTipPropsType, TourConfigType } from '../types'
import DefaultTooltip from './DefaultToolTip'

const Tooltip = (props: ToolTipPropsType) => {
  const { handleNext, handlePrev, handleCancel } = props
  const translate = useTranslate()
  const translateParse = useTranslateParse()
  return (
    <DefaultTooltip
      title={translate('Enter CUSTOMER details')}
      // onClose={handleCancel}
      icon={ContactsOutlinedIcon}
      content={
        <div>
          {translateParse(
            "Add your customer's contact information. You can add more than one customer contact by clicking the <strong>ADD ANOTHER<strong> button. Once done, click <b>Next<b> to go to the next step.",
            {
              strong: (label: string) => <strong>{label}</strong>,
              b: (label: string) => <b>{label}</b>,
            }
          )}
        </div>
      }
      actions={
        <>
          <Button disabled>
            <span>{translate('Back')}</span>
          </Button>
          <Button
            onClick={() => handleNext && handleNext()}
            onTouchStart={(e) => {
              e.stopPropagation()
              if (handleNext) {
                handleNext()
              }
            }}
          >
            <span>{translate('Next')}</span>
          </Button>
        </>
      }
      arrowPlacement={'left'}
    />
  )
}

const EnterContactDetail: TourConfigType = {
  spotlights: [{ targetId: 'customer-contact-accordion', disableBackgroundScroll: true, keepObserve: true }],
  tooltips: [{ targetId: 'customer-contact-accordion', placement: 'right', component: Tooltip }],
}

export default EnterContactDetail

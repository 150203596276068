import { Divider, Grid, Typography } from '@material-ui/core'
import { orderSelectors } from 'ducks/orderComponents'
import { memo, useContext } from 'react'
import { useSelector } from 'react-redux'
import { TotalCost } from '../TotalCost'
import { CheckoutPresenterContext } from './HdmCheckoutProvider'
import { formatCurrency } from 'util/misc'

const OrderSummary = ({ currencySymbol }: { currencySymbol: string | undefined }) => {
  const lineItems = useSelector(orderSelectors.getOrderLineItems)
  const hdmCheckoutPresenter = useContext(CheckoutPresenterContext)
  const { itemsCost, tax } = hdmCheckoutPresenter?.getOrderCost(lineItems) || { itemsCost: 0, tax: 0 }

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Order summary
      </Typography>
      <Grid container>
        <Grid item xs={8}>
          <Typography variant="body1" gutterBottom>
            Items:
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" gutterBottom>
            {currencySymbol}{formatCurrency(itemsCost)}
          </Typography>
        </Grid>

        <Divider style={{ width: 100, height: 2, margin: '10px 0', marginLeft: 'auto' }} />

        <Grid item xs={8}>
          <Typography variant="body1" gutterBottom>
            VAT (20%):
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" gutterBottom>
            {currencySymbol}{formatCurrency(tax)}
          </Typography>
        </Grid>
        <Divider style={{ width: '100%', height: 2, margin: '20px 0' }} />
        <TotalCost loading={false} costWithCurrencySymbol={`${currencySymbol}${formatCurrency(itemsCost + tax)}`} />
      </Grid>
    </div>
  )
}

export default memo(OrderSummary)

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { TableCell as Mui4TableCell } from '@material-ui/core';
import { default as Mui5TableCell } from '@mui/material/TableCell';
import { forwardRef } from 'react';
import { Inspector } from '../debug/Inspector';
import { useComponentVersion } from '../hooks';
import { OsTableVersion } from './Table';
export var TableCell = forwardRef(function (props, ref) {
    return (_jsx(Inspector, { name: "TableCell", propDefs: TableCellProps, showExample: true, component: OsTableCell, props: __assign(__assign({}, props), { ref: ref }) }));
});
var OsTableCell = forwardRef(function (props, ref) {
    if (ref === void 0) { ref = null; }
    var version = useComponentVersion('table');
    switch (version) {
        case OsTableVersion.V2:
            var padding = props.padding, rest = __rest(props, ["padding"]);
            var padding5 = padding === 'default' ? 'normal' : padding;
            return _jsx(Mui5TableCell, __assign({ ref: ref, padding: padding5 }, rest));
        default:
            return _jsx(Mui4TableCell, __assign({ ref: ref }, props));
    }
});
var TableCellProps = {};

import { CardActions, makeStyles } from '@material-ui/core'
import { passwordReset } from 'actions/authActions'
import CustomField from 'elements/field/CustomField'
import { Button } from 'opensolar-ui'
import React, { useCallback } from 'react'
import { useTranslate } from 'react-admin'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { required } from 'validations'
import { renderInput, styles } from '../../common'
import type { PasswordResetLinksProps } from './PasswordResetLinks'
import PasswordResetLinks from './PasswordResetLinks'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: 280,
    marginTop: 0,
    minWidth: 210,
    padding: '0.5em 1.5em 1em 1.5em',
  },
  button: {
    color: theme.headerFontColor,
    background: theme.themeColor,
    '&:hover': {
      cursor: 'pointer',
      background: theme.themeColorDark,
    },
  },
  input: {
    margin: '10px 0 20px',
    '& .MuiFormHelperText-root': {
      marginTop: 0,
      height: 0,
    },
  },
  buttonWrapper: {
    padding: 0,
  },
}))

const PasswordReset = ({
  onResetRequestSuccess,
  Logo,
  onCreateAccount,
  onSignIn,
}: { onResetRequestSuccess(): void; Logo?: React.ReactNode } & PasswordResetLinksProps) => {
  const translate = useTranslate()
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleSubmitForm = useCallback(({ email }) => {
    dispatch(
      passwordReset({
        payload: {
          email,
        },
        callbacks: {
          onSuccess: onResetRequestSuccess,
        },
      })
    )
  }, [])
  return (
    <div className={classes.wrapper}>
      <Form
        onSubmit={handleSubmitForm}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <div>
            {Logo}
            <h1 style={{ fontWeight: 500 }}>{translate('Request Password Reset')}</h1>
            <p style={{ fontWeight: 300 }}>
              {translate('Enter your email address to receive an email containing a password reset link.')}
            </p>
            <form onSubmit={handleSubmit}>
              <div style={styles.form}>
                <div className={classes.input}>
                  <CustomField
                    name="email"
                    validate={required}
                    component={renderInput}
                    label={translate('Email')}
                    fullWidth={true}
                  />
                </div>
              </div>
              <CardActions classes={{ root: classes.buttonWrapper }}>
                <Button classes={{ root: classes.button }} type="submit" disabled={pristine || submitting} fullWidth>
                  <span>{translate('Request Password Reset')}</span>
                </Button>
              </CardActions>
            </form>
            <PasswordResetLinks onCreateAccount={onCreateAccount} onSignIn={onSignIn} />
          </div>
        )}
      />
    </div>
  )
}
export default PasswordReset

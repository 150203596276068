import { Grid, Paper } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import React, { FC } from 'react'
import { NumberInput, SelectInput, TextInput, useTranslate } from 'react-admin'
import { SECTOR_CHOICES } from '../../constants/sectors'
import useStyle from './styles'

type AutoApplyInputTypes = {
  enableByState?: boolean
  enableByZip?: boolean
  enableByCountry?: boolean
  enableByComponentCode?: boolean
  enableByMinSystemSize?: boolean
  enableByMaxSystemSize?: boolean
  enableByMinBatterySize?: boolean
  enableByMaxBatterySize?: boolean
  enableBySector?: boolean
  enableByOrder?: boolean // This currently ONLY applies to incentive auto-apply
  enableByPriority?: boolean
  disabled?: boolean
  containerComponent?: React.ComponentType
}

const AutoApplyInputs: FC<AutoApplyInputTypes> = ({
  enableByState = true,
  enableByZip = true,
  enableByCountry = false,
  enableByComponentCode = true,
  enableByMinSystemSize = true,
  enableByMaxSystemSize = true,
  enableByMinBatterySize = false,
  enableByMaxBatterySize = false,
  enableBySector = true,
  enableByOrder = false,
  enableByPriority = false,
  disabled = false,
  containerComponent = Paper,
}) => {
  const translate = useTranslate()
  const classes = useStyle()

  const Comp = containerComponent

  return (
    <Comp className={classes.paperStyle}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h2>{translate('Auto Apply Settings')}</h2>
        </Grid>
        {enableByState && (
          <Grid item xs={12} sm={12} lg={12}>
            <TextInput
              className={classes.defaultFieldStyle}
              label={'Auto apply only in specified states'}
              name={'auto_apply_only_specified_states'}
              source="auto_apply_only_specified_states"
              disabled={disabled}
              endAdornment={<InputAdornment position="end">{translate('Comma separated values')}</InputAdornment>}
              fullWidth={true}
            />
          </Grid>
        )}
        {enableByZip && (
          <Grid item xs={12} sm={12} lg={12}>
            <TextInput
              className={classes.defaultFieldStyle}
              label={'Auto apply only in specified ZIP codes'}
              name={'auto_apply_only_specified_zips'}
              source="auto_apply_only_specified_zips"
              disabled={disabled}
              endAdornment={<InputAdornment position="end">{translate('Comma separated values')}</InputAdornment>}
              fullWidth={true}
            />
          </Grid>
        )}
        {enableByCountry && (
          <Grid item xs={12} sm={12} lg={12}>
            <TextInput
              className={classes.defaultFieldStyle}
              label={'Auto apply only in specified countries (iso2)'}
              name={'auto_apply_only_specified_countries'}
              source="auto_apply_only_specified_countries"
              disabled={disabled}
              endAdornment={<InputAdornment position="end">{translate('Comma separated values')}</InputAdornment>}
              fullWidth={true}
            />
          </Grid>
        )}
        {enableByComponentCode && (
          <Grid item xs={12} sm={12} lg={12}>
            <TextInput
              className={classes.defaultFieldStyle}
              label={'Auto apply Component codes'}
              name={'auto_apply_component_codes'}
              source="auto_apply_component_codes"
              disabled={disabled}
              endAdornment={<InputAdornment position="end">{translate('Comma separated values')}</InputAdornment>}
              fullWidth={true}
            />
          </Grid>
        )}
        {enableByMinSystemSize && (
          <Grid item xs={12} sm={4} lg={3}>
            <NumberInput
              className={classes.defaultFieldStyle}
              label={'Auto apply min system size'}
              name={'auto_apply_min_system_size'}
              source="auto_apply_min_system_size"
              disabled={disabled}
              endAdornment={<InputAdornment position="end">kW</InputAdornment>}
              fullWidth={true}
            />
          </Grid>
        )}
        {enableByMaxSystemSize && (
          <Grid item xs={12} sm={4} lg={3}>
            <NumberInput
              className={classes.defaultFieldStyle}
              label={'Auto apply max system size'}
              name={'auto_apply_max_system_size'}
              source="auto_apply_max_system_size"
              disabled={disabled}
              endAdornment={<InputAdornment position="end">kW</InputAdornment>}
              fullWidth={true}
            />
          </Grid>
        )}
        {enableByMinBatterySize && (
          <Grid item xs={12} sm={4} lg={3}>
            <NumberInput
              className={classes.defaultFieldStyle}
              label={'Auto apply min battery size'}
              name={'auto_apply_min_battery_kwh'}
              source="auto_apply_min_battery_kwh"
              disabled={disabled}
              endAdornment={<InputAdornment position="end">kWh</InputAdornment>}
              fullWidth={true}
            />
          </Grid>
        )}
        {enableByMaxBatterySize && (
          <Grid item xs={12} sm={4} lg={3}>
            <NumberInput
              className={classes.defaultFieldStyle}
              label={'Auto apply max battery size'}
              name={'auto_apply_max_battery_kwh'}
              source="auto_apply_max_battery_kwh"
              disabled={disabled}
              endAdornment={<InputAdornment position="end">kWh</InputAdornment>}
              fullWidth={true}
            />
          </Grid>
        )}
        {enableBySector && (
          <Grid item xs={12} sm={4} lg={3}>
            <SelectInput
              className={classes.defaultFieldStyle}
              label={'Auto apply sector'}
              name={'auto_apply_sector'}
              source="auto_apply_sector"
              disabled={disabled}
              choices={SECTOR_CHOICES}
              fullWidth={true}
            />
          </Grid>
        )}
        {enableByPriority && (
          <Grid item xs={12} sm={4} lg={3}>
            <NumberInput
              className={classes.defaultFieldStyle}
              label="Auto apply priority"
              name="auto_apply_priority"
              source="auto_apply_priority"
              disabled={disabled}
              fullWidth={true}
            />
          </Grid>
        )}
        {enableByOrder && (
          <Grid item xs={12} sm={4} lg={3}>
            <SelectInput
              className={classes.defaultFieldStyle}
              name={'order'}
              source="order"
              choices={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => {
                return { id: value, name: value }
              })}
              disabled={disabled}
              fullWidth={true}
            />
          </Grid>
        )}
      </Grid>
    </Comp>
  )
}

export default AutoApplyInputs

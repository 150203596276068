import { Paper } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import type { OpenSolarThemeType } from 'Themes'
import ListResultNotFound from 'elements/list/ListResultNotFound'
import React, { memo } from 'react'
import { DatagridLoading } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import ProjectTablePagination from './Pagination'

const useStyles = makeOpenSolarStyles(
  (theme: OpenSolarThemeType) => ({
    table: {
      tableLayout: 'fixed',
    },
    row: {},
    rowCell: {},
    expandHeader: {
      padding: 0,
      width: theme.spacing(6),
    },
    expandIconCell: {
      width: theme.spacing(6),
    },
    headerCell: {
      position: 'sticky',
      top: 0,
      zIndex: 2,
      backgroundColor: 'rgba(230, 230, 230, 0.85)',
      fontSize: 14,
      fontWeight: 500,
    },
  }),
  { name: 'ProjectTableContainer' }
)

const TableContainer = ({
  loaded,
  loading,
  children,
  total,
  query,
  queryPresenter,
}: {
  loaded: boolean
  loading: boolean
  children: JSX.Element | JSX.Element[]
  total: any
  query: any
  queryPresenter: any
}) => {
  const classes = useStyles()

  if (loaded === false) {
    return <DatagridLoading classes={classes} hasBulkActions={true} nbChildren={5} size="small" />
  }

  return (
    <Paper elevation={1}>
      <Table className={classes.table} size="small">
        {children}
      </Table>
      {/* keep showing table header if no result found */}
      {total === 0 && loading === false ? (
        <ListResultNotFound />
      ) : (
        <ProjectTablePagination total={total} query={query} queryPresenter={queryPresenter} />
      )}
    </Paper>
  )
}

export default memo(TableContainer)

import { Card, CardActions, CardContent, CardMedia, Icon } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { Button } from 'opensolar-ui'
import React, { FC, memo } from 'react'
import { useTranslate } from 'react-admin'
import { useHistory, useLocation } from 'react-router-dom'
import Login from '../../icons/Login.svg'

type coursesType = {
  title?: string
  description?: string
  logo?: string
  image?: string
  login?: string
  signup?: string
  type: 'iframe' | 'launch' | undefined
  countries?: string
  states?: string
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 300,
    margin: 30,
    '&.MuiCardActions-spacing > :not(:first-child)': {
      marginLeft: 10,
    },
  },
  media: {
    objectFit: 'cover',
    border: 'none',
    margin: 10,
    height: 175,
    overflow: 'auto',
    maxWidth: 268,
    padding: '16px 16px 0 16px',
    [theme.breakpoints.down(390)]: {
      maxWidth: 'unset',
      padding: 'unset',
    },
  },
  title: {
    fontSize: 20,
    [theme.breakpoints.down(300)]: {
      fontSize: 18,
    },
  },
  cardActions: {
    justifyContent: 'center',
    padding: '8px 0 20px',
    '& > :not(:first-child)': {
      marginLeft: 10,
      [theme.breakpoints.down(380)]: {
        marginLeft: 0,
        marginTop: 10,
      },
    },
    [theme.breakpoints.down(380)]: {
      flexDirection: 'column',
    },
  },
  label: {
    fontSize: 14,
    textTransform: 'none',
    [theme.breakpoints.down(280)]: {
      fontSize: 12,
    },
    [theme.breakpoints.down(260)]: {
      fontSize: 10,
    },
    [theme.breakpoints.down(240)]: {
      fontSize: 8,
    },
  },
}))

const Courses: FC<coursesType> = ({
  title,
  description,
  logo,
  image,
  login,
  signup,
  type,
  // , countries, states
}) => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const translate = useTranslate()
  const loginIcon = (
    <Icon>
      <img
        alt="login"
        src={Login}
        style={{
          fill: 'currentColor',
          width: '0.8em',
          height: '0.9em',
          display: 'inline-block',
          fontSize: '1.5rem',
          transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          flexShrink: 0,
        }}
      />
    </Icon>
  )

  const logoIcon = (
    <Icon>
      <img alt="logo" src={logo} style={{ height: 30, objectFit: 'contain' }} />
    </Icon>
  )

  const handleClick = (action: string | undefined) => {
    if (type === 'launch') {
      window.open(`${action}`, '_blank')
    } else {
      history.push(`${location.pathname}?course=${action}`)
    }
    logAmplitudeEvent('course_button_clicked', { action: action, course_title: title })
  }

  return (
    <Card className={classes.root}>
      <CardMedia className={classes.media} image={image} />
      <CardContent>
        {logoIcon}
        <Typography gutterBottom variant="h6" component="div" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="body2" color="textSecondary" style={{ height: 120, overflow: 'auto' }}>
          {description}
        </Typography>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button
          variant="contained"
          classes={{ label: classes.label }}
          startIcon={loginIcon}
          onClick={() => handleClick(login)}
        >
          {translate('Login')}
        </Button>
        <Button
          variant="contained"
          classes={{ label: classes.label }}
          startIcon={<SchoolOutlinedIcon />}
          onClick={() => handleClick(signup)}
        >
          {translate('Sign-up to courses')}
        </Button>
      </CardActions>
    </Card>
  )
}

export default memo(Courses)

import type { FilterComponentNode, FilterNodeKeysType } from 'elements/hardwareFilter/type'
import type { ComponentTypes } from 'types/selectComponent'
import { FILTER_NODE_KEY_AND_FACTORY } from '../constants'
import BasicSelectorRenderer from '../renderers/BasicSelectorRenderer'

const useAvailableFilterSelectorNodes = ({
  filtersNodes,
  componentType,
}: {
  filtersNodes: FilterNodeKeysType[]
  componentType?: ComponentTypes
}): FilterComponentNode[] => {
  const factoryHooks = filtersNodes.map((key: FilterNodeKeysType) => {
    return FILTER_NODE_KEY_AND_FACTORY[key]
  })
  const nodes = factoryHooks.map((hook) =>
    hook({ RendererComponent: BasicSelectorRenderer, options: { componentType } })
  )

  const filterNodes: FilterComponentNode[] = []
  nodes.forEach((node) => {
    if (node !== undefined) {
      filterNodes.push(node)
    }
  })
  return filterNodes
}

export default useAvailableFilterSelectorNodes

import React from 'react'
import Disclaimer from '../../elements/Disclaimer'
import OpenSolarDisclosure from './OpenSolarDisclosure'

const systemDisclaimerKeys = [
  // 'disclaimer_system_hardware_components',
  'disclaimer_system_performance_new',
  'disclaimer_daily_energy_flows',
  'disclaimer_environmental_benefits',
]

const paymentOptionDisclaimerKeys = [
  'disclaimer_financial_impact',
  'disclaimer_system_price',
  'disclaimer_payment_type',
]

const PoweredBy = ({ org, selectedSystem, selectedPaymentOption }) => {
  // Inject server-side populated disclaimer directly from selectedSystem[disclaimer_*] into
  return (
    <div>
      {systemDisclaimerKeys
        .filter((key) => selectedSystem[key] && selectedSystem[key].length > 0)
        .map((key, index) => (
          <Disclaimer key={index} content={selectedSystem[key]} />
        ))}

      {paymentOptionDisclaimerKeys
        .filter((key) => selectedPaymentOption[key] && selectedPaymentOption[key].length > 0)
        .map((key, index) => (
          <Disclaimer key={index} content={selectedPaymentOption[key]} />
        ))}

      {selectedPaymentOption.integration === 'sunlight' && (
        <Disclaimer
          content={
            '<p>By clicking the "Apply Now" button you will be directed to Sunlight Financial LLC\'s application form. OpenSolar Pty Ltd does not view or store any of the details you include in this form - they will be provided directly to Sunlight. OpenSolar may receive details of whether or not your finance application was approved. Sunlight\'s terms and conditions and privacy policy will apply in the event you provide your details to Sunlight using their form. Please contact Sunlight if you have any questions or concerns about your finance application.</p>'
          }
        />
      )}

      <hr className="light" />

      <OpenSolarDisclosure org={org} />
    </div>
  )
}

export default PoweredBy

import CircularProgress from '@material-ui/core/CircularProgress'
import ChartistHover from 'chartist-hover'
import React, { Component } from 'react'
import ChartistGraph from 'react-chartist'
import { addUpdateCssRule } from '../../util/misc'

const toolTipDefaultStyle = {
  position: 'absolute',
  background: 'rgba(237, 237, 237, 0.9)',
  textAlign: 'left',
  borderRadius: 3,
  padding: 4,
}

// function ctBarLabels(options) {
//   return function ctBarLabels(chart) {
//     var defaultOptions = {
//       labelClass: 'ct-bar-label',
//       labelInterpolationFnc: Chartist.noop,
//       labelOffset: {
//         x: 0,
//         y: 0,
//       },
//       position: {
//         x: null,
//         y: null,
//       },
//       textAnchor: 'middle',
//     }

//     options = Chartist.extend({}, defaultOptions, options)

//     var positionX =
//       options.position.x ||
//       function (data) {
//         return (data.x1 + data.x2) / 2 + options.labelOffset.x
//       }

//     var positionY =
//       options.position.y ||
//       function (data) {
//         return (data.y1 + data.y2) / 2 + options.labelOffset.y
//       }

//     if (chart instanceof Chartist.Bar) {
//       chart.on('draw', function (data) {
//         // console.log(data)
//         if (data.type === 'bar') {
//           data.group
//             .elem(
//               'text',
//               {
//                 // This gets the middle point of the bars and then adds the
//                 // optional offset to them
//                 x: positionX(data),
//                 y: positionY(data),
//                 style: 'text-anchor: middle',
//               },
//               options.labelClass
//             )
//             // Add bar label with series name if !== 0
//             .text(Math.round(data.value.y) !== 0 ? data.series.name : '')
//         }
//       })
//     }
//   }
// }

class LifeTimeSavingsChart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      toolTipStyle: {},
      year: undefined,
      feedInTariff: undefined,
      billIncentiveInYear: undefined,
      savings: undefined,
      netSavings: undefined,
    }

    addUpdateCssRule(
      '.ct-chart .savings .ct-bar-hover',
      `{ stroke: ${props.myeStyles.highlightColorInteraction} !important; }`
    )
  }
  onMouseLeave = (e) => {
    this.setState({
      toolTipStyle: {},
      year: undefined,
      feedInTariff: undefined,
      billIncentiveInYear: undefined,
      savings: undefined,
      netSavings: undefined,
    })
  }

  onMouseEnter = (e) => {
    const year = e.srcElement.getAttribute('year')
    const index = parseInt(e.srcElement.id)

    if (year !== this.state.year) {
      const feedInTariff = e.srcElement.getAttribute('cumulativeFeedInTariff')
      const billIncentiveInYear = e.srcElement.getAttribute('cumulativeIncentives')
      const savings = e.srcElement.getAttribute('cumulativeSavings')
      const netSavings = e.srcElement.getAttribute('cumulativeNetSavings')
      this.setState({
        toolTipStyle: { top: e.pageY - 80, left: e.pageX - 80 },
        year,
        feedInTariff,
        billIncentiveInYear,
        savings,
        netSavings,
      })
      if (index >= 0) {
        const hoverBar = window.$('.ct-chart .savings .ct-bar')[index]
        hoverBar && hoverBar.setAttribute('class', 'ct-bar ct-bar-hover')
      }
    }
  }

  render() {
    const {
      bills_current,
      bills_proposed,
      currencySymbol,
      YAxisRange,
      regular_payments_yearly,
      fullCalcReady,
      selectedProject,
      isIgnoreTax,
    } = this.props

    const hideExportCreditBreakdown =
      selectedProject?.proposal_data?.proposal_template_settings?.hide_export_credit_breakdown

    const lifeTimeSavingsChartData = window.Designer.LifeTimeSavingsChartData(
      bills_current.bills_yearly,
      bills_proposed.bills_yearly,
      currencySymbol,
      YAxisRange,
      regular_payments_yearly,
      isIgnoreTax,
      bills_proposed.distributed_generation_rules,
      hideExportCreditBreakdown
    )

    const classSize =
      YAxisRange.maxYAxis * 1.2 > 1000000 ? 'ct-chart LifeTimeSavingsChart-small' : 'ct-chart LifeTimeSavingsChart'
    // Hack: Not sure why we can't just use Designer.ctBarLabelsBillSavings - labels don't appear
    // Re-implementing within this file fixes labels.
    /*
    // Too Show Lables on graph
    lifeTimeSavingsChartData.options.plugins = [
      ctBarLabels({
        textAnchor: 'middle',
      }),
    ]
    */

    lifeTimeSavingsChartData.options.plugins = [
      ...lifeTimeSavingsChartData.options.plugins,
      ChartistHover({
        onMouseEnter: this.onMouseEnter,
        onMouseLeave: this.onMouseLeave,
        triggerSelector: null,
      }),
    ]
    const { toolTipStyle, year, feedInTariff, billIncentiveInYear, savings, netSavings } = this.state
    const { translate } = this.props
    const style = { ...toolTipDefaultStyle, ...toolTipStyle }
    const showExportCredit = feedInTariff && feedInTariff !== '0' && !hideExportCreditBreakdown
    return (
      <div className="LifeTimeSavingsGraphContainer">
        {fullCalcReady ? (
          <div>
            {year && (
              <div className="mye-graph-tooltips" style={style}>
                <p style={{ margin: 0 }}>
                  {translate('Year')}: {parseInt(year)}
                </p>
                {showExportCredit && (
                  <p style={{ margin: 0 }}>
                    {selectedProject && selectedProject.country_iso2 === 'GB'
                      ? translate('Smart Export Guarantee')
                      : translate('Export Credit')}
                    : {this.props.currencySymbol}
                    {Math.round(feedInTariff).toLocaleString(window.locale)}
                  </p>
                )}
                {billIncentiveInYear && billIncentiveInYear !== '0' && (
                  <p style={{ margin: 0 }}>
                    {translate('Incentives')}: {this.props.currencySymbol}
                    {Math.round(billIncentiveInYear).toLocaleString(window.locale)}
                  </p>
                )}
                {savings && savings !== '0' && (
                  <p style={{ margin: 0 }}>
                    {translate('Savings')}: {this.props.currencySymbol}
                    {Math.round(savings).toLocaleString(window.locale)}
                  </p>
                )}
                {netSavings && netSavings !== '0' && (
                  <p style={{ margin: 0 }}>
                    {translate('Net Savings')}: {this.props.currencySymbol}
                    {Math.round(netSavings).toLocaleString(window.locale)}
                  </p>
                )}
              </div>
            )}
            <ChartistGraph
              className={classSize}
              style={{
                marginTop: 20,
                marginLeft: 0,
                marginBottom: 0,
                marginRight: 0,
                height: '200px',
              }}
              data={lifeTimeSavingsChartData}
              options={lifeTimeSavingsChartData.options}
              type={'Bar'}
            />
          </div>
        ) : (
          <div style={{ position: 'relative', height: 200, marginTop: 20 }}>
            <CircularProgress
              size={40}
              top={70}
              left={0}
              status="loading"
              style={{
                display: 'inline-block',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                animation: 'none',
              }}
            />
          </div>
        )}
      </div>
    )
  }
}

export default LifeTimeSavingsChart

import Modal from 'elements/modal/Modal'
import { Button, SettingsIcon } from 'opensolar-ui'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { doNotTranslate } from 'util/misc'
import useStyles from './scaffoldStyle'

interface QuoteProps {
  isOpen: boolean
  closeModal: () => void
}

const EnableScaffoldingDialog: React.FunctionComponent<QuoteProps> = ({ isOpen, closeModal }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Modal
      isOpen={isOpen}
      header={<></>}
      className={classes.enableModalContainer}
      body={
        <div>
          <div className={classes.displayFlex}>
            <div className={classes.iconContainer}>
              <div className={`${classes.iconScaffold} ${classes.alignCenter}`}>
                <SettingsIcon size={20} color={COLOR_PALETTE.darkGrey} />
              </div>
            </div>
          </div>
          <div className={classes.centerContainer}>
            <div>
              <div className={`${classes.fieldText} ${classes.marginTop16} ${classes.enableTextColor}`}>
                <div>{doNotTranslate('Enable PVF')}</div>
              </div>
            </div>
            <div className={`${classes.enableDescription}`}>
              <div>
                {doNotTranslate(
                  'Please enable PVF in Control > Other > Connect before creating a quote for your scaffolding.'
                )}
              </div>
            </div>
          </div>
        </div>
      }
      footer={
        <div className={classes.modalFooter}>
          <div className={classes.modalFooterButtons}>
            <Button onClick={closeModal} variant="contained" color="default" fullWidth={true}>
              {'Not Now'}
            </Button>
            <Button
              onClick={() => {
                closeModal()
                history.push('/wallet_products')
              }}
              variant="contained"
              color="primary"
              fullWidth={true}
              className={classes.enableBtnStyle}
            >
              {'Enable PVF'}
            </Button>
          </div>
        </div>
      }
    />
  )
}

export default EnableScaffoldingDialog

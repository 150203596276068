import _ from 'lodash'
import { memo, useEffect, useState } from 'react'
import { useForm } from 'react-final-form'
import { FIELDS_REQUIRE_RECALC } from './fields'

function areEqual({ values: previousValues }: any, { values: nextValues }: any) {
  /*
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
    */
  for (let i = 0; i < FIELDS_REQUIRE_RECALC.length; i++) {
    const field = FIELDS_REQUIRE_RECALC[i]
    if (previousValues && nextValues && !_.isEqual(previousValues[field], nextValues[field])) {
      return false
    }
  }
  return true
}

const CalcImpactListener = ({ values }: any) => {
  const form = useForm()
  const formState = form.getState()
  const isProjectRequireSystemCalcs = form.mutators.isUnsavedProjectDataRequireSystemCalcs()
  const [isMounting, setIsMounting] = useState(true)
  useEffect(() => {
    setIsMounting(false)
  }, [])
  useEffect(() => {
    if (isMounting === false && Object.values(formState?.dirtyFields)?.length !== 0 && !isProjectRequireSystemCalcs) {
      form.mutators.requireSystemCalcs(true)
    }
  })

  return <div style={{ display: 'none' }} />
}

export default memo(CalcImpactListener, areEqual)

import { Link, makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { useTranslate } from 'ra-core'
import { useFormState } from 'react-final-form'
import { ProjectType } from 'types/projects'
import { getInitiatedChangeOrder } from 'util/changeOrder'

const useStyles = makeStyles(() => ({
  customLink: {
    color: '#FFFFFF !important',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginLeft: '5px',
  },
  container: {
    position: 'fixed',
    top: 100,
    left: 0,
    width: '100%',
    height: '40px',
    backgroundColor: '#1751D0',
    zIndex: 100,
    padding: '5px 15px 5px 15px',
    color: '#FFFFFF',
  },
  link: {
    color: '#FFFFFF !important',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}))
const ChangeOrderBanner = ({ handleCancelChangeOrder }) => {
  const project = useFormState().values
  const initiatedChangeOrder = getInitiatedChangeOrder(project as ProjectType)
  const soldPaymentTitle = initiatedChangeOrder ? initiatedChangeOrder.payment_option_title : ''
  const translate = useTranslate()
  const classes = useStyles()
  const learnMoreURL =
    'https://support.opensolar.com/hc/en-us/articles/9808231963407-How-to-create-a-Change-Order-or-Amendment-for-a-Sold-Project'
  return (
    <Grid className={classes.container} container wrap="nowrap">
      <Grid item container xs={6} alignItems="center" wrap="nowrap">
        <InfoOutlinedIcon style={{ marginRight: '5px', color: '#FFFFFF' }} />
        {translate('You have initiated Change Order. Please create systems with %{soldPaymentTitle} payment option.', {
          soldPaymentTitle: soldPaymentTitle,
        })}
        <span>
          <Link className={classes.customLink} href={learnMoreURL} target="_blank" rel="noreferrer">
            {translate('Learn More')}
          </Link>
        </span>
      </Grid>
      <Grid item container xs={6} justify="flex-end" alignItems="center" wrap="nowrap">
        <Link onClick={handleCancelChangeOrder} className={classes.link}>
          {translate('Cancel Change Order')}
        </Link>
      </Grid>
    </Grid>
  )
}

export default ChangeOrderBanner

import { UiSwitch } from 'elements/UiSwitch'
import MfaLoginWrapperFormContent from 'pages/mfa/MfaLoginWrapper'
import React, { FC } from 'react'
import { Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import LoginFormContent from './LoginFormContent'
import MfaChallengeFormContent from './MfaChallengeFormContent'
import useLoginFormSubmit from './useLoginFormSubmit'
import useLoginStep from './useLoginStep'

const useStyles = makeOpenSolarStyles((theme: any) => ({
  wrapper: {},
}))

type LoginPageFormPropsType = {
  initialValues?: any
  onSuccess?(): void
  onMfaCancel?(): void
  onMfaFinish?(): void
  AdditionalActions?: React.ReactNode
  AdditionalMessage?: React.ReactNode
  Logo: React.ReactNode
}

const LoginForm: FC<LoginPageFormPropsType> = ({
  initialValues,
  onSuccess,
  onMfaCancel,
  onMfaFinish,
  AdditionalActions,
  AdditionalMessage,
  Logo,
}) => {
  const isLoading = useSelector((state: any) => Boolean(state.admin && state.admin.loading))
  const step = useLoginStep()
  const handleLoginFormSubmit = useLoginFormSubmit({ onSuccess })
  const CoreForm = (
    <Form
      onSubmit={handleLoginFormSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, form, submitting, pristine, values }) => {
        const disableSignIn = pristine || isLoading
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault()
              handleSubmit()
            }}
          >
            {step === 'login' && (
              <LoginFormContent
                disableSignIn={disableSignIn}
                AdditionalMessage={AdditionalMessage}
                AdditionalActions={AdditionalActions}
              />
            )}
            {step === 'mfa-challenge' && (
              <MfaChallengeFormContent
                handleSubmitSms={() => handleLoginFormSubmit({ ...values, token: undefined, send_sms: 1 })}
                onCancel={onMfaCancel}
                disableSignIn={disableSignIn}
              />
            )}
            {step === 'mfa-wrapper' && <MfaLoginWrapperFormContent onCancel={onMfaCancel} onFinish={onMfaFinish} />}
          </form>
        )
      }}
    />
  )

  return (
    <UiSwitch uiKey="account_modal.login">
      {Logo}
      {CoreForm}
    </UiSwitch>
  )
}

export const useLoginPageForm = (props: LoginPageFormPropsType): React.ReactNode => {
  return <LoginForm {...props} />
}

export default LoginForm

import { makeStyles } from '@material-ui/core/styles'
import { permissionsSelectors } from 'ducks/permissions'
import { Button } from 'opensolar-ui'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import { useTranslate } from 'ra-core'
import React from 'react'
import { useSelector } from 'react-redux'
import { ANON_SHARE_CONTACT } from 'types/contacts'
import { Theme } from 'types/themes'
import { COLOR_PALETTE } from '../../../styles'
import ContactInput from './ContactInput'
import ContactLinkExisting from './ContactLinkExisting'

const useStyles = makeStyles((theme: Theme) => ({
  accordionStyle: {
    backgroundColor: COLOR_PALETTE.lightGrey2,
    padding: 0,
  },
  removeBtn: {
    marginLeft: 'auto !important',
  },
}))

interface PropTypes {
  index: number
  contact: any
  handleAssign: (contact: any, index: number) => void
  handleDeleteContact: (id: number) => void
  handleRemove: (index: number) => void
}

const ContactAccordion: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const { contact, index, handleAssign, handleDeleteContact, handleRemove } = props
  const expand = contact?.id !== undefined && contact?.id !== null
  const { allowDelete } = useSelector(permissionsSelectors.getProjectPermissionByKey('info_contact_info_basic'))

  if (contact?.type === ANON_SHARE_CONTACT) return null
  return (
    <AccordionCard
      className={classes.accordionStyle}
      elevation={0}
      defaultExpanded={!expand}
      name={'contact-' + index}
      title={
        !contact?.first_name && !contact?.family_name
          ? !contact?.id
            ? `${translate('Contact')} #${index + 1}`
            : `${translate('Contact')} ${contact.id}`
          : `${contact.first_name || ''} ${contact.family_name || ''}`
      }
      titleIcon={() => <></>}
      content={contact?.assignExisting ? ContactLinkExisting : ContactInput}
      contentProps={{
        index: index,
        source: `contacts_data.${index}`,
        contact: contact,
        handleAssign: contact?.assignExisting
          ? (contact) => {
              handleAssign(contact, index)
            }
          : undefined,
        handleDeleteContact: handleDeleteContact,
      }}
      summaryContent={
        <Button
          variant="text"
          color="error"
          onClick={() => {
            handleRemove(index)
          }}
          className={classes.removeBtn}
          disabled={!allowDelete}
        >
          {translate('Remove from Project')}
        </Button>
      }
    />
  )
}
export default ContactAccordion

import { makeStyles } from '@material-ui/core'
import { useTranslate } from 'ra-core'
import React from 'react'
import { useFormState } from 'react-final-form'
import Schedule from 'resources/batterySchemes/Schedule'
import { Theme } from 'types/themes'
import { currencySymbolForCountry } from 'util/misc'

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  itemWrapper: {
    display: 'flex',
    margin: '5px 3px',
  },
  innerSectionTitle: {
    fontSize: 13,
    margin: 0,
    marginRight: 30,
    fontWeight: theme.typography.fontWeightRegular,
  },
  dataDetail: {
    display: 'flex',
    justifyContent: 'space-between',
    '& p': {
      margin: 0,
      fontSize: 11,
      fontWeight: theme.typography.fontWeightLight,
    },
  },
  hrLight: {
    color: theme.greyLight2,
    height: '1px',
    border: 'none',
    backgroundColor: theme.greyLight2,
  },
  scheduleStyles: {
    display: 'flex',
    marginRight: 30,
    '& .timeWrapper': {
      marginRight: 15,
    },
    '& .columnWrapper': {
      marginRight: 15,
    },
    '& div': {
      width: 'unset',
      fontSize: 11,
      fontWeight: theme.typography.fontWeightLight,
    },
  },
  leftDiv: {
    marginRight: 'auto',
  },
}))

export const FixedRateItemSummary = ({ rateItems, priceAdjustmentFactor = 1.0, taxAdjustmentFactor = 1.0 }) => {
  const classes = useStyles()
  const formState = useFormState()
  const projectCountry = formState.values['country_iso2']
  const currencySymbol = currencySymbolForCountry(projectCountry)

  return rateItems.map((item, rateIndex) => (
    <>
      <div className={classes.itemWrapper}>
        <p className={`${classes.innerSectionTitle} ${classes.leftDiv}`}>{item?.name}</p>
        <div className={classes.dataDetail}>
          <p>
            {currencySymbol}
            {Math.round(item.value * priceAdjustmentFactor * taxAdjustmentFactor * 10000) / 10000} /{' '}
            {item.units.slice(2)}
          </p>
        </div>
      </div>
      {rateIndex === rateItems.length - 1 && <hr />}
    </>
  ))
}

export const RateItemSummary = ({ rateItems, priceAdjustmentFactor = 1.0, taxAdjustmentFactor = 1.0 }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const formState = useFormState()
  const projectCountry = formState.values['country_iso2']
  const currencySymbol = currencySymbolForCountry(projectCountry)

  return rateItems.map((item, rateIndex) => (
    <>
      {item.tou_periods.map((touPeriod, periodIndex) => (
        <div className={classes.itemWrapper}>
          <div className={classes.leftDiv}>
            <p className={classes.innerSectionTitle}>{touPeriod.name}</p>
            {touPeriod.schedule && (
              <div className={classes.scheduleStyles}>
                <Schedule itemList={item.tou_periods} itemIndex={periodIndex} shortDisplay={true} />
              </div>
            )}
          </div>
          <div>
            {touPeriod.blocks.map((block, i) => (
              <div className={classes.dataDetail}>
                {touPeriod.blocks.length > 1 && (
                  <p style={{ marginRight: 25 }}>
                    {touPeriod.blocks.length > i + 1 ? (
                      <>{`${translate('Tier')} ${i + 1} (${touPeriod.blocks
                        .slice(0, i)
                        .reduce((a, b) => a + b.limit, 0)} - 
                      ${touPeriod?.blocks?.slice(0, i + 1).reduce((a, b) => a + b.limit, 0)} ${block?.units.slice(
                        2
                      )})`}</>
                    ) : (
                      <>
                        {`${translate('Tier')} ${i + 1} (> ${touPeriod?.blocks
                          ?.slice(0, i + 1)
                          .reduce((a, b) => a + b.limit, 0)} ${block?.units.slice(2)})`}
                      </>
                    )}
                  </p>
                )}
                <p>{`${currencySymbol}${
                  Math.round(block.value * priceAdjustmentFactor * taxAdjustmentFactor * 10000) / 10000
                } / ${block.units.slice(2)}`}</p>
              </div>
            ))}
          </div>
        </div>
      ))}

      {rateIndex === rateItems.length - 1 && <hr />}
    </>
  ))
}

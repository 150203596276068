// @ts-nocheck
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { hideFullScreenLoader, markCreditAppAsOpened, myEnergySelectors } from 'ducks/myEnergy'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PaymentOptionType } from 'types/paymentOptions'
import { ProjectType } from 'types/projects'
import { logAppOpenedEvent } from '../../../../util/events'
import { TransactionActionButton } from '../../../elements/TransactionStartButton'
import { ActionDataType } from '.././types'
import { removeCreditAppParams } from '../util'

type PropTypes = {
  actionData: ActionDataType
  translate: (raw: string) => string
  showDialog: (transactionType: string, options: { url: string }, isFullWidth?: boolean) => void
  setWaitingForAction: Function
  myeStyles: CSSProperties
  acceptButtonStyle: CSSProperties
  acceptButtonStyleDisabled: CSSProperties
  isPro: boolean
  selectedPaymentOptionName: string
  selectedProject: ProjectType
  selectedPaymentOptionId: number
  paymentOptionData: PaymentOptionType
}
const PlentiApplicationButton: React.FunctionComponent<PropTypes> = (props) => {
  const {
    actionData,
    translate,
    showDialog,
    myeStyles,
    acceptButtonStyle,
    acceptButtonStyleDisabled,
    selectedPaymentOptionName,
    isPro,
    selectedPaymentOptionId,
    paymentOptionData,
  } = props

  const forceOpenCreditApp = useSelector(myEnergySelectors.getShouldForceOpenCreditApp)

  const dispatch = useDispatch()

  useEffect(() => {
    if (forceOpenCreditApp && selectedPaymentOptionId === paymentOptionData?.id) {
      openDialog()
      removeCreditAppParams()
      dispatch(hideFullScreenLoader())
      dispatch(markCreditAppAsOpened())
    }
  }, [forceOpenCreditApp, selectedPaymentOptionId])

  let buttonLabelText = (() => {
    if (actionData.status_title === 'InitiatedButIncomplete') {
      return translate('Continue with Plenti Finance')
    } else if (actionData.status_title === 'ApprovedPendingDocs') {
      return translate('Apply for Plenti Finance ✓')
    } else if (actionData.status_title === 'ApprovedNotTrackingSubstate') {
      return translate('Plenti Finance Approved ✓')
    } else {
      return translate('Apply for Plenti Finance')
    }
  })()

  let extra_actions = []

  if (actionData.status_code === 'available') {
    extra_actions.push(
      <p key={extra_actions.length} className="small">
        {'Clicking "' +
          buttonLabelText +
          '" will take you to the Plenti\'s credit application. OpenSolar does not view or store any of the data you enter in this application.'}
      </p>
    )
  }

  if (actionData.status_description) {
    extra_actions.push(<p>{actionData.status_description}</p>)
  }

  const modifyUrlBasedOnUserType = (url: string) => {
    return url && isPro ? url.replace('workflowType=1', 'workflowType=3') : url
  }

  const openDialog = (e?: MouseEvent) => {
    logAppOpenedEvent(actionData, selectedPaymentOptionName)
    showDialog(
      'PlentiLoanApplication',
      {
        url: modifyUrlBasedOnUserType(actionData.application_url),
      },
      true
    )
  }

  return (
    <TransactionActionButton
      label={buttonLabelText}
      disabled={actionData.status_code !== 'available'}
      myeStyles={myeStyles}
      buttonStyle={actionData.status_code === 'available' ? acceptButtonStyle : acceptButtonStyleDisabled}
      action={actionData.status_code === 'available' ? openDialog : null}
      extra_actions={extra_actions}
      sequenceNumber={1}
    />
  )
}
export default PlentiApplicationButton

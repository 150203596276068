import { Grid } from '@material-ui/core'
import CashFlowSetupChecklist from 'pages/cashFlow/sharedComponents/CashFlowSetupChecklist'
import { useTranslate } from 'ra-core'
import { TextInput } from 'ra-ui-materialui'
import React from 'react'
import { Form } from 'react-final-form'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type PropTypes = {}

const useStyles = makeOpenSolarStyles((theme) => ({
  container: {
    background: theme.white,
    padding: '16px 24px',
    border: '1px solid #e7e7e7',
    borderRadius: '5px',
    margin: '1rem 0',
  },

  header: {
    fontSize: 18,
    fontWeight: 400,
  },

  justifyCenter: {
    justifyContent: 'center',
  },

  row: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },

  divider: {
    margin: '24px 0',
  },

  field: {
    margin: '8px 0',
  },
}))

const Registration: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const onSubmit = () => {}

  return (
    <Form
      onSubmit={onSubmit}
      render={(formProps) => (
        <div>
          <Grid container spacing={2} className={classes.justifyCenter}>
            <Grid item xs={8}>
              <div className={classes.container}>
                <h1 className={classes.header}>{translate('Business Info')}</h1>
                <p>
                  {translate(
                    'Reach out to cashflow@opensolar.com if you have any questions about registration information'
                  )}
                </p>
                <Grid item xs={12}>
                  <Grid item xs={6}>
                    <TextInput
                      label={`Business legal name`}
                      source={`business_legal_name`}
                      fullWidth
                      variant="outlined"
                      className={classes.field}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={6}>
                    <TextInput
                      label={`Business Tax ID`}
                      source={`business_tax_id`}
                      fullWidth
                      variant="outlined"
                      className={classes.field}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextInput
                      label={`Type of business`}
                      source={`business_type`}
                      fullWidth
                      variant="outlined"
                      className={classes.field}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      label={`Date business commenced`}
                      source={`date_business_commenced`}
                      fullWidth
                      variant="outlined"
                      className={classes.field}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label={`Address`}
                    source={`business_address`}
                    fullWidth
                    variant="outlined"
                    className={classes.field}
                  />
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextInput
                      label={`City`}
                      source={`business_city`}
                      fullWidth
                      variant="outlined"
                      className={classes.field}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      label={`State`}
                      source={`business_state`}
                      fullWidth
                      variant="outlined"
                      className={classes.field}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextInput
                      label={`Zip Code`}
                      source={`business_zip`}
                      fullWidth
                      variant="outlined"
                      className={classes.field}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      label={`Company Phone Number`}
                      source={`business_phone_number`}
                      fullWidth
                      variant="outlined"
                      className={classes.field}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label={`Website`}
                    source={`business_website`}
                    fullWidth
                    variant="outlined"
                    className={classes.field}
                  />
                </Grid>
              </div>
            </Grid>
            <Grid item xs={3}>
              <CashFlowSetupChecklist activeStep={5} />
            </Grid>
          </Grid>
        </div>
      )}
    />
  )
}

export default Registration

/**
 * This file intentionally has very few references, as is is referenced
 * by the `ra-core` package, which causes all sorts of problems.
 */
import { MFAConfigType } from '../types/mfa'

export const SET_MFA_CONFIG = 'SET_MFA_CONFIG'
export const SET_MFA_CHECK_REQUIRED = 'SET_MFA_CHECK_REQUIRED'
export const SET_MFA_CONFIG_REQUIRED = 'SET_MFA_CONFIG_REQUIRED'
export const SET_MFA_CODES_SHOWING = 'SET_MFA_CODES_SHOWING'
export const SET_MFA_HAS_DEVICES = 'SET_MFA_HAS_DEVICES'

export const setMfaConfig = (mfaConfig: MFAConfigType) => ({
  type: SET_MFA_CONFIG,
  payload: mfaConfig,
})

export const setMfaCheckRequired = (required: boolean) => ({
  type: SET_MFA_CHECK_REQUIRED,
  payload: required,
})

export const setMfaHasDevices = (sms: boolean, totp: boolean, recovery: boolean) => ({
  type: SET_MFA_HAS_DEVICES,
  payload: { sms, totp, recovery },
})

export const setMfaCodesShowing = (value: boolean) => ({
  type: SET_MFA_CODES_SHOWING,
  payload: { value },
})

export const setMfaConfigRequired = (required: boolean) => ({
  type: SET_MFA_CONFIG_REQUIRED,
  payload: required,
})

import restClient from 'restClient'

export const TOUR_Z_INDEX = 2501

export const recordUserAction = (userId: number, step_name: string) => {
  const action_name = 'fuji_tour-' + step_name
  const API_URL = window.API_ROOT + '/api'
  const restClientInstance = restClient(API_URL)
  restClientInstance('CUSTOM_POST', 'custom', {
    url:
      'users/' +
      //@ts-ignore
      userId +
      '/action/',
    data: {
      action_name,
    },
  })
    .then((res: any) => {
      console.log('res', res)
    })
    .catch((err) => {
      console.log('error saving user action', err)
    })
}

// Warning: don't use this directly, use `useUserActionsContext().recordAction` function instead.
export const recordGenericUserAction = (userId: number, action_name: string) => {
  const API_URL = window.API_ROOT + '/api'
  const restClientInstance = restClient(API_URL)
  return restClientInstance('CUSTOM_POST', 'custom', {
    url:
      'users/' +
      //@ts-ignore
      userId +
      '/action/',
    data: {
      action_name,
    },
  })
    .then((res: any) => {
      console.log('res', res)
    })
    .catch((err) => {
      console.log('error saving user action', err)
    })
}

export const findGenericUserAction = async (userId: number, action_name: string) => {
  return new Promise<{ data?: { exists?: boolean } }>((resolve, reject) => {
    const API_URL = window.API_ROOT + '/api'
    const restClientInstance = restClient(API_URL)
    restClientInstance('CUSTOM_GET', 'custom', {
      url:
        'users/' +
        //@ts-ignore
        userId +
        '/find_action/?action_name=' +
        action_name,
    })
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

import { RootState } from 'types/state'

export const SET_SELECTED_SYSTEM = 'SET_SELECTED_SYSTEM'

export type SystemsReducerState = {
  selectedSystem?: string
}
const initialState: SystemsReducerState = {
  selectedSystem: undefined,
}

export function systemsReducer(state: SystemsReducerState = initialState, action: ActionType): SystemsReducerState {
  switch (action.type) {
    case SET_SELECTED_SYSTEM:
      return {
        ...state,
        selectedSystem: action.selectedSystem,
      }
    default:
      return state
  }
}

interface SetSelectedSystem {
  type: typeof SET_SELECTED_SYSTEM
  selectedSystem: string | undefined
}

type ActionType = SetSelectedSystem

export function setSelectedSystem(value: string | undefined): SetSelectedSystem {
  return {
    type: SET_SELECTED_SYSTEM,
    selectedSystem: value,
  }
}

export const systemsSelectors = {
  selectedSystem: (state: RootState) => {
    return state.designer.systems.selectedSystem
  },
}

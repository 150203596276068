// @ts-nocheck
import CustomField from 'elements/field/CustomField'
import React, { FC } from 'react'
import { FileField, FileInput, TextInput, useTranslate } from 'react-admin'
import FileFieldClearable from '../../elements/field/FileFieldClearable'

type PropsType = {
  pvprosell_template_id: string
  api_key_pvsell: string
  disabled: boolean
  record: any
  projectId: number
}

const inputStyle = {
  wrapper: {
    width: '100%',
  },
  textInput: {
    width: 256,
  },
}

export const fieldsBespoke = [
  { type: 'h2', title: 'Bespoke Template File' },
  { fieldName: 'valid_for_days', type: 'TextInput', fieldLabel: 'Default Proposal Validity (days)' },
  { fieldName: 'bespoke_template_file_contents', type: 'FileField', fieldLabel: 'Bespoke Template File' },
  { fieldName: 'bespoke_template_file_contents', type: 'FileInput' },
  { fieldName: 'bespoke_proposal_config', type: 'TextInputDisabled', fieldLabel: 'Bespoke Proposal Config' },
  { fieldName: 'bespoke_proposal_config_applied', type: 'TextInput', fieldLabel: 'Bespoke Proposal Config Applied' },
]

const fieldsInGroups = () => {
  var fieldGroups = []
  var group = null
  fieldsBespoke.forEach((f) => {
    if (f.type === 'h2') {
      if (group) {
        fieldGroups.push(group)
      }
      group = { title: f.title, fields: [] }
    } else {
      group.fields.push(f)
    }
  })
  fieldGroups.push(group)
  return fieldGroups
}

const BespokeFormContent: FC<PropsType> = ({ disabled, record, projectId }) => {
  const translate = useTranslate()

  return (
    <div style={inputStyle.wrapper}>
      {fieldsInGroups().map((fieldGroup, fieldGroupIndex) => (
        <div
          style={{
            minHeight: 320,
            position: 'relative',
            width: '100%',
            padding: 0,
          }}
        >
          {fieldGroup.fields.map((fieldDetails, i) => {
            switch (fieldDetails.type) {
              case 'h2':
                return <h2 key={fieldGroupIndex + i}>{translate(fieldDetails.title)}</h2>
              case 'TextInput':
                return (
                  <CustomField
                    // style={{ display: 'block' }}
                    key={fieldDetails.fieldName + i}
                    name={fieldDetails.fieldName}
                    source={fieldDetails.fieldName}
                    style={inputStyle.textInput}
                    component={TextInput}
                  />
                )
              case 'TextInputDisabled':
                return (
                  <CustomField
                    // style={{ display: 'block' }}
                    key={fieldDetails.fieldName + i}
                    name={fieldDetails.fieldName}
                    source={fieldDetails.fieldName}
                    style={inputStyle.textInput}
                    component={TextInput}
                    disabled={true}
                  />
                )
              case 'FileField':
                return (
                  <CustomField
                    component={FileFieldClearable}
                    key={fieldGroupIndex + i}
                    source={fieldDetails.fieldName}
                    name={fieldDetails.fieldName}
                    title="title"
                    record={record}
                  />
                )
              case 'FileInput':
                return (
                  <div>
                    <CustomField
                      component={FileInput}
                      key={fieldGroupIndex + i}
                      name={fieldDetails.fieldName}
                      source={fieldDetails.fieldName}
                      label={translate('Upload File')}
                      placeholder={<p>{translate('Drop your file here')}</p>}
                      validate={(file, _record, message = 'File size exceeds size limit (3MB)') => {
                        const fileContents = _record.bespoke_template_file_contents
                        if (fileContents && fileContents.length > 0) {
                          if (_record.bespoke_template_file_contents[0].rawFile?.size > 3000000) {
                            return message
                          }
                        }
                      }}
                    >
                      <FileField source="src" title="title" />
                    </CustomField>
                  </div>
                )

              default:
                return null
            }
          })}
        </div>
      ))}
    </div>
  )
}

export default BespokeFormContent

import { useMediaQuery } from '@material-ui/core'
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined'
import { Button } from 'opensolar-ui'
import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { ProjectType } from 'types/projects'
import { SystemDesignSunlightQuickCreateType } from '../sunlight/types'
import ManageDocusignDialog from './ManageDocusignDialog'

type PropTypes = {
  project: ProjectType
  designs?: SystemDesignSunlightQuickCreateType[]
}

const SungageSendAppButton: React.FunctionComponent<PropTypes> = (props) => {
  const [showDialog, setShowDialog] = useState<boolean>(false)

  const onButtonClick = () => {
    setShowDialog(!showDialog)
  }
  const translate = useTranslate()
  const isMD = useMediaQuery((theme: any) => theme.breakpoints.between('sm', 'md'))

  return (
    <div
      style={{
        width: '100%',
        marginTop: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {/* <ProUXButton */}
      <Button
        // label={'Manage Docusign Contract'}
        onClick={onButtonClick}
        variant="contained"
        color="default"
        style={{ padding: isMD ? '5px 10.5px' : '10.5px 15px', height: isMD ? 'unset' : 40 }}
        // type="secondary"
        // backgroundColor="#fff"
        // fullWidth
        startIcon={<DescriptionIcon />}
        // justifyContent="flex-start"
      >
        <span style={{ textTransform: 'none' }}>{translate('Manage DocuSign Contract')}</span>
      </Button>
      {showDialog && (
        <ManageDocusignDialog project={props.project} open={showDialog} onClose={() => setShowDialog(false)} />
      )}
    </div>
  )
}
export default SungageSendAppButton

import { DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core'
import CustomField from 'elements/field/CustomField'
import CheckboxInput from 'elements/input/CheckboxInput'
import DependentInput from 'elements/input/DependentInput'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import React, { useEffect, useState } from 'react'
import { SelectInput, TextInput } from 'react-admin'
import { useFormState } from 'react-final-form'

type PropTypes = {
  confirmContact: (formVals: Object) => void
  isLoading: boolean
  existingURL: string | undefined
  onClose: () => void
  disabled: boolean
}

const PhoenixContactInfoForm: React.FC<PropTypes> = (props) => {
  const [showExistingQuoteChoice, setShowExistingQuoteChoice] = useState<boolean>(false)

  useEffect(() => {
    if (props.existingURL) setShowExistingQuoteChoice(true)
  }, [props.existingURL])

  const formState = useFormState()

  const submit = () => {
    props.confirmContact(formState.values)
  }

  const goToExistingURL = () => {
    window.open(props.existingURL, '_blank')
  }

  return (
    <>
      <DialogTitle>
        {showExistingQuoteChoice ? 'You Have an Existing Application' : 'Verify Contact Information'}
      </DialogTitle>
      <DialogContent>
        <div style={{ width: '95%' }}>
          {showExistingQuoteChoice ? (
            <div>
              <p>
                It looks like you have already started an application for this project. Would you like to continue to
                your existing application or create a new one?
              </p>
              <div></div>
            </div>
          ) : (
            <>
              <p>
                Please verify your contact information before we can bring you to your finance application -{' '}
                <strong>you will not be able to edit this information on the application itself.</strong>
              </p>
              <Grid container spacing={1}>
                <Grid item sm={2} xs={6}>
                  <CustomField
                    name="cust_salutation"
                    source="cust_salutation"
                    label="Salutation"
                    component={SelectInput}
                    choices={[
                      { id: 'Mrs.', name: 'Mrs.' },
                      { id: 'Ms.', name: 'Ms.' },
                      { id: 'Miss', name: 'Miss' },
                      { id: 'Mr.', name: 'Mr.' },
                      { id: 'Dr.', name: 'Dr.' },
                      { id: 'Prof.', name: 'Prof.' },
                      { id: 'Rev.', name: 'Rev.' },
                      { id: 'Lady', name: 'Lady' },
                      { id: 'Sir', name: 'Sir' },
                    ]}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                  />
                </Grid>
                <Grid item sm={5} xs={6}>
                  <CustomField
                    name="cust_first_name"
                    source="cust_first_name"
                    component={TextInput}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                    label="First Name"
                  />
                </Grid>
                <Grid item sm={5} xs={6}>
                  <CustomField
                    name="cust_last_name"
                    source="cust_last_name"
                    component={TextInput}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                    label="Last Name"
                  />
                </Grid>
                <Grid item sm={6} xs={6}>
                  <CustomField
                    name="cust_telephone"
                    source="cust_telephone"
                    component={TextInput}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                    label="Telephone"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <CustomField
                    name="cust_email"
                    source="cust_email"
                    component={TextInput}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                    label="Email"
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <CustomField
                    name="cust_address_1"
                    source="cust_address_1"
                    component={TextInput}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                    label="Address"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <CustomField
                    name="cust_address_2"
                    source="cust_address_2"
                    component={TextInput}
                    fullWidth
                    style={{ width: '100%' }}
                    label="Address Line 2 (optional)"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <CustomField
                    name="cust_address_3"
                    source="cust_address_3"
                    component={TextInput}
                    fullWidth
                    style={{ width: '100%' }}
                    label="Address Line 3 (optional)"
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <CustomField
                    name="cust_city"
                    source="cust_city"
                    component={TextInput}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                    label="City"
                  />
                </Grid>
                <Grid item sm={4} xs={6}>
                  <CustomField
                    name="cust_county"
                    source="cust_county"
                    component={TextInput}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                    label="County"
                  />
                </Grid>
                <Grid item sm={4} xs={6}>
                  <CustomField
                    name="cust_postcode"
                    source="cust_postcode"
                    component={TextInput}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                    label="Postal Code"
                  />
                </Grid>
                <Grid item sm={12}>
                  <CustomField
                    name="use_same_address"
                    source="use_same_address"
                    label="The address above is where the system will be installed"
                    defaultValue={true}
                    component={(checkboxProps: any) => (
                      <CheckboxInput {...checkboxProps} elStyle={{ maxWidth: '900px' }} />
                    )}
                    fullWidth
                    style={{ width: '100%' }}
                  />
                </Grid>
                <DependentInput dependsOn="use_same_address" value={false}>
                  <>
                    <Grid item sm={12} xs={12}>
                      <CustomField
                        name="inst_address_1"
                        source="inst_address_1"
                        component={TextInput}
                        fullWidth
                        style={{ width: '100%' }}
                        required={true}
                        label="Installation Site Address"
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <CustomField
                        name="inst_address_2"
                        source="inst_address_2"
                        component={TextInput}
                        fullWidth
                        style={{ width: '100%' }}
                        label="Installation Site Address Line 2 (optional)"
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <CustomField
                        name="inst_address_3"
                        source="inst_address_3"
                        component={TextInput}
                        fullWidth
                        style={{ width: '100%' }}
                        label="Installation Site Address Line 3 (optional)"
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <CustomField
                        name="inst_city"
                        source="inst_city"
                        component={TextInput}
                        fullWidth
                        style={{ width: '100%' }}
                        required={true}
                        label="Installation Site City"
                      />
                    </Grid>
                    <Grid item sm={4} xs={6}>
                      <CustomField
                        name="inst_county"
                        source="inst_county"
                        component={TextInput}
                        fullWidth
                        style={{ width: '100%' }}
                        required={true}
                        label="Installation Site County"
                      />
                    </Grid>
                    <Grid item sm={4} xs={6}>
                      <CustomField
                        name="inst_postcode"
                        source="inst_postcode"
                        component={TextInput}
                        fullWidth
                        style={{ width: '100%' }}
                        required={true}
                        label="Installation Site Postal Code"
                      />
                    </Grid>
                  </>
                </DependentInput>
              </Grid>
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {!showExistingQuoteChoice && (
            <p>Clicking “Continue to Application” below will bring you to the application on Phoenix’s website.</p>
          )}
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ margin: '15px' }}>
              {showExistingQuoteChoice ? (
                <ProUXButton
                  type="secondary"
                  onClick={() => setShowExistingQuoteChoice(false)}
                  label="Create New Application"
                />
              ) : (
                <ProUXButton type="secondary" onClick={props.onClose} label="Cancel" />
              )}
            </div>
            <div style={{ margin: '15px' }}>
              {showExistingQuoteChoice ? (
                <ProUXButton
                  type="primary"
                  onClick={goToExistingURL}
                  label="Continue to Existing Application"
                  loading={props.isLoading}
                />
              ) : (
                <ProUXButton
                  type="primary"
                  onClick={submit}
                  label="Continue to Application"
                  loading={props.isLoading}
                  disabled={props.disabled}
                />
              )}
            </div>
          </div>
          {props.existingURL && !showExistingQuoteChoice && (
            <span
              style={{ textAlign: 'center', textDecoration: 'underline', cursor: 'pointer', marginTop: '10px' }}
              onClick={goToExistingURL}
            >
              Or click here to go to your existing application
            </span>
          )}
        </div>
      </DialogActions>
    </>
  )
}
export default PhoenixContactInfoForm

import { TablePagination, Toolbar, useMediaQuery } from '@material-ui/core'
import type { TablePaginationActionsProps } from '@material-ui/core/TablePagination/TablePaginationActions'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import type { Argument as ClassName } from 'classnames'
import classnames from 'classnames'
import { BaseThemeType } from 'opensolar-ui'
import React, { useCallback } from 'react'
import { useTranslate } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import useTranslateParse from 'util/useTranslateParse'
import PaginationCustomActions from './PaginationCustomActions'

const defaultPerPageOptions = [5, 10, 20, 25, 50]

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rowsPerPageDropdownMenu: {
    minWidth: '154px !important',
    padding: '8px 0px 8px 0px',
    gap: '0px',
    borderRadius: '8px 0px 0px 0px',
    opacity: '0px',
  },
  boldNumber: {
    fontWeight: 'bold',
  },
}))

type PaginationPropsType = {
  source?: string
  page: number
  perPage: number
  total: number
  setPage: (page: number) => void
  setPerPage: (perPage: number) => void
  loading?: boolean
  rowsPerPageOptions?: number[]
  actionComponentStart?: React.ReactNode
  actionComponentEnd?: React.ElementType<TablePaginationActionsProps>
  className?: ClassName
}

const Pagination = ({
  source,
  page,
  perPage = 10,
  rowsPerPageOptions = defaultPerPageOptions,
  total,
  setPage,
  setPerPage,
  loading,
  actionComponentStart,
  className,
}: PaginationPropsType) => {
  const translate = useTranslate()
  const translateParse = useTranslateParse()
  const classes = useStyles()
  const isSmall = useMediaQuery((theme: BaseThemeType) => theme.breakpoints.down('sm'))
  const getNbPages = () => Math.ceil(total / perPage) || 1

  /**
   * Warning: material-ui's page is 0-based
   */
  const handlePageChange = useCallback(
    (event, page) => {
      event && event.stopPropagation()
      logAmplitudeEvent('pagination_interacted', {
        action: 'clicked',
        context: 'page',
        source,
      })
      if (page < 0 || page > getNbPages() - 1) {
        throw new Error(
          translate('ra.navigation.page_out_of_boundaries', {
            page: page + 1,
          })
        )
      }
      setPage(page + 1)
    },
    [total, perPage, setPage, translate]
  )
  const handlePerPageChange = useCallback(
    (event) => {
      setPerPage(event.target.value)
      logAmplitudeEvent('pagination_interacted', {
        action: 'clicked',
        context: 'per_page',
        source,
      })
    },
    [setPerPage]
  )
  const labelDisplayedRows = useCallback(
    ({ from, to, count }) => {
      count = count !== -1 ? count : `more than ${to}`
      const fromLabel = translateParse(`<strong> ${from} <strong> - `, {
        strong: (value) => <strong>{value}</strong>,
      })
      const toLabel = translateParse(`<strong>${to}<strong> of `, {
        strong: (value) => <strong>{value}</strong>,
      })
      const countLabel = translateParse(`<strong>${count}<strong>`, {
        strong: (value) => <strong>{value}</strong>,
      })
      return (
        <span>
          {fromLabel} {toLabel} {countLabel}
        </span>
      )
    },
    [classes.boldNumber]
  )

  if (total === 0) {
    return loading ? <Toolbar variant="dense" /> : null
  }

  return (
    <div className={classnames(classes.wrapper, className)}>
      <div>{React.isValidElement(actionComponentStart) && actionComponentStart}</div>
      <TablePagination
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerPageChange}
        ActionsComponent={() => (
          <PaginationCustomActions page={page} totalPages={getNbPages()} handlePageChange={handlePageChange} />
        )}
        component="div"
        labelRowsPerPage={!isSmall && translate('ra.navigation.page_rows_per_page')}
        labelDisplayedRows={labelDisplayedRows}
        rowsPerPageOptions={isSmall ? [] : rowsPerPageOptions}
        SelectProps={{
          MenuProps: {
            classes: { paper: classes.rowsPerPageDropdownMenu },
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            getContentAnchorEl: null,
          },
        }}
      />
    </div>
  )
}

export default React.memo(Pagination)

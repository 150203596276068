import { CircularProgress, DialogActions, DialogContent, TextField } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import { Button, Chip } from 'opensolar-ui'
import React, { useEffect, useMemo, useState } from 'react'
import { Field, Form, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { ProjectType } from 'types/projects'
import InvoiceEmailCustomization from '../InvoiceEmailCustomization'
import InvoiceRecipientField from './InvoiceRecipientField'
import { InvoiceEmail, SimpleInvoiceType } from './types'

const restClientInstance = restClient(window.API_ROOT + '/api')

interface BasePropTypes {
  sendInvoice: (emailConfiguration: InvoiceEmail) => Promise<void>
  previousPage: () => void
  invoice: SimpleInvoiceType | undefined
  isLoading: boolean
}

interface ContentPropTypes extends BasePropTypes {
  project: ProjectType
}

const useStyles = makeOpenSolarStyles((theme) => ({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  toRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  helpText: {
    marginTop: '10px',
    marginBottom: '20px',
    fontSize: '10px',
    color: theme.greyMid1,
  },
  to: {
    marginRight: '6px',
  },
  btnWrapper: {
    padding: '0px 10px',
  },
  actionsRow: {
    padding: '10px',
    borderTop: `1px solid ${theme.greyLight2}`,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

const ReviewAndSendCustomInvoiceContents: React.FC<ContentPropTypes> = (props) => {
  const classes = useStyles()
  const formState = useFormState()

  return (
    <>
      <DialogContent>
        <div>
          <h1>Review and Send Invoice Email</h1>
          <p>Make any final edits and send invoice email to customer(s)</p>
        </div>
        <div className={classes.contentWrapper}>
          <div>
            <div className={classes.toRow}>
              <div className={classes.to}>To:</div>
              {formState.values?.to?.map((email) => (
                <Chip key={email} label={email} />
              ))}
            </div>
            <div className={classes.helpText}>
              Change customer contact on the project or in the edit invoice details workflow to update
            </div>
            <InvoiceRecipientField project={props.project} />
            <div className={classes.helpText}>All contacts except for the customer are bcc'ed on customer emails</div>
            <div>
              <Field name={`subject`}>
                {(fieldProps) => (
                  <div>
                    <TextField
                      name={fieldProps.input.name}
                      label="Subject"
                      value={fieldProps.input.value}
                      onChange={fieldProps.input.onChange}
                      variant="outlined"
                      size="small"
                      fullWidth
                      placeholder=""
                    />
                  </div>
                )}
              </Field>
            </div>
            <div>
              <InvoiceEmailCustomization
                label="Message"
                fieldName="message"
                initialValue={formState?.values?.message}
                hideToolbar={true}
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.actionsRow}>
          <div className={classes.btnWrapper}>
            <Button onClick={props.previousPage} disabled={props.isLoading}>
              Back
            </Button>
          </div>
          <div className={classes.btnWrapper}>
            <Button
              variant="contained"
              color="primary"
              disabled={props.isLoading}
              onClick={() =>
                props.sendInvoice({
                  email_subject: formState.values.subject,
                  email_body: formState.values.message,
                  to_addresses: formState.values.to,
                  bcc_addresses: formState.values.bcc,
                })
              }
            >
              {props.isLoading ? <CircularProgress /> : <span>Send Invoice</span>}
            </Button>
          </div>
        </div>
      </DialogActions>
    </>
  )
}

const ReviewAndSendCustomInvoice: React.FC<BasePropTypes> = (props) => {
  const org = useSelector(orgSelectors.getOrg)
  const project = useFormState().values as ProjectType
  const [subjectTemplate, setSubjectTemplate] = useState<string>('')
  const [bodyTemplate, setBodyTemplate] = useState<string>('')

  useEffect(() => {
    if (props?.invoice?.id) {
      restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${org?.id}/invoices/${props?.invoice?.id}/preview_email/?template=issue_invoice`,
      })
        .then((res) => {
          setSubjectTemplate(res?.data?.subject)
          setBodyTemplate(res?.data?.body)
        })
        .catch((error) => console.error(error.message))
    }
  }, [])

  const initialToValue = useMemo(() => {
    return project?.contacts_data
      ?.filter((contact) => !!contact.email && !contact.email.includes('@os.code'))
      ?.map((con) => con.email)
  }, [project?.contacts])
  return (
    <Form onSubmit={() => {}} initialValues={{ to: initialToValue, subject: subjectTemplate, message: bodyTemplate }}>
      {({ handleSubmit }) => <ReviewAndSendCustomInvoiceContents {...props} project={project} />}
    </Form>
  )
}
export default ReviewAndSendCustomInvoice

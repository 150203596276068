import { transactionSelectors } from 'ducks/transaction'
import { ItemTableRow, getRows } from 'myenergy/elements/PaymentOptions'
import React from 'react'
import { useLocale, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { ProposalDataType } from 'types/proposals'
import { formatCurrencyWithSymbol } from 'util/misc'
import { STRIPE_PAYMENT_METHOD } from '../constants'

const useStyles = makeOpenSolarStyles((them) => ({
  wrapper: {
    marginBottom: '20px',
  },
}))

type PropTypes = {
  html: string | undefined
  proposalData: ProposalDataType
}

const CheckoutQuotationTable: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const classes = useStyles()
  const locale = useLocale()

  const transactionRequestData = useSelector(transactionSelectors.getTransactionRequestData)
  const {
    payment_method,
    payment_amount,
    loan_amount,
    paid_to_date_surcharge_amount,
    surcharge_amount,
    paid_to_date,
    paid_to_date_tax_included,
    balance_outstanding,
    payment_terminology,
    payment_amount_tax_included,
    additional_details,
    down_payment_balance_outstanding,
  } = transactionRequestData

  if (!transactionRequestData || !props.proposalData) return null

  const taxName = props.proposalData?.selectedProject?.proposal_data?.project.tax_name || 'GST'
  const rows = getRows(
    transactionRequestData?.paymentOptionData,
    transactionRequestData?.pricing,
    props.proposalData?.currencySymbol,
    !!props.proposalData?.selectedProject?.proposal_data?.project?.is_commercial,
    props.proposalData?.selectedProject?.proposal_data?.project?.country_iso2 === 'AU',
    transactionRequestData?.quotationConfiguration,
    transactionRequestData?.systemSize,
    translate,
    true,
    1
  )
  return (
    <div className={classes.wrapper} data-testid="quotation-table-display-wrapper">
      {props.html && <div data-testid="quotation-table-renderer" dangerouslySetInnerHTML={{ __html: props.html }} />}
      <table className="transaction-details">
        <tbody>
          {rows.map((row, i) => (
            <ItemTableRow key={i} data={row} />
          ))}
          {paid_to_date > 0 && (
            <tr>
              <td colSpan={2} data-testid="paid-to-date-label">
                {loan_amount ? translate('Down Payment Paid To Date') : translate('Paid To Date')}
              </td>
              <td colSpan={1} data-testid="paid-to-date-amount">
                {formatCurrencyWithSymbol(paid_to_date, props.proposalData?.currencySymbol, locale, 2)}
                {paid_to_date_tax_included > 0 && (
                  <div className="small">
                    {translate('Including')}{' '}
                    {formatCurrencyWithSymbol(paid_to_date_tax_included, props.proposalData?.currencySymbol, locale, 2)}
                    {translate(taxName)}
                  </div>
                )}
                {paid_to_date_surcharge_amount > 0 && (
                  <div className="small">
                    {translate('Including')}{' '}
                    {formatCurrencyWithSymbol(
                      paid_to_date_surcharge_amount,
                      props.proposalData?.currencySymbol,
                      locale,
                      2
                    )}
                    {translate('Payment Fee')}
                  </div>
                )}
              </td>
            </tr>
          )}
          {Boolean(paid_to_date > 0 && !loan_amount) && (
            <tr>
              <td colSpan={2} data-testid="balance-outstanding-label">
                {translate('Balance Outstanding')}
              </td>
              <td colSpan={1} data-testid="balance-outstanding-amount">
                {formatCurrencyWithSymbol(balance_outstanding, props.proposalData?.currencySymbol, locale, 2)}
              </td>
            </tr>
          )}
          {Boolean(paid_to_date > 0 && loan_amount) && (
            <tr>
              <td colSpan={2} data-testid="downpayment-outstanding-label">
                {translate('Down Payment Outstanding')}
              </td>
              <td colSpan={1} data-testid="downpayment-outstanding-amount">
                {formatCurrencyWithSymbol(
                  down_payment_balance_outstanding,
                  props.proposalData?.currencySymbol,
                  locale,
                  2
                )}
              </td>
            </tr>
          )}
          {Boolean(payment_terminology && payment_amount > 0) && (
            <tr className="highlight">
              <td colSpan={2} data-testid="payment-terminology-label">
                {translate(payment_terminology)}
              </td>
              <td colSpan={1}>
                {formatCurrencyWithSymbol(payment_amount, props.proposalData?.currencySymbol, locale, 2)}
                {payment_amount_tax_included > 0 ? (
                  <div className="small" style={{ fontWeight: 'normal' }}>
                    {translate('Including')} &nbsp;
                    {payment_amount_tax_included &&
                      formatCurrencyWithSymbol(
                        payment_amount_tax_included,
                        props.proposalData?.currencySymbol,
                        locale,
                        2
                      )}
                    &nbsp;
                    {translate(taxName)}
                  </div>
                ) : (
                  ''
                )}

                {surcharge_amount > 0 && (
                  <div className="small" style={{ fontWeight: 'normal' }}>
                    {translate('Including')} &nbsp;
                    {formatCurrencyWithSymbol(surcharge_amount, props.proposalData?.currencySymbol, locale, 2)} &nbsp;
                    {payment_method === STRIPE_PAYMENT_METHOD
                      ? translate('Credit Card Payment Fee')
                      : translate('Payment Fee')}
                  </div>
                )}
              </td>
            </tr>
          )}
          {additional_details && additional_details.length > 0 && (
            <tr>
              <td
                colSpan={3}
                className="small"
                style={{ whiteSpace: 'pre-wrap' }}
                data-testid="additional-details-wrapper"
              >
                {additional_details}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
export default CheckoutQuotationTable

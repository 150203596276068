export const SELECT_TARIFF_DIALOG = 'SELECT_TARIFF_DIALOG'
export const SELECT_TARIFF = 'SELECT_TARIFF'
export const SELECT_TARIFF_LOADING = 'SELECT_TARIFF_LOADING'
export const SELECT_TARIFF_FAILURE = 'SELECT_TARIFF_FAILURE'
export const SELECT_TARIFF_SUCCESS = 'SELECT_TARIFF_SUCCESS'

export const setSelectTariffDialog = (
  isOpen,
  projectId,
  countryId,
  currentOrProposed,
  deactivateSceneOnOpen = true
) => ({
  type: SELECT_TARIFF_DIALOG,
  payload: {
    isOpen,
    projectId,
    countryId,
    currentOrProposed,
    deactivateSceneOnOpen,
  },
})

export const selectTariff = (orgId, projectId, utilityTariffOrgId, utilityTariffId, currentOrProposed) => ({
  type: SELECT_TARIFF,
  payload: {
    orgId,
    projectId,
    url: 'projects/' + projectId + '/',
    data: {
      ['utility_tariff_' + currentOrProposed]: utilityTariffId
        ? window.API_BASE_URL + 'orgs/' + utilityTariffOrgId + '/utility_tariffs/' + utilityTariffId + '/'
        : null,
    },
  },
  meta: { resource: 'projects', fetch: 'CUSTOM_PUT', cancelPrevious: false },
})

export default (previousState = {}, { type, meta, payload, requestPayload }) => {
  if (type === '@@router/LOCATION_CHANGE') {
    // Clear all settings on change of location
    //do we need this hack, maybe do this at component umount stage
    const availablePath = ['/design', '/studio', '/energy', '/projects']
    if (
      !availablePath.some((path) => {
        return payload.location.pathname.includes(path)
      })
    ) {
      previousState = { ...previousState, isOpen: false }
    }
  }

  if (type === SELECT_TARIFF_DIALOG) {
    var version = previousState && previousState.version ? previousState.version + 1 : 1

    if (payload.deactivateSceneOnOpen) {
      //only apply for studio
      try {
        if (payload.isOpen === true) {
          var enablePluginsOverride = true
          window.editor && window.editor.interactive(false, undefined, enablePluginsOverride)
          window.MapHelper && window.MapHelper.interactive(false)
        } else {
          window.editor && window.editor.interactive(true)
          window.MapHelper && window.MapHelper.interactive(true)
        }
      } catch (err) {}
    }

    previousState = {
      ...previousState,
      ...{
        isOpen: payload.isOpen,
        projectId: payload.projectId,
        countryId: payload.countryId,
        currentOrProposed: payload.currentOrProposed,
        version: version,
      },
    }
  }

  if (type === SELECT_TARIFF_SUCCESS) {
    previousState = {
      status: 'success',
    }
  }

  if (type === SELECT_TARIFF_LOADING) {
    previousState = {
      status: 'loading',
    }
  }

  if (type === SELECT_TARIFF_FAILURE) {
    previousState = {
      status: 'failure',
    }
  }

  return previousState
}

// @ts-nocheck
import { Dialog, DialogContent, useMediaQuery } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { getAvailableActions, markAsSold, openCreditDecisionDialog } from 'ducks/myEnergy'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import LoadingDots from 'layout/widgets/LoadingDots'
import React, { useEffect, useState } from 'react'
import { SimpleForm, useTranslate } from 'react-admin'
import { useDispatch } from 'react-redux'
import restClient from 'restClient'
import { ProjectType } from 'types/projects'
import { getCommonCreditDecision, getFirstRealContact } from 'util/misc'
import SungageApplicationDialogFooter from './SungageApplicationDialogFooter'
import SungageApplicationForm from './SungageApplicationForm'
import { COBORROWER_PREFIX } from './constants'
import { DisclosureType, SungageDecisionType } from './types'

var restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  open: boolean
  onClose: () => void
  disclosures: DisclosureType
  project: ProjectType
  paymentOptionId: number
  systemUuid: string
  orgId: number
  onDecisionRendered: () => void
  isFullWidth: boolean
}

const SungageApplicationDialog: React.FunctionComponent<PropTypes> = (props) => {
  // at the moment we don't need this. This is just future proofing against LP maybe some day deciding to require a signature from the co-app

  const [loading, setLoading] = useState<boolean>(false)
  const [decision, setDecision] = useState<SungageDecisionType | undefined>(undefined)
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const [creditErrorHeader, setCreditErrorHeader] = useState<string | undefined>(undefined)
  const [creditErrorMsg, setCreditErrorMsg] = useState<string | undefined>(undefined)
  const [pollCount, setPollCount] = useState<number>(0)

  const translate = useTranslate()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery('(max-width:800px)')

  useEffect(() => {
    logAmplitudeEvent('integrated_finance_application_opened', {
      integration: 'sungage',
      project_id: props.project?.id,
      payment_option_id: props?.paymentOptionId,
    })
  }, [])

  useEffect(() => {
    if (!props.project?.contacts || props.project?.contacts?.length === 0) {
      setErrorMsg('Please add a customer to this project before submitting the application')
    }
  }, [props.project?.contacts])

  useEffect(() => {
    if (pollCount > 0) {
      pollForDecision()
    }
  }, [pollCount])

  useEffect(() => {
    if (decision) {
      props.onDecisionRendered()
      if (
        decision === 'Approved' ||
        decision === 'Conditionally approved' ||
        decision === 'Approved for reduced loan amount'
      ) {
        dispatch(markAsSold(props.systemUuid, props.paymentOptionId))
      }
      // wait a bit, then go fetch new customer actions
      setTimeout(() => {
        dispatch(getAvailableActions(props.orgId, props.project.id))
      }, 500)
    }
  }, [decision])

  const getInitialValues = () => {
    if (!props.project) return {}
    // get primary contact's info
    let primaryContact = undefined
    if (props.project.contacts_data?.length) {
      primaryContact = getFirstRealContact(props.project.contacts_data)
    } else {
      primaryContact =
        props.project.contacts && props.project.contacts.length > 0 ? props.project?.contacts[0] : undefined
    }
    const first_name = primaryContact ? primaryContact.first_name : undefined
    const family_name = primaryContact ? primaryContact.family_name : undefined

    // get secondary contact's info if there is one
    const secondaryContact =
      props.project.contacts && props.project.contacts.length > 1 ? props.project?.contacts[1] : undefined

    return {
      first_name: first_name,
      family_name: family_name,
      address: props.project.address,
      locality: props.project.locality,
      state: props.project.state,
      zip: props.project.zip,
      email: primaryContact ? primaryContact.email : undefined,
      phone_number: primaryContact ? primaryContact.phone : undefined,
      autopay: 'yes',
      employment_status: 'Employed',
      property_type: 'Single-Family',
      residence_type: 'Primary',
      [`${COBORROWER_PREFIX}first_name`]: secondaryContact ? secondaryContact.first_name : undefined,
      [`${COBORROWER_PREFIX}family_name`]: secondaryContact ? secondaryContact.family_name : undefined,
      [`${COBORROWER_PREFIX}email`]: secondaryContact ? secondaryContact.email : undefined,
      [`${COBORROWER_PREFIX}phone_number`]: secondaryContact ? secondaryContact.phone : undefined,
    }
  }

  const getValidationError = (formVals: any) => {
    if (!formVals['app_terms'])
      return 'Please read and agree to the terms and conditions at the bottom of the application'
    if (!formVals['e_sign'] || !formVals['terms']) return 'Primary Applicant must agree to terms and conditions'
    if (formVals['has_coborrower']) {
      if (!formVals[`${COBORROWER_PREFIX}e_sign`] || !formVals[`${COBORROWER_PREFIX}terms`])
        return 'Secondary Applicant must agree to terms and conditions'
    }
    if (!formVals['property_type']) return 'Property Type is required'
    if (!formVals['residence_type']) return 'Residence Type is required'
    if (!formVals['years_at_address']) return 'Years at address is required'
    return undefined
  }

  const handleApplicationResponse = (response: any) => {
    if (response?.data?.success && response?.data?.decision) {
      logAmplitudeEvent('integrated_finance_application_submitted', {
        integration: 'sungage',
        decision: getCommonCreditDecision(response.data.decision),
        project_id: props.project?.id,
        payment_option_id: props?.paymentOptionId,
      })
      setDecision(response.data.decision)
      props.onClose()
      const decisionDisplayProps = {
        decision: response.data.decision,
        amountApproved: response.data.amount_approved,
        stips: response.data.stips,
      }
      dispatch(openCreditDecisionDialog('sungage', decisionDisplayProps))
      setLoading(false)
    } else if (!response?.data?.success) {
      if (response?.data?.is_pending) {
        setPollCount(pollCount + 1)
      } else {
        logAmplitudeEvent('integrated_finance_application_submit_error', {
          integration: 'sungage',
          project_id: props.project?.id,
          payment_option_id: props?.paymentOptionId,
        })
        setLoading(false)
        if (response?.data?.credit_error) {
          setErrorMsg(undefined)
          setCreditErrorHeader(response.data.credit_error_type)
          setCreditErrorMsg(response.data.credit_error_message)
        } else if (response?.data?.errorMsg) setErrorMsg(response.data.errorMsg)
        else setErrorMsg('We were unable to submit this credit application')
      }
    }
  }

  const handleApplicationError = (err: any, requestStart: Date) => {
    console.log('err.body', err.body)
    if (err?.body?.credit_error) {
      setLoading(false)
      setErrorMsg(undefined)
      setCreditErrorHeader(err.bodycredit_error_type)
      setCreditErrorMsg(err.body.credit_error_message)
      logAmplitudeEvent('integrated_finance_application_blocked', {
        integration: 'sungage',
        project_id: props.project?.id,
        payment_option_id: props?.paymentOptionId,
      })
    } else if (err?.body?.errorMsg) {
      setErrorMsg(err.body.errorMsg)
      setLoading(false)
    } else {
      // we're in unhandled exception territory now, but this could be a timeout and if it is we want to continue to poll
      let currentTime = new Date()
      // @ts-ignore
      if (currentTime - requestStart >= 29000) {
        setPollCount(pollCount + 1)
      } else {
        setLoading(false)
        logAmplitudeEvent('integrated_finance_application_submit_error', {
          integration: 'sungage',
          project_id: props.project?.id,
          payment_option_id: props?.paymentOptionId,
        })
        setErrorMsg('Something went wrong and we were unable to submit this credit application')
      }
    }
  }

  const submitApplication = (formVals: any) => {
    let error: string | undefined = getValidationError(formVals)
    if (error) {
      logAmplitudeEvent('integrated_finance_application_form_error', {
        integration: 'sungage',
        error: error,
        project_id: props.project?.id,
        payment_option_id: props?.paymentOptionId,
      })
      setErrorMsg(error)
      return
    }
    setLoading(true)
    let requestStart = new Date()
    // @ts-ignore
    restClientInstance('CUSTOM_POST', 'custom', {
      url:
        'orgs/' +
        props.orgId +
        '/projects/' +
        props.project?.id +
        '/systems/' +
        props.systemUuid +
        '/' +
        props.paymentOptionId +
        '/sungage_application/',
      data: {
        ...formVals,
        income: formVals.income ? formVals.income.replace(/[^0-9.,$]/g, '') : undefined,
        coborrower_income: formVals.coborrower_income
          ? formVals.coborrower_income.replace(/[^0-9.,$]/g, '')
          : undefined,
        mortgage_pmt: formVals.mortgage_pmt ? formVals.mortgage_pmt.replace(/[^0-9.,$]/g, '') : undefined,
      },
    })
      .then(handleApplicationResponse)
      .catch((err) => handleApplicationError(err, requestStart))
    try {
      logAmplitudeEvent('integrated_finance_application_submit_attempt', { integration: 'sungage' })
    } catch (ex) {}
  }

  const pollForDecision = () => {
    setTimeout(() => {
      // @ts-ignore
      restClientInstance('CUSTOM_GET', 'custom', {
        url:
          'orgs/' +
          props.orgId +
          '/projects/' +
          props.project?.id +
          '/systems/' +
          props.systemUuid +
          '/' +
          props.paymentOptionId +
          `/sungage_application/check/?poll_count=${pollCount}`,
      })
        .then(handleApplicationResponse)
        .catch((err: any) => {
          console.log('err', err)
        })
    }, pollCount * 1000)
  }

  const confirmBeforeClose = () => {
    if (window.confirm(translate('Are you sure you want to exit your application? All progress will be lost')))
      props.onClose()
  }

  const unblock = () => {
    setCreditErrorMsg(undefined)
  }

  const renderFormContent = () => {
    return (
      <>
        {loading && (
          <>
            <div
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundColor: '#ececec',
                opacity: 0.9,
                zIndex: 9000,
              }}
            />
            <div
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0px 20px',
                zIndex: 9001,
              }}
            >
              <LoadingDots text="Your application is being reviewed. This may take up to 30 seconds. Thank you for your patience" />
            </div>
          </>
        )}
        <div style={{ paddingBottom: '250px' }}>
          {creditErrorMsg && (
            <div
              style={{
                position: 'absolute',
                left: '0px',
                right: '0px',
                bottom: '0px',
                top: '0px',
                backgroundColor: '#fff',
                zIndex: 9000,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: creditErrorMsg,
                  }}
                  style={{ textAlign: 'center' }}
                />

                <p>{translate('Once you have resolved this issue you can re-apply through this proposal.')}</p>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '30px',
                  }}
                >
                  <div style={{ margin: '15px' }}>
                    <ProUXButton type="secondary" label="Dismiss and Re-Apply Later" onClick={props.onClose} />
                  </div>
                  <div style={{ margin: '15px' }}>
                    <ProUXButton type="primary" label="I'm ready to Re-Apply" onClick={unblock} />
                  </div>
                </div>
              </div>
            </div>
          )}
          <SimpleForm initialValues={getInitialValues()} toolbar={<div></div>}>
            <SungageApplicationForm disclosures={props.disclosures} />
            <SungageApplicationDialogFooter
              submitApplication={submitApplication}
              loading={loading}
              onClose={confirmBeforeClose}
              errorMsg={errorMsg}
            />
          </SimpleForm>
        </div>
      </>
    )
  }

  return (
    <Dialog
      maxWidth={'md'}
      open={props.open}
      onClose={confirmBeforeClose}
      fullScreen={isMobile || props.isFullWidth}
      fullWidth={isMobile || props.isFullWidth}
    >
      <div style={{ textAlign: 'center' }}>
        <h1 style={{ fontSize: isMobile ? undefined : '24px' }}>
          {creditErrorMsg
            ? translate('Unable to Submit Application')
            : translate('Apply for Financing with Sungage Financial')}
        </h1>
      </div>
      <DialogContent dividers>{renderFormContent()}</DialogContent>
    </Dialog>
  )
}

export default SungageApplicationDialog

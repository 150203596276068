export const DUPLICATE = 'DUPLICATE'
export const DUPLICATE_FAILURE = 'DUPLICATE_FAILURE'
export const DUPLICATE_LOADING = 'DUPLICATE_LOADING'
export const DUPLICATE_SUCCESS = 'DUPLICATE_SUCCESS'

export const duplicate = (payload, params) => ({
  type: DUPLICATE,
  payload,
  meta: { params: params },
})

import { logAmplitudeEvent } from 'amplitude/amplitude'
import Button from 'elements/button/Button'
import { Link } from 'ra-ui-materialui'
import React, { useMemo } from 'react'
import { useTranslate } from 'react-admin'

export const ExtraCreateButton = ({ extraCreateButton, extraCreateButtonLabel, basePath, resource }) => {
  const translate = useTranslate()

  const onClick = () => {
    logAmplitudeEvent('control_crud_action', {
      type: 'create',
      resource: resource,
      variant: 'empty_page',
    })
  }

  const extraCreateButtonComp = useMemo(() => {
    return extraCreateButton ? React.cloneElement(extraCreateButton, { onClick }) : undefined
  }, [extraCreateButton])

  return (
    <div
      style={{
        marginTop: 100,
        textAlign: 'center',
        padding: '0 12px',
      }}
    >
      {extraCreateButtonComp ? (
        extraCreateButtonComp
      ) : (
        <Link style={{ textDecoration: 'none' }} to={`${basePath}/create`} onClick={onClick}>
          <Button
            variant="contained"
            style={{
              margin: 'auto',
            }}
            name="raCreateButton"
          >
            <span>{translate(extraCreateButtonLabel || 'Create A New Record')}</span>
          </Button>
        </Link>
      )}
    </div>
  )
}

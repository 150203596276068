import { and, ControlProps, rankWith, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { CircularProgress, makeStyles } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import Alert from 'elements/Alert'
import Button from 'elements/button/Button'
import lodash from 'lodash'
import { useTranslate } from 'ra-core'
import * as React from 'react'
import { useMemo, useState } from 'react'
import { useForm } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'types/state'

const tester = rankWith(100, and(uiTypeIs('ReduxButton')))

const useStyles = makeStyles({
  wrapper: {
    marginBottom: '6px',
    display: 'flex',
    alignItems: 'center',
  },
  progressSpinner: {
    margin: '4px 20px 0',
  },
  alert: {
    display: 'inline-block',
    margin: '0 10px',
  },
})

const mapValuesDeep = (v, callback) =>
  lodash.isObject(v) ? lodash.mapValues(v, (v) => mapValuesDeep(v, callback)) : callback(v)

const Renderer: React.FC<ControlProps> = ({ uischema, data, visible }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const form = useForm()
  const translate = useTranslate()
  const [hasClicked, setHasClicked] = useState(false)

  const orgId: string = useSelector(authSelectors.getOrgId)?.toString() || ''
  const projectId: string = useSelector((state: RootState) => state.projectId)?.toString() || ''
  const replaceValues = (value: unknown) => {
    if (typeof value !== 'string') return value
    value = value.replaceAll('${orgId}', orgId).replaceAll('${projectId}', projectId)
    return value
  }

  const action = uischema['action']
  const reduxAction = useMemo(() => mapValuesDeep(action, replaceValues), [action])
  const text = uischema['text']

  const progressStateKey = uischema['progress_state']
  const progressState = useSelector((state: RootState) =>
    progressStateKey ? lodash.get(state, progressStateKey) : false
  )

  const errorStateKey = uischema['error_state']
  const errorState = useSelector((state: RootState) => (progressStateKey ? lodash.get(state, errorStateKey) : false))

  function handleClick() {
    setHasClicked(true)
    /* This is a hack to allow the reducer/saga processing this action
     * to update fields in the form.
     * At the moment this is only used in `loadZoneData.ts`
     */
    reduxAction.form = form
    dispatch(reduxAction)
  }

  if (!visible) return <></>

  return (
    <div className={classes.wrapper}>
      <Button onClick={handleClick} disabled={progressState}>
        {text}
      </Button>
      {progressState && <CircularProgress size={20} className={classes.progressSpinner} />}
      {hasClicked && !progressState && errorState && (
        <Alert classes={{ wrapper: classes.alert }} severity="error" margin="dense">
          {translate(errorState)}
        </Alert>
      )}
    </div>
  )
}

const renderer = withJsonFormsControlProps(Renderer)

export default {
  tester,
  renderer,
}

import type { OsValidityType } from 'Themes'
import type { ForecastStatus } from './type'

export const mapForecastStatusToValidity = (status: ForecastStatus): OsValidityType => {
  switch (status) {
    case 'not_forecast':
      return 'default'
    case 'not_available':
      return 'error'
    case 'available':
      return 'valid'
    case 'below_min':
      return 'warning'
  }
}

import { useMemo } from 'react'
import type { FactoryHookType } from '../type'
import maybeCreateRatingFilterNode from './RatingFilterNodeFactory'

const useRatingFilterNode: FactoryHookType = ({ RendererComponent, options }) => {
  return useMemo(() => {
    return maybeCreateRatingFilterNode({
      RendererComponent,
      componentType: options.componentType,
    })
  }, [options.componentType, RendererComponent])
}

export default useRatingFilterNode

import StartConfig from './Start'
import SearchAddressConfig from './SearchAddress'
import EnterContactDetail from './EnterContactDetail'
import { CheckSite, CreateProject } from './CheckSite'
import ClickDesign from './ClickDesign'
import SelectDesignMode from './SelectDesignMode'
import AlignMap from './AlignMap'
import { SolarTouch } from './DrawPanel'
import { SetPrice } from './SetPrice'
import { ClickPaymentOptionTab, SetPaymentOption } from './ViewPaymentOption'
import ClickProposal from './ClickProposal'
import { Selections, SystemViews, ProposalToolbar } from './ViewOnlineProposal'
import { LandOnlineProposal } from './LandOnlineProposal'
import SaveProject from './SaveProject'
import EnterEnergyUsage from './EnterEnergyUsage'
import ClickEnergy from './ClickEnergy'
import FinishConfig from './Finish'
import { StepType } from '../types'

export const GROUPS = [
  'Enter Address',
  'Project Details',
  'Design Mode',
  'Explore Site',
  'Add Panels',
  'Set Price',
  'Payment Option',
  'Save',
  'View Proposal',
]

const STEPS: StepType[] = [
  {
    step: 'start',
    config: StartConfig,
    page: 'home',
  },
  {
    step: 'search_address',
    group: GROUPS[0],
    config: SearchAddressConfig,
    page: 'home',
  },
  {
    step: 'check_site',
    group: GROUPS[0],
    config: CheckSite,
    page: 'explore',
  },
  {
    step: 'create_project',
    group: GROUPS[0],
    config: CreateProject,
    page: 'explore',
  },
  {
    step: 'enter_contact',
    group: GROUPS[1],
    config: EnterContactDetail,
    page: 'info',
  },
  {
    step: 'click_energy',
    group: GROUPS[1],
    config: ClickEnergy,
    page: 'info',
  },
  {
    step: 'enter_energy_usage',
    group: GROUPS[1],
    config: EnterEnergyUsage,
    page: 'energy',
  },
  {
    step: 'click_design',
    group: GROUPS[1],
    config: ClickDesign,
    page: 'energy',
  },
  {
    step: 'select_design_mode',
    group: GROUPS[2],
    config: SelectDesignMode,
    page: 'design',
  },
  {
    step: 'align_map',
    group: GROUPS[3],
    config: AlignMap,
    page: 'design',
  },
  {
    step: 'solar_touch',
    group: GROUPS[4],
    config: SolarTouch,
    page: 'design',
  },
  {
    step: 'set_price',
    group: GROUPS[5],
    config: SetPrice,
    page: 'design',
  },
  {
    step: 'click_payment_option_tab',
    group: GROUPS[6],
    config: ClickPaymentOptionTab,
    page: 'design',
  },
  {
    step: 'set_payment_option',
    group: GROUPS[6],
    config: SetPaymentOption,
    page: 'design',
  },
  {
    step: 'save_project',
    group: GROUPS[7],
    config: SaveProject,
    page: 'design',
  },
  {
    step: 'view_online_proposal',
    group: GROUPS[8],
    config: LandOnlineProposal,
    page: 'proposal',
  },
  {
    step: 'system_views',
    group: GROUPS[8],
    config: SystemViews,
    page: 'proposal',
  },
  {
    step: 'view_selections',
    group: GROUPS[8],
    config: Selections,
    page: 'proposal',
  },
  {
    step: 'proposal_toolbar',
    group: GROUPS[8],
    config: ProposalToolbar,
    page: 'proposal',
  },
  {
    step: 'finish',
    config: FinishConfig,
    page: 'proposal',
  },
]

export default STEPS

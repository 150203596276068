import { makeStyles } from '@material-ui/core'
import UnarchiveOutlinedIcon from '@material-ui/icons/UnarchiveOutlined'
import { Button, Chip } from 'opensolar-ui'
import React, { useCallback } from 'react'
import { useTranslate } from 'react-admin'

const useStyles = makeStyles(
  (theme) => ({
    button: {
      // margin: '0 2px 0 0',
      margin: '0 5px',
    },
  }),
  { name: 'ShowArchivedButton' }
)

const ShowArchivedButton = (props: any) => {
  const translate = useTranslate()
  const classes = useStyles()
  const { label = 'Show Archived', filterValues, setFilters } = props
  const handleClick = useCallback(() => {
    setFilters({ ...filterValues, show_only_archived: 1 })
  }, [filterValues])
  const handleDelete = useCallback(() => {
    setFilters({ ...filterValues, show_only_archived: 0 })
  }, [filterValues])
  const showArchived = !!filterValues?.show_only_archived
  return showArchived ? (
    <Chip variant="outlined" label={translate(label)} onDelete={handleDelete} />
  ) : (
    <Button
      className={classes.button}
      startIcon={<UnarchiveOutlinedIcon />}
      variant="contained"
      color="default"
      onClick={handleClick}
      style={{ margin: '0 5px' }}
    >
      <span>{translate(label)}</span>
    </Button>
  )
}

export default ShowArchivedButton

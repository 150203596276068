import { useMemo } from 'react'
import type { FactoryHookType } from '../type'
import maybeCreateInverterFilterNode from './InverterTypeFilterNodeFactory'

const useInverterTypeFilterNode: FactoryHookType = ({ RendererComponent, options }) => {
  return useMemo(() => {
    return maybeCreateInverterFilterNode({
      componentType: options.componentType,
      RendererComponent,
    })
  }, [options.componentType, RendererComponent])
}

export default useInverterTypeFilterNode

import { StyleWarningLevel } from './types';
export function makeStyleNotices(styles, rules) {
    if (!styles)
        return undefined;
    var highestLevel = StyleWarningLevel.info;
    var styleNotices = [];
    for (var _i = 0, styles_1 = styles; _i < styles_1.length; _i++) {
        var style = styles_1[_i];
        for (var k in style.values) {
            var value = style.values[k];
            var key = k;
            var rule = rules[key];
            if (rule === undefined)
                continue;
            if (typeof value !== 'string' || value === '')
                continue;
            var level = void 0;
            if (typeof rule.level === 'function') {
                level = rule.level(value);
                if (level === undefined)
                    continue;
            }
            else {
                level = rule.level;
            }
            if (level > highestLevel)
                highestLevel = level;
            // Filter out subkeys with the same value (e.g. border > borderLeft)
            var filterKey = false;
            for (var _a = 0, styleNotices_1 = styleNotices; _a < styleNotices_1.length; _a++) {
                var k_1 = styleNotices_1[_a];
                if (key.startsWith(k_1.key) && k_1.value === value) {
                    filterKey = true;
                    break;
                }
            }
            if (filterKey)
                continue;
            styleNotices.push({
                key: key,
                value: value,
                level: level,
            });
        }
    }
    if (!styleNotices.length)
        return undefined;
    return { styles: styleNotices, highestLevel: highestLevel };
}

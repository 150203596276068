import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Checkbox } from 'opensolar-ui'
import PropTypes from 'prop-types'
import { FieldTitle, useTranslate } from 'react-admin'
import { toCapitalise } from 'util/misc'

const ArrowDown = ({ style }) => (
  <div
    style={{
      cursor: 'pointer',
      width: 0,
      height: 0,
      border: '5px solid transparent',
      borderTop: '5px solid rgb(0,0,0)',
      ...style,
    }}
  />
)

const ArrowUp = ({ style }) => (
  <div
    style={{
      cursor: 'pointer',
      width: 0,
      height: 0,
      border: '5px solid transparent',
      borderBottom: '5px solid rgb(0,0,0)',
      ...style,
    }}
  />
)

const styles = {
  labelContainer: {
    fontSize: 16,
    lineHeight: '24px',
    display: 'inline-block',
    position: 'relative',
    cursor: 'auto',
    marginTop: 14,
  },
  label: {
    lineHeight: '22px',
    zIndex: 1,
    transform: 'scale(0.75)',
    transformOrigin: 'left top',
    pointerEvents: 'none',
    userSelect: 'none',
  },
}

const generateId = (fieldName = '') => {
  const parts = fieldName.split('_').map((str) => toCapitalise(str))
  return `customCheckBox${parts.join('')}`
}

const CustomCheckboxGroupInput = (props) => {
  const translate = useTranslate()

  const {
    options,
    input = {},
    disabledChoices = [],
    handleReorder,
    handleCheck: handleCheckOverride,
    renderExtraComponent,
    choices,
    isRequired,
    label,
    resource,
    source,
  } = props
  const { onChange, value } = input
  const handleCheck = (event, isChecked) => {
    const newValue = isChecked ? [...value, ...[event.target.value]] : value.filter((v) => v !== event.target.value)
    onChange && onChange(newValue)
  }

  const renderCheckbox = (choices, extraMargin = 0) => {
    return choices.map((choice, key) => (
      <div key={choice.id + extraMargin} id={generateId(choice.id)}>
        <div
          className={'sortable-checkbox'}
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
            margin: '5px 20px 5px 0',
            height: 40,
          }}
        >
          {!props.disabled && (
            <div style={{ marginLeft: 10, marginRight: 20 }}>
              <div style={{ marginBottom: 5 }}>
                {choices.length === 1 ? (
                  <ArrowUp
                    style={{
                      opacity: 0.2,
                      cursor: 'not-allowed',
                    }}
                  />
                ) : (
                  <div className="arrow-up" onClick={() => handleReorder(choice.id, 'up')}>
                    <ArrowUp />
                  </div>
                )}
              </div>
              <div>
                {choices.length === 1 ? (
                  <ArrowDown
                    style={{
                      opacity: 0.2,
                      cursor: 'not-allowed',
                    }}
                  />
                ) : (
                  <div className="arrow-down" onClick={() => handleReorder(choice.id, 'down')}>
                    <ArrowDown />
                  </div>
                )}
              </div>
            </div>
          )}
          <FormControlLabel
            key={choice.id}
            style={{ marginLeft: extraMargin, marginRight: 0 }}
            control={
              <Checkbox
                {...options}
                style={{ width: 'auto' }}
                color="primary"
                checked={value.includes(choice.id)}
                value={choice.id}
                disabled={disabledChoices.includes(choice.id)}
                onChange={handleCheckOverride || handleCheck}
              />
            }
            label={translate(choice.label)}
          />
          {/* <div style={{ marginLeft: extraMargin }}>
            <span>{translate(choice.label)}</span>
            <CheckboxGroupInputItem
              {...options}
              checked={value.includes(choice.id)}
              onCheck={handleCheckOverride || handleCheck}
              value={choice.id}
              disabled={disabledChoices.includes(choice.id)}
            />
          </div> */}
          {renderExtraComponent && renderExtraComponent(choice.id)}
        </div>
        {choice.children && renderCheckbox(choice.children, extraMargin + 40)}
      </div>
    ))
  }

  return (
    <div>
      <div style={styles.labelContainer}>
        <div style={styles.label}>
          {label && <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />}
        </div>
      </div>
      {renderCheckbox(choices, 0)}
    </div>
  )
}

CustomCheckboxGroupInput.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  source: PropTypes.string,
  options: PropTypes.object,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
  }),
  isRequired: PropTypes.bool,
  resource: PropTypes.string,
  muiTheme: PropTypes.object.isRequired,
}

CustomCheckboxGroupInput.defaultProps = {
  choices: [],
  options: {},
}

export default CustomCheckboxGroupInput

import {
  Dialog,
  DialogTitleProps,
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import { DeleteOutlineOutlined, SearchOutlined } from '@material-ui/icons'
import { authReload } from 'actions/authActions'
import { ComponentVersions_2_1 } from 'constants/uxVersions'
import { authSelectors } from 'ducks/auth'
import { orgsActions } from 'ducks/orgs'
import Alert from 'elements/Alert'
import { Severities } from 'elements/notificationVariants'
import { TinyCircularProgress } from 'elements/progress/TinyCircularProgress'
import useRemountApp from 'hooks/useRemountApp'
import { StyledDialogContent, StyledDialogTitle } from 'layout/StyledDialog'
import { debounce } from 'lodash'
import { Button, Chip, ComponentVersionsInherit, IconButton } from 'opensolar-ui'
import useSwitchOrg from 'pages/orgSwitcher/useSwitchOrg'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { Confirm, useNotify, useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import restClient from 'restClient'
import { OrgType } from 'types/orgs'
import { Theme } from 'types/themes'

type PropTypes = {
  title: string
  close: () => void
  availableOrgs: OrgType[]
  redirectUrl: Function
}

const useStyle = makeStyles<Theme>(
  (theme: Theme) => ({
    searchInput: {
      width: '100%',
    },
    tableStyles: {
      margin: '20px 0',
      width: 'calc(100% + 16px)',

      '& .MuiTableCell-root': {
        border: 'none',
        padding: '3px 6px',
        ...theme.typography.subtitle1,
      },
    },
    tableHeader: {
      fontWeight: 'bold',
    },
    actionCell: {
      textAlign: 'right',
    },
  }),
  { name: 'SelectOrg' }
)

type SelectOrgDialogTitleProps = {
  onClose?: () => void
}

export const DialogTitle: FC<SelectOrgDialogTitleProps & DialogTitleProps> = (props) => {
  const { children, onClose, ...other } = props
  return (
    <StyledDialogTitle onClose={onClose} disableTypography {...other}>
      {children}
    </StyledDialogTitle>
  )
}

let requestCounter = 0

const SelectOrg: React.FunctionComponent<PropTypes> = (props) => {
  const { title, close, availableOrgs, redirectUrl } = props
  const selectedOrgId = useSelector(authSelectors.getOrgId)
  const user = useSelector(authSelectors.getCurrentUser)
  const currRole = useSelector(authSelectors.getCurrentRole)
  const isStaff = useSelector(authSelectors.getIsStaff)
  const isSuperuser = useSelector(authSelectors.getIsSuperUser)
  const staffAccessCountries = useSelector(authSelectors.getStaffAccessCountries)
  const userRoles = useSelector(authSelectors.getUserRoles)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [searchPending, setSearchPending] = useState(false)
  const [activeOrgsList, setActiveOrgsList] = useState(availableOrgs)
  const [globalOrgsList, setGlobalOrgsList] = useState<any[]>([])
  const [deleteIds, setDeleteIds] = useState({ org: null, role: null })
  const translate = useTranslate()
  const switchOrg = useSwitchOrg()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const notify = useNotify()
  const classes = useStyle()
  const remountApp = useRemountApp()
  const restClientInstance = restClient(window.API_ROOT + '/api')

  const findUserRoleByOrg = (org_id) => {
    return userRoles?.find((x: any) => x.org_id === org_id)
  }

  const staffWarning: { msg: string; severity: Severities; hideSearch?: true } | undefined = useMemo(() => {
    if (!isStaff || isSuperuser) return

    if (staffAccessCountries?.indexOf('*') !== -1)
      return { msg: 'Staff access to all countries available', severity: 'success' }
    if (!staffAccessCountries)
      return {
        msg:
          "You don't have access to any countries, you won't be able to add yourself to orgs.\nContact a superuser to grant you access.",
        severity: 'warning',
        hideSearch: true,
      }
    else
      return {
        msg: `You have access to the following countries: ${staffAccessCountries}.\nContact a superuser for access to more countries.`,
        severity: 'info',
      }
  }, [isStaff, isSuperuser, staffAccessCountries])

  const showSearchInput = (isStaff && !staffWarning?.hideSearch) || availableOrgs.length > 6

  const handleSwitchOrg = (org_id) => {
    if (selectedOrgId !== org_id) {
      switchOrg({ orgId: org_id, reloadOrg: true })

      if (window.RUNNING_AS_APP === true) {
        //   window.ReactNativeWebView.postMessage('RELOAD_APP')
      } else {
        const newPath = (redirectUrl && redirectUrl(org_id)) || '/projects'

        // TODO: Be aware the following code execute before org reload complete
        // To fix properly, we need to either await saga effect by take or refactor to async/await
        if (location.pathname === newPath) {
          // Remount the app
          remountApp()
        } else {
          history.push(newPath)
        }
        close()
      }
    } else if (redirectUrl) {
      history.push(redirectUrl(org_id))
      remountApp()
      close()
    }
  }

  const deleteRole = (roleId, orgId) => {
    const isCurrentRole = currRole?.id === roleId
    if (isCurrentRole) {
      notify('Cannot delete role while in use. Please switch to another role/org first.', 'warning')
    } else {
      restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${orgId}/roles/`,
      })
        .then((response) => {
          if (response.data.length > 1) {
            setDeleteIds({ role: roleId, org: orgId })
            setShowConfirmDialog(true)
          } else {
            notify("Can't delete last member in team", 'warning')
          }
        })
        .catch((error) => {
          console.warn('Failed to load org roles: ', error)
          notify('Failed to remove. Please contact your team Admin.', 'error')
        })
    }
  }

  const handleDelete = () => {
    if (deleteIds?.role && deleteIds?.org) {
      restClientInstance('CUSTOM_DELETE', 'custom', {
        url: `orgs/${deleteIds.org}/roles/${deleteIds?.role}/`,
      })
        .then((response) => {
          setDeleteIds({ org: null, role: null })
          setShowConfirmDialog(false)
          dispatch(authReload())
          notify('Data deleted')
        })
        .catch((error) => {
          console.log('Failed to delete role: ', error)
        })
    }
  }

  const handleJoinAndSwitch = (orgUrl, orgId) => {
    if (!orgUrl && orgId) orgUrl = `${window.API_BASE_URL}orgs/${orgId}/`
    if (!user) return
    dispatch(orgsActions.joinOrgAndSwitch({ orgUrl, orgId, userId: user?.id }))
    close()
  }

  const handleSearch = useCallback((query: string) => {
    if (query) {
      const counter = requestCounter + 1
      requestCounter = counter

      const activeOrgs = availableOrgs.filter(
        (x) => x.name.toLowerCase().includes(query.toLowerCase()) || x.id.toString().includes(query)
      )
      const activeIds = activeOrgs.map((x) => x.id)
      setActiveOrgsList(activeOrgs)

      if (isStaff) {
        restClientInstance('CUSTOM_GET', 'custom', {
          url: `orgs/?search=${encodeURIComponent(query)}`,
        })
          .then((response) => {
            if (requestCounter !== counter) {
              // ignore old response
              return
            }
            setGlobalOrgsList(response.data.filter((x) => !activeIds.includes(x.id)))
            setSearchPending(false)
          })
          .catch((error) => {
            console.log('Failed to retrieve global orgs: ', error)
            setSearchPending(false)
          })
      } else {
        setGlobalOrgsList([])
        setSearchPending(false)
      }
    } else {
      setActiveOrgsList(availableOrgs)
      setGlobalOrgsList([])
      setSearchPending(false)
    }
  }, [])

  const handleSearchDebounced = useCallback(debounce(handleSearch, 1000, { leading: false, trailing: true }), [])

  const onSearchChange = (e) => {
    setSearchPending(true)
    handleSearchDebounced(e.target.value)
  }

  const RenderOrgRows = ({ orgList }) => {
    return (
      <>
        {orgList
          .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
          .map((org) => {
            const role: any = findUserRoleByOrg(org.id)
            return (
              <TableRow key={org.id}>
                <TableCell>
                  <Typography variant="caption">{org.id}</Typography>
                </TableCell>
                <TableCell>{org.name}</TableCell>
                <TableCell>
                  <Chip label={org.country ? org.country.iso2 : org.country_iso2} />
                </TableCell>
                <TableCell>{role?.permissions_role_title}</TableCell>
                <TableCell className={classes.actionCell}>
                  {role ? (
                    <>
                      <Button
                        disabled={selectedOrgId === org.id}
                        variant="contained"
                        onClick={() => {
                          handleSwitchOrg(org.id)
                        }}
                      >
                        {selectedOrgId === org.id ? translate('Selected') : translate('Select Org')}
                      </Button>
                      {(role.is_admin || isStaff) && (
                        <IconButton
                          onClick={() => {
                            deleteRole(role?.id, org?.id)
                          }}
                        >
                          <DeleteOutlineOutlined />
                        </IconButton>
                      )}
                    </>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleJoinAndSwitch(org.url, org.id)
                      }}
                    >
                      {translate('Join & Switch')}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            )
          })}
      </>
    )
  }

  return (
    <Dialog open={true} maxWidth={'md'}>
      <DialogTitle onClose={close}>{title || translate('Select Org')}</DialogTitle>
      <StyledDialogContent dividers>
        <div>
          {showSearchInput && (
            <TextField
              autoFocus
              variant="outlined"
              placeholder="Search org name or ID"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined />
                  </InputAdornment>
                ),
                endAdornment: searchPending && <TinyCircularProgress />,
              }}
              onChange={onSearchChange}
              className={classes.searchInput}
            />
          )}
          {availableOrgs.length === 0 && (
            <Alert severity="warning">
              There is no available organisation in this account. Please click cancel button to continue.
            </Alert>
          )}
          {staffWarning && <Alert severity={staffWarning.severity}>{staffWarning.msg}</Alert>}
          {!!activeOrgsList.length || !!globalOrgsList.length ? (
            <ComponentVersionsInherit versions={ComponentVersions_2_1}>
              <Table className={classes.tableStyles} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCell}></TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="subtitle1" className={classes.tableHeader}>
                        {translate('Org Name')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.tableHeader}>
                        {translate('Country')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.tableHeader}>
                        {translate('Role')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <RenderOrgRows orgList={activeOrgsList} />
                  <RenderOrgRows orgList={globalOrgsList} />
                </TableBody>
              </Table>
              {showConfirmDialog && deleteIds && (
                <Confirm
                  isOpen={showConfirmDialog}
                  title={'Delete team role: ' + deleteIds.role}
                  content={'Are you sure you want to delete this item?'}
                  onClose={() => {
                    setShowConfirmDialog(false)
                  }}
                  onConfirm={handleDelete}
                />
              )}
            </ComponentVersionsInherit>
          ) : (
            ''
          )}
        </div>
      </StyledDialogContent>
    </Dialog>
  )
}
export default SelectOrg

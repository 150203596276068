import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { Button } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import { formatInt } from '../../util/misc'
import { enhancedFormatCurrency } from './utils'

export const FinancialDetailDialog = (props) => {
  //show PBI when the value is greater than 0
  const translate = useTranslate()

  const showBillIncentives = showBillIncentiveColumns(props.proposalData)
  const showCustomerIncentives = showIncentiveColumns(props.proposalData)
  const showTaxEffect = showSolarTaxEffect(props.proposalData)

  const showUpfrontCustomerIncentives =
    props.proposalData.selectedPaymentOption &&
    sumUpfrontCustomerIncentives(props.proposalData) > 0 &&
    (props.proposalData.selectedPaymentOption.payment_type === 'cash' ||
      props.proposalData.selectedPaymentOption.payment_type === 'loan')

  return (
    <div className="ContainerLetter" id="ContainerLetter">
      <Dialog
        open={props.isOpen}
        autoScrollBodyContent={true}
        maxWidth="xl"
        fullWidth={true}
        onBackdropClick={() => props.closeDialog()}
      >
        <DialogContent style={{ padding: 0 }}>
          <div>
            <table className={'mye-detail-table'} style={{ textAlign: 'center', width: '100%' }}>
              <thead>
                <tr style={{ background: props.myeStyles.highlightColorLight, color: props.myeStyles.textColor }}>
                  <th>{translate('Year')}</th>
                  <th>
                    <span style={{ display: 'block' }}>{translate('Electricity Consumption')}</span>
                    <span style={{ display: 'block' }}>(kWh)</span>
                  </th>
                  <th>
                    <span style={{ display: 'block' }}>{translate('Solar Generation')}</span>
                    <span style={{ display: 'block' }}>(kWh)</span>
                  </th>
                  <th>
                    <span style={{ display: 'block' }}>{translate('Utility Bill')}</span>
                    <span style={{ display: 'block' }}>{translate('(before solar)')}</span>
                    <span style={{ display: 'block' }}>({props.proposalData.currencySymbol})</span>
                  </th>
                  <th>
                    <span style={{ display: 'block' }}>{translate('Utility Bill')}</span>
                    <span style={{ display: 'block' }}>{translate('(after solar)')}</span>
                    <span style={{ display: 'block' }}>({props.proposalData.currencySymbol})</span>
                  </th>
                  {showTaxEffect && (
                    <th>
                      <span style={{ display: 'block' }}>{translate('Tax Effect of Solar')}</span>
                      <span style={{ display: 'block' }}>({props.proposalData.currencySymbol})</span>
                    </th>
                  )}
                  {showBillIncentives && BillIncentiveColumnHeader(props.proposalData)}
                  {showBillIncentives && (
                    <th>
                      <span style={{ display: 'block' }}>{translate('Total Energy Spend')}</span>
                      <span style={{ display: 'block' }}>{translate('(After)')}</span>
                      <span style={{ display: 'block' }}>({props.proposalData.currencySymbol})</span>
                    </th>
                  )}
                  <th>
                    <span style={{ display: 'block' }}>{translate('Annual Savings')}</span>
                    <span style={{ display: 'block' }}>{translate('(from solar)')}</span>
                    <span style={{ display: 'block' }}>({props.proposalData.currencySymbol})</span>
                  </th>
                  <th>
                    <span style={{ display: 'block' }}>{translate('System Costs')}</span>
                    <span style={{ display: 'block' }}>({translate('Net of Dealer Incentives')})</span>
                    <span style={{ display: 'block' }}>({props.proposalData.currencySymbol})</span>
                  </th>
                  {showUpfrontCustomerIncentives && (
                    <th>
                      <span style={{ display: 'block' }}>{translate('Customer Incentives')}</span>
                      <span style={{ display: 'block' }}>({translate('Upfront')})</span>
                      <span style={{ display: 'block' }}>({props.proposalData.currencySymbol})</span>
                    </th>
                  )}
                  {showCustomerIncentives && CustomerIncentiveColumnHeader(props.proposalData)}
                  <th>
                    <span style={{ display: 'block' }}>{translate('Net Savings')}</span>
                    <span style={{ display: 'block' }}>({props.proposalData.currencySymbol})</span>
                  </th>
                  <th>
                    <span style={{ display: 'block' }}>{translate('Cumulative Impacts')}</span>
                    <span style={{ display: 'block' }}>({props.proposalData.currencySymbol})</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.cumulativeImpacts.map((cumulativeImpact, index) => {
                  return (
                    <RenderAnnuallyDetail
                      {...props}
                      key={cumulativeImpact + index}
                      index={index}
                      cumulativeImpact={cumulativeImpact}
                      showBillIncentives={showBillIncentives}
                      showCustomerIncentives={showCustomerIncentives}
                      showUpfrontCustomerIncentives={showUpfrontCustomerIncentives}
                      showTaxEffect={showTaxEffect}
                    />
                  )
                })}
              </tbody>
            </table>
          </div>
        </DialogContent>
        <DialogActions style={{ borderTop: '1px solid #c5c5c5' }}>
          <Button style={{ marginRight: 10 }} onClick={() => props.closeDialog()}>
            <span>{translate('Close')}</span>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default FinancialDetailDialog

const RenderAnnuallyDetail = (props) => {
  const {
    index,
    cashFlows,
    cumulativeImpact,
    proposalData,
    showBillIncentives,
    showCustomerIncentives,
    showUpfrontCustomerIncentives,
    showTaxEffect,
  } = props

  return (
    <tr key={cumulativeImpact + index}>
      <td style={{ background: props.myeStyles.highlightColorLight, color: props.myeStyles.textColor }}>
        {new Date().getFullYear() + index}
      </td>
      <td>
        {proposalData && proposalData.selectedSystem && proposalData.selectedSystem.consumption
          ? formatInt(proposalData.selectedSystem.consumption.usage_annual_proposed, window.locale)
          : '--'}
      </td>
      <td>{getSolarGeneration(proposalData, index)}</td>
      <td>{getPaymentOptionVariable(proposalData, 'utility_bill_yearly_current', index)}</td>
      <td>{getPaymentOptionVariable(proposalData, 'utility_bill_yearly_proposed', index)}</td>
      {showTaxEffect && <td>{getPaymentOptionVariable(proposalData, 'tax_effect_of_solar_per_year', index)}</td>}
      {showBillIncentives && BillIncentiveColumns(proposalData, index)}
      {showBillIncentives && (
        <td>{getPaymentOptionVariable(proposalData, 'utility_cost_after_incentives_proposed', index)}</td>
      )}
      <td>{getPaymentOptionVariable(proposalData, 'utility_savings_yearly', index + 1)}</td>
      <td>{getPaymentOptionVariable(proposalData, 'system_cost_including_incentives', index)}</td>
      {showUpfrontCustomerIncentives && <td>{getUpfrontCustomerIncentives(proposalData, index)}</td>}
      {showCustomerIncentives && CustomerIncentiveColumns(proposalData, index)}
      <td>{enhancedFormatCurrency(cashFlows[index], false)}</td>
      <td>{enhancedFormatCurrency(cumulativeImpact, false)}</td>
    </tr>
  )
}

const showIncentiveColumns = (proposalData) => (proposalData.selectedPaymentOption.incentives ? true : false)

const showBillIncentiveColumns = (proposalData) =>
  proposalData.selectedSystem.bills.proposedSelected?.incentives.length > 0

const showSolarTaxEffect = (proposalData) =>
  (proposalData.selectedPaymentOption.tax_effect_of_solar_per_year?.reduce((sum, a) => sum + a, 0) || 0) > 0

const getSolarGeneration = (proposalData, index) =>
  formatInt(
    proposalData.selectedSystem.bills.proposedSelected.bills_yearly[index].annual.solar_generation_kwh || 0,
    window.locale
  )

const getPaymentOptionVariable = (proposalData, variableName, year, multiplier = 1) =>
  formatInt(proposalData.selectedPaymentOption[variableName][year] * multiplier || 0, window.locale)

const sumUpfrontCustomerIncentives = (proposalData) => {
  const isIgnoreTax = proposalData.selectedSystem?.pricing?.ignore_tax_in_financials
  const customerIncentives = proposalData.selectedPaymentOption.pricing.incentive_to_customer.incentives
  let result = 0
  customerIncentives.forEach((incentive) => {
    if (!!incentive.inc_taxes) {
      result += 0
    } else {
      result += isIgnoreTax ? incentive.ex_tax : incentive.inc_tax
    }
  })
  return result
}

const getUpfrontCustomerIncentives = (proposalData, index) => {
  if (index === 0) {
    return formatInt(sumUpfrontCustomerIncentives(proposalData), window.locale)
  } else {
    return formatInt(0, window.locale)
  }
}

const BillIncentiveColumns = (proposalData, index) => {
  const isIgnoreTax = proposalData.selectedSystem?.pricing?.ignore_tax_in_financials
  const billIncentives = proposalData.selectedPaymentOption.proposed_utility_bill_incentives
  return billIncentives.map((incentive) => (
    <td>{formatInt(isIgnoreTax ? incentive.ex_taxes[index] : incentive.inc_taxes[index], window.locale)}</td>
  ))
}

const BillIncentiveColumnHeader = (proposalData) => {
  const billIncentives = proposalData.selectedPaymentOption.proposed_utility_bill_incentives
  return billIncentives.map((incentive) => (
    <th>
      <span style={{ display: 'block' }}>{incentive.title}</span>
      <span> ({proposalData.currencySymbol}) </span>
    </th>
  ))
}

const CustomerIncentiveColumns = (proposalData, index) => {
  const isIgnoreTax = proposalData.selectedSystem?.pricing?.ignore_tax_in_financials
  const customerIncentives = proposalData.selectedPaymentOption.incentives
  return customerIncentives.map((incentive) => (
    <td>{formatInt(isIgnoreTax ? incentive.ex_taxes[index] : incentive.inc_taxes[index], window.locale)}</td>
  ))
}

const CustomerIncentiveColumnHeader = (proposalData) => {
  const customerIncentives = proposalData.selectedPaymentOption.incentives
  return customerIncentives.map((incentive) => (
    <th>
      <span style={{ display: 'block' }}>{incentive.title}</span>
      <span> ({proposalData.currencySymbol}) </span>
    </th>
  ))
}

import { Grid, Typography } from '@material-ui/core'
import { memo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { formatCurrency } from 'util/misc'

export const useTotalCostStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  label: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  cost: {
    fontSize: '18px',
    color: '#018030',
    whiteSpace: 'nowrap',
    textAlign: 'right',
  },
  skeleton: {
    minWidth: 80,
  },
}))

export const TotalCost = memo(
  ({ loading, costWithCurrencySymbol }: { loading: boolean; costWithCurrencySymbol: string }) => {
    const classes = useTotalCostStyles()
    return (
      <Grid container alignItems="center">
        <Grid item xs={8}>
          <Typography variant="h6">Order total:</Typography>
        </Grid>
        <Grid item xs={4}>
          {loading ? (
            <Skeleton className={classes.skeleton} />
          ) : (
            <span className={classes.cost}>{costWithCurrencySymbol}</span>
          )}
        </Grid>
      </Grid>
    )
  }
)

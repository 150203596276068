import { BooleanInput, DateInput, NumberInput, SelectInput, TextInput } from 'ra-ui-materialui'
import React from 'react'

export type GenericFieldType = {
  component: string
  key: string
  label: string
  source: string
  name?: string
  choices?: any
  endAdornment?: string
  startAdornment?: string
  defaultValue?: any
  variant?: 'standard' | 'filled' | 'outlined' | undefined
}

export type GenericInputFieldType = {
  data: GenericFieldType
  style?: any
  fullWidth?: boolean
  disabled?: boolean
  className?: any
}

const GenericInputField: React.FC<GenericInputFieldType> = ({ data, style, fullWidth, disabled, className }) => {
  switch (data.component) {
    case 'SelectInput':
      return (
        <SelectInput
          key={data.key}
          label={data.label}
          source={data.source}
          name={data.name}
          choices={data.choices}
          startAdornment={data.startAdornment}
          endAdornment={data.endAdornment}
          defaultValue={data.defaultValue}
          variant={data.variant}
          fullWidth={fullWidth}
          style={style}
          className={className}
          disabled={disabled}
        />
      )
    case 'BooleanInput':
      return (
        <BooleanInput
          key={data.key}
          label={data.label}
          source={data.source}
          name={data.name}
          endAdornment={data.endAdornment}
          defaultValue={data.defaultValue}
          fullWidth={fullWidth}
          style={style}
          className={className}
          disabled={disabled}
        />
      )
    case 'NumberInput':
      return (
        <NumberInput
          key={data.key}
          label={data.label}
          source={data.source}
          name={data.name}
          startAdornment={data.startAdornment}
          endAdornment={data.endAdornment}
          defaultValue={data.defaultValue}
          variant={data.variant}
          fullWidth={fullWidth}
          style={style}
          className={className}
          disabled={disabled}
        />
      )
    case 'DateInput':
      return (
        <DateInput
          key={data.key}
          label={data.label}
          source={data.source}
          name={data.name}
          startAdornment={data.startAdornment}
          endAdornment={data.endAdornment}
          defaultValue={data.defaultValue}
          variant={data.variant}
          fullWidth={fullWidth}
          style={style}
          className={className}
          disabled={disabled}
        />
      )
    default:
      return (
        <TextInput
          key={data.key}
          label={data.label}
          source={data.source}
          name={data.name}
          startAdornment={data.startAdornment}
          endAdornment={data.endAdornment}
          defaultValue={data.defaultValue}
          variant={data.variant}
          fullWidth={fullWidth}
          style={style}
          className={className}
          disabled={disabled}
        />
      )
  }
}

export default GenericInputField

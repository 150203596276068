const parseContactInitialValues = (formValsOnSave: any, returnedVals: any = {}) => {
  // if user added contacts move those from contacts_new to the saved contacts arrays to make it clear those changes have been saved

  const newInitialValues: any = formValsOnSave
  if (returnedVals?.contacts_data?.length > 0 && returnedVals?.contacts?.length > 0) {
    newInitialValues.contacts_data = returnedVals.contacts_data
    newInitialValues.contacts = returnedVals.contacts
    newInitialValues.contacts_new = []
  }

  return newInitialValues
}

export default parseContactInitialValues

import { Dialog, useMediaQuery } from '@material-ui/core'
import { ControlBreadcrumbLink, DefaultBreadCrumbs, ListBreadCrumbs } from 'elements/BreadCrumbs'
import QuickFilter from 'elements/QuickFilter'
import CustomField from 'elements/field/CustomField'
import ChipsInput from 'elements/input/ChipsInput'
import { List } from 'elements/react-admin/List'
import moment from 'moment'
import React from 'react'
import {
  AutocompleteInput,
  ChipField,
  Create,
  CreateButton,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  FilterComp,
  FunctionField,
  ListButton,
  NotFound,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'
import Calendar from 'react-big-calendar'
import { useForm } from 'react-final-form'
import { connect, useSelector } from 'react-redux'
import { STAGE_CHOICES } from 'resources/projects/choices'
import { styles as standardStyles } from 'styles'
import Button from '../../elements/button/Button'
import DateTimeInput from '../../elements/input/DateTimeInput'
import DependentInput from '../../elements/input/DependentInput'
import { formatTimeStringBaseOnLocale, getRange, parseQueryStringToDictionary, urlToId } from '../../util/misc'

import DefaultActions from '../../elements/DefaultActions'

import CalendarIcon from '@material-ui/icons/DateRangeOutlined'
import ListIcon from '@material-ui/icons/ListOutlined'
import PersonIcon from '@material-ui/icons/PersonOutlined'
import TodayIcon from '@material-ui/icons/TodayOutlined'
import { Link } from 'react-router-dom'

import { setCreateActivityDialog as setCreateActivityDialogAction } from 'ducks/activities'
import { authSelectors } from 'ducks/auth'
import { permissionsSelectors } from 'ducks/permissions'
import { NotifyTeamMembersField } from 'projectSections/elements/fields/AssigneeMultiSelectField'
// import { Button as MuiButton } from '@material-ui/core'

Calendar.setLocalizer(Calendar.momentLocalizer(moment))

const EVENT_TYPE_ID_ACTIVITY_UNSPECIFIED = 29

export const IS_COMPLETE_CHOICES = [
  { id: 0, name: 'Incomplete' },
  { id: 1, name: 'Complete' },
]

const secondsPerHour = 3600

export const DURATION_CHOICES = [
  { id: 900, name: '15 Minutes' },
  { id: 1800, name: '30 Minutes' },
  { id: 2700, name: '45 Minutes' },
  { id: 3600, name: '1 Hour' },
].concat(
  getRange(22).map((i) => ({
    // increment in half-hour blocks
    id: secondsPerHour + i * (secondsPerHour / 2),
    name: Math.floor((60 + i * 30) / 60) + (i % 2 === 1 ? '.5' : '') + ' Hours',
  }))
)

export const EventFilter = (props) => (
  <FilterComp {...props}>
    {/* <TextInput label="pos.search" style={standardStyles.FILTER_FIELD_STYLE} source="q" /> */}
    <ReferenceInput source="project" reference="projects" perPage={1000}>
      <SelectInput
        options={{ style: standardStyles.FILTER_FIELD_STYLE }}
        source="title"
        optionText="title"
        optionValue="id"
      />
    </ReferenceInput>

    <SelectInput
      options={{ style: standardStyles.FILTER_FIELD_STYLE }}
      source="event_type_id"
      choices={EVENT_TYPE_CHOICES}
    />

    <ReferenceInput source="team_members" reference="roles" perPage={1000}>
      <SelectInput
        options={{ style: standardStyles.FILTER_FIELD_STYLE }}
        sortChoices={true}
        source="display"
        optionText="display"
        optionValue="id"
      />
    </ReferenceInput>

    {false && (
      <ReferenceInput label="Contact" source="contact" reference="contacts">
        <SelectInput source="display" optionText="display" optionValue="id" />
      </ReferenceInput>
    )}

    <ReferenceInput source="action" reference="actions">
      <SelectInput
        options={{ style: standardStyles.FILTER_FIELD_STYLE }}
        source="title"
        optionText="title"
        optionValue="id"
      />
    </ReferenceInput>

    <SelectInput
      options={{ style: standardStyles.FILTER_FIELD_STYLE }}
      source="task_status"
      choices={TASK_STATUS_CHOICES}
    />

    <SelectInput
      options={{ style: standardStyles.FILTER_FIELD_STYLE }}
      source="project__stage"
      choices={STAGE_CHOICES}
    />

    <QuickFilter source="is_scheduled" defaultValue={1} />
    <QuickFilter source="is_unscheduled" defaultValue={1} />
    <QuickFilter source="is_archived" defaultValue={1} />
  </FilterComp>
)

// let allViews = Object.keys(Calendar.Views).map((k) => Calendar.Views[k])

// const ListUnscheduledEvents = ({ events }) => {
//   const translate = useTranslate()
//   return (
//     <div>
//       <h1>{translate('Unscheduled Events')}</h1>
//       <p className="small">
//         {translate('Events with status')}={translate('Scheduled but no start date specified')}
//       </p>
//       {events.length === 0 && <div>{translate('No unscheduled events found')}</div>}
//       {events.map((event) => (
//         <div key={event.id}>
//           {event.title}
//           <a
//             href="#"
//             onClick={(e) => {
//               e.preventDefault()
//               window.location.href = '#/events/' + event.id
//             }}
//           >
//             {translate('Edit')}
//           </a>
//           <hr className="light" style={{ marginTop: 5, marginBottom: 5 }} />
//         </div>
//       ))}
//     </div>
//   )
// }

const recordToEvent = (record) => {
  var start = record.start ? new Date(record.start) : null
  var end
  if (record.end && record.start !== record.end) {
    end = new Date(record.end)
  } else if (record.start) {
    end = new Date(record.start)
    end.setHours(end.getHours() + 1)
  } else {
    end = null
  }

  return {
    id: record.id,
    title: record.project_name + ': ' + record.title,
    start: start,
    end: end,
  }
}

const dataToEvents = (ids, data) => {
  var events = []
  ids.forEach((id) => {
    var record = data[id]
    events.push(recordToEvent(record))
  })
  return events
}

export const EVENT_TYPE_CHOICES = [
  { id: 29, name: 'Activity (Miscellaneous)' },
  { id: 0, name: 'Email Invitation Sent' },
  // { id: 1, name: 'Customer Read Invitation Email' },
  // { id: 2, name: 'Customer Viewed Online Proposal' },
  // { id: 3, name: 'Customer Accepted Online Proposal' },
  // { id: 4, name: 'Customer Made a Payment' },
  // { id: 44, name: 'System Changed' },
  { id: 22, name: 'Call (inbound)' },
  { id: 20, name: 'Call (outbound)' },
  { id: 21, name: 'Call (outbound, no answer)' },
  { id: 24, name: 'Email (inbound)' },
  { id: 23, name: 'Email (outbound)' },
  { id: 40, name: 'On-site Installation' },
  { id: 41, name: 'On-site Maintenance Visit' },
  { id: 42, name: 'On-site Sales Visit' },
  // { id: 43, name: 'Task' },
  { id: 30, name: 'Busy' },
  // { id: 45, name: 'Project Assigned' },
]

export const EVENT_TYPES_NOT_EDITABlE = [1, 2, 3, 4, 44, 43, 45, 49, 50]

export const TASK_STATUS_CHOICES = [
  { id: 0, name: 'Not Tracked' },
  { id: 1, name: 'Incomplete' },
  { id: 2, name: 'Complete' },
]

const getResourceLinkPath = ({ record, reference }) => {
  return record && record.id && `/projects/${record.id}/info`
}

export const EventList = ({ accessRights: { allowCreate, allowEdit, allowDelete }, ...props }) => {
  const translate = useTranslate()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  // var reportSelected = detectSelectedReport(props.location.pathname, props.location.search, props.recentProjects)

  return (
    <List
      breadCrumbs={<DefaultBreadCrumbs />}
      titleStyle={{ fontSize: 16, lineHeight: '16px', paddingTop: 0, display: 'block' }}
      title={'All Activities'}
      actions={
        <DefaultActions
          hasArchived={true}
          otherActions={
            <Link style={{ textDecoration: 'none' }} to={'/calendar'} key={2}>
              <Button
                variant="contained"
                color="default"
                // label="Calendar"
                redirect={false}
                startIcon={<CalendarIcon />}
                submitOnEnter={false}
                style={{ margin: 5 }}
              >
                {/* <CalendarIcon /> */}
                <span>{translate('Calendar')}</span>
              </Button>
            </Link>
          }
          createButtonLabel="Activity"
        />
      }
      filters={<EventFilter />}
      perPage={20}
      {...props}
      hasCreate={allowCreate}
    >
      {isDesktop ? (
        <Datagrid>
          <TextField source="title" sortable={false} />

          <ReferenceField
            getResourceLinkPath={getResourceLinkPath}
            label="Project"
            source="project"
            reference="projects"
            sortable={false}
          >
            <ChipField source="address" />
          </ReferenceField>

          <TextField source="modified_date" overrideValueFunc={(value) => formatTimeStringBaseOnLocale(value)} />

          <FunctionField
            source={null}
            label="Actions"
            render={(record, source, rest) => (
              <div>
                {allowEdit && <EditButton useListStyle={true} record={record} basePath={rest.basePath} />}
                {allowDelete && (
                  <DeleteButton
                    useListStyle={true}
                    undoable={false}
                    record={record}
                    resource={rest.resource}
                    redirect={`/${rest.resource}`}
                  />
                )}
              </div>
            )}
            sortable={false}
          />
        </Datagrid>
      ) : (
        <Datagrid>
          <TextField source="title" sortable={false} />

          <ReferenceField
            getResourceLinkPath={getResourceLinkPath}
            label="Project"
            source="project"
            reference="projects"
            sortable={false}
          >
            <ChipField source="address" />
          </ReferenceField>
          <FunctionField
            source={null}
            label="Actions"
            render={(record, source, rest) => (
              <div>
                {allowEdit && <EditButton useListStyle={true} record={record} basePath={rest.basePath} />}
                {allowDelete && (
                  <DeleteButton
                    useListStyle={true}
                    undoable={false}
                    record={record}
                    resource={rest.resource}
                    redirect={`/${rest.resource}`}
                  />
                )}
              </div>
            )}
            sortable={false}
          />
        </Datagrid>
      )}
    </List>
  )
}

export const EventInputs = (props) => {
  const translate = useTranslate()
  return props.record && props.record.is_complete === true ? (
    <EventInputsForCompletedRecord {...props} />
  ) : (
    <EventInputsAll {...props} translate={translate} />
  )
}

export const EventInputsForCompletedRecord = (props) => {
  return (
    <div>
      <div style={{ width: 300, marginRight: 20 }}>
        <p>Activity Title: {props.record.title}</p>
        {props.record && props.record.project && props.record.project_name && (
          <div style={{ marginBottom: 10, marginTop: 20 }}>
            Project:{' '}
            <Link to={'/projects/' + urlToId(props.record.project) + '/info'}>{props.record.project_name}</Link>
          </div>
        )}
        <div>
          <CustomField
            style={{ width: 400 }}
            component={TextInput}
            {...props}
            fullWidth={true}
            multiline={true}
            name="notes"
            source="notes"
          />
        </div>
        <CustomField style={{ width: 400 }} component={DateTimeInput} {...props} name="start" source="start" />
      </div>
      <div style={{ clear: 'both' }} />
    </div>
  )
}

const isCreateForm = (record) => !Boolean(Object.keys(record).length)

const EventInputsAll = (props) => {
  const form = useForm()
  const eventInputStyle = {
    width: 256,
    display: props.noteOnly ? 'none' : 'block',
  }
  return (
    <div>
      {props.projectImmutable || !isCreateForm(props.record) ? null : (
        <CustomField
          component={ReferenceInput}
          {...props}
          label="Project"
          name="project"
          reference="projects"
          optionValueField="url"
          allowEmpty
        >
          <AutocompleteInput
            options={{
              style: eventInputStyle,
              disabled: !isCreateForm(props.record),
              placeholder: 'Enter a project',
            }}
            optionText="address"
            optionValue="url"
          />
        </CustomField>
      )}

      {props.record && props.record.project && props.record.project_name && (
        <div style={{ marginBottom: 40 }}>
          Project: <Link to={'/projects/' + urlToId(props.record.project) + '/info'}>{props.record.project_name}</Link>
        </div>
      )}

      <div style={{}}>
        <CustomField
          style={{ ...eventInputStyle, marginRight: 10 }}
          component={SelectInput}
          label="Event type"
          name="event_type_id"
          source="event_type_id"
          choices={EVENT_TYPE_CHOICES}
        />
      </div>

      <div style={{ maxWidth: 610 }}>
        <CustomField
          component={TextInput}
          fullWidth={true}
          multiline={true}
          label="resources.events.fields.notes"
          name="notes"
          source="notes"
        />
      </div>

      <div style={{ ...eventInputStyle, clear: 'both' }}>
        <CustomField
          style={eventInputStyle}
          component={ReferenceArrayInput}
          label="resources.events.fields.assigned_to"
          name="team_members"
          source="team_members"
          reference="roles"
          optionValueField="url"
        >
          <ChipsInput fullWidth={true} margin="dense" source="display" optionText="display" optionValue="url" />
        </CustomField>
      </div>

      <div style={{ ...eventInputStyle, clear: 'both' }}>
        <NotifyTeamMembersField style={eventInputStyle} chipsProps={{ margin: 'dense' }} />
      </div>

      <DependentInput dependsOn={'task_status'} resolve={(value) => value !== 1 && value !== 2}>
        <Button
          variant={'contained'}
          startIcon={<ListIcon />}
          raised={true}
          style={{ marginRight: 10, marginBottom: 10 }}
          onClick={() => form.mutators.updateField('task_status', 1)}
          // labelStyle={{ lineHeight: '15px' }}
        >
          <span>{props.translate('Track as Task')}</span>
        </Button>
      </DependentInput>

      <DependentInput dependsOn={'task_status'} resolve={(value) => value === 1 || value === 2}>
        <div style={{ ...eventInputStyle, marginRight: 10 }}>
          <CustomField
            component={SelectInput}
            label="Task Status"
            name="task_status"
            source="task_status"
            choices={TASK_STATUS_CHOICES}
          />
        </div>
      </DependentInput>

      <DependentInput dependsOn={'start'} resolve={(value) => !Boolean(value)}>
        <Button
          variant={'contained'}
          startIcon={<TodayIcon />}
          style={{ marginRight: 10, marginBottom: 10, display: props.noteOnly ? 'none' : '' }}
          raised={true}
          onClick={() => form.mutators.updateField('start', new Date())}
          // labelStyle={{ lineHeight: '15px' }}
        >
          <span>{props.translate('Add Schedule')}</span>
        </Button>
      </DependentInput>

      <DependentInput dependsOn={'start'} resolve={(value) => Boolean(value)}>
        <div style={{ ...eventInputStyle, height: 77, marginRight: 20 }}>
          <CustomField style={{ minWidth: '168px' }} component={DateTimeInput} {...props} name="start" source="start" />
        </div>
      </DependentInput>

      <DependentInput dependsOn={'start'} resolve={(value) => Boolean(value)}>
        <div style={{ ...eventInputStyle, marginRight: 20 }}>
          <CustomField
            style={{ minWidth: '168px' }}
            component={SelectInput}
            label="Duration"
            name="duration"
            source="duration"
            choices={DURATION_CHOICES}
          />
        </div>
      </DependentInput>

      <DependentInput dependsOn={'start'} resolve={(value) => Boolean(value)}>
        <div style={{ clear: 'both' }}></div>
      </DependentInput>

      <DependentInput dependsOn={'contact'} resolve={(value) => typeof value === 'undefined'}>
        <Button
          variant={'contained'}
          style={{ marginBottom: 10, display: props.noteOnly ? 'none' : '' }}
          startIcon={<PersonIcon />}
          raised={true}
          onClick={() => form.mutators.updateField('contact', null)}
        >
          <span>{props.translate('Assign Customer')}</span>
        </Button>
      </DependentInput>

      <DependentInput dependsOn={'contact'} resolve={(value) => typeof value !== 'undefined'}>
        <div style={{ ...eventInputStyle, marginRight: 10 }}>
          <CustomField
            style={{ width: '100%' }}
            component={ReferenceInput}
            resource="contacts"
            source="contact"
            name="contact"
            reference="contacts"
            optionValueField="url"
            allowEmpty
          >
            <AutocompleteInput
              sortChoices={true}
              source="display"
              optionText="display"
              optionValue="url"
              options={{ fullWidth: true, placeholder: 'Enter a contact' }}
            />
          </CustomField>
        </div>
      </DependentInput>

      <div style={{ clear: 'both' }} />
    </div>
  )
}

const getDefaultValues = (props) => {
  //e.g. ?start=2019-07-22T16:26:05.338Z
  const queryParams = parseQueryStringToDictionary(props.location.search)

  var data = {}
  if (queryParams.project) {
    data.project = queryParams.project
  }
  if (queryParams.event_type_id) {
    data.event_type_id = parseInt(queryParams.event_type_id)
  }
  if (queryParams.task_status) {
    data.task_status = parseInt(queryParams.task_status)
  }
  if (queryParams.team_members) {
    data.team_members = queryParams.team_members.split(',')
  }
  if (queryParams.start) {
    data.start = queryParams.start
  }
  if (queryParams.duration) {
    data.duration = parseInt(queryParams.duration)
  }

  if (queryParams.end) {
    data.end = queryParams.end
  }
  if (parseInt(queryParams.is_complete) === 1) {
    data.is_complete = true
    if (data.duration) {
      data.duration = 15 * 60
    }
  }

  if (!data.event_type_id) {
    // if activity type not set, use Activity (Unspecified)
    data.event_type_id = EVENT_TYPE_ID_ACTIVITY_UNSPECIFIED
  }

  if (!data.duration) {
    data.duration = 60 * 60
  }

  if (parseInt(queryParams.appointment) === 1) {
    data.appointment = true
  }

  return data
}

const getRedirect = (props) => {
  if (props.location.search.indexOf('calendar') !== -1) {
    return '/calendar'
  } else if (props.location.search.indexOf('project=http') !== -1) {
    var params = parseQueryStringToDictionary(props.location.search)
    if (params.project) {
      return '/projects/' + urlToId(params.project)
    }
  } else {
    return '/events'
  }
}

const buildQuerystring = (orgId, projectId, futureOrPast, noteOnly) => {
  var parts = {
    project: window.API_BASE_URL + 'orgs/' + orgId + '/projects/' + projectId + '/',
  }

  if (futureOrPast === 'past') {
    parts['task_status'] = 2
    // parts['start'] = 'x'
  }

  if (noteOnly) {
    parts['start'] = new Date().toISOString()
  }

  var result = ''
  for (var key in parts) {
    result += key + '=' + parts[key] + '&'
  }
  return result
}

export const EventCreate = (props) => {
  const { allowCreate } = useSelector(permissionsSelectors.getPermissionByKey('activities'))
  return (
    <Create breadCrumbs={<DefaultBreadCrumbs />} {...props}>
      <SimpleForm disabled={!allowCreate} redirect={getRedirect(props)} defaultValue={getDefaultValues(props)}>
        <EventInputs {...props} />
      </SimpleForm>
    </Create>
  )
}

export const EventCreatePopup = connect(
  (state) => ({
    isOpen: state.activities && state.activities.isOpen,
    projectId: state.activities && state.activities.projectId,
    noteOnly: state.activities && state.activities.noteOnly,
    location: {
      hash: '',
      search: buildQuerystring(
        authSelectors.getOrgId(state),
        state.activities.projectId,
        state.activities.futureOrPast,
        state.activities.noteOnly
      ),
      pathname: '/events',
    },
  }),
  { setCreateActivityDialog: setCreateActivityDialogAction }
)((props) => {
  const { allowCreate } = useSelector(permissionsSelectors.getPermissionByKey('activities'))
  return (
    <Dialog
      key={props.version}
      title={null}
      // bodyStyle={{ padding: '0', maxHeight: 580 }}
      autoScrollBodyContent={true}
      actions={null}
      open={props.isOpen}
      // onBackdropClick={() => {
      //   DialogHelper.beforeClose()
      //   props.setCreateActivityDialog(false, null)
      //   props.push('/projects/' + props.projectId)
      // }}
      className="CrudDialog"
    >
      <Create
        {...props}
        hideBreadCrumbs={true}
        resource="events"
        basePath="/events/create"
        title={props.noteOnly ? 'Add Note' : 'Create Activity'}
      >
        <SimpleForm
          disabled={!allowCreate}
          redirect={'/projects/' + props.projectId}
          defaultValue={getDefaultValues(props)}
        >
          <EventInputs {...props} projectImmutable={true} noteOnly={props.noteOnly} />
        </SimpleForm>
      </Create>
    </Dialog>
  )
})

export const EventEdit = ({ accessRights: { allowView, allowCreate, allowEdit, allowDelete }, ...props }) => {
  return (
    <Edit breadCrumbs={<DefaultBreadCrumbs />} {...props} hasDelete={allowDelete}>
      <SimpleForm redirect={getRedirect(props)} defaultValue={getDefaultValues(props)} disabled={!allowEdit}>
        <EventInputs {...props} />
      </SimpleForm>
    </Edit>
  )
}

class CalendarViewReactBigCalendar extends React.Component {
  render() {
    return (
      <div style={{ marginTop: 40 }}>
        <Calendar
          events={dataToEvents(this.props.ids, this.props.data).filter((event) => event.start)}
          step={30}
          style={{ minHeight: 500, height: 'calc(100vh - 300px)' }}
          showMultiDayTimes
          defaultDate={new Date()}
          __components={{ event: CustomEvent }}
          onSelectEvent={(event) => {
            window.location.href = '#/events/' + event.id + '?redirect=calendar'
          }}
          onSelectSlot={(args) => {
            var duration = (args.end - args.start) / 1000

            window.location.href =
              '#/events/create?start=' + args.start.toISOString() + '&duration=' + duration + '&redirect=calendar'
          }}
          __onSelecting={(args) => console.log('onSelecting', args)}
          __onRangeChange={(args) => console.log('onRangeChange', args)}
          views={['month', 'week', 'day', 'agenda']}
          defaultView={'week'}
          popup={true}
          selectable={'ignoreEvents'}
        />
        {/*<ListUnscheduledEvents events={dataToEvents(this.props.ids, this.props.data).filter(event => !event.start)} />*/}
      </div>
    )
  }
}
//
// This tries to use the List component for managing the events.
// To use this, we would need to set the start date filters
//  a) initially automatically using same logic as Calendar
//  b) update dynamically based on onRangeChange

const _EventCalendar = (props) => {
  const { allowView, allowCreate } = useSelector(permissionsSelectors.getPermissionByKey('activities'))
  // var reportSelected = detectSelectedReport(props.location.pathname, props.location.search, props.recentProjects)
  if (!allowView) {
    return <NotFound />
  }

  const breadcrumbs = [ControlBreadcrumbLink, { title: 'Activities', link: '/events' }, { title: 'Calendar' }]

  return (
    <List
      breadCrumbs={<ListBreadCrumbs links={breadcrumbs} />}
      actions={
        <DefaultActions
          createButtonLabel="Add Activity"
          otherActions={[
            <ListButton basePath={'events'} style={{ marginRight: 5 }} />,
            allowCreate && <CreateButton basePath={'events'} key={3} />,
          ]}
        />
      }
      pagination={null}
      filters={<EventFilter />}
      filter={{
        event_type_id__in: EVENT_TYPE_CHOICES.map((choice) => choice.id).join(','),
        ordering: '-modified_date',
      }}
      {...props}
      showViewEvenWhenNoResults={true}
      showButtons={true}
      hasCreate={false}
      titleStyle={{ fontSize: 16, lineHeight: '16px', paddingTop: 0 }}
      title="Calendar"
      location={{
        hash: '',
        search:
          '?filter=' +
          JSON.stringify({ ...props.filter, ...{ is_complete: 'false' } }) +
          '&page=' +
          props.page +
          '&perPage=' +
          props.perPage +
          '&sort=' +
          props.sort,
        pathname: '/calendar',
      }}
      resource="events"
    >
      <CalendarViewReactBigCalendar />
    </List>
  )
}

// Stand-alone event calendar which only loads the records in the date range
// Not really necessary, because filtering by date range is unneccessary until we have >100s of events
// class _EventCalendar extends React.Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       events: [],
//     }
//   }
//   loadData(start, end) {
//     console.log('loadData')
//
//     restClientInstance('GET_LIST', 'events', {}).then(response => {
//       console.log(response)
//       this.setState({
//         events: response.data.map(record => recordToEvent(record)),
//       })
//     })
//   }
//   render() {
//     return (
//       <div>
//         <Calendar
//           events={this.state.events}
//           views={allViews}
//           step={30}
//           showMultiDayTimes
//           defaultDate={new Date()}
//           __components={{ event: CustomEvent }}
//           onSelectEvent={event => {
//             console.log(event)
//             window.location.href = '#/events/' + event.id
//           }}
//           onSelectSlot={args => {
//             window.location.href =
//               '#/events/create?start=' + args.start.toISOString() + '&end=' + args.end.toISOString() + ''
//           }}
//           onSelecting={args => console.log('onSelecting', args)}
//           onRangeChange={args => {
//             var start, end
//             if (args.start) {
//               start = args.start
//             } else {
//               start = args[0]
//             }
//             if (args.end) {
//               end = args.end
//             } else {
//               end = args[args.length - 1]
//             }
//             console.log('onRangeChange', args)
//             this.loadData(start, end)
//           }}
//           views={['month', 'week', 'day', 'agenda']}
//           defaultView={'week'}
//           popup={true}
//           selectable={'ignoreEvents'}
//         />
//       </div>
//     )
//   }
// }

const mapStateToProps = (state) => {
  var page
  try {
    page = state.admin.resources.events.list.params.page
  } catch (e) {
    page = 1
  }

  var filter
  try {
    filter = state.admin.resources.events.list.params.filter
  } catch (e) {
    filter = {}
  }

  var version
  try {
    version = state.events.version
  } catch (e) {
    version = 1
  }

  return {
    version: version,
    orgId: state.auth.org_id,
    projectId: state.selectTariff.projectId,
    currentOrProposed: state.selectTariff.currentOrProposed,
    page: page,
    filter: filter,
    query: {},
    // @TODO: We need to reload on each page
    perPage: 1000,
  }
}

export const EventCalendar = connect(mapStateToProps, {})(_EventCalendar)

import { ManageIntegrationConfigMapType } from './types'

export const MANAGE_INTEGRATION_CONFIG_MAP = {
  sungage: {
    type: 'dialog',
    content: {
      header: 'Which Sungage tax credit product do you use?',
      p1:
        'Select your preferred Sungage tax credit product and then refresh payment options to ensure your proposals have accurate pricing and financial details.',
      fields: [
        {
          source: 'org_configs.sungage_tax_product',
          type: 'select',
          label: 'Sungage Tax Credit Product',
          choices: [
            { id: 'classic_18', name: 'Classic 18' },
            { id: 'sungage_advantage', name: 'Sungage Advantage' },
          ],
        },
      ],
    },
    feature_flag_filter: undefined,
  },
  mosaic: {
    type: 'dialog',
    content: {
      header: 'Which Mosaic products would you like to refresh?',
      p1:
        'To refresh and import only the default products configured in your Mosaic portal, choose "Defaults". Otherwise, choose "All Products".',
      fields: [
        {
          source: 'org_configs.mosaic_portal_import_type',
          type: 'select',
          label: 'Mosaic Portal Import Type',
          choices: [
            { id: 'defaults', name: 'Defaults' },
            { id: 'all_products', name: 'All Products' },
          ],
        },
      ],
    },
    feature_flag_filter: undefined,
  },
} as ManageIntegrationConfigMapType

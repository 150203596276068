// in src/users.js
import { Grid } from '@material-ui/core'
import GenericInputField from 'elements/input/GenericInputField'
import { BooleanInput, NumberInput, SelectInput, useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'
import DependentInput from '../../elements/input/DependentInput'
import AppliedOverTimeInputs from './AppliedOverTimeInputs'
import {
  FIXED_INCENTIVE,
  getIncentiveVariables,
  INCENTIVES_WITH_PAID_TO_SELECTION,
  INCENTIVE_PAID_TO_CHOICES,
  INCENTIVE_PAID_TO_CUSTOMER,
  INCENTIVE_TYPES_WITH_APPLIED_OVER_TIME,
  PEAK_HOURS_BATTERY_INCENTIVE,
  SELF_GENERATION_INCENTIVE_PROGRAM,
  SWEDEN_GREEN_DEDUCTION_INCENTIVE,
} from './IncentiveConstants'
import SelfGenerationPBIInputs from './SelfGenerationPBIInputs'
import useStyle from './styles'

const IncentiveInputFields = ({ disabled, currencySymbol }) => {
  const classes = useStyle()
  const translate = useTranslate()
  const formState = useFormState()

  const incentiveType = formState.values.incentive_type
  const isAppliedOverTimePeriod = formState.values.is_applied_over_timeperiod
  const incentiveVariables = getIncentiveVariables(incentiveType, currencySymbol)

  return (
    <>
      <Grid container direction="row">
        {incentiveVariables.map(
          (fieldData) =>
            !fieldData.skip && (
              <Grid item xs={12} lg={6} xl={3} className={classes.grid}>
                <GenericInputField data={{ ...fieldData }} className={classes.defaultFieldStyle} />
              </Grid>
            )
        )}
      </Grid>
      <DependentInput dependsOn="incentive_type" resolve={(value) => value === SELF_GENERATION_INCENTIVE_PROGRAM}>
        <SelfGenerationPBIInputs />
      </DependentInput>
      <DependentInput
        dependsOn="incentive_type"
        resolve={(value) => INCENTIVE_TYPES_WITH_APPLIED_OVER_TIME.includes(value)}
      >
        <AppliedOverTimeInputs enableByUtilityBill={true} />
      </DependentInput>
      <DependentInput
        dependsOn="incentive_type"
        resolve={(value) => ![FIXED_INCENTIVE, SWEDEN_GREEN_DEDUCTION_INCENTIVE].includes(value)}
      >
        <Grid container direction="row" className={classes.grid}>
          <NumberInput
            source="cap_amount"
            label="Cap Amount"
            className={classes.defaultFieldStyle}
            startAdornment={currencySymbol}
            endAdornment={translate('optional')}
            disabled={disabled}
          />
        </Grid>
      </DependentInput>
      {!isAppliedOverTimePeriod && INCENTIVES_WITH_PAID_TO_SELECTION.includes(incentiveType) && (
        <Grid container direction="row" className={classes.grid}>
          <SelectInput
            className={classes.selectInputField}
            source="incentive_paid_to"
            label="Incentive Paid To"
            choices={INCENTIVE_PAID_TO_CHOICES.map((c) => {
              return { id: c.id, name: c.name }
            })}
            default={INCENTIVE_PAID_TO_CUSTOMER}
          />
        </Grid>
      )}
      {incentiveType !== PEAK_HOURS_BATTERY_INCENTIVE && (
        <DependentInput dependsOn="incentive_paid_to" resolve={(value) => value === INCENTIVE_PAID_TO_CUSTOMER}>
          <Grid container direction="row" className={classes.grid}>
            <BooleanInput
              className={classes.toggleField}
              label="Use for Loan Pay Down"
              source="use_for_loan_pay_down"
              disabled={disabled}
            />
          </Grid>
        </DependentInput>
      )}
    </>
  )
}

export default IncentiveInputFields

import { permissionsSelectors } from 'ducks/permissions'
import React from 'react'
import { useSelector } from 'react-redux'
import { RoleCreate } from 'resources/roles/Roles'
import CustomizedToolbar from './CustomizedToolbar'

const AddRole = (props: any) => {
  const { onDismiss, onSuccess, onFailure, onDelete, ...rest } = props
  const accessRights = useSelector(permissionsSelectors.getPermissionByKey('team'))
  return (
    <RoleCreate
      accessRights={accessRights}
      toolbar={
        <CustomizedToolbar onDismiss={onDismiss} onSuccess={onSuccess} onFailure={onFailure} onDelete={onDelete} />
      }
      hideBreadCrumbs={true}
      resource={'roles'}
      style={{ paddingBottom: 0 }}
      basePath={'/roles'}
      match={{
        isExact: true,
        params: {},
        path: '/roles/create',
      }}
      location={{ pathname: '/roles/create', search: '' }}
      {...rest}
    />
  )
}

export default AddRole

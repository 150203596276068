var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @ts-nocheck
import { ConnectedRouter } from 'connected-react-router';
import { createHashHistory } from 'history';
import { useContext } from 'react';
import { Provider, ReactReduxContext } from 'react-redux';
import { AuthContext, convertLegacyAuthProvider } from '../auth';
import { convertLegacyDataProvider, DataProviderContext } from '../dataProvider';
import TranslationProvider from '../i18n/TranslationProvider';
import createAdminStore from './createAdminStore';
var CoreAdminContext = function (_a) {
    var authProvider = _a.authProvider, dataProvider = _a.dataProvider, i18nProvider = _a.i18nProvider, children = _a.children, history = _a.history, customReducers = _a.customReducers, customSagas = _a.customSagas, initialState = _a.initialState;
    var reduxIsAlreadyInitialized = !!useContext(ReactReduxContext);
    if (!dataProvider) {
        throw new Error("Missing dataProvider prop.\nReact-admin requires a valid dataProvider function to work.");
    }
    var finalAuthProvider = authProvider instanceof Function ? convertLegacyAuthProvider(authProvider) : authProvider;
    var finalDataProvider = dataProvider instanceof Function ? convertLegacyDataProvider(dataProvider) : dataProvider;
    var finalHistory = history || createHashHistory();
    var renderCore = function () {
        return (_jsx(AuthContext.Provider, __assign({ value: finalAuthProvider }, { children: _jsx(DataProviderContext.Provider, __assign({ value: finalDataProvider }, { children: _jsx(TranslationProvider, __assign({ i18nProvider: i18nProvider }, { children: typeof window !== 'undefined' ? (_jsx(ConnectedRouter, __assign({ history: finalHistory }, { children: children }))) : (children) })) })) })));
    };
    if (reduxIsAlreadyInitialized) {
        if (!history) {
            throw new Error("Missing history prop.\nWhen integrating react-admin inside an existing redux Provider, you must provide the same 'history' prop to the <Admin> as the one used to bootstrap your routerMiddleware.\nReact-admin uses this history for its own ConnectedRouter.");
        }
        return renderCore();
    }
    else {
        // console.log(
        //   createAdminStore({
        //     authProvider: finalAuthProvider,
        //     customReducers,
        //     customSagas,
        //     dataProvider: finalDataProvider,
        //     initialState,
        //     history: finalHistory,
        //   }).getState()
        // )
        return (_jsx(Provider, __assign({ store: createAdminStore({
                authProvider: finalAuthProvider,
                customReducers: customReducers,
                customSagas: customSagas,
                dataProvider: finalDataProvider,
                initialState: initialState,
                history: finalHistory,
            }) }, { children: renderCore() })));
    }
};
export default CoreAdminContext;

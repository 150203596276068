import { CloseOutlined } from '@material-ui/icons'
import { authSelectors } from 'ducks/auth'
import Button from 'elements/button/Button'
import { StyledDialog, StyledDialogContent } from 'layout/StyledDialog'
import { useNotify, useTranslate } from 'ra-core'
import React from 'react'
import { useSelector } from 'react-redux'
import { restClientInstance } from 'resources/paymentOptions/financialIntegrations/FinancialIntegration'
import { StudioSystemType } from 'types/global'
import { ProjectType } from 'types/projects'
import { useStyles } from './panels/styles'

interface PropTypes {
  close: Function
  isOpen: boolean
  project: ProjectType
  system: StudioSystemType
}

const EmailConfirmDialog: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const email = useSelector(authSelectors.getCurrentUser)?.email
  const orgId = useSelector(authSelectors.getOrgId)
  const notify = useNotify()

  const sendEmail = (e) => {
    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${orgId}/projects/${props.project.id}/email_docx_report/`,
      data: {
        // applicationPaymentOptionId: props.system.payment_options[0].id,
        applicationSystemUuid: props.system.uuid,
        email: email,
      },
    })
      .then((res) => {
        notify('Report has been sent to your email.')
      })
      .catch((err) => {
        notify('Report email sending failed. Please try again.', 'warning')
      })
    props.close()
  }

  return (
    <StyledDialog open={props.isOpen}>
      <StyledDialogContent>
        <div className={classes.row}>
          <h1>{translate('Email Report')}</h1>
          <button className={classes.closeIcon} onClick={() => props.close()}>
            <CloseOutlined />
          </button>
        </div>
        <div className={classes.confirmDialogBody}>
          {translate('This report will be sent to: ')} <strong>{email}</strong>
        </div>
        <div className={classes.confirmDialogButtons}>
          <Button variant="contained" onClick={() => props.close()}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" style={{ marginLeft: 10 }} onClick={sendEmail}>
            Email Report
          </Button>
        </div>
      </StyledDialogContent>
    </StyledDialog>
  )
}

export default EmailConfirmDialog

import React, { useEffect } from 'react'
import { useForm } from 'react-final-form'

type PropTypes = {
  disableEditing: () => void
}

const DragAndDropEditWarning: React.FunctionComponent<PropTypes> = (props) => {
  const form = useForm()

  useEffect(() => {
    if (form.getState().values?.file_contents_url) {
      props.disableEditing()
    }
  }, [form])

  return null
}
export default DragAndDropEditWarning

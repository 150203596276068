import { Chip } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type PropTypes = {}

const useStyles = makeOpenSolarStyles((theme) => ({
  chip: {
    backgroundColor: theme.alertBg_warning,
    margin: '4px 0px 4px 4px',
  },
}))

const IntegratedChip: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  return <Chip className={classes.chip} label={translate('Integrated')} size="small" />
}
export default IntegratedChip

// @ts-nocheck
import React from 'react'
import { useSelector } from 'react-redux'
import { RoleEdit } from 'resources/roles/Roles'
import { getRoleIdFromState } from 'util/misc'
import CustomizedToolbar from './CustomizedToolbar'

const EditPersonalDetail = (props: any) => {
  const { onDismiss, onSuccess, onFailure, onDelete, ...rest } = props
  const userId = useSelector(getRoleIdFromState)

  // Hard coded because this only ever edits the current user's role which is always editable
  // We should ideally adopt an existing way to handle this rather than using my hack.
  const accessRights = { allowView: true, allowCreate: false, allowEdit: true, allowDelete: false }

  return (
    <RoleEdit
      accessRights={accessRights}
      toolbar={
        <CustomizedToolbar onDismiss={onDismiss} onSuccess={onSuccess} onFailure={onFailure} onDelete={onDelete} />
      }
      hideBreadCrumbs={true}
      resource={'roles'}
      style={{ paddingBottom: 0 }}
      basePath={'/roles'}
      match={{
        params: { id: userId },
        isExact: true,
        path: '/roles/:id',
        url: '/roles/' + userId,
      }}
      location={{ pathname: '/roles/' + userId }}
      {...rest}
    />
  )
}

export default EditPersonalDetail

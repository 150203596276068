import { Dialog, DialogActions, DialogContent, FormControlLabel } from '@material-ui/core'
import ContentCreate from '@material-ui/icons/CreateOutlined'
import Alert from 'elements/Alert'
import GenericButton from 'elements/button/GenericButton'
import CustomField from 'elements/field/CustomField'
import _ from 'lodash'
import { Button, Switch } from "opensolar-ui"
import { Component } from 'react'
import CheckboxInput from '../../elements/input/CheckboxInput'
import CustomCheckboxGroupInput from '../../elements/input/CustomCheckboxGroupInput'
import EditorComponent from '../../elements/wysiwyg/EditorComponentWrapper'
import { getPmtOptionHasEditableQuoteTable } from '../../util/misc'
import { styles } from './styles.js'
const getEditorButtons = (fieldName) => {
  const editorButtonConfig = {
    moreText: {
      // List of buttons used in the  group.
      buttons: ['bold', 'italic', 'underline', `placeholder_${fieldName}`],
      // Alignment of the group in the toolbar.
      align: 'left',
      buttonsVisible: 4,
    },
    moreMisc: {
      buttons: ['html'],
      align: 'right',
      buttonsVisible: 1,
    },
  }
  return {
    placeholderText: 'Type something to override default content',
    toolbarButtons: editorButtonConfig,
    toolbarButtonsSM: editorButtonConfig,
    toolbarButtonsXS: editorButtonConfig,
  }
}

const DraftEditorWithContent = ({ label, source }) => (
  <EditorComponent
    fieldName={source}
    options={getEditorButtons(source)}
    label={label}
    mentionContext="quotation_configuration"
    allowUploadImage={false}
  />
)

export const LineItemChoices = {
  system_details: {
    id: 'system_details',
    label: 'System Details',
    explainer: '"System Details" lists the make, model and quantity for modules and inverters.',
  },
  top_line_system_price: {
    id: 'top_line_system_price',
    label: 'Standard System Price',
    explainer: 'This field will show the top-line system price before any discounts.',
  },
  discount: {
    id: 'discount',
    label: 'Discounts',
    explainer:
      '"Discounts" captures any quick discounts which are not entered as itemized line items and any payment-specific discounts as a result of applying a price override.',
  },
  system_price: {
    id: 'system_price',
    label: 'Total System Price',
    explainer:
      '"Total System Price" is the price of the system after any relevant discounts have been taken into account. Discounts and adders which are visible to the customer will automatically be shown as separate line items.',
  },
  system_price_per_watt: {
    id: 'system_price_per_watt',
    label: 'System Price / Watt',
    explainer: '"System Price / Watt" is the System Price in dollars divided by the system size in watts.',
  },
  // battery_price: {
  //   id: 'battery_price',
  //   label: 'Battery Price',
  //    left: '',
  // left_extra: '',
  // right: '',
  // right_extra: ''
  // },
  incentives_to_customer: {
    id: 'incentives_to_customer',
    label: 'Estimated Other Incentives',
    explainer:
      '"Estimated Other Incentives" captures the estimated value of incentives that are paid out over time (e.g. tax credits, and Performance Based Incentives).',
  },
  total_incentives_to_customer: {
    id: 'total_incentives_to_customer',
    label: 'Total Additional Incentives',
    explainer: '“Total Additional Incentive” is the sum of all the incentives to the customer.',
  },
  net_system_cost: {
    id: 'net_system_cost',
    label: 'Estimated Net System Cost',
    explainer:
      '"Estimated Net System Cost" is the cost to the customer after all discounts and all incentives (estimated or otherwise) have been accounted for.',
  },
  down_payment: {
    id: 'down_payment',
    label: 'Down Payment',
    explainer:
      '"Down Payment" is the amount that the customer will pay the contractor prior to installation - typically at time of contract signing.',
  },
  loan_amount: {
    id: 'loan_amount',
    label: 'Loan Amount',
    explainer:
      '"Solar Loan Amount" is the portion of the System Price that will be financed.  Typically, this is the System Price minus Upfront Incentives and Down Payment.',
  },
  loan_interest_rate: {
    id: 'loan_interest_rate',
    label: 'Loan Interest Rate',
    explainer: '"Loan Interest Rate" is the default interest rate on the loan.',
  },
  loan_term: {
    id: 'loan_term',
    label: 'Loan Term',
    explainer: '"Loan Term" is the number of periods in the loan.',
  },
  loan_prepayment: {
    id: 'loan_prepayment',
    label: 'Loan Prepayment',
    explainer:
      '"Prepayment in month [X]" is the amount that must be prepaid by the end of month X to secure a low monthly payment for the remainder of the term of the loan.',
  },
  no_interest_period: {
    id: 'no_interest_period',
    label: 'No Interest Period',
    explainer: '"Interest-Free Period" is the promotional period during which no interest accrues on the loan.',
  },
  no_payment_period: {
    id: 'no_payment_period',
    label: 'No Payment Period',
    explainer: '"No Payments (Months X-Y)" shows the promotional period during which no payments will be due.',
  },
  interest_only_period: {
    id: 'interest_only_period',
    label: 'Interest Only',
    explainer:
      '"Interest Only Payment Amount (Months X-Y)" is the amount that must be paid each period during an "Interest Only" promotional period.',
  },
  loan_repayments_initial: {
    id: 'loan_repayments_initial',
    label: 'Initial Loan Payment',
    explainer: (
      <i>
        For "Solar-Style" loans, this is the monthly payment for the life of the loan assuming the required prepayment
        IS made. If the required prepayment is NOT made, this monthly payment only applies to the portion of the loan
        before the required prepayment.<br></br>
        <br></br>For "Mortgage-Style" loans, this is the monthly payment for the life of the loan assuming the required
        prepayment is NOT made. If the required prepayment IS made, this monthly payment only applies to the portion of
        the loan before the required prepayment.
      </i>
    ),
  },
  loan_repayments_final: {
    id: 'loan_repayments_final',
    label: 'Final Loan Payment',
    explainer: (
      <i>
        For "Solar-Style" loans, this is the monthly payment after the required prepayment is due if the required
        prepayment is NOT made.<br></br>
        <br></br>
        For "Mortgage-Style" loans, this is the monthly payment for the portion of the loan after the required
        prepayment, assuming the required prepayment IS made.
      </i>
    ),
  },
  total_payments: {
    id: 'total_payments',
    label: 'Total Payments',
    explainer: '"Total Payments" is the sum of all the payments that will be made for the solar energy system.',
  },
  regular_payment: {
    id: 'regular_payment',
    label: 'Regular Payments',
  },
  financed_amount: {
    id: 'financed_amount',
    label: 'Financed Amount',
  },
  additional_details: {
    id: 'additional_details',
    label: 'Additional Details',
  },
  ppa_upfront_payment: {
    id: 'ppa_upfront_payment',
    label: '',
  },
  ppa_dollars_per_kwh: {
    id: 'ppa_dollars_per_kwh',
    label: 'PPA $/kWh',
    explainer:
      '"PPA Cap kWh” is the maximum kWh usage that is supplied through the Power purchase agreement. The extra energy usage will be provided through the grid based on the selected electricity tariff. ',
  },
  cap_total_kwh: {
    id: 'cap_total_kwh',
    label: 'PPA Cap kWh',
  },
  incentives_to_installer: {
    id: 'incentives_to_installer',
    label: 'Upfront Incentives',
    explainer:
      '"Upfront Incentives" captures any incentives that are paid out upfront, at or around the time of installation.',
  },
  purchase_price: {
    id: 'purchase_price',
    label: 'Purchase Price',
    explainer:
      '"Purchase Price" is the cost to the customer after all discounts and upfront incentives have been subtracted from the Standard System Price.',
  },
  loan_advanced: {
    id: 'loan_advanced',
    label: 'Loan Details',
  },
}

// Copied directly from payment_options.py
export const LineItemChoicesDefaults = {
  cash: {
    variables: ['deposit_percentage', 'deposit_min', 'deposit_max'],
    quotation_items: [
      'system_details',
      'top_line_system_price',
      'discount',
      'system_price',
      'incentives_to_installer',
      'purchase_price',

      'incentives_to_customer',
      'total_incentives_to_customer',
      'net_system_cost',
    ],
    quotation_items_extras: ['system_price_per_watt'],
  },
  loan: {
    variables: [
      'payment_frequency',
      'interest_rate',
      'term',
      'down_payment_percentage',
      'down_payment_min',
      'down_payment_max',
      'dealer_fee_percentage',
      'dealer_fee_fixed',
      'no_interest_period',
      'no_payment_period',
      'interest_only_period',
      'prepayment_formula',
      'loan_type',
      'prepayment_period',
      'prepayment_percentage',
    ],
    quotation_items: [
      'system_details',
      'top_line_system_price',
      'discount',
      'system_price',
      'incentives_to_installer',
      'purchase_price',
      'down_payment',
      'loan_amount',
      'loan_interest_rate',
      'loan_term',
      'loan_prepayment',
      'no_interest_period',
      'no_payment_period',
      'interest_only_period',
      'loan_repayments_initial',
      'loan_repayments_final',
      'loan_repayments',
      'total_payments',

      'incentives_to_customer',
      'total_incentives_to_customer',
      'net_system_cost',
    ],
    quotation_items_extras: ['system_price_per_watt'],
  },
  loan_advanced: {
    variables: [
      'down_payment_enabled',
      'initial_loan_enabled',
      'down_payment_percentage',
      'down_payment_min',
      'down_payment_max',
      'delayed_down_payment_duration',
      'down_payment_interest_rate',
      'down_payment_dealer_fee_percentage',
      'down_payment_dealer_fee',
      'initial_payment_frequency',
      'initial_term',
      'initial_interest_rate',
      'initial_dealer_fee_percentage',
      'initial_dealer_fee',
      'initial_principal_reduction',
      'initial_principal_reduction_min',
      'initial_principal_reduction_max',
      'initial_bullet_enabled',
      'initial_bullet_payment_percentage',
      'initial_bullet_payment_min',
      'initial_bullet_payment_max',
      'final_payment_frequency',
      'final_term',
      'final_interest_rate',
      'final_dealer_fee_percentage',
      'final_dealer_fee',
      'final_bullet_enabled',
      'final_bullet_payment_percentage',
      'final_bullet_payment_min',
      'final_bullet_payment_max',
    ],
    quotation_items: ['loan_advanced', 'incentives_to_customer', 'total_incentives_to_customer', 'net_system_cost'],
    quotation_items_extras: ['system_price_per_watt'],
  },
  ppa: {
    variables: [
      'ppa_dollars_per_kwh',
      'ppa_escalator_annual',
      'ppa_upfront_payment',
      'term_years',
      'ppa_dollars_per_kwh_exports',
      'cap_total_kwh',
    ],
    quotation_items: ['system_details', 'ppa_upfront_payment', 'ppa_dollars_per_kwh', 'cap_total_kwh'],
    quotation_items_extras: [
      'system_price_per_watt',
      'top_line_system_price',

      'incentives_to_customer',
      'total_incentives_to_customer',
      'net_system_cost',
    ],
  },
  regular_payment: {
    variables: ['payment_amount', 'payment_frequency', 'term_years'],
    quotation_items: ['system_details', 'regular_payment', 'total_payments', 'additional_details'],
    quotation_items_extras: [
      'system_price_per_watt',
      'top_line_system_price',

      'incentives_to_customer',
      'total_incentives_to_customer',
      'net_system_cost',
    ],
  },
  lease: {
    variables: [
      'payment_frequency',
      'term',
      'interest_rate',
      'dealer_fee',
      'down_payment_percentage',
      'down_payment_min',
      'down_payment_max',
      'brokerage_rate',
      'escalator_annual',
      'payment_timing',
      'additional_details',
    ],
    quotation_items: [
      'system_details',
      'discount',
      'system_price',
      'incentives_to_installer',
      'financed_amount',
      'regular_payment',
      'total_payments',
      'additional_details',
    ],
    quotation_items_extras: [
      'system_price_per_watt',
      'top_line_system_price',

      'incentives_to_customer',
      'total_incentives_to_customer',
      'net_system_cost',
    ],
  },
}

// This creates a dict for each paymentOption like {cash: []}
// Then merges them into one dict with all payment option types as keys
// I did this so we could copy-paste LineItemChoicesDefaults directly from payment_options.py
// instead of needing to update them manually/individually
const LineItemChoicesByPaymentOptionType = Object.assign(
  {},
  ...Object.keys(LineItemChoicesDefaults).map((paymentOptionType) => {
    return {
      [paymentOptionType]: LineItemChoicesDefaults[paymentOptionType].quotation_items
        .map((key) => LineItemChoices[key])
        .concat(LineItemChoicesDefaults[paymentOptionType].quotation_items_extras.map((key) => LineItemChoices[key])),
    }
  })
)
const getNewIndex = function (arr, new_index) {
  return ((new_index % arr.length) + arr.length) % arr.length
}

const reorderArray = function (arr, old_index, new_index) {
  new_index = getNewIndex(arr, new_index)
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
  return arr // for testing
}

const reorderSection = (choices, selectedField, direction) => {
  return choices.every((choice, index) => {
    if (choice.id === selectedField) {
      const currentIndex = index
      const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1
      reorderArray(choices, currentIndex, newIndex)
      return false
    } else if (choice.children) {
      return reorderSection(choice.children, selectedField, direction)
    }
    return true
  })
}

export default class QuotationConfiguration extends Component {
  constructor(props) {
    super(props)

    this.state = {
      is_default: true,
      choices: [],
      dialogOpen: false,
    }
  }

  componentDidMount() {
    this.setChoices()
  }

  setChoices = () => {
    // Filter any invalid values, from old data
    var choices = _.cloneDeep(LineItemChoicesByPaymentOptionType[this.props.paymentType])

    if (choices) {
      choices = choices.filter(Boolean)
    } else {
      choices = []
    }

    const {
      input: { value = [] },
    } = this.props

    choices.sort((a, b) => {
      if (!value.includes(a.id)) {
        return 1
      } else if (!value.includes(b.id)) {
        return -1
      } else {
        return value.indexOf(a.id) - value.indexOf(b.id)
      }
    })

    const is_default = !Boolean(value.length)
    this.setState({ choices, is_default })
  }

  componentDidUpdate(prevProps, prevState) {
    // Refresh if
    // a) record is loaded and has a paymentType
    // a) either previous props did not have record loaded or payment_type has changed
    //
    // Beware infinite loops!
    if (this.props.paymentType && this.props.paymentType !== prevProps.paymentType) {
      this.setChoices()
    }
  }

  handleOpen = (id) => {
    this.setState({ dialogOpen: true, editingLineItemId: id })
  }

  handleClear = () => {
    const { editingLineItemId } = this.state
    const { updateField } = this.props
    const fields = ['highlight', 'left', 'left_extra', 'right', 'right_extra']
    fields.forEach((field) => {
      updateField(`quotation_labels.${editingLineItemId}.${field}`, null)
    })
    this.setState({ dialogOpen: false, editingLineItemId: undefined })
  }

  handleConfirm = () => {
    this.setState({ dialogOpen: false, editingLineItemId: undefined })
  }

  handleReorder = (selectedField, direction) => {
    const { choices } = this.state
    const { input: { onChange, value = [] } = {} } = this.props
    reorderSection(choices, selectedField, direction)
    this.setState({
      choices,
    })
    const newValue = choices.map((choice) => choice.id).filter((choice) => value.includes(choice))
    onChange(newValue)
  }

  handleOnclick = (currentValue) => {
    const { updateField, paymentType, input: { onChange } = {} } = this.props
    if (currentValue === true) {
      onChange('')
      updateField('quotation_labels', '')
    } else {
      const newInputValue = paymentType && LineItemChoicesDefaults[paymentType].quotation_items
      onChange(newInputValue)
    }
    this.setState({
      is_default: currentValue,
    })
  }

  customiseButton = (id) => {
    const { translate, layout } = this.props
    return (
      <Button
        style={
          layout === 1
            ? { marginRight: 5, marginLeft: 'auto', minWidth: 30, boxShadow: 'unset' }
            : {
                // padding: '3px 10px',
                marginRight: '10%',
                marginLeft: 'auto',
                // border: '1px solid #cdcdcd',
                // borderRadius: 4,
              }
        }
        variant={layout === 1 ? 'default' : 'contained'}
        color="default"
        size={'small'}
        // buttonStyle={{ height: 30, lineHeight: '30px' }}
        // labelStyle={{ padding: '0px 10px' }}
        onClick={(e) => {
          e.preventDefault()
          this.handleOpen(id)
        }}
      >
        <span>{layout > 1 ? translate('CUSTOMISE') : <ContentCreate />}</span>
      </Button>
    )
  }

  renderDialog() {
    const { resource, translate, dialogClasses, theme } = this.props
    const { editingLineItemId } = this.state
    const { themeColor, themeColorDark } = theme
    const editingLineItem = (LineItemChoices[editingLineItemId] && LineItemChoices[editingLineItemId].label) || ''
    return (
      <Dialog
        autoDetectWindowHeight={false}
        autoScrollBodyContent={true}
        classes={dialogClasses}
        open={this.state.dialogOpen}
        key={editingLineItemId}
      >
        <DialogContent dividers>
          <h1 style={{ color: 'rgb(77, 77, 77)' }}>{`Customise Quotation Line Item "${editingLineItem}"`}</h1>
          {editingLineItemId && LineItemChoices[editingLineItemId].explainer && (
            <p>
              <i>{LineItemChoices[editingLineItemId].explainer}</i>
            </p>
          )}
          <CustomField
            component={CheckboxInput}
            name={`quotation_labels.${editingLineItemId}.highlight`}
            source={`quotation_labels.${editingLineItemId}.highlight`}
            resource={resource}
            label={'Highlight this line item'}
          />
          <CustomField
            component={DraftEditorWithContent}
            name={`quotation_labels.${editingLineItemId}.left`}
            source={`quotation_labels.${editingLineItemId}.left`}
            resource={resource}
            label="Label"
          />
          <CustomField
            component={DraftEditorWithContent}
            label="Label Explainer Text"
            name={`quotation_labels.${editingLineItemId}.left_extra`}
            source={`quotation_labels.${editingLineItemId}.left_extra`}
            resource={resource}
          />
          <CustomField
            component={DraftEditorWithContent}
            name={`quotation_labels.${editingLineItemId}.right`}
            source={`quotation_labels.${editingLineItemId}.right`}
            resource={resource}
            label="value"
          />
          <CustomField
            component={DraftEditorWithContent}
            name={`quotation_labels.${editingLineItemId}.right_extra`}
            source={`quotation_labels.${editingLineItemId}.right_extra`}
            resource={resource}
            label="Value Explainer Text"
          />
        </DialogContent>
        <DialogActions>
          <Button style={{ marginRight: 10 }} onClick={this.handleClear}>
            <span>{translate('Clear')}</span>
          </Button>
          <GenericButton
            backgroundColor={themeColor || '#ffda00'}
            backgroundColorOnHover={themeColorDark || '#F3B800'}
            keyboardFocused={true}
            onClick={this.handleConfirm}
            label={translate('Confirm')}
          />
        </DialogActions>
      </Dialog>
    )
  }

  render() {
    const { paymentType, formControlLabelStyle } = this.props
    const customizationEnabled =
      getPmtOptionHasEditableQuoteTable(this.props.record) &&
      (!this.props.record.finance_product_category ||
        Object.keys(this.props.record.finance_product_category)?.length === 0 ||
        this.props.record.finance_product_category.quotation_table_is_editable)
    const isCustom = !this.state.is_default && customizationEnabled
    return (
      <div style={this.state.is_default ? styles.loanWrapper : styles.loanWrapperWithBoxShadow}>
        {this.renderDialog()}

        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', height: 50 }}>
          <FormControlLabel
            style={formControlLabelStyle}
            control={
              <Switch
                checked={isCustom}
                onChange={(event, isInputChecked) => {
                  this.handleOnclick(!isInputChecked)
                }}
                // labelStyle={elStyles.loan.ToggleInputLabel}
                disabled={!customizationEnabled}
              />
            }
            labelPlacement="start"
            label={this.props.translate('Custom Quotation Table')}
          />
        </div>
        {!customizationEnabled && (
          <Alert severity="info">
            {this.props.translate('Quotation Tables are not customizable for integrated payment options.')}
          </Alert>
        )}
        {isCustom && (
          <div>
            {paymentType === 'loan' ? (
              <p>
                Fields that are not relevant to this loan will automatically be omitted from the quotation table. (e.g.
                if this loan does not have a prepayment requirement, the "Prepayment" line will be omitted.)
              </p>
            ) : (
              <p>
                Fields that are not relevant to this payment type will automatically be omitted from the quotation
                table.{' '}
              </p>
            )}
            <CustomCheckboxGroupInput
              {...this.props}
              disabledChoices={[]}
              choices={this.state.choices}
              handleReorder={this.handleReorder}
              renderExtraComponent={this.customiseButton}
            />
          </div>
        )}
      </div>
    )
  }
}

import React from 'react'
import LogoOrTitle from '../mainContent/components/LogoOrTitle'

const addHttpIfMissing = (url) => (url.indexOf('http') === 0 ? url : 'http://' + url)

const Header = ({ org, title }) => (
  <div style={{ textAlign: 'center', marginBottom: 30 }}>
    <LogoOrTitle org={org} skipTitleStyle />
    {org.logo_public_url && org.logo_public_url.length && <h2>{org.name}</h2>}
    {org.company_website && org.company_website.length && (
      <p>
        <a href={addHttpIfMissing(org.company_website)} target="_blank" rel="noreferrer">
          {org.company_website}
        </a>
      </p>
    )}
    <h1>{title}</h1>
  </div>
)

const Letter = ({ org, title, content, isAboutUs, overrideLogo }) => (
  <div>
    <div style={{ textAlign: 'center', marginBottom: 30 }}>
      {isAboutUs ? (
        <Header org={org} title={title} />
      ) : (
        <div>
          <LogoOrTitle org={org} overrideLogo={overrideLogo} skipTitleStyle />
          <h1>{title}</h1>
        </div>
      )}
    </div>

    <div
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  </div>
)

export default Letter

import { BATTERY_COMPATIBILITY_FILTER_KEY } from 'elements/hardwareFilter/batteryCompatibility/BatteryCompatibilityFilterNodeFactory'
import { getCompatibleCodesForSystem } from 'elements/hardwareSelector/SearchToolbar'
import HardwareSelectorCore from 'elements/hardwareSelectorV2/HardwareSelectorCore'
import { Box, Button } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { BSA_MODE_NODE_KEY } from '../utility/constants'
import { BatterySalesAssistantFlowNodeComponentStandardPropsType } from '../utility/types'

const useStyles = makeOpenSolarStyles(() => ({
  manualSelectorWrapper: {
    minHeight: '0px',
    display: 'flex',
  },
}))

const BSAManualSelectorNode: React.FC<BatterySalesAssistantFlowNodeComponentStandardPropsType> = ({
  flowController,
  flowStore,
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const compatibleBatteryCodes = getCompatibleCodesForSystem('battery')
  const defaultFilterValues = {
    ...(compatibleBatteryCodes.length ? { [BATTERY_COMPATIBILITY_FILTER_KEY]: compatibleBatteryCodes.join(',') } : {}),
  }

  return (
    <div className={classes.manualSelectorWrapper}>
      <HardwareSelectorCore
        config={{
          componentTypes: ['battery'],
          defaultFilterValues,
        }}
        layoutOptions={{
          footer: (
            <Box display="flex" justifyContent="flex-end">
              <Button
                color="default"
                onClick={() =>
                  flowController.goTo({
                    currentNodeKey: BSA_MODE_NODE_KEY,
                    options: {},
                  })
                }
                variant="contained"
              >
                {translate('Back')}
              </Button>
            </Box>
          ),
          isHeaderHidden: true,
        }}
        onClose={flowStore.onFinish}
      />
    </div>
  )
}

export default BSAManualSelectorNode

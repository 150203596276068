import { omit } from 'lodash'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import { useCallback, useMemo } from 'react'
import { DISTRIBUTOR_BRAND_FILTER_KEY } from '../distributorBrand/DistributorBrandFilterNodeFactory'
import useDistributorAvailableBrands from '../distributorBrand/useDistributorAvailableBrands'
import type { ManufacturerFilterProps } from './ManufacturerFilter'
import { EXHIBITOR_FILTER_KEY, MANUFACTURER_FILTER_KEY, defaultManufacturerOption } from './ManufacturerFilter'

const DistributorBrandManufacturerFilter = ({
  disabled,
  modifiableFilterValues,
  persistentFilterValues,
  allFilterValues,
  setModifiableFilters,
  RendererComponent,
  componentType,
}: ManufacturerFilterProps) => {
  const onSelect = useCallback(
    (value) => {
      // Always clear first
      const newFilterValues = omit({ ...modifiableFilterValues }, [
        EXHIBITOR_FILTER_KEY,
        MANUFACTURER_FILTER_KEY,
        DISTRIBUTOR_BRAND_FILTER_KEY,
      ])

      if (value === '') {
      } else if (value !== '') {
        newFilterValues[DISTRIBUTOR_BRAND_FILTER_KEY] = value
      }
      setModifiableFilters(newFilterValues)
    },
    [modifiableFilterValues]
  )

  const selectedDistributor = useMemo(() => {
    switch (allFilterValues['require_distributor']) {
      case 'segen':
        return HardwareSupplierEnum.Segen
      case 'outlet':
        return HardwareSupplierEnum.SolarOutlet
      case 'city_plumbing':
        return HardwareSupplierEnum.CityPlumbing
      default:
        return undefined
    }
  }, [allFilterValues['require_distributor']])

  const availableBrands = useDistributorAvailableBrands({
    distributor: selectedDistributor,
    componentType: componentType,
  })

  const options = useMemo(() => {
    return availableBrands.sort().reduce(
      (options, brand) => {
        return options.concat({ id: brand, value: encodeURIComponent(brand), title: brand })
      },
      [defaultManufacturerOption]
    )
  }, [availableBrands])

  const value =
    allFilterValues[DISTRIBUTOR_BRAND_FILTER_KEY] !== undefined ? allFilterValues[DISTRIBUTOR_BRAND_FILTER_KEY] : ''

  return (
    <RendererComponent
      id={'manufacturer_filter'}
      defaultValue={''}
      label={'Manufacturer'}
      options={options}
      disabled={!!disabled}
      onSelect={onSelect}
      value={value}
    />
  )
}

export default DistributorBrandManufacturerFilter

// @ts-nocheck
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { hideFullScreenLoader, markCreditAppAsOpened, myEnergySelectors } from 'ducks/myEnergy'
import LoadingDots from 'layout/widgets/LoadingDots'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'
import { PaymentOptionType } from 'types/paymentOptions'
import { ProjectType } from 'types/projects'
import { TransactionActionButton } from '../../../elements/TransactionStartButton'
import ReapplicationConfirm from '../ReapplicationConfirm'
import { removeCreditAppParams } from '../util'
import { LOANPAL_OR_GOODLEAP } from './constants'
import LoanpalApplicationDialog from './LoanpalApplicationDialog'

var restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  actionData: any
  translate: (raw: string) => string
  showDialog: boolean
  setWaitingForAction: Function
  myeStyles: CSSProperties
  acceptButtonStyle: CSSProperties
  acceptButtonStyleDisabled: CSSProperties
  isPro: boolean
  selectedPaymentOptionName: string
  paymentOptionData: PaymentOptionType
  selectedProject: ProjectType
  onDecisionRendered: () => void
  loanpalDecisionRendered: boolean
  selectedPaymentOptionId: number
}
const LoanpalApplicationButton: React.FunctionComponent<PropTypes> = (props) => {
  const {
    actionData,
    translate,
    setWaitingForAction,
    myeStyles,
    acceptButtonStyle,
    acceptButtonStyleDisabled,
    selectedProject,
    paymentOptionData,
    selectedPaymentOptionId,
  } = props
  const [showApplicationDialog, setShowApplicationDialog] = useState<boolean>(false)
  const [disclosuresLoading, setDisclosuresLoading] = useState<boolean>(false)
  const [disclosures, setDisclosures] = useState<object[] | undefined>(undefined)
  const [showReapplicationConfirm, setShowReapplicationConfirm] = useState<boolean>(false)
  const [isFullWidth, setIsFullWidth] = useState<boolean>(false)

  const forceOpenCreditApp = useSelector(myEnergySelectors.getShouldForceOpenCreditApp)
  const dispatch = useDispatch()

  useEffect(() => {
    if (forceOpenCreditApp && selectedPaymentOptionId === paymentOptionData?.id) {
      openApplicationDialog()
      removeCreditAppParams()
      setIsFullWidth(true)
    }
  }, [forceOpenCreditApp, selectedPaymentOptionId])

  let buttonLabelText =
    actionData.status_title === 'InitiatedButIncomplete'
      ? translate('Finish Loan Application')
      : translate('Get Pre-Qualified!')

  let extra_actions = []
  const orgId = props.paymentOptionData?.org_id ? props.paymentOptionData?.org_id : actionData.org_id

  if (actionData.status_code === 'available') {
    extra_actions.push(
      <p key={extra_actions.length} className="small">
        Clicking "Get Pre-Qualified!" will take you to {LOANPAL_OR_GOODLEAP}'s credit application. OpenSolar does not
        view or store any of the data you enter in this application except name and email address.
      </p>
    )
  }

  if (actionData.status_title === 'ApprovedPendingDocs') {
    extra_actions.push(
      <p key={extra_actions.length}>
        Loan Approved{' '}
        <a
          onClick={(event) => {
            event.preventDefault()

            // Request the email send (which will automatically record an incomplete transaction)
            setWaitingForAction(true)
            restClientInstance('CUSTOM_GET', 'custom', {
              url: 'orgs/' + orgId + '/projects/' + actionData.project_id + '/send_loan_docs/',
            }).then(
              (response) => {
                console.log(response.data)
                window.Designer.showNotification(window.translate('Loan Docs Requested'))
                setWaitingForAction(false)
              },
              (reject) => {
                console.log(reject)
                window.Designer.showNotification(window.translate('Error Sending Loan Docs'), 'danger')
                setWaitingForAction(false)
              }
            )
          }}
          // eslint-disable-line
          href="#"
        >
          Send Loan Docs
        </a>
      </p>
    )
  } else if (actionData.status_title === 'Declined') {
    extra_actions.push(
      <div
        key={extra_actions.length}
        style={{ textAlign: 'center', color: myeStyles.textColor, margin: '15px 0px 15px 0px' }}
      >
        ✖ Not Approved for Financing
      </div>
    )
  } else if (actionData.status_description) {
    // Other statuses where we simply print the status_description
    // Including: OtherOrUnknown
    extra_actions.push(<p key={extra_actions.length}>{actionData.status_description}</p>)
  }

  const openApplicationDialog = () => {
    //logAppOpenedEvent(actionData, selectedPaymentOptionName)
    setDisclosuresLoading(true)
    restClientInstance('CUSTOM_GET', 'custom', {
      url: 'orgs/' + orgId + '/projects/' + actionData.project_id + '/loanpal_disclosures/',
    })
      .then(
        (response: any) => {
          if (response?.data?.disclosures?.data) {
            setShowApplicationDialog(true)
            setDisclosures(response.data.disclosures.data)
            setShowReapplicationConfirm(false)
          }
        },
        (reject: any) => {
          console.log(reject)
          window.Designer.showNotification(window.translate(reject.message), 'danger')
          logAmplitudeEvent('integrated_finance_application_open_error', { integration: 'loanpal' })
        }
      )
      .catch((err: any) => {
        if (err?.body?.message) {
          window.Designer.showNotification(window.translate(err.body.message), 'danger')
          logAmplitudeEvent('integrated_finance_application_open_error', { integration: 'loanpal' })
        }
      })
      .finally(() => {
        setDisclosuresLoading(false)
        dispatch(hideFullScreenLoader())
        dispatch(markCreditAppAsOpened())
      })
  }
  return (
    <>
      {actionData.status_code !== 'complete' && (
        <TransactionActionButton
          label={
            <div>
              {disclosuresLoading ? (
                <LoadingDots color="#fff" />
              ) : (
                <>
                  {buttonLabelText}
                  <div style={{ fontSize: 11 }}>(Takes less than 5 minutes)</div>
                </>
              )}
            </div>
          }
          disabled={actionData.status_code !== 'available' || props.loanpalDecisionRendered}
          myeStyles={myeStyles}
          labelStyle={{ padding: 0 }}
          buttonStyle={{
            height: 39,
            ...(actionData.status_code === 'available' && !props.loanpalDecisionRendered
              ? acceptButtonStyle
              : acceptButtonStyleDisabled),
          }}
          action={openApplicationDialog}
          extra_actions={extra_actions}
        />
      )}
      {actionData.status_code === 'complete' && (
        <>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h2>Your Financing Decision</h2>
            <div>{actionData.status_description}</div>
            {actionData.status_title?.includes('Declined') && (
              <div style={{ margin: '10px 0px', width: '100%', display: 'flex', justifyContent: 'center' }}>
                <div
                  style={{
                    color: myeStyles?.highlightColor,
                    textAlign: 'center',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => setShowReapplicationConfirm(true)}
                >
                  Want to Re-Apply?
                </div>
                <ReapplicationConfirm
                  close={() => setShowReapplicationConfirm(false)}
                  onConfirm={openApplicationDialog}
                  isOpen={showReapplicationConfirm}
                  loading={disclosuresLoading}
                />
              </div>
            )}
          </div>
        </>
      )}
      {disclosures && (
        <LoanpalApplicationDialog
          open={showApplicationDialog}
          onClose={() => setShowApplicationDialog(false)}
          disclosures={disclosures}
          project={selectedProject}
          paymentOptionId={paymentOptionData?.id}
          systemUuid={actionData?.system_uuid}
          orgId={orgId}
          onDecisionRendered={props.onDecisionRendered}
          isFullWidth={isFullWidth}
        />
      )}
    </>
  )
}
export default LoanpalApplicationButton

import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import _ from 'lodash'
import get from 'lodash/get'
import { Chip } from 'opensolar-ui'
import React, { useCallback } from 'react'
import { useInput } from 'react-admin'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: -theme.spacing(1),
      marginBottom: -theme.spacing(1),
    },
    chip: { margin: 4 },
  }),
  { name: 'ClearableChipField' }
)

const ClearableChipField = (props) => {
  const {
    loaded,
    choices = [],
    className,
    filterFunction,
    resource,
    onDelete: onDeleteOverride,
    id: idOverride,
    input: inputOverride,
    classes: classesOverride,
    optionText = 'name',
    optionValue = 'id',
    source,
    emptyText,
    allowDelete,
    ...rest
  } = props
  const { input } = useInput({
    resource,
    source,
    id: idOverride,
    input: inputOverride,
    ...rest,
  })

  const handleDelete = useCallback(
    (deleteItem) => {
      if (typeof onDeleteOverride === 'function') {
        onDeleteOverride(deleteItem, input)
      } else {
        const newValue = _.clone(input.value)
        _.remove(newValue, (item) => item === deleteItem)
        input.onChange(newValue)
      }
    },
    [input.onChange, onDeleteOverride]
  )

  const classes = useStyles(props)
  if (loaded === false) {
    return <LinearProgress />
  }

  return (
    <div className={classnames(classes.root, className)} {...sanitizeRestProps(rest)}>
      {input.value &&
        input.value.map((id) => {
          const item = choices.find((choice) => get(choice, optionValue) === id)
          if (!item) {
            return null
          }
          if (filterFunction && filterFunction(item) === false) {
            return null
          }
          const label = get(item, optionText)
          const value = get(item, optionValue)
          const deleteAction = allowDelete ? () => handleDelete(value) : undefined
          return (
            <Chip className={classnames(classes.chip, className)} onDelete={deleteAction} {...rest} label={label} />
          )
        })}
    </div>
  )
}

const sanitizeRestProps = ({ currentSort, setSort, loading, loaded, label, ...props }) => props
export default ClearableChipField

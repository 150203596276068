import { useSelectedSystem } from 'Designer/hooks/useSelectedSystem'
import { useSelector } from 'react-redux'
import { ComponentDependencies } from 'types/associatedComponents'
import { RootState } from 'types/state'

const NonModuleComponentTypesLookup = {
  others: 'other',
  batteries: 'battery',
  inverters: 'inverter',
}

const useRecommendedAndRequiredComponentOrdering = () => {
  const system = useSelectedSystem()
  const selectedTab = useSelector((state: RootState) => state.designer.view.selectedTab)
  const nonModuleComponentType = NonModuleComponentTypesLookup[selectedTab as string]
  const recommendedAndRequiredComponents: Record<string, string> = {}
  if (nonModuleComponentType) {
    //using object {[code]: code} to have O(1) checking for existence
    const dependencies: ComponentDependencies = system?.custom_data['component_dependencies'] || []

    dependencies.forEach((dependency) => {
      const requiredComponents = dependency?.require || []
      const recommendedComponents = dependency?.recommend || []
      const components = [...requiredComponents, ...recommendedComponents]
      components?.forEach((component) => {
        const componentCode = component.code
        if (nonModuleComponentType && [nonModuleComponentType].includes(component.componentType)) {
          if (!component.totalCalculatedQty || recommendedAndRequiredComponents[componentCode]) return
          recommendedAndRequiredComponents[componentCode] = componentCode
        }
      })
    })
  }

  return Object.keys(recommendedAndRequiredComponents)
}

export default useRecommendedAndRequiredComponentOrdering

import { makeStyles } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'

export const usePlaceholderTagStyles = makeStyles<
  OpenSolarThemeType,
  {
    x: number
    y: number
    zoom: number
    declutter: boolean
    aCellIsBeingDragged: boolean
    isFocused: boolean
    showFormat: boolean
    width: number
    height: number
    fontSize: number
    isImage: boolean
  }
>((theme) => {
  return {
    formatWrapper: {
      padding: '5px 10px',
      backgroundColor: '#fff',
      width: '600px',
    },
    formatWrapperImage: {
      padding: '5px 10px',
      backgroundColor: '#fff',
      minWidth: '100px',
    },
    dimensionsInputWrapper: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '15px',
    },
    formatFieldWithMargin: {
      marginTop: '15px',
    },
    fontArrowWrapper: {
      margin: '5px 10px',
      backgroundColor: '#ececec',
      borderRadius: '20px',
      cursor: 'pointer',
      padding: '0x 3px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: theme.boxShadow.default,
    },
    inputWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    unitLabel: { margin: '5px 10px' },
    formatInputWrapper: {
      margin: '5px 10px',
      backgroundColor: '#ececec',
      borderRadius: '20px',
      cursor: 'pointer',
      padding: '0x 3px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: theme.boxShadow.default,
    },
    col: { display: 'flex', flexDirection: 'column' },
    rowFlexStart: { display: 'flex', justifyContent: 'flex-start' },
    mainWrapper: ({ x, y, zoom, aCellIsBeingDragged, isFocused, showFormat }) => ({
        zIndex: showFormat || isFocused ? 400 : 300,
        position: 'absolute',
        left: `${x * zoom}px`,
        top: `${y * zoom}px`,
        opacity: aCellIsBeingDragged && !isFocused ? 0.4 : undefined,
    }),
    title: ({ zoom, aCellIsBeingDragged, isFocused, declutter }) => ({
      padding: `${zoom * 2}px ${zoom * 4}px ${zoom * 2}px ${zoom * 2}px`,
      backgroundColor: '#fff',
      borderTopRightRadius: '5px',
      borderTopLeftRadius: '5px',
      border: `${zoom * 1}px solid #ececec`,
      borderBottom: 'none',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      opacity: aCellIsBeingDragged && !isFocused ? 0.4 : declutter ? 0 : undefined,
      fontSize: `${zoom * 12}px`,
      height: `${zoom * (12 + 4 + 1)}px`,
      color: '#a2a2a2',
    }),
    contentWrapper: ({ zoom, aCellIsBeingDragged, isFocused, declutter, showFormat }) => ({
      zIndex: showFormat ? 4 : 3,
      cursor: 'pointer',
      boxShadow: declutter ? undefined : theme.boxShadow.default,
      background: declutter ? 'transparent' : 'rgba(255,255,255,0.6)',
      border: isFocused ? `${zoom * 1}px solid black` : `${zoom * 1}px solid ${declutter ? 'transparent' : '#ececec'}`,
      borderRadius: '4px',
      opacity: aCellIsBeingDragged && !isFocused ? 0.4 : undefined,
    }),
    dragTarget: ({ zoom }) => ({
      padding: `${zoom * 2}px ${zoom * 6}px ${zoom * 2}px ${zoom * 6}px`,
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      touchAction: 'none',
      zIndex: 102,
    }),
    valueWrapper: ({ width, height, zoom, fontSize, declutter, showFormat, isImage }) => ({
      width: `${width * zoom}px`,
      height: `${zoom * height}px`,
      position: 'absolute',
      left: '0px',
      top: '0px',
      bottom: '0px',
      opacity: 1.0,
      backgroundColor: declutter ? 'transparent' : showFormat ? '#ffda00' : isImage ? '#ececec' : '#fff',
      fontSize: `${fontSize * zoom}px`,
      padding: `${zoom * 2}px`,
      borderBottom: `${zoom * 1}px solid ${declutter ? 'transparent' : 'black'}`,
      display: isImage ? 'flex' : undefined,
      justifyContent: isImage ? 'center' : undefined,
      alignItems: isImage ? 'center' : undefined,
      textAlign: isImage ? 'center' : undefined,
    }),
    dimensionsDiv: ({ zoom, width, height }) => ({
      width: `${zoom * width}px`,
      height: `${zoom * height}px`,
    }),
    dropdownWrapper: ({ zoom }) => ({
      padding: `0px ${zoom * 5}px 0px ${zoom * 20}px`,
    }),
  }
})

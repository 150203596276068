import React from 'react'
import { PublicFileList, PublicFileCreate, PublicFileEdit } from './PublicFiles'

const PublicFileName = ({ record, translate, style }) => {
  return (
    <span style={style}>
      {translate('File')} {record ? `"${record.title}"` : ''}
    </span>
  )
}

export default {
  list: PublicFileList,
  create: PublicFileCreate,
  edit: PublicFileEdit,
  options: {
    create: {
      title: 'Create Public File',
      subtitle: null,
      breadCrumb: 'Create Public File',
    },
    list: {
      title: 'Public Files',
      subtitle: null,
      breadCrumb: 'Public Files',
    },
    edit: {
      title: <PublicFileName />,
      subtitle: null,
      breadCrumb: 'Edit Public File',
    },
  },
}

import { Checkbox, FormControlLabel } from 'opensolar-ui'
import { FC } from 'react'

export const CheckboxSizes: FC = () => {
  return (
    <>
      {/* SNIPPET START 1 */}
      <Checkbox defaultChecked size="small" />
      <Checkbox defaultChecked />
      <Checkbox defaultChecked size="large" />
      {/* SNIPPET END 1 */}
    </>
  )
}

export const CheckboxColors: FC = () => {
  return (
    <>
      {/* SNIPPET START 2 */}
      <Checkbox defaultChecked color="default" />
      <Checkbox defaultChecked color="primary" />
      <Checkbox defaultChecked color="secondary" />
      <Checkbox defaultChecked color="info" />
      <Checkbox defaultChecked color="success" />
      <Checkbox defaultChecked color="warning" />
      <Checkbox defaultChecked color="error" />
      {/* SNIPPET END 2 */}
    </>
  )
}

export const CheckboxLabelled: FC = () => {
  return (
    <>
      {/* SNIPPET START 3 */}
      <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
      <FormControlLabel control={<Checkbox />} label="Start Label" labelPlacement="start" />
      <FormControlLabel required control={<Checkbox />} label="Required" />
      <FormControlLabel disabled control={<Checkbox />} label="Disabled" />
      {/* SNIPPET END 3 */}
    </>
  )
}

export const CheckboxStates: FC = () => {
  return (
    <>
      {/* SNIPPET START 4 */}
      <Checkbox checked={true} />
      <Checkbox indeterminate={true} />
      <Checkbox disabled={true} />
      {/* SNIPPET END 4 */}
    </>
  )
}

import ProUXButton from 'elements/proUXButtons/ProUXButton'
import moment from 'moment'
import React, { useState } from 'react'
import { WalletTransactionType } from 'types/wallet'
import { CreateTransactionDialogWithForm } from './createWalletTransactionDialog'

type PropTypes = {
  record: WalletTransactionType
}

const ReversalButton: React.FC<PropTypes> = (props) => {
  const [showDialog, setShowDialog] = useState<boolean>(false)

  const openReversalDialog = () => setShowDialog(true)
  //@ts-ignore
  const lineItem = props.record.transaction_line_items?.[0]

  return (
    <div>
      {props.record.transaction_type === 'purchase' && !props.record.is_reversed ? (
        <ProUXButton type="secondary" label="Reversal" onClick={openReversalDialog} />
      ) : null}
      {showDialog && (
        <CreateTransactionDialogWithForm
          initialValues={{
            transaction_type: 'reversal',
            invoice_id: props.record.invoice_id,
            amount_ex_tax: props.record.amount_ex_tax ? props.record.amount_ex_tax * -1 : 0,
            amount_inc_tax: props.record.amount_inc_tax ? props.record.amount_inc_tax * -1 : 0,
            tax_combined: props.record.tax_combined ? props.record.tax_combined * -1 : 0,
            created_date: moment(props.record.created_date).format('LL'),
            org_id: props.record.org_id,
            org_name: props.record.org_name,
            product_purchased_name: props.record.product_purchased_name,
            created_by_email: props.record.created_by_email,
            project_title: props.record.project_title,

            product_purchased: lineItem?.product_purchased,
            project: lineItem?.project,

            //@ts-ignore TODO: fix this
            transaction_to_reverse: props.record.url,
            units: -lineItem?.units,

            //@ts-ignore TODO: fix this - just used to display in the form, don't send to backend
            previous_transaction_type: props.record.transaction_type,

            currency: props.record.currency,
          }}
          invoiceToReverse={props.record}
          open={showDialog}
          setOpen={setShowDialog}
        />
      )}
    </div>
  )
}
export default ReversalButton

import { duplicate as duplicateAction } from 'actions/restActions'
import CreateEditToolbar from 'elements/CreateEditToolbar'
import PaginationWithActions from 'elements/PaginationWithActions'
import { List } from 'elements/react-admin/List'
import ListActions from 'elements/react-admin/ListActions'
import {
  AutocompleteInput,
  Create,
  Datagrid,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin'
import { connect } from 'react-redux'
import { currencySymbolForCountry } from 'util/misc'
import { getOrgCountryCodeFromState } from 'util/org'
import { inputStyle } from '../../activeComponents/common'
import GenericComponentField from '../GenericComponentField'
import { ActionsField, FieldFromComponentReferenceOrData, LYRA_SUPPORT_OPTIONS } from '../common'
import { moduleDataFields } from './constants'

const getDefaultValuesFromDataModules = (record) => {
  // isCustom triggers whether to use standard or custom data fieldsets
  var data = record.data ? JSON.parse(record.data) : null
  var values
  const componentDataFields = moduleDataFields
  values = Object.assign(
    {
      lookup_in_component_database: isNaN(record.id) || Boolean(record.module),
      module: record.module,
    },
    record
  )

  if (data) {
    //some values copy directly from matching fieldname
    componentDataFields.forEach(
      (fieldConfig) => (values[fieldConfig.custom_field_prefix_source] = data[fieldConfig.source])
    )
  } else {
    componentDataFields.forEach((fieldConfig) => (values[fieldConfig.custom_field_prefix_source] = ''))
  }

  return values
}

const mapStateToProps = (state) => {
  return {
    org_id: state.auth ? state.auth.org_id : null,
    currencySymbol: currencySymbolForCountry(getOrgCountryCodeFromState(state)),
  }
}

const mapDispatchToProps = {
  duplicate: duplicateAction,
}

export const ComponentModuleList = connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => (
  <List
    pagination={
      <PaginationWithActions
        uploadModel={'component_modules'}
        downloadUrl={window.API_BASE_URL + 'component_modules/?fieldset=csv&format=csv&ordering=-id'}
        downloadFilename="OpenSolarComponentModules"
      />
    }
    title="Modules"
    hasSearch={true}
    actions={<ListActions createButtonLabel="Add New Module" />}
    {...props}
  >
    <Datagrid>
      <FieldFromComponentReferenceOrData source="code" sortable={true} />
      <FieldFromComponentReferenceOrData source="manufacturer_name" sortable={false} />
      <FieldFromComponentReferenceOrData source="countries_include_csv" sortable={false} />
      <FieldFromComponentReferenceOrData source="countries_exclude_csv" sortable={false} />
      <ActionsField
        source="actions"
        resource="component_modules"
        // label={translate('Actions')}
        duplicate={props.duplicate}
        sortable={false}
      />
    </Datagrid>
  </List>
))

const ComponentModuleName = ({ translate, style }) => {
  return <span style={style}>{translate('Edit Module')}</span>
}

const formatSubmitValues = (values) => {
  const data = {}
  moduleDataFields.forEach((fieldConfig) => {
    const key = fieldConfig.source
    data[key] = values[fieldConfig.custom_field_prefix_source]
    if (fieldConfig.component === 'Number') {
      data[key] = parseFloat(data[key])
    }
    if (fieldConfig.component === 'Boolean') {
      data[key] = !!data[key]
    }
  })
  const dataString = JSON.stringify(data)
  values.data = dataString
  values.code = values['custom_data_field_code']
  return values
}

const _ComponentModuleEdit = (props) => {
  return (
    <Edit title={<ComponentModuleName />} {...props}>
      <SimpleForm
        formatSubmitValues={formatSubmitValues}
        defaultValue={getDefaultValuesFromDataModules}
        toolbar={<CreateEditToolbar resource="component_modules" org_id={props.org_id} duplicate={props.duplicate} />}
      >
        <ReferenceInput
          name="manufacturer"
          source="manufacturer"
          reference="manufacturers"
          optionValueField="url"
          sort={{ field: 'name', order: 'ASC' }}
          allowEmpty
          {...props}
        >
          <AutocompleteInput
            optionText="name"
            optionValue="url"
            options={{
              style: inputStyle,
              containerStyle: inputStyle,
            }}
          />
        </ReferenceInput>

        {moduleDataFields.map((fieldConfig, index) => (
          <GenericComponentField key={index} props={props} fieldConfig={fieldConfig} isStaff={true} />
        ))}

        <div style={{ clear: 'both' }} />
        <TextInput style={inputStyle} multiline={true} source="external_data.lyra.uuid" label="Lyra UUID" />
        <SelectInput
          choices={LYRA_SUPPORT_OPTIONS}
          source="external_data.lyra.support_status"
          label="Lyra Support Status"
        />

        <TextInput fullWidth={true} multiline={true} source="countries_include_csv" />
        <TextInput fullWidth={true} multiline={true} source="countries_exclude_csv" />

        <TextInput source="data" style={{ width: '100%', opacity: '0.5', display: 'none' }} label="Hidden Data" />
      </SimpleForm>
    </Edit>
  )
}

export const ComponentModuleEdit = connect(mapStateToProps, mapDispatchToProps)(_ComponentModuleEdit)

const _ComponentModuleCreate = (props) => {
  const translate = useTranslate()
  return (
    <Create title="Create Module" {...props}>
      <SimpleForm formatSubmitValues={formatSubmitValues} defaultValue={getDefaultValuesFromDataModules}>
        <ReferenceInput
          name="manufacturer"
          source="manufacturer"
          reference="manufacturers"
          optionValueField="url"
          sort={{ field: 'name', order: 'ASC' }}
          allowEmpty
          {...props}
        >
          <AutocompleteInput
            optionText="name"
            optionValue="url"
            options={{
              style: inputStyle,
              containerStyle: inputStyle,
            }}
          />
        </ReferenceInput>

        {moduleDataFields.map((fieldConfig, index) => (
          <GenericComponentField key={index} props={props} fieldConfig={fieldConfig} isStaff={true} />
        ))}
        <div style={{ clear: 'both' }} />
        <TextInput fullWidth={true} multiline={true} source="countries_include_csv" />
        <TextInput fullWidth={true} multiline={true} source="countries_exclude_csv" />
        <TextInput
          source="data"
          style={{ width: '100%', opacity: '0.5', display: 'none' }}
          elStyle={{ width: '100%' }}
          label={translate('Hidden Data')}
        />
      </SimpleForm>
    </Create>
  )
}

export const ComponentModuleCreate = connect(mapStateToProps, mapDispatchToProps)(_ComponentModuleCreate)

import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, useMediaQuery } from '@material-ui/core'
import { AspectRatioOutlined, MinimizeRounded } from '@material-ui/icons'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import React, { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { PaymentOptionDataType } from 'types/paymentOptions'
import { SystemDataType } from 'types/systems'
import { QuotationTablePropsType } from './index'
import PaymentOptionRow from './PaymentOptionRow'

type PropTypes = {
  open: boolean
  onClose: () => void
  system: SystemDataType
  paymentOptions: PaymentOptionDataType[]
  quotationTableProps: QuotationTablePropsType
  setWasDismissed: (val: boolean) => void
}

const useStyle = makeStyles((theme) => ({
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  expandIcon: {
    cursor: 'pointer',
    backgroundColor: 'rgba(236, 236, 236, 0.7)',
    borderRadius: '20px',
    padding: '7px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid black',
  },
  actionsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '5px 20px',
    width: '100%',
  },
}))

const ApplyNowPromptDialog: React.FC<PropTypes> = (props) => {
  const isMobile = useMediaQuery('(max-width:800px)')

  const [selectedPaymentOption, setSelectedPaymentOption] = useState<undefined | PaymentOptionDataType>(undefined)
  const [isExpanded, setIsExpanded] = useState<boolean>(isMobile)

  const translate = useTranslate()
  const classes = useStyle()

  useEffect(() => {
    if (props.system) {
    }
  }, [])

  useEffect(() => {
    if (isMobile) setIsExpanded(true)
  }, [isMobile])

  const openApp = () => {
    props.onClose()
    props.setWasDismissed(true)
  }

  if (!props.open) return null

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth={isMobile ? undefined : 'xl'} fullScreen={isExpanded}>
      <DialogTitle>
        <div className={classes.titleWrapper}>
          <span>{translate('Learn About Your Financing Options')}</span>
          {!isMobile && (
            <div className={classes.expandIcon} onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? <MinimizeRounded /> : <AspectRatioOutlined />}
            </div>
          )}
        </div>
      </DialogTitle>
      <DialogContent>
        <div>
          <p>
            The following financing options are available on this system. If you'd like to learn more or apply for
            financing you can do so by clicking the buttons below.
          </p>
          {props.paymentOptions?.map((pmt) => {
            // if (selectedPaymentOption && selectedPaymentOption.id !== pmt.id) return null
            return (
              <PaymentOptionRow
                paymentOption={pmt}
                key={`${pmt.id}-${props.system.uuid}`}
                setSelectedPaymentOption={setSelectedPaymentOption}
                isSelected={selectedPaymentOption?.id === pmt.id}
                quotationTableProps={props.quotationTableProps}
                systemId={props.system?.id}
                openApp={openApp}
              />
            )
          })}
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.actionsWrapper}>
          <ProUXButton onClick={props.onClose} type="secondary" label="Close" />
        </div>
      </DialogActions>
    </Dialog>
  )
}
export default ApplyNowPromptDialog

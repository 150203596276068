var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import createStandardIcon from './createStandardIcon';
var HeatPumpOutlined = function (props) {
    return (_jsxs("svg", __assign({ focusable: "false", "aria-hidden": "true", viewBox: "0 0 24 24" }, props, { children: [_jsx("path", { d: "M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2m0 16H5V5h14z" }), _jsx("path", { d: "M12 18c3.31 0 6-2.69 6-6s-2.69-6-6-6-6 2.69-6 6 2.69 6 6 6m3.44-8c.26.45.44.96.51 1.5h-7.9c.07-.54.24-1.05.51-1.5zm.51 2.5c-.07.54-.24 1.05-.51 1.5H8.56c-.26-.45-.44-.96-.51-1.5zM9.38 15h5.24c-.7.61-1.61 1-2.62 1s-1.91-.39-2.62-1m5.24-6H9.38c.7-.61 1.61-1 2.62-1s1.91.39 2.62 1" })] })));
};
export var HeatPumpOutlinedIcon = createStandardIcon(HeatPumpOutlined);

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export var PaperClipIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? '1rem' : _b, _c = _a.color, color = _c === void 0 ? 'currentColor' : _c, rest = __rest(_a, ["size", "color"]);
    var iconAttributes = {
        width: size,
        height: size,
        stroke: color,
    };
    return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 18 20", fill: "none" }, rest, iconAttributes, { children: _jsx("path", { d: "M16.9567 9.50494L9.91124 16.5504C7.99362 18.468 5.11768 18.6945 3.16253 16.7393C1.24491 14.8217 1.49413 12.0436 3.44928 10.0884L11.3691 2.16861C12.5812 0.956493 14.5326 0.956493 15.7447 2.16861C16.9568 3.38073 16.9568 5.33212 15.7447 6.54424L7.68585 14.6031C7.0817 15.2073 6.10218 15.2073 5.49803 14.6031C4.89388 13.999 4.89388 13.0195 5.49803 12.4153L12.6825 5.2308", stroke: color, strokeWidth: "2", strokeLinecap: "round" }) })));
};

import { Signal } from 'opensolar-sdk'
import { SignalType } from '../../types/studio/signals'

/**
 * This binds a Studio signal to an SDK signal.
 */

export const bindSignal = <SdkHandler extends (...args: any) => any, A1 = any, A2 = any, A3 = any>(
  signalName: string,
  sdkSignal: Signal<SdkHandler>,
  studioSignal: SignalType<A1, A2, A3>,
  sanitizeArgs: (args: [A1, A2, A3]) => Parameters<SdkHandler> = defaultSanitize as any
) => {
  const callback = (...args: [A1, A2, A3]) => {
    var SIGNALS_TO_IGNORE_WHILE_LOADING = ['objectAdded', 'objectChanged', 'objectRemoved']

    if (SIGNALS_TO_IGNORE_WHILE_LOADING.includes(signalName)) {
      var sceneIsLoading = window.editor.sceneIsLoading
      var terrainIsLoading = window.editor.scene.dsmUrl && window.editor.waiting.terrainFirstRender

      if (sceneIsLoading || terrainIsLoading) {
        // e.g. Calling refreshUserData on objects while scene is loading can cause critical errors
        console.warn('Signals skipped while scene is loading to prevent corrupting data while loading: ' + signalName)
        return
      }
    }
    const sdkArgs = sanitizeArgs(args)
    sdkSignal.dispatch(...sdkArgs)
  }
  studioSignal.add(callback)

  // returns cleanup function
  return () => {
    studioSignal.remove(callback)
  }
}

const defaultSanitize = (args: any[]): any[] => {
  return args.map((arg) => {
    if (!arg) {
      return null
    }

    switch (typeof arg) {
      case 'object':
        if (arg.toJSON) {
          // This could be unnecessary process effort but currently there is no guarantee that userData
          // will be up to date so we force it to refresh here
          // Do not apply if arg.parent is not set because the object is not in the scene and could cause
          // errors if trying to call refreshUserData
          if (arg.parent && arg.refreshUserData) {
            arg.refreshUserData()
          }
          return arg.toJSON().object
        }
        if (arg.id) return { id: arg.id }
        if (Array.isArray(arg)) return defaultSanitize(arg)
        return undefined // Don't return complex object?
      case 'function':
        return null // hopefully never actually happens
    }
    return arg
  })
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var MoreOptionsIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? '1rem' : _b, _c = _a.color, color = _c === void 0 ? 'currentColor' : _c, rest = __rest(_a, ["size", "color"]);
    var iconAttributes = {
        width: size,
        height: size,
        stroke: color,
    };
    return (_jsx("svg", __assign({ viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, rest, iconAttributes, { children: _jsx("g", __assign({ id: "dot-vertical-filled" }, { children: _jsxs("g", __assign({ id: "Icon" }, { children: [_jsx("path", { d: "M10 6.5C8.89543 6.5 8 5.60457 8 4.5C8 3.39543 8.89543 2.5 10 2.5C11.1046 2.5 12 3.39543 12 4.5C12 5.60457 11.1046 6.5 10 6.5Z", fill: color }), _jsx("path", { d: "M10 12.5C8.89543 12.5 8 11.6046 8 10.5C8 9.39543 8.89543 8.5 10 8.5C11.1046 8.5 12 9.39543 12 10.5C12 11.6046 11.1046 12.5 10 12.5Z", fill: color }), _jsx("path", { d: "M10 18.5C8.89543 18.5 8 17.6046 8 16.5C8 15.3954 8.89543 14.5 10 14.5C11.1046 14.5 12 15.3954 12 16.5C12 17.6046 11.1046 18.5 10 18.5Z", fill: color })] })) })) })));
};

import CloseDialogButton from 'elements/button/CloseDialogButton'
import { useTranslate } from 'ra-core'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type PropTypes = {
  logoURL: string | undefined | URL
  orgName: string | undefined
  hideShadow?: boolean
  closeDialog?: () => void
}

const useStyles = makeOpenSolarStyles<{ hideShadow: boolean }>((theme) => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLet: '5px',
    borderBottom: ({ hideShadow }) => (hideShadow ? undefined : `1px solid ${theme.greyLight2}`),
    width: '100%',
    height: '50px',
    marginBottom: '20px',
    fontSize: '16px',
    backgroundColor: '#fff',
    boxShadow: ({ hideShadow }) => (hideShadow ? undefined : `0px 2px 10px ${theme.greyLight2}`),
  },
  left: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 11,
  },
  closeWrapper: {
    flex: 1,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '8px',
  },
  closeIcon: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.5,
    },
  },
  logoOrName: {
    paddingLeft: '5px',
    marginRight: '10px',
  },
  paymentsLabel: {
    borderLeftWidth: '1px',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.greyLight2,
    paddingLeft: '10px',
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
}))

const PaymentsPageHeader: React.FC<PropTypes> = (props) => {
  const classes = useStyles({ hideShadow: Boolean(props.hideShadow) })
  const translate = useTranslate()
  return (
    <div className={classes.row}>
      <div className={classes.left}>
        <div className={classes.logoOrName}>
          {props.logoURL ? <img src={`${props.logoURL}`} alt={props.orgName} height="40px" /> : props.orgName}
        </div>
        <div className={classes.paymentsLabel}>{translate('Payments')}</div>
      </div>
      {props.closeDialog ? (
        <div className={classes.closeWrapper}>
          <CloseDialogButton onClose={props.closeDialog} />
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
export default PaymentsPageHeader

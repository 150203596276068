const ModuleSpacingLandscapeIcon = () => (
  <svg width="100%" height="100%" viewBox="0 0 232 233" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.171387" width="232" height="232" fill="white" />
    <path
      d="M14 68H44.5H46.5L77 73.5L84 73.225L114.5 68H116.5L147 73.5H154L184.5 68H186.311H217V77L186.311 70.6254H184.5L154 76.5H147L116.5 70.75H114.5L84 76.525L77 76.5L46.5 70.5H44.5L14 77V68Z"
      fill="#FFAB1F"
    />
    <path
      d="M14 164H44.5H46.5L77 169.5L84 169.225L114.5 164H116.5L147 169.5H154L184.5 164H186.311H217V173L186.311 166.625H184.5L154 172.5H147L116.5 166.75H114.5L84 172.525L77 172.5L46.5 166.5H44.5L14 173V164Z"
      fill="#FFAB1F"
    />
    <path d="M14 32.7094L44.7065 26.9998V67.9417L14 73.6513V32.7094Z" fill="#727272" />
    <path
      d="M19.8334 49.9165L17.925 52.0002L19.8334 54.0832"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M46.4121 27.1714L77.1186 32.881V73.823L46.4121 68.1134V27.1714Z" fill="#727272" />
    <path
      d="M71.1666 49.9165L73.075 52.0002L71.1666 54.0832"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14 76.2672L44.7065 70.5576V111.5L14 117.209V76.2672Z" fill="#727272" />
    <path d="M46.4121 70.5579L77.1186 76.2675V117.209L46.4121 111.5V70.5579Z" fill="#727272" />
    <path
      d="M19.8334 91.9165L17.925 94.0002L19.8334 96.0832"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71.1666 91.9165L73.075 94.0002L71.1666 96.0832"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M83.9426 32.881L114.649 27.1714V68.1134L83.9426 73.823V32.881Z" fill="#727272" />
    <path d="M116.355 27.1714L147.061 32.881V73.823L116.355 68.1134V27.1714Z" fill="#727272" />
    <path
      d="M89.8334 49.9165L87.925 52.0002L89.8334 54.0832"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M141.167 49.9165L143.075 52.0002L141.167 54.0832"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M83.9426 76.2672L114.649 70.5576V111.5L83.9426 117.209V76.2672Z" fill="#727272" />
    <path d="M116.355 70.5579L147.061 76.2675V117.209L116.355 111.5V70.5579Z" fill="#727272" />
    <path
      d="M89.8334 91.9165L87.925 94.0002L89.8334 96.0832"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M141.167 91.9165L143.075 94.0002L141.167 96.0832"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M153.885 32.881L184.592 27.1714V68.1134L153.885 73.823V32.881Z" fill="#727272" />
    <path d="M186.297 27.1714L217.004 32.881V73.823L186.297 68.1134V27.1714Z" fill="#727272" />
    <path
      d="M159.833 49.9165L157.925 52.0002L159.833 54.0832"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M211.167 49.9165L213.075 52.0002L211.167 54.0832"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M153.885 76.2672L184.592 70.5576V111.5L153.885 117.209V76.2672Z" fill="#727272" />
    <path d="M186.297 70.5579L217.004 76.2675V117.209L186.297 111.5V70.5579Z" fill="#727272" />
    <path
      d="M159.833 91.9165L157.925 94.0002L159.833 96.0832"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M211.167 91.9165L213.075 94.0002L211.167 96.0832"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14 128.881L44.7065 123.171V164.113L14 169.823V128.881Z" fill="#727272" />
    <path d="M46.4121 123.171L77.1186 128.881V169.823L46.4121 164.113V123.171Z" fill="#727272" />
    <path
      d="M19.8334 145.917L17.925 148L19.8334 150.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71.1666 145.917L73.075 148L71.1666 150.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14 172.267L44.7065 166.558V207.5L14 213.209V172.267Z" fill="#727272" />
    <path d="M46.4121 166.558L77.1186 172.267V213.209L46.4121 207.5V166.558Z" fill="#727272" />
    <path
      d="M19.8334 187.917L17.925 190L19.8334 192.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71.1666 187.917L73.075 190L71.1666 192.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M83.9426 128.881L114.649 123.171V164.113L83.9426 169.823V128.881Z" fill="#727272" />
    <path d="M116.355 123.171L147.061 128.881V169.823L116.355 164.113V123.171Z" fill="#727272" />
    <path
      d="M89.8334 145.917L87.925 148L89.8334 150.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M141.167 145.917L143.075 148L141.167 150.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M83.9426 172.267L114.649 166.558V207.5L83.9426 213.209V172.267Z" fill="#727272" />
    <path d="M116.355 166.558L147.061 172.267V213.209L116.355 207.5V166.558Z" fill="#727272" />
    <path
      d="M89.8334 187.917L87.925 190L89.8334 192.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M141.167 187.917L143.075 190L141.167 192.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M153.885 128.881L184.592 123.171V164.113L153.885 169.823V128.881Z" fill="#727272" />
    <path d="M186.297 123.171L217.004 128.881V169.823L186.297 164.113V123.171Z" fill="#727272" />
    <path
      d="M159.833 145.917L157.925 148L159.833 150.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M211.167 145.917L213.075 148L211.167 150.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M153.885 172.267L184.592 166.558V207.5L153.885 213.209V172.267Z" fill="#727272" />
    <path d="M186.297 166.558L217.004 172.267V213.209L186.297 207.5V166.558Z" fill="#727272" />
    <path
      d="M159.833 187.917L157.925 190L159.833 192.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M211.167 187.917L213.075 190L211.167 192.083"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ModuleSpacingLandscapeIcon

import { Box, Card, Dialog, DialogContent, DialogTitle, Grid, IconButton, TextField } from '@material-ui/core'
import { VerifiedUser } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orgSelectors } from 'ducks/orgs'
import { Button } from 'opensolar-ui'
import React, { useEffect, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { restClientInstance } from 'resources/paymentOptions/financialIntegrations/FinancialIntegration'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import Badge from './sharedComponents/Badge'

type PropTypes = {
  closeDialog: () => void
}

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    borderTop: '1px #DADBE9 solid',
    padding: '1rem 0',
  },

  flexRow: {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
  },

  icon: {
    top: 10,
    right: 10,
    position: 'absolute',
    minWidth: 40,
    backgroundColor: theme.headerBackgroundColor,
  },

  closeIcon: {
    color: '#B4B4B4',
  },

  header: { fontSize: 20, fontWeight: 500, marginTop: 0, marginBottom: 10 },

  description: {
    fontSize: 13,
    lineHeight: '22px',
  },

  card: {
    backgroundColor: '#EDF3FF',
    border: 'none',
    textAlign: 'center',
    padding: '1rem',
    marginBottom: '1rem',
  },

  cardHeader: {
    color: '#2C499D',
    fontSize: 15,
    fontWeight: 500,
  },

  cardDescription: {
    color: theme.greyMid1,
    fontSize: 11,
  },

  chart: {
    height: '7rem',
  },

  shieldIcon: {
    height: '1.25rem',
    color: '#019939',
    padding: '10px',
    background: '#DAE5FF',
    borderRadius: 7,
  },

  backBtn: {
    color: theme.greyMid1,
    fontSize: 13,
  },

  submitBtn: {
    width: '100%',
    fontSize: 13,
  },
}))

const NonAdminOnboardingForm: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const notify = useNotify()

  const orgId = useSelector(orgSelectors.getOrg)?.id
  const [contact, setContact] = useState('')

  useEffect(() => {
    logAmplitudeEvent('psp_onboarding_non_admin_form_viewed', { org_id: orgId })
  }, [])

  const onPageSubmit = () => {
    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${orgId}/payments/non_admin_onboarding_request/`,
      data: { who_to_contact: contact },
    })
      .then((res) => {
        if (res?.data?.success) {
          notify(translate('Thank you, we will reach out to the administrators in your account'), 'success')
          props.closeDialog()
          logAmplitudeEvent('psp_onboarding_non_admin_form_submitted', { org_id: orgId })
        }
      })
      .catch((err) => {
        notify(
          translate('Something went wrong and we were unable to contact the administrators in your account'),
          'warning'
        )
      })
  }

  return (
    <Dialog open={true} maxWidth="md">
      <DialogTitle>
        <div className={classes.flexRow}>
          {translate('Activate CashFlow')}
          <Badge text={translate('1 min')} />
        </div>
      </DialogTitle>
      <IconButton className={classes.icon} onClick={props.closeDialog}>
        <CloseIcon className={classes.closeIcon} />
      </IconButton>

      <DialogContent>
        <Grid container spacing={2} className={classes.wrapper}>
          <Grid item xs={6}>
            <h1 className={classes.header}>{translate("We'll help you get setup")}</h1>

            <p className={classes.description}>
              {translate(
                "OpenSolar CashFlow is the first ever intelligent payments solution built specifically for Solar Pros, by Solar Pros. Let's unlock an easier way to get paid and manage your project accounting!"
              )}
            </p>
            <p className={classes.description}>
              {translate(
                "Only users with administrator privileges can set up CashFlow. It looks like you're not an admin, but we can still help you get CashFlow set up."
              )}
            </p>
            <p className={classes.description}>
              {translate(
                "We'll reach out to the admins on your account to start CashFlow setup. In the meantime if you have any suggestions who would be best to complete this process, please share that below:"
              )}
            </p>
            <TextField
              fullWidth={true}
              id="outlined-multiline-static"
              label={translate('Who at your company is best to set up CashFlow? (Optional)')}
              multiline
              variant="outlined"
              rows={4}
              value={contact}
              onChange={(e) => {
                setContact(e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <Card variant="outlined" className={classes.card}>
              <img src={`${window.PUBLIC_URL}/images/cashflow/pie-chart.svg`} className={classes.chart} />
              <h2 className={classes.cardHeader}>
                {translate('Solar Businesses save 20% or more on credit card transactions with CashFlow')}
              </h2>
              <p className={classes.cardDescription}>
                {translate('* Compared to consumer point of sale transaction fees')}
              </p>
            </Card>
            <Card variant="outlined" className={classes.card}>
              <VerifiedUser className={classes.shieldIcon} />
              <h2 className={classes.cardHeader}>{translate('Safe & Secure')}</h2>
              <p className={classes.cardDescription}>
                {translate(
                  'Payment information is secure and encrypted. Our platform prioritizes security and data privacy'
                )}
              </p>
              <Box display="flex" alignItems="center" justifyContent="space-evenly">
                <img src={`${window.PUBLIC_URL}/images/cashflow/visa.svg`} />
                <img src={`${window.PUBLIC_URL}/images/cashflow/mastercard.svg`} />
                <img src={`${window.PUBLIC_URL}/images/cashflow/apple-pay.svg`} />
                <img src={`${window.PUBLIC_URL}/images/cashflow/google-pay.svg`} />
                <img src={`${window.PUBLIC_URL}/images/cashflow/paypal.svg`} />
              </Box>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Button variant="text" className={classes.backBtn} onClick={props.closeDialog}>
              {translate('Back')}
            </Button>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <Button variant="contained" color="primary" className={classes.submitBtn} onClick={onPageSubmit}>
              {translate("I'm interested in using CashFlow!")}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default NonAdminOnboardingForm

import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orderSelectors } from 'ducks/orderComponents'
import Button from 'elements/button/Button'
import { useCallback, useState } from 'react'
import { Confirm } from 'react-admin'
import { useSelector } from 'react-redux'
import useSegenService from 'services/segen/useSegenServiceV2'
import { useFeatureFlag } from 'util/split'

const SegenLoginButton = ({
  fullWidth,
  returnUrl,
  color = 'default',
  variant = 'contained',
}: {
  fullWidth?: boolean
  returnUrl?: string
  color?: 'default' | 'primary' | 'secondary' | 'warning'
  variant?: 'contained' | 'outlined' | 'text'
}) => {
  const [isOpen, setOpen] = useState(false)
  const segenService = useSegenService()
  const enableRedirect = !!useFeatureFlag('segen_auth_redirect', 'on')

  const lineItems = useSelector(orderSelectors.getOrderableLineItems)

  const handleConnectToSegen = useCallback(async () => {
    await segenService.connect({ enableRedirect: true, source: 'hardware_banner', returnUrl })
  }, [enableRedirect])

  const handleButtonClick = () => {
    if (enableRedirect) {
      setOpen(true)
    } else {
      handleConnectToSegen()
    }
    logAmplitudeEvent('hardware_distributor_connect_button_clicked', {
      source: 'hardware_banner',
      distributor: 'segen',
    })
  }

  return (
    <>
      <Button
        variant={variant}
        color={color}
        label={'Connect Segen'}
        onClick={handleButtonClick}
        disabled={false}
        fullWidth={fullWidth}
      />
      {enableRedirect && (
        <Confirm
          isOpen={isOpen}
          title="Connect to Segen"
          content="You will be redirected to the Segen login page to connect your accounts, please confirm to proceed. Any unsaved changes will be discarded."
          confirmColor="warning"
          onConfirm={() => {
            segenService.connect({ enableRedirect, source: 'SegenConnectUserButton', returnUrl })
          }}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  )
}

export default SegenLoginButton

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import PropTypes from 'prop-types';
import Title, { TitlePropType } from './Title';
import { useTranslate } from 'ra-core';
var Subtitle = function (_a) {
    var subtitle = _a.subtitle;
    var translate = useTranslate();
    return subtitle ? (_jsx("p", __assign({ style: {
            color: 'rgba(0, 0, 0, 0.54)',
            margin: 0,
            marginBottom: 10,
        } }, { children: typeof subtitle === 'string' ? translate(subtitle) : subtitle }))) : null;
};
export default Subtitle;

import type { AccountInfo, IPublicClientApplication, SilentRequest } from '@azure/msal-browser'
import { AuthenticationResult } from '@azure/msal-browser'

export type ConnectArgsType = {
  accessTokenRequest: SilentRequest
  enableRedirect: boolean
}

export type DisconnectArgsType = {
  account: AccountInfo
}

export type AuthenticationStatus = 'idle' | 'authenticated' | 'unAuthenticated' | 'inProcess'

type GetAccessTokenType = (args: { accessTokenRequest: SilentRequest }) => Promise<string | undefined>

class MsalBasicService {
  protected msalInstance: IPublicClientApplication
  protected cachedAuthenticationResult: AuthenticationResult | undefined
  protected authenticationStatus: AuthenticationStatus = 'idle'

  constructor({ msalInstance }: { msalInstance: IPublicClientApplication }) {
    this.msalInstance = msalInstance
  }

  private acquireTokenSilent = async (accessTokenRequest: SilentRequest): Promise<AuthenticationResult | undefined> => {
    try {
      const accessTokenResponse = await this.msalInstance.acquireTokenSilent(accessTokenRequest)
      // Acquire token silent success
      this.cachedAuthenticationResult = accessTokenResponse
      return accessTokenResponse
    } catch (error) {
      console.warn('acquireTokenSilent error, ', error)
    }
  }

  private acquireTokenByPopup = async (
    accessTokenRequest: SilentRequest
  ): Promise<AuthenticationResult | undefined> => {
    let accessToken = await this.acquireTokenSilent(accessTokenRequest)
    if (accessToken) {
      return accessToken
    }
    try {
      const acquireTokenPopupResponse = await this.msalInstance.acquireTokenPopup(accessTokenRequest)
      this.cachedAuthenticationResult = acquireTokenPopupResponse
      return acquireTokenPopupResponse
    } catch (error) {
      console.warn('acquireTokenPopup error, ', error)
    }
  }

  private acquireTokenByRedirect = async (
    accessTokenRequest: SilentRequest
  ): Promise<AuthenticationResult | undefined> => {
    let accessToken = await this.acquireTokenSilent(accessTokenRequest)
    if (accessToken) {
      return accessToken
    }
    try {
      await this.msalInstance.acquireTokenRedirect(accessTokenRequest)
      // Nothing return due to redirection
    } catch (error) {
      console.warn('acquireTokenByRedirect error, ', error)
    }
  }

  protected getAllAccounts = (): AccountInfo[] => {
    return this.msalInstance.getAllAccounts()
  }

  protected refreshAuthenticationSilently = async ({ accessTokenRequest }: { accessTokenRequest: SilentRequest }) => {
    const accessTokenResponse = await this.acquireTokenSilent(accessTokenRequest)
    if (accessTokenResponse != null) {
      return accessTokenResponse
    }
  }

  protected getAccessTokenSilently: GetAccessTokenType = async ({ accessTokenRequest }) => {
    const accessTokenResponse = await this.acquireTokenSilent(accessTokenRequest)
    if (accessTokenResponse != null) {
      return accessTokenResponse.accessToken
    }
  }

  protected connectAccount = async ({ accessTokenRequest, enableRedirect }: ConnectArgsType) => {
    let authenticationResult: AuthenticationResult | undefined = undefined
    if (enableRedirect) {
      authenticationResult = await this.acquireTokenByRedirect(accessTokenRequest)
    } else {
      authenticationResult = await this.acquireTokenByPopup(accessTokenRequest)
    }

    if (authenticationResult != null) {
      return true
    }

    return false
  }

  protected disconnectAccount = async ({ account }: DisconnectArgsType) => {
    const logoutRequest = {
      account,
    }

    await this.msalInstance.logoutRedirect(logoutRequest)
    this.cachedAuthenticationResult = undefined
    return true
  }
}

export default MsalBasicService

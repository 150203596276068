import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { orgSelectors } from '../../../ducks/orgs'
import { HardwareSupplierEnum } from '../../../pages/ordering/type'
import type { FactoryHookType } from '../type'
import maybeCreateDiscountFilterNode from './DiscountFilterNodeFactory'

const useDiscountTypeFilterNode: FactoryHookType = ({ RendererComponent, options }) => {
  const enabledDistributors = useSelector(orgSelectors.getEnabledHardwareSuppliers)

  // Currently it should only show for Solar Outlet for Org within AU
  const hasDistributor = enabledDistributors.includes(HardwareSupplierEnum.SolarOutlet)

  return useMemo(() => {
    return maybeCreateDiscountFilterNode({
      hasDistributor,
      RendererComponent,
    })
  }, [options.componentType, hasDistributor])
}

export default useDiscountTypeFilterNode

import { makeStyles, useMediaQuery, withTheme } from '@material-ui/core'
import ContentCreate from '@material-ui/icons/CreateOutlined'
import AlertError from '@material-ui/icons/ErrorOutlineOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { push } from 'connected-react-router'
import { authSelectors } from 'ducks/auth'
import CustomField from 'elements/field/CustomField'
import { useBulkActionButtons } from 'elements/hooks/useBulkActionButtons'
import QuickFilter from 'elements/QuickFilter'
import { useEditShareable } from 'elements/react-admin/EditShareable'
import { List } from 'elements/react-admin/List'
import ListActions from 'elements/react-admin/ListActions'
import TextFieldWithSharedEntityIcon from 'elements/TextFieldWithSharedEntityIcon'
import inflection from 'inflection'
import withMediaQuery from 'layout/withMediaQuery'
import { Button } from 'opensolar-ui'
import { useListController } from 'ra-core'
import { Filter } from 'ra-ui-materialui'
import { useEffect } from 'react'
import {
  BooleanField,
  Create,
  Datagrid,
  FunctionField,
  SaveButton,
  showNotification as showNotificationAction,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useNotify,
  useTranslate,
} from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { connect, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import compose from 'recompose/compose'
import ShareabilityFilters from 'resources/connectedOrgs/ShareabilityFilters'
import GreenCheckIcon from 'resources/paymentOptions/financialIntegrations/GreenCheckIcon'
import { styles as standardStyles } from 'styles'
import { duplicate as duplicateAction } from '../../actions/restActions'
import EditOrImportButton from '../../elements/button/EditOrImportButton'
import SimpleList from '../../elements/CustomSimpleList'
import { currencySymbolForCountry, formatDateString, periodsPerYear, titleCase } from '../../util/misc'
import { getOrgCountryCodeFromState, getOrgFromState } from '../../util/org'
import { toggle_inputs_and_extra_fields } from './common'
import { payment_types_and_variables } from './fields'
import { PaymentOptionFormContent } from './PaymentOptionFormContent'
import QuotationConfiguration from './QuotationConfiguration'
import { styles } from './styles.js'
import SwapPaymentOptionsButton from './swapPaymentOptions/SwapPaymentOptionsButton'
import { defaultMilestones } from './widgets/PaymentMilestones'

const getFieldConfig = (payment_type, variable_name) => {
  var variables = payment_types_and_variables.filter((pt) => pt.title === payment_type)[0].variables
  for (var j = 0; j < variables.length; j++) {
    if (variables[j].name === variable_name) {
      return variables[j]
    }
  }
  throw new Error('getFieldConfig(): field configuration not found for:' + payment_type + '/' + variable_name)
}

const paymentTypeLabelForPaymentType = (paymentType) => {
  try {
    return payment_types_and_variables.filter((pt) => pt.title === paymentType)[0].label
  } catch (error) {
    return 'Not specified'
  }
}

const mapStateToProps = (state) => {
  var org = getOrgFromState(state)
  return {
    orgId: org && org.id,
    currencySymbol: currencySymbolForCountry(getOrgCountryCodeFromState(state)),
    enableCheckout: org && org.enable_checkout,
  }
}

function validNumberInRange(value, min, max) {
  var val = parseFloat(value)
  var isLessThanValid = false

  if (min !== undefined && val < min) {
    isLessThanValid = true
  } else if (max !== undefined && val > max) {
    // continue
  } else {
    return
  }

  if (min !== undefined && max !== undefined) {
    return 'Field must be between ' + min + ' and ' + max + '.'
  } else if (isLessThanValid) {
    return 'Field must be > ' + min + '.'
  } else {
    return 'Field must be < ' + max + '.'
  }
}

const fieldsForPaymentType = (paymentTypeName) => {
  for (var index in payment_types_and_variables) {
    var item = payment_types_and_variables[index]
    if (item.title === paymentTypeName) {
      return item.variables
    }
  }
}

const disableBulkActionButtons = (data, selectedIds) => {
  const parseConfig = (id) => JSON.parse(data[id].configuration_json)
  // filter integration from selected items if they have treat_all_terms_as_unit set to true
  const selectedIntegrations = Array.from(
    new Set(
      selectedIds
        .map((id) => {
          const config = parseConfig(id)
          if (config?.integration && data[id].finance_product_category.treat_all_terms_as_unit) {
            return config.integration
          }
          return null
        })
        .filter(Boolean)
    )
  )

  for (const integration of selectedIntegrations) {
    const integrationOptions = Object.keys(data).filter((id) => {
      const config = parseConfig(id)
      return config?.integration === integration && !data[id].is_archived
    })

    // Check if the selected items match the all-or-nothing criteria
    const allSelected = integrationOptions.every((id) => selectedIds.includes(Number(id)))
    const noneSelected = integrationOptions.every((id) => !selectedIds.includes(Number(id)))

    if (!(allSelected || noneSelected)) {
      return true
    }
  }
  return false
}

export const PaymentOptionFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput style={standardStyles.FILTER_FIELD_STYLE} label="pos.search" source="q" />
      <QuickFilter source="show_only_archived" defaultValue={1} />
    </Filter>
  )
}

const _PaymentOptionList = ({ accessRights: { allowCreate, allowEdit, allowDelete }, ...props }) => {
  const notify = useNotify()
  const translate = useTranslate()
  const isMedium = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const controllerData = useListController(props)
  const shouldDisableBulkActionButton = disableBulkActionButtons(controllerData.data, controllerData.selectedIds)
  const bulkActionButtons = useBulkActionButtons(
    { ...props, disableBulkActionButtons: shouldDisableBulkActionButton },
    allowEdit,
    allowDelete,
    allowEdit
  )

  const resourceName = translate(`resources.${props.resource}.name`, {
    smart_count: 1,
    _: inflection.humanize(inflection.singularize(props.resource)),
  })

  // check for params to trigger notification on mount
  useEffect(() => {
    const postHashParams = window.location.hash.substring(window.location.hash.indexOf('?') + 1)
    if (!postHashParams) return null
    const params = new URLSearchParams(postHashParams)
    const productsAdded = params.get('products_added')
    const partner = params.get('partner')
    const errorMsg = params.get('oauth_error')
    if (errorMsg) {
      notify(errorMsg, 'warning')
    } else if (productsAdded && partner) {
      let addedProducts = false
      try {
        if (parseInt(productsAdded) > 0) addedProducts = true
      } catch (ex) {}
      if (addedProducts) {
        notify(
          `${productsAdded} ${partner} ${
            parseInt(productsAdded) === 1 ? 'product has' : 'products have'
          } been added to your org.`,
          'success'
        )
      } else {
        notify(
          `Your ${partner} account is connected but has no active products. Please enable products in your ${partner} portal then use the Refresh Payment Options button below.`,
          'warning'
        )
      }
    }
  }, [])
  return (
    <List
      hasSearch={true}
      actions={<ListActions hasArchived={true} CustomButton={<SwapPaymentOptionsButton />} />}
      alwayShowActions={true}
      extraCreateButtonLabel={translate('ra.page.empty_new', { name: resourceName })}
      filters={<ShareabilityFilters />}
      perPage={20}
      {...props}
      hasCreate={allowCreate}
      bulkActionButtons={bulkActionButtons}
    >
      {isMobile ? (
        <SimpleList
          primaryText={(record) => (
            <span>
              <GreenCheckIcon configuration_json={record.configuration_json} />
              {record.title}
            </span>
          )}
          secondaryText={(record) => (
            <span>
              {translate('Payment Type')}: {translate(paymentTypeLabelForPaymentType(record.payment_type))}
              <br />
              {translate('Auto-apply')}: {record.auto_apply_enabled ? translate('Yes') : translate('No')}
            </span>
          )}
          linkTo={(basePath, id) => `${basePath}/${id}`}
          rightIcon={() => (allowCreate ? <ContentCreate /> : null)}
        />
      ) : (
        <Datagrid duplicate={props.duplicate}>
          <FunctionField
            source="title"
            displayInCard={true}
            render={(record) => (
              <div>
                {record.configuration_json && <GreenCheckIcon configuration_json={record.configuration_json} />}
                <TextFieldWithSharedEntityIcon source="title" record={record} />
              </div>
            )}
            sortable={true}
          />
          <FunctionField
            source="payment_type"
            displayInCard={true}
            render={(record) => translate(paymentTypeLabelForPaymentType(record.payment_type))}
            sortable={true}
          />
          <FunctionField
            source="created_date"
            label="Date Created"
            displayInCard={true}
            render={(record) => formatDateString(record.created_date)}
            sortable={true}
          />
          <BooleanField
            source="auto_apply_enabled"
            label="resources.payment_options.fields.auto_apply"
            textAlign={'center'}
            displayInCard={true}
            skipFromGrid={Boolean(props.layout < 3)}
          />
          {isMedium && <TextField source="priority" />}
          <EditOrImportButton
            sortable={false}
            allowDelete={allowDelete}
            allowEdit={allowEdit}
            source="actions"
            org_id={props.org_id}
            resource="payment_options"
            duplicate={props.duplicate}
            hideShared={true}
          />
        </Datagrid>
      )}
    </List>
  )
}

export const PaymentOptionList = connect(
  (state) => {
    return {
      org_id: state.auth ? state.auth.org_id : null,
    }
  },
  { duplicate: duplicateAction }
)(_PaymentOptionList)

const validatePaymentOptionData = (values, translate, isStaff) => {
  //alert('On startup, populate the variable_* fields from configuration_json')

  const errors = {}
  if (!values.title) {
    errors.title = [translate('Title is required')]
  }
  if (!values.payment_type || values.payment_type.length === 0) {
    errors.payment_type = [translate('Payment Type is required')]
  }

  if (!values?.variable_integration) {
    if (values?.variable_prepayment_enabled && !values?.variable_prepayment_period) {
      errors.variable_prepayment_period = [translate('Please either add a value greater than 0 or disable Prepayment')]
    }
    if (
      values?.variable_prepayment_enabled &&
      values?.variable_prepayment_formula === 'percentage_of_post_dealer_fee_loan_amount' &&
      !values?.variable_prepayment_percentage
    ) {
      errors.variable_prepayment_percentage = [translate('Prepayment Percantage mustu be greater than 0')]
    }
  }

  if (values.variable_payment_milestones_enabled) {
    const totalPercentage =
      values.payment_milestones?.reduce((sum, milestone) => sum + (milestone.deposit_percentage || 0), 0) || 0

    if (totalPercentage > 100) {
      errors['payment_milestones'] = []
      for (let milestones of values.payment_milestones) {
        for (let index = 0; index <= milestones.length; index++) {
          errors['payment_milestones'][index] = {
            deposit_percentage: translate(
              'Total milestone payments percentage are greater than 100% of the system price.'
            ),
          }
        }
      }
    }
  }

  var fieldsForSelectedPaymentType = fieldsForPaymentType(values.payment_type) || []
  fieldsForSelectedPaymentType.forEach((variable) => {
    if (variable.name) {
      const variable_name = variable.name
      const key = `variable_${variable_name}`

      var validationMethod
      try {
        validationMethod = getFieldConfig(values.payment_type, variable_name).validationMethod
      } catch (err) {
        validationMethod = null
      }

      var resolveMethod
      try {
        resolveMethod = getFieldConfig(values.payment_type, variable_name).resolve
      } catch (err) {}
      if (!resolveMethod) {
        resolveMethod = function () {
          return true
        }
      }

      var fieldIsActive = resolveMethod(values)
      if (!fieldIsActive) {
        return //next field
      }

      var fieldName
      try {
        fieldName = getFieldConfig(values.payment_type, variable_name).label
      } catch (err) {
        fieldName = null
      }

      if (fieldsForSelectedPaymentType.map((f) => f.name).indexOf(variable_name) !== -1) {
        if (validationMethod === 'truthy') {
          if (!values[key]) {
            errors[key] = [
              fieldName ? fieldName + ' is required' : titleCase(variable_name.split('_').join(' ')) + ' is required',
            ]
          }
        } else if (validationMethod === 'trueorfalse') {
          if (values[key] !== true && values[key] !== false) {
            errors[key] = [
              fieldName ? fieldName + ' is required' : titleCase(variable_name.split('_').join(' ')) + ' is required',
            ]
          }
        } else if (validationMethod === 'floatRequiredIfNoPaymentFactors') {
          let hasPaymentFactors
          if (values.configuration_json) {
            const paymentFactors = JSON.parse(values.configuration_json)?.payment_factors
            hasPaymentFactors = paymentFactors && paymentFactors?.length > 0
          }
          if (!hasPaymentFactors) {
            if (isNaN(parseFloat(values[key]))) {
              errors[key] = [
                fieldName ? fieldName + ' is required' : titleCase(variable_name.split('_').join(' ')) + ' is required',
              ]
            }
          }
        } else if (validationMethod === 'floatrequired') {
          if (isNaN(parseFloat(values[key]))) {
            errors[key] = [
              fieldName ? fieldName + ' is required' : titleCase(variable_name.split('_').join(' ')) + ' is required',
            ]
          }
        } else if (validationMethod === 'integerrequired') {
          if (typeof values[key] === 'string') {
            if (values[key].includes('.')) {
              errors[key] = [translate('integer number is required')]
            }
          } else if (!Number.isInteger(values[key])) {
            errors[key] = [translate('integer number is required')]
          }
        } else if (validationMethod === 'floatoptional') {
          if (!values[key] || values[key] === '') {
          } else if (isNaN(parseFloat(values[key]))) {
            errors[key] = [
              fieldName
                ? fieldName + ' must be a number or empty'
                : titleCase(variable_name.split('_').join(' ')) + ' must be a number or empty',
            ]
          }
        } else if (validationMethod === 'csvrequired') {
          if ([null, undefined, ''].includes(values[key])) {
            errors[key] = [
              fieldName ? fieldName + ' is required' : titleCase(variable_name.split('_').join(' ')) + ' is required',
            ]
          } else if (typeof values[key] === 'string') {
            const lines = values[key].split('\n') // Should we worry about multiple lines?
            const fields = lines[0].split(',')
            for (const field of fields) {
              if (field === '' || (!Number.isInteger(field) && Number.isNaN(Number.parseFloat(field)))) {
                errors[key] = [translate('Invalid values')]
              }
            }
          }
        }
      }

      var fieldValidation
      if (values.payment_type === 'cash') {
        if (variable_name === 'deposit_percentage') {
          fieldValidation = validNumberInRange(values[key], 0, 100)
        } else if (variable_name === 'deposit_min') {
          fieldValidation = validNumberInRange(values[key], 0)
        } else if (variable_name === 'deposit_max') {
          fieldValidation = validNumberInRange(values[key], 0)
        }
      } else if (values.payment_type === 'loan') {
        if (variable_name === 'interest_rate') {
          fieldValidation = validNumberInRange(values[key], 0)
        } else if (variable_name === 'term_years') {
          fieldValidation = validNumberInRange(values[key], 0)
        } else if (variable_name === 'prepayment_period') {
          fieldValidation = validNumberInRange(values[key], 0)
        } else if (variable_name === 'no_interest_period') {
          fieldValidation = validNumberInRange(values[key], 0)
        } else if (variable_name === 'no_payment_period') {
          fieldValidation = validNumberInRange(values[key], 0)
        } else if (variable_name === 'interest_only_period') {
          fieldValidation = validNumberInRange(values[key], 0)
        } else if (variable_name === 'dealer_fee_percentage') {
          fieldValidation = validNumberInRange(values[key], 0, 100)
        } else if (variable_name === 'dealer_fee_fixed') {
          fieldValidation = validNumberInRange(values[key], 0)
        } else if (variable_name === 'down_payment_percentage') {
          fieldValidation = validNumberInRange(values[key], 0, 100)
        } else if (variable_name === 'down_payment_min') {
          fieldValidation = validNumberInRange(values[key], 0)
        } else if (variable_name === 'down_payment_max') {
          fieldValidation = validNumberInRange(values[key], 0)
        } else if (variable_name === 'reversion_rate') {
          fieldValidation = validNumberInRange(values[key], 0)
        } else if (variable_name === 'start_of_reversion_period') {
          fieldValidation = validNumberInRange(values[key], 0)
        } else if (variable_name === 'product_fee_fixed') {
          fieldValidation = validNumberInRange(values[key], 0)
        }
      } else if (values.payment_type === 'ppa') {
        if (variable_name === 'ppa_dollars_per_kwh') {
          fieldValidation = validNumberInRange(values[key], 0)
        } else if (variable_name === 'ppa_upfront_payment') {
          fieldValidation = validNumberInRange(values[key], 0)
        } else if (variable_name === 'ppa_dollars_per_kwh_exports') {
          fieldValidation = validNumberInRange(values[key], 0)
        } else if (variable_name === 'term_years') {
          fieldValidation = validNumberInRange(values[key], 0)
        } else if (variable_name === 'cap_total_kwh') {
          fieldValidation = validNumberInRange(values[key], 0)
        }
      } else if (values.payment_type === 'regular_payment') {
        if (variable_name === 'payment_amount') {
          fieldValidation = validNumberInRange(values[key], 0)
        } else if (variable_name === 'term_years') {
          fieldValidation = validNumberInRange(values[key], 0)
        }
      } else if (values.payment_type === 'lease') {
        if (variable_name === 'term') {
          fieldValidation = validNumberInRange(values[key], 0)
        } else if (variable_name === 'interest_rate') {
          fieldValidation = validNumberInRange(values[key], 0)
        } else if (variable_name === 'dealer_fee') {
          fieldValidation = validNumberInRange(values[key], 0)
        } else if (variable_name === 'down_payment_percentage') {
          fieldValidation = validNumberInRange(values[key], 0, 100)
        } else if (variable_name === 'down_payment_min') {
          fieldValidation = validNumberInRange(values[key], 0)
        } else if (variable_name === 'down_payment_max') {
          fieldValidation = validNumberInRange(values[key], 0)
        } else if (variable_name === 'brokerage_rate') {
          fieldValidation = validNumberInRange(values[key], 0)
        }
      }

      if (fieldValidation) {
        errors[key] = translate(fieldValidation)
      }
    }
  })
  return errors
}

const formatSubmitValues = (values, isStaff) => {
  const configuration_object = {}
  const fieldsForSelectedPaymentType = fieldsForPaymentType(values.payment_type) || []

  fieldsForSelectedPaymentType.forEach((variable) => {
    if (variable.name) {
      const variable_name = variable.name
      const key = `variable_${variable_name}`

      var validationMethod
      try {
        validationMethod = getFieldConfig(values.payment_type, variable_name).validationMethod
      } catch (err) {
        validationMethod = null
      }

      var resolveMethod
      try {
        resolveMethod = getFieldConfig(values.payment_type, variable_name).resolve
      } catch (err) {}
      if (!resolveMethod) {
        resolveMethod = function () {
          return true
        }
      }

      var fieldIsActive = resolveMethod(values)
      if (!fieldIsActive) {
        return //next field
      }

      if (fieldsForSelectedPaymentType.map((f) => f.name).indexOf(variable_name) !== -1) {
        if (validationMethod === 'truthy') {
          configuration_object[variable_name] = values[key]
        } else if (validationMethod === 'trueorfalse') {
          configuration_object[variable_name] = values[key]
        } else if (validationMethod === 'floatRequiredIfNoPaymentFactors') {
          configuration_object[variable_name] = parseFloat(values[key])
        } else if (validationMethod === 'floatrequired') {
          configuration_object[variable_name] = parseFloat(values[key])
        } else if (validationMethod === 'floatoptional') {
          if (!values[key] || values[key] === '') {
            configuration_object[variable_name] = null
          } else {
            configuration_object[variable_name] = parseFloat(values[key])
          }
        } else if (validationMethod === 'csvrequired') {
          configuration_object[variable_name] = values[key]
        } else if (validationMethod === 'optional') {
          if (!values[key] || values[key] === '') {
            configuration_object[variable_name] = null
          }
          configuration_object[variable_name] = values[key]
        }
      }
    }
  })

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  // Start hack for populating default payment frequency
  // Only for payment options which have a payment_frequency field

  if (
    fieldsForSelectedPaymentType.filter((f) => f.name === 'payment_frequency').length > 0 &&
    !configuration_object.hasOwnProperty('payment_frequency')
  ) {
    configuration_object['payment_frequency'] = 'monthly'
  }

  if (values.quotation_items) {
    configuration_object.quotation_items = values.quotation_items.join(',')
  }

  if (values.quotation_labels) {
    configuration_object.quotation_labels = JSON.stringify(values.quotation_labels)
  }

  if (values.payment_milestones) {
    configuration_object.payment_milestones_enabled = values.variable_payment_milestones_enabled
    configuration_object.show_payment_milestones_table = values.variable_show_payment_milestones_table

    // do not save payment_milestones if all three deposit fields (%, min and max) are either not set or 0 in the main deposit fields unless this is a non-cash payment option. Staff users have
    // the ability to save milestones onto non-cash payment options only for the purposes for contract templates and we don't need to require that a deposit is set up for this.
    // and we want to make sure when a non-staff user comes back and updates a non-cash payment option with milestones that we don't clear the milestones
    if (
      values['variable_deposit_percentage'] ||
      values['variable_deposit_min'] ||
      values['variable_deposit_max'] ||
      values.payment_type !== 'cash'
    ) {
      configuration_object.payment_milestones = values.payment_milestones
    }
  }

  // clearing out the prepayment section will set loan type to null, but we need this to be populated to identify the product type
  if (
    values.variable_integration === 'plenti' &&
    values.integration_external_reference &&
    values.integration_external_reference.includes('is_bnpl=true')
  ) {
    configuration_object.loan_type = 'buy_now_pay_later'
  }

  // End hack for populating default payment frequency /////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  // Start Hack: Inject term into json by multiplying term_years by payments per year for selected payment frequency
  // only do the hack for those payment options have 'term_years'
  if (
    values.variable_term_years &&
    values.payment_type &&
    payment_types_and_variables.find((a) => a.title === values.payment_type) &&
    payment_types_and_variables
      .find((a) => a.title === values.payment_type)
      .variables.some((field) => field.name === 'term_years')
  ) {
    var payments_per_year =
      values.variable_payment_frequency && periodsPerYear[values.variable_payment_frequency]
        ? periodsPerYear[values.variable_payment_frequency]
        : periodsPerYear['monthly']

    try {
      configuration_object.term = payments_per_year * parseFloat(values.variable_term_years)
    } catch (error) {
      console.warn('Error converting term_years to term')
    }
  }
  // End Hack for term ///////////////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  // Start Hack: Convert optional floats to 0 when null is not permitted
  // @TODO: Ideally this would not be required but some leases may exist with empty dealer_fee
  // and now that this field is disbled it would be impossible to manually populate it to
  // allow saving the form
  if (configuration_object.hasOwnProperty('dealer_fee')) {
    if (configuration_object.dealer_fee === null) {
      configuration_object.dealer_fee = 0
    }
  }

  // handle payment factors
  if (values.variable_payment_factors_enabled) {
    configuration_object.payment_factors_enabled = true
    configuration_object.payment_factors = values.variable_payment_factors
    configuration_object.payment_factors_use_gross_cost = values.variable_payment_factors_use_gross_cost
  } else {
    configuration_object.payment_factors_enabled = false
    configuration_object.payment_factors = []
    configuration_object.payment_factors_use_gross_cost = false
  }

  // End Hack for converting null floats to 0 ///////////////////////////////////////////////////////////////////////////////
  values.configuration_json = JSON.stringify(configuration_object)

  // track saves for non-integrated finance (eg not cash) payment options
  if (values?.payment_type !== 'cash' && !values.variable_integration) {
    logAmplitudeEvent('non_integrated_finance_product_saved', {
      title: values?.title,
      payment_type: values?.payment_type,
      auto_apply_enabled: values?.auto_apply_enabled,
      is_archived: values?.is_archived,
      dealer_fee_percentage: values?.variable_dealer_fee_percentage,
      interest_rate: values?.variable_interest_rate,
      term_years: values?.variable_term_years,
      reversion_rate: values?.reversion_rate,
      start_of_reversion_period: values?.start_of_reversion_period,
      is_new: values?.id ? 'no' : 'yes',
      product_fee_fixed: values?.product_fee_fixed,
    })
  }
  return values
}

const defaultValueEdit = (record) => {
  var defaultValuesObject = Object.assign(
    {
      auto_apply_enabled: false,
      is_archived: false,
      priority: 1,
      variable_deposit_percentage: 0,
      variable_collect_signature: true, // Required because BooleanInputs don't seem to be initialized until interaction
      variable_advance_settings_enabled: false,
      variable_payment_milestones_enabled: false,
      variable_show_payment_milestones_table: true,
      variable_payment_frequency: 'monthly',
      use_highest_standard_system_price: true,
      payment_milestones: defaultMilestones,
      auto_discount: true,
      custom_data: {},
    },
    record
  )

  if (!record) {
    return defaultValuesObject
  }

  var configuration_object = record && record.configuration_json ? JSON.parse(record.configuration_json) : {}
  var obj = defaultValuesObject

  var customData = record && record.custom_data ? record.custom_data : {}
  customData.extra_quote_acceptance_content = customData?.extra_quote_acceptance_content || []
  obj.custom_data = customData

  for (var key in configuration_object) {
    obj['variable_' + key] = configuration_object[key]
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  // Start Hack: Inject term into json by multiplying term_years by payments per year for selected payment frequency
  // very nasty here, basically we only want to use the hack when 'term_years' field is available
  if (
    obj.variable_term &&
    obj.payment_type &&
    payment_types_and_variables.find((a) => a.title === obj.payment_type) &&
    payment_types_and_variables
      .find((a) => a.title === obj.payment_type)
      .variables.some((field) => field.name === 'term_years')
  ) {
    var payments_per_year =
      obj.variable_payment_frequency && periodsPerYear[obj.variable_payment_frequency]
        ? periodsPerYear[obj.variable_payment_frequency]
        : periodsPerYear['monthly']

    try {
      obj.variable_term_years = Math.round(100 * (parseFloat(obj.variable_term) / payments_per_year)) / 100
    } catch (error) {
      console.warn('Error converting term_years to term')
    }
  }

  // End Hack for term ///////////////////////////////////////////////////////////////////////////////

  //Inject other values that are not saved in the data, but can be derived from the data
  obj.quotation_items = obj.variable_quotation_items && obj.variable_quotation_items.split(',')
  obj.quotation_labels = obj.variable_quotation_labels && JSON.parse(obj.variable_quotation_labels)
  obj.payment_milestones = obj.variable_payment_milestones || obj.payment_milestones

  toggle_inputs_and_extra_fields.forEach((toggle_input) => {
    //enable toggle
    if (toggle_input.extraFieldsDefaultValues) {
      //different from default values
      obj[toggle_input.key] = toggle_input.extraFields.some(
        (field) => obj[field] !== undefined && obj[field] !== toggle_input.extraFieldsDefaultValues[field]
      )
    } else {
      //at least one of the children has a value
      obj[toggle_input.key] = toggle_input.extraFields.some((field) => obj[field])
    }
  })
  return obj
}

const CustomToolbar = (props) => {
  const translate = useTranslate()
  return (
    <Toolbar {...props}>
      <Link style={{ textDecoration: 'none' }} to={`/${props.resource}`}>
        <Button
          startIcon={<AlertError />}
          variant="contained"
          color="default"
          style={{ margin: '10px 0 ', position: 'relative' }}
        >
          <span>{translate('Cancel')}</span>
        </Button>
      </Link>
      <SaveButton
        label="Save"
        redirect={false}
        disabled={props.disabled}
        submitOnEnter={true}
        raised={true}
        funcBeforeSubmit={(value) => {
          const result = window.confirm(
            translate(
              'Modifying this payment option will impact existing projects that use this payment option. Changes to an individual project will take effect when you make an updates to the project. Are you sure you want to continue?'
            )
          )
          if (result) {
            return value
          } else {
            //To do
            // throw new SubmissionError({
            //   accept_term_and_condition: 'Please accept agreement before submit the form',
            //   _error: 'Agreement failed!',
            // })
          }
        }}
      />
    </Toolbar>
  )
}

const _PaymentOptionEdit = ({ accessRights, ...props }) => {
  const translate = useTranslate()
  const isStaff = useSelector(authSelectors.getIsStaff)

  return useEditShareable({
    accessRights: accessRights,
    editProps: props,
    render: ({ access }) => (
      <SimpleForm
        validate={(values) => validatePaymentOptionData(values, translate, isStaff)}
        defaultValue={defaultValueEdit}
        formatSubmitValues={(vals) => formatSubmitValues(vals, isStaff)}
        toolbar={<CustomToolbar {...props} />}
        disabled={!access.allowEdit}
      >
        <PaymentOptionFormContent
          isEdit={true}
          enableCheckout={props.enableCheckout}
          layout={props.layout}
          currencySymbol={props.currencySymbol}
          resource={props.resource}
          {...props}
        />
      </SimpleForm>
    ),
  }).editPage
}
export const PaymentOptionEdit = compose(withMediaQuery, connect(mapStateToProps, {}))(_PaymentOptionEdit)

const _PaymentOptionCreate = (props) => {
  const translate = useTranslate()
  const isStaff = useSelector(authSelectors.getIsStaff)
  return (
    <Create {...props}>
      <SimpleForm
        formatSubmitValues={(vals) => formatSubmitValues(vals, isStaff)}
        validate={(values) => validatePaymentOptionData(values, translate, isStaff)}
        defaultValue={defaultValueEdit}
      >
        <PaymentOptionFormContent
          isEdit={false}
          enableCheckout={props.enableCheckout}
          layout={props.layout}
          currencySymbol={props.currencySymbol}
          resource={props.resource}
          {...props}
        />
      </SimpleForm>
    </Create>
  )
}

const useStyles = makeStyles({
  paper: {
    width: '80%',
    maxWidth: 900,
  },
})

const _ExtraFieldsWrapper = (props) => {
  const form = useForm()
  const formState = useFormState()
  const translate = useTranslate()
  const classes = useStyles()
  const paymentType = formState.values['payment_type']
  if (!paymentType) return null
  return (
    <>
      <CustomField
        component={QuotationConfiguration}
        translate={translate}
        formControlLabelStyle={styles.formControlLabelStyle}
        source={'quotation_items'}
        name={'quotation_items'}
        updateField={form.mutators.updateField}
        paymentType={paymentType}
        dialogClasses={classes}
        {...props}
      />
    </>
  )
}

export const ExtraFieldsWrapper = withTheme(withMediaQuery(_ExtraFieldsWrapper))

export const PaymentOptionCreate = compose(
  withMediaQuery,
  connect(mapStateToProps, {
    push: push,
    showNotification: showNotificationAction,
  })
)(_PaymentOptionCreate)

import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { Button, IconButton } from 'opensolar-ui'
import { InvoicePaymentDetails } from 'pages/cashFlow/invoices/types'
import { PaymentRequestType } from 'pages/cashFlow/types'
import { useTranslate } from 'ra-core'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type PropTypes = {
  closeDialog: () => void
  paymentRequest: PaymentRequestType | InvoicePaymentDetails
  disableButtons?: boolean
  cancelInvoice: () => void
}

const useStyles = makeOpenSolarStyles((theme) => ({
  titleWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.greyLight1,
  },
  content: {
    lineHeight: 1.5,
  },
  closeButton: {
    color: theme.greyMid1,
  },
  buttonsRow: {
    display: 'flex',
    padding: '16px 24px',
    justifyContent: 'end',
    gap: '10px',
    alignItems: 'center',
    width: '100%',
  },
  progressSpinner: {
    margin: '4px 20px 0',
    color: theme.greyDark2,
  },
}))

const CancelInvoiceConfirmation: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <Dialog open={true}>
      <DialogTitle>
        <div className={classes.titleWrapper}>
          <div>{translate('Confirm Invoice Cancellation')}</div>
          <div>
            <IconButton onClick={props.closeDialog}>
              <CloseOutlined className={classes.closeButton} />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <p className={classes.content}>
          {translate(
            'Please confirm you would like to cancel %{invoice} on this project. Note that cancelling an invoice that has already been sent to the customer will trigger a cancellation notice to the customer.',
            { invoice: props.paymentRequest.invoice_number }
          )}
        </p>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttonsRow}>
          <div>
            <Button onClick={props.closeDialog} color="primary" variant="outlined" disabled={props.disableButtons}>
              {translate('Cancel')}
            </Button>
          </div>
          <div>
            <Button color="primary" variant="contained" onClick={props.cancelInvoice}>
              {props.disableButtons ? (
                <CircularProgress size={20} className={classes.progressSpinner} />
              ) : (
                <span> {translate('Confirm')} </span>
              )}
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  )
}
export default CancelInvoiceConfirmation

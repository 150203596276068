import type { FilterType, GetDefaultFilterValuePropsType } from 'types/selectComponent'

type WarrantyRangeType = {
  year: number
  value: string
  title: string
}

const WARRANTY_RANGES: WarrantyRangeType[] = [
  {
    year: 12,
    value: '12',
    title: '12 year',
  },
  {
    year: 25,
    value: '25',
    title: '25 year',
  },
  {
    year: 30,
    value: '30',
    title: '30 year',
  },
  {
    year: 40,
    value: '40',
    title: '40 year',
  },
]

const getMatchedRange = (target: number | string, ranges: WarrantyRangeType[]) => {
  for (let i = 0; i < ranges.length; i++) {
    const { year, value } = ranges[i]
    if (target === year || target === value) {
      return value
    }
  }
}

export const productWarrantyFilter: FilterType = {
  key: 'product_warranty',
  label: 'Warranty',
  options: WARRANTY_RANGES.map((range: WarrantyRangeType) => ({
    title: range.title,
    value: range.value,
  })),
  getDefaultValue({ specsData }: GetDefaultFilterValuePropsType) {
    if (specsData.product_warranty == null) {
      return
    }
    return getMatchedRange(specsData.product_warranty, WARRANTY_RANGES)
  },
}

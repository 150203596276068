import PhoenixHeaderBanner from 'myenergy/elements/phoenix/PhoenixHeaderBanner'
import { getIsPhoenixV2 } from 'myenergy/elements/phoenix/util'
import ProjectWarningBox from 'projectSections/sections/common/ProjectWarningBox'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import LogoOrTitle from '../mainContent/components/LogoOrTitle'

const styles = {
  mobile: {
    display: 'flex',
    justifyContent: 'center',
    height: 'inherit',
  },
  standard: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 'inherit',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '100%',
  },
  warning: {
    marginLeft: 20,
  },
}

export const Header = (props) => {
  const [showPhoenix, setShowPhoenix] = useState(false)
  const { proposalData, isMobile } = props
  const coBrandLogo = proposalData?.proposalTemplateSettings.co_branding_logo_public_url
  const overrideLogo = proposalData?.proposalTemplateSettings.override_logo_public_url
  const style = isMobile ? styles.mobile : styles.standard
  const viewAsCustomer = useSelector((state) => state.viewAsCustomer)

  useEffect(() => {
    if (props.phoenixReference) {
      const isPhoenixV2 = getIsPhoenixV2(props.phoenixReference)
      if (showPhoenix && isPhoenixV2) setShowPhoenix(false)
      else if (!showPhoenix && !isPhoenixV2) setShowPhoenix(true)
    }
  }, [props.phoenixReference])

  return (
    <div style={styles.wrapper}>
      <div style={style}>
        <LogoOrTitle
          size={isMobile ? 'medium' : 'large'}
          org={proposalData.org}
          overrideLogo={overrideLogo}
          allowUploadButton={isMobile ? false : true}
        />
        {!viewAsCustomer && (
          <div style={styles.warning}>
            <ProjectWarningBox categories={['proposal-header']} />
          </div>
        )}
        {coBrandLogo && !isMobile && (
          <div style={{ lineHeight: '100px', marginLeft: 20 }}>
            <img
              src={coBrandLogo}
              id="co-logo-image"
              alt="co-Logo"
              style={{ maxWidth: 240, maxHeight: 50, margin: 'auto 0px', verticalAlign: 'middle' }}
            />
          </div>
        )}
      </div>
      {showPhoenix && (
        <PhoenixHeaderBanner
          orgLogo={proposalData?.org?.logo_public_url}
          overrideLogo={overrideLogo}
          coBrandLogo={coBrandLogo}
        />
      )}
    </div>
  )
}

// export const AboutUsLink = ({ name, showDialog }) => (
//   <a
//     id="MyEnergyHeaderLogoLink"
//     href="#"
//     onClick={e => {
//       e.preventDefault()
//       showDialog('AboutUs')
//     }}
//   >
//     About {name}
//   </a>
// )

// export const LetterLink = ({ showDialog }) => (
//   <a
//     id="MyEnergyHeaderLetterLink"
//     href="#"
//     onClick={e => {
//       e.preventDefault()
//       showDialog('Letter')
//     }}
//   >
//     Introduction
//   </a>
// )

// export const HeaderLinks = ({ proposalData, showDialog, id }) => (
//   <div style={{ marginBottom: '20px' }} id={id}>
//     {letterIsAvailable(proposalData) && <LetterLink showDialog={showDialog} />}
//     {letterIsAvailable(proposalData) && <WhiteSpace />}
//     <AboutUsLink name={proposalData.org.name} showDialog={showDialog} />
//   </div>
// )

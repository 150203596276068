import React from 'react'
import type { FilterComponentNode, FilterComponentRendererType, FilterGenericPropsType } from '../type'
import DistributorBrandFilter, { DistributorBrandOptionsType } from './DistributorBrandFilter'

export const DISTRIBUTOR_BRAND_FILTER_KEY = 'manufacturer_names'

const maybeCreateDistributorBrandFilterNode = ({
  RendererComponent,
  availableBrands,
}: {
  RendererComponent: FilterComponentRendererType<DistributorBrandOptionsType>
  availableBrands: string[]
}): FilterComponentNode | undefined => {
  const options = availableBrands.sort().map((brand) => ({ id: brand, value: encodeURIComponent(brand), title: brand }))

  const defaultOption = {
    title: 'All',
    value: '',
    id: 'default',
  }
  options.unshift(defaultOption)

  const FilterComponent = (props: FilterGenericPropsType) => {
    return (
      <DistributorBrandFilter
        disabled={availableBrands.length === 0}
        options={options}
        filterKey={DISTRIBUTOR_BRAND_FILTER_KEY}
        RendererComponent={RendererComponent}
        {...props}
      />
    )
  }
  return {
    key: DISTRIBUTOR_BRAND_FILTER_KEY,
    component: FilterComponent,
  }
}

export default maybeCreateDistributorBrandFilterNode

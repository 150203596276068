import { useTranslate } from 'react-admin'

const useTranslateWithVariable = () => {
  const translate = useTranslate()
  return (phrase, options) => {
    // If phrase contains a placeholder, it will not be injected when the translation is not found (when the en
    // key is the same as the translation because polyglot will never perform an interpolation when the key is used
    // as a fallback. So we insert the key as the actual fallback phrase (by populating it in options._)
    if (!options?._ && phrase && phrase.indexOf('%{') !== -1) {
      options._ = phrase
    }
    return translate(phrase, options)
  }
}

export default useTranslateWithVariable

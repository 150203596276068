// @ts-nocheck
import { Dialog, DialogContent, DialogTitle, useMediaQuery } from '@material-ui/core'
import {
  CreditDecisionDialogLoanType,
  DecisionDisplayPropsType,
  closeCreditDecisionDialog,
  getAvailableActions,
} from 'ducks/myEnergy'
import React, { useEffect } from 'react'
import { useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import DividendDecisionExplanation from './dividend/DividendDecisionExplanation'
import LoanpalDecisionExplanation from './loanpal/LoanpalDecisionExplanation'
import MosaicDecisionExplanation from './mosaic/MosaicDecisionExplanation'
import GenericDecisionExplanation from './sungage/GenericDecisionExplanation'
import SungageDecisionExplanation from './sungage/SungageDecisionExplanation'

type PropTypes = {
  orgId: number
  projectId: number
}

const CreditDecisionDialog: React.FunctionComponent<PropTypes> = (props) => {
  const dispatch = useDispatch()
  const translate = useTranslate()
  const isMobile = useMediaQuery('(max-width:800px)')

  const closeIt = () => dispatch(closeCreditDecisionDialog())
  const loanType: CreditDecisionDialogLoanType = useSelector((state: any) => state.myEnergy.loanType)
  const displayProps: DecisionDisplayPropsType = useSelector((state: any) => state.myEnergy.decisionDisplayProps)
  //   once a decision is given wait a bit then refresh the available actions
  useEffect(() => {
    if (displayProps?.decision) {
      setTimeout(() => {
        dispatch(getAvailableActions(props.orgId, props.projectId))
      }, 500)
    }
  }, [displayProps?.decision])

  const renderContent = () => {
    if (loanType === 'loanpal') {
      return <LoanpalDecisionExplanation {...displayProps} onClose={closeIt} />
    } else if (loanType === 'mosaic') {
      return <MosaicDecisionExplanation {...displayProps} onClose={closeIt} />
    } else if (loanType === 'sungage') {
      return <SungageDecisionExplanation {...displayProps} onClose={closeIt} />
    } else if (loanType === 'dividend') {
      return <DividendDecisionExplanation {...displayProps} onClose={closeIt} />
    } else if (loanType === 'generic') {
      return (
        <GenericDecisionExplanation decision={displayProps.decision} stips={displayProps.stips} onClose={closeIt} />
      )
    }
  }

  const approvals = [
    'APPROVED',
    'CONDITIONAL',
    'Approved',
    'ApprovedWithStipulations',
    'APPROVED_COUNTER_OFFER',
    'Conditionally approved',
  ]
  const dialogTitle = approvals.includes(displayProps?.decision)
    ? translate("Congratulations! You're Approved!")
    : translate('Your Financing Application Decision')

  return (
    <Dialog open={true} onClose={closeIt}>
      <DialogTitle>
        <h1 style={{ fontSize: isMobile ? '14px' : undefined, textAlign: 'center' }}>{dialogTitle}</h1>
      </DialogTitle>
      <DialogContent>{renderContent()}</DialogContent>
    </Dialog>
  )
}
export default CreditDecisionDialog

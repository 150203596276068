import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  adWrapper: {
    marginTop: 10,
    marginBottom: 20,
  },
}))

const HardwareAdBannerContainer = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.adWrapper}> {children} </div>
}

export default HardwareAdBannerContainer

// @ts-nocheck
import { Card } from '@material-ui/core'
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined'
import { Button } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { financingIntegrations } from 'resources/paymentOptions/financialIntegrations'
import { getOrgCountryCodeFromState } from 'util/org'
import useTranslateParse from '../../../util/useTranslateParse'
import { ToolTipPropsType, TourConfigType } from '../types'
import DefaultTooltip from './DefaultToolTip'

const PaymentOptionTabTooltip = (props: ToolTipPropsType) => {
  const { handleNext, handlePrev, handleCancel } = props
  const translate = useTranslate()
  const translateParse = useTranslateParse()

  return (
    <DefaultTooltip
      title={translate('View Payment Option')}
      // onClose={handleCancel}
      icon={PaymentOutlinedIcon}
      content={
        <div style={{ maxWidth: 350 }}>
          {translateParse(
            'You can present multiple payment options - loans, PPAs, cash - to your customers. Click on the <strong>Payment Options<strong> button to see how.',
            { strong: (label: string) => <strong>{label}</strong> }
          )}
        </div>
      }
      arrowPlacement={'left'}
      actions={
        <>
          <Button
            onClick={() => handlePrev && handlePrev()}
            onTouchStart={(e) => {
              e.stopPropagation()
              if (handlePrev) {
                handlePrev()
              }
            }}
          >
            <span>{translate('Back')}</span>
          </Button>
          <Button disabled={true}>
            <span>{translate('Next')}</span>
          </Button>
        </>
      }
    />
  )
}

const PaymentOptionDetailTooltip = (props: ToolTipPropsType) => {
  const { handleNext, handlePrev, handleCancel } = props
  const translate = useTranslate()
  const translateParse = useTranslateParse()
  const country_iso2 = useSelector((state: any) => getOrgCountryCodeFromState(state))
  const financePartnersLogo = financingIntegrations
    .filter((data) => data?.config?.support_countries?.includes(country_iso2))
    .map((data) => data?.config?.logo || data?.config?.logo_small)
  return (
    <DefaultTooltip
      title={translate('View Payment Option')}
      // onClose={handleCancel}
      icon={PaymentOutlinedIcon}
      content={
        <div style={{ maxWidth: 400 }}>
          <div>
            {translate(
              'When you offer a loan from one of the leading solar finance providers, your customer will be able to apply for the loan and receive a decision without ever leaving their online proposal.'
            )}
          </div>
          {(country_iso2 === 'AU' || country_iso2 === 'US' || country_iso2 === 'NZ') && (
            <div style={{ marginTop: 10 }}>
              {translate(
                "At the end of this tour, we'll show you how to turn on the financing integrations for any of our finance partners:"
              )}
            </div>
          )}
          <div style={{ marginTop: 10 }}>
            {financePartnersLogo.map((logo) => (
              <Card style={{ display: 'flex', justifyContent: 'center', margin: 5, padding: '5px 0' }}>
                <img
                  key={logo}
                  src={`${window.PUBLIC_URL}/images/${logo}`}
                  style={{ margin: logo.includes('smart') ? '-10px 10px' : '0px 10px' }}
                  height={logo.includes('smart') ? '50px' : '35px'}
                />
              </Card>
            ))}
          </div>
          <div style={{ marginTop: 10 }}>
            {translateParse(
              "For this project, you can use the default payment option, or, if you'd like to add another payment option, you can click <b>Override Default Payment options<b>. (You'll be able to add additional payment options to your list after this tour.)",
              { b: (label: string) => <b>{label}</b> }
            )}
          </div>
          <div style={{ marginTop: 10 }}>
            {translateParse('Click <b>Next<b> to save this project.', { b: (label: string) => <b>{label}</b> })}
          </div>
        </div>
      }
      actions={
        <>
          <Button
            onClick={() => handlePrev && handlePrev()}
            onTouchStart={(e) => {
              e.stopPropagation()
              if (handlePrev) {
                handlePrev()
              }
            }}
          >
            <span>{translate('Back')}</span>
          </Button>
          <Button
            onClick={() => handleNext && handleNext()}
            onTouchStart={(e) => {
              e.stopPropagation()
              if (handleNext) {
                handleNext()
              }
            }}
          >
            <span>{translate('Next')}</span>
          </Button>
        </>
      }
      arrowPlacement={'left'}
    />
  )
}

export const ClickPaymentOptionTab: TourConfigType = {
  spotlights: [{ targetId: 'system-panel-payment-option-tab' }],
  tooltips: [{ targetId: 'system-panel-payment-option-tab', placement: 'right', component: PaymentOptionTabTooltip }],
}

export const SetPaymentOption: TourConfigType = {
  spotlights: [{ targetId: 'payment-option-checkbox-container', keepObserve: true }],
  tooltips: [
    { targetId: 'payment-option-checkbox-container', placement: 'right', component: PaymentOptionDetailTooltip },
  ],
}

import { Divider } from '@material-ui/core'
import TableBody from '@material-ui/core/TableBody'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orgSelectors } from 'ducks/orgs'
import moment from 'moment'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import type { ComponentTypes } from 'types/selectComponent'
import type { ForecastDetailDialogController } from '../detail/useForecastDetailDialogController'
import type { ForecastedProjectInventory } from '../type'
import ForecastTableRow, { HistoricalProjectRow } from './ForecastTableRow'

const ForecastTableContent = ({
  forecastDetailDialogController,
  forecastedProjectInventoryData,
  refreshInventoryDashboard,
}: {
  forecastDetailDialogController: ForecastDetailDialogController
  forecastedProjectInventoryData: ForecastedProjectInventory[]
  refreshInventoryDashboard(): void
}) => {
  const checkIsPastDate = (date) => {
    return moment(date).startOf('day').isBefore(moment().startOf('day'))
  }

  const historicalProjectInventory = forecastedProjectInventoryData.filter(
    (forecastedProjectInventory) =>
      forecastedProjectInventory.project.confirm_needed === true ||
      checkIsPastDate(forecastedProjectInventory.project.pickup_date)
  )
  const projectInventory = forecastedProjectInventoryData.filter(
    (forecastedProjectInventory) =>
      forecastedProjectInventory.project.confirm_needed === false &&
      !checkIsPastDate(forecastedProjectInventory.project.pickup_date)
  )
  const orgWorkflows = useSelector(orgSelectors.getWorkflows)

  const handleStatusClicked = (forecastedInventory: ForecastedProjectInventory, componentType: ComponentTypes) => {
    const item = forecastedInventory.items_required_allocated.find((item) => item.component_type === componentType)
    forecastDetailDialogController.onOpen()
    forecastDetailDialogController.setFilter({
      projectId: forecastedInventory.project_id,
      componentType: item?.component_type,
      code: item?.code,
      forecastEndDate: forecastedInventory.project.pickup_date,
    })

    logAmplitudeEvent('generic_button_clicked', {
      source: item?.component_type,
      context: 'inventory_forecast_bubble',
    })
  }

  const getOptions = (data: ForecastedProjectInventory) => {
    const workflow = orgWorkflows?.find((x) => x.id === data.project.workflow?.workflow_id)
    const options = workflow?.workflow_stages?.filter(
      (x) => !x.is_archived || x.id === data.project.workflow?.active_stage_id
    )

    return options
  }

  return (
    <TableBody>
      {historicalProjectInventory.map((forecastedProjectInventory: ForecastedProjectInventory) => {
        const stageOptions = getOptions(forecastedProjectInventory)
        return (
          <>
            <HistoricalProjectRow
              key={forecastedProjectInventory.project_id}
              forecastedProjectInventory={forecastedProjectInventory}
              refreshInventoryDashboard={refreshInventoryDashboard}
              stageOptions={stageOptions}
            />
            <Divider light />
          </>
        )
      })}
      {projectInventory.map((forecastedProjectInventory: ForecastedProjectInventory) => {
        const stageOptions = getOptions(forecastedProjectInventory)
        return (
          <>
            <ForecastTableRow
              key={forecastedProjectInventory.project_id}
              forecastedProjectInventory={forecastedProjectInventory}
              refreshInventoryDashboard={refreshInventoryDashboard}
              onStatusClicked={(componentType) => handleStatusClicked(forecastedProjectInventory, componentType)}
              stageOptions={stageOptions}
            />
            <Divider light />
          </>
        )
      })}
    </TableBody>
  )
}

export default memo(ForecastTableContent)

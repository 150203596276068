// @ts-nocheck
import { makeStyles } from '@material-ui/core'
import React, { FC } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Actions, Address, getActivity, getDate } from './AssignedActivitiesTable'

type PropsType = {
  records: any
  loading: Boolean
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    borderTop: '1px solid #e7e7e7',
    padding: 5,
    display: 'flex',
    alignItems: 'center',
  },
  firstRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  secondRow: {
    marginTop: 15,
  },
  actionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}))

const AssignedActivitiesTableSmall: FC<PropsType> = ({ records, loading }) => {
  const classes = useStyles()
  return loading ? (
    <Skeleton height="50px" />
  ) : (
    records &&
      Object.values(records).map((row: any) => (
        <div className={classes.wrapper} key={row.id}>
          <div>
            <div className={classes.firstRow}>
              <span>Date: {getDate(row)}</span>
              <span>{row.project && <Address project={row.project} record={row.project_data} />}</span>
            </div>
            <div className={classes.secondRow}>Detail: {getActivity(row)}</div>
          </div>
          <div className={classes.actionWrapper}>{<Actions record={row} />}</div>
        </div>
      ))
  )
}

export default AssignedActivitiesTableSmall

import { useActionsListener } from 'ducks/action_listener'

export function promsifyReduxSaga<T, R>(action: (args: T) => R, successActions: string[], failureActions: string[]) {
  return async (args: T): Promise<R> => {
    return new Promise((resolve, reject) => {
      const ret = action(args)
      useActionsListener((payload: { type?: string }) => {
        if (!payload.type) return
        if (successActions.includes(payload.type)) {
          resolve(ret)
          return true
        }
        if (failureActions.includes(payload.type)) {
          reject(ret)
          return true
        }
      })
    })
  }
}

import { orgSelectors } from 'ducks/orgs'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ProOnlyFinanceCTAType } from 'types/orgs'

export const useGetFinanceCTAs = (thisProjectIsResidential: boolean): ProOnlyFinanceCTAType[] => {
  const availableFinanceIntegrations = useSelector(orgSelectors.getAvailableFinanceIntegrations)

  let ctas: ProOnlyFinanceCTAType[] = useMemo(() => {
    if (!availableFinanceIntegrations || !availableFinanceIntegrations?.length) return []
    let tempCtas: ProOnlyFinanceCTAType[] = []
    availableFinanceIntegrations.forEach((integration) => {
      integration?.pro_only_ctas?.forEach((thisCta) => {
        if (!thisProjectIsResidential && thisCta.include_for_all_commercial) tempCtas.push(thisCta)
        else if (thisCta.include_for_all_residential) tempCtas.push(thisCta)
      })
    })
    return tempCtas
  }, [availableFinanceIntegrations])

  return ctas
}

import React, { useCallback } from 'react'
import type { FilterComponentRendererType, FilterGenericPropsType, FilterKeysType, OptionGenericType } from '../type'
import type { WarrantyRangeType } from './WarrantyFilterNodeFactory'

export type FilterOptionsType = OptionGenericType<string>[]

type FilterProps = FilterGenericPropsType & {
  RendererComponent: FilterComponentRendererType<FilterOptionsType>
  filterKey: FilterKeysType
  warrantyOptions: WarrantyRangeType[]
}

const mapWarrantyOptionsToSelectOptions = (warrantyOptions: WarrantyRangeType[]): FilterOptionsType => {
  const defaultOption = {
    title: 'All',
    value: '',
    id: 'default',
  }
  const selectOptions: FilterOptionsType = [defaultOption]

  warrantyOptions.forEach((option) => {
    selectOptions.push({ title: option.title, value: option.value, id: option.value })
  })

  return selectOptions
}

const WarrantyFilter = ({
  filterKey,
  warrantyOptions,
  modifiableFilterValues,
  persistentFilterValues,
  allFilterValues,
  setModifiableFilters,
  RendererComponent,
}: FilterProps) => {
  const onSelect = useCallback(
    (value) => {
      setModifiableFilters({ ...modifiableFilterValues, [filterKey]: value })
    },
    [modifiableFilterValues]
  )

  return (
    <RendererComponent
      id={filterKey}
      defaultValue={''}
      label={'Warranty'}
      options={mapWarrantyOptionsToSelectOptions(warrantyOptions)}
      disabled={false}
      onSelect={onSelect}
      value={allFilterValues[filterKey]}
    />
  )
}

export default WarrantyFilter

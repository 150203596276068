import { FormControlLabel, RadioGroup } from '@material-ui/core'
import { permissionsSelectors } from 'ducks/permissions'
import { Grid, Radio } from 'opensolar-ui'
import { getSupplierConfigByFilterKey } from 'pages/ordering/configs'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { ComponentTypesV2 } from 'types/selectComponent'
import { DistributorDataTypeV2, HardwareComponentDataType } from '../types'
import PriceBlock from './PriceBlock'
import QuantityBlock from './QuantityBlock'

const useStyles = makeOpenSolarStyles({
  container: {
    width: '100%',
  },
})

interface DistributorDetailsProps {
  code: string
  componentData?: HardwareComponentDataType
  componentType: ComponentTypesV2
  distributors: [DistributorDataTypeV2, DistributorDataTypeV2, ...DistributorDataTypeV2[]]
}

const DistributorDetails: React.FC<DistributorDetailsProps> = ({
  code,
  componentData,
  componentType,
  distributors,
}) => {
  const [selectedDistributor, setSelectedDistributor] = useState(distributors[0])

  const canViewComponentPricing = useSelector(permissionsSelectors.getPermissionByKey('component_pricing_costing'))

  const classes = useStyles()

  const handleChange = ({ target: { value } }) => {
    setSelectedDistributor(distributors.find(({ distributor }) => distributor === value)!)
  }

  return (
    <Grid container justify="flex-end" className={classes.container} xs={12}>
      {canViewComponentPricing && (
        <>
          <Grid item xs={1}>
            <QuantityBlock distributors={[selectedDistributor]} />
          </Grid>
          <Grid item xs={1}>
            <PriceBlock
              code={code}
              componentData={componentData}
              componentType={componentType}
              distributors={[selectedDistributor]}
            />
          </Grid>
        </>
      )}
      <Grid item xs={2}>
        <RadioGroup
          aria-label="distributor"
          name="distributor"
          onChange={handleChange}
          value={selectedDistributor.distributor}
        >
          {distributors.map(({ distributor }) => {
            const supplierConfig = getSupplierConfigByFilterKey(distributor)
            return (
              <FormControlLabel
                control={<Radio checked={distributor === selectedDistributor.distributor} />}
                label={supplierConfig?.name}
                value={distributor}
              />
            )
          })}
        </RadioGroup>
      </Grid>
    </Grid>
  )
}

export default DistributorDetails

import { FormControl, TextField } from '@material-ui/core'
import { CloseOutlined, TuneOutlined } from '@material-ui/icons'
import { API_URL } from 'Designer/integrations/ironRidge/constants'
import { authSelectors } from 'ducks/auth'
import { permissionsSelectors } from 'ducks/permissions'
import Button from 'elements/button/Button'
import { updateSystemHardware } from 'elements/hardwareSelector/updateSystemHardware'
import { StyledDialog, StyledDialogContent } from 'layout/StyledDialog'
import { useTranslate } from 'ra-core'
import { SelectInput } from 'ra-ui-materialui'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { validateModuleDataLite } from 'resources/activeComponents/modules/validation'
import { moduleTechnologyValues } from 'resources/components/modules/constants'
import restClient from 'restClient'
import { StudioSystemType } from 'types/global'
import { useStyles } from './panels/styles'

interface PropTypes {
  component: ModuleType
  isOpen: boolean
  close: Function
  system: StudioSystemType
}

const PanelInfoDialog: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()

  const component = props.component
  const [isEditing, setIsEditing] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [panel, setPanel] = useState({ ...component })
  const [isCustom, setIsCustom] = useState(component.module_id === null)
  const [errors, setErrors] = useState({})

  const { allowCreate, allowEdit } = useSelector(permissionsSelectors.getProjectPermissionByKey('components'))
  const org_id = useSelector(authSelectors.getOrgId)
  const restClientInstance = restClient(API_URL)

  useEffect(() => {
    setIsCustom(component.module_id === null)
    setPanel({ ...component })
  }, [component])

  const ErrorItem = ({ fieldKey }) => {
    return (
      <>
        {fieldKey in errors ? (
          <div className={classes.panelRow}>
            <p className={classes.panelLabel}></p>
            <p className={classes.error}>{errors[fieldKey]}</p>
          </div>
        ) : (
          ''
        )}
      </>
    )
  }

  const onChange = (e) => {
    const { name, value } = e.target
    setPanel({
      ...panel,
      [name]: value,
    })
  }

  const onClose = () => {
    setErrors({})
    setPanel({ ...component })
    setShowForm(false)
    props.close()
  }

  const panelCopyFields = [
    'manufacturer_name',
    'code',
    'technology',
    'height',
    'width',
    'kw_stc',
    'noct',
    'temp_coefficient_vpmax',
    'max_power_voltage',
    'voc',
    'temp_coefficient_voc',
  ]

  const convertNumberFields = ['height', 'width', 'kw_stc']
  const convertNumbers = (toEdit) => {
    for (var i in convertNumberFields) {
      var prop = convertNumberFields[i]
      panel[prop] = toEdit ? panel[prop] * 1000 : panel[prop] / 1000
    }
  }

  const formatSubmitData = () => {
    var data = {}
    for (var i in panelCopyFields) {
      const key = panelCopyFields[i]
      if (typeof panel[key] === 'number') {
        data[key] = parseFloat(panel[key])
      } else {
        data[key] = panel[key]
      }
    }
    const errors = validateModuleDataLite(data, translate)
    if (Object.keys(errors).length) {
      setErrors(errors)
      data = 400
    } else {
      const dataString = JSON.stringify(data)
      data['data'] = dataString
    }
    return data
  }

  const savePanelForm = (orgId: number | undefined, data: object, isEdit: boolean = false) => {
    var type = isEdit ? 'CUSTOM_PUT' : 'CUSTOM_POST'
    var url = `orgs/${org_id}/component_module_activations` + (isEdit ? `/${panel.id}/` : '/')
    return restClientInstance(type, 'custom', {
      url: url,
      data: data,
    })
      .then((res) => {
        var callback = function () {
          updateSystemHardware('module', res.data.id, [props.system.uuid])
        }
        window.AccountHelper.loadComponentSpecs(callback)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const switchDialogMode = (mode) => {
    setErrors({})
    if (mode === 'info') {
      var data
      convertNumbers(false)
      //check for edited fields
      var editedFields: string[] = []
      for (var prop in panel) {
        if (Object.prototype.hasOwnProperty.call(panel, prop) && panelCopyFields.includes(prop)) {
          if (panel[prop] !== component[prop]) {
            data = formatSubmitData()
            if (data !== 400) {
              data = formatSubmitData()
              savePanelForm(org_id, data, isEditing)
            }
            break
          }
        }
      }
      if (data !== 400) {
        props.close()
        setShowForm(false)
      }
    } else {
      convertNumbers(true)
      if (mode === 'copy') {
        if (!panel.code.includes('Custom')) {
          panel.code = 'Custom ' + component.code
        }
        setIsEditing(false)
      } else {
        setIsEditing(true)
      }
      setShowForm(true)
    }
  }

  return (
    <StyledDialog open={props.isOpen}>
      <StyledDialogContent style={{ width: 300 }}>
        <div className={classes.row} style={{ marginBottom: 20 }}>
          <h2>{showForm ? translate('New Panel Information') : translate('Panel Information')}</h2>
          <button className={classes.closeIcon} onClick={onClose}>
            <CloseOutlined />
          </button>
        </div>
        <div className={classes.panelRow}>
          <p className={classes.panelLabel}>{translate('Manufacturer')}</p>
          {showForm ? (
            <FormControl className={classes.panelInput}>
              <TextField
                label={false}
                variant="outlined"
                value={panel.manufacturer_name}
                name="manufacturer_name"
                onChange={onChange}
              ></TextField>
            </FormControl>
          ) : (
            <p>{panel.manufacturer_name}</p>
          )}
        </div>
        <ErrorItem fieldKey={'manufacturer_name'} />
        <div className={classes.panelRow}>
          <p className={classes.panelLabel}>{translate('Model/Code')}</p>
          {showForm ? (
            <FormControl className={classes.panelInput}>
              <TextField
                label={false}
                variant="outlined"
                value={panel.code}
                name="code"
                onChange={onChange}
              ></TextField>
            </FormControl>
          ) : (
            <p>{panel.code}</p>
          )}
        </div>
        <ErrorItem fieldKey={'code'} />
        <div className={classes.panelRow}>
          <p className={classes.panelLabel}>{translate('Technology')}</p>
          {showForm ? (
            <FormControl className={classes.panelInput}>
              <SelectInput
                label={false}
                variant="outlined"
                source={panel.technology}
                value={panel.technology}
                choices={moduleTechnologyValues}
                name="technology"
                onChange={onChange}
              ></SelectInput>
            </FormControl>
          ) : (
            <p>{panel.technology}</p>
          )}
        </div>
        <ErrorItem fieldKey={'technology'} />
        <div className={classes.panelRow}>
          <p className={classes.panelLabel}>{translate('Length')}</p>
          {showForm ? (
            <FormControl className={classes.panelInput}>
              <TextField
                label={false}
                variant="outlined"
                value={panel.height}
                type="number"
                inputProps={{ max: '1000', min: '0' }}
                name="height"
                onChange={onChange}
              ></TextField>
              mm
            </FormControl>
          ) : (
            <p>{panel.height * 1000} mm</p>
          )}
        </div>
        <ErrorItem fieldKey={'height'} />
        <div className={classes.panelRow}>
          <p className={classes.panelLabel}>{translate('Width')}</p>
          {showForm ? (
            <FormControl className={classes.panelInput}>
              <TextField
                label={false}
                variant="outlined"
                value={panel.width}
                type="number"
                inputProps={{ max: '1000', min: '0' }}
                name="width"
                onChange={onChange}
              ></TextField>
              mm
            </FormControl>
          ) : (
            <p>{panel.width * 1000} mm</p>
          )}
        </div>
        <ErrorItem fieldKey={'width'} />
        <div className={classes.panelRow}>
          <p className={classes.panelLabel}>{translate('Nominal Power')}</p>
          {showForm ? (
            <FormControl className={classes.panelInput}>
              <TextField
                label={false}
                variant="outlined"
                value={panel.kw_stc}
                type="number"
                inputProps={{ max: '1000', min: '0' }}
                name="kw_stc"
                onChange={onChange}
              ></TextField>
              W
            </FormControl>
          ) : (
            <p>{panel.kw_stc * 1000} W</p>
          )}
        </div>
        <ErrorItem fieldKey={'kw_stc'} />
        <div className={classes.panelRow}>
          <p className={classes.panelLabel}>{translate('NOCT')}</p>
          {showForm ? (
            <FormControl className={classes.panelInput}>
              <TextField
                label={false}
                variant="outlined"
                value={panel.noct}
                type="number"
                name="noct"
                inputProps={{ max: '1000', min: '0' }}
                onChange={onChange}
              ></TextField>
              °C
            </FormControl>
          ) : (
            <p>{Math.ceil(panel.noct)} °C</p>
          )}
        </div>
        <ErrorItem fieldKey={'noct'} />
        <div className={classes.panelRow}>
          <p className={classes.panelLabel}>{translate('Temp. Coeff.')}</p>
          {showForm ? (
            <FormControl className={classes.panelInput}>
              <TextField
                label={false}
                variant="outlined"
                value={panel.temp_coefficient_vpmax}
                type="number"
                name="temp_coefficient_vpmax"
                inputProps={{ max: '1000', min: '0' }}
                onChange={onChange}
              ></TextField>
              %/C
            </FormControl>
          ) : (
            <p>{panel.temp_coefficient_vpmax} %/C</p>
          )}
        </div>
        <ErrorItem fieldKey={'temp_coefficient_vpmax'} />
        <div className={classes.panelRow}>
          <p className={classes.panelLabel}>{translate('Max. Pow. Voltage')}</p>
          {showForm ? (
            <FormControl className={classes.panelInput}>
              <TextField
                label={false}
                variant="outlined"
                value={panel.max_power_voltage}
                type="number"
                name="max_power_voltage"
                inputProps={{ max: '1000', min: '0' }}
                onChange={onChange}
              ></TextField>
              V
            </FormControl>
          ) : (
            <p>{panel.max_power_voltage} V</p>
          )}
        </div>
        <ErrorItem fieldKey={'max_power_voltage'} />
        <div className={classes.panelRow}>
          <p className={classes.panelLabel}>{translate('Voc')}</p>
          {showForm ? (
            <FormControl className={classes.panelInput}>
              <TextField
                label={false}
                variant="outlined"
                value={panel.voc}
                type="number"
                name="voc"
                inputProps={{ max: '1000', min: '0' }}
                onChange={onChange}
              ></TextField>
              V
            </FormControl>
          ) : (
            <p>{panel.voc} V</p>
          )}
        </div>
        <ErrorItem fieldKey={'voc'} />
        <div className={classes.panelRow}>
          <p className={classes.panelLabel}>{translate('Temp. Coeff. VOC')}</p>
          {showForm ? (
            <FormControl className={classes.panelInput}>
              <TextField
                label={false}
                variant="outlined"
                value={panel.temp_coefficient_voc}
                type="number"
                name="temp_coefficient_voc"
                inputProps={{ max: '1000', min: '0' }}
                onChange={onChange}
              ></TextField>
              %/°C
            </FormControl>
          ) : (
            <p>{panel.temp_coefficient_voc} %/°C</p>
          )}
        </div>
        <ErrorItem fieldKey={'temp_coefficient_voc'} />
        {showForm ? (
          <Button
            className={classes.panelButton}
            variant="contained"
            color="primary"
            size="medium"
            label="Save and Close"
            onClick={() => switchDialogMode('info')}
          />
        ) : (
          <>
            {isCustom && allowEdit ? (
              <Button
                className={classes.panelButton}
                variant="contained"
                color="default"
                size="medium"
                label="Edit"
                onClick={() => switchDialogMode('edit')}
                startIcon={<TuneOutlined />}
                style={{ marginRight: 4 }}
              />
            ) : (
              ''
            )}
            {allowCreate ? (
              <Button
                className={classes.panelButton}
                variant="contained"
                color="default"
                size="medium"
                label="Make a Copy"
                onClick={() => switchDialogMode('copy')}
                startIcon={<TuneOutlined />}
              />
            ) : (
              ''
            )}
          </>
        )}
      </StyledDialogContent>
    </StyledDialog>
  )
}

export default PanelInfoDialog

import { SPLIT_NAMES } from 'ducks/split'
import lodash from 'lodash'
import { PermissionKey } from 'types/roles'

// Sections mapped out, holds information about who can view what
export const sections: Record<string, SectionInfo> = {
  header: {
    // show: ['lite'],
    children: {
      nav: {
        children: {
          zone: {
            hide: ['lite'],
          },
          segen: {
            hide: ['lite'],
          },
          control: {
            requireAccessRight: ['team', 'business_info'],
          },
        },
      },
    },
  },
  configuration: {
    children: {
      settings: {
        children: {
          mfa: {
            show: ['!sso', 'mfa'],
          },
          oslite: {
            show: ['nearmap'],
          },
          manage_nearmap_user: {
            show: ['nearmap'],
          },
          change_password: {
            hide: ['sso'],
          },
          change_email: {
            hide: ['sso'],
          },
          ux: {
            hide: ['lite'],
          },
          segen: {
            hide: [],
          },
          ui_switch: {
            hide: ['lite'],
          },
        },
      },
      locales: {
        hide: ['lite'],
      },
      staff: {
        show: ['staff'],
      },
    },
  },
  account_modal: {
    children: {
      locales: {
        hide: ['lite'],
      },
    },
  },
  homepage: {
    children: {
      actions: {
        hide: ['lite'],
      },
      lite_msg: {
        show: ['lite'],
      },
    },
  },
  projects: {
    children: {
      search: {
        children: {
          listButton: {
            hide: ['lite'],
          },
        },
      },
      project: {
        children: {
          info: {
            children: {
              contact: {
                requireAccessRight: 'contacts',
                children: {
                  info_contact_info_basic: {
                    requireAccessRight: 'info_contact_info_basic',
                    children: {
                      info_contact_info_full: {
                        show: [],
                      },
                      linked_projects: {
                        show: [],
                      },
                    },
                  },
                  info_contact_info_full: {
                    requireAccessRight: 'info_contact_info_full',
                    children: {
                      integrations: {
                        orgFeatures: ['centrix_credit_check'],
                      },
                    },
                  },
                  linked_projects: {
                    show: [],
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  control: {
    children: {
      dashboard: {
        hide: ['lite'],
      },
      company: {
        requireAccessRight: ['team', 'business_info'],
        children: {
          business_info: {
            requireAccessRight: 'business_info',
            children: {
              fields: {
                children: {
                  color_highlight: {
                    hide: ['lite'],
                  },
                  about_content: {
                    hide: ['lite'],
                  },
                },
              },
            },
          },
          team: {
            requireAccessRight: 'team',
            children: {
              create: {},
              edit: {},
              fields: {
                children: {
                  grantAccess: {
                    hide: ['lite'],
                  },
                  allowEmailNotifications: {
                    hide: ['lite'],
                  },
                  jobTitle: {
                    hide: ['lite'],
                  },
                  accreditation: {
                    hide: ['lite'],
                  },
                  userImage: {
                    hide: ['lite'],
                  },
                  meetings: {
                    hide: ['lite'],
                  },
                  olark: {
                    hide: ['lite'],
                  },
                  integrations: {
                    hide: ['lite'],
                  },
                },
              },
            },
          },
          business_process: {
            hide: ['lite'],
            requireAccessRight: 'business_process',
          },
          custom_roles: {
            hide: ['lite'],
            isAdmin: true,
          },
          settings: {
            hide: ['lite'],
            requireAccessRight: 'settings',
          },
          connected_orgs: {
            hide: ['lite'],
            feature: 'connected_orgs',
            isAdmin: true,
          },
        },
      },
      pricing_and_payments: {
        hide: ['lite'],
        children: {
          pricing_schemes: {
            requireAccessRight: 'pricing',
          },
          cost_information: {
            requireAccessRight: 'cost_information',
          },
          adders: {
            requireAccessRight: 'adders',
          },
          payment_options: {
            requireAccessRight: 'payment_options',
          },
          commissions: {
            requireAccessRight: 'commissions',
          },
        },
      },
      design_and_hardware: {
        hide: ['lite'],
        children: {
          component_module_activations: {
            requireAccessRight: 'components',
          },
          component_inverter_activations: {
            requireAccessRight: 'components',
          },
          component_battery_activations: {
            requireAccessRight: 'components',
          },
          component_other_activations: {
            requireAccessRight: 'components',
          },
          setbacks_and_design_settings: {
            requireAccessRight: 'setbacks_and_design_settings',
          },
          battery_control_schemes: {
            requireAccessRight: 'battery_control_schemes',
          },
          ordering_orders: {
            orgFeatures: ['segen'],
          },
          ordering_quotes: {
            orgFeatures: ['segen'],
          },
        },
      },
      purchase_experience: {
        hide: ['lite'],
        children: {
          proposal_templates: {
            requireAccessRight: 'proposal_template',
          },
          contracts: {
            requireAccessRight: 'contract_template',
          },
          checkout_exp: {
            requireAccessRight: 'checkout_exp',
          },
          case_studies: {
            requireAccessRight: 'case_studies',
          },
        },
      },
      customers: {
        hide: ['lite'],
        children: {
          contacts: {
            requireAccessRight: 'contacts',
            children: {
              info_contact_info_basic: {
                requireAccessRight: 'info_contact_info_basic',
              },
              info_contact_info_full: {
                requireAccessRight: 'info_contact_info_full',
                children: {
                  integrations: {
                    orgFeatures: ['centrix_credit_check'],
                  },
                },
              },
            },
          },
          transactions: {
            requireAccessRight: 'transactions',
          },
          private_files: {
            requireAccessRight: 'files',
          },
          activities: {
            requireAccessRight: 'activities',
          },
        },
      },
      other: {
        hide: ['lite'],
        children: {
          utility_tariffs: {
            requireAccessRight: 'utility_tariffs',
          },
          incentives: {
            requireAccessRight: 'incentives',
          },
          integrations: {
            requireAccessRight: 'integrations_and_api_keys',
          },
          document_templates: {
            requireAccessRight: 'document_templates',
          },
          lead_capture_forms: {
            requireAccessRight: 'lead_capture_forms',
          },
          sales_territories: {
            requireAccessRight: 'sales_territories',
          },
          public_files: {
            requireAccessRight: 'public_files',
          },
        },
      },
      staff: {
        hide: ['lite'],
      },
      super_user: {
        hide: ['lite'],
      },
      projects: {
        children: {
          search: {
            children: {
              listButton: {
                hide: ['lite'],
              },
            },
          },
        },
      },
    },
  },
}

export interface SectionInfo {
  feature?: SPLIT_NAMES
  show?: HideShowTypes[]
  hide?: HideShowTypes[]
  requireAccessRight?: PermissionKey | PermissionKey[]
  orgFeatures?: string[]
  children?: Record<string, SectionInfo>
  isAdmin?: boolean
}

export type HideShowTypes = 'lite' | 'nearmap' | 'sso' | '!sso' | 'mfa' | 'staff' | 'superuser'

export function recurseSections(path: string, cb: (obj: SectionInfo, key: string, path: string) => false | void) {
  let parent = sections
  const parts = lodash.toPath(path)
  let pathHere = ''
  for (const part of parts) {
    let item = lodash.get(parent, part)
    pathHere += part
    if (item === undefined || cb(item, part, pathHere) === false || !item.children) break
    parent = item.children
    pathHere += '.'
  }
}

import { InputLabel, MenuItem, Select } from '@material-ui/core'
import React, { ChangeEvent, useState } from 'react'
import { useForm } from 'react-final-form'

type PropTypes = {
  fieldPrefix: string
  fieldName: string
  choices: string
  label: string
}

const MultiSelectCustomField: React.FC<PropTypes> = (props) => {
  const form = useForm()

  const [fieldValue, setFieldValue] = useState<string>(form.getState().values[`${props.fieldPrefix}${props.fieldName}`])

  // this field needs to be saved as a string
  const handleMultiSelectChange = (e: ChangeEvent) => {
    if (form.mutators.markFieldAsDirty) form.mutators.markFieldAsDirty(`${props.fieldPrefix}${props.fieldName}`)
    // @ts-ignore
    let newFieldValue = e.target.value.filter((val) => val !== '').join(';')
    setFieldValue(newFieldValue)
    form.change(`${props.fieldPrefix}${props.fieldName}`, newFieldValue)
  }

  // but the select component needs it to be an array
  const parseMultiSelectValue = () => {
    return fieldValue?.split(';') || []
  }

  return (
    <>
      <InputLabel style={{ paddingTop: '10px', fontSize: '12px' }}>{props.label}</InputLabel>
      <Select
        multiple={true}
        label={props.label}
        labelId={`${props.fieldPrefix}${props.fieldName}`}
        id={`${props.fieldPrefix}${props.fieldName}`}
        // @ts-ignore
        onChange={handleMultiSelectChange}
        value={parseMultiSelectValue()}
        fullWidth
        style={{ width: '100%' }}
      >
        {(props.choices?.split(',') || []).map((value) => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </>
  )
}
export default MultiSelectCustomField

import { BaseProjectType } from 'types/projects'
import { urlToId } from './misc'

export const deleteInitiatedChangeOrder = (
  initiatedChangeOrder: number,
  orgId: number,
  projectId: number,
  restClientInstance,
  form
) => {
  if (initiatedChangeOrder) {
    restClientInstance('CUSTOM_DELETE', 'custom', {
      url: `orgs/${orgId}/projects/${projectId}/change_orders/?change_order_id=${initiatedChangeOrder}`,
    }).then(() => {
      form.mutators.updateFieldSilently(
        'change_orders',
        form.getState().values.change_orders.filter((x) => !!x && x.id !== initiatedChangeOrder)
      )
      form.mutators.updateFieldSilently('initiated_change_order', null)
    })
  }
}

export const getChangeOrders = (projectValues: BaseProjectType, form?) => {
  if (!projectValues || !projectValues.change_orders || projectValues.change_orders.length === 0) {
    return []
  }

  const changeOrders = projectValues.change_orders.filter((v) => !!v)
  if (form) {
    form.mutators.updateFieldSilently('change_orders', changeOrders)
  }

  return changeOrders
}

export const getInitiatedChangeOrder = (projectValues: BaseProjectType) => {
  const changeOrders = getChangeOrders(projectValues)
  return changeOrders.find((v) => !v.is_complete)
}

const getSystemIdForUuid = (uuid, projectValues) => {
  const system = projectValues.systems.find((v) => v.uuid === uuid)
  if (system) {
    return system.id
  }
  return null
}

export const useSystemIsEditable = (system, projectValues) => {
  if (!system || !projectValues) {
    return true
  }

  const hasInitiatedChangeOrder = !!getInitiatedChangeOrder(projectValues)

  const systemSoldId = projectValues.original_system_sold
    ? urlToId(projectValues.original_system_sold)
    : urlToId(projectValues.system_sold)
  const currentSystemId =
    system.userData && system.userData.id ? system.userData.id : getSystemIdForUuid(system.userData.uuid, projectValues)
  const changeOrders = getChangeOrders(projectValues)
  const changeOrderSoldSystems = changeOrders.filter((v) => v.is_complete).flatMap((v) => v.system_sold)
  const isCurrentSystemSold = currentSystemId === systemSoldId || changeOrderSoldSystems.includes(currentSystemId)
  return !(hasInitiatedChangeOrder && isCurrentSystemSold)
}

import { FormApi } from 'final-form'
import { clearUnsavedData } from 'projectSections/utils/unsavedDataStore'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { reloadEntireApp } from 'util/misc'
import useRerender from './useRerender'

export const useProjectDiscard = () => {
  const history = useHistory()
  const rerender = useRerender()
  const projectId = useSelector((state: any) => state.projectId)
  const handleDiscard = useCallback(([form]: [form: FormApi]) => {
    clearUnsavedData()
    if (projectId === 'new') {
      history.push('/projects')
    } else {
      const dirtyFields = form.mutators.getFormDirtyFields()
      if (Object.values(dirtyFields).includes('design')) {
        if (window.RUNNING_AS_APP === true) {
          history.push('/projects')
        } else {
          reloadEntireApp()
        }
      } else {
        rerender(form)
        //workaround to restart form with keepDirtyOnReinitialize set to true
        //more detail see:https://github.com/final-form/final-form/issues/151
        form.setConfig('keepDirtyOnReinitialize', false)
        form.restart()
        form.setConfig('keepDirtyOnReinitialize', true)
        form.mutators.resetFormDirtyFields()
      }
    }
  }, [])

  return handleDiscard
}

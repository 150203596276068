import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import LoadingDots from 'layout/widgets/LoadingDots'
import React, { useEffect, useMemo, useState } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type PropTypes = {
  title: string
  messages: string[]
  intervalMs: number
}

const useStyles = makeOpenSolarStyles((theme) => ({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '200px',
  },
  dotsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 0px',
  },
}))

const LoadingDialog: React.FC<PropTypes> = (props) => {
  const [currentStep, setCurrentStep] = useState<number>(0)

  useEffect(() => {
    if (currentStep < props.messages.length - 1) {
      setTimeout(() => {
        setCurrentStep(currentStep + 1)
      }, props.intervalMs)
    }
  }, [currentStep])

  const classes = useStyles()

  const loadingText = useMemo(() => {
    return props.messages[currentStep]
  }, [currentStep])

  return (
    <Dialog open={true}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <div className={classes.contentWrapper}>
          <div className={classes.dotsWrapper}>
            <LoadingDots text={loadingText} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
export default LoadingDialog

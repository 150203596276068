import React, { useEffect, useState } from 'react'
import { SystemDataType } from 'types/systems'
import SunlightNonSolarProjectType from './SunlightNonSolarProjectType'

type PropTypes = {
  system: SystemDataType
  updateFieldState: (fieldName: string, value: string) => void
  nonSolarProjectType: string | undefined
}

const SUNLIGHT_SOLAR_PLUS_ITC = 'solarplusitc'

const PaymentOptionInputs: React.FC<PropTypes> = (props) => {
  const [hasSunlightITC, setHasSunlightITC] = useState<boolean>(false)

  useEffect(() => {
    if (props.system && props.system.payment_options?.length > 0) {
      let foundSunlightITC = false
      props.system.payment_options?.forEach((pmt) => {
        if (pmt?.title?.toLowerCase()?.includes(SUNLIGHT_SOLAR_PLUS_ITC)) foundSunlightITC = true
      })
      if (foundSunlightITC && !hasSunlightITC) setHasSunlightITC(true)
      else if (!foundSunlightITC && hasSunlightITC) setHasSunlightITC(false)
    }
  }, [props.system])

  return (
    <div>
      {hasSunlightITC && (
        <SunlightNonSolarProjectType
          updateFieldState={props.updateFieldState}
          nonSolarProjectType={props.nonSolarProjectType}
          system={props.system}
        />
      )}
    </div>
  )
}
export default PaymentOptionInputs

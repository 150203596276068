import { SystemContext } from 'Designer/designRules/types'
import { EffectFactory } from '.'
import { setSystemCustomData } from '../utils'

export type SetSystemCustomDataDef = {
  type: 'set-system-custom-data'
  prop: string
  value: unknown
}

export const effect_setSystemCustomData: EffectFactory<SetSystemCustomDataDef> = (def) => {
  return {
    execute: async (context: SystemContext) => {
      setSystemCustomData(context, def.prop, def.value)
    },
  }
}

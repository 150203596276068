import { ButtonsRow } from 'projectSections/sections/manage3/common/ButtonsRow'
import { IconBtnWithPermissions } from 'projectSections/sections/manage3/common/IconBtnWithPermissions'

import { ProjectTransactionTypeV3 } from 'types/projectTransactions'

type TransactionRowActionsProps = {
  transactionRecordData: ProjectTransactionTypeV3
  transactionSelectFunc: (i: ProjectTransactionTypeV3) => void
  setRecordResponseInfo: (i: any) => void
}

export const TransactionRowActions = ({
  transactionRecordData,
  transactionSelectFunc,
  setRecordResponseInfo,
}: TransactionRowActionsProps) => {
  return (
    <ButtonsRow>
      <IconBtnWithPermissions
        permissionToCheck="info_transactions"
        permissions={['allowEdit']}
        btnType={'edit'}
        resource={'transactions'}
        record={transactionRecordData}
        onClick={() => {
          transactionSelectFunc(transactionRecordData)
        }}
      />
      <IconBtnWithPermissions
        permissionToCheck="info_transactions"
        permissions={['allowDelete']}
        btnType={'delete'}
        resource={'transactions'}
        record={transactionRecordData}
        onClick={undefined}
        onDelete={() => {
          setRecordResponseInfo({
            status: 'deleted',
            id: transactionRecordData.id,
            response: transactionRecordData.id,
          })
        }}
      />
    </ButtonsRow>
  )
}

import { useMediaQuery } from '@material-ui/core'
import HelpIcon from '@material-ui/icons/HelpOutlineOutlined'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'
import { helpCenterActions } from 'ducks/helpCenter'
import { orderSelectors } from 'ducks/orderComponents'
import { BaseThemeType, Box, IconButton, styled } from 'opensolar-ui'
import ProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/projectOrderPresenter'
import SearchBarController from 'persistentContent/elements/SearchBar'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setToggleGlobalCart } from 'reducer/designer/view'
import { RootState } from 'types/state'
import { useFeatureFlag } from 'util/split'

export const NavigationIconButton = styled(IconButton)({
  color: '#4F4D55',
  fontSize: 12,
  fontWeight: 700,
  gap: 6,
  padding: 8,
  '&:hover': {
    color: '#0A090B',
  },
  '& svg': {
    height: 20,
    width: 20,
  },
})

const NavigationIconButtons = () => {
  const cartItems = useSelector(orderSelectors.getConfirmedLineItems)
  const totalQuantity = ProjectOrderPresenter.getLineItemsTotalQuantity(cartItems)
  const globalCartToggle = useSelector((state: RootState) => state.designer.view.globalCartToggle)
  const history = useHistory()
  const enableMultiDistributorShop = useFeatureFlag('enable_multi_distributor_shop', 'on')

  const dispatch = useDispatch()
  const isTablet = useMediaQuery((theme: BaseThemeType) => theme.breakpoints.up('sm'))

  return (
    <Box alignItems="center" display="flex" gridGap={6}>
      <SearchBarController
        triggerIconButton={
          <NavigationIconButton size="small" variant="squared">
            <SearchOutlinedIcon />
          </NavigationIconButton>
        }
      />
      <NavigationIconButton
        onClick={() =>
          enableMultiDistributorShop ? dispatch(setToggleGlobalCart(!globalCartToggle)) : history.push('/shop/cart')
        }
        size="small"
        variant="squared"
      >
        <ShoppingCartOutlinedIcon />
        {totalQuantity > 0 && totalQuantity}
      </NavigationIconButton>
      {isTablet && (
        <NavigationIconButton
          onClick={() => dispatch(helpCenterActions.setHelpCenterOpen(true))}
          size="small"
          variant="squared"
        >
          <HelpIcon />
        </NavigationIconButton>
      )}
    </Box>
  )
}

export default NavigationIconButtons

import BlueSnapOnboardingSection from './BlueSnapOnboardingSection'
import BankInfoRight from './bankInformationPage/BankInfoRight'
import BankInfoTitle from './bankInformationPage/BankInfoTitle'
import BankInfoTop from './bankInformationPage/BankInfoTop'
import BusinessInfoTop from './businessInfoPage/BusinessInfoTop'
import ControlDescriptionRight from './controlDescriptionPage/ControlDescriptionRight'
import ControlDescriptionTitle from './controlDescriptionPage/ControlDescriptionTitle'
import ControlDescriptionTop from './controlDescriptionPage/ControlDescriptionTop'
import DecisionDisplayTop from './decisionDisplayPage/DecisionDisplayTop'
import IntroTitle from './introPage/IntroTitle'
import IntroTop from './introPage/IntroTop'
import PersonInfoTop from './personInformationPage/PersonInfoTop'
import TermsTitle from './termsPage/TermsTitle'
import TermsTop from './termsPage/TermsTop'
import { OnboardingFormRouteInfoType } from './types'
import VerifyBusinessRight from './verifyBusinessPage/VerifyBusinessRight'
import VerifyBusinessTitle from './verifyBusinessPage/VerifyBusinessTitle'
import VerifyBusinessTop from './verifyBusinessPage/VerifyBusinessTop'

const routes: OnboardingFormRouteInfoType = {
  intro: {
    hasFormFields: false,
    sectionName: 'Activate CashFlow',
    fieldPageNumber: null,
    nextKey: 'verifyBusiness',
    prevKey: null,
    showNextButton: true,
    nextButtonLabel: 'Activate CashFlow',
    showChevronInNextButton: true,
    topLeft: {
      component: IntroTop,
    },
    right: {
      component: null,
    },
    bottomLeft: {
      component: null,
    },
    title: {
      component: IntroTitle,
    },
  },
  verifyBusiness: {
    hasFormFields: true,
    sectionName: 'Verify Business Info',
    fieldPageNumber: 1,
    nextKey: 'businessInfo',
    prevKey: 'intro',
    showNextButton: true,
    topLeft: {
      component: VerifyBusinessTop,
    },
    right: {
      component: VerifyBusinessRight,
    },
    bottomLeft: {
      component: BlueSnapOnboardingSection,
    },
    title: {
      component: VerifyBusinessTitle,
    },
  },
  businessInfo: {
    hasFormFields: true,
    sectionName: 'Business Info',
    fieldPageNumber: 2,
    nextKey: 'terms',
    prevKey: 'verifyBusiness',
    showNextButton: true,
    showSaveAndContinueLater: true,
    topLeft: {
      component: BusinessInfoTop,
    },
    right: {
      component: null,
    },
    bottomLeft: {
      component: BlueSnapOnboardingSection,
    },
    title: {
      component: VerifyBusinessTitle,
    },
  },
  terms: {
    hasFormFields: true,
    sectionName: 'Terms',
    fieldPageNumber: 3,
    nextKey: 'controlDescription',
    prevKey: 'businessInfo',
    showNextButton: true,
    topLeft: {
      component: TermsTop,
    },
    right: {
      component: null,
    },
    bottomLeft: {
      component: BlueSnapOnboardingSection,
    },
    title: {
      component: TermsTitle,
    },
  },
  controlDescription: {
    hasFormFields: false,
    sectionName: 'Contact Description',
    fieldPageNumber: null,
    nextKey: 'personInformation',
    prevKey: 'terms',
    showNextButton: false,
    topLeft: {
      component: ControlDescriptionTop,
    },
    right: {
      component: ControlDescriptionRight,
    },
    bottomLeft: {
      component: BlueSnapOnboardingSection,
    },
    title: {
      component: ControlDescriptionTitle,
    },
  },
  personInformation: {
    hasFormFields: true,
    sectionName: 'Add/Edit contact',
    fieldPageNumber: 4,
    nextKey: 'controlDescriptionFilled',
    prevKey: 'controlDescription',
    showCancelButton: true,
    showAddButton: true,
    showNextButton: false,
    topLeft: {
      component: PersonInfoTop,
    },
    right: {
      component: null,
    },
    bottomLeft: {
      component: BlueSnapOnboardingSection,
    },
    title: {
      component: ControlDescriptionTitle,
    },
  },
  controlDescriptionFilled: {
    hasFormFields: false,
    sectionName: 'Confirm Contacts',
    fieldPageNumber: null,
    nextKey: 'bankInformation',
    prevKey: 'terms',
    showNextButton: true,
    topLeft: {
      component: ControlDescriptionTop,
    },
    right: {
      component: ControlDescriptionRight,
    },
    bottomLeft: {
      component: null,
    },
    title: {
      component: ControlDescriptionTitle,
    },
  },
  bankInformation: {
    hasFormFields: true,
    sectionName: 'Bank Information',
    nextButtonLabel: 'Submit',
    fieldPageNumber: 5,
    nextKey: 'decisionDisplay',
    prevKey: 'controlDescriptionFilled',
    showNextButton: true,
    topLeft: {
      component: BankInfoTop,
    },
    right: {
      component: BankInfoRight,
    },
    bottomLeft: {
      component: BlueSnapOnboardingSection,
    },
    title: {
      component: BankInfoTitle,
    },
  },
  decisionDisplay: {
    hasFormFields: false,
    sectionName: 'decisionDisplay',
    fieldPageNumber: null,
    nextKey: null,
    prevKey: null,
    showNextButton: false,
    topLeft: {
      component: DecisionDisplayTop,
    },
    right: {
      component: null,
    },
    bottomLeft: {
      component: null,
    },
    title: {
      component: null,
    },
  },
}

export default routes

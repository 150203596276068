import { MagicWandIcon } from 'icons/MagicWandIcon'
import { useDispatch } from 'react-redux'
import { setAutoStringModuleUuids } from 'reducer/designer/autoString'
import { setSelectedDesignTab } from 'reducer/designer/view'
import ActionButton from './ActionButton'

const AutoStringButton = ({ moduleGrids }) => {
  const dispatch = useDispatch()
  const handleClicked = () => {
    if (window.Designer) {
      var autoStringModuleUuids = moduleGrids
        .map((moduleGrid) => moduleGrid.getModules().map((osModule) => osModule.uuid))
        .reduce((a, b) => [...a, ...b])

      window.editor.select(null)
      dispatch(setSelectedDesignTab('inverters'))
      dispatch(setAutoStringModuleUuids(autoStringModuleUuids))
    }
  }

  return <ActionButton Icon={MagicWandIcon} onClick={handleClicked} label={'Auto-String...'} />
}

export default AutoStringButton

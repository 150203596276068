import { makeStyles } from '@material-ui/core'
import { Button, FileQuestionIcon } from 'opensolar-ui'
import { COLOR_PALETTE, getHardwareSectionPreOrderStyles } from 'pages/ordering/checkoutv3/preOrder/styles'
import { useFormState } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import { Theme } from 'types/themes'
import { useTranslate } from 'ra-core'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '40px 28px',
    width: '100%',
    maxWidth: 1440,
    boxSizing: 'border-box',
    flexGrow: 1,
    height: '100vh',
  },
  flexCenter: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyCardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    padding: '40px 24px',
    background: '#FFFFFF',
    width: '100%',
    height: '200px',
  },
  emptyIconWrapper: {
    padding: 15,
    background: COLOR_PALETTE.grey8,
    borderRadius: '12px',
  },
  emptyCardTitle: {
    fontSize: '14px',
    fontWeight: 550,
    color: COLOR_PALETTE.darkGrey,
  },
  emptyCardSubtitle: {
    fontSize: '12px',
    color: COLOR_PALETTE.grey2,
  },
}))

const EmptyCheckout = () => {
  const history = useHistory()
  const project = useFormState().values
  const classes = useStyles()
  const sectionStyles = getHardwareSectionPreOrderStyles()
  const translate = useTranslate()

  const handleGoToDesign = () => {
    history.push(`/projects/${project.id}/design`)
  }

  return (
    <div className={classes.container}>
      <div className={classes.emptyCardWrapper}>
        <div className={classes.emptyIconWrapper}>
          <FileQuestionIcon width="26" height="26" color={COLOR_PALETTE.darkGrey} strokeWidth="10" />
        </div>
        <div className={`${sectionStyles.column} ${classes.flexCenter}`} style={{ gap: '8px' }}>
          <span className={classes.emptyCardTitle}>{translate('You haven’t created a system design yet')}</span>
          <span className={classes.emptyCardSubtitle}>
            {translate('Head over to the Design Tab to create your design and review all your hardware parts here.')}
          </span>
        </div>
        <Button variant="outlined" onClick={handleGoToDesign} color="default">
          {translate('Go to Design Tab')}
        </Button>
      </div>
    </div>
  )
}

export default EmptyCheckout

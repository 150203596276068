import lodash from 'lodash'
import { useEffect, useMemo, useRef } from 'react'
import { useForm } from 'react-final-form'
import { useStudioSignalsLazy } from 'Studio/signals/useStudioSignalsLazy'
import { StudioSystemType } from 'types/global'
import { FileDataBrief } from 'types/projects'
import { SnapshotGeneratorDiffFlags } from 'types/studio/editor'
import { useFeatureFlag } from 'util/split'

const log = (...args) => {
  if (window.debugSystemSnapshot) {
    console.log(...args)
  }
}

export const useSnapshotExtension = () => {
  const form = useForm()
  const noSystemThumbnail = useRef<string | undefined>()
  const systemThumbnails = useRef<{ [systemUuid: string]: string }>({})
  const studioExtensions = useMemo(() => window.editor.extensions, [])
  const isSnapshottingNow = useRef(false)

  const enableThumbnails = useFeatureFlag('project_thumbnails', 'on')

  useEffect(() => {
    if (!enableThumbnails) return
    form.registerField('files_data', () => {}, {})

    studioExtensions.SnapshotGenerator.activate()
    return () => studioExtensions.SnapshotGenerator.deactivate()
  }, [enableThumbnails])

  const isSatisfied = (system: StudioSystemType | undefined, diff: SnapshotGeneratorDiffFlags) => {
    if (!(diff.env && diff.view && (!system || diff.systems[system.uuid]))) {
      return false
    }

    // Check if the form has file data
    const filesData = form.getState().values['files_data'] || form.getState().values['private_files_data']
    if (!filesData) return false

    for (const fileData of filesData) {
      if (
        !fileData.file_tag_titles?.includes('Project Thumbnail') &&
        !fileData.file_tags_data?.some((t) => t.title === 'Project Thumbnail')
      )
        continue
      if ((system && fileData.system_uuid === system?.uuid) || (!system && !fileData.system_uuid)) {
        return true
      }
    }

    return false
  }

  const finishSnapshot = (system: StudioSystemType | undefined) => {
    isSnapshottingNow.current = false
    studioExtensions.SnapshotGenerator.updateEditorVisualState(system ? [system.uuid] : [])
    checkCurrentSystem(false)
  }

  const systemSnapshotNow = async () => {
    if (!enableThumbnails) return

    if (!studioExtensions.SnapshotGenerator.isActive) {
      log('SnapshotGenerator is not active, skipping')
      return
    }

    const system = window.editor.selectedSystem
    const diff = studioExtensions.SnapshotGenerator.getEditorVisualStateDiffFlags()
    if (isSatisfied(system, diff)) {
      log('No changes detected, skipping')
      return
    }

    if (isSnapshottingNow.current) {
      log('SnapshotGenerator is currently capturing, skipping')
      return
    }
    isSnapshottingNow.current = true

    log('System snapshot begin', system?.uuid)

    try {
      const snapshots = await studioExtensions.SnapshotGenerator.generate({
        size: [300, 225], // 3x the actual size of the thumbnail in CSS element to account for retina displays
        systemUuids: system ? [system.uuid] : null,
        backgroundFill: '#ECECED',
        showDesignGuides: false,
        scale: (300 / 550) * 0.9, // 550px is the image box width, 0.9 gives it a little breathing room
      })
      const imageData = await window.CanvasUtils.exportAsJPEGDataUrl(snapshots[0], {
        compressionLevel: 0.8,
      })
      if (system) {
        systemThumbnails.current[system.uuid] = imageData
      } else {
        noSystemThumbnail.current = imageData
      }
    } catch (error) {
      console.error(`Error generating snapshot for system ${system?.uuid}:`, error)
      if (system) {
        delete systemThumbnails.current[system.uuid]
      } else {
        noSystemThumbnail.current = undefined
      }
      finishSnapshot(system)
      return
    }
    log('System snapshot complete', system?.uuid)

    finishSnapshot(system)
  }

  const systemSnapshotLater = lodash.debounce(systemSnapshotNow, 1000)

  useStudioSignalsLazy(
    (obj, prop?: string) => {
      obj = obj.getSystem?.() || obj
      if (obj.type !== 'OsSystem' || prop === 'output') return
      systemSnapshotLater()
    },
    ['objectChanged']
  )

  useStudioSignalsLazy(() => {
    const system = window.editor.selectedSystem
    if (system || studioExtensions.SnapshotGenerator.compatibleMapImagery()) systemSnapshotLater()
  }, ['cameraChanged'])

  const checkCurrentSystem = (allowSnapshot = true) => {
    if (!studioExtensions.SnapshotGenerator.isActive) return
    const system = window.editor.selectedSystem
    const diff = studioExtensions.SnapshotGenerator.getEditorVisualStateDiffFlags()
    if (
      allowSnapshot &&
      !isSatisfied(system, diff) &&
      (system || studioExtensions.SnapshotGenerator.compatibleMapImagery())
    ) {
      systemSnapshotLater()
    } else {
      const thumbnail = system ? systemThumbnails.current[system.uuid] : noSystemThumbnail.current
      const payload: FileDataBrief[] = thumbnail
        ? [
            {
              system_uuid: system?.uuid,
              title: 'project-thumbnail.jpg',
              file_tag_titles: ['Design Artifact', 'Project Thumbnail'],
              file_contents_b64: thumbnail,
            },
          ]
        : []

      // ProjectForm changes are very expensive, so we'll save this as a 'quiet field'
      form.mutators.setQuietField('files_data', payload)
    }
  }

  useStudioSignalsLazy(checkCurrentSystem, ['systemSelected'])
}

declare global {
  interface Window {
    debugSystemSnapshot: boolean
  }
}

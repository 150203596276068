import { useMemo } from 'react'
import type { ComponentTypesV2 } from 'types/selectComponent'
import { mapComponentTypesV2ToV1 } from 'util/misc'
import { CURRENT_FILTER_KEY_V2 } from '../currentType/CurrentTypeFilterNodeFactoryV2'
import type {
  ConfigurableFilterOptionsType,
  FilterComponentNodeV2,
  FilterGenericPropsType,
  FilterValuesType,
} from '../typeV2'
import { parseStringifiedListFilterValue } from '../utils/utils'
import {
  CURRENT_RATING_FILTER_GTE_KEY,
  CURRENT_RATING_FILTER_LTE_KEY,
  CURRENT_RATING_RANGE,
  getOptionValueByFilterValues,
  getRangeOptionByValue,
  mapRangeOptionsToSelectOptions,
} from './constants'

export const CURRENT_RATING_FILTER_NODE_KEY_V2 = 'current_rating'

export const isCurrentRatingFilterAvailableV2 = (filterValues: FilterValuesType) => {
  const currentTypes = parseStringifiedListFilterValue<ComponentTypesV2>({
    filterValues,
    key: 'types',
  })
  return currentTypes.has('isolator') && filterValues[CURRENT_FILTER_KEY_V2] === 'ac'
}

export const createCurrentRatingFilterNode = <T,>({
  label = 'Current Rating',
  rendererComponent: RendererComponent,
  rendererAdditionalProps,
  ...restConfigs
}: ConfigurableFilterOptionsType<string, string>): FilterComponentNodeV2 => {
  const FilterComponent = ({
    modifiableFilterValues,
    allFilterValues,
    setModifiableFilters,
  }: FilterGenericPropsType) => {
    const types = parseStringifiedListFilterValue<ComponentTypesV2>({
      filterValues: allFilterValues,
      key: 'types',
    })
    const selectOptions = useMemo(() => {
      return mapRangeOptionsToSelectOptions(CURRENT_RATING_RANGE)
    }, [])

    const hasOtherType = Array.from(types).some((type) => {
      return mapComponentTypesV2ToV1(type) === 'other'
    })

    if (!hasOtherType) {
      return null
    }

    const onSelect = (value) => {
      const matchedRangeOption = getRangeOptionByValue(value, CURRENT_RATING_RANGE)
      const newFilterValues = {
        ...modifiableFilterValues,
        [CURRENT_RATING_FILTER_GTE_KEY]: matchedRangeOption?.min,
        [CURRENT_RATING_FILTER_LTE_KEY]: matchedRangeOption?.max,
      }
      setModifiableFilters(newFilterValues)
    }

    const disabled = !isCurrentRatingFilterAvailableV2(allFilterValues)

    return (
      <RendererComponent
        id={CURRENT_RATING_FILTER_NODE_KEY_V2}
        defaultValue={''}
        additionalProps={rendererAdditionalProps}
        label={label}
        options={selectOptions}
        disabled={!!disabled}
        onSelect={onSelect}
        value={getOptionValueByFilterValues({
          ranges: CURRENT_RATING_RANGE,
          gte: allFilterValues[CURRENT_RATING_FILTER_GTE_KEY],
          lte: allFilterValues[CURRENT_RATING_FILTER_LTE_KEY],
        })}
      />
    )
  }

  return {
    key: CURRENT_RATING_FILTER_NODE_KEY_V2,
    component: FilterComponent,
  }
}

export default createCurrentRatingFilterNode

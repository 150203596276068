import { HelpLink } from 'elements/link/HelpLink'
import React from 'react'
import useTranslateParse from '../../../util/useTranslateParse'
import { ActiveBatteriesCreate, ActiveBatteriesEdit, ActiveBatteriesList } from './activeBatteries'

const BatterySubtitle = () => {
  const translateParse = useTranslateParse()
  return (
    <span>
      {translateParse(
        "Search our extensive database, and add batteries to your 'active' list so your team can use them to design. Non-admins do not have the ability to search the database and can only use equipment on this list. If any equipment is missing from the database, email <mailtoLink>support@opensolar.com<mailtoLink> and we will add it, or you can create your own.",
        { mailtoLink: (label) => <a href="mailto:support@opensolar.com">{label}</a> }
      )}{' '}
      <HelpLink href="https://support.opensolar.com/hc/en-us/articles/4407230997401" />
    </span>
  )
}

const ActiveBatteriesName = ({ translate, style }) => {
  return <span style={style}>{translate('Edit Battery')}</span>
}

export default {
  list: ActiveBatteriesList,
  edit: ActiveBatteriesEdit,
  create: ActiveBatteriesCreate,
  options: {
    create: {
      title: 'Battery Activation',
      subtitle: null,
      breadCrumb: 'Battery Activation',
    },
    list: {
      title: 'My Active Batteries List',
      subtitle: <BatterySubtitle />,
      breadCrumb: 'Batteries',
    },
    edit: {
      title: <ActiveBatteriesName />,
      subtitle: null,
      breadCrumb: 'Edit Battery',
    },
  },
}

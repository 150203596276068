import type LineItemType from 'pages/ordering/OrderLineItem'
import React, { memo } from 'react'
import BulkQuantityErrorMessage from './bulk/BulkQuantityErrorMessage'

const useQuantityMessage = ({ lineItem }: { lineItem: LineItemType }) => {
  const Message = memo(() => {
    return <BulkQuantityErrorMessage lineItem={lineItem} />
  })
  return Message
}

export default useQuantityMessage
import { Grid } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import { formatPricePerWattWithUnit, getPricePerWatt } from 'pages/ordering/detail/content/price/PriceDetail'
import { getDiscountSavingPerItem, getMinimumRequiredQuantityForDiscount } from 'pages/ordering/discountUtils'
import type { DiscountType } from 'pages/ordering/type'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { ComponentTypes } from 'types/selectComponent'
import { currencySymbolForCountry, formatCurrencyWithSymbol } from 'util/misc'

const useStyles = makeOpenSolarStyles((theme) => ({
  title: {
    fontSize: '20px',
    color: theme.palette.success.main,
  },
  container: {
    margin: '20px 0',
    width: 200,
  },
}))

const VolumeDiscounts = ({
  discounts,
  pricePerItem,
  componentType,
  outputPower,
  quantityPerItem,
}: {
  discounts: DiscountType[]
  pricePerItem: number
  componentType?: ComponentTypes
  outputPower: number
  quantityPerItem: number
}) => {
  const classes = useStyles()
  const orgIso2 = useSelector(orgSelectors.getOrgIso2)
  const currencySymbol = useSelector((state: any) => {
    return currencySymbolForCountry(orgSelectors.getOrgIso2(state))
  })

  if (discounts.length === 0) {
    return null
  }

  if (discounts.length === 1 && getMinimumRequiredQuantityForDiscount({ discount: discounts[0], pricePerItem }) <= 1) {
    // No point to show volume discount if quantity is 1
    return null
  }

  const minQuantityAndPrice = discounts.map((discount) => {
    const minimumRequiredQuantity = getMinimumRequiredQuantityForDiscount({ discount, pricePerItem })
    const savingPerItem = getDiscountSavingPerItem({ discount, pricePerItem })
    const priceAfterDiscount = pricePerItem - savingPerItem
    const pricePerWattAfterDiscount = getPricePerWatt({
      power: outputPower,
      price: priceAfterDiscount,
      quantityPerItem,
    })
    return {
      quantity: minimumRequiredQuantity,
      price:
        componentType === 'module' && pricePerWattAfterDiscount
          ? `${formatPricePerWattWithUnit(pricePerWattAfterDiscount, orgIso2)}/W`
          : `${formatCurrencyWithSymbol(priceAfterDiscount, currencySymbol)}`,
    }
  })
  minQuantityAndPrice.sort((a, b) => a.quantity - b.quantity)

  return (
    <Grid container className={classes.container} spacing={2}>
      <Grid item xs={12} className={classes.title}>
        Volume discounts
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={6}>
          Min Qty
        </Grid>
        <Grid item xs={6}>
          New price
        </Grid>
        {minQuantityAndPrice.map(({ quantity, price }) => {
          return (
            <>
              <Grid item xs={6}>
                {quantity}
              </Grid>
              <Grid item xs={6}>
                {price}
              </Grid>
            </>
          )
        })}
      </Grid>
    </Grid>
  )
}

export default memo(VolumeDiscounts)

import { ContiguousPanelRow, HasPosition, Item, MountingCalcResult, Panel, PanelRow, RailComponent } from '../types'
import { addItems, createMountingItem } from '../utils'
import { BomGeneratorAbstract } from './BomGeneratorAbstract'

export abstract class PerRowRailBomGeneratorAbstract extends BomGeneratorAbstract {
  abstract chooseRail(): RailComponent
  abstract chooseMidClamp(): Item
  abstract chooseEndClamp(): Item
  abstract chooseEndCap(): Item | null
  abstract getRailPositions(panel: Panel): HorizontalRailPosition[]

  async generateBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    return this.block.rows
      .flatMap(getContiguousRows)
      .reduce((resultFromLastRow, row) => this.generateRailBomForRow(resultFromLastRow, row), result)
  }

  generateRailBomForRow(resultFromLastRow: MountingCalcResult, row: PanelRow): MountingCalcResult {
    const resultWithRails = this.addRails(row, resultFromLastRow)
    const resultWithMidClamps = this.addMidClamps(row, resultWithRails)
    const resultWithEndClamps = this.addEndClamps(row, resultWithMidClamps)
    const resultWithEndCaps = this.addEndCaps(row, resultWithEndClamps)
    return resultWithEndCaps
  }

  addRails(row: ContiguousPanelRow, result: MountingCalcResult): MountingCalcResult {
    if (!row.length) return result
    const baseRail = this.chooseRail()
    if (baseRail.railType === 'mini') {
      return this.addMiniRails(row, result)
    } else {
      // Standard continuous rail
      return this.addUncutRails(row, result)
    }
  }

  // We're going to first add rail lengths that may be longer than the rails actually come
  // Then after the whole bom is done, we'll rejig, cutting and splicing as need
  addUncutRails(row: ContiguousPanelRow, result: MountingCalcResult): MountingCalcResult {
    const railComponents: RailComponent[] = []
    const baseRail = this.chooseRail()
    const firstPanel = row[0]
    const lastPanel = row[row.length - 1]
    const rowStartLeft = firstPanel.left
    const rowStartTop = firstPanel.top
    const rowEndLeft = lastPanel.left + lastPanel.width // Distance from left at end of last panel, not left edge of last panel
    const rowLength = rowEndLeft - rowStartLeft

    this.getRailPositions(firstPanel).forEach(({ left, right, top }) =>
      railComponents.push({
        ...baseRail,
        length: rowLength - left + right,
        top: top + rowStartTop,
        left: left + rowStartLeft,
        blockIndex: this.blockIndex,
      })
    )

    return addItems(
      result,
      railComponents.map((railComponent) => ({
        name: railComponent.name,
        components: [railComponent],
      }))
    )
  }

  addMiniRails(row: ContiguousPanelRow, result: MountingCalcResult): MountingCalcResult {
    if (row.length < 2) return result
    const railComponents: RailComponent[] = []
    const baseRail = this.chooseRail()
    const panelWidth = this.block.panel.width
    const offset = (row[1].left - (row[0].left + panelWidth)) / 2 // half the gap between panels, to position rail right in the middle
    const rowStart = row[0].left
    const rowEnd = getRowEnd(row)

    // Add rails to outer edges (eg. where endclamps usually are)
    this.getRailPositions(row[0]).forEach(({ top }) => {
      railComponents.push(
        { ...baseRail, top, left: rowStart, blockIndex: this.blockIndex },
        { ...baseRail, top, left: rowEnd, blockIndex: this.blockIndex }
      )
    })

    // Add rails to inner edges (eg. where midclamps usually are)
    for (let index = 1; index < row.length; index++) {
      // Start with the second panel
      const left = row[index].left - offset // Halfway point between the panels
      railComponents.push(
        ...this.getRailPositions(this.block.panel).map(
          // For each rail...
          ({ top }) => {
            return {
              ...baseRail,
              top: top,
              left: left,
              blockIndex: this.blockIndex,
            }
          }
        )
      )
    }

    return addItems(
      result,
      railComponents.map((railComponent) => ({
        name: railComponent.name,
        components: [railComponent],
      }))
    )
  }

  addMidClamps(row: ContiguousPanelRow, result: MountingCalcResult): MountingCalcResult {
    if (row.length < 2) return result

    const midClamps: Item[] = []
    const baseClamp = this.chooseMidClamp()
    const panelWidth = this.block.panel.width
    const offset = (row[1].left - (row[0].left + panelWidth)) / 2 // half the gap between panels, to position clamps right in the middle

    for (let index = 1; index < row.length; index++) {
      // Start with the second panel
      const left = row[index].left - offset // Halfway point between the panels
      midClamps.push(
        ...this.getRailPositions(this.block.panel).map(
          // For each rail...
          ({ top }) => createMountingItem(baseClamp, { top, left, blockIndex: this.blockIndex }) // Add the clamp at the right position
        )
      )
    }

    return addItems(result, midClamps)
  }

  addEndClamps(row: ContiguousPanelRow, result: MountingCalcResult): MountingCalcResult {
    if (!row.length) return result

    const endClamps: Item[] = []
    const baseClamp = this.chooseEndClamp()

    const rowStart = row[0].left
    const rowEnd = getRowEnd(row)

    this.getRailPositions(row[0]).forEach(({ top }) => {
      endClamps.push(
        createMountingItem(baseClamp, { top, left: rowStart, blockIndex: this.blockIndex }),
        createMountingItem(baseClamp, { top, left: rowEnd, blockIndex: this.blockIndex })
      )
    })

    return addItems(result, endClamps)
  }

  addEndCaps(row: ContiguousPanelRow, result: MountingCalcResult): MountingCalcResult {
    const baseCap = this.chooseEndCap()
    if (!baseCap) return result

    const rowTop = row[0].top
    const rowStart = row[0].left
    const rowEnd = getRowEnd(row)

    const endCaps = this.getRailPositions(row[0]).flatMap(({ left, right, top }) => [
      createMountingItem(baseCap, { top: top + rowTop, left: left + rowStart, blockIndex: this.blockIndex }),
      createMountingItem(baseCap, { top: top + rowTop, left: right + rowEnd, blockIndex: this.blockIndex }),
    ])

    return addItems(result, endCaps)
  }
}

// A PanelRow may have gaps. Much easier to work with rows without gaps
// An alternative would be to check each x-coord for a panel, and neighbours, and add to the bom accordingly
function getContiguousRows(row: PanelRow): ContiguousPanelRow[] {
  const contiguousRows: ContiguousPanelRow[] = []

  let currentContiguousRow: ContiguousPanelRow = []

  row.forEach((panel, index) => {
    if (panel.isActive) {
      currentContiguousRow.push(panel)

      if (!row[index + 1] || !row[index + 1].isActive) {
        // We've found the end of the contiguous row...
        // ...so we push it and start over
        contiguousRows.push(currentContiguousRow)
        currentContiguousRow = []
      }
    }
  })

  return contiguousRows
}

function getRowEnd(row: ContiguousPanelRow) {
  return row[row.length - 1].left + row[row.length - 1].width
}

export interface HorizontalRailPosition extends HasPosition {
  // left: number (inherited) // mm, how far the rail pokes out before the start of the row
  // top: number (inhertied) // mm, how far down the panel the rail is placed
  right: number // mm, how far the rail extends past the end of a row
}

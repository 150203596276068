import { makeStyles, useMediaQuery } from '@material-ui/core'
import RateReviewIcon from '@material-ui/icons/RateReviewOutlined'
import { myEnergySelectors } from 'ducks/myEnergy'
import { Button } from 'opensolar-ui'
import React, { useEffect, useState } from 'react'
import { AvailableCustomerActionsType } from 'types/customerActions'
import useMemoizedSelector from 'util/useMemoizedSelector'
import SendCreditAppDialog from './SendCreditAppDialog'
import { SEND_APP_SUPPORTED_PAYMENT_METHODS } from './constants'

type PropTypes = {}

const useStyles = makeStyles((theme: any) => ({
  buttonWrapper: {
    marginRight: 20,
  },
}))

const SendCreditAppButton: React.FC<PropTypes> = (props) => {
  const [shouldRender, setShouldRender] = useState<boolean>(false)
  const [showDialog, setShowDialog] = useState<boolean>(false)

  const classes = useStyles()
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('xs'))
  const availableActions = useMemoizedSelector(myEnergySelectors.getProposalAvailableActions) as
    | undefined
    | AvailableCustomerActionsType

  useEffect(() => {
    if (availableActions) {
      let foundAvailableApp = false
      availableActions?.forEach((systemData) => {
        systemData.actions_available?.forEach((action) => {
          if (SEND_APP_SUPPORTED_PAYMENT_METHODS.includes(action.payment_method) && action.status_code === 'available')
            foundAvailableApp = true
        })
      })
      setShouldRender(foundAvailableApp)
    }
  }, [availableActions])

  const onButtonClick = () => {
    setShowDialog(true)
  }

  if (!shouldRender || isMobile) return null
  return (
    <div className={classes.buttonWrapper}>
      <Button variant="outlined" color="primary" startIcon={<RateReviewIcon />} size="small" onClick={onButtonClick}>
        <span style={{ textTransform: 'none' }}>Send Credit App to Customer</span>
      </Button>
      {showDialog && <SendCreditAppDialog isOpen={showDialog} onClose={() => setShowDialog(false)} />}
    </div>
  )
}
export default SendCreditAppButton

import { Box, Paper } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { LOCALES_SUPPORTED_LEAD_CAPTURE_FORM } from 'App'
import { solkunderLink } from 'constants/links'
import { ComponentVersions_2_1 } from 'constants/uxVersions'
import { authSelectors } from 'ducks/auth'
import CustomField from 'elements/field/CustomField'
import { List } from 'elements/react-admin/List'
import { Button, ComponentVersionsInherit } from 'opensolar-ui'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import {
  Create,
  Datagrid,
  Edit,
  FormDataConsumer,
  FunctionField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'
import { useFormState } from 'react-final-form'
import { connect, useSelector } from 'react-redux'
import { getLocale } from 'storage/appStorage'
import { required } from 'validations'
import { duplicate as duplicateAction } from '../../actions/restActions'
import EditOrImportButton from '../../elements/button/EditOrImportButton'
import CheckboxInput from '../../elements/input/CheckboxInput'
import { getRoleFromState } from '../../util/misc'
import { getOrgFromState } from '../../util/org'
import LeadCaptureFormCreateButton from './LeadCaptureFormCreateButton'
import { useLeadCaptureFormats } from './utils'

const optional_field_choices = [
  { name: 'Exclude', id: 1 },
  { name: 'Include (optional)', id: 2 },
  { name: 'Include (required)', id: 3 },
]

export const LeadCaptureFormList = connect(
  (state) => {
    return {
      org_id: state.auth ? state.auth.org_id : null,
    }
  },
  { duplicate: duplicateAction }
)(({ accessRights: { allowCreate, allowEdit, allowDelete }, ...props }) => {
  const isAdmin = useSelector((state) => Boolean(getRoleFromState(state)?.is_admin))

  const formatOptions = useLeadCaptureFormats()

  const leadCaptureFormFormatAsDict = {}
  formatOptions.forEach((item) => {
    leadCaptureFormFormatAsDict[item.id] = item.name
  })

  useEffect(() => {
    logAmplitudeEvent('on_lead_capture_page', { org_id: props.org_id })
  }, [])

  return (
    <List
      extraCreateButton={isAdmin ? <LeadCaptureFormCreateButton options={formatOptions} /> : null}
      actions={<>{props.hasCreate && <LeadCaptureFormCreateButton options={formatOptions} />}</>}
      {...props}
      hasCreate={allowCreate}
    >
      <Datagrid duplicate={props.duplicate} isAdmin={isAdmin}>
        <TextField source="title" />
        <FunctionField
          source="format"
          displayInCard={true}
          render={(record) => leadCaptureFormFormatAsDict[record.format]}
          elStyle={{ width: 20 }}
          sortable={false}
        />
        <ReferenceField
          source="assign_to_team_member"
          label="Assign to"
          displayInCard={true}
          skipFromGrid={true}
          sortable={false}
          reference="roles"
          linkType="list"
        >
          <TextField source="display" />
        </ReferenceField>
        <TextField source="lead_source" displayInCard={true} skipFromGrid={true} sortable={false} />
        <EditOrImportButton
          sortable={false}
          source="actions"
          label="Actions"
          org_id={props.org_id}
          resource="lead_capture_forms"
          duplicate={props.duplicate}
          isAdmin={isAdmin}
          allowEdit={allowEdit}
          allowDelete={allowDelete}
        />
      </Datagrid>
    </List>
  )
})

const styles = {
  floatLeft: {
    float: 'left',
    marginRight: 10,
  },
  inputWidth: {
    width: 256,
    marginRight: 10,
  },
}

const getCode = (orgId, record, translate) => {
  if (!record || !record.uuid) {
    return translate('(Available after creation)')
  }

  var coreScript =
    '<script id="opensolar_lead_capture" type="text/javascript" src="' +
    window.API_BASE_URL_ABSOLUTE +
    'lead_capture_widget/' +
    record.uuid.split('-').join('') +
    '"></script>'

  if (record.format === 1) {
    return coreScript
  } else if (record.format === 2) {
    return coreScript + '<button onclick="renderOpenSolarWidget()">' + translate('Click Me') + '</button>'
  } else if (record.format === 3) {
    return (
      <div
        onClickCapture={() => {
          logAmplitudeEvent('solkunder_code_block_section_clicked', { org_id: orgId, uuid: record.uuid })
        }}
      >
        <p>
          UUID: <strong>{record.uuid.toUpperCase()}</strong>
        </p>
        <p>
          ORG ID: <strong>{orgId}</strong>
        </p>
      </div>
    )
  } else {
    return translate('Unavailable')
  }
}

const HTML_FIELDS = [
  { id: 'last_name_setting', label: 'Last name setting' },
  { id: 'phone_setting', label: 'Phone setting' },
  { id: 'notes_setting', label: 'Notes setting' },
  { id: 'roof_type_setting', label: 'Roof type setting' },
  { id: 'number_of_phases_setting', label: 'Number of phases setting' },
]

const SolkunderLinkButton = ({ record }) => {
  const translate = useTranslate()

  if (!record || !record.uuid) {
    return (
      <Button variant="outlined" color="default" disabled={true}>
        {translate('(Available after creation)')}
      </Button>
    )
  }

  const orgId = window.getStorage().getItem('org_id')
  const uuid = record.uuid

  const url = `${solkunderLink}?org_id=${orgId}&uuid=${uuid}`

  return (
    <Button
      variant="outlined"
      color="default"
      onClick={() => {
        logAmplitudeEvent('solkunder_link_to_clicked', { org_id: orgId, uuid: uuid })
        window.open(url, '_blank', 'noreferrer')
      }}
    >
      {translate('Link to Solkunder')}
    </Button>
  )
}

const Inputs = (props) => {
  const translate = useTranslate()
  const form = useFormState()
  const orgId = useSelector(authSelectors.getOrgId)

  const format = form.values?.format

  const formatOptions = useLeadCaptureFormats()

  const getLanguageChoices = () => {
    return [...Object.entries(LOCALES_SUPPORTED_LEAD_CAPTURE_FORM).map(([key, value]) => ({ id: key, name: value }))]
  }

  return (
    <div>
      <h1>{translate('Configuration')}</h1>

      <CustomField
        component={SelectInput}
        defaultValue={1}
        name="format"
        source="format"
        label="Format"
        choices={formatOptions}
        style={styles.floatLeft}
        disabled={props.disabled || props.isCreated}
      />

      <TextInput
        style={styles.inputWidth}
        name="title"
        source="title"
        {...props}
        required={true}
        validate={required}
        margin="dense"
      />

      <CustomField
        component={ReferenceInput}
        source="assign_to_team_member"
        name="assign_to_team_member"
        {...props}
        reference="roles"
        allowEmpty
        style={styles.inputWidth}
      >
        <SelectInput sortChoices={true} optionText="display" optionValue="url" />
      </CustomField>

      <CustomField
        component={TextInput}
        style={styles.inputWidth}
        name="lead_source"
        source="lead_source"
        {...props}
        disabled={props.record?.format === 3}
      />

      <div style={{ clear: 'both' }} />

      {(format === 1 || format === 2) && (
        <>
          <CustomField
            component={TextInput}
            name="form_heading"
            style={styles.inputWidth}
            source="form_heading"
            required={true}
            validate={required}
            {...props}
          />
          <CustomField
            component={TextInput}
            name="form_subheading"
            style={styles.inputWidth}
            source="form_subheading"
            {...props}
          />
          <CustomField
            component={TextInput}
            name="submit_button_label"
            style={styles.inputWidth}
            source="submit_button_label"
            {...props}
          />
          <CustomField
            component={TextInput}
            multiline={true}
            style={{ width: '100%' }}
            name="thank_you_message"
            source="thank_you_message"
            {...props}
          />

          <CustomField
            component={SelectInput}
            choices={getLanguageChoices()}
            style={styles.inputWidth}
            value="en"
            name="language"
            source="language"
            {...props}
          />

          {/* <CustomField
        component={CheckboxInput}
        name={'disable_form_localisation'}
        source={'disable_form_localisation'}
        elStyle={{ margin: '16px 0px 20px', maxWidth: '100%' }}
        label={translate('Disable Country Filtering')}
        helperText={translate(
          'By toggling this on, the lead capture form will be able to search for countries outside of the country set in your Organisation.'
        )}
      /> */}

          <h1>{translate('Input Fields')}</h1>
          {HTML_FIELDS.map((source, i) => (
            <CustomField
              key={i}
              component={SelectInput}
              name={source.id}
              source={source.id}
              label={source.label}
              choices={optional_field_choices}
              style={styles.floatLeft}
              disabled={props.disabled}
            />
          ))}
          <div style={{ clear: 'both' }} />
        </>
      )}

      {props.enableLeadDistribution && (
        <CustomField
          component={CheckboxInput}
          name={'enable_lead_recipients'}
          source={'enable_lead_recipients'}
          elStyle={{ margin: '16px 0px 20px', maxWidth: '100%' }}
          label={translate('Enable Lead Distribution')}
          helperText={translate(
            'By toggling this on, the lead capture form will distribute leads to other organisations.'
          )}
          disabled={props.disabled}
        />
      )}
      {!props.disabled && (
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (formData && Boolean(formData.enable_lead_recipients)) {
              if (!formData.id) {
                return <p>(Please create the form before you can manage lead recipients)</p>
              } else {
                return (
                  <a disabled={true} href={'#/lead_recipients?filter=%7B"lead_capture_form"%3A' + formData.id + '%7D'}>
                    Manage Lead Recipients
                  </a>
                )
              }
            } else {
              return null
            }
          }}
        </FormDataConsumer>
      )}

      {(format === 1 || format === 2) && (
        <>
          <h1 style={{ marginTop: 30 }}>{translate('Spam Protection (Optional)')}</h1>
          <p>{translate('To enable Google reCAPTCHA v3 create a reCAPTCHA and enter details here')}</p>
          <CustomField
            component={TextInput}
            style={styles.inputWidth}
            name="recaptcha_site_key"
            source="recaptcha_site_key"
            {...props}
          />
          <CustomField
            component={TextInput}
            style={styles.inputWidth}
            name="recaptcha_secret_key"
            source="recaptcha_secret_key"
            {...props}
          />

          <h1>{translate('Embed Code')}</h1>
          <p>{translate('Add this HTML code to your website, blog, etc.')}</p>
          <div class="code-block" style={{ maxWidth: 700, wordWrap: 'break-word' }}>
            {getCode(orgId, props.record, translate)}
          </div>
        </>
      )}

      {format === 3 && (
        <>
          <h1>{translate('Link Solkunder with OpenSolar')}</h1>
          <p>{translate('Click this button to link your Solkunder account with OpenSolar')}</p>
          <ComponentVersionsInherit versions={ComponentVersions_2_1}>
            <SolkunderLinkButton record={props.record} />
          </ComponentVersionsInherit>
          <p className="small">{translate('OR')}</p>
          <p>{translate('Manually enter these parameters on your Solkunder account')}</p>
          <div class="code-block" style={{ maxWidth: 700 }}>
            {getCode(orgId, props.record, translate)}
          </div>
        </>
      )}
    </div>
  )
}

export const LeadCaptureFormEdit = connect(
  (state) => ({
    enableLeadDistribution: Boolean(getOrgFromState(state) && getOrgFromState(state).enable_lead_distribution),
  }),
  {}
)(({ accessRights: { allowView, allowCreate, allowEdit, allowDelete }, ...props }) => {
  let withToolBar = allowEdit ? {} : { toolbar: false }

  return (
    <Edit {...props} hasDelete={allowDelete}>
      <SimpleForm redirect={false} {...withToolBar} disabled={!allowEdit}>
        <Inputs {...props} disabled={!allowEdit} isCreated={true} />
      </SimpleForm>
    </Edit>
  )
})

export const LeadCaptureFormCreate = connect(
  (state) => ({
    enableLeadDistribution: Boolean(getOrgFromState(state) && getOrgFromState(state).enable_lead_distribution),
  }),
  {}
)((props) => {
  const orgId = useSelector(authSelectors.getOrgId)

  useEffect(() => {
    if (props.optionId === 3) {
      logAmplitudeEvent('solkunder_on_lead_capture_create', { org_id: orgId })
      return
    }
    logAmplitudeEvent('on_lead_capture_create', { org_id: orgId, option_id: props.optionId })
  }, [])

  return (
    <Create {...props} component={props.noBorder ? Box : undefined} style={props.noBorder ? { padding: 0 } : {}}>
      <SimpleForm
        toolbar={props.toolbar}
        defaultValue={{
          format: props.optionId ?? 1,
          last_name_setting: 1,
          phone_setting: 2,
          notes_setting: 2,
          roof_type_setting: 1,
          number_of_phases_setting: 1,
          captcha_setting: 1,
          language: getLocale(),
          lead_source: props.optionId === 3 ? 'Solkunder' : 'Lead Capture Form',
          enable_lead_recipients: false,
        }}
      >
        <Paper elevation={0}>
          <Inputs {...props} isCreated={false} />
        </Paper>
      </SimpleForm>
    </Create>
  )
})

LeadCaptureFormCreate.propTypes = {
  optionId: PropTypes.number,
}

import InfoTooltip from 'elements/tooltip/InfoTooltip'
import { styled } from 'opensolar-ui'
import { useMemo } from 'react'
import { useField } from 'react-final-form'
import { DevicesArrayField } from './DevicesArrayField'
import { DeviceSearchField } from './DeviceSearchField'
import { EnaDateField, EnaRadioGroup, EnaSelectField, EnaTextField } from './EnaFields'
import { FilesField } from './FilesField'

export const DiscriminatorField: React.FC<any> = ({ schema, parentPath, fieldName, inputData }) => {
  const discriminatorOptions = Object.keys(schema.discriminator.mapping)
  const propertyName = schema?.discriminator.propertyName
  const sectionPath = `${parentPath}.${fieldName}.${propertyName}`

  const {
    input: { value: fieldVal },
    meta,
  } = useField(sectionPath, { subscription: { value: true } })

  const schemaSelected = useMemo(() => {
    const definition = schema.discriminator.mapping?.[fieldVal]
    const schemaId = definition && fieldVal ? definition.split('/').pop() : null
    const schemaSelected = schema?.oneOf.find((s) => s.title === schemaId)
    return schemaSelected
  }, [fieldVal, schema])

  return (
    <div>
      <EnaSelectField parsedSchema={{ ...inputData, selectOptions: discriminatorOptions }} path={sectionPath} />
      <SchemaFields schema={schemaSelected} required fieldName={`${parentPath}.${fieldName}`} />
    </div>
  )
}

const InputByType: React.FC<any> = (props) => {
  const { schema, fieldName, parentPath } = props
  const path = parentPath ? `${parentPath}.${fieldName}` : fieldName
  const inputData = parseInputSchema(schema) || ({} as any)
  const { displayIf } = schema?.json_schema_extra || {}

  const {
    input: { value: sectionValues },
  } = useField(parentPath, { subscription: { value: true, dirty: true, touched: true } })

  const isHidden = displayIf?.property ? sectionValues?.[displayIf?.property] !== displayIf?.value : false

  if (isHidden) {
    return <></>
  }

  if (inputData?.inputType === 'SELECT INPUT') {
    return <EnaSelectField parsedSchema={inputData} path={path} />
  } else if (inputData?.inputType === 'RADIO INPUT') {
    return <EnaRadioGroup parsedSchema={inputData} path={path} />
  } else if (inputData?.inputType === 'TEXT INPUT' || ['installerEmail', 'installerName'].includes(fieldName)) {
    return <EnaTextField parsedSchema={inputData} path={path} />
  } else if (inputData?.inputType === 'DATE INPUT') {
    return <EnaDateField parsedSchema={inputData} path={path} />
  } else if (['exportLimitSLDAttachment', 'cutOutImages', 'additionalAttachments'].includes(fieldName)) {
    return <FilesField {...props} path={path} key={fieldName} required />
  } else if (schema?.discriminator) {
    return <DiscriminatorField {...props} inputData={inputData} />
  } else if (['existingDevices', 'devicesToInstall'].includes(fieldName)) {
    return <DevicesArrayField schema={schema} fieldName={fieldName} key={fieldName} />
  } else if (inputData?.inputType === 'G100_SEARCH_RECOGNISED_INPUT_01') {
    return <DeviceSearchField {...props} deviceType={'G100_DEVICE'} enaLctDataset={'TTR'} key={fieldName} />
  } else {
    return <></>
  }
}

export const SchemaFields: React.FC<any> = (props) => {
  const { schema, fieldName } = props

  const innerFields = useMemo(() => {
    const innerFieldsSchemas = schema?.properties || {}
    return Object.keys(innerFieldsSchemas).map((_fieldName) => {
      const _schema = innerFieldsSchemas?.[_fieldName]
      return { schema: _schema, fieldName: _fieldName, parentPath: fieldName }
    })
  }, [schema, fieldName])

  if (!schema || !fieldName) {
    return <></>
  }

  return (
    <div id={fieldName}>
      <InputByType {...props} />
      {innerFields.map((f) => (
        <SchemaFields key={f.fieldName} {...f} />
      ))}
    </div>
  )
}

export interface EnaInputProps {
  fieldSchema: EnaInputData | null
}

export interface EnaInputData {
  title: string
  radioOptions?: Record<string, any>
  selectOptions?: any[]
  helperText?: string | null
  required: boolean
  tooltip?: string | null
  placeholderText?: string | null
  hidden: boolean
  errorMessage: string | null
  validation: string | null
  dataType?: string
  displayIf: object | null
  inputType: string
  path?: string
  disabled?: boolean
}

export const parseInputSchema = (schema): EnaInputData | null => {
  if (!schema) {
    return null
  }
  const { title = '', json_schema_extra = {}, type: dataType } = schema

  const {
    options,
    title: helperText,
    required,
    tooltip,
    placeholderText = '',
    visible,
    title: customTitle,
    type,
    displayIf,
    pattern = {},
  } = json_schema_extra

  return {
    title: customTitle !== 'User account derived input' ? customTitle : title,
    radioOptions: options || null,
    selectOptions: (options && options?.selectOptions) || null,
    helperText,
    required,
    tooltip,
    placeholderText,
    hidden: !visible,
    errorMessage: pattern?.message || null,
    validation: pattern?.value || null,
    dataType,
    inputType: type,
    displayIf,
  }
}

const StyledTooltip = styled(InfoTooltip)(({ theme }) => ({
  fontSize: 19,
  position: 'absolute',
  right: 0,
  cursor: 'help',
}))

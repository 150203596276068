// @ts-nocheck
import { InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import React, { useEffect, useState } from 'react'
import { useNotify } from 'react-admin'
import { useSelector } from 'react-redux'
import restClient from 'restClient'

type PropTypes = {}

const PageConfigSave: React.FunctionComponent<PropTypes> = (props) => {
  const [mode, setMode] = useState<'create' | 'edit' | undefined>(undefined)
  const [createName, setCreateName] = useState<string | undefined>(undefined)
  const [createJSON, setCreateJSON] = useState<string | undefined>(undefined)
  const [editName, setEditName] = useState<string | undefined>(undefined)
  const [editJSON, setEditJSON] = useState<string | undefined>(undefined)
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const [nameOptions, setNameOptions] = useState<string[]>([])
  const [saving, setSaving] = useState<boolean>(false)
  const [fetching, setFetching] = useState<boolean>(false)

  const notify = useNotify()

  useEffect(() => {
    if (mode === 'edit') fetchNameOptions()
  }, [mode])

  useEffect(() => {
    if (createJSON || editJSON) {
      let jsonToValidate = createJSON ? createJSON : editJSON
      try {
        JSON.parse(jsonToValidate)
        if (errorMsg) setErrorMsg(undefined)
      } catch (ex) {
        setErrorMsg('Invalid JSON')
      }
    }
  }, [createJSON, editJSON])

  useEffect(() => {
    if (createName) {
      if (createName.length > 170) setErrorMsg('Above character limit')
      else if (errorMsg === 'Above character limit') setErrorMsg(undefined)
    }
  }, [createName])

  useEffect(() => {
    if (editName) fetchData()
  }, [editName])

  const isStaff: boolean = useSelector(authSelectors.getIsStaff)

  if (!isStaff) {
    return (
      <div>
        <h2>Page Not Found</h2>
      </div>
    )
  }

  const fetchNameOptions = () => {
    setFetching(true)
    const API_URL = window.API_ROOT + '/api'
    const restClientInstance = restClient(API_URL)
    restClientInstance('CUSTOM_GET', 'custom', {
      url: 'feature_config/names/',
    })
      .then((res: any) => {
        if (res.data.status === 'ok') {
          setNameOptions(res.data.names)
        } else if (res.data.status === 'error') {
          notify('Unable to fetch existing config record names', 'warning')
        }
      })
      .catch((err: any) => {
        console.log(err, err)
      })
      .finally(() => setFetching(false))
  }

  const fetchData = () => {
    setFetching(true)
    const API_URL = window.API_ROOT + '/api'
    const restClientInstance = restClient(API_URL)
    restClientInstance('CUSTOM_GET', 'custom', {
      url: `feature_config/?name=${editName}`,
    })
      .then((res: any) => {
        if (res.data.status === 'ok') {
          setEditJSON(JSON.stringify(JSON.parse(res.data.config_data), undefined, 4))
        } else if (res.data.status === 'error') {
          notify('Unable to fetch existing config data', 'warning')
        }
      })
      .catch((err: any) => {
        console.log(err, err)
      })
      .finally(() => setFetching(false))
  }

  const createNew = () => {
    setSaving(true)
    const API_URL = window.API_ROOT + '/api'
    const restClientInstance = restClient(API_URL)
    restClientInstance('CUSTOM_POST', 'custom', {
      url: 'feature_config/',
      data: {
        name: createName,
        json: createJSON,
      },
    })
      .then((res: any) => {
        if (res.data.status === 'ok') {
          notify('Your Feature Config record has been saved', 'success')
          setMode(undefined)
          setCreateJSON(undefined)
          setCreateName(undefined)
        } else if (res.data.status === 'error') {
          notify(res.data.message, 'warning')
        }
      })
      .catch((err: any) => {
        console.log(err, err)
      })
      .finally(() => setSaving(false))
  }

  const saveEdit = () => {
    setSaving(true)
    const API_URL = window.API_ROOT + '/api'
    const restClientInstance = restClient(API_URL)
    restClientInstance('CUSTOM_PUT', 'custom', {
      url: 'feature_config/',
      data: {
        name: editName,
        json: editJSON,
      },
    })
      .then((res: any) => {
        if (res.data.status === 'ok') {
          notify('Your Feature Config record has been updated', 'success')
          setMode(undefined)
          setEditJSON(undefined)
          setEditName(undefined)
        } else if (res.data.status === 'error') {
          notify(res.data.message, 'warning')
        }
      })
      .catch((err: any) => {
        console.log(err, err)
      })
      .finally(() => setSaving(false))
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <h2>Configuration Records</h2>
      {!mode && (
        <div>
          <p>Would you like to create a new Feature Config or edit an existing one?</p>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <div style={{ margin: '20px' }}>
              <ProUXButton onClick={() => setMode('create')} label="Create New" type="primary" />
            </div>
            <div style={{ margin: '20px' }}>
              <ProUXButton onClick={() => setMode('edit')} label="Edit Existing" type="primary" />
            </div>
          </div>
        </div>
      )}
      {mode === 'create' && (
        <div style={{ width: '800px' }}>
          <h4>Create a New Feature Config Record</h4>
          <p>Be careful when you choose the name, it cannot be edited once it's created</p>
          <TextField
            onChange={(e) => setCreateName(e.target.value)}
            value={createName}
            label="Config Record Name"
            fullWidth
          />
          <TextField
            onChange={(e) => setCreateJSON(e.target.value)}
            value={createJSON}
            label="Config JSON"
            fullWidth
            multiline={true}
            rows={30}
            style={{ border: '1px solid #ececec', marginTop: '20px' }}
          />
          {errorMsg && <p style={{ color: 'red' }}>{errorMsg}</p>}
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div>
              <ProUXButton
                label="Save"
                onClick={createNew}
                disabled={!createName || !createJSON || !!errorMsg}
                loading={saving}
                type="primary"
              />
            </div>
            <div>
              <ProUXButton type="secondary" label="Cancel" onClick={() => setMode(undefined)} />
            </div>
          </div>
        </div>
      )}
      {mode === 'edit' && (
        <div style={{ width: '800px' }}>
          <h4>Edit and Existing Feature Config Record</h4>
          <InputLabel id="demo-simple-select-label">Config Name</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={editName}
            label="Config Name"
            onChange={(e) => setEditName(e.target.value)}
            fullWidth={true}
          >
            {nameOptions?.map((existingName: string) => {
              return (
                <MenuItem key={existingName} value={existingName}>
                  {existingName}
                </MenuItem>
              )
            })}
          </Select>
          <TextField
            onChange={(e) => setEditJSON(e.target.value)}
            value={editJSON}
            label="Config JSON"
            fullWidth
            multiline={true}
            rows={30}
            style={{ border: '1px solid #ececec', marginTop: '20px' }}
          />
          {errorMsg && <p style={{ color: 'red' }}>{errorMsg}</p>}
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div>
              <ProUXButton
                type="primary"
                label="Save"
                onClick={saveEdit}
                loading={saving}
                disabled={!editName || !editJSON || !!errorMsg}
              />
            </div>
            <div>
              <ProUXButton type="secondary" label="Cancel" onClick={() => setMode(undefined)} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default PageConfigSave

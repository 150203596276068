import { ModuleGridType } from 'types/global'
import type { BasicAccessorConfig } from './types'

const setter = ({ moduleGrid, value }: { moduleGrid: ModuleGridType; value: boolean }) => {
  const isUsingTiltRacks = moduleGrid.isUsingTiltRacks()
  if (value === true && !isUsingTiltRacks) {
    moduleGrid.panelTiltOverride = null
  }
  window.editor.execute(
    new window.SetPanelConfigurationCommand(
      moduleGrid,
      'slopeAuto',
      value,
      window.Utils.generateCommandUUIDOrUseGlobal()
    )
  )
}

const getter = (moduleGrid: ModuleGridType): boolean => {
  return moduleGrid.slopeAuto
}

export const slopeAutoAccessorConfig: BasicAccessorConfig<boolean> = {
  get: getter,
  set: setter,
}

import ViewComfyRoundedIcon from '@material-ui/icons/ViewComfyRounded'
import { useStudioSignalsLazy } from 'Studio/signals/useStudioSignalsLazy'
import { Button } from 'opensolar-ui'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import useTranslateParse from '../../../util/useTranslateParse'
import { ToolTipPropsType, TourConfigType } from '../types'
import DefaultTooltip from './DefaultToolTip'

const SolarTouchToolTip = (props: ToolTipPropsType) => {
  const { handleNext, handlePrev } = props
  const hasPanels = window.editor?.filter('type', 'OsModuleGrid').length || 0
  const [showMoreMessage, setShowMoreMessage] = useState(!hasPanels)
  const translate = useTranslate()
  const translateParse = useTranslateParse()

  const [panelQuantity, setPanelQuantity] = useState(window.editor?.selectedSystem?.moduleQuantity() || 0)

  useStudioSignalsLazy(() => {
    setPanelQuantity(window.editor?.selectedSystem.moduleQuantity() || 0)
  }, ['objectChanged'])

  const is3D = window.MapData?.is3D(window.MapHelper?.activeMapInstance?.mapData)
  useEffect(() => {
    if (hasPanels) {
      setShowMoreMessage(false)
    }
  }, [hasPanels])
  const handleClick = useCallback(() => {
    handleNext && handleNext()
  }, [])
  return (
    <DefaultTooltip
      title={is3D ? translate('Add Panels') : translate('Draw Panels')}
      // onClose={handleCancel}
      icon={ViewComfyRoundedIcon}
      content={
        <div>
          {is3D ? (
            <div>
              {showMoreMessage && (
                <div>
                  {translateParse("When you're ready to add your first array, click <b>+ PANELS<b>.", {
                    b: (label: string) => <b>{label}</b>,
                  })}
                </div>
              )}
              <div style={{ marginBottom: 10 }}>
                {!showMoreMessage && (
                  <div>
                    {translate(
                      'Move your mouse to the location where you want to place the first panel (notice how the module instantly snaps to the correct pitch and azimuth as it moves across the screen!) and then click and hold the left mouse button, and drag to place as many panels as you want.'
                    )}
                  </div>
                )}
              </div>
              {showMoreMessage && (
                <div style={{ marginBottom: 10 }}>
                  {translate(
                    "We'll use the default hardware on this design. On your next design, you'll be able to select any manufacturer and model you want, and update your defaults."
                  )}
                </div>
              )}
            </div>
          ) : (
            <div>
              {' '}
              <div style={{ marginBottom: 10 }}>
                {translateParse('You can add as many panel groups as you want, click <b>+ PANELS<b>.', {
                  b: (label: string) => <b>{label}</b>,
                })}
              </div>
              <div style={{ marginBottom: 10 }}>
                {translate(
                  'Move your mouse to the location where you want to place the first panel and then click and hold the left mouse button, and drag to place as many panels as you want.'
                )}
              </div>
            </div>
          )}
          <div style={{ marginTop: 10 }}>
            {translateParse('Once done, click <b>Next<b> to go to next step.', {
              b: (label: string) => <b>{label}</b>,
            })}
          </div>
        </div>
      }
      image={
        <div style={{ padding: '0 5px' }}>
          {is3D ? (
            <img
              style={{ width: 390, height: 250 }}
              src={`${window.PUBLIC_URL}/images/draw-panels-3d.gif`}
              alt="Draw panels 3D"
            />
          ) : (
            <img
              style={{ width: 390, height: 250 }}
              src={`${window.PUBLIC_URL}/images/draw-panels-2d.gif`}
              alt="Draw panels 2D"
            />
          )}
        </div>
      }
      actions={
        <>
          <Button
            onClick={() => handlePrev && handlePrev()}
            onTouchStart={(e) => {
              e.stopPropagation()
              if (handlePrev) {
                handlePrev()
              }
            }}
          >
            <span>{translate('Back')}</span>
          </Button>
          <Button
            //always show next button, hasPanels not working in touch device
            disabled={panelQuantity === 0}
            onClick={handleClick}
            onTouchStart={(e) => {
              e.stopPropagation()
              handleClick()
            }}
          >
            <span>{translate('Next')}</span>
          </Button>
        </>
      }
    />
  )
}

const FirstSolarTouchToolTip = (props: ToolTipPropsType) => {
  const { handleNext } = props
  const translate = useTranslate()
  const translateParse = useTranslateParse()

  return (
    <DefaultTooltip
      title={translate('DRAW PANELS')}
      // onClose={handleCancel}
      content={
        <div>
          <div>{translate('Click and hold the left mouse button, and drag to place as many panels as you want.')}</div>
          <div style={{ marginTop: 10 }}>
            {translateParse('Once done, click <b>Next<b> to go to next step.', {
              b: (label: string) => <b>{label}</b>,
            })}
          </div>
        </div>
      }
      // image={
      //   <img
      //     style={{ width: '100%' }}
      //     src={
      //       'https://images.squarespace-cdn.com/content/v1/5cd35e87840b16fb95c01c7a/1558047897204-04ORZ8ISFMI1DS8QY754/1st%2Bimage.jpg'
      //     }
      //     alt="Draw Panel GIF"
      //   />
      // }
      actions={
        <>
          <Button
            onClick={() => handleNext && handleNext()}
            onTouchStart={(e) => {
              e.stopPropagation()
              if (handleNext) {
                handleNext()
              }
            }}
          >
            <span>{translate('Next')}</span>
          </Button>
        </>
      }
    />
  )
}

export const FirstSolarTouch: TourConfigType = {
  spotlights: [{ targetId: 'snapshot-crop-marks' }],
  tooltips: [
    {
      targetId: 'snapshot-crop-marks',
      placement: 'left',
      component: FirstSolarTouchToolTip,
      options: { style: { width: 350 } },
    },
  ],
}

export const SolarTouch: TourConfigType = {
  spotlights: [{ targetId: 'snapshot-crop-marks' }, { targetId: 'add-panel-button' }],
  tooltips: [
    {
      targetId: 'snapshot-crop-marks',
      placement: 'left',
      hideArrow: true,
      component: SolarTouchToolTip,
    },
  ],
}

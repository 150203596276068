var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/styles';
var useStyles = makeStyles({
    root: {
        marginLeft: 10,
    },
    row: {},
    label: {
        display: 'inline-block',
    },
    value: {
        display: 'inline-block',
        color: 'lightblue',
    },
}, { name: 'PropsTable' });
export var PropsTable = function (_a) {
    var propDefs = _a.propDefs, props = _a.props;
    var classes = useStyles();
    return (_jsx("div", __assign({ className: classes.root }, { children: Object.entries(propDefs).map(function (_a) {
            var propName = _a[0], propDef = _a[1];
            return propDef.hidden ? null : (_jsxs("div", __assign({ className: classes.row }, { children: [_jsx("div", __assign({ className: classes.label }, { children: propName + '=' })), _jsx("div", __assign({ className: classes.value }, { children: (props === null || props === void 0 ? void 0 : props.hasOwnProperty(propName))
                            ? JSON.stringify(props[propName])
                            : propDef.default !== undefined
                                ? JSON.stringify(propDef.default) + ' (using default)'
                                : 'undefined' }))] }), propName));
        }) })));
};

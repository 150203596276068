import { useMemo } from 'react'
import { FormTransform_mcs } from '../transform/FormTransform_mcs'
import { FormTransform } from '../types'

const CustomTransforms: Record<string, FormTransform> = {
  _mcs: FormTransform_mcs,
}

const useCustomFormTransforms = ({ transformsConfig }: { transformsConfig?: string[] }) => {
  const transforms: FormTransform[] = useMemo(() => {
    if (!transformsConfig) return []

    const ret: FormTransform[] = []
    for (const name of transformsConfig) {
      const transform = CustomTransforms[name]
      if (!transform) {
        console.error('Failed to locate custom transform with ID: ', name)
        continue
      }
      ret.push(transform)
    }

    return ret
  }, [])

  return transforms
}

export default useCustomFormTransforms

import React from 'react'
import { useTranslate } from 'react-admin'
import { dialogStyles } from '../styles'
import { errorMap } from './validateSchedule'

const KeyLegend = ({ showAlert }) => {
  const classes = dialogStyles()
  const translate = useTranslate()
  return (
    <div>
      <h4 className={classes.removeMargin}>{translate('Key')}</h4>
      <div className={classes.row}>
        <p className={`${classes.square} ${classes.bgUnavailable}`}></p>
        <p>{translate('Unavailable Time Blocks')}</p>
      </div>
      <div className={classes.row}>
        <p className={`${classes.square} ${classes.bgAvailable}`}></p>
        <p>{translate('Available Time Blocks')}</p>
      </div>
      <div className={classes.row}>
        <p className={`${classes.square} ${classes.bgAssigned}`}></p>
        <p>{translate('Assigned Time Blocks')}</p>
      </div>
      {showAlert.includes(errorMap.overlapping) && (
        <div className={classes.row}>
          <p className={`${classes.square} ${classes.bgOverlapping}`}></p>
          <p>{translate('Overlapping Time Blocks')}</p>
        </div>
      )}
    </div>
  )
}

export default KeyLegend

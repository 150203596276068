export const SET_INLINE_FORM = 'SET_INLINE_FORM'

export const setInlineForm = initialValues => ({
  type: SET_INLINE_FORM,
  payload: initialValues,
})

export default function reducer(previousState = null, { type, payload }) {
  if (!previousState) {
    previousState = null
  }

  if (type === SET_INLINE_FORM) {
    return (previousState = payload)
  } else if (type === '@@router/LOCATION_CHANGE') {
    //Clear on any location change, including a successful form submission
    return (previousState = null)
  }

  return previousState
}

import { useMemo } from 'react'
import { useNotify } from 'react-admin'
import restClient from 'restClient'
import VtacService from 'services/vtac/VtacService'

const restClientInstance = restClient(window.API_ROOT + '/api')
const useVtacService = (): VtacService => {
  const notify = useNotify()
  const vtacService = useMemo(() => new VtacService(restClientInstance, notify), [restClientInstance, notify])

  return vtacService
}

export default useVtacService

import { authSelectors } from 'ducks/auth'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { PromoFilters } from '../utils'

const unAuthenticatedFiltersData: PromoFilters = {
  org: undefined,
  user: undefined,
  role: undefined,
  orgCountry: '',
  isAdmin: undefined,
  requireMeetsOne: true,
  acceptedTermsDate: undefined,
}

const useInAppPromoFiltersData = (): PromoFilters => {
  const orgCountry = useSelector(authSelectors.getCurrentOrgCountry) || ''
  const role = useSelector(authSelectors.getCurrentRole)
  const user = useSelector(authSelectors.getCurrentUser)
  const isAdmin: boolean = useSelector(authSelectors.getIsAdmin)
  const authStatus = useSelector(authSelectors.getAuthStatus)
  const org = useSelector(authSelectors.getCurrentOrg)
  const acceptedTermsDate = useSelector(authSelectors.getAcceptedTermsDate)

  const filtersData = useMemo(
    () => ({ org, user, role, orgCountry, isAdmin, requireMeetsOne: true, acceptedTermsDate }),
    [org, user, role, orgCountry, isAdmin, acceptedTermsDate]
  )

  if (authStatus !== 'authenticated') {
    return unAuthenticatedFiltersData
  }

  return filtersData
}

export default useInAppPromoFiltersData

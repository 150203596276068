import { makeStyles } from '@material-ui/core'
import ActionDelete from '@material-ui/icons/DeleteOutlined'
import { Button } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import { SystemObjectType } from 'types/systems'

type PropTypes = {
  system: SystemObjectType
  disabled: boolean
}
const useStyles = makeStyles((theme: any) => ({
  buttonWrapper: {
    width: '100%',
    margin: '10px 0',
    textAlign: 'center',
  },
  buttonIcon: {
    maxHeight: 35,
    maxWidth: 35,
    marginLeft: 0,
  },
  button: {
    minWidth: 190,
    width: 190,
    height: 32,
    color: 'rgb(0,0,0)',
    background: '#F6F6F6',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    '&:hover': {
      background: 'rgba(250,250,250,0.9)',
    },
  },
}))

const handleDeleteSystem = (system: SystemObjectType) => {
  var canDeleteResult = system.canDelete ? system.canDelete() : true

  if (canDeleteResult !== true) {
    if (canDeleteResult === false) {
      window.Designer.showNotification('Unable to delete', 'warning')
    } else {
      window.Designer.showNotification(canDeleteResult, 'warning')
    }
    return
  }

  var confirmationPrompt = system.confirmBeforeDelete ? system.confirmBeforeDelete() : 'Delete ' + system.name + '?'

  if (window.confirm(confirmationPrompt) === false) return

  var parent = system.parent
  if (parent !== null || system.onDeleteOverride) window.editor.deleteObject(system)
}

const DeleteSystemButton: React.FC<PropTypes> = ({ system, disabled = false }) => {
  const translate = useTranslate()
  const classes = useStyles()
  return (
    <div className={classes.buttonWrapper}>
      <Button
        startIcon={<ActionDelete className={classes.buttonIcon} />}
        classes={{ root: classes.button }}
        onClick={() => handleDeleteSystem(system)}
        disabled={disabled}
      >
        <span>{translate('Delete System')}</span>
      </Button>
    </div>
  )
}

export default DeleteSystemButton

import { Typography } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import React, { memo, useContext, useState } from 'react'
import { useNotify } from 'react-admin'
import Skeleton from 'react-loading-skeleton'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { CheckoutFormContext, CheckoutPresenterContext, QuoteContext } from './SegenCheckoutProvider'

const useStyles = makeOpenSolarStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  clickableLink: {
    textDecoration: 'underline',
    color: '#1751D0',
    cursor: 'pointer',
  },
  radioGroup: {
    marginLeft: 50,
  },
}))

const ShippingSchedule = () => {
  const [deliveryMethod, setDeliveryMethod] = useState('Cheapest')
  const {
    address,
    hasLiftingEquipment,
    deliveryMethodPreference,
    segenOptions: { deliveryMethodOptions },
  } = useContext(CheckoutFormContext)
  const { loading, quote } = useContext(QuoteContext)
  const segenCheckoutPresenter = useContext(CheckoutPresenterContext)
  const notify = useNotify()
  const classes = useStyles()

  const handleChangeDeliveryMethod = (event: React.ChangeEvent<HTMLInputElement>) => {
    const oldMethod = deliveryMethodPreference
    const method = (event.target as HTMLInputElement).value
    logAmplitudeEvent('hardware_segen_shipping', {
      action: 'clicked',
      context: 'delivery_method_radio',
      value: method,
    })
    setDeliveryMethod(method)
    if (!quote) {
      return
    }
    const onSuccess = () => {
      logAmplitudeEvent('hardware_segen_shipping', {
        action: 'updated',
        context: 'delivery_method',
      })
      setDeliveryMethod(method)
    }
    const onFailed = () => {
      notify('Failed to update shipping method', 'error')
      setDeliveryMethod(oldMethod)
    }
    segenCheckoutPresenter?.updateShippingDetails({
      reference: quote.reference,
      shippingDetails: {
        address,
        deliveryMethodPreference: method,
        hasLiftingEquipment,
      },
      onSuccess,
      onFailed,
    })
  }

  if (deliveryMethodOptions == null) {
    return <Skeleton width="100%" height="80px" />
  }

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Choose your delivery day
      </Typography>
      <FormControl className={classes.radioGroup} component="fieldset">
        <RadioGroup
          aria-label="delivery-method"
          name="delivery-method"
          value={deliveryMethod}
          onChange={handleChangeDeliveryMethod}
        >
          {deliveryMethodOptions.map((method: string) => {
            return <FormControlLabel key={method} value={method} control={<Radio />} label={method} />
          })}
        </RadioGroup>
      </FormControl>
    </div>
  )
}

export default memo(ShippingSchedule)

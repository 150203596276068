import { useSoldSystem } from 'projectSections/hooks/useSoldSystem'
import ProjectWarningBox from 'projectSections/sections/common/ProjectWarningBox'
import React, { useCallback, useState } from 'react'
import { ProjectErrorType, SeverityType } from 'reducer/project/projectErrorsReducer'
import { ProjectType } from 'types/projects'
import { doNotTranslate } from 'util/misc'
import IntegrationRow from '../IntegrationRow'

const getValidationMessage = (isSold: Boolean) => {
  if (!isSold) {
    return 'Project must be sold before creating a permit pack'
  }
  return undefined
}
interface PropTypes {
  project: ProjectType
}

const IronRidgeAtlasCTA: React.FC<PropTypes> = ({ project }) => {
  const [highestSeverity, setHighestSeverity] = useState<SeverityType | undefined>(undefined)
  const soldSystem = useSoldSystem()

  const soldSystemFilter = useCallback(
    (error: ProjectErrorType) => {
      if (error.systemId === undefined) {
        // If error is not related to a specific system
        return true
      } else if (soldSystem?.uuid) {
        // If error is related to a specific system, only show errors related to the sold system
        return error.systemId === soldSystem.uuid
      }
      return true
    },
    [soldSystem]
  )

  const automateFlow = () => {
    window.open(
      `${window.LYRA_BASE_URL}/permit_packs/new?open_solar_project_id=${project.id}&open_solar_system_uuid=${soldSystem?.uuid}`,
      '_blank'
    )
  }

  const isButtonDisabled = !soldSystem || !project.is_residential || highestSeverity === 'error'

  return (
    <IntegrationRow
      title={'Atlas'}
      description={doNotTranslate(
        'Quickly and easily create a permit pack using our direct integrations to get a complete bill of materials for this project'
      )}
      WarningMsgComponent={
        !!soldSystem && (
          <ProjectWarningBox
            sources={['lyra-export']}
            filter={soldSystemFilter}
            setHighestSeverity={setHighestSeverity}
            maxShown={1}
          />
        )
      }
      tooltip={getValidationMessage(!!soldSystem)}
      btnLabel={doNotTranslate('Create Permit Pack')}
      disabled={isButtonDisabled}
      onClick={() => {
        automateFlow()
      }}
      logo={<img src={`${window.PUBLIC_URL}/images/atlas-logo-only.svg`} height={32} width={46} />}
    />
  )
}

export default IronRidgeAtlasCTA

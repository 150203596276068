import { MenuItem, Select, styled } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDataProvider } from 'react-admin'

export const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}))

interface DetachedSelectInputProps {
  listName: string
  filterType: string
  onChange: (e: any) => void
  perPage?: number
  orderField?: string
  order?: 'ASC' | 'DESC'
  optionValue?: string
  defaultValue?: string
  disabledItems?: string[]
}

export const DetachedSelectInput: React.FC<DetachedSelectInputProps> = ({
  listName,
  filterType,
  onChange,
  perPage = 100,
  orderField = 'title',
  order = 'ASC',
  optionValue = 'title',
  defaultValue = '',
  disabledItems = [],
}) => {
  const [options, setOptions] = useState<any[]>([])
  const [selectedValue, setSelectedValue] = useState<string | number>(defaultValue)
  const dataProvider = useDataProvider()

  useEffect(() => {
    dataProvider
      .getList(listName, {
        pagination: { page: 1, perPage },
        sort: { field: orderField, order },
        filter: { type: filterType },
      })
      .then(({ data }) => {
        setOptions(data)
      })
      .catch((error) => console.error(error))
  }, [dataProvider, listName, filterType, perPage, orderField, order])

  const handleChange = (e) => {
    const value = e.target.value
    setSelectedValue(value)
    onChange(value)
  }

  return (
    <StyledSelect
      fullWidth
      value={selectedValue}
      defaultValue={defaultValue}
      onChange={handleChange}
      variant="outlined"
      displayEmpty
    >
      {options.map((option) => {
        return (
          <MenuItem key={option.id} disabled={disabledItems.includes(option[optionValue])} value={option[optionValue]}>
            {option.title}
          </MenuItem>
        )
      })}
    </StyledSelect>
  )
}

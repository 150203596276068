import React from 'react'
import { useTranslate, TextInput } from 'react-admin'
import CustomField from 'elements/field/CustomField'
import Button from 'elements/proUXButtons/ProUXButton'
import { AccountFormToShowType } from './types'

type PropTypes = {
  accountFormToShow: AccountFormToShowType
  handleSubmit: any
  loading: boolean
  switchMode: (newMode: 'new' | 'existing') => void
}

const GreenLancerAccountForm: React.FunctionComponent<PropTypes> = (props) => {
  const translate = useTranslate()
  const renderExistingAccountFields = () => {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <div style={{ flex: 1, width: '80%' }}>
          <CustomField
            name="email"
            component={TextInput}
            label={translate('Email')}
            type="email"
            fullWidth={true}
            style={{ width: '100%', marginRight: '0px' }}
          />
        </div>
        <div style={{ flex: 1, width: '80%' }}>
          <CustomField
            name="password"
            component={TextInput}
            label={translate('Password')}
            type="password"
            fullWidth={true}
            style={{ width: '100%', marginRight: '0px' }}
          />
        </div>
      </div>
    )
  }

  const renderNewAccountFields = () => {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ flex: 1, width: '80%' }}>
          <CustomField
            name="first_name"
            component={TextInput}
            label={translate('First Name')}
            type="text"
            fullWidth={true}
            style={{ width: '100%' }}
          />
        </div>
        <div style={{ flex: 1, width: '80%' }}>
          <CustomField
            name="last_name"
            component={TextInput}
            label={translate('Last Name')}
            type="text"
            fullWidth={true}
            style={{ width: '100%' }}
          />
        </div>
        <div style={{ flex: 1, width: '80%' }}>
          <CustomField
            name="email"
            component={TextInput}
            label={translate('Email')}
            type="email"
            fullWidth={true}
            style={{ width: '100%' }}
          />
        </div>
        <div style={{ flex: 1, width: '80%' }}>
          <CustomField
            name="phone"
            component={TextInput}
            label={translate('Phone')}
            type="tel"
            fullWidth={true}
            style={{ width: '100%' }}
          />
        </div>
      </div>
    )
  }

  return (
    <div style={{ width: '100%' }}>
      <form onSubmit={props.handleSubmit}>
        <div>{props.accountFormToShow === 'existing' ? renderExistingAccountFields() : renderNewAccountFields()}</div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Button
            type="primary"
            disabled={props.loading}
            loading={props.loading}
            label={props.accountFormToShow === 'new' ? translate('Create Account') : translate('Sign In')}
            onClick={props.handleSubmit}
          />
          <div
            style={{
              position: 'relative',
              width: '100%',
              height: '1px',
              borderBottom: '1px solid #ececec',
              marginTop: '40px',
            }}
          >
            <span
              style={{
                position: 'absolute',
                left: 'calc(50% - 25px',
                top: '-8px',
                width: '50px',
                textAlign: 'center',
                backgroundColor: '#fff',
              }}
            >
              OR
            </span>
          </div>
          <a
            style={{ marginTop: '20px', marginBottom: '20px' }}
            onClick={() => props.switchMode(props.accountFormToShow === 'new' ? 'existing' : 'new')}
          >
            {props.accountFormToShow === 'new'
              ? translate('Sign in to existing account')
              : translate('Create new account')}
          </a>
        </div>
      </form>
    </div>
  )
}

export default GreenLancerAccountForm

import OrderLineItem from 'pages/ordering/OrderLineItem'
import { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { formatCurrency } from 'util/misc'
import PreOrderService from './services/PreOrderService'
import { AccountManagerType, ContactInfoType, DeliveryAddressInfoType } from './types'

class PreOrderPresenter {
  private service: PreOrderService | undefined
  private setSystem: React.Dispatch<React.SetStateAction<any>>
  private setDistributorData: React.Dispatch<React.SetStateAction<any>>
  private setContactInfo: React.Dispatch<React.SetStateAction<{ [key: string]: ContactInfoType }>>
  private setDeliveryAddressInfo: React.Dispatch<React.SetStateAction<{ [key: string]: DeliveryAddressInfoType }>>
  private setAccountManagerInfo: React.Dispatch<React.SetStateAction<{ [key: string]: AccountManagerType | undefined }>>
  constructor(
    service: PreOrderService | undefined,
    setSystem: React.Dispatch<React.SetStateAction<any>>,
    setDistributorData: React.Dispatch<React.SetStateAction<any>>,
    setContactInfo: React.Dispatch<React.SetStateAction<{ [key: string]: ContactInfoType }>>,
    setDeliveryAddressInfo: React.Dispatch<React.SetStateAction<{ [key: string]: DeliveryAddressInfoType }>>,
    setAccountManagerInfo: React.Dispatch<React.SetStateAction<{ [key: string]: AccountManagerType | undefined }>>
  ) {
    this.service = service
    this.setSystem = setSystem
    this.setDistributorData = setDistributorData
    this.setContactInfo = setContactInfo
    this.setDeliveryAddressInfo = setDeliveryAddressInfo
    this.setAccountManagerInfo = setAccountManagerInfo
  }

  updateSystem = (system: any) => {
    this.setSystem(system)
  }

  updateContactInfo = (data: { [key: string]: ContactInfoType }) => {
    this.setContactInfo(data)
  }

  updateDeliveryAddressInfo = (data: { [key: string]: DeliveryAddressInfoType }) => {
    this.setDeliveryAddressInfo(data)
  }

  updateDistributorData = (distributorData: any) => {
    this.setDistributorData(distributorData)
  }

  processCheckout = async (distributorData: any, contactInfo: any, deliveryAddressInfo: any) => {
    const requestData = {}
    Object.keys(distributorData).forEach((key) => {
      const items = Object.values(distributorData[key]['items']).map((item) => {
        const lineItemObj = new OrderLineItem(item as OrderLineItem)
        const { afterDiscount } = lineItemObj.getBestDiscountOffer()
        return {
          code: lineItemObj.code,
          quantity: lineItemObj.quantity,
          title: lineItemObj.data?.short_description,
          pricePerUnit: lineItemObj.pricePerUnit,
          variantId: key === 'outlet' ? lineItemObj.data?.base_sku : lineItemObj.variantId,
          sku: lineItemObj.data?.code,
          imageUrl: lineItemObj.data?.image_url || '',
          componentType: lineItemObj.componentType,
          totalPrice: formatCurrency((lineItemObj.pricePerUnit || 0) * lineItemObj.quantity),
          discountedPrice: formatCurrency(afterDiscount),
        }
      })
      requestData[key] = {
        ...distributorData[key],
        contactInfo: contactInfo[key],
        shippingInfo: {
          ...distributorData[key]['shippingInfo'],
          address: deliveryAddressInfo[key],
        },
        items,
      }
    })
    const checkout = await this.service?.createCheckout(requestData)
    let response: any[] = []
    if (checkout?.id) {
      const orderPromises: any[] = []
      Object.keys(distributorData).forEach((key) => {
        orderPromises.push(
          this.service?.processCheckoutOrder(requestData[key], checkout?.id, key as HardwareSupplierFilterKeyType)
        )
      })
      response = await Promise.all(orderPromises)
    }
    return response
  }

  updateAccountManager = (accountManagerData: { [key: string]: AccountManagerType | undefined }) => {
    this.setAccountManagerInfo(accountManagerData)
  }
}

export default PreOrderPresenter

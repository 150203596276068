import { Link, Typography } from '@material-ui/core'
import { FC } from 'react'
import { ComponentVersionInfo } from './LibraryVersions'
import { useUiLibraryStyles } from './styles'

type ComponentPageProps = {
  name: string
  import?: string
  description?: string
  versions?: ComponentVersionInfo[]
  links?: { mui4?: string; mui5?: string }
}

const ComponentPage: FC<ComponentPageProps> = (props) => {
  const classes = useUiLibraryStyles()

  return (
    <div className={classes.page}>
      <Typography variant="h3">{props.name}</Typography>
      {props.links && (
        <div className={classes.page_links}>
          {props.links.mui4 && (
            <Link href={props.links.mui4} target="_blank" rel="noreferrer">
              MUI v4 Docs
            </Link>
          )}
          {props.links.mui5 && (
            <Link href={props.links.mui5} target="_blank" rel="noreferrer">
              MUI v5 Docs
            </Link>
          )}
        </div>
      )}
      {props.import && <div className={'code-block ' + classes.page_import}>{props.import}</div>}
      {props.description && (
        <Typography variant="body1" className={classes.bodyText}>
          {props.description}
        </Typography>
      )}
      <div className={classes.page_children}>{props.children}</div>
    </div>
  )
}

export default ComponentPage

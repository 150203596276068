import { FormControlLabel, Paper, Radio, RadioGroup } from '@material-ui/core'
import CustomField from 'elements/field/CustomField'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import { Switch } from "opensolar-ui"
import { ChangeEvent, default as React, useEffect, useState } from 'react'
import { BooleanInput, TextInput, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { authSelectors } from '../../ducks/auth'
import DocusignCATemplateDialog from './DocusignCATemplateDialog'

type PropTypes = {
  disabled: boolean
  // mode: 'pdf' | 'html'
  // setMode: (newVal: 'pdf' | 'html') => void
}

export const DocusignSettingsSection: React.FunctionComponent<PropTypes> = (props) => {
  const form = useForm()

  const org = useSelector(authSelectors.getCurrentOrg)

  const getInitialCountersignType = () => {
    let record = form.getState().values
    if (!record) return undefined
    if (record.docusign_require_sales_rep_countersignature) return 'dynamic'
    else if (record.docusign_designated_countersigner_email) return 'fixed'
    else return undefined
  }

  type Countersign = undefined | 'fixed' | 'dynamic'

  const getCountersigningEnabled = (dsConfig: any) => getInitialCountersignType() !== undefined

  const [showCATemplateDialog, setShowCATemplateDialog] = useState<boolean>(false)
  const [countersignEnabled, setCountersignEnabled] = useState<boolean>(getCountersigningEnabled(org?.docusign_config))
  const [countersignType, setCountersignType] = useState<Countersign>(getInitialCountersignType())

  useEffect(() => {
    if (form.getState().values.docusign_require_sales_rep_countersignature) {
      setCountersignType('dynamic')
    } else if (countersignType) {
      setCountersignType('fixed')
    }
  }, [form.getState().values, countersignType])

  useEffect(() => {
    form.change('enable_docusign_countersignature', countersignEnabled)
    if (!countersignEnabled) {
      form.change('docusign_require_sales_rep_countersignature', false)
      form.change('docusign_designated_countersigner_email', '')
      form.change('docusign_designated_countersigner_first_name', '')
      form.change('docusign_designated_countersigner_family_name', '')
    }
  }, [countersignEnabled])

  const translate = useTranslate()
  const isUSABased = useSelector(authSelectors.getCurrentOrg)?.country?.iso2 === 'US'

  const handleCountersignToggle = (e: ChangeEvent<HTMLInputElement>) => {
    setCountersignEnabled(e.target.checked)
  }

  const handleCountersignTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value: Countersign = e.target.value as Countersign
    setCountersignType(value)
    if (value === 'dynamic') form.change('docusign_require_sales_rep_countersignature', true)
    else form.change('docusign_require_sales_rep_countersignature', false)
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FormControlLabel
        label={translate('Enable DocuSign Countersigner')}
        labelPlacement="start"
        control={<Switch checked={countersignEnabled} onChange={handleCountersignToggle} disabled={props.disabled} />}
      />
      {form.getState().errors?.enable_docusign_countersignature && (
        <p style={{ color: 'red', margin: '15px 0px', textAlign: 'center' }}>
          {form.getState().errors?.enable_docusign_countersignature}
        </p>
      )}
      <CustomField
        component={BooleanInput}
        name={'show_header'}
        label={'Show header (in docusign PDF)'}
        source="show_header"
        defaultValue={false}
      />

      <Paper style={{ padding: '10px', width: '100%', display: countersignEnabled ? undefined : 'none' }}>
        <h4 style={{ textAlign: 'center', marginTop: '20px' }}>
          {translate('How would you like to choose the countersigner for this contract?')}
        </h4>
        <RadioGroup
          onChange={handleCountersignTypeChange}
          style={{ display: 'flex', flexDirection: 'row' }}
          value={countersignType}
        >
          <FormControlLabel
            label={translate('Dynamic - Assigned Team Member is countersigner')}
            value="dynamic"
            control={<Radio />}
          />
          <FormControlLabel
            label={translate('Fixed - set one individual to be countersigner for all projects')}
            value="fixed"
            control={<Radio />}
          />
        </RadioGroup>
        {countersignType && (
          <>
            <p className="small">
              {countersignType === 'dynamic'
                ? translate(
                    'The information below will enable a countersignature in the unlikely event that the Assigned Team Member cannot be used as the countersigner (e.g. that user does not have a first and last name entered in OpenSolar).'
                  )
                : translate('The information below will be used to set the countersigner for every contract.')}
            </p>
            <CustomField
              component={TextInput}
              fullWidth={true}
              name="docusign_designated_countersigner_email"
              source="docusign_designated_countersigner_email"
              label={`${countersignType === 'dynamic' ? 'Backup ' : 'Designated '}Countersigner Email`}
              disabled={props.disabled}
            />
            <CustomField
              component={TextInput}
              fullWidth={true}
              name="docusign_designated_countersigner_first_name"
              source="docusign_designated_countersigner_first_name"
              label={`${countersignType === 'dynamic' ? 'Backup ' : 'Designated '}Countersigner First Name`}
              disabled={props.disabled}
            />
            <CustomField
              component={TextInput}
              fullWidth={true}
              name="docusign_designated_countersigner_family_name"
              source="docusign_designated_countersigner_family_name"
              label={`${countersignType === 'dynamic' ? 'Backup ' : 'Designated '}Countersigner Last Name`}
              disabled={props.disabled}
            />
          </>
        )}
        <br />
        {isUSABased && (
          <div
            style={{
              margin: '20px 0px 50px 0px',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h4>California Consumer Protection Guide Template</h4>
            <p>
              {org?.docusign_config?.enable_california_protection_guide
                ? 'Click the button below to edit or disable your existing California Consumer Protection Guide Docusign template.'
                : 'Click the button below to automatically include the California Consumer Protection Guide as part of all Docusign contracts for projects based in California.'}
            </p>
            <ProUXButton
              type="primary"
              label={org?.docusign_config?.enable_california_protection_guide ? 'Edit Template' : 'Enable Template'}
              onClick={() => setShowCATemplateDialog(true)}
            />
            <DocusignCATemplateDialog isOpen={showCATemplateDialog} onClose={() => setShowCATemplateDialog(false)} />
          </div>
        )}
      </Paper>
    </div>
  )
}
export default DocusignSettingsSection

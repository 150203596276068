export const INTEGRATION_TO_FIELDS = {
  dividend: {
    divlev: [
      {
        key: 'loan_interest_rate',
        name: 'Loan APR',
      },
      {
        key: 'loan_prepayment',
        name: 'Voluntary Payment',
      },
      {
        key: 'loan_repayments_initial',
        name: 'Initial Monthly Payment',
        description: 'This is the monthly payment that homeowner pays before the prepayment is due',
      },
      {
        key: 'loan_repayments_final_no_paydown',
        name: 'Escalated Monthly Payment',
        description:
          'This is the escalated monthly payment that the customer will receive if they do not make a prepayment',
      },
    ],
    io_product: [
      {
        key: 'loan_interest_rate',
        name: 'Loan APR',
      },
      {
        key: 'loan_prepayment',
        name: 'Voluntary Payment',
      },
      {
        key: 'loan_repayments_initial',
        name: 'Initial Monthly Payment',
        description: 'This is the monthly payment that homeowner pays before the prepayment is due',
      },
      {
        key: 'loan_repayments_final',
        name: 'Final Monthly Payment',
        description:
          'This is the reduced monthly payment that the customer will receive after they make their prepayment',
      },
      {
        key: 'loan_repayments_final_no_paydown',
        name: 'IO Product Esclated Payments',
        description:
          'For IO products only. This is the increased monthly payment if the customer elects to not make a prepayment',
      },
    ],
    simple: [
      {
        key: 'loan_interest_rate',
        name: 'Loan APR',
      },
      {
        key: 'loan_prepayment',
        name: 'Voluntary Payment',
      },
      {
        key: 'loan_repayments_initial',
        name: 'Initial Monthly Payment',
        description: 'This is the monthly payment that homeowner pays before the prepayment is due',
      },
      {
        key: 'loan_repayments_final',
        name: 'Final Monthly Payment',
        description:
          'This is the reduced monthly payment that the customer will receive after they make their prepayment',
      },
    ],
  },
}

export const PRODUCT_CODE_TO_NAME = {
  io_product: 'I/O Product',
  simple: 'Simple Product',
  divlev: 'DivLev Product',
}

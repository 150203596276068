import { removeProjectError } from 'actions/project/projectErrorsActions'
import { EffectFactory } from '.'
import { SystemContext } from '../types'

export type RemoveSystemErrorDef = {
  type: 'remove-system-error'
  key: string
}

export const effect_removeSystemError: EffectFactory<RemoveSystemErrorDef> = (def) => {
  return {
    execute: async (context: SystemContext) => {
      context.dispatch(
        removeProjectError({
          key: def.key,
          systemId: context.system.uuid,
          source: 'design-rule',
        })
      )
    },
  }
}

import { ConditionFactory, SystemContext } from 'Designer/designRules/types'
import { BaseConditionDef, SystemConditionDef } from '.'
import { nested_condition } from './nested_condition'

export type AndConditionDef = BaseConditionDef & {
  type: 'and'
  conditions: SystemConditionDef[]
}

export const condition_and: ConditionFactory<AndConditionDef> = (def) => {
  const { subConditions, ...nested } = nested_condition(def)
  return {
    ...nested,
    check: (context: SystemContext) => {
      return subConditions.every((c) => context.runCondition(c.condition, c.def))
    },
  }
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.preferences = void 0;
var action_1 = require("../core/action");
exports.preferences = {
    // State
    // route: new ShallowState<Route | undefined>(undefined),
    // path: new Value<string | undefined>(undefined),
    // Actions
    setString: new action_1.Action(),
    setObject: new action_1.Action(),
};

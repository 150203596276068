import { inventoryFilterSelectors, setFilterValue } from 'ducks/inventoryFilter'
import type { Filter } from 'elements/controller/filter/filter'
import useFilter from 'elements/controller/filter/useFilter'
import type { Query } from 'elements/controller/query/query'
import useQuery from 'elements/controller/query/useQuery'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { InventoryFilterKeyType, InventoryFiltersType } from './type'

type InventoryFilterOptions = {
  defaultQuery?: Query
  defaultFilter?: Filter
}

type InventoryFilter = {
  filterValues: InventoryFiltersType
  updateFilterValueByKey<T extends InventoryFilterKeyType>(args: UpdateFilterValueArgs<T>): void
}
export type UpdateFilterValueArgs<T extends InventoryFilterKeyType> = {
  key: T
  value: InventoryFiltersType[T]
}

const useInventoryFilter = (options?: InventoryFilterOptions): InventoryFilter => {
  const dispatch = useDispatch()
  const filterValues: any = useSelector(inventoryFilterSelectors.getInventoryFilter)
  const updateFilterValueByKey = useCallback(
    <T extends InventoryFilterKeyType>({ key, value }: UpdateFilterValueArgs<T>) => {
      dispatch(setFilterValue(key, value))
    },
    [filterValues]
  )

  return useMemo(
    () => ({
      filterValues,
      updateFilterValueByKey,
    }),
    [filterValues, updateFilterValueByKey]
  )
}

export default useInventoryFilter

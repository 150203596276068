import { jsxs as _jsxs } from "react/jsx-runtime";
import { InputLabel } from '@mui/material';
import { styled } from '../styles';
var StyledLabel = styled(InputLabel)({
    marginBottom: 5,
});
export var OsFieldTitleVersion;
(function (OsFieldTitleVersion) {
    OsFieldTitleVersion[OsFieldTitleVersion["V1"] = 1] = "V1";
    OsFieldTitleVersion[OsFieldTitleVersion["V2"] = 2] = "V2";
})(OsFieldTitleVersion || (OsFieldTitleVersion = {}));
export var FieldTitle = function (_a) {
    var label = _a.label, required = _a.required;
    return (_jsxs(StyledLabel, { children: [label, required && ' *'] }));
};
export default FieldTitle;

import { getSupplierConfig } from 'pages/ordering/configs'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'types/state'
import { DISTRIBUTOR_BRAND_FILTER_KEY } from '../distributorBrand/DistributorBrandFilterNodeFactory'
import { MANUFACTURER_FILTER_KEY } from '../manufacturer/ManufacturerFilter'
import type { FilterComponentRendererType, FilterGenericPropsType, FilterKeysType, OptionGenericType } from '../type'
import { DISTRIBUTOR_FILTER_KEY } from './DistributorFilterNodeFactory'

export type DistributorFilterOptionsType = OptionGenericType<string>[]

const defaultValue = 'all'

const getDistributorsOptions = (enabledDistributors: HardwareSupplierEnum[]) => {
  const options = [{ id: 'default', title: 'All', value: defaultValue }]
  enabledDistributors.forEach((distributor) => {
    const config = getSupplierConfig(distributor)
    if (!config) return
    options.push({
      id: `require_${config.filterKey}`,
      title: config.name || '',
      value: config.filterKey,
    })
  })

  return options
}

type DistributorFilterProps = FilterGenericPropsType & {
  distributors: HardwareSupplierEnum[]
  RendererComponent: FilterComponentRendererType<DistributorFilterOptionsType>
  filterKey: FilterKeysType
}

const DistributorFilter = ({
  filterKey,
  label,
  modifiableFilterValues,
  persistentFilterValues,
  allFilterValues,
  setModifiableFilters,
  distributors,
  RendererComponent,
}: DistributorFilterProps) => {
  const zone = useSelector((state: RootState) => state.zone)

  const onSelect = useCallback(
    (value) => {
      const newFilterValues = { ...modifiableFilterValues, [filterKey]: value }
      if (value === undefined || value === defaultValue) {
        delete newFilterValues[DISTRIBUTOR_FILTER_KEY]
      }

      // clear manufacturer filters
      delete newFilterValues[MANUFACTURER_FILTER_KEY]
      delete newFilterValues[DISTRIBUTOR_BRAND_FILTER_KEY]

      setModifiableFilters(newFilterValues)
    },
    [modifiableFilterValues]
  )

  // Special request disable distributor filter in hardware section
  const disabled = zone !== 'crm'
  // Have to map from false to 'all'
  const selectedValue = allFilterValues[filterKey] === false ? 'all' : allFilterValues[filterKey]
  return (
    <RendererComponent
      id={filterKey}
      defaultValue={defaultValue}
      label={label || 'Available from'}
      options={getDistributorsOptions(distributors)}
      disabled={disabled}
      onSelect={onSelect}
      value={selectedValue}
    />
  )
}

export default DistributorFilter

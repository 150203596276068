import { ClickAwayListener, Tooltip, withStyles } from '@material-ui/core'
import { MoreVertOutlined } from '@material-ui/icons'
import { IconButton, IconButtonProps } from 'opensolar-ui'
import React, { useState } from 'react'

const PopoverTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#FFF',
    padding: 0,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 400,
  },
}))(Tooltip)

interface PropTypes {
  disabled: boolean
  btnProps?: IconButtonProps
}

const ActionsIconButton: React.FC<PropTypes> = ({ disabled, children, btnProps }) => {
  const [open, openDropdown] = useState(false)
  return (
    <PopoverTooltip
      placement="bottom-end"
      open={open}
      title={
        <ClickAwayListener onClickAway={() => openDropdown(false)}>
          <div>{children}</div>
        </ClickAwayListener>
      }
      interactive
    >
      <IconButton
        onClick={() => {
          openDropdown(!open)
        }}
        color="secondary"
        variant="squared"
        disabled={disabled}
        {...btnProps}
      >
        <MoreVertOutlined />
      </IconButton>
    </PopoverTooltip>
  )
}
export default ActionsIconButton

const listQueryParamsFormatter = {
  parse: <T>(value: any): Set<T> => {
    if (value) {
      try {
        if (value instanceof Set) {
          return value
        }
        return new Set<T>(JSON.parse(value))
      } catch (e) {
        return new Set<T>()
      }
    }
    return new Set<T>()
  },
  format: <T>({ value }: { filterValues?; value: T; selectedOption? }) => {

    if (Array.isArray(value) && value.length > 0) {
      return JSON.stringify(value)
    }

    if (value instanceof Set && value.size > 0) {
      return JSON.stringify(value)
    }

    if (canBeArray(value)) {
      return value
    }

    return undefined
  },
}

const canBeArray = (str) => {
    try {
        const arr = JSON.parse(str);
        return Array.isArray(arr);
    } catch {
        return false;
    }
}

export default listQueryParamsFormatter

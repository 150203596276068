import { SystemEffect } from 'Designer/designRules/types'
import { AddComponentEffectType, effect_addComponentByCode } from './add_component_by_code'
import { AddSystemErrorDef, effect_addSystemError } from './add_system_error'
import { AdjustComponentQuantitiesType, effect_adjustComponentQuantities } from './adjust_component_quantities'
import { effect_removeComponentByCode, RemoveComponentEffectType } from './remove_component_by_code'
import { effect_removeSystemError, RemoveSystemErrorDef } from './remove_system_error'
import { effect_setSessionData, SetSessionDataDef } from './set_session_data'
import { effect_setSystemCustomData, SetSystemCustomDataDef } from './set_system_custom_data'
import { effect_setSystemProp, SetSystemPropDef } from './set_system_prop'
import { effect_showNotification, ShowMessageEffectType } from './show_notification'
import { effect_trackComponentAssociation, TrackComponentAssociationEffectType } from './track_component_association'
import { effect_updateAssociatedComponents, UpdateAssociatedComponentsEffectType } from './update_associated_components'

export const createSystemEffect = (def: SystemEffectDef): SystemEffect => {
  const factory = systemEffects[def.type]
  if (!factory) throw new Error(`DesignRules: Unknown system effect type: ${def.type}`)
  return factory(def)
}

export type EffectFactory<T = any> = (def: T) => SystemEffect

const systemEffects: Record<SystemEffectTypes, EffectFactory> = {
  'add-component-by-code': effect_addComponentByCode,
  'update-associated-components': effect_updateAssociatedComponents,
  'track-component-association': effect_trackComponentAssociation,
  'remove-component-by-code': effect_removeComponentByCode,
  'show-notification': effect_showNotification,
  'set-system-custom-data': effect_setSystemCustomData,
  'set-system-prop': effect_setSystemProp,
  'set-session-data': effect_setSessionData,
  'add-system-error': effect_addSystemError,
  'remove-system-error': effect_removeSystemError,
  'adjust-component-quantities': effect_adjustComponentQuantities,
}

/// TYPES
export type SystemEffectDef =
  | AddComponentEffectType
  | UpdateAssociatedComponentsEffectType
  | TrackComponentAssociationEffectType
  | RemoveComponentEffectType
  | ShowMessageEffectType
  | SetSystemCustomDataDef
  | SetSystemPropDef
  | AddSystemErrorDef
  | RemoveSystemErrorDef
  | AdjustComponentQuantitiesType
  | SetSessionDataDef

export type SystemEffectTypes = SystemEffectDef['type']

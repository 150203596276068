import { Theme, useMediaQuery } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import * as DOMPurify from 'dompurify'
import RenderDialog from 'myenergy/mainContent/components/RenderDialog'
import { SystemComponentBlock, SystemComponentContainer } from 'myenergy/mainContent/components/SystemComponent'
import { useState } from 'react'
import { FormDataConsumer, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { orgSelectors } from '../../ducks/orgs'

const useStyles = makeStyles((theme) => ({
  modelCodesDropdown: {
    width: '100%',
    marginTop: '0',
  },
}))

const ContentPreview = () => {
  const translate = useTranslate()
  const classes = useStyles()
  const layout = useMediaQuery((theme: Theme) => theme.breakpoints.down('md')) ? 'small' : 'large'
  const objOrganization = useSelector(orgSelectors.getOrg)

  const [activeDialog, setActiveDialog] = useState<string | undefined>()
  const [dialogData, setDialogData] = useState<unknown | undefined>()
  const [dialogIsFullWidth, setDialogIsFullWidth] = useState<unknown | undefined>()

  const proposalData = {
    myeStyles: {
      textColor: '#000000',
      highlightColor: '#54a196',
      highlightColorLight: '#54a19620',
      highlightColorInteraction: '#68b5aa',
      textColorOnHighlight: '#ffffff',
    },
    selectedSystem: {
      module: null,
    },
  }

  const showDialog = (activeDialog: 'Video' | 'Detail', dialogData: unknown, isFullWidth = false) => {
    setActiveDialog(activeDialog)
    setDialogData(dialogData)
    setDialogIsFullWidth(isFullWidth)
  }

  const closeDialog = () => {
    setActiveDialog(undefined)
    setDialogData(undefined)
    setDialogIsFullWidth(undefined)
  }

  return (
    <FormDataConsumer>
      {({ formData }) => {
        if (formData?.component_type === undefined) return <></>

        const {
          title,
          description_featured,
          logo_image,
          promotional_image,
          video_url,
          product_details,
          spec_sheet_pdf,
        } = formData

        const sanitizeHtmlContents = (htmlContent = '') => {
          // Regular expression to match placeholders surrounded by curly braces
          const regex = /\{\{([^}]+)\}\}/g
          const replacedContent = htmlContent?.replace(regex, 'XX')

          return DOMPurify.sanitize(replacedContent)
        }

        const componentGeneralContent: Partial<{
          title: string
          isFeatured: boolean
          content: string
          productImageUrl: string
          logoUrl: string
          videoUrl: string
          productDetails: string
          specSheetUrl: string
        }> = {
          title,
          content: sanitizeHtmlContents(description_featured) ?? null,
          isFeatured: true,
          logoUrl: logo_image || objOrganization?.logo_public_url?.toString(),
          productDetails: sanitizeHtmlContents(product_details) ?? null,
          productImageUrl: promotional_image ?? null,
          specSheetUrl: spec_sheet_pdf ?? null,
          videoUrl: video_url ?? null,
        }

        return (
          <div id="contentPreview-container">
            <Grid container>
              <Grid item xs={6}>
                <h3>{translate('Content Preview')}</h3>
              </Grid>
              {/* {associated_component_model_codes && associated_component_model_codes.length > 1 && (
                <Grid item xs={6}>
                  <SelectInput
                    className={classes.modelCodesDropdown}
                    fullWidth
                    source={'no-source'}
                    choices={assembleModelCodeOptions()}
                    defaultValue={associated_component_model_codes[0]}
                    label=""
                  />
                </Grid>
              )} */}
            </Grid>

            <SystemComponentContainer>
              <SystemComponentBlock
                component={componentGeneralContent}
                isMobile={layout === 'small'}
                proposalData={proposalData}
                showDialog={showDialog}
              />
            </SystemComponentContainer>

            {activeDialog && (
              <RenderDialog
                activeDialog={activeDialog}
                dialogData={dialogData}
                dialogIsFullWidth={dialogIsFullWidth}
                layout={layout}
                closeDialog={closeDialog}
                proposalData={proposalData}
              />
            )}
          </div>
        )
      }}
    </FormDataConsumer>
  )
}

export default ContentPreview

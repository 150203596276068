import type { ComponentTypesV2 } from 'types/selectComponent'
import BasicFilter from '../basic/BasicFilter'
import type {
  ConfigurableFilterOptionsType,
  FilterComponentNodeV2,
  FilterGenericPropsType,
  OptionGenericType,
} from '../typeV2'
import { parseStringifiedListFilterValue } from '../utils/utils'

export const WARRANTY_FILTER_KEY_V2 = 'product_warranty'
const defaultLabel = 'Warranty'
const defaultSelectedOptionValue = ''
const SELECT_ALL_OPTION = { id: 'default', title: 'All', value: defaultSelectedOptionValue }

const WARRANTY_RANGES: OptionGenericType<number>[] = [
  {
    id: '12',
    value: 12,
    title: '12 year',
  },
  {
    id: '25',
    value: 25,
    title: '25 year',
  },
  {
    id: '30',
    value: 30,
    title: '30 year',
  },
  {
    id: '40',
    value: 40,
    title: '40 year',
  },
]

const getWarrantyOptionsByComponentTypes = (types: Set<ComponentTypesV2>) => {
  if (types.has('module')) {
    return [SELECT_ALL_OPTION, ...WARRANTY_RANGES]
  }
}

const getDefaultValue = (args?: { specsData: { [key: string]: string | number } }) => {
  if (!args) return
  const { specsData } = args
  if (specsData.product_warranty == null) {
    return
  }

  for (let i = 0; i < WARRANTY_RANGES.length; i++) {
    const { id, value } = WARRANTY_RANGES[i]
    if (specsData.product_warranty === value || specsData.product_warranty === value.toString()) {
      return { [WARRANTY_FILTER_KEY_V2]: value }
    }
  }
  return
}

const createWarrantyFilterNode = <T,>({
  label = defaultLabel,
  ...restConfigs
}: ConfigurableFilterOptionsType<number | string, T>): FilterComponentNodeV2 => {
  const FilterComponent = (filterProps: FilterGenericPropsType) => {
    const types = parseStringifiedListFilterValue<ComponentTypesV2>({
      filterValues: filterProps.allFilterValues,
      key: 'types',
    })
    const options = getWarrantyOptionsByComponentTypes(types)
    if (!options) return null

    return (
      <BasicFilter
        label={label}
        defaultSelectedOptionValue={defaultSelectedOptionValue}
        options={options}
        filterKey={WARRANTY_FILTER_KEY_V2}
        {...restConfigs}
        {...filterProps}
      />
    )
  }

  return {
    key: WARRANTY_FILTER_KEY_V2,
    component: FilterComponent,
    getMatchedValueFromSpecsData: getDefaultValue,
  }
}

export default createWarrantyFilterNode

import { TextField, TextFieldProps, makeStyles } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import { Chip } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'
import { formatTimeStringBaseOnLocale } from 'util/misc'
import { SharedItem } from './ShareSummary'

interface PropTypes {
  items: SharedItem[]
}

const useStyles = makeStyles(
  (theme: OpenSolarThemeType) => ({
    chipWrapper: {
      flexWrap: 'wrap',
      alignItems: 'stretch',
    },
    chip: {
      display: 'inline-block',
      padding: '5px 10px',
      margin: '5px',
      height: 'unset',
    },
    input: {
      display: 'inline-block',
      width: 'unset',
      flexGrow: 1,
    },
  }),
  { name: 'SharedItemsDisplay' }
)

const SharedItemsDisplay = (props: PropTypes & TextFieldProps) => {
  const { items, ...otherProps } = props
  const classes = useStyles()
  const translate = useTranslate()
  const chipLabel = (item: SharedItem) => (
    <>
      <strong>{item.title}</strong>

      {item.timestamp && (
        <>
          <br />
          {translate('Time shared: ') + formatTimeStringBaseOnLocale(item.timestamp, true)}
        </>
      )}
    </>
  )
  return (
    <TextField
      {...otherProps}
      InputProps={{
        classes: { root: classes.chipWrapper, input: classes.input },
        startAdornment: items?.map((item, index) => (
          <Chip key={index} className={classes.chip} tabIndex={-1} label={chipLabel(item)} />
        )),
      }}
    />
  )
}

export default SharedItemsDisplay

import { makeStyles } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { parseSchedules } from 'elements/scheduleDialog/utils'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { Theme } from 'types/themes'
import { urlToId } from 'util/misc'
import { permissionsSelectors } from '../../../../ducks/permissions'
import { parseTariffData } from '../parseTariffInitialValues'
import ClearButton from './ClearButton'
import SelectNewTariffButton from './SelectNewTariffButton'
import TariffSummaryDialog from './TariffSummaryDialog'
import UtilityTariffLink from './UtilityTariffLink'
import { useProposedTariffData } from './hooks'
import { UtilityTariffDialogContext } from './index'
import TariffDialogCreateButton from './selectTariffDialog/TariffDialogCreateButton'

const restClientInstance = restClient(window.API_ROOT + '/api')
export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  tariffContainer: {
    padding: 20,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  spanRight: {
    marginLeft: 'auto',
  },
  subText: {
    color: theme.greyMid1,
    marginLeft: 10,
    fontWeight: theme.typography.fontWeightLight,
  },
  tariffLabel: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  inputsContainer: {
    '& p': {
      width: '30%',
    },
    padding: '10px 20px',
  },
  input: {
    marginLeft: 'auto',
    width: '70%',
  },
}))

const ProposedTariff = ({ tariffData, disabled }) => {
  const tariffType = tariffData.tariffDataType
  const classes = useStyles()
  const translate = useTranslate()
  const formState = useFormState()
  const { dispatch } = useContext(UtilityTariffDialogContext)
  const formValues = formState.values
  const name = tariffData?.name || 'Customised Utility Tariff'

  // customised utility tariff
  if (tariffType === 'custom') {
    return (
      <div className={classes.column}>
        {disabled ? (
          name
        ) : (
          <a
            id="view-current-custom-tariff-proposed-link"
            href="#"
            onClick={(e) => {
              e.preventDefault()
              dispatch({
                type: 'edit',
                payload: {
                  isOpen: true,
                  variant: 'proposed',
                  basePath: '/custom_tariff_proposed/' + formValues.id,
                  resource: 'custom_tariff_proposed',
                  mode: 'edit',
                  id: formValues.id,
                },
              })
            }}
          >
            {name}
          </a>
        )}
        <div className="small">({translate('Customised for this project')})</div>
      </div>
    )
    // selected utility tariff from database
  } else if (tariffType === 'proposed') {
    return (
      <UtilityTariffLink
        basePath={'/utility_tariffs'}
        tariffId={tariffData.id}
        code={tariffData.code}
        variant={'proposed'}
        resource={'utility_tariffs'}
        disabled={disabled}
      />
    )
    // use current utility tariff customised
  } else if (tariffType === 'current_custom') {
    return (
      <div className={classes.column}>
        {disabled ? (
          name
        ) : (
          <a
            id="view-current-custom-tariff-link"
            href="#"
            onClick={(e) => {
              e.preventDefault()
              dispatch({
                type: 'edit',
                payload: {
                  isOpen: true,
                  variant: 'current',
                  basePath: '/custom_tariff_current/' + formValues.id,
                  resource: 'custom_tariff_current',
                  mode: 'edit',
                  id: formValues.id,
                },
              })
            }}
          >
            {name}
          </a>
        )}
        <div className="small">{'(' + translate('Set based on current customised tariff') + ')'}</div>
      </div>
    )
    // If the current tariff data came from the database, see if it specifies a transition tariff.
    // Display the transition tariff whenever it's configured.
  } else if (tariffType === 'current') {
    return (
      <div className={classes.column}>
        <UtilityTariffLink
          basePath={'/utility_tariffs'}
          tariffId={tariffData.id}
          code={tariffData.code}
          variant={'proposed'}
          resource={'utility_tariffs'}
          disabled={disabled}
        />
        <div className="small">{'(' + translate('Set based on current tariff') + ')'}</div>
      </div>
    )
    // guess utility tariff
  } else {
    if (tariffData) {
      return (
        <div className={classes.column}>
          <UtilityTariffLink
            basePath={'/utility_tariffs'}
            tariffId={tariffData.id}
            code={tariffData.code}
            variant={'proposed'}
            resource={'utility_tariffs'}
            disabled={disabled}
          />
          <div className="small">{'(' + translate('Set based on current tariff') + ')'}</div>
        </div>
      )
    } else {
      return <span>{translate('Not set')}</span>
    }
  }
}

const formTariffUrl = (data, orgId) => {
  return `${window.API_ROOT}/api/orgs/${orgId}/utility_tariffs/${typeof data === 'string' ? urlToId(data) : data}/`
}

const UtilityTariffProposedContent = () => {
  const translate = useTranslate()
  const formState = useFormState()
  const classes = useStyles()
  const formValues = formState.values
  const showClearButton = formValues?.utility_tariff_proposed_data || formValues?.utility_tariff_proposed_custom
  const countryId = urlToId(formValues.country)
  const [transitionTariff, setTransitionTariff] = useState<any>(undefined)
  const tariffData = useProposedTariffData(formValues)
  const orgId = useSelector(authSelectors.getOrgId)
  const { allowEdit, allowCreate, allowDelete } = useSelector(
    permissionsSelectors.getProjectPermissionByKey('energy_tariff')
  )

  useEffect(() => {
    if (tariffData.current_transition) {
      restClientInstance('CUSTOM_GET', 'custom', {
        url: formTariffUrl(tariffData.current_transition, orgId),
      })
        .then((res) => {
          setTransitionTariff({
            ...res.data,
            tariffDataType: tariffData.tariffDataType,
            data: parseTariffData(res.data.data),
          })
        })
        .catch((err) => {
          console.log('Failed to fetch transition tariff details: ' + err)
        })
    } else {
      setTransitionTariff(undefined)
    }
  }, [tariffData])

  return (
    <div className={classes.tariffContainer}>
      <div className={classes.row}>
        <p className={classes.tariffLabel}>
          {translate('Proposed Tariff')}
          <span className={classes.subText}>{translate('What the customer will be switching to')}</span>
        </p>
        <div className={`${classes.row} ${classes.spanRight}`}>
          {allowEdit && <SelectNewTariffButton countryId={countryId} currentOrProposed="proposed" />}
          {allowCreate && <TariffDialogCreateButton currentOrProposed="proposed" context="energy_page" />}
        </div>
      </div>
      <div className={classes.inputsContainer}>
        <div className={classes.row}>
          <p>{translate('Proposed Tariff')}:</p>
          <div className={`${classes.input} ${classes.row}`}>
            <>
              <TariffSummaryDialog
                tariffData={parseSchedules(transitionTariff?.data || tariffData?.data || tariffData)}
              />
              <ProposedTariff tariffData={transitionTariff || tariffData} disabled={!allowEdit} />
            </>
            {allowDelete && showClearButton && <ClearButton variant="proposed" />}
          </div>
        </div>
        {/* <div className={classes.row}>
          <p>{translate('Valid From')}:</p>
          <div className={classes.input}>
            <SelectInput
              variant="outlined"
              label=""
              name={'valid_from'}
              resource={'projects'}
              onChange={() => {}}
              optionText={'title'}
              optionValue={'value'}
              choices={[]}
              source={'valid_from'}
            />
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default UtilityTariffProposedContent

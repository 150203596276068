import { ManufacturerCreate, ManufacturerEdit, ManufacturerList } from './Manufacturer'

// const ManufacturerTitle = ({ record, translate, style }) => {
//   return (
//     <span style={style}>
//       {translate('Manufacturer')} {record ? `"${record.name}"` : ''}
//     </span>
//   )
// }

export default {
  list: ManufacturerList,
  create: ManufacturerCreate,
  edit: ManufacturerEdit,
  //   options: {
  //     create: {
  //       title: 'Create Manufacturer',
  //       subtitle: null,
  //       breadCrumb: 'Create Manufacturer',
  //     },
  //     list: {
  //       title: 'Manufacturers',
  //       subtitle: null,
  //       breadCrumb: 'Manufacturers',
  //     },
  //     edit: {
  //       title: <ManufacturerTitle />,
  //       subtitle: null,
  //       breadCrumb: 'Edit Manufacturer',
  //     },
  //   },
}

// @ts-nocheck
import { Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import React, { useEffect, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import restClient from 'restClient'
import { ProjectType } from 'types/projects'
import { PaymentOptionSunlightQuickCreateType, SystemDesignSunlightQuickCreateType } from '../sunlight/types'

let restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  onClose: () => void
  open: boolean
  project: ProjectType
  designs?: SystemDesignSunlightQuickCreateType[]
}

const ManageDocusignDialog: React.FunctionComponent<PropTypes> = (props) => {
  const getSungagePaymentOptions = () => {
    if (systems && systems.length > 0) {
      let allOptions: PaymentOptionSunlightQuickCreateType[] = []
      systems.forEach((design) => {
        if (design.paymentOptionsData && design.paymentOptionsData.length > 0) {
          design.paymentOptionsData.forEach((opt) => allOptions.push(opt))
        }
      })
      return allOptions
    } else return []
  }

  const [availablePaymentOptions, setAvailablePaymentOptions] = useState<PaymentOptionSunlightQuickCreateType[]>([])
  const [hasSetInitialPmts, setHasSetInitialPmts] = useState<boolean>(false)
  const [selectedPaymentOptionId, setSelectedPaymentOptionId] = useState<string | undefined>(undefined)
  const [selectedSystem, setSelectedSystem] = useState<SystemDesignSunlightQuickCreateType | undefined>(undefined)
  const [customerEmail, setCustomerEmail] = useState<string | undefined>(undefined)
  const [customerPhone, setCustomerPhone] = useState<string | undefined>(undefined)
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const [hasExisting, setHasExisting] = useState<boolean>(!!props.project.docusign_contract_envelope_id)
  const [systems, setSystems] = useState<any[]>([])
  const [draftEnvelopeId, setDraftEnvelopeId] = useState<string | undefined>(undefined)
  const [envelopeUrl, setEnvelopeUrl] = useState<string | undefined>(undefined)
  const [resetLoading, setResetLoading] = useState<boolean>(false)
  const [previewLoading, setPreviewLoading] = useState<boolean>(false)

  const translate = useTranslate()
  const notify = useNotify()

  useEffect(() => {
    const systemsFromStudio = window.editor.getSystems()?.map((sys) => {
      sys.paymentOptionsData = sys.payment_options
      sys.title = sys.name && sys.name.length > 0 ? sys.name : `${sys.moduleQuantity()} ${translate('Panels System')}`
      return sys
    })
    if (systemsFromStudio && systemsFromStudio.length > 0) setSystems(systemsFromStudio)
  }, [])

  useEffect(() => {
    if (!selectedPaymentOptionId && availablePaymentOptions && availablePaymentOptions.length === 1) {
      setSelectedPaymentOptionId(`${availablePaymentOptions[0].id}`)
    }
  }, [selectedPaymentOptionId, availablePaymentOptions])

  useEffect(() => {
    if (selectedSystem && selectedPaymentOptionId) {
      let pmtOnSystem =
        selectedSystem.payment_options?.filter((pmt) => `${pmt.id}` === `${selectedPaymentOptionId}`)?.length > 0
      if (!pmtOnSystem) setSelectedPaymentOptionId(undefined)
    }
  }, [selectedPaymentOptionId, selectedSystem])

  useEffect(() => {
    if (!selectedSystem && systems && systems.length === 1) {
      setSelectedSystem(systems[0])
    }
  }, [systems, selectedSystem])

  useEffect(() => {
    if (!hasSetInitialPmts) {
      setAvailablePaymentOptions(getSungagePaymentOptions())
      setHasSetInitialPmts(true)
    }
  }, [systems, hasSetInitialPmts])

  useEffect(() => {
    if (draftEnvelopeId) redirectToDocusign()
  }, [draftEnvelopeId])

  useEffect(() => {
    if (selectedSystem && systems) {
      //dedupe titles present on multiple systems
      const availablePmtOptions = selectedSystem.paymentOptionsData
      setAvailablePaymentOptions(availablePmtOptions)
      if (!availablePmtOptions || availablePmtOptions.length === 0) setSelectedPaymentOptionId(undefined)
    } else if (availablePaymentOptions && availablePaymentOptions.length > 0) setAvailablePaymentOptions([])
  }, [selectedSystem, systems])

  useEffect(() => {
    if (props.project && props.project.contacts_data && props.project?.contacts_data?.length > 0) {
      let email = props?.project?.contacts_data[0]?.email
      let phone = props?.project?.contacts_data[0]?.phone
      setCustomerEmail(email)
      setCustomerPhone(phone)
    }
  }, [props.project?.contacts_data])

  const handlePmtOptionChange = (e: any) => {
    setSelectedPaymentOptionId(e.target.value)
  }
  const handleSystemChange = (e: any) => {
    if (systems && systems.length > 0) {
      let uuidMatches = systems.filter((system) => system.uuid === e.target.value)
      if (uuidMatches && uuidMatches.length > 0) setSelectedSystem(uuidMatches[0])
    }
  }

  const startPreview = () => {
    if (!selectedSystem || !selectedSystem.uuid || !selectedPaymentOptionId) {
      setErrorMsg('Please select a system and a payment option')
      return
    }
    setErrorMsg(undefined)
    setPreviewLoading(true)
    return restClientInstance('CUSTOM_GET', 'custom', {
      url:
        'orgs/' +
        props?.project?.org_id +
        '/projects/' +
        props?.project?.id +
        '/systems/' +
        selectedSystem?.uuid +
        '/' +
        selectedPaymentOptionId +
        '/contract_docusign_envelope_id/',
      data: {
        is_draft: true,
      },
    })
      .then((res: any) => {
        if (res?.data?.envelope_id) setDraftEnvelopeId(res.data.envelope_id)
      })
      .catch((err: any) => {
        console.log('err.body', err?.body)
        if (err?.body?.error_message) notify(err.body.error_message, 'warning')
        else notify('We were unable to generate the draft envelope for this project', 'warning')
        logAmplitudeEvent('docusign_preview_error', { project_id: props.project.id })
      })
      .finally(() => setPreviewLoading(false))
  }

  const redirectToDocusign = () => {
    const restClientInstance = restClient(window.API_ROOT + '/api')
    return restClientInstance('CUSTOM_POST', 'custom', {
      url:
        'orgs/' +
        props?.project?.org_id +
        '/projects/' +
        props?.project?.id +
        '/systems/' +
        selectedSystem?.uuid +
        '/' +
        selectedPaymentOptionId +
        '/contract_docusign_envelope_url/',
      data: { envelope_id: draftEnvelopeId, is_draft: true },
    })
      .then((res: any) => {
        if (res?.data?.envelope_url) {
          setEnvelopeUrl(res.data.envelope_url)
        } else if (res.data.error_code) {
        }
      })
      .catch((err: any) => {
        notify('We were unable to generate a preview of the contract for this project', 'warning')
        logAmplitudeEvent('docusign_preview_error', { project_id: props.project.id })
      })
  }

  const resetContract = () => {
    setResetLoading(true)
    restClientInstance('CUSTOM_POST', 'custom', {
      url: 'orgs/' + props.project.org_id + '/projects/' + props.project.id + '/reset_contract/docusign/',
    })
      .then((res: any) => {
        if (res?.data?.success) {
          logAmplitudeEvent('docusign_contract_reset', { project_id: props.project.id })
          notify('The Docusign contract has been reset', 'success')
          props.onClose()
        }
      })
      .catch((err: any) => {
        console.log('err')
        logAmplitudeEvent('docusign_contract_reset_error', { project_id: props.project.id })
        notify('We were unable to reset the Docusign contract for this project', 'warning')
      })
      .finally(() => setResetLoading(false))
  }

  const goToUrl = () => {
    window.open(envelopeUrl, '_blank')
    logAmplitudeEvent('docusign_preview_viewed', { project_id: props.project.id })
    props.onClose()
  }

  const renderForm = () => {
    if (envelopeUrl) {
      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <p>
              {translate(
                "When you are ready, click the button below to open a new tab that lets you preview the Customer's view of the contract in a draft envelope."
              )}
            </p>
            <p>
              {translate(
                'The draft envelope will not have the signatures and interactive elements that the customer will see when they open the envelope from the proposal'
              )}
            </p>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <ProUXButton label="Go To Docusign" type="primary" onClick={goToUrl} />
          </Grid>
        </Grid>
      )
    }
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div>
            {translate(
              'You can use the form below to preview the Docusign contract that your customer would see for a given system and payment option. Clicking the "Preview Contract" button below will make a draft envelope in your docusign account and nothing will be sent to the customer.'
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <h2 style={{ marginTop: '30px' }}>{translate('Select a System')}</h2>
          <RadioGroup
            style={{ alignItems: 'flex-start' }}
            name="pmt-options"
            value={`${selectedSystem?.uuid}`}
            onChange={handleSystemChange}
          >
            {systems &&
              systems.map((system) => {
                return (
                  <FormControlLabel
                    key={system.uuid}
                    value={system.uuid}
                    control={<Radio />}
                    label={<span style={{ fontSize: 13 }}>{system.title}</span>}
                  />
                )
              })}
          </RadioGroup>
        </Grid>
        <Grid item xs={12} sm={6}>
          <h2 style={{ marginTop: '30px' }}>{translate('Select a Payment Option')}</h2>
          {selectedSystem && availablePaymentOptions?.length === 0 && (
            <div style={{ color: 'red' }}>
              {translate(
                'You must add a Sungage payment option to this system before you can send a credit application'
              )}
            </div>
          )}
          <RadioGroup value={`${selectedPaymentOptionId}`} onChange={handlePmtOptionChange}>
            {availablePaymentOptions.map((pmtOption) => {
              return (
                <FormControlLabel
                  key={pmtOption.id}
                  value={`${pmtOption.id}`}
                  control={<Radio disabled={!selectedSystem} checked={`${pmtOption.id}` === selectedPaymentOptionId} />}
                  label={<span style={{ fontSize: 13 }}>{pmtOption.title}</span>}
                />
              )
            })}
          </RadioGroup>
          {!availablePaymentOptions?.length && <span className="small">Please select the system first</span>}
        </Grid>
        {(!customerEmail || !customerPhone) && (
          <Grid item xs={12}>
            <div style={{ color: 'red', textAlign: 'center' }}>
              {translate(
                'Please make sure the project is saved with a customer that has a valid phone number and email address.'
              )}
            </div>
          </Grid>
        )}
        {errorMsg && (
          <Grid item xs={12}>
            <p style={{ color: 'red', textAlign: 'center', width: '100%' }}>{errorMsg}</p>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
        >
          <div style={{ margin: '10px 20px' }}>
            <ProUXButton
              label="Preview Contract"
              type="primary"
              onClick={startPreview}
              loading={previewLoading}
              disabled={
                previewLoading ||
                !selectedSystem ||
                !selectedPaymentOptionId ||
                !customerEmail ||
                !customerPhone ||
                resetLoading
              }
            />
          </div>
        </Grid>
        {hasExisting && (
          <Grid
            item
            xs={12}
            style={{
              marginTop: '60px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '15px',
            }}
          >
            <h4>Want to Reset the Current Contract?</h4>
            <p>
              If you would like to clear out the existing Docusign contract on this project and make a new contract
              available you can do so by clicking "Reset Contract" below. This only makes the contract available for
              signature in the online proposal, it won't send a notification to your customer.
            </p>
            <ProUXButton
              label="Reset Contract"
              type="secondary"
              onClick={resetContract}
              loading={resetLoading}
              disabled={previewLoading}
            />
          </Grid>
        )}
      </Grid>
    )
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Manage your Docusign Contract</DialogTitle>
      <DialogContent>{renderForm()}</DialogContent>
    </Dialog>
  )
}
export default ManageDocusignDialog

import { useInput } from 'ra-core'
import React, { Suspense } from 'react'
import { useTranslate } from 'react-admin'

// for the web app
// this will be commented-out by build.py
const EditInstance = React.lazy(() => import('./EditorComponent'))

// for the mobile app
// this will be un-commented by build.py
//import EditInstance from './EditorComponent'

const LoadingAnimation = () => (
  <div className="froala-loading-animation-wrapper">
    <div className="froala-loading-animation">
      <div>
        <span></span>
      </div>
      <div>
        <span></span>
      </div>
      <div>
        <span></span>
      </div>
      <div>
        <span></span>
      </div>
      <span>LOADING...</span>
    </div>
  </div>
)

export const EditorField = React.memo(({ value, handleChange, ...rest }) => {
  const translate = useTranslate()
  return (
    <div>
      <Suspense fallback={<LoadingAnimation />}>
        <EditInstance {...rest} value={value} translate={translate} handleChange={handleChange} />
      </Suspense>
    </div>
  )
})

const EditorComponent = (props) => {
  const source = props.source || props['fieldName']

  const {
    input: { onChange, onBlur, value },
  } = useInput({
    ...props,
    source,
  })
  return <EditorField {...props} onBlur={onBlur} fieldName={source} value={value} handleChange={onChange} />
}
export default EditorComponent

import { Tooltip, makeStyles } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import { Chip } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'

type PropTypes = {
  status: string
}

const paidInvoices = ['Paid']
const overdueInvoices = ['Overdue']
const cancelledInvoices = ['Cancelled']
const outstandingInvoices = ['Viewed', 'Sent', 'Awaiting Transfer', 'Other']
const inProgressInvoices = ['Pending', 'Approved', 'Partially Paid']

const useStyles = makeStyles<OpenSolarThemeType, { status }>((theme) => ({
  statusChip: {
    backgroundColor: ({ status }) => {
      if (!status) {
        return 'rgb(255, 255, 255)'
      } else if (paidInvoices.includes(status)) {
        return 'rgb(235, 251, 241)'
      } else if (overdueInvoices.includes(status)) {
        return 'rgb(249, 234, 237)'
      } else if (cancelledInvoices.includes(status)) {
        return 'rgb(255, 246, 197)'
      } else if (outstandingInvoices.includes(status)) {
        return 'rgb(236, 240, 250)'
      } else if (inProgressInvoices.includes(status)) {
        return 'rgb(255 223 156)'
      }
    },

    color: ({ status }) => {
      if (!status) {
        return undefined
      } else if (paidInvoices.includes(status)) {
        return 'rgb(17, 128, 48)'
      } else if (overdueInvoices.includes(status)) {
        return 'rgb(193, 27, 4)'
      } else if (cancelledInvoices.includes(status) || inProgressInvoices.includes(status)) {
        return 'rgb(94, 94, 94)'
      } else if (outstandingInvoices.includes(status)) {
        return 'rgb(27, 60, 146)'
      } else return undefined
    },

    padding: '8px 12px',
    borderRadius: 5,
    fontWeight: 500,
  },
}))

const INVOICE_STATUS_TOOLTIP_MAP = {
  'Not Sent': 'Invoice has not yet been sent to the customer.',
  Sent: 'Invoice and payment link has been sent to the customer.',
  Viewed: 'Payment link has been viewed by the customer but the payment is not yet complete.',
  'Awaiting Transfer': 'Customer saw the payment instructions but has not yet taken action.',
  Pending: 'Customer authorized the payment, but it has not yet been confirmed.',
  Approved: 'Customer payment was approved and will reach your bank account shortly.',
  'Partially Paid': 'Partial payment was complete and sent to your bank account.',
  Paid: 'The customer has completed their payment.',
  Overdue: 'Payment was not authorized prior to the due date.',
  Cancelled: 'Invoice was cancelled by your team.',
  'Payment Failed':
    'Payment was not successful. Customer has been prompted to retry but you can send the payment link again.',
  'Chargeback pending': 'Customer has initiated chargeback procedure. OpenSolar will be in contact about next steps.',
  'Chargeback complete': 'Chargeback has been processed.',
  'Refund Pending': 'Refund process has been initiated but is not yet complete.',
  Refunded: 'Payment was refunded by your team.',
  Other: 'Invoice is in an exception status - reach out to OpenSolar for more information.',
}

const InvoiceStatusChip: React.FC<PropTypes> = (props) => {
  const invoiceStatus = (status) => {
    if (status) {
      switch (status) {
        case 'requested':
          return 'Sent'
        case 'cancelled':
          return 'Cancelled'
        case 'expired':
          return 'Overdue'
        case 'chargeback pending':
          return 'Chargeback Pending'
        case 'chargeback complete':
          return 'Chargeback Complete'
        case 'paid in full':
          return 'Approved'
        case 'partially paid':
          return 'Partially Paid'
        case 'payment failed':
          return 'Payment Failed'
        case 'refund pending':
          return 'Refund Pending'
        case 'refunded':
          return 'Refunded'
        case 'transfer instructions viewed':
          return 'Awaiting Transfer'
        case 'viewed':
          return 'Viewed'
        case 'unsent':
          return 'Not Sent'
        case 'pending':
          return 'Pending'
        case 'completed':
          return 'Paid'
        default:
          return 'Other'
      }
    }
  }
  const status = invoiceStatus(props.status)
  const classes = useStyles({ status })
  const translate = useTranslate()

  if (!status) return null
  return (
    <Tooltip title={translate(INVOICE_STATUS_TOOLTIP_MAP[status])}>
      <Chip label={translate(status)} variant="default" className={classes.statusChip} size="small" />
    </Tooltip>
  )
}

export default InvoiceStatusChip

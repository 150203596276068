import Alert from 'elements/Alert'
import InfoTooltip from 'elements/tooltip/InfoTooltip'
import { FormApi, FormState } from 'final-form'
import { useUxVersion } from 'hooks/useUxVersion'
import {
  StyledDialog,
  StyledDialog3,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from 'layout/StyledDialog'
import { FC, ReactFragment, ReactNode, useMemo } from 'react'
import { Button, useTranslate } from 'react-admin'
import { useSubForm } from './useSubForm'
export interface FormProps {
  formState: FormState<any>
  form: FormApi<any>
}

type Props = {
  title: ReactNode
  parentForm: FormApi
  open: boolean
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  onClose: () => void
  onSaved?: () => void // Defaults to 'onClose' if unspecified
  mutators?: any
  allowInvalidSubmit?: boolean
  customToolbar?: (props: ActionsPropTypes) => JSX.Element
  initialValues?: Object
  disableClose?: boolean
  validate?: (values: Object) => Object
}

/**
 * This lets you have a dialog form within a main react-admin
 * form whose changes can be independently discarded.
 *
 * Saving the dialog actually just updates the parent react-admin form.
 * This form doesn't do any loading/saving via the rest client.
 *
 * example usage:
 * ```jsx
 *    const form = useForm();
 *    const [open, setOpen] = useState(false);
 *
 *    return (
 *    <>
 *        <SubFormDialog
 *           open={open}
 *           onClose={() => setOpen(false)}
 *           parentForm={form}
 *           title="My Sub Form"
 *           mutators={mutations}  // The project form has some special mutators that should be reused here
 *           >
 *           <!--  Any react-admin form elements can go here -->
 *           <TextInput source="name" />
 *        </SubFormDialog>
 *
 *        <Button onClick={() => setOpen(true)}>Open Sub Form</Button>
 *    </>
 *    );
 *
 * ```
 */
export const SubFormDialog: FC<Props> = ({
  title,
  parentForm,
  children,
  open,
  maxWidth,
  mutators,
  onClose,
  onSaved,
  allowInvalidSubmit,
  customToolbar,
  initialValues,
  disableClose,
  validate,
}) => {
  const subForm = useSubForm({
    parentForm,
    onSaved: onSaved || onClose,
    mutators,
    disconnected: !open,
    render: () => children,
    allowInvalidSubmit,
    initialValues,
    validate,
  })
  const ui3Enabled = useUxVersion() === 3
  const DialogComponent = useMemo(() => (ui3Enabled ? StyledDialog3 : StyledDialog), [ui3Enabled])
  return (
    <DialogComponent open={open} maxWidth={maxWidth} onBackdropClick={onClose}>
      <StyledDialogTitle onClose={disableClose ? undefined : onClose}>{title}</StyledDialogTitle>
      <StyledDialogContent>{subForm.render()}</StyledDialogContent>
      <SubFormActions
        customToolbar={customToolbar}
        onDiscard={onClose}
        form={subForm.form}
        onSave={subForm.save}
        formState={subForm.formState}
        hasValidationErrors={subForm.hasValidationErrors}
        formDirty={subForm.formDirty}
        allowInvalidSubmit={!!allowInvalidSubmit}
      />
    </DialogComponent>
  )
}

export interface ActionsPropTypes {
  onDiscard: () => void
  onSave: () => void
  formState: FormState<any> | undefined
  form: FormApi<any> | undefined
  hasValidationErrors: boolean
  formDirty: boolean
  allowInvalidSubmit: boolean
  customToolbar?: (props: ActionsPropTypes) => JSX.Element
}

const SubFormActions = (props: ActionsPropTypes) => {
  const { onDiscard, onSave, formState, hasValidationErrors, formDirty, allowInvalidSubmit, customToolbar } = props
  const translate = useTranslate()
  if (customToolbar) return customToolbar(props)
  return (
    <StyledDialogActions>
      {/* As custom forms don't show errors well inline, we'll show here */}
      {hasValidationErrors && (
        <Alert severity="warning" margin="dense" styles={{ marginLeft: 12, marginRight: 8, flexGrow: 1 }}>
          {translate('Errors in form, please address')}
          <InfoTooltip size="small" title={formatErrors(formState?.errors)} style={{ marginLeft: 4 }} />
        </Alert>
      )}
      <Button variant="contained" color="default" size="large" onClick={onDiscard}>
        <>{translate('Discard')}</>
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={onSave}
        disabled={!formState || !formDirty || (!allowInvalidSubmit && hasValidationErrors)}
      >
        <>{translate('Accept')}</>
      </Button>
    </StyledDialogActions>
  )
}

const formatErrors = (errors?: { [key: string]: string[] | string }): ReactFragment => {
  const errorEls: JSX.Element[] = []
  if (errors) {
    for (const key in errors) {
      const err = errors[key]
      const errorArray: string[] = typeof err === 'string' ? [err] : err
      errorArray.forEach((error, index) => {
        errorEls.push(<div key={key + '.' + index}>{error}</div>)
      })
    }
  }
  return <>{errorEls}</>
}

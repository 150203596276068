import { getSupplierConfig } from 'pages/ordering/configs'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import React from 'react'
import type { FilterComponentNode, FilterComponentRendererType, FilterGenericPropsType } from '../type'
import DistributorFilter, { DistributorFilterOptionsType } from './DistributorFilter'

export const DISTRIBUTOR_FILTER_KEY = 'require_distributor'

const maybeCreateDistributorFilterNode = ({
  selectedDistributor,
  enabledDistributors,
  RendererComponent,
}: {
  selectedDistributor: HardwareSupplierEnum | undefined
  enabledDistributors: HardwareSupplierEnum[] | undefined
  RendererComponent: FilterComponentRendererType<DistributorFilterOptionsType>
}): FilterComponentNode | undefined => {
  if (!enabledDistributors || enabledDistributors.length === 0) {
    return
  }
  const FilterComponent = (props: FilterGenericPropsType) => (
    <DistributorFilter
      filterKey={DISTRIBUTOR_FILTER_KEY}
      distributors={enabledDistributors}
      RendererComponent={RendererComponent}
      {...props}
    />
  )

  const getDefaultValue = () => {
    return { [DISTRIBUTOR_FILTER_KEY]: getSupplierConfig(selectedDistributor)?.filterKey || 'all' }
  }

  return {
    key: DISTRIBUTOR_FILTER_KEY,
    component: FilterComponent,
    getDefaultValue,
  }
}

export default maybeCreateDistributorFilterNode

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var DotHorizontalIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? '1rem' : _b, _c = _a.color, color = _c === void 0 ? 'currentColor' : _c, rest = __rest(_a, ["size", "color"]);
    var iconAttributes = {
        width: size,
        height: size,
        stroke: color,
    };
    return (_jsxs("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 6", fill: "none" }, rest, iconAttributes, { children: [_jsx("path", { d: "M5.20039 3.0001C5.20039 4.32558 4.12587 5.4001 2.80039 5.4001C1.47491 5.4001 0.400391 4.32558 0.400391 3.0001C0.400391 1.67461 1.47491 0.600098 2.80039 0.600098C4.12587 0.600098 5.20039 1.67461 5.20039 3.0001Z", fill: color }), _jsx("path", { d: "M12.4004 3.0001C12.4004 4.32558 11.3259 5.4001 10.0004 5.4001C8.67491 5.4001 7.60039 4.32558 7.60039 3.0001C7.60039 1.67461 8.67491 0.600098 10.0004 0.600098C11.3259 0.600098 12.4004 1.67461 12.4004 3.0001Z", fill: color }), _jsx("path", { d: "M19.6004 3.0001C19.6004 4.32558 18.5259 5.4001 17.2004 5.4001C15.8749 5.4001 14.8004 4.32558 14.8004 3.0001C14.8004 1.67461 15.8749 0.600098 17.2004 0.600098C18.5259 0.600098 19.6004 1.67461 19.6004 3.0001Z", fill: color })] })));
};

import { HelpLink } from 'elements/link/HelpLink'
import React from 'react'
import { useTranslate } from 'react-admin'
import { CostingCreate, CostingEdit, CostingList } from './Costings'

const CostingTitle = ({ record, translate, style }) => {
  return (
    <span style={style}>
      {translate('My Costing Schemes')} {record ? `"${record.title}"` : ''}
    </span>
  )
}

const Subtitle = () => {
  const translate = useTranslate()
  return (
    <span>
      {translate(
        "You can include all of your project costs within OpenSolar, which allows you to track profitability and use 'cost-plus' pricing schemes."
      )}
      <HelpLink href="https://support.opensolar.com/hc/en-us/articles/4407182185625" />
    </span>
  )
}

export default {
  list: CostingList,
  edit: CostingEdit,
  create: CostingCreate,
  options: {
    create: {
      title: 'Create Costing Scheme',
      subtitle: null,
      breadCrumb: 'Create Costing Scheme',
    },
    list: {
      title: 'Costing Schemes',
      subtitle: <Subtitle />,
      breadCrumb: 'Costing Schemes',
    },
    edit: {
      title: <CostingTitle />,
      subtitle: null,
      breadCrumb: 'Edit Costing Scheme',
    },
  },
}

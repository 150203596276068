import { setMfaCheckRequired, setMfaConfigRequired } from 'ducks/auth_mfa_actions'
import { Button } from 'opensolar-ui'
import MfaChallenge from 'pages/mfa/MfaChallenge'
import React, { FC } from 'react'
import { useTranslate } from 'react-admin'
import { useDispatch } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import SignInButton from './actions/SignInButton'

const useStyles = makeOpenSolarStyles((theme: any) => ({
  button: { marginTop: '15px' },
  wrapper: {
    width: 360,
  },
}))

type MfaChallengeFormContentPropsType = {
  handleSubmitSms(): void
  onCancel?(): void
  disableSignIn: boolean
}

const MfaChallengeFormContent: FC<MfaChallengeFormContentPropsType> = ({
  handleSubmitSms,
  onCancel,
  disableSignIn,
}) => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const handleCancel = () => {
    dispatch(setMfaCheckRequired(false))
    dispatch(setMfaConfigRequired(false))
    onCancel?.()
  }
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      <MfaChallenge onSubmitSms={handleSubmitSms} />
      <SignInButton disableSignIn={disableSignIn} />
      <Button onClick={handleCancel} color="default" fullWidth className={classes.button}>
        <span>{translate('Cancel')}</span>
      </Button>
    </div>
  )
}

export default MfaChallengeFormContent

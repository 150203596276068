// @ts-nocheck
import { ClickAwayListener, TextField } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import { featureConfigSelectors } from 'ducks/featureConfig'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDebouncedCallback } from 'util/Debounce'
import PlaceholderSearchSuggestion from './PlaceholderSearchSuggestion'
import { FieldPathType, PlaceholderBlackListType, PlaceholderWhiteListType } from './ProjectPlaceholderWizard'
import { formatPlaceholderLabel } from './util'

type PlaceholderOptionType = {
  label: string
  field_path: string
  is_draggable: boolean
}

type PropTypes = {
  proposalData: Object
  activeData: Object
  startDrag: (fieldName: string, value: string) => void
  endDrag: () => void
  fieldPath: FieldPathType | undefined
}

const PlaceholderSearch: React.FunctionComponent<PropTypes> = (props) => {
  const [textVal, setTextVal] = useState<string | undefined>(undefined)
  const [options, setOptions] = useState<PlaceholderOptionType[]>([])
  const [showOptions, setShowOptions] = useState<boolean>(false)

  const whitelistVals: PlaceholderWhiteListType | undefined = useSelector((state) =>
    featureConfigSelectors.getFeatureConfigData(state, 'placeholder_whitelist')
  )

  const blacklistPaths: PlaceholderBlackListType | undefined = useSelector((state) =>
    featureConfigSelectors.getFeatureConfigData(state, 'placeholder_blacklist')
  )

  useEffect(() => {
    if (textVal !== undefined) debounceSearchOptions(textVal)
  }, [textVal])

  const debounceSearchOptions = (val: string) => {
    doDebouncedSearchOptions(val)
  }

  const getMatchStrength = (fieldKey: string, searchString: string, pathSoFar: string | undefined) => {
    const fieldName = fieldKey
    if (fieldKey && searchString) {
      fieldKey = formatPlaceholderLabel(fieldKey, pathSoFar, whitelistVals)
      if (!fieldKey) return 0
      fieldKey.replaceAll('_', ' ')
      if (fieldKey.toLowerCase().includes(searchString)) {
        return { strength: 1, label: fieldKey, field_path: pathSoFar, fieldName }
      }
    }
    return { strength: 0, label: undefined, field_path: undefined, fieldName }
  }

  const doDebouncedSearchOptions = useDebouncedCallback((val: string) => {
    if (!val || val.length === 1) return
    if (val) val = val.toLowerCase()
    let matches: PlaceholderOptionType[] = []
    const checkObjectKeys = (searchObj: Object, pathSoFar: string | undefined) => {
      if (!searchObj) return
      Object.keys(searchObj).forEach((fieldKey: string) => {
        let isArray = Array.isArray(searchObj[fieldKey])
        let isObj = !isArray && typeof searchObj[fieldKey] === 'object'
        if (!isObj) {
          try {
            const asObj = JSON.parse(searchObj[fieldKey])
            if (Array.isArray(asObj)) isArray = true
            else if (typeof asObj === 'object') isObj = true
          } catch (ex) {}
        }
        const match = getMatchStrength(fieldKey, val, pathSoFar)
        if (match.strength > 0) {
          if (!blacklistPaths || !blacklistPaths?.full_paths?.includes(`${match?.field_path}.${match.fieldName}`)) {
            matches.push({
              label: match.label,
              field_path: match.field_path,
              is_draggable: true,
              fieldName: match.fieldName,
            })
          }
        }
        if (isObj || isArray) {
          let newPathSoFar = pathSoFar ? pathSoFar + '.' + fieldKey : fieldKey
          try {
            let parsed = searchObj[fieldKey]
            if (!Array.isArray(searchObj[fieldKey]) && typeof searchObj[fieldKey] !== 'object') {
              parsed = JSON.parse(searchObj[fieldKey])
            }
            if (isArray) {
              parsed.forEach((arrayElement, i) => {
                try {
                  let childPathSoFar = newPathSoFar + '.' + i
                  checkObjectKeys(arrayElement, childPathSoFar)
                } catch (ex) {
                  console.log('child exception', ex)
                }
              })
            } else {
              checkObjectKeys(parsed, newPathSoFar)
            }
          } catch (ex) {}
        }
      })
    }

    checkObjectKeys(props.proposalData, undefined)
    if (matches && matches.length > 0) {
      setOptions(matches.slice(0, 10))
    } else {
      setOptions([])
    }
    setShowOptions(true)
  }, 300)

  const onInputFocus = () => {
    if (options?.length > 0 && textVal?.length > 1 && !showOptions) setShowOptions(true)
  }

  const endDrag = () => {
    props.endDrag()
    setTextVal('')
    setOptions([])
    setShowOptions(false)
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        height: '30px',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
        <div style={{ flex: 7, paddingLeft: '10px' }}>
          <TextField
            placeholder="Search Placeholders"
            value={textVal}
            onChange={(e: ChangeEvent) => setTextVal(e.target.value)}
            fullWidth={true}
            style={{ width: '100%' }}
            onFocus={onInputFocus}
          />
        </div>
        <div style={{ flex: 1 }}>
          <Search />
        </div>
      </div>
      {showOptions && (
        <ClickAwayListener onClickAway={() => setShowOptions(false)}>
          <div
            style={{
              position: 'absolute',
              left: '0px',
              top: props.fieldPath ? '70px' : '30px',
              backgroundColor: 'white',
              width: '100%',
              boxShadow: '0px 1px 3px rgba(0,0,0,0.3)',
              cursor: 'pointer',
            }}
          >
            {options?.map((opt, i) => (
              <PlaceholderSearchSuggestion
                i={i}
                label={opt.label}
                fieldName={opt.fieldName}
                path={opt.field_path}
                activeData={props.activeData}
                proposalData={props.proposalData}
                startDrag={props.startDrag}
                endDrag={endDrag}
              />
            ))}
          </div>
        </ClickAwayListener>
      )}
    </div>
  )
}
export default PlaceholderSearch

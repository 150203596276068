import { makeStyles } from '@material-ui/core/styles'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { updateCourseData, updateShowLearn } from 'ducks/learning'
import FullScreenLoading from 'layout/widgets/FullScreenLoading'
import { loadAcademyData } from 'pages/home/ActionButtons'
import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { parseQueryStringToDictionary } from 'util/misc'
import { getOrgFromState } from 'util/org'
import Courses from './Courses'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'block',
    background: 'rgba(255,255,255)',
    position: 'fixed',
    width: '100%',
    height: 'calc(100vh - 50px)',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: { height: 'calc(100vh - 100px)' },
  },
  h1: {
    textAlign: 'left',
    margin: '30px auto 0',
    paddingLeft: 75,
    maxWidth: 750,
    fontWeight: 400,
    fontSize: 20,
    [theme.breakpoints.down(450)]: { paddingLeft: 45 },
  },
  p: {
    textAlign: 'left',
    margin: '20px auto -20px',
    paddingLeft: 75,
    maxWidth: 750,
    color: 'rgba(0,0,0,0.54)',
    [theme.breakpoints.down(450)]: { paddingLeft: 45 },
  },
}))

export const MyLearning = memo((props) => {
  const classes = useStyles()
  const {
    search,
    // , state
  } = useLocation()

  const countryIso2 = useSelector((state: any) => getOrgFromState(state)?.country?.iso2)
  const orgId = useSelector((state: any) => getOrgFromState(state)?.id)
  const { course } = parseQueryStringToDictionary(search) as any
  var courseData = useSelector((state) => (state as any).learning.courseData)

  const [isLoading, setIsLoading] = useState(true)
  const [trackingTimeStart, setTrackingTimeStart] = useState<Date | undefined>(undefined)

  const dispatch = useDispatch()

  const boundUpdateCourseData = (value) => dispatch(updateCourseData(value))
  const boundUpdateShowLearn = (value) => dispatch(updateShowLearn(value))

  useEffect(() => {
    loadAcademyData(countryIso2, courseData, null, setIsLoading, boundUpdateCourseData, boundUpdateShowLearn)
  }, [orgId])

  // track page view just once on mount
  useEffect(() => {
    logAmplitudeEvent('course_page_viewed', {})
  }, [])

  useEffect(() => {
    if (course && !trackingTimeStart) {
      setTrackingTimeStart(new Date())
    } else if (!course && trackingTimeStart) {
      // @ts-ignore
      logAmplitudeEvent('returned_from_course', { duration: Math.round((new Date() - trackingTimeStart) / 1000) })
      setTrackingTimeStart(undefined)
    }
  }, [course, trackingTimeStart])

  return isLoading || !courseData ? (
    <FullScreenLoading />
  ) : (
    <div className={classes.wrapper}>
      {course ? (
        <iframe src={course} height="100%" width="100%" frameBorder="0" />
      ) : (
        <>
          <h1 className={classes.h1}>{courseData.pageTitle}</h1>
          <p className={classes.p}>{courseData.pageDescription}</p>
          <div
            style={{
              display: 'flex',
              padding: 15,
              justifyContent: 'space-evenly',
              margin: 'auto',
              maxWidth: 750,
              flexWrap: 'wrap',
            }}
          >
            {courseData &&
              courseData.content &&
              courseData.content
                .filter((course) => course.countries.includes(countryIso2))
                .map(
                  (
                    course: {
                      title?: string
                      description?: string
                      logo: { url: string }
                      image: { url: string }
                      login: { url: string }
                      signup: { url: string }
                      type: 'iframe' | 'launch' | undefined
                    },
                    index: React.Key | null | undefined
                  ) => (
                    <Courses
                      key={index}
                      title={course.title}
                      description={course.description}
                      logo={course.logo.url}
                      image={course.image.url}
                      login={course.login.url}
                      signup={course.signup.url}
                      type={course.type}
                      // countries={course.countries}
                      // states={course.states}
                    />
                  )
                )}
          </div>
        </>
      )}
    </div>
  )
})

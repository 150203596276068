import { useCallback, useState } from 'react'
import useVtacService from 'services/vtac/useVtacService'

const useAutoSyncVtacPrice = () => {
  const [isSyncing, setIsSyncing] = useState(false)

  const vtacService = useVtacService()

  const syncVtacPrices = useCallback(
    async ({ distributor }) => {
      setIsSyncing(true)
      await vtacService.syncPrices({ distributor })
      setIsSyncing(false)
    },
    [isSyncing]
  )

  return { isSyncing, syncVtacPrices }
}

export default useAutoSyncVtacPrice

import _ from 'lodash'
import { PaymentRequestType } from 'pages/cashFlow/types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ExpectedMilestonePaymentType } from 'types/paymentOptions'
import { RootState } from 'types/state'

export const useIsCalculating = (systemUuid: string | undefined | null) => {
  const systemCalculation = useSelector((state: RootState) => state.designer.systemCalculation)
  const { processQueue } = systemCalculation
  const isCalculating = systemUuid && processQueue[systemUuid]?.status === 'waitingCalculation'

  return Boolean(isCalculating)
}

export const createNewMilestone = (
  existingMilestones: ExpectedMilestonePaymentType[]
): ExpectedMilestonePaymentType => {
  const newMilestone: ExpectedMilestonePaymentType = {
    percentage: 0,
    title: '',
    description: null,
    payment_amount: 0,
    currency: existingMilestones[0]?.currency,
    is_paid: false,
    is_next: false,
    payment_number: existingMilestones?.length,
    payment_milestone_configuration_id: null,
    payment_request_id: null,
    date_requested: null,
    date_due: null,
    date_completed: null,
    is_deposit: false,
  }
  return newMilestone
}

export const updatPriceOverrideAndRecalc = (systemUuid: string, paymentOptionId: number, systemPrice: string) => {
  const system = window.editor.getSystems()?.find((sys) => sys.uuid === systemUuid)
  let priceOverride = _.cloneDeep(system?.payment_options_settings_overrides) || {}
  // force the system to not use auto-applied payment options since we are now overriding one
  let overidePaymentOptionIds = system?.payment_options_override || system?.payment_options.map((po) => po.id)
  overidePaymentOptionIds = overidePaymentOptionIds?.filter((value, index, self) => {
    return self.indexOf(value) === index
  })
  if (!priceOverride?.[paymentOptionId]) {
    priceOverride[paymentOptionId] = {
      down_payment: 0,
      price: parseFloat(systemPrice),
    }
  } else {
    priceOverride[paymentOptionId].price = parseFloat(systemPrice)
  }
  window.editor.execute(new window.SetValueCommand(system, 'payment_options_override', overidePaymentOptionIds))
  window.editor.execute(new window.SetValueCommand(system, 'payment_options_settings_overrides', priceOverride))
}

export const useGetLastPaymentRequestForMilestone = (
  allPaymentRequests: PaymentRequestType[],
  milestone: ExpectedMilestonePaymentType
) => {
  const lastRequest = useMemo(() => {
    if (!allPaymentRequests?.length) return null
    const matchingRequests = allPaymentRequests?.filter((req) => req.payment_number === milestone.payment_number)
    if (!matchingRequests?.length) return null
    return matchingRequests[matchingRequests.length - 1]
  }, [milestone, allPaymentRequests])

  return lastRequest
}

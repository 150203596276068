import { ModuleGridType, ModuleLayout } from 'types/global'
import type { BasicAccessorConfig } from './types'

const setter = ({ moduleGrid, value }: { moduleGrid: ModuleGridType; value: ModuleLayout }) => {
  window.editor.execute(new window.RedrawModulesCommand(moduleGrid, 'moduleLayout', value, moduleGrid.moduleLayout()))
}

const getter = (moduleGrid: ModuleGridType): ModuleLayout => {
  return moduleGrid.moduleLayout()
}

export const orientationAccessorConfig: BasicAccessorConfig<ModuleLayout> = {
  get: getter,
  set: setter,
}

import { PROJECT_SECTION_CLEAR, PROJECT_SECTION_UPDATE } from 'actions/project'
import { Reducer } from 'redux'
import { SectionType } from 'types/section'

const defaultState = null

export const projectSectionReducer: Reducer<SectionType> = (previousState = defaultState, { type, payload = {} }) => {
  if (type === PROJECT_SECTION_UPDATE) {
    return payload?.section
  } else if (type === PROJECT_SECTION_CLEAR) {
    return null
  } else {
    return previousState
  }
}

import { DialogContent } from '@material-ui/core'
import { permissionsSelectors } from 'ducks/permissions'
import CustomField from 'elements/field/CustomField'
import { UserProfileAddIcon } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useEffect, useMemo } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import Notice from '../../../common/Notice'
import { COLOR_PALETTE } from '../../../styles'
import ContactAccordion from './ContactAccordion'

interface PropTypes {}

const ContactsFormContent: React.FC<PropTypes> = () => {
  const form = useForm()
  const translate = useTranslate()
  const formValues = useFormState().values
  const contacts = useMemo(() => formValues.contacts_data, [formValues.contacts_data])
  const { allowCreate, allowDelete } = useSelector(
    permissionsSelectors.getProjectPermissionByKey('info_contact_info_basic')
  )
  useEffect(() => {
    if (contacts.length === 0) {
      form.change('contacts_data', [{}])
    }
  }, [])

  const handleAssign = (contact, index) => {
    if (!allowCreate) return
    let newContacts = [...contacts]
    newContacts[index] = contact
    form.change('contacts_data', newContacts)
  }

  const handleRemove = (index) => {
    if (!allowDelete) return
    let newContacts = [...contacts]
    newContacts.splice(index, 1)
    form.change('contacts_data', newContacts)
  }

  const handleDeleteContact = (id) => {
    form.change(
      'contacts_data',
      contacts.filter((x) => x.id !== id)
    )
  }

  if (!contacts?.length) {
    return (
      <DialogContent>
        <Notice
          icon={<UserProfileAddIcon size={23} color={COLOR_PALETTE.darkGrey} />}
          mainText={translate('You have no customer contacts in this project')}
          subText={translate(
            'Click Add Contact below to add a new contact or assign an existing contact to this project'
          )}
        />
      </DialogContent>
    )
  }

  return (
    <>
      {contacts?.map((contact, i) => (
        <CustomField
          key={'contact-' + i}
          source={`contacts_data.${i}.id`}
          name={`contacts_data.${i}.id`}
          component={ContactAccordion}
          index={i}
          contact={contact}
          handleAssign={handleAssign}
          handleDeleteContact={handleDeleteContact}
          handleRemove={handleRemove}
        />
      ))}
    </>
  )
}

export default ContactsFormContent

import type { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
export const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  title: {
    fontWeight: 400,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: '16px',
  },
  left: {
    float: 'left',
    fontWeight: 600,
    clear: 'left',
    maxWidth: 'calc(100% - 4em)',
  },
  leftSmall: {
    float: 'left',
    fontWeight: 300,
    lineHeight: '11px',
    clear: 'left',
    fontSize: '10px',
    color: '#a2a2a2',
  },
  right: {
    float: 'right',
  },
  titleWrapper: {
    display: 'flex',
  },
  container: {
    margin: '10px 0',
  },
}))

import { makeStyles, Tooltip } from '@material-ui/core'
import RedoIcon from '@material-ui/icons/RedoOutlined'
import UndoIcon from '@material-ui/icons/UndoOutlined'
import { useUxVersion } from 'hooks/useUxVersion'
import { Button, FlipLeftIcon, FlipRightIcon, IconButton } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { useCallback, useEffect, useState } from 'react'
import { useStudioSignalsLazy } from 'Studio/signals/useStudioSignalsLazy'

const useStyles = makeStyles({
  button: {
    minWidth: 'unset',
    padding: '6px 3px',
  },
})

const historyCanUndo = () => window.editor.history && window.editor.history.canUndo()
const historyCanRedo = () => window.editor.history && window.editor.history.canRedo()

const UndoRedoButtons = ({ layout, disableActions }: { disableActions: boolean; layout: number }) => {
  const [undoDisabled, setUndoDisabled] = useState(!historyCanUndo())
  const [redoDisabled, setRedoDisabled] = useState(!historyCanRedo())
  const translate = useTranslate()
  const classes = useStyles()
  const ui3Enabled = useUxVersion() === 3
  const refreshState = useCallback(() => {
    setUndoDisabled(!historyCanUndo())
    setRedoDisabled(!historyCanRedo())
  }, [])

  useEffect(() => {
    refreshState()
  }, [disableActions])

  useStudioSignalsLazy(refreshState, [
    'sceneLoaded',
    'sceneGraphChanged',
    'objectChanged',
    'objectSelected',
    'systemSelected',
    'controlModeChanged',
    'cameraChanged',
  ])

  return (
    <>
      <Tooltip PopperProps={{ style: { zIndex: 2001 } }} title={translate('Undo')}>
        <span>
          {ui3Enabled ? (
            <IconButton
              color="primary"
              disabled={undoDisabled}
              onClick={() => window.editor.undo()}
              style={{ marginRight: 8 }}
            >
              <FlipLeftIcon />
            </IconButton>
          ) : (
            <Button classes={{ root: classes.button }} disabled={undoDisabled} onClick={() => window.editor.undo()}>
              <UndoIcon style={{ width: layout === 3 ? 20 : 24, marginRight: 0 }} />
            </Button>
          )}
        </span>
      </Tooltip>
      <Tooltip PopperProps={{ style: { zIndex: 2001 } }} title={translate('Redo')}>
        <span>
          {ui3Enabled ? (
            <IconButton color="primary" disabled={redoDisabled} onClick={() => window.editor.redo()}>
              <FlipRightIcon />
            </IconButton>
          ) : (
            <Button classes={{ root: classes.button }} disabled={redoDisabled} onClick={() => window.editor.redo()}>
              <RedoIcon style={{ width: layout === 3 ? 20 : 24, marginRight: 0 }} />
            </Button>
          )}
        </span>
      </Tooltip>
    </>
  )
}

export default UndoRedoButtons

import { Card, CardActions, makeStyles } from '@material-ui/core'
import CheckboxInput from 'elements/input/CheckboxInput'
import { Button } from 'opensolar-ui'
import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import restClient from 'restClient'
import { updateEmailPreferences } from '../../actions/authActions'
import GDPRText from '../../elements/GDPRText'
import AuthModalWrapper from './AuthModalWrapper'

type PropTypes = {
  history: any
}

const useStyles = makeStyles((theme: any) => ({
  card: {
    width: '400px',
    margin: '0 auto',
    minWidth: 210,
    padding: '0.5em 1em 1em 1em',
  },
  button: {
    color: theme.headerFontColor,
    background: theme.themeColor,
    '&:hover': {
      cursor: 'pointer',
      background: theme.themeColorDark,
    },
  },
}))

const EmailPreferences: React.FC<PropTypes> = (props) => {
  const valueFromDb = useSelector((state: any) => state.auth?.user?.email_marketing_opt_in)
  const classes = useStyles()
  const [loading, setLoading] = useState<Boolean>(false)
  const [value, setValue] = useState<Boolean>(valueFromDb)
  const [errorMsg, setErrorMsg] = useState<string | null>(null)
  const translate = useTranslate()
  const history = useHistory()
  const dispatch = useDispatch()

  const handleSubmit = () => {
    setLoading(true)
    var restClientInstance = restClient(window.API_ROOT + '/api')
    restClientInstance('CUSTOM_PATCH', 'custom', {
      url: 'users/email_preferences',
      data: {
        email_marketing_opt_in: value,
      },
    })
      .then((res: any) => {
        //this is pretty hacky but we need to update both the db and redux and authClient doesn't seem to be a clean way to do that
        dispatch(updateEmailPreferences({ email_marketing_opt_in: value }))
        history.push('/configuration')
      })
      .catch((err: any) => {
        setErrorMsg('Unable to save email preferences.')
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return (
    <AuthModalWrapper>
      <Card elevation={0} classes={{ root: classes.card }}>
        <h2 style={{ textAlign: 'center' }}>{translate('Email Preferences')}</h2>
        <div style={{ margin: '30px 0px' }}>
          <CheckboxInput
            input={{
              value,
              onChange: (newVal: boolean) => setValue(newVal),
            }}
            label={translate(GDPRText)}
            elStyle={{ width: '100%', maxWidth: '400px' }}
          />
        </div>
        {errorMsg && <span style={{ color: 'red' }}>{errorMsg}</span>}
        <CardActions>
          <Button
            classes={{ root: classes.button }}
            type="submit"
            disabled={!!loading}
            fullWidth
            onClick={handleSubmit}
          >
            <span>{translate('Save')}</span>
          </Button>
        </CardActions>
      </Card>
    </AuthModalWrapper>
  )
}
export default EmailPreferences

import { makeStyles, Tooltip } from '@material-ui/core'
import { UnsubscribeOutlined } from '@material-ui/icons'
import InvoiceActions from 'projectSections/sections/payments/cashFlowTransactions/invoiceActions'
import React from 'react'
import { OpenSolarThemeType } from 'Themes'
import { PaymentRequestType } from '../types'
import { InvoiceRecord } from './types'

type PropTypes = {
  record: InvoiceRecord
}

const useStyles = makeStyles<OpenSolarThemeType>((theme) => ({
  actionRow: {
    cursor: 'pointer',
    padding: '10px',
  },
  pausedBadge: {
    color: '#BC1D1C',
    padding: '5px 7px 0px',
    background: '#F1D7D7',
    borderRadius: 15,
  },
  row: {
    display: 'flex',
    gap: 10,
    justifyContent: 'end',
    alignItems: 'center',
  },
}))

const InvoiceTableActions: React.FC<PropTypes> = ({ record }) => {
  const classes = useStyles()

  if (!record) return null

  // Try to fill up all required fields by InvoiceActions
  const paymentRequest = {
    id: record.payment_request?.id,
    invoice_number: record.invoice_number,
    invoice: record.id,
    payment_number: record.payment_number,
    date_completed: record.payment_request?.date_completed,
    date_due: record.payment_request?.date_due,
    status: record.status,
    payment_method: record.payment_request?.payment_method,
    payment_option: record.project?.payment_option_sold_id || record.payment_request?.payment_option_id,
    payment_collection_url: record.payment_request?.payment_collection_url,
    invoice_email_reminder_status: record.invoice_email_reminder_status,
    org: record.org_id,
    project: record.project?.id,
  } as PaymentRequestType

  return (
    <div>
      <div className={classes.row}>
        <div className={classes.reminderWrapper}>
          {record.invoice_email_reminder_status === 'paused' ? (
            <div className={classes.pausedBadge}>
              <Tooltip title="Automated email reminders currently stopped.">
                <UnsubscribeOutlined />
              </Tooltip>
            </div>
          ) : null}
        </div>
        <InvoiceActions
          allPaymentRequests={[]} // This will disable send invoice features since this is already "Invoice" table
          paymentRequest={paymentRequest}
          projectId={record.project.id}
          systemUuid={record.project.system_sold_uuid || record.payment_request.system_uuid}
        />
      </div>
    </div>
  )
}

export default InvoiceTableActions

import Alert from 'elements/Alert'
import ProUxButton from 'elements/proUXButtons/ProUXButton'
import React, { useEffect, useState } from 'react'
import { formatCurrency } from 'util/misc'
import { LoanpalDecisionType } from './types'

type PropTypes = {
  decision: LoanpalDecisionType
  onClose: () => void
  amountApproved?: number
  amountRequested?: number
  approvedContacts?: number[]
  appliedWithCoborrower: boolean
}

const LoanpalDecisionExplanation: React.FunctionComponent<PropTypes> = (props) => {
  const [notAllApplicantsApproved, setNotAllApplicantsApproved] = useState<boolean>(false)

  // if application is approved, they applied with a coborrower, but only one person is approved
  useEffect(() => {
    if (
      props.appliedWithCoborrower &&
      props.approvedContacts &&
      props.approvedContacts.length === 1 &&
      ['APPROVED', 'CONDITIONAL'].includes(props.decision)
    ) {
      setNotAllApplicantsApproved(true)
    }
  }, [props.approvedContacts && props.appliedWithCoborrower && props.decision])

  // only used in the event that two people applied but only one is approved
  const getApprovedApplicantName = () => {
    if (!props.approvedContacts || !notAllApplicantsApproved) return null
    return props.approvedContacts[0]
  }

  const renderApplicantWarningText = () => {
    if (!props.approvedContacts) return null
    return (
      <div>
        <Alert severity="info">
          Although you applied with two applicants this approval only applies to {getApprovedApplicantName()}
        </Alert>
      </div>
    )
  }

  return (
    <div
      style={{
        margin: '10px 0px 20px 0px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      {props.decision === 'APPROVED' && (
        <div>
          <img
            src={`${window.PUBLIC_URL}/images/loanpal_logo.svg`}
            alt=""
            height={45}
            style={{ marginBottom: '20px' }}
          />
          <h2>{getApprovedApplicantName() ? `${getApprovedApplicantName()} is Approved` : 'Approved'}</h2>
          <p>Your application for financing has been approved! Please check your email for more details.</p>
          {notAllApplicantsApproved && renderApplicantWarningText()}
        </div>
      )}
      {props.decision === 'DECLINED' && (
        <div>
          <h2>Declined</h2>
          <p>Your application for financing has been declined. Please check your email for more details.</p>
        </div>
      )}
      {props.decision === 'PENDING' && (
        <div>
          <h2>Under Review</h2>
          <p>Your application for financing is under review. Please check your email for more details.</p>
        </div>
      )}
      {props.decision === 'CONDITIONAL' && (
        <div>
          <img
            src={`${window.PUBLIC_URL}/images/loanpal_logo.svg`}
            alt=""
            height={45}
            style={{ marginBottom: '20px' }}
          />
          <h2>
            {getApprovedApplicantName()
              ? `${getApprovedApplicantName()} is Conditionally Approved`
              : 'Conditionally Approved'}
          </h2>
          <p>
            Your application for financing has been approved pending certain documentation requirements. Click
            "Continue" below to view the required documentation and take the next step towards securing your financing
          </p>
          {notAllApplicantsApproved && renderApplicantWarningText()}
        </div>
      )}
      {props.decision === 'APPROVED_COUNTER_OFFER' && (
        <div>
          <img
            src={`${window.PUBLIC_URL}/images/loanpal_logo.svg`}
            alt=""
            height={45}
            style={{ marginBottom: '20px' }}
          />
          <h2>Approved for a reduced loan amount - ${formatCurrency(props.amountApproved)}</h2>
          <p>
            Your application for financing has been approved for a maximum loan amount of
            <strong> ${formatCurrency(props.amountApproved)}</strong> which is below the{' '}
            <strong>${formatCurrency(props.amountRequested)}</strong> you requested. Please check your email or talk to
            your salesperson for more details.
          </p>
        </div>
      )}
      <div style={{ marginTop: '30px' }}>
        <ProUxButton
          type="secondary"
          onClick={props.onClose}
          label={props.decision === 'CONDITIONAL' ? 'Continue' : 'Dismiss'}
        />
      </div>
    </div>
  )
}
export default LoanpalDecisionExplanation

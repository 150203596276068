import { BomGeneratorAbstract } from '../bom_generators/BomGeneratorAbstract'
import {
  PerColumnRailBomGeneratorAbstract,
  VerticalRailPosition,
} from '../bom_generators/PerColumnRailBomGeneratorAbstract'
import {
  HorizontalRailPosition,
  PerRowRailBomGeneratorAbstract,
} from '../bom_generators/PerRowRailBomGeneratorAbstract'
import {
  BasicSpacingLayoutAdjusterAbstract,
  SpacingRuleSet,
} from '../layout_adjusters/BasicSpacingLayoutAdjusterAbstract'
import { MountingSystemAbstract } from '../MountingSystemAbstract'
import { CutAndSplicerAbstract } from '../post_processors/CutAndSplicerAbstract'
import { RoofHookAdderAbstract } from '../post_processors/RoofHookAdderAbstract'
import {
  CompatibilityParameters,
  Component,
  Direction,
  Fastener,
  Item,
  MountingCalcInput,
  MountingCalcResult,
  MountingComponentType,
  Panel,
  PanelBlock,
  QuantityPerProduct,
  RailComponent,
  RailType,
} from '../types'

import { getQuantityOfProducts } from '../utils'

const spacingRuleSet: SpacingRuleSet = {
  interPanel: { horizontalSpacing: 18, verticalSpacing: 18 },
}
class FastensolSpacingAdjuster extends BasicSpacingLayoutAdjusterAbstract {
  spacingRuleSet = spacingRuleSet
}

const railOverhang = 50
class FastensolBomGenerator extends BomGeneratorAbstract {
  async generateBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    const starterBom = await this.generateStarterBom(result)
    return this.postProcess(starterBom)
  }

  generateStarterBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    if (this.block.orientation === 'portrait') {
      return this.generateBomFrom(FastensolPortraitBomGenerator, result)
    } else {
      return this.generateBomFrom(FastensolLandscapeBomGenerator, result)
    }
  }

  postProcess(result: MountingCalcResult): MountingCalcResult {
    const resultWithRoofHooks = new FastensolRoofHookAdder(this.input).process(result)
    const resultWithSplices = new FastensolRailSplicer(this.input).process(resultWithRoofHooks)
    return resultWithSplices
  }
}

export class Fastensol extends MountingSystemAbstract {
  layoutAdjusterClass = FastensolSpacingAdjuster
  bomGeneratorClass = FastensolBomGenerator

  getFastener(): Fastener {
    const railDirection = this.input.panelBlocks[0].moduleGrid.moduleLayout() === 'portrait' ? 'horizontal' : 'vertical'
    return chooseFastener(chooseHook(this.input, null, chooseRail(this.input, railDirection)))
  }

  getCompatibilityParameters(): CompatibilityParameters {
    return {
      integrations: ['City Plumbing'],
      slopeRange: [0, 60],
      roofTypes: ['Tile Clay', 'Tile Concrete', 'Tile Slate'],
      roofTypeRequired: false,
      featureFlag: 'mounting_Fastensol',
    }
  }
}

class FastensolPortraitBomGenerator extends PerRowRailBomGeneratorAbstract {
  chooseRail() {
    return chooseRail(this.input, 'horizontal')
  }

  chooseMidClamp() {
    return chooseMidClamp(this.input)
  }

  chooseEndClamp() {
    return chooseEndClamp(this.input)
  }

  chooseEndCap() {
    return chooseEndCap(this.input)
  }

  getRailPositions(panel: Panel): HorizontalRailPosition[] {
    const distanceFromRailToPanelEdge = (panel.height * 0.6) / 2

    return [
      {
        top: distanceFromRailToPanelEdge,
        left: -railOverhang,
        right: railOverhang,
      },
      {
        top: panel.height - distanceFromRailToPanelEdge,
        left: -railOverhang,
        right: railOverhang,
      },
    ]
  }
}

class FastensolLandscapeBomGenerator extends PerColumnRailBomGeneratorAbstract {
  chooseRail() {
    return chooseRail(this.input, 'vertical')
  }

  chooseMidClamp() {
    return chooseMidClamp(this.input)
  }

  chooseEndClamp() {
    return chooseEndClamp(this.input)
  }

  chooseEndCap() {
    return chooseEndCap(this.input)
  }

  getRailPositions(panel: Panel): VerticalRailPosition[] {
    const distanceFromRailToPanelEdge = (panel.width * 0.6) / 2

    return [
      {
        left: distanceFromRailToPanelEdge,
        top: -railOverhang,
        bottom: railOverhang,
      },
      {
        left: panel.width - distanceFromRailToPanelEdge,
        top: -railOverhang,
        bottom: railOverhang,
      },
    ]
  }
}

function chooseRail(input: MountingCalcInput, direction: Direction): RailComponent {
  const componentCode = input.options.railColour as string
  return {
    type: MountingComponentType.RAIL,
    railType: 'continuous',
    direction,
    name: componentCode,
    length: 0,
    top: 0,
    left: 0,
  }
}

function chooseHook(input: MountingCalcInput, panelBlock: PanelBlock | null, rail: RailComponent) {
  const componentCode = input.options.roofHook as string
  return [
    {
      name: componentCode,
      components: [
        {
          name: componentCode,
          type: MountingComponentType.ROOF_HOOK,
          left: -10,
          top: -10,
        },
      ],
    },
  ]
}

function chooseEndCap(input: MountingCalcInput): Item {
  const componentCode = input.options.endCap as string
  return {
    name: componentCode,
    components: [
      {
        name: componentCode,
        type: MountingComponentType.OTHER,
        left: -18.1,
        top: -24.8,
      },
    ],
  }
}

function chooseEndClamp(input: MountingCalcInput): Item {
  const componentCode = input.options.endClamp as string
  return {
    name: componentCode,
    components: [
      {
        name: componentCode,
        type: MountingComponentType.CLAMP,
        left: -10,
        top: -10,
      },
    ],
  }
}

function chooseMidClamp(input: MountingCalcInput): Item {
  const componentCode = input.options.midClamp as string
  return {
    name: componentCode,
    components: [
      {
        name: componentCode,
        type: MountingComponentType.CLAMP,
        left: -10,
        top: -10,
      },
    ],
  }
}

const fastensolFastenersNeededPerHook: QuantityPerProduct = {
  '2': ['F-TH02', 'F-TH02A'],
  '3': ['F-TH01', 'F-TH06'],
}

function chooseFastener(hook: Item[]): Fastener {
  const hookName = hook[0].name
  const fastenersQty = getQuantityOfProducts(fastensolFastenersNeededPerHook, hookName) as number
  return {
    includedInRoofHookProduct: true,
    name: '',
    qtyPerRoofHook: fastenersQty,
    length: 80,
    diameter: 6.3,
    components: [],
  }
}

class FastensolRoofHookAdder extends RoofHookAdderAbstract {
  spacingRuleSet = spacingRuleSet
  railOverhang = railOverhang

  getRailType(input: MountingCalcInput): RailType {
    return 'continuous'
  }

  chooseHook(input: MountingCalcInput, panelBlock: PanelBlock, rail: RailComponent) {
    return chooseHook(input, panelBlock, rail)
  }

  chooseFastener(hooks) {
    return chooseFastener(hooks)
  }
}

class FastensolRailSplicer extends CutAndSplicerAbstract {
  isTarget(component: Component): boolean {
    return component?.type === MountingComponentType.RAIL
  }

  chooseSplice(railName: string, input: MountingCalcInput): Item[] {
    return [
      {
        name: 'F-RC-S',
        components: [
          {
            type: MountingComponentType.SPLICE,
            name: 'F-RC-S',
            top: 0,
            left: 0,
          },
        ],
      },
    ]
  }

  getFullLength(railName: string): number {
    return 3300
  }
}

import { StudioSignalTypes } from 'types/studio/signals'
import { useStudioSignals, UseStudioSignalsOpts } from './useStudioSignals'

export function useStudioSignalsLazy(
  handler: Function,
  signals: StudioSignalTypes[],
  scope?: object,
  opts?: UseStudioSignalsOpts
) {
  if (!opts) opts = {}
  if (opts.debounce === undefined) opts.debounce = 0.05
  if (opts.maxWait === undefined) opts.maxWait = 0.5
  useStudioSignals(handler, signals, scope, opts)
}

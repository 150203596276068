import { UiKey } from 'elements/UiKey'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import React, { FC } from 'react'
import { BooleanInput, EmailField, TextInput, useTranslate } from 'react-admin'
import { useRoleFormStyles } from './useRoleFormStyles'

type Props = {
  id?: number
  disabled?: boolean
}

const uiKey = 'control.company.team.fields'

const RoleContactFieldsContent: FC<Props> = ({ disabled, id }) => {
  const isCreate = !id
  const translate = useTranslate()
  const inputProps = { id: id + '', resource: 'roles', disabled }
  const classes = useRoleFormStyles()

  return (
    <div>
      <EmailField label="resources.roles.fields.user_email" source="user_email" />
      {!isCreate && (
        <p className="small">
          {translate(
            'Reply emails will be directed to their user email address unless they have specified an optional display email.'
          )}
        </p>
      )}
      <div className={classes.fieldCont} style={{ alignItems: 'center' }}>
        <UiKey uiKey={uiKey + '.email'}>
          <TextInput
            label={!isCreate ? 'resources.roles.fields.email' : 'Send email invitation to...'}
            className={classes.input}
            source="email"
            {...inputProps}
          />
        </UiKey>

        <UiKey uiKey={uiKey + '.contact'}>
          <>
            <TextInput
              label="resources.roles.fields.first_name"
              className={classes.input}
              source="first_name"
              {...inputProps}
            />
            <TextInput
              label="resources.roles.fields.family_name"
              className={classes.input}
              source="family_name"
              {...inputProps}
            />
            <TextInput label="resources.roles.fields.phone" className={classes.input} source="phone" {...inputProps} />
          </>
        </UiKey>

        <UiKey uiKey={uiKey + '.jobTitle'}>
          <TextInput label="resources.roles.fields.job_title" source="job_title" className={classes.input} />
        </UiKey>

        <UiKey uiKey={uiKey + '.accreditation'}>
          <TextInput label="resources.roles.fields.accreditation" source="accreditation" className={classes.input} />
        </UiKey>

        <UiKey uiKey={uiKey + '.allowEmailNotifications'}>
          <BooleanInput
            label="resources.roles.fields.allow_email_notifications"
            source="allow_email_notifications"
            defaultValue={true}
          />
        </UiKey>
      </div>
    </div>
  )
}

export const RoleContactFields: FC<Props> = (props) => {
  const translate = useTranslate()
  return (
    <AccordionCard
      disabled={props.disabled}
      defaultExpanded={true}
      name={'role-contact-info'}
      title={translate('Contact Information')}
      content={RoleContactFieldsContent}
      contentProps={props}
    />
  )
}

import Grid from '@material-ui/core/Grid'
import AttachmentIcon from '@material-ui/icons/AttachmentOutlined'
import GavelIcon from '@material-ui/icons/GavelOutlined'
import LaunchIcon from '@material-ui/icons/LaunchOutlined'
import React from 'react'
import { useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import AccordionCard from '../AccordionCard'
import { complianceForms } from './constants'
import { ComplianceFormType, RecordType } from './types'

type PropTypes = {
  enableDocsForNZ: boolean
}

const PermitsAndServicesContent: React.FunctionComponent<PropTypes> = (props) => {
  const form = useForm()
  const formState = form.getState()
  const translate = useTranslate()
  const record = formState.values as RecordType

  const getNotAvailableMessage = () => {
    if (!record.systems || record.systems.length === 0) {
      return translate('Compliance forms available after system design')
    } else if (record.systems.length > 1 && !record.system_installed) {
      return translate(
        'When a project has multiple systems, compliance forms are only available once the installed system is selected.'
      )
    } else {
      return null
    }
  }

  const countFiles = (record: RecordType, file_tag: any) =>
    record && record.private_files_data && record.private_files_data.length
      ? record.private_files_data.filter(
          (private_file_data) =>
            private_file_data.file_tags_data.filter((file_tag_data) => file_tag_data.title === file_tag).length > 0
        ).length
      : 0

  const filterByGeo = (record: RecordType, items: ComplianceFormType[], enableDocsForNZ: boolean): any[] =>
    items.filter((item: ComplianceFormType) => checkGeoFilters(item, enableDocsForNZ))

  const checkGeoFilters = (item: ComplianceFormType, enableDocsForNZ: boolean): boolean => {
    if (
      record.country_iso2 &&
      record.country_iso2.length > 0 &&
      item.countries &&
      item.countries.indexOf(record.country_iso2) === -1
    ) {
      return false
    }

    if (record.country_iso2 === 'NZ' && enableDocsForNZ === false) {
      return false
    }

    if (record.state && record.state.length > 0 && item.states && item.states.indexOf(record.state) === -1) {
      return false
    }

    if (record.zip && record.zip.length > 0 && item.zips && item.zips.indexOf(record.zip) === -1) {
      return false
    }

    var tariff_data = record.utility_tariff_proposed_data
      ? record.utility_tariff_proposed_data
      : record.utility_tariff_current_data

    if (
      tariff_data &&
      tariff_data.distributor_name &&
      tariff_data.distributor_name.length > 0 &&
      item.distributors &&
      item.distributors.indexOf(tariff_data.distributor_name) === -1
    ) {
      return false
    }

    return true
  }

  return (
    <div>
      <>
        {getNotAvailableMessage() ? (
          <p className="notAvailable">{getNotAvailableMessage()}</p>
        ) : (
          <>
            {filterByGeo(record, complianceForms, props.enableDocsForNZ).map((complianceFormData: any, i: number) => (
              <div>
                <h2>{translate(complianceFormData.heading)}</h2>
                {complianceFormData.notes && complianceFormData.notes.length > 0 && (
                  <div className="small" style={{ marginBottom: '20px' }}>
                    {translate(complianceFormData.notes)}
                  </div>
                )}
                <Grid container spacing={3}>
                  {filterByGeo(record, complianceFormData.groups, props.enableDocsForNZ).map(
                    (complianceFormGroup, j) => (
                      <Grid item xs={12} sm={6} key={j}>
                        <>
                          <div style={{ color: '#7E7E7E' }}>{translate(complianceFormGroup.heading)}</div>
                          <div>
                            <ul style={{ fontSize: 12 }}>
                              {filterByGeo(record, complianceFormGroup.items, props.enableDocsForNZ).map(
                                (complianceFormItem, k) => (
                                  <li
                                    key={k}
                                    style={{
                                      whiteSpace: 'normal',
                                      listStyle: 'none',
                                    }}
                                  >
                                    {complianceFormItem.url && (
                                      <a
                                        href={complianceFormItem.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ position: 'relative', textOverflow: 'ellipsis' }}
                                      >
                                        {complianceFormItem.icon === 'launch' && (
                                          <LaunchIcon
                                            style={{
                                              position: 'absolute',
                                              height: 12,
                                              width: 12,
                                              marginTop: 2,
                                              marginLeft: -25,
                                            }}
                                          />
                                        )}

                                        {translate(complianceFormItem.title)}
                                      </a>
                                    )}
                                    {!complianceFormItem.url && complianceFormItem.file_tag && (
                                      <span>
                                        {complianceFormItem.icon === 'file' && (
                                          <AttachmentIcon
                                            style={{
                                              position: 'absolute',
                                              height: 12,
                                              width: 12,
                                              marginTop: 2,
                                              marginLeft: -25,
                                            }}
                                          />
                                        )}
                                        {translate(complianceFormItem.title)} (
                                        {countFiles(record, complianceFormItem.file_tag)})
                                      </span>
                                    )}
                                    {!complianceFormItem.url && !complianceFormItem.file_tag && (
                                      <span>{translate(complianceFormItem.title)}</span>
                                    )}
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </>
                      </Grid>
                    )
                  )}
                </Grid>
              </div>
            ))}
          </>
        )}
      </>
    </div>
  )
}

const PermitsAndServicesAccordion: React.FunctionComponent = () => {
  const translate = useTranslate()
  return (
    <AccordionCard
      name={'permits_and_services'}
      title={translate('Permits & Services')}
      titleIcon={GavelIcon}
      content={PermitsAndServicesContent}
      lazyRender={true}
    />
  )
}
export default PermitsAndServicesAccordion

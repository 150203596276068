// in src/users.js
import { authSelectors } from 'ducks/auth'
import TextFieldWithSharedEntityIcon from 'elements/TextFieldWithSharedEntityIcon'
import CustomField from 'elements/field/CustomField'
import { useBulkActionButtons } from 'elements/hooks/useBulkActionButtons'
import { useEditShareable } from 'elements/react-admin/EditShareable'
import { List } from 'elements/react-admin/List'
import ListActions from 'elements/react-admin/ListActions'
import inflection from 'inflection'
import React from 'react'
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  ImageField,
  ImageInput,
  NumberInput,
  SimpleForm,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'
import { useSelector } from 'react-redux'
import ShareabilityFilters from 'resources/connectedOrgs/ShareabilityFilters'
import ShareabilitySelector from 'resources/connectedOrgs/ShareabilitySelector'
import { getRoleFromState } from 'util/misc'
import EditOrImportButton from '../../elements/button/EditOrImportButton'
import ImageFieldClearable from '../../elements/field/ImageFieldClearable'
import OverviewInputs from '../../elements/input/OverviewInputs'
import EditorComponent from '../../elements/wysiwyg/EditorComponentWrapper'

const DraftEditorWithContent = ({ record, disabled }) => {
  return <EditorComponent fieldName="content" mentionContext="empty" allowUploadImage={true} disabled={disabled} />
}

export const TestimonialList = ({ accessRights: { allowView, allowCreate, allowEdit, allowDelete }, ...props }) => {
  const isAdmin = (props.permissions && props.permissions.includes('is_admin')) !== false
  const translate = useTranslate()
  const orgId = useSelector(authSelectors.getOrgId)
  const bulkActionButtons = useBulkActionButtons(props, allowEdit, allowDelete)

  const resourceName = translate(`resources.${props.resource}.name`, {
    smart_count: 1,
    _: inflection.humanize(inflection.singularize(props.resource)),
  })

  return (
    <List
      {...props}
      extraCreateButtonLabel={isAdmin ? translate('ra.page.empty_new', { name: resourceName }) : null}
      actions={<ListActions hasSearch={true} />}
      hasCreate={allowCreate}
      bulkActionButtons={bulkActionButtons}
      filters={<ShareabilityFilters />}
    >
      <Datagrid isAdmin={isAdmin}>
        <TextFieldWithSharedEntityIcon source="name" label="Name" />
        <BooleanField source="auto_apply_enabled" label="Auto Apply" displayInCard={true} sortable={false} />
        <TextField source="order" displayInCard={true} label="Order" />
        <EditOrImportButton
          source="actions"
          label="Actions"
          org_id={orgId}
          resource="testimonials"
          allowDuplicate={false}
          sortable={false}
          isAdmin={isAdmin}
          allowEdit={allowEdit}
          allowDelete={allowDelete}
        />
      </Datagrid>
    </List>
  )
}

// export const TestimonialShow = (props) => (
//   <Show title={'Case Study'} subtitle={subtitle} {...props}>
//     <SimpleShowLayout>
//       <TextField source="name" label="Title" />
//       <TextField source="content" />
//       <TextField source="order" />
//       <ImageField source="image_file_contents" title="title" />
//     </SimpleShowLayout>
//   </Show>
// )

export const TestimonialEdit = ({ accessRights, ...props }) => {
  const translate = useTranslate()
  const isAdmin = useSelector((state) => Boolean(getRoleFromState(state)?.is_admin))

  return useEditShareable({
    accessRights,
    editProps: props,
    render: ({ access }) => (
      <SimpleForm
        hideCancelButton={!!props.hideCancelButton}
        validate={(values) => validateTestimonial(values, translate)}
        toolbar={access.allowEdit ? undefined : false}
        disabled={!access.allowEdit}
      >
        <OverviewInputs>
          <CustomField
            component={TextInput}
            label="resources.testimonials.fields.title"
            name="name"
            source="name"
            disabled={!access.allowEdit}
          />
          <CustomField
            component={BooleanInput}
            label="Auto Apply"
            defaultValue={true}
            name="auto_apply_enabled"
            source="auto_apply_enabled"
            elStyle={{ marginTop: 20 }}
            disabled={!access.allowEdit}
          />
          <NumberInput name="order" source="order" disabled={!access.allowEdit} />
        </OverviewInputs>
        <DraftEditorWithContent disabled={!access.allowEdit} />
        <h3>{translate('Current Photo')}</h3>
        <ImageFieldClearable source="image_file_contents" maxWidth={200} disabled={!access.allowEdit} />
        {isAdmin && (
          <>
            <h1>{translate('Upload Photo')}</h1>
            <ImageInput
              source="image_file_contents"
              accept="image/*"
              placeholder={<p>{translate('Click to upload or drag & drop an image file here')}</p>}
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </>
        )}
        <ShareabilitySelector />
      </SimpleForm>
    ),
  }).editPage
}

export const TestimonialCreate = (props) => {
  const translate = useTranslate()
  return (
    <Create {...props}>
      <SimpleForm validate={(values) => validateTestimonial(values, translate)}>
        <OverviewInputs>
          <CustomField component={TextInput} label="resources.testimonials.fields.title" name="name" source="name" />
          <CustomField
            component={BooleanInput}
            label="Auto Apply"
            defaultValue={true}
            name="auto_apply_enabled"
            source="auto_apply_enabled"
            elStyle={{ marginTop: 20 }}
          />
          <NumberInput name="order" source="order" />
        </OverviewInputs>
        <DraftEditorWithContent />

        <h1>{translate('Upload Photo')}</h1>

        <ImageInput
          source="image_file_contents"
          accept="image/*"
          placeholder={<p>{translate('Click to upload or drag & drop an image file here')}</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <ShareabilitySelector />
      </SimpleForm>
    </Create>
  )
}

const validateTestimonial = (values, translate) => {
  const errors = {}
  if (!values.name) {
    errors.name = [translate('Title is required.')]
  }
  return errors
}

import { MenuItem } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orderSelectors } from 'ducks/orderComponents'
import type LineItemType from 'pages/ordering/OrderLineItem'
import OrderLineItem from 'pages/ordering/OrderLineItem'
import type ProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/projectOrderPresenter'
import type { DistributorDataType } from 'pages/ordering/type'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import useStyles from '../styles'

const generateNewLineItemByDistributor = ({ lineItem, distributor, quantity }) => {
  const newLineItem = new OrderLineItem({
    ...lineItem,
    quantity,
  })
  newLineItem.switchVariant(distributor.variant_id)
  return newLineItem
}

const findInStockData = (distributor: DistributorDataType) => {
  return distributor.stock_levels?.find((stockLevel) => stockLevel.in_stock)
}

const populateBestCombination = ({
  originalLineItem,
  distributors,
  minRequiredQuantity,
}: {
  originalLineItem: LineItemType
  distributors: DistributorDataType[]
  minRequiredQuantity: number
}): LineItemType[] => {
  const lineItems: LineItemType[] = []
  distributors.sort((a: DistributorDataType, b: DistributorDataType) => b.quantity_per_item - a.quantity_per_item)

  for (let i = 0; i < distributors.length; i++) {
    const distributor = distributors[i]
    const { quantity_per_item } = distributor
    const quantity = Math.floor(minRequiredQuantity / quantity_per_item)
    if (quantity > 0) {
      minRequiredQuantity -= quantity * quantity_per_item
    }
    const newLineItem = generateNewLineItemByDistributor({
      lineItem: originalLineItem,
      distributor,
      quantity,
    })
    lineItems.push(newLineItem)
  }
  return lineItems
}

const CustomQuantityOptions = ({
  lineItem,
  orderingPresenter,
  closeDropdown,
  trackSource,
}: {
  lineItem: LineItemType
  orderingPresenter: ProjectOrderPresenter
  closeDropdown(): void
  trackSource: string
}) => {
  const existingLineItems = useSelector(orderSelectors.getLineItemsByCode(lineItem.code))

  const classes = useStyles()

  const handleClick = useCallback(() => {
    logAmplitudeEvent('hardware_quantity_field_selector', {
      source: trackSource,
      action: 'clicked',
      type: 'bulk',
      context: 'Individual_and_bulk',
    })
    closeDropdown()
    if (!lineItem.data?.distributors) {
      return
    }
    const minRequiredQuantity = lineItem.projectOrder?.reduce((sum, current) => sum + current.requiredQuantity, 0) || 0

    // TODO: need to filter down  distributors by selected hardware supplier
    const newLineItems = populateBestCombination({
      originalLineItem: lineItem,
      distributors: lineItem.data.distributors,
      minRequiredQuantity,
    })

    if (newLineItems == null) {
      // If there is no replaceable variants then do nothing
      return
    }
    orderingPresenter.deleteLineItemsByCode(lineItem.code)
    orderingPresenter.addLineItems(newLineItems)
  }, [lineItem, existingLineItems])

  return (
    <>
      <div className={classes.selectionHeader}>Custom Quantity</div>
      <MenuItem onClick={handleClick} className={classes.menuItem}>
        Individual + Bulk
      </MenuItem>
    </>
  )
}

export const createCustomQuantityOptions = (
  trackSource: string,
  lineItem: LineItemType,
  orderingPresenter: ProjectOrderPresenter
) => {
  return ({ closeDropdown }) => (
    <CustomQuantityOptions
      closeDropdown={closeDropdown}
      lineItem={lineItem}
      orderingPresenter={orderingPresenter}
      trackSource={trackSource}
    />
  )
}

export default CustomQuantityOptions

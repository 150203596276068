import Button from 'elements/button/Button'
import React from 'react'

const CtaButton = ({ label, onClick, disabled }) => {
  return (
    <Button color="primary" disabled={disabled} fullWidth={true} onClick={onClick} style={{ marginTop: 10 }}>
      {label}
    </Button>
  )
}

export default CtaButton

import { Divider, Typography } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import Button from 'elements/button/Button'
import { memo } from 'react'
import { useHistory } from 'react-router-dom'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  container: {
    width: '100%',
    padding: 20,
    boxSizing: 'border-box',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '14px 0px 34px 0px',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '64%',
  },
  font18: {
    fontSize: '18px',
    lineHeight: '28.8px',
    textAlign: 'center',
  },
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}))

const OutletOrderConfirmationPage = ({ orderId }: { orderId: string }) => {
  const classes = useStyles()
  const history = useHistory()
  const onClickCTA = () => {
    logAmplitudeEvent('hardware_solar_outlet_review_purchase_order', {
      distributor: 'outlet',
      type: 'quote',
      method: 'redirect',
    })
    history.push('/manage_orders/incoming_transfer')
  }

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <Typography variant="h4" style={{ fontSize: '30px' }}>
          {`Order Confirmed`}
        </Typography>
      </div>
      <Divider style={{ color: '#B4B4B4', width: '100%' }} />
      <div className={classes.main}>
        <Typography className={`${classes.font18} ${classes.fontWeightMedium}`} style={{ marginTop: '32px' }}>
          {`Thanks for placing your order! This is your order ID: ${orderId}`}
        </Typography>
        <Typography className={`${classes.font18} ${classes.fontWeightMedium}`} style={{ marginTop: '35px' }}>
          Here’s what will happen next: Solar Outlet will process the quote you submitted and contact you to confirm
          pricing, and send shipping confirmation.
        </Typography>
        <Button color="primary" style={{ marginTop: '50px' }} onClick={onClickCTA}>
          Review Purchase Order
        </Button>
      </div>
    </div>
  )
}

export default memo(OutletOrderConfirmationPage)

import { useMediaQuery } from '@material-ui/core'
import UploadIcon from '@material-ui/icons/BackupOutlined'
import { Button, IconButton } from 'opensolar-ui'
import { PaginationComp, useNotify, useTranslate } from 'react-admin'
import { useHistory } from 'react-router-dom'
import { handleCsvDownload } from 'resources/projects/util'

const PaginationWithActions = ({ downloadUrl, downloadFilename, downloadCSV, ...props }) => {
  const translate = useTranslate()
  const notify = useNotify()
  const history = useHistory()
  const showIconButton = useMediaQuery((theme) => theme.breakpoints.down('lg'))
  const handleClick = () => history.push('/csv_upload?model=' + props.uploadModel)
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>
        {downloadUrl && downloadFilename && (
          <Button
            style={{ margin: 8 }}
            onClick={(event) =>
              handleCsvDownload(
                downloadUrl,
                notify,
                props.filterValues,
                downloadFilename,
                { page: props.page, perPage: props.perPage },
                downloadCSV
              )
            }
            className={'MuiButton-textPrimary'}
          >
            <span>{translate('Download')}</span>
          </Button>
        )}

        {props.uploadModel &&
          (showIconButton ? (
            <IconButton style={{ borderRadius: 2 }} onClick={handleClick}>
              <UploadIcon />
            </IconButton>
          ) : (
            <Button onClick={handleClick}>
              <span>{translate('Upload & Import CSV')}</span>
            </Button>
          ))}
      </div>
      <PaginationComp {...props} />
    </div>
  )
}

export default PaginationWithActions

import { Dialog, InputLabel, MenuItem, Select } from '@material-ui/core'
import { countries, countriesWhichRequireTermsOptIn } from 'constants/countries'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { viewModeActions, viewModeSelectors } from 'ducks/viewMode'
import GDPRText from 'elements/GDPRText'
import CheckboxInput from 'elements/input/CheckboxInput'
import { TermsAndPrivacyLinks } from 'elements/legal/TermsAndPrivacyLinks'
import ResponsiveVimeoPlayer from 'elements/ResponsiveVimeoPlayer'
import withMediaQuery from 'layout/withMediaQuery'
import { Button } from 'opensolar-ui'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { withTranslate } from 'react-admin'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import compose from 'recompose/compose'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { updateEmailPreferences as updateEmailPreferencesAction } from '../../actions/authActions'
import { setTermsAccepted as setTermsAcceptedAction } from '../../ducks/auth'
import { setWelcomePro as setWelcomeProAction } from '../../ducks/welcome'
import { getRoleFromState } from '../../util/misc'

export const ORIENTATION_VIDEO_URLS = {
  full: 'https://player.vimeo.com/video/742363084?h=7a71a8d75d',
  lite: 'https://player.vimeo.com/video/856873380?h=cc6a2e4956',
}

const useStyles = makeOpenSolarStyles((theme) => ({
  button: {
    backgroundColor: theme && theme.themeColor,
    color: theme && theme.headerFontColor,
    '&:hover': {
      backgroundColor: theme && theme.themeColorDark,
    },
  },
}))

const useDialogStyles = makeOpenSolarStyles((theme) => ({
  dialog: {
    width: '100%',
    height: '100%',
    maxWidth: 840,
    maxHeight: 472,
  },
}))

const StyledButton = ({ children, ...props }) => {
  const classes = useStyles()
  return (
    <Button classes={{ root: classes.button }} {...props}>
      <span style={{ fontSize: 14 }}>{children}</span>
    </Button>
  )
}

const StyledDialog = (props) => {
  const classes = useDialogStyles()
  return <Dialog classes={{ paper: classes.dialog }} {...props} />
}

class UserAgreementDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      message: props.message ? props.message : null,
      loading: false,
      showCountrySelection: false,
      selectedCountry: '',
      showGDPR: false,
      GDPRAnswer: props.email_marketing_opt_in || !countriesWhichRequireTermsOptIn.includes(this.props.orgCountry),
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.selectedCountry || this.state.selectedCountry === 'US') {
      if (this.state.showGDPR) this.setState({ showGDPR: false })
    } else {
      if (!this.state.showGDPR) this.setState({ showGDPR: true })
      if (this.state.selectedCountry !== prevState.selectedCountry) {
        if (countriesWhichRequireTermsOptIn.includes(this.state.selectedCountry)) {
          if (this.state.GDPRAnswer) this.setState({ GDPRAnswer: false })
        } else {
          if (!this.state.GDPRAnswer) this.setState({ GDPRAnswer: true })
        }
      }
    }
    if (this.props.orgCountry && !this.state.selectedCountry) {
      this.setState({ selectedCountry: this.props.orgCountry })
    }

    if (this.props.isOpen) {
      // Hide while showing this dialog
      this.props.setShowFujiTour(false)
    }
  }

  acceptTerms() {
    this.setState({ loading: true })
    this.props
      .restClientInstance('CUSTOM_POST', 'accept_terms', {
        url: 'accept_terms/',
        data: {
          email_marketing_opt_in: this.state.GDPRAnswer,
        },
      })
      .then(
        (response) => {
          this.setState({ loading: false })
          this.props.updateEmailPreferences({ email_marketing_opt_in: this.state.GDPRAnswer })

          if (this.props.setTermsAccepted) {
            this.props.setTermsAccepted()
          } else {
            console.log('setTermsAccepted not available. No update')
          }
          this.props.setShowFujiTour(true)
          // this.showFujiTourWhenAvailable()
        },
        (reject) => {
          this.setState({ loading: false, message: reject.message })
        }
      )
  }

  handleCountryChange = (newCountry) => {
    this.setState({ selectedCountry: newCountry })
  }

  renderGDPRToggle = () => {
    return (
      <div>
        <CheckboxInput
          input={{
            value: this.state.GDPRAnswer,
            onChange: (newVal) => {
              this.setState({ GDPRAnswer: newVal })
            },
          }}
          label={this.props.translate(GDPRText)}
          elStyle={{ width: '100%', maxWidth: null }}
        />
      </div>
    )
  }

  renderCountrySelection = () => {
    return (
      <div style={{ margin: '20px 10px 0px 10px' }}>
        <InputLabel id="country">{this.props.translate('Your Country')}</InputLabel>
        <Select
          labelId="country"
          defaultValue={this.props.orgCountry}
          value={this.state.selectedCountry}
          onChange={(event) => this.handleCountryChange(event.target.value)}
          style={{ width: 300 }}
        >
          {countries.map((country) => {
            return (
              <MenuItem key={country.id} value={country.id}>
                {country.name}
              </MenuItem>
            )
          })}
        </Select>
      </div>
    )
  }

  onContinueWithoutTerms = () => {
    // this.showFujiTourWhenAvailable()
    this.props.setShowFujiTour(true)
    this.props.setWelcomePro(false)
  }

  render() {
    const { translate } = this.props
    const videoUrl = this.props.isLite ? ORIENTATION_VIDEO_URLS.lite : ORIENTATION_VIDEO_URLS.full
    if (this.props.location.pathname.indexOf('/login') === 0) return null
    else
      return (
        <StyledDialog
          id="UserAgreementDialog"
          title={null}
          autoDetectWindowHeight={true}
          actions={null}
          open={this.props.isOpen}
        >
          <h1 style={{ margin: '15px 20px' }}>{translate('Welcome to OpenSolar')}</h1>
          <ResponsiveVimeoPlayer videoUrl={videoUrl} trackingSource={'user_agreement'} />

          {!this.props.terms_accepted && (
            <div>
              <div>
                {this.renderCountrySelection()}
                {this.state.showGDPR && this.renderGDPRToggle()}
              </div>
              <div
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <div style={{ Display: 'flex', flexDirection: 'column' }}>
                  <div style={{ margin: 10 }}>
                    <TermsAndPrivacyLinks />
                  </div>
                  {this.state.message && <p>{this.state.message}</p>}
                </div>
                <StyledButton
                  id="UserAgreementDialogContinue"
                  style={{ float: 'right', margin: '6px 10px' }}
                  onClick={() => this.acceptTerms()}
                >
                  <span>{translate('continue')}</span>
                </StyledButton>
              </div>
            </div>
          )}

          {this.props.terms_accepted && (
            <div>
              <StyledButton
                id="UserAgreementDialogContinue"
                style={{ float: 'right', margin: '6px 10px' }}
                onClick={this.onContinueWithoutTerms}
              >
                <span id="register_continue_btn">{translate('continue')}</span>
              </StyledButton>
            </div>
          )}
        </StyledDialog>
      )
  }
}

UserAgreementDialog.propTypes = {
  restClientInstance: PropTypes.func,
  handleTermsAccepted: PropTypes.func,
}

UserAgreementDialog.defaultProps = {}

// Only show in myenergy when user data has been loaded
// Don't flash it before data loads because they may have already accepted terms

export const isOpen = (state) => {
  // Only show for Pro, not customer
  // Customer acceptance is handled in the MyEnergy Introduction popup
  if (!Boolean(getRoleFromState(state))) {
    return false
  }

  // Do not show until sso finished
  if (authSelectors.getAuthStatus(state) === 'sso_logging_in') {
    return false
  }

  // Open if terms not accepted OR if welcome message is active
  if (state.auth?.user?.id && !authSelectors.getHasAcceptedTerms(state)) {
    return true
  } else if (state.welcome && state.welcome.pro === true) {
    return true
  } else {
    return false
  }
}

const mapStateToProps = (state) => {
  const org = orgSelectors.getOrg(state)
  const role = getRoleFromState(state)
  return {
    isOpen: isOpen(state),
    auth: state.auth,
    terms_accepted: authSelectors.getHasAcceptedTerms(state),
    email_marketing_opt_in: !!state.auth?.user?.email_marketing_opt_in,
    isPro: Boolean(role),
    isAdmin: Boolean(role) && role.is_admin,
    isLite: viewModeSelectors.isUserLite(state),
    logo: org?.logo_public_url,
    orgCountry: orgSelectors.getOrgIso2(state),
    fujiTourEnabled: viewModeSelectors.fujiTourEnabled(state),
  }
}

const UserAgreementDialogComposed = compose(
  withMediaQuery,
  withTranslate,
  connect(mapStateToProps, {
    setWelcomePro: setWelcomeProAction,
    setTermsAccepted: setTermsAcceptedAction,
    updateEmailPreferences: updateEmailPreferencesAction,
    setShowFujiTour: viewModeActions.setShowFujiTour,
  })
)(UserAgreementDialog)

export default (props) => {
  const location = useLocation()

  return <UserAgreementDialogComposed location={location} {...props} />
}

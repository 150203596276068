import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery } from '@material-ui/core'
import { EventIdType, logAmplitudeEvent } from 'amplitude/amplitude'
import _ from 'lodash'
import { Box, Switch, Typography, getTypographyStyles } from 'opensolar-ui'
import NumberInput from 'pages/inventory/components/NumberInput'
import { useTranslate } from 'ra-core'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { Theme } from 'types/themes'
import { BSAAppliancePower, BSAAppliances } from '../utility/types'
import { InfoContainer } from './InfoContainer'

const AMPLITUDE_TAB = 'enter_by_appliance'

const useTableStyles = makeOpenSolarStyles(() => ({
  tableContainer: {
    border: '1px solid #E7E7E7',
    borderRadius: '12px',
  },
  table: {
    '& tr:last-child td': {
      border: 'none',
    },
    '& td': {
      borderColor: '#E7E7E7',
    },
  },
  headRow: {
    background: '#F8F8F8',
    '& > th:first-child': {
      width: '40%',
    },
    '& > th:not(:first-child):not(:last-child)': {
      width: '25%',
    },
    '& > th:last-child': {
      width: '10%',
    },
  },
  input: {
    alignItems: 'center',
    background: '#FFF',
    borderRadius: '4px',
    border: '2px solid #E7E7E7',
    display: 'flex',
    height: '30px',
    justifyContent: 'center',
    padding: '0 10px',
    textAlign: 'center',
    width: '48px',
    ...getTypographyStyles({ textVariant: 'body2' }),
    '& input': {
      textAlign: 'center',
    },
    '&:disabled': {
      background: '#FFF',
      opacity: 0.25,
    },
  },
}))

const AppliancesTable = ({
  applianceKeys,
  appliancePowers,
  onChange,
}: {
  applianceKeys: string[]
  appliancePowers: BSAAppliances
  onChange: (applianceKey: string, powerKey: keyof BSAAppliancePower, value: number | boolean) => void
}) => {
  const classes = useTableStyles()
  const translate = useTranslate()

  const handleChange = (applianceKey: string, powerKey: keyof BSAAppliancePower, value: number) => {
    if (value >= 0) onChange(applianceKey, powerKey, powerKey === 'quantity' ? parseInt(`${value}`) : value)
  }

  const handleSwitchChange = (applianceKey: string, powerKey: keyof BSAAppliancePower, value: boolean) => {
    onChange(applianceKey, powerKey, value)
  }

  return (
    <TableContainer classes={{ root: classes.tableContainer }} component={Box}>
      <Table classes={{ root: classes.table }}>
        <TableHead>
          <TableRow classes={{ root: classes.headRow }}>
            <TableCell>
              <Typography textVariant="caption1">{translate('Appliance')}</Typography>
            </TableCell>
            <TableCell>
              <Typography textVariant="caption1">{translate('Average Continuous Power')}</Typography>
            </TableCell>
            <TableCell>
              <Typography textVariant="caption1">{translate('Peak Power')}</Typography>
            </TableCell>
            <TableCell>
              <Typography textVariant="caption1">{translate('Qty')}</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {applianceKeys.map((applianceKey) => {
            const appliancePower = appliancePowers[applianceKey] as BSAAppliancePower
            return (
              <TableRow key={applianceKey}>
                <TableCell>
                  <Box alignItems="center" display="flex">
                    <Switch
                      checked={appliancePower.enabled}
                      onChange={(_, isToggled) => {
                        logAmplitudeEvent(
                          (isToggled
                            ? `bda_${appliancePower.amplitudeKey}_toggle_on`
                            : `bda_${appliancePower.amplitudeKey}_toggle_off`) as EventIdType
                        )
                        handleSwitchChange(applianceKey, 'enabled', isToggled)
                      }}
                    />
                    <Typography textVariant="subtitle2">{translate(applianceKey)}</Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box alignItems="center" display="flex" gridGap={8}>
                    <NumberInput
                      className={classes.input}
                      disabled={!appliancePower.enabled}
                      inputProps={{ className: '', min: 0, max: 99.9 }}
                      InputProps={{ className: '', disableUnderline: true }}
                      onBlur={() => {
                        logAmplitudeEvent(
                          `bda_${appliancePower.amplitudeKey}_average_continuous_power_text_input_recorded` as EventIdType,
                          {
                            inputted_data: appliancePower.continuousPower,
                          }
                        )
                      }}
                      updateValue={(value) => handleChange(applianceKey, 'continuousPower', value)}
                      onFocus={() => {
                        logAmplitudeEvent(
                          `bda_${appliancePower.amplitudeKey}_average_continuous_power_text_input_selected` as EventIdType
                        )
                      }}
                      value={appliancePower.continuousPower}
                    />
                    <Typography colorHex="#757575" textVariant="body1">
                      {translate('kW')}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box alignItems="center" display="flex" gridGap={8}>
                    <NumberInput
                      className={classes.input}
                      disabled={!appliancePower.enabled}
                      inputProps={{ className: '', min: 0, max: 99.9 }}
                      InputProps={{ className: '', disableUnderline: true }}
                      onBlur={() => {
                        logAmplitudeEvent(
                          `bda_${appliancePower.amplitudeKey}_peak_power_text_input_recorded` as EventIdType,
                          {
                            inputted_data: appliancePower.peakPower,
                          }
                        )
                      }}
                      updateValue={(value) => handleChange(applianceKey, 'peakPower', value)}
                      onFocus={() => {
                        logAmplitudeEvent(
                          `bda_${appliancePower.amplitudeKey}_peak_power_text_input_selected` as EventIdType
                        )
                      }}
                      value={appliancePower.peakPower}
                    />
                    <Typography colorHex="#757575" textVariant="body1">
                      {translate('kW')}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <NumberInput
                    className={classes.input}
                    disabled={!appliancePower.enabled}
                    inputProps={{ className: '', min: 0, max: 99 }}
                    InputProps={{ className: '', disableUnderline: true }}
                    onBlur={() => {
                      logAmplitudeEvent(
                        `bda_${appliancePower.amplitudeKey}_quantity_text_input_recorded` as EventIdType,
                        {
                          inputted_data: appliancePower.quantity,
                        }
                      )
                    }}
                    onFocus={() => {
                      logAmplitudeEvent(
                        `bda_${appliancePower.amplitudeKey}_quantity_text_input_selected` as EventIdType
                      )
                    }}
                    updateValue={(value) => handleChange(applianceKey, 'quantity', value)}
                    value={appliancePower.quantity}
                  />
                  {/* <TextField
                    classes={{ root: classes.input }}
                    disabled={!appliancePower.enabled}
                    InputProps={{ disableUnderline: true }}
                    onBlur={() => {
                      logAmplitudeEvent(
                        `bda_${appliancePower.amplitudeKey}_quantity_text_input_recorded` as EventIdType,
                        {
                          inputted_data: appliancePower.quantity,
                        }
                      )
                    }}
                    onChange={({ target: { value } }) => handleChange(applianceKey, 'quantity', value)}
                    onFocus={() => {
                      logAmplitudeEvent(
                        `bda_${appliancePower.amplitudeKey}_quantity_text_input_selected` as EventIdType
                      )
                    }}
                    value={appliancePower.quantity}
                  /> */}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const useStyles = makeOpenSolarStyles(() => ({
  inputRoot: {
    marginTop: '2px',
    '& .MuiInputAdornment-root': {
      paddingBottom: '6px',
    },
  },
  input: {
    ...getTypographyStyles({ colorHex: '#757575', textVariant: 'body1' }),
    paddingTop: '0px',
  },
}))

interface BSABackupAppliancesInputProps {
  appliancePowers: BSAAppliances
  backupHours: number
  handleChangeAppliance: (applianceKey: string, powerKey: keyof BSAAppliancePower, value: number | boolean) => void
  handleChangeBackupHours: (string) => void
  averageContinuousPower: number
  totalPeakPower: number
}

const BSABackupAppliancesInput: React.FC<BSABackupAppliancesInputProps> = ({
  appliancePowers,
  backupHours,
  handleChangeAppliance,
  handleChangeBackupHours,
  averageContinuousPower,
  totalPeakPower,
}) => {
  const classes = useStyles()
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const translate = useTranslate()

  const appliances = Object.keys(appliancePowers)
  const applianceChunks = _.chunk(appliances, isSmallScreen ? appliances.length : Math.round(appliances.length / 2))

  return (
    <>
      <Typography colorHex="#212121" textVariant="body1">
        {translate(
          "Enter the number of hours you need backup power in case of an outage. Then, select the appliances you wish to back up from the list provided. The tool will automatically calculate the 'Average Continuous Power' and 'Peak Power' based on the appliances you've selected."
        )}
      </Typography>
      <Box display="flex" gridGap={56}>
        <InfoContainer
          inputClasses={{
            root: classes.inputRoot,
            input: classes.input,
          }}
          inputEndText={translate('hours')}
          label={translate('Hours of Backup')}
          onBlur={() => {
            logAmplitudeEvent('bda_hours_of_backup_text_input_recorded', {
              in_BDA_tab: AMPLITUDE_TAB,
              inputted_data: `${backupHours} hours`,
            })
          }}
          onChange={handleChangeBackupHours}
          onFocus={() => {
            logAmplitudeEvent('bda_hours_of_backup_text_input_selected', { in_BDA_tab: AMPLITUDE_TAB })
          }}
          type="input"
          value={backupHours}
        />
        <InfoContainer
          label={translate('Average Continuous Power')}
          value={`${averageContinuousPower} ${translate('kW')}`}
        />
        <InfoContainer label={translate('Peak Power')} value={`${totalPeakPower} ${translate('kW')}`} />
      </Box>
      <Box alignItems="flex-start" display="flex" gridGap={24}>
        {applianceChunks.map((applianceKeys, applianceChunkIndex) => (
          <AppliancesTable
            applianceKeys={applianceKeys}
            appliancePowers={appliancePowers}
            onChange={handleChangeAppliance}
            key={`appliances-${applianceChunkIndex + 1}`}
          />
        ))}
      </Box>
    </>
  )
}

export default BSABackupAppliancesInput

import React, { useContext, useEffect, useRef } from 'react'
import { useDataProvider, useNotify } from 'react-admin'
import { SidePanelContext } from '../SidePanel'
import { useSelector } from 'react-redux'
import PanelContainer from '../PanelContainer'
import { CustomerActivitySubHeader, UpComingActivitySubHeader } from './SubHeader'
import CustomerActivity from './CustomerActivity'
import UpComingActivity from './UpComingActivity'

const ActivityPanel = () => {
  const orgId = useSelector((state: any) => (state.auth ? state.auth.org_id : null))
  const { state, setState } = useContext(SidePanelContext)
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const stateRef = useRef(state)
  stateRef.current = state
  useEffect(() => {
    function fetchActivities() {
      const onSuccess = ({ data }: any) => {
        //ensure using latest state
        setState({
          ...stateRef.current,
          userActivities: data.user_activity,
          customerActivities: data.customer_activity,
        })
      }
      const onFailed = (error: any) => {
        notify('ra.notification.http_error', 'warning')
        //@ts-ignore
        console.warn(error)
      }

      dataProvider
        .CUSTOM_GET(
          'events',
          {
            url: 'home/activity/',
          },
          { onSuccess }
        )
        .catch(onFailed)
    }
    if ((!state.userActivities || !state.customerActivities) && orgId) {
      fetchActivities()
    }
  }, [])
  return (
    <PanelContainer title="Activity">
      <div style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto', flexShrink: 0, maxHeight: '40%' }}>
        <UpComingActivitySubHeader />
        <div style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
          <UpComingActivity data={state.userActivities} />
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
        <CustomerActivitySubHeader />
        <div style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
          <CustomerActivity data={state.customerActivities} />
        </div>
      </div>
    </PanelContainer>
  )
}

export default ActivityPanel

import SharedEntityIcon from 'elements/connectedOrgs/SharedEntityIcon'
import ProjectName from 'elements/ProjectName'
import { styled } from 'opensolar-ui'
import { Link } from 'react-admin'

interface PropTypes {
  record: any
}

const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  minWidth: 150,
})

const LinkStyled = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 500,
})

const AddressDisplay: React.FC<PropTypes> = ({ record }) => {
  return (
    <LinkStyled to={record.is_lite ? `/projects/${record.id}/design` : `/projects/${record.id}/manage`}>
      <Wrapper style={{ alignItems: record.business_name ? 'flex-start' : 'center' }}>
        <ProjectName business_name={record.business_name} address={record.address} />
        {!!record.shared_with?.length && (
          <SharedEntityIcon
            owner={record.org_id}
            owner_name={record.org_name}
            shared_with={record.shared_with}
            margin="wide"
            iconBtnStyles={{ paddingTop: record.business_name ? 3 : undefined }}
            version={3}
          />
        )}
      </Wrapper>
    </LinkStyled>
  )
}

export default AddressDisplay

import ClearIcon from '@material-ui/icons/ClearOutlined'
import { IconButton } from 'opensolar-ui'
import React from 'react'
import { FileField, FormDataConsumer } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'

const FileFieldClearable = (props) => {
  const formState = useFormState()
  const newFieldValue = formState.values[props.source]
  const form = useForm()
  const handleClick = () => {
    form.mutators.updateField(props.source, null)
  }
  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <FileField
        record={props.record}
        source={props.source}
        title="title"
        style={{
          margin: '0.5rem 0',
          maxHeight: '10rem',
          maxWidth: props.maxWidth ? props.maxWidth : '100%',
          opacity: props.record && newFieldValue && newFieldValue.length > 0 ? 1.0 : 0.2,
        }}
      />
      {props.disabled !== true && props.record && props.record[props.source] && props.record[props.source].length > 0 && (
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData[props.source] &&
            formData[props.source].length > 0 && (
              <IconButton
                onClick={handleClick}
                style={{
                  position: 'absolute',
                  padding: 0,
                  width: 20,
                  height: 20,
                  top: '0.5rem',
                  right: -30,
                  minWidth: 20,
                }}
                iconStyle={{
                  color: 'white',
                  backgroundColor: '#4d4d4d',
                  borderRadius: '50%',
                  padding: 2,
                  width: 16,
                  height: 16,
                }}
              >
                <ClearIcon />
              </IconButton>
            )
          }
        </FormDataConsumer>
      )}
    </div>
  )
}
export default FileFieldClearable

import { Button } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: any) => ({
  button: {
    padding: 0,
  },
}))

const SignInButton = ({ disableSignIn }: { disableSignIn: boolean }) => {
  const translate = useTranslate()
  const classes = useStyles()
  return (
    <Button variant="contained" color="primary" type="submit" disabled={disableSignIn} fullWidth>
      <span>{translate('Sign In')}</span>
    </Button>
  )
}

export default SignInButton

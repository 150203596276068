import { CheckCircle } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { triggerSystemRefresh } from 'ducks/paymentOptionSelection'
import { Chip } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'
import { useForm } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { StudioSystemType } from 'types/global'
import { systemNameOrDefault } from 'util/misc'

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    marginRight: '5px',
    cursor: 'pointer',
  },
  contents: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  contentColumn: {
    margin: '0px 2px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  notVisibleButton: {
    height: '14px',
    width: '14px',
    borderRadius: '10px',
    border: '1px solid gray',
    margin: '0px 1px',
  },
  systemIndexText: {
    color: theme.greyMid1,
  },
}))
type PropTypes = {
  system: StudioSystemType
  index: number
}

const SystemChip: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const form = useForm()
  const dispatch = useDispatch()

  const translate = useTranslate()

  const onClick = () => {
    let newVal = !props.system.show_customer
    if (newVal) {
      logAmplitudeEvent('payments_page_make_system_visible', {
        project_id: form.getState()?.values?.id,
        placement: 'system chip',
      })
    } else {
      logAmplitudeEvent('payments_page_make_system_hidden', {
        project_id: form.getState()?.values?.id,
        placement: 'system chip',
      })
    }
    const system = window.editor.getSystems()?.find((sys) => sys.uuid === props.system.uuid)
    window.editor.execute(new window.SetValueCommand(system, 'show_customer', newVal))
    form.mutators.markFieldAsDirty('design')
    form.change('design', 'has unsaved change')

    dispatch(triggerSystemRefresh())
  }

  return (
    <div className={classes.wrapper}>
      <Chip
        size="small"
        label={
          <div className={classes.contents}>
            <div className={classes.contentColumn}>
              {props.system.show_customer ? (
                <CheckCircle htmlColor="rgb(80, 170, 88)" fontSize="small" />
              ) : (
                <div className={classes.notVisibleButton}></div>
              )}
            </div>
            <div className={classes.contentColumn}>
              <span className={classes.systemIndexText}>
                {translate('System %{system_index}:', { system_index: props.index + 1 })}
              </span>
            </div>
            <div className={classes.contentColumn}>{systemNameOrDefault(props.system.userData)}</div>
          </div>
        }
        onClick={onClick}
      />
    </div>
  )
}
export default SystemChip

import { IRONRIDGE_SSO_CONFIG } from 'pages/auth/ironridge/Config'
import type { PartnerConfigType } from '../type'

export const IRONRIDGE_CONFIG: PartnerConfigType = {
  name: 'ironridge',
  partner_id: 'ironridge',
  displayName: 'IronRidge',
  logoUrl: IRONRIDGE_SSO_CONFIG.logoUrl,
  // authConfig: IRONRIDGE_AUTH_CONFIG,
  orgLinkingParams: ['company_uuid'],
  orgLinkingMessage:
    'IronRidge now offers direct access to the OpenSolar proposal tool you use today, as well as a racking tool (previously known as the IronRidge Design Assistant), permit pack tool, and more – all in one place.',
}

import { apiVersionCompatibility } from '../util/misc'
import {ReduxAccesType, AccessFeaturesType, versionMismatchType} from '../types/redux_acces';

export const SET_VERSION = 'SET_VERSION'
export const SET_VERSION_MISMATCH = 'SET_VERSION_MISMATCH'
export const SET_VERSION_MISMATCH_DISMISSED = 'SET_VERSION_MISMATCH_DISMISSED'
export const SET_MAINTENANCE_MODE = 'SET_MAINTENANCE_MODE'

export const setVersion = (value: string) => ({
  type: SET_VERSION,
  payload: value,
})

export const setVersionMismatch = (value: versionMismatchType) => ({
  type: SET_VERSION_MISMATCH,
  payload: value,
})

export const setVersionMismatchDismissed = (value: boolean) => ({
  type: SET_VERSION_MISMATCH_DISMISSED,
  payload: value,
})

export const setMaintenanceMode = (value: boolean) => ({
  type: SET_MAINTENANCE_MODE,
  payload: value,
})

const initialState: ReduxAccesType = {
  version: null,
  versionMismatch: null,
  versionMismatchDismissed: false,
  maintenanceMode: false,
  features: {} as AccessFeaturesType,
}

export default function reducer(previousState: ReduxAccesType = initialState, { type, payload }: {type: string, payload: any}) {

  if (type === 'SET_VERSION' && previousState.version !== payload) {
    var featuresToUpdate = {
      ...previousState.features,
      lineItems: apiVersionCompatibility(payload, '1.7.0') === 4,
    }
    return Object.assign({}, previousState, { version: payload, features: featuresToUpdate })
  }
  if (type === 'SET_VERSION_MISMATCH') {
    return Object.assign({}, previousState, { versionMismatch: payload })
  }
  if (type === 'SET_VERSION_MISMATCH_DISMISSED') {
    return Object.assign({}, previousState, { versionMismatchDismissed: payload })
  }
  if (type === 'SET_MAINTENANCE_MODE') {
    return Object.assign({}, previousState, { maintenanceMode: payload })
  }
  return previousState
}

import { Notify, useNotify } from 'ra-core'
import { useState } from 'react'
import restClient from 'restClient'
import { HardwareSupplierFilterKeyType } from '../type'

const API_URL = window.API_ROOT + '/api'
const restClientInstance = restClient(API_URL)

/**
 * If we ever do more than call pricing sync, it might be worth pulling the rest client stuff out into a service class.
 * We don't check front end auth status. The assumption is that will execute sequentially - we need the tokens on the back end
 * by the time we kick off the
 */

const executeSaveAccessTokens = async ({
  orgId,
  supplierFilterKey,
  refreshToken,
  notify,
}: {
  orgId: number
  supplierFilterKey: HardwareSupplierFilterKeyType
  refreshToken: string
  notify: Notify
}) => {
  try {
    let response = await restClientInstance('CUSTOM_PUT', 'custom', {
      url: `orgs/${orgId}/distributors/${supplierFilterKey}/save_access_tokens/`,
      data: {
        refresh_token: refreshToken,
      },
    })
  } catch (ex) {
    console.warn('Could not sync prices', ex)
    notify('Could not sync prices')
  }
}

const useSaveAccessTokensAfterLogin = () => {
  const [isSyncing, setIsSyncing] = useState<boolean>(false)
  const notify = useNotify()

  const saveAccessTokens = async ({
    orgId,
    refreshToken,
    supplierFilterKey,
  }: {
    orgId: number
    refreshToken: string
    supplierFilterKey: HardwareSupplierFilterKeyType
  }) => {
    setIsSyncing(true)

    await executeSaveAccessTokens({
      orgId,
      supplierFilterKey,
      refreshToken: refreshToken || '',
      notify,
    })

    setIsSyncing(false)
  }

  return { isSyncing, saveAccessTokens }
}

export default useSaveAccessTokensAfterLogin

import { Button } from 'opensolar-ui'
import React from 'react'
import { useFormState } from 'react-final-form'

const AcceptItemsButton = ({
  selectedItemSource,
  recordedItemSource,
  handleClick,
}: {
  selectedItemSource: string
  recordedItemSource: string
  handleClick(e): void
}) => {
  const formState = useFormState()
  const recordedLineItems = formState.values?.[recordedItemSource] || []
  const selectedLineItems = formState.values?.[selectedItemSource] || new Set()
  const isAllSelected = selectedLineItems.size > 0 && selectedLineItems.size === recordedLineItems.length

  return (
    <Button variant="contained" disabled={!formState.dirty} color="primary" onClick={handleClick}>
      {isAllSelected ? 'Accept items' : 'Partially accept items'}
    </Button>
  )
}

export default AcceptItemsButton

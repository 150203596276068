import { Dialog, makeStyles } from '@material-ui/core'
import React, { useMemo, useState } from 'react'
import { useFormState } from 'react-final-form'
import { NORMAL_CONTACT } from 'types/contacts'
import EmailInviteContent from './invite/EmailInviteContent'
import ShareModalContent from './ShareModalContent'

const useStyles = makeStyles({
  modal: {
    '& .MuiDialog-paper': {
      width: 560,
    },
  },
})

interface PropTypes {
  open: boolean
  onClose: () => void
}

const ShareProposalLinkModal: React.FC<PropTypes> = ({ open, onClose }) => {
  const classes = useStyles()
  const project = useFormState().values
  const [emailMode, setModalMode] = useState(false)
  const contacts = useMemo(() => project.contacts_data?.filter((x) => x.type === NORMAL_CONTACT), [
    project.contacts_data,
  ])
  const contactDisplay = useMemo(() => {
    let display = ''
    contacts?.forEach((contact, i) => {
      if (contact)
        display +=
          contact.display ||
          (!contact.first_name && !contact.family_name
            ? contact.email
            : `${contact.first_name || ''} ${contact.family_name || ''}`)
      if (i !== contacts?.length - 1) display += ', '
    })
    return display
  }, [contacts])

  const handleClose = () => {
    if (emailMode) setModalMode(false)
    onClose()
  }

  return (
    <Dialog open={open} className={classes.modal}>
      {emailMode ? (
        <EmailInviteContent onClose={handleClose} setModalMode={setModalMode} contactDisplay={contactDisplay} />
      ) : (
        <ShareModalContent
          onClose={handleClose}
          setModalMode={setModalMode}
          contactDisplay={contactDisplay}
          shareDisabled={!!!project.contacts_data?.length}
        />
      )}
    </Dialog>
  )
}

export default ShareProposalLinkModal

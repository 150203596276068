import { useFeatureFlag } from 'util/split'

const formatOptions = [
  { name: 'Embed In Page', id: 1 },
  { name: 'Popup On Click', id: 2 },
]

const formatOptionsSolkunder = [...formatOptions, { name: 'Solkunder', id: 3 }]

export const useLeadCaptureFormats = () => {
  const solkunderOn = useFeatureFlag('solkunder', 'on')
  return solkunderOn ? formatOptionsSolkunder : formatOptions
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import { makeStyles } from '@material-ui/core/styles';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import classnames from 'classnames';
import inflection from 'inflection';
import PropTypes from 'prop-types';
import { CRUD_DELETE, useDelete, useNotify, useRedirect, useRefresh, useTranslate, } from 'ra-core';
import { Fragment, useCallback, useState } from 'react';
import Confirm from '../layout/Confirm';
import Button from './Button';
var listStyle = { color: '#4d4d4d', justifyContent: 'flex-start', margin: 0 };
var defaultStyle = {};
var DeleteWithConfirmButton = function (props) {
    var basePath = props.basePath, className = props.className, onSuccessCallback = props.onSuccess, onFailureCallback = props.onFailure, _a = props.confirmTitle, confirmTitle = _a === void 0 ? 'ra.message.delete_title' : _a, _b = props.confirmContent, confirmContent = _b === void 0 ? 'ra.message.delete_content' : _b, _c = props.icon, icon = _c === void 0 ? defaultIcon : _c, _d = props.label, label = _d === void 0 ? 'ra.action.delete' : _d, onClick = props.onClick, record = props.record, resource = props.resource, _e = props.useListStyle, useListStyle = _e === void 0 ? false : _e, _f = props.redirect, redirectTo = _f === void 0 ? 'list' : _f, rest = __rest(props, ["basePath", "className", "onSuccess", "onFailure", "confirmTitle", "confirmContent", "icon", "label", "onClick", "record", "resource", "useListStyle", "redirect"]);
    var _g = useState(false), open = _g[0], setOpen = _g[1];
    var translate = useTranslate();
    var notify = useNotify();
    var redirect = useRedirect();
    var refresh = useRefresh();
    var _h = useDelete(resource, record.id, record, {
        action: CRUD_DELETE,
        onSuccess: function () {
            notify('ra.notification.deleted', 'info', { smart_count: 1 });
            if (onSuccessCallback) {
                onSuccessCallback();
            }
            redirect(redirectTo, basePath);
            refresh();
        },
        onFailure: function (error) {
            notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
            setOpen(false);
            if (onFailureCallback) {
                onFailureCallback();
            }
        },
        undoable: false,
    }), deleteOne = _h[0], loading = _h[1].loading;
    var handleClick = function (e) {
        setOpen(true);
        e.stopPropagation();
    };
    var handleDialogClose = function (e) {
        setOpen(false);
        e.stopPropagation();
    };
    var handleDelete = useCallback(function (event) {
        deleteOne();
        if (typeof onClick === 'function') {
            onClick(event);
        }
    }, [deleteOne, onClick]);
    return (_jsxs(Fragment, { children: [_jsx(Button, __assign({ onClick: handleClick, label: label, className: classnames('ra-delete-button', 'MuiButton-textPrimary', className), variant: props.name === 'editDeleteButton' ? 'contained' : 'text', size: props.name === 'editDeleteButton' ? 'large' : 'small', color: 'default', style: useListStyle ? listStyle : defaultStyle }, rest, { children: icon }), "button"), _jsx(Confirm, { isOpen: open, loading: loading, title: confirmTitle, content: confirmContent, translateOptions: {
                    name: inflection.humanize(translate("resources.".concat(resource, ".name"), {
                        smart_count: 1,
                        _: inflection.singularize(resource),
                    }), true),
                    id: record.title || record.id,
                }, confirmColor: "warning", onConfirm: handleDelete, onClose: handleDialogClose })] }));
};
var defaultIcon = _jsx(DeleteOutlinedIcon, {});
var useStyles = makeStyles(function (theme) { return ({
    deleteButton: {
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
}); }, { name: 'RaDeleteWithConfirmButton' });
DeleteWithConfirmButton.propTypes = {
    basePath: PropTypes.string,
    classes: PropTypes.object,
    className: PropTypes.string,
    confirmTitle: PropTypes.string,
    confirmContent: PropTypes.string,
    label: PropTypes.string,
    record: PropTypes.any,
    redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
    resource: PropTypes.string,
    icon: PropTypes.element,
};
export default DeleteWithConfirmButton;

// @ts-nocheck
import Alert from 'elements/Alert'
import CustomField from 'elements/field/CustomField'
import { BooleanInput, useTranslate } from 'react-admin'

const ProposalShareability = (props: any) => {
  const translate = useTranslate()
  return (
    <div>
      <h2>{translate('Proposal Shareability')}</h2>

      <CustomField
        component={BooleanInput}
        source="proposals_auto_shareable"
        name="proposals_auto_shareable"
        style={{ width: '100%' }}
        label="Auto enable proposal shareability"
        {...props}
      />
      <Alert severity="info">
        {translate(
          'Proposal shareability is automatically enabled for a project when the pro or customer generates the PDF proposal.'
        )}
      </Alert>
    </div>
  )
}

export default ProposalShareability

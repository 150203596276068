import CustomField from 'elements/field/CustomField'
import React, { FC } from 'react'
import { useTranslate } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { composeValidators, emailFormat, required } from 'validations'
import { renderInput } from '../../common'
import PasswordInput from '../fields/PasswordInput'
import SignInButton from './actions/SignInButton'

const useStyles = makeOpenSolarStyles((theme: any) => ({
  wrapper: {
    width: 280,
  },
  input: {
    margin: '10px 0',
  },
}))

type LoginPanelFormContentopsType = {
  disableSignIn: boolean
  AdditionalActions?: React.ReactNode
  AdditionalMessage?: React.ReactNode
}

const LoginPanelFormContent: FC<LoginPanelFormContentopsType> = ({
  disableSignIn,
  AdditionalMessage,
  AdditionalActions,
}) => {
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      {!!AdditionalMessage && <div>{AdditionalMessage}</div>}
      <div>
        <div className={classes.input}>
          <CustomField
            name="email"
            type="email"
            validate={composeValidators(required, emailFormat)}
            component={renderInput}
            label={translate('email')}
            fullWidth={true}
            inputProps={{
              id: 'login-form-email',
            }}
          />
        </div>
        <div className={classes.input}>
          <PasswordInput
            inputProps={{
              id: 'login-form-password',
            }}
          />
        </div>
        <SignInButton disableSignIn={disableSignIn} />
      </div>
      {!!AdditionalActions && <div>{AdditionalActions}</div>}
    </div>
  )
}

export default LoginPanelFormContent

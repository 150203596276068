export const getProjectWarningStyles = (theme) => {
  return {
    container: {
      margin: '20px 50px',
    },
  }
}

const getProjectSectionStyles = (theme) => {
  return {
    sectionWrapper: {
      margin: '20px 20px',
    },
    formPage: {
      maxWidth: 1160,
      margin: 'auto',
    },
  }
}

export default getProjectSectionStyles

import { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { LegacyDataProvider } from 'ra-core'
import appStorage from 'storage/appStorage'

class PreOrderService {
  private restClientInstance: LegacyDataProvider

  constructor(restClientInstance: LegacyDataProvider) {
    this.restClientInstance = restClientInstance
  }

  processCheckoutOrder = async (
    requestData: any,
    checkoutId: number,
    distributor: HardwareSupplierFilterKeyType
  ): Promise<any> => {
    const response = await this.restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${appStorage.getOrgId()}/ordering/checkout/${checkoutId}/process_order/${distributor}/`,
      data: requestData,
    }).catch(() => {})
    return response?.data
  }

  createCheckout = async (requestData: any): Promise<any> => {
    const response = await this.restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${appStorage.getOrgId()}/checkouts/`,
      data: requestData,
    }).catch(() => {})
    return response?.data
  }
}

export default PreOrderService

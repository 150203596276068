export const orgsData = {
  1: {
    id: 1,
    logo_file_contents: 'https://sunsuite-designer-static-local.s3.amazonaws.com/media/public/undefined_P7HooGu',
    name: 'MySolarCo Pty Ltd',
    about_content: 'Here is the general content about this company. I wonder if HTML works... :-)',
    sales_phone_number: '+61 9481 1111',
    company_email: 'contact@www.mysolarco.co',
    company_website: 'www.mysolarco.co',
    country_iso2: 'AU',
  },
  2: {
    id: 2,
    logo_file_contents: 'https://sunsuite-designer-static-local.s3.amazonaws.com/media/public/undefined_P7HooGu',
    name: 'MySolarCo Pty Ltd',
    about_content: 'Here is the general content about this company. I wonder if HTML works... :-)',
    sales_phone_number: '+61 9481 1111',
    company_email: 'contact@www.mysolarco.co',
    company_website: 'www.mysolarco.co',
    country_iso2: 'US',
  },
}

export const projectData = {
  id: 5,
  business_name: 'BigPowerUserCo',
  address: '12 Main St',
  locality: 'Manly',
  state: 'NSW',
  zip: '2099',
  country_iso2: 'AU',
  lat: 43.124124141,
  lon: 121.0232411,
  assigned_team_member: {
    id: 1,
    first_name: 'Bob',
    family_name: 'Jones',
    email: 'bob@gmail.com',
    display: 'Bob',
  },
  contacts_data: [
    { id: 1, first_name: 'Bob', family_name: 'Jones', email: 'bob@gmail.com' },
    {
      id: 2,
      first_name: 'Jill',
      family_name: 'Jones',
      email: 'jill@gmail.com',
      phone: '+612 234 1233',
    },
  ],
}

export const systemData = {
  id: 5,
}

export const genericData = [
  {
    id: 1,
  },
  {
    id: 2,
  },
]

export const genericDataAsDict = {
  1: genericData[0],
  2: genericData[1],
}

export const paymentOptionsData = genericData

export const contractTemplatesData = genericData

export const rolesData = {
  1: {
    id: 1,
    first_name: 'Bob',
    email: 'bob@gmail.com',
  },
  2: {
    id: 2,
    first_name: 'Jess',
    email: 'jess@gmail.com',
  },
}

export const pricingSchemesData = {
  1: {
    id: 1,
    title: 'A',
    pricing_formula: 'Price Per Watt',
  },
  2: {
    id: 2,
    title: 'B',
    pricing_formula: 'Markup Percentage',
  },
  3: {
    id: 3,
    title: 'C',
    pricing_formula: 'Price Per Watt By Size',
  },
  4: {
    id: 4,
    title: 'D',
    pricing_formula: 'Fixed Price',
  },
}

export const tagsData = genericData

export const roofTypesData = [
  {
    id: 1,
    name: 'Tin',
  },
  {
    id: 2,
    name: 'Tile',
  },
]

export const testimonialsData = [
  {
    id: 1,
    name: 'Jane Wang',
    content: 'These guys are legends',
    order: 1,
    image_file_contents:
      'https://sunsuite-designer-static-local.s3.amazonaws.com/media/public/16143068_1711729855519150_6532692707690369680_n.jpg',
  },
  {
    id: 2,
    name: 'Roger Jones',
    content: 'Amazing service. Thanks!',
    order: 2,
  },
]

export const environmentals = {
  co2_tons: 140000,
  car_km: 745.2,
  trees_planted: 420,
  flights: 120,
}

// export const selectedSystem = {
//     uuid:"def",
//     moduleQuantity: 20,
//     kw_stc: 24.0,
//     battery_total_kwh: 13.0,
//     pricing: {
//         system_price_including_tax: 25000,
//     },
//     show_customer: false,
//     output: {
//         annual: 5167,
//         monthly: [597.7086266292399,498.5037928902324,409.3015703517588,349.6449540181376,257.1252645061244,241.66873748625946,268.43207983275755,350.01798568035485,455.31129671796486,544.0099236416457,581.4125343514447,622.8465511149496]
//     },
// }

export const selectedSystem = {
  bills: [
    {
      before: {
        annual: {
          total: 0,
          feed_in_tariff: 0,
        },
        hourly_energy_flow_for_days: {},
      },
      after: {
        annual: {
          total: 0,
          feed_in_tariff: 0,
        },
      },
    },
  ],
  financials: {
    net_cost_to_customer: 0,
    utility_cost_annual_current: 1000,
    utility_cost_annual_proposed: 600,
    utility_cost_total_current: 20000,
    utility_cost_total_proposed: 12000,
    utility_savings_first_year: 400,
    utility_savings_total: 8000,
  },
  order: 0,
  uuid: 'A68EE68F-DD21-4D95-A38A-24F43FE8C07B',
  name: 'System',
  summary:
    'System\nSunPower SPR-390E-WHT-D x 8\nPVI 3.0 OUTD-S-US-Z-M-A (240V) (8x1)\nAnnual Output: 5176 kWh\nPrice: $62400 (Tax: $6240)',
  module: {
    code: 'SPR-390E-WHT-D',
    voc: 85.3,
    temp_coefficient_vpmax: -0.3382,
    temp_coefficient_voc: -0.229,
    kw_stc: 390,
    manufacturer: 'SunPower',
    size: [1.046, 2.067],
    cost: 0,
    quantity: 0,
  },
  site: {
    longitude: 151.17,
    latitude: -33.93,
  },
  inverters: [
    {
      uuid: '7654622F-FA5A-445E-90BA-614C09F04AF0',
      mppts: [
        {
          uuid: 'BF1F69C1-BF21-4FF3-A0B4-0A5F2326AB99',
          azimuth: 93.26417458699046,
          slope: 35.214873485821315,
          strings: [
            {
              uuid: '7F1A3CF6-534A-4E21-82F9-364A7AD76DE6',
              modules: [
                {
                  uuid: '662A1476-013F-43FE-A9A9-D8F6BA3D2A0B',
                },
                {
                  uuid: '47F88EE3-67E1-4694-AF31-23CB46822C31',
                },
                {
                  uuid: '27610DCF-646D-4CA3-BD5A-21F4227EB0DD',
                },
                {
                  uuid: '4649CF23-28EE-46E3-9E52-080D4B4BDCC2',
                },
                {
                  uuid: '00E8C85F-A459-45E2-BFB4-7FE54F3F665B',
                },
                {
                  uuid: 'C815204A-4171-4618-B730-1146AA1D26CB',
                },
                {
                  uuid: '4373D616-74FC-4E8C-848D-18D2F29A1ACF',
                },
                {
                  uuid: '5EF5F7BF-0242-41BE-AB1D-B3C57B26974A',
                },
              ],
            },
          ],
          shadingOverride: null,
        },
      ],
      code: 'PVI 3.0 OUTD-S-US-Z-M-A (240V)',
      shadingOverride: null,
      output: {
        monthly: [
          597.7086266292399,
          498.5037928902324,
          409.3015703517588,
          349.6449540181376,
          257.1252645061244,
          241.66873748625946,
          268.43207983275755,
          350.01798568035485,
          455.31129671796486,
          544.0099236416457,
          581.4125343514447,
          622.8465511149496,
        ],
        annual: 5175.98331722087,
        children: [
          {
            monthly: [
              622.6131527387917,
              519.2747842606587,
              426.35580244974875,
              364.21349376889333,
              267.83881719387955,
              251.7382682148536,
              279.6167498257891,
              364.6020684170363,
              474.2826007478801,
              566.677003793381,
              605.6380566160883,
              648.7984907447393,
            ],
            annual: 5391.6492887717395,
            children: [
              {
                monthly: [
                  622.6131527387917,
                  519.2747842606587,
                  426.35580244974875,
                  364.21349376889333,
                  267.83881719387955,
                  251.7382682148536,
                  279.6167498257891,
                  364.6020684170363,
                  474.2826007478801,
                  566.677003793381,
                  605.6380566160883,
                  648.7984907447393,
                ],
                annual: 5391.6492887717395,
                children: [
                  {
                    monthly: [
                      77.82664409234896,
                      64.90934803258234,
                      53.294475306218594,
                      45.52668672111166,
                      33.479852149234944,
                      31.467283526856697,
                      34.95209372822364,
                      45.57525855212955,
                      59.28532509348501,
                      70.83462547417263,
                      75.70475707701104,
                      81.09981134309241,
                    ],
                    annual: 673.9561610964674,
                    children: [],
                    uuid: '662A1476-013F-43FE-A9A9-D8F6BA3D2A0B',
                  },
                  {
                    monthly: [
                      77.82664409234896,
                      64.90934803258234,
                      53.294475306218594,
                      45.52668672111166,
                      33.479852149234944,
                      31.467283526856697,
                      34.95209372822364,
                      45.57525855212955,
                      59.28532509348501,
                      70.83462547417263,
                      75.70475707701104,
                      81.09981134309241,
                    ],
                    annual: 673.9561610964674,
                    children: [],
                    uuid: '47F88EE3-67E1-4694-AF31-23CB46822C31',
                  },
                  {
                    monthly: [
                      77.82664409234896,
                      64.90934803258234,
                      53.294475306218594,
                      45.52668672111166,
                      33.479852149234944,
                      31.467283526856697,
                      34.95209372822364,
                      45.57525855212955,
                      59.28532509348501,
                      70.83462547417263,
                      75.70475707701104,
                      81.09981134309241,
                    ],
                    annual: 673.9561610964674,
                    children: [],
                    uuid: '27610DCF-646D-4CA3-BD5A-21F4227EB0DD',
                  },
                  {
                    monthly: [
                      77.82664409234896,
                      64.90934803258234,
                      53.294475306218594,
                      45.52668672111166,
                      33.479852149234944,
                      31.467283526856697,
                      34.95209372822364,
                      45.57525855212955,
                      59.28532509348501,
                      70.83462547417263,
                      75.70475707701104,
                      81.09981134309241,
                    ],
                    annual: 673.9561610964674,
                    children: [],
                    uuid: '4649CF23-28EE-46E3-9E52-080D4B4BDCC2',
                  },
                  {
                    monthly: [
                      77.82664409234896,
                      64.90934803258234,
                      53.294475306218594,
                      45.52668672111166,
                      33.479852149234944,
                      31.467283526856697,
                      34.95209372822364,
                      45.57525855212955,
                      59.28532509348501,
                      70.83462547417263,
                      75.70475707701104,
                      81.09981134309241,
                    ],
                    annual: 673.9561610964674,
                    children: [],
                    uuid: '00E8C85F-A459-45E2-BFB4-7FE54F3F665B',
                  },
                  {
                    monthly: [
                      77.82664409234896,
                      64.90934803258234,
                      53.294475306218594,
                      45.52668672111166,
                      33.479852149234944,
                      31.467283526856697,
                      34.95209372822364,
                      45.57525855212955,
                      59.28532509348501,
                      70.83462547417263,
                      75.70475707701104,
                      81.09981134309241,
                    ],
                    annual: 673.9561610964674,
                    children: [],
                    uuid: 'C815204A-4171-4618-B730-1146AA1D26CB',
                  },
                  {
                    monthly: [
                      77.82664409234896,
                      64.90934803258234,
                      53.294475306218594,
                      45.52668672111166,
                      33.479852149234944,
                      31.467283526856697,
                      34.95209372822364,
                      45.57525855212955,
                      59.28532509348501,
                      70.83462547417263,
                      75.70475707701104,
                      81.09981134309241,
                    ],
                    annual: 673.9561610964674,
                    children: [],
                    uuid: '4373D616-74FC-4E8C-848D-18D2F29A1ACF',
                  },
                  {
                    monthly: [
                      77.82664409234896,
                      64.90934803258234,
                      53.294475306218594,
                      45.52668672111166,
                      33.479852149234944,
                      31.467283526856697,
                      34.95209372822364,
                      45.57525855212955,
                      59.28532509348501,
                      70.83462547417263,
                      75.70475707701104,
                      81.09981134309241,
                    ],
                    annual: 673.9561610964674,
                    children: [],
                    uuid: '5EF5F7BF-0242-41BE-AB1D-B3C57B26974A',
                  },
                ],
                uuid: '7F1A3CF6-534A-4E21-82F9-364A7AD76DE6',
              },
            ],
            uuid: 'BF1F69C1-BF21-4FF3-A0B4-0A5F2326AB99',
          },
        ],
        uuid: '7654622F-FA5A-445E-90BA-614C09F04AF0',
      },
      efficiency: 96,
    },
  ],
  batteries: [
    {
      uuid: 'A61ECDA1-4BDB-4F83-9550-A5D07E54AE8B',
      code: 'POWERWALL2 (208V)',
      kwh_optimal: 2.0,
    },
  ],
  output: {
    monthly: [
      597.7086266292399,
      498.5037928902324,
      409.3015703517588,
      349.6449540181376,
      257.1252645061244,
      241.66873748625946,
      268.43207983275755,
      350.01798568035485,
      455.31129671796486,
      544.0099236416457,
      581.4125343514447,
      622.8465511149496,
    ],
    annual: 5175.98331722087,
    children: [
      {
        monthly: [
          597.7086266292399,
          498.5037928902324,
          409.3015703517588,
          349.6449540181376,
          257.1252645061244,
          241.66873748625946,
          268.43207983275755,
          350.01798568035485,
          455.31129671796486,
          544.0099236416457,
          581.4125343514447,
          622.8465511149496,
        ],
        annual: 5175.98331722087,
        children: [
          {
            monthly: [
              622.6131527387917,
              519.2747842606587,
              426.35580244974875,
              364.21349376889333,
              267.83881719387955,
              251.7382682148536,
              279.6167498257891,
              364.6020684170363,
              474.2826007478801,
              566.677003793381,
              605.6380566160883,
              648.7984907447393,
            ],
            annual: 5391.6492887717395,
            children: [
              {
                monthly: [
                  622.6131527387917,
                  519.2747842606587,
                  426.35580244974875,
                  364.21349376889333,
                  267.83881719387955,
                  251.7382682148536,
                  279.6167498257891,
                  364.6020684170363,
                  474.2826007478801,
                  566.677003793381,
                  605.6380566160883,
                  648.7984907447393,
                ],
                annual: 5391.6492887717395,
                children: [
                  {
                    monthly: [
                      77.82664409234896,
                      64.90934803258234,
                      53.294475306218594,
                      45.52668672111166,
                      33.479852149234944,
                      31.467283526856697,
                      34.95209372822364,
                      45.57525855212955,
                      59.28532509348501,
                      70.83462547417263,
                      75.70475707701104,
                      81.09981134309241,
                    ],
                    annual: 673.9561610964674,
                    children: [],
                    uuid: '662A1476-013F-43FE-A9A9-D8F6BA3D2A0B',
                  },
                  {
                    monthly: [
                      77.82664409234896,
                      64.90934803258234,
                      53.294475306218594,
                      45.52668672111166,
                      33.479852149234944,
                      31.467283526856697,
                      34.95209372822364,
                      45.57525855212955,
                      59.28532509348501,
                      70.83462547417263,
                      75.70475707701104,
                      81.09981134309241,
                    ],
                    annual: 673.9561610964674,
                    children: [],
                    uuid: '47F88EE3-67E1-4694-AF31-23CB46822C31',
                  },
                  {
                    monthly: [
                      77.82664409234896,
                      64.90934803258234,
                      53.294475306218594,
                      45.52668672111166,
                      33.479852149234944,
                      31.467283526856697,
                      34.95209372822364,
                      45.57525855212955,
                      59.28532509348501,
                      70.83462547417263,
                      75.70475707701104,
                      81.09981134309241,
                    ],
                    annual: 673.9561610964674,
                    children: [],
                    uuid: '27610DCF-646D-4CA3-BD5A-21F4227EB0DD',
                  },
                  {
                    monthly: [
                      77.82664409234896,
                      64.90934803258234,
                      53.294475306218594,
                      45.52668672111166,
                      33.479852149234944,
                      31.467283526856697,
                      34.95209372822364,
                      45.57525855212955,
                      59.28532509348501,
                      70.83462547417263,
                      75.70475707701104,
                      81.09981134309241,
                    ],
                    annual: 673.9561610964674,
                    children: [],
                    uuid: '4649CF23-28EE-46E3-9E52-080D4B4BDCC2',
                  },
                  {
                    monthly: [
                      77.82664409234896,
                      64.90934803258234,
                      53.294475306218594,
                      45.52668672111166,
                      33.479852149234944,
                      31.467283526856697,
                      34.95209372822364,
                      45.57525855212955,
                      59.28532509348501,
                      70.83462547417263,
                      75.70475707701104,
                      81.09981134309241,
                    ],
                    annual: 673.9561610964674,
                    children: [],
                    uuid: '00E8C85F-A459-45E2-BFB4-7FE54F3F665B',
                  },
                  {
                    monthly: [
                      77.82664409234896,
                      64.90934803258234,
                      53.294475306218594,
                      45.52668672111166,
                      33.479852149234944,
                      31.467283526856697,
                      34.95209372822364,
                      45.57525855212955,
                      59.28532509348501,
                      70.83462547417263,
                      75.70475707701104,
                      81.09981134309241,
                    ],
                    annual: 673.9561610964674,
                    children: [],
                    uuid: 'C815204A-4171-4618-B730-1146AA1D26CB',
                  },
                  {
                    monthly: [
                      77.82664409234896,
                      64.90934803258234,
                      53.294475306218594,
                      45.52668672111166,
                      33.479852149234944,
                      31.467283526856697,
                      34.95209372822364,
                      45.57525855212955,
                      59.28532509348501,
                      70.83462547417263,
                      75.70475707701104,
                      81.09981134309241,
                    ],
                    annual: 673.9561610964674,
                    children: [],
                    uuid: '4373D616-74FC-4E8C-848D-18D2F29A1ACF',
                  },
                  {
                    monthly: [
                      77.82664409234896,
                      64.90934803258234,
                      53.294475306218594,
                      45.52668672111166,
                      33.479852149234944,
                      31.467283526856697,
                      34.95209372822364,
                      45.57525855212955,
                      59.28532509348501,
                      70.83462547417263,
                      75.70475707701104,
                      81.09981134309241,
                    ],
                    annual: 673.9561610964674,
                    children: [],
                    uuid: '5EF5F7BF-0242-41BE-AB1D-B3C57B26974A',
                  },
                ],
                uuid: '7F1A3CF6-534A-4E21-82F9-364A7AD76DE6',
              },
            ],
            uuid: 'BF1F69C1-BF21-4FF3-A0B4-0A5F2326AB99',
          },
        ],
        uuid: '7654622F-FA5A-445E-90BA-614C09F04AF0',
      },
    ],
    uuid: 'A68EE68F-DD21-4D95-A38A-24F43FE8C07B',
  },
  pricing: {
    extra: {},
    system_price_including_tax: 62400,
    system_price_excluding_tax: 56160,
    tax: 6240,
    settings: {
      tax_percentage_included: 10,
      price_per_watt: 20,
    },
  },
  payment_options: [
    {
      id: 10,
      code: 'cash',
      headline: 11500,
      headline_format: 'dollars_rounded',
      name: 'Cash Payment',
      regular_payment: 100,
      net_cost_to_customer: 9000,
      net_profit: 5000,
      utility_cost_annual_current: 2000,
      utility_cost_annual_proposed: 1000,
      utility_cost_total_current: 100000,
      utility_cost_total_proposed: 50000,
      payback_years: 8,
      cash_flows: [
        -10000,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
      ],
      bank_balance: [
        -1000,
        0,
        1000,
        2000,
        3000,
        4000,
        5000,
        6000,
        7000,
        8000,
        9000,
        10000,
        11000,
        12000,
        13000,
        14000,
        15000,
        16000,
        17000,
        18000,
      ],
    },
    {
      id: 1,
      code: 'loan',
      headline: 89.0,
      headline_format: 'dollars_and_cents',
      name: 'Loan Payment',
      regular_payment: 100,
      net_cost_to_customer: 9000,
      net_profit: 5000,
      utility_cost_annual_current: 2000,
      utility_cost_annual_proposed: 1000,
      utility_cost_total_current: 100000,
      utility_cost_total_proposed: 50000,
      payback_years: 8,
      cash_flows: [
        -10000,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
      ],
      bank_balance: [
        -1000,
        0,
        1000,
        2000,
        3000,
        4000,
        5000,
        6000,
        7000,
        8000,
        9000,
        10000,
        11000,
        12000,
        13000,
        14000,
        15000,
        16000,
        17000,
        18000,
      ],
    },
    {
      id: 2,
      code: 'ppa',
      headline: '15c /kWh',
      headline_format: 'string',
      name: 'PPA for 20 years',
      regular_payment: 100,
      net_cost_to_customer: 9000,
      net_profit: 5000,
      utility_cost_annual_current: 2000,
      utility_cost_annual_proposed: 1000,
      utility_cost_total_current: 100000,
      utility_cost_total_proposed: 50000,
      payback_years: 8,
      cash_flows: [
        -10000,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
      ],
      bank_balance: [
        -1000,
        0,
        1000,
        2000,
        3000,
        4000,
        5000,
        6000,
        7000,
        8000,
        9000,
        10000,
        11000,
        12000,
        13000,
        14000,
        15000,
        16000,
        17000,
        18000,
      ],
    },
  ],
  pricingSchemeTitle: 'Ohil',
  shadingOverride: null,
  snapshot: null,
  show_customer: false,
  moduleQuantity: 8,
  kwStc: 3.12,
  kw_stc: 3.12,
  battery_total_kwh: 13.0,
  pricingFormula: 'Price Per Watt',
  pricingSettings: {
    price_per_watt: 20,
    tax_percentage_included: 10,
  },
  costs: {
    modules: 0,
    inverters: 0,
    batteries: null,
    cogs: null,
    labor: 0,
    cogs_and_labor: 0,
    acquisition: 0,
    fulfillment: 0,
  },
  dcOptimizerEfficiency: null,
  autoString: true,
}

export const userLoginData = [
  {
    id: 1,
    projects: [
      {
        id: 101,
        assigned_team_member: {
          id: 1,
          first_name: 'Bob',
          family_name: 'Jones',
          email: 'bob@gmail.com',
          display: 'Bob',
        },
        proposal_data: {
          design: '{"object": {"userData":{"views":[{"show_customer":true}, {"show_customer":false}]}}}',
          address: '1600 California St',
          status: 0,
          usage: '{"usage_data_source":"kwh_annual","values":"9000"}',
          usage_normalized: {
            annual: 5000,
            monthly: [800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800],
          },
          systems: [
            {
              data: {
                uuid: 'def',
                moduleQuantity: 20,
                kwStc: 24.0,
                kw_stc: 24.0,
                battery_total_kwh: 13.0,
                pricing: {
                  system_price_including_tax: 25000,
                },
                bills: [
                  {
                    before: {
                      annual: {
                        total: 0,
                        feed_in_tariff: 0,
                      },
                      hourly_energy_flow_for_days: {},
                    },
                    after: {
                      annual: {
                        total: 0,
                        feed_in_tariff: 0,
                      },
                    },
                  },
                ],
                financials: {
                  total_cost_of_energy: 16000,
                  net_cost_to_customer: 12000,
                  utility_cost: 10000,
                },
                environmentals: environmentals,
                show_customer: true,
                output: {
                  annual: 5167,
                  monthly: [
                    597.7086266292399,
                    498.5037928902324,
                    409.3015703517588,
                    349.6449540181376,
                    257.1252645061244,
                    241.66873748625946,
                    268.43207983275755,
                    350.01798568035485,
                    455.31129671796486,
                    544.0099236416457,
                    581.4125343514447,
                    622.8465511149496,
                  ],
                },
                payment_options: [
                  {
                    id: 3,
                    code: 'cash',
                    headline: 11500,
                    headline_format: 'dollars_rounded',
                    name: 'Cash Payment',
                    regular_payment: 100,
                    net_cost_to_customer: 9000,
                    net_profit: 5000,
                    utility_cost_annual_current: 2000,
                    utility_cost_annual_proposed: 1000,
                    utility_cost_total_current: 100000,
                    utility_cost_total_proposed: 50000,
                    payback_years: 8,
                    cash_flows: [
                      -10000,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                    ],
                    bank_balance: [
                      -1000,
                      0,
                      1000,
                      2000,
                      3000,
                      4000,
                      5000,
                      6000,
                      7000,
                      8000,
                      9000,
                      10000,
                      11000,
                      12000,
                      13000,
                      14000,
                      15000,
                      16000,
                      17000,
                      18000,
                    ],
                  },
                  {
                    id: 4,
                    code: 'loan',
                    headline: 89.0,
                    headline_format: 'dollars_and_cents',
                    name: 'Loan Payment',
                    regular_payment: 100,
                    net_cost_to_customer: 9000,
                    net_profit: 5000,
                    utility_cost_annual_current: 2000,
                    utility_cost_annual_proposed: 1000,
                    utility_cost_total_current: 100000,
                    utility_cost_total_proposed: 50000,
                    payback_years: 8,
                    cash_flows: [
                      -10000,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                    ],
                    bank_balance: [
                      -1000,
                      0,
                      1000,
                      2000,
                      3000,
                      4000,
                      5000,
                      6000,
                      7000,
                      8000,
                      9000,
                      10000,
                      11000,
                      12000,
                      13000,
                      14000,
                      15000,
                      16000,
                      17000,
                      18000,
                    ],
                  },
                  {
                    id: 5,
                    code: 'ppa',
                    headline: '15c /kWh',
                    headline_format: 'string',
                    name: 'PPA for 20 years',
                    regular_payment: 100,
                    net_cost_to_customer: 9000,
                    net_profit: 5000,
                    utility_cost_annual_current: 2000,
                    utility_cost_annual_proposed: 1000,
                    utility_cost_total_current: 100000,
                    utility_cost_total_proposed: 50000,
                    payback_years: 8,
                    cash_flows: [
                      -10000,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                    ],
                    bank_balance: [
                      -1000,
                      0,
                      1000,
                      2000,
                      3000,
                      4000,
                      5000,
                      6000,
                      7000,
                      8000,
                      9000,
                      10000,
                      11000,
                      12000,
                      13000,
                      14000,
                      15000,
                      16000,
                      17000,
                      18000,
                    ],
                  },
                ],
                module: {
                  code: 'SPR-390E-WHT-D',
                  manufacturer: 'SunPower',
                },
                inverters: [{ code: 'INV XYZ' }, { code: 'INV XYZ' }],
                batteries: [{ code: 'BAT ABC', kwh_optimal: 2.0 }],
              },
            },
            {
              data: {
                uuid: 'abc',
                moduleQuantity: 12,
                kwStc: 12.0,
                kw_stc: 12.0,
                battery_total_kwh: 0.0,
                pricing: {
                  system_price_including_tax: 12000,
                },
                financials: {
                  total_cost_of_energy: 26000,
                  net_cost_to_customer: 22000,
                  utility_cost: 20000,
                },
                environmentals: environmentals,
                show_customer: true,
                output: {
                  annual: 10167,
                  monthly: [
                    1597.7086266292399,
                    1498.5037928902324,
                    1409.3015703517588,
                    1349.6449540181376,
                    1257.1252645061244,
                    1241.66873748625946,
                    1268.43207983275755,
                    1350.01798568035485,
                    1455.31129671796486,
                    1544.0099236416457,
                    1581.4125343514447,
                    1622.8465511149496,
                  ],
                },
                payment_options: [
                  {
                    id: 6,
                    code: 'cash',
                    headline: 11500,
                    headline_format: 'dollars_rounded',
                    name: 'Cash Payment',
                    regular_payment: 100,
                    net_cost_to_customer: 9000,
                    net_profit: 8000,
                    utility_cost_annual_current: 2000,
                    utility_cost_annual_proposed: 1000,
                    utility_cost_total_current: 100000,
                    utility_cost_total_proposed: 50000,
                    payback_years: 8,
                    cash_flows: [
                      -10000,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                    ],
                    bank_balance: [
                      -1000,
                      0,
                      1000,
                      2000,
                      3000,
                      4000,
                      5000,
                      6000,
                      7000,
                      8000,
                      9000,
                      10000,
                      11000,
                      12000,
                      13000,
                      14000,
                      15000,
                      16000,
                      17000,
                      18000,
                    ],
                  },
                  {
                    id: 7,
                    code: 'loan',
                    headline: 89.0,
                    headline_format: 'dollars_and_cents',
                    name: 'Loan Payment',
                    regular_payment: 100,
                    net_cost_to_customer: 9000,
                    net_profit: 9000,
                    utility_cost_annual_current: 2000,
                    utility_cost_annual_proposed: 1000,
                    utility_cost_total_current: 100000,
                    utility_cost_total_proposed: 50000,
                    payback_years: 8,
                    cash_flows: [
                      -10000,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                    ],
                    bank_balance: [
                      -1000,
                      0,
                      1000,
                      2000,
                      3000,
                      4000,
                      5000,
                      6000,
                      7000,
                      8000,
                      9000,
                      10000,
                      11000,
                      12000,
                      13000,
                      14000,
                      15000,
                      16000,
                      17000,
                      18000,
                    ],
                  },
                  {
                    id: 8,
                    code: 'ppa',
                    headline: '15c /kWh',
                    headline_format: 'string',
                    name: 'PPA for 20 years',
                    regular_payment: 100,
                    net_cost_to_customer: 9000,
                    net_profit: 7000,
                    utility_cost_annual_current: 2000,
                    utility_cost_annual_proposed: 1000,
                    utility_cost_total_current: 100000,
                    utility_cost_total_proposed: 50000,
                    payback_years: 8,
                    cash_flows: [
                      -10000,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                      100,
                    ],
                    bank_balance: [
                      -1000,
                      0,
                      1000,
                      2000,
                      3000,
                      4000,
                      5000,
                      6000,
                      7000,
                      8000,
                      9000,
                      10000,
                      11000,
                      12000,
                      13000,
                      14000,
                      15000,
                      16000,
                      17000,
                      18000,
                    ],
                  },
                ],
                module: {
                  code: 'SPR-390E-WHT-D',
                  manufacturer: 'SunPower',
                },
                inverters: [{ code: 'XYZ' }, { code: 'PRR' }],
                batteries: [{ code: 'XYZ', kwh_optimal: 2.0 }],
              },
            },
          ],
        },
      },
    ],
  },
]

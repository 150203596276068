// @ts-nocheck
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined'
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined'
import { Button } from 'opensolar-ui'
import React, { FC, useMemo } from 'react'
import { useTranslate } from 'react-admin'
import ToolTip from '../tools/MuiToolTip'
import Spotlight from '../tools/Spotlight'
import useFindElements from '../tools/useFindElements'
import { SpotlightConfigType, SpotlightPropsType, ToolTipPropsType, TourConfigType } from '../types'
import DefaultTooltip from './DefaultToolTip'

const ClickPricingTabToolTip = (props: ToolTipPropsType) => {
  const { handleNext, handlePrev, handleCancel } = props
  const translate = useTranslate()
  return (
    <DefaultTooltip
      title={translate('SET PRICING')}
      // onClose={handleCancel}
      icon={MonetizationOnOutlinedIcon}
      arrowPlacement={'left'}
      content={
        <div style={{ maxWidth: 350 }}>{translate('Click the Pricing section to set the price for this system.')}</div>
      }
      actions={
        <>
          <Button
            onClick={() => handlePrev && handlePrev()}
            onTouchStart={(e) => {
              e.stopPropagation()
              if (handlePrev) {
                handlePrev()
              }
            }}
          >
            <span>{translate('Back')}</span>
          </Button>
          <Button disabled={true}>
            <span>{translate('Next')}</span>
          </Button>
        </>
      }
    />
  )
}

const BackToSystemTooltip = (props: ToolTipPropsType) => {
  const { handleNext, handlePrev, handleCancel } = props
  const panelQuantity = window.editor?.selectedSystem?.moduleQuantity()
  const translate = useTranslate()
  return (
    <DefaultTooltip
      title={translate('Set Pricing')}
      // onClose={handleCancel}
      icon={MonetizationOnOutlinedIcon}
      content={
        <div>
          {/*{`Now that you have placed the panels, let's set the system price. Click "Back to ${*/}
          {/*  isNaN(panelQuantity) ? '' : panelQuantity*/}
          {/*} Panels System" to proceed.`}*/}
          {translate('panels_system', { panelQuantity: isNaN(panelQuantity) ? '' : panelQuantity })}
        </div>
      }
      actions={
        <>
          <Button
            onClick={() => handlePrev && handlePrev()}
            onTouchStart={(e) => {
              e.stopPropagation()
              if (handlePrev) {
                handlePrev()
              }
            }}
          >
            <span>{translate('Back')}</span>
          </Button>
          <Button disabled={true}>
            <span>{translate('Next')}</span>
          </Button>
        </>
      }
      arrowPlacement={'left'}
    />
  )
}

const PriceDetailToolTip = (props: ToolTipPropsType) => {
  const { handleNext, handlePrev, handleCancel } = props
  const translate = useTranslate()
  return (
    <DefaultTooltip
      title={translate('SET PRICING')}
      // onClose={handleCancel}
      icon={MonetizationOnOutlinedIcon}
      content={
        <div style={{ maxWidth: 400 }}>
          <div style={{ marginBottom: 10 }}>
            {translate(
              'OpenSolar allows you to configure pricing formulas that work for your business (e.g. Price-Per-Watt, Cost Plus). You can set these up after the tour.'
            )}
          </div>

          <div style={{ marginBottom: 10 }}>
            {translate('For now, let\'s choose "Enter Price Manually" and enter the system price including taxes.')}
          </div>
          <div>{translate('Any discounts, price adders and incentives will be applied to this base price.')}</div>
        </div>
      }
      actions={
        <>
          <Button
            onClick={() => handlePrev && handlePrev()}
            onTouchStart={(e) => {
              e.stopPropagation()
              if (handlePrev) {
                handlePrev()
              }
            }}
          >
            <span>{translate('Back')}</span>
          </Button>
          <Button
            onClick={() => handleNext && handleNext()}
            onTouchStart={(e) => {
              e.stopPropagation()
              if (handleNext) {
                handleNext()
              }
            }}
          >
            <span>{translate('Next')}</span>
          </Button>
        </>
      }
      arrowPlacement={'left'}
    />
  )
}

const OpenPanelSystemTooltip = (props: ToolTipPropsType) => {
  const { handlePrev } = props
  const translate = useTranslate()
  return (
    <DefaultTooltip
      title={translate('SYSTEM SUMMARY')}
      icon={LayersOutlinedIcon}
      content={<div>{translate('Click here to open the system summary.')}</div>}
      actions={
        <>
          <Button
            onClick={() => handlePrev && handlePrev()}
            onTouchStart={(e) => {
              e.stopPropagation()
              if (handlePrev) {
                handlePrev()
              }
            }}
          >
            <span>{translate('Back')}</span>
          </Button>
          <Button disabled={true}>
            <span>{translate('Next')}</span>
          </Button>
        </>
      }
      arrowPlacement={'left'}
    />
  )
}

const ToolTips = [
  { targetId: 'back-system-button', placement: 'right', component: BackToSystemTooltip },
  { targetId: 'expansion-content-system', placement: 'right', component: OpenPanelSystemTooltip },
  { targetId: 'system-panel-pricing-tab', placement: 'right', component: ClickPricingTabToolTip },
  { targetId: 'pricing-detail-container', placement: 'right', component: PriceDetailToolTip },
]

const SetPriceToolTip: FC<ToolTipPropsType> = ({ handleNext, handlePrev, handleCancel }) => {
  const foundElementId = useFindElements(
    ToolTips.map((a) => a.targetId),
    true
  )
  const tooltip: any = useMemo(() => ToolTips.find((a) => a.targetId === foundElementId), [foundElementId])
  return tooltip ? (
    <ToolTip
      key={`tour-step-${tooltip.targetId}-tooltip`}
      handleNext={handleNext}
      handlePrev={handlePrev}
      handleCancel={handleCancel}
      {...tooltip}
    />
  ) : null
}

const ClickPricingTabSpotlight = { targetId: 'system-panel-pricing-tab' }
const BackToSystemSpotlight = { targetId: 'back-system-button' }
const PriceDetailSpotlight = { targetId: 'pricing-detail-container', keepObserve: true }
const OpenPanelSystemSpotlight = { targetId: 'expansion-content-system' }
const Spotlights = [BackToSystemSpotlight, OpenPanelSystemSpotlight, ClickPricingTabSpotlight, PriceDetailSpotlight]

const SetPriceSpotlight: FC<SpotlightPropsType> = ({ handleHover }) => {
  const foundElementId = useFindElements(
    Spotlights.map((a) => a.targetId),
    true
  )
  const spotlight: SpotlightConfigType | undefined = useMemo(
    () => Spotlights.find((a) => a.targetId === foundElementId),
    [foundElementId]
  )
  return spotlight ? (
    <Spotlight key={`tour-step-${spotlight.targetId}-spotlight`} handleHover={handleHover} {...spotlight} />
  ) : null
}

export const SetPrice: TourConfigType = {
  spotlights: [<SetPriceSpotlight />],
  tooltips: [<SetPriceToolTip />],
}

import { ComponentVersions_2_1 } from 'constants/uxVersions'
import { useSelectedSystem } from 'Designer/hooks/useSelectedSystem'
import Alert from 'elements/Alert'
import Button from 'elements/button/Button'
import { ComponentVersionsInherit } from 'opensolar-ui'
import { FC, useState } from 'react'
import { useTranslate } from 'react-admin'
import { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { AssociationType, ComponentDependencies, DependenciesForDisplayByCode } from 'types/associatedComponents'
import { StudioSystemType } from 'types/global'
import { ComponentTypes } from 'types/selectComponent'
import AssociatedComponentsDialog from './AssociatedComponentsDialog'

type Filter = {
  componentTypes: ComponentTypes[]
}

type Props = {
  associationType: AssociationType
  filter?: Filter
}

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  text: {
    paddingBottom: '10px',
  },
}))

function fetchComponentDependencies(
  system: StudioSystemType,
  associationType: AssociationType,
  filter?: Filter
): DependenciesForDisplayByCode {
  const dependenciesForDisplay: DependenciesForDisplayByCode = {}
  const dependencies: ComponentDependencies = system.custom_data['component_dependencies'] || []

  dependencies.forEach((dependency) => {
    let associatedComponents = dependency[associationType]
    associatedComponents?.forEach((associatedComponent) => {
      if (!filter || filter.componentTypes.includes(associatedComponent.componentType)) {
        if (!associatedComponent.totalCalculatedQty) return
        if (!dependenciesForDisplay[associatedComponent.code]) dependenciesForDisplay[associatedComponent.code] = []
        dependenciesForDisplay[associatedComponent.code].push({
          dependentComponent: associatedComponent,
          parentComponents: dependency.parentComponents,
        })
      }
    })
  })

  return dependenciesForDisplay
}

const AssociatedComponentsAlert: FC<Props> = ({ associationType, filter }) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const translate = useTranslate()
  const system = useSelectedSystem()
  if (!system?.custom_data) return <></>
  const components = fetchComponentDependencies(system, associationType, filter)
  if (!Object.values(components).length) return <></>

  return (
    <ComponentVersionsInherit versions={ComponentVersions_2_1}>
      <Alert severity={'info'}>
        <div className={classes.text}>
          {associationType === 'require'
            ? translate('Required components have been automatically added based on your selection.')
            : translate('Save time with our smart picks for you!')}
        </div>

        <Button
          variant="contained"
          size="small"
          onClick={() => {
            setOpen(true)
          }}
        >
          <span>{translate('View Components')}</span>
        </Button>
      </Alert>
      <AssociatedComponentsDialog
        isOpen={open}
        associationType={associationType}
        components={components}
        onClose={() => setOpen(false)}
      />
    </ComponentVersionsInherit>
  )
}

export default AssociatedComponentsAlert

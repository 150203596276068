import { Badge, InputAdornment } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { permissionsSelectors } from 'ducks/permissions'
import BulkDeleteWithConfirmButton from 'elements/button/BulkDeleteWithConfirmButton'
import UiContainer from 'elements/UiContainer'
import lodashDebounce from 'lodash/debounce'
import { Button, DeleteOutlineIcon, FilterIcon, SearchOutlineIcon, SettingsIcon, styled, TextField } from 'opensolar-ui'
import { COLOR_PALETTE, getProjectSectionStyles } from 'projectSections/sections/manage3/styles'
import { useTranslate } from 'ra-core'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import FilterDrawer from './filtering/FilterDrawer'
import SettingsDrawer from './views/SettingsDrawer'

const uiKey = 'projects.search'
const DEFAULT_DEBOUNCE = 500

const FilterBadge = styled(Badge)({
  '& .MuiBadge-badge': { background: COLOR_PALETTE.darkBlue2 },
})

const Wrapper = styled('div')({
  width: '100%',
  margin: '10px 0',
  flexWrap: 'wrap',
  rowGap: 10,
})

const LeftContainer = styled('div')({
  marginRight: 'auto',
  flexWrap: 'wrap',
  rowGap: 10,
})

const SearchField = styled(TextField)({
  margin: 0,
  width: 400,
  maxWidth: '90%',
  marginRight: 10,
})

const SearchToolbar = (props) => {
  const { setFilters, filterValues, selectedIds, viewSettings } = props
  const translate = useTranslate()
  const classes = getProjectSectionStyles()
  const [searchText, setSearchText] = useState('')
  const [filterOpen, setFilterOpen] = useState(false)
  const [settingsOpen, setSettingsOpen] = useState(false)
  const { allowDelete } = useSelector(permissionsSelectors.getPermissionByKey('project'))

  const trackValueChanged = useCallback(() => {
    logAmplitudeEvent('generic_filter_interacted', {
      action: 'updated',
      key: 'q',
    })
  }, [])

  const debouncedTrackValueChanged = useMemo(() => {
    return lodashDebounce(trackValueChanged, DEFAULT_DEBOUNCE)
  }, [trackValueChanged])

  const handleSearch = (e) => {
    const queryVal = e?.target?.value
    debouncedTrackValueChanged()
    setFilters({ ...filterValues, q: queryVal })
    setSearchText(e.target.value)
  }

  // Initialise searchfield Text from the URL and remove search on view change
  useEffect(() => {
    if (filterValues?.q) {
      setSearchText(filterValues.q)
    }
    if (searchText) {
      setSearchText('')
    }
  }, [filterValues, viewSettings.selectedView])

  const activeFiltersCount = useMemo(() => {
    if (filterValues?.q) return Object.keys(filterValues).length - 1
    else return Object.keys(filterValues).length
  }, [filterValues])

  return (
    <UiContainer uiKey={uiKey}>
      <Wrapper className={classes.row}>
        <LeftContainer className={classes.row}>
          <SearchField
            label={false}
            size="small"
            value={searchText}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlineIcon color={COLOR_PALETTE.iconColor} />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
            variant="outlined"
            placeholder={translate('Search')}
          />

          <FilterBadge badgeContent={activeFiltersCount} color="primary" invisible={activeFiltersCount === 0}>
            <Button
              variant="outlined"
              color={activeFiltersCount > 0 ? 'primary' : 'secondary'}
              startIcon={<FilterIcon />}
              onClick={() => {
                setFilterOpen(!filterOpen)
              }}
              style={{
                borderColor: activeFiltersCount > 0 ? COLOR_PALETTE.darkBlue2 : undefined,
              }}
            >
              {translate('Sort & Filter')}
            </Button>
          </FilterBadge>
        </LeftContainer>
        {allowDelete && !!selectedIds?.length && (
          <BulkDeleteWithConfirmButton
            label="Delete Projects"
            variant={'outlined'}
            color={'secondary'}
            startIcon={<DeleteOutlineIcon />}
            {...props}
            style={{ marginRight: 10 }}
          />
        )}
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<SettingsIcon />}
          onClick={() => {
            setSettingsOpen(!settingsOpen)
          }}
        >
          {translate('View Settings')}
        </Button>
      </Wrapper>

      <FilterDrawer
        open={filterOpen}
        onClose={() => {
          setFilterOpen(false)
        }}
        filterValues={filterValues}
        setFilters={setFilters}
      />
      <SettingsDrawer
        open={settingsOpen}
        onClose={() => {
          setSettingsOpen(false)
        }}
        {...viewSettings}
      />
    </UiContainer>
  )
}

export default SearchToolbar

import { logAmplitudeEvent } from 'amplitude/amplitude'
import { Alert } from 'opensolar-ui'
import { useEffect } from 'react'
import { useTranslate } from 'react-admin'
import { hasBatteryInSystem } from '../utility/utils'

const ExistingAppliedBatteryAlert = () => {
  const translate = useTranslate()

  const hasBatteries = hasBatteryInSystem(window.editor?.selectedSystem)

  useEffect(() => {
    if (hasBatteries) logAmplitudeEvent('bda_warning_notification_banner')
  }, [])

  if (!hasBatteries) {
    return null
  }

  return (
    <Alert severity="warning">
      {translate(
        'Adding battery from the modeling tool will override any existing batteries already applied to the project.'
      )}
    </Alert>
  )
}

export default ExistingAppliedBatteryAlert

import React, { Component } from 'react'
import _ from 'lodash'
import CustomCheckboxGroupInput from '../../elements/input/CustomCheckboxGroupInput'

export const GROUP_BREAK_PREFIX = 'group_break_'

const getNewIndex = function (arr, new_index) {
  return ((new_index % arr.length) + arr.length) % arr.length
}

const reorderArray = function (arr, old_index, new_index) {
  new_index = getNewIndex(arr, new_index)
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
  return arr // for testing
}

const getAllChildrenChoices = (choices) => {
  let result = []
  ;(function loopThroughChoices(choices) {
    choices.forEach((choice) => {
      result.push(choice.id)
      if (choice.children) loopThroughChoices(choice.children)
    })
  })(choices)
  return result
}

const updateDisabledChoices = (choices, disabledChoices) => {
  let result = disabledChoices
  ;(function loopThroughChoices(choices) {
    choices.forEach((choice) => {
      if (choice.children) {
        if (choice.isChecked === false) {
          result = result.concat(getAllChildrenChoices(choice.children))
        } else {
          loopThroughChoices(choice.children)
        }
      }
    })
  })(choices)
  return result
}

const reorderSection = (choices, selectedField, direction) => {
  return choices.every((choice, index) => {
    if (choice.id === selectedField) {
      const currentIndex = index
      const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1
      reorderArray(choices, currentIndex, newIndex)
      return false
    } else if (choice.children) {
      return reorderSection(choice.children, selectedField, direction)
    }
    return true
  })
}

const getParentId = (choices, selectedId) => {
  let result = 'top'
  function assignParentId(choices, parentId) {
    return choices.every((choice) => {
      if (choice.id === selectedId) {
        result = parentId
        return false
      } else if (choice.children) {
        return assignParentId(choice.children, choice.id)
      }
      return true
    })
  }
  assignParentId(choices, result)
  return result
}

const reorderGenericItem = (choices, selectedField, direction) => {
  const choicesList = getAllChildrenChoices(choices)
  const selectedFieldIndex = choicesList.indexOf(selectedField)
  const swappedIndex =
    direction === 'up'
      ? getNewIndex(choicesList, selectedFieldIndex - 1)
      : getNewIndex(choicesList, selectedFieldIndex + 1)
  const replacedChoiceId = choicesList[swappedIndex]
  let replacedChoiceIndex, indexInCurrentGroup, currentChoicesGroup, newChoicesGroup
  ;(function assignReorderChoices(choices, replacedId) {
    choices.forEach((choice, index) => {
      if (choice.id === selectedField) {
        indexInCurrentGroup = index
        currentChoicesGroup = choices
      } else if (choice.id === replacedId) {
        replacedChoiceIndex = index
        newChoicesGroup = choices
      }
      if (choice.children) {
        return assignReorderChoices(choice.children, replacedId)
      }
    })
  })(choices, replacedChoiceId)
  let newIndex = replacedChoiceIndex,
    selectedGenericItem
  if (direction === 'up') {
    if (newChoicesGroup.length === replacedChoiceIndex + 1) newIndex = newChoicesGroup.length
  } else {
    if (newChoicesGroup[replacedChoiceIndex].children && currentChoicesGroup.length !== indexInCurrentGroup + 1) {
      newIndex = 0
      newChoicesGroup = newChoicesGroup[replacedChoiceIndex].children
    }
  }
  selectedGenericItem = currentChoicesGroup.splice(indexInCurrentGroup, 1)[0]
  newChoicesGroup.splice(newIndex, 0, selectedGenericItem)
}

const updateSection = (choices, selectedField, isChecked) => {
  return choices.every((choice, index) => {
    if (choice.id === selectedField) {
      choice.isChecked = isChecked
      return false
    } else if (choice.children) {
      return updateSection(choice.children, selectedField, isChecked)
    }
    return true
  })
}

const refreshChoices = (choices, inputValue) => {
  choices.sort((a, b) => {
    if (!inputValue.includes(a.id)) {
      return 1
    } else if (!inputValue.includes(b.id)) {
      return -1
    } else {
      return inputValue.indexOf(a.id) - inputValue.indexOf(b.id)
    }
  })
  choices.forEach((choice) => {
    choice.isChecked = inputValue.includes(choice.id)
    if (choice.children) refreshChoices(choice.children, inputValue)
  })
}

export default class SectionConfiguration extends Component {
  constructor(props) {
    super(props)
    this.state = {
      choices: _.cloneDeep(props.choices),
    }
  }

  componentDidMount() {
    this.updateChoices()
  }

  restructureChoices = (inputValue) => {
    const newChoices = this.state.choices

    if (!inputValue) {
      return newChoices
    }

    const { genericGroup } = this.props
    inputValue.forEach((value, index) => {
      if (value.includes(GROUP_BREAK_PREFIX)) {
        const genericBlockIndex = index - 1
        const genericBlockId = inputValue[genericBlockIndex]
        if (genericGroup.includes(genericBlockId)) {
          const replacedGenericBlock = newChoices.splice(
            newChoices.findIndex((choice) => choice.id === genericBlockId),
            1
          )[0]
          ;(function loopThroughChoices(choices, group = 'top') {
            if (value === `${GROUP_BREAK_PREFIX}${group}`) {
              choices.push(replacedGenericBlock)
            }
            choices.forEach((choice) => {
              if (choice.children) loopThroughChoices(choice.children, choice.id)
            })
          })(newChoices)
        }
      }
    })
    return newChoices
  }

  updateChoices = (inputValue) => {
    const {
      input: { value = [] },
    } = this.props
    const newValue = inputValue || value
    const newChoices = this.restructureChoices(newValue)
    refreshChoices(newChoices, newValue)
    this.setState({
      choices: newChoices,
    })
  }

  getDisabledChoices = () => {
    const { choices } = this.state
    const { disabled, disabledChoices = [] } = this.props
    if (disabled) {
      return getAllChildrenChoices(choices)
    } else {
      return updateDisabledChoices(choices, disabledChoices)
    }
  }

  getInputValue = (allChoices) => {
    const { genericGroup } = this.props
    let newValue = []
    ;(function loopThroughChoices(choices) {
      choices.forEach((choice) => {
        if (choice.isChecked) {
          newValue.push(choice.id)
          genericGroup.includes(choice.id) &&
            newValue.push(`${GROUP_BREAK_PREFIX}${getParentId(allChoices, choice.id)}`)
        }
        if (choice.children) loopThroughChoices(choice.children)
      })
    })(allChoices)
    return newValue
  }

  refreshInputValue = (updatedChoices) => {
    const { updateField, source } = this.props
    const newValue = this.getInputValue(updatedChoices)
    updateField(source, newValue)
    this.setState({
      choices: updatedChoices,
    })
  }

  handleReorder = (selectedField, direction) => {
    const { choices } = this.state
    const { genericGroup } = this.props
    if (genericGroup.includes(selectedField)) {
      reorderGenericItem(choices, selectedField, direction)
    } else {
      reorderSection(choices, selectedField, direction)
    }
    this.refreshInputValue(choices)
  }

  handleCheck = (event, isChecked) => {
    const { choices } = this.state
    updateSection(choices, event.target.value, isChecked)
    this.refreshInputValue(choices)
  }

  render() {
    return (
      <CustomCheckboxGroupInput
        {...this.props}
        disabledChoices={this.getDisabledChoices()}
        choices={[...this.state.choices]}
        handleCheck={this.handleCheck}
        handleReorder={this.handleReorder}
      />
    )
  }
}

import LoadingDots from 'layout/widgets/LoadingDots'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { PaymentRequestType } from '../types'
import { useGetMonitePaymentLink, useLoadMonitePaymentsComponent } from './utils'

type PropTypes = {
  paymentRequestData: PaymentRequestType
  projectId: string
}

const useStyles = makeOpenSolarStyles((theme) => ({
  main: {
    minHeight: '400px',
    position: 'relative',
  },
  loadingWrapper: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
    opacity: 0.75,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F8F8F8',
    zIndex: 2,
  },
}))

const SEPACreditForm: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const { isLoaded } = useLoadMonitePaymentsComponent()
  const { linkID, status, isLoadingPaymentRequest } = useGetMonitePaymentLink(
    isLoaded,
    props.projectId,
    props.paymentRequestData.payment_request_id
  )

  return (
    <div className={classes.main} data-testid="sepa-credit-wrapper">
      {!linkID && isLoadingPaymentRequest && (
        <div className={classes.loadingWrapper}>
          <LoadingDots />
        </div>
      )}
      {linkID && isLoaded && (
        // @ts-ignore
        <monite-payment
          style={{ position: 'absolute', height: '100%', width: '100%' }}
          link-id={linkID}
          data-testid="monite-payments-component"
        />
      )}
    </div>
  )
}

export default SEPACreditForm

import { Accordion, AccordionSummary, TextField, Typography } from '@material-ui/core'
import LinkIcon from '@material-ui/icons/LinkOutlined'
import ShareIcon from '@material-ui/icons/ShareOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { paymentOptionSelectionSelectors } from 'ducks/paymentOptionSelection'
import { Button, Switch } from "opensolar-ui"
import Confirm from 'projectSections/elements/dialog/Confirm'
import { useGetSoldRecords } from 'projectSections/sections/payments/cashFlowTransactions/utils'
import { useIsProjectUsingCashFlow } from 'projectSections/sections/payments/hooks'
import React, { useEffect, useState } from 'react'
import { NotificationType, useNotify, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import FinanceShareWarning from './FinanceShareWarning'

const restClientInstance = restClient(window.API_ROOT + '/api')

const ShareableLinkAccordion = ({ orgId, projectId }: { orgId?: number; projectId: number }) => {
  const translate = useTranslate()
  const notify = useNotify()
  const form = useForm()
  const shareLink = form.getState().values?.share_link
  const [url, setUrl] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [enableSharing, setEnableSharing] = useState({
    checkEnabled: false,
  })

  const [disableConfirmShowing, setDisableConfirmShowing] = useState(false)
  const project = useFormState().values
  const [soldSystem, soldPaymentOption] = useGetSoldRecords(project)
  const refreshSystemsTrigger = useSelector(paymentOptionSelectionSelectors.getSystemRefreshTrigger)
  const isProjectUsingCashflow = useIsProjectUsingCashFlow(soldSystem, soldPaymentOption, refreshSystemsTrigger)

  useEffect(() => {
    if (!projectId) return // 'new' project
    if (shareLink !== '') {
      setUrl(shareLink)
      setEnableSharing({
        checkEnabled: true,
      })
    }
  }, [projectId])

  const copyUrl = (
    url: string,
    notify: (message: string, type?: NotificationType) => void,
    translate: (value: string) => string
  ) => {
    navigator.clipboard.writeText(url).then(
      function () {
        notify(translate('Link copied to clipboard'), 'success')
      },
      function (err) {
        console.warn(err)
        notify(translate('Failed to copy link to clipboard'), 'warning')
      }
    )
  }

  const toggleSharingEnabled = () => {
    setLoading(true)
    let promise
    if (enableSharing.checkEnabled) {
      logAmplitudeEvent('link_sharing_disabled', {
        project_id: `${projectId}`,
        page: 'project/info',
        has_cashflow: isProjectUsingCashflow,
      })
      promise = restClientInstance('CUSTOM_PUT', 'custom', {
        url: 'orgs/' + orgId + '/projects/' + projectId + '/share_link/',
        data: {
          enable: false,
        },
      }).then((res: { data: { url: React.SetStateAction<string> } }) => {
        if (res.data.url !== '') {
          setUrl(res.data.url)
        }
        if (res.data.url === '') {
          form.mutators.updateFieldSilently('share_link', res.data.url)
        }
      })
    } else {
      logAmplitudeEvent('link_sharing_enabled', {
        project_id: `${projectId}`,
        page: 'project/info',
        has_cashflow: isProjectUsingCashflow,
      })
      promise = restClientInstance('CUSTOM_PUT', 'custom', {
        url: 'orgs/' + orgId + '/projects/' + projectId + '/share_link/',
        data: {
          enable: true,
        },
      }).then((res: { data: { url: React.SetStateAction<string> } }) => {
        if (res.data.url !== '') {
          setUrl(res.data.url)
          form.mutators.updateFieldSilently('share_link', res.data.url)
        }
      })
    }
    promise.then(() => setEnableSharing({ ...enableSharing, checkEnabled: !enableSharing.checkEnabled }))
    promise.finally(() => setLoading(false))
  }

  const handleSwitchChange = (event: { target: { name: any; checked: any } }) => {
    if (enableSharing.checkEnabled) {
      setDisableConfirmShowing(true)
    } else {
      toggleSharingEnabled()
    }
  }

  return !projectId ? (
    <></>
  ) : (
    <Accordion key="shareableLink" expanded={true}>
      <AccordionSummary
        aria-controls="expansion-content-shareable"
        id="expansion-header-shareable"
        style={{ alignItems: 'center', justifyContent: 'space-between' }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <ShareIcon />
            <span style={{ margin: '0 10px' }}>{translate('Share Proposal')}</span>
          </div>
        </div>
      </AccordionSummary>
      <div style={{ width: '90%', margin: 'auto' }}>
        <Typography variant="body2">{translate('To allow sharing this proposal anonymously.')}</Typography>
        <Typography
          variant="body2"
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingTop: 10,
            paddingBottom: 10,
            width: '100%',
          }}
        >
          {translate('Enable sharing')}
          <Switch checked={enableSharing.checkEnabled} onChange={handleSwitchChange} name="checkEnabled" />
        </Typography>
        {enableSharing.checkEnabled && !loading && (
          <div style={{ width: '100%', margin: 'auto', marginBottom: 20 }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <TextField variant="outlined" size="small" fullWidth value={url || ''} />
              <Button
                variant="contained"
                style={{ marginLeft: 10, minWidth: 50 }}
                startIcon={<LinkIcon />}
                onClick={() => copyUrl(url, notify, translate)}
              >
                {translate('Copy')}
              </Button>
            </div>
            <FinanceShareWarning />
          </div>
        )}
      </div>
      <Confirm
        open={disableConfirmShowing}
        header={{ title: translate('ra.message.are_you_sure') }}
        content={translate(
          'This will invalidate the existing shareable link permanently. Any customer who has access to the link will no longer be able to access the online proposal.'
        )}
        buttons={[
          {
            children: 'Cancel',
            onClick: () => setDisableConfirmShowing(false),
          },
          {
            color: 'primary',
            children: 'OK',
            onClick: () => {
              setDisableConfirmShowing(false)
              toggleSharingEnabled()
            },
          },
        ]}
      />
    </Accordion>
  )
}

export default ShareableLinkAccordion

import { Grid, useMediaQuery } from '@material-ui/core'
import useAdFilters from 'elements/ads/useAdFilters'
import BannerAd, { filterAvailableAds, weightedRandom, weightedRandomMultiple } from 'elements/BannerAd'
import { usePromos } from 'persistentContent/inAppPromo/hooks/usePromos'
import { bannerConfigType } from 'persistentContent/inAppPromo/types'
import { useTranslate } from 'ra-core'
import { FC, useEffect, useMemo, useState } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapperRelativeNotUsed: {
    width: '90%',
    maxWidth: '800px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'left',
    marginTop: 60,
  },
  wrapperAbsolute: {
    width: '90%',
    maxWidth: '800px',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    bottom: 90,
    left: '50%',
    transform: 'translateX(-50%)',
    position: 'absolute',
  },
  campaignIcon: {
    maxWidth: 24,
    maxHeight: 24,
    fill: '#212121',
    marginRight: 8,
    marginLeft: 6,
    alignItems: 'center',
  },
  headingContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    margin: '18px 10px 10px 10px',
    textAlign: 'left',
  },
  headingIcon: {
    verticalAlign: 'middle',
  },
  headingTitle: {
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.87)',
  },
}))

type Props = {
  onSetShown?: (shown: boolean) => void
}

const HomeAdCluster: FC<Props> = (props) => {
  // Manually fetch ad placements/
  // Hide the whole element if banner is not filled
  // Only show text links if both text links are found
  const { promos: promoConfigs } = usePromos('banner')
  const [leaderboardBannerConfig, setLeaderboardBannerConfig] = useState<bannerConfigType | null>(null)
  const [textLinkBannerConfigs, setTextLinkBannerConfigs] = useState<bannerConfigType[] | null>(null)
  const heightIsSufficient = useMediaQuery('(min-height:740px)')
  const widthIsSufficient = useMediaQuery('(min-width:850px)')
  const [isReady, setIsReady] = useState<boolean>(false)
  const isMobileDevice = useMemo(() => window.Utils?.isTouchDevice(), [])
  const adFiltersLeaderboard = useAdFilters({ formats: ['leaderboard'], placement: 'home_cluster' })
  const adFiltersText = useAdFilters({ formats: ['text'], placement: 'home_cluster' })

  const classes = useStyles()
  const translate = useTranslate()

  useEffect(() => {
    if (promoConfigs?.length > 0 && !isReady) {
      // 1 x  Banner
      let adConfig
      let availableAds

      availableAds = filterAvailableAds(promoConfigs, adFiltersLeaderboard)
      adConfig = availableAds.length > 0 ? weightedRandom(availableAds) : null
      if (adConfig?.bannerConfig) {
        setLeaderboardBannerConfig(adConfig.bannerConfig)
      }

      // 2 x Text Links
      availableAds = filterAvailableAds(promoConfigs, adFiltersText)
      let textLinkAdConfigs = availableAds.length > 0 ? weightedRandomMultiple(availableAds, 2) : []
      if (textLinkAdConfigs.filter((textLinkAdConfig) => textLinkAdConfig.bannerConfig).length === 2) {
        setTextLinkBannerConfigs(textLinkAdConfigs.map((textLinkAdConfig) => textLinkAdConfig.bannerConfig))
      }

      setIsReady(true)
    }
  }, [promoConfigs])

  const hidden = !heightIsSufficient || !widthIsSufficient || isMobileDevice

  useEffect(() => {
    props.onSetShown?.(!hidden)
  }, [hidden])

  if (hidden) return null

  return (
    <div className={classes.wrapperAbsolute}>
      <Grid container spacing={0} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {(leaderboardBannerConfig || textLinkBannerConfigs?.length === 2) && (
          <div
            style={{
              width: '100%',
              boxSizing: 'border-box',
              borderRadius: '4px',
              border: '2px solid #dedede',
              textAlign: 'center',
            }}
          >
            <div className={classes.headingContainer}>
              <div className={classes.campaignIcon}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                  <path d="M18 11v2h4v-2h-4zm-2 6.61c.96.71 2.21 1.65 3.2 2.39.4-.53.8-1.07 1.2-1.6-.99-.74-2.24-1.68-3.2-2.4-.4.54-.8 1.08-1.2 1.61zM20.4 5.6c-.4-.53-.8-1.07-1.2-1.6-.99.74-2.24 1.68-3.2 2.4.4.53.8 1.07 1.2 1.6.96-.72 2.21-1.65 3.2-2.4zM4 9c-1.1 0-2 .9-2 2v2c0 1.1.9 2 2 2h1v4h2v-4h1l5 3V6L8 9H4zm5.03 1.71L11 9.53v4.94l-1.97-1.18-.48-.29H4v-2h4.55l.48-.29zM15.5 12c0-1.33-.58-2.53-1.5-3.35v6.69c.92-.81 1.5-2.01 1.5-3.34z"></path>{' '}
                </svg>
              </div>
              <span className={classes.headingTitle}>{translate('Latest Product News')}</span>
            </div>

            <div style={{ width: '100%', textAlign: 'left' }}>
              <div style={{ width: 728, marginTop: 20, marginRight: 'auto', marginBottom: 10, marginLeft: 'auto' }}>
                {leaderboardBannerConfig && (
                  <BannerAd
                    formats={['leaderboard']}
                    placement="home_cluster"
                    bannerConfigOverride={leaderboardBannerConfig}
                  />
                )}

                {textLinkBannerConfigs?.length === 2 && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      width: '100%',
                      marginTop: 10,
                    }}
                  >
                    {textLinkBannerConfigs.map((textLinkBannerConfig, index) => (
                      <div key={index} style={{ width: '50%' }}>
                        <BannerAd
                          formats={['text']}
                          placement="home_cluster"
                          bannerConfigOverride={textLinkBannerConfig}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Grid>
    </div>
  )
}

export default HomeAdCluster

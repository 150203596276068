// @ts-nocheck
import { logAmplitudeEvent } from 'amplitude/amplitude'
import React, { useEffect, useState } from 'react'

const WRAPPER_EL_ID = 'os-vimeo-wrapper'
type PropTypes = {
  videoUrl: string
  trackingSource: string
  maxWidth?: number
  maxHeight?: number
}

/*
Caution: Discontinue the use of this component for new use cases.
This component is intended to be substituted with another component. 
It is retained in its current state because the bespoke proposal still relies on it.
Todo: update the bespoke video link then remove this component
*/

const VimeoPlayer: React.FC<PropTypes> = (props) => {
  const [vimeoLoaded, setVimeoLoaded] = useState<boolean>(() => !!window.Vimeo)
  const [vimeoIframeHTML, setVimeoIframeHTML] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (!window.Vimeo) {
      var script = document.createElement('script')
      script.src = 'https://player.vimeo.com/api/player.js'
      script.onload = () => setVimeoLoaded(true)
      document.head.appendChild(script)
    } else {
      setVimeoLoaded(true)
    }
  }, [])

  useEffect(() => {
    try {
      if (vimeoIframeHTML && vimeoLoaded) {
        // give it a second for iframe to render
        setTimeout(() => {
          var iframe = document.getElementById(WRAPPER_EL_ID)
          if (iframe) {
            var player = new window.Vimeo.Player(iframe)

            player.on('play', function () {
              logAmplitudeEvent('vimeo_video_player_interacted', {
                url: props.videoUrl,
                action: 'played',
                source: props.trackingSource,
              })
            })

            player.on('ended', function () {
              logAmplitudeEvent('vimeo_video_player_interacted', {
                url: props.videoUrl,
                action: 'finished',
                source: props.trackingSource,
              })
            })
          } else {
            console.error('Vimeo Player error: no DOM element found with ID: ' + WRAPPER_EL_ID)
          }
        }, 1000)
      }
    } catch (ex) {
      console.log('exception adding event listeners', ex)
    }
  }, [vimeoLoaded, vimeoIframeHTML])

  useEffect(() => {
    if (props.videoUrl) {
      fetch(
        `https://vimeo.com/api/oembed.json?url=${encodeURIComponent(props.videoUrl)}&maxwidth=${
          props.maxWidth
        }&maxheight=${props.maxHeight}`
      ).then((res) => {
        res.json().then((resBody) => {
          console.log('vimeo Res', resBody)
          setVimeoIframeHTML(resBody.html)
        })
      })
    }
  }, [props.videoUrl])

  return (
    <div>
      {vimeoIframeHTML && vimeoLoaded && (
        <div
          id={WRAPPER_EL_ID}
          dangerouslySetInnerHTML={{
            __html: vimeoIframeHTML,
          }}
        />
      )}
    </div>
  )
}
export default VimeoPlayer

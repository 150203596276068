import DuplicateIcon from '@material-ui/icons/FileCopyOutlined'
import { Button, DeleteButton, EditButton, useTranslate } from 'react-admin'

export const LYRA_SUPPORT_OPTIONS = [
  { id: true, name: 'Supported' },
  { id: false, name: 'Unsupported' },
]

export const CUSTOM_FIELD_PREFIX = 'custom_data_field_'
export const convertToLegacyCustomFields = (configs) => {
  return configs.map((config) => {
    let options = {}
    // such hacks to convert it to old customModuleDataFields
    if (config.softWarning != null) {
      options['softWarning'] = config.softWarning
    }
    if (config.formatter != null) {
      options['parse'] = config.formatter.parse
      options['format'] = config.formatter.format
    }
    if (config.selectOptions != null) {
      options['values'] = config.selectOptions
    }
    if (config.validate != null) {
      options['validate'] = config.validate
    }
    if (config.extra != null) {
      options = { ...options, ...config.extra }
    }

    const extraProps = Object.keys(options).length > 0 ? options : undefined
    return {
      source: config.source,
      label: config.label,
      component: config.component,
      endAdornment: config.unit,
      extra: extraProps,
      custom_field_prefix_source: `${CUSTOM_FIELD_PREFIX}${config.source}`,
      isStaffOnly: config.isStaffOnly,
      dependentInputParams: config.dependentInputParams,
      referenceInputParams: config.referenceInputParams,
    }
  })
}

const iconButtonStyle = { color: '#4d4d4d', justifyContent: 'flex-start', margin: 0, padding: 6 }
export const ActionsField = (props) => {
  return (
    <div style={{ minWidth: 110 }}>
      <EditButton useListStyle={true} style={iconButtonStyle} hideLabelAt={'xl'} {...props} />

      <Button
        label={'Duplicate'}
        redirect={false}
        hideLabelAt={'xl'}
        submitOnEnter={false}
        raised={true}
        style={iconButtonStyle}
        onClick={(event) =>
          props.duplicate({
            resource: props.resource,
            id: props.record.id,
          })
        }
      >
        <DuplicateIcon />
      </Button>
      <DeleteButton
        useListStyle={true}
        hideLabelAt={'xl'}
        style={iconButtonStyle}
        undoable={false}
        record={props.record}
        resource={props.resource}
        redirect={`/${props.resource}`}
      />
    </div>
  )
}

export const DuplicateButtonDatagridField = (props) => (
  <Button
    redirect={false}
    label={'Duplicate'}
    submitOnEnter={false}
    style={{ color: '#4d4d4d', width: 100, justifyContent: 'flex-start', margin: 0 }}
    onClick={(event) =>
      props.duplicate({
        resource: props.resource,
        id: props.record.id,
      })
    }
    {...props}
  >
    {<DuplicateIcon />}
  </Button>
)

export const FieldFromComponentReferenceOrData = ({ record, source }) => {
  const translate = useTranslate()
  if (record[source]) {
    return <span>{record[source]}</span>
  }

  if (record.data) {
    var data = JSON.parse(record.data)
    if (data && data[source]) {
      return <span>{data[source]}</span>
    }
  }

  return <span>{translate('Unknown')}</span>
}

import React from 'react'
import useTranslateParse from 'util/useTranslateParse'
import { CommissionsCreate, CommissionsEdit, CommissionsList } from './Commissions'

const Subtitle = () => {
  const translateParse = useTranslateParse()
  return (
    <div>
      {translateParse(
        'Create custom commissions that can be assigned to team members. To learn more about how to use this feature, <a>click here<a>.',
        {
          a: (label) => (
            <a
              href={'https://support.opensolar.com/hc/en-us/articles/4602423382799'}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none', whiteSpace: 'nowrap', color: 'rgb(24, 144, 255)' }}
            >
              {label}
            </a>
          ),
        }
      )}
    </div>
  )
}

export default {
  list: CommissionsList,
  edit: CommissionsEdit,
  create: CommissionsCreate,
  options: {
    create: {
      subtitle: <Subtitle />,
    },
    list: {
      subtitle: null,
    },
    edit: {
      subtitle: null,
    },
  },
}

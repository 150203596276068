import { ConditionFactory, SystemContext } from 'Designer/designRules/types'
import { BaseConditionDef } from '.'

export type SystemHasModulesDef = BaseConditionDef & {
  type: 'system-has-modules'
}

export const condition_systemHasModules: ConditionFactory<SystemHasModulesDef> = (def) => {
  return {
    getProjectFields: () => [],
    getStudioSignals: () => ['system-selected', 'panel-added', 'panel-removed'],
    check: (context: SystemContext) => {
      return context.system.moduleQuantity() > 0
    },
  }
}

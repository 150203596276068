import { makeStyles } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import ChipsInput from 'elements/input/ChipsInput'
import { Chip } from 'opensolar-ui'
import {
  filterByOrgShare,
  getOrgGroups,
  useOtherOrgIds,
  useProjectOwnerDetails,
} from 'projectSections/sections/info/orgSharing/util'
import { useTranslate } from 'ra-core'
import { FunctionComponent } from 'react'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { Theme } from 'types/themes'

export const useStyles = makeStyles<Theme, {}>(
  () => ({
    choiceWithChip: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
  }),
  { name: 'SharedEntityChipsInput' }
)

type WithChip = {
  chip?: string
}

export const SharedEntityChipsInput = <Item extends unknown>(props) => {
  const form = useForm()
  const translate = useTranslate()
  const orgId = useSelector(authSelectors.getOrgId) as number
  const ownerOrg = useProjectOwnerDetails(useSelector(orgSelectors.getOrg), form.getState().values.org_id)
  const sharedWith = form.getState().values.shared_with
  const otherOrgIds = useOtherOrgIds(orgId, ownerOrg?.org_id, sharedWith)

  if (sharedWith?.length > 0) {
    const newChoices = formatChoices(props.choices, orgId, ownerOrg, sharedWith, otherOrgIds)
    return (
      <ChipsInput
        {...props}
        optionText={(i) =>
          i
            ? i[props.optionText || 'title']
            : !!sharedWith?.length && <i style={{ color: '#aaa' }}>{translate('Unshared Item')}</i>
        }
        customItemRender={(optionText: string, optionValue: string, option: WithChip & Item) => {
          return <RenderItemWithChip text={optionText} value={optionValue} chip={option?.chip} />
        }}
        choices={newChoices}
      />
    )
  } else {
    return <ChipsInput {...props} />
  }
}

const formatChoices = (choices, orgId, ownerOrg, sharedWith, otherOrgIds) => {
  const groups = getOrgGroups(orgId, ownerOrg, sharedWith)
  const newChoices: any = []
  for (const choice of choices) {
    const groupId = filterByOrgShare(choice, orgId, otherOrgIds)
    const group = groups.find((x) => x.id === groupId)
    newChoices.push({ ...choice, chip: group?.name + (group?.chip ? ` - ${group?.chip}` : '') })
  }
  return newChoices
}
interface ItemWithChipProps {
  text?: string
  value?: any
  chip?: string
}

export const RenderItemWithChip: FunctionComponent<ItemWithChipProps> = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.choiceWithChip}>
      <span>{props.text}</span>
      {props.chip && (
        <Chip
          key={props.value}
          className="GroupedSelectHeadingChip"
          label={<strong>{props.chip}</strong>}
          size="small"
          variant="outlined"
          disabled={true}
        />
      )}
    </div>
  )
}

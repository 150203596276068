import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { Check, Delete } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { orgsActions } from 'ducks/orgs'
import { ListBreadCrumbs } from 'elements/BreadCrumbs'
import ButtonWithConfirmDialog from 'elements/button/ButtonWithConfirmDialog'
import EditOrImportButton from 'elements/button/EditOrImportButton'
import { List } from 'elements/react-admin/List'
import ListActions from 'elements/react-admin/ListActions'
import { Switch } from "opensolar-ui"
import useTranslateWithVariable from 'projectSections/hooks/useTranslateWithVariable'
import { useNotify, useTranslate } from 'ra-core'
import { useEffect, useState } from 'react'
import { BooleanField, Button, Datagrid, FunctionField, NotFound } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { ConnectedOrg } from 'types/orgs'
import { getQueryVariable } from 'util/query'
import { ConnectedOrgsBreadcumbs } from './breadcrumbs'
import { styles as makeStyles } from './styles'
import { PendingOrgConnection } from './types'
import { acceptConnection, deleteConnectionRequest, getPendingConnections, toggleConnection } from './util'

const PendingConnectionActions = ({ record }) => {
  const translate = useTranslate()
  const translateWithVariable = useTranslateWithVariable()
  const resource = 'connected_orgs'
  const history = useHistory()
  const notify = useNotify()
  const dispatch = useDispatch()

  return (
    <>
      <Button
        variant="contained"
        label="Accept"
        record={record}
        resource={resource}
        onClick={() => {
          acceptConnection(record)
            .then((res) => {
              dispatch(orgsActions.reloadOrg())
              history.push('/connected_orgs/' + res.data.id)
            })
            .catch((err) => {
              notify('Failed to accept connection. Please try again.', 'warning')
            })
        }}
      >
        <Check />
      </Button>
      <ButtonWithConfirmDialog
        variant="outlined"
        label={'Delete'}
        record={record}
        resource={resource}
        handleClick={() => {
          deleteConnectionRequest(record)
            .then((res) => {
              history.go(0)
            })
            .catch((err) => {
              notify('Failed to delete connection. Please try again.', 'warning')
              console.log(err)
            })
        }}
        dialogTitle={translateWithVariable('Delete connection request from %{org_name}', {
          org_name: record.org_from_name,
        })}
        dialogBody={translate('Are you sure you want to delete this item?')}
        confirmLabel={'Delete'}
        icon={<Delete />}
      />
    </>
  )
}

const BreadCrumbsWithPendingConnections = ({
  pendingList,
  hasList,
  resource,
  ...props
}: {
  pendingList: PendingOrgConnection[]
  hasList?: boolean
  detail?: string | null
  resource?: unknown
  options?: {}
}) => {
  const breadcrumbs = [...ConnectedOrgsBreadcumbs, { title: 'Connected Orgs' }]
  return (
    <>
      <ListBreadCrumbs links={breadcrumbs} />
      {pendingList.length > 0 ? <PendingConnectionsTable pendingList={pendingList} /> : null}
    </>
  )
}

const PendingConnectionsTable = ({ pendingList }: { pendingList: PendingOrgConnection[] }) => {
  const translate = useTranslate()
  const styles = makeStyles()
  return (
    <div className={styles.pendingContainer}>
      <h2 className={styles.pendingTitle}>{translate('Pending Connections')}</h2>
      <Table className={styles.table}>
        <TableHead className={styles.tableHeading}>
          <TableRow>
            <TableCell>{translate('Organisation')}</TableCell>
            <TableCell>{translate('Actions')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pendingList.map((item) => {
            return (
              <TableRow>
                <TableCell>{item.org_from_name}</TableCell>
                <TableCell>
                  <PendingConnectionActions record={item} />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  )
}

export const SharedEntitiesLink = (props) => {
  const { record, basePath, ...otherProps } = props
  if (!record?.is_other_active || !record?.is_other_enabled || !record?.is_active) return null
  return (
    <Link to={record ? `${basePath}/${record.id}/shared` : basePath}>
      <Button label={'View Shared Items'} variant={props.variant} color={props.color} size={props.size} />
    </Link>
  )
}

export const ConnectedOrgList = ({ accessRights: { allowEdit }, ...props }) => {
  const notify = useNotify()
  const styles = makeStyles()
  const history = useHistory()
  const translate = useTranslate()
  const orgId = useSelector(authSelectors.getOrgId)
  const isAdmin = useSelector(authSelectors.getIsAdmin)
  const [pendingConnects, setPendingConnects] = useState<PendingOrgConnection[]>([])

  const getAcceptConnectionId = getQueryVariable('accept_connection')

  useEffect(() => {
    getPendingConnections(orgId).then((res) => {
      setPendingConnects(res.data)
    })
  }, [])

  useEffect(() => {
    if (getAcceptConnectionId && pendingConnects.length > 0) {
      const acceptConnectionAsInt = parseInt(getAcceptConnectionId)
      const findItem = pendingConnects.find((x: any) => x.item_id === acceptConnectionAsInt)
      if (findItem) {
        acceptConnection(findItem)
          .then((res) => {
            history.push('/connected_orgs/' + res.data.id)
          })
          .catch((err) => {
            notify('Failed to accept connection. Please try again.', 'warning')
          })
      }
    }
  }, [getAcceptConnectionId, pendingConnects])

  const onDeleteClick = (record) => {
    logAmplitudeEvent('org_connection_deleted', { org_name: record.org_name })
  }

  if (!isAdmin) {
    return <NotFound />
  }
  return (
    <>
      <List
        basePath={undefined}
        id={undefined}
        {...props}
        perPage={20}
        actions={<ListActions hasArchived={false} createButtonLabel={'Connect An Org'} />}
        hasSearch={true}
        hasCreate={isAdmin}
        breadCrumbs={<BreadCrumbsWithPendingConnections pendingList={pendingConnects} />}
        showNoResultsPage={false}
      >
        <Datagrid className={styles.table}>
          <FunctionField
            source="org_name"
            label="Organisation"
            render={(record, source, rest) => (
              <div className={styles.pendingConnectionItem}>
                {record['org_name']}{' '}
                {!record['is_other_active'] ? (
                  <div className={styles.chip}>{translate('Awaiting Confirmation')}</div>
                ) : (
                  !record['is_other_enabled'] && <div className={styles.chip}>{translate('Connection Disabled')}</div>
                )}
              </div>
            )}
          />
          <FunctionField
            source={undefined}
            label="Enabled"
            render={(record) => <div>{allowEdit && <Toggle record={record as ConnectedOrg} />}</div>}
            sortable={false}
          />
          <BooleanField source="auto_share_projects" label="Auto-Share" sortable={false} />
          <EditOrImportButton
            sortable={false}
            source="actions"
            label="Actions"
            resource="connected_orgs"
            allowDuplicate={false}
            allowEdit={isAdmin}
            allowDelete={isAdmin}
            onDeleteClick={onDeleteClick}
            customAction={<SharedEntitiesLink />}
          />
        </Datagrid>
      </List>
    </>
  )
}

interface ToggleProps {
  record: ConnectedOrg
}
const Toggle = ({ record }: ToggleProps) => {
  const dispatch = useDispatch()
  const [enable, setEnable] = useState(record.is_active)

  const handleToggleChange = (event) => {
    const toggleValue = event.target.checked
    setEnable(toggleValue)
    dispatch(toggleConnection(toggleValue, record))
  }
  return <Switch key={record.id} name="toggle" onChange={handleToggleChange} checked={enable} />
}

import { MenuItem, Select, Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { solkunderLink } from 'constants/links'
import { authSelectors } from 'ducks/auth'
import WithPermissionsCheck from 'elements/WithPermissionsCheck'
import { useGetList, useTranslate } from 'ra-core'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

type PropTypes = {
  classes?: {
    button?: string
    icon?: string
    select?: string
    wrapper?: string
  }
}

const BigTooltip = withStyles((theme: any) => ({
  tooltip: {
    color: theme.greyDark2,
    fontSize: '12px',
    fontWeight: 400,
    backgroundColor: theme.greyLight1,
    padding: '6px 8px',
    borderRadius: '6px',
    boxShadow: theme.boxShadow.default,
    cursor: 'pointer',
    whiteSpace: 'pre-wrap',
  },
  arrow: {
    color: theme.greyLight1,
  },
}))(Tooltip)

export const LeadPurchaseSelector: FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const history = useHistory()

  const { ids: solkunderIds, data: solkunderData, total: solkunderTotal } = useGetList(
    'lead_capture_forms',
    { page: 1, perPage: 100 },
    { field: 'published_at', order: 'DESC' },
    { form_format: 3 }
  )
  const orgId = useSelector(authSelectors.getOrgId)

  const handleSolkunderClick = (key?: string) => {
    if (solkunderTotal === 0) {
      history.push({ pathname: '/lead_capture_forms' })
      logAmplitudeEvent('solkunder_used_inactive', { org_id: orgId })
      return
    }

    const data =
      solkunderData && solkunderIds ? (key ? solkunderData[key] : null) || solkunderData[solkunderIds[0]] : null
    if (!data) return

    const url = `${solkunderLink}?org_id=${orgId}&uuid=${data.uuid}`
    logAmplitudeEvent('solkunder_used_active', { org_id: orgId, uuid: data.uuid })
    window.open(url, '_blank', 'noreferrer')
  }

  return (
    <>
      {solkunderTotal === 0 ? (
        <WithPermissionsCheck permissionToCheck="lead_capture_forms" permissions={['allowCreate']} projectMode={false}>
          <span className={props.classes?.wrapper}>
            <a className={props.classes?.button} onClick={() => handleSolkunderClick()}>
              <span>{translate('Buy leads with Solkunder')}</span>
            </a>
            <BigTooltip
              title={translate(
                "Solkunder (powered by 3Quotes) is Europe's leading provider of verified solar and energy storage leads."
              )}
              arrow
              onOpen={() => {
                logAmplitudeEvent('solkunder_on_info_icon', { org_id: orgId })
              }}
            >
              <span>
                <InfoOutlinedIcon className={props.classes?.icon} />
              </span>
            </BigTooltip>
          </span>
        </WithPermissionsCheck>
      ) : (
        <WithPermissionsCheck permissionToCheck="project" permissions={['allowCreate']} projectMode={false}>
          {solkunderData && solkunderTotal !== 1 ? (
            <span>
              <Select
                className={props.classes?.select}
                value="button"
                onChange={(event) => handleSolkunderClick(event.target.value as string)}
              >
                <MenuItem key="button" value="button" disabled>
                  {translate('Buy leads with Solkunder')}
                </MenuItem>
                {Object.keys(solkunderData).map((key: string) => {
                  const data = solkunderData[key]
                  return (
                    <MenuItem key={key} value={key}>
                      {data.title}
                    </MenuItem>
                  )
                })}
              </Select>
            </span>
          ) : (
            <span>
              <a className={props.classes?.button} onClick={() => handleSolkunderClick()}>
                <span>{translate('Buy leads with Solkunder')}</span>
              </a>
            </span>
          )}
        </WithPermissionsCheck>
      )}
    </>
  )
}

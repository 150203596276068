import { HelpLink } from 'elements/link/HelpLink'
import React from 'react'
import { useTranslate } from 'react-admin'
import { UtilityTariffCreate, UtilityTariffEdit, UtilityTariffList } from './UtilityTariff'

const Subtitle = () => {
  const translate = useTranslate()
  return (
    <span>
      {translate(
        "Search our extensive database of utilities and rates, and include rates on your 'Favorites' list. If there is a utility or rate missing from the database, please email the rate sheet to"
      )}{' '}
      <a href="mailto:support@opensolar.com">support@opensolar.com</a>.
      <HelpLink href="https://support.opensolar.com/hc/en-us/articles/4407439719833" />
    </span>
  )
}

export default {
  list: UtilityTariffList,
  edit: UtilityTariffEdit,
  create: UtilityTariffCreate,
  options: {
    create: {
      title: 'Create Utility Tariff',
      subtitle: null,
      breadCrumb: 'Create Utility Tariff',
    },
    list: {
      title: 'Utility Tariffs Database',
      subtitle: <Subtitle />,
      breadCrumb: 'Utility Tariffs',
    },
    edit: {
      title: 'Edit Utility Tariff',
      subtitle: null,
      breadCrumb: 'Edit Utility Tariff',
    },
  },
}

export const customTariffProposed = { edit: UtilityTariffEdit }

export const customTariffCurrent = { edit: UtilityTariffEdit }

import {
  FormControlLabel,
  Grid,
  Button as MaterialButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orderSelectors } from 'ducks/orderComponents'
import Button from 'elements/button/Button'
import { isEmpty } from 'lodash'
import { useGetMany, useNotify } from 'ra-core'
import { memo, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import AddressAutocomplete from 'resources/projects/elements/AddressAutocomplete'
import { RootState } from 'types/state'
import { getAddressValue } from '../../helper'
import { AddressType, SavedAddressType } from '../../types'
import { CheckoutPresenterContext, ShippingDetailContext } from './CheckoutProvider'
import { useSolarJuiceStyles } from './style'
import { ContactAddressType, ShippingDetailType } from './type'

type Props = {
  showChangeShippingAddress: boolean
  onChangeShowShippingAddress: (value: boolean) => void
}

const ShippingAddress = ({ showChangeShippingAddress, onChangeShowShippingAddress }: Props) => {
  const notify = useNotify()
  const solarJuiceClasses = useSolarJuiceStyles()
  const lineItems = useSelector(orderSelectors.getOrderableLineItems)
  const shippingDetail: ShippingDetailType = useContext(ShippingDetailContext)
  const checkoutPresenter = useContext(CheckoutPresenterContext)
  const [selectedSavedAddress, setSelectedSavedAddress] = useState<string | undefined>(
    getAddressValue(shippingDetail.contactName, shippingDetail.contactAddress)
  )
  const [savedAddresses, setSavedAddresses] = useState<SavedAddressType[]>([
    {
      contactName: shippingDetail.contactName,
      contactNumber: shippingDetail.contactNumber,
      contactAddress: shippingDetail.contactAddress,
      value: getAddressValue(shippingDetail.contactName, shippingDetail.contactAddress),
    },
  ])
  const [selectedShippingAddress, setSelectedShippingAddress] = useState<AddressType>(savedAddresses[0])
  const [customAddress, setCustomAddress] = useState<AddressType>()
  const [deliveryInstructions, setDeliveryInstructions] = useState<string>(shippingDetail.deliveryInstructions)
  const projectIds = orderSelectors.getProjectListByLineItems(lineItems)
  const { data: projectsData } = useGetMany('projects', projectIds)
  const apiKeyGoogle = useSelector((state: RootState) =>
    state.auth && state.auth.api_key_google ? state.auth.api_key_google : ''
  )

  useEffect(() => {
    if (!isEmpty(projectsData)) {
      const addresses = savedAddresses
      const projectAddresses = projectsData.map((item) => {
        const contactName = !isEmpty(item?.contacts_data) ? item.contacts_data[0].display : item?.org_name
        const contactNumber = !isEmpty(item?.contacts_data) ? item.contacts_data[0].phone : shippingDetail.contactNumber
        const contactAddress: ContactAddressType = {
          address: item?.address || '',
          locality: item?.locality || '',
          state: item?.state || '',
          zip: item?.zip || '',
          countryName: shippingDetail.contactAddress.countryName,
          countryIso2: shippingDetail.contactAddress.countryIso2,
        }
        return {
          contactName,
          contactNumber,
          contactAddress,
          value: getAddressValue(contactName, contactAddress),
        }
      })
      setSavedAddresses([...addresses, ...projectAddresses])
    }
  }, [projectsData])

  const handleClickChangeShippingAddress = () => {
    if (showChangeShippingAddress) {
      setCustomAddress(undefined)
      setSelectedSavedAddress(
        getAddressValue(selectedShippingAddress.contactName, selectedShippingAddress.contactAddress)
      )
    }
    onChangeShowShippingAddress(!showChangeShippingAddress)
  }

  const handleSaveDeliveryInstructions = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeliveryInstructions(event.target.value)
    checkoutPresenter?.updateShippingDetail({
      ...shippingDetail,
      deliveryInstructions: event.target.value,
    })
  }

  const handleSaveChanges = () => {
    notify('Shipping address has been successfully changed.', 'success')
    const address: any = customAddress
      ? { ...customAddress }
      : { ...savedAddresses.find((item) => item.value === selectedSavedAddress) }
    if (address) {
      logAmplitudeEvent('hardware_solar_outlet_pricing_option_updated', {
        distributor: 'outlet',
        source: 'solar_juice_checkout',
        address: address,
      })
      delete address?.value
      setSelectedShippingAddress(address)
      checkoutPresenter?.updateShippingDetail({
        ...shippingDetail,
        ...address,
      })
    }
    onChangeShowShippingAddress(false)
  }

  const onPlaceSelected = (placeAsFields) => {
    setSelectedSavedAddress(undefined)
    setCustomAddress({
      contactName: shippingDetail.contactName,
      contactNumber: shippingDetail.contactNumber,
      contactAddress: {
        address: placeAsFields?.address || '',
        locality: placeAsFields?.locality || '',
        state: placeAsFields?.state || '',
        zip: placeAsFields?.zip || '',
        countryName: shippingDetail.contactAddress.countryName,
        countryIso2: shippingDetail.contactAddress.countryIso2,
      },
    })
  }

  const handleSelectSavedAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomAddress(undefined)
    setSelectedSavedAddress(event.target.value)
  }

  return (
    <Grid container direction="row" justify="space-between" style={{ padding: '20px 0px' }}>
      <Grid item xs={3}>
        <Typography variant="h6" className={`${solarJuiceClasses.font18} ${solarJuiceClasses.fontWeightMedium}`}>
          <span style={{ marginRight: '20px' }}>1</span>
          <span>Shipping address</span>
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Grid container direction="row">
          {!showChangeShippingAddress && selectedShippingAddress && (
            <Grid item xs={12}>
              <Typography
                className={solarJuiceClasses.font16}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  letterSpacing: '0.32px',
                }}
              >
                <span>{selectedShippingAddress.contactName}</span>
                <span>{selectedShippingAddress.contactAddress.address}</span>
                <span>{`${selectedShippingAddress.contactAddress.locality} ${selectedShippingAddress.contactAddress.state} ${selectedShippingAddress.contactAddress.zip} ${selectedShippingAddress.contactAddress.countryIso2}`}</span>
              </Typography>
            </Grid>
          )}
          {showChangeShippingAddress && (
            <Grid item xs={9} style={{ marginTop: '16px' }}>
              <Typography variant="h6" className={`${solarJuiceClasses.font16} ${solarJuiceClasses.fontWeightMedium}`}>
                <span>Select custom address</span>
              </Typography>
              <AddressAutocomplete
                onPlaceSelected={onPlaceSelected}
                api_key_google={apiKeyGoogle}
                types={['address']}
              />
              {savedAddresses && savedAddresses.length > 1 && (
                <>
                  <Typography
                    variant="h6"
                    className={`${solarJuiceClasses.font16} ${solarJuiceClasses.fontWeightMedium}`}
                    style={{ marginTop: '16px' }}
                  >
                    <span>Saved addresses</span>
                  </Typography>
                  <RadioGroup
                    name="savedAddresses"
                    style={{ marginTop: '4px' }}
                    value={selectedSavedAddress}
                    onChange={handleSelectSavedAddress}
                  >
                    {savedAddresses.map((item: SavedAddressType, index: number) => (
                      <FormControlLabel
                        key={`saved-address-${index}`}
                        value={item.value}
                        control={
                          <Radio
                            color="secondary"
                            style={{
                              color: selectedSavedAddress === item.value ? '#4272DD' : '#B4B4B4',
                            }}
                          />
                        }
                        label={`${item.contactName} ${item.contactAddress.address} ${item.contactAddress.locality} ${item.contactAddress.state} ${item.contactAddress.zip} ${item.contactAddress.countryIso2}`}
                      />
                    ))}
                  </RadioGroup>
                </>
              )}
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container direction="column">
              <Grid item xs={9} style={{ marginTop: '16px' }}>
                <Typography
                  variant="h6"
                  className={`${solarJuiceClasses.font16} ${solarJuiceClasses.fontWeightMedium}`}
                >
                  <span>Delivery Instructions</span>
                </Typography>
                <TextField
                  variant="outlined"
                  placeholder="Enter delivery instructions here"
                  multiline
                  rows={4}
                  rowsMax={4}
                  style={{ width: '100%', marginTop: '10px' }}
                  value={deliveryInstructions}
                  onChange={handleSaveDeliveryInstructions}
                />
              </Grid>
              {showChangeShippingAddress && (
                <Grid item xs={3} style={{ marginTop: '16px' }}>
                  <Button color="default" fullWidth onClick={handleSaveChanges}>
                    Save changes
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={1}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <MaterialButton variant="text" onClick={handleClickChangeShippingAddress} style={{ height: '22px' }}>
          <Typography
            variant="h6"
            className={solarJuiceClasses.font14}
            style={{
              display: 'flex',
              flexDirection: 'row',
              color: '#113B98',
            }}
          >
            <span>{showChangeShippingAddress ? 'Close' : 'Change'}</span>
            {showChangeShippingAddress && (
              <CloseOutlined style={{ marginLeft: '8px', fontSize: '24px', color: '#1C1B1F' }} />
            )}
          </Typography>
        </MaterialButton>
      </Grid>
    </Grid>
  )
}

export default memo(ShippingAddress)

import { Accordion, AccordionDetails, AccordionSummary, Grid, Link, makeStyles } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import LaunchIcon from '@material-ui/icons/LaunchOutlined'
import { changeOrderHelpCenterLink } from 'constants/links'
import { authSelectors } from 'ducks/auth'
import Alert from 'elements/Alert'
import TinyBox from 'elements/TinyBox'
import { Button, Chip, IconButton } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import restClient from 'restClient'
import { ProjectType } from 'types/projects'
import { deleteInitiatedChangeOrder, getChangeOrders, getInitiatedChangeOrder } from 'util/changeOrder'
import { systemNameOrDefaultWithSize } from '../../../../util/misc'
import { ChangeOrderForm } from './ChangeOrderForm'
const API_URL = window.API_ROOT + '/api'

export const useStyles = makeStyles(() => ({
  subLabel: {
    fontSize: '10px',
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 300,
    marginTop: 8,
  },
  dateInput: { width: '100%' },
  accordionRoot: {
    width: '100%',
  },
  verticalAlign: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paddingRight8: {
    paddingRight: '8px',
  },
  checkedBullet: {
    '&::before': { content: '"✓"' },
  },
  alert: {
    width: '100%',
    height: '40px',
    backgroundColor: '#FFFFFF',
    padding: '0px 16px 0px 16px',
    borderRadius: '8px',
    border: '1px solid #ECECED',
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    color: '#FFFFFF',
    height: '36px',
    padding: '0px 14px',
    gap: '8px',
    borderRadius: '6px',
    backgroundColor: '#4272DD',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: '#4272DD',
    },
  },
  noUnderline: {
    textDecoration: 'none',
  },
}))

const ChangeOrderContainer = () => {
  const restClientInstance = restClient(API_URL)
  const translate = useTranslate()
  const classes = useStyles()
  const orgId = useSelector(authSelectors.getOrgId)
  const form = useForm()
  const formState = useFormState()
  const project = formState.values
  const systems = project.systems
  const changeOrders = getChangeOrders(project as ProjectType)
  const initiatedChangeOrder = getInitiatedChangeOrder(project as ProjectType)
  const learnMoreURL = changeOrderHelpCenterLink
  const isFormDirty = form.mutators.getFormDirtyFields()?.length > 0

  const initiateChangeOrder = () => {
    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${orgId}/projects/${project.id}/initiate_change_order/`,
    })
      .then((response: any) => {
        form.mutators.updateFieldSilently('change_orders', [...changeOrders, response.data])
        form.mutators.updateFieldSilently('initiated_change_order', response.data)
      })
      .catch((error: any) => {
        console.error('Error initiating change order', error)
      })
  }

  return (
    <>
      <div className={classes.accordionRoot}>
        {changeOrders.map((value, index) => {
          const system = systems?.find((e) => e.id === value.system_sold)
          let systemName = systemNameOrDefaultWithSize(system, undefined)
          if (systemName === 'Unknown System') {
            systemName = ''
          }
          const disableFields = changeOrders?.[index]?.is_complete && changeOrders?.length - index - 1 > 0
          return (
            <Accordion key={value.id} defaultExpanded={!value.is_complete}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className={classes.verticalAlign}>
                  <h3 className={classes.paddingRight8}>
                    {translate('Change Order #%{index}: %{systemName}', { index: index + 1, systemName })}
                  </h3>
                  {value.is_complete && <Chip label={translate('Complete')} size="small" />}
                </div>
                <IconButton
                  style={{ marginLeft: 'auto', marginRight: '0px' }}
                  onClick={() => deleteInitiatedChangeOrder(value.id, orgId!, project.id, restClientInstance, form)}
                  disabled={disableFields}
                >
                  <Delete />
                </IconButton>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1}>
                  <Grid item md={12}>
                    <Alert severity="info" styles={{ width: '100%' }}>
                      <div>
                        <b>{translate('Systems eligible for Change Order must meet the below criteria.')}</b>
                        <ul style={{ listStyleType: 'none' }}>
                          <li className={classes.checkedBullet}>{translate('Not previously sold')}</li>
                          <li className={classes.checkedBullet}>{translate('Visible to customer')}</li>
                          <li className={classes.checkedBullet}>
                            {translate('Offers a %{paymentOptionName} payment option', {
                              paymentOptionName: value.payment_option_title ?? '',
                            })}
                          </li>
                        </ul>
                      </div>
                    </Alert>
                  </Grid>
                  <Grid item>
                    <NavLink className={classes.noUnderline} to={`/projects/${project.id}/design`}>
                      <Button className={classes.button} variant="contained" size="small" startIcon={<LaunchIcon />}>
                        {translate('Review Systems in Design Page')}
                      </Button>
                    </NavLink>
                  </Grid>
                  <Grid item md={12}>
                    <ChangeOrderForm orgId={orgId} changeOrderIndex={index} disableFields={disableFields} />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          )
        })}
      </div>
      {!initiatedChangeOrder && (
        <TinyBox>
          <Button variant="outlined" onClick={initiateChangeOrder} disabled={isFormDirty}>
            {translate('Initiate Change Order')}
          </Button>
          <p className={classes.subLabel}>
            {translate('Initiate a change order request to manage updates in your accepted customer proposal.')}
            <Link target="_blank" rel="noreferrer" href={learnMoreURL}>
              {translate('Learn More')}
            </Link>
          </p>
        </TinyBox>
      )}
    </>
  )
}

export default ChangeOrderContainer

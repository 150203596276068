import { SystemContext } from 'Designer/designRules/types'
import { EffectFactory } from '.'

export type SetSystemPropDef = {
  type: 'set-system-prop'
  prop: string
  value: unknown
}

export const effect_setSystemProp: EffectFactory<SetSystemPropDef> = (def) => {
  return {
    execute: async (context: SystemContext) => {
      window.editor.execute(new window.SetValueCommand(context.system, def.prop, def.value))
    },
  }
}

import { FormControl, InputBase, makeStyles, Select, Tooltip } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDownOutlined'
import React from 'react'

type PropTypes = {
  myeStyles: any
  onChange: Function
  menuItems: any[]
  selectedValue: any
  selectedPaymentOption?: any
  tooltipLabel: string
  expandedIcon?: boolean
  wrapSelectedText?: boolean
  onOpen?: (e: Object) => void
}

const useStyles = makeStyles<
  any,
  { wrapSelectedText?: boolean; textColor: string; highlightColor: string; highlightColorOnHover: string }
>(() => ({
  root: ({ wrapSelectedText }) => ({
    whiteSpace: wrapSelectedText ? 'normal' : 'nowrap',
    overflow: wrapSelectedText ? 'visible' : 'hidden',
    position: 'relative',
  }),
  icon: ({ textColor }) => ({
    position: 'absolute',
    height: '100%',
    top: '0px',
    right: '0px',
    borderColor: textColor,
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
    padding: '0px',
  }),
  iconOpen: {
    borderColor: 'transparent',
  },
  menuItem: ({ textColor, highlightColor, highlightColorOnHover }) => ({
    '&$selected': {
      color: textColor,
      background: highlightColor,
      '&:hover': {
        background: highlightColorOnHover,
      },
    },
  }),
  selected: {},
}))

const useInputClasses = makeStyles<any, { textColor: string; descriptionToShow?: string }>(() => ({
  root: ({ textColor }) => ({
    'label + &': {},
    '& svg': {
      padding: '0 0px',
      borderLeft: `1px solid ${textColor}`,
      color: textColor,
    },
    cursor: 'pointer',
    '& .MuiSelect-select.MuiSelect-select': {
      paddingRight: 40,
    },
  }),

  input: ({ textColor, descriptionToShow }) => ({
    textAlign: 'left',
    padding: descriptionToShow ? '8px 24px 5px 12px' : '13px 24px 13px 12px',
    color: textColor,
    fontSize: 16,
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  }),
}))

const useFormControlClasses = makeStyles<any, { highlightColor: string; highlightColorOnHover: string }>(() => ({
  root: ({ highlightColor, highlightColorOnHover }) => ({
    backgroundColor: highlightColor,
    borderRadius: 4,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: highlightColorOnHover,
    },
    boxShadow: 'rgb(0 0 0 / 20%) 3px 3px 3px',
    overflowX: 'hidden',
    overflowY: 'hidden',
    maxWidth: '240px',
  }),
}))

export default function DropDownComponent(props: PropTypes) {
  const { myeStyles, onChange, menuItems, selectedValue, tooltipLabel, onOpen } = props
  const highlightColor: string = myeStyles.highlightColor
  const highlightColorOnHover: string = myeStyles.highlightColorInteraction
  const textColor: string = myeStyles.textColorOnHighlight
  const descriptionToShow =
    props.selectedPaymentOption?.integration === 'brighte'
      ? `$${Math.round(props.selectedPaymentOption.regular_payment)} per fortnight`
      : undefined

  const selectClasses = useStyles({
    wrapSelectedText: props.wrapSelectedText,
    textColor,
    highlightColor,
    highlightColorOnHover,
  })

  const inputClasses = useInputClasses({ textColor, descriptionToShow })

  const formControlClasses = useFormControlClasses({ highlightColor, highlightColorOnHover })

  const handleChange = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    onChange(e.target.value)
  }

  const renderIconComponent = (iconProps: any) => {
    if (props.menuItems?.length === 1) return null
    return props.expandedIcon ? (
      <ArrowDropDownIcon fontSize="large" {...iconProps} />
    ) : (
      <ArrowDropDownIcon {...iconProps} />
    )
  }

  const renderValue = () => {
    if (!descriptionToShow) return props.selectedPaymentOption?.title
    else
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>{props.selectedPaymentOption?.title}</span>
          <span style={{ fontSize: '10px', marginTop: '-5px' }}>{descriptionToShow}</span>
        </div>
      )
  }

  return (
    <FormControl classes={formControlClasses} style={{ width: '100%' }}>
      <Tooltip placement="right" title={tooltipLabel} enterDelay={300}>
        <Select
          MenuProps={{
            getContentAnchorEl: null,
            disableScrollLock: true,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
          classes={{ root: selectClasses.root, icon: selectClasses.icon, iconOpen: selectClasses.iconOpen }}
          value={selectedValue}
          onChange={handleChange}
          onOpen={onOpen}
          input={<InputBase classes={inputClasses} />}
          IconComponent={(iconProps) => renderIconComponent(iconProps)}
          renderValue={props.selectedPaymentOption ? renderValue : undefined}
          native={false}
        >
          {menuItems.map((item) => {
            return React.cloneElement(item, {
              classes: { root: selectClasses.menuItem, selected: selectClasses.selected },
            })
          })}
        </Select>
      </Tooltip>
    </FormControl>
  )
}

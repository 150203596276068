import { Link, makeStyles } from '@material-ui/core'
import { ArrowUpRightSquareOutlineIcon, PaperClipIcon } from 'opensolar-ui'
import { ComplianceFormGroupType } from 'projectSections/sections/info/permitsAndServices/types'
import { useTranslate } from 'ra-core'
import { Theme } from 'types/themes'
import { COLOR_PALETTE, getProjectSectionStyles } from '../styles'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: '100%',
    marginBottom: 20,
  },
  darkGreyColor: {
    color: COLOR_PALETTE.darkGrey,
  },
  marginLeft: {
    marginLeft: 8,
  },
  docWrapper: {
    margin: '10px 0',
    marginRight: 50,

    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  itemWrapper: {
    flexWrap: 'wrap',
    maxHeight: 150,
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'unset',
    },
  },
  link: {
    textDecoration: 'none',
  },
}))

interface PropTypes {
  item: ComplianceFormGroupType
  filterByGeo: (items) => any[]
  countFiles: (file_tag) => number
}
const FormDisplay: React.FC<PropTypes> = ({ item, filterByGeo, countFiles }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const sectionStyles = getProjectSectionStyles()

  return (
    <div className={classes.wrapper}>
      <p className={classes.darkGreyColor}>{translate(item.heading)}</p>
      <div className={`${sectionStyles.column} ${classes.itemWrapper}`}>
        {filterByGeo(item.items).map((doc) => (
          <div className={classes.docWrapper}>
            {doc.url && (
              <Link
                className={`${sectionStyles.row} ${classes.link}`}
                href={doc.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {doc.icon === 'launch' && <ArrowUpRightSquareOutlineIcon color={COLOR_PALETTE.iconColor} />}
                <span className={classes.marginLeft}>{translate(doc.title)}</span>
              </Link>
            )}
            {!doc.url && doc.file_tag && (
              <div className={sectionStyles.row}>
                {doc.icon === 'file' && <PaperClipIcon color={COLOR_PALETTE.iconColor} />}
                <span className={classes.marginLeft}>{`${translate(doc.title)} (${countFiles(doc.file_tag)})`}</span>
              </div>
            )}
            {!doc.url && !doc.file_tag && <span>{translate(doc.title)}</span>}
          </div>
        ))}
      </div>
    </div>
  )
}
export default FormDisplay

import { OrderingQuoteList } from './OrderingQuotes'

export default {
  list: OrderingQuoteList,
  options: {
    list: {
      title: 'Quotes List',
      breadCrumb: 'Quotes List',
    },
  },
}

import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  projectErrorSelector,
  ProjectErrorType,
  ProjErrorCategory,
  ProjErrorSource,
  SeverityType,
} from '../../reducer/project/projectErrorsReducer'

export type ProjErrorFilter = (error: ProjectErrorType) => boolean

export type ProjErrorFilters = {
  sources?: ProjErrorSource[]
  categories?: ProjErrorCategory[]
  severities?: SeverityType[]
  keys?: string[]
  systemIds?: string[]
  componentIds?: string[]
  fields?: string[]
  filter?: ProjErrorFilter
}

export function rawFiltersProjectErrors(errors: ProjectErrorType[], filters: ProjErrorFilters[]): ProjectErrorType[] {
  let filtered = errors.filter((e) => !!filters.find((f) => matchFilterError(e, f)))
  const suppressed = filtered.reduce<string[]>(
    (ret, error) => (error.suppresses ? ret.concat(error.suppresses) : ret),
    []
  )
  return filtered.filter((e) => !suppressed.includes(e.key))
}

export function matchFilterError(error: ProjectErrorType, filter: ProjErrorFilters): boolean {
  if (filter.sources) {
    if (!filter.sources.includes(error.source)) return false
  }
  if (filter.severities) {
    if (!filter.severities.includes(error.severity)) return false
  }
  if (filter.keys) {
    if (!filter.keys.includes(error.key)) return false
  }
  if (filter.systemIds) {
    if (!error.systemId || !filter.systemIds.includes(error.systemId)) return false
  }
  if (filter.componentIds) {
    if (!error.componentId || !filter.componentIds.includes(error.componentId)) return false
  }
  if (filter.fields) {
    if (!error.field || !filter.fields.includes(error.field)) return false
  }
  if (filter.categories) {
    const cats = filter.categories
    if (!error.category) {
      return false
    } else if (typeof error.category === 'string') {
      if (!cats.includes(error.category)) return false
    } else {
      if (!error.category.find((cat) => cats.includes(cat))) return false
    }
  }
  if (filter.filter) {
    if (!filter.filter(error)) return false
  }
  return true
}

export function useFilterProjectErrors(filters: ProjErrorFilters[]): ProjectErrorType[] {
  const categories: string = filters.reduce(
    (value: string, filter: ProjErrorFilters) => value + filter.categories?.join(','),
    ''
  )
  const keys: string = filters.reduce((value: string, filter: ProjErrorFilters) => value + filter.keys?.join(','), '')
  const severities: string = filters.reduce(
    (value: string, filter: ProjErrorFilters) => value + filter.severities?.join(','),
    ''
  )
  const sources: string = filters.reduce(
    (value: string, filter: ProjErrorFilters) => value + filter.sources?.join(','),
    ''
  )
  const systemIds: string = filters.reduce(
    (value: string, filter: ProjErrorFilters) => value + filter.systemIds?.join(','),
    ''
  )
  const componentIds: string = filters.reduce(
    (value: string, filter: ProjErrorFilters) => value + filter.componentIds?.join(','),
    ''
  )
  const fields: string = filters.reduce(
    (value: string, filter: ProjErrorFilters) => value + filter.fields?.join(','),
    ''
  )
  const filterFuncs = filters.reduce(
    (value: (ProjErrorFilter | undefined)[], filter: ProjErrorFilters) => value.concat(filter.filter),
    []
  )
  const filterFuncsKey = useMemo(() => {
    return Math.random()
  }, filterFuncs)

  const errors = useSelector(projectErrorSelector.getAllErrors)
  return useMemo(() => rawFiltersProjectErrors(errors, filters), [
    errors,
    errors.length,
    categories,
    keys,
    severities,
    sources,
    systemIds,
    componentIds,
    fields,
    filterFuncsKey,
  ])
}

import { Card } from '@material-ui/core'
import Alert from 'elements/Alert'
import { useUxVersion } from 'hooks/useUxVersion'
import { Button } from 'opensolar-ui'
import { useTranslate } from 'react-admin'
import { useHistory } from 'react-router-dom'

const DesignPageNotAvailable = ({ message, projectId }) => {
  const translate = useTranslate()
  const uxVersion = useUxVersion()
  const isOnLegacyVersion = uxVersion < 3
  const history = useHistory()
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        flexDirection: 'column',
        display: 'flex',
        WebkitFlexDirection: 'column',
        background: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1,
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Card style={{ width: '70%', maxWidth: 500, padding: 20 }}>
          <h2>{translate('Error')}</h2>
          <div>
            <Alert severity="error" options={{ style: { marginBottom: 15 } }}>
              {translate(message)}
            </Alert>
            <Button
              style={{ float: 'right' }}
              variant="contained"
              onClick={() => {
                history.push('/projects/' + projectId + (isOnLegacyVersion ? '/info' : '/manage'))
              }}
            >
              <span>{translate('Edit Address')}</span>
            </Button>
          </div>
        </Card>
      </div>
    </div>
  )
}

export default DesignPageNotAvailable

import { authSelectors } from 'ducks/auth'
import { openOrderCart, orderSelectors } from 'ducks/orderComponents'
import type LineItemType from 'pages/ordering/OrderLineItem'
import useProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/useProjectOrderPresenter'
import React, { useCallback } from 'react'
import { useForm } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import ProjectHelper from 'resources/projects/ProjectHelper'
import { ProjectType } from 'types/projects'
import { DialogHelper } from 'util/misc'
import SectionButtonCTA from '../ctaAccordion/SectionButtonCTA'

type PropTypes = {
  // isMainCTA?: boolean
  disabled?: boolean
}

const SolarOutletCTA: React.FunctionComponent<PropTypes> = (props) => {
  const orgId = useSelector(authSelectors.getOrgId)
  const orderingPresenter = useProjectOrderPresenter()
  const orderProjects = useSelector(orderSelectors.getOrderProjectIds)

  const form = useForm()
  const record = form.getState().values as ProjectType
  const openCart = useCallback(() => {
    dispatch(openOrderCart(true))
    DialogHelper.afterOpen()
  }, [])

  const dispatch = useDispatch()

  const addOrderItemsToCart = async (
    lineItems: LineItemType[] | undefined,
    projectId: number,
    projectAddress: string | null
  ) => {
    if (!lineItems || !orgId) {
      return
    }

    if (!orderProjects.includes(projectId)) {
      openCart()
      return
    }

    await orderingPresenter.addLineItems(lineItems)
    openCart()
  }

  const handleClick = () => {
    const soldSystemData = ProjectHelper.getSoldSystemData(record, window.editor.sceneAsJSON())

    if (soldSystemData) {
      addOrderItemsToCart(orderingPresenter.getLineItemsFromSystem(soldSystemData, 'outlet'), record.id, record.address)
    }
  }

  return <SectionButtonCTA handleClick={handleClick} disabled={props.disabled} label={'Order with Solar Outlet'} />
}
export default SolarOutletCTA

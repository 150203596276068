import React from 'react'

const Disclaimer = ({ content }) => (
  <div
    className="small"
    dangerouslySetInnerHTML={{
      __html: content,
    }}
  />
)

export default Disclaimer

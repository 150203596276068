import { FormControl, MenuItem, Select, Tooltip, Typography } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import React, { useCallback } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  timeFilter: {
    display: 'flex',
    alignItems: 'center',
  },
  selectInput: { marginLeft: 10, marginRight: 5 },
  text: {
    color: theme.greyMid1,
  },
}))

const InventoryFilterSelect = ({
  options,
  field,
  value,
  label,
  onUpdate,
  disabled,
}: {
  field: string
  label: string
  options: { label: string; value: string | number }[]
  value: unknown
  onUpdate(args: { key: string; value: unknown }): void
  disabled: boolean
}) => {
  const classes = useStyles()
  const handleUpdate = useCallback(
    (e) => {
      onUpdate({ key: field, value: e.target.value })
      logAmplitudeEvent('generic_filter_interacted', {
        action: `inventory_dashboard_filter_${field}_updated`,
        value: e.target.value,
      })
    },
    [field, onUpdate]
  )

  return (
    <div className={classes.timeFilter}>
      <Typography variant="body2" className={classes.text}>
        {label}
      </Typography>
      {disabled ? (
        <Tooltip title={'Filters unavailable during search'}>
          <FormControl variant="outlined" size="small" className={classes.selectInput}>
            <Select value={value} onChange={handleUpdate} displayEmpty={true} disabled={disabled}>
              {options.map((option) => {
                return (
                  <MenuItem key={option.value} value={option.value}>
                    <Typography variant="body2">{option.label}</Typography>
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Tooltip>
      ) : (
        <FormControl variant="outlined" size="small" className={classes.selectInput}>
          <Select value={value} onChange={handleUpdate} displayEmpty={true} disabled={disabled}>
            {options.map((option) => {
              return (
                <MenuItem key={option.value} value={option.value}>
                  <Typography variant="body2">{option.label}</Typography>
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      )}
    </div>
  )
}

export default InventoryFilterSelect

import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { orgSelectors } from 'ducks/orgs'
import { useGetIsCashFlowVisible } from 'pages/cashFlow/utils'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { useGetProjectHasCashFlow } from '../cashFlowTransactions/utils'
import CashFlowEventTable from './CashFlowEventTable'
import { useGetCategorizedEvents } from './utils'

const useStyles = makeStyles((theme) => ({
  content: {
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  headerWrapper: {
    width: '100%',
    fontSize: '18px',
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 16,
  },
  buttonWrapper: {
    margin: '10px 5px',
    [theme.breakpoints.between('sm', 'sm')]: {
      margin: 2,
    },
  },

  iconTitleWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  headingTitle: {
    margin: '0 10px',
  },
  descriptionWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  updateStageTextWrapper: {
    minWidth: '200px',
  },
  updateStageButtonWrapper: {
    marginLeft: '20px',
  },
}))

const CashFlowEvents: React.FunctionComponent = () => {
  const cashFlowIsVisible = useGetIsCashFlowVisible()
  const classes = useStyles()
  const translate = useTranslate()
  const project = useFormState()?.values
  const hasCashFlowOnThisProject = useGetProjectHasCashFlow()

  const { events, isFetching } = useGetCategorizedEvents(['os_payments', 'cashflow'], project.org_id, project.id)
  const orgHasCashFlow = useSelector(orgSelectors.getCashFlowIsActive)

  const isSold = useMemo(() => {
    return Boolean(project?.system_sold) && Boolean(project?.payment_option_sold)
  }, [project?.system_sold, project?.payment_option_sold])

  if (!cashFlowIsVisible || !isSold || (!orgHasCashFlow && !hasCashFlowOnThisProject)) return null
  return (
    <Accordion key="payments-events" expanded={true}>
      <AccordionSummary
        aria-controls="expansion-content-payments-events"
        id="expansion-header-payments-events"
        classes={{ content: classes.content }}
      >
        <div className={classes.headerWrapper}>{translate('Recent Activity')}</div>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CashFlowEventTable events={events} isFetching={isFetching} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default CashFlowEvents

import { logAmplitudeEvent } from 'amplitude/amplitude'
import Button from 'elements/button/Button'
import { memo, useCallback, useState } from 'react'
import BomPopup from './BomPopup'
import type { BomDataResponseType, BomDataType, DownloadBomFileType, DownloadBomPricingType } from './type'

type ViewBomButtonPropsType = {
  disabled: boolean
  getBomData: <T extends DownloadBomFileType, W extends DownloadBomPricingType>(
    fileType: T,
    pricingType: W
  ) => Promise<BomDataResponseType<T> | undefined> | undefined
}

const ViewBomButton = ({ disabled, getBomData }: ViewBomButtonPropsType) => {
  const [showPopup, setShowPopup] = useState(false)
  const [bomData, setBomData] = useState<BomDataType | undefined>(undefined)
  const [bomDataLoading, setBomDataLoading] = useState<boolean>(false)

  const handleButtonClick = useCallback(async () => {
    setShowPopup(true)
    if (!bomData) {
      const newBomData = await getBomData('json', 'distributor')
      setBomData(newBomData)
    }
    logAmplitudeEvent('hardware_view_bom_button_clicked')
  }, [bomData, getBomData])

  const closePopup = useCallback(() => {
    setShowPopup(false)
  }, [])

  const handleUpdatePricingSelector = async (pricingType: DownloadBomPricingType) => {
    setBomDataLoading(true)
    const newBomData = await getBomData('json', pricingType)
    setBomData(newBomData)
    setBomDataLoading(false)
  }

  return (
    <div>
      <Button variant="contained" label="View BOM" disabled={disabled} onClick={handleButtonClick} size="small" />
      {showPopup && (
        <BomPopup
          isOpen={showPopup}
          closeDialog={closePopup}
          bomData={bomData}
          getBomData={getBomData}
          onUpdatePricingSelector={handleUpdatePricingSelector}
          bomDataLoading={bomDataLoading}
        />
      )}
    </div>
  )
}

export default memo(ViewBomButton)

import { useTranslate } from 'ra-core'
import { useMemo } from 'react'
import { taxNameForCountryIso2 } from 'util/misc'

export const useTaxIdentifierLabel = (currency: string | undefined, countryIso2: string | undefined) => {
  const translate = useTranslate()

  return useMemo(() => {
    if (currency === 'AUD' || (!currency && countryIso2 === 'AU')) {
      return 'ABN'
    } else {
      return translate('%{taxName} Number', { taxName: taxNameForCountryIso2(countryIso2) })
    }
  }, [currency, countryIso2])
}

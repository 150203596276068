import { List } from 'elements/react-admin/List'
import React from 'react'
import { Datagrid, DateField, FunctionField, PaginationComp, TextField, useTranslate } from 'react-admin'
import { currencySymbolForCurrencyCode, formatDecimalPlaces } from 'util/misc'

export const OrderingQuoteList = (props) => {
  const translate = useTranslate()
  return (
    <List title="Quotes" perPage={20} pagination={<PaginationComp {...props} rowsPerPageOptions={[]} />} {...props}>
      <Datagrid>
        {/*<TextField source="account" />*/}
        <TextField source="reference" sortable={false} />
        <DateField source="createdDate" sortable={false} />
        <TextField label="Quote Status" source="orderStatus" sortable={false} />
        <TextField source="purchaseOrder" sortable={false} />

        <TextField
          label="Gross Total"
          source="totalsResponse.grossTotal"
          sortable={false}
          overrideValueFunc={(value) => {
            var currencySymbol = currencySymbolForCurrencyCode(value.currency)
            var valueFormatted = formatDecimalPlaces(value.amount, 2)
            return currencySymbol === '€' ? valueFormatted + ' ' + currencySymbol : currencySymbol + valueFormatted
          }}
        />

        <TextField
          label="Net Total"
          source="totalsResponse.netPrice"
          sortable={false}
          overrideValueFunc={(value) => {
            var currencySymbol = currencySymbolForCurrencyCode(value.currency)
            var valueFormatted = formatDecimalPlaces(value.amount, 2)
            return currencySymbol === '€' ? valueFormatted + ' ' + currencySymbol : currencySymbol + valueFormatted
          }}
        />

        <TextField
          label="Discount"
          source="totalsResponse.discount.percentage"
          sortable={false}
          overrideValueFunc={(value) => formatDecimalPlaces(value, 1) + '%'}
        />

        <FunctionField
          source="portalUrl"
          sortable={false}
          label={translate('Link')}
          headerStyle={{ flex: '1 1 100%' }}
          render={(record) => (
            <div style={{ flex: '1 1 100%', marginRight: 5 }}>
              <div>
                <a href={record.portalUrl} target="_blank" rel="noreferrer">
                  View
                </a>
              </div>
            </div>
          )}
        />
      </Datagrid>
    </List>
  )
}

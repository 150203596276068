import { Theme, Tooltip, makeStyles } from '@material-ui/core'
import { CheckCircle, Settings } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orgSelectors } from 'ducks/orgs'
import { useTranslate } from 'ra-core'
import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { LENDER_LOGO_PATHS } from 'resources/financeIntegrations/constants'
import { AvailableFinanceIntegrationType } from 'types/orgs'
import { RootState } from 'types/state'

type PropTypes = {
  integration: AvailableFinanceIntegrationType
  onClick: () => void
  onInactiveClick: () => void
  isSelected: boolean
  index: number
  hideChecks?: boolean
  isPromo?: boolean
}

const useStyles = makeStyles<Theme, { isActive: boolean; isSelected: boolean }>((theme) => ({
  wrapper: {
    cursor: 'pointer',
    padding: '5px',
    margin: '10px',
    position: 'relative',
    width: '100px',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    borderColor: (args) => (args.isSelected ? 'rgb(245, 211, 71)' : '#ececec'),
    borderStyle: 'solid',
    borderWidth: '2px',
    borderRadius: '5px',
    backgroundColor: (args) => (args.isActive ? undefined : '#ececec'),
  },
  hoveringIcon: {
    position: 'absolute',
    left: '2px',
    top: '2px',
    cursor: 'pointer',
  },
  img: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}))

const IntegrationFilterButton: React.FC<PropTypes> = (props) => {
  const logoUrl =
    props.integration.logo_large_contents ||
    `${window.PUBLIC_URL}/images/${LENDER_LOGO_PATHS[props.integration.integration_name]?.large}`
  const integrationStatusOnOrg = useSelector((state: RootState) =>
    orgSelectors.getGenericIntegrationStatus(state, props.integration?.integration_name)
  )
  const isActive = !!integrationStatusOnOrg

  const classes = useStyles({ isActive, isSelected: props.isSelected })
  const translate = useTranslate()

  useEffect(() => {
    if (props.isPromo) {
      logAmplitudeEvent('integration_included_in_payments_promo_dialog', {
        integration: props.integration?.integration_name,
        index: props.index,
      })
    }
  }, [props.isPromo])

  const onClick = () => {
    if (props.isPromo) {
      logAmplitudeEvent('integration_clicked_in_payments_promo_dialog', {
        integration: props.integration?.integration_name,
        index: props.index,
      })
    }
    if (isActive) {
      props.onClick()
    } else {
      props.onInactiveClick()
    }
  }

  const tooltipTitle = useMemo(() => {
    if (isActive) return translate("Click to filter by this provider's integrated payment options")
    else return translate('Set up integrated finance partner')
  }, [isActive])

  return (
    <Tooltip title={tooltipTitle} placement="top">
      <div className={classes.wrapper} onClick={onClick}>
        <div className={classes.hoveringIcon}>
          {isActive ? (
            <>{props.hideChecks ? <></> : <CheckCircle htmlColor="rgba(55, 169, 46, 0.9)" fontSize="small" />}</>
          ) : (
            <Settings fontSize="small" htmlColor="rgb(117, 117, 117)" />
          )}
        </div>
        <div className={classes.img}>
          <img src={logoUrl} width="100px" alt={props.integration.display_name} />
        </div>
      </div>
    </Tooltip>
  )
}
export default IntegrationFilterButton

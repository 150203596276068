import _ from 'lodash'
import React, { useEffect } from 'react'
import { StudioSignalTypes } from 'types/studio/signals'
import { UseStudioSignalsOpts } from './useStudioSignals'
import { applyStudioSignals, removeStudioSignals } from './utils'

const withStudioSignals = (BaseComponent: any): any => {
  if (typeof BaseComponent === 'string') throw new Error('Incorrect usage')

  const RefComponent = React.forwardRef((props, ref) => {
    const removeHandlers: (() => void)[] = []

    function useStudioSignals(
      handler: Function,
      signals: StudioSignalTypes[],
      scope?: object,
      opts?: UseStudioSignalsOpts
    ) {
      const debounce = (opts?.debounce || 0) * 1000
      const maxWait = (opts?.debounce || 0) * 1000

      let handlerBound = function () {
        handler.apply(scope, arguments)
      }
      if (debounce > 0)
        handlerBound = _.debounce(handlerBound, debounce, {
          leading: false,
          trailing: true,
          maxWait,
        })

      applyStudioSignals(signals, handlerBound)

      const removeHandler = () => {
        removeStudioSignals(signals, handlerBound)
      }
      removeHandlers.push(removeHandler)
    }

    function useStudioSignalsLazy(
      handler: Function,
      signals: StudioSignalTypes[],
      scope?: object,
      opts?: UseStudioSignalsOpts
    ) {
      if (!opts) opts = {}
      if (opts.debounce === undefined) opts.debounce = 0.05
      if (opts.maxWait === undefined) opts.maxWait = 0.5
      useStudioSignals(handler, signals, scope, opts)
    }

    useEffect(() => {
      // This is where all get cleaned up
      return () => {
        for (const handler of removeHandlers) handler()
      }
    }, [])

    return (
      <BaseComponent
        {...props}
        ref={ref}
        useStudioSignals={useStudioSignals}
        useStudioSignalsLazy={useStudioSignalsLazy}
      />
    )
  })

  RefComponent.defaultProps = BaseComponent.defaultProps

  return RefComponent
}

export default withStudioSignals

export type WithStudioSignalsProps = {
  useStudioSignals: (
    handler: Function,
    signals: StudioSignalTypes[],
    scope?: object,
    opts?: UseStudioSignalsOpts
  ) => void
  useStudioSignalsLazy: (
    handler: Function,
    signals: StudioSignalTypes[],
    scope?: object,
    opts?: UseStudioSignalsOpts
  ) => void
}

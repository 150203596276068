import CustomField from 'elements/field/CustomField'
import { styled } from 'opensolar-ui'
import { useMemo, useState } from 'react'
import { AutocompleteInput, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { FilterField, OS_PROJECT_FILTERS } from './fields'

interface PropTypes {
  source: string
  filter: FilterField
  filtersAdded: string[]
}

const FilterRow = styled('div')({
  display: 'flex',
  width: '100%',
  gap: 10,
})

const Placeholder = styled('span')(({ theme }) => ({
  color: theme.palette.grey[500],
}))

const FilterDisplay: React.FC<PropTypes> = ({ source, filter, filtersAdded }) => {
  const form = useForm()
  const translate = useTranslate()
  const [searchedValue, setSearchedValue] = useState('')

  const unselectedFilterOptions = useMemo(() => {
    const filteredOptions = OS_PROJECT_FILTERS.filter((filterOption) => {
      const sameFilterSelections = filtersAdded.filter((id) => id === filterOption.id).length
      const totalOperators = filterOption.relatedFields?.length || 1

      return sameFilterSelections < totalOperators || filter.fieldId === filterOption.id
    })
    // If there is a search value
    if (searchedValue) {
      return filteredOptions.filter((filterOption) =>
        filterOption.name.toLowerCase().includes(searchedValue.toLowerCase())
      )
    }
    // If no search value, return all the filtered options
    return filteredOptions.sort((a, b) => a.name.localeCompare(b.name))
  }, [filter.fieldId, filtersAdded, searchedValue])

  const [resetFieldData, setResetFieldData] = useState(false)
  const fieldData = useMemo(() => {
    return resetFieldData ? undefined : OS_PROJECT_FILTERS.find((x) => x.id === filter.fieldId)
  }, [filter.fieldId, resetFieldData])

  const selectedValues = useMemo(() => {
    return filtersAdded.reduce<string[]>((acc, fieldId) => {
      const filterField = form.getFieldState(`${source}.value`)
      if (filterField?.value) {
        return [...acc, ...(Object.values(filterField.value) as string[])]
      }
      return acc
    }, [])
  }, [filtersAdded, form, source])
  return (
    <FilterRow>
      <AutocompleteInput
        source={`${source}.fieldId`}
        label={false}
        variant="outlined"
        choices={unselectedFilterOptions}
        margin="none"
        allowEmpty={true}
        setFilter={(value) => setSearchedValue(value)}
        onChange={(value) => {
          if (value !== null) {
            setResetFieldData(false)
          }
        }}
        options={{
          InputProps: {
            onChange: () => {
              setResetFieldData(true)
            },
            placeholder: translate('Select filter'),
          },
        }}
      />
      {fieldData?.field && (
        <CustomField
          source={source}
          name={source}
          component={fieldData?.field}
          fieldId={fieldData.id}
          {...(fieldData.props || {})}
          selectedValues={selectedValues}
        />
      )}
    </FilterRow>
  )
}

export default FilterDisplay

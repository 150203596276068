import { useMediaQuery } from '@material-ui/core'
import ArrowDown from '@material-ui/icons/ExpandMoreOutlined'
import React from 'react'
import { Theme } from 'types/themes'
import { PHOENIX_SCROLL_TARGET } from './PhoenixBanner'

type PropTypes = {
  overrideLogo: undefined | string
  coBrandLogo: undefined | string
  orgLogo: undefined | string
}

const PhoenixHeaderBanner: React.FC<PropTypes> = (props) => {
  const scrollToPhoenix = () => {
    let elem = document.getElementById(PHOENIX_SCROLL_TARGET)
    if (elem) elem.scrollIntoView(true)
  }
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const hasLogo = !!props.overrideLogo || !!props.coBrandLogo || !!props.orgLogo
  if (isMobile) return null
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'rgb(132, 76, 134)',
        color: '#fff',
        padding: hasLogo ? '0px' : '5px',
        marginTop: hasLogo ? '0px' : '5px',
        cursor: 'pointer',
      }}
      onClick={scrollToPhoenix}
    >
      <div style={{ flex: 1 }}>
        <img height={hasLogo ? '20px' : '30px'} src={`${window.PUBLIC_URL}/images/phoenix-logo-white.png`} />
      </div>
      <div style={{ display: 'flex', flex: 2, justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ fontWeight: 900, marginRight: '5px' }}>FINANCE AVAILABLE</div>
        <div style={{ marginLeft: '5px' }}>From Phoenix Financial Consultants</div>
      </div>
      <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <ArrowDown htmlColor="#fff" />
      </div>
    </div>
  )
}
export default PhoenixHeaderBanner

export const TRANSFER_STATUS_OPTIONS = [
  { id: 'Overdue', name: 'Overdue' },
  { id: 'Received', name: 'Received' },
  { id: 'Submitted', name: 'Submitted' },
  { id: 'Partially received', name: 'Partially received' },
]

export const ORDER_STATUS = {
  QUOTE_SUBMITTED: 'Quote Submitted',
  ORDERED: 'Ordered',
  PROCESSING: 'Processing',
  DELIVERED: 'Delivered',
}

export const ORDER_STATUS_OPTIONS = [
  { id: ORDER_STATUS.QUOTE_SUBMITTED, name: ORDER_STATUS.QUOTE_SUBMITTED },
  { id: ORDER_STATUS.ORDERED, name: ORDER_STATUS.ORDERED },
  { id: ORDER_STATUS.PROCESSING, name: ORDER_STATUS.PROCESSING },
  { id: ORDER_STATUS.DELIVERED, name: ORDER_STATUS.DELIVERED },
]

export const DISTRIBUTOR_ORDER_STATUS_OPTIONS = (distributor) => {
  switch (distributor) {
    case 'segen':
      return [
        { id: ORDER_STATUS.QUOTE_SUBMITTED, name: ORDER_STATUS.QUOTE_SUBMITTED },
        { id: ORDER_STATUS.ORDERED, name: ORDER_STATUS.ORDERED },
        { id: ORDER_STATUS.PROCESSING, name: ORDER_STATUS.PROCESSING },
        { id: ORDER_STATUS.DELIVERED, name: ORDER_STATUS.DELIVERED },
      ]
    default:
      return [
        { id: ORDER_STATUS.ORDERED, name: ORDER_STATUS.ORDERED },
        { id: ORDER_STATUS.PROCESSING, name: ORDER_STATUS.PROCESSING },
        { id: ORDER_STATUS.DELIVERED, name: ORDER_STATUS.DELIVERED },
      ]
  }
}

export const TRANSFER_TYPE = {
  INCOMING: 'incoming',
  OUTGOING: 'outgoing',
}

export const TRANSFER_ISSUED_REASON = {
  STOCKTAKE: 'Stocktake',
  INCOMING_PO: 'Incoming PO',
  CONSUMED: 'Consumed',
  DAMAGED: 'Damaged',
  RESTOCKED: 'Restocked',
  RETURNED: 'Returned',
  SOLD: 'Sold',
  STOLEN: 'Stolen',
}

export const TRANSFER_ISSUED_REASON_OPTIONS = [
  { id: 'Stocktake', name: TRANSFER_ISSUED_REASON.STOCKTAKE },
  { id: 'Incoming PO', name: TRANSFER_ISSUED_REASON.INCOMING_PO },
  { id: 'Consumed', name: TRANSFER_ISSUED_REASON.CONSUMED },
  { id: 'Damaged', name: TRANSFER_ISSUED_REASON.CONSUMED },
  { id: 'Restocked', name: TRANSFER_ISSUED_REASON.RESTOCKED },
  { id: 'Returned', name: TRANSFER_ISSUED_REASON.RETURNED },
  { id: 'Sold', name: TRANSFER_ISSUED_REASON.SOLD },
  { id: 'Stolen', name: TRANSFER_ISSUED_REASON.STOLEN },
]

import SendIcon from '@material-ui/icons/SendOutlined'
import React from 'react'
import { Button, showNotification as showNotificationAction } from 'react-admin'
import { connect } from 'react-redux'
import restClient from 'restClient'
import { urlToId } from '../../util/misc'

const restClientInstance = restClient(window.API_ROOT + '/api')

const listStyle = { color: '#4d4d4d', width: 90, justifyContent: 'flex-start', margin: 0 }
const defaultStyle = {}

const ResendInvitationButton = (props) =>
  !props.record.has_logged_in && (
    <Button
      label="Resend"
      redirect={false}
      submitOnEnter={false}
      onClick={(event) => {
        restClientInstance('CUSTOM_POST', 'custom', {
          url: 'orgs/' + urlToId(props.record.org) + '/roles/' + props.record.id + '/resend_invitation/',
        })
          .then((response) => {
            props.showNotification(response.data.message, 'success')
          })
          .catch((error) => {
            props.showNotification(error.message, 'warning')
          })
      }}
      style={props.useListStyle ? listStyle : defaultStyle}
      {...props}
    >
      <SendIcon />
    </Button>
  )

export default connect(() => ({}), { showNotification: showNotificationAction })(ResendInvitationButton)

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { TextField as Mui4TextField } from '@material-ui/core';
import { default as Mui5TextField } from '@mui/material/TextField';
import { forwardRef } from 'react';
import { Inspector } from '../debug/Inspector';
import { useComponentVersion } from '../hooks';
import { ChevronDownOutlineIcon } from '../icons';
import FieldTitle from './FieldTitle';
// * MUI TextField API Spec  https://mui.com/material-ui/api/text-field/
export var TextField = function (props) { return (_jsx(Inspector, { name: "TextField", propDefs: TextFieldPropsDefs, showExample: false, component: OsTextField, props: props })); };
export var OsTextFieldVersion;
(function (OsTextFieldVersion) {
    OsTextFieldVersion[OsTextFieldVersion["V1"] = 1] = "V1";
    OsTextFieldVersion[OsTextFieldVersion["V2"] = 2] = "V2";
})(OsTextFieldVersion || (OsTextFieldVersion = {}));
var V2_TEXT_COLORS = ['primary', 'secondary'];
var OsTextField = forwardRef(function (props, ref) {
    var version = useComponentVersion('text_field');
    switch (version) {
        case OsTextFieldVersion.V2:
            var InputProps = props.InputProps, SelectProps = props.SelectProps, label = props.label, color = props.color, rest = __rest(props, ["InputProps", "SelectProps", "label", "color"]);
            var InputProps5 = InputProps;
            var SelectProps5 = __assign({ IconComponent: ChevronDownOutlineIcon }, SelectProps);
            return (_jsx(Mui5TextField, __assign({ ref: ref, InputProps: InputProps5, SelectProps: SelectProps5, color: color || 'secondary', InputLabelProps: {
                    //set by default to apply OS Stratis UI styling
                    shrink: true,
                }, label: label === false ? undefined : typeof label === 'string' ? (_jsx(FieldTitle, { label: label, required: rest.required })) : (label) }, rest)));
        default:
            var mui4Props = __assign(__assign({}, props), { variant: props.variant, color: color && V2_TEXT_COLORS.includes(color) ? color : 'secondary' });
            return _jsx(Mui4TextField, __assign({ ref: ref }, mui4Props));
    }
});
var TextFieldPropsDefs = {};

import { Grid, makeStyles } from '@material-ui/core'
import { AddOutlined, EditOutlined } from '@material-ui/icons'
import Alert from 'elements/Alert'
import { List } from 'elements/react-admin/List'
import ListActions from 'elements/react-admin/ListActions'
import { useEffect } from 'react'
import {
  BooleanInput,
  Button,
  Create,
  Datagrid,
  Edit,
  FunctionField,
  NumberField,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  required,
  useNotify,
  useRedirect,
} from 'react-admin'
import { useHistory } from 'react-router-dom'
import PaymentFactorsWrapper from 'resources/paymentOptions/widgets/PaymentFactorsWrapper'
import { TERM_STATUS_CHOICES } from './constants'

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const getFinanceProductCategoryId = () => {
  const path = window.location.hash.substring(window.location.hash.indexOf('#') + 2)
  const params = new URLSearchParams(path.substring(path.indexOf('?')))
  const paramStr = params.get('finance_product_category_id')
  return paramStr ? parseInt(paramStr) : undefined
}

export const FinanceProductTermList = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const financeProductCategoryId = getFinanceProductCategoryId()

  useEffect(() => {
    if (!financeProductCategoryId) {
      history.push('/finance_partners')
    }
  }, [financeProductCategoryId])

  const CustomCreateButton = () => (
    <Button
      label="Create a Finance Product Term"
      onClick={() =>
        history.push(`/finance_product_terms/create?finance_product_category_id=${financeProductCategoryId}`)
      }
      startIcon={<AddOutlined />}
      variant="outlined"
    />
  )

  const goToEdit = (record) => {
    history.push(`/finance_product_terms/${record.id}?finance_product_category_id=${financeProductCategoryId}`)
  }

  return (
    <List
      hasSearch={true}
      actions={<ListActions CustomButton={CustomCreateButton()} />}
      {...props}
      hasCreate={false}
      filter={{ finance_product_category_id: financeProductCategoryId }}
    >
      <Datagrid>
        <TextField source="title" label="Internal Title" />
        <TextField source="display_name" />
        <FunctionField
          source="finance_product"
          label="Status"
          sortable={false}
          render={(record: any, source, rest) => <div>{record.status?.replaceAll('_', ' ')}</div>}
        />
        <NumberField source="version_number" />
        <FunctionField
          source="finance_product"
          label="Actions"
          render={(record: any, source, rest) => (
            <div className={classes.row}>
              <Button startIcon={<EditOutlined />} label="Edit" onClick={() => goToEdit(record)} />
            </div>
          )}
          sortable={false}
        />
      </Datagrid>
    </List>
  )
}

const FinanceProductTermFields = (props) => {
  const financeProductCategoryId = getFinanceProductCategoryId()
  const validateJSON = (value) => {
    if (!value) return undefined
    try {
      JSON.parse(value)
      return undefined
    } catch (ex) {
      return 'Invalid JSON'
    }
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <TextInput label="Internal Title" source="title" validate={[required()]} fullWidth />
      </Grid>
      <Grid item xs={4}>
        <TextInput source="display_name" validate={[required()]} fullWidth />
      </Grid>
      <Grid item xs={4}>
        <SelectInput
          source="status"
          validate={[required()]}
          fullWidth
          style={{ width: '100%' }}
          choices={TERM_STATUS_CHOICES}
          optionText="label"
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput source="description" fullWidth />
      </Grid>
      <Grid item xs={3}>
        <NumberInput source="version_number" label="Version Number" validate={[required()]} fullWidth />
      </Grid>
      <Grid item xs={3}>
        {/* @ts-ignore */}
        <ReferenceInput
          label="Finance Product Category"
          reference="finance_products"
          source="finance_product_category"
          fullWidth
          style={{ width: '100%' }}
          disabled={true}
        >
          <SelectInput optionText="display_name" optionValue="url" source="finance_product_category" disabled={true} />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12}>
        <h2>Pricing:</h2>
      </Grid>
      <Grid item xs={6}>
        <NumberInput source="interest_rate" label="Interest Rate (%)" validate={[required()]} fullWidth />
      </Grid>
      <Grid item xs={6}>
        <NumberInput source="payment_periods" label="Payment Periods" validate={[required()]} fullWidth />
      </Grid>
      <Grid item xs={6}>
        <NumberInput source="reversion_rate" label="Reversion Rate (%)" fullWidth />
      </Grid>
      <Grid item xs={6}>
        <NumberInput source="start_of_reversion_period" label="Start of Reversion Period" fullWidth />
      </Grid>
      <Grid item xs={12}>
        <Alert severity="info">
          When payment factors are enabled the interest rate will only be used for display purposes (eg the title of the
          payment option if this is a loan).
        </Alert>
      </Grid>
      <Grid item xs={12}>
        <PaymentFactorsWrapper variableRoot="payment_factor_json" disabled={false} />
      </Grid>
      <Grid item xs={4}>
        <NumberInput source="dealer_fee_percentage" validate={[required()]} fullWidth />
      </Grid>
      <Grid item xs={4}>
        <NumberInput source="dealer_fee_fixed" validate={[required()]} fullWidth />
      </Grid>
      <Grid item xs={4}>
        <NumberInput source="product_fee_fixed" fullWidth />
      </Grid>
      <Grid item xs={12}>
        <BooleanInput
          source="auto_apply_enabled"
          label="Enable Auto-apply when imported into org?"
          defaultValue={false}
          defaultChecked={false}
        />
      </Grid>
      <Grid item xs={12}>
        <BooleanInput
          source="is_archived"
          label="Archive immediately for all orgs using this product term?"
          defaultValue={false}
          defaultChecked={false}
        />
      </Grid>
    </Grid>
  )
}

export const FinanceProductTermEdit = (props) => {
  const financeProductCategoryId = getFinanceProductCategoryId()

  return (
    <Edit
      {...props}
      hasDelete={true}
      redirect={(resource, id, data) =>
        `/finance_product_terms?finance_product_category_id=${financeProductCategoryId}`
      }
    >
      <SimpleForm>
        <FinanceProductTermFields {...props} />
      </SimpleForm>
    </Edit>
  )
}

export const FinanceProductTermCreate = (props) => {
  const financeProductCategoryId = getFinanceProductCategoryId()
  const redirect = useRedirect()
  const notify = useNotify()

  const onSuccess = (data) => {
    notify('Financing Product Term Created')
    redirect(`/finance_product_terms?finance_product_category_id=${financeProductCategoryId}`)
  }
  return (
    <Create {...props} mutationOptions={{ onSuccess }}>
      <SimpleForm initialValues={{ finance_product_category_id: financeProductCategoryId }}>
        <FinanceProductTermFields {...props} />
      </SimpleForm>
    </Create>
  )
}

import { authSelectors } from 'ducks/auth'
import { permissionsSelectors } from 'ducks/permissions'
import { ControlBreadcrumbLink, ListBreadCrumbs } from 'elements/BreadCrumbs'
import TextFieldWithSharedEntityIcon from 'elements/TextFieldWithSharedEntityIcon'
import EditOrImportButton from 'elements/button/EditOrImportButton'
import { useBulkActionButtons } from 'elements/hooks/useBulkActionButtons'
import { useEditShareable } from 'elements/react-admin/EditShareable'
import { List } from 'elements/react-admin/List'
import React, { useMemo } from 'react'
import {
  Create,
  Datagrid,
  FilterComp,
  FunctionField,
  NotFound,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'
import { useSelector } from 'react-redux'
import ShareabilitySelector from 'resources/connectedOrgs/ShareabilitySelector'
import SharedFromInput from 'resources/connectedOrgs/filters/SharedFromInput'
import SharedWithInput from 'resources/connectedOrgs/filters/SharedWithInput'
import { STAGE_CHOICES } from 'resources/projects/choices'
import { styles as standardStyles } from 'styles'

export const ActionFilter = (props) => (
  <FilterComp {...props}>
    <SelectInput options={{ style: standardStyles.FILTER_FIELD_STYLE }} source="stage" choices={STAGE_CHOICES} />
    <SharedFromInput source="owned_by" label={'Shared From'} />
    <SharedWithInput source="shared_with" label={'Shared With'} />
  </FilterComp>
)

const inputStyle = {
  width: 256,
}

export const ActionList = (props) => {
  const { allowView, allowCreate, allowEdit, allowDelete } = useSelector(
    permissionsSelectors.getPermissionByKey('business_process')
  )
  const orgId = useSelector(authSelectors.getOrgId)
  const isAdmin = useSelector(authSelectors.getIsAdmin)
  const bulkActionButtons = useBulkActionButtons(props, allowEdit, allowDelete)

  if (!allowView) {
    return <NotFound />
  }

  const breadcrumbs = [
    ControlBreadcrumbLink,
    { title: 'Business Process', link: '/business-process' },
    { title: 'Actions' },
  ]
  return (
    <List
      breadCrumbs={<ListBreadCrumbs links={breadcrumbs} />}
      filters={<ActionFilter />}
      hasSearch={true}
      perPage={20}
      {...props}
      hasCreate={allowCreate}
      bulkActionButtons={bulkActionButtons}
    >
      <Datagrid>
        <TextFieldWithSharedEntityIcon source="title" label="Title" />
        {
          <FunctionField
            source={null}
            label="Actions"
            render={(record, source, rest) => (
              <EditOrImportButton
                sortable={false}
                org_id={orgId}
                source="actions"
                label="Actions"
                resource={props.resource}
                record={record}
                basePath={props.basePath}
                allowDuplicate={false}
                isAdmin={isAdmin}
                allowEdit={allowEdit}
                allowDelete={allowDelete}
              />
            )}
            sortable={false}
          />
        }
      </Datagrid>
    </List>
  )
}

export const ActionShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="title" />
    </SimpleShowLayout>
  </Show>
)

const WorkflowDisplay = (props) => {
  const translate = useTranslate()
  const workflowTitles = useMemo(() => props.record?.workflows?.map((x) => x.workflow))
  return <p>{translate('Currently added to the following workflows:') + ` ${workflowTitles?.join(', ')}`}</p>
}

export const ActionEdit = (props) => {
  const accessRights = useSelector(permissionsSelectors.getPermissionByKey('business_process'))

  return useEditShareable({
    accessRights,
    editProps: props,
    render: ({ access }) => (
      <SimpleForm validate={validateTitle} disabled={!access.allowEdit}>
        <TextInput source="title" style={inputStyle} />
        <WorkflowDisplay {...props} />
        <ShareabilitySelector />
      </SimpleForm>
    ),
  }).editPage
}

export const ActionCreate = (props) => (
  <Create {...props}>
    <SimpleForm validate={validateTitle}>
      <TextInput source="title" style={inputStyle} />
      <ShareabilitySelector />
    </SimpleForm>
  </Create>
)

const validateTitle = (values) => {
  const errors = {}
  if (!values.title) {
    errors.title = ['Title is required.']
  }
  return errors
}

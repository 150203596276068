// @ts-nocheck
import { Tooltip } from '@material-ui/core'
import { DeleteOutline } from '@material-ui/icons'
import { authSelectors } from 'ducks/auth'
import WithPermissionsCheck from 'elements/WithPermissionsCheck'
import { Button } from 'opensolar-ui'
import React, { useCallback, useContext } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { UtilityTariffDialogContext } from './index'

type PropTypes = {
  variant: 'current' | 'proposed'
  disabled?: boolean
}

const ClearButton: React.FC<PropTypes> = ({ variant, disabled }) => {
  const translate = useTranslate()
  const form = useForm()
  const notify = useNotify()
  const { state, dispatch } = useContext(UtilityTariffDialogContext)
  const projectId = useSelector((state: any) => state.projectId)
  const orgId = useSelector(authSelectors.getOrgId)
  const clearTariff = useCallback(
    (data) => {
      form.batch(() => {
        //@ts-ignore
        form.registerField(`utility_tariff_${variant}`, () => {})
        form.change(`utility_tariff_${variant}`, null)

        //@ts-ignore
        form.registerField(`utility_tariff_${variant}_data`, () => {})
        form.change(`utility_tariff_${variant}_data`, null)

        //@ts-ignore
        form.registerField(`utility_tariff_${variant}_custom`, () => {})
        form.change(`utility_tariff_${variant}_custom`, null)
      })
      dispatch({ type: 'refresh' })
      notify('ra.notification.updated', 'info')
    },
    [projectId, orgId]
  )
  return (
    <WithPermissionsCheck permissionToCheck="energy_tariff" permissions={['allowDelete']}>
      <Button
        style={{
          maxWidth: '40px',
          maxHeight: '30px',
          minWidth: '40px',
          minHeight: '30px',
          backgroundColor: 'rgba(0, 0, 0, 0)',
        }}
        disabled={disabled}
        id="ClearCurrentTariff"
        onClick={(e) => {
          e.preventDefault()
          const data = {
            ['utility_tariff_' + variant]: null,
          }
          clearTariff(data)
        }}
      >
        <Tooltip title={translate('Clear')} enterDelay={300}>
          <DeleteOutline style={{ fill: disabled ? '#d8d8d8' : 'rgb(77, 77, 77)' }} />
        </Tooltip>
      </Button>
    </WithPermissionsCheck>
  )
}

export default ClearButton

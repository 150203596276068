import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { Button } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'

interface LaunchInvoiceConfirmationProps {
  open: boolean
  onConfirm: () => void
  onClose: () => void
}

const LaunchInvoiceConfirmation: React.FC<LaunchInvoiceConfirmationProps> = ({ open, onConfirm, onClose }) => {
  const translate = useTranslate()
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{translate('Ready to Send the Invoice?')}</DialogTitle>
      <DialogContent>
        <p>
          {translate(
            'This will create and send the invoice to the customer. Are you ready to proceed to the payment page?'
          )}
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          {translate('Cancel')}
        </Button>
        <Button onClick={onConfirm} variant="contained" color="primary">
          {translate('Yes, send the invoice')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default LaunchInvoiceConfirmation

import { ModuleGridType } from 'types/global'
import type { BasicAccessorConfig } from './types'

const setter = ({ moduleGrid, value }: { moduleGrid: ModuleGridType; value: boolean }) => {
  window.editor.execute(
    new window.SetPanelConfigurationCommand(
      moduleGrid,
      'azimuthAuto',
      value,
      window.Utils.generateCommandUUIDOrUseGlobal()
    )
  )
}

const getter = (moduleGrid: ModuleGridType): boolean => {
  return moduleGrid.azimuthAuto
}

export const azimuthAutoAccessorConfig: BasicAccessorConfig<boolean> = {
  get: getter,
  set: setter,
}

import { Grid, makeStyles } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import { orgSelectors } from 'ducks/orgs'
import { useTranslate } from 'ra-core'
import { useMemo } from 'react'
import ChartistGraph from 'react-chartist'
import { useSelector } from 'react-redux'
import { formatCurrencyWithSymbol } from 'util/misc'

type PropTypes = {
  totalPaidAmount: number
  totalOutstandingAmount: number
  totalOverdueAmount: number
  totalPayableAmount: number
  totalInProgressAmount: number
}

const useStyles = makeStyles<OpenSolarThemeType, { invoiceType? }>((theme) => ({
  summaryContainer: {
    margin: 'auto',
  },

  payable: {
    fill: theme.white,
  },

  paid: {
    fill: '#559744',
    stroke: theme.white,
    strokeWidth: '1px',
  },

  outstanding: {
    fill: theme.white,
    stroke: theme.white,
    strokeWidth: '1px',
  },

  overdue: {
    fill: '#D27363',
    stroke: theme.white,
    strokeWidth: '1px',
  },

  inProgress: {
    fill: '#E8C376',
    stroke: theme.white,
    strokeWidth: '1px',
  },

  totalLabel: {
    fontWeight: 500,
    fontSize: 16,
    color: theme.white,
    marginBottom: 3,
  },

  statusLabels: {
    display: 'flex',
    gap: 5,
    alignItems: 'center',
    marginLeft: '-20px',
  },

  statusLabelValue: {
    paddingLeft: 0,
  },

  label: {
    color: theme.white,
    margin: 0,
    fontWeight: 300,
  },

  badge: {
    height: '8px',
    width: '8px',
    marginLeft: '5px',
    borderRadius: '12px',
    backgroundColor: ({ invoiceType }) => {
      switch (invoiceType) {
        case 'Outstanding':
          return theme.white
        case 'Overdue':
          return '#C11B05'
        case 'In Progress':
          return '#E8C376'
        case 'Collected':
          return '#277D19'
        default:
          return 'transparent'
      }
    },
  },
}))

export const DefaultPieChart = (props) => {
  const classes = useStyles({})
  const translate = useTranslate()

  const currencySymbol = useSelector(orgSelectors.getCurrencySymbolFromOrg)

  const startAngle = useMemo(() => {
    const { totalPaidAmount, totalPayableAmount } = props
    let percentPaid = totalPayableAmount === 0 ? 0 : totalPaidAmount / totalPayableAmount
    return percentPaid * 360
  }, [props.totalPaid, props.totalPayableAmount])

  return (
    <Grid container spacing={1} className={classes.mainContainer}>
      <Grid item xs={7} className={classes.summaryContainer}>
        <Grid container spacing={2} className={classes.totalLabel}>
          <Grid item xs={6}>
            <span>{translate('Total Value')}</span>
          </Grid>
          <Grid item xs={6}>
            <span>{formatCurrencyWithSymbol(props.totalPayableAmount, currencySymbol, undefined, 0)}</span>
          </Grid>
        </Grid>

        <Grid container spacing={2} className={classes.label}>
          <Grid item xs={6}>
            <div className={classes.statusLabels}>
              <StatusBadge invoiceType="Outstanding" />
              <span>{translate('Outstanding')}</span>
            </div>
          </Grid>
          <Grid item xs={6}>
            <span className={classes.statusLabelValue}>
              {formatCurrencyWithSymbol(props.totalOutstandingAmount, currencySymbol, undefined, 0)}
            </span>
          </Grid>
        </Grid>

        <Grid container spacing={2} className={classes.label}>
          <Grid item xs={6}>
            <div className={classes.statusLabels}>
              <StatusBadge invoiceType="Overdue" />
              <span>{translate('Overdue')}</span>
            </div>
          </Grid>
          <Grid item xs={6}>
            <span className={classes.statusLabelValue}>
              {formatCurrencyWithSymbol(props.totalOverdueAmount, currencySymbol, undefined, 0)}
            </span>
          </Grid>
        </Grid>

        <Grid container spacing={2} className={classes.label}>
          <Grid item xs={6}>
            <div className={classes.statusLabels}>
              <StatusBadge invoiceType="In Progress" />
              <span>{translate('In Progress')}</span>
            </div>
          </Grid>
          <Grid item xs={6}>
            <span className={classes.statusLabelValue}>
              {formatCurrencyWithSymbol(props.totalInProgressAmount, currencySymbol, undefined, 0)}
            </span>
          </Grid>
        </Grid>

        <Grid container spacing={2} className={classes.label}>
          <Grid item xs={6}>
            <div className={classes.statusLabels}>
              <StatusBadge invoiceType="Collected" />
              <span>{translate('Collected')}</span>
            </div>
          </Grid>
          <Grid item xs={6}>
            <span className={classes.statusLabelValue}>
              {formatCurrencyWithSymbol(props.totalPaidAmount, currencySymbol, undefined, 0)}
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <div className={classes.chartWrapper}>
          <ChartistGraph
            data={{
              labels: ['Outstanding', 'Paid', 'Overdue'],
              series: [
                { value: props.totalOverdueAmount, className: classes.overdue },
                { value: props.totalOutstandingAmount, className: classes.outstanding },
                { value: props.totalPaidAmount, className: classes.paid },
                { value: props.totalInProgressAmount, className: classes.inProgress },
              ],
            }}
            //@ts-ignore
            options={{ showLabel: false, startAngle: startAngle, height: '135px', width: '135px' }}
            type={'Pie'}
          />
        </div>
      </Grid>
    </Grid>
  )
}

const StatusBadge = ({ invoiceType }) => {
  const classes = useStyles({ invoiceType })
  return <div className={classes.badge}></div>
}

export default DefaultPieChart

import AddIcon from '@material-ui/icons/AddOutlined'
import DragHandleIcon from '@material-ui/icons/DragHandleOutlined'
import RemoveIcon from '@material-ui/icons/RemoveOutlined'
import ChartistHover from 'chartist-hover'
import React, { Component, memo } from 'react'
import { useTranslate } from 'react-admin'
import ChartistGraph from 'react-chartist'
import { PropTypes, StateTypes } from './BillSavingsChart.types'
import { billFrequencyLabels } from './index'

const toolTipDefaultStyle: React.CSSProperties = {
  position: 'absolute',
  background: 'rgba(237, 237, 237, 0.9)',
  borderRadius: 3,
  padding: 4,
  textAlign: 'left',
}
const textStyle: React.CSSProperties = {
  // overflow: 'hidden',
  // textOverflow: 'ellipsis',
  // whiteSpace: 'nowrap',
  maxWidth: 60,
}
const symbolStyle: React.CSSProperties = { fontSize: 16 }

const NetSpendLegend = memo<PropTypes>((props: PropTypes) => {
  const translate = useTranslate()
  const { currencySymbol, billFrequency, newBill, regularPayment, totalBillBasedIncentive } = props
  const netSpend = newBill + regularPayment - totalBillBasedIncentive

  return (
    <div
      className={'small'}
      style={{ display: 'flex', margin: '-20px 0px 20px 35px', alignItems: 'center', justifyContent: 'space-between' }}
    >
      <div>
        <div>{currencySymbol + netSpend.toFixed(0)}</div>
        <div style={textStyle}>{translate('Net Spend')}</div>
      </div>

      <DragHandleIcon style={symbolStyle} />

      <div>
        <div>{currencySymbol + newBill.toFixed(0)}</div>
        <div style={textStyle}>{translate('New Bill')}</div>
      </div>
      {!!regularPayment && (
        <>
          <AddIcon style={symbolStyle} />
          <div>
            <div>{currencySymbol + regularPayment.toFixed(0)}</div>
            <div style={textStyle}>{translate(billFrequencyLabels[billFrequency] + ' Payments')}</div>
          </div>
        </>
      )}
      {!!totalBillBasedIncentive && (
        <>
          <RemoveIcon style={symbolStyle} />
          <div>
            <div>{currencySymbol + totalBillBasedIncentive.toFixed(0)}</div>
            <div style={textStyle}>{translate('Incentives')}</div>
          </div>
        </>
      )}
    </div>
  )
})

class BillSavingsChart extends Component<PropTypes, StateTypes> {
  constructor(props: PropTypes) {
    super(props)
    this.state = {
      toolTips: [],
      toolTipStyle: {},
    }
  }

  getToolTipsLabel = (className: string) => {
    let toolTips: { label: string; value: number }[] = []
    const { billFrequency, oldBill, newBill, regularPayment, totalBillBasedIncentive } = this.props
    if (className.includes('OldBill')) {
      toolTips.push({ label: 'Old Bill', value: oldBill })
    } else if (className.includes('NetSpend')) {
      toolTips.push({ label: 'Net Spend', value: newBill + regularPayment - totalBillBasedIncentive })
    } else if (className.includes('NewBill')) {
      toolTips.push({ label: 'New Bill', value: newBill })
    } else if (className.includes('Payment')) {
      toolTips.push({ label: billFrequencyLabels[billFrequency] + ' Payments', value: regularPayment })
      toolTips.push({ label: 'New Bill', value: newBill })
    } else if (className.includes('BillIncentives')) {
      toolTips.push({ label: 'Bill Incentives', value: totalBillBasedIncentive })
    }
    return toolTips
  }

  onMouseEnter = (e: React.MouseEvent) => {
    if (!Boolean((e.target as HTMLElement).getAttribute('ct:value'))) return
    const parentElement = (e.target as HTMLElement).parentElement
    const className = parentElement && parentElement.getAttribute('class')
    const toolTips = this.getToolTipsLabel(className || '')
    if (toolTips.length !== this.state.toolTips.length) {
      this.setState({ toolTipStyle: { top: e.pageY - 40, left: e.pageX }, toolTips })
    }
  }

  onMouseLeave = () => {
    this.setState({
      toolTipStyle: {},
      toolTips: [],
    })
  }

  render() {
    const {
      paymentTitle,
      paymentType,
      currencySymbol,
      billFrequency,
      oldBill,
      newBill,
      regularPayment,
      totalBillBasedIncentive,
    } = this.props
    //show net spend when both positive and negative values existed at the same time
    const showNetSpend = (regularPayment > 0 || newBill > 0) && (newBill < 0 || totalBillBasedIncentive > 0)
    const billSavingsChartData = window.Designer.BillSavingsChartData(
      oldBill,
      newBill,
      paymentTitle,
      paymentType,
      regularPayment,
      currencySymbol,
      billFrequency,
      totalBillBasedIncentive,
      showNetSpend
    )
    // Hack: Not sure why we can't just use Designer.ctBarLabelsBillSavings - labels don't appear
    // Re-implementing within this file fixes labels.
    billSavingsChartData.options.plugins = [
      ...billSavingsChartData.options.plugins,
      ChartistHover({
        onMouseEnter: this.onMouseEnter,
        onMouseLeave: this.onMouseLeave,
        triggerSelector: null,
      }),
    ]
    const style: React.CSSProperties = { ...toolTipDefaultStyle, ...this.state.toolTipStyle }
    return (
      <div style={{ width: '100%' }}>
        <div className="mye-graph-tooltips" style={style}>
          {this.state.toolTips.map((toolTip) => {
            return (
              <p style={{ margin: 0 }}>
                {toolTip.label}: {this.props.currencySymbol}
                {Math.round(toolTip.value).toLocaleString(window.locale)}
              </p>
            )
          })}
        </div>
        <ChartistGraph
          className="ct-chart BillSavingsChart"
          style={{
            marginTop: 20,
            marginLeft: 0,
            marginBottom: 0,
            marginRight: 0,
            height: '200px',
          }}
          data={billSavingsChartData}
          options={billSavingsChartData.options}
          type={'Bar'}
        />
        {showNetSpend && <NetSpendLegend {...this.props} />}
      </div>
    )
  }
}

export default BillSavingsChart

import { ReorderComponentButton } from 'projectSections/sections/design/systems/tabs/common/ReorderComponentButton'
import React, { ReactNode, useCallback, useMemo } from 'react'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
} from 'react-beautiful-dnd'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import {
  DraggableListController,
  DraggableListControllerProps,
  useDraggabbleListController,
} from './useDraggabbleListController'

export type DragMode = 'whole-element' | 'drag-handle' | 'drag-handle-if-mobile'

type DraggableListProps<T, K = string> = Omit<DraggableListColumnProps<T, K>, 'controller' | 'groupId'> &
  Omit<DraggableListControllerProps<T, K>, 'groups' | 'onDragComplete'> & {
    list: T[]
    handleReorder: (oldOrder: K[], newOrder: K[]) => void
  }

const useStyles = makeOpenSolarStyles<{ horizontal?: boolean }>(
  {
    dragging: { background: 'white', borderRadius: 4, '&>*': { opacity: 0.5 } },
    droppabble: ({ horizontal }) => ({
      display: 'flex',
      flexDirection: horizontal ? 'row' : 'column',
      flexWrap: horizontal ? 'wrap' : undefined,
    }),
  },
  { name: 'DraggabbleList' }
)

export const DraggableList = <T, K = string>(props: DraggableListProps<T, K>) => {
  const { list, optionId, handleReorder, ...innerProps } = props

  const onDragComplete = (oldGroups, newGroups) => {
    handleReorder(
      oldGroups.default.map((i) => controller.getItemId(i)),
      newGroups.default.map((i) => controller.getItemId(i))
    )
  }

  const groups = useMemo(() => ({ default: list }), [list])

  const controller = useDraggabbleListController({ groups, optionId, onDragComplete })

  return (
    <DragDropContext onDragStart={controller.onDragStart} onDragEnd={controller.onDragEnd}>
      <DraggableListColumn {...innerProps} controller={controller} groupId="default" />
    </DragDropContext>
  )
}

type DraggableListColumnProps<T, K = string> = {
  render: (
    item: T,
    opts: {
      index: number
      dragHandleProps: any
      isDragging: boolean
      dragHandle: ReactNode | undefined
    }
  ) => ReactNode
  applyDragStyles?: boolean
  dragMode?: DragMode
  horizontal?: boolean
  endAnnotation?: ReactNode
  isDragDisabled?: (item: T) => boolean
  controller: DraggableListController<T, K>
  groupId: string
}

export const DraggableListColumn = <T, K = string>({
  render,
  applyDragStyles,
  dragMode = 'drag-handle-if-mobile',
  horizontal,
  controller,
  endAnnotation,
  isDragDisabled,
  groupId,
}: DraggableListColumnProps<T, K>) => {
  let isMobileDevice = useMemo(() => window.Utils?.isTouchDevice(), [])

  const classes = useStyles({ horizontal })

  const dragEnabled = controller.isDragEnabled(groupId)

  const showDragHandle = useMemo(() => {
    if (!dragEnabled) return false
    switch (dragMode) {
      case 'whole-element':
        return false
      case 'drag-handle-if-mobile':
        if (!isMobileDevice) return false
    }
    return true
  }, [dragEnabled, dragMode, isMobileDevice])

  const addContainerDragProps = dragEnabled && !showDragHandle

  const renderChild = useCallback(
    (provided: DraggableProvided, snapshot: DraggableStateSnapshot, itemKey: string, item: T, index: number) => {
      const dragHandle = !showDragHandle ? undefined : <ReorderComponentButton buttonProps={provided.dragHandleProps} />
      const isDragging = itemKey === controller.draggingItem
      return (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...(!addContainerDragProps ? {} : provided.dragHandleProps)}
          className={!isDragging || !applyDragStyles ? undefined : classes.dragging}
        >
          {render(item, {
            index,
            dragHandleProps: provided.dragHandleProps,
            isDragging,
            dragHandle,
          })}
        </div>
      )
    },
    [render, showDragHandle, controller.draggingItem]
  )

  const items = controller.groups[groupId] || []

  return (
    <Droppable droppableId={groupId} direction={horizontal ? 'horizontal' : 'vertical'}>
      {(provided: DroppableProvided, snapshot: DraggableStateSnapshot) => (
        <div className={classes.droppabble} ref={provided.innerRef} {...provided.droppableProps}>
          {items.map((item, index) => {
            const itemKey = controller.getItemKey(item)
            return (
              <Draggable
                key={itemKey}
                draggableId={itemKey}
                index={index}
                isDragDisabled={!dragEnabled || isDragDisabled?.(item)}
              >
                {(provided) => renderChild(provided, snapshot, itemKey, item, index)}
              </Draggable>
            )
          })}
          {provided.placeholder}
          {endAnnotation}
        </div>
      )}
    </Droppable>
  )
}

import { makeStyles, TextField } from '@material-ui/core'
import { memo } from 'react'
import { Theme } from 'types/themes'
import { COLOR_PALETTE } from '../styles'
import { useTranslate } from 'ra-core'

type Props = {
  handleUpdateDeliveryInstructions: (event: React.ChangeEvent<HTMLInputElement>) => void
  value: string
}

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    width: '100%',
    padding: 0,
  },
  title: {
    fontSize: '14px',
    fontWeight: 500,
    color: COLOR_PALETTE.grey,
  },
  subTitle: {
    fontSize: '14px',
    fontWeight: 400,
    color: COLOR_PALETTE.grey,
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}))

const DeliveryInstruction = ({ handleUpdateDeliveryInstructions, value }: Props) => {
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <>
      <div className={classes.titleWrapper}>
        <span className={classes.title}>{translate('Delivery Instructions')}</span>
        <span className={classes.subTitle}>{translate('optional')}</span>
      </div>
      <TextField
        className={classes.textField}
        variant="outlined"
        placeholder={translate('Add additional delivery instructions here')}
        multiline
        rows={4}
        style={{ width: '100%', padding: 0 }}
        onChange={handleUpdateDeliveryInstructions}
      />
    </>
  )
}

export default memo(DeliveryInstruction)

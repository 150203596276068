import { makeStyles } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import React from 'react'
import { FilterComp, SearchInput } from 'react-admin'

type PropTypes = {}

const STATUS_CHOICES = [
  {
    id: 'requested,viewed,cancelled,payment failed,transfer instructions viewed',
    name: 'Outstanding',
  },
  { id: 'expired', name: 'Overdue' },
  { id: 'completed', name: 'Paid' },
  { id: 'cancelled', name: 'Cancelled' },
]

const useStyles = makeStyles<OpenSolarThemeType>((theme) => ({
  searchBar: {
    marginTop: 5,
    margin: 0,
  },
}))

const ProjectsFilter: React.FC<PropTypes> = (props) => {
  const classes = useStyles()

  return (
    <FilterComp {...props}>
      <SearchInput
        source="q"
        placeholder="Search"
        alwaysOn={true}
        options={{ variant: 'outlined' }}
        className={classes.searchBar}
      />
      {/* <SelectInput
        options={{ style: standardStyles.FILTER_FIELD_STYLE }}
        source="payment_type"
        choices={STATUS_CHOICES}
        translateChoice={true}
        label="Payment Type"
      /> */}
    </FilterComp>
  )
}

export default ProjectsFilter

import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIosOutlined'
import { Button } from 'opensolar-ui'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  root: {
    position: 'relative',
    top: '15%',
    margin: 0,
    minWidth: 45,
    maxWidth: 45,
    height: 'fit-content',
    padding: '10px 0',
  },
  disabled: {},
  label: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    textTransform: 'none',
  },
  text: ({ side }: { side: 'left' | 'right' }) => ({
    writingMode: 'vertical-lr',
    transform: side === 'right' ? 'rotate(180deg)' : '',
  }),
  startIcon: {
    margin: 0,
    marginBottom: 5,
  },
}))

export type DrawerPullPropsType = {
  side: 'left' | 'right'
  arrowDirection: 'left' | 'right'
  handleClick(): void
  children: React.ReactNode
  disabled: boolean
  className?: string
}

const DrawerPull = ({ side, arrowDirection, disabled, handleClick, children, className }: DrawerPullPropsType) => {
  const classes = useStyles({ side })
  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        disabled={disabled}
        classes={classes}
        className={className}
        disableRipple={true}
        startIcon={arrowDirection === 'right' ? <ArrowForwardIosIcon /> : <ArrowBackIosOutlinedIcon />}
        onClick={handleClick}
      >
        <span className={classes.text}>{children}</span>
      </Button>
    </div>
  )
}

export default DrawerPull

import { GROUPS } from '../fuji/steps'
import { StepType } from '../types'
import OpenPaymentOptionDialogStep from './AddPaymentOptionDialogStep'
import CardStep from './CardStep'
import PaymentOptionConfiguration from './PaymentOptionConfiguration'
import PaymentOptionDialogStep from './PaymentOptionDialog'
import PaymentsTabStep from './PaymentsTabStep'
import StartConfig from './Start'
import SystemConfigurationStep from './SystemConfiguration'

export const PAGE_ONLY_TOUR_STEPS: StepType[] = [
  {
    step: 'start',
    config: StartConfig,
    page: 'payments',
  },
  {
    step: 'cards',
    config: CardStep,
    page: 'payments',
  },
  {
    step: 'open_payment_option_dialog',
    config: OpenPaymentOptionDialogStep,
    page: 'payments',
  },
  {
    step: 'payment_option_dialog',
    config: PaymentOptionDialogStep,
    page: 'payments',
  },
  {
    step: 'system_configuration',
    config: SystemConfigurationStep,
    page: 'payments',
  },
  {
    step: 'payment_configuration',
    config: PaymentOptionConfiguration,
    page: 'payments',
  },
]

export const FUJI_TOUR_PAYMENTS_TAB_STEPS: StepType[] = [
  {
    step: 'payments_tab',
    config: PaymentsTabStep,
    group: GROUPS[6],
    page: 'design',
  },
  {
    step: 'cards',
    config: CardStep,
    group: GROUPS[6],
    page: 'payments',
  },
  {
    step: 'open_payment_option_dialog',
    config: OpenPaymentOptionDialogStep,
    group: GROUPS[6],
    page: 'payments',
  },
  {
    step: 'payment_option_dialog',
    config: PaymentOptionDialogStep,
    group: GROUPS[6],
    page: 'payments',
  },
  {
    step: 'system_configuration',
    config: SystemConfigurationStep,
    group: GROUPS[6],
    page: 'payments',
  },
  {
    step: 'payment_configuration',
    config: PaymentOptionConfiguration,
    group: GROUPS[6],
    page: 'payments',
  },
]

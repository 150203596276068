import type LineItemType from 'pages/ordering/OrderLineItem'
import type { ProjectOrderDetail } from 'pages/ordering/type'
import type { DataProviderProxy, Identifier } from 'react-admin'
import ProjectOrderPresenter from '../ProjectOrderPresenter/projectOrderPresenter'
import type {
  BasicComponentLineItemType,
  HardwareOrderProjectRecord,
  HardwareSupplierFilterKeyType,
  SystemDesignType,
} from '../type'
import type { SystemSelectDropdownOptionType } from './table/SystemSelectDropdown'
import type { BomDataResponseType, DownloadBomFileType } from './table/viewBom/type'
import type { ProjectTableStore, SelectedSystemMapType } from './useProjectTable'

export class ProjectTablePresenter {
  private updateProjectTableStore: React.Dispatch<React.SetStateAction<ProjectTableStore>>
  private orderingPresenter: ProjectOrderPresenter
  private dataProvider: DataProviderProxy
  constructor(
    updateProjectTableStore: React.Dispatch<React.SetStateAction<ProjectTableStore>>,
    orderingPresenter: ProjectOrderPresenter,
    dataProvider: DataProviderProxy
  ) {
    this.updateProjectTableStore = updateProjectTableStore
    this.orderingPresenter = orderingPresenter
    this.dataProvider = dataProvider
  }

  onSelect = async (newIds: Identifier[]) => {
    // disabled
  }

  onToggle = async ({
    projectId,
    systemId,
    record,
    selectedSupplierKey,
  }: {
    projectId: number
    systemId: number
    record: HardwareOrderProjectRecord
    selectedSupplierKey: HardwareSupplierFilterKeyType
  }) => {
    const lineItems = this.orderingPresenter
      .getLineItemsBySystemId(systemId)
      .filter((lineItem: LineItemType) => !lineItem.confirmed)

    if (lineItems.length > 0) {
      this.orderingPresenter.deleteUnconfirmedLineItemsBySystemId(systemId)
    } else {
      this.populateLineItemsBySystemId({ projectId, systemId, systemsDesign: record.systems, selectedSupplierKey })
    }
  }

  populateLineItemsBySystemId = ({
    selectedSupplierKey,
    systemsDesign,
    systemId,
    projectId,
  }: {
    selectedSupplierKey: HardwareSupplierFilterKeyType
    systemsDesign?: SystemDesignType[]
    systemId: number
    projectId: number
  }) => {
    const systemDesign = systemsDesign?.find((system) => system.id === systemId)
    if (!systemDesign) {
      return
    }
    const lineItems = this.orderingPresenter.getLineItemsFromSystemDesignData({
      selectedSupplierKey,
      systemDesign,
      projectId,
      systemId,
    })
    this.orderingPresenter.addLineItems(lineItems)
  }

  static getSystemDisplayName = (system: SystemDesignType): string => {
    // Multi-modules not supported in one system
    const panels: BasicComponentLineItemType = system.modules[0]

    return system.name || `${panels?.quantity || 0} Panels System`
  }

  getSystemSelectOptions = (systems?: SystemDesignType[]): SystemSelectDropdownOptionType[] => {
    return (
      systems?.map((system: SystemDesignType) => ({
        id: system.id,
        name: ProjectTablePresenter.getSystemDisplayName(system),
      })) || []
    )
  }

  populateSelectedSystemMap = (lineItems: LineItemType[]) => {
    const newSelectedSystemMap: SelectedSystemMapType = lineItems.reduce((hashMap, lineItem) => {
      lineItem.projectOrder?.forEach((detail: ProjectOrderDetail) => {
        hashMap[detail.projectId] = detail.systemId
      })
      return hashMap
    }, {})

    this.updateProjectTableStore((projectTableState: ProjectTableStore) => {
      return {
        ...projectTableState,
        selectedSystemMap: newSelectedSystemMap,
      }
    })
  }

  handleSelectSystem = ({
    systemId,
    projectId,
    currentSystemId,
    record,
    selectedSupplierKey,
  }: {
    systemId: number
    projectId: number
    currentSystemId: number | undefined
    record: HardwareOrderProjectRecord
    selectedSupplierKey: HardwareSupplierFilterKeyType
  }) => {
    this.updateProjectTableStore((projectTableState: ProjectTableStore) => {
      const newSelectedSystemMap = { ...projectTableState.selectedSystemMap, [projectId]: systemId }
      return {
        ...projectTableState,
        selectedSystemMap: newSelectedSystemMap,
      }
    })

    if (currentSystemId != null) {
      const lineItems = this.orderingPresenter
        .getLineItemsBySystemId(currentSystemId)
        .filter((lineItem: LineItemType) => !lineItem.confirmed)

      if (lineItems.length > 0) {
        this.orderingPresenter.deleteUnconfirmedLineItemsBySystemId(currentSystemId)
      }
      this.populateLineItemsBySystemId({ projectId, systemId, systemsDesign: record.systems, selectedSupplierKey })
    }
  }

  getSystemSelectedId = ({
    projectId,
    soldSystemId,
    selectedSystemMap,
    systemOptions,
  }: {
    projectId: number
    soldSystemId?: number
    selectedSystemMap: SelectedSystemMapType
    systemOptions: SystemSelectDropdownOptionType[]
  }): number | undefined => {
    const firstDefaultSystem = systemOptions.length === 1 ? systemOptions[0].id : undefined
    return selectedSystemMap[projectId] ?? soldSystemId ?? firstDefaultSystem
  }

  getBomData = async <T extends DownloadBomFileType>({
    projectId,
    selectedSystemId,
    fileType,
    pricingType,
  }: {
    projectId: number
    selectedSystemId: number
    fileType: T
    pricingType: string
  }): Promise<BomDataResponseType<T> | undefined> => {
    try {
      const response = await this.dataProvider.CUSTOM_GET('projects', {
        url: `projects/${projectId}/systems/${selectedSystemId}/download_bom/?file_type=${fileType}&pricing_type=${pricingType}`,
      })
      return response.data
    } catch (e) {
      console.warn(e)
      return
    }
  }
}

export default ProjectTablePresenter

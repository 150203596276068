import DateInput from 'elements/input/DateInput'
import { styled } from 'opensolar-ui'

const DateInputWrapper = styled('div', { name: 'DateInputWrapper' })({
  width: '100%',
  margin: '8px 4px',
})
const StyledDateInput = (props) => {
  return (
    <DateInputWrapper>
      <DateInput {...props} fullWidth={true} />
    </DateInputWrapper>
  )
}
export default StyledDateInput

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orgSelectors } from 'ducks/orgs'
import ProjectName from 'elements/ProjectName'
import { Checkbox } from 'opensolar-ui'
import { getDistributorConfigByEnum } from 'pages/ordering/configs'
import { useNotify } from 'ra-core'
import React, { memo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { STAGE_CHOICES } from 'resources/projects/choices'
import { SelectDropDown } from 'resources/projects/projectsList/SelectDropDown'
import type { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import type { HardwareOrderProjectRecord, HardwareSupplierFilterKeyType } from '../../type'
import type { ProjectTablePresenter } from '../projectTablePresenter'
import type { ProjectTableStore } from '../useProjectTable'
import ContactsCell from './ContactsCell'
import InstallationDataInput from './InstallationDataInput'
import OrderCell from './OrderCell'
import SystemSelectDropdown from './SystemSelectDropdown'
import type { BomDataResponseType, DownloadBomFileType, DownloadBomPricingType } from './viewBom/type'
import ViewBomButton from './viewBom/ViewBomButton'

type ProjectTableRowPropsType = {
  selectedHardwareSupplierKey: HardwareSupplierFilterKeyType
  record: HardwareOrderProjectRecord
  checked: boolean
  projectTableStore: ProjectTableStore
  projectTablePresenter: ProjectTablePresenter
}

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  tableRow: {
    height: '62px',
  },
}))

const ProjectTableRow = ({
  checked,
  record,
  projectTableStore,
  projectTablePresenter,
  selectedHardwareSupplierKey,
}: ProjectTableRowPropsType) => {
  const classes = useStyles()
  const notify = useNotify()
  const calcErrors = record.last_calculation_error && Object.keys(record.last_calculation_error).length > 0
  const systemOptions = projectTablePresenter.getSystemSelectOptions(record.systems)
  const selectedSystemId = projectTablePresenter.getSystemSelectedId({
    projectId: record.id,
    soldSystemId: record.system_sold_id,
    selectedSystemMap: projectTableStore.selectedSystemMap,
    systemOptions,
  })
  const supplier = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const selectedDistributorKey = getDistributorConfigByEnum(supplier)?.filterKey as string

  const handleToggleCheckbox: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation()
    if (!selectedSystemId) {
      return
    }

    if (calcErrors && !checked) {
      notify(
        'This project cannot be selected for review as the BOM may not be fully updated due to calculation error. Please review the design before ordering.',
        'error'
      )
      return
    }

    projectTablePresenter.onToggle({
      selectedSupplierKey: selectedHardwareSupplierKey,
      projectId: record.id,
      systemId: selectedSystemId,
      record,
    })

    logAmplitudeEvent('hardware_project_checkbox_toggled', {
      activate: checked ? 'off' : 'on',
    })
  }

  const onSelectSystemDropdown = useCallback(
    (systemId: number) => {
      projectTablePresenter.handleSelectSystem({
        selectedSupplierKey: selectedHardwareSupplierKey,
        currentSystemId: selectedSystemId,
        systemId,
        projectId: record.id,
        record,
      })
    },
    [record.id, selectedSystemId]
  )

  const getBomData = useCallback(
    <T extends DownloadBomFileType, W extends DownloadBomPricingType>(
      fileType: T,
      pricingType: W
    ): Promise<BomDataResponseType<T> | undefined> | undefined => {
      if (selectedSystemId == null) {
        return
      }
      const pricing: string = pricingType === 'distributor' ? selectedDistributorKey : pricingType
      return projectTablePresenter.getBomData({
        projectId: record.id,
        pricingType: pricing,
        selectedSystemId,
        fileType,
      })
    },
    [selectedSystemId, record.id]
  )

  return (
    <TableRow className={classes.tableRow} data-testid="order-by-project-row">
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={checked}
          disabled={systemOptions.length === 0 || !selectedSystemId}
          onClick={handleToggleCheckbox}
          id="hardware-ordering-project-select"
        />
      </TableCell>
      <TableCell>
        <ProjectName business_name={record.business_name} address={record.address} />
      </TableCell>
      <TableCell>
        <ContactsCell contactsData={record.contacts_data} />
      </TableCell>
      <TableCell>
        <SystemSelectDropdown
          selectedSystemId={selectedSystemId}
          systemOptions={systemOptions}
          onSelect={onSelectSystemDropdown}
        />
      </TableCell>
      <TableCell>
        <SelectDropDown choices={STAGE_CHOICES} source="stage" submitSource="" record={record} />
      </TableCell>
      <TableCell>
        <InstallationDataInput
          projectId={record.id}
          installationDate={record.installation_date}
          trackSource="hardware_installation_date_updated"
        />
      </TableCell>
      <TableCell>
        <OrderCell orders={record.orders} />
      </TableCell>
      <TableCell>
        <ViewBomButton disabled={!selectedSystemId} getBomData={getBomData} />
      </TableCell>
    </TableRow>
  )
}

export default memo(ProjectTableRow)

import { authSelectors } from 'ducks/auth'
import Alert from 'elements/Alert'
import React from 'react'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import IntegrationRoleConnectionButtons from './IntegrationRoleConnectionButtons'

const DividendButtonWrapper: React.FunctionComponent = () => {
  const loggedInRole = useSelector(authSelectors.getCurrentRole)
  const form = useForm()
  const isLoggedInRole = loggedInRole?.id === form.getState().values?.id

  return (
    <>
      <h2>Dividend Integration</h2>
      {!isLoggedInRole && (
        <Alert severity="info">
          The Dividend buttons below will affect this user you are currently editing. It will not affect the user you
          are currently logged in as.
        </Alert>
      )}

      <IntegrationRoleConnectionButtons integration="dividend" />
    </>
  )
}
export default DividendButtonWrapper

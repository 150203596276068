import { Typography } from 'opensolar-ui'
import { useTranslate } from 'ra-core'

export const ErrorHelperText = ({ showText }) => {
  const translate = useTranslate()
  return (
    <div style={{height: '15px', paddingTop: '4px'}}>
      {showText === true && (
        <Typography textVariant="helper1" colorHex="#F44336">
          {translate('This field is required')}
        </Typography>
      )}
    </div>
  )
}

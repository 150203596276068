import { makeStyles, Tooltip } from '@material-ui/core'
import { Button, IconButton } from 'opensolar-ui'

import AddIcon from '@material-ui/icons/AddOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import InverterDetailPopover from 'projectSections/sections/design/systems/tabs/inverter/InverterDetailPopover'
import React from 'react'
import { useTranslate } from 'react-admin'
import { trimDecimalPlaces } from '../util/misc'

const styles = {
  button: {
    minWidth: 40,
    // backgroundColor: 'rgba(255, 255, 255, 0.8)',
    margin: '5px 5px 5px 0px',
  },
  buttonLabel: {
    minWidth: 32,
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: 12,
  },
}

export const ToolsBatteries = ({ plusOnClick, ...otherProps }) => {
  const translate = useTranslate()
  return (
    <div>
      <Tooltip PopperProps={{ style: { zIndex: 2001 } }} title={translate('Add Battery')} enterDelay={300}>
        <Button
          // style={styles.button}
          // labelWrapperStyle={styles.buttonLabel}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            plusOnClick()
          }}
          variant="contained"
          color="default"
          startIcon={<AddIcon />}
          {...otherProps}
        >
          <span>{translate('Battery')}</span>
        </Button>
      </Tooltip>
    </div>
  )
}

export const ToolsBattery = (props) => {
  return (
    <IconButton
      style={styles.button}
      labelStyle={styles.buttonLabel}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        window.editor.deleteObject(props.object)
      }}
    >
      {<DeleteIcon style={{ color: '#000000' }} />}
    </IconButton>
  )
}

export const ToolsOthers = ({ plusOnClick, ...otherProps }) => {
  const translate = useTranslate()
  return (
    <div>
      <Tooltip PopperProps={{ style: { zIndex: 2001 } }} title={translate('Add Component')} enterDelay={300}>
        <Button
          // style={styles.button}
          // labelWrapperStyle={styles.buttonLabel}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            plusOnClick()
          }}
          variant="contained"
          color="default"
          startIcon={<AddIcon />}
          {...otherProps}
        >
          <span>{translate('Component')}</span>
        </Button>
      </Tooltip>
    </div>
  )
}

export const ToolsOther = (props) => (
  <IconButton
    style={styles.button}
    labelStyle={styles.buttonLabel}
    onClick={(e) => {
      e.preventDefault()
      e.stopPropagation()
      window.editor.deleteObject(props.object)
    }}
  >
    {<DeleteIcon style={{ color: '#000000' }} />}
  </IconButton>
)

export const ToolsInverters = ({ plusOnClick, disabled = false }) => {
  const translate = useTranslate()
  return (
    <Tooltip PopperProps={{ style: { zIndex: 2001 } }} title={translate('Add Inverter')} enterDelay={300}>
      <Button
        style={{ minWidth: 40, margin: '20px 5px 5px 0px' }}
        id="studioAddInverterButton"
        // labelWrapperStyle={styles.buttonLabel}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          plusOnClick()
        }}
        disabled={disabled}
        variant="contained"
        color="default"
        startIcon={<AddIcon />}
        fullWidth={true}
      >
        <span>{translate('Inverter')}</span>
      </Button>
    </Tooltip>
  )
}

export const ToolsInverter = (props) => {
  const translate = useTranslate()
  var target = window.editor.objectByUuid(props.object.uuid)

  if (!target) {
    return null
  }
  return (
    <div>
      {props.isMicroInverter ? (
        <Button
          style={{ ...styles.button, margin: 0 }}
          disabled={!props.allowEdit}
          // labelWrapperStyle={styles.buttonLabel}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            props.plusOnClick()
          }}
          startIcon={props.object.mppts().length > 0 && <AddIcon />}
          variant="contained"
          color="default"
          size="small"
        >
          <span>{props.object.mppts().length > 0 ? translate('Branch') : translate('Cables (Optional)')}</span>
        </Button>
      ) : (
        <Button
          style={{ ...styles.button, margin: 0 }}
          // labelWrapperStyle={styles.buttonLabel}
          disabled={!props.allowEdit}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            props.plusOnClick()
          }}
          variant="contained"
          color="default"
          size="small"
          // label={props.plusLabel}
        >
          <span style={{ textTransform: 'none' }}>{props.plusLabel}</span>
        </Button>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  wrapper: { margin: '10px 0', display: 'flex', justifyContent: 'space-between', fontSize: 10 },
}))

const getMicroInverterQuantity = (moduleQuantity, inverter) => {
  return Math.ceil(moduleQuantity / inverter.mppt_quantity)
}

export const InverterRatio = (props) => {
  const translate = useTranslate()
  const classes = useStyles()
  var target = window.editor.objectByUuid(props.object.uuid)
  const hasDcOptimizer = Boolean(props?.system?.dcOptimizer())
  if (!target) {
    return null
  }

  var dcAcRatio = target.calculateDcAcRatio()
  return (
    <div className={classes.wrapper}>
      <div>
        <div>DC/AC: {dcAcRatio ? trimDecimalPlaces(dcAcRatio, 2) : '---'}</div>
        {props.isMicroInverter && (
          <div>
            {translate('Quantity')}:{' '}
            {getMicroInverterQuantity(props.object.moduleQuantity(), props.object) ||
              getMicroInverterQuantity(
                props.system.moduleQuantity() - props.system.getStrungModules().length,
                props.object
              )}
          </div>
        )}
      </div>
      {!hasDcOptimizer && (
        <div>
          <span>{translate('Inverter')}</span>
          <InverterDetailPopover inverter={props.object} />
        </div>
      )}
    </div>
  )
}

export const ToolsMppt = (props) => {
  const translate = useTranslate()
  return (
    <div>
      {!props.isMicroInverter && (
        <IconButton
          style={styles.button}
          labelStyle={styles.buttonLabel}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            window.editor.deleteObject(props.object)
          }}
        >
          {<DeleteIcon style={{ color: '#000000' }} />}
        </IconButton>
      )}
      <Button
        style={styles.button}
        // labelWrapperStyle={styles.buttonLabel}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          props.plusOnClick()
        }}
        variant="contained"
        color="default"
        startIcon={<AddIcon />}
      >
        <span>{props.isMicroInverter ? translate('Circuit') : translate('String')}</span>
      </Button>
    </div>
  )
}

import Typography from '@material-ui/core/Typography'
import Tooltip from 'elements/tooltip/Tooltip'
import React, { memo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import type { ContactType } from 'types/contacts'
import { NORMAL_CONTACT } from 'types/contacts'

const useStyles = makeOpenSolarStyles((theme) => ({
  container: { margin: 0, padding: 0 },
  contactWrapper: { clear: 'both', marginRight: 5 },
  displayContact: { textDecoration: 'underline', cursor: 'default' },
}))

const TypographyWithLabel = ({ label, value }: { label: string; value: string | null }) => {
  if (!value) {
    return null
  }
  return (
    <Typography variant="subtitle2" display="block" noWrap={true}>
      {label}:{' '}
      <Typography variant="body1" component="span">
        {value}
      </Typography>
    </Typography>
  )
}

const TooltipContent = memo(({ contact }: { contact: ContactType }) => {
  return (
    <div>
      <TypographyWithLabel label={'First Name'} value={contact.first_name} />
      <TypographyWithLabel label={'Family Name'} value={contact.family_name} />
      <TypographyWithLabel label={'Email'} value={contact.email} />
      <TypographyWithLabel label={'Phone'} value={contact.phone} />
    </div>
  )
})

const ContactsCell = ({ contactsData }: { contactsData: ContactType[] | undefined }) => {
  const classes = useStyles()
  if (contactsData == null || contactsData.length === 0) {
    return <div>-</div>
  }
  return (
    <div className={classes.container}>
      {contactsData
        .filter((contact) => contact.type === NORMAL_CONTACT)
        .map((contact) => {
          return (
            <div className={classes.contactWrapper} key={contact.id}>
              <Tooltip
                title={<TooltipContent contact={contact} />}
                interactive={true}
                arrow
                placement="top"
                enterDelay={200}
              >
                <Typography variant="body1" component="span" className={classes.displayContact}>
                  {contact ? contact.display : null}
                </Typography>
              </Tooltip>
            </div>
          )
        })}
    </div>
  )
}

export default memo(ContactsCell)

import { ConditionFactory, SystemContext } from 'Designer/designRules/types'
import { BaseConditionDef } from '.'
import { findComponentIn } from '../utils'

export type ComponentActiveConditionDef = BaseConditionDef & {
  type: 'component-active'
  code: string
}

export const condition_componentActive: ConditionFactory<ComponentActiveConditionDef> = (def) => {
  return {
    getProjectFields: () => [],
    getStudioSignals: () => [],
    check: (context: SystemContext) => {
      return !!findComponentIn(context.activeComponents.getAll(), def.code)
    },
  }
}

import { Grid, Typography } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { Button } from 'opensolar-ui'
import { getSupplierConfig } from 'pages/ordering/configs'
import { HardwareSupplierEnum, HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { memo, useCallback } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import useDistributorAuthenticationStatus from 'services/useDistributorAuthenticationStatus'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import HardwareStandardBannerContainer from '../HardwareStandardBannerContainer'
import OrderByProjectBanner from './OrderByProjectBanner'

const useHdmBannerStyles = makeOpenSolarStyles((theme) => ({
  container: {
    padding: 20,
  },
  partner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  badge: {
    width: 110,
    background: theme.themeColorLight,
    padding: '3px 10px',
    borderRadius: '12px',
  },
  img: {
    maxWidth: 100,
  },
  link: { color: theme.blue, textDecoration: 'underline', cursor: 'pointer' },
}))

const VtacConnectBanner = () => {
  const classes = useHdmBannerStyles()
  const history = useHistory()

  const handleLearnMoreClicked = useCallback(() => {
    window.open('https://pages.opensolar.com/partners/v-tac', '_blank', 'noreferrer')
  }, [])

  const handleClickConnect = () => {
    history.push('/shop/connect_distributor/vtac/email')
  }

  const handleClickRegisterLink = () => {
    history.push('/shop/connect_distributor/vtac/email')
    logAmplitudeEvent('hardware_banner_register_click', { supplier: 'vtac' })
  }

  return (
    <Grid container className={classes.container} wrap={'wrap'} spacing={3}>
      <Grid item xs={12} sm={2} container alignItems="center" justify="center" direction="column">
        <div style={{ maxWidth: 120 }}>
          <img
            className={classes.img}
            alt="supplier-logo"
            src={getSupplierConfig(HardwareSupplierEnum.VtacUk)?.logoUrl || ''}
          />
        </div>
        <div className={classes.badge}>
          <Typography align="center" variant="caption">
            OpenSolar Partner
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} sm={7}>
        <div>
          <Typography variant="h6" align="center" gutterBottom>
            Connect your hardware supplier V-Tac
          </Typography>
          <Typography align="center" variant="body1" gutterBottom>
            Connect your V-Tac account to OpenSolar to view trade discounts and order using your credit balance.
          </Typography>
          <Typography align="center" variant="body1" className={classes.link} onClick={handleLearnMoreClicked}>
            Learn more
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} sm={3} container alignItems="center" justify="center" direction="column">
        <Button onClick={handleClickConnect} variant="contained" color="primary" fullWidth>
          Connect V-Tac
        </Button>
        <Typography variant="caption" align="center">
          OR
        </Typography>
        <Button onClick={handleClickRegisterLink} variant="outlined" color="primary" fullWidth>
          Register to View Pricing
        </Button>
      </Grid>{' '}
    </Grid>
  )
}

const VtacBanner = ({
  renderOBPBanner = true,
  distributor = 'vtac_uk',
}: {
  renderOBPBanner?: boolean
  distributor?: HardwareSupplierFilterKeyType
}) => {
  const orgId = useSelector(authSelectors.getOrgId) || 0
  const { isAuthenticated, isChecking } = useDistributorAuthenticationStatus({ orgId, distributor })

  if (isChecking) {
    return (
      <HardwareStandardBannerContainer>
        <Skeleton height={'104px'} />
      </HardwareStandardBannerContainer>
    )
  }

  if (isAuthenticated && !renderOBPBanner) return null

  if (!isAuthenticated)
    return (
      <HardwareStandardBannerContainer>
        <VtacConnectBanner />
      </HardwareStandardBannerContainer>
    )

  return <OrderByProjectBanner />
}

export default memo(VtacBanner)

import { JsonSchema, UISchemaElement } from '@jsonforms/core'
import { MentionContexts } from 'elements/wysiwyg/constants'
import { EditorPlaceholderCategory } from 'elements/wysiwyg/types'

export enum FormPlacementEnum {
  'info_page' = 'info_page',
  'manage_page' = 'manage_page',
  'before_document_generation' = 'before_document_generation',
  'energy_page' = 'energy_page',
  'before_contract' = 'before_contract',
  'designpanel_mounting_button' = 'designpanel_mounting_button',
  'role_form' = 'role_form',
  'contact_form' = 'contact_form',
  'info_page_contact' = 'info_page_contact',
  'payment_option_form' = 'payment_option_form',
  'payment_option_form_cash' = 'payment_option_form_cash',
  'payment_option_form_loan' = 'payment_option_form_loan',
  'payment_option_form_ppa' = 'payment_option_form_ppa',
  'payment_option_form_regular' = 'payment_option_form_regular',
  'payment_option_form_lease' = 'payment_option_form_lease',
}

export enum CustomFormTypeEnum {
  'project' = 'project',
  'system_project' = 'system_project',
  'role' = 'role',
  'contact' = 'contact',
  'payment_option' = 'payment_option',
}

export type FormPlacementType = {
  id?: number
  priority: number
  icon?: string
  form_location: FormPlacementEnum
  start_open?: boolean
  staff_only?: boolean
  contract_template_id?: number
  document_template_id?: number
  view_data: {
    hide_during_create?: boolean
  }
}

export interface CustomFormRecordType {
  is_archived: boolean
  id: number
  configuration: undefined | Object
  title: string
  org: string | undefined
  url: string
  placements: FormPlacementType[]
  orgs_exclude: string
  countries_include: string
  countries_exclude: string
  parent_form: string | undefined
  is_public: boolean
}

// This is the representation of a custom form which comes through in the org payload
export interface CustomFormUsageType {
  id: number
  type: CustomFormTypeEnum
  title: string
  configuration: CustomFormConfigurationType
  placements: FormPlacementType[]
}

export type CustomFormConfigurationType = {
  schema?: JsonSchema
  scopes?: {
    [scope: string]: {
      path?: string
      schema?: JsonSchema
      default?: object
    }
  }
  uiSchema: UISchemaElement
  transforms?: string[]
  header?: string
  header_subtext?: string
  placeholders?: CustomFormPlaceholderCategory[]
}

export type CustomFormPlaceholderCategory = EditorPlaceholderCategory & {
  contexts: MentionContexts[]
}

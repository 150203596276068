import { makeStyles } from '@material-ui/core'
import DialogEditor from 'projectSections/elements/DialogEditor'
import React, { useCallback, useContext } from 'react'
import { useForm } from 'react-final-form'
import { useHistory, useLocation } from 'react-router-dom'
import { UtilityTariffDialogContext } from '../index'
import CreateTariff from './CreateTariff'
import EditTariff from './EditTariff'

const useStyles = makeStyles(
  (theme) => ({
    dialog: {
      // let it be transparent
      // background: '#ffffff',
    },
  }),
  { name: 'utility-tariff-dialog' }
)

const UtilityTariffDialog = () => {
  const { state, dispatch } = useContext(UtilityTariffDialogContext)
  const { isOpen, basePath, mode, resource, id } = state
  const location = useLocation()
  const form = useForm()
  const history = useHistory()
  const closeDialog = useCallback(() => {
    history.push(location.pathname)
    dispatch({ type: 'clear' })
  }, [])

  const classes = useStyles()
  // console.log(state)
  return (
    <DialogEditor
      //@ts-ignore
      isOpen={isOpen}
      id={id}
      options={{ maxWidth: 'xl', className: classes.dialog }}
      handleClose={closeDialog}
      title={null}
      editorComponent={React.createElement(mode === 'create' ? CreateTariff : EditTariff)}
    />
  )
}
export default UtilityTariffDialog

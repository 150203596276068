import { useFeatureFlag } from 'util/split'
import HardwareMenu from './HardwareMenu'

export const useInventoryZoneMenu = () => {
  const enableInventoryManagement = useFeatureFlag('inventory_management', 'on')
  if (enableInventoryManagement) {
    return HardwareMenu
  }
  // hide menu if inventory management not enabled
  return null
}

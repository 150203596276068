import { InputWrapper } from 'projectSections/sections/manage3/styles'
import React from 'react'
import { TextInput, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import ChipArrayInput from '../../../../common/ChipsArrayInput'

const NotesContent: React.FC = (props) => {
  const form = useForm()
  const translate = useTranslate()
  const formValues = useFormState().values
  return (
    <InputWrapper>
      <TextInput source={'notes'} variant="outlined" label={''} multiline rows={3} fullWidth />
      <ChipArrayInput
        source={'notify_team_members'}
        resource={'team_members'}
        reference={'roles'}
        optionText="display"
        optionValue="id"
        chipsInputProps={{
          source: 'notify_team_members',
          options: {
            placeholder: !!formValues.notify_team_members?.length ? undefined : 'Select a team member',
            label: translate('Notify'),
            variant: 'outlined',
          },
        }}
        onChange={(values) => {
          form.change(
            'notify_team_members',
            values.map((x) => x.id)
          )
        }}
      />
    </InputWrapper>
  )
}
export default NotesContent

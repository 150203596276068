// @ts-nocheck
import { Dialog, DialogContent, makeStyles, useMediaQuery } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { getAvailableActions, markAsSold, openCreditDecisionDialog } from 'ducks/myEnergy'
import LoadingDots from 'layout/widgets/LoadingDots'
import React, { useEffect, useState } from 'react'
import { SimpleForm, useTranslate } from 'react-admin'
import { useDispatch } from 'react-redux'
import restClient from 'restClient'
import { ProjectType } from 'types/projects'
import { getCommonCreditDecision, getFirstRealContact } from 'util/misc'
import LoanpalApplicationDialogFooter from './LoanpalApplicationDialogFooter'
import LoanpalApplicationForm from './LoanpalApplicationForm'
import LoanpalDisclosureSection from './LoanpalDisclosureSection'
import { COBORROWER_PREFIX, LOANPAL_OR_GOODLEAP } from './constants'
import { DisclosureSectionType, DisclosureType, LoanpalDecisionType } from './types'

var restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  open: boolean
  onClose: () => void
  disclosures: DisclosureType[]
  project: ProjectType
  paymentOptionId: number
  systemUuid: string
  orgId: number
  onDecisionRendered: () => void
  isFullWidth: boolean
}

const useStyles = makeStyles({
  dialog: {
    '& .MuiDialog-paper': {
      position: 'relative',
    },
  },
})

const LoanpalApplicationDialog: React.FunctionComponent<PropTypes> = (props) => {
  const [disclosures, setDisclosures] = useState<DisclosureType[]>([])
  const [invalidDisclosures, setInvalidDisclosures] = useState<string[]>([])
  const [awaitingPrimarySignature, setAwaitingPrimarySignature] = useState<boolean>(false)
  const [primarySignatureData, setPrimarySignatureData] = useState<string | undefined>(undefined)
  const [indexOfPrimarySignature, setIndexOfPrimarySignature] = useState<number | undefined>(undefined)
  // at the moment we don't need this. This is just future proofing against LP maybe some day deciding to require a signature from the co-app
  const [awaitingSecondarySignature, setAwaitingSecondarySignature] = useState<boolean>(false)
  const [secondarySignatureData, setSecondarySignatureData] = useState<string | undefined>(undefined)

  const [loading, setLoading] = useState<boolean>(false)
  const [decision, setDecision] = useState<LoanpalDecisionType | undefined>(undefined)
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)

  const translate = useTranslate()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery('(max-width:800px)')

  useEffect(() => {
    logAmplitudeEvent('integrated_finance_application_opened', {
      integration: 'loanpal',
      project_id: props.project?.id,
      payment_option_id: props.paymentOptionId,
    })
  }, [])

  useEffect(() => {
    if (!props.project?.contacts || props.project?.contacts?.length === 0) {
      setErrorMsg('Please add a customer to this project before submitting the application')
    }
  }, [props.project?.contacts])

  useEffect(() => {
    if (props.disclosures) {
      const invalidSections = [] as string[]
      const validDisclosures = props.disclosures.filter((disclosure) => {
        if (!disclosure || !disclosure.current || !disclosure.tokenExpiresAt) return false
        const now = new Date()
        const exp = new Date(disclosure.tokenExpiresAt)
        if (now.getTime() - exp.getTime() > 0) {
          setErrorMsg('Your session has expired. Please reload the page and start your application again')
          return false
        } else return true
      })
      if (validDisclosures && validDisclosures.length > 0) {
        validDisclosures.forEach((disclosure, disclosureIndex: number) => {
          disclosure.sections = disclosure.sections.sort(
            (a: DisclosureSectionType, b: DisclosureSectionType) => a.sort - b.sort
          )
          let signatureCount = 0
          disclosure.sections.forEach((section, i: number) => {
            if (section.checkbox) invalidSections.push(`${disclosureIndex}-${section.sort}`)
            if (section.signatureBox) {
              if (signatureCount === 0) {
                setAwaitingPrimarySignature(true)
                setIndexOfPrimarySignature(i)
                signatureCount = 1
              } else setAwaitingSecondarySignature(true)
            }
          })
        })
      }
      setDisclosures(validDisclosures)
      setInvalidDisclosures(invalidSections)
    }
  }, [props.disclosures])

  useEffect(() => {
    if (decision) {
      props.onDecisionRendered()
      if (decision === 'APPROVED' || decision === 'CONDITIONAL' || decision === 'APPROVED_COUNTER_OFFER') {
        dispatch(markAsSold(props.systemUuid, props.paymentOptionId))
      }
      // wait a bit, then go fetch new customer actions
      setTimeout(() => {
        dispatch(getAvailableActions(props.orgId, props.project.id))
      }, 500)
    }
  }, [decision])

  const getInitialValues = () => {
    if (!props.project) return {}
    // get primary contact's info
    let primaryContact = undefined
    if (props.project.contacts_data?.length) {
      primaryContact = getFirstRealContact(props.project.contacts_data)
    } else {
      primaryContact =
        props.project.contacts && props.project.contacts.length > 0 ? props.project?.contacts[0] : undefined
    }
    const first_name = primaryContact ? primaryContact.first_name : undefined
    const family_name = primaryContact ? primaryContact.family_name : undefined

    // get secondary contact's info if there is one
    const secondaryContact =
      props.project.contacts && props.project.contacts.length > 1 ? props.project?.contacts[1] : undefined

    return {
      first_name: first_name,
      family_name: family_name,
      address: props.project.address,
      locality: props.project.locality,
      state: props.project.state,
      zip: props.project.zip,
      email: primaryContact ? primaryContact.email : undefined,
      phone_number: primaryContact ? primaryContact.phone : undefined,
      autopay: 'yes',
      [`${COBORROWER_PREFIX}first_name`]: secondaryContact ? secondaryContact.first_name : undefined,
      [`${COBORROWER_PREFIX}family_name`]: secondaryContact ? secondaryContact.family_name : undefined,
      [`${COBORROWER_PREFIX}email`]: secondaryContact ? secondaryContact.email : undefined,
      [`${COBORROWER_PREFIX}phone_number`]: secondaryContact ? secondaryContact.phone : undefined,
    }
  }

  const markDisclosureAsValid = (i: string) => {
    if (invalidDisclosures.includes(i)) {
      setInvalidDisclosures(invalidDisclosures?.filter((sectionKey) => sectionKey !== i))
    }
  }

  const markAsInvalid = (i: string) => {
    if (!invalidDisclosures.includes(i)) setInvalidDisclosures([...invalidDisclosures, i])
  }

  const markPrimarySignatureAsComplete = (signatureData: string) => {
    setPrimarySignatureData(signatureData)
  }

  const markSecondarySignatureAsComplete = (signatureData: string) => {
    setSecondarySignatureData(signatureData)
  }

  const getValidationError = () => {
    if (invalidDisclosures && invalidDisclosures.length > 0) {
      return translate('Please read and agree to the disclosures')
    } else if (awaitingPrimarySignature && !primarySignatureData) {
      return translate('Please sign your name at the bottom of the form')
    } else if (awaitingSecondarySignature && !secondarySignatureData) {
      return translate('Please sign your name at the bottom of the form in the second signature box')
    }
    return undefined
  }

  const submitApplication = (formVals: any) => {
    let error: string | undefined = getValidationError()
    if (error) {
      logAmplitudeEvent('integrated_finance_application_form_error', {
        integration: 'loanpal',
        error: error,
        project_id: props.project?.id,
        payment_option_id: props?.paymentOptionId,
      })
      setErrorMsg(error)
      return
    }
    setLoading(true)
    // @ts-ignore
    let token: undefined | string = undefined
    props.disclosures.forEach((disc) => {
      if (!token && disc.token) token = disc.token
    })
    formVals['disclosureToken'] = token
    restClientInstance('CUSTOM_POST', 'custom', {
      url:
        'orgs/' +
        props.orgId +
        '/projects/' +
        props.project?.id +
        '/systems/' +
        props.systemUuid +
        '/' +
        props.paymentOptionId +
        '/loanpal_application/',
      data: {
        ...formVals,
        primary_signature_data: primarySignatureData,
        secondary_signature_data: secondarySignatureData,
        income: formVals.income ? formVals.income.replace(/[^0-9.,$]/g, '') : undefined,
        coborrower_income: formVals.coborrower_income
          ? formVals.coborrower_income.replace(/[^0-9.,$]/g, '')
          : undefined,
      },
    })
      .then((response: any) => {
        if (response?.data?.success && response?.data?.decision) {
          logAmplitudeEvent('integrated_finance_application_submitted', {
            integration: 'loanpal',
            decision: getCommonCreditDecision(response.data.decision),
            project_id: props.project?.id,
            payment_option_id: props?.paymentOptionId,
          })
          setDecision(response.data.decision)
          props.onClose()
          const decisionDisplayProps = {
            decision: response.data.decision,
            amountApproved: response.data.amount_approved,
            amountRequested: response.data.amount_requested,
            approvedContacts: response.data.approved_contacts,
            appliedWithCoborrower: response.data.has_coborrower,
          }
          dispatch(openCreditDecisionDialog('loanpal', decisionDisplayProps))
        } else if (!response?.data?.success) {
          logAmplitudeEvent('integrated_finance_application_submit_error', {
            integration: 'loanpal',
            project_id: props.project?.id,
            payment_option_id: props?.paymentOptionId,
          })
          if (response?.data?.errorMsg) setErrorMsg(response.data.errorMsg)
          else setErrorMsg('We were unable to submit this credit application')
        }
      })
      .catch((err: any) => {
        console.log('err', err.status, err.body, err.name)
        logAmplitudeEvent('integrated_finance_application_submit_error', {
          integration: 'loanpal',
          project_id: props.project?.id,
          payment_option_id: props?.paymentOptionId,
        })
        setErrorMsg('Something went wrong and we were unable to submit this credit application')
      })
      .finally(() => setLoading(false))
    try {
      logAmplitudeEvent('integrated_finance_application_submit_attempt', { integration: 'loanpal' })
    } catch (ex) {}
  }

  const confirmBeforeClose = () => {
    if (window.confirm(translate('Are you sure you want to exit your application? All progress will be lost')))
      props.onClose()
  }

  const renderFormContent = () => {
    return (
      <>
        {loading && (
          <>
            <div
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundColor: '#ececec',
                opacity: 0.9,
                zIndex: 9000,
              }}
            />
            <div
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0px 10px',
                zIndex: 9001,
              }}
            >
              <LoadingDots text="Your application is being reviewed. This may take up to 30 seconds. Thank you for your patience" />
            </div>
          </>
        )}
        <div style={{ paddingBottom: '250px' }}>
          <SimpleForm initialValues={getInitialValues()} toolbar={<div></div>}>
            <LoanpalApplicationForm />
            {disclosures?.map((disc: DisclosureType, disclosureIndex: number) => (
              <div>
                <h4>{disc.name}</h4>
                {disc?.sections.map((section: DisclosureSectionType, n: number) => (
                  <LoanpalDisclosureSection
                    {...section}
                    key={n}
                    markAsValid={markDisclosureAsValid}
                    markAsInvalid={markAsInvalid}
                    disclosureIndex={disclosureIndex}
                    invalidDisclosures={invalidDisclosures}
                    markSignatureAsComplete={
                      n === indexOfPrimarySignature ? markPrimarySignatureAsComplete : markSecondarySignatureAsComplete
                    }
                  />
                ))}
              </div>
            ))}
            <LoanpalApplicationDialogFooter
              submitApplication={submitApplication}
              loading={loading}
              onClose={confirmBeforeClose}
              errorMsg={errorMsg}
            />
          </SimpleForm>
        </div>
      </>
    )
  }

  return (
    <Dialog
      maxWidth={'md'}
      open={props.open}
      onClose={confirmBeforeClose}
      fullScreen={isMobile || props.isFullWidth}
      fullWidth={isMobile || props.isFullWidth}
    >
      <div style={{ textAlign: 'center' }}>
        <h1 style={{ fontSize: isMobile ? undefined : '24px' }}>
          {translate(`Apply for Financing with ${LOANPAL_OR_GOODLEAP}`)}
        </h1>
      </div>
      <DialogContent dividers>{renderFormContent()}</DialogContent>
    </Dialog>
  )
}

export default LoanpalApplicationDialog

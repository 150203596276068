import { Theme, useMediaQuery } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import ProUxButton from 'elements/proUXButtons/ProUXButton'
import { ActionDataType } from 'myenergy/selectionComponent/loanApplicationButton/types'
import React, { useState } from 'react'
import { useNotify } from 'react-admin'
import restClient from 'restClient'
import { ProjectType } from 'types/projects'
import { LoanpalDecisionType } from '../../../myenergy/selectionComponent/loanApplicationButton/loanpal/types'

const API_URL = window.API_ROOT + '/api'
const restClientInstance = restClient(API_URL)

type DecisionsResponseType = {
  [contactId: string]: {
    applicant_type: 'primary' | 'secondary'
    email: string
    decision_status: LoanpalDecisionType
  }
}

type PropTypes = {
  project: ProjectType
  loanpalAgreementData: ActionDataType
}

const LoanpalSendDocsProButton: React.FunctionComponent<PropTypes> = (props) => {
  const [loading, setLoading] = useState<boolean>(false)

  const notify = useNotify()
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 960))
  const isXxs = useMediaQuery((theme: Theme) => theme.breakpoints.down(332))
  const handleClick = () => {
    setLoading(true)
    restClientInstance('CUSTOM_POST', 'custom', {
      url:
        'orgs/' +
        props.loanpalAgreementData.org_id +
        '/projects/' +
        props.loanpalAgreementData.project_id +
        '/systems/' +
        props.loanpalAgreementData.system_uuid +
        '/' +
        props.loanpalAgreementData.payment_option_id +
        '/loanpal_docs/',
    })
      .then((res: any) => {
        console.log('res', res)
        if (res.data?.success) {
          logAmplitudeEvent('integrated_finance_loan_agreement_sent', {
            integration: 'loanpal',
            screen: 'my_energy',
            project_id: props.loanpalAgreementData.project_id,
          })
          notify('The Loan Docs have been sent to the homeowner', 'success')
        }
      })
      .catch((err: any) => {
        console.log('err', err)
        if (err?.body?.message) {
          notify(err.body.message, 'warning')
        } else {
          notify('Something went wrong and we are unable to send your Loan Agreement', 'warning')
        }
        logAmplitudeEvent('integrated_finance_loan_agreement_error', {
          integration: 'laonpal',
          screen: 'my_energy',
          project_id: props.loanpalAgreementData.project_id,
        })
      })
      .finally(() => setLoading(false))
  }

  return (
    <div style={{ width: '100%' }}>
      <ProUxButton
        startIcon={
          <img src={`${window.PUBLIC_URL}/images/loanpal_logo.svg`} alt="" height={15} style={{ marginRight: 10 }} />
        }
        label="Send Loan Docs"
        onClick={handleClick}
        type="secondary"
        backgroundColor="#fff"
        border="1px solid #ececec"
        labelStyle={{ display: 'flex', flexDirection: isMd || isXxs ? 'column' : 'row', alignItems: 'flex-start' }}
        fullWidth
        fullHeight
        // isWideIcon
        isMarginTop={isMd || isXxs ? true : false}
        justifyContent="flex-start"
        style={{ width: '100%' }}
        loading={loading}
      />
    </div>
  )
}

export default LoanpalSendDocsProButton

import { useLayoutEffect, useMemo } from 'react'
import useInventoryWarehouses from './useInventoryWarehouses'

const usePrimaryWarehouse = () => {
  const { loadingWarehouses, warehouses, loadWarehouses } = useInventoryWarehouses()
  useLayoutEffect(() => {
    loadWarehouses()
  }, [])
  return useMemo(() => {
    return {
      isLoading: loadingWarehouses,
      primaryWarehouse: warehouses?.find((warehouse) => warehouse.name === 'Primary Warehouse'),
    }
  }, [loadingWarehouses, warehouses])
}

export default usePrimaryWarehouse

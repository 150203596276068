import { findScheduleConflicts } from 'elements/scheduleDialog/summary/validateSchedule'
import { ScheduleType } from 'elements/scheduleDialog/types'
import { useMemo } from 'react'
import { days, defaultSchedule, timeLabels } from './utils'

export const useConflictedSlots = (schedule: ScheduleType, allSchedules: ScheduleType[], index: number) => {
  const otherSchedules = useMemo(() => allSchedules.filter((x, i) => i !== index && x !== null), [allSchedules, index])
  return useMemo(() => {
    return !!otherSchedules.length ? findScheduleConflicts(schedule, otherSchedules) : []
  }, [schedule, otherSchedules])
}

export const useFullyUsedSlots = (allSchedules: ScheduleType[], index: number): ScheduleType[] => {
  const otherSchedules = useMemo(() => allSchedules.filter((x, i) => i !== index && x !== null), [allSchedules, index])
  return useMemo(() => {
    // Create a schedule that contains all dates whose hours are fully used
    const fullyUsedDates: ScheduleType = defaultSchedule()
    fullyUsedDates.date_ranges = []
    for (let month = 0; month < 12; month++) {
      for (let day = 1; day < 32; day++) {
        const date = new Date(2020, month, day) // pick any year that is a leap year
        if (date.getMonth() !== month) continue // fallen outside of month

        const dateRange = { start_date: date, end_date: date }
        const schedule = defaultSchedule()
        schedule.date_ranges = [dateRange]

        const conflicts = findScheduleConflicts(schedule, otherSchedules)
        if (allHoursTaken(conflicts)) {
          fullyUsedDates.date_ranges.push(dateRange)
        }
      }
    }
    combineDateRanges(fullyUsedDates)

    // Create a schedule that contains all hours whose dates fill the year
    const fullyUsedHours: ScheduleType = defaultSchedule()
    fullyUsedHours.date_ranges = []
    for (const day of days) {
      //TODO: deal with other increments
      for (let i = 0; i < timeLabels.length; i++) {
        const schedule = defaultSchedule()
        schedule.date_ranges = []
        schedule.applicable_days_and_hours.data[day][i] = true

        const conflicts = findScheduleConflicts(schedule, otherSchedules)
        if (allDatesTaken(conflicts)) {
          fullyUsedHours.applicable_days_and_hours.data[day][i] = true
        }
      }
    }

    return [fullyUsedDates, fullyUsedHours]
  }, [otherSchedules])
}

const allHoursTaken = (conflicts: ScheduleType[]) => {
  for (const day of days) {
    //TODO: deal with other increments
    for (let i = 0; i < timeLabels.length; i++) {
      if (!conflicts.find((x) => x.applicable_days_and_hours.data[day][i])) {
        return false
      }
    }
  }
  return true
}

const allDatesTaken = (conflicts: ScheduleType[]) => {
  for (let month = 0; month < 12; month++) {
    for (let day = 1; day < 32; day++) {
      if (!conflicts.find((x) => withinDateRange(x, month, day))) {
        return false
      }
    }
  }
  return true
}

const withinDateRange = (schedule: ScheduleType, month: number, date: number) => {
  for (let date_range of schedule.date_ranges) {
    let start = date_range.start_date
    let end = date_range.end_date
    if (!start || !end) continue

    const isInverted = start > end //TODO: this should deal ignore years

    if (isInverted) {
      const temp = start
      start = end
      end = temp
    }

    const isWithin =
      ((month === start.getMonth() && date >= start.getDate()) || month > start.getMonth()) &&
      ((month === end.getMonth() && date <= end.getDate()) || month < end.getMonth())

    if (isInverted !== isWithin) return true
  }
  return false
}

const combineDateRanges = (schedule: ScheduleType) => {
  let i = 0
  let dummyDate = new Date(0)
  while (i < schedule.date_ranges.length) {
    const range = schedule.date_ranges[i]
    const range2 = schedule.date_ranges[i + 1]
    if (!range2) break

    if (range.end_date) {
      dummyDate.setTime(range.end_date.getTime())
      dummyDate.setDate(dummyDate.getDate() + 1)
    }

    if (range.end_date && range2.start_date && dummyDate.getTime() === range2.start_date.getTime()) {
      range.end_date = range2.end_date
      schedule.date_ranges.splice(i + 1, 1)
    } else {
      i++
    }
  }
}

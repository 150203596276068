import { makeStyles } from '@material-ui/core'
import ImageFieldClearable from 'elements/field/ImageFieldClearable'
import { UiKey } from 'elements/UiKey'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import { ImageField, ImageInput, useTranslate } from 'react-admin'

const uiKey = 'control.company.team.fields'

const useStyles = makeStyles({
  fieldWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  portrait: {
    border: '5px solid #f3f3f3',
    borderRadius: '5px',
    boxShadow: '0 0 5px rgba(0,0,0,0.3)',
    display: 'inline-block',
  },
})

const RoleProfileFieldsContent = (props) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <UiKey uiKey={uiKey + '.userImage'}>
      <div className={classes.fieldWrapper}>
        <div className={classes.portrait}>
          <ImageFieldClearable
            record={props.record}
            label="resources.roles.fields.portrait_image"
            source="portrait_image"
          />
        </div>

        <ImageInput
          label={false}
          source="portrait_image"
          //label="Upload Portrait"
          accept="image/*"
          placeholder={<p>{translate('Click to upload or drag & drop an image file here')}</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </div>
    </UiKey>
  )
}

export const RoleProfileFields = (props) => {
  const translate = useTranslate()
  return (
    <AccordionCard
      disabled={props.disabled}
      defaultExpanded={true}
      name={'role-profile'}
      title={translate('Upload Portrait')}
      content={RoleProfileFieldsContent}
      contentProps={props}
    />
  )
}

import { OSSDK } from 'opensolar-sdk'
import { useCallback, useMemo } from 'react'
import appStorage from 'storage/appStorage'

const BANNED_KEYS = ['auth', 'org', 'org_id', 'token', 'nearmap_token']

const checkKey = (key: string) => {
  if (BANNED_KEYS.includes(key)) {
    throw new Error(`Key ${key} cannot be set via the SDK`)
  }
}

export const usePreferenceBindings = () => {
  const bind = useCallback((sdk: OSSDK, cleanups: (() => void)[]) => {
    sdk.preferences.setString.own(async (key, value) => {
      checkKey(key)
      appStorage.setString(key, value)
    })

    sdk.preferences.setObject.own(async (key, value) => {
      checkKey(key)
      appStorage.setJSON(key, value)
    })
  }, [])
  return useMemo(() => ({ bind }), [bind])
}

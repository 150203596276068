import { ModuleGridType, ModuleLayout, PanelConfiguration, PanelPlacement } from 'types/global'
import MODULE_GRID_SETTER_GETTER_CONFIGS from './accessors/configs'

type ModuleGridGroupSettingsHandler = {
  onRackingTypeChange(value: PanelConfiguration): void
  setAzimuth(value: number): void
  setAzimuthAuto(value: boolean): void
  onSlopeChange(value: number): void
  setSlopeAuto: (value: boolean) => void
  setPanelTiltOverride: (value: number) => void
  setOrientation: (orientation: ModuleLayout) => void
  setElevationAuto: (value: boolean) => void
  setGroundClearance: (value: number) => void
  setOffsetRows: (value: boolean) => void
  setPanelPlacement: (value: PanelPlacement) => void
  setModuleSpacing: ({ horizontal, vertical }: { horizontal?: number; vertical?: number }) => void
  setModulesPerCol: (value: number) => void
  setModulesPerRow: (value: number) => void
  setTrackingMode: (value: unknown) => void
  setGroupSpacing: ({ horizontal, vertical }: { horizontal?: number; vertical?: number }) => void
}

const useModuleGridGroupSettingsHandler = ({
  moduleGrids,
}: {
  moduleGrids: ModuleGridType[]
}): ModuleGridGroupSettingsHandler => {
  const onRackingTypeChange = (newValue: PanelConfiguration) => {
    moduleGrids.forEach((moduleGrid) => {
      MODULE_GRID_SETTER_GETTER_CONFIGS['rackingType'].set({
        moduleGrid,
        value: newValue,
      })
    })
  }

  const setAzimuth = (value: number) => {
    moduleGrids.forEach((moduleGrid) => {
      MODULE_GRID_SETTER_GETTER_CONFIGS['azimuth'].set({
        moduleGrid,
        value,
      })
    })
  }

  const setAzimuthAuto = (value: boolean) => {
    moduleGrids.forEach((moduleGrid) => {
      MODULE_GRID_SETTER_GETTER_CONFIGS['azimuthAuto'].set({
        moduleGrid,
        value,
      })
    })
  }

  const onSlopeChange = (value: number) => {
    moduleGrids.forEach((moduleGrid) => {
      MODULE_GRID_SETTER_GETTER_CONFIGS['slope'].set({
        moduleGrid,
        value,
      })
    })
  }

  const setSlopeAuto = (value: boolean) => {
    moduleGrids.forEach((moduleGrid) => {
      MODULE_GRID_SETTER_GETTER_CONFIGS['slopeAuto'].set({
        moduleGrid,
        value,
      })
    })
  }

  const setPanelTiltOverride = (value: number) => {
    moduleGrids.forEach((moduleGrid) => {
      MODULE_GRID_SETTER_GETTER_CONFIGS['tilt'].set({
        moduleGrid,
        value,
      })
    })
  }

  const setOrientation = (value: ModuleLayout): void => {
    moduleGrids.forEach((moduleGrid) => {
      MODULE_GRID_SETTER_GETTER_CONFIGS['orientation'].set({
        moduleGrid,
        value,
      })
    })
  }

  const setElevationAuto = (value: boolean) => {
    moduleGrids.forEach((moduleGrid) => {
      MODULE_GRID_SETTER_GETTER_CONFIGS['elevationAuto'].set({
        moduleGrid,
        value,
      })
    })
  }

  const setGroundClearance = (value: number) => {
    moduleGrids.forEach((moduleGrid) => {
      MODULE_GRID_SETTER_GETTER_CONFIGS['groundClearance'].set({
        moduleGrid,
        value,
      })
    })
  }

  const setOffsetRows = (value: boolean) => {
    moduleGrids.forEach((moduleGrid) => {
      MODULE_GRID_SETTER_GETTER_CONFIGS['offset'].set({
        moduleGrid,
        value,
      })
    })
  }

  const setPanelPlacement = (value: PanelPlacement) => {
    moduleGrids.forEach((moduleGrid) => {
      MODULE_GRID_SETTER_GETTER_CONFIGS['panelPlacement'].set({
        moduleGrid,
        value,
      })
    })
  }

  const setModuleSpacing = ({ horizontal, vertical }: { horizontal?: number; vertical?: number }) => {
    moduleGrids.forEach((moduleGrid) => {
      const newValue = MODULE_GRID_SETTER_GETTER_CONFIGS['moduleSpacing'].get(moduleGrid) || [0, 0] // is it possible to have moduleSpacing undefined?
      if (horizontal !== undefined) newValue[0] = horizontal
      if (vertical !== undefined) newValue[1] = vertical

      MODULE_GRID_SETTER_GETTER_CONFIGS['moduleSpacing'].set({
        moduleGrid,
        value: newValue,
      })
    })
  }

  const setModulesPerCol = (value: number) => {
    moduleGrids.forEach((moduleGrid) => {
      MODULE_GRID_SETTER_GETTER_CONFIGS['modulesPerCol'].set({
        moduleGrid,
        value,
      })
    })
  }

  const setModulesPerRow = (value: number) => {
    moduleGrids.forEach((moduleGrid) => {
      MODULE_GRID_SETTER_GETTER_CONFIGS['modulesPerRow'].set({
        moduleGrid,
        value,
      })
    })
  }

  const setTrackingMode = (value: unknown) => {
    moduleGrids.forEach((moduleGrid) => {
      MODULE_GRID_SETTER_GETTER_CONFIGS['trackingMode'].set({
        moduleGrid,
        value: value,
      })
    })
  }

  const setGroupSpacing = ({ horizontal, vertical }: { horizontal?: number; vertical?: number }) => {
    moduleGrids.forEach((moduleGrid) => {
      const newValue = MODULE_GRID_SETTER_GETTER_CONFIGS['groupSpacing'].get(moduleGrid) || [0, 0] // is it possible to have groupSpacing undefined?
      if (horizontal !== undefined) newValue[0] = horizontal
      if (vertical !== undefined) newValue[1] = vertical
      MODULE_GRID_SETTER_GETTER_CONFIGS['groupSpacing'].set({
        moduleGrid,
        value: newValue,
      })
    })
  }

  return {
    onRackingTypeChange,
    setAzimuth,
    setAzimuthAuto,
    onSlopeChange,
    setSlopeAuto,
    setPanelTiltOverride,
    setOrientation,
    setElevationAuto,
    setGroundClearance,
    setOffsetRows,
    setPanelPlacement,
    setModuleSpacing,
    setModulesPerCol,
    setModulesPerRow,
    setTrackingMode,
    setGroupSpacing,
  }
}

export default useModuleGridGroupSettingsHandler

import { FormControlLabel, ListSubheader } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { Checkbox, Chip } from 'opensolar-ui'
import { groupChoices, useProjectOwnerDetails } from 'projectSections/sections/info/orgSharing/util'
import { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { isPricingLockedForStage } from 'util/misc'

const handleRemoveOverride = (uuid, url) => {
  var system = window.editor.objectByUuid(uuid)
  var newValue = [...system['incentives']]
  newValue.splice(system['incentives'].indexOf(url), 1)
  window.editor.execute(new window.SetValueCommand(system, 'incentives', newValue))
}

const handleAddOverride = (uuid, url) => {
  var system = window.editor.objectByUuid(uuid)
  var newValue = [...system['incentives'], url]
  window.editor.execute(new window.SetValueCommand(system, 'incentives', newValue))
}

const IncentiveCheckBoxes = ({ system }) => {
  const translate = useTranslate()
  const formValues = useFormState().values
  const orgWorkflows = useSelector(orgSelectors.getWorkflows)
  const pricingIsLocked = isPricingLockedForStage(formValues, orgWorkflows) && system.override_price_locking !== true
  const availableIncentives = window.AccountHelper.getIncentivesAvailable()
  const archivedIncentiveIds =
    (system.incentives &&
      system.incentives.filter((id) => !availableIncentives.some((incentive) => incentive.id === id))) ||
    []
  const form = useForm()
  const sharedWith = form.getState().values.shared_with
  const orgId = useSelector(authSelectors.getOrgId)
  const ownerOrg = useProjectOwnerDetails(useSelector(orgSelectors.getOrg), form.getState().values.org_id)
  const [incentives, setIncentives] = useState(availableIncentives)
  useEffect(() => {
    if (!!sharedWith?.length) {
      const newChoices = groupChoices(availableIncentives, orgId, ownerOrg, sharedWith)
      setIncentives(newChoices)
    } else {
      setIncentives(availableIncentives)
    }
  }, [])

  const renderArchivedIncentiveCheckBox = () => {
    return archivedIncentiveIds.map((id) => {
      const incentiveData = window.AccountHelper.getIncentiveById(id)
      if (!incentiveData) return null
      const isChecked = system.incentives.indexOf(id) !== -1
      return (
        <FormControlLabel
          key={id}
          style={{ display: 'flex', alignItems: 'flex-start', fontSize: 12 }}
          control={
            <Checkbox
              key={id}
              size="small"
              style={{ padding: '0 9px' }}
              labelPosition="right"
              checked={isChecked}
              onChange={(event) => {
                if (event.target.checked) {
                  handleAddOverride(system.uuid, id)
                } else {
                  handleRemoveOverride(system.uuid, id)
                }
              }}
              disabled={pricingIsLocked}
            />
          }
          label={
            <p className={isChecked && 'incentive-override-checked'} style={{ marginTop: 4, fontSize: 12 }}>
              {incentiveData.title} ({translate('archived')})
            </p>
          }
        />
      )
    })
  }

  const renderAvailableIncentiveCheckBox = () =>
    incentives.map((incentiveData, incentiveIndex) => {
      if (incentiveData.hasOwnProperty('heading') && incentiveData['heading']) {
        return (
          <ListSubheader key={incentiveData.id} className="GroupedSelectHeading">
            {incentiveData.name}
            {incentiveData.chip && (
              <Chip
                key={incentiveData.id}
                label={translate(incentiveData.chip)}
                className="GroupedSelectHeadingChip"
                size="small"
                variant="outlined"
                disabled={true}
              />
            )}
          </ListSubheader>
        )
      } else {
        return (
          <FormControlLabel
            key={incentiveIndex}
            style={{ display: 'flex', alignItems: 'flex-start' }}
            control={
              <Checkbox
                key={incentiveIndex}
                size="small"
                style={{ padding: '0 9px' }}
                labelPosition="right"
                checked={system.incentives.indexOf(incentiveData.id) !== -1}
                onChange={(event) => {
                  if (event.target.checked) {
                    handleAddOverride(system.uuid, incentiveData.id)
                  } else {
                    handleRemoveOverride(system.uuid, incentiveData.id)
                  }
                }}
                disabled={pricingIsLocked}
              />
            }
            label={<p style={{ marginTop: 4, fontSize: 12 }}>{incentiveData.title}</p>}
          />
        )
      }
    })

  return (
    <>
      <hr />
      {renderArchivedIncentiveCheckBox()}
      {renderAvailableIncentiveCheckBox()}
    </>
  )
}

export default IncentiveCheckBoxes

// @ts-nocheck
import SearchInput from 'elements/input/ListPageSearchBar'
import { ExporterContext } from 'ra-core'
import React, { useContext, useState } from 'react'
import { Button, ExportButton, useTranslate } from 'react-admin'
import { CreateTransactionDialogWithForm } from './createWalletTransactionDialog'

const WalletTransactionListToolbar = (props: any) => {
  const {
    classes: classesOverride,
    filters,
    filterValues, // dynamically set via the UI by the user
    permanentFilter, // set in the List component by the developer
    actions,
    exporter,
    hasSearch = true,
    hasArchived,
    hideActions,
    resource,
    showFilter,
    displayedFilters,
    createButtonLabel,
    hasCreate,
    useCreateDialog,
    hasExport,
    searchBarPlaceholder,
  } = props
  const translate = useTranslate()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const exporterFromContext = useContext(ExporterContext)

  const customExporter = (data, records, provider, resource) =>
    exporterFromContext(data, records, provider, 'Wallet Transactions ' + new Date().toISOString().slice(0, 10))

  const openDialog = () => setIsOpen(true)
  return (
    <>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasSearch && <SearchInput {...props} placeholder={translate(searchBarPlaceholder)} />}
      {hasCreate && (
        <Button label={'Create'} variant={'outlined'} color="default" size="default" onClick={openDialog} />
      )}
      {hasExport && (
        <ExportButton
          resource={resource}
          filter={{ ...filterValues, ...permanentFilter, format: 'csv' }}
          maxResults={1000000}
          exporter={customExporter}
          label="Download CSV"
        />
      )}
      {hasCreate && isOpen && (
        // <Form
        //   onSubmit={(e) => console.log('e', e)}
        //   render={(formProps) => <CreateDialog isOpen={isOpen} close={() => setIsOpen(false)} {...formProps} />}
        // />
        <CreateTransactionDialogWithForm open={isOpen} setOpen={setIsOpen} />
      )}
    </>
  )
}
export default WalletTransactionListToolbar

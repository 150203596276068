import withMediaQuery from 'layout/withMediaQuery'
import React from 'react'
import { withTranslate } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import { TextField } from '@material-ui/core'
import { Button } from 'opensolar-ui'
import { doNotTranslate } from 'util/misc'

// const styles = {
//   warning: {
//     card: 'pink',
//     title: 'red',
//   },
//   notice: {
//     card: 'yellow',
//     title: 'orange',
//   },
//   ok: {
//     card: 'white',
//     title: 'black',
//   },
//   hr: {
//     border: 'none',
//     height: 1,
//     color: '#ddd',
//     backgroundColor: '#ddd',
//     margin: 0,
//   },
//   icon: {
//     display: 'inline',
//     verticalAlign: 'middle',
//     width: 20,
//     margin: 0,
//     marginRight: 10,
//     paddingBottom: '2px',
//     position: 'unset',
//     fill: '#4d4d4d',
//   },
// }

class PageStaffReports extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mounted: false,
      loading: false,
    }
  }
  generateReport(report) {
    if (!report || report.indexOf('/api/reports/') !== 0) {
      alert(doNotTranslate('Report URL should start with /api/reports/'))
      return
    }

    this.setState({ loading: true })

    // Enforce prefix of /api/reports/ to reduce ability malicious use-cases for posting to other endpoints.
    var baseUrl = window.API_BASE_URL.replace('/api/', '')
    var pathWithoutPrefix = report.replace('/api/reports/', '')
    var fullUrl = baseUrl + '/api/reports/' + pathWithoutPrefix

    // We cannot follow redirects automatically because
    // a) intermediate requests require authorization header but
    // b) the final redirect poinst to AWS and browsers are not smart enough to skip the authorization header only here
    //
    // Therefore, we treat redirects as errors and resubmit those that point to the same domain. The final redirect
    // to AWS we will conver to window.open()
    fetch(fullUrl, {
      headers: window.Utils.tokenAuthHeaders(),
      mode: 'cors',
      credentials: 'same-origin',
      cache: 'no-cache',
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Error ' + response.status)
        }
      })
      .then((data) => {
        this.setState({ loading: false })

        if (data.download_url) {
          window.open(data.download_url)
        } else {
          throw new Error('Unknown error downloading report')
        }
      })
      .catch((error) => {
        alert('Error: ' + error)
        this.setState({ loading: false })
      })
  }
  componentDidMount() {
    this.setState({ mounted: true })

    if (this.props.location?.search && this.props.location.search.indexOf('?report=') === 0) {
      var report = this.props.location.search.replace('?report=', '')
      this.setState({ report: report })
    }
  }

  componentWillUnmount() {
    this.setState({ mounted: false })
  }

  render() {
    const { translate } = this.props

    return (
      <div
        style={{
          marginBottom: 50,
          width: '100%',
          color: '#4d4d4d',
          paddingTop: 40,
          opacity: this.state.loading ? '0.3' : '1.0',
        }}
      >
        <div style={{ padding: '24px' }}>
          <div
            style={{
              background: 'white',
              boxShadow: '1px 1px 5px #737070',
            }}
          >
            <div style={{ padding: 20 }}>
              <TextField
                variant="outlined"
                label={doNotTranslate('Report URL (starting with /api/reports/...)')}
                name="report"
                value={this.state.report}
                onChange={(event) => {
                  this.setState({ report: event.target.value })
                }}
                style={{ width: '100%' }}
              />
              <Button
                onClick={() => this.generateReport(this.state.report)}
                variant="contained"
                fullWidth={true}
                style={{ width: 200, marginTop: 20 }}
              >
                <span>{translate('Generate')}</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isStaff: state.auth ? state.auth.is_staff : null,
})

const enhance = compose(withTranslate, withMediaQuery, connect(mapStateToProps, {}))
export default enhance(PageStaffReports)

import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const useUiKey = (ref: React.RefObject<HTMLElement>) => {
  const location = useLocation()
  const [uiKey, setUiKey] = useState<string>('')

  useEffect(() => {
    if (ref.current) {
      const element = ref.current

      const tagName = element.tagName.toLowerCase()

      const id = element.getAttribute('data-testid') || ''

      const pagePath = location.pathname.replace('/', '')
      const uiKeyString = `${pagePath}.${tagName}.${id}`

      setUiKey(uiKeyString)
    }
  }, [ref, location.pathname])

  return uiKey
}

export default useUiKey

import { Divider, Typography } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import { useTranslate } from 'ra-core'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { StudioSystemType } from 'types/global'
import { PartnerAccessoriesID } from 'types/mounting'
import { RemoveComponentButton } from '../../common/RemoveComponentButton'
import { removePartnerAccessory } from '../nativeMounting/calculate'
import { partnerAccessoriesIDs } from '../nativeMounting/constants'
import { PartnerAccessoryInputs } from '../nativeMounting/Inputs'
import MountingSystemCapsule from './MountingSystemCapsule'

const useStyles = makeOpenSolarStyles((theme) => ({
  divider: {
    margin: '10px 0',
  },
  selectedPartnerAccessory: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const partnerAccessoriesAvailabilityByIso2: Record<PartnerAccessoriesID, string[]> = {
  solaskirt: ['GB'],
}

const PartnerAccessoriesSelector = ({ system, allowEdit }: { system: StudioSystemType; allowEdit: boolean }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const countryIso2 = useSelector(orgSelectors.getOrgIso2) || ''
  const partnerAccessoriesOptions = partnerAccessoriesIDs.filter((partnerAccessory) =>
    partnerAccessoriesAvailabilityByIso2[partnerAccessory].includes(countryIso2)
  )
  const selectedPartnerAccessories = system.partner_accessories || []

  if (!partnerAccessoriesOptions.length) return <></>

  const handleAddPartnerAccessory = (partnerAccessoryId: PartnerAccessoriesID) => {
    const partner_accessories = system.partner_accessories || []

    if (!partner_accessories.includes(partnerAccessoryId)) partner_accessories.push(partnerAccessoryId)

    var commandUUID = window.Utils.generateCommandUUIDOrUseGlobal()
    window.editor.execute(new window.SetValueCommand(system, 'partner_accessories', partner_accessories, commandUUID))
    window.editor.signals.objectChanged.dispatch(system, 'partner_accessories')
  }

  const handleRemovePartnerAccessory = (partnerAccessoryId: PartnerAccessoriesID) => {
    const partner_accessories = system.partner_accessories || []
    partner_accessories.splice(partner_accessories.indexOf(partnerAccessoryId), 1)
    removePartnerAccessory(system, partnerAccessoryId)

    var commandUUID = window.Utils.generateCommandUUIDOrUseGlobal()
    window.editor.execute(new window.SetValueCommand(system, 'partner_accessories', partner_accessories, commandUUID))
  }

  return (
    <>
      <Divider className={classes.divider} />
      <Typography variant="subtitle2" gutterBottom>
        {translate('Partner Accessories')}
      </Typography>
      {partnerAccessoriesOptions.map((partnerAccessoryId) => {
        const isSelectedPartnerAccessory = selectedPartnerAccessories.includes(partnerAccessoryId)

        return (
          <>
            <div {...(isSelectedPartnerAccessory && { className: classes.selectedPartnerAccessory })}>
              <MountingSystemCapsule
                integrationName={partnerAccessoryId}
                onClick={() => handleAddPartnerAccessory(partnerAccessoryId)}
              />
              {isSelectedPartnerAccessory && (
                <RemoveComponentButton onClick={() => handleRemovePartnerAccessory(partnerAccessoryId)} />
              )}
            </div>
            {isSelectedPartnerAccessory && (
              <PartnerAccessoryInputs allowEdit={allowEdit} partnerAccessory={partnerAccessoryId} />
            )}
          </>
        )
      })}
    </>
  )
}

export default PartnerAccessoriesSelector

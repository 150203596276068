import { ComponentModuleCreate, ComponentModuleEdit, ComponentModuleList } from './moduleComponents'

import { customModuleDataFields, moduleDataFields } from './constants'

export default {
  list: ComponentModuleList,
  edit: ComponentModuleEdit,
  create: ComponentModuleCreate,
}

export { customModuleDataFields, moduleDataFields }

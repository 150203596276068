import React from 'react'

const RevenueIcon = (props) => (
  <svg {...props} viewBox="0 0 100 63" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M49.9288 62.9139C35.557 62.9139 21.1851 62.9139 6.81331 62.9139C1.95173 62.9139 0 60.9923 0 56.1528C0 39.6059 0 23.0589 0 6.51202C0 2.13509 2.0227 0.0355842 6.38748 0.0355842C35.486 0.0355842 64.5845 0.0711696 93.6475 0C98.0478 0 100.035 2.8112 99.9995 6.47643C99.8576 23.2013 99.9286 39.9261 99.9286 56.651C99.9286 60.7432 97.8349 62.8783 93.683 62.8783C79.1338 62.9139 64.5135 62.9139 49.9288 62.9139ZM81.7597 7.89982C81.6178 7.82865 81.5468 7.79307 81.4758 7.79307C60.7875 7.79307 40.0637 7.75748 19.3754 7.79307C18.914 7.79307 18.2043 8.43359 18.0269 8.8962C16.4655 13.3799 13.5911 16.5469 8.97796 17.8991C7.8424 18.2194 7.70046 18.7888 7.70046 19.7851C7.73595 27.6138 7.73595 35.4424 7.70046 43.2711C7.70046 44.4098 8.05532 44.8724 9.15539 45.1927C13.4137 46.4381 16.3236 49.3561 17.743 53.4839C18.2753 55.0853 18.985 55.3343 20.4754 55.3343C40.2411 55.2988 59.9713 55.2988 79.737 55.2988C80.3758 55.2988 81.0145 55.2988 81.6888 55.2988C81.8662 54.765 82.0081 54.3024 82.1856 53.8754C83.676 49.5696 86.4794 46.5093 90.8796 45.1927C92.1926 44.8012 92.441 44.1607 92.441 42.9508C92.4055 35.3001 92.4055 27.6494 92.441 19.9986C92.441 18.7532 92.1571 18.1127 90.8796 17.7212C89.4957 17.2942 88.1472 16.5825 86.9407 15.7641C84.1728 13.8781 82.5759 11.0669 81.7597 7.89982Z"
      fill="url(#paint0_linear_6249:253498)"
    />
    <path
      d="M49.9643 50.5304C39.3185 50.4592 30.9438 42.0256 31.0148 31.4214C31.0857 20.9239 39.5314 12.4903 50.0353 12.4903C60.6456 12.4903 69.1622 21.0662 69.0912 31.7416C69.0557 42.168 60.4681 50.6016 49.9643 50.5304ZM47.5512 32.4889C46.6996 31.4925 46.0608 30.7097 45.3511 29.998C43.8252 28.539 41.7315 28.4322 40.4186 29.7133C39.0346 31.1011 39.0346 32.9871 40.5605 34.624C42.0154 36.1897 43.5413 37.6843 45.0672 39.1433C46.6641 40.6734 48.4739 40.6378 50.0707 39.0365C53.158 36.0118 56.2098 32.9515 59.2261 29.8912C60.894 28.2187 61.0359 25.9769 59.6874 24.6958C58.339 23.4148 56.2808 23.6283 54.6484 25.2652C52.3419 27.5782 50.0707 29.9268 47.5512 32.4889Z"
      fill="url(#paint1_linear_6249:253498)"
    />
    <path
      d="M25.0176 31.5637C24.9821 34.4105 22.5336 36.8302 19.6592 36.7946C16.7494 36.7591 14.3718 34.3393 14.4428 31.4569C14.5138 28.6457 16.8913 26.3327 19.6947 26.3327C22.6046 26.2971 25.0531 28.7169 25.0176 31.5637Z"
      fill="url(#paint2_linear_6249:253498)"
    />
    <path
      d="M75.1239 31.3858C75.1948 28.5746 77.6079 26.226 80.4468 26.2972C83.3566 26.3683 85.7697 28.8593 85.6632 31.7061C85.5567 34.5528 83.1437 36.8303 80.3048 36.7947C77.395 36.7591 75.0529 34.3037 75.1239 31.3858Z"
      fill="url(#paint3_linear_6249:253498)"
    />
    <defs>
      <linearGradient id="paint0_linear_6249:253498" x1="50" y1="0" x2="50" y2="62.9139" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
      <linearGradient id="paint1_linear_6249:253498" x1="50" y1="0" x2="50" y2="62.9139" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
      <linearGradient id="paint2_linear_6249:253498" x1="50" y1="0" x2="50" y2="62.9139" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
      <linearGradient id="paint3_linear_6249:253498" x1="50" y1="0" x2="50" y2="62.9139" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
    </defs>
  </svg>
)
export default RevenueIcon

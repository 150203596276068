// todo (03/10/22): reconcile style with themes.js to use theme as much as possible.
// It's being refactored soon so I'm holding off for now
import { DialogContent, LinearProgress, makeStyles, useMediaQuery } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { permissionsSelectors } from 'ducks/permissions'
import BannerAd from 'elements/BannerAd'
import SharedEntityIcon from 'elements/connectedOrgs/SharedEntityIcon'
import { FormApi } from 'final-form'
import lodash, { isEqual } from 'lodash'
import { Button } from 'opensolar-ui'
import ProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/projectOrderPresenter'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'
import { AnyComponentType, BaseComponentType } from 'types/components'
import { ProjectShare } from 'types/projects'
import { ComponentTypes, FilterOverridesType, InverterTypeFilter, SelectComponentFilters } from 'types/selectComponent'
import { RootState } from 'types/state'
import { Theme } from 'types/themes'
import useActivatedComponents from '../../hooks/components/useActivatedComponents'
import ComponentOverview from './ComponentOverview'
import Exhibitors from './Exhibitors'
import {
  getAppliedFilters,
  getDefaultFilterSettings,
  getFiltersSchema,
  hardwareFilterTransformer,
  userHasAppliedFilters,
} from './Filters'
import { plural } from './HardwareSelector'
import SearchToolbar, { getCompatibleCodesForSystem } from './SearchToolbar'
import SelectButton from './SelectButton'
import PriceField from './price/PriceField'

interface ComponentResponse {
  total: number
  data: {
    id: number
    data: string
  }[]
}

const rowStyling = {
  display: 'contents',
}

const iconStyling: CreateCSSProperties = {
  height: '20px',
  width: '20px',
  verticalAlign: 'middle',
  objectFit: 'contain',
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    myList: {
      color: theme.secondaryColor,
    },
    content: {
      padding: 0,
    },
    paginationNav: {
      display: 'inline-flex',
      width: '100%',
      justifyContent: 'space-between',
      padding: '0 30px',
      boxSizing: 'border-box',
      margin: '5px 0',
      minHeight: 30,
    },
    pageCount: {
      display: 'flex',
      alignItems: 'center',
    },
    componentRowUnsafe: {
      color: '#adadad',
    },
    searchToolbarWrapper: {
      padding: '0 8px',
    },
    componentSelectionWrapper: {
      display: 'flex',
      flexDirection: 'row',
      columnGap: '10px',
      width: '100%',
    },
    componentTable: {
      display: 'grid',
      gridTemplateColumns: '2fr 1fr 2fr 2fr 0fr 0fr',
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: '2fr 1fr 2fr 0fr',
      },
      gridTemplateRows: '0fr 0fr 0fr 0fr 0fr 0fr 0fr 0fr 0fr',
      flexBasis: 'fit-content',
      flexGrow: 1,
    },
    componentTableWithoutPrice: {
      gridTemplateColumns: '2fr 1fr 2fr 0fr 0fr',
    },
    componentTableOther: {
      gridTemplateColumns: '2fr 3fr 2fr 2fr 0fr 0fr',
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: '2fr 2fr 2fr 0fr',
      },
    },
    componentTableOtherWithoutPrice: {
      gridTemplateColumns: '2fr 3fr 2fr 0fr 0fr',
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: '2fr 2fr 2fr 0fr',
      },
    },
    componentHeader: {
      fontWeight: 'bold',
      ...rowStyling,
      '& > *': {
        padding: '8px',
        [theme.breakpoints.down('sm')]: {
          padding: '8px 0px 8px 4px',
        },
        borderTop: 'solid 1px grey',
        borderBottom: 'solid 1px grey',
        backgroundColor: 'rgba(230, 230, 230, 0.85)',
      },
    },
    componentRow: {
      ...rowStyling,
      '& > div': {
        /*  Todo: remove this css rule */
        padding: '10px',
        borderBottom: 'solid 1px lightgrey',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
          padding: '8px 2px',
        },
        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column',
          justifyContent: 'center',
        },
      },
      '& $componentCode': {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
      },
    },
    resultMatch: {
      fontWeight: 'bold',
    },
    componentPrice: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    componentRating: {
      whiteSpace: 'nowrap',
    },
    componentCode: {},
    componentTableMessage: {
      textAlign: 'center',
      padding: '20px 0px 10px 0px',
      gridColumn: '1 / -1',
    },
    alertIcon: {
      ...iconStyling,
      margin: '0 10px',
      marginRight: 0,
      color: '#8b0000',
    },
    shareIconMini: {
      ...iconStyling,
      margin: 0,
    },
    logo: {
      height: '36px',
      width: '48px',
      margin: '0 auto',
      verticalAlign: 'middle',
      objectFit: 'contain',
      marginRight: '5px',
    },
    logoPlaceholder: {
      backgroundColor: '#F8F8F8',
      height: '36px',
      width: '48px',
      margin: '0 auto',
      verticalAlign: 'middle',
      marginRight: '5px',
    },
  }),
  { name: 'HardwareSelectionDialog' }
)

const kwToWatts = (kw: number): number => {
  try {
    return Math.round(1000 * kw)
  } catch (e) {
    return 0
  }
}

const ComponentSpecsRating: FC<{
  componentType: ComponentTypes
  component: BaseComponentType
  filterSettings: SelectComponentFilters
}> = (props) => {
  const { componentType, component, filterSettings } = props
  const classes = useStyles()
  var value,
    suffix = ''
  if (componentType === 'module' && component.kw_stc) {
    value = kwToWatts(component.kw_stc)
    suffix = ' W'
  } else if (componentType === 'inverter' && component.max_power_rating) {
    value = component.max_power_rating
    suffix = ' kW'
  } else if (componentType === 'battery' && component.kwh_optimal) {
    value = component.kwh_optimal
    suffix = ' kWh'
  } else {
    value = '-'
  }

  return (
    <span className={classes.componentRating}>
      <span className={`${filterRatingMatch(filterSettings, componentType, component) ? classes.resultMatch : ''}`}>
        {value}
      </span>
      {suffix}
    </span>
  )
}

const containSubstring = (string: string, substring = '') => {
  return string.toUpperCase().includes(substring.toUpperCase())
}

function stringMatchesRating(string: string, componentType: string, component: BaseComponentType) {
  var matchesRating
  if (componentType === 'module' && component?.kw_stc && String(kwToWatts(component.kw_stc)) === string) {
    matchesRating = true
  } else if (
    componentType === 'inverter' &&
    component?.max_power_rating &&
    (String(kwToWatts(component.max_power_rating)) === string || String(component.max_power_rating) === string)
  ) {
    // match either watts or kw for inverters
    matchesRating = true
  } else if (componentType === 'battery' && String(component.kwh_optimal) === string) {
    matchesRating = true
  } else if (componentType === 'other') {
    // no specs to match for other components
  }
  return matchesRating
}

function filterRatingMatch(
  filter: SelectComponentFilters,
  componentType: string,
  component: BaseComponentType
): string | null {
  var match: string | null = null
  var filterValuesParts = filter?.q ? filter.q.split(' ').filter((value) => value.length) : []
  filterValuesParts.forEach((partMatch) => {
    if (stringMatchesRating(partMatch, componentType, component)) match = partMatch
  })
  return match
}

function filterFieldMatches(
  filter: SelectComponentFilters,
  component: BaseComponentType,
  field: string
): string[] | null {
  var matches: string[] = []
  var filterValuesParts = filter?.q ? filter.q.split(' ').filter((value) => value.length) : []
  filterValuesParts.forEach((partMatch) => {
    if (containSubstring(component[field], partMatch)) matches.push(partMatch)
  })
  if (!matches.length) return null
  return matches
}

const ComponentSpecsField: FC<{
  field: string
  component: BaseComponentType
  filterSettings: SelectComponentFilters
}> = (props) => {
  const { field, component, filterSettings } = props
  const classes = useStyles()
  const fieldValue = component[field]?.toString()
  const translate = useTranslate()
  if (!fieldValue) {
    return <span className={classes.componentRating}>{translate('Not set')}</span>
  }
  var splitFieldIndices = [0]
  const matches = filterFieldMatches(filterSettings, component, field)
  if (matches) {
    matches.forEach((match) => {
      const indexOfMatch = fieldValue.toUpperCase().indexOf(match.toUpperCase())
      splitFieldIndices.push(indexOfMatch, indexOfMatch + match.length)
    })
  }
  // Iterate through the matches, storing the location of their first and last character
  splitFieldIndices.push(fieldValue.length)
  var splitFields: string[] = []
  for (let i = 0; i < splitFieldIndices.length - 1; i++) {
    splitFields.push(fieldValue.substring(splitFieldIndices[i], splitFieldIndices[i + 1]))
  }
  // Style the sections of the field which match
  var fieldComponents: JSX.Element[] = []
  for (let i = 0; i < splitFields.length; i++) {
    if (i % 2 === 0) {
      fieldComponents.push(<span key={i}>{splitFields[i]}</span>)
    } else {
      fieldComponents.push(
        <span key={i} className={classes.resultMatch}>
          {splitFields[i]}
        </span>
      )
    }
  }

  return (
    <span>
      {fieldComponents.map(function (object, i) {
        return object
      })}
    </span>
  )
}

interface ExtBaseComponentType extends BaseComponentType {
  componentActivationId?: number | string
  componentId?: string
  org_name?: string

  unsharedComponent?: string | false
  isSharedComponent?: string | false
  isSharedFromMyList?: string | false
}

function filterActiveComponents(
  activeComponentsData: AnyComponentType[],
  filter: SelectComponentFilters,
  componentType: string
): ExtBaseComponentType[] {
  console.log('activeCompnoet', filter?.require_distributor, activeComponentsData)
  var filterValuesParts = filter?.q ? filter.q.split(' ').filter((value) => value.length) : []
  var compatibleCodes
  if (componentType === 'battery' && filter?.compatibility === 'true') {
    compatibleCodes = getCompatibleCodesForSystem('battery')
  }
  var availableComponents: ExtBaseComponentType[] = lodash.cloneDeep(activeComponentsData)
  availableComponents = availableComponents.filter((component) => {
    if (filter?.owner_org) {
      if (component.org_id !== filter.owner_org) return false
    }

    if (filter?.require_stock === 'true') {
      if (component.ordering === undefined) {
        return false
      }
      const hasStock = component.ordering.some((distributor) => {
        const status = ProjectOrderPresenter.getStockLevelStatusMain({
          quantity: 1,
          stockLevels: distributor.stock_levels,
          isAvailable: distributor.is_available,
        })
        return status !== 'out_of_stock' && status !== 'not_available'
      })
      if (!hasStock) {
        return false
      }
    }

    if (filter?.require_distributor) {
      if (component.ordering?.find((ordering_item) => ordering_item.distributor === filter.require_distributor)) {
        //ok, keep it
      } else {
        return false
      }
    }

    if (compatibleCodes && compatibleCodes.length && !compatibleCodes.includes(component.code)) {
      return false
    }

    if (filter?.exhibitor_org_id) {
      if (filter.exhibitor_org_id !== component.exhibitor_org_id) {
        return false
      }
    }

    if (filter?.manufacturer) {
      if (filter.manufacturer !== component.manufacturer) {
        return false
      }
    }

    if (filter?.rating_range) {
      const range_parts = filter?.rating_range.split(',')
      const lowerRatingBand = parseFloat(range_parts[0])
      const higherRatingBand = parseFloat(range_parts[1])
      if (componentType === 'module') {
        if (!component.kw_stc || component.kw_stc < lowerRatingBand || component.kw_stc > higherRatingBand) return false
      } else if (componentType === 'inverter') {
        if (
          !component.max_power_rating ||
          component.max_power_rating < lowerRatingBand ||
          component.max_power_rating > higherRatingBand
        )
          return false
      } else if (componentType === 'battery') {
        if (
          !component.kwh_optimal ||
          component.kwh_optimal < lowerRatingBand ||
          component.kwh_optimal > higherRatingBand
        )
          return false
      }
    }

    if (filter?.current_rating_range) {
      const lowerRatingBand = Number(filter?.current_rating_range.split(',')[0])
      const higherRatingBand = Number(filter?.current_rating_range.split(',')[1])
      if (
        !component.current_rating ||
        component.current_rating < lowerRatingBand ||
        component.current_rating > higherRatingBand
      )
        return false
    }

    if (filter?.voltage_to_current_rating) {
      if (!component?.voltage_to_current_rating) return false
      const voltage_and_current_rating = filter.voltage_to_current_rating.split(',')
      const voltage_rating = voltage_and_current_rating[0]
      const current_rating = voltage_and_current_rating[1]
      Object.keys(component.voltage_to_current_rating).filter((voltage) => {
        //@ts-ignore
        return voltage < voltage_rating && component.voltage_to_current_rating[voltage] < current_rating
      })
    }

    if (filter?.current_type) {
      if (component?.current_type !== filter.current_type) return false
    }

    function getPropertyAsNumber(component, propName): number | null {
      if (component.hasOwnProperty(propName)) {
        const value = component[propName]
        return typeof value === 'number' ? value : parseFloat(value as string) || null
      }
      return null
    }

    if (
      filter?.cable_length &&
      getPropertyAsNumber(filter, 'cable_length') !== getPropertyAsNumber(component, 'cable_length')
    ) {
      return false
    }

    if (
      filter?.cable_thickness &&
      getPropertyAsNumber(filter, 'cable_thickness') !== getPropertyAsNumber(component, 'cable_thickness')
    ) {
      return false
    }

    if (filter?.colour) {
      if (component?.colour !== filter.colour) return false
    }

    const getComponentType = (component): InverterTypeFilter => {
      let componentType
      if (component.hasOwnProperty('hybrid') && component.hybrid === 'Y') {
        componentType = 'hybrid'
      } else if (component.hasOwnProperty('microinverter') && component.microinverter === true) {
        componentType = 'microinverter'
      } else {
        componentType = 'string'
      }
      return componentType
    }

    if (filter?.inverter_type) {
      if (filter?.inverter_type !== getComponentType(component)) return false
    }

    if (filter?.phase_type) {
      if (component?.phase_type !== filter.phase_type) return false
    }

    if (filter?.mppt_quantity) {
      if (component?.mppt_quantity !== parseInt(filter.mppt_quantity)) return false
    }

    if (filter?.current_ac_max) {
      if (component?.current_ac_max !== parseInt(filter.current_ac_max)) return false
    }

    if (filter?.product_warranty) {
      if (component?.product_warranty !== parseInt(filter.product_warranty)) return false
    }

    if (filter?.other_component_type) {
      if (component?.other_component_type !== filter.other_component_type) return false
    }

    if (filterValuesParts.length) {
      var matches = 0
      filterValuesParts.forEach((partMatch) => {
        var matchesRating = stringMatchesRating(partMatch, componentType, component)

        if (
          matchesRating ||
          containSubstring(component.code, partMatch) ||
          containSubstring(component.manufacturer_name, partMatch) ||
          (component?.kw_stc && String(kwToWatts(component.kw_stc)) === partMatch) ||
          (component.skus?.length > 0 && component.skus.some((sku) => containSubstring(sku, partMatch))) ||
          (component.title && containSubstring(component.title, partMatch))
        ) {
          matches++
        }
      })

      if (matches !== filterValuesParts.length) return false
    }
    return true
  })
  return availableComponents
}

function getUnsharedContent(component, sharedWith: ProjectShare[]) {
  const project = window.WorkspaceHelper.project
  const findOwner = sharedWith?.find((x) => x.org_id === project?.org_id)
  if (sharedWith && sharedWith?.length > 0) {
    if (component.share_with_orgs.length === 0) {
      if (project.org_id === component.org_id) {
        return sharedWith
          ?.map((item) => {
            return item.org_name
          })
          .join(', ')
      } else {
        return findOwner?.org_name
      }
    } else {
      if (project.org_id === component.org_id) {
        var notSharedList: string[] = []
        sharedWith?.forEach((x) => {
          const findShare = component.share_with_orgs.find((y) => y === x.org_id)
          if (!findShare) {
            notSharedList.push(x.org_name)
          }
        })
        if (notSharedList.length === 0) {
          return false
        } else {
          return notSharedList.join(', ')
        }
      } else {
        const findShare = component.share_with_orgs.find((y) => y === project.org_id)
        if (findShare) {
          return false
        } else {
          return findOwner?.org_name
        }
      }
    }
  } else {
    return false
  }
}

export const fetchComponents = (
  componentType: ComponentTypes,
  filterSettings: SelectComponentFilters,
  activeComponentsData: AnyComponentType[],
  callback: (components: ComponentType[], totalPages: number, includeResults: boolean) => void,
  orgId: number | undefined,
  sharedWith: ProjectShare[],
  includeResults: boolean
) => {
  /*
  Scenarios:
    1: No search terms or filtering: show activations only
    2: Search terms entered or filter applied: combine both activations and database results

    Note: When battery compatibility toggle is checked, this is treated like a search term, so db results will always
    be included.
  */
  const page = filterSettings.page || 1
  const filter = getAppliedFilters(filterSettings)
  const resultsPerPage = 8
  const includeActivations = page === 1
  const selectedOrgFilter = filter.owner_org !== undefined
  var components: ComponentType[] = []
  if (includeActivations) {
    let filteredComponents = filterActiveComponents(activeComponentsData, filter, componentType)
    filteredComponents.forEach((c) => {
      //Beware custom components which will not have componentId
      //We will use custom_{componentActivationId} instead to avoid clashes
      var componentIdFieldName = componentType + '_id'
      var componentId = c[componentIdFieldName] || 'custom_' + c.id
      var componentIdHybrid = componentId + '_' + c.id // Inject a hybrid ID to avoid clashes
      c.componentActivationId = c.id
      c.id = componentIdHybrid
      c.componentId = componentId
      c.hasActivation = true
      c.unsharedComponent = c.org_id === orgId ? getUnsharedContent(c, sharedWith) : false
    })
    components.push(...filteredComponents)
  }
  if (includeResults && !selectedOrgFilter) {
    filter.exclude_activated_for_org_id = orgId

    const API_URL = window.API_ROOT + '/api'
    const restClientInstance = restClient(API_URL)
    restClientInstance('GET_LIST', 'component_' + plural(componentType), {
      pagination: {
        page,
        perPage: resultsPerPage,
      },
      filter: hardwareFilterTransformer({ filterValues: filter, componentType }),
    }).then((response: ComponentResponse) => {
      const totalPages = Math.ceil(response.total / resultsPerPage)
      response.data.forEach((component) => {
        // Filter out activated components
        if (componentType === 'module') {
          if (
            !activeComponentsData.some(
              (activeComponent) => 'module_id' in activeComponent && activeComponent.module_id === component.id
            )
          ) {
            components.push(new ModuleType({ ...component, ...JSON.parse(component.data) }))
          }
        } else if (componentType === 'inverter') {
          if (
            !activeComponentsData.some(
              (activeComponent) => 'inverter_id' in activeComponent && activeComponent.inverter_id === component.id
            )
          ) {
            components.push(new InverterType({ ...component, ...JSON.parse(component.data) }))
          }
        } else if (componentType === 'battery') {
          if (
            !activeComponentsData.some(
              (activeComponent) => 'battery_id' in activeComponent && activeComponent.battery_id === component.id
            )
          ) {
            components.push(new BatteryType({ ...component, ...JSON.parse(component.data) }))
          }
        } else {
          if (
            !activeComponentsData.some(
              (activeComponent) => 'other_id' in activeComponent && activeComponent.other_id === component.id
            )
          ) {
            var componentData = JSON.parse(component.data)
            if (componentData.voltage_to_current_rating && typeof componentData.voltage_to_current_rating === 'string')
              componentData.voltage_to_current_rating = JSON.parse(componentData.voltage_to_current_rating)
            components.push(new OtherType({ ...component, ...componentData }))
          }
        }
      })
      callback(components, totalPages, includeResults)
    })
  } else {
    const totalPages = 1
    callback(components, totalPages, includeResults)
  }
}

type ComponentRowPropsType = {
  activeComponentsData: AnyComponentType[]
  component: ExtBaseComponentType
  showPrice: boolean
  filterSettings: SelectComponentFilters
  componentType: ComponentTypes
  projectForm?: FormApi
  sharedWith: ProjectShare[]
  projectOwnerId?: number
}

const ComponentRow: FC<ComponentRowPropsType> = ({
  activeComponentsData,
  componentType,
  component,
  showPrice,
  filterSettings,
  sharedWith,
  projectOwnerId,
}) => {
  const translate = useTranslate()
  // const sharedWith = projectForm?.getState().values['shared_with']
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const classes = useStyles()

  return (
    <div className={classes.componentRow} data-testid="component--row">
      <div data-testid="component--manufacturer_name">
        <span>
          {component.logo && component.logo.length > 0 ? (
            <img className={classes.logo} src={component.logo} />
          ) : (
            <div className={classes.logoPlaceholder} />
          )}
        </span>
        <ComponentSpecsField field="manufacturer_name" component={component} filterSettings={filterSettings} />
      </div>
      <div data-testid="component--title">
        {componentType === 'other' ? (
          <ComponentSpecsField field="title" component={component} filterSettings={filterSettings} />
        ) : (
          <ComponentSpecsRating componentType={componentType} component={component} filterSettings={filterSettings} />
        )}
      </div>
      <div className={classes.componentCode}>
        <ComponentSpecsField field="code" component={component} filterSettings={filterSettings} />
        {component.hasActivation && (
          <span>
            <div className={classes.myList}>
              {sharedWith?.length ? (
                <SharedEntityIcon
                  margin="normal"
                  textMode={true}
                  owner={component.org_id}
                  shared_ids={component.share_with_orgs}
                  projectSharedOrgs={sharedWith}
                  customSharedMessage={'My List (shared with %{orgs})'}
                  customUnsharedMessage={'✓ My List (not shared with %{orgs})'}
                  projectOwnerId={projectOwnerId}
                />
              ) : (
                translate('✓ My List')
              )}
            </div>
          </span>
        )}
      </div>
      {!isMobile && showPrice && (
        <PriceField
          component={component}
          selectedSupplierFilterValue={filterSettings.require_distributor || undefined}
        />
      )}
      {!isMobile && <ComponentOverview component={component} />}
      <div>
        <SelectButton record={component} activeComponentsData={activeComponentsData} />
      </div>
    </div>
  )
}

export type HardwareSelectionPropsType = {
  sharedWith: ProjectShare[]
  projectOwnerId?: number
}

const HardwareSelectionList: FC<HardwareSelectionPropsType> = ({ sharedWith = [], projectOwnerId }) => {
  const dispatch = useDispatch()
  const translate = useTranslate()
  const [components, setComponents] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [searchHasRun, setSearchHasRun] = useState(false)
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('xs'))
  //TODO: Fix this
  const orgId = useSelector(authSelectors.getOrgId)
  const countryIso2 = useSelector(orgSelectors.getOrgIso2)
  //TODO: this looks wrong
  const distributorEnabled = !!useSelector(orgSelectors.getEnabledHardwareSuppliers)
  const componentPricingCostingPermissions = useSelector(
    permissionsSelectors.getPermissionByKey('component_pricing_costing')
  )
  const componentType = useSelector((state: RootState) => state.selectComponent?.componentType) as ComponentTypes
  const adPlacementTop = `hardware_selection_${componentType}_top`
  const adPlacementBottom = `hardware_selection_${componentType}_bottom`
  const activeComponentsData = useActivatedComponents({
    type: componentType,
  })

  const filtersToOverride =
    (useSelector((state: RootState) => state.selectComponent?.filtersToOverride) as FilterOverridesType) || []

  // Note: ideally show price shouldn't depend on isActivatedComponentOrderingDataReady.
  // A proper way to handle it is to show loading animation when isActivatedComponentOrderingDataReady is not ready.
  // But this is just a edge case and we are expecting a refactor to this HardwareSelectionDialog
  const showPrice = distributorEnabled && componentPricingCostingPermissions.allowView
  const [filtersSchema, setFiltersSchema] = React.useState(getFiltersSchema(componentType))

  // Apply default backend hardware filters
  const [filterSettings, setFilterSettings] = useState(getDefaultFilterSettings(countryIso2))
  const persistentFilterSettings = useRef(getDefaultFilterSettings(countryIso2)) // TEST - should this include initialFilterSettingsToo?
  const [totalPages, setTotalPages] = useState(1)
  const [includeResults, setIncludeResults] = useState(false)

  // Apply filter settings to hide/default hardware filters
  const [initialFilterSettings, setInitialFilterSettings] = React.useState({})
  useEffect(() => {
    var newFilterSettings = { ...filterSettings }
    var newInitialFilterSettings = { ...filterSettings }
    if (filtersToOverride?.length) {
      filtersToOverride.forEach((filter) => {
        if (filter.value) {
          newFilterSettings[filter.key] = filter.value
          if (filter.hidden) {
            filtersSchema.forEach((filterSchema) => {
              if (filterSchema.key === filter.key) filterSchema.isHidden = () => true
            })
            newInitialFilterSettings[filter.key] = filter.value
          }
        }
        if (filter.options) {
          filtersSchema.forEach((filterSchema) => {
            if (filterSchema.key === filter.key) {
              // @ts-ignore
              filter.options.forEach((newOption) => {
                // If override option is a duplicate, we replace the existing option with the new one, else we add it as an extra
                const duplicateIndex = filterSchema.options.findIndex(
                  (existingOption) => existingOption.title === newOption.title
                )
                if (duplicateIndex === -1) {
                  filterSchema.options.push(newOption)
                } else {
                  filterSchema.options[duplicateIndex] = newOption
                }
              })
            }
          })
        }
      })
    }
    setInitialFilterSettings(newInitialFilterSettings)
    setFilterSettings(newFilterSettings)
    persistentFilterSettings.current = newFilterSettings
  }, [])

  function fetchComponentsCallback(components, totalPages, includeResults) {
    setComponents(components)
    setTotalPages(totalPages)
    setIncludeResults(includeResults)
    setIsLoading(false)
    if (!searchHasRun) {
      setSearchHasRun(true)
    }
  }

  const search = () => {
    if (isEqual(persistentFilterSettings.current, filterSettings)) {
      setIsLoading(true)
      const includeResults =
        userHasAppliedFilters(filtersSchema, filterSettings, true, countryIso2) || !!filterSettings?.q?.length
      fetchComponents(
        componentType,
        filterSettings,
        activeComponentsData,
        fetchComponentsCallback,
        orgId,
        sharedWith,
        includeResults
      )
    }
  }
  useEffect(() => {
    // TODO: clean up
    setTimeout(search, 350)
  }, [filterSettings, activeComponentsData])

  const setFilters = (newFilterSettings, overrideAll = false) => {
    if (!newFilterSettings.page) newFilterSettings.page = 1
    if (overrideAll === true) {
      setFilterSettings(newFilterSettings)
      persistentFilterSettings.current = newFilterSettings
    } else {
      setFilterSettings({ ...filterSettings, ...newFilterSettings })
      persistentFilterSettings.current = { ...filterSettings, ...newFilterSettings }
    }
  }

  const onExhibitorLogoClick = (newFilterSettings) => {
    setFilterSettings(newFilterSettings)
    persistentFilterSettings.current = newFilterSettings
  }

  var extraHeaderClass = ''
  if (!showPrice && !isMobile) {
    if (componentType === 'other') {
      extraHeaderClass = classes.componentTableOtherWithoutPrice
    } else {
      extraHeaderClass = classes.componentTableWithoutPrice
    }
  }

  return (
    <DialogContent classes={{ root: classes.content }}>
      {searchHasRun && (
        <BannerAd
          formats={['leaderboard']}
          placement={adPlacementTop}
          containerStyle={{ marginTop: 10, marginBottom: 20 }}
          requiredWindowSize={{ width: 960, height: 800 }}
        />
      )}

      <div className={classes.searchToolbarWrapper}>
        <SearchToolbar
          trackSource={'hardware_selection_dialog'}
          componentType={componentType}
          filtersSchema={filtersSchema}
          setFilters={setFilters}
          filterSettings={filterSettings}
          sharedWith={sharedWith}
          projectOwnerId={projectOwnerId}
          initialFilterSettings={initialFilterSettings}
        />
      </div>

      <div className={classes.componentSelectionWrapper}>
        <div
          className={`${classes.componentTable} ${
            componentType === 'other' ? classes.componentTableOther : ''
          } ${extraHeaderClass}`}
        >
          <div className={classes.componentHeader}>
            <div>{translate('Manufacturer')}</div>
            <div>{translate(componentType === 'other' ? 'Title' : 'Rating')}</div>
            <div>{translate('Code')}</div>
            {!isMobile && showPrice && <div>{translate('Price')}</div>}
            {!isMobile && <div></div>}
            <div></div>
          </div>
          {!isLoading && components.length === 0 ? (
            <div className={classes.componentTableMessage}>{translate('No results found.')}</div>
          ) : (
            components.map((component, index) => {
              return (
                <ComponentRow
                  key={index}
                  activeComponentsData={activeComponentsData}
                  component={component}
                  showPrice={showPrice}
                  filterSettings={filterSettings}
                  componentType={componentType}
                  sharedWith={sharedWith}
                  projectOwnerId={projectOwnerId}
                />
              )
            })
          )}
          {!userHasAppliedFilters(filtersSchema, filterSettings, true, countryIso2) && !filterSettings?.q?.length && (
            <div className={classes.componentTableMessage}>
              {translate('To search the full component database, add a search query or apply filters.')}
            </div>
          )}
        </div>
      </div>
      {includeResults && (
        <div className={classes.paginationNav}>
          {filterSettings.page && filterSettings.page > 1 ? (
            <Button
              onClick={() => {
                setFilters({ page: Number(filterSettings.page) - 1 })
              }}
              size="small"
              color="primary"
            >
              &lt; Prev
            </Button>
          ) : (
            <div></div>
          )}
          <div className={classes.pageCount}>
            <span>
              Page {filterSettings.page} / {totalPages ? totalPages : 1}
            </span>
          </div>
          {filterSettings.page && filterSettings.page < totalPages ? (
            <Button
              onClick={() => {
                setFilters({ page: Number(filterSettings.page) + 1 })
              }}
              size="small"
              color="primary"
            >
              Next &gt;
            </Button>
          ) : (
            <div></div>
          )}
        </div>
      )}
      <Exhibitors setFilters={onExhibitorLogoClick} filterValues={filterSettings} />
      {searchHasRun && (
        <BannerAd
          formats={['leaderboard']}
          placement={adPlacementBottom}
          containerStyle={{ marginTop: 10, marginBottom: 20 }}
          requiredWindowSize={{ width: 960, height: 800 }}
        />
      )}
      {isLoading && <LinearProgress style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }} />}
    </DialogContent>
  )
}

export default HardwareSelectionList

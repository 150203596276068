import { Dialog, DialogTitle } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import MosaicConnectRole from 'projectSections/sections/payments/errorPrompts/MosaicConnectRole'
import SungageConnectRole from 'projectSections/sections/payments/errorPrompts/SungageConnectRoles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ErrorPromptType,
  PROMPT_BRIGHTE_ROLE_ERROR_TYPE,
  PROMPT_PHOENIX_ROLE_TYPE,
  PROMPT_SUNGAGE_BATTERY_INPUT_TYPE,
  PROMPT_SWITCH_PROJECT_TYPE,
  projectErrorPromptsSelector,
  removeViewedErrorPrompt,
} from 'reducer/project/projectErrorPromptsReducer'
import BrighteRoleErrorPrompt from './BrighteRoleErrorPrompt'
import GenericEmailInputPrompt from './GenericEmailInputPrompt'
import PhoenixRolePrompt from './PhoenixRolePrompt'
import SungageBatteryInputPrompt from './SungageBatteryInputPrompt'
import SwitchProjectTypePrompt from './SwitchProjectTypePrompt'
import LightReachMoreInfo from './lightReach/LightReachMoreInfo'
import { CommonPromptPropTypes } from './types'

const PAYMENT_PROMPT_MAP = {
  [PROMPT_SUNGAGE_BATTERY_INPUT_TYPE]: SungageBatteryInputPrompt,
  [PROMPT_PHOENIX_ROLE_TYPE]: PhoenixRolePrompt,
  [PROMPT_BRIGHTE_ROLE_ERROR_TYPE]: BrighteRoleErrorPrompt,
  4: GenericEmailInputPrompt,
  5: SungageBatteryInputPrompt,
  6: MosaicConnectRole,
  7: SungageConnectRole,
  9: LightReachMoreInfo,
  10: LightReachMoreInfo,
}

export const PAYMENT_PROMPT_ID_TO_TITLE = {
  6: 'Start Selling Mosaic',
  7: 'Start Selling Sungage',
  9: 'Configure LightReach Payment Option',
  10: 'Configure LightReach Payment Option',
}

export const WHOLE_DIALOG_RENDERER = {
  [PROMPT_SWITCH_PROJECT_TYPE]: SwitchProjectTypePrompt,
}

const StudioErrorPrompt: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const projectErrorPrompts = useSelector(projectErrorPromptsSelector.getAllErrorPrompts)
  const nextPrompt = useSelector(projectErrorPromptsSelector.getNextPrompt)

  const [isOpen, setIsOpen] = useState<boolean>(true)
  const [currentPrompt, setCurrentPrompt] = useState<ErrorPromptType | undefined>(nextPrompt)

  useEffect(() => {
    if (!currentPrompt && nextPrompt) setCurrentPrompt(nextPrompt)
  }, [currentPrompt, nextPrompt])

  useEffect(() => {
    if (isOpen && nextPrompt?.promptId) {
      logAmplitudeEvent('design_error_prompt_shown', { prompt_id: nextPrompt.promptId })
    }
    if (!isOpen && nextPrompt) {
      setIsOpen(true)
    }
  }, [nextPrompt, isOpen])

  const closeDialog = () => {
    if (currentPrompt && currentPrompt.systemId) {
      setIsOpen(false)
      dispatch(removeViewedErrorPrompt(currentPrompt?.promptId, currentPrompt?.systemId))
      setCurrentPrompt(undefined)
    }
  }

  // Close current prompt if no longer in redux
  useEffect(() => {
    if (!currentPrompt) return
    const errorStillApplicable = projectErrorPrompts.some((prompt) => {
      return prompt.promptId === currentPrompt.promptId
    })
    if (!errorStillApplicable) {
      closeDialog()
    }
  }, [projectErrorPrompts, currentPrompt])

  const renderPrompt = () => {
    if (currentPrompt && currentPrompt.promptId && PAYMENT_PROMPT_MAP[currentPrompt.promptId]) {
      const PromptComponent: React.FC<CommonPromptPropTypes> = PAYMENT_PROMPT_MAP[currentPrompt.promptId]
      return (
        <PromptComponent
          message={currentPrompt.message}
          systemUuid={currentPrompt.systemId}
          onClose={closeDialog}
          integration={currentPrompt.integration}
          promptId={currentPrompt?.promptId}
          paymentOptionId={currentPrompt?.paymentOptionId || currentPrompt?.pmtId}
        />
      )
    }
  }
  if (!currentPrompt || !currentPrompt.systemId || !currentPrompt.message || !currentPrompt.promptId) return null

  const DialogComponent = WHOLE_DIALOG_RENDERER[currentPrompt.promptId]
  if (DialogComponent) {
    return (
      <DialogComponent
        message={currentPrompt.message}
        systemUuid={currentPrompt.systemId}
        onClose={closeDialog}
        integration={currentPrompt.integration}
        promptId={currentPrompt?.promptId}
        open={isOpen}
      />
    )
  }

  return (
    <Dialog open={isOpen} onClose={closeDialog}>
      <DialogTitle>{PAYMENT_PROMPT_ID_TO_TITLE[currentPrompt.promptId] || 'Warning'}</DialogTitle>
      {renderPrompt()}
    </Dialog>
  )
}

export default StudioErrorPrompt

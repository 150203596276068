import { logAmplitudeEvent } from 'amplitude/amplitude'
import { crudUpdate } from 'ra-core'
import restClient from 'restClient'

const restClientInstance = restClient(window.API_ROOT + '/api')

export const getRole = (url, orgId: null | number = null) => {
  if (orgId) {
    url = `orgs/${orgId}/roles/${url}/`
  }
  return restClientInstance('CUSTOM_GET', 'custom', {
    url: url,
  })
}

export const getPendingConnections = (orgId) => {
  const url = `orgs/${orgId}/connected_orgs/pending/`
  return restClientInstance('CUSTOM_GET', 'custom', {
    url: url,
  })
}

export const acceptConnection = (record) => {
  const url = `orgs/${record.org_to_id}/connected_orgs/accept_connection/`
  return restClientInstance('CUSTOM_POST', 'custom', {
    url: url,
    data: {
      org_to_id: record.org_from_id,
    },
  }).then((res) => {
    logAmplitudeEvent('org_connection_accepted', { org_name: res.data.org_name })
    return res
  })
}

export const deleteConnectionRequest = (record) => {
  const url = `orgs/${record.org_from_id}/connected_orgs/${record.item_id}/`
  return restClientInstance('CUSTOM_DELETE', 'custom', {
    url: url,
  }).then((res) => {
    logAmplitudeEvent('org_connection_rejected', { org_name: res.data.org_name })
    return res
  })
}

// Returns an action that must be dispatched to redux
export const toggleConnection = (toggle, record) => {
  return crudUpdate(
    'connected_orgs',
    record.id,
    {
      is_active: toggle,
    },
    record,
    '/connected_orgs',
    false
  )
}

import React, { useMemo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import {
  FieldPrefixFilterType,
  FormRouteInfoPropTypes,
  OnboardingFormPageInfoType,
  PSPOnboardingFieldsMapType,
} from './types'

type PropTypes = {
  initialPageNumber: number
  fieldMap: PSPOnboardingFieldsMapType
  pageInfo: OnboardingFormPageInfoType
  onPageSubmit: (saveAndContinue?: boolean) => void
  goBack: () => void
  navigateToPage: (routeKey: string) => void
  setFieldPrefixFilter: (filter: FieldPrefixFilterType) => void
  fieldPrefixFilter: FieldPrefixFilterType
  closeDialog: () => void
}

const useStyles = makeOpenSolarStyles((theme) => ({
  mainWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 10px',
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  rightColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '1.5rem',
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  backButtonBox: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  continueButtonsBox: {
    flex: 2,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonWrapper: {
    padding: '10px 20px',
  },
  submitButton: {
    width: '100%',
    fontSize: 13,
  },
}))

const BlueSnapOnboardingFormContents: React.FC<PropTypes> = (props) => {
  const classes = useStyles()

  const childProps = useMemo(() => {
    return {
      pageNumber: props.pageInfo?.fieldPageNumber,
      fieldMap: props.fieldMap,
      sectionName: props.pageInfo?.sectionName,
      fieldPrefixFilter: props.fieldPrefixFilter,
      setFieldPrefixFilter: props.setFieldPrefixFilter,
      navigateToPage: props.navigateToPage,
      onPageSubmit: props.onPageSubmit,
      closeDialog: props.closeDialog,
    } as FormRouteInfoPropTypes
  }, [props.pageInfo, props.fieldMap, props.fieldPrefixFilter, props.fieldPrefixFilter])

  return (
    <div>
      <div className={classes.mainWrapper}>
        <div className={classes.leftColumn}>
          {props.pageInfo?.topLeft?.component ? <props.pageInfo.topLeft.component {...childProps} /> : null}
          {props.pageInfo?.bottomLeft?.component ? <props.pageInfo.bottomLeft.component {...childProps} /> : null}
        </div>
        {props.pageInfo?.right?.component && (
          <div className={classes.rightColumn}>
            {props.pageInfo?.right?.component ? <props.pageInfo.right.component {...childProps} /> : null}
          </div>
        )}
      </div>
    </div>
  )
}
export default BlueSnapOnboardingFormContents

import { useTheme } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import classnames from 'classnames'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import { memo, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import useSegenAuthenticationStatus from 'services/segen/useSegenAuthenticationStatus'
import useDistributorAuthenticationStatus from 'services/useDistributorAuthenticationStatus'
import { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { getSupplierConfig } from '../configs'
import useEnabledHardwareSuppliers from '../hooks/useEnabledHardwareSuppliers'
import SupplierSelector from './SupplierSelector'

const restClientInstance = restClient(window.API_ROOT + '/api')

const useSupplierWidgetStyles = makeOpenSolarStyles(() => ({
  container: {
    color: '#ffffff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 175,
    position: 'relative',
  },
}))

const useSupplierButtonStyles = makeOpenSolarStyles((theme) => ({
  wrapperLogo: ({ isConnected }: { isConnected: boolean }) => ({
    display: 'flex',
    width: '120px',
    height: '50px',
    position: 'relative',
    lineHeight: '50px',
    textAlign: 'center',
    backgroundColor: '#ffffff',
    borderRadius: '4px',
    cursor: isConnected ? 'default' : 'pointer',
  }),
  wrapperText: {
    display: 'flex',
    height: '40px',
    width: '130px',
    position: 'relative',
    textAlign: 'center',
    color: theme.greyDark2,
    border: `1px solid ${theme.greyLight1}`,
    borderRadius: '4px',
  },
  img: {
    verticalAlign: 'middle',
    maxWidth: 90,
    margin: '9px',
    marginLeft: '20px',
  },
  iconDecorator: {
    left: 5,
    top: 5,
    position: 'absolute',
  },
  availableIcon: {
    width: 7,
    height: 7,
    borderRadius: '50%',
    background: 'green',
  },
  selectorIcon: { display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'right' },
  settingIcon: { color: theme.greyDark2, width: 10, height: 10 },
  supplierName: {
    width: '85px',
    color: theme.greyDark2,
    minWidth: '90px',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '15px',
    justifyContent: 'center',
  },
}))

export type SupplierButtonMode = 'text' | 'logo'

export const SupplierButton = memo(
  ({
    supplier,
    onClick,
    isConnected,
    canChangeSupplier,
    mode,
  }: {
    supplier: HardwareSupplierEnum
    onClick?(): Promise<void>
    isConnected: boolean
    canChangeSupplier: boolean
    mode: SupplierButtonMode
  }) => {
    const theme: OpenSolarThemeType = useTheme()
    const config = getSupplierConfig(supplier)

    const classes = useSupplierButtonStyles({ isConnected: isConnected })

    return (
      <>
        <div className={mode === 'logo' ? classes.wrapperLogo : classes.wrapperText} onClick={onClick}>
          {isConnected ? (
            <span className={classnames(classes.availableIcon, classes.iconDecorator)} />
          ) : (
            <SettingsOutlinedIcon className={classnames(classes.settingIcon, classes.iconDecorator)} />
          )}

          {mode === 'logo' ? (
            <img className={classes.img} alt="supplier-logo" src={config?.logoUrl || ''} />
          ) : (
            <div className={classes.supplierName}>{config?.name}</div>
          )}

          {canChangeSupplier && (
            <div className={classes.selectorIcon}>
              <KeyboardArrowDownIcon width={4} htmlColor={theme.greyDark2} />
            </div>
          )}
        </div>
      </>
    )
  }
)

const SegenSupplierButton = ({ canChangeSupplier, mode }: { canChangeSupplier: boolean; mode: SupplierButtonMode }) => {
  const authenticationStatus = useSegenAuthenticationStatus()

  return (
    <>
      <SupplierButton
        supplier={HardwareSupplierEnum.Segen}
        isConnected={authenticationStatus === 'authenticated'}
        canChangeSupplier={canChangeSupplier}
        mode={mode}
      />
    </>
  )
}

const SolarOutletSupplierButton = memo(
  ({ canChangeSupplier, mode }: { canChangeSupplier: boolean; mode: SupplierButtonMode }) => {
    return (
      <SupplierButton
        supplier={HardwareSupplierEnum.SolarOutlet}
        isConnected={true}
        canChangeSupplier={canChangeSupplier}
        mode={mode}
      />
    )
  }
)

const CityPlumbingSupplierButton = ({
  canChangeSupplier,
  mode,
  orgId,
}: {
  canChangeSupplier: boolean
  mode: SupplierButtonMode
  orgId: number
}) => {
  const { isAuthenticated } = useDistributorAuthenticationStatus({ orgId, distributor: 'city_plumbing' })

  return (
    <SupplierButton
      supplier={HardwareSupplierEnum.CityPlumbing}
      isConnected={isAuthenticated}
      canChangeSupplier={canChangeSupplier}
      mode={mode}
    />
  )
}

const HdmSupplierButton = ({
  canChangeSupplier,
  mode,
  orgId,
}: {
  canChangeSupplier: boolean
  mode: SupplierButtonMode
  orgId: number
}) => {
  const { isAuthenticated } = useDistributorAuthenticationStatus({ orgId, distributor: 'hdm' })

  return (
    <SupplierButton
      supplier={HardwareSupplierEnum.Hdm}
      isConnected={isAuthenticated}
      canChangeSupplier={canChangeSupplier}
      mode={mode}
    />
  )
}

const VtacUkSupplierButton = ({
  canChangeSupplier,
  mode,
  orgId,
}: {
  canChangeSupplier: boolean
  mode: SupplierButtonMode
  orgId: number
}) => {
  const country = useSelector(orgSelectors.getOrgIso2) || 'GB'
  const { isAuthenticated } = useDistributorAuthenticationStatus({
    orgId,
    distributor: country === 'GB' ? 'vtac_uk' : 'vtac_pl',
  })

  return (
    <SupplierButton
      supplier={HardwareSupplierEnum.VtacUk}
      isConnected={isAuthenticated}
      canChangeSupplier={canChangeSupplier}
      mode={mode}
    />
  )
}

const VtacPolandSupplierButton = ({
  canChangeSupplier,
  mode,
  orgId,
}: {
  canChangeSupplier: boolean
  mode: SupplierButtonMode
  orgId: number
}) => {
  const { isAuthenticated } = useDistributorAuthenticationStatus({ orgId, distributor: 'vtac_pl' })

  return (
    <SupplierButton
      supplier={HardwareSupplierEnum.VtacPoland}
      isConnected={isAuthenticated}
      canChangeSupplier={canChangeSupplier}
      mode={mode}
    />
  )
}

const SupplierWidget = ({ mode, hideSupplierLabel }: { mode: SupplierButtonMode; hideSupplierLabel?: boolean }) => {
  const classes = useSupplierWidgetStyles()
  const [supplierSelectorActive, setSupplierSelectorActive] = useState<boolean>(false)
  const orgIso2 = useSelector(orgSelectors.getOrgIso2)
  const selectedSupplier = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const [supplierAnchorEl, setSupplierAnchorEl] = useState<HTMLElement | null>(null)
  const orgId = useSelector(authSelectors.getOrgId) || 0

  const enabledSuppliers = useEnabledHardwareSuppliers()

  const canChangeSupplier = useMemo(() => {
    return enabledSuppliers.length > 1
  }, [enabledSuppliers])

  const SupplierButton = useMemo(() => {
    if (selectedSupplier === HardwareSupplierEnum.Segen) {
      return <SegenSupplierButton canChangeSupplier={canChangeSupplier} mode={mode} />
    }
    if (selectedSupplier === HardwareSupplierEnum.SolarOutlet || orgIso2 === 'AU') {
      return <SolarOutletSupplierButton canChangeSupplier={canChangeSupplier} mode={mode} />
    }
    if (selectedSupplier === HardwareSupplierEnum.CityPlumbing) {
      return <CityPlumbingSupplierButton canChangeSupplier={canChangeSupplier} mode={mode} orgId={orgId} />
    }
    if (selectedSupplier === HardwareSupplierEnum.Hdm) {
      return <HdmSupplierButton canChangeSupplier={canChangeSupplier} mode={mode} orgId={orgId || 0} />
    }
    if (selectedSupplier === HardwareSupplierEnum.VtacUk) {
      return <VtacUkSupplierButton canChangeSupplier={canChangeSupplier} mode={mode} orgId={orgId} />
    }
    if (selectedSupplier === HardwareSupplierEnum.VtacPoland) {
      return <VtacPolandSupplierButton canChangeSupplier={canChangeSupplier} mode={mode} orgId={orgId} />
    }
    // Give CP a chance to render first but default to Segen.
    if (orgIso2 === 'GB' || orgIso2 === 'UK') {
      return <SegenSupplierButton canChangeSupplier={canChangeSupplier} mode={mode} />
    }
    return null
  }, [selectedSupplier, orgIso2])

  const handleSupplierSelected = () => {
    setSupplierAnchorEl(null)
    setSupplierSelectorActive(false)
  }

  const WrappedSupplierButton = useMemo(() => {
    if (enabledSuppliers.length <= 1) {
      return SupplierButton
    }

    return (
      <a
        onClick={(event) => {
          setSupplierAnchorEl(supplierAnchorEl ? null : event.currentTarget)
          setSupplierSelectorActive((supplierSelectorActive) => !supplierSelectorActive)
        }}
      >
        {SupplierButton}
      </a>
    )
  }, [SupplierButton, selectedSupplier])

  return (
    <>
      <div>
        <div className={classes.container}>
          {!hideSupplierLabel && mode !== 'text' && (
            <div>
              <span>Suppliers:&nbsp;</span>
            </div>
          )}
          <div>
            {WrappedSupplierButton}
            {supplierSelectorActive && (
              <SupplierSelector
                enabledSuppliers={enabledSuppliers}
                onSupplierSelected={handleSupplierSelected}
                anchor={supplierAnchorEl}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default memo(SupplierWidget)

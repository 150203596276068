import { logAmplitudeEvent } from 'amplitude/amplitude'
import HorizontalDropdownWithLabel from 'pages/inventory/components/HorizontalDropdownWithLabel'
import React, { useCallback, useMemo } from 'react'
import type { ForecastDetailDialogController } from '../../forecast/detail/useForecastDetailDialogController'
import type { ForecastedProjectInventory } from '../../forecast/type'

const ProjectSelector = ({
  selectedProject,
  updateFilter,
  forecastedProjectInventoryData,
}: {
  selectedProject?: number
  updateFilter: ForecastDetailDialogController['setFilter']
  forecastedProjectInventoryData: ForecastedProjectInventory[] | undefined
}) => {
  const handleProjectSelected = useCallback(
    (projectId: number) => {
      const newSelectedForecastProjectInventory = forecastedProjectInventoryData?.find(
        (data) => data.project_id === projectId
      )
      if (!newSelectedForecastProjectInventory) {
        console.error('Unexpected project id selected')
        return
      }
      const item = newSelectedForecastProjectInventory.items_required_allocated[0]
      updateFilter({
        forecastEndDate: newSelectedForecastProjectInventory.project.pickup_date,
        projectId: newSelectedForecastProjectInventory.project_id,
        code: item?.code,
        componentType: item?.component_type,
      })
      logAmplitudeEvent('generic_dropdown_interacted', {
        source: 'project_selector',
        action: 'inventory_forecast_project_selected',
      })
    },
    [forecastedProjectInventoryData]
  )

  const projectOptions = useMemo(() => {
    return (
      forecastedProjectInventoryData?.map((inventory) => ({
        value: inventory.project_id,
        name: inventory.project.title,
      })) || []
    )
  }, [forecastedProjectInventoryData])

  return (
    <HorizontalDropdownWithLabel
      label={'Project'}
      value={selectedProject}
      handleSelect={handleProjectSelected}
      options={projectOptions}
    />
  )
}

export default ProjectSelector

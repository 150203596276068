import { viewModeSelectors } from 'ducks/viewMode'
import React, { createContext, useState } from 'react'
import { useSelector } from 'react-redux'
import ActionButtons from './ActionButtons'
import ActivityPanel from './activityTab/ActivityTab'
import OverViewPanel from './overviewTab/Overview'
import RecentPanel from './recentTab/RecentTab'

const defaultState = {
  recentFilters: null,
  userActivities: null,
  customerActivities: null,
  myProjectsData: null,
  allProjectsData: null,
  tagsData: null,
}

const defaultContext = {
  state: defaultState,
  setState: (state: any) => {},
}
export const SidePanelContext = createContext(defaultContext)

const SidePanel = () => {
  const homeDrawerState = useSelector(viewModeSelectors.homeDrawerState)
  const [state, setState] = useState(defaultState)

  return (
    <SidePanelContext.Provider value={{ state, setState }}>
      {homeDrawerState === 'recent' && <RecentPanel />}
      {homeDrawerState === 'activity' && <ActivityPanel />}
      {homeDrawerState === 'overview' && <OverViewPanel />}
      <ActionButtons />
    </SidePanelContext.Provider>
  )
}

export default SidePanel

import { JsonSchema, UISchemaElement } from '@jsonforms/core'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { openCreditDecisionDialog } from 'ducks/myEnergy'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import { useNotify, useTranslate } from 'ra-core'
import React, { useEffect, useState } from 'react'
import { Form, useFormState } from 'react-final-form'
import { useDispatch } from 'react-redux'
import restClient from 'restClient'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { ProjectType } from 'types/projects'
import { HostedDisclosureType } from '../types'
import HostedCreditAppFormContent from './HostedCreditAppFormContent'
import { useInitialValuesByIntegration } from './initialValueSetters'
import LoadingOverlay from './LoadingOverlay'
import VALIDATORS_BY_INTEGRATION from './validators'

const restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  onClose: () => void
  orgId: number
  projectId: number
  systemUuid: string
  paymentOptionId: number
  integration: string
  setIsLoading: (val: boolean) => void
  isLoading: boolean
  project: ProjectType
  disclosures?: HostedDisclosureType[]
  form_json?: FormJSONType | null
  initialValues: object
}

export type FormJSONType = {
  schema: JsonSchema
  uiSchema: UISchemaElement
}

type DisclosureResponseData = {
  success: boolean
  disclosures: HostedDisclosureType[]
  integration_display_name: string
  logo_url: string
  form_json: FormJSONType | null
}

type DisclosuresResponseType = {
  data: DisclosureResponseData
}

const useStyles = makeOpenSolarStyles((theme) => ({
  logoWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  actionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorMsg: {
    color: 'red',
  },
  buttonsRow: {
    display: 'flex',
    width: '100%',
  },
  buttonWrapper: {
    margin: '15px',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  mainContentWrapper: {
    padding: '10px',
  },
}))

const HostedCreditApplicationDialog: React.FC<PropTypes> = (props) => {
  const [hostedDisclosures, setHostedDisclosures] = useState<HostedDisclosureType[]>(props.disclosures || [])
  const [logoURL, setLogoURL] = useState<string | undefined>(undefined)
  const [integrationDisplayName, setIntegrationDisplayName] = useState<string | undefined>(undefined)
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const [formJSON, setFormJSON] = useState<FormJSONType | null>(props.form_json || null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const translate = useTranslate()
  const dispatch = useDispatch()
  const notify = useNotify()
  const classes = useStyles()
  const formState = useFormState()

  useEffect(() => {
    if (!props.disclosures) {
      getApplicationDisclosures()
    }
  }, [])

  const getApplicationDisclosures = () => {
    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${props.orgId}/projects/${props.projectId}/systems/${props.systemUuid}/${props.paymentOptionId}/hosted_credit_app/`,
    })
      .then((response: DisclosuresResponseType) => {
        if (response?.data?.success) {
          setHostedDisclosures(response?.data?.disclosures)
          setLogoURL(response?.data?.logo_url)
          setIntegrationDisplayName(response?.data?.integration_display_name)
          setFormJSON(response?.data?.form_json)
        }
      })
      .catch((err) => {
        if (err?.body?.message) {
          notify(translate(err.body.message), 'warning')
          logAmplitudeEvent('integrated_finance_application_open_error', {
            integration: props?.integration,
          })
        }
      })
      .finally(() => {
        props.setIsLoading(false)
      })
  }

  const onSubmit = () => {
    let validator = VALIDATORS_BY_INTEGRATION[props.integration]
    if (validator) {
      const error = validator(formState.values)
      console.log('error', error)
      setErrorMsg(error)
      if (error) return
    }
    setIsLoading(true)
    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${props.orgId}/projects/${props.projectId}/systems/${props.systemUuid}/${props.paymentOptionId}/${props.integration}/submit_application/`,
      data: formState.values,
    })
      .then((res) => {
        if (res?.data?.decision) {
          logAmplitudeEvent('integrated_finance_application_submitted', {
            integration: props.integration,
            decision: res.data.decision,
            project_id: props.project?.id,
            payment_option_id: props?.paymentOptionId,
          })
          const decisionDisplayProps = {
            decision: res.data.decision,
            stips: res?.data?.stipulations || [],
          }
          dispatch(openCreditDecisionDialog('generic', decisionDisplayProps))
          props.onClose()
        }
      })
      .catch((err) => {
        if (err?.body?.message) {
          setErrorMsg(err.body.message)
        } else {
          setErrorMsg('Something went wrong and we were unable to process your credit application')
        }
      })
      .finally(() => setIsLoading(false))
  }

  const dismiss = () => {
    const confirm = window.confirm('Are you sure you want to exit your finance application? All Progress will be list.')
    if (confirm) props.onClose()
  }

  if (props.isLoading) return null
  return (
    <Dialog open={true} onClose={props.onClose}>
      <DialogTitle>Apply for Financing with {integrationDisplayName || 'Our Lending Partner'} </DialogTitle>
      <DialogContent>
        <div className={classes.mainContentWrapper}>
          {isLoading && <LoadingOverlay />}
          {logoURL && (
            <div className={classes.logoWrapper}>
              <img height="75px" src={logoURL}></img>
            </div>
          )}
          <HostedCreditAppFormContent
            onClose={props.onClose}
            integration={props.integration}
            disclosures={hostedDisclosures}
            form_json={formJSON}
            initialValues={props.initialValues}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.actionsWrapper}>
          <div>{errorMsg && <span className={classes.errorMsg}>{errorMsg}</span>}</div>
          <div className={classes.buttonsRow}>
            <div className={classes.buttonWrapper}>
              <ProUXButton label="Dismiss" type="secondary" onClick={dismiss} />
            </div>
            <div className={classes.buttonWrapper}>
              <ProUXButton label="Submit Application" type="primary" onClick={onSubmit} disabled={isLoading} />
            </div>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  )
}

const FormWrappedDialog: React.FC<PropTypes> = (props) => {
  const validate = (formVals: object) => {
    let validator = VALIDATORS_BY_INTEGRATION[props.integration]
    if (validator) {
      const error = validator(formVals)
    }
  }

  const initialValues = useInitialValuesByIntegration(props.integration, props.project)
  return (
    <Form
      onSubmit={validate}
      initialValues={initialValues}
      render={() => <HostedCreditApplicationDialog {...props} initialValues={initialValues} />}
    />
  )
}
export default FormWrappedDialog

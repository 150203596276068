import { makeStyles, TableCell, TableRow, Tooltip, useMediaQuery } from '@material-ui/core'
import { Info, Star, TrendingUpOutlined } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import {
  addAllPaymentOptionsForIntegration,
  paymentOptionSelectionSelectors,
  removeAllPaymentOptionsForIntegration,
  updateSelectedPaymentOptions,
} from 'ducks/paymentOptionSelection'
import { Checkbox } from 'opensolar-ui'
import { useLocale, useNotify, useTranslate } from 'ra-core'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useFormState } from 'react-final-form'
import Skeleton from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux'
import { useOpenSolarTheme } from 'themes/useOpenSolarTheme'
import { RootState } from 'types/state'
import { Theme } from 'types/themes'
import { currencySymbolForCountry, formatCurrencyWithSymbol } from 'util/misc'
import { ALL_OR_NOTHING_ADDED_WARNING, ALL_OR_NOTHING_REMOVED_WARNING } from '../constants'
import { useGetIntegration, useGetMostUrgentMessage } from '../hooks'
import IntegratedChip from '../IntegratedChip'
import {
  ALL_OR_NOTHING_INTEGRATIONS,
  INTEGRATIONS_WITHOUT_QUICK_CALCS,
  PAYMENT_FREQUENCY_MAP,
  ROW_WIDTHS,
} from './constants'
import { CalculatedPaymentOption, PAYMENT_OPTION_MESSAGE_TYPE_ENUM } from './types'

type PropTypes = {
  pmt: CalculatedPaymentOption | undefined
  isLoading: boolean
  billSavings?: number
}

const useStyles = makeStyles<
  Theme,
  { isLoading: boolean; messageType: PAYMENT_OPTION_MESSAGE_TYPE_ENUM | undefined; isMobile: boolean }
>((theme) => ({
  row: {
    padding: '2px',
    height: '60px',
    //@ts-ignore
    opacity: ({ isLoading }) => (isLoading ? 0.5 : 1.0),
    // @ts-ignore
    backgroundColor: ({ messageType }) => {
      switch (messageType) {
        case 'error':
          return theme.alertBg_error
        case 'warning':
          return theme.alertBg_warning
        case 'disabled':
          return theme.greyMid2
        default:
          return undefined
      }
    },
  },
  cell: {
    padding: '0px 10px 0px 0px',
    maxWidth: '320px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '250px',
    },
  },
  trimmableText: {
    maxWidth: '320px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '250px',
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  stackedCellContents: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  pmtTitleRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  centeredStackedCellContents: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  savingsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  stackedCellRow: {
    height: '30px',
  },
  lenderDisplayName: {
    color: theme.greyMid1,
    fontSize: '12px',
    textAlign: 'center',
    width: '100%',
  },
  messageRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  messageIconWrapper: {
    marginRight: '5px',
    display: 'flex',
    flexdirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  messageText: {
    fontSize: '10px',
    // @ts-ignore
    color: ({ messageType }) => {
      switch (messageType) {
        case 'error':
          return theme.alert_error
        case 'warning':
          return theme.alert_warning
        case 'disabled':
          return theme.greyDark2
        default:
          return undefined
      }
    },
  },
}))

const PaymentOptionSelectionRow: React.FC<PropTypes> = (props) => {
  const messageToShow = useGetMostUrgentMessage(props.pmt?.messages)

  useEffect(() => {
    if (messageToShow) {
      logAmplitudeEvent('payment_option_error_shown', {
        message: messageToShow.message || messageToShow.text,
        type: messageToShow.type,
        error_prompt_id: messageToShow.error_prompt_id,
        placement: 'payment option selection dialog',
      })
    }
  }, [messageToShow])

  const project = useFormState().values
  const isSelected = useSelector(
    (state: RootState) =>
      props.pmt && paymentOptionSelectionSelectors.getIsSelected(state, props.pmt?.payment_option_id)
  )
  const currencySymbol = currencySymbolForCountry(project.country_iso2)
  const integration = useGetIntegration(props.pmt?.integration)

  const dispatch = useDispatch()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const classes = useStyles({ isLoading: props.isLoading, messageType: messageToShow?.type, isMobile })
  const openSolarTheme = useOpenSolarTheme()
  const locale = useLocale()
  const translate = useTranslate()
  const notify = useNotify()

  // returns the expected monthly savings for this system once you account for the repayment.
  // If the savings are negative or there is no calculated repayment then undefined is returned and nothing is rendered in that column
  const monthlySavings = useMemo(() => {
    if (props.billSavings !== undefined) {
      const repaymentToUse = props.pmt?.repayment || 0
      if (repaymentToUse !== undefined && !props.pmt?.messages?.length) {
        const savings = props.billSavings - repaymentToUse
        if (savings > 0) return savings.toFixed(0)
      }
    }
    return undefined
  }, [props.billSavings, props.pmt?.repayment, props.pmt?.payment_type, props.pmt?.messages])

  const amplitudePayload = useMemo(() => {
    return {
      integration: props.pmt?.integration,
      payment_type: props.pmt?.payment_type,
      payment_frequency: props.pmt?.payment_frequency,
      system_price: props.pmt?.system_price,
      repayment: props.pmt?.repayment,
      interest_rate: props.pmt?.interest_rate,
      term_years: props.pmt?.term_years,
      monthly_savings: monthlySavings,
      payment_option_id: props.pmt?.payment_option_id,
    }
  }, [props.pmt, monthlySavings])

  useEffect(() => {
    logAmplitudeEvent('payment_option_explorer_row_shown', amplitudePayload)
  }, [])

  const toggleSelection = useCallback(() => {
    // if not CURRENTLY selected, then it means they just selected it. Log an event
    if (!isSelected) {
      logAmplitudeEvent('payment_option_explorer_row_selected', amplitudePayload)
    }

    if (props.pmt) {
      // certain integrations require that either none of their payment options are included or all of them are. When that integration
      // is selected/unselected we'll use different action selectors
      if (!props.pmt?.integration || !ALL_OR_NOTHING_INTEGRATIONS?.includes(props.pmt?.integration)) {
        dispatch(updateSelectedPaymentOptions(props.pmt))
      } else {
        if (isSelected) {
          dispatch(removeAllPaymentOptionsForIntegration(props.pmt.integration))
          notify(translate(ALL_OR_NOTHING_ADDED_WARNING, 'info'))
        } else {
          dispatch(addAllPaymentOptionsForIntegration(props.pmt?.integration))
          notify(translate(ALL_OR_NOTHING_REMOVED_WARNING, 'info'))
        }
      }
    }
  }, [isSelected])

  const messageIconColor = useMemo(() => {
    switch (messageToShow?.type) {
      case 'error':
        return 'rgb(140, 38, 33)'
      case 'warning':
        return 'rgb(149, 103, 36)'
      case 'disabled':
        return 'rgb(100, 100, 100)'
      default:
        return undefined
    }
  }, [messageToShow?.type])

  const hideFinancials = useMemo(() => {
    return props.pmt?.integration && INTEGRATIONS_WITHOUT_QUICK_CALCS?.includes(props.pmt.integration)
  }, [props.pmt?.integration])

  // for initial loading state before we have any payment options to show
  if (!props.pmt) {
    return (
      <TableRow className={classes.row}>
        <TableCell></TableCell>
        <TableCell className={classes.cell} width={ROW_WIDTHS[0]}>
          <Skeleton height="55px" />
        </TableCell>
        <TableCell className={classes.cell} width={ROW_WIDTHS[1]}>
          <Skeleton height="55px" />
        </TableCell>
        {!isMobile && (
          <TableCell className={classes.cell} width={ROW_WIDTHS[2]}>
            <Skeleton height="55px" />
          </TableCell>
        )}
        {!isMobile && (
          <TableCell className={classes.cell} width={ROW_WIDTHS[3]}>
            <Skeleton height="55px" />
          </TableCell>
        )}
        <TableCell className={classes.cell} width={ROW_WIDTHS[4]}>
          <Skeleton height="55px" />
        </TableCell>
        {!isMobile && (
          <TableCell className={classes.cell} width={ROW_WIDTHS[5]}>
            <Skeleton height="55px" />
          </TableCell>
        )}
      </TableRow>
    )
  }

  // subsequent loading states will just add opacity to the row
  return (
    <TableRow className={classes.row}>
      <TableCell width={ROW_WIDTHS[0]} className={classes.cell}>
        <Checkbox checked={isSelected} onChange={toggleSelection} disabled={messageToShow?.type === 'disabled'} />
      </TableCell>
      <TableCell width={ROW_WIDTHS[1]} className={classes.cell}>
        <div className={classes.stackedCellContents}>
          {messageToShow && (
            <div className={classes.messageRow}>
              <div className={classes.messageIconWrapper}>
                <Info htmlColor={messageIconColor} fontSize="small" />
              </div>
              <div className={classes.messageText}>{messageToShow.message}</div>
            </div>
          )}

          <div className={classes.pmtTitleRow}>
            {props.pmt.is_popular && (
              <Tooltip title={translate('Most popular')}>
                <Star htmlColor="rgb(249, 216, 73)" />
              </Tooltip>
            )}
            <Tooltip title={props.pmt.title}>
              <div className={classes.trimmableText}>{props.pmt.title}</div>
            </Tooltip>
          </div>
          <div>{props.pmt.integration && <IntegratedChip />}</div>
        </div>
      </TableCell>
      {!isMobile && (
        <TableCell width={ROW_WIDTHS[2]} className={classes.cell}>
          <div className={classes.stackedCellContents}>
            <div className={classes.centeredStackedCellContents}>
              {integration && integration?.logo_small_contents && (
                <Tooltip title={integration.display_name}>
                  <img height="25px" src={integration.logo_small_contents} alt={integration?.display_name} />
                </Tooltip>
              )}
              {integration && integration?.display_name && (
                <div className={classes.lenderDisplayName}>{integration.display_name}</div>
              )}
            </div>
          </div>
        </TableCell>
      )}
      <TableCell width={ROW_WIDTHS[3]} className={classes.cell}>
        <div className={classes.centeredStackedCellContents}>
          {!props.pmt.repayment && (messageToShow || hideFinancials)
            ? 'N/A'
            : formatCurrencyWithSymbol(props.pmt.repayment, currencySymbol, locale, 0)}
          {props.pmt.repayment && props.pmt.payment_frequency && props.pmt.payment_frequency !== 'monthly' && (
            <div className={classes.lenderDisplayName}>
              {translate('Paid %{frequency}', {
                frequency: PAYMENT_FREQUENCY_MAP[props.pmt.payment_frequency] || props.pmt.payment_frequency,
              })}
            </div>
          )}
        </div>
      </TableCell>
      {!isMobile && (
        <TableCell width={ROW_WIDTHS[4]} className={classes.cell}>
          <div className={classes.centeredStackedCellContents}>
            <div className={classes.savingsRow}>
              {monthlySavings && !hideFinancials
                ? formatCurrencyWithSymbol(monthlySavings, currencySymbol, locale, 0)
                : ''}
              {monthlySavings && !hideFinancials && <TrendingUpOutlined htmlColor={openSolarTheme.alert_success} />}
            </div>
          </div>
        </TableCell>
      )}
      {!isMobile && (
        <TableCell width={ROW_WIDTHS[5]} className={classes.cell}>
          <div className={classes.centeredStackedCellContents}>
            {formatCurrencyWithSymbol(props.pmt.system_price, currencySymbol, locale, 0)}
          </div>
        </TableCell>
      )}
      {!isMobile && (
        <TableCell width={ROW_WIDTHS[6]} className={classes.cell}>
          {props.pmt.interest_rate !== null && props.pmt.payment_type !== 'ppa'
            ? `${props.pmt.interest_rate_disclaimer ? props.pmt.interest_rate_disclaimer : ''}${
                props.pmt.interest_rate
              }%`
            : ''}
        </TableCell>
      )}
      {!isMobile && (
        <TableCell width={ROW_WIDTHS[7]} className={classes.cell}>
          {props.pmt.term_years !== null
            ? translate('%{term_years} Years', {
                term_years: props.pmt.term_years ? Math.round(props.pmt.term_years) : 0,
              })
            : ''}
        </TableCell>
      )}
    </TableRow>
  )
}
export default PaymentOptionSelectionRow

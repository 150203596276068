import { useMediaQuery } from '@material-ui/core'
import { EditOutlined } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { setSelectTariffDialog } from 'ducks/selectTariff'
import React, { useCallback } from 'react'
import { Button } from 'react-admin'
import { useFormState } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Theme } from 'types/themes'
import getDefaultPathForSelectTariffsDialog from './selectTariffDialog/getDefaultPathForSelectTariffsDialog'

type PropTypes = {
  currentOrProposed: 'current' | 'proposed'
  countryId?: number
}

const SelectNewTariffButton: React.FC<PropTypes> = ({ countryId, currentOrProposed }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const formState = useFormState()
  const formValues = formState?.values
  const isResidential = formValues?.is_residential
  const projectId = formValues?.id
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const handleClick = useCallback(
    (e) => {
      e.preventDefault()
      history.push(getDefaultPathForSelectTariffsDialog(history.location.pathname, projectId, isResidential))
      dispatch(setSelectTariffDialog(true, projectId, countryId, currentOrProposed, false))
      logAmplitudeEvent('select_tariff_clicked', {
        currentOrProposed,
      })
    },
    [projectId]
  )
  return (
    <Button
      children={isMobile ? <EditOutlined /> : undefined}
      variant="outlined"
      label="Select Tariff"
      size="small"
      onClick={handleClick}
    />
  )
}

export default SelectNewTariffButton

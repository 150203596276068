import { makeStyles } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import { authSelectors } from 'ducks/auth'
import CustomField from 'elements/field/CustomField'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import { useTranslate } from 'ra-core'
import React from 'react'
import { SelectInput } from 'react-admin'
import { useSelector } from 'react-redux'
import DocusignSettingsSection from 'resources/contracts/DocusignSettingsSection'

interface FieldTypes {
  disabled: boolean
}

export const IntegrationField: React.FC<FieldTypes> = ({ disabled }) => {
  const translate = useTranslate()
  const docusign_account_connected = useSelector(authSelectors.getDocusignAccountConnected)
  if (!docusign_account_connected) return null
  return (
    <>
      <span style={{ minWidth: 100, maxWidth: 100, marginRight: 10 }}>{translate('Integration')}</span>
      <CustomField
        component={SelectInput}
        fullWidth={true}
        multiline={true}
        choices={[
          { id: 0, name: 'None' },
          { id: 1, name: 'DocuSign' },
        ]}
        name="integration"
        source="integration"
        label={false}
        disabled={disabled}
      />
    </>
  )
}

export const useStyles = makeStyles((theme: OpenSolarThemeType) => ({
  accordion: {
    marginTop: '20px !important',
    '& .MuiAccordionDetails-root': {
      padding: '0px 30px 20px',
    },
  },
}))

interface PropTypes {
  disabled: boolean
}

const DocusignSettings: React.FC<PropTypes> = ({ disabled }) => {
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <AccordionCard
      className={classes.accordion}
      title={translate('DocuSign Settings')}
      name={'docusign-settings'}
      disabled={disabled}
      content={DocusignSettingsSection}
      titleIcon={() => <></>}
      lazyRender={true}
    />
  )
}
export default DocusignSettings

// import Button from 'elements/button/Button'
import { Button, CheckOutlineIcon, CloseOutlineIcon, styled } from 'opensolar-ui'
import { SaveButton, Toolbar } from 'react-admin'

const InlineEditToolbar = styled(Toolbar)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  backgroundColor: 'white',
  padding: 0,
  margin: 0,
  height: 'calc(100% - 4px) !important',
  top: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    alignItems: 'end',
    flexDirection: 'column',
    width: '60px',
    height: '90px !important',
  },
  '& button': {
    minWidth: '40px !important',
    width: '40px',
    height: '40px',
  },
}))

const CancelMiniButton = styled(Button)(({ theme }) => ({
  '& span': {
    marginRight: 0,
    marginLeft: 0,
  },
}))

const SaveMiniButton = styled(SaveButton)(({ theme }) => ({
  marginRight: 0,
  '& span, & svg': {
    marginRight: 0,
    marginLeft: 0,
  },
}))

export const EditInlineRowButtons = ({ record, ...props }) => {
  return (
    <InlineEditToolbar>
      <CancelMiniButton
        variant="text"
        startIcon={<CloseOutlineIcon color="#BC1C1C" />} //htmlColor="#BC1C1C"
        color="secondary"
        onClick={props.onDismiss}
        id={`close-form-on-esc-btn--${props.fieldId}`}
      />
      <SaveMiniButton
        label=""
        variant="text"
        color="secondary"
        submitOnEnter={true}
        id={`save-form-on-enter-btn--${props.fieldId}`}
        onSave={(values: object, redirect: any, form: any, defaultSaveFunc: Function) => {
          return defaultSaveFunc(values, redirect, { onSuccess: props.onSuccessEdit })
        }}
        {...props}
        icon={<CheckOutlineIcon color="#018030" />} //fill="#018030"
      />
    </InlineEditToolbar>
  )
}

export const SET_VIEW_AS_CUSTOMER = 'SET_VIEW_AS_CUSTOMER'

export const setViewAsCustomer = (value) => ({
  type: SET_VIEW_AS_CUSTOMER,
  payload: value,
})

const defaultState = false

export default function reducer(previousState = defaultState, { type, payload }) {
  if (type === SET_VIEW_AS_CUSTOMER) {
    return !!payload
  }
  return previousState
}

export const viewAsCustomerSelectors = {
  getViewAsCustomer: (state) => !!state.viewAsCustomer,
}

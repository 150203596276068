import { logAmplitudeEvent } from 'amplitude/amplitude'
import React, { useEffect, useRef } from 'react'
import { useIsOnScreen } from '../utils'

type PropTypes = {
  index: number
  recordCount: number
  eventName?: 'payments_page_system_visible'
  onVisibleChange?: (isVisible: boolean) => void
}

const VisbilityLogger: React.FC<PropTypes> = (props) => {
  const ref = useRef(null)
  const isVisible = useIsOnScreen(ref)

  useEffect(() => {
    if (isVisible) {
      if (props.eventName) {
        logAmplitudeEvent(props.eventName, {
          index: props.index,
          count: props.recordCount,
        })
      }
    }
    if (props.onVisibleChange) {
      props.onVisibleChange(isVisible)
    }
  }, [isVisible])

  return <div ref={ref}></div>
}
export default VisbilityLogger

import { logAmplitudeEvent } from 'amplitude/amplitude'
import BannerAd from 'elements/BannerAd'
import { AVAILABILITY_FILTER_KEY } from 'elements/hardwareFilter/stockAvailability/AvailabilityFilterNodeFactory'
import { memo, useEffect, useMemo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import type { ComponentTypes } from 'types/selectComponent'
import TopBanner from './banner/TopBanner'
import QueryControlledCatalog from './catalog/QueryControlledCatalog'
import ShopItemsWithFilter from './catalogv3/ShopItemsWithFilter'

import { useFeatureFlag } from '../../util/split'
import { mapComponentTypesV1ToV2 } from '../../util/misc'

const useStyles = makeOpenSolarStyles((theme) => ({
  container: { margin: '20px 0' },
  containerV3: {margin: '20px 0', background: '#F6F9FC'}
}))

const getShopResultsPageAdPlacement = ({
  location,
  searchText,
  componentType,
}: {
  location: 'top' | 'bottom'
  searchText: string
  componentType?: ComponentTypes
}): string => {
  if (componentType) {
    return `shop_category_${componentType}_${location}`
  } else if (searchText) {
    return `shop_search_${location}`
  }
  return ''
}

const TRACKING_SOURCE = 'hardware_search_catalog_view'

/*
    Page url formats:
      /shop/search/{searchText}
      or
      /shop/search/{componentType}

    Filter query params example:
      /shop/search/module/?filter={filter key value pairs}

    Note: Filter query params change will not trigger a catalog remount, but if the searchText and componentType are changed a remount is expected.

    @searchText:
        search text for filtering
    @componentType:
        component type used for filtering purpose
*/

const ShopCatalog = ({ searchText, componentType }: { searchText: string; componentType?: ComponentTypes }) => {
  const enabledHardwareShopV3 = useFeatureFlag('enable_multi_distributor_shop', 'on')

  if (enabledHardwareShopV3) {
    return <ShopItemsWithFilter searchText={searchText} trackSource={TRACKING_SOURCE} componentType={mapComponentTypesV1ToV2(componentType)} />
  }

  const initialFilterValues = {
    q: searchText,
    [AVAILABILITY_FILTER_KEY]: 'true',
  }

  const title = searchText && <h1>{`Search Results: ${searchText}`}</h1>
  return (
    <QueryControlledCatalog
      trackSource={TRACKING_SOURCE}
      initialFilterValues={initialFilterValues}
      title={title}
      componentType={componentType}
    />
  )
}

const ShopResultsPage = ({ searchText, componentType }: { searchText: string; componentType?: ComponentTypes }) => {
  const classes = useStyles()
  const enabledHardwareShopV3 = useFeatureFlag('enable_multi_distributor_shop', 'on')
  useEffect(() => {
    logAmplitudeEvent('page_viewed', {
      page: TRACKING_SOURCE,
      searchText,
      componentType,
    })
  }, [])

  const adPlacementBottom = getShopResultsPageAdPlacement({ location: 'bottom', searchText, componentType })
  const topBannerAdPlacememnt = getShopResultsPageAdPlacement({ location: 'top', searchText, componentType })

  return (
    <div className={enabledHardwareShopV3 ? classes.containerV3 : classes.container}>
      <TopBanner adBannerPlacement={topBannerAdPlacememnt} />
      <ShopCatalog searchText={searchText} componentType={componentType} />
      {!!adPlacementBottom && (
        <BannerAd
          formats={['leaderboard']}
          placement={adPlacementBottom}
          containerStyle={{ marginTop: 10, marginBottom: 20 }}
          requiredWindowSize={{ width: 960 }}
        />
      )}
    </div>
  )
}

export default memo(ShopResultsPage)

import { orgSelectors } from 'ducks/orgs'
import { paymentOptionSelectionSelectors } from 'ducks/paymentOptionSelection'
import { projectMilestonesSelectors } from 'ducks/projectMilestones'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { StudioSystemType } from 'types/global'
import { AvailableFinanceIntegrationType } from 'types/orgs'
import { PaymentOptionDataType } from 'types/paymentOptions'
import { MESSAGE_TYPE_PRIORITY } from './paymentOptionSelection/constants'
import { CalculatedPaymentOptionMessageType } from './paymentOptionSelection/types'

export const useGetIntegration = (integrationName: string | null | undefined) => {
  const [integration, setIntegration] = useState<AvailableFinanceIntegrationType | undefined>(undefined)

  const availableIntegrations = useSelector(orgSelectors.getAvailableFinanceIntegrations)

  useEffect(() => {
    if (availableIntegrations?.length && integrationName) {
      let foundIntegration = availableIntegrations?.find(
        (integration) => integration.integration_name === integrationName
      )
      setIntegration(foundIntegration)
    }
  }, [availableIntegrations, integrationName])

  return integration
}

const STATUS_SORTING_MAP = {
  active: 2,
  shared: 1,
}

export const useGetSortedIntegrations = (
  onlyResidential: boolean,
  onlyCommercial: boolean,
  onlyInactive: boolean,
  onlyActive: boolean
) => {
  const [sortedIntegrations, setSortedIntegrations] = useState<AvailableFinanceIntegrationType[]>()

  const availableIntegrations = useSelector(orgSelectors.getAvailableFinanceIntegrations)
  const org = useSelector(orgSelectors.getOrg)

  useEffect(() => {
    if (availableIntegrations) {
      let integrationsToDisplay = availableIntegrations?.filter(
        (integration) =>
          !integration.banner_content?.hide_from_payment_option_selection_dialog &&
          ((integration.support_residential && onlyResidential) || (integration.support_commercial && onlyCommercial))
      )
      let sortedIntegrations = integrationsToDisplay
        .filter((integration) => {
          if (!onlyInactive && !onlyActive) return true
          // filter based on status
          if (onlyInactive && onlyActive) {
            console.warn('Both active and inactive integrations are being filtered out')
            return false
          }
          let status = STATUS_SORTING_MAP[org?.[`enable_${integration.integration_name}`]] || 0
          if (onlyInactive) {
            return !status
          } else if (onlyActive) {
            return !!status
          }
          return true
        })
        .sort((a, b) => {
          let aStatus = STATUS_SORTING_MAP[org?.[`enable_${a.integration_name}`]] || 0
          let bStatus = STATUS_SORTING_MAP[org?.[`enable_${b.integration_name}`]] || 0
          return bStatus - aStatus
        })
        ?.sort((a, b) => {
          let aPriority = a?.banner_content?.priority || 99
          let bPriority = b?.banner_content?.priority || 99
          return aPriority - bPriority
        })
      setSortedIntegrations(sortedIntegrations)
    }
  }, [availableIntegrations])

  return sortedIntegrations
}

export const useGetMostUrgentMessage = (messages: CalculatedPaymentOptionMessageType[] | undefined) => {
  const [messageToShow, setMessageToShow] = useState<CalculatedPaymentOptionMessageType | null>(null)

  useEffect(() => {
    // only show one message at a time, sort by priority and pick the first one
    if (messages?.length) {
      let sortedMessages = messages?.sort((a, b) => {
        let aPriority = MESSAGE_TYPE_PRIORITY[a.type] || 3
        let bPriority = MESSAGE_TYPE_PRIORITY[b.type] || 3
        return aPriority - bPriority
      })
      setMessageToShow(sortedMessages[0])
    } else if (messageToShow) {
      setMessageToShow(null)
    }
  }, [messages])

  return messageToShow
}

export const useGetMonthlyBillSavings = (system: StudioSystemType | undefined) => {
  const [billSavings, setBillSavings] = useState<number>(0)

  useEffect(() => {
    if (system && system.bills) {
      let currentAnnual = system?.bills?.current?.bills_yearly[0]?.annual?.total || 0
      let proposalAnnual = Object.values(system?.bills?.proposed)[0]?.bills_yearly[0]?.annual?.total || 0
      setBillSavings((currentAnnual - proposalAnnual) / 12)
    }
  }, [])

  return billSavings
}

export const useGetCurrentAverageBill = (system: StudioSystemType | undefined) => {
  const [averageBill, setAverageBill] = useState<number>(0)

  useEffect(() => {
    if (system && system.bills) {
      let currentAnnual = system?.bills?.current?.bills_yearly[0]?.annual?.total || 0
      setAverageBill(currentAnnual / 12)
    }
  }, [])

  return averageBill
}

export const useGetMilestones = (pmtId: number | undefined, systemUuid: string | undefined | null, projectId: number) => {
  // NOTE - this gets milestones directly from calcs saved on the system and can therefore be stale. Only use this function
  // if you're only interested in the payment amounts. Use useGetPaymentRequets if having updated milestone status values is important
  const milestoneRefreshTrigger = useSelector(projectMilestonesSelectors.getRefreshMilestonesTrigger)
  const systemRereshTriger = useSelector(paymentOptionSelectionSelectors.getSystemRefreshTrigger)

  const milestones = useMemo(() => {
    if (!pmtId || !systemUuid) return undefined
    const thisSystem = window.editor.getSystems()?.find((sys) => sys.uuid === systemUuid)
    if (thisSystem && pmtId) {
      const expectedMilestones = thisSystem?.payment_options?.find((pmt) => pmt.id === pmtId)
        ?.expected_milestone_payments
      if (expectedMilestones?.length) return expectedMilestones
    }
    return undefined
  }, [pmtId, systemUuid, milestoneRefreshTrigger, systemRereshTriger])

  return milestones
}

export const useSystem = (systemUuid: string | null) => {
  const system = useMemo(() => {
    if (systemUuid) {
      return window.editor.getSystems()?.find((sys) => sys.uuid === systemUuid)
    }
  }, [systemUuid])

  return system
}

export const useIsProjectUsingCashFlow = (
  soldSystem: StudioSystemType | undefined,
  soldPaymentOption: PaymentOptionDataType | undefined,
  refreshSystemsTrigger: string | undefined
) => {
  const fetchSystems = () => window.editor?.getSystems() || []
  const [systems, setSystems] = useState<StudioSystemType[]>(fetchSystems())

  useEffect(() => {
    if (refreshSystemsTrigger) {
      setSystems([...fetchSystems()])
    }
  }, [refreshSystemsTrigger])

  const selectedSystem =
    soldSystem || systems.find((s) => s.payment_options?.some((p) => p.expected_milestone_payments?.length))

  const selectedPaymentOption =
    soldPaymentOption || selectedSystem?.payment_options?.find((p) => p.expected_milestone_payments?.length)

  const isProjectUsingCashflow = useMemo(() => !!selectedPaymentOption?.expected_milestone_payments?.length, [
    selectedPaymentOption?.expected_milestone_payments,
  ])

  return isProjectUsingCashflow
}

import { Button, styled, TableIcon } from 'opensolar-ui'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { SectionHeader } from '../styles'

const MidSection = styled('div')(({ theme }) => ({
  marginBottom: 25,
  paddingBottom: 15,
  borderBottom: '1px solid ' + theme.palette.secondary.light,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}))

const ButtonStyled = styled(Button)({
  justifyContent: 'flex-start',
})

const ViewOptions: React.FC = (props) => {
  const form = useForm()
  const translate = useTranslate()
  const formValues = useFormState().values
  const isTableView = useMemo(() => formValues.isTableView, [formValues.isTableView])

  const setTableView = (status) => {
    form.change('isTableView', status)
  }
  return (
    <>
      <SectionHeader>{translate('View Options')}</SectionHeader>
      <MidSection>
        <ButtonStyled
          startIcon={<TableIcon />}
          disableRipple
          variant="text"
          color="secondary"
          onClick={() => {
            setTableView(true)
          }}
          style={{ color: isTableView ? COLOR_PALETTE.darkBlue2 : undefined }}
        >
          {translate('Table View')}
        </ButtonStyled>
        {/* <Button
          startIcon={<BarChartSquareIcon transform={'rotate(180deg)'} />}
          disableRipple
          variant="text"
          color="secondary"
          onClick={() => {
            setTableView(false)
          }}
          style={{ color: isTableView ? undefined : COLOR_PALETTE.darkBlue2 }}
        >
          {translate('Kanban View')}
        </Button> */}
      </MidSection>
    </>
  )
}

export default ViewOptions

import { Typography } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import ProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/projectOrderPresenter'
import RegisterLink from 'pages/ordering/detail/content/RegisterLink'
import type { DistributorDataType, StockLevelStatus, StockLevelType } from 'pages/ordering/type'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import React, { Suspense, memo } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const StatusMessage = React.lazy(() => import('pages/ordering/StatusMessage'))

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: () => ({
    display: 'inline-flex',
    flexWrap: 'wrap',
    lineHeight: 1,
    fontWeight: 'bold',
  }),
  segment: {
    marginLeft: 5,
  },
}))

const PureStockMessage = ({
  status,
  quantity,
  priceMessage,
}: {
  status: StockLevelStatus
  quantity?: number
  priceMessage?: string
}) => {
  // const isAvailable = ProjectOrderPresenter.isStockLevelReadyToOrder(status)

  const classes = useStyles()
  return (
    <Typography variant="caption" className={classes.wrapper}>
      <Suspense fallback={<div />}>{<StatusMessage status={status} />}</Suspense>
      {quantity != null && <span className={classes.segment}>({quantity})</span>}
      {priceMessage && <span className={classes.segment}>{priceMessage}</span>}
    </Typography>
  )
}

const StockMessage = ({
  distributor,
  showPrice,
  currencySymbol,
}: {
  distributor: DistributorDataType | undefined
  showPrice: boolean
  currencySymbol: string
}) => {
  const distributorRegistrationRequired = useSelector(orgSelectors.distributorRegistrationRequired)
  const selectedSupplier = useSelector(orgSelectors.getSelectedHardwareSupplier)

  if (distributorRegistrationRequired === HardwareSupplierEnum.SolarOutlet) {
    return <RegisterLink />
  }

  const status = ProjectOrderPresenter.getStockLevelStatusMain({
    quantity: 0,
    stockLevels: distributor?.stock_levels,
    isAvailable: !!distributor?.is_available,
  })

  const inStockLevel = distributor?.stock_levels?.find((stock: StockLevelType) => stock.in_stock)
  const priceMessage =
    showPrice && distributor != null
      ? `at ${currencySymbol}${(Math.round(distributor.price * 100) / 100).toFixed(2)} each`
      : ''

  // Hide stock level for Solar Outlet
  const quantity = selectedSupplier === HardwareSupplierEnum.SolarOutlet ? undefined : inStockLevel?.quantity
  return <PureStockMessage status={status} quantity={quantity} priceMessage={priceMessage} />
}

export default memo(StockMessage)

import { useMediaQuery, useTheme } from '@material-ui/core'
import type { PaperProps } from '@material-ui/core/Paper'
import Slide from '@material-ui/core/Slide'
import { BaseThemeType } from 'opensolar-ui'
import React, { useCallback, useState } from 'react'
import type { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import DrawerPull from './DrawerPull'

export const getDefaultDrawerTheme = (theme: OpenSolarThemeType): DrawerThemeType => {
  return {
    highlightColor: theme.primaryColor,
    highlightColorInteraction: theme.primaryColorOnHover,
    textColorOnHighlight: theme.greyDark2,
  }
}

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    width: '100%',
    zIndex: theme.zIndex.drawer,
    pointerEvents: 'none',
    position: 'relative',
    height: '100%',
    minHeight: 'inherit',
    overflow: 'hidden',
  },
  drawer: ({ side, drawerTheme }: { side: SideDrawerSide; drawerTheme: DrawerThemeType }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    minHeight: 'inherit',
    height: 'inherit',
    ...(side === 'left' && {
      flexDirection: 'row-reverse',
    }),
  }),
  pull: ({ side, drawerTheme }: { side: SideDrawerSide; drawerTheme: DrawerThemeType }) => ({
    ...(!!drawerTheme && {
      background: drawerTheme.highlightColor,
      color: drawerTheme.textColorOnHighlight,
      '&:hover': {
        background: drawerTheme.highlightColorInteraction,
      },
    }),
    pointerEvents: 'all',
    borderRadius: side === 'left' ? '0px 4px 4px 0px' : '4px 0px 0px 4px',
    boxShadow: side === 'left' ? '2px 2px 4px rgba(0, 0, 0, 0.2)' : '-2px 2px 4px rgba(0, 0, 0, 0.2)',
  }),
  paper: ({ side, drawerTheme }: { side: SideDrawerSide; drawerTheme: DrawerThemeType }) => ({
    width: 450,
    pointerEvents: 'all',
    overflow: 'auto',
    backgroundColor: theme.white,
    borderColor: drawerTheme.highlightColor,
    borderStyle: 'solid',
    borderWidth: side === 'left' ? '4px 4px 4px 0' : '4px 0 4px 4px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]: { width: 280 },
  }),
}))

type DrawerThemeType = {
  highlightColor: string
  highlightColorInteraction: string
  textColorOnHighlight: string
}

type SideDrawerSide = 'left' | 'right'

type SideDrawerPropsType = {
  open: boolean
  disabled: boolean
  unmountOnExit: boolean // Important to keep form inputs mounted
  PaperProps?: Partial<PaperProps>
  children: React.ReactNode
  DrawerPullLabel: string
  setOpen(open: boolean): void
  side: SideDrawerSide
  drawerTheme?: DrawerThemeType
}

const SideDrawer = ({
  open,
  setOpen,
  children,
  side,
  disabled,
  DrawerPullLabel,
  drawerTheme,
  unmountOnExit,
}: SideDrawerPropsType) => {
  const theme: OpenSolarThemeType = useTheme()
  const classes = useStyles({ side, drawerTheme: drawerTheme || getDefaultDrawerTheme(theme) })
  const expansionTowardDirection = side === 'left' ? 'right' : 'left'
  const foldTowardDirection = expansionTowardDirection === 'left' ? 'right' : 'left'
  const [showExpandPull, setShowExpandPull] = useState<boolean>(!open)
  const isMobile = useMediaQuery((theme: BaseThemeType) => theme.breakpoints.down('xs'))
  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <div className={classes.wrapper}>
      {showExpandPull && (
        <div className={classes.drawer} style={{ marginTop: isMobile ? 60 : 0 }}>
          <DrawerPull
            className={classes.pull}
            side={side}
            arrowDirection={expansionTowardDirection}
            disabled={disabled}
            handleClick={handleOpen}
          >
            {DrawerPullLabel}
          </DrawerPull>
        </div>
      )}
      <Slide
        direction={expansionTowardDirection}
        in={open}
        mountOnEnter
        unmountOnExit={unmountOnExit}
        onEnter={() => setShowExpandPull(false)}
        onExited={() => setShowExpandPull(true)}
      >
        <div className={classes.drawer}>
          <DrawerPull
            className={classes.pull}
            disabled={false}
            side={side}
            arrowDirection={foldTowardDirection}
            handleClick={handleClose}
          >
            {DrawerPullLabel}
          </DrawerPull>
          <div className={classes.paper}>{children}</div>
        </div>
      </Slide>
    </div>
  )
}

export default SideDrawer

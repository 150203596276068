const FrameWidthPortraitIcon = () => (
  <svg width="100%" height="100%" viewBox="0 0 232 233" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.514648" width="232" height="232" fill="white" />
    <path d="M9 80.7434L9 38.0315L9 30.3434" stroke="#FFAB1F" strokeWidth="3" strokeLinecap="round" />
    <path d="M7 30.3433L10.3898 30.3433L11 30.3433" stroke="#FFAB1F" strokeWidth="3" strokeLinecap="round" />
    <path d="M7 81.3433L10.3898 81.3433L11 81.3433" stroke="#FFAB1F" strokeWidth="3" strokeLinecap="round" />
    <path d="M14 30.6475L45 27.343V51.0385L14 54.343V30.6475Z" fill="#727272" />
    <path
      d="M19.8333 40.2598L17.9249 42.3434L19.8333 44.4264"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M46 27.343L77 30.6475V54.343L46 51.0385V27.343Z" fill="#727272" />
    <path
      d="M71.1667 40.2598L73.0751 42.3434L71.1667 44.4264"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14 57.6475L45 54.343V78.0385L14 81.343V57.6475Z" fill="#727272" />
    <path d="M46 54.343L77 57.6475V81.343L46 78.0385V54.343Z" fill="#727272" />
    <path
      d="M19.8333 66.2598L17.9249 68.3434L19.8333 70.4264"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71.1667 66.2598L73.0751 68.3434L71.1667 70.4264"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M84 30.6475L115 27.343V51.0385L84 54.343V30.6475Z" fill="#727272" />
    <path
      d="M89.8333 40.2598L87.9249 42.3434L89.8333 44.4264"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M116 27.343L147 30.6475V54.343L116 51.0385V27.343Z" fill="#727272" />
    <path
      d="M141.167 40.2598L143.075 42.3434L141.167 44.4264"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M84 57.6475L115 54.343V78.0385L84 81.343V57.6475Z" fill="#727272" />
    <path d="M116 54.343L147 57.6475V81.343L116 78.0385V54.343Z" fill="#727272" />
    <path
      d="M89.8333 66.2598L87.9249 68.3434L89.8333 70.4264"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M141.167 66.2598L143.075 68.3434L141.167 70.4264"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M154 30.6475L185 27.343V51.0385L154 54.343V30.6475Z" fill="#727272" />
    <path
      d="M159.833 40.2598L157.925 42.3434L159.833 44.4264"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M186 27.343L217 30.6475V54.343L186 51.0385V27.343Z" fill="#727272" />
    <path
      d="M211.167 40.2598L213.075 42.3434L211.167 44.4264"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M154 57.6475L185 54.343V78.0385L154 81.343V57.6475Z" fill="#727272" />
    <path d="M186 54.343L217 57.6475V81.343L186 78.0385V54.343Z" fill="#727272" />
    <path
      d="M159.833 66.2598L157.925 68.3434L159.833 70.4264"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M211.167 66.2598L213.075 68.3434L211.167 70.4264"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14 160.647L45 157.343V181.039L14 184.343V160.647Z" fill="#727272" />
    <path
      d="M19.8333 170.26L17.9249 172.343L19.8333 174.426"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M46 157.343L77 160.647V184.343L46 181.039V157.343Z" fill="#727272" />
    <path
      d="M71.1667 170.26L73.0751 172.343L71.1667 174.426"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14 187.647L45 184.343V208.039L14 211.343V187.647Z" fill="#727272" />
    <path d="M46 184.343L77 187.647V211.343L46 208.039V184.343Z" fill="#727272" />
    <path
      d="M19.8333 196.26L17.9249 198.343L19.8333 200.426"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71.1667 196.26L73.0751 198.343L71.1667 200.426"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M84 160.647L115 157.343V181.039L84 184.343V160.647Z" fill="#727272" />
    <path
      d="M89.8333 170.26L87.9249 172.343L89.8333 174.426"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M116 157.343L147 160.647V184.343L116 181.039V157.343Z" fill="#727272" />
    <path
      d="M141.167 170.26L143.075 172.343L141.167 174.426"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M84 187.647L115 184.343V208.039L84 211.343V187.647Z" fill="#727272" />
    <path d="M116 184.343L147 187.647V211.343L116 208.039V184.343Z" fill="#727272" />
    <path
      d="M89.8333 196.26L87.9249 198.343L89.8333 200.426"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M141.167 196.26L143.075 198.343L141.167 200.426"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M154 160.647L185 157.343V181.039L154 184.343V160.647Z" fill="#727272" />
    <path
      d="M159.833 170.26L157.925 172.343L159.833 174.426"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M186 157.343L217 160.647V184.343L186 181.039V157.343Z" fill="#727272" />
    <path
      d="M211.167 170.26L213.075 172.343L211.167 174.426"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M154 187.647L185 184.343V208.039L154 211.343V187.647Z" fill="#727272" />
    <path d="M186 184.343L217 187.647V211.343L186 208.039V184.343Z" fill="#727272" />
    <path
      d="M159.833 196.26L157.925 198.343L159.833 200.426"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M211.167 196.26L213.075 198.343L211.167 200.426"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14 95.6475L45 92.343V116.039L14 119.343V95.6475Z" fill="#727272" />
    <path
      d="M19.8333 105.26L17.9249 107.343L19.8333 109.426"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M46 92.343L77 95.6475V119.343L46 116.039V92.343Z" fill="#727272" />
    <path
      d="M71.1667 105.26L73.0751 107.343L71.1667 109.426"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14 122.647L45 119.343V143.039L14 146.343V122.647Z" fill="#727272" />
    <path d="M46 119.343L77 122.647V146.343L46 143.039V119.343Z" fill="#727272" />
    <path
      d="M19.8333 131.26L17.9249 133.343L19.8333 135.426"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71.1667 131.26L73.0751 133.343L71.1667 135.426"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M84 95.6475L115 92.343V116.039L84 119.343V95.6475Z" fill="#727272" />
    <path
      d="M89.8333 105.26L87.9249 107.343L89.8333 109.426"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M116 92.343L147 95.6475V119.343L116 116.039V92.343Z" fill="#727272" />
    <path
      d="M141.167 105.26L143.075 107.343L141.167 109.426"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M84 122.647L115 119.343V143.039L84 146.343V122.647Z" fill="#727272" />
    <path d="M116 119.343L147 122.647V146.343L116 143.039V119.343Z" fill="#727272" />
    <path
      d="M89.8333 131.26L87.9249 133.343L89.8333 135.426"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M141.167 131.26L143.075 133.343L141.167 135.426"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M154 95.6475L185 92.343V116.039L154 119.343V95.6475Z" fill="#727272" />
    <path
      d="M159.833 105.26L157.925 107.343L159.833 109.426"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M186 92.343L217 95.6475V119.343L186 116.039V92.343Z" fill="#727272" />
    <path
      d="M211.167 105.26L213.075 107.343L211.167 109.426"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M154 122.647L185 119.343V143.039L154 146.343V122.647Z" fill="#727272" />
    <path d="M186 119.343L217 122.647V146.343L186 143.039V119.343Z" fill="#727272" />
    <path
      d="M159.833 131.26L157.925 133.343L159.833 135.426"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M211.167 131.26L213.075 133.343L211.167 135.426"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default FrameWidthPortraitIcon

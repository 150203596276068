export const SET_STUDIO_HAS_HIGHLIGHTS = 'SET_STUDIO_HAS_HIGHLIGHTS'

export const setStudioHasHighlights = (value: boolean) => ({
  type: SET_STUDIO_HAS_HIGHLIGHTS,
  payload: value,
})

const defaultState = {
  hasHighlightsRow: false,
}

export default function reducer(previousState = defaultState, { type, payload }: { type: string; payload: any }) {
  if (type === SET_STUDIO_HAS_HIGHLIGHTS) {
    return {
      ...previousState,
      hasHighlightsRow: !!payload,
    }
  }
  return previousState
}

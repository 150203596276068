import { authSelectors } from 'ducks/auth'
import OrderLineItem from 'pages/ordering/OrderLineItem'
import ProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/projectOrderPresenter'
import type { HardwareSupplierFilterKeyType, LineItemLoadedDataType } from 'pages/ordering/type'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import type { HardwareStockStatusMap } from './HardwareSection'
import type { HardwareDetailType } from './getHardwareDetailFromSystem'

const useAvailabilityCheck = (): {
  loading: boolean
  loadStockStatus(
    hardwares: HardwareDetailType[],
    supplierFilterKey: HardwareSupplierFilterKeyType
  ): Promise<HardwareStockStatusMap | undefined>
} => {
  const [loading, setLoading] = useState(false)
  const orgId = useSelector(authSelectors.getOrgId)

  const getStatusMap = useCallback(
    (
      hardwares: HardwareDetailType[],
      loadedData: LineItemLoadedDataType[],
      supplierFilterKey: HardwareSupplierFilterKeyType
    ) => {
      const statusMap: HardwareStockStatusMap = {}
      loadedData.forEach((data: LineItemLoadedDataType) => {
        const hardware = hardwares.find((a) => a.code === data.code)

        if (!hardware) {
          return
        }

        const orderingItemForSingleItem =
          data.distributors && OrderLineItem.getDefaultSelectedVariant(data.distributors, supplierFilterKey)

        statusMap[data.code] = ProjectOrderPresenter.getStockLevelStatusMain({
          quantity: hardware.quantity,
          stockLevels: orderingItemForSingleItem?.stock_levels,
          isAvailable: !!orderingItemForSingleItem?.is_available,
        })
      })
      return statusMap
    },
    []
  )

  const loadStockStatus = useCallback(
    async (hardwares: HardwareDetailType[], supplierFilterKey: HardwareSupplierFilterKeyType) => {
      if (!orgId) return
      if (hardwares.length === 0) return

      setLoading(true)
      const loadedData: LineItemLoadedDataType[] | undefined = await ProjectOrderPresenter.getPriceAndStock({
        orgId,
        lineItems: hardwares.map(
          (hardware) =>
            new OrderLineItem({
              code: encodeURIComponent(hardware.code),
              quantity: hardware.quantity,
              selectedDistributor: supplierFilterKey,
            })
        ),
        supplierFilterKey,
      })
      setLoading(false)

      if (loadedData != null) {
        return getStatusMap(hardwares, loadedData, supplierFilterKey)
      }
    },
    [orgId]
  )
  return {
    loading,
    loadStockStatus,
  }
}

export default useAvailabilityCheck

import AddIcon from '@material-ui/icons/Add'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { paymentOptionSelectionSelectors } from 'ducks/paymentOptionSelection'
import { Button } from 'opensolar-ui'
import React, { useCallback, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { StudioSystemType } from 'types/global'
import { RootState } from 'types/state'
import PaymentOptionDialog from './PaymentOptionDialog'

type PropTypes = {
  system: StudioSystemType
  systemIndex: number
}

const AddPaymentOptionsButton: React.FC<PropTypes> = (props) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const translate = useTranslate()
  const notify = useNotify()
  const project = useFormState()?.values

  const calculationQueue = useSelector((state: RootState) => {
    return state?.designer?.systemCalculation?.debounceQueue
  })
  const isCalculating = useSelector((state: RootState) => {
    return paymentOptionSelectionSelectors.getIsSystemCalculating(state, props.system.uuid)
  })

  const openDialog = useCallback(() => {
    if (isCalculating || calculationQueue?.includes(props.system.uuid)) {
      notify(translate('Please wait for calculations to finish'), 'warning')
      return
    } else {
      setIsDialogOpen(true)
      logAmplitudeEvent('payment_option_dialog_opened', { project_id: project?.id, system_index: props.systemIndex })
    }
  }, [calculationQueue, isCalculating])

  const closeDialog = () => {
    setIsDialogOpen(false)
  }
  return (
    <div>
      <Button
        id="add_payment_options_btn"
        variant="outlined"
        color="primary"
        startIcon={<AddIcon />}
        size="small"
        onClick={openDialog}
      >
        <span>{translate('Add Payment Options')}</span>
      </Button>
      {isDialogOpen && <PaymentOptionDialog system={props.system} isOpen={true} onClose={closeDialog} />}
    </div>
  )
}
export default AddPaymentOptionsButton

export const SET_STAGE = 'SET_STAGE'
export const SET_STAGE_LOADING = 'SET_STAGE_LOADING'
export const SET_STAGE_FAILURE = 'SET_STAGE_FAILURE'
export const SET_STAGE_SUCCESS = 'SET_STAGE_SUCCESS'

export const setStage = (id, stage) => ({
  type: SET_STAGE,
  payload: { id, url: 'projects/' + id + '/stage/' + stage },
  meta: { resource: 'projects', fetch: 'CUSTOM_POST', cancelPrevious: false },
})

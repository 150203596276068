import { Typography } from '@material-ui/core'
import { ComponentVersions_3_0 } from 'constants/uxVersions'
import { authSelectors } from 'ducks/auth'
import { Button, ComponentVersionsInherit, styled } from 'opensolar-ui'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import { useSelector } from 'react-redux'
import { useFeatureFlag } from 'util/split'
import { getCountrySuppliers } from '../configs'

const FlexWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  padding: 20,
  maxWidth: 1500,
  backgroundColor: theme.palette.common.white,
}))

const SectionLeft = styled('section')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  maxWidth: 682,
  marginTop: 10,
})

const SectionRight = styled('section')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: 10,
})

const LogoWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid #e7e7e7',
  borderRadius: 4,
  width: 66,
  height: 48,
})

const Logo = styled('img')({
  objectFit: 'contain',
  padding: 8,
  width: 50,
  height: 32,
})

const ConnectButton = styled(Button)(({ theme }) => ({
  marginTop: 15,
  background: `${COLOR_PALETTE.blue3} !important`,
  color: `${theme.palette.common.white} !important`,
}))

const ConnectionBanner = () => {
  const orgCountry = useSelector(authSelectors.getCurrentOrgCountry)
  const countrySuppliers = getCountrySuppliers(orgCountry || '')
  const enableHdm = useFeatureFlag('hdm', 'on')
  const enableVtac = useFeatureFlag('vtac', 'on')

  return (
    <ComponentVersionsInherit versions={ComponentVersions_3_0}>
      <FlexWrapper>
        <SectionLeft>
          <Typography variant="h6">Welcome to Hardware Marketplace</Typography>
          <Typography variant="subtitle1" gutterBottom color="textSecondary">
            Log in now to access exclusive wholesale pricing available only in OpenSolar
          </Typography>
          <ConnectButton
            variant="contained"
            onClick={() => {
              window.scrollTo({
                top: 10000,
                left: 0,
                behavior: 'smooth',
              })
            }}
          >
            Connect your account
          </ConnectButton>
        </SectionLeft>

        <SectionRight>
          {countrySuppliers?.map((config, index) => {
            if (config.logoUrl.includes('hdm') && !enableHdm) {
              return null
            }
            if (config.logoUrl.includes('vtac') && !enableVtac) {
              return null
            }

            return (
              <LogoWrapper>
                <Logo key={index} src={config.logoUrl} alt="logo" />
              </LogoWrapper>
            )
          })}
        </SectionRight>
      </FlexWrapper>
    </ComponentVersionsInherit>
  )
}

export default ConnectionBanner

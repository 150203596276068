import { setStudioSavePrompt } from 'ducks/studioPrompt'
import { useLogout, USER_LOGIN_SUCCESS, useTranslate } from 'ra-core'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { reloadEntireApp } from 'util/misc'
import { getNumber } from '../util/env'
import { customStorage } from './customLocalStorage'

/**
 * This polls the locally stored token to see whether a change has happened.
 * As `customLocalStorage` shadows the whole local storage API, this class directly accesses the 'real' LS API.
 * It only checks LS when the window is in focus, so that another tab can log out, and then back in, without this tab doing anything.
 *
 * 'Normal' keys get shared across tabs, and are updated as soon as the change comes through.
 *
 * Special logic around the 'token' key:
 * - If token goes from null to non-null, assume there is nothing unsaved in the window and refresh the page
 * - If the token goes from non-null to non-null, just use the new token, assuming it has the same access
 * - If the token goes from non-null to null, go through the logout process, including the unsaved changes dialog
 */

const sharedKeys = ['token', 'nearmap_token']

export const useLocalStoragePoller = () => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const [pageVisible, setPageVisible] = useState(false)
  const [shownPrompt, setShownPrompt] = useState(false)
  const [lastValidUrl, setLastValidUrl] = useState<string | undefined>()
  const logout = useLogout()

  const keyChanged = useCallback(
    (key: string, appValue: string | null, browserValue: string | null) => {
      switch (key) {
        case 'token':
          if (!appValue && browserValue) {
            const auth_str = customStorage.getBrowserStorage().getItem('auth')
            let auth
            try {
              auth = auth_str ? JSON.parse(auth_str) : null
            } catch {}

            if (lastValidUrl && auth) {
              console.log('Token changed from null to non-null, attempting auth completion: ', lastValidUrl)
              //window.location.href = lastValidUrl
              window.getStorage().setItem(key, browserValue)
              dispatch({
                type: USER_LOGIN_SUCCESS,
                payload: {
                  json: auth,
                },
                meta: {
                  pathName: lastValidUrl,
                },
              })
              setLastValidUrl(undefined)
            } else {
              console.log('Token changed from null to non-null, reloading...')
              reloadEntireApp()
            }
            return // important
          } else if (appValue && !browserValue) {
            console.log('Token changed from non-null to null, running logout process')
            setLastValidUrl(window.location.hash.substring(1))
            // taken from UserSettingsDropdown.js
            if (window.projectForm?.mutators.getFormDirtyFields().length > 0) {
              setShownPrompt(true)
              dispatch(
                setStudioSavePrompt({
                  show_prompt: true,
                  redirect: logout,
                  prompt_heading: translate('Leave Project to %{label}?', { label: translate('Logout') }),
                })
              )
            } else {
              logout()
            }
          } else if (appValue && browserValue) {
            console.log('Token changed from non-null to non-null, using new token')
            setLastValidUrl(undefined)
            // hope/assume that the new token has the same access as the last
            window.getStorage().setItem(key, browserValue)

            const auth_str = customStorage.getBrowserStorage().getItem('auth')
            if (auth_str) {
              // Update auth blob also
              window.getStorage().setItem('auth', auth_str)
            }

            // Hide save studio prompt if it was shown
            if (shownPrompt) {
              dispatch(setStudioSavePrompt({}))
            }
          }
          if (browserValue) setShownPrompt(false)
          break

        default:
          console.log('Updating from local storage: ', key)
          if (browserValue) window.getStorage().setItem(key, browserValue)
          else window.getStorage().removeItem(key)
      }
    },
    [shownPrompt, lastValidUrl]
  )

  useEffect(() => {
    const handler = () => {
      setPageVisible(document.visibilityState === 'visible')
    }
    document.addEventListener('visibilitychange', handler)
    handler()
    return () => document.removeEventListener('visibilitychange', handler)
  }, [])

  const checkStorage = useCallback(() => {
    // Don't bother running unless we're in allowLocal=true
    if (!customStorage.allowLocal) return

    for (var key of sharedKeys) {
      var appValue = window.getStorage().getItem(key)
      var browserValue = customStorage.getBrowserStorage().getItem(key)
      if (appValue === browserValue) continue

      keyChanged(key, appValue, browserValue)
    }
  }, [keyChanged])

  useEffect(() => {
    if (!pageVisible) return

    const time = getNumber('SHARED_STORAGE_POLL_TIME', 5000)
    const interval = setInterval(() => checkStorage(), time)
    checkStorage()
    return () => {
      clearInterval(interval)
    }
  }, [checkStorage, pageVisible])
}

import { HelpLink } from 'elements/link/HelpLink'
import React from 'react'
import { useTranslate } from 'react-admin'
import { LeadCaptureFormCreate, LeadCaptureFormEdit, LeadCaptureFormList } from './LeadCaptureForms'

const Subtitle = () => {
  const translate = useTranslate()
  return (
    <span>
      {translate(
        'Configure a Lead Capture form for your website or Facebook page, which will automatically create a project within OpenSolar any time a prospective customer fills out the form.'
      )}
      <HelpLink href="https://support.opensolar.com/hc/en-us/articles/4407439700249" />
    </span>
  )
}

const LeadCaptureFormTitle = ({ record, translate, style }) => {
  return (
    <span style={style}>
      {translate('Lead Capture')}: {record ? `"${record.title}"` : ''}
    </span>
  )
}

const CreateLeadCaptureFormTitle = ({ record, translate, style }) => {
  return <span style={style}>{translate('Create Lead Capture')}</span>
}

export default {
  list: LeadCaptureFormList,
  create: LeadCaptureFormCreate,
  edit: LeadCaptureFormEdit,
  options: {
    create: {
      title: <CreateLeadCaptureFormTitle />,
      subtitle: null,
      breadCrumb: 'Create Lead Capture',
    },
    list: {
      title: 'My Lead Capture List',
      subtitle: <Subtitle />,
      breadCrumb: 'Lead Capture',
    },
    edit: {
      title: <LeadCaptureFormTitle />,
      subtitle: null,
      breadCrumb: 'Edit Lead Capture',
    },
  },
}

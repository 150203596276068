import { useMemo } from 'react'
import type { FactoryHookType } from '../type'
import maybeCreateWarrantyFilterNode from './WarrantyFilterNodeFactory'

const useWarrantyFilterNode: FactoryHookType = ({ RendererComponent, options }) => {
  return useMemo(() => {
    return maybeCreateWarrantyFilterNode({
      componentType: options.componentType,
      RendererComponent,
    })
  }, [options.componentType, RendererComponent])
}

export default useWarrantyFilterNode

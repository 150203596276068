import { authSelectors } from 'ducks/auth'
import Button from 'elements/proUXButtons/ProUXButton'
import { useTranslate } from 'ra-core'
import React from 'react'
import { useSelector } from 'react-redux'

type PropTypes = {
  goToTotp: () => void
  goToPhone: () => void
  cancel?: () => void
}

const ChooseMfaMode: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const activeMfaDevices = useSelector(authSelectors.getConfirmedMfaDevice) || []

  let activeSms = false
  let activeTotp = false

  for (const device of activeMfaDevices) {
    switch (device.kind) {
      case 'sms':
        activeSms = true
        break
      case 'totp':
        activeTotp = true
        break
    }
  }

  const smsMfaAllowedOnOrg = useSelector(authSelectors.getIsSmsMfaAllowedOnOrg)
  const isStaff = useSelector(authSelectors.getIsStaff)
  const showSms = isStaff || smsMfaAllowedOnOrg || activeSms

  return (
    <div>
      <p>{translate('Please select your preferred method for two-factor authentication')}</p>
      {showSms && (
        <div id="updateSMS" style={{ width: '100%', marginTop: '15px' }}>
          <Button
            label={activeSms ? 'Update SMS' : 'Add SMS'}
            onClick={props.goToPhone}
            type="secondary"
            fullWidth={true}
            style={{ width: '100%' }}
          />
        </div>
      )}
      <div id="updateAuthenticatorApp" style={{ width: '100%', marginTop: '15px' }}>
        <Button
          label={activeTotp ? 'Update Authenticator App' : 'Add Authenticator App'}
          onClick={props.goToTotp}
          type="secondary"
          fullWidth={true}
          style={{ width: '100%' }}
        />
      </div>
      {props.cancel && (
        <div style={{ width: '100%', marginTop: '15px' }}>
          <Button label="Back" onClick={props.cancel} type="text" fullWidth={true} style={{ width: '100%' }} />
        </div>
      )}
    </div>
  )
}
export default ChooseMfaMode

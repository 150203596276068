import appStorage from 'storage/appStorage'

export const getDocumentHasDocusignTag = (content: string) => {
  if (!content) return false
  return content.includes('/DS-')
}

export const getDocumentHasSecondaryDocusignTag = (content: string) => {
  if (!content) return false
  return (
    content.includes('/DS-sig2') ||
    content.includes('/DS-initials2') ||
    content.includes('/DS-date2') ||
    content.includes('/DS-name2')
  )
}

export const getPostHashParams = () => {
  const hash = window.location.hash
  if (!hash) return null
  const postHashParams = hash.substring(hash.indexOf('?') + 1)
  if (!postHashParams) return null
  return new URLSearchParams(postHashParams)
}

export const getDocusignEventParam = () => {
  const params = getPostHashParams()
  return params ? params.get('event') : null
}

export const getDocusignAllSignedParam = () => {
  const params = getPostHashParams()
  return params ? params.get('all_signed') : null
}

export const getEventKey = () => {
  const params = getPostHashParams()
  return params ? params.get('event_key') : null
}

export const getIsFirstOfThisEvent = () => {
  const eventKey = getEventKey()
  const storageKey = appStorage.getString('docusign_event_key')
  return !!eventKey && eventKey !== storageKey
}

export const saveEventKey = () => {
  const key = getEventKey()
  if (!key) return
  appStorage.setString('docusign_event_key', key)
}

// @ts-nocheck
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { hideFullScreenLoader, markCreditAppAsOpened, myEnergySelectors } from 'ducks/myEnergy'
import LoadingDots from 'layout/widgets/LoadingDots'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'
import { PaymentOptionType } from 'types/paymentOptions'
import { ProjectType } from 'types/projects'
import { TransactionActionButton } from '../../../elements/TransactionStartButton'
import ReapplicationConfirm from '../ReapplicationConfirm'
import { removeCreditAppParams } from '../util'
import SungageApplicationDialog from './SungageApplicationDialog'

var restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  actionData: any
  translate: (raw: string) => string
  showDialog: boolean
  setWaitingForAction: Function
  myeStyles: CSSProperties
  acceptButtonStyle: CSSProperties
  acceptButtonStyleDisabled: CSSProperties
  isPro: boolean
  selectedPaymentOptionName: string
  paymentOptionData: PaymentOptionType
  selectedProject: ProjectType
  onDecisionRendered: () => void
  loanpalDecisionRendered: boolean
  selectedPaymentOptionId: number
}
const LoanpalApplicationButton: React.FunctionComponent<PropTypes> = (props) => {
  const {
    actionData,
    translate,
    myeStyles,
    acceptButtonStyle,
    acceptButtonStyleDisabled,
    selectedProject,
    paymentOptionData,
    selectedPaymentOptionId,
  } = props
  const [showApplicationDialog, setShowApplicationDialog] = useState<boolean>(false)
  const [disclosuresLoading, setDisclosuresLoading] = useState<boolean>(false)
  const [disclosures, setDisclosures] = useState<string[] | undefined>(undefined)
  const [showReapplication, setShowReapplication] = useState<boolean>(false)
  const [isFullWidth, setIsFullWidth] = useState<boolean>(false)

  const forceOpenCreditApp = useSelector(myEnergySelectors.getShouldForceOpenCreditApp)
  const dispatch = useDispatch()

  useEffect(() => {
    if (forceOpenCreditApp && selectedPaymentOptionId === paymentOptionData?.id) {
      openApplication()
      removeCreditAppParams()
      setIsFullWidth(true)
    }
  }, [forceOpenCreditApp, selectedPaymentOptionId])

  let buttonLabelText =
    actionData.status_title === 'InitiatedButIncomplete'
      ? translate('Finish Loan Application')
      : translate('Apply Now!')

  let extra_actions = []
  const orgId = props.paymentOptionData?.org_id ? props.paymentOptionData?.org_id : actionData.org_id

  const openReapplication = () => {
    openApplication()
  }

  const openApplication = () => {
    setDisclosuresLoading(true)

    restClientInstance('CUSTOM_GET', 'custom', {
      url: 'orgs/' + orgId + '/projects/' + actionData.project_id + '/sungage_disclosures/',
    })
      .then(
        (response: any) => {
          if (response?.data?.disclosures) {
            setShowApplicationDialog(true)
            setShowReapplication(false)
            setDisclosures(response.data.disclosures)
          }
        },
        (reject: any) => {
          console.log(reject)
          window.Designer.showNotification(window.translate(reject.message), 'danger')
        }
      )
      .catch((err) => {
        if (err?.body?.message) {
          window.Designer.showNotification(window.translate(err.body.message), 'danger')
          logAmplitudeEvent('integrated_finance_application_open_error', { integration: 'sungage' })
        }
      })
      .finally(() => {
        setDisclosuresLoading(false)
        dispatch(hideFullScreenLoader())
        dispatch(markCreditAppAsOpened())
      })
  }

  if (actionData.status_code === 'available') {
    extra_actions.push(
      <p key={extra_actions.length} className="small">
        Clicking "Apply Now!" will take you to Sungage's credit application, which Sungage indicates is a soft pull with
        no impact on your credit score. OpenSolar does not store any of your data except name and email address.
      </p>
    )
  }

  if (actionData.status_description) {
    // Other statuses where we simply print the status_description
    // Including: OtherOrUnknown
    extra_actions.push(<p key={extra_actions.length}>{actionData.status_description}</p>)
  }

  // NOTE: sungage is different from the other lenders in how we handle re-applications. Approvals will also see re-application links
  return (
    <>
      {actionData.status_code === 'complete' && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
            {(actionData.status_title?.includes('Approved') || actionData.status_title?.includes('approved')) && (
              <CheckCircleIcon htmlColor="rgba(55, 169, 46, 0.9)" style={{ marginRight: '5px' }} />
            )}
            <h2>{actionData.status_title}</h2>
          </div>
          <div>{actionData.status_description}</div>
          <div style={{ marginTop: '10px', marginBottom: '10px', width: '100%', justifyContent: 'center' }}>
            <div
              style={{
                textAlign: 'center',
                cursor: 'pointer',
                textDecoration: 'underline',
                color: myeStyles?.highlightColor,
              }}
              onClick={() => setShowReapplication(true)}
            >
              Want to Re-Apply?
            </div>
          </div>
          <ReapplicationConfirm
            close={() => setShowReapplication(false)}
            onConfirm={openReapplication}
            loading={disclosuresLoading}
            isOpen={showReapplication}
          />
        </div>
      )}
      {actionData.status_code !== 'complete' && (
        <TransactionActionButton
          label={
            <div>
              {disclosuresLoading ? (
                <LoadingDots color="#fff" />
              ) : (
                <>
                  {buttonLabelText}
                  <div style={{ fontSize: 11 }}>(Takes less than 5 minutes)</div>
                </>
              )}
            </div>
          }
          disabled={actionData.status_code !== 'available' || props.loanpalDecisionRendered}
          myeStyles={myeStyles}
          labelStyle={{ padding: 0 }}
          buttonStyle={{
            height: 39,
            ...(actionData.status_code === 'available' && !props.loanpalDecisionRendered
              ? acceptButtonStyle
              : acceptButtonStyleDisabled),
          }}
          action={openApplication}
          extra_actions={extra_actions}
        />
      )}
      {disclosures && (
        <SungageApplicationDialog
          open={showApplicationDialog}
          onClose={() => setShowApplicationDialog(false)}
          disclosures={disclosures}
          project={selectedProject}
          paymentOptionId={paymentOptionData?.id}
          systemUuid={actionData?.system_uuid}
          orgId={orgId}
          onDecisionRendered={props.onDecisionRendered}
          isFullWidth={isFullWidth}
        />
      )}
    </>
  )
}
export default LoanpalApplicationButton

import Tooltip from 'elements/tooltip/Tooltip'
import { Button } from 'opensolar-ui'
import React, { ReactNode } from 'react'
import { useStyles } from './styles'

interface PropTypes {
  title: string
  description: ReactNode
  WarningMsgComponent?: ReactNode
  tooltip?: string
  btnLabel: string
  onClick: () => void
  logo?: ReactNode
  disabled?: boolean
}

const IntegrationRow: React.FC<PropTypes> = ({
  title,
  description,
  btnLabel,
  onClick,
  logo,
  disabled,
  WarningMsgComponent,
  tooltip,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <div className={classes.innerWrapper}>
        <div className={classes.detailWrapper}>
          <h3 className={classes.header}>{title}</h3>
          <p className={classes.desc}>{description}</p>
        </div>
        {logo && <div className={classes.iconWrapper}>{logo}</div>}
      </div>
      <div>
        {WarningMsgComponent}
        <Tooltip title={tooltip} color={'secondary'}>
          <span>
            <Button
              className={disabled ? classes.actionBtnDisabled : classes.actionBtn}
              onClick={onClick}
              disabled={disabled}
            >
              {btnLabel}
            </Button>
          </span>
        </Tooltip>
      </div>
    </div>
  )
}

export default IntegrationRow

import { makeStyles } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useTranslate, userLogin } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { doNotTranslate, parseQueryStringToDictionary } from 'util/misc'
import AuthModalWrapper from './AuthModalWrapper'
import { NearmapLogo } from './common'

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    width: 280,
    marginTop: 0,
    minWidth: 210,
    padding: '0.5em 1em 1em 1em',
  },
  button: {
    color: theme.headerFontColor,
    background: theme.themeColor,
    '&:hover': {
      cursor: 'pointer',
      background: theme.themeColorDark,
    },
  },
}))

const LoggingInWithNearmap = () => {
  const classes = useStyles()
  const history = useHistory()
  const translate = useTranslate()
  const isLoading = useSelector((state: any) => Boolean(state.admin && state.admin.loading))
  const location = useLocation()
  const dispatch = useDispatch()
  const loginWithNearmapToken = (nearmap_token: string) => {
    // // Special cases BROKEN because localStorage org_id gets updated by other updates after fixing them here:
    // //
    // // 1. New Account
    // // If we are creating a new org then we intentionally forget any org_id in localStorage
    // // This ensures we will switch to the newly created org id instead of retaining the old org id
    // //
    // // 2. Upgrade Account
    // // Ensure we switch to this account. Probably redundant but may be useful when multiple orgs are linked.

    const homeRedirectionPath = '/home'

    const { state: pathName = homeRedirectionPath } = location
    dispatch(
      userLogin({
        payload: {
          nearmap_token: nearmap_token,
          upgrade_action: undefined,
          upgrade_org_id: null,
          sendBearerToken: true,
        },
        state: { nextPathname: pathName },
        nearmapToken: nearmap_token,
      })
    )
  }
  useEffect(() => {
    if (location.search) {
      if (!isLoading) {
        const queryParams = parseQueryStringToDictionary(location.search)
        if (queryParams?.nearmap_token) {
          loginWithNearmapToken(queryParams.nearmap_token)
        }
      }
    }
  }, [])
  return (
    <AuthModalWrapper>
      <div className={classes.wrapper}>
        <div>
          <div style={{ textAlign: 'center' }}>
            <NearmapLogo />
          </div>
          <p>{doNotTranslate('Logging in with Nearmap...')}</p>
          <p>
            {doNotTranslate('If login screen does not appear, click here to')}{' '}
            <a href={window.API_BASE_URL + 'nearmap/login/'}>{translate('Login with Nearmap')}</a>
          </p>
        </div>
      </div>
    </AuthModalWrapper>
  )
}
export default LoggingInWithNearmap

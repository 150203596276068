import {
  ArrowCurveLeftUpOutlineIcon,
  ArrowCurveRightUpOutlineIcon,
  InformationCircleIcon,
  MarkerOutlineIcon,
  ToggleButton,
  ToggleButtonGroup,
} from 'opensolar-ui'
import { FC, useState } from 'react'

export const ToggleButtonExclusive: FC = () => {
  const [value, setValue] = useState('left')
  const onChange = (e, v) => setValue(v)
  return (
    <>
      {/* SNIPPET START 1 */}
      <ToggleButtonGroup value={value} exclusive onChange={onChange}>
        <ToggleButton value="left">
          <ArrowCurveLeftUpOutlineIcon />
        </ToggleButton>
        <ToggleButton value="right">
          <ArrowCurveRightUpOutlineIcon />
        </ToggleButton>
      </ToggleButtonGroup>
      {/* SNIPPET END 1 */}
    </>
  )
}

export const ToggleButtonMultiple: FC = () => {
  const [value, setValue] = useState<string[]>(['marker', 'info'])
  const onChange = (e, v) => setValue(v)
  return (
    <>
      {/* SNIPPET START 2 */}
      <ToggleButtonGroup value={value} onChange={onChange}>
        <ToggleButton value="marker">
          <MarkerOutlineIcon />
        </ToggleButton>
        <ToggleButton value="info">
          <InformationCircleIcon />
        </ToggleButton>
      </ToggleButtonGroup>
      {/* SNIPPET END 2 */}
    </>
  )
}

export const ToggleButtonVert: FC = () => {
  const [value, setValue] = useState<string[]>(['marker', 'info'])
  const onChange = (e, v) => setValue(v)
  return (
    <>
      {/* SNIPPET START 3 */}
      <ToggleButtonGroup value={value} onChange={onChange} orientation="vertical">
        <ToggleButton value="marker">
          <MarkerOutlineIcon />
        </ToggleButton>
        <ToggleButton value="info">
          <InformationCircleIcon />
        </ToggleButton>
      </ToggleButtonGroup>
      {/* SNIPPET END 3 */}
    </>
  )
}

export const ToggleButtonColours: FC = () => {
  const [value, setValue] = useState<string[]>([
    'default',
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
  ])
  const onChange = (e, v) => setValue(v)
  return (
    <>
      {/* SNIPPET START 4 */}
      <ToggleButtonGroup value={value} onChange={onChange}>
        <ToggleButton value="primary" color="primary">
          Primary
        </ToggleButton>
        <ToggleButton value="secondary" color="secondary">
          Secondary (default)
        </ToggleButton>
        <ToggleButton value="info" color="info">
          Info
        </ToggleButton>
        <ToggleButton value="success" color="success">
          Success
        </ToggleButton>
        <ToggleButton value="warning" color="warning">
          Warning
        </ToggleButton>
        <ToggleButton value="error" color="error">
          Error
        </ToggleButton>
      </ToggleButtonGroup>
      {/* SNIPPET END 4 */}
    </>
  )
}

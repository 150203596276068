import type LineItemType from 'pages/ordering/OrderLineItem'
import type { QuantitySelectorType } from 'pages/ordering/order/table/quantity/useQuantitySelectorType'
import React, { memo } from 'react'
import BulkOrderMessage from './bulk/BulkOrderMessage'
import VolumeDiscountMessage from './discount/VolumeDiscountMessage'

const useQuantityMessage = ({ lineItem, type }: { lineItem: LineItemType; type?: QuantitySelectorType }) => {
  if (type === 'bulk') {
    const Message = memo(() => {
      return <BulkOrderMessage lineItem={lineItem} />
    })
    return Message
  }
  if (type === 'discount') {
    return VolumeDiscountMessage
  }
}

export default useQuantityMessage

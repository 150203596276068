import { Dialog, DialogContent, makeStyles, Tooltip } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import { IconButton } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'

const hasImage = (testimonial) => testimonial.image_file_contents && testimonial.image_file_contents.length > 0

const CaseStudies = ({ org, testimonials, proposalData, hideHeading, selectedProject }) => {
  const translate = useTranslate()
  return (
    <div style={{ marginBottom: 50 }}>
      {testimonials && testimonials.length > 0 && (
        <h1>{proposalData.proposalTemplateSettings.heading_case_studies || translate('Case Studies')}</h1>
      )}

      {testimonials &&
        testimonials.length > 0 &&
        testimonials.map((testimonial, index) => (
          <div key={index} style={{ width: '100%', marginTop: 25 }}>
            <div style={hasImage(testimonial) ? {} : {}}>
              <h2>{testimonial.name}</h2>
              <p dangerouslySetInnerHTML={{ __html: testimonial.content }} />
            </div>

            {hasImage(testimonial) && (
              <div style={{ width: '100%', textAlign: 'center' }}>
                <img
                  alt=""
                  src={testimonial.image_file_contents}
                  style={{ maxWidth: '70%', maxHeight: 300, marginRight: 10 }}
                />
              </div>
            )}

            {index !== testimonials.length - 1 && <hr className="light" style={{ marginTop: 20, marginBottom: 20 }} />}
          </div>
        ))}
    </div>
  )
}
const useStyle = makeStyles({
  paper: {
    maxWidth: 500,
    width: '90%',
  },
})

export const CaseStudiesDialog = (props) => {
  const translate = useTranslate()
  const classes = useStyle()
  return (
    <div className="CaseStudies" id="CaseStudies">
      <Dialog
        open={props.isOpen}
        style={{ maxHeight: 'calc(100vh - 56px)' }}
        className="case-studies"
        classes={classes}
        onBackdropClick={() => props.closeDialog()}
      >
        <DialogContent>
          <IconButton
            id="CaseStudiesClose"
            style={{
              top: 10,
              right: 10,
              position: 'absolute',
              minWidth: 40,
              backgroundColor: 'rgba(255, 255, 255, 0)',
            }}
            onClick={() => props.closeDialog()}
          >
            <Tooltip title={translate('Close Dialog')} enterDelay={300}>
              <CloseIcon style={{ color: '#000000' }} />
            </Tooltip>
          </IconButton>
          {props.org && props.testimonials && props.testimonials.length > 0 && (
            <CaseStudies
              org={props.org}
              testimonials={props.testimonials}
              proposalData={props.proposalData}
              hideHeading={true}
              selectedProject={props.selectedProject}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

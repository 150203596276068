var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box as Mui4Box } from '@material-ui/core';
import { default as Mui5Box } from '@mui/material/Box';
import { forwardRef } from 'react';
import { Inspector } from '../debug/Inspector';
import { useComponentVersion } from '../hooks';
// * MUI Box API Spec  https://mui.com/material-ui/api/Box/
export var Box = function (props) { return (_jsx(Inspector, { name: "Box", propDefs: BoxPropsDefs, showExample: true, component: OsBox, props: props })); };
export var BoxVersion;
(function (BoxVersion) {
    BoxVersion[BoxVersion["V1"] = 1] = "V1";
    BoxVersion[BoxVersion["V2"] = 2] = "V2";
})(BoxVersion || (BoxVersion = {}));
var OsBox = forwardRef(function (props, ref) {
    var version = useComponentVersion('box');
    switch (version) {
        case BoxVersion.V2:
            return _jsx(Mui5Box, __assign({ ref: ref }, props));
        default:
            return _jsx(Mui4Box, __assign({}, props));
    }
});
var BoxPropsDefs = {};

import { FormControlLabel } from '@material-ui/core'
import { permissionsSelectors } from 'ducks/permissions'
import { Checkbox } from 'opensolar-ui'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { PriceOverrideButton, styles } from '../../PanelSystem'
import PanelGenericWarningBox from '../../warning/PanelGenericWarningBox'
import { PaymentOptionsSummary } from '../summary/Summary'
import PaymentOptionCompButton from './financialIntegrations/paymentOptionMonthlyComp/PaymentOptionCompButton'
import PaymentOptionsList from './PaymentOptionsList'

const PaymentOptions = ({
  systems,
  systemsLoadingUuids,
  system,
  pricingIsLocked,
  togglePaymentOptionOverrides,
  currencySymbol,
  countryIso2,
}) => {
  const translate = useTranslate()
  const { allowView, allowEdit } = useSelector(permissionsSelectors.getProjectPermissionByKey('design_payment_options'))

  if (!allowView) {
    return null
  }
  return (
    <div style={allowEdit ? styles.expansionPanelDetailsContainer : { pointerEvents: 'none', cursor: 'not-allowed' }}>
      <PanelGenericWarningBox category="payment_option" />
      {countryIso2 === 'US' && (
        <PaymentOptionCompButton
          countryIso2={countryIso2}
          system={system}
          pricingIsLocked={pricingIsLocked}
          systemsLoadingUuids={systemsLoadingUuids}
        />
      )}
      {system.payment_options_override === null && (
        <div style={{ marginTop: 10, color: 'rgba(0,0,0,0.54)', fontSize: 12 }}>
          <span>{translate('Default') + ': '}</span>
          <PaymentOptionsSummary system={system} />
        </div>
      )}

      <div style={{ ...styles.paperTight, position: 'relative' }}>
        <div id="payment-option-checkbox-container">
          <FormControlLabel
            control={
              <Checkbox
                labelPosition="right"
                size="small"
                checked={system.payment_options_override !== null}
                onChange={(event) => {
                  togglePaymentOptionOverrides(system.uuid, event.target.checked)
                }}
                disabled={pricingIsLocked}
              />
            }
            label={<span style={{ fontSize: 12 }}>{translate('Override Default Payment Options')}</span>}
          />
          <PaymentOptionsList system={system} />
        </div>
        <PriceOverrideButton
          systemsLoadingUuids={systemsLoadingUuids}
          pricingIsLocked={pricingIsLocked}
          currencySymbol={currencySymbol}
          systems={systems}
          tab="payment_options"
        />
      </div>
    </div>
  )
}

export default PaymentOptions

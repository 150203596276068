import { BomGeneratorAbstract } from '../bom_generators/BomGeneratorAbstract'
import {
  HorizontalRailPosition,
  PerRowRailBomGeneratorAbstract,
} from '../bom_generators/PerRowRailBomGeneratorAbstract'
import {
  BasicSpacingLayoutAdjusterAbstract,
  SpacingRuleSet,
} from '../layout_adjusters/BasicSpacingLayoutAdjusterAbstract'
import { MountingSystemAbstract } from '../MountingSystemAbstract'
import { PostProcessorAbstract } from '../post_processors/PostProcessorAbstract'
import { RoofHookAdderAbstract } from '../post_processors/RoofHookAdderAbstract'
import {
  CompatibilityParameters,
  Direction,
  Fastener,
  Item,
  MountingCalcInput,
  MountingCalcResult,
  MountingComponentType,
  Panel,
  PanelBlock,
  RailComponent,
  RailType,
} from '../types'

const spacingRuleSet: SpacingRuleSet = {
  interPanel: { horizontalSpacing: 24, verticalSpacing: 24 },
}
class MetasoleSpacingAdjuster extends BasicSpacingLayoutAdjusterAbstract {
  spacingRuleSet = spacingRuleSet
}

const railOverhang = 30
class MetasoleBomGenerator extends BomGeneratorAbstract {
  async generateBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    const starterBom = await this.generateStarterBom(result)
    return this.postProcess(starterBom)
  }

  generateStarterBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    if (this.block.orientation === 'landscape') {
      return this.generateBomFrom(MetasoleLandscapeBomGenerator, result)
    } else {
      return this.generateBomFrom(MetasolePortraitBomGenerator, result)
    }
  }

  postProcess(result: MountingCalcResult): MountingCalcResult {
    const resultWithRoofHooks = new MetasoleRoofHookAdder(this.input).process(result)
    // Rail doesn't require splicing because the rails are mini
    const resultWithRoofAdapter = new MetasoleCorrugatedRoofAdapterAdder(this.input).process(resultWithRoofHooks)
    return resultWithRoofAdapter
  }
}
export class Metasole extends MountingSystemAbstract {
  layoutAdjusterClass = MetasoleSpacingAdjuster
  bomGeneratorClass = MetasoleBomGenerator

  getFastener(): Fastener {
    const railDirection = this.input.panelBlocks[0].moduleGrid.moduleLayout() === 'portrait' ? 'horizontal' : 'vertical'
    return chooseFastener(railDirection)
  }

  getCompatibilityParameters(): CompatibilityParameters {
    return {
      integrations: ['Segen'],
      roofTypes: ['Kliplock', 'Metal Standing Seam', 'Metal Tin'],
      roofTypeRequired: false,
      slopeRange: [3, 75],
      moduleThicknessRange: [30, 50],
    } as CompatibilityParameters
  }
}

// MS+ system for landscape panels
class MetasoleLandscapeBomGenerator extends PerRowRailBomGeneratorAbstract {
  chooseRail() {
    return chooseRail(this.input, 'vertical')
  }

  chooseMidClamp() {
    return chooseMidClamp(this.input)
  }

  chooseEndClamp() {
    return chooseEndClamp(this.input)
  }

  chooseEndCap() {
    return null
  }

  getRailPositions(panel: Panel): HorizontalRailPosition[] {
    const distanceFromRailToPanelEdge = (panel.height * 0.5) / 2

    return [
      {
        top: distanceFromRailToPanelEdge,
        left: -railOverhang,
        right: railOverhang,
      },
      {
        top: panel.height - distanceFromRailToPanelEdge,
        left: -railOverhang,
        right: railOverhang,
      },
    ]
  }
}

// MS+P system for portrait panels
class MetasolePortraitBomGenerator extends PerRowRailBomGeneratorAbstract {
  chooseRail() {
    return chooseRail(this.input, 'horizontal')
  }

  chooseMidClamp() {
    return chooseMidClamp(this.input)
  }

  chooseEndClamp() {
    return chooseEndClamp(this.input)
  }

  chooseEndCap() {
    return null
  }

  getRailPositions(panel: Panel): HorizontalRailPosition[] {
    const distanceFromRailToPanelEdge = (panel.height * 0.5) / 2

    return [
      {
        top: distanceFromRailToPanelEdge,
        left: -railOverhang,
        right: railOverhang,
      },
      {
        top: panel.height - distanceFromRailToPanelEdge,
        left: -railOverhang,
        right: railOverhang,
      },
    ]
  }
}

function chooseRail(input: MountingCalcInput, direction: Direction): RailComponent {
  let componentCode = ''

  if (direction === 'vertical') {
    componentCode = '420402'
  } else {
    const roofRibDistance = input.options.roofRibDistance as number

    if (roofRibDistance <= 320) {
      componentCode = '420421'
    } else if (roofRibDistance <= 370) {
      componentCode = '420420'
    }
  }

  return {
    type: MountingComponentType.RAIL,
    railType: 'mini',
    direction: direction,
    name: componentCode,
    length: 0,
    top: 0,
    left: 0,
  }
}

function chooseMidClamp(input: MountingCalcInput): Item {
  return {
    name: '420082',
    components: [
      {
        name: '420082',
        type: MountingComponentType.CLAMP,
        left: -10,
        top: -10,
      },
    ],
  }
}

function chooseEndClamp(input: MountingCalcInput): Item {
  return {
    name: '420081',
    components: [
      {
        name: '420081',
        type: MountingComponentType.CLAMP,
        left: -10,
        top: -10,
      },
    ],
  }
}

function chooseHook(input: MountingCalcInput, panelBlock: PanelBlock | null, rail: RailComponent) {
  return []
}

function chooseFastener(direction: Direction): Fastener {
  return {
    includedInRoofHookProduct: true,
    name: '',
    qtyPerRoofHook: direction === 'vertical' ? 2 : 4,
    length: 25,
    diameter: direction === 'vertical' ? 5.5 : 6.0,
    components: [],
  }
}

class MetasoleRoofHookAdder extends RoofHookAdderAbstract {
  spacingRuleSet = spacingRuleSet
  railOverhang = railOverhang

  getRailType(input: MountingCalcInput) {
    return 'mini' as RailType
  }

  chooseHook(input: MountingCalcInput, panelBlock: PanelBlock, rail: RailComponent) {
    return chooseHook(input, panelBlock, rail)
  }

  chooseFastener(hook: Item[]) {
    const railDirection = this.input.panelBlocks[0].moduleGrid.moduleLayout() === 'portrait' ? 'horizontal' : 'vertical'
    return chooseFastener(railDirection)
  }
}

class MetasoleCorrugatedRoofAdapterAdder extends PostProcessorAbstract {
  process(result: MountingCalcResult): MountingCalcResult {
    if (this.input.options.includeRoofAdapter === 'true') {
      // add 1 420401 for every 420402
      result.items.forEach((item) => {
        if (item.name === '420402') {
          result.items.push({
            name: '420401',
            components: [],
          })
        }
      })
    }

    return result
  }
}

import { HardwareSupplierEnum } from 'pages/ordering/type'
import { useHistory } from 'react-router-dom'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

interface Props {
  hardwareSupplier?: HardwareSupplierEnum
}

const REGISTER_LINK_MAPPING: {
  [key in Exclude<HardwareSupplierEnum, | HardwareSupplierEnum.Hdm>]: string
} = {
  [HardwareSupplierEnum.SolarOutlet]: 'https://pages.opensolar.com/partners/solar-outlet#Getstarted',
  [HardwareSupplierEnum.Segen]: 'https://share.hsforms.com/1r-gtFaxoQj-vksZvB23FFAcne2p',
  [HardwareSupplierEnum.CityPlumbing]: 'https://share.hsforms.com/1ArJCBRFsSReMQzJtbzqw7gcne2p',
  [HardwareSupplierEnum.VtacUk]: '/shop/connect_distributor/vtac/email',
  [HardwareSupplierEnum.VtacPoland]: '/shop/connect_distributor/vtac/email'
}

const useStyles = makeOpenSolarStyles((theme) => ({
  vtacLinkText: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}))

const RegisterLink: React.FC<Props> = ({ hardwareSupplier = HardwareSupplierEnum.SolarOutlet }) => {
  const history = useHistory()
  const classes = useStyles()

  const handleVtacRegisterLink = (path: string) => {
    history.push(path)
  }

  return (
    <span>
    {(hardwareSupplier === HardwareSupplierEnum.VtacUk || hardwareSupplier === HardwareSupplierEnum.VtacPoland)
      ? (
        <a
          onClick={() => handleVtacRegisterLink(REGISTER_LINK_MAPPING[hardwareSupplier])}
          className={classes.vtacLinkText}
        >
          Register
        </a>
      ): (
        <a href={REGISTER_LINK_MAPPING[hardwareSupplier]} target='_blank' rel='noreferrer'>
          Register
        </a>
      )
    }{' '}
      to view pricing and stock availability
  </span>
  )
}

export default RegisterLink

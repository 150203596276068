import { FormControl, MenuItem } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { useNewProjectsList } from 'hooks/useNewProjectsList'
import lodash from 'lodash'
import { FormControl as Mui5FormControl, Select, styled } from 'opensolar-ui'
import { useNotify, useRefresh, useTranslate } from 'ra-core'
import { ReactNode, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { ProjectType } from 'types/projects'

const Ux2StyledFormControl = styled(FormControl)({
  maxWidth: 110,
  '& fieldset': {
    border: 'none',
  },
})

const StyledSelect = styled(Select)({
  fontSize: 12,
})

interface PropTypes {
  source: string
  project: ProjectType
  choices: any[]
  submitSource?: string
  color?: any
  renderValue?: (choice) => ReactNode
  renderChoiceValue?: (choice) => ReactNode
}
const restClientInstance = restClient(window.API_ROOT + '/api')
const ProjectSelectDropdown: React.FC<PropTypes> = ({
  source,
  project,
  choices,
  submitSource,
  color,
  renderValue,
  renderChoiceValue,
}) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const translate = useTranslate()
  const orgId = useSelector(authSelectors.getOrgId)
  const recordValue = useMemo(() => lodash.get(project, source), [project, source])
  const handleChange = useCallback((e) => {
    const newValue = e.target.value
    restClientInstance('CUSTOM_PATCH', 'custom', {
      url: `orgs/${orgId}/projects/${project.id}/`,
      data: {
        [submitSource || source]: newValue,
      },
    })
      .then(() => {
        notify('ra.notification.updated', 'info')
        refresh()
      })
      .catch((e) => {
        notify(`Failed to update ${source} for project: ${project.address}`, 'error')
      })

    logAmplitudeEvent('project_list_select_dropdown', {
      action: 'changed',
      source,
      recordValue,
      newValue,
    })
  }, [])

  const getChoiceValue = (item) => {
    if (!item) return ''
    if (renderChoiceValue) return renderChoiceValue(item)
    return translate(item.name || item.title || '')
  }

  const ui3Enabled = useNewProjectsList()
  const FormControlComp = useMemo(() => (ui3Enabled ? Mui5FormControl : Ux2StyledFormControl), [ui3Enabled])
  const SelectComp = useMemo(() => (ui3Enabled ? Select : StyledSelect), [ui3Enabled])
  if (!choices) return null
  return (
    <FormControlComp variant="outlined" size={'small'} color={color} focused={false}>
      <SelectComp
        value={recordValue}
        onChange={handleChange}
        renderValue={() => {
          const item = choices?.find((choice) => choice.id === recordValue)
          if (!item) return ''
          if (renderValue) return renderValue(item)
          return translate(item.name || item.title || '')
        }}
      >
        {choices?.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {getChoiceValue(item)}
          </MenuItem>
        ))}
      </SelectComp>
    </FormControlComp>
  )
}

export default ProjectSelectDropdown

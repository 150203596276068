import { useCallback, useState } from 'react'
import { useDataProvider, useNotify } from 'react-admin'
import type { BasicInventory, EditNotesType, InventoryByLocationResponse } from '../type'

type InventoryDataType = { code: string } & { [key in keyof BasicInventory]?: unknown } & EditNotesType

type UpdateInventoryDataArgs = { locationId: number; inventories: InventoryDataType[] }

export type UpdateInventoryDataType = ({ locationId, inventories }: UpdateInventoryDataArgs) => Promise<boolean>

type InventoryDataResponse = {
  savingInventoryData: boolean
  updateInventoryData: UpdateInventoryDataType
}

const useUpdateInventory = (): InventoryDataResponse => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const updateInventoryData = useCallback(async ({ locationId, inventories }: UpdateInventoryDataArgs) => {
    setIsLoading(true)
    let success = false
    try {
      await dataProvider
        .CUSTOM_POST(
          'inventory/by_location',
          {
            url: `inventory_locations/${locationId}/edit_inventory/`,
            data: inventories,
          },
          {
            onSuccess: ({ data }: { data: InventoryByLocationResponse }) => {
              success = true
            },
          }
        )
        .finally(() => {
          setIsLoading(false)
        })
    } catch (e) {
      notify((e as Error).message, 'warning')
    }

    return success
  }, [])

  return {
    savingInventoryData: isLoading,
    updateInventoryData,
  }
}

export default useUpdateInventory

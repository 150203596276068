var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var MarkerOutlineIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? '1rem' : _b, _c = _a.color, color = _c === void 0 ? 'currentColor' : _c, rest = __rest(_a, ["size", "color"]);
    var iconAttributes = {
        width: size,
        height: size,
        stroke: color,
    };
    return (_jsxs("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 18 22", fill: "none" }, rest, iconAttributes, { children: [_jsx("path", { d: "M8.99937 20.6C8.99937 20.6 16.5124 13.9218 16.5124 8.91307C16.5124 4.76373 13.1487 1.40002 8.99937 1.40002C4.85003 1.40002 1.48633 4.76373 1.48633 8.91307C1.48633 13.9218 8.99937 20.6 8.99937 20.6Z", stroke: color, strokeWidth: "2" }), _jsx("path", { d: "M11.3997 8.60018C11.3997 9.92566 10.3252 11.0002 8.99968 11.0002C7.6742 11.0002 6.59968 9.92566 6.59968 8.60018C6.59968 7.27469 7.6742 6.20018 8.99968 6.20018C10.3252 6.20018 11.3997 7.27469 11.3997 8.60018Z", stroke: color, strokeWidth: "2" })] })));
};

// in src/users.js
import { Paper, makeStyles, useMediaQuery } from '@material-ui/core'
import ContentCreate from '@material-ui/icons/CreateOutlined'
import PaginationWithCsvActions from 'elements/PaginationWithCsvActions'
import QuickFilter from 'elements/QuickFilter'
import TextFieldWithSharedEntityIcon from 'elements/TextFieldWithSharedEntityIcon'
import CustomField from 'elements/field/CustomField'
import { useBulkActionButtons } from 'elements/hooks/useBulkActionButtons'
import { StandardInputs } from 'elements/input/StandardInputs'
import { useEditShareable } from 'elements/react-admin/EditShareable'
import { List } from 'elements/react-admin/List'
import ListActions from 'elements/react-admin/ListActions'
import inflection from 'inflection'
import withMediaQuery from 'layout/withMediaQuery'
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  FilterComp,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import ShareabilityFilters from 'resources/connectedOrgs/ShareabilityFilters'
import ShareabilitySelector from 'resources/connectedOrgs/ShareabilitySelector'
import { useResourceStyles } from 'resources/styles'
import { styles as standardStyles } from 'styles'
import { currencySymbolForCountry, titleCase } from 'util/misc'
import { getOrgCountryCodeFromState } from 'util/org'
import { duplicate as duplicateAction } from '../../actions/restActions'
import CustomSimpleList from '../../elements/CustomSimpleList'
import EditOrImportButton from '../../elements/button/EditOrImportButton'
import DependentInput from '../../elements/input/DependentInput'
import GeneralHelperCard from './GeneralHelperCard'
import PricingFormulaSelectInput from './PricingFormulaSelectInput'

export const TAX_MODE_CHOICES = [
  { id: 'including', name: 'Including Tax' },
  { id: 'excluding', name: 'Excluding Tax' },
]

export const AUTO_APPLY_SECTOR_CHOICES = [
  { id: 0, name: 'All' },
  { id: 1, name: 'Residential' },
  { id: 2, name: 'Commercial' },
]

const mapStateToProps = (state) => {
  return {
    currencySymbol: currencySymbolForCountry(getOrgCountryCodeFromState(state)),
  }
}

const useStyles = makeStyles((theme) => ({
  description: {
    lineHeight: '1.5',
    color: '#626262',
  },
}))

export const getUnitsForVariableName = (variableName) => {
  if (variableName.indexOf('price') !== -1) {
    return '$'
  } else if (variableName.indexOf('percentage') !== -1) {
    return '%'
  } else {
    return ''
  }
}

export const pricing_formulas_and_variables = [
  {
    title: 'Price Per Watt',
    variables: ['price_per_watt', 'battery_price_per_kwh', 'tax_percentage_included', 'tax_mode'],
    labels: ['Price Per Watt', 'Battery Price Per kWh', 'Tax', 'Including/Excluding Tax'],
    variableNames: [
      'variable_price_per_watt',
      'variable_battery_price_per_kwh',
      'variable_tax_percentage_included',
      'variable_tax_mode',
    ],
  },
  {
    title: 'Markup Percentage',
    variables: [
      'markup_percentage',
      'tax_percentage_included',
      'tax_mode',
      'deduct_incentive_to_installer_before_markup',
    ],
    labels: [
      'Markup Percentage',
      'Tax',
      'Including/Excluding Tax',
      'Apply markup after applying incentives to installer',
    ],
    variableNames: [
      'variable_markup_percentage',
      'variable_tax_percentage_included',
      'variable_tax_mode',
      'variable_deduct_incentive_to_installer_before_markup',
    ],
  },
  {
    title: 'Price Per Watt By Size',
    variables: [
      'price_per_watt_0_1',
      'price_per_watt_1_2',
      'price_per_watt_2_3',
      'price_per_watt_3_4',
      'price_per_watt_4_5',
      'price_per_watt_5_6',
      'price_per_watt_6_7',
      'price_per_watt_7_8',
      'price_per_watt_8_9',
      'price_per_watt_9_10',
      'price_per_watt_10_12',
      'price_per_watt_12_14',
      'price_per_watt_14_16',
      'price_per_watt_16_18',
      'price_per_watt_18_20',
      'price_per_watt_20_30',
      'price_per_watt_30_40',
      'price_per_watt_40_50',
      'price_per_watt_50_60',
      'price_per_watt_60_70',
      'price_per_watt_70_80',
      'price_per_watt_80_90',
      'price_per_watt_90_100',
      'price_per_watt_100_plus',
      'battery_price_per_kwh_0_5',
      'battery_price_per_kwh_5_10',
      'battery_price_per_kwh_10_100',
      'battery_price_per_kwh_100_plus',
      'tax_percentage_included',
      'tax_mode',
    ],
    labels: [
      'Price Per Watt (0-1kW)',
      'Price Per Watt (1kW-2kW)',
      'Price Per Watt (2kW-3kW)',
      'Price Per Watt (3kW-4kW)',
      'Price Per Watt (4kW-5kW)',
      'Price Per Watt (5kW-6kW)',
      'Price Per Watt (6kW-7kW)',
      'Price Per Watt (7kW-8kW)',
      'Price Per Watt (8kW-9kW)',
      'Price Per Watt (9kW-10kW)',
      'Price Per Watt (10kW-12kW)',
      'Price Per Watt (12kW-14kW)',
      'Price Per Watt (14kW-16kW)',
      'Price Per Watt (16kW-18kW)',
      'Price Per Watt (18kW-20kW)',
      'Price Per Watt (20kW-30kW)',
      'Price Per Watt (30kW-40kW)',
      'Price Per Watt (40kW-50kW)',
      'Price Per Watt (50kW-60kW)',
      'Price Per Watt (60kW-70kW)',
      'Price Per Watt (70kW-80kW)',
      'Price Per Watt (80kW-90kW)',
      'Price Per Watt (90kW-100kW)',
      'Price Per Watt (100kW+)',
      'Battery Price Per kWh (0-5)',
      'Battery Price Per kWh (5-10)',
      'Battery Price Per kWh (10-100)',
      'Battery Price Per kWh (100+)',
      'Tax',
      'Including/Excluding Tax',
    ],
    variableNames: [
      'variable_price_per_watt_0_1',
      'variable_price_per_watt_1_2',
      'variable_price_per_watt_2_3',
      'variable_price_per_watt_3_4',
      'variable_price_per_watt_4_5',
      'variable_price_per_watt_5_6',
      'variable_price_per_watt_6_7',
      'variable_price_per_watt_7_8',
      'variable_price_per_watt_8_9',
      'variable_price_per_watt_9_10',
      'variable_price_per_watt_10_12',
      'variable_price_per_watt_12_14',
      'variable_price_per_watt_14_16',
      'variable_price_per_watt_16_18',
      'variable_price_per_watt_18_20',
      'variable_price_per_watt_20_30',
      'variable_price_per_watt_30_40',
      'variable_price_per_watt_40_50',
      'variable_price_per_watt_50_60',
      'variable_price_per_watt_60_70',
      'variable_price_per_watt_70_80',
      'variable_price_per_watt_80_90',
      'variable_price_per_watt_90_100',
      'variable_price_per_watt_100_plus',
      'variable_battery_price_per_kwh_0_5',
      'variable_battery_price_per_kwh_5_10',
      'variable_battery_price_per_kwh_10_100',
      'variable_battery_price_per_kwh_100_plus',
      'variable_tax_percentage_included',
      'variable_tax_mode',
    ],
  },
  {
    title: 'Fixed Price',
    variables: ['price', 'tax_percentage_included', 'tax_mode'],
    labels: ['Price', 'Tax', 'Including/Excluding Tax'],
    variableNames: ['variable_price', 'variable_tax_percentage_included', 'variable_tax_mode'],
  },
]

const fieldsForPricingFormula = (pricingFormulaName) => {
  for (var index in pricing_formulas_and_variables) {
    var item = pricing_formulas_and_variables[index]
    if (item.title === pricingFormulaName) {
      return item.variables
    }
  }
}

export const PricingSchemeFilter = (props) => (
  <FilterComp {...props}>
    <TextInput style={standardStyles.FILTER_FIELD_STYLE} label="pos.search" source="q" />
    <QuickFilter source="show_only_archived" defaultValue={1} />
  </FilterComp>
)

const _PricingSchemeList = ({ accessRights: { allowView, allowCreate, allowEdit, allowDelete }, ...props }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const bulkActionButtons = useBulkActionButtons(props, allowEdit, allowDelete, allowEdit)

  const resourceName = translate(`resources.${props.resource}.name`, {
    smart_count: 1,
    _: inflection.humanize(inflection.singularize(props.resource)),
  })

  return (
    <List
      hasSearch={true}
      alwayShowActions={true}
      actions={<ListActions hasArchived={true} />}
      extraCreateButtonLabel={translate('ra.page.empty_new', { name: resourceName })}
      filters={<ShareabilityFilters />}
      perPage={20}
      {...props}
      pagination={
        <PaginationWithCsvActions
          csvUploadDialogTitle="Price Scheme File Upload"
          csvUploadDialogDescription={
            <div className={classes.description}>
              <p>
                {translate(
                  'Upload a formatted CSV file to quickly make updates to your price schemes. Best practice is to download a CSV file of your price schemes, make changes and then upload the updated file.'
                )}
              </p>
              <p>
                {translate(
                  'Note that if you want to add new records, simply leave the ID column blank. You cannot remove existing records via .csv file upload - you must delete them in the table above.'
                )}
              </p>
            </div>
          }
        />
      }
      hasCreate={allowCreate}
      bulkActionButtons={bulkActionButtons}
    >
      {isMobile ? (
        <CustomSimpleList
          primaryText={(record) => `${record.title}`}
          secondaryText={(record) => (
            <span>
              {translate('Pricing Formula')}: {record.pricing_formula}
              <br />
              {translate('Auto-apply')}: {record.auto_apply_enabled ? translate('Yes') : translate('No')}
            </span>
          )}
          linkTo={(basePath, id) => `${basePath}/${id}`}
          rightIcon={() => (allowCreate ? <ContentCreate /> : null)}
        />
      ) : (
        <Datagrid duplicate={props.duplicate}>
          <TextFieldWithSharedEntityIcon source="title" />
          <BooleanField
            source="auto_apply_enabled"
            label="resources.pricing_schemes.fields.auto_apply"
            skipFromGrid={Boolean(props.layout < 3)}
            displayInCard={true}
            textAlign={'center'}
          />
          <TextField
            source="pricing_formula"
            translate={translate}
            label="Formula"
            displayInCard={true}
            sortable={false}
            skipFromGrid={Boolean(props.layout < 3)}
          />
          <EditOrImportButton
            source={'actions'}
            allowEdit={allowEdit}
            allowDelete={allowDelete}
            label="Actions"
            org_id={props.org_id}
            resource="pricing_schemes"
            duplicate={props.duplicate}
            hideShared={true}
          />
        </Datagrid>
      )}
    </List>
  )
}

export const PricingSchemeList = compose(
  withMediaQuery,
  connect(
    (state) => {
      return {
        org_id: state.auth ? state.auth.org_id : null,
      }
    },
    { duplicate: duplicateAction }
  )
)(_PricingSchemeList)

// export const PricingSchemeShow = (props) => (
//   <Show {...props} title={<PricingSchemeTitle />}>
//     <SimpleShowLayout>
//       <TextField source="title" />
//       <BooleanField source="auto_apply_enabled" />
//       <TextField source="pricing_formula" />
//     </SimpleShowLayout>
//   </Show>
// )

const validatePricingSchemeData = (values, translate) => {
  //alert('On startup, populate the variable_* fields from configuration_json')
  const errors = {}
  if (!values.title) {
    errors.title = [translate('Title is required')]
  }

  if (!values.pricing_formula || values.pricing_formula.length === 0) {
    errors.pricing_formula = [translate('Pricing Formula is required')]
  }

  if (!values.priority) {
    errors.priority = [translate('Priority is required')]
  }

  if (values.pricing_formula) {
    var fieldsForSelectedPricingFormula = fieldsForPricingFormula(values.pricing_formula)

    fieldsForSelectedPricingFormula.forEach((variable_name) => {
      var key = `variable_${variable_name}`

      if ((variable_name.startsWith('price') || variable_name.startsWith('battery_price')) && values[key] < 0) {
        errors[key] = translate('This field must be > 0.')
      } else if (variable_name === 'tax_percentage_included' && values[key] < 0) {
        errors[key] = translate('This field must be > 0.')
      } else if (variable_name === 'markup_percentage' && values[key] < 0) {
        errors[key] = translate('This field must be > 0.')
      } else if (variable_name === 'tax_mode') {
        //pass
      } else if (variable_name === 'deduct_incentive_to_installer_before_markup') {
        //pass
      } else if (
        isNaN(parseFloat(values[key])) &&
        (values.pricing_formula !== 'Price Per Watt By Size' || variable_name === 'tax_percentage_included')
      ) {
        errors[key] = [translate('is required', { item: titleCase(variable_name.split('_').join(' ')) })]
      }
    })
  }

  return errors
}

const defaultValueEdit = (record) => {
  var defaultValuesObject = Object.assign(
    {
      auto_apply_enabled: false,
      priority: 1,
      variable_tax_mode: 'including',
    },
    record
  )

  if (!record) {
    return defaultValuesObject
  }

  var configuration_object = record && record.configuration_json ? JSON.parse(record.configuration_json) : {}

  var obj = defaultValuesObject

  for (var key in configuration_object) {
    obj['variable_' + key] = configuration_object[key]
  }
  return obj
}

const formatSubmitValues = (values) => {
  const fieldsForSelectedPricingFormula = fieldsForPricingFormula(values.pricing_formula)
  const configuration_object = {}
  fieldsForSelectedPricingFormula.forEach((variable_name) => {
    configuration_object[variable_name] = values[`variable_${variable_name}`]
  })
  values.configuration_json = JSON.stringify(configuration_object)
  return values
}

export const _PricingSchemeEdit = ({ accessRights, ...props }) => {
  const translate = useTranslate()
  const resourceStyles = useResourceStyles()

  return useEditShareable({
    accessRights,
    editProps: props,
    render: ({ access }) => (
      <SimpleForm
        validate={(values) => validatePricingSchemeData(values, translate)}
        // onFormChange={handleFormChange}
        formatSubmitValues={formatSubmitValues}
        defaultValue={defaultValueEdit}
        disabled={!access.allowEdit}
      >
        <div style={{ width: '100%' }}>
          <DependentInput dependsOn="pricing_formula" value={'Price Per Watt'} key={'notes for Price Per Watt'}>
            <p>{translate('Enter prices inclusive or exclusive of tax, depending on your settings.')}</p>
          </DependentInput>

          <DependentInput
            dependsOn="pricing_formula"
            value="Price Per Watt By Size"
            key={'notes for Price Per Watt By Size'}
          >
            <p>
              {translate(
                'For both systems and batteries, blank pricing tiers will automatically use the closest pricing tier which has been set. Where the higher and lower pricing tiers are the same distance apart, use the lower tier.'
              )}
            </p>
            <p>{translate('Enter prices inclusive or exclusive of tax, depending on your settings.')}</p>
          </DependentInput>

          <DependentInput dependsOn="pricing_formula" value={'Markup Percentage'} key={'notes for Markup Percentage'}>
            <p>{translate('Markup is applied to tax-exclusive costs, then tax is added automatically.')}</p>
          </DependentInput>

          <DependentInput dependsOn="pricing_formula" value={'Fixed Price'} key={'notes for Fixed Price'}>
            <p>{translate('Prices are manually entered for each system')}</p>
          </DependentInput>
        </div>

        <StandardInputs
          extraFields={[<PricingFormulaSelectInput label="Pricing Formula" />]}
          description={false}
          autoApply="auto_apply_enabled"
        />

        <DependentInput dependsOn="pricing_formula" value={'Markup Percentage'}>
          <GeneralHelperCard />
        </DependentInput>
        <DependentInput dependsOn="auto_apply_enabled" value={true}>
          {/* Consider replacing with Generic AutoApplyInputs in ../autoApply/AutoApplyInputs.
          Note that we haven't done this yet since the input "priority" variable name is not consistent with
           auto-apply used in other places in the App, which have this field called "auto_apply_priority". */}
          <Paper classes={{ root: resourceStyles.formSection }}>
            <TextInput multiline={true} fullWidth={true} source="auto_apply_only_specified_states" />
            <TextInput multiline={true} fullWidth={true} source="auto_apply_only_specified_zips" />
            <TextInput multiline={true} fullWidth={true} source="auto_apply_component_codes" />
            <NumberInput source="auto_apply_min_system_size" />
            <NumberInput source="auto_apply_max_system_size" />
            <CustomField
              component={SelectInput}
              name="auto_apply_sector"
              source="auto_apply_sector"
              choices={AUTO_APPLY_SECTOR_CHOICES}
            />
            <NumberInput source="priority" step={1} />
          </Paper>
        </DependentInput>

        {pricing_formulas_and_variables.map((pf) => {
          return (
            <DependentInput dependsOn="pricing_formula" value={pf.title} key={'dep_' + pf.title}>
              {pf.variables.map((pfvar, index) => {
                if (pfvar === 'tax_mode') {
                  return (
                    <SelectInput
                      key={pfvar}
                      label={pf.labels[index]}
                      source={'variable_' + pfvar}
                      name={'variable_' + pfvar}
                      choices={TAX_MODE_CHOICES}
                      translateChoices={true}
                      defaultValue={'including'}
                    />
                  )
                } else if (pfvar === 'deduct_incentive_to_installer_before_markup') {
                  return (
                    <BooleanInput
                      key={pfvar}
                      label={pf.labels[index]}
                      style={{ marginTop: 20 }}
                      source={'variable_' + pfvar}
                      name={'variable_' + pfvar}
                      defaultValue={false}
                    />
                  )
                } else {
                  return (
                    <NumberInput
                      key={pfvar}
                      label={pf.labels[index]}
                      source={'variable_' + pfvar}
                      endAdornment={getUnitsForVariableName(pfvar).replace('$', props.currencySymbol)}
                      InputLabelProps={{
                        shrink: true,
                        style: { width: 200 },
                      }}
                    />
                  )
                }
              })}
            </DependentInput>
          )
        })}

        <TextInput source="configuration_json" style={{ display: 'none' }} />
        <ShareabilitySelector dependsOn="pricing_formula" />
      </SimpleForm>
    ),
  }).editPage
}

export const PricingSchemeEdit = compose(connect(mapStateToProps, {}))(_PricingSchemeEdit)

const _PricingSchemeCreate = (props) => {
  const translate = useTranslate()
  const resourceStyles = useResourceStyles()
  return (
    <Create {...props}>
      <SimpleForm
        validate={(values) => validatePricingSchemeData(values, translate)}
        formatSubmitValues={formatSubmitValues}
        defaultValue={defaultValueEdit}
      >
        <StandardInputs
          extraFields={[<PricingFormulaSelectInput label="Pricing Formula" />]}
          archive={false}
          description={false}
          autoApply="auto_apply_enabled"
        />

        <DependentInput dependsOn="pricing_formula" value={'Markup Percentage'}>
          <GeneralHelperCard />
        </DependentInput>
        <DependentInput dependsOn="auto_apply_enabled" value={true}>
          <Paper classes={{ root: resourceStyles.formSection }}>
            <TextInput multiline={true} fullWidth={true} source="auto_apply_only_specified_states" />
            <TextInput multiline={true} fullWidth={true} source="auto_apply_only_specified_zips" />
            <TextInput multiline={true} fullWidth={true} source="auto_apply_component_codes" />
            <NumberInput source="auto_apply_min_system_size" />
            <NumberInput source="auto_apply_max_system_size" />
            <CustomField
              component={SelectInput}
              name="auto_apply_sector"
              source="auto_apply_sector"
              choices={AUTO_APPLY_SECTOR_CHOICES}
            />
            <NumberInput source="priority" step={1} />
          </Paper>
        </DependentInput>

        {pricing_formulas_and_variables.map((pf) => {
          return (
            <DependentInput dependsOn="pricing_formula" value={pf.title} key={'dep_' + pf.title}>
              {pf.variables.map((pfvar, index) => {
                if (pfvar === 'tax_mode') {
                  return (
                    <SelectInput
                      key={pfvar}
                      label={pf.labels[index]}
                      source={'variable_' + pfvar}
                      name={'variable_' + pfvar}
                      choices={TAX_MODE_CHOICES}
                      translateChoices={true}
                      defaultValue={'including'}
                    />
                  )
                } else if (pfvar === 'deduct_incentive_to_installer_before_markup') {
                  return (
                    <BooleanInput
                      key={pfvar}
                      label={pf.labels[index]}
                      style={{ marginTop: 20 }}
                      source={'variable_' + pfvar}
                      name={'variable_' + pfvar}
                      defaultValue={false}
                    />
                  )
                } else {
                  return (
                    <NumberInput
                      key={pfvar}
                      label={pf.labels[index]}
                      source={'variable_' + pfvar}
                      endAdornment={getUnitsForVariableName(pfvar).replace('$', props.currencySymbol)}
                      InputLabelProps={{
                        shrink: true,
                        style: { width: 200 },
                      }}
                    />
                  )
                }
              })}
            </DependentInput>
          )
        })}
        <TextInput source="configuration_json" style={{ display: 'none' }} />
        <ShareabilitySelector dependsOn="pricing_formula" />
      </SimpleForm>
    </Create>
  )
}
export const PricingSchemeCreate = compose(connect(mapStateToProps, {}))(_PricingSchemeCreate)

import { Badge, makeStyles, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core'
import UserIcon from '@material-ui/icons/AccountCircleOutlined'
import ConnectedIcon from '@material-ui/icons/GroupOutlined'
import ConnectingIcon from '@material-ui/icons/HourglassEmptyOutlined'
import { authSelectors } from 'ducks/auth'
import { wsActions, WsContainer, wsSelectors } from 'ducks/websocket'
import { Chip, IconButton } from 'opensolar-ui'
import useInterval from 'projectSections/hooks/useInterval'
import { useTranslate } from 'ra-core'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { SectionType } from 'types/section'
import { RootState } from 'types/state'
import { useFeatureFlag } from 'util/split'

export const EDIT_MSG_TYPE = 'project-edit-state'
export const JOIN_MSG_TYPE = 'project-join'
export const LEAVE_MSG_TYPE = 'project-leave'
const SEND_EVERY = 8 * 1000 // 30s

const dirtyFieldsToString = (dirtyFields: any[]) => {
  return [...dirtyFields]
    .sort(function (a, b) {
      return a - b
    })
    .join(',')
}

const useStyles = makeStyles({
  icon: {},
  userIcon: {
    marginLeft: 5,
  },
  userName: {
    display: 'inline-block',
    margin: '0 10px',
    width: '100%',
  },
  tooltipPopper: {
    zIndex: 100000,
  },
  suChip: {
    marginLeft: '10px',
  },
})

export interface ProjectEditStateMsg {
  type: typeof EDIT_MSG_TYPE
  section: SectionType
  fields: string[]
}
export interface ProjectLeaveJoinMsg {
  type: typeof LEAVE_MSG_TYPE | typeof JOIN_MSG_TYPE
}

interface PropTypes {
  className?: string
}

const SocketStatusIcon: React.FC<PropTypes> = ({ className }) => {
  const translate = useTranslate()
  const state = useSelector(wsSelectors.getState)
  const available = useSelector(wsSelectors.getAvailable)
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const [open, setOpen] = React.useState(false)
  const [lastDirtyFields, setLastDirtyFields] = React.useState('')
  const form = useForm()
  const dispatch = useDispatch()
  const [dirtyFields, setDirtyFields] = useState<string[]>([])
  const [userInfo, setUserInfo] = useState<WsContainer<ProjectEditStateMsg>[]>([])
  const wsState = useSelector(wsSelectors.getState)
  const section = useSelector((state: RootState) => state.project.section)
  const isSuperuser = useSelector(authSelectors.getIsSuperUser)
  const classes = useStyles()

  useInterval(() => {
    sendEditState()
  }, SEND_EVERY)

  const onClick = useCallback(() => setOpen(!open), [open])

  const cullOld = useCallback(() => {
    const cutoff = Date.now() - SEND_EVERY * 2
    let removed = false
    const newUserInfo = userInfo.filter((u) => {
      if (u.arrived > cutoff) return true
      removed = true
      return false
    })
    if (removed) setUserInfo(newUserInfo)
  }, [userInfo])

  const sendEditState = useCallback(() => {
    if (wsState !== 'connected') return

    cullOld()
    dispatch(
      wsActions.broadcast({
        type: EDIT_MSG_TYPE,
        section,
        fields: dirtyFields,
      })
    )
  }, [section, dirtyFields, wsState, cullOld])

  const onMessage = useCallback(
    (data: ProjectEditStateMsg | ProjectLeaveJoinMsg, cont: WsContainer<ProjectEditStateMsg>) => {
      // Only show SU info if self is SU
      if (cont.original_data.is_superuser && !isSuperuser) return

      switch (data.type) {
        case JOIN_MSG_TYPE:
        case EDIT_MSG_TYPE:
          if (!cont.original_data.data.fields) cont.original_data.data.fields = []

          let found = false
          let newUserInfo = userInfo.map((u) => {
            if (u.original_data.session_id === cont.original_data.session_id) {
              found = true
              return cont
            } else {
              return u
            }
          })

          if (!found) newUserInfo.push(cont)
          setUserInfo(newUserInfo)

          if (data.type === JOIN_MSG_TYPE) sendEditState()

          break
        case LEAVE_MSG_TYPE:
          setUserInfo(userInfo.filter((u) => u.original_data.session_id !== cont.original_data.session_id))
          break
      }
    },
    []
  )

  const enableConcurrentEditingProtection = useFeatureFlag('concurrent_editing_protection', 'on')
  useEffect(() => {
    if (enableConcurrentEditingProtection) {
      const checkDirtyFields = () => {
        let dirtyFields = form.mutators.getFormDirtyFields() || []
        let dirtyFieldsAsString = dirtyFieldsToString(dirtyFields)

        if (dirtyFieldsAsString !== lastDirtyFields) {
          setDirtyFields(dirtyFields)
          sendEditState()
          console.debug('WebSocket: Notify unsaved fields changed', dirtyFields)
          setLastDirtyFields(dirtyFieldsAsString)
        }
      }

      const unsub = form.subscribe(checkDirtyFields, {
        active: true,
        hasSubmitErrors: true,
        submitErrors: true,
        values: true,
        dirtyFields: true,
      })

      return () => {
        // clearTimer()
        unsub()
      }
    }
  }, [enableConcurrentEditingProtection, lastDirtyFields, sendEditState])

  useEffect(() => {
    // Need to send an initial update to bind this connection to the correct project ID
    if (enableConcurrentEditingProtection && wsState === 'connected') {
      const dirtyFields = form.mutators.getFormDirtyFields() || []
      setLastDirtyFields(dirtyFieldsToString(dirtyFields))
      setDirtyFields(dirtyFields)
      dispatch(
        wsActions.broadcast({
          type: JOIN_MSG_TYPE,
          fields: dirtyFields,
        })
      )

      return () => {
        if (enableConcurrentEditingProtection && wsState === 'connected') {
          dispatch(
            wsActions.broadcast({
              type: LEAVE_MSG_TYPE,
            })
          )

          // dispatch(wsActions.removeSubscriber<ProjectEditStateMsg>(onMessage))
        }
      }
    }
  }, [enableConcurrentEditingProtection, wsState])

  useEffect(() => {
    if (enableConcurrentEditingProtection) {
      dispatch(wsActions.addSubscriber<ProjectEditStateMsg>(onMessage))

      return () => {
        dispatch(wsActions.removeSubscriber<ProjectEditStateMsg>(onMessage))
      }
    }
  }, [enableConcurrentEditingProtection])

  useEffect(() => {
    if (enableConcurrentEditingProtection) {
      sendEditState()
    }
  }, [enableConcurrentEditingProtection, section])

  const hasChanges = useCallback(() => {
    for (const u of userInfo) {
      if (!!u.original_data.data.fields.length) return true
    }
  }, [userInfo])

  function renderUser(
    id: string,
    name: string,
    section: SectionType | undefined,
    fields: string[],
    superuser: boolean
  ) {
    return (
      <MenuItem key={'user:' + id}>
        <Tooltip
          classes={{ popper: classes.tooltipPopper }}
          title={
            <>
              {fields.map((f, idx) => (
                <div key={idx}>{f}</div>
              ))}
            </>
          }
        >
          <UserIcon color={fields.length ? 'primary' : 'disabled'} className={classes.userIcon} />
        </Tooltip>
        <span className={classes.userName}>
          <Typography variant="body1">{name}</Typography>
        </span>
        {section && (
          <span>
            <Chip label={translate(section as string)} size="small" />
          </span>
        )}
        {superuser && <Chip classes={{ root: classes.suChip }} label="SU" size="small" color="primary" />}
      </MenuItem>
    )
  }

  if (!available) {
    return <></>
  } else {
    let icon
    if (state === 'disconnected') {
      icon = <ConnectingIcon className={classes.icon} />
    } else if (state === 'connecting') {
      icon = <ConnectingIcon className={classes.icon} />
    } else {
      icon = <ConnectedIcon className={classes.icon} />
    }

    return (
      <IconButton className={className} ref={(el) => setAnchorEl(el)} onClick={onClick}>
        <Badge variant={hasChanges() ? 'dot' : undefined} color="primary">
          {icon}
        </Badge>
        <Menu
          onClose={() => setOpen(false)}
          open={open}
          disableScrollLock={true}
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          <MenuItem key="status">
            <Typography variant="body1">{translate('Status: %{state}', { state: translate(state) })}</Typography>
          </MenuItem>
          {renderUser('self', translate('You'), section, dirtyFields, isSuperuser)}
          {userInfo.map((u) =>
            renderUser(
              u.user_id + '',
              u.user_name,
              u.original_data.data.section,
              u.original_data.data.fields,
              u.original_data.is_superuser
            )
          )}
        </Menu>
      </IconButton>
    )
  }
}
export default SocketStatusIcon

import { and, ControlProps, rankWith, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { FullscreenOutlined } from '@material-ui/icons'
import { IconButton } from 'opensolar-ui'
import { ImagePreviewer } from 'projectSections/sections/manage3/common/ImagePreviewer'
import { default as React } from 'react'

const tester = rankWith(100, and(uiTypeIs('ZoomableImage')))

const Renderer: React.FC<ControlProps> = ({ uischema, visible, enabled }) => {
  const src = uischema['src']
  const style = uischema['style'] || {}

  if (!visible) return <></>

  const img = <img src={src} style={style} />

  return (
    <ImagePreviewer src={src}>
      <IconButton style={{ position: 'absolute', top: 20, right: 20 }}>
        <FullscreenOutlined />
      </IconButton>
      {img}
    </ImagePreviewer>
  )
}

const renderer = withJsonFormsControlProps(Renderer)

export default {
  tester,
  renderer,
}

import ProposalViewersList from './ProposalViewersList'

export default {
  list: ProposalViewersList,
  options: {
    list: {
      title: 'Customer ProposalViewers',
      subtitle:
        'Enable, Disable or Delete customers who have access to the online proposal through the shareable link.',
      breadCrumb: 'Proposal Viewers',
    },
  },
}

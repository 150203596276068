import { orgSelectors } from 'ducks/orgs'
import { useGetConnectedOrgsThatSharedIntegration } from 'projectSections/sections/payments/utils'
import React from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import IntegrationRoleConnectionButton from './IntegrationRoleConnectionButton'

const restClientInstance = restClient(window.API_ROOT + '/api')

type SimpleOrgData = {
  name: string | undefined
  id: number | undefined
}

type PropTypes = {
  integration: string
}

const IntegrationRoleConnectionButtons: React.FC<PropTypes> = (props) => {
  const org = useSelector(orgSelectors.getOrg)
  const connectedOrgs = org?.connected_orgs

  const orgsThatSharedIntegration = useGetConnectedOrgsThatSharedIntegration(props.integration, connectedOrgs, org)

  if (orgsThatSharedIntegration?.length) {
    return (
      <div>
        {org?.name && org?.id && (
          <IntegrationRoleConnectionButton integration={props.integration} orgName={org.name} orgId={org.id} />
        )}
        {orgsThatSharedIntegration?.map((connectedOrg) => {
          if (!connectedOrg.name || !connectedOrg.id) return null
          return (
            <IntegrationRoleConnectionButton
              integration={props.integration}
              orgName={connectedOrg.name}
              orgId={connectedOrg.id}
            />
          )
        })}
      </div>
    )
  } else if (org?.name && org?.id && org[`enable_${props.integration}`] === 'active') {
    return <IntegrationRoleConnectionButton integration={props.integration} orgName={org?.name} orgId={org?.id} />
  } else {
    return <div>This integration is not active in your account yet.</div>
  }
}
export default IntegrationRoleConnectionButtons

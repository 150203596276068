import { makeStyles, Tooltip } from '@material-ui/core'
import PeopleAlt from '@material-ui/icons/PeopleAlt'
import PeopleAltOutlined from '@material-ui/icons/PeopleAltOutlined'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { IconButton, UserProfileGroupIcon, UserProfileGroupOutlineIcon } from 'opensolar-ui'
import React, { CSSProperties, useEffect, useMemo, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { ConnectedOrgBrief } from 'types/orgs'
import { ProjectShare } from 'types/projects'
import { Theme } from 'types/themes'

const getMargin = (margin?: Margin) => (margin === 'wide' ? '0 10px' : margin === 'normal' ? '0 4px' : '')

const useStyles = makeStyles<Theme, { otherOwner: boolean; textMode?: boolean; margin?: Margin }>(
  (theme) => ({
    root: ({ otherOwner, margin }) => ({
      margin: getMargin(margin),
      cursor: 'default',
      opacity: otherOwner ? 0.65 : 0.4,
    }),
    textRoot: {
      display: 'inline-flex',
      alignItems: 'center',
    },
    text: ({ margin }) => ({
      margin: getMargin(margin),
    }),
    icon: ({ textMode }) => ({
      width: textMode ? 16 : 20,
      height: textMode ? 16 : 20,
    }),
  }),
  { name: 'SharedEntityIcon' }
)

/*
shared_ids - the IDs of orgs that this entity is shared with, will overrule shared_with
shared_with - objects representing project-type sharing
*/

type Margin = 'wide' | 'normal'

interface PropType {
  textMode?: boolean
  shared_ids?: number[]
  shared_with?: ProjectShare[]
  shared_urls?: string[]
  owner?: number
  owner_url?: string
  toolTipStyles?: object
  margin?: Margin
  customSharedMessage?: string
  customUnsharedMessage?: string
  owner_name?: string
  projectOwnerId?: number
  projectSharedOrgs?: ProjectShare[]
  iconBtnStyles?: CSSProperties
  version?: 2 | 3
}

function findOrg(orgs: ConnectedOrgBrief[], id: number | string): ConnectedOrgBrief | undefined {
  for (const org of orgs) if (org.org_id === id || org.org_url === id) return org
}
function findOrgsByIds(orgs: ConnectedOrgBrief[], ids: number[]): ConnectedOrgBrief[] {
  return orgs.filter((o) => ids.indexOf(o.org_id) !== -1)
}
function findOrgsByUrls(orgs: ConnectedOrgBrief[], urls: string[]): ConnectedOrgBrief[] {
  return orgs.filter((o) => urls.indexOf(o.org_url) !== -1)
}
function findOrgsByShared(
  orgs: ConnectedOrgBrief[],
  shared: ProjectShare[],
  projectOwnerId?: number
): ConnectedOrgBrief[] {
  return orgs.filter((o1) => shared.find((o2) => o1.org_id === o2.org_id) || o1.org_id === projectOwnerId)
}
function findOrgsByNotShared(
  orgs: ConnectedOrgBrief[],
  shared: ProjectShare[],
  exclude: ConnectedOrgBrief[],
  projectOwnerId?: number
): ConnectedOrgBrief[] {
  return orgs.filter(
    (o1) => !exclude.includes(o1) && (shared.find((o2) => o1.org_id === o2.org_id) || o1.org_id === projectOwnerId)
  )
}

const SharedEntityIcon: React.FunctionComponent<PropType> = ({
  owner,
  shared_ids,
  shared_with,
  shared_urls,
  toolTipStyles,
  owner_url,
  margin,
  textMode,
  customSharedMessage,
  customUnsharedMessage,
  owner_name,
  projectOwnerId,
  projectSharedOrgs,
  iconBtnStyles,
  version = 2,
}) => {
  const [tooltip, setTooltip] = useState('')
  const [unshared, setUnshared] = useState(false)
  const translate = useTranslate()
  const connectedOrgs = useSelector(orgSelectors.getConnectedOrgs)
  const myOrgId = useSelector(authSelectors.getOrgId)
  const myOrgUrl = useSelector(orgSelectors.getOrgUrl)
  const isOwner = (owner && owner === myOrgId) || (owner_url && owner_url === myOrgUrl)
  const otherOwner = !isOwner

  const classes = useStyles({ otherOwner, margin, textMode })

  useEffect(() => {
    if (otherOwner && (owner || owner_url || owner_name)) {
      setUnshared(false)
      let org: ConnectedOrgBrief | undefined
      if (owner) org = findOrg(connectedOrgs, owner)
      if (!org && owner_url) org = findOrg(connectedOrgs, owner_url)

      setTooltip(org ? translate('Shared by %{org}', { org: org?.org_name }) : owner_name ? owner_name : '')
    } else {
      let orgs: ConnectedOrgBrief[] | undefined
      let notSharedOrgs: ConnectedOrgBrief[] | undefined

      if (shared_urls) {
        orgs = findOrgsByUrls(connectedOrgs, shared_urls)
      } else if (shared_ids) {
        orgs = findOrgsByIds(connectedOrgs, shared_ids)
      } else if (shared_with) {
        orgs = findOrgsByShared(connectedOrgs, shared_with)
      }

      if (projectSharedOrgs?.length) {
        // If shared_urls|shared_ids are defined, projectSharedOrgs will further filter the list
        // Otherwise it will just filter on the orgs connections
        orgs = findOrgsByShared(orgs || connectedOrgs, projectSharedOrgs, projectOwnerId)
        notSharedOrgs = findOrgsByNotShared(connectedOrgs, projectSharedOrgs, orgs, projectOwnerId)
      } else if (textMode) {
        setUnshared(false)
        setTooltip('')
        return
      }

      if (orgs?.length) {
        setUnshared(false)
        const isShared = !!shared_with?.find((o) => o.is_shared) || false
        const shareMessage = customSharedMessage || 'Shared with %{orgs}'
        let orgNames: String | undefined
        if (orgs?.length) {
          orgNames = orgs.map((o) => o.org_name).join(', ')
          if (orgNames) {
            setTooltip(
              translate(isShared ? shareMessage : 'Using settings from %{orgs}', {
                orgs: orgNames,
              })
            )
          }
        }
      } else if (notSharedOrgs?.length && customUnsharedMessage) {
        setUnshared(true)
        const unsharedMessage = customUnsharedMessage || 'Not shared with %{orgs}'
        setTooltip(
          translate(unsharedMessage, {
            orgs: notSharedOrgs.map((o) => o.org_name).join(', '),
          })
        )
      } else {
        setUnshared(false)
        setTooltip('')
      }
    }
  }, [otherOwner, shared_ids, shared_with, projectSharedOrgs, shared_urls, connectedOrgs])

  const ui3Enabled = useMemo(() => version === 3, [version])
  const Icon = ui3Enabled
    ? otherOwner
      ? UserProfileGroupOutlineIcon
      : UserProfileGroupIcon
    : otherOwner
    ? PeopleAltOutlined
    : PeopleAlt
  const ui3IconProps = useMemo(
    () => (ui3Enabled ? { className: ui3Enabled ? undefined : classes.icon, size: 16 } : {}),
    [ui3Enabled]
  )
  if (tooltip === '') {
    return <></>
  } else if (textMode) {
    return (
      <span className={classes.textRoot}>
        {!unshared && <Icon className={classes.icon} {...ui3IconProps} />}
        <span className={classes.text}>{tooltip}</span>
      </span>
    )
  } else {
    return (
      <Tooltip title={tooltip} PopperProps={toolTipStyles}>
        <IconButton size="small" className={classes.root} style={iconBtnStyles}>
          <Icon className={classes.icon} {...ui3IconProps} />
        </IconButton>
      </Tooltip>
    )
  }
}
export default SharedEntityIcon

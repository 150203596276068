import React, { useMemo, useEffect, useRef, useCallback } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { LAYOUTS, breakpoints } from './LayoutConfig'

const useLayout = () => {
  const isSM = useMediaQuery((theme: any) => theme.breakpoints.up(LAYOUTS['sm'].width))
  const isMD = useMediaQuery((theme: any) => theme.breakpoints.up(LAYOUTS['md'].width))
  const isLG = useMediaQuery((theme: any) => theme.breakpoints.up(LAYOUTS['lg'].width))
  const isXL = useMediaQuery((theme: any) => theme.breakpoints.up(LAYOUTS['xl'].width))
  const layout = useMemo(() => {
    let currentLayout
    if (isXL) {
      currentLayout = LAYOUTS['xl'].layout
    } else if (isLG) {
      currentLayout = LAYOUTS['lg'].layout
    } else if (isMD) {
      currentLayout = LAYOUTS['md'].layout
    } else if (isSM) {
      currentLayout = LAYOUTS['sm'].layout
    } else {
      currentLayout = LAYOUTS['xs'].layout
    }
    return currentLayout
  }, [isSM, isMD, isLG, isXL])

  return layout
}

export default useLayout

import { SystemsDataFromDesignDataJsonString } from 'util/misc'
import { buildSystemPlaceholders } from 'myenergy/mainContent/populate'
import { SystemDataType } from 'types/systems'

const parseDesignInitialValues = (formValsOnSave: any, returnedVals: any = {}) => {
  const newInitialValues: any = formValsOnSave

  if (formValsOnSave && formValsOnSave.design && typeof formValsOnSave.design === 'object') {
    // @ts-ignore
    const systemsData = SystemsDataFromDesignDataJsonString(formValsOnSave.design)
    const systemSummaries: string[] = []
    systemsData?.forEach((system: SystemDataType) => {
      systemSummaries.push(buildSystemPlaceholders(system, null, system.inverters).summary)
    })
    // @ts-ignore
    newInitialValues?.systems?.forEach((system: any, i: number) => {
      if (systemSummaries && systemSummaries[i]) system.summary = systemSummaries[i]
    })
  }

  if (newInitialValues.design) {
    delete newInitialValues.design
  }
  return newInitialValues
}

export default parseDesignInitialValues

import WbSunnyOutlinedIcon from '@material-ui/icons/WbSunnyOutlined'
import { helpCenterActions } from 'ducks/helpCenter'
import { permissionsSelectors } from 'ducks/permissions'
import { Button } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { useFeatureFlag } from 'util/split'
import DefaultTooltip from '../fuji/DefaultToolTip'
import { TOUR_Z_INDEX } from '../tools/common'
import { ToolTipPropsType, TourConfigType } from '../types'

type PropsType = {
  open: boolean
  handleClose: (force?: true) => void | boolean
  handleStopTour: (e?: React.MouseEvent<HTMLButtonElement>) => void
  handleStartTour: (e?: React.MouseEvent<HTMLButtonElement>) => void
}

const Tooltip = (props: ToolTipPropsType) => {
  const accessRightsSetting = useSelector(permissionsSelectors.getPermissionsSetting)
  const { handleNext, handlePrev, handleCancel } = props
  const dispatch = useDispatch()
  const translate = useTranslate()
  const paymentsTabIsEnabled = useFeatureFlag('payments_tab', 'on')
  if (!paymentsTabIsEnabled) return null
  return (
    <div
      id="start_tooltip"
      style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)', position: 'fixed', zIndex: TOUR_Z_INDEX }}
    >
      <DefaultTooltip
        title={translate('Explore the Payment Tab')}
        icon={WbSunnyOutlinedIcon}
        onClose={handleCancel}
        disableDrag={true}
        content={
          <>
            <p style={{ lineHeight: '1.4em' }}>
              {translate(
                'The Payment tab simplifies exploring payment options and launching applications from your device for integrated payments. This guide will teach you how to use the new payment tab effectively to close more deals.'
              )}
            </p>
          </>
        }
        actions={
          <>
            <Button
              id="skip_tour_btn"
              variant="contained"
              /* @ts-ignore */
              onClick={handleCancel}
              /* @ts-ignore */
              onTouchStart={handleCancel}
              style={{ marginRight: 10 }}
            >
              <span>{translate('Skip')}</span>
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.stopPropagation()
                dispatch(helpCenterActions.setHelpCenterOpen(false))
                if (handleNext) {
                  handleNext()
                }
              }}
              onTouchStart={(e) => {
                e.stopPropagation()
                dispatch(helpCenterActions.setHelpCenterOpen(false))
                if (handleNext) {
                  handleNext()
                }
              }}
            >
              <span>{translate('Start Tour')}</span>
            </Button>
          </>
        }
      />
    </div>
  )
}

const PaymentsTourController: TourConfigType = {
  spotlights: [],
  tooltips: [<Tooltip key={1} />],
}

export default PaymentsTourController

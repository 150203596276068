import { List } from 'elements/react-admin/List'
import React from 'react'
import {
  AutocompleteInput,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  FilterComp,
  FunctionField,
  ReferenceInput,
  SimpleForm,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'
import { styles as standardStyles } from 'styles'
import PaginationWithActions from '../../elements/PaginationWithActions'

export const DistributorFilter = (props) => (
  <FilterComp {...props}>
    <TextInput label="pos.search" style={standardStyles.FILTER_FIELD_STYLE} source="q" />
    <ReferenceInput source="country" reference="countries" sort={{ field: 'name', order: 'ASC' }}>
      <AutocompleteInput
        options={{ style: standardStyles.FILTER_FIELD_STYLE, placeholder: 'Enter a country' }}
        optionText="name"
        optionValue="id"
      />
    </ReferenceInput>
  </FilterComp>
)

export const DistributorList = (props) => (
  <List
    pagination={<PaginationWithActions uploadModel={'distributors'} />}
    title="Distributor"
    hasSearch={true}
    filters={<DistributorFilter />}
    {...props}
  >
    <Datagrid>
      <TextField source="name" />
      <FunctionField
        source={null}
        label="Actions"
        textAlign={'center'}
        render={(record, source, rest) => (
          <div>
            <EditButton useListStyle={true} record={record} basePath={rest.basePath} />
            <DeleteButton
              useListStyle={true}
              undoable={false}
              record={record}
              resource={rest.resource}
              redirect={`/${rest.resource}`}
            />
          </div>
        )}
        sortable={false}
      />
    </Datagrid>
  </List>
)

const DistributorTitle = ({ record, style }) => {
  const translate = useTranslate()
  return (
    <span style={style}>
      {translate('Distributor')} {record ? `"${record.name}"` : ''}
    </span>
  )
}

const inputStyle = {
  width: 256,
}

export const DistributorEdit = (props) => (
  <Edit title={<DistributorTitle />} {...props}>
    <SimpleForm>
      <TextInput source="name" style={inputStyle} />
      <ReferenceInput source="country" reference="countries" sort={{ field: 'name', order: 'ASC' }}>
        <AutocompleteInput
          options={{ style: inputStyle, placeholder: 'Enter a country' }}
          optionText="name"
          optionValue="url"
        />
      </ReferenceInput>
      <TextInput source="aliases" style={inputStyle} />
      <TextInput fullWidth={true} multiline={true} source="zips_csv" />
    </SimpleForm>
  </Edit>
)

export const DistributorCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" style={inputStyle} />
      <ReferenceInput source="country" reference="countries" sort={{ field: 'name', order: 'ASC' }}>
        <AutocompleteInput
          options={{ style: inputStyle, placeholder: 'Enter a country' }}
          optionText="name"
          optionValue="url"
        />
      </ReferenceInput>
      <TextInput source="aliases" style={inputStyle} />
      <TextInput fullWidth={true} multiline={true} source="zips_csv" />
    </SimpleForm>
  </Create>
)

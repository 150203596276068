// @ts-nocheck
import React from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import DialogEditor from '../../../../elements/DialogEditor'
import CreateTransactionDialogFormWrapper from './CreateTransactionDialogFormWrapper'

type PropTypes = {
  showEditDialog: boolean
  setShowEditDialog: (newVal: boolean) => void
  transactionToEdit: any
  onDismiss: () => void
}

const CreateTransactionDialog: React.FunctionComponent<PropTypes> = (props) => {
  const translate = useTranslate()
  const notify = useNotify()
  const form = useForm()
  const formState = useFormState()
  const projectUrl = formState.values.url

  const onFailure = () => {}

  const onSuccess = (response: any) => {
    notify('Your changes have been saved', 'success')
    const newTransaction = response.data
    const record = formState.initialValues
    record.transactions_data.push(newTransaction)
    props.onDismiss()
  }

  return (
    <DialogEditor
      isOpen={props.showEditDialog}
      handleClose={() => props.setShowEditDialog(false)}
      id={props.transactionToEdit.id}
      title={translate('Request Payment')}
      editorComponent={
        <CreateTransactionDialogFormWrapper
          basePath={'/transactions'}
          resource={'transactions'}
          redirect={undefined}
          hideCancelButton={true}
          hideBreadCrumbs={true}
          style={{ padding: 0 }}
          title={translate('Request Payment')}
          onDismiss={props.onDismiss}
          onSuccess={onSuccess}
          onFailure={onFailure}
          projectUrl={projectUrl}
        />
      }
    />
  )
}
export default CreateTransactionDialog

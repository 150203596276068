// @ts-nocheck
import Utils from 'Studio/Utils'
import withStudioSignals from 'Studio/signals/withStudioSignals'
import { viewModeSelectors } from 'ducks/viewMode'
import CircularProgressWithLabel from 'elements/CircularProgressWithLabel'
import WithPermissionsCheck from 'elements/WithPermissionsCheck'
import React, { Component } from 'react'
import { useTranslate } from 'react-admin'
import { connect, useSelector } from 'react-redux'
import compose from 'recompose/compose'
import { PremiumImageryBlockReasonType } from 'types/global'
import { ViewTypes } from 'types/view'
import AddNewViewButton from './AddNewViewButton'
import ToolbarViewsPopover from './ToolbarViewsPopover'
import ToolbarViewButton from './ViewButton'

type ClassesTypes = {
  iconButton: any
  iconButtonActive: any
  icon: any
  disabled: any
  positionWrapper: any
  button: any
  divider: any
}

type PropTypes = {
  data: any
  layout: number
  studioMode: string
  classes?: ClassesTypes
  visibleOverride?: boolean
  isPremiumImageryAvailable: boolean
  premiumImageryUnavailableReson: PremiumImageryBlockReasonType
}

type StateTypes = {
  visible: boolean
  anchorEl: HTMLElement | null
  selectedObject: any
  views: any[]
  popoverViewUuid: any | null
  activeViewData: any | null
  viewsLoading: boolean
}

const hasSystems = () => {
  var systems = window.editor && window.editor.getSystems ? window.editor.getSystems() : null
  return systems && systems.length > 0
}

const LoadingProgress: React.FunctionComponent<{ activeViewData: ViewTypes }> = (props) => {
  const translate = useTranslate()
  const { progress, progressParts } = useSelector((state: any) => {
    var weightings = {
      detect: 0.2,
      dsm: 0.5,
      ortho: 0.2,
      render: 0.1,
    }
    var progressParts = {
      detect: Boolean(state?.designer?.detectImagery?.availableMapTypes?.length) ? 1 : 0,
      dsm: state.designer.detectImagery.progressDsm,
      ortho: state.designer.detectImagery.progressOrtho,
      render: state.designer.detectImagery.progressRender,
    }

    // Special case: There are valid scenarios where loading progress bar should be considered complete
    // even though availableMapTypes detection has not occurred. e.g. In MyEnergy we can already show
    // 3D even before available imagery detection is complete. To avoid showing an unnecessary loading
    // indicator in that scenario, simply overwrite the "detect" value if all the other values are
    // already complete.
    if (progressParts.dsm && progressParts.ortho && progressParts.render) {
      progressParts.detect = 1
    }

    var totalWeighting = Object.values(weightings).reduce((a, b) => a + b, 0)

    return {
      progress:
        Object.keys(weightings)
          .map((key) => progressParts[key] * weightings[key])
          .reduce((a, b) => a + b, 0) / totalWeighting,
      progressParts: progressParts,
    }
  })

  if (!window.MapData.mapTypeIs3D(props.activeViewData?.mapType)) {
    // Only show when current view is 3D view that is loading
    return null
  }

  var label
  if (progressParts.detect < 1) {
    label = 'Detecting Imagery'
  } else if (progressParts.dsm < 1) {
    label = 'Loading 3D Model'
  } else if (progressParts.ortho < 1) {
    label = 'Loading 3D Textures'
  } else if (progressParts.render < 1) {
    label = 'Rendering 3D'
  }

  return progress < 1 ? <CircularProgressWithLabel value={Math.round(100 * progress)} label={translate(label)} /> : null
}

class ToolbarViews extends Component<PropTypes, StateTypes> {
  constructor(props: PropTypes) {
    super(props)

    var _state = {
      visible: false,
      selectedObject: null,
      anchorEl: null,
      popoverViewUuid: null,
      views: window.ViewHelper?.views || [],
      activeViewData: null,
      viewsLoading: false,
    }

    //Override with any supplied state - for use in storybook
    _state = Object.assign(_state, this.stateFromData(props.data || _state))

    this.state = _state
  }

  componentDidMount() {
    this.props.useStudioSignalsLazy(
      this.refreshPanel,
      ['sceneLoaded', 'viewsChanged', 'objectSelected', 'sceneGraphChanged', 'displayModeChanged'],
      this
    )
  }

  stateFromData(data: any) {
    var viewsData = data.views.map((v: ViewTypes, viewIndex: number) => {
      var label = window.MapData.getLabel(v.mapData)
      var keyboardShortcutSuffix = false && !Utils.iOS() ? ' (' + (viewIndex + 1) + ')' : ''
      return {
        uuid: v.uuid,
        labelMajor: label.major,
        labelMinor: label.minor + keyboardShortcutSuffix,
        show_customer: v.show_customer,
        mapType: v.mapData.mapType,
      }
    })

    var activeViewUuid = data.activeViewUuid ? data.activeViewUuid : null

    var activeViewData

    try {
      activeViewData = viewsData.filter(function (v: ViewTypes) {
        return v.uuid === activeViewUuid
      })[0]
    } catch (err) {
      activeViewData = null
    }

    //need a better way to decide visible value

    let visible = false

    if (this.props.studioMode === 'myenergy') {
      visible = true
    } else if (hasSystems()) {
      // Always show if a system is found regardless of what is happening with design mode selection
      visible = true
    } else {
      //When landing in Studio before design mode is selected (and no default design mode preference is saved)
      //there should be no views visible at the bottom of screen.
      //The only option to proceed should be to choose a design mode.

      if (!this.props.designModeState && !hasSystems()) {
        //initialing
        visible = false
      } else if (this.props.designModeState === 'open-locked' && !hasSystems()) {
        //before design mode is selected
        visible = false
      } else {
        visible = true
      }
    }

    return {
      visible,
      selectedObject: window.editor.selected,
      views: viewsData,
      activeViewData: activeViewData,
      viewsLoading: window.editor.viewTabsLoading(),
    }
  }

  handleSelectView = (viewUuid: string) => {
    //console.log(e)
    const { studioMode } = this.props

    if (window.ViewHelper) {
      //window.ViewHelper.loadViewByUuid(viewUuid, window.editor)
      if (window.Designer.controlMode && window.Designer.controlMode !== 'both') {
        window.Designer.showNotification('Warning: Unable to change view when AlignMap mode is active.', 'danger')
        return
      }
      if (studioMode === 'myenergy') {
        window.ViewHelper.loadViewByUuid(viewUuid, window.editor)
        window.ViewHelper.selectViewByUUID(viewUuid)
      } else {
        window.editor.execute(new window.SetViewCommand(viewUuid, window.ViewHelper))
      }
    } else {
      console.log('Warning: window.ViewHelper.loadViewByUuid not found... updating state directly for storybook')
      this.setState({
        activeViewData: this.state.views.filter((v) => v.uuid === viewUuid)[0],
      })
    }
  }

  handleDeleteView = (viewIndex: number) => {
    if (window.ViewHelper) {
      window.ViewHelper.deleteView(window.ViewHelper.getViewByIndex(viewIndex), window.editor)
    } else {
      console.log('Warning: window.ViewHelper.deleteView not found... updating state directly for storybook')

      // Note only affects local data not remove. e.g. In Storybook mockup
      var views = this.state.views
      views.splice(viewIndex, 1)
      this.setState({
        views: views,
        activeViewData: views[viewIndex - 1],
      })
    }
  }

  handleShowTexturesForViewToggle = (index: number) => {
    window.ViewHelper.toggleShowTexturesForView(window.ViewHelper.getViewByIndex(index))
  }

  handleShowCustomerForViewToggle = (index: number) => {
    window.ViewHelper.toggleShowCustomerForView(window.ViewHelper.getViewByIndex(index))
  }

  handleShowGroundForViewToggle = (index: number) => {
    window.ViewHelper.toggleShowGroundForView(window.ViewHelper.getViewByIndex(index))
  }

  handleStyleForViewToggle = (index: number) => {
    window.ViewHelper.toggleStyleForView(window.ViewHelper.getViewByIndex(index))
  }

  handleAddView = (index: number) => {
    window.ViewHelper.duplicateView(window.ViewHelper.getViewByIndex(index))
  }

  activeMapType = () => {
    return this.state.activeViewData.mapType
  }

  refreshPanel() {
    if (window.Designer && !window.Designer.allowUiUpdates()) {
      return
    }
    this.setState(
      this.stateFromData({
        //@TODO: We could avoid calling setState for changed to editor.selected which don't affect this
        views: window.ViewHelper.views,
        activeViewUuid: window.ViewHelper.selectedViewUuid(),
      })
    )
  }

  setPopover(anchorEl: HTMLElement | null, popoverViewUuid: any) {
    if (popoverViewUuid === this.state.popoverViewUuid) {
      this.setState({ anchorEl: null, popoverViewUuid: null })
    } else {
      this.setState({ anchorEl, popoverViewUuid })
    }
  }

  handleClose() {
    this.setPopover(null, null)
  }

  render() {
    if (!this.state.visible) {
      return null
    }
    const { layout } = this.props
    const { viewsLoading, anchorEl, popoverViewUuid, views = [] } = this.state
    const popoverView =
      popoverViewUuid === 'new' ? { uuid: 'new' } : views.find((view) => view.uuid === popoverViewUuid)
    return (
      <div
        id="ToolbarViews"
        style={{
          background: 'rgba(0,0,0,0.1)',
          borderRadius: 8,
          height: 67,
          pointerEvents: 'auto',
          margin: layout > 1 ? '0 20px' : '0 10px',
        }}
      >
        <div
          id="ToolbarViewsViewButtons"
          style={{
            padding: 5,
            display: 'flex',

            margin: '0 auto',
            width: 'fit-content',
            maxWidth: '100%',
            overflowX: 'auto',
            alignItems: 'flex-start',
          }}
        >
          {views.map((view, index) => {
            var isActiveView = this.state.activeViewData && view.uuid === this.state.activeViewData.uuid

            return (
              <ToolbarViewButton
                view={view}
                key={view.uuid}
                anchorEl={anchorEl}
                setPopover={this.setPopover.bind(this)}
                isActiveView={isActiveView}
                handleSelectView={this.handleSelectView}
              />
            )
          })}
          <WithPermissionsCheck
            permissionToCheck="design"
            permissions={['allowEdit']}
            disabledProp="disabled"
            disabledTooltip={translate('No permission to add/create views')}
          >
            <AddNewViewButton anchorEl={anchorEl} setPopover={this.setPopover.bind(this)} />
          </WithPermissionsCheck>
          {anchorEl && popoverViewUuid && popoverView && (
            <WithPermissionsCheck permissionToCheck="design" permissions={['allowEdit']}>
              <ToolbarViewsPopover
                anchorEl={anchorEl}
                handleClose={this.handleClose.bind(this)}
                view={popoverView}
                isPremiumImageryAvailable={this.props.isPremiumImageryAvailable}
                premiumImageryUnavailableReson={this.props.premiumImageryUnavailableReson}
              />
            </WithPermissionsCheck>
          )}

          <LoadingProgress activeViewData={this.state.activeViewData} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  studioMode: state.studioMode,
  designModeState: viewModeSelectors.designModeState(state),
})

export default compose(withStudioSignals, connect(mapStateToProps))(ToolbarViews)

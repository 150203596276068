import { setTransactionRequestData, transactionSelectors } from 'ducks/transaction'
import React, { useEffect, useState } from 'react'
import { RadioButtonGroupInput, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { CustomerActionType } from 'types/customerActions'
import { ProposalDataType } from 'types/proposals'
import { OFFLINE, STRIPE_PAYMENT_METHOD } from '../../constants'

type PropTypes = {
  proposalData: ProposalDataType
}

const PaymentMethodSelection: React.FC<PropTypes> = (props) => {
  const [offlineTitle, setOfflineTitle] = useState<string>('Offline Payment')
  const [shouldRenderChoice, setShouldRenderChoice] = useState<boolean>(false)

  const transactionRequestData = useSelector(transactionSelectors.getTransactionRequestData)
  const translate = useTranslate()
  const dispatch = useDispatch()
  const form = useForm()

  useEffect(() => {
    doSetup()
  }, [])

  const doSetup = () => {
    if (!props.proposalData?.selectedProject.available_customer_actions) {
      // there are no valid actions to show, this should never happen but let's not incorrectly populate form fields if it does
      return
    }
    let result = null as null | CustomerActionType
    let availablePaymentMethods = [] as string[]
    const customerActionsForSystem = props.proposalData?.selectedProject.available_customer_actions.filter(
      (ca) => ca.system_uuid === transactionRequestData?.system_uuid
    )
    customerActionsForSystem.forEach((ca) => {
      ca['actions_available'].forEach((avail_action) => {
        if (
          avail_action.payment_option_id === transactionRequestData?.payment_option_id &&
          avail_action.payment_method === OFFLINE
        ) {
          result = avail_action
        }

        // check for payment methods
        if (
          [STRIPE_PAYMENT_METHOD, OFFLINE].includes(avail_action.payment_method) &&
          avail_action.payment_option_id === transactionRequestData?.payment_option_id &&
          avail_action.status_code === 'available'
        ) {
          if (availablePaymentMethods.indexOf(avail_action.payment_method) === -1) {
            availablePaymentMethods.push(avail_action.payment_method)
          }
        }
      })
    })

    setOfflineTitle(result?.payment_title || 'Offline Payment')
    setShouldRenderChoice(availablePaymentMethods?.length > 1)

    //if there is only one payment method, we don't render anything here but we do want to update form state
    //and if there are multiple payment methods, we default to stripe
    if (availablePaymentMethods?.length === 1) {
      form.change('selected_payment_method', availablePaymentMethods[0])
    } else if (availablePaymentMethods?.length > 1) {
      if (availablePaymentMethods.includes(STRIPE_PAYMENT_METHOD)) {
        form.change('selected_payment_method', STRIPE_PAYMENT_METHOD)
      } else {
        form.change('selected_payment_method', availablePaymentMethods[0])
      }
    }
  }

  // when the payment method selection is changed we need to build and update the transactionRequestData object in redux
  const updateTransactionRequestData = (selectedPaymentMethod) => {
    let actionMatchingPaymentMethod = props.proposalData?.selectedProject?.available_customer_actions
      ?.find((systemActions) => {
        return systemActions?.system_uuid === props.proposalData?.selectedSystem?.uuid
      })
      ?.actions_available?.find((action) => action.payment_method === selectedPaymentMethod)

    if (actionMatchingPaymentMethod) {
      const quotationConfiguration =
        (props.proposalData.proposal_template_settings &&
          props.proposalData.proposal_template_settings.quotation_table_configuration &&
          props.proposalData.proposal_template_settings.quotation_table_configuration.length > 0 &&
          JSON.parse(props.proposalData.proposal_template_settings.quotation_table_configuration)) ||
        {}

      const newTransactionRequestData: CustomerActionType = Object.assign({}, actionMatchingPaymentMethod, {
        paymentOptionData: props.proposalData.selectedPaymentOption,
        pricing: props.proposalData.selectedSystem.pricing,
        quotationConfiguration: quotationConfiguration,
        systemSize: props.proposalData.selectedSystem.kw_stc,
        additional_costs: props.proposalData.selectedSystem.additional_costs,
      })

      if (actionMatchingPaymentMethod) {
        dispatch(setTransactionRequestData(newTransactionRequestData))
      }
    }
  }

  if (!shouldRenderChoice) return null
  return (
    <RadioButtonGroupInput
      source="selected_payment_method"
      label={translate('How would you like to pay your deposit?')}
      variant="outlined"
      onChange={updateTransactionRequestData}
      choices={[
        { id: STRIPE_PAYMENT_METHOD, name: translate('Credit Card') },
        { id: OFFLINE, name: translate(offlineTitle) },
      ]}
      data-testid="payment-method-selection-radio-group"
    />
  )
}
export default PaymentMethodSelection

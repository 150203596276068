import { DialogActions, DialogContent } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import Alert from 'elements/Alert'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'

const restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  roleId: number
  setShowNewUserForm: (val: boolean) => void
  isRoleEdit?: boolean
}

const PhoenixCreateRole: React.FC<PropTypes> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const orgId = useSelector(authSelectors.getOrgId)

  const onSubmit = (vals) => {
    setIsLoading(true)
    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${orgId}/roles/${props.roleId}/phoenix/users/create/`,
    })
      .then((res) => {
        if (res.data.url) {
          window.open(res.data.url, '_blank')
          logAmplitudeEvent('phoenix_new_user_redirected', {})
        }
      })
      .catch((err) => {
        console.log('err', err)
      })
      .finally(() => setIsLoading(false))
  }
  return (
    <>
      <DialogContent>
        <div
          style={{
            width: '95%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {!props.isRoleEdit && (
            <>
              <Alert severity="warning">
                The Assigned team member for this project has not connected their Phoenix User.
              </Alert>
              <p>
                Click the button below to be brought to Phoenix’s Trader Application form, which we’ve pre-filled with
                as much information as we can. After you submit your application, a member of the Phoenix team will
                review it and create your account.
              </p>
              <p>
                <strong>
                  Once your Phoenix account has been created, you can connect it to your OpenSolar account by returning
                  to this project or creating a new one, or by emailing us at phoenix@opensolar.com.
                </strong>
              </p>
            </>
          )}
          {props.isRoleEdit && (
            <>
              <p>
                Click the button below to be brought to Phoenix’s Trader Application form, which we’ve pre-filled with
                as much information as we can. After you submit your application, a member of the Phoenix team will
                review it and create your account.
              </p>
              <p>
                <strong>
                  Once the new Phoenix user is active you will need to come back to this page and connect this user to
                  the new Phoenix user.
                </strong>
              </p>
            </>
          )}
          <div style={{ display: 'flex', justifyContent: 'center', margin: '40px 0px 10px 0px' }}>
            <span
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => props.setShowNewUserForm(false)}
            >
              Connect an existing Phoenix user
            </span>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <div style={{ margin: '15px' }}>
            <ProUXButton type="primary" label="Go to Phoenix's User Form" onClick={onSubmit} loading={isLoading} />
          </div>
        </div>
      </DialogActions>
    </>
  )
}
type WrapperPropTypes = {
  roleId: number
}

export default PhoenixCreateRole

import { Grid } from '@material-ui/core'
import React from 'react'
import { currencySymbolForCurrencyCode, formatCurrencyWithSymbol } from 'util/misc'
import { LocalBankTransferDataType, PaymentRequestType } from '../types'

type PropTypes = {
  instructions: LocalBankTransferDataType
  paymentRequestData: PaymentRequestType
}

const TransferInstructions: React.FC<PropTypes> = (props) => {
  const currencySymbol = currencySymbolForCurrencyCode(props.paymentRequestData.currency)
  return (
    <div>
      <h2>Transfer Instructions</h2>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          Amount to Transfer
        </Grid>
        <Grid item xs={6}>
          {formatCurrencyWithSymbol(props.paymentRequestData.payment_amount, currencySymbol)}
        </Grid>
        <Grid item xs={6}>
          Account Number
        </Grid>
        <Grid item xs={6}>
          {props.instructions?.localBankTransferInfo?.bankAccountNumber}
        </Grid>
        <Grid item xs={6}>
          Bank Name
        </Grid>
        <Grid item xs={6}>
          {props.instructions?.localBankTransferInfo?.bankName}
        </Grid>
        <Grid item xs={6}>
          Bank Country
        </Grid>
        <Grid item xs={6}>
          {props.instructions?.localBankTransferInfo?.countryDescription}
        </Grid>
        <Grid item xs={6}>
          Bank City
        </Grid>
        <Grid item xs={6}>
          {props.instructions?.localBankTransferInfo?.bankCity}
        </Grid>
        <Grid item xs={6}>
          Bank City
        </Grid>
        <Grid item xs={6}>
          {props.instructions?.localBankTransferInfo?.bankCode}
        </Grid>
        {props.instructions?.localBankTransferInfo?.iban && (
          <>
            <Grid item xs={6}>
              IBAN
            </Grid>
            <Grid item xs={6}>
              {props.instructions?.localBankTransferInfo?.iban}
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          Swift Code
        </Grid>
        <Grid item xs={6}>
          {props.instructions?.localBankTransferInfo?.swiftCode}
        </Grid>
        <Grid item xs={6}>
          Payment Reference
        </Grid>
        <Grid item xs={6}>
          {props.instructions?.localBankTransferInfo?.paymentReference}
        </Grid>
      </Grid>
    </div>
  )
}
export default TransferInstructions

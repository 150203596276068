import { Grid } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import CustomField from 'elements/field/CustomField'
import { silentlyUpdateFormFields } from 'projectSections/form/util'
import React, { useEffect, useState } from 'react'
import { SelectInput, useDataProvider, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import { ACCESS_CHOICES, PRIORITY_CHOICES } from 'resources/projects/choices'
import restClient from 'restClient'
import ProjectAssignmentField from './ProjectAssignmentField'
import { RoleSelectOptionType, RoleWithDisplay } from './types'
import { convertRolesToOptions } from './util'

const restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  label: string
  name: string
  resource: string
  allowDelete: boolean
  projectId: number | undefined
  sharedWith: unknown[] | undefined
}

const ProjectAssignmentFieldDataWrapper: React.FC<PropTypes> = (props) => {
  const sharedWith = useFormState().values['shared_with']
  const projectId = useFormState().values['id']
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const form = useForm()
  const orgId = useSelector(authSelectors.getCurrentOrg)?.id

  const [initialData, setInitialData] = useState<RoleSelectOptionType[]>([])
  const [isFetchingRoles, setIsFetchingRoles] = useState<boolean>(true)
  const [isFetchingUpdates, setIsFetchingUpdates] = useState<boolean>(true)

  const fetchRoles = () => {
    setIsFetchingRoles(true)
    dataProvider
      .getList('roles', {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'display', order: 'DESC' },
        filter: { shared_project_id: projectId },
      })
      .then((res) => {
        let responseData = res.data as RoleWithDisplay[]
        setInitialData(convertRolesToOptions(responseData))
      })
      .catch((err) => console.log('err', err))
      .finally(() => setIsFetchingRoles(false))
  }

  useEffect(() => {
    if (projectId) fetchRoles()
  }, [projectId])

  useEffect(() => {
    fetchUpdatedAssignments()
  }, [])

  const fetchUpdatedAssignments = () => {
    setIsFetchingUpdates(true)
    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${orgId}/projects/${projectId}/assignment/`,
    })
      .then((res) => {
        if (res.data.assignments) {
          silentlyUpdateFormFields(form, res.data.assignments, true)
        }
      })
      .catch((err) => console.log('err', err))
      .finally(() => setIsFetchingUpdates(false))
  }

  if (isFetchingRoles || isFetchingUpdates) {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12} style={{ padding: '4px 0px' }}>
          <Skeleton width="100%" height="80px" />
        </Grid>
        <Grid item xs={12} style={{ padding: '4px 0px' }}>
          <Skeleton width="100%" height="80px" />
        </Grid>
        <Grid item xs={12} style={{ padding: '4px 0px' }}>
          <Skeleton width="100%" height="80px" />
        </Grid>
        <Grid item xs={12} style={{ padding: '4px 0px' }}>
          <Skeleton width="100%" height="80px" />
        </Grid>
        <Grid item xs={12} style={{ padding: '4px 0px' }}>
          <Skeleton width="100%" height="80px" />
        </Grid>
        <Grid item xs={12} style={{ padding: '4px 0px' }}>
          <Skeleton width="100%" height="80px" />
        </Grid>
        <Grid item xs={12} style={{ padding: '4px 0px' }}>
          <Skeleton width="100%" height="80px" />
        </Grid>
      </Grid>
    )
  }
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <ProjectAssignmentField
          name="assigned_role"
          resource="assigned_role"
          label="Assigned Team Member"
          allowDelete={props.allowDelete}
          projectId={projectId}
          sharedWith={sharedWith}
          initialData={initialData}
        />
      </Grid>
      <Grid item xs={12}>
        <ProjectAssignmentField
          name="assigned_salesperson_role"
          resource="project-assigned_salesperson_role"
          label="Assigned Salesperson"
          allowDelete={props.allowDelete}
          projectId={projectId}
          sharedWith={sharedWith}
          initialData={initialData}
        />
      </Grid>
      <Grid item xs={12}>
        <ProjectAssignmentField
          name="assigned_designer_role"
          resource="project-assigned_designer_role"
          label="Assigned Designer"
          allowDelete={props.allowDelete}
          projectId={projectId}
          sharedWith={sharedWith}
          initialData={initialData}
        />
      </Grid>
      <Grid item xs={12}>
        <ProjectAssignmentField
          name="assigned_site_inspector_role"
          resource="project-assigned_site_inspector_role"
          label="Assigned Site Inspector"
          allowDelete={props.allowDelete}
          projectId={projectId}
          sharedWith={sharedWith}
          initialData={initialData}
        />
      </Grid>
      <Grid item xs={12}>
        <ProjectAssignmentField
          name="assigned_installer_role"
          resource="project-assigned_installer_role"
          label="Assigned Installer"
          allowDelete={props.allowDelete}
          projectId={projectId}
          sharedWith={sharedWith}
          initialData={initialData}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomField
          name="priority"
          label={translate('Priority')}
          component={SelectInput}
          choices={PRIORITY_CHOICES}
          fullWidth={true}
          style={{ width: '100%' }}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomField
          name="access"
          label={translate('Access')}
          component={SelectInput}
          choices={ACCESS_CHOICES}
          fullWidth={true}
          style={{ width: '100%' }}
        />
      </Grid>
    </Grid>
  )
}
export default ProjectAssignmentFieldDataWrapper

import React from 'react'
import { useTranslate } from 'react-admin'

const SunlightQuickCreateParagraph: React.FunctionComponent = () => {
  const translate = useTranslate()
  return (
    <p style={{ textAlign: 'center' }}>
      {translate(
        "Save time when your customer's ready to apply. Click below to pre-populate the credit or prequalification application in the Orange Portal. When your customer is ready to apply, just open up that application in the Orange Portal and get your customer qualified."
      )}
    </p>
  )
}
export default SunlightQuickCreateParagraph

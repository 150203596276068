import { getCompatibleCodesForSystem } from 'elements/hardwareSelector/SearchToolbar'
import { useCallback } from 'react'
import type {
  FilterComponentRendererType,
  FilterGenericPropsType,
  FilterKeysType,
  FilterValuesType,
  OptionGenericType,
} from '../type'

export type FilterOptionsType = OptionGenericType<string>[]

type FilterProps = FilterGenericPropsType & {
  RendererComponent: FilterComponentRendererType<FilterOptionsType>
  filterKey: FilterKeysType
  id: string
}

const BATTERY_COMPATIBILITY_OPTIONS: FilterOptionsType = [
  { id: 'default', title: 'All', value: '' },
  { id: 'yes', title: 'Yes', value: 'true' },
]

const getBatteryCompatibilityValue = ({
  filterValues,
  filterKey,
}: {
  filterValues: FilterValuesType
  filterKey: FilterKeysType
}) => {
  const codes = filterValues[filterKey]
  const compatible = !!codes && getCompatibleCodesForSystem('battery').join(',') === codes
  return compatible ? 'true' : undefined
}

// TODO: getCompatibleCodesForSystem not responsive
const BatteryCompatibilityFilter = ({
  id,
  filterKey,
  modifiableFilterValues,
  persistentFilterValues,
  allFilterValues,
  setModifiableFilters,
  RendererComponent,
}: FilterProps) => {
  const onSelect = useCallback(
    (value) => {
      if (value === 'true') {
        setModifiableFilters({
          ...modifiableFilterValues,
          [filterKey]: getCompatibleCodesForSystem('battery').join(','),
        })
      } else {
        if (!!modifiableFilterValues[filterKey]) {
          const newModifiableFilterValues = { ...modifiableFilterValues }
          delete newModifiableFilterValues[filterKey]
          setModifiableFilters(newModifiableFilterValues)
        }
      }
    },
    [modifiableFilterValues]
  )

  return (
    <RendererComponent
      id={id}
      defaultValue={''}
      label={'Compatible with system'}
      options={BATTERY_COMPATIBILITY_OPTIONS}
      disabled={getCompatibleCodesForSystem('battery').length === 0}
      onSelect={onSelect}
      value={getBatteryCompatibilityValue({ filterValues: allFilterValues, filterKey })}
    />
  )
}

export default BatteryCompatibilityFilter

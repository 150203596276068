import { Box, Card, CardActions, CardContent, Typography, makeStyles } from '@material-ui/core'
import { ChevronRight } from '@material-ui/icons'
import { OpenSolarThemeType } from 'Themes'
import { Button } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'
import { useHistory } from 'react-router-dom'

type PropTypes = {
  type: string
  totalCount: number | undefined
  statusFilter: string
  setStatusFilter: (string) => void
}

const useStyles = makeStyles<OpenSolarThemeType, { invoiceType }>((theme) => ({
  card: {
    borderRadius: 10,
    cursor: 'pointer',
  },

  activeCard: {
    borderRadius: 10,
    cursor: 'pointer',
    border: ({ invoiceType }) => {
      switch (invoiceType) {
        case 'Outstanding':
          return '2px solid #2C499D !important'
        case 'Overdue':
          return '2px solid #C11B05 !important'
        case 'Paid':
          return '2px solid #277D19 !important'
        case 'In Progress':
          return '2px solid #E8C376 !important'
        default:
          return 'none'
      }
    },
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.05), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
  },

  flexRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  badge: {
    height: '12px',
    width: '12px',
    marginLeft: '5px',
    borderRadius: '12px',
    backgroundColor: ({ invoiceType }) => {
      switch (invoiceType) {
        case 'Outstanding':
          return '#2C499D'
        case 'Overdue':
          return '#C11B05'
        case 'Paid':
          return '#277D19'
        case 'In Progress':
          return '#E8C376'
        default:
          return 'transparent'
      }
    },
  },

  invoiceCount: {
    fontWeight: 500,
    fontSize: 25,
  },

  cardLabel: {
    color: theme.greyMid1,
  },

  viewBtn: {
    color: theme.alert_info,
  },
}))

const SummaryCard: React.FC<PropTypes> = (props) => {
  const classes = useStyles({ invoiceType: props.type })
  const translate = useTranslate()
  const history = useHistory()

  const FILTERS_MAP = {
    Outstanding: 'requested,viewed,payment failed,transfer instructions viewed',
    'In Progress': 'paid in full,pending,partially paid',
    Overdue: 'expired',
    Paid: 'completed',
  }

  const filterInvoices = (type) => {
    history.push(`/cashflow/invoices?&filter={"status":"${FILTERS_MAP[type]}"}`)
    props.setStatusFilter(type)
  }

  return (
    <Card
      className={`${props.type === props.statusFilter ? classes.activeCard : classes.card}`}
      onClick={() => filterInvoices(props.type)}
    >
      <CardContent>
        <Box className={classes.flexRow}>
          <Typography variant="h5" className={classes.invoiceCount}>
            {props.totalCount}
          </Typography>
          <div className={classes.badge}></div>
        </Box>
        <Typography variant="subtitle1" className={classes.cardLabel}>
          {translate('%{type}', { type: props.type })}
        </Typography>
      </CardContent>

      <CardActions>
        <Button size="small" color="info" variant="text" className={classes.viewBtn}>
          {translate('View')} <ChevronRight />
        </Button>
      </CardActions>
    </Card>
  )
}

export default SummaryCard

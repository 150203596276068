import { makeStyles } from '@material-ui/core'
import { Theme } from 'types/themes'

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapRow: {
    flexWrap: 'wrap',
  },
  header: {
    width: '100%',
    paddingTop: 15,
    '& h1': {
      fontSize: 20,
      fontWeight: theme.typography.fontWeightRegular,
    },
    '& button': {
      marginLeft: 'auto',
    },
  },
  bottomMargin: {
    margin: 0,
    marginBottom: 20,
  },
  fullWidthInput: {
    width: '100%',
    marginRight: 5,
  },
  textInput: {
    width: '50%',
    marginRight: 5,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  simpleInput: {
    width: '100%',
    margin: 0,
  },
  numInput: {
    width: '25%',
    marginRight: 5,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& hr': {
      margin: '20px 0',
    },
  },
  infoIcon: {
    color: theme.greyMid1,
    height: 20,
    marginLeft: 5,
  },
  staffSubText: {
    color: theme.greyMid1,
  },
  blueText: {
    color: theme.blue,
  },
  blueButton: {
    border: `2px solid ${theme.blue} !important`,
    backgroundColor: 'rgba(0, 148, 255, 0.5)',
  },
  tariffHistory: {
    display: 'flex',
    '& p': {
      width: 120,
    },
  },
  tariffVersions: {
    flexWrap: 'wrap',
  },
  booleanStyle: {
    width: 256,
    minWidth: 256,
  },
  autoComplete: {
    width: '50%',
    marginRight: 5,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

export const tableStyles = makeStyles<Theme>((theme: Theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  tableContainer: {
    marginBottom: 30,
  },
  tableHeader: {
    fontWeight: theme.typography.fontWeightBold,
    backgroundColor: theme.greyMid2,
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    padding: '15px',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    padding: 10,
    paddingBottom: 0,
    borderLeft: '1px solid ' + theme.greyMid2,
    borderRight: '1px solid ' + theme.greyMid2,
    '& p': {
      margin: '5px 0',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& hr': {
      marginBottom: '0 !important',
      marginTop: '10px !important',
    },
  },
  lastRow: {
    paddingBottom: 10,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    borderBottom: '1px solid ' + theme.greyMid2,
  },
  scheduleHeaders: {
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  subHeaders: {
    '& div': {
      '& span:first-child': {
        width: '60%',
      },
      width: '60%',
    },
    '& span': {
      fontWeight: theme.typography.fontWeightRegular,
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& div': {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
  },
  threeCol: {
    '& span:nth-child(2)': {
      width: '67%',
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        width: '50%',
      },
      [theme.breakpoints.down('sm')]: {
        width: 'unset',
      },
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      margin: '10px 0',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  adjustmentHeaders: {
    '& span:first-child': {
      width: '35%',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginTop: 5,
      justifyContent: 'space-between',
    },
  },
  //pricing rate
  rateContainer: {
    padding: '4px 0px 2px',
    backgroundColor: theme.greyLight1,
    border: '1px solid ' + theme.greyMid2,
    borderRadius: 5,
    marginBottom: 5,
    display: 'flex',
    flexDirection: 'row',
  },
  deleteBtn: {
    padding: 5,
    marginBottom: 'auto',
    [theme.breakpoints.down('lg')]: {
      marginRight: 'auto',
    },
  },
  addRate: {
    marginRight: 'auto',
  },
  rateContent: {
    display: 'flex',
    alignContent: 'stretch',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  addRatePeriod: {
    marginLeft: 0,
    marginTop: 10,
  },
  addTimeOfUseBtn: {
    marginBottom: 15,
  },
  //rate period
  ratePeriod: {
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: theme.greyLight1,
    borderRadius: 5,
    padding: 8,
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
  },
  periodRow: {
    width: '100%',
    marginRight: 5,
    '& .periodRow': {
      borderBottom: '1px solid ' + theme.greyMid2,
      borderRadius: 0,
    },
    '& .periodRow:first-child': {
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
    },
    '& .periodRow:last-child': {
      borderBottom: 'none',
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
    },
  },
  multiTier: {
    marginTop: 2,
    marginBottom: 'auto',
  },
  rateName: {
    width: '20%',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  rateInput: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    margin: 0,
    height: 30,
    backgroundColor: theme.white,
    '& .MuiInputBase-root': {
      fontSize: 14,
      width: '100%',
    },
  },
  rateRow: {
    marginBottom: 5,
  },

  criteriaInputsBlock: {
    padding: '8px',
  },
  criteriaInput: {
    margin: '0px',
    width: '100%',
    minWidth: '120px',
    '& .MuiFormHelperText-root': {
      display: 'none !important',
    },
    '& .MuiInputBase-root': {
      height: 30,
      backgroundColor: theme.white,
    },
  },
  criteriaTextInput: {
    '& .MuiInputLabel-marginDense': {
      marginTop: '-4px',
    },
  },

  tierContainer: {
    margin: '0 20px',
    width: '45%',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      margin: '5px 0',
    },
  },
  scheduleContainer: {
    width: '35%',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  //period tiers
  periodTier: {
    display: 'flex',
    alignItems: 'center',
    // border: '3px solid red',
    // '& > div:first-child': {
    //   border: '3px solid red',
    // },
    [theme.breakpoints.down('lg')]: {
      marginBottom: 5,
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      borderBottom: '1px solid ' + theme.greyMid2,
    },
  },
  tierLabel: {
    marginRight: 5,
    width: '4em',
    fontSize: '.8em',
  },
  tierInputWithLabel: {
    width: '33%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  tierInput: {
    width: '30%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  adjustmentContainer: {
    width: '60%',
    paddingLeft: 5,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: 0,
      marginTop: 5,
    },
  },
  midInputContainer: {
    width: '30%',
    padding: '0 5px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: 0,
      margin: '5px 0',
    },
  },
  deleteContainer: {
    width: '6%',
    [theme.breakpoints.down('md')]: {
      width: 'unset',
    },
  },
  addTierBtn: {
    margin: 0,
    whiteSpace: 'nowrap',
  },
  deleteTier: {
    padding: 5,
  },
}))

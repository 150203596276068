import { duplicate } from 'actions/restActions'
import { authSelectors } from 'ducks/auth'
import { permissionsSelectors } from 'ducks/permissions'
import { ControlBreadcrumbLink, ListBreadCrumbs } from 'elements/BreadCrumbs'
import TextFieldWithSharedEntityIcon from 'elements/TextFieldWithSharedEntityIcon'
import EditOrImportButton from 'elements/button/EditOrImportButton'
import { useBulkActionButtons } from 'elements/hooks/useBulkActionButtons'
import { List } from 'elements/react-admin/List'
import ListActions from 'elements/react-admin/ListActions'
import { Button } from 'opensolar-ui'
import React from 'react'
import { BooleanField, Create, Datagrid, Edit, FunctionField, NotFound, useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import WorkflowForm from './WorkflowForm'

export const WorkflowList = (props) => {
  const { allowView, allowCreate, allowEdit, allowDelete } = useSelector(
    permissionsSelectors.getPermissionByKey('business_process')
  )
  const dispatch = useDispatch()
  const translate = useTranslate()
  const orgId = useSelector(authSelectors.getOrgId)
  const isAdmin = useSelector(authSelectors.getIsAdmin)
  const bulkActionButtons = useBulkActionButtons(props, allowEdit, allowDelete)

  if (!allowView) {
    return <NotFound />
  }

  const breadcrumbs = [
    ControlBreadcrumbLink,
    { title: 'Business Process', link: '/business-process' },
    { title: 'Project Workflows' },
  ]
  const duplicateAction = (payload) => {
    dispatch(duplicate(payload))
  }
  return (
    <List
      breadCrumbs={<ListBreadCrumbs links={breadcrumbs} />}
      hasSearch={true}
      perPage={20}
      actions={
        <ListActions
          CustomButton={
            <Link to={'/actions'}>
              <Button variant="contained" color="default">
                {translate('Actions List')}
              </Button>
            </Link>
          }
          hasArchived={true}
          createButtonLabel="Add Workflow"
        />
      }
      {...props}
      hasCreate={allowCreate}
      bulkActionButtons={bulkActionButtons}
    >
      <Datagrid>
        <TextFieldWithSharedEntityIcon source="title" label="Title" />
        <BooleanField source="is_default" label={'Is Default'} displayInCard={true} sortable={false} />
        {
          <FunctionField
            label="Actions"
            render={(record, source, rest) => (
              <EditOrImportButton
                sortable={false}
                org_id={orgId}
                source="actions"
                label="Actions"
                resource={props.resource}
                //@ts-ignore
                record={record}
                basePath={props.basePath}
                duplicate={duplicateAction}
                isAdmin={isAdmin}
                allowEdit={allowEdit}
                allowDelete={allowDelete}
              />
            )}
            sortable={false}
          />
        }
      </Datagrid>
    </List>
  )
}

export const WorkflowEdit = (props) => {
  const { allowView, allowDelete } = useSelector(permissionsSelectors.getPermissionByKey('business_process'))
  if (!allowView) {
    return <NotFound />
  }
  return (
    <Edit {...props} hasDelete={allowDelete}>
      <WorkflowForm {...props} />
    </Edit>
  )
}

export const WorkflowCreate = (props) => {
  return (
    <Create {...props}>
      <WorkflowForm {...props} />
    </Create>
  )
}

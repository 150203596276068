import _ from 'lodash'

import formatTariffSubmitValues from 'projectSections/sections/energy/formatTariffSubmitValues'
import formatWorkflowValues from 'projectSections/sections/manage/projectManagement/formatWorkflowValues'
import formatManageValues from 'projectSections/sections/manage3/formatManageValues'
import { useCallback, useRef } from 'react'
import formatDesignSubmitValues from '../sections/design/formatDesignSubmitValues'
import { useFormatUsageSubmitValues } from '../sections/energy/formatUsageSubmitValues'
import formatSharedWithValues from '../sections/info/orgSharing/formatSharedWithValues'
import formatChangeOrderValues from '../sections/info/sale/formatChangeOrderValues'
import pipe from './pipe'

type Formatter = (formValues: any, dirtyFields: string[]) => any

export const useProjectFormFormatter = (): Formatter => {
  const formatUsageSubmitValues = useFormatUsageSubmitValues()

  const formatter: Formatter = useCallback(
    (formValues, dirtyFields) => {
      const submitValues = _.pick(formValues, dirtyFields)
      pipe(
        formatUsageSubmitValues,
        formatDesignSubmitValues,
        formatSharedWithValues,
        formatTariffSubmitValues,
        formatWorkflowValues,
        formatManageValues,
        formatChangeOrderValues
      )(submitValues, formValues, dirtyFields)
      return submitValues
    },
    [formatUsageSubmitValues]
  )

  const latestFormatter = useRef(formatter)
  latestFormatter.current = formatter

  // This hook always returns the same function reference
  // Internally though, this points to the latest formatter function
  // This is because react-final-form doesn't accept changes to the function over time
  return useCallback((formValues, dirtyFields): Formatter => latestFormatter.current(formValues, dirtyFields), [])
}

//TODO: fix
type ComponentType = any

export const withProjectFormFormatter = (BaseComponent: ComponentType): ComponentType => {
  const ComponentWithProjectFormFormatter = (props: any) => {
    const formatter = useProjectFormFormatter()
    return <BaseComponent {...props} projectFormFormatter={formatter} />
  }

  ComponentWithProjectFormFormatter.defaultProps = BaseComponent.defaultProps

  return ComponentWithProjectFormFormatter
}

import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { List } from 'elements/react-admin/List'
import moment from 'moment'
import React, { useEffect } from 'react'
import { Datagrid, DateInput, FilterComp, FunctionField, TextField, TextInput } from 'react-admin'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getLocale } from 'storage/appStorage'
import { AccessRightsType } from 'types/global'
import { currencySymbolForCurrencyCode, formatCurrencyWithSymbol, taxNameForCountryIso2 } from 'util/misc'
import ReversalButton from './ReversalButton'
import WalletTransactionListToolbar from './WalletTransactionListToolbar'
import { useTransactionTaxInfo } from './useTransactionTaxInfo'

type PropTypes = {
  accessRights: AccessRightsType
  isGlobal: boolean
  match: {
    isExact: boolean
    params: { orgId: string } | undefined
    path: string
    url: string
  }
}

const Filters = (props) => {
  const isSuperUser: boolean = useSelector(authSelectors.getIsSuperUser) && window.location.href.includes('/global')
  return (
    <FilterComp {...props}>
      <TextInput label="Product" source="product_purchased_name" />
      <TextInput label="Project" source="project_title" />
      <TextInput label="User Email" source="user_email" />
      <TextInput label="Invoice Id" source="invoice_id" />
      <TextInput label="Activity" source="transaction_type" />
      <DateInput label="Created After" source="created_date__gte" />
      <DateInput label="Created Before" source="created_date__lte" />
      {isSuperUser && <TextInput label="Org Name" source="org_name" />}
    </FilterComp>
  )
}

export const ValueIncTAX = ({ record }) => {
  const symbol = currencySymbolForCurrencyCode(record.currency)
  return <>{formatCurrencyWithSymbol(record.amount_inc_tax, symbol, getLocale())}</>
}

export const WalletTransactionList: React.FC<PropTypes> = (props) => {
  useEffect(() => {
    moment.locale(window.locale)
  }, [])

  const isSuperUser: boolean = useSelector(authSelectors.getIsSuperUser) && window.location.href.includes('/global')

  const countryIso2 = useSelector(orgSelectors.getOrgIso2)
  const taxName = taxNameForCountryIso2(countryIso2)

  return (
    <List
      {...props}
      hasCreate={isSuperUser}
      hasSearch={false}
      filters={<Filters />}
      actions={<WalletTransactionListToolbar createButtonLabel="Create" hasExport={true} useCreateDialog={true} />}
      //@ts-ignore
      subtitle={useTransactionTaxInfo(countryIso2)}
    >
      <Datagrid>
        <FunctionField label="Date" render={(record) => moment(record.created_date).format('LL')} />
        {isSuperUser && <TextField source="transaction_line_items.0.product_purchased_name" label="Product" />}
        {isSuperUser && <TextField source="org_name" label="Org Name" />}
        <TextField source="user_email" label="User Email" />
        <TextField source="transaction_line_items.0.project_title" label="Project" />
        <TextField source="transaction_type_label" label="Type" />
        <FunctionField
          source="id"
          render={(record) => (
            <p
              id="wallet_activity_id"
              style={{
                fontWeight: 'bold',
                fontSize: '1em',
                margin: 0,
                textOverflow: 'ellipsis',
              }}
            >
              <Link
                to={(isSuperUser ? '/global_wallet_transactions/' : '/wallet_transactions/') + record.id}
                style={{}}
              >
                {record.id}
              </Link>
            </p>
          )}
        />

        {isSuperUser && <TextField source="transaction_line_items.0.units" label="Quantity" />}
        {/*@ts-ignore*/}
        <ValueIncTAX />
        <FunctionField
          label={taxName}
          source="tax_combined"
          render={(record) =>
            (record.amount_inc_tax ? Math.round((record.tax_combined / record.amount_ex_tax) * 100) : 0) + '%'
          }
        />
        {isSuperUser && <TextField source="currency" label="Currency" />}
        {/*@ts-ignore*/}
        {isSuperUser && <ReversalButton />}
      </Datagrid>
    </List>
  )
}
export default WalletTransactionList

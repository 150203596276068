var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import MuiTooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { defaultRules } from './defaultRules';
import { ExampleComponent } from './ExampleComponent';
import { getStyleDefinition } from './getStyleDefinition';
import { makeStyleNotices } from './makeStyleNotices';
import { PropsTable } from './PropsTable';
import { StyleWarnings } from './StyleWarnings';
import { StyleWarningLevel } from './types';
var useStyles = makeStyles({
    componentHover: {
        pointerEvents: 'unset',
        outline: '2px dashed BlueViolet !important',
        outlineOffset: 1,
        '&.style-warning-warning': {
            outlineColor: 'orange !important',
        },
        '&.style-warning-error': {
            outlineColor: 'red !important',
        },
    },
    tooltip: {
        background: 'black',
        fontFamily: 'monospace',
        color: 'white',
    },
    subhead: {
        marginTop: 10,
    },
}, { name: 'InspectOverlay' });
// Force this to only be assessed once per session, requires reload, but ensures no performance hit
var enableUiInspector = localStorage.getItem('enableUiInspector') === 'true';
export function Inspector(props) {
    if (!enableUiInspector)
        return _jsx(props.component, __assign({}, props.props));
    return _jsx(InspectorEnabled, __assign({}, props));
}
/*
This component only really exists to allow Inspector to exit early when not enabled.
That is, otherwise all of the following hooks would complain about conditionally being run.
*/
function InspectorEnabled(_a) {
    var name = _a.name, Component = _a.component, rules = _a.rules, propDefs = _a.propDefs, showExample = _a.showExample, props = _a.props;
    var classes = useStyles();
    var _b = useState(StyleWarningLevel.info), highestLevel = _b[0], setHighestLevel = _b[1];
    //Nested state here is to get around annoying react recursion
    var _c = useState({ notices: [] }), styleNotices = _c[0], setStyleNotices = _c[1];
    var calcHighestLevel = useCallback(function () {
        var highestLevelNew = styleNotices.notices.reduce(function (prev, curr) { return Math.max(prev, curr.highestLevel); }, 0) || StyleWarningLevel.info;
        setHighestLevel(highestLevelNew);
    }, [styleNotices]);
    var addStyleNotices = useCallback(function (id, name, order, notices) {
        var existing = styleNotices.notices.filter(function (n) { return n.id !== id; });
        if (notices) {
            existing.push(__assign({ id: id, name: name, order: order }, notices));
            existing.sort(function (a, b) { return a.order - b.order; });
        }
        styleNotices.notices = existing;
        setStyleNotices(styleNotices);
        calcHighestLevel();
    }, [styleNotices]);
    var clearStyleNotices = function (idStartsWith) {
        var existing = styleNotices.notices.filter(function (n) { return !n.id.startsWith(idStartsWith); });
        styleNotices.notices = existing;
        setStyleNotices(styleNotices);
        calcHighestLevel();
    };
    var realRules = rules || defaultRules;
    var styleProp = props['style'];
    useEffect(function () {
        addStyleNotices('style', "'style' prop", 0, makeStyleNotices(styleProp ? [{ values: styleProp }] : [], realRules));
    }, [styleProp, addStyleNotices]);
    var className = props['className'];
    useEffect(function () {
        clearStyleNotices('className.');
        if (!className)
            return;
        var classNames = className.split(' ');
        var i = 0;
        for (var _i = 0, classNames_1 = classNames; _i < classNames_1.length; _i++) {
            var key = classNames_1[_i];
            var id = "className: '".concat(key, "'");
            addStyleNotices(id, "'className' prop (.".concat(key, ")"), 1 + i / classNames.length, makeStyleNotices(getStyleDefinition('.' + key), realRules));
            i++;
        }
    }, [className, addStyleNotices]);
    var classesProp = props['classes'];
    // Extra caching step as 'classes' is often declared inline
    var classesKey = useMemo(function () { return JSON.stringify(classesProp); }, [classesProp]);
    useEffect(function () {
        clearStyleNotices('classes.');
        if (!classesProp) {
            return;
        }
        var i = 0;
        var keys = Object.keys(classesProp);
        for (var key in classesProp) {
            var id = 'classes.' + key;
            addStyleNotices(id, "'".concat(id, "' prop"), 2 + i / keys.length, makeStyleNotices(getStyleDefinition('.' + classesProp[key]), realRules));
            i++;
        }
    }, [classesKey, addStyleNotices]);
    var newClassName = classes.componentHover + ' ' + getLevelStyle(highestLevel) + ' ' + (className || '');
    return (_jsx(_Fragment, { children: _jsx(MuiTooltip, __assign({ classes: { tooltip: classes.tooltip }, title: _jsxs(_Fragment, { children: [_jsx("div", { children: name }), propDefs && _jsx(PropsTable, { propDefs: propDefs, props: props }), !!styleNotices.notices.length && _jsx("div", __assign({ className: classes.subhead }, { children: "Style Warnings:" })), styleNotices.notices.map(function (n) { return (_jsx(StyleWarnings, { name: n.name, notices: n.styles }, n.id)); }), showExample && (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: classes.subhead }, { children: "Standardised Example:" })), _jsx(ExampleComponent, { type: Component, propDefs: propDefs, props: props })] }))] }) }, { children: _jsx(Component, __assign({}, props, { className: newClassName })) })) }));
}
var getLevelStyle = function (level) {
    return 'style-warning-' + StyleWarningLevel[level];
};

import { Grid } from '@material-ui/core'
import CustomField from 'elements/field/CustomField'
import React from 'react'
import { NumberInput, TextInput, useTranslate } from 'react-admin'

// const TRANSACTION_TYPES = [{ id: 'reversal', name: 'Reversal' }]

type PropTypes = {}

const WalletReversalForm: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  return (
    <>
      <Grid item xs={12} sm={4}>
        <CustomField
          name="transaction_type"
          source="transaction_type"
          label={translate('Type of Credit/Debit')}
          component={TextInput}
          disabled={true}
          fullWidth
          style={{ width: '100%' }}
          required={false}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <CustomField
          name="invoice_id"
          source="invoice_id"
          label={translate('Invoice ID')}
          component={TextInput}
          disabled={true}
          fullWidth
          style={{ width: '100%' }}
          required={false}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <CustomField
          name="amount_inc_tax"
          source="amount_inc_tax"
          label={translate('Total Amount')}
          component={NumberInput}
          disabled={true}
          fullWidth
          style={{ width: '100%' }}
          required={false}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <CustomField
          name="tax_combined"
          source="tax_combined"
          label={translate('Tax')}
          component={NumberInput}
          disabled={true}
          fullWidth
          style={{ width: '100%' }}
          required={false}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <CustomField
          name="created_date"
          source="created_date"
          label={translate('Date')}
          component={TextInput}
          disabled={true}
          fullWidth
          style={{ width: '100%' }}
          required={false}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <CustomField
          name="product_purchased_name"
          source="product_purchased_name"
          label={translate('Provider')}
          component={TextInput}
          disabled={true}
          fullWidth
          style={{ width: '100%' }}
          required={false}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <CustomField
          name="currency"
          source="currency"
          label={translate('Currency')}
          component={TextInput}
          disabled={true}
          fullWidth
          style={{ width: '100%' }}
          required={false}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <CustomField
          name="created_by_email"
          source="created_by_email"
          label={translate('User Email')}
          component={TextInput}
          disabled={true}
          fullWidth
          style={{ width: '100%' }}
          required={false}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <CustomField
          name="project_title"
          source="project_title"
          label={translate('Project Title')}
          component={TextInput}
          disabled={true}
          fullWidth
          style={{ width: '100%' }}
          required={false}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <CustomField
          name="previous_transaction_type"
          source="previous_transaction_type"
          label={translate('Activity')}
          component={TextInput}
          disabled={true}
          fullWidth
          style={{ width: '100%' }}
          required={false}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomField
          name="notes"
          source="notes"
          label={translate('Notes')}
          component={TextInput}
          fullWidth
          style={{ width: '100%' }}
          required={false}
        />
      </Grid>
    </>
  )
}
export default WalletReversalForm

import {
  ComponentOtherCreate,
  ComponentOtherEdit,
  ComponentOtherList,
  customOtherDataFieldsCable,
  customOtherDataFieldsDcOptimizer,
  customOtherDataFieldsForAdditionalCosts,
  customOtherDataFieldsIsolator,
  customOtherDataFieldsMeter,
  customOtherDataFieldsShowCustomer,
  otherDataFields,
} from './otherComponents'

import { customOtherDataFields } from './constants'

export default {
  list: ComponentOtherList,
  edit: ComponentOtherEdit,
  create: ComponentOtherCreate,
}
export {
  customOtherDataFields,
  customOtherDataFieldsCable,
  customOtherDataFieldsDcOptimizer,
  customOtherDataFieldsForAdditionalCosts,
  customOtherDataFieldsIsolator,
  customOtherDataFieldsMeter,
  customOtherDataFieldsShowCustomer,
  otherDataFields,
}

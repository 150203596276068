import { setAmplitudeEventGroups } from 'amplitude/amplitude'
import { useUxVersion } from 'hooks/useUxVersion'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { projectInfoSelector } from 'reducer/project/projectInfoReducer'

export const useSetAmplitudeIdentity = () => {
  const project_id = useSelector(projectInfoSelector.getProjectId)
  const project_country_iso2 = useSelector(projectInfoSelector.getProjectCountryIso2)
  const ux_version = useUxVersion()
  // Todo: move this to saga
  useEffect(() => {
    setAmplitudeEventGroups({ project_id, project_country_iso2, ux_version })
  }, [project_id, project_country_iso2, ux_version])
}

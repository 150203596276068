import { makeStyles, useMediaQuery } from '@material-ui/core'
import { LYRA_DEFAULT_MODULE_SPACING } from 'Designer/integrations/lyra/constants'
import { permissionsSelectors } from 'ducks/permissions'
import { tourSelectors } from 'ducks/tour'
import { useShowTabs } from 'ducks/viewMode'
import HardwareSelector from 'elements/hardwareSelector/HardwareSelector'
import { StudioContainer } from 'elements/StudioContainer'
import { useUxVersion } from 'hooks/useUxVersion'
import FullScreenLoading from 'layout/widgets/FullScreenLoading'
import { useAutoSaveProjectEnabled } from 'projectSections/hooks/useAutoSaveProjectEnabled'
import { useLyraPermitPackEnabled } from 'projectSections/integrations/lyra/useLyraPermitPackEnabled'
import React, { useCallback, useEffect, useState } from 'react'
import { NotFound, useNotify } from 'react-admin'
import { Field, useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ProjectType } from 'types/projects'
import { RootState } from 'types/state'
import { Theme } from 'types/themes'
import { parseQueryStringToDictionary } from 'util/misc'
import { useFeatureFlag } from 'util/split'
import SectionContainer from '../SectionContainer'
import DesignPageNotAvailable from './DesignPageNotAvailable'
import PageDesignerLite from './lite/PageDesignerLite'
import PageDesigner from './PageDesigner'

const getProjectIdSpecial = () => (window.location.hash.includes('/replay/') ? 'replay' : 'new')

const useStyles = makeStyles({
  fillContainer: { position: 'absolute', width: '100%', height: '100%', inset: 0 },
})

const DesignPage: React.FC = () => {
  const form = useForm()
  const formState = form.getState()
  const project = formState.values as ProjectType
  const { allowView } = useSelector(permissionsSelectors.getProjectPermissionByKey('design'))
  const isProjectRequireSystemCalcs = form.mutators.isUnsavedProjectDataRequireSystemCalcs()
  const projectId = formState.values.id || getProjectIdSpecial()
  const isLite = !!form.getState().values.is_lite
  const tour = useSelector(tourSelectors.tour)
  const isInFujiTour = tour === 'fuji'
  const lyraEnabled = useLyraPermitPackEnabled()
  const classes = useStyles()
  const autoSaveEnabled = useAutoSaveProjectEnabled()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'), { noSsr: true })
  const uxVersion3 = useUxVersion() === 3

  useEffect(() => {
    if (isInFujiTour) {
      window.editor?.controllers?.suspendHotkeys()
    } else {
      window.editor?.controllers?.restoreHotkeys()
    }
  }, [isInFujiTour])

  useEffect(() => {
    //have to manually set up StudioPlugin due to some timing issue
    //@TODO:  fix signal sequences for setAutoLoadPlugins in UX2
    if (window.syncPluginsWithSelectedSystem) {
      window.syncPluginsWithSelectedSystem()
    }

    // Hack Part 1/2: Tricky: countryIso2 gets lost from formState.values because this is only set when loading from the back-end
    // it is not actually part of the form itself, so it can get lost (e.g. when returning from MyE to Studio)
    // Can we handle this in a nicer way?
    const originalCountryIso2 = window.WorkspaceHelper.project?.country_iso2
    const originalCountryUrl = window.WorkspaceHelper.project?.country

    // Main code to update window.WorkspaceHelper.project
    // we don't actually need window.WorkspaceHelper.project any more
    // use form values through props instead of WorkspaceHelper
    // @TODO: Is this really the best place for this? Perhaps, because we can get the values we need
    // from formState but we should review...
    window.WorkspaceHelper.project = project
    window.WorkspaceHelper.project.id = projectId

    // Hack Part 2/2: If originalCountryIso2 has been lost and countryUrl still matches then manually re-apply it
    // This hack should not be necessary, try to refactor to avoid needing it.
    if (
      !window.WorkspaceHelper.project?.country_iso2 &&
      originalCountryIso2 &&
      window.WorkspaceHelper.project?.country === originalCountryUrl
    ) {
      window.WorkspaceHelper.project.country_iso2 = originalCountryIso2
    }
  }, [])

  useEffect(() => {
    if (isProjectRequireSystemCalcs) {
      //re-calc all systems
      window.editor.filter('type', 'OsSystem').forEach((system) => {
        window.Designer.requestSystemCalculations(system)
      })
    }
  }, [isProjectRequireSystemCalcs])

  useEffect(() => {
    // we only do this once when the Design page loads
    if (lyraEnabled) {
      window.editor.controllers?.FingerPaint?.storeGridPresets({
        moduleSpacing: [LYRA_DEFAULT_MODULE_SPACING, LYRA_DEFAULT_MODULE_SPACING],
      })
    }
    return () => window.editor.controllers?.FingerPaint?.clearGridPresets()
  }, [])

  // Prepare here and inject through props because deeper components are old-style components where hooks won't work
  const showTabs = useShowTabs()

  if (!allowView) {
    return <NotFound />
  }

  return (
    <SectionContainer section="design" mode={isLite ? 'studioLite' : 'studio'}>
      {isLite ? (
        <PageDesignerLite />
      ) : (
        <PageDesigner
          showTabs={showTabs}
          form={form}
          projectValues={formState.values}
          autoSaveEnabled={autoSaveEnabled}
          bottomPadding={isMobile && !uxVersion3}
        />
      )}

      <Field name="design" component="input" type="text" style={{ display: 'none' }} />

      <HardwareSelector sharedWith={project.shared_with || []} projectOwnerId={project.org_id} />

      <StudioContainer className={classes.fillContainer} zIndex={20} />
    </SectionContainer>
  )
}

export const Design: React.FC = () => {
  const [isLoading, setLoading] = useState(true)
  const notify = useNotify()
  const [validationError, setValidationError] = useState('')
  const formState = useForm().getState()
  const projectId = formState.values.id || getProjectIdSpecial()
  const orgId = useSelector((state: RootState) => (state.auth ? state.auth.org_id : null)) || formState.values.org_id
  const identifier = formState.values.identifier || null
  const location = useLocation()
  const enableDesignOnObliques = useFeatureFlag('studio_design_on_obliques', 'on')
  const enableStudioNext = useFeatureFlag('studio_next', 'on')

  useEffect(() => {
    if (!window.STUDIO_FEATURE_FLAGS) {
      window.STUDIO_FEATURE_FLAGS = {}
    }

    window.STUDIO_FEATURE_FLAGS.DESIGN_ON_OBLIQUES = enableDesignOnObliques
    window.STUDIO_FEATURE_FLAGS.STUDIO_NEXT = enableStudioNext
  }, [enableDesignOnObliques])

  useEffect(() => {
    const { org_id, project_id, datetime_for_url } = parseQueryStringToDictionary(location.search)

    if (org_id && project_id && datetime_for_url) {
      setTimeout(function () {
        window.ReplayHelper.downloadAndLoadReplay(org_id, project_id, datetime_for_url)
      }, 1000)
    } else if (!formState.values.lat || !formState.values.lon) {
      setValidationError(window.WorkspaceHelper?.messages.abort_missing_location_and_address)
    } else {
      setValidationError('')
    }
  }, [formState.values.lat, formState.values.lon])

  const reloadSpecs = useCallback(() => {
    const systems = window.editor.getSystems()
    systems.forEach((system) => {
      system.refreshDesignComponentSpecs()
    })
  }, [])

  useEffect(() => {
    //do not render studio until AccountHelper ready
    const loadStudioProfile = async () => {
      if (!window.AccountHelper.isLoaded()) {
        try {
          await window.WorkspaceHelper.loadStudioProfile({ id: projectId, org_id: orgId, identifier })
          reloadSpecs()
        } catch (error: any) {
          notify('Error during initialization: ' + error?.['message'], 'error', { error })
        }
      }
      if (isLoading) {
        setLoading(false)
      }
    }

    if (projectId === 'replay') {
      setLoading(false)
    } else {
      loadStudioProfile()
    }

    return () => {
      //help to turn off highlighted animation and deactivate controller like ModulePlacement
      window.editor.deselect()

      // Do not clear available imagery when leaving Design because we may want to retain it in other sections
      // (e.g. MyE) We will only clear it when
      // a) we attempt to load new imagery for a new location.
      // b) when we leave the project section completely.

      if (window.ReplayHelper.recordingInProgress) {
        window.ReplayHelper.stopRecording(true)
      }
    }
  }, [])

  if (isLoading) {
    return <FullScreenLoading />
  } else if (validationError) {
    return <DesignPageNotAvailable message={validationError} projectId={projectId} />
  }

  return <DesignPage />
}

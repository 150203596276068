import { Component, Item, MountingCalcInput, MountingCalcResult, RailComponent } from '../types'
import { addItems, createMountingItem } from '../utils'
import { PostProcessorAbstract } from './PostProcessorAbstract'

// ASSUMPTION: Target items only contain one component, and it has a length. (Possible to enforce with type system, but would be annoying)
// QUIRK: When consolidated into single items, the name is taken from the first component
// QUIRK: Target items are assumed to be consolidatable.
//   ---> So if you have a system with two different kinds of rail, you will have to use two CutAndSplicers

export abstract class CutAndSplicerAbstract extends PostProcessorAbstract {
  abstract isTarget(component: Component): boolean // If true, component must have length
  abstract chooseSplice(componentName: string, input: MountingCalcInput): Item[]
  abstract getFullLength(componentName: string): number

  targetComponents: RailComponent[] = []
  nonTargetItems: Item[] = []

  // We output a MountingCalc result because we may want to attach a rail cutting diagram in the future
  // If we improve this algorithm we may have such tight cutting schemes that it's important to show the installer
  // On that note, this problem is the Cutting Stock Problem https://en.wikipedia.org/wiki/Cutting_stock_problem
  process(result: MountingCalcResult): MountingCalcResult {
    this.separateTargetsAndNonTargets(result) // sets this.targetComponents and this.nonTargetItems

    if (!this.targetComponents.length) return result

    const splices: Item[] = []
    const fullLengths: RailComponent[] = []
    const shortLengths: RailComponent[] = []

    this.targetComponents.forEach((component) => {
      const fullComponentLength = this.getFullLength(component.name)
      const positionName = component.direction === 'horizontal' ? 'left' : 'top'
      let remainingLength = component.length
      let position = component[positionName]

      while (remainingLength > fullComponentLength) {
        fullLengths.push({
          ...component,
          [positionName]: position,
          length: fullComponentLength,
        })

        position += fullComponentLength
        remainingLength -= fullComponentLength

        const spliceProperties = {
          left: component.left,
          top: component.top,
          [positionName]: position, // overwrite left or top
          blockIndex: component.blockIndex,
        }
        splices.push(
          ...this.chooseSplice(component.name, this.input).map((splice) => createMountingItem(splice, spliceProperties))
        )
      }

      const lastComponent = { ...component, [positionName]: position, length: remainingLength }

      if (remainingLength === fullComponentLength) {
        fullLengths.push(lastComponent)
      } else {
        shortLengths.push(lastComponent)
      }
    })

    const consolidatedShortComponents = this.consolidateShortComponents(shortLengths)
    const fullLengthItems = fullLengths.map((component) => ({ name: component.name, components: [component] }))

    return addItems(removeItems(result), this.nonTargetItems, consolidatedShortComponents, fullLengthItems, splices)
  }

  // We make some effort to group short rail lengths onto whole rail lengths
  // This is by no means an optimal algorithm, and the results might ocassionally comically bad
  consolidateShortComponents(shortLengths: RailComponent[]): Item[] {
    type CutGroup = { lengthRemaining: number; components: RailComponent[] }

    const fullComponentLength = this.getFullLength(shortLengths[0].name)
    const cutGroups: CutGroup[] = [{ lengthRemaining: fullComponentLength, components: [] }]

    const bladeWidth = this.input?.options?.bladeWidth ?? 5 // mm. This may be too much, looks like blades are typically 2-3mm.

    shortLengths.forEach((uncutComponent) => {
      const { length } = uncutComponent

      const needANewGroup = !cutGroups.some((cutGroup) => {
        if (length < cutGroup.lengthRemaining) {
          cutGroup.components.push(uncutComponent)
          cutGroup.lengthRemaining -= length + bladeWidth
          return true
        }
        return false
      })

      if (needANewGroup) {
        cutGroups.push({
          lengthRemaining: fullComponentLength - length - bladeWidth,
          components: [uncutComponent],
        })
      }
    })

    return cutGroups.map(({ components }) => ({
      name: components[0].name,
      components,
    }))
  }

  separateTargetsAndNonTargets(result: MountingCalcResult) {
    this.targetComponents = []
    this.nonTargetItems = []

    result.items.forEach((item) => {
      if (this.isTarget(item.components[0])) this.targetComponents.push(item.components[0] as RailComponent)
      else this.nonTargetItems.push(item)
    })
  }
}

function removeItems(result: MountingCalcResult) {
  return {
    ...result,
    items: [],
  }
}

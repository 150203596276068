import CheckIcon from '@material-ui/icons/Check'
import { OpenSolarThemeType } from 'Themes'
import TooltipWithDisabledButton from 'persistentContent/elements/TooltipWithDisabledButton'
import { memo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  container: {
    margin: 10,
  },
}))

const InventoryFilterButton = ({
  selected,
  label,
  handleClick,
  disabled,
}: {
  selected: boolean
  label: string
  handleClick(): void
  disabled: boolean
}) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <TooltipWithDisabledButton
        label={label}
        clickFunc={handleClick}
        disabled={disabled}
        title={disabled ? 'Filters unavailable during search' : ''}
        color={selected ? 'primary' : 'default'}
        size="small"
        variant="contained"
        icon={selected ? <CheckIcon /> : undefined}
      />
    </div>
  )
}

export default memo(InventoryFilterButton)

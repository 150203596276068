import { startTransaction } from 'myenergy/elements/TransactionStartButton'
import { customerActionsData } from 'myenergy/selectionComponent'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

const IframeBespoke = ({ proposalDataRaw, bespokeProposalUrl, showDialog }) => {
  const [iframeRef, setIframeRef] = useState(null)
  // const iframeHeightRef = useRef()
  const dispatch = useDispatch()
  const [iframeHeight, setIframeHeight] = useState(1000)
  const [currentProposalDataFormat, setCurrentProposalDataFormat] = useState(null)

  // inject feature flags into proposal data so we can build bespoke proposal in preparation for features which have
  // not yet been released
  if (proposalDataRaw[0]) {
    proposalDataRaw[0].features = ['PLAY_VIDEO_1']
  }

  useEffect(() => {
    // Ensure the regular DesignComponent (Studio) does not show in the background while viewing the iframe
    window.setStudioMode('hidden')

    function iframeListener(message) {
      if (message.data?.type === 'startTransaction') {
        startTransaction(dispatch, message.data.transactionRequestData)
      } else if (message.data?.type === 'playVideo') {
        showDialog('Video', { videoUrl: message.data.url })
      } else if (message.data?.type === 'readyForData') {
        // iFrame has loaded, now inject the data

        setCurrentProposalDataFormat(proposalDataRaw[0].projects[0]?.simulate_first_year_only ? 'first' : 'full')

        iframeRef.contentWindow.postMessage(
          {
            type: 'renderOnlineProposalOnline',
            data: proposalDataRaw,
          },
          '*'
        )
      } else if (message.data?.height) {
        // Allow iframe height to continue updating after it is first set to account for responsive layouts
        setIframeHeight(message.data?.height + 100) //100px margin
      }
    }
    window.addEventListener('message', iframeListener)
    return () => window.removeEventListener('message', iframeListener)
  })

  useEffect(() => {
    // If first load was only first year, wait for full calcs then push data to iFrame again

    var newProposalDataFormat = proposalDataRaw[0].projects[0]?.simulate_first_year_only ? 'first' : 'full'

    if (currentProposalDataFormat === 'first' && newProposalDataFormat === 'full') {
      setCurrentProposalDataFormat(newProposalDataFormat)

      iframeRef.contentWindow.postMessage(
        {
          type: 'renderOnlineProposalOnline',
          data: proposalDataRaw,
        },
        '*'
      )
    }
  }, [proposalDataRaw[0].projects[0]?.simulate_first_year_only])

  const project = proposalDataRaw[0].projects[0]?.proposal_data?.project

  if (!project) {
    return null
  }

  const systems = proposalDataRaw[0].projects[0]?.proposal_data?.systems

  const projectId = project.id

  var actions = []

  systems.forEach((s) => {
    s.data.payment_options.forEach((po) => {
      actions.push({
        system_uuid: s.data.uuid,
        payment_option_id: po.id,

        // Currently we inject actions separately from system data, we could merge this in future
        customerAction: customerActionsData(
          projectId,
          s.data.uuid,
          po.id,
          proposalDataRaw[0].projects[0]?.available_customer_actions,
          {}, //proposalData.myeStyles,
          {}, //styles.general.acceptButton,
          {}, //styles.general.acceptButtonDisabled,
          po,
          s.data.pricing,
          {}, //quotationConfiguration,
          s.data.kw_stc,
          project.contacts,
          false, // showDialog,
          null, //setWaitingForAction,
          true, //isPro,
          (input) => input, // translate,
          s.data.additional_costs,
          po.title,
          project.docusign_contract_auth_failures,
          false, //proposalData?.org?.enable_contracts_in_docusign,
          po.contract_template,
          false, //hasAvailableDocusignAction,
          project, // proposalData.selectedProject,
          'actionsData'
        ),
      })
    })
  })

  // Temporarily inject into the project data to avoid passing a separate data item, refactor this later
  project.actions = actions

  return (
    <div>
      <iframe
        ref={setIframeRef}
        style={{
          border: 'none',
          width: '100%',
          height: iframeHeight,
          pointerEvents: 'all',
          opacity: currentProposalDataFormat === 'first' ? 0.2 : 1,
        }}
        id="BespokeHtml"
        scrolling="no"
        src={bespokeProposalUrl}
      ></iframe>
    </div>
  )
}

export default IframeBespoke

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Typography as Mui4Typography, makeStyles, } from '@material-ui/core';
import { default as Mui5Typography } from '@mui/material/Typography';
import { forwardRef } from 'react';
import { Inspector } from '../debug/Inspector';
import { useComponentVersion } from '../hooks';
// * MUI Typography API Spec  https://mui.com/material-ui/api/Typography/
export var Typography = function (props) { return (_jsx(Inspector, { component: OsTypography, name: "Typography", propDefs: TypographyPropsDefs, props: props, showExample: true })); };
export var TypographyVersion;
(function (TypographyVersion) {
    TypographyVersion[TypographyVersion["V1"] = 1] = "V1";
    TypographyVersion[TypographyVersion["V2"] = 2] = "V2";
})(TypographyVersion || (TypographyVersion = {}));
export var getTypographyStyles = function (_a) {
    var _b = _a.colorHex, colorHex = _b === void 0 ? '#212121' : _b, _c = _a.textVariant, textVariant = _c === void 0 ? 'body1' : _c;
    switch (textVariant) {
        // body
        case 'body1':
            return {
                color: colorHex,
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '22px',
            };
        case 'body1SemiBold':
            return {
                color: colorHex,
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '22px',
            };
        case 'body1Bold':
            return {
                color: colorHex,
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '22px',
            };
        case 'body2':
            return {
                color: colorHex,
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '22px',
            };
        // caption
        case 'caption1':
            return {
                color: colorHex,
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: '16px',
            };
        case 'caption2':
            return {
                color: colorHex,
                textAlign: 'center',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '16px',
            };
        // h
        case 'h3':
            return {
                color: colorHex,
                fontSize: '34px',
                fontWeight: 400,
                lineHeight: 'normal',
            };
        case 'h4':
            return {
                fontSize: '24px',
                fontWeight: 400,
                lineHeight: 'normal',
            };
        case 'h5':
            return {
                color: colorHex,
                fontSize: '20px',
                fontWeight: 400,
                lineHeight: '24px',
            };
        // subtitles
        case 'subtitle1':
            return {
                color: colorHex,
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '20px',
            };
        case 'subtitle2':
            return {
                color: colorHex,
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: 'normal',
            };
        // helper
        case 'helper1':
            return {
                color: colorHex,
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '16px',
            };
        default:
            return {};
    }
};
var useMui4Styles = makeStyles(function () { return ({
    root: function (_a) {
        var colorHex = _a.colorHex, textVariant = _a.textVariant;
        return (__assign({}, getTypographyStyles({ colorHex: colorHex, textVariant: textVariant })));
    },
}); });
var OsTypography = forwardRef(function (props, ref) {
    var version = useComponentVersion('typography');
    var styleProps = {
        colorHex: props.colorHex,
        textVariant: props.textVariant
    };
    var v4Styles = useMui4Styles(styleProps);
    switch (version) {
        case TypographyVersion.V2:
            return (_jsx(Mui5Typography, __assign({ align: props.align, ref: ref, sx: __assign({}, getTypographyStyles(styleProps)) }, { children: props.children })));
        default:
            return (_jsx(Mui4Typography, __assign({ align: props.align, classes: { root: v4Styles.root } }, { children: props.children })));
    }
});
var TypographyPropsDefs = {
    textVariant: { default: 'body1' },
};

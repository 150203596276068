import { logAmplitudeEvent } from 'amplitude/amplitude'
import Button from 'elements/button/Button'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

const OrderByProjectButton = () => {
  const history = useHistory()

  const handleClick = useCallback(() => {
    history.push('/shop/order_by_project')
    logAmplitudeEvent('generic_button_clicked', {
      source: 'hardware_banner',
      context: 'order_by_project',
    })
  }, [])

  return (
    <Button variant="contained" color="primary" label={'Order by Project'} disabled={false} onClick={handleClick} />
  )
}

export default OrderByProjectButton

import Grid from '@material-ui/core/Grid'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import { permissionsSelectors } from 'ducks/permissions'
import React from 'react'
import { ReferenceField, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import AccordionCard from '../AccordionCard'
import SystemSummaryRow from './SystemSummaryRow'
import { SystemPropInterface } from './types'

const SystemSummaryContent: React.FunctionComponent = () => {
  const translate = useTranslate()
  const form = useForm()
  const formState = form.getState()
  const calcsReadyFromProjectData = Boolean(!formState.values.simulate_first_year_only)
  var systems: SystemPropInterface[] = [] //formState.values.systems

  // If design loaded in editor, use it, which will be most up-to-date and will include data which has not yet been saved
  // otherwise if full calcs have returned, use data loaded from database (which may not include all systems)
  // if full calcs have not returned AND design is not loaded in editor, show "refresh" instruction (which we can replace in future
  // when we automatically-detect this scenario and poll/reload systems when finished)
  //
  // Note that formState.values.design never actually holds the full design data, so we can only use it
  // from window.editor if already loaded
  if (window.editor && window.editor.getSystems()) {
    window.editor.getSystems().forEach((osSystem) => {
      // @OTOD: Deal with translations here
      var systemName = osSystem.getName()

      systems.push({
        id: null,
        url: null,
        name: systemName,
        uuid: osSystem.uuid,
        show_customer: osSystem.show_customer,
        discount: osSystem?.pricing?.discount || 0,
        kw_stc: osSystem.kwStc() || 0,
        battery_total_kwh: osSystem.batteryTotalKwh() || 0,
        batteries: osSystem.batteries().map((osBattery) => ({
          battery_id: osBattery.battery_id,
        })),
        price_including_tax: osSystem.pricing?.system_price_including_tax || 0,
        output_annual_kwh: osSystem.output?.annual ? Math.round(osSystem?.output?.annual) : 0,
        consumption_offset_percentage: osSystem.consumption?.consumption_offset_percentage
          ? Math.round(osSystem.consumption?.consumption_offset_percentage)
          : 0,
      })
    })
  } else if (!calcsReadyFromProjectData) {
    // Cannot pull system details from window.editor and full calcs were not ready when project was loaded from API
    // This will almost never happen beacuse editor is almost always initialized when viewing info page.
    // One rare case would be when systems were just deleted in studio and saved before calcs return.
    // Another rare case could be when initially loading the page if editor is still initializing for some reason (or if it failed)
    return <div>System calculations in progress. Refresh to view...</div>
  } else {
    // Only use systems data from database if calcs are not pending.
    systems = formState.values.systems
  }

  if (!systems || systems.length === 0) {
    return (
      <div>
        <p className="notAvailable">{translate('This project does not have any designed systems')}</p>
      </div>
    )
  } else
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {systems.map((system, i: number) => (
            <ReferenceField
              getResourceLinkPath={() => false}
              reference="projects"
              //@ts-ignore
              record={formState.values}
              source="id"
              key={i}
              renderChildrenBeforeRecord={true}
            >
              <SystemSummaryRow system={system} index={i} />
            </ReferenceField>
          ))}
        </Grid>
      </Grid>
    )
}

const SystemSummaryAccordion: React.FunctionComponent = () => {
  const translate = useTranslate()
  const { allowView, allowCreate, allowEdit, allowDelete } = useSelector(
    permissionsSelectors.getProjectPermissionByKey('info_system_summary_section_pricing')
  )
  if (!allowView) {
    return null
  }
  return (
    <AccordionCard
      name={'system_summary'}
      title={translate('System Summary')}
      titleIcon={AssignmentOutlinedIcon}
      content={SystemSummaryContent}
      disabled={!allowEdit}
      lazyRender={true}
    />
  )
}
export default SystemSummaryAccordion

import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { OpenSolarTheme } from 'Themes'

const useStyles = makeStyles((theme: Theme) => ({
  descriptionWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '16px',
    marginTop: '12px',
    flexDirection: 'column',
    lineHeight: '20px',
  },
  description: {
    fontSize: '14px',
    fontWeight: 400,
    color: 'rgba(79, 77, 85, 1)',
  },
  boldDescription: {
    fontSize: '14px',
    fontWeight: 550,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginBottom: '8px',
    color: 'rgba(79, 77, 85, 1)',
  },
  buttonWrapper: {
    marginTop: '15px',
  },
  titleText: {
    fontWeight: 700,
    fontSize: '14px',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tagClass: {
    padding: '4px 12px',
    borderRadius: '5px',
    textAlign: 'center',
    height: '20px',
    paddingLeft: '8px',
    paddingRight: '8px',
    fontSize: '12px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
  },
  modalContainer: {
    borderRadius: '8px',
  },
  modalHeader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalHeaderNoBorder: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalHeaderText: {
    fontWeight: 700,
    fontSize: '18px',
  },
  modalCloseButton: {
    border: 'none',
    background: 'none',
    fontSize: '30px',
    cursor: 'pointer',
  },
  customDialogTitle: {
    minWidth: '500px',
    padding: 0,
  },
  modalHeading: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
  },
  formFont: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
  },
  modalFooter: {
    padding: '20px',
    display: 'flex',
    borderTop: '1px solid rgba(230, 230, 230, 1)',
    justifyContent: 'space-between',
    width: '100%',
  },
  modalFooterButtonsRight: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  modalFooterButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '12px',
    marginLeft: 'auto',
  },
  formContentOrderScaffold: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  formContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    margin: '40px 0',
  },
  radioGroupStyle: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 'auto',
  },
  // dropdown
  customFormControl: {
    width: '100%',
    border: '1px solid #ced4da',
    borderRadius: '10px',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInput-underline': {
      content: '',
      borderBottom: 'none',
    },
    height: '40px',
    boxShadow: '0px 1.5px 4px -1px rgba(10, 9, 11, 0.07)',
  },

  //bottom info container
  infoContainer: {
    padding: '20px',
    marginTop: '25px',
    borderRadius: '8px',
    border: '1px solid rgba(171, 188, 238, 1)',
    backgroundColor: 'rgba(243, 246, 253, 1)',
  },
  internalInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
  },
  infoContainerTitle: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '22px',
  },
  infoContainerSubTitle: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#4F4D55',
  },
  fieldText: {
    fontSize: '14px',
    fontWeight: 550,
    lineHeight: '20px',
    color: '#2D2B32',
  },
  divider: {
    height: '1px',
    borderTop: '1px solid #E6E6E6',
    margin: '16px 0',
  },
  internalDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  costText: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  textInput: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      boxShadow: '0px 1.5px 4px -1px rgba(10, 9, 11, 0.07)',
    },
  },
  stepper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    gap: 12,
  },
  stepperProgressBar: {
    height: 4,
    borderRadius: 2,
    backgroundColor: '#ECECED',
  },
  stepperProgressBarFilled: {
    backgroundColor: '#2C499D',
  },
  stepperTextFilled: {
    fontWeight: 550,
    color: '#0A090B',
  },
  descriptionTC: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
    color: '#4F4D55',
  },
  titleTC: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '22px',
    color: '#1D1C20',
  },
  acceptanceText: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
    color: '#1D1C20',
  },
  learnMoreText: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '22px',
    color: '#1751D0',
  },
  enableModalContainer: {
    width: '590px',
  },
  enableModalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    width: '590px',
  },
  enableTextColor: {
    color: '#0A090B',
  },
  descriptionStyle: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  iconContainer: {
    padding: 12,
    backgroundColor: '#F8F8F8',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto',
  },
  alignCenter: {
    margin: '0 auto',
  },
  enableDescription: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    color: '#4F4D55',
    marginTop: 4,
    textAlign: 'center',
  },
  tooltipContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    width: '252px',
  },
  tooltipArrow: {
    '&::before': {
      backgroundColor: '#0A090B',
      color: '#0A090B',
    },
  },
  tooltip: {
    backgroundColor: '#0A090B',
    color: '#FFF',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
  },
  fieldStyle: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  imageUpload: {
    width: 275,
    marginLeft: 'auto',
    marginRight: 'auto',
    '& img': {
      maxHeight: 50,
      maxWidth: 200,
    },
    '& .MuiFormHelperText-root': {
      marginTop: 0,
      height: 0,
    },
    '& .MuiFormControl-marginDense': {
      marginBottom: 0,
    },
  },
  centerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paymentContainer: {
    border: '1px solid rgba(230, 230, 230, 1)',
    borderRadius: '4px',
  },
  paymentOptionText: {
    fontSize: '18px',
    fontWeight: 475,
    lineHeight: '24px',
  },
  paymentOption: {
    padding: '32px 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  paymentNoteSection: {
    padding: '32px 24px',
    backgroundColor: '#F9F9F9',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
    color: '#4F4D55',
  },
  borderTop: {
    borderTop: '1px solid rgba(230, 230, 230, 1)',
  },
  footerButtons: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    marginLeft: 'auto',
  },
  fullWidth: {
    width: '100%',
  },
  errorText: {
    color: theme.palette.error.main,
  },
  marginBottom24: {
    marginBottom: 24,
  },
  recalculateBtnStyle: {
    border: `1px solid ${OpenSolarTheme.greyMid2}`,
    backgroundColor: OpenSolarTheme.white,
    borderRadius: 8,
    fontSize: 14,
    fontWeight: 500,
  },
  costCalculateBtnStyle: {
    backgroundColor: OpenSolarTheme.openSolarYellow,
    borderRadius: '8px',
    border: 'none',
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 1)',
    boxShadow: OpenSolarTheme.boxShadow.default,
  },
  cancelBtnStyle: {
    backgroundColor: OpenSolarTheme.white,
    borderRadius: '8px',
    fontSize: '14px',
    color: 'rgba(225, 33, 33, 1)',
    border: `1px solid ${OpenSolarTheme.greyMid2}`,
  },
  checkboxContainer: {
    display: 'flex',
    marginBottom: 32,
  },
  gap24: {
    gap: 24,
  },
  gap16: {
    gap: 16,
  },
  gap8: {
    gap: 8,
  },
  gap6: {
    gap: 6,
  },
  displayFlex: {
    display: 'flex',
  },
  marginTop24: {
    marginTop: 24,
  },
  marginTop40: {
    marginTop: 40,
  },
  marginTop16: {
    marginTop: 16,
  },
  marginBottom5: {
    marginBottom: 5,
  },
  marginBottom0: {
    marginBottom: 0,
  },
  margin0: {
    margin: 0,
  },
  formContainer: {
    display: 'flex',
    gap: '24px',
    marginBottom: '10px',
  },
  selectorColor: {
    color: OpenSolarTheme.ValidityPalette.default.color,
  },
  tooltipImageStyle: {
    height: 232,
    width: 'auto',
  },
  tooltipIconSize: {
    height: 14,
    width: 14,
  },
  iconScaffold: {
    height: 20,
    width: 20,
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  gotoDesign: {
    fontWeight: 600,
    cursor: 'pointer',
  },
  notNowBtnStyle: {
    backgroundColor: '#ECECED',
    borderRadius: '8px',
    borderColor: '#ECECED',
    fontSize: '14px',
    color: '#4F4D55',
  },
  enableBtnStyle: {
    backgroundColor: OpenSolarTheme.openSolarYellow,
    borderRadius: '8px',
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 1)',
    whiteSpace: 'nowrap',
  },
  saveExitBtnStyle: {
    backgroundColor: OpenSolarTheme.white,
    borderRadius: '8px',
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 1)',
    border: `2px solid ${OpenSolarTheme.greyMid2}`,
  },
  confirmBtnStyle: {
    borderRadius: '8px',
    fontSize: '14px',
    marginBottom: '4px',
    color: OpenSolarTheme.blue,
    border: `1px solid ${OpenSolarTheme.greyMid2}`,
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
}))

export default useStyles

import { Dialog, makeStyles, Tooltip } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import { IconButton } from 'opensolar-ui'
import React, { FC } from 'react'
import { useTranslate } from 'react-admin'
import Skeleton from 'react-loading-skeleton'

const useStyles = makeStyles({
  paper: {
    width: '80%',
    maxWidth: 900,
  },
  content: { padding: 20 },
  closeButton: {
    top: 10,
    right: 10,
    position: 'absolute',
    minWidth: 40,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
})

type PropsType = {
  closeDialog: Function
}

const LoadingDialog: FC<PropsType> = ({ closeDialog }) => {
  const translate = useTranslate()
  const classes = useStyles()
  return (
    <div>
      <Dialog id="loading-dialog" open={true} classes={classes}>
        <div className={classes.content}>
          <IconButton classes={{ root: classes.closeButton }} onClick={() => closeDialog()}>
            <Tooltip title={translate('Close Dialog')} enterDelay={300}>
              <CloseIcon style={{ color: '#000000' }} />
            </Tooltip>
          </IconButton>
          <div style={{ height: 40, width: 200, margin: '10px 0' }}>
            <Skeleton height={'40px'} />
          </div>
          <Skeleton height={'300px'} />
          <div style={{ height: 40, width: '100%', margin: '10px 0' }}>
            <Skeleton height={'40px'} />
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default LoadingDialog

import { EventType, InteractionStatus } from '@azure/msal-browser'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orderSelectors } from 'ducks/orderComponents'
import CreateEmptyOrderButton from 'layout/widgets/CreateEmptyOrderButton'
import { Button } from 'opensolar-ui'
import useTranslateWithVariable from 'projectSections/hooks/useTranslateWithVariable'
import { useNotify } from 'ra-core'
import { Confirm } from 'ra-ui-materialui'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { setAuthRedirection, storeAuthRedirectionToLocalStorage } from 'redirections/authRedirectionStore'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { useFeatureFlag } from 'util/split'
import useSegenService from './useSegenServiceV2'

const useStyles = makeOpenSolarStyles((theme) => ({
  button: { margin: 5 },
}))

type SegenConnectUserButtonProps = {
  layout: number | null
  allowOrdering: boolean
  variant: 'contained' | 'outlined' | 'text'
  trackSource: string
  label?: string
  icon?: React.ReactNode
}

export const SegenConnectUserButton: React.FC<SegenConnectUserButtonProps> = ({
  layout,
  allowOrdering,
  variant,
  trackSource,
  label = 'Connect Segen...',
  icon,
}) => {
  const translate = useTranslateWithVariable()
  const notify = useNotify()
  const classes = useStyles()
  const [brokenAccount, setBrokenAccount] = useState(false)
  const { instance, inProgress, accounts } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const segenService = useSegenService()
  const useRedirect = !!useFeatureFlag('segen_auth_redirect', 'on')
  const enableHardwareOrdering = useFeatureFlag('hardware_ordering', 'on')
  const [confirmingConnect, setConfirmingConnect] = useState(false)
  const isEmptyOrder = useSelector(orderSelectors.getIsEmptyOrder)
  const lineItems = useSelector(orderSelectors.getOrderLineItems)

  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        notify(
          'Something went wrong while connecting to Segen. Please check your username and password are correct.',
          'warning'
        )
      }
    })
    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId)
      }
    }
  }, [instance])

  const onClickConnect = () => {
    logAmplitudeEvent('hardware_distributor_connect_button_clicked', { source: trackSource, distributor: 'segen' })
    if (useRedirect) {
      setConfirmingConnect(true)
    } else {
      segenService.connect({ enableRedirect: useRedirect, source: 'SegenConnectUserButton' })
    }
  }

  const onClickDisconnect = () => {
    setAuthRedirection({ type: 'AUTH_SUCCESS_REDIRECT', redirect: '/shop' })
    storeAuthRedirectionToLocalStorage()
    setBrokenAccount(false)
    logAmplitudeEvent('disconnect_segen_clicked', { source: trackSource })
    segenService.disconnect()
  }

  let currentControl
  if (isAuthenticated && !brokenAccount) {
    // Is Authenticated
    if (allowOrdering && isEmptyOrder && !enableHardwareOrdering) {
      // Is able to create orders but doesn't have an order
      currentControl = (
        <span style={{ display: 'inline-block' }}>
          <CreateEmptyOrderButton layout={layout} enableHardwareOrdering={enableHardwareOrdering} />
        </span>
      )
    } else if (!allowOrdering) {
      // Is not able to create orders, show disconect
      currentControl = (
        <Button
          onClick={onClickDisconnect}
          variant={variant}
          color="default"
          disabled={false}
          className={classes.button}
        >
          {translate('Disconnect Segen')}
        </Button>
      )
    }
  } else if (inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect) {
    // inProgress check prevents sign-in button from being displayed briefly after returning from a redirect sign-in. Processing the server response takes a render cycle or two
    currentControl = (
      <Button
        onClick={onClickConnect}
        variant={variant}
        color="default"
        size={allowOrdering ? 'small' : undefined}
        disabled={false}
        className={classes.button}
      >
        {icon && icon}
        {label}
      </Button>
    )
  } else {
    // Connecting
    currentControl = <span className="small">Connecting to Segen...</span>
  }

  return (
    <>
      {currentControl}
      <Confirm
        isOpen={confirmingConnect}
        title="Connect to Segen"
        content="You will be redirected to the Segen login page to connect your accounts, please confirm to proceed. Any unsaved changes will be discarded."
        confirmColor="warning"
        onConfirm={() => segenService.connect({ enableRedirect: useRedirect, source: 'SegenConnectUserButton' })}
        onClose={() => setConfirmingConnect(false)}
      />
    </>
  )
}

export default SegenConnectUserButton

import { Tooltip } from '@material-ui/core'
import { ArrowBackOutlined, ArrowForwardOutlined, CalendarTodayOutlined, LockOutlined } from '@material-ui/icons'
import { orgSelectors } from 'ducks/orgs'
import { useTranslate } from 'ra-core'
import { Button } from 'ra-ui-materialui'
import React, { useMemo } from 'react'
import { SelectInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { ProjectWorkflowBrief } from 'types/projects'
import { WorkflowType } from 'types/workflows'
import { useDateFormatted } from 'util/date'
import { handleImplicitToggles, isPricingLockedForStage, urlToId } from 'util/misc'
import { workflowStyles } from '../styles'
import ActionsAccordion from './ActionsAccordion'
import ErrorDialog from './ErrorDialog'
import StageStepper from './StageStepper'
import { useProjectEntityPermissions } from './hooks'

interface PropTypes {
  selectedWorkflow?: ProjectWorkflowBrief
  workflows: WorkflowType[]
  handleChange: (workflow) => void
  source?: string
  orgDisplay?: string
  isExternalWorkflow?: boolean
}
const ProjectWorkflow: React.FC<PropTypes> = (props) => {
  const { selectedWorkflow, handleChange, workflows, isExternalWorkflow } = props
  const form = useForm()
  const classes = workflowStyles()
  const translate = useTranslate()
  const project = useFormState().values
  const initialValues = form.getState().initialValues
  const currOrgUrl = useSelector(orgSelectors.getOrgUrl)
  const workflowObj = useMemo(() => workflows?.find((x) => x.id === selectedWorkflow?.workflow_id), [
    workflows,
    selectedWorkflow,
  ])
  const stages = useMemo(() => workflowObj?.workflow_stages, [workflowObj])
  const formattedDate = useDateFormatted(project.created_date, { long: true })
  const { allowView, allowEdit } = useProjectEntityPermissions(
    workflowObj?.org ? urlToId(workflowObj?.org) : undefined,
    'manage_workflow_stages'
  )
  const priceLock = useMemo(() => isPricingLockedForStage(project, workflows), [selectedWorkflow, workflows])
  const statusWarningNotifs = useMemo(() => {
    let notifs: string[] = []
    const findWorkflow = workflows?.find((x) => x.id === selectedWorkflow?.workflow_id)
    if (findWorkflow && findWorkflow.org === project.org) {
      const findStage = findWorkflow?.workflow_stages?.find((x) => x.id === selectedWorkflow?.active_stage_id)
      if (findStage && findStage.milestone < 2 && project.project_sold !== null) notifs.push('sold_warning')
      if (findStage && findStage.milestone < 3 && project.project_installed !== null) notifs.push('installed_warning')
      if (initialValues.project_sold === null && project.project_sold === 1) {
        notifs.push('sold_info')
      }
      if (initialValues.project_installed === null && project.project_installed === 1) {
        notifs.push('installed_info')
      }
      if (initialValues.project_sold !== null && project.project_sold === null) {
        notifs.push('unsold_info')
      }
      if (initialValues.project_installed !== null && project.project_installed === null) {
        notifs.push('uninstalled_info')
      }
    }
    return notifs
  }, [project.project_sold, project.project_installed, project.workflow])

  const onWorkflowChange = (e) => {
    const newId = e.target.value
    //check if new workflow has already been assigned to the project
    const projectWorkflow = project?.workflows?.find((x) => x.workflow_id === newId)
    const workflow_stages = workflows.find((x) => x.id === newId)?.workflow_stages
    if (projectWorkflow) {
      handleImplicitToggles(
        {
          workflow_stages: workflow_stages,
          active_stage_id: projectWorkflow.active_stage_id,
          org_id: projectWorkflow.org_id,
        },
        project,
        form
      )
      handleChange({
        workflow_id: projectWorkflow.workflow_id,
        active_stage_id: projectWorkflow.active_stage_id,
      })
    } else {
      const findWorkflow = workflows.find((x) => x.id === newId)
      if (findWorkflow) {
        const active_stage_id = !!findWorkflow.workflow_stages.length ? findWorkflow.workflow_stages[0].id : undefined
        handleImplicitToggles(
          {
            ...findWorkflow,
            active_stage_id: active_stage_id,
          },
          project,
          form
        )
        handleChange({
          workflow_id: findWorkflow.id,
          active_stage_id: active_stage_id,
        })
      }
    }
  }

  const onStageChange = (workflow) => {
    const findWorkflow = workflows?.find((x) => x.id === workflow.workflow_id)
    if (findWorkflow)
      handleImplicitToggles({ ...findWorkflow, active_stage_id: workflow.active_stage_id }, project, form)
    handleChange(workflow)
  }
  const workflowChoices = useMemo(
    () =>
      props.orgDisplay && currOrgUrl ? workflows.filter((x) => x.share_with_orgs?.includes(currOrgUrl)) : workflows,
    [workflows, props.orgDisplay, currOrgUrl]
  )
  const workflowOrgId = useMemo(() => {
    if (workflowObj) return urlToId(workflowObj.org)
  }, [workflowObj])

  const activeStageIndex: number | undefined = useMemo(() => {
    if (stages) {
      const stageItem = stages?.find((x) => x.id === selectedWorkflow?.active_stage_id)
      if (stageItem) return stages.indexOf(stageItem)
      return !!stages.length && typeof stages[0].id === 'number' ? stages[0].id : undefined
    }
  }, [stages, selectedWorkflow])
  const prevStep = useMemo(() => {
    if (activeStageIndex !== undefined && activeStageIndex > 0 && stages) {
      const prevSteps = stages.filter((x, i) => i < activeStageIndex && !x.is_archived).reverse()
      if (!!prevSteps.length) return prevSteps[0]
    }
  }, [stages, activeStageIndex])

  const nextStep = useMemo(() => {
    if (activeStageIndex !== undefined && stages && activeStageIndex !== stages.length - 1) {
      const nextSteps = stages.filter((x, i) => i > activeStageIndex && !x.is_archived && x.milestone < 4)
      if (!!nextSteps.length) return nextSteps[0]
    }
  }, [stages, activeStageIndex])

  if ((!workflows || !!!workflows.length) && !isExternalWorkflow) {
    return <ErrorDialog />
  }
  return (
    <div className={classes.column}>
      {allowView && (
        <>
          <div className={classes.row}>
            <div className={`${classes.row} ${classes.selectDrop}`}>
              <span>{!isExternalWorkflow ? translate('My Project Workflow: ') : translate('Project Workflow: ')}</span>
              {!!workflowChoices.length ? (
                <SelectInput
                  translateChoice={false}
                  source={props.source || 'workflow.workflow_id'}
                  label={false}
                  disabled={!allowEdit}
                  choices={workflowChoices}
                  defaultValue={selectedWorkflow?.workflow_id}
                  optionText={'title'}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  onChange={onWorkflowChange}
                />
              ) : (
                translate('Unknown')
              )}
            </div>
            {props.orgDisplay && (
              <p className={classes.orgDisplay}>
                <span>{translate('Team:')}</span> <strong>{props.orgDisplay}</strong>
              </p>
            )}
          </div>
          <p className={`${classes.projectDate} ${classes.row}`}>
            {!isExternalWorkflow ? (
              <>
                <CalendarTodayOutlined />
                {translate('Project Created: ')} {formattedDate}
              </>
            ) : (
              <></>
            )}

            {priceLock && (
              <div className={classes.lockPricing}>
                <Tooltip
                  title={translate(
                    'Based on the Stage of the project, the pricing, incentives and payment options are locked on this project.'
                  )}
                  PopperProps={{ style: { zIndex: 2001 } }}
                >
                  <LockOutlined style={{ color: 'red' }} />
                </Tooltip>
                {translate('Pricing is locked')}
              </div>
            )}
          </p>

          {selectedWorkflow && (
            <StageStepper
              allowEdit={allowEdit}
              workflow={selectedWorkflow}
              handleChange={!allowEdit ? undefined : onStageChange}
              workflows={workflows}
              statusNotifs={statusWarningNotifs}
            />
          )}
        </>
      )}
      {activeStageIndex !== undefined && (
        <>
          {stages && stages[activeStageIndex] && (
            <ActionsAccordion actions={stages[activeStageIndex]?.actions} workflowOrgId={workflowOrgId} />
          )}
          {allowView && (
            <div className={classes.toggleWrapper}>
              {prevStep && (
                <Button
                  disabled={!allowEdit}
                  onClick={() => onStageChange({ ...selectedWorkflow, active_stage_id: prevStep.id })}
                  startIcon={<ArrowBackOutlined />}
                  label={translate('Move to %{step_title}', { step_title: prevStep.title })}
                  variant="contained"
                  color="default"
                />
              )}
              {nextStep && (
                <Button
                  className="nextBtn"
                  disabled={!allowEdit}
                  onClick={() => onStageChange({ ...selectedWorkflow, active_stage_id: nextStep.id })}
                  endIcon={<ArrowForwardOutlined />}
                  label={translate('Move to %{step_title}', { step_title: nextStep.title })}
                  variant="contained"
                  color="default"
                />
              )}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default ProjectWorkflow

// @ts-nocheck
import React from 'react'
import { Create } from 'react-admin'
import CreateTransactionDialogFormContent from './CreateTransactionDialogFormContent'

type PropTypes = {
  toolbar: any
  hideCancelButton: boolean
  redirect?: string
  projectUrl: string
}

const CreateTransactionDialogFormWrapper: React.FunctionComponent<PropTypes> = (props) => {
  return (
    <Create {...props} hideTitle={true} record={{ project: props.projectUrl }}>
      <CreateTransactionDialogFormContent {...props} />
    </Create>
  )
}

export default CreateTransactionDialogFormWrapper

import { Chip } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    padding: '0px 5px',
  },
}))

type PropTypes = {
  type: 'residential' | 'commercial'
}

const FinanceTypeChip: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()

  const label = useMemo(() => {
    if (props.type === 'commercial') return translate('Commercial')
    else if (props.type === 'residential') return translate('Residential')
  }, [props.type])

  if (!label) return null
  return (
    <div className={classes.wrapper}>
      <Chip label={label} />
    </div>
  )
}
export default FinanceTypeChip

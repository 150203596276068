import { Grid, makeStyles, useMediaQuery } from '@material-ui/core'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import DuplicateIcon from '@material-ui/icons/FileCopyOutlined'
import CustomField from 'elements/field/CustomField'
import CheckboxInput from 'elements/input/CheckboxInput'
import ScheduleDialog from 'elements/scheduleDialog/ScheduleDialog'
import { ScheduleType } from 'elements/scheduleDialog/types'
import { defaultSchedule } from 'elements/scheduleDialog/utils'
import _ from 'lodash'
import { Button, IconButton } from 'opensolar-ui'
import React, { FC, useState } from 'react'
import { NumberInput, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import Schedule from './Schedule'

const useStyles2 = makeStyles((theme) => ({
  iconButton: {
    color: 'rgb(77, 77, 77)',
    '&:hover': {
      background: 'rgba(77, 77, 77,0.2)',
    },
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const Actions: FC<{ index: number; disableDelete: boolean }> = ({ index, disableDelete }) => {
  const form = useForm()
  const formState = useFormState()
  const classes = useStyles2()
  const handleDelete = () => {
    const { strategies } = form.getState().values
    const newStrategies = [...strategies]
    newStrategies.splice(index, 1)
    form.change('strategies', newStrategies)
  }

  const handleDuplicate = () => {
    const { strategies } = form.getState().values
    const newStrategy = _.cloneDeep(strategies[index])
    const newStrategies = [...strategies]
    newStrategies.push(newStrategy)
    form.change('strategies', newStrategies)
  }
  return (
    <div className={classes.iconContainer}>
      <IconButton size="small" classes={{ root: classes.iconButton }} onClick={handleDuplicate}>
        <DuplicateIcon />
      </IconButton>
      <IconButton size="small" classes={{ root: classes.iconButton }} disabled={disableDelete} onClick={handleDelete}>
        <DeleteOutlinedIcon />
      </IconButton>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 10,
  },
  heading: {
    background: '#F8F8F8',
    borderTop: '2px solid #e7e7e7',
    display: 'flex',
    alignItems: 'center',
    fontWeight: theme.typography.fontWeightMedium,
  },
  row: {
    borderTop: '2px solid #e7e7e7',
    borderBottom: '2px solid #e7e7e7',
    padding: '10px 5px',
    display: 'flex',
    alignItems: 'center',
  },
  tableCell: {
    display: 'flex',
    alignItems: 'center',
  },
  firstCol: {
    width: 80,
    padding: '16px 5px',
  },
  label: {
    marginTop: 40,
    fontWeight: 'bolder',
  },
  twoCols: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    [theme.breakpoints.down('xl')]: {
      justifyContent: 'flex-start',
    },
    '& span': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  col2: {
    [theme.breakpoints.up('xl')]: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
  checkboxWrapper: {
    [theme.breakpoints.up('xl')]: {
      height: '100%',
    },
  },
  checkboxWithError: {
    padding: 5,
    color: 'red',
    '&.Mui-checked': {
      color: 'red',
    },
  },
  checkbox: {
    padding: 5,
    marginRight: 5,
  },
}))

const resource = 'battery_schemes'
type StrategyType = {
  can_charge_from_solar: boolean
  can_discharge_to_load: boolean
  can_charge_from_grid: boolean
  can_discharge_to_grid: boolean
  min_state_of_charge_percent: number | null
  max_charge_rate_kw: number | null
  schedule: ScheduleType
}

export const defaultStrategy = {
  can_charge_from_solar: false,
  can_discharge_to_load: false,
  can_charge_from_grid: false,
  can_discharge_to_grid: false,
  min_state_of_charge_percent: null,
  max_charge_rate_kw: null,
  schedule: defaultSchedule(),
}

const detectConflictBatterySchedule = (strategies: StrategyType[]) => {
  //To Do: show error message to conflict schedule
}

const StrategyTableLarge = () => {
  const classes = useStyles()
  const form = useForm()
  const translate = useTranslate()
  const formState = useFormState()
  const displayTableHeader = useMediaQuery((theme: any) => theme.breakpoints.up('xl'))
  const formValues = formState.values
  const errorMessages = formState.errors
  const handleAddStrategy = () => {
    const values = form.getState().values
    const newStrategies = [...values.strategies, { ...defaultStrategy, schedule: defaultSchedule() }]
    form.change('strategies', newStrategies)
  }
  const [editingSchedule, setEditingSchedule] = useState<undefined | number>()
  return (
    <div>
      <div className={classes.container}>
        {displayTableHeader && (
          <div className={classes.heading}>
            <div className={classes.firstCol}>{translate('Strategy')}</div>
            <Grid container spacing={1} justify={'space-around'}>
              <Grid item xl={3} style={{ display: 'flex' }}>
                <div style={{ width: '50%' }}>
                  <div>{translate('Allow Charge From')}:</div>
                  <div className={classes.twoCols}>
                    <span style={{ width: '50%' }}>{translate('System')}</span>
                    <span style={{ width: '50%' }}>{translate('Grid')}</span>
                  </div>
                </div>
                <div style={{ width: '50%' }}>
                  <div>{translate('Allow Discharge To')}:</div>
                  <div className={classes.twoCols}>
                    <span style={{ width: '50%' }}>{translate('Load')}</span>
                    <span style={{ width: '50%' }}>{translate('Grid')}</span>
                  </div>
                </div>
              </Grid>
              <Grid item xl={2}>
                {translate('Minimum State of Charge(%)')}
              </Grid>
              <Grid item xl={2}>
                {translate('Maximum Charge/ Discharge Rate (kW)')}
              </Grid>
              <Grid item xl={4}>
                {translate('Applicable Time-Period')}
                <div className={classes.twoCols}>
                  <div style={{ width: '60%' }} className={classes.tableCell}>
                    <span style={{ width: '60%' }}>{translate('Hours')}</span>
                    <span>{translate('Weekdays')}</span>
                  </div>
                  <span>{translate('Date Range')}</span>
                </div>
              </Grid>
              <Grid item xl={1}>
                {translate('Actions')}
              </Grid>
            </Grid>
          </div>
        )}
        <div>
          {formValues.strategies?.map((strategy: any, index: number) => {
            const batteryLogicError = errorMessages && errorMessages[`strategies.${index}`]
            return (
              <div className={classes.row} key={strategy.title}>
                <div className={classes.firstCol}>
                  {translate('Strategy')} {index + 1}
                </div>
                <Grid container spacing={1} justify={'space-around'}>
                  <Grid item xs={12} xl={3} className={classes.col2}>
                    {!displayTableHeader && (
                      <div style={{ display: 'flex' }}>
                        <div style={{ width: '50%' }}>
                          <div style={{ marginBottom: 20 }}>
                            <b>{translate('Allow Charge From')}:</b>
                          </div>
                          <div className={classes.twoCols}>
                            <span style={{ width: '50%' }}>{translate('System')}</span>
                            <span style={{ width: '50%' }}>{translate('Grid')}</span>
                          </div>
                        </div>
                        <div style={{ width: '50%' }}>
                          <div style={{ marginBottom: 20 }}>
                            <b>{translate('Allow Discharge To')}:</b>
                          </div>
                          <div className={classes.twoCols}>
                            <span style={{ width: '50%' }}>{translate('Load')}</span>
                            <span style={{ width: '50%' }}>{translate('Grid')}</span>
                          </div>
                        </div>
                      </div>
                    )}
                    <Grid container justify={'space-between'} alignItems={'center'} className={classes.checkboxWrapper}>
                      <Grid item xs={3} xl={3}>
                        <CustomField
                          component={CheckboxInput}
                          name={`strategies.${index}.can_charge_from_solar`}
                          source={`strategies.${index}.can_charge_from_solar`}
                          options={{
                            classes: { root: batteryLogicError ? classes.checkboxWithError : classes.checkbox },
                          }}
                          resource={resource}
                          label=""
                        />
                      </Grid>
                      <Grid item xs={3} xl={3}>
                        <CustomField
                          component={CheckboxInput}
                          name={`strategies.${index}.can_charge_from_grid`}
                          source={`strategies.${index}.can_charge_from_grid`}
                          options={{
                            classes: { root: batteryLogicError ? classes.checkboxWithError : classes.checkbox },
                          }}
                          resource={resource}
                          label=""
                        />
                      </Grid>
                      <Grid item xs={3} xl={3}>
                        <CustomField
                          component={CheckboxInput}
                          name={`strategies.${index}.can_discharge_to_load`}
                          source={`strategies.${index}.can_discharge_to_load`}
                          options={{
                            classes: { root: batteryLogicError ? classes.checkboxWithError : classes.checkbox },
                          }}
                          resource={resource}
                          label=""
                        />
                      </Grid>
                      <Grid item xs={3} xl={3}>
                        <CustomField
                          component={CheckboxInput}
                          name={`strategies.${index}.can_discharge_to_grid`}
                          source={`strategies.${index}.can_discharge_to_grid`}
                          options={{
                            classes: { root: batteryLogicError ? classes.checkboxWithError : classes.checkbox },
                          }}
                          resource={resource}
                          label=""
                        />
                      </Grid>
                    </Grid>
                    {batteryLogicError && <div style={{ color: 'red' }}>{batteryLogicError}</div>}
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} xl={2} className={classes.tableCell}>
                    {!displayTableHeader && (
                      <div className={classes.label}>{translate('Minimum State of Charge(%)')}:</div>
                    )}
                    <NumberInput
                      resource={resource}
                      name={`strategies.${index}.min_state_of_charge_percent`}
                      source={`strategies.${index}.min_state_of_charge_percent`}
                      label=""
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} xl={2} className={classes.tableCell}>
                    {!displayTableHeader && (
                      <div className={classes.label}>{translate('Maximum Charge/ Discharge Rate (kW)')}:</div>
                    )}
                    <NumberInput
                      resource={resource}
                      name={`strategies.${index}.max_charge_rate_kw`}
                      source={`strategies.${index}.max_charge_rate_kw`}
                      label=""
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} xl={4} className={classes.tableCell}>
                    {!displayTableHeader && <div className={classes.label}>{translate('Applicable Time-Period')}:</div>}
                    <Schedule
                      itemList={formValues.strategies}
                      itemIndex={index}
                      onEdit={() => setEditingSchedule(index)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} xl={1} className={classes.tableCell}>
                    {!displayTableHeader && (
                      <div className={`${classes.label} ${classes.tableCell}`}>{translate('Actions')}:</div>
                    )}
                    <Actions index={index} disableDelete={formValues.strategies?.length < 2} />
                  </Grid>
                </Grid>
              </div>
            )
          })}
        </div>
      </div>
      {editingSchedule !== undefined && (
        <ScheduleDialog
          isOpen={true}
          onClose={() => {
            setEditingSchedule(undefined)
          }}
          allSchedules={formValues.strategies?.map((strategy: any) => strategy.schedule) || []}
          editingIndex={editingSchedule}
          handleSave={(state) => {
            let newStrategies = _.cloneDeep(formState.values.strategies)
            newStrategies[editingSchedule].schedule = state
            form.change('strategies', newStrategies)
            setEditingSchedule(undefined)
          }}
          dialogTitle={translate('Strategy ') + (editingSchedule + 1)}
        />
      )}
      <div style={{ marginTop: 20 }}>
        <Button variant="contained" onClick={handleAddStrategy}>
          {translate('Add New Strategy')}
        </Button>
      </div>
    </div>
  )
}

const StrategyTable = () => {
  const isLargeScreen = useMediaQuery((theme: any) => theme.breakpoints.up('xl'))

  return <div style={{ margin: '50px 0' }}>{isLargeScreen ? <StrategyTableLarge /> : <StrategyTableLarge />}</div>
}

export default StrategyTable

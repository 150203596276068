import { Grid } from '@material-ui/core'
import AssignmentLateOutlinedIcon from '@material-ui/icons/AssignmentLateOutlined'
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined'
import EditIcon from '@material-ui/icons/CreateOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { Button, styled } from 'opensolar-ui'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import { useState } from 'react'
import { ORDER_STATUS } from '../constants'
import AcceptPurchaseOrderDialog from '../modals/AcceptPurchaseOrderDialog'
import EditPurchaseOrderDialog from '../modals/EditPurchaseOrderDialog'
import ViewPurchaseOrderDialog from '../modals/ViewPurchaseOrderDialog'
import type { Transfer, TransferFormType } from '../type'

const ReviewButton = styled(Button)<{ onManage: boolean }>(({ theme, onManage }) => ({
  background: onManage ? `${COLOR_PALETTE.blue3} !important` : '',
  color: onManage ? `${theme.palette.common.white} !important` : '',
}))

const IncomingListActionsField = ({
  transfer,
  transferType,
  allowReview,
  orderStatus,
  onManage,
}: {
  transfer: Transfer
  transferType: TransferFormType
  allowReview?: boolean
  orderStatus?: string
  onManage: boolean
}) => {
  const [acceptDialogOpen, setAcceptDialogOpen] = useState(false)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [viewDialogOpen, setViewDialogOpen] = useState(false)

  return (
    <>
      {transfer?.is_arrived ? (
        <ReviewButton
          size="small"
          variant="contained"
          color="info"
          startIcon={onManage ? <></> : <AssignmentTurnedInOutlinedIcon />}
          onManage={onManage}
          onClick={() => {
            setViewDialogOpen(true)
            logAmplitudeEvent('generic_button_clicked', {
              source: 'view',
              context: 'incoming_purchase_order_table',
            })
          }}
        >
          {allowReview ? 'Review PO' : 'View PO'}
        </ReviewButton>
      ) : (
        <Grid container spacing={1}>
          {allowReview && (
            <Grid item>
              <ReviewButton
                size="small"
                variant="contained"
                color="info"
                onManage={onManage}
                startIcon={onManage ? <></> : <AssignmentTurnedInOutlinedIcon />}
                onClick={() => {
                  if (orderStatus === ORDER_STATUS.DELIVERED) {
                    setAcceptDialogOpen(true)
                  } else {
                    setViewDialogOpen(true)
                  }
                  logAmplitudeEvent('generic_button_clicked', {
                    source: 'review',
                    context: 'incoming_purchase_order_table',
                  })
                }}
              >
                Review PO
              </ReviewButton>
            </Grid>
          )}

          {!allowReview && (
            <Grid item>
              <Button
                size="small"
                variant="contained"
                color="default"
                startIcon={<AssignmentLateOutlinedIcon />}
                onClick={() => {
                  setAcceptDialogOpen(true)
                  logAmplitudeEvent('generic_button_clicked', {
                    source: 'check_in',
                    context: 'incoming_purchase_order_table',
                  })
                }}
              >
                Check in
              </Button>
            </Grid>
          )}

          {!allowReview && (
            <Grid item>
              <Button
                size="small"
                variant="contained"
                color="info"
                startIcon={<EditIcon />}
                onClick={() => {
                  setEditDialogOpen(true)
                  logAmplitudeEvent('generic_button_clicked', {
                    source: 'edit',
                    context: 'incoming_purchase_order_table',
                  })
                }}
              >
                Edit
              </Button>
            </Grid>
          )}
        </Grid>
      )}
      {/* TODO: The following dialogs can be combined */}
      {editDialogOpen && (
        <EditPurchaseOrderDialog
          isOpen={editDialogOpen}
          initialValues={transfer}
          onClose={() => {
            setEditDialogOpen(false)
            logAmplitudeEvent('generic_button_clicked', {
              source: 'cancel',
              context: 'edit_incoming_purchase_order_dialog',
            })
          }}
          transferType={transferType}
        />
      )}
      {acceptDialogOpen && (
        <AcceptPurchaseOrderDialog
          isOpen={acceptDialogOpen}
          initialValues={transfer}
          onClose={() => {
            setAcceptDialogOpen(false)
            logAmplitudeEvent('generic_button_clicked', {
              source: 'cancel',
              context: 'accept_incoming_purchase_order_dialog',
            })
          }}
          transferType={transferType}
          allowReview={allowReview}
        />
      )}
      {viewDialogOpen && (
        <ViewPurchaseOrderDialog
          isOpen={viewDialogOpen}
          initialValues={transfer}
          onClose={() => setViewDialogOpen(false)}
          transferType={transferType}
          allowReview={allowReview}
        />
      )}
    </>
  )
}

export default IncomingListActionsField

import { orgSelectors } from 'ducks/orgs'
import Alert from 'elements/Alert'
import lodash from 'lodash'
import { useGetNotStockedItemCodes } from 'projectSections/sections/design/cogsOverride/useGetNotStockedItemCodes'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { doNotTranslate } from 'util/misc'

const useStyles = makeOpenSolarStyles((theme) => ({
  gapBottom: {
    marginBottom: 10,
  },
  gapTop: {
    marginTop: 10,
  },
  spacing: {
    marginBottom: 10,
    marginTop: 10,
  },
}))

const NotStockedWarningForUK = () => {
  const classes = useStyles()
  const { notStockedItemCodes, selectedDistributor } = useGetNotStockedItemCodes()

  if (!notStockedItemCodes.length) return null

  return (
    <div className={classes.spacing}>
      <Alert severity="warning" className={classes.spacing}>
        <div>
          <div className={classes.gapBottom}>
            {doNotTranslate('The following components are not stocked by')} {selectedDistributor}:
          </div>
          {lodash.uniq(notStockedItemCodes).map((code) => (
            <div key={code}>{code}</div>
          ))}
          <div className={classes.gapTop}>
            {doNotTranslate(
              'Manually review associated hardware costs in the "Customise Hardware Cost" dialog before proceeding.'
            )}
          </div>
        </div>
      </Alert>
    </div>
  )
}

const NotStockedItemsWarning = () => {
  const hasEnabledUKDistributor = useSelector(orgSelectors.getEnableUKHardwareSuppliers).length > 0

  if (!hasEnabledUKDistributor) return null
  return <NotStockedWarningForUK />
}

export default NotStockedItemsWarning

import { Tooltip } from '@material-ui/core'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutlineOutlined'
import CreateIcon from '@material-ui/icons/CreateOutlined'
import { orgSelectors } from 'ducks/orgs'
import TinyBox from 'elements/TinyBox'
import { TinyLabel } from 'elements/TinyLabel'
import { IconButton } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { PaymentOptionType } from 'types/paymentOptions'
import { ProjectTransactionType } from 'types/projectTransactions'
import { formatCurrency, formatTimeStringBaseOnLocale } from 'util/misc'

const useStyles = makeOpenSolarStyles((theme) => ({
  row: {
    margin: theme.spacing(1),
  },
  successIcon: {
    color: theme.alert_success,
    marginRight: theme.spacing(0.5),
  },
}))

const READABLE_TYPE_MAP: any = {
  mosaic_application: 'Mosaic Loan Application',
  loanpal_application: 'Goodleap Loan Application',
  sungage_application: 'Sungage Loan Application',
  commonbond_application: 'CommonBond Application',
  commonbond_loan_agreement: 'CommonBond Loan Agreement',
  dividend_application: 'Dividend Loan Application',
  dividend_loan_agreement: 'Dividend Loan Agreement',
  mosaic_loan_agreement: 'Mosaic Loan Agreement',
  plenti_loan_application: 'Plenti Loan Application',
  plenti_bnpl_application: 'Plenti BNPL Application',
  brighte_application: 'Brighte BNPL Application',
  expired_docusign: 'Docusign Contract',
  docusign: 'Docusign Contract',
  phoenix_application: 'Phoenix Application',
}

type PropTypes = {
  transaction: ProjectTransactionType
  currencySymbol: string
  openEditDialog: (toEdit: ProjectTransactionType) => void
  record?: PaymentOptionType
  projectUrl?: URL
  loading?: boolean
}

const TransactionRow: React.FunctionComponent<PropTypes> = (props) => {
  const paymentOption: PaymentOptionType | undefined = props.record
  const myOrgUrl = useSelector(orgSelectors.getOrgUrl)
  const transactionOrgUrl = props.transaction.org
  const enableAction = myOrgUrl === transactionOrgUrl
  const isIntegratedFinance: boolean =
    paymentOption && !!paymentOption.configuration_json
      ? !!JSON.parse(paymentOption.configuration_json).integration
      : false
  const classes = useStyles()
  const translate = useTranslate()
  const showAmountApproved =
    props.transaction?.external_finance_amount_approved && props.transaction?.external_finance_amount_requested

  const statusForPayment = (is_complete: boolean, funds_confirmed: boolean, amount: number) => {
    // Loan applications have special statuses, others use default

    // order of keys: is_complete, funds_confirmed, amount (>0)
    type MessageKeyType = '000' | '001' | '010' | '011' | '100' | '101' | '110' | '111'
    const messages = {
      '000': 'Awaiting Response',
      '001': 'Awaiting Response',
      '010': 'Invalid Status',
      '011': 'Invalid Status',
      '100': 'Complete',
      '101': 'Awaiting Payment',
      '110': 'Not Required',
      '111': 'Funds Received',
    }

    const key = ((is_complete ? '1' : '0') + (funds_confirmed ? '1' : '0') + (amount > 0 ? '1' : '0')) as MessageKeyType
    return messages[key]
  }

  const getHumanFriendlyType = () => {
    if (READABLE_TYPE_MAP[props.transaction.transaction_type])
      return translate(READABLE_TYPE_MAP[props.transaction.transaction_type])
    else return translate(props.transaction.transaction_type)
  }

  const getStatusText = (): string => {
    if (props.transaction.transaction_type === 'expired_docusign') return 'Contract Reset'
    let statusText: string = props.transaction.external_status_title
      ? props.transaction.external_status_title
      : statusForPayment(props.transaction.is_complete, props.transaction.funds_confirmed, props.transaction.amount)

    let statusSuffix: string | null = props.transaction.external_status_description
      ? ' (' + props.transaction.external_status_description + ')'
      : null
    return statusSuffix ? statusText + statusSuffix : statusText
  }

  const openEditDialog = () => {
    props.openEditDialog(props.transaction)
  }

  const fundsReceived = getStatusText() === 'Funds Received'

  return (
    <TinyBox
      title={
        fundsReceived && (
          <>
            <div style={{ display: 'flex' }}>
              <CheckCircleOutline fontSize="small" className={classes.successIcon} />
              {translate(getStatusText())}
            </div>
          </>
        )
      }
      className={classes.row}
      actions={
        <Tooltip title={enableAction ? '' : translate('This item belongs to another organisation')}>
          <div>
            <IconButton onClick={openEditDialog} disabled={!enableAction}>
              <CreateIcon />
            </IconButton>
          </div>
        </Tooltip>
      }
    >
      <>
        <TinyLabel label={translate('Method') + ':'}>{getHumanFriendlyType()}</TinyLabel>
        <TinyLabel label={translate('Status') + ':'}>{translate(getStatusText())}</TinyLabel>
        {!showAmountApproved && props.transaction.amount > 0 ? (
          <TinyLabel label={translate('Amount') + ':'}>
            {props.currencySymbol}
            {formatCurrency(props.transaction.amount)}{' '}
            {props.transaction.tax_included > 0 && (
              <span>
                ({translate('Tax Included')}: {props.currencySymbol}
                {formatCurrency(props.transaction.tax_included)})
              </span>
            )}
          </TinyLabel>
        ) : null}
        {showAmountApproved ? (
          <>
            <TinyLabel label={translate('Amount Requested') + ':'}>
              {props.currencySymbol}
              {formatCurrency(props.transaction.external_finance_amount_requested)}{' '}
            </TinyLabel>
            <TinyLabel label={translate('Amount Approved') + ':'}>
              {props.currencySymbol}
              {formatCurrency(props.transaction.external_finance_amount_approved)}{' '}
            </TinyLabel>
          </>
        ) : null}
        {props.transaction.signature_data && (
          <TinyLabel label={translate('Signature') + ':'}>
            <img src={props.transaction.signature_data} style={{ width: 200, height: 50, display: 'block' }} />
          </TinyLabel>
        )}
        {isIntegratedFinance && props.transaction.created_date && (
          <TinyLabel label={translate('Initiated') + ':'}>
            {formatTimeStringBaseOnLocale(props.transaction.created_date)}
          </TinyLabel>
        )}
        {isIntegratedFinance && props.transaction.modified_date && (
          <TinyLabel label={translate('Last Modified') + ':'}>
            {formatTimeStringBaseOnLocale(props.transaction.modified_date)}
          </TinyLabel>
        )}
      </>
    </TinyBox>
  )
}
export default TransactionRow

import { orgSelectors } from 'ducks/orgs'
import { useTranslate } from 'ra-core'
import { useSelector } from 'react-redux'
import { MentionContexts } from './constants'
import { PlaceholderContext, contextConfigMap, defaultContextConfig } from './contextConfigs'
import dataConfigs from './dataConfigs'
import { EditorPlaceholder, EditorPlaceholderCategory } from './types'

/**
 * `usePlaceholders` returns a list of placeholder categories for a given context.
 * The returns values are:
 * - Pre-translated, including substituting in substrings
 * - Sorted alphabetically (both category and items within)
 * - Categories are combined by title
 * - Placeholders are 'expanded' out to represent each project role, system, or contact available in this context
 */
export const usePlaceholders = () => {
  const translate = useTranslate()
  const customForms = useSelector(orgSelectors.getCustomForms)

  return (mentionContext?: MentionContexts): EditorPlaceholderCategory[] => {
    if (!mentionContext) mentionContext = 'empty'
    const placeholderDefs = [...dataConfigs[mentionContext]]
    let ret: EditorPlaceholderCategory[] = []

    const translateItem = (label: string, item: EditorPlaceholder, tokens: any = {}): EditorPlaceholder => {
      tokens = { ...(tokens || {}), ...(item.transSubs || {}) }
      for (const token in tokens) {
        let value = tokens[token]
        if (typeof value === 'string') {
          value = translate(value, tokens)
        }
        tokens[token] = value
      }
      return {
        ...item,
        label: translate(label, tokens),
      }
    }

    const addCategory = (value: EditorPlaceholderCategory) => {
      const existing = ret.find((c) => c.category === value.category)
      if (existing) {
        existing.items = existing.items.concat(value.items)
      } else {
        ret.push(value)
      }
    }
    // Add placeholders from custom forms
    for (const customForm of customForms) {
      if (customForm.configuration?.placeholders) {
        for (const placeholder of customForm.configuration?.placeholders) {
          if (placeholder.contexts?.includes(mentionContext)) placeholderDefs.push(placeholder)
        }
      }
    }

    // Iterate and transform all categories
    for (const category of placeholderDefs) {
      if (!category.create_for_each) {
        addCategory({
          ...category,
          category: translate(category.category),
          items: category.items.map((item) => translateItem(item.label, item)),
        })

        continue
      }

      const context: PlaceholderContext = contextConfigMap[mentionContext] || defaultContextConfig

      let entities
      switch (category.create_for_each) {
        case 'project_role':
          entities = context.project_roles
          break

        case 'system':
          entities = context.systems
          break

        case 'contact':
          entities = context.contacts
          break
      }

      for (var i = 0; i < entities.length; i++) {
        const entity = entities[i]
        const num = i + 1
        const items = category.items.map((item) => {
          let itemLabel = item.label
          if (itemLabel === '%{label}') itemLabel = entity.label
          const newItem = translateItem(itemLabel, item, { label: entity.label, num })
          newItem.field = newItem.field.replace('%{path}', entity.path)
          newItem.hidden_in_picker = entity.hidden_in_picker
          return newItem
        })

        const getLabel = (ent, cat) => {
          let catLabel = translate(ent.label)
          if (ent.label.includes('%{num}') && !ent.label.includes('%{label}')) {
            catLabel = translate(ent.label, { num: `${num}` })
            return catLabel
          } else if (cat.category !== '%{label}') {
            catLabel = translate(cat.category, { label: catLabel, num })
            return catLabel
          } else {
            return catLabel
          }
        }
        const translatedLabel = getLabel(entity, category)
        addCategory({ category: translatedLabel, items })
      }
    }

    // Sort alphabetically
    ret = ret.sort((a, b) => a.category.localeCompare(b.category))
    for (const category of ret) {
      category.items = category.items.sort((a, b) => a.label.localeCompare(b.label))

      // Check category for hidden_in_picker
      category.hidden_in_picker = category.items.every((item) => item.hidden_in_picker)
    }

    return ret
  }
}

import { CustomFormAnyData } from 'customForm/types'
import lodash from 'lodash'
import { CustomFormUsageType } from 'resources/customForms/types'

export const applyScopeDefaults = (customForm: CustomFormUsageType, data: CustomFormAnyData) => {
  const resolveDefaultsWithData = (v) => {
    return resolveDefaults(v, data)
  }

  if (customForm.configuration?.scopes) {
    for (const i in customForm.configuration.scopes) {
      const scope = customForm.configuration.scopes[i]
      if (scope.default) {
        let defaultData = lodash.mapValues(scope.default, resolveDefaultsWithData)
        if (scope.path) {
          const parts = scope.path.split('.')
          lodash.forEachRight(parts, (p: string, i: number) =>
            i % 2 === 0 && p === 'properties' ? defaultData : (defaultData = { [p]: defaultData })
          )
        }
        data = lodash.mergeWith(defaultData, data, mergeStrategy, (a, b) =>
          b === undefined || b === '' ? a : undefined
        )
      }
    }
  }
}

const mergeStrategy = (objValue, srcValue) => {
  if (lodash.isArray(srcValue)) {
    return objValue
  }
}

const resolveDefaults = (value: unknown, data: CustomFormAnyData) => {
  if (typeof value !== 'object' || value?.['@type'] !== 'ref') return value
  return lodash.get(data, value['ref'])
}

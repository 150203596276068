import type { ComponentTypes } from 'types/selectComponent'
import type { FilterComponentNode, FilterComponentRendererType, FilterGenericPropsType } from '../type'
import CurrentRatingFilter, { RatingFilterOptionsType } from './CurrentRatingFilter'
import { CURRENT_RATING_FILTER_GTE_KEY, CURRENT_RATING_FILTER_LTE_KEY, CURRENT_RATING_RANGE } from './constants'

export const CURRENT_RATING_FILTER_NODE_KEY = 'current_rating'

const maybeCreateCurrentRatingFilterNode = ({
  RendererComponent,
  componentType,
}: {
  RendererComponent: FilterComponentRendererType<RatingFilterOptionsType>
  componentType?: ComponentTypes
}): FilterComponentNode | undefined => {
  if (componentType !== 'other') {
    return
  }

  const FilterComponent = (props: FilterGenericPropsType) => {
    return (
      <CurrentRatingFilter
        gteFilterKey={CURRENT_RATING_FILTER_GTE_KEY}
        lteFilterKey={CURRENT_RATING_FILTER_LTE_KEY}
        rangeOptions={CURRENT_RATING_RANGE}
        RendererComponent={RendererComponent}
        {...props}
      />
    )
  }
  return {
    key: CURRENT_RATING_FILTER_NODE_KEY,
    component: FilterComponent,
  }
}

export default maybeCreateCurrentRatingFilterNode

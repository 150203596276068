import type { FilterNodeKeyAndRenderer } from 'elements/hardwareFilter/type'
import createAccordionRenderer from './createAccordionRenderer'
import { ComponentTypes } from '../../../types/selectComponent'

const mapRendererConfigWithAccordion = ({
  config,
  defaultExpanded,
  showDivider = false,
  recordLru = false,
}: {
  config: FilterNodeKeyAndRenderer[]
  defaultExpanded?: string[]
  showDivider: boolean
  recordLru: boolean
}) => {
  return config.map(({label, key, rendererComponent }, index) => {
    const isLast = index === config.length - 1
    return {
      key,
      rendererComponent: createAccordionRenderer({
        BasicRenderer: rendererComponent,
        filterKey: key,
        filterLabel: label,
        options: {
          defaultExpanded: !!defaultExpanded?.includes(key),
          showDivider: isLast ? false : showDivider,
          recordLru,
        },
      }),
    }
  })
}

export default mapRendererConfigWithAccordion

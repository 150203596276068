//Actions
export const UPDATE_SHOW_LEARN = 'UPDATE_SHOW_LEARN'
export const UPDATE_COURSE_DATA = 'UPDATE_COURSE_DATA'

//Action Creators
export const updateShowLearn = (value: boolean) => {
  return {
    type: UPDATE_SHOW_LEARN,
    payload: value,
  }
}
export const updateCourseData = (value: any) => {
  return { type: UPDATE_COURSE_DATA, payload: value }
}

const initialState = {
  showLearn: false,
  courseData: {},
}

//Reducer
export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case UPDATE_SHOW_LEARN:
      return {
        ...state,
        showLearn: (action as any).payload,
      }
    case UPDATE_COURSE_DATA:
      return {
        ...state,
        courseData: (action as any).payload,
      }
    default:
      return state
  }
}

import { Box, Grid } from '@material-ui/core'
import { FileField, FileInput, useTranslate } from 'react-admin'

const HeatContent = () => {
  const translate = useTranslate()

  return (
    <Box width="100%">
      <Grid item xs={12}>
        <FileInput
          source="heat_json_file"
          placeholder={<p>{translate('Click to upload or drag & drop a file here')}</p>}
          label="Upload JSON file"
          accept="application/JSON"
        >
          <FileField source="src" title="title" />
        </FileInput>
      </Grid>
    </Box>
  )
}

export default HeatContent

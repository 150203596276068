import { OrgType } from 'types/orgs'
import { RootState } from 'types/state'

/**
 * @deprecated use `orgSelectors.getOrg` instead
 */
export function getOrgFromState(state: RootState): OrgType | undefined {
  return state.orgs?.org
}

/**
 * @deprecated use `orgSelectors.getOrgIso2` instead
 */
export function getOrgCountryIso2FromState(state: RootState): string | undefined {
  const org = getOrgFromState(state)
  return org?.country?.iso2
}

/**
 * @deprecated use `orgSelectors.getOrgIso2` instead
 */
export const getOrgCountryCodeFromState = (state: RootState) => {
  const org = getOrgFromState(state)
  return org?.country?.iso2 || 'AU'
}

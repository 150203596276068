import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PencilIcon from '@material-ui/icons/EditOutlined'
import SaveIcon from '@material-ui/icons/SaveOutlined'
import { markFieldActive, markFieldInactive } from 'Studio/Utils'
import { IconButton } from 'opensolar-ui'
import React, { useCallback, useState } from 'react'
import { useTranslate } from 'react-admin'
import { SystemObjectType } from 'types/systems'
import { selectedFontColor, unselectedFontColor } from './SystemAccordion'

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    height: 25,
    marginBottom: 2,
  },
  label: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    verticalAlign: 'middle',
    textOverflow: 'ellipsis',
    maxWidth: 230,
    display: 'inline-block',
    fontSize: 18,
    color: (isSelectedSystem) => (isSelectedSystem ? selectedFontColor : unselectedFontColor),
  },
  icon: {
    color: 'black',
    width: 18,
    height: 18,
  },
  iconButton: {
    flexBasis: 'auto',
    padding: 0,
    width: 25,
    height: 25,
    margin: 0,
  },
}))

type ActionPropTypes = {
  system: SystemObjectType
  isEditing: boolean
  setEditingSystemUuid: Function
  handleSaveSystemName: Function
}

const ActionButton: React.FC<ActionPropTypes> = ({ isEditing, system, setEditingSystemUuid, handleSaveSystemName }) => {
  const classes = useStyles()
  return isEditing ? (
    <IconButton
      onClick={(e) => {
        e.stopPropagation()
        handleSaveSystemName()
      }}
      classes={{ root: classes.iconButton }}
    >
      <SaveIcon className={classes.icon} />
    </IconButton>
  ) : (
    <IconButton
      onClick={(e) => {
        e.stopPropagation()
        setEditingSystemUuid(system.uuid)
      }}
      classes={{ root: classes.iconButton }}
    >
      <PencilIcon className={classes.icon} />
    </IconButton>
  )
}

type PropTypes = {
  system: SystemObjectType
  isEditing: boolean
  isSelectedSystem: boolean
  setEditingSystemUuid: Function
}

const SystemName: React.FC<PropTypes> = ({ system, isEditing, isSelectedSystem, setEditingSystemUuid }) => {
  const translate = useTranslate()
  const classes = useStyles(isSelectedSystem)
  const [name, setName] = useState<string>(system.name || '')
  const systemName =
    system.name && system.name.length > 0 ? system.name : `${system.moduleQuantity()} ${translate('Panels System')}`
  const handleSaveSystemName = useCallback(() => {
    window.editor.execute(
      new window.SetValueCommand(system, 'name', name, window.Utils.generateCommandUUIDOrUseGlobal())
    )
    setEditingSystemUuid(null)
  }, [name])
  return (
    <div className={classes.wrapper}>
      {isEditing && isSelectedSystem ? (
        <TextField
          name="systemName"
          value={name}
          autoFocus={true}
          onFocus={() => {
            markFieldActive('systemName', system)
          }}
          onBlur={() => {
            markFieldInactive()
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              markFieldInactive()
              handleSaveSystemName()
            }
          }}
          onClick={(e) => e.stopPropagation()}
          onChange={(event) => {
            setName(event.target.value)
          }}
          style={{ width: 230 }}
          inputProps={{ style: { paddingBottom: 3 } }}
        />
      ) : (
        <span className={classes.label}>{systemName}</span>
      )}
      {isSelectedSystem && (
        <ActionButton
          isEditing={isEditing}
          system={system}
          handleSaveSystemName={handleSaveSystemName}
          setEditingSystemUuid={setEditingSystemUuid}
        />
      )}
    </div>
  )
}

export default SystemName

import React from 'react'
import type { ComponentTypes } from 'types/selectComponent'
import type { FilterComponentNode, FilterComponentRendererType, FilterGenericPropsType } from '../type'
import WarrantyFilter, { FilterOptionsType } from './WarrantyFilter'

export const WARRANTY_FILTER_KEY = 'product_warranty'

export type WarrantyRangeType = {
  year: number
  value: string
  title: string
}

const WARRANTY_RANGES: WarrantyRangeType[] = [
  {
    year: 12,
    value: '12',
    title: '12 year',
  },
  {
    year: 25,
    value: '25',
    title: '25 year',
  },
  {
    year: 30,
    value: '30',
    title: '30 year',
  },
  {
    year: 40,
    value: '40',
    title: '40 year',
  },
]

const getWarrantyOptionsByComponentType = (componentType: ComponentTypes) => {
  if (componentType === 'module') {
    return WARRANTY_RANGES
  }
}

const getDefaultValue = (args?: { specsData: { [key: string]: string | number } }) => {
  if (!args) return
  const { specsData } = args
  if (specsData.product_warranty == null) {
    return
  }

  for (let i = 0; i < WARRANTY_RANGES.length; i++) {
    const { year, value } = WARRANTY_RANGES[i]
    if (specsData.product_warranty === year || specsData.product_warranty === value) {
      return { [WARRANTY_FILTER_KEY]: value }
    }
  }
  return
}

const maybeCreateWarrantyFilterNode = ({
  RendererComponent,
  componentType,
}: {
  RendererComponent: FilterComponentRendererType<FilterOptionsType>
  componentType?: ComponentTypes
}): FilterComponentNode | undefined => {
  if (!componentType) {
    return undefined
  }

  const warrantyOptions = getWarrantyOptionsByComponentType(componentType)

  if (!warrantyOptions) {
    return undefined
  }

  const FilterComponent = (props: FilterGenericPropsType) => {
    return (
      <WarrantyFilter
        filterKey={WARRANTY_FILTER_KEY}
        warrantyOptions={warrantyOptions}
        RendererComponent={RendererComponent}
        {...props}
      />
    )
  }
  return {
    key: WARRANTY_FILTER_KEY,
    component: FilterComponent,
    getDefaultValue,
  }
}

export default maybeCreateWarrantyFilterNode

import { IRONRIDGE_SSO_CONFIG } from 'pages/auth/ironridge/Config'
import type HostConfig from './types'

const IronridgeHostConfig: HostConfig = {
  name: 'ironridge',
  hostnames: [
    'irda.sunsuite.com.au',
    'opensolar.ironridge.com',
    'opensolarstg.ironridge.com',
    'opensolardev.ironridge.com',
  ],
  loginConfigs: [IRONRIDGE_SSO_CONFIG],
}

export default IronridgeHostConfig

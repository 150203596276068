import { Card, makeStyles } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import AuthModalWrapper from './AuthModalWrapper'

const useStyles = makeStyles((theme: any) => ({
  card: {
    width: 308,
    minWidth: 210,
    padding: '0.5em 1em 1em 1em',
  },
  button: {
    color: theme.headerFontColor,
    background: theme.themeColor,
    '&:hover': {
      cursor: 'pointer',
      background: theme.themeColorDark,
    },
  },
}))

const EmailChangeComplete = () => {
  const translate = useTranslate()
  const classes = useStyles()
  return (
    <AuthModalWrapper>
      <Card elevation={0} classes={{ root: classes.card }}>
        <h1>{translate('Email Change Complete')}</h1>
        <p>{translate('Your email has been changed.')}</p>
      </Card>
    </AuthModalWrapper>
  )
}
export default EmailChangeComplete

import React, { useEffect, useState } from 'react'
import Alert from './Alert'
import { useTranslate } from 'ra-core'

type PropTypes = {}

const PaymentOptionWarning: React.FC<PropTypes> = () => {
  const [messages, setMessages] = useState<string[]>([])

  const translate = useTranslate()

  useEffect(() => {
    const systems = window.editor.getSystems()
    let errorMessages: string[] = []
    systems?.forEach((sys) => {
      sys?.payment_options?.forEach((pmt) => {
        if (pmt.integration) {
          let errorsOnly = pmt?.messages?.filter((message) => message.type === 'error')
          if (errorsOnly && errorsOnly.length) {
            let errorString = errorsOnly[0].message || errorsOnly[0].text
            if (errorString) errorMessages.push(`${pmt.title} - ${errorString}`)
          }
        }
      })
    })
    setMessages(errorMessages)
  }, [])

  if (!messages?.length) return null
  return (
    <Alert severity="warning">
      <p>
        {translate(
          'Warning: This Project has one or more validation errors that could prevent the integrated credit applciation from being submitted. Please resolve these issues before continuing.'
        )}
      </p>
      <ul>
        {messages?.map((messageString, i) => (
          <li key={i}>{messageString}</li>
        ))}
      </ul>
    </Alert>
  )
}
export default PaymentOptionWarning

import React from 'react'
import { SelectInput } from 'react-admin'
import { useForm } from 'react-final-form'
import { pricing_formulas_and_variables } from './PricingSchemes'

interface PropTypes {
  label?: string | false
}

const PricingFormulaSelectInput: React.FC<PropTypes> = ({ label }) => {
  const form = useForm()
  return (
    <SelectInput
      name="pricing_formula"
      source="pricing_formula"
      resource="pricing_schemes"
      label={label}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value
        if (newValue === 'Markup Percentage') {
          form.change('variable_tax_mode', 'excluding')
        } else {
          form.change('variable_tax_mode', 'including')
        }
      }}
      choices={pricing_formulas_and_variables.map((pf) => {
        return { id: pf.title, name: pf.title }
      })}
    />
  )
}

export default PricingFormulaSelectInput

// @ts-nocheck
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import Button from 'elements/proUXButtons/ProUXButton'
import LoadingDots from 'layout/widgets/LoadingDots'
import React, { useState } from 'react'
import { systemNameOrDefault } from 'util/misc'

const useStyles = makeStyles((theme) => ({
  descriptionWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: '25px',
    marginTop: '12px',
  },
  cartWrapper: {
    marginRight: '10px',
  },
  description: {
    fontSize: '12px',
    marginRight: '15px',
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonWrapper: {
    marginTop: '15px',
  },
}))

type PropTypes = {
  handleClick: () => void
  projectId: number
  errorMsg?: string
}

const MainCTA: React.FunctionComponent<PropTypes> = (props) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)

  const handleClick = (e: Event, uuid: string | undefined) => {
    const promise = props.handleClick(e, uuid)
    if (!promise) {
      return
    }
    setLoading(true)
    promise.finally(() => {
      setLoading(false)
      logAmplitudeEvent('segen_create_quote_clicked', { project_id: props.projectId })
    })
  }

  return (
    <>
      {loading ? (
        <>
          <div
            style={{
              position: 'absolute',
              left: '0px',
              right: '0px',
              top: '0px',
              bottom: '0px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <LoadingDots />
          </div>
          <div style={{ opacity: 0.1 }}>
            <Grid item xs={12}>
              <h2>Order Parts With Segen</h2>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.descriptionWrapper}>
                <div className={classes.cartWrapper}>
                  <img
                    src={`${window.PUBLIC_URL}/images/shopping_cart_transparent_body.png`}
                    alt=""
                    height={38}
                    width={38}
                  />
                </div>
                <div className={classes.description}>
                  After designing your solar system on OpenSolar, you can create a Segen quote with just a click of the
                  button. Quick and easy!
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              {/*<div className={classes.logoWrapper}>
          <img src={`${window.PUBLIC_URL}/images/solar_outlet_logo.png`} alt="" height={34} width={116} />
        </div>*/}
            </Grid>
            <Grid item xs={12}>
              {(props.hasSoldSystem || props.systems?.length === 1) && (
                <div className={classes.buttonWrapper}>
                  <Button
                    onClick={handleClick}
                    label="Create Quote..."
                    type="primary"
                    fullWidth={true}
                    style={{ backgroundColor: 'rgb(239,124,24)' }}
                    labelStyle={{ color: '#fff' }}
                  />
                </div>
              )}

              {!props.hasSoldSystem &&
                props.systems?.length !== 1 &&
                props.systems.map((system) => {
                  return (
                    <div className={classes.buttonWrapper}>
                      <Button
                        onClick={(e) => handleClick(e, system.uuid)}
                        label={'Create Quote for ' + systemNameOrDefault(system)}
                        type="primary"
                        fullWidth={true}
                        style={{ backgroundColor: 'rgb(239,124,24)' }}
                        labelStyle={{ color: '#fff' }}
                      />
                    </div>
                  )
                })}

              {props.errorMsg && <div>{props.errorMsg}</div>}
            </Grid>
          </div>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <h2>Order Parts With Segen</h2>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.descriptionWrapper}>
              <div className={classes.cartWrapper}>
                <img
                  src={`${window.PUBLIC_URL}/images/shopping_cart_transparent_body.png`}
                  alt=""
                  height={38}
                  width={38}
                />
              </div>
              <div className={classes.description}>
                After designing your solar system on OpenSolar, you can create a Segen quote with just a click of the
                button. Quick and easy!
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            {/*<div className={classes.logoWrapper}>
          <img src={`${window.PUBLIC_URL}/images/solar_outlet_logo.png`} alt="" height={34} width={116} />
        </div>*/}
          </Grid>
          <Grid item xs={12}>
            {(props.hasSoldSystem || props.systems?.length === 1) && (
              <div className={classes.buttonWrapper}>
                <Button
                  onClick={handleClick}
                  label="Create Quote..."
                  type="primary"
                  fullWidth={true}
                  style={{ backgroundColor: 'rgb(239,124,24)' }}
                  labelStyle={{ color: '#fff' }}
                />
              </div>
            )}

            {!props.hasSoldSystem &&
              props.systems?.length !== 1 &&
              props.systems.map((system) => {
                return (
                  <div className={classes.buttonWrapper}>
                    <Button
                      onClick={(e) => handleClick(e, system.uuid)}
                      label={'Create Quote for ' + systemNameOrDefault(system)}
                      type="primary"
                      fullWidth={true}
                      style={{ backgroundColor: 'rgb(239,124,24)' }}
                      labelStyle={{ color: '#fff' }}
                    />
                  </div>
                )
              })}

            {props.errorMsg && <div>{props.errorMsg}</div>}
          </Grid>
        </>
      )}
    </>
  )
}
export default MainCTA

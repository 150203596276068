import type { ComponentTypes } from 'types/selectComponent'

export type HardwareDetailType = {
  code: string
  quantity: number
  type: ComponentTypes
}

const getHardwareDetailFromSystem = (system): HardwareDetailType[] => {
  const hardwares: HardwareDetailType[] = []
  const moduleCode: string = system.moduleType()?.code
  if (moduleCode != null) {
    hardwares.push({
      code: moduleCode,
      quantity: system.moduleQuantity('all'),
      type: 'module',
    })
  }

  const inverters = system.inverters()
  // Logic copied from OsSystem.getSummary
  // Todo: refactor OsSystem.getSummary to make it easier to reuse
  inverters.forEach((inverter) => {
    let quantity: number
    if (inverter.microinverter) {
      quantity = inverter.moduleQuantity()
        ? Math.ceil(inverter.moduleQuantity() / inverter.mppt_quantity)
        : Math.ceil((system.moduleQuantity() - system.getStrungModules().length) / inverter.mppt_quantity)
    } else {
      quantity = 1
    }
    hardwares.push({
      code: inverter.code,
      quantity: quantity,
      type: 'inverter',
    })
  })

  const batteries = system.batteries()
  batteries.forEach((battery) => {
    const existingComponent = hardwares.find((component) => component.code === battery.code)
    if (existingComponent) {
      existingComponent.quantity += 1
    } else {
      hardwares.push({
        code: battery.code,
        quantity: 1,
        type: 'battery',
      })
    }
  })

  const others = system.others()

  others.forEach((other) => {
    hardwares.push({
      code: other.code,
      quantity: other.quantity,
      type: 'other',
    })
  })

  return hardwares
}

export default getHardwareDetailFromSystem

// @ts-nocheck
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined'
import { Button } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import useTranslateParse from '../../../util/useTranslateParse'
import { ToolTipPropsType, TourConfigType } from '../types'
import DefaultTooltip from './DefaultToolTip'

const Tooltip = (props: ToolTipPropsType) => {
  const { handleNext, handlePrev, handleCancel } = props
  const translate = useTranslate()
  const translateParse = useTranslateParse()
  return (
    <DefaultTooltip
      title={translate('SELECT THE DESIGN MODE')}
      // onClose={handleCancel}
      icon={LayersOutlinedIcon}
      content={
        <div>
          <div>
            {translate(
              'The first step is to choose how you want to design your project. OpenSolar provides 3 options*. You can read a bit about each one on the right.'
            )}
          </div>
          <div style={{ margin: '10px 0' }}>
            {translate('We recommend 3D when it is available, and 2D if 3D is not available.')}
          </div>{' '}
          <div style={{ marginBottom: 10 }}>
            {translateParse("When you've made your decision, click <b>Select<b>.", {
              b: (label: string) => <b>{label}</b>,
            })}
          </div>
          <small>
            <b>{translate('*3D may not be available in less populated areas.')}</b>
          </small>
        </div>
      }
      actions={
        <>
          <Button
            onClick={() => handlePrev && handlePrev()}
            onTouchStart={(e) => {
              e.stopPropagation()
              if (handlePrev) {
                handlePrev()
              }
            }}
          >
            <span>{translate('Back')}</span>
          </Button>
          <Button disabled={true}>
            <span>{translate('Next')}</span>
          </Button>
        </>
      }
      arrowPlacement={'right'}
    />
  )
}

const SelectDesignMode: TourConfigType = {
  spotlights: [{ targetId: 'design-mode-selection-wrapper', keepObserve: true, maxWait: 30000 }],
  tooltips: [
    {
      targetId: 'design-mode-selection-wrapper',
      placement: 'left',
      component: Tooltip,
      keepObserve: true,
      maxWait: 30000,
    },
  ],
}

export default SelectDesignMode

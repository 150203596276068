import TourTooltip from 'elements/tooltip/TourTooltip'
import { TourConfigType } from 'persistentContent/tours/types'
import { useTranslate } from 'ra-core'
import { FC } from 'react'

const StepTooltip: FC = (props) => {
  const translate = useTranslate()
  return (
    <TourTooltip
      title={translate('More Project Details')}
      subtext={translate(
        "We've kept all the vital sections for your project management needs and given them a fresh new look."
      )}
      {...props}
    />
  )
}

const MoreDetails: TourConfigType = {
  spotlights: [{ targetId: 'Project-Marketing-Section', autoScroll: true }],
  tooltips: [
    {
      targetId: 'Project-Marketing-Section',
      placement: 'top-start',
      component: StepTooltip,
      options: { accountScroll: true },
    },
  ],
}

export default MoreDetails

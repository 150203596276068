import WalletTransactionEdit from './WalletTransactionEdit'
import WalletTransactionList from './WalletTransactionList'

export default {
  list: WalletTransactionList,
  edit: WalletTransactionEdit,
  options: {
    list: {
      title: 'Wallet Activity',
      breadCrumb: 'Wallet Activity',
    },
    show: {
      title: 'wallet transaction title',
      subtitle: null,
      breadCrumb: 'Wallet Transaction',
    },
  },
}

// in src/users.js
import { authSelectors } from 'ducks/auth'
import { DefaultBreadCrumbs } from 'elements/BreadCrumbs'
import DateInput from 'elements/input/DateInput'
import PaginationWithActions from 'elements/PaginationWithActions'
import { List } from 'elements/react-admin/List'
import jsonExport from 'jsonexport/dist'
import React, { useMemo } from 'react'
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  Datagrid,
  DeleteButton,
  downloadCSV,
  Edit,
  EditButton,
  FilterComp,
  FunctionField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'
import { useSelector } from 'react-redux'
import { styles as standardStyles } from 'styles'
import { dateParser, formatTimeStringBaseOnLocale, getQueryVariable, urlToId } from 'util/misc'
import DateTimeInput from '../../elements/input/DateTimeInput'
import DependentInput from '../../elements/input/DependentInput'

export const TRANSACTION_TYPE_CHOICES = [
  { id: 'credit_card_stripe', name: 'Credit Card Online (Stripe)' },
  { id: 'offline', name: 'Offline' },
  { id: 'none', name: 'None' },
  { id: 'sunlight_loan_application', name: 'Sunlight Application' },
  { id: 'sunlight_loan_prequal', name: 'Sunlight Pre-Qualification' },
  { id: 'plenti_loan_application', name: 'Plenti Loan Application' },
  { id: 'plenti_bnpl_application', name: 'Plenti BNPL Application' },
  { id: 'energy_ease_application', name: 'Smart Ease Application' },
  { id: 'mosaic_application', name: 'Mosaic Application' },
  { id: 'mosaic_loan_agreement', name: 'Mosaic Loan Agreement' },
  { id: 'commonbond_application', name: 'CommonBond Application' },
  { id: 'commonbond_loan_agreement', name: 'CommonBond Loan Agreement' },
  { id: 'docusign', name: 'DocuSign' },
  { id: 'brighte_application', name: 'Brighte Application' },
  { id: 'dividend_application', name: 'Dividend Loan Application' },
  { id: 'dividend_loan_agreement', name: 'Dividend Loan Agreement' },
  { id: 'phoenix_application', name: 'Phoenix Application' },
]

export const BOOLEAN_CHOICES = [
  { id: 'true', name: 'Yes' },
  { id: 'false', name: 'No' },
]

export const TransactionFilter = (props) => (
  <FilterComp {...props}>
    {/*<QuickFilter label="Active" source="is_active" defaultValue={1} />*/}
    {/*@TODO: Fix QuickFilter to allow multiple with the same source */}
    {/*<QuickFilter label="Inactive" source="is_active" defaultValue={0} />*/}
    <SelectInput
      source="transaction_type"
      choices={TRANSACTION_TYPE_CHOICES}
      options={{ style: standardStyles.FILTER_FIELD_STYLE }}
    />
    <DateInput source="transaction_date_gte" parse={dateParser} />
    <DateInput source="transaction_date_lte" parse={dateParser} />
    <SelectInput
      source="is_complete"
      choices={BOOLEAN_CHOICES}
      options={{ style: standardStyles.FILTER_FIELD_STYLE }}
    />
    <SelectInput
      source="funds_confirmed"
      choices={BOOLEAN_CHOICES}
      options={{ style: standardStyles.FILTER_FIELD_STYLE }}
    />
    <TextInput source="amount" style={standardStyles.FILTER_FIELD_STYLE} />
    {/* <TextInput label="pos.search" source="q" style={standardStyles.FILTER_FIELD_STYLE} /> */}
  </FilterComp>
)

const getDefaultValuesCreate = (record) => {
  return {
    project: getQueryVariable('project'),
  }
}

// const getProjectFilter = () => {
//   var project = getQueryVariable('project')
//   var project_id = project ? urlToId(project) : null
//   if (project_id) {
//     return { project: project_id }
//   } else {
//     return null
//   }
// }

const getRedirectLinkAfterCreate = () => {
  var project = getQueryVariable('project')
  var project_id = project ? urlToId(project) : null
  if (project_id) {
    return '/projects/' + project_id
  } else {
    return '/transactions/'
  }
}

export const TransactionList = ({ accessRights: { allowCreate, allowEdit, allowDelete }, ...props }) => {
  const translate = useTranslate()
  const orgId = useSelector(authSelectors.getOrgId)
  const downloadUrl = useMemo(
    () => window.API_BASE_URL + `orgs/${orgId}/transactions/export_transactions/?page=%{page}&size=%{perPage}`,
    [orgId]
  )
  async function customDownloadCSV(blob, filename) {
    const text = await blob.text()
    const json = JSON.parse(text)
    jsonExport(json.results, (err, csv) => downloadCSV(csv, filename))
  }
  return (
    <List
      title="Project Transactions"
      breadCrumbs={<DefaultBreadCrumbs />}
      perPage={20}
      filters={<TransactionFilter />}
      {...props}
      hasCreate={allowCreate}
      pagination={
        <PaginationWithActions
          downloadUrl={downloadUrl}
          downloadFilename="OpenSolarTransactions"
          downloadCSV={customDownloadCSV}
          rowsPerPageOptions={[5, 10, 20, 25, 50, 100, 200]}
        />
      }
    >
      <Datagrid>
        <TextField source="project_name" sortable={false} />
        <TextField source="transaction_type" overrideValueFunc={(value) => translate(value)} />
        <TextField source="amount" />
        <TextField source="transaction_datetime" overrideValueFunc={(value) => formatTimeStringBaseOnLocale(value)} />
        <FunctionField
          source={null}
          label="Actions"
          render={(record, source, rest) => (
            <div>
              {allowEdit && <EditButton useListStyle={true} record={record} basePath={rest.basePath} label={'Edit'} />}
              {allowDelete && (
                <DeleteButton
                  useListStyle={true}
                  undoable={false}
                  record={record}
                  resource={rest.resource}
                  redirect={`/${rest.resource}`}
                />
              )}
            </div>
          )}
          sortable={false}
        />
      </Datagrid>
    </List>
  )
}

// export const TransactionShow = (props) => (
//   <Show {...props} title={<TransactionTitle />}>
//     <SimpleShowLayout>
//       <TextField source="id" />
//       <TextField source="amount" />
//       <TextField source="surcharge_amount" />
//     </SimpleShowLayout>
//   </Show>
// )

const inputStyle = {
  width: 256,
}

const toggleStyle = {
  marginTop: 40,
}

export const TransactionEdit = ({ accessRights: { allowView, allowCreate, allowEdit, allowDelete }, ...props }) => {
  const translate = useTranslate()
  return (
    <Edit breadCrumbs={<DefaultBreadCrumbs />} {...props} hasDelete={allowDelete}>
      <SimpleForm redirect={getRedirectLinkAfterCreate()} disabled={!allowEdit}>
        <TextField source="id" />
        <ReferenceInput
          //label="Project"
          source="project"
          reference="projects"
          optionValueField="url"
          translateChoice={false}
        >
          <AutocompleteInput
            options={{
              style: inputStyle,
              placeholder: 'Enter a project',
            }}
            optionText="address"
            optionValue="url"
          />
        </ReferenceInput>

        <TextInput style={inputStyle} source="amount" />
        <TextInput style={inputStyle} source="tax_included" />
        <TextInput style={inputStyle} source="surcharge_amount" />

        <h2>
          {translate('Details for Completed Payments')} ({translate('leave empty for payment requests')})
        </h2>
        <DateTimeInput source="transaction_datetime" />
        <BooleanInput style={toggleStyle} source="is_complete" />
        <BooleanInput style={toggleStyle} source="funds_confirmed" />
        <SelectInput source="transaction_type" choices={TRANSACTION_TYPE_CHOICES} allowEmpty />

        <DependentInput dependsOn="transaction_type" value="credit_card_stripe">
          <TextField style={inputStyle} source="details" />
        </DependentInput>
      </SimpleForm>
    </Edit>
  )
}

export const TransactionCreate = (props) => {
  const translate = useTranslate()
  return (
    <Create breadCrumbs={<DefaultBreadCrumbs />} {...props}>
      <SimpleForm defaultValue={getDefaultValuesCreate} redirect={getRedirectLinkAfterCreate()}>
        <ReferenceInput source="project" reference="projects" optionValueField="url" allowEmpty translateChoice={false}>
          <AutocompleteInput
            options={{
              style: inputStyle,
              placeholder: 'Enter a project',
            }}
            optionText="address"
            optionValue="url"
          />
        </ReferenceInput>

        <TextInput style={inputStyle} source="amount" />
        <TextInput style={inputStyle} source="tax_included" />
        <TextInput style={inputStyle} source="surcharge_amount" />

        <h2>
          {translate('Details for Completed Payments')} ({translate('leave empty for payment requests')})
        </h2>
        <DateTimeInput source="transaction_datetime" />
        <BooleanInput style={toggleStyle} source="is_complete" />
        <BooleanInput style={toggleStyle} source="funds_confirmed" />
        <SelectInput source="transaction_type" choices={TRANSACTION_TYPE_CHOICES} allowEmpty />
      </SimpleForm>
    </Create>
  )
}

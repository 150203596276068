var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export var WrenchOutlineIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? '1rem' : _b, _c = _a.color, color = _c === void 0 ? 'currentColor' : _c, rest = __rest(_a, ["size", "color"]);
    var iconAttributes = {
        width: size,
        height: size,
        stroke: color,
    };
    return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20", fill: "none" }, rest, iconAttributes, { children: _jsx("path", { d: "M18.982 5.37503C18.9682 5.2148 18.6105 4.90708 18.4592 4.85219C18.3075 4.79697 18.1376 4.83456 18.0235 4.94809L15.7339 7.23769L12.7599 4.26374L15.0954 1.93712C15.2096 1.82337 15.2469 1.65413 15.1919 1.50324C15.1363 1.35205 14.7696 1.03175 14.6084 1.01787C13.1901 0.89613 11.8004 1.40061 10.7956 2.40174C9.41951 3.77278 9.05509 5.77488 9.7004 7.48143C9.62987 7.54063 9.56028 7.60336 9.49162 7.671L1.69603 14.9993C1.69328 15.002 1.69057 15.0049 1.68759 15.0075C0.770802 15.9209 0.770802 17.407 1.68759 18.3206C2.60453 19.2339 4.08537 19.2228 5.00201 18.3094C5.00596 18.3057 5.00958 18.302 5.01319 18.2979L12.305 10.4712C12.3715 10.4047 12.4332 10.3351 12.4914 10.2637C14.2049 10.9077 16.2158 10.5455 17.5931 9.1736C18.5977 8.1724 19.1044 6.78782 18.982 5.37503Z", stroke: color, strokeWidth: "2", strokeLinejoin: "round" }) })));
};

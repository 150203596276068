import { useState } from 'react';
export var useMenu = function (menuId) {
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    return {
        menuController: {
            isOpen: isOpen,
            handleToggleMenu: function () { return setIsOpen(function (v) { return !v; }); },
        },
        anchorElementAriaProps: {
            'aria-controls': isOpen ? menuId : undefined,
            'aria-haspopup': 'true',
            'aria-expanded': isOpen ? 'true' : undefined,
        },
        menuProps: {
            id: menuId,
            onClose: function () { return setIsOpen(false); },
            open: isOpen,
        },
    };
};

import { CircularProgress } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { getSystemName } from 'Designer/hooks/useSystemName'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { permissionsSelectors } from 'ducks/permissions'
import { Alert, ReceiptLinesOutlineIcon } from 'opensolar-ui'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import { convertUTCDate } from 'projectSections/sections/info/installation'
import SolarOutletCTA from 'projectSections/sections/info/solarOutlet'
import { autoAdvanceSoldProject, getAutoAdvanceSoldLabel } from 'projectSections/utils/autoAdvanceStage'
import { BooleanInput } from 'ra-ui-materialui'
import React, { ChangeEvent, useEffect, useMemo } from 'react'
import { SelectInput, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { useDateFormatter } from 'util/date'
import { dateParserYYYYMMDD, getCurrentDate } from 'util/misc'
import { useFeatureFlag } from 'util/split'
import StyledDateInput from '../common/StyledDateInput'
import { getProjectSectionStyles } from '../styles'
import { InputContainer, InputRow, useStyles } from './styles'

const SaleAccordion: React.FC = (props) => {
  const translate = useTranslate()
  const sectionStyles = getProjectSectionStyles()
  const { allowView } = useSelector(permissionsSelectors.getProjectPermissionByKey('info_sale'))
  if (!allowView) {
    return null
  }
  return (
    <AccordionCard
      name={'sale'}
      elevation={0}
      title={translate('Sale')}
      className={sectionStyles.accordionReskin}
      titleIcon={ReceiptLinesOutlineIcon}
      content={SaleContent}
      lazyRender={true}
    />
  )
}

const SaleContent = (props) => {
  const form = useForm()
  const formState = useFormState()
  const classes = useStyles()
  const translate = useTranslate()
  const formatter = useDateFormatter()
  const project = formState.values
  const sectionStyles = getProjectSectionStyles()
  const orgId = useSelector(authSelectors.getOrgId)
  const orgWorkflows = useSelector(orgSelectors.getWorkflows)
  const enableSolarOutlet = useSelector(orgSelectors.getEnableOutlet)
  const enableNewHardwareOrdering = useFeatureFlag('hardware_ordering', 'on')
  const { allowEdit } = useSelector(permissionsSelectors.getProjectPermissionByKey('info_sale'))
  const contractDate = useMemo(() => {
    if (project.contract_date) {
      return formatter(convertUTCDate(project.contract_date)).split(' ')[0]
    }
  }, [project.contract_date])
  const systemSold = useMemo(() => project.system_sold, [project.system_sold])
  const systems = useMemo(() => {
    let getSystems: any = []
    //title and payment option details are not available in project form > systems values
    window.editor.getSystems()?.forEach((sys) => {
      const getSystem = project?.systems?.find((x) => x.uuid === sys.uuid)
      if (getSystem) {
        getSystems.push({ ...sys, url: getSystem.url, module_quantity: sys.moduleQuantity() })
      }
    })
    return getSystems
  }, [window.editor.getSystems()?.length, project.systems])
  const availablePaymentOptions = useMemo(() => {
    if (project.system_sold) {
      const selectedSystem = systems.find((x) => x.url === project.system_sold)
      if (selectedSystem) {
        return selectedSystem.payment_options?.map((x) => ({
          ...x,
          url: `${window.API_BASE_URL_ABSOLUTE}orgs/${orgId}/payment_options/${x.id}/`,
        }))
      }
    }
    return undefined
  }, [project.system_sold])

  const soldAutoToggle = (e, dateVal = false) => {
    const value = dateVal ? e : e?.target?.value
    if (project.project_sold === 1 && value) {
      form.mutators.updateField('project_sold', 2)
      if (!project.contract_date && !dateVal) form.change('contract_date', getCurrentDate())
    }
  }
  const logMarkedPmtAsSold = (e: ChangeEvent<HTMLInputElement>) => {
    soldAutoToggle(e)
    const selectedURL = e?.target?.value
    let selectedName = 'none'
    if (selectedURL && availablePaymentOptions) {
      const selectedOption = availablePaymentOptions.find((x: any) => x.url === selectedURL)
      if (selectedOption) selectedName = selectedOption?.title
    }
    logAmplitudeEvent('payment_option_manually_marked_sold', {
      project_id: project?.id,
      payment_option_title: selectedName,
    })
  }
  const toggleLabel = useMemo(() => {
    if (project.contract_date && project.project_sold !== null) {
      if (project.project_sold === 1) {
        return translate('This project was marked sold on %{contract_date} through project workflow automation.', {
          contract_date: contractDate,
        })
      } else {
        return translate('This project was marked sold on %{contract_date}.', {
          contract_date: contractDate,
        })
      }
    } else if (project.project_sold === 1) {
      return translate('This project was marked sold through project workflow automation.')
    }
  }, [project.contract_date, project.project_sold])

  const toggleAutoAdvanceSoldLabel = useMemo(() => {
    return getAutoAdvanceSoldLabel(project, orgId, orgWorkflows, formState, translate)
  }, [project.project_sold, formState.initialValues])

  useEffect(() => {
    autoAdvanceSoldProject(project, orgId, orgWorkflows, formState, form)
  }, [project.project_sold])

  const disableFields = useMemo(() => {
    const currentWorkflow = orgWorkflows.find((e) => e.id === project.workflow.workflow_id)
    const currentStage = currentWorkflow?.workflow_stages.find((e) => e.id === project.workflow.active_stage_id)
    if (currentStage?.milestone === 4) {
      return true
    }
  }, [project.workflow.active_stage_id])

  return (
    <InputContainer>
      <div className={classes.blueWrapper}>
        <div className={sectionStyles.row}>
          <BooleanInput
            value={project.project_sold !== null}
            options={{ color: 'primary' }}
            source="project_sold"
            label={false}
            onChange={(value) => {
              if (value === true) {
                form.change('project_sold', 2)
                if (!project.contract_date) form.change('contract_date', getCurrentDate())
              } else {
                form.change('project_sold', null)
                form.change('contract_date', null)
                form.change('system_sold', null)
                form.change('payment_option_sold', null)
              }
            }}
            disabled={disableFields || !allowEdit}
          />
          <span style={{ marginLeft: 20 }}>{translate('Sold')}</span>
        </div>
        {toggleLabel && (
          <div style={{ marginTop: 10 }}>
            <span>{toggleLabel}</span>
          </div>
        )}
        {toggleAutoAdvanceSoldLabel && (
          <div style={{ marginTop: 10 }}>
            <Alert severity="info">
              <span>{toggleAutoAdvanceSoldLabel}</span>
            </Alert>
          </div>
        )}
      </div>
      <InputRow>
        <StyledDateInput
          label={'Contract Date'}
          inputVariant="outlined"
          source={'contract_date'}
          parse={dateParserYYYYMMDD}
          onChange={(value) => {
            soldAutoToggle(value, true)
          }}
          disabled={disableFields || !allowEdit}
        />
        {!!systems?.length ? (
          <SelectInput
            allowEmpty
            label={'System Sold'}
            choices={systems}
            onChange={soldAutoToggle}
            optionValue="url"
            sort={{ field: 'order', order: 'ASC' }}
            optionText={(choice: any) => getSystemName(choice, translate)}
            resettable={true}
            source={'system_sold'}
            variant="outlined"
            disabled={disableFields || !allowEdit}
            fullWidth
          />
        ) : (
          <div>
            <CircularProgress className={classes.loading} size={18} />
          </div>
        )}
        {systemSold && availablePaymentOptions ? (
          <>
            {!!availablePaymentOptions?.length ? (
              <SelectInput
                allowEmpty
                label={'Payment Option Sold'}
                choices={availablePaymentOptions}
                onChange={logMarkedPmtAsSold}
                optionValue="url"
                sort={{ field: 'order', order: 'ASC' }}
                optionText={'title'}
                resettable={true}
                source={'payment_option_sold'}
                variant="outlined"
                disabled={disableFields || !allowEdit}
                fullWidth
              />
            ) : (
              <div>
                <CircularProgress className={classes.loading} size={18} />
              </div>
            )}
          </>
        ) : (
          <SelectInput
            allowEmpty
            disabled={true}
            options={{ helperText: translate('Please select a System Sold to load the Payment Options') }}
            label={'Payment Option Sold'}
            choices={[]}
            sort={{ field: 'order', order: 'ASC' }}
            resettable={true}
            source={'payment_option_sold'}
            variant="outlined"
            fullWidth
          />
        )}
      </InputRow>

      {enableSolarOutlet && !enableNewHardwareOrdering && (
        <div>
          <h3 className={classes.sectionHeader}>Solar Outlet</h3>
          <p>{translate('Get your equipment ordered 10x faster at no extra cost')}</p>
          <div className={sectionStyles.column}>
            <div className={classes.solarOutlet}>
              <SolarOutletCTA disabled={!systemSold || !allowEdit} />
              {!systemSold && <p className="subtext">{translate('Available once a sold system is saved')}</p>}
            </div>
          </div>
        </div>
      )}
    </InputContainer>
  )
}

export default SaleAccordion

const FlushIcon = (props: { highlight: boolean }) => {
  const neutralColorHex1 = '#C7CAD1'
  const neutralColorHex2 = '#9499A5'
  const highlightColorHex = '#4272DD'

  return (
    <svg width="65" height="25" viewBox="0 0 65 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M57.8353 24.3467L5.67532 10.3704"
        stroke={props.highlight ? highlightColorHex : neutralColorHex1}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1.5 1.5"
      />
      <path
        d="M50.3067 17.6704L15.0504 8.22351"
        stroke={props.highlight ? highlightColorHex : neutralColorHex2}
        strokeWidth="3.74"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default FlushIcon

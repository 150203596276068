var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import useResizeObserver from '@react-hook/resize-observer';
import { createElement, useCallback, useRef, useState } from 'react';
var useStyles = makeStyles({
    appFooter: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 2000,
        width: '100%',
    },
    footerPadding: {},
});
export var LayoutFooter = function (_a) {
    var bottomNavigation = _a.bottomNavigation;
    var classes = useStyles();
    var footerRef = useRef(null);
    var _b = useState(0), footerHeight = _b[0], setFooterHeight = _b[1];
    var target = footerRef.current;
    var checkSize = useCallback(function () {
        if (!target)
            return;
        var bounds = target.getBoundingClientRect();
        setFooterHeight(bounds.height);
    }, [target]);
    useResizeObserver(footerRef, checkSize);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: classes.footerPadding, style: { height: footerHeight } }), _jsxs("div", __assign({ id: "app-footer", className: classes.appFooter, ref: footerRef }, { children: [_jsx("div", { id: "secondary-footer" }), bottomNavigation && createElement(bottomNavigation)] }))] }));
};

import {
  Box,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import moment from 'moment'
import React, { CSSProperties } from 'react'
import { Show, SimpleShowLayout, TopToolbar, useLocale } from 'react-admin'
import { useTaxIdentifierLabel } from 'resources/wallet/useTaxIdentifierLabel'
import { AccessRightsType } from 'types/global'
import { currencySymbolForCurrencyCode, formatCurrencyWithSymbol, taxNameForCountryIso2 } from '../../util/misc'
import { useTransactionTaxInfo } from './useTransactionTaxInfo'

type PropTypes = {
  accessRights: AccessRightsType
  record: unknown
}

const useStyles = makeStyles({
  wrapper: { marginTop: '1rem' },
})
const hiddenTable: CSSProperties = {
  border: 'none',
  minWidth: '250px',
}
const table: CSSProperties = {
  ...hiddenTable,
  border: '1px solid',
  borderCollapse: 'collapse',
}
const hiddenCell: CSSProperties = {
  padding: '8px 12px',
  minWidth: '120px',
}
const thAndtdLeftAlight: CSSProperties = {
  ...hiddenCell,
  borderStyle: 'outset',
  borderCollapse: 'collapse',
  textAlign: 'left',
}
const thAndtdRightAlight: CSSProperties = {
  ...hiddenCell,
  borderStyle: 'outset',
  borderCollapse: 'collapse',
  textAlign: 'right',
}

const getValueBaseonTransactionType = (value, type) => {
  if (type === 'gift' || type === 'reversal' || type === 'refund') {
    return -Math.abs(value)
  } else {
    return Math.abs(value)
  }
}

const Subtotal = ({ record }) => {
  const locale = useLocale()
  const countryIso2 = record.org.country_iso2
  const tax_percentage = Math.round((100.0 * record.tax_combined) / (record.amount_inc_tax - record.tax_combined))
  const taxName = taxNameForCountryIso2(countryIso2).toUpperCase()

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div>{useTransactionTaxInfo(countryIso2)}</div>
      <table style={table}>
        <tr>
          <td style={thAndtdLeftAlight}>Subtotal</td>
          <td style={thAndtdRightAlight}>
            {formatCurrencyWithSymbol(
              getValueBaseonTransactionType(record.amount_ex_tax, record.transaction_type),
              currencySymbolForCurrencyCode(record.currency),
              locale,
              2
            )}
          </td>
        </tr>
        <tr>
          <td style={thAndtdLeftAlight}>
            {taxName} {tax_percentage || 0}%
          </td>
          <td style={thAndtdRightAlight}>
            {formatCurrencyWithSymbol(
              getValueBaseonTransactionType(record.tax_combined, record.transaction_type),
              currencySymbolForCurrencyCode(record.currency),
              locale,
              2
            )}
          </td>
        </tr>
        <tr style={{ backgroundColor: '#F5F5F5' }}>
          <td style={thAndtdLeftAlight}>Total</td>
          <td style={thAndtdRightAlight}>
            {formatCurrencyWithSymbol(
              getValueBaseonTransactionType(record.amount_inc_tax, record.transaction_type),
              record.currency,
              locale,
              2
            )}
          </td>
        </tr>
      </table>
    </Box>
  )
}

const TaxIdentifier = ({ record }) => {
  const countryIso2 = record.org.country_iso2
  const tax_identifier_label = useTaxIdentifierLabel(record.currency, countryIso2)

  return record.tax_identifier ? (
    <>
      {tax_identifier_label}: {record.tax_identifier}
    </>
  ) : (
    <></>
  )
}

const PaidAmount = ({ record }) => {
  const locale = useLocale()
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <table style={hiddenTable}>
          <tr>
            <td style={hiddenCell}>&nbsp;</td>
          </tr>

          <tr>
            <td style={hiddenCell}>
              <TaxIdentifier record={record} />
            </td>
          </tr>
        </table>
      </div>
      <div>
        <table style={table}>
          <tr>
            <td style={thAndtdLeftAlight}>Paid Amount</td>
            <td style={thAndtdRightAlight}>
              {formatCurrencyWithSymbol(
                getValueBaseonTransactionType(record.amount_inc_tax, record.transaction_type),
                currencySymbolForCurrencyCode(record.currency),
                locale,
                2
              )}
            </td>
          </tr>

          <tr>
            <td style={thAndtdLeftAlight}>Balance due</td>
            <td style={thAndtdRightAlight}>
              {formatCurrencyWithSymbol(
                record.amount_inc_tax - record.amount_inc_tax,
                currencySymbolForCurrencyCode(record.currency),
                locale,
                2
              )}
            </td>
          </tr>
        </table>
      </div>
    </Box>
  )
}

const InvoiceDescription = ({ record }) => {
  const locale = useLocale()
  const lineItem = record.transaction_line_items[0]

  return (
    <>
      <TableContainer component={Paper}>
        <Table style={{ border: '1px outset', borderCollapse: 'collapse' }}>
          <TableHead>
            <TableRow style={{ backgroundColor: '#F5F5F5' }}>
              <TableCell>Description</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Unit Price</TableCell>
              <TableCell align="right">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={record.name}>
              <TableCell component="th" scope="row">
                {lineItem.product_purchased_name}{' '}
              </TableCell>
              <TableCell align="right">{lineItem.units}</TableCell>
              <TableCell align="right">
                {formatCurrencyWithSymbol(
                  getValueBaseonTransactionType(lineItem.amount_ex_tax, record.transaction_type),
                  currencySymbolForCurrencyCode(record.currency),
                  locale,
                  2
                )}
              </TableCell>
              <TableCell align="right">
                {formatCurrencyWithSymbol(
                  getValueBaseonTransactionType(lineItem.amount_ex_tax, record.transaction_type),
                  currencySymbolForCurrencyCode(record.currency),
                  locale,
                  2
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

const BillTo = ({ record }) => {
  const { org } = record
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      }}
    >
      <div>
        <strong>Bill to:</strong>
        <span style={{ marginLeft: '1rem' }}>{record.org_name}</span>
        <br />
        <span style={{ marginLeft: '3.5rem' }}>{org?.address && org.address}</span>
        <br />
        <span style={{ marginLeft: '3.5rem' }}>
          {org?.locality ? `${org.locality},` : ''} &nbsp;{org?.state && org.state}&nbsp;{org?.zip && org.zip}
        </span>
      </div>
      <table style={table}>
        <tr>
          <td style={thAndtdLeftAlight}>Invoice number:</td>
          <td style={thAndtdLeftAlight}>{record.id}</td>
        </tr>

        <tr>
          <td style={thAndtdLeftAlight}>Date:</td>
          <td style={thAndtdLeftAlight}>{moment(record.created_date).format('LL')}</td>
        </tr>
      </table>
    </Box>
  )
}

const PostShowActions = () => <TopToolbar></TopToolbar>
export const WalletTransactionEdit: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      {/*@ts-ignore*/}
      <Show actions={<PostShowActions />} {...props} title="Wallet Transaction">
        {/*@ts-ignore*/}
        <SimpleShowLayout {...props}>
          <h1>Tax Invoice</h1>
          <p>OpenSolar Pty Ltd</p>
          <p>Level 1/477 Pitt Street,</p>
          <p>Haymarket NSW 2000</p>
          <p>
            <strong>ABN:</strong> 82 621 679 632
          </p>
          <hr style={{ borderTop: '1px solid #B3B3B3', margin: 5 }} />
          {/* <Invoice /> */}
          <BillTo {...props} />
          <br />
          <InvoiceDescription {...props} />
          <br />

          <Subtotal {...props} />
          <br />
          <PaidAmount {...props} />
        </SimpleShowLayout>
      </Show>
    </div>
  )
}
export default WalletTransactionEdit

// @ts-nocheck
import {
  ClickAwayListener,
  FormControlLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Slider,
  TextField,
} from '@material-ui/core'
import { ArrowDownward, ArrowDropDown, ArrowRight, ArrowUpward } from '@material-ui/icons'
import { featureConfigSelectors } from 'ducks/featureConfig'
import moment from 'moment'
import { Checkbox } from 'opensolar-ui'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { EMPTY_FORMULA_ROW, fieldPathToDisplayMap, getDocusignLabelFromTag, pathToDjangoMap } from './constants'
import ImagePlaceholderTag from './ImagePlaceholderTag'
import PlaceholderTagFormulaLine from './PlaceholderTagFormulaLine'
import { PlaceholderWhiteListType } from './ProjectPlaceholderWizard'
import { usePlaceholderTagStyles } from './templatePlaceholderTag/placeholderTagStyles'
import { FieldType, FormatType, MathOperationType, PlaceHolderSaveConfig, PlaceholderType } from './TemplateWizard'

type PropTypes = {
  x: number
  y: number
  field_path: string
  width: number
  lines: number
  value: string
  field_key: string
  use_int_comma: boolean
  format_as_currency: boolean
  round_digits: undefined | number
  default_value: undefined | string
  math_operations: MathOperationType[]
  date_format_rule: undefined | FormatType
  removePlaceholder: (key: string) => void
  savePlaceholderChange: (key: string, path: string, config: PlaceHolderSaveConfig) => void
  aCellIsBeingDragged: boolean
  onDrag: () => void
  onDragEnd: () => void
  fieldBeingDragged: FieldType | undefined
  onFocus: (placeholders: PlaceholderType[]) => void
  focusedPlaceholders: PlaceholderType[]
  zoom: number
  declutter: boolean
  formatLastClosed: Date | undefined
  is_checkbox: boolean
}

const TemplatePlaceholderTag: React.FunctionComponent<PropTypes> = (props) => {
  const whitelistVals: PlaceholderWhiteListType | undefined = useSelector((state) =>
    featureConfigSelectors.getFeatureConfigData(state, 'placeholder_whitelist')
  )

  const getFormattedFieldTitle = () => {
    if (!props.field_path?.startsWith('popular.') && props.field_path?.includes('DJANGO_TAGS'))
      return 'Custom Django Tag'
    if (isDocusign) return getDocusignLabelFromTag(props.value)
    let pathToDisplayWithWhitelist = { ...fieldPathToDisplayMap }
    if (whitelistVals && whitelistVals.path_to_display) {
      pathToDisplayWithWhitelist = {
        ...pathToDisplayWithWhitelist,
        ...whitelistVals.path_to_display,
      }
    }
    if (pathToDisplayWithWhitelist[props.field_path]) return pathToDisplayWithWhitelist[props.field_path]
    let raw = props.field_path.split('.')[props.field_path.split('.').length - 1]
    let asWords = raw.replaceAll('_', ' ').split(' ')
    for (let i = 0, len = asWords.length; i < len; i++) {
      let word = asWords[i]
      word = word.replace(word.charAt(0), word.charAt(0).toUpperCase())
      asWords[i] = word
    }
    let label = asWords.join(' ')
    if (label?.includes('DJANGO TAGS ')) label = label.replace('DJANGO TAGS ', '')
    return label
  }

  const [x, setX] = useState<number>(props.x)
  const [y, setY] = useState<number>(props.y)
  const [dragStartX, setDragStartX] = useState<number | undefined>(undefined)
  const [dragStartY, setDragStartY] = useState<number | undefined>(undefined)
  const [showFormat, setShowFormat] = useState<boolean>(false)
  const [isDocusign, setIsDocusign] = useState<boolean>(props.field_path?.includes('docusign.'))
  const [fontSize, setFontSize] = useState<number>(props.font_size || 9)
  const [width, setWidth] = useState<number>(props.width || 200)
  const [lines, setLines] = useState<number>(props.lines || 1)
  const [value, setValue] = useState<string>(props.value)
  const [useIntComma, setUseIntComma] = useState<boolean>(props.use_int_comma)
  const [formatAsCurrency, setFormatAsCurrency] = useState<boolean>(props.format_as_currency)
  const [roundDigits, setRoundDigits] = useState<number | undefined>(props.round_digits)
  const [formulaEnabled, setFormulaEnabled] = useState<boolean>(props.math_operations?.length > 0)
  const [formulaRows, setFormulaRows] = useState<MathOperationType[]>(props.math_operations)
  const [defaultValue, setDefaultValue] = useState<string | undefined>(props.default_value)
  const [isDate, setIsDate] = useState<boolean>(false)
  const [dateFormatRule, setDateFormatRule] = useState<undefined | FormatType>(props.date_format_rule)
  const [anchorEl, setAnchorEl] = useState(null)
  const [title, setTitle] = useState<string>(getFormattedFieldTitle())
  const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const [isDjango, setIsDjango] = useState<boolean>(
    props.field_path?.includes('DJANGO_TAGS') && !props.field_path?.includes('popular.DJANGO_TAGS')
  )
  const [isCheckbox, setIsCheckbox] = useState<boolean>(!!props.is_checkbox)
  const [isImage, setIsImage] = useState<boolean>(props.field_path?.startsWith('images.'))

  const classes = usePlaceholderTagStyles({
    x,
    y,
    zoom: props.zoom,
    declutter: props.declutter,
    aCellIsBeingDragged: props.aCellIsBeingDragged,
    isFocused,
    showFormat,
    width,
    height: props.zoom * (Math.ceil(lines * fontSize) + 4),
    fontSize: fontSize,
    isImage: false,
  })

  useEffect(() => {
    handleValueChange(props.value)
  }, [props.value])

  useEffect(() => {
    if (props.formatLastClosed) {
      if (showFormat) setShowFormat(false)
    }
  }, [props.formatLastClosed])

  useEffect(() => {
    if (props.focusedPlaceholders?.length > 0) {
      props.focusedPlaceholders?.forEach((placeholder) => {
        if (!isFocused && placeholder.field_key === props.field_key) setIsFocused(true)
        else if (isFocused) setIsFocused(false)
      })
    }
  }, [props.focusedPlaceholders])

  const translate = useTranslate()

  useEffect(() => {
    setTitle(getFormattedFieldTitle())
  }, [props.field_path])

  useEffect(() => {
    if (props.field_path?.includes('DJANGO_TAGS') && !defaultValue) {
      let mergedMap = { ...pathToDjangoMap, ...whitelistVals?.path_to_django }
      if (mergedMap[props.field_path]) {
        setDefaultValue(mergedMap[props.field_path])
      }
    }
  }, [props.field_path, defaultValue])

  useEffect(() => {
    if (props.value) {
      try {
        // check if this is a formatted date string passed from back-end in "1st March 2022" format. If true, save in state otherwise leave it as is
        const isValid =
          moment(props.value, 'Do MMMM YYYY', true).isValid() || moment(props.value, 'MMM DD YYYY', true).isValid()
        if (isValid) setIsDate(true)
      } catch (ex) {}
    }
  }, [props.value])

  useEffect(() => {
    if (isInitialized && props.font_size && props.font_size !== fontSize) {
      setFontSize(props.font_size)
    }
  }, [props.font_size, isInitialized])

  useEffect(() => {
    if (isInitialized) {
      let config: PlaceHolderSaveConfig = {
        x,
        y,
        font_size: fontSize,
        width,
        use_int_comma: useIntComma,
        format_as_currency: formatAsCurrency,
        default_value: defaultValue,
        lines,
        round_digits: roundDigits,
        math_operations: formulaRows,
        date_format_rule: dateFormatRule,
        is_checkbox: isCheckbox,
      }
      props.savePlaceholderChange(props.field_key, props.field_path, config)
    } else {
      setIsInitialized(true)
    }
  }, [
    lines,
    fontSize,
    width,
    isInitialized,
    x,
    y,
    useIntComma,
    roundDigits,
    defaultValue,
    formatAsCurrency,
    formulaRows,
    dateFormatRule,
    isCheckbox,
  ])

  useEffect(() => {
    if (formulaEnabled && !formulaRows) setFormulaRows([EMPTY_FORMULA_ROW])
    else if (formulaRows?.length > 0 && !formulaEnabled) setFormulaRows([])
  }, [formulaEnabled])

  const menuOpen = Boolean(anchorEl)

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const doDelete = () => {
    props.removePlaceholder(props.field_key)
  }

  const dragStart = (e) => {
    setDragStartX(e.clientX)
    setDragStartY(e.clientY)
    props.onDrag()
  }

  const dragEnd = (e) => {
    const midDragX = e.clientX
    const midDragY = e.clientY
    if (dragStartX === undefined || dragStartY === undefined) return
    if (midDragX < 0 || midDragY < 0) {
      doDelete()
    } else {
      if (midDragX) {
        let relativeX = dragStartX - midDragX
        setX(x - relativeX)
      }
      if (midDragY) {
        let relativeY = dragStartY - midDragY
        setY(y - relativeY)
      }
    }
    setDragStartX(undefined)
    setDragStartY(undefined)
    props.onDragEnd()
  }

  const toggleFormat = () => {
    setShowFormat(!showFormat)
    handleMenuClose()
  }

  const changeWidth = (e: ChangeEvent, newVal: string) => {
    if (isNaN(parseInt(newVal))) setWidth(100)
    else setWidth(parseInt(newVal))
  }

  const updateRow = (newVal: MathOperationType, i: number) => {
    let newRows = formulaRows?.map((existingRow, n) => {
      if (i === n) existingRow = newVal
      return existingRow
    })
    setFormulaRows(newRows)
  }

  const removeRow = (rowIndex: number) => {
    if (formulaRows.length === 1) setFormulaEnabled(false)
    setFormulaRows(formulaRows?.filter((row, i) => i !== rowIndex))
  }
  const addRow = () => {
    if (formulaRows?.length >= 5) return
    setFormulaRows([...formulaRows, EMPTY_FORMULA_ROW])
  }

  const handleValueChange = (newVal: string) => {
    if (!props.field_path.includes('docusign.')) {
      setValue(newVal)
    }
  }

  const renderFormValue = () => {
    if (lines === 1)
      return (
        <input
          value={getRoundedFormattedValue()}
          onChange={(e) => handleValueChange(e.target.value)}
          style={{
            fontSize: `${fontSize}px`,
            border: 'none',
            backgroundColor: '#ffda00',
            width: `${width}px`,
            height: `${Math.ceil(lines * fontSize) + 4}px`,
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          }}
        />
      )
    return (
      <textarea
        value={getRoundedFormattedValue()}
        onChange={(e) => handleValueChange(e.target.value)}
        style={{
          fontSize: `${fontSize}px`,
          border: 'none',
          backgroundColor: '#ffda00',
          width: `${width}px`,
          height: `${Math.ceil(lines * fontSize) + 4}px`,
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        }}
      />
    )
  }

  const decrementFontSize = () => {
    setFontSize(Math.max(6, fontSize - 1))
  }

  const incrememtFontSize = () => {
    setFontSize(Math.min(64, fontSize + 1))
  }

  const decrementLines = () => {
    setLines(Math.max(1, lines - 1))
  }

  const incrementLines = () => {
    setLines(Math.min(20, lines + 1))
  }

  const hideFormat = (event: MouseEvent<Document, MouseEvent>) => {
    setShowFormat(false)
  }

  const handleDateFormatChange = (e: ChangeEvent) => {
    setDateFormatRule(e.target.value)
  }

  const handleIntCommaChange = (e: ChangeEvent) => {
    if (e.target.value === 'currency') {
      setUseIntComma(false)
      setFormatAsCurrency(true)
    } else if (e.target.value === 'number') {
      setUseIntComma(true)
      setFormatAsCurrency(false)
    }
  }
  const handleCurrencyChange = (e) => setFormatAsCurrency(e.target.checked)

  const incrementRoundDigits = () => {
    if (!roundDigits) setRoundDigits(1)
    else {
      if (useIntComma || formatAsCurrency) setRoundDigits(Math.min(2, roundDigits + 1))
      else setRoundDigits(Math.min(10, roundDigits + 1))
    }
  }

  const decrementRoundDigits = () => {
    if (roundDigits !== undefined) {
      setRoundDigits(Math.max(0, roundDigits - 1))
    } else setRoundDigits(0)
  }

  const handleDefaultValueChange = (e) => {
    setDefaultValue(e.target.value)
    if (!props.value) handleValueChange(e.target.value)
  }

  const formatValueAsData = (formatRule: undefined | FormatType) => {
    let asMoment = moment(props.value, 'Do MMMM YYYY', true).isValid()
      ? moment(props.value, 'Do MMMM YYYY', true)
      : moment(props.value, 'MMM DD YYYY')
    if (formatRule) {
      try {
        return asMoment.format(formatRule)
      } catch (ex) {}
    }
    return asMoment.format('MM/DD/YYYY')
  }

  const getRoundedFormattedValue = () => {
    if (isCheckbox) {
      if (value === true || value === 'true') return '✓'
      else return ' '
    }
    if (isDate) {
      return formatValueAsData(dateFormatRule)
    }
    if (props.field_path?.includes('DJANGO_TAGS')) return 'Preview unavailable'
    let valToReturn
    if (!isNaN(value) && roundDigits !== undefined && roundDigits !== false) {
      if (!useIntComma && !formatAsCurrency) valToReturn = parseFloat(value).toFixed(roundDigits)
      else {
        valToReturn = Number(parseFloat(value).toFixed(roundDigits)).toLocaleString(undefined, {
          minimumFractionDigits: roundDigits,
          maximumFractionDigits: roundDigits,
        })
      }
    } else valToReturn = value
    if (!valToReturn && defaultValue) valToReturn = defaultValue
    let isHTML = /^/.test(valToReturn)
    if (isHTML && !!valToReturn && typeof valToReturn === 'string') {
      return valToReturn?.replace('</p>', '\n').replace(/(<([^>]+)>)/gi, '')
    } else return valToReturn
  }

  const elevateOnHover = () => setIsFocused(true)
  const sinkOnBlur = () => setIsFocused(false)

  const getNumberFormatValue = () => {
    if (formatAsCurrency) return 'currency'
    if (useIntComma) return 'number'
    else return undefined
  }

  const renderFormat = () => {
    if (isDjango)
      return (
        <div className={classes.formatWrapper}>
          <span className="small">
            {translate(
              'You can edit the appearance of your placeholder using the form below. The area highlighted in yellow is what will be added to your document.'
            )}
          </span>
          <div className={classes.formatFieldWithMargin}>
            <strong>Width:</strong>
            <Slider aria-label="Width (pixels)" value={width} onChange={changeWidth} min={25} max={500} />
          </div>
          <div className={classes.formatFieldWithMargin}>
            <strong>Font Size:</strong>
            <div className={classes.inputWrapper}>
              <div className={classes.fontArrowWrapper} onClick={decrementFontSize}>
                <ArrowDownward fontSize="small" />
              </div>
              <div className={classes.unitLabel}>{`${fontSize}`} pixels</div>
              <div className={classes.fontArrowWrapper} onClick={incrememtFontSize}>
                <ArrowUpward fontSize="small" />
              </div>
            </div>
          </div>
          <div className={classes.formatFieldWithMargin}>
            <strong>Number of Lines:</strong>
            <div className={classes.inputWrapper}>
              <div className={classes.fontArrowWrapper} onClick={decrementLines}>
                <ArrowDownward fontSize="small" />
              </div>
              <div className={classes.unitLabel}>{`${lines}`} lines</div>
              <div className={classes.fontArrowWrapper} onClick={incrementLines}>
                <ArrowUpward fontSize="small" />
              </div>
            </div>
          </div>
          <h4>Enter Django Tags below</h4>
          <TextField
            value={defaultValue}
            onChange={(e) => setDefaultValue(e.target.value)}
            fullWidth
            multiline={true}
            rows={8}
          />
        </div>
      )
    else
      return (
        <div className={classes.formatWrapper}>
          <span className="small">
            {translate(
              'You can edit the appearance of your placeholder using the form below. The area highlighted in yellow is what will be added to your document.'
            )}
          </span>
          <div className={classes.formatFieldWithMargin}>
            <strong>Width:</strong>
            <Slider aria-label="Width (pixels)" value={width} onChange={changeWidth} min={25} max={500} />
          </div>
          {!isDocusign && (
            <>
              <div className={classes.formatFieldWithMargin}>
                <strong>Font Size:</strong>
                <div className={classes.inputWrapper}>
                  <div className={classes.formatInputWrapper} onClick={decrementFontSize}>
                    <ArrowDownward fontSize="small" />
                  </div>
                  <div className={classes.unitLabel}>{`${fontSize}`} pixels</div>
                  <div className={classes.formatInputWrapper} onClick={incrememtFontSize}>
                    <ArrowUpward fontSize="small" />
                  </div>
                </div>
              </div>
              <div className={classes.formatFieldWithMargin}>
                <strong>Number of Lines:</strong>
                <div className={classes.inputWrapper}>
                  <div className={classes.formatInputWrapper} onClick={decrementLines}>
                    <ArrowDownward fontSize="small" />
                  </div>
                  <div className={classes.unitLabel}>{`${lines}`} lines</div>
                  <div className={classes.formatInputWrapper} onClick={incrementLines}>
                    <ArrowUpward fontSize="small" />
                  </div>
                </div>
              </div>
              {isDate ? (
                <div className={classes.formatFieldWithMargin}>
                  <strong>Date Formatting:</strong>
                  <RadioGroup
                    onChange={handleDateFormatChange}
                    // className={classes.rowFlexStart}
                    style={{ display: 'flex', flexDirection: 'row' }}
                    value={dateFormatRule}
                  >
                    <div className={classes.col}>
                      <div className={classes.rowFlexStart}>
                        <FormControlLabel
                          value={'MM/DD/YYYY'}
                          label={formatValueAsData('MM/DD/YYYY')}
                          control={<Radio />}
                        />
                        <FormControlLabel
                          value={'MMMM Do, YYYY'}
                          label={formatValueAsData('MMMM Do, YYYY')}
                          control={<Radio />}
                        />
                      </div>
                      <div className={classes.rowFlexStart}>
                        <FormControlLabel
                          value={'DD/MM/YYYY'}
                          label={formatValueAsData('DD/MM/YYYY')}
                          control={<Radio />}
                        />
                        <FormControlLabel
                          value={'dddd MMMM Do YYYY'}
                          label={formatValueAsData('dddd MMMM Do YYYY')}
                          control={<Radio />}
                        />
                      </div>
                    </div>
                  </RadioGroup>
                </div>
              ) : (
                <>
                  <div className={classes.formatFieldWithMargin}>
                    <strong>Rounding:</strong>
                    <div className={classes.inputWrapper}>
                      <div className={classes.formatInputWrapper} onClick={decrementRoundDigits}>
                        <ArrowDownward fontSize="small" />
                      </div>
                      <div className={classes.unitLabel}>
                        {roundDigits === undefined ? (
                          <span>{translate('Click an arrow to enable rounding')}</span>
                        ) : (
                          <span>
                            Round to {`${roundDigits}`} decimal {roundDigits === 1 ? 'place' : 'places'}
                          </span>
                        )}
                      </div>
                      <div className={classes.formatInputWrapper} onClick={incrementRoundDigits}>
                        <ArrowUpward fontSize="small" />
                      </div>
                    </div>
                  </div>
                  <h4>{translate('Number Formatting')}</h4>
                  <RadioGroup
                    onChange={handleIntCommaChange}
                    style={{ display: 'flex', flexDirection: 'row' }}
                    value={getNumberFormatValue()}
                  >
                    <FormControlLabel label={'Number'} value="number" control={<Radio />} />
                    <FormControlLabel label={'Currency'} value="currency" control={<Radio />} />
                  </RadioGroup>
                </>
              )}
              <div className={classes.inputWrapper}>
                <div className={classes.col}>
                  <strong>{translate('Enable Formula')}</strong>
                  {!formulaEnabled && (
                    <Checkbox onChange={() => setFormulaEnabled(!formulaEnabled)} value={formulaEnabled} />
                  )}
                  {formulaEnabled && (
                    <span className="small">
                      {translate(
                        'The value above will not change as you build out the formula. You can check the value by generating this document on a project.'
                      )}
                    </span>
                  )}
                  {formulaEnabled &&
                    formulaRows?.map((row, rowIndex) => (
                      <PlaceholderTagFormulaLine
                        key={rowIndex}
                        row={row}
                        rowIndex={rowIndex}
                        fieldBeingDragged={props.fieldBeingDragged}
                        updateRow={updateRow}
                        addRow={addRow}
                        removeRow={removeRow}
                      />
                    ))}
                </div>
              </div>
              <div className={classes.inputWrapper}>
                <strong>{translate('Is Checkbox')}</strong>
                <Checkbox onChange={() => setIsCheckbox(!isCheckbox)} checked={isCheckbox} />
              </div>
              <div className={classes.inputWrapper}>
                <strong>{translate('Default Value when Blank')}:</strong>
                <TextField value={defaultValue} onChange={handleDefaultValueChange} fullWidth />
              </div>
            </>
          )}
        </div>
      )
  }

  const onFocus = (e: Event) => {
    e.stopPropagation()
    props.onFocus([props])
  }

  if (isImage) {
    return <ImagePlaceholderTag {...props} />
  } else
    return (
      <ClickAwayListener onClickAway={hideFormat}>
        <div className={classes.mainWrapper} onMouseEnter={elevateOnHover} onMouseLeave={sinkOnBlur} onClick={onFocus}>
          <span className={classes.title}>{title}</span>
          <div className={classes.contentWrapper}>
            <div className={classes.dragTarget} draggable={true} onDragStart={dragStart} onDragEnd={dragEnd}>
              <div className={classes.valueWrapper}>
                {!showFormat ? <span>{getRoundedFormattedValue()}</span> : renderFormValue()}
              </div>
              <div className={classes.dimensionsDiv}></div>
              {!props.declutter && (
                <>
                  <div onClick={handleMenuClick} className={classes.dropdownWrapper}>
                    {menuOpen ? <ArrowRight /> : <ArrowDropDown />}
                  </div>
                  <Menu
                    id="demo-simple-select-autowidth"
                    style={{ marginLeft: '20px' }}
                    open={menuOpen}
                    onClose={handleMenuClose}
                    anchorEl={anchorEl}
                  >
                    {(!isDocusign || props.field_path?.includes('docusign.text')) && (
                      <MenuItem onClick={toggleFormat}>Format</MenuItem>
                    )}
                    <MenuItem onClick={doDelete} style={{ color: 'red' }}>
                      Delete
                    </MenuItem>
                  </Menu>
                </>
              )}
            </div>
            {showFormat && renderFormat()}
          </div>
        </div>
      </ClickAwayListener>
    )
}
export default React.memo(TemplatePlaceholderTag)

import * as Sentry from '@sentry/react'

export const handleAddComponent = (type: string, uuid?: string, params?: any) => {
  var target = uuid ? window.editor.objectByUuid(uuid) : undefined
  // var commandUUID = window.Utils.generateCommandUUIDOrUseGlobal()

  if (uuid && !target) {
    Sentry.captureException(
      new Error(`Unable to find target parent object with uuid=${uuid}. Abort handleAddComponent.`)
    )
    return
  }

  switch (type) {
    case 'inverter':
      window.editor.execute(
        new window.AddObjectCommand(
          new OsInverter({ inverter_id: params.inverterId || window.AccountHelper.getDefaultInverterId() }),
          target,
          false
        )
      )
      break
    case 'mppt':
      var newMppt = new OsMppt()
      window.editor.execute(new window.AddObjectCommand(newMppt, target, false))
      window.editor.execute(new window.AddObjectCommand(new OsString(), newMppt, true))
      break
    case 'string':
      window.editor.execute(new window.AddObjectCommand(new OsString(), target, true))
      break
    case 'moduleGrid':
      window.editor.controllers['AddObject'].start('OsModuleGrid')
      break
    case 'battery':
      window.editor.execute(
        new window.AddObjectCommand(
          new OsBattery({ battery_id: params.batteryId || window.AccountHelper.getDefaultBatteryId() }),
          target,
          false
        )
      )
      break
    case 'other':
      var otherComponentId = params?.otherId
      window.editor.execute(
        new window.AddObjectCommand(
          new window.OsOther({ other_id: otherComponentId, ...(params || {}) }),
          target,
          false
        )
      )
      break
    default:
    //
  }
}

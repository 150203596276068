import type { QUERY_ORDER } from 'elements/controller/query/query'
import type { LineItemLoadedDataType } from 'pages/ordering/type'
import type { Identifier, RecordMap } from 'react-admin'
import { GET_LIST, useGetList, useNotify } from 'react-admin'

/*
The basic hook for fetching hardware components data from endpoint /api/components/
  @page:
    page for pagination start from 1
  @perPage:
    number of items per page
  @sort:
    sort by which model field 
  @order:
    either SORT_DESC or SORT_ASC
  @resource:
    resource for hardware components. Note: components___aliases could be used for avoid overriding the redux data
  @filterValues:
    other filters that will be convert to query params
*/

const useOrderComponentsListBasic = ({
  page,
  perPage,
  sort,
  order,
  resource,
  filterValues,
}: {
  page: number
  perPage: number
  sort: string
  order: QUERY_ORDER
  resource: string
  filterValues: { [key: string]: unknown }
}): {
  data?: RecordMap<LineItemLoadedDataType>
  ids?: Identifier[]
  total?: number
  error?: any
  loading: boolean
  loaded: boolean
} => {
  const notify = useNotify()

  const { data, ids, total, loading, loaded } = useGetList<LineItemLoadedDataType>(
    resource,
    {
      page,
      perPage,
    },
    { field: sort, order },
    {
      ...filterValues,
    },
    {
      action: GET_LIST,
      onFailure: (error) =>
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning'),
    }
  )

  return { data, ids, total, loading, loaded }
}

export default useOrderComponentsListBasic

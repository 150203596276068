// @ts-nocheck
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined'
import { Button } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import useTranslateParse from '../../../util/useTranslateParse'
import { ToolTipPropsType, TourConfigType } from '../types'
import DefaultTooltip from './DefaultToolTip'

const Tooltip = (props: ToolTipPropsType) => {
  const { handleNext, handlePrev, handleCancel } = props
  const translate = useTranslate()
  const translateParse = useTranslateParse()
  return (
    <DefaultTooltip
      title={translate('Design System')}
      // onClose={handleCancel}
      icon={LayersOutlinedIcon}
      content={
        <div>
          <div>
            {translate("Now that you have entered the customer contact information, let's design the solar system.")}
          </div>
          <div>
            {translateParse('Click <strong>Design<strong> to proceed.', {
              strong: (label: string) => <strong>{label}</strong>,
            })}
          </div>
        </div>
      }
      actions={
        <>
          <Button
            onClick={() => handlePrev && handlePrev()}
            onTouchStart={(e) => {
              e.stopPropagation()
              if (handlePrev) {
                handlePrev()
              }
            }}
          >
            <span>{translate('Back')}</span>
          </Button>
          <Button disabled={true}>
            <span>{translate('Next')}</span>
          </Button>
        </>
      }
      arrowPlacement={'top'}
    />
  )
}

const ClickDesign: TourConfigType = {
  spotlights: [{ targetId: 'subheader-design', disableBackgroundScroll: true, padding: 5 }],
  tooltips: [{ targetId: 'subheader-design', placement: 'bottom', component: Tooltip }],
}

export default ClickDesign

const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/

export const validateHexColor = (value) => {
  if (value) {
    if (!hexColorRegex.test(value)) {
      return 'Please choose from colour picker'
    }
  }
}

export const validateOrgContentEdit = (translate, values) => {
  const errors = {}
  if (values.sales_phone_number) {
    const phoneRegex = /^$|^(\(?\+?[0-9]*\)?)?[0-9_\- ()]*$/
    if (!phoneRegex.test(values.sales_phone_number)) {
      errors.sales_phone_number = [translate('Wrong phone format')]
    }
  }
  if (values.color_highlight) {
    if (validateHexColor(values.color_highlight)) {
      errors.color_highlight = [translate(validateHexColor(values.color_highlight))]
    }
  }
  return errors
}

export const validateOrgEdit = (values) => {
  const errors = {}
  if (values.offline_surcharge_percentage < 0 || values.offline_surcharge_percentage > 100) {
    errors.offline_surcharge_percentage = ['min: "0", max: "100" ']
  }
  return errors
}

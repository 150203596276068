import { Grid } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import inflection from 'inflection'
import React from 'react'
import type { OsValidityType } from 'Themes'
import { useOpenSolarTheme } from 'themes/useOpenSolarTheme'
import type { InventoryType } from '../../type'
import useTableStyles from '../useTableStyles'

const mapValidityType = (validity: InventoryType['validity']): OsValidityType => {
  switch (validity) {
    case 'available':
      return 'valid'
    case 'not_forecast':
      return 'default'
    case 'not_available':
      return 'error'
    case 'below_min':
      return 'warning'
  }
}

const InventoryListTableRow = ({
  inventory,
  editMinIcon,
  editOnHandIcon,
  handleAvailableIconClicked,
}: {
  inventory: InventoryType
  editMinIcon?: React.ReactNode
  editOnHandIcon?: React.ReactNode
  handleAvailableIconClicked({ code }: { code: string }): void
}) => {
  const classes = useTableStyles()
  const theme = useOpenSolarTheme()
  const [minFieldHover, setMinFieldHover] = React.useState(false)
  const [onHandFieldHover, setOnHandFieldHover] = React.useState(false)
  const componentType = inventory.component_type
  const validity = mapValidityType(inventory.validity)

  return (
    <Grid container xs={12} alignItems={'center'} justify={'center'} className={classes.rowWrapper}>
      <Grid item xs={1}>
        {componentType && inflection.capitalize(componentType)}
      </Grid>
      <Grid item xs={4}>
        {inventory.code}
      </Grid>
      <Grid
        item
        xs={1}
        component={'div'}
        onMouseEnter={() => setMinFieldHover(true)}
        onMouseLeave={() => setMinFieldHover(false)}
      >
        <div className={classes.gridItem}>
          <div style={{ color: '#7f7d83' }}>{inventory.reorder_quantity}</div>
          {minFieldHover && editMinIcon}
        </div>
      </Grid>
      <Grid
        item
        xs={1}
        component={'div'}
        onMouseEnter={() => setOnHandFieldHover(true)}
        onMouseLeave={() => setOnHandFieldHover(false)}
      >
        <div className={classes.gridItem}>
          {inventory.on_hand}
          {onHandFieldHover && editOnHandIcon}
        </div>
      </Grid>
      <Grid item xs={1}>
        <div className={classes.gridItem}>{inventory.incoming}</div>
      </Grid>
      <Grid item xs={1}>
        <div className={classes.gridItem}>{inventory.allocated}</div>
      </Grid>
      <Grid item xs={1}>
        <div className={classes.gridItem}>
          <div>{inventory.available}</div>
          <InfoOutlinedIcon
            onClick={() => handleAvailableIconClicked({ code: inventory.code })}
            // TODO: move to classes
            style={{ cursor: 'pointer', color: theme.ValidityPalette[validity].color }}
          />
        </div>
      </Grid>
      <Grid item xs={2}>
        {/* Remove this due to the shift towards a multi-distributor*/}
        {/* {loadingStock ? (
          <Skeleton />
        ) : hasStock ? (
          <div className={classes.gridItem}>
            <IconButton
              onClick={() => {
                history.push(`/shop/product/${encodeURIComponent(inventory.code)}`)
                logAmplitudeEvent('generic_button_clicked', {
                  source: 'view_catalogue',
                  context: 'inventory_list_view_catalogue',
                })
              }}
            >
              <ExitToAppOutlinedIcon />
            </IconButton>
          </div>
        ) : (
          <div className={classes.gridItem}>Item not stocked</div>
        )} */}
      </Grid>
    </Grid>
  )
}

export default InventoryListTableRow

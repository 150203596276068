import { authReload, AUTH_RELOAD_FAILURE } from 'actions/authActions'
import { setMapTypeBlockingList } from 'actions/designer/detectImageryActions'
import { authSelectors } from 'ducks/auth'
import { GET_ORG, GET_ORG_FAIL, GET_ORG_SUCCESS } from 'ducks/orgs'
import { setBlockedPermissions } from 'ducks/permissions'
import { viewModeActions } from 'ducks/viewMode'
import { FormApi } from 'final-form'
import lodash from 'lodash'
import { OSSDK, Route } from 'opensolar-sdk'
import { useEffect, useMemo, useState } from 'react'
import { USER_LOGOUT } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import appStorage from 'storage/appStorage'
import { RootState } from 'types/state'
import { usePreferenceBindings } from './bindings/usePreferenceBindings'
import { createOrUpdateProject } from './flows/createOrUpdateProject'
import { generateDocument } from './flows/generateDocument'
import { bindCrud } from './utils/bindCrud'
import { bindSignal } from './utils/bindSignal'
import { promsifyReduxSaga } from './utils/promsifyReduxSaga'
import { useBindReactAdminForm } from './utils/useBindReactAdminForm'
import { useBindSelector } from './utils/useBindSelector'

const STUDIO_SIGNAL_NAMES = [
  'windowResize',
  'objectSelected',
  'objectAdded',
  'objectChanged',
  'objectRemoved',
  'sceneLoaded',
  'queueProcessed',
]

// Wildcards disabled for now until we verify they are safe from jailbrake/exfiltration/esecalation/DOS/etc.
const FUNCTIONS_PERMITTED = [
  'MapHelper.activeMapInstance.toMapData',
  'ViewHelper.selectedView',
  'ViewHelper.animateToLonLat',
  'ViewHelper.toggleShade',
  'ViewHelper.animateToDirection',
  'ShadeHelper.generateShadeSummaryCsv',
  'ShadeHelper.downloadShadeSummaryCsv',

  'editor.scene.raytracedShadingAvailable',
  'editor.selectedSystem.raytracedShadingAvailable',

  'AccountHelper.isLoaded',

  // Disable for security
  // 'ViewHelper.*',
  // 'SceneHelper.*',
  // 'MapHelper.*',
  // 'editor.*',

  // Not using * here as I think it might introduce security issues (e.g. editor.extensions.DxfExporter.editorInstance.getSecretToken())
  'editor.extensions.DxfExporter.isAvailable',
  'editor.extensions.DxfExporter.generate',
  'editor.extensions.DxfExporter.generateAndDownload',
]

// Wildcards disabled (not implemented) for now until we verify they are safe from jailbrake/exfiltration/esecalation/DOS/etc.
const COMMANDS_PERMITTED = [
  'AddGoogleObliquesCommand',
  'AddNodeToFacetCommand',
  'AddObjectCommand',
  'AddScriptCommand',
  'AddViewCommand',
  'DuplicateViewCommand',
  'MoveObjectCommand',
  'MultiCmdsCommand',
  'NodeMergeCommand',
  'NodeRemoveEdgeCommand',
  'RedrawModulesCommand',
  'RemoveModulesFromStringCommand',
  'RemoveNodeCommand',
  'RemoveObjectCommand',
  'RemoveScriptCommand',
  'RemoveViewCommand',
  'ReorderChildrenCommand',
  'ReorderViewsCommand',
  'SaveViewCommand',
  'SetColorCommand',
  'SetEdgeTypeCommand',
  'SetFloatingNodeCommand',
  'SetGeometryCommand',
  'SetGeometryValueCommand',
  'SetMapRotationCommand',
  'SetMapTypeCommand',
  'SetMaterialColorCommand',
  'SetMaterialCommand',
  'SetMaterialMapCommand',
  'SetMaterialValueCommand',
  'SetModuleGridActiveModulesCommand',
  'SetObjectTypeCommand',
  'SetPanelConfigurationCommand',
  'SetPositionCommand',
  'SetRotationCommand',
  'SetScaleCommand',
  'SetSceneCommand',
  'SetScriptValueCommand',
  'SetSkewCommand',
  'SetSlopesCommand',
  'SetTransformModeCommand',
  'SetUuidCommand',
  'SetValueCommand',
  'SetViewCommand',
  'SetViewValueCommand',
  'SetZoomCommand',
  'StringModuleArrayAssignmentCommand',
  'StringModuleAssignmentCommand',
  'SystemClearElectricalsCommand',
  'UpdateElectricalsCommand',
  'UpdateSceneOriginCommand',
  'UpdateSystemCalculationCommand',
  'UpdateViewCommand',
  'ZoomCameraByFactorCommand',
]

export const useSdkBindings = ({ sdk }: { sdk: OSSDK }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const active = useMemo(() => sdk.isMaster, [sdk.isMaster])
  const [projectForm, setProjectForm] = useState<FormApi | undefined>()
  const projectSection = useSelector((state: RootState) => state.project.section)

  const preference = usePreferenceBindings()

  useEffect(() => {
    if (!active) return

    sdk.route.route.value = location
    sdk.route.path.value = location.pathname
  }, [location, active])

  useEffect(() => {
    sdk.peers.add('ViewSetViewInfo', (msg: any) => {
      dispatch(viewModeActions.setViewOverrides(msg.overrides))
    })
  }, [])

  useEffect(() => {
    let form = window.projectForm
    setProjectForm(form)
  }, [projectSection])

  // Remove unnecessary demo signal handlers
  // useStudioSignals(() => {
  //   checkDesign()
  // }, ['objectAdded', 'objectRemoved'])

  useBindReactAdminForm(projectForm, {
    save: sdk.project_form.save,
    discard: sdk.project_form.discard,
    active: sdk.project_form.active,
    save_state: sdk.project_form.save_state,
    dirty_fields: sdk.project_form.dirty_fields,
    values: sdk.project_form.values,
    setValues: sdk.project_form.setValues,
  })

  // Remove unnecessary demo signal handlers
  // useEffect(() => {
  //   if (projectForm) {
  //     checkDesign()
  //   } else {
  //     sdk.project_form.system_count.value = 0
  //     sdk.project_form.save_state.value = 'none'
  //   }
  // }, [projectForm])

  // Remove unnecessary demo signal handlers
  // const checkDesign = useCallback(() => {
  //   const systems = window.editor.getSystems()
  //   sdk.project_form.system_count.value = systems?.length || 0
  //   sdk.project_form.system_summaries.value =
  //     systems?.map((s) => ({
  //       name: s.name,
  //       module_count: s.moduleQuantity(),
  //       payment_options: s.payment_options.map((p) => ({
  //         title: p.title,
  //         net_system_cost: p.net_system_cost,
  //         payback_year: p.payback_year,
  //         utility_bill_savings_total: p.utility_bill_savings_total,
  //       })),
  //     })) || []
  // }, [])

  useEffect(() => {
    if (!active) {
      const gotoRoute = (route) => history.push(route)
      sdk.route.route.add(gotoRoute)
      return () => sdk.route.route.remove(gotoRoute)
    }

    if (sdk.resolvedConfig.permissions_block) {
      dispatch(setBlockedPermissions(sdk.resolvedConfig.permissions_block))
    }

    if (sdk.resolvedConfig.view_overrides) {
      dispatch(viewModeActions.setViewOverrides(sdk.resolvedConfig.view_overrides))
    }

    if (sdk.resolvedConfig.map_types_block) {
      dispatch(setMapTypeBlockingList(sdk.resolvedConfig.map_types_block))
    }

    let pendingRoutes: PendingRoute[] = []
    history.listen((location, action) => {
      if (!pendingRoutes.length || action !== 'PUSH') return

      for (const pending of pendingRoutes) {
        if (pending.route === location.pathname) {
          pending.resolve()
        } else {
          pending.reject()
        }
      }
      pendingRoutes = []
    })

    /**
     * Need to consider which scenarios we would cancel auto-login. Just because auth fails does not mean we should cancel this promise.
     * We should allow it to fail as many times as we like and still listen for a successful login. Perhaps we should only cancel it if the
     * user explicitly cancels the auto-login flow, but we may not allow that anyway for initial SDK implementations.
     */
    sdk.auth.attemptAutoLogin.own(
      promsifyReduxSaga<void, void>(
        (): void => {
          if (appStorage.has('token')) {
            dispatch(authReload())
          } else {
            dispatch({
              type: AUTH_RELOAD_FAILURE,
              error: 'No token',
              meta: { auth: true },
            })
          }
        },
        [GET_ORG_SUCCESS],
        // We removed these beacuse we will keep listening for success even if we hit these login failures, since they can retry
        [] // [AUTH_RELOAD_FAILURE, GET_ORG_FAIL]
      )
    )

    sdk.auth.loginWithToken.own(
      promsifyReduxSaga<{ token: string }, void>(
        ({ token }): void => {
          appStorage.setToken(token)
          dispatch(authReload())
        },
        [GET_ORG_SUCCESS],
        [AUTH_RELOAD_FAILURE, GET_ORG_FAIL]
      )
    )

    sdk.auth.refreshToken.own(async (token: string) => {
      appStorage.setToken(token)
    })

    sdk.auth.logout.own(async () => {
      dispatch({ type: USER_LOGOUT })
    })

    sdk.auth.setProjectIdentifiersToken.own(async (token: string) => {
      appStorage.setProjectIdentifiersToken(token)
    })

    sdk.route.goto.own(async (route) => {
      return new Promise<void>((resolve, reject) => {
        pendingRoutes.push({
          route,
          resolve,
          reject,
        })
        history.push(route)
      })
    })
    sdk.current_org.reload.own(async () => {
      dispatch({ type: GET_ORG })
    })
    sdk.project_form.updateFields.own(async (fields) => {
      let form = window.projectForm

      //TODO: Find a cleaner way of doing this
      // It can take a while for the project form to be ready, wait a bit if not ready
      const imax = 10
      for (let i = 0; i < imax; i++) {
        if (!form && history.location.pathname.includes('/projects/')) {
          console.debug(`Waiting for project form to be ready...${i}/${imax}`)
          await new Promise((resolve) => setTimeout(resolve, 1000))
          form = window.projectForm
        } else {
          break
        }
      }

      if (!form) {
        throw new Error("Can't update project fields, not currently in project form")
      }
      //TODO: wait for project form to be ready
      for (const key in fields) {
        form.registerField(key, () => {}, {})
        form.mutators.updateField(key, fields[key])
      }
    })

    sdk.project_form.getDesignData.own(async () => {
      const sceneAsJSON = window.editor?.sceneAsJSON()
      if (!sceneAsJSON) {
        throw new Error('Design data not available')
      }
      return sceneAsJSON
    })

    sdk.studio.getLoadedData.own(async () => {
      return window.AccountHelper.loadedData
    })

    sdk.studio.getSelectedSystemData.own(async () => {
      return window.editor.selectedSystem.refreshUserData()
    })

    sdk.studio.getSystemImageUrl.own(async (systemUuid: string, width?: number, height?: number) => {
      let projectId = sdk.project_form.values.value.id
      let orgId = sdk.project_form.values.value.org_id
      let url = `${window.API_BASE_URL}orgs/${orgId}/projects/${projectId}/systems/${systemUuid}/image_url/?width=400&height=300`
      return fetch(url, {
        headers: window.Utils.tokenAuthHeaders({
          'Content-Type': 'application/json',
        }),
      })
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          return data.url
        })
    })

    const getCommandClass = (commandName) => {
      if (!COMMANDS_PERMITTED.includes(commandName)) {
        throw new Error(`Command ${commandName} is not permitted`)
      }
      return window[commandName]
    }

    sdk.studio.executeCommandFromJson.own(async (commandName, json) => {
      const CommandClass = getCommandClass(commandName)
      // @ts-ignore
      const cmd = new CommandClass()
      cmd.fromJSON(json)
      // @ts-ignore
      return window.editor.execute(cmd)
    })

    sdk.studio.executeCommand.own(async (commandName, commandArgs) => {
      const CommandClass = getCommandClass(commandName)
      // @ts-ignore
      return window.editor.execute(new CommandClass(...commandArgs))
    })

    sdk.studio.callFunction.own(async (functionDotPath, args) => {
      let permitted = false
      if (FUNCTIONS_PERMITTED.includes(functionDotPath)) {
        permitted = true
      } else {
        for (const permittedFunction of FUNCTIONS_PERMITTED) {
          if (permittedFunction.endsWith('*')) {
            let permittedPrefix = permittedFunction.substring(0, permittedFunction.length - 1)

            if (functionDotPath.startsWith(permittedPrefix)) {
              permitted = true
              break
            }
          }
        }
      }

      if (!permitted) {
        throw new Error(`Function ${functionDotPath} is not permitted`)
      }

      let functionNameParts = functionDotPath.split('.')
      let lastPart = functionNameParts.pop()
      let scope = lodash.get(window as any, functionNameParts.join('.'))
      let func = lastPart && scope ? scope[lastPart] : undefined

      if (!func) {
        throw new Error(`Function ${functionDotPath} not found`)
      }

      return func.apply(scope, args)
    })

    sdk.studio.autoDesignRunAndLoadFromEquipment.own(
      async (
        calculator_id,
        setbackDistance,
        equipment,
        mappingConfigDistributor,
        facetsMode,
        lonlat,
        constraints,
        customData,
        runShading,
        runCalcs
      ) =>
        window.SceneHelper.autoDesignRunAndLoadFromEquipment(
          calculator_id,
          setbackDistance,
          equipment,
          mappingConfigDistributor,
          facetsMode,
          lonlat,
          constraints,
          customData,
          runShading,
          runCalcs
        )
    )

    sdk.studio.setComponents.own((componentCodesAndQuantities, keepExistingComponents, systemUuid) =>
      window.SceneHelper.setComponents(componentCodesAndQuantities, keepExistingComponents, systemUuid)
    )

    sdk.studio.removeObject.own(async (objectUuid) => {
      let obj = window.editor.objectByUuid(objectUuid)
      let cmd = new window.RemoveObjectCommand(obj, false, false, undefined, false)
      return window.editor.execute(cmd)
    })

    sdk.studio.availableImagery.own()

    sdk.flows.createOrUpdateProject.own(createOrUpdateProject(sdk))
    sdk.flows.generateDocument.own(generateDocument(sdk))

    // Things that other app domains aren't allowed to set
    sdk.route.route.own()
    sdk.route.path.own()

    sdk.current_org.id.own()
    sdk.auth.current_role.own()

    bindCrud(sdk.resources.projects, 'projects')

    const cleanups: (() => void)[] = []

    const customSanitizeArgs = {
      queueProcessed: function (args) {
        return args
      },
    }

    // TODO: find a way to make this more performant so that
    // SDK users don't have to 'pre-register' their interest in
    // signals, they just listen to them.
    STUDIO_SIGNAL_NAMES.forEach((signalName) => {
      if (sdk.resolvedConfig.signals?.includes(signalName)) {
        cleanups.push(
          bindSignal(
            signalName,
            sdk.studio[signalName],
            window.editor.signals[signalName],
            customSanitizeArgs[signalName]
          )
        )
      }
    })

    preference.bind(sdk, cleanups)

    sdk.markReady()

    return () => cleanups.forEach((cleanup) => cleanup())
  }, [active])

  useBindSelector((state: any) => {
    return state.designer?.detectImagery?.availableMapTypes || undefined
  }, sdk.studio.availableImagery)

  useBindSelector(authSelectors.getOrgId, sdk.current_org.id)
  useBindSelector(authSelectors.getCurrentRole, sdk.auth.current_role)
}

type PendingRoute = {
  route: string | Route
  resolve: () => void
  reject: () => void
}

import { Paper } from '@material-ui/core'
import Alert from 'elements/Alert'
import SoldChip from 'projectSections/sections/payments/SoldChip'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { SelectInput } from 'react-admin'
import { useFormState } from 'react-final-form'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { PaymentOptionDataType } from 'types/paymentOptions'
import { ProjectType } from 'types/projects'
import { urlToId } from 'util/misc'

type PropTypes = {
  project: ProjectType
}

const useStyles = makeOpenSolarStyles((theme) => ({
  paperWrapper: {
    marginBottom: 10,
  },
  paperContents: {
    padding: 15,
    paddingTop: 0,
  },
  selectRow: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    '& .MuiFormControl-root:first-child': {
      marginRight: 'auto',
    },
  },
  selectInput: {
    margin: 0,
    '& .MuiInputBase-root': {
      height: 50,
    },
  },
  titleStyle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
  },
  titleWidth: {
    width: 120,
  },
  title: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  itemChip: { marginLeft: 'auto' },
}))

type BriefSystemType = {
  id?: number
  uuid?: string
  title: string
  payment_options: PaymentOptionDataType[]
  selectedPaymentOptionId?: number
}

const ChangeOrderSelection: React.FC<PropTypes> = ({ project }) => {
  const translate = useTranslate()
  const systems = useMemo((): BriefSystemType[] => {
    let getSystems: BriefSystemType[] = []
    //title and payment option details are not available in project form > systems values
    window.editor.getSystems()?.forEach((sys) => {
      const getSystem = project?.systems?.find((x) => x.uuid === sys.uuid)
      if (getSystem) {
        getSystems.push({
          id: getSystem?.id,
          uuid: sys.uuid,
          title: sys.name && sys.name.length > 0 ? sys.name : `${sys.moduleQuantity()} ${translate('Panels System')}`,
          payment_options: sys.payment_options,
        })
      }
    })
    return getSystems
  }, [project])
  const systemSold = useMemo(() => {
    const systemSold: BriefSystemType | undefined = systems?.find((x) => x.id === urlToId(project?.system_sold))
    if (systemSold) {
      return {
        ...systemSold,
        selectedPaymentOptionId: !!systemSold.payment_options.length
          ? systemSold.payment_options?.find((pmt) => pmt.id === urlToId(project.payment_option_sold))?.id ||
            systemSold.payment_options[0].id
          : undefined,
      }
    }
  }, [systems, project?.system_sold, project.payment_option_sold])
  if (systems && systems.length < 2) {
    return (
      <Alert severity={'warning'}>
        {translate(
          'The project only has 1 system designed. For Change Order document to properly generate, you will need to select 2 different system options.'
        )}
      </Alert>
    )
  }
  return (
    <div>
      <ChangeOrderSystemInput
        source="originalSystem"
        title={translate('Original System')}
        description={translate('Select the system that is being changed.')}
        systems={systems}
        systemSold={systemSold}
      />
      <ChangeOrderSystemInput
        source="revisedSystem"
        title={translate('Revised System')}
        description={translate(
          'Select the revised system design. If you have not designed a revised system you can select the design that is most similar and manually edit the fields that will appear in this document before the document is generated.'
        )}
        systems={systems}
        systemSold={systemSold}
      />
    </div>
  )
}

type SystemInputTypes = {
  source: string
  title: string
  description: string
  systems: BriefSystemType[]
  systemSold?: BriefSystemType
}

const ChangeOrderSystemInput: React.FC<SystemInputTypes> = ({ source, title, description, systems, systemSold }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const formState = useFormState()
  const formValues = formState.values
  const defaultValue = useMemo((): BriefSystemType | undefined => {
    if (systemSold && source === 'originalSystem') {
      return systemSold
    } else {
      let selectedSystem: BriefSystemType | undefined = systemSold
        ? systems?.find((x) => x.id !== systemSold?.id)
        : systems[0]
      if (selectedSystem)
        return {
          ...selectedSystem,
          selectedPaymentOptionId: !!selectedSystem?.payment_options?.length
            ? selectedSystem?.payment_options[0].id
            : undefined,
        }
    }
    return undefined
  }, [])
  const systemPaymentOptions = useMemo(
    () =>
      formValues?.[source]?.uuid ? systems?.find((x) => x.uuid === formValues?.[source].uuid)?.payment_options : [],
    [formValues?.[source]]
  )
  return (
    <div className={classes.paperWrapper}>
      <Paper>
        <div className={classes.paperContents}>
          <h2>{title}</h2>
          <p className="small">{description}</p>
          <div className={classes.selectRow}>
            <SelectInput
              className={classes.selectInput}
              label={false}
              source={source + '.uuid'}
              fullWidth
              variant="outlined"
              choices={systems}
              defaultValue={defaultValue?.uuid}
              optionValue={'uuid'}
              optionText={(value: any) => {
                const showSold = value?.id === systemSold?.id
                return (
                  <div className={classes.titleStyle}>
                    <div className={`${classes.title} ${showSold ? classes.titleWidth : ''}`}>{value.title}</div>
                    {showSold && (
                      <div className={classes.itemChip}>
                        <SoldChip tooltipText="Sold" />
                      </div>
                    )}
                  </div>
                )
              }}
            />

            <SelectInput
              className={classes.selectInput}
              label={false}
              source={source + '.payment_option_id'}
              fullWidth
              variant="outlined"
              choices={!!systemPaymentOptions?.length ? systemPaymentOptions : defaultValue?.payment_options}
              defaultValue={defaultValue?.selectedPaymentOptionId}
              optionValue={'id'}
              optionText={(value: any) => {
                const showSold =
                  value?.id === systemSold?.selectedPaymentOptionId && systemSold?.uuid === formValues?.[source]?.uuid
                return (
                  <div className={classes.titleStyle}>
                    <div className={`${classes.title} ${showSold ? classes.titleWidth : ''}`}>{value.title}</div>
                    {showSold && (
                      <div className={classes.itemChip}>
                        <SoldChip tooltipText="Sold" chipText={translate('Sold')} />
                      </div>
                    )}
                  </div>
                )
              }}
            />
          </div>
        </div>
      </Paper>
    </div>
  )
}
export default ChangeOrderSelection

import { Card, Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import BookOutlinedIcon from '@material-ui/icons/BookOutlined'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import GavelIcon from '@material-ui/icons/GavelOutlined'
import { LOCALES_SUPPORTED_CUSTOMER_FACING } from 'App.js'
import { ComponentVersions_2_1 } from 'constants/uxVersions'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { permissionsSelectors } from 'ducks/permissions'
import CustomField from 'elements/field/CustomField'
import { SharedEntityChipsInput } from 'elements/field/SharedEntityChipsInput'
import SharedEntitySelectInput from 'elements/field/SharedEntitySelectInput'
import DateInput from 'elements/input/DateInput'
import InfoTooltip from 'elements/tooltip/InfoTooltip'
import UnresolvableReferenceInput from 'elements/UnresolvableReferenceInput'
import EditorComponent from 'elements/wysiwyg/EditorComponentWrapper'
import { Button, ComponentVersionsInherit } from 'opensolar-ui'
import React, { useEffect, useState } from 'react'
import { FieldTitle, ReferenceArrayInput, ReferenceInput, SelectInput, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getLanguageChoices } from 'resources/projects/choices'
import restClient from 'restClient'
import { dateParser } from 'util/misc'
import useMemoizedSelector from 'util/useMemoizedSelector'
import { systemNameOrDefault } from '../../../util/misc'
import DialogEditor from '../../elements/DialogEditor'
import { formSharingParams, formSharingParamsString } from '../info/orgSharing/util'
import ContractTemplateEditor from './ContractTemplateEditor'
import ProposalTemplateEditor from './ProposalTemplateEditor'
import PvsellProposalDialog from './PvsellProposalDialog'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      padding: '10px 20px',
    },
    fieldsWrapper: {
      display: 'flex',
      alignItems: 'center',
      // paddingRight: 20,
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        margin: '10px 5px',
      },
    },
    card: {
      background: '#F8F8F8',
    },
    inputForm: {
      width: '40%',
      margin: '1rem 1.2rem 1rem 1.5rem ',
    },
    input: {
      margin: '16px 5px',
      [theme.breakpoints.down('xs')]: {
        margin: 5,
      },

      width: 200,
      flexGrow: 1,
      '& .MuiFormLabel-root': {
        color: 'black',
      },
    },
    chipsInput: {
      margin: '16px 5px',
      [theme.breakpoints.down('xs')]: {
        margin: 5,
      },
      '& .MuiFormLabel-root': {
        color: 'black',
      },
    },

    button: {
      maxWidth: 130,
      flexGrow: 0,
      textAlign: 'center',
      paddingRight: 20,
    },
    contractContainer: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 25,
    },
    contractInput: {
      color: theme.greyLight2,
      marginTop: 0,
      marginBottom: '1rem',
    },
  }),
  { name: 'customise-proposal-inputs' }
)

const getDefaultTemplate = (choices, org, isShared) => {
  let defaultTemplate = choices.find((choice) => choice.is_default && org === choice.org)
  if (isShared) {
    const defaultExternal = choices.find(
      (choice) => choice.is_default && org !== choice.org && choice.share_with_orgs.includes(org)
    )
    defaultTemplate = defaultExternal || defaultTemplate
  }
  return defaultTemplate
}

const getTemplateById = (url, choices) => {
  const template = choices && choices.find((choice) => choice.url === url)
  return template
}
const InputWithEditor = ({ component, editorComponent, buttonLabel, ...props }) => {
  const { name, choices } = props
  const [isOpen, setOpen] = useState(false)
  const form = useForm()
  const translate = useTranslate()
  const formValue = form.getState().values
  const classes = useStyles()
  const sharedWith = formValue.shared_with
  const isShared = !!sharedWith?.length
  const defaultTemplate = getDefaultTemplate(choices, formValue.org, isShared)
  const currentSelectedTemplate = formValue[name] === null ? defaultTemplate : getTemplateById(formValue[name], choices)
  const id = currentSelectedTemplate && currentSelectedTemplate.id

  const org = useSelector(orgSelectors.getOrg)
  const [newButtonLabel, setButtonLabel] = useState(buttonLabel)
  const [emptyText, setEmptyText] = useState('')
  const getDefaultTemplateText = (defaultTemplate) => {
    const defaultEnd = defaultTemplate
      ? ` (${defaultTemplate.title})`
      : org?.id !== formValue.org_id
      ? ` (${translate('Unshared Item')})`
      : ''
    return translate('Default Template') + defaultEnd
  }

  useEffect(() => {
    if (isShared) {
      setButtonLabel((currentSelectedTemplate?.org === org?.url ? 'Edit ' : 'View ') + buttonLabel)
    } else {
      setButtonLabel('Edit ' + buttonLabel)
    }
    setEmptyText(getDefaultTemplateText(defaultTemplate))
  }, [currentSelectedTemplate, sharedWith, org])

  return (
    <div className={classes.fieldsWrapper}>
      {React.createElement(component, {
        allowEmpty: true,
        emptyValue: '',
        emptyText: <i style={{ color: '#aaa' }}>{emptyText}</i>,
        ...props,
      })}
      <div className={classes.button}>
        {id && (
          <>
            <ComponentVersionsInherit versions={ComponentVersions_2_1}>
              <Button
                variant="contained"
                size="small"
                style={{ textTransform: 'none' }}
                onClick={(e) => {
                  e.preventDefault()
                  setOpen(true)
                }}
              >
                <span>{translate(newButtonLabel)}</span>
              </Button>
            </ComponentVersionsInherit>
            <DialogEditor
              isOpen={isOpen}
              handleClose={() => setOpen(false)}
              id={id}
              editorComponent={editorComponent}
              editorProps={{ templateOwner: currentSelectedTemplate?.org === org?.url }}
            />
          </>
        )}
      </div>
    </div>
  )
}

const useStyles2 = makeStyles((theme) => ({
  wysiwygHeading: {
    margin: 0,
    color: 'black',
    whiteSpace: 'normal',
    borderBottom: 'none',
  },
  wysiwygWrapper: { margin: '16px 0', display: 'flex', flexDirection: 'column' },
  infoIcon: {
    color: 'rgb(0, 148, 255)',
  },
  popoverContent: {
    maxWidth: 300,
    padding: 10,
    fontSize: 12,
    cursor: 'default',
    background: 'rgba(0,0,0,0.2)',
  },
  icon: {
    width: 24,
    height: 24,
    marginRight: 10,
    verticalAlign: 'middle',
  },
  heading: {
    verticalAlign: 'middle',
    whiteSpace: 'normal',
    cursor: 'pointer',
  },
  accordion: {
    margin: '16px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

const EditorAccordion = ({ source, heading, handleChange, tooltip, icon }) => {
  const [expanded, setExpanded] = useState(false)

  const form = useForm()
  const values = form.getState().values
  useEffect(() => {
    if (values[source]) {
      setExpanded(true)
    }
  }, [])
  const classes = useStyles2()
  return expanded ? (
    <div className={classes.wysiwygWrapper}>
      <h3 className={classes.wysiwygHeading}>{heading}</h3>
      <EditorComponent fieldName={source} allowUploadImage={true} onChange={handleChange} mentionContext="empty" />
      <CustomField name={source} component="input" style={{ display: 'none' }} />
    </div>
  ) : (
    <div className={classes.accordion}>
      <div>
        {React.createElement(icon, { className: classes.icon })}
        <span onClick={() => setExpanded(true)} className={classes.heading}>
          {heading}
        </span>
      </div>
      <InfoTooltip title={tooltip} />
    </div>
  )
}

const SelectProposalTemplateInput = (props) => {
  const { choices, input } = props
  const form = useForm()
  const enhancedOnChange = (e) => {
    input.onChange(e)
    const newProposalTemplate = e.target.value
    const newProposalTemplateData = choices.find((choice) => choice.url === newProposalTemplate)
    const isFieldExist = form.getFieldState('proposal_template_settings')
    if (!isFieldExist) {
      form.registerField('proposal_template_settings', () => {}, {})
    }
    form.change('proposal_template_settings', {
      pvprosell_template_id: newProposalTemplateData?.pvprosell_template_id,
      title: newProposalTemplateData?.title,
    })
  }
  return <SharedEntitySelectInput {...props} onChange={enhancedOnChange} variant="standard" />
}

const ProposalInputs = ({ handleChange, proposalData, buttonLabel }) => {
  const translate = useTranslate()
  const [showPvsellDialog, setPvsellDialogVisibility] = useState(false)
  const classes = useStyles()
  const projectId = useSelector((state) => state.projectId)
  const api_key_pvsell = useSelector(authSelectors.getPvSellKey)
  const form = useForm()
  const formValue = form.getState().values
  const contractTempateMode = formValue?.contract_template_mode
  const pvSellTemplateId = formValue?.proposal_template_settings?.pvprosell_template_id
  const [contracts, setContracts] = useState([])
  const [defaultContractTemplate, setDefaultContractTemplate] = useState([])
  const [showContractTempateMode, setShowContractTempateMode] = React.useState(false)

  const currOrgId = useSelector(authSelectors.getOrgId)
  const restClientInstance = restClient(window.API_ROOT + '/api')
  const contractPermissions = useMemoizedSelector(permissionsSelectors.getProjectPermissionByKey('contract_template'))

  function getDefaultContractTemplate(contracts) {
    for (let i = 0; i < contracts.length; i++) {
      if (contracts[i].is_default === true) {
        return contracts[i]
      }
    }
  }

  function getDefaultContractTemplateForPaymentOption(contracts, paymentOption) {
    const prop = 'is_default_' + paymentOption.payment_type
    for (let i = 0; i < contracts.length; i++) {
      if (contracts[i][prop]) {
        return contracts[i]
      }
    }
    return defaultContractTemplate
  }

  useEffect(() => {
    if (contractPermissions?.allowView) {
      var contractsUrl = 'contracts/' + formSharingParamsString(currOrgId, form.getState().values, 'fieldset=list')
      restClientInstance('CUSTOM_GET', 'contracts', {
        url: contractsUrl,
      })
        .then((response) => {
          const contracts = response.data
          setContracts(contracts)
          setDefaultContractTemplate(getDefaultContractTemplate(contracts))
          if (contractTempateMode === 'payment-option') {
            setShowContractTempateMode(true)
          }
        })
        .catch((error) => {
          console.log('Failed to load contracts: ', error)
        })
    }
  }, [contractPermissions?.allowView])

  const handleContractModeClick = () => {
    form.mutators.markFieldAsDirty('contract_template_mode')
    form.mutators.updateField('contract_template_mode', showContractTempateMode ? 'project' : 'payment-option')

    if (contractTempateMode === 'payment-option' && proposalData && proposalData.systems) {
      proposalData.systems.forEach((systemData) => {
        let osSystem = window.editor.objectByUuid(systemData.uuid)
        let contract_template_overrides = osSystem.contract_template_overrides || {}
        setValueCmd(osSystem, 'contract_template_overrides', contract_template_overrides)
      })
    } else {
      handleChange()
    }

    setShowContractTempateMode(!showContractTempateMode)
  }

  function getValueForPaymentOption(systemData, paymentOption) {
    const system = window.editor.objectByUuid(systemData.uuid)
    const value = system?.contract_template_overrides[paymentOption.id]
    if (value === undefined) return ''
    else if (value === '') return ''
    else
      return !!value
        ? system?.contract_template_overrides[paymentOption.id]
        : getDefaultContractTemplateForPaymentOption(contracts, paymentOption)?.id
  }

  function setValueCmd(osSystem, prop, value) {
    window.editor.execute(new window.SetValueCommand(osSystem, prop, value))

    // Ideally this could be handled automatically in response to the SetValueCommand but it appears that
    // this is not detected automatically when we edit contract template overrides in the left drawer
    // in Online Proposal section. So for no we will trigger manually. This is copied from
    // ProjectForm.handleHistoryChanged()
    form.mutators.markFieldAsDirty('design')
    form.change('design', 'has unsaved change')

    // Trigger proposal to reload with our new changes
    // @TODO: Add a delay so quickly changing multiple contracts for multiple payment options will only
    // result in only loading proposal data once.
    handleChange()
  }

  const contractOnChange = (event, systemData, paymentOption) => {
    let osSystem = window.editor.objectByUuid(systemData.uuid)
    if (osSystem) {
      const value = event.target.value
      var contract_template_overrides = osSystem.contract_template_overrides || {}
      if (!value || value === '') {
        if (contract_template_overrides.hasOwnProperty(paymentOption.id)) {
          delete contract_template_overrides[paymentOption.id]
        }
      } else {
        contract_template_overrides[paymentOption.id] = value
      }

      setValueCmd(osSystem, 'contract_template_overrides', contract_template_overrides)
    }
  }

  // const getDefaultMenuItem = (paymentOption) => {
  //   return (
  //     <MenuItem key="default" className="manual-item" value={''}>
  //       <span style={{ fontStyle: 'italic', color: '#aaa' }}>{getDefaultMenuItemLabel(paymentOption)}</span>
  //     </MenuItem>
  //   )
  // }

  const getDefaultContract = (paymentOption) => {
    let defTemplate = getDefaultContractTemplateForPaymentOption(contracts, paymentOption)
    return defTemplate ? translate('Default') + ` (${defTemplate.title})` : translate('Default Template')
  }

  return (
    <div className={classes.container}>
      <UnresolvableReferenceInput
        source="proposal_templates"
        label={translate('Proposal Template')}
        filter={formSharingParams(currOrgId, formValue, { show_archived: 1 })}
        onChange={handleChange}
        name="proposal_template"
        reference="proposal_templates"
        optionValueField="url"
        perPage={1000}
        allowEmpty
        ignoreWarnings={['associatedReference']}
      >
        <InputWithEditor
          optionText="title"
          optionValue="url"
          component={SelectProposalTemplateInput}
          style={{ marginBottom: '30px', marginRight: '55px' }}
          options={{ className: classes.input, SelectProps: { displayEmpty: true } }}
          InputLabelProps={{
            shrink: true,
          }}
          buttonLabel={'Proposal Template'}
          editorComponent={<ProposalTemplateEditor handleChange={handleChange} projectId={projectId} />}
        />
      </UnresolvableReferenceInput>

      <div className={classes.fieldsWrapper}>
        <Grid item xs={6}>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            {translate('Contract Template')}
          </Typography>
        </Grid>
        <Grid style={{ marginLeft: '53px' }} item xs={6}>
          <Link
            onClick={handleContractModeClick}
            variant="contained"
            style={{ textTransform: 'none' }}
            classes={classes}
            disableRipple={true}
          >
            <span style={{ marginLeft: !showContractTempateMode ? '' : '100px' }}>
              {!showContractTempateMode ? translate('Set Per System/Payment option') : translate('Set Per Project ')}
            </span>
          </Link>
        </Grid>
      </div>
      <br />
      {!showContractTempateMode && (
        <Card variant="outlined" className={classes.card}>
          <ReferenceInput
            source="contract"
            filter={formSharingParams(currOrgId, formValue, { show_archived: 1 })}
            onChange={handleChange}
            label={translate('Contract Template')}
            name="contract"
            reference="contracts"
            optionValueField="url"
            perPage={1000}
            allowEmpty
          >
            <InputWithEditor
              optionText="title"
              optionValue="url"
              component={SharedEntitySelectInput}
              style={{ marginBottom: '30px', marginRight: '48px', marginLeft: '28px' }}
              options={{ className: classes.inputForm, SelectProps: { displayEmpty: true } }}
              InputLabelProps={{
                shrink: true,
              }}
              buttonLabel={'Contract Template'}
              editorComponent={<ContractTemplateEditor handleChange={handleChange} />}
            />
          </ReferenceInput>
        </Card>
      )}
      {
        <>
          {showContractTempateMode && (
            <Card variant="outlined" classes={{ root: classes.card }}>
              {proposalData?.systems?.length > 0 &&
                proposalData.systems.map((systemData) => (
                  <>
                    <div style={{ paddingLeft: '1.5rem' }}>
                      <p style={{ foneWeight: 'bold' }}> System: {translate(systemNameOrDefault(systemData))}</p>
                    </div>
                    {systemData.payment_options.map((paymentOption) => {
                      return (
                        <div className={classes.contractContainer}>
                          <FieldTitle label={paymentOption.title} />
                          <SharedEntitySelectInput
                            emptyValue=""
                            label={false}
                            allowEmpty={true}
                            choices={contracts}
                            displayEmpty={true}
                            key={paymentOption.id}
                            standardPadding={true}
                            className={classes.contractInput}
                            id={'paymentOption' + paymentOption.id}
                            value={getValueForPaymentOption(systemData, paymentOption)}
                            MenuProps={{ disableScrollLock: true, style: { zIndex: 2500 } }}
                            emptyText={<i style={{ color: '#aaa' }}>{getDefaultContract(paymentOption)}</i>}
                            onChange={(event) => {
                              contractOnChange(event, systemData, paymentOption)
                            }}
                            style={{ minWidth: '60% !important' }}
                          />
                        </div>
                      )
                    })}
                  </>
                ))}
            </Card>
          )}
        </>
      }
      <div className={classes.fieldsWrapper}>
        <DateInput
          name="valid_until_date"
          className={classes.input}
          source="valid_until_date"
          id="salesValidUntilDate"
          parse={dateParser}
          onChange={handleChange}
          hideClearButton={true}
          label={translate('Valid Until')}
          variant="standard"
        />
        {!pvSellTemplateId && (
          <SelectInput
            onChange={handleChange}
            className={classes.input}
            style={{ marginBottom: '10px' }}
            InputLabelProps={{
              shrink: true,
            }}
            options={{
              SelectProps: {
                displayEmpty: true,
                renderValue: (locale) => (
                  <span>{LOCALES_SUPPORTED_CUSTOMER_FACING[locale] || translate('Default')}</span>
                ),
              },
            }}
            translateChoice={false}
            source="language_override"
            name="language_override"
            label={translate('Language')}
            choices={getLanguageChoices()}
          />
        )}
      </div>
      {pvSellTemplateId && api_key_pvsell && (
        <div style={{ margin: '16px 5px', fontWeight: 'bolder' }}>
          <span>PVProsell PDF Proposals</span>
          <Button
            // style={{
            //   boxSizing: 'border-box',
            //   boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.25)',
            //   borderRadius: '3px',
            // }}
            // labelWrapperStyle={{ color: '#4d4d4d', padding: '0 16px 0 16px' }}
            variant="contained"
            size="small"
            style={{ marginLeft: 10 }}
            className={classes.button}
            onClick={(e) => {
              setPvsellDialogVisibility(true)
            }}
          >
            {translate('Generate...')}
          </Button>

          <PvsellProposalDialog
            api_key_pvsell={api_key_pvsell}
            project_id={projectId}
            pvsell_selected_proposal_id={pvSellTemplateId}
            isOpen={showPvsellDialog}
            setOpen={setPvsellDialogVisibility}
          />
        </div>
      )}
      {!pvSellTemplateId && (
        <div style={{ paddingRight: 10 }}>
          <ReferenceArrayInput
            name="testimonials"
            source="testimonials"
            onChange={handleChange}
            filter={formSharingParams(currOrgId, formValue, {})}
            reference="testimonials"
            optionValueField="url"
          >
            <SharedEntityChipsInput
              options={{
                placeholder: translate('Choose case studies to override defaults'),
                className: classes.chipsInput,
              }}
              label={translate('Case Studies')}
              fullWidth={true}
              optionText="name"
              optionValue="url"
            />
          </ReferenceArrayInput>
        </div>
      )}
      <Divider style={{ margin: '20px 0' }} />
      <EditorAccordion
        source="proposal_message"
        handleChange={handleChange}
        icon={BookOutlinedIcon}
        heading={`${translate('Cover Message')} (${translate('override')})`}
        tooltip={
          <div>
            {translate(
              'Type your message into the box below to customize the message that your customer will see when they first access this proposal . You can include images or links, although this is not required.'
            )}
          </div>
        }
      />
      <EditorAccordion
        source="proposal_content"
        handleChange={handleChange}
        icon={DescriptionOutlinedIcon}
        heading={translate('Project-specific Proposal Content')}
        tooltip={
          <div>
            Type your message into the box below to customize contract terms that are specific to this project. To
            specify where these terms will appear in your contract, check out{' '}
            <a
              href="https://support.opensolar.com/hc/en-us/articles/900004317783-Adding-Customised-Messages-Terms-for-a-Specific-Project"
              target="_blank"
              rel="noreferrer"
            >
              this HelpCenter article
            </a>
            .
          </div>
        }
      />
      <EditorAccordion
        source="contract_terms"
        handleChange={handleChange}
        icon={GavelIcon}
        heading={translate('Project-specific Contract Terms')}
        tooltip={
          <div>
            Type your message into the box below to customize content that you'd like to appear in the proposal that is
            specific to this project. You can include images or links, although this is not required. To specify where
            this content will appear in your proposal, check out{' '}
            <a
              href="https://support.opensolar.com/hc/en-us/articles/900004317783-Adding-Customised-Messages-Terms-for-a-Specific-Project"
              target="_blank"
              rel="noreferrer"
            >
              this HelpCenter article
            </a>
            .
          </div>
        }
      />
    </div>
  )
}

export default ProposalInputs

import { SelectInputProps } from 'ra-ui-materialui/src/input/SelectInput'
import { SelectInput } from 'react-admin'
import { styles as standardStyles } from 'styles'

type Props = {
  choices?: object[]
  source?: string
  meta?: {
    error: unknown
  }
} & Omit<SelectInputProps, 'source'>

export const SelectCommissionInput = ({ choices, meta, source, ...props }: Props) => {
  //add manual entered to choices
  const finalChoices = (choices || []).concat({ id: 'manual', title: 'Manual Entered' })
  //eliminate error message for missing reference of 'manual' commission
  const { error, ...eliminatedMeta } = meta || {}
  return (
    <SelectInput
      source={'commissions'}
      options={{ style: standardStyles.FILTER_FIELD_STYLE, variant: 'filled' }}
      {...props}
      optionText="title"
      choices={finalChoices}
      meta={eliminatedMeta}
      sortChoices={true}
      margin={'none'}
    />
  )
}

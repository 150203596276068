import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

export const useContactFormStyles = makeOpenSolarStyles(
  {
    dateInput: {
      minWidth: '100%',
      maxWidth: '100%',
    },
    selectInput: {
      minWidth: '100%',
      maxWidth: '100%',
    },
    projectsInputContainer: {
      display: 'flex',
      alignItems: 'end',
      minHeight: '105px',
      paddingBottom: '29px !important',
    },
    detail: {
      '& .MuiGrid-grid-xs-true': {
        minHeight: '80px',
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
  { name: 'ContactForm' }
)

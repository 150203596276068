import { InputLabel, MenuItem, Select } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { useUserActionsContext } from 'contexts/userActions/useUserActionsContext'
import { authSelectors } from 'ducks/auth'
import { rolesSelectors } from 'ducks/auth_roles'
import { orgSelectors } from 'ducks/orgs'
import Alert from 'elements/Alert'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import { findGenericUserAction } from 'persistentContent/tours/tools/common'
import { useGetConnectedOrgBeingUsed } from 'projectSections/sections/payments/utils'
import React, { useEffect, useState } from 'react'
import { useNotify } from 'react-admin'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import appStorage from 'storage/appStorage'
import { RoleType } from 'types/roles'

const STORAGE_KEY = 'dividend_role_dialog_new'
const restClientInstance = restClient(window.API_ROOT + '/api')

type UserOptionType = {
  id: string
  name: string
}

type PropTypes = {
  onClose: () => void
  onSuccess: () => void
  orgId: number
  role?: RoleType
}

const DividendConnectRoleDialog: React.FC<PropTypes> = (props) => {
  const loggedInRoleIsConnected: boolean = useSelector(rolesSelectors.getIsRoleConnectedToDividend)
  const targetRoleIsConnected = !props.role?.id && loggedInRoleIsConnected //if a user is connected a different user
  const userId = useSelector(authSelectors.getCurrentUserId)
  const thisRolesOrg = useSelector(orgSelectors.getOrg)
  const isStaff = useSelector(authSelectors.getIsStaff)
  const userActions = useUserActionsContext()

  const [loadingUsers, setLoadingUsers] = useState<boolean>(false)
  const [userOptions, setUserOptions] = useState<UserOptionType[]>([])
  const [saving, setSaving] = useState<boolean>(false)
  const [selectedDividendId, setSelectedDividendId] = useState<string | undefined>(undefined)
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)

  const connectedOrgBeingUsed = useGetConnectedOrgBeingUsed(thisRolesOrg, props.orgId)

  const notify = useNotify()

  useEffect(() => {
    if (!targetRoleIsConnected && appStorage.getString(STORAGE_KEY) !== 'shown' && userId) {
      findGenericUserAction(userId, STORAGE_KEY)
        .then((res) => {
          if (res?.data?.exists === false) {
            setTimeout(() => {
              setLoadingUsers(true)
              //logAmplitudeEvent('in_app_promo_shown', { title: promoConfig.title, promo_id: promoConfig.id})
              userActions.recordAction(STORAGE_KEY)
            }, 1000)
          }
        })
        .catch((err) => {})
      appStorage.setString(STORAGE_KEY, 'shown')
    }
  }, [targetRoleIsConnected])

  useEffect(() => {
    if (props.role?.id) {
      setLoadingUsers(true)
    }
  }, [props.role?.id])

  useEffect(() => {
    if (loadingUsers) {
      restClientInstance('CUSTOM_GET', 'custom', {
        url: 'orgs/' + props.orgId + '/dividend/users/',
      })
        .then((response) => {
          let options = response?.data?.users?.reps?.map((dividendUser) => {
            return {
              id: dividendUser.id,
              name: dividendUser.email,
            }
          })
          if (options?.length > 0) {
            setUserOptions(options)
            logAmplitudeEvent('dividend_connect_role_dialog_shown', { page: props.role?.id ? 'role' : 'design' })
          }
        })
        .catch((err) => {})
    }
  }, [loadingUsers])

  const save = () => {
    setSaving(true)
    restClientInstance('CUSTOM_POST', 'custom', {
      url: 'orgs/' + props.orgId + '/roles/' + props.role?.id + '/dividend/connect/',
      data: {
        dividend_contact_id: selectedDividendId,
      },
    })
      .then((response) => {
        if (response?.data?.success) {
          logAmplitudeEvent('dividend_role_connected', { page: props.role?.id ? 'role' : 'design' })
          notify('The Dividend user has been connected', 'success')
          props.onSuccess()
        }
      })
      .catch((err) => {
        setErrorMsg('Unable to connect the dividend role')
        logAmplitudeEvent('dividend_connect_role_error', { page: props.role?.id ? 'role' : 'design' })
      })
      .finally(() => setSaving(false))
  }

  if (targetRoleIsConnected || userOptions?.length === 0) return null
  if (connectedOrgBeingUsed && !isStaff) {
    return (
      <div>
        <p>
          The simplest way to make sure projects are assigned to the proper Dividend user is to ensure that the email of
          the OpenSolar user matches the email of the Dividend user.
        </p>
        <p>
          If this is not feasible and you'd like to assign this user to a Dividnend user with a different email address
          please reach out to OpenSolar Support.
        </p>
      </div>
    )
  }
  return (
    <div style={{ width: '100%' }}>
      {connectedOrgBeingUsed && isStaff && (
        <Alert severity="info">
          We do not let non-staff users edit their Dividend role connections for other orgs. Only staff members can do
          this. This is to prevent users from seeing the full list of reps in a connected org.
        </Alert>
      )}
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <img src={`${window.PUBLIC_URL}/images/dividend-logo-wide.svg`} alt="" height={50} />
      </div>
      <p style={{ marginTop: '40px' }}>
        Connecting your Dividend user will make it so the Dividend projects you create are assigned to you. Pick your
        Dividend from the user below.
      </p>
      <p>If you can't find your user that's ok, this is not a required step.</p>
      <InputLabel shrink={true}>
        Dividend User's Email Address {connectedOrgBeingUsed ? `for ${connectedOrgBeingUsed?.org_name}` : ''}
      </InputLabel>
      <Select
        autoWidth
        displayEmpty={true}
        value={selectedDividendId}
        onChange={(e: React.ChangeEvent<{ value: unknown }>) => setSelectedDividendId(e.target.value as string)}
        fullWidth={true}
      >
        {userOptions.map((option) => (
          <MenuItem key={option.id} value={option.id} title={option.name}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
      {errorMsg && <p style={{ textAlign: 'center', color: 'red' }}>{errorMsg}</p>}
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <div style={{ margin: '15px' }}>
          <ProUXButton type="secondary" label="Dismiss" onClick={props.onClose} />
        </div>
        <div style={{ margin: '15px' }}>
          <ProUXButton
            type="primary"
            disabled={!selectedDividendId}
            label="Connect User"
            loading={saving}
            onClick={save}
          />
        </div>
      </div>
    </div>
  )
}
export default DividendConnectRoleDialog

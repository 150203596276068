import { TextFieldProps } from '@material-ui/core/TextField'
import { DownshiftProps } from 'downshift'
import { DynamicIcon } from 'elements/DynamicIcon'
import { InputProps } from 'ra-core'
import { SelectInput } from 'ra-ui-materialui'
import React, { useMemo } from 'react'

const iconStyle = {
  height: 12,
}
interface Options {
  suggestionsContainerProps?: any
  labelProps?: any
  containerStyle?: any
  suggestionItemStyle?: any
}

type Choice = { id: string; name: any }

const BestIcons = [
  'AddIcCall',
  'Accessibility',
  'AccountBox',
  'AccountCircle',
  'AccountBalance',
  'AccountBalanceWallet',
  'AccessTime',
  'Map',
  'DirectionsSubway',
  'MyLocation',
  'AirportShuttle',
  'LocalShipping',
  'Place',
  'Explore',
  'Public',
  'WbSunny',
  'Brightness5',
  'Power',
  'BatteryFull',
]

export const DynamicIconInput: React.FC<InputProps<TextFieldProps & Options> & DownshiftProps<any>> = (props) => {
  const choices: Choice[] = useMemo(() => {
    const ret: Choice[] = []
    for (const id of BestIcons)
      ret.push({
        id: id,
        name: <DynamicIcon icon={id} style={iconStyle} />,
      })
    return ret
  }, [])

  //@ts-ignore
  return <SelectInput choices={choices} {...props} translateChoice={false} />
}

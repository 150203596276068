var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import ContentSave from '@material-ui/icons/SaveOutlined';
import classnames from 'classnames';
import { Button } from 'opensolar-ui';
import PropTypes from 'prop-types';
import { FormContext, useNotify, useTranslate } from 'ra-core';
import { cloneElement, useContext } from 'react';
import { sanitizeButtonRestProps } from './Button';
var SaveButton = function (props) {
    var className = props.className, classesOverride = props.classes, invalid = props.invalid, _a = props.label, label = _a === void 0 ? 'ra.action.save' : _a, pristine = props.pristine, redirect = props.redirect, saving = props.saving, submitOnEnter = props.submitOnEnter, _b = props.variant, variant = _b === void 0 ? 'contained' : _b, _c = props.icon, icon = _c === void 0 ? defaultIcon : _c, onClick = props.onClick, handleSubmitWithRedirect = props.handleSubmitWithRedirect, onSave = props.onSave, rest = __rest(props, ["className", "classes", "invalid", "label", "pristine", "redirect", "saving", "submitOnEnter", "variant", "icon", "onClick", "handleSubmitWithRedirect", "onSave"]);
    var classes = useStyles(props);
    var notify = useNotify();
    var translate = useTranslate();
    var setOnSave = useContext(FormContext).setOnSave;
    var handleClick = function (event) {
        if (typeof onSave === 'function') {
            setOnSave(onSave);
        }
        else {
            // we reset to the Form default save function
            setOnSave();
        }
        if (saving) {
            // prevent double submission
            event.preventDefault();
        }
        else {
            if (invalid) {
                notify('ra.message.invalid_form', 'warning');
            }
            // always submit form explicitly regardless of button type
            if (event) {
                event.preventDefault();
            }
            handleSubmitWithRedirect(redirect);
        }
        if (typeof onClick === 'function') {
            onClick(event);
        }
    };
    var type = submitOnEnter ? 'submit' : 'button';
    var displayedLabel = label && translate(label, { _: label });
    return (_jsxs(Button, __assign({ className: classnames(classes.button, className), variant: variant, name: 'raSaveButton', onClick: handleClick, color: saving ? 'default' : 'primary', "aria-label": displayedLabel }, sanitizeButtonRestProps(rest), { children: [saving ? (_jsx(CircularProgress, { size: 18, thickness: 2, className: classes.leftIcon })) : (cloneElement(icon, {
                className: classnames(classes.leftIcon, classes.icon),
            })), _jsx("span", { children: displayedLabel })] })));
};
var defaultIcon = _jsx(ContentSave, {});
var useStyles = function (props) {
    return makeStyles(function (theme) {
        return {
            button: {
                position: 'relative',
                // [theme.breakpoints.down('xs')]: {
                //   padding: 6,
                // },
                margin: 10,
            },
            leftIcon: {
                marginRight: 10,
                // color: theme.headerFontColor,
            },
            icon: {
                // fontSize: 20,
                width: 21,
                height: 21,
            },
        };
    }, { name: 'RaSaveButton' })();
};
SaveButton.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    handleSubmitWithRedirect: PropTypes.func,
    onSave: PropTypes.func,
    invalid: PropTypes.bool,
    label: PropTypes.string,
    pristine: PropTypes.bool,
    redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
    saving: PropTypes.bool,
    submitOnEnter: PropTypes.bool,
    variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
    icon: PropTypes.element,
};
export default SaveButton;

import { useMediaQuery } from '@material-ui/core'
import { BaseThemeType, styled } from 'opensolar-ui'
import { ContentBg, getProjectSectionStyles } from 'projectSections/sections/manage3/styles'
import { useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useLocation } from 'react-router-dom'

const SkeletonWrapper = styled('div')({
  width: '100%',
})

const DoubleWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  '& div:first-child': {
    marginRight: 30,
  },
})

const SkeletonFlex = styled('div')({
  flex: 1,
})

//Used for UI 3.0 pages
const LoadingSkeleton: React.FunctionComponent = (props) => {
  const location = useLocation()
  const classes = getProjectSectionStyles()
  const isMobile = useMediaQuery((theme: BaseThemeType) => theme.breakpoints.down('xs'))
  const pageContainers = {
    manage: isMobile
      ? [350, 150, 350, 350, 300, 50, 50, 50, 50, 50, 50]
      : [210, 160, [300, 300], [300, 300], 50, 50, 50, 50],
  }
  const contHeights = useMemo(() => {
    if (location.pathname.includes('manage')) return pageContainers['manage']
  }, [location.pathname, isMobile])
  if (!contHeights) return null
  return (
    <ContentBg>
      <div className={classes.sectionWrapper}>
        {contHeights?.map((height, i) => {
          if (typeof height === 'number')
            return (
              <SkeletonWrapper key={'skeleton-' + i} style={{ height: height }}>
                <Skeleton height={height} />
              </SkeletonWrapper>
            )
          const maxHeight = Math.max.apply(Math, height)
          return (
            <DoubleWrapper key={'skeleton-' + i} style={{ height: maxHeight }}>
              {height.map((x, j) => (
                <SkeletonFlex key={'skeleton-' + j} style={{ height: x }}>
                  <Skeleton height={x} />
                </SkeletonFlex>
              ))}
            </DoubleWrapper>
          )
        })}
      </div>
    </ContentBg>
  )
}
export default LoadingSkeleton

import { useViewShow } from 'ducks/viewMode'
import React, { FC, PropsWithChildren, forwardRef, useMemo } from 'react'

type UiContainerPropsType = {
  uiKey: string
  children?: React.ReactElement
}

/**
 * This component is a basic compntainer with a UI key, to allow it to
 * be hidden/shown using the view mode system.
 */

export const UiKey: FC<PropsWithChildren<UiContainerPropsType>> = forwardRef(({ uiKey, children, ...rest }, ref) => {
  const show = useViewShow(uiKey)

  return useMemo(() => (!show || !children ? <></> : React.cloneElement(children, { ...rest, ref })), [
    show,
    ref,
    children,
  ])
})

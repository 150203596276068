/*
EagleView Inform Essentials+	11	
EagleView Inform Essentials+ for Commercial	12
EagleView Inform Advanced	62	
EagleView Inform Advanced for Commercial	63
*/
export const EAGLEVIEW_PRODUCT_OPTIONS = [
    { id: 62, name: 'Inform Advanced (Residential)' },
    { id: 11, name: 'Inform Essentials+ (Residential)' },
    { id: 63, name: 'Inform Advanced for Commercial' },
    { id: 12, name: 'Inform Essentials+ for Commercial' },
]
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { DialogTitle as Mui4DialogTitle } from '@material-ui/core';
import { default as Mui5DialogTitle } from '@mui/material/DialogTitle';
import { forwardRef } from 'react';
import { Inspector } from '../debug/Inspector';
import { useComponentVersion } from '../hooks';
import { OsDialogVersion } from './Dialog';
export var DialogTitle = function (props) {
    return (_jsx(Inspector, { name: "DialogTitle", propDefs: DialogTitleProps, showExample: true, component: OsDialogTitle, props: props }));
};
var OsDialogTitle = forwardRef(function (props, ref) {
    if (ref === void 0) { ref = null; }
    var version = useComponentVersion('dialog');
    switch (version) {
        case OsDialogVersion.V2:
            //@ts-ignore
            return _jsx(Mui5DialogTitle, __assign({ ref: ref }, props));
        default:
            return _jsx(Mui4DialogTitle, __assign({ ref: ref }, props));
    }
});
var DialogTitleProps = {};

// @ts-nocheck
import { Fade, Grid, makeStyles } from '@material-ui/core'
import BrightnessIcon from '@material-ui/icons/Brightness4Outlined'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import DuplicateIcon from '@material-ui/icons/FileCopyOutlined'
import LinkedCameraIcon from '@material-ui/icons/LinkedCameraOutlined'
import MoveDownIcon from '@material-ui/icons/LowPriorityOutlined'
import MoreHorizIcon from '@material-ui/icons/MoreHorizOutlined'
import FixSkewIcon from '@material-ui/icons/PanoramaHorizontalOutlined'
import RotateIcon from '@material-ui/icons/RotateRightOutlined'
import PlansetIcon from '@material-ui/icons/SelectAllOutlined'
import RotateDisabledIcon from '@material-ui/icons/SyncDisabledOutlined'
import { Button } from 'opensolar-ui'
import React, { useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'

type PropTypes = {
  handleClose: Function
  forcePopoverReposition: Function
}

const useStyles = makeStyles((theme: any) => ({
  button: {
    padding: 5,
    width: 50,
    height: 50,
  },
  buttonActive: {
    padding: 5,
    width: 50,
    height: 50,
    background: theme.blue,
    color: 'rgb(255,255,255)',
    '&:hover': {
      background: theme.blue,
    },
  },
  icon: {
    maxWidth: 20,
    maxHeight: 20,
  },
  label: {
    fontSize: 8,
    display: 'flex',
    lineHeight: '10px',
    height: 40,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}))

const PopoverTools: React.FC<PropTypes> = ({ handleClose, forcePopoverReposition }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const notify = useNotify()
  const [showMore, setShowMore] = useState(false)
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Button
            classes={{ root: classes.button, label: classes.label }}
            onClick={() => {
              if (window.ViewHelper) {
                window.editor.execute(
                  new window.ReorderViewsCommand(window.ViewHelper, window.ViewHelper.selectedView(), 'down')
                )
              }
              handleClose()
            }}
          >
            <MoveDownIcon classes={{ root: classes.icon }} style={{ marginRight: 0 }} />
            <span>{translate('Reorder')}</span>
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            classes={{ root: classes.button, label: classes.label }}
            disabled={window.ViewHelper && window.ViewHelper.views && window.ViewHelper.views.length < 2}
            onClick={() => {
              if (window.ViewHelper && window.ViewHelper.views && window.ViewHelper.views.length > 1) {
                if (window.Designer.controlMode && window.Designer.controlMode !== 'both') {
                  notify(translate('Unable to delete a view while in align-map mode'), 'danger')
                } else {
                  const object = window.editor.selected
                  var confirmationPrompt = object.confirmBeforeDelete
                    ? object.confirmBeforeDelete()
                    : translate('Delete this view?')

                  if (window.confirm(confirmationPrompt) === false) return
                  if (object.type === 'ViewDummy') window.editor.deleteObject(object)
                }
              }
            }}
          >
            <DeleteIcon classes={{ root: classes.icon }} style={{ marginRight: 0 }} />
            <span>{translate('Delete')}</span>
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            classes={{ root: showMore ? classes.buttonActive : classes.button, label: classes.label }}
            onClick={() => {
              setShowMore(!showMore)
              forcePopoverReposition()
            }}
          >
            <MoreHorizIcon classes={{ root: classes.icon }} style={{ marginRight: 0 }} />
            <span>{translate('More')}</span>
          </Button>
        </Grid>
      </Grid>
      {showMore && (
        <Fade in={showMore} style={{ transformOrigin: '0 0 0' }} {...(showMore ? { timeout: 1000 } : {})}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Button
                classes={{ root: classes.button, label: classes.label }}
                onClick={() => {
                  if (window.ViewHelper) {
                    //window.ViewHelper.duplicateView(window.ViewHelper.selectedView())
                    window.editor.execute(
                      new window.DuplicateViewCommand(window.ViewHelper.selectedView().uuid, window.ViewHelper)
                    )
                  }
                }}
              >
                <DuplicateIcon classes={{ root: classes.icon }} style={{ marginRight: 0 }} />
                <span>{translate('Duplicate')}</span>
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                classes={{ root: classes.button, label: classes.label }}
                onClick={async () => {
                  // window.SceneHelper.addGoogleObliques()
                  var originalEditorMode = window.editor.displayMode ? window.editor.displayMode : 'hidden'
                  var discardChanges = true

                  await OsGoogle.addGoogleObliquesAfterDetection(window.editor.scene.sceneOrigin4326, true)

                  // Mark loading complete only when views are finished being added
                  window.editor.setMode(originalEditorMode, discardChanges)

                  window.editor.signals.escapePressed.dispatch()
                }}
              >
                <LinkedCameraIcon classes={{ root: classes.icon }} style={{ marginRight: 0 }} />
                <span>{translate('Add Obliques')}</span>
              </Button>
            </Grid>
            {false && (
              <Grid item xs={4}>
                <Button
                  classes={{ root: classes.button, label: classes.label }}
                  onClick={(e) =>
                    window.editor.execute(
                      new window.SetSkewCommand(window.ViewHelper, undefined, undefined, {}, {}, 'open')
                    )
                  }
                >
                  <FixSkewIcon classes={{ root: classes.icon }} style={{ marginRight: 0 }} />
                  <span>{translate('Fix Skew')}</span>
                </Button>
              </Grid>
            )}

            <Grid item xs={4}>
              <Button
                classes={{
                  root:
                    window.ViewHelper.selectedView() && window.ViewHelper.selectedView().allowTilt
                      ? classes.buttonActive
                      : classes.button,
                  label: classes.label,
                }}
                onClick={(e) =>
                  window.editor.execute(
                    new window.SetViewValueCommand(
                      window.ViewHelper.selectedView(),
                      'allowTilt',
                      !window.ViewHelper.selectedView().allowTilt,
                      window.ViewHelper
                    )
                  )
                }
              >
                {window.ViewHelper.selectedView() && window.ViewHelper.selectedView().allowTilt ? (
                  <RotateIcon classes={{ root: classes.icon }} style={{ marginRight: 0 }} />
                ) : (
                  <RotateDisabledIcon classes={{ root: classes.icon }} style={{ marginRight: 0 }} />
                )}
                <span>{translate('Allow Tilt')}</span>
              </Button>
            </Grid>

            {(window.ViewHelper.getViewByUuid(window.ViewHelper.selectedViewUuid()).mapData.mapType === 'None' ||
              window.MapData.mapTypeIs3D(
                window.ViewHelper.getViewByUuid(window.ViewHelper.selectedViewUuid()).mapData.mapType
              )) && (
              <Grid item xs={4}>
                <Button
                  classes={{
                    root:
                      window.ViewHelper.getViewByUuid(window.ViewHelper.selectedViewUuid()).facetDisplayModeOverride ===
                      'alternative'
                        ? classes.buttonActive
                        : classes.button,
                    label: classes.label,
                  }}
                  onClick={(e) =>
                    window.ViewHelper.toggleFacetDisplayModeForView(
                      window.ViewHelper.getViewByUuid(window.ViewHelper.selectedViewUuid())
                    )
                  }
                >
                  <PlansetIcon classes={{ root: classes.icon }} style={{ marginRight: 0 }} />
                  <span>{translate('Alternative Textures')}</span>
                </Button>
              </Grid>
            )}

            {window.MapData.mapTypeIs3D(
              window.ViewHelper.getViewByUuid(window.ViewHelper.selectedViewUuid()).mapData.mapType
            ) && (
              <Grid item xs={4}>
                <Button
                  classes={{
                    root: (window.editor.getTerrain() ? window.editor.getTerrain().useHeatmap() : false)
                      ? classes.buttonActive
                      : classes.button,
                    label: classes.label,
                  }}
                  onClick={(e) => {
                    window.editor.getTerrain().useHeatmap(!window.editor.getTerrain().useHeatmap())
                    // This should probably trigger viewsChanged but we use transformModeChanged to avoid
                    // needing to listen to viewsChanged which fires all the time and would need
                    // optimization
                    window.editor.signals.transformModeChanged.dispatch()

                    // Also call viewsChanged because ViewControllers listens to viewsChanged
                    // for updating the bottom-right shade/align-map/zoom buttons.
                    window.editor.signals.viewsChanged.dispatch()
                  }}
                >
                  <BrightnessIcon classes={{ root: classes.icon }} />
                  <span>{translate('View Shade')}</span>
                </Button>
              </Grid>
            )}
          </Grid>
        </Fade>
      )}
    </>
  )
}

export default PopoverTools

import { Tooltip } from '@material-ui/core'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import Button from 'elements/button/Button'
import { CSSProperties, FC, useState } from 'react'
import { useNotify } from 'react-admin'
import { useField, useForm } from 'react-final-form'
import restClient from 'restClient'

type Props = {
  orgId: number
  projectId: number
  style?: CSSProperties
}

export const ProjectDuplicateButton: FC<Props> = ({ orgId, projectId, style }) => {
  const [isLoading, setIsLoading] = useState(false)
  const notify = useNotify()
  const form = useForm()
  const dirtyFields = form.mutators.getFormDirtyFields()
  const formDirty = dirtyFields.length > 0
  const systemsReady: boolean = !useField('simulate_first_year_only', {
    subscription: { value: true },
  }).input.value
  const disableDuplication = formDirty || !systemsReady

  const toolTipMessage = () => {
    if (formDirty) return 'Save changes to enable project duplication'
    if (!systemsReady) return 'System calculations ongoing, duplication will be available in a few seconds'
    return 'Project duplication disabled'
  }

  const sendDuplicateRequest = () => {
    const restClientInstance = restClient(window.API_ROOT + '/api')
    const url = 'orgs/' + orgId + '/projects/' + projectId + '/duplicate/'
    setIsLoading(true)
    restClientInstance('CUSTOM_POST', 'custom', {
      url,
    })
      .then((content) => {
        setIsLoading(false)
        notify('Project duplicated', 'success', {
          //@ts-ignore
          buttons: [
            {
              label: 'Go to project',
              action: () =>
                window.open(`${window.location.origin}/#/projects/${content.data.project_id}/info`, '_blank'),
            },
          ],
        })
      })
      .catch(() => {
        setIsLoading(false)
        notify('Project duplication failed', 'warning')
      })
  }

  const duplicateButton = (
    <Button
      label="Duplicate Project"
      startIcon={<FileCopyOutlinedIcon />}
      onClick={sendDuplicateRequest}
      loading={isLoading || !systemsReady}
      disabled={disableDuplication}
      style={style}
    />
  )
  return disableDuplication ? (
    <Tooltip title={toolTipMessage()} enterDelay={300}>
      <span>{duplicateButton}</span>
    </Tooltip>
  ) : (
    duplicateButton
  )
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useMemo } from 'react';
/**
 * Allows for specifying the version of the component to be used in this
 * part of the application hierarchy.
 *
 * Using `ComponentVersionsContext.Provider` won't inherit undefined component
 * versions from the parent context.
 * Do do this, instead use `ComponentVersionsInherit`.
 *
 * Usage:
 * ```
 *  <ComponentVersionsInherit versions={{button:3, chip:2, iconButton:1}}>
 *      <MySection {...props} />
 *  </ComponentVersionsInherit>
 * ```
 *
 * In OS main we have a set of configs for this purpose (spa/app/src/constants/uxVersions.ts).
 * Example:
 * ```
 *  <ComponentVersionsInherit versions={ComponentVersions_3_0}>
 *      <MySection {...props} />
 *  </ComponentVersionsInherit>
 * ```
 */
export var ComponentVersionsContext = createContext({});
export var ComponentVersionsInherit = function (_a) {
    var children = _a.children, versions = _a.versions;
    var parentContext = useContext(ComponentVersionsContext);
    var resolvedVersions = useMemo(function () {
        return __assign(__assign({}, parentContext), versions);
    }, [parentContext, versions]);
    return _jsx(ComponentVersionsContext.Provider, __assign({ value: resolvedVersions }, { children: children }));
};
// This is a version of the context which loads it's settings from localStorage, for debugging purposes
export var ComponentVersionsStoredKey = 'ui-component-versions';
export var ComponentVersionsStoredRoot = function (_a) {
    var children = _a.children;
    var versions = parseStoredComponentVersions();
    return _jsx(ComponentVersionsContext.Provider, __assign({ value: versions }, { children: children }));
};
export var parseStoredComponentVersions = function () {
    var versionsStr = localStorage.getItem(ComponentVersionsStoredKey);
    var versions;
    if (versionsStr) {
        try {
            versions = JSON.parse(versionsStr);
        }
        catch (e) {
            console.warn('Failed to parse stored component versions from localStorage: ', ComponentVersionsStoredKey);
        }
    }
    if (!versions)
        versions = {};
    return versions;
};
export var setStoredComponentVersion = function (component, version) {
    var versions = parseStoredComponentVersions();
    versions[component] = version;
    localStorage.setItem(ComponentVersionsStoredKey, JSON.stringify(versions));
};

import CustomForm from 'customForm/CustomForm'
import { FormPlacementInfo } from 'customForm/util/findFormPlacements'
import { DynamicIcon } from 'elements/DynamicIcon'
import React from 'react'
import { useForm, useFormState } from 'react-final-form'
import { StudioSystemType } from 'types/global'
import { ContactFormExpandSection } from './ContactFormExpandSection'

type PropTypes = {
  customForm: FormPlacementInfo
  system?: StudioSystemType
}

export const CustomFormExpandSection: React.FC<PropTypes> = ({ customForm, system }) => {
  const configuration = customForm.form.configuration
  const placement = customForm.placements[0]
  const parentForm = useForm()
  const parentFormState = useFormState()

  if (!configuration) return null
  return (
    <ContactFormExpandSection
      startIcon={placement.icon && <DynamicIcon icon={placement.icon} />}
      sectionTitle={customForm.form.title}
      defaultExpanded={placement.start_open}
    >
      <CustomForm
        key={`custom-form-${customForm.form.id}`}
        customForm={customForm.form}
        isLoading={!configuration}
        selectedSystem={system}
        parentForm={parentForm}
        parentFormState={parentFormState}
        unlinkValidation={true}
      />
    </ContactFormExpandSection>
  )
}

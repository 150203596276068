type ActionListener = (payload: any) => boolean | void
let listeners: ActionListener[] = []

/**
 * This is a utility reducer that allows external code to listen to actions.
 */

export default function reducer(previousState = null, payload) {
  if (listeners.length) {
    const newListeners: ActionListener[] = []
    for (const listener of listeners) {
      if (!listener(payload)) {
        newListeners.push(listener)
      }
    }
    listeners = newListeners
  }
  return previousState
}

// Listener return true to be removed
export function useActionsListener(listener: ActionListener) {
  listeners.push(listener)
}

import { Paper, Slider } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import withMediaQuery from 'layout/withMediaQuery'
import { IconButton } from 'opensolar-ui'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withTranslate } from 'react-admin'

import compose from 'recompose/compose'

import { applyToModuleGrid, clearTypingInFieldOnUnmount, stripKeyForActiveTextField } from '../Studio/Utils'

import withStudioSignals from 'Studio/signals/withStudioSignals'
import { closablePanels } from 'util/closablePanels'
import styles from './styles'

const name = 'OverlaySlope'

class OverlaySlope extends Component {
  constructor(props) {
    super(props)

    var _state = {
      visible: Boolean(props.visible),
      slopeRaw: (this.props.moduleGrid && this.props.moduleGrid.getSlope()) || '',
    }

    stripKeyForActiveTextField.call(this, _state, this.props.moduleGrid)

    this.state = _state
  }

  close() {
    window.setOverlay(null)
  }

  componentDidMount() {
    closablePanels.add(name, this.close, this)
    this.props.useStudioSignalsLazy(this.refreshPanel, ['objectChanged', 'sceneGraphChanged'], this)
  }

  componentWillUnmount() {
    closablePanels.remove(name)
    clearTypingInFieldOnUnmount.call(this)
  }

  refreshPanel() {
    const slopeRaw = this.props.moduleGrid && this.props.moduleGrid.getSlope()
    if (slopeRaw !== this.state.slopeRaw) {
      this.setState({
        slopeRaw,
      })
    }
  }

  render() {
    if (!this.state.visible) {
      return null
    }

    const { translate } = this.props

    return (
      <Paper
        style={Object.assign({}, this.props.layout >= 2 ? styles.overlayLayoutTablet : styles.overlayLayoutMobile, {
          opacity: this.state.transparent ? 0.25 : 1,
        })}
      >
        <h4>{translate('Set Slope')}</h4>

        <IconButton
          style={{
            position: 'absolute',
            right: 10,
            top: 10,
            width: 40,
            minWidth: 40,
            height: 50,
            bottom: 24,
          }}
          onClick={this.close.bind(this)}
        >
          {<CloseIcon />}
        </IconButton>

        <Slider
          style={{
            marginTop: 10,
            marginBottom: 10,
            padding: 0,
          }}
          value={this.props.moduleGrid.getSlope()}
          min={-1}
          max={60}
          onChange={(event, newValue) => {
            var newSlope = Math.round(newValue % 360)
            //Negative values will map to null value and empty string for textfield
            this.setState({
              slopeRaw: newSlope < 0 ? '' : newSlope,
            })
            applyToModuleGrid.bind(this)(this.props.moduleGrid, undefined, newSlope)

            this.setState({ transparent: true })
          }}
          onChangeCommitted={() => this.setState({ transparent: false })}
        />
      </Paper>
    )
  }
}

OverlaySlope.propTypes = {
  visible: PropTypes.bool,
}

OverlaySlope.defaultProps = {
  visible: true,
}

export default compose(withTranslate, withMediaQuery, withStudioSignals)(OverlaySlope)

import { authSelectors } from 'ducks/auth'
import BulkActionButtons from 'elements/button/BulkActionButtons'
import React from 'react'
import { useSelector } from 'react-redux'

export const useBulkActionButtons = (
  props: any,
  allowEdit: boolean,
  allowDelete: boolean,
  hasArchived: boolean = false
) => {
  const connectedOrgs = useSelector(authSelectors.getActiveConnectedOrgs)
  const allowBulkEdit = allowEdit && connectedOrgs.length
  const allowBulkDelete = allowDelete

  if (allowBulkEdit || allowBulkDelete)
    return (
      <BulkActionButtons
        enableBulkArchive={hasArchived}
        enableBulkSharing={allowBulkEdit}
        enableBulkDelete={allowBulkDelete}
        {...props}
      />
    )

  return undefined
}

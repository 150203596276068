import { Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, useTheme } from '@material-ui/core'
import GenericButton from 'elements/button/GenericButton.js'
import CustomSelectField from 'elements/field/CustomSelectField'
import { Button } from 'opensolar-ui'
import {
  GenerateAndDownloadPvsellProposal,
  ajaxPost,
  listProposals,
} from 'projectSections/sections/proposal/PvsellProposalDialog'
import React from 'react'
import { withTranslate } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import restClient from 'restClient'
import {
  selectPvsellProposal as selectPvsellProposalAction,
  setPvsellDialogVisibility as setPvsellDialogVisibilityAction,
} from '../../ducks/pvsell.js'
import { getOrgFromState } from '../../util/org'

const pvsellDomain = window.SUNWIZ_API_DOMAIN

const getProposalWithId = (proposals, selected_proposal_id) =>
  proposals.filter(
    (item) => !selected_proposal_id || item.pdf_url.indexOf('=' + selected_proposal_id + '&project_id=') !== -1
  )[0]

const EditPvsellProposal = (api_key_pvsell, project_id, selected_proposal_id, onComplete) => {
  // fetch the data from myEnergy then POST it to Pvsell.

  var pvsellPostUrl = pvsellDomain + '/standalone/project/save?access-token=' + api_key_pvsell

  restClient(window.API_ROOT + '/api')('CUSTOM_GET', 'custom', {
    url: 'user_logins/?project_ids=' + project_id,
  }).then((response) => {
    var proposalData = response.data

    ajaxPost(
      pvsellPostUrl,
      {
        project_id: project_id,
        data: JSON.stringify(proposalData),
      },
      {
        success: function (data) {
          console.log(data)

          listProposals(project_id, api_key_pvsell, {
            success: (data) => {
              console.log(data)
              var selected_proposal = getProposalWithId(data.data, selected_proposal_id)
              if (selected_proposal) {
                console.log(selected_proposal)
                window.open(selected_proposal.update_url, '_blank')
              } else {
                console.warn('selected proposal not found')
              }
            },
            error: (err) => {},
            complete: () => {},
          })
        },
        error: function (err) {
          // handle your error logic here
          console.log(err)
          onComplete()
        },
      }
    )

    onComplete()
  })
}

const _PvsellProposalDialog = ({
  translate,
  setPvsellDialogVisibility,
  pvsellDialogVisibility,
  pvsell_proposals,
  pvsell_selected_proposal_id,
  selectPvsellProposal,
  api_key_pvsell,
  project_id,
}) => {
  const theme = useTheme()
  return (
    <div>
      <Dialog open={Boolean(pvsellDialogVisibility)}>
        <DialogTitle>Generate SunWiz Proposal</DialogTitle>
        <DialogContent>
          <p>Proposal data will be sent to SunWiz for proposal generation.</p>
          {pvsell_proposals && (
            <CustomSelectField
              value={pvsell_selected_proposal_id}
              label={'Proposal Template'}
              variant="outlined"
              style={{ width: 256, margin: 0 }}
              onChange={(event, key, payload) => {
                selectPvsellProposal(event.target.value)
              }}
            >
              {pvsell_proposals.map((pvsell_proposal) => {
                try {
                  return (
                    <MenuItem value={pvsell_proposal.proposal_id} key={pvsell_proposal.proposal_id}>
                      {pvsell_proposal.name}
                    </MenuItem>
                  )
                } catch (e) {
                  return null
                }
              })}
            </CustomSelectField>
          )}
          <DialogActions>
            <Button onClick={() => setPvsellDialogVisibility(false)}>
              <span>{translate('Cancel')}</span>
            </Button>
            <GenericButton
              labelColor="#000000"
              backgroundColor={theme.themeColor}
              backgroundColorOnHover={theme.themeColorDark}
              onClick={() =>
                EditPvsellProposal(api_key_pvsell, project_id, pvsell_selected_proposal_id, () =>
                  setPvsellDialogVisibility(false)
                )
              }
              label={translate('Edit Template')}
            />
            <GenericButton
              labelColor="#FFFFFF"
              backgroundColor="#42C310"
              backgroundColorOnHover="#42C310"
              style={{ padding: 5 }}
              onClick={() =>
                GenerateAndDownloadPvsellProposal(
                  project_id,
                  pvsell_selected_proposal_id,
                  () => setPvsellDialogVisibility(false),
                  'pdf'
                )
              }
              label={translate('Generate & Download PDF')}
            />
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    project_id: state.pvsell && state.pvsell ? state.pvsell.project_id : null,
    api_key_pvsell: getOrgFromState(state) && getOrgFromState(state).api_key_pvsell,
    pvsell_proposals: state.pvsell && state.pvsell ? state.pvsell.proposals : [],
    pvsellDialogVisibility: state.pvsell && state.pvsell.pvsellDialogVisibility,
    pvsell_selected_proposal_id: state.pvsell && state.pvsell ? state.pvsell.selected_proposal_id : [],
  }
}

export const PvsellProposalDialog = compose(
  withTranslate,
  connect(mapStateToProps, {
    setPvsellDialogVisibility: setPvsellDialogVisibilityAction,
    selectPvsellProposal: selectPvsellProposalAction,
  })
)(_PvsellProposalDialog)

export default PvsellProposalDialog

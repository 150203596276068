import { InputProps, useInput, useReferenceInputController } from 'ra-core'
import React, { FunctionComponent, ReactElement } from 'react'

import { ReferenceInputView, ReferenceInputViewProps } from 'ra-ui-materialui'

/** This custom ReferenceInput includes an additional prop: ignoreWarnings which allows
 * the use of ReferenceInput while ignoring input warnings based on the string key passed into
 * the prop. The key used must be part of the referenceInputWarnings object added below to
 * successfully match and ignore a specific warning.
 */

interface Props {
  allowEmpty?: boolean
  basePath: string
  children: ReactElement
  classes?: any
  className?: string
  label: string
  reference: string
  resource: string
  [key: string]: any
}

const referenceInputWarnings = {
  associatedReference: 'Associated reference no longer appears to be available.',
}

type ReferenceInputProps = Props & InputProps & Partial<ReferenceInputViewProps>
const UnresolvableReferenceInput: FunctionComponent<ReferenceInputProps> = ({
  format,
  onBlur,
  onChange,
  onFocus,
  parse,
  validate,
  ignoreWarnings,
  ...props
}) => {
  const inputProps = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    validate,
    ...props,
  })

  const controllerParts = useReferenceInputController({ ...props, ...inputProps })
  if (ignoreWarnings) {
    ignoreWarnings.forEach((key) => {
      if (
        Object.keys(referenceInputWarnings).includes(key) &&
        controllerParts?.warning?.includes(referenceInputWarnings[key])
      ) {
        controllerParts.warning = undefined
      }
    })
  }
  return <ReferenceInputView {...inputProps} {...props} {...controllerParts} />
}

UnresolvableReferenceInput.defaultProps = {
  filter: {},
  filterToQuery: (searchText) => (searchText ? { q: searchText } : {}),
  perPage: 20,
  sort: { field: 'id', order: 'DESC' },
}

export default UnresolvableReferenceInput

import { setGenerateDocumentDialog } from 'ducks/generateDocument'
import { CameraIcon, FileOutlineIcon, UploadIcon } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { FileDialogType, FilesContext, FilesContextProps } from '../FilesContextWrapper'
import { StyledMenu, StyledMenuItem } from '../styles/styles'
interface NewFileMenuProps {
  anchorEl: HTMLElement | null
  setAnchorEl: any
}

export const NewFileMenu: React.FC<NewFileMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const { setDialogSelected, dialogSelected } = useContext(FilesContext) as FilesContextProps

  useEffect(() => {
    if (dialogSelected) setAnchorEl(null)
  }, [dialogSelected, setAnchorEl])

  const handleClose = () => setAnchorEl(null)

  const handleMenuItemClick = (action: FileDialogType, dispatchAction?: () => void) => {
    if (dispatchAction) dispatch(dispatchAction())
    setDialogSelected(action)
  }

  return (
    <StyledMenu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
      <StyledMenuItem onClick={() => handleMenuItemClick('generate_file', () => setGenerateDocumentDialog(true))}>
        <FileOutlineIcon variant={2} /> {translate('Generate File')}
      </StyledMenuItem>
      <StyledMenuItem onClick={() => handleMenuItemClick('upload_file')}>
        <UploadIcon /> {translate('Upload File')}
      </StyledMenuItem>
      <StyledMenuItem onClick={() => handleMenuItemClick('upload_site_photos')}>
        <CameraIcon /> {translate('Upload Site Photos')}
      </StyledMenuItem>
    </StyledMenu>
  )
}

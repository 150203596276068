import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import { useTranslate } from 'ra-core'
import React, { useEffect, useState } from 'react'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { LENDER_LOGO_PATHS } from 'resources/financeIntegrations/constants'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { useGetSortedIntegrations } from '../hooks'
import PromoBannerDialog from './PromoBannerDialog'

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    width: 'calc(100% - 4px)',
    padding: '2px',
  },
  banner: {
    width: 'calc(100% - 4px)',
    height: '100%',
    display: 'flex',
    borderColor: theme.greyMid2,
    borderWidth: '2px',
    borderStyle: 'solid',
    borderTopWidth: '0px',
    padding: '2px',
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flex: 1,
    padding: '10px',
  },
  titleText: {
    fontSize: '24px',
    fontWeight: 200,
    marginBottom: '10px',
  },
  linkText: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  middle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    flex: 3,
  },
  logoWrapper: {
    margin: '14px',
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

type PropTypes = {}

const PaymentsPromoBanner: React.FC<PropTypes> = (props) => {
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const project = useFormState().values
  const availableIntegrations = useGetSortedIntegrations(!!project.is_residential, !project.is_residential, true, false)
  const activeIntegrations = useGetSortedIntegrations(!!project.is_residential, !project.is_residential, false, true)

  const classes = useStyles()
  const translate = useTranslate()
  const isAdmin = useSelector(authSelectors.getIsAdmin)

  const openDialog = (placementClicked: 'learn more' | 'button') => {
    setShowDialog(true)
    logAmplitudeEvent('payments_page_promo_dialog_opened', { is_admin: isAdmin, placement: placementClicked })
  }

  useEffect(() => {
    if (!activeIntegrations?.length && availableIntegrations?.length) {
      logAmplitudeEvent('payments_page_promo_banner_shown', { is_admin: isAdmin })
      availableIntegrations.forEach((integration, i) => {
        logAmplitudeEvent('integration_included_in_payments_promo_banner', {
          integration: integration.integration_name,
          index: i,
        })
      })
    }
  }, [activeIntegrations, availableIntegrations])
  if (activeIntegrations?.length || !availableIntegrations?.length) return null
  return (
    <div className={classes.wrapper}>
      <div className={classes.banner}>
        <div className={classes.leftColumn}>
          <div className={classes.titleText}>{translate('Activate Finance Integrations')}</div>
          <div>
            {translate('Unlock Sales, Sell faster')}{' '}
            <a className={classes.linkText} onClick={() => openDialog('learn more')}>
              {translate('Learn more')}
            </a>
          </div>
        </div>
        <div className={classes.middle}>
          {availableIntegrations.map((integration) => (
            <div key={integration.integration_name} className={classes.logoWrapper}>
              <img
                src={
                  integration.logo_large_contents ||
                  `${window.PUBLIC_URL}/images/${LENDER_LOGO_PATHS[integration.integration_name]?.large}`
                }
                height="20px"
              />
            </div>
          ))}
        </div>
        <div className={classes.right}>
          {isAdmin && (
            <ProUXButton
              id="set_up_finance_btn"
              type="secondary"
              onClick={() => openDialog('button')}
              label={translate('Set up Finance Integrations')}
            />
          )}
        </div>
      </div>
      {showDialog && <PromoBannerDialog onClose={() => setShowDialog(false)} />}
    </div>
  )
}
export default PaymentsPromoBanner

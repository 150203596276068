import { useMemo } from 'react'
import { MountingID, mountingSystemDisplayInfo, PartnerAccessoriesID } from 'types/mounting'
import ReusableSystemCapsule from './ReusableSystemCapsule'

export const mountingSystems: mountingSystemDisplayInfo[] = [
  { displayName: 'Viridian', id: 'viridian', imageFile: 'viridian_logo.jpg', automated: true },
  { displayName: 'SolarRoof Pro', id: 'solarRoofPro', imageFile: 'clenergy_logo.png', automated: true },
  { displayName: 'Renusol Configurator', id: 'renusol', imageFile: 'renusol_logo.jpg' },
  { displayName: 'Renusol VS+', id: 'variosole', imageFile: 'renusol_logo.jpg', automated: true },
  { displayName: 'Esdec ClickFit EVO', id: 'esdec', imageFile: 'esdec_logo.png', automated: true },
  { displayName: 'Esdec FlatFix Fusion', id: 'flatFix', imageFile: 'esdec_logo.png', automated: true },
  { displayName: 'Renusol MS+, MS+P', id: 'metasole', imageFile: 'renusol_logo.jpg', automated: true },
  { displayName: 'Esdec', id: 'esdec', imageFile: 'esdec_logo.png' },
  { displayName: 'Schletter', id: 'schletter', imageFile: 'schletter_logo.png', automated: true },
  { displayName: 'Fastensol', id: 'fastensol', imageFile: 'fastensol_logo.png', automated: true },
  {
    displayName: 'MS Pitched ProLine',
    id: 'msPitchedProLine',
    imageFile: 'ms-pitched-pro-line-logo.svg',
    automated: true,
  },
  {
    displayName: 'MS Trapeze ProLine',
    id: 'msTrapezeProLine',
    imageFile: 'ms-pitched-pro-line-logo.svg',
    automated: true,
  },
  {
    displayName: 'GSE In-Roof',
    id: 'gseInRoof',
    imageFile: 'gse-logo.png',
    automated: true,
  },
  {
    displayName: 'SolaSkirt',
    id: 'solaskirt',
    imageFile: 'solaskirt_logo.png',
  },
]

const MountingSystemCapsule = ({
  integrationName,
  onClick,
}: {
  integrationName: MountingID | PartnerAccessoriesID
  onClick?(): void
}) => {
  const selectedMountingSystemConfig = useMemo(() => mountingSystems.find((config) => config.id === integrationName), [
    integrationName,
  ])
  if (selectedMountingSystemConfig === undefined) return null
  return <ReusableSystemCapsule config={selectedMountingSystemConfig} onClick={onClick} />
}

export default MountingSystemCapsule

import { orgSelectors } from 'ducks/orgs'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import type { FilterComponentRendererType, FilterGenericPropsType, FilterKeysType, OptionGenericType } from '../type'

export type OwnerOrgFilterOptionsType = OptionGenericType<number | ''>[]

type OwnerOrgFilterProps = FilterGenericPropsType & {
  RendererComponent: FilterComponentRendererType<OwnerOrgFilterOptionsType>
  filterKey: FilterKeysType
}

const OwnerOrgFilter = ({
  disabled,
  filterKey,
  modifiableFilterValues,
  persistentFilterValues,
  allFilterValues,
  setModifiableFilters,
  RendererComponent,
}: OwnerOrgFilterProps) => {
  const formValues = useForm().getState().values
  const sharedWith = formValues.shared_with
  const projectOwnerId = formValues.org_id
  const org = useSelector(orgSelectors.getOrg)
  const connectedOrgs = org?.connected_orgs || []

  const options = useMemo(() => {
    const Options: OwnerOrgFilterOptionsType = [
      {
        title: 'All',
        value: '',
        id: 'default',
      },
    ]
    sharedWith.forEach((detail) =>
      Options.push({
        title: detail.org_name,
        value: detail.org_id,
        id: detail.org_name + detail.org_id,
      })
    )
    if (!Options.find((x) => x.value === projectOwnerId)) {
      if (org?.id !== projectOwnerId) {
        const findOwner = connectedOrgs?.find((x) => x.org_id === projectOwnerId)
        findOwner &&
          Options.unshift({
            id: findOwner.org_name + findOwner.org_id,
            title: findOwner.org_name,
            value: findOwner.org_id,
          })
      } else if (!!org) {
        Options.unshift({ id: org.name + org.id, title: org.name, value: org.id })
      }
    }
    return Options
  }, [])

  const onSelect = useCallback(
    (value) => {
      const newFilterValues = { ...modifiableFilterValues, [filterKey]: value }
      setModifiableFilters(newFilterValues)
    },
    [modifiableFilterValues]
  )

  return (
    <RendererComponent
      id={filterKey}
      defaultValue={''}
      label={'Owner Org'}
      options={options}
      disabled={disabled}
      onSelect={onSelect}
      value={allFilterValues[filterKey]}
    />
  )
}

export default OwnerOrgFilter

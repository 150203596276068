import { CircularProgress } from '@material-ui/core'
import debounce from 'lodash/debounce'
import { CloseOutlineIcon, IconButton, Menu, MenuItem, TextField } from 'opensolar-ui'
import { useNotify } from 'ra-core'
import { useCallback, useState } from 'react'
import { Field, useField, useForm } from 'react-final-form'
import appStorage from 'storage/appStorage'
import { getReadableLabel } from 'util/misc'
import { required as fieldRequired } from 'validations'
import { DeviceType, EnaLctDataset } from '../consts'
import { ListCalloutBox } from '../elements/ListCalloutBox'

export interface DeviceSearchFieldProps {
  fieldName: string
  parentPath: string
  schema?: any
  deviceType: DeviceType | 'G100_DEVICE'
  enaLctDataset: EnaLctDataset
  onReset?: any
}

export const DeviceSearchField: React.FC<DeviceSearchFieldProps> = (props) => {
  const { fieldName, parentPath, deviceType, enaLctDataset } = props
  const path = `${parentPath}.${fieldName}`
  const [options, setOptions] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const notify = useNotify()

  const form = useForm()

  const deviceSysRefSearchResultPath = `${parentPath}.supplementingInfo.deviceSysRefSearchResult`

  const {
    input: { value: deviceSysRefSearchResult },
    meta,
  } = useField(deviceSysRefSearchResultPath, { subscription: { value: true, dirty: true, touched: true } })

  const {
    input: { value: phases },
  } = useField('supplyDetails.phaseCode', { subscription: { value: true, dirty: true, touched: true } })

  const getDeviceSuggestions = async (searchTerm): Promise<any> => {
    try {
      let url = `${window.API_ROOT}/api/get_ena_device_options/?search_term=${searchTerm}&device_type=${deviceType}&dataset=${enaLctDataset}&jurisdiction=GB`
      if (phases) {
        url = url + `&phases=${phases}`
      }
      const res = await fetch(url, {
        method: 'GET',
        headers: { Authorization: 'Bearer ' + appStorage.getToken() },
      })
      const { statusText, ok, status } = res
      if (!ok) throw new Error(`${status} ${statusText}`)
      try {
        return await res.json()
      } catch (jsonParseError) {
        throw new Error('Invalid JSON response received from the server')
      }
    } catch (e) {
      notify(`Error retrieving device suggestions : ${e}`, 'error')
      console.error(`Error ${e}`)
    }
  }

  const debouncedSearch = useCallback(
    debounce(async (searchTerm: string) => {
      if (searchTerm && searchTerm.length > 4) {
        setLoading(true)
        const result = await getDeviceSuggestions(searchTerm)
        if (Array.isArray(result?.data)) {
          setOptions(result.data)
          setLoading(false)
          setAnchorEl(document.getElementById(`search--${path}`))
        }
      } else setAnchorEl(null)
    }, 200),
    []
  )

  const resetSelection = () => {
    form.change(parentPath, {})
    if (props.onReset) {
      props.onReset()
    }
  }

  return (
    <div style={{ paddingTop: 16, paddingBottom: 16 }}>
      <Field name={path} required validate={fieldRequired}>
        {({ input }) => (
          <>
            <TextField
              id={`search--${path}`}
              label="Search"
              variant="outlined"
              fullWidth
              onChange={(e) => {
                const newValue = e.target.value
                input.onChange(e.target.value)
                debouncedSearch(newValue)
              }}
              value={input.value}
              InputProps={{
                endAdornment: loading ? (
                  <CircularProgress size={20} />
                ) : deviceSysRefSearchResult?.deviceRef ? (
                  <IconButton type="button" onClick={resetSelection}>
                    <CloseOutlineIcon color="red" />
                  </IconButton>
                ) : null,
              }}
              placeholder="Start typing to search for a device"
            />
            <Menu
              id={`${path}-ena-search`}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              {options.length > 0 ? (
                options.map((option, index) => (
                  <MenuItem
                    key={option.deviceRef}
                    onClick={() => {
                      form.change(deviceSysRefSearchResultPath, option)
                      form.change(path, option.deviceRef)
                      setAnchorEl(null)
                    }}
                  >
                    {option.displayValue}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No results</MenuItem>
              )}
            </Menu>
          </>
        )}
      </Field>
      {deviceSysRefSearchResult?.deviceRef && (
        <div style={{ marginTop: 20 }} key={deviceSysRefSearchResult?.deviceRef}>
          <ListCalloutBox
            listItems={Object.keys(deviceSysRefSearchResult)
              .slice(2)
              .map((k) => {
                const readableName = getReadableLabel(k)
                return `${readableName}: ${deviceSysRefSearchResult[k]}`
              })}
            title={deviceSysRefSearchResult.displayValue}
          />
        </div>
      )}
    </div>
  )
}

import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { IconButton } from 'opensolar-ui'
import React from 'react'
import ProjectOrderPresenter from '../../ProjectOrderPresenter/projectOrderPresenter'

export const ActionHeader = () => {
  return (
    <IconButton>
      <MoreVertIcon />
    </IconButton>
  )
}

type DeleteActionProps = {
  disabled: boolean
  uuid: string
  orderingPresenter: ProjectOrderPresenter
}

export const DeleteAction = ({ disabled, uuid, orderingPresenter }: DeleteActionProps) => {
  const handleClick = () => {
    // use uuid for deleting groupedLineItem
    orderingPresenter.deleteLineItemByUuid(uuid)
    logAmplitudeEvent('hardware_order_table_delete_button_clicked')
  }
  return (
    <IconButton disabled={disabled} onClick={handleClick} style={{ padding: '6px' }}>
      <DeleteOutlinedIcon />
    </IconButton>
  )
}

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { useTranslate } from 'ra-core'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'

const ErrorDialog: React.FC = (props) => {
  const history = useHistory()
  const translate = useTranslate()

  return (
    <Dialog open={true}>
      <DialogTitle>{translate('No Project Workflow Templates Available')}</DialogTitle>
      <DialogContent>
        <p>
          {translate(
            'You must have at least 1 active Project Workflow template configured in your org to edit a project.'
          )}
        </p>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            history.goBack()
          }}
          variant="contained"
          color="default"
        >
          {translate('Go Back')}
        </Button>
        <Link to="/workflows" target="_blank" rel="noreferrer">
          <Button variant="contained" color="primary">
            {translate('Create Project Workflow')}
          </Button>
        </Link>
      </DialogActions>
    </Dialog>
  )
}
export default ErrorDialog

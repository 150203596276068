import { isEmpty } from 'lodash'

export const getShippingFeeCurrencySymbol = (fee: string, currencySymbol: string) => {
  return ['TBC', 'FREE'].includes(fee) ? '' : currencySymbol
}

export const validateSegenData = (data: any) => {
  return (
    !isEmpty(data?.quoteId) && !isEmpty(data?.shippingInfo?.dispatchDate) && !isEmpty(data?.shippingInfo?.dispatchDate)
  )
}

export const validateCityPlumbingData = (data: any) => {
  const isDelivery = data?.shippingInfo?.deliveryMethod === 'delivery'
  if (isDelivery) {
    return !isEmpty(data?.shippingInfo?.deliveryDate) && !isEmpty(data?.shippingInfo?.deliveryTime)
  }
  return !isEmpty(data?.shippingInfo?.deliveryDate) && !isEmpty(data?.shippingInfo?.deliveryBranch?.name)
}

export const validateHdmData = (data: any) => {
  // insert HDM data validation here
  return true
}

export const validateVtacData = (data: any) => {
  // insert VTAC data validation here
  return true
}

export const validateOutletData = (data: any) => {
  if (data?.shippingInfo?.deliveryMethod === 'Pickup at warehouse') {
    return !isEmpty(data?.shippingInfo?.warehouse)
  }
  return !isEmpty(data?.shippingInfo?.deliveryMethod)
}

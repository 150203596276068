import { useMemo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { ComponentTypesV2 } from 'types/selectComponent'
import { formatDecimalPlaces } from 'util/misc'
import { HardwareComponentDataType } from '../types'
import useCommonStyles from './useCommonStyles'
import { getComponentRating } from './utility'

const useStyles = makeOpenSolarStyles(() => ({
  container: {
    padding: '2px 0px',
  },
}))

interface RatingBlockProps {
  componentData?: HardwareComponentDataType
  componentType: ComponentTypesV2
}

const RatingBlock: React.FC<RatingBlockProps> = ({ componentData, componentType }) => {
  const commonClasses = useCommonStyles()
  const classes = useStyles()

  const rating = useMemo(() => {
    const componentRating = getComponentRating(componentType, componentData)
    if (typeof componentRating === 'string') return componentRating

    const { suffix, value } = componentRating
    let rating = typeof value === 'number' && value % 1 > 0 ? formatDecimalPlaces(value, 1) : value
    return `${rating} ${suffix}`
  }, [componentType, componentData])

  return (
    <div className={classes.container}>
      <span className={commonClasses.mainText}>{rating}</span>
    </div>
  )
}

export default RatingBlock

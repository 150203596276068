import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import React, { useMemo } from 'react'
import { useTranslate } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  formControl: {
    width: '100%',
    marginTop: 20,
    '& legend': {
      minWidth: 80,
      maxWidth: 'none',
    },
  },
  select: {
    padding: '10px 24px 10px 12px',
  },
  labelDefault: {
    color: theme.palette.grey[600],
  },
}))

const CustomSelectField = (props) => {
  const { label, value, onChange, children, menuItemProps, emptySelectable, labelStyle = {}, ...otherProps } = props
  const classes = useStyles()
  const translate = useTranslate()

  // 'displayEmpty' requires a custom renderValue function
  if (props.displayEmpty && props.emptyText && !otherProps.renderValue) {
    otherProps.renderValue = (value) => {
      let foundItem
      React.Children.forEach(children, (menuItem) => {
        if (menuItem === value || menuItem.props.value === value) foundItem = menuItem
      })
      return foundItem || props.emptyText
    }
  }

  // 'emptySelectable' adds an additional item to the menu to revert back to an unset state
  // The limitation is that it doesn't allow customisation to how it is rendered.
  let realChildren = useMemo(() => {
    return !emptySelectable
      ? children
      : [
          <MenuItem key={-1} value={props.defaultValue}>
            {props.emptyText || translate('Default')}
          </MenuItem>,
        ].concat(children)
  }, [emptySelectable, children, props.emptyText, props.defaultValue])

  return (
    <FormControl variant="outlined" classes={{ root: classes.formControl }}>
      <InputLabel
        shrink={true}
        classes={{ root: classes.labelDefault }}
        style={labelStyle}
        id="customized-select-label"
      >
        {label}
      </InputLabel>
      <Select
        label={label}
        classes={{ root: classes.select }}
        MenuProps={{ disableScrollLock: true }}
        value={value}
        onChange={onChange}
        {...otherProps}
        style={{ paddingTop: 8 }}
      >
        {React.Children.map(realChildren, (menuItem) => menuItem && React.cloneElement(menuItem))}
      </Select>
    </FormControl>
  )
}

// Force UX1 to use UX2 select field style to avoid problems with switching between them
export default CustomSelectField

import { useCallback, useMemo } from 'react'
import { CURRENT_FILTER_KEY } from '../currentType/CurrentTypeFilterNodeFactory'
import { OTHER_COMPONENT_FILTER_KEY } from '../otherComponentType/OtherComponentTypeFilterNodeFactory'
import type { FilterComponentRendererType, FilterGenericPropsType, FilterValuesType, OptionGenericType } from '../type'
import {
  RatingRangeType,
  getOptionValueByFilterValues,
  getRangeOptionByValue,
  mapRangeOptionsToSelectOptions,
} from './constants'

export type RatingFilterOptionsType = OptionGenericType<string>[]

type CurrentRatingFilterProps = FilterGenericPropsType & {
  RendererComponent: FilterComponentRendererType<RatingFilterOptionsType>
  rangeOptions: RatingRangeType[]
  gteFilterKey: string
  lteFilterKey: string
}

export const isCurrentRatingFilterAvailable = (filterValues: FilterValuesType) => {
  return filterValues[OTHER_COMPONENT_FILTER_KEY] === 'isolator' && filterValues[CURRENT_FILTER_KEY] === 'ac'
}

const CurrentRatingFilter = ({
  rangeOptions,
  gteFilterKey,
  lteFilterKey,
  modifiableFilterValues,
  persistentFilterValues,
  allFilterValues,
  setModifiableFilters,
  RendererComponent,
}: CurrentRatingFilterProps) => {
  const onSelect = useCallback(
    (value) => {
      const matchedRangeOption = getRangeOptionByValue(value, rangeOptions)
      const newFilterValues = {
        ...modifiableFilterValues,
        [gteFilterKey]: matchedRangeOption?.min,
        [lteFilterKey]: matchedRangeOption?.max,
      }
      setModifiableFilters(newFilterValues)
    },
    [modifiableFilterValues, rangeOptions]
  )

  const selectOptions = useMemo(() => {
    return mapRangeOptionsToSelectOptions(rangeOptions)
  }, [rangeOptions])

  const disabled = !isCurrentRatingFilterAvailable(allFilterValues)

  return (
    <RendererComponent
      id={'current_rating_filter'}
      defaultValue={''}
      label={'Current Rating'}
      options={selectOptions}
      disabled={disabled}
      onSelect={onSelect}
      value={getOptionValueByFilterValues({
        ranges: rangeOptions,
        gte: allFilterValues[gteFilterKey],
        lte: allFilterValues[lteFilterKey],
      })}
    />
  )
}

export default CurrentRatingFilter

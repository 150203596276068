import type { InventoryLocationType } from 'pages/inventory/location/type'
import { useMemo } from 'react'
import { SelectInput } from 'react-admin'

const WizardDestinationInput = ({
  installationSiteWarehouse,
  source,
  ...rest
}: {
  source: string
  installationSiteWarehouse: InventoryLocationType | undefined
}) => {
  const { choices, ...referenceInputProps } = rest as any
  const finalChoices = useMemo(() => {
    if (choices.some((choice) => choice.url === installationSiteWarehouse?.url)) {
      const projectAddressOption = choices.find((choice) => choice.url === installationSiteWarehouse?.url)
      projectAddressOption.name = 'Project address'
      return choices
    } else {
      return [...choices, { name: 'Project address', url: installationSiteWarehouse?.url }]
    }
  }, [installationSiteWarehouse, choices.length])

  if (installationSiteWarehouse === undefined) {
    return null
  }
  return (
    <SelectInput
      source={source}
      sortChoices={true}
      optionText="name"
      optionValue="url"
      choices={finalChoices}
      {...referenceInputProps}
    />
  )
}

export default WizardDestinationInput

import { makeStyles } from '@material-ui/core'
import { isEmpty } from 'lodash'
import { AccountManagerIcon, Button, EmailOutlineIcon, Grid } from 'opensolar-ui'
import { FC, memo, useContext } from 'react'
import { Theme } from 'types/themes'
import { useTranslate } from 'ra-core'
import { DISTRBUTOR_KEY_TO_NAME_MAPPING, SOURCE_CONTEXT_MAPPING } from './constants'
import { COLOR_PALETTE, getHardwareSectionPreOrderStyles } from './styles'
import { AccountManagerType, CheckoutSourceType } from './types'

type Props = {
  checkoutSource: CheckoutSourceType
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {},
  rowWrapper: {
    alignItems: 'center',
    columnGap: '12px',
  },
  distributor: {
    fontSize: '14px',
    fontWeight: 550,
    color: COLOR_PALETTE.darkGrey,
  },
  distributorInfo: {
    fontSize: '14px',
    fontWeight: 475,
    color: COLOR_PALETTE.darkGrey,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  sendMessage: {
    fontSize: '12px',
    fontWeight: 600,
    color: COLOR_PALETTE.blue,
  },
}))

const AccountManagerSection: FC<Props> = ({ checkoutSource }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const sectionStyles = getHardwareSectionPreOrderStyles()
  const distributorData = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].distributorDataContext)
  const accountManagerData = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].accountManagerContext)
  return (
    <div className={`${classes.wrapper} ${sectionStyles.moduleFullWidth}`}>
      <div className={sectionStyles.sectionCardTop}>
        <div className={`${sectionStyles.row} ${classes.rowWrapper}`}>
          <div style={{ marginLeft: '-4px' }}>
            <AccountManagerIcon width="20" height="20" />
          </div>
          <span className={sectionStyles.headerTitle}>{translate('Account Managers')}</span>
        </div>
      </div>
      <div className={sectionStyles.sectionCardBottom}>
        <Grid container style={{ gap: '24px' }}>
          {Object.keys(distributorData).map((key) => {
            const accountManager: AccountManagerType | undefined = accountManagerData[key]
            if (isEmpty(accountManager)) return null
            return (
              <Grid container key={key}>
                <Grid item xs={5}>
                  <span className={`${classes.distributor}`}>{DISTRBUTOR_KEY_TO_NAME_MAPPING[key]}</span>
                </Grid>
                <Grid item xs={7} className={sectionStyles.column}>
                  <div className={sectionStyles.row} style={{ columnGap: '10px' }}>
                    <span className={`${classes.distributorInfo}`}>{accountManager?.name}</span>
                    <span className={`${classes.distributorInfo}`}>{accountManager?.contactNumber}</span>
                  </div>
                  {accountManager?.email && (
                    <div className={`${sectionStyles.row} ${classes.rowWrapper}`}>
                      <Button
                        className={`${classes.row}`}
                        onClick={() => (window.location.href = 'mailto:' + accountManager.email)}
                        size="small"
                        startIcon={<EmailOutlineIcon color={COLOR_PALETTE.blue} size={20} />}
                      >
                        <span className={`${classes.sendMessage}`}>{translate('Send Message')}</span>
                      </Button>
                    </div>
                  )}
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </div>
    </div>
  )
}

export default memo(AccountManagerSection)

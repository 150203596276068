import { fetchUtils } from 'ra-core'
import { useEffect, useState } from 'react'
import restClient from 'restClient'
import usePageVisibility from './usePageVisibility'

/*
  Calls an endpoint every Xms and calls onChange with the response.
  If the window is not visible, the polling is disabled.
  It will immediately call the endpoint once the window becomes visible again.
*/
const restClientInstance = restClient(window.API_ROOT + '/api')
export const useEndpointPoller = <T = any>(
  endpoint: string,
  poll_time: number,
  onChange: (a: T) => void,
  opts?: { disabled?: boolean; cache_bust?: boolean }
) => {
  const [hadError, setHadError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [lastResponse, setLastResponse] = useState<string | undefined>()
  const pageVisible = usePageVisibility()

  const disabled = opts?.disabled || !pageVisible

  const doLoad = () => {
    const url = endpoint + (opts?.cache_bust ? Date.now() : '')
    setLoading(true)
    const loadProm =
      url.startsWith('http://') || url.startsWith('https://')
        ? fetchUtils.fetchJson<T>(url)
        : restClientInstance('CUSTOM_GET', 'custom', { url })
    loadProm
      .then((res) => {
        // The two different request methods result in different response structures.
        // TODO: Should unify on a single fetch implementation
        const json = res.json || res.data
        const str = res.body || JSON.stringify(json)

        if (lastResponse === str) return // Don't update if the response hasn't changed
        setHadError(false)
        setLoading(false)
        setLastResponse(str)
        onChange(json as T)
      })
      .catch((err) => {
        if (!hadError) {
          setHadError(true)
          setLoading(false)
          console.warn('Error polling endpoint: ' + endpoint, err)
        }
      })
  }

  useEffect(() => {
    if (disabled) return
    doLoad()
  }, [disabled])

  useEffect(() => {
    if (disabled) return
    const interval = setInterval(doLoad, poll_time)

    return () => clearInterval(interval)
  }, [lastResponse, disabled])

  return { loading, hadError }
}

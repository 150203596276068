import { makeStyles, useMediaQuery } from '@material-ui/core'
import { SchoolOutlined } from '@material-ui/icons'
import HistoryOutlinedIcon from '@material-ui/icons/HistoryOutlined'
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined'
import TimelineRoundedIcon from '@material-ui/icons/TimelineRounded'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { updateCourseData, updateShowLearn } from 'ducks/learning'
import { HomeDrawerState, viewModeActions, viewModeSelectors } from 'ducks/viewMode'
import UiContainer from 'elements/UiContainer'
import { FC, memo, useCallback, useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getOrgFromState } from 'util/org'
import { useFeatureFlag } from 'util/split'
import Button from '../../elements/button/Button'

type PageDashboardType = {
  org?: any
  updateCourseData: (value: any) => void
  updateShowLearn: (value: boolean) => void
  showLearn?: boolean
  courseData?: any
}

const useActionStyles = makeStyles(
  (theme) => ({
    wrapper: {
      // width: 350,
      position: 'absolute',
      bottom: 10,
      right: 16,
      gap: 10,
      display: 'flex',
      justifyContent: 'space-around',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row',
        right: 20,
        gap: 20,
      },
      [theme.breakpoints.down('xs')]: {},
    },
    icon: {
      maxWidth: 18,
      maxHeight: 18,
    },
    button: {
      fontSize: 14,
      height: 40,
      textTransform: 'none',
      fontWeight: 'normal',
      boxShadow: 'none',
      borderRadius: 4,
      minWidth: 84,
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
        '& .MuiButton-label .MuiSvgIcon-root': {
          paddingLeft: 6,
        },
      },
      '&.active': {
        background: '#737373',
        color: 'rgb(255,255,255)',
        '&:hover': {
          background: '#737373',
        },
      },
    },
  }),
  { name: 'ActionButtons' }
)

export const loadAcademyData = (
  countryIso2,
  courseData,
  setIsLearn,
  setIsLoading,
  updateCourseData,
  updateShowLearn
) => {
  const hasCoursesForCountry = (_courseData: any) =>
    _courseData.content.some((course) => course.countries.includes(countryIso2))

  //check out whether courseList exists,
  // if so, set learn shown and set loaded
  //if not, fetch course.json, then set the state of courseList, and update the redux store
  if (courseData && Object.entries(courseData).length > 0) {
    let showLearn = hasCoursesForCountry(courseData)
    if (setIsLearn) {
      setIsLearn(showLearn)
    }
    setIsLoading(false)
  } else {
    //fetch course.json by calling api,
    //check out whether org's country is same to course's country,
    //if so, set states of courseData, showLearn true, as well as state of isLoading to false
    fetch(`${window.API_ROOT}/api/feature_config/public/os_learn/`, {
      method: 'GET',
      headers: window.Utils.tokenAuthHeaders(),
      mode: 'cors',
      credentials: 'same-origin',
      cache: 'no-cache',
    })
      .then((response) => response.json())
      .then((data) => {
        let courseData = JSON.parse(data.config_data)
        let showLearn = hasCoursesForCountry(courseData)
        if (setIsLearn) {
          setIsLearn(showLearn)
        }
        setIsLoading(false)
        updateCourseData(courseData)
        updateShowLearn(showLearn)
      })
      .catch((e) => {
        console.log(e)
        setIsLoading(false)
      })
  }
  //looking at whether org changes, if so, re-useEffect to check whether org's country still equal to course's country
}

const Actions: FC<PageDashboardType> = memo(
  ({ org, updateCourseData, updateShowLearn, showLearn, courseData, ...props }) => {
    const classes = useActionStyles()
    const history = useHistory()
    const translate = useTranslate()
    const [isLearn, setIsLearn] = useState(showLearn ? showLearn : false)
    const [isLoading, setIsLoading] = useState(true)
    const homeDrawerState = useSelector(viewModeSelectors.homeDrawerState)
    const dispatch = useDispatch()
    //check whether there are any coures for the org's country
    const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('xs'))
    const uiKey = 'homepage'
    const uiActionsKey = uiKey + '.actions'
    const solkunderFeatureOn = useFeatureFlag('solkunder', 'on')

    useEffect(() => {
      loadAcademyData(org?.country?.iso2, courseData, setIsLearn, setIsLoading, updateCourseData, updateShowLearn)
    }, [org?.id])

    const handleClick = useCallback((tab: HomeDrawerState) => {
      dispatch(viewModeActions.setHomeDrawerState(tab))
    }, [])

    const handleAcademyClick = () => {
      logAmplitudeEvent('home_academy_button_clicked', {})
      history.push('/academy')
    }

    return (
      <>
        <UiContainer uiKey={uiActionsKey} className={classes.wrapper}>
          {isMobile ||
            (isLearn && (
              <Button
                uiKey={uiActionsKey + '.academy'}
                className={classes.button + (homeDrawerState === 'learn' ? ' active' : '')}
                onClick={handleAcademyClick}
                startIcon={<SchoolOutlined className={classes.icon} />}
                variant="contained"
              >
                <span>{translate('Academy')}</span>
              </Button>
            ))}
          <Button
            uiKey={uiActionsKey + '.recent'}
            className={classes.button + (homeDrawerState === 'recent' ? ' active' : '')}
            onClick={() => handleClick('recent')}
            startIcon={<HistoryOutlinedIcon className={classes.icon} />}
            variant="contained"
          >
            <span>{translate('Recent')}</span>
          </Button>
          <Button
            uiKey={uiActionsKey + '.activity'}
            className={classes.button + (homeDrawerState === 'activity' ? ' active' : '')}
            onClick={() => handleClick('activity')}
            startIcon={<PlaylistAddCheckOutlinedIcon className={classes.icon} />}
            variant="contained"
          >
            <span>{translate('Activity')}</span>
          </Button>
          <Button
            uiKey={uiActionsKey + '.overview'}
            className={classes.button + (homeDrawerState === 'overview' ? ' active' : '')}
            onClick={() => handleClick('overview')}
            startIcon={<TimelineRoundedIcon className={classes.icon} />}
            variant="contained"
          >
            <span data-testid="overview">{translate('Overview')}</span>
          </Button>
        </UiContainer>
        <UiContainer uiKey={uiKey + '.lite_msg'} className={classes.wrapper}>
          <p className="small">
            You are using <strong>OpenSolar Lite</strong>
          </p>
        </UiContainer>
      </>
    )
  }
)

const mapStateToProps = (state: any) => {
  return {
    org: getOrgFromState(state),
    showLearn: state.learning.showLearn,
    courseData: state.learning.courseData,
  }
}

const mapDispatchToProps = {
  updateShowLearn: updateShowLearn,
  updateCourseData: updateCourseData,
}

export default connect(mapStateToProps, mapDispatchToProps)(Actions)

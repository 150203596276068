import { TypographyProps } from '@material-ui/core/Typography'
import { authSelectors } from 'ducks/auth'
import SharedEntityIcon from 'elements/connectedOrgs/SharedEntityIcon'
import { FieldProps, InjectedFieldProps } from 'ra-ui-materialui/src/field/types'
import { FC, memo } from 'react'
import { TextField, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { urlToId } from 'util/misc'

const TextFieldWithSharedEntityIcon: FC<FieldProps & InjectedFieldProps & TypographyProps> = memo(
  ({ record, source, label, ...rest }) => {
    const org = useSelector(authSelectors.getCurrentOrg)
    const translate = useTranslate()

    const translatedLabel = typeof label === 'string' ? translate(label) : label

    return (
      <span>
        <TextField source={source} label={translatedLabel} record={record} {...rest} />
        <SharedEntityIcon
          owner_url={org?.url}
          shared_with={record?.share_with_orgs?.map((sharedWithUrl) => {
            return {
              org_id: urlToId(sharedWithUrl),
              is_shared: true,
            }
          })}
          shared_urls={record?.share_with_orgs}
        />
      </span>
    )
  }
)

export default TextFieldWithSharedEntityIcon

import type { SSOFlowConfigType } from '../sso/types'

// export const IRONRIDGE_AUTH_CONFIG: AuthConfigType = {
//   type: 'user-pass',
//   // name: 'ironridge',
//   displayName: 'IronRidge',
//   logoUrl: 'iron_ridge_logo.svg',
// }

let userPassRedirectUri: string
//TODO: re-use the logic in the SDK, once merged
switch (window.ENV) {
  case 'dev':
  case 'vega':
  case 'mars':
  case 'experiment':
  case 'dock':
  case 'irda':
    userPassRedirectUri = `https://${window.ENV}.sunsuite.com.au`
    break

  default:
    const subdomain = window.ENV === 'staging4' ? window.ENV : 'app'
    userPassRedirectUri = `https://${subdomain}.opensolar.com`
}

export const IRONRIDGE_SSO_CONFIG: SSOFlowConfigType = {
  id: 'ironridge-sso',
  type: 'sso',
  displayName: 'IronRidge',
  name: 'ironridge',
  partner_id: 'ironridge',
  brandingLogo: 'iron_ridge_logo.svg',
  logoUrl: 'iron_ridge_logo.svg',
  userPassRedirectUri,
}

import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { WsContainer, wsActions } from 'ducks/websocket'
import { EDIT_MSG_TYPE, ProjectEditStateMsg } from 'elements/websocket/SocketStatusIcon'
import { Button } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFeatureFlag } from 'util/split'

export default function SocketDialog() {
  const translate = useTranslate()
  const [msg, setMsg] = useState<string>('')
  const [showNotification, setShowNotification] = useState<boolean>(false)
  const [ignoreAll, setIgnoreAll] = useState<boolean>(false)
  const [otherFields, setOtherFields] = useState<string[]>([])
  const isSuperuser = useSelector(authSelectors.getIsSuperUser)
  const dispatch = useDispatch()

  // Keep a hash of values to filter out messages from other users which have already been shown
  const lastFields = useMemo<Record<number, string>>(() => ({}), [])

  const onMessage = useCallback(
    (data: ProjectEditStateMsg, cont: WsContainer<ProjectEditStateMsg>) => {
      // Only show SU info if self is SU
      if (cont.original_data.is_superuser && !isSuperuser) return

      if (data.type !== EDIT_MSG_TYPE || !data.fields?.length) return
      let fields = ''
      data.fields = data.fields.sort((f1, f2) => f1.localeCompare(f2))
      for (let i in data.fields) {
        let field = data.fields[i]
        if (field.includes('contacts_new.0.')) {
          field = field.replace('contacts_new.0.', 'resources.contacts.fields.')
        }
        if (fields.length) fields += ', '
        fields += translate(field)
        data.fields[i] = field
      }

      const userLastFields = lastFields[cont.user_id]
      lastFields[cont.user_id] = fields
      //TODO: could make this subset search smarter
      if (userLastFields && (userLastFields === fields || userLastFields.indexOf(fields) !== -1)) {
        // Either fields hasn't changed, or has only reduced, abort
        return
      }

      setOtherFields(data.fields)
      setMsg(
        translate(`Someone is making changes to this project on the following fields:`, {
          name: cont.user_name,
          fields,
        })
      )
      // if (ignoreAll) window.Designer.showNotification(msg + ' ' + fields)
      // else setShowNotification(true)
      if (!ignoreAll) setShowNotification(true)
    },
    [ignoreAll]
  )

  //TODO: prevent ws connect without used features
  const enableConcurrentEditingProtection = useFeatureFlag('concurrent_editing_protection', 'on')
  useEffect(() => {
    if (enableConcurrentEditingProtection) {
      dispatch(wsActions.addSubscriber<ProjectEditStateMsg>(onMessage))

      return () => {
        dispatch(wsActions.removeSubscriber<ProjectEditStateMsg>(onMessage))
      }
    }
  }, [enableConcurrentEditingProtection, onMessage])

  const handleClose = () => {
    setShowNotification(false)
  }

  const handleDismiss = () => {
    setIgnoreAll(true)
    setShowNotification(false)
  }

  return showNotification ? (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true}>
      <DialogTitle id="customized-dialog-title">{translate('Attention')}</DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          {msg}
          <ul>
            {otherFields.map((f) => (
              <li>{translate(f)}</li>
            ))}
          </ul>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleDismiss} color="default" variant="contained">
          {translate('Ignore all')}
        </Button>
        <Button autoFocus onClick={handleClose} color="primary" variant="contained">
          {translate('Dismiss')}
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    <></>
  )
}

import { captureException, captureMessage } from '@sentry/browser';
import { HIDE_NOTIFICATION, SHOW_NOTIFICATION, } from '../../actions/notificationActions';
import { UNDO } from '../../actions/undoActions';
var notificationsReducer = function (previousState, action) {
    var _a;
    if (previousState === void 0) { previousState = []; }
    switch (action.type) {
        case SHOW_NOTIFICATION:
            var type = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.type;
            // coincidentally, our types match sentry.io's levels
            if (action.payload.message && type !== 'info' && type !== 'success') {
                var payload = {
                    level: type,
                    tags: {
                        'from-notification': true,
                    },
                };
                if (action.payload.error)
                    captureException(action.payload.error, payload);
                else
                    captureMessage(action.payload.message, payload);
            }
            return previousState.concat(action.payload);
        case HIDE_NOTIFICATION:
        case UNDO:
            return previousState.slice(1);
        default:
            return previousState;
    }
};
export default notificationsReducer;
/**
 * Returns the first available notification to show
 * @param {Object} state - Redux state
 */
export var getNotification = function (state) { return state.admin.notifications[0]; };

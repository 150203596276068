import { Box } from 'opensolar-ui'
import React from 'react'
import { makeOpenSolarStyles } from '../../../themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: any) => ({
  expoTag: {
    x: '60',
    y: '20',
    dominantBaseline: 'middle',
    textAnchor: 'middle',
    fill: 'white',
    fontSize: '12'
  }
}))

const ExpoRibbonTag = ({ fillColor = '#2D2B32', text = '' }) => {
  const classes = useStyles()
  const padding = 2
  const width = 100 + padding * 2
  const height = 20 + padding * 2
  const tagAngleWidth = 4

  return (
    <Box>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d={`M${padding} ${padding}H${width - padding}L${width - padding - tagAngleWidth} ${height / 2}L${
            width - padding
          } ${height - padding}H${padding}V${padding}Z`}
          fill={fillColor}
        />
        <text x={`${width / 2 - tagAngleWidth / 2}`} y="50%" className={classes.expoTag}>
          {text}
        </text>
      </svg>
    </Box>
  )
}

export default ExpoRibbonTag
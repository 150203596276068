import type { ComponentTypesV2 } from 'types/selectComponent'
import { mapComponentTypesV2ToV1 } from 'util/misc'
import BasicFilter from '../basic/BasicFilter'
import type {
  ConfigurableFilterOptionsType,
  FilterComponentNodeV2,
  FilterGenericPropsType,
  FilterValuesType,
  OptionGenericType,
} from '../typeV2'
import { parseStringifiedListFilterValue } from '../utils/utils'

export const CURRENT_FILTER_KEY_V2 = 'current_type'
const defaultValue = ''

export const isCurrentTypeFilterAvailableV2 = (filterValues: FilterValuesType) => {
  const currentTypes = parseStringifiedListFilterValue<ComponentTypesV2>({
    filterValues,
    key: 'types',
  })
  return currentTypes.has('cable') || currentTypes.has('isolator')
}

const CURRENT_TYPE_OPTIONS: OptionGenericType<string>[] = [
  { id: 'default', title: 'All', value: defaultValue },
  { id: 'ac', title: 'AC', value: 'ac' },
  { id: 'dc', title: 'DC', value: 'dc' },
]

export const createCurrentTypeFilterNode = <T,>({
  label = 'Current Type',
  ...restConfigs
}: ConfigurableFilterOptionsType<string, T>): FilterComponentNodeV2 => {
  const FilterComponent = (filterProps: FilterGenericPropsType) => {
    const types = parseStringifiedListFilterValue<ComponentTypesV2>({
      filterValues: filterProps.allFilterValues,
      key: 'types',
    })

    if (mapComponentTypesV2ToV1(Array.from(types)[0]) !== 'other') {
      return null
    }

    const disabled = !isCurrentTypeFilterAvailableV2(filterProps.allFilterValues)

    return (
      <BasicFilter
        label={label}
        defaultSelectedOptionValue={defaultValue}
        options={CURRENT_TYPE_OPTIONS}
        filterKey={CURRENT_FILTER_KEY_V2}
        {...restConfigs}
        {...filterProps}
        disabled={disabled}
      />
    )
  }

  return {
    key: CURRENT_FILTER_KEY_V2,
    component: FilterComponent,
  }
}

export default createCurrentTypeFilterNode

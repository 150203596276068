import { Grid } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import { orgSelectors } from 'ducks/orgs'
import { getDiscountSavingPerItem } from 'pages/ordering/discountUtils'
import type { DiscountType } from 'pages/ordering/type'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { currencySymbolForCountry, formatCurrencyWithSymbol } from 'util/misc'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  price: ({ hasDiscount }: { hasDiscount: boolean }) => ({
    textDecoration: hasDiscount ? 'line-through' : 'unset',
  }),
  priceAfterDiscount: {
    fontSize: 16,
    color: theme.secondaryColor,
  },
}))

export const CostPerUnit = ({ pricePerUnit, discount }: { pricePerUnit?: number | 'TBC'; discount?: DiscountType }) => {
  if (pricePerUnit == null) {
    return null
  }
  const usingCustomPricing = pricePerUnit === 'TBC'
  const savingPerItem =
    !usingCustomPricing && discount != null ? getDiscountSavingPerItem({ discount, pricePerItem: pricePerUnit }) : 0
  const priceAfterDiscount = !usingCustomPricing && !!savingPerItem ? pricePerUnit - savingPerItem : undefined

  return <Cost price={pricePerUnit} priceAfterDiscount={priceAfterDiscount} />
}

export const TotalCost = ({
  beforeDiscount,
  afterDiscount,
}: {
  beforeDiscount?: number | 'TBC'
  afterDiscount?: number
}) => {
  if (beforeDiscount == null) {
    return null
  }
  return <Cost price={beforeDiscount} priceAfterDiscount={afterDiscount} />
}

const Cost = ({ price, priceAfterDiscount }: { price: number | 'TBC'; priceAfterDiscount?: number }) => {
  const usingCustomPricing = price === 'TBC'
  const hasDiscount = !usingCustomPricing && priceAfterDiscount != null && price > priceAfterDiscount
  const classes = useStyles({ hasDiscount: hasDiscount && !usingCustomPricing })
  const currencySymbol = useSelector((state: any) => {
    return currencySymbolForCountry(orgSelectors.getOrgIso2(state))
  })

  return (
    <Grid container spacing={1}>
      {usingCustomPricing ? (
        <Grid item xs={12} className={classes.price}>
          TBC
        </Grid>
      ) : (
        <>
          <Grid item xs={12} className={classes.price}>
            {`${formatCurrencyWithSymbol(price, currencySymbol)}`}
          </Grid>
          {hasDiscount && (
            <Grid item xs={12} className={classes.priceAfterDiscount}>
              {`${formatCurrencyWithSymbol(priceAfterDiscount, currencySymbol)}`}
            </Grid>
          )}
        </>
      )}
    </Grid>
  )
}

export default Cost

import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import React, { useEffect, useMemo } from 'react'
import { useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { MaterialUIWidthType } from 'types/misc'
import { updateProjectWorkflows } from 'util/misc'
import SharedWorkflows from './SharedWorkflows'
import ProjectWorkflow from './workflow/ProjectWorkflow'

const useStyles = makeStyles((theme) => ({
  content: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  disabled: {
    pointerEvents: 'none',
    cursor: 'not-allowed',
  },
  accordionDetails: { display: 'block' },
  iconTitleWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  headingTitle: {
    margin: '0 10px',
  },
  descriptionWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '16px',
  },
  descriptionWrapperMobile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginTop: '16px',
  },
  updateStageTextWrapper: {
    minWidth: '200px',
  },
  updateStageButtonWrapper: {
    marginLeft: '20px',
  },
  updateStageButtonWrapperMonile: {
    marginTop: '20px',
  },
}))

type PropTypes = {
  width: MaterialUIWidthType
}

const ProjectManagementAccordion: React.FunctionComponent<PropTypes> = (props) => {
  const form = useForm()
  const classes = useStyles()
  const translate = useTranslate()
  const formValues = useFormState().values
  const currOrgId = useSelector(authSelectors.getOrgId)
  const orgWorkflows = useSelector(orgSelectors.getWorkflows)

  const otherOrgsWorkflows = useMemo(() => {
    if (!!!formValues.shared_with?.length) return []
    const sharedWithIds = formValues.shared_with?.map((x) => x.org_id)
    if (currOrgId === formValues.org_id) {
      return formValues.workflows.filter((x) => x.org_id !== currOrgId && sharedWithIds.includes(x.org_id))
    } else {
      return formValues.workflows.filter((x) => x.org_id === formValues.org_id)
    }
  }, [formValues.shared_with, formValues.workflows])

  useEffect(() => {
    form.registerField('project_sold', () => {}, {})
    form.registerField('contract_date', () => {}, {})
    form.registerField('project_installed', () => {}, {})
    form.registerField('installation_date', () => {}, {})
  }, [])

  return (
    <Accordion key="management" expanded={true}>
      <AccordionSummary
        aria-controls="expansion-content-management"
        id="expansion-header-management"
        classes={{ content: classes.content }}
      >
        <div className={classes.iconTitleWrapper}>
          <AccountTreeOutlinedIcon />
          <span className={classes.headingTitle}>{translate('Project Management')}</span>
        </div>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.accordionDetails }}>
        <ProjectWorkflow
          selectedWorkflow={formValues.workflow}
          workflows={orgWorkflows}
          handleChange={(workflow) => {
            updateProjectWorkflows(formValues.workflow, workflow, formValues, form, 'workflow')
          }}
        />
        {otherOrgsWorkflows && !!otherOrgsWorkflows.length && <SharedWorkflows sharedWorkflows={otherOrgsWorkflows} />}
      </AccordionDetails>
    </Accordion>
  )
}

export default withWidth()(ProjectManagementAccordion)

import { createContext } from 'react'

/**
 * Allows for decoupled setting of the currency symbol across parts of the
 * react app hierarchy.
 *
 * To set the currency symbol for a region of the hierarchy, wrap the region in:
 * ```ts
 *  <CurrencySymbolContext.Provider value={currencySymbol}>
 *   <SomeComponent />
 *  </CurrencySymbolContext.Provider>
 * ```
 *
 * To use the currency symbol in a component, use the following:
 * ```ts
 * const currencySymbol = useContext(CurrencySymbolContext)
 * ```
 *
 */

export const CurrencySymbolContext = createContext<string>('$')

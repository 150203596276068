// @ts-nocheck
import { makeStyles } from '@material-ui/core'
import { Button } from 'opensolar-ui'
import React, { FC, memo } from 'react'
import { useReference, useTranslate } from 'react-admin'
import { useHistory } from 'react-router-dom'
import { PRIORITY_CHOICES, STAGE_CHOICES } from 'resources/projects/choices'
import { getDefaultFilters } from './getDefaultFilters'

const useStyles = makeStyles(() => ({
  root: {},
  label: {
    textTransform: 'none',
    fontSize: 12,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    maxWidth: 280,
    fontWeight: 300,
  },
}))

const getRedirect = (filterString: string) => {
  return 'projects?filter=' + encodeURIComponent(filterString) + '&order=DESC&page=1&perPage=20&sort=modified'
}

const LabelPartBoolean: FC<{ value?: any }> = memo(({ value }) => {
  return <span style={{ marginRight: 5 }}>{!!value ? 'True' : 'False'}</span>
})

const LabelPartText: FC<{ value?: any }> = memo(({ value }) => {
  return <span style={{ marginRight: 5 }}>{value}</span>
})

const LabelPartSelect: FC<{ value?: any; choices: any }> = memo(({ value, choices }) => {
  return <span style={{ marginRight: 5 }}>{choices[value]?.name}</span>
})

const LabelPartReference: FC<{ reference: string; optionValue: string; optionText: string; value?: any }> = memo(
  (props) => {
    const { reference, optionText, optionValue = 'id', value } = props
    const { referenceRecord } = useReference({
      [optionValue]: value,
      reference,
    })
    return <span style={{ marginRight: 5 }}>{referenceRecord ? referenceRecord[optionText] : '...'}</span>
  }
)

const SOURCE_AND_LABEL_PART: any = {
  q: <LabelPartText />,
  assigned_role_id: <LabelPartReference reference="roles" optionText="display" optionValue="id" />,
  is_unassigned: <LabelPartBoolean />,
  assigned_installer_role_id: <LabelPartReference reference="roles" optionText="display" optionValue="id" />,
  assigned_site_inspector_role_id: <LabelPartReference reference="roles" optionText="display" optionValue="id" />,
  assigned_designer_role_id: <LabelPartReference reference="roles" optionText="display" optionValue="id" />,
  assigned_salesperson_role_id: <LabelPartReference reference="roles" optionText="display" optionValue="id" />,
  tags: <LabelPartReference reference="tags" optionText="title" optionValue="id" />,
  locality: <LabelPartText />,
  zips: <LabelPartText />,
  states: <LabelPartText />,
  lead_source: <LabelPartText />,
  priority: <LabelPartSelect choices={PRIORITY_CHOICES} />,
  stage: <LabelPartSelect choices={STAGE_CHOICES} />,
  action_complete: <LabelPartReference reference="actions" optionText="title" optionValue="id" />,
  action_incomplete: <LabelPartReference reference="actions" optionText="title" optionValue="id" />,
  starred: <LabelPartBoolean />,
  systems__pricing_scheme: <LabelPartReference reference="pricing_schemes" optionText="title" optionValue="id" />,
  proposal_template: <LabelPartReference reference="proposal_templates" optionText="title" optionValue="id" />,
  has_systems: <LabelPartBoolean />,
  has_sold_system: <LabelPartBoolean />,
  has_system_without_pricing_scheme: <LabelPartBoolean />,
  has_installed_system: <LabelPartBoolean />,
  has_system_larger_than: <LabelPartText />,
  filter: <LabelPartBoolean />,
  sold_on_or_after: <LabelPartText />,
  sold_on_or_before: <LabelPartText />,
  created_date_gte: <LabelPartText />,
  created_date_lte: <LabelPartText />,
  modified_date_gte: <LabelPartText />,
  modified_date_lte: <LabelPartText />,
}

const Label: FC<{ filterString: string }> = ({ filterString }) => {
  const filter = JSON.parse(filterString)
  const translate = useTranslate()
  // Exclude orgId key because that is use to relate a filter to an Org, it is not part of the actual filterset
  return (
    <>
      {Object.keys(filter)
        .filter((key) => key !== 'orgId')
        .map((key) => {
          return SOURCE_AND_LABEL_PART[key] ? (
            <span key={key}>
              {translate(`resources.projects.fields.${key}`)}:{' '}
              {React.cloneElement(SOURCE_AND_LABEL_PART[key], { value: filter[key] })}
            </span>
          ) : (
            'Customised'
          )
        })}
    </>
  )
}

const FilterTag: FC<{ filterString: string }> = ({ filterString }) => {
  const defaultFilters = getDefaultFilters()
  const foundDefaultFilter = defaultFilters.find((f) => f.filter === filterString)
  const history = useHistory()
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <Button
      variant="outlined"
      classes={{ label: classes.label, root: classes.root }}
      onClick={() => history.push(getRedirect(filterString))}
    >
      <span>{foundDefaultFilter ? translate(foundDefaultFilter.label) : <Label filterString={filterString} />}</span>
    </Button>
  )
}

export default memo(FilterTag)

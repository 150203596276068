import React from 'react'
import { useTranslate } from 'react-admin'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Dialog, DialogContent } from '@material-ui/core'

type PropTypes = {
  type: 'prequal' | 'credit'
  visible: boolean
}

const SunlightApplicationLoading: React.FunctionComponent<PropTypes> = (props) => {
  const translate = useTranslate()
  const displayTextPartOne =
    props.type === 'credit'
      ? translate('Preparing your Sunlight Financial credit application.')
      : translate('Preparing your Sunlight Financial prequalification application.')
  return (
    <Dialog open={props.visible} style={{ textAlign: 'center' }}>
      <DialogContent style={{ padding: '10px 40px' }}>
        <img
          alt="Sunlight Financial"
          src={`${window.PUBLIC_URL}/images/logo-sunlight-financial.png`}
          style={{ maxHeight: 84, maxWidth: 220 }}
        />
        <div style={{ margin: '20px 0px' }}>
          <CircularProgress
            size={60}
            //@ts-ignore
            left={10}
            top={0}
            style={{
              display: 'inline-block',
              position: 'relative',
            }}
          />
        </div>
        <p>{displayTextPartOne}</p>
        <p>{translate('(Process may take up to 30 seconds. Thank you for your patience.)')}</p>
      </DialogContent>
    </Dialog>
  )
}

export default SunlightApplicationLoading

import type { IPublicClientApplication, SilentRequest } from '@azure/msal-browser'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import type { LegacyDataProvider } from 'react-admin'
import { setAuthRedirection, storeAuthRedirectionToLocalStorage } from 'redirections/authRedirectionStore'
import restClient from 'restClient'
import appStorage from 'storage/appStorage'
import MsalIdentityService from '../identity/MsalBasicService'
import { getLoginRequest } from './authConfig'

export type SegenConfigType = {
  tenant: string
  clientId: string
}

class SegenAuthenticationService extends MsalIdentityService {
  static _instance: SegenAuthenticationService | undefined
  protected segenConfig: SegenConfigType
  static unableAcquireAccessTokenSilently = false
  static priceSyncRequired = false

  constructor({ msalInstance, segenConfig }: { msalInstance: IPublicClientApplication; segenConfig: SegenConfigType }) {
    super({ msalInstance })

    this.segenConfig = segenConfig
  }

  private getCurrentAccount = () => {
    // NOTE: this is always using the first account since Segen is the only use case atm
    return this.getAllAccounts()[0]
  }

  private getAccessTokenRequest = (): SilentRequest => {
    const account = this.getCurrentAccount()
    return getLoginRequest(this.segenConfig.tenant, account)
  }

  static reconcileOrders = async (restClientInstance: LegacyDataProvider) => {
    await restClientInstance('CUSTOM_PATCH', 'custom', {
      url: 'orgs/' + appStorage.getOrgId() + '/_orders/reconcile/',
    }).catch((err: any) => {
      console.log('error', err)
    })
  }

  static recordSegenAuthenticated = async (restClientInstance: LegacyDataProvider) => {
    await restClientInstance('CUSTOM_PATCH', 'custom', {
      url: 'orgs/' + appStorage.getOrgId() + '/segen_authenticated/',
    }).catch((err: any) => {
      console.log('error', err)
    })
  }

  static onConnectSuccess = ({ method, source }: { method: 'redirect' | 'popup' | 'silent'; source: string }) => {
    logAmplitudeEvent('segen_account_connected', { method, source })
    SegenAuthenticationService.unableAcquireAccessTokenSilently = false
    if (method === 'redirect') {
      // Require price sync when user manually connect to Segen
      SegenAuthenticationService.priceSyncRequired = true
    }
    const restClientInstance = restClient(window.API_ROOT + '/api')
    Promise.all([
      SegenAuthenticationService.reconcileOrders(restClientInstance),
      SegenAuthenticationService.recordSegenAuthenticated(restClientInstance),
    ])
  }

  get cachedSegenAccessToken(): string | undefined {
    if (this.cachedAuthenticationResult == null || this.cachedAuthenticationResult.expiresOn == null) {
      return undefined
    }
    const now = new Date()
    if (this.cachedAuthenticationResult.expiresOn < now) {
      return this.cachedAuthenticationResult.accessToken
    }
    return undefined
  }

  isAuthenticated = async (): Promise<boolean> => {
    const accessToken = await this.maybeGetSegenAccessTokenSilently()
    return accessToken != null
  }

  maybeGetSegenAccessTokenSilently = async () => {
    const account = this.getCurrentAccount()

    if (account == null || SegenAuthenticationService.unableAcquireAccessTokenSilently === true) {
      // early break if no available login account
      return
    }
    const accessTokenRequest = this.getAccessTokenRequest()
    const accessToken = await this.getAccessTokenSilently({ accessTokenRequest })
    if (accessToken == null) {
      SegenAuthenticationService.unableAcquireAccessTokenSilently = true
    }
    return accessToken
  }

  connect = async ({
    enableRedirect,
    source,
    returnUrl = '/shop/after_connect/segen',
  }: {
    enableRedirect: boolean
    source: string
    returnUrl?: string
  }) => {
    const accessTokenRequest = this.getAccessTokenRequest()
    logAmplitudeEvent('connect_segen_attempted', { method: enableRedirect ? 'redirect' : 'popup', source })

    setAuthRedirection({ type: 'AUTH_SUCCESS_REDIRECT', redirect: returnUrl })
    storeAuthRedirectionToLocalStorage()

    const isSuccess = await this.connectAccount({ accessTokenRequest, enableRedirect })

    // It looks like this code never executes in the case that we have gone via redirect.
    if (isSuccess) {
      SegenAuthenticationService.onConnectSuccess({ method: enableRedirect ? 'redirect' : 'popup', source })
    }
  }

  disconnect = async () => {
    const account = this.getCurrentAccount()
    const isSuccess = await this.disconnectAccount({ account })
    return isSuccess
  }
}

export default SegenAuthenticationService

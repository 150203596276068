import { InputAdornment, InputProps } from '@material-ui/core'
import { Box, Typography, getTypographyStyles } from 'opensolar-ui'
import NumberInput from 'pages/inventory/components/NumberInput'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles(() => ({
  input: { ...getTypographyStyles({ colorHex: '#757575', textVariant: 'body1' }) },
}))

interface BaseInfoContainerProps {
  inputClasses?: InputProps['classes']
  label: string
  value: string | number
}

interface InfoContainerProps extends BaseInfoContainerProps {
  inputEndText?: never
  onBlur?: never
  onChange?: never
  onFocus?: never
  type?: 'text'
  value: string
}

export interface InputInfoContainerProps extends BaseInfoContainerProps {
  inputEndText?: string
  onBlur?: () => void
  onChange: (number) => void
  onFocus?: () => void
  type: 'input'
  value: number
}

export const InfoContainer: React.FC<InfoContainerProps | InputInfoContainerProps> = ({
  inputClasses,
  inputEndText,
  label,
  onBlur,
  onChange,
  onFocus,
  type = 'text',
  value,
}) => {
  const classes = useStyles()

  return (
    <Box alignItems="flex-start" display="flex" flexDirection="column" gridGap={8}>
      <Typography colorHex="#757575" textVariant="caption2">
        {label}
      </Typography>
      {type === 'text' && (
        <Typography colorHex="#757575" textVariant="body1">
          {value}
        </Typography>
      )}
      {type === 'input' && (
        <NumberInput
          className={classes.input}
          onBlur={onBlur}
          onFocus={onFocus}
          updateValue={(newValue) => {
            if (Number(newValue) >= 0) onChange!(newValue)
          }}
          value={value as number}
          {...(inputEndText && {
            InputProps: {
              endAdornment: <InputAdornment position="end">{inputEndText}</InputAdornment>,
            },
          })}
        />
      )}
    </Box>
  )
}

import DeleteOutlined from '@material-ui/icons/DeleteOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import DeleteButton from 'elements/proUXButtons/DeleteButton'
import TooltipWithDisabledButton from 'persistentContent/elements/TooltipWithDisabledButton'
import { useTranslate } from 'ra-core'
import { Confirm } from 'ra-ui-materialui'
import React, { useState } from 'react'
import { useForm } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import { ProjectType } from 'types/projects'
import { upgradeLiteToFullProject } from '../../util/upgradeLiteToFullProject'
import { useStyles } from './styles'

interface PropTypes {
  project: ProjectType
}

const ProjectActionsPanel: React.FC<PropTypes> = ({ project }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const history = useHistory()
  const form = useForm()
  const dirtyFields = form.mutators.getFormDirtyFields()
  const disableActions = dirtyFields?.length === 0
  const [confirmingUpgrade, setConfirmingUpgrade] = useState(false)

  const onDeleteSuccess = () => {
    logAmplitudeEvent('project_deleted', { project_id: project?.id })
    history.push('/projects')
  }

  const upgradeProject = () => {
    setConfirmingUpgrade(false)
    logAmplitudeEvent('project_lite_upgrade', { project_id: project?.id })
    upgradeLiteToFullProject(form)
  }

  return (
    <div className={classes.buttonCont}>
      <DeleteButton
        className={classes.button}
        variant="outlined"
        color="primary"
        label="delete project"
        confirmMode="dialog"
        resource="projects"
        record={project}
        startIcon={<DeleteOutlined />}
        confirmTitle={translate('Delete %{title}?', { title: project?.title || 'project' })}
        confirmBody={translate('Are you sure you want to delete this project?')}
        onSuccess={onDeleteSuccess}
        title={''}
        fullWidth={false}
      />

      <TooltipWithDisabledButton
        title={!disableActions ? translate('Please wait until the project is saved') : ''}
        label="Enhance Project in OpenSolar"
        clickFunc={() => setConfirmingUpgrade(true)}
        disabled={!disableActions}
        buttonStyle={classes.button}
      />

      <Confirm
        isOpen={confirmingUpgrade}
        title="Enhance Project in OpenSolar"
        content="This project was designed using OpenSolar Lite. By choosing to enhance your project using OpenSolar you will have access to the full range of design, sales, and management features. Note: An OpenSolar project cannot be converted back to an OpenSolar Lite project."
        confirmColor="warning"
        onConfirm={upgradeProject}
        onClose={() => setConfirmingUpgrade(false)}
      />
    </div>
  )
}

export default ProjectActionsPanel

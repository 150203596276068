import { CircularProgress, makeStyles } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { reloadEntireApp } from 'app/src/util/misc'
import { Button } from 'opensolar-ui'
import AuthFlowLogo from 'pages/auth/components/logos/AuthFlowLogo'
import React, { useState } from 'react'
import type { Identifier } from 'react-admin'
import { useNotify } from 'react-admin'
import { useHistory, useLocation } from 'react-router-dom'
import restClient from 'restClient'
import { doNotTranslate, parseQueryStringToDictionary } from 'util/misc'
import type { PartnerConfigType } from '../type'

const useStyles = makeStyles((theme: OpenSolarThemeType) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  message: {
    maxWidth: 250,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  responseMsg: {
    maxWidth: 250,
    textAlign: 'center',
  },
}))

interface PropTypes {
  selectedOrgId: Identifier
  onCancel: () => void
  partnerConfig: PartnerConfigType
}

// Map the query params to endpoint recognizable field
const mapParamKeyToRequestKey = (key: String): string | undefined => {
  if (key === 'company_uuid') {
    return 'partner_company_uuid'
  }
  return
}

const filterOrgLinkingParams = ({
  params,
  partnerConfig,
}: {
  params: { [key in string]: unknown }
  partnerConfig: PartnerConfigType
}): { [key in string]: unknown } => {
  const filteredParams = {}
  partnerConfig.orgLinkingParams?.forEach((key: string) => {
    const value = params[key]
    if (value === undefined) return
    const requiredKey = mapParamKeyToRequestKey(key)
    if (requiredKey === undefined) return
    filteredParams[requiredKey] = value
  })

  return filteredParams
}

const restClientInstance = restClient(window.API_ROOT + '/api')
const PartnerLinkConfirmation: React.FC<PropTypes> = ({ selectedOrgId, onCancel, partnerConfig }) => {
  const notify = useNotify()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const location = useLocation()

  const handleLink = () => {
    setIsLoading(true)
    const queryParams = parseQueryStringToDictionary(location.search)
    logAmplitudeEvent('partner_org_linking_interacted', {
      action: 'started',
      context: 'org_linking',
      source: 'org_linking_page',
      partner: partnerConfig.partner_id || 'unknown',
    })
    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${selectedOrgId}/link_with_external_partner/`,
      data: {
        partner_id: partnerConfig.partner_id,
        ...filterOrgLinkingParams({ params: queryParams, partnerConfig }),
      },
    })
      .then((response) => {
        setIsLoading(false)
        logAmplitudeEvent('partner_org_linking_interacted', {
          action: 'succeeded',
          context: 'org_linking',
          source: 'org_linking_page',
          partner: partnerConfig.partner_id || 'unknown',
        })
        notify(doNotTranslate(`Org successfully linked with ${partnerConfig.displayName}`), 'success')

        history.push('/')
        // In order to refresh auth
        reloadEntireApp()
      })
      .catch((err) => {
        setIsLoading(false)
        logAmplitudeEvent('partner_org_linking_interacted', {
          action: 'failed',
          context: 'org_linking',
          source: 'org_linking_page',
          partner: partnerConfig.partner_id || 'unknown',
        })
        notify(doNotTranslate(`Error linking org with ${partnerConfig.displayName}: ${err?.body?.message}`), 'error')
      })
  }

  return (
    <PartnerLinkConfirmationPure
      onCancel={onCancel}
      onLink={handleLink}
      loading={isLoading}
      logoUrl={window.PUBLIC_URL + '/images/' + partnerConfig.logoUrl}
      displayName={partnerConfig.displayName}
      customMessage={partnerConfig.orgLinkingMessage}
    />
  )
}

export const PartnerLinkConfirmationPure = ({
  onLink,
  onCancel,
  loading,
  logoUrl,
  displayName,
  customMessage,
}: {
  onLink(): void
  onCancel(): void
  loading: boolean
  logoUrl: string
  displayName: string
  customMessage?: string
}) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <AuthFlowLogo alt="branding logo" src={logoUrl} />
          <p className={classes.message}>
            {customMessage || doNotTranslate(`Would you like to proceed with linking your Org with ${displayName}?`)}
          </p>
          <div className={classes.row}>
            <Button variant="contained" color="default" onClick={onCancel}>
              {doNotTranslate('Cancel')}
            </Button>
            <Button variant="contained" color="primary" onClick={onLink}>
              {doNotTranslate('Confirm')}
            </Button>
          </div>
        </>
      )}
    </div>
  )
}
export default PartnerLinkConfirmation

import ProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/projectOrderPresenter'
import type { DistributorDataType } from 'pages/ordering/type'

export const filterOrderableDistributors = (distributors: DistributorDataType[]) => {
  return distributors?.filter((distributor: DistributorDataType) => {
    const status = ProjectOrderPresenter.getStockLevelStatusMain({
      quantity: 0,
      stockLevels: distributor.stock_levels,
      isAvailable: distributor.is_available,
    })
    return ProjectOrderPresenter.isStockLevelReadyToOrder(status)
  })
}

import { FormControlLabel, makeStyles } from '@material-ui/core'
import ContentSave from '@material-ui/icons/SaveOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { permissionsSelectors } from 'ducks/permissions'
import { setSelectTariffDialog as setSelectTariffDialogAction } from 'ducks/selectTariff'
import { studioSelectors } from 'ducks/studioMode'
import Button from 'elements/button/Button'
import { Checkbox } from 'opensolar-ui'
import React, { useCallback, useContext } from 'react'
import { FormContext, Toolbar, useNotify, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useFormatTariffValues } from '../hooks'
import { UtilityTariffDialogContext } from '../index'

const useStyles = makeStyles(
  (theme: any) => {
    return {
      button: {
        position: 'relative',
        padding: '4px 10px',
        margin: 10,
        width: 100,
      },
      toolbar: {
        background: 'rgba(224, 224, 224, 1)',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
      },
    }
  },
  { name: 'create-utility-tariff-dialog-toolbar' }
)

const CreateTariffToolbar = (props: any) => {
  const { saving, setSaveToBD, saveToDB } = props
  const translate = useTranslate()
  const location = useLocation()
  const { setOnSave } = useContext(FormContext)
  const isStudioMode = useSelector(studioSelectors.isInStudio)
  const history = useHistory()
  const classes = useStyles()
  const tariffForm = useForm()
  const formState = useFormState()
  const projectForm = window.projectForm
  const notify = useNotify()
  const { state, dispatch, handleSelect } = useContext(UtilityTariffDialogContext)
  const { onSuccess, onFailure, variant } = state
  const dispatchAction = useDispatch()
  const saveTariffToDataBase = useCallback(
    (values: object, redirect: string, form: any, defaultSaveFunc: Function) => {
      const onSuccessOverride = (response: any) => {
        handleSelect(response.data, variant)
        //clear search query
        history.push(location.pathname)
        //show notification
        notify('Tariff created', 'info')
        //close dialog
        dispatch({ type: 'clear' })
        //close select tariff dialog
        dispatchAction(setSelectTariffDialogAction(false, null, null, null, isStudioMode))
      }
      const onFailureOverride = (e: any) => {
        notify(e.message, 'warning')
        if (onFailure) {
          onFailure()
        }
      }
      defaultSaveFunc(values, redirect, { onSuccess: onSuccessOverride, onFailure: onFailureOverride })
    },
    [onSuccess, onFailure, location.pathname]
  )
  const submitValue = useFormatTariffValues(formState?.values)

  const { allowCreate: allowCreateTariff } = useSelector(
    permissionsSelectors.getProjectPermissionByKey('utility_tariffs')
  )

  const createOnceOffTariff = useCallback(() => {
    projectForm.batch(() => {
      //@ts-ignore
      projectForm.registerField(`utility_tariff_${variant}`, () => {})
      projectForm.change(`utility_tariff_${variant}`, null)

      //@ts-ignore
      projectForm.registerField(`utility_tariff_${variant}_data`, () => {})
      projectForm.change(`utility_tariff_${variant}_data`, null)

      projectForm.registerField(`utility_tariff_${variant}_custom`, () => {}, {})
      projectForm.change(`utility_tariff_${variant}_custom`, submitValue.data)
    })
    dispatch({ type: 'clear' })
    dispatchAction(setSelectTariffDialogAction(false, null, null, null, isStudioMode))
    history.push(location.pathname)
  }, [location.pathname, submitValue])

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (saving) {
        event.preventDefault()
        return
      }
      if (saveToDB) {
        if (!setOnSave) throw new Error('Something went wrong, unable to find setOnSave function')
        setOnSave(saveTariffToDataBase)
        tariffForm.submit()
      } else {
        createOnceOffTariff()
      }
    },
    [saveToDB, location.pathname, saving, submitValue]
  )

  return (
    <Toolbar className={classes.toolbar} {...props}>
      <FormControlLabel
        control={
          <Checkbox
            checked={saveToDB}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const value = event.target.checked
              setSaveToBD(value)
              logAmplitudeEvent('save_new_tariff_toggle', {
                toggled: value,
              })
            }}
            color="primary"
            disabled={!allowCreateTariff}
          />
        }
        label={translate('Save to My Utility Tariffs')}
      />
      <Button
        classes={{ root: classes.button }}
        disabled={saving}
        loading={saving}
        startIcon={<ContentSave />}
        variant="contained"
        color="primary"
        type="button"
        onClick={handleClick}
      >
        <span>{translate('Create')}</span>
      </Button>
    </Toolbar>
  )
}

export default CreateTariffToolbar

import { List } from 'elements/react-admin/List'
import React from 'react'
import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from 'react-admin'

const RoofTypeTitle = ({ record, translate }) => {
  return (
    <span>
      {translate('Roof Type')} {record ? `"${record.name}"` : ''}
    </span>
  )
}

export const RoofTypeList = (props) => (
  <List title="Roof Types" {...props}>
    <Datagrid>
      <TextField source="name" />
      <EditButton />
    </Datagrid>
  </List>
)

export const RoofTypeShow = (props) => (
  <Show title={<RoofTypeTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
    </SimpleShowLayout>
  </Show>
)

export const RoofTypeEdit = (props) => (
  <Edit title={<RoofTypeTitle />} {...props}>
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
)

export const RoofTypeCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect={'list'}>
      <TextInput source="name" />
    </SimpleForm>
  </Create>
)

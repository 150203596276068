import { ComponentTypesV2 } from 'types/selectComponent'
import { HardwareComponentDataType, HardwareComponentType } from '../types'

const kwToWatts = (kw: number): number => {
  try {
    return Math.round(1000 * kw)
  } catch (e) {
    return 0
  }
}

export const getComponentData = (component: HardwareComponentType): HardwareComponentDataType | undefined => {
  if (!component.data || component.data === 'null') return undefined

  try {
    return JSON.parse(component.data)
  } catch {
    return undefined
  }
}

export const getComponentRating = (
  componentType: ComponentTypesV2,
  componentData?: HardwareComponentDataType
):
  | {
      suffix: string
      value: string | number
    }
  | '-' => {
  if (!componentData) return '-'

  let suffix = ''
  let value: string | number = ''

  if (componentType === 'module' && componentData.kw_stc) {
    suffix = 'W'
    value = kwToWatts(componentData.kw_stc)
  } else if (componentType === 'inverter' && componentData.max_power_rating) {
    suffix = 'kW'
    value = componentData.max_power_rating
  } else if (componentType === 'battery' && componentData.kwh_optimal) {
    suffix = 'kWh'
    value = componentData.kwh_optimal
  } else {
    value = '-'
  }

  return { suffix, value }
}

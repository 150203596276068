var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Dialog as Mui4Dialog } from '@material-ui/core';
import { default as Mui5Dialog } from '@mui/material/Dialog';
import { forwardRef } from 'react';
import { Inspector } from '../debug/Inspector';
import { useComponentVersion } from '../hooks';
export var Dialog = function (props) {
    return _jsx(Inspector, { name: "Dialog", propDefs: DialogProps, showExample: true, component: OsDialog, props: props });
};
export var OsDialogVersion;
(function (OsDialogVersion) {
    OsDialogVersion[OsDialogVersion["V1"] = 1] = "V1";
    OsDialogVersion[OsDialogVersion["V2"] = 2] = "V2";
})(OsDialogVersion || (OsDialogVersion = {}));
var OsDialog = forwardRef(function (props, ref) {
    if (ref === void 0) { ref = null; }
    var version = useComponentVersion('dialog');
    switch (version) {
        case OsDialogVersion.V2:
            //@ts-ignore TODO: fix this ref typing issue
            return _jsx(Mui5Dialog, __assign({ ref: ref }, props));
        default:
            return _jsx(Mui4Dialog, __assign({ ref: ref }, props));
    }
});
var DialogProps = {
    open: { hidden: true },
};

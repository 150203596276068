import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined'
import { Button } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import DefaultTooltip from '../fuji/DefaultToolTip'
import { ToolTipPropsType, TourConfigType } from '../types'

const Tooltip = (props: ToolTipPropsType) => {
  const { handleNext, handlePrev, handleCancel } = props
  const translate = useTranslate()
  const next = () => {
    if (handleNext) handleNext()
  }
  return (
    <DefaultTooltip
      title={translate('Configure Proposal Options')}
      // onClose={handleCancel}
      icon={LayersOutlinedIcon}
      content={
        <div>
          <p>
            {translate(
              'From the Payment tab, you have the option to configure how the proposal will appear to your customer.'
            )}
          </p>
          <p>
            {translate(
              'When your project has multiple system designs you can reorder systems by selecting the up and down arrow on the far right next to the system name. Unchecking the checkbox will remove the system from the proposal view.'
            )}
          </p>
        </div>
      }
      actions={
        <>
          <Button onClick={next}>
            <span>{translate('Next')}</span>
          </Button>
        </>
      }
      arrowPlacement={'left'}
    />
  )
}

const SystemConfigurationStep: TourConfigType = {
  spotlights: [{ targetId: 'system_checkbox_0', disableBackgroundScroll: false, padding: 5 }],
  tooltips: [{ targetId: 'system_checkbox_0', placement: 'right', component: Tooltip }],
}

export default SystemConfigurationStep

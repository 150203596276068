import { Card, makeStyles } from '@material-ui/core'
import SchoolSelectedIcon from '@material-ui/icons/SchoolRounded'
import { Button } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import { useHistory } from 'react-router-dom'
import { Theme } from 'types/themes'

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: '20px 15px',
    margin: 5,
    textAlign: 'center',
    // width: '45%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    Width: 400,
    left: 0,
    top: 0,
    background: '#F8F8F8',
    border: theme.border.default,
    boxSizing: 'border-box',
    borderRadius: 3,
    order: 0,
    flexGrow: 1,
    flex: '1 1 auto',
  },

  buttonsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    margin: '0 auto',
    flex: 'none',
    order: 0,
    alignSelf: 'stretch',
  },
}))

const DashboardLearn = () => {
  const classes = useStyles()
  const history = useHistory()
  const translate = useTranslate()
  return (
    <>
      <Card id="supportCard" variant="outlined" classes={{ root: classes.card }}>
        <h1 style={{ marginTop: 0 }}>{translate('Academy')}</h1>
        <p>
          {translate('Stay qualified and certified with courses delivered by experts online and onsite. Enrol now.')}
        </p>
        <div className={classes.buttonsWrapper}>
          <Button
            style={{ margin: '0 auto' }}
            variant="outlined"
            startIcon={<SchoolSelectedIcon />}
            onClick={() => (history as any).push('/academy')}
          >
            <span>{translate('Access Academy')}</span>
          </Button>
        </div>
      </Card>
    </>
  )
}

export default DashboardLearn

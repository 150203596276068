import { Grid } from '@material-ui/core'
import { CurveConfig } from 'ducks/consumptionCurves'
import DependentInput from 'elements/input/DependentInput'
import { CurvesDataForProject, useGetCountryCurvesData } from 'hooks/useConsumptionCurves'
import _ from 'lodash'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { SelectInput, TextInput, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { EMPTY24 } from 'util/time'
import { DailyConsumptionGraph } from './DailyConsumptionGraph'
import { UsageDataType } from './IntervalDataFields'
import { TabSelect } from './TabSelect'

const useStyles = makeOpenSolarStyles((theme) => ({
  curversContainer: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.grey[50],
    borderRadius: 8,
  },
  input: {
    width: '100%',
    '& #curve_weekday, #curve_weekend, #scale_weekend': {
      backgroundColor: 'white',
    },
  },
  graphWrapper: {
    marginTop: theme.spacing(2),
  },
}))

type HourlyCurveSettingsProps = {
  onFormChange: () => void
  projectCountry: string
  loading: boolean
  usageData: UsageDataType
  isResidential: boolean
}

type ViewMode = 'curve_weekday' | 'curve_weekend'
interface GraphContextProps {
  viewMode: ViewMode
  selectView: (e: any, v: ViewMode) => void
}

const GraphContext = createContext<GraphContextProps | null>(null)

const HourlyCurveGraph: React.FC<HourlyCurveSettingsProps> = (props) => {
  const classes = useStyles()
  const { viewMode, selectView } = useContext(GraphContext) as GraphContextProps
  const curve = props.usageData.normalized?.curves[viewMode]
  const curveValues = useMemo(() => curve || EMPTY24, [curve])

  const tabs = [
    {
      label: 'Weekday',
      value: 'curve_weekday',
    },
    {
      label: 'Weekend',
      value: 'curve_weekend',
    },
  ]

  return (
    <>
      <TabSelect onChange={selectView} tabs={tabs} tabSelected={viewMode} />

      <div className={classes.graphWrapper}>
        <DailyConsumptionGraph consumption={curveValues} loading={props.loading} />
      </div>
    </>
  )
}

const HourlyCurveTextInputs: React.FC<HourlyCurveSettingsProps & CurvesDataForProject> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const { selectView } = useContext(GraphContext) as GraphContextProps

  const handleOnChange = _.debounce(() => {
    props.onFormChange()
  }, 2000)

  return (
    <>
      <DependentInput dependsOn="usage_data_source" resolve={(value: any) => value && value !== 'Default'}>
        <Grid item xs={12}>
          <SelectInput
            onChange={() => {
              props.onFormChange()
              selectView(null, 'curve_weekday')
            }}
            options={{ InputLabelProps: { shrink: true } }}
            source="curve_weekday"
            resource={'projects'}
            name="curve_weekday"
            variant={'outlined'}
            disabled={props.loading}
            choices={props.countryCurves.map((curve: CurveConfig) => {
              return {
                id: curve.title,
                name: curve.title,
              }
            })}
            fullWidth
            className={classes.input}
          />
        </Grid>
      </DependentInput>
      <DependentInput dependsOn="usage_data_source" resolve={(value: any) => value && value !== 'Default'}>
        <Grid item xs={12}>
          <SelectInput
            fullWidth
            onChange={() => {
              props.onFormChange()
              selectView(null, 'curve_weekend')
            }}
            style={{ width: '100%' }}
            options={{ InputLabelProps: { shrink: true } }}
            source="curve_weekend"
            resource={'projects'}
            name="curve_weekend"
            variant={'outlined'}
            disabled={props.loading}
            choices={props.countryCurves.map((curve: CurveConfig) => {
              return {
                id: curve.title,
                name: curve.title,
              }
            })}
            className={classes.input}
          />
        </Grid>
      </DependentInput>
      <DependentInput dependsOn="usage_data_source" resolve={(value: any) => value && value !== 'Default'}>
        <Grid item xs={12}>
          <TextInput
            fullWidth
            onChange={handleOnChange}
            resource={'projects'}
            name="scale_weekend"
            source="scale_weekend"
            disabled={props.loading}
            helperText={translate('(e.g. 1.1 = Weekends 10% higher than weekdays)')}
            variant={'outlined'}
            options={{ InputLabelProps: { shrink: true } }}
            className={classes.input}
            type="number"
          />
        </Grid>
      </DependentInput>
    </>
  )
}

export const HourlyCurveSettings: React.FC<HourlyCurveSettingsProps> = (props) => {
  const classes = useStyles()
  const form = useForm()
  const [viewMode, setViewMode] = useState<'curve_weekday' | 'curve_weekend'>('curve_weekday')
  const selectView = useCallback((e, v) => setViewMode(v), [])

  const viewContext: GraphContextProps = useMemo<GraphContextProps>(() => ({ viewMode, selectView }), [
    viewMode,
    selectView,
  ])

  const curvesData = useGetCountryCurvesData(props.projectCountry, props.isResidential)

  const curveFields = [
    { field: 'curve_weekend', defaultVal: curvesData.defaultCurve.title },
    { field: 'curve_weekday', defaultVal: curvesData.defaultCurve.title },
    { field: 'scale_weekend', defaultVal: 1 },
  ]

  useEffect(() => {
    let isMounted = true
    curveFields.forEach((f) => {
      if (!form.getFieldState(f.field)?.value) {
        form.registerField(f.field, () => {}, {})
        form.mutators.updateField(f.field, f.defaultVal)
      }
    })
    props.onFormChange()
    return () => {
      isMounted = false
    }
  }, [])

  return (
    <GraphContext.Provider value={viewContext}>
      <Grid item container spacing={3} className={classes.curversContainer}>
        <Grid item xs={12} sm={6}>
          <HourlyCurveTextInputs {...props} {...curvesData} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <HourlyCurveGraph {...props} />
        </Grid>
      </Grid>
    </GraphContext.Provider>
  )
}

import { SPLIT_NAMES } from 'ducks/split'
import React, { useState } from 'react'
import { useFeatureFlag } from 'util/split'
import RateChangeManagementDialog from './RateChangeManagementDialog'

type PropTypes = {
  service: {
    name: string
    title: string
    logo: string
  }
}

const RateChangeLink: React.FC<PropTypes> = (props) => {
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const isEnabled = useFeatureFlag(`${props.service.name}_rate_change` as SPLIT_NAMES, 'on')
  const onClick = () => {
    setShowDialog(true)
  }

  if (!isEnabled) return null
  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <a onClick={onClick} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
        Manage {props.service.title} Rate Changes
      </a>
      {showDialog && (
        <RateChangeManagementDialog
          isOpen={true}
          onClose={() => setShowDialog(false)}
          lenderTitle={props.service.title}
          lenderName={props.service.name}
          logoURL={props.service.logo}
        />
      )}
    </div>
  )
}
export default RateChangeLink

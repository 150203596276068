import { OrderingOrderList } from './OrderingOrders'

export default {
  list: OrderingOrderList,
  options: {
    list: {
      title: 'Orders List',
      breadCrumb: 'Orders List',
    },
  },
}

import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined'
import { Button } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import DefaultTooltip from '../fuji/DefaultToolTip'
import { ToolTipPropsType, TourConfigType } from '../types'

const Tooltip = (props: ToolTipPropsType) => {
  const { handleNext, handlePrev, handleCancel } = props
  const translate = useTranslate()

  const next = () => {
    if (handleNext) handleNext()
  }
  return (
    <DefaultTooltip
      title={translate('Configure Proposal Payment Options')}
      // onClose={handleCancel}
      icon={LayersOutlinedIcon}
      content={
        <div>
          <p>
            {translate(
              'From the Payment tab, you have the option to configure how the proposal will appear to your customer.'
            )}
          </p>
          <p>
            {translate(
              'Reorder payment options by dragging and dropping the cards using the dots on the top-left. You can remove a payment option by clicking the trash icon.'
            )}
          </p>
        </div>
      }
      actions={
        <>
          <Button
            onClick={() => handlePrev && handlePrev()}
            onTouchStart={(e) => {
              e.stopPropagation()
              if (handlePrev) {
                handlePrev()
              }
            }}
          >
            <span>{translate('Back')}</span>
          </Button>
          {window.location.href?.includes('?tour=fuji') ? (
            <Button onClick={next}>
              <span>{translate('Next')}</span>
            </Button>
          ) : (
            <Button variant="contained" color="primary" onClick={() => handleCancel && handleCancel()}>
              <span>{translate('Finish Tour')}</span>
            </Button>
          )}
        </>
      }
      arrowPlacement={'left'}
    />
  )
}

const PaymentOptionConfiguration: TourConfigType = {
  spotlights: [{ targetId: 'pmt-option-card-wrapper-0', disableBackgroundScroll: false, padding: 5 }],
  tooltips: [{ targetId: 'pmt-option-card-wrapper-0', placement: 'right', component: Tooltip }],
}

export default PaymentOptionConfiguration

import { authSelectors } from 'ducks/auth'
import { ControlBreadcrumbLink, ListBreadCrumbs } from 'elements/BreadCrumbs'
import Empty from 'elements/Empty'
import EditOrImportButton from 'elements/button/EditOrImportButton'
import { List } from 'elements/react-admin/List'
import ListActions from 'elements/react-admin/ListActions'
import { ListProps } from 'ra-core/src/controller/useListController'
import React from 'react'
import { Datagrid, NotFound, TextField, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { ConnectedOrgsBreadcumbs } from 'resources/connectedOrgs/breadcrumbs'
import { CUSTOM_ROLE_TYPES, USER_ROLE_TYPE } from 'types/customRole'

export interface CustomRoleListProps {
  basePath: string
  id: string
  roleType: CUSTOM_ROLE_TYPES
}

export const CustomRoleList: React.FC<CustomRoleListProps & ListProps> = ({ roleType = USER_ROLE_TYPE, ...props }) => {
  const isAdmin = useSelector(authSelectors.getIsAdmin)
  const translate = useTranslate()
  // const role = useSelector(authSelectors.getCurrentRole)
  if (!isAdmin) {
    return <NotFound />
  }
  const breadcrumbs =
    roleType === USER_ROLE_TYPE
      ? [ControlBreadcrumbLink, { title: 'Custom Roles' }]
      : [...ConnectedOrgsBreadcumbs, { title: 'Sharing Permissions' }]
  return (
    <List
      {...props}
      breadCrumbs={<ListBreadCrumbs links={breadcrumbs} />}
      filter={{ role_type: roleType }}
      perPage={20}
      actions={<ListActions hasArchived={false} createButtonLabel={'Create Permission Set'} />}
      hasSearch={true}
      hasCreate={isAdmin}
      empty={<Empty resourceName={translate('teams roles')} />}
    >
      <Datagrid>
        <TextField source="title" label="Title" />
        {/* <TextField source="description" /> */}
        {/* <FunctionField
            source="role_type"
            label="Type"
            render={(record: CustomRoleType) => ROLE_TYPE_CHOICES.find((x) => x.id === record.role_type)?.name}
          /> */}

        <EditOrImportButton
          sortable={false}
          source="actions"
          label="Actions"
          resource="component_other_activations"
          allowDuplicate={false}
          allowEdit={isAdmin}
          allowDelete={isAdmin}
        />
      </Datagrid>
    </List>
  )
}

import { permissionsSelectors } from 'ducks/permissions'

import { DeleteOutlineIcon, DownloadIcon, EditOutlineIcon, EyeIcon } from 'opensolar-ui'
import DeleteButton from 'projectSections/sections/manage3/common/DeleteButton'
import { useTranslate } from 'ra-core'
import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { PrivateFileType } from 'types/privateFile'
import { FileDialogType, FilesContext, FilesContextProps } from '../FilesContextWrapper'
import { StyledMenu, StyledMenuBtnItem, StyledMenuItem } from '../styles/styles'
import { downloadFile } from '../utils'

interface CrudFileMenuProps {
  anchorEl: HTMLElement | null
  setAnchorEl: any
  record: PrivateFileType
}

export const CrudFileMenu: React.FC<CrudFileMenuProps> = ({ anchorEl, setAnchorEl, record, ...props }) => {
  const translate = useTranslate()

  const { setDialogSelected, dialogSelected, setRecordSelected, onSuccess } = useContext(
    FilesContext
  ) as FilesContextProps

  const { allowDelete, allowEdit } = useSelector(permissionsSelectors.getPermissionByKey('info_documents'))

  useEffect(() => {
    if (dialogSelected) setAnchorEl(null)
  }, [dialogSelected, setAnchorEl])

  const handleClose = () => setAnchorEl(null)

  const handleMenuItemClick = (action: FileDialogType) => {
    setRecordSelected(record)
    setDialogSelected(action)
  }

  return (
    <StyledMenu key={record.id} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
      <StyledMenuItem onClick={() => handleMenuItemClick('edit_file')}>
        <EditOutlineIcon variant={2} /> {translate('Edit')}
      </StyledMenuItem>
      <StyledMenuItem onClick={() => handleMenuItemClick('preview_file')}>
        <EyeIcon /> {translate('Preview')}
      </StyledMenuItem>
      <StyledMenuItem
        onClick={() => {
          downloadFile(record)
          setAnchorEl(null)
        }}
      >
        <DownloadIcon /> {translate('Download')}
      </StyledMenuItem>
      <StyledMenuBtnItem>
        <DeleteButton
          btnLabel={'Delete'}
          title={'file'}
          record={record}
          resource={'private_files'}
          {...props}
          onSuccess={() => {
            onSuccess()
            setAnchorEl(null)
          }}
          confirmMode="dialog"
          isDisabled={!allowDelete}
          btnProps={{
            startIcon: <DeleteOutlineIcon size={23} />,
            variant: 'text',
            color: 'error',
          }}
        />
      </StyledMenuBtnItem>
    </StyledMenu>
  )
}

import { orgSelectors } from 'ducks/orgs'
import { Button } from 'opensolar-ui'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import useSolarOutletService from 'services/outlet/useSolarOutletService'
import useSegenService from 'services/segen/useSegenServiceV2'

const SyncSegenProductsButton = memo(() => {
  const segenService = useSegenService()

  return (
    <Button
      style={{
        marginTop: 10,
        maxWidth: 230,
        height: 'fit-content',
      }}
      variant="contained"
      size="small"
      onClick={() => {
        segenService.syncProducts()
      }}
    >
      Sync Segen Products
    </Button>
  )
})

const SyncOutletProductsButton = memo(() => {
  const solarOutletService = useSolarOutletService()

  return (
    <Button
      style={{
        marginTop: 10,
        maxWidth: 230,
        height: 'fit-content',
      }}
      variant="contained"
      size="small"
      onClick={() => {
        solarOutletService?.syncProduct()
      }}
    >
      Sync Outlet Products
    </Button>
  )
})

const ProductsSyncButton = () => {
  //TODO: precedence
  const supplier = useSelector(orgSelectors.getEnabledHardwareSuppliers)
  if (supplier.includes(HardwareSupplierEnum.Segen)) {
    return <SyncSegenProductsButton />
  }

  if (supplier.includes(HardwareSupplierEnum.SolarOutlet)) {
    return <SyncOutletProductsButton />
  }
  //TODO: CP
  return null
}

export default ProductsSyncButton

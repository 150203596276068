import { logAmplitudeEvent } from 'amplitude/amplitude'
import GenericButton from 'elements/button/GenericButton'
import LoadingDots from 'layout/widgets/LoadingDots'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { showTransactionForm as showTransactionFormAction, transactionSelectors } from '../../ducks/transaction'
import { DialogHelper } from '../../util/misc'

const mapStateToProps = (state) => ({})

export const TransactionActionButton = (props) => {
  return (
    <div>
      <GenericButton
        wrapperStyle={{ display: 'block' }}
        style={{
          ...props.buttonStyle,
          fontSize: 16,
          height: 'unset',
          minHeight: 40,
          alignItems: 'center',
          display: 'flex',
          overflow: 'auto',
          padding: 5,
          wordWrap: 'break-word',
          width: 'calc(100% - 2px)',
          letterSpacing: 'normal',
        }}
        labelWrapperStyle={{ padding: 0, width: '100%', textTransform: 'none', whiteSpace: 'normal' }}
        label={
          props.isLoading && props.actionData ? (
            <LoadingDots color="#fff" />
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {props.sequenceNumber && (
                <div
                  style={{
                    width: 44,
                    height: 0,
                    minWidth: 44,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRight: props.buttonStyle.border,
                  }}
                >
                  {props.sequenceNumber}
                </div>
              )}
              <div style={{ wordBreak: 'break-word', flexGrow: 1, ...props.labelStyle }}>{props.label}</div>
            </div>
          )
        }
        onClick={(e) => {
          e.preventDefault()
          if (props.action) {
            props.action()
          }
        }}
      />
      {props.status_title && <p>{props.status_title}</p>}
      {props.status_description && <p className="small">{props.status_description}</p>}
      {props.extra_actions && props.extra_actions}
    </div>
  )
}

export const startTransaction = (dispatch, transactionRequestData) => {
  DialogHelper.afterOpen()

  dispatch(
    showTransactionFormAction({
      transactionRequestData: transactionRequestData,
    })
  )

  const payload = {
    payment_method: transactionRequestData?.payment_method,
    collect_signature: transactionRequestData?.collect_signature,
    payment_option_type: transactionRequestData?.payment_option_type,
  }
  logAmplitudeEvent('transaction_started', payload)
}

export const _TransactionStartButton = (props) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(transactionSelectors.getIsLoading)

  //this isn't reall where this logic should live, but we need to have transactionRequestData in scope to open the dialog
  //so this is in charge of re-opening the transaction dialog after the first KBA failure to let the customer know they have another shot
  useEffect(() => {
    if (props.docusignKBAFailures < 1 && window.location.href.includes('event=id_check_failed')) {
      props.showTransactionForm({
        transactionRequestData: props.transactionRequestData,
      })
    }
  }, [])

  var action = (event) => {
    if (event) {
      event.preventDefault()
    }

    if (props.disabled) {
      // do nothing
    } else {
      startTransaction(dispatch, props.transactionRequestData)
    }
  }

  if (props.elementType === 'link') {
    return (
      <a
        href="#"
        style={{ color: props.myeStyles.highlightColor, align: 'center', margin: 15 }}
        key={props.key}
        onClick={action}
      >
        {isLoading ? <LoadingDots color="#a2a2a2" /> : props.translate(props.label)}
      </a>
    )
  } else {
    return <TransactionActionButton {...props} action={action} isLoading={isLoading} />
  }
}
_TransactionStartButton.propTypes = {
  label: PropTypes.string,
  transactionRequestData: PropTypes.object,
}

_TransactionStartButton.defaultProps = {
  transactionRequestData: {},
}

export default connect(mapStateToProps, { showTransactionForm: showTransactionFormAction })(_TransactionStartButton)

import { push } from 'connected-react-router'
import { orgsActions } from 'ducks/orgs'
import {
  CRUD_CREATE_SUCCESS,
  CRUD_DELETE_SUCCESS,
  CRUD_UPDATE_SUCCESS,
  REFRESH_VIEW,
  showNotification,
} from 'react-admin'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { DUPLICATE, DUPLICATE_FAILURE, DUPLICATE_LOADING, DUPLICATE_SUCCESS } from '../actions/restActions'
import { SET_UPLOAD_LOGO } from '../ducks/uploadLogo'

const ENTITY_CHANGED_ACTION = [CRUD_UPDATE_SUCCESS, CRUD_DELETE_SUCCESS, CRUD_CREATE_SUCCESS, DUPLICATE_SUCCESS]
const ORG_DEPENDANT_RESOURCES = ['content', 'orgs', 'tags', 'custom_fields', 'connected_orgs', 'workflows']

export default (restClient) => {
  if (!restClient) return () => null
  function* handleAction(action) {
    const { type, payload, meta } = action

    if (ENTITY_CHANGED_ACTION.includes(type) && ORG_DEPENDANT_RESOURCES.includes(meta.resource)) {
      console.debug('Entity contained in Org data has been updated, reloading org data...')
      yield put(orgsActions.reloadOrg())
    }

    switch (type) {
      case CRUD_UPDATE_SUCCESS:
        if (meta.resource === 'content') {
          //@TODO: Can we check whether SET_UPLOAD_LOGO is currently true and only send this action if currently true?
          yield put({ type: SET_UPLOAD_LOGO, payload: false })
        }
        break

      case CRUD_CREATE_SUCCESS:
        // Reload project page after creating a new event in the popup
        if (meta.resource === 'events') {
          yield put({ type: REFRESH_VIEW })
        }
        break

      case DUPLICATE: {
        try {
          yield put({ type: DUPLICATE_LOADING })
          const restPayload = yield call(restClient, DUPLICATE, payload)
          yield put({
            type: DUPLICATE_SUCCESS,
            payload: restPayload,
          })
          yield put(push('/' + payload.resource + '/' + restPayload.data.id))
          yield put(showNotification('Record Copied', 'success'))
        } catch (e) {
          yield put({
            type: DUPLICATE_FAILURE,
            error: e,
          })
          const errorMessage =
            typeof e === 'string' ? e : typeof e === 'undefined' || !e.message ? 'duplicate_error' : e.message
          yield put(showNotification(errorMessage, 'warning'))
        }
        break
      }
      default:
    }
  }
  return function* watchActions() {
    yield all([takeEvery((action) => action.meta && action.meta, handleAction)])
  }
}

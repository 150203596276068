import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useTranslate } from 'react-admin'
import { Field, useForm, useFormState } from 'react-final-form'

type PropTypes = {}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .Mui-checked': {
      color: '#1890ff',
    },
    '& .MuiIconButton-colorSecondary:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
}))

export const IsResidentialRadioButtonGroup: React.FunctionComponent<PropTypes> = (props) => {
  const formState = useFormState()
  const classes = useStyles()
  const form = useForm()
  const translate = useTranslate()
  const value = formState.values['is_residential'] !== false
  return (
    <Field name="is_residential">
      {() => (
        <RadioGroup
          className={classes.root}
          name="is_residential"
          value={Boolean(value)}
          onChange={(e) => {
            // hack as material UI dispatch the new value as string...
            const newValue = e.target.value === 'true'
            if (newValue === true) {
              const fieldsNeedToBeClear = ['business_name', 'business_identifier']
              fieldsNeedToBeClear.forEach((field) => form.mutators.updateField(field, null))
            }
            form.mutators.updateField('is_residential', newValue)
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
            <div>
              <FormControlLabel value={true} control={<Radio />} label={translate('Residential')} />
            </div>
            <div>
              <FormControlLabel value={false} control={<Radio />} label={translate('Commercial')} />
            </div>
          </div>
        </RadioGroup>
      )}
    </Field>
  )
}

export default IsResidentialRadioButtonGroup

const manageFields = [
  'access',
  'actions',
  'workflow',
  'assigned_installer_role',
  'assigned_role',
  'assigned_site_inspector_role',
  'priority',
]

export default manageFields

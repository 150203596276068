import { makeStyles, Slide } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import { viewModeActions } from 'ducks/viewMode'
import { IconButton } from 'opensolar-ui'
import React, { useCallback, useContext } from 'react'
import { useTranslate } from 'react-admin'
import { useDispatch } from 'react-redux'
import { SidePanelContext } from './SidePanel'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: 350,
    background: 'rgb(255,255,255)',
    height: '100%',
    boxSizing: 'border-box',
    padding: '16px 0px 80px 0px',
    marginRight: 16,
    [theme.breakpoints.up('xl')]: { paddingBottom: 60 },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 60,
      width: '100%',
      left: 'calc(50% - 175px)',
      maxWidth: 350,
      padding: '16px 0px 80px 0px',
      position: 'absolute',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    border: '0px none',
    overflowX: 'hidden',
    overflowY: 'auto',
    boxSizing: 'border-box',
    height: '100%',
    borderRadius: 10,
    boxShadow: '0px 0px 4px rgb(0 0 0 / 0.25)',
  },
  header: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    flexDirection: 'row',
    flex: 0,
    height: '64px',
    minHeight: '64px',
    paddingLeft: '24px',
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
    verticalAlign: 'middle',
  },
  iconButton: {
    color: 'rgba(0,0,0,0.54)',
    padding: 5,
    marginRight: 4,
    width: 48,
    height: 48,
  },
}))

type PropsType = {
  title: string
  children: React.ReactNode
}

const Panel = ({ title, children }: PropsType) => {
  const classes = useStyles()
  const { state, setState } = useContext(SidePanelContext)
  const translate = useTranslate()
  const dispatch = useDispatch()

  const handleClose = useCallback(() => {
    dispatch(viewModeActions.setHomeDrawerState(undefined))
  }, [state])

  return (
    <Slide direction="left" timeout={500} in={true} mountOnEnter unmountOnExit>
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <div className={classes.header}>
            <span className={classes.title}>{translate(title)}</span>
            <IconButton className={classes.iconButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          {children}
        </div>
      </div>
    </Slide>
  )
}

export default Panel

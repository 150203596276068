import { makeStyles } from '@material-ui/core/styles'
import { IconButton } from 'opensolar-ui'
import React, { MouseEvent } from 'react'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    color: 'rgba(0,0,0,0.87)',
    padding: 0,
    '& .MuiSvgIcon-root': {
      width: 22,
      height: 22,
      padding: 6,
    },
  },
}))

type PropTypes = {
  size?: 'medium' | 'small'
  onClick: (e: MouseEvent) => void
  disabled?: boolean
}

const ClickableIcon: React.FunctionComponent<PropTypes> = (props) => {
  const classes = useStyles()
  if (!props.children) return null
  return (
    <IconButton onClick={props.onClick} className={classes.wrapper} size={props.size} disabled={props.disabled}>
      {props.children}
    </IconButton>
  )
}

export default ClickableIcon

import { AddersCreate, AddersEdit, AddersList } from './Adders'

type PropsType = {
  record: any
  translate: Function
  style?: any
}

export default {
  list: AddersList,
  edit: AddersEdit,
  create: AddersCreate,
  options: {
    create: {
      title: 'Create Adder',
      subtitle: null,
      breadCrumb: 'Create Adder',
    },
    list: {
      title: 'Adders Library',
      subtitle: null,
      breadCrumb: 'Adders Library',
    },
    edit: {
      title: 'Edit Adder',
      subtitle: null,
      breadCrumb: 'Edit Adder',
    },
  },
}

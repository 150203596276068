import { Grid, Tooltip, makeStyles } from '@material-ui/core'
import { Info, SaveOutlined } from '@material-ui/icons'
import { orgSelectors } from 'ducks/orgs'
import LoadingDots from 'layout/widgets/LoadingDots'
import { Button } from 'opensolar-ui'
import CashFlowSetupChecklist from 'pages/cashFlow/sharedComponents/CashFlowSetupChecklist'
import { useNotify, useTranslate } from 'ra-core'
import { BooleanInput, SelectArrayInput, TextInput } from 'ra-ui-materialui'
import React, { useEffect, useState } from 'react'
import { Form, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { sanitizeCashFlowConfigForm, useGetCashFlowConfigurations, useGetPaymentMethodChoices } from '../utils'

type PropTypes = {
  config: any
}

const useStyles = makeOpenSolarStyles((theme) => ({
  containerWrapper: {
    overflow: 'hidden',
  },

  container: {
    background: theme.white,
    padding: '16px 24px',
    border: '1px solid #e7e7e7',
    borderRadius: '5px',
    margin: '1rem 0',
    lineHeight: 1.5,
  },

  header: {
    fontSize: 18,
    margin: '10px 0',
    fontWeight: 400,
  },

  headerRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  justifyCenter: {
    justifyContent: 'center',
  },

  buttonsContainer: {
    display: 'flex',
    justifyContent: 'end',
    margin: '1.5rem 0',
  },
  buttonWrapper: {
    backgroundColor: 'white',
    margin: '0px 10px 0px 20px',
  },

  milestoneContainer: {
    background: theme.white,
    padding: '16px 24px',
    border: '1px solid #e7e7e7',
  },

  fieldRow: {
    margin: '5px 0',
  },

  flexRow: {
    display: 'flex',
    alignItems: 'center',
    gap: '1.5rem',
  },

  infoIcon: {
    fontSize: 18,
    color: theme.greyMid1,
  },
}))

const useCustomStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormControlLabel-root': {
      flexFlow: 'row',
    },
  },
}))

const FinancedProjectDeposits: React.FC<PropTypes> = (props) => {
  const restClientInstance = restClient(window.API_ROOT + '/api')
  const orgId = useSelector(orgSelectors.getOrg)?.id

  const formValues = useFormState().values
  const classes = useStyles()
  const switchClasses = useCustomStyles()
  const translate = useTranslate()
  const notify = useNotify()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const paymentMethodChoices = useGetPaymentMethodChoices()

  useEffect(() => {
    if (formValues) setIsLoading(false)
  }, [formValues])

  const onSave = () => {
    if (orgId) {
      setIsLoading(true)
      restClientInstance('CUSTOM_PUT', 'custom', {
        url: `orgs/${orgId}/payments/configuration/${formValues.id}/`,
        data: sanitizeCashFlowConfigForm(formValues),
      })
        .then((res) => {
          notify(translate('Your updates have been saved', 'success'))
        })
        .catch((err) => {
          if (err?.body?.message) notify(err.body.message, 'warning')
          else if (err?.body?.non_field_errors?.length) notify(err.body.non_field_errors[0], 'warning')
          else notify(translate('Something went wrong'), 'error')
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  return (
    <div className={classes.containerWrapper}>
      <Grid container spacing={2} className={classes.justifyCenter}>
        <Grid item xs={11} lg={8}>
          <div>
            <div className={classes.container}>
              <h1 className={classes.header}>{translate('Financed Project Deposits')}</h1>
              <p>
                {translate(
                  'CashFlow lets your customers pay deposits for financed projects or other payment options. To require a deposit, set a default deposit amount for the payment option itself or for individual projects on the payment option card.'
                )}
              </p>
            </div>
          </div>

          {isLoading ? (
            <LoadingDots />
          ) : (
            <>
              <div className={classes.milestoneContainer}>
                <div className={classes.headerRow}>
                  <h2>Milestone 1</h2>
                </div>
                <Grid container xs={12}>
                  <Grid item xs={6}>
                    <TextInput
                      label={`Title`}
                      variant="outlined"
                      fullWidth
                      source="payment_milestone_configurations[0].title"
                      className={classes.fieldRow}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      label={`Description`}
                      variant="outlined"
                      fullWidth
                      source="payment_milestone_configurations[0].description"
                      className={classes.fieldRow}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <div className={classes.flexRow}>
                    <BooleanInput label="Is Archived" classes={switchClasses} source="is_archived" />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={classes.flexRow}>
                    <BooleanInput
                      label="Enable Surcharging"
                      classes={switchClasses}
                      source="payment_milestone_configurations[0].surcharging_enabled"
                    />
                    <span>
                      <Tooltip
                        title="When Surcharging is enabled credit card fees will be paid by the customer as part of the checkout flow"
                        enterDelay={300}
                        className={classes.infoIcon}
                      >
                        <Info />
                      </Tooltip>
                    </span>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <SelectArrayInput
                    label="Accepted Payment Methods"
                    source="payment_milestone_configurations[0].accepted_payment_methods"
                    choices={paymentMethodChoices}
                    fullWidth={true}
                    className={classes.fieldRow}
                  />
                </Grid>
              </div>
              <div className={classes.buttonsContainer}>
                <Button variant="contained" color="primary" type="submit" onClick={onSave}>
                  <SaveOutlined /> {translate('Save')}
                </Button>
              </div>
            </>
          )}
        </Grid>
        <Grid item xs={11} lg={3}>
          <CashFlowSetupChecklist activeStep={2} />
        </Grid>
      </Grid>
    </div>
  )
}

const FinancedProjectDepositsFormWrapper: React.FC<PropTypes> = (props) => {
  const onSubmit = () => {}
  const orgId = useSelector(orgSelectors.getOrg)?.id
  const configs = useGetCashFlowConfigurations(orgId, 1, 10).find((config) => config.deposit_only === true)

  return (
    <Form
      onSubmit={() => {}}
      initialValues={configs}
      render={(formProps) => <FinancedProjectDeposits {...formProps} {...props} />}
    />
  )
}

export default FinancedProjectDepositsFormWrapper

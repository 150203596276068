import { and, ControlProps, rankWith, uiTypeIs } from '@jsonforms/core'
import { Unwrapped } from '@jsonforms/material-renderers'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { ExpandLessOutlined, ExpandMoreOutlined } from '@material-ui/icons'
import React, { useState } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const { MaterialBooleanControl } = Unwrapped

const tester = rankWith(100, and(uiTypeIs('ExpandableTerms')))

const useStyles = makeOpenSolarStyles((theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  icon: {
    cursor: 'pointer',
  },
  readMoreRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  readMore: {
    cursor: 'pointer',
    fontSize: '12px',
    color: '#a2a2a2',
    textDecoration: 'underline',
  },
}))

const Renderer: React.FC<ControlProps> = (props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const classes = useStyles()

  // @ts-ignore
  const termsName = props.schema?.properties?.ExpandableTerms?.name

  const onChange = (value: boolean) => {
    setIsChecked(value)
    props.handleChange(props.path, value)
  }

  return (
    <div>
      <div className={classes.row}>
        <MaterialBooleanControl
          {...props}
          handleChange={(path, value) => onChange(value)}
          data={isChecked}
          label={`I agree to the ${termsName}`}
        />
        {isExpanded ? (
          <ExpandLessOutlined className={classes.icon} onClick={() => setIsExpanded(false)} />
        ) : (
          <ExpandMoreOutlined className={classes.icon} onClick={() => setIsExpanded(!isExpanded)} />
        )}
      </div>
      {isExpanded ? (
        // @ts-ignore
        <div className="small">{props.schema?.properties?.ExpandableTerms?.text}</div>
      ) : (
        <div className={classes.readMoreRow}>
          {/* @ts-ignore */}
          <div className="small">{props.schema?.properties?.ExpandableTerms?.text.substring(0, 80)}</div>
          <div>
            ...{' '}
            <a className={classes.readMore} onClick={() => setIsExpanded(true)}>
              Read more
            </a>
          </div>
        </div>
      )}
    </div>
  )
}

const renderer = withJsonFormsControlProps(Renderer)

export default {
  tester,
  renderer,
}

// @ts-nocheck
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import PhoenixApplicationButton from 'myenergy/elements/phoenix/PhoenixApplicationButton'
import React, { useState } from 'react'
import { ProjectType } from 'types/projects'
import BrighteApplicationButton from './brighte/BrighteApplicationButton'
import DividendApplicationButton from './dividend/DividendApplicationButton'
import GenericIntegratedFinanceApplicationButton from './generic/IntegratedFinanceApplicationButton'
import LoanpalApplicationButton from './loanpal/LoanpalApplicationButton'
import MosaicApplicationButton from './mosaic/MosaicApplicationButton'
import PlentiApplicationButton from './plenti/PlentiApplicationButton'
import SungageApplicationButton from './sungage/SungageApplicationButton'
import SunlightApplicationButton from './sunlight/SunlightApplicationButton'
import { ActionDataType } from './types'

type PropTypes = {
  actionData: ActionDataType
  translate: (raw: string) => string
  showDialog: (transactionType: string, options: { url: string }, isFullWidth?: boolean) => void
  setWaitingForAction: Function
  myeStyles: CSSProperties
  acceptButtonStyle: CSSProperties
  acceptButtonStyleDisabled: CSSProperties
  isPro: boolean
  selectedPaymentOptionName: string
  selectedProject: ProjectType
}
const LoanApplication: React.FunctionComponent<PropTypes> = (props) => {
  const { actionData } = props
  const [loanpalDecisionRendered, setLoanpalDecisionRendered] = useState<boolean>(false)

  if (actionData.payment_method === 'sunlight_loan_application') {
    return <SunlightApplicationButton {...props} />
  } else if (actionData.payment_method === 'loanpal_application') {
    return (
      <LoanpalApplicationButton
        {...props}
        loanpalDecisionRendered={loanpalDecisionRendered}
        onDecisionRendered={() => setLoanpalDecisionRendered(true)}
      />
    )
  } else if (actionData.payment_method === 'mosaic_application') {
    return (
      <MosaicApplicationButton
        {...props}
        decisionRendered={loanpalDecisionRendered}
        onDecisionRendered={() => setLoanpalDecisionRendered(true)}
      />
    )
  } else if (actionData.payment_method === 'sungage_application') {
    return (
      <SungageApplicationButton
        {...props}
        decisionRendered={loanpalDecisionRendered}
        onDecisionRendered={() => setLoanpalDecisionRendered(true)}
      />
    )
  } else if (actionData.payment_method === 'brighte_application') {
    return (
      <BrighteApplicationButton
        {...props}
        decisionRendered={loanpalDecisionRendered}
        onDecisionRendered={() => setLoanpalDecisionRendered(true)}
      />
    )
  } else if (actionData.payment_method === 'dividend_application') {
    return (
      <DividendApplicationButton
        {...props}
        decisionRendered={loanpalDecisionRendered}
        onDecisionRendered={() => setLoanpalDecisionRendered(true)}
      />
    )
  } else if (actionData.payment_method === 'phoenix_application') {
    return <PhoenixApplicationButton {...props} />
  } else if (
    actionData.payment_method === 'plenti_loan_application' ||
    actionData.payment_method === 'plenti_bnpl_application'
  ) {
    return <PlentiApplicationButton {...props} />
  } else if (actionData.finance_integration) {
    return (
      <GenericIntegratedFinanceApplicationButton
        {...props}
        decisionRendered={loanpalDecisionRendered}
        onDecisionRendered={() => setLoanpalDecisionRendered(true)}
      />
    )
  } else return null
}
export default LoanApplication

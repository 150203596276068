import { AccordionDetails, Grid } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { orgSelectors } from 'ducks/orgs'
import EnabledSupplierSelector from 'elements/EnabledSupplierSelector'
import ComponentTypeImg from 'elements/field/ComponentTypeImag'
import QuantityButton from 'pages/ordering/catalogv3/QuantityButton'
import { STOCK_LEVEL_STATUS } from 'pages/ordering/constants'
import LowStockWarning from 'pages/ordering/order/table/quantity/LowStockWarning'
import type LineItemType from 'pages/ordering/OrderLineItem'
import ProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/projectOrderPresenter'
import { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { ComponentTypes } from 'types/selectComponent'
import { formatCurrencyWithSymbol } from 'util/misc'
import AlternativePicker from './AlternativePicker'
import ProductTitle from './ProductTitle'
import RemoveLineItemButton from './RemoveLineItemButton'

const useStyles = makeOpenSolarStyles((theme) => ({
  container: {
    fontSize: theme.typography.caption.fontSize,
    margin: '10px 0',
    boxShadow: 'none',
    border: '1px solid #DCDEE3',
    borderRadius: 6,
  },
  textWrapper: {
    overflow: 'hidden',
  },
  quantity: {
    textAlign: 'center',
  },
  icon: {
    textAlign: 'center',
  },
  segenLoginPrompt: {
    padding: 0,
    marginBottom: 10,
    textAlign: 'end',
  },
  productTitle: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    textDecorationLine: 'underline',
  },
  productSubtitle: {
    color: '#7F7D83',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '18px',
  },
  productPrice: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '22px',
  },
  chipContainer: {
    borderRadius: 5,
    padding: '0px 6px',
  },
  chipDefault: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.grey[800],
  },
  chipError: {
    backgroundColor: '#FFE3E3',
    color: theme.palette.error.dark,
  },
  qtyButtonWrapper: {
    marginTop: 18,
    width: '100%',
  },
}))

// TODO: Could we just reuse the Cost component from the ordering/order/table/Cost.tsx file?
// Has the same variables and logic but different styling.
const LineItemCost = ({ isAvailable, price, priceAfterDiscount }) => {
  const classes = useStyles()
  const usingCustomPricing = price === 'TBC'
  const hasDiscount = !usingCustomPricing && priceAfterDiscount != null && price > priceAfterDiscount
  const currencySymbol = useSelector(orgSelectors.getCurrencySymbolFromOrg)

  if (!isAvailable) {
    return <Typography className={classes.productPrice}>{`N/A`}</Typography>
  }

  return hasDiscount ? (
    <>
      <Typography className={classes.productPrice} style={{ color: '#019939' }}>
        {formatCurrencyWithSymbol(priceAfterDiscount, currencySymbol)}
      </Typography>
      <Typography className={classes.productSubtitle}>{`Was ${formatCurrencyWithSymbol(
        price,
        currencySymbol
      )}`}</Typography>
    </>
  ) : (
    <Typography className={classes.productPrice}>{formatCurrencyWithSymbol(price, currencySymbol)}</Typography>
  )
}

const BomComponentDetail = ({
  title,
  lineItems,
  componentType,
  location,
  handleUpdateSupplier,
  handleRemoveLineItem,
}: {
  title: string
  lineItems: LineItemType[]
  componentType: ComponentTypes | 'mounting'
  showChip?: boolean
  location: 'cart' | 'design'
  handleUpdateSupplier: (
    selectedSupplier: HardwareSupplierFilterKeyType,
    code: string,
    price: number | undefined
  ) => void
  handleRemoveLineItem: ((uuid: string) => void) | null
}) => {
  const classes = useStyles()
  const currencySymbol = useSelector(orgSelectors.getCurrencySymbolFromOrg)
  const isActivatedComponentOrderingDataReady = true

  const totalQty = useMemo(() => {
    return lineItems.reduce((acc, lineItem) => {
      return acc + lineItem.quantity
    }, 0)
  }, [lineItems])

  const totalAvailable = useMemo(() => {
    let totalQty = 0
    for (const lineItem of lineItems) {
      const stockLevelStatus = ProjectOrderPresenter.getStockLevelStatusFromLineItem(lineItem)

      if (stockLevelStatus === STOCK_LEVEL_STATUS.AVAILABLE) {
        totalQty += lineItem.quantity
      } else if (stockLevelStatus === STOCK_LEVEL_STATUS.PARTIAL_AVAILABLE) {
        const { stockLevels } = lineItem
        const inStockLevel = stockLevels?.find((stock) => stock.in_stock)
        totalQty += inStockLevel?.quantity || 0
      }
    }

    return totalQty
  }, [lineItems])

  return (
    <Accordion defaultExpanded={totalAvailable !== totalQty} elevation={2} className={classes.container}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container alignItems="center" style={{ justifyContent: 'space-between' }}>
          <Grid direction="row" alignItems="center" style={{ display: 'flex' }}>
            <ComponentTypeImg size="small" componentType={componentType} />
            <Typography variant="subtitle2" style={{ marginLeft: 4 }}>{`${title}`}</Typography>
          </Grid>
          {location === 'design' && (
            <div
              className={`${classes.chipContainer} ${
                totalAvailable === totalQty ? classes.chipDefault : classes.chipError
              }`}
            >{`${totalAvailable}/${totalQty} items available`}</div>
          )}
        </Grid>
      </AccordionSummary>
      {lineItems.map((lineItem: LineItemType) => {
        const { beforeDiscount, afterDiscount, discount } = lineItem.getBestDiscountOffer()
        const lineItemSupplierInfo = lineItem?.data?.distributors.find(
          (distributor) => distributor.distributor === lineItem.selectedDistributor
        )
        const stockStatus = ProjectOrderPresenter.getStockLevelStatusFromLineItem(lineItem)
        const showPrice = [STOCK_LEVEL_STATUS.AVAILABLE, STOCK_LEVEL_STATUS.OUT_OF_STOCK].includes(stockStatus)
        return (
          <AccordionDetails>
            <Grid
              key={lineItem.code}
              direction="row"
              container
              alignContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid xs={3}>
                <img
                  src={lineItemSupplierInfo?.image_url || `${window.PUBLIC_URL}/images/component_photo_holder.svg`}
                  style={{ maxWidth: 80, maxHeight: 80 }}
                />
              </Grid>
              <Grid xs={6}>
                <ProductTitle lineItem={lineItem} location={location} />
                <Typography className={classes.productSubtitle} style={{ marginTop: 6 }}>
                  {lineItem.code}
                </Typography>
                {location === 'design' ? (
                  <Grid item container direction="row" style={{ marginTop: 8, display: 'flex', alignItems: 'center' }}>
                    <Typography className={classes.productSubtitle}>{`Quantity: ${lineItem.quantity}`}</Typography>
                    <Typography className={classes.productSubtitle} style={{ marginLeft: 8 }}>{`Unit Price: ${
                      showPrice ? formatCurrencyWithSymbol(lineItem.pricePerUnit, currencySymbol) : 'N/A'
                    }`}</Typography>
                  </Grid>
                ) : (
                  <div className={classes.qtyButtonWrapper}>
                    {stockStatus === STOCK_LEVEL_STATUS.OUT_OF_STOCK && <LowStockWarning lineItem={lineItem} />}
                    <QuantityButton
                      uuid={lineItem?.uuid}
                      handleZeroQuantity={() => {
                        if (handleRemoveLineItem) handleRemoveLineItem(lineItem?.uuid)
                      }}
                      initialValue={lineItem?.quantity}
                    />
                  </div>
                )}
              </Grid>
              <Grid xs={3} style={{ textAlign: 'right' }}>
                <LineItemCost isAvailable={showPrice} price={beforeDiscount} priceAfterDiscount={afterDiscount} />
                <EnabledSupplierSelector
                  selectedSupplier={lineItem?.selectedDistributor}
                  handleChangeSupplier={(supplier) => {
                    handleUpdateSupplier(supplier, lineItem?.code, lineItemSupplierInfo?.price)
                  }}
                />
                {location === 'cart' && (
                  <RemoveLineItemButton
                    handleRemove={() => {
                      if (handleRemoveLineItem) handleRemoveLineItem(lineItem?.uuid)
                    }}
                  />
                )}
              </Grid>
              {location === 'design' && stockStatus !== STOCK_LEVEL_STATUS.AVAILABLE && (
                <AlternativePicker component={lineItem} />
              )}
            </Grid>
          </AccordionDetails>
        )
      })}
    </Accordion>
  )
}

export default BomComponentDetail

import { useMemo } from 'react'
import type { ComponentTypesV2 } from 'types/selectComponent'
import useHardwareSelectorFetchEngine from './useHardwareSelectorFetchEngine'


interface GroupedHardwareData {
  modules: any[]
  inverters: any[]
  batteries: any[]
  mounting: any[]
  loading: boolean
}

const useGroupedHardwareSelector = (defaultFilterValues?: { [key: string]: any }): GroupedHardwareData => {
  const mountingTypes = [
    'dc_optimizer',
    'mounting_other',
    'mounting_clamp',
    'mounting_rail',
    'mounting_flashing',
  ] as ComponentTypesV2[]

  // Separate fetch calls for each group
  const { componentsData: moduleData, loading: moduleLoading } = useHardwareSelectorFetchEngine({
    componentTypes: ['module'],
    defaultFilterValues,
    limit: 10,
  })

  const { componentsData: inverterData, loading: inverterLoading } = useHardwareSelectorFetchEngine({
    componentTypes: ['inverter'],
    defaultFilterValues,
    limit: 10,
  })

  const { componentsData: batteryData, loading: batteryLoading } = useHardwareSelectorFetchEngine({
    componentTypes: ['battery'],
    defaultFilterValues,
    limit: 10,
  })

  const {
    componentsData: mountingData,
    loading: mountingLoading
  } = useHardwareSelectorFetchEngine({
    componentTypes: mountingTypes,
    defaultFilterValues,
    limit: 10,
  })

  const loading = moduleLoading || inverterLoading || batteryLoading || mountingLoading

  const groupedData = useMemo(
    () => ({
      modules: moduleData || [],
      inverters: inverterData || [],
      batteries: batteryData || [],
      mounting: mountingData || [],
      loading,
    }),
    [moduleData, inverterData, batteryData, mountingData, loading]
  )

  return groupedData
}

export default useGroupedHardwareSelector

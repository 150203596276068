import { makeStyles } from '@material-ui/core'
import { Grid } from 'opensolar-ui'
import AccountManagerSection from 'pages/ordering/checkoutv3/preOrder/AccountManagerSection'
import DeliveryDetailSection from 'pages/ordering/checkoutv3/preOrder/DeliveryDetailSection'
import CityPlumbingCartSection from 'pages/ordering/checkoutv3/preOrder/distributorCart/CityPlumbingCartSection'
import HdmCartSection from 'pages/ordering/checkoutv3/preOrder/distributorCart/HdmCartSection'
import OutletCartSection from 'pages/ordering/checkoutv3/preOrder/distributorCart/OutletCartSection'
import TrustSolarCartSection from 'pages/ordering/checkoutv3/preOrder/distributorCart/TrustSolarCartSection'
import VtacCartSection from 'pages/ordering/checkoutv3/preOrder/distributorCart/VtacCartSection'
import OrderSummarySection from 'pages/ordering/checkoutv3/preOrder/OrderSummarySection'
import { COLOR_PALETTE } from 'pages/ordering/checkoutv3/preOrder/styles'
import { CheckoutSourceType } from 'pages/ordering/checkoutv3/preOrder/types'
import { createElement, memo, useContext } from 'react'
import { useFormState } from 'react-final-form'
import { Theme } from 'types/themes'
import { OrderContext } from '..'
import EmptyCheckout from './EmptyCheckout'
import PreOrderProvider, { DistributorDataContext, TotalItemCountContext } from './PreOrderProvider'
import SegenCartSection from './SegenCartSection'
import SystemOptionSection from './SystemOptionSection'
import { useTranslate } from 'ra-core'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '40px 28px',
    width: '100%',
    maxWidth: 1440,
    boxSizing: 'border-box',
    flexGrow: 1,
  },
  columnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    flexGrow: 1,
  },
  leftColumnGap: {
    gap: '12px',
  },
  rightColumnGap: {
    gap: '24px',
  },
  distributorWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100vw',
    padding: '24px 0px',
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerContent: {
    fontSize: '24px',
    fontWeight: 600,
  },
  emptyStateWrapper: {
    diplay: 'flex',
    flexDirection: 'column',
    background: COLOR_PALETTE.white,
  },
}))

const DISTRIBUTOR_CART_MAPPING = {
  segen: SegenCartSection,
  city_plumbing: CityPlumbingCartSection,
  hdm: HdmCartSection,
  vtac_uk: VtacCartSection,
  vtac_pl: VtacCartSection,
  trust_solar: TrustSolarCartSection,
  outlet: OutletCartSection,
}

const PreOrderContent = () => {
  const classes = useStyles()
  const distributordata = useContext(DistributorDataContext)
  const checkoutSource: CheckoutSourceType = 'project_hardware'
  const { updateHardwareComponent } = useContext(OrderContext)
  let componentSortOrder: number = 2
  return (
    <Grid container spacing={3} className={classes.wrapper}>
      <Grid item xs={8}>
        <Grid container className={`${classes.columnWrapper} ${classes.leftColumnGap}`}>
          <Grid item xs={12}>
            <SystemOptionSection />
          </Grid>
          <Grid item xs={12}>
            <DeliveryDetailSection sortOrder={componentSortOrder} checkoutSource={checkoutSource} />
          </Grid>
          <Grid item xs={12} className={classes.distributorWrapper}>
            {Object.keys(distributordata).map((item) => {
              return createElement(DISTRIBUTOR_CART_MAPPING[item], {
                distributorKey: item,
                key: item,
                sortOrder: componentSortOrder + 1,
                checkoutSource,
              })
            })}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid container className={`${classes.columnWrapper} ${classes.rightColumnGap}`}>
          <Grid item xs={12}>
            <OrderSummarySection checkoutSource={checkoutSource} updateHardwareComponent={updateHardwareComponent} />
          </Grid>
          <Grid item xs={12}>
            <AccountManagerSection checkoutSource={checkoutSource} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const PreOrder = () => {
  const classes = useStyles()
  const itemCount = useContext(TotalItemCountContext)
  const project = useFormState().values
  const systems = project?.systems || []
  const translate = useTranslate()

  if (itemCount === 0) {
    return <EmptyCheckout />
  }

  return (
    <>
      {systems.length > 0 && (
        <div className={classes.headerWrapper}>
          <span className={classes.headerContent}>{translate(`Review and Place Order (${itemCount} items)`)}</span>
        </div>
      )}
      <PreOrderContent />
    </>
  )
}

const PreOrderPage = () => (
  <PreOrderProvider>
    <PreOrder />
  </PreOrderProvider>
)

export default memo(PreOrderPage)

import { useCallback, useMemo } from 'react'
import { isCableLengthFilterAvailable } from '../cableLength/CableLengthFilter'
import { CABLE_LENGTH_FILTER_KEY } from '../cableLength/CableLengthFilterNodeFactory'
import { isCableThicknessFilterAvailable } from '../cableThickness/CableThicknessFilter'
import { CABLE_THICKNESS_FILTER_KEY } from '../cableThickness/CableThicknessFilterNodeFactory'
import { isCurrentRatingFilterAvailable } from '../currentRating/CurrentRatingFilter'
import { CURRENT_RATING_FILTER_GTE_KEY, CURRENT_RATING_FILTER_LTE_KEY } from '../currentRating/constants'
import { isCurrentTypeFilterAvailable } from '../currentType/CurrentTypeFilter'
import { CURRENT_FILTER_KEY } from '../currentType/CurrentTypeFilterNodeFactory'
import { isOtherComponentPhaseFilterAvailable } from '../phaseType/PhaseTypeFilter'
import { PHASE_FILTER_KEY } from '../phaseType/PhaseTypeFilterNodeFactory'
import type { FilterValuesType } from '../type'
import { isVoltageAndCurrentFilterAvailable } from '../voltageAndCurrent/VoltageAndCurrentFilter'
import { VOLTAGE_AND_CURRENT_FILTER_KEY } from '../voltageAndCurrent/VoltageAndCurrentFilterNodeFactory'

const useOtherComponentTypeFilterSideEffect = () => {
  const FILTER_KEYS_AND_AVAILABILITY_CHECK = useMemo(
    () => [
      {
        isAvailable: isCableLengthFilterAvailable,
        filterKeys: [CABLE_LENGTH_FILTER_KEY],
      },
      {
        isAvailable: isCurrentTypeFilterAvailable,
        filterKeys: [CURRENT_FILTER_KEY],
      },
      {
        isAvailable: isOtherComponentPhaseFilterAvailable,
        filterKeys: [PHASE_FILTER_KEY],
      },
      {
        isAvailable: isCurrentRatingFilterAvailable,
        filterKeys: [CURRENT_RATING_FILTER_GTE_KEY, CURRENT_RATING_FILTER_LTE_KEY],
      },
      {
        isAvailable: isCableThicknessFilterAvailable,
        filterKeys: [CABLE_THICKNESS_FILTER_KEY],
      },
      {
        isAvailable: isVoltageAndCurrentFilterAvailable,
        filterKeys: [VOLTAGE_AND_CURRENT_FILTER_KEY],
      },
    ],
    []
  )
  return useCallback(({ filterValues, value }: { filterValues: FilterValuesType; value: string }): FilterValuesType => {
    const newFilterValue = { ...filterValues }
    FILTER_KEYS_AND_AVAILABILITY_CHECK.forEach(({ isAvailable, filterKeys }) => {
      if (!isAvailable(filterValues)) {
        filterKeys.forEach((key) => delete newFilterValue[key])
      }
    })
    return newFilterValue
  }, [])
}

export default useOtherComponentTypeFilterSideEffect

// @ts-nocheck
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Theme, useMediaQuery } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import ProUxButton from 'elements/proUXButtons/ProUXButton'
import React, { useEffect, useState } from 'react'
import { useNotify } from 'react-admin'
import restClient from 'restClient'
import { CustomerActionType } from 'types/customerActions'
import { ProjectType } from 'types/projects'
import { LOANPAL_OR_GOODLEAP } from '../loanApplicationButton/loanpal/constants'
import { LoanpalDecisionType } from '../loanApplicationButton/loanpal/types'

const API_URL = window.API_ROOT + '/api'
const restClientInstance = restClient(API_URL)

type DecisionsResponseType = {
  [contactId: string]: {
    applicant_type: 'primary' | 'secondary'
    email: string
    decision_status: LoanpalDecisionType
  }
}

type PropTypes = {
  project: ProjectType
  integration: 'loanpal' | 'mosaic'
}

const StipCTAForPros: React.FunctionComponent<PropTypes> = (props) => {
  const [stipActions, setStipActions] = useState<CustomerActionType | undefined>(undefined)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [loadingSend, setLoadingSend] = useState<boolean>(false)
  const [decisionsLoaded, setDecisionsLoaded] = useState<boolean>(false)
  const [primaryEmail, setPrimaryEmail] = useState<string | undefined>(undefined)
  const [secondaryEmail, setSecondaryEmail] = useState<string | undefined>(undefined)
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 967))
  const isXxs = useMediaQuery((theme: Theme) => theme.breakpoints.down(332))

  const notify = useNotify()

  useEffect(() => {
    if (showDialog) {
      getApplicantDecisions()
      logAmplitudeEvent('send_stip_link_clicked', {
        page: window.location.hash?.includes('/manage') ? 'Manage Page' : 'Info Page',
        integration: props.integration,
      })
    }
  }, [showDialog])

  useEffect(() => {
    if (
      props.project?.available_customer_actions &&
      props.project.available_customer_actions.length > 0 &&
      props.project.available_customer_actions[0] &&
      props.project.available_customer_actions[0].actions_available &&
      props.project.available_customer_actions[0].actions_available.length > 0
    ) {
      let actionsWithStips = props.project.available_customer_actions[0].actions_available.filter(
        (act) => ['created'].includes(act.stipulation_review_status) && act.stipulation_acceptable_docs
      )
      if (actionsWithStips?.length > 0) setStipActions(actionsWithStips)
    }
  }, [props.project?.available_customer_actions])

  const getApplicantDecisions = () => {
    restClientInstance('CUSTOM_GET', 'custom', {
      url: 'orgs/' + props.project.org_id + '/projects/' + props.project.id + '/loanpal_applicant_decisions/',
    })
      .then((response: DecisionsResponseType) => {
        if (response?.data?.decisions) {
          Object.keys(response.data.decisions).forEach((contactId: string) => {
            let applicant = response.data.decisions[contactId]
            if (applicant.applicant_type === 'primary') setPrimaryEmail(applicant.email)
            else setSecondaryEmail(applicant.email)
          })
        }
      })
      .catch((err: any) => {
        console.log('error getting available actions', err)
      })
      .finally(() => setDecisionsLoaded(true))
  }

  const sendEmail = () => {
    if (!primaryEmail) {
      setErrorMsg('Email is required')
      return
    }
    setLoadingSend(true)
    const location = window.location.hash?.includes('/manage') ? 'Manage Page' : 'Info Page'
    restClientInstance('CUSTOM_POST', 'custom', {
      url:
        'orgs/' + props.project.org_id + '/projects/' + props.project.id + '/' + props.integration + '/send_stip_url/',
      data: {
        primary_email: primaryEmail,
        secondary_email: secondaryEmail,
      },
    })
      .then((response: any) => {
        if (response.data.result === true) {
          logAmplitudeEvent('send_stip_link_sent', { integration: 'loanpal', page: location })
          setErrorMsg(undefined)
          setShowDialog(false)
          notify('Upload link sent', 'success')
        }
      })
      .catch((err: any) => {
        console.log('error sending stip upload url', err)
        logAmplitudeEvent('send_stip_link_error', { integration: 'loanpal', page: location })
        setErrorMsg('Unable to send email')
      })
      .finally(() => setLoadingSend(false))
  }

  return (
    <div style={{ width: '100%' }}>
      <ProUxButton
        startIcon={
          props.integration === 'mosaic' ? (
            <img src={`${window.PUBLIC_URL}/images/Mosaic-Logo-Small.png`} alt="" height={21} />
          ) : (
            <img src={`${window.PUBLIC_URL}/images/loanpal_logo.svg`} alt="" height={15} />
          )
        }
        label="Send Stip Upload Link"
        onClick={() => setShowDialog(true)}
        type="secondary"
        backgroundColor="#fff"
        border="1px solid #ececec"
        fullWidth
        style={{ width: '100%' }}
        justifyContent="flex-start"
        labelStyle={{
          display: 'flex',
          flexDirection: isMd || isXxs ? 'column' : 'row',
          alignItems: isMd || isXxs ? 'flex-start' : 'center',
        }}
      />
      {showDialog && (
        <Dialog open={true} onClose={() => setShowDialog(false)}>
          <DialogTitle>Send a Secure Upload Link</DialogTitle>
          <DialogContent>
            <p>The following stipulations have not yet been submitted for review for this project:</p>
            <ul>
              {stipActions.map((stipAction: CustomerActionType) => (
                <li key={stipAction.stipulation_name}>{stipAction.stipulation_name}</li>
              ))}
            </ul>
            {decisionsLoaded && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <p>
                    Clicking "Email Link" will send an upload link to the email
                    {secondaryEmail ? ' addresses ' : ' address '} below. Your customer will be able to see what
                    documents are required and will be able to securely upload them directly to{' '}
                    {props.integration === 'mosaic' ? 'Mosaic' : LOANPAL_OR_GOODLEAP}. If they open the link on a mobile
                    phone they can take a picture of the document and upload that.
                  </p>
                </Grid>
                <Grid item xs={12} sm={secondaryEmail ? 6 : 12}>
                  <TextField
                    id="primary-email"
                    label="Primary Applicant Email"
                    onChange={(e) => setPrimaryEmail(e.target.value)}
                    value={primaryEmail}
                    fullWidth
                  />
                </Grid>
                {secondaryEmail && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="secondary-email"
                      label="Secondary Applicant Email"
                      onChange={(e) => setSecondaryEmail(e.target.value)}
                      value={secondaryEmail}
                      fullWidth
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
              {errorMsg && <span style={{ color: 'red' }}>{errorMsg}</span>}
              <div style={{ margin: '0px 15px' }}>
                <ProUxButton
                  type="secondary"
                  onClick={() => setShowDialog(false)}
                  label="Cancel"
                  disabled={loadingSend}
                />
              </div>
              <div style={{ margin: '0px 15px' }}>
                <ProUxButton type="primary" onClick={sendEmail} label="Email Link" loading={loadingSend} />
              </div>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </div>
  )
}

export default StipCTAForPros

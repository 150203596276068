import { makeStyles, Typography } from '@material-ui/core'
import InfoTooltip from 'elements/tooltip/InfoTooltip'
import React, { useCallback } from 'react'
import { useTranslate } from 'react-admin'
import restClient from 'restClient'
import { OSComponentType } from 'types/components'

const useStyles = makeStyles((theme) => ({
  specsOverview: {
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    maxWidth: '100vw',
    width: '450px',
  },
  overviewButton: {
    minWidth: 'fit-content',
    '& svg': {
      height: '22px',
    },
  },
  overviewHeaders: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginBottom: '10px',
  },
  overviewTitleSection: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    margin: '10px 0',
  },
  componentImage: {
    maxWidth: '100px',
    maxHeight: '100px',
    marginRight: '10px',
  },
  specsTable: {
    display: 'grid',
    gridTemplateColumns: '2fr 3fr',
    padding: '10px',
    overflow: 'hidden',
    '& > *': {
      borderBottom: 'solid 1px lightgrey',
      paddingBottom: '2px',
      marginBottom: '4px',
    },
    '& > *:nth-child(odd)': {
      textAlign: 'right',
      paddingRight: '10px',
      whiteSpace: 'noWrap',
    },
  },
  overviewHeader: {
    paddingLeft: '4px',
  },
}))

//TODO: Split into separate defs for component types
//TODO: Unify with ModuleType in /types/studio/items.ts

type ComponentPropsType = {
  component: OSComponentType
}

function truncate(str, n) {
  if (str.length <= n) {
    return str
  } else {
    const subString = str.slice(0, n - 1)
    return subString.slice(0, subString.lastIndexOf(' ')) + '...'
  }
}

const kwToWatts = (kw) => {
  try {
    return Math.round(1000 * kw)
  } catch (e) {
    return 0
  }
}

const specToString = {
  manufacturer_name: {
    label: 'Manufacturer',
  },
  technology: {
    label: 'Technology',
  },
  skus: {
    label: 'SKU',
  },
  kw_stc: {
    label: 'Rated output power',
    valueToString: (value) => {
      return kwToWatts(value) + ' W'
    },
  },
  kwh_optimal: {
    label: 'Total energy',
    valueToString: (value) => {
      return value + ' kWh'
    },
  },
  max_power_rating: {
    label: 'Rated output power',
    valueToString: (value) => {
      return value + ' kW'
    },
  },
  width: {
    label: 'Width',
    valueToString: (value) => {
      return value + ' m'
    },
  },
  height: {
    label: 'Height',
    valueToString: (value) => {
      return value + ' m'
    },
  },
  thickness: {
    label: 'Thickness',
    valueToString: (value) => {
      return value + ' mm'
    },
  },
  weight: {
    label: 'Weight',
    valueToString: (value) => {
      return value + ' kg'
    },
  },
  power_max_continuous: {
    label: 'Max continuous power',
    valueToString: (value) => {
      return value + ' kW'
    },
  },
  max_power_voltage: {
    label: (
      <>
        V<sub>mp</sub>
      </>
    ),
    valueToString: (value) => {
      return value + ' V'
    },
  },
  voc: {
    label: (
      <>
        V<sub>oc</sub>
      </>
    ),
    valueToString: (value) => {
      return value + ' V'
    },
  },
  imp: {
    label: (
      <>
        I<sub>mp</sub>
      </>
    ),
    valueToString: (value) => {
      return value + ' A'
    },
  },
  isc: {
    label: (
      <>
        I<sub>sc</sub>
      </>
    ),
    valueToString: (value) => {
      return value + ' A'
    },
  },
  voltage_nominal: {
    label: 'Rated output voltage',
    valueToString: (value) => {
      return value + ' V'
    },
  },
  voltage_to_current_rating: {
    label: 'Voltage to current rating',
    valueToString: (value) => {
      const voltage_to_current_rating = value
      const voltages = Object.keys(voltage_to_current_rating)
      return (
        <>
          {voltages.map((voltage, index) => {
            const current = voltage_to_current_rating[voltage]
            return `${voltage}V: ${current}A` + (index !== voltages.length - 1 ? ', ' : '')
          })}
        </>
      )
    },
  },
  mppt_quantity: {
    label: 'Number of MPPTs',
  },
  mppt_voltage_min: {
    label: (
      <>
        Minimum V<sub>mpp</sub>
      </>
    ),
    valueToString: (value) => {
      return value + ' V'
    },
  },
  mppt_voltage_max: {
    label: (
      <>
        Maximum V<sub>mpp</sub>
      </>
    ),
    valueToString: (value) => {
      return value + ' V'
    },
  },
  efficiency: {
    label: 'Efficiency',
    valueToString: (value) => {
      return value + '%'
    },
  },
  efficiency_factor: {
    label: 'Efficiency',
    valueToString: (value) => {
      return Number(value) * 100 + '%'
    },
  },
  current_type: {
    label: 'Current type',
    valueToString: (value) => {
      return value.toUpperCase()
    },
  },
  current_rating: {
    label: 'Current rating',
    valueToString: (value) => {
      return Number(value) + ' A'
    },
  },
  voltage_rating: {
    label: 'Voltage rating',
    valueToString: (value) => {
      return Number(value) + ' V'
    },
  },
  current_ac_max: {
    label: 'Max AC output current',
    valueToString: (value) => {
      return Number(value) + ' A'
    },
  },
  cable_thickness: {
    label: 'Thickness',
    valueToString: (value) => {
      return Number(value) + ' mm'
    },
  },
  cable_length: {
    label: 'Total length',
    valueToString: (value) => {
      return Number(value) + ' m'
    },
  },
  phase_type: {
    label: 'Phase type',
  },
  dc_optimizer_efficiency: {
    label: 'Optimizer efficiency',
    valueToString: (value) => {
      return Number(value) + '%'
    },
  },
  dc_optimizer_max_input_power: {
    label: 'Max input power',
    valueToString: (value) => {
      return Number(value) + ' W'
    },
  },
  dc_optimizer_max_input_voltage: {
    label: 'Max input voltage',
    valueToString: (value) => {
      return Number(value) + ' V'
    },
  },
  dc_optimizer_max_input_current: {
    label: 'Max input current',
    valueToString: (value) => {
      return Number(value) + ' A'
    },
  },
  dc_optimizer_max_output_voltage: {
    label: 'Max output voltage',
    valueToString: (value) => {
      return Number(value) + ' V'
    },
  },
  dc_optimizer_max_output_current: {
    label: 'Max output current',
    valueToString: (value) => {
      return Number(value) + ' A'
    },
  },
  description: {
    label: 'Description',
    valueToString: (value) => {
      return truncate(value, 200)
    },
  },
  colour: {
    label: 'Colour',
  },
}

interface ExhibitedContent {
  promotional_image?: string
}

const ComponentOverview: React.FC<ComponentPropsType & { promotionalImage?: string }> = ({
  component,
  promotionalImage,
}) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <div className={classes.specsOverview}>
      <div className={classes.overviewTitleSection}>
        <div className={classes.overviewHeaders}>
          <Typography variant="h5">{component.code}</Typography>
          <Typography className={classes.overviewHeader} variant="h6">
            {translate('Datasheet')}
          </Typography>
        </div>
        {promotionalImage && <img className={classes.componentImage} src={promotionalImage}></img>}
      </div>
      <div className={classes.specsTable}>
        {Object.keys(specToString).map((key) => {
          return !component[key] ? null : (
            <>
              <Typography variant="caption">{translate(specToString[key].label)} : </Typography>
              <Typography variant="caption">
                {specToString[key].valueToString ? specToString[key].valueToString(component[key]) : component[key]}
              </Typography>
            </>
          )
        })}
      </div>
    </div>
  )
}

const ComponentOverviewIcon: React.FC<ComponentPropsType & { customIcon?: React.ReactNode }> = ({
  component,
  customIcon,
}) => {
  const [exhibitedContent, setExhibitedContent] = React.useState<ExhibitedContent>()
  const iconRef = React.useRef<HTMLDivElement | null>(null)
  const [isNotEnoughSpace, setIsNotEnoughSpace] = React.useState(false)

  const handleOpen = useCallback(() => {
    // only try to fetch content once if component manufacturer is exhibitor
    if (exhibitedContent != null || !component.logo) {
      return
    }

    const API_URL = window.API_ROOT + '/api'
    const restClientInstance = restClient(API_URL)
    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/1/component_content/?search=` + component.code,
    })
      .then((res) => {
        setExhibitedContent(res?.data[0])
      })
      .catch((err) => {
        console.warn(err)
      })
  }, [component, exhibitedContent])
  const handleClose = () => {
    //  console.log('close')
  }

  React.useEffect(() => {
    const handleResize = () => {
      if (iconRef.current) {
        const { bottom } = iconRef.current.getBoundingClientRect()
        const windowHeight = window.innerHeight
        const distanceFromBottom = windowHeight - bottom
        const distance = +distanceFromBottom <= 550.0
        setIsNotEnoughSpace(distance)
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [iconRef])

  return (
    <div ref={iconRef} data-testid="component--overview">
      <InfoTooltip
        customIcon={customIcon}
        fullWidth={true}
        onOpen={handleOpen}
        onClose={handleClose}
        placement={!isNotEnoughSpace ? 'top' : 'left-end'}
        title={<ComponentOverview component={component} promotionalImage={exhibitedContent?.promotional_image} />}
      />
    </div>
  )
}

export default ComponentOverviewIcon

import { IconButtonProps } from '@material-ui/core'
import DragIndicatorIcon from '@material-ui/icons/DragIndicatorOutlined'
import Tooltip from 'elements/tooltip/Tooltip'
import { IconButton } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'
import { useComponentActionStyles } from './styles'

export const ReorderComponentButton = ({ buttonProps }: { buttonProps: IconButtonProps }) => {
  const classes = useComponentActionStyles()
  const translate = useTranslate()
  return (
    <Tooltip title={translate('Drag to reorder')}>
      <IconButton className={classes.button} {...buttonProps}>
        {<DragIndicatorIcon className={classes.icon} />}
      </IconButton>
    </Tooltip>
  )
}

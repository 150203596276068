import { useEffect, useRef } from 'react';
export function useDebouncedCallback(callback, wait) {
    // track args & timeout handle between calls
    var argsRef = useRef();
    var timeout = useRef();
    function cleanup() {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
    }
    // make sure our timeout gets cleared if
    // our consuming component gets unmounted
    useEffect(function () { return cleanup; }, []);
    return function debouncedCallback() {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        // capture latest args
        argsRef.current = args;
        // clear debounce timer
        cleanup();
        // start waiting again
        timeout.current = setTimeout(function () {
            if (argsRef.current) {
                callback.apply(void 0, argsRef.current);
            }
        }, wait);
    };
}

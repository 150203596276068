import { TablePagination, Theme, Toolbar, useMediaQuery } from '@material-ui/core'
import type { TablePaginationActionsProps } from '@material-ui/core/TablePagination/TablePaginationActions'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import type { Argument as ClassName } from 'classnames'
import classnames from 'classnames'
import React, { useCallback } from 'react'
import { useTranslate } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const defaultPerPageOptions = [5, 10, 20, 25]

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

type PaginationPropsType = {
  source?: string // Tracking source
  page: number
  perPage: number
  total: number
  setPage: (page: number) => void
  setPerPage: (perPage: number) => void
  loading?: boolean
  rowsPerPageOptions?: number[]
  actionComponentStart?: React.ReactNode
  actionComponentEnd?: React.ElementType<TablePaginationActionsProps>
  className?: ClassName
}

const Pagination = ({
  source,
  page,
  perPage = 10,
  rowsPerPageOptions = defaultPerPageOptions,
  total,
  setPage,
  setPerPage,
  loading,
  actionComponentStart,
  actionComponentEnd,
  className,
}: PaginationPropsType) => {
  const translate = useTranslate()
  const classes = useStyles()
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const getNbPages = () => Math.ceil(total / perPage) || 1

  /**
   * Warning: material-ui's page is 0-based
   */
  const handlePageChange = useCallback(
    (event, page) => {
      event && event.stopPropagation()
      logAmplitudeEvent('pagination_interacted', {
        action: 'clicked',
        context: 'page',
        source,
      })
      if (page < 0 || page > getNbPages() - 1) {
        throw new Error(
          translate('ra.navigation.page_out_of_boundaries', {
            page: page + 1,
          })
        )
      }
      setPage(page + 1)
    },
    [total, perPage, setPage, translate]
  )
  const handlePerPageChange = useCallback(
    (event) => {
      setPerPage(event.target.value)
      logAmplitudeEvent('pagination_interacted', {
        action: 'clicked',
        context: 'per_page',
        source,
      })
    },
    [setPerPage]
  )

  const labelDisplayedRows = useCallback(
    ({ from, to, count }) =>
      translate('ra.navigation.page_range_info', {
        offsetBegin: from,
        offsetEnd: to,
        total: count,
      }),
    [translate]
  )

  if (total === 0) {
    return loading ? <Toolbar variant="dense" /> : null
  }

  return (
    <div className={classnames(classes.wrapper, className)}>
      <div>{React.isValidElement(actionComponentStart) && actionComponentStart}</div>
      <TablePagination
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerPageChange}
        ActionsComponent={actionComponentEnd}
        component="div"
        labelRowsPerPage={!isSmall && translate('ra.navigation.page_rows_per_page')}
        labelDisplayedRows={labelDisplayedRows}
        rowsPerPageOptions={isSmall ? [] : rowsPerPageOptions}
      />
    </div>
  )
}

export default React.memo(Pagination)

import { CustomFormAnyData } from 'customForm/types'
import type { FormApi } from 'final-form'
import lodash from 'lodash'
import { useCallback } from 'react'
import { difference } from '../util/difference'

interface Props<T extends CustomFormAnyData = CustomFormAnyData> {
  currentData: T
  newData: T
}

const useOnSaveCustomForm = ({ parentForm }: { parentForm: FormApi }) =>
  useCallback(({ currentData, newData }: Props) => {
    let hasAnyChanges = false

    if (currentData.type === 'project' || currentData.type === 'system_project') {
      if (newData.type !== currentData.type) return false // Something went wrong

      // Check for changes within project namespace
      if (updateStandardFields(parentForm, newData.project, currentData.project)) {
        hasAnyChanges = true
      }

      // Check for changes within project_data namespace
      if (updateJsonField(parentForm, newData.project_data, currentData.project_data, 'custom_data')) {
        hasAnyChanges = true
      }
    }

    if (currentData.type === 'role') {
      if (newData.type !== currentData.type) return false // Something went wrong

      // Check for changes within role namespace
      if (updateStandardFields(parentForm, newData.role, currentData.role)) {
        hasAnyChanges = true
      }

      // Check for changes within role_data namespace
      if (updateJsonField(parentForm, newData.role_data, currentData.role_data, 'user_data')) {
        hasAnyChanges = true
      }
    }

    if (currentData.type === 'contact') {
      if (newData.type !== currentData.type) {
        return false // Something went wrong
      }

      // Check for changes within contact namespace
      if (updateStandardFields(parentForm, newData.contact, currentData.contact)) {
        hasAnyChanges = true
      }

      // Check for changes within contact_data namespace
      if (updateJsonField(parentForm, newData.contact_data, currentData.contact_data, 'custom_data')) {
        hasAnyChanges = true
      }
    }

    if (currentData.type === 'payment_option') {
      if (newData.type !== currentData.type) {
        return false // Something went wrong
      }

      // Check for changes within payment_option namespace
      if (updateStandardFields(parentForm, newData.payment_option, currentData.payment_option)) {
        hasAnyChanges = true
      }

      // Check for changes within payment_option_data namespace
      if (updateJsonField(parentForm, newData.payment_option_data, currentData.payment_option_data, 'custom_data')) {
        hasAnyChanges = true
      }
    }

    return hasAnyChanges
  }, [])

export default useOnSaveCustomForm

const updateStandardFields = (parentForm: FormApi, newData: any, currentData: any) => {
  let hasAnyChanges = false
  const diff = difference(newData, currentData)
  lodash.forEach(diff, (value, key) => {
    if (window.debugCustomForms) console.debug(`Custom Forms: Setting ${key} to `, value)
    parentForm.mutators.updateField(key, value)
    if (parentForm.mutators.markFieldAsDirty) parentForm.mutators.markFieldAsDirty(key)
    hasAnyChanges = true
  })
  return hasAnyChanges
}

const updateJsonField = (parentForm: FormApi, newData: any, currentData: any, fieldName: string) => {
  let hasAnyChanges = false
  const diff = difference(newData, currentData)
  let hasUpdates = false
  lodash.forEach(diff, (value, key) => {
    lodash.set(currentData, key, value)
    hasUpdates = true
    hasAnyChanges = true
  })
  if (hasUpdates) {
    if (window.debugCustomForms) console.debug(`Custom Forms: Setting ${fieldName} to `, currentData)
    parentForm.change(fieldName, currentData)
    parentForm.mutators.updateField(fieldName, currentData)
    if (parentForm.mutators.markFieldAsDirty) parentForm.mutators.markFieldAsDirty(fieldName)
  }
  return hasAnyChanges
}

import BannerAd from 'elements/BannerAd'
import { getProjectSectionStyles } from '../../../styles'

export const LeaderboardAd = () => {
  const sectionStyles = getProjectSectionStyles()
  return (
    <BannerAd
      formats={['leaderboard']}
      placement="project_manage"
      requiredWindowSize={{ width: 960 }}
      rounded={true}
      className={sectionStyles.moduleFullWidth}
    />
  )
}

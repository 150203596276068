import { promoConfigType } from 'persistentContent/inAppPromo/types'
import { createContext, useMemo, useState } from 'react'
import { usePollPromos } from './hooks/usePollPromos'

export type PromosContextType = {
  state: PromosLoadState
  promos: promoConfigType[]
}

export type PromosLoadState = 'not-loaded' | 'loaded' | 'reloading'

export const PromosContextCore = createContext<PromosContextType>({ state: 'not-loaded', promos: [] })

export const PromosContext = ({ children }) => {
  const [state, setState] = useState<PromosLoadState>('not-loaded')

  const promos = usePollPromos(setState)

  const value = useMemo(() => ({ state, promos }), [state, promos])
  return <PromosContextCore.Provider value={value}>{children}</PromosContextCore.Provider>
}

import { changeTheme } from 'ducks/theme'
import getSsoStartLink from 'pages/auth/sso/getSsoStartLink'
import { AuthConfigType, SsoAuthConfigType } from 'pages/auth/types'
import { useTranslate } from 'ra-core'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { storeAuthRedirectionToLocalStorage } from 'redirections/authRedirectionStore'
import appStorage from 'storage/appStorage'
import AuthFlowLogo from '../logos/AuthFlowLogo'
import { SsoLoginContent } from './SsoLoginContent'
import BasicLink from './actions/BasicLink'

type Props = {
  config: SsoAuthConfigType
  allLoginConfigs: AuthConfigType[]
  onRegister: () => void
  onSwitchToLogin: (config: AuthConfigType) => void
}

export const SsoLoginPage = ({ config, allLoginConfigs, onRegister, onSwitchToLogin }: Props) => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const history = useHistory()

  const onSsoLogin = (sso: SsoAuthConfigType) => {
    const ssoLink = getSsoStartLink({ partnerId: sso.partner_id })

    storeAuthRedirectionToLocalStorage()
    if (ssoLink) window.location.href = ssoLink
  }

  const userPassConfig = useMemo(
    () => allLoginConfigs.find((a) => a.type === 'user-pass') as SsoAuthConfigType | undefined,
    [allLoginConfigs]
  )

  const onSwitchToLoginHandler = (to) => {
    onSwitchToLogin(to)

    // The following logic are special for nearmap sso and we should refactor and remove them
    if (config.partner_id === 'nearmap') {
      appStorage.clear('nearmap_token')
      dispatch(changeTheme('opensolar'))
    }
  }

  return (
    <SsoLoginContent
      brandingName={config.displayName}
      onLogin={() => onSsoLogin(config)}
      Logo={<AuthFlowLogo alt="branding logo" src={window.PUBLIC_URL + '/images/' + config.brandingLogo} />}
      Actions={
        <>
          {(config.userPassRedirectUri || userPassConfig) && (
            <p>
              <BasicLink
                id="switch-to-user-pass"
                handleClick={() => {
                  if (userPassConfig) {
                    onSwitchToLoginHandler(userPassConfig)
                    return false
                  } else if (config.userPassRedirectUri) {
                    window.location.href = config.userPassRedirectUri
                  }
                }}
                label={translate('Login to OpenSolar without %{partner_name}', {
                  partner_name: config.displayName,
                })}
                margin="dense"
              />
            </p>
          )}

          {userPassConfig && (
            <p>
              <BasicLink
                id="switch-to-register"
                handleClick={() => {
                  onRegister()
                  return false
                }}
                label={translate('Create a free OpenSolar account')}
                margin="dense"
              />
            </p>
          )}
        </>
      }
    />
  )
}

import { makeStyles, useMediaQuery } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { setStudioSavePrompt } from 'ducks/studioPrompt'
import { BaseThemeType } from 'opensolar-ui'
import { useShowProjectActions } from 'projectSections/hooks/useShowProjectActions'
import { useTranslate } from 'ra-core'
import { useEffect, useMemo } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import TagsDisplay from 'resources/projects/projects3/elements/TagsDisplay'
import { Theme } from 'types/themes'
import ChipArrayInput from '../../common/ChipsArrayInput'
import HoverEditWrapper from '../../common/HoverEditWrapper'
import { COLOR_PALETTE, getProjectSectionStyles } from '../../styles'
const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    minHeight: 30,
    display: 'flex',
    marginTop: 8,
    alignItems: 'center',
    color: COLOR_PALETTE.darkGrey,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  addBtn: {
    margin: '0 !important',
    marginTop: '8px !important',
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
}))
const ProjectTags = () => {
  const classes = useStyles()
  const translate = useTranslate()
  const project = useFormState().values
  const tags_data = useMemo(() => project.tags_data, [project.tags_data])
  const isMobile = useMediaQuery((theme: BaseThemeType) => theme.breakpoints.down('xs'))
  const projectTagsClasses = getProjectSectionStyles()
  const isRunningAsApp = window.RUNNING_AS_APP
  const dispatch = useDispatch()
  const isFormUnsaved = useShowProjectActions()

  const handleClick = (event, label) => {
    if (isFormUnsaved && isRunningAsApp) {
      event.preventDefault()
      dispatch(
        setStudioSavePrompt({
          show_prompt: true,
          redirect: '/tags',
          prompt_heading: translate('Leave Project to %{label}?', { label: translate(label) }),
        })
      )
    }
  }
  return (
    <HoverEditWrapper
      modalProps={{
        headerText: translate('Tags'),
        content: <TagsFormContent />,
        toolbarBtn: () => (
          <Link
            style={{ marginRight: 'auto' }}
            onClick={(event) => handleClick(event, 'Add Tags')}
            to={'/tags'}
            target={isRunningAsApp ? '_self' : '_blank'}
            rel="noopener noreferrer"
          >
            {translate('Add New Tags')}
          </Link>
        ),
      }}
      className={
        isMobile
          ? projectTagsClasses.mobileViewDiv
          : `${projectTagsClasses.detail} ${projectTagsClasses.tagsCustomWidth}`
      }
      titleElement={<p>{translate('Tags')}</p>}
      emptyToggle={
        !!!project?.tags?.length
          ? {
              label: <>{translate('Add Tags')}</>,
              startIcon: <Add />,
              className: classes.addBtn,
            }
          : undefined
      }
    >
      <div className={classes.contentWrapper}>{!!tags_data?.length && <TagsDisplay tags={tags_data} />}</div>
    </HoverEditWrapper>
  )
}

const TagsFormContent = (props) => {
  const form = useForm()
  const classes = useStyles()
  const translate = useTranslate()
  const formValues = useFormState().values

  useEffect(() => {
    form.registerField('tags_data', () => {}, {})
  }, [])

  return (
    <ChipArrayInput
      source={'tags'}
      resource={'tags'}
      reference={'tags'}
      chipsInputProps={{
        source: 'tags',
        options: {
          placeholder: !!formValues.tags?.length ? undefined : 'Input a tag',
          label: translate('Tags'),
          variant: 'outlined',
        },
      }}
      onChange={(values) => {
        form.change('tags_data', values)
      }}
    />
  )
}
export default ProjectTags

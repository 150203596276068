import React from 'react'
import AddressAutocomplete from 'resources/projects/elements/AddressAutocomplete.js'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'

type PropTypes = {
  setJustSelectedAddress: () => void
}

const AddressAutocompleteWrapper: React.FunctionComponent<PropTypes> = (props) => {
  const form = useForm()
  const formState = form.getState()

  const apiKeyGoogle = useSelector((state: any) =>
    state.auth && state.auth.api_key_google ? state.auth.api_key_google : ''
  )
  const handlePlaceSelected = function (placeAsFields: any) {
    // var placeAsFields = parseGooglePlace(place)
    form.batch(() => {
      for (var fieldName in placeAsFields) {
        //   @ts-ignore
        form.change(fieldName, placeAsFields[fieldName]) // listeners not notified
        form.change('search_address', false)
        props.setJustSelectedAddress()
      }
    }) // NOW all listeners notified
  }
  const getDefaultValue = () => {
    if (!formState || !formState.values || !formState.values.address) return ''
    else {
      let defaultValue = formState.values.address
      if (formState.values.locality) defaultValue += `, ${formState.values.locality}`
      if (formState.values.state) defaultValue += `, ${formState.values.state}`
      if (formState.values.zip) defaultValue += ` ${formState.values.zip}`
      return defaultValue
    }
  }
  return (
    <AddressAutocomplete
      onPlaceSelected={handlePlaceSelected}
      api_key_google={apiKeyGoogle}
      types={['address']}
      defaultValue={getDefaultValue()}
      componentRestrictions={null}
      isProUx
      isProjectPage
    />
  )
}

export default AddressAutocompleteWrapper

// @ts-nocheck
import Grid from '@material-ui/core/Grid'
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined'
import { permissionsSelectors } from 'ducks/permissions'
import CustomField from 'elements/field/CustomField'
import ChipsInput from 'elements/input/ChipsInput'
import React from 'react'
import { ReferenceArrayInput, TextInput, useTranslate } from 'react-admin'
import { Field } from 'react-final-form'
import { useSelector } from 'react-redux'
import AccordionCard from '../AccordionCard'

const SalesContent: React.FunctionComponent = () => {
  const translate = useTranslate()
  return (
    <Grid container spacing={3}>
      <Grid item xs={6} sm={3}>
        <CustomField
          resource="projects"
          disabled={true}
          component={TextInput}
          name={'id'}
          source={'id'}
          label="ID"
          fullWidth
          style={{ width: '100%' }}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <Field
          resource="projects"
          component={TextInput}
          name={'identifier'}
          source={'identifier'}
          label={'Identifier'}
          fullWidth={true}
          style={{ width: '100%' }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomField
          //@TODO: Refactor into standard field translation and remove label attribute
          label="Lead Source"
          component={TextInput}
          name={'lead_source'}
          source={'lead_source'}
          fullWidth
          style={{ width: '100%' }}
        />
      </Grid>
      <Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'flex-end' }}>
        <CustomField
          name="tags"
          source="tags"
          component={ReferenceArrayInput}
          label={translate('Loading Tags...')}
          reference="tags"
          resource="tags"
          optionValueField="url"
          fullWidth
          style={{ width: '100%' }}
        >
          <ChipsInput
            optionText={'title'}
            optionValue="url"
            options={{ placeholder: 'Enter a tag' }}
            translateChoice={false}
            fullWidth
            style={{ width: '100%' }}
            useSmallChip={true}
            label="Tags"
          />
        </CustomField>
      </Grid>
      <Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'flex-end' }}>
        <CustomField
          component={TextInput}
          multiline={true}
          name="notes"
          source="notes"
          label="Notes"
          fullWidth={true}
          style={{ width: '100%', marginBottom: '0px', marginRight: '0px' }}
        />
      </Grid>
    </Grid>
  )
}

const SalesAccordion: React.FunctionComponent = () => {
  const translate = useTranslate()
  const { allowView, allowCreate, allowEdit, allowDelete } = useSelector(
    permissionsSelectors.getProjectPermissionByKey('info_sales_and_marketing')
  )
  if (!allowView) {
    return null
  }
  return (
    <AccordionCard
      name={'sales'}
      disabled={!allowEdit}
      title={translate('Sales & Marketing')}
      titleIcon={BookmarkBorderOutlinedIcon}
      content={SalesContent}
      lazyRender={true}
    />
  )
}
export default SalesAccordion

import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreOutlined'
import { push as pushAction } from 'connected-react-router'
import Alert from 'elements/Alert'
import CustomField from 'elements/field/CustomField'
import { useBulkActionButtons } from 'elements/hooks/useBulkActionButtons'
import { List } from 'elements/react-admin/List'
import ListActions from 'elements/react-admin/ListActions'
import TextFieldWithSharedEntityIcon from 'elements/TextFieldWithSharedEntityIcon'
import _ from 'lodash'
import { Switch } from "opensolar-ui"
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  FileField,
  FileInput,
  FormDataConsumer,
  ImageField,
  ImageInput,
  RadioButtonGroupInput,
  SelectArrayInput,
  SelectInput,
  showNotification as showNotificationAction,
  SimpleForm,
  TextInput,
  useTranslate
} from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import Skeleton from 'react-loading-skeleton'
import { connect, useSelector } from 'react-redux'
import { duplicate as duplicateAction } from '../../actions/restActions'
import CustomCheckboxGroupInput from '../../elements/input/CustomCheckboxGroupInput'
import DependentInput from '../../elements/input/DependentInput'
import OverviewInputs from '../../elements/input/OverviewInputs'
import EditorComponent from '../../elements/wysiwyg/EditorComponentWrapper'
import { getRoleFromState, renderDraftEditor, renderRawHtmlEditor, toCapitalise } from '../../util/misc'
import { getOrgCountryCodeFromState, getOrgFromState } from '../../util/org'
import FeatureFiguresSelector from './FeatureFiguresSelector'
// import{FormControl,InputLabel,Select} from '@material-ui/core'
import { Box } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { makeStyles } from '@material-ui/core/styles'
import { authSelectors } from 'ducks/auth'
import { StandardInputs } from 'elements/input/StandardInputs'
import { useEditShareable } from 'elements/react-admin/EditShareable'
import { Button } from 'opensolar-ui'
import ShareabilityFilters from 'resources/connectedOrgs/ShareabilityFilters'
import ShareabilitySelector from 'resources/connectedOrgs/ShareabilitySelector'
import EditOrImportButton from '../../elements/button/EditOrImportButton'
import CreateEditToolbar from '../../elements/CreateEditToolbar'
import FileFieldClearable from '../../elements/field/FileFieldClearable'
import ImageFieldClearable from '../../elements/field/ImageFieldClearable'
import BespokeFormContent, { fieldsBespoke } from './BespokeFromContent'
import CreateButton from './ProposalTemplateCreateButton'
import PVProSellFormContent from './PVProSellFormContent'
import SectionConfiguration from './SectionConfiguration'
import { ProposalTemplateType } from './types'

const DEFAULT_PROPOSAL_TEMPLATE_SETTINGS = {
  hide_export_credit_breakdown: false,
  lock_y_axis_across_all_options: true,
  checkout_hidden: false,
}

const useStyles = makeStyles(
  {
    hidePreviews: {
      '& .previews': { display: 'none' },
    },
  },
  { name: 'FileInput' }
)

// export const ProposalTemplateFilter = (props) => (
//   <Filter {...props}>
//     <TextInput label="pos.search" source="q" style={standardStyles.FILTER_FIELD_STYLE} />
//     <QuickFilter source="show_exhibitor_content" label="Show Exhibitor Content" defaultValue={1} />
//     <QuickFilter source="show_only_archived" defaultValue={1} />
//   </Filter>
// )

const ProposalTemplateActions = (props) => {
  const { filterValues, setFilters } = props
  const translate = useTranslate()
  const [checked, setChecked] = useState(filterValues?.show_exhibitor_content === 1 ? true : false)

  const toggleChecked = () => {
    setChecked(!checked)
    setFilters({ ...filterValues, show_exhibitor_content: checked ? 0 : 1 })
  }
  return (
    <>
      <FormControlLabel
        style={{ margin: '8px' }}
        control={<Switch checked={checked} onChange={toggleChecked} color="primary" />}
        label={translate('View Partner Templates')}
        labelPlacement="start"
      />
      <ListActions {...props} hasArchived={true} createButtonLabel="Add Proposal Template" hasCreate={false} />
      {props.hasCreate && <CreateButton />}
    </>
  )
}

export const ProposalTemplateList = connect(
  (state) => {
    return {
      org_id: state.auth ? state.auth.org_id : null,
      api_key_pvsell: getOrgFromState(state)?.api_key_pvsell,
      country_iso2: getOrgCountryCodeFromState(state),
    }
  },
  { duplicate: duplicateAction }
)(({ accessRights: { allowView, allowCreate, allowEdit, allowDelete }, ...props }) => {
  const isAdmin = useSelector((state) => Boolean(getRoleFromState(state)?.is_admin))
  const bulkActionButtons = useBulkActionButtons(props, allowEdit, allowDelete, allowEdit)

  return (
    <List
      hasSearch={true}
      alwayShowActions={true}
      extraCreateButton={<CreateButton />}
      actions={<ProposalTemplateActions api_key_pvsell={props.api_key_pvsell} country_iso2={props.country_iso2} />}
      perPage={20}
      {...props}
      hasCreate={allowCreate}
      bulkActionButtons={bulkActionButtons}
      filters={<ShareabilityFilters />}
    >
      <Datagrid duplicate={props.duplicate} isAdmin={isAdmin}>
        <TextFieldWithSharedEntityIcon source="title" label="Title" />
        <BooleanField source="is_default" textAlign={'center'} displayInCard={true} sortable={false} />
        <EditOrImportButton
          sortable={false}
          source="actions"
          label="Actions"
          org_id={props.org_id}
          resource="proposal_templates"
          duplicate={props.duplicate}
          isAdmin={isAdmin}
          allowEdit={allowEdit}
          allowDelete={allowDelete}
        />
      </Datagrid>
    </List>
  )
})

const inputStyle = {
  width: 256,
}
const defaultChoices = []
const PVSellTemplateSelectInput = () => {
  const [choices, setChoices] = useState(defaultChoices)
  const [isLoading, setIsLoading] = useState(true)
  const translate = useTranslate()
  const api_key_pvsell = useSelector(authSelectors.getPvSellKey)
  const handleEditTemplate = (templateId) => {
    window.open(
      `${window.SUNWIZ_API_DOMAIN}/standalone/proposal-page/update?id=${templateId}&access-token=${api_key_pvsell}`,
      '_blank'
    )
  }
  useEffect(() => {
    const success = (response) => {
      if (response.data) {
        setChoices(response.data)
        setIsLoading(false)
      }
    }
    const error = (error) => {
      console.log(error)
      setIsLoading(false)
    }
    window.$.ajax({
      type: 'GET',
      url: `${window.SUNWIZ_API_DOMAIN}/standalone/project/list-proposals?access-token=${api_key_pvsell}&per-page=1000`,
      dataType: 'json',
      contentType: 'application/json',
      success,
      error,
    })
  }, [])

  return isLoading ? (
    <Skeleton height="50px" />
  ) : (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <SelectInput source="pvprosell_template_id" optionText="name" optionValue="id" choices={choices} />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.pvprosell_template_id && (
            <Button
              variant="contained"
              style={{ height: 35 }}
              color="primary"
              onClick={() => handleEditTemplate(formData.pvprosell_template_id)}
            >
              <span>{translate('Edit Template')}</span>
            </Button>
          )
        }
      </FormDataConsumer>
    </div>
  )
}

export const ProposalTemplateCreate = (props) => {
  const isAdmin = useSelector((state) => Boolean(getRoleFromState(state)?.is_admin))
  const translate = useTranslate()
  function defaultCreateValue(values) {
    values.type = props.mode
    return values
  }

  return (
    <Create {...props} component={props.noBorder ? Box : undefined} style={props.noBorder ? { padding: 0 } : {}}>
      <SimpleForm
        redirect="edit"
        defaultValue={defaultCreateValue}
        validate={(values) => validateTitle(values, translate)}
        toolbar={props.toolbar}
      >
        <StandardInputs
          archive={isAdmin}
          autoApply={isAdmin && 'is_default'}
          autoApplyLabel="Is Default"
          description={false}
          extraFields={props.mode === ProposalTemplateType.pvprosell ? [<PVSellTemplateSelectInput />] : []}
        />
      </SimpleForm>
    </Create>
  )
}

export const DraftEditorWithContent = (props) => {
  const generateId = (fieldName = '') => {
    const parts = fieldName.split('_').map((str) => toCapitalise(str))
    return `wysiwyg${parts.join('')}`
  }

  return (
    <EditorComponent
      label={props.label}
      fieldName={props.fieldName}
      allowUploadImage={props.allowUploadImage}
      charCounterMax={props.charCounterMax}
      mentionContext={props.mentionContext ? props.mentionContext : 'org_and_project_without_project_specific_terms'}
      {...props}
      id={generateId(props.fieldName)}
    />
  )
}

DraftEditorWithContent.defaultProps = {
  addField: true,
}

const applyDataOrDefaultValue = (configurationValue, path, configurationData, choices) => {
  Object.keys(choices).forEach((key) => {
    _.setWith(
      configurationValue,
      `${path}${key}.value`,
      _.get(configurationData, `${path}${key}.value`, choices[key].defaultValue),
      Object
    )
    choices[key].children &&
      applyDataOrDefaultValue(configurationValue, `${path}${key}.`, configurationData, choices[key].children)
  })
}

const getSettingsData = (settingsData) => {
  if (!settingsData) {
    settingsData = {}
  }

  const newSettingsData = { ...settingsData }
  for (const field in DEFAULT_PROPOSAL_TEMPLATE_SETTINGS) {
    if (settingsData[field] === undefined) {
      newSettingsData[field] = DEFAULT_PROPOSAL_TEMPLATE_SETTINGS[field]
    }
  }
  return newSettingsData
}

const applySettingsDataToForm = function (settingsData, data) {
  if (!data) data = {}
  const formData = getSettingsData(settingsData)
  fields.forEach((field) => {
    switch (field.type) {
      case 'TextInput':
      case 'TextInputDisabled':
      case 'BooleanInput':
      case 'DraftEditorWithContent':
      case 'SelectInput':
        data[field.fieldName] = formData[field.fieldName]

        break

      case 'FeatureFiguresSelector':
      case 'SelectArrayInput':
      case 'SectionConfiguration':
      case 'CustomCheckboxGroupInput':
        if (formData[field.fieldName]) {
          data[field.fieldName] = formData[field.fieldName].split(',')
        } else {
          data[field.fieldName] = []
        }

        break
      case 'ConfigurationCheckboxGroup':
        let configurationValue = {}
        let configurationData = {}
        try {
          if (formData[field.fieldName]) {
            configurationData = JSON.parse(formData[field.fieldName])
          }
        } catch (e) {
          console.warn(e)
        }
        applyDataOrDefaultValue(configurationValue, '', configurationData, field.choices)
        data[field.fieldName] = JSON.stringify(configurationValue)
        break
      case 'RadioButtonGroupInput':
        data[field.fieldName] = formData[field.fieldName]
        break

      default: //ignore
    }
  })

  data.settings = JSON.stringify(data)
  return data
}

const prepareSettingsDataFormSubmit = function (values) {
  let settingsData = {}
  let fieldList = values.type === 'bespoke' ? fieldsBespoke : fields
  let valueAsArray
  let valueAsString
  let valueAsArrayUnsorted

  fieldList.forEach((field) => {
    switch (field.type) {
      case 'TextInput':
      case 'TextInputDisabled':
      case 'BooleanInput':
      case 'DraftEditorWithContent':
      case 'SelectInput':
        settingsData[field.fieldName] = values[field.fieldName]
        break
      case 'SelectArrayInput':
        valueAsArray = values[field.fieldName]
        valueAsString = valueAsArray && valueAsArray.length > 0 ? valueAsArray.join(',') : ''
        settingsData[field.fieldName] = valueAsString
        break
      case 'FeatureFiguresSelector':
        valueAsArray = values[field.fieldName]
        valueAsString = valueAsArray && valueAsArray.length > 0 ? valueAsArray.filter((a) => !!a).join(',') : ''
        settingsData[field.fieldName] = valueAsString
        break
      case 'CustomCheckboxGroupInput':
        valueAsArrayUnsorted = values[field.fieldName] || []
        // let valueAsArray = valueAsArrayUnsorted
        //   ? field.choices.filter(c => valueAsArrayUnsorted.indexOf(c.id) >= 0).map(c => c.id)
        //   : []
        if (Array.isArray(valueAsArrayUnsorted) && valueAsArrayUnsorted.length > 0) {
          valueAsString = valueAsArrayUnsorted.join(',')
          if (valueAsString) settingsData[field.fieldName] = valueAsString
        }

        break
      case 'SectionConfiguration':
        valueAsArrayUnsorted = values[field.fieldName] || []
        // let valueAsArray = valueAsArrayUnsorted
        //   ? field.choices.filter(c => valueAsArrayUnsorted.indexOf(c.id) >= 0).map(c => c.id)
        //   : []
        valueAsArray = valueAsArrayUnsorted
        valueAsString = valueAsArray && valueAsArray.length > 0 ? valueAsArray.join(',') : ''
        if (valueAsString) settingsData[field.fieldName] = valueAsString
        break
      case 'ConfigurationCheckboxGroup':
        settingsData[field.fieldName] = values[field.fieldName]
        break
      case 'RadioButtonGroupInput':
        settingsData[field.fieldName] = values[field.fieldName]
        break

      default: //ignore
    }
  })

  return settingsData
}

export const defaultValueFunc = function (record) {
  let data = {}
  if (record && record.settings && record.settings.length) {
    try {
      let settingsData = JSON.parse(record.settings)
      data = applySettingsDataToForm(settingsData, data)
      data.checkout_hidden = settingsData.checkout_hidden
    } catch (err) {
      console.error(err)
    }
  }

  if (data.qr_code_toggle === null) data.qr_code_toggle = true
  if (data.include_finance_qr_code === null) data.include_finance_qr_code = true
  if (data.qr_code_user_input === null)
    data.qr_code_user_input = '<p>Scan QR code on your phone to access the online proposal.</p>'

  if (!data.proposal_checkout_buttons) {
    // Handle legacy proposal templates that don't have the new fields yet.
    if (data.checkout_hidden) {
      data.proposal_checkout_buttons = 'disabled'
      data.proposal_checkout_message = null
    } else {
      data.proposal_checkout_buttons = 'disable_after_expiry'
      const checkout_message = record.proposal_checkout_message
        ? record.proposal_checkout_message
        : data.proposal_checkout_message
      data.proposal_checkout_message = checkout_message
    }
  }

  data.title = record.title
  data.is_default = record.is_default
  data.proposal_image_file_contents = record.proposal_image_file_contents
  data.co_branding_logo_file_contents = record.co_branding_logo_file_contents
  data.bespoke_template_file_contents = record.bespoke_template_file_contents
  data.bespoke_proposal_config = record.bespoke_proposal_config
  data.bespoke_proposal_config_applied = record.bespoke_proposal_config_applied
  data.override_logo_file_contents = record.override_logo_file_contents

  return data
}

const mapStateToPropsEdit = (state) => {
  return {
    org_id: state.auth ? state.auth.org_id : null,
    isAdmin: getRoleFromState(state) ? getRoleFromState(state).is_admin : false,
    isStaff: state.auth ? state.auth.is_staff : false,
    enableExhibit: getOrgFromState(state) && getOrgFromState(state).enable_exhibit,
    api_key_pvsell: getOrgFromState(state)?.api_key_pvsell,
  }
}

const locked_sections_fields = [
  //cannot disable: 'title',
  //cannot disable: 'valid_for_days',

  'sections',
  'highlight_rows',
  'business_video_link',
  'pdf_systems_to_include',
  'pdf_payment_options_to_include',
  'exhibit_content',
  'show_product_types',
  'featured_product_types',

  'cover_image_format',

  'heading_cover',
  'proposal_image_file_contents',
  'co_branding_logo_file_contents',
  'bespoke_template_file_contents',
  'override_logo_file_contents',
  'letter_page_content',
  'header_column_1',
  'header_column_2',
  'header_column_3',

  'footer_column_1',
  'footer_column_2',
  'footer_column_3',
  'footer_column_4',

  'footer_column_left',

  'about_us_content',
  'next_steps_content',
  'quotation_custom_content',
  'content_block_1',

  'content_block_2',

  'content_block_3',

  'heading_recommended_system_option',
  'heading_system_hardware',
  'heading_system_performance',
  'heading_electricity_bill_savings',
  'heading_how_your_system_works',
  'heading_daily_energy_flows',
  'heading_payment_option',
  'heading_net_financial_impact',
  'heading_environmental_benefits',
  'heading_case_studies',
  'heading_next_steps',

  'disclaimer_system_hardware_components',
  'disclaimer_system_performance_new',
  'disclaimer_environmental_benefits',
  'disclaimer_system_performance',
  'disclaimer_daily_energy_flows',
  'disclaimer_system_price',
  'disclaimer_financial_impact',
  'disclaimer_payment_option_cash',
  'disclaimer_payment_option_loan',
  'disclaimer_payment_option_ppa',
  'disclaimer_payment_option_regular_payment',
  'disclaimer_payment_option_lease',
]

const getLockedSectionsChoices = () => {
  return locked_sections_fields.map((lockedSectionFieldName) => {
    const _field = fields.filter((f) => f.fieldName === lockedSectionFieldName)[0]
    if (_field && _field.fieldLabel) {
      return { label: _field.fieldLabel, defaultValue: false, id: lockedSectionFieldName }
    } else {
      return { label: lockedSectionFieldName, defaultValue: false, id: lockedSectionFieldName }
    }
  })
}

export const isLockedByOriginalOwner = (record, fieldName) => {
  return record.original && record.locked_sections && record.locked_sections.indexOf(fieldName) !== -1
}

const fields = [
  { type: 'h1', title: 'Proposal Settings' },

  { fieldName: 'valid_for_days', type: 'TextInput', fieldLabel: 'Default Proposal Validity (days)' },

  {
    fieldName: 'sections',
    fieldLabel: 'Sections',
    type: 'SectionConfiguration',
    choices: [
      {
        id: 'letter',
        label: 'Cover Message',
        defaultValue: true,
      },
      {
        id: 'about_us',
        label: 'About Us (PDF only)',
        defaultValue: true,
      },
      {
        id: 'systems_table',
        label: 'Systems Comparison Table (PDF only)',
        defaultValue: true,
      },
      {
        id: 'systems_group',
        label: 'Systems Group',
        defaultValue: true,
        children: [
          {
            id: 'system_hardware',
            label: 'System Details',
            defaultValue: true,
          },
          {
            id: 'exhibit_tile_hardware',
            label: 'Hardware Showcase Tiles',
            defaultValue: true,
            children: [
              {
                id: 'exhibit_tile_module',
                label: 'Module Showcase Tile',

                defaultValue: true,
              },
              {
                id: 'exhibit_tile_inverter',
                label: 'Inverter Showcase Tile',
                defaultValue: true,
              },
              {
                id: 'exhibit_tile_battery',
                label: 'Battery Showcase Tile',
                defaultValue: true,
              },
              {
                id: 'exhibit_tile_other',
                label: 'Other Component Showcase Tile',
                defaultValue: true,
              },
            ],
          },
          {
            id: 'business_video_link',
            label: 'Business Video',
            defaultValue: false,
          },
          {
            id: 'energy_supply',
            label: 'System Performance',
            defaultValue: true,
          },
          {
            id: 'self_consumption_breakdown',
            label: 'Self-consumption and export limit breakdown',
            defaultValue: false,
          },
          {
            id: 'environmental_benefits',
            label: 'Environmental Benefits',
            defaultValue: true,
          },
          {
            id: 'how_your_system_works',
            label: 'How your system works',
            defaultValue: true,
          },
          {
            id: 'daily_energy_flows',
            label: 'Daily energy flows',
            defaultValue: false,
          },
          {
            id: 'payment_options_group',
            label: 'Payment Options Group',
            defaultValue: true,
            children: [
              {
                id: 'bill_savings',
                label: 'Bill Savings',
                defaultValue: true,
                children: [
                  {
                    id: 'bill_savings_table',
                    label: 'Bill Savings Table',
                    defaultValue: true,
                  },
                ],
              },
              {
                id: 'exhibit_tile_utility_tariffs',
                label: 'Tariff Showcase Tile',
                defaultValue: true,
              },
              {
                id: 'financial_benefits',
                label: 'Financial Benefits',
                defaultValue: true,
                children: [
                  {
                    id: 'financial_benefits_detailed',
                    label: 'Financial Benefits Detailed',
                    defaultValue: false,
                  },
                ],
              },
              {
                id: 'payment_options',
                label: 'Quotation Table',
                defaultValue: true,
              },
              {
                id: 'exhibit_tile_finance',
                label: 'Finance Showcase Tile',
                defaultValue: true,
              },
            ],
          },
        ],
      },
      {
        id: 'next_steps',
        label: 'Next Steps',
        defaultValue: true,
      },
      {
        id: 'case_studies_simple',
        label: 'Case Studies',
        defaultValue: true,
      },
      {
        id: 'contract',
        label: 'Terms and Conditions',
        defaultValue: true,
      },
      {
        id: 'action_buttons',
        label: 'Action Buttons (Online only)',
        defaultValue: true,
      },
      {
        id: 'opensolar_disclaimer',
        label: 'OpenSolar Disclaimer (required)',
        defaultValue: true,
      },
      {
        id: 'spec_sheets',
        label: 'Brochures / Spec Sheets',
        defaultValue: true,
      },
      {
        id: 'content_block_1',
        label: 'Content Block 1',
        defaultValue: false,
      },
      {
        id: 'content_block_2',
        label: 'Content Block 2',
        defaultValue: false,
      },
      {
        id: 'content_block_3',
        label: 'Content Block 3',
        defaultValue: false,
      },
    ],
    genericGroup: ['content_block_1', 'content_block_2', 'content_block_3'],
    disabledChoices: ['opensolar_disclaimer'],
  },
  { type: 'h2', title: 'Featured Figures' },
  {
    fieldName: 'highlight_rows',
    fieldLabel: 'Featured Figures',
    type: 'FeatureFiguresSelector',
    choices: [
      // Technical Metrics
      { id: 'kw_stc', name: 'PV System Size' },
      { id: 'kwp_stc', name: 'PV System Size (kWp)' },
      { id: 'battery_total_kwh', name: 'Battery Size' },
      { id: 'daily_solar_generation', name: 'Daily Solar Generation (Year 1)' },
      { id: 'annual_solar_generation', name: 'Annual Solar Generation (Year 1)' },
      { id: 'consumption_offset_percentage', name: 'Consumption Offset (%)' },
      { id: 'energy_from_solar', name: 'Energy From Solar (%)' },
      { id: 'grid_independence', name: 'Grid Independence (%)' },
      { id: 'self_consumption', name: 'Self Consumption (%)' },
      { id: 'capacity_factor', name: 'Capacity Factor (%)' },
      { id: 'performance_ratio', name: 'Performance Ratio (%)' },
      { id: 'system_lifetime', name: 'System Lifetime' },
      { id: 'annual_energy_consumption', name: 'Annual Electricity Usage' },
      { id: 'annual_energy_import_grid', name: 'Annual Electricity Drawn from Grid' },
      { id: 'emissions_offset_percentage', name: '% Of CO₂, SOₓ & NOₓ per year' },
      { id: 'co2_tons', name: 'Tons of CO₂ reduced per year' },
      { id: 'car_km', name: 'Car distance avoided (lifetime)' },
      { id: 'trees_planted', name: 'Trees planted' },
      { id: 'flights', name: 'Long haul flights avoided' },

      // Utility Bill & Savings
      { id: 'utility_savings_total', name: 'Utility Savings (Lifetime)' },
      { id: 'utility_bill_savings_year_0', name: 'Utility Savings (Year 1)' },
      { id: 'utility_savings_per_bill_year_0', name: 'Utility Savings Per Bill' },
      { id: 'net_savings_year_0', name: 'First Year Savings' },
      { id: 'total_energy_cost_after_solar', name: 'Annual Energy Cost After Solar' },
      { id: 'solar_cost_per_kwh_year_0', name: 'Solar Cost Per kWh' },
      { id: 'average_utility_rate_before_solar_year_0', name: 'Average Utility Rate Before Solar' },
      { id: 'utility_bill_before_solar_year_0', name: 'Utility Bill Year 1 (Before Solar)' },
      { id: 'utility_bill_after_solar_year_0', name: 'Utility Bill Year 1 (After Solar)' },
      { id: 'utility_bill_lifetime_before_solar', name: 'Lifetime Utility Bill (Before Solar)' },
      { id: 'utility_bill_lifetime_after_solar', name: 'Lifetime Utility Bill (After Solar)' },

      // Financial Metrics
      { id: 'payback', name: 'Payback' },
      { id: 'discounted_payback_period', name: 'Discounted Payback Period' },
      { id: 'net_present_value', name: 'Net Present Value' },
      { id: 'total_return_on_investment', name: 'Total Return on Investment' },
      { id: 'internal_rate_of_return', name: 'Rate of Return on Investment' },
      { id: 'lcoe', name: 'Levelized Cost of Energy' },
      { id: 'net_cost_us', name: 'Net Cost' },
      { id: 'lifetime_net_profit', name: 'Net Savings (Lifetime)' },

      // Cost to customer for installing Solar
      { id: 'system_price', name: 'Total System Price (Auto inc/ex tax)' },
      { id: 'system_price_inc_tax', name: 'Total System Price inc tax' },
      { id: 'system_price_ex_tax', name: 'Total System Price ex tax' },
      { id: 'purchase_price', name: 'Purchase Price (Auto inc/ex tax)' },
      { id: 'purchase_price_inc_tax', name: 'Purchase Price inc tax' },
      { id: 'purchase_price_ex_tax', name: 'Purchase Price ex tax' },
      { id: 'payment_amount', name: 'Payment Amount (Auto inc/ex tax)' },
      { id: 'payment_amount_inc_tax', name: 'Payment Amount inc tax' },
      { id: 'payment_amount_ex_tax', name: 'Payment Amount ex tax' },
      { id: 'net_system_price', name: 'Net System Price (Auto inc/ex tax)' },
      { id: 'net_system_price_inc_tax', name: 'Net System Price inc tax' },
      { id: 'net_system_price_ex_tax', name: 'Net System Price ex tax' },
      { id: 'net_payment_amount', name: 'Net Payment Amount (Auto inc/ex tax)' },
      { id: 'net_payment_amount_inc_tax', name: 'Net Payment Amount inc tax' },
      { id: 'net_payment_amount_ex_tax', name: 'Net Payment Amount ex tax' },
    ],
  },

  { type: 'h2', title: 'Business Video' },
  { fieldName: 'business_video_link', type: 'TextInput', fieldLabel: 'Youtube Video Link' },

  { type: 'h2', title: 'Showcase Settings' },

  {
    fieldName: 'exhibit_content',
    fieldLabel: 'Showcase Content',
    type: 'SelectInput',
    choices: [
      { id: 'yes', name: 'Yes' },
      { id: 'no', name: 'No' },
    ],
  },

  {
    fieldName: 'show_product_types',
    type: 'SelectArrayInput',
    choices: [
      { id: 'module', name: 'Module' },
      { id: 'inverter', name: 'Inverter' },
      { id: 'battery', name: 'Battery' },
      { id: 'other', name: 'Others' },
      { id: 'utility_tariff', name: 'Tariff' },
    ],
  },
  {
    fieldName: 'featured_product_types',
    fieldLabel: 'Is Featured (Staff only)',
    type: 'SelectArrayInput',
    staff_only: true,
    choices: [
      { id: 'module', name: 'Module' },
      { id: 'inverter', name: 'Inverter' },
      { id: 'battery', name: 'Battery' },
      { id: 'other', name: 'Other' },
      { id: 'utility_tariff', name: 'Tariff' },
    ],
  },

  { type: 'h1', title: 'PDF Content' },
  {
    fieldName: 'pdf_systems_to_include',
    type: 'SelectInput',
    choices: [
      { id: 'selected', name: 'Selected' },
      { id: 'all', name: 'All' },
    ],
  },
  {
    fieldName: 'pdf_payment_options_to_include',
    type: 'SelectInput',
    choices: [
      { id: 'selected', name: 'Selected' },
      { id: 'all', name: 'All' },
    ],
  },

  {
    fieldName: 'qr_code_toggle',
    fieldLabel: 'Add QR Code to access Online Proposal',
    type: 'BooleanInput',
  },
  {
    type: 'Alert',
    title:
      'QR Code to access Online Proposal is only shown in the proposal PDF if proposal shareability is enabled in Project > Info.',
  },
  {
    fieldName: 'include_finance_qr_code',
    fieldLabel: 'Add QR Code to access finance application form',
    type: 'BooleanInput',
  },
  {
    type: 'Alert',
    title:
      'When enabled, QR Codes for finance applications will be included in the proposal PDF for any project with available finance applications. Proposal shareability does not need to be enabled on the project.',
  },
  {
    fieldName: 'qr_code_user_input',
    fieldLabel: 'QR Code Cover Page Content',
    type: 'DraftEditorWithContent',
    condition: 'qr_code_toggle',
    mentionContext: 'org_and_project_without_project_specific_terms',
    allowUploadImage: false,
    charCounterMax: 250,
  },

  { type: 'h1', title: 'Cover Page Template' },

  { fieldName: 'heading_cover', type: 'TextInput', fieldLabel: 'Cover Page Title' },

  { type: 'h2', title: 'Cover Page Image' },
  { fieldName: 'proposal_image_file_contents', type: 'ImageField', fieldLabel: 'Cover Page Image' },
  { fieldName: 'proposal_image_file_contents', type: 'ImageInput' },
  {
    fieldName: 'cover_image_format',
    fieldLabel: 'Cover Image Format',
    type: 'RadioButtonGroupInput',
    choices: [
      { id: 'half', name: 'Half' },
      { id: 'full', name: 'Full' },
    ],
  },

  { type: 'h2', title: 'Co-Branding Logo' },
  { fieldName: 'co_branding_logo_file_contents', type: 'ImageField', fieldLabel: 'Co-Branding Logo' },
  { fieldName: 'co_branding_logo_file_contents', type: 'ImageInput' },

  { type: 'h2', title: 'Cover Page Message' },
  {
    fieldName: 'letter_page_content',
    fieldLabel: 'Letter Page Content',
    type: 'DraftEditorWithContent',
    mentionContext: 'org_and_project_without_project_specific_terms',
    allowUploadImage: false,
    charCounterMax: 2200,
  },
  { type: 'h2', title: 'Cover Page Headers' },
  {
    fieldName: 'header_column_1',
    fieldLabel: 'Header Column 1 of 3',
    type: 'DraftEditorWithContent',
    mentionContext: 'org_and_project_without_project_specific_terms',
    allowUploadImage: true,
    charCounterMax: 250,
  },
  {
    fieldName: 'header_column_2',
    fieldLabel: 'Header Column 2 of 3',
    type: 'DraftEditorWithContent',
    mentionContext: 'org_and_project_without_project_specific_terms',
    allowUploadImage: true,
    charCounterMax: 250,
  },
  {
    fieldName: 'header_column_3',
    fieldLabel: 'Header Column 3 of 3',
    type: 'DraftEditorWithContent',
    mentionContext: 'org_and_project_without_project_specific_terms',
    allowUploadImage: true,
    charCounterMax: 250,
  },

  { type: 'h2', title: 'Cover Page Footers' },
  {
    fieldName: 'footer_column_1',
    fieldLabel: 'Footer Column 1 of 4',
    type: 'DraftEditorWithContent',
    mentionContext: 'org_and_project_without_project_specific_terms',
    allowUploadImage: true,
    charCounterMax: 250,
  },
  {
    fieldName: 'footer_column_2',
    fieldLabel: 'Footer Column 2 of 4',
    type: 'DraftEditorWithContent',
    mentionContext: 'org_and_project_without_project_specific_terms',
    allowUploadImage: true,
    charCounterMax: 250,
  },
  {
    fieldName: 'footer_column_3',
    fieldLabel: 'Footer Column 3 of 4',
    type: 'DraftEditorWithContent',
    mentionContext: 'org_and_project_without_project_specific_terms',
    allowUploadImage: true,
    charCounterMax: 250,
  },
  {
    fieldName: 'footer_column_4',
    fieldLabel: 'Footer Column 4 of 4',
    type: 'DraftEditorWithContent',
    mentionContext: 'org_and_project_without_project_specific_terms',
    allowUploadImage: true,
    charCounterMax: 250,
  },

  { type: 'h1', title: 'Standard Page Template' },

  { type: 'h2', title: 'Standard Page Footer' },
  {
    fieldName: 'footer_column_left',
    fieldLabel: 'Footer Column Left',
    type: 'DraftEditorWithContent',
    mentionContext: 'org_and_project_without_project_specific_terms',
    allowUploadImage: false,
    charCounterMax: 250,
  },

  { type: 'h1', title: 'Proposal Content' },

  { type: 'h2', title: 'General Content' },
  {
    fieldName: 'about_us_content',
    fieldLabel: 'About Us',
    type: 'DraftEditorWithContent',
    mentionContext: 'org_and_project_without_project_specific_terms',
    allowUploadImage: true,
  },
  {
    fieldName: 'hide_about_us_content_in_letter',
    fieldLabel: 'Hide About Us in Cover Message Dialog in MyEnergy',
    type: 'BooleanInput',
  },
  {
    fieldName: 'next_steps_content',
    fieldLabel: 'Next Steps',
    type: 'DraftEditorWithContent',
    mentionContext: 'org_and_project_without_project_specific_terms',
    allowUploadImage: true,
  },
  {
    fieldName: 'quotation_custom_content',
    fieldLabel: 'Quotation Content',
    type: 'DraftEditorWithContent',
    mentionContext: 'org_and_project_without_project_specific_terms',
    allowUploadImage: true,
  },

  {
    fieldName: 'content_block_1',
    fieldLabel: 'Content Block 1',
    type: 'DraftEditorWithContent',
    mentionContext: 'org_and_project_without_project_specific_terms',
    allowUploadImage: true,
  },

  {
    fieldName: 'content_block_2',
    fieldLabel: 'Content Block 2',
    type: 'DraftEditorWithContent',
    mentionContext: 'org_and_project_without_project_specific_terms',
    allowUploadImage: true,
  },

  {
    fieldName: 'content_block_3',
    fieldLabel: 'Content Block 3',
    type: 'DraftEditorWithContent',
    mentionContext: 'org_and_project_without_project_specific_terms',
    allowUploadImage: true,
  },
  { type: 'h2', title: 'Override Org Logo' },
  { type: 'p', title: 'Image uploaded here will be used instead of the org logo when this proposal template is used.' },
  { fieldName: 'override_logo_file_contents', type: 'ImageField', fieldLabel: 'Override Org Logo' },
  { fieldName: 'override_logo_file_contents', type: 'ImageInput' },

  { type: 'h2', title: 'Customise Headings' },
  {
    fieldName: 'heading_recommended_system_option',
    type: 'TextInput',
    fieldLabel: 'Heading - Recommended System Option',
  },
  { fieldName: 'heading_system_hardware', type: 'TextInput', fieldLabel: 'Heading - Your Solution' },
  { fieldName: 'heading_system_performance', type: 'TextInput', fieldLabel: 'Heading - System Performance' },
  {
    fieldName: 'heading_electricity_bill_savings',
    type: 'TextInput',
    fieldLabel: 'Heading - Electricity Bill Savings',
  },
  { fieldName: 'heading_how_your_system_works', type: 'TextInput', fieldLabel: 'Heading - How Your System Works' },
  { fieldName: 'heading_daily_energy_flows', type: 'TextInput', fieldLabel: 'Heading - Daily Energy Flows' },
  { fieldName: 'heading_payment_option', type: 'TextInput', fieldLabel: 'Heading - Payment Option' },
  { fieldName: 'heading_net_financial_impact', type: 'TextInput', fieldLabel: 'Heading - Net Financial Impact' },
  { fieldName: 'heading_environmental_benefits', type: 'TextInput', fieldLabel: 'Heading - Environmental Benefits' },
  { fieldName: 'heading_case_studies', type: 'TextInput', fieldLabel: 'Heading - Case Study' },
  { fieldName: 'heading_next_steps', type: 'TextInput', fieldLabel: 'Heading - Next Steps' },

  { type: 'h2', title: 'Disclaimers' },
  {
    fieldName: 'disclaimer_system_hardware_components',
    type: 'DraftEditorWithContent',
    mentionContext: 'org_and_project_and_system_without_project_specific_terms',
    fieldLabel: 'System Hardware Components',
    allowUploadImage: true,
  },
  {
    fieldName: 'disclaimer_system_performance_new',
    type: 'DraftEditorWithContent',
    mentionContext: 'org_and_project_and_system_without_project_specific_terms',
    fieldLabel: 'System Performance',
    allowUploadImage: true,
  },
  {
    fieldName: 'disclaimer_environmental_benefits',
    type: 'DraftEditorWithContent',
    mentionContext: 'org_and_project_and_system_without_project_specific_terms',
    fieldLabel: 'Environmental Benefits',
    allowUploadImage: true,
  },
  {
    fieldName: 'disclaimer_system_performance',
    type: 'DraftEditorWithContent',
    mentionContext: 'org_and_project_and_system_without_project_specific_terms',
    fieldLabel: 'Bill Savings',
    allowUploadImage: true,
  },
  {
    fieldName: 'disclaimer_daily_energy_flows',
    type: 'DraftEditorWithContent',
    mentionContext: 'org_and_project_and_system_without_project_specific_terms',
    fieldLabel: 'Daily Energy Flows',
    allowUploadImage: true,
  },
  {
    fieldName: 'disclaimer_system_price',
    type: 'DraftEditorWithContent',
    mentionContext: 'org_and_project_and_system_without_project_specific_terms',
    fieldLabel: 'System Quotation',
    allowUploadImage: true,
  },
  {
    fieldName: 'disclaimer_financial_impact',
    type: 'DraftEditorWithContent',
    mentionContext: 'org_and_project_and_system_without_project_specific_terms',
    fieldLabel: 'Financial Impact',
    allowUploadImage: true,
  },
  {
    fieldName: 'disclaimer_payment_option_cash',
    type: 'DraftEditorWithContent',
    mentionContext: 'org_and_project_and_system_without_project_specific_terms',
    fieldLabel: 'Financial Impact - Cash',
    allowUploadImage: true,
  },
  {
    fieldName: 'disclaimer_payment_option_loan',
    type: 'DraftEditorWithContent',
    mentionContext: 'org_and_project_and_system_without_project_specific_terms',
    fieldLabel: 'Financial Impact - Loan',
    allowUploadImage: true,
  },
  {
    fieldName: 'disclaimer_payment_option_ppa',
    type: 'DraftEditorWithContent',
    mentionContext: 'org_and_project_and_system_without_project_specific_terms',
    fieldLabel: 'Financial Impact - PPA',
    allowUploadImage: true,
  },
  {
    fieldName: 'disclaimer_payment_option_regular_payment',
    type: 'DraftEditorWithContent',
    mentionContext: 'org_and_project_and_system_without_project_specific_terms',
    fieldLabel: 'Financial Impact - Regular Payment',
    allowUploadImage: true,
  },
  {
    fieldName: 'disclaimer_payment_option_lease',
    type: 'DraftEditorWithContent',
    mentionContext: 'org_and_project_and_system_without_project_specific_terms',
    fieldLabel: 'Financial Impact - Lease',
    allowUploadImage: true,
  },
  { type: 'h1', title: 'Advanced Settings' },
  { type: 'h2', title: 'Proposal Checkout Settings' },
  {
    type: 'p',
    title:
      'You can control whether your customers can checkout proposal or not. When disabled, new proposals can not be accepted by the customers.',
  },
  {
    fieldName: 'proposal_checkout_buttons',
    type: 'SelectInput',
    choices: [
      { id: 'disable_after_expiry', name: 'Disable after proposal validity has expired' },
      { id: 'enabled', name: 'Enabled' },
      { id: 'disabled', name: 'Disabled' },
    ],
  },
  {
    fieldName: 'proposal_checkout_message',
    type: 'DraftEditorWithContent',
    fieldLabel: 'Message to customer when proposal checkout is disabled',
    mentionContext: 'org_and_project_without_project_specific_terms',
    allowUploadImage: true,
    condition: 'proposal_checkout_buttons',
    conditionOperand: ['disable_after_expiry', 'disabled'],
  },
  { type: 'h2', title: 'Cumulative Bill Savings Chart Settings' },
  {
    type: 'p',
    title: 'When enabled, any export credit in the Cumulative Bill Savings chart is combined with the savings value.',
  },
  {
    fieldName: 'hide_export_credit_breakdown',
    fieldLabel: 'Hide Export Credit Breakdown',
    type: 'BooleanInput',
    defaultValue: false,
  },
  { type: 'h2', title: 'Net Financial Impact Chart Settings' },
  {
    type: 'p',
    title:
      'When locked, the Cumulative and Annual Savings chart follows the same minimum and maximum savings on the y-axis between all the system and payment options for better savings comparison.',
  },
  {
    fieldName: 'lock_y_axis_across_all_options',
    fieldLabel: 'Lock Minimum/Maximum Axis',
    type: 'BooleanInput',
    defaultValue: true,
  },
]

// Add after others so we can use other fields to lookup their labels
// Insert into index=3, right after "sections"
fields.push({
  type: 'h1',
  title: 'Locked Sections',
  visibility: function (record, enableExhibit) {
    if (record.original || !enableExhibit) {
      return false
    } else {
      return true
    }
  },
})
fields.push({
  fieldName: 'locked_sections',
  fieldLabel: 'Locked Sections',
  type: 'CustomCheckboxGroupInput',
  choices: getLockedSectionsChoices(),
  visibility: function (record, enableExhibit) {
    if (record.original || !enableExhibit) {
      return false
    } else {
      return true
    }
  },
})

const fieldsInGroups = () => {
  let fieldGroups = []
  let group = null
  fields.forEach((f) => {
    if (f.type === 'h1') {
      if (group) {
        fieldGroups.push(group)
      }
      group = { title: f.title, fields: [] }
    } else {
      group.fields.push(f)
    }
  })
  fieldGroups.push(group)
  return fieldGroups
}

const properCaseUnderscoredString = (underscoredString) =>
  underscoredString.fieldName
    .split('_')
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(' ')

const fieldIsVisible = (field, record, enableExhibit, isStaff) =>
  (!field.visibility || field.visibility(record, enableExhibit) === true) && (isStaff || !field.staff_only)

export const RenderInputs = (props) => {
  const { record } = props
  const translate = useTranslate()
  const formState = useFormState()
  const isStaff = useSelector(authSelectors.getIsStaff)
  const form = useForm()
  const disabled = props.disabled === true
  const classes = useStyles()
  if (
    form.getState()?.values?.type === ProposalTemplateType.pvprosell ||
    form.getState()?.values?.type === ProposalTemplateType.bespoke
  ) {
    return null
  }

  const labelOrDefault = (fieldDetails, fallbackToName = true) =>
    fieldDetails.fieldLabel
      ? translate(fieldDetails.fieldLabel)
      : fallbackToName
      ? translate(properCaseUnderscoredString(fieldDetails))
      : undefined

  return !record.settings ? (
    <></>
  ) : (
    <div>
      {fieldsInGroups()
        // Skip any field groups which have no visible fields inside
        .filter(
          (fieldGroup) =>
            fieldGroup.fields.filter((field) => fieldIsVisible(field, props.record, props.enableExhibit, isStaff))
              .length > 0
        )
        .map((fieldGroup, fieldGroupIndex) => (
          <Accordion
            key={fieldGroupIndex + '_' + props.disabled}
            className="Accordion"
            classes={{ expanded: 'ExpansionPanelExpanded' }}
          >
            <AccordionSummary className="AccordionSummary" expandIcon={<ExpandMoreIcon />}>
              {translate(fieldGroup.title)}
            </AccordionSummary>
            <AccordionDetails className="AccordionDetails">
              <div
                style={{
                  // minHeight: 320,
                  position: 'relative',
                  width: '100%',
                  padding: 0,
                }}
              >
                {fieldGroup.fields
                  .filter((field) => fieldIsVisible(field, props.record, props.enableExhibit, isStaff))
                  .map((fieldDetails, i) => {
                    switch (fieldDetails.type) {
                      case 'p':
                        return (
                          <p
                            style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 12, margin: 0 }}
                            key={fieldGroupIndex + i}
                          >
                            {translate(fieldDetails.title)}
                          </p>
                        )
                      case 'h2':
                        return <h2 key={fieldGroupIndex + i}>{translate(fieldDetails.title)}</h2>
                      case 'h5':
                        return <h5 key={fieldGroupIndex + i}>{translate(fieldDetails.title)}</h5>
                      case 'Alert':
                        return <Alert severity="info">{translate(fieldDetails.title)}</Alert>
                      case 'BooleanInput':
                        return (
                          <BooleanInput
                            key={fieldGroupIndex + i}
                            component={BooleanInput}
                            name={fieldDetails.fieldName}
                            label={fieldDetails.fieldLabel || fieldDetails.fieldName}
                            source={fieldDetails.fieldName}
                            defaultValue={fieldDetails.defaultValue}
                            disabled={disabled}
                            {...props}
                          />
                        )
                      case 'TextInput':
                        return (
                          <CustomField
                            key={fieldDetails.fieldName + i}
                            name={fieldDetails.fieldName}
                            source={fieldDetails.fieldName}
                            style={inputStyle}
                            component={TextInput}
                            label={labelOrDefault(fieldDetails)}
                            options={{
                              disabled: isLockedByOriginalOwner(record, fieldDetails.fieldName) || disabled,
                            }}
                            {...props}
                          />
                        )
                      case 'DraftEditorWithContent':
                        return (
                          <FormDataConsumer>
                            {({ formData }) => {
                              return (
                                (!fieldDetails.condition ||
                                  formData[fieldDetails.condition] === fieldDetails.conditionOperand ||
                                  fieldDetails.conditionOperand?.includes(formData[fieldDetails.condition])) && (
                                  <div key={fieldGroupIndex}>
                                    <DependentInput dependsOn={fieldDetails.fieldName} resolve={renderDraftEditor}>
                                      <CustomField
                                        label={labelOrDefault(fieldDetails)}
                                        component={DraftEditorWithContent}
                                        fieldName={fieldDetails.fieldName}
                                        allowUploadImage={fieldDetails.allowUploadImage}
                                        name={fieldDetails.fieldName}
                                        source={fieldDetails.fieldName}
                                        charCounterMax={fieldDetails.charCounterMax}
                                        mentionContext={fieldDetails.mentionContext}
                                        record={record}
                                        resource={props.resource}
                                        disabled={isLockedByOriginalOwner(record, fieldDetails.fieldName) || disabled}
                                      />
                                    </DependentInput>

                                    <DependentInput dependsOn={fieldDetails.fieldName} resolve={renderRawHtmlEditor}>
                                      <CustomField
                                        label={labelOrDefault(fieldDetails)}
                                        component={TextInput}
                                        fullWidth={true}
                                        multiline={true}
                                        style={inputStyle}
                                        fieldName={fieldDetails.fieldName}
                                        name={fieldDetails.fieldName}
                                        source={fieldDetails.fieldName}
                                        {...props}
                                      />
                                    </DependentInput>
                                  </div>
                                )
                              )
                            }}
                          </FormDataConsumer>
                        )
                      case 'SelectInput':
                        return (
                          <CustomField
                            key={fieldGroupIndex + i}
                            name={fieldDetails.fieldName}
                            source={fieldDetails.fieldName}
                            component={SelectInput}
                            style={inputStyle}
                            label={fieldDetails.fieldLabel}
                            choices={fieldDetails.choices.map((obj) => {
                              return { name: translate(obj.name), ...obj }
                            })}
                            options={{
                              disabled: isLockedByOriginalOwner(record, fieldDetails.fieldName) || disabled,
                            }}
                            {...props}
                          />
                        )
                      case 'SelectArrayInput':
                        return (
                          //should we refactor this back to old UI
                          <CustomField
                            component={SelectArrayInput}
                            style={inputStyle}
                            key={fieldGroupIndex + i}
                            label={fieldDetails.fieldLabel}
                            fieldName={fieldDetails.fieldName}
                            source={fieldDetails.fieldName}
                            options={{
                              listStyle: { overflow: 'auto', display: 'inline-grid', maxHeight: 200 },
                              disabled: isLockedByOriginalOwner(record, fieldDetails.fieldName) || disabled,
                            }}
                            name={fieldDetails.fieldName}
                            choices={fieldDetails.choices.map((obj) => {
                              return { name: translate(obj.name), ...obj }
                            })}
                            {...props}
                          />
                        )

                      case 'FeatureFiguresSelector':
                        return (
                          <FeatureFiguresSelector
                            key={fieldGroupIndex + i}
                            fieldName={fieldDetails.fieldName}
                            source={fieldDetails.fieldName}
                            options={{
                              listStyle: { overflow: 'auto', display: 'inline-grid', maxHeight: 200 },
                              disabled: isLockedByOriginalOwner(record, fieldDetails.fieldName) || disabled,
                              style: inputStyle,
                            }}
                            name={fieldDetails.fieldName}
                            choices={fieldDetails.choices.map((obj) => {
                              return { name: translate(obj.name), ...obj }
                            })}
                            {...props}
                          />
                        )
                      case 'CustomCheckboxGroupInput':
                        return (
                          <CustomField
                            component={CustomCheckboxGroupInput}
                            key={fieldGroupIndex + i}
                            fieldName={fieldDetails.fieldName}
                            source={fieldDetails.fieldName}
                            name={fieldDetails.fieldName}
                            genericGroup={fieldDetails.genericGroup}
                            choices={fieldDetails.choices}
                            // options={
                            //   {
                            //      disabled: fieldDetails.disabled && fieldDetails.disabled(props.record) === true,
                            //   }
                            // }
                            disabledChoices={
                              disabled ? fieldDetails.choices.map((choice) => choice.id) : fieldDetails.disabledChoices
                            }
                            disabled={disabled}
                          />
                        )
                      case 'SectionConfiguration':
                        return formState.values[fieldDetails.fieldName] ? (
                          <CustomField
                            component={SectionConfiguration}
                            key={fieldGroupIndex + i}
                            choices={fieldDetails.choices.map((obj) => {
                              return { name: translate(obj.name), ...obj }
                            })}
                            fieldName={fieldDetails.fieldName}
                            source={fieldDetails.fieldName}
                            name={fieldDetails.fieldName}
                            updateField={form.mutators.updateField}
                            genericGroup={fieldDetails.genericGroup}
                            options={{
                              style: { width: 300 },
                            }}
                            disabled={isLockedByOriginalOwner(record, fieldDetails.fieldName) || disabled}
                            disabledChoices={
                              //disable generic blocks for solaria template
                              fieldDetails.disabledChoices
                            }
                            resource={props.resource}
                          />
                        ) : null

                      case 'ImageField':
                        return (
                          <CustomField
                            component={ImageFieldClearable}
                            key={fieldGroupIndex + i}
                            source={fieldDetails.fieldName}
                            name={fieldDetails.fieldName}
                            title="title"
                            record={record}
                            disabled={isLockedByOriginalOwner(record, fieldDetails.fieldName) || disabled}
                            resource={props.resource}
                          />
                        )
                      case 'ImageInput':
                        return (
                          !disabled &&
                          (isLockedByOriginalOwner(record, fieldDetails.fieldName) !== true ? (
                            <CustomField
                              component={ImageInput}
                              key={fieldGroupIndex + i}
                              name={fieldDetails.fieldName}
                              source={fieldDetails.fieldName}
                              label="Upload Image"
                              accept="image/*"
                              placeholder={<p>{translate('Drop your image here')}</p>}
                              {...props}
                            >
                              <ImageField source="src" title="title" />
                            </CustomField>
                          ) : null)
                        )
                      case 'FileField':
                        return (
                          <CustomField
                            component={FileFieldClearable}
                            key={fieldGroupIndex + i}
                            source={fieldDetails.fieldName}
                            name={fieldDetails.fieldName}
                            title="title"
                            record={record}
                            disabled={isLockedByOriginalOwner(record, fieldDetails.fieldName) || disabled}
                            resource={props.resource}
                          />
                        )
                      case 'FileInput':
                        return (
                          !disabled &&
                          (isLockedByOriginalOwner(record, fieldDetails.fieldName) !== true ? (
                            <div
                              className={
                                typeof formState.values[fieldDetails.fieldName] === 'string' && classes.hidePreviews
                              }
                            >
                              <CustomField
                                component={FileInput}
                                key={fieldGroupIndex + i}
                                name={fieldDetails.fieldName}
                                source={fieldDetails.fieldName}
                                label={translate('Upload File')}
                                placeholder={<p>{translate('Drop your file here')}</p>}
                                {...props}
                              >
                                <FileField source="src" title="title" />
                              </CustomField>
                            </div>
                          ) : null)
                        )
                      case 'RadioButtonGroupInput':
                        return (
                          <CustomField
                            key={fieldGroupIndex + i}
                            style={{ top: 0, display: 'table', color: 'rgb(162, 160, 160)' }}
                            name={fieldDetails.fieldName}
                            label={fieldDetails.fieldLabel}
                            source={fieldDetails.fieldName}
                            component={RadioButtonGroupInput}
                            choices={fieldDetails.choices.map((obj) => {
                              return { name: translate(obj.name), ...obj }
                            })}
                            options={{
                              initialValue: 'full',
                              disabled: disabled,
                            }}
                            disabled={isLockedByOriginalOwner(record, fieldDetails.fieldName) || disabled}
                            resource={props.resource}
                          />
                        )

                      default:
                        return null
                    }
                  })}
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  )
}

export const formatSubmitValues = (values) => {
  const newSettingsData = prepareSettingsDataFormSubmit(values)
  if (!Array.isArray(values['locked_sections']) || !newSettingsData['locked_sections']) {
    values['locked_sections'] = []
  }

  values.settings = JSON.stringify(newSettingsData)

  // remove `original` field which should not be posted to back-end or will causes errors
  if (values.original) {
    delete values.original
  }
  return values
}

const _ProposalTemplateEdit = ({ accessRights, ...props }) => {
  const translate = useTranslate()
  return useEditShareable({
    accessRights,
    editProps: props,
    render: ({ access }) => (
      <SimpleForm
        hideCancelButton={!!props.hideCancelButton}
        defaultValue={defaultValueFunc}
        redirect={false}
        formatSubmitValues={formatSubmitValues}
        keepDirtyOnReinitialize={true}
        toolbar={
          access.allowEdit ? (
            <CreateEditToolbar org_id={props.org_id} resource="proposal_templates" duplicate={props.duplicate} />
          ) : null
        }
        validate={(values) => validateTitle(values, translate)}
        disabled={!access.allowEdit}
      >
        <OverviewInputs>
          <CustomField
            component={TextInput}
            inputStyle={inputStyle}
            name="title"
            source="title"
            disabled={!access.allowEdit}
          />
          <CustomField component={BooleanInput} name="is_default" source="is_default" disabled={!access.allowEdit} />
          <CustomField
            component={BooleanInput}
            name="is_archived"
            source="is_archived"
            defaultValue={false}
            disabled={!access.allowEdit}
          />

          {props.enableExhibit && (
            <CustomField
              component={BooleanInput}
              name="accessible_to_partners"
              label="Accessible to Partners"
              source="accessible_to_partners"
              defaultValue={false}
              disabled={!props.isStaff}
            />
          )}
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.pvprosell_template_id &&
              props.api_key_pvsell && (
                <PVProSellFormContent
                  pvprosell_template_id={formData.pvprosell_template_id}
                  api_key_pvsell={props.api_key_pvsell}
                  disabled={!access.allowEdit}
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) => formData.type === ProposalTemplateType.bespoke && <BespokeFormContent />}
          </FormDataConsumer>
        </OverviewInputs>
        <TextInput
          fullWidth={true}
          multiline={true}
          source="settings"
          style={{ display: 'none' }}
          disabled={!access.allowEdit}
        />
        <RenderInputs enableExhibit={props.enableExhibit} disabled={!access.allowEdit} />
        <ShareabilitySelector />
        <div style={{ marginBottom: 20 }}></div>
      </SimpleForm>
    ),
  }).editPage
}

_ProposalTemplateEdit.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
}

export const validateTitle = (values, translate) => {
  const errors = {}
  if (!values.title) {
    errors.title = [translate('Title is required.')]
  }
  return errors
}

export const ProposalTemplateEdit = connect(mapStateToPropsEdit, {
  showNotification: showNotificationAction,
  push: pushAction,
  duplicate: duplicateAction,
})(_ProposalTemplateEdit)

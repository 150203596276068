import { useMediaQuery, useTheme } from '@material-ui/core'
import { LABELS_FOR_COMPONENT_TYPES } from 'elements/hardwareSelector/HardwareSelector'
import useOptimisticValue from 'elements/input/useOptimisticValue'
import { useTranslate } from 'react-admin'
import type { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { mapComponentTypesV2ToV1 } from 'util/misc'
import HardwareSelectorTopBanner from '../../../pages/ordering/banner/HardwareSelectorTopBanner'
import { ComponentTypesV2 } from '../../../types/selectComponent'
import HardwareSelectorSearchBar from './HardwareSelectorSearchBar'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  description: {
    color: '#0A090B',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    marginBottom: '14px',
  },
  supportText: {
    textAlign: 'center',
    flex: 1,
  },
}))

export const getPlaceholderByComponentType = (componentType: ComponentTypesV2): string => {
  let searchTextPlaceHolder = 'Search by component code and/or brand name...'

  switch (componentType) {
    case 'module':
      searchTextPlaceHolder = `Search by panel code, brand name and/or rating in Watts...`
      break
    case 'inverter':
      searchTextPlaceHolder = `Search by inverter code and/or brand name...`
      break
    case 'battery':
      searchTextPlaceHolder = 'Search by battery code and/or brand name...'
      break
  }

  return searchTextPlaceHolder
}

const HardwareSelectorToolBars = ({
  description,
  value = '',
  placeholder,
  componentType,
  onChange,
}: {
  description?: string
  value?: string
  placeholder: string
  componentType: ComponentTypesV2
  onChange(search: string): void
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const translate = useTranslate()
  const downLG = useMediaQuery(theme.breakpoints.down('lg'))

  const { optimisticValue, updateOptimisticValue } = useOptimisticValue<string>({ defaultValue: '', value })

  const componentTypeKey = mapComponentTypesV2ToV1(componentType)

  return (
    <div className={classes.container}>
      {!downLG && <HardwareSelectorTopBanner searchText={optimisticValue} componentType={componentType} />}
      {description && <div className={classes.description}>{description}</div>}
      <div className={classes.searchContainer}>
        <HardwareSelectorSearchBar
          placeholder={placeholder}
          optimisticValue={optimisticValue}
          updateOptimisticValue={updateOptimisticValue}
          onChange={onChange}
        />
        <div className={classes.supportText}>
          {translate(LABELS_FOR_COMPONENT_TYPES[componentTypeKey]?.cannotFind)}{' '}
          <a href="https://support.opensolar.com/hc/requests/new">{translate('Let us know')}</a>
        </div>
      </div>
    </div>
  )
}

export default HardwareSelectorToolBars

import CheckboxSelectField, { CheckboxSelectFieldProps, ChoiceType } from 'elements/field/CheckboxSelectField'
import { useInput } from 'ra-core'
import { useMemo } from 'react'
import { toCapitalise } from 'util/misc'

interface PropTypes {
  source: string
  choices?: { id: string; name: string }[] | string //string separated by commas
  optionValue?: string
  optionText?: string
}
const CheckboxMultiSelectInput = <T,>(props: PropTypes & Omit<CheckboxSelectFieldProps<T>, 'choices'>) => {
  const { source, choices = [], optionValue = 'id', optionText = 'name', ...rest } = props
  const selectChoices = useMemo(
    () =>
      (typeof choices === 'string'
        ? (choices?.split(',') || []).map((value: string) => ({
            id: value,
            name: value,
          }))
        : choices.map((x) => {
            const choiceId = typeof x[optionValue] !== 'string' ? x[optionValue].toString() : x[optionValue]
            return { id: choiceId, name: x[optionText] }
          })) as ChoiceType<T>[],
    [choices]
  )
  const { id, input } = useInput({
    source,
    parse: (choice) => {
      let newVal = choice.id
      let newValues: any = input.value ? [...input.value] : []
      newValues = newValues?.includes(newVal) ? newValues.filter((x) => x !== newVal) : [...newValues, newVal]
      return newValues.toString()
    },
    format: (value) => {
      const existingChoices = selectChoices?.map((y) => y.id)
      if (value) return value.split(',').filter((x) => existingChoices?.includes(x))
    },
  })
  return (
    <CheckboxSelectField
      id={id}
      {...input}
      {...rest}
      choices={selectChoices}
      selected={input.value}
      customDisplay={(value) => {
        let displayStr
        value?.forEach((x, i) => {
          const choiceName = selectChoices?.find((y) => y.id === x)?.name
          if (i === 0) displayStr = toCapitalise(choiceName)
          else displayStr = `${displayStr}, ${toCapitalise(choiceName)}`
        })
        return displayStr
      }}
    />
  )
}
export default CheckboxMultiSelectInput

import { makeStyles } from '@material-ui/core'
import { EditOutlined } from '@material-ui/icons'
import { OpenSolarThemeType } from 'Themes'
import Chip from 'elements/chip/Chip'
import { DraggableFlow } from 'elements/dnd/DraggableFlow'
import { IconButton } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'
import { useForm } from 'react-final-form'
import { WorkflowActionType, WorkflowStageType } from 'types/workflows'

const useStyles = makeStyles((theme: OpenSolarThemeType) => ({
  helpText: {
    color: theme.greyMid1,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  actionChip: {
    marginRight: 5,
    marginBottom: 5,
    '& svg': {
      color: theme.greyMid1,
    },
  },
  editBtn: {
    padding: 0,
    marginLeft: 5,
    height: 25,
  },
  dragBtn: {
    padding: 0,
    marginRight: 5,
  },
  editBtnIcon: {
    fontSize: '19px',
    marginRight: 5,
  },
}))

interface PhaseTypes {
  stageId: number
  stage: WorkflowStageType
  setEditingAction: (action) => void
}

const Actions: React.FC<PhaseTypes> = (props) => {
  const { stageId, stage, setEditingAction } = props
  const form = useForm()
  const classes = useStyles()
  const translate = useTranslate()

  const deleteAction = (actionId) => {
    const newStage = { ...stage, actions: stage.actions.filter((x) => x.id !== actionId) }
    form.mutators.updateField(`workflow_stages.${stageId}`, newStage)
  }

  return (
    <div className={classes.row}>
      {!!!stage.actions.length ? (
        <span className={classes.helpText}>{translate('Click + Action to create an action')}</span>
      ) : (
        <DraggableFlow
          items={stage.actions}
          handleReorder={(newOrder: WorkflowActionType[]) => {
            let newStage = {
              ...stage,
              actions: newOrder,
            }
            form.mutators.updateField(`workflow_stages.${stageId}`, newStage)
          }}
          dragMode="drag-handle"
          render={(action, { dragHandle }) => (
            <ActionChip
              item={action}
              stage={stage}
              dragHandle={dragHandle}
              onEdit={(action) => {
                setEditingAction({ stageId, action })
              }}
              onDelete={(action) => {
                deleteAction(action.id)
              }}
            />
          )}
        />
      )}
    </div>
  )
}

const ActionChip = (props) => {
  const { stage, item: action, onEdit, onDelete, dragHandle } = props
  const classes = useStyles()

  return (
    <Chip
      disabled={stage.forDelete}
      className={classes.actionChip}
      variant="secondary"
      size="medium"
      label={
        <div className={classes.row}>
          {dragHandle}
          {action.title}
          <IconButton
            className={classes.editBtn}
            onClick={() => {
              onEdit(action)
            }}
          >
            <EditOutlined className={classes.editBtnIcon} />
          </IconButton>
        </div>
      }
      onDelete={() => {
        onDelete(action)
      }}
    />
  )
}

export default Actions

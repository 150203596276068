import React from 'react'
import LogoOrTitle from './LogoOrTitle'

const addHttpIfMissing = (url) => (url.indexOf('http') === 0 ? url : 'http://' + url)

const Header = ({ org, title }) => (
  <div style={{ textAlign: 'center', marginBottom: 30 }}>
    <LogoOrTitle org={org} skipTitleStyle />
    {org.logo_public_url && org.logo_public_url.length && <h2>{org.name}</h2>}
    <h2>{title}</h2>
    {org.company_website && org.company_website.length && (
      <p>
        <a href={addHttpIfMissing(org.company_website)} target="_blank" rel="noreferrer">
          {org.company_website}
        </a>
      </p>
    )}
  </div>
)

export const AboutUS = ({ org, title, selectedProject, showLetter }) => {
  var content =
    selectedProject.proposal_data &&
    selectedProject.proposal_data.proposal_template_settings &&
    selectedProject.proposal_data.proposal_template_settings.about_us_content
      ? selectedProject.proposal_data.proposal_template_settings.about_us_content
      : org.about_content

  var hasContent = Boolean(content && content.trim())
  if (showLetter) {
    if (!hasContent) {
      return null
    } else {
      return (
        <div id="AboutUSContent">
          <hr style={{ margin: '40px 0', border: '1px solid #f0f0f0' }} />
          <div style={{ width: '100%' }}>
            <div
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          </div>
        </div>
      )
    }
  } else {
    return (
      <div id="AboutUSContent">
        <div style={{ width: '100%' }}>
          <Header org={org} title={title} />
          {hasContent && (
            <div
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          )}
        </div>
      </div>
    )
  }
}

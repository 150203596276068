import { StudioSystemType } from 'types/global'
import { McsData, RoofFacet } from 'types/mcs'

export function getRoofFacets(system: StudioSystemType, mcsData: McsData): RoofFacet[] {
  const moduleGrids = system.moduleGrids()
  var roofFacets = mcsData?.roof_facets
  // Assign moduleGrids to roof facets if necessary
  // Used for backwards compatibility and to assign new moduleGrids
  // First filter the roof facets module grids so that we're only looking at the module grids in this system (mcsData.roof_facets is a project-level variable)
  const moduleGridsInSystem = moduleGrids.map((moduleGrid) => moduleGrid.uuid)
  if (roofFacets)
    roofFacets = roofFacets.reduce((systemRoofFacets, roofFacet) => {
      if (roofFacet.moduleGridUuids) {
        roofFacet.moduleGridUuids = roofFacet.moduleGridUuids.filter((moduleGridUuid) =>
          moduleGridsInSystem.includes(moduleGridUuid)
        )
      }
      if (roofFacet.moduleGridUuids?.length) {
        let moduleGrid = window.editor.objectByUuid(roofFacet.moduleGridUuids[0])
        roofFacet.slope = moduleGrid.getSlope()
        roofFacet.azimuth = moduleGrid.getAzimuth()
        roofFacet.module_quantity = moduleGrid.moduleQuantity()
      }
      systemRoofFacets.push(roofFacet)
      return systemRoofFacets
    }, [] as RoofFacet[])
  // Determine which module grids are not assigned to a roof and vice versa
  var moduleGridsNotAssignedToRoofs = moduleGrids.map((moduleGrid) => moduleGrid.uuid)
  var roofsNotAssignedModuleGrids: number[] = []
  if (roofFacets && roofFacets.length) {
    roofFacets.forEach((roof, index) => {
      if (roof.moduleGridUuids && roof.moduleGridUuids.length) {
        moduleGridsNotAssignedToRoofs = moduleGridsNotAssignedToRoofs.filter(
          (uuid) => !roof.moduleGridUuids?.includes(uuid)
        )
      } else {
        roofsNotAssignedModuleGrids.push(index)
      }
    })
  } else {
    roofFacets = []
  }
  // Assign any unassigned module grids to empty roofs or create a new roof
  while (moduleGridsNotAssignedToRoofs.length) {
    let emptyRoof = roofsNotAssignedModuleGrids.shift()
    let moduleGridUuid = moduleGridsNotAssignedToRoofs.shift()!
    let moduleGrid = window.editor.objectByUuid(moduleGridUuid)
    const roofFacetData = {
      slope: moduleGrid.getSlope(),
      azimuth: moduleGrid.getAzimuth(),
      moduleGridUuids: [moduleGridUuid],
    }
    const roofFacetDefaults = {
      fixing_length_screwed_through_timber: 70,
      fixing_diameter: 6,
      fixing_qty: moduleGrid.moduleQuantity() * 4,
    }
    if (typeof emptyRoof !== 'undefined') {
      roofFacets[emptyRoof] = { ...roofFacetDefaults, ...roofFacets[emptyRoof], ...roofFacetData }
    } else {
      roofFacets.push({ ...roofFacetDefaults, ...roofFacetData })
    }
  }
  // Remove excess roof facets that have no module grids
  roofFacets = roofFacets.filter((roofFacet) => !!roofFacet.moduleGridUuids?.length)
  return roofFacets
}

export function getMcsRoofFacetData(system: StudioSystemType, mcsDataParent: Record<string, any>): RoofFacet[] {
  let mcsData: McsData = mcsDataParent._mcs
  if (!mcsData) {
    mcsData = mcsDataParent._mcs = {}
  }
  const roofFacets = getRoofFacets(system, mcsData)
  mcsData.roof_facets = roofFacets
  return roofFacets
}

import { FormControlLabel, makeStyles, TextField } from '@material-ui/core'
import { Button, Checkbox } from 'opensolar-ui'
import { parseQueryStringToDictionary } from 'util/misc'
import { OpenSolarTheme } from '../../Themes'

export const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    //CSS3 100vh not constant in mobile browser
    //https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
    minHeight: window.Utils?.iOS() ? '-webkit-fill-available' : '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Roboto, sans-serif',
  },
  card: {
    float: 'left',
    marginTop: 0,
    minWidth: 210,
    padding: '0.5em 1em 1em 1em',
  },
  cardOnly: {
    minWidth: 210,
    padding: '0.5em 1em 1em 1em',
  },
  avatar: {
    margin: '1em',
    textAlign: 'center ',
  },
  form: {},
  input: {
    display: 'flex',
    margin: '10px 0',
  },
  hint: {
    textAlign: 'center',
    marginTop: '1em',
    color: '#4D4D4D',
    fontSize: '11pt',
  },
  terms: {
    fontSize: '11pt',
    textAlign: 'center',
  },
  emailConsent: {
    margin: '20px 0px 10px 0px',
  },
}

export const NearmapLogo = (props) => (
  <img
    alt=""
    src={window.PUBLIC_URL + '/images/nearmap_on_opensolar_logo_837x152.png'}
    style={{ width: 200, margin: 20 }}
    {...props}
  />
)

export const renderInput = ({ label, input, meta: { touched, invalid, error }, fullWidth, ...custom }) => (
  <TextField
    label={label}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    margin="dense"
    {...input}
    {...custom}
    fullWidth={fullWidth}
  />
)

export const renderCheckbox = ({ label, input, meta, ...custom }) => (
  <FormControlLabel control={<Checkbox margin="dense" {...input} {...custom} />} label={label} />
)

export const pathnameAndParamsWithUrlFragment = (basePath, location, urlFragment) => {
  const urlFragmentStorage = basePath.includes('/login') || basePath.includes('/register') ? 'url' : 'params'

  const pathname = urlFragment && urlFragmentStorage === 'url' ? basePath + '-' + urlFragment : basePath

  const params = Object.assign(
    location.params || parseQueryStringToDictionary(location.search),
    urlFragment && urlFragmentStorage === 'params' ? { urlFragment: urlFragment } : {}
  )

  return { pathname, params }
}

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: (theme && theme.themeColor) || OpenSolarTheme.themeColor,
    color: (theme && theme.headerFontColor) || OpenSolarTheme.headerFontColor,
    '&:hover': {
      backgroundColor: (theme && theme.themeColorDark) || OpenSolarTheme.themeColorDark,
    },
  },
}))

export const StyledButton = ({ children, ...props }) => {
  const classes = useStyles()
  return (
    <Button classes={{ root: classes.button }} {...props}>
      <span style={{ fontSize: 14 }}>{children}</span>
    </Button>
  )
}

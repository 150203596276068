import { Card } from '@material-ui/core'
import React, { memo } from 'react'
import { useTranslate } from 'react-admin'

export const PreviewNotAvailable = memo(({ message }) => {
  const translate = useTranslate()
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        flexDirection: 'column',
        display: 'flex',
        WebkitFlexDirection: 'column',
        zIndex: 1,
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Card style={{ width: '308px', minWidth: 210, padding: '0.5em 1em 1em 1em' }}>
          <h1>{translate('Welcome to PDF Preview')}</h1>
          <h2 style={{ color: '#C1292E' }}>{translate(message)}</h2>
        </Card>
      </div>
    </div>
  )
})

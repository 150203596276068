import { FormControlLabel, InputAdornment, Radio, RadioGroup } from '@material-ui/core'
import { CurrencySymbolContext } from 'contexts/CurrencySymbolContext'
import { authSelectors } from 'ducks/auth'
import DateFormInput from 'elements/input/DateInput'
import DependentInput from 'elements/input/DependentInput'
import React, { useContext, useState } from 'react'
import { BooleanInput, NumberInput, required, SelectInput, TextInput, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { dateParser, formatDateString } from 'util/misc'
import useTranslateParse from 'util/useTranslateParse'
import { validRange } from 'validations'
import { UNIT_OPTIONS } from '../common'
import { useStyles } from '../styles'
import { InputWithToolTip } from '../TariffInputs'
import AdjustmentTable from './pricingTable/AdjustmentTable'
import PricingTable from './pricingTable/PricingTable'
import SolarCompensationInputs from './solarCompensation/SolarCompensationInputs'

type PropTypes = {
  isCustom?: boolean
  disabled?: boolean
  countryIso2: string
}

const TaxDetailsFields: React.FC<PropTypes> = ({ isCustom, countryIso2, disabled }) => {
  const form = useForm()
  const classes = useStyles()
  const translate = useTranslate()
  const formState = useFormState()
  const formValues = formState.values
  const translateParse = useTranslateParse()
  const isStaff = useSelector(authSelectors.getIsStaff)
  const currencySymbol = useContext(CurrencySymbolContext)
  const [showVersionInput, setShowVersionInput] = useState(false)
  const tariffVersion = formValues.version
  const handleChange = (name, value) => {
    form.mutators.updateField(name, value)
  }

  return (
    <div className={classes.column}>
      {isStaff && (
        <>
          <h2>{translate('Staff Only')}</h2>
          <BooleanInput source="is_public_read_only" label="Is Public" disabled={disabled} />
          <TextInput
            name={'ui_message_key'}
            label={'UI Message Key'}
            source={'ui_message_key'}
            className={classes.textInput}
            variant="outlined"
            options={{
              InputLabelProps: {
                shrink: true,
              },
            }}
            disabled={disabled}
          />
          {formValues.modified_is_public_read_only_by_role_email && (
            <p className={classes.staffSubText}>
              Set to Is Public by: {formValues.modified_is_public_read_only_by_role_email}
            </p>
          )}
          <hr className="light" />
        </>
      )}

      {/* <div className={classes.row}>
        <Button
          startIcon={<Restore />}
          label={'Tariff Version History'}
          variant="text"
          size="medium"
          onClick={() => {
            setShowVersionInput(!showVersionInput)
          }}
          className={classes.blueText}
        />
        {tariffVersion === tariffVersions[tariffVersions.length - 1] && (
          <span className={classes.staffSubText}>{`(${translate('Currently Latest Selected')})`}</span>
        )}
      </div> 
      {showVersionInput && (
        <div className={classes.tariffHistory}>
          <p>{translate('Valid From')}:</p>
          <div className={`${classes.row} ${classes.tariffVersions}`}>
            {tariffVersions.map((version) => (
              <Button
                label={version}
                variant="outlined"
                onClick={() => {
                  handleChange('version', version)
                }}
                className={version === tariffVersion ? classes.blueButton : undefined}
              />
            ))}
            <Button label={'Add New Tariff Version'} startIcon={<Add />} variant="outlined" />
          </div>
        </div>
      )}*/}
      {!isCustom && (
        <>
          <DateFormInput
            className={classes.numInput}
            source={'valid_from'}
            label="Valid From"
            defaultValue={new Date()}
            parse={dateParser}
            inputVariant={'outlined'}
            options={{
              InputLabelProps: {
                shrink: true,
              },
              disabled: disabled,
            }}
            disabled={disabled}
          />
          <DateFormInput
            className={classes.numInput}
            source="valid_to"
            label="Valid To (Optional)"
            parse={dateParser}
            inputVariant={'outlined'}
            options={{
              InputLabelProps: {
                shrink: true,
              },
              disabled: disabled,
            }}
          />
          {formValues.modified_date && (
            <p className={classes.staffSubText}>Last updated on: {formatDateString(formValues.modified_date)}</p>
          )}
          {formValues.modified_by_role_email && (
            <p className={classes.staffSubText}>Last updated by: {formValues.modified_by_role_email} </p>
          )}
          <hr className="light" />
        </>
      )}
      <h2>{translate('Tax')}</h2>
      <div className={`${classes.row} ${classes.wrapRow}`}>
        <p>{translate('Pricing in this Rate is Tax')}:</p>
        <RadioGroup
          name="tariffData.tax_settings"
          value={formState.values.tariffData.tax_settings}
          defaultValue={'inclusive_tax'}
          onChange={(e) => {
            handleChange('tariffData.tax_settings', e.target.value)
          }}
        >
          <div className={classes.row}>
            <div>
              <FormControlLabel
                value={'inclusive_tax'}
                control={<Radio />}
                label={translate('Inclusive')}
                disabled={disabled}
              />
            </div>
            <div>
              <FormControlLabel
                value={'exclusive_tax'}
                control={<Radio />}
                label={translate('Exclusive')}
                disabled={disabled}
              />
            </div>
          </div>
        </RadioGroup>
      </div>
      <NumberInput
        label="Tax Amount"
        source="tariffData.tax_percentage"
        options={{
          InputLabelProps: {
            shrink: true,
          },
        }}
        disabled={disabled}
        className={classes.numInput}
        variant="outlined"
        validate={[validRange(0, 100, true), required()]}
        endAdornment={<InputAdornment position="end">%</InputAdornment>}
      />
      <hr className="light" />
      <h2>{translate('Solar Compensation')}</h2>
      <SolarCompensationInputs currencySymbol={currencySymbol} countryIso2={countryIso2} disabled={disabled} />
      <hr className="light" />
      <div className={classes.row}>
        <InputWithToolTip
          message={
            'The minimum amount that must be paid on an electricity bill for that billing period. It cannot be offset by generation credits.'
          }
        >
          <h2>{translate('Has Minimum Charge')}</h2>
        </InputWithToolTip>
        <BooleanInput label="" source={'has_minimum_charge'} disabled={disabled} />
      </div>
      <DependentInput dependsOn="has_minimum_charge" value={true}>
        <div className={`${classes.row} ${classes.wrapRow}`}>
          <NumberInput
            label="Minimum Charge Amount"
            source="tariffData.minimum_charge.value"
            options={{
              InputLabelProps: {
                shrink: true,
              },
            }}
            disabled={disabled}
            startAdornment={currencySymbol}
            className={classes.numInput}
            variant="outlined"
          />
          <SelectInput
            className={classes.numInput}
            variant="outlined"
            options={{
              InputLabelProps: {
                shrink: true,
              },
            }}
            disabled={disabled}
            optionText={(record: any) => {
              return `${currencySymbol}/${record?.name}`
            }}
            fullWidth
            label="Units"
            name="tariffData.minimum_charge.units"
            source="tariffData.minimum_charge.units"
            choices={UNIT_OPTIONS.fixed_charges}
          />
        </div>
      </DependentInput>
      <hr className="light" />
      <h2>{translate('Pricing')}</h2>
      <div className={`${classes.row} ${classes.bottomMargin} ${classes.wrapRow}`}>
        <InputWithToolTip
          message={translateParse(
            'The frequency in which pricing tiers are reset to 0. This ONLY impact <strong>energy<strong> tiered charges.',
            {
              strong: (label: string) => <strong>{label}</strong>,
            }
          )}
        >
          <p>{translate('Price Tiering Period')}:</p>
        </InputWithToolTip>
        <RadioGroup
          name="tariffData.price_tiering_period"
          value={formState.values.tariffData.price_tiering_period}
          onChange={(e) => {
            handleChange('tariffData.price_tiering_period', e.target.value)
          }}
        >
          <div className={classes.row}>
            <div>
              <FormControlLabel
                value={'per_day'}
                control={<Radio />}
                label={translate('Per Day')}
                disabled={disabled}
              />
            </div>
            <div>
              <FormControlLabel
                value={'per_billing_period'}
                control={<Radio />}
                label={translate('Per Billing Period')}
                disabled={disabled}
              />
            </div>
          </div>
        </RadioGroup>
      </div>
      <PricingTable disabled={disabled} countryIso2={countryIso2} />
      <AdjustmentTable disabled={disabled} />
    </div>
  )
}
export default TaxDetailsFields

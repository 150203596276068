import { Box, ImagePlaceHolderIcon } from 'opensolar-ui'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { useState } from 'react'

const useStyles = makeOpenSolarStyles((theme) => ({
  image: {
    minWidth: 36,
    minHeight: 30,
    maxWidth: 75,
    maxHeight: 100,
    objectFit: 'contain',
  },
}))

const HardwareImage = ({ imageUrl }: { imageUrl?: string }) => {
  const classes = useStyles()
  const [isImageUrlBroken, setIsImageUrlBroken] = useState(false)

  const shouldShowImage = !isImageUrlBroken && imageUrl

  return (
    <Box display="flex" justifyContent="center">
      {!!shouldShowImage ? (
        <img
          src={imageUrl}
          className={classes.image}
          onError={() => {
            setIsImageUrlBroken(true)
          }}
          loading="lazy"
        />
      ) : (
        <ImagePlaceHolderIcon height="42" width="42" fill="#ADACB0" />
      )}
    </Box>
  )
}

export default HardwareImage

import { Card, MenuItem, Popper, styled } from '@material-ui/core'
import { AddIcon, Button, DeleteOutlineIcon, FormControl, IconButton } from 'opensolar-ui'
import { DetachedSelectInput, StyledSelect } from 'projectSections/sections/manage3/common/DetachedSelectInput'
import { useTranslate } from 'ra-core'
import React, { useEffect, useRef, useState } from 'react'

export const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    heigth: '100vh',
    width: '100vw',
    maxWidth: '100vw',
    padding: 0,
  },
}))

export const CardBody = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 16,
  gap: 10,
  alignItems: 'baseline',
}))

export const FilterRow = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  padding: 10,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  boxSizing: 'border-box',
  borderRadius: 8,
  gap: 8,
  flex: 1,
  [theme.breakpoints.down('sm')]: {
    alignItems: 'stretch',
    flexDirection: 'column',
    '&>*': {
      alignItems: 'stretch',
    },
  },
}))

export const FieldContainer = styled('div')(({ theme }) => ({
  minWidth: 120,
  display: 'flex',
  flex: 1,
}))

export const CardFooter = styled('div')(({ theme }) => ({
  borderTop: '1px solid' + theme.palette.grey[100],
  padding: 16,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 10,
  justifyContent: 'space-between',
  '&>:last-child': {
    gap: 10,
    display: 'flex',
  },
}))

interface FiltersAvailable {
  displayName: string
  optionsFilter: string
  optionValue: string
  optionsSource: string
}

type RAListFilter = Record<string, string[]>

interface ListPopperFilterProps {
  setListFilter: (newFilter: RAListFilter) => void
  filtersApplied: RAListFilter
  filtersAvailable: Record<string, FiltersAvailable>
  children: any
}

interface FilterSelection {
  filterType: string
  condition: 'is' | 'is not'
  value: string
}

export const ListPopperFilter: React.FC<ListPopperFilterProps> = ({
  setListFilter,
  filtersApplied,
  filtersAvailable,
  children,
}) => {
  const filterTypes: string[] = filtersAvailable ? Object.keys(filtersAvailable) : []
  const defaultFilters: FilterSelection = { filterType: filterTypes[0], condition: 'is', value: '' }

  const [filters, setFilters] = useState<FilterSelection[]>([defaultFilters])
  const [isOpen, setIsOpen] = useState<any>(false)
  const anchorRef = useRef(null)
  const translate = useTranslate()

  useEffect(() => {
    const initialFilters: any = []
    if (filtersAvailable.length) {
      Object.keys(filtersApplied).forEach((filterType) => {
        const isExclude = filterType.toLowerCase().includes('exclude')
        if (filtersApplied && filtersApplied[filterType]) {
          initialFilters.push(
            ...filtersApplied[filterType].map((filterValue) => ({
              filterType: filterType.replace('_exclude', ''),
              condition: isExclude ? 'is not' : 'is',
              value: filterValue,
            }))
          )
        }
      })
    }
    if (initialFilters.length) {
      setFilters(initialFilters)
    }
  }, [filtersApplied])

  const handleClick = (event) => {
    setIsOpen((prev) => !prev)
    if (!anchorRef.current) {
      anchorRef.current = event.currentTarget
    }
  }

  const addFilter = () => {
    setFilters([...filters, defaultFilters])
  }

  const removeFilter = (index) => {
    setFilters(filters.filter((_, i) => i !== index))
  }

  const updateFilter = (index, field, value) => {
    const newFilters = [...filters]
    newFilters[index][field] = value
    setFilters(newFilters)
  }

  const handleSubmit = () => {
    const newFilters: any = {}

    filters.forEach((filter) => {
      if (filter.value) {
        const filterName = filter.condition === 'is not' ? `${filter.filterType}_exclude` : filter.filterType
        const existingFiltersOfType = newFilters[filterName] || []
        newFilters[filterName] = [...existingFiltersOfType, filter.value]
      }
    })
    setListFilter(newFilters)
    setIsOpen(false)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleClearFilters = () => {
    setListFilter({})
    setIsOpen(false)
  }

  return (
    <>
      {React.cloneElement(children, {
        ref: anchorRef,
        onClick: (e) => {
          handleClick(e)
          if (children.props.onClick) {
            children.props.onClick(e)
          }
        },
      })}
      <Popper open={isOpen} anchorEl={anchorRef.current} style={{ zIndex: 1500 }} placement="bottom-start">
        <StyledCard>
          <CardBody>
            {filters.map((filter, index) => (
              <FilterRow key={`${index}-`}>
                <FieldContainer>
                  <FormControl fullWidth variant="outlined">
                    <StyledSelect
                      value={filter.filterType}
                      onChange={(e) => updateFilter(index, 'filterType', e.target.value)}
                      variant="outlined"
                      displayEmpty
                    >
                      {filterTypes.map((type, i) => (
                        <MenuItem key={i} value={type}>
                          {filtersAvailable[type].displayName}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </FormControl>
                </FieldContainer>
                <FieldContainer>
                  <FormControl fullWidth variant="outlined">
                    <StyledSelect
                      value={filter.condition}
                      onChange={(e) => updateFilter(index, 'condition', e.target.value)}
                      variant="outlined"
                      displayEmpty
                    >
                      <MenuItem value="is">Is</MenuItem>
                      <MenuItem value="is not">Is Not</MenuItem>
                    </StyledSelect>
                  </FormControl>
                </FieldContainer>
                <FieldContainer>
                  <FormControl fullWidth variant="outlined">
                    <DetachedSelectInput
                      onChange={(value) => updateFilter(index, 'value', value)}
                      listName={filtersAvailable[filter.filterType].optionsSource}
                      filterType={filtersAvailable[filter.filterType].optionsFilter}
                      defaultValue={filter.value}
                      disabledItems={filters.map((f) => f.value)}
                    />
                  </FormControl>
                </FieldContainer>

                <IconButton
                  onClick={() => removeFilter(index)}
                  color="error"
                  variant="squared"
                  disabled={filters.length === 1}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </FilterRow>
            ))}
            <Button
              color={'secondary'}
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={addFilter}
              fullWidth={false}
              disabled={filters.slice(-1)[0].value === ''}
            >
              {translate('Add another filter')}
            </Button>
          </CardBody>
          <CardFooter>
            <div>
              <Button color={'secondary'} variant="outlined" onClick={handleClose}>
                {translate('Cancel')}
              </Button>
            </div>
            <div>
              <Button color={'secondary'} variant="outlined" onClick={handleClearFilters}>
                {translate('Clear Filters')}
              </Button>
              <Button variant="contained" color="primary" onClick={handleSubmit}>
                {translate('Confirm')}
              </Button>
            </div>
          </CardFooter>
        </StyledCard>
      </Popper>
    </>
  )
}

import React from 'react'
import { useTranslate } from 'react-admin'
import { CalcErrorType } from 'reducer/project/projectErrorsReducer'

const parseLastCalcErrorMessage = (error: CalcErrorType) => {
  //in theory there is only one last calc error at a time
  const last_calculation_error = error.message
  var parts: string[] = []
  for (let field in last_calculation_error) {
    if (last_calculation_error[field].length) {
      // error is simple string (not the standard case, but may occur)
      parts.push(field + ': ' + last_calculation_error[field])
    } else {
      // error is array of messages
      last_calculation_error[field].forEach((message: any) => parts.push(field + ': ' + message))
    }
  }
  return parts.length ? (
    <>
      {parts.map((part, index) => (
        <div key={index}>{part} </div>
      ))}
    </>
  ) : null
}

const CalcError = ({ error }: { error: CalcErrorType }) => {
  const translate = useTranslate()
  return (
    <span>
      {translate('Calculation error.')} {parseLastCalcErrorMessage(error) || translate('Unspecified error')}
    </span>
  )
}

export default CalcError

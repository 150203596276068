// @ts-nocheck
import { Grid, MenuItem } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import { authSelectors } from 'ducks/auth'
import { updateRoleMosaicEnabled } from 'ducks/auth_roles'
import { orgSelectors } from 'ducks/orgs'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import { useGetConnectedOrgBeingUsed } from 'projectSections/sections/payments/utils'
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'
import { ProjectType } from 'types/projects'
import { RoleType } from 'types/roles'
import { getIsValidEmail } from 'util/misc'

let restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  project: ProjectType | undefined
  onClose: () => void
  mosaicEmails?: string[]
  setRoleEmailNotInMosaic?: (val: boolean) => void
  onSuccess: () => void
  pendingPmtOrgId: number | undefined
  roleToShow?: RoleType | undefined
}

const MosaicConnectRoleDialogContent: React.FunctionComponent<PropTypes> = (props) => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const notify = useNotify()
  const role: RoleType = useSelector(authSelectors.getCurrentRole)
  const user = useSelector(authSelectors.getCurrentUser)
  const thisRolesOrg = useSelector(orgSelectors.getOrg)

  const orgId = props.pendingPmtOrgId ? props.pendingPmtOrgId : props.project?.org_id

  const [roleEmailToUse, setRoleEmailToUse] = useState<string | undefined>(undefined)
  const [roleErrorMsg, setRoleErrorMsg] = useState<string | undefined>(undefined)
  const [checkingEmail, setCheckingEmail] = useState<boolean>(false)
  const [mode, setMode] = useState<'existing' | 'create'>('existing')
  const [mosaicEmails, setMosaicEmails] = useState<string[]>(props.mosaicEmails || [])

  //for creating new users
  const [newUserEmail, setNewUserEmail] = useState<string | undefined>(
    props.roleToShow ? props.roleToShow?.email : user?.email
  )
  const [newUserFirstName, setNewUserFirstName] = useState<string | undefined>(
    props.roleToShow?.first_name || role?.first_name
  )
  const [newUserLastName, setNewUserLastName] = useState<string | undefined>(
    props.roleToShow?.family_name || role?.family_name
  )
  const [newUserPhone, setNewUserPhone] = useState<string | undefined>(
    props.roleToShow ? props.roleToShow.phone : role?.phone
  )
  const [newUserSaving, setNewUserSaving] = useState<boolean>(false)
  const [newUserErrorMsg, setNewUserErrorMsg] = useState<string | undefined>(undefined)

  const connectedOrgBeingUsed = useGetConnectedOrgBeingUsed(thisRolesOrg, orgId)

  const showTextInput = useMemo(() => {
    return connectedOrgBeingUsed || mosaicEmails?.length > 50
  }, [connectedOrgBeingUsed, mosaicEmails])

  useEffect(() => {
    if (props.mosaicEmails === undefined) checkMosaicEmail(undefined, true)
  }, [props.mosaicEmails])

  const checkMosaicEmail = useCallback(
    (emailToUse?: string, justPopulateList: boolean = false) => {
      setCheckingEmail(true)
      let roleToUse = props.roleToShow?.id || role.id
      const url = emailToUse
        ? 'orgs/' + orgId + '/roles/' + roleToUse + '/mosaic/has_login/?email=' + emailToUse
        : 'orgs/' + orgId + '/roles/' + roleToUse + '/mosaic/has_login/'

      restClientInstance('CUSTOM_GET', 'custom', {
        url: url,
      })
        .then((res) => {
          if (justPopulateList) {
            setMosaicEmails(res.data.mosaic_emails)
          } else if (res.data.success) {
            dispatch(updateRoleMosaicEnabled(true, role?.id))
            if (props.setRoleEmailNotInMosaic) props.setRoleEmailNotInMosaic(false)
            else props.onClose()
            props.onSuccess()
          } else {
            if (emailToUse)
              setRoleErrorMsg(
                "No active users in this company's Mosaic account have this email address. Please either create a new user below or use a different email address."
              )
            props.setRoleEmailNotInMosaic(true)
            setMosaicEmails(res.data.mosaic_emails)
          }
        })
        .catch((err) => console.log('err', err))
        .finally(() => setCheckingEmail(false))
    },
    [orgId]
  )

  const verifyInputEmail = () => {
    if (!roleEmailToUse) {
      setRoleErrorMsg('Please provide an email address')
      return
    } else if (!getIsValidEmail(roleEmailToUse)) {
      setRoleErrorMsg('Please provide a valid email address')
      return
    }
    checkMosaicEmail(roleEmailToUse)
  }

  const createNewUser = useCallback(() => {
    if (!newUserEmail) setNewUserErrorMsg(translate('Email is required'))
    else if (!getIsValidEmail(newUserEmail)) setNewUserErrorMsg(translate('Email is invalid'))
    else if (!newUserFirstName) setNewUserErrorMsg(translate('First Name is invalid'))
    else if (!newUserLastName) setNewUserErrorMsg(translate('Last Name is invalid'))
    else if (!newUserPhone) setNewUserErrorMsg(translate('Phone Number is invalid'))
    else if (newUserPhone.replace(/[^0-9]/g, '')?.length !== 10)
      setNewUserErrorMsg(translate('Phone Number must have 10 digits'))
    else {
      let roleToUse = props.roleToShow?.id || role.id
      const url = 'orgs/' + orgId + '/roles/' + roleToUse + '/mosaic/mosaic_user/'
      setNewUserSaving(true)
      restClientInstance('CUSTOM_POST', 'custom', {
        url: url,
        data: {
          email: newUserEmail,
          phone: newUserPhone.replace(/[^0-9]/g, ''),
          first_name: newUserFirstName,
          last_name: newUserLastName,
        },
      })
        .then((res: any) => {
          if (res.data.success) {
            dispatch(updateRoleMosaicEnabled(true, role?.id))
            notify(translate('Mosaic User created, you can now use Mosaic Payment Options', 'success'))
            props.onClose()
            props.onSuccess()
          } else {
            setNewUserErrorMsg(translate('Sorry, something went wrong and we were unable to create a new Mosaic user'))
          }
        })
        .catch((err: any) => {
          if (err?.body?.message) setNewUserErrorMsg(err.body.message)
          else
            setNewUserErrorMsg(translate('Sorry, something went wrong and we were unable to create a new Mosaic user'))
        })
        .finally(() => setNewUserSaving(false))
    }
  }, [orgId])

  const descriptionText = useMemo(() => {
    if (props.roleToShow) {
      if (connectedOrgBeingUsed) {
        return `In order to sell Mosaic on behalf of ${connectedOrgBeingUsed.org_name}, please let us know the email address that ${props.roleToShow.display} uses to login to ${connectedOrgBeingUsed.org_name}'s Mosaic account`
      } else {
        return `In order to sell Mosaic on OpenSolar, please let us know the email address that ${props.roleToShow.display} uses to login to your company's Mosaic account`
      }
    } else {
      if (connectedOrgBeingUsed) {
        return `In order to sell Mosaic on behalf of ${connectedOrgBeingUsed.org_name}, please let us know the email address that you use to login to ${connectedOrgBeingUsed.org_name}'s Mosaic account`
      } else {
        return "In order to sell Mosaic on OpenSolar, please let us know the email address that you use to login to your company's Mosaic account"
      }
    }
  }, [connectedOrgBeingUsed, props.roleToShow])

  const labelText = useMemo(() => {
    if (connectedOrgBeingUsed) {
      return ''
    } else {
      return "Select an email from your company's Mosaic account"
    }
  }, [connectedOrgBeingUsed])

  const newUserDescriptionText = useMemo(() => {
    if (props.roleToShow) {
      return `If ${props.roleToShow.display} does not have a login to their Mosaic account, you can create a new user below.`
    } else {
      return 'If you do not have a login to your Mosaic account, you can create a new user below.'
    }
  }, [connectedOrgBeingUsed, props.roleToShow])

  const textFieldLabel = useMemo(() => {
    if (connectedOrgBeingUsed) {
      if (props.roleToShow)
        return `Email Address that ${props.roleToShow.display} uses for their ${connectedOrgBeingUsed?.org_name} account with Mosaic`
      else return `Your Mosaic Account Email Address for ${connectedOrgBeingUsed?.org_name}`
    } else {
      if (props.roleToShow) return `Email Address for ${props.roleToShow.display} account`
      else return 'Your Mosaic Account Email Address'
    }
  }, [props.roleToShow, connectedOrgBeingUsed])

  return (
    <div
      style={{
        width: '90%',
        height: '90%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5%',
      }}
    >
      <Grid container spacing={3}>
        {mode === 'existing' && (
          <>
            <Grid item xs={12}>
              <div>{descriptionText}</div>
            </Grid>
            <Grid item xs={12}>
              <>
                <InputLabel id="mosaic-email-label" style={{ marginTop: '20px' }}>
                  {labelText}
                </InputLabel>
                {showTextInput ? (
                  <TextField
                    value={roleEmailToUse}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setRoleEmailToUse(e.target.value)}
                    label={textFieldLabel}
                    fullWidth
                  />
                ) : (
                  <Select
                    label={
                      props.roleToShow
                        ? `Mosaic Account Email Address for ${props.roleToShow.display}`
                        : 'Your Mosaic Account Email Address'
                    }
                    onChange={(e: any) => setRoleEmailToUse(e.target.value)}
                    fullWidth
                    style={{ width: '100%' }}
                  >
                    {mosaicEmails &&
                      mosaicEmails.map((em) => {
                        return (
                          <MenuItem key={em} value={em}>
                            {em}
                          </MenuItem>
                        )
                      })}
                  </Select>
                )}
              </>
            </Grid>
            {roleErrorMsg && (
              <Grid item xs={12}>
                <div style={{ color: 'red' }}>{roleErrorMsg}</div>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              style={{
                margin: '20px 0px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ProUXButton
                label="Save Email Address"
                type="primary"
                onClick={verifyInputEmail}
                disabled={!roleEmailToUse}
                loading={checkingEmail}
              />
              <div
                style={{
                  borderBottom: '1px solid #ececec',
                  width: '200px',
                  height: '10px',
                  position: 'relative',
                  margin: '15px 0px',
                  textAlign: 'center',
                }}
              >
                <span
                  style={{
                    width: '50px',
                    height: '16px',
                    position: 'absolute',
                    left: 'calc(50% - 25px)',
                    bottom: '-8px',
                    backgroundColor: '#fff',
                  }}
                >
                  OR
                </span>
              </div>
              <p style={{ margin: '15px 0px 5px 0px' }}>{newUserDescriptionText}</p>
              <a
                onClick={() => setMode('create')}
                style={{ marginTop: '15px', cursor: 'pointer', textDecoration: 'underline' }}
              >
                {translate('Make a new Mosaic user')}
              </a>
            </Grid>
          </>
        )}
        {mode === 'create' && (
          <>
            <Grid item xs={12}>
              <div>
                {`Clicking the button below will create a new Mosaic Salesperson user and will allow ${
                  props.roleToShow ? props.roleToShow.display : 'you'
                } to send Mosaic credit applications in OpenSolar. If salesperson is not the appropriate role you can change the role of this new user on Mosaic's site once the user is created.`}
              </div>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="First Name"
                fullWidth
                value={newUserFirstName}
                onChange={(e) => setNewUserFirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Last Name"
                fullWidth
                value={newUserLastName}
                onChange={(e) => setNewUserLastName(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Email Address"
                fullWidth
                value={newUserEmail}
                onChange={(e) => setNewUserEmail(e.target.value)}
                type="email"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Phone Number"
                fullWidth
                value={newUserPhone}
                onChange={(e) => setNewUserPhone(e.target.value)}
                type="tel"
              />
            </Grid>
            {roleErrorMsg && (
              <Grid item xs={12}>
                <div style={{ color: 'red' }}>{roleErrorMsg}</div>
              </Grid>
            )}
            {newUserErrorMsg && (
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ color: 'red', textAlign: 'center' }}>{newUserErrorMsg}</div>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              style={{
                margin: '20px 0px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ProUXButton
                label="Create Mosaic User"
                type="primary"
                onClick={createNewUser}
                disabled={!newUserFirstName || !newUserLastName || !newUserEmail || !newUserPhone}
                loading={newUserSaving}
              />
              <div
                style={{
                  borderBottom: '1px solid #ececec',
                  width: '200px',
                  height: '10px',
                  position: 'relative',
                  margin: '15px 0px',
                  textAlign: 'center',
                }}
              >
                <span
                  style={{
                    width: '50px',
                    height: '16px',
                    position: 'absolute',
                    left: 'calc(50% - 25px)',
                    bottom: '-8px',
                    backgroundColor: '#fff',
                  }}
                >
                  OR
                </span>
              </div>
              <a onClick={() => setMode('existing')} style={{ marginTop: '15px', cursor: 'pointer' }}>
                {translate('Connect an Existing Mosaic User')}
              </a>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  )
}
export default MosaicConnectRoleDialogContent

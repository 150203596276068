import { Divider } from '@material-ui/core'
import { useCountryListsTranslated } from 'hooks/useCountryListsTranslated'
import { Button, EmailIcon, Grid, MessageCircleIcon, PhoneCallIcon, styled, Typography } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { useMemo } from 'react'

const ContactName = styled(Grid)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}))

const ContactInfo = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  color: '#757575',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}))

const TooltipActions = styled(Grid)(({ theme }) => ({
  alignItems: 'flex-start',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}))

const StyledIconButtons = styled(Button)({
  color: '#000000 !important',
  width: 80,
  height: 25,
  textAlign: 'left',
  justifyContent: 'flex-start !important',
  margin: '4px 0px 4px 0px !important',
})
const countriesExcludePrefix = ['NZ'] //exclude NZ from adding '+' phone prefix
const CustomerBadgeTooltipContent = ({ activeContactInfo }) => {
  const translate = useTranslate()
  const { countriesAll } = useCountryListsTranslated()
  const name = `${activeContactInfo?.first_name} ${activeContactInfo?.family_name}`
  const email = activeContactInfo?.email
  const phone = useMemo(() => {
    let prefix = ''
    if (activeContactInfo?.custom_data?.os_meta?.phone_country_iso2) {
      const country = countriesAll.find((x) => x.iso2 === activeContactInfo?.custom_data?.os_meta?.phone_country_iso2)
      if (country) prefix = `${countriesExcludePrefix.includes(country.iso2) ? '' : '+'}${country.prefix}`
    }
    if (activeContactInfo.phone) {
      return `${prefix}${activeContactInfo.phone}`
    }
    return ``
  }, [activeContactInfo])
  return (
    <Grid container direction="column">
      <ContactName item>
        <Typography variant="body1">{name}</Typography>
      </ContactName>
      <ContactInfo item>
        <Typography variant="body2">
          {email} {email && phone && <span>&bull;</span>} {phone}
        </Typography>
      </ContactInfo>
      <Divider />
      <TooltipActions item container direction="column">
        {email && (
          <Grid item>
            <StyledIconButtons
              onClick={() => (window.location.href = 'mailto:' + email)}
              size="small"
              startIcon={<EmailIcon color="#757575" />}
            >
              <span style={{ fontSize: '0.75rem', marginLeft: 5, color: 'black !important' }}>
                {translate('Email')}
              </span>
            </StyledIconButtons>
          </Grid>
        )}
        {phone && (
          <>
            <Grid item>
              <StyledIconButtons
                onClick={() => (window.location.href = `tel:${phone}`)}
                size="small"
                startIcon={<PhoneCallIcon color="#757575" />}
              >
                <span style={{ fontSize: '0.75rem', marginLeft: 5, color: 'black !important' }}>
                  {translate('Call')}
                </span>
              </StyledIconButtons>
            </Grid>
            <Grid item>
              <StyledIconButtons
                onClick={() => (window.location.href = `sms:${phone}`)}
                size="small"
                startIcon={<MessageCircleIcon color="#757575" />}
              >
                <span style={{ fontSize: '0.75rem', marginLeft: 5, color: 'black !important' }}>
                  {translate('Message')}
                </span>
              </StyledIconButtons>
            </Grid>
          </>
        )}
      </TooltipActions>
    </Grid>
  )
}
export default CustomerBadgeTooltipContent

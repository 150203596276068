import { Divider, Popover, TextField } from '@material-ui/core'
import { AddOutlined } from '@material-ui/icons'
import { Button, Chip } from 'opensolar-ui'
import { RoleWithDisplay } from 'projectSections/sections/manage/assignment/types'
import { useDataProvider } from 'ra-core'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { ProjectType } from 'types/projects'
import { getIsValidEmail } from 'util/misc'

type PropTypes = {
  project: ProjectType
}

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px 0px 0px 0px',
    position: 'relative',
    flexWrap: 'wrap',
    gap: 10,
    marginBottom: 10,
  },
  label: {
    position: 'absolute',
    left: '10px',
    top: '-8px',
    padding: '0px 8px',
    backgroundColor: 'white',
    fontSize: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  input: {
    borderRadius: '4px',
    border: '2px solid #e7e7e7',
    padding: '10px 14px',
    width: '100%',
    marginRight: '10px',
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '500px',
    minHeight: '32px',
  },
  select: {
    display: 'flex',
    justifyContent: 'center',
  },
  addIcon: {
    cursor: 'pointer',
    border: '1px solid #e7e7e7',
    borderRadius: '15px',
    color: theme.greyMid1,
  },
  popoverContainer: {
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
  popoverEmailRow: {
    padding: '5px',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.5,
    },
  },
  freeTextRow: {
    marginTop: '10px',
  },
  chipWrapper: {
    margin: '2px 8px 2px 0px',
  },
}))

type RoleSelectOptionType = {
  email: string
  name: string
}

const InvoiceRecipientField: React.FC<PropTypes> = (props) => {
  const [showPopover, setShowPopover] = useState<boolean>(false)
  const [avalableRoles, setAvailableRoles] = useState<RoleSelectOptionType[]>([])
  const [emailFreeText, setEmailFreeText] = useState<string | undefined>(undefined)
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const formVals = useFormState().values
  const form = useForm()
  const popoverRef = useRef<HTMLDivElement>(null)

  const openPopover = () => {
    setShowPopover(true)
  }

  const addEmail = (emailAddress) => {
    form.change('bcc', [...(formVals?.bcc || []), emailAddress])
    setShowPopover(false)
  }

  const removeEmail = (emailAddress) => {
    form.change(
      'bcc',
      formVals?.bcc?.filter((email) => email !== emailAddress)
    )
  }

  const addFreeTextEmail = () => {
    addEmail(emailFreeText)
    setEmailFreeText(undefined)
  }

  const fetchRoles = () => {
    dataProvider
      .getList('roles', {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'display', order: 'DESC' },
        filter: { shared_project_id: props.project?.id, search: undefined },
      })
      .then((res) => {
        let responseData = res.data as RoleWithDisplay[]
        let rolesWithAnEmail = responseData.filter((role) => role.email || role.user_email)
        setAvailableRoles(
          rolesWithAnEmail?.map((role) => ({ email: role.email || role.user_email, name: role.display }))
        )
      })
      .catch((err) => setAvailableRoles([]))
  }

  useEffect(() => {
    if (props.project?.id) {
      fetchRoles()
    }
  }, [props.project?.id])

  const emailFreeTextIsValid = useMemo(() => {
    return Boolean(emailFreeText) && getIsValidEmail(emailFreeText)
  }, [emailFreeText])

  const availableEmails = useMemo(() => {
    let options: RoleSelectOptionType[] = []
    props.project?.contacts_data?.forEach((contact) => {
      if (
        contact.type === 0 &&
        contact.email &&
        !formVals?.to?.includes(contact.email) &&
        !formVals?.bcc?.includes(contact.email)
      ) {
        let contactName = contact.email
        if (contact.first_name) {
          contactName = contact.first_name
          if (contact.family_name) {
            contactName += ` ${contact.family_name}`
          }
        }
        options.push({ email: contact.email, name: contact.first_name || contact.email })
      }
    })
    avalableRoles?.forEach((role) => {
      if (!formVals?.to?.includes(role.email) && !formVals?.bcc?.includes(role.email)) {
        options.push(role)
      }
    })
    return options
  }, [avalableRoles, formVals?.to, formVals?.bcc, props.project?.contacts_data])

  return (
    <div className={classes.wrapper}>
      <div className={classes.label}>bcc</div>
      <div className={classes.input}>
        {formVals?.bcc?.map((email, i) => (
          <div className={classes.chipWrapper}>
            <Chip key={email} label={email} onDelete={() => removeEmail(email)} />
          </div>
        ))}
      </div>
      <div className={classes.select} ref={popoverRef}>
        <Button onClick={openPopover} variant="contained">
          <AddOutlined className={classes.addIcon} /> Add Recipient
        </Button>
        <Popover
          id={'invoice-email-recipients-popover'}
          open={showPopover}
          anchorEl={popoverRef?.current}
          onClose={() => setShowPopover(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <div className={classes.popoverContainer}>
            <div>
              {availableEmails?.length ? (
                <>
                  <div className="small">Choose from project</div>
                  {availableEmails?.map((role) => (
                    <div key={role.email} className={classes.popoverEmailRow} onClick={() => addEmail(role.email)}>
                      {role?.name || role.email}
                    </div>
                  ))}
                </>
              ) : null}
              {availableEmails?.length ? <Divider /> : null}

              <div className={classes.freeTextRow}>
                {availableEmails?.length ? <div className="small">Or enter an email address</div> : null}
                <TextField onChange={(e) => setEmailFreeText(e.target.value)} value={emailFreeText} />
                <Button size="small" onClick={addFreeTextEmail} disabled={!emailFreeTextIsValid}>
                  Add
                </Button>
              </div>
            </div>
          </div>
        </Popover>
      </div>
    </div>
  )
}

export default InvoiceRecipientField

import { Dialog, styled } from 'opensolar-ui'
import React from 'react'
import { PrivateFileType } from 'types/privateFile'
import { ImagePreviewer } from '../../../../../../common/ImagePreviewer'
import { PdfRenderer } from '../../../../../../common/PdfRenderer'
import { getFileExtension } from '../utils'

const StyledDialog = styled(Dialog)(({ theme }) => ({
  overflow: 'hidden !important',
  width: '100%',
  '& .MuiDialog-paper': {
    width: 'auto',
    maxHeight: '100%',
  },
}))

const StyledDialogContent = styled('div')(({ theme }) => ({
  padding: '0px',
  display: 'flex',
}))

type PropTypes = {
  record: PrivateFileType
  isOpen: boolean
  onDismiss: () => void
}

export const PreviewDialog: React.FunctionComponent<PropTypes> = ({ record, isOpen, onDismiss }) => {
  const isPdf = 'pdf' === getFileExtension(record)

  return !isPdf ? (
    <ImagePreviewer isOpen={isOpen} onClose={onDismiss} src={record.file_contents} />
  ) : (
    <StyledDialog open={isOpen} onClose={onDismiss}>
      <StyledDialogContent>
        <PdfRenderer pdfUrl={`${record?.file_contents}`} />
      </StyledDialogContent>
    </StyledDialog>
  )
}

export const TARIFF_FIELDS = [
  'utility_tariff_current_custom',
  'utility_tariff_proposed_custom',
  'utility_tariff_current_data',
  'utility_tariff_proposed_data',
]

export const parseTariffData = (data) => {
  if (typeof data === 'string') {
    try {
      return JSON.parse(data)
    } catch (e) {
      return {}
    }
  } else {
    return data
  }
}

const parseTariffInitialValues = (formValsOnSave: any, returnedVals: any = {}) => {
  const newInitialValues: any = formValsOnSave
  TARIFF_FIELDS.forEach((tariff) => {
    const tariffData = newInitialValues[tariff]
    if (tariffData) newInitialValues[tariff] = parseTariffData(tariffData)
  })
  return newInitialValues
}

export default parseTariffInitialValues

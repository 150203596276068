import React, { useEffect, useState } from 'react'
import { Box, Grid, IconButton, TextField, AddIcon, MinusIcon, styled } from 'opensolar-ui'
import { useGlobalCart } from '../../../hooks/useGlobalCart'

const QuantityButtonContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '36px',
  borderRadius: '6px',
  color: 'white',
  overflow: 'hidden',
}))

const QuantityBox = styled(Grid)(() => ({
  borderTop: `1px solid #CDCDCD`,
  borderBottom: `1px solid #CDCDCD`,
  height: '36px',
  '& p': {
    textAlign: 'center',
    fontSize: '18px',
  },
}))

const StyledIconButton = styled(IconButton)(() => ({
  background: '#4272DD',
  color: 'white',
  padding: 0,
  height: '36px',
  width: '36px',
  borderRadius: 0,
  '&:hover': {
    background: '#113B98',
  },
}))

const StyledTextField = styled(TextField)(() => ({
  width: '100%',
  textAlign: 'center',
  border: 'none',
  background: 'transparent',
  color: 'white',
  padding: '0',

  '& .OSUI-OutlinedInput-notchedOutlinenotchedOutline': {
    border: 'none',
  },
  '&:hover .OSUI-OutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .Mui-focused .OSUI-OutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .OSUI-InputBase-root': {
    background: 'transparent',
  },
  '& fieldset': {
    border: 'none', // Remove the default border
  },
  '& .MuiInput-root': {
    fontSize: '18px',
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before ': {
    borderBottom: 'none',
  },
}))

const QuantityButton = ({
  handleZeroQuantity,
  uuid,
  initialValue,
}: {
  handleZeroQuantity: () => void
  uuid?: string
  initialValue?: number
}) => {
  const { updateLineItemQty } = useGlobalCart()
  const [quantity, setQuantity] = useState(1)

  useEffect(() => {
    if (uuid) {
      updateLineItemQty(uuid, quantity)
    }
  }, [quantity])

  useEffect(() => {
    if (initialValue) {
      setQuantity(initialValue)
    }
  }, [initialValue])

  const handleQuantityChange = (amount) => {
    setQuantity((prev) => {
      const newQuantity = Math.max(0, prev + amount)
      if (newQuantity === 0 && handleZeroQuantity) {
        handleZeroQuantity()
      }
      return newQuantity
    })
  }

  const handleInputChange = (event) => {
    const value = event.target.value

    if (value === '') {
      setQuantity(0)
      return
    }

    if (Number(value) === 0) {
      handleZeroQuantity()
      return
    }

    if (/^[0-9]+$/.test(value)) {
      setQuantity(Number(value))
    }
  }

  const handleBlur = () => {
    if (quantity === 0) {
      setQuantity(1)
    }
  }

  return (
    <QuantityButtonContainer>
      <Grid container direction="row" justify="space-between" alignItems="center" wrap="nowrap">
        <Grid item>
          <StyledIconButton onClick={() => handleQuantityChange(-1)}>
            <MinusIcon />
          </StyledIconButton>
        </Grid>
        <QuantityBox container item justify="center" alignContent="center" xs={10}>
          <StyledTextField
            value={quantity}
            onChange={handleInputChange}
            variant="outlined"
            onBlur={handleBlur}
            inputProps={{
              min: 0,
              border: 'none',
              style: { textAlign: 'center' },
            }}
          />
        </QuantityBox>
        <Grid item>
          <StyledIconButton onClick={() => handleQuantityChange(1)}>
            <AddIcon />
          </StyledIconButton>
        </Grid>
      </Grid>
    </QuantityButtonContainer>
  )
}

export default QuantityButton

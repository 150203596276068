import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import ShopSection from './ShopSection'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  container: {
    backgroundColor: theme.white,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    overflow: 'hidden',
  },
  font: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  accordion: {
    border: 'none !important',
    borderRadius: 0,
    margin: '0 !important',
    '& .MuiAccordionDetails-root': {
      paddingBottom: 16,
    },
    '& .MuiAccordionSummary-content': {
      position: 'relative',
      '& span': {
        fontWeight: 500,
        fontSize: '1rem',
      },
    },
  },
}))

const ShopAccordion = ({ project }: { project: Record<string, any> }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <AccordionCard
        name={'hardware'}
        defaultExpanded={true}
        title={'Hardware'}
        titleIcon={LocalShippingOutlinedIcon}
        render={() => <ShopSection project={project} />}
        lazyRender={true}
        className={classes.accordion}
        elevation={0}
      />
    </div>
  )
}

export default ShopAccordion

// @ts-nocheck
import { HelpLink } from 'elements/link/HelpLink'
import React from 'react'
import { useTranslate } from 'react-admin'
import { BatterySchemesCreate, BatterySchemesEdit, BatterySchemesList } from './BatterySchemes'

type PropsType = {
  record: any
  translate: Function
  style?: any
}

const PricingSchemeTitle = ({ record, translate, style }: PropsType) => {
  return (
    <span style={style}>
      {translate('Battery Scheme')}: {record ? `"${record.name}"` : ''}
    </span>
  )
}

export const Subtitle = () => {
  const translate = useTranslate()
  return (
    <div>
      {translate(
        'Create your own battery control schemes which allows you to control when to charge/discharge your battery. These battery control schemes can be applied in the Design page of your project when a battery is added.'
      )}
      <HelpLink href="https://support.opensolar.com/hc/en-us/articles/7875451066511" />
    </div>
  )
}

const CreatePricingSchemeTitle = ({ record, translate, style }: PropsType) => {
  return <span style={style}>{translate('Create Battery Scheme')}</span>
}

export default {
  list: BatterySchemesList,
  edit: BatterySchemesEdit,
  create: BatterySchemesCreate,
  options: {
    create: {
      title: <CreatePricingSchemeTitle />,
      subtitle: null,
      breadCrumb: 'Create Battery Scheme',
    },
    list: {
      title: 'Battery Control Scheme',
      subtitle: <Subtitle />,
      breadCrumb: 'Battery Schemes',
    },
    edit: {
      title: <PricingSchemeTitle />,
      subtitle: null,
      breadCrumb: 'Edit Battery Scheme',
    },
  },
}

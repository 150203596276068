import { Grid } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import LaunchIcon from '@material-ui/icons/LaunchOutlined'
import SharedEntitySelectInput from 'elements/field/SharedEntitySelectInput'
import DateInput from 'elements/input/DateInput'
import { Button } from 'opensolar-ui'
import { BooleanInput, ReferenceInput, SelectInput, useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'
import { NavLink } from 'react-router-dom'
import { ProjectType } from 'types/projects'
import { dateParser, systemNameOrDefaultWithSize, urlToId } from '../../../../util/misc'
import { formSharingParams } from '../orgSharing/util'
import { useStyles } from './ChangeOrderContainer'

export const ChangeOrderForm = ({ orgId, changeOrderIndex, disableFields }) => {
  const formState = useFormState<ProjectType>()
  const project = formState.values
  const classes = useStyles()
  const translate = useTranslate()
  const { values } = useFormState()
  const changeOrder = values.change_orders?.[changeOrderIndex]
  const isAllowReviewActive = changeOrder?.allow_review
  const projectSystems = project.systems ?? []
  const hasValidSystems = project.change_orders.length <= projectSystems.length - 1
  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <Grid container>
          <Grid item md={8}>
            <ReferenceInput
              reference="document_templates"
              name={`change_orders[${changeOrderIndex}].document_template`}
              source={`change_orders[${changeOrderIndex}].document_template`}
              filter={formSharingParams(orgId!, window.WorkspaceHelper.project, { type: 8 })}
              label="Change Order Template"
              disabled={disableFields}
            >
              <SelectInput
                optionText="title"
                optionValue="id"
                source={`change_orders[${changeOrderIndex}].document_template`}
                required
                helperText={
                  !project.change_orders[changeOrderIndex]?.document_template
                    ? 'Please select a Change Order Document Template'
                    : null
                }
              />
            </ReferenceInput>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <DateInput
          label="Contract Date"
          source={`change_orders[${changeOrderIndex}].contract_date`}
          parse={dateParser}
          className={classes.dateInput}
          disabled={disableFields}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <ReferenceInput
          label="Sold System"
          resource="projects"
          reference="systems"
          source={`change_orders[${changeOrderIndex}].system_sold`}
          postQueryFilterFn={(q) =>
            q.id !== urlToId(project.original_system_sold ? project.original_system_sold : project.system_sold)
          }
          filter={{
            project: project.id,
            visible_to: orgId,
            has_change_order: false,
          }}
          style={{ width: '100%' }}
          sort={{ field: 'order', order: 'ASC' }}
          allowEmpty
          disabled={disableFields}
        >
          <SelectInput
            optionValue="id"
            optionText={(choice: any) => systemNameOrDefaultWithSize(choice, undefined)}
            resettable={true}
            source={`change_orders[${changeOrderIndex}].system_sold`}
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12} md={4}>
        {/* Changing Payment Options on Change Orders are not supported yet. */}
        <ReferenceInput
          label="Sold Payment Option"
          resource="projects"
          reference="payment_options"
          source="payment_option_sold"
          filter={{
            project: project.id,
            visible_to: orgId,
          }}
          sort={{ field: 'order', order: 'ASC' }}
          style={{ width: '100%' }}
          allowEmpty
          disabled={true}
        >
          <SharedEntitySelectInput optionValue="url" optionText="title" resettable={false} />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12} md={12}>
        <BooleanInput
          source={`change_orders[${changeOrderIndex}].allow_review`}
          label="Allow customers to review and accept change order in the Online Proposal page."
          labelPlacement="end"
          disabled={disableFields || !hasValidSystems || (changeOrder && changeOrder.is_complete)}
        />
      </Grid>
      <Grid item xs={12} md={12} className={classes.alert}>
        <InfoIcon style={{ marginRight: '5px', color: '#4272DD' }} />
        {translate('Your customer can view and accept all eligible systems in the Online Proposal')}
      </Grid>
      {isAllowReviewActive && (
        <Grid item xs={12} md={12} style={{ marginTop: '10px' }}>
          <NavLink to={`/projects/${values.id}/proposal`} className={classes.noUnderline}>
            <Button
              className={`${classes.noUnderline} ${classes.button}`}
              variant="contained"
              size="small"
              startIcon={<LaunchIcon />}
            >
              {translate('Review Change Order Proposal')}
            </Button>
          </NavLink>
        </Grid>
      )}
    </Grid>
  )
}

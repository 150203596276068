import type { OpenSolarThemeType } from 'Themes'
import { orgSelectors } from 'ducks/orgs'
import { permissionsSelectors } from 'ducks/permissions'
import { Grid } from 'opensolar-ui'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  header: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    // this padding is commented out since accordion functionality removed for phase 1
    // 60px for padding right accounts for placement of accordion dropdown
    // padding: '10px 60px 10px 10px',
    padding: '10px',
  },
  overflowItem: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}))

export interface HeaderPropsType {
  hiddenColumns?: Set<string>
}

const HardwareSelectorListHeader: React.FC<HeaderPropsType> = ({ hiddenColumns }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const updateSort = () => {}
  const hasEnabledDistributors = (useSelector(orgSelectors.getEnabledHardwareSuppliers)?.length ?? 0) > 0
  const isAU = useSelector(orgSelectors.getOrgIso2) === 'AU'
  const distributorPricingRolePermission = useSelector(permissionsSelectors.getPermissionByKey('distributor_pricing'))

  return (
    <Grid container alignItems="center" xs={12} className={classes.header}>
      <Grid item xs={1} style={{ paddingLeft: 20 }}>
        {translate('Item')}
      </Grid>
      <Grid item xs={2} className={classes.overflowItem}>
        {translate('Brand')}
      </Grid>
      <Grid item xs={isAU ? 4 : 3} className={classes.overflowItem}>
        {translate('Name')}
      </Grid>
      {!hiddenColumns?.has('rating') && (
        <Grid item xs={1}>
          {translate('Rating')}
        </Grid>
      )}
      {hasEnabledDistributors && distributorPricingRolePermission.allowView && (
        <>
          {!isAU && (
            <Grid item xs={1}>
              {translate('Stock')}
            </Grid>
          )}
          <Grid item xs={1} className={classes.overflowItem}>
            {translate('Price')}
          </Grid>
          <Grid item xs={2} className={classes.overflowItem}>
            {translate('Available from')}
          </Grid>
        </>
      )}
      <Grid item xs={1}></Grid>
    </Grid>
  )
}

export default HardwareSelectorListHeader

import { Dialog, DialogContent, DialogTitle, Tooltip } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import useGetCustomForms from 'customForm/hooks/useGetCustomForms'
import {
  clearDownloadUrl as clearDownloadUrlAction,
  generateDocumentSelectors,
  setGenerateDocumentDialog as setGenerateDocumentDialogAction,
  setOpenDialog,
} from 'ducks/generateDocument'
import Alert from 'elements/Alert'
import { IconButton } from 'opensolar-ui'
import React, { useMemo, useRef, useState } from 'react'
import { useTranslate } from 'react-admin'
import { Form, useFormState } from 'react-final-form'
import { connect, useDispatch, useSelector } from 'react-redux'
import { FormPlacementEnum } from 'resources/customForms/types'
import ChangeOrderPlaceholders from 'resources/projects/elements/documentGeneration/ChangeOrderPlaceholders'
import GenerateDocumentButtons from 'resources/projects/elements/documentGeneration/GenerateDocumentButtons'
import { GenerateDocumentInitialDialog } from 'resources/projects/elements/documentGeneration/GenerateDocumentInitialDialog'
import { GenerateDocumentInputDialog } from 'resources/projects/elements/documentGeneration/GenerateDocumentInputDialog'

const GenerateDocumentDialog = (props) => {
  const translate = useTranslate()
  const formValues = useFormState().values
  const selectedDocumentTemplateId = formValues?.document_template_id
  const saveCustomFormRef = useRef()
  const customSubFormRef = useRef()
  const isDocusign = useSelector((state) => state.generateDocument.dialogOptions.isDocusign)

  const openDialog = useSelector((state) => state.generateDocument.dialogOptions.openDialog)
  const [docId, setDocId] = useState()
  // const [customFormsForDoc, setCustomFormsForDoc] = useState([])
  const doc = useSelector(generateDocumentSelectors.getDocById)(docId)
  const customForms = useGetCustomForms({
    location: FormPlacementEnum.before_document_generation,
    entityId: selectedDocumentTemplateId,
    omitNoEntityId: true,
  })
  const dispatch = useDispatch()

  const closeDialog = () => {
    setDocId(undefined)
    props.setGenerateDocumentDialog(false)
    dispatch(setOpenDialog('initial'))
  }
  const showDocuSignInfo = useMemo(() => {
    if (formValues.document_type === 'change_order') return isDocusign && openDialog === 'CO-input'
    else return isDocusign
  }, [formValues.document_type, isDocusign, openDialog])

  return (
    <Dialog
      autoDetectWindowHeight={true}
      actions={null}
      open={props.isOpen}
      onBackdropClick={closeDialog}
      maxWidth={openDialog === 'CO-input' ? 'xl' : undefined}
    >
      <DialogTitle>{translate('Generate Document')}</DialogTitle>
      <IconButton
        id="GenerateDialogClose"
        style={{
          top: 10,
          right: 10,
          position: 'absolute',
          minWidth: 40,
          backgroundColor: 'rgba(255, 255, 255, 0)',
        }}
        onClick={closeDialog}
      >
        <Tooltip title={translate('Close Dialog')} enterDelay={300}>
          <CloseIcon style={{ color: '#000000' }} />
        </Tooltip>
      </IconButton>
      {doc?.status === 'failure' && (
        <Alert severity="error" styles={{ margin: '0 20px' }}>
          {translate('Document Generation Unsuccessful.') + ' ' + (doc?.error?.length > 0 ? doc?.error : '')}
        </Alert>
      )}
      <DialogContent>
        {openDialog === 'initial' && (
          <GenerateDocumentInitialDialog
            clearDownloadUrl={props.clearDownloadUrl}
            {...props}
            closeDialog={closeDialog}
            doc={doc}
          />
        )}
        {openDialog === 'CO-input' && <ChangeOrderPlaceholders />}
        {openDialog === 'input' && (
          <GenerateDocumentInputDialog
            parentForm={props.projectForm}
            customFormsForDoc={customForms}
            projectFormDirtyFields={props.projectFormDirtyFields}
            saveRef={saveCustomFormRef}
            formRef={customSubFormRef}
          />
        )}
        {showDocuSignInfo && (
          <Alert severity="info">
            {translate(
              "You've selected a Docusign template. Clicking the button below will email a Docusign envelope to all recipients. Please note that changes to the system design will not invalidate this document."
            )}
          </Alert>
        )}
      </DialogContent>
      <GenerateDocumentButtons
        {...props}
        setDocId={setDocId}
        docId={docId}
        doc={doc}
        hasForms={!!customForms.length}
        saveCustomFormRef={saveCustomFormRef}
        customSubFormRef={customSubFormRef}
      />
    </Dialog>
  )
}

const FormWrapperGenerateDocumentDialog = (props) => {
  if (!props.isOpen) return null
  return (
    <Form
      onSubmit={() => {}}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          <GenerateDocumentDialog {...props} />
        </form>
      )}
    />
  )
}

export default connect(
  (state) => ({
    isOpen: state.generateDocument ? state.generateDocument.isOpen : false,
  }),
  {
    setGenerateDocumentDialog: setGenerateDocumentDialogAction,
    clearDownloadUrl: clearDownloadUrlAction,
  }
)(FormWrapperGenerateDocumentDialog)

import { Box } from 'opensolar-ui'
import useCommonStyles from './useCommonStyles'

const EmptyColumnItem: React.FC = () => {
  const commonClasses = useCommonStyles()

  return (
    <Box className={commonClasses.textsContainer} display="flex" flexDirection="column">
      <span style={{ color: '#0A090B' }}>&ndash;</span>
    </Box>
  )
}

export default EmptyColumnItem

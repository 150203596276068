var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export var FileCheckOutlineIcon = function (_a) {
    var variant = _a.variant, _b = _a.size, size = _b === void 0 ? '1rem' : _b, _c = _a.color, color = _c === void 0 ? 'currentColor' : _c, rest = __rest(_a, ["variant", "size", "color"]);
    var iconAttributes = {
        width: size,
        height: size,
        stroke: color,
    };
    switch (variant) {
        case 1:
            return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 22 22", fill: "none" }, rest, iconAttributes, { children: _jsx("path", { d: "M8.89922 20.6H4.09921C2.77372 20.6 1.69921 19.5254 1.69922 18.2L1.69931 3.80001C1.69932 2.47453 2.77383 1.40002 4.09931 1.40002H14.8996C16.2251 1.40002 17.2996 2.47454 17.2996 3.80002V10.4M12.4996 17.2L14.6996 19.4L20.2996 13.4", stroke: color, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }) })));
        case 2:
            return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 22", fill: "none" }, rest, iconAttributes, { children: _jsx("path", { d: "M8.29961 20.6H3.4996C2.17411 20.6 1.0996 19.5254 1.09961 18.2L1.0997 3.80001C1.09971 2.47453 2.17422 1.40002 3.4997 1.40002H14.3C15.6255 1.40002 16.7 2.47454 16.7 3.80002V10.4M11.9 17.2L14.1 19.4L18.9 14.5998M5.29998 6.20002H12.5M5.29998 9.80002H12.5M5.29998 13.4H8.89998", stroke: color, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }) })));
    }
};

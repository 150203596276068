import { Dialog } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import LaunchPaymentsOnboardingDialog from 'elements/paymentsOnboarding/LaunchPaymentsOnboardingDialog'
import UnderReview from 'elements/paymentsOnboarding/decisionDisplayPage/UnderReview'
import { DialogContent } from 'opensolar-ui'
import { useNotify } from 'ra-core'
import { NotFound } from 'ra-ui-materialui'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { BlueSnapOnboardingStatus } from 'types/orgs'
import { CashFlowSetupContext } from './CashFlowContext'
import CashFlowRoutes from './routes/Routes'
import CashFlowSubHeader from './subheaders/CashFlowSubHeader'
import { CashFlowSetupProgress } from './types'
import { getSetupCompletedSteps, useGetIsCashFlowVisible } from './utils'

type PropTypes = {}
const useStyles = makeOpenSolarStyles((theme) => ({
  contentWrapper: {
    overflow: 'hidden',
    marginBottom: '3rem',
  },
}))

const CashFlowLandingPage: React.FC<PropTypes> = (props) => {
  const org = useSelector(orgSelectors.getOrg)
  const [isFetchingSetupProgress, setIsFetchingSetupProgress] = useState<boolean>(true)
  const [setupProgress, setSetupProgress] = useState<CashFlowSetupProgress | {}>({})

  const notify = useNotify()
  const classes = useStyles()
  const history = useHistory()
  const blueSnapOnboardingStatus = useSelector(orgSelectors.getCashFlowOnboardingStatus)
  // make sure submitted is treated as "approved". This is a bit optimistic but denials are rare and submitted is just a temporary status but we don't
  // want users to still be presented with this form when they've already submitted their application
  const statusValuesToTreatAsApproved: BlueSnapOnboardingStatus[] = ['approved', 'conditionally approved', 'submitted']
  const isApproved = blueSnapOnboardingStatus && statusValuesToTreatAsApproved.includes(blueSnapOnboardingStatus)
  const cashFlowEnabled = useGetIsCashFlowVisible()

  useEffect(() => {
    if (blueSnapOnboardingStatus && blueSnapOnboardingStatus !== 'created' && window.location.hash === '#/cashflow') {
      history.push('/cashflow/invoices')
    }
  }, [blueSnapOnboardingStatus, window.location.hash])

  useEffect(() => {
    if (org?.id) {
      getSetupCompletedSteps(org.id)
        .then((data) => {
          setSetupProgress(data)
        })
        .catch(() => {
          notify('Unable to fetch setup checklist progress', 'warning')
        })
        .finally(() => {
          setIsFetchingSetupProgress(false)
        })
    }
  }, [org?.id])

  const goToControl = () => {
    history.push('/control')
  }

  if (!cashFlowEnabled) return <NotFound />
  else if (blueSnapOnboardingStatus && ['pending', 'submitted', 'denied'].includes(blueSnapOnboardingStatus)) {
    return (
      <Dialog open={true} onClose={goToControl} maxWidth="xl">
        <DialogContent>
          <UnderReview closeDialog={goToControl} />
        </DialogContent>
      </Dialog>
    )
  }
  return !isApproved ? (
    <>
      <LaunchPaymentsOnboardingDialog />
    </>
  ) : (
    <div className={classes.contentWrapper}>
      <CashFlowSetupContext.Provider value={{ setupProgress, setSetupProgress, isFetchingSetupProgress }}>
        <CashFlowSubHeader />
        <CashFlowRoutes />
      </CashFlowSetupContext.Provider>
    </div>
  )
}

export default CashFlowLandingPage

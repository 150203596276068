import { MenuItem } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orgSelectors } from 'ducks/orgs'
import type LineItemType from 'pages/ordering/OrderLineItem'
import { formatPricePerWattWithUnit } from 'pages/ordering/detail/content/price/PriceDetail'
import useProductSpecsData from 'pages/ordering/detail/useProductSpecsData'
import {
  getDiscountLabel,
  getModuleDiscountSavingPerWatt,
  isMinimumAmountRequirement,
} from 'pages/ordering/discountUtils'
import type { DiscountType } from 'pages/ordering/type'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { currencySymbolForCountry } from 'util/misc'
import useStyles from '../styles'

const DiscountOptions = ({
  lineItem,
  updateLineItemQuantity,
  closeDropdown,
  trackSource,
}: {
  lineItem: LineItemType
  updateLineItemQuantity(quantity: number): void
  closeDropdown(): void
  trackSource: string
}) => {
  const classes = useStyles()
  const productDetailData = useProductSpecsData(lineItem)
  const orgIso2 = useSelector(orgSelectors.getOrgIso2)
  const currencySymbol = currencySymbolForCountry(orgIso2)
  const outputPower = productDetailData.kw_stc && productDetailData.kw_stc * 1000
  const handleUpdateQuantity = useCallback(({ quantity }: { quantity: number }) => {
    logAmplitudeEvent('hardware_quantity_field_selector', {
      source: trackSource,
      action: 'clicked',
      context: 'volume_discount',
      type: 'volume_discount',
      value: quantity,
    })
    closeDropdown()
    updateLineItemQuantity(quantity)
  }, [])
  const handleDiscountClicked = ({ discount, pricePerItem }: { discount: DiscountType; pricePerItem: number }) => {
    if (isMinimumAmountRequirement(discount.minimumRequirement)) {
      // Handle minimum amount requirement
      const minimumAmount = parseFloat(discount.minimumRequirement.greaterThanOrEqualToSubtotal.amount)
      const minimumRequiredQuantity = Math.ceil(minimumAmount / pricePerItem)
      return handleUpdateQuantity({ quantity: minimumRequiredQuantity })
    } else {
      // Handle minimum quantity requirement
      const quantity = parseInt(discount.minimumRequirement.greaterThanOrEqualToQuantity)
      return handleUpdateQuantity({ quantity })
    }
  }

  const selectedDistributor = lineItem.selectedDistributorOrderingData
  return (
    <>
      <MenuItem
        onClick={() => {
          handleUpdateQuantity({ quantity: 1 })
        }}
        className={classes.menuItem}
      >
        Standard pricing
      </MenuItem>
      {selectedDistributor != null &&
        selectedDistributor.volume_discounts?.map((discount: DiscountType) => {
          const savingPerWatt =
            lineItem.componentType === 'module' &&
            discount &&
            getModuleDiscountSavingPerWatt({
              discount,
              outputPower,
              quantityPerItem: selectedDistributor.quantity_per_item,
              pricePerItem: selectedDistributor.price,
            })

          return (
            <MenuItem
              key={discount.id}
              onClick={() => {
                handleDiscountClicked({ discount, pricePerItem: selectedDistributor.price })
              }}
              className={classes.menuItem}
            >
              {getDiscountLabel({
                savingsOverride: savingPerWatt
                  ? `${formatPricePerWattWithUnit(savingPerWatt, orgIso2)}/W off`
                  : undefined,
                discount,
                currencySymbol,
              })}
            </MenuItem>
          )
        })}
    </>
  )
}

export const createDiscountOptions = (
  trackSource: string,
  lineItem: LineItemType,
  updateLineItemQuantity: (quantity: number) => void
) => {
  return ({ closeDropdown }) => (
    <DiscountOptions
      trackSource={trackSource}
      closeDropdown={closeDropdown}
      lineItem={lineItem}
      updateLineItemQuantity={updateLineItemQuantity}
    />
  )
}

export default DiscountOptions

import { and, ControlProps, rankWith, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import Alert from 'elements/Alert'
import { template, templateSettings } from 'lodash'
import * as React from 'react'

const tester = rankWith(100, and(uiTypeIs('Alert')))

const Renderer: React.FC<ControlProps> = ({ uischema, data, visible }) => {
  let text = uischema['text']
  const severity = uischema['severity'] || 'info'

  if (!visible) return <></>

  if (text.indexOf('{{') !== -1) {
    if (!data) {
      console.warn('No data found for alert renderer, aborting')
      return <></>
    }

    templateSettings.interpolate = /{{([\s\S]+?)}}/g
    text = template(text)({ data })
  }

  return (
    <Alert severity={severity}>
      <span style={{ whiteSpace: 'pre-line' }}>{text}</span>
    </Alert>
  )
}

const renderer = withJsonFormsControlProps(Renderer)

export default {
  tester,
  renderer,
}

import restClient from 'restClient'

const EVENT_DICT = {
  49: 'Finance Application Accessed',
} as any

export const logAppOpenedEvent = (actionData: any, selectedPaymentOptionName: string) => {
  if (!actionData || !selectedPaymentOptionName) return
  let data = {
    event_type_id: 49, //Finance Application Accessed event
    extra_notes_lines: [actionData.selected_system_title, selectedPaymentOptionName],
  }
  logGenericEventWithPayload(49, actionData.org_id, actionData.project_id, data)
}

export const logGenericEventWithPayload = (eventId: number, orgId: number, projectId: number, data: any) => {
  if (!eventId || !EVENT_DICT[eventId] || !orgId || !projectId) {
    console.log('unable to log eventID', eventId)
    return
  }
  let restClientInstance = restClient(window.API_ROOT + '/api')
  restClientInstance('CUSTOM_POST', 'custom', {
    url: 'orgs/' + orgId + '/projects/' + projectId + '/record_event/',
    data,
  })
    .then((response: any) => null)
    .catch((err: any) => {
      console.log('err', err)
    })
}

import { CopyLeftOutlineIcon } from 'opensolar-ui'
import ActionButton from './ActionButton'

const DuplicateToolButton = ({ object }) => {
  const handleClicked = () => {
    const keepPosition = false
    const useCommand = true
    object.duplicate?.(window.editor, keepPosition, useCommand)
  }

  return <ActionButton Icon={CopyLeftOutlineIcon} onClick={handleClicked} label={'Duplicate'} />
}

export default DuplicateToolButton

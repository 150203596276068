import React, { useCallback } from 'react'
import { COMPONENT_TYPE_OPTIONS } from 'resources/components/others/constants'

import type { FilterComponentRendererType, FilterGenericPropsType, FilterKeysType, OptionGenericType } from '../type'
import useOtherComponentTypeFilterSideEffect from './useOtherComponentTypeFilterSideEffect'

export type FilterOptionsType = OptionGenericType<string>[]

type FilterProps = FilterGenericPropsType & {
  RendererComponent: FilterComponentRendererType<FilterOptionsType>
  filterKey: FilterKeysType
}

const defaultOption: OptionGenericType<string> = { id: 'default', title: 'All', value: '' }
export const FILTER_OPTIONS: FilterOptionsType = COMPONENT_TYPE_OPTIONS.filter(
  (option) => option.id !== 'general'
).reduce(
  (result, option) => {
    result.push({
      value: option.id,
      title: option.name,
      id: option.id,
    })
    return result
  },
  [defaultOption]
)

const OtherComponentTypeFilter = ({
  filterKey,
  label,
  modifiableFilterValues,
  persistentFilterValues,
  allFilterValues,
  setModifiableFilters,
  RendererComponent,
}: FilterProps) => {
  const onChangeEffect = useOtherComponentTypeFilterSideEffect()
  const onSelect = useCallback(
    (value) => {
      const filterValues = { ...modifiableFilterValues, [filterKey]: value }
      const newFilterValues = onChangeEffect({ filterValues, value })
      setModifiableFilters(newFilterValues)
    },
    [modifiableFilterValues, onChangeEffect]
  )
  return (
    <RendererComponent
      id={filterKey}
      defaultValue={''}
      label={label || 'Component Type'}
      options={FILTER_OPTIONS}
      disabled={false}
      onSelect={onSelect}
      value={allFilterValues[filterKey]}
    />
  )
}

export default OtherComponentTypeFilter

import { useSelectedSystem } from 'Designer/hooks/useSelectedSystem'
import { orgSelectors } from 'ducks/orgs'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import { useSelector } from 'react-redux'
import { useFeatureFlag } from 'util/split'
import BomDrawer from './bom/BomDrawer'
import BomDrawerV3 from './bom3/BomDrawerV3'

//Installer: logic to show which drawer is design area
const DesignSideDrawer = () => {
  const system = useSelectedSystem()
  const selectedSupplier = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const partsListV3 = useFeatureFlag('design_multi_distributor', 'on')

  if (
    system &&
    (selectedSupplier === HardwareSupplierEnum.Segen ||
      selectedSupplier === HardwareSupplierEnum.CityPlumbing ||
      selectedSupplier === HardwareSupplierEnum.Hdm ||
      selectedSupplier === HardwareSupplierEnum.VtacUk ||
      selectedSupplier === HardwareSupplierEnum.VtacPoland)
  ) {
    return partsListV3 ? <BomDrawerV3 /> : <BomDrawer />
  }

  return null
}

export default DesignSideDrawer

import { ConnectedOrgCreate, ConnectedOrgEdit } from './ConnectedOrgs'
import { ConnectedOrgList } from './ConnectedOrgsList'

export const featureDescription =
  'Establish connections between organisations to share components and collaborate on projects.'

export default {
  list: ConnectedOrgList,
  edit: ConnectedOrgEdit,
  create: ConnectedOrgCreate,
  options: {
    create: {
      title: 'Connect An Org',
      breadCrumb: 'Connect An Org',
    },
    list: {
      title: 'Connected Orgs',
      subtitle: featureDescription,
      breadCrumb: 'Connected Orgs',
    },
    edit: {
      title: 'Edit Org Connection',
      breadCrumb: 'Edit Org Connection',
    },
  },
}

import { List } from 'elements/react-admin/List'
import ListActions from 'elements/react-admin/ListActions'
import EditorComponent from 'elements/wysiwyg/EditorComponentWrapper'
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  FilterComp,
  FunctionField,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin'
import { FormSpy } from 'react-final-form'
import { connect } from 'react-redux'

const inputStyle = {
  width: 256,
  maxWidth: 256,
  marginRight: 40,
}

export const WalletProductFilter = connect((state) => {
  return {}
}, null)((props) => (
  <FilterComp {...props}>
    <TextInput label="pos.search" source="q" />
  </FilterComp>
))

export const WalletProductList = (props) => (
  <List hasSearch={true} actions={<ListActions createButtonLabel="Add Wallet Product" />} {...props}>
    <Datagrid>
      <TextField source="title" />
      <TextField source="country_iso2" />
      <TextField source="unit_price_ex_tax" />
      <FunctionField
        source={null}
        label="Actions"
        render={(record, source, rest) => (
          <div>
            <EditButton useListStyle={true} record={record} basePath={rest.basePath} />
            <DeleteButton
              useListStyle={true}
              undoable={false}
              record={record}
              resource={rest.resource}
              redirect={`/${rest.resource}`}
            />
          </div>
        )}
        sortable={false}
      />
    </Datagrid>
  </List>
)

const WalletInputFields = (props) => {
  return (
    <>
      <TextInput source="title" validate={[required()]} />
      <TextInput source="currency" validate={[required()]} />
      <FormSpy subscription={{ values: true }}>
        {({ values }) => {
          const isVariableType = values.type === 'variable'
          if(isVariableType) {
            values.unit_price_ex_tax = 0
          }
          return (
            <>
            {!isVariableType && (
                <TextInput
                  source="unit_price_ex_tax"
                  validate={[required()]}
                />
              )}
            </>
          );
        }}
      </FormSpy>
      <SelectInput
        source="unit"
        choices={[
          { id: 'per project', name: 'per project' },
          { id: 'per call', name: 'per call' },
        ]}
        validate={[required()]}
      />
      <SelectInput
        source="type"
        choices={[
          { id: 'fixed', name: 'Fixed' },
          { id: 'variable', name: 'Variable' },
        ]}
        validate={[required()]}
      />
      <ReferenceInput
        label="resources.orgs.fields.country"
        name="country"
        source="country"
        reference="countries"
        optionValueField="url"
        sort={{ field: 'name', order: 'ASC' }}
        perPage={1000}
        allowEmpty={true}
        validate={[required()]}
        {...props}
      >
        <AutocompleteInput
          optionText="name"
          optionValue="url"
          allowDuplicates={true}
          options={{
            style: inputStyle,
            containerStyle: inputStyle,
            placeholder: 'Enter a country',
          }}
          validate={[required()]}
        />
      </ReferenceInput>
      <TextInput
        source="description"
        label="Description (HTML)"
        validate={[required()]}
        multiline
        rows={3}
        options={{
          style: inputStyle,
          containerStyle: inputStyle,
        }}
      />
      <EditorComponent label="Terms and Conditions" allowUploadImage={true} fieldName="terms" />
      <BooleanInput source="enabled" />
    </>
  )
}

export const WalletProductEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <WalletInputFields {...props} />
    </SimpleForm>
  </Edit>
)

export const WalletProductCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm initialValues={{ enabled: true }}>
        <WalletInputFields {...props} />
      </SimpleForm>
    </Create>
  )
}

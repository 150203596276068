import { Dialog, DialogActions, DialogContent, Divider, Tooltip, withTheme } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import { TermsAndPrivacyLinks } from 'elements/legal/TermsAndPrivacyLinks'
import { Button, IconButton } from 'opensolar-ui'
import { Component } from 'react'
import { withTranslate } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import myApiRestClient from 'restClient'
import { authSelectors, setTermsAccepted as setTermsAcceptedAction } from '../../ducks/auth'
import { setWelcomeDialogOpen as setWelcomeDialogOpenAction } from '../../ducks/welcome'
import { DialogHelper, loadStripeIfNotFound } from '../../util/misc'
import Letter from '../elements/Letter'
import { AboutUS } from '../mainContent/components/AboutUs'

const API_URL = window.API_ROOT + '/api'
const restClientInstance = myApiRestClient(API_URL)

class _LetterDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      message: props.message ? props.message : null,
      loading: false,
    }

    this.isClosing = false
  }

  componentDidMount() {
    if (this.props.termsAccepted) loadStripeIfNotFound()
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.termsAccepted && this.props.termsAccepted) loadStripeIfNotFound()
  }

  acceptTerms() {
    this.setState({ loading: true })

    restClientInstance('CUSTOM_POST', 'accept_terms', {
      url: 'accept_terms/',
    }).then(
      (response) => {
        // console.log('ok', response)

        this.setState({ loading: false })

        if (this.props.setTermsAccepted) {
          this.isClosing = true
          DialogHelper.beforeClose()
          this.props.setTermsAccepted()
          this.props.setWelcomeDialogOpen(false)
        } else {
          console.log('setTermsAccepted not available. No update')
        }
      },
      (reject) => {
        this.setState({ loading: false, message: reject.message })
      }
    )
  }

  renderContinueActionButton() {
    const { translate } = this.props
    return this.props.termsAccepted ? (
      <Button
        id={'continueProposalButton'}
        variant="contained"
        color="primary"
        wrapperStyle={{
          textAlign: 'right',
          width: '100%',
          bottom: 0,
          right: 0,
        }}
        onClick={() => {
          DialogHelper.beforeClose()
          this.props.setWelcomeDialogOpen(false)
        }}
      >
        <span style={{ textTransform: 'none' }}>{translate('Continue to Proposal')}</span>
      </Button>
    ) : (
      <div
        style={{
          width: '100%',
          bottom: 0,
          right: 0,
        }}
      >
        <p className="small" style={{ color: '#888585', margin: 0 }}>
          {translate('Solar Proposal powered by OpenSolar Pty Ltd.')} <TermsAndPrivacyLinks />
        </p>
        <div style={{ width: '100%', textAlign: 'right', marginTop: 10, marginBottom: 5 }}>
          <Button
            id={'continueProposalButton'}
            variant="contained"
            color="primary"
            onClick={() => {
              this.acceptTerms()
            }}
          >
            <span style={{ textTransform: 'none' }}>{translate('Continue to Proposal')}</span>
          </Button>
        </div>
      </div>
    )
  }

  render() {
    if (this.props.isOpen) {
      if (!this.isClosing) {
        DialogHelper.afterOpen()
      }
    }
    return (
      <div className="ContainerLetter" id="ContainerLetter">
        <Dialog
          open={this.props.isOpen}
          PaperProps={{ id: 'welcome-message-dialog' }}
          style={{ maxHeight: 'calc(100vh - 56px)', zIndex: 2001 }}
          // actionsContainerStyle={{ textAlign: 'left', borderTop: '1px solid #888585', marginTop: 1 }}
          onBackdropClick={() => {
            if (this.props.termsAccepted) {
              DialogHelper.beforeClose()
              this.props.setWelcomeDialogOpen(false)
            }
          }}
          className="proposal-message"
        >
          <DialogContent>
            {this.props.termsAccepted && (
              <IconButton
                id="CloseLetter"
                style={{
                  top: 10,
                  right: 10,
                  position: 'absolute',
                  minWidth: 40,
                  backgroundColor: 'rgba(255, 255, 255, 0)',
                }}
                onClick={() => {
                  DialogHelper.beforeClose()
                  this.props.setWelcomeDialogOpen(false)
                }}
              >
                <Tooltip title={this.props.translate('Close Dialog')} enterDelay={300}>
                  {<CloseIcon style={{ color: '#000000' }} />}
                </Tooltip>
              </IconButton>
            )}

            {this.props.org && this.props.showLetter && this.props.content && (
              <Letter
                org={this.props.org}
                title={this.props.title}
                content={this.props.content}
                overrideLogo={this.props.overrideLogo}
              />
            )}
            {this.props.org && this.props.showLetter && this.props.showAboutUsContent && (
              <AboutUS
                org={this.props.org}
                selectedProject={this.props.selectedProject}
                showLetter={Boolean(this.props.content)}
              />
            )}
          </DialogContent>
          <Divider style={{ background: 'rgb(136, 133, 133)' }} />
          <DialogActions>{this.renderContinueActionButton()}</DialogActions>
        </Dialog>
      </div>
    )
  }
}

const isOpen = (state) => {
  // Open if terms not accepted OR if customer welcome message is active
  if (
    window.location.href.includes('event=id_check_failed') ||
    window.location.href.includes('event=signing_complete') ||
    window.location.href.includes('event=session_timeout')
  ) {
    return false
  } else if (state.auth?.user?.id && !authSelectors.getHasAcceptedTerms(state)) {
    return true
  } else if (state.welcome && state.welcome.show_welcome_dialog === true) {
    return true
  } else {
    return false
  }
}
const mapStateToProps = (state) => {
  return {
    isOpen: isOpen(state),
    termsAccepted: authSelectors.getHasAcceptedTerms(state) && !authSelectors.isAnonymousShareUser(state),
    modal: !authSelectors.getHasAcceptedTerms(state),
    showLetter: state.welcome && state.welcome.customer === true,
  }
}

const enhance = compose(
  withTheme,
  withTranslate,
  connect(mapStateToProps, {
    setTermsAccepted: setTermsAcceptedAction,
    setWelcomeDialogOpen: setWelcomeDialogOpenAction,
  })
)

export const LetterDialog = enhance(_LetterDialog)

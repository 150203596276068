import { CircularProgress } from '@material-ui/core'
import Button from 'elements/button/Button'
import { AlertCircleOutlineIcon, EditOutlineIcon } from 'opensolar-ui'
import { FC } from 'react'

export const ButtonVariants: FC = () => {
  return (
    <>
      {/* SNIPPET START 1 */}
      <Button variant="text">Text Variant</Button>

      <Button variant="contained">Contained Variant</Button>

      <Button variant="outlined">Outlined Variant</Button>
      {/* SNIPPET END 1 */}
    </>
  )
}

export const ButtonColors: FC = () => {
  return (
    <>
      {/* SNIPPET START 2 */}
      <Button color="default">Default Color</Button>
      <Button color="primary">Primary Color</Button>
      <Button color="secondary">Secondary Color</Button>
      <Button color="info">Info Color</Button>
      <Button color="success">Success Color</Button>
      <Button color="warning">Warning Color</Button>
      <Button color="error">Error Color</Button>
      {/* SNIPPET END 2 */}
    </>
  )
}

export const ButtonSizes: FC = () => {
  return (
    <>
      {/* SNIPPET START 3 */}
      <Button size="small">Small</Button>
      <Button size="medium">Medium (default)</Button>
      <Button size="large">Large</Button>
      {/* SNIPPET END 3 */}
    </>
  )
}

export const ButtonIcons: FC = () => {
  return (
    <>
      {/* SNIPPET START 4 */}
      <Button startIcon={<EditOutlineIcon variant={1} />}>Start Icon</Button>
      <Button endIcon={<AlertCircleOutlineIcon />}>End Icon</Button>
      <Button endIcon={<CircularProgress size={24} />} color="secondary">
        Progress Icon
      </Button>
      {/* SNIPPET END 4 */}
    </>
  )
}

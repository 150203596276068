import { AUTH_REDIRECT_SESSION_STORAGE_KEY } from 'redirections/authRedirectionStore'

export class WindowStorage {
  allowLocal = false
  keys: string[] | undefined
  storage: Record<string, string> = {}
  dontCopy: string[] = [AUTH_REDIRECT_SESSION_STORAGE_KEY] // Add keys here to prevent them being copied from sessionStorage > localStorage

  constructor() {
    this.pullFrom(sessionStorage)
  }

  get length(): number {
    return this.getKeys().length
  }

  setAllowLocal(value: boolean) {
    if (this.allowLocal === value) return
    this.allowLocal = value

    if (this.allowLocal) {
      // Copy all keys from sessionStorage into localStorage and use it going forward
      // Fetch keys first to avoid iterating over an object while modifying it
      var storageKeys = Object.keys(sessionStorage)

      for (const key of storageKeys) {
        const value = sessionStorage.getItem(key)
        if (this.dontCopy.includes(key)) continue
        if (value) localStorage.setItem(key, value)
        sessionStorage.removeItem(key)
      }
      this.pullFrom(localStorage)
    } else {
      // Switching back to session storage
      this.clearStorage()
      this.pullFrom(sessionStorage)
    }
  }

  clearStorage() {
    this.keys = undefined
    this.storage = {}
  }

  pullFrom(storage: Storage) {
    var storageKeys = Object.keys(storage)
    for (const key of storageKeys) {
      this.storage[key] = storage.getItem(key) as string
    }
  }

  clear() {
    this.getBrowserStorage().clear()
  }

  getKeys(): string[] {
    return this.keys || (this.keys = Object.keys(this.storage))
  }

  clearKeys() {
    this.keys = undefined
  }

  key(i: number): string | null {
    return this.getKeys()[i]
  }
  getItem(key: string): string | null {
    return this.storage[key] || null
  }
  setItem(key: string, value: string) {
    this.clearKeys()
    this.storage[key] = value
    this.getBrowserStorage().setItem(key, value)
  }
  removeItem(key: string) {
    this.clearKeys()
    delete this.storage[key]
    this.getBrowserStorage().removeItem(key)
  }

  // Not to be used generally, should only be used in special cases where direct access to
  // browser storgae API is required.
  getBrowserStorage() {
    if (this.allowLocal) return localStorage
    else return sessionStorage
  }
}

export const customStorage = new WindowStorage()
window.getStorage = () => customStorage

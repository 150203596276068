import React, { useState, useEffect } from 'react'
import { Grid, Divider, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { TextInput, useTranslate, SelectInput } from 'react-admin'
import { useForm, useField } from 'react-final-form'
import CheckboxInput from 'elements/input/CheckboxInput'
import DependentInput from 'elements/input/DependentInput'
import CustomField from 'elements/field/CustomField'
import Button from 'elements/proUXButtons/ProUXButton'
import { getYearChoices, MONTHS, DAYS_BY_MONTH, EMPLOYMENT_STATUS, COBORROWER_PREFIX, PHONE_TYPES } from './constants'
import { formatCurrency, getIsValidEmail } from 'util/misc'

type ChoiceType = {
  name: string
  id: string
}

type PropTypes = {}

const useStyles = makeStyles({
  applicantHeaderView: {},
  applicantHeaderText: {
    fontWeight: 900,
    fontSize: '18px',
  },
  applicantSection: {
    border: '1px solid #ececec',
    borderRadius: '5px',
    padding: '10px',
    width: '100%',
  },
})

const LoanpalApplicationForm: React.FunctionComponent<PropTypes> = (props) => {
  const primaryDobMonth = useField('dob_month', { subscription: { value: true } }).input.value
  const secondaryDobMonth = useField(`${COBORROWER_PREFIX}dob_month`, { subscription: { value: true } }).input.value

  const getDayChoices = (isPrimary: boolean) => {
    const selectedMonth = isPrimary ? primaryDobMonth : secondaryDobMonth
    return selectedMonth &&
      !isNaN(parseInt(selectedMonth)) &&
      parseInt(selectedMonth) > 0 &&
      parseInt(selectedMonth) < 13
      ? // @ts-ignore
        DAYS_BY_MONTH[selectedMonth]
      : DAYS_BY_MONTH['none']
  }

  const [copiedAddress, setCopiedAddress] = useState<boolean>(false)
  const [primaryAppDayChoices, setPrimaryAppDayChoices] = useState<ChoiceType[]>(getDayChoices(true))
  const [secondaryAppDayChoices, setSecondaryAppDayChoices] = useState<ChoiceType[]>(getDayChoices(false))

  useEffect(() => {
    setPrimaryAppDayChoices(getDayChoices(true))
  }, [primaryDobMonth])

  useEffect(() => {
    setSecondaryAppDayChoices(getDayChoices(false))
  }, [secondaryDobMonth])

  const translate = useTranslate()
  const form = useForm()
  const classes = useStyles()
  const isMobile = useMediaQuery('(max-width:800px)')

  const copyPrimaryAddress = () => {
    const formVals = form.getState().values
    form.change(`${COBORROWER_PREFIX}address`, formVals.address)
    form.change(`${COBORROWER_PREFIX}locality`, formVals.locality)
    form.change(`${COBORROWER_PREFIX}state`, formVals.state)
    form.change(`${COBORROWER_PREFIX}zip`, formVals.zip)
    setCopiedAddress(true)
  }

  const validatePhone = (raw: string, otherFields: any, meta: any) => {
    if (!raw) {
      return 'Phone Number is required'
    } else {
      const justNumbers = raw.replace(/[^0-9]/g, '')
      if (justNumbers.length !== 10) return 'Invalid phone number'
    }
    return undefined
  }

  const validateEmail = (raw: string) => {
    const isValid = getIsValidEmail(raw)
    if (!raw) return 'Required Field'
    else if (!isValid) return 'Invalid Email Address'
    else return undefined
  }

  const parsePhone = (formatted: string) => {
    if (!formatted) return undefined
    else {
      const deletedParens = formatted.length === 4 && formatted.startsWith('(') && !formatted.endsWith(')')
      let justNumbers = formatted.replace(/[^0-9]/g, '')
      if (justNumbers.length > 0) {
        justNumbers = justNumbers.substring(0, 10)
        if (deletedParens) justNumbers = justNumbers.substring(0, 2)
      }
      return justNumbers
    }
  }

  const formatPhone = (phone: string) => {
    if (!phone) return null
    phone = phone.replace(/[^\d]/g, '')

    if (phone.length > 0) {
      let chunk1 = phone.substring(0, 3)
      let chunk2 = phone.substring(3, 6)
      let chunk3 = phone.substring(6)
      if (chunk1 && chunk2 && chunk3) {
        return `(${chunk1}) ${chunk2} - ${chunk3}`
      } else if (chunk1 && chunk2) {
        return `(${chunk1}) ${chunk2}`
      } else if (chunk1 && chunk1.length === 3) {
        return `(${chunk1})`
      } else return chunk1
    }
    return null
  }

  const parseSSN = (formatted: string) => {
    if (!formatted) return undefined
    else {
      let justNumbers = formatted.replace(/[^0-9]/g, '')
      if (justNumbers.length > 0) {
        justNumbers = justNumbers.substring(0, 4)
      }
      return justNumbers
    }
  }

  const parseIncome = (formatted: string) => {
    if (!formatted) return undefined
    else {
      let justNumbers = formatted.replace(/[^0-9,$]/g, '')
      if (justNumbers.length > 0) {
        justNumbers = justNumbers.substring(0, 9)
      }
      return justNumbers
    }
  }

  const renderContactFields = (isSecondary: boolean) => {
    const fieldPrefix = isSecondary ? COBORROWER_PREFIX : ''

    return (
      <div className={classes.applicantSection}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div className={classes.applicantHeaderView}>
              <span className={classes.applicantHeaderText}>
                {isSecondary ? 'Co-Borrower Information' : 'Primary Borrower Information'}
              </span>
            </div>
          </Grid>
          <Grid item xs={6}>
            <CustomField
              name={`${fieldPrefix}first_name`}
              source="first_name"
              component={TextInput}
              fullWidth
              style={{ width: '100%' }}
              required={true}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomField
              name={`${fieldPrefix}family_name`}
              source="family_name"
              component={TextInput}
              fullWidth
              style={{ width: '100%' }}
              required={true}
            />
          </Grid>
          <Grid item xs={isSecondary && !copiedAddress ? 8 : 12}>
            <CustomField
              name={`${fieldPrefix}address`}
              source="address"
              label={translate('Street (no P.O. Boxes)')}
              component={TextInput}
              fullWidth
              style={{ width: '100%' }}
              required={true}
            />
          </Grid>
          {isSecondary && !copiedAddress && (
            <Grid
              item
              xs={4}
              style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', paddingBottom: '32px' }}
            >
              <Button onClick={copyPrimaryAddress} label="Use Address From Above" type="secondary" />
            </Grid>
          )}
          <Grid item xs={6}>
            <CustomField
              name={`${fieldPrefix}locality`}
              source="locality"
              label={translate('City')}
              component={TextInput}
              fullWidth
              style={{ width: '100%' }}
              required={true}
            />
          </Grid>
          <Grid item xs={3}>
            <CustomField
              name={`${fieldPrefix}state`}
              source="state"
              component={TextInput}
              fullWidth
              style={{ width: '100%' }}
              required={true}
            />
          </Grid>
          <Grid item xs={3}>
            <CustomField
              name={`${fieldPrefix}zip`}
              source="zip"
              component={TextInput}
              fullWidth
              style={{ width: '100%' }}
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <CustomField
              name={`${fieldPrefix}phone_number`}
              source="phone_number"
              label={translate('Phone Number')}
              component={TextInput}
              fullWidth
              style={{ width: '100%' }}
              validateOnBlur={validatePhone}
              parse={parsePhone}
              format={formatPhone}
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CustomField
              name={`${fieldPrefix}phone_type`}
              source="phone_type"
              label={translate('Phone Type')}
              component={SelectInput}
              choices={PHONE_TYPES}
              fullWidth
              style={{ width: '100%' }}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <span style={{ fontWeight: 900 }}>Date of Birth</span>
              <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ flex: 1, padding: '0px 4px' }}>
                  <CustomField
                    name={`${fieldPrefix}dob_month`}
                    source="dob_month"
                    label={translate('Month')}
                    component={SelectInput}
                    choices={MONTHS}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                  />
                </div>
                <div style={{ flex: 1, padding: '0px 4px' }}>
                  <CustomField
                    name={`${fieldPrefix}dob_day`}
                    source="dob_day"
                    label={translate('Day')}
                    component={SelectInput}
                    choices={isSecondary ? secondaryAppDayChoices : primaryAppDayChoices}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                    // parse={parseDate}
                  />
                </div>
                <div style={{ flex: 1, padding: '0px 4px' }}>
                  <CustomField
                    name={`${fieldPrefix}dob_year`}
                    source="dob_year"
                    label={translate('Year')}
                    component={SelectInput}
                    choices={getYearChoices()}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                    // parse={parseDate}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomField
              name={`${fieldPrefix}ssn`}
              source="ssn"
              label={
                isMobile
                  ? translate('Last 4 of Social Security Number')
                  : translate('Last 4 digits of your Social Security Number')
              }
              component={TextInput}
              fullWidth
              style={{ width: '100%' }}
              parse={parseSSN}
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomField
              name={`${fieldPrefix}email`}
              source="email"
              label={translate('Email Address')}
              component={TextInput}
              fullWidth
              style={{ width: '100%' }}
              required={true}
              validateOnBlur={validateEmail}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomField
              name={`${fieldPrefix}income`}
              source="income"
              label={translate('Pre-Tax Annual Income')}
              component={TextInput}
              fullWidth
              parse={parseIncome}
              style={{ width: '100%' }}
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomField
              name={`${fieldPrefix}employment_status`}
              source="employment_status"
              label={translate('Employment Status')}
              component={SelectInput}
              choices={EMPLOYMENT_STATUS}
              fullWidth
              style={{ width: '100%' }}
              required={true}
            />
          </Grid>
          <DependentInput
            dependsOn={`${fieldPrefix}employment_status`}
            resolve={(val: string) => val !== 'NOT_EMPLOYED' && val !== 'RETIRED'}
          >
            <>
              <Grid item xs={6} sm={4}>
                <CustomField
                  name={`${fieldPrefix}employer`}
                  source="employer"
                  label={translate('Employer')}
                  component={TextInput}
                  fullWidth
                  style={{ width: '100%' }}
                  required={false}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomField
                  name={`${fieldPrefix}occupation`}
                  source="occupation"
                  label={translate('Job Title')}
                  component={TextInput}
                  fullWidth
                  style={{ width: '100%' }}
                  required={false}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CustomField
                  name={`${fieldPrefix}citizenship`}
                  source="citizenship"
                  label={translate('Citizenship')}
                  component={SelectInput}
                  choices={[
                    { name: translate('US Citizen'), id: 'US_CITIZEN' },
                    { name: translate('Lawful Permanent Resident Alien'), id: 'LAWFUL_PERMANENT_RESIDENT_ALIEN' },
                    { name: translate('Other'), id: 'OTHER' },
                  ]}
                  fullWidth
                  style={{ width: '100%' }}
                  required={true}
                />
              </Grid>
            </>
          </DependentInput>
          <DependentInput
            dependsOn={`${fieldPrefix}employment_status`}
            resolve={(val: string) => val === 'NOT_EMPLOYED' || val === 'RETIRED'}
          >
            <Grid item xs={12}>
              <CustomField
                name={`${fieldPrefix}citizenship`}
                source="citizenship"
                label={translate('Citizenship')}
                component={SelectInput}
                choices={[
                  { name: translate('US Citizen'), id: 'US_CITIZEN' },
                  { name: translate('Lawful Permanent Resident Alien'), id: 'LAWFUL_PERMANENT_RESIDENT_ALIEN' },
                  { name: translate('Other'), id: 'OTHER' },
                ]}
                fullWidth
                style={{ width: '100%' }}
                required={true}
              />
            </Grid>
          </DependentInput>
          <Divider />
        </Grid>
      </div>
    )
  }
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={`${window.PUBLIC_URL}/images/loanpal_logo.svg`} alt="" height={90} width={180} />
        </Grid>
        {renderContactFields(false)}
        <Grid item xs={12}>
          <CustomField
            name="has_coborrower"
            source="has_coborrower"
            label={translate('Add Co-Borrower')}
            component={CheckboxInput}
            fullWidth
            style={{ width: '100%' }}
          />
        </Grid>
        <DependentInput dependsOn="has_coborrower" value={true}>
          <>{renderContactFields(true)}</>
        </DependentInput>
        <Grid item xs={6}>
          <CustomField
            name="autopay"
            source="autopay"
            label={translate('Pay with Autopay')}
            component={SelectInput}
            choices={[
              { name: translate('Yes'), id: 'yes' },
              { name: translate('No'), id: 'no' },
            ]}
            fullWidth
            style={{ width: '100%' }}
          />
        </Grid>
      </Grid>
    </div>
  )
}
export default LoanpalApplicationForm

import React, { useState } from 'react'
import FoldArrows from '../elements/FoldArrows'

const useFoldableMenuItemControl = (defaultExpanded) => {
  const [open, setOpen] = useState(!!defaultExpanded)
  const onToggle = () => setOpen(!open)
  const ArrowIconNode = <FoldArrows open={open} handleClick={onToggle} />
  return {
    ArrowIconNode,
    open,
    onToggle,
  }
}

export default useFoldableMenuItemControl

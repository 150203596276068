import { makeStyles } from '@material-ui/core'
import { Styles, WithStylesOptions } from '@material-ui/core/styles/withStyles'
import { OpenSolarThemeType } from 'Themes'

export function makeOpenSolarStyles<Props extends object = {}, ClassKey extends string = string>(
  styles: Styles<OpenSolarThemeType, Props, ClassKey>,
  options?: Omit<WithStylesOptions<OpenSolarThemeType>, 'withTheme'>
) {
  return makeStyles(styles, options)
}

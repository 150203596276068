import { FC } from 'react'
import { ReferenceInput, SelectInput } from 'react-admin'
import ConditionalRefFilter from './ConditionalRefFilter'

interface PropTypes {
  source: string
  fieldId: string
}

const PriceScheme: FC<PropTypes> = (props) => {
  const handleChange = (value) => {
    let newValue = {}
    if (value?.conditional !== undefined && value?.itemId) {
      newValue[value.conditional ? 'systems__pricing_scheme' : 'systems__pricing_scheme_not'] = value?.itemId
    }
    return newValue
  }
  return (
    <ConditionalRefFilter {...props} onChange={handleChange}>
      <ReferenceInput source={`${props.source}.fields.itemId`} reference="pricing_schemes" label="">
        <SelectInput source="title" optionText="title" variant="outlined" margin="none" label={false} />
      </ReferenceInput>
    </ConditionalRefFilter>
  )
}

export default PriceScheme

import * as Sentry from '@sentry/react'
import appStorage from 'storage/appStorage'
import { MapDataTypes } from 'types/map'

const getFallback3DImagery = () => {
  if (appStorage.getString('nearmap_token')) {
    return {
      design_mode: '3D',
      map_type: 'Nearmap3D',
      provider: 'Nearmap',
      score: 7,
      variation_data: null,
    }
  } else {
    return {
      design_mode: '3D',
      map_type: 'Google3D',
      provider: 'Google',
      score: 5,
      variation_data: null,
      variation_name: 'Google Solar API',
    }
  }
}

export const getImageryTypeDefault = (allowSaved = true) => {
  const imageryTypeDefaultString = allowSaved ? appStorage.getString('imageryTypeDefault') : undefined
  if (imageryTypeDefaultString) {
    try {
      return JSON.parse(imageryTypeDefaultString)
    } catch (err) {
      Sentry.captureException(err)
    }
  }
  return getFallback3DImagery()
}

type ImageryTypeWithMatchScore = {
  score: number
  imageryType: MapDataTypes | null
}

export const findBestMatchForDefaultImageryType = (
  imageryTypes: MapDataTypes[],
  defaultOverride: MapDataTypes | undefined
): MapDataTypes | null => {
  const defaultImageryType = defaultOverride || getImageryTypeDefault()
  // can't find more suitable names :D
  return imageryTypes.reduce(
    (defending: ImageryTypeWithMatchScore, challenger: MapDataTypes) => {
      const challengerScore = scoreImageryTypeAgainstDefault(challenger, defaultImageryType)
      if (challengerScore > defending.score) {
        return { score: challengerScore, imageryType: challenger }
      } else {
        return defending
      }
    },
    { score: -1, imageryType: null }
  ).imageryType
}

export enum IMAGERY_MATCH_LEVEL {
  DesignMode = 1,
  MapType = 2,
  Provider = 3,
  VariationName = 4,
}

export const scoreImageryTypeAgainstDefault = (
  imageryType: MapDataTypes,
  defaultOverride: MapDataTypes | undefined
) => {
  const defaultImageryType = defaultOverride || getImageryTypeDefault()
  if (!defaultImageryType || !imageryType) return 0

  const sameDesignMode = imageryType.design_mode === defaultImageryType.design_mode
  const sameMapType = imageryType.map_type === defaultImageryType.map_type
  const sameProvider = imageryType.provider === defaultImageryType.provider
  const sameVariationName = imageryType.variation_name === defaultImageryType.variation_name
  let score = 0
  if (sameDesignMode) {
    score += 1
    if (sameMapType) {
      score += 1
      if (sameProvider) {
        score += 1
        if (sameVariationName) {
          score += 1
        }
      }
    }
  }
  return score
}

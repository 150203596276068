import React from 'react'

const SolarIcon = (props) => (
  <svg {...props} viewBox="0 0 100 89" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M64.9813 88.0427C62.1839 88.0427 59.3865 88.0765 56.5554 88.0427C54.3646 88.009 53.7243 86.9633 54.668 85.0069C59.0495 76.1019 63.4983 67.2306 67.8798 58.3256C68.4528 57.145 69.2617 56.6728 70.5087 56.6728C76.1035 56.7065 81.7321 56.639 87.3269 56.7065C89.585 56.7065 90.2591 57.8533 89.248 59.8772C84.8665 68.7822 80.4176 77.6535 76.0361 86.5586C75.4969 87.6717 74.7217 88.1102 73.5084 88.0765C70.7109 88.009 67.8461 88.0427 64.9813 88.0427Z"
      fill="url(#paint0_linear_6249:253466)"
    />
    <path
      d="M15.9424 88.0427C13.0776 88.0427 10.2128 88.0765 7.34794 88.0427C5.25831 88.009 4.61794 86.9633 5.52794 85.1081C9.94313 76.1694 14.392 67.2306 18.8072 58.2918C19.3465 57.1787 20.1216 56.7065 21.3013 56.7065C26.9635 56.7065 32.6257 56.6727 38.288 56.7402C40.4787 56.7739 41.1191 57.8533 40.1417 59.8097C35.7602 68.7148 31.3113 77.5861 26.9635 86.4911C26.3905 87.6717 25.6154 88.1439 24.3346 88.1102C21.5709 88.009 18.7735 88.0427 15.9424 88.0427Z"
      fill="url(#paint1_linear_6249:253466)"
    />
    <path
      d="M40.5124 88.0427C37.6476 88.0427 34.7828 88.0765 31.918 88.0427C29.8283 88.009 29.188 86.9634 30.098 85.0744C34.5132 76.1356 38.9957 67.1969 43.3772 58.2581C43.9165 57.145 44.6917 56.6728 45.8713 56.6728C51.5335 56.6728 57.1958 56.639 62.858 56.7065C65.0487 56.7402 65.6891 57.8196 64.7117 59.776C60.2965 68.7148 55.8476 77.6535 51.4324 86.5923C50.9269 87.638 50.1854 88.0765 49.0395 88.0427C46.2421 88.009 43.3772 88.0427 40.5124 88.0427Z"
      fill="url(#paint2_linear_6249:253466)"
    />
    <path
      d="M28.3117 51.9841C25.8176 51.9841 23.4246 51.9841 20.8294 51.9841C20.2902 41.4263 23.9976 32.7911 32.4572 26.6183C42.5346 19.2986 53.4884 18.6577 64.2398 24.8643C74.4184 30.7335 78.9347 40.0096 78.5976 51.9167C76.0024 51.9167 73.5084 51.9167 71.048 51.9167C71.958 37.6147 60.7684 28.5072 49.7472 28.4735C38.6924 28.4735 27.5702 37.446 28.3117 51.9841Z"
      fill="url(#paint3_linear_6249:253466)"
    />
    <path
      d="M45.8376 7.72884C45.8376 6.54825 45.8039 5.36766 45.8376 4.18707C45.905 1.85962 47.5565 0.13933 49.8146 0.00440566C52.0054 -0.0967879 54.0276 1.55604 54.1287 3.84976C54.2635 6.54825 54.2635 9.24675 54.1287 11.9115C54.0276 14.104 52.0054 15.7231 49.9157 15.6894C47.6576 15.6219 45.905 13.8679 45.8376 11.5742C45.8039 10.3261 45.8376 9.04436 45.8376 7.72884Z"
      fill="url(#paint4_linear_6249:253466)"
    />
    <path
      d="M7.98832 45.7439C9.23536 45.7439 10.4487 45.6764 11.6957 45.7439C14.055 45.8788 15.7739 47.6328 15.8076 49.8253C15.8076 51.9504 14.1898 53.8056 11.9317 53.9068C9.23536 54.0417 6.53906 54.0417 3.84276 53.9068C1.51721 53.8394 -0.0331649 52.0179 0.000538803 49.8253C0.0342425 47.6666 1.65202 45.9463 3.94387 45.7776C5.29202 45.6764 6.64017 45.7776 7.98832 45.7439C7.98832 45.7776 7.98832 45.7776 7.98832 45.7439Z"
      fill="url(#paint5_linear_6249:253466)"
    />
    <path
      d="M91.978 53.9743C90.6299 53.9743 89.2817 54.0755 87.9336 53.9406C85.7428 53.7382 84.2262 52.0179 84.2262 49.8928C84.2262 47.7677 85.7428 45.9463 87.9336 45.8451C90.6636 45.7101 93.4273 45.7101 96.191 45.8451C98.348 45.9463 99.9658 47.8015 99.9995 49.8591C100.033 51.9504 98.3817 53.8056 96.1573 54.008C94.7754 54.0755 93.3936 53.9743 91.978 53.9743Z"
      fill="url(#paint6_linear_6249:253466)"
    />
    <path
      d="M77.5529 26.9554C75.3958 26.8205 74.0814 26.0784 73.3736 24.4931C72.6995 22.9752 72.8006 21.4573 73.9128 20.2429C75.7666 18.2191 77.7214 16.2627 79.7436 14.4074C81.2603 13.0245 83.8892 13.1931 85.271 14.5761C86.8888 16.1277 87.1584 18.2865 85.6417 20.0405C83.7543 22.1993 81.6984 24.1557 79.5751 26.1121C79.0021 26.6518 77.991 26.7868 77.5529 26.9554Z"
      fill="url(#paint7_linear_6249:253466)"
    />
    <path
      d="M26.9972 22.2671C26.9635 24.3921 26.222 25.6739 24.7727 26.416C23.1887 27.2255 21.5372 27.1581 20.2227 25.9775C18.2005 24.1223 16.2457 22.1996 14.392 20.142C12.909 18.5229 13.1113 16.1954 14.5942 14.6438C15.9761 13.2271 18.5713 12.9235 20.1216 14.3065C22.245 16.1954 24.2335 18.2868 26.1546 20.3781C26.6939 20.9853 26.8287 21.896 26.9972 22.2671Z"
      fill="url(#paint8_linear_6249:253466)"
    />
    <defs>
      <linearGradient id="paint0_linear_6249:253466" x1="50" y1="0" x2="50" y2="88.1119" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
      <linearGradient id="paint1_linear_6249:253466" x1="50" y1="0" x2="50" y2="88.1119" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
      <linearGradient id="paint2_linear_6249:253466" x1="50" y1="0" x2="50" y2="88.1119" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
      <linearGradient id="paint3_linear_6249:253466" x1="50" y1="0" x2="50" y2="88.1119" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
      <linearGradient id="paint4_linear_6249:253466" x1="50" y1="0" x2="50" y2="88.1119" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
      <linearGradient id="paint5_linear_6249:253466" x1="50" y1="0" x2="50" y2="88.1119" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
      <linearGradient id="paint6_linear_6249:253466" x1="50" y1="0" x2="50" y2="88.1119" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_6249:253466"
        x1="79.7978"
        y1="13.4507"
        x2="79.7978"
        y2="26.9554"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_6249:253466"
        x1="20.1865"
        y1="13.4116"
        x2="20.1865"
        y2="26.9552"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6F737E" />
        <stop offset="1" stopColor="#2C3039" />
      </linearGradient>
    </defs>
  </svg>
)
export default SolarIcon

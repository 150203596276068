import appStorage from 'storage/appStorage'
import { ProjectType } from 'types/projects'

const key = 'project_form_auto_saved_data'

export type UnsavedProjectData = {
  unsavedData: Partial<ProjectType>
  pathname: string
  orgId: number
  address: string
  projectId: number
}

export function getUnsavedData() {
  return appStorage.getJSON(key) as UnsavedProjectData | undefined
}

export function setUnsavedData(data: UnsavedProjectData) {
  appStorage.setJSON(key, data)
}

export function clearUnsavedData() {
  appStorage.clear(key)
}

import DependentInput from 'elements/input/DependentInput'
import React from 'react'
import { BooleanInput, NumberInput, SelectInput, TextInput, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import NEM3ExportRateSummaryPanel from 'resources/utilityTariffs/Nem3SummaryPanel'
import useTranslateParse from 'util/useTranslateParse'
import { InputWithToolTip } from '../../TariffInputs'
import { UTILITY_TARIFF_DISTRIBUTED_GENERATION_RULES_CHOICES, utilityHasNEM3 } from '../../common'
import { useStyles } from '../../styles'
import CustomInputs from './CustomInputs'

type ExportCreditTypes = {
  source: string
  simpleFlatInput: boolean
  currencySymbol: string
  disabled?: boolean
}

const HasMinimumExportCredit: React.FC<ExportCreditTypes> = ({ source, simpleFlatInput, currencySymbol, disabled }) => {
  const classes = useStyles()
  const translate = useTranslate()
  return simpleFlatInput ? (
    <></>
  ) : (
    <DependentInput
      dependsOn={'tariffData'}
      resolve={(value) =>
        ['Gross Metering with Feed-in-tariff', 'Gross Metering', 'net_billing_with_credit_carryover'].includes(
          value?.solar_compensation_settings?.distributed_generation_rules
        )
      }
    >
      <div className={classes.row}>
        <InputWithToolTip
          message={
            'The minimum annual export credit guarantee. If the customer is unable to earn enough export credits to meet the annual credit guarantee they are paid the difference.'
          }
        >
          <span>{translate('Has Minimum Export Credit')}</span>
        </InputWithToolTip>
        <BooleanInput label="" source={'has_minimum_export_credit'} defaultValue={false} disabled={disabled} />
      </div>
      <DependentInput dependsOn="has_minimum_export_credit" value={true}>
        <div className={`${classes.row} ${classes.wrapRow}`}>
          <TextInput
            name={`${source}.minimum_annual_export_credit.value_by_year`}
            source={`${source}.minimum_annual_export_credit.value_by_year`}
            label={'Minimum Export Credit By Year'}
            endAdornment={`${currencySymbol}/year CSV`}
            className={classes.textInput}
            variant="outlined"
            fullWidth
            options={{
              InputLabelProps: {
                shrink: true,
              },
            }}
            disabled={disabled}
          />
          <NumberInput
            name={`${source}.minimum_annual_export_credit.number_of_years`}
            source={`${source}.minimum_annual_export_credit.number_of_years`}
            label={'Number Of Years'}
            className={classes.numInput}
            variant="outlined"
            fullWidth
            options={{
              InputLabelProps: {
                shrink: true,
              },
            }}
            disabled={disabled}
          />
        </div>
      </DependentInput>
    </DependentInput>
  )
}
type PropTypes = {
  currencySymbol: string
  countryIso2: string
  disabled?: boolean
  simpleFlatInput?: boolean
  overrideSource?: string
  hideLabel?: boolean
}

const SolarCompensationInputs: React.FC<PropTypes> = ({
  currencySymbol,
  countryIso2,
  disabled,
  simpleFlatInput = false,
  overrideSource = '',
  hideLabel = false,
}) => {
  const form = useForm()
  const classes = useStyles()
  const translate = useTranslate()
  const formState = useFormState()
  const formValues = formState?.values
  const tariffData = formValues?.tariffData
  const translateParse = useTranslateParse()
  const source = overrideSource || 'tariffData.solar_compensation_settings'
  const solarCompensationMechanism = tariffData?.solar_compensation_settings?.distributed_generation_rules
  const choices = countryIso2
    ? UTILITY_TARIFF_DISTRIBUTED_GENERATION_RULES_CHOICES.filter(
        (x) => !x?.areaFilter?.country_iso2 || x.areaFilter?.country_iso2?.includes(countryIso2)
      )
    : UTILITY_TARIFF_DISTRIBUTED_GENERATION_RULES_CHOICES
  const defaultSolarMech = UTILITY_TARIFF_DISTRIBUTED_GENERATION_RULES_CHOICES.find((x) =>
    x.countryDefaults?.includes(countryIso2)
  )?.id

  return (
    <>
      {!simpleFlatInput && (
        <TextInput
          className={classes.textInput}
          variant="outlined"
          options={{
            InputLabelProps: {
              shrink: true,
            },
          }}
          disabled={disabled}
          fullWidth
          name={`${source}.name`}
          source={`${source}.name`}
          label="Name (Optional)"
        />
      )}
      <InputWithToolTip
        interactive
        message={translateParse(
          'How excess solar energy sold (exported) back to the grid is compensated. This will impact the treatment of utility bill savings. Read more about the solar compensation mechanisms <a>here<a>',
          {
            a: (label: string) => (
              <a
                style={{ color: 'rgb(24, 144, 255)' }}
                href={
                  'https://support.opensolar.com/hc/en-us/articles/7411675557775-What-is-Solar-Compensation-Mechanism-on-an-Electricity-Tariff-Rate-'
                }
                target="_blank"
                rel="noreferrer"
              >
                {label}
              </a>
            ),
          }
        )}
      >
        <SelectInput
          choices={choices}
          className={simpleFlatInput ? classes.simpleInput : classes.textInput}
          variant="outlined"
          disabled={disabled}
          options={{
            InputLabelProps: {
              shrink: true,
            },
          }}
          defaultValue={defaultSolarMech}
          name={`${source}.distributed_generation_rules`}
          source={`${source}.distributed_generation_rules`}
          label={hideLabel ? '' : 'Solar Compensation Mechanism'}
        />
      </InputWithToolTip>
      <CustomInputs
        disabled={disabled}
        solarCompensationMechanism={solarCompensationMechanism}
        currencySymbol={currencySymbol}
        countryCode={countryIso2}
        simpleFlatInput={simpleFlatInput}
        source={source}
      />
      {formValues?.utility_name && utilityHasNEM3(formValues?.utility_name) && (
        <DependentInput
          dependsOn={'tariffData'}
          resolve={(value) => value?.solar_compensation_settings?.apply_nem3_export_rates}
        >
          <NEM3ExportRateSummaryPanel
            utilityName={formValues?.utility_name}
            tariffData={tariffData}
            sector={formValues?.sector}
          />
        </DependentInput>
      )}
      <HasMinimumExportCredit
        source={source}
        currencySymbol={currencySymbol}
        simpleFlatInput={simpleFlatInput}
        disabled={disabled}
      />
    </>
  )
}

export default SolarCompensationInputs

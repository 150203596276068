// Rules for ALL SolarRoof systems
// rail overhang must equal 40% or less of the interface spacing (space between feet / roof hooks)
// 18mm between modules or 20mm if using universal clamps
// Standard end clamps add 20mm to ends of rails
// vertical spacing of roof hooks = 0.5 - 0.75 x module height

// Note that there are specific AU/NZ structural recommendations in the manual here: https://www.clenergy.com/wp-content/uploads/2020/09/clenergy-pvezrack-solarroof-installation-guide.pdf

// Rules for Clenergy PV-ezRack SolarRoof Pro 2
// Same kit and components for landscape and portrait layouts on residential roofs, roof hook swivels
// hanger bolt used to screw into wood purlins

import { BomGeneratorAbstract } from '../bom_generators/BomGeneratorAbstract'
import {
  PerColumnRailBomGeneratorAbstract,
  VerticalRailPosition,
} from '../bom_generators/PerColumnRailBomGeneratorAbstract'
import {
  HorizontalRailPosition,
  PerRowRailBomGeneratorAbstract,
} from '../bom_generators/PerRowRailBomGeneratorAbstract'
import {
  BasicSpacingLayoutAdjusterAbstract,
  SpacingRuleSet,
} from '../layout_adjusters/BasicSpacingLayoutAdjusterAbstract'
import { MountingSystemAbstract } from '../MountingSystemAbstract'
import { CutAndSplicerAbstract } from '../post_processors/CutAndSplicerAbstract'
import { RoofHookAdderAbstract } from '../post_processors/RoofHookAdderAbstract'
import { getRailDirection } from '../railing'
import {
  CompatibilityParameters,
  Component,
  Direction,
  Fastener,
  Item,
  MountingCalcInput,
  MountingCalcResult,
  MountingComponentType,
  Panel,
  PanelBlock,
  RailComponent,
  RailType,
} from '../types'

const spacingRuleSet: SpacingRuleSet = {
  interPanel: { horizontalSpacing: 20, verticalSpacing: 20 },
  // thermal: { horizontalRule: { maxDistance: 17600, gapSize: 130 } } // NOT YET IMPLEMENTED
}
class SolarRoofProSpacingAdjuster extends BasicSpacingLayoutAdjusterAbstract {
  spacingRuleSet = spacingRuleSet
}

const railOverhang = 100 // This mean the rail pokes out 100mm each side. Clenergy don't mention what this value should be except to allow 18mm for end clamps

class SolarRoofProBomGenerator extends BomGeneratorAbstract {
  async generateBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    const starterBom = await this.generateStarterBom(result)
    return this.postProcess(starterBom)
  }

  generateStarterBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    if (getRailDirection(this.block.orientation, this.input.options) === 'horizontal') {
      return this.generateBomFrom(SolarRoofProHorizontalRailBomGenerator, result)
    } else {
      return this.generateBomFrom(SolarRoofVerticalRailBomGenerator, result)
    }
  }

  postProcess(result: MountingCalcResult): MountingCalcResult {
    const resultWithRoofHooks = new SolarRoofProRoofHookAdder(this.input).process(result)
    return new SolarRoofProRailSplicer(this.input).process(resultWithRoofHooks)
  }
}
export class SolarRoofPro extends MountingSystemAbstract {
  layoutAdjusterClass = SolarRoofProSpacingAdjuster
  bomGeneratorClass = SolarRoofProBomGenerator

  // getFastener is used to calculate the structural inputs which in turn dictate the number of roof hooks.
  // Note that fasteners may vary between module groups, but here we are just picking one as a default.
  getFastener(): Fastener {
    const railDirection = getRailDirection(this.input.panelBlocks[0].moduleGrid.moduleLayout(), this.input.options)
    return chooseFastener(chooseHook(this.input, null, chooseRail(this.input, railDirection)))
  }

  getCompatibilityParameters(): CompatibilityParameters {
    return {
      integrations: ['Segen', 'City Plumbing', 'HDM'],
      slopeRange: [0, 60],
      moduleThicknessRange: [30, 46],
      roofHeightRange: [0, 30],
      roofTypes: ['Tile Clay', 'Tile Concrete', 'Tile Slate'],
      roofTypeRequired: false, //if compatibility here is only these roof types then roof type should be required?
      featureFlag: 'mounting_SolarRoofPro',
    }
  }
}

class SolarRoofProHorizontalRailBomGenerator extends PerRowRailBomGeneratorAbstract {
  // Horizontal and vertical railing use the same components, so we have shared functions

  chooseRail() {
    return chooseRail(this.input, 'horizontal')
  }

  chooseMidClamp() {
    return chooseMidClamp(this.input)
  }

  chooseEndClamp() {
    return chooseEndClamp(this.input)
  }

  chooseEndCap() {
    return chooseEndCap(this.input)
  }

  getRailPositions(panel: Panel): HorizontalRailPosition[] {
    // Space the rails at 60% of panel height. Approx. 1⁄2 to 3⁄4 of module height is advised.
    const distanceFromRailToPanelEdge = (panel.height * 0.6) / 2

    return [
      {
        top: distanceFromRailToPanelEdge,
        left: -railOverhang,
        right: railOverhang,
      },
      {
        top: panel.height - distanceFromRailToPanelEdge,
        left: -railOverhang,
        right: railOverhang,
      },
    ]
  }
}

class SolarRoofVerticalRailBomGenerator extends PerColumnRailBomGeneratorAbstract {
  chooseRail() {
    return chooseRail(this.input, 'vertical')
  }

  chooseMidClamp() {
    return chooseMidClamp(this.input)
  }

  chooseEndClamp() {
    return chooseEndClamp(this.input)
  }

  chooseEndCap() {
    return chooseEndCap(this.input)
  }

  getRailPositions(panel: Panel): VerticalRailPosition[] {
    // Space the rails at 60% of panel height. Approx. 1⁄2 to 3⁄4 of module height is advised.
    const distanceFromRailToPanelEdge = (panel.width * 0.6) / 2

    return [
      {
        left: distanceFromRailToPanelEdge,
        top: -railOverhang,
        bottom: railOverhang,
      },
      {
        left: panel.width - distanceFromRailToPanelEdge,
        top: -railOverhang,
        bottom: railOverhang,
      },
    ]
  }
}

function chooseRail(input: MountingCalcInput, direction: Direction): RailComponent {
  return {
    type: MountingComponentType.RAIL,
    railType: 'continuous',
    direction,
    name: input.options.railLength === 4600 ? 'ER-R-PRO4600' : 'ER-R-PRO3500',
    length: 0,
    top: 0,
    left: 0,
  }
}

function chooseMidClamp(input: MountingCalcInput): Item {
  const componentCode = input.options.clampColour === 'silver' ? 'CLMC-U/30/46-G' : 'CLMC-U/30/46-G/BA'
  return {
    name: componentCode,
    components: [
      {
        name: componentCode,
        type: MountingComponentType.CLAMP,
        left: -10,
        top: -10,
      },
    ],
  }
}

function chooseEndClamp(input: MountingCalcInput): Item {
  const componentCode = input.options.clampColour === 'silver' ? 'CLMC-U/30/46-G' : 'CLMC-U/30/46-G/BA'
  return {
    name: componentCode,
    components: [
      {
        name: componentCode,
        type: MountingComponentType.CLAMP,
        left: -10,
        top: -10,
      },
    ],
  }
}

function chooseEndCap(input: MountingCalcInput): Item {
  const componentCode = input.options.endCapColour === 'grey' ? 'ER-CAP-PROG' : 'ER-CAP-PROB'
  return {
    name: componentCode,
    components: [
      {
        name: componentCode,
        type: MountingComponentType.OTHER,
        left: -18.1,
        top: -24.8,
      },
    ],
  }
}
function chooseHook(input: MountingCalcInput, panelBlock: PanelBlock | null, rail: RailComponent) {
  const componentCode = input.options.roofHook as string
  return [
    {
      name: componentCode,
      components: [
        {
          name: componentCode,
          type: MountingComponentType.ROOF_HOOK,
          left: -10,
          top: -10,
        },
      ],
    },
  ]
}

function chooseFastener(hooks): Fastener {
  return {
    includedInRoofHookProduct: false,
    name: 'ER-US-6.3-70-BW-BX',
    qtyPerRoofHook: 2,
    length: 70,
    diameter: 6.3,
    components: [],
  }
}

class SolarRoofProRoofHookAdder extends RoofHookAdderAbstract {
  spacingRuleSet = spacingRuleSet
  railOverhang = railOverhang

  getRailType(input: MountingCalcInput): RailType {
    return 'continuous'
  }

  chooseHook(input: MountingCalcInput, panelBlock: PanelBlock, rail: RailComponent) {
    return chooseHook(input, panelBlock, rail)
  }

  chooseFastener(hook: Item[]) {
    return chooseFastener(hook)
  }
}

class SolarRoofProRailSplicer extends CutAndSplicerAbstract {
  isTarget(component: Component): boolean {
    return component?.type === MountingComponentType.RAIL
  }

  chooseSplice(railName: string, input: MountingCalcInput): Item[] {
    return [
      {
        name: 'ER-SP-ST',
        components: [
          {
            type: MountingComponentType.SPLICE,
            name: 'ER-SP-ST',
            top: 0,
            left: 0,
          },
        ],
      },
    ]
  }

  getFullLength(railName: string): number {
    if (railName === 'ER-R-PRO3500') return 3500
    if (railName === 'ER-R-PRO4600') return 4600
    return 0
  }
}

import { RoleSelectOptionType, RoleWithDisplay } from './types'

export const convertRolesToOptions = (roles: RoleWithDisplay[]): RoleSelectOptionType[] => {
  return roles?.map((role) => {
    return {
      url: role.url,
      display: role.display,
    }
  })
}

import PropTypes from 'prop-types'
import { Component } from 'react'
import { withTranslate } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import Panel, { StudioPanelContext } from '../Designer/Panel'

import { getMeasurementsFromState, metersToFeet, trimDecimalPlaces } from '../util/misc'

import { DistanceNumberField } from 'elements/field/DistanceNumberField'
import withStudioSignals from 'Studio/signals/withStudioSignals'
import { refreshPanelGeneric, stripKeyForActiveTextField } from '../Studio/Utils'
class PanelEdge extends Component {
  constructor(props) {
    super(props)
    var state = {
      visible: false,
      edge: null,
      allowEdit: true,
    }

    var injectState = props.state ? props.state : null
    if (props.edge) {
      injectState = this.stateFromObject(props.edge)
    }

    //Override with any supplied state - for use in storybook
    if (injectState) {
      for (var key in injectState) {
        state[key] = injectState[key]
      }
    }

    this.state = state
  }

  componentDidMount() {
    this.props.useStudioSignalsLazy(this.refreshPanel, ['objectChanged', 'objectSelected', 'sceneGraphChanged'], this)
  }

  stateFromObject(edge) {
    if (!edge) {
      return { visible: false }
    }
    var _state = {
      visible: true,
      edge: edge,
      length: this.format(this.getLength(edge)),
      maxLength: this.format(this.getLengthConstraint(edge)),
    }
    stripKeyForActiveTextField.call(this, _state, edge)
    return _state
  }

  getLength(object) {
    return Math.round(object.getLength() * 1000) / 1000
  }

  getLengthConstraint(object) {
    return Math.round(object.getLengthConstraint() * 100) / 100
  }

  refreshPanel() {
    refreshPanelGeneric.call(this, 'OsEdge')
  }

  format = (value) => {
    const { measurements } = this.props
    if (measurements === 'imperial') {
      return trimDecimalPlaces(metersToFeet(value), 4)
    }
    return value
  }

  applyLength(edge, lengthValue) {
    if (lengthValue !== 0) {
      edge.setLength(lengthValue)
    }
  }

  render() {
    if (!this.state.visible) {
      return null
    } else {
      const { edge } = this.state
      const { translate } = this.props
      var type = edge.isWire() ? 'wire' : 'edge'

      return (
        <Panel
          showTools={this.state.allowEdit}
          selectedObject={this.state.edge}
          title={type === 'edge' ? translate('Edge') : translate('Wire')}
          summary={null}
          content={null}
          feature={
            <StudioPanelContext.Provider value={{ context: this, object: edge }}>
              <DistanceNumberField
                disabled={!this.state.allowEdit}
                name={`edge-length`}
                label={translate('Length')}
                value={this.state.length}
                maxDecimalPlaces={3}
                minValue={0.001}
                maxValue={this.state.maxLength}
                minErrorMsg={translate('Supply a value greater than 0.')}
                maxErrorMsg={translate('Value provided exceeds limit.')}
                measurementStd={this.props.measurements}
                convertTo="metric"
                onBlur={(currentValue) => {
                  if (edge.belongsToStructure()) {
                    this.applyLength(edge, currentValue)
                  }
                }}
                onChange={(newValue) => {
                  if (!edge.belongsToStructure()) {
                    this.applyLength(edge, newValue)
                  }
                }}
                onKeyDown={(currentValue, rawEvent) => {
                  switch (rawEvent.key) {
                    case 'Enter':
                      this.applyLength(edge, currentValue)
                      break
                    case 'Escape':
                      window.editor.deselect()
                      break
                    default:
                      break
                  }
                }}
              />
            </StudioPanelContext.Provider>
          }
        />
      )
    }
  }
}

PanelEdge.propTypes = {
  state: PropTypes.object,
}

export default compose(
  withTranslate,
  withStudioSignals,
  connect(
    (state) => ({
      measurements: getMeasurementsFromState(state),
    }),
    {}
  )
)(PanelEdge)

import { logAmplitudeEvent } from 'amplitude/amplitude'
import { useEffect } from 'react'

export const useTrackPartnerTags = () => {
  useEffect(() => {
    // set session storage vars for campaign tracking
    const path = window.location.hash.substring(window.location.hash.indexOf('#') + 2)
    const params = new URLSearchParams(path.substring(path.indexOf('?')))
    const partner = params.get('partner_source')
    const campaign = params.get('campaign')
    if (partner) sessionStorage.setItem('os_partner_source', partner)
    if (campaign) sessionStorage.setItem('os_partner_campaign', campaign)

    if (partner || campaign) {
      logAmplitudeEvent('partner_registration_form_viewed', {
        partner: partner || 'unknown',
        campaign: campaign || 'unknown',
      })
    }
  })
}

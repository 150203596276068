export const branchData = [
  {
    name: 'Aberdeen Causeway CPS',
    address: '101 Causeway End, Off Charles Street, Aberdeen, AB25 3TQ',
    manager: 'Aaron Ross',
    contactNumber: '01224 642594',
    email: 'cpsaberdeen@cityplumbing.co.uk',
    weekdayHours: '07:00 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Aberdeen Mastrick CPS',
    address: 'City Heating Spares, 24 Whitemyres Avenue, Mastrick, Aberdeen, AB16 6HQ',
    manager: 'James M. Hendry',
    contactNumber: '01224 663200',
    email: 'cpsaberdeen2@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Aberdeen PTS',
    address: '6 Abbotswell Road, Aberdeen, Aberdeen, AB12 3AB',
    manager: 'Jim Ross',
    contactNumber: '01224 893700',
    email: '6044.sales@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Alloa CPS',
    address: 'Unit 1 Dumyat Business Park, Bond Street, Tullibody, Alloa, FK10 2PB',
    manager: 'Craig Lewis',
    contactNumber: '01259 226790',
    email: 'cpsalloa@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Barrow CPS',
    address: 'Unit 3 Forge Close, Barrow-In-Furness, Barrow-In-Furness, LA14 2UF',
    manager: 'Heather Delves',
    contactNumber: '01229 842470',
    email: 'cpsbarrow@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Bellshill CPS',
    address: '17 Earn Avenue, Righead Ind Estate, Bellshill, Lanarkshire, ML4 3LW',
    manager: 'Paul Mcginley',
    contactNumber: '01698 573300',
    email: 'cpsbellshill@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Blackpool CPS',
    address: 'City Heating Spares, Units 8 & 9 Canberra Court, Amy Johnson Way, Blackpool, FY4 2FD',
    manager: 'Jennifer Day',
    contactNumber: '01253 403406',
    email: 'blackpool@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Carlisle CPS',
    address: 'City Heating Spares, Units 1-2, 24 Brunthill Road, Kingstown Ind Est, Carlisle, CA3 0EH',
    manager: 'Jai Wood',
    contactNumber: '01228 549387',
    email: 'cpscarlisle@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Coatbridge CPS',
    address: 'Unit 3, Calder Park, North Caldeen Road, Coatbridge, Lanarkshire, ML5 4EF',
    manager: 'Andrew Learmonth',
    contactNumber: '01236 426061',
    email: 'cpscoatbridge@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Coatbridge PTS',
    address: 'Unit 2a M8 Monklands Est, Kirkshaws Road, Coatbridge, ML5 4RP',
    manager: 'Derek Wright',
    contactNumber: '01236 707960',
    email: '6215.sales@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Dalkeith CPS',
    address: 'Unit 1, Grannies Park Industrial Est, Dalkeith, Midlothian, EH22 1JY',
    manager: 'Alf Lowrie',
    contactNumber: '0131 663 1555',
    email: 'cpsdalkeith@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Darlington CPS',
    address: 'Units 4-6, Cleveland Trd Est, Darlington, Durham, DL1 2PB',
    manager: 'Bobby Wray',
    contactNumber: '01325 481 357',
    email: 'cpdarlington@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Douglas CPS',
    address: 'City Heating Spares, The Milestone, Peel Road, Douglas, Isle Of Man, IM1 5ED',
    manager: 'Jayne Reid',
    contactNumber: '01624 674643',
    email: 'douglasp@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Dumbarton CPS',
    address: 'Unit 12 Birch Road, Broadmeadow Industrial Estate, Dumbarton, G82 2RE',
    manager: 'Tony Murray',
    contactNumber: '01389 762448',
    email: 'cpsdumbarton@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Dumfries CPS',
    address: 'City Heating Spares, Unit A, St Marys Ind. Estate, Dumfries, DG1 1NA',
    manager: 'Greg Wallace',
    contactNumber: '01387 257685',
    email: 'cpsdumfries@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Dundee CPS',
    address: 'Unit 2a Edison Place, Dryburgh Industrial Estate, Dundee, DD2 3QU',
    manager: 'Sye Sturrock',
    contactNumber: '01382 825625',
    email: 'cpsdundee@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Dunfermline Rosyth CPS',
    address: 'Unit 4, Primrose Lane Industrial Est., Rosyth, Dunfermline, KY11 2SF',
    manager: 'Beverley Carter',
    contactNumber: '01383 410011',
    email: 'cpsdunrosyth@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Durham CPS',
    address: 'Unit C2, Abbey Rd Retail & Business Prk, Abbey Road, Durham, DH1 5HA',
    manager: 'Andrew Gillies',
    contactNumber: '0191 386 2121',
    email: 'cpsdurham@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'East Kilbride CPS',
    address: 'Unit 3, Colvilles Park, East Kilbride, Glasgow, G75 0GZ',
    manager: 'Andrew Horn',
    contactNumber: '01355 579000',
    email: 'cpseastkilbride@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Edinburgh Albert Street CPS',
    address: '61 Albert Street, Edinburgh, Edinburgh, EH7 5LW',
    manager: 'Peter Cryans',
    contactNumber: '0131 561 3500',
    email: 'cpsedinburgh@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Edinburgh Newbridge PTS',
    address: 'Unit 1, Chariot Drive, Newbridge, Edinburgh, EH28 8PA',
    manager: 'Derek Marshall',
    contactNumber: '0131 335 9120',
    email: '6214.sales@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Edinburgh Peffermill CPS',
    address: 'Unit 1 Block F Prestonfield Pk, Peffermill Industrial Estate, Edinburgh, EH16 5UY',
    manager: 'Stuart Renton',
    contactNumber: '0131 6569800',
    email: 'cpspeffermill@cityplumbing.co.uk',
    weekdayHours: '07:00 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Edinburgh Stenhouse CPS',
    address: '23 Stenhouse Mill Wynd, Stenhouse Mill Wynd Ind Est, Edinburgh, EH11 3XX',
    manager: 'Stuart Aiton',
    contactNumber: '0131 443 4114',
    email: 'cpsedinwest@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Elgin CPS',
    address: 'Unit 3, Elgin Trade Park, Ashgrove Road, Elgin, IV30 1AZ',
    manager: 'Jason Larsen',
    contactNumber: '01343 58 9590',
    email: 'elgin@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Falkirk CPS',
    address: 'Unit 11 Almond Road, Middlefield Industrial Estate, Falkirk, FK2 9HQ',
    manager: 'Scott Boyd',
    contactNumber: '01324 639825',
    email: 'cpsfalkirk@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Galashiels CPS',
    address: 'City Heating Spares, Unit 2, Galabank Business Park, Wilderhaugh Street, Galashiels, TD1 1PR',
    manager: 'Graeme Mccormack',
    contactNumber: '01896 754098',
    email: 'cpsgalashiels@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Glasgow Anniesland CPS',
    address: 'Unit 37 Anniesland Business Pa, Netherton Road, Glasgow, G13 1EU',
    manager: 'Damian Morgan',
    contactNumber: '0141 200 0163',
    email: 'anniesland@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Glasgow Hillington CPS',
    address: 'City Heating Spares, 65-67 Kelvin Avenue, Hillington Ind Estate, Glasgow, G52 4LP',
    manager: 'Eric Taylor',
    contactNumber: '0141 882 7700',
    email: 'cpshilling@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Glasgow Lawmoor Road CPS',
    address: 'Unit 3a Southpoint, Dixon Blazes Ind, Lawmoor Road, Glasgow, G5 0UG',
    manager: 'Steven Bisset',
    contactNumber: '0141 420 2055',
    email: 'cpsglasgow@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Glasgow London Road CPS',
    address: 'City Heating Spares, Unit 6 London Road Trading Est, 2500 London Road, Glasgow, G32 8XZ',
    manager: 'Steven Mccallum',
    contactNumber: '0141 778 3429',
    email: 'cpsglasgowruther@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Greenock CPS',
    address: 'Unit 2, Block 3, East Hamilton Street, Cappielo, Greenock, PA15 2TQ',
    manager: 'Callum King',
    contactNumber: '01475 721666',
    email: 'cpsgreenock@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Inverness CPS',
    address: '12-14 Harbour Road, Longman Estate, Inverness, IV1 1SY',
    manager: 'Mark Crane',
    contactNumber: '01463 716166',
    email: 'invernesscps@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Irvine PTS',
    address: 'Unit 11, Arkwright Way, North Newmoor Ind. Estate, Irvine, KA11 4JU',
    manager: 'Graeme Withers',
    contactNumber: '01294 219250',
    email: '6153.sales@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Kendal CPS',
    address: 'Unit 4a, Merced Building, Parkside Road, Kendal, LA9 7EN',
    manager: 'Ken Houghton',
    contactNumber: '01539 742150',
    email: '6126.sales@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Kilmarnock CPS',
    address: 'City Heating Spares, Unit 2, Loreny Ind. Estate, Simonsburn Road, Kilmarnock, KA1 5LA',
    manager: 'Raymond Hay',
    contactNumber: '01563 536829',
    email: 'cpskilmarnock@cityplumbing.co.uk',
    weekdayHours: '07:00 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Kirkcaldy CPS',
    address: 'Unit 6, Frederick Wilson Park, Hayfield Place Kirkcaldy, KY2 5DR',
    manager: 'Scott Findlay',
    contactNumber: '01592 649930',
    email: 'cpskirkcaldy@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Livingston CPS',
    address: 'Unit 27, Houston Ind Est, Off Houston Road, Livingston, EH54 5BZ',
    manager: 'Craig Rawding',
    contactNumber: '01506 444818',
    email: 'livingstone@cityplumbing.co.uk',
    weekdayHours: '07:00 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Loanhead CPS',
    address: 'City Heating Spares, Unit 7, Pentland Ind Estate, Main Street, Loanhead, EH20 9QH',
    manager: 'Declan Smith',
    contactNumber: '0131 448 0729',
    email: 'cpsloanhead@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Morecambe CPS',
    address: 'Unit 2e & 2f Northgate, White Lund Industrial Estate, Morecambe, Lancashire, LA3 3SZ',
    manager: 'Tyne-Louise Bentley',
    contactNumber: '01524 585260',
    email: 'cpsmorecambe@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Nelson CPS',
    address: 'Unit 4, Valley Forge Bus Park, Reedyford Road, Nelson, BB9 8TU',
    manager: 'Lisa Wood',
    contactNumber: '01282 877270',
    email: 'cpsnelson@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Newcastle PTS',
    address: 'Unit 2; New York Way , New York Industrial Park , Newcastle Upon Tyne , NE27 0QF',
    manager: 'Bill Carr',
    contactNumber: '0191 256 5400',
    email: '6138.sales@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'North Shields CPS',
    address: 'Unit G1, Narvik Way, Tyne Tunnel Trading Estate, North Shields, NE29 7XJ',
    manager: 'Scott Stephenson',
    contactNumber: '0191 2578256',
    email: 'northshields@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Oban CPS',
    address: '7e Glengallan Road, Oban, Oban, PA34 4HG',
    manager: 'David Corr',
    contactNumber: '01631 567640',
    email: 'cpsoban@cityplumbing.co.uk',
    weekdayHours: '07:30 – 16:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Perth CPS',
    address: 'City Heating Spares, Ruthvenfield Way, Inveralmond Ind Est, Perth, PH1 3UF',
    manager: 'Julie Fitzpatrick',
    contactNumber: '01738 444450',
    email: 'cpsperth@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'South Shields CPS',
    address: 'Unit 4 Western Approach, South Shields, South Shields, NE33 5QY',
    manager: 'Mick Johnston',
    contactNumber: '0191 455 5582',
    email: 'cpssouthshields@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Stirling CPS',
    address: 'City Heating Spares, Unit 1-4, 8 Whitehouse Road, Stirling, FK7 7SP',
    manager: 'Greig Bain',
    contactNumber: '01786 451239',
    email: 'cpsstirling@cityplumbing.co.uk',
    weekdayHours: '07:00 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Stockton CPS',
    address: 'North Tees Industrial Estate, Stockton On Tees, Teesside, TS18 2RS',
    manager: 'Adam Jones',
    contactNumber: '01642 667700',
    email: '6140.sales@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Tranent CPS',
    address: 'City Heating Spares, Block A Unit 3 Macmerry Ind Es, Macmerry, Tranent, EH33 1RD',
    manager: 'Grant Crichton',
    contactNumber: '01875 616284',
    email: 'cpstranent@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Wishaw CPS',
    address: 'City Heating Spares, Unit D, Excelsior Park, Canyon Road, Wishaw, ML2 0EG',
    manager: 'Stephen Rodger',
    contactNumber: '01698 373696',
    email: 'cpsmotherwell@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
  {
    name: 'Workington PTS',
    address: 'Unit 5 Enterprise Ct, Lakes Rd, Derwent Howe Ind Est, Workington, Cumbria, CA14 3YP',
    manager: 'Kerry Bartholomew',
    contactNumber: '01900 872777',
    email: '6046.sales@cityplumbing.co.uk',
    weekdayHours: '07:30 – 17:00',
    saturdayHours: '08:00 – 12:00',
    sundayHours: 'Closed',
  },
]

import { orgSelectors } from 'ducks/orgs'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import type { FilterType, GetDefaultFilterValuePropsType } from 'types/selectComponent'
import { getFeatureFlagSelector } from 'util/split'

export const StockAvailabilityFilter: FilterType = {
  key: 'require_stock',
  label: 'Current stock availability',
  options: [{ title: 'In stock', value: 'true' }],
  isHidden() {
    const reduxState = window.reduxStore?.getState()
    if (reduxState == null) {
      return true
    }
    const hardwareSupplier = orgSelectors.getSelectedHardwareSupplier(reduxState)
    if (hardwareSupplier === HardwareSupplierEnum.Segen || hardwareSupplier === HardwareSupplierEnum.CityPlumbing) {
      return false
    } else if (hardwareSupplier === HardwareSupplierEnum.SolarOutlet) {
      const getFeatureValue = getFeatureFlagSelector(reduxState)
      const enableNewHardwareOrdering = getFeatureValue('hardware_ordering', 'on')
      if (enableNewHardwareOrdering) {
        return false
      }
    }

    return true
  },
  getDefaultValue({ specsData }: GetDefaultFilterValuePropsType) {
    return 'true'
  },
}

import LaunchIcon from '@material-ui/icons/Launch'
import PowerIcon from '@material-ui/icons/Power'
import { Button, styled } from 'opensolar-ui'
import { ExtendedHardwareSupplierConfig } from 'projectSections/sections/manage3/hardware/DistributorChip'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import { useHistory } from 'react-router-dom'
import { CityPlumbingConnectUserButton } from 'services/cityPlumbing/CityPlumbingConnectUserButton'
import HdmConnectUserButton from 'services/hdm/HdmConnectUserButton'
import SegenConnectUserButton from 'services/segen/SegenConnectUserButton'
import VtacConnectUserButton from 'services/vtac/VtacConnectUserButton'

const ShopButton = styled(Button)(({ theme }) => ({
  background: `${COLOR_PALETTE.blue3} !important`,
  color: `${theme.palette.common.white} !important`,
}))

const FlexWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
})

const ButtonContent = styled('div')({
  display: 'flex',
  gap: 5,
})

const ConnectButton = ({ config }: { config: ExtendedHardwareSupplierConfig }) => {
  const history = useHistory()

  const directToShop = (supplier) => {
    history.push(`/shop/distributor/${supplier}`)
  }

  if (!config.isVisible) {
    return <></>
  }

  if (config.isConnected) {
    const shopButton = (
      <FlexWrapper>
        <ShopButton onClick={() => directToShop(config.filterKey)}>Shop now</ShopButton>
      </FlexWrapper>
    )

    return shopButton
  }

  if (!config.isConnected) {
    const supplierKey = config.logoUrl.match(/(segen|city|hdm|vtac)/)?.[0] || 'default'

    const connectUserButton = {
      segen: (
        <FlexWrapper>
          <SegenConnectUserButton
            label={'Connect'}
            variant={'outlined'}
            layout={null}
            allowOrdering={false}
            trackSource={'shop_page'}
            icon={<PowerIcon />}
          />
          <Button
            variant={'outlined'}
            color={'default'}
            onClick={() => {
              window.open(config.registrationUrl, '_blank')
            }}
          >
            <ButtonContent>
              <LaunchIcon />
              Register
            </ButtonContent>
          </Button>
        </FlexWrapper>
      ),
      city: (
        <FlexWrapper>
          <CityPlumbingConnectUserButton
            label={'Connect'}
            variant={'outlined'}
            layout={null}
            allowOrdering={false}
            trackSource={'shop_page'}
          />
          <Button
            variant={'outlined'}
            color={'default'}
            onClick={() => {
              window.open(config.registrationUrl, '_blank')
            }}
          >
            <ButtonContent>
              <LaunchIcon />
              Register
            </ButtonContent>
          </Button>
        </FlexWrapper>
      ),
      hdm: (
        <FlexWrapper>
          <HdmConnectUserButton label={'Connect'} variant={'outlined'} trackSource={'shop_page'} icon={<PowerIcon />} />
          <Button
            variant={'outlined'}
            color={'default'}
            onClick={() => {
              window.open(config.registrationUrl, '_blank')
            }}
          >
            <ButtonContent>
              <LaunchIcon />
              Register
            </ButtonContent>
          </Button>
        </FlexWrapper>
      ),
      vtac: (
        <FlexWrapper>
          <VtacConnectUserButton
            label={'Connect'}
            variant={'outlined'}
            trackSource={'shop_page'}
            icon={<PowerIcon />}
          />
          <Button
            variant={'outlined'}
            color={'default'}
            onClick={() => {
              window.open(config.registrationUrl, '_blank')
            }}
          >
            <ButtonContent>
              <LaunchIcon />
              Register
            </ButtonContent>
          </Button>
        </FlexWrapper>
      ),
      default: <></>,
    }[supplierKey]

    if (connectUserButton) return connectUserButton
  }

  return <></>
}

export default ConnectButton

import { Chip, ClickAwayListener, makeStyles, Paper } from '@material-ui/core'
import { Add, DeleteOutlined, EditOutlined, RestoreFromTrashOutlined, UnarchiveOutlined } from '@material-ui/icons'
import Tooltip from 'elements/tooltip/Tooltip'
import { Button, IconButton } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { ReactNode, useState } from 'react'
import { TextInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { OpenSolarThemeType } from 'Themes'
import { WorkflowStageType } from 'types/workflows'
import Actions from './Actions'

const useStyles = makeStyles(
  (theme: OpenSolarThemeType) => ({
    stageWrapper: {
      marginBottom: 15,
      flexWrap: 'wrap',
      rowGap: 10,
      padding: 10,
      '& .MuiIconButton-root': {
        padding: 0,
      },
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      '& p': {
        margin: 0,
      },
    },
    stageDetail: {
      marginLeft: 'auto',
      '& p': {
        color: theme.greyMid1,
      },
    },
    dragIcon: {
      marginRight: 5,
    },
    stageSuperTitle: {
      display: 'inline-block',
      marginRight: 8,
      minWidth: 60,
    },
    titleInput: {
      minWidth: 300,
      margin: 0,
    },
    titleFixed: {
      paddingTop: 8,
      paddingBottom: 5,
    },
    disabled: {
      color: theme.greyLight2,
    },
    archiveChip: {
      marginLeft: 10,
    },
    deleteBtn: {
      marginLeft: 10,
    },
    editIcon: {
      marginLeft: 10,
    },
  }),
  { name: 'Stage' }
)

interface PhaseTypes {
  dragHandle: ReactNode | undefined
  stage: WorkflowStageType
  stageId: number
  isLastStage: boolean
  setEditingAction: (action) => void
}

const Stage: React.FC<PhaseTypes> = (props) => {
  const { stage, stageId, setEditingAction, dragHandle } = props
  const form = useForm()
  const classes = useStyles()
  const translate = useTranslate()
  const formValues = useFormState().values
  const [isEditing, setIsEditing] = useState(false)
  const [isHovering, setIsHovering] = useState(false)

  const toggleDelete = () => {
    if (typeof stage.id === 'string') {
      const newStages = [...formValues.workflow_stages.filter((x) => x.id !== stage.id)]
      form.mutators.updateField('workflow_stages', newStages)
    } else if (stage.is_archived) {
      form.mutators.updateField(`workflow_stages.${stageId}`, { ...stage, is_archived: false })
    } else {
      form.mutators.updateField(`workflow_stages.${stageId}`, { ...stage, forDelete: !stage.forDelete })
    }
  }
  return (
    <Paper className={`${classes.stageWrapper}  ${stage.forDelete ? classes.disabled : ''}`} key={'stage-' + stageId}>
      <div
        className={classes.row}
        onMouseEnter={() => {
          setIsHovering(true)
        }}
        onMouseLeave={() => {
          setIsHovering(false)
        }}
      >
        <span className={classes.dragIcon}>{dragHandle}</span>
        <ClickAwayListener
          onClickAway={() => {
            setIsEditing(false)
          }}
        >
          <div className={classes.row}>
            <p className={classes.row}>
              <span className={classes.stageSuperTitle}>
                <strong>{translate('Stage %{stage_number}: ', { stage_number: stageId + 1 })}</strong>
              </span>

              {isEditing ? (
                <TextInput
                  source={`workflow_stages.${stageId}.title`}
                  label={false}
                  className={classes.titleInput}
                  disabled={!isEditing}
                  onBlur={() => setIsEditing(false)}
                />
              ) : (
                <span onClick={() => setIsEditing(true)} className={classes.titleFixed}>
                  {stage.title}
                </span>
              )}
            </p>
            {!isEditing && isHovering && (
              <IconButton
                className={classes.editIcon}
                onClick={() => {
                  setIsEditing(true)
                }}
              >
                <EditOutlined />
              </IconButton>
            )}
          </div>
        </ClickAwayListener>

        <div className={`${classes.stageDetail} ${classes.row}`}>
          {typeof stage.id === 'number' && <p>ID: {stage.id}</p>}
          {stage.is_archived && <Chip label={'Archived'} className={classes.archiveChip} />}
          <Tooltip
            title={
              !stage.forDelete && props.isLastStage ? translate("Can't delete last stage from workflow") : undefined
            }
          >
            <div>
              <IconButton
                onClick={toggleDelete}
                className={classes.deleteBtn}
                disabled={!stage.forDelete && props.isLastStage}
              >
                {stage.forDelete ? (
                  <RestoreFromTrashOutlined />
                ) : stage.is_archived ? (
                  <UnarchiveOutlined />
                ) : (
                  <DeleteOutlined />
                )}
              </IconButton>
            </div>
          </Tooltip>
        </div>
      </div>
      <p>
        <strong>{translate('Actions')}</strong>
      </p>
      <div style={{ display: 'flex' }}>
        <Actions stageId={stageId} stage={stage} setEditingAction={setEditingAction} />
        <Button
          startIcon={<Add />}
          variant="text"
          color="default"
          onClick={() => {
            setEditingAction({
              stageId: stageId,
              action: { id: 'newAction-' + stage.actions.length, title: undefined, share_with_orgs: [] },
            })
          }}
          disabled={stage.forDelete}
          size="small"
        >
          {translate('Action')}
        </Button>
      </div>
    </Paper>
  )
}

export default Stage

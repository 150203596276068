import { makeStyles, Menu, MenuItem, Portal, useMediaQuery } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDownOutlined'
import { permissionsSelectors } from 'ducks/permissions'
import { Button } from 'opensolar-ui'
import { useGetFujiTourNextStepQuery } from 'persistentContent/tours/fuji/useGetFUJITourSteps'
import { clearUnsavedData } from 'projectSections/utils/unsavedDataStore'
import React, { useCallback, useContext } from 'react'
import { FormContext, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme: any) => ({
  actions: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: 0,
      display: 'flex',
      height: 50,
      padding: '0 20px',
      zIndex: 1000,
      boxSizing: 'border-box',
      left: 0,
      width: '100%',
      background: 'rgba(219, 219, 219, 0.8)',
    },
    [theme.breakpoints.down('xs')]: { bottom: 50 },
  },
  button: {
    // width: 180,
    position: 'relative',
    margin: 0,
    padding: 0,
    [theme.breakpoints.down('md')]: {
      marginLeft: 'auto',
      marginTop: 10,
    },
  },
  label: {
    padding: '8px 10px',
  },
  list: {
    padding: 0,
    backgroundColor: theme.headerBackgroundColor,
  },
  menuItem: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.75,
    background: 'none',
    color: theme.headerFontColor,
    '&:hover': {
      cursor: 'pointer',
      background: theme.themeColor,
    },
  },
  endIcon: {
    padding: 4,
    borderLeft: '1px solid rgba(0,0,0,0.87)',
  },
}))

const CreateButton = () => {
  const form = useForm()
  const { allowCreate } = useSelector(permissionsSelectors.getPermissionByKey('project'))
  const translate = useTranslate()
  const { setOnSave } = useContext(FormContext)
  const history = useHistory()
  const submitting = form.getState().submitting
  const { tour, step } = useSelector((state: any) => state.tour)
  const nextStep = useGetFujiTourNextStepQuery(step)
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const expandDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSave = (to: string) => {
    if (!setOnSave) return
    setOnSave(function (values: any, redirect: string, form: any, handleSubmit: Function) {
      // Goes to `useProjectSave.js`
      return handleSubmit(values, to, form)
        .then((response: any) => {
          //customized actions onsuccess
          const fujiTourQuery = tour === 'fuji' ? nextStep : ''
          if (fujiTourQuery) {
            history.push({ search: fujiTourQuery })
          }
          clearUnsavedData()
        })
        .catch(() => {
          //customized actions onerror
        })
    })
    form.submit()
  }

  const closeDropdown = useCallback(() => {
    setAnchorEl(null)
  }, [])

  return (
    <div className={classes.actions}>
      <Button
        classes={{ root: classes.button }}
        aria-controls="simple-menu"
        aria-haspopup="true"
        id={'create-project-button'}
        size="small"
        color="primary"
        variant="contained"
        disabled={submitting || !allowCreate}
        onClick={(e) => e.preventDefault()}
      >
        <span onClick={() => handleSave('info')} className={classes.label} id={'create-project-button-default'}>
          {translate('Create Project')}
        </span>
        <span className={classes.endIcon} onClick={expandDropdown}>
          <ArrowDropDownIcon />
        </span>
      </Button>
      <Menu
        classes={{ list: classes.list }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeDropdown}
      >
        <MenuItem className={classes.menuItem} onClick={() => handleSave('energy')}>
          {' '}
          {translate('Create')} → {translate('Energy')}
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={() => handleSave('design')}>
          {' '}
          {translate('Create')} → {translate('Design')}
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={() => handleSave('manage')}>
          {' '}
          {translate('Create')} → {translate('Manage')}
        </MenuItem>
      </Menu>
    </div>
  )
}

const CreateProjectButton = () => {
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('lg'))
  return isDesktop ? (
    <CreateButton />
  ) : (
    <Portal container={() => document.getElementById('PersistentContent')}>
      <CreateButton />
    </Portal>
  )
}

export default CreateProjectButton

import { ConditionFactory, SystemContext } from 'Designer/designRules/types'
import { BaseConditionDef } from '.'

export type ComponentProductEnabledDef = BaseConditionDef & {
  type: 'premium-product-enabled'
  title: string
}

export const condition_productEnabled: ConditionFactory<ComponentProductEnabledDef> = (def) => {
  return {
    getProjectFields: () => [],
    getStudioSignals: () => [],
    check: (context: SystemContext) => {
      return context.premiumProducts.some((p) => p.title === def.title && p.is_active)
    },
  }
}

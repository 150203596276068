import { Grid, makeStyles } from '@material-ui/core'
import ImageFieldClearable from 'elements/field/ImageFieldClearable'
import ChipsInput from 'elements/input/ChipsInput'
import DependentInput from 'elements/input/DependentInput'
import { List } from 'elements/react-admin/List'
import { Button, Chip } from 'opensolar-ui'
import React from 'react'
import {
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  FunctionField,
  ImageField,
  ImageInput,
  ReferenceArrayInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  required,
} from 'react-admin'
import { useHistory } from 'react-router-dom'
import { ACTIVATION_TYPE_CHOICES, LENDER_STATUS_CHOICES } from './constants'

const useStyles = makeStyles(() => ({
  col: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  btnWarpper: {
    margin: '5px 0px',
  },
  row: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
}))

export const LenderList = (props) => {
  const classes = useStyles()
  const history = useHistory()

  const goToRelatedResource = (
    financePartnerId: number,
    resource: 'finance_validation_sets' | 'finance_products' | 'finance_ctas'
  ) => {
    history.push(`/${resource}?finance_partner_id=${financePartnerId}`)
  }
  return (
    <List hasSearch={true} {...props}>
      <Datagrid>
        <TextField source="display_name" />
        <FunctionField
          source="finance_product"
          label="Countries"
          sortable={false}
          render={(record: any, source, rest) => {
            return (
              <div className={classes.row}>
                {record.countries_include_names?.map((orgName) => (
                  <Chip variant="outlined" label={orgName} key={orgName} />
                ))}
              </div>
            )
          }}
        />
        <FunctionField
          source="finance_product"
          label="Status"
          sortable={false}
          render={(record: any, source, rest) => <div>{record.status?.replaceAll('_', ' ')}</div>}
        />
        <FunctionField
          source="finance_integration"
          label="Actions"
          render={(record: any, source, rest) => (
            <EditButton useListStyle={true} record={record} basePath={rest.basePath} />
          )}
          sortable={false}
        />
        <FunctionField
          source="finance_integration"
          label="Related Records"
          render={(record: any, source, rest) => (
            <div className={classes.col}>
              <div className={classes.btnWarpper}>
                <Button onClick={() => goToRelatedResource(record.id, 'finance_products')} variant="outlined">
                  <span style={{ width: '150px' }}>View products</span>
                </Button>
              </div>
              <div className={classes.btnWarpper}>
                <Button onClick={() => goToRelatedResource(record.id, 'finance_validation_sets')} variant="outlined">
                  <span style={{ width: '150px' }}>View validation sets</span>
                </Button>
              </div>
              <div className={classes.btnWarpper}>
                <Button onClick={() => goToRelatedResource(record.id, 'finance_ctas')} variant="outlined">
                  <span style={{ width: '150px' }}>View CTAs</span>
                </Button>
              </div>
            </div>
          )}
          sortable={false}
        />
      </Datagrid>
    </List>
  )
}

const LenderInputFields = (props) => {
  const validateJSON = (value) => {
    if (!value) return undefined
    try {
      JSON.parse(value)
      return undefined
    } catch (ex) {
      return 'Invalid JSON'
    }
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <TextInput source="integration_name" validate={[required()]} fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextInput source="display_name" validate={[required()]} fullWidth />
      </Grid>
      <Grid item xs={3}>
        <SelectInput
          source="status"
          choices={LENDER_STATUS_CHOICES}
          defaultValue="pending"
          fullWidth={true}
          optionText="label"
          validate={[required()]}
        />
      </Grid>
      <Grid item xs={3}>
        <SelectInput
          source="activation_type"
          choices={ACTIVATION_TYPE_CHOICES}
          defaultValue="api_credentials"
          fullWidth={true}
          optionText="label"
          validate={[required()]}
        />
      </Grid>
      <Grid item xs={6}>
        {/* @ts-ignore */}
        <ReferenceArrayInput
          label="Whitelisted Countries"
          reference="countries"
          source="countries_include"
          fullWidth
          style={{ width: '100%' }}
        >
          <ChipsInput optionText="name" optionValue="url" source="countries_include" />
        </ReferenceArrayInput>
      </Grid>
      <Grid item xs={6}>
        <ImageFieldClearable
          record={props.record}
          label="logo_large_contents"
          source="logo_large_contents"
          maxWidth={200}
        />

        <ImageInput
          source="logo_large_contents"
          label="Large Logo"
          accept="image/*"
          placeholder={<p>Click to upload or drag & drop an image file here</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </Grid>
      <Grid item xs={6}>
        <ImageFieldClearable
          record={props.record}
          label="logo_small_contents"
          source="logo_small_contents"
          maxWidth={50}
        />

        <ImageInput
          source="logo_small_contents"
          label="Small Logo"
          accept="image/*"
          placeholder={<p>Click to upload or drag & drop an image file here</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </Grid>
      <Grid item xs={12}>
        <ImageFieldClearable
          record={props.record}
          label="banner_image_contents"
          source="banner_image_contents"
          maxWidth={600}
        />

        <ImageInput
          source="banner_image_contents"
          label="Banner Image"
          accept="image/*"
          placeholder={<p>Click to upload or drag & drop an image file here</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          source="banner_content"
          fullWidth
          validate={validateJSON}
          multiline={true}
          rows={30}
          InputProps={{
            classes: { input: 'code-block' },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <h4>Decision Status Mapping JSON</h4>
        <div>Used to map the status that the lender sends us to the status that we want to save on the Transaction</div>
        <TextInput
          label="Decision Status Mapping JSON"
          source="decision_status_mapping_json"
          fullWidth
          validate={validateJSON}
          multiline={true}
          rows={30}
          InputProps={{
            classes: { input: 'code-block' },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <BooleanInput source="enable_webhooks" />
      </Grid>
      <DependentInput dependsOn="enable_webhooks" value={true}>
        <Grid item xs={12}>
          <h4>Status Mapping JSON</h4>
          <div>
            Used to map the fields that are sent to us via webhook requests to fields that our webhook handler needs (eg
            project id, transaction_status, etc)
          </div>
          <TextInput
            label="Webhook Field Mapping JSON"
            source="webhook_field_mapping_json"
            fullWidth
            validate={validateJSON}
            multiline={true}
            rows={30}
            InputProps={{
              classes: { input: 'code-block' },
            }}
          />
        </Grid>
      </DependentInput>
      <Grid item xs={12}>
        <BooleanInput source="paid_for_offline_deals" />
      </Grid>
    </Grid>
  )
}

export const LenderEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <LenderInputFields {...props} />
    </SimpleForm>
  </Edit>
)

export const LenderCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm initialValues={{ enabled: true }}>
        <LenderInputFields {...props} />
      </SimpleForm>
    </Create>
  )
}

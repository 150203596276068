import { useCallback } from 'react'
import { OTHER_COMPONENT_FILTER_KEY } from '../otherComponentType/OtherComponentTypeFilterNodeFactory'
import type {
  FilterComponentRendererType,
  FilterGenericPropsType,
  FilterKeysType,
  FilterValuesType,
  OptionGenericType,
} from '../type'

export type FilterOptionsType = OptionGenericType<string>[]

type FilterProps = FilterGenericPropsType & {
  RendererComponent: FilterComponentRendererType<FilterOptionsType>
  filterKey: FilterKeysType
}

export const isCableThicknessFilterAvailable = (filterValues: FilterValuesType) => {
  return filterValues[OTHER_COMPONENT_FILTER_KEY] === 'cable'
}

export const CABLE_THICKNESS_OPTIONS: FilterOptionsType = [
  { id: 'default', title: 'All', value: '' },
  { id: '4mm', title: '4mm', value: '4' },
  { id: '6mm', title: '6mm', value: '6' },
  { id: '10mm', title: '10mm', value: '10' },
]

const CableThicknessFilter = ({
  filterKey,
  modifiableFilterValues,
  persistentFilterValues,
  allFilterValues,
  setModifiableFilters,
  RendererComponent,
}: FilterProps) => {
  const onSelect = useCallback(
    (value) => {
      setModifiableFilters({ ...modifiableFilterValues, [filterKey]: value })
    },
    [modifiableFilterValues]
  )

  const disabled = !isCableThicknessFilterAvailable(allFilterValues)

  return (
    <RendererComponent
      id={filterKey}
      defaultValue={''}
      label={'Cable Thickness'}
      options={CABLE_THICKNESS_OPTIONS}
      disabled={disabled}
      onSelect={onSelect}
      value={allFilterValues[filterKey]}
    />
  )
}

export default CableThicknessFilter

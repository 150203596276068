import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/AddOutlined'
import ScheduleIcon from '@material-ui/icons/ScheduleOutlined'
import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
// import Button from 'elements/proUXButtons/ProUXButton'
import Grid from '@material-ui/core/Grid'
import { permissionsSelectors } from 'ducks/permissions'
import Button from 'elements/button/Button'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { ProjectType, StageActionEventType } from 'types/projects'
import CRUDRecordDialog from '../../common/CRUDRecordDialog'
import ProjectHistoryEvent from '../projectHistory/ProjectHistoryEvent'
import IncompleteEventForm from '../projectManagement/editEventDialog/IncompleteEventForm'

const useStyles = makeStyles((theme) => ({
  content: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 16,
  },
  buttonWrapper: {
    margin: '10px 5px',
    [theme.breakpoints.between('sm', 'sm')]: {
      margin: 2,
    },
  },

  iconTitleWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  headingTitle: {
    margin: '0 10px',
  },
  descriptionWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  updateStageTextWrapper: {
    minWidth: '200px',
  },
  updateStageButtonWrapper: {
    marginLeft: '20px',
  },
}))

const PlannedActivityAccordion: React.FunctionComponent = () => {
  const [showTaskDialog, setShowTaskDialog] = useState<boolean>(false)
  const [showAppointmentDialog, setShowAppointmentDialog] = useState<boolean>(false)
  const { allowView, allowCreate, allowEdit, allowDelete } = useSelector(
    permissionsSelectors.getProjectPermissionByKey('manage_notes_activities_actions')
  )
  const translate = useTranslate()
  const classes = useStyles()
  const form = useForm()
  const project = form.getState().values as ProjectType
  const disabled = !Boolean(project.url) || !allowCreate
  const allPlannedEvents: StageActionEventType[] =
    project && project.events_data
      ? project?.events_data?.filter((e: StageActionEventType) => e.is_planned && !e.is_archived)
      : []

  const addTask = () => setShowTaskDialog(true)
  const addAppointment = () => setShowAppointmentDialog(true)

  const updateEventInForm = (savedRecord: StageActionEventType) => {
    //this gets populated on refresh from server but we need to do it ourselves here
    savedRecord.is_planned = moment().diff(moment(savedRecord.start), 'days') < 0
    const existingRecords = form.getState().values.events_data
    const newRecords = [savedRecord, ...existingRecords]
    form.getState().initialValues.events_data = newRecords
    form.getState().values.events_data = newRecords
  }

  return (
    <Accordion key="planned-activity" expanded={true}>
      <AccordionSummary
        aria-controls="expansion-content-planned-activity"
        id="expansion-header-planned-activity"
        classes={{ content: classes.content }}
      >
        <div className={classes.headerWrapper}>
          <div className={classes.iconTitleWrapper}>
            <ScheduleIcon />
            <span className={classes.headingTitle}>{translate('Planned Activity')}</span>
          </div>
          <div className={classes.buttonsWrapper}>
            <div className={classes.buttonWrapper}>
              <Button
                startIcon={<AddIcon />}
                onClick={addTask}
                variant="contained"
                color="default"
                size="small"
                label={translate('Task')}
                disabled={disabled}
              />
            </div>
            <div className={classes.buttonWrapper}>
              <Button
                startIcon={<AddIcon />}
                onClick={addAppointment}
                variant="contained"
                color="default"
                size="small"
                label={translate('Appointment')}
                disabled={disabled}
              />
            </div>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          {allPlannedEvents.map((e) => (
            <ProjectHistoryEvent
              record={e}
              isPlanned={true}
              onEdit={function (): void {
                throw new Error('Function not implemented.')
              }}
            />
          ))}
        </Grid>
      </AccordionDetails>
      {showTaskDialog && (
        <CRUDRecordDialog
          isOpen={showTaskDialog}
          record={{ project: project.url }}
          resource="events"
          basePath="/events"
          onDismiss={() => setShowTaskDialog(false)}
          formContent={<IncompleteEventForm showNotifyTeamMembers={true} mode="future_task" />}
          notificationOnSuccess={true}
          customSuccessNotificationText={translate('Your task has been saved')}
          updateFormValsFn={updateEventInForm}
          dialogTitle={translate('Add Task')}
        />
      )}
      {showAppointmentDialog && (
        <CRUDRecordDialog
          isOpen={showAppointmentDialog}
          record={{ project: project.url }}
          resource="events"
          basePath="/events"
          onDismiss={() => setShowAppointmentDialog(false)}
          formContent={<IncompleteEventForm showNotifyTeamMembers={true} mode="future_appointment" />}
          notificationOnSuccess={true}
          customSuccessNotificationText={translate('Your appointment has been saved')}
          updateFormValsFn={updateEventInForm}
          dialogTitle={translate('Add Appointment')}
        />
      )}
    </Accordion>
  )
}

export default PlannedActivityAccordion

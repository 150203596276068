import { AddAlertOutlined } from '@material-ui/icons'
import ClearIcon from '@material-ui/icons/ClearOutlined'
import EditOutlined from '@material-ui/icons/EditOutlined'
import MailOutline from '@material-ui/icons/MailOutlineOutlined'
import TinyBox from 'elements/TinyBox'
import { TinyLabel } from 'elements/TinyLabel'
import { IconButton } from 'opensolar-ui'
import React, { FC } from 'react'

export const TinyBoxBasic: FC = () => {
  return (
    <>
      {/* SNIPPET START 1 */}
      <TinyBox title="This is the title" tagline="This is the tagline" subtitle="This is the subtitle" />
      {/* SNIPPET END 1 */}
    </>
  )
}

export const TinyBoxActions: FC = () => {
  return (
    <>
      {/* SNIPPET START 2 */}
      <TinyBox
        title="This TinyBox has actions"
        actions={
          <>
            <IconButton>
              <MailOutline />
            </IconButton>
            <IconButton>
              <EditOutlined />
            </IconButton>
          </>
        }
      />
      {/* SNIPPET END 2 */}
    </>
  )
}

export const TinyLabelBasic: FC = () => {
  return (
    <>
      {/* SNIPPET START 3 */}
      <TinyLabel label="The Label">The Content</TinyLabel>
      {/* SNIPPET END 3 */}
    </>
  )
}

export const TinyBoxMax: FC = () => {
  return (
    <>
      {/* SNIPPET START 4 */}
      <TinyBox
        tagline="This is the tagline"
        title="This TinyBox has everything"
        subtitle="This is the subtitle"
        actions={
          <>
            <IconButton>
              <EditOutlined />
            </IconButton>
          </>
        }
        sidebar={
          <>
            <MailOutline fontSize="small" />
          </>
        }
      >
        <TinyLabel label="Visibility:">Shown</TinyLabel>
        <TinyLabel label="Permission:">Full Access</TinyLabel>
        <TinyLabel label="Accepted:">Yes</TinyLabel>
        <TinyLabel label="Payment Method:">Loan</TinyLabel>
      </TinyBox>
      {/* SNIPPET END 4 */}
    </>
  )
}

export const TinyBoxSidebar: FC = () => {
  return (
    <>
      {/* SNIPPET START 6 */}
      <TinyBox
        title="This TinyBox has sidebar items"
        sidebar={
          <>
            <AddAlertOutlined fontSize="small" />
            <ClearIcon fontSize="small" />
          </>
        }
      ></TinyBox>
      {/* SNIPPET END 6 */}
    </>
  )
}

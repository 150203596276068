import { logAmplitudeEvent } from 'amplitude/amplitude'
import { Switch } from "opensolar-ui"
import { memo, useState } from 'react'
import { useNotify } from 'react-admin'
import Skeleton from 'react-loading-skeleton'
import useToggleForecastProject from '../hooks/useToggleForecastProject'

const ForecastToggle = ({
  forecasted,
  projectId,
  onSuccess,
  onFailed,
}: {
  forecasted: boolean
  projectId: number
  onSuccess?(): void
  onFailed?(): void
}) => {
  const notify = useNotify()
  const { switchingProjectForecast, switchProjectForecast } = useToggleForecastProject()
  const [isChecked, setIsChecked] = useState<boolean>(forecasted)

  const handleToggle = async () => {
    const forecast = !isChecked
    setIsChecked(forecast)
    await switchProjectForecast({ projectId, forecast })
    notify('Forecast change saved', 'success')
    onSuccess?.() // Need to handle the case where it failed to update
    logAmplitudeEvent('generic_toggle_interacted', {
      source: 'forecast_toggle',
      action: 'inventory_forecast_toggle_updated',
    })
  }

  if (switchingProjectForecast) {
    return <Skeleton />
  }
  return <Switch onChange={handleToggle} checked={isChecked} />
}

export default memo(ForecastToggle)

import { useCallback } from 'react'
import { useDataProvider } from 'react-admin'

type ConfirmTransferArgs = { projectIds: number[] }

const useConfirmProjectsTransfer = () => {
  const dataProvider = useDataProvider()
  const confirmProjectsTransfer = useCallback(async ({ projectIds }: ConfirmTransferArgs) => {
    await dataProvider.CUSTOM_POST('inventory_transfer/confirm_project_transfer', {
      url: 'inventory_transfer/confirm_project_transfer/',
      data: { project_ids: projectIds },
    })
  }, [])

  return {
    confirmProjectsTransfer,
  }
}

export default useConfirmProjectsTransfer

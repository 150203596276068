import { Dialog } from '@material-ui/core'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import { IconButton } from 'opensolar-ui'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslate } from 'react-admin'
import DailyEnergyFlowsChart from '../../myenergy/DailyEnergyFlowsChart'

const scenarios = [
  {
    index: 0,
  },
  {
    index: 1,
  },
  {
    index: 2,
  },
  {
    index: 3,
  },
]

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const seasonNamesForNorthernHemisphereOrder = ['Winter', 'Spring', 'Summer', 'Autumn']

const scenarioIndexToSeasonName = (index, hemisphere) =>
  seasonNamesForNorthernHemisphereOrder[hemisphere === 'north' ? index : (index + 2) % 4]

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

class DailyEnergyFlowsPopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedScenarioIndex: 0,
      displayDetail: false,
    }
  }

  displayDetailToggle = (payload) => {
    this.setState({
      ...this.state,
      displayDetail: payload,
    })
  }

  render() {
    if (
      !this.props.isOpen ||
      !this.props.system ||
      !this.props.system.bills ||
      !this.props.system.bills.proposedSelected ||
      this.props.system.bills.proposedSelected.bills_yearly.length === 0
    ) {
      return null
    }
    const { displayDetail } = this.state
    const { translate } = this.props
    return (
      <div>
        <Dialog className="energy-flow" open={this.props.isOpen} onBackdropClick={() => this.props.closeDialog()}>
          <DialogTitle onClose={() => this.props.closeDialog()}>{translate('Daily Energy Flows')}</DialogTitle>

          <DialogContent dividers>
            <div style={{ width: '100%', display: this.props.layout === 1 ? 'inline-block' : 'flex' }}>
              <div
                style={{
                  margin: '12px 0px 20px 0',
                  display: 'flex',
                  float: this.props.layout === 1 ? '' : 'left',
                  width: this.props.layout === 1 ? '' : '60%',
                }}
              >
                {scenarios.map((scenario) => (
                  <button
                    key={scenario.index}
                    className={
                      'switch-button ' +
                      (scenario.index === this.state.selectedScenarioIndex ? 'switch-button-active' : '')
                    }
                    onClick={() => {
                      this.setState({ selectedScenarioIndex: scenario.index })
                    }}
                    style={{
                      flex: 1,
                      width: '20%',
                      borderRadius:
                        scenario.index === 0 ? '10px 0 0 10px' : scenario.index === 3 ? '0px 10px 10px 0' : '',
                    }}
                  >
                    {translate(scenarioIndexToSeasonName(scenario.index, this.props.hemisphere))}
                  </button>
                ))}
              </div>
              <div style={{ display: this.props.layout === 1 ? 'none' : 'flex', width: '5%' }} />
              <div
                style={{ margin: '12px 0px 20px 0', display: 'flex', width: this.props.layout === 1 ? '50%' : '35%' }}
              >
                <button
                  className={'switch-button ' + (displayDetail ? '' : 'switch-button-active')}
                  style={{
                    borderRadius: '10px 0 0 10px',
                  }}
                  onClick={() => this.displayDetailToggle(false)}
                >
                  {translate('Weekday')}
                </button>
                <button
                  className={'switch-button ' + (displayDetail ? 'switch-button-active' : '')}
                  style={{
                    borderRadius: '0 10px 10px 0',
                  }}
                  onClick={() => this.displayDetailToggle(true)}
                >
                  {translate('Weekend')}
                </button>
              </div>
            </div>
            {!displayDetail && (
              <DailyEnergyFlowsChart
                data={
                  this.props.system.bills.proposedSelected.bills_yearly[0].hourly_energy_flow_for_days[
                    this.state.selectedScenarioIndex * 2
                  ]
                }
                layout={this.props.layout}
              />
            )}

            <div style={{ clear: 'both' }} />
            {displayDetail && (
              <DailyEnergyFlowsChart
                data={
                  this.props.system.bills.proposedSelected.bills_yearly[0].hourly_energy_flow_for_days[
                    this.state.selectedScenarioIndex * 2 + 1
                  ]
                }
                layout={this.props.layout}
              />
            )}
          </DialogContent>
        </Dialog>
      </div>
    )
  }
}

DailyEnergyFlowsPopup.propTypes = {
  isOpen: PropTypes.bool,
  hemisphere: PropTypes.string,
  system: PropTypes.object,
  closeDialog: PropTypes.func.isRequired,
}

DailyEnergyFlowsPopup.defaultProps = {
  isOpen: false,
  system: null,
  hemisphere: 'north',
}

export default withTranslate(DailyEnergyFlowsPopup)

// @ts-nocheck
import { Dialog, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import withWidth, { WithWidthOptions } from '@material-ui/core/withWidth'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import Button from 'elements/proUXButtons/ProUXButton'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { reloadEntireApp } from 'util/misc'

type PropTypes = {
  isOpen: boolean
  close: () => void
  width: WithWidthOptions
}

const useStyles = makeStyles({
  root: {},
  checked: {},
  errorText: {
    color: 'red',
    textAlign: 'center',
    width: '100%',
  },
})

const MosaicAccountDialog: React.FunctionComponent<PropTypes> = (props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const [partnerIdentifier, setPartnerIdentifier] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (errorMsg) {
      logAmplitudeEvent('mosaic_account_dialog_error', { error: errorMsg })
    }
  }, [errorMsg])

  const orgId = useSelector(authSelectors.getOrgId)

  const classes = useStyles()

  const save = () => {
    if (!partnerIdentifier) {
      setErrorMsg('Partner Identifier is required to conenct with Mosaic')
      return
    }

    setLoading(true)
    const restClientInstance = restClient(window.API_ROOT + '/api')
    restClientInstance('CUSTOM_POST', 'custom', {
      url: 'orgs/' + orgId + '/connect_mosaic/',
      data: {
        partner_identifier: partnerIdentifier,
      },
    })
      .then((response: any) => {
        if (response.data?.success) {
          logAmplitudeEvent('mosaic_account_connected', {
            products_added: response?.data?.products_added,
            missing_logins: response?.data?.missing_logins?.length,
          })
          goToPmts(response?.data?.products_added)
        } else {
          setErrorMsg('Incorrect Partner Identifier')
        }
      })
      .catch((error: any) => {
        setErrorMsg('Unable to connect your Mosaic account')
        console.log('error', error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const goToPmts = (prodCount?: number) => {
    let prodParam = prodCount
    let url = '/#/payment_options'
    if (prodParam >= 0) url += `?products_added=${prodParam}&partner=Mosaic`
    reloadEntireApp(url)
  }

  return (
    <Dialog
      fullScreen={props.width === 'xs' || props.width === 'xxs'}
      open={props.isOpen}
      style={{ textAlign: 'center' }}
      onClose={props.close}
    >
      <DialogTitle>Connect your Mosaic Account</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <p>
              Enter your Mosaic API credentials below to connect your account. If you don't have these credentials you
              can get them by reaching out to Mosaic directly
            </p>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="mosic-client-id"
              label="Mosaic Partner Identifier"
              fullWidth={true}
              value={partnerIdentifier}
              onChange={(e) => setPartnerIdentifier(e.target.value)}
            />
          </Grid>
          {errorMsg && (
            <Grid item xs={12}>
              <p className={classes.errorText}>{errorMsg}</p>
            </Grid>
          )}
          <Grid item xs={12} style={{ paddingTop: 20, display: 'flex', justifyContent: 'center' }}>
            <Button label="Save" type="primary" onClick={save} loading={loading} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
export default withWidth()(MosaicAccountDialog)

import { Grid } from '@material-ui/core'
import { SubFormDialog } from 'elements/react-admin/SubFormDialog'
import { useProjectFormMutators } from 'projectSections/form/mutations'
import { BusinessNameAndIdentifierFields } from 'projectSections/sections/info/siteDetails/BusinessNameAndIdentifierFields'
import IsResidentialRadioButtonGroup from 'projectSections/sections/info/siteDetails/ProjectTypeRadioButtons'
import { useTranslate } from 'ra-core'
import React from 'react'
import { useForm } from 'react-final-form'
import { CommonPromptPropTypes } from './types'

type Props = CommonPromptPropTypes & {
  open: boolean
}

const SwitchProjectTypePrompt: React.FC<Props> = (props) => {
  const form = useForm()
  const translate = useTranslate()
  const mutations = useProjectFormMutators()

  const onSavedToParentForm = () => {
    window.Designer.startAllSystemCalculation()
  }

  return (
    <SubFormDialog
      open={props.open}
      onClose={props.onClose}
      title={translate('Switch Project Type')}
      parentForm={form}
      mutators={mutations}
      onSaved={onSavedToParentForm}
      allowInvalidSubmit={true}
    >
      <Grid item xs={12}>
        <p style={{ marginTop: 0, marginBottom: 10, textAlign: 'start' }}>
          {translate(props.message || '')}.{' '}
          {translate('Please update this below or select a different payment option.')}
        </p>
        <IsResidentialRadioButtonGroup />
      </Grid>
      <BusinessNameAndIdentifierFields validate={true} />
    </SubFormDialog>
  )
}
export default SwitchProjectTypePrompt

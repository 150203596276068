import { viewModeSelectors } from 'ducks/viewMode'
import { useNewProjectsList } from 'hooks/useNewProjectsList'
import { useSelector } from 'react-redux'
import ProjectsListUI3 from './projects3'
import ProjectsList from './ProjectsList'
import ProjectListLite from './ProjectsListLite'

const SwitchProjectsList = (props) => {
  const ui3Enabled = useNewProjectsList()
  const isUserLite = useSelector(viewModeSelectors.isUserLite)
  if (isUserLite)
    return (
      <div>
        <ProjectListLite {...props} />
      </div>
    )
  if (ui3Enabled) return <ProjectsListUI3 {...props} />
  return (
    <div>
      <ProjectsList {...props} />
    </div>
  )
}

//hack for project page
export default {
  list: SwitchProjectsList,
  edit: null,
  create: null,
  options: {
    show: {
      subtitle: null,
      breadCrumb: 'Edit Project',
    },
    list: {
      //hide bread crumb and use custom project selector dropdown as title
    },
    create: {
      // auto generate default title and bread crumb for create
    },
  },
}

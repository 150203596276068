import FullScreenLoading from 'layout/widgets/FullScreenLoading'
import React, { Suspense } from 'react'
import GenericErrorPage from './GenericErrorPage'
import type { SSOFlowConfigType } from './types'

const SSOAuthFlowLazy = React.lazy(() => import('./SSOAuthFlowLazy'))

const SSOAuthFlow = ({
  ssoConfig,
  options,
}: {
  ssoConfig: SSOFlowConfigType | undefined
  options?: Record<string, string>
}) => {
  if (ssoConfig === undefined) {
    return <GenericErrorPage />
  }

  if (typeof options?.token !== 'string') {
    return <GenericErrorPage />
  }

  return (
    <Suspense fallback={<FullScreenLoading />}>
      <SSOAuthFlowLazy
        ssoConfig={ssoConfig}
        initialNodeConfig={{
          currentNodeKey: 'EXTERNAL_AUTH_VALIDATION',
          options: { token: options.token, source: options.source },
        }}
      />
    </Suspense>
  )
}

export default SSOAuthFlow

// @ts-nocheck
import ProUxButton from 'elements/proUXButtons/ProUXButton'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

type PropTypes = {
  decision: MosaicDecisionType
  onClose: () => void
  amountApproved?: number
  amountRequested?: number
  approvedContacts?: number[]
  appliedWithCoborrower: boolean
}

const MosaicDecisionExplanation: React.FunctionComponent<PropTypes> = (props) => {
  const [notAllApplicantsApproved, setNotAllApplicantsApproved] = useState<boolean>(false)

  const applicantMessages = useSelector((state: any) => state?.myEnergy?.decisionDisplayProps?.mosaicApplicantMessages)

  // if application is approved, they applied with a coborrower, but only one person is approved
  useEffect(() => {
    if (
      props.appliedWithCoborrower &&
      props.approvedContacts &&
      props.approvedContacts.length === 1 &&
      ['Approved', 'ApprovedWithStipulations'].includes(props.decision)
    ) {
      setNotAllApplicantsApproved(true)
    }
  }, [props.approvedContacts && props.appliedWithCoborrower && props.decision])

  // only used in the event that two people applied but only one is approved
  const getApprovedApplicantName = () => {
    if (!props.approvedContacts || !notAllApplicantsApproved) return null
    return props.approvedContacts[0]
  }

  return (
    <div
      style={{
        margin: '10px 0px 20px 0px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {props.decision === 'Approved' && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img height="45" src={window.PUBLIC_URL + '/images/Mosaic-Logo.png'} style={{ marginBottom: '30px' }} />
          <h2 style={{ textAlign: 'center' }}>Credit Decision - Approved</h2>
        </div>
      )}
      {props.decision === 'Declined' && (
        <div>
          <h2 style={{ textAlign: 'center' }}>Credit Decision - Declined</h2>
        </div>
      )}
      {props.decision === 'Pending' && (
        <div>
          <h2 style={{ textAlign: 'center' }}>Credit Decision - Under Review</h2>
        </div>
      )}
      {props.decision === 'ApprovedWithStipulations' && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img height="45" src={window.PUBLIC_URL + '/images/Mosaic-Logo.png'} style={{ marginBottom: '30px' }} />
          <h2 style={{ textAlign: 'center' }}>
            Credit Decision - Approved Pending Verification - Check Email for Next Steps
          </h2>
        </div>
      )}
      {props.decision === 'APPROVED_COUNTER_OFFER' && (
        <div>
          <img height="45" src={window.PUBLIC_URL + '/images/Mosaic-Logo.png'} style={{ marginBottom: '30px' }} />
          <h2 style={{ textAlign: 'center' }}>Credit Decision - Approved for a reduced loan amount</h2>
        </div>
      )}
      {applicantMessages &&
        Object.values(applicantMessages).map((txt: string) => {
          return <p>{txt}</p>
        })}
      <div style={{ marginTop: '30px' }}>
        <ProUxButton type="secondary" onClick={props.onClose} label="Continue" />
      </div>
    </div>
  )
}
export default MosaicDecisionExplanation

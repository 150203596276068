import classnames from 'classnames'
import { Box } from 'opensolar-ui'
import React from 'react'
import type { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => {
  return {
    scrollShadow: {
      background:
        'linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%',
      backgroundRepeat: 'no-repeat',
      backgroundColor: '#ffffff',
      backgroundSize: '100% 40px, 100% 40px, 100% 10px, 100% 10px',
      backgroundAttachment: 'local, local, scroll, scroll',
    },
    container: {
      width: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      maxHeight: 'inherit',
      minHeight: '0px',
      height: 'inherit',
    },
    heading: {
      height: 50,
      padding: '0 20px',
      display: 'flex',
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    body: {
      minHeight: '0px',
      flexGrow: 1,
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      flexWrap: 'nowrap',
    },
    toolBars: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    mainContent: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      boxSizing: 'border-box',
      padding: '20px 10px',
      height: 'inherit',
      maxHeight: 'inherit',
    },
    listWrapper: {
      flexGrow: 1,
      overflow: 'hidden',
    },
    list: {
      height: '100%',
      maxHeight: '100%',
      overflow: 'auto',
    },
    sidebar: {
      padding: '20px 10px',
      boxSizing: 'border-box',
      width: 240,
      height: 'inherit',
      maxHeight: 'inherit',
      flexShrink: 0,
      flexBasis: '240px',
      overflow: 'auto',
    },
  }
})

const HardwareSelectorLayout = ({
  heading,
  toolBars,
  listContent,
  sidebar,
  footer,
}: {
  heading?: React.ReactNode
  toolBars: React.ReactNode
  listContent: React.ReactNode
  sidebar: React.ReactNode
  footer?: React.ReactNode
}) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {heading && <div className={classes.heading}>{heading}</div>}
      <div className={classes.body}>
        <div className={classes.sidebar}>{sidebar}</div>
        <Box className={classes.mainContent} display="flex" gridRowGap={24}>
          <div className={classes.toolBars}>{toolBars}</div>
          <div className={classes.listWrapper}>
            <div className={classnames(classes.list, classes.scrollShadow)}>{listContent}</div>
          </div>
          {!!footer && <div>{footer}</div>}
        </Box>
      </div>
    </div>
  )
}

export default HardwareSelectorLayout

import StyledDateInput from 'projectSections/sections/manage3/common/StyledDateInput'
import { useTranslate } from 'ra-core'
import React from 'react'
import { SelectInput, TextInput } from 'react-admin'
import { GENDER_CHOICES } from 'resources/projects/choices'
import { dateParserYYYYMMDD } from 'util/misc'
import CustomFields from '../../../common/CustomFields'
import { InputRow, InputWrapper } from '../../../styles'
import { ContactFormExpandSection } from './ContactFormExpandSection'

type PropTypes = {
  source: string
  disabled?: boolean
}

export const ContactMoreDetailsSection: React.FC<PropTypes> = ({ source, disabled = false }) => {
  const translate = useTranslate()
  return (
    <ContactFormExpandSection sectionTitle={(isOpen) => translate(isOpen ? 'Hide details' : 'View more details')}>
      <InputWrapper>
        <TextInput
          source={`${source}.middle_name`}
          variant="outlined"
          label={'Middle Name'}
          disabled={disabled}
          fullWidth
        />
        <InputRow>
          <SelectInput
            source={`${source}.gender`}
            variant="outlined"
            label={'Gender'}
            choices={GENDER_CHOICES}
            disabled={disabled}
            fullWidth
          />
          <StyledDateInput
            inputVariant={'outlined'}
            source={`${source}.date_of_birth`}
            label={'Date of Birth'}
            options={{ color: 'secondary' }}
            disabled={disabled}
            parse={dateParserYYYYMMDD}
          />
        </InputRow>
        <InputRow>
          <TextInput
            source={`${source}.passport_number`}
            variant="outlined"
            label={'Passport Number'}
            disabled={disabled}
            fullWidth
          />
          <TextInput
            source={`${source}.licence_number`}
            variant="outlined"
            label={'License Number'}
            disabled={disabled}
            fullWidth
          />
        </InputRow>
        <CustomFields model={'Contact'} source={source} inputVariant="outlined" disabled={disabled} />
      </InputWrapper>
    </ContactFormExpandSection>
  )
}

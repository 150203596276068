import Typography from '@material-ui/core/Typography'
import { styled } from 'opensolar-ui'
import { ComponentPlaceHolderImage } from 'pages/ordering/catalogv3/ProductTile'
import { getDiscountSavingPerItem } from 'pages/ordering/discountUtils'
import PercentageDiscountTag from 'pages/ordering/elements/PercentageDiscountTag'
import type LineItemType from 'pages/ordering/OrderLineItem'
import { format_percentage_value } from 'util/misc'
import ProductPrice from './price/ProductPrice'

export const Wrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-evenly',
  backgroundColor: 'white',
  padding: 20,
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
})

const ImageWrapper = styled('div')({
  position: 'relative',
  width: '100%',
})

const DiscountWrapper = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 10,
})

const ProductImage = styled('img')({
  width: '202px',
  height: '302px',
  objectFit: 'contain',
  borderRadius: '8px',
})

const LogoImage = styled('img')({
  height: 40,
  width: 80,
  objectFit: 'contain',
})

const ProductCard = ({ lineItem, outputPower }: { lineItem: LineItemType; outputPower: number }) => {
  const { discount } = lineItem.getBestDiscountOffer()

  const discountSavingPerItem =
    discount &&
    lineItem.selectedDistributorOrderingData &&
    getDiscountSavingPerItem({
      discount,
      pricePerItem: lineItem.selectedDistributorOrderingData.price,
    })

  const discountPercentValue =
    discountSavingPerItem &&
    lineItem.selectedDistributorOrderingData &&
    discountSavingPerItem / lineItem?.selectedDistributorOrderingData?.price

  return (
    <Wrapper>
      <div>
        {lineItem?.data?.image_url ? (
          <ImageWrapper>
            {discountPercentValue && (
              <DiscountWrapper>
                <PercentageDiscountTag showUpTo={false} percentage={format_percentage_value(discountPercentValue)} />
              </DiscountWrapper>
            )}
            <ProductImage src={lineItem?.data?.image_url} alt="Product" />
          </ImageWrapper>
        ) : (
          <ComponentPlaceHolderImage />
        )}
      </div>
      <div>
        {lineItem?.data?.logo_image_url && <LogoImage src={lineItem?.data?.logo_image_url} alt="logo" />}
        <Typography variant="h6" gutterBottom>
          {lineItem?.data?.short_description}
        </Typography>
        <Typography variant="subtitle1">{lineItem?.data?.code}</Typography>
        <ProductPrice
          pricePerItem={lineItem?.selectedDistributorOrderingData?.price}
          discountSavingPerItem={discountSavingPerItem}
          outputPower={outputPower}
          quantityPerItem={lineItem.selectedDistributorOrderingData?.quantity_per_item}
        />
      </div>
    </Wrapper>
  )
}

export default ProductCard

import lodash from 'lodash'
import { FC, useEffect, useMemo } from 'react'
import { SelectInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { BOOLEAN_CHOICES } from '../fields'

interface PropTypes {
  source: string
  fieldId: string
  valueStr?: string
  onChange?: (value) => object
}

const ConditionalRefFilter: FC<PropTypes> = ({ source, fieldId, valueStr, children, onChange }) => {
  const form = useForm()
  const formValues = useFormState().values
  const value = useMemo(() => lodash.get(formValues, `${source}.fields`), [formValues, source])
  useEffect(() => {
    let newValue = {}
    if (onChange) {
      newValue = onChange(value)
    } else if (value?.conditional !== undefined && value?.itemId) {
      newValue[valueStr || fieldId] = `${value.conditional ? '' : '-'}${value.itemId}`
    }
    form.change(`${source}.value`, newValue)
  }, [value])
  return (
    <>
      <SelectInput
        choices={BOOLEAN_CHOICES}
        label={false}
        source={`${source}.fields.conditional`}
        variant="outlined"
        margin={'none'}
      />
      {children}
    </>
  )
}

export default ConditionalRefFilter

import { ModuleGridType, PanelConfiguration } from 'types/global'
import { formatNumber } from '../helpers'
import type { BasicAccessorConfig } from './types'

const setter = ({ moduleGrid, value }: { moduleGrid: ModuleGridType; value: PanelConfiguration }) => {
  moduleGrid.modulesPerRow(value === 'DUAL_TILT_RACK' ? 2 : 1)
  const currentRackingType = moduleGrid.getPanelConfiguration()
  if (value === currentRackingType) return // no change
  const slope = formatNumber(moduleGrid.getSlope())

  window.editor.execute(
    new window.SetPanelConfigurationCommand(
      moduleGrid,
      'panelConfiguration',
      value,
      window.Utils.generateCommandUUIDOrUseGlobal()
    )
  )

  if (value === 'STANDARD') {
    window.editor.execute(
      new window.SetPanelConfigurationCommand(
        moduleGrid,
        'panelTiltOverride',
        null,
        window.Utils.generateCommandUUIDOrUseGlobal()
      )
    )
  } else if (moduleGrid.panelTiltOverride === null) {
    window.editor.execute(
      new window.SetPanelConfigurationCommand(
        moduleGrid,
        'panelTiltOverride',
        slope + 20,
        window.Utils.generateCommandUUIDOrUseGlobal()
      )
    )
  }
}

const getter = (moduleGrid: ModuleGridType): PanelConfiguration => {
  return moduleGrid.getPanelConfiguration()
}

export const rackingTypeAccessorConfig: BasicAccessorConfig<PanelConfiguration> = {
  get: getter,
  set: setter,
}

import type { OpenSolarThemeType } from 'Themes'
import ComponentTypeImg from 'elements/field/ComponentTypeImag'
import ComponentOverviewIcon from 'elements/hardwareSelector/ComponentOverview'
import type LineItemType from 'pages/ordering/OrderLineItem'
import React, { memo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { getOrderTableAvailabilityCategory } from '../OrdersTable'

const useCodeFieldStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10,
  },
  code: {
    marginLeft: 5,
    marginRight: 5,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 100,
  },
  image: {
    width: 35,
    height: 35,
  },
}))

type CodeFieldPropsType = {
  lineItem: LineItemType
}

export const CodeField = memo(({ lineItem }: CodeFieldPropsType) => {
  const classes = useCodeFieldStyles()
  const { code, data, componentType } = lineItem
  const componentTypeLabel = componentType || data?.component_type
  const component = data && JSON.parse(data.data)
  const availability = getOrderTableAvailabilityCategory(lineItem)

  return (
    <div className={classes.container} data-testid="hardware-ordering-item-code">
      {data?.image_url && availability !== 'unavailable' ? (
        <img src={data.image_url} alt="componentImage" className={classes.image} />
      ) : (
        <ComponentTypeImg componentType={componentTypeLabel} size="medium" />
      )}
      <span className={classes.code}>{code}</span>
      {component && availability !== 'unavailable' && <ComponentOverviewIcon component={component} />}
    </div>
  )
})

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var UserProfileIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? '1rem' : _b, _c = _a.color, color = _c === void 0 ? 'currentColor' : _c, rest = __rest(_a, ["size", "color"]);
    var iconAttributes = {
        width: size,
        height: size,
        stroke: color,
    };
    return (_jsxs("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 18 18", fill: "none" }, rest, iconAttributes, { children: [_jsx("path", { d: "M12.8182 4.00966C12.8182 6.05007 11.1087 7.87491 9 7.87491C6.89128 7.87491 5.18182 6.05007 5.18182 4.00966C5.18182 1.96924 6.89128 0 9 0C11.1087 0 12.8182 1.96924 12.8182 4.00966Z", fill: color }), _jsx("path", { d: "M18 13.7779C18 16.1098 15.2727 18.0002 9 18.0002C2.72727 18.0002 0 16.1098 0 13.7779C0 11.446 4.02944 9.5556 9 9.5556C13.9706 9.5556 18 11.446 18 13.7779Z", fill: color })] })));
};

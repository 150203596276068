import { GenerateDocumentFlow, OSSDK } from 'opensolar-sdk'

/**
 * Note: these flows are built in a way that they could be used outside
 * of the SDK. This allows them to be used as examples also.
 */

export function generateDocument(sdk: OSSDK) {
  return async (payload: GenerateDocumentFlow): Promise<any> => {
    let projectId = payload.projectId || sdk.project_form.values.value.id
    let orgId = payload.orgId || sdk.project_form.values.value.org_id
    let url = new URL(
      `${window.API_BASE_URL}orgs/${orgId}/projects/${projectId}/generate_document_pdf/${payload.documentType}/`
    )

    Object.entries({
      language: 'en',
      system_uuid: payload.systemUuid,
      // we can omit document_template_id if we want to use the default template
      document_template_id: payload.documentTemplateId || undefined,
      payment_option_id: payload.paymentOptionId || undefined,
    }).forEach(([key, value]) => {
      if (typeof value !== 'undefined') {
        url.searchParams.set(key, String(value))
      }
    })

    return fetch(url, {
      headers: window.Utils.tokenAuthHeaders({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data)
        return data
      })
      .catch((error) => {
        console.error('Error:', error)
        return error
      })
  }
}

// @ts-nocheck
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import PhotoIcon from '@material-ui/icons/PhotoCameraOutlined'
import RefreshIcon from '@material-ui/icons/RefreshOutlined'
import HeightIcon from '@material-ui/icons/VerticalAlignTopOutlined'
import withStudioSignals from 'Studio/signals/withStudioSignals'
import { Button } from 'opensolar-ui'
import { Component } from 'react'
import { withTranslate } from 'react-admin'
import { compose } from 'recompose'
import ToolButton from './ToolButton'
import ViewControllers from './ViewControllers'

const THREE = window.THREE

const styleFunc = (theme: any, wrap?: boolean) => {
  const ret = {
    positionWrapper: {
      position: 'absolute',
      bottom: '15px',
      right: '10px',
      zIndex: 1000,
      textAlign: 'right',
      [theme.breakpoints.down('sm')]: { bottom: 90 },
    },
    button: {
      background: 'rgba(255,255,255, 1)',
      borderRadius: '4px',
      color: 'rgb(0,0,0)',
      verticalAlign: 'bottom',
      padding: '0 5px',
      height: 30,
      '&:hover': {
        background: 'rgba(250,250,250,0.9)',
      },
    },
    icon: {
      width: 24,
      height: 24,
      margin: 0,
    },
    divider: {
      margin: '0 10px',
    },
    // disabled: {},
  }
  if (wrap) return createStyles(ret)
  else return ret
}

export const classStyles = (theme: any) => styleFunc(theme, true)

export const useStyles = makeStyles(styleFunc)

export type ClassesTypes = {
  iconButton: any
  iconButtonActive: any
  icon: any
  disabled: any
  positionWrapper: any
  button: any
  divider: any
}

type PropTypes = {
  translate: Function
  classes: ClassesTypes
  state?: any
}
type StateTypes = {
  hasShadingCalcsAwaitingTrigger: boolean
  showRefreshTerrainButton: boolean
  snapshotEnabled: boolean
  widgetsVisible: boolean
  alignMapActive: boolean
  measureHeightActive: boolean
  measureHeightEnabled: boolean
  measureHeightVisible: boolean
}

class ToolbarWidgets extends Component<PropTypes, StateTypes> {
  constructor(props: PropTypes) {
    super(props)
    this.state = props.state
      ? props.state
      : {
        widgetsVisible: true,
        alignMapActive: false,
        measureHeightActive: window.editor.controllers.MeasureHeight.active,
      }
  }

  componentDidMount() {
    if (window.Designer) {
      window.Designer.ToolbarWidgets = this
    } else {
      console.log('Warning: window.Designer not available, unable to link ToolbarWidgets')
    }

    this.props.useStudioSignalsLazy(
      this.refreshPanel,
      [
        'viewsChanged',
        'sceneLoaded',
        'sceneGraphChanged',
        'objectChanged',
        'objectSelected',
        'systemSelected',
        'controlModeChanged',
        'displayModeChanged',
        'mapChanged',
      ],
      this
    )
  }
  componentWillUnmount(): void {
    if (window.Designer) window.Designer.ToolbarWidgets = undefined
  }

  refreshPanel() {
    if (!window.Designer || window.Designer.allowUiUpdates()) {
      setTimeout(() => this.refreshPanelAsync(), 1)
    }
  }

  refreshPanelAsync() {
    var _widgetsVisible = window.editor.getSystems().length > 0 && window.editor.displayMode === 'interactive'

    var mapType =
      window.MapHelper && window.MapHelper.activeMapInstance ? window.MapHelper.activeMapInstance.mapType : null

    var selectedView = window.ViewHelper.selectedView()
    var target4326 = selectedView?.mapData?.center ? new THREE.Vector2().fromArray(selectedView.mapData.center) : null

    // I am not sure if mapType would ever differ from activeMapInstance and selectedView but we keep it here just in case
    var selectedViewMapType = selectedView?.mapData?.mapType || mapType

    // Disable snapshots if using Nearmap ApiKey because imagery will not include the CORS headers
    // Disable snapshots if running on iOS/Android because a) trouble with data uris b) they can just take a screenshot
    var _snapshotEnabled =
      (mapType === 'Nearmap' || mapType === 'NearmapSource' || mapType === 'NearmaOblique') &&
      window.AccountHelper.hasNearmapOnOpenSolar() &&
      window.RUNNING_AS_APP !== true

    var distanceFromTerrainCenterAsFraction = window.editor.viewport.distanceFromTerrainCenter(true)

    var isActiveAndWithinBounds = function (_mapType, _target4326, bounds, period) {
      /*
      bounds is a list with 4 values: [xmin, ymin, xmax, ymax]
      */
      if (_mapType === 'Nearmap3D' && period === 'initial' && bounds) {
        // var [xmin, ymin, xmax, ymax] = bounds

        // Bounds is the full bounds but we need to ensure the full loaded data falls within the bounds
        // So we cannot refresh too close to the bounds or the dataset will fall outside the bounds and fail.
        // Inset the bounds by the half-size of the DSM

        var dsmSize = 100
        var insetMeters = dsmSize / 2
        var insetMercatorMeters = insetMeters / window.Utils.webMercatorMetersToRealWorldMeters(_target4326.y)

        var min4326 = new THREE.Vector2(bounds[0], bounds[1])
        var max4326 = new THREE.Vector2(bounds[2], bounds[3])

        var minWithInset3857 = window.Utils.reprojectVector2(min4326, 'EPSG:4326', 'EPSG:3857').add(
          new THREE.Vector2(insetMercatorMeters, insetMercatorMeters)
        )
        var maxWithInset3857 = window.Utils.reprojectVector2(max4326, 'EPSG:4326', 'EPSG:3857').add(
          new THREE.Vector2(-insetMercatorMeters, -insetMercatorMeters)
        )

        var allowRefreshWithinBounds = new THREE.Box2(
          window.Utils.reprojectVector2(minWithInset3857, 'EPSG:3857', 'EPSG:4326'),
          window.Utils.reprojectVector2(maxWithInset3857, 'EPSG:3857', 'EPSG:4326')
        )

        // // distance from center
        // var min3857 = window.Utils.reprojectVector2(min4326, 'EPSG:4326', 'EPSG:3857')
        // var max3857 = window.Utils.reprojectVector2(max4326, 'EPSG:4326', 'EPSG:3857')
        // var center3857 = new THREE.Vector2((max3857.x + min3857.x) / 2, (max3857.y + min3857.y) / 2)
        // var target3857 = window.Utils.reprojectVector2(_target4326, 'EPSG:4326', 'EPSG:3857')
        // console.log(
        //   'diff',
        //   new THREE.Vector2().subVectors(target3857, center3857).toArray(),
        //   new THREE.Vector2().subVectors(target3857, center3857).length()
        // )

        return allowRefreshWithinBounds.containsPoint(new THREE.Vector2(_target4326.x, _target4326.y))
      } else {
        return true
      }
    }

    var hasPremiumImageryActivationWithinBounds =
      target4326 &&
      this.props.premiumImageryActivations.some((pia) =>
        isActiveAndWithinBounds(mapType, target4326, pia.bounds, pia.period)
      )

    var _showRefreshTerrainButton =
      ((selectedViewMapType === 'Nearmap3D' && hasPremiumImageryActivationWithinBounds) ||
        (selectedViewMapType === 'GetMapping3D' && hasPremiumImageryActivationWithinBounds) ||
        selectedViewMapType === 'Google3D') &&
      distanceFromTerrainCenterAsFraction &&
      distanceFromTerrainCenterAsFraction > 0.3

    var measureHeightVisible = window.AccountHelper.hasNearmapOnOpenSolar()
    var measureHeightEnabled = mapType === 'NearmapSource' && window.AccountHelper.hasNearmapOnOpenSolar()

    if (
      this.state.hasShadingCalcsAwaitingTrigger !== window.ShadeHelper.hasShadingCalcsAwaitingTrigger() ||
      this.state.snapshotEnabled !== _snapshotEnabled ||
      this.state.showRefreshTerrainButton !== _showRefreshTerrainButton ||
      this.state.widgetsVisible !== _widgetsVisible ||
      this.state.alignMapActive !== (window.Designer.controlMode === 'map') ||
      this.state.measureHeightActive !== window.editor.controllers.MeasureHeight.active ||
      this.state.measureHeightEnabled !== measureHeightEnabled ||
      this.state.measureHeightVisible !== measureHeightVisible
    ) {
      this.setState({
        hasShadingCalcsAwaitingTrigger: window.ShadeHelper.hasShadingCalcsAwaitingTrigger(),
        snapshotEnabled: _snapshotEnabled,
        widgetsVisible: _widgetsVisible,
        showRefreshTerrainButton: _showRefreshTerrainButton,
        alignMapActive: window.Designer.controlMode === 'map',
        measureHeightActive: window.editor.controllers.MeasureHeight.active,
        measureHeightEnabled: measureHeightEnabled,
        measureHeightVisible: measureHeightVisible,
      })
    }
  }

  render() {
    if (this.state.widgetsVisible === false) {
      return null
    }
    const {
      measureHeightActive,
      measureHeightEnabled,
      measureHeightVisible,
      snapshotEnabled,
      hasShadingCalcsAwaitingTrigger,
      showRefreshTerrainButton,
    } = this.state

    const { translate, classes } = this.props

    return (
      <div id="tool-bar-widgets" className={classes.positionWrapper}>
        <div>
          <span className={classes.divider}>
            {showRefreshTerrainButton && (
              <Button
                id="show-refresh-terrain-button"
                variant="contained"
                startIcon={<RefreshIcon />}
                onClick={() => window.SceneHelper.refreshTerrain()}
              >
                <span>{translate('Reload 3D')}</span>
              </Button>
            )}
          </span>
          <span className={classes.divider}>
            {snapshotEnabled && (
              <ToolButton
                id="DownloadSnapshotsButton"
                title={translate('Download Snapshots')}
                onClick={() => {
                  window.WorkspaceHelper.snapshotAndDownload()
                }}
                uiKey="studio.view_controls.DownloadSnapshotsButton"
              >
                <PhotoIcon classes={{ root: classes.icon }} />
              </ToolButton>
            )}
            {measureHeightVisible && (
              <ToolButton
                id="MeasureHeightButton"
                title={translate('Height Measure')}
                onClick={() => {
                  if (measureHeightActive) {
                    window.editor.controllers.MeasureHeight.finish()
                  } else {
                    window.editor.controllers.MeasureHeight.start()
                  }
                }}
                selected={measureHeightActive}
                disabled={!measureHeightEnabled}
                uiKey="studio.view_controls.MeasureHeightButton"
              >
                <HeightIcon classes={{ root: classes.icon }} />
              </ToolButton>
            )}
          </span>
          <ViewControllers />
        </div>
      </div>
    )
  }
}

export default compose(
  withStudioSignals,
  withStyles(classStyles, { name: 'toolbar-widgets' })
)(withTranslate(ToolbarWidgets))

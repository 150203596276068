import lodash from 'lodash'

export function difference(object, base): Record<string, any> {
  function flatDiff(object, base, dict, parentKey) {
    lodash.forEach(object, (value, i) => {
      const value2 = base[i]
      if (value === value2) return
      const key = parentKey.length ? `${parentKey}.${i}` : i
      if (lodash.isObject(value) && lodash.isObject(value2)) {
        flatDiff(value, value2, dict, key)
      } else {
        dict[key] = value
      }
    })
  }
  const ret = {}
  flatDiff(object, base, ret, '')
  return ret
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/**
 *
  return opensolar SVG component with standard props
 */
var createStandardIcon = function (Icon) {
    return function (_a) {
        var _b = _a.width, width = _b === void 0 ? '1rem' : _b, _c = _a.height, height = _c === void 0 ? '1rem' : _c, _d = _a.color, color = _d === void 0 ? 'currentColor' : _d, _e = _a.stroke, stroke = _e === void 0 ? 'currentColor' : _e, _f = _a.fill, fill = _f === void 0 ? 'currentColor' : _f, _g = _a.strokeWidth, strokeWidth = _g === void 0 ? '0.01rem' : _g, _h = _a.transform, transform = _h === void 0 ? '' : _h, rest = __rest(_a, ["width", "height", "color", "stroke", "fill", "strokeWidth", "transform"]);
        return (_jsx(Icon, __assign({ width: width, height: height, color: color, stroke: stroke, fill: fill, strokeWidth: strokeWidth, transform: transform }, rest)));
    };
};
export default createStandardIcon;

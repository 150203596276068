import Alert from 'elements/Alert'
import React from 'react'
import { FinanceCtaType } from 'resources/financeCTAs/constants'
import { ActionDataType } from './loanApplicationButton/types'

type PropTypes = {
  actionData: ActionDataType
}

const SEVERITY_MAP = {
  [FinanceCtaType.ALERT_ERROR]: 'error',
  [FinanceCtaType.ALERT_INFO]: 'info',
  [FinanceCtaType.ALERT_WARNING]: 'warning',
}

const AlertCTA: React.FC<PropTypes> = (props) => {
  if (!props.actionData?.status_title || !props.actionData?.cta_type || !SEVERITY_MAP[props.actionData?.cta_type])
    return null
  return (
    <div>
      <Alert severity={SEVERITY_MAP[props.actionData?.cta_type]}>
        <p>{props.actionData.status_title}</p>
        {props.actionData.status_title_secondary && <p>{props.actionData.status_title_secondary}</p>}
      </Alert>
    </div>
  )
}
export default AlertCTA

import { FormApi } from 'final-form'

/**
 * This utility makes sure that a project is in the correct state for the Lite UI.
 * If it is run on a Full project, it has the potential to lose info (but the
 * UI doesn't provide any way to do this).
 */

import { PERFORMANCE_CALCULATORS_PVWATTS_V8 } from 'constants/calculators'

const DEFAULT_SYSTEM_EFFICIENCY = 77
const DEFAULT_KWH = 0.3
export const DEFAULT_TARIFF = {
  version: '1.0',
  name: null,
  code: null,
  aliases: null,
  bill_frequency: 'quarterly',
  description: '',
  applicability: {
    sector: 0,
    popularity: 10,
  },
  solar_compensation_settings: {
    distributed_generation_rules: 'Gross Metering with Feed-in-tariff',
  },
  tax_settings: 'inclusive_tax',
  tax_percentage: 0,
  minimum_charge: null,
  has_minimum_charge: false,
  has_minimum_export_credit: false,
  criteria: null,
  price_tiering_period: 'per_billing_period',
  energy_charges: [
    {
      criteria_name: null,
      criteria_option: null,
      tou_periods: [
        {
          name: 'Energy Charge',
          blocks: [{ value: DEFAULT_KWH, units: '$/kWh', limit: null }],
          schedule: null,
        },
      ],
    },
  ],
  demand_charges: [],
  fixed_charges: [],
  export_credits: [],
  pv_capacity_charges: [],
  controlled_load_charges: [],
  percentage_adjustments_charges: [],
  percentage_adjustments_discounts: [],
}
const DEFAULT_TARIFF_STR = JSON.stringify(DEFAULT_TARIFF)

export function validateLiteProject(form: FormApi) {
  // Add a system if one doesn't already exist
  if (window.editor.getSystems().length === 0) {
    console.debug(`Creating an initial system for Lite project`)
    window.Designer.startPlacementMode('System')
  }

  const system = window.editor.getSystems()[0]

  // Set the system lifetime to 1y
  if (system.system_lifetime !== 1) {
    console.debug(`Updating Lite project's system lifetime to 1 (from ${system.system_lifetime})`)
    window.editor.execute(new window.SetValueCommand(system, 'system_lifetime', 1))
  }

  // Use PVWatts calculator
  if (system.calculator !== PERFORMANCE_CALCULATORS_PVWATTS_V8) {
    console.debug(`Updating Lite project's system calculator to PVWatts (from ${system.calculator})`)
    window.editor.execute(new window.SetValueCommand(system, 'calculator', PERFORMANCE_CALCULATORS_PVWATTS_V8))
  }

  // Set default system efficiency
  if (system.system_efficiency_override === undefined) {
    console.debug(`Updating Lite project's system efficiency to ${DEFAULT_SYSTEM_EFFICIENCY}`)
    window.editor.execute(new window.SetValueCommand(system, 'system_efficiency_override', DEFAULT_SYSTEM_EFFICIENCY))
  }

  // Add custom tariff if doesn't exist
  form.registerField(`utility_tariff_current_custom`, () => {}, {})
  if (
    !form.getState().values.utility_tariff_current_custom &&
    form.getState().values.utility_tariff_current_custom !== DEFAULT_TARIFF_STR
  ) {
    console.debug(`Updating Lite project's custom utility tariff`)
    form.change('utility_tariff_current_custom', DEFAULT_TARIFF_STR)
  }
}

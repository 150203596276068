import { getMcsRoofFacetData } from 'projectSections/sections/design/systems/tabs/mounting/nativeMounting/mcsRoofFacets'
import { StudioSystemType } from 'types/global'
import { McsData } from 'types/mcs'
import { CustomFormAnyData } from '../types'

export function FormTransform_mcs(data: CustomFormAnyData) {
  if (data.type !== 'project' && data.type !== 'system_project') return

  const selectedSystem: StudioSystemType | undefined = data.selected_system
  if (!selectedSystem) return

  let roof_facets = getMcsRoofFacetData(selectedSystem, data.project_data)
  let mcsData: McsData = data.project_data._mcs
}

// @ts-nocheck
import { makeStyles } from '@material-ui/core/styles'
import DeleteButton from 'elements/proUXButtons/DeleteButton'
import Button from 'elements/proUXButtons/ProUXButton'
import SaveButton from 'elements/proUXButtons/SaveButton'
import React from 'react'
import { Toolbar, useTranslate } from 'react-admin'
import { ContactType } from 'types/contacts'

const useStylesToolbar = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
  },
})

const useStyles = makeStyles({
  footer: {
    margin: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  rightButtonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  cancelButtonWrapper: {
    marginRight: '15px',
  },
  leftButton: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
})

type PropTypes = {
  onSuccess: Function
  onFailure: Function
  onDelete: (id?: number) => void
  onDismiss: () => void
  record: ContactType
  handleSubmitWithRedirect: Function
  handleSubmit: Function
  invalid: boolean
  pristine: boolean
  saving: boolean
  resource: string
}

const ToolBarContents = (props: PropTypes) => {
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <div className={classes.footer}>
      <div className={classes.leftButton}>
        <DeleteButton
          record={props.record || {}}
          label={translate('Uncheck Action')}
          resource={props.resource}
          onSuccess={() => props.onDelete(props.record.id)}
          onFailure={props.onFailure}
          invalid={!props.record}
        />
      </div>
      <div className={classes.rightButtonGroup}>
        <div className={classes.cancelButtonWrapper}>
          <Button type="text" label={translate('Cancel')} onClick={props.onDismiss} />
        </div>
        <div>
          <SaveButton
            label={translate('Save')}
            {...props}
            redirect={undefined}
            submitOnEnter={true}
            onSave={(values: object, redirect: string, form: any, defaultSaveFunc: Function) => {
              defaultSaveFunc(values, redirect, { onSuccess: props.onSuccess, onFailure: props.onFailure })
            }}
            saving={props.saving}
          />
        </div>
      </div>
    </div>
  )
}

const EditContactDialogFooter: React.FunctionComponent<PropTypes> = (props) => {
  return (
    <Toolbar {...props} classes={useStylesToolbar()}>
      <ToolBarContents {...props} />
    </Toolbar>
  )
}

export default EditContactDialogFooter

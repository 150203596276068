import WalletHome from './WalletHome'

export default {
  list: WalletHome,
  options: {
    list: {
      title: 'Wallet',
      breadCrumb: 'Wallet Activity',
    },
  },
}

import { orgSelectors } from 'ducks/orgs'
import { useProjectEntityPermissions } from 'projectSections/sections/manage/projectManagement/workflow/hooks'
import { useTranslate } from 'ra-core'
import { useMemo } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { ExternalWorkflow, WorkflowType } from 'types/workflows'
import { urlToId } from 'util/misc'
import { useFeatureFlag } from 'util/split'
import { getProjectSectionStyles } from '../styles'
import StageStepper from './StageStepper'
import WorkflowNotifs from './WorkflowNotifs'
import { useProjectExternalOrg } from './hooks'
import { getIsProjectSold, handleImplicitToggles } from './util'

interface PropTypes {
  selectedWorkflow?: WorkflowType
  externalWorkflow?: ExternalWorkflow
  handleChange: (workflow) => void
  noSharedWorkflows: boolean
}

const Workflow: React.FC<PropTypes> = ({ selectedWorkflow, externalWorkflow, handleChange, noSharedWorkflows }) => {
  const form = useForm()
  const translate = useTranslate()
  const project = useFormState().values
  const sectionStyles = getProjectSectionStyles()
  const externalOrg = useProjectExternalOrg()
  const activeStageId = useMemo(() => {
    if (externalWorkflow) return externalWorkflow?.selectedWorkflow?.active_stage_id
    return project.workflow?.active_stage_id
  }, [externalWorkflow?.selectedWorkflow, project.workflow])
  const enableManageShop = useFeatureFlag('manage_shop', 'on')
  const isProjectSold = getIsProjectSold(project)
  const cashFlowActiveOnOrg = useSelector(orgSelectors.getCashFlowIsActive)
  const isCashFlowProjectSold = isProjectSold && cashFlowActiveOnOrg

  const onStageChange = (workflow) => {
    handleImplicitToggles({ ...selectedWorkflow, active_stage_id: workflow.active_stage_id }, project, form)
    handleChange(workflow)
  }
  const { allowView, allowEdit } = useProjectEntityPermissions(
    selectedWorkflow?.org ? urlToId(selectedWorkflow?.org) : undefined,
    'manage_workflow_stages'
  )
  return (
    <div
      className={
        enableManageShop || isCashFlowProjectSold || !cashFlowActiveOnOrg
          ? sectionStyles.sectionCardMiddle
          : sectionStyles.sectionCardBottom
      }
    >
      {!allowView || noSharedWorkflows ? (
        <p>
          {translate('You do not have access to view the workflow and actions applied on this project by %{orgs}', {
            orgs: externalOrg,
          })}
        </p>
      ) : externalWorkflow && !selectedWorkflow ? (
        <p>
          {translate('You do not have access to view the workflow applied on this project by %{orgs}', {
            orgs: externalOrg,
          })}
        </p>
      ) : (
        <>
          <StageStepper
            workflow={selectedWorkflow}
            activeStageId={activeStageId}
            disabled={!allowEdit}
            handleChange={onStageChange}
          />
          {selectedWorkflow && <WorkflowNotifs workflow={selectedWorkflow} />}
        </>
      )}
    </div>
  )
}

export default Workflow

import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined'
import { useStudioSignalsLazy } from 'Studio/signals/useStudioSignalsLazy'
import { Button } from 'opensolar-ui'
import React, { useCallback, useState } from 'react'
import { useTranslate } from 'react-admin'
import useTranslateParse from '../../../util/useTranslateParse'
import { ToolTipPropsType, TourConfigType } from '../types'
import DefaultTooltip from './DefaultToolTip'

const Tooltip = (props: ToolTipPropsType) => {
  const { handleNext, handlePrev, handleCancel } = props
  const [is3D, setIs3D] = useState(window.MapData?.is3D(window.MapHelper?.activeMapInstance?.mapData))
  const refreshState = useCallback(() => {
    setIs3D(window.MapData?.is3D(window.MapHelper?.activeMapInstance?.mapData))
    window.dispatchEvent(new Event('resize'))
  }, [])
  const translate = useTranslate()
  const translateParse = useTranslateParse()

  useStudioSignalsLazy(refreshState, ['viewsChanged', 'objectAdded'])

  return (
    <DefaultTooltip
      title={is3D ? translate('Explore the Site') : translate('Center the Image')}
      icon={ExploreOutlinedIcon}
      content={
        <div>
          {is3D ? (
            <>
              <div style={{ marginBottom: 10 }}>
                {translate(
                  "Check out your site on the right in 3D. OpenSolar has modeled the pitch, azimuth and sun access of every point on the screen so you don't have to!"
                )}
              </div>
              <div>
                {translateParse(
                  'You can explore the site by holding down your secondary mouse button (usually "right click" on a mouse or two-fingered tap on a trackpad) and dragging around. When done click <b>Next<b>.',
                  { b: (label: string) => <b>{label}</b> }
                )}
              </div>
            </>
          ) : (
            <div>
              {translateParse(
                'Check out your site. Make sure that the home is centered in the image. When done, click <b>Next<b>.',
                { b: (label: string) => <b>{label}</b> }
              )}
            </div>
          )}
        </div>
      }
      image={
        <div style={{ padding: '0 5px' }}>
          {is3D ? (
            <img
              style={{ width: 390, height: 250 }}
              src={`${window.PUBLIC_URL}/images/align-map-3d.gif`}
              alt="Align Map 3D"
            />
          ) : (
            <img
              style={{ width: 390, height: 250 }}
              src={`${window.PUBLIC_URL}/images/align-map-2d.gif`}
              alt="Align Map 2D"
            />
          )}
        </div>
      }
      actions={
        <>
          <Button
            onClick={() => handlePrev && handlePrev()}
            onTouchStart={(e) => {
              e.stopPropagation()
              if (handlePrev) {
                handlePrev()
              }
            }}
          >
            <span>{translate('Back')}</span>
          </Button>
          <Button
            onClick={() => handleNext && handleNext()}
            onTouchStart={(e) => {
              e.stopPropagation()
              if (handleNext) {
                handleNext()
              }
            }}
          >
            <span>{translate('Next')}</span>
          </Button>
        </>
      }
      arrowPlacement={'right'}
    />
  )
}

const AlignMap: TourConfigType = {
  spotlights: [{ targetId: 'snapshot-crop-marks', padding: 0, keepObserve: true }],
  tooltips: [
    {
      targetId: 'snapshot-crop-marks',
      placement: 'left',
      component: Tooltip,
    },
  ],
}

export default AlignMap

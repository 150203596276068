import { updateRole } from 'ducks/auth_roles'
import { clearLineItems, updateSelectedHardwareSupplier } from 'ducks/orderComponents'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import { useDispatch } from 'react-redux'
import { getSupplierConfig } from '../configs'

const useHandleChangeSelectedDistributor = () => {
  const dispatch = useDispatch()
  return (desiredSupplier: HardwareSupplierEnum) => {
    const config = getSupplierConfig(desiredSupplier)
    dispatch(updateSelectedHardwareSupplier(desiredSupplier))
    dispatch(updateRole({ user_data: { selected_hardware_supplier: config?.filterKey } }))
    dispatch(clearLineItems())
  }
}

export default useHandleChangeSelectedDistributor

import { DialogActions, DialogContent, TextField } from '@material-ui/core'
import Alert from 'elements/Alert'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import { useGetOrgIdFromPaymentOptions } from 'projectSections/sections/payments/utils'
import { useNotify, useTranslate } from 'ra-core'
import React, { useEffect, useState } from 'react'
import { useFormState } from 'react-final-form'
import restClient from 'restClient'
import { RoleType } from 'types/roles'
import { CommonPromptPropTypes } from './types'

const restClientInstance = restClient(window.API_ROOT + '/api')

type RoleResponseType = {
  data: RoleType
}

const GenericEmailInputPrompt: React.FC<CommonPromptPropTypes> = (props) => {
  const [assignedRoleEmail, setAssignedRoleEmail] = useState<string | undefined>(undefined)
  const [salespersonEmail, setSalespersonEmail] = useState<string | undefined>(undefined)
  const [assignedRole, setAssignedRole] = useState<RoleType | undefined>(undefined)
  const [assignedSalesperson, setAssginedSalesperson] = useState<RoleType | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const formState = useFormState()
  const translate = useTranslate()
  const notify = useNotify()
  const project = formState.values
  const orgId = useGetOrgIdFromPaymentOptions(props.systemUuid, project.org_id)

  useEffect(() => {
    if (formState.values.assigned_role) {
      getRole(formState.values.assigned_role, 'assigned_role')
    }
  }, [formState?.values?.assigned_role])

  useEffect(() => {
    if (formState.values.assigned_salesperson_role) {
      getRole(formState.values.assigned_salesperson_role, 'assigned_salesperson_role')
    }
  }, [formState?.values?.assigned_salesperson_role])

  const getRole = (roleURL: string, roleType: 'assigned_role' | 'assigned_salesperson_role') => {
    if (!roleURL) return undefined
    restClientInstance('CUSTOM_GET', 'custom', {
      url: roleURL,
    })
      .then((roleRes: RoleResponseType) => {
        if (roleRes?.data) {
          if (roleType === 'assigned_role') setAssignedRole(roleRes.data)
          else if (roleType === 'assigned_salesperson_role') setAssginedSalesperson(roleRes.data)
        }
      })
      .catch((err) => {
        if (roleType === 'assigned_role') setAssignedRole(undefined)
        else if (roleType === 'assigned_salesperson_role') setAssginedSalesperson(undefined)
      })
  }

  //pause event listener when dialog is open, resume them when dialog closes
  useEffect(() => {
    window.editor.pauseDesignEventListener = true
    return () => {
      window.editor.pauseDesignEventListener = false
    }
  }, [])

  const onSubmit = () => {
    setIsLoading(true)
    restClientInstance('CUSTOM_PUT', 'custom', {
      url: `orgs/${orgId}/finance/${props.integration}/connect_roles/`,
      data: {
        assigned_role_id: assignedRole?.id,
        assigned_role_email: assignedRoleEmail,
        assigned_salesperson_role_id: assignedSalesperson?.id,
        assigned_salesperson_role_email: salespersonEmail,
      },
    })
      .then((res) => {
        console.log('res', res)
        notify(translate('Your updates have been saved'), 'success')
        props.onClose()
      })
      .catch((err) => {
        if (err?.body?.message) notify(err?.body?.message, 'warning')
        else notify(translate('Something went wrong'), 'warning')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <>
      <DialogContent>
        <div>
          <Alert severity="error">{props.message}</Alert>
          {assignedRole?.display && (
            <>
              <h2>Assigned Role - {assignedRole?.display}</h2>
              <div style={{ width: '100%', marginTop: '20px' }}>
                <TextField
                  label={`Lender Email Address for ${assignedRole?.display}`}
                  name="Email Address"
                  value={assignedRoleEmail}
                  style={{ width: '100%' }}
                  fullWidth
                  onChange={(event) => {
                    const value = event.target.value
                    setAssignedRoleEmail(value)
                  }}
                />
              </div>
            </>
          )}
          {assignedSalesperson?.display && (
            <>
              <h2>Assigned Salesperson - {assignedSalesperson?.display}</h2>
              <div style={{ width: '100%', marginTop: '20px' }}>
                <TextField
                  label={`Lender Email Address for ${assignedSalesperson?.display}`}
                  name="Email Address"
                  value={salespersonEmail}
                  style={{ width: '100%' }}
                  fullWidth
                  onChange={(event) => {
                    const value = event.target.value
                    setSalespersonEmail(value)
                  }}
                />
              </div>
            </>
          )}
          <Alert severity="info">
            Either the assigned salesperson or the assigned role must connect their user to the lender by providing the
            email address they use in the lender's portal. If both users provide their lender email then applications
            will be credited to the salesperson.
          </Alert>
        </div>
      </DialogContent>
      <DialogActions>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <div style={{ margin: 20 }}>
            <ProUXButton type="primary" label="Save Changes" onClick={onSubmit} loading={isLoading} />
          </div>
        </div>
      </DialogActions>
    </>
  )
}
export default GenericEmailInputPrompt

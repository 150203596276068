import { makeStyles, MenuItem, MenuItemProps } from '@material-ui/core'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import classnames from 'classnames'
import React, { isValidElement } from 'react'

const EmptyLogo = () => (
  <div
    style={{
      width: 44,
      height: 32,
      background: 'rgb(247, 247, 247)',
      verticalAlign: 'middle',
      display: 'inline-block',
    }}
  />
)

const useStyles = makeStyles(
  (theme) => ({
    root: {
      fontWeight: 400,
      display: 'flex',
    },
    selected: {
      fontWeight: 500,
    },
    extraText: {
      fontSize: 12,
      lineHeight: '15px',
      marginRight: 5,
      display: 'inline-block',
      maxWidth: 200,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    logo: {
      flex: '0 0 48px',
      textAlign: 'center',
    },
    suggestion: {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      flex: '1 1 100%',
      margin: 'auto auto auto 2px',
    },
    suggestionText: { fontWeight: 300 },
    highlightedSuggestionText: { fontWeight: 500 },
  }),
  { name: 'CustomAutocompleteSuggestionItem' }
)

interface Props {
  suggestion: any
  index: number
  highlightedIndex: number
  isSelected: boolean
  filterValue: string
  classes?: any
  getSuggestionText: (suggestion: any) => string
  getSuggestionValue: (suggestion: any) => string
  allowEmpty: boolean
  formatValue: (data: any) => string
}

const CustomAutocompleteItem: React.FC<Props & MenuItemProps<'li', { button?: true }>> = (props) => {
  const {
    suggestion,
    index,
    highlightedIndex,
    isSelected,
    filterValue,
    allowEmpty,
    classes: classesOverride,
    getSuggestionText,
    getSuggestionValue,
    formatValue,
    ...rest
  } = props
  const classes = useStyles(props)
  const isHighlighted = highlightedIndex === index
  const suggestionText = getSuggestionText(suggestion)
  const suggestionValue = getSuggestionValue(suggestion)
  const data = suggestion.data && JSON.parse(suggestion.data)
  let matches
  let parts

  if (!isValidElement(suggestionText)) {
    matches = match(suggestionText, filterValue)
    parts = parse(suggestionText, matches)
  }

  if (!suggestionValue && allowEmpty) {
    return (
      <MenuItem
        key={suggestionText}
        selected={isHighlighted}
        className={classnames(classes.root, {
          [classes.selected]: isSelected,
        })}
        {...rest}
      >
        <div>None</div>
      </MenuItem>
    )
  }

  return (
    <MenuItem
      key={suggestionText}
      selected={isHighlighted}
      className={classnames(classes.root, {
        [classes.selected]: isSelected,
      })}
      {...rest}
    >
      <div className={classes.logo}>
        {suggestion.logo ? (
          <img
            src={suggestion.logo}
            style={{
              display: 'inline-block',
              maxHeight: 36,
              maxWidth: 48,
              textAlign: 'center',
              verticalAlign: 'middle',
            }}
          />
        ) : (
          <EmptyLogo />
        )}

        <i style={{ display: 'inline-block', height: '100%', width: 0, verticalAlign: 'middle' }} />
      </div>
      <div className={classes.suggestion}>
        <div>
          {parts.map((part: any, index: number) => {
            return part.highlight ? (
              <span key={index} className={classes.highlightedSuggestionText}>
                {part.text}
              </span>
            ) : (
              <strong key={index} className={classes.suggestionText}>
                {part.text}
              </strong>
            )
          })}
        </div>
        <div style={{ display: 'flex' }}>
          <span className={classes.extraText}>{suggestion.manufacturer_name}</span>
          {data && formatValue && <span className={classes.extraText}>{formatValue(data)}</span>}
        </div>
      </div>
    </MenuItem>
  )
}

export default CustomAutocompleteItem

import { authSelectors } from 'ducks/auth'
import { PromoFilters } from 'persistentContent/inAppPromo/utils'
import { useSelector } from 'react-redux'

const useAdFilters = ({
  formats,
  placement,
  requireMeetsOne = false,
}: {
  formats: string[]
  placement: string
  requireMeetsOne?: boolean
}): BannerFilters => {
  const org = useSelector(authSelectors.getCurrentOrg)
  const orgCountry = useSelector(authSelectors.getCurrentOrgCountry) || ''
  const role = useSelector(authSelectors.getCurrentRole)
  const user = useSelector(authSelectors.getCurrentUser)
  const isAdmin: boolean = useSelector(authSelectors.getIsAdmin)
  const acceptedTermsDate = useSelector(authSelectors.getAcceptedTermsDate)

  return {
    org,
    orgCountry,
    role,
    user,
    isAdmin,
    requireMeetsOne,
    formats,
    placement,
    acceptedTermsDate,
  }
}

export type BannerFilters = PromoFilters & {
  formats: string[]
  placement: string
}

export default useAdFilters

import { Grid } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ComponentTypeImg from 'elements/field/ComponentTypeImag'
import { TotalCost } from 'pages/ordering/order/table/Cost'
import LowStockWarning from 'pages/ordering/order/table/quantity/LowStockWarning'
import type LineItemType from 'pages/ordering/OrderLineItem'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  container: {
    fontSize: theme.typography.caption.fontSize,
    margin: '10px 0',
    boxShadow: 'none',
  },
  textWrapper: {
    overflow: 'hidden',
  },
  quantity: {
    textAlign: 'center',
  },
  icon: {
    textAlign: 'center',
  },
  segenLoginPrompt: {
    padding: 0,
    marginBottom: 10,
    textAlign: 'end',
  },
}))

const BomComponentDetail = ({ title, lineItems }: { title: string; lineItems: LineItemType[] }) => {
  const classes = useStyles()
  const isActivatedComponentOrderingDataReady = true

  return (
    <Accordion defaultExpanded={true} elevation={2} className={classes.container}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle2">{`${title} (${lineItems.length})`}</Typography>
      </AccordionSummary>
      {lineItems.map((lineItem: LineItemType) => {
        const { beforeDiscount, afterDiscount, discount } = lineItem.getBestDiscountOffer()
        return (
          <Grid key={lineItem.code} container alignContent="flex-start" alignItems="flex-start" spacing={1}>
            <Grid item xs={1} className={classes.icon}>
              <ComponentTypeImg componentType={lineItem.componentType} size="small" />
            </Grid>
            <Grid item xs={2} className={classes.textWrapper}>
              {lineItem.data?.manufacturer_name}
            </Grid>
            <Grid item xs={4} className={classes.textWrapper}>
              {lineItem?.data?.short_description && (
                <Typography style={{ fontWeight: 'bold' }}>{lineItem.data.short_description}</Typography>
              )}
              {lineItem.code}
            </Grid>
            <Grid item xs={3} className={classes.quantity}>
              {lineItem.quantity}
              {/* only enable the warning when ordering data ready
              awaiting https://github.com/open-solar/sunsuite/pull/6108 get merged */}
              {isActivatedComponentOrderingDataReady && <LowStockWarning lineItem={lineItem} />}
            </Grid>
            {isActivatedComponentOrderingDataReady && (
              <Grid item xs={2}>
                {beforeDiscount !== undefined ? (
                  <TotalCost beforeDiscount={beforeDiscount} afterDiscount={afterDiscount} />
                ) : (
                  'N/A'
                )}
              </Grid>
            )}
            {/* Do we want to add prompt to enable segen ?*/}
            {/* {enableSegen && (
              <Grid item xs={12} className={classes.segenLoginPrompt}>
                <SegenLoginPrompt
                  segenService={segenService}
                  label={'Sign in with Segen to view price and stock status'}
                />
              </Grid>
            )} */}
          </Grid>
        )
      })}
    </Accordion>
  )
}

export default BomComponentDetail

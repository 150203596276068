import { useMediaQuery } from '@material-ui/core'
import { DefaultBreadCrumbs } from 'elements/BreadCrumbs'
import { List } from 'elements/react-admin/List'
import {
  ChipField,
  Datagrid,
  DeleteButton,
  EditButton,
  EmailField,
  FilterComp,
  FunctionField,
  ReferenceArrayField,
  SimpleList,
  SingleFieldList,
  TextField,
  TextInput,
  useTranslate,
  withTranslate,
} from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { styles as standardStyles } from 'styles'

export const _ContactFilter = (props) => (
  <FilterComp {...props}>
    {/*@TODO: Fix QuickFilter to allow multiple with the same source */}
    {/*<QuickFilter label="Inactive" source="is_active" defaultValue={0} />*/}
    <TextInput label="pos.search" style={standardStyles.FILTER_FIELD_STYLE} source="q" />
  </FilterComp>
)

export const ContactFilter = connect((state) => ({ recentProjects: state.recentProjects }), {})(_ContactFilter)
const getResourceLinkPath = (basePath, id, linkType) => {
  return `/projects/${encodeURIComponent(id)}/info`
}
const _ContactList = ({ accessRights: { allowView, allowCreate, allowEdit, allowDelete }, ...props }) => {
  const translate = useTranslate()
  // var reportSelected = detectSelectedReport(props.location.pathname, props.location.search, props.recentProjects)
  // var showButtons = reportSelected.title === CUSTOM_REPORT_TITLE
  // var urlOriginal = props.location.pathname + props.location.search

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  return (
    <List
      showButtons={true}
      breadCrumbs={<DefaultBreadCrumbs />}
      titleStyle={{ fontSize: 16, lineHeight: '16px', paddingTop: 0 }}
      hasSearch={true}
      perPage={20}
      {...props}
      hasCreate={allowCreate}
      filter={{ type: 0 }}
    >
      {isMobile ? (
        <SimpleList
          primaryText={(record) =>
            (record.first_name && record.first_name.length) || (record.family_name && record.family_name.length)
              ? `${record.first_name} ${record.family_name}`
              : translate('(Unnamed)')
          }
          secondaryText={(record) => (
            <span>
              {translate('Email')}: {record.email}
              <br />
              {translate('Phone')}: {record.phone}
            </span>
          )}
          linkTo={(basePath, id) => `${basePath}/${id}`}
        />
      ) : (
        <Datagrid>
          <TextField source="first_name" />
          <TextField source="family_name" />
          {isDesktop && <EmailField source="email" />}
          {isDesktop && <TextField source="phone" />}
          {isDesktop && (
            <FunctionField
              source={'projects'}
              label="Projects"
              render={(record, source, rest) => (
                <div>
                  <ReferenceArrayField record={record} source={source} reference="projects" {...rest}>
                    <SingleFieldList getResourceLinkPath={getResourceLinkPath}>
                      <ChipField style={{ maxWidth: 140 }} source="title" />
                    </SingleFieldList>
                  </ReferenceArrayField>
                </div>
              )}
              sortable={false}
            />
          )}

          <FunctionField
            source={null}
            label="Actions"
            render={(record, source, rest) => (
              <div>
                {allowEdit && <EditButton useListStyle={true} record={record} basePath={rest.basePath} />}
                {allowDelete && (
                  <DeleteButton
                    useListStyle={true}
                    undoable={false}
                    record={record}
                    resource={rest.resource}
                    redirect={`/${rest.resource}`}
                  />
                )}
              </div>
            )}
            sortable={false}
          />
        </Datagrid>
      )}
    </List>
  )
}
const mapStateToPropsList = (state) => {
  return {
    recentProjects: state.recentProjects,
  }
}
export default compose(withTranslate, connect(mapStateToPropsList, {}))(_ContactList)

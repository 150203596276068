import { useCallback, useState } from 'react'
import { useDataProvider, useNotify } from 'react-admin'
import type { InventoryFiltersType } from '../../filter/type'
import type { DashboardStatisticsData } from '../type'

type LoadInventoryStatisticsOptions = {
  forecast?: boolean
  filters?: InventoryFiltersType
}

export type LoadDashboardStatistics = (options?: LoadInventoryStatisticsOptions) => Promise<void>

type DashboardStatisticsResponse = {
  dashboardStatistics: DashboardStatisticsData | undefined
  loadingDashboardStatistics: boolean
  loadDashboardStatistics: LoadDashboardStatistics
}

const useDashboardStatistics = (): DashboardStatisticsResponse => {
  const [dashboardStatistics, setDashboardStatistics] = useState<DashboardStatisticsData | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const loadDashboardStatistics = useCallback(async (options?: LoadInventoryStatisticsOptions) => {
    setIsLoading(true)
    const forecastResult = options?.forecast ? 1 : 0
    try {
      await dataProvider
        .CUSTOM_POST(
          'inventory/dashboard',
          {
            url: `inventory/dashboard/?forecast=${forecastResult}`,
            data: options?.filters,
          },
          {
            onSuccess: ({ data }: { data: DashboardStatisticsData }) => {
              setDashboardStatistics(data)
            },
          }
        )
        .finally(() => {
          setIsLoading(false)
        })
    } catch (e) {
      notify('Failed to get inventory statistics')
      setIsLoading(false)
    }
  }, [])

  return {
    dashboardStatistics,
    loadingDashboardStatistics: isLoading,
    loadDashboardStatistics,
  }
}

export default useDashboardStatistics

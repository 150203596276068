import { makeStyles } from '@material-ui/core'
import InfoTooltip from 'elements/tooltip/InfoTooltip'
import React from 'react'
import { OpenSolarThemeType } from 'Themes'

const useStyles = makeStyles((theme: OpenSolarThemeType) => ({
  icon: { width: 20, height: 20, color: theme.blue, verticalAlign: 'middle' },
  iconWithError: { width: 20, height: 20, color: 'rgb(230, 37, 0)', verticalAlign: 'middle' },
  popover: {
    pointerEvents: 'none',
    transform: 'translate(20px, -20px)',
    [theme.breakpoints.down('xs')]: {
      transform: 'none',
    },
  },
  paper: {
    pointerEvents: 'auto',
    padding: 10,
    width: 260,
  },
}))

const MouseOverPopover = ({ hasError, children }: { hasError: boolean; children: NonNullable<React.ReactNode> }) => {
  const classes = useStyles()
  return (
    <InfoTooltip
      placement="right"
      interactive={true}
      className={hasError ? classes.iconWithError : classes.icon}
      title={children}
    />
  )
}

export default MouseOverPopover

import { Dialog, DialogContent } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import { IconButton } from 'opensolar-ui'
import PropTypes from 'prop-types'
import React from 'react'
import { ProjectEditUsageModal } from 'resources/projects/UsageComponents.js'
import { userLoginData } from './data'

class EditUsage extends React.Component {
  render() {
    return (
      <div>
        <Dialog className="modal" open={this.props.isOpen} onBackdropClick={() => this.props.closeDialog()}>
          <DialogContent>
            <div style={{ minHeight: 700 }}>
              <ProjectEditUsageModal
                resource={this.props.resource}
                match={{ params: { id: this.props.id } }}
                location={{ pathname: this.props.pathname }}
              />

              <IconButton
                id="EditUsageClose"
                style={{
                  top: 10,
                  right: 10,
                  position: 'absolute',
                  minWidth: 40,
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                }}
                onClick={() => this.props.closeDialog()}
              >
                {<CloseIcon color="#000000" />}
              </IconButton>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    )
  }
}

EditUsage.propTypes = {
  isOpen: PropTypes.bool,
  selectedProject: PropTypes.object,
  closeDialog: PropTypes.func.isRequired,
}

EditUsage.defaultProps = {
  isOpen: false,
  selectedProject: userLoginData[0].projects[0],
}

export default EditUsage

import { logAmplitudeEvent } from 'amplitude/amplitude'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import { useTranslate } from 'ra-core'
import React from 'react'
import { useFormState } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '400px',
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: theme.greyLight2,
  },
  titleText: {
    color: theme.greyMid1,
    fontSize: '18px',
    fontWeight: 200,
    textAlign: 'center',
  },
  paragraph: {
    width: '450px',
    textAlign: 'center',
    margin: '30px 0px',
  },
  buttonWrapper: {},
}))

export const NoSystems: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const translate = useTranslate()
  const project = useFormState()?.values

  const onClick = () => {
    logAmplitudeEvent('payments_page_design_a_system_clicked', { project_id: project.id })
    history.push(`/projects/${project.id}/design`)
  }
  return (
    <div className={classes.wrapper}>
      <div className={classes.titleText}>{translate('Please design a system first')}</div>
      <p className={classes.paragraph}>
        {translate(
          'Design a solar or battery system for this project to see Cash Purchase price and add payment options.'
        )}
      </p>
      <div className={classes.buttonWrapper}>
        <ProUXButton type="secondary" label="Design a system" onClick={onClick} />
      </div>
    </div>
  )
}
export default NoSystems

import { azimuthAccessorConfig } from './azimuth'
import { azimuthAutoAccessorConfig } from './azimuthAuto'
import { elevationAutoAccessorConfig } from './elevationAuto'
import { groundClearanceAccessorConfig } from './groundClearance'
import { groupSpacingAccessorConfig } from './groupSpacing'
import { moduleSpacingAccessorConfig } from './moduleSpacing'
import { modulesPerColAccessorConfig } from './modulesPerCol'
import { modulesPerRowAccessorConfig } from './modulesPerRow'
import { offsetAccessorConfig } from './offset'
import { orientationAccessorConfig } from './orientation'
import { panelPlacementAccessorConfig } from './panelPlacement'
import { rackingTypeAccessorConfig } from './rackingType'
import { slopAccessorConfig } from './slope'
import { slopeAutoAccessorConfig } from './slopeAuto'
import { tiltAccessorConfig } from './tilt'
import { trackingModeAccessorConfig } from './trackingMode'

const MODULE_GRID_SETTER_GETTER_CONFIGS = {
  azimuth: azimuthAccessorConfig,
  azimuthAuto: azimuthAutoAccessorConfig,
  elevationAuto: elevationAutoAccessorConfig,
  groundClearance: groundClearanceAccessorConfig,
  groupSpacing: groupSpacingAccessorConfig,
  moduleSpacing: moduleSpacingAccessorConfig,
  modulesPerCol: modulesPerColAccessorConfig,
  modulesPerRow: modulesPerRowAccessorConfig,
  offset: offsetAccessorConfig,
  orientation: orientationAccessorConfig,
  panelPlacement: panelPlacementAccessorConfig,
  rackingType: rackingTypeAccessorConfig,
  slope: slopAccessorConfig,
  slopeAuto: slopeAutoAccessorConfig,
  tilt: tiltAccessorConfig,
  trackingMode: trackingModeAccessorConfig,
} as const
export default MODULE_GRID_SETTER_GETTER_CONFIGS

import { ConditionFactory, SystemContext } from 'Designer/designRules/types'
import { BaseConditionDef } from '.'

export type ExhibitorAvailableDef = BaseConditionDef & {
  type: 'exhibitor-available'
  name: string
}

export const condition_exhibitorAvailable: ConditionFactory<ExhibitorAvailableDef> = (def) => {
  return {
    getProjectFields: () => [],
    getStudioSignals: () => [],
    check: (context: SystemContext) => {
      return context.exhibitors.some((e) => e.name === def.name)
    },
  }
}

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import FullScreenLoading from 'layout/widgets/FullScreenLoading'
import { Grid, IconButton } from 'opensolar-ui'
import type LineItemType from 'pages/ordering/OrderLineItem'
import ProductCard from './content/ProductCard'
import ProductDetailDescription3 from './content/ProductDetailDescription3'
import TechnicalDetails3 from './content/TechnicalDetails3'
import { ProductDetailNotFound } from './ProductDetail'
import useProductSpecsData from './useProductSpecsData'
import useHardwareSelectorFetchEngine from '../../../elements/hardwareSelectorV2/fetch/useHardwareSelectorFetchEngine'
import listQueryParamsFormatter from '../../../elements/input/listQueryParamsFormatter'
import { ProductDetailCheckoutMultiDistributor } from './content/ProductDetailCheckoutMultiDistributor'

const ProductDetail3 = ({
  loading,
  componentCode,
  lineItem,
  goBack,
}: {
  loading: boolean
  componentCode: string
  lineItem: LineItemType | undefined
  goBack?(): void
}) => {
  const productDetailData = useProductSpecsData(lineItem)
  const componentCodesSet = new Set([componentCode])
  let productDetailsDataV2 = undefined

  const { componentsData } = useHardwareSelectorFetchEngine({
    componentTypes: [],
    limit: 1,
    defaultFilterValues: {
      codes: listQueryParamsFormatter.format({ value: componentCodesSet }),
    },
  })

  if (componentsData && componentsData.length > 0) {
    productDetailsDataV2 = componentsData[0]
  }

  if (loading) {
    return <FullScreenLoading />
  }
  if (!productDetailData || !lineItem) {
    return <ProductDetailNotFound />
  }

  return (
    <Grid container xs spacing={3} style={{ padding: 20 }}>
      <Grid item xs={12}>
        <IconButton onClick={goBack} size="small">
          <ArrowBackIosIcon />
          Back
        </IconButton>
      </Grid>
      <Grid item xs>
        <ProductCard lineItem={lineItem} outputPower={(productDetailData.kw_stc || 0) * 1000} />
      </Grid>
      <Grid item xs={4}>
        <ProductDetailCheckoutMultiDistributor component={productDetailsDataV2} />
      </Grid>
      <Grid item xs={12}>
        <TechnicalDetails3
          productDetailData={productDetailData}
          componentType={lineItem.componentType}
          quantityPerItem={lineItem.quantityPerItem}
        />
      </Grid>
      <Grid item xs={12}>
        <ProductDetailDescription3 lineItem={lineItem} />
      </Grid>
    </Grid>
  )
}

export default ProductDetail3

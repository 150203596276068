import { Dialog } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { setSelectComponentDialog } from 'ducks/selectComponent'
import React, { FC, useCallback, useEffect } from 'react'
import { changeListParams } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import type { ComponentTypes } from 'types/selectComponent'
import { RootState } from 'types/state'
import { DialogHelper } from 'util/misc'
import { useFeatureFlag } from 'util/split'
import HardwareSelectionDialogContent, { HardwareSelectionPropsType } from './HardwareSelectionDialog'
import HardwareSelectionDialogV2 from './HardwareSelectionDialogV2'
import HardwareSelectionHeader from './HardwareSelectionHeader'

export const plural = (key: string) => (key === 'battery' ? 'batteries' : key + 's')

interface HardwareSelectorLabelSet {
  singular: string
  plural: string
  cannotFind: string
  footerCallToAction: string
  searchHint: string
}

export const LABELS_FOR_COMPONENT_TYPES: { [key in ComponentTypes]: HardwareSelectorLabelSet } = {
  module: {
    singular: 'Module',
    plural: 'Modules',
    cannotFind: "Can't find module?",
    footerCallToAction: 'To search from over 20,000 modules in the OpenSolar database, add search terms above.',
    searchHint: 'Search by module code, manufacturer name and/or rating in Watts...',
  },
  inverter: {
    singular: 'Inverter',
    plural: 'Inverters',
    cannotFind: "Can't find inverter?",
    footerCallToAction: 'To search from over 4,000 inverters in the OpenSolar database, add search terms above.',
    searchHint: 'Search by inverter code, and/or manufacturer name...',
  },
  battery: {
    singular: 'Battery',
    plural: 'Batteries',
    cannotFind: "Can't find battery?",
    footerCallToAction: 'To search from over 400 batteries in the OpenSolar database, add search terms above.',
    searchHint: 'Search by battery code and/or manufacturer name...',
  },
  other: {
    singular: 'Other Component',
    plural: 'Other Components',
    cannotFind: "Can't find component?",
    footerCallToAction: 'To search from over 600 other components in the OpenSolar database, add search terms above.',
    searchHint: 'Search by component code and/or manufacturer name...',
  },
}

export const RECORDS_PER_PAGE = 20

const useStyles = makeStyles((theme) => ({
  dialog: { minHeight: 520 },
}))

const HardwareSelector: FC<HardwareSelectionPropsType> = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const { isOpen, version, componentType } = useSelector(mapStateToProps)
  const enableHardwareFilterV2 = useFeatureFlag('hardware_filter_v2', 'on')
  useEffect(() => {
    if (isOpen) {
      DialogHelper.afterOpen('DialogSelectComponent' + componentType)
    }
  }, [isOpen])
  const handleClose = useCallback(() => {
    DialogHelper.beforeClose()
    dispatch(setSelectComponentDialog(false, null, null, []))
    history.push(location.pathname)
    dispatch(
      changeListParams('component_' + plural(componentType), {
        sort: null,
        order: null,
        page: 1,
        perPage: RECORDS_PER_PAGE,
        filter: {},
      })
    )
  }, [location.pathname, componentType])
  return (
    <Dialog
      id="hardware-selector-dialog"
      key={version}
      maxWidth="lg"
      classes={{ root: classes.dialog }}
      open={isOpen}
      onBackdropClick={handleClose}
    >
      <HardwareSelectionHeader handleClose={handleClose} componentType={componentType} />
      {enableHardwareFilterV2 ? (
        <HardwareSelectionDialogV2 {...props} />
      ) : (
        <HardwareSelectionDialogContent {...props} />
      )}
    </Dialog>
  )
}

const mapStateToProps = (state: RootState) => {
  var componentType
  try {
    componentType = state.selectComponent.componentType
  } catch (e) {
    componentType = null
  }

  var version
  try {
    version = state.selectComponent.version
  } catch (e) {
    version = 1
  }

  var isOpen
  try {
    isOpen = Boolean(state.selectComponent.isOpen)
  } catch (e) {
    isOpen = false
  }

  return {
    version: version,
    componentType: componentType,
    isOpen: isOpen,
  }
}

export default HardwareSelector

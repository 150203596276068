// in src/users.js
import { Portal } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/SearchOutlined'
import React, { useCallback, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'

import { Dialog, DialogActions, DialogContent, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { push as pushAction } from 'connected-react-router'
import { permissionsSelectors } from 'ducks/permissions'
import {
  selectTariff as selectTariffAction,
  setSelectTariffDialog as setSelectTariffDialogAction,
} from 'ducks/selectTariff'
import { studioSelectors } from 'ducks/studioMode'
import { StyledDialogTitle } from 'layout/StyledDialog'
import { Button } from 'opensolar-ui'
import {
  AutocompleteInput,
  BooleanInput,
  Datagrid,
  FilterComp,
  FunctionField,
  List,
  PaginationComp,
  ReferenceInput,
  SelectInput,
  TextInput,
  changeListParams as changeListParamsAction,
  showNotification as showNotificationAction,
  useTranslate,
} from 'react-admin'
import { useLocation } from 'react-router-dom'
import { UTILITY_TARIFF_SECTOR_CHOICES } from 'resources/utilityTariffs/common'
import { DialogHelper, getRoleFromState } from 'util/misc'
import { getOrgFromState } from 'util/org'
import { getQueryVariable } from 'util/query'
import useTranslateParse from 'util/useTranslateParse'
import TariffDialogCreateButton from './TariffDialogCreateButton'

const getDefaultFilterValues = (countryId, projectId) => {
  var filter = {
    country: countryId,
    fieldset: 'full',
    project_id: projectId,
    tariff_dataset: 'default',
    fav: 0,
    ordering: 'recently_used',
  }

  return filter
}

const wrapPortalIfTrue = (enablePortal, input) => {
  if (enablePortal) {
    return <Portal container={() => document.getElementById('tariff-selector-header')}>{input}</Portal>
  } else {
    return input
  }
}

const TariffFilter = connect((state) => {
  var obj = {
    extraFilters: {},
  }

  try {
    obj.extraFilters.projectId = state.selectTariff.projectId
  } catch (e) {
    //
  }

  return obj
}, {})((props) =>
  wrapPortalIfTrue(
    !props.isMobile,
    <>
      <FilterComp style={{ display: 'block' }} {...props} alignment="left" variant="outlined" debounce={1000}>
        <TextInput
          style={{ flex: 1 }}
          fullWidth={true}
          alwaysOn={true}
          autoFocus={true}
          label="Keyword Search"
          source="q"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </FilterComp>
      <FilterComp {...props} style={{ display: 'flex' }} variant="outlined" debounce={1000}>
        <ReferenceInput
          fullWidth={true}
          alwaysOn={true}
          label="Search Utility"
          source="utility"
          reference="utilities"
          filter={{ country: props.countryId }}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteInput optionText="name" optionValue="id" emptyLabel="Any" />
        </ReferenceInput>
        <ReferenceInput
          fullWidth={true}
          alwaysOn={true}
          label="Search Distributor"
          source="distributor"
          reference="distributors"
          filter={{ country: props.countryId }}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteInput optionText="name" optionValue="id" emptyLabel="Any" />
        </ReferenceInput>
        {/*
        <SelectInput
          alwaysOn={true}
          source="popularity_filter"
          label="Popularity"
          choices={[
            { id: 'any', name: 'Any' },
            { id: 'low', name: 'Low' },
            { id: 'high', name: 'High' },
          ]}
        />
      */}
        <SelectInput
          fullWidth={true}
          alwaysOn={true}
          label="Sector"
          source="sector"
          choices={[{ id: 'Any', name: 'Any' }].concat(UTILITY_TARIFF_SECTOR_CHOICES)}
          parse={(v) => (v === 'Any' ? null : v)}
          format={(v) => (!v ? 'Any' : v)}
        />
        <BooleanInput alwaysOn={true} label="Show Historical Tariffs" source="show_historical_tariffs" />

        {/*<SelectInput
      alwaysOn={true}
      options={{ style: standardStyles.FILTER_FIELD_STYLE }}
      source="project_id"
      choices={[
        { id: props.projectId, name: 'Match Project Location' },
        { id: 'None', name: 'Entire Country' },
      ]}
    />
    */}

        {/*(
        <SelectInput
          alwaysOn={true}
          elStyle={{ width: 340, marginTop: -18 }}
          source="tariff_dataset"
          labelEmpty="Public & Custom"
          choices={[
            // { id: 'None', name: 'Public & Custom' },
            // { id: 1, name: 'Public Only' },
            // { id: 0, name: 'Custom Only' },
            { id: 'default', name: 'Current Tariffs + My Tariffs (Default)' },
            { id: 'my', name: 'My Tariffs' },
            { id: 'historical', name: 'Historical Tariffs' },
            { id: 'all', name: 'All' },
          ]}
          // parse={v => (v === 'None' ? null : v)}
          // format={v => (v === 1 || v === 0 ? v : 'None')}
        />
      )*/}

        {/*
    <ReferenceInput alwaysOn={true} elStyle={{ width: 160 }} label="Country" source="country" reference="countries">
      <AutocompleteInput options={{ fullWidth: true }} optionText="name" optionValue="id" />
    </ReferenceInput>
    */}
      </FilterComp>
    </>
  )
)

const listStyles = makeStyles((theme) => ({
  wrapper: {},
  table: {
    tableLayout: 'fixed',
  },
  list: {
    // Fix position of filters for mobile and non-mobile layouts by removing this
    // display: 'flex',

    alignItems: 'flex-start',

    // Fix position of filters for mobile and non-mobile layouts by removing this
    '& .Ra-List-Toolbar-wrapper': {
      [theme.breakpoints.up('sm')]: {
        display: 'none !important',
      },
      [theme.breakpoints.down('xs')]: {
        margin: 10,
      },
    },
    padding: 0,
    '& .Ra-List-Toolbar': {
      width: '100%',
    },
    '& .Ra-List-Main': {
      flexGrow: 1,
      padding: 0,
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    '& form': {
      width: '100%',
    },
    '& td': {
      padding: '10px 16px',
    },
  },
}))

const TariffList = (props) => {
  const classes = listStyles()
  const translate = useTranslate()
  const muiTheme = useTheme()
  const location = useLocation()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const queryFilter = getQueryVariable('filter')

  useEffect(() => {
    if (queryFilter) {
      const filters = JSON.parse(queryFilter)
      if (filters) {
        logAmplitudeEvent('select_tariff_dialog_filters', {
          keyword_search: filters.q,
          utility_id: filters.utility,
          distributor_id: filters.distributor,
          sector_id: filters.sector,
        })
      }
    }
  }, [queryFilter])
  return (
    <div>
      <List
        actions={null}
        id={props.orgId}
        showButtons={false}
        hasCreate={false}
        hideBreadCrumbs={true}
        className={classes.list}
        format="popup"
        hideTitle={true}
        filters={props.filters}
        pagination={<PaginationComp widthOverride={1} />}
        perPage={20}
        location={{
          hash: '',
          pathname: '/utility_tariffs',
        }}
        filter={getDefaultFilterValues(props.countryId, props.projectId)}
        resource="utility_tariffs"
      >
        <Datagrid stylesOverride={{}}>
          <FunctionField
            source="rate"
            sortable={false}
            label={'Utility'}
            headerStyle={{ flex: '1 1 100%' }}
            render={(record) => (
              <div style={{ flex: '1 1 100%', marginRight: 5 }}>
                <div>{record?.utility_name}</div>
              </div>
            )}
          />
          <FunctionField
            source="rate"
            sortable={false}
            label={'Name'}
            headerStyle={{ flex: '1 1 100%' }}
            render={(record) => (
              <div style={{ flex: '1 1 100%', marginRight: 5 }}>
                <div>{record?.name}</div>
              </div>
            )}
          />
          <FunctionField
            source="rate"
            sortable={false}
            label={'Code'}
            headerStyle={{ flex: '1 1 100%' }}
            render={(record) => (
              <div style={{ flex: '1 1 100%', marginRight: 5 }}>
                <div>{record.code}</div>
              </div>
            )}
          />
          {!isMobile && (
            <FunctionField
              source="hasActivation"
              sortable={false}
              label={'Data Source'}
              headerStyle={{ width: 110 }}
              render={(record) => (
                <div>
                  {record.org_id !== props.orgId || (record.org_id === 1 && record.is_public_read_only)
                    ? translate('OpenSolar Database')
                    : translate('Custom Tariff')}
                  {record.recently_used ? (
                    <>
                      <br />
                      <div style={{ color: '#0094FF' }}>{translate('Recently Used')}</div>
                    </>
                  ) : null}
                </div>
              )}
            />
          )}

          <FunctionField
            source="select"
            sortable={false}
            label={null}
            headerStyle={{ width: isMobile ? 74 : 110 }}
            render={(record) => (
              <Button
                variant="contained"
                size={isMobile ? 'small' : 'medium'}
                hoverColor={muiTheme.themeColor}
                labelStyle={{ padding: 0, margin: 0 }}
                onClick={() => {
                  DialogHelper.beforeClose()
                  props.onSelect(record, props.currentOrProposed)
                  props.push(location.pathname)
                  props.changeListParams('utility_tariffs', {
                    sort: null,
                    order: null,
                    page: 1,
                    perPage: null,
                    filter: {},
                  })
                }}
              >
                <span>{translate('Select')}</span>
              </Button>
            )}
          />
        </Datagrid>
      </List>
    </div>
  )
}

const useStyles = makeStyles({
  paper: {
    width: '80%',
    maxWidth: 1200,
    position: 'relative',
  },
  header: {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
    padding: '20px 20px 10px',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItem: 'center',
    fontSize: 20,
    fontWeight: 'bold',
  },
  content: { borderTop: '2px solid rgba(0, 0, 0, 0.12)', padding: 0 },
  wrapper: {
    position: 'sticky',
    bottom: 0,
    right: 0,
    width: '100%',
    display: 'flex',
    // flexWrap: 'wrap',
    background: 'rgb(243, 242, 242)',
    height: 60,
    padding: 0,
  },
  helpText: { fontStyle: 'italic', fontWeight: 'bold', marginRight: 9 },
  supportIcon: {
    fill: '#0094FF',
    width: 20,
    height: 20,
    verticalAlign: 'middle',
  },
  supportLink: {
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
    fontStyle: 'normal',
    color: '#0094FF',
    margin: '0 5px',
    textTransform: 'uppercase',
    '&:hover': {
      color: '#0094FF',
    },
  },
})

const SelectTariffDialogActions = React.memo(() => {
  const translateParse = useTranslateParse()
  const translate = useTranslate()
  const { allowCreate } = useSelector(permissionsSelectors.getProjectPermissionByKey('energy_usage_tariff'))
  const classes = useStyles()
  const currentOrProposed = useSelector((state) => state.selectTariff?.currentOrProposed)

  return (
    <DialogActions className={classes.wrapper}>
      <div style={{ margin: '20px auto' }}>
        {translateParse("Can't find tariff? <a>Let us know<a>", {
          a: (label) => (
            <a
              href={'https://support.opensolar.com/hc/requests/new'}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                logAmplitudeEvent('report_missing_tariff_clicked', {
                  currentOrProposed,
                })
              }}
            >
              {label}
            </a>
          ),
        })}
        {allowCreate && (
          <>
            {' '}
            {translate('or')}{' '}
            {<TariffDialogCreateButton currentOrProposed={currentOrProposed} context="search_dialog" />}
          </>
        )}
      </div>
    </DialogActions>
  )
})

const SelectTariffDialog = (props) => {
  const location = useLocation()
  const translate = useTranslate()
  const classes = useStyles()
  const isStudioMode = useSelector(studioSelectors.isInStudio)
  const handleClose = useCallback(() => {
    DialogHelper.beforeClose()
    props.setSelectTariffDialog(false, null, null, null, isStudioMode)
    props.push(location.pathname)
    props.changeListParams('utility_tariffs', {
      sort: null,
      order: null,
      page: 1,
      perPage: null,
      filter: {},
    })
  }, [])
  if (props.isOpen) {
    DialogHelper.afterOpen('DialogSelectTariff' + props.currentOrProposed)
  }
  return (
    props.isOpen && (
      <Dialog
        key={props.version}
        id="select-tariff-dialog"
        title={null}
        autoDetectWindowHeight={false}
        classes={classes}
        open={props.isOpen}
        onBackdropClick={handleClose}
        // actionsContainerStyle={{ backgroundColor: '#e2e2e2', minHeight: 70, paddingTop: 20 }}
      >
        <div id="tariff-selector-header" className={classes.header}>
          <StyledDialogTitle onClose={handleClose} style={{ padding: 0 }}>
            <span className={classes.title}>
              {props.currentOrProposed === 'current' ? (
                <span>{translate('Select Tariff - Current (Before Solar)')}</span>
              ) : (
                <span>{translate('Select Tariff - Proposed (After Solar)')}</span>
              )}
            </span>
          </StyledDialogTitle>
        </div>
        <DialogContent classes={{ root: classes.content }}>
          <SelectTariffList {...props} translate={translate} />
        </DialogContent>
        {!props.hideActions && <SelectTariffDialogActions />}
      </Dialog>
    )
  )
}

const SelectTariffList = (props) => {
  // const location = useLocation()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  // useEffect(() => {
  //   let filters = getDefaultFilterValues(props.countryId, props.projectId)
  //   filters.fieldset = 'full'
  //   props.push(location.pathname + '?filter=' + encodeURIComponent(JSON.stringify(filters)))
  // }, [])

  return (
    <TariffList
      {...props}
      filters={
        <TariffFilter
          countryId={props.countryId}
          projectId={props.projectId}
          layout={props.layout}
          isMobile={isMobile}
        />
      }
    />
  )
}

const mapStateToProps = (state) => {
  var page
  try {
    page = state.admin.resources.utility_tariffs.list.params.page
  } catch (e) {
    page = 1
  }

  var filter
  try {
    filter = state.admin.resources.utility_tariffs.list.params.filter
  } catch (e) {
    filter = {}
  }
  // if (Object.keys(filter).length === 0) {
  //   filter = { q: 'Citi' } //{ q: 'Jem' }
  // }

  var version
  try {
    version = state.selectTariff.version
  } catch (e) {
    version = 1
  }

  var isOpen
  try {
    isOpen = Boolean(state.selectTariff.isOpen)
  } catch (e) {
    isOpen = false
  }

  return {
    version: version,
    orgId: state.auth.org_id,
    projectId: state.selectTariff.projectId,
    countryId: state.selectTariff.countryId,
    currentOrProposed: state.selectTariff.currentOrProposed,
    isOpen: isOpen,
    page: page,
    filter: filter,
    query: {},
    location: state.router.location,
    isAdmin: getRoleFromState(state) ? getRoleFromState(state).is_admin : false,
    org: getOrgFromState(state),
  }
}

// export default connect(mapStateToProps, { crudGetList: crudGetListAction })(
//     CustomRouteLayout
// );
//

// const FormWrappedSelectTariffDialog = (props) => {
//   return <SelectTariffDialog {...props} />
// }
//
// export default connect(mapStateToProps, {
//   showNotification: showNotificationAction,
//   changeListParams: changeListParamsAction,
//   setSelectTariffDialog: setSelectTariffDialogAction,
//   selectTariff: selectTariffAction,
//   push: pushAction,
// })(FormWrappedSelectTariffDialog)

export default connect(mapStateToProps, {
  showNotification: showNotificationAction,
  changeListParams: changeListParamsAction,
  setSelectTariffDialog: setSelectTariffDialogAction,
  selectTariff: selectTariffAction,
  push: pushAction,
})(SelectTariffDialog)

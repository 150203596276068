import { Grid, Paper } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import type { OpenSolarThemeType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import _ from 'lodash'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { useNotify } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import type { InventoryLocationType } from '../../../location/type'
import useUpdateInventory from '../../hooks/useUpdateInventory'
import type { BasicInventory, EditableInventoryFields, InventoryType } from '../../type'
import InlineInventoryCreate from '../create/InlineInventoryCreate'
import EditableInventoryTableHeader from './EditableInventoryTableHeader'
import EditableInventoryTableRow from './EditableInventoryTableRow'
import InventoryTableActions from './InventoryTableActions'
import useInventoryTableEditNotes from './useInventoryTableEditNotes'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  heading: {
    marginBottom: 10,
  },
}))

type EditableInventoryTableProps = {
  loadedInventoryData: InventoryType[] | undefined
  location: InventoryLocationType
  afterSave?(): void
  afterCancel?(): void
  toolbar?: React.ReactNode
  editMin?: boolean
  editOnHand?: boolean
}

const emptyLoadedInventoryData = []

const EditableInventoryTable = ({
  loadedInventoryData = emptyLoadedInventoryData,
  afterCancel,
  afterSave,
  location,
  toolbar,
  editMin,
  editOnHand,
}: EditableInventoryTableProps) => {
  const notify = useNotify()
  const classes = useStyles()
  const { InventoryTableEditTopInputs, editNoteState } = useInventoryTableEditNotes()
  const { savingInventoryData, updateInventoryData } = useUpdateInventory()
  const loadedInventoryDataMap = useMemo(() => {
    return loadedInventoryData.reduce(
      (map, inventory) => map.set(inventory.code, inventory),
      new Map<string, BasicInventory>()
    )
  }, [loadedInventoryData])

  const [freshInventoryDataMap, setFreshInventoryDataMap] = useState<Map<string, BasicInventory>>(
    loadedInventoryDataMap
  )

  const onCancel = useCallback(() => {
    afterCancel?.()
    logAmplitudeEvent('generic_button_clicked', {
      source: 'cancel',
      context: 'edit_inventory_table',
    })
  }, [afterCancel])

  const onSave = async () => {
    const newInventoryData = Array.from(freshInventoryDataMap.values())
    const inventories = _.differenceWith(newInventoryData, loadedInventoryData, _.isEqual)
    const success = await updateInventoryData({
      locationId: location.id,
      inventories: inventories.map((inventory) => ({
        ...inventory,
        reason: editNoteState.reason,
        note: editNoteState.note,
      })),
    })
    if (success) {
      notify('Inventory Saved', 'success')
      afterSave?.()
    }

    logAmplitudeEvent('generic_button_clicked', {
      source: 'save',
      context: 'edit_inventory_table',
    })
  }

  const handleInlineCreate = (inventoryToAdd: BasicInventory) => {
    const existInventory = freshInventoryDataMap.get(inventoryToAdd.code)
    if (existInventory) {
      const newOnHandValue = existInventory.on_hand + inventoryToAdd.on_hand
      onInventoryUpdated({ code: inventoryToAdd.code, field: 'on_hand', value: newOnHandValue })
      const newReorderQuantity = existInventory.reorder_quantity + inventoryToAdd.reorder_quantity
      onInventoryUpdated({ code: inventoryToAdd.code, field: 'reorder_quantity', value: newReorderQuantity })
    } else {
      onInventoryAdded(inventoryToAdd)
    }
  }

  const onInventoryAdded = useCallback((inventoryToAdd: BasicInventory) => {
    setFreshInventoryDataMap((map) => {
      const newMap = new Map(map)
      newMap.set(inventoryToAdd.code, inventoryToAdd)
      return newMap
    })
  }, [])

  const onInventoryUpdated = useCallback(
    ({ code, field, value }: { code: string; field: EditableInventoryFields; value: number }) => {
      setFreshInventoryDataMap((map) => {
        const newMap = new Map(map)
        const inventory = newMap.get(code)
        if (!inventory) return map
        const newInventory = { ...inventory, [field]: value }
        newMap.set(inventory.code, newInventory)
        return newMap
      })
    },
    []
  )

  return (
    <>
      <Grid container spacing={2} alignItems="flex-start" className={classes.heading}>
        <Grid item xs={5}>
          {!!toolbar && toolbar}
        </Grid>
        <Grid item xs={7}>
          {InventoryTableEditTopInputs}
        </Grid>
      </Grid>
      <Paper elevation={1}>
        <Table size="small">
          <EditableInventoryTableHeader editMin={editMin} editOnHand={editOnHand} />
          {Array.from(freshInventoryDataMap.values()).map((inventory: BasicInventory) => {
            return (
              <EditableInventoryTableRow
                disabled={savingInventoryData}
                key={inventory.code}
                onInventoryUpdated={onInventoryUpdated}
                inventory={inventory}
                oldInventory={loadedInventoryDataMap.get(inventory.code)}
                editMin={editMin}
                editOnHand={editOnHand}
              />
            )
          })}
          {!editMin && !editOnHand && (
            <InlineInventoryCreate disabled={savingInventoryData} onSave={handleInlineCreate} />
          )}
          <InventoryTableActions onCancel={onCancel} onSave={onSave} saving={savingInventoryData} />
        </Table>
      </Paper>
    </>
  )
}

export default memo(EditableInventoryTable)

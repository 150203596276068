import { FormControlLabel, makeStyles, Popover, Theme } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles'
import { ArrowDownward, ArrowUpward, FormatSizeOutlined, ZoomIn, ZoomOut } from '@material-ui/icons'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import LoadingDots from 'layout/widgets/LoadingDots'
import { IconButton, Switch } from "opensolar-ui"
import React, { useCallback, useEffect, useRef, useState } from 'react'
import TemplatePlaceholderTag from './TemplatePlaceholderTag'
import { FieldType, PlaceHolderSaveConfig, PlaceholderType } from './TemplateWizard'

const ZOOM_INCREMENT = 0.25

type PropTypes = {
  url: string
  saveDropResult: (x: number, y: number) => void
  placeholders: { [key: string]: PlaceholderType }
  removePlaceholder: (key: string) => void
  savePlaceholderChange: (key: string, path: string, config: PlaceHolderSaveConfig) => void
  pageNum: number
  setPageNum: (num: number) => void
  fieldBeingDragged: FieldType | undefined
  onFocus: (placeholders: PlaceholderType[]) => void
  focusedPlaceholders: PlaceholderType[]
  updateAllFontSizes: (val: number) => void
  initialGlobalFontSize: number
}

const useStyles = makeStyles<
  Theme,
  {
    multiSelectX0: number | undefined
    multiSelectX1: number | undefined
    multiSelectXOffset: number | undefined
    multiSelectY0: number | undefined
    multiSelectY1: number | undefined
    multiSelectYOffset: number | undefined
  }
>((theme) => ({
  multiSelectX: ({
    multiSelectX0,
    multiSelectX1,
    multiSelectXOffset,
    multiSelectY0,
    multiSelectY1,
    multiSelectYOffset,
  }) => {
    const base = {
      border: '1px solid black',
      position: 'absolute',
      zIndex: 100,
      touchAction: 'none',
    } as CreateCSSProperties
    if (
      multiSelectX0 !== undefined &&
      multiSelectX1 !== undefined &&
      multiSelectXOffset !== undefined &&
      multiSelectY0 !== undefined &&
      multiSelectY1 !== undefined &&
      multiSelectYOffset !== undefined
    ) {
      base.left = `${Math.min(multiSelectX0, multiSelectX1) - multiSelectXOffset}px`
      base.top = `${Math.min(multiSelectY0, multiSelectY1) - multiSelectYOffset}px`
      base.width = `${Math.abs(multiSelectX0 - multiSelectX1)}px`
      base.height = `${Math.abs(multiSelectY0 - multiSelectY1)}px`
    }
    return base
  },
  fontButtonContainer: { width: '300px', display: 'flex', justifyContent: 'flex-end' },
  popoverContent: {
    padding: '15px',
    borderRadius: '3px',
  },
  fontSizeSelectionWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px',
  },
  fontSizeButton: {
    margin: '5px 10px',
    backgroundColor: '#ececec',
    borderRadius: '20px',
    cursor: 'pointer',
    padding: '0x 3px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 1px 3px rgba(0,0,0,0.3)',
  },
  prevPageWrapper: {
    flex: 2,
    display: 'flex',
    justifyContent: 'flex-start',
    zIndex: 101,
  },
  centerWrapper: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  centerContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  zoomBtnWrapper: {
    margin: '0px 15px',
    cursor: 'pointer',
    zIndex: 101,
  },
  loadingIconWrapper: {
    position: 'absolute',
    left: '0px',
    right: '0px',
    top: '0px',
    bottom: '0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightBtnsWrapper: {
    flex: 2,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    zIndex: 101,
  },
}))

const TemplateWizardPDFDisplay: React.FunctionComponent<PropTypes> = (props) => {
  const [pageCount, setPageCount] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [pdfObj, setPdfObj] = useState<any>(undefined)
  const [aCellIsBeingDragged, setACellIsBeingDragged] = useState<boolean>(false)
  const [multiSelectIsActive, setMultiSelectIsActive] = useState<boolean>(false)
  const [multiSelectX0, setMultiSelectX0] = useState<undefined | number>(undefined)
  const [multiSelectY0, setMultiSelectY0] = useState<undefined | number>(undefined)
  const [multiSelectX1, setMultiSelectX1] = useState<undefined | number>(undefined)
  const [multiSelectY1, setMultiSelectY1] = useState<undefined | number>(undefined)
  const [multiSelectXOffset, setMultiSelectXOffset] = useState<undefined | number>(undefined)
  const [multiSelectYOffset, setMultiSelectYOffset] = useState<undefined | number>(undefined)
  const [zoom, setZoom] = useState<number>(1)
  const [declutter, setDeclutter] = useState<boolean>(false)
  const [formatLastClosed, setFormatLastClosed] = useState<Date | undefined>(undefined)
  const [showFontPopover, setShowFontPopover] = useState<boolean>(false)
  const [tempGlobalFontSize, setTempGlobalFontSize] = useState<number>(props.initialGlobalFontSize)

  const pdfjsLib = window['pdfjs-dist/build/pdf']

  const fontBtnRef = useRef(null)
  const classes = useStyles({
    multiSelectX0,
    multiSelectX1,
    multiSelectXOffset,
    multiSelectY0,
    multiSelectY1,
    multiSelectYOffset,
  })

  useEffect(() => {
    var loadingTask = pdfjsLib.getDocument(props.url)
    loadingTask.promise.then(
      (pdf: any) => {
        setPdfObj(pdf)
        setPageCount(pdf.numPages)
        props.setPageNum(1)
      },
      function (reason) {
        // PDF loading error
        console.error(reason)
      }
    )
  }, [props.url])

  useEffect(() => {
    if (pdfObj && props.pageNum && zoom) loadPage()
  }, [pdfObj, props.pageNum, zoom])

  const loadPage = () => {
    // Fetch the first page
    setLoading(true)
    var pageNumber = props.pageNum
    pdfObj.getPage(pageNumber).then((page: any) => {
      var scale = zoom
      var viewport = page.getViewport({ scale: scale })

      // Prepare canvas using PDF page dimensions
      var canvas = document.getElementById('pdf-target') as HTMLCanvasElement
      var context = canvas?.getContext('2d')
      canvas.height = viewport.height
      canvas.width = viewport.width

      // Render PDF page into canvas context
      var renderContext = {
        canvasContext: context,
        viewport: viewport,
      }
      var renderTask = page.render(renderContext)
      renderTask.promise.then(() => {
        setLoading(false)
      })
    })
  }

  const onDrop = (e) => {
    e.preventDefault()
    const pdfRect = document.getElementById('pdf-target')?.getBoundingClientRect() as DOMRect
    let relativeX = e.clientX - pdfRect.x
    let relativeY = e.clientY - pdfRect.y
    props.saveDropResult(relativeX, relativeY)
  }
  const overrideDefault = (e) => {
    e.preventDefault()
    return false
  }

  const zoomIn = () => {
    setZoom(Math.min(zoom + ZOOM_INCREMENT, 2))
  }

  const zoomOut = () => {
    setZoom(Math.max(0.5, zoom - ZOOM_INCREMENT))
  }

  const onMultiSelectStart = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    closeFormatMenus()
    e.preventDefault()
    const pdfRect = document.getElementById('pdf-target')?.getBoundingClientRect() as DOMRect
    setMultiSelectXOffset(pdfRect.x)
    setMultiSelectYOffset(pdfRect.y)
    setMultiSelectX0(e.clientX)
    setMultiSelectX1(e.clientX)
    setMultiSelectY0(e.clientY)
    setMultiSelectY1(e.clientY)
    setMultiSelectIsActive(true)
  }

  const onDrag = useCallback(
    (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
      if (multiSelectIsActive) {
        setMultiSelectX1(e.clientX)
        setMultiSelectY1(e.clientY)
      }
    },
    [multiSelectIsActive]
  )

  const onDeclutterChange = () => {
    setDeclutter(!declutter)
  }

  const closeFormatMenus = () => {
    setFormatLastClosed(new Date())
  }

  const onDragEnd = (
    e: React.MouseEvent<HTMLCanvasElement, MouseEvent> | React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (
      multiSelectX0 !== undefined &&
      multiSelectX1 !== undefined &&
      multiSelectXOffset !== undefined &&
      multiSelectY0 !== undefined &&
      multiSelectY1 !== undefined &&
      multiSelectYOffset
    ) {
      const left = Math.min(multiSelectX0, multiSelectX1) - multiSelectXOffset
      const top = Math.min(multiSelectY0, multiSelectY1) - multiSelectYOffset
      const width = Math.abs(multiSelectX0 - multiSelectX1)
      const height = Math.abs(multiSelectY0 - multiSelectY1)

      setMultiSelectIsActive(false)
      setMultiSelectX0(undefined)
      setMultiSelectX1(undefined)
      setMultiSelectY0(undefined)
      setMultiSelectY1(undefined)

      let selectedPlaceholders = [] as PlaceholderType[]
      Object.values(props.placeholders)?.forEach((placeholder) => {
        if (placeholder.page_num === props.pageNum) {
          let isInBox =
            placeholder.x >= left &&
            placeholder.y >= top &&
            placeholder.x + placeholder.width < left + width &&
            placeholder.y < top + height
          if (isInBox) selectedPlaceholders.push(placeholder)
        }
      })

      if (selectedPlaceholders?.length > 0) {
        props.onFocus(selectedPlaceholders)
      }
    }
  }

  const saveGlobalFontSize = () => {
    props.updateAllFontSizes(tempGlobalFontSize)
  }

  return (
    <div
      style={{
        backgroundColor: '#ececec',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: '10px',
        }}
      >
        <div className={classes.prevPageWrapper}>
          <ProUXButton
            label="Prev Page"
            onClick={() => props.setPageNum(Math.max(1, props.pageNum - 1))}
            type="secondary"
            disabled={props.pageNum <= 1}
          />
        </div>
        <div className={classes.centerWrapper}>
          <div className={classes.centerContentWrapper}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div className={classes.zoomBtnWrapper} onClick={zoomOut}>
                <ZoomOut />
              </div>
              <span>
                Page {props.pageNum} of {pageCount}
              </span>
              <div className={classes.zoomBtnWrapper} onClick={zoomIn}>
                <ZoomIn />
              </div>
            </div>
            <div style={{ zIndex: 101 }}>
              <FormControlLabel control={<Switch onChange={onDeclutterChange} checked={declutter} />} label="Preview" />
            </div>
          </div>
        </div>
        <div className={classes.rightBtnsWrapper}>
          {tempGlobalFontSize && (
            <div ref={fontBtnRef} className={classes.fontButtonContainer}>
              <IconButton onClick={() => setShowFontPopover(true)}>
                <FormatSizeOutlined />
              </IconButton>
              <Popover
                id={'font-size-popover'}
                open={showFontPopover}
                anchorEl={fontBtnRef.current}
                onClose={() => setShowFontPopover(false)}
                anchorOrigin={{
                  horizontal: 'left',
                  vertical: 'center',
                }}
                disableRestoreFocus
              >
                <div className={classes.popoverContent}>
                  <h4>Adjust font size for all placeholders?</h4>
                  <div className={classes.fontSizeSelectionWrapper}>
                    <div
                      className={classes.fontSizeButton}
                      onClick={() => setTempGlobalFontSize(Math.max(tempGlobalFontSize - 1, 3))}
                    >
                      <ArrowDownward fontSize="small" />
                    </div>
                    <div style={{ margin: '5px 10px' }}>{`${tempGlobalFontSize}`} pixels</div>
                    <div
                      className={classes.fontSizeButton}
                      onClick={() => setTempGlobalFontSize(tempGlobalFontSize + 1)}
                    >
                      <ArrowUpward fontSize="small" />
                    </div>
                  </div>
                  <ProUXButton type="secondary" label="Apply to All Placeholders" onClick={saveGlobalFontSize} />
                </div>
              </Popover>
            </div>
          )}

          <ProUXButton
            label="Next Page"
            onClick={() => props.setPageNum(props.pageNum + 1)}
            type="secondary"
            disabled={props.pageNum >= pageCount}
          />
        </div>
      </div>
      <div style={{ position: 'relative', paddingBottom: '500px' }}>
        {multiSelectX0 && <div className={classes.multiSelectX} onMouseUp={onDragEnd}></div>}
        <canvas
          id="pdf-target"
          onDragEnter={overrideDefault}
          onDragOver={overrideDefault}
          onDrop={onDrop}
          onMouseDownCapture={onMultiSelectStart}
          onMouseMove={onDrag}
          onMouseUp={onDragEnd}
          style={{ cursor: 'crosshair' }}
        ></canvas>
        {Object.values(props.placeholders)?.map((placeholder) => {
          if (placeholder.page_num !== props.pageNum) return null
          else
            return (
              <TemplatePlaceholderTag
                key={placeholder.field_key}
                {...placeholder}
                removePlaceholder={props.removePlaceholder}
                savePlaceholderChange={props.savePlaceholderChange}
                aCellIsBeingDragged={aCellIsBeingDragged}
                onDrag={() => setACellIsBeingDragged(true)}
                onDragEnd={() => setACellIsBeingDragged(false)}
                fieldBeingDragged={props.fieldBeingDragged}
                onFocus={props.onFocus}
                focusedPlaceholders={props.focusedPlaceholders}
                zoom={zoom}
                declutter={declutter}
                formatLastClosed={formatLastClosed}
              />
            )
        })}
      </div>
      {loading && (
        <div className={classes.loadingIconWrapper}>
          <LoadingDots />
        </div>
      )}
    </div>
  )
}
export default TemplateWizardPDFDisplay

import React from 'react'
import type { ComponentTypes } from 'types/selectComponent'
import type { FilterComponentNode, FilterComponentRendererType, FilterGenericPropsType } from '../type'
import BatteryCompatibilityFilter, { FilterOptionsType } from './BatteryCompatibilityFilter'

export const BATTERY_COMPATIBILITY_FILTER_NODE_KEY = 'battery_compatibility'
export const BATTERY_COMPATIBILITY_FILTER_KEY = 'codes'

const maybeCreateBatteryCompatibilityFilterNode = ({
  RendererComponent,
  componentType,
}: {
  RendererComponent: FilterComponentRendererType<FilterOptionsType>
  componentType?: ComponentTypes
}): FilterComponentNode | undefined => {
  if (componentType !== 'battery') {
    return undefined
  }

  const FilterComponent = (props: FilterGenericPropsType) => {
    return (
      <BatteryCompatibilityFilter
        id={BATTERY_COMPATIBILITY_FILTER_NODE_KEY}
        filterKey={BATTERY_COMPATIBILITY_FILTER_KEY}
        RendererComponent={RendererComponent}
        {...props}
      />
    )
  }
  return {
    key: BATTERY_COMPATIBILITY_FILTER_NODE_KEY,
    component: FilterComponent,
  }
}

export default maybeCreateBatteryCompatibilityFilterNode

import { all, call, put, takeEvery } from 'redux-saga/effects'
import restClient from 'restClient'
import { ReduxActionType } from 'types/global'

export const GET_CURVES = 'GET_CURVES'
export const GET_CURVES_SUCCESS = 'GET_CURVES_SUCCESS'
export const GET_CURVES_FAIL = 'GET_CURVES_FAIL'

export type CurvesStoreType = {
  curves_data: CurveConfig[] | null
  loading: boolean
  ready: boolean
}

const defaultState: CurvesStoreType = {
  curves_data: null,
  loading: false,
  ready: false,
}

export default (state: CurvesStoreType = defaultState, action: ReduxActionType) => {
  switch (action.type) {
    case GET_CURVES:
      return {
        ...state,
        loading: true,
        orgId: action.payload.orgId,
      }
    case GET_CURVES_SUCCESS:
      return {
        ...state,
        loading: false,
        curves_data: action?.payload?.curves_data,
        ready: true,
      }
    case GET_CURVES_FAIL:
      return {
        ...state,
        loading: false,
        ready: false,
      }
  }
  return state
}

export function* curvesSaga(action: ReduxActionType) {
  try {
    const curves_data = yield call(doGetCurves, action.payload.orgId)
    yield put({ type: GET_CURVES_SUCCESS, payload: { curves_data } })
  } catch (e) {
    console.log('FAILED')
    yield put({ type: GET_CURVES_FAIL, key: action.payload.key })
  }
}

export const doGetCurves = (orgId) => {
  const API_URL = window.API_ROOT + '/api'
  return new Promise((resolve, reject) => {
    const restClientInstance = restClient(API_URL)
    restClientInstance('CUSTOM_GET', 'custom', {
      url: 'orgs/' + orgId + '/daily_consumption_curves/',
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((err) => {
        console.error('Error getting country curves config data', err)
        reject(false)
      })
  })
}

export const loadCurves = (orgId) => {
  return {
    type: GET_CURVES,
    payload: {
      orgId: orgId,
    },
  }
}

export interface CurveConfig {
  title: string
  curve_identifier: string
  is_default_domestic: string[]
  is_default_commercial: string[]
  country_whitelist: string[]
  country_blacklist: string[]
  mcs_customer_type: 'in_half_day' | 'out_all_day' | 'home_all_day'
  curve: number[]
}

export const curvesSelectors = {
  getIsLoading: (state: any): boolean => !!state.consumption_curves?.loading,
  getIsReady: (state: any): boolean => !!state.consumption_curves?.ready,
  getCurvesData: (state: any): CurveConfig[] => state.consumption_curves.curves_data,
}

export function* consumptionCurvesSagas() {
  yield all([takeEvery(GET_CURVES, curvesSaga)])
}

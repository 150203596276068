// @ts-nocheck
import { useMediaQuery } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { featureConfigSelectors, getPublicFeatureConfig } from 'ducks/featureConfig'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { AutocompleteInput, ReferenceInput, SelectInput, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'
import { systemNameOrDefaultWithSize } from 'util/misc'
import FieldPathDropdown from './FieldPathDropdown'
import PlaceholderSearch from './PlaceHolderSearch'
import PlaceholderCell from './PlaceholderCell'
import { placeholderPathsToHide, staticPlaceholders } from './constants'
import { makeNumericIfPossible, parseValFromMultiLevelPath } from './util'

export type PlaceholderWhiteListType = {
  popular: {
    [key: string]: string
  }
  path_to_django: {
    [key: string]: string
  }
  path_to_display: {
    [key: string]: string
  }
}

export type PlaceholderBlackListType = {
  full_paths: string[]
}

export type FieldPathType = {
  display: string
  fieldName: string
  path: string
  parent: FieldPathType | undefined
}
type PropTypes = {
  startDrag: (fieldName: string, value: string) => void
  endDrag: () => void
  selectedProjectId: string | undefined
  initialProjectURL: string | undefined
  setSelectedProjectId: (val: string | undefined) => void
  selectedSystemId: string | undefined
  initialSystemURL: string | undefined
  setSelectedSystemId: (val: string | undefined) => void
  selectedPaymentOptionId: string | undefined
  initialPaymentOptionURL: string | undefined
  setSelectedPaymentOptionId: (val: string | undefined) => void
  proposalData: any
  activeData: any
  setProposalData: (newData: any) => void
  setActiveData: (newData: any) => void
  documentType: number
}

const ProjectPlaceholderWizard: React.FunctionComponent<PropTypes> = (props) => {
  const form = useForm()
  const [fieldPath, setFieldPath] = useState<FieldPathType | undefined>(undefined)
  const [systemsLoading, setSystemsLoading] = useState<boolean>(false)
  const [pmtsLoading, setPmtsLoading] = useState<boolean>(false)

  const isMD = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  const isSM = useMediaQuery((theme: any) => theme.breakpoints.down(510))
  const translate = useTranslate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPublicFeatureConfig('placeholder_whitelist'))
    dispatch(getPublicFeatureConfig('placeholder_blacklist'))
  }, [])

  const whitelistVals: PlaceholderWhiteListType | undefined = useSelector((state) =>
    featureConfigSelectors.getFeatureConfigData(state, 'placeholder_whitelist')
  )

  const blacklistPaths: PlaceholderBlackListType | undefined = useSelector((state) =>
    featureConfigSelectors.getFeatureConfigData(state, 'placeholder_blacklist')
  )

  const whitelistLoading: boolean | undefined = useSelector((state) =>
    featureConfigSelectors.getFeatureConfigLoading(state, 'placeholder_whitelist')
  )
  const blacklistLoading: boolean | undefined = useSelector((state) =>
    featureConfigSelectors.getFeatureConfigLoading(state, 'placeholder_blacklist')
  )
  const orgId: number = useSelector(authSelectors.getCurrentOrg)?.id

  useEffect(() => {
    if (
      props.initialProjectURL &&
      props.initialSystemURL &&
      props.initialPaymentOptionURL &&
      whitelistLoading === false &&
      blacklistLoading === false
    ) {
      getData()
    }
  }, [
    props.initialProjectURL,
    props.initialSystemURL,
    props.initialPaymentOptionURL,
    whitelistLoading,
    blacklistLoading,
  ])

  const handleProjectChange = (projectUrl: string) => {
    let projectId = projectUrl ? projectUrl.substring(projectUrl.indexOf('projects/') + 9)?.replace('/', '') : undefined
    props.setSelectedProjectId(projectId)
    props.setSelectedSystemId(undefined)
    props.setSelectedPaymentOptionId(undefined)
    form.change('payment_option_sold', undefined)
    form.change('system_sold', undefined)
    let config
    try {
      config = JSON.parse(form.getState().values?.content)
    } catch (ex) {
      config = {}
    }
    config.sample_project_url = projectUrl
    form.change('content', JSON.stringify(config))
    setSystemsLoading(true)
    setTimeout(() => {
      setSystemsLoading(false)
    }, 2000)
    // to-do, this is setting id but the inputs need url
  }

  const handleSystemChange = (e: any) => {
    let systemId = e?.target?.value?.substring(e.target?.value.indexOf('systems/') + 8)?.replace('/', '')
    props.setSelectedSystemId(systemId)
    form.change('payment_option_sold', undefined)
    props.setSelectedPaymentOptionId(undefined)
    let config
    try {
      config = JSON.parse(form.getState().values?.content)
    } catch (ex) {
      config = {}
    }
    config.sample_system_url = e?.target?.value
    form.change('content', JSON.stringify(config))
    setPmtsLoading(true)
    setTimeout(() => {
      setPmtsLoading(false)
    }, 2000)
  }

  const handlePaymentOptionChange = (e: any) => {
    let paymentOptionId = e.target?.value?.substring(e.target?.value.indexOf('payment_options/') + 16)?.replace('/', '')
    props.setSelectedPaymentOptionId(paymentOptionId)
    let config
    try {
      config = JSON.parse(form.getState().values?.content)
    } catch (ex) {
      config = {}
    }
    config.sample_payment_option_url = e.target?.value
    form.change('content', JSON.stringify(config))
  }

  const getData = () => {
    const API_URL = window.API_ROOT + '/api'
    const restClientInstance = restClient(API_URL)
    restClientInstance('CUSTOM_GET', 'custom', {
      url:
        `orgs/${orgId}/projects/${props.selectedProjectId}/systems/${props.selectedSystemId}/${props.selectedPaymentOptionId}/sample_placeholder_data/` +
        (props.documentType ? `?document_type=${props.documentType}` : ''),
    })
      .then((res: any) => {
        let staticWithWhiteList = { ...staticPlaceholders }
        if (props.documentType === 8) {
          staticWithWhiteList['original_system'] = {}
        }
        if (whitelistVals) {
          if (whitelistVals.popular) {
            staticWithWhiteList.popular = {
              ...staticWithWhiteList.popular,
              ...whitelistVals.popular,
            }
          }
          if (whitelistVals.images) {
            staticWithWhiteList.images = {
              ...whitelistVals.images,
            }
          }
        }
        let data = res.data.data
        try {
          let unformattedSampleData = JSON.parse(JSON.stringify(data))
          if (unformattedSampleData) {
            let withStaticPlaceholders = { ...staticWithWhiteList, ...unformattedSampleData }
            props.setProposalData(withStaticPlaceholders)
            props.setActiveData(withStaticPlaceholders)
          }
        } catch (ex) {
          console.log('ex', ex)
        }
      })
      .catch((err: any) => {
        console.log('err', err)
      })
  }

  const parseVal = (raw: string) => {
    try {
      const parsed = JSON.parse(raw)
      if (parsed === undefined || parsed === null) return ''
      else return parsed
    } catch (ex) {
      return raw
    }
  }

  const getShouldShowArrow = (childData: any) => {
    return ['object', 'array'].includes(typeof childData)
  }

  const getDisplayFromFieldName = (fieldName: string) => fieldName

  const handleCellClick = (fieldName: string) => {
    let clickedPath = fieldPath?.path ? `${fieldPath.path}.` : ''
    clickedPath += fieldName

    let newFieldPathChild: FieldPathType = {
      display: getDisplayFromFieldName(fieldName),
      fieldName: fieldName,
      path: clickedPath,
      parent: fieldPath && fieldPath.path ? fieldPath : undefined,
    }
    setFieldPath(newFieldPathChild)

    let childVal
    // we inject some child objets into the parent UI so people don't need to dig for things like payment options, but this requires different parsing
    if (fieldName === 'popular' || fieldName === 'custom_content') {
      // get all of the children of the popular object and use them as activeData
      let staticWithWhiteList = { ...staticPlaceholders }
      if (whitelistVals) {
        if (whitelistVals.popular) {
          staticWithWhiteList.popular = {
            ...staticWithWhiteList.popular,
            ...whitelistVals.popular,
          }
        }
      }
      let staticChildren = { ...staticWithWhiteList[fieldName] }
      Object.keys(staticChildren).forEach((path: string) => {
        staticChildren[path] = parseValFromMultiLevelPath(path, props.activeData)
      })
      props.setActiveData(staticChildren)
    } else {
      if (fieldName?.includes('.')) {
        childVal = parseValFromMultiLevelPath(fieldName, props.activeData)
      } else {
        childVal = parseVal(props.activeData[fieldName])
      }
      if (getShouldShowArrow(childVal)) {
        props.setActiveData(childVal)
      }
    }
  }

  const revertPath = (newPath: string) => {
    if (newPath === 'root') {
      props.setActiveData(props.proposalData)
      setFieldPath(undefined)
    } else {
      // traverse field path to find the one the user just selected
      const resetPathIfMatch = (pathNode: FieldPathType) => {
        if (newPath === pathNode.path) {
          setFieldPath(pathNode)
        } else if (pathNode.parent) {
          resetPathIfMatch(pathNode.parent)
        } else setFieldPath(undefined)
      }
      if (fieldPath) resetPathIfMatch(fieldPath)
      // then reset data
      if (newPath?.includes('.')) props.setActiveData(parseValFromMultiLevelPath(newPath, props.proposalData))
      else props.setActiveData(JSON.parse(_.get(props.proposalData, newPath)))
    }
  }

  const closeFields = () => {
    props.setActiveData(undefined)
    setFieldPath(undefined)
    props.setProposalData(undefined)
  }

  return (
    <div>
      <div
        style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flexStart', width: '100%' }}
      >
        <div style={{ display: isSM ? 'block' : 'flex', width: isMD ? '100%' : '50%' }}>
          <div style={{ flex: 1, padding: '10px' }}>
            <ReferenceInput
              //label="Project"
              source="project"
              reference="projects"
              optionValueField="url"
              translateChoice={false}
              onChange={handleProjectChange}
              initialValue={props.initialProjectURL}
              allowEmpty
            >
              <AutocompleteInput
                options={{
                  placeholder: 'Enter a project',
                  style: { width: '100%' },
                }}
                optionText="address"
                optionValue="url"
                resettable
              />
            </ReferenceInput>
          </div>
          <div style={{ flex: 1, padding: '10px' }}>
            <ReferenceInput
              //@TODO: Refactor into standard field translation and remove label attribute
              label={'System Design'}
              resource={'projects'}
              reference="systems"
              source="system_sold"
              filter={{
                project: props.selectedProjectId,
              }}
              style={{ width: '100%' }}
              sort={{ field: 'order', order: 'ASC' }}
              allowEmpty
              disabled={!props.selectedProjectId || systemsLoading}
              onChange={handleSystemChange}
              initialValue={props.initialSystemURL}
            >
              <SelectInput
                resettable={true}
                optionValue="url"
                optionText={(choice: any) =>
                  systemNameOrDefaultWithSize(choice, {
                    choiceSuffix:
                      props.documentType === 8 && choice.system_sold ? ' - ' + translate('Original System') : undefined,
                  })
                }
                disabled={systemsLoading}
              />
            </ReferenceInput>
          </div>
        </div>
        <div style={{ display: isSM ? 'block' : 'flex', width: isMD ? '100%' : '50%' }}>
          <div style={{ flex: 1, padding: '10px' }}>
            <ReferenceInput
              //@TODO: Refactor into standard field translation and remove label attribute
              label={'Payment Option'}
              resource={'projects'}
              reference="payment_options"
              source="payment_option_sold"
              filter={{
                project: props.selectedProjectId,
                system: props.selectedSystemId,
              }}
              style={{ width: '100%' }}
              sort={{ field: 'order', order: 'ASC' }}
              allowEmpty
              disabled={!props.selectedSystemId || pmtsLoading}
              onChange={handlePaymentOptionChange}
              initialValue={props.initialPaymentOptionURL}
            >
              <SelectInput resettable={true} optionValue="url" optionText={(choice: any) => choice.title} />
            </ReferenceInput>
          </div>
          <div style={{ flex: 1, padding: '10px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ProUXButton
              label="Load Sample Data"
              style={{ fontSize: isSM ? '12px' : '16px' }}
              onClick={getData}
              type="secondary"
              disabled={!props.selectedPaymentOptionId || !props.selectedSystemId || !props.selectedProjectId}
            />
          </div>
        </div>
      </div>
      {props.activeData && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            border: '1px solid #ececec',
            borderRadius: '4px',
            position: 'fixed',
            left: '0px',
            width: '240px',
            top: '50px',
            bottom: '0px',
            backgroundColor: '#fff',
            zIndex: 900,
          }}
        >
          <div style={{ flex: 1, width: '100%' }}>
            <h4>{translate('Field Placeholders')}</h4>
            <p>{translate('Select the field you want to use and drag it onto the PDF')}</p>
            <PlaceholderSearch
              proposalData={props.proposalData}
              activeData={props.activeData}
              startDrag={props.startDrag}
              endDrag={props.endDrag}
              fieldPath={fieldPath}
            />
            {fieldPath && <FieldPathDropdown fieldPath={fieldPath} revertPath={revertPath} />}
          </div>
          <div
            style={{
              flex: 8,
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              overflowY: 'scroll',
              marginBottom: '20px',
            }}
          >
            <>
              {fieldPath && (
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginBottom: '15px' }}>
                  <a
                    onClick={() => revertPath('root')}
                    style={{ cursor: 'pointer', color: '#0094FF', textDecoration: 'underline' }}
                  >
                    {translate('Back to Home')}
                  </a>
                </div>
              )}
              {Object.keys(props.activeData).map((fieldName: string) => {
                let fullPath = fieldPath?.path ? `${fieldPath?.path}.${fieldName}` : fieldName
                if (blacklistPaths?.full_paths?.includes(fullPath)) return null
                if (placeholderPathsToHide?.includes(fieldName)) return null
                return (
                  <PlaceholderCell
                    key={fieldName}
                    fieldName={fieldName}
                    shouldShowArrow={getShouldShowArrow(parseVal(props.activeData[fieldName]))}
                    value={makeNumericIfPossible(props.activeData[fieldName])}
                    onClick={() => handleCellClick(fieldName)}
                    startDrag={props.startDrag}
                    endDrag={props.endDrag}
                    currentPath={fieldPath?.path}
                  />
                )
              })}
            </>
          </div>
          <div style={{ flex: 1, width: '80%', padding: '10px 10%', zIndex: 901 }}>
            <ProUXButton label="Close" type="secondary" onClick={closeFields} fullWidth />
          </div>
        </div>
      )}
    </div>
  )
}
export default ProjectPlaceholderWizard

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flows = void 0;
var action_1 = require("../core/action");
/**
 * 'Flows' are sequences of actions designed to cover
 * common SDK use cases that would otherwise require
 * multiple actions to be dispatched in sequence.
 */
exports.flows = {
    // Actions
    createOrUpdateProject: new action_1.Action(),
    generateDocument: new action_1.Action(),
};

import { Divider, MenuItem, Paper } from '@material-ui/core'
import { ComponentVersions_2_1 } from 'constants/uxVersions'
import { setStudioSavePrompt as setStudioSavePromptAction } from 'ducks/studioPrompt'
import { PrimaryButton } from 'elements/button/GenericButton'
import CustomSelectField from 'elements/field/CustomSelectField'
import LoadingDots from 'layout/widgets/LoadingDots'
import { Button, Chip, ComponentVersionsInherit } from 'opensolar-ui'
import { getSupplierConfig } from 'pages/ordering/configs'
import OrderLineItem from 'pages/ordering/OrderLineItem'
import { ManageIconButton } from 'projectSections/sections/design/systems/PanelSystem'
import React, { useCallback } from 'react'
import { useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { autoStringSelectors, requestAutoStringing } from 'reducer/designer/autoString'
import { formatCurrencyWithSymbol } from 'util/misc'

type PropTypes = {
  systemUuid: string
  inverterRange?: string
  currencySymbol: string
  disabled: boolean
}

const isStringInverter = (code) => {
  return !Boolean(
    window.AccountHelper.getComponentInverterSpecsAvailable().find(
      (inverterAvailable) => inverterAvailable.code === code
    )?.microinverter
  )
}

const inverterCost = (code) => {
  var component = window.AccountHelper.getComponentInverterSpecsAvailable().find(
    (inverterAvailable) => inverterAvailable.code === code
  )
  const supplierConfigFilterKey = getSupplierConfig(
    window.reduxStore.getState().orderComponents.selectedHardwareSupplier
  )?.filterKey

  if (supplierConfigFilterKey) {
    var distributorPrice =
      component?.ordering &&
      OrderLineItem.getDefaultSelectedVariant(component?.ordering, supplierConfigFilterKey)?.price

    if (distributorPrice) {
      // Get Segen cost, if available
      return distributorPrice
    }
  }

  if (component?.cost) {
    // Otherwise get configured COGS cost
    return component.cost
  } else {
    return 0
  }
}

const calculateCostCombined = (autoStringDesign) => {
  var total = 0
  autoStringDesign.system_design.inverters.forEach((i) => {
    var price = inverterCost(i.code)
    if (price) {
      total += price
    }
  })
  return total
}

const AutoStringChoices = ({ systemUuid, currencySymbol }) => {
  const recommendations = useSelector(autoStringSelectors.getRecommedationsForSystem(systemUuid))

  if (!recommendations?.system_designs?.length) {
    return <div>no results yet</div>
  }

  return (
    <div>
      <Divider style={{ height: 2, marginTop: 20 }} />
      {recommendations.system_designs.map((autoStringDesign, autoStringDesignIndex) => (
        <div style={{ fontSize: 12 }}>
          <h4 className="studio" style={{ marginTop: 20 }}>
            Option {autoStringDesignIndex + 1}
          </h4>
          {isStringInverter(autoStringDesign.system_design.inverters[0]?.code) && (
            <div>DC/AC: {autoStringDesign.dc_ac_ratio.toFixed(2)}</div>
          )}
          {Boolean(calculateCostCombined(autoStringDesign)) && (
            <div>
              Cost:{' '}
              {formatCurrencyWithSymbol(calculateCostCombined(autoStringDesign), currencySymbol, window.locale, 2)}
            </div>
          )}
          {autoStringDesign.system_design.inverters.map((inverter) => (
            <div style={{ backgroundColor: '#f6f6f6', paddingLeft: 2 }}>
              <div style={{ display: 'inline-block', marginRight: 2 }}>{inverter.code}</div>
              <div style={{ display: 'inline-block', marginRight: 2, marginTop: 4, marginBottom: 4 }}>
                {inverter.mppts.map((mppt, mpptIndex) => (
                  <Chip
                    size="small"
                    id={'mppt' + mpptIndex}
                    key={'mppt' + mpptIndex}
                    style={{
                      height: 22,
                      margin: '0 4px 0 0',
                      border: '1px solid transparent',
                    }}
                    // style={{ margin: 4, backgroundColor: '#D8D8D8', cursor: 'pointer' }}
                    label={mppt.strings.map((string) => string.modules.length).join(', ')}
                  />
                ))}
              </div>
            </div>
          ))}
          <PrimaryButton
            onClick={() => {
              window.SceneHelper.buildSystemForModuleClustersAndSystem(
                window.editor,
                window.editor.selectedSystem.uuid,
                autoStringDesign.system_design
              )
            }}
            label="Apply"
          />
          <Divider style={{ height: 2, marginTop: 20 }} />
        </div>
      ))}
    </div>
  )
}

const QuickStringing = ({ systemUuid, inverterRange, currencySymbol, disabled }: PropTypes) => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const autoStringModuleUuids = useSelector(autoStringSelectors.moduleUuids)
  const autoStringRunning = useSelector(autoStringSelectors.running)
  const recommendations = useSelector(autoStringSelectors.getRecommedationsForSystem(systemUuid))

  const handleChooseInverterRange = useCallback((inverterRange: string, uuid: string) => {
    if (inverterRange === 'manage') {
      dispatch(
        setStudioSavePromptAction({
          show_prompt: true,
          redirect: '/component_inverter_activations',
          prompt_heading: 'Leave Studio to manage available Inverters?',
          prompt_message: 'Any unsaved changes will be discarded.',
        })
      )
      return
    }
    var system = window.editor.objectByUuid(uuid)
    window.editor.execute(new window.SetValueCommand(system, 'inverterRange', inverterRange))
  }, [])

  return (
    <>
      <Paper style={{ marginTop: 25, padding: 10, boxSizing: 'border-box' }}>
        <h4>{translate('Auto Configuration')}</h4>

        <div style={{ display: 'flex' }}>
          <CustomSelectField
            disabled={disabled}
            style={{ flex: '1 1' }}
            label={translate('Inverter Range')}
            value={inverterRange || ''}
            defaultValue=""
            displayEmpty={true}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleChooseInverterRange(event.target.value || '', systemUuid)
            }}
          >
            {window.AccountHelper.getInverterRanges()
              .map((inverterRange: any, index: number) => (
                <MenuItem key={index} value={inverterRange ? inverterRange.code || '' : ''}>
                  {inverterRange && inverterRange.label ? inverterRange.label : translate('Default')}
                </MenuItem>
              ))
              .concat([
                <MenuItem key="manage" value={'manage'} className="manage-item-opensolar">
                  {translate('Manage available Inverters')}
                </MenuItem>,
              ])}
          </CustomSelectField>

          <ManageIconButton
            heading={translate('Leave Studio to manage available Inverters?')}
            tooltipLabel={translate('Manage Inverters')}
            url={'/component_inverter_activations'}
            disabled={disabled}
          />
        </div>
        <ComponentVersionsInherit versions={ComponentVersions_2_1}>
          <Button
            style={{
              margin: '10px 0px 0px 0px',
            }}
            variant="contained"
            fullWidth={true}
            onClick={() => {
              dispatch(requestAutoStringing(systemUuid, 'best', autoStringModuleUuids))
            }}
            disabled={disabled}
          >
            {translate('Auto Add Best Inverter')}
          </Button>

          <Button
            style={{
              margin: '10px 0px 0px 0px',
            }}
            variant="contained"
            fullWidth={true}
            onClick={() => {
              dispatch(requestAutoStringing(systemUuid, 'all', autoStringModuleUuids))
            }}
            disabled={disabled}
          >
            {translate('View Recommendations')}
          </Button>
        </ComponentVersionsInherit>
      </Paper>

      {(recommendations || autoStringRunning === 'all') && (
        <>
          <h4 className="studio" style={{ marginTop: 20 }}>
            {translate('Recommendations')}
          </h4>
          {recommendations ? (
            <AutoStringChoices currencySymbol={currencySymbol} systemUuid={systemUuid} />
          ) : (
            <LoadingDots />
          )}
        </>
      )}
    </>
  )
}

export default QuickStringing

export const TOUR_STEP_START = 'TOUR_STEP_START'
export const TOUR_STEP_UPDATE = 'TOUR_STEP_UPDATE'
export const TOUR_STEP_END = 'TOUR_STEP_END'

export const updateTourStep = (tour: string, step: string) => ({
  type: TOUR_STEP_UPDATE,
  payload: {
    tour,
    step,
  },
})

export const startTourStep = (tour: string) => ({
  type: TOUR_STEP_START,
  payload: {
    tour,
  },
})

export const terminateCurrentTour = (tour?: string) => ({
  type: TOUR_STEP_END,
  payload: { tour },
})

import { memo, useMemo } from 'react'
import type { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { ComponentTypes } from 'types/selectComponent'

type ComponentTypeImgPropsType = {
  componentType?: ComponentTypes | 'mounting'
  size: 'small' | 'medium'
}

const useComponentTypeImgStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  componentImg: ({ size }: { size: ComponentTypeImgPropsType['size'] }) => ({
    textAlign: 'center',
    width: size === 'small' ? 18 : 24,
    height: size === 'small' ? 18 : 24,
    justifyContent: 'center',
    alignItem: 'center',
  }),
  img: ({ size }: { size: ComponentTypeImgPropsType['size'] }) => ({
    width: size === 'small' ? 18 : 24,
    verticalAlign: 'middle',
  }),
}))

const ComponentTypeImg = memo(({ componentType, size }: ComponentTypeImgPropsType) => {
  const classes = useComponentTypeImgStyles({ size })

  const imageUrl = useMemo(() => {
    switch (componentType) {
      case 'module':
        return window.PUBLIC_URL + '/images/panels.svg'
      case 'mounting':
        return window.PUBLIC_URL + '/images/mounting.svg'
      case 'inverter':
        return window.PUBLIC_URL + '/images/inverters.svg'
      case 'battery':
        return window.PUBLIC_URL + '/images/batteries.svg'
      case 'other':
        return window.PUBLIC_URL + '/images/other_components.svg'
      default:
        return null
    }
  }, [componentType])

  return (
    <span className={classes.componentImg}>
      {imageUrl ? <img className={classes.img} alt={`${componentType} icon`} src={imageUrl} /> : ''}
    </span>
  )
})

export default ComponentTypeImg

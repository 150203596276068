import { HelpLink } from 'elements/link/HelpLink'
import React from 'react'
import useTranslateParse from '../../../util/useTranslateParse'
import { ActiveInvertersCreate, ActiveInvertersEdit, ActiveInvertersList } from './activeInverters'

const ActiveInvertersName = ({ translate, style }) => {
  return <span style={style}>{translate('Edit Inverter')}</span>
}

const InverterSubtitle = () => {
  const translateParse = useTranslateParse()
  return (
    <span>
      {translateParse(
        "Search our extensive database, and add inverters to your 'active' list so your team can use them to design. Non-admins do not have the ability to search the database and can only use equipment on this list. If any equipment is missing from the database, email <mailtoLink>support@opensolar.com<mailtoLink> and we will add it, or you can create your own.",
        { mailtoLink: (label) => <a href="mailto:support@opensolar.com">{label}</a> }
      )}{' '}
      <HelpLink href="https://support.opensolar.com/hc/en-us/articles/4407235661081" />
    </span>
  )
}

export default {
  list: ActiveInvertersList,
  edit: ActiveInvertersEdit,
  create: ActiveInvertersCreate,
  options: {
    create: {
      title: 'Inverter Activation',
      subtitle: null,
      breadCrumb: 'Inverter Activation',
    },
    list: {
      title: 'My Active Inverters List',
      subtitle: <InverterSubtitle />,
      breadCrumb: 'Inverters',
    },
    edit: {
      title: <ActiveInvertersName />,
      subtitle: null,
      breadCrumb: 'Edit Inverter',
    },
  },
}

import { logAmplitudeEvent } from 'amplitude/amplitude'
import ShowArchivedButton from 'elements/button/ShowArchivedButton'
import SearchInput from 'elements/input/ListPageSearchBar'
import inflection from 'inflection'
import React from 'react'
import { CreateButton, useTranslate } from 'react-admin'

export const ListActions = (props: any) => {
  const {
    classes: classesOverride,
    filters,
    filterValues, // dynamically set via the UI by the user
    permanentFilter, // set in the List component by the developer
    actions,
    exporter,
    hasSearch = true,
    hasArchived,
    hideActions,
    resource,
    showFilter,
    displayedFilters,
    createButtonLabel,
    hasCreate,
    searchBarPlaceholder,
    CustomButton,
    ...rest
  } = props
  const translate = useTranslate()
  const resourceName = translate(`resources.${props.resource}.name`, {
    smart_count: 1,
    _: inflection.humanize(inflection.singularize(props.resource)),
  })

  const onCreateClick = () => {
    logAmplitudeEvent('control_crud_action', {
      type: 'create',
      resource: resource,
      variant: 'actions',
    })
  }

  return (
    <>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasSearch && <SearchInput {...props} placeholder={translate(searchBarPlaceholder)} />}
      {CustomButton && React.cloneElement(CustomButton)}
      {hasArchived && <ShowArchivedButton {...props} />}
      {hasCreate && (
        <CreateButton
          id={`list-action-create-button-${resource}`}
          data-cy="list-action-create-button"
          label={createButtonLabel || translate('ra.page.new', { name: resourceName })}
          variant={createButtonLabel === 'Add Cost Information' ? 'contained' : 'outlined'}
          color="default"
          basePath={props.basePath}
          onClick={onCreateClick}
        />
      )}
    </>
  )
}
export default ListActions

// in src/users.js
import { Card, makeStyles } from '@material-ui/core'

import PaginationWithCsvActions from 'elements/PaginationWithCsvActions'
import CustomField from 'elements/field/CustomField'
import ListActions from 'elements/react-admin/ListActions'
import React from 'react'
import { Create, Datagrid, Edit, List, SimpleForm, TextField, TextInput, useTranslate } from 'react-admin'
import EditOrImportButton from '../../elements/button/EditOrImportButton'

const isAdmin = true

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    margin: '0 auto',
    color: '#4d4d4d',
    boxSizing: 'border-box',
    padding: '0px 0px',
  },
  card: {
    marginBottom: 10,
    padding: 20,
    borderWidth: 2,
    borderColor: '#e7e7e7',
    boxSizing: 'border-box',
  },
}))

export const InventoryLocationList = ({
  accessRights: { allowView, allowCreate, allowEdit, allowDelete },
  ...props
}) => {
  const translate = useTranslate()

  return (
    <List
      {...props}
      actions={<ListActions hasSearch={true} createButtonLabel={translate('Create')} />}
      filter={{ is_public_read_only: false }}
      pagination={
        <PaginationWithCsvActions
          csvUploadDialogTitle="CSV Upload - Stock"
          csvUploadDialogDescription="Here you can update stock quantities by first downloading them (coming soon) and then reuploading them here, or upload new ones."
        />
      }
      hasCreate={allowCreate}
    >
      <Datagrid isAdmin={isAdmin}>
        <TextField source="name" displayInCard={true} label={translate('Name')} />

        <EditOrImportButton
          source="actions"
          label={translate('Actions')}
          resource="stock_locations"
          allowDuplicate={false}
          sortable={false}
          isAdmin={isAdmin}
          allowEdit={allowEdit}
          allowDelete={allowDelete}
        />
      </Datagrid>
    </List>
  )
}

// export const InventoryLocationShow = (props) => (
//   <Show title={'Case Study'} subtitle={subtitle} {...props}>
//     <SimpleShowLayout>
//       <TextField source="name" label="Title" />
//       <TextField source="content" />
//       <TextField source="order" />
//       <ImageField source="image_file_contents" title="title" />
//     </SimpleShowLayout>
//   </Show>
// )

const StockPreferencesForLocation = ({ record }) => {
  const classes = useStyles()

  return (
    <>
      <h2>Stock Preferences</h2>
      <p>Preferences are configured using CSV upload.</p>
      {record.stock_preferences?.length > 0 ? (
        <div className={classes.wrapper}>
          {record.stock_preferences.map((stockPreference) => (
            <Card variant="outlined" classes={{ root: classes.card }}>
              <div>Code: {stockPreference.code}</div>
              <div>Re-order quantity: {stockPreference.reorder_quantity}</div>
              <div>Default Location: {stockPreference.is_default_location ? 'Yes' : 'No'}</div>
            </Card>
          ))}
        </div>
      ) : (
        <div>Not set</div>
      )}
    </>
  )
}

export const InventoryLocationEdit = ({
  accessRights: { allowView, allowCreate, allowEdit, allowDelete },
  ...props
}) => {
  const translate = useTranslate()
  let withToolBar = allowEdit ? {} : { toolbar: false }
  return (
    <Edit {...props} hasDelete={allowDelete}>
      <SimpleForm
        hideCancelButton={!!props.hideCancelButton}
        validate={(values) => validateInventoryLocation(values, translate)}
        {...withToolBar}
        disabled={!allowEdit}
      >
        <CustomField
          component={TextInput}
          label="resources.stock_locations.fields.name"
          name="name"
          source="name"
          disabled={!allowEdit}
        />
        <StockPreferencesForLocation />
      </SimpleForm>
    </Edit>
  )
}

export const InventoryLocationCreate = (props) => {
  const translate = useTranslate()
  return (
    <Create {...props}>
      <SimpleForm validate={(values) => validateInventoryLocation(values, translate)}>
        <TextField
          component={TextInput}
          label="resources.stock_locations.fields.name"
          defaultValue={''}
          name="name"
          source="name"
          elStyle={{ marginTop: 20 }}
        />
      </SimpleForm>
    </Create>
  )
}

const validateInventoryLocation = (values, translate) => {
  const errors = {}
  if (!values.name) {
    errors.name = [translate('Name is required.')]
  }
  return errors
}

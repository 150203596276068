import { useCallback, useState } from 'react'
import { useDataProvider } from 'react-admin'
import { dateParser } from 'util/misc'

type UpdateInstallationDateOptions = { projectId: number; date: Date }

type UpdateInstallationDateResponse = {
  isLoading: boolean
  updateInstallationDate: (options: UpdateInstallationDateOptions) => Promise<void>
}

const useUpdateInstallationDate = (): UpdateInstallationDateResponse => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dataProvider = useDataProvider()
  const updateInstallationDate = useCallback(async ({ projectId, date }: UpdateInstallationDateOptions) => {
    setIsLoading(true)
    await dataProvider.update('projects', {
      id: projectId,
      data: { installation_date: dateParser(date) },
      // previousData should be optional, restClient never handles previousData
      previousData: { id: projectId },
    })
    setIsLoading(false)
  }, [])

  return {
    isLoading,
    updateInstallationDate,
  }
}

export default useUpdateInstallationDate

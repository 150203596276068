// @ts-nocheck
import { makeStyles } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import CustomField from 'elements/field/CustomField'
import CheckboxInput from 'elements/input/CheckboxInput'
import OverviewInputs from 'elements/input/OverviewInputs'
import React, { useState } from 'react'
import { BooleanInput, Edit, SaveButton, SimpleForm, TextInput, Toolbar, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { ContentFields, DisclaimerText, validateDocument } from 'resources/contracts/Contracts'
import DocusignSettingsSection from 'resources/contracts/DocusignSettingsSection'
import DragAndDropEditWarning from './DragAndDropEditWarning'

const CustomizedToolbar = (props: any) => {
  return (
    <Toolbar style={{ zIndex: 3, position: 'absolute', bottom: 0, boxSizing: 'border-box', width: '100%' }} {...props}>
      <div>
        <SaveButton
          {...props}
          redirect={false}
          submitOnEnter={true}
          onSave={(values: object, redirect: string, form: any, defaultSaveFunc: Function) => {
            defaultSaveFunc(values, redirect, { onSuccess: props.onSuccess, onFailure: props.onFailure })
          }}
        />
      </div>
    </Toolbar>
  )
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginLeft: '-10px',
  },
})

const LoanTemplateDisclaimer = ({ format }) => (
  // Same text as in Contracts.js
  <div
    style={
      format === 'small'
        ? { height: '100', overflow: 'auto', backgroundColor: '#f0f0f0', borderRadius: 3, padding: 10 }
        : { backgroundColor: '#f0f0f0', borderRadius: 3, padding: 10 }
    }
  >
    <p style={{ fontWeight: 'bold' }}>{DisclaimerText.header1}</p>
    <p>{DisclaimerText.section1}</p>
    <p style={{ fontWeight: 'bold' }}>{DisclaimerText.header2}</p>
    <p>{DisclaimerText.section2}</p>
    <p>{DisclaimerText.section3}</p>
    <p>{DisclaimerText.section4}</p>
    <p>{DisclaimerText.section5}</p>
  </div>
)

const ContractTemplateEditor = ({ handleChange, handleClose, templateOwner, ...rest }: any) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false)

  const translate = useTranslate()
  const classes = useStyles()

  const docusign_account_connected = useSelector(authSelectors.getDocusignAccountConnected)
  const isAdmin = useSelector(authSelectors.getIsAdmin)
  const disabled = !isAdmin || !templateOwner

  const disableEditing = () => setIsDisabled(true)

  if (isDisabled) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '40px',
        }}
      >
        <h1>{translate('Please edit this contract template via the Control tab')}</h1>
        <p>
          {translate(
            'This contract template was made using PDF drag and drop. Templates made in this manner cannot currently be edited in the Online Proposal tab.'
          )}
        </p>
      </div>
    )
  } else
    return (
      <Edit
        style={{ padding: 0 }}
        {...rest}
        basePath={'/contracts'}
        resource={'contracts'}
        hasDelete={false}
        hideBreadCrumbs={true}
      >
        <SimpleForm
          redirect={false}
          hideCancelButton={true}
          style={{ marginBottom: 80 }}
          validate={(values: any) => validateDocument(values, translate)}
          toolbar={
            !disabled ? (
              <CustomizedToolbar
                onSuccess={() => {
                  handleClose()
                  handleChange()
                }}
              />
            ) : (
              false
            )
          }
        >
          <OverviewInputs>
            <DragAndDropEditWarning disableEditing={disableEditing} />
            <CustomField component={TextInput} name={'title'} source="title" disabled={disabled} />

            <CustomField
              component={BooleanInput}
              name={'is_default'}
              label={translate('Default Contract')}
              source="is_default"
              defaultValue={false}
              disabled={disabled}
            />
            <span> {translate('Default for payment type')}</span>
            <div className={classes.container}>
              <CustomField
                component={CheckboxInput}
                name={'is_default_loan'}
                label={translate('Loan')}
                source="is_default_loan"
                defaultValue={false}
                disabled={disabled}
              />

              <CustomField
                component={CheckboxInput}
                name={'is_default_ppa'}
                label={translate('PPA')}
                source="is_default_ppa"
                defaultValue={false}
                disabled={disabled}
              />

              <CustomField
                component={CheckboxInput}
                name={'is_default_cash'}
                label={translate('Cash')}
                source="is_default_cash"
                defaultValue={false}
                disabled={disabled}
              />

              <CustomField
                component={CheckboxInput}
                name={'is_default_regular_payment'}
                label={translate('Regular Payment')}
                source="is_default_regular_payment"
                defaultValue={false}
                disabled={disabled}
              />

              <CustomField
                component={CheckboxInput}
                name={'is_default_lease'}
                label={translate('Lease')}
                source="is_default_lease"
                defaultValue={false}
                disabled={disabled}
              />
            </div>
            <CustomField
              component={BooleanInput}
              name="is_archived"
              source="is_archived"
              label="Archive"
              defaultValue={false}
              disabled={disabled}
            />
            <CustomField
              component={BooleanInput}
              name={'show_header'}
              label="Show header (in docusign PDF)"
              source="show_header"
              defaultValue={false}
              disabled={disabled}
            />
            <CustomField
              component={TextInput}
              fullWidth={true}
              multiline={true}
              name={'description'}
              source="description"
              disabled={disabled}
            />
            <LoanTemplateDisclaimer
              label=""
              source=""
              format="small"
              style={{
                marginTop: 20,
                display: 'flex',
                alignItems: 'center',
              }}
              disabled={disabled}
            />
          </OverviewInputs>

          {docusign_account_connected && isAdmin && (
            <div>
              <DocusignSettingsSection disabled={disabled} {...rest} />
            </div>
          )}

          <ContentFields
            translate={translate}
            docusign_account_connected={docusign_account_connected}
            label="Displayed for all payment options. Specific terms &amp; conditions for selected payment option will also be shown."
            disabled={disabled}
          />
        </SimpleForm>
      </Edit>
    )
}

export default ContractTemplateEditor

import { call, put, takeEvery } from 'redux-saga/effects'

import { CALCULATION_PROCESS_START, CALCULATION_PROCESS_STATUS_UPDATE } from 'actions/designer'
import { showNotification } from 'react-admin'
import {
  AutoStringRunType,
  SYSTEM_AUTO_STRINGING_REQUEST,
  setAutoStringRecommendations,
  setAutoStringRunning,
} from 'reducer/designer/autoString'

export interface AutoStringingRequestSideEffect {
  payload: {
    uuid: string
    return_type: AutoStringRunType
    moduleUuids: string[]
    inverterRange?: string | number
    replaceInverters?: boolean
  }
}

export const requestSystemForClusters = (
  uuid: string,
  return_type: AutoStringRunType,
  moduleUuids: string[],
  inverterRange?: string | number,
  replaceInverters?: boolean
) => window.SceneHelper.requestSystemForClusters(uuid, return_type, moduleUuids, inverterRange, replaceInverters)

export function* handleAutoStringingRequest({ payload }: AutoStringingRequestSideEffect) {
  const system = window.editor.objectByUuid(payload.uuid)
  if (!system) {
    yield put(showNotification('System no longer available ', 'error'))
    return
  }
  if (system.moduleQuantity() === 0) {
    yield put(showNotification('System has no panels', 'error'))
    return
  }
  try {
    yield put(setAutoStringRecommendations(undefined))
    yield put(setAutoStringRunning(payload.return_type))
    yield put({ type: CALCULATION_PROCESS_START, payload: { uuid: payload.uuid, requireClusters: true } })
    yield put({
      type: CALCULATION_PROCESS_STATUS_UPDATE,
      payload: { uuid: payload.uuid, status: 'waitingCluster' },
    })
    const ret = yield call(
      requestSystemForClusters,
      payload.uuid,
      payload.return_type,
      payload.moduleUuids,
      payload.inverterRange,
      payload.replaceInverters
    )
    console.log('auto-string: ', ret)
    yield put({
      type: CALCULATION_PROCESS_STATUS_UPDATE,
      payload: { uuid: payload.uuid, status: 'handlingClusterSuccess' },
    })
    yield put(showNotification('Auto Stringing calculated.', 'info'))
    yield put(setAutoStringRunning(undefined))
  } catch (e) {
    yield put(setAutoStringRunning(undefined))
    yield put(showNotification('Auto Stringing could not be calculated.', 'warning'))
  }
}

export function* watchAutoStringingRequest() {
  //@ts-ignore
  yield takeEvery(SYSTEM_AUTO_STRINGING_REQUEST, handleAutoStringingRequest)
}

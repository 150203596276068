import { PaymentOptionDataType } from 'types/paymentOptions'
import { parseJsonSafe } from 'util/misc'

export const getSungage18MoVersion = (
  juneVersionPmtOption: PaymentOptionDataType,
  availablePaymentOptions: PaymentOptionDataType[]
): PaymentOptionDataType | undefined => {
  let version18Mo: PaymentOptionDataType | undefined = undefined
  availablePaymentOptions?.forEach((pmt) => {
    if (pmt.id !== juneVersionPmtOption.id) {
      if (
        parseJsonSafe(pmt?.configuration_json)?.integration === 'sungage' &&
        (pmt.title?.replace(' - 18mo ZIP', '') === juneVersionPmtOption.title?.replace(' - 18mo ZIP', '') ||
          pmt.title?.replace(' - 18mo Deferred Payment Portion', '') ===
            juneVersionPmtOption.title?.replace(' - 18mo Deferred Payment Portion', ''))
      ) {
        version18Mo = pmt
      }
    }
  })
  return version18Mo
}

export const sortByIntegration = (unsorted: PaymentOptionDataType[]): PaymentOptionDataType[] => {
  if (!unsorted) return []
  let sorted = unsorted.sort((a, b) => {
    const configA = parseJsonSafe(a.configuration_json)
    const configB = parseJsonSafe(b.configuration_json)
    const integrationA = configA?.integration
    const integrationB = configB?.integration

    // sort with archived at the top, because if it's in this list it means it's included on the system
    if (a.is_archived && !b.is_archived) return -1
    else if (!a.is_archived && b.is_archived) return 1
    else if (integrationA && !integrationB) return -1
    else if (!integrationA && integrationB) return 1
    else if (integrationA === integrationB) {
      const termA = configA?.term
      const termB = configB?.term
      if (termA === termB) {
        const rateA = configA?.interest_rate
        const rateB = configB?.interest_rate
        if (rateA === rateB) {
          return a.title > b.title ? 1 : -1
        } else {
          return rateA > rateB ? 1 : -1
        }
      } else {
        return termA > termB ? 1 : -1
      }
    } else {
      return integrationA > integrationB ? 1 : -1
    }
  })
  return sorted
}

import React, { useCallback } from 'react'
import type { FilterComponentRendererType, FilterGenericPropsType, FilterKeysType, OptionGenericType } from '../type'

export type DiscountFilterOptionsType = OptionGenericType<string>[]


const defaultValue = 'false'
const discounted_option_value = 'true'
const DISCOUNT_OPTIONS = [
  { id: 'default', title: 'All', value: defaultValue },
  { id: 'discounted_only', title: 'Discounted only', value: discounted_option_value }]

type DiscountedFilterProps = FilterGenericPropsType & {
  RendererComponent: FilterComponentRendererType<DiscountFilterOptionsType>
  filterKey: FilterKeysType
}

const DiscountFilter = ({
  filterKey,
  disabled,
  modifiableFilterValues,
  persistentFilterValues,
  allFilterValues,
  setModifiableFilters,
  RendererComponent,
}: DiscountedFilterProps) => {

  const onSelect = useCallback(
    (value) => {
      const discounted_only = value !== defaultValue
      setModifiableFilters({...modifiableFilterValues, [filterKey]: discounted_only})
    },
    [modifiableFilterValues]
  )

  let selectedValue = defaultValue

  // If the query parameters were manually modified, check if it's valid
  const isFilterBoolean: boolean = typeof allFilterValues[filterKey] === "boolean"

  if (isFilterBoolean && allFilterValues[filterKey]) {
    selectedValue = discounted_option_value
  }

  return (
    <RendererComponent
      id={filterKey}
      defaultValue={defaultValue}
      label={'Discount'}
      options={DISCOUNT_OPTIONS}
      disabled={disabled}
      onSelect={onSelect}
      value={selectedValue}
    />
  )
}

export default DiscountFilter

import { Divider } from '@material-ui/core'
import type LineItemType from 'pages/ordering/OrderLineItem'
import ProductDetail from 'pages/ordering/detail/ProductDetail'
import ReplaceItemCta from 'pages/ordering/detail/cta/ReplaceItemCta'
import useProductDetail from 'pages/ordering/detail/useProductDetailLineItem'
import React, { useCallback, useMemo } from 'react'
import { useNotify } from 'react-admin'
import createHardwareSelectNode from '../hardwareSelect/HardwareSelectNode'
import type { FlowControllerType, ReplaceItemNode } from '../types'

const ONE_MINUTE_IN_MS = 60 * 1000

type ReviewItemNodeProps = {
  flowController: FlowControllerType
  newCode: string
  originalLineItem: LineItemType
  trackSource: string
}

const ReviewItemNode = ({ flowController, newCode, originalLineItem, trackSource }: ReviewItemNodeProps) => {
  const notify = useNotify()

  const { lineItem, loading, updateLineItem } = useProductDetail({
    componentCode: newCode,
    confirmed: originalLineItem.confirmed,
    defaultQuantity: originalLineItem.quantity,
    defaultProjectOrder: originalLineItem.projectOrder,
  })
  const hasAssociatedProjects = !!originalLineItem.projectOrder?.length

  const onFinish = useCallback(() => {
    flowController.closeFlow()
    if (hasAssociatedProjects) {
      notify(
        'You have replaced component(s) for some project(s) in the order only. Compatibility checks are recommended to ensure the new components work with the design. Please go to the project(s) to update them.',
        'warning',
        { autoHideDuration: 2 * ONE_MINUTE_IN_MS }
      )
    }
  }, [hasAssociatedProjects])
  const handleGoBack = useCallback(() => {
    const hardwareSelect = createHardwareSelectNode({
      flowController,
      originalLineItem,
      trackSource,
    })
    flowController.goTo({ node: hardwareSelect })
  }, [])

  const CtaAction = useMemo(
    () =>
      lineItem != null ? (
        <ReplaceItemCta
          lineItem={lineItem}
          updateLineItem={updateLineItem}
          originalLineItem={originalLineItem}
          onComplete={onFinish}
        />
      ) : null,
    [lineItem, updateLineItem]
  )

  return (
    <div>
      <Divider />
      <ProductDetail
        lineItem={lineItem}
        loading={loading}
        CtaAction={CtaAction}
        goBack={handleGoBack}
        trackSource="replace_item_dialog"
      />
    </div>
  )
}

export type CreateReviewItemNodeType = (args: ReviewItemNodeProps) => ReplaceItemNode

const createReviewItemNode = ({ flowController, newCode, originalLineItem, trackSource }): ReplaceItemNode => ({
  key: 'reviewItem',
  title: 'Review Item',
  Content: (
    <ReviewItemNode
      flowController={flowController}
      newCode={newCode}
      originalLineItem={originalLineItem}
      trackSource={trackSource}
    />
  ),
})

export default createReviewItemNode

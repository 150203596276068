import { useCallback, useMemo } from 'react'
import {
  CABLE_LENGTH_FILTER_KEY_V2,
  isCableLengthFilterAvailableV2,
} from '../cableLength/CableLengthFilterNodeFactoryV2'
import {
  CABLE_THICKNESS_FILTER_KEY_V2,
  isCableThicknessFilterAvailableV2,
} from '../cableThickness/CableThicknessFilterNodeFactoryV2'
import { isCurrentRatingFilterAvailableV2 } from '../currentRating/CurrentRatingFilterNodeFactoryV2'
import { CURRENT_RATING_FILTER_GTE_KEY, CURRENT_RATING_FILTER_LTE_KEY } from '../currentRating/constants'
import { CURRENT_FILTER_KEY_V2, isCurrentTypeFilterAvailableV2 } from '../currentType/CurrentTypeFilterNodeFactoryV2'
import { PHASE_FILTER_KEY_V2, isOtherComponentPhaseFilterAvailableV2 } from '../phaseType/PhaseTypeFilterNodeFactoryV2'
import type { FilterValuesType, OptionGenericType } from '../typeV2'
import { parseStringifiedListFilterValue } from '../utils/utils'
import {
  VOLTAGE_AND_CURRENT_FILTER_KEY_V2,
  isVoltageAndCurrentFilterAvailableV2,
} from '../voltageAndCurrent/VoltageAndCurrentFilterNodeFactoryV2'
import {
  FILTER_OPTIONS,
  GENERAL_OTHER_COMPONENT_TYPE,
  SELECT_ALL_OPTION,
} from './OtherComponentTypeFilterNodeFactoryV2'

const useOtherComponentTypeFilterSideEffectV2 = <OptionValue, RendererValue>() => {
  const FILTER_KEYS_AND_AVAILABILITY_CHECK = useMemo(
    () => [
      {
        isAvailable: isCableLengthFilterAvailableV2,
        filterKeys: [CABLE_LENGTH_FILTER_KEY_V2],
      },
      {
        isAvailable: isCurrentTypeFilterAvailableV2,
        filterKeys: [CURRENT_FILTER_KEY_V2],
      },
      {
        isAvailable: isOtherComponentPhaseFilterAvailableV2,
        filterKeys: [PHASE_FILTER_KEY_V2],
      },
      {
        isAvailable: isCurrentRatingFilterAvailableV2,
        filterKeys: [CURRENT_RATING_FILTER_GTE_KEY, CURRENT_RATING_FILTER_LTE_KEY],
      },
      {
        isAvailable: isCableThicknessFilterAvailableV2,
        filterKeys: [CABLE_THICKNESS_FILTER_KEY_V2],
      },
      {
        isAvailable: isVoltageAndCurrentFilterAvailableV2,
        filterKeys: [VOLTAGE_AND_CURRENT_FILTER_KEY_V2],
      },
    ],
    []
  )
  return useCallback(
    ({
      filterValues,
    }: {
      filterValues: FilterValuesType
      value: RendererValue
      selectedOption: OptionGenericType<OptionValue>
    }): FilterValuesType => {
      const newFilterValue = { ...filterValues }

      FILTER_KEYS_AND_AVAILABILITY_CHECK.forEach(({ isAvailable, filterKeys }) => {
        if (!isAvailable(filterValues)) {
          filterKeys.forEach((key) => delete newFilterValue[key])
        }
      })
      const types = parseStringifiedListFilterValue({
        filterValues: newFilterValue,
        key: 'types',
      })

      if (types.has(SELECT_ALL_OPTION.value)) {
        // add all types when select all option is selected
        // adding the logic here because we don't want to check the other options when select all option is selected
        const typesValue = new Set([...FILTER_OPTIONS.map((option) => option.value), GENERAL_OTHER_COMPONENT_TYPE])
        typesValue.delete(SELECT_ALL_OPTION.value)
        newFilterValue['types'] = typesValue
      }
      return newFilterValue
    },
    []
  )
}

export default useOtherComponentTypeFilterSideEffectV2

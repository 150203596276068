import { Card, Grid, makeStyles } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import React, { FC, useMemo } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import StarButton from 'resources/projects/elements/StarButton'
import { ContactButtonsHome } from 'resources/projects/projectsList/ContactButtonsHome'
import { ProjectType } from 'types/projects'
import NotAvailable from './NotAvailable'
import { RecentProjectsSubHeader } from './SubHeader'

const useStyles = makeStyles((theme) => ({
  card: {
    width: 150,
    height: 130,
    padding: 10,
    boxSizing: 'border-box',
    boxShadow: 'none',
    border: '1px solid rgb(0, 0, 0, 0.2)',
  },
  addressWrapper: {
    fontSize: '12px',
    textAlign: 'center',
    margin: '0px 0px 8px 0px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    minHeight: 28, // Ensure that single and double lines fill same height
  },
  contact: {
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.54)',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textDecoration: 'none',
  },
  contactWrapper: {
    width: '100%',
    textAlign: 'center',
    height: 45,
  },
  actionWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 5,
  },
}))

type PropsType = {
  project: ProjectType
}

const Stage = ({ workflow }: any) => {
  const orgWorkflows = useSelector(orgSelectors.getWorkflows)
  const currentStage = useMemo(() => {
    const workflowStages = orgWorkflows?.find((x) => x.id === workflow?.workflow_id)?.workflow_stages
    if (workflowStages) {
      return workflowStages.find((x) => x.id === workflow.active_stage_id)?.title
    }
  }, [orgWorkflows, workflow])
  if (!currentStage) {
    return <div></div>
  }
  return (
    <div style={{ background: 'rgba(240, 240, 240, 1)', borderRadius: 4, padding: '3px 10px' }}>
      <span style={{ verticalAlign: 'middle', fontSize: 12 }}>{currentStage}</span>
    </div>
  )
}

const ProjectCard: FC<PropsType> = ({ project }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const address = project.address
  const locality = project.locality ? `, ${project.locality}` : ''
  const state = project.state ? `, ${project.state}` : ''
  const zip = project.zip ? `, ${project.zip}` : ''
  const contact = project.contacts_data && project.contacts_data[0]
  return (
    <Card classes={{ root: classes.card }}>
      <h3 className={classes.addressWrapper}>
        <Link style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }} to={`/projects/${project.id}/info`}>
          {project.business_name && project.business_name.length && project.business_name + ', '}
          {address ? address + locality + state + zip : translate('(Address not specified)')}
        </Link>
      </h3>
      <div className={classes.contactWrapper}>
        {contact && (
          <>
            <div className={classes.contact}>
              <Link style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.54)' }} to={'/contacts/' + contact.id}>
                {contact ? contact.display : null}
              </Link>
            </div>
            <ContactButtonsHome contact={contact} />
          </>
        )}
      </div>
      <div className={classes.actionWrapper}>
        <Stage workflow={project.workflow} />
        <StarButton record={project} style={{ width: 'auto', padding: 0 }} />
      </div>
    </Card>
  )
}

const RecentProjects = () => {
  const recentProjects =
    useSelector((state: any) => state.recentProjects.filter((project) => project.org_id === state.auth?.org_id)) || []
  const projects = recentProjects.slice(0, 6)
  return (
    <div>
      <RecentProjectsSubHeader />
      <div>
        {projects.length === 0 ? (
          <div style={{ margin: '10px 16px' }}>
            <NotAvailable />
          </div>
        ) : (
          <div style={{ margin: '10px 16px 20px' }}>
            <Grid container spacing={2} style={{ overflowY: 'auto', overflowX: 'hidden' }}>
              {projects.map((project: ProjectType) => (
                <Grid key={project.id} item xs={6}>
                  <ProjectCard project={project} />
                </Grid>
              ))}
            </Grid>
          </div>
        )}
      </div>
    </div>
  )
}

export default RecentProjects

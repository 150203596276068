import Grid from '@material-ui/core/Grid'
import CustomField from 'elements/field/CustomField'
import DateTimeInput from 'elements/input/DateTimeInput'
import React from 'react'
import { TextInput, useTranslate } from 'react-admin'

const CompletedEventForm: React.FunctionComponent = (props: any) => {
  const translate = useTranslate()
  return (
    <>
      <Grid item xs={12}>
        <h2>
          {translate('Event Title')}: {props.record.title}
        </h2>
      </Grid>
      <Grid item xs={12}>
        <CustomField
          style={{ width: '100%' }}
          component={TextInput}
          fullWidth={true}
          multiline={true}
          {...props}
          name="notes"
          source="notes"
        />
      </Grid>
      <Grid item xs={12}>
        <CustomField component={DateTimeInput} {...props} name="start" source="start" fullWidth={true} disabled />
      </Grid>
    </>
  )
}

export default CompletedEventForm

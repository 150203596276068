import { makeStyles } from '@material-ui/core'
import { Checkbox } from 'opensolar-ui'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import Markdown from 'react-markdown'
import LoanpalDisclosureSignature from './LoanpalDisclosureSignature'

type PropTypes = {
  boldText: boolean
  checkbox: boolean
  signatureBox: boolean
  sort: number
  text: string
  disclosureIndex: number
  markAsValid: (i: string) => void
  markAsInvalid: (i: string) => void
  invalidDisclosures: string[]
  markSignatureAsComplete: (signatureData: string) => void
}

const useStyles = makeStyles({
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
})

const LoanpalDisclosureSection: React.FunctionComponent<PropTypes> = (props) => {
  const [checked, setChecked] = useState<boolean>(false)
  const classes = useStyles()
  const sigRef = useRef()

  const handleCheckChange = (e: ChangeEvent<HTMLInputElement>, newCheckedVal: boolean) => {
    setChecked(newCheckedVal)
    if (newCheckedVal) props.markAsValid(`${props.disclosureIndex}-${props.sort}`)
    else props.markAsInvalid(`${props.disclosureIndex}-${props.sort}`)
  }

  useEffect(() => {
    if (!props.checkbox) props.markAsValid(`${props.disclosureIndex}-${props.sort}`)
    else {
      props.markAsInvalid(`${props.disclosureIndex}-${props.sort}`)
    }
  }, [props.checkbox, props.signatureBox])

  return (
    <div className={props.signatureBox ? classes.column : classes.row}>
      {props.checkbox && (
        <Checkbox checked={checked} onChange={handleCheckChange} inputProps={{ 'aria-label': 'primary checkbox' }} />
      )}
      <div style={{ fontWeight: props.boldText ? 900 : undefined, fontSize: '13px' }}>
        <Markdown children={props.text} linkTarget="_blank" />
      </div>
      {props.signatureBox && (
        <LoanpalDisclosureSignature
          markSignatureAsComplete={props.markSignatureAsComplete}
          disclosureIndex={props.disclosureIndex}
          sort={props.sort}
        />
      )}
    </div>
  )
}
export default LoanpalDisclosureSection

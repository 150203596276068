import { authSelectors } from 'ducks/auth'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  clearAuthRedirection,
  getAuthRedirection,
  retrieveAuthRedirectionFromLocalStorage,
} from './authRedirectionStore'

/**
 * The auth referred to here is whether we're authenticated with OS.
 */
const useAuthRedirect = () => {
  const authStatus = useSelector(authSelectors.getAuthStatus)
  const history = useHistory()

  useEffect(() => {
    retrieveAuthRedirectionFromLocalStorage()
  }, [])

  useEffect(() => {
    if (authStatus !== 'authenticated') {
      return
    }
    const redirection = getAuthRedirection()
    if (redirection === undefined || redirection.type !== 'AUTH_SUCCESS_REDIRECT') {
      return
    }
    clearAuthRedirection()
    history.push(redirection.redirect)
  }, [authStatus])
}

export default useAuthRedirect

import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import AuthModalWrapper from './AuthModalWrapper'
import { pathnameAndParamsWithUrlFragment } from './common'
import { OpenSolarAuthFlowLogo } from './components/logos/AuthFlowLogo'
import PasswordReset from './components/reset/PasswordReset'

const PasswordResetPage = () => {
  const history = useHistory()
  const location = useLocation()

  const onResetRequestSuccess = useCallback(() => {
    history.push('/password-reset-requested')
  }, [])

  const onCreateAccountClick = useCallback(() => {
    // @ts-ignore
    history.push(pathnameAndParamsWithUrlFragment('/register', location, location.params?.urlFragment))
  }, [])

  const onSignInClick = useCallback(() => {
    // @ts-ignore
    history.push(pathnameAndParamsWithUrlFragment('/login', location, location.params?.urlFragment))
  }, [])

  return (
    <AuthModalWrapper>
      <PasswordReset
        onResetRequestSuccess={onResetRequestSuccess}
        Logo={<OpenSolarAuthFlowLogo />}
        onCreateAccount={onCreateAccountClick}
        onSignIn={onSignInClick}
      />
    </AuthModalWrapper>
  )
}
export default PasswordResetPage

import { Dialog, DialogContent, makeStyles } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import { Button } from 'opensolar-ui'
import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import restClient from 'restClient'
import { ProjectType } from '../../../types/projects'
import { SystemDesignSunlightQuickCreateType } from './types'

let restClientInstance = restClient(window.API_ROOT + '/api')
const CONFIG = {
  logo: 'logo-sunlight-financial.png',
  logo_small: 'logo-sunlight-financial-small.png',
  studio_banner_title: 'Simplify Your Sale!',
  name: 'sunlight',
  themeColor: 'rgb(254, 184, 22)',
  themeColorDark: 'rgb(232, 143, 36)',
}

const useStylesWhiteButton = makeStyles({
  label: {
    padding: '0px 0px 0px 10px',
    fontSize: 13,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'flex',
  },
  endIcon: {
    margin: 0,
  },
  root: {
    border: '1px solid #212121',
    lineHeight: '32px',
    textAlign: 'start',
    height: 35,
    padding: '0 5px',
    marginTop: '10px',
    width: '100%',
    justifyContent: 'flex-start',
  },
})

type PropTypes = {
  project: ProjectType
  designs?: SystemDesignSunlightQuickCreateType[]
}

const SunlightQuickCreateLauncher: React.FunctionComponent<PropTypes> = (props) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const sendLoanDocs = () => {
    setLoading(true)
    // Request the email send (which will automatically record an incomplete transaction)
    restClientInstance('CUSTOM_GET', 'custom', {
      url: 'orgs/' + props.project.org_id + '/projects/' + props.project.id + '/send_loan_docs/',
    })
      .then(
        (response: any) => {
          window.Designer.showNotification(window.translate('Loan Docs Requested'))
          setShowModal(false)
        },
        (reject: any) => {
          window.Designer.showNotification(window.translate('Error Sending Loan Docs'), 'warning')
        }
      )
      .catch((ex: any) => {
        window.Designer.showNotification(window.translate('Error Sending Loan Docs'), 'warning')
      })
      .finally(() => setLoading(false))
  }

  const renderDialog = () => {
    return (
      <Dialog
        open={showModal}
        style={{ maxHeight: 'calc(100vh - 56px)', textAlign: 'center' }}
        onBackdropClick={() => setShowModal(false)}
      >
        <DialogContent style={{ paddingBottom: '20px' }}>
          <img
            alt="Sunlight Financial"
            src={`${window.PUBLIC_URL}/images/logo-sunlight-financial.png`}
            style={{ maxHeight: 84, maxWidth: 220, marginBottom: '40px' }}
          />
          <p>
            Clicking the button below will send the Sunlight Loan docs for this project. Please note that it can take up
            to a minute or two for Sunlight's systm to generate and send the documents.
          </p>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ margin: '10px' }}>
              <ProUXButton type="secondary" label="Cancel" onClick={() => setShowModal(false)} />
            </div>
            <div style={{ margin: '10px' }}>
              <ProUXButton type="primary" label="Send Loan Docs" onClick={sendLoanDocs} loading={loading} />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    )
  }

  const handleButtonClick = () => {
    setShowModal(true)
    logAmplitudeEvent('send_loan_agreement_dialog_opened', {
      integration: 'sunlight',
      page: window.location.hash?.includes('/manage') ? 'Manage Page' : 'Info Page',
    })
  }

  const whiteButtonClasses = useStylesWhiteButton()
  const translate = useTranslate()

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Button
        startIcon={
          <img
            alt={CONFIG.name}
            src={`${window.PUBLIC_URL}/images/${CONFIG.logo_small}`}
            style={{ maxHeight: 24, maxWidth: 60 }}
          />
        }
        classes={whiteButtonClasses}
        className="UploadLogoButton"
        onClick={handleButtonClick}
      >
        <span style={{ textTransform: 'none' }}>{translate('Send Loan Docs')}</span>
      </Button>
      {renderDialog()}
    </div>
  )
}

export default SunlightQuickCreateLauncher

import { orderSelectors } from 'ducks/orderComponents'
import FullScreenLoading from 'layout/widgets/FullScreenLoading'
import { memo, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import { parseQueryStringToDictionary } from 'util/misc'
import PostCheckoutPage from './PostCheckoutPage'
import PreOrderPage from './PreOrderPage'
import SegenCheckoutProvider, { CheckoutPresenterContext, OrderContext, QuoteContext } from './SegenCheckoutProvider'

const PostQuotePage = memo(() => {
  const { loading, quote } = useContext(QuoteContext)
  const portalUrl = quote?.portalUrl
  if (loading) {
    return <FullScreenLoading />
  }
  if (portalUrl == null) {
    return <Redirect to={'/shop/cart/'} />
  }
  return <PostCheckoutPage quoteOrOrderType="quote" portalUrl={portalUrl} />
})

const PostOrderPage = memo(() => {
  const { submitting, isReady, order } = useContext(OrderContext)
  const { loading, quote } = useContext(QuoteContext)
  // Use the portal url from quote
  const portalUrl = quote?.portalUrl
  if (submitting) {
    return <FullScreenLoading />
  }
  if (!isReady || !portalUrl) {
    return <Redirect to={'/shop/cart/'} />
  }
  return <PostCheckoutPage quoteOrOrderType="order" portalUrl={portalUrl} />
})

const Checkout = memo(() => {
  const location = useLocation()
  const params = parseQueryStringToDictionary(location.search)
  const [initialing, setInitialing] = useState<boolean>(true)
  const lineItemsConfirmed = useSelector(orderSelectors.getOrderableLineItems)
  const segenCheckoutPresenter = useContext(CheckoutPresenterContext)

  useEffect(() => {
    const init = async () => {
      await segenCheckoutPresenter?.init(lineItemsConfirmed)
      setInitialing(false)
    }
    init()
  }, [])

  if (lineItemsConfirmed.length === 0) {
    return <Redirect to={'/shop'} />
  }
  if (initialing) {
    return <FullScreenLoading />
  }

  if (params.step === 'post-order') {
    return <PostOrderPage />
  }

  if (params.step === 'post-quote') {
    return <PostQuotePage />
  }

  return <PreOrderPage />
})

const SegenCheckoutWithContext = () => {
  return (
    <SegenCheckoutProvider>
      <Checkout />
    </SegenCheckoutProvider>
  )
}

export default SegenCheckoutWithContext

import { useCallback, useEffect, useMemo, useState } from 'react'

export type DraggableListControllerProps<T, K = string> = {
  groups: { [key: string]: T[] }
  optionId?: string
  onDragComplete: (oldGroups: { [key: string]: T[] }, newGroups: { [key: string]: T[] }) => void
}

export const useDraggabbleListController = <T, K>({
  groups,
  optionId = 'uuid',
  onDragComplete,
}: DraggableListControllerProps<T, K>): DraggableListController<T, K> => {
  // When a change is made to the order of the list
  // we temporarily store a new copy of `list` in `fakeList`, with the new ordering
  // This gets cleared after 0.5s, so that the user can see the 'real' change
  // This prevents the order from flickering back to the old order while the change is being applied
  const [fakeGroups, setFakeGroups] = useState<{ [key: string]: T[] }>({})
  const [draggingItem, setDraggingItem] = useState<string | undefined>(undefined)

  const resolvedGroups = useMemo(() => ({ ...groups, ...fakeGroups }), [fakeGroups, groups])

  const getItemId = useCallback((item: T) => item[optionId], [optionId])

  const getItemKey = useCallback((item: T) => getItemId(item) + '', [getItemId])

  const isDragEnabled = useCallback(
    (groupId: string) => {
      const list = resolvedGroups[groupId]
      return list?.length > 1
    },
    [groups]
  )

  useEffect(() => {
    setFakeGroups({})
  }, [groups])

  const onDragStart = useCallback((data) => {
    setDraggingItem(data.draggableId)
  }, [])

  const onDragEnd = useCallback(
    (data) => {
      setDraggingItem(undefined)
      if (!data.destination) {
        // dropped outside of list
        return
      }

      const item = groups[data.source.droppableId][data.source.index]

      const newGroups = { ...groups }

      const sourceGroup = [...groups[data.source.droppableId]]
      sourceGroup.splice(data.source.index, 1)
      newGroups[data.source.droppableId] = sourceGroup

      if (data.destination.droppableId === data.source.droppableId) {
        sourceGroup.splice(data.destination.index, 0, item)
      } else {
        const destGroup = [...groups[data.destination.droppableId]]
        destGroup.splice(data.destination.index, 0, item)
        newGroups[data.destination.droppableId] = destGroup
      }

      onDragComplete(groups, newGroups)

      setFakeGroups({
        [data.source.droppableId]: newGroups[data.source.droppableId],
        [data.destination.droppableId]: newGroups[data.destination.droppableId],
      })
      setTimeout(() => {
        setFakeGroups({})
      }, 500)
    },
    [groups]
  )

  return {
    draggingItem,
    onDragStart,
    onDragEnd,
    groups: resolvedGroups,
    getItemId,
    getItemKey,
    isDragEnabled,
  }
}

export type DraggableListController<T, K> = {
  draggingItem: string | undefined
  onDragStart: (data: any) => void
  onDragEnd: (data: any) => void
  getItemId(item: T): K
  getItemKey(item: T): string
  isDragEnabled: (groupId: string) => boolean
  groups: { [key: string]: T[] }
}

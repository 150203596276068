import { Button } from 'opensolar-ui'
import { authSelectors } from 'ducks/auth'
import { useSelector } from 'react-redux'
import restClient from 'restClient'

const syncComponent = async (orgId: number | undefined): Promise<void> => {
  const restClientInstance = restClient(window.API_ROOT + '/api')
  if (orgId !== undefined) {
    const response = await restClientInstance('CUSTOM_GET', 'custom', {
      url: `sync_components/?orgs=${orgId}`,
    })
  }
}

const ComponentsSyncButton = () => {
  const orgId = useSelector(authSelectors.getOrgId)
  return (
    <Button
      style={{
        marginTop: 10,
        maxWidth: 230,
        height: 'fit-content',
      }}
      variant="contained"
      size="small"
      onClick={() => {
        syncComponent(orgId)
      }}
    >
      Sync Components
    </Button>
  )
}

export default ComponentsSyncButton

import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from 'types/state'
import { getOrgFromState } from 'util/org'
import { getImageryTypeDefault } from '../design/util/getImageryTypeDefault'

/*
This is all of the special logic that should run on the explore page (full/lite)
*/

interface ExploreLogicRet {
  defaultMapType: string
  selectedMapTypeOrFirst: string
  selectedMapType: string
  setSelectedMapType: (value: string) => void
  mapTypes: string[]
  finishExploreMode: () => void
}

export const useExploreLogic = (isCreated = false, usePrefs = true): ExploreLogicRet => {
  const form = useForm()
  const history = useHistory()
  const formValues = form.getState().values
  const projectCountryIso2 = formValues.country_iso2
  const projectState = formValues.state

  const setSelectedMapType = (value: string) => {
    if (value === '') return

    setSelectedMapTypeInner(value)

    // Hack which blocks loadView when controlMode is 'map
    var controlModeOriginal = window.Designer.controlMode
    window.Designer.controlMode = undefined

    var viewInstance = window.ViewHelper.selectedView()

    /*
      Beware: Do not inject oblique data from the explore page because this should never transfer from one
      mapType to another. It should be retrieved from the MapType definition for the layer.
      Even though we will throw away the old mapData we are not deleting the key by reference, just in case that
      might break the MapType definition
      */
    var mapDataExcludingOblique = { ...viewInstance.mapData }
    if (mapDataExcludingOblique.oblique) {
      delete mapDataExcludingOblique.oblique
    }

    var mapData = window.MapData.createMapData({ ...mapDataExcludingOblique, mapType: value })

    viewInstance.mapData = mapData

    window.ViewHelper.loadView(viewInstance, window.editor)

    // Cleanup controlMode hack
    window.Designer.controlMode = controlModeOriginal
  }

  const hasNmos = useSelector((state: RootState) => {
    return !!state.auth?.user?.login_authority || !!getOrgFromState(state)?.external_account_id
  })

  const hasNearmap = useSelector((state: RootState) => {
    return !!getOrgFromState(state)?.enable_nearmap
  })

  const enableOtherImagery = useSelector((state: RootState) => !!getOrgFromState(state)?.enable_other_imagery)

  const [selectedMapType, setSelectedMapTypeInner] = useState('')

  useEffect(() => {
    if (isCreated) return

    // Register a handful of fields that to be able to modify (most will already be added by Design)
    const requiredFields = ['lat', 'lon', 'address', 'state', 'zip', 'country_iso2', 'locality']
    for (var i of requiredFields) {
      form.registerField(i, () => {}, {})
    }
  }, [isCreated])

  const handleCallBack = useCallback(() => {
    // Beware: toMapData().center can be unstable because Google maps getBounds() can be totally wrong, possibly related
    // to when map returns from an inactive/background state.
    // BROKEN: const coordinates = window.MapHelper.activeMapInstance.toMapData().center
    //
    // Instead we will simply use getCenter() for the map which is a simpler method and does not seem to suffer from
    // the same instability that affects getBounds().
    var useSimplifiedGetCenter = true
    const coordinates = window.MapHelper.activeMapInstance.toMapData(useSimplifiedGetCenter).center

    if (coordinates && coordinates.length === 2) {
      // Start debugging code for wrong location loading
      if (window.studioDebug) {
        window.locationLogAdd([
          'explore handleCallBack ' +
            window.MapHelper.activeMapInstance.toMapData().mapType +
            ' ' +
            (window.MapHelper.activeMapInstance.detectAndApplyGoogleTopDownMaxZoomInProgress ? 'ignored' : 'applied'),
          coordinates[0],
          coordinates[1],
        ])

        const coordinatesVerifyWrongBounds = window.MapHelper.activeMapInstance.toMapData().center

        window.locationLogAdd([
          'explore handleCallBack old getBounds ' +
            window.MapHelper.activeMapInstance.toMapData().mapType +
            ' ' +
            (window.MapHelper.activeMapInstance.detectAndApplyGoogleTopDownMaxZoomInProgress ? 'ignored' : 'applied'),
          coordinatesVerifyWrongBounds[0],
          coordinatesVerifyWrongBounds[1],
        ])
      }
      // End debugging code for wrong location loading

      if (!window.MapHelper.activeMapInstance.detectAndApplyGoogleTopDownMaxZoomInProgress) {
        var lon = coordinates[0]
        var lat = coordinates[1]

        form.change('lon', lon)
        form.change('lat', lat)

        // Also update scene.sceneOrigin4326 so when we startDesignMode we use the viewport final location
        // instead of the original lat/lon from the address lookup
        window.editor.scene.sceneOrigin4326 = [lon, lat]
      } else {
        /*
        maxZoom detection is in progress, do NOT use the current map center to detect coordinates because they are
        unstable while the map is zooming.

        If they have not changed significantly then it does not matter at all. We do not currently re-trigger the update
        because we assume the update will be called when the animation is finished as part of  zoom detection finishing.

        If this is not reliable then we may want to attach a listener to zoom detection completion but this is
        complicated/dangerous because if the detection runs very slowly it may fire at the complete wrong time and
        corrupt data. A simpler/safer alternative would be to trigger another attempt 1 second later but we assume
        this is not required for now.
        */
        console.warn('explore handleCallBack ignored while maxZoom detection in progress')
      }
    }
  }, [])

  var mapTypes = window.mapTypesForExplorePage(
    hasNearmap,
    hasNmos,
    enableOtherImagery,
    projectCountryIso2,
    projectState
  )

  const finishExploreMode = () => {
    /*
    Ensure that window.Designer.controlMode does not remain in "map" after leaving Explore page, otherwise
    some components will think that we are in "align map" module_external_link
    */
    if (window.Designer.controlMode !== 'both') {
      // Discard changes because we assume that any changes have already been applied to the project/design

      // Critical to force editor.interactive() and MapHelper.interactive() both to false otherwise editor
      // may be running in the background. The next page that we arrive at will set it correctly, we really just
      // need to return controlMode to the default value without missing with interactive values.
      var forceInteractiveValue = false
      window.Designer.changeControl('both', true, forceInteractiveValue)
    }

    window.SnapshotHelper.visibility(false)
    window.editor.signals.cameraAnimationFinished.remove(handleCallBack)
  }

  useEffect(() => {
    // This is only used in lite, where this hook remains active during the entire design process
    if (isCreated) finishExploreMode()
  }, [isCreated])

  // If nothing selected, check if a preference has been saved and use that if it is available in map types
  // for this location
  const imageryTypeDefault = usePrefs ? getImageryTypeDefault() : undefined
  const availableMapTypeMatchingPreference =
    imageryTypeDefault?.map_type &&
    mapTypes.some((mapType) => {
      return mapType === imageryTypeDefault.map_type && !imageryTypeDefault?.validation_data?.require_wallet_product
    })
      ? imageryTypeDefault.map_type
      : null

  const selectedMapTypeOrFirst = selectedMapType || availableMapTypeMatchingPreference || mapTypes[0]

  useEffect(() => {
    if (isCreated) return

    const formValues = form.getState().values
    const hasLocationInForm = formValues?.lon && formValues?.lat
    if (/*!location.state &&*/ !hasLocationInForm) {
      //invalid: missing location data
      console.log('No location found, redirecting to /projects')
      history.push('/projects')
      return
    } /* else {
      const lat = location.state?.lat || formValues.lat
      const lon = location.state?.lon || formValues.lon
      const country_iso2 = location.state?.country_iso2 || formValues.country_iso2
      if (location.state) {
        form.batch(() => {
          for (var fieldName in location.state) {
            //we can use form.registerField here
            if (infoFields.includes(fieldName)) form.change(fieldName, location.state[fieldName]) // listeners not notified
          }
        }) // NOW all listeners notified
      }*/
    const lat = formValues.lat
    const lon = formValues.lon
    const country_iso2 = formValues.country_iso2

    window.editor.tweenLeftMargin(0)
    //always show viewfinder in explore page
    window.SnapshotHelper.visibility(true)
    window.MapHelper.jumpToMaxZoomAfterNextDetection = true
    window.editor.signals.cameraAnimationFinished.add(handleCallBack)
    window.WorkspaceHelper.loadWithLocation(window.editor, { lat, lon, country_iso2 }, true, selectedMapTypeOrFirst)
    window.WorkspaceHelper.inheritProjectPermissions()
    //}

    return finishExploreMode
  }, [isCreated])

  return {
    selectedMapTypeOrFirst,
    selectedMapType,
    setSelectedMapType,
    mapTypes,
    finishExploreMode,
    defaultMapType: availableMapTypeMatchingPreference || mapTypes[0],
  }
}

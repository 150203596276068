import { transactionSelectors } from 'ducks/transaction'
import React, { useRef } from 'react'
import { useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import SignaturePad from 'react-signature-pad'

type PropTypes = {}

const NativeSignaturePad: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const signatureRef = useRef(null)
  const form = useForm()

  const transactionRequestData = useSelector(transactionSelectors.getTransactionRequestData)

  if (!transactionRequestData.collect_signature) return null
  return (
    <>
      <h2 data-testid="native-signature-header">
        {translate('Signature')}{' '}
        <span className="small" style={{ fontWeight: 'normal' }}>
          {translate('Sign with your finger or mouse')}
        </span>
      </h2>
      <SignaturePad
        clearButton="true"
        ref={(element: any) => {
          signatureRef.current = element
          window.SignaturePad = element
        }}
        onEnd={(e: MouseEvent) => {
          if (signatureRef?.current) {
            // @ts-ignore
            form.change('signature_data', signatureRef.current.toDataURL())
          }
        }}
      />
    </>
  )
}
export default NativeSignaturePad

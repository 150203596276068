import { Chip, Paper, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { authSelectors } from 'ducks/auth'
import restClient from 'restClient'
import { Theme } from 'types/themes'

const restClientInstance = restClient(window.API_ROOT + '/api')
const MILESTONE_FILTERS = {
  all: {
    label: 'All Projects',
  },
  unsold: {
    searchFilter: '{"milestone":"0,1,2,3", "project_sold":false, "project_installed":false}',
    label: 'Unsold',
  },
  sold: {
    searchFilter: '{"milestone":"0,1,2,3", "project_sold":true, "project_installed":false}',
    label: 'Sold (Not Installed)',
  },
  installed: {
    searchFilter: '{"milestone":"0,1,2,3", "project_installed":true}',
    label: 'Installed',
  },
  others: {
    searchFilter: '{"milestone":4}',
    label: 'Others',
  },
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    wrapper: {
      cursor: 'default',
      width: 'calc(100% - 4px)',
      display: 'flex',
      // margin: '10px 0 20px',
      margin: 0,
      boxShadow: 'none',
      border: theme.border.default,
      borderBottom: 'none',
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px',
      [theme.breakpoints.down('xs')]: { display: 'none' },
    },
    activeTab: {
      textAlign: 'center',
      fontWeight: 'bold',
      minWidth: 30,
      padding: 0,
      flexGrow: 1,
      borderBottom: '2px solid black',
    },
    tab: {
      textAlign: 'center',
      minWidth: 30,
      padding: 0,
      flexGrow: 1,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgb(240,240,240)',
      },
    },
  }),
  { name: 'project-list-search-field' }
)

export const ProjectStageCount = () => {
  const [loadedData, setLoadedData] = useState<object>()
  const [isLoading, setIsLoading] = useState(true)
  const orgId = useSelector(authSelectors.getOrgId)
  const classes = useStyles()
  useEffect(() => {
    restClientInstance('CUSTOM_GET', 'custom', {
      url: 'orgs/' + orgId + '/project_milestone_count/',
    })
      .then((response) => {
        setLoadedData(response.data)
        setIsLoading(false)
      })
      .catch(() => {
        setLoadedData({})
        setIsLoading(false)
      })
  }, [])

  const location = useLocation()
  const translate = useTranslate()
  return (
    <Paper elevation={3} className={classes.wrapper}>
      {Object.keys(MILESTONE_FILTERS).map((key, index) => {
        const milestone = MILESTONE_FILTERS[key]
        const filterStr = `?filter=${encodeURIComponent(milestone.searchFilter)}`
        const isActive = location.search ? location.search.includes(filterStr) : index === 0
        const count = loadedData?.[key]
        const countStr = count ? count : '0'

        return (
          <div
            className={isActive ? classes.activeTab : classes.tab}
            key={key}
            onClick={() =>
              //@ts-ignore
              (window.location = '/#/projects' + (key === 'all' ? '' : filterStr))
            }
          >
            <div style={{ padding: '15px 10px' }}>
              {isLoading ? (
                <Skeleton height={'20px'} />
              ) : (
                <a>
                  {`${translate(milestone.label)}`}
                  &nbsp;
                  <Chip label={`${countStr}`} size="small" />
                </a>
              )}
            </div>
          </div>
        )
      })}
    </Paper>
  )
}

import { Button } from 'opensolar-ui'
import React, { useEffect, useState } from 'react'
import { useFormState } from 'react-final-form'
import { AvailableFinanceIntegrationType } from 'types/orgs'
import { parseJsonSafe } from 'util/misc'
import GenericConnectRoleDialog from './GenericConnectRoleDialog'

type PropTypes = {
  integration: AvailableFinanceIntegrationType
  orgId: number | undefined
  orgName?: string
}

const GenericFinanceRoleConnection: React.FC<PropTypes> = (props) => {
  const [currentEmail, setCurrentEmail] = useState<string | undefined>(undefined)
  const [showDialog, setShowDialog] = useState<boolean>(false)

  const formState = useFormState()

  useEffect(() => {
    const integrationJson = parseJsonSafe(formState.values.integration_json)

    if (
      props.orgId &&
      integrationJson &&
      props.integration?.integration_name &&
      integrationJson[props.integration.integration_name] &&
      integrationJson[props.integration.integration_name][props.orgId]
    ) {
      setCurrentEmail(integrationJson[props.integration.integration_name][props.orgId]?.email)
    }
  }, [formState?.values?.integration_json, props.orgId])

  const openConnectDialog = () => {
    setShowDialog(true)
  }

  return (
    <div>
      {currentEmail ? (
        <div>
          <div>
            <Button variant="outlined" onClick={openConnectDialog}>
              <span>{`Edit ${props.integration.display_name} connection${
                props.orgName ? ` for ${props.orgName}` : ''
              }`}</span>
            </Button>
          </div>
          <span className="small">(Currently {currentEmail})</span>
        </div>
      ) : (
        <div>
          <Button variant="outlined" onClick={openConnectDialog}>
            <span>{`Set ${props.integration.display_name} connection${
              props.orgName ? ` for ${props.orgName}` : ''
            }`}</span>
          </Button>
        </div>
      )}
      {showDialog && (
        <GenericConnectRoleDialog
          integration={props.integration}
          onClose={() => setShowDialog(false)}
          orgId={props.orgId}
        />
      )}
    </div>
  )
}
export default GenericFinanceRoleConnection

import { Dialog, DialogTitle, IconButton } from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import LightReachMoreInfo from 'Designer/StudioErrorPrompts/lightReach/LightReachMoreInfo'
import { PaymentRequestType } from 'pages/cashFlow/types'
import React from 'react'
import { useTranslate } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type PropTypes = {
  onClose: () => void
  paymentRequests: PaymentRequestType[]
  deletePaymentOption: () => void
  systemUuid: string | null
  paymentOptionId: number
  isPendingSave: boolean
}

const useStyles = makeOpenSolarStyles((theme) => ({
  titleWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.greyLight1,
  },
  closeButton: {
    color: theme.greyMid1,
  },
  buttonsRow: {
    display: 'flex',
    padding: '16px 24px',
    justifyContent: 'end',
    gap: '10px',
    alignItems: 'center',
    width: '100%',
  },
  field: {
    margin: '5px 0',
  },
  label: {
    marginTop: 0,
  },
  icon: {
    color: theme.greyMid1,
    fontSize: 20,
    margin: '10px 0px 10px 6px',
    cursor: 'pointer',
  },
  taxIdWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

const LightReachConfigurationDialog: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Dialog open={true} maxWidth="sm">
      <DialogTitle>
        <div className={classes.titleWrapper}>
          <div>{translate('LightReach Payment Option Configuration')}</div>
          <div>
            <IconButton onClick={props.onClose}>
              <CloseOutlined className={classes.closeButton} />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <LightReachMoreInfo {...props} onClose={props.onClose} />
    </Dialog>
  )
}

export default LightReachConfigurationDialog

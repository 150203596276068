import React from 'react'
import { SalesTerritoryList, SalesTerritoryEdit, SalesTerritoryCreate } from './SalesTerritories'

const subtitle = 'Create Sales Territories to receive leads.'

const SalesTerritoryTitle = ({ record, translate, style }) => {
  return <span style={style}>{translate('Create Sales Territories')}</span>
}

export default {
  list: SalesTerritoryList,
  edit: SalesTerritoryEdit,
  create: SalesTerritoryCreate,
  options: {
    create: {
      title: <SalesTerritoryTitle />,
      subtitle: subtitle,
      breadCrumb: 'Create Sales Territories',
    },
    list: {
      title: 'My Sales Territories',
      subtitle: subtitle,
      breadCrumb: 'Sales Territories',
    },
    edit: {
      title: 'Edit Sales Territories',
      subtitle: subtitle,
      breadCrumb: 'Edit Sales Territories',
    },
  },
}

import { useFeatureFlag } from 'util/split'
import type { HardwareSelectorDialogConfig } from './HardwareSelectorCore'
import useHardwareSelectorV2 from './useHardwareSelectorV2'

type OpenHardwareSelectorCallback = (config: HardwareSelectorDialogConfig) => void

export interface WithHardwareSelectorV2Props {
  enableHardwareDialogV2: boolean
  handleOpenHardwareSelector: OpenHardwareSelectorCallback
  handleCloseHardwareSelector(): void
}

export function withHardwareSelectorV2<T extends WithHardwareSelectorV2Props>(Component: React.ComponentType<T>) {
  return (props: Omit<T, keyof WithHardwareSelectorV2Props>) => {
    const enableHardwareDialogV2 = useFeatureFlag('hardware_dialog_v2', 'on')
    const { Dialog: HardwareSelectorDialogV2, flowController } = useHardwareSelectorV2()

    const handleOpenHardwareSelector: OpenHardwareSelectorCallback = (config) => {
      flowController.openDialog({ config })
    }

    const handleCloseHardwareSelector = () => {
      flowController.closeDialog()
    }

    const newProps = { ...props, enableHardwareDialogV2, handleOpenHardwareSelector, handleCloseHardwareSelector } as T

    return (
      <>
        <Component {...newProps} />
        {enableHardwareDialogV2 && HardwareSelectorDialogV2}
      </>
    )
  }
}

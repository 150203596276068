import { useSelector } from 'react-redux'
import { RootState } from 'types/state'
import ControlMenu from './ControlMenu'
import { useInventoryZoneMenu } from './useInventoryZoneMenu'

import { useFeatureFlag } from '../../util/split'
import SideBarNavigationMenus from './SideBarNavigationMenus'

// Menu categorized by zone based on current design,...
const useMenuByZone = () => {
  const zone = useSelector((state: RootState) => state.zone)
  const InventoryZoneMenu = useInventoryZoneMenu()

  const enableInventoryManagement = useFeatureFlag('inventory_management', 'on')
  const enableHwNewSideBar = useFeatureFlag('hardware_new_sidebar', 'on')

  if (zone === 'control') {
    return ControlMenu
  } else if (enableHwNewSideBar && (zone === 'shop' || zone === 'inventory' || zone === 'manage_orders')) {
    return SideBarNavigationMenus
  } else if (enableInventoryManagement && (zone === 'shop' || zone === 'inventory')) {
    return InventoryZoneMenu
  }
}

export default useMenuByZone

import { EventIdType } from 'amplitude/amplitude'
import { getBackendLogger } from 'loggers/registry'
import { BackendKey } from 'loggers/types'
import { createContext, useCallback, useContext } from 'react'

type LogEventParams = {
  eventKey: string
  eventType: string
  eventConfig: Record<string, any>
  additionalData: Record<string, any>
}

const TrackingContext = createContext((params: LogEventParams) => {})

export const TrackingProvider = ({ children }) => {
  const logEvent = useCallback(({ eventKey, eventType, additionalData, eventConfig }: LogEventParams) => {
    const shouldTrack = eventConfig && !eventConfig.block

    if (shouldTrack) {
      const {
        sampling_rate: samplingRate = 1.0,
        backends = ['AMPLITUDE'],
        event_name: baseName = 'unknown',
      } = eventConfig

      if (Math.random() < samplingRate) {
        const eventData = { eventType, ...additionalData, ...eventConfig }

        backends.forEach((backend: BackendKey) => {
          const logger = getBackendLogger(backend)
          if (logger) {
            logger(eventKey as EventIdType, eventType, eventData, baseName)
          } else {
            console.warn(`Logger not found for backend: ${backend}`)
          }
        })
      }
    }
  }, [])

  return <TrackingContext.Provider value={logEvent}>{children}</TrackingContext.Provider>
}

export const useTracking = () => useContext(TrackingContext)

import { logAmplitudeEvent } from 'amplitude/amplitude'
import { LegacyDataProvider, Notify } from 'ra-core'
import appStorage from 'storage/appStorage'
import { CreditLimitType, HdmCreateCustomerResultType, HdmOrderResultType, HdmOrderType } from './type'

class HdmService {
  private restClientInstance: LegacyDataProvider
  private notify: Notify

  constructor(restClientInstance: LegacyDataProvider, notify: Notify) {
    this.restClientInstance = restClientInstance
    this.notify = notify
  }

  creditLimit = async (): Promise<CreditLimitType | undefined> => {
    const response = await this.restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${appStorage.getOrgId()}/hdm_api/company_credit_limit/`,
    }).catch(() => {
      logAmplitudeEvent('hardware_hdm_service_failed', {
        context: 'credit_limit',
      })
    })
    // Disconnected from HDM.
    if ([401, 500].includes(response?.data?.status_code)) {
      return undefined
    }

    return response?.data
  }

  placeOrder = async (order: HdmOrderType): Promise<HdmOrderResultType> => {
    const response = await this.restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${appStorage.getOrgId()}/hdm_api/place_order/`,
      data: order,
    }).catch(() => {
      logAmplitudeEvent('hardware_hdm_service_failed', {
        context: 'place_order',
      })
    })

    return response?.data
  }

  placeOrderFromCredit = async (order: HdmOrderType): Promise<HdmOrderResultType> => {
    const response = await this.restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${appStorage.getOrgId()}/hdm_api/place_order_from_credit/`,
      data: order,
    }).catch(() => {
      logAmplitudeEvent('hardware_hdm_service_failed', {
        context: 'place_quote',
      })
    })

    return response?.data
  }

  /**
   * User gets sent an email from HDM with the code to exchange.
   */
  verifyEmail = async (email: string): Promise<boolean> => {
    const response = await this.restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${appStorage.getOrgId()}/hdm_api/verify_email/`,
      data: { customer_email: email },
    }).catch(() => {
      logAmplitudeEvent('hardware_hdm_service_failed', {
        context: 'connect_user_verify_email',
      })
    })

    const msg = response?.data?.msg
    if (msg && msg === 'Please check your email') {
      return true
    }
    return false
  }

  exchangeVerificationCode = async (code: string): Promise<string> => {
    const response = await this.restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${appStorage.getOrgId()}/hdm_api/verification_code/`,
      data: { verification_code: code },
    }).catch(() => {
      logAmplitudeEvent('hardware_hdm_service_failed', {
        context: 'connect_user_verification_code',
      })
    })
    return response?.data?.key
  }

  createCustomer = async ({
    email,
    firstname,
    lastname,
  }: {
    email: string
    firstname: string
    lastname: string
  }): Promise<HdmCreateCustomerResultType> => {
    const response = await this.restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${appStorage.getOrgId()}/hdm_api/customer_create/`,
      data: { email, firstname, lastname },
    }).catch(() => {
      logAmplitudeEvent('hardware_hdm_service_failed', {
        context: 'connect_user_create_customer',
      })
    })
    return response?.data
  }
}

export default HdmService

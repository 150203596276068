var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useInput, useReferenceInputController, warning as warningLog } from 'ra-core';
import { Children, cloneElement } from 'react';
import LinearProgress from '../layout/LinearProgress';
import Labeled from './Labeled';
import ReferenceError from './ReferenceError';
import sanitizeInputProps from './sanitizeRestProps';
var ReferenceInput = function (_a) {
    var format = _a.format, onBlur = _a.onBlur, onChange = _a.onChange, onFocus = _a.onFocus, parse = _a.parse, validate = _a.validate, props = __rest(_a, ["format", "onBlur", "onChange", "onFocus", "parse", "validate"]);
    var inputProps = useInput(__assign({ format: format, onBlur: onBlur, onChange: onChange, onFocus: onFocus, parse: parse, validate: validate }, props));
    //@ts-ignore
    return _jsx(ReferenceInputView, __assign({}, inputProps, props, useReferenceInputController(__assign(__assign({}, props), inputProps))));
};
// ReferenceInput.propTypes = {
//   allowEmpty: PropTypes.bool,
//   basePath: PropTypes.string,
//   children: PropTypes.element.isRequired,
//   className: PropTypes.string,
//   classes: PropTypes.object,
//   filter: PropTypes.object,
//   filterToQuery: PropTypes.func.isRequired,
//   postQueryFilterFn: PropTypes.func,
//   label: PropTypes.string,
//   onChange: PropTypes.func,
//   perPage: PropTypes.number,
//   record: PropTypes.object,
//   reference: PropTypes.string.isRequired,
//   resource: PropTypes.string,
//   sort: PropTypes.shape({
//     field: PropTypes.string,
//     order: PropTypes.oneOf(['ASC', 'DESC']),
//   }),
//   source: PropTypes.string,
// }
ReferenceInput.defaultProps = {
    filter: {},
    filterToQuery: function (searchText) { return (searchText ? { q: searchText } : {}); },
    perPage: 20,
    sort: { field: 'id', order: 'DESC' },
};
var sanitizeRestProps = function (_a) {
    var choices = _a.choices, className = _a.className, crudGetMatching = _a.crudGetMatching, crudGetOne = _a.crudGetOne, filter = _a.filter, filterToQuery = _a.filterToQuery, onChange = _a.onChange, perPage = _a.perPage, 
    // reference,
    referenceSource = _a.referenceSource, setFilter = _a.setFilter, setPagination = _a.setPagination, setSort = _a.setSort, sort = _a.sort, validation = _a.validation, rest = __rest(_a, ["choices", "className", "crudGetMatching", "crudGetOne", "filter", "filterToQuery", "onChange", "perPage", "referenceSource", "setFilter", "setPagination", "setSort", "sort", "validation"]);
    return sanitizeInputProps(rest);
};
export var ReferenceInputView = function (_a) {
    var allowEmpty = _a.allowEmpty, basePath = _a.basePath, children = _a.children, choices = _a.choices, classes = _a.classes, className = _a.className, error = _a.error, helperText = _a.helperText, id = _a.id, input = _a.input, isRequired = _a.isRequired, loading = _a.loading, label = _a.label, meta = _a.meta, resource = _a.resource, setFilter = _a.setFilter, setPagination = _a.setPagination, setSort = _a.setSort, source = _a.source, warning = _a.warning, renderChildrenBeforeRecord = _a.renderChildrenBeforeRecord, postQueryFilterFn = _a.postQueryFilterFn, rest = __rest(_a, ["allowEmpty", "basePath", "children", "choices", "classes", "className", "error", "helperText", "id", "input", "isRequired", "loading", "label", "meta", "resource", "setFilter", "setPagination", "setSort", "source", "warning", "renderChildrenBeforeRecord", "postQueryFilterFn"]);
    if (Children.count(children) !== 1) {
        throw new Error('<ReferenceInput> only accepts a single child');
    }
    if (loading && !renderChildrenBeforeRecord) {
        return (_jsx(Labeled, __assign({ id: id, label: label, source: source, resource: resource, className: className, isRequired: isRequired, meta: meta, input: input }, { children: _jsx(LinearProgress, {}) })));
    }
    // This is not a final-form error but an unrecoverable error from the
    // useReferenceInputController hook
    if (error) {
        return _jsx(ReferenceError, { label: label, error: error });
    }
    // When the useReferenceInputController returns a warning, it means there it
    // had an issue trying to load the referenced record
    // We display it by overriding the final-form meta
    var finalMeta = warning
        ? __assign(__assign({}, meta), { error: warning }) : meta;
    // helperText should never be set on ReferenceInput, only in child component
    // But in a Filter component, the child helperText have to be forced to false
    warningLog(helperText !== undefined && helperText !== false, "<ReferenceInput> doesn't accept a helperText prop. Set the helperText prop on the child component instead");
    var disabledHelperText = helperText === false ? { helperText: helperText } : {};
    var filteredChoices = postQueryFilterFn ? choices === null || choices === void 0 ? void 0 : choices.filter(function (choice) { return postQueryFilterFn(choice); }) : choices;
    return cloneElement(children, __assign(__assign({ allowEmpty: allowEmpty, classes: classes, className: className, input: input, isRequired: isRequired, label: label, resource: resource, meta: finalMeta, source: source, choices: filteredChoices, basePath: basePath, setFilter: setFilter, setPagination: setPagination, setSort: setSort, translateChoice: false, loading: loading }, disabledHelperText), sanitizeRestProps(rest)));
};
export default ReferenceInput;

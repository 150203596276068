import React, { useMemo } from 'react'
import AuthFlowLogo, { OpenSolarAuthFlowLogo } from './AuthFlowLogo'
import { legacyLandingPageExistingUrlFragments } from './Configs'
import LazyLoadLandingPageLogo from './LazyLoadLandingPageLogo'

const useLogoByUrlFragment = ({ urlFragment }: { urlFragment?: string }) => {
  return useMemo(() => {
    if (urlFragment === undefined) {
      return <OpenSolarAuthFlowLogo />
    } else if (legacyLandingPageExistingUrlFragments[urlFragment]) {
      return (
        <AuthFlowLogo alt={urlFragment} src={window.PUBLIC_URL + legacyLandingPageExistingUrlFragments[urlFragment]} />
      )
    } else {
      return <LazyLoadLandingPageLogo urlFragment={urlFragment} />
    }
  }, [urlFragment])
}

export default useLogoByUrlFragment

import { Typography } from '@material-ui/core'
import { useTranslate } from 'ra-core'

import React from 'react'
import { ModulePropTypes, ModuleReleaseMessageProgress } from '../types'

export const IAPModule_ReleaseMessageProgress: React.FC<ModulePropTypes<ModuleReleaseMessageProgress>> = ({
  promo,
  module,
  translationSubs,
}) => {
  const translate = useTranslate()

  let message1: string | undefined
  let message2: string | undefined

  message1 = module.msg || 'OpenSolar is getting even better with our latest release.'
  if (promo.buttonURL) {
    // Has release notes
    message2 = module.msgNotes || 'Check out the release notes while you wait.'
  } else {
    // No release notes
    message2 = module.msgNoNotes || 'Please wait until the update is complete.'
  }

  if (!message1 && !message2) return null
  return (
    <p>
      {message1 && (
        <Typography variant="body1" display="inline">
          {translate(message1, translationSubs)}
        </Typography>
      )}{' '}
      {message2 && (
        <Typography variant="body1" display="inline">
          {translate(message2, translationSubs)}
        </Typography>
      )}
    </p>
  )
}

import { orgSelectors } from 'ducks/orgs'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import {
  BlueSnapOnboardingDataResponse,
  BlueSnapOnboardingState,
  BlueSnapOwnerType,
  BlueSnapRepType,
  NormalizedBlueSnapContactType,
  OnboardingDecisionType,
  PSPOnboardingFieldType,
  PSPOnboardingFieldsMapType,
  PSPOnboardingStatusResponseType,
} from './types'

const restClientInstance = restClient(window.API_ROOT + '/api')

export const useGetPspOnboardingData = (pspIntegration = 'bluesnap') => {
  const [allFields, setAllFields] = useState<BlueSnapOnboardingState | undefined>(undefined)

  const orgId = useSelector(orgSelectors.getOrg)?.id

  useEffect(() => {
    if (orgId) {
      restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${orgId}/${pspIntegration}/onboarding/fields/`,
      })
        .then((res: BlueSnapOnboardingDataResponse) => {
          const sortedFields = sortOnboardingFieldsIntoMap(res.data.onboarding_fields)
          let initialPage = !!res.data.last_completed_page ? res.data.last_completed_page + 1 : 0

          // If the page we expect the user to start on doesn't exist then we lower the initialPage var until we get to a page that does exist
          // The only scenario this is protecting against is if we were to remove an entire page of questions when there are outstanding applications
          // which will probably not happen?
          if (!sortedFields[initialPage]) {
            while (initialPage > 0) {
              initialPage -= 1
              if (sortedFields[initialPage]) {
                break
              }
            }
          }

          setAllFields({
            onboarding_fields: sortedFields,
            initialPage: initialPage,
            application_data: res.data.application_data,
          })
        })
        .catch((err) => {
          console.log('err', err)
        })
    }
  }, [orgId])

  return allFields
}

const sortOnboardingFieldsIntoMap = (fieldsArray: PSPOnboardingFieldType[]): PSPOnboardingFieldsMapType => {
  let map: PSPOnboardingFieldsMapType = {}
  fieldsArray?.forEach((field) => {
    // add first element for page number
    if (!map?.[field.page_number]) {
      map[field.page_number] = []
    }

    // add the field to the array for this page number
    map[field.page_number].push(field)
  })

  return map
}

export const getNormalizeBlueSnapContact = (raw: BlueSnapOwnerType | BlueSnapRepType) => {
  const normalized: NormalizedBlueSnapContactType = {
    firstName: undefined,
    lastName: undefined,
    address: undefined,
    state: undefined,
    city: undefined,
    country: undefined,
    dateOfBirth: undefined,
    govID: undefined,
    phone: undefined,
    zip: undefined,
  }
  // @ts-ignore
  if (raw?.ownerFirstName) normalized.firstName = raw.ownerFirstName
  // @ts-ignore
  if (raw?.ownerLastName) normalized.lastName = raw.ownerLastName
  // @ts-ignore
  if (raw?.address) normalized.address = raw.address
  // @ts-ignore
  if (raw?.city) normalized.city = raw.city
  // @ts-ignore
  if (raw?.state) normalized.state = raw.state
  // @ts-ignore
  if (raw?.country) normalized.country = raw.country
  // @ts-ignore
  if (raw?.ownerDateOfBirth) normalized.dateOfBirth = raw.ownerDateOfBirth
  // @ts-ignore
  if (raw?.ownerGovID) normalized.govID = raw.ownerGovID
  // @ts-ignore
  if (raw?.ownerPhone) normalized.phone = raw.ownerPhone
  // @ts-ignore
  if (raw?.zip) normalized.zip = raw.zip

  // @ts-ignore
  if (raw?.companyRepFirstName) normalized.firstName = raw.companyRepFirstName
  // @ts-ignore
  if (raw?.companyRepLastName) normalized.lastName = raw.companyRepLastName
  // @ts-ignore
  if (raw?.companyRepAddress) normalized.address = raw.companyRepAddress
  // @ts-ignore
  if (raw?.companyRepState) normalized.state = raw.companyRepState
  // @ts-ignore
  if (raw?.companyRepCity) normalized.city = raw.companyRepCity
  // @ts-ignore
  if (raw?.companyRepCountry) normalized.country = raw.companyRepCountry
  // @ts-ignore
  if (raw?.companyRepDateOfBirth) normalized.dateOfBirth = raw.companyRepDateOfBirth
  // @ts-ignore
  if (raw?.companyRepGovID) normalized.govID = raw.companyRepGovID
  // @ts-ignore
  if (raw?.companyRepPhone) normalized.phone = raw.companyRepPhone
  // @ts-ignore
  if (raw?.companyRepZip) normalized.zip = raw.companyRepZip

  return normalized
}

export const getContactsAreEqual = (
  contact1: NormalizedBlueSnapContactType,
  contact2: NormalizedBlueSnapContactType
) => {
  if (!contact1 || !contact2) return false
  else
    return (
      contact1.firstName === contact2.firstName &&
      contact1.lastName === contact2.lastName &&
      contact1.address === contact2.address &&
      contact1.city === contact2.city &&
      contact1.country === contact2.country &&
      contact1.dateOfBirth === contact2.dateOfBirth &&
      contact1.govID === contact2.govID &&
      contact1.phone === contact2.phone &&
      contact1.zip === contact2.zip
    )
}

export const getContactErrorMessage = (contact: NormalizedBlueSnapContactType) => {
  if (!contact.firstName) return 'First Name is required'
  else if (!contact.lastName) return 'Last Name is required'
  else if (!contact.address) return 'Address is required'
  else if (!contact.city) return 'City is required'
  else if (!contact.country) return 'Country is required'
  else if (!contact.dateOfBirth) return 'Date of Birth is required'
  else if (!contact.govID) return 'Government ID is required'
  else if (!contact.phone) return 'Phone Number is required'
  else if (!contact.zip) return 'Zip is required'
}

export const contactIsNotEmpty = (obj: Object) => {
  const length = Object.keys(obj)?.length > 0
  if (!length) return false
  else return Object.values(obj)?.some((value) => value !== null && value !== '' && value !== undefined)
}

export const usePollOnboardingDecicision = (
  testDecision: OnboardingDecisionType | null = null,
  pspIntegration = 'bluesnap'
) => {
  const MAX_REQUESTS = 20
  const MS_BETWEEN_REQUESTS = 3000
  const [requestCount, setRequestCount] = useState<number>(0)
  const [decision, setDecision] = useState<OnboardingDecisionType | null>(null)

  const orgId = useSelector(orgSelectors.getOrg)?.id

  const doPoll = useCallback(() => {
    if (orgId && MAX_REQUESTS > requestCount) {
      restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${orgId}/payments/${pspIntegration}/onboarding/status/`,
      })
        .then((res: PSPOnboardingStatusResponseType) => {
          if (res?.data?.status) {
            if (['created', 'submitted'].includes(res?.data?.status)) {
              // the status is not a decision yet, we're still waiting on BlueSnap's webhook so poll again
              setTimeout(() => {
                setRequestCount(requestCount + 1)
              }, MS_BETWEEN_REQUESTS)
            } else {
              // we have a real decision. return it and stop polling
              setDecision(res?.data?.status)
              setRequestCount(MAX_REQUESTS + 1)
            }
          }
        })
        .catch((err) => {
          // if we hit an error just set the decision to pending and we'll reach out to the pro
          setDecision('pending')
        })
    }
  }, [requestCount, setRequestCount, orgId, MS_BETWEEN_REQUESTS, MAX_REQUESTS])

  useEffect(() => {
    if (!decision) {
      if (testDecision) {
        // if this is a test decision then let's just poll 5 times to simulate a waiting state and make sure
        // we're at least not throwing an error when trying to fetch the status. Then set the decision to the desired test result
        if (requestCount < 5) doPoll()
        else setDecision(testDecision)
      } else {
        if (requestCount < MAX_REQUESTS) doPoll()
        else setDecision('pending')
      }
    }
  }, [requestCount, testDecision, decision])

  return decision
}

let errorState = ''
export const setValidationError = (newError) => {
  errorState = newError
}

export const getValidationError = () => {
  return errorState
}

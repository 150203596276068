var styleCache = {};
var styleSheetsLength = 0;
var fillStyleCache = function () {
    var styleSheets = window.document.styleSheets;
    if (styleSheetsLength === styleSheets.length)
        return;
    styleCache = {};
    styleSheetsLength = styleSheets.length;
    for (var i = 0; i < styleSheetsLength; i++) {
        try {
            var classes = styleSheets[i].rules || styleSheets[i].cssRules;
        }
        catch (e) {
            // Stylesheets from other domains are not accessible, skip them
            continue;
        }
        if (!classes)
            continue;
        var classesLength = classes.length;
        for (var x = 0; x < classesLength; x++) {
            var cssRule = classes[x];
            if (!cssRule.selectorText)
                continue;
            var styles = cssRule.selectorText.split(',').map(function (s) { return s.trim(); });
            for (var _i = 0, styles_1 = styles; _i < styles_1.length; _i++) {
                var styleName = styles_1[_i];
                // if (!styleCache[styleName]) styleCache[styleName] = []
                // styleCache[styleName].push({
                //   selectorFull: cssRule.selectorText,
                //   selectorMatched: styleName,
                //   values: cssRule.style,
                // })
                var partials = styleName.split(/[> ]|(\b(?=\.))/).map(function (s) { return ((s === null || s === void 0 ? void 0 : s.trim) ? s.trim() : s); });
                // if (partials.length === 1) continue
                for (var _a = 0, partials_1 = partials; _a < partials_1.length; _a++) {
                    var styleName_1 = partials_1[_a];
                    if (!styleName_1)
                        continue;
                    if (!styleCache[styleName_1])
                        styleCache[styleName_1] = [];
                    styleCache[styleName_1].push({
                        selectorFull: cssRule.selectorText,
                        selectorMatched: styleName_1,
                        values: cssRule.style,
                    });
                }
            }
        }
    }
};
export function getStyleDefinition(className) {
    fillStyleCache();
    return styleCache[className];
}

import Tooltip from '@material-ui/core/Tooltip'
import { ErrorOutlineOutlined } from '@material-ui/icons'
import ExpandLessIcon from '@material-ui/icons/ExpandLessOutlined'
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreOutlined'
import { Button } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: any) => ({
  button: {
    margin: 0,
    padding: 0,
    height: 30,
    minWidth: 'auto',
    verticalAlign: 'bottom',
    color: 'rgb(0,0,0)',
    background: 'rgb(255,255,255)',
    '&$disabled': {
      background: 'rgb(224 224 224)',
    },
    '&:hover': {
      background: 'rgba(250,250,250,0.9)',
    },
    '&:first-child': {
      borderRadius: '4px 0 0 4px',
    },
    '&:last-child': {
      borderRadius: '0 4px 4px 0',
    },
  },
  activeButton: {
    margin: '0 2px',
    padding: 0,
    height: 30,
    minWidth: 'auto',
    color: 'rgb(255,255,255)',
    fill: 'rgb(255,255,255)',
    background: 'rgba(24, 144, 255,0.9)',
    '&$disabled': {
      background: 'rgb(224 224 224)',
    },
    '&:hover': {
      background: 'rgba(24, 144, 255,1)',
    },
  },
  disabled: {},
  startIcon: {
    margin: '2px 2px 2px 5px',
  },
  endIcon: {
    margin: '2px 5px 2px 2px',
  },
  startIconSize: {
    width: 20,
    height: 20,
  },
  endIconSize: {
    width: 18,
    height: 18,
  },
  warningIcon: {
    width: 20,
    height: 20,
    color: theme.alertIcon_warning,
    marginRight: 5,
  },
}))

interface PropTypes {
  label: string
  icon: any
  onClick: () => void
  dropdownOpen: boolean
  disabled: boolean
  id?: string
  displayWarning?: boolean
}

// type RefType = HTMLButtonElement | null
// const ToolbarSelector = React.forwardRef<RefType, PropTypes>((props, ref) => {
//   const translate = useTranslate()
//   const classes = useStyles()
//   const { disabled, label, icon, onClick, dropdownOpen } = props
//   return (
//     <Tooltip PopperProps={{ style: { zIndex: 2001 } }} enterDelay={300} title={translate(label)}>
//       <Button
//         variant="contained"
//         ref={ref}
//         classes={{
//           root: dropdownOpen ? classes.activeButton : classes.button,
//           startIcon: classes.startIcon,
//           endIcon: classes.endIcon,
//           disabled: classes.disabled,
//         }}
//         disabled={disabled}
//         startIcon={React.createElement(icon, { className: classes.icon })}
//         onClick={onClick}
//         endIcon={
//           dropdownOpen ? <ExpandLessIcon className={classes.icon} /> : <ExpandMoreIcon className={classes.icon} />
//         }
//       >
//         {dropdownOpen && translate(label)}
//       </Button>
//     </Tooltip>
//   )
// })

const ToolbarSelector: React.FC<PropTypes> = ({
  disabled,
  label,
  icon,
  onClick,
  dropdownOpen,
  id,
  displayWarning = false,
}) => {
  const translate = useTranslate()
  const classes = useStyles()
  return (
    <Tooltip PopperProps={{ style: { zIndex: 2001 } }} enterDelay={300} title={translate(label)}>
      <Button
        variant="contained"
        classes={{
          root: dropdownOpen ? classes.activeButton : classes.button,
          startIcon: classes.startIcon,
          endIcon: classes.endIcon,
          disabled: classes.disabled,
        }}
        disabled={disabled}
        id={id}
        startIcon={React.cloneElement(icon, { className: classes.startIconSize })}
        onClick={onClick}
        endIcon={
          dropdownOpen ? (
            <ExpandLessIcon className={classes.endIconSize} />
          ) : (
            <ExpandMoreIcon className={classes.endIconSize} />
          )
        }
      >
        {displayWarning && <ErrorOutlineOutlined classes={{ root: classes.warningIcon }} />}
        {dropdownOpen && <span>{translate(label)}</span>}
      </Button>
    </Tooltip>
  )
}

export default ToolbarSelector

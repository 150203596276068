import { Divider, ListItem } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

// const tertiaryStyle = { float: 'right', opacity: 0.541176 }

const SimpleList = ({ ids, data, basePath, primaryText, secondaryText, rightIcon, linkTo }) => (
  <>
    <Divider />
    {ids.map((id) => (
      <span key={id}>
        <ListItem>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              margin: '10px 0',
              alignItems: 'center',
            }}
          >
            <div>
              <div style={{ color: 'rgb(77, 77, 77)', fontSize: 16, marginBottom: 10 }}>
                {primaryText(data[id], id)}
              </div>
              <div style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 14 }}>
                {secondaryText && secondaryText(data[id], id)}
              </div>
            </div>
            {rightIcon && <Link to={linkTo(basePath, id)}> {rightIcon(data[id], id)}</Link>}
          </div>
        </ListItem>
        <Divider />
      </span>
    ))}
  </>
)

SimpleList.propTypes = {
  ids: PropTypes.array,
  data: PropTypes.object,
  basePath: PropTypes.string,
  primaryText: PropTypes.func,
  secondaryText: PropTypes.func,
  secondaryTextLines: PropTypes.number,
  tertiaryText: PropTypes.func,
  leftAvatar: PropTypes.func,
  leftIcon: PropTypes.func,
  rightAvatar: PropTypes.func,
  rightIcon: PropTypes.func,
}

export default SimpleList

import { authSelectors } from 'ducks/auth'
import { plural } from 'elements/hardwareSelector/HardwareSelector'
import { useCallback, useState } from 'react'
import { useDataProvider } from 'react-admin'
import { useSelector } from 'react-redux'
import { ComponentTypes } from 'types/selectComponent'

type ActivateComponentArgs = {
  componentType: ComponentTypes
  componentId: number
}

const useActivateComponent = <T>() => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dataProvider = useDataProvider()
  const orgId = useSelector(authSelectors.getOrgId)
  const activateComponent = useCallback(async ({ componentType, componentId }: ActivateComponentArgs) => {
    setIsLoading(true)
    const response = await dataProvider.CUSTOM_POST('custom', {
      url: `orgs/${orgId}/component_${componentType}_activations/`,
      data: {
        [componentType]: '/api/component_' + plural(componentType) + '/' + componentId + '/',
      },
    })
    setIsLoading(false)
    return response.data as T
  }, [])
  return activateComponent
}

export default useActivateComponent

import { makeStyles } from '@material-ui/core'
import { Theme } from 'types/themes'

export const COLOR_LEGEND_CONFIGS = {
  unavailable: 'rgba(146, 146, 146, 0.75)',
  available: 'rgba(230, 230, 230, 1)',
  assigned: 'rgba(52, 133, 49, 0.75)',
  //uses mix blend color code
  //temporary until ranges are fixed
  overlapping: 'rgb(90,164,91)',
  assignedBlend: '#87E97F',
}

export const dialogStyles = makeStyles<Theme, {}>(
  (theme) => {
    return {
      dialogWidth: {
        '& .MuiDialog-paperWidthSm': {
          maxWidth: 1000,
        },
        '& .MuiDialogContent-root': {
          padding: '30px 50px',
        },
      },
      dialogHeader: {
        fontSize: 24,
        marginBottom: 16,
      },
      button: {
        '&:hover': {
          cursor: 'pointer',
          background: theme.themeColorDark,
        },

        position: 'relative',
        padding: '4px 10px',
        margin: 10,
        width: 100,
        background: theme.themeColor,
        color: theme.headerFontColor,
      },
      row: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
          margin: 3,
        },
      },
      square: {
        width: 24,
        height: 24,
        marginRight: 5,
        borderRadius: 5,
      },
      alertWarning: {
        position: 'absolute',
      },
      summaryTable: {
        '& .MuiTableCell-root': {
          padding: 8,
          borderColor: theme.greyDark2,
        },
      },
      removeBottomBorder: {
        '& .MuiTableCell-root': {
          borderBottom: 'none',
        },
      },
      warning: {
        '& .MuiSvgIcon-root': {
          fill: theme.alertIcon_warning,
        },
      },
      spanRight: {
        marginLeft: 'auto',
      },
      summaryContainer: {
        margin: '18px 0',
        display: 'flex',
        alignItems: 'start',
      },
      removeMargin: {
        margin: 0,
      },
      bgUnavailable: {
        backgroundColor: COLOR_LEGEND_CONFIGS.unavailable,
      },
      bgAvailable: {
        backgroundColor: COLOR_LEGEND_CONFIGS.available,
      },
      bgAssigned: {
        backgroundColor: COLOR_LEGEND_CONFIGS.assignedBlend,
      },
      bgOverlapping: {
        backgroundColor: COLOR_LEGEND_CONFIGS.overlapping,
      },
      removeTopPadding: {
        paddingTop: 0,
      },
      saveError: {
        color: theme.alertIcon_error,
      },
    }
  },
  { name: 'ScheduleDialog' }
)

export const dateRangeStyles = makeStyles<Theme, {}>(
  (theme) => {
    return {
      row: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
          margin: 3,
        },
      },
      dateRangeBar: {
        width: '100%',
        height: 40,
        backgroundColor: COLOR_LEGEND_CONFIGS.available,
        position: 'relative',
      },
      dateRangeLabels: {
        '& p': {
          textTransform: 'uppercase',
          color: '#A8A8A8',
          margin: '0 !important',
          // borderLeft: '1px solid #D9D9D9',
          textAlign: 'center',
          fontSize: 12,
        },
      },
      dateRangeUnavailable: {
        position: 'absolute',
        height: 40,
        backgroundColor: COLOR_LEGEND_CONFIGS.unavailable,
      },
      dateRangeAssignedWrapper: {
        position: 'absolute',
        height: 50,
        borderLeft: `1px solid black`,
        borderRight: `1px solid black`,
        bottom: 0,
      },
      dateRangeAssigned: {
        marginTop: 10,
        height: 40,
        backgroundColor: COLOR_LEGEND_CONFIGS.assigned,
        mixBlendMode: 'hard-light',
      },
      dateRangeOverlapping: {
        marginTop: 10,
        height: 40,
        backgroundColor: COLOR_LEGEND_CONFIGS.overlapping,
      },
      dateRangeInput: {
        alignItems: 'baseline',
        justifyContent: 'space-between',
      },
      noHovered: {
        opacity: 0.5,
      },
      clearBtn: {
        padding: 0,
        margin: 0,
      },
      pointLabel: {
        position: 'absolute',
        top: -32,
        width: 50,
        '& p': {
          margin: 0,
          transform: 'rotate(-90deg) translate(10%, 0)',
        },
      },
      dateRangeContainer: {
        marginTop: 70,
      },
      dateRangeInputContainer: {
        marginTop: 20,
      },
      addDateRange: {
        margin: '15px 0',
      },
      removeMargin: {
        margin: 0,
      },
    }
  },
  { name: 'ScheduleDateRange' }
)

export const scheduleSelectorStyles = makeStyles<Theme, {}>(
  (theme) => {
    return {
      row: {
        display: 'flex',
        alignItems: 'center',
        userSelect: 'none',
      },
      column: {
        display: 'flex',
        flexDirection: 'column',
      },
      timeSlot: {
        border: '1px solid ' + COLOR_LEGEND_CONFIGS.unavailable,
        height: 40,
        width: '100%',
      },
      labelContainer: {
        left: -3,
        top: '100%',
        position: 'absolute',
      },
      timeLabel: {
        margin: 0,
        transformOrigin: '0 50%',
        transform: 'rotate(-90deg) translate(-100%, 0)',
        textWrap: 'nowrap',
      },
      selectorContainer: {
        width: '100%',
        position: 'relative',
      },
      selectionBox: {
        position: 'absolute',
        border: '1px dashed rgba(0, 0, 0, 0.5)',
      },
      justifyCenter: {
        justifyContent: 'center',
      },
      labelSlot: {
        borderLeft: '1px solid black',
        borderRight: '1px solid black',
        height: 15,
        position: 'relative',
        width: '100%',
      },
      endBorder: {
        borderRightWidth: 2,
      },
      endTimeLabel: {
        width: 0,
        marginLeft: -1,
      },
      startBorder: {
        borderLeftWidth: 2,
      },
      dayLabel: {
        userSelect: 'none',
        marginRight: 'auto',
        width: 50,
      },
      dayRow: {
        height: 40,
      },
      slotsRow: {
        flexGrow: 1,
        flexShrink: 1,
      },
      labelRow: {
        display: 'flex',
      },
      timeLabels: {
        alignItems: 'start',
        marginTop: 1,
      },
    }
  },
  { name: 'ScheduleSelector' }
)

// @ts-nocheck
import { Checkbox } from 'opensolar-ui'
import React, { ChangeEvent } from 'react'
import { useTranslate } from 'react-admin'
import { DataGridProjectDataType } from './types'

type PropTypes = {
  onSelectAll: (e: ChangeEvent, projectIds: number[]) => void
  selectedAll: boolean
  data: DataGridProjectDataType
}

const SwapPaymentOptionsTableHeader: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const onSelect = (e: ChangeEvent) => {
    props.onSelectAll(e, props.data)
  }
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '6px, 16px, 6px, 10px',
        backgroundColor: '#ececec',
        fontWeight: 900,
        fontSize: '14px',
      }}
    >
      <div style={{ flex: 1, paddingLeft: '10px' }}>
        <Checkbox checked={props.selectedAll} onChange={onSelect} />
      </div>
      <div style={{ flex: 4 }}>Project</div>
      <div style={{ flex: 2 }}>Price per system/payment option</div>
      <div style={{ flex: 1, textAlign: 'right', paddingRight: '6px' }}>Result</div>
    </div>
  )
}
export default SwapPaymentOptionsTableHeader

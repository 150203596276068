import Chip from 'elements/chip/Chip'
import { useTranslate } from 'ra-core'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  menuItemWrapper: ({ clickable }: { clickable: boolean }) => ({
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
    alignItems: 'center',
    columnGap: '15px',
    height: '40px',
    border: theme.border.default,
    borderRadius: '4px',
    margin: '10px 0',
    padding: '5px 0',
    justifyContent: 'space-between',
    '&:hover': {
      cursor: clickable ? 'pointer' : 'default',
      background: clickable ? theme.greyLight1 : 'inherit',
    },
  }),
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '25%',
    paddingLeft: 5,
  },
  image: {
    height: 'fit-content',
    maxHeight: '100%',
    maxWidth: '100%',
  },
  label: {
    width: '75%',
    whiteSpace: 'pre-wrap',
    lineHeight: 'normal',
    paddingRight: 5,
  },
  chip: {
    cursor: 'inherit',
  },
}))

const ReusableSystemCapsule = ({
  config,
  onClick,
}: {
  config: { imageFile: string; displayName: string; automated?: boolean }
  onClick?(): void
}) => {
  const classes = useStyles({ clickable: !!onClick })
  const translate = useTranslate()

  return (
    <div className={classes.menuItemWrapper} onClick={onClick}>
      <div className={classes.imageWrapper}>
        <img src={`${window.PUBLIC_URL}/images/${config.imageFile}`} className={classes.image} />
      </div>
      <div className={classes.label}>
        <div>{config.displayName}</div>
        {config.automated && (
          <Chip className={classes.chip} label={translate('Automated')} size="small" variant="info" />
        )}
      </div>
    </div>
  )
}

export default ReusableSystemCapsule

const formatTariffSubmitValues = (submitValues: any) => {
  const customTariff = submitValues.utility_tariff_current_custom
  if (customTariff && typeof customTariff !== 'string') {
    submitValues['utility_tariff_current_custom'] = JSON.stringify(customTariff)
  }
  const proposedCustomTariff = submitValues.utility_tariff_proposed_custom
  if (proposedCustomTariff && typeof proposedCustomTariff !== 'string') {
    submitValues['utility_tariff_proposed_custom'] = JSON.stringify(proposedCustomTariff)
  }
  return submitValues
}

export default formatTariffSubmitValues

import { AutoAddElectricalResults, useAutoAddElectrical } from 'elements/electrical/autoAddElectrical'
import { useCallback } from 'react'
import { StudioSystemType } from 'types/studio/items'
import { mapComponentTypesV2ToV1 } from 'util/misc'
import type { WizardComponentLikeType } from '../wizardUtils/types'
import useMaybeActivateAndReload from '../wizardUtils/useMaybeActivateAndReload'

type SelectInverterSetProps = {
  componentsSet: WizardComponentLikeType[]
  invertersDesignData: any
}

const clearSystemInverters = ({ systemUuid }: { systemUuid: string }) => {
  const system = systemUuid && window.editor.objectByUuid(systemUuid)
  const inverters = system?.inverters() || []
  var commandUUID = window.Utils.generateCommandUUIDOrUseGlobal()
  const mppts = inverters.flatMap((inverter) => inverter.mppts())

  // have to delete mppt first otherwise non-parent mppt throws error in react components
  mppts.forEach((component) => {
    window.editor.execute(new window.RemoveObjectCommand(component, true, false, commandUUID))
  })

  inverters.forEach((component) => {
    window.editor.execute(new window.RemoveObjectCommand(component, true, false, commandUUID))
  })
}

const useSelectInverterDesignAssistant = ({ system, onClose }: { system: StudioSystemType; onClose(): void }) => {
  const maybeActivateAndReload = useMaybeActivateAndReload()
  const { autoAddElectrical } = useAutoAddElectrical(system)

  return useCallback(async ({ componentsSet, invertersDesignData }: SelectInverterSetProps) => {
    await maybeActivateAndReload({
      componentsSet,
    })

    clearSystemInverters({ systemUuid: system.uuid })

    // Inject activated inverter id into inverter design data. We do this since invertersDesignData returns the activation ids
    // for each inverter as null. This is by design since non-activated inverters returned in the component selector will not have
    // an activation id until it has been selected and activated.
    invertersDesignData.forEach((inverterData) => {
      componentsSet.forEach((component) => {
        if (component.id === inverterData.inverter_id) {
          const activateComponentData = window.AccountHelper.getComponentActivationFromCode(
            component.code,
            mapComponentTypesV2ToV1(component.type)
          )
          inverterData.inverter_id = activateComponentData?.id
          inverterData.inverter_activation_id = activateComponentData?.id
        }
      })
    })

    const systemDataForClusters = {
      inverters: invertersDesignData,
    }

    // Default for all selected inverters.
    const inverterExtraProps = {
      string_isolation: 'seperately',
    }

    const { invertersAdded } = window.SceneHelper.buildSystemForModuleClustersAndSystem(
      window.editor,
      system.uuid,
      systemDataForClusters,
      inverterExtraProps
    )

    const promises: Promise<AutoAddElectricalResults>[] = []
    for (const inverter of invertersAdded) {
      promises.push(
        new Promise((resolve) =>
          autoAddElectrical(inverter, inverter.userData.string_isolation === 'parallel', resolve)
        )
      )
    }

    await Promise.all(promises)

    onClose()
  }, [])
}

export default useSelectInverterDesignAssistant

// @ts-nocheck
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'

type PropTypes = {
  isOpen: boolean
  onClose: () => void
  studioMode: boolean
  editRoleMode: boolean
  onTabOpened: () => void
  roleId?: number
  setLoading: (val: boolean) => void
}

const BrighteAccountDialog: React.FunctionComponent<PropTypes> = (props) => {
  const [loading, setLoading] = useState<boolean>(false)

  const role = useSelector(authSelectors.getCurrentRole)
  const orgId = useSelector(authSelectors.getOrgId)

  useEffect(() => {
    if (props.isOpen) {
      if (props.studioMode) logAmplitudeEvent('brighte_connect_account_dialog_opened', { page: 'studio' })
      else if (props.editRoleMode) logAmplitudeEvent('brighte_connect_account_dialog_opened', { page: 'edit_role' })
      else logAmplitudeEvent('brighte_connect_account_dialog_opened', { page: 'api_keys' })
    }
  }, [props.isOpen, props.studioMode, props.editRoleMode])

  useEffect(() => {
    if (props.studioMode && props.isOpen) {
      submit()
    }
  }, [props.studioMode, props.isOpen])

  const submit = () => {
    setLoading(true)
    const restClientInstance = restClient(window.API_ROOT + '/api')
    let roleId = props.roleId ? props.roleId : role.id
    let url = 'orgs/' + orgId + '/roles/' + roleId + '/brighte/oauth/'
    // this additional param just makes us not fetch products
    if (props.studioMode) url += '?studio_mode=true'
    else if (props.editRoleMode) url += '?edit_role_mode=true'
    restClientInstance('CUSTOM_GET', 'custom', {
      url: url,
    })
      .then((response: any) => {
        if (response.data?.success) {
          if (props.studioMode) {
            logAmplitudeEvent('brighte_oauth_started', { page: 'studio' })
            // @ts-ignore
            props.onTabOpened()
            window.open(response.data.url, '_blank').focus()
          } else if (props.editRoleMode) {
            logAmplitudeEvent('brighte_oauth_started', { page: 'edit_role' })
            window.location.href = response.data.url
          } else {
            logAmplitudeEvent('brighte_oauth_started', { page: 'api_keys' })
            window.location.href = response.data.url
          }
        }
      })
      .catch((error: any) => {
        console.log('error', error)
        if (props.studioMode) logAmplitudeEvent('brighte_oauth_error', { page: 'studio' })
        else if (props.editRoleMode) logAmplitudeEvent('brighte_oauth_error', { page: 'edit_role' })
        else logAmplitudeEvent('brighte_oauth_error', { page: 'api_keys' })
      })
      .finally(() => {
        setLoading(false)
        props.setLoading(false)
      })
  }

  if (props.studioMode) {
    return null
  }
  return (
    <Dialog open={props.isOpen} onClose={props.onClose}>
      <DialogTitle>Start Selling Brighte!</DialogTitle>
      <DialogContent
        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
      >
        <img src={`${window.PUBLIC_URL}/images/brighte_logo.png`} height="50" />
        <p style={{ textAlign: 'center' }}>
          The first step in activating your Brighte integration is connecting your Brighte account to OpenSolar.
          Clicking "Connect Account" below will:
        </p>
        <ul>
          <li>Bring you to Brighte's portal where you can sign in</li>
          <li>
            Once you've signed in you'll be asked to grant permissions to OpenSolar so we can create and manage
            applications with your user
          </li>
          <li>
            You'll then be brought back to OpenSolar and your Brighte payment options will be automatically saved in
            your OpenSolar account
          </li>
        </ul>
        <div style={{ borderRadius: '5px', backgroundColor: '#fcf4c5', opacity: 0.7, marginBottom: '15px' }}>
          <p style={{ textAlign: 'center', color: 'black', margin: '0px 10px', padding: '4px' }}>
            Please note, OpenSolar does not have access to the dealer fees associated with your Brighte payment options.
            If you would like to include dealer fees in pricing please manually add the dealer fees after your Brighte
            payment options are imported.
          </p>
        </div>
      </DialogContent>
      <DialogActions>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: '20px',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <ProUXButton type="secondary" onClick={props.onClose} label="Dismiss" />
          <ProUXButton type="primary" onClick={submit} label="Connect Account" loading={loading} />
        </div>
      </DialogActions>
    </Dialog>
  )
}
export default BrighteAccountDialog

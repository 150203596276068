import { HelpLink } from 'elements/link/HelpLink'
import React from 'react'
import { useTranslate } from 'react-admin'
import { CustomFieldCreate, CustomFieldEdit, CustomFieldList } from './CustomFields'

const CustomFieldTitle = ({ record, style }) => {
  const translate = useTranslate()
  return (
    <span style={style}>
      {translate('Custom Fields')}: {record ? `"${record.title}"` : ''}
    </span>
  )
}

const Subtitle = () => {
  const translate = useTranslate()
  return (
    <span>
      {translate(
        'Create multiple custom fields for your projects and customer contacts to suit your business workflow. You can also create new COGS fields that appear in cost information templates that can help you customise your cost buildup labelling.'
      )}
      <HelpLink href="https://support.opensolar.com/hc/en-us/articles/4411889716761" />
    </span>
  )
}

export default {
  list: CustomFieldList,
  edit: CustomFieldEdit,
  create: CustomFieldCreate,
  options: {
    create: {
      title: 'Create Custom Field',
      subtitle: null,
      breadCrumb: 'Create Custom Field',
    },
    list: {
      title: 'Custom Fields',
      subtitle: <Subtitle />,
      breadCrumb: 'Custom Fields',
    },
    edit: {
      title: <CustomFieldTitle />,
      subtitle: null,
      breadCrumb: 'Edit Custom Field',
    },
  },
}

import { makeStyles } from '@material-ui/core'
import React from 'react'
import { formatCurrency } from 'util/misc'

const useStyles = makeStyles({
  warning: {
    textAlign: 'center',
    backgroundColor: '#ffda00',
    opacity: 0.7,
    color: 'black',
    marginBottom: '10px',
    padding: '2px 10px',
    borderRadius: '5px',
  },
})

type PropTypes = {
  pricePayable: number
}
const PhoenixDownPaymentWarning: React.FC<PropTypes> = (props) => {
  const classes = useStyles()

  if (!props.pricePayable) return null
  if (props.pricePayable > 18000) {
    return (
      <div className={classes.warning}>
        <p>This system is above the maximum allowable system price for finance of £18,000.</p>
      </div>
    )
  } else {
    let depositNeeded = props.pricePayable - 15000
    if (depositNeeded < 0) depositNeeded = 0
    if (depositNeeded <= 0) return null
    return (
      <div className={classes.warning}>
        <p>
          This system is above the maximum allowable finance amount of £15,000. When you start your application for
          finance you will be asked to enter a deposit of <strong>at least £{formatCurrency(depositNeeded)}.</strong>
        </p>
      </div>
    )
  }
}
export default PhoenixDownPaymentWarning

export const TIME_PERIOD_INVENTORY_FILTER_OPTIONS = [
  {
    label: '1 week',
    value: 7,
  },
  {
    label: '2 weeks',
    value: 14,
  },
  {
    label: '3 weeks',
    value: 21,
  },
  {
    label: '4 weeks',
    value: 28,
  },
  {
    label: '6 weeks',
    value: 42,
  },
  {
    label: '8 weeks',
    value: 56,
  },
  {
    label: '12 weeks',
    value: 84,
  },
]

import { LOCALES_SUPPORTED_CUSTOMER_FACING } from 'App.js'

export const GENDER_CHOICES = [
  { id: 0, name: 'Not Specified' },
  { id: 1, name: 'Female' },
  { id: 2, name: 'Male' },
]

export const STAGE_CHOICES = [
  { id: 0, name: 'Designing' },
  { id: 1, name: 'Selling' },
  { id: 2, name: 'Installing' },
  { id: 3, name: 'Maintaining' },
  { id: 4, name: 'Other' },
]

export const MILESTONE_CHOICES = [
  {
    id: 0,
    name: 'Unsold',
    milestoneUrl: 'inventory/projects/?milestone=0,1,2,3&ordering=-id&project_installed=false&project_sold=false',
  },
  {
    id: 1,
    name: 'Sold (Not Installed)',
    milestoneUrl: 'inventory/projects/?milestone=0,1,2,3&ordering=-id&project_installed=false&project_sold=true',
  },
  {
    id: 2,
    name: 'Installed',
    milestoneUrl: 'inventory/projects/?milestone=0,1,2,3&ordering=-id&project_installed=true',
  },
  {
    id: 3,
    name: 'Other',
    milestoneUrl: 'inventory/projects/?milestone=4&ordering=-id',
  },
]

export const PRIORITY_CHOICES = [
  { id: 1, name: 'Low' },
  { id: 2, name: 'Medium' },
  { id: 3, name: 'High' },
]

export const PROJECT_TYPE_CHOICES = [
  { id: 0, name: 'OpenSolar' },
  { id: 1, name: 'OpenSolar Lite' },
]

export const getLanguageChoices = () => {
  return [
    { id: null, name: 'Default' },
    ...Object.entries(LOCALES_SUPPORTED_CUSTOMER_FACING).map(([key, value]) => ({ id: key, name: value })),
  ]
}

export const HAS_CELLULAR_COVERAGE_CHOICES = [
  { id: 0, name: 'Not Set' },
  { id: 1, name: 'Yes' },
  { id: 2, name: 'No' },
]

export const ACCESS_CHOICES = [
  { id: 2, name: 'Restricted' },
  { id: 1, name: 'Unrestricted' },
]

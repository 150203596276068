import { Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/AddOutlined'
import ScreenRotationIcon from '@material-ui/icons/ScreenRotationOutlined'
import { useViewShow, viewModeSelectors } from 'ducks/viewMode'
import { Button } from 'opensolar-ui'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { Theme } from 'types/themes'

const useStyles = makeStyles<Theme, { size: Sizes; active: boolean }>((theme) => {
  return {
    icon: ({ size }) => {
      return {
        '&.MuiSvgIcon-root': {
          padding: 0,
          margin: '0 auto',
          paddingTop: size === 'normal' ? 6 : 0,
          width: size === 'normal' ? 20 : 25,
          height: size === 'normal' ? 20 : 25,
          [theme.breakpoints.down(size === 'normal' ? 'sm' : 'xs')]: {
            textTransform: 'none',
            width: 21,
            height: 21,
            paddingTop: 0,
          },
        },
      }
    },
    root: ({ size, active }) => {
      return {
        backgroundColor: active ? theme.themeColorDark : theme.themeColor,
        width: size === 'normal' ? 69 : 45,
        minWidth: size === 'normal' ? 69 : 45,
        height: size === 'normal' ? 66 : 45,
        lineHeight: 28,
        margin: 4,
        position: 'absolute',
        left: size === 'normal' ? 439 : 337,
        top: 4,
        [theme.breakpoints.down(size === 'normal' ? 'sm' : 'xs')]: {
          textTransform: 'none',
          top: size === 'normal' ? 72 : 'unset',
          right: size === 'normal' ? 'unset' : 20,
          left: size === 'normal' ? 6 : 'unset',
          bottom: size === 'normal' ? 'unset' : -40,
          margin: 0,
          height: 33,
          width: 90,
        },
        '&:hover': {
          background: active ? theme.themeColorDark : theme.themeColorLight,
        },
        boxShadow:
          size === 'normal'
            ? ''
            : '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      }
    },
    startIcon: ({ size }) => {
      return {
        width: 'inherit',
        height: 'inherit',
        margin: 0,
        [theme.breakpoints.down(size === 'normal' ? 'sm' : 'xs')]: {
          width: 21,
        },
      }
    },
    label: {
      height: '100%',
    },
    labelText: ({ size }) => {
      return {
        padding: 0,
        position: 'absolute',
        width: '100%',
        textAlign: 'center',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        top: size === 'normal' ? 36 : 32,
        fontSize: size === 'normal' ? 14 : 8,
        display: 'block',
        lineHeight: 'initial',
        [theme.breakpoints.down(size === 'normal' ? 'sm' : 'xs')]: {
          position: 'static',
          fontSize: 12,
        },
      }
    },
    toggle: ({ size }) => {
      return {
        backgroundColor: 'rgba(255,255,255, 0.9)',
        width: 45,
        minWidth: 45,
        height: 45,
        lineHeight: '28px',
        margin: 4,
        position: 'absolute',
        left: '390px',
        top: '4px',
        [theme.breakpoints.down(size === 'normal' ? 'sm' : 'xs')]: {
          fontSize: 10,
          top: 'unset',
          textTransform: 'none',
          right: 20,
          left: 'unset',
          bottom: -80,
          margin: 0,
          height: 33,
          width: 90,
        },
        '&:hover': {
          background: 'rgba(255, 255, 255, 0.5)',
        },
        boxShadow:
          '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      }
    },
    labelSmaller: {
      padding: 0,
      position: 'absolute',
      width: '100%',
      textAlign: 'center',
      top: 32,
      fontSize: '7px',
      display: 'block',
      lineHeight: '10px',
      [theme.breakpoints.down('xs')]: {
        position: 'static',
        fontSize: 10,
        paddingLeft: 12,
      },
    },
  }
})

interface AddPanelButtonProps {
  hideOrientationButton?: boolean
  size?: Sizes
  disabled?: boolean
}

type Sizes = 'small' | 'normal'

const AddPanelButton: React.FC<AddPanelButtonProps> = ({ hideOrientationButton, size = 'small', disabled = false }) => {
  const translate = useTranslate()
  // const isActive = useMemo(() => !!window.editor?.controllers?.FingerPaint?.active)
  const [isActive, setIsActive] = useState(false)
  const isUserLite = useSelector(viewModeSelectors.isUserLite)

  const handler = () => {
    setIsActive(!!window.editor?.controllers?.FingerPaint?.active)
  }

  useEffect(() => {
    setIsActive(!!window.editor?.controllers?.FingerPaint?.active)
    const signal = window.editor?.controllers?.FingerPaint?.activeChanged
    if (!signal) return
    signal.add(handler)
    return () => signal.remove(handler)
  })

  const isFingerPaintAvailable = !!(window.editor?.metersPerPixel() < 0.5)
  const isLandscape = window.editor?.controllers?.FingerPaint?.panelOrientation === 'landscape'

  const classes = useStyles({ size, active: isActive })

  const onClick = useCallback(() => {
    if (isUserLite) window.editor.deselect()

    if (!isFingerPaintAvailable) {
      window.Designer.showNotification(translate('Zoom in closer to add panels'), 'warning')
      return
    }

    if (isActive) {
      window.editor.signals.escapePressed.dispatch()
    } else {
      window.ViewHelper.setViewBoxEditMode(false)
      window.Designer.startPlacementMode('Paint Modules (P)')
    }
  }, [isFingerPaintAvailable, isActive])


  const show = useViewShow('studio.tabs.panels.add')
  if (!show) {
    return null
  }

  return (
    <>
      <div>
        <Tooltip title="Add Panels (P)">
          <Button
            id="add-panel-button"
            startIcon={<AddIcon className={classes.icon} />}
            classes={{ root: classes.root, label: classes.label, startIcon: classes.startIcon }}
            onClick={onClick}
            disabled={disabled}
          >
            {isActive && !hideOrientationButton ? (
              <span className={classes.labelSmaller}>{translate(isLandscape ? 'Landscape' : 'Portrait')}</span>
            ) : (
              <span className={classes.labelText}>{translate('PANELS')}</span>
            )}
          </Button>
        </Tooltip>
        {isActive && !hideOrientationButton && (
          <Button
            startIcon={<ScreenRotationIcon className={classes.icon} />}
            classes={{ root: classes.toggle, label: classes.label, startIcon: classes.startIcon }}
            onClick={() => {
              window.editor.signals.setPanelOrientation.dispatch(!isLandscape ? 'landscape' : 'portrait')
              return
            }}
          >
            <span className={classes.labelSmaller}>{translate(isLandscape ? 'Portrait' : 'Landscape')}</span>
          </Button>
        )}
      </div>
    </>
  )
}

export default AddPanelButton

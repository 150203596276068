import { useCallback, useState } from 'react'
import { useDataProvider } from 'react-admin'
import composeUrlWithQueryParams from 'util/fetch/composeUrlWithQueryParams'
import type { InventoryFiltersType } from '../../filter/type'
import type { InventoryForecastTransfer } from '../type'

type ForecastInventoryTransfersOptions = {
  code: string
  forecastEndDate?: string
  projectId?: number
  filters?: InventoryFiltersType // inventory dashboard filters
}

type ForecastInventoryTransfersResponse = {
  loadingForecastInventoryTransfers: boolean
  loadForecastInventoryTransfers: (options: ForecastInventoryTransfersOptions) => Promise<void>
  forecastInventoryTransfers: InventoryForecastTransfer[]
}

const useForecastInventoryTransfers = (): ForecastInventoryTransfersResponse => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [forecastInventoryTransfers, setForecastInventoryTransfers] = useState<InventoryForecastTransfer[]>([])
  const dataProvider = useDataProvider()
  const loadForecastInventoryTransfers = useCallback(
    async ({ projectId, filters, code, forecastEndDate }: ForecastInventoryTransfersOptions) => {
      if (projectId === undefined) return
      setIsLoading(true)
      await dataProvider
        .CUSTOM_POST(
          'inventory/forecast_transfers',
          {
            url: composeUrlWithQueryParams({
              baseUrl: 'inventory/forecast_transfers',
              params: { codes: code, project_id: projectId },
            }),
            data: {
              ...filters,
              // Can we move pickup_date_to to URL? it is not a part of dashboard filter
              pickup_date_to: forecastEndDate ? forecastEndDate : undefined,
              include_forecast_project: true,
            },
          },
          {
            onSuccess: ({ data }) => {
              const transfers = Object.values(data)[0]
              if (Array.isArray(transfers)) {
                setForecastInventoryTransfers(transfers)
              } else {
                // Error
                setForecastInventoryTransfers([])
              }
            },
          }
        )
        .finally(() => {
          setIsLoading(false)
        })
    },
    []
  )

  return {
    loadingForecastInventoryTransfers: isLoading,
    loadForecastInventoryTransfers,
    forecastInventoryTransfers,
  }
}

export default useForecastInventoryTransfers

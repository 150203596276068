import { Table, TableBody, TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core'
import React, { memo, useMemo } from 'react'
import { useTranslate } from 'react-admin'
import { monthNames } from 'util/misc'
import { dialogStyles } from '../styles'
import { DateRange, ScheduleType } from '../types'
import { groupSchedules, translateTimeLabel } from '../utils'

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    margin: '5px 0',
  },
  shortRow: {
    display: 'flex',
  },
}))

export const MonthSummary = ({ schedule, shortRow }: { schedule: ScheduleType; shortRow?: boolean }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const showSummary = !!schedule.date_ranges?.filter((x: DateRange) => x.start_date && x.end_date).length
  if (!schedule || !showSummary) {
    return <span>-</span>
  }
  return (
    <>
      {schedule.date_ranges.map((range) => {
        if (range.start_date && range.end_date) {
          const startDate = `${range.start_date?.getDate()} ${translate(monthNames[range.start_date?.getMonth()])}`
          const endDate = `${range.end_date?.getDate()} ${translate(monthNames[range.end_date?.getMonth()])}`
          return <div className={shortRow ? classes.shortRow : classes.row}>{`${startDate} to ${endDate}`}</div>
        } else return <></>
      })}
    </>
  )
}

const DetailTable = memo<{ state: ScheduleType }>(({ state }) => {
  const classes = dialogStyles()
  const translate = useTranslate()
  const groupedSchedules = useMemo(() => groupSchedules(state.applicable_days_and_hours?.data), [
    state.applicable_days_and_hours?.data,
  ])
  const schedulesLength = groupedSchedules && Object.keys(groupedSchedules).length

  return state ? (
    <div className={classes.spanRight}>
      <h2>{translate('Applicable Date & Time')}</h2>
      <Table className={classes.summaryTable}>
        <TableHead>
          <TableRow>
            <TableCell>{translate('Hours')}</TableCell>
            <TableCell>{translate('Weekdays')}</TableCell>
            <TableCell>{translate('Date Range')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!!schedulesLength ? (
            <>
              {Object.keys(groupedSchedules).map((schedule, i) => {
                const lastItem = i + 1 === Object.keys(groupedSchedules).length
                return (
                  <TableRow className={lastItem ? classes.removeBottomBorder : ''}>
                    <TableCell>
                      {groupedSchedules[schedule].map((timeRange) => (
                        <div>{translateTimeLabel(timeRange, translate)}</div>
                      ))}
                    </TableCell>
                    <TableCell>{schedule}</TableCell>
                    {i === 0 && (
                      <TableCell rowSpan={schedulesLength} style={{ borderBottom: 'none' }}>
                        <MonthSummary schedule={state} />
                      </TableCell>
                    )}
                  </TableRow>
                )
              })}
            </>
          ) : (
            <TableRow className={classes.removeBottomBorder}>
              <TableCell>-</TableCell>
              <TableCell>-</TableCell>
              <TableCell>
                <MonthSummary schedule={state} />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  ) : null
})

export default DetailTable

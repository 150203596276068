import { useCallback } from 'react'
import addComponentSetToSystem from '../../wizardUtils/addComponentSetToSystem'
import type { WizardComponentLikeType } from '../../wizardUtils/types'
import useMaybeActivateAndReload from '../../wizardUtils/useMaybeActivateAndReload'
import type { BSACustomerPriorityKeyType } from '../utility/types'

type SelectInverterSetProps = {
  systemUuid: string
  componentsSet: WizardComponentLikeType[]
  customerPriority?: BSACustomerPriorityKeyType
}

const clearSystemBatteries = ({ systemUuid }: { systemUuid: string }) => {
  const system = systemUuid && window.editor.objectByUuid(systemUuid)
  const components = system?.batteries() || []
  var commandUUID = window.Utils.generateCommandUUIDOrUseGlobal()
  components.forEach((component) => {
    window.editor.execute(new window.RemoveObjectCommand(component, true, false, commandUUID))
  })
}

const updateSystemBatteryControlScheme = ({
  systemUuid,
  customerPriority,
}: {
  systemUuid: string
  customerPriority?: BSACustomerPriorityKeyType
}) => {
  const system = systemUuid && window.editor.objectByUuid(systemUuid)
  // Change `self_consumption` to `solar_charged`, since it has a different value for battery control scheme
  // Use `solar_charged` as default if empty
  const batteryControlScheme = customerPriority === 'self_consumption' ? 'solar_charged' : customerPriority
  window.editor.execute(
    new window.SetValueCommand(system, 'battery_control_scheme', batteryControlScheme || 'solar_charged')
  )
}

const useSelectBatteryDesignAssistant = ({ onClose }: { onClose(): void }) => {
  const maybeActivateAndReload = useMaybeActivateAndReload()

  return useCallback(async ({ systemUuid, componentsSet, customerPriority }: SelectInverterSetProps) => {
    await maybeActivateAndReload({
      componentsSet,
    })

    updateSystemBatteryControlScheme({ systemUuid, customerPriority })

    clearSystemBatteries({ systemUuid })

    addComponentSetToSystem({ systemUuid, componentsSet })

    onClose()
  }, [])
}

export default useSelectBatteryDesignAssistant

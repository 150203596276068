import { EXCLUDE_DOCUMENT_TEMPLATES_FOR_PREFILL } from 'resources/documentTemplates/DocumentTemplates'
import { restClientInstance } from 'resources/paymentOptions/financialIntegrations/FinancialIntegration'
import { fieldPathToDisplayMap, indexToWord, staticPlaceholders } from './constants'
import { PlaceholderWhiteListType } from './ProjectPlaceholderWizard'

export const formatPlaceholderLabel = (
  rawName: string,
  fieldPathWithoutName: string | undefined,
  whitelistVals: PlaceholderWhiteListType | undefined
) => {
  let fieldPath = fieldPathWithoutName ? `${fieldPathWithoutName}.${rawName}` : rawName
  let staticWithWhitelist = { ...staticPlaceholders }
  if (whitelistVals) {
    if (whitelistVals.popular) {
      staticWithWhitelist.popular = {
        ...staticWithWhitelist.popular,
        ...whitelistVals.popular,
      }
    }
  }
  if (fieldPath.startsWith('popular.')) {
    return staticWithWhitelist['popular'][fieldPath.replace('popular.', '')]
  } else if (fieldPath.startsWith('custom_content.')) {
    return staticWithWhitelist['custom_content'][fieldPath.replace('custom_content.', '')]
  }

  // @ts-ignore
  let displayWithWhitelist = { ...fieldPathToDisplayMap }
  if (whitelistVals) {
    if (whitelistVals.path_to_display) {
      displayWithWhitelist = {
        ...displayWithWhitelist,
        ...whitelistVals.path_to_display,
      }
    }
  }
  if (displayWithWhitelist[fieldPath]) return displayWithWhitelist[fieldPath]
  let asWords = rawName.replaceAll('_', ' ').split(' ')
  if (asWords && asWords.length === 1 && indexToWord[asWords[0]]) {
    return indexToWord[asWords[0]]
  }
  for (let i = 0, len = asWords.length; i < len; i++) {
    let word = asWords[i]
    word = word.replace(word.charAt(0), word.charAt(0).toUpperCase())
    asWords[i] = word
  }
  return asWords.join(' ')
}

export const getThousandsSeperator = () => {
  let n = 1000.1
  let nAsString: string = n.toLocaleString().substring(1, 2)
  return nAsString
}

export const makeNumericIfPossible = (raw: string | number) => {
  if (!raw || typeof raw !== 'string') return raw
  if (!/\d/.test(raw)) return raw
  else if (!/^[0-9,.]*$/.test(raw)) return raw
  else {
    try {
      let formatted = parseFloat(raw.replace(getThousandsSeperator(), ''))
      return isNaN(formatted) ? raw : formatted
    } catch (ex) {
      return raw
    }
  }
}

export const parseValFromMultiLevelPath = (fieldName: String, dataToParse: any) => {
  if (fieldName.startsWith('popular.')) fieldName = fieldName.replace('popular.', '')
  if (!fieldName || !fieldName.includes('.')) return undefined
  let valueToReturn
  let chunks = fieldName.split('.')
  const parseChunk = (nameChunk: string, chunkIndex = 0, evalObj: Object = dataToParse): any => {
    let isLastChunk = chunkIndex === chunks.length - 1
    if (typeof evalObj === 'string') {
      try {
        evalObj = JSON.parse(evalObj)
      } catch (ex) {
        if (nameChunk === 'state') console.log('oops', ex)
      }
    }
    let evaluated: any = parseInt(nameChunk) ? evalObj[parseInt(nameChunk)] : evalObj[nameChunk]
    if (isLastChunk) valueToReturn = evaluated
    else parseChunk(chunks[chunkIndex + 1], chunkIndex + 1, evaluated)
  }
  parseChunk(chunks[0], 0)
  return valueToReturn
}

export const getLanguages = (locales) => {
  const languages: object[] = [{ id: '', name: 'Default' }]
  Object.entries(locales).forEach(([key, value]) => {
    languages.push({ id: key, name: value })
  })
  return languages
}

export const ALL_LOCALES_GROUP = '**'

type HasLocale = {
  type: number
  locale: LocaleId
}
type LocaleId = string

export const groupAndFilterDocumentsByLanguage = (
  data: HasLocale[],
  withContractFilter = false,
  restrictToPrefillableTemplates = false
): [Record<LocaleId, HasLocale[]>, LocaleId[]] => {
  const addToGroup = (groupedDocs: Record<string, HasLocale[]>, groupName: string, item: HasLocale) => {
    const list = groupedDocs[groupName]
    if (!list) {
      groupedDocs[groupName] = [item]
    } else {
      list.push(item)
    }
  }
  //group available documents by language/locale
  var groupedDocs = {}
  for (const i in data) {
    const item = data[i]

    if (restrictToPrefillableTemplates === true && EXCLUDE_DOCUMENT_TEMPLATES_FOR_PREFILL.includes(item.type)) {
      // skip
    } else if (item.type !== 3 || withContractFilter) {
      if (item.locale) {
        addToGroup(groupedDocs, item.locale, item)
        const localeParts = item.locale.split('_')
        if (localeParts.length > 1) addToGroup(groupedDocs, localeParts[0], item)
      } else {
        addToGroup(groupedDocs, ALL_LOCALES_GROUP, item)
      }
    }
  }

  const filteredList: LocaleId[] = []
  const locales = Object.keys(groupedDocs)
  for (const i of locales) {
    if (i !== ALL_LOCALES_GROUP) filteredList.push(i)
  }
  return [groupedDocs, filteredList]
}

export const matchLocaleFromGrouped = (
  data: Record<LocaleId, HasLocale[]>,
  locale: LocaleId,
  fallbackToEnglish: boolean
): HasLocale[] => {
  let docsForLocale = data[locale] || (fallbackToEnglish && data['en']) || []

  if (data[ALL_LOCALES_GROUP]) {
    docsForLocale = docsForLocale.concat(data[ALL_LOCALES_GROUP])
  }

  return docsForLocale
}

export const getAvailableDocs = (countryIso2, orgId) => {
  return restClientInstance('CUSTOM_GET', 'custom', {
    url: `document_templates/availability/${countryIso2}/?org_id=${orgId}`,
  })
}

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import classnames from 'classnames'
import { useFilterProjectErrors } from 'projectSections/errors/useFilterProjectErrors'
import { memo } from 'react'
import type { TabButtonProps } from './TabButton'
import { useTabStyle } from './TabButton'

const SummaryTabButton = (props: TabButtonProps) => {
  const classes = useTabStyle()
  const { tooltipMessage, disabled, Icon, id, selected, errorCategories, onClick } = props

  const checkSystemError = !!useFilterProjectErrors([
    {
      categories: errorCategories,
    },
  ]).length

  return (
    <div className={classes.wrapper}>
      <button
        disabled={disabled}
        id={id}
        className={classnames(classes.button, selected ? classes.selected : '')}
        onClick={onClick}
        title={tooltipMessage}
      >
        <Icon width="28" height="28" />
      </button>
      {checkSystemError && <InfoOutlinedIcon className={classes.infoIcon} />}
    </div>
  )
}

export default memo(SummaryTabButton)

import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core'
import { FormControlProps } from '@material-ui/core/FormControl'
import { SelectProps } from '@material-ui/core/Select'
import { ChoicesProps, InputProps, useChoices, useInput, useTranslate } from 'ra-core'
import React, { FunctionComponent, useCallback } from 'react'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      margin: '16px 40px 16px 0px',
    },
  }),
  { name: 'FeatureFiguresSelector' }
)

const defaultValue = ['', '', '', '']

type FeatureFigure = {
  id: string
  name: string
}

const FeatureFiguresSelector: FunctionComponent<ChoicesProps & InputProps<SelectProps> & FormControlProps> = (
  props
) => {
  const translate = useTranslate()
  const classes = useStyles()
  const {
    choices = [],
    classes: classesOverride,
    className,
    format,
    helperText,
    label,
    margin = 'dense',
    onBlur,
    onFocus,
    options,
    optionText,
    optionValue,
    parse,
    resource,
    emptyText = '',
    source,
    translateChoice,
    validate,
    variant = 'standard',
    ...rest
  } = props

  const { getChoiceText, getChoiceValue } = useChoices<FeatureFigure, string>({
    optionText,
    optionValue,
    translateChoice,
  })

  const renderMenuItemOption = useCallback((choice) => getChoiceText(choice), [getChoiceText])

  const renderMenuItem = useCallback(
    (choice) => {
      return choice ? (
        <MenuItem key={getChoiceValue(choice)} value={getChoiceValue(choice)}>
          {renderMenuItemOption(choice)}
        </MenuItem>
      ) : null
    },
    [getChoiceValue, renderMenuItemOption]
  )

  const {
    input: { onChange, value: inputValue = defaultValue },
    meta: { error, touched },
  } = useInput({
    format,
    onBlur,
    onFocus,
    parse,
    resource,
    source,
    validate,
    ...rest,
  })
  const handleChange = useCallback(
    (name, index) => {
      const newValue = [...inputValue]
      newValue[index] = name
      onChange(newValue)
    },
    [inputValue]
  )
  return (
    <>
      {[1, 2, 3, 4].map((a, index) => {
        return (
          <FormControl margin={margin} key={a} classes={{ root: classes.root }}>
            <InputLabel shrink={true}>{`${translate('Featured Figures')} #${index + 1}`}</InputLabel>
            <Select
              autoWidth
              displayEmpty={true}
              error={!!(touched && error)}
              variant={variant}
              value={inputValue[index] || 'none'} // Using 'none' here to force it to always be in controlled value mode
              onChange={(e: React.ChangeEvent<{ value: unknown }>) => handleChange(e.target.value, index)}
              {...options}
            >
              <MenuItem
                value={''}
                key="null"
                aria-label={translate('ra.action.clear_input_value')}
                title={translate('ra.action.clear_input_value')}
              >
                {translate('None')}
              </MenuItem>
              {choices.map(renderMenuItem)}
            </Select>
          </FormControl>
        )
      })}
    </>
  )
}

export default FeatureFiguresSelector

import { Grid, Typography } from '@material-ui/core'
import { DeleteIcon } from 'opensolar-ui'
import { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  container: {
    paddingLeft: 8,
    paddingRight: 8,
    marginTop: 24,
    cursor: 'pointer',
  },
  label: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '16px',
  },
}))

const RemoveLineItemButton = ({ handleRemove }: { handleRemove: () => void }) => {
  const classes = useStyles()

  return (
    <Grid
      container
      item
      direction="row"
      justify="flex-end"
      alignItems="center"
      className={classes.container}
      onClick={handleRemove}
    >
      <DeleteIcon />
      <Typography className={classes.label}>Remove</Typography>
    </Grid>
  )
}

export default RemoveLineItemButton

import React from 'react'
import type { ComponentTypes } from 'types/selectComponent'
import type { FilterComponentNode, FilterComponentRendererType, FilterGenericPropsType } from '../type'
import InverterTypeFilter, { FilterOptionsType } from './InverterTypeFilter'

export const INVERTER_TYPE_FILTER_NODE_KEY = 'inverter_type'

const getDefaultValue = (args?: { specsData: { [key: string]: string | number }; componentType?: ComponentTypes }) => {
  if (!args) {
    return
  }
  const { specsData, componentType } = args
  if (componentType !== 'inverter') {
    return
  }
  if (specsData.microinverter === 'Y') {
    return { microinverter: 'Y' }
  }
  if (specsData.hybrid === 'Y') {
    return { hybrid: 'Y' }
  }
  return
}

const maybeCreateInverterFilterNode = ({
  RendererComponent,
  componentType,
}: {
  RendererComponent: FilterComponentRendererType<FilterOptionsType>
  componentType?: ComponentTypes
}): FilterComponentNode | undefined => {
  if (componentType !== 'inverter') {
    return undefined
  }

  const FilterComponent = (props: FilterGenericPropsType) => {
    return (
      <InverterTypeFilter filterKey={INVERTER_TYPE_FILTER_NODE_KEY} RendererComponent={RendererComponent} {...props} />
    )
  }
  return {
    key: INVERTER_TYPE_FILTER_NODE_KEY,
    component: FilterComponent,
    getDefaultValue,
  }
}

export default maybeCreateInverterFilterNode

import { NativeMountingID, PartnerAccessoriesID } from '../../../../../../../types/mounting'

export const nativeMountingIDs: NativeMountingID[] = [
  'solarRoofPro',
  'variosole',
  'schletter',
  'fastensol',
  'msPitchedProLine',
  'msTrapezeProLine',
  'esdec',
  'flatFix',
  'metasole',
  'gseInRoof',
]

export const partnerAccessoriesIDs: PartnerAccessoriesID[] = ['solaskirt']

export const possibleInputVariables = [
  'roofHookCalculationMethod',
  'railDirection',
  'railDirectionLandscapePanels',
  'railDirectionPortraitPanels',
  'mountingSpacing',
  'railLength',
  'railColour',
  'clampColour',
  'endCapColour',
  'roofHook',
  'geniusKit',
  'mountingRail',
  'railSplice',
  'midClamp',
  'endClamp',
  'horizontalEndClamp',
  'verticalEndClamp',
  'fastener',
  'endCap',
  'profile',
  'panelThicknessRange',
  'terrainCategory',
  'windSpeed',
  'system',
  'tpoMats',
  'rowDistance',
  'roofHeight',
  'blackArticles',
  'includeRoofAdapter',
  'roofRibDistance',
  'seamType',
  'flangeDistance',
  'panelConfiguration',
  'roofHookMaterial',
  'clampQty',
  'topConnection',
  'flexibleStrip',
  'rearSupportBarIncluded',
  'chimneyQty',
] as const

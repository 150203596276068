import HardwareFilterV2 from 'elements/hardwareFilter/HardwareFilterV2'
import { HARDWARE_FILTERS_ORDER } from 'elements/hardwareFilter/constants'
import useFilterNodes from 'elements/hardwareFilter/hooks/useAvailableFilterSelectorNodes'
import { AVAILABILITY_FILTER_KEY } from 'elements/hardwareFilter/stockAvailability/AvailabilityFilterNodeFactory'
import type { FilterComponentNode, FilterValuesType } from 'elements/hardwareFilter/type'
import { getFiltersSchema } from 'elements/hardwareSelector/Filters'
import SearchBar from 'elements/hardwareSelector/filters/SearchBar'
import Pagination from 'elements/pagination/Pagination'
import { assign } from 'lodash'
import type LineItemType from 'pages/ordering/OrderLineItem'
import useControlledCollection from 'pages/ordering/hooks/useControlledCollection'
import type { LineItemLoadedDataType } from 'pages/ordering/type'
import React, { useCallback, useMemo } from 'react'
import type { FilterType } from 'types/selectComponent'
import { useFeatureFlag } from 'util/split'
import ShopResultsFilter from '../../ShopResultsFilter'
import type { CatalogItemDataType } from '../../catalog/HardwareCatalog'
import HardwareCatalog from '../../catalog/HardwareCatalog'
import createReviewItemNode from '../reviewItem/ReviewItemNode'
import type { FlowControllerType, ReplaceItemNode } from '../types'
import ReplacementCatalogTitle from './ReplacementCatalogTitle'
import ReviewDesignsTable from './ReviewDesignsTable'

type HardwareSelectNodeProps = {
  originalLineItem: LineItemType
  flowController: FlowControllerType
  trackSource: string
}

const getCatalogData = (data: (LineItemLoadedDataType | undefined)[]): (CatalogItemDataType | undefined)[] => {
  return data.map((item) => {
    if (item == null) {
      return undefined
    }

    const distributors = item.distributors
    return {
      code: item.code,
      manufacturer_name: item.manufacturer_name,
      short_description: item.short_description,
      // TODO: BE to do there is no image_url returned
      image_url: item.image_url,
      distributors: distributors,
    }
  })
}

const getDefaultFilterByLineItemV1 = (lineItem: LineItemType) => {
  const schema = getFiltersSchema(lineItem.componentType)
  const filter = { filterValues: { fieldset: 'list', my_list: true }, displayedFilters: {} }
  const rawSpecsData = lineItem.data?.data
  if (!rawSpecsData) return filter

  const specsData = JSON.parse(rawSpecsData)
  schema.forEach((filterSettings: FilterType) => {
    const defaultFilterValue = filterSettings.getDefaultValue?.({ specsData })
    if (defaultFilterValue != null) {
      filter.filterValues[filterSettings.key] = defaultFilterValue
    }
  })
  return filter
}

const getDefaultFilterValuesByLineItemV2 = ({
  lineItem,
  filterNodes,
}: {
  lineItem: LineItemType
  filterNodes: FilterComponentNode[]
}): FilterValuesType => {
  let filterValues = { [AVAILABILITY_FILTER_KEY]: 'true' }
  const rawSpecsData = lineItem.data?.data
  if (!rawSpecsData) return filterValues
  const specsData = JSON.parse(rawSpecsData)
  filterNodes.forEach((node: FilterComponentNode) => {
    if (node.getDefaultValue) {
      const defaultValue = node.getDefaultValue({ specsData, componentType: lineItem.componentType })
      defaultValue && assign(filterValues, defaultValue)
    }
  })
  return filterValues
}

const HardwareSelect = ({ flowController, originalLineItem, trackSource }: HardwareSelectNodeProps) => {
  const defaultFilterValuesV1 = useMemo(() => getDefaultFilterByLineItemV1(originalLineItem), [])
  const enableHardwareFilterV2 = useFeatureFlag('hardware_filter_v2', 'on')
  const filterNodes = useFilterNodes({
    filtersNodes: HARDWARE_FILTERS_ORDER,
    componentType: originalLineItem.componentType,
  })
  const defaultFilterValuesV2 = useMemo(() => {
    return getDefaultFilterValuesByLineItemV2({ lineItem: originalLineItem, filterNodes })
  }, [])
  const {
    page,
    perPage,
    setPage,
    setPerPage,
    setFilters,
    total = 0,
    productData,
    loading,
    persistentFilterValues,
    modifiableFilterValues,
    allFilterValues,
  } = useControlledCollection({
    catalog: 'full',
    defaultFilterValues: enableHardwareFilterV2 ? defaultFilterValuesV2 : defaultFilterValuesV1,
    showExhibitedFirst: true,
    componentType: originalLineItem.componentType,
  })

  const onNext = useCallback((code: string) => {
    const reviewItem = createReviewItemNode({ flowController, newCode: code, originalLineItem, trackSource })
    flowController.goTo({ node: reviewItem })
  }, [])

  // TODO: we don't have to create a special function for SearchBar
  // consider update SearchBar to accept setModifiableFilters
  const handleFilter = useCallback(
    (newFilterValues: { [key: string]: unknown }) => {
      setFilters({ ...modifiableFilterValues, ...newFilterValues })
    },
    [modifiableFilterValues]
  )

  if (productData == null || !originalLineItem.componentType) {
    return null
  }

  return (
    <div>
      {originalLineItem.projectOrder != null && <ReviewDesignsTable projectOrder={originalLineItem.projectOrder} />}
      <ReplacementCatalogTitle />
      <div>
        <SearchBar componentType={originalLineItem.componentType} setFilters={handleFilter} />
        {enableHardwareFilterV2 ? (
          <HardwareFilterV2
            setModifiableFilters={setFilters}
            trackSource={trackSource}
            filterNodes={filterNodes}
            allFilterValues={allFilterValues}
            persistentFilterValues={persistentFilterValues}
            modifiableFilterValues={modifiableFilterValues}
          />
        ) : (
          <ShopResultsFilter
            setFilters={setFilters}
            trackSource={trackSource}
            handleUpdate={handleFilter}
            filterValues={modifiableFilterValues}
            componentType={originalLineItem.componentType}
          />
        )}
      </div>
      <HardwareCatalog
        trackSource={trackSource}
        justify="space-evenly"
        loading={loading}
        total={total}
        data={getCatalogData(productData)}
        onNext={onNext}
        Pagination={
          <Pagination
            total={total}
            loading={loading}
            page={page}
            perPage={perPage}
            setPage={setPage}
            setPerPage={setPerPage}
            rowsPerPageOptions={[20]}
          />
        }
      />
    </div>
  )
}

export type CreateHardwareSelectNodeType = (args: HardwareSelectNodeProps) => ReplaceItemNode

const createHardwareSelectNode = ({ flowController, originalLineItem, trackSource }): ReplaceItemNode => ({
  key: 'hardwareSelect',
  title: 'Find replacement items and update proposals',
  Content: (
    <HardwareSelect flowController={flowController} originalLineItem={originalLineItem} trackSource={trackSource} />
  ),
})

export default createHardwareSelectNode

import { Tab, Tabs, Theme, useMediaQuery } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { Box, Button, getTypographyStyles } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useEffect, useMemo, useState } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { formatDecimalPlaces } from 'util/misc'
import BSABackupAppliancesInput from '../components/BSABackupAppliancesInput'
import { BSABackupAppliancesManual } from '../components/BSABackupAppliancesManual'
import BatterySalesAssistantContainer from '../components/BatterySalesAssistantContainer'
import { BSA_APPLIANCES, BSA_CUSTOMER_PRIORITY_NODE_KEY, BSA_SELECTOR_NODE_KEY } from '../utility/constants'
import {
  BatterySalesAssistantFlowNodeComponentStandardPropsType,
  BSAAppliancePower,
  BSAAppliances,
  BSABackupAppliancesTab,
} from '../utility/types'

const useStyles = makeOpenSolarStyles((theme) => ({
  skipText: {
    fontWeight: 500,
  },
  tabs: {
    borderBottom: '1px solid #E7E7E7',
  },
  tabsIndicator: {
    backgroundColor: theme.blue,
  },
  tab: {
    minWidth: '0px',
    padding: '10px',
    opacity: 1,
    '&.Mui-selected .MuiTab-wrapper': {
      color: theme.blue,
    },
  },
  tabWrapper: {
    textTransform: 'none',
    ...getTypographyStyles({ colorHex: '#757575' }),
  },
}))

interface BSABackupAppliancesProps extends BatterySalesAssistantFlowNodeComponentStandardPropsType {}

const BACKUP_HOURS_DEFAULT = 0
const AVERAGE_CONTINUOUS_POWER_DEFAULT = 0
const TOTAL_PEAK_POWER_DEFAULT = 0

const BSABackupAppliancesNode: React.FC<BSABackupAppliancesProps> = ({ flowController, flowStore }) => {
  const classes = useStyles()

  const [appliancePowers, setAppliancePowers] = useState<BSAAppliances>(flowStore.backupAppliances || BSA_APPLIANCES)
  const [currentTab, setCurrentTab] = useState<BSABackupAppliancesTab>(flowStore.backupAppliancesTab || 'manual')
  const [backupHours, setBackupHours] = useState(flowStore.backupHours || BACKUP_HOURS_DEFAULT)
  const [averageContinuousPower, setAverageContinuousPower] = useState(
    flowStore.averageContinuousPower || AVERAGE_CONTINUOUS_POWER_DEFAULT
  )
  const [totalPeakPower, setTotalPeakPower] = useState(flowStore.totalPeakPower || TOTAL_PEAK_POWER_DEFAULT)
  const [previousValues, setPreviousValues] = useState({
    backupHours: BACKUP_HOURS_DEFAULT,
    averageContinuousPower: AVERAGE_CONTINUOUS_POWER_DEFAULT,
    totalPeakPower: TOTAL_PEAK_POWER_DEFAULT,
  })

  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const isSkipShown = useMemo(
    () =>
      Number(backupHours) === BACKUP_HOURS_DEFAULT &&
      Number(averageContinuousPower) === AVERAGE_CONTINUOUS_POWER_DEFAULT &&
      Number(totalPeakPower) === TOTAL_PEAK_POWER_DEFAULT,
    [backupHours, averageContinuousPower, totalPeakPower]
  )
  const translate = useTranslate()

  const handleBack = () => {
    logAmplitudeEvent('bda_back_button_clicked')
    flowController.goTo({
      currentNodeKey: BSA_CUSTOMER_PRIORITY_NODE_KEY,
      options: {},
    })
  }

  const handleChangeAppliance = (applianceKey: string, powerKey: keyof BSAAppliancePower, value: number | boolean) => {
    setAppliancePowers((aP) => {
      return {
        ...aP,
        [applianceKey]: {
          ...aP[applianceKey],
          [powerKey]: value,
          ...(powerKey === 'enabled' &&
            value === true &&
            aP[applianceKey].quantity === 0 && {
              quantity: 1,
            }),
        },
      }
    })
  }

  const handleChangeBackupHours = (value) => setBackupHours(value)

  const handleChangeTab = (tab: BSABackupAppliancesTab) => {
    logAmplitudeEvent(tab === 'appliances' ? 'bda_appliance_tab_clicked' : 'bda_manual_tab_clicked')
    const currentValues = {
      backupHours,
      averageContinuousPower,
      totalPeakPower,
    }

    setBackupHours(previousValues.backupHours)
    setAverageContinuousPower(previousValues.averageContinuousPower)
    setTotalPeakPower(previousValues.totalPeakPower)
    setPreviousValues(currentValues)
    setCurrentTab(tab)
  }

  const handleContinue = (isSkip: boolean = false) => {
    if (isSkip) {
      logAmplitudeEvent('bda_skip_button_clicked', {
        location: 'Battery Sales Assistant Modal',
        action: 'Clicked Skip Button',
      })
    } else {
      logAmplitudeEvent('bda_next_button_clicked', {
        recorded_on_click: currentTab === 'appliances' ? 'enter_by_appliance' : 'house_of_backup',
      })
    }
    flowController.setFlowStore((state) => ({
      ...state,
      backupAppliances: appliancePowers,
      backupAppliancesTab: currentTab,
      backupHours,
      averageContinuousPower,
      totalPeakPower,
    }))
    flowController.goTo({
      currentNodeKey: BSA_SELECTOR_NODE_KEY,
      options: {},
    })
  }

  useEffect(() => {
    if (currentTab === 'appliances') {
      setAverageContinuousPower(
        formatDecimalPlaces(
          Object.values(appliancePowers).reduce(
            (c, v) => c + (v.enabled ? Number(v.continuousPower) * Number(v.quantity) : 0),
            0
          ),
          2
        )
      )
      setTotalPeakPower(
        formatDecimalPlaces(
          Object.values(appliancePowers).reduce(
            (c, v) => c + (v.enabled ? Number(v.peakPower) * Number(v.quantity) : 0),
            0
          ),
          2
        )
      )
    }
  }, [appliancePowers, currentTab])

  return (
    <BatterySalesAssistantContainer
      footerActions={
        <Box display="flex" flex={1} justifyContent="space-between">
          {isSkipShown ? (
            <Button color="default" onClick={() => handleContinue(true)} variant="text">
              <span className={classes.skipText}>{translate('Skip')}</span>
            </Button>
          ) : (
            <Box />
          )}
          <Box display="flex" gridColumnGap={12}>
            <Button color="default" onClick={handleBack} variant="contained">
              {translate('Back')}
            </Button>
            <Button color="primary" variant="contained" onClick={() => handleContinue()}>
              {translate('Next')}
            </Button>
          </Box>
        </Box>
      }
    >
      <Box display="flex" flexDirection="column" gridGap={32} flexWrap={isSmallScreen ? 'wrap' : 'nowrap'}>
        <Tabs
          classes={{ root: classes.tabs, indicator: classes.tabsIndicator }}
          onChange={(_, v) => handleChangeTab(v)}
          value={currentTab}
        >
          <Tab
            classes={{ root: classes.tab, wrapper: classes.tabWrapper }}
            label={translate('Enter Manually')}
            value="manual"
          />
          <Tab
            classes={{ root: classes.tab, wrapper: classes.tabWrapper }}
            label={translate('Enter by Appliance')}
            value="appliances"
          />
        </Tabs>
        {currentTab === 'appliances' && (
          <BSABackupAppliancesInput
            appliancePowers={appliancePowers}
            backupHours={backupHours}
            handleChangeAppliance={handleChangeAppliance}
            handleChangeBackupHours={handleChangeBackupHours}
            averageContinuousPower={averageContinuousPower}
            totalPeakPower={totalPeakPower}
          />
        )}
        {currentTab === 'manual' && (
          <BSABackupAppliancesManual
            backupHours={backupHours}
            handleChangeBackupHours={handleChangeBackupHours}
            handleChangeTotalCP={setAverageContinuousPower}
            handleChangeTotalPP={setTotalPeakPower}
            averageContinuousPower={averageContinuousPower}
            totalPeakPower={totalPeakPower}
          />
        )}
      </Box>
    </BatterySalesAssistantContainer>
  )
}

export default BSABackupAppliancesNode

import { makeStyles } from '@material-ui/core'
import { Checkbox, Grid } from 'opensolar-ui'
import { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { ChangeEvent, FC, memo, useContext, useEffect, useState } from 'react'
import { Theme } from 'types/themes'
import { SOURCE_CONTEXT_MAPPING } from '../constants'
import { COLOR_PALETTE, getHardwareSectionPreOrderStyles } from '../styles'
import { CheckoutSourceType, ContactInfoType, DeliveryAddressInfoType } from '../types'
import DeliveryDetail from './DeliveryDetail'
import { useTranslate } from 'ra-core'

type Props = {
  distributorKey: HardwareSupplierFilterKeyType
  checkoutSource: CheckoutSourceType
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {},
  saveContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  save: {
    fontSize: '12px',
    fontWeight: 600,
    color: COLOR_PALETTE.blue,
    cursor: 'pointer',
  },
  defaultAddressCheckboxLabel: {
    color: COLOR_PALETTE.black,
    marginLeft: 8,
    fontWeight: 475,
  },
}))

const DistributorDeliveryDetail: FC<Props> = ({ distributorKey, checkoutSource }) => {
  const classes = useStyles()
  const sectionStyles = getHardwareSectionPreOrderStyles()
  const contactInfo = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].contactInfoContext)
  const deliveryAddressInfo = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].deliveryAddressInfoContext)
  const preOrderPresenter = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].presenterContext)
  const [editable, setEditable] = useState<boolean>(false)
  const [isDefaultAddress, setIsDefaultAddress] = useState<boolean>(true)
  const [deliveryAddress, setDeliveryAddress] = useState<DeliveryAddressInfoType>()
  const [contactDetails, setContactDetails] = useState<ContactInfoType>()
  const translate = useTranslate()

  useEffect(() => {
    setContactDetails(contactInfo['general'])
  }, [contactInfo])

  useEffect(() => {
    setDeliveryAddress(deliveryAddressInfo['general'])
  }, [deliveryAddressInfo])

  const handleSave = () => {
    preOrderPresenter?.updateContactInfo({
      ...contactInfo,
      [distributorKey]: contactDetails as ContactInfoType,
    })
    preOrderPresenter?.updateDeliveryAddressInfo({
      ...deliveryAddressInfo,
      [distributorKey]: deliveryAddress as DeliveryAddressInfoType,
    })
    setEditable(false)
  }

  const handleUpdateDeliveryAddress = (input: DeliveryAddressInfoType) => {
    setDeliveryAddress(input)
  }

  const handleUpdateContactDetails = (field: string, value: string) => {
    setContactDetails({
      ...contactDetails,
      [field]: value,
    })
  }

  const handleClickDefaultAddressCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setContactDetails(contactInfo['general'])
      setDeliveryAddress(deliveryAddressInfo['general'])
      preOrderPresenter?.updateContactInfo({
        ...contactInfo,
        [distributorKey]: contactInfo['general'],
      })
      preOrderPresenter?.updateDeliveryAddressInfo({
        ...deliveryAddressInfo,
        [distributorKey]: deliveryAddressInfo['general'],
      })
      setIsDefaultAddress(true)
      setEditable(false)
    } else {
      setEditable(true)
      setIsDefaultAddress(false)
    }
  }

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={6}>
            <span className={sectionStyles.headerTitle}>{translate('Delivery Details')}</span>
          </Grid>
          {editable && (
            <Grid item xs={6} className={classes.saveContainer}>
              <span className={classes.save} onClick={handleSave}>
                Save
              </span>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <DeliveryDetail
          contactInfo={contactInfo ? contactInfo[distributorKey] : undefined}
          deliveryAddressInfo={deliveryAddressInfo ? deliveryAddressInfo[distributorKey] : undefined}
          editable={editable}
          showDeliveryDetailTitle={false}
          handleUpdateDeliveryAddress={handleUpdateDeliveryAddress}
          handleUpdateContactDetails={handleUpdateContactDetails}
        />
      </Grid>
      <Grid item xs={12}>
        <div className={`${sectionStyles.row}`}>
          <Checkbox
            defaultChecked={true}
            sx={{
              padding: 0,
              '&.Mui-checked': {
                color: COLOR_PALETTE.blue2,
              },
            }}
            value={isDefaultAddress}
            onChange={handleClickDefaultAddressCheckbox}
          />
          <span className={classes.defaultAddressCheckboxLabel}>{translate('Use my default address and contact informations')}</span>
        </div>
      </Grid>
    </Grid>
  )
}

export default memo(DistributorDeliveryDetail)

// @ts-nocheck
import ContactsOutlinedIcon from '@material-ui/icons/ContactsOutlined'
import MapOutlinedIcon from '@material-ui/icons/MapOutlined'
import { Button } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import useTranslateParse from '../../../util/useTranslateParse'
import { TOUR_Z_INDEX } from '../tools/common'
import { ToolTipPropsType, TourConfigType } from '../types'
import DefaultTooltip from './DefaultToolTip'

const AlignMapTooltip = (props: ToolTipPropsType) => {
  const { handleNext, handlePrev, handleCancel } = props
  const translate = useTranslate()
  return (
    <div style={{ top: 120, left: 40, position: 'fixed', zIndex: TOUR_Z_INDEX }}>
      <DefaultTooltip
        title={translate('Check Site')}
        // onClose={handleCancel}
        icon={MapOutlinedIcon}
        content={
          <>
            <div>{translate('Confirm that the site is centered on the screen.')}</div>
            <div style={{ marginTop: 10 }}>
              {translate('You can align the imagery by holding down your left mouse button and moving around.')}
            </div>
          </>
        }
        actions={
          <>
            <Button
              onClick={() => handlePrev && handlePrev()}
              onTouchStart={(e) => {
                e.stopPropagation()
                if (handlePrev) {
                  handlePrev()
                }
              }}
            >
              <span>{translate('Back')}</span>
            </Button>
            <Button
              onClick={() => handleNext && handleNext()}
              onTouchStart={(e) => {
                e.stopPropagation()
                if (handleNext) {
                  handleNext()
                }
              }}
            >
              <span>{translate('Next')}</span>
            </Button>
          </>
        }
      />
    </div>
  )
}

const CreateButtonTooltip = (props: ToolTipPropsType) => {
  const { handleNext, handlePrev, handleCancel } = props
  const translate = useTranslate()
  const translateParse = useTranslateParse()
  return (
    <DefaultTooltip
      title={translate('Enter Customer Details')}
      icon={ContactsOutlinedIcon}
      content={
        <div style={{ width: 300 }}>
          <div>{translate("Now, let's add customer information on the project Info page.")}</div>
          <div>
            {translateParse('Click <strong>Create Project<strong> to proceed.', {
              strong: (label: string) => <strong>{label}</strong>,
            })}
          </div>
        </div>
      }
      actions={
        <>
          <Button
            onClick={() => handlePrev && handlePrev()}
            onTouchStart={(e) => {
              e.stopPropagation()
              if (handlePrev) {
                handlePrev()
              }
            }}
          >
            <span>{translate('Back')}</span>
          </Button>
          <Button disabled={true}>
            <span>{translate('Next')}</span>
          </Button>
        </>
      }
    />
  )
}

export const CheckSite: TourConfigType = {
  spotlights: [{ targetId: 'explore-map-container', padding: 0 }],
  tooltips: [<AlignMapTooltip />],
}

export const CreateProject: TourConfigType = {
  spotlights: [{ targetId: 'create-project-button-default' }],
  tooltips: [
    {
      targetId: 'create-project-button-default',
      placement: 'bottom',
      component: CreateButtonTooltip,
      options: { style: { left: 'unset', right: 10, maxWidth: 350 } },
    },
  ],
}

import { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

export const useCheckoutStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  container: {
    width: '100%',
    padding: 20,
    boxSizing: 'border-box',
  },
  title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '45px',
  },
  contentWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  main: {
    flexGrow: 1,
    flexDirection: 'column',
    columnGap: '100px',
    paddingRight: '30px',
  },
  content: {
    height: '400px',
    padding: '20px 0px 20px 0px',
    display: 'flex',
    flexDirection: 'row',
  },
  font14: {
    fontSize: '14px',
    lineHeight: '22px',
  },
  font16: {
    fontSize: '16px',
    lineHeight: '26px',
  },
  font18: {
    fontSize: '18px',
    lineHeight: '22px',
  },
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  placeOrderCard: {
    diplay: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    boxSizing: 'border-box',
    border: theme.border.default,
    borderRadius: '8px',
    padding: '14px',
  },
  validColor: {
    color: theme.ValidityPalette['valid'].color,
  },
  divider: {
    color: '#B4B4B4',
  },
  radio: {
    padding: '0px 16px 0px 0px',
    '&.Mui-checked': {
      color: '#4272DD',
    },
  },
}))

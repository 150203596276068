import { orgSelectors } from 'ducks/orgs'
import { useGetFinanceCTAs } from 'projectSections/hooks/useGetFinanceCTAs'
import React from 'react'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import DialogCTAButton from '../DialogCTAButton'

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    margin: '0px 15px',
  },
}))

type PropTypes = {}

const ToolbarCTAButton: React.FC<PropTypes> = (props) => {
  const formState = useFormState()
  const classes = useStyles()
  const isResidential: boolean = formState.values['is_residential']
  const availableFinanceIntegrations = useSelector(orgSelectors.getAvailableFinanceIntegrations)
  const availableCTAs = useGetFinanceCTAs(isResidential)

  if (!availableFinanceIntegrations?.length) return null
  return (
    <div className={classes.wrapper}>
      {availableCTAs?.map((cta) => (
        <DialogCTAButton cta={cta} key={cta.cta_main_text} />
      ))}
    </div>
  )
}
export default ToolbarCTAButton

import React, { useState, useEffect } from 'react'
import { SungageDecisionType } from './types'
import ProUxButton from 'elements/proUXButtons/ProUXButton'

type PropTypes = {
  decision: SungageDecisionType
  onClose: () => void
  amountApproved?: number
  amountRequested?: number
  approvedContacts?: number[]
  appliedWithCoborrower: boolean
  stips: string[]
}

const SungageDecisionExplanation: React.FunctionComponent<PropTypes> = (props) => {
  return (
    <div
      style={{
        margin: '10px 0px 20px 0px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      {props.decision === 'Approved' && (
        <div>
          <img
            src={`${window.PUBLIC_URL}/images/sungage-logo-wide.png`}
            alt=""
            height={45}
            style={{ marginBottom: '30px' }}
          />
          <h2>Loan Decision - Approved</h2>
          <p>Your application for financing has been approved! Please check your email for more details.</p>
        </div>
      )}
      {props.decision === 'Declined' && (
        <div>
          <h2>Loan Decision - Declined</h2>
          <p>
            Your application for financing has been declined. Please check your email and contact your salesperson for
            more details.
          </p>
        </div>
      )}
      {props.decision === 'Pending' && (
        <div>
          <h2>Loan Decision - Under Review</h2>
          <p>
            Your application for financing is under review. Please check your email and contact your salesperson for
            more details and next steps.
          </p>
        </div>
      )}
      {props.decision === 'Not yet submitted' && (
        <div>
          <h2>Loan Decision - Under Review</h2>
          <p>
            Your application for financing is under review. Please check your email and contact your salesperson for
            more details and next steps.
          </p>
        </div>
      )}
      {props.decision === 'Conditionally approved' && (
        <div>
          <img
            src={`${window.PUBLIC_URL}/images/sungage-logo-wide.png`}
            alt=""
            height={45}
            style={{ marginBottom: '30px' }}
          />
          <h2>Loan Decision - Conditionally Approved</h2>
          <p>
            Your application for financing has been conditionally approved pending verification of certain
            documentation. Please check your email for information on next steps.
          </p>
          {/* <ul style={{ textAlign: 'left', margin: '30px 0px' }}>
            {props?.stips?.map((stip) => (
              <li key={stip}>{stip}</li>
            ))}
          </ul> */}
        </div>
      )}
      <div style={{ marginTop: '30px' }}>
        <ProUxButton type="secondary" onClick={props.onClose} label="Continue" />
      </div>
    </div>
  )
}
export default SungageDecisionExplanation

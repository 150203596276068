import {
  CustomFormTypeEnum,
  CustomFormUsageType,
  FormPlacementEnum,
  FormPlacementType,
} from 'resources/customForms/types'

export interface FormPlacementInfo {
  form: CustomFormUsageType
  placements: FormPlacementType[]
}

export type FindPlacementsOpts = {
  forms: CustomFormUsageType[]
  type?: CustomFormTypeEnum
  location?: FormPlacementEnum
  locations?: FormPlacementEnum[]
  isStaff?: boolean
  entityId?: number
  omitNoEntityId?: boolean
  dontSort?: boolean
  isCreateForm?: boolean
  formTitles?: string[]
}

export const findFormPlacements = (opts: FindPlacementsOpts) => {
  const ret: FormPlacementInfo[] = []

  for (const form of opts.forms) {
    if (opts.type !== undefined && form.type !== opts.type) continue
    if (opts.formTitles && opts.formTitles.indexOf(form.title) === -1) continue

    const placements: FormPlacementType[] = []

    for (const placement of form.placements) {
      if (opts.location !== undefined && placement.form_location !== opts.location) continue
      if (opts.locations !== undefined && !opts.locations.includes(placement.form_location)) continue
      if (opts.isStaff === false && placement.staff_only) continue
      if (opts.isCreateForm && placement.view_data?.hide_during_create) continue

      switch (placement.form_location) {
        case 'before_document_generation':
          if (placement.document_template_id && placement.document_template_id !== opts?.entityId) continue
          if (opts.omitNoEntityId && placement.document_template_id === null) continue
          break
        case 'before_contract':
          if (opts.entityId && placement.contract_template_id !== opts.entityId) continue
          if (opts.omitNoEntityId && placement.contract_template_id === null) continue
          break
        default:
          break
      }
      placements.push(placement)
    }

    if (placements.length) ret.push({ form, placements })
  }

  if (!opts.dontSort) {
    ret.sort((a, b) => {
      const aPlacement = a.placements[0]
      const bPlacement = b.placements[0]

      if (aPlacement.priority === null && bPlacement.priority === null) return 0
      if (aPlacement.priority === null) return 1
      if (bPlacement.priority === null) return -1
      return aPlacement.priority - bPlacement.priority
    })
  }

  return ret
}

import { getSupplierConfig } from 'pages/ordering/configs'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import { useCallback, useEffect, useMemo, useState } from 'react'
import restClient from 'restClient'
import type { ComponentTypes } from '../../../types/selectComponent'

type LoadDistributorAvailableBrandsOptions = { distributor: HardwareSupplierEnum }

const useGetDistributorAvailableBrands = ({ componentType }: { componentType?: ComponentTypes }) => {
  const restClientInstance = restClient(window.API_ROOT + '/api')

  const getDistributorAvailableBrands = useCallback(async ({ distributor }: LoadDistributorAvailableBrandsOptions) => {
    const supplierConfig = getSupplierConfig(distributor)
    const response = await restClientInstance('CUSTOM_GET', 'custom', {
      url: `ordering/${supplierConfig?.filterKey}/manufacturer_list/?component_type=${componentType}`,
    })
    return response.data
  }, [componentType])

  return getDistributorAvailableBrands
}

const useDistributorAvailableBrands = ({
  distributor,
  componentType,
}: {
  distributor?: HardwareSupplierEnum
  componentType?: ComponentTypes
}) => {
  const [brands, setBrands] = useState<string[]>(() => [])
  const getDistributorAvailableBrands = useGetDistributorAvailableBrands({ componentType })
  useEffect(() => {
    if (distributor !== undefined) {
      getDistributorAvailableBrands({ distributor }).then((brands) => {
        if (brands && brands.length > 0) {
          setBrands(brands)
        }
      })
    }
  }, [componentType, distributor])
  return useMemo(() => brands, [componentType, brands])
}

export default useDistributorAvailableBrands

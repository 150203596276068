var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
export var EmailOutlineIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? '20' : _b, _c = _a.color, color = _c === void 0 ? '#0A090B' : _c;
    return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: size, height: size, viewBox: "0 0 ".concat(size, " ").concat(size), fill: "none" }, { children: _jsx("path", { d: "M4.54292 16.4274C4.16778 16.4274 3.85069 16.2978 3.59167 16.0387C3.33264 15.7795 3.20312 15.4622 3.20312 15.0868V6.5947C3.20312 6.21928 3.33264 5.9031 3.59167 5.64616C3.85069 5.38921 4.16778 5.26074 4.54292 5.26074H17.03C17.4051 5.26074 17.7222 5.39032 17.9812 5.64949C18.2403 5.90866 18.3698 6.22595 18.3698 6.60137V15.0935C18.3698 15.4689 18.2403 15.785 17.9812 16.042C17.7222 16.2989 17.4051 16.4274 17.03 16.4274H4.54292ZM10.7865 11.4755L4.28646 7.65012V15.0876C4.28646 15.1625 4.31049 15.2239 4.35854 15.272C4.4066 15.32 4.46806 15.3441 4.54292 15.3441H17.03C17.1049 15.3441 17.1663 15.32 17.2144 15.272C17.2624 15.2239 17.2865 15.1625 17.2865 15.0876V7.65012L10.7865 11.4755ZM10.7865 10.0732L17.1583 6.34408H4.41458L10.7865 10.0732ZM4.28646 7.65012V6.34408V15.0876C4.28646 15.1625 4.31049 15.2239 4.35854 15.272C4.4066 15.32 4.46806 15.3441 4.54292 15.3441H4.28646V7.65012Z", fill: color }) })));
};

import {
  Dialog,
  Divider,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLessOutlined'
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreOutlined'
import { Button } from 'opensolar-ui'

import React from 'react'
import appStorage from 'storage/appStorage'

const useStyles = makeStyles((theme) => ({
  root: {
    'MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}))

const ExpireAccessOptions = [
  {
    id: '0',
    expires: '3y',
    name: 'After 3 years (default)',
  },
  {
    id: '1',
    expires: 'until_revoked',
    name: 'When the customer wants (indefinite access)',
  },
  {
    id: '2',
    expires: '1y',
    name: 'After 1 year',
  },
  {
    id: '3',
    expires: '30d',
    name: 'After 30 days',
  },
  {
    id: '4',
    expires: '7d',
    name: 'After 7 days',
  },
]

export default function RequestUtilityDataForm({ isOpen, isClose, name, email, utilities, closeDialog }) {
  const classes = useStyles()
  const [showAdvanced, setShowAdvanced] = React.useState(false)
  const [nameValue, setNameValue] = React.useState(name)
  const [emailValue, setEmailValue] = React.useState(email)
  const [utilityValue, setUtilityValue] = React.useState()
  const [expireAccessValue, setExpireAccessValue] = React.useState('3y')

  const onSubmitHandler = (event) => {
    event.preventDefault()

    const body = {
      customer_name: nameValue,
      customer_email: emailValue,
      customer_utility: utilityValue,
      expires: expireAccessValue,
    }

    const orgId = appStorage.getOrgId()
    fetch(`${window.API_ROOT}/api/orgs/${orgId}/utility_api_request_data/`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + appStorage.getToken(),
      },
    }).then((response) => {
      if (response.status === 200) {
        window.alert('Request sent!')
        closeDialog()
        isClose()
      } else {
        window.alert('Error sending request!')
      }
    })
  }

  return (
    <div>
      <Dialog open={isOpen}>
        <Typography variant="h5" style={{ margin: 'auto', textAlign: 'center', paddingTop: 25 }}>
          Request Data
          <Typography variant="subtitle1" style={{ margin: 'auto', paddingBottom: 25, paddingTop: 25 }}>
            Easily request data from a utility customer.
          </Typography>
          <Divider />
        </Typography>
        <form className={classes.root} autoComplete="off" onSubmit={onSubmitHandler}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '60%',
              margin: 'auto',
              gap: '20px',
            }}
          >
            <TextField
              label="Customer Name"
              variant="outlined"
              fullWidth
              defaultValue={name}
              value={nameValue}
              onChange={(e) => {
                setNameValue(e.target.value)
              }}
            />
            <TextField
              label="Customer Email"
              variant="outlined"
              fullWidth
              defaultValue={email}
              required
              value={emailValue}
              onChange={(e) => {
                setEmailValue(e.target.value)
              }}
            />
            <FormControl variant="outlined" fullWidth style={{ marginBottom: 20 }}>
              <InputLabel>Customer Utility</InputLabel>
              <Select label="Customer Utility" value={utilityValue} onChange={(e) => setUtilityValue(e.target.value)}>
                {Object.entries(utilities).map((utility) => (
                  <MenuItem key={utility[0]} value={utility[0]}>
                    {utility[1]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ width: '100%', backgroundColor: '#f5f5f5' }}>
            <div style={{ width: '60%', margin: 'auto' }}>
              <Typography
                variant="h7"
                onClick={() => {
                  setShowAdvanced(!showAdvanced)
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                Advanced Options
                {showAdvanced ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Typography>
              {showAdvanced && (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                  <FormControl variant="outlined" fullWidth style={{ backgroundColor: 'white', marginBottom: 20 }}>
                    <InputLabel>Expire access</InputLabel>
                    <Select
                      label="Expire Access"
                      defaultValue="3y"
                      onChange={(e) => setExpireAccessValue(e.target.value)}
                    >
                      {ExpireAccessOptions.map((option) => (
                        <MenuItem key={option.id} value={option.expires}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
            </div>
          </div>
          <div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: 15,
                paddingBottom: 25,
              }}
            >
              <Button style={{ marginRight: 5 }} variant="contained" onClick={() => isClose()}>
                <span>Cancel</span>
              </Button>
              <Button type="submit" style={{ marginLeft: 5 }} variant="outlined">
                <span>Send Data Request</span>
              </Button>
            </div>
          </div>
        </form>
      </Dialog>
    </div>
  )
}

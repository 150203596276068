import { Grid, makeStyles } from '@material-ui/core'
import Alert from 'elements/Alert'
import React from 'react'
import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  List,
  NumberField,
  NumberInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin'

const useStyles = makeStyles(() => ({
  col: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  btnWarpper: {
    margin: '5px 0px',
  },
  row: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
}))

export const PaymentFactorList = (props) => {
  return (
    <List hasSearch={true} {...props} hasCreate={false}>
      <Datagrid>
        <TextField source="integration" />
        <TextField source="product_type" />
        <TextField source="state_code" />
        <NumberField source="interest_rate" />
        <NumberField source="payment_periods" />
        <NumberField source="prepayment_percentage" />
        <NumberField source="prepayment_period" />
        <EditButton useListStyle={true} label="Edit" />
      </Datagrid>
    </List>
  )
}

const PaymentFactorInputFields = (props) => {
  const validateJSON = (value) => {
    if (!value) return undefined
    try {
      JSON.parse(value)
      return undefined
    } catch (ex) {
      return 'Invalid JSON'
    }
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Alert severity="warning">
          <span>
            Warning, editing payment factors will result in changes to the calculations that users see in the Payment
            Option Selection Dialog. Please only edit this record if you are certain you know how payment factors are
            used in this feature
          </span>
        </Alert>
      </Grid>
      <Grid item xs={4}>
        <TextInput source="integration" disabled={true} />
      </Grid>
      <Grid item xs={4}>
        <TextInput source="product_type" disabled={true} />
      </Grid>
      <Grid item xs={4}>
        <NumberInput source="interest_rate" disabled={true} />
      </Grid>
      <Grid item xs={4}>
        <NumberInput source="payment_periods" disabled={true} />
      </Grid>
      <Grid item xs={4}>
        <NumberInput source="prepayment_percentage" disabled={true} />
      </Grid>
      <Grid item xs={4}>
        <NumberInput source="prepayment_period" disabled={true} />
      </Grid>
      <Grid item xs={12}>
        <h4>Payment Factor JSON</h4>
        <TextInput
          label="Payment Factor JSON"
          source="payment_factors"
          fullWidth
          validate={validateJSON}
          multiline={true}
          rows={30}
          InputProps={{
            classes: { input: 'code-block' },
          }}
        />
      </Grid>
    </Grid>
  )
}

export const PaymentFactorEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <PaymentFactorInputFields {...props} />
    </SimpleForm>
  </Edit>
)

export const PaymentFactorCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm initialValues={{ enabled: true }}>
        <PaymentFactorInputFields {...props} />
      </SimpleForm>
    </Create>
  )
}

import type { OpenSolarThemeType } from 'Themes'
import { orderSelectors } from 'ducks/orderComponents'
import InfoTooltip from 'elements/tooltip/InfoTooltip'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  titleTextAndIcon: {
    marginRight: '5px',
    lineHeight: '24px',
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
  },
}))

const TableTitle = () => {
  const classes = useStyles()
  const unconfirmedOrderProjects = useSelector(orderSelectors.getUnconfirmedOrderProjectIds)
  return (
    <>
      <div className={classes.titleTextAndIcon}>Add hardware by project ({unconfirmedOrderProjects.length})</div>
      <InfoTooltip
        trackSource="hardware_add_by_project"
        title={
          'You can add hardware per project here. Select the checkbox for a project to review the items for that project'
        }
      />
    </>
  )
}

export default memo(TableTitle)

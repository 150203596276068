import { Grid, Paper } from '@material-ui/core'
import { NumberInput, useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'
import { styles } from '../styles.js'

export const ReversionRate = ({ resource, disabled = false, style: disabledStyle = null }) => {
  const formState = useFormState()
  const paymentFactorsEnabled = !!formState.values?.variable_payment_factors_enabled
  const translate = useTranslate()

  return (
    <Paper style={disabled && disabledStyle ? disabledStyle : styles.loanWrapper}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h1>{translate('Reversion Rate')}</h1>
        </Grid>
        <Grid item xs={3}>
          <NumberInput
            resource={resource}
            style={disabled ? disabledStyle : styles.inputFieldLessWide}
            label={'Reversion Rate'}
            source={'reversion_rate'}
            name={'reversion_rate'}
            endAdornment={'% p.a.'}
            disabled={disabled || paymentFactorsEnabled}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <NumberInput
            step={1}
            resource={resource}
            style={styles.inputFieldLessWide}
            label={'Start of Reversion Period'}
            source={'start_of_reversion_period'}
            name={'start_of_reversion_period'}
            endAdornment={'periods'}
            disabled={disabled}
            fullWidth
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

// @ts-nocheck
import { makeStyles } from '@material-ui/core'
import React from 'react'
import { useTranslate } from 'react-admin'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'
import { EmailButtonHome } from 'resources/projects/projectsList/ContactButtonsHome'
import { formatDateStringBaseOnLocale } from 'util/misc'
import { NoCustomerActivities } from './NotAvailable'

const useStyles = makeStyles((theme) => ({}))

const timeSince = (date: any) => {
  var seconds = Math.abs(Math.floor((new Date() - date) / 1000))

  var interval = Math.floor(seconds / 31536000)

  if (interval > 1) {
    return interval + ' years'
  }
  interval = Math.floor(seconds / 2592000)
  if (interval > 1) {
    return interval + ' months'
  }
  interval = Math.floor(seconds / 86400)
  if (interval > 1) {
    return interval + ' days'
  }
  interval = Math.floor(seconds / 3600)
  if (interval > 1) {
    return interval + ' hours'
  }
  interval = Math.floor(seconds / 60)
  if (interval > 1) {
    return interval + ' minutes'
  }
  return Math.floor(seconds) + ' seconds'
}

const ActivityRow = ({ record }: any) => {
  const translate = useTranslate()

  var date = null
  if (record.completion_date) {
    // date_label = 'Completed'
    date = new Date(record.completion_date.replace(/ /gi, 'T'))
  } else if (record.start) {
    // date_label = 'Scheduled'
    date = new Date(record.start.replace(/ /gi, 'T'))
  } else {
    // date_label = null
    date = null
  }

  // Show relative time if less than 30 days ago
  var notes
  var scheduleStatus = null
  if (date) {
    var date_diff = date - Date.now()

    if (date_diff > 0 && date_diff < 24 * 60 * 60 * 1000 * 30) {
      notes = 'In ' + timeSince(date)
      scheduleStatus = 'future'
    } else if (date_diff < 0 && date_diff > -24 * 60 * 60 * 1000 * 30) {
      notes = timeSince(date) + ' ago'
      scheduleStatus = 'past'
    } else {
      notes = formatDateStringBaseOnLocale(new Date(date))
      scheduleStatus = 'future'
    }
  } else {
    notes = ''
    scheduleStatus = null
  }

  const isOverdue = record.task_status === 1 && scheduleStatus === 'past'

  return (
    <div style={{ display: 'flex', opacity: record.is_archived ? 0.5 : 1.0 }}>
      <div style={{ flex: '1 1 0' }}>
        <div style={{ display: 'flex' }}>
          <span
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              flexGrow: 1,
              width: 100,
              fontSize: 12,
            }}
          >
            {record.event_type_id !== 0 && record.notes && record.notes.length ? record.notes : record.title}
          </span>
          {record.task_status === 1 && isOverdue && (
            <span className="small" style={{ color: 'red', padding: 1, border: '1px solid red', marginRight: 5 }}>
              {translate('OVERDUE')}
            </span>
          )}
          {record.task_status === 1 && !isOverdue && (
            <span className="small" style={{ color: 'red', padding: 1, border: '1px solid red' }}>
              {translate('INCOMPLETE')}
            </span>
          )}
        </div>
        <div className="small" style={{ padding: '4px 2px 15px 2px' }}>
          {notes && <span>{notes} </span>}
          {record.project_id && (
            <Link to={`/projects/${record.project_id}/info`} style={{ color: '#a2a2a2' }}>
              {record.project_name}
            </Link>
          )}
          {record.who && (
            <div style={{ float: 'right', marginTop: -8 }}>
              <EmailButtonHome contact={record.who} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const CustomerActivity = ({ data }: any) => {
  if (!data) {
    const NumberToRepeat = 15
    return (
      <div style={{ margin: '10px 16px' }}>
        {new Array(NumberToRepeat).fill(1).map((a, index) => {
          return <Skeleton key={index} height={'30px'} style={{ margin: '5px 0' }} />
        })}
      </div>
    )
  }
  return (
    <div style={{ margin: '10px 16px' }}>
      {data.length === 0 ? (
        <NoCustomerActivities />
      ) : (
        data.map((record: any) => <ActivityRow key={record.id} record={record} />)
      )}
    </div>
  )
}

export default CustomerActivity

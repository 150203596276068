import { Grid } from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import { addAccordionFormsAndSort } from 'customForm/card/addAccordionFormsAndSort'
import { FormPlacementInfo } from 'customForm/util/findFormPlacements'
import { UiKey } from 'elements/UiKey'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import React, { FC, useMemo } from 'react'
import { useTranslate } from 'react-admin'
import { ContactType } from 'types/contacts'
import { ContactFields } from './ContactFields'
import { useContactFormStyles } from './useContactFormStyles'

interface ContactFieldsProps extends Omit<ContactFormProps, 'customForms'> {}

export const ContactAccordion: FC<ContactFieldsProps> = ({ id, uiKey, prefix, contact }) => {
  const translate = useTranslate()
  return (
    <AccordionCard
      defaultExpanded={true}
      name={'contact-accordion'}
      title={translate('Contact Information')}
      content={ContactFields}
      titleIcon={PersonIcon}
      contentProps={{ id, uiKey, prefix, contact }}
    />
  )
}

export interface ContactFormProps {
  uiKey: string
  customForms?: FormPlacementInfo[]
  id?: string
  prefix?: string
  contact?: ContactType | null
}

export const ContactForms: FC<ContactFormProps> = (props) => {
  const classes = useContactFormStyles()
  const sortAndRenderComponents = useMemo(() => {
    let toRender = [
      {
        component: ContactAccordion,
        shouldRender: true,
        priority: 1,
        props,
      },
    ]

    return addAccordionFormsAndSort(toRender, props?.customForms || [])
  }, [props])

  return (
    <Grid className={classes.detail} style={{ width: '100%' }}>
      <UiKey uiKey={props.uiKey}>
        <>{sortAndRenderComponents}</>
      </UiKey>
    </Grid>
  )
}

import { logAmplitudeEvent } from 'amplitude/amplitude'
import HorizontalDropdownWithLabel from 'pages/inventory/components/HorizontalDropdownWithLabel'
import React, { useCallback, useMemo } from 'react'
import type { InventoryType } from '../type'

const ComponentForecastDialogToolbar = ({
  selectedCode,
  handleCodeUpdate,
  inventoryData,
}: {
  inventoryData: InventoryType[]
  handleCodeUpdate(code: string): void
  selectedCode: string | undefined
}) => {
  const handleSelected = useCallback(
    (code: string) => {
      handleCodeUpdate(code)
      logAmplitudeEvent('generic_dropdown_interacted', {
        source: 'component_selector',
        action: 'inventory_forecast_component_selected',
      })
    },
    [inventoryData]
  )

  const options = useMemo(() => inventoryData.map((inventory) => ({ value: inventory.code, name: inventory.code })), [
    inventoryData,
  ])

  return (
    <HorizontalDropdownWithLabel
      label={'Component'}
      value={selectedCode}
      handleSelect={handleSelected}
      options={options}
    />
  )
}

export default ComponentForecastDialogToolbar

import { Theme, useMediaQuery } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { useNotify } from 'ra-core'
import React, { useState } from 'react'
import restClient from 'restClient'
import { ProjectType } from 'types/projects'
import { US_FINCOS } from '../constants'
import DividendChangeOrderCTA from './DividendChangeOrderCTA'

const restClientInstance = restClient(window.API_ROOT + '/api')

const LENDER_NAME_MAP = {
  dividend: 'Dividend',
}

type PropTypes = {
  changeOrderIntegration: typeof US_FINCOS[number]
  project: ProjectType
}

const SendChangeOrderCTA: React.FC<PropTypes> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 967))
  const isXxs = useMediaQuery((theme: Theme) => theme.breakpoints.down(332))

  const notify = useNotify()

  const sendChangeOrder = (doChangeOrder: boolean) => {
    setIsLoading(true)
    const transactionType = doChangeOrder ? 'change order' : 'project update'
    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${props.project.org_id}/projects/${props.project.id}/${props.changeOrderIntegration}/change_order/`,
      data: {
        do_change_order: doChangeOrder,
      },
    })
      .then((res) => {
        if (res.data.not_needed) {
          notify(
            `The project info in the lender's portal is already up-to-date. No ${transactionType} was submitted.`,
            'info'
          )
          logAmplitudeEvent('finance_change_order_submitted_no_update', {
            project_id: props.project.id,
            integration: props.changeOrderIntegration,
            change_order_enabled: doChangeOrder,
          })
        } else if (res.data.success) {
          let message =
            res.data.message ||
            `The ${transactionType} has been submitted to ${LENDER_NAME_MAP[props.changeOrderIntegration]}`
          notify(message, 'success')
          logAmplitudeEvent('finance_change_order_submitted', {
            project_id: props.project.id,
            integration: props.changeOrderIntegration,
            change_order_enabled: doChangeOrder,
          })
        } else {
          let message = res.data.message || `Unable to submit ${transactionType}`
          notify(message, 'warning')
          logAmplitudeEvent('finance_change_order_error', {
            project_id: props.project.id,
            integration: props.changeOrderIntegration,
            change_order_enabled: doChangeOrder,
          })
        }
      })
      .catch((err) => {
        if (err?.body?.message) {
          notify(err.body.message, 'warning')
        } else {
          notify(`Unable to submit ${transactionType}`, 'warning')
        }
        logAmplitudeEvent('finance_change_order_error', {
          project_id: props.project.id,
          integration: props.changeOrderIntegration,
          change_order_enabled: doChangeOrder,
        })
      })
      .finally(() => setIsLoading(false))
  }

  if (
    !props.project ||
    !props.changeOrderIntegration ||
    !props.project.payment_option_sold ||
    !props.project.system_sold
  )
    return null

  if (props.changeOrderIntegration === 'dividend')
    return <DividendChangeOrderCTA {...props} sendChangeOrder={sendChangeOrder} isLoading={isLoading} />
  else return null
}
export default SendChangeOrderCTA

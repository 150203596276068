import { useTranslate } from 'ra-core'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import useTranslateParse from 'util/useTranslateParse'
import { FormRouteInfoPropTypes } from '../types'

const useStyles = makeOpenSolarStyles((theme) => ({
  header: { fontSize: 20, fontWeight: 500, marginTop: 0, marginBottom: 0 },
  description: {
    lineHeight: 1.5,
  },
}))

const VerifyBusinessTop: React.FC<FormRouteInfoPropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const translateParse = useTranslateParse()

  return (
    <div data-testid="bluesnap-verify-business-top">
      <h1 className={classes.header}>{translate("Let's verify your business")}</h1>
      <p className={classes.description}>
        {translate('We use this information to confirm your business identity.')} <br />
        {translateParse(
          'Please enter your information <strong> exactly <strong> as it appears on your official tax documents.',
          {
            strong: (label: string) => <strong>{label}</strong>,
          }
        )}
      </p>
    </div>
  )
}
export default VerifyBusinessTop

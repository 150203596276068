import { Typography } from '@material-ui/core'
import { styled } from 'opensolar-ui'
import { useContext } from 'react'
import { OrderContext } from '../..'
import { COLOR_PALETTE } from '../../../manage3/styles'
import OrderActivity from './OrderActivity'

export const FullWidthWrapper = styled('div')({
  width: '100%',
})

export const SectionCardTop = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: 20,
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  borderBottom: '1px solid ' + COLOR_PALETTE.lightGrey,
}))

export const SectionCardMiddle = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: 20,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  borderBottom: '1px solid ' + COLOR_PALETTE.lightGrey,
}))

export const SectionCardBottom = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: 20,
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
}))

const OrderActivitySection = () => {
  const { orderData } = useContext(OrderContext)

  return (
    <FullWidthWrapper>
      <SectionCardTop>
        <Typography variant="h6">Order Activity</Typography>
      </SectionCardTop>
      <SectionCardBottom>
        <OrderActivity orderData={orderData} />
      </SectionCardBottom>
    </FullWidthWrapper>
  )
}

export default OrderActivitySection

import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined'
import { Button } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import DefaultTooltip from '../fuji/DefaultToolTip'
import { ToolTipPropsType, TourConfigType } from '../types'

const Tooltip = (props: ToolTipPropsType) => {
  const { handleNext, handlePrev, handleCancel } = props
  const translate = useTranslate()
  return (
    <DefaultTooltip
      title={translate('Add Payment Options')}
      // onClose={handleCancel}
      icon={LayersOutlinedIcon}
      content={
        <div>
          <div>
            {translate('Click the "Add Payment Options button to learn how to explore and add new payment options')}
          </div>
        </div>
      }
      actions={
        <>
          <Button
            onClick={() => handlePrev && handlePrev()}
            onTouchStart={(e) => {
              e.stopPropagation()
              if (handlePrev) {
                handlePrev()
              }
            }}
          >
            <span>{translate('Back')}</span>
          </Button>
          <Button disabled={true}>
            <span>{translate('Next')}</span>
          </Button>
        </>
      }
      arrowPlacement={'right'}
    />
  )
}

const OpenPaymentOptionDialogStep: TourConfigType = {
  spotlights: [{ targetId: 'add-pmt-dialog-open-button-0', disableBackgroundScroll: false, padding: 5 }],
  tooltips: [{ targetId: 'add-pmt-dialog-open-button-0', placement: 'left', component: Tooltip }],
}

export default OpenPaymentOptionDialogStep

// @ts-nocheck
import React, { useContext } from 'react'
// import ProUXButton from './ProUXButton'
import ContentSave from '@material-ui/icons/SaveOutlined'
import Button from 'elements/button/Button'
import { FormContext } from 'react-admin'
import { SaveButtonPropTypes } from './types'

const ProUXSaveButton: React.FunctionComponent<SaveButtonPropTypes> = (props) => {
  const onClick = () => {
    //stolen from react-admin's save button logic. Set onSave within the form's context
    if (props.onSave) context.setOnSave(props.onSave)
    else context.setOnSave()
    props.handleSubmitWithRedirect(props.redirect)
  }
  const context: { setOnSave: (fn?: Function) => void } = useContext(FormContext)

  return (
    // <ProUXButton
    <Button
      variant="contained"
      color="primary"
      disabled={props.invalid || props.disabled}
      label={props.label}
      onClick={onClick}
      startIcon={<ContentSave />}
      loading={props.saving}
      hideLabel={false}
    />
  )
}
export default ProUXSaveButton

import TourTooltip from 'elements/tooltip/TourTooltip'
import { TourConfigType } from 'persistentContent/tours/types'
import { useTranslate } from 'ra-core'
import { FC } from 'react'

const StepTooltip: FC = (props) => {
  const translate = useTranslate()
  return (
    <TourTooltip
      title={translate('New Table View')}
      subtext={translate('Gain insights into the status of your projects using our updated project list page.')}
      {...props}
    />
  )
}

const ProjectList: TourConfigType = {
  spotlights: [{ targetId: 'Project-List-Section' }],
  tooltips: [
    {
      targetId: 'Project-List-Section',
      placement: 'top-start',
      component: StepTooltip,
    },
  ],
}

export default ProjectList

import { HelpLink } from 'elements/link/HelpLink'
import React from 'react'
import { useTranslate } from 'react-admin'
import { WorkflowCreate, WorkflowEdit, WorkflowList } from './Workflows'

const WorkflowsTitle = ({ record, style }) => {
  const translate = useTranslate()
  return (
    <span style={style}>
      {translate('Project Workflows')}: {record ? `"${record.title}"` : ''}
    </span>
  )
}

const Subtitle = () => {
  const translate = useTranslate()
  return (
    <span>
      {translate(
        "Ensure your team is staying on track with their projects by using Project Workflows to customise project stages and actions to match your business' needs."
      )}
      <HelpLink href="https://support.opensolar.com/hc/en-us/articles/8530529693967" />
    </span>
  )
}

export default {
  list: WorkflowList,
  edit: WorkflowEdit,
  create: WorkflowCreate,
  options: {
    create: {
      title: 'Create Workflow',
      subtitle: null,
      breadCrumb: 'Create Workflow',
    },
    list: {
      title: 'Project Workflows',
      subtitle: <Subtitle />,
      breadCrumb: 'Project Workflows',
    },
    edit: {
      title: <WorkflowsTitle />,
      subtitle: null,
      breadCrumb: 'Edit Workflow',
    },
  },
}

export const PVSELL_PROPOSALS_SET = 'PVSELL_PROPOSALS_SET'
export const PVSELL_PROPOSAL_SELECT = 'PVSELL_PROPOSAL_SELECT'
export const PVSELL_PROJECT_SELECT = 'PVSELL_PROJECT_SELECT'
export const SET_PVSELL_DIALOG_VISIBILITY = 'SET_PVSELL_DIALOG_VISIBILITY'

export const setPvsellDialogVisibility = (isOpen) => ({
  type: SET_PVSELL_DIALOG_VISIBILITY,
  payload: isOpen,
})

export const setPvsellProposals = (proposals) => ({
  type: PVSELL_PROPOSALS_SET,
  payload: {
    proposals: proposals,
  },
})

export const selectPvsellProposal = (selected_proposal_id) => ({
  type: PVSELL_PROPOSAL_SELECT,
  payload: {
    selected_proposal_id: selected_proposal_id,
  },
})

export const selectPvsellProject = (project_id) => ({
  type: PVSELL_PROJECT_SELECT,
  payload: {
    project_id: project_id,
  },
})

const injectProposalId = (proposals) => {
  var proposal_id
  proposals.forEach((proposal) => {
    proposal_id = proposal.update_url.split('id=')[1].split('&')[0]
    proposal['proposal_id'] = proposal_id
  })

  return proposals
}

export default (previousState = {}, { type, meta, payload, requestPayload }) => {
  if (!previousState) {
    previousState = { proposals: [], selected_proposal_id: null }
  }

  if (type === SET_PVSELL_DIALOG_VISIBILITY) {
    previousState = {
      ...previousState,
      pvsellDialogVisibility: payload,
    }
  }

  if (type === PVSELL_PROPOSALS_SET) {
    var proposals = payload.proposals && payload.proposals.length > 0 ? injectProposalId(payload.proposals) : []

    previousState = {
      ...previousState,
      proposals: proposals,

      //@TODO: Gracefully handle scenarios when no proposal templates exist. Needs UX
      // e.g. show error message, disable buttons, explain how to add proposal templates with link to SunWiz.
      selected_proposal_id: proposals && proposals.length > 0 ? proposals[0].proposal_id : null,
    }
  }

  if (type === PVSELL_PROPOSAL_SELECT) {
    previousState = {
      ...previousState,
      selected_proposal_id: payload.selected_proposal_id,
    }
  }

  if (type === PVSELL_PROJECT_SELECT) {
    previousState = {
      ...previousState,
      project_id: payload.project_id,
    }
  }

  return previousState
}

export const defaultContextConfig: PlaceholderContext = {
  systems: [{ label: 'System', path: 'system' }],
  project_roles: [
    { label: 'Assigned Team Member', path: 'assigned_team_member', hidden_in_picker: true },
    { label: 'Assigned Team Member', path: 'assigned_role' },
    { label: 'Assigned Installer', path: 'assigned_installer_role' },
    { label: 'Assigned Designer', path: 'assigned_designer_role' },
    { label: 'Assigned Site Inspector', path: 'assigned_site_inspector_role' },
    { label: 'Assigned Salesperson', path: 'assigned_salesperson_role' },
  ],
  contacts: [
    { label: 'Contact %{num}', path: 'contacts.0' },
    { label: 'Contact %{num}', path: 'contacts.1' },
    { label: 'Contact %{num}', path: 'contacts.2' },
  ],
}

export const contextConfigMap = {
  change_order: {
    ...defaultContextConfig,
    systems: [
      { label: 'Original System', path: 'original_system' },
      { label: 'Revised System', path: 'system' },
    ],
  },
}

export type PlaceholderContext = {
  project_roles: PlaceholderEntity[]
  systems: PlaceholderEntity[]
  contacts: PlaceholderEntity[]
}

type PlaceholderEntity = {
  label: string
  path: string
  hidden_in_picker?: boolean
}

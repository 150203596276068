import { Paper } from '@material-ui/core'
import { Button } from 'opensolar-ui'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withTranslate } from 'react-admin'
import Utils from '../Studio/Utils'
import DirectionAndMagnitudeField from '../elements/field/DirectionAndMagnitudeField'

const maxSkew = 40

class PanelFixSkew extends Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: Boolean(props.visible),
      bearing: null,
      magnitudeInDegrees: null,
      fraction: { x: 0, y: 0.01 },
    }
  }

  componentDidMount() {}

  open() {
    this.setState({ visible: true })
    if (window.SceneHelper) {
      window.SceneHelper.skewGuideShow()
    }
  }

  close() {
    this.setState({ visible: false })
    if (window.SceneHelper) {
      window.SceneHelper.skewGuideHide()
    }
  }

  done() {
    this.close()
  }

  setAzimuthAndMagnitude(fractionFromCenter) {
    const data = this.fractionToAzimuthAndMagnitude(fractionFromCenter)

    var bearing = Math.round(data.bearing)
    var magnitudeInDegrees = Math.round(maxSkew * data.magnitude)

    if (window.ViewHelper) {
      //window.ViewHelper.setSkew(bearing, magnitudeInDegrees)
      var oldValue = this.state
      window.editor.execute(
        new window.SetSkewCommand(window.ViewHelper, bearing, magnitudeInDegrees, oldValue, fractionFromCenter)
      )

      // var cameraParams = window.ViewHelper.getCameraParams()
      //
      // var orientationCorrection = {
      //     bearing: Math.round(data.azimuth),
      //     magnitudeInDegrees: Math.round(maxSkew * data.magnitude)
      // }
      //
      // window.editor.loadCamera(null, window.ViewHelper.createCameraParams( 'top', orientationCorrection, new window.THREE.Vector3().fromArray(cameraParams.center) ))
      //
      // var isNew = true
      // window.editor.signals.cameraChanged.dispatch( window.editor.camera, isNew )
      // window.editor.signals.escapePressed.dispatch()
    }

    this.setState({
      bearing: bearing,
      magnitudeInDegrees: magnitudeInDegrees,
      fraction: fractionFromCenter,
    })
  }

  eventToFraction(e) {
    var elementRect = e.currentTarget.getBoundingClientRect()
    var positionRelativeToElement = {
      x: e.clientX - elementRect.left,
      y: e.clientY - elementRect.top,
    }
    var fractionFromTopLeft = {
      x: positionRelativeToElement.x / elementRect.width,
      y: positionRelativeToElement.y / elementRect.height,
    }
    var fractionFromCenter = {
      x: (fractionFromTopLeft.x - 0.5) * 2,
      y: (fractionFromTopLeft.y - 0.5) * 2,
    }
    // console.log('fractionFromTopLeft', fractionFromTopLeft)
    // console.log('fractionFromCenter', fractionFromCenter)

    return fractionFromCenter
  }

  fractionToAzimuthAndMagnitude(fractionFromCenter) {
    var bearing = Utils.bearing({ x: 0, y: 0 }, { x: fractionFromCenter.x, y: -1 * fractionFromCenter.y })
    // console.log('bearing', bearing)

    var fractionDistance = Math.pow(Math.pow(fractionFromCenter.x, 2) + Math.pow(fractionFromCenter.y, 2), 0.5)
    // console.log('fractionDistance', fractionDistance)
    return {
      bearing: bearing,
      magnitude: fractionDistance,
    }
  }

  render() {
    if (!this.state.visible) {
      return null
    }
    const { translate } = this.props
    return (
      <Paper
        style={Object.assign(
          {
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            padding: 20,
          },
          this.props.style
        )}
      >
        <DirectionAndMagnitudeField
          // ref={(element) => {
          //   if (element) referenceSave.call(element, 'DirectionAndMagnitudeField')
          //   else referenceClear('DirectionAndMagnitudeField')
          // }}
          onChange={(fractionFromCenter) => {
            this.setAzimuthAndMagnitude(fractionFromCenter)
          }}
          bearing={this.state.bearing}
          style={{
            cursor: 'pointer',
            width: 100,
            height: 100,
            float: 'left',
            position: 'relative',
          }}
        />

        {/*
                <Paper circle={true} style={{cursor: 'pointer', width:100, height:100, float: 'left', position:'relative'}} onClick={(e) => this.setAzimuthAndMagnitude(this.eventToFraction(e))}>30
                    <Paper circle={true} style={{width: 66, height: 66, position:'absolute', top: 16, left: 16}}>20</Paper>
                    <Paper circle={true} style={{width: 33, height: 33, position:'absolute', top: 33, left: 33}}>10</Paper>
                </Paper>
                */}

        <div style={{ float: 'left', marginLeft: 20, width: 140 }}>
          <div style={{}}>
            {translate('Skew direction:')}{' '}
            {this.state.bearing !== null && this.state.magnitudeInDegrees > 0
              ? this.state.bearing
              : translate('Click in circle to set')}
          </div>
          <br />
          <div style={{}}>
            {translate('Skew amount:')}{' '}
            {this.state.magnitudeInDegrees !== null && this.state.magnitudeInDegrees > 0
              ? this.state.magnitudeInDegrees
              : translate('Click in circle to set')}
          </div>
          <Button
            onClick={() =>
              window.editor.execute(new window.SetSkewCommand(window.ViewHelper, undefined, undefined, {}, {}, 'close'))
            }
          >
            <span>{translate('OK')}</span>
          </Button>
        </div>
        <div style={{ clear: 'both' }} />
      </Paper>
    )
  }
}

PanelFixSkew.propTypes = {
  visible: PropTypes.bool,
}

PanelFixSkew.defaultProps = {
  visible: true,
}

// const mapStateToProps = state => ({
//     // projectId: state.projectId,
//     // recentProjects: state.recentProjects,
//     // zone: state.zone,
// });
//
// export default withRouter(connect(mapStateToProps, {})(PanelFixSkew));
export default withTranslate(PanelFixSkew)

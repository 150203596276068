import { Divider, Grid, Typography } from '@material-ui/core'
import { orderSelectors } from 'ducks/orderComponents'
import { memo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { TotalCost } from '../TotalCost'
import { orgSelectors } from 'ducks/orgs'
import { formatCurrency } from 'util/misc'
import { getOrderCost } from '../../utils'
import { CartDiscount, getCartDiscount, getCartDiscountValue } from '../../order/CartDiscounts'

const OrderSummary = ({ currencySymbol }: { currencySymbol: string | undefined }) => {
  const [cartDiscount, setCartDiscount] = useState<CartDiscount>(null)

  const lineItems = useSelector(orderSelectors.getOrderLineItems)
  const countryIso2 = useSelector(orgSelectors.getOrgIso2)
  const selectedSupplier = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const itemsCost = getOrderCost(lineItems, false)
  const costWithDiscounts = getOrderCost(lineItems, true)
  const tradeDiscountValue = itemsCost - costWithDiscounts
  const cartDiscountValue = getCartDiscountValue(costWithDiscounts, cartDiscount)
  const subtotal = costWithDiscounts - cartDiscountValue
  const taxPercent = countryIso2 === 'GB' ? 20 : 23
  const tax = (subtotal * taxPercent) / 100

  useEffect(() => {
    getCartDiscount(lineItems, selectedSupplier, (cartDiscount) => {
      setCartDiscount(cartDiscount)
    })
  }, [lineItems, selectedSupplier])


  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Order summary
      </Typography>
      <Grid container>
        <Grid item xs={8}>
          <Typography variant="body1" gutterBottom>
            Items:
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" gutterBottom>
            {currencySymbol} {formatCurrency(itemsCost)}
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography variant="body1" gutterBottom>
            Trade Discount:
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" gutterBottom>
            -{currencySymbol} {formatCurrency(tradeDiscountValue)}
          </Typography>
        </Grid>

        <Divider style={{ width: 100, height: 2, margin: '10px 0', marginLeft: 'auto' }} />

        <Grid item xs={8}>
          <Typography variant="body1" gutterBottom>
            VAT ({taxPercent}%):
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" gutterBottom>
            {currencySymbol} {formatCurrency(tax)}
          </Typography>
        </Grid>
        <Divider style={{ width: '100%', height: 2, margin: '20px 0' }} />
        <TotalCost loading={false} costWithCurrencySymbol={`${currencySymbol} ${formatCurrency(subtotal + tax)}`} />
      </Grid>
    </div>
  )
}

export default memo(OrderSummary)

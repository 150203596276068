import { findFormPlacements, FindPlacementsOpts, FormPlacementInfo } from 'customForm/util/findFormPlacements'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { CustomFormUsageType } from 'resources/customForms/types'
import useMemoizedSelector from 'util/useMemoizedSelector'

type UseGetCustomFormsOpts = Omit<FindPlacementsOpts, 'forms'> & {
  forms?: CustomFormUsageType[]
}

const useGetCustomForms = (opts: UseGetCustomFormsOpts): FormPlacementInfo[] => {
  const [forms, setForms] = useState<FormPlacementInfo[]>([])

  const orgCustomForms = useMemoizedSelector(orgSelectors.getCustomForms)
  const isStaff = useSelector(authSelectors.getIsStaff)

  // As opts is normally passed through as an object literal, we need to use a deep comparison
  // Omit `opts.forms` as it can be compared normally
  const optsString = JSON.stringify({
    ...opts,
    forms: undefined,
  })

  useEffect(() => {
    const formPlacements = findFormPlacements({
      isStaff,
      ...opts,
      forms: opts.forms || orgCustomForms,
    })
    if (formPlacements) {
      setForms(formPlacements)
    }
  }, [orgCustomForms, optsString, opts.forms, isStaff])

  return forms
}

export default useGetCustomForms

import { Typography } from '@material-ui/core'

import { countryMapping } from 'constants/countries'
import { useNotify } from 'ra-core'
import { memo, useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import AddressAutocomplete from 'resources/projects/elements/AddressAutocomplete.js'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { RootState } from 'types/state'
import AddressCompletionForm from '../AddressCompletionForm'
import { BillingInfoContext, CheckoutPresenterContext, ShippingInfoContext } from './VtacCheckoutProvider'
import { VtacAddressInfoTypeEnum } from '../../../../services/vtac/type'

const useAddressFieldStyles = makeOpenSolarStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  clickableLink: {
    textDecoration: 'underline',
    color: '#1751D0',
    cursor: 'pointer',
  },
}))

const AddressField = ({ handleUpdateAddressInfo, infoType, addressInfo }) => {
  const notify = useNotify()

  const vtacCheckoutPresenter = useContext(CheckoutPresenterContext)
  const { firstname, lastname, city, state, street, postcode, country_id, contact_number } = addressInfo || {}
  const [editing, setEditing] = useState<boolean>(false)
  const [isValidAddress, setIsValidAddress] = useState<boolean>(true)
  const classes = useAddressFieldStyles()
  const apiKeyGoogle = useSelector((state: RootState) =>
    state.auth && state.auth.api_key_google ? state.auth.api_key_google : ''
  )

  const handleClick = () => {
    setEditing(!editing)
    setIsValidAddress(true)
  }

  const onPlaceSelected = (placeAsFields) => {
    const newAddressInfo = {
      ...(addressInfo || {}),
      street: placeAsFields.address,
      city: placeAsFields.locality,
      state: placeAsFields.state,
      postcode: placeAsFields.zip,
      country_id: countryMapping[placeAsFields.country_iso2]?.iso2,
    }
    const isValidAddressInfo =
      infoType === VtacAddressInfoTypeEnum.ShippingInfo
        ? vtacCheckoutPresenter?.validateShippingInfo(newAddressInfo)
        : vtacCheckoutPresenter?.validateBillingInfo(newAddressInfo)
    if (!isValidAddressInfo) {
      notify(
        'Your preferred address was not able to be automatically populated. Please complete your address manually',
        'warning'
      )
    }
    setEditing(!isValidAddressInfo)
    setIsValidAddress(!!isValidAddressInfo)
    handleUpdateAddressInfo(placeAsFields)
  }

  return (
    <div className={classes.container}>
      {editing ? (
        <div style={{ flexGrow: 1, paddingRight: 50 }}>
          {isValidAddress ? (
            <AddressAutocomplete onPlaceSelected={onPlaceSelected} api_key_google={apiKeyGoogle} types={['address']} />
          ) : (
            <AddressCompletionForm
              handleUpdateAddress={onPlaceSelected}
              initialValues={{
                address: street || '',
                locality: city || '',
                state: state || '',
                zip: postcode || '',
                country_iso2: country_id || 'GB', // TODO deal with PL?
              }}
              requiredFields={['address', 'locality', 'state', 'zip', 'country_iso2']}
            />
          )}
        </div>
      ) : (
        <div>
          {firstname && lastname && (
            <Typography variant="body1" gutterBottom>
              {firstname + ' ' + lastname}
            </Typography>
          )}
          {contact_number && (
            <Typography variant="body1" gutterBottom>
              {contact_number}
            </Typography>
          )}
          <Typography variant="body1" gutterBottom>
            {street}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {state} {city} {postcode} {country_id}
          </Typography>
        </div>
      )}
      <Typography variant="body1" gutterBottom className={classes.clickableLink} onClick={handleClick}>
        {editing ? 'cancel' : 'enter/update address'}
      </Typography>
    </div>
  )
}

const AddressInfo = ({ infoType }: { infoType: VtacAddressInfoTypeEnum }) => {
  const vtacCheckoutPresenter = useContext(CheckoutPresenterContext)
  const shippingAddress = useContext(ShippingInfoContext)
  const billingAddress = useContext(BillingInfoContext)
  const address = infoType === VtacAddressInfoTypeEnum.ShippingInfo ? shippingAddress : billingAddress

  const handleUpdateAddressInfo = (placeAsFields) => {
    const newAddressInfo = {
      ...(address || {}),
      street: placeAsFields.address,
      city: placeAsFields.locality,
      state: placeAsFields.state,
      postcode: placeAsFields.zip,
      country_id: countryMapping[placeAsFields.country_iso2]?.iso2,
    }
    if (infoType === VtacAddressInfoTypeEnum.ShippingInfo)
      vtacCheckoutPresenter?.updateShippingInfo(newAddressInfo)
    else vtacCheckoutPresenter?.updateBillingInfo(newAddressInfo)
  }

  return (
    <div>
      <AddressField handleUpdateAddressInfo={handleUpdateAddressInfo} infoType={infoType} addressInfo={address} />
    </div>
  )
}

export default memo(AddressInfo)

import { ShoppingCart, ShoppingCartOutlined } from '@material-ui/icons'
import ControlSelectedIcon from '@material-ui/icons/BusinessCenter'
import ControlIcon from '@material-ui/icons/BusinessCenterOutlined'
import ProjectsIcon from '@material-ui/icons/FolderOutlined'
import ProjectsSelectedIcon from '@material-ui/icons/FolderRounded'
import HomeIcon from '@material-ui/icons/HomeOutlined'
import HomeSelectedIcon from '@material-ui/icons/HomeRounded'
import { orgSelectors } from 'ducks/orgs'
import LogoOrTitle from 'myenergy/mainContent/components/LogoOrTitle'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useFeatureFlag } from 'util/split'

const useBottomNavigationIcons = () => {
  const isNav3Enabled = useFeatureFlag('nav_3', 'on')
  const zone = useSelector((state: any) => state.zone)
  const { zone_org: zoneOrg } = useSelector(orgSelectors.getOrg) || {}

  return useCallback(
    (zoneValue) => {
      const isZoneSelected = zoneValue === zone && !isNav3Enabled

      switch (true) {
        case zoneValue === 'home':
          return isZoneSelected ? <HomeSelectedIcon /> : <HomeIcon />

        case zoneValue === 'crm':
          return isZoneSelected ? <ProjectsSelectedIcon /> : <ProjectsIcon />

        case zoneValue === 'control':
          return isZoneSelected ? <ControlSelectedIcon /> : <ControlIcon />

        case zoneValue === 'shop':
          return isZoneSelected ? <ShoppingCart /> : <ShoppingCartOutlined />

        case zoneValue === 'cashflow':
          return <img src={`${window.PUBLIC_URL}/images/cashflow/cash-icon.svg`} />

        case zoneValue === 'zone':
          return (
            <LogoOrTitle
              allowUploadButton={false}
              org={zoneOrg}
              skipTitle={undefined}
              skipTitleStyle={undefined}
              style={{ maxWidth: 50, height: 24 }}
              type="mobilecobranding"
            />
          )

        default:
          break
      }
    },
    [isNav3Enabled, zone]
  )
}

export default useBottomNavigationIcons

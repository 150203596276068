import { TestimonialCreate, TestimonialEdit, TestimonialList } from './Testimonials'

const subtitle =
  "Make your proposal stand out with stories and images of successful projects you've completed. Please ensure that case studies are based on genuine projects completed by your organisation."

export default {
  list: TestimonialList,
  edit: TestimonialEdit,
  create: TestimonialCreate,
  options: {
    create: {
      title: 'Create Case Study',
      subtitle: subtitle,
      breadCrumb: 'Create Case Study',
    },
    list: {
      title: 'Case Studies',
      subtitle: subtitle,
      breadCrumb: 'Case Studies',
    },
    edit: {
      title: 'Edit Case Study',
      subtitle: subtitle,
      breadCrumb: 'Edit Case Study',
    },
  },
}

import { Grid } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import CustomField from 'elements/field/CustomField'
import DependentInput from 'elements/input/DependentInput'
import React, { FC } from 'react'
import { BooleanInput, NumberInput, SelectInput, TextInput, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { FREQUENCY_CHOICES, FREQUENCY_CHOICES_WITH_BILL, PRICE_BASED_INCENTIVE } from './IncentiveConstants'
import useStyle from './styles'

type AppliedOverTimeInputsType = {
  enableByUtilityBill: boolean
  enableNonDiscountIncentive: boolean
  enableFrequencySelection: boolean
  enableAdjustmentMultiplier: boolean
}

const AppliedOverTimeInputs: FC<AppliedOverTimeInputsType> = ({
  enableByUtilityBill = false,
  enableNonDiscountIncentive = true,
  enableFrequencySelection = true,
  enableAdjustmentMultiplier = true,
}) => {
  const translate = useTranslate()
  const form = useForm()
  const formState = useFormState()
  const classes = useStyle()
  const isEnabled = formState.values.is_applied_over_timeperiod
  const isAppliedToUtilityBill = formState.values.is_applied_to_utility_bill
  const incentiveType = formState.values.incentive_type

  const formatMultiplier = (raw: string | undefined) => {
    if (!raw) return raw
    // Remove all characters that aren't a digit, minus, dot, or comma
    return raw.replaceAll(/[^-,.0-9]/gi, '')
  }

  const validateMultiplier = (raw: string | undefined) => {
    if (!raw) return undefined
    const multi_strs = raw.split(',')
    const multipliers = multi_strs.filter((t) => t === parseFloat(t) + '') // Remove any entries that don't correctly parse to a float
    if (multi_strs.length !== multipliers.length) {
      const msg = translate("Multipliers aren't formatted correctly")
      return msg
    } else if (formState.values.terms && multipliers.length !== formState.values.terms) {
      const msg = translate('Amount of multipliers must match terms (%{terms})', { terms: formState.values.terms })
      return msg
    } else return undefined
  }

  return (
    <>
      <div className={Boolean(isEnabled) ? classes.containerWithBoxShadow : classes.container}>
        <BooleanInput
          label="Apply incentive over time period"
          name={'is_applied_over_timeperiod'}
          source="is_applied_over_timeperiod"
          className={classes.toggleField}
        />
        <DependentInput dependsOn={'is_applied_over_timeperiod'} value={true}>
          <Grid container spacing={3}>
            {enableByUtilityBill && incentiveType !== PRICE_BASED_INCENTIVE && (
              <Grid item className={classes.cell}>
                <CustomField
                  className={classes.defaultFieldStyle + ' ' + classes.toggleField}
                  label="Is applied to utility bill"
                  name={'is_applied_to_utility_bill'}
                  source="is_applied_to_utility_bill"
                  component={BooleanInput}
                  fullWidth={true}
                />
              </Grid>
            )}
            {enableNonDiscountIncentive && (
              <Grid item className={classes.cell}>
                <CustomField
                  className={classes.defaultFieldStyle + ' ' + classes.toggleField}
                  label="Use non discounted value in quotation"
                  name={'use_non_discounted_value_in_quotation'}
                  source="use_non_discounted_value_in_quotation"
                  component={BooleanInput}
                  fullWidth={true}
                />
              </Grid>
            )}
            {enableFrequencySelection && (
              <Grid item className={classes.cell}>
                <CustomField
                  className={classes.defaultFieldStyle}
                  label="Payment Frequency"
                  name={'frequency'}
                  source="frequency"
                  component={SelectInput}
                  // Is there potential Danger of invalid choices being stored in the data if we start off with
                  // isAppliedToUtilityBill=true with "Per Utility Billing Frequency" selected, but then we
                  // set isAppliedToUtilityBill=false?
                  choices={Boolean(isAppliedToUtilityBill) ? FREQUENCY_CHOICES_WITH_BILL : FREQUENCY_CHOICES}
                  fullWidth={true}
                />
              </Grid>
            )}
            <Grid item className={classes.cell}>
              <NumberInput
                className={classes.defaultFieldStyle}
                label="Number of terms"
                name={'terms'}
                source="terms"
                fullWidth={true}
              />
            </Grid>
            {enableAdjustmentMultiplier && (
              <Grid item className={classes.cell}>
                <TextInput
                  className={classes.defaultFieldStyle}
                  label="Multiplier by term"
                  name={'multiplier_by_terms'}
                  source="multiplier_by_terms"
                  fullWidth={true}
                  format={formatMultiplier}
                  validate={validateMultiplier}
                  endAdornment={<InputAdornment position="end">{`${translate('Comma separated values')} (${translate('Optional')})`}</InputAdornment>}
                />
              </Grid>
            )}
          </Grid>
        </DependentInput>
      </div>
    </>
  )
}

export default AppliedOverTimeInputs

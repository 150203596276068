import { List } from 'elements/react-admin/List'
import React from 'react'
import {
  AutocompleteInput,
  Datagrid,
  EmailField,
  FilterComp,
  ReferenceInput,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'

import { styles as standardStyles } from 'styles'
export const GlobalContactFilter = (props) => (
  <FilterComp {...props}>
    <TextInput label="pos.search" source="q" style={standardStyles.FILTER_FIELD_STYLE} />
    <ReferenceInput label="Org" source="org" reference="orgs">
      <AutocompleteInput optionText="name" optionValue="id" />
    </ReferenceInput>
  </FilterComp>
)

export const GlobalContactList = (props) => {
  const translate = useTranslate()
  return (
    <List hasSearch={true} filters={<GlobalContactFilter />} minQuantityToShowAsList={0} {...props}>
      <Datagrid cardTextStyle={{ padding: 15, height: 150, overflow: 'auto' }}>
        <TextField source="id" />
        <TextField source="display" sortable={false} />
        <TextField source="org_name" sortable={false} />
        <EmailField source="email" label={translate('Contact Email')} sortable={false} />
        <EmailField source="user_email" label={translate('User Email')} sortable={false} />
        <TextField source="phone" sortable={false} />
      </Datagrid>
    </List>
  )
}

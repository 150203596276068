import { EventType, InteractionStatus } from '@azure/msal-browser'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { useEffect, useMemo, useState } from 'react'
import { getMsalSegenSingletonInstance } from 'services/identity/getMsalSingletonInstance'
import SegenAuthenticationService from 'services/segen/SegenAuthenticationService'

export type SegenConnectStatus = 'startup' | 'processing' | 'authenticated' | 'unauthenticated'

const getSegenAuthenticationStatus = ({ inProgress, isAuthenticated, unableAcquireTokenSilently }) => {
  if (inProgress === InteractionStatus.Startup) {
    return 'startup'
  }

  if (inProgress !== InteractionStatus.None) {
    return 'processing'
  }

  if (isAuthenticated && !unableAcquireTokenSilently) {
    return 'authenticated'
  }

  return 'unauthenticated'
}

const useSegenAuthenticationStatus = (): SegenConnectStatus => {
  const { inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const [unableAcquireTokenSilently, setUnableAcquireTokenSilently] = useState(
    () => SegenAuthenticationService.unableAcquireAccessTokenSilently
  )

  const status = useMemo(
    () =>
      getSegenAuthenticationStatus({
        inProgress,
        isAuthenticated,
        unableAcquireTokenSilently,
      }),
    [inProgress, isAuthenticated, unableAcquireTokenSilently]
  )
  const msalSegenInstance = useMemo(() => getMsalSegenSingletonInstance(), [])

  useEffect(() => {
    /* Handle special case when return to OS App after long delay leaves Segen connection broken 
    useIsAuthenticated shows authenticated but InteractionRequiredAuthError threw when acquireTokenSilent
    more detail: https://github.com/open-solar/opensolar-todo/issues/9200
    */
    const callBackId = msalSegenInstance.addEventCallback((event) => {
      if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        setUnableAcquireTokenSilently(false)
      } else if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        setUnableAcquireTokenSilently(true)
        console.log('Acquire token failed, ', event.error)
      }
    })
    return () => {
      callBackId != null && msalSegenInstance.removeEventCallback(callBackId)
    }
  }, [])
  return status
}

export default useSegenAuthenticationStatus

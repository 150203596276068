import { FormControl, FormControlLabel, FormGroup, Grid } from '@material-ui/core'
import useOptimisticValue from 'elements/input/useOptimisticValue'
import { Checkbox } from 'opensolar-ui'
import { useEffect, useMemo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { Theme } from 'types/themes'
import type { BasicRendererComponentPropsType, OptionGenericType } from '../type'
import useLimitedOptionsController from './useLimitedOptionsController'
import useSearchInputController from './useSearchInputController'

const useStyles = makeOpenSolarStyles((theme: Theme) => ({
  title: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  filterOptions: {
    fontSize: '14px',
    fontWeight: 400,
    textAlign: 'left',
    margin: '0',
    padding: '0px',
    '& .MuiFormControlLabel-label': {
      fontWeight: 400,
      lineHeight: '20px',
    },
    '& .MuiIconButton-root': {
      padding: '3px 12px',
    },
  },
  showMore: {
    color: '#0094FF',
    margin: '0 10px',
    fontWeight: 400,
    cursor: 'pointer',
  },
}))

/*
  A Checkbox renderer for hardware filters
*/
const BasicMultiCheckboxRenderer = <T extends OptionGenericType<string | number>[]>({
  id,
  label,
  options,
  disabled,
  onSelect,
  defaultValue,
  value,
}: BasicRendererComponentPropsType<T>) => {
  const classes = useStyles({ disabled: !!disabled })

  const { optimisticValue, updateOptimisticValue } = useOptimisticValue<T[number]['value']>({
    defaultValue,
    value,
  })
  const shouldShowSearch = options.length > 4
  const { SearchInputNode, filteredOptions } = useSearchInputController({ options, disabled: !!disabled })
  // Limit the options to 4
  const { limitedOptions, LimitedOptionsController } = useLimitedOptionsController(filteredOptions)

  const checkedState = useMemo(() => {
    return optimisticValue
      .toString()
      .split(',')
      .reduce((acc, option) => ({ ...acc, [option]: true }), {})
  }, [optimisticValue])

  const handleChange = (option: T[number], isChecked: boolean) => {
    const defaultOption = options.find((item) => item.value === defaultValue);
    if (!defaultOption) {
      return
    }

    if (isChecked) {
      checkedState[option.value] = true;
      if (defaultOption && option.value === defaultOption.value) {
        options.forEach((optionItem) => {
          if (optionItem.value !== defaultOption.value) {
            delete checkedState[optionItem.value];
          }
        });
      } else {
        delete checkedState[defaultOption.value];
      }
    } else {
      delete checkedState[option.value];
      if (Object.keys(checkedState).length === 0) {
        checkedState[defaultOption.value] = true;
      }
    }

    const newValue = Object.keys({ ...checkedState }).join(',');
    updateOptimisticValue(newValue);
    onSelect(newValue);
  }

  useEffect(() => {
    const defaultOption = options.find((option) => option.value === defaultValue)
    if (defaultOption) {
      handleChange(defaultOption, true)
    }
  }, [options, defaultValue])

  return (
    <FormControl id={id} disabled={disabled}>
      <Grid>
        {!!shouldShowSearch && SearchInputNode}
        <FormGroup>
          {limitedOptions.map((option, i) => {
            return (
              <FormControlLabel
                className={classes.filterOptions}
                value={option.value}
                key={option.id}
                control={
                  <Checkbox
                    onChange={() => handleChange(option, !checkedState[option.value])}
                    checked={checkedState[option.value] || false}
                    name={option.title}
                  />
                }
                label={option.title}
              />
            )
          })}
          {!disabled && LimitedOptionsController}
        </FormGroup>
      </Grid>
    </FormControl>
  )
}

export default BasicMultiCheckboxRenderer

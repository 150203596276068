import { paymentOptionSelectionSelectors } from 'ducks/paymentOptionSelection'
import { useGetSoldRecords } from 'projectSections/sections/payments/cashFlowTransactions/utils'
import { useIsProjectUsingCashFlow } from 'projectSections/sections/payments/hooks'
import { useNotify, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import EditUsage from '../../EditUsage'
import GenerateProposal from '../../GenerateProposal'
import AboutUsDialog from '../../dialogs/AboutUsDialog'
import { CaseStudiesDialog } from '../../dialogs/CaseStudiesDialog'
import DailyEnergyFlowsDialog from '../../dialogs/DailyEnergyFlowsDialog'
import DailyEnergyFlowsDialogSolarZero from '../../dialogs/DailyEnergyFlowsDialogSolarZero'
import FinancialDetailDialog from '../../dialogs/FinancialDetailDialog'
import { IframeDialog } from '../../dialogs/IframeDialog'
import LoadingDialog from '../../dialogs/LoadingDialog'
import NextStepsDialog from '../../dialogs/NextStepsDialog'
import SavingsDetailDialog from '../../dialogs/SavingsDetailDialog'
import SystemDetailDialog from '../../dialogs/SystemDetailDialog'
import VideoDialog from '../../dialogs/VideoDialog'

const mergeFirstNumbersInArray = (arr) => [arr[0] + arr[1]].concat(arr.slice(2))
const DIALOGS_REQUIRE_FULL_CALC = ['GenerateProposal', 'DailyEnergyFlowsDialog', 'ImageDialog', 'FinancialDetail']

export const RenderDialog = (props) => {
  const { proposalData, closeDialog, activeDialog, dialogData, layout, user, dialogIsFullWidth } = props
  const translate = useTranslate()
  const notify = useNotify()
  const myeStyles = proposalData.myeStyles

  const [soldSystem, soldPaymentOption] = useGetSoldRecords(proposalData.selectedProject)
  const refreshSystemsTrigger = useSelector(paymentOptionSelectionSelectors.getSystemRefreshTrigger)
  const isProjectUsingCashflow = useIsProjectUsingCashFlow(soldSystem, soldPaymentOption, refreshSystemsTrigger)

  if (!proposalData.fullCalcReady && DIALOGS_REQUIRE_FULL_CALC.includes(activeDialog)) {
    return <LoadingDialog closeDialog={closeDialog} />
  }

  switch (activeDialog) {
    case 'AboutUs':
      return (
        <AboutUsDialog
          org={proposalData.org}
          title={proposalData.proposalTemplateSettings.heading_cover}
          content={props.content}
          isOpen={true}
          closeDialog={closeDialog}
          selectedProject={proposalData.selectedProject}
          myeStyles={myeStyles}
        />
      )
    case 'NextSteps':
      return (
        <NextStepsDialog
          isOpen={true}
          closeDialog={closeDialog}
          myeStyles={myeStyles}
          content={
            proposalData.selectedProject &&
            proposalData.selectedProject.proposal_data &&
            proposalData.selectedProject.proposal_data.proposal_template_settings &&
            proposalData.selectedProject.proposal_data.proposal_template_settings.next_steps_content
          }
        />
      )
    case 'CaseStudies':
      return (
        <CaseStudiesDialog
          org={proposalData.org}
          isOpen={true}
          testimonials={proposalData.selectedProject.testimonials}
          proposalData={proposalData}
          closeDialog={closeDialog}
          myeStyles={myeStyles}
        />
      )
    case 'EditUsage':
      return (
        <EditUsage
          isOpen={true}
          selectedProject={proposalData.selectedProject}
          closeDialog={closeDialog}
          resource={'my_projects'}
          pathname={'/myenergy/' + proposalData.selectedProject.id}
          id={proposalData.selectedProject.id}
        />
      )
    case 'DailyEnergyFlowsDialog':
      return (
        <DailyEnergyFlowsDialog
          isOpen={true}
          system={proposalData.selectedSystem}
          hemisphere={proposalData.selectedProject.lat > 0 ? 'north' : 'south'}
          closeDialog={closeDialog}
          layout={layout}
          myeStyles={myeStyles}
        />
      )
    case 'ImageDialog':
      return (
        <DailyEnergyFlowsDialogSolarZero
          isOpen={true}
          closeDialog={closeDialog}
          title={proposalData.proposalTemplateSettings.heading_daily_energy_flows}
        />
      )
    case 'Video':
      return <VideoDialog isOpen={true} closeDialog={closeDialog} myeStyles={myeStyles} dialogData={dialogData} />
    case 'Detail':
      return (
        <SystemDetailDialog
          isOpen={true}
          closeDialog={closeDialog}
          myeStyles={myeStyles}
          dialogData={dialogData}
          system={proposalData.selectedSystem}
        />
      )
    case 'FinancialDetail':
      return (
        <FinancialDetailDialog
          isOpen={true}
          cashFlows={mergeFirstNumbersInArray(props.proposalData.cashFlows['cash_flows'])}
          cumulativeImpacts={props.proposalData.cashFlows['bank_balance'].slice(1)}
          closeDialog={closeDialog}
          proposalData={proposalData}
          myeStyles={myeStyles}
          translate={translate}
        />
      )
    case 'SavingsDetail':
      return (
        <SavingsDetailDialog
          isOpen={true}
          closeDialog={closeDialog}
          proposalData={proposalData}
          myeStyles={myeStyles}
          translate={translate}
        />
      )
    case 'GenerateProposal':
      if (!proposalData.fullCalcReady && DIALOGS_REQUIRE_FULL_CALC.includes(activeDialog)) {
        return <LoadingDialog closeDialog={closeDialog} />
      } else {
        return (
          <GenerateProposal
            translate={translate}
            notify={notify}
            key={
              proposalData.selectedSystem.uuid +
              '_' +
              proposalData.selectedPaymentOption.id +
              '_' +
              proposalData.selectedViewUuid
            }
            isOpen={true}
            user={user}
            fullCalcReady={proposalData.fullCalcReady}
            selectedSystem={proposalData.selectedSystem}
            proposalTemplateSettings={proposalData.proposalTemplateSettings}
            systems={proposalData.systems}
            selectedProject={proposalData.selectedProject}
            selectedOrg={proposalData.org}
            selectedPaymentOption={proposalData.selectedPaymentOption}
            hasCashFlow={isProjectUsingCashflow}
            selectedViewUuid={proposalData.selectedViewUuid}
            closeDialog={closeDialog}
            myeStyles={myeStyles}
          />
        )
      }
    default:
      return (
        <IframeDialog
          activeDialog={activeDialog}
          closeDialog={closeDialog}
          myeStyles={myeStyles}
          dialogData={dialogData}
          dialogIsFullWidth={dialogIsFullWidth}
        />
      )
  }
}

export default RenderDialog

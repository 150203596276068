var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export var HomeIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? '1rem' : _b, _c = _a.color, color = _c === void 0 ? 'currentColor' : _c, rest = __rest(_a, ["size", "color"]);
    var iconAttributes = {
        width: size,
        height: size,
        stroke: color,
        fill: color || 'none',
    };
    return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20" }, rest, iconAttributes, { children: _jsx("path", { d: "M0.9386 6.25167C0.600941 6.48001 0.400391 6.84967 0.400391 7.24369V17.774C0.400391 18.7824 1.26 19.5999 2.32039 19.5999H6C6.55228 19.5999 7 19.1522 7 18.5999V12.2964C7 11.6241 7.57308 11.0791 8.28 11.0791H11.72C12.4269 11.0791 13 11.6241 13 12.2964V18.5999C13 19.1522 13.4477 19.5999 14 19.5999H17.6804C18.7408 19.5999 19.6004 18.7824 19.6004 17.774V7.24369C19.6004 6.84967 19.3998 6.48001 19.0622 6.25167L10.7422 0.625153C10.2981 0.324819 9.70271 0.324819 9.2586 0.625152L0.9386 6.25167Z", fill: color }) })));
};

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import { Sort, useTranslate } from 'ra-core'
import React, { useEffect, useState } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { useOpenSolarTheme } from 'themes/useOpenSolarTheme'
import { StudioSystemType } from 'types/global'
import { Theme } from 'types/themes'
import { useGetMonthlyBillSavings } from '../hooks'
import PaymentOptionSelectionRow from './PaymentOptionSelectionRow'
import { ROW_WIDTHS } from './constants'
import { CalculatedPaymentOption } from './types'

type PropTypes = {
  rows: CalculatedPaymentOption[]
  sort: Sort | undefined
  setSort: (newSort: Sort) => void
  isLoading: boolean
  system: StudioSystemType
}

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    minHeight: '340px',
  },
  headerRow: {
    padding: '0px',
    backgroundColor: theme.greyMid2,
  },
  headerCell: {
    padding: '4px',
    backgroundColor: theme.greyMid2,
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: theme.greyMid2,
  },
  centeredHeaderCell: {
    padding: '4px',
    backgroundColor: theme.greyMid2,
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: theme.greyMid2,
    textAlign: 'center',
  },
  savingsTitleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  providerLabel: {
    display: 'flex',
    justifyContent: 'flex-end', // because the labels have sort icons, flex-end is actually what is needed to center this label
  },
  noResultsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '20px',
    width: '100%',
    position: 'absolute',
    left: '0px',
    right: '0px',
  },
  noResultsTitle: {
    textAlign: 'center',
    fontWeight: 400,
  },
}))

const PaymentOptionSelectionTable: React.FC<PropTypes> = (props) => {
  // avoid flash of empty cell while fetching rows
  const [savedRows, setSavedRows] = useState<CalculatedPaymentOption[]>(props.rows)

  useEffect(() => {
    if (props.rows && Object.values(props.rows)?.length >= 0 && !props.isLoading) {
      setSavedRows(props.rows)
    }
  }, [props.isLoading, props.rows])

  const translate = useTranslate()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const classes = useStyles()
  const openSolarTheme = useOpenSolarTheme()
  const billSavings = useGetMonthlyBillSavings(props.system)

  const changeSort = (sortTitle: string) => {
    if (props.sort?.field === sortTitle) {
      props.setSort({ field: props.sort.field, order: props.sort.order === 'DESC' ? 'ASC' : 'DESC' })
    } else {
      props.setSort({ field: sortTitle, order: 'ASC' })
    }
  }

  if (!savedRows) return null
  return (
    <div className={classes.wrapper}>
      <Table id="payment-option-selection-table">
        <TableHead>
          <TableRow className={classes.headerRow}>
            <TableCell width={ROW_WIDTHS[0]} className={classes.headerCell}></TableCell>
            <TableCell width={ROW_WIDTHS[1]} className={classes.headerCell}>
              <TableSortLabel
                active={props.sort?.field === 'title'}
                // @ts-ignore DESC and ASC are actually required, not the lowercase versions
                direction={props.sort?.order === 'DESC' ? 'desc' : 'asc'}
                onClick={() => changeSort('title')}
              >
                {translate('Name')}
              </TableSortLabel>
            </TableCell>
            {!isMobile && (
              <TableCell width={ROW_WIDTHS[2]} className={classes.headerCell}>
                <TableSortLabel
                  active={props.sort?.field === 'integration'}
                  // @ts-ignore DESC and ASC are actually required, not the lowercase versions
                  direction={props.sort?.order === 'DESC' ? 'desc' : 'asc'}
                  onClick={() => changeSort('integration')}
                  className={classes.providerLabel}
                >
                  {translate('Provider')}
                </TableSortLabel>
              </TableCell>
            )}
            <TableCell width={ROW_WIDTHS[3]} className={classes.centeredHeaderCell}>
              <TableSortLabel
                active={props.sort?.field === 'repayment'}
                // @ts-ignore DESC and ASC are actually required, not the lowercase versions
                direction={props.sort?.order === 'DESC' ? 'desc' : 'asc'}
                onClick={() => changeSort('repayment')}
              >
                {translate('Monthly Payment')}
              </TableSortLabel>
            </TableCell>
            {!isMobile && (
              <TableCell width={ROW_WIDTHS[4]} className={classes.centeredHeaderCell}>
                <div className={classes.savingsTitleRow}>
                  {translate('Monthly Savings')}
                  <Tooltip
                    title={translate(
                      "Monthly Savings show estimated year 1 monthly savings by comparing the system's Estimated Utility Savings to the Monthly Payment for the payment option. The green arrow indicates a monthly payment is estimated to be cashflow positive in year one. Note that these are initial estimates and may differ when applied to an actual system"
                    )}
                  >
                    <InfoOutlined fontSize="small" htmlColor="rgb(117, 117, 117)" />
                  </Tooltip>
                </div>
              </TableCell>
            )}
            {!isMobile && (
              <TableCell width={ROW_WIDTHS[5]} className={classes.centeredHeaderCell}>
                {translate('System Price')}
              </TableCell>
            )}
            {!isMobile && (
              <TableCell width={ROW_WIDTHS[6]} className={classes.headerCell}>
                <TableSortLabel
                  active={props.sort?.field === 'interest_rate'}
                  // @ts-ignore DESC and ASC are actually required, not the lowercase versions
                  direction={props.sort?.order === 'DESC' ? 'desc' : 'asc'}
                  onClick={() => changeSort('interest_rate')}
                >
                  {translate('Int. Rate')}
                </TableSortLabel>
              </TableCell>
            )}
            {!isMobile && (
              <TableCell width={ROW_WIDTHS[7]} className={classes.headerCell}>
                <TableSortLabel
                  active={props.sort?.field === 'term_years'}
                  // @ts-ignore DESC and ASC are actually required, not the lowercase versions
                  direction={props.sort?.order === 'DESC' ? 'desc' : 'asc'}
                  onClick={() => changeSort('term_years')}
                >
                  {translate('Term')}
                </TableSortLabel>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.isLoading &&
            !Object.values(savedRows)?.length &&
            Array.apply(null, Array(5))?.map((el, i) => (
              <PaymentOptionSelectionRow pmt={undefined} key={i} isLoading={true} />
            ))}
          {Object.values(savedRows)?.map((pmt) => (
            <PaymentOptionSelectionRow
              pmt={pmt}
              key={pmt.payment_option_id}
              isLoading={props.isLoading}
              billSavings={billSavings}
            />
          ))}
          {!props.isLoading && Object.values(savedRows)?.length === 0 && (
            <div className={classes.noResultsWrapper}>
              <h2 className={classes.noResultsTitle}>{translate('No Payment Options Found')}</h2>
            </div>
          )}
        </TableBody>
      </Table>
    </div>
  )
}
export default PaymentOptionSelectionTable

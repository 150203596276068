import CustomForm, { CustomFormProps } from 'customForm/CustomForm'
import React, { FC } from 'react'
import { useForm, useFormState } from 'react-final-form'

const CustomFormDialogContent: FC<Omit<CustomFormProps, 'parentForm'>> = (props) => {
  const parentForm = useForm()
  const parentFormState = useFormState()
  return <CustomForm parentForm={parentForm} parentFormState={parentFormState} {...props} />
}

export default CustomFormDialogContent

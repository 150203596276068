import type LineItemType from 'pages/ordering/OrderLineItem'
import OrderLineItem from 'pages/ordering/OrderLineItem'
import useProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/useProjectOrderPresenter'
import QuantityField from 'pages/ordering/order/table/quantity/QuantityField'
import useQuantityFieldParts from 'pages/ordering/order/table/quantity/useQuantityFieldParts'
import React, { useCallback } from 'react'
import getSelectorOptions from './getSelectorOptions'

const CtaQuantityField = ({
  lineItem,
  setLineItem,
}: {
  lineItem: LineItemType
  setLineItem: (lineItem: LineItemType) => void
}) => {
  const orderingPresenter = useProjectOrderPresenter()
  const { type, Message, QuantityErrorMessage, Selector } = useQuantityFieldParts({ lineItem })

  const handleUpdateQuantity = useCallback(
    (quantity) => {
      setLineItem(new OrderLineItem({ ...lineItem, quantity }))
    },
    [lineItem]
  )

  const handleSelect = useCallback((newLineItem: LineItemType) => {
    setLineItem(newLineItem)
  }, [])

  return (
    <QuantityField
      quantity={lineItem.quantity}
      Message={!!Message && <Message />}
      QuantityErrorMessage={!!QuantityErrorMessage && <QuantityErrorMessage />}
      Selector={
        !!Selector && (
          <Selector
            options={getSelectorOptions({
              lineItem,
              handleSelect,
              orderingPresenter,
              type,
              trackSource: 'product_detail',
            })}
          />
        )
      }
      onUpdateQuantity={handleUpdateQuantity}
    />
  )
}

export default CtaQuantityField

import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import CustomForm from 'customForm/CustomForm'
import { FormPlacementInfo } from 'customForm/util/findFormPlacements'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import { SimpleForm } from 'ra-ui-materialui'
import React, { useEffect, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import restClient from 'restClient'
import { StudioSystemType } from 'types/global'
import { SelectedProjectType } from 'types/projects'
import { SystemDataType } from 'types/systems'

const restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  customForms: FormPlacementInfo[]
  onNext: () => void
  onClose: () => void
  project: SelectedProjectType
  system?: SystemDataType
  orgId: number
}

const useStyles = makeStyles({
  actionsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    width: '100%',
  },
})

const CustomFormDialogContent: React.FC<PropTypes> = (props) => {
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const classes = useStyles()
  const form = useForm()
  const formState = useFormState()

  const submitCustomForms = () => {
    let customFormValues = form.getState().values?.custom_data
    setIsSaving(true)
    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${props.orgId}/projects/${props.project.id}/contract/custom_form/`,
      data: {
        custom_data: customFormValues,
      },
    })
      .then((res) => {
        if (res.data.success) props.onNext()
      })
      .catch((err) => console.log('err'))
      .finally(() => setIsSaving(false))
  }

  const findStudioSystem = (system?: SystemDataType): StudioSystemType | undefined => {
    if (!system) return
    var systems = window.editor.getSystems()
    return systems.find((s) => s.uuid === system.uuid)
  }

  return (
    <Dialog open={true} onClose={props.onClose}>
      <DialogTitle></DialogTitle>
      <DialogContent>
        {props.customForms?.map((customForm) => (
          <CustomForm
            key={customForm.form.id}
            customForm={customForm.form}
            parentForm={form}
            parentFormState={formState}
            isLoading={!customForm.form.configuration}
            selectedSystem={findStudioSystem(props.system)}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <div className={classes.actionsWrapper}>
          <ProUXButton type="secondary" label="Dismiss" onClick={props.onClose} />
          <ProUXButton type="primary" label="Continue to Contract" onClick={submitCustomForms} loading={isSaving} />
        </div>
      </DialogActions>
    </Dialog>
  )
}

const CustomFormDialog: React.FC<PropTypes> = (props) => {
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [intialData, setInitialData] = useState<Object>(props.project)
  useEffect(() => {
    let formIds = [] as number[]
    props.customForms?.forEach((form) => {
      formIds.push(form.form.id)
    })
    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${props.orgId}/projects/${props.project.id}/custom_data_by_key/?form_ids=${formIds}`,
    })
      .then((res) => {
        if (res?.data?.data) {
          setInitialData({ ...props.project, custom_data: { ...res.data.data } })
        }
      })
      .catch((err) => {})
      .finally(() => setShowDialog(true))
  }, [])

  if (!showDialog) return null
  return (
    <SimpleForm initialValues={intialData}>
      <CustomFormDialogContent {...props} />
    </SimpleForm>
  )
}
export default CustomFormDialog

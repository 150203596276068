import { amplitudeLogger } from './amplitudeLogger'
import { ga4Logger } from './ga4Logger'
import { BackendLogger } from './types'

export const backendRegistry: Record<string, BackendLogger> = {
  AMPLITUDE: amplitudeLogger,
  GA4: ga4Logger,
}

export const getBackendLogger = (platform: string): BackendLogger | undefined => backendRegistry[platform]

import { logAmplitudeEvent } from 'amplitude/amplitude'
import { push } from 'connected-react-router'
import { updateIsLite } from 'ducks/auth_roles'
import { orgsActions } from 'ducks/orgs'
import { showNotification, USER_LOGIN, USER_LOGIN_FAILURE, USER_LOGIN_SUCCESS, useTranslate } from 'react-admin'
import { all, call, delay, put, select, takeEvery } from 'redux-saga/effects'
import SegenService from 'services/segen/SegenServiceV2'
import appStorage from 'storage/appStorage'
import { clearQueryParam, getQueryString } from 'util/query'
import {
  AUTH_REGISTER,
  AUTH_RELOAD,
  AUTH_RELOAD_FAILURE,
  AUTH_RELOAD_LOADING,
  AUTH_RELOAD_SUCCESS,
  AUTO_LOGIN,
  AUTO_LOGIN_FAILURE,
  AUTO_LOGIN_LOADING,
  AUTO_LOGIN_SUCCESS,
  EMAIL_CHANGE_FORM,
  EMAIL_CHANGE_FORM_FAILURE,
  EMAIL_CHANGE_FORM_LOADING,
  EMAIL_CHANGE_FORM_SUCCESS,
  PASSWORD_CHANGE_FORM,
  PASSWORD_CHANGE_FORM_FAILURE,
  PASSWORD_CHANGE_FORM_LOADING,
  PASSWORD_CHANGE_FORM_SUCCESS,
  PASSWORD_RESET,
  PASSWORD_RESET_FAILURE,
  PASSWORD_RESET_FORM,
  PASSWORD_RESET_FORM_FAILURE,
  PASSWORD_RESET_FORM_LOADING,
  PASSWORD_RESET_FORM_SUCCESS,
  PASSWORD_RESET_LOADING,
  PASSWORD_RESET_SUCCESS,
  PROCESS_AUTH,
  PROCESS_AUTH_SUCCESS,
  USER_REGISTER,
  USER_REGISTER_FAILURE,
  USER_REGISTER_LOADING,
  USER_REGISTER_SUCCESS,
} from '../actions/authActions'
import { authSelectors, SET_AUTH, SET_AUTH_RELOADING, SET_AUTH_STATUS, SET_ORG_ID } from '../ducks/auth'
import { setMfaCheckRequired, setMfaConfigRequired, setMfaHasDevices } from '../ducks/auth_mfa_actions'
import { LOAD_COUNT_INCREMENT } from '../ducks/loadCount'
import { SET_WELCOME_PRO } from '../ducks/welcome'

const getPathAfterLoginSuccess = (payload, meta) => {
  if (payload && payload.json && payload.json.roles.length > 0) {
    const state = (meta && meta.state) || {}
    return { pathname: (meta && meta.pathName) || state.nextPathname || '/', search: state.nextSearch || '' }
  } else if (payload && payload.json && payload.json.user && payload.json.user.id > 0) {
    return '/myenergy'
  } else {
    return { pathname: '/login', state: (meta && meta.state) || '' }
  }
}

// const getTheme = (payload) => {
//   const orgsList = payload && payload.json && payload.json.orgs
//   const org_id = payload && payload.json && payload.json.org_id
//   const orgData = orgsList.find((org) => org.id === org_id)
//   let theme = 'opensolar'
//   if (orgData && orgData.external_account_id !== null) {
//     theme = 'nearmap'
//   }
//   return theme
// }

export default (authClient) => {
  if (!authClient) return () => null
  const translate = useTranslate()

  function* handleAuth(action) {
    const { type, payload, meta, callbacks } = action
    switch (type) {
      case SET_ORG_ID:
        yield put(updateIsLite())
        yield put(orgsActions.reloadOrg(action.payload.orgId))
        yield call(SegenService.resetInstanceOnSetOrgId) // Reset Segen when switching org
        break

      case PROCESS_AUTH:
        yield put({ type: AUTH_RELOAD_LOADING, meta: { auth: true } })
        yield call(authClient, PROCESS_AUTH, payload)
        const authPayload = payload.auth
        if (authPayload === undefined) {
          // This should never happened, to remove it add strong type in authActions
          console.warn('no auth data to process in PROCESS_AUTH')
          return
        }
        yield put({
          type: PROCESS_AUTH_SUCCESS,
          payload: { auth: payload.auth, reloadOrg: true }, // matches the existing expected format
          meta: { auth: true },
        })

        // This is a hack to ensure onSuccess gets called after any nested redux action or effects
        yield delay(100)

        callbacks?.onSuccess?.(authPayload)
        break

      case AUTH_RELOAD:
        const authReloading = yield select(authSelectors.getAuthReloading)
        if (authReloading) {
          console.warn('AUTH_RELOAD already in progress, skipping')
          return
        }
        try {
          yield put({ type: AUTH_RELOAD_LOADING, meta: { auth: true } })
          yield put({ type: SET_AUTH_STATUS, payload: 'reloading' })
          const authPayload = yield call(authClient, AUTH_RELOAD, payload)
          yield put({
            type: AUTH_RELOAD_SUCCESS,
            payload: { auth: authPayload?.json, reloadOrg: payload?.reloadOrg },
            meta: { auth: true },
          })
        } catch (e) {
          yield put({
            type: AUTH_RELOAD_FAILURE,
            error: e,
            meta: { auth: true },
          })
          if (!e.body?.mfa_status) {
            const errorMessage =
              typeof e === 'string' ? e : typeof e === 'undefined' || !e.message ? 'ra.auth.reload_error' : e.message
            yield put(showNotification(errorMessage, 'warning'))
          }

          // TODO: refactor this to handle by callbacks
          yield put(push(payload?.redirectOnFailure || '/login'))
        }
        break

      case AUTH_RELOAD_LOADING:
        yield put({
          type: SET_AUTH_RELOADING,
          payload: true,
        })
        break

      case PROCESS_AUTH_SUCCESS:
      case AUTH_RELOAD_SUCCESS:
        yield put({
          type: SET_AUTH,
          payload: payload.auth,
        })
        if (payload.reloadOrg) {
          //This needs to invoke after `SET_AUTH` as reloadOrg relies on the org_id in the auth data
          yield put(orgsActions.reloadOrg())
        }
        yield put(setMfaCheckRequired(false))
        yield put(setMfaConfigRequired(false))
        yield put(updateIsLite(true))

        const authStatus = yield select(authSelectors.getAuthStatus)
        if (authStatus !== 'sso_logging_in') {
          // not part of sso authentication process
          yield put({ type: SET_AUTH_STATUS, payload: 'authenticated' })
        }

        break

      case USER_LOGIN_SUCCESS: {
        logAmplitudeEvent('login_success', {})
        yield put({
          type: SET_AUTH,
          payload: payload.json,
        })
        yield put(setMfaCheckRequired(false))
        yield put(setMfaConfigRequired(false))
        yield put(updateIsLite(true))

        const autoLoginProject = yield select(authSelectors.getAutoLoginProject)
        if (!autoLoginProject) yield put(orgsActions.reloadOrg())

        const authStatus = yield select(authSelectors.getAuthStatus)
        if (authStatus !== 'sso_logging_in') {
          // when not part of sso authentication process

          // Allow special redirection for NMOS if the user login succeeded but the org needs action
          if (
            payload.json?.post_login_message &&
            payload.json.post_login_message.includes('NEARMAP_CREATE_OR_UPGRADE')
          ) {
            appStorage.setString('nearmap_token', action.meta.nearmapToken)
            yield put(push('/login-nearmap-choose-create-or-upgrade?nearmap_token=' + meta.nearmapToken))
          } else {
            // Standard next step after login
            yield put(push(getPathAfterLoginSuccess(payload, meta)))
          }

          yield put({ type: SET_AUTH_STATUS, payload: 'authenticated' })
        }

        break
      }

      case USER_LOGIN_FAILURE:
      case AUTH_RELOAD_FAILURE: {
        // Clear org from local storage, as it now has MFA turned on
        yield put(orgsActions.clearOrg())

        yield put({
          type: SET_AUTH_RELOADING,
          payload: false,
        })

        const authStatus = yield select(authSelectors.getAuthStatus)
        if (authStatus !== 'sso_logging_in') {
          // when not part of sso authentication process
          yield put({ type: SET_AUTH_STATUS, payload: 'undetermined' })
        }

        const error = action?.error

        console.debug(type, error?.body)
        const mfa_status = error?.body?.mfa_status

        if (mfa_status)
          yield put(
            setMfaHasDevices(
              !!error.body?.has_sms_device,
              !!error.body?.has_totp_device,
              !!error.body?.has_recovery_codes
            )
          )

        if (mfa_status === 'mfa_required') {
          logAmplitudeEvent('mfa_required_upon_login', {})
          yield put(setMfaCheckRequired(true))
          yield put(setMfaConfigRequired(false))
        } else if (mfa_status === 'mfa_required_but_not_configured') {
          logAmplitudeEvent('mfa_setup_required_on_login', {})
          if (error?.body?.token) appStorage.setString('token', error?.body?.token)
          yield put(setMfaCheckRequired(false))
          yield put(setMfaConfigRequired(true))
        } else if (mfa_status === 'mfa_rejected') {
          logAmplitudeEvent('mfa_code_rejected', {})
          yield put(showNotification('Invalid Authentication code', 'warning'))
        } else {
          // Clear localStorage token if token is invalid or expired, indicated by HTTP STATUS 401
          // Do not clear for other login failure reasons such as nearmap upgrade clashes, duplicate org, etc.
          logAmplitudeEvent('login_fail', {})
          if (action.error && action.error.status === 401) {
            appStorage.setString('token')

            var auth = appStorage.getJSON('auth')
            if (auth) {
              auth.token = null
              appStorage.setJSON('auth', auth)
            }
          }

          try {
            var message = action.error.body.detail

            if (message.indexOf('NEARMAP_USER_EMAIL_CLASH') !== -1) {
              appStorage.setString('nearmap_token', action.meta.nearmapToken)
              yield put(push('/login-modal?NEARMAP_USER_EMAIL_CLASH'))
            } else if (message.indexOf('NEARMAP_ORG_NAME_CLASH') !== -1) {
              appStorage.setString('nearmap_token', action.meta.nearmapToken)
              yield put(push('/login-modal?NEARMAP_ORG_NAME_CLASH'))
            } else if (message.indexOf('NEARMAP_ADMIN_REQUIRED') !== -1) {
              yield put(push('/login-nearmap-admin-required'))
            } else if (message.indexOf('NEARMAP_PRODUCT_NOT_PROVISIONED') !== -1) {
              yield put(push('/login-nearmap-product-not-provisioned'))
            } else if (message.indexOf('NEARMAP_CREATE_OR_UPGRADE') !== -1) {
              appStorage.setString('nearmap_token', action.meta.nearmapToken)
              yield put(push('/login-nearmap-choose-create-or-upgrade?nearmap_token=' + meta.nearmapToken))
            }
          } catch (e) {
            //other
          }
        }
        break
      }

      case USER_LOGIN: {
        const authStatus = yield select(authSelectors.getAuthStatus)
        if (authStatus !== 'sso_logging_in') {
          // not part of sso authentication process
          yield put({ type: SET_AUTH_STATUS, payload: 'os_logging_in' })
        }
        break
      }

      case USER_REGISTER: {
        try {
          yield put({ type: USER_REGISTER_LOADING })
          const authStatus = yield select(authSelectors.getAuthStatus)

          if (authStatus !== 'sso_logging_in') {
            // not part of sso authentication process
            yield put({ type: SET_AUTH_STATUS, payload: 'os_registering' })
          }

          const authPayload = yield call(authClient, AUTH_REGISTER, payload)

          if (!authPayload) {
            // only here if MFA is require after login
            yield put({
              type: USER_REGISTER_FAILURE,
              payload: undefined,
            })
          } else {
            logAmplitudeEvent('registration_form_submitted', {})

            // When registering a new org while logged in, token in response will be empty, repopulate here
            if (!authPayload.json.token) {
              authPayload.json.token = appStorage.getToken()
            }

            clearQueryParam('is_lite')

            yield put({
              type: USER_REGISTER_SUCCESS,
              payload: authPayload,
              meta: { auth: true },
            })
            yield put({
              type: SET_AUTH,
              payload: authPayload.json,
            })
            yield put({
              type: SET_ORG_ID,
              payload: { orgId: authPayload.json.org_id },
              meta: { auth: true },
            })
            yield put({
              type: SET_WELCOME_PRO,
              payload: true,
            })

            // This is a hack to ensure onSuccess gets called after any nested redux action or effects
            yield delay(100)
            callbacks?.onSuccess?.(authPayload)
          }
        } catch (e) {
          let errorMessage
          switch (e.body?.code) {
            case 'org_name_exists':
              errorMessage =
                'Org with this name already exists. Please reach out to your org admins to add you as a team member.'
              break
            case 'email_not_match_current_login_user':
              errorMessage = 'Email does not match the current logged-in user.'
              break
            case 'password_not_match_current_login_user':
              errorMessage = 'Password does not match the current logged-in user.'
              break
            case 'email_exists':
              errorMessage = 'User with this email already exists.'
              break
            default:
              errorMessage = e.body?.non_field_errors || 'Something went wrong'
              break
          }

          yield put(showNotification(translate(errorMessage), 'warning'))

          yield put({
            type: USER_REGISTER_FAILURE,
            error: e,
            meta: { auth: true },
          })
        }
        break
      }

      case USER_REGISTER_FAILURE: {
        const authStatus = yield select(authSelectors.getAuthStatus)

        if (authStatus !== 'sso_logging_in') {
          // not part of sso authentication process
          yield put({ type: SET_AUTH_STATUS, payload: 'undetermined' })
        }
        break
      }

      case USER_REGISTER_SUCCESS: {
        window.GA4?.initOnce(payload.json?.user?.id, 'pro')
        window.GA4?.recordEvent('create_org_success')

        // SSO authenticated status and redirect handled separately
        const isSsoLogin = yield select(authSelectors.getAuthStatus) === 'sso_logging_in'
        if (isSsoLogin) {
          break
        }

        const authStatus = yield select(authSelectors.getAuthStatus)

        if (authStatus !== 'sso_logging_in') {
          // not part of sso authentication process
          yield put({ type: SET_AUTH_STATUS, payload: 'authenticated' })
        }

        break
      }

      case PASSWORD_RESET: {
        try {
          yield put({ type: PASSWORD_RESET_LOADING })
          const authPayload = yield call(authClient, PASSWORD_RESET, payload)
          yield put({
            type: PASSWORD_RESET_SUCCESS,
            payload: authPayload,
          })

          // This is a hack to ensure onSuccess gets called after any nested redux action or effects
          yield delay(100)
          callbacks?.onSuccess?.(authPayload)
        } catch (e) {
          yield put({
            type: PASSWORD_RESET_FAILURE,
            error: e,
            meta: { auth: true },
          })
          const errorMessage =
            typeof e === 'string'
              ? e
              : typeof e === 'undefined' || !e.message
              ? 'ra.auth.password_reset_error'
              : e.message
          yield put(showNotification(errorMessage, 'warning'))

          // This is a hack to ensure onFailure gets called after any nested redux action or effects
          yield delay(100)
          callbacks?.onFailure?.(e)
        }
        break
      }

      case PASSWORD_RESET_FORM: {
        try {
          yield put({ type: PASSWORD_RESET_FORM_LOADING })
          const authPayload = yield call(authClient, PASSWORD_RESET_FORM, payload)
          yield put({
            type: PASSWORD_RESET_FORM_SUCCESS,
            payload: authPayload,
          })
          // This is a hack to ensure onSuccess gets called after any nested redux action or effects
          yield delay(100)
          callbacks?.onSuccess?.(authPayload)
        } catch (e) {
          yield put({
            type: PASSWORD_RESET_FORM_FAILURE,
            error: e,
            meta: { auth: true },
          })
          const errorMessage =
            typeof e === 'string'
              ? e
              : typeof e === 'undefined' || !e.message
              ? 'ra.auth.password_reset_form_error'
              : e.message

          yield put(showNotification(errorMessage, 'warning'))
          // This is a hack to ensure onFailure gets called after any nested redux action or effects
          yield delay(100)
          callbacks?.onFailure?.(e)
        }
        break
      }

      case PASSWORD_CHANGE_FORM: {
        try {
          yield put({ type: PASSWORD_CHANGE_FORM_LOADING })
          const authPayload = yield call(authClient, PASSWORD_CHANGE_FORM, payload)
          yield put({
            type: PASSWORD_CHANGE_FORM_SUCCESS,
            payload: authPayload,
          })
          // This is a hack to ensure onSuccess gets called after any nested redux action or effects
          yield delay(100)
          callbacks?.onSuccess?.(authPayload)
        } catch (e) {
          yield put({
            type: PASSWORD_CHANGE_FORM_FAILURE,
            error: e,
            meta: { auth: true },
          })
          const errorMessage =
            typeof e === 'string'
              ? e
              : typeof e === 'undefined' || !e.message
              ? 'ra.auth.password_change_form_error'
              : e.message
          yield put(showNotification(errorMessage, 'warning'))

          // This is a hack to ensure onFailure gets called after any nested redux action or effects
          yield delay(100)
          callbacks?.onFailure?.(e)
        }
        break
      }

      case EMAIL_CHANGE_FORM: {
        try {
          yield put({ type: EMAIL_CHANGE_FORM_LOADING })
          const authPayload = yield call(authClient, EMAIL_CHANGE_FORM, payload)
          yield put({
            type: EMAIL_CHANGE_FORM_SUCCESS,
            payload: authPayload,
          })
          // This is a hack to ensure onSuccess gets called after any nested redux action or effects
          yield delay(100)
          callbacks?.onSuccess?.(authPayload)
        } catch (e) {
          yield put({
            type: EMAIL_CHANGE_FORM_FAILURE,
            error: e,
            meta: { auth: true },
          })
          const errorMessage =
            typeof e === 'string'
              ? e
              : typeof e === 'undefined' || !e.message
              ? 'ra.auth.email_change_form_error'
              : e.message
          yield put(showNotification(errorMessage, 'warning'))

          // This is a hack to ensure onFailure gets called after any nested redux action or effects
          yield delay(100)
          callbacks?.onFailure?.(e)
        }
        break
      }

      case AUTO_LOGIN: {
        try {
          yield put({ type: AUTO_LOGIN_LOADING })
          yield put({ type: SET_AUTH_STATUS, payload: 'auto_logging_in' })
          const authPayload = yield call(authClient, AUTO_LOGIN, payload)
          yield put({
            type: AUTO_LOGIN_SUCCESS,
            payload: authPayload,
          })
          yield put({
            type: SET_AUTH,
            payload: authPayload.json,
          })
          yield put({ type: SET_AUTH_STATUS, payload: 'authenticated' })

          if (payload && payload.path) {
            var isAnonymousShareUser = authPayload?.json?.user?.is_anonymous_share_user === true
            if (isAnonymousShareUser) {
              // Ignore redirection for anon share user, they can keep the token in the URL.
              // Beware: Are there other params that an anon share user MUST have stripped from the URL?
              // If so they may experience bugs due to the redirection no longer occurring.
            } else {
              let path = payload.path
              let params_to_keep = window.location.href?.includes('view_mode')
                ? window.location.href.substring(window.location.href.indexOf('view_mode'))
                : undefined
              if (params_to_keep) {
                path += '?' + params_to_keep
              }
              yield put(push(path))
            }
          }
          yield put({
            type: LOAD_COUNT_INCREMENT,
          })
        } catch (e) {
          const isPro = e?.status === 401 && e.body?.detail.indexOf('cannot login with secure links') !== -1

          yield put({
            type: AUTO_LOGIN_FAILURE,
            error: e,
            meta: { auth: true },
          })
          const errorMessage =
            !e?.body?.detail || e?.body?.detail.indexOf('Auto-login was unsuccessful.') !== -1
              ? 'The online proposal you are trying to access is not valid. Please check the URL or reach out to your solar installer to request a new online proposal link.'
              : e?.body?.detail

          if (isPro) {
            const nextPathname = window.location.hash.substring(1, window.location.hash.indexOf('?'))
            const nextSearch = getQueryString()
            yield put(
              push({
                pathname: '/login',
                state: {
                  nextPathname,
                  nextSearch,
                },
              })
            )
          }
          yield put(showNotification(errorMessage, 'warning', { autoHideDuration: 60000 }))
          yield put({ type: SET_AUTH_STATUS, payload: 'undetermined' })
        }
        break
      }

      default:
    }
  }

  return function* watchAuthActions() {
    yield all([
      takeEvery((action) => action.type === USER_LOGIN_SUCCESS || (action.meta && action.meta.auth), handleAuth),
    ])
  }
}

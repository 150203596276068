import { useLayoutEffect, useState } from 'react'
import useHdmService from 'services/hdm/useHdmService'
import { CreditLimitType } from './type'

const useHdmCreditLimit = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [creditLimit, setCreditLimit] = useState<CreditLimitType | undefined>()
  const hdmService = useHdmService()
  useLayoutEffect(() => {
    setIsLoading(true)
    const init = async () => {
      const accountDetail = await hdmService.creditLimit()
      if (accountDetail) {
        setCreditLimit(accountDetail)
      }
      setIsLoading(false)
    }
    init()
  }, [])
  return { isLoading, creditLimit }
}

export default useHdmCreditLimit

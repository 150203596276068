import { permissionsSelectors } from 'ducks/permissions'
import ListResultNotFound from 'elements/list/ListResultNotFound'
import { CostSource } from 'projectSections/hooks/useComponentCost'
import React, { useMemo } from 'react'
import { DatagridLoading } from 'react-admin'
import { useSelector } from 'react-redux'
import type { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import type { InverterWizardRecordType } from '../inverterWizard/types'
import { HardwareComponentType, HardwareListRowProps } from '../types'
import HardwareListRow from './HardwareListRow'
import HardwareSelectorListHeader, { HeaderPropsType } from './HardwareSelectorListHeader'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  list: {
    boxSizing: 'border-box',
    width: '100%',
  },
  stickyHeaderWrapper: {
    background: '#ffffff',
    position: 'sticky',
    zIndex: 1,
    top: 0,
  },
  header: {
    boxSizing: 'border-box',
    width: '100%',
    minHeight: 50,
    backgroundColor: theme.greyLight3,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '10px 10px 0 0',
  },
  body: {
    overflow: 'hidden',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '0 0 10px 10px',
  },
  pagination: {},
}))

export const HardwareSelectorTableLayout = ({
  header,
  pagination,
  list,
}: {
  header: React.ReactNode
  pagination: React.ReactNode
  list: React.ReactNode
}) => {
  const classes = useStyles()
  return (
    <div className={classes.list}>
      <div className={classes.stickyHeaderWrapper}>
        <div className={classes.header}>{header}</div>
      </div>
      <div className={classes.body}>{list}</div>
      <div className={classes.pagination}>{pagination}</div>
    </div>
  )
}

interface HardwareSelectorListProps<T> {
  data: T[]
  Header?: React.FC<HeaderPropsType>
  isSelectDisabled?: boolean
  loading: boolean

  onSelect(record: T, costSource?: CostSource, price?: number): void

  Pagination: React.ReactNode
  Row?: React.FC<HardwareListRowProps<T>>
  total: number
  hiddenColumns?: Set<string>
  noResults?: React.ReactNode
}

const HardwareSelectorList = <T extends HardwareComponentType | InverterWizardRecordType>({
  data,
  Header = HardwareSelectorListHeader,
  isSelectDisabled = false,
  loading,
  onSelect,
  Pagination,
  Row = HardwareListRow as React.FC<HardwareListRowProps<T>>,
  noResults,
  total,
  hiddenColumns,
}: HardwareSelectorListProps<T>) => {
  const { allowCreate } = useSelector(permissionsSelectors.getProjectPermissionByKey('components'))
  const List = useMemo(() => {
    if (loading) {
      return <DatagridLoading hasBulkActions={false} nbChildren={5} size="small" />
    }
    if (total === 0 && !loading) {
      return noResults || <ListResultNotFound />
    }
    return (
      <div>
        {data.map((record, index) => (
          <Row
            allowCreate={allowCreate}
            isSelectDisabled={isSelectDisabled}
            record={record}
            onSelect={onSelect}
            key={index}
            hiddenColumns={hiddenColumns}
          />
        ))}
      </div>
    )
  }, [isSelectDisabled, loading, total, data])

  return (
    <HardwareSelectorTableLayout
      header={<Header hiddenColumns={hiddenColumns} />}
      list={List}
      pagination={Pagination}
    />
  )
}

export default HardwareSelectorList

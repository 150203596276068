import type { PartnerIdType } from 'pages/auth/sso/types'
import { getIronRidgeSsoLink } from '../ironridge/authConfig'

// Unable to put those link into sso config due to racing issue when app first load. Such as window.API_BASE_URL will be undefined
const getSsoStartLink = ({ partnerId }: { partnerId: PartnerIdType }) => {
  if (partnerId === 'nearmap') {
    return window.API_BASE_URL + 'nearmap/'
  } else if (partnerId === 'ironridge') {
    const { tenant, clientId } = window.getIronRidgeConfig()
    return getIronRidgeSsoLink({ tenant, clientId })
  }
  return undefined
}

export default getSsoStartLink

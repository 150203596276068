import { CardActions } from '@material-ui/core'
import ListActions from 'elements/react-admin/ListActions'
import PropTypes from 'prop-types'
import React from 'react'
import onlyUpdateForKeys from 'recompose/onlyUpdateForKeys'

const Actions = ({ otherActions, ...rest }) => {
  return (
    <CardActions>
      <ListActions {...rest} />
      {otherActions && otherActions}
    </CardActions>
  )
}

Actions.propTypes = {
  basePath: PropTypes.string,
  displayedFilters: PropTypes.object,
  filters: PropTypes.element,
  filterValues: PropTypes.object,
  hasCreate: PropTypes.bool,
  resource: PropTypes.string,
  showFilter: PropTypes.func,
  theme: PropTypes.object,
  otherActions: PropTypes.array,
}

export default onlyUpdateForKeys(['resource', 'filters', 'displayedFilters', 'filterValues', 'theme'])(Actions)

import { useEffect, useState } from 'react'
import { useFeatureFlag } from 'util/split'
import { FUJI_TOUR_PAYMENTS_TAB_STEPS } from '../payments/steps'
import { StepType } from '../types'
import { DISPLAY_NAME } from './'
import STEPS from './steps'

export const useGetFujiTourNextStepQuery = (step: string) => {
  const STEPS = useGetFUJITourSteps()
  const currentStepIndex = STEPS.findIndex((s) => s.step === step)
  if (currentStepIndex !== -1 && STEPS[currentStepIndex + 1]) {
    const step = STEPS[currentStepIndex + 1].step
    return `?tour=${DISPLAY_NAME}&step=${step}`
  }
}

export const getFujiTourNextStepQuery = (step: string, isPaymentsEnabled: boolean) => {
  const steps = getFUJISteps(isPaymentsEnabled)
  const currentStepIndex = steps.findIndex((s) => s.step === step)
  if (currentStepIndex !== -1 && steps[currentStepIndex + 1]) {
    const step = steps[currentStepIndex + 1].step
    return `?tour=${DISPLAY_NAME}&step=${step}`
  }
}

const useGetFUJITourSteps = () => {
  const [stepsForThisUser, setStepsForThisUser] = useState<StepType[]>(STEPS)
  const isPaymentsEnabled = !!useFeatureFlag('payments_tab', 'on')

  useEffect(() => {
    setStepsForThisUser(getFUJISteps(isPaymentsEnabled))
  }, [isPaymentsEnabled])

  return stepsForThisUser
}

export const getFUJISteps = (isPaymentsEnabled: boolean) => {
  if (!isPaymentsEnabled) return STEPS
  else {
    let indexOfFirstPmtStep: number | undefined = undefined
    STEPS.forEach((step, i) => {
      if (!indexOfFirstPmtStep && step.group === 'Payment Option') {
        indexOfFirstPmtStep = i
      }
    })

    let stepsWithPaymentPage = STEPS.filter((step) => step.group !== 'Payment Option')
    if (indexOfFirstPmtStep !== undefined) {
      stepsWithPaymentPage.splice(indexOfFirstPmtStep, 0, ...FUJI_TOUR_PAYMENTS_TAB_STEPS)
    }
    return stepsWithPaymentPage
  }
}
export default useGetFUJITourSteps

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseQueryStringToDictionary = exports.getQueryVariable = exports.getQueryString = exports.getQueryParams = void 0;
function getQueryParams() {
    return parseQueryStringToDictionary(getQueryString());
}
exports.getQueryParams = getQueryParams;
// This gets around the issue where the query string is considered part of the hash if it is last
function getQueryString() {
    return window.location.search || window.location.hash.split('?')[1];
}
exports.getQueryString = getQueryString;
function getQueryVariable(variable) {
    return getQueryParams()[variable];
}
exports.getQueryVariable = getQueryVariable;
function parseQueryStringToDictionary(queryString) {
    if (!queryString)
        return {};
    var dictionary = {};
    // remove the '?' from the beginning of the queryString if it exists
    if (queryString.indexOf('?') === 0) {
        queryString = queryString.substring(1);
    }
    // Step 1: separate out each key/value pair
    var parts = queryString.split('&');
    for (var i = 0; i < parts.length; i++) {
        var p = parts[i];
        // Step 2: Split Key/Value pair
        var keyValuePair = p.split('=');
        // Step 3: Add Key/Value pair to Dictionary object
        var key = keyValuePair[0];
        var value = keyValuePair[1];
        // decode URI encoded string, if it exists
        if (value) {
            value = decodeURIComponent(value);
            value = value.replace(/\+/g, ' ');
        }
        else {
            value = ''; // keys with no value (e.g. '?is_lite&...')
        }
        dictionary[key] = value;
    }
    // Step 4: Return Dictionary Object
    return dictionary;
}
exports.parseQueryStringToDictionary = parseQueryStringToDictionary;

import { push as pushAction } from 'connected-react-router'
import CustomField from 'elements/field/CustomField'
import loadash from 'lodash'
import PropTypes from 'prop-types'
import {
  Edit,
  SelectInput,
  showNotification as showNotificationAction,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin'
import { useFormState } from 'react-final-form'
import { connect } from 'react-redux'
import DependentInput from '../../elements/input/DependentInput'

import {
  ar12,
  ar4,
  ar6,
  bimonthlyNames,
  defaultDailyCurve,
  monthNames,
  parseQueryStringToDictionary,
  quarterNames,
} from 'util/misc'

import set from 'set-value'

export const usageCurves = [
  { id: 'Flat', name: { default: 'Flat', GB: 'Flat  (MCS Home all day)' } },
  { id: 'Mostly Day', name: { default: 'Mostly Day', GB: 'Mostly Day  (MCS Home all day)' } },
  { id: 'Middle of Day', name: { default: 'Middle of Day', GB: 'Middle of Day  (MCS Home all day)' } },
  { id: 'Mostly Night', name: { default: 'Mostly Night', GB: 'Mostly Night  (MCS Out all day)' } },
  { id: 'Evening Peak', name: { default: 'Evening Peak', GB: 'Evening Peak  (MCS In half day)' } },
  { id: 'Evening High Peak', name: { default: 'Evening High Peak', GB: 'Evening High Peak  (MCS In half day)' } },
  { id: 'Evening Super Peak', name: { default: 'Evening Super Peak', GB: 'Evening Super Peak  (MCS In half day)' } },
  { id: 'Double Peak', name: { default: 'Double Peak', GB: 'Double Peak  (MCS In half day)' } },
  { id: 'EV Charging (Night-time)', name: { default: 'EV Charging (Night-time)', GB: 'EV Charging (Night-time)' } },
  {
    id: 'Business Hours 9am-5pm',
    name: { default: 'Business Hours 9am-5pm', GB: 'Business Hours 9am-5pm  (MCS Home all day)' },
  },
  {
    id: 'Extended Business Hours 7am-6pm',
    name: { default: 'Extended Business Hours 7am-6pm', GB: 'Extended Business Hours 7am-6pm  (MCS Home all day)' },
  },
]

export const getAllUsageFields = () => {
  const fields = [
    'usage_data_source',
    'bill_annual',
    'kwh_annual',
    'interval_60min',
    'curve_weekday',
    'curve_weekend',
    'scale_weekend',
    'demand_60min',
    'kw_monthly_demand',
    'controlled_load_daily_kwh_0',
    'controlled_load_daily_kwh_1',
    'controlled_load_daily_kwh_2',
    'units',
    'data_source',
    'end_date',
    'start_date',
    'file_name',
    'interval_mins',
    'monthly_usage_override',
    'monthly_values',
    'normalized',
    'natural_gas',
  ]
  ar12.forEach((i) => {
    fields.push('kwh_' + i)
    fields.push('bill_' + i)
    fields.push('kw_' + i)
  })
  return fields
}

export const validate8760 = (value) => {
  var numValues = value
    .split('\n')
    .join(',') // treat newline as tab delimiters
    .split('\t')
    .join(',') // allow tab delimiters
    .split(',,')
    .join(',') // remove double delimeters
    .split(',') // finally, split using delimeter
    .filter((v) => parseFloat(v) || parseFloat(v) === 0).length
  if (numValues === 8760) {
    return //valid
  } else {
    return numValues + ' / 8760 comma-separated hourly usage values entered.'
  }
}

export const validate8760OrEmpty = (value) => {
  if (!value || (value.trim && !value.trim())) {
    return //valid
  } else {
    return validate8760(value)
  }
}

const styles = {
  floatLeftMonthlyFirst: { display: 'inline-block', maxWidth: 100 },
  floatLeft: { width: '256' },
  floatLeftMonthly: { display: 'inline-block', marginLeft: 5, maxWidth: 100 },
}

const UsageInputs = (props) => {
  const translate = useTranslate()
  const formState = useFormState()
  const projectCountry = formState.values['country_iso2']
  return (
    <>
      <SelectInput
        source="usage_data_source"
        name="usage_data_source"
        style={detailStyle}
        // onChange={(event, newValue, previousValue) => {
        //   props.handleChangeSelect(event, 'usage_data_source', newValue)

        //   // trigger resize specifically for MyEnergy Edit Usage dialog which needs to resize
        //   // based on contents of this form
        //   // we could pass a property to ensure this only fires when necessary
        //   // but we currently just let it always fire - should be no major issue.
        //   setTimeout(function () {
        //     window.dispatchEvent(new Event('resize'))
        //   }, 100)
        // }}
        // value={props.usage_data_source}

        // do we need to clear associated fields??
        // options={{
        //   onChange: (event, element) => {
        //     const currentValue =
        //       form.getFieldState('usage_data_source') && form.getFieldState('usage_data_source').value
        //     const newValue = event.target.value
        //     form.batch(() => {
        //       form.mutators.updateField('usage_data_source', newValue)
        //     })
        //   },
        // }}
        choices={[
          { id: 'Enter Annual kWh', name: 'Annual kWh' },
          { id: 'Default', name: 'Default' },
          { id: 'Estimate (Low)', name: 'Estimate (Low)' },
          { id: 'Estimate (Medium)', name: 'Estimate (Medium)' },
          { id: 'Estimate (High)', name: 'Estimate (High)' },
          { id: 'Enter Daily kWh for 1-12 Months', name: 'Enter Daily kWh for 1-12 Months' },
          { id: 'Enter 1-12 Monthly kWh', name: 'Enter 1-12 Monthly kWh' },
          { id: 'Enter 1-12 Monthly Bills', name: 'Enter 1-12 Monthly Bills' },
          { id: 'Enter 1-6 Bi-Monthly kWh', name: 'Enter 1-6 Bi-Monthly kWh' },
          { id: 'Enter 1-6 Bi-Monthly Bills', name: 'Enter 1-6 Bi-Monthly Bills' },
          { id: 'Enter 1-4 Quarterly kWh', name: 'Enter 1-4 Quarterly kWh' },
          { id: 'Enter 1-4 Quarterly Bills', name: 'Enter 1-4 Quarterly Bills' },
          { id: 'Enter Annual Bill', name: 'Enter Annual Bill' },
          { id: 'Hourly Interval', name: 'Hourly Interval' },
        ]}
        translateChoice={true}
      />
      <div style={{ clear: 'both' }} />

      <DependentInput dependsOn="usage_data_source" value={'Enter 1-12 Monthly kWh'}>
        {ar12.map((i) => {
          return (
            <TextInput
              key={'kwh_' + i}
              source={'kwh_' + i}
              style={i === 0 || i === 6 ? styles.floatLeftMonthlyFirst : styles.floatLeftMonthly}
              // value={props['kwh_' + i]}
              label={translate(monthNames[i])}
              {...props}
            />
          )
        })}
      </DependentInput>

      <DependentInput dependsOn="usage_data_source" value={'Enter Daily kWh for 1-12 Months'}>
        {ar12.map((i) => {
          return (
            <TextInput
              key={'kwh_' + i}
              source={'kwh_' + i}
              style={i === 0 || i === 6 ? styles.floatLeftMonthlyFirst : styles.floatLeftMonthly}
              // value={props['kwh_' + i]}
              label={translate(monthNames[i])}
              {...props}
            />
          )
        })}
      </DependentInput>

      <DependentInput dependsOn="usage_data_source" value={'Enter 1-12 Monthly Bills'}>
        {ar12.map((i) => {
          return (
            <TextInput
              key={'bill_' + i}
              source={'bill_' + i}
              style={i === 0 || i === 6 ? styles.floatLeftMonthlyFirst : styles.floatLeftMonthly}
              // value={props['bill_' + i]}
              label={translate(monthNames[i])}
              {...props}
            />
          )
        })}
      </DependentInput>

      <DependentInput dependsOn="usage_data_source" value={'Enter 1-6 Bi-Monthly Bills'}>
        {ar6.map((i) => {
          return (
            <TextInput
              key={'bill_' + i}
              source={'bill_' + i}
              style={i === 0 ? styles.floatLeftMonthlyFirst : styles.floatLeftMonthly}
              // value={props['bill_' + i]}
              label={translate(bimonthlyNames[i])}
              {...props}
            />
          )
        })}
      </DependentInput>

      <DependentInput dependsOn="usage_data_source" value={'Enter 1-6 Bi-Monthly kWh'}>
        {ar6.map((i) => {
          return (
            <TextInput
              key={'kwh_' + i}
              source={'kwh_' + i}
              style={i === 0 ? styles.floatLeftMonthlyFirst : styles.floatLeftMonthly}
              // value={props['bill_' + i]}
              label={translate(bimonthlyNames[i])}
              {...props}
            />
          )
        })}
      </DependentInput>

      <DependentInput dependsOn="usage_data_source" value={'Enter 1-4 Quarterly kWh'}>
        {ar4.map((i) => {
          return (
            <TextInput
              key={'kwh_' + i}
              source={'kwh_' + i}
              style={i === 0 ? styles.floatLeftMonthlyFirst : styles.floatLeftMonthly}
              // value={props['kwh_' + i]}
              label={translate(quarterNames[i])}
              {...props}
            />
          )
        })}
      </DependentInput>

      <DependentInput dependsOn="usage_data_source" value={'Enter 1-4 Quarterly Bills'}>
        {ar4.map((i) => {
          return (
            <TextInput
              key={'bill_' + i}
              source={'bill_' + i}
              style={i === 0 ? styles.floatLeftMonthlyFirst : styles.floatLeftMonthly}
              // value={props['bill_' + i]}
              label={quarterNames[i]}
              {...props}
            />
          )
        })}
      </DependentInput>

      <DependentInput dependsOn="usage_data_source" value={'Enter Annual Bill'}>
        <TextInput
          key={'bill_annual'}
          source={'bill_annual'}
          // value={props['bill_annual']}
          {...props}
        />
      </DependentInput>

      <DependentInput dependsOn="usage_data_source" value={'Enter Annual kWh'}>
        <TextInput
          key={'kwh_annual'}
          source={'kwh_annual'}
          // value={props['kwh_annual']}
          {...props}
        />
      </DependentInput>

      <DependentInput dependsOn="usage_data_source" value={'Hourly Interval'}>
        <TextInput
          style={detailStyleNoWidth}
          fullWidth={true}
          multiline={true}
          key={'interval_60min'}
          source={'interval_60min'}
          validate={validate8760}
          {...props}
        />
      </DependentInput>
      <div style={{ clear: 'both' }} />
      <DependentInput dependsOn="usage_data_source" resolve={(value) => value !== 'Default'}>
        <SelectInput
          source="curve_weekday"
          allowEmpty
          emptyValue={null}
          style={detailStyle}
          translateChoice={projectCountry !== 'GB'}
          // value={props.curve_weekday}
          choices={usageCurves.map((val = {}) => {
            if (val.name) {
              return {
                id: val.id,
                //fixed the label. Requires backened migration otherwise!
                name: val.name[projectCountry] || val.name.default,
              }
            } else {
              return {
                id: val.id,
                name: 'Default (' + defaultDailyCurve + ')',
              }
            }
          })}
        />
      </DependentInput>

      <DependentInput dependsOn="usage_data_source" resolve={(value) => value !== 'Default'}>
        <SelectInput
          source="curve_weekend"
          allowEmpty
          emptyValue={null}
          style={detailStyle}
          translateChoice={projectCountry !== 'GB'}
          // value={props.curve_weekend}
          choices={usageCurves.map((val = {}) => {
            if (val.name) {
              return {
                id: val.id,
                name: val.name.default,
              }
            } else {
              return {
                id: val.id,
                name: 'Default (' + defaultDailyCurve + ')',
              }
            }
          })}
        />
      </DependentInput>

      <DependentInput dependsOn="usage_data_source" resolve={(value) => value !== 'Default'}>
        <TextInput
          source="scale_weekend"
          style={detailStyle}
          {...props}
          helperText={translate('(e.g. 1.1 = Weekends 10% higher than weekdays)')}
        />
      </DependentInput>

      <DependentInput dependsOn="usage_data_source" resolve={(value) => value !== 'Default'}>
        <TextInput
          style={detailStyleNoWidth}
          multiline={true}
          key={'demand_60min'}
          source={'demand_60min'}
          validate={validate8760OrEmpty}
          {...props}
        />
      </DependentInput>

      <DependentInput dependsOn="usage_data_source" resolve={(value) => value !== 'Default'}>
        <TextInput
          source="controlled_load_daily_kwh_0"
          style={detailStyle}
          {...props}
          //  value={props.controlled_load_daily_kwh_0}
        />
      </DependentInput>

      <DependentInput dependsOn="usage_data_source" resolve={(value) => value !== 'Default'}>
        <TextInput
          source="controlled_load_daily_kwh_1"
          style={detailStyle}
          {...props}
          // value={props.controlled_load_daily_kwh_1}
        />
      </DependentInput>

      <DependentInput dependsOn="usage_data_source" resolve={(value) => value !== 'Default'}>
        <TextInput
          source="controlled_load_daily_kwh_2"
          style={detailStyle}
          {...props}
          //  value={props.controlled_load_daily_kwh_2}
        />
      </DependentInput>

      <CustomField
        component={TextInput}
        source="usage"
        name="usage"
        // value={props.usage}
        style={{ width: '100%', opacity: '0.5', display: 'none' }}
        label="Hidden Data Usage"
      />
    </>
  )
}

const injectFromQueryString = (search, data) => {
  var fields = {
    addressSearch: 'string',
    address: 'string',
    zip: 'string',
    locality: 'string',
    state: 'string',
    country: 'string',
    county: 'string',
    lat: 'string',
    lon: 'string',
    business_name: 'string',
    business_identifier: 'string',
    is_residential: 'boolean',
    tags: 'array_of_strings',

    'contacts_new.0.first_name': 'string',
    'contacts_new.0.family_name': 'string',
    'contacts_new.0.email': 'string',
    'contacts_new.0.phone': 'string',

    add_more_details: 'boolean',
    priority: 'int',
    assigned_role: 'string',
    notes: 'string',

    usage_data_source: 'string',
    kwh_annual: 'float',
    curve_weekday: 'string',
    curve_weekend: 'string',
    scale_weekend: 'float',

    utilityTariffSupplied: 'boolean',
    utility_tariff_current: 'string',
    utility_tariff_proposed: 'string',

    initDesign: 'string',

    utility_tariff_current_custom: 'string',
    utility_tariff_proposed_custom: 'string',
  }

  var params = parseQueryStringToDictionary(search)
  Object.keys(params).forEach((path) => {
    if (Object.keys(fields).indexOf(path) !== -1) {
      var value
      switch (fields[path]) {
        case 'int':
          value = parseInt(params[path])
          break
        case 'float':
          value = parseFloat(params[path])
          break
        case 'boolean':
          var raw = params[path]
          if (raw === 'false' || raw === '0') {
            value = false
          } else {
            value = Boolean(params[path])
          }
          break
        case 'array_of_strings':
          value = params[path].split('|')
          break
        case 'json':
          value = JSON.parse(params[path])
          break
        case 'string':
        default:
          value = params[path]
          break
      }

      set(data, path, value)
    }
  })

  //Special treatment for lists/arrays of objects. Backend will not accept a dict with numeric keys
  if (data.contacts_new) {
    data.contacts_new = Object.keys(data.contacts_new).map((key) => {
      return data.contacts_new[key]
    })
  }
}

export const defaultValueFunc = function (record, search, country_iso2) {
  var data = {
    usage_data_source: 'Enter Annual kWh',
    kwh_annual: '',
    bill_annual: '',
    interval_60min: '',
    demand_60min: '',
    kw_monthly_demand: null,
    curve_weekday: null,
    curve_weekend: null,
    scale_weekend: null,
    controlled_load_daily_kwh_0: null,
    controlled_load_daily_kwh_1: null,
    controlled_load_daily_kwh_2: null,
    country_iso2: country_iso2 || (record ? record.country_iso2 : undefined),
    is_residential: record ? Boolean(record.is_residential) : true,
  }

  if (search) {
    injectFromQueryString(search, data)
  }

  ar12.forEach((i) => {
    data['kwh_' + i] = ''
  })
  ar12.forEach((i) => {
    data['bill_' + i] = ''
  })

  if (record && record.usage && record.usage.length) {
    try {
      var usageData = JSON.parse(record.usage)

      if (
        (usageData.usage_data_source &&
          usageData.usage_data_source.length &&
          usageData.values &&
          (usageData.values > 0 || usageData.values.length > 0)) ||
        !usageData.values
      ) {
        data = applyUsageData(usageData, data)
      }
    } catch (err) {
      console.error(err)
    }
  }

  if (data.initDesign) {
    try {
      data.design = window.CompressionHelper.compress(JSON.stringify(JSON.parse(data.initDesign)))
    } catch (err) {
      console.error(err)
    }
  }

  return data
}

const applyUsageData = function (usageData, data) {
  if (!data) data = {}
  if (!usageData) {
    usageData = {
      usage_data_source: null,
      kwh_annual: '',
      bill_annual: '',
      interval_60min: '',
      demand_60min: '',
      kw_monthly_demand: null,
      curve_weekday: null,
      curve_weekend: null,
      scale_weekend: null,
      controlled_load_daily_kwh_0: null,
      controlled_load_daily_kwh_1: null,
      controlled_load_daily_kwh_2: null,
      natural_gas: {},
    }
    ar12.forEach((i) => {
      usageData['kwh_' + i] = ''
      usageData['bill_' + i] = ''
      usageData['kw_' + i] = ''
    })
  }

  switch (usageData && usageData.usage_data_source) {
    case 'Estimate':
      if (usageData.values === 'Low') {
        data.usage_data_source = 'Estimate (Low)'
      } else if (usageData.values === 'Medium') {
        data.usage_data_source = 'Estimate (Medium)'
      } else if (usageData.values === 'High') {
        data.usage_data_source = 'Estimate (High)'
      }
      break
    case 'kwh_monthly':
      // this.setState({usage_data_source:'Estimate (High)'})
      data.usage_data_source = 'Enter 1-12 Monthly kWh'

      ar12.forEach((i) => {
        data['kwh_' + i] = usageData.values[i]
      })

      break
    case 'kwh_daily_per_month':
      // this.setState({usage_data_source:'Estimate (High)'})
      data.usage_data_source = 'Enter Daily kWh for 1-12 Months'

      ar12.forEach((i) => {
        data['kwh_' + i] = usageData.values[i]
      })

      break
    case 'bill_monthly':
      data.usage_data_source = 'Enter 1-12 Monthly Bills'

      ar12.forEach((i) => {
        data['bill_' + i] = usageData.values[i]
      })

      break
    case 'kwh_every_second_month':
      data.usage_data_source = 'Enter 1-6 Bi-Monthly kWh'

      ar6.forEach((i) => {
        data['kwh_' + i] = usageData.values[i]
      })

      break
    case 'bill_every_second_month':
      data.usage_data_source = 'Enter 1-6 Bi-Monthly Bills'

      ar6.forEach((i) => {
        data['bill_' + i] = usageData.values[i]
      })

      break
    case 'kwh_quarterly':
      // this.setState({usage_data_source:'Estimate (High)'})
      data.usage_data_source = 'Enter 1-4 Quarterly kWh'

      ar4.forEach((i) => {
        data['kwh_' + i] = usageData.values[i]
      })

      break
    case 'bill_quarterly':
      data.usage_data_source = 'Enter 1-4 Quarterly Bills'

      ar4.forEach((i) => {
        data['bill_' + i] = usageData.values[i]
      })

      break
    case 'kwh_annual':
      data.usage_data_source = 'Enter Annual kWh'
      data.kwh_annual = usageData.values
      break
    case 'bill_annual':
      data.usage_data_source = 'Enter Annual Bill'
      data.bill_annual = usageData.values
      break
    case 'interval_60min':
      data.usage_data_source = 'Interval Data'
      data.interval_60min = usageData.values
      break
    case 'interval_data':
      data.usage_data_source = 'Interval Data'
      break
    case 'Default':
    default:
      data.usage_data_source = 'Enter Annual kWh'
      data.values = null
  }

  data.curve_weekday = usageData.curve_weekday
  data.curve_weekend = usageData.curve_weekend
  data.scale_weekend = usageData.scale_weekend
  data.demand_60min = usageData.demand_60min
  data.controlled_load_daily_kwh_0 = usageData.controlled_load_daily_kwh_0
  data.controlled_load_daily_kwh_1 = usageData.controlled_load_daily_kwh_1
  data.controlled_load_daily_kwh_2 = usageData.controlled_load_daily_kwh_2
  data.natural_gas = usageData.natural_gas

  if (data.utilityTariffSupplied) {
    data.utilityTariffSupplied = data.usage_data_source !== 'default'
  }

  if (usageData.kw_monthly_demand) {
    ar12.forEach((i) => {
      data['kw_' + i] = usageData.kw_monthly_demand[i]
    })
  }

  return data
}

const floatOrNull = (value) => {
  var valueAsFloat = parseFloat(value)
  if (isNaN(valueAsFloat)) {
    return null
  } else {
    return valueAsFloat
  }
}

const getIntervalData = (values) => {
  if (!values.usage) return undefined

  const intervalData = JSON.parse(values.usage)
  const units = values.units
  const monthly_usage_override =
    values.monthly_usage_override && !loadash.isEqual(values.monthly_usage_override, intervalData.monthly_values)
      ? values.monthly_usage_override
      : null
  return {
    data_source: intervalData.data_source,
    end_date: intervalData.end_date,
    start_date: intervalData.start_date,
    file_name: intervalData.file_name,
    interval_mins: intervalData.interval_mins,
    kw_monthly_demand: intervalData.kw_monthly_demand,
    monthly_values: intervalData.monthly_values,
    values: intervalData.values,
    uploaded_file_id: intervalData.uploaded_file_id,
    units,
    monthly_usage_override,
  }
}

export const getUsageData = function (values) {
  var data = {
    natural_gas: values['natural_gas'],
    usage_data_source: 'default',
    values: null,
    curve_weekday: values['curve_weekday'],
    curve_weekend: values['curve_weekend'],
    scale_weekend: floatOrNull(values['scale_weekend']),
    demand_60min: values['demand_60min'],
    kw_monthly_demand: values['kw_monthly_demand'],
    controlled_load_daily_kwh_0: floatOrNull(values['controlled_load_daily_kwh_0']),
    controlled_load_daily_kwh_1: floatOrNull(values['controlled_load_daily_kwh_1']),
    controlled_load_daily_kwh_2: floatOrNull(values['controlled_load_daily_kwh_2']),
  }

  switch (values['usage_data_source']) {
    case 'Estimate (Low)':
      data.usage_data_source = 'Estimate'
      data.values = 'Low'
      break
    case 'Estimate (Medium)':
      data.usage_data_source = 'Estimate'
      data.values = 'Medium'
      break
    case 'Estimate (High)':
      data.usage_data_source = 'Estimate'
      data.values = 'High'
      break
    case 'Enter 1-12 Monthly kWh':
      data.usage_data_source = 'kwh_monthly'
      data.values = ar12.map((i) => floatOrNull(values['kwh_' + i]))
      break
    case 'Enter Daily kWh for 1-12 Months':
      data.usage_data_source = 'kwh_daily_per_month'
      data.values = ar12.map((i) => floatOrNull(values['kwh_' + i]))
      break
    case 'Enter 1-12 Monthly Bills':
      data.usage_data_source = 'bill_monthly'
      data.values = ar12.map((i) => floatOrNull(values['bill_' + i]))
      break
    case 'Enter 1-6 Bi-Monthly kWh':
      data.usage_data_source = 'kwh_every_second_month'
      data.values = ar6.map((i) => floatOrNull(values['kwh_' + i]))
      break
    case 'Enter 1-6 Bi-Monthly Bills':
      data.usage_data_source = 'bill_every_second_month'
      data.values = ar6.map((i) => floatOrNull(values['bill_' + i]))
      break
    case 'Enter 1-4 Quarterly kWh':
      data.usage_data_source = 'kwh_quarterly'
      data.values = ar4.map((i) => floatOrNull(values['kwh_' + i]))
      break
    case 'Enter 1-4 Quarterly Bills':
      data.usage_data_source = 'bill_quarterly'
      data.values = ar4.map((i) => floatOrNull(values['bill_' + i]))
      break
    case 'Enter Annual kWh':
      data.usage_data_source = 'kwh_annual'
      data.values = floatOrNull(values['kwh_annual'])
      break
    case 'Enter Annual Bill':
      data.usage_data_source = 'bill_annual'
      data.values = floatOrNull(values['bill_annual'])
      break
    case 'Hourly Interval':
      data.usage_data_source = 'interval_data'
      data.values = values['interval_60min']
      break
    case 'Interval Data':
      data.usage_data_source = 'interval_data'
      data = { ...data, ...(getIntervalData(values) || {}) }
      break
    case 'Default':
    default:
    //keep default values
  }

  data.kw_monthly_demand = ar12.map((i) => floatOrNull(values['kw_' + i]))

  return data
}

const detailStyle = {
  marginRight: 10,
  clear: 'both',
  width: '100%',
  maxWidth: 256,
}

const detailStyleNoWidth = {
  //marginRight: 10,
  clear: 'both',
  maxWidth: 660,
  width: '100%',
}

const formatSubmitValues = (values) => {
  const submitValues = getUsageData(values)
  const newUsageString = JSON.stringify(submitValues)
  submitValues.usage = newUsageString
  return submitValues
}

// ProjectEditUsageModal
const _ProjectEditUsageModal = (props) => {
  return (
    <Edit title={null} actions={null} {...props}>
      <SimpleForm
        defaultValue={defaultValueFunc}
        formatSubmitValues={formatSubmitValues}
        redirect={false}
        hideCancelButton={true}
      >
        <UsageInputs {...props} showAlways={true} />
      </SimpleForm>
    </Edit>
  )
}

_ProjectEditUsageModal.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
}

export const ProjectEditUsageModal = connect(
  {},
  {
    showNotification: showNotificationAction,
    push: pushAction,
  }
)(_ProjectEditUsageModal)

import jwt from 'jwt-decode'
import { FC } from 'react'
import { usePermissions } from 'react-admin'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import appStorage from 'storage/appStorage'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { pathnameAndParamsWithUrlFragment } from './common'
import LoginForm from './components/auth/LoginForm'
import LoginModalActions from './components/auth/actions/LoginModalActions'
import useLoginStep from './components/auth/useLoginStep'
import { OpenSolarAuthFlowLogo } from './components/logos/AuthFlowLogo'
import NearMapLoginMessage from './nearmap/NearMapLoginMessage'

const useStyles = makeOpenSolarStyles((theme: any) => ({
  wrapper: {},
}))

const getEmailFromNearmapToken = () => {
  try {
    var nearmap_token = appStorage.getString('nearmap_token')
    if (nearmap_token) {
      var decodedToken: JWT | undefined = jwt(nearmap_token)
      if (decodedToken && decodedToken.email) {
        return decodedToken.email
      }
    }
  } catch (e) {
    return null
  }
}

interface JWT {
  email: string | undefined
}

const LoginModal: FC<{ urlFragment: string | null }> = ({ urlFragment }) => {
  const location = useLocation()
  const history = useHistory()
  const step = useLoginStep()

  const { loaded, permissions } = usePermissions()

  const onCancel = () => {
    history.push('/logout')
  }

  const onMfaFinish = () => {
    history.push('/home')
  }

  const onForgotPassword = () => {
    history.push(pathnameAndParamsWithUrlFragment('/password-reset', location, urlFragment))
  }

  // Do not redirect any nearmap flows
  const isNearmapUrl = urlFragment && urlFragment.toLowerCase().includes('nearmap')

  if (loaded && !isNearmapUrl && step === 'login' && permissions.includes('is_user')) {
    console.debug('Login complete, redirecting to /home')
    return (
      <>
        <Redirect to={'/home'} />
      </>
    )
  }

  return (
    <LoginForm
      initialValues={{ email: getEmailFromNearmapToken() }}
      onMfaFinish={onMfaFinish}
      onMfaCancel={onCancel}
      Logo={<OpenSolarAuthFlowLogo />} // Always OS logo when it's login modal
      AdditionalActions={<LoginModalActions onForgotPassword={onForgotPassword} onCancel={onCancel} />}
      AdditionalMessage={<NearMapLoginMessage />} // bad naming ... NearMapLoginMessage is a legacy component
    />
  )
}

export default LoginModal

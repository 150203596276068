import { defaultValueFunc } from 'resources/projects/UsageComponents'

// re-use proux1 defaultValue Func as much as possible.

const parseUsageInitialValues = (formValsOnSave: any, returnedVals: any = {}) => {
  let parsedUsageData
  if (returnedVals?.usage?.length > 0) {
    parsedUsageData = defaultValueFunc(returnedVals)
  } else {
    parsedUsageData = defaultValueFunc(formValsOnSave)
  }
  if (parsedUsageData) {
    for (const [key, value] of Object.entries(parsedUsageData)) {
      //ignore is_residential and country_iso2 that parsed from defaultValueFunc
      //To Do: refactor defaultValueFunc after completely transfer to UX2.0
      //untangle is_residential and country_iso2
      if (key !== 'is_residential' && key !== 'country_iso2') {
        formValsOnSave[key] = value
      }
    }
  }
  return formValsOnSave
}

export default parseUsageInitialValues

import { withWidth } from '@material-ui/core'
import { terminateCurrentTour, updateTourStep } from 'actions/tourActions'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors, removeTourBanner } from 'ducks/auth'
import { useShowTourBanner } from 'hooks/useShowTourBanner'
import { recordUserAction } from 'persistentContent/tours/tools/common'
import { useTranslate } from 'ra-core'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ProjectFujiTour from '.'
import ConfirmModal from '../../../../projectSections/sections/manage3/common/ConfirmModal'
import tourImage from './assets/list.gif'
import BannerDialog from './BannerDialog'
interface PropTypes {
  width: string
}

const tourType = 'project_list'
const ProjectFujiTourBanner: FC<PropTypes> = ({ width }) => {
  const dispatch = useDispatch()
  const translate = useTranslate()
  const { tour } = useSelector((state: any) => state.tour)
  const shouldShowBanner = useShowTourBanner(width, tourType)
  const [displayBanner, setDisplayBanner] = useState(true)
  const [confirmDialog, showConfirm] = useState(false)
  const [endDialog, showEndDialog] = useState(false)
  const userId = useSelector(authSelectors.getCurrentUserId)

  useEffect(() => {
    if (shouldShowBanner.show) logAmplitudeEvent('fuji_banner_shown', { tourType })
    return () => {
      handleHide()
    }
  }, [])

  const handleHide = () => {
    if (userId) recordUserAction(userId, 'list_tour_hidden')
    logAmplitudeEvent('fuji_banner_hidden', { tourType })
    dispatch(removeTourBanner(true, tourType))
    dispatch(terminateCurrentTour(tour))
    showConfirm(false)
    setDisplayBanner(false)
  }

  const handleStart = () => {
    logAmplitudeEvent('fuji_tour_started', { tourType })
    setDisplayBanner(false)
    dispatch(updateTourStep('fuji_project', 'details'))
  }

  const handleDialogCloseClick = () => {
    logAmplitudeEvent('fuji_confirm_cancel_dialog_shown', { tourType })
    showConfirm(true) // Show the confirmation dialog when the close button is clicked
  }

  const handleEndTour = () => {
    if (userId) recordUserAction(userId, 'list_tour_finish')
    dispatch(removeTourBanner(true, 'project_list'))
    dispatch(terminateCurrentTour(tour))
    showEndDialog(false)
  }

  if (!shouldShowBanner.show) return null
  return (
    <>
      <BannerDialog
        endDialog={false}
        headerText="Your projects have a new look!"
        subText="Take a walkthrough with us to discover the user-friendly changes designed to make your projects tracking and filtering more efficient."
        actionLabel="Get Started"
        open={displayBanner}
        onClose={handleDialogCloseClick}
        handleAction={handleStart}
        imgSrc={tourImage}
      />
      <ConfirmModal
        open={confirmDialog}
        setOpen={showConfirm}
        title={translate('Are you sure you want to skip?')}
        mainText={translate(
          'The tour is the best way for you to quickly learn your way around the new Projects page on OpenSolar.'
        )}
        submitBtnLabel={translate('Skip Tour')}
        handleSubmit={handleHide}
        submitBtnProps={{ variant: 'contained' }}
      />
      <BannerDialog
        endDialog={endDialog}
        headerText="Projects Page Tour Complete!"
        subText="Need a refresher? Start the tour anytime from the Support button. For more details on the redesigned pages, explore our Help Center."
        actionLabel="Learn more"
        open={endDialog}
        onClose={handleEndTour}
        handleAction={() => {
          window.open('https://support.opensolar.com/hc/en-us/sections/11007475981839-New-Manage-UI', '_blank')
          handleEndTour()
        }}
      />

      <ProjectFujiTour
        toggleEndDialog={() => {
          showEndDialog(true)
        }}
      />
    </>
  )
}

export default withWidth()(ProjectFujiTourBanner)

import { useMemo, useState } from 'react'
import { QueryPresenter } from './queryPresenter'

import { Query } from './query'

const useQuery = (defaultQuery?: Partial<Query>): { query: Query; queryPresenter: QueryPresenter } => {
  const [query, setQuery] = useState(() => new Query(defaultQuery))
  const queryPresenter = useMemo(() => new QueryPresenter(setQuery), [])
  return { query, queryPresenter }
}

export default useQuery

import { Dialog, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import Button from 'elements/proUXButtons/ProUXButton'
import { LOANPAL_OR_GOODLEAP } from 'myenergy/selectionComponent/loanApplicationButton/loanpal/constants'
import { useTranslate } from 'ra-core'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { reloadEntireApp } from 'util/misc'

type PropTypes = {
  isOpen: boolean
  close: () => void
}

const useStyles = makeStyles({
  root: {},
  checked: {},
  errorText: {
    color: 'red',
    textAlign: 'center',
    width: '100%',
  },
})

const LoanpalAccountDialog: React.FunctionComponent<PropTypes> = (props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const [password, setPassword] = useState<string | undefined>(undefined)
  const [username, setUsername] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (errorMsg) {
      logAmplitudeEvent('loanpal_account_dialog_error', { error: errorMsg })
    }
  }, [errorMsg])

  const orgId = useSelector(authSelectors.getOrgId)

  const classes = useStyles()
  const translate = useTranslate()

  const save = () => {
    if (!username && !password) {
      setErrorMsg('Username and password are both required')
      return
    } else if (!username) {
      setErrorMsg('Username is required')
      return
    } else if (!password) {
      setErrorMsg('password is required')
      return
    }

    setLoading(true)
    const restClientInstance = restClient(window.API_ROOT + '/api')
    restClientInstance('CUSTOM_POST', 'custom', {
      url: 'orgs/' + orgId + '/connect_loanpal/',
      data: {
        username,
        password,
      },
    })
      .then((response: any) => {
        if (response.data?.success) {
          logAmplitudeEvent('loanpal_account_connected', {})
          let url = '/#/payment_options'
          if (response?.data?.products_added !== undefined)
            url += `?products_added=${response.data.products_added}&partner=Goodleap`
          reloadEntireApp(url)
        } else {
          setErrorMsg('Incorrect username and/or password')
        }
      })
      .catch((error: any) => {
        setErrorMsg(`Unable to connect your ${LOANPAL_OR_GOODLEAP} account`)
        console.log('error', error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Dialog open={props.isOpen} style={{ textAlign: 'center' }} onClose={props.close}>
      <DialogTitle>{translate(`Connect your ${LOANPAL_OR_GOODLEAP} Account`)}</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <p>
              Enter your {LOANPAL_OR_GOODLEAP} API credentials below to connect your account. If you don't have these
              credentials you can get them by reaching out to {LOANPAL_OR_GOODLEAP} directly
            </p>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="loanpalUsername"
              label={`${LOANPAL_OR_GOODLEAP} API Username`}
              fullWidth={true}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="loanpalPassword"
              label={`${LOANPAL_OR_GOODLEAP} API Password`}
              fullWidth={true}
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Grid>
          {errorMsg && (
            <Grid item xs={12}>
              <p className={classes.errorText}>{errorMsg}</p>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            style={{ paddingTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
          >
            <Button label="Connect Your Account" type="primary" onClick={save} loading={loading} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
export default LoanpalAccountDialog

import { useTranslate } from 'ra-core'
import React from 'react'
import { TextInput } from 'react-admin'

type PropTypes = {}

const NativeCheckoutNameInput: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()

  //RN keyboard hide the content, hack to make sure the input field alway shows in current window
  const handleInputHackOnFocus = (e: any) => {
    window.addEventListener('resize', () => e.target?.scrollIntoView({ behavior: 'smooth' }))
  }
  const handleInputHackOnBlur = (e: any) => {
    window.removeEventListener('resize', () => e.target?.scrollIntoView({ behavior: 'smooth' }))
  }
  const validate = (value: string) => {
    if (!value) return 'Please type in your name below'
    else if (value.length < 4) return 'Your name must be at least 4 characters long'
    else return undefined
  }
  return (
    <TextInput
      label={translate('Your Name')}
      source="customer_name"
      fullWidth
      style={{
        maxWidth: 256,
        width: '100%',
        display: 'block',
        marginTop: 10,
      }}
      onFocus={handleInputHackOnFocus}
      onBlur={handleInputHackOnBlur}
      validate={validate}
    />
  )
}
export default NativeCheckoutNameInput

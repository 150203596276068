import Confirm, { ButtonInfo, ConfirmProps } from 'projectSections/elements/dialog/Confirm'
import React, { useMemo, useState } from 'react'
import { Button } from 'react-admin'

interface PropTypes {
  record: any
  resource: string
  label: string
  handleClick: () => void
  variant: 'text' | 'outlined' | 'contained' | undefined
  dialogTitle: string
  dialogBody: string
  confirmLabel: string
  icon: any
  size?: 'small' | 'medium' | 'large' | undefined
  saveButton?: ButtonInfo
}

const ButtonWithConfirmDialog: React.FC<PropTypes> = (props) => {
  const [dialog, showDialog] = useState(false)
  const handleClose = () => showDialog(false)
  const defaultButtons: ConfirmProps['buttons'] = useMemo(
    () => [
      {
        children: 'Cancel',
        onClick: handleClose,
      },
      props.saveButton || {
        color: 'primary',
        children: props.confirmLabel,
        onClick: props.handleClick,
      },
    ],
    [props]
  )

  return (
    <>
      <Button
        size={props.size}
        variant={props.variant}
        label={props.label}
        record={props.record}
        resource={props.resource}
        redirect={props.resource}
        onClick={() => showDialog(true)}
      >
        {props.icon}
      </Button>
      <Confirm
        open={dialog}
        header={{ title: props.dialogTitle }}
        content={props.dialogBody}
        buttons={defaultButtons}
      />
    </>
  )
}

export default ButtonWithConfirmDialog

import type { Theme as MUITheme } from '@material-ui/core/styles/createMuiTheme'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import { breakpoints } from 'layout/LayoutConfig'
import { CSSProperties } from 'react'

export type OsValidityType = 'default' | 'valid' | 'error' | 'warning'
const COLOR_PALETTE = {
  blue: {
    100: '#FDFDFF',
    200: '#FAFBFE',
    300: '#EFF4FF',
    400: '#D6E0F7',
    500: '#B8CAF2',
    600: '#94B0EC',
    700: '#6c92e4',
    800: '#4272dd',
    900: '#1751D0',
    1000: '#113B98',
  },
}

const ValidityPalette: {
  [key in OsValidityType]: {
    color: string
    background: string
  }
} = {
  valid: {
    background: '#ebfbf1',
    color: '#018030',
  },
  error: {
    background: '#ffe3e3',
    color: '#bc1c1c',
  },
  warning: {
    background: '#fff1da',
    color: '#c4710a',
  },
  default: {
    background: '#f1f1f1',
    color: '#7f7d83',
  },
}

export const Z_INDEX_CONFIGS = {
  // TODO: add more configs and never set zIndex value directly
  tooltip: 2500,
  modal: 2000,
  drawer: 1200,
}

const BOX_SHADOW_CONFIGS = {
  default: '0px 2px 4px -1px rgba(0, 0, 0, 0.05), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
  hoverSpecial: '0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
}

const OpenSolarPalette = {
  white: '#FFFFFF',
  openSolarYellow: '#FFD800',
  warningYellow: '#E99F26',
  warningOrange: '#C95213',
  errorRed: '#D32F2F',
  themeColor: '#ffda00',
  themeColorLight: '#fff6c5',
  themeColorDark: '#F3B800',
  primaryColor: '#FFD800',
  primaryColorOnHover: '#FFC700',
  secondaryColor: '#0094FF', // blue
  headerBackgroundColor: 'rgb(255,255,255)',
  headerFontColor: 'rgb(0, 0, 0)',
  headerHighlightFontColor: 'rgba(24, 144, 255, 1)',
  headerIconColor: 'rgba(0,0,0)',
  searchIconColor: 'rgb(41,41,41)',
  infoIconColor: 'rgb(0, 148, 255)',
  helpColor: '#5bafff', // light blue
  blue5: '#113B98',
  blue4: '#4272DD',
  name: 'opensolar',
  secondaryColorLight: 'rgb(0, 148, 255)',

  greyLight1: '#F6F6F6',
  greyLight2: '#CDCDCD',
  greyLight3: '#F8F8F8',
  greyLight4: '#F6F9FC',
  greyMid1: '#757575',
  greyMid2: '#E6E6E6',
  greyDark2: '#212121',
}

const NearmapPalette = {
  ...OpenSolarPalette,
  headerBackgroundColor: 'rgb(14, 35, 81)',
  headerFontColor: 'rgb(255,255,255)',
  headerHighlightFontColor: 'rgb(255,255,255)',
  headerIconColor: 'rgb(255,255,255)',
  helpColor: '#FFF',
  name: 'nearmap',
}

const getTheme = (palette): OpenSolarThemeType => {
  const standardFontColor = 'rgba(0, 0, 0, 0.87)'
  const stdInputBorder_color = '#E7E7E7 !important'
  const stdInputBorder_hover = '#B4B4B4 !important'
  const stdBtnFontSize = 16

  const primaryBorder_hover = {
    borderWidth: 2,
    borderColor: stdInputBorder_hover,
  }

  const standardBorder = `2px solid ${stdInputBorder_color}`

  const primaryBorder = {
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: stdInputBorder_color,

    '&:hover': primaryBorder_hover,
    '&:focus': primaryBorder_hover,
  }
  const defaultMuiTheme = createMuiTheme()
  return {
    ...palette,
    ValidityPalette,
    straitPalette: COLOR_PALETTE,
    fontFamily: 'Roboto, sans-serif', // TODO: remove, already exists in typography
    standardFontColor,
    helpIconColor: palette.secondaryColorLight,
    blue: palette.secondaryColor, // TODO: move to 'secondaryColor'

    alert_error: 'rgb(95, 33, 32)',
    alertBg_error: 'rgb(253, 237, 237)',
    alertIcon_error: 'rgb(230, 37, 0)',

    alert_warning: 'rgb(102, 60, 0)',
    alertBg_warning: 'rgb(255, 244, 229)',
    alertIcon_warning: '#f79522',

    alert_info: 'rgb(44,73,157)',
    alertBg_info: 'rgb(236, 236, 236)',
    alertIcon_info: 'rgb(44,73,157)',

    alert_success: 'rgb(44,157,73)',
    alertBg_success: 'rgb(236,236,236)',
    alertIcon_success: 'rgb(44,157,73)',

    boxShadow: BOX_SHADOW_CONFIGS,

    border: {
      default: standardBorder,
    },

    breakpoints: {
      values: breakpoints,
    },
    palette: {
      primary: {
        main: standardFontColor,
      },
    },
    name: palette.name,
    zIndex: {
      ...Z_INDEX_CONFIGS,
    },
    typography: {
      alignLeft: {
        textAlign: 'left',
      },
      alignCenter: {
        textAlign: 'center',
      },
      alignRight: {
        textAlign: 'right',
      },
      alignJustify: {
        textAlign: 'justify',
      },
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 600,
      button: {
        fontSize: stdBtnFontSize,
      },
    },
    overrides: {
      MuiPaper: {
        elevation1: { boxShadow: 'none', border: standardBorder },
      },
      MuiAccordion: {
        root: {
          marginBottom: 10,
          '&:before': {
            display: 'none',
          },
        },
        rounded: { borderRadius: 4 },
      },
      MuiFab: {
        root: {
          color: palette.secondaryColor,
          border: standardBorder,
          backgroundColor: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#FFFFFF',
            border: '2px solid ' + palette.secondaryColor,
            boxShadow: '0px 4px 5px rgb(0 0 0 / 14%), 0px 1px 10px rgb(0 0 0 / 12%)',
          },
        },
      },
      MuiLink: {
        root: {
          whiteSpace: 'nowrap',
          color: 'rgb(24, 144, 255) !important', //TODO: remove all of the conflicts on this
        },
      },
      MuiCircularProgress: {
        colorPrimary: {
          color: palette.themeColor,
        },
      },
      MuiFormControl: {
        marginDense: {
          marginTop: 16,
          marginBottom: 16,
          marginRight: 30,
        },
      },
      MuiDialog: {
        paper: {
          width: '80%',
        },
        paperScrollPaper: {
          maxHeight: 'calc(90% - 64px)',
        },
      },
      MuiTabs: {
        indicator: {
          backgroundColor: palette.themeColor,
        },
      },
      MuiTableCell: {
        root: {
          overflow: 'hidden',
          whiteSpace: 'normal',
          textOverflow: 'ellipsis',
          fontSize: 13,
        },
        sizeSmall: {
          padding: '6px 12px 6px 10px',
        },
      },
      MuiInputLabel: {
        root: {
          '&$focused': {
            color: 'rgba(0, 0, 0, 0.9)',
          },
          fontSize: 14,
        },
      },
      MuiExpansionPanel: {
        root: {
          marginLeft: 10,
          marginRight: 10,
          '&$expanded': {
            marginLeft: 0,
            marginRight: 0,
          },
        },
      },
      MuiTypography: {
        body1: {
          fontSize: 14,
          lineHeight: 1,
        },
        colorPrimary: {
          color: palette.themeColor,
        },
      },
      MuiInput: {
        root: {
          fontSize: 14,
        },
        underline: {
          '&:after': {
            borderBottomColor: 'rgba(0,0,0,0.9) !important',
          },
        },
      },
      MuiStepIcon: {
        root: {
          '&$active': {
            color: '#1890ff',
          },
          '&$completed': {
            color: '#1890ff',
          },
        },
      },
      MuiIconButton: {
        colorPrimary: {
          color: '#4d4d4d',
        },
        colorSecondary: {
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
      },
      MuiButton: {
        root: {
          marginLeft: 0,
          boxSizing: 'border-box',
          height: 40,
          fontSize: stdBtnFontSize,
          fontWeight: 400,
          lineHeight: '16px',
          padding: '10.5px 15px',
          '&$disabled': {
            color: '#B4B4B4',
            backgroundColor: '',
            background: '#FFFFFF',
          },
        },
        sizeSmall: {
          height: 30,
          fontSize: 14,
          lineHeight: '13px',
          padding: '7px 12px',
        },
        label: {
          textTransform: 'capitalize',
          // textTransform: 'lowercase',
          // '&:first-letter': {
          //   textTransform: 'capitalize',
          // },
          // '& > *:first-letter': {
          //   textTransform: 'capitalize',
          // },
          '& .MuiSvgIcon-root': {
            width: 21,
            height: 21,
            marginRight: 10,
            fontSize: 20,
          },
        },
        disabled: {
          //do not add css here
          //this will add styling to .Mui-disabled globally
        },
        contained: {
          ...primaryBorder,
          color: '#212121',
          backgroundColor: '#F8F8F8',
          boxShadow: 'unset',
          '&:hover': {
            ...primaryBorder_hover,
            backgroundColor: '#F8F8F8',
            boxShadow: BOX_SHADOW_CONFIGS.hoverSpecial,
          },
          '&.Mui-disabled': {
            color: '#B4B4B4',
            backgroundColor: '#FFFFFF',
          },
        },
        containedPrimary: {
          boxShadow: BOX_SHADOW_CONFIGS.default,
          backgroundColor: palette.primaryColor,
          fontWeight: 500,
          color: '#212121',
          border: 'unset',
          '&:hover': {
            border: 'unset',
            backgroundColor: '',
            background: `linear-gradient(360deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 62.5%), ${palette.primaryColorOnHover}`,
            boxShadow: BOX_SHADOW_CONFIGS.default,
          },
          '&.Mui-disabled': {
            backgroundColor: 'rgba(255, 199, 0, 0.3)',
            color: '#B4B4B4',
          },
        },
        containedSecondary: {
          border: 'unset',
          fontWeight: 500,
          boxShadow: BOX_SHADOW_CONFIGS.default,
          color: '#FFFFFF',
          background: 'linear-gradient(360deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 62.5%), #66B551',
          backgroundColor: '',
          '&:hover': {
            border: 'unset',
            backgroundColor: '',
            background: 'linear-gradient(360deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 62.5%), #1F9600',
            boxShadow: BOX_SHADOW_CONFIGS.default,
          },
          '&.Mui-disabled': {
            color: '#FFFFFF',
            background:
              'linear-gradient(360deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 62.5%), rgba(31, 150, 0, 0.35)',
          },
        },
        containedSizeLarge: {
          padding: '10.5px 15px',
          fontSize: 16,
        },
        outlined: {
          ...primaryBorder,
          color: palette.secondaryColor,
          padding: '10.5px 15px',
          background: '#FFFFFF',
          '&:hover': {
            ...primaryBorder_hover,
            background: '#FFFFFF',
            boxShadow: BOX_SHADOW_CONFIGS.hoverSpecial,
          },
          '&.Mui-disabled': {
            // border: stdInputBorder,
            color: 'rgba(0, 148, 255, 0.45)',
          },
        },
        outlinedPrimary: {
          // color: standardFontColor,
          ...primaryBorder,
        },
        textPrimary: {
          color: '#4d4d4d',
          '&:hover': {
            backgroundColor: 'rgba(77, 77, 77, 0.3)',
          },
        },
        textSizeSmall: {
          padding: '4px 10px',
        },

        startIcon: {
          width: 21,
          height: 21,
          margin: '-1px 10px -1px 0',
          '&.MuiButton-iconSizeSmall': {
            margin: '0 10px 0 0',
          },
        },

        endIcon: {
          '&.MuiButton-iconSizeSmall': {
            margin: '0 0 0 10px',
          },
        },

        iconSizeSmall: {
          marginLeft: 0,
          marginRight: 10,
          width: 15,
          height: 15,
          '& > *:first-child': {
            width: 'inherit',
            height: 'inherit',
          },
        },
      },
      MuiChip: {
        outlinedSecondary: {
          color: defaultMuiTheme.palette.error.main,
          borderColor: defaultMuiTheme.palette.error.main,
        },
      },
      MuiExpansionPanelSummary: {
        expandIcon: {
          margin: 0,
          padding: 0,
        },
      },
      MuiRadio: {
        colorSecondary: {
          '&$checked': {
            color: 'rgb(24, 144, 255)',
            '&:hover': {
              backgroundColor: 'rgba(0,0,0,0.04)',
            },
          },
        },
      },
      MuiFormControlLabel: {
        root: {
          marginLeft: 0,
        },
        labelPlacementStart: {
          marginLeft: 0,
          width: 'fit-content',
          justifyContent: 'flex-end',
        },
      },
      MuiCheckbox: {
        colorSecondary: {
          '&$checked': {
            color: 'rgb(24, 144, 255)',
            '&:hover': {
              backgroundColor: 'rgba(0,0,0,0.04)',
            },
          },
        },
        colorPrimary: {
          '&$checked': {
            color: 'rgb(24, 144, 255)',
          },
        },
      },
      MuiInputBase: {
        inputMarginDense: {
          paddingTop: 8,
          paddingBottom: 5,
        },
      },
      MuiSelect: {
        select: {
          '&:focus': {
            backgroundColor: 'rgba(0,0,0,0)',
          },
        },
      },
      MuiOutlinedInput: {
        notchedOutline: {
          ...primaryBorder,
        },
      },
      MuiSwitch: {
        colorSecondary: {
          '&$checked': {
            color: palette.themeColor,
          },
          '&$checked + $track': {
            backgroundColor: palette.themeColor,
          },
        },
      },
      MuiLinearProgress: {
        colorPrimary: {
          backgroundColor: palette.themeColorLight,
        },
        barColorPrimary: {
          backgroundColor: palette.themeColor,
        },
      },
      MuiCardActions: {
        spacing: {
          '&> :not(:first-child)': {
            marginLeft: 0,
          },
        },
      },
      MuiDialogContentText: {
        root: {
          lineHeight: 1.5,
        },
      },
      MuiListSubheader: {
        root: {
          '&.GroupedSelectHeading': {
            display: 'flex',
            alignItems: 'center',
          },
          '& .GroupedSelectHeadingTitle': {
            marginRight: 10,
          },
          '& .GroupedSelectHeadingChip': {
            marginLeft: 'auto',
          },
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: palette.greyLight1,
          boxShadow: BOX_SHADOW_CONFIGS.default,
          padding: '10px',
          color: standardFontColor,
          fontSize: 12,
        },
        popper: {
          zIndex: Z_INDEX_CONFIGS.tooltip,
        },
        arrow: {
          color: palette.greyLight1,
        },
      },
      RaCardContentInner: {
        root: {
          '&.nolist': {
            padding: 16,
          },
        },
      },
    },
  }
}

export type OpenSolarThemeType = MUITheme &
  typeof OpenSolarPalette & {
    ValidityPalette: typeof ValidityPalette
    straitPalette: typeof COLOR_PALETTE
    boxShadow: {
      default: string
      hoverSpecial: string
    }

    border: {
      default: string
    }

    typography: {
      htmlFontSize: number
      fontFamily: string
      fontSize: number
      fontWeightLight: number
      fontWeightRegular: number
      fontWeightMedium: number
      fontWeightBold: number
      h1: Partial<CSSProperties>
      h2: Partial<CSSProperties>
      h3: Partial<CSSProperties>
      h4: Partial<CSSProperties>
      h5: Partial<CSSProperties>
      h6: Partial<CSSProperties>
      subtitle1: Partial<CSSProperties>
      subtitle2: Partial<CSSProperties>
      body1: Partial<CSSProperties>
      body2: Partial<CSSProperties>
      button: Partial<CSSProperties>
      caption: Partial<CSSProperties>
      overline: Partial<CSSProperties>
      alignLeft: Partial<CSSProperties>
      alignCenter: Partial<CSSProperties>
      alignRight: Partial<CSSProperties>
      alignJustify: Partial<CSSProperties>
    }
    shape: {
      borderRadius: number
    }
    transitions: {
      easing: {
        easeInOut: string
        easeOut: string
        easeIn: string
        sharp: string
      }
      duration: {
        shortest: number
        shorter: number
        short: number
        standard: number
        complex: number
        enteringScreen: number
        leavingScreen: number
      }
    }
    zIndex: {
      mobileStepper: number
      speedDial: number
      appBar: number
      drawer: number
      modal: number
      snackbar: number
      tooltip: number
      0
    }

    fontFamily: string
    standardFontColor: string
    helpIconColor: string
    blue: string
    alert_error: string
    alertBg_error: string
    alertIcon_error: string
    alert_warning: string
    alertBg_warning: string
    alertIcon_warning: string
    alert_info: string
    alertBg_info: string
    alertIcon_info: string
    alert_success: string
    alertBg_success: string
    alertIcon_success: string
  }

export const OpenSolarTheme = getTheme(OpenSolarPalette)
export const NearmapTheme = getTheme(NearmapPalette)

import { ClickAwayListener, Menu, MenuItem, TextField } from '@material-ui/core'
import { ArrowDropDown, ArrowRight } from '@material-ui/icons'
import { featureConfigSelectors } from 'ducks/featureConfig'
import React, { ChangeEvent, MouseEvent, useEffect, useState } from 'react'
import { useDebouncedCallback, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { RootState } from 'types/state'
import { PlaceholderWhiteListType } from './ProjectPlaceholderWizard'
import { FieldType, FormatType, MathOperationType, PlaceHolderSaveConfig, PlaceholderType } from './TemplateWizard'
import { fieldPathToDisplayMap, getDocusignLabelFromTag, pathToDjangoMap } from './constants'
import { usePlaceholderTagStyles } from './templatePlaceholderTag/placeholderTagStyles'

type PropTypes = {
  x: number
  y: number
  field_path: string
  width: number
  font_size: number
  lines: number
  value: string
  field_key: string
  use_int_comma: boolean
  format_as_currency: boolean
  round_digits: undefined | number
  default_value: undefined | string
  math_operations: MathOperationType[]
  date_format_rule: undefined | FormatType
  removePlaceholder: (key: string) => void
  savePlaceholderChange: (key: string, path: string, config: PlaceHolderSaveConfig) => void
  aCellIsBeingDragged: boolean
  onDrag: () => void
  onDragEnd: () => void
  fieldBeingDragged: FieldType | undefined
  onFocus: (placeholders: PlaceholderType[]) => void
  focusedPlaceholders: PlaceholderType[]
  zoom: number
  declutter: boolean
  formatLastClosed: Date | undefined
  is_checkbox: boolean
  height?: number
  page_num: number
}

const MAX_WIDTH = 500
const MAX_HEIGHT = 500

const ImagePlaceholderTag: React.FunctionComponent<PropTypes> = (props) => {
  const whitelistVals: PlaceholderWhiteListType | undefined = useSelector((state: RootState) =>
    featureConfigSelectors.getFeatureConfigData(state, 'placeholder_whitelist')
  )

  const getFormattedFieldTitle = () => {
    if (!props.field_path?.startsWith('popular.') && props.field_path?.includes('DJANGO_TAGS'))
      return 'Custom Django Tag'
    if (isDocusign) return getDocusignLabelFromTag(props.value)
    let pathToDisplayWithWhitelist = { ...fieldPathToDisplayMap }
    if (whitelistVals && whitelistVals.path_to_display) {
      pathToDisplayWithWhitelist = {
        ...pathToDisplayWithWhitelist,
        ...whitelistVals.path_to_display,
      }
    }
    if (pathToDisplayWithWhitelist[props.field_path]) return pathToDisplayWithWhitelist[props.field_path]
    let raw = props.field_path.split('.')[props.field_path.split('.').length - 1]
    let asWords = raw.replaceAll('_', ' ').split(' ')
    for (let i = 0, len = asWords.length; i < len; i++) {
      let word = asWords[i]
      word = word.replace(word.charAt(0), word.charAt(0).toUpperCase())
      asWords[i] = word
    }
    let label = asWords.join(' ')
    if (label?.includes('DJANGO TAGS ')) label = label.replace('DJANGO TAGS ', '')
    return label
  }

  const [x, setX] = useState<number>(props.x)
  const [y, setY] = useState<number>(props.y)
  const [dragStartX, setDragStartX] = useState<number | undefined>(undefined)
  const [dragStartY, setDragStartY] = useState<number | undefined>(undefined)
  const [showFormat, setShowFormat] = useState<boolean>(false)
  const [isDocusign, setIsDocusign] = useState<boolean>(props.field_path?.includes('docusign.'))
  const [fontSize, setFontSize] = useState<number>(props.font_size || 9)
  const [width, setWidth] = useState<number>(props.width || 200)
  const [height, setHeight] = useState<number>(props.height || 200)
  const [defaultValue, setDefaultValue] = useState<string | undefined>(props.default_value)
  const [anchorEl, setAnchorEl] = useState(null)
  const [title, setTitle] = useState<string>(getFormattedFieldTitle())
  const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const [tempWidth, setTempWidth] = useState<number>(width)
  const [tempHeight, setTempHeight] = useState<number>(height)

  const classes = usePlaceholderTagStyles({
    x,
    y,
    zoom: props.zoom,
    declutter: props.declutter,
    aCellIsBeingDragged: props.aCellIsBeingDragged,
    isFocused,
    showFormat,
    width,
    height,
    fontSize: props.font_size,
    isImage: true,
  })

  const doDebouncedDimensionsChange = useDebouncedCallback((newWidthVal: number, newHeightVal: number) => {
    setWidth(Math.max(20, newWidthVal))
    setHeight(Math.max(20, newHeightVal))
  }, 500)

  useEffect(() => {
    doDebouncedDimensionsChange(tempWidth, tempHeight)
  }, [tempHeight, tempWidth])

  useEffect(() => {
    if (props.formatLastClosed) {
      if (showFormat) setShowFormat(false)
    }
  }, [props.formatLastClosed])

  useEffect(() => {
    if (props.focusedPlaceholders?.length > 0) {
      props.focusedPlaceholders?.forEach((placeholder) => {
        if (!isFocused && placeholder.field_key === props.field_key) setIsFocused(true)
        else if (isFocused) setIsFocused(false)
      })
    }
  }, [props.focusedPlaceholders])

  const translate = useTranslate()

  useEffect(() => {
    setTitle(getFormattedFieldTitle())
  }, [props.field_path])

  useEffect(() => {
    if (props.field_path?.includes('DJANGO_TAGS') && !defaultValue) {
      let mergedMap = { ...pathToDjangoMap, ...whitelistVals?.path_to_django }
      if (mergedMap[props.field_path]) {
        setDefaultValue(mergedMap[props.field_path])
      }
    }
  }, [props.field_path, defaultValue])

  useEffect(() => {
    if (isInitialized && props.font_size && props.font_size !== fontSize) {
      setFontSize(props.font_size)
    }
  }, [props.font_size, isInitialized])

  useEffect(() => {
    if (isInitialized) {
      let config = {
        x,
        y,
        width,
        height,
        font_size: 9,
        use_int_comma: false,
        format_as_currency: false,
        default_value: '',
        lines: 1,
        round_digits: undefined,
        math_operations: [],
        date_format_rule: undefined,
        is_checkbox: false,
      }
      props.savePlaceholderChange(props.field_key, props.field_path, config)
    } else {
      setIsInitialized(true)
    }
  }, [width, height, isInitialized, x, y])

  const menuOpen = Boolean(anchorEl)

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const doDelete = () => {
    props.removePlaceholder(props.field_key)
  }

  const dragStart = (e) => {
    setDragStartX(e.clientX)
    setDragStartY(e.clientY)
    props.onDrag()
  }

  const dragEnd = (e) => {
    const midDragX = e.clientX
    const midDragY = e.clientY
    if (dragStartX === undefined || dragStartY === undefined) return
    if (midDragX < 0 || midDragY < 0) {
      doDelete()
    } else {
      if (midDragX) {
        let relativeX = dragStartX - midDragX
        setX(x - relativeX)
      }
      if (midDragY) {
        let relativeY = dragStartY - midDragY
        setY(y - relativeY)
      }
    }
    setDragStartX(undefined)
    setDragStartY(undefined)
    props.onDragEnd()
  }

  const toggleFormat = () => {
    setShowFormat(!showFormat)
    handleMenuClose()
  }

  const changeTempWidth = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (isNaN(parseInt(e.target.value))) setTempWidth(0)
    else setTempWidth(Math.min(MAX_WIDTH, parseInt(e.target.value)))
  }

  const changeTempHeight = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (isNaN(parseInt(e.target.value))) setTempHeight(0)
    else setTempHeight(Math.min(MAX_HEIGHT, parseInt(e.target.value)))
  }

  const hideFormat = () => {
    setShowFormat(false)
  }

  const elevateOnHover = () => setIsFocused(true)
  const sinkOnBlur = () => setIsFocused(false)

  const renderFormat = () => {
    return (
      <div className={classes.formatWrapperImage}>
        <div className={classes.dimensionsInputWrapper}>
          <strong>Width (pixels):</strong>
          <TextField value={tempWidth} onChange={changeTempWidth} />
        </div>
        <div className={classes.dimensionsInputWrapper}>
          <strong>Height (pixels):</strong>
          <TextField value={tempHeight} onChange={changeTempHeight} />
        </div>
      </div>
    )
  }

  const onFocus = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    props.onFocus([props])
  }

  return (
    <ClickAwayListener onClickAway={hideFormat}>
      <div
        style={{}}
        className={classes.mainWrapper}
        onMouseEnter={elevateOnHover}
        onMouseLeave={sinkOnBlur}
        onClick={onFocus}
      >
        <span className={classes.title}>{title}</span>
        <div className={classes.contentWrapper}>
          <div className={classes.dragTarget} draggable={true} onDragStart={dragStart} onDragEnd={dragEnd}>
            <div className={classes.valueWrapper}>
              <span>
                {translate(
                  'Image previews are unavailable while editing a template. Please generate the document to view the image'
                )}
              </span>
            </div>
            <div className={classes.dimensionsDiv}></div>
            {!props.declutter && (
              <>
                <div onClick={handleMenuClick} className={classes.dropdownWrapper}>
                  {menuOpen ? <ArrowRight /> : <ArrowDropDown />}
                </div>
                <Menu
                  id="demo-simple-select-autowidth"
                  style={{ marginLeft: '20px' }}
                  open={menuOpen}
                  onClose={handleMenuClose}
                  anchorEl={anchorEl}
                >
                  {(!isDocusign || props.field_path?.includes('docusign.text')) && (
                    <MenuItem onClick={toggleFormat}>Format</MenuItem>
                  )}
                  <MenuItem onClick={doDelete} style={{ color: 'red' }}>
                    Delete
                  </MenuItem>
                </Menu>
              </>
            )}
          </div>
          {showFormat && renderFormat()}
        </div>
      </div>
    </ClickAwayListener>
  )
}
export default React.memo(ImagePlaceholderTag)

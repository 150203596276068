import { CustomRolePermissionsType } from 'types/customRole'

export const DefaultPredefinedAccessRights = {
  control: {
    business_info: {
      disabled: ['create', 'delete'],
      label: 'Business Info',
      resource: 'content',
    },
    team: {
      disabled: [],
      label: 'Team',
      resource: 'roles',
    },
    business_process: {
      disabled: [],
      label: 'Business process',
      resource: 'business_process',
    },
    // custom_roles: {
    //   disabled: [],
    //   label: 'Custom Roles',
    //   resource: 'permissions_role',
    // },
    settings: {
      disabled: ['create', 'delete'],
      label: 'Settings',
      resource: 'orgs',
    },
    pricing: {
      disabled: [],
      label: 'Pricing',
      resource: 'pricing_schemes',
    },
    payment_options: {
      disabled: [],
      label: 'Payment Options',
      resource: 'payment_options',
    },
    cost_information: {
      disabled: [],
      label: 'Costing Information',
      resource: 'costings',
    },
    commissions: {
      disabled: [],
      label: 'Commissions',
      resource: 'commissions',
    },
    components: {
      disabled: [],
      label: 'Hardware Components',
      resource: 'components',
    },
    component_pricing_costing: {
      disabled: [],
      label: 'Hardware Components Pricing',
      resource: 'component_pricing_costing',
    },
    setbacks_and_design_settings: {
      disabled: [],
      label: 'Setbacks & Design Settings',
      resource: 'project_configurations',
    },
    battery_control_schemes: {
      disabled: [],
      label: 'Battery Control Schemes',
      resource: 'battery_schemes',
    },
    proposal_template: {
      disabled: [],
      label: 'Proposal Templates',
      resource: 'proposal_templates',
    },
    contract_template: {
      disabled: [],
      label: 'Contracts',
      resource: 'contracts',
    },
    checkout_exp: {
      disabled: ['create', 'delete'],
      label: 'Checkout Experience',
      resource: 'customer_checkout',
    },
    case_studies: {
      disabled: [],
      label: 'Case Studies',
      resource: 'testimonials',
    },
    contacts: {
      disabled: [],
      label: 'Contacts',
      resource: 'contacts',
    },
    transactions: {
      disabled: [],
      label: 'Transactions',
      resource: 'transactions',
    },
    files: {
      disabled: [],
      label: 'Files',
      resource: 'private_files',
    },
    activities: {
      disabled: [],
      label: 'Activities',
      resource: 'events',
    },
    utility_tariffs: {
      disabled: [],
      label: 'Utility Tariffs',
      resource: 'utility_tariffs',
    },
    incentives: {
      disabled: [],
      label: 'Incentives',
      resource: 'incentives',
    },
    integrations_and_api_keys: {
      disabled: ['create', 'delete'],
      label: 'Integrations & API Keys',
      resource: 'external_api_keys',
    },
    document_templates: {
      disabled: [],
      label: 'Document Templates',
      resource: 'document_templates',
    },
    lead_capture_forms: {
      disabled: [],
      label: 'Lead Capture',
      resource: 'lead_capture_forms',
    },
    sales_territories: {
      disabled: [],
      label: 'Sales Territories',
      resource: 'sales_territories',
    },
    public_files: {
      disabled: [],
      label: 'Public Files',
      resource: 'public_files',
    },
    // components_import_export: {
    //   disabled: ['create', 'delete', 'edit'],
    //   label: 'Components > import/export',
    //   resource: 'components_import_export',
    // },
    // pricing_import_export: {
    //   disabled: ['create', 'delete', 'edit'],
    //   label: 'Pricing > Import/export',
    //   resource: 'pricing_import_export',
    // },
    // cost_information_import_export: {
    //   disabled: ['create', 'delete', 'edit'],
    //   label: 'Cost information > import/export',
    //   resource: 'cost_information_import_export',
    // },
    // commissions: {
    //   disabled: [],
    //   label: 'Commissions',
    //   resource: 'commissions',
    // },
    adders: {
      disabled: [],
      label: 'Adders',
      resource: 'adders',
    },
    grant_access_to_projects_from_these_users: {
      disabled: ['create', 'delete'],
      label: 'Grant Access To Projects From These Users',
      resource: 'grant_access_to_projects_from_these_users',
    },
    distributor_pricing: {
      disabled: ['create', 'edit', 'delete'],
      label: 'Distributor Pricing',
      resource: 'distributor_pricing',
    },
    cashflow_onboarding: {
      disabled: [],
      label: 'Cashflow Onboarding',
      resource: 'cashflow_onboarding',
    },
    cashflow_configuration: {
      disabled: [],
      label: 'Cashflow Configuration',
      resource: 'cashflow_configuration',
    },
    cashflow_nav_projects: {
      disabled: [],
      label: 'Cashflow Top Navigation: Projects',
      resource: 'cashflow_nav_projects',
    },
    cashflow_nav_invoices: {
      disabled: [],
      label: 'Cashflow Top Navigation: Invoices',
      resource: 'cashflow_nav_invoices',
    },
    cashflow_nav_configure: {
      disabled: [],
      label: 'Cashflow Top Navigation: Configure',
      resource: 'cashflow_nav_configure',
    },
  },
  project: {
    project: {
      disabled: ['view', 'edit', 'delete'],
      label: 'Projects',
      resource: 'projects',
    },
    purchases_for_projects: {
      disabled: [],
      label: 'Projects - Purchases',
      resource: 'purchases_for_projects',
    },
    manage: {
      disabled: ['create', 'delete'],
      label: 'Manage',
      resource: 'manage',
    },
    manage_notes_activities_actions: {
      disabled: [],
      label: 'Manage - Notes/activities/actions',
      resource: 'manage_notes_activities_actions',
    },
    manage_workflow_stages: {
      disabled: [],
      label: 'Manage - Project Workflow/Stages',
      resource: 'manage_workflow_stages',
    },
    manage_assigned_users: {
      disabled: [],
      label: 'Manage - Assigned users',
      resource: 'manage_assigned_users',
    },
    info_contact_info_basic: {
      disabled: [],
      label: 'Info - Contact Info Basic (Name, phone, email)',
      label_ux3: 'Manage - Contact Info Basic (Name, phone, email)',
      resource: 'info_contact_info_basic',
    },
    info_contact_info_full: {
      disabled: [],
      label: 'Info - Contact Info Full (rest)',
      label_ux3: 'Manage - Contact Info Full (rest)',
      resource: 'info_contact_info_full',
    },
    info_sales_and_marketing: {
      disabled: ['create', 'delete'],
      label: 'Info - Sales and Marketing',
      label_ux3: 'Manage - Sales and Marketing',
      resource: 'info_sales_and_marketing',
    },
    info_system_summary_section_pricing: {
      disabled: ['create', 'edit', 'delete'],
      label: 'Info - System Summary section pricing',
      label_ux3: 'Manage - System Summary section pricing',
      resource: 'info_system_summary_section_pricing',
    },
    info_sale: {
      disabled: ['create', 'delete'],
      label: 'Info - Sale',
      label_ux3: 'Manage - Sale',
      resource: 'info_sale',
    },
    info_installation_info: {
      disabled: ['create', 'delete'],
      label: 'Info - Installation info',
      label_ux3: 'Manage - Installation info',
      resource: 'info_installation_info',
    },
    info_transactions: {
      disabled: [],
      label: 'Info - Transactions',
      label_ux3: 'Manage - Transactions',
      resource: 'info_transactions',
    },
    info_documents: {
      disabled: [],
      label: 'Info - Documents',
      label_ux3: 'Manage - Documents',
      resource: 'info_documents',
    },
    info_sharing: {
      disabled: ['create', 'edit', 'delete'],
      sync_disabled_to: 'view',
      label: 'Info - Sharing',
      label_ux3: 'Manage - Sharing',
      resource: 'info_sharing',
    },
    energy_usage_tariff: {
      disabled: [],
      label: 'Energy - Usage',
      resource: 'energy_usage_tariff',
    },
    energy_tariff: {
      disabled: ['delete'],
      label: 'Energy - Tariff',
      resource: 'energy_tariff',
    },
    design: {
      disabled: ['create', 'delete'],
      label: 'Design',
      resource: 'design',
    },
    design_panels_build: {
      disabled: ['view', 'create', 'delete'],
      label: 'Design - Buildable Panels',
      resource: 'design_panels_build',
    },
    design_pricing: {
      disabled: ['create', 'delete'],
      label: 'Design - Pricing',
      resource: 'design_pricing',
    },
    design_commission_override: {
      disabled: ['create', 'delete'],
      label: 'Design - Commission',
      resource: 'design_commission_override',
    },
    design_payment_options: {
      disabled: ['create', 'delete'],
      label: 'Design - Payment options',
      resource: 'design_payment_options',
    },
    design_incentives: {
      disabled: [],
      label: 'Design - Incentives',
      resource: 'design_incentives',
    },
    design_price_adders: {
      disabled: [],
      label: 'Design - Adders & Discounts',
      resource: 'design_price_adders',
    },
    design_cost_breakdown: {
      disabled: ['create', 'edit', 'delete'],
      label: 'Design - Cost Breakdown',
      resource: 'design_cost_breakdown',
    },
    design_costing_override: {
      disabled: ['delete', 'create'],
      label: 'Design - Costing override',
      resource: 'design_costing_override',
    },
    design_tax_override: {
      disabled: ['delete', 'create'],
      label: 'Design - Tax override',
      resource: 'design_tax_override',
    },
    proposal: {
      disabled: ['create', 'delete'],
      label: 'Proposal',
      resource: 'proposal',
    },
    sld: {
      disabled: ['view', 'edit', 'delete'],
      label: 'SLD',
      resource: 'sld',
    },
    invoice_actions_send: {
      disabled: [],
      label: 'Invoice Actions: Send',
      resource: 'invoice_actions_send',
    },
    invoice_actions_send_reminder: {
      disabled: [],
      label: 'Invoice Actions: Send Reminders',
      resource: 'invoice_actions_send_reminder',
    },
    invoice_actions_cancel: {
      disabled: [],
      label: 'Invoice Actions: Cancel',
      resource: 'invoice_actions_cancel',
    },
    invoice_actions_mark_paid_offline: {
      disabled: [],
      label: 'Invoice Actions: Mark Paid Offline',
      resource: 'invoice_actions_mark_paid_offline',
    },
    invoice_actions_preview: {
      disabled: [],
      label: 'Invoice Actions: Preview',
      resource: 'invoice_actions_preview',
    },
    invoice_actions_custom_invoices: {
      disabled: [],
      label: 'Invoice Actions: Custom Invoices',
      resource: 'invoice_actions_custom_invoices',
    },
    invoice_data_downloads: {
      disabled: [],
      label: 'Invoice Downloads',
      resource: 'invoice_data_downloads',
    },
    invoice_edit_details: {
      disabled: [],
      label: 'Invoice Edit Details',
      resource: 'invoice_edit_details',
    },
    invoice_adjust_payments: {
      disabled: [],
      label: 'Invoice Adjust Payments',
      resource: 'invoice_adjust_payments',
    },
    invoice_adjust_prices: {
      disabled: [],
      label: 'Invoice Adjust Prices',
      resource: 'invoice_adjust_prices',
    },
    project_payments: {
      disabled: [],
      label: 'Payments Tab',
      resource: 'project_payments',
    },
    payments_projects_presale: {
      disabled: [],
      label: 'Access Payments Presales',
      resource: 'payments_projects_presale',
    },
    payments_projects_postsale: {
      disabled: [],
      label: 'Access Payments Postsales',
      resource: 'payments_projects_presale',
    },
  },
}

const getLabel = (key: string, settings: any, isUx3: boolean) => {
  if (settings[key] && settings[key].label) {
    if (isUx3 && settings[key].label_ux3) {
      return settings[key].label_ux3
    }
    return settings[key].label
  }
  return key.replaceAll('_', ' ').toUpperCase()
}

const mergePermissionsRoleToPredefinedAccessRights = (defaultSetting: any, baseSetting: any, isUx3: boolean) => {
  const mergedResult = {}
  for (const key in baseSetting) {
    if (key === 'design_discounts') {
      continue
    }
    mergedResult[key] = {
      disabled: ['create', 'delete', 'edit', 'view'].filter((right) => !baseSetting[key][right]),
      sync_disabled_to:
        defaultSetting[key] && defaultSetting[key].sync_disabled_to ? defaultSetting[key].sync_disabled_to : false,
      label: getLabel(key, defaultSetting, isUx3),
      resource: defaultSetting[key] && defaultSetting[key].resource ? defaultSetting[key].resource : key,
    }
  }
  return mergedResult
}

export const getPredefinedAccessRights = (baseSettings?: CustomRolePermissionsType, isUx3: boolean = false) => {
  //generate predefined access rights base on latest admin settings
  if (!baseSettings || !baseSettings.control || !baseSettings.project) {
    //invalid baseSettings return default config
    return DefaultPredefinedAccessRights
  } else {
    const predefinedAccessRights = {
      control: mergePermissionsRoleToPredefinedAccessRights(
        DefaultPredefinedAccessRights.control,
        baseSettings.control,
        isUx3
      ),
      project: mergePermissionsRoleToPredefinedAccessRights(
        DefaultPredefinedAccessRights.project,
        baseSettings.project,
        isUx3
      ),
    }
    return predefinedAccessRights
  }
}

export const getResourceToAccessKeyMap = (predefinedAccessRights: any = DefaultPredefinedAccessRights) => {
  const mapTable = {}
  for (const key in predefinedAccessRights.control) {
    const resource = predefinedAccessRights.control[key].resource
    if (resource) {
      mapTable[resource] = key
    }
  }
  for (const key in predefinedAccessRights.project) {
    const resource = predefinedAccessRights.project[key].resource
    if (resource) {
      mapTable[resource] = key
    }
  }
  return mapTable
}

export const DefaultResourceToAccessKeyMap = getResourceToAccessKeyMap(DefaultPredefinedAccessRights)

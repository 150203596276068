import { useEffect, useState } from 'react'
import restClient from 'restClient'
import { InvoicesSummaryType } from './types'

const restClientInstance = restClient(window.API_ROOT + '/api')

export const useGetInvoicesSummary = (orgId: number | undefined) => {
  const [invoicesSummary, setInvoicesSummary] = useState<InvoicesSummaryType>()
  const [invoiceSettingsLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (orgId) {
      setIsLoading(true)
      restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${orgId}/cashflow_invoices/summary/`,
      })
        .then((res) => {
          setInvoicesSummary(res.data)
        })
        .catch((err) => {
          console.log('err', err)
        })
        .finally(() => setIsLoading(false))
    }
  }, [orgId])

  return { invoicesSummary, invoiceSettingsLoading }
}

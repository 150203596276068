var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export var NotificationBoxOutline = function (_a) {
    var _b = _a.size, size = _b === void 0 ? '1rem' : _b, _c = _a.color, color = _c === void 0 ? 'currentColor' : _c, rest = __rest(_a, ["size", "color"]);
    var iconAttributes = {
        width: size,
        height: size,
        stroke: color,
    };
    return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20", fill: "none" }, rest, iconAttributes, { children: _jsx("path", { d: "M8.875 1H4.375C2.51104 1 1 2.51103 1 4.37498V15.625C1 17.489 2.51104 19 4.375 19H15.625C17.489 19 19 17.489 19 15.625V10.5624M19 3.81248C19 5.36578 17.7408 6.62497 16.1875 6.62497C14.6342 6.62497 13.375 5.36578 13.375 3.81248C13.375 2.25919 14.6342 1 16.1875 1C17.7408 1 19 2.25919 19 3.81248Z", stroke: color, strokeWidth: "2", strokeLinecap: "round" }) })));
};

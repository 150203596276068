import { List } from 'elements/react-admin/List'
import React from 'react'
import {
  AutocompleteInput,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  FunctionField,
  ReferenceInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin'

export const inputStyle = {
  width: 256,
}

export const LandingPageList = (props) => (
  <List hasSearch={true} {...props}>
    <Datagrid>
      <TextField source="url_fragment" />
      <FunctionField
        source={null}
        label="Actions"
        render={(record, source, rest) => (
          <div>
            <EditButton useListStyle={true} record={record} basePath={rest.basePath} />
            <DeleteButton
              useListStyle={true}
              undoable={false}
              record={record}
              resource={rest.resource}
              redirect={`/${rest.resource}`}
            />
          </div>
        )}
        sortable={false}
      />
    </Datagrid>
  </List>
)

export const LandingPageEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="url_fragment" />

      <ReferenceInput
        name="org"
        source="org"
        reference="orgs"
        optionValueField="url"
        sort={{ field: 'name', order: 'ASC' }}
        allowEmpty
        {...props}
      >
        <AutocompleteInput
          optionText="name"
          optionValue="url"
          options={{
            style: inputStyle,
            containerStyle: inputStyle,
          }}
        />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
)

export const LandingPageCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="url_fragment" />
      <ReferenceInput
        name="org"
        source="org"
        reference="orgs"
        optionValueField="url"
        sort={{ field: 'name', order: 'ASC' }}
        allowEmpty
        {...props}
      >
        <AutocompleteInput
          optionText="name"
          optionValue="url"
          options={{
            style: inputStyle,
            containerStyle: inputStyle,
          }}
        />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)

import React from 'react'
import { useTranslate } from 'react-admin'

const formatEnvironmentals = (value, lang = undefined) => {
  if (typeof lang === 'string') {
    // change en_US to en-US for Number.prototype.toLocaleString
    lang = lang.replace('_', '-')
  }
  return value.toLocaleString(lang, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
}

const formatWithOneDecimalPlace = (value, lang = undefined) => {
  if (typeof lang === 'string') {
    // change en_US to en-US for Number.prototype.toLocaleString
    lang = lang.replace('_', '-')
  }
  return value.toLocaleString(lang, {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  })
}

const EnvironmentalBenefits = (props) => {
  const translate = useTranslate()
  const { language } = props.proposalData.selectedProject
  return (
    <div>
      <h1 className="mye-section-title">
        {props.proposalData.proposalTemplateSettings.heading_environmental_benefits}
      </h1>
      <p style={{ textAlign: 'center', fontSize: 12, marginTop: 0 }}>
        {translate('Solar has no emissions. It just silently generates pure, clean energy.')}
      </p>
      <img
        src={window.PUBLIC_URL + '/images/myenergy/environmental-benefits-tree.jpg'}
        style={{
          width: '100%',
          height: '100px',
          marginTop: 20,
          marginBottom: 20,
          borderRadius: 10,
          objectFit: 'cover',
        }}
      />
      <div style={{ display: props.layout <= 2 ? '' : 'flex', justifyContent: 'space-between' }}>
        <div
          style={{
            width: props.layout <= 2 ? '100%' : '35%',
            fontSize: 12,
            textAlign: 'center',
            border: '1px solid #efefef',
            padding: '5px 0',
            borderRadius: 3,
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <p style={{ fontSize: 12, margin: '0 0 3px 0' }}>{translate('Each Year')}</p>
          </div>
          <div style={{ display: 'flex' }}>
            <div
              style={{
                flex: '1',
                padding: '0px 10px 0px 10px',
              }}
            >
              <div className={props.layout === 1 ? 'smallFF' : ''}>
                <div className={props.layout <= 2 ? 'feature-figure-medium' : 'feature-figure'}>
                  {formatEnvironmentals(props.proposalData.solarOutputAsPercentage, language)}
                  <span style={{ fontSize: 18 }}>%</span>
                </div>
                <div>{translate('Of CO₂, SOₓ & NOₓ')}</div>
              </div>
            </div>
            <div
              style={{
                flex: '1',
                padding: '0px 10px 0px 10px',
              }}
            >
              <div className={props.layout === 1 ? 'smallFF' : ''}>
                {props.proposalData.selectedSystem.environmentals.co2_tons < 1 ? (
                  <div className={props.layout <= 2 ? 'feature-figure-medium' : 'feature-figure'}>
                    {formatEnvironmentals(props.proposalData.selectedSystem.environmentals.co2_tons * 1000.0, language)}
                    <span style={{ fontSize: 18 }}>{translate('kg')}</span>
                  </div>
                ) : (
                  <div className={props.layout <= 2 ? 'feature-figure-medium' : 'feature-figure'}>
                    {formatWithOneDecimalPlace(props.proposalData.selectedSystem.environmentals.co2_tons, language)}
                    <span style={{ fontSize: 18 }}>{translate('tons')}</span>
                  </div>
                )}
                <div>{translate('Avoided CO₂ per year')}</div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            width: props.layout <= 2 ? '100%' : '60%',
            fontSize: 12,
            textAlign: 'center',
            border: '1px solid #efefef',
            padding: '5px 0',
            borderRadius: 3,
            marginTop: props.layout <= 2 ? 10 : 0,
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <p style={{ fontSize: 12, margin: '0 0 3px 0' }}>{translate('Over System Lifetime')}</p>
          </div>
          <div style={{ display: 'flex' }}>
            {props.proposalData.selectedSystem.environmentals.car_km > 0 && (
              <div
                style={{
                  flex: '1',
                  padding: '0px 10px 0px 10px',
                }}
              >
                <div className={props.layout === 1 ? 'smallFF' : ''}>
                  <div className={props.layout <= 2 ? 'feature-figure-medium' : 'feature-figure'}>
                    {props.proposalData.selectedSystem.site.country === 'US'
                      ? formatEnvironmentals(
                          props.proposalData.selectedSystem.environmentals.car_km * 0.62137,
                          language
                        )
                      : formatEnvironmentals(props.proposalData.selectedSystem.environmentals.car_km, language)}
                  </div>
                  <div>
                    {props.proposalData.selectedSystem.site.country === 'US'
                      ? translate('Car miles avoided')
                      : translate('Car km avoided')}
                  </div>
                </div>
              </div>
            )}
            {props.proposalData.selectedSystem.environmentals.trees_planted > 0 && (
              <div
                style={{
                  flex: '1',
                  padding: '0px 10px 0px 10px',
                }}
              >
                <div className={props.layout === 1 ? 'smallFF' : ''}>
                  <div className={props.layout <= 2 ? 'feature-figure-medium' : 'feature-figure'}>
                    {formatEnvironmentals(props.proposalData.selectedSystem.environmentals.trees_planted, language)}
                  </div>
                  <div>{translate('Trees planted')}</div>
                </div>
              </div>
            )}
            {props.proposalData.selectedSystem.environmentals.flights > 0 && (
              <div
                style={{
                  flex: '1',
                  padding: '0px 10px 0px 10px',
                }}
              >
                <div className={props.layout === 1 ? 'smallFF' : ''}>
                  <div className={props.layout <= 2 ? 'feature-figure-medium' : 'feature-figure'}>
                    {formatEnvironmentals(props.proposalData.selectedSystem.environmentals.flights, language)}
                  </div>
                  <div>{translate('Long haul flights avoided')}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EnvironmentalBenefits

// @ts-nocheck
import CustomField from 'elements/field/CustomField'
import React from 'react'
import { TextInput, useTranslate } from 'react-admin'
import { FundsFormModeType } from './EditWalletPage'

type PropTypes = {
  switchMode: (newMode: FundsFormModeType) => void
}

const AddFunds: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  return (
    <>
      <h1>{translate('Add funds to your account')}</h1>
      <p>
        {translate(
          'Set the amount you’d like to add to your Wallet in the field below. Alternatively we recommend that you enable auto top-up so your team can access Premium content uninterrupted.'
        )}
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'underline',
            color: 'rgb(63, 147, 237)',
            marginLeft: '5px',
          }}
          onClick={props.switchMode}
        >
          {translate('Setup Auto Top-Up')}
        </span>
      </p>
      <div>
        <CustomField
          style={{ minWidth: '400px', marginBottom: '0px' }}
          name="funds_to_add"
          label="Amount"
          component={TextInput}
          initialValue={0}
          type="number"
        />
      </div>
    </>
  )
}

export default AddFunds

import type { CardConfigType, CategoryConfigType } from '../type'

const cachedMatchResults = {}

// Doesn't really need to cache the results...
const dfsDetectMatchedPath = (card: CardConfigType, pathname: string) => {
  const cacheKey = card.url + pathname
  if (cachedMatchResults.hasOwnProperty(cacheKey)) {
    return cachedMatchResults[cacheKey]
  }

  cachedMatchResults[cacheKey] = card.url === pathname
  if (cachedMatchResults[cacheKey]) {
    return true
  }

  const childCards = card.cards || []
  for (let i = 0; i < childCards.length; i++) {
    if (dfsDetectMatchedPath(childCards[i], pathname)) {
      cachedMatchResults[cacheKey] = true
      return true
    }
  }
}

const getDefaultExpanded = ({
  config,
  pathname,
}: {
  config: CardConfigType | CategoryConfigType
  pathname: string
}): boolean => {
  if (pathname === '/control') return false // early break
  const defaultExpanded = !!config.cards?.some((card: CardConfigType) => {
    return dfsDetectMatchedPath(card, pathname)
  })
  return defaultExpanded
}

export default getDefaultExpanded

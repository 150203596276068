import { useCallback } from 'react'
import { useDataProvider } from 'react-admin'
import { RecordedLineItem } from 'resources/inventoryTransfer/HardwareItems'
import type { Transfer } from 'resources/inventoryTransfer/type'
import { dateParser } from 'util/misc'

type UpdateTransferDeliveryDateArgs = { transferId: number; date: Date }

type updateInventoryTransferArgs = { [key in keyof Transfer]?: unknown }

const useUpdateTransfer = () => {
  const dataProvider = useDataProvider()
  const updateTransferDeliveryDate = useCallback(async ({ transferId, date }: UpdateTransferDeliveryDateArgs) => {
    await dataProvider.CUSTOM_PATCH('inventory/projects', {
      url: `inventory_transfers/${transferId}/`,
      data: {
        is_arrived: true,
        pickup_date: dateParser(date),
      },
    })
  }, [])

  const updateInventoryTransfer = useCallback(
    async ({
      transferId,
      line_items_new,
      ...data
    }: { transferId: number; line_items_new: RecordedLineItem } & updateInventoryTransferArgs) => {
      await dataProvider.CUSTOM_PATCH('inventory/projects', {
        url: `inventory_transfers/${transferId}/`,
        data: {
          line_items_new,
          ...data,
        },
      })
    },
    []
  )

  return {
    updateTransferDeliveryDate,
    updateInventoryTransfer,
  }
}

export default useUpdateTransfer

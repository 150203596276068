import React, { useCallback, useEffect } from 'react'
import { DISTRIBUTOR_FILTER_KEY } from '../distributor/DistributorFilterNodeFactory'
import type { FilterComponentRendererType, FilterGenericPropsType, FilterKeysType, OptionGenericType } from '../type'

export type FilterOptionsType = OptionGenericType<string>[]

const defaultValue = ''

const OPTIONS: FilterOptionsType = [
  { id: 'default', title: 'All', value: defaultValue },
  { id: 'require_stock', title: 'In stock', value: 'true' },
]

type FilterProps = FilterGenericPropsType & {
  RendererComponent: FilterComponentRendererType<FilterOptionsType>
  filterKey: FilterKeysType
}

const AvailabilityFilter = ({
  filterKey,
  label,
  modifiableFilterValues,
  persistentFilterValues,
  allFilterValues,
  setModifiableFilters,
  RendererComponent,
}: FilterProps) => {
  const onSelect = useCallback(
    (value) => {
      setModifiableFilters({ ...modifiableFilterValues, [filterKey]: value })
    },
    [modifiableFilterValues]
  )

  const value = allFilterValues[filterKey] || defaultValue

  const disabled = !allFilterValues[DISTRIBUTOR_FILTER_KEY]
  return (
    <RendererComponent
      id={filterKey}
      defaultValue={defaultValue}
      label={label || 'Current stock availability'}
      options={OPTIONS}
      disabled={disabled}
      onSelect={onSelect}
      value={value}
    />
  )
}

export default AvailabilityFilter

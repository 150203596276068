import { useAddComponentToSystem } from 'hooks/components/useAddComponentToSystem'
import { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { mapComponentTypesV2ToV1 } from 'util/misc'
import useActivatedComponents from '../../hooks/components/useActivatedComponents'
import type { HardwareSelectorDialogConfig } from './HardwareSelectorCore'

type handleSelectType = (component: any) => void // TODO: add strong typing once endpoint is ready

const useHandleSelect = ({
  onClose,
  config,
}: {
  onClose(): void
  config: HardwareSelectorDialogConfig
}): handleSelectType => {
  const history = useHistory()
  const location = useLocation()
  const componentType = mapComponentTypesV2ToV1(config.componentTypes[0])
  const activeComponentsData = useActivatedComponents({
    type: componentType,
  })

  const addComponentToSystem = useAddComponentToSystem()
  const onResolve = useCallback(
    (component) => {
      history.push(location.pathname)
    },
    [location.pathname]
  )

  return useCallback(
    (component) => {
      return addComponentToSystem({
        componentType: componentType,
        componentActivationId: component.model_id,
        componentId: component.model_id,
        activeComponentsData,
        targetUuids: config.targetUuids,
        slotKey: config.slotKey,
        closeDialog: onClose,
      }).then(onResolve)
    },
    [addComponentToSystem, onResolve, config]
  )
}

export default useHandleSelect

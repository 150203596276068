import { authSelectors } from 'ducks/auth'
import { useLogoutIfAccessDenied, useNotify, useTranslate } from 'ra-core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import AuthModalWrapper from '../auth/AuthModalWrapper'
import ChooseMfaMode from './ChooseMfaMode'
import MfaAddTotp from './MfaAddTotp'
import MfaPhoneNumber from './MfaPhoneNumber'
import MfaRecoveryCodes from './MfaRecoveryCodes'
import MfaSmsCode from './MfaSmsCode'
import MfaStatus from './MfaStatus'
import './mfa.css'
import { fetchMfaConfig } from './util'

type PageType = 'status' | 'mode' | 'phone' | 'sms-code' | 'totp' | 'recovery-codes'
type PropTypes = {
  match: {
    params: {
      page: PageType
    }
  }
}

const MfaActiveSessionWrapper: React.FC<PropTypes> = (props) => {
  const history = useHistory()
  const notify = useNotify()
  const translate = useTranslate()
  const dispatch = useDispatch()
  const logoutIfAccessDenied = useLogoutIfAccessDenied()

  const page = props?.match?.params?.page

  const [currentPage, setCurrentPage] = useState<PageType>('mode')

  const mfaConfig = useSelector(authSelectors.getMfaConfig)

  useEffect(() => {
    if (page !== currentPage) setCurrentPage(page)
  }, [page])

  const goToStatus = () => {
    fetchMfaConfig(dispatch, translate, notify, undefined, logoutIfAccessDenied).then(() => history.push('/mfa/status'))
  }
  const goToMode = () => {
    fetchMfaConfig(dispatch, translate, notify, undefined, logoutIfAccessDenied).finally(() =>
      history.push('/mfa/mode')
    )
  }

  const goToPhone = () => history.push('/mfa/phone')
  const goToSmsCode = () => history.push('/mfa/sms-code')
  const goToTotp = () => history.push('/mfa/totp')
  const goToRecovery = () => {
    history.push('/mfa/recovery-codes')
  }
  const finishAddDevice = () => {
    fetchMfaConfig(dispatch, translate, notify, undefined, logoutIfAccessDenied).finally(() => {
      if (!mfaConfig || mfaConfig.inactive.indexOf('recovery') !== -1) history.push('/mfa/recovery-codes')
      else history.push('/mfa/status')
    })
  }

  useEffect(() => {
    fetchMfaConfig(dispatch, translate, notify, undefined, logoutIfAccessDenied)
  }, [])

  return (
    <AuthModalWrapper>
      <div
        style={{
          padding: '40px 80px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          maxWidth: '425px',
        }}
      >
        <img src={`${window.PUBLIC_URL}/images/opensolar-logo-horizontal.svg`} alt="" height={100} />
        {currentPage === 'status' && (
          <MfaStatus goToPhone={goToPhone} goToTotp={goToTotp} goToMode={goToMode} goToRecovery={goToRecovery} />
        )}
        {currentPage === 'mode' && <ChooseMfaMode goToPhone={goToPhone} goToTotp={goToTotp} cancel={goToStatus} />}
        {currentPage === 'phone' && <MfaPhoneNumber goToSmsCode={goToSmsCode} cancel={goToStatus} />}
        {currentPage === 'sms-code' && <MfaSmsCode goToRecovery={finishAddDevice} cancel={goToStatus} />}
        {currentPage === 'totp' && <MfaAddTotp goToRecovery={finishAddDevice} cancel={goToStatus} />}
        {currentPage === 'recovery-codes' && <MfaRecoveryCodes onFinish={goToStatus} />}
      </div>
    </AuthModalWrapper>
  )
}
export default MfaActiveSessionWrapper

import React, { useState } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type PropTypes = {
  index: number
  handlePaymentOptionReorder: (newIndex: number) => void
}

const useStyles = makeOpenSolarStyles((theme) => ({
  target: {
    left: '-7px',
    top: '0px',
    right: '0px',
    bottom: '0px',
    position: 'absolute',
  },
  barHighlight: {
    position: 'absolute',
    top: '5px',
    bottom: '5px',
    borderRadius: '20px',
    backgroundColor: 'rgb(142, 199, 249)',
    width: '5px',
    left: '-3px',
    zIndex: 900,
  },
}))

const PaymentOptionDragTarget: React.FC<PropTypes> = (props) => {
  const [isActiveTarget, setIsActiveTarget] = useState<boolean>(false)

  const classes = useStyles(isActiveTarget)
  const onDrop = (e: any) => {
    props.handlePaymentOptionReorder(props.index)
  }

  const onDragEnter = (e) => {
    overrideDefault(e)
    setIsActiveTarget(true)
  }

  const onDragLeave = (e) => {
    setIsActiveTarget(false)
  }

  const overrideDefault = (e) => {
    e.preventDefault()
    return false
  }

  return (
    <div
      className={classes.target}
      onDrop={onDrop}
      onDragOver={overrideDefault}
      onDragEnter={onDragEnter}
      onDragExit={onDragLeave}
      onDragLeave={onDragLeave}
    >
      {isActiveTarget && <div className={classes.barHighlight}></div>}
    </div>
  )
}
export default PaymentOptionDragTarget

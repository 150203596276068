import { CircularProgress, makeStyles } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import React, { useMemo } from 'react'
import { useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'
import { WorkflowActionType } from 'types/workflows'
import StageActions from '../StageActions'
import { useProjectEntityPermissions } from './hooks'

interface PropTypes {
  actions: WorkflowActionType[]
  workflowOrgId?: number
}
interface ActionsContentProps extends PropTypes {
  allowEdit: boolean
}
const ActionsContent: React.FC<ActionsContentProps> = (props) => {
  return <StageActions actions={props.actions} allowEdit={props.allowEdit} />
}

const useStyles = makeStyles((theme: OpenSolarThemeType) => ({
  actions: {
    width: '100%',
    border: 'none !important',
    '& .MuiAccordionSummary-root': {
      padding: 0,
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  summary: {
    fontSize: 12,
    marginLeft: 'auto',
    '& p': {
      marginLeft: 15,
      fontSize: 12,
    },
  },
  calendarIcon: {
    height: 15,
  },
  circleWrapper: {
    position: 'relative',
    width: 30,
    height: 30,
  },
  progressCircle: {
    color: '#4272DD',
    position: 'absolute',
    width: '20px !important',
    transform: 'rotate(90deg) !important',
    top: -5,
    left: -5,
  },
  baseProgress: {
    color: '#e7e7e7',
  },
}))

const ActionsAccordion: React.FC<PropTypes> = ({ actions, workflowOrgId }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const formValues = useFormState().values
  const { allowView, allowEdit } = useProjectEntityPermissions(workflowOrgId, 'manage_notes_activities_actions')
  const percentageComplete = useMemo(() => {
    let completeEventCount = 0
    actions.forEach((x) => {
      const events = formValues.actions?.find((y) => y.id === x.id)?.events
      if (events && !!events.length) {
        const completed = events.filter((z) => z.is_complete)
        if (!!completed.length) completeEventCount += 1
      }
    })
    return actions.length ? Math.round((completeEventCount / actions.length) * 100) : 0
  }, [actions, formValues.actions])
  if (!allowView) return null
  return (
    <AccordionCard
      className={classes.actions}
      defaultExpanded={true}
      name={'actions'}
      title={translate('Actions')}
      titleIcon={() => <></>}
      content={ActionsContent}
      contentProps={{ actions, allowEdit }}
      summaryContent={
        <div className={`${classes.row} ${classes.summary}`}>
          <div className={classes.circleWrapper}>
            <CircularProgress
              variant="determinate"
              value={100}
              className={`${classes.progressCircle} ${classes.baseProgress}`}
            />
            <CircularProgress variant="determinate" value={percentageComplete} className={classes.progressCircle} />
          </div>

          {percentageComplete + '%'}
        </div>
      }
    />
  )
}
export default ActionsAccordion

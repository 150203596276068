import { validateTiersInput } from '../common'

export const validateOtherData = (values, translate) => {
  const errors = {}
  if (values.lookup_in_component_database !== false) {
    if (!values.other) {
      errors.other = [translate('Please select a component from dropdown.')]
    }
  }
  validateTiersInput(values, errors)
  return errors
}

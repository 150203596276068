import SharedEntitySelectInput from 'elements/field/SharedEntitySelectInput'
import { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import {
  getAvailableDocs,
  groupAndFilterDocumentsByLanguage,
  matchLocaleFromGrouped,
} from 'resources/documentTemplates/util'

const DOCUMENT_TEMPLATE_TYPE_TO_DOCUMENT_TYPE = {
  0: 'owners_manual',
  1: 'generic_document',
  2: 'installation_instructions',
  3: 'generic_document',
  4: 'energy_yield_report',
  5: 'shade_report',
  6: 'pv_site_plan',
  7: 'structural_report_mcs',
  8: 'change_order',
  9: 'lightreach_sales_design_details',
}

export const SelectDocTemplateInput = ({ choices = [], meta = {}, doc, ...props }) => {
  const project = window.WorkspaceHelper.project
  const [templateChoices, setTemplateChoices] = useState([])
  const [choicesWithExtras, setChoicesWithExtras] = useState([])
  // const [loading, setLoading] = useState(false)
  const translate = useTranslate()
  const sharedWith = project.shared_with

  const updateTemplateChoices = (chosenLanguage, docs, documentTemplatesAll) => {
    let staticItems = [
      { title: translate('Generic Documents'), disabled: true },
      { title: 'Contract PDF', url: 'contract_only' },
      { title: 'Proposal PDF', url: 'proposal_only' },
      { title: 'Combined Proposal and Contract PDF', url: 'proposal' },
    ]

    if (sharedWith?.length) {
      staticItems = staticItems.filter((x) => x.title !== translate('Generic Documents'))
    }

    const newSetCustomized = []
    const newSetGeneric = []

    const docsForLocale = matchLocaleFromGrouped(docs, chosenLanguage, true)

    if (docsForLocale.length) {
      Object.values(docsForLocale).forEach((x) => {
        var documentTemplateMatch = documentTemplatesAll.find((documentTemplate) => documentTemplate.id === x.id)
        if (documentTemplateMatch) {
          var newItem = { ...documentTemplateMatch }
          if (newItem.org_id === 1) {
            newSetGeneric.push(newItem)
          } else {
            newSetCustomized.push(newItem)
          }
        }
      })
    }

    newSetCustomized.sort((a, b) => (a.title > b.title ? 1 : -1))
    newSetGeneric.sort((a, b) => (a.title > b.title ? 1 : -1))

    setTemplateChoices(newSetCustomized.concat(staticItems).concat(newSetGeneric))
  }

  useEffect(() => {
    let newChoices = choices.concat()

    if (!sharedWith?.length) {
      // Add a space between custom and generic
      if (newChoices.length) newChoices.push({ title: <span>&nbsp;</span>, disabled: true })
      newChoices = newChoices.concat(templateChoices)
    }

    setChoicesWithExtras(newChoices)
  }, [choices, templateChoices])

  const getDocsAvailable = () => {
    // setLoading(true)
    getAvailableDocs(project?.country_iso2, project?.org_id)
      .then((res) => {
        // setLoading(false)
        if (res.data.length > 0) {
          var [groupedDocs] = groupAndFilterDocumentsByLanguage(res.data, undefined, false)
          if (Object.keys(groupedDocs).length > 0) {
            updateTemplateChoices(project?.language, groupedDocs, res.data)
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const onChange = (e) => {
    props.clearDownloadUrl()
    const documentTemplateUrl = e.target.value

    if (['contract_only', 'proposal_only', 'proposal'].includes(documentTemplateUrl)) {
      form.change('document_type', documentTemplateUrl)
      form.change('document_template_id', null)
      form.change('document_template_url', documentTemplateUrl)
      form.change('document_template_org_id', null)
    } else {
      const documentTemplateData =
        choicesWithExtras.find((choice) => choice.url === e.target.value) ||
        templateChoices.find((choice) => choice.url === e.target.value)

      form.change('document_template_org_id', documentTemplateData?.org_id)
      form.change('document_template_id', documentTemplateData?.id || null)
      form.change('document_template_url', documentTemplateData?.url || null)

      const documentTypeCode = !documentTemplateData
        ? undefined
        : DOCUMENT_TEMPLATE_TYPE_TO_DOCUMENT_TYPE.hasOwnProperty(documentTemplateData.type)
        ? DOCUMENT_TEMPLATE_TYPE_TO_DOCUMENT_TYPE[documentTemplateData.type]
        : DOCUMENT_TEMPLATE_TYPE_TO_DOCUMENT_TYPE[1]

      form.change('document_type', documentTypeCode)
    }
  }

  useEffect(() => {
    if (project?.country_iso2) {
      getDocsAvailable()
    }
  }, [project])

  const form = useForm()
  const modifiedMeta = { ...meta, error: undefined }
  return (
    <SharedEntitySelectInput
      optionValue="url"
      variant="outlined"
      onChange={onChange}
      {...props}
      choices={choicesWithExtras}
      meta={modifiedMeta}
      label={false}
      disabled={!!doc}
      style={{ width: 350 }}
      defaultOptions={!!sharedWith?.length ? templateChoices : []}
      defaultOptionsTitle={'Generic Documents'}
      projectOwnerOrg={project.org_id}
      sharedWith={sharedWith}
      fullWidth={true}
      emptyText={<i style={{ color: '#aaa' }}>{'Select Document Template...'}</i>}
    />
  )
}

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Popper } from '@material-ui/core'
import { orderSelectors } from 'ducks/orderComponents'
import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { getSupplierConfig } from '../configs'
import { HardwareSupplierEnum } from '../type'
import useHandleChangeSelectedDistributor from './useHandleChangeSelectedDistributor'

type PropsType = {
  enabledSuppliers: HardwareSupplierEnum[]
  onSupplierSelected: () => void
  anchor?: HTMLElement | null
}

const useSupplierSelectorStyles = makeOpenSolarStyles(() => ({
  wrapper: () => ({
    padding: '14px',
    position: 'absolute',
    textAlign: 'left',
    backgroundColor: '#ffffff',
    borderRadius: '6px',
    cursor: 'pointer',
    boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.25)',
  }),
  supplier: {
    marginBottom: '14px',
  },
  img: {
    verticalAlign: 'middle',
    maxHeight: '50px',
    margin: '12px',
  },
}))

// We remind the user that changing suppliers will empty the cart for now.
const Supplier = ({ logoUrl, onClick }: { logoUrl: string; onClick: () => void }) => {
  const classes = useSupplierSelectorStyles()

  return (
    <div className={classes.supplier} onClick={onClick}>
      <img className={classes.img} alt="supplier-logo" src={logoUrl} />
    </div>
  )
}

const SupplierSelector = ({ enabledSuppliers, onSupplierSelected, anchor }: PropsType) => {
  const classes = useSupplierSelectorStyles()
  const dispatch = useDispatch()
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false)
  const [hasItemsInCart, setHasItemsInCart] = useState<boolean>(false)
  const [desiredSupplier, setDesiredSupplier] = useState<HardwareSupplierEnum>()
  const lineItems = useSelector(orderSelectors.getOrderLineItems)
  const handleChangeSelectedDistributor = useHandleChangeSelectedDistributor()

  const handleClose = () => {
    setShowConfirmDialog(false)
  }

  const doUpdateSelectedSupplier = () => {
    if (desiredSupplier) {
      handleChangeSelectedDistributor(desiredSupplier)
      onSupplierSelected()
      setShowConfirmDialog(false)
    }
  }

  useEffect(() => {
    setHasItemsInCart(lineItems.length > 0)
    console.log('hasItemsInCart', hasItemsInCart)
  }, [lineItems.length])

  useEffect(() => {
    if (hasItemsInCart) {
      setShowConfirmDialog(true)
    } else {
      doUpdateSelectedSupplier()
    }
  }, [desiredSupplier])

  /// We show the warning dialog if there are items in a shopping cart.
  return (
    <>
      <Dialog
        fullScreen={false}
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>Change hardware supplier</DialogTitle>
        <DialogContent>
          <DialogContentText>Changing hardware supplier will empty your shopping cart.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={doUpdateSelectedSupplier} color="primary" variant="contained">
            Proceed
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Popper open={Boolean(anchor)} anchorEl={anchor} style={{ zIndex: 1500 }} placement="bottom-start">
        <div className={classes.wrapper}>
          {enabledSuppliers.map((supplier, index) => {
            const config = getSupplierConfig(supplier)
            if (config) {
              return (
                <Supplier
                  key={index}
                  logoUrl={config.logoUrl}
                  onClick={() => {
                    setDesiredSupplier(supplier)
                  }}
                />
              )
            }
            return null
          })}
        </div>
      </Popper>
    </>
  )
}

export default memo(SupplierSelector)

import type { OptionGenericType } from '../typeV2'

const getCheckBoxSelectAllMutator = <OptionValue, RendererValue>(SELECT_ALL_OPTION: OptionGenericType<OptionValue>) => {
  const checkBoxSelectAllMutator = ({
    value,
    selectedOption,
    options,
  }: {
    value: OptionValue
    selectedOption: OptionGenericType<OptionValue>
    options: OptionGenericType<OptionValue>[]
  }) => {
    console.log('getCheckBoxSelectAllMutator', value)
    if (!(value instanceof Set)) {
      return value
    }

    if (selectedOption.id === SELECT_ALL_OPTION.id || value.size === 0) {
      // click on select all
      return new Set([SELECT_ALL_OPTION.value]) as RendererValue
    }  if (selectedOption.id !== SELECT_ALL_OPTION.id) {
      value.delete(SELECT_ALL_OPTION.value)
      return value
    }
    return value
  }
  return checkBoxSelectAllMutator
}

export default getCheckBoxSelectAllMutator

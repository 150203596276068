import { orgSelectors } from 'ducks/orgs'
import CheckboxMultiSelectInput from 'elements/input/CheckboxMultiSelectInput'
import DateTimeInput from 'elements/input/DateTimeInput'
import React, { useMemo } from 'react'
import { SelectInput, TextInput } from 'react-admin'
import { useSelector } from 'react-redux'
import { getModelByFieldName } from 'resources/customFields/PredefinedModels'
import { CustomFieldType, CustomFieldVariants } from 'types/orgs'
import StyledDateInput from './StyledDateInput'

//aim to replace CustomFieldsContent.tsx for UI 3.0
type ModelType = 'Project' | 'Contact' | 'COGS'

interface PropTypes {
  model: ModelType
  source?: string
  inputClass?: string
  inputVariant?: 'outlined' | 'standard' | 'filled'
  disabled?: boolean
}
const getCustomFields = (customFields: CustomFieldType[] | undefined, model: ModelType) => {
  return customFields
    ? customFields.filter((config: CustomFieldType) => getModelByFieldName(config.field) === model)
    : []
}
const CustomFields: React.FC<PropTypes> = (props) => {
  const org = useSelector(orgSelectors.getOrg)
  const customFields = useMemo(() => getCustomFields(org?.custom_fields, props.model), [])

  return (
    <>
      {customFields.map((config: CustomFieldType, i) => {
        return <RenderField key={'field-' + i} config={config} {...props} />
      })}
    </>
  )
}

interface FieldProps extends PropTypes {
  config: CustomFieldType
}

const RenderField: React.FC<FieldProps> = ({ config, source, inputClass, inputVariant, disabled }) => {
  const fieldSource = useMemo(() => (source ? `${source}.${config.field}` : config.field), [source, config.field])
  const selectChoices = useMemo(
    () =>
      (config.select_field_choices?.split(',') || []).map((value: string) => ({
        id: value,
        name: value,
      })),
    [config.select_field_choices]
  )

  switch (config.field_type) {
    case CustomFieldVariants.text:
      return (
        <TextInput
          source={fieldSource}
          variant={inputVariant}
          label={config.label}
          className={inputClass}
          disabled={disabled}
        />
      )
    case CustomFieldVariants.dropdown:
      return (
        <SelectInput
          variant={inputVariant}
          source={fieldSource}
          className={inputClass}
          label={config.label}
          choices={selectChoices}
          disabled={disabled}
          allowEmpty
        />
      )
    case CustomFieldVariants.dateTime:
      return (
        // @ts-ignore
        <DateTimeInput
          source={fieldSource}
          options={{
            inputVariant: inputVariant,
          }}
          label={config.label}
          disabled={disabled}
          fullWidth
        />
      )
    case CustomFieldVariants.date:
      return (
        <StyledDateInput inputVariant={inputVariant} source={fieldSource} label={config.label} disabled={disabled} />
      )
    case CustomFieldVariants.multiSelect:
      return (
        <CheckboxMultiSelectInput
          source={fieldSource}
          choices={selectChoices}
          className={inputClass}
          variant={inputVariant}
          label={config.label}
          style={{ width: '100%' }}
          color={'secondary'}
          disabled={disabled}
        />
      )
    default:
      return (
        <TextInput
          source={`${source}.${config.field}`}
          variant={inputVariant}
          label={config.label}
          className={inputClass}
          disabled={disabled}
        />
      )
  }
}
export default CustomFields

import LoadingDots from 'layout/widgets/LoadingDots'
import React, { memo } from 'react'
import { useTranslate } from 'react-admin'

const LoadingSpinner = memo(({ style, text }) => {
  const translate = useTranslate()
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'white',
        opacity: 0.5,
        zIndex: 1000,
        ...style,
      }}
    >
      <div
        style={{
          position: 'fixed',
          transform: 'translate(-50%, -50%)',
          top: '50%',
          left: '50%',
          zIndex: 1001,
        }}
      >
        <div
          style={{
            marginTop: -50,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadingDots />
          {text && <span style={{ marginTop: '20px' }}>{translate(text)}</span>}
        </div>
      </div>
    </div>
  )
})

export default LoadingSpinner

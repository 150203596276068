// @ts-nocheck
import { Divider } from '@material-ui/core'
import GenericButton from 'elements/button/GenericButton'
import React, { CSSProperties, useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { PaymentMethodEnum } from 'types/paymentOptions'
import { ProjectType } from '../../../types/projects'
import { getIsValidSunlightCreditAppContact, getIsValidSunlightPrequalContact } from '../../../util/misc'
import SunlightTransferCredit from './SunlightTransferCredit'
import SunlightQuickCreateParagraph from './SunlightTransferParagraph'
import SunlightTransferPrequal from './SunlightTransferPrequal'
import { PaymentOptionSunlightQuickCreateType, SystemDesignSunlightQuickCreateType } from './types'

const CONFIG = {
  logo: 'logo-sunlight-financial.png',
  logo_small: 'logo-sunlight-financial-small.png',
  studio_banner_title: 'Simplify Your Sale!',
  name: 'sunlight',
  themeColor: 'rgb(254, 184, 22)',
  themeColorDark: 'rgb(232, 143, 36)',
}

const wrapperStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}

const buttonsWrapperStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  width: '100%',
}

const individualButtonWrapper: CSSProperties = {
  marginTop: '15px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
}

const disabledMessageStyle: CSSProperties = {
  fontStyle: 'italic',
  margin: '5px 10px',
  width: '220px',
  textAlign: 'center',
}

type PropTypes = {
  project: ProjectType
  orgId: number
  systemUuid: string | null
  paymentOptionId: number | null
  labelColor: string
  backgroundColor: string
  backgroundColorOnHover: string
  allowCreditApp: boolean
  allowPrequal: boolean
  creditDisabledMsg?: string
  hideDivider?: boolean
  paymentOptionForPrequal?: PaymentOptionSunlightQuickCreateType
  systemDesigns?: SystemDesignSunlightQuickCreateType[]
  onFinish?: (type: 'prequal' | 'credit') => void
}

const SunlightTransferChoice: React.FunctionComponent<PropTypes> = (props) => {
  const getAlreadyHasPrequal = () => {
    let hasIt = false
    //@ts-ignore
    if (props.project && props.project.transactions_data) {
      props.project.transactions_data.forEach((transaction) => {
        if (transaction.transaction_type === 'sunlight_loan_prequal' && transaction.external_application_id)
          hasIt = true
      })
    } else if (props.project && props.project.available_customer_actions) {
      props.project.available_customer_actions.forEach((action) => {
        if (action.actions_available)
          action.actions_available.forEach((availAction) => {
            if (availAction.payment_method === PaymentMethodEnum.sunlight_loan_prequal && !!availAction.status_title)
              hasIt = true
          })
      })
    }
    return hasIt
  }

  const getAlreadyHasCreditApp = () => {
    let hasIt = false
    //@ts-ignore
    if (props.project && props.project.transactions_data) {
      let sunlightLoanTransactions = props.project.transactions_data.filter(
        (transaction) =>
          transaction.transaction_type === 'sunlight_loan_application' && transaction.external_application_id
      )
      if (sunlightLoanTransactions && sunlightLoanTransactions.length > 0) hasIt = true
    } else if (props.project && props.project.available_customer_actions) {
      props.project.available_customer_actions.forEach((action) => {
        if (action.actions_available)
          action.actions_available.forEach((availAction) => {
            if (
              availAction.payment_method === PaymentMethodEnum.sunlight_loan_application &&
              !!availAction.status_title
            )
              hasIt = true
          })
      })
    }
    return hasIt
  }

  const [disablePrequalDueToContact, setDisablePrequalDueToContact] = useState<boolean>(false)
  const [disableCreditDueToContact, setDisableCreditDueToContact] = useState<boolean>(false)
  const [userChosePrequal, setUserChosePrequal] = useState<boolean>(false)
  const [userChoseCredit, setUserChoseCredit] = useState<boolean>(false)
  const [alreadyHasCreditApp, setAlreadyHasCreditApp] = useState<boolean>(getAlreadyHasCreditApp())
  const [alreadyHasPrequal, setAlreadyHasPrequal] = useState<boolean>(getAlreadyHasPrequal())

  const translate = useTranslate()

  //a bit hacky, but if rendering in MyEnergy we need to check the validity of the contact for prequal based on project.contacts
  //but if it's rendering in project page the parent component checks project.contact_data and passes prequal eligibility down as prop
  useEffect(() => {
    if (props.project && !disablePrequalDueToContact) {
      let hasValid = false
      if (props.project.contacts_data && props.project.contacts_data.length > 0) {
        props.project.contacts_data.forEach((con) => {
          if (getIsValidSunlightPrequalContact(con)) hasValid = true
        })
      } else if (props.project.contacts) {
        props.project.contacts.forEach((con) => {
          if (getIsValidSunlightPrequalContact(con)) hasValid = true
        })
      }
      if (!hasValid) setDisablePrequalDueToContact(true)
    }
  }, [props.project, disablePrequalDueToContact])

  //same thing for but credit app which only requires name
  useEffect(() => {
    if (props.project && !disableCreditDueToContact) {
      let hasValid = false
      if (props.project.contacts_data && props.project.contacts_data.length > 0) {
        props.project.contacts_data.forEach((con) => {
          if (getIsValidSunlightCreditAppContact(con)) hasValid = true
        })
      } else if (props.project.contacts) {
        props.project.contacts.forEach((con) => {
          if (getIsValidSunlightCreditAppContact(con)) hasValid = true
        })
      }

      if (!hasValid) setDisableCreditDueToContact(true)
    }
  }, [props.project, disableCreditDueToContact])

  const renderChoice = () => {
    return (
      <>
        <SunlightQuickCreateParagraph />
        <div style={buttonsWrapperStyle}>
          <div style={individualButtonWrapper}>
            <GenericButton
              onClick={() => setUserChoseCredit(true)}
              startIcon={
                <img
                  alt={CONFIG.name}
                  src={`${window.PUBLIC_URL}/images/${CONFIG.logo_small}`}
                  style={{ maxHeight: 24, maxWidth: 60 }}
                />
              }
              labelWrapperStyle={{ padding: '0 8px', whiteSpace: 'normal' }}
              label={translate('Send Credit App to Customer')}
              labelColor={props.labelColor}
              backgroundColor={props.backgroundColor}
              backgroundColorOnHover={props.backgroundColorOnHover}
              disabled={!props.allowCreditApp || alreadyHasCreditApp || disableCreditDueToContact}
              tooltipLabel={null}
              style={{ minWidth: '220px' }}
            />
            {props.creditDisabledMsg && <span style={disabledMessageStyle}>{props.creditDisabledMsg}</span>}
            {!props.creditDisabledMsg && alreadyHasCreditApp && (
              <span style={disabledMessageStyle}>An application has already been created for this project</span>
            )}
            {!props.creditDisabledMsg && !alreadyHasCreditApp && disableCreditDueToContact && (
              <span style={disabledMessageStyle}>{translate('Must have a contact with email and full name')}</span>
            )}
          </div>
          <div style={individualButtonWrapper}>
            <GenericButton
              onClick={() => setUserChosePrequal(true)}
              startIcon={
                <img
                  alt={CONFIG.name}
                  src={`${window.PUBLIC_URL}/images/${CONFIG.logo_small}`}
                  style={{ maxHeight: 24, maxWidth: 60 }}
                />
              }
              labelWrapperStyle={{ padding: '0 8px', whiteSpace: 'normal' }}
              label={translate('Create Sunlight Prequal App')}
              labelColor={props.labelColor}
              backgroundColor={props.backgroundColor}
              backgroundColorOnHover={props.backgroundColorOnHover}
              disabled={!props.allowPrequal || alreadyHasPrequal || disablePrequalDueToContact}
              tooltipLabel={null}
              style={{ minWidth: '220px' }}
            />
            {alreadyHasPrequal && (
              <span style={disabledMessageStyle}>A Prequal has already been created for this project</span>
            )}
            {!alreadyHasPrequal && disablePrequalDueToContact && (
              <span style={disabledMessageStyle}>
                {translate('Contact must have valid name, email, and 10 digit phone')}
              </span>
            )}
          </div>
        </div>
      </>
    )
  }

  const handleFinish = (type: 'prequal' | 'credit') => {
    if (props.onFinish) props.onFinish(type)
    type === 'prequal' ? setAlreadyHasPrequal(true) : setAlreadyHasCreditApp(true)
    if (userChoseCredit) setUserChoseCredit(false)
    if (userChosePrequal) setUserChosePrequal(false)
  }

  if (!props.project?.id || !props.orgId) return null
  return (
    <>
      {!props.hideDivider && <Divider style={{ marginTop: 20, marginBottom: 20 }} />}
      <div style={wrapperStyle}>
        <img
          alt={CONFIG.name}
          src={`${window.PUBLIC_URL}/images/${CONFIG.logo}`}
          style={{ maxHeight: 58, maxWidth: 280 }}
        />
        {userChosePrequal && (
          <SunlightTransferPrequal
            project={props.project}
            orgId={props.orgId}
            systemUuid={props.systemUuid}
            paymentOptionId={props.paymentOptionId || props.paymentOptionForPrequal?.id}
            onBack={() => setUserChosePrequal(false)}
            backgroundColor={props.backgroundColor}
            backgroundColorOnHover={props.backgroundColorOnHover}
            labelColor={props.labelColor}
            onFinish={() => handleFinish('prequal')}
          />
        )}
        {userChoseCredit && (
          <SunlightTransferCredit
            project={props.project}
            orgId={props.orgId}
            systemUuid={props.systemUuid}
            paymentOptionId={props.paymentOptionId}
            onBack={() => setUserChoseCredit(false)}
            backgroundColor={props.backgroundColor}
            backgroundColorOnHover={props.backgroundColorOnHover}
            labelColor={props.labelColor}
            systemDesigns={props.systemDesigns}
            onFinish={() => handleFinish('credit')}
          />
        )}
        {!userChosePrequal && !userChoseCredit && renderChoice()}
      </div>
    </>
  )
}
export default SunlightTransferChoice

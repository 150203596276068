import { satisfies } from 'compare-versions'
import { get, isEqual } from 'lodash'
import { AuthUserType } from 'types/auth'
import { OrgType } from 'types/orgs'
import { RoleType } from 'types/roles'
import { promoConfigType, targetFieldType } from './types'

const MIN = 60 * 1000

export const migratePromos = (promos: promoConfigType[] | undefined) => {
  if (promos) promos.forEach(migratePromo)
  return promos
}

// Migrate any 'wild' promos into the current format
export const migratePromo = (promo: promoConfigType) => {
  if (!promo.skipEnvs) promo.skipEnvs = ['cypress']
  promo.targetOrgFields = migrateFields(promo.targetOrgFields)
  promo.targetRoleFields = migrateFields(promo.targetRoleFields)
  promo.targetUserFields = migrateFields(promo.targetUserFields)
}

function migrateFields(fields: targetFieldType[] | undefined): targetFieldType[] {
  if (!fields) return []

  return fields.map((field) => {
    if (field.op) return field // Already in new format

    const newField: targetFieldType = { fieldPath: field.fieldPath }

    if (field.value !== undefined) {
      newField.op = 'eq'
      newField.value = field.value
    } else if (field.not !== undefined) {
      newField.op = 'neq'
      newField.value = field.not
    } else if (field.any) {
      newField.op = 'in'
      newField.value = field.any
    } else if (field.notAny) {
      newField.op = 'nin'
      newField.value = field.notAny
    }

    return newField
  })
}

export const filterPromos = (
  promoConfigs: promoConfigType[],
  filters: PromoFilters,
  opts: { debug?: boolean } = {}
) => {
  return promoConfigs.filter((promoConfig) => filterPromo(promoConfig, filters, opts))
}

export type PromoFilters = {
  org: OrgType | undefined
  user: AuthUserType | undefined
  acceptedTermsDate: Date | undefined
  role: RoleType | undefined
  orgCountry: string
  isAdmin: boolean | undefined
  requireMeetsOne: boolean
}

export const filterPromo = (
  promoConfig: promoConfigType,
  filters: PromoFilters,
  opts: {
    debug?: boolean
    storageKey?: string // Omit to always reshow regardless of history
  } = {}
): boolean => {
  if (!promoConfig.id) {
    // Need an ID to track whether it's been shown
    console.warn('Skipping misconfigured in-app promo, no ID:', promoConfig)
    if (opts.debug) console.debug('\tPromo Filtered, no ID: ', promoConfig)
    return false
  }

  let meetsOne = false

  // check env
  if (promoConfig.skipEnvs && promoConfig.skipEnvs.includes(window.ENV)) {
    if (opts.debug)
      console.debug('\tPromo Filtered, env mismatch: ', promoConfig.id, {
        promo_skipEnvs: promoConfig.skipEnvs,
        app_env: window.ENV,
      })
    return false
  }

  // if we only want admins to see it, make sure they're an admin
  if (promoConfig.adminOnly) {
    if (!filters.isAdmin) {
      if (opts.debug)
        console.debug('\tPromo Filtered, is admin mismatch: ', promoConfig.id, {
          promo_isAdmin: promoConfig.adminOnly,
          user_isAdmin: filters.isAdmin,
        })
      return false
    }
    meetsOne = true
  }

  if (promoConfig.isPro !== undefined) {
    if (promoConfig.isPro !== !!filters.role) {
      if (opts.debug)
        console.debug('\tPromo Filtered, is pro mismatch: ', promoConfig.id, {
          promo_isPro: promoConfig.isPro,
          user_isPro: !!filters.role,
        })
      return false
    }
    meetsOne = true
  }

  if (promoConfig.inMobileApp !== undefined) {
    if (!!window.RUNNING_AS_APP !== promoConfig.inMobileApp) {
      if (opts.debug)
        console.debug('\tPromo Filtered, running as app mismatch: ', promoConfig.id, {
          promo_inMobileApp: promoConfig.inMobileApp,
          app_inMobileApp: window.RUNNING_AS_APP,
        })
      return false
    }
    meetsOne = true
  }

  if (promoConfig.isCustomer !== undefined) {
    if (promoConfig.isCustomer !== !filters.role) {
      if (opts.debug)
        console.debug('\tPromo Filtered, is customer mismatch: ', promoConfig.id, {
          promo_isCustomer: promoConfig.isCustomer,
          user_isCustomer: !filters.role,
        })
      return false
    }
    meetsOne = true
  }

  // if we're showing this to certain roles, make sure this is one
  if (filters.role?.id && promoConfig.roleIds && promoConfig.roleIds?.length > 0) {
    let hasMatch = false
    promoConfig.roleIds?.forEach((promoId) => {
      if (`${promoId}` === `${filters.role?.id}`) hasMatch = true
    })
    if (hasMatch) {
      meetsOne = true
    } else {
      if (opts.debug)
        console.debug('\tPromo Filtered, role id mismatch: ', promoConfig.id, {
          promo_roleIds: promoConfig.roleIds,
          role_id: filters.role?.id,
        })
      return false
    }
  }
  // if we only show to certain orgs, make sure this one
  if (filters.org?.id && promoConfig.orgIds && promoConfig.orgIds?.length > 0) {
    let hasMatch = false
    promoConfig.orgIds?.forEach((promoId) => {
      if (`${promoId}` === `${filters.org?.id}`) hasMatch = true
    })
    if (hasMatch) {
      meetsOne = true
    } else {
      if (opts.debug)
        console.debug('\tPromo Filtered, org id mismatch: ', promoConfig.id, {
          promo_orgIds: promoConfig.orgIds,
          org_id: filters.org?.id,
        })
      return false
    }
  }
  //  check org's country
  if (promoConfig.org_country_iso2) {
    let countries: string[]
    if (typeof promoConfig.org_country_iso2 === 'string') countries = [promoConfig.org_country_iso2]
    else countries = promoConfig.org_country_iso2

    if (countries.includes('!' + filters.orgCountry)) {
      // Excluded country
      if (opts.debug)
        console.debug('\tPromo Filtered, excluded country: ', promoConfig.id, {
          org_country: filters.orgCountry,
          promo_country: promoConfig.org_country_iso2,
        })
      return false
    }

    if (countries.includes(filters.orgCountry)) meetsOne = true
    else {
      if (opts.debug)
        console.debug('\tPromo Filtered, country mismatch: ', promoConfig.id, {
          org_country: filters.orgCountry,
          promo_country: promoConfig.org_country_iso2,
        })
      return false
    }
  }
  // check account age
  if (promoConfig.minAccountAge_days) {
    const accountAge = filters.acceptedTermsDate ? Date.now() - filters.acceptedTermsDate.getTime() : 0
    const accountAgeDays = accountAge / (24 * 60 * 60 * 1000)
    if (accountAgeDays < promoConfig.minAccountAge_days) {
      if (opts.debug)
        console.debug('\tPromo Filtered, account age mismatch: ', promoConfig.id, {
          accountAgeDays,
          minAccountAge: promoConfig.minAccountAge_days,
        })
      return false
    }
    meetsOne = true
  }
  // check role fields
  if (promoConfig.targetRoleFields && promoConfig.targetRoleFields?.length > 0) {
    if (
      !checkFields(promoConfig.targetRoleFields, filters.role, {
        debug: opts?.debug,
        debug_prefix: '\tPromo Filtered, role field mismatch: ' + promoConfig.id,
      })
    )
      return false
    else meetsOne = true
  }
  // check user fields
  if (promoConfig.targetUserFields && promoConfig.targetUserFields?.length > 0) {
    if (
      !checkFields(promoConfig.targetUserFields, filters.user, {
        debug: opts?.debug,
        debug_prefix: '\tPromo Filtered, user field mismatch: ' + promoConfig.id,
      })
    )
      return false
    else meetsOne = true
  }
  // check org fields
  if (promoConfig.targetOrgFields && promoConfig.targetOrgFields?.length > 0) {
    if (
      !checkFields(promoConfig.targetOrgFields, filters.org, {
        debug: opts?.debug,
        debug_prefix: '\tPromo Filtered, org field mismatch: ' + promoConfig.id,
      })
    )
      return false
    else meetsOne = true
  }
  // check SPA version
  if (promoConfig.spaVersion) {
    if (window.SPA_VERSION && !satisfies(window.SPA_VERSION, promoConfig.spaVersion)) {
      if (opts.debug)
        console.debug('\tPromo Filtered, SPA version mismatch: ', promoConfig.id, {
          app_spaVersion: window.SPA_VERSION,
          promo_spaVersion: promoConfig.spaVersion,
        })
      return false
    } else meetsOne = true
  }

  // Check platform/s
  if (promoConfig.platforms) {
    const platforms: string[] =
      typeof promoConfig.platforms === 'string' ? [promoConfig.platforms] : promoConfig.platforms
    let foundPlatform = false
    for (const platform of platforms) {
      if (navigator.platform.toLowerCase().includes(platform.toLowerCase())) {
        foundPlatform = true
        break
      }
    }
    if (!foundPlatform) {
      if (opts.debug)
        console.debug('\tPromo Filtered, platform mismatch: ', promoConfig.id, {
          navigatorPlatform: navigator.platform,
          promoPlatforms: promoConfig.platforms,
        })
      return false
    }
    meetsOne = true
  }

  // Timing checks
  const now = Date.now()
  if (promoConfig.timeShowStart) {
    const timeShowStart = promoConfig.timeShowStart * 1000
    const endTime = timeShowStart + (promoConfig.timeDurationMins || 0) * MIN

    if (promoConfig.timeDurationMins && !promoConfig.timeShowExpired && endTime < now) {
      // Past end time and not showing expired
      if (opts?.debug) console.debug('\tPromo Filtered, before start time: ', promoConfig.id, { endTime, now })
      return false
    } else if (timeShowStart > now) {
      // Before start time
      if (opts?.debug) console.debug('\tPromo Filtered, before start time: ', promoConfig.id, { timeShowStart, now })
      return false
    }

    meetsOne = true
  }

  if (promoConfig.timeShowEnd && !promoConfig.timeShowExpired) {
    const timeShowEnd = promoConfig.timeShowEnd * 1000

    if (timeShowEnd < now) {
      if (opts?.debug)
        console.debug('\tPromo Filtered, after end time: ', promoConfig.id, {
          timeShowEnd,
          now,
        })
      return false
    }

    meetsOne = true
  }

  if (filters.requireMeetsOne && !meetsOne) {
    if (opts.debug) console.debug("\tPromo Filtered, doesn't meet at least one condition: ", promoConfig.id)
    return false
  }

  return true
}

function doesMatch(value: any, fieldTarget: targetFieldType): boolean {
  if (fieldTarget.op) {
    switch (fieldTarget.op) {
      case 'eq':
        return isEqual(value, fieldTarget.value)
      case 'neq':
        return !isEqual(value, fieldTarget.value)
      case 'gt':
        return value > fieldTarget.value
      case 'lt':
        return value < fieldTarget.value
      case 'gte':
        return value >= fieldTarget.value
      case 'lte':
        return value <= fieldTarget.value
      case 'in':
        return fieldTarget.value.includes(value)
      case 'nin':
        return !fieldTarget.value.includes(value)
    }
  } else {
    if (fieldTarget.value !== undefined) {
      return isEqual(value, fieldTarget.value)
    }
    if (fieldTarget.not !== undefined) {
      return !isEqual(value, fieldTarget.not)
    }
    if (fieldTarget.any) {
      return fieldTarget.any.includes(value)
    }
    if (fieldTarget.notAny) {
      return !fieldTarget.notAny.includes(value)
    }
  }
  return true // If no conditions are specified, consider it a match
}

// Returns true if all fields pass
function checkFields(
  targetFields: targetFieldType[],
  obj: any,
  opts: { debug?: boolean; debug_prefix?: string } = {}
): boolean {
  const debug_prefix = opts.debug_prefix || 'Filter mismatch: '

  return !targetFields.some((fieldTarget) => {
    const value = get(obj, fieldTarget.fieldPath)

    const matches = doesMatch(value, fieldTarget)

    if (!matches && opts.debug) {
      console.debug(debug_prefix, { value, fieldTarget })
    }

    return !matches
  })
}

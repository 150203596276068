import { Dialog, DialogContent, Tooltip } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import { IconButton } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import { connect } from 'react-redux'
import Letter from '../elements/Letter'
import { AboutUS } from '../mainContent/components/AboutUs'

export const AboutUsDialog = (props) => {
  const translate = useTranslate()
  return (
    <div className="ContainerAboutUs" id="ContainerAboutUs">
      <Dialog
        open={props.isOpen}
        style={{ maxHeight: 'calc(100vh - 56px)' }}
        onBackdropClick={() => props.closeDialog()}
        className="about-us"
      >
        <DialogContent>
          <IconButton
            id="AboutUsClose"
            style={{
              top: 10,
              right: 10,
              position: 'absolute',
              minWidth: 40,
              backgroundColor: 'rgba(255, 255, 255, 0)',
            }}
            onClick={() => props.closeDialog()}
          >
            <Tooltip title={translate('Close Dialog')} enterDelay={300}>
              <CloseIcon style={{ color: '#000000' }} />
            </Tooltip>
          </IconButton>
          {props.org && props.showLetter && props.content && (
            <Letter org={props.org} title={props.title} content={props.content} isAboutUs={true} />
          )}
          {props.org && (
            <AboutUS
              org={props.org}
              title={props.title}
              selectedProject={props.selectedProject}
              showLetter={Boolean(props.showLetter && props.content)}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default connect((state) => ({ showLetter: state.welcome && state.welcome.customer === true }))(AboutUsDialog)

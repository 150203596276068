import { Theme, makeStyles, useMediaQuery } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import LoadingDots from 'layout/widgets/LoadingDots'
import { PaymentRequestType } from 'pages/cashFlow/types'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { StudioSystemType } from 'types/global'
import { ExpectedMilestonePaymentType, PaymentOptionDataType } from 'types/paymentOptions'
import { useGetPaymentRequests } from '../../cashFlowTransactions/utils'
import MilestoneCell from './MilestoneCell'
import { useIsCalculating } from './utils'

type PropTypes = {
  paymentOption: PaymentOptionDataType
  system: StudioSystemType
  projectId: number
  milestones: ExpectedMilestonePaymentType[]
  adHocPaymentRequests: PaymentRequestType[]
  refreshMilestones: () => void
  isFetchingMilestones: boolean
  displayedInWidget?: boolean | false
}

const useStyles = makeStyles<OpenSolarThemeType, { isMobile: boolean; displayedInWidget?: boolean }>((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: ({ isMobile }) => (isMobile ? 'column' : 'row'),
    justifyContent: ({ displayedInWidget }) => (displayedInWidget ? 'flex-start' : 'center'),
    alignItems: 'center',
    width: '100%',
    minHeight: '133px',
    paddingLeft: 15,
  },
  loadingDots: {
    opacity: 0.5,
    position: 'absolute',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.greyLight1,
  },
  nothingHereYet: {
    color: theme.greyMid1,
    padding: '10px 50px',
    textAlign: 'center',
  },
}))

const MilestoneTimeline: React.FC<PropTypes> = (props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const classes = useStyles({ isMobile, displayedInWidget: props.displayedInWidget })
  const translate = useTranslate()
  const isCalculating = useIsCalculating(props.system.uuid)
  const { paymentRequests, isLoading } = useGetPaymentRequests(props.projectId, props.system, props.paymentOption)

  const nothingHereYetText = useMemo(() => {
    if (props.paymentOption?.payment_type === 'cash') {
      return translate(
        'This sold payment option does not have a CashFlow Configuration applied to it. This likely means you either do not have an auto-applied configuration or this project was created before your first configuration was.'
      )
    } else {
      if (props.paymentOption?.down_payment || props.paymentOption?.down_payment_override) {
        return translate(
          'This sold finance payment option does not have a CashFlow configuration applied to it. This likely means you have not configured an auto-applied CashFlow configuration for finance payment options.'
        )
      } else {
        return translate('This sold finance payment option has no upfront payment and no post-sale milestones.')
      }
    }
  }, [props.paymentOption?.payment_type])
  if (!props.paymentOption || !props.system.uuid) return null
  else
    return (
      <div className={classes.wrapper}>
        <div className={classes.row}>
          {props.isFetchingMilestones && (
            <Skeleton width={isMobile ? '150px' : '600px'} height={isMobile ? '300px' : '125px'} />
          )}
          {!props.isFetchingMilestones &&
            props?.milestones?.map((milestone, i) => (
              <MilestoneCell
                key={i}
                milestone={milestone}
                paymentOption={props.paymentOption}
                systemUuid={props.system.uuid}
                refreshMilestones={props.refreshMilestones}
                isLast={i === props.milestones.length - 1}
                allPaymentRequests={paymentRequests}
                displayedInWidget={props.displayedInWidget}
              />
            ))}
          {!props.isFetchingMilestones && !props.milestones?.length && (
            <div className={classes.nothingHereYet}>{nothingHereYetText}</div>
          )}
        </div>
        {isCalculating ? (
          <div className={classes.loadingDots}>
            <LoadingDots />
          </div>
        ) : null}
      </div>
    )
}
export default MilestoneTimeline

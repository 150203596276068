import { FC } from 'react'
import { CodeBlock } from '../CodeBlock'
import ComponentPage from '../ComponentPage'
import { TinyBoxActions, TinyBoxBasic, TinyBoxMax, TinyBoxSidebar, TinyLabelBasic } from './TinyBoxUsage'

const TinyBoxUsage_code = require('!!raw-loader!./TinyBoxUsage').default

export const DemoTinyBox: FC = () => {
  return (
    <ComponentPage
      name={'TinyBox & TinyLabel'}
      import="import { TinyBox, TinyLabel } from 'elements/TinyBox'"
      description={
        'TinyBox is useful for displaying detailed information in a small area.\nTinyLabel is useful for displaying small label/value pairs.'
      }
    >
      <CodeBlock
        name="TinyBox Basic Usage"
        code={TinyBoxUsage_code}
        codePart={1}
        description="How to use the title, tagline and subtitle props"
      >
        <TinyBoxBasic />
      </CodeBlock>

      <CodeBlock name="TinyLabel Basic Usage" code={TinyBoxUsage_code} codePart={3}>
        <TinyLabelBasic />
      </CodeBlock>

      <CodeBlock
        name="TinyBox Actions"
        code={TinyBoxUsage_code}
        codePart={2}
        description="How to add action buttons to a TinyBox"
      >
        <TinyBoxActions />
      </CodeBlock>

      <CodeBlock
        name="TinyBox with sidebar"
        code={TinyBoxUsage_code}
        codePart={6}
        description="How to add 'sidebar' elements to a TinyBox"
      >
        <TinyBoxSidebar />
      </CodeBlock>

      <CodeBlock
        name="TinyBox with everything"
        code={TinyBoxUsage_code}
        codePart={4}
        description="Maxed out TinyBox, using TinyLabel inside"
      >
        <TinyBoxMax />
      </CodeBlock>
    </ComponentPage>
  )
}

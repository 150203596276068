import { makeStyles, Tooltip } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartOutlined'
import { push } from 'connected-react-router'
import { openOrderCart, orderSelectors } from 'ducks/orderComponents'
import { Button } from 'opensolar-ui'
import ProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/projectOrderPresenter'
import React from 'react'
import { useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { DialogHelper } from 'util/misc'
import { useFeatureFlag } from 'util/split'

const StyledIconButton = withStyles({
  root: {
    color: '#1890ff',
    padding: 0,
  },
})(IconButton)

const useStyles = makeStyles({
  startIcon: {
    margin: 0,
  },
})

const OrderCartButton = (props) => {
  const dispatch = useDispatch()
  const enableHardwareOrdering = useFeatureFlag('hardware_ordering', 'on')
  const cartItems = useSelector(orderSelectors.getConfirmedLineItems)
  const totalQuantity = ProjectOrderPresenter.getLineItemsTotalQuantity(cartItems)
  const translate = useTranslate()
  const classes = useStyles()
  return (
    <div
      style={{
        textAlign: 'center',
        borderRadius: '3px',
        marginRight: 5,
      }}
    >
      {props.layout > 3 ? (
        <Button
          classes={classes}
          variant="outlined"
          color="default"
          size="small"
          startIcon={<ShoppingCartIcon />}
          onClick={() => {
            if (enableHardwareOrdering) {
              dispatch(push('/shop/cart'))
            } else {
              dispatch(openOrderCart(true))
              DialogHelper.afterOpen()
            }
          }}
        >
          <span>{translate('Cart') + (totalQuantity ? ` (${totalQuantity})` : '')}</span>
        </Button>
      ) : (
        <StyledIconButton
          style={{
            overflow: 'inherit',
            minWidth: 10,
            lineHeight: '33px',
            width: 35,
            padding: 5,
          }}
          onClick={() => {
            if (enableHardwareOrdering) {
              dispatch(push('/shop/cart'))
            } else {
              dispatch(openOrderCart(true))
              DialogHelper.afterOpen()
            }
          }}
        >
          <Tooltip title={translate('Cart')} enterDelay={300}>
            <ShoppingCartIcon style={{ fill: '#1890ff' }} />
          </Tooltip>
        </StyledIconButton>
      )}
    </div>
  )
}

export default OrderCartButton

// in src/users.js
import CloudDownloadIcon from '@material-ui/icons/CloudDownloadOutlined'
import { DefaultBreadCrumbs } from 'elements/BreadCrumbs'
import ChipsInput from 'elements/input/ChipsInput'
import { List } from 'elements/react-admin/List'
import ListActions from 'elements/react-admin/ListActions'
import { IconButton } from 'opensolar-ui'
import {
  AutocompleteInput,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  FileField,
  FileInput,
  FunctionField,
  ReferenceArrayInput,
  ReferenceInput,
  SimpleForm,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'

import { getQueryVariable, urlToId } from '../../util/misc'

export const PrivateFileList = ({ accessRights: { allowCreate, allowEdit, allowDelete }, ...props }) => {
  // var reportSelected = detectSelectedReport(props.location.pathname, props.location.search, props.recentProjects)
  // var urlOriginal = props.location.pathname + props.location.search

  return (
    <List
      breadCrumbs={<DefaultBreadCrumbs />}
      titleStyle={{ fontSize: 16, lineHeight: '16px', paddingTop: 0 }}
      actions={<ListActions createButtonLabel="Add New File" />}
      title={'Private Files'}
      filter={{ file_tags_exclude: 'Design Artifact' }}
      // title={
      //   <ProjectListTitleAndSelector
      //     location={props.location}
      //     history={props.history}
      //     search={props.location.search}
      //     recentProjects={props.recentProjects}
      //     extraActions={
      //       reportSelected.showCustomizeButton ? (
      //         <ExtraActions urlOriginal={urlOriginal} history={props.history} />
      //       ) : null
      //     }
      //   />
      // }
      {...props}
      hasCreate={allowCreate}
    >
      <Datagrid>
        <TextField source="title" />
        <DownloadIconLink />
        <FunctionField
          source={null}
          label="Actions"
          render={(record, source, rest) => (
            <div>
              {allowEdit && <EditButton useListStyle={true} record={record} basePath={rest.basePath} />}
              {allowDelete && (
                <DeleteButton
                  useListStyle={true}
                  undoable={false}
                  record={record}
                  resource={rest.resource}
                  redirect={`/${rest.resource}`}
                />
              )}
            </div>
          )}
          sortable={false}
        />
      </Datagrid>
    </List>
  )
}

export const DownloadIconLink = ({ record }) => {
  if (record.file_contents && record.file_contents.length > 0) {
    return (
      <a href={record.file_contents} target="_blank" rel="noreferrer">
        <IconButton>
          <CloudDownloadIcon />
        </IconButton>
      </a>
    )
  } else {
    return (
      <IconButton disabled={true}>
        <CloudDownloadIcon />
      </IconButton>
    )
  }
}

const inputStyle = { width: 256 }

const validateForm = (values, translate) => {
  const errors = {}
  if (!values.file_contents) {
    errors.file_contents = [translate('File is required')]
  }
  if (!values.project) {
    errors.project = [translate('Project is required')]
  }
  return errors
}

export const PrivateFileEdit = ({ accessRights: { allowView, allowCreate, allowEdit, allowDelete }, ...props }) => {
  return (
    <Edit breadCrumbs={<DefaultBreadCrumbs />} {...props} hasDelete={allowDelete}>
      <SimpleForm redirect={getRedirectLinkAfterCreate()} disabled={!allowEdit}>
        <DownloadIconLink />

        <ReferenceInput source="project" reference="projects" optionValueField="url" allowEmpty>
          <AutocompleteInput
            optionText="address"
            optionValue="url"
            options={{ style: inputStyle, placeholder: 'Enter a project' }}
          />
        </ReferenceInput>

        <ReferenceArrayInput
          source="file_tags"
          reference="file_tags"
          optionValueField="url"
          filter={{ type: 'private' }}
          perPage={50}
          filterToQuery={(searchText) => ({ search: searchText, type: 'private' })}
        >
          <ChipsInput optionText="title" optionValue="url" translateChoice={false} options={{ style: inputStyle }} />
        </ReferenceArrayInput>

        <TextInput source="title" style={inputStyle} helperText="optional" />
      </SimpleForm>
    </Edit>
  )
}

const getDefaultValuesCreate = (record) => {
  var fileTagsQueryVariable = getQueryVariable('file_tags')
  var fileTagsUrls = fileTagsQueryVariable ? fileTagsQueryVariable.split(',') : []

  return {
    project: getQueryVariable('project'),
    file_tags: fileTagsUrls,
  }
}

const getRedirectLinkAfterCreate = () => {
  var project = getQueryVariable('project')
  var project_id = project ? urlToId(project) : null
  if (project_id) {
    return '/projects/' + project_id
  } else {
    return '/private_files/'
  }
}

export const PrivateFileCreate = (props) => {
  const translate = useTranslate()
  return (
    <Create breadCrumbs={<DefaultBreadCrumbs />} {...props}>
      <SimpleForm
        validate={(values) => validateForm(values, translate)}
        defaultValue={getDefaultValuesCreate}
        redirect={getRedirectLinkAfterCreate()}
      >
        <ReferenceInput source="project" reference="projects" optionValueField="url" allowEmpty>
          <AutocompleteInput
            options={{ style: inputStyle, placeholder: 'Enter a project' }}
            optionText="address"
            optionValue="url"
          />
        </ReferenceInput>

        <FileInput
          source="file_contents"
          placeholder={<p>{translate('Click to upload or drag & drop a file here')}</p>}
        >
          <FileField source="src" style={inputStyle} title="title" />
        </FileInput>

        <ReferenceArrayInput
          source="file_tags"
          reference="file_tags"
          optionValueField="url"
          filter={{ type: 'private' }}
          perPage={50}
          filterToQuery={(searchText) => ({ search: searchText, type: 'private' })}
        >
          <ChipsInput optionText="title" optionValue="url" translateChoice={false} options={{ style: inputStyle }} />
        </ReferenceArrayInput>

        <TextInput source="title" style={inputStyle} helperText="Optional" />
      </SimpleForm>
    </Create>
  )
}

import { makeStyles } from '@material-ui/core'
import FileCloudUpload from '@material-ui/icons/CloudUploadOutlined'
import { setUploadLogo as setUploadLogoAction } from 'ducks/uploadLogo'
import { Button } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import { connect } from 'react-redux'
import { getRoleFromState } from 'util/misc'

const sizes = {
  cobrandingsmall: [100, 32],
  small: [112, 32],
  medium: [200, 50],
  large: [300, 65],
}

//hack for IE
const containerHeight = {
  small: '44px',
  medium: '65px',
  large: '100px',
}

const textHeadingStyles = {
  small: {
    fontSize: 18,
    fontWeight: 300,
    marginLeft: 0,
    marginRight: 10,
    // marginTop: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  medium: {
    fontSize: 30,
    fontWeight: 300,
    marginLeft: 0,
    marginRight: 10,
    // marginTop: 6,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  large: {
    fontSize: 34,
    fontWeight: 300,
    marginLeft: 0,
    marginRight: 10,
    // marginTop: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}

const useStyles = makeStyles((theme) => ({
  label: {
    // padding: 0,
    // marginLeft: 5,
    // fontSize: 13,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'flex',
    // maxWidth: 88,
  },
  endIcon: {
    margin: 0,
  },
  root: {
    // border: '1px solid #212121',
    // lineHeight: '32px',
    textAlign: 'start',
    // height: 35,
    // padding: '0 5px',
    // minWidth: 36,
  },
  text: {
    [theme.breakpoints.down('md')]: {
      maxWidth: 200,
    },
  },
}))

export const LogoOrTitle = ({
  org,
  skipTitle,
  skipTitleStyle,
  style,
  isAdmin,
  allowUploadButton,
  setUploadLogo,
  hideText = false,
  size = 'medium',
  showsInStudio = false,
  type,
  overrideLogo = undefined,
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const logoDisplay = overrideLogo ? overrideLogo : org?.logo_public_url
  if (!org) {
    return (
      <img
        className="logo-root"
        alt=""
        style={{
          width: sizes[size][0],
          height: sizes[size][1],
          paddingTop: 0,
        }}
        src={window.PUBLIC_URL + '/images/logo_inline_336x96.png'}
      />
    )
  } else if (logoDisplay && logoDisplay.length) {
    return (
      <div
        className="logo-root"
        style={{ zIndex: 1, lineHeight: type === 'mobilecobranding' ? null : containerHeight[size] }}
      >
        <img
          src={logoDisplay}
          id="logo-image"
          alt="Logo"
          style={Object.assign(
            {
              maxWidth: sizes[size][0],
              maxHeight: sizes[size][1],
              margin: 'auto 0px',
              verticalAlign: 'middle',
              objectFit: 'contain',
            },
            style ? style : {}
          )}
        />
      </div>
    )
  } else {
    if (skipTitle) {
      // We can choose to skip showing the title as a fallback if no logo is present
      return null
    } else {
      const uploadLogoButtonWidth = !hideText && { minWidth: 160 }
      const titleMaxWidth = showsInStudio ? '290px' : 'calc(40vw - 35px)'
      return (
        <div
          className="logo-root"
          style={skipTitleStyle ? {} : { display: 'flex', alignItems: 'center', maxWidth: titleMaxWidth }}
        >
          <div id="logo-text" className={classes.text} style={textHeadingStyles[size]}>
            {org.name}
          </div>
          {isAdmin && allowUploadButton && size !== 'small' && (
            <div>
              <Button
                variant="contained"
                color="default"
                startIcon={
                  <FileCloudUpload
                  // style={{ marginLeft: 10, width: 24, height: 24, verticalAlign: 'middle' }}
                  />
                }
                classes={classes}
                style={{
                  ...uploadLogoButtonWidth,
                }}
                className="UploadLogoButton"
                onClick={() => setUploadLogo(true)}
              >
                {!hideText && <span>{translate('Upload Logo')}</span>}
              </Button>
            </div>
          )}
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => {
  const role = getRoleFromState(state)
  return {
    isPro: Boolean(role),
    isAdmin: role ? role.is_admin : false,
  }
}

export default connect(mapStateToProps, {
  setUploadLogo: setUploadLogoAction,
})(LogoOrTitle)

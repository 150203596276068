import { CircularProgress, Dialog, DialogContent, Theme, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    '& .MuiDialog-paper': {
      width: 'unset',
      minWidth: 624,
    },
    '& .MuiDialogContent-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: '20px 0',
    },
  },
  display: {
    fontWeight: 500,
  },
}))

interface PropTypes {
  open: boolean
  displayText: string
}
//TODO: Update CircularProgress design for UI 3.0?
const LoadingDialog: React.FC<PropTypes> = ({ open, displayText }) => {
  const classes = useStyles()

  return (
    <Dialog open={open} className={classes.modal}>
      <DialogContent>
        <CircularProgress />
        <p className={classes.display}>{displayText}</p>
      </DialogContent>
    </Dialog>
  )
}
export default LoadingDialog

import type { OpenSolarThemeType } from 'Themes'
import { orgSelectors } from 'ducks/orgs'
import { getSupplierConfig } from 'pages/ordering/configs'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'

import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { useFeatureFlag } from 'util/split'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  button: {
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: '10px',
  },
}))

const AvailabilityCheckButton = ({
  onClick,
  showStockAvailability,
}: {
  onClick(): void
  showStockAvailability: boolean
}) => {
  const classes = useStyles()
  const enableHardwareOrdering = useFeatureFlag('hardware_ordering', 'on')
  const selectedSupplier = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const supplierName = getSupplierConfig(selectedSupplier)?.name

  if (selectedSupplier == null) return null
  if (!enableHardwareOrdering) return null

  const CheckButton = (
    <span className={classes.button} onClick={onClick}>
      {showStockAvailability
        ? `Refresh Stock Availability (${supplierName})`
        : `Check Stock Availability (${supplierName})`}
    </span>
  )

  return CheckButton
}

export default memo(AvailabilityCheckButton)

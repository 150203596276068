import React from 'react'
import { feetToMeters, metersToFeet } from 'util/misc'
import { CustomNumberField, NumFieldPropType } from './CustomNumberField'

type MeasurementStdType = 'imperial' | 'metric'

type DistanceFieldPropType = NumFieldPropType & {
  measurementStd: MeasurementStdType
  convertTo?: MeasurementStdType
}

const noConversion = (value: number): number => value

const ConversionMapping = {
  imperial: {
    // convert to
    imperial: noConversion,
    metric: feetToMeters,
  },
  metric: {
    // convert to
    imperial: metersToFeet,
    metric: noConversion,
  },
}

// NOTE: DistanceNumberField converts all inputs to metric (in meters), by default
// as studio units are in meters
export const DistanceNumberField = ({ measurementStd, convertTo = 'metric', ...otherProps }: DistanceFieldPropType) => {
  if (measurementStd) {
    return (
      <CustomNumberField
        {...otherProps}
        endAdornment={measurementStd === 'imperial' ? 'ft' : 'm'}
        {...{ transformFunc: convertTo ? ConversionMapping[measurementStd][convertTo] : noConversion }}
      />
    )
  } else {
    return <CustomNumberField {...otherProps} />
  }
}

import { Link as MuiLink, MenuItem } from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import Select from '@material-ui/core/Select'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { uilib_menu } from './components/UIComponentList'

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      zIndex: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
    uxSelect: {
      margin: '0 10px',
    },
    menu_item: {
      paddingLeft: 10,
    },
  })
)

const Navigator = (props) => {
  const classes = useStyles()

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <div className={classes.toolbar} />
      <Select
        variant="outlined"
        className={classes.uxSelect}
        onChange={(e) => props.setCurrentVersion(props.versions?.[e.target.value as number])}
        value={props.currentVersion ? props.versions.indexOf(props.currentVersion) : 0}
      >
        {props.versions.map((version, index) => (
          <MenuItem key={version.label} value={index}>
            {version.label}
          </MenuItem>
        ))}
      </Select>
      <List>
        {uilib_menu.map((item) => (
          <>
            <ListSubheader component="div">
              <span>{item.name}</span>{' '}
              {item.info_url && (
                <MuiLink href={item.info_url} target="_blank">
                  (info)
                </MuiLink>
              )}
            </ListSubheader>
            {item.children.map((item) => (
              <ListItem button key={item.name} component={Link} to={item.route}>
                <ListItemText className={classes.menu_item} primary={item.name} />
              </ListItem>
            ))}
          </>
        ))}
      </List>
    </Drawer>
  )
}

export default Navigator

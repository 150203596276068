import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  inputSelectTextColor: {
    color: theme.ValidityPalette.default.color,
  },
  displayFlex: {
    display: 'flex',
  },
  gap6: {
    gap: '6px',
  },
  items: {
    flexDirection: 'column',
    gap: '6px',
  },
  radioButtonPosition: {
    display: 'flex',
    flexDirection: 'row',
  },
  radioGroupPosition: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: 'auto',
  },
  radioGap: {
    marginBottom: 16,
  },
  tooltipContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    width: '252px',
  },
  tooltipIcon: {
    height: 14,
    width: 14,
  },
  marginLeft3: {
    marginLeft: 3,
  },
  tooltipArrow: {
    '&::before': {
      backgroundColor: '#0A090B',
      color: '#0A090B',
    },
  },
  tooltip: {
    backgroundColor: '#0A090B',
    color: theme.white,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
  },
  measureLength: {
    color: theme.secondaryColor,
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '16px',
    marginTop: '6px',
    cursor: 'pointer',
  },
  scaffoldingCostNote: {
    display: 'flex',
    padding: '12px',
    flexDirection: 'column',
    gap: '16px',
    backgroundColor: theme.greyLight1,
    borderRadius: '6px',
  },
  fullWidth: {
    width: '100%',
  },
  noteTitle: {
    color: theme.greyDark2,
    fontWeight: 500,
    fontSize: '10px',
  },
  noteText: {
    color: '#4D4D4D',
    fontWeight: 400,
    fontSize: '10px',
  },
  marginTop8: {
    marginTop: '8px',
  },
  actionButton: {
    width: '100%',
    backgroundColor: 'rgba(255, 216, 0, 1)',
    border: 'none',
  },
  costContainer: {
    backgroundColor: theme.greyLight1,
    borderRadius: '6px',
    padding: '6px',
  },
  costDisplay: {
    display: 'flex',
    padding: '6px',
    gap: '16px',
    justifyContent: 'space-between',
    borderRadius: '6px',
  },
  errorText: {
    color: theme.palette.error.main,
  },
  boldDescription: {
    fontSize: '12px',
    fontWeight: 550,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
}))

export default useStyles

import { useCallback, useMemo } from 'react'
import type {
  FilterComponentRendererType,
  FilterGenericPropsType,
  FilterKeysType,
  FilterValuesType,
  OptionGenericType,
} from '../type'

export type FilterOptionsType = OptionGenericType<string>[]

type FilterProps = FilterGenericPropsType & {
  RendererComponent: FilterComponentRendererType<FilterOptionsType>
  filterKey: FilterKeysType
}

export type InverterType = 'microinverter' | 'hybrid' | 'string'

const DEFAULT_OPTION = { id: 'default', title: 'All', value: '' }
export const MICRO_OPTION = {
  id: 'microinverter',
  title: 'Microinverter',
  value: 'microinverter',
}
export const HYBRID_OPTION = { id: 'hybrid', title: 'Hybrid', value: 'hybrid' }
export const STRING_OPTION = { id: 'string', title: 'String / Other', value: 'string' }

const INVERTER_TYPE_OPTIONS: FilterOptionsType = [DEFAULT_OPTION, MICRO_OPTION, HYBRID_OPTION, STRING_OPTION]

const mapFilterValueToInverterTypeOption = (filterValues: FilterValuesType) => {
  if (filterValues[HYBRID_OPTION.value] === 'Y') {
    return HYBRID_OPTION.value
  } else if (filterValues[MICRO_OPTION.value] === 'Y') {
    return MICRO_OPTION.value
  } else if (filterValues[HYBRID_OPTION.value] === 'N' && filterValues[MICRO_OPTION.value] === 'N') {
    return STRING_OPTION.value
  }
  return undefined
}

const InverterTypeFilter = ({
  filterKey,
  modifiableFilterValues,
  persistentFilterValues,
  allFilterValues,
  setModifiableFilters,
  RendererComponent,
}: FilterProps) => {
  const onSelect = useCallback(
    (value) => {
      const newFilterValues = { ...modifiableFilterValues }
      delete newFilterValues[MICRO_OPTION.value]
      delete newFilterValues[HYBRID_OPTION.value]

      if (value === HYBRID_OPTION.value) {
        newFilterValues[HYBRID_OPTION.value] = 'Y'
      } else if (value === MICRO_OPTION.value) {
        newFilterValues[MICRO_OPTION.value] = 'Y'
      } else if (value === STRING_OPTION.value) {
        newFilterValues[HYBRID_OPTION.value] = 'N'
        newFilterValues[MICRO_OPTION.value] = 'N'
      }
      setModifiableFilters(newFilterValues)
    },
    [modifiableFilterValues]
  )

  const selectedValue = useMemo(() => mapFilterValueToInverterTypeOption(allFilterValues), [allFilterValues])

  return (
    <RendererComponent
      id={filterKey}
      defaultValue={''}
      label={'Inverter Type'}
      options={INVERTER_TYPE_OPTIONS}
      disabled={false}
      onSelect={onSelect}
      value={selectedValue}
    />
  )
}

export default InverterTypeFilter

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { default as Mui4Radio } from '@material-ui/core/Radio';
import { makeStyles } from '@material-ui/core/styles';
import AdjustIcon from '@material-ui/icons/Adjust';
import { default as Mui5Radio } from '@mui/material/Radio';
import classnames from 'classnames';
import { Inspector } from '../debug/Inspector';
import { useComponentVersion } from '../hooks/useComponentVersion';
var useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
        color: 'rgba(0, 0, 0, 0.25)',
    },
    checkedIcon: {
        '&.MuiSvgIcon-root': {
            color: '#FFD800',
        },
    },
    v2root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    v2icon: {
        // Style for the default icon
        borderRadius: '50%',
        width: 20,
        height: 20,
        boxShadow: 'inset 0 0 0 1.5px #DCDCDE',
        backgroundColor: 'transparent',
        'input:hover ~ &': {
            backgroundColor: 'fff',
            boxShadow: 'inset 0 0 0 1.5px #6C92E4',
        },
    },
    v2checkedIcon: {
        // Style for the checked icon
        backgroundColor: '#1751D0',
        boxShadow: 'inset 0 0 0 1.5px #1751D0',
        '&:before': {
            display: 'block',
            width: 8,
            height: 8,
            backgroundColor: 'white',
            borderRadius: '50%',
            content: '""',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
        },
        'input:hover ~ &': {
            backgroundColor: '#113B98',
            boxShadow: 'inset 0 0 0 1.5px #113B98',
        },
        'input:focus ~ &': {
            outline: '1px solid white',
            boxShadow: '0px 0px 0px 3px #618DF2, 0px 0px 0px 4px #FFF',
        },
    },
});
export var Radio = function (props) {
    return _jsx(Inspector, { name: "Radio", propDefs: RadioPropDefs, showExample: true, component: OsRadio, props: props });
};
export var OsRadioVersion;
(function (OsRadioVersion) {
    OsRadioVersion[OsRadioVersion["V1"] = 1] = "V1";
    OsRadioVersion[OsRadioVersion["V2"] = 2] = "V2";
})(OsRadioVersion || (OsRadioVersion = {}));
export var OsRadio = function (props) {
    var classes = useStyles();
    var version = useComponentVersion('radio');
    switch (version) {
        case OsRadioVersion.V2:
            var mui5props = __assign(__assign({}, props), { className: classnames(classes.v2root, props.className), checkedIcon: _jsx("span", { className: "".concat(classes.v2icon, " ").concat(classes.v2checkedIcon) }), icon: _jsx("span", { className: classes.v2icon }), color: props.color || 'default' });
            return _jsx(Mui5Radio, __assign({ disableRipple: true }, mui5props));
        default:
            var color = props.color, rest = __rest(props, ["color"]);
            var color4 = color === 'primary' || color === 'secondary' ? color : 'default';
            return (_jsx(Mui4Radio, __assign({ className: classes.root, disableRipple: true, color: color4, checkedIcon: _jsx(AdjustIcon, { className: props.checked ? classes.checkedIcon : '' }) }, rest)));
    }
};
var RadioPropDefs = {
    defaultChecked: { default: false },
    checked: { hidden: true },
    size: { default: 'medium' },
    color: { default: 'default' },
    checkedIcon: { hidden: true },
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.client_check = void 0;
function client_check(domains_whitelist, nested, referrer, logger) {
    if (logger === void 0) { logger = console; }
    if (!nested)
        return true;
    if (!referrer) {
        logger.error('No referrer found, client_check failed!');
        return false;
    }
    var url = new URL(referrer);
    var domain = url.host;
    var ret = domains_whitelist.some(function (d) {
        // Change from wildcard syntax to regex syntax
        // 1. Escape all dots
        var regstr = d.replace(/\./g, '\\.');
        // 2. Replace * with .*
        regstr = regstr.replace(/\*/g, '.*');
        var regex = new RegExp("^".concat(regstr, "$"));
        var ret = !!domain.match(regex);
        if (ret) {
            logger.debug('referrer domain passed client_check: ', domain, d);
        }
        return ret;
    });
    if (!ret) {
        logger.error('referrer domain failed client_check: ', domain, domains_whitelist);
    }
    return ret;
}
exports.client_check = client_check;

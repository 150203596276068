import Alert from 'elements/Alert'
import React, { memo, useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { dialogStyles } from '../styles'
import { ScheduleType } from '../types'
import DetailTable from './DetailTable'
import KeyLegend from './KeyLegend'
import validateSchedule, { errorMap } from './validateSchedule'

export const ScheduleAlert = ({ errors }) => {
  const translate = useTranslate()
  return (
    <Alert severity={'warning'}>
      {errors?.includes(errorMap.noDateRange) && <div>{translate('Please set a date range.')}</div>}
      {errors?.includes(errorMap.noHoursSelected) && (
        <div>{translate('Please assign times in the Applicable Days and Hours table.')}</div>
      )}
      {errors?.includes(errorMap.overlapping) && (
        <div>
          {translate(
            'Time blocks selected is also used by another schedule. Please ensure no two schedules are assigned to same time periods.'
          )}
        </div>
      )}
    </Alert>
  )
}

const ScheduleSummary = memo<{ state: ScheduleType; unavailableSlots: ScheduleType[]; showSaveError: boolean }>(
  ({ state, unavailableSlots, showSaveError }) => {
    const [showAlert, setShowAlert] = useState<string[]>([])
    const classes = dialogStyles()
    useEffect(() => {
      setShowAlert(validateSchedule(state, unavailableSlots))
    }, [state, unavailableSlots])
    const showError =
      showAlert.length > 0 && (showSaveError || (showAlert.length === 1 && showAlert.includes(errorMap.overlapping)))
    return (
      <div>
        {showError && <ScheduleAlert errors={showAlert} />}
        <div className={`${classes.row} ${classes.summaryContainer}`}>
          <KeyLegend showAlert={showAlert} />
          <DetailTable state={state} />
        </div>
      </div>
    )
  }
)

export default ScheduleSummary

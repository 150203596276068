import { terminateCurrentTour, updateTourStep } from 'actions/tourActions'
import { removeTourBanner } from 'ducks/auth'
import TourRide from 'persistentContent/tours/tools/TourRide'
import { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PROJECT_FUJI_TOUR_STEPS } from './steps'
interface PropTypes {
  toggleEndDialog: () => void
}
const ProjectFujiTour: FC<PropTypes> = ({ toggleEndDialog }) => {
  const dispatch = useDispatch()
  const { tour, step } = useSelector((state: any) => state.tour)

  const stepIndex = useMemo(() => {
    const findStep = PROJECT_FUJI_TOUR_STEPS.find((x) => x.step === step)
    if (findStep) return PROJECT_FUJI_TOUR_STEPS.indexOf(findStep)
    return 0
  }, [step])

  const handleNext = () => {
    let nextStepIndex = stepIndex + 1
    let element: HTMLElement | null = null

    while (nextStepIndex < PROJECT_FUJI_TOUR_STEPS.length && !element) {
      const configs = PROJECT_FUJI_TOUR_STEPS[nextStepIndex].config
      const spotlights: any = configs.spotlights
      const targetId = spotlights[0].targetId
      element = document.getElementById(targetId)
      if (!element) {
        nextStepIndex += 1
      }
    }
    if (element && nextStepIndex < PROJECT_FUJI_TOUR_STEPS.length) {
      const nextStep = PROJECT_FUJI_TOUR_STEPS[nextStepIndex].step
      if (nextStep === 'projectListSort') {
        openDrawer(nextStepIndex)
        dispatch(updateTourStep(tour, nextStep))
      } else if (nextStep === 'projectListSettings') {
        closeDrawer(nextStepIndex - 1)
        openDrawer(nextStepIndex)
        dispatch(updateTourStep(tour, nextStep))
      } else {
        dispatch(updateTourStep(tour, nextStep))
      }
    } else if (stepIndex + 1 === PROJECT_FUJI_TOUR_STEPS.length) {
      closeDrawer(stepIndex)
      dispatch(terminateCurrentTour(tour))
      toggleEndDialog()
    }
  }

  const handlePrev = () => {
    let prevStepIndex = stepIndex - 1
    let element: HTMLElement | null = null

    while (prevStepIndex >= 0 && !element) {
      const configs = PROJECT_FUJI_TOUR_STEPS[prevStepIndex].config
      const spotlights: any = configs.spotlights
      const targetId = spotlights[0].targetId
      element = document.getElementById(targetId)
      if (!element) {
        prevStepIndex -= 1
      }
    }

    if (element && prevStepIndex >= 0) {
      const prevStep = PROJECT_FUJI_TOUR_STEPS[prevStepIndex].step
      if (prevStep === 'projectList') {
        closeDrawer(prevStepIndex + 1)
        dispatch(updateTourStep(tour, prevStep))
      } else if (prevStep === 'projectListSort') {
        openDrawer(prevStepIndex)
        closeDrawer(prevStepIndex + 1)
        dispatch(updateTourStep(tour, prevStep))
      } else if (prevStep === 'projectListSettings') {
        openDrawer(prevStepIndex)
        dispatch(updateTourStep(tour, prevStep))
      } else {
        dispatch(updateTourStep(tour, prevStep))
      }
    }
  }

  function openDrawer(index) {
    const configs = PROJECT_FUJI_TOUR_STEPS[index].config
    const spotlights: any = configs.spotlights
    const targetId = spotlights[0].targetId
    const id = targetId.split('-Inner')[0]
    const drawer = document.getElementById(id) as HTMLElement
    const drawerF = drawer?.firstElementChild as HTMLElement
    if (drawer && drawerF) {
      drawerF.style.visibility = 'visible'
      drawerF.style.transform = 'translateX(0)'
    }
  }

  function closeDrawer(index) {
    const configs = PROJECT_FUJI_TOUR_STEPS[index].config
    const spotlights: any = configs.spotlights
    const targetId = spotlights[0].targetId
    const id = targetId.split('-Inner')[0]
    const drawer = document.getElementById(id) as HTMLElement
    const drawerF = drawer?.firstElementChild as HTMLElement
    if (drawer && drawerF) {
      drawerF.style.visibility = 'hidden'
    }
  }

  const handleCancel = () => {
    const step = PROJECT_FUJI_TOUR_STEPS[stepIndex].step
    if (step === 'projectListSort' || step === 'projectListSettings') {
      closeDrawer(stepIndex)
    }
    dispatch(removeTourBanner(true, 'project_list'))
    dispatch(terminateCurrentTour(tour))
  }

  return (
    <TourRide
      steps={PROJECT_FUJI_TOUR_STEPS}
      stepIndex={stepIndex}
      visible={tour === 'fuji_project'}
      handleNext={handleNext}
      handlePrev={stepIndex === 0 ? undefined : handlePrev}
      handleNextLabel={stepIndex + 1 === PROJECT_FUJI_TOUR_STEPS.length ? 'Finish' : 'Next'}
      handleCancel={handleCancel}
    />
  )
}

export default ProjectFujiTour

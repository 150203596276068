import { Tooltip, makeStyles, useMediaQuery } from '@material-ui/core'
import { CheckOutlined } from '@material-ui/icons'
import { COLOR_PALETTE } from 'elements/input/styles'
import { PaymentRequestType } from 'pages/cashFlow/types'
import { useTranslate } from 'ra-core'
import React, { useCallback, useMemo, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { ExpectedMilestonePaymentType, PaymentOptionDataType } from 'types/paymentOptions'
import { Theme } from 'types/themes'
import { currencySymbolForCountry, formatCurrencyWithSymbol } from 'util/misc'
import SendInvoiceButton from '../../cashFlowTransactions/invoiceActions/SendInvoiceBtn'
import { useGetCashflowCustomerInfoError } from '../../cashFlowTransactions/utils'
import PaymentRequestCopyLink from './milestoneOverride/PaymentRequestCopyLink'
import PaymentRequestStatusChip from './milestoneOverride/PaymentRequestStatusChip'
import { useGetLastPaymentRequestForMilestone } from './utils'

type PropTypes = {
  milestone: ExpectedMilestonePaymentType
  paymentOption: PaymentOptionDataType | undefined
  systemUuid: string | undefined
  refreshMilestones: () => void
  isLast: boolean
  allPaymentRequests: PaymentRequestType[]
  displayedInWidget?: boolean | false
}

const useStyles = makeStyles<
  Theme,
  {
    isMobile: boolean
    isSentNotPaid?: boolean
    isNext?: boolean
    isPaid?: boolean
    isLast?: boolean
    displayedInWidget?: boolean
  }
>((theme) => ({
  milestoneWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: ({ isMobile }) => (isMobile ? 'center' : 'flex-start'),
    padding: ({ isMobile }) => (isMobile ? '10px 0px 20px 0px' : '0px 40px 0px 0px'),
    paddingRight: ({ isLast }) => (isLast ? '0px' : '40px'),
    position: 'relative',
    minWidth: '100px',
    height: ({ displayedInWidget }) => (displayedInWidget ? '140px' : '150px'),
  },
  iconWrapper: {
    zIndex: 5,
  },
  milestoneTitle: {
    fontWeight: 500,
    color: ({ isNext, isPaid, isSentNotPaid }) => {
      if (isNext) {
        return '#4272DD'
      } else if (isPaid || isSentNotPaid) {
        return theme.greyDark2
      } else {
        return '#ADACB0'
      }
    },
  },
  pmtAmount: {
    fontSize: '15px',
    marginBottom: '5px',
    color: ({ isNext, isPaid, isSentNotPaid }) => {
      if (isNext || isPaid || isSentNotPaid) {
        return theme.greyDark2
      } else {
        return '#ADACB0'
      }
    },
  },
  milestoneRow: {
    textAlign: 'left',
    padding: '3px 0px',
  },
  statusAndButtonRow: {
    display: 'flex',
  },
  invoicePaid: {
    color: theme.alertIcon_success,
  },
  invoiceSent: {
    color: '#4272DD',
    fontSize: '12px',
  },
  blueCheck: {
    height: '16px',
    width: '16px',
    color: 'white',
    backgroundColor: '#1751D0',
    borderRadius: '50px',
    padding: '11px',
    marginBottom: '6px',
  },
  blueCircle: {
    borderRadius: '50px',
    backgroundColor: '#4272DD',
    color: 'white',
    height: '36px',
    width: '36px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    marginBottom: '11px',
  },
  grayCircle: {
    borderRadius: '50px',
    backgroundColor: COLOR_PALETTE.lightGrey,
    color: theme.greyDark2,
    height: '36px',
    width: '36px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    marginBottom: '8px',
  },
  nextCircle: {
    borderRadius: '50px',
    backgroundColor: '#1751D0',
    color: theme.white,
    height: '36px',
    width: '36px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    marginTop: '-3px',
    border: '2px solid #618DF2',
    backgroundClip: 'content-box',
    padding: '2px',
    marginBottom: '5px',
  },
  blueBtn: {
    backgroundColor: '#4272DD',
    color: 'white',
  },
  timelineBar: {
    position: 'absolute',
    top: '16px',
    left: '0px',
    right: '0px',
    height: '4px',
    backgroundColor: ({ isPaid }) => {
      if (isPaid) {
        return '#4272DD'
      } else {
        return COLOR_PALETTE.lightGrey
      }
    },
    zIndex: 4,
  },
}))

const MilestoneCell: React.FC<PropTypes> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const project = useFormState()?.values
  const form = useForm()
  const isPendingSave = form.mutators.getFormDirtyFields()?.length > 0
  const currencySymbol = currencySymbolForCountry(project.country_iso2)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const translate = useTranslate()

  const hasMissingRequiredCustomerInfo = useGetCashflowCustomerInfoError()
  const lastPaymentRequestForMilestone = useGetLastPaymentRequestForMilestone(props.allPaymentRequests, props.milestone)

  const showTimelineBar = useMemo(() => {
    return !props.isLast && !isMobile
  }, [props.milestone, isMobile])

  const isSentNotPaid = useMemo(() => {
    return !props.milestone.is_paid && Boolean(props.milestone.payment_request_id)
  }, [props.milestone.is_paid, props.milestone.payment_request_id])

  const renderIcon = useCallback(() => {
    if (props.milestone.is_paid) {
      return <CheckOutlined className={classes.blueCheck} />
    } else if (isSentNotPaid) {
      return <div className={classes.blueCircle}>{props.milestone.payment_number}</div>
    } else if (props.milestone.is_next) {
      return <div className={classes.nextCircle}>{props.milestone.payment_number}</div>
    } else {
      return <div className={classes.grayCircle}>{props.milestone.payment_number}</div>
    }
  }, [isSentNotPaid, props.milestone.is_paid, props.milestone.payment_number])

  const statusDate = useMemo(() => {
    if (lastPaymentRequestForMilestone) {
      if (lastPaymentRequestForMilestone.status === 'pending' && lastPaymentRequestForMilestone.date_completed) {
        return new Date(lastPaymentRequestForMilestone.date_completed).toLocaleDateString()
      } else if (
        lastPaymentRequestForMilestone.status === 'requested' &&
        lastPaymentRequestForMilestone.date_requested
      ) {
        return new Date(lastPaymentRequestForMilestone.date_requested).toLocaleDateString()
      } else if (lastPaymentRequestForMilestone.status === 'paid in full') {
        if (lastPaymentRequestForMilestone.date_paid_out) {
          return new Date(lastPaymentRequestForMilestone.date_paid_out).toLocaleDateString()
        } else if (lastPaymentRequestForMilestone.date_completed) {
          return new Date(lastPaymentRequestForMilestone.date_completed).toLocaleDateString()
        }
      }
    } else {
      if (props.milestone.is_paid && props.milestone.date_completed) {
        return new Date(props.milestone.date_completed).toLocaleDateString()
      } else if (isSentNotPaid && props.milestone.date_requested) {
        return new Date(props.milestone.date_requested).toLocaleDateString()
      }
    }
  }, [props.milestone, isSentNotPaid])

  const sendInvoiceTooltip = useMemo(() => {
    if (isPendingSave) {
      return translate('Save changes before sending invoice')
    } else if (hasMissingRequiredCustomerInfo) {
      return translate(
        'Please fill out missing required customer and/or project address details before sending invoice'
      )
    }
    return ''
  }, [isPendingSave, hasMissingRequiredCustomerInfo])

  const showCopyLink = useMemo(() => {
    if (lastPaymentRequestForMilestone?.status === 'payment failed') return true
    return (
      lastPaymentRequestForMilestone &&
      !props.milestone.is_next &&
      lastPaymentRequestForMilestone.status &&
      lastPaymentRequestForMilestone.status !== 'unsent' &&
      lastPaymentRequestForMilestone.amount_paid <= 0
    )
  }, [lastPaymentRequestForMilestone, props.milestone.is_next])

  const isPaid = props.milestone.is_paid || false
  const isNext = props.milestone.is_next || false
  const classes = useStyles({
    isMobile,
    isSentNotPaid,
    isNext,
    isPaid,
    isLast: !showTimelineBar,
    displayedInWidget: props.displayedInWidget,
  })

  return (
    <div className={classes.milestoneWrapper}>
      <div className={classes.iconWrapper}>{renderIcon()}</div>

      <div className={classes.milestoneRow}>
        <div className={classes.milestoneTitle}>{props.milestone.title}</div>
      </div>

      <div className={classes.milestoneRow}>
        <div className={classes.pmtAmount}>
          {formatCurrencyWithSymbol(props.milestone.payment_amount, currencySymbol, undefined, 0)}
        </div>
      </div>
      <div className={classes.statusAndButtonRow}>
        {props.milestone.is_next && (
          <Tooltip title={sendInvoiceTooltip} placement="top">
            <div>
              {props.paymentOption?.org_id && props.systemUuid && lastPaymentRequestForMilestone && (
                <SendInvoiceButton
                  label="invoice"
                  actionType="invoice"
                  blueBtn={true}
                  projectId={project?.id}
                  systemUuid={props.systemUuid}
                  paymentRequest={lastPaymentRequestForMilestone}
                  disableButtons={Boolean(sendInvoiceTooltip)}
                />
              )}
            </div>
          </Tooltip>
        )}
        {lastPaymentRequestForMilestone &&
          !props.milestone.is_next &&
          lastPaymentRequestForMilestone.status &&
          lastPaymentRequestForMilestone.status !== 'unsent' && (
            <PaymentRequestStatusChip paymentRequest={lastPaymentRequestForMilestone} extraText={statusDate} />
          )}
        {showCopyLink && lastPaymentRequestForMilestone?.payment_collection_url && (
          <PaymentRequestCopyLink url={lastPaymentRequestForMilestone.payment_collection_url} method="copied" />
        )}
      </div>
      {showTimelineBar && <div className={classes.timelineBar} />}
    </div>
  )
}
export default MilestoneCell

// @ts-nocheck
import Grid from '@material-ui/core/Grid'
import CustomField from 'elements/field/CustomField'
import ChipsInput from 'elements/input/ChipsInput'
import React from 'react'
import { FileField, FileInput, ReferenceArrayInput, required, TextInput, useTranslate } from 'react-admin'
import { PrivateFileType } from 'types/privateFile'

type PropTypes = {
  record?: PrivateFileType
}

const NewFileform: React.FunctionComponent<PropTypes> = (props) => {
  const translate = useTranslate()
  const validateFileUpload = [required()]
  const eventInputStyle = {
    width: '100%',
  }
  return (
    <>
      <Grid item xs={12}>
        <CustomField
          style={{ ...eventInputStyle }}
          component={TextInput}
          label="resources.file_tags.fields.title"
          name="title"
          source="title"
        />
      </Grid>
      <Grid item xs={12}>
        <ReferenceArrayInput
          label="resources.file_tags.fields.file_tags"
          source="file_tags"
          reference="file_tags"
          optionValueField="url"
          filter={{ type: 'private' }}
          perPage={50}
          filterToQuery={(searchText: string) => ({ search: searchText, type: 'private' })}
          fullWidth={true}
          renderChildrenBeforeRecord
          defaultValue={[]}
        >
          {/* @ts-ignore */}
          <ChipsInput
            translateChoice={true}
            optionText="title"
            optionValue="url"
            fullWidth={true}
            showSpinnerWhenRefreshing
          />
        </ReferenceArrayInput>
      </Grid>
      <Grid item xs={12}>
        <FileInput
          source="file_contents"
          validate={validateFileUpload}
          placeholder={<p>{translate('Click to upload or drag & drop a file here')}</p>}
          label="resources.file_tags.fields.file_contents"
        >
          <FileField source="src" style={{ width: '100%' }} title="title" />
        </FileInput>
      </Grid>
    </>
  )
}

export default NewFileform

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import createStandardIcon from './createStandardIcon';
var BarChartSquareIconSvg = function (props) {
    var fill = props.fill, color = props.color, transform = props.transform, rest = __rest(props, ["fill", "color", "transform"]);
    return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 22 22", fill: "none" }, rest, { style: { transform: transform } }, { children: _jsx("path", { d: "M6.20039 15.8V13.4M11.0004 15.8V11M15.8004 15.8V6.20002M3.80039 20.6C2.47491 20.6 1.40039 19.5255 1.40039 18.2V3.80002C1.40039 2.47454 2.47491 1.40002 3.80039 1.40002H18.2004C19.5259 1.40002 20.6004 2.47454 20.6004 3.80002V18.2C20.6004 19.5255 19.5259 20.6 18.2004 20.6H3.80039Z", stroke: color, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }) })));
};
export var BarChartSquareIcon = createStandardIcon(BarChartSquareIconSvg);

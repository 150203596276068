import { useCallback, useMemo } from 'react'
import { useNotify } from 'react-admin'
import restClient from 'restClient'
import CityPlumbingService from './CityPlumbingService'
import { setAuthRedirection, storeAuthRedirectionToLocalStorage } from '../../redirections/authRedirectionStore'
import { useAuth } from 'react-oidc-context'

const restClientInstance = restClient(window.API_ROOT + '/api')
const useCityPlumbingService = (): CityPlumbingService => {
  const notify = useNotify()
  const auth = useAuth()

  const cityPlumbingService = useMemo(() => {
    const service = new CityPlumbingService(restClientInstance, notify)

    // Override the empty connect method because signinRedirect is a hook and cannot be used within a class
    service.connect = async () => {
      setAuthRedirection({
        type: 'AUTH_SUCCESS_REDIRECT',
        redirect: '/shop/after_connect/city_plumbing',
      })
      storeAuthRedirectionToLocalStorage()
      await auth?.signinRedirect()
    }

    return service
  }, [])

  return cityPlumbingService
}

export default useCityPlumbingService

import ProUXButton from 'elements/proUXButtons/ProUXButton'
import React from 'react'
import { useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useForm } from 'react-final-form'

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'absolute',
    bottom: '0px',
    left: '0px',
    right: '0px',
    backgroundColor: '#fff',
    padding: '10px',
    zIndex: 900,
    borderTop: '1px solid #ececec',
  },
  errorWrapper: {
    marginBottom: '15px',
  },
  errorText: {
    color: 'red',
    textAlign: 'center',
  },
  buttonsWrapperDesktop: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-right',
    width: '100%',
  },
  buttonsWrapperMobile: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  buttonWrapper: {
    flex: 1,
    marginLeft: '15px',
    display: 'flex',
    justifyContent: 'center',
  },
})

type PropTypes = {
  submitApplication: (formVals: Object) => void
  loading: boolean
  onClose: () => void
  errorMsg?: string
}

const MosaicApplicationFooter: React.FunctionComponent<PropTypes> = (props) => {
  const classes = useStyles()
  const form = useForm()
  const isMobile = useMediaQuery('(max-width:1080)')
  const onSubmit = () => {
    const vals = form.getState().values
    props.submitApplication(vals)
  }
  return (
    <div className={classes.main}>
      <div className={classes.errorWrapper}>
        {props.errorMsg && <span className={classes.errorText}>{props.errorMsg}</span>}
      </div>
      <div className={isMobile ? classes.buttonsWrapperMobile : classes.buttonsWrapperDesktop}>
        <div className={classes.buttonWrapper}>
          <ProUXButton type="secondary" onClick={props.onClose} label="Cancel" />
        </div>
        <div className={classes.buttonWrapper}>
          <ProUXButton
            type="primary"
            onClick={onSubmit}
            label="Apply Now"
            disabled={props.loading}
            loading={props.loading}
          />
        </div>
      </div>
    </div>
  )
}
export default MosaicApplicationFooter

import { BomGeneratorAbstract } from '../bom_generators/BomGeneratorAbstract'
import {
  PerColumnRailBomGeneratorAbstract,
  VerticalRailPosition,
} from '../bom_generators/PerColumnRailBomGeneratorAbstract'
import {
  HorizontalRailPosition,
  PerRowRailBomGeneratorAbstract,
} from '../bom_generators/PerRowRailBomGeneratorAbstract'
import {
  BasicSpacingLayoutAdjusterAbstract,
  SpacingRuleSet,
} from '../layout_adjusters/BasicSpacingLayoutAdjusterAbstract'
import { MountingSystemAbstract } from '../MountingSystemAbstract'
import { CutAndSplicerAbstract } from '../post_processors/CutAndSplicerAbstract'
import { RoofHookAdderAbstract } from '../post_processors/RoofHookAdderAbstract'
import { getRailDirection } from '../railing'
import {
  CompatibilityParameters,
  Component,
  Direction,
  Fastener,
  Item,
  MountingCalcInput,
  MountingCalcResult,
  MountingComponentType,
  Panel,
  PanelBlock,
  RailComponent,
  RailType,
} from '../types'

// LIMITATIONS / Rules that haven't been implemented
// There should be a maximum contiuous rail length of 12m, so an interruption should be planned in the panel spacing
// Systems can also be designed with two layers of railing instead of one. This is useful when the clearance of rafters doesn't fit with the fixing points of modules
// You can add anti-slip protection to the lowest row

const spacingRuleSet: SpacingRuleSet = {
  interPanel: { horizontalSpacing: 24, verticalSpacing: 24 },
  // thermal: {} // NOT YET IMPLEMENTED. But an interuption should be planned every 12m
}
class VariosoleSpacingAdjuster extends BasicSpacingLayoutAdjusterAbstract {
  spacingRuleSet = spacingRuleSet
}

const railOverhang = 100 // Rail pokes out 100mm each side. Minimum allowed for Variosole is 50mm, maximum is 300mm

class VariosoleBomGenerator extends BomGeneratorAbstract {
  async generateBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    const starterBom = await this.generateStarterBom(result)
    return this.postProcess(starterBom)
  }

  generateStarterBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    if (getRailDirection(this.block.orientation, this.input.options) === 'horizontal') {
      return this.generateBomFrom(VariosoleHorizontalRailBomGenerator, result)
    } else {
      return this.generateBomFrom(VariosoleVerticalRailBomGenerator, result)
    }
  }

  postProcess(result: MountingCalcResult): MountingCalcResult {
    const resultWithRoofHooks = new VariosoleRoofHookAdder(this.input).process(result)
    const resultWithGeniusKits = addGeniusKits(this.input, resultWithRoofHooks)
    const resultWithSplices = new VariosoleRailSplicer(this.input).process(resultWithGeniusKits)
    return bundleItems(this.input, resultWithSplices)
  }
}

export class Variosole extends MountingSystemAbstract {
  layoutAdjusterClass = VariosoleSpacingAdjuster
  bomGeneratorClass = VariosoleBomGenerator

  // getFastener is used to calculate the structural inputs which in turn dictate the number of roof hooks.
  // Note that fasteners may vary between module groups, but here we are just picking one as a default.
  getFastener(): Fastener {
    const railDirection = getRailDirection(this.input.panelBlocks[0].moduleGrid.moduleLayout(), this.input.options)
    return chooseFastener(chooseHook(this.input, null, chooseRail(this.input, railDirection)))
  }

  getCompatibilityParameters(): CompatibilityParameters {
    return {
      integrations: ['Segen'],
      slopeRange: [20, 60], // 5-60 for solar fasteners but 20-60 for roof hooks. Also depends on tile type for pitches below 20
      moduleThicknessRange: [30, 50],
      roofTypes: ['Tile Clay', 'Tile Concrete', 'Tile Slate'],
      roofTypeRequired: false,
      featureFlag: 'mounting_Variosole',
    }
  }
}

class VariosoleHorizontalRailBomGenerator extends PerRowRailBomGeneratorAbstract {
  // Horizontal and vertical railing use the same components, so we have shared functions

  chooseRail() {
    return chooseRail(this.input, 'horizontal')
  }

  chooseMidClamp() {
    return chooseMidClamp(this.input)
  }

  chooseEndClamp() {
    return chooseEndClamp(this.input)
  }

  chooseEndCap() {
    return chooseEndCap(this.input)
  }

  getRailPositions(panel: Panel): HorizontalRailPosition[] {
    // Space the rails at 60% of panel height. No distance is advised in Renusol's documentation.
    const distanceFromRailToPanelEdge = (panel.height * 0.6) / 2

    return [
      {
        top: distanceFromRailToPanelEdge,
        left: -railOverhang,
        right: railOverhang,
      },
      {
        top: panel.height - distanceFromRailToPanelEdge,
        left: -railOverhang,
        right: railOverhang,
      },
    ]
  }
}

class VariosoleVerticalRailBomGenerator extends PerColumnRailBomGeneratorAbstract {
  chooseRail() {
    return chooseRail(this.input, 'vertical')
  }

  chooseMidClamp() {
    return chooseMidClamp(this.input)
  }

  chooseEndClamp() {
    return chooseEndClamp(this.input)
  }

  chooseEndCap() {
    return chooseEndCap(this.input)
  }

  getRailPositions(panel: Panel): VerticalRailPosition[] {
    // Space the rails at 60% of panel height. No distance is advised in Renusol's documentation.
    const distanceFromRailToPanelEdge = (panel.width * 0.6) / 2

    return [
      {
        left: distanceFromRailToPanelEdge,
        top: -railOverhang,
        bottom: railOverhang,
      },
      {
        left: panel.width - distanceFromRailToPanelEdge,
        top: -railOverhang,
        bottom: railOverhang,
      },
    ]
  }
}

function chooseRail(input: MountingCalcInput, direction: Direction): RailComponent {
  const componentCode =
    input.options.railLength === 3300
      ? input.options.railColour === 'black'
        ? '400524-B'
        : '400524'
      : input.options.railColour === 'black'
      ? '400525-B'
      : '400525'
  return {
    type: MountingComponentType.RAIL,
    railType: 'continuous',
    direction,
    name: componentCode,
    length: 0,
    top: 0,
    left: 0,
  }
}

function chooseMidClamp(input: MountingCalcInput): Item {
  const componentCode = input.options.clampColour === 'black' ? '420082-BE' : '420082'
  return {
    name: componentCode,
    components: [
      {
        name: componentCode,
        type: MountingComponentType.CLAMP,
        left: -10,
        top: -10,
      },
    ],
  }
}

function chooseEndClamp(input: MountingCalcInput): Item {
  const componentCode = input.options.clampColour === 'black' ? '420081-BE' : '420081'
  return {
    name: componentCode,
    components: [
      {
        name: componentCode,
        type: MountingComponentType.CLAMP,
        left: -10,
        top: -10,
      },
    ],
  }
}

function chooseEndCap(input: MountingCalcInput): Item {
  const componentCode = '920043'
  return {
    name: componentCode,
    components: [
      {
        name: componentCode,
        type: MountingComponentType.OTHER,
        left: -18.1,
        top: -24.8,
      },
    ],
  }
}

function chooseHook(input: MountingCalcInput, panelBlock: PanelBlock | null, rail: RailComponent) {
  const roofHookCode = input.options.roofHook || '420172'
  return [
    {
      name: roofHookCode,
      components: [
        {
          name: roofHookCode,
          type: MountingComponentType.ROOF_HOOK,
          left: -10,
          top: -10,
        },
      ],
    },
  ]
}

function chooseFastener(hook: Item[]): Fastener {
  const qtyPerRoofHook = hook[0].name === '420171' ? 3 : 2
  return {
    includedInRoofHookProduct: false,
    name: '900318',
    qtyPerRoofHook,
    length: 80,
    diameter: 6,
    components: [],
  }
}

class VariosoleRoofHookAdder extends RoofHookAdderAbstract {
  spacingRuleSet = spacingRuleSet
  railOverhang = railOverhang

  getRailType(input: MountingCalcInput): RailType {
    return 'continuous'
  }

  chooseHook(input: MountingCalcInput, panelBlock: PanelBlock, rail: RailComponent) {
    return chooseHook(input, panelBlock, rail)
  }

  chooseFastener(hook: Item[]) {
    return chooseFastener(hook)
  }
}

class VariosoleRailSplicer extends CutAndSplicerAbstract {
  isTarget(component: Component): boolean {
    return component?.type === MountingComponentType.RAIL
  }

  chooseSplice(railName: string, input: MountingCalcInput): Item[] {
    return [
      {
        name: '400531',
        components: [
          {
            type: MountingComponentType.SPLICE,
            name: '400531',
            top: 0,
            left: 0,
          },
        ],
      },
    ]
  }

  getFullLength(railName: string): number {
    if (railName === '400524' || railName === '400524-B') return 3300
    if (railName === '400525' || railName === '400525-B') return 4400
    return 0
  }
}

function addGeniusKits(input: MountingCalcInput, result: MountingCalcResult) {
  const geniusKit = input.options?.geniusKit
  if (geniusKit && geniusKit !== 'None') {
    const roofHookCount = result.items
      .flatMap((item) => item.components)
      .filter((component) => component.type === MountingComponentType.ROOF_HOOK).length
    const geniusKits = Array.apply(null, Array(roofHookCount)).map((item) => {
      return {
        name: geniusKit,
        components: [],
      }
    })
    result.items.push(...geniusKits)
  }
  return result
}

function bundleItems(input: MountingCalcInput, result: MountingCalcResult) {
  // Screws are sold in boxes of 21
  const screwCount = result.items.filter((item) => item.name === '900318').length
  result.items = result.items.filter((item) => item.name !== '900318')
  const boxesOfScrews = Array.apply(null, Array(Math.ceil(screwCount / 21))).map((item) => {
    return {
      name: 'REN-900318-21',
      components: [],
    }
  })
  result.items.push(...boxesOfScrews)
  // End caps are sold as pairs
  const capCount = result.items.filter((item) => item.name === '920043').length
  result.items = result.items.filter((item) => item.name !== '920043')
  const purchasableCaps = Array.apply(null, Array(Math.ceil(capCount / 2))).map((item) => {
    return {
      name: '920043',
      components: [],
    }
  })
  result.items.push(...purchasableCaps)
  return result
}

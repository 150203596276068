import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { addAccordionFormsAndSort } from 'customForm/card/addAccordionFormsAndSort'
import useGetCustomForms from 'customForm/hooks/useGetCustomForms'
import { useMemo } from 'react'
import { FormPlacementEnum } from 'resources/customForms/types'
import { useFeatureFlag } from 'util/split'
import getProjectSectionStyles from '../../styles'
import SectionContainer from '../SectionContainer'
import ElectricityUsageAccordion from './ElectricityUsage'
import ElectricityUtilityAccordion from './ElectricityUtility'
import HeatAccordion from './Heat'
import NaturalGasAccordion from './NaturalGas'
import TransportationAccordion from './Transportation'

const useStyles = makeStyles(getProjectSectionStyles)

const Energy = () => {
  const classes = useStyles()
  const customForms = useGetCustomForms({ location: FormPlacementEnum.energy_page })
  const enableEoeUpdates = useFeatureFlag('enable_eoe_energy_tab', 'on')

  const sortAndRenderComponents = useMemo(() => {
    let toRender = [
      {
        component: ElectricityUsageAccordion,
        shouldRender: true,
        priority: 10,
      },
      {
        component: ElectricityUtilityAccordion,
        shouldRender: true,
        priority: 20,
      },
      {
        component: NaturalGasAccordion,
        shouldRender: enableEoeUpdates,
        priority: 30,
      },
      {
        component: TransportationAccordion,
        shouldRender: enableEoeUpdates,
        priority: 40,
      },
      {
        component: HeatAccordion,
        shouldRender: enableEoeUpdates,
        priority: 50,
      },
    ]

    return addAccordionFormsAndSort(toRender, customForms)
  }, [customForms])

  return (
    <SectionContainer section="energy" mode="background">
      <div className={classes.formPage}>
        <div className={classes.sectionWrapper}>
          <Grid container spacing={3}>
            <Grid xs={12} sm={12} item>
              {sortAndRenderComponents}
            </Grid>
          </Grid>
        </div>
      </div>
    </SectionContainer>
  )
}

export default Energy

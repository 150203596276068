import { Typography } from '@material-ui/core'
import { orderSelectors } from 'ducks/orderComponents'
import { useTranslate } from 'ra-core'
import { useSelector } from 'react-redux'

const CheckoutTitle = () => {
  const translate = useTranslate()
  const lineItems = useSelector(orderSelectors.getOrderLineItems)
  const quantity = lineItems.reduce((accumulator, item) => accumulator + item.quantity, 0)

  return (
    <Typography variant="h4" gutterBottom align="center">
      {translate('checkout_total_items', { smart_count: quantity })}
    </Typography>
  )
}

export default CheckoutTitle

import React, { useMemo } from 'react'
import { US_FINANCE_INTEGRATION_ENUM } from 'types/paymentOptions'

type PropTypes = {
  integration_name: US_FINANCE_INTEGRATION_ENUM
  required_org_names: string[]
  orgName: string | undefined
  requiresExternalRefresh: boolean
}

const INTEGRATION_MAP = {
  sungage: 'Sungage',
  sunlight: 'Sunlight',
  mosaic: 'Mosaic',
  dividend: 'Dividend',
  loanpal: 'Goodleap',
}

const RefreshReminderRow: React.FC<PropTypes> = (props) => {
  const orgList = useMemo(() => {
    if (!props.requiresExternalRefresh || !props.required_org_names?.length) return ''
    if (props.required_org_names.length === 1 && props.orgName === props.required_org_names[0]) return ''
    let list = ' - ('
    props.required_org_names?.forEach((orgNameToRefresh, i) => {
      if (i > 0) list += ', '
      if (orgNameToRefresh === props.orgName) list += 'Your Account'
      else list += orgNameToRefresh
    })
    list += ')'
    return list
  }, [props.required_org_names, props.orgName, props.requiresExternalRefresh])
  return <li key={props.integration_name}>{`${INTEGRATION_MAP[props.integration_name]}${orgList}`}</li>
}

export default RefreshReminderRow

import ContentSave from '@material-ui/icons/SaveOutlined'
import React from 'react'
import Button from '../../../../elements/button/Button'

const styles = {
  page: {
    backgroundColor: '#fbfbfb',
    margin: '50px 20px',
    padding: 6,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 5,
    marginBottom: 15,
    justifyContent: 'space-around',
    alignItems: 'stretch',
    gap: 10,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    gap: 10,
    backgroundColor: '#fff',
    borderRadius: 4,
    padding: 4,
  },
  secondaryHeading: {
    marginLeft: 40,
  },
}

const ButtonDemo = () => {
  return (
    <div style={styles.page}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h1>API:</h1>
        <small>* default prop can be omitted</small>
        <div style={styles.secondaryHeading}>
          <div>
            <b>variant: </b>
            <ul>
              <li>'outlined'</li>
              <li>
                'contained' <i>(default)</i>
              </li>
              <li>'text'</li>
            </ul>
          </div>
          <div>
            <b>color: </b>
            <ul>
              <li>
                'default' <i>(default)</i>
              </li>
              <li>'primary'</li>
              <li>'secondary'</li>
              <li>'info'</li>
              <li>'warning'</li>
              <li>'error'</li>
              <li>'success'</li>
            </ul>
          </div>
          <div>
            <b>size: </b>
            <ul>
              <li>'small'</li>
              <li>
                'medium' <i>(default)</i>
              </li>
              <li>'large'</li>
            </ul>
          </div>
        </div>

        <h1>Contained Buttons:</h1>

        <ButtonSection variant="contained" color="default" styles={styles} />
        <ButtonSection variant="contained" color="primary" styles={styles} />
        <ButtonSection variant="contained" color="secondary" styles={styles} />
        <ButtonSection variant="contained" color="info" styles={styles} />
        <ButtonSection variant="contained" color="warning" styles={styles} />
        <ButtonSection variant="contained" color="error" styles={styles} />
        <ButtonSection variant="contained" color="success" styles={styles} />

        <h1>Outlined Buttons:</h1>

        <ButtonSection variant="outlined" color="default" styles={styles} />
        <ButtonSection variant="outlined" color="primary" styles={styles} />
        <ButtonSection variant="outlined" color="secondary" styles={styles} />
        <ButtonSection variant="outlined" color="info" styles={styles} />
        <ButtonSection variant="outlined" color="warning" styles={styles} />
        <ButtonSection variant="outlined" color="error" styles={styles} />
        <ButtonSection variant="outlined" color="success" styles={styles} />

        <h1>Text Buttons:</h1>

        <ButtonSection variant="text" color="default" styles={styles} />
        <ButtonSection variant="text" color="primary" styles={styles} />
        <ButtonSection variant="text" color="secondary" styles={styles} />
        <ButtonSection variant="text" color="info" styles={styles} />
        <ButtonSection variant="text" color="warning" styles={styles} />
        <ButtonSection variant="text" color="error" styles={styles} />
        <ButtonSection variant="text" color="success" styles={styles} />
      </div>
    </div>
  )
}

const ButtonSection = ({ variant, color, styles }) => {
  return (
    <>
      <div style={styles.secondaryHeading}>
        <div>
          <b>Props: </b> variant="{variant}" color="{color}"
        </div>
      </div>
      <div style={styles.row}>
        <div style={styles.wrapper}>
          <div>size="medium":</div>
          <Button variant={variant} color={color} label="Normal Enabled" />
          <Button variant={variant} color={color} label="Normal Disabled" startIcon={<ContentSave />} disabled />
          <Button variant={variant} color={color} label="Icon Enabled" startIcon={<ContentSave />} />
          <Button variant={variant} color={color} label={'Loading'} loading={true} />
        </div>
        <div style={styles.wrapper}>
          <div>size="small":</div>
          <Button variant={variant} color={color} size="small" label="Small Enabled" startIcon={<ContentSave />} />
          <Button variant={variant} color={color} size="small" label="Small Disabled" disabled />
        </div>
        <div style={styles.wrapper}>
          <div>size="large":</div>
          <Button variant={variant} color={color} size="large" label="Large Enabled" startIcon={<ContentSave />} />
          <Button variant={variant} color={color} size="large" label="Large Disabled" disabled />
        </div>
      </div>
    </>
  )
}

export default ButtonDemo

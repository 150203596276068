export const MONTHS = [
  { id: '01', name: 1 },
  { id: '02', name: 2 },
  { id: '03', name: 3 },
  { id: '04', name: 4 },
  { id: '05', name: 5 },
  { id: '06', name: 6 },
  { id: '07', name: 7 },
  { id: '08', name: 8 },
  { id: '09', name: 9 },
  { id: '10', name: 10 },
  { id: '11', name: 11 },
  { id: '12', name: 12 },
]

const MIN_DAYS = [
  { id: '01', name: 1 },
  { id: '02', name: 2 },
  { id: '03', name: 3 },
  { id: '04', name: 4 },
  { id: '05', name: 5 },
  { id: '06', name: 6 },
  { id: '07', name: 7 },
  { id: '08', name: 8 },
  { id: '09', name: 9 },
  { id: '10', name: 10 },
  { id: '11', name: 11 },
  { id: '12', name: 12 },
  { id: '13', name: 13 },
  { id: '14', name: 14 },
  { id: '15', name: 15 },
  { id: '16', name: 16 },
  { id: '17', name: 17 },
  { id: '18', name: 18 },
  { id: '19', name: 19 },
  { id: '20', name: 20 },
  { id: '21', name: 21 },
  { id: '22', name: 22 },
  { id: '23', name: 23 },
  { id: '24', name: 24 },
  { id: '25', name: 25 },
  { id: '26', name: 26 },
  { id: '27', name: 27 },
  { id: '28', name: 28 },
  { id: '29', name: 29 },
]

export const DAYS_BY_MONTH = {
  '01': [...MIN_DAYS, { id: '30', name: 30 }, { id: '31', name: 31 }],
  '02': [...MIN_DAYS],
  '03': [...MIN_DAYS, { id: '30', name: 30 }, { id: '31', name: 31 }],
  '04': [...MIN_DAYS, { id: '30', name: 30 }],
  '05': [...MIN_DAYS, { id: '30', name: 30 }, { id: '31', name: 31 }],
  '06': [...MIN_DAYS, { id: '30', name: 30 }],
  '07': [...MIN_DAYS, { id: '30', name: 30 }, { id: '31', name: 31 }],
  '08': [...MIN_DAYS, { id: '30', name: 30 }, { id: '31', name: 31 }],
  '09': [...MIN_DAYS, { id: '30', name: 30 }],
  '10': [...MIN_DAYS, { id: '30', name: 30 }, { id: '31', name: 31 }],
  '11': [...MIN_DAYS, { id: '30', name: 30 }],
  '12': [...MIN_DAYS, { id: '30', name: 30 }, { id: '31', name: 31 }],
  none: [...MIN_DAYS, { id: '30', name: 30 }, { id: '31', name: 31 }],
}

export const getYearChoices = () => {
  const now = new Date()
  const thisYear = now.getFullYear()
  const maxYear = thisYear - 17
  let years: { id: string; name: string }[] = []

  let yearToAdd = maxYear
  while (yearToAdd >= 1900) {
    const yearString = `${yearToAdd}`
    years.push({ id: yearString, name: yearString })
    yearToAdd--
  }
  return years
}

import { Component } from 'react'
import { withTranslate } from 'react-admin'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import appStorage from 'storage/appStorage'
import { parseQueryStringToDictionary } from '../util/misc'
import { getOrgFromState } from '../util/org'

// const styles = {
//   warning: {
//     card: 'pink',
//     title: 'red',
//   },
//   notice: {
//     card: 'yellow',
//     title: 'orange',
//   },
//   ok: {
//     card: 'white',
//     title: 'black',
//   },
//   hr: {
//     border: 'none',
//     height: 1,
//     color: '#ddd',
//     backgroundColor: '#ddd',
//     margin: 0,
//   },
// }

const ShowResults = ({ state, translate }) => (
  <div>
    {Object.keys(state.results)
      .filter((key) => key !== 'exceptions')
      .map((key) => (
        <div key={key}>{key + ': ' + state.results[key]}</div>
      ))}

    {state.results.exceptions && state.results.exceptions.length && <h3>{translate('Exceptions:')}</h3>}

    {state.results.exceptions && state.results.exceptions.map((exception) => <div>{exception}</div>)}

    {state.csv_file_upload_request_id && <Link to={'/csv_file_upload_requests'}>View Details</Link>}
    <hr />
  </div>
)

class PageUploadCsv extends Component {
  constructor(props) {
    super(props)

    console.log(props.location)
    var q = parseQueryStringToDictionary(props.location.search)

    this.state = {
      results: null,
      model: q.model ? q.model : null,
      csv_file_upload_request_id: null,
    }
  }

  handleSuccess(response) {
    if (response.error && response.error.length > 0) {
      this.setState({ results: { error: response.error } })
    } else {
      this.setState({
        results: { message: response.message },
        csv_file_upload_request_id: response.csv_file_upload_request_id,
      })
    }
  }

  handleError(error) {
    var message = error.message ? error.message : 'Error'

    this.setState({ results: { error: message } })
  }

  upload(model, uploadToSpecificOrg, orgId) {
    this.setState({ model: model })

    var input = document.querySelector('input[type="file"][id="csv_upload_' + model + '"]')

    var data = new FormData()
    data.append('file', input.files[0])
    data.append('user', 'hubot')

    var url = uploadToSpecificOrg
      ? window.API_BASE_URL + 'orgs/' + orgId + '/' + model + '/csv_upload/'
      : window.API_BASE_URL + model + '/csv_upload/?org_id=' + orgId

    fetch(url, {
      method: 'POST',
      body: data,
      headers: {
        Authorization: 'Bearer ' + appStorage.getToken(),
      },
    })
      .then(
        (response) => response.json() // if the response is a JSON object
      )
      .then(
        (success) => this.handleSuccess(success) // Handle the success response object
      )
      .catch(
        (error) => this.handleError(error) // Handle the error response object
      )
  }

  render() {
    const { translate } = this.props

    return (
      <div>
        {(!this.state.model || this.state.model === 'projects') && (
          <div style={{ marginBottom: 50, padding: 20 }}>
            <h2>{translate('Upload Projects CSV')}</h2>
            {this.state.results && this.state.model === 'projects' && (
              <ShowResults state={this.state} {...this.props} translate={translate} />
            )}
            <div>
              <div>{translate('Upload CSV to create projects and contacts.')}</div>
              <div style={{ paddingTop: 20 }}>
                <input type="file" name="csv_upload_projects" id="csv_upload_projects" />
              </div>
              <div style={{ paddingTop: 20 }}>
                <input
                  type="button"
                  value={translate('Upload CSV')}
                  name="submit"
                  onClick={() => this.upload('projects', true, this.props.orgId)}
                />
              </div>
            </div>
          </div>
        )}
        {(!this.state.model || this.state.model === 'utility_tariffs') && (
          <div style={{ marginBottom: 50, padding: 20 }}>
            <h2>{translate('Upload Utility Tariffs CSV')}</h2>
            {this.state.results && this.state.model === 'utility_tariffs' && (
              <ShowResults state={this.state} translate={translate} />
            )}
            <div>
              <div stye="padding: 20">
                <input type="file" name="csv_upload_utility_tariffs" id="csv_upload_utility_tariffs" />
              </div>
              <div stye="padding: 20">
                <input
                  type="button"
                  value={translate('Upload CSV')}
                  name="submit"
                  onClick={() => this.upload('utility_tariffs', true, this.props.orgId)}
                />
              </div>
            </div>
          </div>
        )}
        {(!this.state.model || this.state.model === 'utilities') && (
          <div style={{ marginBottom: 50, padding: 20 }}>
            <h2>{translate('Upload Utilities CSV')}</h2>
            {this.state.results && this.state.model === 'utilities' && (
              <ShowResults state={this.state} translate={translate} />
            )}
            <div>
              <div stye="padding: 20">
                <input type="file" name="csv_upload_utilities" id="csv_upload_utilities" />
              </div>
              <div stye="padding: 20">
                <input
                  type="button"
                  value={translate('Upload CSV')}
                  name="submit"
                  onClick={() => this.upload('utilities', false, this.props.orgId)}
                />
              </div>
            </div>
          </div>
        )}
        {(!this.state.model || this.state.model === 'distributors') && (
          <div style={{ marginBottom: 50, padding: 20 }}>
            <h2>{translate('Upload Distributors CSV')}</h2>
            {this.state.results && this.state.model === 'distributors' && (
              <ShowResults state={this.state} translate={translate} />
            )}
            <div>
              <div stye="padding: 20">
                <input type="file" name="csv_upload_distributors" id="csv_upload_distributors" />
              </div>
              <div stye="padding: 20">
                <input
                  type="button"
                  value={translate('Upload CSV')}
                  name="submit"
                  onClick={() => this.upload('distributors', false, this.props.orgId)}
                />
              </div>
            </div>
          </div>
        )}
        {['component_modules', 'component_inverters', 'component_batteries', 'component_others']
          .filter((component_type) => !this.state.model || this.state.model === component_type)
          .map((component_type) => (
            <div key={component_type} style={{ marginBottom: 50, padding: 20 }}>
              <h2>{translate('Upload Component CSV (' + component_type + ')')}</h2>
              {this.state.results && this.state.model === component_type && (
                <ShowResults state={this.state} translate={translate} />
              )}
              <div>
                <div stye="padding: 20">
                  <input type="file" name={'csv_upload_' + component_type} id={'csv_upload_' + component_type} />
                </div>
                <div stye="padding: 20">
                  <input
                    type="button"
                    value={translate('Upload CSV')}
                    name="submit"
                    onClick={() => this.upload(component_type, false, this.props.orgId)}
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  orgId: state.auth ? state.auth.org_id : null,
  enableExhibit: getOrgFromState(state) && getOrgFromState(state).enable_exhibit,
})

export default connect(mapStateToProps, {})(withTranslate(PageUploadCsv))

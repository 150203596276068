var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FieldTitle as OsFieldTitle, OsFieldTitleVersion, useComponentVersion } from 'opensolar-ui';
import { useMemo } from 'react';
import pure from 'recompose/pure';
import useTranslate from '../i18n/useTranslate';
import getFieldLabelTranslationArgs from './getFieldLabelTranslationArgs';
export var FieldTitle = function (_a) {
    var resource = _a.resource, source = _a.source, label = _a.label, isRequired = _a.isRequired, rest = __rest(_a, ["resource", "source", "label", "isRequired"]);
    var translate = useTranslate();
    var version = useComponentVersion('field_title');
    var autoTranslate = useMemo(function () { return translate.apply(void 0, getFieldLabelTranslationArgs({ label: label, resource: resource, source: source })); }, [
        label,
        resource,
        source,
    ]);
    switch (version) {
        case OsFieldTitleVersion.V2:
            return _jsx(OsFieldTitle, { label: rest.translate === false ? label || '' : autoTranslate, required: isRequired });
        default:
            return (_jsxs("span", { children: [autoTranslate, isRequired && ' *'] }));
    }
};
// wat? TypeScript looses the displayName if we don't set it explicitly
FieldTitle.displayName = 'FieldTitle';
export default pure(FieldTitle);

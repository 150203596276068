import appStorage from 'storage/appStorage'
import { ReduxActionType } from 'types/global'
import { RootState } from 'types/state'

export const SET_THEME = 'SET_THEME'
export const CHANGE_THEME = 'CHANGE_THEME'
export const CHANGE_LOCATION = '@@router/LOCATION_CHANGE'

export function setTheme(theme: OsTheme) {
  return {
    type: SET_THEME,
    payload: theme,
  }
}
export function changeTheme(theme: OsTheme) {
  return {
    type: CHANGE_THEME,
    payload: theme,
  }
}

type ThemeAction = {
  type: typeof SET_THEME | typeof CHANGE_THEME
  payload: OsTheme
}

const getInitialState = () => {
  return appStorage.getString('theme_skin') || 'opensolar'
}

export default function reducer(previousState = getInitialState(), { type, payload }: ThemeAction | ReduxActionType) {
  if (type === SET_THEME) {
    console.debug('Set theme: ', payload)
    return payload
  }
  if (type === CHANGE_THEME) {
    console.debug('Change theme: ', payload)
    appStorage.setString('theme_skin', payload)
    return payload
  }
  if (type === CHANGE_LOCATION && payload.location.pathname && payload.location.pathname.includes('nearmap')) {
    console.debug('Change theme to nearmap')
    appStorage.setString('theme_skin', 'nearmap')
    return 'nearmap'
  }
  return previousState
}

export const themeSelectors = {
  getTheme(state: RootState): OsTheme {
    return state.theme
  },
}

export type OsTheme = 'opensolar' | 'nearmap'

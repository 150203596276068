import { authSelectors } from 'ducks/auth'
import {
  addOrderLineItems,
  deleteLineItems,
  deleteOrderByCode,
  deleteOrderBySystemId,
  orderSelectors,
  updateLoadedProductData,
  updateOrderQuantity
} from 'ducks/orderComponents'
import { orgSelectors } from 'ducks/orgs'
import _ from 'lodash'
import moment from 'moment'
import type LineItemType from 'pages/ordering/OrderLineItem'
import { OrderLineItem } from 'pages/ordering/OrderLineItem'
import {
  HardwareSupplierEnum,
  type BasicComponentLineItemType,
  type HardwareSupplierFilterKeyType,
  type LineItemLoadedDataType,
  type StockLevelStatus,
  type StockLevelType,
  type SystemDesignLineItemCategoryType,
  type SystemDesignType
} from 'pages/ordering/type'
import type { LegacyDataProvider } from 'react-admin'
import type { Dispatch, Store } from 'redux'
import restClient from 'restClient'
import { InverterDataType } from 'types/inverter'
import type { ComponentTypes } from 'types/selectComponent'
import type { RootState } from 'types/state'
import type { SystemDataType } from 'types/systems'
import { STOCK_LEVEL_STATUS } from '../constants'

const CATEGORY_AND_PRODUCT_TYPE_MAP: Record<SystemDesignLineItemCategoryType, ComponentTypes> = {
  batteries: 'battery',
  inverters: 'inverter',
  modules: 'module',
  others: 'other',
}

const getInverterModuleQuantity = (inverter: InverterDataType): number => {
  return (
    (inverter.mppts &&
      inverter.mppts
        .map((mppt) => mppt.strings.reduce((sum, string) => sum + string.modules.length, 0))
        .reduce((sum, cur) => sum + cur, 0)) ||
    0
  )
}

const getTotalUnstrungModulesQuantity = (system: SystemDataType): number => {
  return system.unstrungModulesByModuleGrid
    ? system.unstrungModulesByModuleGrid.map((mg) => mg.modules.length).reduce((a, b) => a + b, 0)
    : 0
}

class ProjectOrderPresenter {
  private store
  dispatch
  restClientInstance

  constructor(store: Store<RootState>, dispatch: Dispatch, restClientInstance: LegacyDataProvider) {
    this.store = store
    this.dispatch = dispatch
    this.restClientInstance = restClientInstance
  }

  private getState = () => {
    return this.store.getState()
  }

  static getLineItemsTotalQuantity = (lineItems: LineItemType[]) => {
    let total = 0
    lineItems.forEach((item: LineItemType) => {
      // assign default quantityPerItem to 1 for unavailable lineItems
      const { quantity, quantityPerItem = 1 } = item
      if (quantity != null && quantityPerItem != null) {
        total += quantity * quantityPerItem
      }
    })
    return total
  }

  static aggregateLineItems = (lineItems: LineItemType[]) => {
    const aggregateLineItems: LineItemType[] = []
    function getHashKey(lineItem: LineItemType) {
      return `${lineItem.code}+${lineItem.quantityPerItem}+${lineItem.confirmed ? 'confirmed' : 'unconfirmed'}`
    }
    // <code+quantityPerItem, index>
    const hashMapHelper: Record<string, number> = {}
    lineItems.forEach((lineItem: LineItemType) => {
      const recordedIndex = hashMapHelper[getHashKey(lineItem)]
      if (recordedIndex == null) {
        // record index
        aggregateLineItems.push(lineItem)
        hashMapHelper[getHashKey(lineItem)] = aggregateLineItems.length - 1
        return
      }

      const existingRecord: LineItemType | undefined = aggregateLineItems[recordedIndex]

      existingRecord.quantity += lineItem.quantity
      if (existingRecord.projectOrder) {
        existingRecord.projectOrder = _.unionWith(lineItem.projectOrder || [], existingRecord.projectOrder, _.isEqual)
      } else {
        existingRecord.projectOrder = [...(lineItem.projectOrder || [])]
      }
    })

    return aggregateLineItems
  }

  static getPriceAndStock = async ({
    orgId,
    lineItems,
    supplierFilterKey,
  }: {
    orgId: number
    lineItems: LineItemType[]
    supplierFilterKey?: string
  }): Promise<LineItemLoadedDataType[] | undefined> => {
    const restClientInstance = restClient(window.API_ROOT + '/api')
    try {
      const response = await restClientInstance('CUSTOM_GET', 'components', {
        url: `components/?org_id=${orgId}&catalog=full&require_distributor=${supplierFilterKey}&filter_codes=${lineItems
          .map((lineItems) => lineItems.code)
          .join(',')}`,
      })
      return response.data
    } catch (error) {
      console.log('Event not recorded', error)
    }
  }

  static isStockLevelReadyToOrder = (stockLevelStatus: StockLevelStatus) =>
    stockLevelStatus === 'loading' ||
    stockLevelStatus === 'available' ||
    stockLevelStatus === 'low_stock'

  static getStockPlannedDateDays = (stockLevels: StockLevelType[] | undefined): number => {
    const stockLevelsPlanned: StockLevelType | undefined = stockLevels?.find(
      (stockLevel: StockLevelType) => !stockLevel.in_stock && stockLevel.planned_date
    )
    if (!stockLevelsPlanned) {
      return 0
    }
    const date = stockLevelsPlanned.planned_date
    return moment(date).diff(moment(), 'days')
  }

  static getStockLevelStatusMain = ({
    quantity,
    stockLevels,
    isAvailable,
  }: {
    quantity: number
    stockLevels: StockLevelType[] | undefined
    isAvailable: boolean
  }): StockLevelStatus => {
    const selectedHardwareSupplier = orgSelectors.getSelectedHardwareSupplier(window.reduxStore?.getState())
    if (!isAvailable) {
      return STOCK_LEVEL_STATUS.NOT_AVAILABLE as StockLevelStatus
    }

    if (selectedHardwareSupplier === HardwareSupplierEnum.Segen && (!stockLevels || stockLevels.length === 0)) {
      return STOCK_LEVEL_STATUS.AVAILABLE as StockLevelStatus
    }

    const inStockLevel = stockLevels?.find((stock: StockLevelType) => stock.in_stock)

    if (!inStockLevel) {
      // available for pre order
      // applicable for solar outlet only
      if(selectedHardwareSupplier === HardwareSupplierEnum.SolarOutlet) {
        const stockLevelsPlanned: StockLevelType[] | undefined = stockLevels?.filter(
          (stockLevel: StockLevelType) => !stockLevel.in_stock && stockLevel.planned_date
        )
        if (stockLevelsPlanned != null) {
          return STOCK_LEVEL_STATUS.AVAILABLE_FOR_PRE_ORDER as StockLevelStatus
        }
      }
      return 'out_of_stock'
    } else {
      if (isNaN(quantity)) {
        return STOCK_LEVEL_STATUS.AVAILABLE as StockLevelStatus
      }
    }

    if (inStockLevel.quantity >= quantity) {
      if (inStockLevel.quantity > quantity * 1.25) {
        return STOCK_LEVEL_STATUS.AVAILABLE as StockLevelStatus
      } else {
        return STOCK_LEVEL_STATUS.LOW_STOCK as StockLevelStatus
      }
    }
    if (inStockLevel.quantity < quantity) {
      return STOCK_LEVEL_STATUS.PARTIAL_AVAILABLE as StockLevelStatus
    }

    // Set default stock level status to not available?
    return STOCK_LEVEL_STATUS.AVAILABLE_FOR_PRE_ORDER as StockLevelStatus
  }

  static getStockLevelStatusFromLineItem = (lineItem: LineItemType): StockLevelStatus => {
    if (lineItem.status === 'loading') {
      return 'loading'
    }
    const { quantity, stockLevels, pricePerUnit, selectedDistributorOrderingData } = lineItem

    return ProjectOrderPresenter.getStockLevelStatusMain({
      quantity,
      stockLevels,
      isAvailable: !!selectedDistributorOrderingData?.is_available,
    })
  }

  static getLineItemByLoadedData = (
    lineItem: LineItemType,
    loadedData: LineItemLoadedDataType | undefined
  ): LineItemType => {
    const newLineItem = new OrderLineItem({
      ...lineItem,
      status: 'loaded',
      data: loadedData,
      componentType: loadedData?.component_type || lineItem.componentType,
    })

    return newLineItem
  }

  private injectVariantIds = async (lineItems: LineItemType[]): Promise<LineItemLoadedDataType[] | undefined> => {
    try {
      const response = await this.restClientInstance('CUSTOM_POST', 'inject_variant_ids', {
        url: 'inject_variant_ids/',
        data: lineItems,
      })
      return response.data
    } catch (error) {
      console.log('Event not recorded', error)
    }
  }

  getLineItemsBySystemId = (systemId: number) => {
    const state = this.getState()
    const getLineItemsBySystemId = orderSelectors.getLineItemsBySystemId(systemId)
    return getLineItemsBySystemId(state)
  }

  deleteUnconfirmedLineItemsBySystemId = (systemId: number) => {
    this.dispatch(deleteOrderBySystemId({ systemId, confirmed: false }))
  }

  deleteLineItemByUuid = (uuid: string) => {
    this.dispatch(
      deleteLineItems<'uuid'>({ source: 'uuid', identifiers: [uuid] })
    )
  }

  deleteLineItemByUuids = (uuids: string[]) => {
    this.dispatch(
      deleteLineItems<'uuid'>({ source: 'uuid', identifiers: uuids })
    )
  }

  deleteLineItemsByCode = (code: string) => {
    this.dispatch(deleteOrderByCode(code))
  }

  addLineItemsLegacy = async (lineItems: LineItemType[]) => {
    this.dispatch(addOrderLineItems(lineItems))
    const response = await this.injectVariantIds(lineItems)
    if (response == null) {
      return
    }
    this.dispatch(updateLoadedProductData(response, lineItems))
  }

  addLineItems = async (lineItems: LineItemType[]) => {
    this.dispatch(addOrderLineItems(lineItems))
  }

  updateLineItemQuantity = ({ uuid, quantity }: { uuid: string; quantity: number }) => {
    this.dispatch(updateOrderQuantity({ uuid, quantity }))
  }

  // To make it consistent, move this logic to backend
  getLineItemsFromSystem = (soldSystemData: SystemDataType, selectedDistributor: HardwareSupplierFilterKeyType) => {
    if (!soldSystemData) {
      alert('Sorry, this action is currently unavailable')
      return
    }
    const lineItems: LineItemType[] = []
    lineItems.push(
      new OrderLineItem({
        componentType: 'module',
        status: 'loading',
        code: soldSystemData.module.code,
        quantity: soldSystemData.module_quantity,
        quantityPerItem: 1,
        selectedDistributor,
      })
    )
    soldSystemData.inverters.forEach((inverter) => {
      const inverterQuantity = inverter.microinverter
        ? getInverterModuleQuantity(inverter) || getTotalUnstrungModulesQuantity(soldSystemData)
        : 1
      const indexOfLineItems = lineItems.findIndex((i) => i.code === inverter.code)
      if (indexOfLineItems !== -1) {
        lineItems[indexOfLineItems].quantity += inverterQuantity
      } else {
        lineItems.push(
          new OrderLineItem({
            componentType: 'inverter',
            status: 'loading',
            code: inverter.code,
            quantity: inverterQuantity,
            quantityPerItem: 1,
            selectedDistributor,
          })
        )
      }
    })
    soldSystemData.batteries.forEach((battery) => {
      const indexOfLineItems = lineItems.findIndex((i) => i.code === battery.code)
      if (indexOfLineItems !== -1) {
        lineItems[indexOfLineItems].quantity = lineItems[indexOfLineItems].quantity ?? 0 + 1
      } else {
        lineItems.push(
          new OrderLineItem({
            componentType: 'battery',
            status: 'loading',
            code: battery.code,
            quantity: 1,
            quantityPerItem: 1,
            selectedDistributor,
          })
        )
      }
    })
    soldSystemData.others.forEach((other) => {
      const indexOfLineItems = lineItems.findIndex((i) => i.code === other.code)
      if (indexOfLineItems !== -1) {
        lineItems[indexOfLineItems].quantity += other.quantity
      } else {
        lineItems.push(
          new OrderLineItem({
            componentType: 'other',
            status: 'loading',
            code: other.code,
            quantity: other.quantity,
            quantityPerItem: 1,
            selectedDistributor,
          })
        )
      }
    })
    //To Do: get correct quantity
    return lineItems
  }

  getLineItemsFromSystemDesignData = ({
    selectedSupplierKey,
    systemDesign,
    projectId,
    systemId,
  }: {
    selectedSupplierKey: HardwareSupplierFilterKeyType
    systemDesign: SystemDesignType
    projectId: number
    systemId: number
  }): LineItemType[] => {
    const state = this.getState()
    const orgId = authSelectors.getOrgId(state)

    const categories: SystemDesignLineItemCategoryType[] = ['batteries', 'inverters', 'modules', 'others']
    const lineItems: LineItemType[] = []
    categories.forEach((category: SystemDesignLineItemCategoryType) => {
      const newLineItems: LineItemType[] = systemDesign[category].map(
        (basicLineItem: BasicComponentLineItemType) =>
          new OrderLineItem({
            ...basicLineItem,
            status: 'loading',
            quantityPerItem: 1,
            componentType: CATEGORY_AND_PRODUCT_TYPE_MAP[category],
            orgId,
            projectOrder: [
              {
                projectId,
                systemId,
                requiredQuantity: basicLineItem.quantity,
              },
            ],
            selectedDistributor: selectedSupplierKey,
          })
      )
      lineItems.push(...newLineItems)
    })
    return lineItems
  }
}

export default ProjectOrderPresenter

import { TextInputProps } from 'ra-ui-materialui/src/input/TextInput'
import React, { useCallback } from 'react'
import { TextInput, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { celsiusToFahrenheit, getMeasurementsFromState, trimDecimalPlaces } from 'util/misc'
import { formatTemperatureValue, parseTemperatureValue } from 'util/temperature'

interface PropTypes {
  value: number
  source: string
  defaultTemp: number
}

const TemperatureInput: React.FC<PropTypes & TextInputProps> = ({ value, source, defaultTemp, ...props }) => {
  const form = useForm()
  const translate = useTranslate()
  const isFahrenheit = useSelector((state: any) => getMeasurementsFromState(state) === 'imperial')
  const handleTempOverrideChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    form.change(source, formatTemperatureValue(e.target.value, isFahrenheit))
  }, [])

  return (
    <TextInput
      format={(value) => {
        return parseTemperatureValue(value, isFahrenheit)
      }}
      defaultValue={value}
      source={source}
      type="number"
      variant="outlined"
      label={false}
      onChange={handleTempOverrideChange}
      InputProps={{
        endAdornment: isFahrenheit ? '°F' : '°C',
      }}
      placeholder={translate('default to %{defaultTemperature}', {
        defaultTemperature: isFahrenheit
          ? trimDecimalPlaces(celsiusToFahrenheit(defaultTemp), 1)
          : trimDecimalPlaces(defaultTemp, 1),
      })}
      {...props}
    />
  )
}
export default TemperatureInput

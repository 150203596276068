import { logAmplitudeEvent } from 'amplitude/amplitude'
import { Button } from 'opensolar-ui'
import type { ProductDocumentType } from 'pages/ordering/type'
import { useCallback, useEffect } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  container: {
    display: 'flex',
  },
  button: {
    margin: '0 5px',
  },
}))

// Relies on description from segen to show action buttons
const PRODUCT_DOCUMENTS = ['Warranty', 'Data Sheet', 'Installation Manual']

const DocumentActionButton = ({ handleClick, description }: { handleClick(): void; description: string }) => {
  const classes = useStyles()
  useEffect(() => {
    logAmplitudeEvent('hardware_detail_document_button', {
      action: 'mounted',
      context: description,
    })
  }, [])
  return (
    <Button variant="outlined" color="default" className={classes.button} onClick={handleClick}>
      {description}
    </Button>
  )
}

const ProductDetailDescriptionActions = ({ productDocuments }: { productDocuments: ProductDocumentType[] }) => {
  const classes = useStyles()
  const handleButtonClick = useCallback((url: string, description: string) => {
    window.open(url)
    logAmplitudeEvent('hardware_detail_document_button', {
      action: 'clicked',
      context: description,
    })
  }, [])
  return (
    <div className={classes.container}>
      {PRODUCT_DOCUMENTS.map((description: string) => {
        const document = productDocuments.find((detail: ProductDocumentType) => detail.description === description)
        if (document != null) {
          return (
            <DocumentActionButton
              key={description}
              handleClick={() => handleButtonClick(document.url, description)}
              description={description}
            />
          )
        }
        return null
      })}
    </div>
  )
}

export default ProductDetailDescriptionActions

import { ButtonProps } from '@material-ui/core'
import * as Sentry from '@sentry/react'
import { addProjectError, clearProjectErrors, projectPermissionsActions, updateProjectSection } from 'actions/project'
import { newSupportTicketLink } from 'constants/links'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { permissionsSelectors } from 'ducks/permissions'
import { updateRecentProject } from 'ducks/recentProjects'
import Alert from 'elements/Alert'
import { isNestedWindow } from 'opensolar-sdk'
import { showNotification, useTranslate } from 'ra-core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { projectErrorSelector, ProjectErrorType } from 'reducer/project/projectErrorsReducer'
import { PermissionSet } from 'types/roles'
import { RootState } from 'types/state'
import { fetchRequest } from 'util/fetch'
import { useFeatureFlag } from 'util/split'
import Confirm from './elements/dialog/Confirm'
import PageLoadingSkeleton from './elements/displayComponents/PageLoadingSkeleton'
import { parseInitialValues } from './form/parser'
import ProjectForm from './form/ProjectForm'
import useProjectSave from './hooks/useProjectSave'

export const AjaxSessionContext = React.createContext<number | undefined>(undefined)

type ProjectIdType = 'new' | number

type ErrorPromptProps = {
  restartDesignFunc: (() => void) | null
  open: boolean
}

const ProjectLoadErrorPrompt = ({ restartDesignFunc, open }: ErrorPromptProps) => {
  const history = useHistory()
  const translate = useTranslate()

  const promptButtons = useMemo(() => {
    const returnToProjectsBtn: ButtonProps = {
      children: 'Return to Projects',
      onClick: () => history.push('/projects'),
    }
    const restartDesignBtn: ButtonProps = {
      children: 'Restart Design',
    }
    if (restartDesignFunc) restartDesignBtn.onClick = restartDesignFunc
    const contactSupportBtn: ButtonProps = {
      color: 'primary',
      children: 'Contact Support',
      onClick: () => window.open(newSupportTicketLink, '_blank'),
    }
    return restartDesignFunc
      ? [returnToProjectsBtn, restartDesignBtn, contactSupportBtn]
      : [returnToProjectsBtn, contactSupportBtn]
  }, [restartDesignFunc])

  return (
    <Confirm
      open={open}
      header={{
        title: translate('Project failed to load.'),
      }}
      buttons={promptButtons}
      content={
        <Alert severity="error" options={{ style: { marginBottom: 15 } }}>
          {translate(
            'An error prevented the project to load. Please contact OpenSolar Support or choose how to proceed from the options below.'
          )}
        </Alert>
      }
    />
  )
}

const ProjectSections: React.FC<RouterProps> = (props) => {
  const { projectId, previousUnsavedData } = props
  const apiKeyGoogle = useSelector((state: RootState) => state.auth?.api_key_google)
  const apiKeyBing = useSelector((state: RootState) => orgSelectors.getOrg(state)?.api_key_bing)
  const [loadedProjectId, setLoadedProjectId] = useState<ProjectIdType | undefined>()
  const [initialValues, setInitialValues] = useState({})
  const authReloading = useSelector(authSelectors.getAuthReloading)
  const orgLoading = useSelector(orgSelectors.getOrgLoading)
  const authOrgId = useSelector(authSelectors.getOrgId)
  const currentRolePermissions = useSelector(permissionsSelectors.getPermissionsSetting)
  const designRootPermissions = useSelector(permissionsSelectors.getProjectPermissionByKey('design', true))
  const designBuildablePanelsPermissions = useSelector(
    permissionsSelectors.getProjectPermissionByKey('design_panels_build', true)
  )
  const loadedProjPermissions = useSelector(permissionsSelectors.getProjectPermissionsLoaded)
  const projectErrors = useSelector(projectErrorSelector.getAllErrors) || []
  const [errorOnLoad, setErrorOnLoad] = useState(false)
  const [restartDesignFunc, setRestartDesignFunc] = useState<(() => void) | null>(null)
  const DSMTerrainWallBlurringOn = useFeatureFlag('dsm_terrain_wall_blurring', 'on')

  const handleSave = useProjectSave()
  const translate = useTranslate()
  const dispatch = useDispatch()

  useEffect(() => {
    window.editor.terrainSettings.wallBlurringActive = !!DSMTerrainWallBlurringOn
    const terrain = window.editor.getTerrain()
    if (terrain) terrain.setWallBlurringActive(!!DSMTerrainWallBlurringOn, { render: true })
  }, [DSMTerrainWallBlurringOn])

  const fetchProjectData = useCallback((projectId: ProjectIdType) => {
    // Do not use localStorage.getItem('org_id') because it can have various problems inside SDK
    // especially if other tabs are also running the App or SDK. Use authOrgId instead.

    const requestUrl = `${window.API_ROOT}/api/orgs/${authOrgId}/projects/${projectId}/`
    const headers = window.Utils.tokenAuthHeaders({
      'Content-Type': 'application/json',
      'X-CSRFToken': window.getCookie('csrftoken') as string,
    })
    const request = new Request(requestUrl, {
      method: 'GET',
      headers: new Headers(headers),
    })
    return fetchRequest(request, { format: 'json' })
  }, [])

  const updateCalcErrorsFromProjectData = useCallback((projectData) => {
    // this block was extracted from the stack:
    // window.WorkspaceHelper.loadDesign() ->
    // window.WorkspaceHelper.updateLastCalcError() ->
    // window.WorkspaceHelper.addProjectErrorToReduxStore()
    if (projectData.simulate_first_year_only === false) {
      const filter = (error: ProjectErrorType) => error.source === 'lastCalc'
      if (projectErrors.find(filter)) {
        dispatch(clearProjectErrors(filter))
      }

      if (projectData.last_calculation_error && Object.keys(projectData.last_calculation_error).length > 0) {
        const detail = window.Designer.getErrorDetail(
          { responseJSON: projectData.last_calculation_error },
          'Unspecified Error.'
        )
        dispatch(showNotification(translate(`Calculation error: ${detail}`), 'warning'))
        dispatch(
          addProjectError({
            message: projectData.last_calculation_error,
            messageType: 'calcError',
            key: 'LAST_CALC_ERROR',
            severity: 'error',
            systemId: undefined,
            source: 'lastCalc',
            category: 'standalone',
          })
        )
      }
    }
  }, [])

  const updateSharingErrorsFromProjectData = useCallback((projectData) => {
    if (projectData.unshared_items) {
      // this block was extracted from the stack:
      // window.WorkspaceHelper.loadDesign()
      // window.WorkspaceHelper.getUnsharedEntitiesError()
      dispatch(
        addProjectError({
          message: projectData.unshared_items,
          messageType: 'sharingError',
          key: '3PO_ERROR',
          severity: 'warning',
          systemId: undefined,
          source: 'sharing',
          category: 'standalone',
        })
      )
    }
  }, [])

  const updateProjectPermissionsFromProjectData = useCallback(
    (projectData) => {
      // this block was extracted from the stack:
      // window.WorkspaceHelper.loadDesign() ->
      // window.WorkspaceHelper.addProjectPermissionsToReduxStore()
      // also: window.WorkspaceHelper.inheritProjectPermissions
      if (projectData.permissions?.project) {
        const projectPermissions = projectData.permissions?.project
        const rolePermissions = currentRolePermissions || {}
        // changed this from the spread the spread [...] operator to Array.from()
        // as the current TypeScript compilation target (es5) will not allow it
        // unless the --downLevelIteration flag is present
        const permissionKeys = Array.from(new Set(Object.keys(rolePermissions).concat(Object.keys(projectPermissions))))
        let permissions = {}
        permissionKeys.forEach((key) => {
          const roleSetting = rolePermissions[key]
          const projectSetting = projectPermissions[key]
          if (roleSetting && projectSetting) {
            permissions[key] = {
              view: !!roleSetting.view && !!projectSetting.view,
              create: !!roleSetting.create && !!projectSetting.create,
              edit: !!roleSetting.edit && !!projectSetting.edit,
              delete: !!roleSetting.delete && !!projectSetting.delete,
            }
          } else if (roleSetting && !projectSetting) {
            permissions[key] = {
              view: !!roleSetting.view,
              create: !!roleSetting.create,
              edit: !!roleSetting.edit,
              delete: !!roleSetting.delete,
            }
          }
        })
        dispatch(projectPermissionsActions.updateProjectPermissions(permissions as PermissionSet))
      } else {
        dispatch(projectPermissionsActions.inheritProjectPermissions())
      }
    },
    [currentRolePermissions]
  )

  const loadDesignFromProjectData = useCallback((projectData) => {
    // deserialize the project's design data then load it in studio
    projectData.design = projectData.design ? window.editor.deserializeDesign(projectData.design) : null

    // I don't have enough context to refactor this awkward block of code...
    // check to see if any payment options have validation errors on them. If so add them to redux
    if (projectData.design?.object) {
      const systems = projectData.design.object?.children?.filter((child) => child.type === 'OsSystem')
      systems?.forEach((system) => {
        if (system.userData) {
          window.WorkspaceHelper.refreshPaymentOptionWarnings(system.userData)
        }
      })
    }

    let initData = undefined
    if (projectData.design?.init) {
      initData = projectData.design.init
      projectData.design = null
    }

    if (!projectData || !projectData.design) {
      window.WorkspaceHelper.onLoaded(window.editor, false, projectData, initData)
      return
    }

    // attempt to load the project's design data into studio
    // if we encounter a runtime error, we handle it separately from
    // the project-level errors
    try {
      window.WorkspaceHelper.setParamsFromProjectData(projectData)
      window.editor.loadScene(projectData.design, window.WorkspaceHelper.params)
      window.WorkspaceHelper.onLoaded(window.editor, true, projectData)
      if (window.Designer.scheduleRefreshStrings) {
        window.Designer.scheduleRefreshStrings()
      }
    } catch (error) {
      setErrorOnLoad(true)
      dispatch(
        showNotification(
          translate(`Failed to load design for project %{projectId}: %{error}`, { projectId, error }),
          'error'
        )
      )
      setRestartDesignFunc(() => () => {
        // because the previous attempt to load the design data resulted in an error
        // we assume the editor's scene is not anymore in pristine condition
        // we bring it back to a clean state first before restarting the design
        window.editor.loadStarterScene(window.WorkspaceHelper.params)
        // setting the isSceneReady param to false will create a new design
        // using the default design mode and imagery
        window.WorkspaceHelper.onLoaded(window.editor, false, projectData, initData)
        setErrorOnLoad(false)
      })
      Sentry.captureException(error)
    }
  }, [])

  useEffect(() => {
    window.Designer.permissions.setRootPermissions(
      designRootPermissions.allowCreate,
      designRootPermissions.allowDelete,
      designRootPermissions.allowEdit,
      designRootPermissions.allowView
    )
    window.Designer.permissions.updatePermissionsByKeyString(
      'systems.buildablePanels',
      designBuildablePanelsPermissions.allowCreate,
      designBuildablePanelsPermissions.allowDelete,
      designBuildablePanelsPermissions.allowEdit,
      designBuildablePanelsPermissions.allowView
    )
  }, [
    // we only track the edit flags since these are the only ones that matter, for now
    designRootPermissions.allowEdit,
    designBuildablePanelsPermissions.allowEdit,
  ])

  useEffect(() => {
    window.Designer.AjaxSession.invalidateAllPendingRequests()
    const ajaxSessionToken = window.Designer.AjaxSession.createAjaxSessionToken({
      url: `${window.API_ROOT}/api/orgs/${authOrgId}/projects/${projectId}/`,
      nameSpace: 'fetchProjectData',
    })

    console.debug('Project begin load:', projectId)
    const initializeProjectData = async (projectId: ProjectIdType) => {
      setErrorOnLoad(false)
      setRestartDesignFunc(null)

      window.setStudioMode('hidden')
      // Never clear overrideApiKeyGoogle, only overwrite with a new (not-empty) value
      // to avoid some race-condition bugs
      if (apiKeyGoogle) {
        window.AccountHelper.overrideApiKeyGoogle = apiKeyGoogle
      }
      if (apiKeyBing) {
        window.AccountHelper.overrideApiKeyBing = apiKeyBing
      }

      if (!isNaN(projectId as any)) {
        window.WorkspaceHelper.setProjectData({ id: projectId as number })
        window.WorkspaceHelper.designIsLoading = true

        await fetchProjectData(projectId)
          .then((result) => {
            if (!window.Designer.AjaxSession.validateAjaxSessionToken(ajaxSessionToken)) {
              // break the promise chain if the token is invalid
              throw new Error('Ajax session token is invalid.')
            }
            if (window.WorkspaceHelper.designIsLoading === true) {
              window.studioDebug && console.warn('Loading new design but WorkspaceHelper.designIsLoading === true')
            }
            return result.json
          })
          .then((projectData) => {
            // routine redux updates
            updateCalcErrorsFromProjectData(projectData)
            updateSharingErrorsFromProjectData(projectData)
            updateProjectPermissionsFromProjectData(projectData)
            return projectData
          })
          .then((projectData) => {
            // Do not call loadStudioProfile() if we are creating a new design because it breaks default view detection.
            // Ignore this block if we are going to auto-create a new design and let this all happen inside <Design />
            // which also calls loadStudioProfile() but it does not have the same problem with default view detection.
            // @TODO: Untangle this whole aspect of loading/creating designs after awaiting necessary data.
            if (isNestedWindow() && window.editor.deserializeDesign(projectData.design)) {
              // This addresses some issues encountered in SDK when loading a project before component_specs
              // have finished loading. Non-SDK scenarios will just continue and let the component_specs load async.
              return window.WorkspaceHelper.loadStudioProfile({
                id: projectData.id,
                org_id: projectData.org_id,
                identifier: projectData.identifier,
              }).then(() => {
                return projectData
              })
            } else {
              return projectData
            }
          })
          .then((projectData) => {
            // parse and load, into studio, the project's design data
            //this will handle separately any errors caused by the design data
            loadDesignFromProjectData(projectData)
            return projectData
          })
          .then((projectData) => {
            // initialize the project form from raw project data
            const parsedData = parseInitialValues(projectData)
            dispatch(updateRecentProject(parsedData))
            setInitialValues(parsedData)
          })
          .catch((error: Error) => {
            // handle project-level errors here
            if (!window.Designer.AjaxSession.validateAjaxSessionToken(ajaxSessionToken)) {
              if (window.studioDebug) {
                console.log('******** request aborted token: ', ajaxSessionToken)
              }
              // skip sending error message if the request was aborted
              return
            }
            dispatch(
              showNotification(
                translate(`Failed to load project %{projectId}: %{error}`, { projectId, error }),
                'error'
              )
            )
            window.WorkspaceHelper.cancelLoading()
            Sentry.captureException(error)
            setErrorOnLoad(true)
          })
          .finally(() => {
            window.WorkspaceHelper.designIsLoading = false
          })
      } else {
        dispatch(projectPermissionsActions.inheritProjectPermissions())
      }

      setLoadedProjectId(projectId)
      console.debug('Project end load:', projectId)
    }

    initializeProjectData(projectId)

    return () => {
      dispatch(updateProjectSection())
      //clear helpers
      window.WorkspaceHelper.clear()
      window.WorkspaceHelper.cancelLoading()
      window.AccountHelper.reset()
      window.ViewBoxHelper.clear()
      window.ViewBoxHelper.clearStore()
      window.editor.clear()
      window.setStudioMode('hidden')
    }
  }, [projectId])

  if (authReloading || orgLoading || loadedProjectId !== projectId || !loadedProjPermissions || errorOnLoad) {
    return (
      <>
        <ProjectLoadErrorPrompt open={errorOnLoad} restartDesignFunc={restartDesignFunc} />
        <PageLoadingSkeleton showTopNav={true} />
      </>
    )
  } else {
    return (
      <div>
        <ProjectForm save={handleSave} previousUnsavedData={previousUnsavedData} initialValues={initialValues} />
      </div>
    )
  }
}

interface RouterProps {
  projectId: ProjectIdType
  previousUnsavedData: any
}

export default ProjectSections

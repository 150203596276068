import {
  BomGeneratorClass,
  LayoutAdjusterClass,
  MountingCalcInput,
  MountingCalcResult,
  MountingSystem,
  Panel,
  PanelBlock,
  PanelBlockPrecursor,
  PanelBlockWithUnpositionedPanels,
  UnpositionedPanelRow,
} from './types'

export abstract class MountingSystemAbstract implements MountingSystem {
  abstract layoutAdjusterClass: LayoutAdjusterClass
  abstract bomGeneratorClass: BomGeneratorClass

  protected input: MountingCalcInput

  constructor(input: MountingCalcInput) {
    this.input = input
  }

  async calculate(): Promise<MountingCalcResult> {
    const resultWithAdjustedBlocks: MountingCalcResult = {
      items: [],
      panelBlocks: this.calculateAdjustedBlocks(),
      fasteners: {},
      roofIndex: this.input.roof.roofIndex,
    }

    const resultWithBom = await this.generateBom(resultWithAdjustedBlocks)

    return resultWithBom
  }

  calculateAdjustedBlocks(): PanelBlock[] {
    if (!this.input.panelBlocks.length) return []

    const layoutAdjuster = new this.layoutAdjusterClass(this.input)

    return this.input.panelBlocks.map(createPanelBlock).flatMap((block) => layoutAdjuster.adjustBlock(block))
  }

  async generateBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    const panelBlocks = result.panelBlocks
    for (var blockIndex = 0; blockIndex < panelBlocks.length; blockIndex++) {
      const block = panelBlocks[blockIndex]
      const bomGenerator = new this.bomGeneratorClass(this.input, block, blockIndex)
      result = await bomGenerator.generateBom(result)
    }
    return result
  }
}

function createPanelBlock(panelBlockPrecursor: PanelBlockPrecursor): PanelBlockWithUnpositionedPanels {
  const { panel, moduleGrid } = panelBlockPrecursor

  const panelData: Panel = {
    height: panel.height * 1000, // convert m to mm
    width: panel.width * 1000, // convert m to mm
    thickness: panel.thickness,
    id: panel.id,
    splitJunctionBox: panel.splitJunctionBox,
    isActive: false,
  }

  // Transform data so coordinates start from 0, and increase from left-right and top-bottom
  var panels = moduleGrid.cellsActive.map((cell) => {
    const coords = cell.split(',')
    const column = Number(coords[0]) * -1
    const row = Number(coords[1])
    return { row, column }
  })
  const smallestColumnCoord = Math.min(...panels.map((panel) => panel.column))
  if (smallestColumnCoord < 0) panels.map((panel) => (panel.column += Math.abs(smallestColumnCoord)))
  const smallestRowCoord = Math.min(...panels.map((panel) => panel.row))
  if (smallestRowCoord < 0) panels.map((panel) => (panel.row += Math.abs(smallestRowCoord)))

  let numColumns = 0
  let numRows = 0
  panels.forEach(({ row, column }) => {
    if (row + 1 > numRows) numRows = row + 1
    if (column + 1 > numColumns) numColumns = column + 1
  })

  // Initialise an array of rows. Each row is an array with size equal to the number of columns
  // This guarantees we can access every coordinate in the block from now on
  const rows: UnpositionedPanelRow[] = Array.from(new Array(numRows), () =>
    Array.from(new Array(numColumns).fill(null).map(() => JSON.parse(JSON.stringify(panelData))))
  )

  panels.forEach(({ row, column }) => (rows[row][column].isActive = true))

  return {
    uuid: moduleGrid.uuid,
    panel: panelData,
    orientation: moduleGrid.moduleLayout(),
    top: panelBlockPrecursor.top,
    left: panelBlockPrecursor.left,
    rows,
  }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
export var TableViewIcon = function (props) {
    var _a = props.size, size = _a === void 0 ? 24 : _a, _b = props.color, color = _b === void 0 ? 'currentColor' : _b;
    var viewBox = "0 0 ".concat(size, " ").concat(size);
    return (_jsx("svg", __assign({ width: size, height: size, viewBox: viewBox, fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M10.5 4C10.5 3.44772 10.0523 3 9.5 3C8.94771 3 8.5 3.44772 8.5 4H10.5ZM8.5 20C8.5 20.5523 8.94771 21 9.5 21C10.0523 21 10.5 20.5523 10.5 20H8.5ZM7 5H17V3H7V5ZM19 7V17H21V7H19ZM17 19H7V21H17V19ZM5 17V7H3V17H5ZM7 19C5.89543 19 5 18.1046 5 17H3C3 19.2091 4.79086 21 7 21V19ZM19 17C19 18.1046 18.1046 19 17 19V21C19.2091 21 21 19.2091 21 17H19ZM17 5C18.1046 5 19 5.89543 19 7H21C21 4.79086 19.2091 3 17 3V5ZM7 3C4.79086 3 3 4.79086 3 7H5C5 5.89543 5.89543 5 7 5V3ZM8.5 4V20H10.5V4H8.5ZM4.5 10.5H19.5V8.5H4.5V10.5Z", fill: color }) })));
};

import { makeStyles } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import Tooltip from 'elements/tooltip/Tooltip'
import { useNewProjectsList } from 'hooks/useNewProjectsList'
import { useParsedContactData } from 'hooks/useParsedContactData'
import CustomerBadgeTooltipContent from 'projectSections/sections/manage3/common/CustomerBadgeTooltipContent'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import type { ContactType } from 'types/contacts'
import { NORMAL_CONTACT } from 'types/contacts'

const useStyles = makeStyles({
  tooltip: {
    backgroundColor: 'white !important',
    padding: '10px 0px 5px 0px',
    minWidth: '255px',
  },
})
export const ContactsBlock = ({ contactsData }: { contactsData: ContactType[] | undefined }) => {
  const classes = useStyles()
  const ui3Enabled = useNewProjectsList()
  const myOrgId = useSelector(authSelectors.getOrgId)
  const parsedContacts = useParsedContactData(contactsData)
  const firstContact = useMemo(() => (!!parsedContacts?.length ? parsedContacts[0] : undefined), [parsedContacts])
  const hasContactInfo = (contact) => {
    return contact?.email || contact?.phone
  }
  const shouldShowTooltip = hasContactInfo(firstContact)
  if (!contactsData || contactsData?.length === 0 || firstContact?.type === 1) {
    return <div>－</div>
  }
  if (ui3Enabled && firstContact)
    return (
      <Tooltip
        title={shouldShowTooltip ? <CustomerBadgeTooltipContent activeContactInfo={firstContact} /> : ''}
        interactive={true}
        classes={{ tooltip: classes.tooltip }}
      >
        <div style={{ color: '#1890ff' }}>{firstContact.display}</div>
      </Tooltip>
    )
  return (
    <div style={{ margin: 0, padding: 0 }}>
      {contactsData
        .filter((contact) => contact.type === NORMAL_CONTACT)
        .map((contact) => {
          return (
            <div style={{ clear: 'both', marginRight: 5 }} key={contact.id}>
              {myOrgId === contact.org_id ? (
                <Link
                  to={'/contacts/' + contact.id}
                  style={{
                    display: 'inline-block',
                    minWidth: 100,
                  }}
                >
                  <div>{contact ? contact.display : null}</div>
                </Link>
              ) : (
                <div>{contact ? contact.display : null}</div>
              )}
            </div>
          )
        })}
    </div>
  )
}

import awaiting_translation from './awaiting_translation.js'
import en from './en'
import en_US from './en_US.js'
import template_json from './template.json'

//default language package
export default {
  en: { ...awaiting_translation, ...en },
  en_US: { ...awaiting_translation, ...en_US },
}

export const templateTranslations = template_json

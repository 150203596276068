import Grid from '@material-ui/core/Grid'
import CustomField from 'elements/field/CustomField'
import DateInput from 'elements/input/DateInput'
import DateTimeInput from 'elements/input/DateTimeInput'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { SelectInput, TextInput } from 'react-admin'
import { useForm } from 'react-final-form'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import { getModelByFieldName } from 'resources/customFields/PredefinedModels'
import { validateDateTimeField, validateMultiSelectField, validateSelectField } from 'resources/customFields/validation'
import { CustomFieldType } from 'types/orgs'
import { dateParser } from 'util/misc'
import { getOrgFromState } from 'util/org'
import MultiSelectCustomField from './MultiSelectCustomField'

type CustomFieldsPropsType = {
  customFields: CustomFieldType[]
  style?: any
  fieldPrefix?: string
  shrinkLabel?: boolean
}

type ModelType = 'Project' | 'Contact' | 'COGS'

type PropTypes = {
  style?: any
  fieldPrefix?: string
  model: ModelType
  shrinkLabel?: boolean
}

export const getCustomFields = (customFields: CustomFieldType[] | undefined, model: ModelType) => {
  return customFields
    ? customFields.filter((config: CustomFieldType) => getModelByFieldName(config.field) === model)
    : []
}

const CustomFieldsContent: React.FunctionComponent<PropTypes> = ({ fieldPrefix, style, model, shrinkLabel }) => {
  const [isSafeToRender, setIsSafeToRender] = useState(false)
  const org = useSelector((state: any) => getOrgFromState(state))
  const customFields = useMemo(() => getCustomFields(org?.custom_fields, model), [])
  const form = useForm()
  useEffect(() => {
    const formValues = form.getState().values
    //handle field type changed
    form.batch(() => {
      customFields.forEach((config) => {
        //@ts-ignore
        const value = formValues[config.field]
        let hasInvalidValue = false
        switch (config.field_type) {
          case 0:
            break
          case 1:
            if (validateSelectField(value, config.select_field_choices?.split(',') || [])) {
              hasInvalidValue = true
            }
            break
          case 2:
            if (validateDateTimeField(value)) {
              hasInvalidValue = true
            }
            break
          case 3:
            if (validateDateTimeField(value)) {
              hasInvalidValue = true
            }
            break
          case 4:
            if (validateMultiSelectField(value, config.select_field_choices?.split(',') || [])) {
              hasInvalidValue = true
            }
            break
          default:
            break
        }

        if (hasInvalidValue) {
          form.change(config.field, '')
        }
      })
    })
    setIsSafeToRender(true)
  }, [])

  return (
    <Grid container spacing={3} style={{ padding: '0 10px' }}>
      {isSafeToRender ? (
        <CustomFields customFields={customFields} style={style} fieldPrefix={fieldPrefix} shrinkLabel={shrinkLabel} />
      ) : (
        <Skeleton height="50px" />
      )}
    </Grid>
  )
}

const defaultInputStyle = { width: '100%', marginTop: 20 }

export const CustomFields: FC<CustomFieldsPropsType> = ({
  fieldPrefix = '',
  customFields,
  style = defaultInputStyle,
  shrinkLabel = true,
}) => {
  return (
    <div style={{ width: '100%' }}>
      {customFields.map((config: CustomFieldType) => {
        switch (config.field_type) {
          case 0:
            return (
              <CustomField
                resource="projects"
                name={`${fieldPrefix}${config.field}`}
                label={config.label}
                source={`${fieldPrefix}${config.field}`}
                emptyValue={null}
                component={TextInput}
                style={style}
                options={{ InputLabelProps: { shrink: shrinkLabel } }}
                key={`${fieldPrefix}${config.field}`}
              />
            )
          case 1:
            return (
              <SelectInput
                resource="projects"
                name={`${fieldPrefix}${config.field}`}
                label={config.label}
                source={`${fieldPrefix}${config.field}`}
                showArchivedChoices={true}
                choices={(config.select_field_choices?.split(',') || []).map((value: string) => ({
                  id: value,
                  name: value,
                }))}
                options={{ InputLabelProps: { shrink: shrinkLabel } }}
                style={style}
                allowEmpty
                key={`${fieldPrefix}${config.field}`}
              />
            )
          case 2:
            return (
              // @ts-ignore
              <DateTimeInput
                resource="projects"
                name={`${fieldPrefix}${config.field}`}
                label={config.label}
                source={`${fieldPrefix}${config.field}`}
                options={{ InputLabelProps: { shrink: shrinkLabel } }}
                style={style}
                key={`${fieldPrefix}${config.field}`}
              />
            )
          case 3:
            return (
              <DateInput
                style={style}
                label={config.label}
                source={`${fieldPrefix}${config.field}`}
                parse={dateParser}
                key={`${fieldPrefix}${config.field}`}
              />
            )
          case 4:
            return (
              <MultiSelectCustomField
                fieldPrefix={fieldPrefix}
                fieldName={config.field}
                // @ts-ignore
                choices={config.select_field_choices}
                label={config.label}
                key={`${fieldPrefix}${config.field}`}
              />
            )
          default:
            return (
              <CustomField
                resource="projects"
                name={`${fieldPrefix}${config.field}`}
                label={config.label}
                source={`${fieldPrefix}${config.field}`}
                emptyValue={null}
                component={TextInput}
                style={style}
                options={{ InputLabelProps: { shrink: shrinkLabel } }}
                key={`${fieldPrefix}${config.field}`}
              />
            )
        }
      })}
    </div>
  )
}

export default CustomFieldsContent

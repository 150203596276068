import HardwareFilterV2 from 'elements/hardwareFilter/HardwareFilterV2'
import { HARDWARE_FILTERS_ORDER } from 'elements/hardwareFilter/constants'
import useFilterNodes from 'elements/hardwareFilter/hooks/useAvailableFilterSelectorNodes'
import type { FilterValuesType } from 'elements/hardwareFilter/type'
import Pagination from 'elements/pagination/Pagination'
import HardwareCatalog from 'pages/ordering/catalog/HardwareCatalog'
import useQueryControlledCollection from 'pages/ordering/hooks/useQueryControlledCollection'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import type { ComponentTypes } from 'types/selectComponent'
import { useFeatureFlag } from 'util/split'
import ShopResultsFilter from '../ShopResultsFilter'
import HardwareFilterSidebar from './HardwareFilterSidebar'

const useStyles = makeOpenSolarStyles((theme) => ({
  header: { padding: '0 20px' },
}))

/*
    @trackSource:
        used for tracking event
    @initialFilterValues:
        initial filter values
        As the name suggests, these are the initial filter values, and they won't be responsive to updates after the component's initial mounting.
    @title:
        title or description to display
    @componentType:
        component type used for filtering purpose
*/
const QueryControlledCatalog = ({
  trackSource,
  initialFilterValues,
  title,
  componentType,
}: {
  trackSource: string
  title?: React.ReactNode
  initialFilterValues: FilterValuesType
  componentType?: ComponentTypes
}) => {
  const history = useHistory()
  const classes = useStyles()
  const enableHardwareFilterV2 = useFeatureFlag('hardware_filter_v2', 'on')
  const enableHwNewSideBar = useFeatureFlag('hardware_new_sidebar', 'on')

  const filterNodes = useFilterNodes({ filtersNodes: HARDWARE_FILTERS_ORDER, componentType })

  const {
    page,
    perPage,
    setPage,
    setPerPage,
    setFilters,
    total = 0,
    productData,
    loading,
    persistentFilterValues,
    modifiableFilterValues,
    allFilterValues,
  } = useQueryControlledCollection({
    catalog: 'full',
    showExhibitedFirst: true,
    componentType,
    defaultFilterValues: initialFilterValues,
    limit: 20,
  })

  const handleFilter = useCallback(
    (newFilterValues: { [key: string]: unknown }) => {
      setFilters({ ...modifiableFilterValues, ...newFilterValues }, undefined)
    },
    [modifiableFilterValues]
  )

  const onNext = useCallback((encodedCode: string) => {
    history.push({
      pathname: `/shop/product/${encodedCode}`,
      state: { from: `${history.location.pathname}${history.location.search}` },
    })
  }, [])

  return (
    <>
      {enableHwNewSideBar && (
        <HardwareFilterSidebar
          persistentFilterValues={persistentFilterValues}
          modifiableFilterValues={modifiableFilterValues}
          allFilterValues={allFilterValues}
          trackSource={trackSource}
          setModifiableFilterValues={setFilters}
          componentType={componentType}
        />
      )}
      {!enableHwNewSideBar && (
        <div className={classes.header}>
          {enableHardwareFilterV2 ? (
            <HardwareFilterV2
              setModifiableFilters={setFilters}
              trackSource={trackSource}
              filterNodes={filterNodes}
              allFilterValues={allFilterValues}
              persistentFilterValues={persistentFilterValues}
              modifiableFilterValues={modifiableFilterValues}
            />
          ) : (
            <ShopResultsFilter
              setFilters={setFilters}
              trackSource={trackSource}
              handleUpdate={handleFilter}
              filterValues={modifiableFilterValues}
              componentType={componentType}
            />
          )}
          {title}
        </div>
      )}
      <HardwareCatalog
        trackSource={trackSource}
        justify="flex-start"
        data={productData}
        onNext={onNext}
        loading={loading}
        total={total}
        Pagination={
          <Pagination
            total={total}
            loading={loading}
            page={page}
            perPage={perPage}
            setPage={setPage}
            setPerPage={setPerPage}
            rowsPerPageOptions={[20]}
          />
        }
      />
    </>
  )
}

export default QueryControlledCatalog

import InfoTooltip from 'elements/tooltip/InfoTooltip'
import { SelectInput } from 'ra-ui-materialui'
import React from 'react'
import { FieldTitle, useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'
import { DOC_TEMPLATE_FORMAT } from './constants'

// Taken from OverviewInputs
export const styles = {
  label: {
    minWidth: 100,
    maxWidth: 100,
    marginRight: 10,
  },
}

export const DocumentFormatField: any = (props) => {
  const formState = useFormState()
  const isCreated = formState.values.id !== undefined
  const translate = useTranslate()

  const label = 'Editor'
  const source = 'format'
  const name = 'format'

  const tooltip = translate(
    'The PDF Drag-and-Drop is the fastest way to get your document setup. This option lets you upload your file and drag fields directly onto it without having to worry about formatting the underlying document. The Text-based/HTML Editor is the more flexible option. It allows for full customization and lets you use conditional logic to show or hide content based on things like whether a battery is included in the design.'
  )

  return (
    <>
      <span style={styles.label}>
        <FieldTitle label={label} source={source} resource={props.resource} />
      </span>
      <SelectInput
        name={name}
        source={source}
        choices={DOC_TEMPLATE_FORMAT}
        label={label}
        disabled={isCreated}
        {...props}
      />
      <InfoTooltip title={tooltip} />
    </>
  )
}

import PropTypes from 'prop-types'
import React from 'react'
import ImageDialog from './ImageDialog'

class DailyEnergyFlowsDialogNZ extends React.Component {
  render() {
    return (
      <ImageDialog
        isOpen={this.props.isOpen}
        closeDialog={this.props.closeDialog}
        title={this.props.title}
        src={window.PUBLIC_URL + '/images/myenergy/daily-energy-profile-solarZero.png'}
      />
    )
  }
}

DailyEnergyFlowsDialogNZ.propTypes = {
  isOpen: PropTypes.bool,
  closeDialog: PropTypes.func.isRequired,
  title: PropTypes.string,
}

DailyEnergyFlowsDialogNZ.defaultProps = {
  isOpen: false,
  title: null,
}

export default DailyEnergyFlowsDialogNZ

import { CircularProgress, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import classNames from 'classnames'
import { orgSelectors } from 'ducks/orgs'
import DividerWithText from 'elements/DividerWithText'
import { FileUpload } from 'elements/fileUpload/FileUpload'
import { Alert, Box, Button } from 'opensolar-ui'
import { useNotify, useTranslate } from 'ra-core'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { OpenSolarThemeType } from 'Themes'
import { Theme } from 'types/themes'
import {
  BlueSnapDocumentTypeMapping,
  BlueSnapDocUploadFileTypes,
  BlueSnapDocUploadRequest,
  BlueSnapOnboardingDocument,
} from './types'
import { fileToBase64, generateUploadDocumentUrl, uploadCashFlowDocuments } from './utils'

type PropTypes = {
  document: BlueSnapOnboardingDocument
  onSuccess: () => void
  isSubmitting: boolean
  setIsSubmitting: (value: boolean) => void
  isHosted: boolean
}

const useStyles = makeStyles<OpenSolarThemeType, { isMobile: boolean }>((theme) => ({
  textContainer: {
    margin: '5px 0',
  },
  documentDescription: {
    lineHeight: '22px',
    marginTop: '20px',
  },
  fileUploadContainer: ({ isMobile }) => ({
    position: 'relative',
    margin: isMobile ? '15px 0px' : '27px 20px',
  }),
  actionDivider: {
    color: '#7F7D83',
  },
  errorText: {
    color: '#E12121',
  },
  successText: {
    color: theme.alert_success,
  },
  qrImg: {
    height: 150,
    width: 150,
    margin: '15px 0px',
  },
  notesWrapper: {
    marginBottom: '24px',
  },
  notesContent: {
    marginTop: '15px',
    whiteSpace: 'pre-line',
  },
  descriptionTextWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '15px 0px 10px 0px',
  },
  descriptionTextLabel: {
    marginBottom: '5px',
  },
  descriptionTextField: {
    border: '1px solid lightgray',
    backgroundColor: 'rgb(249, 250, 255)',
    borderRadius: '5px',
    padding: '10px 15px',
    width: '100%',
    flexGrow: 1,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },
}))

const UploadDocumentContent: React.FC<PropTypes> = (props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const { document, onSuccess, isSubmitting, setIsSubmitting, isHosted = false } = props

  const orgId = useSelector(orgSelectors.getOrg)?.id
  const translate = useTranslate()
  const notify = useNotify()
  const classes = useStyles({ isMobile })
  const numberOfFilesUploadable = !document.docsReceived ? document.docLimit : document.docLimit - document.docsReceived
  const [errors, setErrors] = useState<string[]>([])
  const [uploaded, setUploaded] = useState<string[]>([])
  const [files, setFiles] = useState<File[]>([])
  const [hostedPageQrCode, setHostedPageQrCode] = useState(undefined)
  const [description, setDescription] = useState('')

  useEffect(() => {
    if (!isHosted && orgId) {
      generateUploadDocumentUrl(orgId, document)
        .then((response) => {
          setHostedPageQrCode(response.data.qrCode)
        })
        .catch((err) => {
          console.log('Unable to generate hosted url: ', err) // Silent failure
        })
    }
  }, [isHosted, orgId])

  useEffect(() => {
    setErrors([])
    setUploaded([])
  }, [files])

  const addFile = async (filesSelected: File[]) => {
    if (!filesSelected.length) {
      return
    }
    try {
      setFiles(filesSelected)
    } catch (error) {
      // notify(`Error uploading files: ${error}`)
      console.error('Error uploading files:', error)
    }
  }

  const deleteFile = (index: number) => {
    setFiles((f) => {
      const newFiles = [...f]
      newFiles.splice(index, 1)
      return newFiles
    })
  }

  const handleUpload = async () => {
    setIsSubmitting(true)
    setErrors([])
    setUploaded([])

    if (files.length) {
      const bluesnapDocUploadRequestPromises = files.map(async (file) => {
        return {
          docType: document.docType,
          title: file.name,
          fileType: file.name?.split('.').pop()?.toLowerCase() || '', // Let FileUpload handle empty file types
          description: description || document.docType,
          content: (await fileToBase64(file))?.split(',')?.pop() || '',
        }
      })
      const bluesnapDocuments: BlueSnapDocUploadRequest[] = await Promise.all(bluesnapDocUploadRequestPromises)
      const requestBody = {
        documents: bluesnapDocuments,
      }
      uploadCashFlowDocuments(orgId, requestBody)
        .then(() => {
          notify('Successfully uploaded documents!', 'success')
          onSuccess()
        })
        .catch((error) => {
          if (error.status_code >= 500) {
            setErrors([error.detail])
          } else {
            setErrors(error.body.errors)
            setUploaded(error.body.success)
          }
          notify(`Failed to upload documents, please see the error messages below`, 'warning')
        })
        .finally(() => {
          setIsSubmitting(false)
        })
    } else if (description) {
      // If only description is provided, we can just just generate a dummy "content" to satisfy the required field
      const requestBody = {
        documents: [
          {
            docType: document.docType,
            title: document.docType + '-no-file-placeholder.pdf',
            fileType: 'pdf',
            description: description,
            content: btoa('no-file-placeholder-content'),
          },
        ],
      }
      uploadCashFlowDocuments(orgId, requestBody)
        .then(() => {
          notify('Successfully uploaded documents!', 'success')
          onSuccess()
        })
        .catch((error) => {
          if (error.status_code >= 500) {
            setErrors([error.detail])
          } else {
            setErrors(error.body.errors)
            setUploaded(error.body.success)
          }
          notify(`Failed to upload documents, please see the error messages below`, 'warning')
        })
        .finally(() => {
          setIsSubmitting(false)
        })
    } else {
      notify('Please select files to upload.')
    }
  }

  return (
    <div>
      {document?.docUnderwriterNotes && (
        // @ts-ignore
        <Alert severity="warning" className={classes.notesWrapper} icon={false}>
          <b>{translate('Underwriter Notes: ')}</b>
          <div className={classes.notesContent}>{document.docUnderwriterNotes}</div>
        </Alert>
      )}
      <Typography className={classes.textContainer} variant="subtitle2">
        {translate('Additional business verification documents are required to setup CashFlow')}
      </Typography>
      <Box className={classes.textContainer}>
        <span>{translate('Please upload the following document: ')}</span>
      </Box>
      <Box className={classNames(classes.textContainer, classes.documentDescription)}>
        <Typography variant="subtitle2" display="inline">
          {translate(BlueSnapDocumentTypeMapping[document.docType]) || document.docType}
        </Typography>
        {!!document.docGenericDescription && <span>{` - ${document.docGenericDescription}`}</span>}
      </Box>
      <div className={classes.fileUploadContainer}>
        <FileUpload
          keyName="cashflow-documents"
          uploadFileSize={10}
          unit="MB"
          deleteFile={deleteFile}
          addFile={addFile}
          fileLinks={files}
          acceptedFormats={[...BlueSnapDocUploadFileTypes]}
          description="PNG, JPG, JPEG, PDF, XLSX, or TIFF - Max file size 10MB"
          variant={'large'}
          fileLimit={numberOfFilesUploadable}
        />
      </div>
      <div className={classes.descriptionTextWrapper}>
        <Typography variant="subtitle2" className={classes.descriptionTextLabel}>
          {translate('Description')}
        </Typography>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          maxLength={1000}
          className={classes.descriptionTextField}
          placeholder={translate('Input description of uploaded files (optional) or add text when no file is requested')}
        />
        <p>{`${description?.length || 0}/1000`}</p>
      </div>

      <Box padding="24px 0px">
        {Boolean(errors.length) && (
          <Box paddingBottom="15px">
            <ul>
              {uploaded.map((uploadedText) => (
                <li className={classes.successText} key={uploadedText}>
                  {uploadedText}
                </li>
              ))}
              {errors.map((error) => (
                <li className={classes.errorText} key={error}>
                  {error}
                </li>
              ))}
            </ul>
          </Box>
        )}
        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            color={isSubmitting ? 'default' : 'primary'}
            onClick={handleUpload}
            disabled={isSubmitting || (!files.length && !description)}
            startIcon={isSubmitting ? <CircularProgress size={18} thickness={2} /> : ''}
          >
            <span>{translate(isSubmitting ? 'Uploading' : 'Upload Document(s)')} </span>
          </Button>
        </Box>
      </Box>
      {hostedPageQrCode && !isSubmitting && (
        <>
          <DividerWithText>
            <Typography className={classes.actionDivider} variant="subtitle2">
              OR
            </Typography>
          </DividerWithText>
          <Box display="flex" flexDirection="column" alignItems="center" marginBottom="24px">
            <img src={hostedPageQrCode} className={classes.qrImg} />
            <Typography>{translate('Scan and Upload files from your mobile device')}</Typography>
          </Box>
        </>
      )}
    </div>
  )
}

export default UploadDocumentContent

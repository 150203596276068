import { TableCell, TableRow, makeStyles } from '@material-ui/core'
import { TrendingUp } from '@material-ui/icons'
import { OpenSolarThemeType } from 'Themes'
import { orgSelectors } from 'ducks/orgs'
import { Button } from 'opensolar-ui'
import { useSystem } from 'projectSections/sections/payments/hooks'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { LightReachPricingOptionType } from 'types/paymentOptions'
import { formatCurrencyWithSymbol } from 'util/misc'

type PropTypes = {
  pricingOption: LightReachPricingOptionType
  systemUuid: string | null
  paymentOptionId: number
  estimatedUtilitySavings: number
  key: number
  selectedPricing
  setSelectedPricing
}

const useStyles = makeStyles<OpenSolarThemeType>((theme) => ({
  selectedBadge: {
    background: '#4272DD',
    color: theme.white,
    padding: '5px 10px',
    fontSize: 14,
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  cellFlex: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },

  icon: {
    color: theme.alert_success,
    fontSize: 15,
  },
}))

const LightReachPricingBandRow: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const currencySymbol = useSelector(orgSelectors.getCurrencySymbolFromOrg)

  const system = useSystem(props.systemUuid)

  const monthlySavings = useMemo(() => {
    if (!system) return undefined
    const savings = props.estimatedUtilitySavings - props.pricingOption.monthlyPayments?.[0]?.totalMonthlyPayment
    if (savings && savings > 0) return formatCurrencyWithSymbol(savings, currencySymbol, undefined, 2)
  }, [system, currencySymbol, props.estimatedUtilitySavings, props.pricingOption.monthlyPayments])

  return (
    <TableRow key={props.key}>
      <TableCell>{formatCurrencyWithSymbol(props.pricingOption.kwhRate, currencySymbol, undefined, 4)}</TableCell>
      <TableCell>
        {formatCurrencyWithSymbol(
          props.pricingOption.monthlyPayments?.[0]?.totalMonthlyPayment,
          currencySymbol,
          undefined,
          2
        )}
      </TableCell>
      <TableCell>
        <div className={classes.cellFlex}>
          {monthlySavings ? (
            <>
              <span>{monthlySavings}</span>
              <TrendingUp className={classes.icon} />
            </>
          ) : null}
        </div>
      </TableCell>
      <TableCell>{formatCurrencyWithSymbol(props.pricingOption.ppwRate, currencySymbol, undefined, 2)}</TableCell>
      <TableCell>
        {formatCurrencyWithSymbol(props.pricingOption.totalEPCPayment, currencySymbol, undefined, 0)}
      </TableCell>
      <TableCell>
        {props.selectedPricing === props.pricingOption.productId ? (
          <div className={classes.selectedBadge}>
            <span> {translate('Selected')}</span>
          </div>
        ) : (
          <Button
            onClick={() => props.setSelectedPricing(props.pricingOption.productId)}
            variant="outlined"
            size="small"
          >
            {translate('Select')}
          </Button>
        )}
      </TableCell>
    </TableRow>
  )
}
export default LightReachPricingBandRow

import { CheckContainedIcon, Switch } from 'opensolar-ui'
import { FC } from 'react'

export const SwitchBasic: FC = () => {
  return (
    <>
      {/* SNIPPET START 1 */}
      <Switch />
      <Switch checked={true} />
      {/* SNIPPET END 1 */}
    </>
  )
}

export const SwitchColours: FC = () => {
  return (
    <>
      {/* SNIPPET START 2 */}
      <Switch color="default" defaultChecked={true} />
      <Switch color="primary" defaultChecked={true} />
      <Switch color="secondary" defaultChecked={true} />
      <Switch color="info" defaultChecked={true} />
      <Switch color="success" defaultChecked={true} />
      <Switch color="warning" defaultChecked={true} />
      <Switch color="error" defaultChecked={true} />
      {/* SNIPPET END 2 */}
    </>
  )
}

export const SwitchSizes: FC = () => {
  return (
    <>
      {/* SNIPPET START 3 */}
      <Switch size="small" />
      <Switch size="medium" />
      {/* SNIPPET END 3 */}
    </>
  )
}

export const SwitchIcons: FC = () => {
  return (
    <>
      {/* SNIPPET START 4 */}
      {/* @ts-ignore */}
      <Switch checkedIcon={<CheckContainedIcon size={15} />} checked={true} />
      {/* @ts-ignore */}
      <Switch icon={<CheckContainedIcon size={15} />} />
      {/* SNIPPET END 4 */}
    </>
  )
}

export const SwitchDisabled: FC = () => {
  return (
    <>
      {/* SNIPPET START 5 */}
      <Switch disabled={true} checked={true} />
      <Switch disabled={true} />
      {/* SNIPPET END 5 */}
    </>
  )
}

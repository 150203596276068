import type { ComponentTypes } from 'types/selectComponent'
import { getConfigByComponentType } from '../manufacturer/FeatureConfigManufacturerFilter'
import type { FilterComponentNode, FilterComponentRendererType, FilterGenericPropsType } from '../type'
import ExhibitorOrgFilter, { ExhibitorFilterOptionsType } from './ExhibitorOrgFilter'

export const EXHIBITOR_FILTER_KEY = 'exhibitor_org_id'

const getExhibitorOptions = ({
  configs,
  orgIso2,
}: {
  configs
  orgIso2: string | undefined
}): ExhibitorFilterOptionsType | undefined => {
  if (!configs || configs.length === 0) {
    return undefined
  }

  const options: ExhibitorFilterOptionsType = configs.reduce((result, option) => {
    if (option.include_country && !option.include_country.includes(orgIso2)) {
      return result
    }
    // TODO: fix the hack to parse value by split
    const value = option.value.split('exhibitor_org_id_')[1]
    if (value) {
      result.push({ title: option.title, value: value, id: option.title + option.value })
    }
    return result
  }, [])

  if (options.length === 0) {
    return undefined
  }

  options.unshift({
    title: 'All',
    value: '',
    id: 'default',
  })

  return options
}

const maybeCreateExhibitorOrgFilterNode = ({
  RendererComponent,
  componentType,
  manufacturerConfig,
  orgIso2,
}: {
  RendererComponent: FilterComponentRendererType<ExhibitorFilterOptionsType>
  componentType?: ComponentTypes
  manufacturerConfig
  orgIso2: string | undefined
}): FilterComponentNode | undefined => {
  if (!componentType) {
    return undefined
  }

  if (!manufacturerConfig) {
    return undefined
  }

  const exhibitorOptions = getExhibitorOptions({
    configs: getConfigByComponentType({ manufacturerConfig, componentType }),
    orgIso2,
  })

  if (!exhibitorOptions) {
    return undefined
  }

  const FilterComponent = (props: FilterGenericPropsType) => (
    <ExhibitorOrgFilter
      exhibitorOptions={exhibitorOptions}
      filterKey={EXHIBITOR_FILTER_KEY}
      RendererComponent={RendererComponent}
      {...props}
    />
  )

  return {
    key: EXHIBITOR_FILTER_KEY,
    component: FilterComponent,
  }
}

export default maybeCreateExhibitorOrgFilterNode

import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { DefaultBreadCrumbs } from 'elements/BreadCrumbs'
import DependentInput from 'elements/input/DependentInput'
import { List } from 'elements/react-admin/List'
import { Button } from 'opensolar-ui'
import React from 'react'
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  Edit,
  EditButton,
  FilterComp,
  FunctionField,
  SimpleForm,
  TextField,
  TextInput,
  TopToolbar,
  showNotification,
  useTranslate,
} from 'react-admin'
import useStyle from 'resources/autoApply/styles'
import restClient from 'restClient'
import { styles as standardStyles } from 'styles'
import QuickFilter from '../../elements/QuickFilter'

var restClientInstance = restClient(window.API_ROOT + '/api')

const validateConfirmation = (confirmEmail, reference) => {
  if (!confirmEmail || !reference) {
    return 'Email and reference must be provided'
  } else if (reference.indexOf('@') !== -1 || reference.indexOf(' ') !== -1) {
    // Reject if using @ or space character
    return 'Reference is invalid'
  } else {
    return true
  }
}

export const GlobalUserFilter = (props) => (
  <FilterComp {...props} displayedFilters={{ q: true }}>
    <TextInput label="pos.search" source="q" style={standardStyles.FILTER_FIELD_STYLE} />
    <QuickFilter label="Staff" source="is_staff" defaultValue={1} />
    <QuickFilter label="SuperUser" source="is_superuser" defaultValue={1} />
  </FilterComp>
)

export const GlobalUserList = (props) => {
  const translate = useTranslate()
  return (
    <List hasSearch={true} filters={<GlobalUserFilter />} minQuantityToShowAsList={0} {...props}>
      <Datagrid cardTextStyle={{ padding: 15, height: 150, overflow: 'auto' }}>
        <TextField source="id" />
        <TextField source="email" />
        <BooleanField source="is_staff" />
        <FunctionField
          source={null}
          label={translate('Actions')}
          render={(record, source, rest) => (
            <div>
              <EditButton useListStyle={true} record={record} basePath={rest.basePath} />
            </div>
          )}
          sortable={false}
        />
      </Datagrid>
    </List>
  )
}

const inputStyle = {
  width: 256,
}

function anonymizeUser(userId) {
  var confirmEmail = prompt('Confirm email')
  var reference = prompt('Reference for record keeping (no personal info)')
  var isValid = validateConfirmation(confirmEmail, reference)
  if (isValid !== true) {
    alert(isValid)
  } else {
    restClientInstance('CUSTOM_PATCH', 'custom', {
      url: 'global_users/' + userId + '/anonymize/',
      data: { email: confirmEmail, reference: reference },
    }).then(
      (response) => {
        showNotification(window.translate(response?.data?.message || 'Success'), 'success')
      },
      (reject) => {
        showNotification(window.translate(reject.message || 'Error'), 'danger')
      }
    )
  }
}

function clearMfa(userId) {
  if (window.confirm("Clearing MFA will remove all of this user's MFA devices, if they exist, are you sure?")) {
    restClientInstance('CUSTOM_POST', 'custom', {
      url: 'mfa/superuser_clear/',
      data: { user_id: userId },
    }).then(
      (response) => {
        showNotification(window.translate(response?.data?.message || 'Success'), 'success')
      },
      (reject) => {
        showNotification(window.translate(reject.message || 'Error'), 'danger')
      }
    )
  }
}

const GlobalUserEditActions = (props) => {
  const translate = useTranslate()
  return (
    <TopToolbar>
      <Button style={{ margin: 5 }} variant="outlined" onClick={() => anonymizeUser(props.id)}>
        {translate('Anonymize User')}
      </Button>
      <Button style={{ margin: 5 }} variant="outlined" onClick={() => clearMfa(props.id)}>
        {translate('Clear MFA')}
      </Button>
      {/* <DeleteButton style={{ margin: 5 }} size="large" mutationMode="pessimistic" record={props.data} {...props} /> */}
    </TopToolbar>
  )
}

export const GlobalUserEdit = (props) => {
  const translate = useTranslate()
  const classes = useStyle()
  return (
    <Edit breadCrumbs={<DefaultBreadCrumbs />} actions={<GlobalUserEditActions {...props} />} {...props}>
      <SimpleForm redirect="edit">
        <BooleanInput style={inputStyle} source="is_staff" />
        <BooleanInput style={inputStyle} source="is_superuser" />
        <BooleanInput style={inputStyle} source="is_active" />
        <TextField style={inputStyle} source="email" />
        <TextField style={inputStyle} source="phone" />
        <TextField style={inputStyle} source="login_authority" />
        <TextField style={inputStyle} source="external_user_id" />
        <TextField style={inputStyle} source="external_account_id" />
        <TextField style={inputStyle} source="terms_accepted_date" />
        <DependentInput
          dependsOn={['is_staff', 'is_superuser']}
          resolve={(is_staff, is_superuser) => is_staff && !is_superuser}
        >
          <TextInput style={inputStyle} source="staff_access_countries_csv" />
        </DependentInput>
        <FunctionField
          source={null}
          label={'List of orgs this user has access to'}
          render={(record, source, rest) => (
            <Table className={classes.tableStyles}>
              <TableHead>
                <TableRow>
                  <TableCell>{translate('Org Name')}</TableCell>
                  <TableCell>{translate('Org ID')}</TableCell>
                  <TableCell>{translate('First Name')}</TableCell>
                  <TableCell>{translate('Last Name')}</TableCell>
                  <TableCell>{translate('Role')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {record.roles?.map((role) => {
                  return (
                    <TableRow key={role.id}>
                      <TableCell>{role.org_name}</TableCell>
                      <TableCell>{role.org_id}</TableCell>
                      <TableCell>{role.first_name}</TableCell>
                      <TableCell>{role.family_name}</TableCell>
                      <TableCell>{role.permissions_role_title}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          )}
          sortable={false}
        />
      </SimpleForm>
    </Edit>
  )
}

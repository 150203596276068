import { makeStyles } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIosOutlined'
import React from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  header: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    margin: '10px 16px',
  },
  headerLeft: {
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.54)',
    verticalAlign: 'middle',
    fontWeight: 500,
  },
  headerRight: {
    fontSize: 14,
    textDecoration: 'none',
  },
  viewAll: {
    verticalAlign: 'middle',
  },
  viewAllIcon: {
    width: 18,
    height: 18,
    verticalAlign: 'middle',
  },
}))

export const RecentProjectsSubHeader = () => {
  const classes = useStyles()
  const translate = useTranslate()
  const recentProjects = useSelector((state: any) => state.recentProjects)
  return (
    <div className={classes.header}>
      <span className={classes.headerLeft}>{translate('Recent Projects')}</span>
      <a
        className={classes.headerRight}
        style={{ color: 'rgba(24, 145, 255, 1)' }}
        href={
          '#/projects?filter=%7B%22filter%22%3A%22' +
          (recentProjects && recentProjects.length > 0
            ? recentProjects.map((recentProject: any) => recentProject.id).join('%2C')
            : '-1') +
          '%22%7D&order=DESC&page=1&perPage=20&sort=id'
        }
      >
        <span className={classes.viewAll}>{translate('View All')}</span>{' '}
        <ArrowForwardIosIcon className={classes.viewAllIcon} />
      </a>
    </div>
  )
}

export const RecentFiltersSubHeader = () => {
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <div className={classes.header}>
      <span className={classes.headerLeft}>{translate('My Recent Filters')}</span>
    </div>
  )
}

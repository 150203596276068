import { ComponentVersions_3_0 } from 'constants/uxVersions'
import { permissionsSelectors } from 'ducks/permissions'
import { ComponentVersionsInherit, styled } from 'opensolar-ui'
import { useSelector } from 'react-redux'
import { FilesContextWrapper } from '../common/FilesContextWrapper'
import { ProjectFilesList } from './ProjectFilesList'

export const MainWrapper = styled('div')({
  padding: 24,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
})

export const BackgroundOverlay = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  overflow: 'clip',
  backgroundColor: '#F6F9FC',
})

export type ViewMode = 'tableView' | 'gridView'

export const FilesPage = (props) => {
  const { allowEdit } = useSelector(permissionsSelectors.getPermissionByKey('info_documents'))

  return (
    <ComponentVersionsInherit versions={ComponentVersions_3_0}>
      <BackgroundOverlay />
      <MainWrapper>
        <FilesContextWrapper>
          <ProjectFilesList {...props} allowEdit={allowEdit} />
        </FilesContextWrapper>
      </MainWrapper>
    </ComponentVersionsInherit>
  )
}

import { orgSelectors } from 'ducks/orgs'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import CityPlumbingOrderConfirmationPage from './checkout/cityPlumbing/CityPlumbingOrderConfirmationPage'
import OutletOrderConfirmationPage from './checkout/solarOutlet/OutletOrderConfirmationPage'
import { HardwareSupplierEnum } from './type'

const OrderConfirmationPage = (props) => {
  const supplier = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const orderId = props?.match?.params?.orderId

  switch (supplier) {
    case HardwareSupplierEnum.SolarOutlet:
      return <OutletOrderConfirmationPage orderId={orderId} />
    case HardwareSupplierEnum.CityPlumbing:
      return <CityPlumbingOrderConfirmationPage orderId={orderId} />
    default:
      return null
  }
}

export default memo(OrderConfirmationPage)

import { Card, TextField, Theme, Tooltip, makeStyles } from '@material-ui/core'
import { AccountBalanceOutlined, DeleteOutline, HelpOutline, InfoOutlined } from '@material-ui/icons'
import { addProjectErrorPrompt } from 'actions/project'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { myEnergySelectors } from 'ducks/myEnergy'
import { orgSelectors } from 'ducks/orgs'
import { paymentOptionSelectionSelectors, triggerSystemRefresh } from 'ducks/paymentOptionSelection'
import Alert from 'elements/Alert'
import { MilestoneOverrideType } from 'pages/cashFlow/types'
import { useLocale, useNotify, useTranslate } from 'ra-core'
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import Skeleton from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux'
import { LENDER_LOGO_PATHS } from 'resources/financeIntegrations/constants'
import { PaymentFrequencyTypeEnum } from 'types/paymentOptions'
import { QuotationLineType } from 'types/quotationLines'
import { PaymentOptionSettingsOverridesType } from 'types/systems'
import { useDebouncedCallback } from 'util/Debounce'
import {
  formatCurrencyWithSymbol,
  formatDecimalPlaces,
  generateUUID,
  getIsDecisionApproval,
  parseJsonSafe,
  parseStringFieldToFloat,
  urlToId,
} from 'util/misc'
import IntegratedChip from '../IntegratedChip'
import SoldChip from '../SoldChip'
import { useGetPaymentRequests } from '../cashFlowTransactions/utils'
import { ALL_OR_NOTHING_REMOVED_WARNING } from '../constants'
import { useGetIntegration, useGetMostUrgentMessage, useSystem } from '../hooks'
import { ALL_OR_NOTHING_INTEGRATIONS, PAYMENT_TYPE_LABEL_MAP } from '../paymentOptionSelection/constants'
import { CalculatedPaymentOptionMessageType } from '../paymentOptionSelection/types'
import { formatAmount, useGetPaymentOptionDataFromSystem } from '../utils'
import ArchivedPaymentOptionAlert from './ArchivedPaymentOptionAlert'
import DragIcon from './DragIcon'
import MilestonePaymentsTable from './MilestonePaymentsTable'
import PaymentOptionCardLightReachDropDown from './PaymentOptionCardLightReachDropDown'
import PaymentOptionCardProductDetails from './PaymentOptionCardProductDetails'
import PaymentOptionCashFlowDropDown from './PaymentOptionCashFlowDropDown'
import SeeDetails from './SeeDetails'
import CardActions from './cardActions'

type PropTypes = {
  paymentType: string
  title: string
  integration: string | undefined | null
  monthlyPayment: number | undefined | null
  ppaPricePerUnit: number | undefined | null
  paymentPerPeriod: number | undefined | null
  paymentFrequency: PaymentFrequencyTypeEnum | null | undefined
  totalPrice: number | undefined | null
  upfrontPayment: number | undefined
  term: number | undefined | null
  interestRate: number | undefined | null
  id: number
  isCalculating: boolean
  systemUuid: string | null
  systemId: number | null
  quotationLines: QuotationLineType[]
  setPmtIdBeingDragged: (val: number | undefined) => void
  messages: CalculatedPaymentOptionMessageType[]
  isGhost: boolean
  disableDrag: boolean
  hideGhostCard?: () => void
  interestRateDisclaimer: null | string | undefined
}

const useStyles = makeStyles<
  Theme,
  { isCalculating: boolean; isGhost: boolean; otherPmtIsSold: boolean; disableDrag: boolean }
>((Theme) => ({
  messageAlert: {
    width: '225px',
  },
  errorPromptLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '250px',
    border: ({ isGhost }) => (isGhost ? '3px dashed rgb(249, 214, 72)' : undefined),
    minHeight: ({ isGhost }) => (isGhost ? '535px' : '485px'),
    padding: '10px 15px 15px 15px',
    opacity: ({ isCalculating, otherPmtIsSold }) => (isCalculating || otherPmtIsSold ? 0.5 : 1.0),
    position: 'relative',
  },
  ghostCardTitleRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgb(245, 248, 252)',
    width: '100%',
    position: 'absolute',
    left: '0px',
    right: '0px',
    top: '0px',
    padding: '6px 0px',
  },
  ghostCardTitleText: {
    color: 'rgb(38,82, 200)',
    marginLeft: '5px',
  },
  dragWrapper: {
    position: 'absolute',
    left: '5px',
    top: ({ isGhost }) => (isGhost ? '40px' : '5px'),
    cursor: 'grab',
  },
  deleteWrapper: {
    position: 'absolute',
    right: '5px',
    top: ({ isGhost }) => (isGhost ? '40px' : '5px'),
    cursor: 'pointer',
  },
  paymentTypeText: {
    fontSize: '18px',
    textAlign: 'center',
  },
  labelText: {
    marginTop: '5px',
    color: 'rgb(117, 117, 117)',
    textAlign: 'center',
  },
  monthlyPaymentText: {
    fontSize: '32px',
    color: 'rgb(67, 167, 69)',
  },
  titleSectionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justfiyContent: 'center',
    alignItems: 'center',
    height: '45px',
    marginTop: ({ isGhost }) => (isGhost ? '35px' : undefined),
  },
  sectionWrapper: {
    marginTop: '15px',
    display: 'flex',
    flexDirection: 'column',
    justfiyContent: 'center',
    alignItems: 'center',
    height: '60px',
  },
  actionsWrapper: {
    marginTop: '0px',
    display: 'flex',
    flexDirection: 'column',
    justfiyContent: 'center',
    alignItems: 'center',
    height: '100px',
  },
  approvedChipWrapper: {
    marginTop: '20px',
  },
  reApplyWrapper: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    textDecoration: 'underline',
    color: 'rgb(62, 142, 332)',
    marginTop: '20px',
    textAlign: 'center',
  },
}))

const PaymentOptionCard: React.FC<PropTypes> = (props) => {
  const form = useForm()
  const project = useFormState().values

  const currencySymbol = useSelector(orgSelectors.getCurrencySymbolFromOrg)
  // after an approval happens within the payment page we update store the sold pmt and system in redux so the UI can immediaetly reflect the approval
  const justSoldSystemUuid = useSelector(myEnergySelectors.getSystemUuidForcedMarkedAsSold)
  const justSoldPmtId = useSelector(myEnergySelectors.getPmtIdForcedMarkedAsSold)
  const locale = useLocale()
  const availablePaymentOptions = useSelector(paymentOptionSelectionSelectors.getAvailablePaymentOptions)

  const isPendingSave = form.mutators.getFormDirtyFields()?.length > 0
  const [editedUpfrontPayment, setEditedUpfrontPayment] = useState<string>(
    props.upfrontPayment ? formatAmount(props.upfrontPayment, '', locale) : '0'
  )
  const [isPendingDeletion, setIsPendingDeletion] = useState<boolean>(false)
  const [hasIntegratedApproval, setHasIntegratedApproval] = useState<boolean>(false)
  const [isSold, setIsSold] = useState<boolean>(false)
  const [otherPmtIsSold, setOtherPmtIsSold] = useState<boolean>(false)
  const [dismissApprovedChip, setDismissApprovedChip] = useState<boolean>(false)
  const [messageToLog, setMessageToLog] = useState<string | undefined>(undefined)

  const mostUrgentMessage = useGetMostUrgentMessage(props.messages)

  const paymentOption = useGetPaymentOptionDataFromSystem(props.systemUuid, props.id)
  const system = useSystem(props.systemUuid)
  const { paymentRequests } = useGetPaymentRequests(project.id, system, paymentOption)

  const depositInvoiceSent = useMemo(() => {
    const activeDeposits = paymentRequests?.filter(
      (pmtReq) =>
        pmtReq.is_deposit &&
        pmtReq.status !== 'cancelled' &&
        pmtReq.status !== 'unsent' &&
        pmtReq.payment_option === props.id
    )
    return !!activeDeposits?.length
  }, [paymentRequests])

  useEffect(() => {
    if (paymentRequests?.length && !props.isCalculating) {
      const firstDeposit = paymentRequests?.filter((pmtReq) => {
        return pmtReq.is_deposit && !['draft', 'cancelled'].includes(pmtReq.status)
      })?.[0]
      if (firstDeposit) {
        // only update the value in the input field if we are either just mounting this component now or the paymentRequest have changed
        // as a result of a recent calcs run. We need to be sure that we don't just always pull the value from calcs
        // since the user can edit this field and it will take a few seconds for their edit to run through calcs and be available in the payment option data
        setEditedUpfrontPayment(formatAmount(firstDeposit.amount_requested, '', locale))
      }
    }
  }, [paymentRequests])

  useEffect(() => {
    if (props.isGhost) {
      logAmplitudeEvent('payment_ghost_card_shown', { project_id: project.id })
    }
  }, [props.isGhost])

  useEffect(() => {
    if (mostUrgentMessage) {
      setTimeout(() => {
        const newMessage = mostUrgentMessage.message || mostUrgentMessage.text
        if (messageToLog !== newMessage) setMessageToLog(newMessage)
      }, 500)
    }
  }, [mostUrgentMessage])

  useEffect(() => {
    if (messageToLog && mostUrgentMessage) {
      logAmplitudeEvent('payment_option_error_shown', {
        message: messageToLog,
        type: mostUrgentMessage.type,
        error_prompt_id: mostUrgentMessage.error_prompt_id,
        placement: 'payments page',
      })
    }
  }, [messageToLog, mostUrgentMessage])

  useEffect(() => {
    if ((justSoldPmtId && justSoldSystemUuid) || (props.integration && project?.transactions_data?.length)) {
      let foundApproval = false
      project.transactions_data?.forEach((transaction) => {
        if (urlToId(transaction.payment_option) === props.id && urlToId(transaction.system) === props.systemId) {
          if (getIsDecisionApproval(transaction.external_status_title)) foundApproval = true
        }
      })
      if (!foundApproval && justSoldPmtId && justSoldSystemUuid) {
        if (justSoldPmtId === props.id && justSoldSystemUuid === props.systemUuid) {
          foundApproval = true
        }
      }
      setHasIntegratedApproval(foundApproval)
    }
  }, [project?.transactions_data, justSoldPmtId, justSoldSystemUuid])

  useEffect(() => {
    // give preference to what is saved on the project. But if those aren't present use redux values
    if ((justSoldPmtId && justSoldSystemUuid) || (project.payment_option_sold && project.system_sold)) {
      const soldPmtId = project.payment_option_sold ? urlToId(project.payment_option_sold) : justSoldPmtId
      const soldSystemId = project.system_sold ? urlToId(project.system_sold) : undefined
      const soldSystemUuid = soldSystemId
        ? project?.systems?.find((sys) => sys.id === soldSystemId)?.uuid
        : justSoldSystemUuid
      if (soldPmtId === props.id && soldSystemUuid === props.systemUuid) {
        setIsSold(true)
        setOtherPmtIsSold(false)
      } else {
        setIsSold(false)
        setOtherPmtIsSold(true)
      }
    }
  }, [project.payment_option_sold, project.system_sold, justSoldPmtId, justSoldSystemUuid])

  const integration = useGetIntegration(props.integration)

  const classes = useStyles({
    isCalculating: props.isCalculating,
    isGhost: props.isGhost,
    otherPmtIsSold,
    disableDrag: props.disableDrag,
  })

  const dispatch = useDispatch()
  const translate = useTranslate()
  const notify = useNotify()

  const onUpfrontPaymentChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEditedUpfrontPayment(e.target.value)
    handleUpfrontPaymentChange(e.target.value)
  }

  const handleUpfrontPaymentChange = useDebouncedCallback((value: string) => {
    const valueAsFloat = parseStringFieldToFloat(value, locale)
    const system = window.editor.getSystems()?.find((sys) => sys.uuid === props.systemUuid)
    const paymentOption = system?.payment_options?.find((pmt) => pmt.id === props.id)
    const currentVal: PaymentOptionSettingsOverridesType = { ...system?.payment_options_settings_overrides }
    if (currentVal[props.id]) {
      currentVal[props.id].down_payment = valueAsFloat
    } else {
      currentVal[props.id] = {
        down_payment: valueAsFloat,
        price: undefined,
      }
    }
    if (paymentRequests?.length && paymentRequests[0] && system && paymentOption) {
      // update milestone overrides
      let numeric = valueAsFloat
      if (Number.isNaN(numeric)) {
        numeric = 0
      }
      const overrideMap = system.milestone_payment_overrides || {}

      // @ts-ignore
      overrideMap[props.id] = paymentRequests?.map((milestone) => {
        let fixedOverrideAmount = milestone.payment_milestone_override_uuid ? milestone.amount_requested : null
        let fixedOverridePercentage = milestone.payment_milestone_override_uuid ? milestone.percentage : null
        return {
          ...milestone,
          override_percentage: fixedOverridePercentage,
          percentage: fixedOverridePercentage,
          override_uuid: milestone.payment_milestone_override_uuid,
          fixed_override_amount: fixedOverrideAmount,
        }
      })

      // if the upfront payment override is > 0, update the first element in the override array with the input value
      if (numeric) {
        if (overrideMap[props.id][0].is_deposit) {
          overrideMap[props.id][0].fixed_override_amount = numeric
          overrideMap[props.id][0].override_percentage = (numeric / paymentOption.pricing?.system_price_payable) * 100
          overrideMap[props.id][0].override_uuid = generateUUID()
        } else {
          let newDepositOverride: MilestoneOverrideType = {
            is_deposit: true,
            fixed_override_amount: numeric,
            override_percentage: (numeric / paymentOption.pricing?.system_price_payable) * 100,
            // @ts-ignore
            percentage: (numeric / paymentOption.pricing?.system_price_payable) * 100,
            override_uuid: generateUUID(),
            title: translate('Deposit'),
            description: null,
            payment_number: 1,
            payment_milestone_configuration_id: null,
          }
          overrideMap[props.id] = [newDepositOverride, ...overrideMap[props.id]]
        }
      } else {
        // if the upfront payment override is 0 remove the first element
        let newOverrides: MilestoneOverrideType[] = []
        overrideMap[props.id]?.forEach((override) => {
          if (override.payment_number > 1) {
            override.payment_number -= 1
            newOverrides.push(override)
          }
        })
        overrideMap[props.id] = newOverrides
      }

      // re-number all of the milestones to account for there potentially being a new number of milestones
      overrideMap[props.id]?.forEach((milestone, i) => {
        milestone.payment_number = i + 1
      })
      window.editor.execute(new window.SetValueCommand(system, 'milestone_payment_overrides', overrideMap))
    }
    window.editor.execute(new window.SetValueCommand(system, 'payment_options_settings_overrides', currentVal))
    form.mutators.markFieldAsDirty('design')
    form.change('design', 'has unsaved change')
    logAmplitudeEvent('upfront_payment_edited_in_card', {
      project_id: project?.id,
      is_cashflow: paymentRequests?.length ? true : false,
    })
  }, 2000)

  const renderProductDetails = () => {
    if (integration) {
      return (
        <div className={classes.sectionWrapper}>
          <div>
            <img
              src={
                integration.logo_large_contents ||
                (LENDER_LOGO_PATHS[integration.integration_name] &&
                  `${window.PUBLIC_URL}/images/${LENDER_LOGO_PATHS[integration.integration_name]?.large}`)
              }
              height="25px"
              alt={integration.display_name}
            />
          </div>
          <div>
            <IntegratedChip />
          </div>
        </div>
      )
    } else if (props.paymentType !== 'cash') {
      return (
        <div className={classes.sectionWrapper}>
          <div>
            <AccountBalanceOutlined htmlColor="rgb(117, 117, 117)" fontSize="large" />
          </div>
        </div>
      )
    } else {
      return (
        <div className={classes.sectionWrapper}>
          <div>
            {paymentRequests?.length ? (
              // show cashflow icon if cashflow is enabled
              <img alt="co-branding" src={window.PUBLIC_URL + '/images/cashflow/cashflow-payments-icon.svg'} />
            ) : (
              <img alt="co-branding" src={window.PUBLIC_URL + '/images/cash-icon.svg'} />
            )}
          </div>
        </div>
      )
    }
  }

  const onDelete = () => {
    if (isSold) {
      notify(
        translate(
          'Sold payment options cannot be removed from designs. If you want to remove this payment option please clear out the sold payment option field in the info page then save the project.'
        ),
        'warning'
      )
      return
    }
    if (props.isGhost) {
      if (props.hideGhostCard) props.hideGhostCard()
      return
    }
    const system = window.editor.getSystems()?.find((sys) => sys.uuid === props.systemUuid)
    let newOverrides: number[] = []
    // if payment options have aleady been overridden then just change the array of pmt ids in payment_options_override
    if (system?.payment_options_override?.length) {
      newOverrides = system.payment_options_override?.filter((pmtIdOnSystem) => pmtIdOnSystem !== props.id)
    } else {
      // if payment options have not already been overriden then loop over the current payment options and pull out the id of every
      // payment option except this one. Save that filtered list of ids into payment_options_override
      system?.payment_options?.forEach((pmt) => {
        if (pmt.id !== props.id) {
          newOverrides.push(pmt.id)
        }
      })
    }

    // handle corner case where there is one auto-applied payment option on a new system but it has a validation error. If the user tries to delete the one pmt
    // then the back-end will just re-apply it and they'll see the error again
    if (mostUrgentMessage && !newOverrides?.length && !props.systemId) {
      notify(
        translate(
          'This payment option cannot be removed in this state. Please resolve the error or add another payment option and then remove this one'
        ),
        'warning'
      )
      return
    } else {
      // if the integration that is being deleted is an all or nothing integration, then remove all other produts of the same integration and alert the user
      if (props.integration && ALL_OR_NOTHING_INTEGRATIONS.includes(props.integration)) {
        let showAllOrNothingNotification = false
        newOverrides = newOverrides.filter((pmtId) => {
          let matchingPmt = availablePaymentOptions?.find((pmt) => pmt?.id === pmtId)
          if (!matchingPmt) return true
          let matchingPmtIntegration = parseJsonSafe(matchingPmt.configuration_json)?.integration
          if (matchingPmtIntegration === props.integration) {
            showAllOrNothingNotification = true
            return false
          }
          return true
        })

        if (showAllOrNothingNotification) {
          notify(translate(ALL_OR_NOTHING_REMOVED_WARNING, 'info'))
        }
      }

      window.editor.execute(new window.SetValueCommand(system, 'payment_options_override', newOverrides))
      form.mutators.markFieldAsDirty('design')
      form.change('design', 'has unsaved change')
      logAmplitudeEvent('payments_page_card_deleted', { project_id: project?.id })
      setIsPendingDeletion(true)
      dispatch(triggerSystemRefresh())
    }
  }

  const onDragStart = () => {
    if (props.isGhost || isSold || otherPmtIsSold) return
    props.setPmtIdBeingDragged(props.id)
  }

  const onDragEnd = () => {
    if (props.isGhost || isSold || otherPmtIsSold) return
    props.setPmtIdBeingDragged(undefined)
    logAmplitudeEvent('payments_page_card_reordered', { project_id: project?.id })
  }

  const openErrorPrompt = useCallback(() => {
    if (!mostUrgentMessage?.error_prompt_id || !props.systemUuid) return
    dispatch(
      addProjectErrorPrompt(
        mostUrgentMessage.error_prompt_id,
        mostUrgentMessage.message,
        props.systemUuid,
        props.integration,
        props.id
      )
    )
  }, [mostUrgentMessage])

  const renderMonthlyPaymentText = useMemo(() => {
    if (!props.monthlyPayment && !!mostUrgentMessage) return translate('N/A')
    return formatCurrencyWithSymbol(props.monthlyPayment, currencySymbol, locale, 0)
  }, [props.monthlyPayment, currencySymbol, locale, mostUrgentMessage])

  const renderPaymentPerPeriodText = useMemo(() => {
    if (!props.paymentPerPeriod && !!mostUrgentMessage) return translate('N/A')
    return formatCurrencyWithSymbol(props.paymentPerPeriod, currencySymbol, locale, 0)
  }, [props.paymentPerPeriod, currencySymbol, locale, mostUrgentMessage])

  if (isPendingDeletion) return null
  return (
    <Card>
      {mostUrgentMessage && (
        <Alert severity={mostUrgentMessage.type === 'error' ? 'error' : 'warning'}>
          <div className={classes.messageAlert}>
            <div>{mostUrgentMessage.message || mostUrgentMessage.text}</div>
            {mostUrgentMessage.error_prompt_id && (
              <div className={classes.errorPromptLink} onClick={openErrorPrompt}>
                {translate('Resolve this error')}
              </div>
            )}
          </div>
        </Alert>
      )}
      {props.id && (
        <ArchivedPaymentOptionAlert id={props.id} integration={props.integration} isSold={otherPmtIsSold || isSold} />
      )}
      <div className={classes.mainContent}>
        {props.isGhost && (
          <div className={classes.ghostCardTitleRow}>
            <div>
              <InfoOutlined fontSize="small" htmlColor="rgb(38,82, 200)" />
            </div>
            <div className={classes.ghostCardTitleText}>{translate('Example Payment Option')}</div>
          </div>
        )}
        <div className={classes.dragWrapper}>
          {props.isGhost && (
            <Tooltip
              title={translate(
                'Integrated finance options allow you to send applications directly to customers when remote selling or have customers apply right from the loan card when selling in person. Click to get setup.'
              )}
            >
              <HelpOutline htmlColor={'rgb(117, 117, 117)'} />
            </Tooltip>
          )}
          {!isSold && !otherPmtIsSold && !props.disableDrag && (
            <DragIcon draggable={true} onDrag={onDragStart} onDragEnd={onDragEnd} />
          )}
          {isSold && (
            <SoldChip
              tooltipText={
                props.integration
                  ? translate(
                      'This payment option has been marked as sold after either an accepted proposal, an approved credit application, or being manually marked as sold in the Info Page'
                    )
                  : translate(
                      'This payment option has been marked as sold after either an accepted proposal or being manually marked as sold in the Info Page'
                    )
              }
            />
          )}
        </div>
        {paymentRequests && paymentRequests?.length && paymentRequests[0] ? (
          <div className={classes.deleteWrapper}>
            <PaymentOptionCashFlowDropDown
              systemUuid={props.systemUuid}
              paymentOptionId={props.id}
              paymentRequests={paymentRequests}
              deletePaymentOption={onDelete}
              isPendingSave={isPendingSave}
            />
          </div>
        ) : integration && integration.integration_name === 'lightreach' ? (
          <div className={classes.deleteWrapper}>
            <PaymentOptionCardLightReachDropDown
              systemUuid={props.systemUuid}
              paymentOptionId={props.id}
              paymentRequests={paymentRequests}
              deletePaymentOption={onDelete}
              isPendingSave={isPendingSave}
            />
          </div>
        ) : (
          <div className={classes.deleteWrapper} onClick={onDelete}>
            <DeleteOutline htmlColor="rgb(117, 117, 117)" />
          </div>
        )}
        <div className={classes.titleSectionWrapper}>
          <div className={classes.paymentTypeText}>{translate(PAYMENT_TYPE_LABEL_MAP[props.paymentType])}</div>
          <div className={classes.labelText}>{props.title}</div>
        </div>
        {renderProductDetails()}
        {props.paymentType === 'cash' ? (
          <div className={classes.sectionWrapper}>
            <div className={classes.monthlyPaymentText}>
              {props.totalPrice !== undefined ? (
                formatCurrencyWithSymbol(props.totalPrice, currencySymbol, locale, 0)
              ) : (
                <Skeleton height={40} width={40} />
              )}
            </div>
            <div className={classes.labelText}>{translate('System Price')}</div>
          </div>
        ) : (
          <div className={classes.sectionWrapper}>
            <div className={classes.monthlyPaymentText}>
              {props.isGhost ? `${currencySymbol}` : renderMonthlyPaymentText}
            </div>
            <div className={classes.labelText}>{translate('Monthly Payment')}</div>
            {props.paymentFrequency !== PaymentFrequencyTypeEnum.monthly && (
              <span className="small">
                {renderPaymentPerPeriodText} paid {props.paymentFrequency}
              </span>
            )}
          </div>
        )}

        <div className={classes.sectionWrapper}>
          <Tooltip
            title={translate(
              'Update customers’ upfront payments directly from the card view to see the impact on repayments'
            )}
            disableHoverListener={!props.isGhost}
          >
            <TextField
              value={editedUpfrontPayment}
              onChange={onUpfrontPaymentChange}
              type="numeric"
              variant="outlined"
              size="small"
              style={{ width: '100px', backgroundColor: 'rgb(248, 248, 248)' }}
              InputProps={{
                startAdornment: currencySymbol,
              }}
              inputProps={{ style: { textAlign: 'center' } }}
              disabled={props.isGhost || depositInvoiceSent || props.integration === 'lightreach'}
            />
          </Tooltip>
          <div className={classes.labelText}>{translate('Upfront Payment')}</div>
        </div>
        {props.paymentType === 'cash' && <MilestonePaymentsTable pmtId={props.id} systemUuid={props.systemUuid} />}
        {(props.paymentType === 'loan' || props.paymentType === 'regular_payment') && (
          <PaymentOptionCardProductDetails
            row1Label={translate('Term')}
            row1Value={translate('%{term_years} Years', {
              term_years:
                props.term !== undefined && props.term !== null && props.term % 1
                  ? formatDecimalPlaces(props.term, 1)
                  : props.term,
            })}
            row2Label={translate('Interest Rate')}
            row2Value={`${props.interestRateDisclaimer || ''}${props.interestRate}%`}
          />
        )}
        {props.paymentType === 'lease' && (
          <PaymentOptionCardProductDetails
            row1Label={translate('Term')}
            row1Value={translate('%{term_years} Years', {
              term_years:
                props.term !== undefined && props.term !== null && props.term % 1
                  ? formatDecimalPlaces(props.term, 1)
                  : props.term,
            })}
          />
        )}
        {props.paymentType === 'ppa' && (
          <PaymentOptionCardProductDetails
            row1Label={translate('Term')}
            row1Value={translate('%{term_years} Years', {
              term_years:
                props.term !== undefined && props.term !== null && props.term % 1
                  ? formatDecimalPlaces(props.term, 1)
                  : props.term,
            })}
            row2Label={translate('%{currency_symbol}/kWh', { currency_symbol: currencySymbol })}
            row2Value={props.ppaPricePerUnit ? `${currencySymbol}${formatDecimalPlaces(props.ppaPricePerUnit, 2)}` : ''}
          />
        )}
        <SeeDetails
          quotationLines={props.quotationLines}
          title={props.title}
          integration={props.integration}
          isCalculating={props.isCalculating}
          paymentOptionId={props.id}
          systemUuid={props.systemUuid}
          isGhost={props.isGhost}
          mostUrgentMessage={mostUrgentMessage}
        />
        {hasIntegratedApproval && !dismissApprovedChip ? (
          <div className={classes.actionsWrapper}>
            <div className={classes.approvedChipWrapper}>
              <SoldChip chipText={translate('Credit Application Approved!')} tooltipText={translate('')} />
            </div>
            <div className={classes.reApplyWrapper} onClick={() => setDismissApprovedChip(true)}>
              {translate('I want to Re-Apply')}
            </div>
          </div>
        ) : (
          <div className={classes.actionsWrapper}>
            <CardActions
              integration={props.integration}
              paymentOptionId={props.id}
              systemUuid={props.systemUuid}
              paymentOptionTitle={props.title}
              isInDialog={false}
              isGhost={props.isGhost}
              mostUrgentMessage={mostUrgentMessage}
              upfrontPaymentString={editedUpfrontPayment}
            />
          </div>
        )}
      </div>
    </Card>
  )
}
export default PaymentOptionCard

import React from 'react'
import type { ComponentTypes } from 'types/selectComponent'
import type { FilterComponentNode, FilterComponentRendererType, FilterGenericPropsType } from '../type'
import MpptQuantityFilter, { FilterOptionsType } from './MpptQuantityFilter'

export const MPPT_QUANTITY_FILTER_KEY = 'mppt_quantity'

const maybeCreateMpptQuantityFilterNode = ({
  RendererComponent,
  componentType,
}: {
  RendererComponent: FilterComponentRendererType<FilterOptionsType>
  componentType?: ComponentTypes
}): FilterComponentNode | undefined => {
  if (componentType !== 'inverter') {
    return undefined
  }

  const FilterComponent = (props: FilterGenericPropsType) => {
    return <MpptQuantityFilter filterKey={MPPT_QUANTITY_FILTER_KEY} RendererComponent={RendererComponent} {...props} />
  }
  return {
    key: MPPT_QUANTITY_FILTER_KEY,
    component: FilterComponent,
  }
}

export default maybeCreateMpptQuantityFilterNode

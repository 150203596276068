import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type PropTypes = {
  row1Label: string
  row1Value: string
  row2Label?: string | undefined | null
  row2Value?: string | undefined | null
}

const useStyles = makeOpenSolarStyles((theme) => ({
  tableWrapper: {
    margin: '15px 0px',
    display: 'flex',
    flexDirection: 'column',
    justfiyContent: 'center',
    alignItems: 'center',
    width: '70%',
  },
  tableRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    textAlign: 'center',
    height: '21px',
  },
  tableText: {
    textAlign: 'center',
  },
  labelText: {
    marginTop: '5px',
    color: 'rgb(117, 117, 117)',
    textAlign: 'center',
  },
}))

const PaymentOptionCardProductDetails: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.tableWrapper}>
      <div className={classes.tableRow}>
        <div className={classes.labelText}>{props.row1Label}</div>
        <div>{props.row1Value}</div>
      </div>
      <div className={classes.tableRow}>
        {props.row2Label && (
          <>
            <div className={classes.labelText}>{props.row2Label}</div>
            <div>{props.row2Value}</div>
          </>
        )}
      </div>
    </div>
  )
}
export default PaymentOptionCardProductDetails

import StatusMessage from 'pages/ordering/StatusMessage'
import type { StockLevelStatus } from 'pages/ordering/type'
import React, { memo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { formatInt } from 'util/misc'

const useStockStatusStyle = makeOpenSolarStyles((theme) => ({
  stockCount: {
    color: theme.greyMid1,
  },
  statusMessage: {
    fontSize: theme.typography.subtitle1.fontSize,
  },
}))

const StockStatus = memo(
  ({
    status,
    inStockQuantity,
    showQuantity,
    plannedDateDays,
  }: {
    status: StockLevelStatus
    inStockQuantity?: number
    showQuantity: boolean
    plannedDateDays?: number | null
  }) => {
    const classes = useStockStatusStyle()
    return (
      <>
        <div className={classes.statusMessage}>
          <StatusMessage status={status} plannedDateDays={plannedDateDays} />
        </div>
        {inStockQuantity != null && showQuantity && (
          <div className={classes.stockCount}>{formatInt(inStockQuantity)} available</div>
        )}
      </>
    )
  }
)

// TODO investigate removing.
export const SegenStockStatus = memo(({ StockStatus }: { StockStatus: React.ReactElement }) => {
  return StockStatus
})

export default StockStatus

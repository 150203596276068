import { useViewShow } from 'ducks/viewMode'
import React, { PropsWithChildren } from 'react'

type UiContainerPropsType = {
  uiKey: string
  style?: React.CSSProperties
  className?: string
  inline?: true
}

/**
 * This component is a basic compntainer with a UI key, to allow it to
 * be hidden/shown using the view mode system.
 */

const UiContainer: React.FunctionComponent<PropsWithChildren<UiContainerPropsType>> = ({
  uiKey,
  children,
  style,
  className,
  inline,
  ...rest
}) => {
  const show = useViewShow(uiKey)

  const Tag = inline ? 'span' : 'div'

  return !show ? (
    <></>
  ) : (
    <Tag style={style} className={className}>
      {typeof children === 'function' ? children(rest) : children}
    </Tag>
  )
}
export default UiContainer

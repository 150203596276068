import { orgSelectors } from 'ducks/orgs'
import type LineItemType from 'pages/ordering/OrderLineItem'
import ProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/projectOrderPresenter'
import { getSupplierConfig } from 'pages/ordering/configs'
import { STOCK_LEVEL_STATUS } from 'pages/ordering/constants'
import PricingRefreshAlert from 'pages/ordering/elements/PricingRefreshAlert'
import type { StockLevelType } from 'pages/ordering/type'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import RegisterLink from '../content/RegisterLink'
import CtaButton from './CtaButton'
import CtaQuantityField from './CtaQuantityField'
import StockStatus from './StockStatus'

const useStyles = makeOpenSolarStyles((theme) => ({
  items: {
    padding: '10px 0',
  },
}))

const CtaAction = ({
  newLineItem,
  updateLineItem,
  ctaLabel,
  handleCtaClick,
}: {
  newLineItem: LineItemType
  updateLineItem: (lineItem: LineItemType) => void
  ctaLabel: string
  handleCtaClick(): void
}) => {
  const classes = useStyles()
  const status = ProjectOrderPresenter.getStockLevelStatusFromLineItem(newLineItem)
  const enabledDistributors = useSelector(orgSelectors.getEnabledHardwareSuppliers)
  const selectedDistributor = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const distributorConfig = getSupplierConfig(selectedDistributor)
  const countryIso2 = useSelector(orgSelectors.getOrgIso2)
  const inStockQuantity = newLineItem.stockLevels?.find((stock: StockLevelType) => stock.in_stock)?.quantity
  const isInvalidQuantity = newLineItem.quantity === 0 || isNaN(newLineItem.quantity)
  const lastPricingRefreshDate = newLineItem.data?.distributors.find(
    (d) => d.distributor === distributorConfig?.filterKey && d.refresh_date !== null
  )?.refresh_date
  const shouldShowRegisterLink =
    (countryIso2 === 'AU' && !enabledDistributors.includes(HardwareSupplierEnum.SolarOutlet)) ||
    (countryIso2 === 'GB' &&
      !enabledDistributors.includes(HardwareSupplierEnum.Segen) &&
      !enabledDistributors.includes(HardwareSupplierEnum.Hdm))

  const isSegenSelected = selectedDistributor === HardwareSupplierEnum.Segen
  const isHdmSelected = selectedDistributor === HardwareSupplierEnum.Hdm
  const isVtacSelected = selectedDistributor === HardwareSupplierEnum.VtacUk || HardwareSupplierEnum.VtacPoland
  const isOutletOrCPEnabled = [HardwareSupplierEnum.SolarOutlet, HardwareSupplierEnum.CityPlumbing].some((item) =>
    enabledDistributors.includes(item)
  )

  const isLargeQuantity = inStockQuantity != null ? inStockQuantity > 900000 : false
  const showQuantity = useMemo(() => {
    if (enabledDistributors.includes(HardwareSupplierEnum.SolarOutlet)) {
      return !isLargeQuantity
    }
    return true
  }, [enabledDistributors, isLargeQuantity])

  let plannedDateDays = 0
  if (status === STOCK_LEVEL_STATUS.AVAILABLE_FOR_PRE_ORDER) {
    plannedDateDays = ProjectOrderPresenter.getStockPlannedDateDays(newLineItem?.stockLevels)
  }

  const hardwareSupplierGB = useMemo(() => {
    if (
      selectedDistributor &&
      [
        HardwareSupplierEnum.CityPlumbing,
        HardwareSupplierEnum.Segen,
        HardwareSupplierEnum.VtacUk
      ].includes(selectedDistributor)) {
      return selectedDistributor
    } else return undefined
  }, [countryIso2, selectedDistributor])

  return (
    <div>
      <section className={classes.items}>
        {isSegenSelected && !lastPricingRefreshDate ? (
          <PricingRefreshAlert lineItems={[newLineItem]} />
        ) : (
          (isOutletOrCPEnabled || isSegenSelected || isHdmSelected || isVtacSelected) && (
            <StockStatus
              status={status}
              inStockQuantity={inStockQuantity}
              showQuantity={showQuantity}
              plannedDateDays={plannedDateDays}
            />
          )
        )}
        {shouldShowRegisterLink && (
          <RegisterLink
            hardwareSupplier={
              countryIso2 === 'GB'
                ? hardwareSupplierGB
                : HardwareSupplierEnum.SolarOutlet
            }
          />
        )}
      </section>
      <CtaQuantityField lineItem={newLineItem} setLineItem={updateLineItem} />
      <CtaButton label={ctaLabel} onClick={handleCtaClick} disabled={status === STOCK_LEVEL_STATUS.NOT_AVAILABLE || isInvalidQuantity} />
    </div>
  )
}

export default CtaAction

import { Grid } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import DependentInput from 'elements/input/DependentInput'
import { useTranslate } from 'ra-core'
import { BooleanInput } from 'ra-ui-materialui'
import React, { useCallback, useEffect } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { PaymentFactorType, PaymentTypeEnum } from 'types/paymentOptions'
import PaymentFactorRow from './PaymentFactorRow'

type PropTypes = {
  variableRoot: string
  disabled: boolean
}

export const EMPTY_ROW = {
  value: 0,
  start_period: 0,
  end_period: 0,
} as PaymentFactorType

const PaymentFactorsWrapper: React.FC<PropTypes> = (props) => {
  const form = useForm()
  const formState = useFormState()
  const currentPaymentFactors = formState?.values[props.variableRoot] as PaymentFactorType[]
  const translate = useTranslate()
  const isStaff = useSelector(authSelectors.getIsStaff)

  useEffect(() => {
    if (!currentPaymentFactors || currentPaymentFactors?.length === 0) {
      form.change(props.variableRoot, [EMPTY_ROW])
    }
  }, [currentPaymentFactors])

  const addRow = useCallback(() => {
    const newVal = [...currentPaymentFactors]
    newVal.push(EMPTY_ROW)
    form.change(props.variableRoot, newVal)
  }, [currentPaymentFactors])

  const removeRow = useCallback(
    (indexToRemove: number) => {
      if (indexToRemove >= currentPaymentFactors?.length) return
      const filtered = currentPaymentFactors.filter((factor, i) => i !== indexToRemove)
      form.change(props.variableRoot, filtered)
    },
    [currentPaymentFactors]
  )
  if (!isStaff) return null
  return (
    <Grid item xs={12}>
      <>
        <h2>{translate('Payment Factors')}</h2>
        <p>
          Payment factors are used to calculate repayments by dividing the financing term up into segments and then
          multiplying the original financed amount by the payment factor value. If the repayment for this payment option
          will be constant for the duration of the term you can just setup one row. If the repayment will vary, you can
          setup multiple rows. First Effective date is the first period that a payment is due for that row (eg month 1
          should have a value of 1). Last Effective Date is the last period that a payment for that row is due (eg the
          last row for a 10 year monthly loan should be 120)
        </p>
      </>
      <DependentInput dependsOn="payment_type" resolve={(val) => val !== PaymentTypeEnum.loan}>
        <BooleanInput
          // when this is called from the payment options edit page we need to add the variable_ prefix to the field name field, when it's called from the FinanceProductCategory page we do not
          source={
            props.variableRoot === 'variable_payment_factors'
              ? 'variable_payment_factors_use_gross_cost'
              : 'payment_factors_use_gross_cost'
          }
          label="Calculate based off Gross Cost (before incentives are removed)?"
          defaultValue={false}
          defaultChecked={false}
          disabled={props.disabled}
        />
      </DependentInput>
      {currentPaymentFactors?.map((factor, i) => (
        <PaymentFactorRow
          addRow={addRow}
          removeRow={removeRow}
          factor={factor}
          key={i}
          index={i}
          variableRoot={props.variableRoot}
          disabled={props.disabled}
        />
      ))}
    </Grid>
  )
}
export default PaymentFactorsWrapper

import { orgSelectors } from 'ducks/orgs'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import { useSelector } from 'react-redux'
import RegisterLink from '../RegisterLink'
import type { PriceDetailProps } from './PriceDetail'
import PriceDetail from './PriceDetail'

const ProductPrice = (props: PriceDetailProps) => {
  const enabledDistributors = useSelector(orgSelectors.getEnabledHardwareSuppliers)
  const selectedDistributor = useSelector(orgSelectors.getSelectedHardwareSupplier)
  if (
    selectedDistributor &&
    [
      HardwareSupplierEnum.Segen,
      HardwareSupplierEnum.CityPlumbing,
      HardwareSupplierEnum.Hdm,
      HardwareSupplierEnum.VtacUk,
      HardwareSupplierEnum.VtacPoland
    ].includes(
      selectedDistributor
    )
  ) {
    return <PriceDetail {...props} />
  } else if (selectedDistributor === HardwareSupplierEnum.SolarOutlet) {
    if (enabledDistributors) {
      return <PriceDetail {...props} />
    } else {
      return <RegisterLink />
    }
  } else {
    //TODO: CP logic
    return null
  }
}

export default ProductPrice

export const gmbhTrapProducts = [
  {
    variableName: 'endClamp',
    dropdown_label: 'End Clamp',
    value: '702-0193',
    label: 'End Clamp 30-40mm',
    sub_type: 'NA',
    is_dropdown: 'FALSE',
    is_default: 'TRUE',
    profile: '6mm|.4m',
    match_orientation: 'horizontal|vertical',
    extra_or_regular: 'reg',
    require_splicer: 'FALSE',
    extra_1__code: '',
    extra_1__included: '',
    extra_1__qty: '',
    extra_2__code: '',
    extra_2__included: '',
    extra_2__qty: '',
    length: '0.1',
    color: 'silver|black',
    panel_range: '30-40',
    roof_type: 'Kliplock|Metal Standing Seam|Metal Tin',
  },
  {
    variableName: 'midClamp',
    dropdown_label: 'Mid Clamp',
    value: '700-0080',
    label: 'Mid Clamp 30-40mm',
    sub_type: 'NA',
    is_dropdown: 'FALSE',
    is_default: 'TRUE',
    profile: '6mm|.4m',
    match_orientation: 'horizontal|vertical',
    extra_or_regular: 'reg',
    require_splicer: 'FALSE',
    extra_1__code: '',
    extra_1__included: '',
    extra_1__qty: '',
    extra_2__code: '',
    extra_2__included: '',
    extra_2__qty: '',
    length: '0.1',
    color: 'silver|black',
    panel_range: '30-40',
    roof_type: 'Kliplock|Metal Standing Seam|Metal Tin',
  },
  {
    variableName: 'mountingRail',
    dropdown_label: 'Rail',
    value: '712-0254',
    label: '6m Silver Rail 1/22',
    sub_type: 'continuous',
    is_dropdown: 'TRUE',
    is_default: 'TRUE',
    profile: '6mm',
    match_orientation: 'horizontal|vertical',
    extra_or_regular: 'reg',
    require_splicer: 'TRUE',
    extra_1__code: '814-0669',
    extra_1__included: 'FALSE',
    extra_1__qty: '2',
    extra_2__code: '806-0309',
    extra_2__included: 'FALSE',
    extra_2__qty: '2',
    length: '6000',
    color: 'silver|black',
    panel_range: '30-40',
    roof_type: 'Kliplock|Metal Standing Seam|Metal Tin',
  },
  {
    variableName: 'mountingRail',
    dropdown_label: 'Rail',
    value: '712-0252',
    label: '0.4m Silver Rail 1/22',
    sub_type: 'mini',
    is_dropdown: 'TRUE',
    is_default: 'FALSE',
    profile: '.4m',
    match_orientation: 'horizontal|vertical',
    extra_or_regular: 'reg',
    require_splicer: 'FALSE',
    extra_1__code: '814-0669',
    extra_1__included: 'FALSE',
    extra_1__qty: '4',
    extra_2__code: '806-0309',
    extra_2__included: 'FALSE',
    extra_2__qty: '4',
    length: '400',
    color: 'silver|black',
    panel_range: '30-40',
    roof_type: 'Kliplock|Metal Standing Seam|Metal Tin',
  },
  {
    variableName: 'railSplice',
    dropdown_label: 'Rail Connector',
    value: '610-0057',
    label: 'Inner Connector 1/22',
    sub_type: 'NA',
    is_dropdown: 'FALSE',
    is_default: 'TRUE',
    profile: '6mm',
    match_orientation: 'horizontal|vertical',
    extra_or_regular: 'extra',
    require_splicer: 'FALSE',
    extra_1__code: '',
    extra_1__included: '',
    extra_1__qty: '',
    extra_2__code: '',
    extra_2__included: '',
    extra_2__qty: '',
    length: '0.1',
    color: 'silver|black',
    panel_range: '30-40',
    roof_type: 'Kliplock|Metal Standing Seam|Metal Tin',
  },
  {
    variableName: 'roofHook',
    dropdown_label: 'Roof Hook',
    value: '814-0669',
    label: 'Side Clip 1/22',
    sub_type: 'NA',
    is_dropdown: 'FALSE',
    is_default: 'TRUE',
    profile: '6mm|.4m',
    match_orientation: 'horizontal|vertical',
    extra_or_regular: 'extra',
    require_splicer: 'FALSE',
    extra_1__code: '',
    extra_1__included: '',
    extra_1__qty: '',
    extra_2__code: '',
    extra_2__included: '',
    extra_2__qty: '',
    length: '0.1',
    color: 'silver|black',
    panel_range: '30-40',
    roof_type: 'Kliplock|Metal Standing Seam|Metal Tin',
  },
  {
    variableName: 'fastener',
    dropdown_label: 'Roof Fixing',
    value: '806-0309',
    label: 'Thin Sheet Screw ',
    sub_type: 'NA',
    is_dropdown: 'FALSE',
    is_default: 'TRUE',
    profile: '6mm|.4m',
    match_orientation: 'horizontal|vertical',
    extra_or_regular: 'extra',
    require_splicer: 'FALSE',
    extra_1__code: '',
    extra_1__included: '',
    extra_1__qty: '',
    extra_2__code: '',
    extra_2__included: '',
    extra_2__qty: '',
    length: '0.1',
    color: 'silver|black',
    panel_range: '30-40',
    roof_type: 'Kliplock|Metal Standing Seam|Metal Tin',
  },
]

// export const gmbh_trap_products = `variableName	dropdown_label	value	label	sub_type	is_dropdown	is_default	profile	match_orientation	extra_or_regular	require_splicer	extra_1__code	extra_1__included	extra_1__qty	extra_2__code	extra_2__included	extra_2__qty	length	color	panel_range	roof_type
// endClamp	End Clamp	702-0193	End Clamp 30-40mm	NA	FALSE	TRUE	6mm|.4m	horizontal|vertical	reg	FALSE							0.1	silver|black	30-40	Kliplock|Metal Standing Seam|Metal Tin
// midClamp	Mid Clamp	700-0080	Mid Clamp 30-40mm	NA	FALSE	TRUE	6mm|.4m	horizontal|vertical	reg	FALSE							0.1	silver|black	30-40	Kliplock|Metal Standing Seam|Metal Tin
// mountingRail	Rail	712-0254	6m Silver Rail 1/22	continuous	TRUE	TRUE	6mm	horizontal|vertical	reg	TRUE	814-0669	FALSE	2	806-0309	FALSE	2	6000	silver|black	30-40	Kliplock|Metal Standing Seam|Metal Tin
// mountingRail	Rail	712-0252	0.4m Silver Rail 1/22	mini	TRUE	FALSE	.4m	horizontal|vertical	reg	FALSE	814-0669	FALSE	4	806-0309	FALSE	4	400	silver|black	30-40	Kliplock|Metal Standing Seam|Metal Tin
// railSplice	Rail Connector	610-0057	Inner Connector 1/22	NA	FALSE	TRUE	6mm	horizontal|vertical	extra	FALSE							0.1	silver|black	30-40	Kliplock|Metal Standing Seam|Metal Tin
// roofHook	Roof Hook	814-0669	Side Clip 1/22	NA	FALSE	TRUE	6mm|.4m	horizontal|vertical	extra	FALSE							0.1	silver|black	30-40	Kliplock|Metal Standing Seam|Metal Tin
// fastener	Roof Fixing	806-0309	Thin Sheet Screw 	NA	FALSE	TRUE	6mm|.4m	horizontal|vertical	extra	FALSE							0.1	silver|black	30-40	Kliplock|Metal Standing Seam|Metal Tin`

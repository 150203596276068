import TourTooltip from 'elements/tooltip/TourTooltip'
import { TourConfigType } from 'persistentContent/tours/types'
import { useTranslate } from 'ra-core'
import { FC } from 'react'

const StepTooltip: FC = (props) => {
  const translate = useTranslate()
  return (
    <TourTooltip
      title={translate('Flexible Sorting & Filtering')}
      subtext={translate('Quickly arrange your list for easy viewing.')}
      {...props}
    />
  )
}

const ProjectListSort: TourConfigType = {
  spotlights: [{ targetId: 'Project-List-Sort-Section-Inner' }],
  tooltips: [
    {
      targetId: 'Project-List-Sort-Section-Inner',
      placement: 'left',
      component: StepTooltip,
    },
  ],
}

export default ProjectListSort

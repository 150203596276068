import { Paper, makeStyles } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { Button } from 'opensolar-ui'
import type { PartnerIdType } from 'pages/auth/sso/types'
import React, { useEffect } from 'react'
import { doNotTranslate } from 'util/misc'
import { AvailableOrg } from './PartnerOrgLinking'

const useStyles = makeStyles((theme: OpenSolarThemeType) => ({
  wrapper: {
    padding: 10,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      marginRight: 20,
    },
  },
  selectOrgBtn: {
    marginLeft: 'auto',
  },
}))

interface PropTypes {
  orgs: AvailableOrg[]
  selectOrg: (org) => void
  partner: string
  partnerId: PartnerIdType
}

const PartnerOrgSelect: React.FC<PropTypes> = ({ orgs, selectOrg, partner, partnerId }) => {
  const classes = useStyles()
  useEffect(() => {
    logAmplitudeEvent('partner_org_linking_interacted', {
      action: 'viewed',
      context: 'org_selector_viewed',
      source: 'org_linking_page',
      partner: partnerId || 'unknown',
    })
  }, [])

  if (!orgs) return null
  return (
    <>
      <p>{doNotTranslate(`Please select an Org to link with ${partner}`)}</p>
      <Paper className={classes.wrapper}>
        {orgs.map((org, i) => (
          <div key={'org-select-' + i} className={classes.row}>
            <p>{org.name}</p>
            <Button
              disabled={org.is_linked || !org.is_admin}
              size="small"
              variant="contained"
              color="default"
              className={classes.selectOrgBtn}
              onClick={() => {
                selectOrg(org)
              }}
            >
              {doNotTranslate(org.is_linked ? 'Linked' : 'Select Org')}
            </Button>
          </div>
        ))}
      </Paper>
    </>
  )
}
export default PartnerOrgSelect

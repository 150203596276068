import { useMemo } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { ProjectType } from 'types/projects'

export const useDesignArtifacts = ({ tags, system_uuid }: { tags?: string[]; system_uuid?: string }) => {
  const form = useForm<ProjectType>()
  const formState = useFormState<ProjectType>()
  const local_files = form.mutators.getQuietField('files_data')
  const remote_files = formState.values.private_files_data

  const local_files_filtered = useMemo(() => {
    if (!local_files) return []
    return local_files.filter((image) => {
      if (tags && !tags.every((tag) => image.file_tag_titles.includes(tag))) return false
      if (system_uuid && image.system_uuid !== system_uuid) return false
      return true
    })
  }, [local_files, tags?.join(','), system_uuid])

  const remote_files_filtered = useMemo(() => {
    if (!remote_files) return []
    return remote_files.filter((image) => {
      if (tags && !tags.every((tag) => image.file_tags_data.some((t) => t.title === tag))) return false
      if (system_uuid && image.system_uuid !== system_uuid) return false
      return true
    })
  }, [remote_files, tags?.join(','), system_uuid])

  return useMemo(() => {
    return [...local_files_filtered, ...remote_files_filtered]
  }, [local_files_filtered, remote_files_filtered])
}

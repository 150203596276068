var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import createStandardIcon from './createStandardIcon';
var FileQuestionIconSvg = function (props) {
    var fill = props.fill, stroke = props.stroke, rest = __rest(props, ["fill", "stroke"]);
    return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 18 22", fill: "none" }, rest, { children: _jsx("path", { d: "M9.59922 20.6H3.59921C2.27372 20.6 1.19921 19.5254 1.19922 18.2L1.19931 3.80001C1.19932 2.47453 2.27383 1.40002 3.59931 1.40002H14.3996C15.7251 1.40002 16.7996 2.47454 16.7996 3.80002V8.00002M11.9996 14.5479C11.9996 13.2512 13.0741 12.2 14.3996 12.2C15.7251 12.2 16.7996 13.2512 16.7996 14.5479C16.7996 15.8446 15.7251 16.8958 14.3996 16.8958M14.3996 20.0903V20", stroke: stroke, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }) })));
};
export var FileQuestionIcon = createStandardIcon(FileQuestionIconSvg);

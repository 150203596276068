import BasicFilter from '../basic/BasicFilter'
import type {
  ConfigurableFilterOptionsType,
  FilterComponentNodeV2,
  FilterGenericPropsType,
  OptionGenericType,
} from '../typeV2'

export const MY_LIST_FILTER_NODE_KEY_V2 = 'is_my_list'
const DEFAULT_OPTION = { id: 'default', title: 'All', value: '' }

const OPTIONS: OptionGenericType<boolean | string>[] = [
  DEFAULT_OPTION,
  {
    id: 'is_my_list',
    value: true,
    title: 'My List',
  },
]

export const createMyListFilterNode = <T,>({
  label = 'Component Type',
  ...restConfigs
}: ConfigurableFilterOptionsType<boolean | string, T>): FilterComponentNodeV2 => {
  const FilterComponent = (filterProps: FilterGenericPropsType) => {
    return (
      <BasicFilter
        label={label}
        defaultSelectedOptionValue={DEFAULT_OPTION.value}
        options={OPTIONS}
        filterKey={MY_LIST_FILTER_NODE_KEY_V2}
        {...restConfigs}
        {...filterProps}
      />
    )
  }

  return {
    key: MY_LIST_FILTER_NODE_KEY_V2,
    component: FilterComponent,
  }
}

export default createMyListFilterNode

import React from 'react'
import { useTranslate } from 'react-admin'
import LogoOrTitle from './LogoOrTitle'

const addHttpIfMissing = (url) => (url.indexOf('http') === 0 ? url : 'http://' + url)

const _SolarProvider = ({ org, hideHeading, selectedProject }) => {
  const translate = useTranslate()
  return (
    <div id="ContainerSolarProvider">
      {hideHeading !== true && <h1>{translate('Your Solar Provider')}</h1>}

      <div style={{ textAlign: 'center', width: '100%' }}>
        <LogoOrTitle org={org} skipTitle={true} />
        <h2>{org.name}</h2>
        {org.company_website && org.company_website.length && (
          <p>
            <a href={addHttpIfMissing(org.company_website)} target="_blank" rel="noreferrer">
              {org.company_website}
            </a>
          </p>
        )}

        <p>
          {selectedProject.assigned_role && (
            <span>
              {translate('Your expert contact')}: {selectedProject.assigned_team_member.display}
              <br />
            </span>
          )}
        </p>
        <p>
          {selectedProject.assigned_team_member.email && selectedProject.assigned_team_member.email.length > 0 && (
            <a href={'mailto:' + selectedProject.assigned_team_member.email}>
              {selectedProject.assigned_team_member.email}
            </a>
          )}
        </p>

        <p>
          {selectedProject.assigned_team_member.phone && selectedProject.assigned_team_member.phone.length > 0 && (
            <a href={'tel:' + selectedProject.assigned_team_member.phone}>
              {selectedProject.assigned_team_member.phone}
            </a>
          )}
        </p>
      </div>
      <p>
        {selectedProject.assigned_team_member.phone && selectedProject.assigned_team_member.phone.length > 0 && (
          <a href={'tel:' + selectedProject.assigned_team_member.phone}>{selectedProject.assigned_team_member.phone}</a>
        )}
      </p>
      <div
        dangerouslySetInnerHTML={{
          __html:
            selectedProject.proposal_data &&
            selectedProject.proposal_data.proposal_template_settings &&
            selectedProject.proposal_data.proposal_template_settings.about_us_content
              ? selectedProject.proposal_data.proposal_template_settings.about_us_content
              : org.about_content,
        }}
      />
    </div>
  )
}

export const SolarProvider = _SolarProvider

import { Grid, Theme, makeStyles, useMediaQuery } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import LoadingDots from 'layout/widgets/LoadingDots'
import { Button } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'
import { currencySymbolForCountry, formatCurrencyWithSymbol } from 'util/misc'
import {
  ACHPaymentDataType,
  BECSPaymentDataType,
  BlueSnapCardDataType,
  PaymentRequestType,
  PricingSummaryType
} from './types'

type PropTypes = {
  isPaid: boolean
  savedPaymentMethodData: BlueSnapCardDataType | ACHPaymentDataType | BECSPaymentDataType | undefined
  paymentRequestData: PaymentRequestType
  countryIso2: string
  projectId: string
  pmtSummaryData: PricingSummaryType | undefined
  summaryIsLoading: boolean
}

const useStyles = makeStyles<OpenSolarThemeType, { isMobile: boolean }>((theme) => ({
  container: {
    borderRadius: ({ isMobile }) => (isMobile ? '0' : '10px'),
    width: ({ isMobile }) => (isMobile ? '100%' : 'auto'),
    border: ({ isMobile }) => (isMobile ? '0' : '1px solid #E7E7E7'),
    borderTop: ({ isMobile }) => (isMobile ? '1px solid #E7E7E7' : '0'),
    background: '#F5F5F5',
    position: 'relative',
  },

  header: {
    fontSize: '20px',
    fontWeight: 400,
    padding: '16px 18px',
    margin: 0,
    borderBottom: '1px solid #E7E7E7',
    display: ({ isMobile }) => (isMobile ? 'none' : 'block'),
  },

  content: {
    padding: '16px 18px',
  },

  description: {
    borderBottom: '1px solid #E7E7E7',
    paddingBottom: '10px',
  },

  sumarryText: {
    margin: '3px 0',
  },

  breakdown: {
    padding: '16px 0 10px',
  },

  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  bold: {
    fontWeight: 'bold',
    margin: '3px 0',
  },

  totalAmount: {
    fontWeight: 'bold',
    fontSize: '16px',
    marginBottom: 0,
  },

  feeInfo: {
    textAlign: 'right',
    color: theme.greyMid1,
    fontSize: '12px',
  },

  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
  },

  btn: {
    fontSize: 14,
    color: '#4272DD',
  },
  loadingMask: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
    backgroundColor: theme.greyLight1,
    opacity: 0.75,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const PaymentSummary: React.FC<PropTypes> = (props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme?.breakpoints.down('sm'))
  const classes = useStyles({ isMobile })
  const translate = useTranslate()
  const currencySymbol = currencySymbolForCountry(props.countryIso2)

  return (
    <div className={classes.container}>
      <h1 className={classes.header} data-testid="payment-summary-title">
        {translate('Summary')}
      </h1>

      <div className={classes.content}>
        <div className={classes.description}>
          <Grid container spacing={1}>
            <Grid item xs={5} md={4} lg={5} xl={4} className={classes.flexAround}>
              <p className={classes.bold}>{translate('Current Payment')}:</p>
            </Grid>
            <Grid item xs={7} md={8} lg={7} xl={8} className={classes.flexAround}>
              <p className={classes.sumarryText}>{props.paymentRequestData.title}</p>
            </Grid>
          </Grid>

          {props.paymentRequestData.description ? (
            <Grid container spacing={1}>
              <Grid item xs={5} md={4} lg={5} xl={4} className={classes.flexAround}>
                <p className={classes.bold}>{translate('Description')}: </p>
              </Grid>
              <Grid item xs={7} md={8} lg={7} xl={8} className={classes.flexAround}>
                <p data-testid="payment-summary-description" className={classes.sumarryText}>
                  {props.paymentRequestData.description}
                </p>
              </Grid>
            </Grid>
          ) : null}

          <Grid container spacing={1}>
            <Grid item xs={5} md={4} lg={5} xl={4} className={classes.flexAround}>
              <p className={classes.bold}>{translate('Amount')}:</p>
            </Grid>
            <Grid item xs={7} md={8} lg={7} xl={8} className={classes.flexAround}>
              <p className={classes.sumarryText}>
                {formatCurrencyWithSymbol(props.paymentRequestData.payment_amount, currencySymbol)}
              </p>
            </Grid>
          </Grid>
        </div>

        <div className={classes.breakdown}>
          <div className={classes.row}>
            <div className={classes.bold} data-testid="payment-summary-purchase-price-label">
              {translate('Purchase Price')}
            </div>
            <div className={classes.bold} data-testid="payment-summary-purchase-price-value">
              {formatCurrencyWithSymbol(props.pmtSummaryData?.purchase_price, currencySymbol)}
            </div>
          </div>
        </div>
        <div className={classes.row}>
          <p className={classes.totalAmount} data-testid="payment-summary-due-today-label">
            {props.isPaid ? translate('Amount Paid') : translate('Amount Due Today')}
          </p>
          <p className={classes.totalAmount} data-testid="payment-summary-due-today-value">
            {formatCurrencyWithSymbol(props.pmtSummaryData?.payment_amount_with_surcharge, currencySymbol)}
          </p>
        </div>

        {props.pmtSummaryData?.surcharge_amount ? (
          <p className={classes.feeInfo} data-testid="payment-summary-surcharge-disclosure">
            {translate('Covers %{surcharge_pct}% credit card transaction fee', {
              surcharge_pct: props.pmtSummaryData?.surcharge_percentage,
            })}
          </p>
        ) : null}

        {props.pmtSummaryData?.invoice_download_url && (
          <div className={classes.btnContainer}>
            <a href={props.pmtSummaryData.invoice_download_url} target="_blank" rel="noopener noreferrer">
              <Button variant="text" className={classes.btn}>
                {translate('Download Invoice')}
              </Button>
            </a>
          </div>
        )}
      </div>
      {props.summaryIsLoading && (
        <div className={classes.loadingMask}>
          <LoadingDots />
        </div>
      )}
    </div>
  )
}
export default PaymentSummary

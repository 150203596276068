import { WorkflowStageType, WorkflowType } from 'types/workflows'
import { WORKFLOW_MILESTONES } from './constants'
import { WorkflowItem } from './types'

export const formComponentList = (stages: WorkflowStageType[], section?: number): WorkflowItem[] => {
  let componentList: WorkflowItem[] = []
  let counter = 0

  WORKFLOW_MILESTONES.forEach((milestone, i) => {
    if (section !== undefined && milestone.section !== section) return

    componentList.push({ id: 'milestone-' + i, type: 'milestone', milestone })

    if (stages) {
      stages.forEach((stage, j) => {
        if (stage.milestone === i)
          componentList.push({ id: 'stage-' + stage.id, type: 'stage', stage, stageIndex: counter++ })
      })
    }
  })
  return componentList
}

export const groupStageItems = (stages: WorkflowStageType[]) => {
  let ret: { stages: WorkflowItem[]; others: WorkflowItem[] } = { stages: [], others: [] }
  let counter = 0

  stages = stages.concat().sort((a, b) => a.order - b.order)

  WORKFLOW_MILESTONES.forEach((milestone, i) => {
    const list = milestone.section === 0 ? ret.stages : ret.others

    list.push({ id: 'milestone-' + i, type: 'milestone', milestone })

    if (stages) {
      stages.forEach((stage, j) => {
        if (stage.milestone === i) list.push({ id: 'stage-' + stage.id, type: 'stage', stage, stageIndex: counter++ })
      })
    }
  })
  return ret
}

export const getMilestoneIndex = (item: WorkflowItem) => {
  switch (item.type) {
    case 'stage':
      return item.stage.milestone
    case 'milestone':
      return WORKFLOW_MILESTONES.indexOf(item.milestone)
  }
}

export const validateWorkflow = (values: Partial<WorkflowType>) => {
  const errors: any = {}
  if (values.id && values.url) {
    //only check for edit flow
    const firstStage = values.workflow_stages
      ?.filter((x) => !x.forDelete && !x.is_archived)
      .sort((a, b) => a.order - b.order)[0]
    if (!firstStage) {
      // These are untranslated intentionally as the form should prevent this from even happening
      if (values.workflow_stages?.length) {
        errors.workflow_stages = ['Workflow must have at least one undeleted stage']
      } else {
        errors.workflow_stages = ['Workflow must have at least one stage']
      }
    } else if (firstStage.milestone !== 0) {
      errors.workflow_stages = ['First stage must be before the Price Lock milestone']
    }
  }

  return errors
}

import { Divider, makeStyles } from '@material-ui/core'
import { Add, DeleteOutline } from '@material-ui/icons'
import { OpenSolarThemeType } from 'Themes'
import CustomField from 'elements/field/CustomField'
import { Button } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { BooleanInput, TextInput } from 'ra-ui-materialui'
import React, { useCallback, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'

type PropTypes = {}

const useStyles = makeStyles<OpenSolarThemeType, { type?; bullet?; order?; reminderEmailsEnabled? }>((theme) => ({
  wrapper: {
    marginBottom: '2rem',
  },
  container: {
    background: theme.white,
    padding: '16px 24px',
    border: '1px solid #e7e7e7',
    borderRadius: '5px',
    margin: '1rem 0',
  },

  header: {
    fontSize: 18,
    fontWeight: 400,
    margin: '5px 0',
  },

  subtext: {
    lineHeight: 1.5,
  },

  daysInput: {
    width: '5rem',
  },

  subHeader: {
    fontSize: 16,
    fontWeight: 400,
  },

  caption: {
    color: '#757575',
    margin: 0,
    lineHeight: 1.5,
  },

  row: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    padding: '5px 0',
  },

  flexSpaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 15,
  },

  badge: {
    padding: '6px 10px',
    border: '1px solid lightgrey',
    color: theme.greyDark2,
    borderRadius: 5,
    fontSize: 'smaller',
  },

  listContainer: {
    marginTop: '3rem',
  },

  remindersContainer: {
    display: ({ reminderEmailsEnabled }) => (reminderEmailsEnabled ? 'block' : 'none'),
  },

  listItem: {
    display: 'flex',
    gap: '1rem',
  },

  listIconContainer: {
    margin: 'auto 0',
  },

  listIcon: {
    fontSize: 15,
    color: ({ type }) => (type === 'info' ? '#4172DD' : type === 'warning' ? '#E12022' : '#DCDCDE'),
  },

  listContent: {
    padding: '10px 40px 10px 15px',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },

  info: {
    background: '#F5F8FD',
  },

  warning: {
    background: '#FFE3E3',
  },

  default: {
    background: theme.greyLight1,
  },

  listSpan: {
    display: 'flex',
    alignItems: 'center',
  },

  listConnector: {
    minHeight: 24,
    borderLeftStyle: 'solid',
    borderLeftWidth: 1,
    borderColor: theme.greyMid2,
    display: ({ order }) => (order === 'last' ? 'none' : 'block'),
    marginLeft: 6,
  },

  bullet: {
    display: 'block',
    width: 10,
    height: 10,
    borderRadius: '50%',
    borderWidth: 2,
    color: ({ type }) => (type === 'info' ? '#4172DD' : type === 'warning' ? '#E12022' : theme.greyMid1),
    background: ({ type, bullet }) =>
      bullet !== 'dotted' && type === 'info' ? '#4172DD' : type === 'warning' ? '#E12022' : theme.greyMid1,
    borderStyle: ({ bullet }) => (bullet === 'full' ? 'solid' : 'dotted'),
  },

  deleteIcon: {
    fontSize: 20,
    cursor: 'pointer',
  },
}))

const useInputStyles = makeStyles((theme) => ({
  root: {
    '&.MuiFormControl-root': {
      margin: '0 10px',
    },

    '& .MuiInputBase-root': {
      height: 30,
      width: 60,
    },
  },
}))

const InvoiceSettings: React.FC<PropTypes> = () => {
  const inputClasses = useInputStyles()
  const translate = useTranslate()
  const formValues = useFormState().values
  const form = useForm()

  const reminderEmailsEnabled = formValues['email_templates'].invoice_reminder.reminders_enabled
  const classes = useStyles({ type: '', bullet: '', reminderEmailsEnabled: reminderEmailsEnabled })

  const [paymentReminders, setPaymentReminders] = useState<number[]>(
    formValues['email_templates'].invoice_reminder.send_after_days
  )
  const [overdueReminders, setOverdueReminders] = useState<number[]>(
    formValues['email_templates'].invoice_overdue.send_after_days
  )

  const addReminder = useCallback(
    (type) => {
      if (type === 'reminder') {
        let currentReminders = paymentReminders ? [...paymentReminders] : []
        let nextReminder
        currentReminders.push(nextReminder)
        form.change('email_templates.invoice_reminder.send_after_days', currentReminders)
        setPaymentReminders(currentReminders)
      } else {
        let currentReminders = overdueReminders ? [...overdueReminders] : []
        let nextReminder
        currentReminders.push(nextReminder)
        form.change('email_templates.invoice_overdue.send_after_days', currentReminders)
        setOverdueReminders(currentReminders)
      }
    },
    [paymentReminders, overdueReminders]
  )

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <h1 className={classes.header}>{translate('Invoice Settings')}</h1>
        <p className={classes.subtext}>
          {translate(
            'Set how many days a customer has to pay an invoice and set payment reminder emails. The due date for invoices sent will be calculated as the invoice issue date plus the specified number of days. All reminder emails below will be sent based on the invoice issued and due date.'
          )}
        </p>

        <div className={classes.row}>
          <p>{translate('How many days does a customer have to pay their invoice?')}</p>
          <CustomField
            label=""
            component={TextInput}
            name="days_until_due"
            size="small"
            variant="outlined"
            className={classes.daysInput}
          />
        </div>

        <Divider />
        <div className={classes.flexSpaceBetween}>
          <h1 className={classes.header}>
            {reminderEmailsEnabled
              ? translate('Reminder Email Automation')
              : translate('Reminder Emails currently disabled')}
          </h1>
          <CustomField
            label=""
            component={BooleanInput}
            name="email_templates.invoice_reminder.reminders_enabled"
            source="email_templates.invoice_reminder.reminders_enabled"
          />
        </div>

        <div className={classes.remindersContainer}>
          <h2 className={classes.subHeader}>
            {translate('Configure when payment reminder emails should be automatically sent to the customer.')}
          </h2>
          <p className={classes.caption}>
            {translate(
              'Email reminders will be automatically sent to the customer if payment has not yet been received.'
            )}
          </p>
          <p className={classes.caption}>{translate('Up to 5 reminder emails can be sent.')}</p>

          <div className={classes.listContainer}>
            <ListItem type="info" bullet="full">
              <span className={classes.listContent + ' ' + classes.info}>{translate('Invoice Sent')}</span>
            </ListItem>

            {paymentReminders?.map((day, i) => (
              <PaymentReminderEmailItem key={i} num={i} setPaymentReminders={setPaymentReminders} />
            ))}

            <ListItem type="secondary" bullet="dotted">
              <Button
                variant="outlined"
                size="small"
                disabled={paymentReminders.length >= 5}
                onClick={() => addReminder('reminder')}
              >
                <Add /> {translate('Reminder email')}
              </Button>
            </ListItem>
            <ListItem type="warning" bullet="full">
              <div className={classes.listContent + ' ' + classes.warning}>
                <span className={classes.listSpan}>
                  {translate('Payment due')}
                  <CustomField
                    label=""
                    component={TextInput}
                    name="days_until_due"
                    source="days_until_due"
                    size="small"
                    variant="outlined"
                    classes={inputClasses}
                    disabled={true}
                  />
                  {translate('days after invoice sent')}
                </span>
              </div>
            </ListItem>

            {overdueReminders?.map((day, i) => (
              <OverdueReminderEmailItem key={i} num={i} setOverdueReminders={setOverdueReminders} />
            ))}

            <ListItem type="secondary" bullet="dotted" order="last">
              <Button
                variant="outlined"
                size="small"
                disabled={overdueReminders.length >= 5}
                onClick={() => addReminder('overdue')}
              >
                <Add /> {translate('Overdue email reminder')}
              </Button>
            </ListItem>
          </div>
        </div>
      </div>
    </div>
  )
}

export const ListItem = (props) => {
  const classes = useStyles({ type: props.type, bullet: props.bullet, order: props.order })
  const { children, bullet } = props

  return (
    <>
      <div className={classes.listItem}>
        <span className={classes.listIconContainer}>
          <span className={classes.bullet}></span>
        </span>
        <div>{children}</div>
      </div>
      <div>
        <span className={classes.listConnector}></span>
      </div>
    </>
  )
}

export const PaymentReminderEmailItem = (props) => {
  const classes = useStyles({ type: props.type, bullet: props.bullet })
  const inputClasses = useInputStyles()
  const translate = useTranslate()
  const formValues = useFormState().values
  const form = useForm()

  const paymentReminders = formValues['email_templates'].invoice_reminder.send_after_days

  const onAdd = (val) => {
    props.setPaymentReminders((prevReminders) => {
      const updatedReminders = [...prevReminders]
      updatedReminders[props.num] = val
      return updatedReminders
    })
  }

  const onDelete = useCallback(() => {
    let currentReminders = [...paymentReminders]
    currentReminders.splice(props.num, 1)
    form.change('email_templates.invoice_reminder.send_after_days', currentReminders)
    props.setPaymentReminders(currentReminders)
  }, [paymentReminders])

  return (
    <ListItem type="secondary" bullet="full">
      <div className={classes.listContent + ' ' + classes.default}>
        <span className={classes.listSpan}>
          {translate('Send payment reminder email')}
          <TextInput
            name={`email_templates.invoice_reminder.send_after_days[${props.num}]`}
            source={`email_templates.invoice_reminder.send_after_days[${props.num}]`}
            variant="outlined"
            label=""
            onBlur={(e) => onAdd(e.target.value)}
            classes={inputClasses}
          />
          {translate('days after invoice sent')}
        </span>
        <span className={classes.badge}>{translate('Automated')}</span>
        <span onClick={onDelete}>
          <DeleteOutline className={classes.deleteIcon} />
        </span>
      </div>
    </ListItem>
  )
}

export const OverdueReminderEmailItem = (props) => {
  const classes = useStyles({ type: props.type, bullet: props.bullet })
  const inputClasses = useInputStyles()
  const translate = useTranslate()
  const formValues = useFormState().values
  const form = useForm()

  const overdueReminders = formValues['email_templates'].invoice_overdue.send_after_days

  const onAdd = (val) => {
    props.setOverdueReminders((prevReminders) => {
      const updatedReminders = [...prevReminders]
      updatedReminders[props.num] = val
      return updatedReminders
    })
  }

  const onDelete = useCallback(() => {
    let currentReminders = [...overdueReminders]
    currentReminders.splice(props.num, 1)
    form.change('email_templates.invoice_overdue.send_after_days', currentReminders)
    props.setOverdueReminders(currentReminders)
  }, [overdueReminders])

  return (
    <ListItem type="secondary" bullet="full">
      <div className={classes.listContent + ' ' + classes.default}>
        <span className={classes.listSpan}>
          {translate('Send over due reminder')}
          <TextInput
            name={`email_templates.invoice_overdue.send_after_days[${props.num}]`}
            source={`email_templates.invoice_overdue.send_after_days[${props.num}]`}
            variant="outlined"
            label=""
            classes={inputClasses}
            onBlur={(e) => onAdd(e.target.value)}
          />
          {translate('days after invoice due date')}
        </span>
        <span className={classes.badge}>{translate('Automated')}</span>
        <span onClick={onDelete}>
          <DeleteOutline className={classes.deleteIcon} />
        </span>
      </div>
    </ListItem>
  )
}

export default InvoiceSettings

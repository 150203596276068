import { makeStyles } from '@material-ui/core'
import { permissionsSelectors } from 'ducks/permissions'
import { viewModeActions, viewModeSelectors } from 'ducks/viewMode'
import SideDrawer from 'elements/drawer/SideDrawer'
import React, { useCallback, useMemo } from 'react'
import { useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import ProposalInputs from './ProposalInputs'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer,
    pointerEvents: 'none',
    inset: '0 0 0 0',
  },
}))

const ProposalDrawer = ({ handleChange, proposalData }) => {
  const showProposalDrawer = useSelector(viewModeSelectors.showProposalDrawer)
  const { allowEdit } = useSelector(permissionsSelectors.getProjectPermissionByKey('proposal'))
  const dispatch = useDispatch()
  const translate = useTranslate()
  const setOpen = useCallback((value) => {
    dispatch(viewModeActions.setShowProposalDrawer(value))
  }, [])
  const drawerTheme = useMemo(() => {
    const myeStyles = proposalData?.myeStyles
    if (!myeStyles) {
      return undefined
    }
    return {
      highlightColor: myeStyles.highlightColor,
      highlightColorInteraction: myeStyles.highlightColorInteraction,
      textColorOnHighlight: myeStyles.textColorOnHighlight,
    }
  }, [proposalData?.myeStyles])
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <SideDrawer
        open={showProposalDrawer}
        unmountOnExit={false}
        drawerTheme={drawerTheme}
        setOpen={setOpen}
        side={'left'}
        disabled={!allowEdit}
        DrawerPullLabel={
          <>
            <span style={{ display: 'inline-block', marginBottom: 6 }}>{translate('Customise')}</span>
            <span>{translate('Proposal')}</span>
          </>
        }
      >
        <ProposalInputs handleChange={handleChange} proposalData={proposalData} />
      </SideDrawer>
    </div>
  )
}

export default ProposalDrawer

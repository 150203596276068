import Grid from '@material-ui/core/Grid'
import CustomField from 'elements/field/CustomField'
import { NotifyTeamMembersField } from 'projectSections/elements/fields/AssigneeMultiSelectField'
import React from 'react'
import { TextInput } from 'react-admin'

const AddNoteDialogFormContent: React.FunctionComponent = () => {
  return (
    <Grid item xs={12}>
      <CustomField
        component={TextInput}
        fullWidth={true}
        multiline={true}
        label="resources.events.fields.notes"
        name="notes"
        source="notes"
      />
      <NotifyTeamMembersField />
    </Grid>
  )
}
export default AddNoteDialogFormContent

// @ts-nocheck
import { makeStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddOutlined'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import ManageIcon from '@material-ui/icons/MoreVertOutlined'
import WithPermissionsCheck from 'elements/WithPermissionsCheck'
import { Button } from 'opensolar-ui'
import React, { useCallback, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { ViewTypes } from 'types/view'
import getIconPathByMapType from 'util/iconByMapType'
import ToolbarViewsPopoverMobile from './ToolbarViewsPopoverMobile'

const useStyles = makeStyles((theme: any) => ({
  viewsWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonIcon: {
    maxHeight: 35,
    maxWidth: 35,
    marginLeft: 0,
  },
  buttonIconSmall: {
    maxHeight: 20,
    maxWidth: 20,
    marginLeft: 0,
    marginRight: 5,
    verticalAlign: 'middle',
  },
  activeButton: {
    minWidth: 145,
    width: 145,
    height: 30,
    marginBottom: 5,
    borderRadius: 4,
    pointerEvents: 'auto',
    color: 'rgb(0,0,0)',
    background: 'rgb(255,255,255)',
    '&:hover': {
      background: 'rgba(250,250,250,0.9)',
    },
  },
  button: {
    minWidth: 145,
    width: 145,
    height: 30,
    marginBottom: 5,
    borderRadius: 4,
    pointerEvents: 'auto',
    color: 'rgb(0,0,0)',
    background: 'rgba(255, 255, 255, 0.5)',
    '&:hover': {
      background: 'rgba(250,250,250,0.9)',
    },
  },
  chooseImageryButton: {
    height: 40,
    borderRadius: 4,
    pointerEvents: 'auto',
    color: 'rgb(0,0,0)',
    background: 'rgb(255,255,255)',
    '&:hover': {
      background: 'rgba(250,250,250,0.9)',
    },
  },
  buttonLabel: {
    textTransform: 'none',
  },
}))

type PropTypes = {
  activeViewData: ViewTypes
  views: ViewTypes[]
  handleSelectView: (viewUuid: string) => void
}

type PopoverStateTypes = {
  popoverViewUuid: string | null
  anchorEl: HTMLElement | null
}

const ToolbarViewsMobile = (props: PropTypes) => {
  const [expand, setExpand] = useState<Boolean>(false)
  const [popoverState, setPopoverState] = useState<PopoverStateTypes>({ anchorEl: null, popoverViewUuid: null })
  const { activeViewData, views, handleSelectView } = props
  const availableImageryTypes = useSelector((state: any) => {
    return state.designer?.detectImagery?.availableMapTypes || []
  })
  const classes = useStyles()
  const translate = useTranslate()
  const handleChooseImagery = () => {
    setExpand(!expand)
  }
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, view: ViewTypes, isActiveView: boolean) => {
      try {
        if (!isActiveView) {
          handleSelectView(view.uuid)
        }

        var dummyObject = new window.THREE.Object3D()
        dummyObject.type = 'ViewDummy'
        dummyObject.userData = view
        dummyObject.userData.excludeFromExport = true

        dummyObject.toolsActive = function () {
          return {
            translateXY: false,
            translateZ: false,
            scaleXY: false,
            scaleZ: false,
            scale: false, //legacy
            rotate: false,
            delete: false,
          }
        }

        dummyObject.canDelete = function () {
          if (window.Designer.controlMode && window.Designer.controlMode !== 'both') {
            return 'Unable to delete a view while in align-map mode '
          } else if (window.ViewHelper.views.length > 1) {
            return true
          } else {
            return 'Unable to delete the last remaining view'
          }
        }

        dummyObject.confirmBeforeDelete = function () {
          return 'Are you sure you want to delete this view?'
        }

        dummyObject.onDeleteOverride = function () {
          //Call this instead of deleting the dummy object
          //window.ViewHelper.deleteView(window.ViewHelper.getViewByUuid(activeViewUuid), window.editor)
          window.editor.execute(new window.RemoveViewCommand(view.uuid, window.ViewHelper))
        }

        window.editor.select(dummyObject)
      } catch (err) {
        console.log(err)
      }
      setPopoverState({ anchorEl: event.currentTarget, popoverViewUuid: view.uuid })
    },
    []
  )

  const handleNewView = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverState({ anchorEl: event.currentTarget, popoverViewUuid: 'new' })
  }, [])

  const handleClose = useCallback(() => {
    setPopoverState({ anchorEl: null, popoverViewUuid: null })
  }, [])
  const popoverView =
    popoverState.popoverViewUuid === 'new'
      ? { uuid: 'new' }
      : views.find((view) => view.uuid === popoverState.popoverViewUuid)
  return (
    <>
      {expand && (
        <div className={classes.viewsWrapper}>
          {views.map((view, index) => {
            var isActiveView = activeViewData && view.uuid === activeViewData.uuid
            return (
              <Button
                classes={{ root: isActiveView ? classes.activeButton : classes.button, label: classes.buttonLabel }}
                key={view.uuid}
                onClick={(e) => handleClick(e, view, isActiveView)}
              >
                <div>
                  <img
                    alt="choose imagery"
                    className={classes.buttonIconSmall}
                    src={getIconPathByMapType(view.mapType)}
                  />
                  <span>{view.labelMinor}</span>
                </div>
                <ManageIcon style={{ marginLeft: 'auto' }} className={classes.buttonIconSmall} />
              </Button>
            )
          })}
          <WithPermissionsCheck permissionToCheck="design" permissions={['allowEdit']}>
            <Button
              startIcon={<AddIcon className={classes.buttonIconSmall} />}
              classes={{ root: classes.activeButton, label: classes.buttonLabel }}
              onClick={handleNewView}
            >
              <span>{translate('New View')}</span>
            </Button>
          </WithPermissionsCheck>
        </div>
      )}
      <Button
        startIcon={
          expand ? (
            <CloseIcon className={classes.buttonIcon} />
          ) : (
            <img
              alt="choose imagery"
              className={classes.buttonIcon}
              src={getIconPathByMapType(activeViewData?.mapType)}
            />
          )
        }
        classes={{ root: classes.chooseImageryButton, label: classes.buttonLabel }}
        key={activeViewData?.uuid}
        onClick={handleChooseImagery}
      >
        <span>{translate(expand ? 'Close' : 'View Imagery')}</span>
      </Button>
      {popoverView && (
        <ToolbarViewsPopoverMobile
          anchorEl={popoverState.anchorEl}
          handleClose={handleClose}
          view={popoverView}
          availableImageryTypes={availableImageryTypes}
        />
      )}
    </>
  )
}

export default ToolbarViewsMobile

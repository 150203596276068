import type { RedirectionEntity } from './redirectionLocalStore'
import { clearLocalRedirection, getLocalRedirection, storeLocalRedirection } from './redirectionLocalStore'

const AuthRedirection = 'AUTH_SUCCESS_REDIRECT'
export type AuthRedirectionType = typeof AuthRedirection

export type AuthRedirectionEntity = RedirectionEntity<AuthRedirectionType>

export const AUTH_REDIRECT_SESSION_STORAGE_KEY = 'auth_redirect_session'

export const storeAuthRedirectionToLocalStorage = () => {
  const redirection = getAuthRedirection()
  if (redirection) {
    storeLocalRedirection(redirection)
  }
}

export const retrieveAuthRedirectionFromLocalStorage = () => {
  const storedRedirection = getLocalRedirection()
  if (storedRedirection && storedRedirection.type === AuthRedirection) {
    setAuthRedirection(storedRedirection as AuthRedirectionEntity)
    clearLocalRedirection()
  }
}

export const getAuthRedirection = (): AuthRedirectionEntity | undefined => {
  const redirectionInString = sessionStorage.getItem(AUTH_REDIRECT_SESSION_STORAGE_KEY)
  if (!redirectionInString) {
    return undefined
  }

  const redirection = JSON.parse(redirectionInString)
  if (redirection === null || typeof redirection !== 'object') {
    return undefined
  }

  if (!redirection.hasOwnProperty('type') || !redirection.hasOwnProperty('redirect')) {
    return undefined
  }

  return redirection as AuthRedirectionEntity
}

export const clearAuthRedirection = () => {
  sessionStorage.removeItem(AUTH_REDIRECT_SESSION_STORAGE_KEY)
}

export const setAuthRedirection = (redirection: AuthRedirectionEntity) => {
  sessionStorage.setItem(AUTH_REDIRECT_SESSION_STORAGE_KEY, JSON.stringify(redirection))
}

//TODO: move this file to replace existing projectsections

import { makeStyles, Tooltip, withStyles } from '@material-ui/core'
import DateInput from 'elements/input/DateInput'
import { styled } from 'opensolar-ui'
import { Theme } from 'types/themes'

export const COLOR_PALETTE = {
  blue: '#1751D0',
  blue2: '#4272DD',
  black: '#0A090B',
  lightGrey: '#ECECED',
  grey: '#6B6A76',
  grey2: '#7F7D83',
  grey3: '#FAFBFC',
  grey4: '#4D4D4D',
  grey5: '#4C4B53',
  grey6: '#E7E7E7',
  grey7: '#2D2B32',
  grey8: '#EFEFEF',
  darkGrey: '#4F4D55',
  white: '#FFFFFF',
  green: '#019939',
  green2: '#018030',
}
const divider = 1
const columnGap = 20
const border = 2

export const getHardwareSectionPreOrderStyles = makeStyles(
  (theme: Theme) => ({
    iconPadding: {
      paddingLeft: 20,
      paddingRight: 10,
      display: 'flex',
      alignItems: 'center',
    },
    sectionWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'stretch',
      padding: 40,
      width: '100%',
      maxWidth: 1440,
      boxSizing: 'border-box',
      gap: 30,

      '& > *': {
        maxWidth: '100%',
      },
    },
    sectionCardTop: {
      backgroundColor: theme.white,
      padding: '16px 24px',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      borderBottom: '1px solid ' + COLOR_PALETTE.lightGrey,
    },
    sectionCardMiddle: {
      backgroundColor: theme.white,
      padding: '16px 24px',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderBottom: '1px solid ' + COLOR_PALETTE.lightGrey,
    },
    sectionCardBottom: {
      backgroundColor: theme.white,
      padding: '16px 24px',
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },
    chip: {
      marginRight: 5,
      height: '16px !important',
    },
    row: {
      display: 'flex',
      alignItems: 'center',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
    },
    moduleFullWidth: {
      width: '100%',
    },
    moduleHalfWidth: {
      width: '40%',
      flexGrow: 1,
      minWidth: 300,
    },
    accordionReskin: {
      width: '100%',
      borderRadius: 8,
      marginBottom: '-20px !important', // Makes the accordions at the bottom more tightly packed than the elements at the top.
      '& .MuiCollapse-container': {
        borderTop: '1px solid ' + COLOR_PALETTE.lightGrey,
      },
      '& .MuiAccordionSummary-content': {
        '& span': {
          fontWeight: 600,
        },
      },
    },
    detail: {
      height: 65,
      marginLeft: columnGap / 2,
      marginRight: columnGap / 2,
      position: 'relative',
      '& p': {
        fontWeight: 500,
        margin: 0,
        color: COLOR_PALETTE.darkGrey,
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        right: '100%',
        top: '50%',
        display: 'inline-block',
        width: divider,
        height: '100%',
        backgroundColor: COLOR_PALETTE.lightGrey,
        transform: 'translateY(-50%)',
        marginRight: (columnGap - divider) / 2 + border,
      },
    },
    tagsCustomWidth: {
      flexGrow: 1,
    },
    teamsCustomWidth: {
      flexGrow: 1,
      maxWidth: 200,
    },
    contactsCustomWidth: {
      flexGrow: 1,
    },
    mobileViewDiv: {
      display: 'flex',
      justifyContent: 'space-between',
      maxHeight: 30,
    },
    headerTitle: {
      fontSize: '16px',
      fontWeight: 600,
      color: COLOR_PALETTE.black,
      lineHeight: '22px',
    },
    accordionHeaderTitle: {
      fontSize: '16px',
      fontWeight: 600,
      marginLeft: '-2px',
      color: COLOR_PALETTE.black,
    },
    inputLabel: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      color: COLOR_PALETTE.grey,
      marginBottom: '4px',
    },
  }),
  { name: 'ProjectSectionStyles' }
)

export const IconButtonTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: COLOR_PALETTE.black,
    color: '#FFF',
    borderRadius: 8,
    padding: '8px 10px',
    fontWeight: 400,
  },
}))(Tooltip)

export const InputRow = styled('div')({
  display: 'flex',
  gap: 10,
})

export const InputWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
})

export const ContentBg = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.info.light,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflow: 'hidden',
}))

export const StyledDateInput = styled(DateInput)({
  width: '100%',
  margin: '8px 4px',
})

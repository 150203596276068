import { MarkerOutlineIcon } from 'opensolar-ui'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import { useTranslate } from 'ra-core'
import React from 'react'
import { useFormState } from 'react-final-form'
import { getProjectSectionStyles } from '../styles'
import CommercialSiteFields from './fieldsets/CommercialSiteFields'
import GeneralSiteInfoFields from './fieldsets/GeneralSiteInfoFields'
import LocationStructureFields from './fieldsets/LocationStructureFields'
import UtilityFields from './fieldsets/UtilityFields'
import { InputContainer, useStyles } from './styles'

const SiteAccordion: React.FC = (props) => {
  const translate = useTranslate()
  const sectionStyles = getProjectSectionStyles()
  return (
    <AccordionCard
      name={'site'}
      elevation={0}
      title={translate('Site Details')}
      className={sectionStyles.accordionReskin}
      titleIcon={MarkerOutlineIcon}
      content={SiteContent}
      lazyRender={true}
    />
  )
}

const SiteContent = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const project = useFormState().values

  return (
    <InputContainer>
      <h3 className={classes.sectionHeader}>{translate('General Information')}</h3>
      {!project?.is_residential && <CommercialSiteFields countryCode={project?.country_iso2} />}
      <GeneralSiteInfoFields />
      <h3 className={classes.sectionHeader}>{translate('Location and Structural Details')}</h3>
      <LocationStructureFields />
      <h3 className={classes.sectionHeader}>{translate('Utility Details')}</h3>
      <UtilityFields />
    </InputContainer>
  )
}

export default SiteAccordion

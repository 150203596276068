export const FINANCE_PRODUCT_CHOICES = [
  { id: 'loan', label: 'Loan' },
  { id: 'lease', label: 'Lease' },
  { id: 'bnpl', label: 'BNPL' },
  { id: 'ppa', label: 'PPA' },
]

export const PAYMENT_FREQUENCE_CHOICES = [
  { id: 'monthly', label: 'Monthly' },
  { id: 'bimonthly', label: 'Bimonthly' },
  { id: 'fortnightly', label: 'Fortnightly' },
  { id: 'weekly', label: 'Weekly' },
  { id: 'quarterly', label: 'Quarterly' },
  { id: 'yearly', label: 'Yearly' },
]

export const PMT_IMPORT_METHOD_CHOICES = [
  { id: 'api', label: 'API Import' },
  { id: 'api_with_stored_payment_factors', label: 'API Import with Stored Payment Factors' },
  { id: 'import', label: 'Import Manually Created Records' },
]

export const DEFAULT_QUOTATION_TABLE_CONFIG = {
  loan_amount: {
    left: "{% trans 'Financed Amount' %}",
    left_extra: '',
    right: '{% blocktrans with value=loan_amount|currency:2 %}{{ value }}{% endblocktrans %}',
    right_extra: null,
  },
  loan_term: {
    left: "{% trans 'Financing Term' %}",
    left_extra: null,
    right: "{% blocktrans with value=loan_term %}{{ value }}{% endblocktrans %}{% trans ' months' %}",
    right_extra: null,
  },
  loan_repayments_initial: {
    left: "{% trans 'Fortnightly Repayments' %}",
    left_extra: null,
    right: '{{ regular_payment }}',
    right_extra: null,
  },
  additional_details: {
    left: '',
    left_extra:
      '<p style="color:black;">There is a one-off $75 establishment fee payable on your first repayment that is not included in the quoted repayment amount and the savings calculations.</p><p>The minimum amount repayable includes the $2.15 Weekly Account Keeping fee (which is calculated daily and is included in the quoted repayment amount). Not all Vendors offer all terms. Please check with your Vendor about which payment terms they offer. A late payment fee of $4.99 will be charged by Brighte if you miss a repayment. Total late fees are capped at $49.90 per calendar year. This is equal to 10 missed repayments.</p><p>The above figures are estimations only. Any advice on this website does not take into account your objectives, financial situation or needs and you should consider whether it is appropriate for you. Not all Brighte accredited partners offer all of Brighte\u2019s finance products. Please check with your vendor on which product they may offer.</p>',
    right: '',
    right_extra: '',
  },
}

import { Box, Card, makeStyles, Tooltip } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import ChipsInput from 'elements/input/ChipsInput'
import { IconButton } from 'opensolar-ui'
import React, { useMemo, useState } from 'react'
import { FormDataConsumer, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { useResourceStyles } from 'resources/styles'
import { ConnectedOrgBrief } from 'types/orgs'
import { urlToId } from 'util/misc'

interface PropTypes {
  dependsOn?: string | Function // If you want this to conditionally render based on another field in the form, pass it in here
  dependsOnValue?: any // If the field should equal a particular value, add here, otherwise any truthy value will work
  noBorder?: true
  disabled?: boolean
  record?: { org?: string }
  extraAction?: React.ReactNode
}

const useStyles = makeStyles({
  inputRoot: {
    minHeight: 50,
  },
  selectorContainer: {
    margin: '20px 0',
  },
})

const ShareabilitySelector: React.FC<PropTypes> = ({
  dependsOn,
  dependsOnValue,
  noBorder,
  disabled,
  record,
  ...props
}) => {
  const source = 'share_with_orgs'
  const translate = useTranslate()
  const resourceStyles = useResourceStyles()
  const classes = useStyles()
  const form = useForm()
  const selected = form.getFieldState(source)?.value
  const connectedOrgs = useSelector(orgSelectors.getConnectedOrgs)
  const connectedOrgsFiltered = connectedOrgs?.filter((x) => x.is_active === true)
  const [showSelector, setShowSelector] = useState(!dependsOn)
  const [choices, setChoices] = useState<Partial<ConnectedOrgBrief>[]>(connectedOrgsFiltered)

  const userOrgId = useSelector(authSelectors.getOrgId)
  const entityOrgId = urlToId(record?.org)
  const isShared = entityOrgId !== undefined && userOrgId !== entityOrgId

  const selectAllOption: Partial<ConnectedOrgBrief> = {
    org_name: translate('Select all'),
    org_url: '*',
  }

  const matchSuggestion = (filterValue: string, choice: ConnectedOrgBrief) =>
    choice.org_name.toLocaleLowerCase().match(filterValue.toLocaleLowerCase())

  const setFilter = useMemo(
    () => (filterValue: string) => {
      const terms = filterValue.toLocaleLowerCase().split(' ')
      const filtered: { org: ConnectedOrgBrief; score: number }[] = []
      for (const orgCon of connectedOrgsFiltered) {
        let score = 0
        const orgName = orgCon.org_name.toLocaleLowerCase()
        for (const term of terms) {
          if (orgCon.org_id.toString() === term || orgName === term) {
            // Match exact ID or name
            score += 100
          } else if (orgName.split(' ').indexOf(term) !== -1) {
            // Match a word in name
            score += 10
          } else if (orgCon.org_id.toString().startsWith(term) || orgName.match(term)) {
            // Match start of ID or some part of name
            score += 1
          }
        }
        if (score > 0) filtered.push({ org: orgCon, score })
      }
      filtered.sort((o1, o2) => o2.score - o1.score)
      let list: Partial<ConnectedOrgBrief>[] = filtered.length ? filtered.map((o) => o.org) : connectedOrgsFiltered
      if (!selected || selected?.length < connectedOrgsFiltered.length) list = list.concat([selectAllOption])
      setChoices(list) // Show all if no matches
    },
    [connectedOrgsFiltered]
  )

  const clearAll = () => {
    form.change(source, [])
  }

  const selectAll = () => {
    const urls: string[] = []
    for (const orgConn of connectedOrgsFiltered) urls.push(orgConn.org_url)
    form.change(source, urls)
  }

  const onChange = (items: string[] | null) => {
    if (!items) return
    for (const url of items) {
      if (url === '*') {
        selectAll()
        return
      }
    }
  }

  const WrapperComp = noBorder ? Box : Card
  const wrapperClass = noBorder ? undefined : resourceStyles.formSection

  // Hide this control when viewing an entity from another org
  if (isShared) return null

  return (
    <>
      {dependsOn && (
        <FormDataConsumer>
          {({ formData }) => {
            const value = typeof dependsOn === 'string' ? formData[dependsOn] : dependsOn(formData)
            if (dependsOnValue === undefined) setShowSelector(value !== undefined && value !== null)
            else setShowSelector(value === dependsOnValue)
            return <></>
          }}
        </FormDataConsumer>
      )}
      {showSelector && (connectedOrgsFiltered.length || 0) > 0 && (
        <WrapperComp className={wrapperClass}>
          <div className={classes.selectorContainer}>
            <p>{translate('Share with the following organisations:')}</p>
            <ChipsInput
              disabled={disabled}
              classes={classes}
              label={false}
              source={source}
              optionValue="org_url"
              fullWidth={true}
              showSpinnerWhenRefreshing
              choices={choices}
              allChoices={connectedOrgs}
              matchSuggestion={matchSuggestion}
              setFilter={setFilter}
              debounceTime={50}
              onChange={onChange}
              optionText={(i) => (i ? i.org_name : <i style={{ color: '#aaa' }}>{translate('Unshared Item')}</i>)}
              options={{
                InputProps: {
                  endAdornment: selected?.length ? (
                    <Tooltip title="Remove all">
                      <IconButton onClick={clearAll}>
                        <Close />
                      </IconButton>
                    </Tooltip>
                  ) : null,
                },
              }}
            />
          </div>
          {props.extraAction}
        </WrapperComp>
      )}
    </>
  )
}

export default ShareabilitySelector

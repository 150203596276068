import { MenuItem } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import type LineItemType from 'pages/ordering/OrderLineItem'
import OrderLineItem from 'pages/ordering/OrderLineItem'
import React, { useCallback } from 'react'
import type { DistributorDataType } from '../../../../type'
import useStyles from '../styles'
import { filterOrderableDistributors } from './utils'

const variantLabel = (quantity_per_item, showVariant, variantId) => {
  const genericLabel = quantity_per_item === 1 ? 'Individual' : 'Bulk'
  const variantLabel = showVariant && variantId ? variantId : genericLabel

  if (quantity_per_item === 1) {
    return `${variantLabel} (${quantity_per_item} unit)`
  } else {
    return `${variantLabel} (${quantity_per_item} units)`
  }
}

const BulkQuantityOptions = ({
  lineItem,
  updateLineItem,
  closeDropdown,
  trackSource,
}: {
  lineItem: LineItemType
  updateLineItem(newLineItem: LineItemType): void
  closeDropdown(): void
  trackSource: string
}) => {
  const classes = useStyles()
  const orderableVariants =
    lineItem?.data?.distributors !== undefined ? filterOrderableDistributors(lineItem.data.distributors) : []
  const handleSelectBulkOption = useCallback(
    ({ distributor }: { distributor: DistributorDataType }) => {
      const quantityPerItem = distributor.quantity_per_item
      const newLineItem = new OrderLineItem({
        ...lineItem,
        quantity: 1,
      })
      newLineItem.switchVariant(distributor.variant_id)
      updateLineItem(newLineItem)
      logAmplitudeEvent('hardware_quantity_field_selector', {
        action: 'clicked',
        type: 'bulk',
        source: trackSource,
        context: 'bulk_quantity',
        value: quantityPerItem,
      })
      closeDropdown()
    },
    [lineItem]
  )

  const hasMultipleIndividualVariants =
    (orderableVariants.filter((distributor: DistributorDataType) => {
      return distributor.quantity_per_item === 1
    })?.length || 0) > 1

  return (
    <>
      <div className={classes.selectionHeader}>Bulk Quantity</div>
      {orderableVariants.map((distributor: DistributorDataType) => {
        return (
          <MenuItem
            onClick={() => {
              // Gracefully handle scenarios where there are multiple options with the same quantity_per_item
              if (distributor.variant_id === lineItem.variantId) {
                return
              }
              handleSelectBulkOption({
                distributor,
              })
            }}
            className={classes.menuItem}
          >
            {variantLabel(distributor.quantity_per_item, hasMultipleIndividualVariants, distributor.variant_id)}
          </MenuItem>
        )
      })}
    </>
  )
}

export const createBulkQuantityOptions = (
  trackSource,
  lineItem: LineItemType,
  handleUpdate: (newLineItem: LineItemType) => void
) => {
  return ({ closeDropdown }) => (
    <BulkQuantityOptions
      closeDropdown={closeDropdown}
      lineItem={lineItem}
      updateLineItem={handleUpdate}
      trackSource={trackSource}
    />
  )
}

export default BulkQuantityOptions

import { useCallback, useState } from 'react'
import type { ComponentTypes } from 'types/selectComponent'

type FilterType = {
  forecastEndDate?: string
  code?: string
  projectId?: number
  componentType?: ComponentTypes
}

export type ForecastDetailDialogController = {
  open: boolean
  onClose(): void
  onOpen(): void
  onCancel(): void
  setFilter(newFilterValues: FilterType): void
} & FilterType

const useForecastDetailDialogController = ({ afterClose }): ForecastDetailDialogController => {
  const [open, setOpen] = useState(false)
  const [filterValues, setFilterValues] = useState<FilterType>({})

  const onClose = useCallback(() => {
    setOpen(false)
    afterClose?.()
  }, [])

  const onOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const onCancel = useCallback(() => {
    setOpen(false)
  }, [])

  const setFilter = useCallback((newFilterValues: FilterType) => {
    setFilterValues((oldFilterValues) => {
      return { ...oldFilterValues, ...newFilterValues }
    })
  }, [])

  return {
    open,
    onClose,
    onOpen,
    onCancel,
    setFilter,
    forecastEndDate: filterValues.forecastEndDate,
    code: filterValues.code,
    projectId: filterValues.projectId,
    componentType: filterValues.componentType,
  }
}

export default useForecastDetailDialogController

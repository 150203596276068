import { Accordion, AccordionDetails, AccordionSummary, Grow, makeStyles, useMediaQuery } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { forceOpenCreditApp } from 'ducks/myEnergy'
import { orgSelectors } from 'ducks/orgs'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import PaymentOptions from 'myenergy/elements/PaymentOptions'
import { useTranslate } from 'ra-core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { INTEGRATION_TO_LOGO } from 'resources/integrations/constants'
import { PaymentOptionDataType } from 'types/paymentOptions'
import { ProposalDataType } from 'types/proposals'
import { Theme } from 'types/themes'
import { currencySymbolForCountry, formatCurrencyWithSymbol } from 'util/misc'
import { QuotationTablePropsType } from './index'

const BASE_IMG_HEIGHT = 30

type PropTypes = {
  paymentOption: PaymentOptionDataType
  setSelectedPaymentOption: (pmt: PaymentOptionDataType | undefined) => void
  isSelected: boolean
  quotationTableProps: QuotationTablePropsType
  systemId: number
  openApp: () => void
}

const useStyles = makeStyles<Theme, { isMobile: boolean }>((theme) => {
  return {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      marginBottom: '10px',
      width: '100%',
    },
    accordion: {
      width: '100%',
    },
    accordionSummary: {
      position: 'sticky',
      top: '-10px',
      backgroundColor: 'white',
    },
    accordionSummaryWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    titleRow: ({ isMobile }) => ({
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      justifyContent: isMobile ? 'center' : 'space-between',
      alignItems: isMobile ? 'flex-start' : 'center',
      width: '100%',
    }),
    titleRowRight: ({ isMobile }) => ({
      flex: 1,
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      justifyContent: 'space-between',
      alignItems: isMobile ? 'flex-start' : 'center',
    }),
    title: {
      marginBottom: '0px',
      marginTop: '0px',
      fontSize: '20px',
      flex: 1,
    },
    quotationTableWrapper: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
  }
})

const PaymenOptionRow: React.FC<PropTypes> = (props) => {
  const isMobile = useMediaQuery('(max-width:800px)')
  const countryIso2 = useSelector(orgSelectors.getOrgIso2)
  const currencySymbol = currencySymbolForCountry(countryIso2)

  const prepareProposalData = () => {
    if (props.quotationTableProps?.proposalData) {
      let newProposalData = { ...props.quotationTableProps.proposalData }
      // @ts-ignore
      newProposalData.selectedPaymentOption = props.paymentOption
      return newProposalData
    }
  }

  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [proposalDataWithPmt, setProposalDataWithPmt] = useState<ProposalDataType | undefined>(prepareProposalData())

  useEffect(() => {
    setProposalDataWithPmt(prepareProposalData())
  }, [props.quotationTableProps?.proposalData, props.paymentOption, props.systemId])

  useEffect(() => {
    if (isExpanded) {
      logAmplitudeEvent('apply_now_prompt_row_expanded', {
        integration: props.paymentOption?.integration,
        project_id: props.quotationTableProps?.proposalData?.selectedProject?.id,
        payment_option_id: props.paymentOption.id,
      })
      props.setSelectedPaymentOption(props.paymentOption)
    } else if (props.isSelected) {
      props.setSelectedPaymentOption(undefined)
    }
  }, [isExpanded, props.isSelected])

  const integrationLogo = INTEGRATION_TO_LOGO[props.paymentOption.integration]?.url
  const relativeHeight = INTEGRATION_TO_LOGO[props.paymentOption.integration]?.relativeHeight
  const imgHeight = BASE_IMG_HEIGHT * relativeHeight

  const classes = useStyles({ isMobile })
  const translate = useTranslate()
  const dispatch = useDispatch()

  const openApp = () => {
    dispatch(forceOpenCreditApp(props.systemId, props.paymentOption.id))
    props.openApp()
    logAmplitudeEvent('app_opened_from_prompt', {
      integration: props.paymentOption?.integration,
      project_id: props.quotationTableProps?.proposalData?.selectedProject?.id,
      payment_option_id: props.paymentOption.id,
    })
  }

  return (
    <div className={classes.wrapper}>
      <Accordion defaultExpanded={false} className={classes.accordion} onChange={() => setIsExpanded(!isExpanded)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummary}>
          <div style={{ width: '100%' }}>
            {integrationLogo && (
              <img src={integrationLogo} height={imgHeight} alt={props.paymentOption.integration || 'lender logo'} />
            )}
            <div className={classes.titleRow}>
              <h4 className={classes.title}>{props.paymentOption.title}</h4>
              {!isExpanded && (
                <div className={classes.titleRowRight}>
                  <div className={classes.title}>
                    {formatCurrencyWithSymbol(props.paymentOption?.regular_payment, currencySymbol, undefined, 0)} per
                    month
                  </div>
                  <div className="small">{translate('Click to learn more about this product and move forward')}</div>
                </div>
              )}
              {isExpanded && <ProUXButton onClick={openApp} type="primary" label="Apply for this product" />}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grow in={true}>
            <div className={classes.quotationTableWrapper}>
              <PaymentOptions
                // @ts-ignore
                quotationConfiguration={props.quotationTableProps.quotationConfiguration}
                // @ts-ignore
                layout={props.quotationTableProps.layout}
                onPaymentMethodChange={() => null}
                key={'test_key'}
                // @ts-ignore
                {...proposalDataWithPmt}
                selectPaymentChart={() => null}
                paymentChart={'bank_balance'}
                translate={translate}
              />
            </div>
          </Grow>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
export default PaymenOptionRow

import { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { useEffect, useState } from 'react'
import restClient from 'restClient'

const restClientInstance = restClient(window.API_ROOT + '/api')

const useDistributorHasTradePricing = ({
  orgId,
  distributor,
}: {
  orgId: number
  distributor: HardwareSupplierFilterKeyType | undefined
}) => {
  const [hasTradePricing, setHasTradePricing] = useState<boolean>(false)
  const [isChecking, setIsChecking] = useState<boolean>(false)
  useEffect(() => {
    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${orgId}/distributors/${distributor}/has_trade_pricing/`,
    })
      .then((response) => {
        if (response?.data?.result) {
          setHasTradePricing(response?.data?.result?.has_trade_pricing)
        }
      })
      .catch((err: any) => {
        console.log(err, err)
      })
      .finally(() => {
        setIsChecking(false)
      })
  }, [orgId, distributor])

  return { hasTradePricing, isChecking }
}

export default useDistributorHasTradePricing

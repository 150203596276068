import { validateTiersInput } from '../common'

export const validateInverterData = (values, translate) => {
  const errors = {}
  if (values.lookup_in_component_database !== false) {
    if (!values.inverter) {
      errors.inverter = [translate('Please select an inverter from dropdown.')]
    }
  }
  validateTiersInput(values, errors)
  return errors
}

import { Accordion, AccordionDetails, Dialog, DialogContent, DialogTitle, List, ListItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import OpenInNewIcon from '@material-ui/icons/OpenInNewOutlined'
import { authSelectors } from 'ducks/auth'
import { Button, IconButton } from 'opensolar-ui'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  accordionDetails: {
    [theme.breakpoints.between('sm', 'sm')]: {
      padding: '8px 10px 16px',
    },
  },
}))
const DesignedReplay = () => {
  const [designRecord, setDesignRecord] = useState([])

  const classes = useStyles()
  const org_id = useSelector(authSelectors.getOrgId)
  const { id } = useParams()
  const history = useHistory()
  const [showDialog, setShowDialog] = useState(false)

  const handleClickOpenDialog = () => {
    getDesignRecord()
  }

  const handleClickOpenReplayRecord = (fullurl) => {
    const url = fullurl.substring(fullurl.lastIndexOf('#/') + 1)
    history.push(url)
  }

  const getDesignRecord = () => {
    fetch(`${window.API_ROOT}/api/orgs/${org_id}/projects/${id}/replays/`, {
      method: 'GET',
      headers: window.Utils.tokenAuthHeaders(),
      mode: 'cors',
      credentials: 'same-origin',
      cache: 'no-cache',
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setDesignRecord(data)
            setShowDialog(true)
          })
        } else {
          setDesignRecord([])
          setShowDialog(true)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <Accordion key="view-design-replay" expanded={true} style={{ color: 'red' }}>
      <AccordionDetails classes={classes.accordionDetails}>
        <div style={{ margin: '7px 0  0 30px' }}>
          <Button
            onClick={handleClickOpenDialog}
            variant="contained"
            style={{ textTransform: 'none' }}
            classes={classes}
            disableRipple={true}
          >
            <span> View Design Replay</span>
          </Button>
        </div>
        {designRecord && (
          <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
            <IconButton
              id="SiteDetailCloseIcon"
              style={{
                top: 10,
                right: 10,
                position: 'absolute',
                minWidth: 40,
                backgroundColor: 'rgba(255, 255, 255, 0)',
              }}
              onClick={() => setShowDialog(false)}
            >
              <CloseIcon />
            </IconButton>
            {/* translation is not required for Design Sessions to Replay */}
            <DialogTitle>Design Sessions to Replay</DialogTitle>

            <DialogContent display="flex" justifyContent="center" alignItems="center">
              {designRecord
                ? designRecord.length > 0 && (
                    <List display="flex" justifyContent="center" alignItems="center">
                      {designRecord?.map((record) => (
                        <>
                          <Button
                            onClick={() => handleClickOpenReplayRecord(record.play)}
                            variant="contained"
                            style={{ textTransform: 'none', margin: '0.5rem' }}
                            classes={classes}
                            disableRipple={true}
                          >
                            <ListItem>
                              {/* translation is not required for Replay session for */}
                              Replay session for {record.timestamp}
                              &nbsp;&nbsp;&nbsp;
                              <OpenInNewIcon />
                            </ListItem>
                          </Button>
                        </>
                      ))}
                    </List>
                  )
                : null}
            </DialogContent>
          </Dialog>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default DesignedReplay

import { Paper, makeStyles } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import { walletSettingsSelectors } from 'ducks/wallet'
import { StandardInput, standardInputStyles } from 'elements/input/StandardInput'
import React from 'react'
import { TextInput, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { required } from 'validations'
import { useTaxIdentifierLabel } from './useTaxIdentifierLabel'

export const useTaxDetailsStyles = makeStyles(
  {
    root: {
      maxWidth: 600,
      margin: '20px 0px',
      padding: '10px 20px',
    },
  },
  { name: 'TaxDetailsSection' }
)

const TaxIdentifierInput = ({ countryIso2 }: { countryIso2: string }) => {
  const wallet = useSelector(walletSettingsSelectors.getWallet)
  const tax_identifier_label = useTaxIdentifierLabel(wallet?.currency, countryIso2)

  return (
    <StandardInput label={tax_identifier_label}>
      <TextInput
        style={standardInputStyles.input}
        source="tax_identifier"
        label={false}
        required={true}
        validate={required}
        InputLabelProps={{
          shrink: true,
        }}
        defaultValue={wallet?.tax_identifier}
      />
    </StandardInput>
  )
}

export const TaxDetailsSection = () => {
  const countryIso2 = useSelector(orgSelectors.getOrgIso2)
  const translate = useTranslate()
  const classes = useTaxDetailsStyles()

  if (countryIso2 !== 'GB' && countryIso2 !== 'NZ') return null

  return (
    <Paper className={classes.root}>
      <h2>{translate('Tax Details')}</h2>
      <TaxIdentifierInput countryIso2={countryIso2} />
    </Paper>
  )
}

import { Accordion, AccordionDetails, AccordionSummary, Theme, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { orgSelectors } from 'ducks/orgs'
import { projectMilestonesSelectors } from 'ducks/projectMilestones'
import { useGetIsCashFlowVisible } from 'pages/cashFlow/utils'
import EditInvoiceDetailsButton from 'projectSections/sections/manage/cashFlowTransactions/invoices/EditInvoiceDetailsButton'
import { useTranslate } from 'ra-core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { OpenSolarThemeType } from 'Themes'
import { StudioSystemType } from 'types/global'
import { PaymentOptionDataType } from 'types/paymentOptions'
import { urlToId } from 'util/misc'
import MilestoneOverrideButton from '../projectProgress/cashFlow/milestoneOverride/MilestoneOverrideButton'
import CashFlowTransactionTable from './CashFlowTransactionTable'
import CustomInvoiceButton from './customInvoice/CustomInvoiceButton'
import { useGetProjectHasCashFlow } from './utils'

const useStyles = makeStyles<OpenSolarThemeType, { isXs: boolean }>((theme) => ({
  content: {
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  headerWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: ({ isXs }) => (isXs ? 'flex-start' : 'flex-end'),
    flexDirection: ({ isXs }) => (isXs ? 'column' : 'row'),
  },
  headerTitle: {
    fontSize: '18px',
  },
  headerButtonsWrapper: {
    display: 'flex',
    justifyContent: ({ isXs }) => (isXs ? 'space-evenly' : 'flex-end'),
    alignItems: 'flex-end',
    flexWrap: ({ isXs }) => (isXs ? 'wrap' : 'nowrap'),
    marginTop: ({ isXs }) => (isXs ? '1rem' : 'unset'),
    rowGap: ({ isXs }) => (isXs ? '10px' : 'unset'),
  },
  headerButton: {
    margin: '0px 5px 0px 10px',
  },
}))

const CashFlowTransactions: React.FunctionComponent = () => {
  const [soldSystem, setSoldSystem] = useState<StudioSystemType | undefined>(undefined)
  const [soldPaymentOption, setSoldPaymentOption] = useState<PaymentOptionDataType | undefined>(undefined)
  const [calcsFinishedTimeStamp, setCalcsFinishedTimeStamp] = useState<string | undefined>(undefined)

  const cashFlowIsVisible = useGetIsCashFlowVisible()
  const orgHasCashFlow = useSelector(orgSelectors.getCashFlowIsActive)
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const classes = useStyles({ isXs })
  const translate = useTranslate()
  const project = useFormState()?.values
  const soldSystemURL = project?.system_sold
  const soldPmtURL = project?.payment_option_sold
  const hasCashFlowOnThisProject = useGetProjectHasCashFlow()
  const expectedMilestonePayments = useSelector(projectMilestonesSelectors.getExpectedMilestonePayments)

  const handleSystemCalculationChanged = useCallback(() => {
    setCalcsFinishedTimeStamp(`${new Date()}`)
  }, [soldSystemURL, soldPmtURL])

  const setSoldRecords = useCallback(() => {
    if (soldSystemURL && soldPmtURL) {
      const allSystemsOnProject = window.editor.getSystems()
      const soldSystem = project?.systems?.find((sys) => sys.url === soldSystemURL)
      setSoldSystem(soldSystem)
      if (soldSystem) {
        const soldPmtId = urlToId(soldPmtURL)
        const soldPaymentOption = allSystemsOnProject
          ?.find((sys) => sys.uuid === soldSystem.uuid)
          ?.payment_options?.find((pmt) => pmt.id === soldPmtId)
        setSoldPaymentOption(soldPaymentOption)
      }
    } else {
      if (soldSystem && !soldSystemURL) setSoldSystem(undefined)
      if (soldPaymentOption && !soldPmtURL) setSoldPaymentOption(undefined)
    }
  }, [soldPmtURL, soldSystemURL])

  useEffect(() => {
    setSoldRecords()
  }, [soldSystemURL, soldPmtURL])

  useEffect(() => {
    if (calcsFinishedTimeStamp) setSoldRecords()
  }, [calcsFinishedTimeStamp])

  useEffect(() => {
    window.editor.signals.systemCalculationsUpdated.add(handleSystemCalculationChanged)
    return () => {
      window.editor.signals.systemCalculationsUpdated.remove(handleSystemCalculationChanged)
    }
  }, [])

  const isSold = useMemo(() => {
    return Boolean(project?.system_sold) && Boolean(project?.payment_option_sold)
  }, [project?.system_sold, project?.payment_option_sold])

  const totalPayable = useMemo(() => {
    return soldPaymentOption?.pricing?.system_price_payable
  }, [soldPaymentOption])

  if (!cashFlowIsVisible || !isSold || (!orgHasCashFlow && !hasCashFlowOnThisProject)) return null
  return (
    <Accordion key="cash-flow-transactions" expanded={true}>
      <AccordionSummary
        aria-controls="expansion-content-cash-flow-transactions"
        id="expansion-header-cash-flow-transactions"
        classes={{ content: classes.content }}
      >
        <div className={classes.headerWrapper}>
          <div className={classes.headerTitle}>{translate('Invoices')}</div>
          <div className={classes.headerButtonsWrapper}>
            <div className={classes.headerButton}>
              {soldPaymentOption && soldSystem && (
                <CustomInvoiceButton paymentOptionId={soldPaymentOption?.id} systemUuid={soldSystem?.uuid} />
              )}
            </div>
            <div className={classes.headerButton}>
              <EditInvoiceDetailsButton projectId={project?.id} />
            </div>
            {project?.id && soldPaymentOption && soldPaymentOption?.org_id && soldSystem && totalPayable && (
              <div className={classes.headerButton}>
                <MilestoneOverrideButton
                  milestones={expectedMilestonePayments || []}
                  projectId={project?.id}
                  orgId={soldPaymentOption?.org_id}
                  systemUuid={soldSystem?.uuid}
                  paymentOptionId={soldPaymentOption?.id}
                  pricePayable={totalPayable}
                />
              </div>
            )}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {soldSystem && soldPaymentOption && (
          <CashFlowTransactionTable soldSystem={soldSystem} soldPaymentOption={soldPaymentOption} />
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default CashFlowTransactions

import { Button, Dialog, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { OpenSolarThemeType } from 'Themes'
import Alert from 'elements/Alert'
import { IconButton } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { SimpleForm, TextInput, Toolbar } from 'react-admin'
import { useFormState } from 'react-final-form'
import ShareabilitySelector from 'resources/connectedOrgs/ShareabilitySelector'
import { WorkflowActionType } from 'types/workflows'

interface FormProps {
  action?: WorkflowActionType
  handleSubmit: (values) => void
  onClose: () => void
  isEdit: boolean
}
const useStylesToolbar = makeStyles({
  toolbar: {
    padding: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  submitBtn: {
    marginLeft: 10,
  },
})

const ActionFormToolbar: React.FC<FormProps> = ({ isEdit, handleSubmit, onClose }) => {
  const classes = useStylesToolbar()
  const translate = useTranslate()
  const formValues = useFormState().values
  return (
    <Toolbar classes={classes}>
      <>
        <Button onClick={onClose} variant="contained" color="default">
          {translate('Cancel')}
        </Button>
        <Button
          onClick={() => {
            handleSubmit(formValues)
          }}
          variant="contained"
          color="primary"
          className={classes.submitBtn}
        >
          {translate(isEdit ? 'Confirm' : 'Add')}
        </Button>
      </>
    </Toolbar>
  )
}

const ActionFormContent: React.FC<FormProps> = (props) => {
  return (
    <SimpleForm toolbar={<ActionFormToolbar {...props} />} save={props.handleSubmit} initialValues={props.action}>
      <div style={{ width: '100%' }}>
        <TextInput
          style={{ maxWidth: 300 }}
          fullWidth
          source="title"
          label="Title"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <ShareabilitySelector />
      </div>
    </SimpleForm>
  )
}
const useStyles = makeStyles((theme: OpenSolarThemeType) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  closeBtn: {
    marginLeft: 'auto',
    marginRight: 10,
  },
  content: {
    marginBottom: 20,
    '& p': {
      color: theme.greyMid1,
    },
    '& .MuiCardContent-root': {
      padding: '0px !important',
    },
  },
  editWarning: {
    margin: '0 20px',
  },
  form: {},
}))

interface DialogProps {
  action?: WorkflowActionType
  onClose: () => void
  handleSubmit: (values) => void
}

const ActionDialog: React.FC<DialogProps> = ({ action, onClose, handleSubmit }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const isEdit = useMemo(() => typeof action?.id !== 'string' || action?.title !== undefined, [action])
  const showActionWarning = useMemo(() => {
    if (action?.workflows_applied && action?.workflows_applied?.length > 1) {
      return (
        translate('This action is currently applied to the following workflows:') +
        ` ${action?.workflows_applied
          .map((x) => x.title)
          ?.toString()
          ?.replaceAll(',', ', ')}`
      )
    } else {
      return undefined
    }
  }, [action])

  if (!action) return null
  return (
    <Dialog open={!!action}>
      <div className={classes.row}>
        <DialogTitle>
          {isEdit ? <>{translate('Edit Action: ') + action.title}</> : <>{translate('New Action')}</>}
        </DialogTitle>
        <IconButton aria-label="close" onClick={onClose} className={classes.closeBtn}>
          <Close />
        </IconButton>
      </div>
      {isEdit && showActionWarning !== undefined && (
        <div className={classes.editWarning}>
          <Alert severity={'warning'}>
            {translate('Any changes to action will reflect on all Project Workflows where this action is being used.')}
            <br />
            <br />
            {showActionWarning}
          </Alert>
        </div>
      )}
      <DialogContent className={classes.content}>
        {isEdit && typeof action.id === 'number' && <p>ID: {action.id} </p>}
        <ActionFormContent action={action} handleSubmit={handleSubmit} isEdit={isEdit} onClose={onClose} />
      </DialogContent>
    </Dialog>
  )
}
export default ActionDialog

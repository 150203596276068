import { Typography } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orgSelectors } from 'ducks/orgs'
import Alert from 'elements/Alert'
import LineItemType from 'pages/ordering/OrderLineItem'
import { getSupplierConfig } from 'pages/ordering/configs'
import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { saveFileAs } from 'util/misc'

const useAlertStyles = makeOpenSolarStyles((theme) => ({
  heading: {
    fontSize: 24,
    lineHeight: '20px',
    marginBottom: 10,
  },
  wrapper: {
    margin: '15px 0px',
    padding: '10px 10px',
    border: '1px solid #FFB945',
  },
  clickableLink: {
    textDecoration: 'underline',
    color: '#1751D0',
    cursor: 'pointer',
  },
}))

// Consider move to utils
const arrayToCsv = (array: string[][]) => {
  return 'data:text/csv;charset=utf-8,' + array.map((e) => e.join(',')).join('\n')
}

const CSV_KEY_AND_VALUE = [
  {
    name: 'Item',
    getValue: (lineItem) => lineItem.code,
  },
  {
    name: 'Category',
    getValue: (lineItem) => lineItem.componentType || lineItem.data?.component_type,
  },
  {
    name: 'Manufacturer',
    getValue: (lineItem) => lineItem.data?.manufacturer_name,
  },
  {
    name: 'Quantity',
    getValue: (lineItem) => lineItem.quantity,
  },
]

const UnavailableItemsAlert = ({ unavailableLineItems }: { unavailableLineItems: LineItemType[] }) => {
  const classes = useAlertStyles()
  const selectedSupplier = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const supplierConfig = getSupplierConfig(selectedSupplier)

  useEffect(() => {
    logAmplitudeEvent('hardware_unavailable_items_warning', {
      action: 'viewed',
      context: 'warning',
    })
  }, [])

  const handleClick = useCallback(() => {
    const csvArray: string[][] = []
    csvArray.push(CSV_KEY_AND_VALUE.map((csvKeyValue) => csvKeyValue['name']))
    unavailableLineItems.forEach((lineItem: LineItemType) => {
      const record = CSV_KEY_AND_VALUE.map((csvKeyValue) => csvKeyValue.getValue(lineItem))
      csvArray.push(record)
    })
    const csvContent = arrayToCsv(csvArray)
    saveFileAs(encodeURI(csvContent), 'Unavailable_Order_Items.csv')
    logAmplitudeEvent('hardware_unavailable_items_warning', {
      action: 'clicked',
      context: 'download_link',
    })
  }, [unavailableLineItems])

  return (
    <Alert severity="warning" classes={{ wrapper: classes.wrapper }}>
      <div className={classes.heading}>Not Supplied by Distributor</div>
      <Typography>
        {`Items below are unavailable from ${supplierConfig?.name}. They cannot be added to your cart.`}
        <span onClick={handleClick} className={classes.clickableLink}>
          {' '}
          Download list of unavailable items.
        </span>
      </Typography>
    </Alert>
  )
}

export default UnavailableItemsAlert

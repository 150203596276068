import React from 'react'

const InfoIcon = (props) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="24" height="24" transform="translate(0.5)" fill="none" />
    <path
      d="M2.5 12.0074C2.5 6.49409 6.98769 2 12.5 2C18.0203 2 22.5 6.47803 22.5 11.9913C22.5 17.7373 17.7423 22.087 12.3094 21.9987C6.9718 21.9104 2.5 17.4324 2.5 12.0074ZM12.4682 20.6986C17.2816 20.6986 21.2053 16.8144 21.2133 12.0395C21.2291 7.2324 17.2736 3.26797 12.5 3.28402C7.71843 3.30007 3.77085 7.25648 3.79468 11.9993C3.82645 16.8064 7.70254 20.6906 12.4682 20.6986Z"
      fill="url(#paint0_linear_6711:178344)"
    />
    <path
      d="M10.3554 10.282C10.4666 10.1295 10.5302 9.9449 10.6175 9.93688C11.8645 9.73625 13.1195 9.55167 14.4619 9.35104C14.16 10.3301 13.9059 11.1647 13.6437 11.9993C13.2228 13.3636 12.7939 14.7199 12.3808 16.0841C12.3093 16.3169 12.1187 16.6299 12.4205 16.8064C12.7383 16.991 12.9289 16.678 13.1116 16.4934C13.3975 16.1965 13.6437 15.8755 13.9217 15.5384C14.0171 15.5866 14.1203 15.6428 14.2633 15.715C13.5723 16.7743 12.8653 17.7614 11.5707 18.0664C11.2847 18.1306 10.9591 18.1306 10.6652 18.0744C9.91061 17.9299 9.52936 17.312 9.75176 16.5416C10.1171 15.2495 10.5381 13.9655 10.9273 12.6815C11.0862 12.1759 11.2529 11.6623 11.3721 11.1487C11.5071 10.5709 11.3006 10.3381 10.697 10.3221C10.6255 10.3141 10.5381 10.298 10.3554 10.282Z"
      fill="url(#paint1_linear_6711:178344)"
    />
    <path
      d="M13.8186 7.92259C12.9925 7.92259 12.3968 7.36083 12.3968 6.59042C12.4047 5.82 13.0084 5.27429 13.8424 5.28231C14.6684 5.29034 15.2721 5.8521 15.2721 6.62252C15.2641 7.36886 14.6525 7.92259 13.8186 7.92259Z"
      fill="url(#paint2_linear_6711:178344)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_6711:178344"
        x1="-30.3"
        y1="-0.109091"
        x2="-20.6394"
        y2="-16.065"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#242730" />
        <stop offset="1" stopColor="#4E5056" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_6711:178344"
        x1="1.86095"
        y1="8.42679"
        x2="6.61201"
        y2="4.15332"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#242730" />
        <stop offset="1" stopColor="#4E5056" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_6711:178344"
        x1="7.6813"
        y1="5.00379"
        x2="8.90387"
        y2="2.80486"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#242730" />
        <stop offset="1" stopColor="#4E5056" />
      </linearGradient>
    </defs>
  </svg>
)
export default InfoIcon

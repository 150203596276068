import { useCallback } from 'react'
import { ProjectErrorType } from 'reducer/project/projectErrorsReducer'
import { filterShareMessage } from './filterShareMessage'

export const useSharingErrorFilter = (otherFilter?: (error: ProjectErrorType) => boolean, sharingSection?: string) => {
  return useCallback(
    (error: ProjectErrorType): boolean => {
      if (otherFilter && !otherFilter(error)) return false
      if (sharingSection && error.source === 'sharing' && !filterShareMessage(error.message, sharingSection))
        return false
      return true
    },
    [otherFilter, sharingSection]
  )
}

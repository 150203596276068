import { useMemo } from 'react'
import { useNotify } from 'react-admin'
import restClient from 'restClient'
import HdmService from 'services/hdm/HdmService'

const restClientInstance = restClient(window.API_ROOT + '/api')
const useHdmService = (): HdmService => {
  const notify = useNotify()
  const hdmService = useMemo(() => new HdmService(restClientInstance, notify), [restClientInstance, notify])

  return hdmService
}

export default useHdmService

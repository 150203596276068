export const PAYMENT_TYPE_LABEL_MAP = {
  lease: 'Lease',
  loan: 'Loan',
  regular_payment: 'Regular Payment',
  cash: 'Cash',
  ppa: 'PPA',
}

export const PAYMENT_FREQUENCY_MAP = {
  monthly: 'Monthly',
  bimonthly: 'Bimonthly',
  fortnightly: 'Fortnightly',
  weekly: 'Weekly',
  quarterly: 'Quarterly',
  yearly: 'Annually',
}

export const ROW_WIDTHS = ['5%', '25%', '5%', '15%', '15%', '15%', '10%', '10%']

export const MESSAGE_TYPE_PRIORITY = {
  disabled: 0,
  error: 1,
  warning: 2,
}

// integrations where you need to either use all of their products or none. Hopefully this list never expands :-/
export const ALL_OR_NOTHING_INTEGRATIONS = ['phoenix', 'selina']

export const INTEGRATIONS_WITHOUT_QUICK_CALCS = ['lightreach']

import { useCallback } from 'react'

const useLoadComponentSpecs = () => {
  const loadComponentSpecs = useCallback(async () => {
    const loadComponentSpecsPromise = new Promise((resolve, reject) => {
      window.AccountHelper.loadComponentSpecs(resolve)
    })
    await loadComponentSpecsPromise
    return true
  }, [])
  return loadComponentSpecs
}

export default useLoadComponentSpecs

import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputLabel, makeStyles } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import CustomField from 'elements/field/CustomField'
import CheckboxInput from 'elements/input/CheckboxInput'
import DependentInput from 'elements/input/DependentInput'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import { useNotify, useRefresh, useTranslate } from 'ra-core'
import { BooleanInput, SelectInput, TextInput } from 'ra-ui-materialui'
import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import { LENDER_LOGO_PATHS } from 'resources/financeIntegrations/constants'
import restClient from 'restClient'
import { AvailableFinanceIntegrationType, IntakeFieldType } from 'types/orgs'
import { getIsValidEmail } from 'util/misc'
import { addOpenSolarParams } from './utils'

type PropTypes = {
  isOpen: boolean
  onClose: () => void
  integration: AvailableFinanceIntegrationType
  isPaymentsPage?: boolean
}

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  buttonWrapper: {
    margin: '20px 40px',
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
  },
}))

const PromoDialog: React.FC<PropTypes> = (props) => {
  const [step, setStep] = useState<1 | 2 | 3>(1)
  const [isExisting, setIsExisting] = useState<boolean>(false)
  const [formError, setFormError] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const notify = useNotify()
  const translate = useTranslate()
  const refreshList = useRefresh()

  const org = useSelector(orgSelectors.getOrg)
  const role = useSelector(authSelectors.getCurrentRole)
  const isAdmin = useSelector(authSelectors.getIsAdmin)

  const classes = useStyles()

  const newPartnerRedirectURL = useMemo(() => {
    if (isAdmin) return props.integration.banner_content?.new_partner_redirect_url
    else return props.integration.banner_content?.new_partner_redirect_url_non_admin
  }, [isAdmin, props.integration.banner_content])

  const newExistingRedirectURL = useMemo(() => {
    if (isAdmin) return props.integration.banner_content?.existing_partner_redirect_url
    else return props.integration.banner_content?.existing_partner_redirect_url_non_admin
  }, [isAdmin, props.integration.banner_content])

  useEffect(() => {
    if (props.isOpen) {
      logAmplitudeEvent('integrated_finance_onboarding_dialog_opened', {
        integration: props.integration.integration_name,
      })
    }
  }, [props.isOpen])

  useEffect(() => {
    const postHashParams = window.location.hash.substring(window.location.hash.indexOf('?') + 1)
    if (!postHashParams) return
    const params = new URLSearchParams(postHashParams)
    const products = params.get('products_added')
    const partner = params.get('partner')

    if (products) {
      notify(`${products} ${partner} products have been added to your org.`, 'success')
    }
  }, [])

  const continueFromStep1 = () => {
    if (props.integration?.banner_content?.skip_step_2) {
      if (newPartnerRedirectURL) {
        window.open(
          addOpenSolarParams(newPartnerRedirectURL, org?.id, org?.name, role?.id, org?.country_iso2),
          '_blank'
        )
        logAmplitudeEvent('integrated_finance_onboarding_user_redirected', {
          integration: props.integration.integration_name,
          account_type: 'new',
        })
      } else {
        if (props.integration?.banner_content?.skip_step_3) {
          const API_URL = window.API_ROOT + '/api'
          const restClientInstance = restClient(API_URL)
          restClientInstance('CUSTOM_POST', 'custom', {
            url: `orgs/${org?.id}/sync_payment_options/${props.integration.integration_name}/`,
          })
            .then((response) => {
              logAmplitudeEvent('integrated_finance_products_refreshed', {
                integration: props.integration.integration_name,
              })
              refreshList()
              const productsAdded = response.data.message.split(' ')[0]
              const newUrl = `${window.location.origin}${window.location.hash}?products_added=${productsAdded}&partner=${props.integration.integration_name}`
              window.history.replaceState(null, '', newUrl)
              window.location.reload()
            })
            .catch((error) => {
              logAmplitudeEvent('integrated_finance_products_refresh_error', {
                integration: props.integration.integration_name,
              })
              notify(error.message, 'warning')
            })
            .finally(() => setLoading(false))
          return
        }
        setStep(3)
      }
    } else setStep(2)
  }

  const renderStep1 = () => (
    <>
      <DialogTitle></DialogTitle>
      <DialogContent>
        <Grid container spacing={0}>
          <Grid item xs={12} className={classes.row}>
            <img
              src={
                props.integration.logo_large_contents ||
                `${window.PUBLIC_URL}/images/${LENDER_LOGO_PATHS[props.integration.integration_name]?.large}`
              }
              alt={`${props.integration.display_name} logo`}
              height="75px"
            />
          </Grid>
          <Grid item xs={12} className={classes.row}>
            <h2>
              {props.integration?.banner_content?.promo_content_title
                ? props.integration.banner_content.promo_content_title
                : translate('%{partnerName} Integration on OpenSolar', { partnerName: props.integration.display_name })}
            </h2>
          </Grid>
          <Grid item xs={12} className={classes.row}>
            {/* @ts-ignore */}
            <div dangerouslySetInnerHTML={{ __html: props.integration.banner_content?.promo_content_html }}></div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.row}>
          <div className={classes.buttonWrapper}>
            <ProUXButton label="Dismiss" onClick={props.onClose} type="secondary" />
          </div>
          <div className={classes.buttonWrapper}>
            <ProUXButton
              label={
                props.integration?.banner_content?.connect_button_label ||
                translate('Connect %{partnerName}', {
                  partnerName: props.integration.display_name,
                })
              }
              onClick={continueFromStep1}
              type="primary"
            />
          </div>
        </div>
      </DialogActions>
    </>
  )

  const handleExisitngPartnerClick = () => {
    if (newExistingRedirectURL) {
      //this is a carve out just for Brighte
      if (newExistingRedirectURL === 'API_KEYS_PAGE') {
        window.open(`#/external_api_keys/${org?.id}`, '_blank')
      } else {
        window.open(
          addOpenSolarParams(newExistingRedirectURL, org?.id, org?.name, role?.id, org?.country_iso2),
          '_blank'
        )
      }
      logAmplitudeEvent('integrated_finance_onboarding_user_redirected', {
        integration: props.integration.integration_name,
        account_type: 'existing',
      })
      sendInternalEmailWithRedirect('existing')
      props.onClose()
    } else {
      setStep(3)
      setIsExisting(true)
    }
  }

  const handleNewPartnerClick = () => {
    if (newPartnerRedirectURL) {
      window.open(addOpenSolarParams(newPartnerRedirectURL, org?.id, org?.name, role?.id, org?.country_iso2), '_blank')
      logAmplitudeEvent('integrated_finance_onboarding_user_redirected', {
        integration: props.integration.integration_name,
        account_type: 'new',
      })
      sendInternalEmailWithRedirect('new')
      props.onClose()
    } else {
      setStep(3)
      setIsExisting(false)
    }
  }

  // when users are redirected to the lender we still want a paper trail saying they came through us so we'll send ourselves a simple email
  const sendInternalEmailWithRedirect = (account_type: 'new' | 'existing') => {
    const vals = {
      integration: props.integration.integration_name,
      company_name: org?.name,
      account_type: account_type,
      is_custom: true,
    }
    onSubmit(vals, true)
  }

  const renderStep2 = () => (
    <>
      <DialogTitle></DialogTitle>
      <DialogContent>
        <Grid container spacing={0}>
          <Grid item xs={12} className={classes.row}>
            <img
              src={
                props.integration.logo_large_contents ||
                `${window.PUBLIC_URL}/images/${LENDER_LOGO_PATHS[props.integration.integration_name]?.large}`
              }
              alt={`${props.integration.display_name} logo`}
              height="75px"
            />
          </Grid>
          <Grid item xs={12} className={classes.row}>
            <h2>{translate("Great - Let's get going!")}</h2>
          </Grid>
          <Grid item xs={12} className={classes.row}>
            {props.integration.banner_content?.page_2_content ? (
              <div dangerouslySetInnerHTML={{ __html: props.integration.banner_content?.page_2_content }}></div>
            ) : (
              <p>
                {translate(
                  'First, are you an existing %{partnerName} partner or do you hope to start selling %{partnerName}?',
                  { partnerName: props.integration.display_name }
                )}
              </p>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.row}>
          <div className={classes.buttonWrapper}>
            <ProUXButton label={translate('Already a Partner')} onClick={handleExisitngPartnerClick} type="primary" />
          </div>
          <div className={classes.buttonWrapper}>
            <ProUXButton label={translate('Become a Partner')} onClick={handleNewPartnerClick} type="primary" />
          </div>
        </div>
      </DialogActions>
    </>
  )

  const onSubmit = (vals: any, ignoreValidation: boolean) => {
    if (!ignoreValidation) {
      // we always ask about connected orgs, so we should always validate it
      if (vals?.has_connected_orgs && !vals?.connected_org_relationship) {
        setFormError(translate('Please describe your relationship with other companies'))
        return
      }

      if (!props.integration.banner_content.intake_fields) {
        let newError = undefined as undefined | string
        if (!vals.company_name) newError = translate('Company name is required')
        else if (!vals.company_hq) newError = translate('Company HQ city and state is required')
        else if (!vals.name) newError = translate('Your Name is required')
        else if (!vals.email) newError = translate('Your Email is required')
        else if (!getIsValidEmail(vals.email)) newError = translate('Your Email is invalid')
        else if (!vals.phone) newError = translate('Phone is required')
        else if (!vals.title) newError = translate('Title is required')
        else if (!isExisting && !vals.installs) newError = translate('Number of installs is required')
        else if (!isExisting && vals.revenue === undefined) newError = translate('Annual Revnue is required')

        if (newError) {
          setFormError(newError)
          return
        }
      } else {
        let newError = undefined as undefined | string
        props.integration.banner_content.intake_fields?.forEach((field) => {
          let fieldIsRequired = true
          if (field.filter_country && field.filter_country !== org?.country?.iso2) fieldIsRequired = false
          if (isExisting && field.only_new_partners) fieldIsRequired = false
          if (!isExisting && field.only_existing_partners) fieldIsRequired = false
          if (fieldIsRequired) {
            const dynamicErrorMsg = translate('%{fieldLabel} is required', { fieldLabel: field.field_label })
            if (field.field_type !== 'boolean' && !vals[field.field_name]) newError = dynamicErrorMsg
          }
        })
        if (newError) {
          setFormError(newError)
          return
        }
      }
    }

    setFormError(undefined)
    setLoading(true)
    const API_URL = window.API_ROOT + '/api'
    const restClientInstance = restClient(API_URL)
    restClientInstance('CUSTOM_POST', 'custom', {
      url: 'orgs/' + org?.id + '/roles/' + role?.id + '/connect_financing/',
      data: {
        account_type: isExisting ? 'existing' : 'new',
        ...vals,
        partner: props.integration.integration_name,
        is_custom: props.integration.banner_content?.intake_fields?.length > 0 || ignoreValidation,
      },
    })
      .then((response: any) => {
        if (response.data.success) {
          if (!props.isPaymentsPage) notify(translate('Your information has been saved'), 'success')
          let eventPayload = {}
          if (!props.integration.banner_content.intake_fields) {
            eventPayload = {
              integration: props.integration.display_name,
              company_name: vals.company_name,
              company_hq: vals.company_hq,
              name: vals.company_hq,
              email: vals.email,
              phone: vals.phone,
              title: vals.title,
              account_type: isExisting ? 'existing' : 'new',
              monthly_installs: !isExisting ? vals.installs : 'not asked',
              annual_revenue: !isExisting ? vals.revenue : 'not asked',
              org_id: org?.id,
              has_connected_orgs: vals?.has_connected_orgs,
              connected_org_relationship: vals?.connected_org_relationship,
            }
          } else {
            eventPayload = vals
          }
          logAmplitudeEvent('integrated_finance_onboarding_dialog_submitted', eventPayload)
          props.onClose()
        } else
          setFormError(
            translate('Sorry, we were unable to submit this form. Please reach out to support@opensolar.com directly')
          )
      })
      .catch((err: any) => {
        setFormError(
          translate('Sorry, we were unable to submit this form. Please reach out to support@opensolar.com directly')
        )
        logAmplitudeEvent('integrated_finance_onboarding_dialog_error', {
          integration: props.integration.integration_name,
        })
      })
      .finally(() => setLoading(false))
  }

  const getComponentTypeForField = (field: IntakeFieldType) => {
    if (field.field_type === 'boolean') return BooleanInput
    else if (field.field_type === 'select' && field.choices?.length) return SelectInput
    else return TextInput
  }

  const renderCustomInputs = (handleSubmit) => (
    <Grid container spacing={1}>
      {props.integration.banner_content?.intake_fields?.map((field) => {
        if (field?.filter_country && field.filter_country !== org?.country?.iso2) return null
        if (isExisting && field.only_new_partners) return null
        if (!isExisting && field.only_existing_partners) return null
        return (
          <Grid item xs={field.grid_size || 6} key={field.field_name}>
            <CustomField
              name={field.field_name}
              required={true}
              component={getComponentTypeForField(field)}
              choices={field.choices}
              label={field.field_label}
              fullWidth={true}
              style={{ marginBottom: '5px', marginTop: '5px', width: '100%' }}
            />
          </Grid>
        )
      })}
      <Grid item xs={12}>
        <CustomField
          name="has_connected_orgs"
          required={true}
          component={BooleanInput}
          label={'We sell on behalf of another company, or other companies sell on behalf of us'}
          fullWidth={true}
          elStyle={{ maxWidth: '100%' }}
          style={{ marginBottom: '5px', marginTop: '5px' }}
        />
      </Grid>
      <DependentInput dependsOn="has_connected_orgs" value={true}>
        <InputLabel id="connected-org-relationship">
          {translate(
            'Please explain how you work with other companies (including their names) to sell and/or install your projects'
          )}
        </InputLabel>
        <CustomField
          name="connected_org_relationship"
          required={true}
          component={TextInput}
          label={''}
          fullWidth={true}
          multiline={true}
          style={{ marginBottom: '5px' }}
        />
        <span className="small">
          {translate('Providing this information will speed up the activation of your integration')}.
        </span>
      </DependentInput>
      {formError && <p style={{ textAlign: 'center', color: 'red', width: '100%' }}>{formError}</p>}
      {renderFormButtons(handleSubmit)}
    </Grid>
  )

  const renderDefaultInputs = (handleSubmit) => (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <CustomField
          name="company_name"
          required={true}
          component={TextInput}
          label={translate('Company Name')}
          fullWidth={true}
          style={{ marginBottom: '5px', marginTop: '5px' }}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomField
          name="company_hq"
          required={true}
          component={TextInput}
          label={translate('Company HQ City and State')}
          fullWidth={true}
          style={{ marginBottom: '5px', marginTop: '5px' }}
        />
      </Grid>
      {!isExisting && (
        <>
          <Grid item xs={6}>
            <CustomField
              name="installs"
              required={true}
              component={TextInput}
              label={translate('# of Installed Projects / Month')}
              fullWidth={true}
              style={{ marginBottom: '5px', marginTop: '5px' }}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomField
              name="revenue"
              required={true}
              component={TextInput}
              label="Annual Revenue ($M)"
              fullWidth={true}
              style={{ marginBottom: '5px', marginTop: '5px' }}
            />
          </Grid>
        </>
      )}
      <Grid item xs={6}>
        <CustomField
          name="name"
          required={true}
          component={TextInput}
          label={translate('Your Name')}
          fullWidth={true}
          style={{ marginBottom: '5px', marginTop: '5px' }}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomField
          name="title"
          required={true}
          component={TextInput}
          label={translate('Your Title')}
          fullWidth={true}
          style={{ marginBottom: '5px', marginTop: '5px' }}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomField
          name="email"
          required={true}
          component={TextInput}
          label={translate('Email Address')}
          fullWidth={true}
          style={{ marginBottom: '5px', marginTop: '5px' }}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomField
          name="phone"
          required={true}
          component={TextInput}
          label={translate('Phone Number')}
          fullWidth={true}
          style={{ marginBottom: '5px', marginTop: '5px' }}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomField
          name="has_connected_orgs"
          required={true}
          component={CheckboxInput}
          label={'We sell on behalf of another company, or other companies sell on behalf of us'}
          fullWidth={true}
          elStyle={{ maxWidth: '100%' }}
          style={{ marginBottom: '5px', marginTop: '5px' }}
        />
      </Grid>
      <DependentInput dependsOn="has_connected_orgs" value={true}>
        <InputLabel id="connected-org-relationship">
          Please explain how you work with other companies (including their names) to sell and/or install your projects
        </InputLabel>
        <CustomField
          name="connected_org_relationship"
          required={true}
          component={TextInput}
          label={''}
          fullWidth={true}
          multiline={true}
          style={{ marginBottom: '5px' }}
        />
        <span className="small">Providing this information will speed up the activation of your integration.</span>
      </DependentInput>
      {formError && <p style={{ textAlign: 'center', color: 'red' }}>{formError}</p>}
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          margin: '40px 0px 20px 0px',
        }}
      ></Grid>
      {renderFormButtons(handleSubmit)}
    </Grid>
  )

  const renderFormButtons = (handleSubmit) => (
    <Grid item xs={12}>
      <div className={classes.row}>
        <div className={classes.buttonWrapper}>
          <ProUXButton type="secondary" label={'Cancel'} onClick={props.onClose} disabled={loading} />
        </div>
        <div className={classes.buttonWrapper}>
          <ProUXButton type="primary" label={'Submit'} onClick={handleSubmit} disabled={loading} />
        </div>
      </div>
    </Grid>
  )

  const renderStep3 = () => (
    <>
      <DialogTitle></DialogTitle>
      <DialogContent>
        <div className={classes.formWrapper}>
          <img
            src={
              props.integration.logo_large_contents ||
              `${window.PUBLIC_URL}/images/${LENDER_LOGO_PATHS[props.integration.integration_name]?.large}`
            }
            alt={`${props.integration.display_name} logo`}
            height="75px"
          />
          {props.integration?.banner_content?.intake_form_description && (
            <p>{props.integration?.banner_content?.intake_form_description}</p>
          )}
          <Form
            onSubmit={(vals) => onSubmit(vals, false)}
            initialValues={{
              company_name: org?.name,
              phone: role?.phone,
              email: getIsValidEmail(role?.display) ? role?.display : role?.email,
              name: role?.first_name && role?.family_name ? `${role.first_name} ${role.family_name}` : undefined,
            }}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit}>
                {props.integration.banner_content?.intake_fields
                  ? renderCustomInputs(handleSubmit)
                  : renderDefaultInputs(handleSubmit)}
              </form>
            )}
          />
        </div>
      </DialogContent>
    </>
  )

  const renderStep = () => {
    if (step === 1) return renderStep1()
    else if (step === 2) return renderStep2()
    else return renderStep3()
  }

  return (
    <Dialog open={props.isOpen} onClose={props.onClose}>
      {renderStep()}
    </Dialog>
  )
}
export default PromoDialog

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { FieldTitle, useChoices, useInput, useTranslate } from 'ra-core';
import React, { useCallback } from 'react';
import { OsSelectVersion, useComponentVersion } from 'opensolar-ui';
import InputHelperText from './InputHelperText';
import ResettableTextField from './ResettableTextField';
var sanitizeRestProps = function (_a) {
    var addLabel = _a.addLabel, allowEmpty = _a.allowEmpty, alwaysOn = _a.alwaysOn, emptyValue = _a.emptyValue, basePath = _a.basePath, choices = _a.choices, className = _a.className, component = _a.component, crudGetMatching = _a.crudGetMatching, crudGetOne = _a.crudGetOne, defaultValue = _a.defaultValue, filter = _a.filter, filterToQuery = _a.filterToQuery, formClassName = _a.formClassName, initializeForm = _a.initializeForm, initialValue = _a.initialValue, input = _a.input, isRequired = _a.isRequired, label = _a.label, locale = _a.locale, meta = _a.meta, onChange = _a.onChange, options = _a.options, optionValue = _a.optionValue, optionText = _a.optionText, disableValue = _a.disableValue, perPage = _a.perPage, record = _a.record, reference = _a.reference, resource = _a.resource, setFilter = _a.setFilter, setPagination = _a.setPagination, setSort = _a.setSort, sort = _a.sort, source = _a.source, textAlign = _a.textAlign, translate = _a.translate, translateChoice = _a.translateChoice, validation = _a.validation, rest = __rest(_a, ["addLabel", "allowEmpty", "alwaysOn", "emptyValue", "basePath", "choices", "className", "component", "crudGetMatching", "crudGetOne", "defaultValue", "filter", "filterToQuery", "formClassName", "initializeForm", "initialValue", "input", "isRequired", "label", "locale", "meta", "onChange", "options", "optionValue", "optionText", "disableValue", "perPage", "record", "reference", "resource", "setFilter", "setPagination", "setSort", "sort", "source", "textAlign", "translate", "translateChoice", "validation"]);
    return rest;
};
var useStyles = makeStyles(function (theme) { return ({
    input: function (_a) {
        var version = _a.version;
        return ({
            width: version === OsSelectVersion.V1 ? theme.spacing(32) : undefined,
        });
    },
}); }, { name: 'RaSelectInput' });
var SelectInput = function (props) {
    var allowEmpty = props.allowEmpty, _a = props.choices, choices = _a === void 0 ? [] : _a, classesOverride = props.classes, className = props.className, disableValue = props.disableValue, emptyText = props.emptyText, emptyValue = props.emptyValue, format = props.format, helperText = props.helperText, label = props.label, onBlur = props.onBlur, onChange = props.onChange, onFocus = props.onFocus, options = props.options, optionText = props.optionText, optionValue = props.optionValue, parse = props.parse, resource = props.resource, source = props.source, translateChoice = props.translateChoice, showArchivedChoices = props.showArchivedChoices, validate = props.validate, rest = __rest(props, ["allowEmpty", "choices", "classes", "className", "disableValue", "emptyText", "emptyValue", "format", "helperText", "label", "onBlur", "onChange", "onFocus", "options", "optionText", "optionValue", "parse", "resource", "source", "translateChoice", "showArchivedChoices", "validate"]);
    var translate = useTranslate();
    var version = (useComponentVersion('select') || OsSelectVersion.V1);
    var classes = useStyles(__assign({ version: version }, props));
    var _b = useChoices({
        optionText: optionText,
        optionValue: optionValue,
        translateChoice: translateChoice,
    }), getChoiceText = _b.getChoiceText, getChoiceValue = _b.getChoiceValue;
    var _c = useInput(__assign({ format: format, onBlur: onBlur, onChange: onChange, onFocus: onFocus, parse: parse, resource: resource, source: source, validate: validate }, rest)), id = _c.id, input = _c.input, isRequired = _c.isRequired, _d = _c.meta, error = _d.error, touched = _d.touched, submitError = _d.submitError;
    var filterChoices = function (choice) {
        if (choice.is_archived) {
            if (input.value && getChoiceValue(choice) === input.value) {
                return true;
            }
            return Boolean(showArchivedChoices);
        }
        return true;
    };
    var availableChoices = choices.filter(function (choice) { return filterChoices(choice); });
    var errorMessage = error || submitError;
    var renderEmptyItemOption = useCallback(function () {
        return React.isValidElement(emptyText)
            ? React.cloneElement(emptyText)
            : emptyText === ''
                ? ' ' // em space, forces the display of an empty line of normal height
                : translate(emptyText, { _: emptyText });
    }, [emptyText, translate]);
    var renderMenuItemOption = useCallback(function (choice) { return getChoiceText(choice); }, [getChoiceText]);
    return (_jsxs(ResettableTextField, __assign({ id: id }, input, { select: true, label: label !== '' &&
            label !== false && _jsx(FieldTitle, { label: label, source: source, resource: resource, isRequired: isRequired }), className: "".concat(classes.input, " ").concat(className), clearAlwaysVisible: true, error: !!(touched && errorMessage), helperText: _jsx(InputHelperText, { touched: !!touched, error: errorMessage, helperText: helperText }) }, sanitizeRestProps(rest), options, { children: [allowEmpty ? (_jsx(MenuItem, __assign({ value: emptyValue, "aria-label": translate('ra.action.clear_input_value'), title: translate('ra.action.clear_input_value') }, { children: renderEmptyItemOption() }), "null")) : null, availableChoices.map(function (choice) { return (_jsxs(MenuItem, __assign({ value: getChoiceValue(choice), disabled: get(choice, disableValue) }, { children: [renderMenuItemOption(choice), " ", choice.is_archived && "(".concat(translate('archived'), ")")] }), getChoiceValue(choice))); })] })));
};
SelectInput.propTypes = {
    allowEmpty: PropTypes.bool,
    emptyText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    emptyValue: PropTypes.any,
    choices: PropTypes.arrayOf(PropTypes.object),
    classes: PropTypes.object,
    className: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    options: PropTypes.object,
    optionText: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.element]).isRequired,
    optionValue: PropTypes.string.isRequired,
    disableValue: PropTypes.string,
    resettable: PropTypes.bool,
    resource: PropTypes.string,
    source: PropTypes.string,
    translateChoice: PropTypes.bool,
};
SelectInput.defaultProps = {
    emptyText: '',
    emptyValue: '',
    options: {},
    optionText: 'name',
    optionValue: 'id',
    translateChoice: true,
    disableValue: 'disabled',
};
export default SelectInput;

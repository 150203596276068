import { orgSelectors } from 'ducks/orgs'
import Alert from 'elements/Alert'
import React from 'react'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { ContactType, NORMAL_CONTACT } from 'types/contacts'

const FinanceShareWarning: React.FunctionComponent = () => {
  const formState = useFormState()
  const contacts_data: ContactType[] = formState.values['contacts_data'] || []
  const hasValid = contacts_data.find((c) => {
    return c.type === NORMAL_CONTACT && c.email && c.phone && ((c.first_name && c.family_name) || c.full_name)
  })

  const org = useSelector(orgSelectors.getOrg)
  const hasFinance =
    org?.enable_sunlight ||
    org?.enable_plenti ||
    org?.enable_energy_ease ||
    org?.enable_loanpal ||
    org?.enable_sungage ||
    org?.enable_dividend ||
    org?.enable_commonbond ||
    org?.enable_mosaic ||
    org?.enable_brighte ||
    org?.enable_selina

  // Intentionally un-translated
  return (
    <>
      {hasFinance && !hasValid && (
        <Alert severity="info" styles={{ margin: '10px 0' }}>
          Add a contact with first name, family name, phone number and email address for finance integrations to work in
          shared links.
        </Alert>
      )}
    </>
  )
}

export default FinanceShareWarning

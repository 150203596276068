import { Dialog, makeStyles, StyleRules, Theme, useMediaQuery, withStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import { authSelectors } from 'ducks/auth'
import { closeRenusolDialog } from 'ducks/renusol'
import Alert from 'elements/Alert'
import LoadingDots from 'layout/widgets/LoadingDots'
import { IconButton } from 'opensolar-ui'
import RenusolBOMDialog from 'projectSections/sections/design/systems/tabs/mounting/renusol/RenusolBOMDialog'
import { BomLineItemType } from 'projectSections/sections/design/systems/tabs/mounting/renusol/types'
import { useSaveOtherComponentsToSystem } from 'projectSections/sections/design/systems/tabs/mounting/useSaveOtherComponentsToSystem'
import { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { OtherComponentType } from 'types/otherComponent'
import { getExternalDesignIdentifier, hasRenusolDesign, launchRenusol, loadParts, storeRenusolParts } from './actions'
import { SegenPart, SegenPartsPayload } from './types'

const useStyle = makeStyles((theme) => ({
  dialog: {
    margin: 0,
  },
  wrapper: {
    width: '100%',
    height: 'calc(100vh - 70px)',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 64px)',
    },
  },
  iframeSizer: {
    width: '100%',
    flexGrow: 1,
  },
  iframe: {
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% / 0.8)',
      height: 'calc(100% / 0.8)',
      transform: 'scale(0.8)',
      transformOrigin: '0 0',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% / 0.7)',
      height: 'calc(100% / 0.7)',
      transform: 'scale(0.7)',
      transformOrigin: '0 0',
    },
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% / 0.55)',
      height: 'calc(100% / 0.55)',
      transform: 'scale(0.55)',
      transformOrigin: '0 0',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
}))

const dialogStyles = (theme: Theme): StyleRules => ({
  closeButtonCont: {
    position: 'absolute',
    right: theme.spacing(0.6),
    top: theme.spacing(0.6),
    background: 'white',
    borderRadius: '1000px',
    zIndex: 1,
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
})

interface DialogTitleProps {
  classes: any
  onClose: () => void
}

const DialogTitle = withStyles(dialogStyles)(({ classes, onClose }: DialogTitleProps) => {
  return (
    <>
      {onClose ? (
        <div className={classes.closeButtonCont}>
          <IconButton
            id="TransactionDialogClose"
            aria-label="close"
            className={classes.closeButton}
            color="primary"
            onClick={onClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      ) : null}
    </>
  )
})

interface RenusolDialogProps {
  onClose: () => void
}

const RenusolDialog = ({ onClose }: RenusolDialogProps) => {
  const classes = useStyle()
  const dispatch = useDispatch()
  const translate = useTranslate()

  const orgId = useSelector(authSelectors.getOrgId) as number
  const userId = useSelector(authSelectors.getCurrentUserId) as number

  const [iframeUrl, setIframeUrl] = useState<string | undefined>(undefined)

  // insert project id as prefix
  var project =
    window?.projectForm?.getState && window.projectForm.getState() ? window.projectForm.getState().values : {}
  const identDetected = getExternalDesignIdentifier(project, window.editor?.selectedSystem)
  const [ident, setIdent] = useState<string | undefined>(identDetected)
  const [parts, setParts] = useState<SegenPartsPayload | undefined>(undefined)
  const [loading, setLoading] = useState<any>(false)

  const saveOtherComponentsToSystem = useSaveOtherComponentsToSystem()

  const debugRenusolDialog = (parts: any) => {
    setIframeUrl(undefined)
    setParts(parts)
    setLoading(false)
  }
  window.debugRenusolDialog = debugRenusolDialog

  const triggerLaunchRenusol = () => {
    setLoading(true)
    launchRenusol(userId, orgId, ident as string, translate)
      .then((jumpurl: any) => {
        console.log(jumpurl)
        setIframeUrl(jumpurl)
      })
      .catch((err: any) => {
        console.error(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const triggerLoadParts = (ident: string) => {
    setLoading(true)
    loadParts(orgId, ident)
      .then((data: SegenPartsPayload) => {
        console.log(data)
        setParts(data)
        storeRenusolParts(window.editor?.selectedSystem, data)
      })
      .catch((err: any) => {
        console.error(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const onBomAcceptance = (
    newComponents: OtherComponentType[],
    allComponents: OtherComponentType[],
    manufacturerName: string,
    markAsComplete: () => void
  ) => {
    saveOtherComponentsToSystem(newComponents, allComponents, manufacturerName).finally(() => {
      if (markAsComplete) {
        markAsComplete()
      }
      handleClose()
    })
  }

  useEffect(() => {
    function iframeListener(message: MessageEvent) {
      if (message.data?.type === 'RENUSOL_RETURNED') {
        console.warn('handled message', message.data)
        setIframeUrl(undefined)
        if (ident) triggerLoadParts(ident)
      } else {
        console.warn('unhandled message', message.data)
      }
    }
    window.addEventListener('message', iframeListener)
    return () => window.removeEventListener('message', iframeListener)
  })

  useEffect(() => {
    // Auto-launch
    triggerLaunchRenusol()

    return () => {}
  }, [])

  const handleClose = (justCloseIt: boolean = false) => {
    onClose()

    // If parts are not already set then force them to be {} so we know that a design has been created
    // in Renusol, beacuse having parts populated is how we know a design exists (even if parts === {})
    if (!hasRenusolDesign(window.editor?.selectedSystem)) {
      storeRenusolParts(window.editor?.selectedSystem, {})
    }

    dispatch(closeRenusolDialog())
  }

  function partToBomItem(part: SegenPart): BomLineItemType {
    return {
      mfr_code: part.article,
      segen_code: part.segenProductCode,
      optional: !!part.recommended,
      name: part.description,
      description: part.description,
      suggested_count: part.count,
      ordering: part.ordering || undefined,
    }
  }

  var bomLineItems: BomLineItemType[] = parts?.partlist?.parts.map(partToBomItem) || []

  const isTiny = useMediaQuery('(max-width:600px)')
  const isPortrait = useMediaQuery('(orientation: portrait)')

  const loaded = !iframeUrl && !loading && parts

  return (
    <Dialog open={true} maxWidth="lg" fullWidth scroll="body" className={classes.dialog} style={{ margin: 0 }}>
      <DialogTitle onClose={handleClose}></DialogTitle>
      <div className={classes.wrapper} style={{ overflow: loaded ? 'scroll' : 'hidden' }}>
        {isTiny && (
          <Alert severity="info" styles={{ margin: '0', paddingRight: '45px' }}>
            This integration is not optimised for mobile sized screens,
            {isPortrait ? <> please rotate your device.</> : <> please try on a wider screen size.</>}
          </Alert>
        )}
        {iframeUrl && (
          <div className={classes.iframeSizer}>
            <iframe
              id="myIFrame"
              className={classes.iframe}
              src={iframeUrl}
              height="100%"
              width="100%"
              scrolling="no"
            ></iframe>
          </div>
        )}
        {!iframeUrl && loading && (
          <div className={classes.container}>
            <LoadingDots text="Loading..." />
          </div>
        )}
        {loaded && (
          <RenusolBOMDialog
            onClose={() => {
              handleClose()
            }}
            warnings={[]}
            bomLineItems={bomLineItems}
            manufacturerName="Renusol"
            onBomAcceptance={onBomAcceptance}
          />
        )}
      </div>
    </Dialog>
  )
}

export default RenusolDialog

import { FormLabel, Grid } from '@material-ui/core'
import { TextInput, useTranslate } from 'react-admin'
import { DownloadIconLink } from 'resources/privateFiles/PrivateFiles'
import { InputRow } from '../styles/dialogAndFieldsStyles'
import { FileTagsChipsInput } from './FileTagsChipsInput'

export const EditFileFields = (props) => {
  const translate = useTranslate()

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item sm={11}>
        <FormLabel>{translate('Title')}</FormLabel>
        <TextInput fullWidth source="title" label="" variant="outlined" helperText="optional" record={props.record} />
      </Grid>
      <Grid item sm={1}>
        <DownloadIconLink record={props.record} />
      </Grid>
      <Grid item sm={12}>
        <InputRow>
          <FormLabel>{translate('File Tags')}</FormLabel>
          <FileTagsChipsInput />
        </InputRow>
      </Grid>
    </Grid>
  )
}

import ErrorOutlineOutlined from '@material-ui/icons/ErrorOutlineOutlined'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  errorTooltipContainer: {
    position: 'absolute',
    top: 35,
    paddingLeft: 7,
    paddingRight: 7,
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: theme.alertBg_error,
    zIndex: 100,
    fontSize: 11,
    color: theme.alert_error,
    borderRadius: 5,
    boxShadow: theme.boxShadow.default,
    textOverflow: 'ellipsis',
    width: 'max-content',
    maxWidth: 170,
    left: -10,
    display: 'flex',
    alignItems: 'center',
  },
  errorToolTipPointer: {
    position: 'absolute',
    height: 8,
    width: 8,
    backgroundColor: theme.alertBg_error,
    transform: 'rotate(45deg)',
    top: -4,
    marginLeft: 10,
  },
  errorIcon: {
    color: theme.alertIcon_error,
    width: 15,
    height: 15,
  },
}))

type PropTypes = {
  message: string
}

export const AlertTooltip = ({ message }: PropTypes) => {
  const classes = useStyles()

  return (
    <div className={classes.errorTooltipContainer}>
      <div className={classes.errorToolTipPointer}></div>
      <ErrorOutlineOutlined className={classes.errorIcon} />
      <span style={{ marginLeft: 3 }}>{message}</span>
    </div>
  )
}

import { Fade } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { myEnergySelectors } from 'ducks/myEnergy'
import { orgSelectors } from 'ducks/orgs'
import {
  clearPendingPaymentOptions,
  paymentOptionSelectionSelectors,
  triggerSystemRefresh,
} from 'ducks/paymentOptionSelection'
import SystemProgressBar from 'projectSections/sections/design/SystemProgressBar'
import { useTranslate } from 'ra-core'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { StudioSystemType } from 'types/global'
import { PaymentOptionDataType } from 'types/paymentOptions'
import { RootState } from 'types/state'
import { isPricingLockedForStage, urlToId } from 'util/misc'
import { PAYMENT_OPTION_MESSAGE_TYPE_ENUM } from '../paymentOptionSelection/types'
import { useLogCalculationMismatches } from '../utils'
import GhostCard from './GhostCard'
import PaymentOptionCardsRenderer from './PaymentOptionCardsRenderer'
import ScrollButton from './ScrollButton'
import SystemRowTitle from './SystemRowTitle'
import VisibilityLogger from './VisibilityLogger'

const useStyles = makeOpenSolarStyles((theme) => ({
  borderTop: {
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: theme.greyMid2,
  },
  mainWrapper: {
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  cardsRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '15px',
    right: '0px',
    left: '0px',
    overflowX: 'auto',
    scrollBehavior: 'smooth',
  },
  cardWrapper: {
    display: 'flex',
    marginRight: '15px',
    position: 'relative',
  },
}))

type PropTypes = {
  system: StudioSystemType
  systemIndex: number
  systemsCount: number
}

const SystemRow: React.FC<PropTypes> = (props) => {
  const project = useFormState().values

  const justSoldSystemUuid = useSelector(myEnergySelectors.getSystemUuidForcedMarkedAsSold)

  const getIsOtherSystemSold = useCallback(() => {
    if (project.system_sold || justSoldSystemUuid) {
      const systemSoldId = project.system_sold ? urlToId(project.system_sold) : undefined
      const soldSystemUuid = systemSoldId
        ? project?.systems?.find((sys) => sys.id === systemSoldId)?.uuid
        : justSoldSystemUuid
      if (soldSystemUuid && soldSystemUuid !== props.system?.uuid) return true
    }
    return false
  }, [project.system_sold, justSoldSystemUuid])

  const [sortedPaymentOptions, setSortedPaymentOptions] = useState<PaymentOptionDataType[]>(
    props.system.payment_options
  )
  const [exceptionPaymentOptions, setExceptionPaymentOptions] = useState<PaymentOptionDataType[]>([])
  const [isExpanded, setIsExpanded] = useState<boolean>(!getIsOtherSystemSold())
  const [pmtIdBeingDragged, setPmtIdBeingDragged] = useState<number | undefined>(undefined)
  const [showScrollLeft, setShowScrollLeft] = useState<boolean>(false)
  const [showScrollRight, setShowScrollRight] = useState<boolean>(false)
  const [disableDrag, setDisableDrag] = useState<boolean>(false)

  const translate = useTranslate()
  const cardWrapperRef = useRef(null)

  const availablePaymentOptions = useSelector(paymentOptionSelectionSelectors.getAvailablePaymentOptions)

  const pendingPaymentOptions = useSelector(paymentOptionSelectionSelectors.getPaymentOptionsPendingCalcs)
  const isPendingCalcs = useSelector(paymentOptionSelectionSelectors.getSystemUuidPendingCalcs) === props.system.uuid
  const refreshTrigger = useSelector(paymentOptionSelectionSelectors.getSystemRefreshTrigger)
  const isCalculating = useSelector((state: RootState) => {
    return paymentOptionSelectionSelectors.getIsSystemCalculating(state, props.system.uuid)
  })

  useLogCalculationMismatches(
    isCalculating,
    pendingPaymentOptions,
    props.system.payment_options,
    project.id,
    props.system
  )

  useEffect(() => {
    logAmplitudeEvent('payments_page_system_rendered', {
      project_id: project?.id,
      system_index: props.systemIndex,
      system_count: props.systemsCount,
    })
  }, [])

  // when the user re-orders payment options we want that order to be reflected immediately instead of waiting for calcs
  // but when calcs finish we want to show the payment_options array in the system design
  const orgWorkflows = useSelector(orgSelectors.getWorkflows)
  const pricingLocked = useMemo(
    () => isPricingLockedForStage(project, orgWorkflows) && !props.system.override_price_locking,
    [orgWorkflows, project]
  )
  useEffect(() => {
    if (props.system?.payment_options_override?.length) {
      let sortedArray = props.system.payment_options?.sort((a, b) => {
        let aIndex = props.system.payment_options_override.indexOf(a.id) || 0
        let bIndex = props.system.payment_options_override.indexOf(b.id) || 0
        return aIndex - bIndex
      })
      setSortedPaymentOptions([...sortedArray])
      logAmplitudeEvent('payments_page_cards_rendered_count', {
        project_id: project?.id,
        count: sortedArray?.length || 0,
      })

      // if any phoenix payment option is present then dragging is disabled
      let hasPhoenix = !!sortedArray?.find((pmt) => pmt.integration === 'phoenix')
      if (hasPhoenix && !disableDrag) setDisableDrag(true)
      else if (!hasPhoenix && disableDrag) setDisableDrag(false)

      // if calcs are complete and a payment option override id isn't found in the system.payment_options then it means there was an exception
      // when calculating that payment option and our back-end didn't return the payment option as a result. So we can't use system.payment_options to render
      // a card for this product. So instead, we'll make a separate card just for this error state
      if (!isCalculating) {
        let missingIds: number[] = []
        props.system?.payment_options_override.forEach((pmtId) => {
          let matchingPmt = sortedArray?.find((pmt) => pmt.id === pmtId)
          if (!matchingPmt) missingIds.push(pmtId)
        })
        if (missingIds?.length) {
          let missingPaymentOptions: PaymentOptionDataType[] = []
          missingIds.forEach((pmtId) => {
            let missingPmt = availablePaymentOptions.find((pmt) => pmt.id === pmtId)
            if (missingPmt) {
              let messages = missingPmt.messages || []
              if (!messages?.length) {
                let message = 'This payment option has an error that is preventing calculations from finishing'
                // when pricing was locked but the save has not been commited then we need to go off of project.stage
                if (pricingLocked) {
                  message =
                    'This payment option cannot be added because pricing is locked for this project. You can override the price lock in the Pricing tab of the Design page'
                }
                messages.push({
                  type: PAYMENT_OPTION_MESSAGE_TYPE_ENUM.error,
                  message: translate(message),
                  system_uuid: props.system.uuid,
                  payment_option_id: missingPmt.id,
                  error_prompt_id: null,
                })
              }
              missingPmt.messages = messages
              missingPaymentOptions.push(missingPmt)
            }
          })
          setExceptionPaymentOptions(missingPaymentOptions)
        } else {
          setExceptionPaymentOptions([])
        }
      } else {
        setExceptionPaymentOptions([])
      }
    } else {
      setSortedPaymentOptions([...props.system.payment_options])
    }
  }, [refreshTrigger, props.system.payment_options, props.system.payment_options_override, availablePaymentOptions])

  const form = useForm()
  const classes = useStyles()
  const dispatch = useDispatch()

  // when calcs are complete clear out the pending payment options that show as a loading state
  useEffect(() => {
    if (!isCalculating) {
      dispatch(clearPendingPaymentOptions())
      dispatch(triggerSystemRefresh())
    }
  }, [isCalculating])

  const handlePaymentOptionReorder = useCallback(
    (newIndex: number) => {
      if (pmtIdBeingDragged) {
        let currentIndex: number | undefined = undefined
        const system = window.editor.getSystems()?.find((sys) => sys.uuid === props.system.uuid)
        let newOverrides: number[] = []
        // if payment options have aleady been overridden then just change the array of pmt ids in payment_options_override
        if (system?.payment_options_override?.length) {
          currentIndex = system.payment_options_override.indexOf(pmtIdBeingDragged)
          newOverrides = system.payment_options_override?.filter((pmtId) => pmtId !== pmtIdBeingDragged)
        } else {
          // if payment options have not already been overriden then loop over the current payment options and pull out the id of every
          // payment option except this one. Save that filtered list of ids into payment_options_override
          system?.payment_options?.forEach((pmt, i) => {
            if (pmt.id !== pmtIdBeingDragged) {
              newOverrides.push(pmt.id)
              currentIndex = i
            }
          })
        }
        if (currentIndex !== newIndex) {
          newOverrides.splice(newIndex, 0, pmtIdBeingDragged)
          window.editor.execute(new window.SetValueCommand(system, 'payment_options_override', newOverrides))
          form.mutators.markFieldAsDirty('design')
          form.change('design', 'has unsaved change')
        }
        setPmtIdBeingDragged(undefined)
      }
    },
    [pmtIdBeingDragged]
  )

  // detect if we need to show scroll buttons
  const getRequiresScroll = useCallback(() => {
    if (sortedPaymentOptions?.length) {
      // @ts-ignore
      let cardWrapperWidth = cardWrapperRef.current?.scrollWidth
      let windowWidth = window.innerWidth
      return cardWrapperWidth > windowWidth
    }
    return false
  }, [sortedPaymentOptions?.length])

  const toggleScrollVisibility = useCallback(
    (isVisibile: boolean, isLeft: boolean) => {
      const requiresScroll = getRequiresScroll()
      // if this is just because more payment options were added and scrolling was previously not required, only show scroll right
      if (requiresScroll && !showScrollLeft && !showScrollRight) {
        setShowScrollRight(true)
      } else if (requiresScroll) {
        // oherwise the user did scroll and we need to see which target was just made to be visible
        if (isLeft) {
          if (isVisibile) setShowScrollLeft(false)
          if (requiresScroll) setShowScrollRight(true)
        } else {
          if (isVisibile) setShowScrollRight(false)
          if (requiresScroll) setShowScrollLeft(true)
        }
      } else {
        // scrolling is no longer needed because payment options have been removed
        if (showScrollLeft) setShowScrollLeft(false)
        if (showScrollRight) setShowScrollRight(false)
      }
    },
    [sortedPaymentOptions?.length, showScrollLeft, showScrollRight]
  )

  return (
    <div className={classes.borderTop}>
      <SystemProgressBar isLoading={false} isCalculating={isCalculating} />
      <SystemRowTitle
        system={props.system}
        index={props.systemIndex}
        totalSystemCount={props.systemsCount}
        toggleIsExpanded={() => setIsExpanded(!isExpanded)}
        isExpanded={isExpanded}
      />
      {isExpanded && (
        <div className={classes.mainWrapper}>
          <Fade in={isExpanded} timeout={300}>
            <div className={classes.cardsRow} id={`pmt-option-card-wrapper-${props.systemIndex}`} ref={cardWrapperRef}>
              <VisibilityLogger
                eventName="payments_page_system_visible"
                index={props.systemIndex}
                recordCount={props.systemsCount}
              />
              <VisibilityLogger
                index={props.systemIndex}
                recordCount={props.systemsCount}
                onVisibleChange={(isVisibile) => toggleScrollVisibility(isVisibile, true)}
              />
              <div className={classes.cardWrapper}>
                <GhostCard />
              </div>
              <PaymentOptionCardsRenderer
                isPendingCalcs={isPendingCalcs}
                isCalculating={isCalculating}
                disableDrag={disableDrag}
                systemIndex={props.systemIndex}
                system={props.system}
                pendingPaymentOptions={pendingPaymentOptions}
                setPmtIdBeingDragged={setPmtIdBeingDragged}
                pmtIdBeingDragged={pmtIdBeingDragged}
                sortedPaymentOptions={sortedPaymentOptions}
                exceptionPaymentOptions={exceptionPaymentOptions}
                handlePaymentOptionReorder={handlePaymentOptionReorder}
              />
              <VisibilityLogger
                index={props.systemIndex}
                recordCount={props.systemsCount}
                onVisibleChange={(isVisibile) => toggleScrollVisibility(isVisibile, false)}
              />
            </div>
          </Fade>
          {showScrollLeft && <ScrollButton direction="left" wrapperRef={cardWrapperRef} />}
          {showScrollRight && <ScrollButton direction="right" wrapperRef={cardWrapperRef} />}
        </div>
      )}
    </div>
  )
}

export default SystemRow

import { useCallback, useMemo, useState } from 'react'
import { DialogHelper } from 'util/misc'
import type { HardwareSelectorDialogConfig } from './HardwareSelectorCore'
import HardwareSelectorCore from './HardwareSelectorCore'
import HardwareSelectorDialogV2 from './HardwareSelectorDialogV2'

const useHardwareSelectorV2 = () => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const [config, setConfig] = useState<HardwareSelectorDialogConfig | undefined>(undefined)

  const openDialog = useCallback(({ config }: { config: HardwareSelectorDialogConfig }) => {
    setConfig(config)
    setOpen(true)
    DialogHelper.afterOpen('DialogAssociatedComponents')
  }, [])

  const closeDialog = useCallback(() => {
    DialogHelper.beforeClose()
    setConfig(undefined)
    setOpen(false)
  }, [])

  const Dialog = useMemo(() => {
    return (
      <HardwareSelectorDialogV2 isOpen={isOpen} onClose={closeDialog}>
        {isOpen && config !== undefined && (
          <HardwareSelectorCore onClose={closeDialog} config={config}></HardwareSelectorCore>
        )}
      </HardwareSelectorDialogV2>
    )
  }, [isOpen, config])

  const flowController = useMemo(() => {
    return { openDialog, closeDialog }
  }, [])

  return useMemo(() => {
    return { flowController, Dialog }
  }, [flowController, Dialog])
}

export default useHardwareSelectorV2

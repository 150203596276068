import { authSelectors } from 'ducks/auth'
import lodash from 'lodash'
import { FC, useEffect, useMemo } from 'react'
import { SelectInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { RootState } from 'types/state'

interface PropTypes {
  source: string
  fieldId: string
}

const RECENTLY_VIEWED_CHOICES = [
  { id: true, name: 'Was recently viewed' },
  { id: false, name: 'Was not recently viewed' },
]

const RecentlyViewed: FC<PropTypes> = ({ source }) => {
  const form = useForm()
  const formValues = useFormState().values
  const orgId = useSelector(authSelectors.getOrgId)
  const value = useMemo(() => lodash.get(formValues, `${source}.fields.conditional`), [formValues, source])
  const recentProjects =
    useSelector((state: RootState) => state.recentProjects?.filter((project) => project.org_id === orgId)) || []

  useEffect(() => {
    let newValue = {}
    if (value !== undefined) {
      newValue[value ? 'filter' : 'filter_out'] = recentProjects.map((recentProject) => recentProject.id).join(',')
    }
    form.change(`${source}.value`, newValue)
  }, [value])

  return (
    <SelectInput
      choices={RECENTLY_VIEWED_CHOICES}
      label={false}
      source={`${source}.fields.conditional`}
      variant="outlined"
      margin={'none'}
    />
  )
}

export default RecentlyViewed

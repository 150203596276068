import _ from 'lodash'
import { getRoleFromState, parseIntegrationJson } from 'util/misc'
export enum FieldTypeEnum {
  text = 'text',
  select = 'select',
  dependentSelect = 'dependentSelect', //a select field whose options are dependent upon the value in another field
  number = 'number',
  checkbox = 'checkbox',
  autoComplete = 'autoComplete',
  dependentAutoComplete = 'dependentAutoComplete',
}
export type SelectOption = {
  name: string
  id: string
}
export type DependentSelectOptions = {
  [fieldName: string]: SelectOption[]
}
export type FormFieldType<T extends SelectOption = any> = {
  label: string
  name: string
  source: string
  fieldType: FieldTypeEnum
  required?: boolean
  initialValueSelector?: (selectorRoot: any) => any
  initialValueSelectorRoot?: SelectorRootEnum
  defaultValue?: any //used when there is no other means to get a value (eg the field from the db isn't populated)
  dependsOn?: string
  helpMessage?: string
  validator?: (val: any) => string | undefined
  options?: T[]
  flexBasisPct?: string
  dependentOptions?: DependentSelectOptions
  renderBasedOnField?: string
  renderBasedOnValue?: any
  queryURL?: string
  dependentQueryFieldName?: string //used for depedent auto complete. This is the field name in the form, the input will handle passing it to the queryURL
  helpText?: string
  checkIfShouldRender?: () => boolean
}

export enum SelectorRootEnum {
  state = 'state',
  window = 'window',
}

export const ironRidgeFields: FormFieldType[] = [
  {
    name: 'email',
    source: 'email',
    label: 'IronRidge account email',
    fieldType: FieldTypeEnum.text,
    required: true,
    flexBasisPct: '100%',
    initialValueSelector: (state: any) => {
      return getRoleFromState(state)?.ironridge_email
        ? getRoleFromState(state)?.ironridge_email
        : _.get(state, 'auth.user.email')
    },
    initialValueSelectorRoot: SelectorRootEnum.state,
  },
  {
    name: 'roof_material',
    source: 'roof_material',
    label: 'Roof Material',
    fieldType: FieldTypeEnum.select,
    required: true,
    flexBasisPct: '20%',
    initialValueSelector: (fieldRoot: any) => {
      //@ts-ignore
      let asJSON = _.get(fieldRoot, 'editor.selectedSystem.integration_json')
      return asJSON ? parseIntegrationJson(asJSON)?.ironridge?.project?.detail?.roofMaterial : null
    },
    initialValueSelectorRoot: SelectorRootEnum.window,
    options: [
      {
        name: 'Comp Shingle',
        id: 'cs',
      },
      {
        name: 'Tile - Flat',
        id: 'tile_f',
      },
      {
        name: 'Tile - S',
        id: 'tile_s',
      },
      {
        name: 'Tile - W',
        id: 'tile_w',
      },
      {
        name: 'Metal',
        id: 'metal',
      },
      {
        name: 'Other',
        id: 'other',
      },
    ],
  },
  {
    name: 'roof_attachment',
    source: 'roof_attachment',
    label: 'Roof Attachment',
    fieldType: FieldTypeEnum.dependentSelect,
    required: true,
    flexBasisPct: '40%',
    dependsOn: 'roof_material',
    initialValueSelector: (fieldRoot: any) => {
      //@ts-ignore
      let asJSON = _.get(fieldRoot, 'editor.selectedSystem.integration_json')
      if (!asJSON) return null
      let parsedIntegrationJson = parseIntegrationJson(asJSON)
      let val = asJSON ? parsedIntegrationJson?.ironridge?.project?.detail?.roofAttachment : null
      if (parsedIntegrationJson.ironridge?.project?.detail?.qmTileDeckFlashing) {
        if (val === 'ko_f') val = 'ko_deck'
        if (val === 'ath') val = 'ath_f'
      }

      return val
    },
    initialValueSelectorRoot: SelectorRootEnum.window,
    dependentOptions: {
      cs: [
        {
          name: 'FlashFoot2',
          id: 'ff2',
        },
        {
          name: 'FlashVue',
          id: 'fv',
        },
        {
          name: 'L-Mount (Lag Bolt)',
          id: 'qm_lm_l',
        },
        {
          name: 'L-Mount (Structural Screw)',
          id: 'qm_lm_s',
        },
        {
          name: 'Halo UltraGrip (Rafter attached)',
          id: 'qm_hug_r',
        },
        {
          name: 'Halo UltraGrip (Deck attached)',
          id: 'qm_hug_d',
        },
        {
          name: 'None',
          id: 'none',
        },
      ],
      tile_f: [
        {
          name: 'All Tile Hook',
          id: 'ath',
        },
        {
          name: 'All Tile Hook + Deck Flashing',
          id: 'ath_f',
        },
        {
          name: 'Knockout Tile + Replacement Flashing',
          id: 'ko_f',
        },
        {
          name: 'Knockout Tile + Deck Flashing',
          id: 'ko_deck',
        },
      ],
      tile_s: [
        {
          name: 'All Tile Hook',
          id: 'ath',
        },
        {
          name: 'All Tile Hook + Deck Flashing',
          id: 'ath_f',
        },
        {
          name: 'Knockout Tile + Replacement Flashing',
          id: 'ko_f',
        },
        {
          name: 'Knockout Tile + Deck Flashing',
          id: 'ko_deck',
        },
      ],
      tile_w: [
        {
          name: 'All Tile Hook',
          id: 'ath',
        },
        {
          name: 'All Tile Hook + Deck Flashing',
          id: 'ath_f',
        },
        {
          name: 'Knockout Tile + Replacement Flashing',
          id: 'ko_f',
        },
        {
          name: 'Knockout Tile + Deck Flashing',
          id: 'ko_deck',
        },
      ],
      metal: [
        {
          name: 'L-Foot Only',
          id: 'lfoot',
        },
        {
          name: 'None',
          id: 'none',
        },
      ],
      other: [
        {
          name: 'L-Foot Only',
          id: 'lfoot',
        },
        {
          name: 'None',
          id: 'none',
        },
      ],
    },
  },
  {
    name: 'roof_shape',
    source: 'roof_shape',
    label: 'Roof Shape',
    fieldType: FieldTypeEnum.select,
    required: true,
    flexBasisPct: '20%',
    initialValueSelector: (fieldRoot: any) => {
      //@ts-ignore
      let asJSON = _.get(fieldRoot, 'editor.selectedSystem.integration_json')
      //@ts-ignore
      if (asJSON) {
        return parseIntegrationJson(asJSON)?.ironridge?.project?.detail?.roofShape
      }
    },
    initialValueSelectorRoot: SelectorRootEnum.window,
    options: [
      {
        name: 'Hip',
        id: 'hip',
      },
      {
        name: 'Gable',
        id: 'gable',
      },
    ],
  },
  {
    name: 'attachment_hardware',
    source: 'attachment_hardware',
    label: 'Attachment Hardware',
    fieldType: FieldTypeEnum.select,
    required: true,
    flexBasisPct: '20%',
    defaultValue: 'square',
    initialValueSelector: (fieldRoot: any) => {
      //@ts-ignore
      let asJSON = _.get(fieldRoot, 'editor.selectedSystem.integration_json')
      return asJSON ? parseIntegrationJson(asJSON)?.ironridge?.project?.detail?.attachmentHardware : null
    },
    initialValueSelectorRoot: SelectorRootEnum.window,
    options: [
      {
        name: 'Square Bolt',
        id: 'square',
      },
      {
        name: 'T - Bolt',
        id: 't-bolt',
      },
      {
        name: 'Aire Dock',
        id: 'dock',
      },
    ],
  },
  {
    name: 'asce',
    source: 'asce',
    label: 'ASCE Code',
    fieldType: FieldTypeEnum.select,
    required: true,
    flexBasisPct: '25%',
    defaultValue: '7-10',
    initialValueSelector: (fieldRoot: any) => {
      //@ts-ignore
      let asJSON = _.get(fieldRoot, 'editor.selectedSystem.integration_json')
      let asceWithPeriod = parseIntegrationJson(asJSON)?.ironridge?.project?.asce
      if (asceWithPeriod) {
        return asceWithPeriod.replace('.', '-')
      }
      return null
    },
    initialValueSelectorRoot: SelectorRootEnum.window,
    options: [
      {
        name: '7-10',
        id: '7-10',
      },
      {
        name: '7-16',
        id: '7-16',
      },
    ],
  },
  {
    name: 'discount',
    source: 'discount',
    label: 'Discount (%)',
    fieldType: FieldTypeEnum.text,
    required: false,
    flexBasisPct: '25%',
    initialValueSelector: (fieldRoot: any) => {
      //@ts-ignore
      let asJSON = _.get(fieldRoot, 'editor.selectedSystem.integration_json')
      return asJSON ? parseIntegrationJson(asJSON)?.ironridge?.project?.discount : null
    },
    initialValueSelectorRoot: SelectorRootEnum.window,
    validator: (val) => {
      if (!Number(parseFloat(val)) === val) return 'Must be a number'
      else if (val > 100) return 'Must be below 100'
      else if (val < 0) return 'Cannot be below 0'
      else return undefined
    },
  },
  {
    name: 'building_height',
    source: 'building_height',
    label: 'Roof Height',
    fieldType: FieldTypeEnum.select,
    required: true,
    flexBasisPct: '25%',
    initialValueSelector: (fieldRoot: any) => {
      //@ts-ignore
      const asJSON = _.get(fieldRoot, 'editor.selectedSystem.integration_json')
      const unroundedStr = asJSON ? parseIntegrationJson(asJSON)?.ironridge?.project?.detail?.buildingHeight : null
      if (unroundedStr >= 0) {
        return `${Math.round(parseInt(unroundedStr) / 5) * 5}`
      } else return unroundedStr
    },
    initialValueSelectorRoot: SelectorRootEnum.window,
    options: [
      {
        name: 'Up to 15ft',
        id: '15',
      },
      {
        name: 'Up to 20ft',
        id: '20',
      },
      {
        name: 'Up to 25ft',
        id: '25',
      },
      {
        name: 'Up to 30ft',
        id: '30',
      },
      {
        name: 'Up to 35ft',
        id: '35',
      },
      {
        name: 'Up to 40ft',
        id: '40',
      },
      {
        name: 'Up to 45ft',
        id: '45',
      },
      {
        name: 'Up to 50ft',
        id: '50',
      },
      {
        name: 'Up to 55ft',
        id: '55',
      },
      {
        name: 'Up to 60ft',
        id: '60',
      },
    ],
  },
  {
    name: 'rail_type',
    source: 'rail_type',
    label: 'Rail Type',
    fieldType: FieldTypeEnum.select,
    required: true,
    flexBasisPct: '25%',
    initialValueSelector: (fieldRoot: any) => {
      //@ts-ignore
      let asJSON = _.get(fieldRoot, 'editor.selectedSystem.integration_json')
      return parseIntegrationJson(asJSON)?.ironridge?.project?.detail?.railType || null
    },
    initialValueSelectorRoot: SelectorRootEnum.window,
    options: [
      {
        name: 'XR10',
        id: 'XR10',
      },
      {
        name: 'XR100',
        id: 'XR100',
      },
      {
        name: 'XR1000',
        id: 'XR1000',
      },
      {
        name: 'Aire A1',
        id: 'A1',
        attachment_compatibility: ['dock'],
      },
      {
        name: 'Aire A2',
        id: 'A2',
        attachment_compatibility: ['dock'],
      },
    ],
  },
  {
    name: 'deck_thickness',
    source: 'deck_thickness',
    label: 'Deck Thickness',
    fieldType: FieldTypeEnum.dependentSelect,
    required: false,
    flexBasisPct: '20%',
    dependsOn: 'roof_attachment',
    initialValueSelector: (fieldRoot: any) => {
      //@ts-ignore
      let asJSON = _.get(fieldRoot, 'editor.selectedSystem.integration_json')
      return asJSON ? parseIntegrationJson(asJSON)?.ironridge?.project?.detail?.deckThickness : null
    },
    initialValueSelectorRoot: SelectorRootEnum.window,
    dependentOptions: {
      qm_hug_d: [
        {
          name: '7/16',
          id: '7/16',
        },
        {
          name: '15/32',
          id: '15/32',
        },
        {
          name: '19/32',
          id: '19/32',
        },
      ],
    },
  },
  {
    name: 'modules_exposed',
    source: 'modules_exposed',
    label: 'Exposed Modules in Design',
    renderBasedOnField: 'asce',
    renderBasedOnValue: '7-16',
    fieldType: FieldTypeEnum.checkbox,
    flexBasisPct: '50%',
    initialValueSelector: (fieldRoot: any) => {
      //@ts-ignore
      let asJSON = _.get(fieldRoot, 'editor.selectedSystem.integration_json')
      //@ts-ignore
      if (asJSON) {
        let roof_sections = parseIntegrationJson(asJSON)?.ironridge?.project?.roofSections
        if (roof_sections && roof_sections.length > 0) return roof_sections[0]?.modulesExposed
        else return null
      } else return null
    },
    initialValueSelectorRoot: SelectorRootEnum.window,
  },
  {
    name: 'modules_edge',
    source: 'modules_edge',
    label: 'Edge Modules in Design',
    renderBasedOnField: 'asce',
    renderBasedOnValue: '7-16',
    fieldType: FieldTypeEnum.checkbox,
    flexBasisPct: '50%',
    initialValueSelector: (fieldRoot: any) => {
      //@ts-ignore
      let asJSON = _.get(fieldRoot, 'editor.selectedSystem.integration_json')
      //@ts-ignore
      if (asJSON) {
        let roof_sections = parseIntegrationJson(asJSON)?.ironridge?.project?.roofSections
        if (roof_sections && roof_sections.length > 0) return roof_sections[0]?.modulesEdge
        else return null
      } else return null
    },
    initialValueSelectorRoot: SelectorRootEnum.window,
  },
  {
    name: 'panel_manufacturer',
    source: 'panel_manufacturer',
    label: 'Panel Manufacturer',
    fieldType: FieldTypeEnum.autoComplete,
    required: true,
    flexBasisPct: '50%',
    renderBasedOnField: 'module_match',
    renderBasedOnValue: false,
    initialValueSelector: (fieldRoot: any) => {
      //@ts-ignore
      let asJSON = _.get(fieldRoot, 'editor.selectedSystem.integration_json')
      return parseIntegrationJson(asJSON)?.ironridge?.project?.manufacturerId || null
    },
    initialValueSelectorRoot: SelectorRootEnum.window,
    queryURL: 'ironridge/manufacturers/',
  },
  {
    name: 'panel_model_number',
    source: 'panel_model_number',
    label: 'Panel Model Number',
    fieldType: FieldTypeEnum.dependentAutoComplete,
    required: true,
    flexBasisPct: '50%',
    renderBasedOnField: 'module_match',
    renderBasedOnValue: false,
    initialValueSelector: (fieldRoot: any) => {
      //@ts-ignore
      let asJSON = _.get(fieldRoot, 'editor.selectedSystem.integration_json')
      return parseIntegrationJson(asJSON)?.ironridge?.project?.panelId || null
    },
    initialValueSelectorRoot: SelectorRootEnum.window,
    queryURL: 'ironridge/panels/',
    dependentQueryFieldName: 'panel_manufacturer',
  },
]

export const ironRidgeAdvancedFields: FormFieldType[] = [
  {
    name: 'risk_category',
    source: 'risk_category',
    label: 'Risk Category',
    fieldType: FieldTypeEnum.dependentSelect,
    required: true,
    flexBasisPct: '25%',
    dependsOn: 'asce',
    initialValueSelector: (fieldRoot: any) => {
      //@ts-ignore
      let asJSON = _.get(fieldRoot, 'editor.selectedSystem.integration_json')
      return asJSON ? parseIntegrationJson(asJSON)?.ironridge?.project?.riskCategory : 'II'
    },
    initialValueSelectorRoot: SelectorRootEnum.window,
    dependentOptions: {
      '7-16': [
        {
          name: 'II',
          id: 'II',
        },
        {
          name: 'III',
          id: 'III',
        },
      ],
      '7-10': [
        {
          name: 'I',
          id: 'I',
        },
        {
          name: 'II',
          id: 'II',
        },
        {
          name: 'III',
          id: 'III',
        },
      ],
    },
  },
  {
    name: 'panel_finish',
    source: 'panel_finish',
    label: 'Racking Finish',
    fieldType: FieldTypeEnum.select,
    required: true,
    flexBasisPct: '25%',
    dependsOn: 'panel_finish',
    initialValueSelector: (fieldRoot: any) => {
      //@ts-ignore
      let asJSON = _.get(fieldRoot, 'editor.selectedSystem.integration_json')
      return asJSON ? parseIntegrationJson(asJSON)?.ironridge?.project?.panelFinish : 'clear'
    },
    initialValueSelectorRoot: SelectorRootEnum.window,
    options: [
      {
        name: 'Clear',
        id: 'clear',
      },
      {
        name: 'Black',
        id: 'black',
      },
    ],
  },
  {
    name: 'camo',
    source: 'camo',
    label: 'Use Camo',
    fieldType: FieldTypeEnum.checkbox,
    flexBasisPct: '16%',
    initialValueSelector: (fieldRoot: any) => {
      //@ts-ignore
      let asJSON = _.get(fieldRoot, 'editor.selectedSystem.integration_json')
      return asJSON ? parseIntegrationJson(asJSON)?.ironridge?.project?.camo : false
    },
    initialValueSelectorRoot: SelectorRootEnum.window,
  },
  {
    name: 'rail_length_14',
    source: 'rail_length_14',
    label: 'Rail Length 14',
    fieldType: FieldTypeEnum.checkbox,
    flexBasisPct: '16%',
    initialValueSelector: (fieldRoot: any) => {
      //@ts-ignore
      let asJSON = _.get(fieldRoot, 'editor.selectedSystem.integration_json')
      let parsed = parseIntegrationJson(asJSON)
      if (parsed?.ironridge?.project?.detail?.railLength14 === '0') return false
      else if (
        parsed?.ironridge?.project?.detail?.availableLengths &&
        !parsed?.ironridge?.project?.detail?.availableLengths.includes(168)
      )
        return false
      return true
    },
    initialValueSelectorRoot: SelectorRootEnum.window,
  },
  {
    name: 'rail_length_17',
    source: 'rail_length_17',
    label: 'Rail Length 17',
    fieldType: FieldTypeEnum.checkbox,
    flexBasisPct: '16%',
    initialValueSelector: (fieldRoot: any) => {
      //@ts-ignore
      let asJSON = _.get(fieldRoot, 'editor.selectedSystem.integration_json')
      let parsed = parseIntegrationJson(asJSON)
      if (parsed?.ironridge?.project?.detail?.railLength17 === '0') return false
      else if (
        parsed?.ironridge?.project?.detail?.availableLengths &&
        !parsed?.ironridge?.project?.detail?.availableLengths.includes(204)
      )
        return false
      return true
    },
    initialValueSelectorRoot: SelectorRootEnum.window,
  },
]

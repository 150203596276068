export const SET_STUDIO_PROMPT_DIALOG = 'SET_STUDIO_PROMPT_DIALOG'

export const setStudioSavePrompt = value => ({
  type: SET_STUDIO_PROMPT_DIALOG,
  payload: value,
})

const defaultState = {
  show_prompt: false,
  prompt_heading: '',
  prompt_message: '',
}

export default function reducer(previousState = defaultState, { type, payload }) {
  if (type === SET_STUDIO_PROMPT_DIALOG) {
    if (Boolean(payload)) {
      return payload
    } else {
      return defaultState
    }
  }
  return previousState
}

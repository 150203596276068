import { MenuItem } from '@material-ui/core'
import { markFieldActive, markFieldInactive } from 'Studio/Utils'
import { CustomNumberField } from 'elements/field/CustomNumberField'
import CustomSelectField from 'elements/field/CustomSelectField'
import { calculateEmptyAsZero } from 'elements/field/transforms'
import _ from 'lodash'
import { useTranslate } from 'ra-core'
import React, { FC, useEffect, useState } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { StudioSystemType } from 'types/studio/items'
import { SwedenGreenIncentiveDataType } from 'types/systems'
import { formatCurrencyWithSymbol } from 'util/misc'

type PropTypes = {
  currencySymbol: string
  pricingIsLocked: boolean
  allowEdit: boolean
  system: StudioSystemType
  handleObjectChanged: Function
}

interface SwedishIncentiveFields {
  label: string
  name: keyof SwedenGreenIncentiveDataType
  value: number
}

const useStyles = makeOpenSolarStyles((theme) => ({
  allocationRegular: {
    color: theme.standardFontColor,
    fontSize: '12px',
  },
  allocationSuccess: {
    color: theme.alert_success,
    fontSize: '12px',
  },
  allocationError: {
    color: theme.alert_error,
    fontSize: '12px',
  },
}))

const SwedenGreenIncentiveField: FC<PropTypes> = ({
  currencySymbol,
  pricingIsLocked,
  allowEdit,
  system,
  handleObjectChanged,
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const [isDisabled, setIsDisabled] = useState<boolean>(false)

  useEffect(() => {
    if (system?.pricing && system.pricing.base_price > 0 && system?.pricing?.incentive_to_customer?.incentives) {
      const hasSwedishIncentive = system?.pricing?.incentive_to_customer?.incentives.find(
        (incentive) => incentive.incentive_type === 9
      )
      setIsDisabled(!hasSwedishIncentive)
    }
  }, [system?.pricing, system?.pricing?.incentive_to_customer?.incentives])

  const totalPrice = system?.pricing?.system_price_including_tax || 0

  const swedenGreenDeductionIncentive: SwedenGreenIncentiveDataType | null = system?.sweden_green_deduction_incentive
  const { battery_price = 0, ev_charger_price = 0, solar_pv_price = 0 } = swedenGreenDeductionIncentive

  const allocationRemaining: number = totalPrice - battery_price - ev_charger_price - solar_pv_price

  const debounceCalc = _.throttle((fieldName: string, value: number) => {
    window.editor.execute(new window.SetValueCommand(swedenGreenDeductionIncentive, fieldName, value))
    handleObjectChanged(system.uuid)
  }, 500)

  const handleFieldChange = (fieldName: string, value: number) => {
    debounceCalc(fieldName, value)
  }

  const getMaxVal = (price: number) => {
    if (allocationRemaining < 0 && allocationRemaining + price > 0) {
      return allocationRemaining + price
    } else {
      return totalPrice
    }
  }

  const swedishIncentiveFields: SwedishIncentiveFields[] = [
    {
      label: 'Kostnad för solcellspaneler',
      name: 'solar_pv_price',
      value: solar_pv_price,
    },
    {
      label: 'Kostnad för batteri',
      name: 'battery_price',
      value: battery_price,
    },
    {
      label: 'Kostnad för elbilsladdare',
      name: 'ev_charger_price',
      value: ev_charger_price,
    },
  ]

  return (
    <div style={{ width: '100%', margin: '6px 0 10px', position: 'relative' }}>
      <div>
        <h2>{'Grön Teknik'}</h2>
        <p>
          {translate('Total System Price')}:{' '}
          <span style={{ whiteSpace: 'nowrap' }}>{formatCurrencyWithSymbol(totalPrice, currencySymbol)}</span>
        </p>
        <p
          className={
            allocationRemaining > 0
              ? classes.allocationRegular
              : allocationRemaining < 0
              ? classes.allocationError
              : classes.allocationSuccess
          }
        >
          Tilldelning kvar ={' '}
          <span style={{ whiteSpace: 'nowrap' }}>{formatCurrencyWithSymbol(allocationRemaining, currencySymbol)}</span>
        </p>
      </div>
      {swedishIncentiveFields.map((field) => {
        return (
          <CustomNumberField
            label={translate(field.label)}
            key={field.name}
            name={field.name}
            value={field.value}
            onChange={(value) => {
              handleFieldChange(field.name, value)
            }}
            endAdornment={currencySymbol}
            disabled={pricingIsLocked || !allowEdit || isDisabled}
            muiStyleLabel
            fieldStyles={{ width: '100%', marginLeft: 0 }}
            transformFunc={calculateEmptyAsZero}
            maxDecimalPlaces={2}
            minValue={0}
            maxValue={getMaxVal(field.value)}
          />
        )
      })}
      <CustomSelectField
        label={translate('Number of Household Members')}
        value={system?.sweden_green_deduction_incentive?.num_of_household_members || 1}
        disabled={pricingIsLocked || !allowEdit}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const value = parseInt(event.target.value)
          handleFieldChange('num_of_household_members', value)
        }}
        labelStyle={{ marginLeft: '-14px' }}
        style={{ width: 160 }}
        variant="standard"
        onFocus={() => {
          markFieldActive('num_of_household_members', system)
        }}
        onBlur={markFieldInactive}
      >
        <MenuItem key={1} value={1}>
          1
        </MenuItem>
        <MenuItem key={2} value={2}>
          2 +
        </MenuItem>
      </CustomSelectField>
    </div>
  )
}
export default SwedenGreenIncentiveField

import { useEffect, useState } from 'react'
import { useFeatureFlag } from 'util/split'

/**
 * Takes a URL template that may or may not have certain OpenSolar-specific parameter placeholders and returns a url with the value of those placeholders if found
 * @param url the base url that will have any dynamic parameters injected into it
 * @param orgId the id of the currently logged in org
 * @param orgName the name of the currently logged in org
 * @param roleId the id of the currently logged in role
 * @param country_iso2 the county_iso2 code for the currently logged in country
 * @returns
 */
export const addOpenSolarParams = (
  url: string,
  orgId: number | undefined,
  orgName: string | undefined,
  roleId: number | undefined,
  country_iso2: string | undefined
): string => {
  if (!url) return url
  const paramMap = {
    '{{orgId}}': orgId,
    '{{orgName}}': orgName,
    '{{roleId}}': roleId,
    '{{countryIso2}}': country_iso2,
  }

  Object.keys(paramMap).forEach((paramKey) => {
    if (url.includes(paramKey) && paramMap[paramKey])
      url = url.replace(paramKey, encodeURIComponent(paramMap[paramKey]))
  })
  return url
}

export const useGetOauthIntegrationIsVisible = (integrationName: string) => {
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const featureFlagExistsAndIsEnabled = useFeatureFlag('lightreach_integration', 'on')

  // we currently only have lightreach using oauth on the new structure. As we add more we'll have to think
  // about whether we want feature flags to be use for them or if we just want them to be visibile
  useEffect(() => {
    console.log('integrationName', integrationName)
    if (integrationName === 'lightreach') {
      setIsVisible(Boolean(featureFlagExistsAndIsEnabled))
    } else {
      setIsVisible(false)
    }
  }, [integrationName, featureFlagExistsAndIsEnabled])

  return isVisible
}

// @ts-nocheck
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined'
import { permissionsSelectors } from 'ducks/permissions'
import React from 'react'
import { BooleanInput, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'

import ProjectAssignmentFieldDataWrapper from './ProjectAssignmentFieldDataWrapper'

const useStyles = makeStyles((theme) => ({
  content: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  iconTitleWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  headingTitle: {
    margin: '0 10px',
  },
  accordionDetails: {
    [theme.breakpoints.between('sm', 'sm')]: {
      padding: '8px 10px 16px',
    },
  },
  disabled: {
    pointerEvents: 'none',
    cursor: 'not-allowed',
  },
}))
const useNotificationSwitchStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: -10,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  button: {
    textTransform: 'none',
    padding: 5,
    marginLeft: 5,
    fontSize: 10,
  },
  icon: {
    width: 18,
    height: 18,
    lineHeight: '18px',
    verticalAlign: 'middle',
  },
  notificationStatus: {
    fontSize: 11,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 180,
    cursor: 'auto',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: 10,
      marginRight: 'auto',
      width: 'unset',
    },
  },
}))
const EmailNotificationSwitch = () => {
  const classes = useNotificationSwitchStyles()

  return (
    <div className={classes.wrapper}>
      <BooleanInput source="allow_email_notifications" label="Allow Email Notifications for this Project" />
    </div>
  )
}

const ProjectAssignmentAccordion: React.FunctionComponent = () => {
  const { allowView, allowCreate, allowEdit, allowDelete } = useSelector(
    permissionsSelectors.getProjectPermissionByKey('manage_assigned_users')
  )
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <Accordion key="assignment" expanded={true}>
      <AccordionSummary
        aria-controls="expansion-content-assignment"
        id="expansion-header-assignment"
        classes={{ content: classes.content }}
      >
        <div>
          <div className={classes.headerWrapper}>
            <div className={classes.iconTitleWrapper}>
              <ReceiptOutlinedIcon />
              <span className={classes.headingTitle}>{translate('Assignment')}</span>
            </div>
          </div>
          <EmailNotificationSwitch />
        </div>
      </AccordionSummary>
      {allowView && (
        <AccordionDetails classes={{ root: allowEdit ? classes.accordionDetails : classes.disabled }}>
          <ProjectAssignmentFieldDataWrapper allowDelete={allowDelete} />
        </AccordionDetails>
      )}
    </Accordion>
  )
}

export default ProjectAssignmentAccordion

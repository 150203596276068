import _ from 'lodash'
import getHardwareDetailFromSystem, {
  HardwareDetailType,
} from 'projectSections/sections/design/systems/tabs/summary/hardware/getHardwareDetailFromSystem'
import { useCallback, useRef, useState } from 'react'
import { useStudioSignalsLazy } from 'Studio/signals/useStudioSignalsLazy'

const defaultHardwareDetails = []

const useHardwareDetailsDynamic = (): HardwareDetailType[] => {
  const [hardwareDetails, SetHardwareDetails] = useState<HardwareDetailType[]>(() =>
    window.editor?.selectedSystem?.uuid
      ? getHardwareDetailFromSystem(window.editor.objectByUuid(window.editor.selectedSystem.uuid))
      : defaultHardwareDetails
  )
  const hardwareDetailsRef = useRef<HardwareDetailType[]>()
  hardwareDetailsRef.current = hardwareDetails

  const updateBomDetails = useCallback((systemUuid) => {
    if (systemUuid) {
      const system = systemUuid && window.editor.objectByUuid(systemUuid)
      if (!!system) {
        const newHardwareDetails = getHardwareDetailFromSystem(system)
        if (!_.isEqual(hardwareDetailsRef.current, newHardwareDetails)) {
          SetHardwareDetails(newHardwareDetails)
        }
      }
    }
  }, [])

  const onFreshState = useCallback((object) => {
    if (!object) {
      return
    }
    if (
      object.type !== 'OsSystem' &&
      object.type !== 'OsInverter' &&
      object.type !== 'OsBattery' &&
      object.type !== 'OsOther' &&
      object.type !== 'OsModule' &&
      object.type !== 'OsModuleGrid'
    ) {
      return
    }
    updateBomDetails(window.editor?.selectedSystem?.uuid)
  }, [])

  useStudioSignalsLazy(
    onFreshState,
    ['sceneLoaded', 'objectChanged', 'objectAdded', 'objectRemoved', 'objectSelected', 'systemSelected'],
    undefined,
    { debounce: 1 }
  )
  return hardwareDetails
}

export default useHardwareDetailsDynamic

import React, { useCallback } from 'react'
import { useTranslate } from 'react-admin'

/*
 key: 
 'leading part1 <1>part one<1> leading part2 <2>part two<2> leading part3 <3>part three<3> ending'
 parsed parts:
 ['leading part1','part one','leading part2', 'part two', 'leading part3', 'part three', 'ending']
*/

const isMatchPlaceHolderPattern = (value: string, index: number, placeholder: string) => {
  return value.substring(index, index + placeholder.length) === placeholder
}

const getTranslateParts = (value: any, patterns: string[]) => {
  const value_str = value + ''
  const parts: string[] = []
  let currentValuePart = ''
  let currentLetterIndex = 0
  while (currentLetterIndex <= value_str.length) {
    const patternIndex = Math.floor(parts.length / 2)
    if (!patterns[patternIndex]) {
      //take rest of substring
      parts.push(value_str.substring(currentLetterIndex))
      //STOP loop
      return parts
    } else if (isMatchPlaceHolderPattern(value_str, currentLetterIndex, patterns[patternIndex])) {
      parts.push(currentValuePart)
      currentValuePart = ''
      currentLetterIndex += patterns[patternIndex].length
    } else {
      currentValuePart += value_str[currentLetterIndex]
      currentLetterIndex++
    }
  }
  return parts
}

const useTranslateParse = () => {
  const translate = useTranslate()
  const translateParse = useCallback((key: string, placeholders: any, terms?: any) => {
    const translatedValue = translate(key, terms)
    const sortedPlaceholders = Object.keys(placeholders)
      .map((placeholder) => `<${placeholder}>`)
      .sort((a, b) => {
        return translatedValue.indexOf(a) - translatedValue.indexOf(b)
      })
    const parts = getTranslateParts(translatedValue, sortedPlaceholders)
    const expectedPartsLength = sortedPlaceholders.length * 2 + 1
    if (parts.length === expectedPartsLength) {
      return (
        <>
          {parts.map((part: any, index: number) => {
            if (index % 2 === 0) {
              return <>{part}</>
            } else {
              const placeholderKey = sortedPlaceholders[Math.floor(index / 2)].replace('<', '').replace('>', '')
              const placeholder = placeholders[placeholderKey]
              if (typeof placeholder === 'function') {
                // 'this' could be a problem
                return placeholder.call(null, part)
              } else if (React.isValidElement(placeholder)) {
                return React.cloneElement(placeholder)
              } else {
                return <>{part}</>
              }
            }
          })}
        </>
      )
    } else {
      return key
    }
  }, [])
  return translateParse
}

export default useTranslateParse

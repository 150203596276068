import React from 'react'
import type { ReplaceItemNode } from './types'

const PlaceholderNode = () => {
  return <div>loading</div>
}

const createPlaceholderNode = (): ReplaceItemNode => ({
  key: 'placeholder',
  title: '',
  Content: <PlaceholderNode />,
})

export type CreatePlaceholderNodeType = () => ReplaceItemNode

export default createPlaceholderNode

import { celsiusToFahrenheit, fahrenheitToCelsius } from './misc'

export const formatTemperatureValue = (v: any, isFahrenheit: boolean) => {
  if (v === null || v === '') {
    return null
  } else if (isNaN(v)) {
    return v
  } else {
    const value = isFahrenheit ? fahrenheitToCelsius(v) : v
    return Math.round(value * 100) / 100
  }
}

export const parseTemperatureValue = (v: any, isFahrenheit: boolean) => {
  if (isNaN(v) || typeof v !== 'number') {
    return v
  } else {
    const value = isFahrenheit ? celsiusToFahrenheit(v) : v
    // only show 1 decimal place
    return Math.round(value * 10) / 10
  }
}

import { Grid } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { getFiltersSchema } from 'elements/hardwareSelector/Filters'
import ClearFilterButton from 'elements/hardwareSelector/filters/ClearFilterButton'
import ComponentFilter from 'elements/hardwareSelector/filters/ComponentFilter'
import React, { memo, useCallback, useMemo } from 'react'
import type { ComponentTypes, FilterType } from 'types/selectComponent'

type FilterValuesType = { [key: string]: unknown }

// Hide distributor filter
const HIDDEN_FILTER_KEYS = ['require_distributor']

const ShopResultsFilter = ({
  trackSource,
  filterValues,
  handleUpdate,
  setFilters,
  componentType,
}: {
  trackSource: string
  filterValues: FilterValuesType
  handleUpdate(filter: FilterValuesType): void
  setFilters(newFilterValues: { [key: string]: unknown }, displayedFilters?: { [key: string]: boolean }): void
  componentType?: ComponentTypes
}) => {
  const filterSchema = useMemo(() => getFiltersSchema(componentType), [componentType]).filter(
    (filter: FilterType) => !HIDDEN_FILTER_KEYS.includes(filter.key)
  )
  const handleClear = useCallback(() => {
    logAmplitudeEvent('hardware_select_filters', {
      action: 'clicked',
      context: 'clear_filter_button',
      source: trackSource,
    })
    const newFilterValues = {}
    for (const key in filterValues) {
      if (filterSchema.every((schema) => schema.key !== key)) {
        newFilterValues[key] = filterValues[key]
      }
    }
    setFilters(newFilterValues)
  }, [setFilters, trackSource, filterSchema, filterValues])
  return (
    <Grid container wrap="nowrap" justify="space-between">
      <Grid container item xs={'auto'} wrap="wrap">
        <ComponentFilter
          trackSource={trackSource}
          filtersSchema={filterSchema}
          filterSettings={filterValues}
          setFilters={handleUpdate}
          sharedWith={[]}
          projectOwnerId={undefined}
        />
      </Grid>
      <Grid item xs={'auto'}>
        {/* Ideally to hide clear filter if filterSchema is empty
        but before that owner_org filter needs to be refactored  */}
        <ClearFilterButton handleClear={handleClear} />
      </Grid>
    </Grid>
  )
}

export default memo(ShopResultsFilter)

import { Tooltip, makeStyles } from '@material-ui/core'
import { Add, WarningOutlined } from '@material-ui/icons'
import PencilIcon from '@material-ui/icons/Edit'
import { MonthSummary } from 'elements/scheduleDialog/summary/DetailTable'
import { ScheduleAlert } from 'elements/scheduleDialog/summary/ScheduleSummary'
import validateSchedule from 'elements/scheduleDialog/summary/validateSchedule'
import { DateRange, ScheduleType } from 'elements/scheduleDialog/types'
import { defaultSchedule, groupSchedules } from 'elements/scheduleDialog/utils'
import { IconButton } from 'opensolar-ui'
import React, { FC, useMemo } from 'react'
import { Button, useTranslate } from 'react-admin'
import { Theme } from 'types/themes'
import { useConflictedSlots } from '../../elements/scheduleDialog/hooks'

type PropsType = {
  itemList: any[]
  itemIndex: number
  value?: ScheduleType
  onEdit?: () => void
  shortDisplay?: boolean
  disabled?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  alertIcon: {
    color: theme.alertIcon_warning,
    marginRight: 10,
  },
  iconButton: {
    color: 'rgb(77, 77, 77)',
    '&:hover': {
      background: 'rgba(77, 77, 77,0.2)',
    },
    marginLeft: 10,
  },
  emptyMargin: {
    margin: 0,
  },
  columnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
  },
  timeWrapper: {
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 10,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
  itemWrapper: {
    width: '30%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    margin: '5px 0',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  shortRow: {
    display: 'flex',
  },
}))

const Schedule: FC<PropsType> = ({ itemList, itemIndex, onEdit, disabled, shortDisplay = false }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const allSchedules = itemList?.map((item: any) => item.schedule) || []
  const value: ScheduleType = allSchedules[itemIndex] ? allSchedules[itemIndex] : defaultSchedule()
  const groupedSchedules = useMemo(() => groupSchedules(value?.applicable_days_and_hours?.data), [
    value?.applicable_days_and_hours?.data,
  ])
  const schedulesLength = groupedSchedules && Object.keys(groupedSchedules).length
  const noDateRanges = !!value?.date_ranges?.filter((x: DateRange) => x?.start_date && x?.end_date).length
  const unavailableSlots = useConflictedSlots(value, allSchedules, itemIndex)
  const scheduleErrors = useMemo(() => {
    return validateSchedule(value, unavailableSlots)
  }, [value, unavailableSlots])

  return (
    <>
      {!!!schedulesLength && !noDateRanges ? (
        <>
          {shortDisplay ? (
            <></>
          ) : (
            <Button
              disabled={disabled}
              label="Set Time-of-Use Schedule"
              variant="outlined"
              startIcon={<Add />}
              hideLabelAt={null}
              onClick={() => {
                if (onEdit) onEdit()
              }}
              className={classes.emptyMargin}
            />
          )}
        </>
      ) : (
        <div className={classes.row}>
          {!!scheduleErrors.length && !shortDisplay && (
            <Tooltip title={<ScheduleAlert errors={scheduleErrors} />}>
              <WarningOutlined className={classes.alertIcon} />
            </Tooltip>
          )}
          <div className={classes.row}>
            {!!schedulesLength ? (
              <div className={`${classes.columnWrapper} columnWrapper`}>
                {Object.keys(groupedSchedules).map((schedule, i) => {
                  return (
                    <div className={shortDisplay ? classes.shortRow : classes.row}>
                      <div className={`${classes.timeWrapper} timeWrapper`}>
                        {groupedSchedules[schedule].map((timeRange) => (
                          <div>{timeRange === 'All Hours' ? translate('All Hours') : timeRange}</div>
                        ))}
                      </div>
                      <div>{schedule}</div>
                    </div>
                  )
                })}
              </div>
            ) : (
              <>
                <div className={classes.itemWrapper}>-</div>
                <div className={classes.itemWrapper}>-</div>
              </>
            )}
            <div>
              <MonthSummary schedule={value} shortRow={shortDisplay} />
            </div>
            {!shortDisplay && (
              <IconButton size="small" classes={{ root: classes.iconButton }} onClick={onEdit} disabled={disabled}>
                <PencilIcon />
              </IconButton>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default Schedule

import { Step, StepButton, Stepper, makeStyles } from '@material-ui/core'
import { ChevronRight } from '@material-ui/icons'
import React from 'react'
import { useTranslate } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type PropTypes = {
  activeStep: number
  completedSteps: {}
}

const useStyles = makeOpenSolarStyles((theme) => ({
  title: {
    marginRight: '10px',
  },
}))

const useStepperStyles = makeStyles((theme) => ({
  root: {
    padding: '.5rem 0 1rem 0',
    borderBottom: '1px #DADBE9 solid',
    '& .MuiButtonBase-root': {
      cursor: 'default',
    },
    '& .MuiStepIcon-root.MuiStepIcon-completed': {
      color: '#019939',
    },
    '& .MuiStepIcon-root.MuiStepIcon-active': {
      color: '#FFDD52',
    },
    '& .MuiStepIcon-root.MuiStepIcon-active .MuiStepIcon-text': {
      fill: '#0A090B',
    },
    '& .MuiStep-horizontal': {
      padding: '0',
    },
    '& .MuiStepConnector-root': {
      display: 'none',
    },
    '& .MuiStepLabel-label': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiStepLabel-label .MuiSvgIcon-root': {
      fontSize: '20px',
      margin: '0 10px',
    },
  },
}))

const steps = [`About Your Business`, 'Terms & Conditions', 'Owner Info', 'Bank Info']

const SetupStepper: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const classes = useStyles()
  const stepperClasses = useStepperStyles()

  return (
    <Stepper activeStep={props.activeStep} classes={stepperClasses}>
      {steps.map((label, index) => (
        <Step key={label} completed={props.completedSteps[index]}>
          <StepButton color="inherit">
            {translate(label)}
            {index !== steps.length - 1 && <ChevronRight />}
          </StepButton>
        </Step>
      ))}
    </Stepper>
  )
}
export default SetupStepper

import { CalculatedPaymentOptionMessageType } from 'projectSections/sections/payments/paymentOptionSelection/types'
import { FinanceCtaType } from 'resources/financeCTAs/constants'
import { CostingDataType } from './costings'
import { CustomerActionType } from './customerActions'
import { FeaturedFigureType } from './featuredFigures'
import { IncentiveDataType } from './incentives'
import { BreakdownDataType } from './paymentOptionBreakdowns'
import { PricingDataType } from './pricing'
import { PricingSchemeFormulaEnum } from './pricingSchemes'
import { QuotationLineType } from './quotationLines'

export type PaymentOptionType = {
  auto_apply_enabled: boolean
  auto_apply_only_specified_states: string
  auto_apply_only_specified_zips: string
  auto_discount: boolean
  configuration_json: string
  contract_template: string
  custom_data?: string
  description: string
  id: number
  integration_external_reference: string
  is_archived: boolean
  modified_date: string
  org: string
  payment_type: PaymentTypeEnum
  priority: number
  title: string
  url: string
  use_highest_standard_system_price: boolean
  utility_tariff_override: string
  category_id: string
  banner_image_url?: string
  org_id: number
}

export enum PaymentMethodEnum {
  none = 'none',
  offline = 'offline',
  credit_card_stripe = 'credit_card_stripe',
  statement = 'statement',
  sunlight_loan_prequal = 'sunlight_loan_prequal',
  sunlight_loan_application = 'sunlight_loan_application',
  docusign = 'docusign',
  phoenix_application = 'phoenix_application',
}

export enum PaymentFrequencyTypeEnum {
  weekly = 'weekly',
  fortnightly = 'fortnightly',
  bimonthly = 'bimonthly',
  monthly = 'monthly',
  quarterly = 'quarterly',
  yearly = 'yearly',
}

export enum PaymentTypeEnum {
  loan = 'loan',
  cash = 'cash',
  lease = 'lease',
  regular_payment = 'regular_payment',
  loan_advanced = 'loan_advanced',
  ppa = 'ppa',
}

export enum US_FINANCE_INTEGRATION_ENUM {
  sunlight = 'sunlight',
  sungage = 'sungage',
  mosaic = 'mosaic',
  loanpal = 'loanpal',
  dividend = 'dividend',
}

export enum AUFinanceIntegrationEnum {
  brighte = 'brighte',
  energy_ease = 'energy_ease',
  plenti = 'plenti',
  handypay = 'handypay',
}

export enum EUFinanceIntegrationEnum {
  cloover = 'cloover',
  phoenix = 'phoenix',
  energy_ease = 'energy_ease',
}

export type ExpectedMilestonePaymentType = {
  percentage: number
  title: string
  description: string | null
  payment_amount: number
  currency: string
  is_paid: boolean
  is_next: boolean
  is_deposit: boolean
  payment_number: number
  payment_milestone_configuration_id: number | null
  payment_request_id: number | null
  fixed_override_amount?: number
  date_requested: string | null
  date_due: string | null
  date_completed: string | null
  override_uuid?: string
}

export type LightReachMonthlyPaymentsDataType = {
  backupBatteryPayment: number
  monthlyPayment: number
  totalMonthlyPayment: number
  year: number
}

export type LightReachPricingOptionType = {
  description: string
  escalationRate: number
  kwhRate: number
  lseId: number
  monthlyPayments: LightReachMonthlyPaymentsDataType[]
  name: string
  ppwRate: number
  productId: string
  solarOnlyEPCPayment: number
  state: string
  totalEPCPayment: number
  type: string
  utilityName: string
}

export interface PaymentOptionDataType {
  integration: string
  pricing: PricingDataType
  incentive_to_customer: {
    total: number
    incentives: IncentiveDataType[]
  }
  ignore_tax_in_financials: boolean
  margin: number
  tax: number
  tax_percentage: number
  top_line_system_price: number
  top_line_system_price_tax: number
  costs: CostingDataType
  price_adjustments: {
    modules: number
    inverters: number
    batteries: number
    others: number
    total: number
  }
  formula: PricingSchemeFormulaEnum
  battery_price: number
  settings: {
    price_per_watt_0_1: number
    price_per_watt_100_plus: number
    price_per_watt_50_60: number
    price_per_watt_10_12: number
    auto_apply_only_specified_zips: string | null
    tax_percentage_included: number
    battery_price_per_kwh_0_5: number
    auto_apply_only_specified_states: string | null
  }
  breakdown: Array<Array<BreakdownDataType>>
  //not sure what extra is
  extra: any
  hashed_args: string | undefined
  cash_flows_payments: number[]
  payment_type: PaymentTypeEnum
  payment_frequency?: PaymentFrequencyTypeEnum
  payment_option_id: number
  ppa_dollars_per_kwh?: number
  name: string
  net_cost_to_customer: number
  net_system_cost: number
  upfront_cost: number
  headline: number
  headline_format: 'dollars_rounded' | 'dollars_and_cents_per_month' | 'string'
  regular_payment: number
  deposit_amount: number
  collect_signature: boolean
  deposit_percentage: number | null
  deposit_min: number | null
  deposit_max: number | null
  id: number
  title: string
  description: string
  utility_tariff_id: string
  utility_bill_yearly_current: number[]
  utility_bill_yearly_proposed: number[]
  lifetime_utility_bill_current: number
  lifetime_utility_bill_proposed: number
  utility_bill_savings_yearly: number[]
  utility_bill_savings_total: number
  utility_savings_per_bill_first_year: number
  performance_based_incentive: number[]
  utility_cost_after_pbi_current: number[]
  utility_cost_after_pbi_proposed: number[]
  utility_cost_total_current: number
  utility_cost_total_proposed: number
  utility_savings_yearly: number[]
  utility_savings_total: number
  annual_savings_yearly: number[]
  depreciation_benefit_per_year: number[]
  total_depreciation_benefit: number
  tax_effect_of_solar_per_year: number[]
  cash_flows_additional_costs: number[]
  cash_flows_additional_costs_total: number
  total_energy_cost_after_solar: number[]
  net_profit: number
  cash_flows: number[]
  system_cost_including_incentives: number[]
  bank_balance: number[]
  net_present_value: number
  payback_year: number | null
  discounted_payback_period: number | null
  total_return_on_investment: number | null
  internal_rate_of_return: number | null
  lcoe: number | null
  utility_inflation_annual: number
  feed_in_tariff_inflation_annual: number
  discount_rate: number
  proposed_usage_adjustment: number
  quotation_lines: QuotationLineType[]
  contract_template: string
  customer_action: CustomerActionType
  featured_figures: Record<string, FeaturedFigureType>
  disclaimer_system_price: string
  disclaimer_financial_impact: string
  disclaimer_payment_type: string
  loan_type?: undefined | 'buy_now_pay_later'
  contract_base_pdf_url?: string
  contract_template_id?: number
  configuration_json: string
  auto_apply_enabled: boolean
  auto_apply_only_specified_states: undefined | string
  auto_discount: boolean
  is_archived?: boolean
  down_payment: number
  down_payment_override?: number
  prepayment_period: number | undefined
  org_id?: number //Note: old systems may not have the org_id in the array of calculated payment options
  finance_product_category: {} | TrimmedFinanceProductCategoryType
  finance_ctas: SimpleFinanceCTA[]
  integration_external_reference?: string
  priority?: number
  banner_image_url?: string
  interest_rate?: number
  interest_rate_disclaimer?: string | null // 2.14.0 introduced this field
  term_years?: number
  term?: number
  messages: CalculatedPaymentOptionMessageType[]
  expected_milestone_payments: ExpectedMilestonePaymentType[]
  custom_data?: { extra_quote_acceptance_content?: { message: string }[] }
  pricing_options?: LightReachPricingOptionType[]
}

export type SimpleFinanceCTA = {
  is_pro_facing: boolean
  is_customer_facing: boolean
  type: FinanceCtaType
}

export type TrimmedFinanceProductCategoryType = {
  quotation_table_is_editable: boolean
  display_name: string
  description: string
  quotation_table_json: undefined | object
  treat_all_terms_as_unit: boolean
}

export type PaymentFactorType = {
  value: number
  start_period: number
  end_period: number
}

import { useCallback } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import restClient from 'restClient'
import { OtherComponentType } from 'types/otherComponent'

export const useSaveOtherComponentsToSystem = () => {
  const restClientInstance = restClient(window.API_ROOT + '/api')
  const notify = useNotify()
  const translate = useTranslate()

  const addOtherComponentToSystem = useCallback((componentToAdd: any) => {
    const target = window.editor.objectByUuid(window.editor.selectedSystem.uuid)
    window.editor.execute(new window.AddObjectCommand(new window.OsOther(componentToAdd), target, false))
  }, [])

  return useCallback(
    (
      componentsToAddOnOrg: OtherComponentType[],
      componentsToAddToSystem: OtherComponentType[],
      manufacturerName: string
    ): Promise<OtherType[]> => {
      return new Promise((resolve, reject) => {
        // Handle cleanup in onFinally not onSuccess because onSuccess will only fire if components need to be created

        // Quick hack to inject these vars, roleId not actually required either
        var orgId = window.projectForm.getState().values.org_id
        var roleId = 999

        // Restrict to either 'Renusol' or 'ESDEC' for now
        var manufacturorForUrl = manufacturerName === 'Renusol' ? manufacturerName : 'ESDEC'

        restClientInstance('CUSTOM_POST', 'custom', {
          url:
            'orgs/' +
            orgId +
            '/roles/' +
            roleId +
            '/systems/' +
            window.editor.selectedSystem.uuid +
            '/' +
            manufacturorForUrl +
            '/components/',
          data: { components: [...componentsToAddOnOrg, ...componentsToAddToSystem] },
        })
          .then((res: any) => {
            if (res.data.success) {
              const existingMfrComponents = window.editor.selectedSystem
                ?.others()
                ?.filter(
                  (component: any) => component.manufacturer_name.toLowerCase() === manufacturerName.toLowerCase()
                )

              if (existingMfrComponents && existingMfrComponents.length > 0) {
                existingMfrComponents.forEach((existingComp) => window.editor.deleteObject(existingComp))
              }

              // get the id, other_id, and other needed fields of newly activated components so we can use that when adding the components to the system
              let fullyPopoulatedNewActivations: OtherType[] = [] //should have the same components as componentsToAddOnOrg, but with all fields needed to add to org
              componentsToAddToSystem?.forEach((comp) => {
                let isNewToOrg = !comp.id
                let newlySavedComponent = res.data.activations?.find((act) => act.code === comp.code)
                comp.other_id = newlySavedComponent.id
                comp.id = newlySavedComponent.id
                comp.manufacturer_name = newlySavedComponent.manufacturer_name
                comp.show_customer = newlySavedComponent.show_customer

                if (isNewToOrg) {
                  // If component data includes ordering price/stock data then we should re-inject that into loaded components after they have been created
                  // because this will not be injected into the data returned by this endpoint
                  fullyPopoulatedNewActivations.push(new OtherType(comp))
                }
              })

              // if we have components that were just added to the org then make sure we add them on the front-end so they are recognized when they're added to the system design
              if (fullyPopoulatedNewActivations.length > 0)
                window.AccountHelper.addComponentOtherSpecs(fullyPopoulatedNewActivations)

              componentsToAddToSystem.forEach((componentToAdd) => {
                addOtherComponentToSystem(componentToAdd)
              })

              resolve(fullyPopoulatedNewActivations)
            }
          })
          .catch((err) => {
            notify(err?.message || translate('There was an error saving components to the system.'))
            reject(err)
          })
      })
    },
    []
  )
}

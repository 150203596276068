import { Paper } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { BooleanInput, NumberInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import DependentInput from '../../../elements/input/DependentInput'
import { toggle_inputs_and_extra_fields } from '../common'
import { styles } from '../styles.js'

export const DealerFee = ({ resource, disabled = false }) => {
  const form = useForm()
  const formState = useFormState()
  const isVariablesEnabled = formState.values['variable_dealer_fee_enabled']
  const isNonSunlightUsFinco = ['mosaic', 'dividend', 'sungage', 'loanpal'].includes(
    formState.values['variable_integration']
  )

  const getEffectiveDealerFee = () => {
    if (!isVariablesEnabled) return undefined
    else
      return (
        parseFloat(formState.values['variable_dealer_fee_percentage'] || 0) +
        parseFloat(formState.values['variable_dealer_fee_percentage_adjustment'] || 0)
      )
  }

  const [effectiveDealerFee, setEffectiveDealerFee] = useState(getEffectiveDealerFee())

  useEffect(() => {
    setEffectiveDealerFee(getEffectiveDealerFee())
  }, [
    formState.values['variable_dealer_fee_percentage_adjustment'],
    formState.values['variable_dealer_fee_percentage'],
  ])

  const getIntegrationCompany = () => {
    const NAME_MAP = {
      loanpal: 'GoodLeap',
      mosaic: 'Mosaic',
      sunlight: 'Sunlight',
      sungage: 'Sungage',
    }
    return NAME_MAP[formState.values['variable_integration']] || 'the financing company'
  }

  return (
    <div style={Boolean(isVariablesEnabled) ? styles.loanWrapperWithBoxShadow : styles.loanWrapper}>
      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', height: 50 }}>
        <BooleanInput
          resource={resource}
          formControlLabelStyle={styles.formControlLabelStyle}
          onChange={(value) => {
            if (!value) {
              const selectedToggle =
                toggle_inputs_and_extra_fields.find((a) => a.key === 'variable_dealer_fee_enabled') || {}
              const extraFields = selectedToggle.extraFields || []
              extraFields.forEach((field) => {
                form.mutators.updateField(field, null)
              })
            }
          }}
          label="Dealer Fee"
          name="variable_dealer_fee_enabled"
          source="variable_dealer_fee_enabled"
          disabled={disabled}
        />
      </div>
      {formState.values['variable_integration'] === 'brighte' && (
        <div style={{ borderRadius: '5px', backgroundColor: '#fcf4c5', opacity: 0.7, marginBottom: '15px' }}>
          <p style={{ textAlign: 'left', color: 'black', margin: '0px', padding: '4px' }}>
            Please note, Opensolar does not have access to the dealer fee associated with your Brighte payment options.
            If you would like to include your dealer fee, please manually add or update the dealer fees here.
          </p>
        </div>
      )}
      <DependentInput dependsOn="variable_dealer_fee_enabled" value={true}>
        {!isVariablesEnabled && (
          <p>Enter a value here if you would like to increase the system price to offset dealer fees</p>
        )}
        <div style={{ display: 'inline-block', alignItems: 'center', flexWrap: 'wrap' }}>
          <NumberInput
            resource={resource}
            style={styles.inputFieldLessWide}
            inputLabelProps={{
              shrink: true,
            }}
            label="Dealer Fee"
            endAdornment="%"
            helperText={formState.values?.payment_type === 'cash' ? 'of System Cost' : 'of Loan Amount'}
            source={'variable_dealer_fee_percentage'}
            name={'variable_dealer_fee_percentage'}
            disabled={disabled}
          />
          <NumberInput
            step={1}
            resource={resource}
            inputLabelProps={{
              shrink: true,
            }}
            style={styles.inputFieldLessWide}
            label={'Fixed Amount'}
            startAdornment="$"
            source={'variable_dealer_fee_fixed'}
            name={'variable_dealer_fee_fixed'}
            disabled={disabled}
          />
        </div>
      </DependentInput>
      {isVariablesEnabled && (
        <div>
          {isNonSunlightUsFinco && (
            <p>
              The dealer fee above has been imported directly from {getIntegrationCompany()} and will be used to
              calculate the customer-facing price. If you would like to use a different dealer fee when calculating
              system price, you can make an adjustment to the imported dealer fee by entering a value (positive or
              negative) below. Editing the dealer fee in this way will <i>only</i> affect the customer-facing price. It
              will <i>not</i> affect your calculated costs and margins.
            </p>
          )}
          {formState.values?.payment_type === 'loan' && (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', padding: '15px' }}>
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <NumberInput
                  resource={resource}
                  inputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  style={{ Width: '100%' }}
                  label="Dealer Fee Adjustment (%)"
                  endAdornment="%"
                  source={'variable_dealer_fee_percentage_adjustment'}
                  name={'variable_dealer_fee_percentage_adjustment'}
                />
              </div>
              <div
                style={{
                  flex: 2,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                {formState.values['variable_dealer_fee_percentage_adjustment'] !== undefined && (
                  <Paper
                    style={{
                      padding: '20px',
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                      backgroundColor: 'rgba(246, 215, 43, 0.35)',
                    }}
                  >
                    <span>The dealer fee used in pricing will be:</span>
                    <strong>{effectiveDealerFee === undefined ? '0.00' : effectiveDealerFee.toFixed(2)}%</strong>
                  </Paper>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined'
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined'
import MoveToInboxOutlinedIcon from '@material-ui/icons/MoveToInboxOutlined'
import type { OpenSolarThemeType, OsValidityType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import ValidityChip from 'elements/chip/ValidityChip'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { useOpenSolarTheme } from 'themes/useOpenSolarTheme'
import EditableDateField from '../../components/EditableDateField'
import useUpdateTransfer from '../../transfer/hooks/useUpdateTransfer'
import useUpdateForecastDate from '../hooks/useUpdateForecastDate'
import useUpdateInstallationDate from '../hooks/useUpdateInstallationDate'
import type { ForecastTransferStatus, InventoryForecastTransfer } from '../type'

const mapForecastStatusToValidity = (status: ForecastTransferStatus): OsValidityType => {
  switch (status) {
    case 'danger':
      return 'error'
    case 'warning':
      return 'warning'
    case '':
    default:
      return 'default'
  }
}

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  wrapper: {
    minHeight: 62,
    borderBottom: '1px solid #e7e7e7',
  },
  iconWrapper: {
    textAlign: 'right',
  },
  icon: {
    color: theme.greyMid1,
  },
  noteText: {
    color: theme.greyMid1,
  },
}))

const ForecastDetailRow = ({
  transfer,
  reloadForecastTransfers,
  handleTouched,
}: {
  transfer: InventoryForecastTransfer
  reloadForecastTransfers(): void
  handleTouched?(): void
}) => {
  const classes = useStyles()
  const theme = useOpenSolarTheme()
  const { updateProjectForecastDate } = useUpdateForecastDate()
  const { updateInstallationDate } = useUpdateInstallationDate()
  const { updateTransferDeliveryDate } = useUpdateTransfer()
  const validity = mapForecastStatusToValidity(transfer.status)
  const handleDateChange = async (date) => {
    handleTouched?.()
    if (transfer.type === 'incoming' || transfer.type === 'outgoing') {
      await updateTransferDeliveryDate({ transferId: transfer.transfer_id, date })
    } else if (transfer.type === 'project' && transfer.installation_date) {
      await updateInstallationDate({ projectId: transfer.project_id, date })
    } else if (transfer.type === 'project' && !transfer.installation_date) {
      await updateProjectForecastDate({ projectId: transfer.project_id, date })
    }
    reloadForecastTransfers()
    logAmplitudeEvent('generic_button_clicked', {
      source: 'forecast_modal_date',
      context: 'inventory_forecast_modal_date',
    })
  }
  return (
    <Grid container item xs={12} alignItems={'center'} className={classes.wrapper}>
      <Grid item xs={1} className={classes.iconWrapper}>
        {transfer.delta > 0 && <MoveToInboxOutlinedIcon className={classes.icon} />}
        {transfer.delta < 0 && (
          <>
            <ArrowDownwardOutlinedIcon className={classes.icon} />
            <LocalShippingOutlinedIcon className={classes.icon} />
          </>
        )}
      </Grid>
      <Grid container item xs={4} style={{ paddingLeft: 15 }} alignItems={'center'}>
        <Grid item xs={12}>
          <Typography variant="body2" style={{ color: theme.ValidityPalette[validity].color, fontWeight: 500 }}>
            {transfer.activity}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" className={classes.noteText}>
            {transfer.notes}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* {moment(transfer.date).format('DD/MM')}
          <IconButton size="small">
            <EditOutlinedIcon fontSize="small" />
          </IconButton> */}
          <EditableDateField date={transfer.date} handleValueChange={handleDateChange} />
        </div>
      </Grid>
      <Grid item xs={2}>
        <ValidityChip label={transfer.delta} validity={validity} />
      </Grid>
      <Grid item xs={2}>
        {transfer.on_hand}
      </Grid>
    </Grid>
  )
}

export default ForecastDetailRow

import { OpenSolarThemeType } from 'Themes'
import { useTranslate } from 'ra-core'
import React from 'react'
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import CashFlowConfigurationNav from './CashFlowConfigurationNav'

type PropTypes = {}

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  linksWrapper: {
    display: 'flex',
    top: 50,
    height: 50,
    padding: '0 20px',
    borderTop: '1px solid #0000001a',
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%)',
    alignItems: 'center',
    flexDirection: 'row',
    position: 'fixed',
    width: '100%',
    justifyContent: 'center',
    background: theme.white,
    zIndex: 1100,
  },

  linkText: {
    textDecoration: 'none',
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,

    padding: '16px 24px',
    letterSpacing: 0.5,
  },

  configureLinksWrapper: {
    display: 'flex',
  },

  configureLinks: {},
}))

const CashFlowSubHeader: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const location = useLocation()

  const configureMatch = useRouteMatch('/cashflow/configure')

  return (
    <div style={{ paddingTop: 50 }}>
      <div className={classes.linksWrapper}>
        <NavLink
          to="/cashflow/projects"
          className={classes.linkText}
          activeStyle={{
            fontWeight: 'bold',
          }}
        >
          {translate('Projects')}
        </NavLink>
        <NavLink
          to="/cashflow/invoices"
          className={classes.linkText}
          activeStyle={{
            fontWeight: 'bold',
          }}
        >
          {translate('Invoices')}
        </NavLink>
        <NavLink
          to="/cashflow/configure/milestones"
          className={classes.linkText}
          isActive={() => !!configureMatch}
          activeStyle={{
            fontWeight: 'bold',
          }}
        >
          {translate('Configure')}
        </NavLink>
      </div>

      {location.pathname.includes('configure') && <CashFlowConfigurationNav />}
    </div>
  )
}

export default CashFlowSubHeader

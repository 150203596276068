import { Grid } from '@material-ui/core'
import { AccountBalance, Business, PeopleAlt, VerifiedUser } from '@material-ui/icons'
import { useTranslate } from 'ra-core'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import PaymentChannels from '../components/PaymentChannels'
import { FormRouteInfoPropTypes } from '../types'

const useStyles = makeOpenSolarStyles((theme) => ({
  mainWrapper: {
    margin: '0 14px',
  },

  container: {
    background: theme.greyLight3,
    borderRadius: '8px',
    padding: '1rem',
    height: '80%',
  },

  header: { fontSize: 20, fontWeight: 500, marginTop: 0, marginBottom: 0 },

  content: {
    padding: '1rem 0',
  },

  icon: {
    color: theme.greyMid1,
    height: '1.25rem',
    marginRight: '.5rem',
  },

  p: {
    display: 'flex',
    alignItems: 'center',
  },

  shieldIcon: {
    height: '1.25rem',
    color: '#019939',
    padding: '10px',
    background: '#DAE5FF',
    borderRadius: 7,
    margin: '10px 0 5px',
  },

  description: {
    color: theme.greyMid1,
    fontSize: 11,
    lineHeight: 1.5,
  },

  blueContainer: {
    background: '#EDF3FF',
    borderRadius: '8px',
    padding: '1rem',
    textAlign: 'center',
    height: '80%',
  },

  header2: {
    color: '#2C499D',
    fontSize: 15,
    fontWeight: 500,
  },

  wrapper: {
    marginBottom: '1rem',
  },
}))

const IntroTop: React.FC<FormRouteInfoPropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <div data-testid="bs-onboarding-intro-top" className={classes.mainWrapper}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <div className={classes.container}>
            <h1 className={classes.header}>{translate('Information to have on hand before you begin')}</h1>

            <div className={classes.content}>
              <p className={classes.p}>
                <Business className={classes.icon} />
                {translate('Legal business information')}
              </p>

              <p className={classes.p}>
                <PeopleAlt className={classes.icon} />
                {translate("Owner and/or Company Representative's personal information")}
              </p>

              <p className={classes.p}>
                <AccountBalance className={classes.icon} />
                {translate('Business bank account information')}
              </p>
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.blueContainer}>
            <VerifiedUser className={classes.shieldIcon} />
            <h2 className={classes.header2}>{translate('Safe & Secure')}</h2>
            <p className={classes.description}>
              {translate(
                'Payment information is secure and encrypted. Our platform ensures security and data privacy by partnering with leading payment providers'
              )}
            </p>
            <div className={classes.wrapper}>
              <PaymentChannels />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
export default IntroTop

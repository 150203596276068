import { useStudioSignalsLazy } from 'Studio/signals/useStudioSignalsLazy'
import { useCallback, useMemo, useState } from 'react'
import { StudioItem } from 'types/global'
import { StudioSignalTypes } from 'types/studio/signals'

const DEFAULT_SIGNALS: StudioSignalTypes[] = ['objectChanged']

export const useStudioInput = <Type = string | number | boolean>(props: {
  entity: StudioItem
  field: string
  signals?: StudioSignalTypes[]
  signalFilter?: false | ((item?: StudioItem) => boolean)
}) => {
  // Keep a counter to force react rerender
  const [counter, setCounter] = useState(0)
  const entity = props.entity
  const field = props.field

  const onSignal = useCallback(
    (item) => {
      if (props.signalFilter === false) {
        // pass
      } else if (props.signalFilter) {
        if (!props.signalFilter(entity)) return
      } else if (item) {
        if (entity.uuid) {
          if (item.uuid !== entity.uuid) return
        } else if (item !== entity) return
      }

      // If filters pass, increment the counter, which will retrigger the useMemo below
      setCounter(counter + 1)
    },
    [entity, counter]
  )

  // Listen to studio events representing changes to the property
  useStudioSignalsLazy(onSignal, props.signals || DEFAULT_SIGNALS, undefined, { trackHandler: true })

  const value = useMemo(() => {
    return entity[field] as Type
  }, [entity, field, counter])

  const onChange = useCallback(
    (value: Type) => {
      window.editor.execute(
        new window.SetValueCommand(entity, field, value, window.Utils.generateCommandUUIDOrUseGlobal())
      )
    },
    [entity, field]
  )

  return { value, onChange }
}

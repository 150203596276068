import { Typography } from '@material-ui/core'
import { Grid } from 'opensolar-ui'
import Skeleton from 'react-loading-skeleton'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { formatCurrency } from 'util/misc'

const useStyles = makeOpenSolarStyles((theme) => ({
  skeleton: {
    height: '20px',
    minWidth: '50px',
  },
}))

const CreditLimit = ({
  loading,
  currencySymbol = '',
  creditLimit,
  supplierName,
}: {
  loading: boolean
  currencySymbol: string | undefined
  creditLimit: number | undefined
  supplierName: string
}) => {
  const classes = useStyles()
  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <Typography variant="h6">
          <span>{supplierName} Credit limit</span>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">
          <span>
            {loading ? <Skeleton className={classes.skeleton} /> : `${currencySymbol}  ${formatCurrency(creditLimit)}`}
          </span>
        </Typography>
      </Grid>
    </Grid>
  )
}

export default CreditLimit

import { makeStyles } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Grow from '@material-ui/core/Grow'
import useGetCustomForms from 'customForm/hooks/useGetCustomForms'
import { permissionsSelectors } from 'ducks/permissions'
import CRUDRecordDialog from 'projectSections/sections/common/CRUDRecordDialog'
import { useNotify, useTranslate } from 'ra-core'
import React, { useState } from 'react'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { ContactFields } from 'resources/contacts/form/ContactFields'
import { ContactForms } from 'resources/contacts/form/ContactForms'
import { FormPlacementEnum } from 'resources/customForms/types'
import { ContactType } from '../../../../types/contacts'
import ContactLinkExisting from './ContactLinkExisting'

type PropTypes = {
  discardContact: () => void
  contact: ContactType | null
  index: number
}

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '10px',
  },
  leftGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: 'rgba(0,0,0,0.54)',
  },
  rightGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: 'rgba(0,0,0,0.54)',
  },
  linkPaddingRight: {
    paddingRight: '15px',
  },
}))

const ContactNew: React.FunctionComponent<PropTypes> = (props) => {
  const [showContactForm, setShowContactForm] = useState<boolean>(true)
  const [showLinkInput, setShowLinkInput] = useState<boolean>(false)
  const [showExpanded, setShowExpanded] = useState<boolean>(false)

  const form = useForm()
  const translate = useTranslate()
  const classes = useStyles()
  const { allowView, allowCreate: allowCreateRestInfo, allowEdit, allowDelete } = useSelector(
    permissionsSelectors.getProjectPermissionByKey('info_contact_info_full')
  )
  const notify = useNotify()
  const onDiscard = () => props.discardContact()
  const toggleLink = () => {
    setShowContactForm(!showContactForm)
    setShowLinkInput(!showLinkInput)
  }
  const customForms = useGetCustomForms({ location: FormPlacementEnum.info_page_contact, isCreateForm: true })

  const onSelection = (selected: ContactType) => {
    if (!!selected) {
      form.mutators.markFieldAsDirty('contacts')
      setShowLinkInput(false)
      setShowContactForm(true)
      const new_contacts_data =
        form.getState().values.contacts_data && form.getState().values.contacts_data.length > 0
          ? [...form.getState().values.contacts_data, selected]
          : [selected]
      form.change('contacts_data', new_contacts_data)
      const contacts = form.getState().values.contacts || []
      contacts.push(selected.url)
      form.change('contacts', contacts)
      form.change('existing_contact', undefined)
      const new_contacts_new = form
        .getState()
        .values.contacts_new.filter((con: ContactType, i: number) => i !== props.index)
      form.change('contacts_new', new_contacts_new)
    } else {
      notify('Something went wrong', 'error')
    }
  }

  return (
    <Grow in={true} timeout={300}>
      <Grid container spacing={3}>
        {showContactForm && (
          <ContactFields
            prefix={`contacts_new.${props.index}.`}
            uiKey="projects.project.info.contact.info_contact_info_basic"
          />
        )}
        {showLinkInput && <ContactLinkExisting contact={props.contact} index={props.index} onSelection={onSelection} />}
        <Grid item xs={12}>
          <div className={classes.main}>
            <div className={classes.leftGroup}>
              <a
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                className={classes.linkPaddingRight}
                onClick={onDiscard}
              >
                {translate('Discard')}
              </a>
              <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={toggleLink}>
                {showLinkInput ? translate('Enter details') : translate('Assign Existing Contact')}
              </a>
            </div>
            <div className={classes.rightGroup}>
              {!showLinkInput && allowCreateRestInfo && (
                <a
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => {
                    setShowExpanded(true)
                  }}
                >
                  {translate('Show more fields')}
                </a>
              )}
            </div>
          </div>
        </Grid>
        <Divider style={{ margin: '20px 0px' }} />
        <CRUDRecordDialog
          isOpen={showExpanded}
          resource="contacts"
          basePath="/contacts/create"
          onDismiss={() => setShowExpanded(false)}
          formContent={
            <ContactForms customForms={customForms} uiKey="projects.project.info.contact" contact={props.contact} />
          }
          notificationOnSuccess={true}
          customSuccessNotificationText={translate('Contact Created')}
          updateFormValsFn={onSelection}
          dialogTitle={translate('Add Contact')}
        />
      </Grid>
    </Grow>
  )
}

export default ContactNew

import { Grid, makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import Alert from 'elements/Alert'
import CSVUploadDialog from 'elements/CSVUploadDialog'
import { Button } from 'opensolar-ui'
import { FC, useState } from 'react'
import { NumberInput, SelectInput, useDataProvider, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { Theme } from 'types/themes'
import { monthNames } from 'util/misc'
import useTranslateParse from 'util/useTranslateParse'
import { customRound } from '../MonthlyUsageChart'

export type UsageDataType = {
  data_source: string
  usage_data_source: string
  uploaded_file_id?: number
  interval_mins?: number
  start_date?: Date
  end_date?: Date
  units?: string
  values?: number[]
  monthly_values?: number[]
  monthly_usage_override?: number[]
  kw_monthly_demand?: number[]
  controlled_load_daily_kwh_0?: number
  controlled_load_daily_kwh_1?: number
  controlled_load_daily_kwh_2?: number
  normalized?: any
}

type PropsType = {
  usageData: UsageDataType
  onChange: any
  uploadURL: string
  disabled?: boolean
}

const useStyles = makeStyles<Theme>((theme) => ({
  dialogStyles: {
    '& .MuiDialog-paperScrollPaper': {
      maxHeight: 'unset',
    },
  },
  intervalFile: {
    padding: '0 10px',
    color: `${theme.secondaryColor} !important`,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  intervalDataDetails: {
    '& p': {
      minWidth: 120,
      marginTop: 10,
      marginBottom: 10,
    },
  },
  intervalData: {
    color: theme.greyMid1,
  },
  tableStyles: {
    maxWidth: 260,
    '& .MuiTableCell-root': {
      padding: '8px 10px',
      border: '1px solid ' + theme.greyLight2,
      backgroundColor: theme.greyMid2,
    },
  },
  monthName: {
    fontWeight: 500,
  },
  dataColumn: {
    textAlign: 'right',
    '& span': {
      paddingRight: 30,
    },
  },
  editableCell: {
    backgroundColor: 'unset !important',
  },
  dataInput: {
    margin: 0,
    height: 0,
    '& .MuiInputBase-input': {
      textAlign: 'right',
    },
    '& .MuiInput-root': {
      margin: 0,
    },
  },
  numList: {
    '& li:first-child': {
      paddingTop: 0,
    },
    '& li': {
      padding: '6px 0',
    },
  },
  topSpace: {
    marginTop: 30,
    '& ul': {
      marginTop: 0,
    },
  },
}))

const DATA_SOURCES = {
  csv_upload: 'CSV Upload',
  utility_api: 'UtilityAPI',
}

const findPrivateFile = (formValues, fileId) => {
  if (fileId) {
    const filterFile = formValues.private_files_data?.filter((x) => x.id === fileId)
    if (filterFile.length > 0) return filterFile[0]
  }
}

const IntervalDataFields: FC<PropsType> = ({ usageData, onChange, uploadURL, disabled }) => {
  const classes = useStyles()
  const form = useForm()
  const formState = useFormState()
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const dirtyFields = form.mutators.getFormDirtyFields()
  const [csvDialogOpen, setCsvDialogOpen] = useState(false)
  const intervalDataFile = findPrivateFile(formState.values, usageData?.uploaded_file_id)
  const showMissingData = !!usageData.monthly_values?.filter((x) => x === null).length
  const isHourlyInterval = usageData.usage_data_source === 'interval_60min' || usageData.data_source === 'utility_api'
  const showData = usageData.interval_mins && usageData.usage_data_source === 'interval_data'

  const onUnitsChange = (event) => {
    usageData.units = event.target.value
    onChange()
  }

  const refreshPrivateFiles = (fileName, fileId) => {
    dataProvider.CUSTOM_GET(
      'private_files',
      {
        url: `private_files/`,
      },
      {
        onSuccess: (response: { data }) => {
          const { data } = response
          const findFile = data.filter((x) => x.title === fileName && x.id === fileId)
          if (findFile.length > 0) {
            let currFiles = [...formState.values.private_files_data]
            currFiles.push(findFile[0])
            form.mutators.updateFieldSilently('private_files_data', currFiles)
          }
        },
        onFailure: (e) => {
          console.log(e)
        },
      }
    )
  }

  return (
    <Grid item xs={12} sm={6}>
      {showData && !isHourlyInterval && showMissingData && (
        <Alert severity="warning">
          {translate('Full year worth of data not provided. Some data points were extrapolated.')}
        </Alert>
      )}
      <div className={classes.row}>
        <p style={{ marginRight: 12, display: 'flex', alignItems: 'baseline' }}>
          {translate('File')}:{' '}
          {intervalDataFile ? (
            <a
              className={classes.intervalFile}
              href={intervalDataFile?.file_contents}
              title={intervalDataFile?.title}
              target="_blank"
              rel="noopener noreferrer"
            >
              {intervalDataFile?.title}
            </a>
          ) : (
            <span className={classes.intervalData}>{translate('No file uploaded')}</span>
          )}
        </p>
        <Button
          variant="outlined"
          onClick={() => {
            setCsvDialogOpen(true)
          }}
          disabled={disabled}
        >
          <span>{intervalDataFile ? translate('Re-Upload CSV') : translate('Upload CSV')}</span>
        </Button>
      </div>
      {showData && (
        <div className={classes.intervalDataDetails}>
          <h2>{translate('Interval Data Details')}</h2>
          <div className={classes.row}>
            <p>{translate('Units')}</p>
            {isHourlyInterval ? (
              <p className={classes.intervalData}>{translate('kWh')}</p>
            ) : (
              <SelectInput
                label={false}
                name="units"
                resource={'projects'}
                onChange={onUnitsChange}
                value={usageData?.units || undefined}
                choices={[
                  { id: 'kwh', name: 'kWh' },
                  { id: 'wh', name: 'Wh' },
                  { id: 'kw', name: 'kW' },
                  { id: 'w', name: 'W' },
                ]}
                source={'units'}
                style={{ margin: 0 }}
                disabled={disabled}
              />
            )}
          </div>
          <div className={classes.row}>
            <p>{translate('Data Source')}</p>
            <p className={classes.intervalData}>{usageData.data_source ? DATA_SOURCES[usageData.data_source] : '-'}</p>
            <p></p>
          </div>
          <div className={classes.row}>
            <p>{translate('Interval')}</p>
            <p className={classes.intervalData}>
              {isHourlyInterval ? 60 : usageData.interval_mins} {translate('Minutes')}
            </p>
          </div>
          <div className={classes.row}>
            <p>{translate('Start Date')}</p>
            <p className={classes.intervalData}>{isHourlyInterval ? '-' : usageData.start_date || '-'}</p>
          </div>
          <div className={classes.row}>
            <p>{translate('End Date')}</p>
            <p className={classes.intervalData}>{isHourlyInterval ? '-' : usageData.end_date || '-'}</p>
          </div>
          {showMissingData && !isHourlyInterval && (
            <>
              <h2>{translate('Adjust Extrapolated Consumption')}</h2>
              <p>
                <span style={{ color: 'red' }}>*</span>
                {translate(
                  'All white cells with grayed out values are extrapolated missing values that can be overridden by entering a value in the table.'
                )}
              </p>
              <Table className={classes.tableStyles}>
                <TableHead>
                  <TableRow>
                    <TableCell>{translate('Month')}</TableCell>
                    <TableCell>
                      {translate('Consumption')} ({translate('kWh')})
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usageData.monthly_values?.map((monthValue, i) => {
                    return (
                      <TableRow>
                        <TableCell className={classes.monthName}>{translate(monthNames[i])}</TableCell>
                        {monthValue !== null ? (
                          <TableCell className={classes.dataColumn}>
                            <span>{customRound(usageData.normalized?.monthly[i])}</span>
                          </TableCell>
                        ) : (
                          <TableCell className={classes.editableCell}>
                            <NumberInput
                              source={`monthly_usage_override[${i}]`}
                              name={`monthly_usage_override[${i}]`}
                              defaultValue={
                                usageData?.monthly_usage_override ? usageData?.monthly_usage_override[i] : undefined
                              }
                              resource={'projects'}
                              onChange={() => {
                                if (formState.values.monthly_usage_override === null) {
                                  form.mutators.updateField('monthly_usage_override', new Array(12).fill(null))
                                }
                                if (
                                  !dirtyFields.includes(
                                    'monthly_usage_override' && usageData.usage_data_source === 'interval_data'
                                  )
                                ) {
                                  form.mutators.markFieldAsDirty('monthly_usage_override')
                                }
                                onChange()
                              }}
                              options={{
                                placeholder: customRound(usageData.normalized?.monthly[i]),
                                InputLabelProps: {
                                  shrink: true,
                                },
                              }}
                              label={false}
                              className={classes.dataInput}
                              disabled={disabled}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </>
          )}
        </div>
      )}

      <CSVUploadDialog
        className={classes.dialogStyles}
        uploadUrl={uploadURL}
        title={'Upload CSV - Interval Data'}
        description={<IntervalDataDialogDescription />}
        isOpen={csvDialogOpen}
        handleClose={(usageData, fileData) => {
          if (usageData?.usage_data_source) {
            form.mutators.updateField('usage', JSON.stringify(usageData))
            form.mutators.updateField('units', usageData.units)
            form.mutators.updateField('monthly_usage_override', usageData.monthly_usage_override)
            form.mutators.markFieldAsDirty('units')
          }
          if (fileData) {
            refreshPrivateFiles(fileData.title, usageData.uploaded_file_id)
          }

          setCsvDialogOpen(false)
        }}
      />
    </Grid>
  )
}
export default IntervalDataFields

const INTERVAL_DATA_SAMPLES = [60, 30, 15]
const INTERVAL_DATA_SAMPLE_URL = window.PUBLIC_URL + '/files/interval_data_templates/'
export const IntervalDataDialogDescription = () => {
  const translate = useTranslate()
  const classes = useStyles()
  const translateParse = useTranslateParse()
  return (
    <>
      <div>
        <p>
          {translateParse('Interval Data can be provided in two <strong>formats<strong>:', {
            strong: (label: string) => <strong>{label}</strong>,
          })}
          <ol className={classes.numList}>
            <li>{translate('Vertical Data - Time-increments per row')}</li>
            <li>{translate('Horizontal data - 365 days (rows) by time-increments (columns)')}</li>
          </ol>
        </p>
        <p>
          {translateParse('The <strong>following criteria must be satisfied<strong> for the uploaded interval data:', {
            strong: (label: string) => <strong>{label}</strong>,
          })}
          <ol className={classes.numList}>
            <li>
              {translate(
                'The data should only contain the date time and consumption data. Please delete all other additional data columns.'
              )}
            </li>
            <li>{translate('The first column must contain the date time.')}</li>
            <li>{translate('At least one full month of interval data. Missing data will be interpolated.')}</li>
          </ol>
        </p>
        <p>
          {translateParse(
            '<strong>If your upload fails or does not load the data as expected<strong>, your data might not be correctly formatted as required by our system. Please refer to the sample interval data templates.',
            {
              strong: (label: string) => <strong>{label}</strong>,
            }
          )}
        </p>
      </div>

      <div className={classes.topSpace}>
        <h1>{translate('Sample Interval Data Templates')}</h1>
        <h2>{translate('Vertical Data')}</h2>
        <ul>
          {INTERVAL_DATA_SAMPLES.map((interval) => (
            <li>
              <a
                href={`${INTERVAL_DATA_SAMPLE_URL}vertical/Data_Format_Vertical_${interval}mins.csv`}
                target="_blank"
                rel="noreferrer"
              >
                {translate('Download Vertical Format - %{min} minute', {
                  min: interval,
                })}
              </a>
            </li>
          ))}
        </ul>
        <h2>{translate('Horizontal Data')}</h2>
        <ul>
          {INTERVAL_DATA_SAMPLES.map((interval) => (
            <li>
              <a
                href={`${INTERVAL_DATA_SAMPLE_URL}horizontal/Data_Format_Horizontal_${interval}mins.csv`}
                target="_blank"
                rel="noreferrer"
              >
                {translate('Download Horizontal Format - %{min} minute', {
                  min: interval,
                })}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

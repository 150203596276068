import { Tooltip } from '@material-ui/core'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import { Button } from 'opensolar-ui'
import { CSSProperties, FC, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useField, useForm } from 'react-final-form'
import restClient from 'restClient'
import LoadingDialog from '../../common/LoadingDialog'

type Props = {
  orgId: number
  projectId: number
  style?: CSSProperties
}

export const ProjectDuplicateButton: FC<Props> = ({ orgId, projectId, style }) => {
  const [isLoading, setIsLoading] = useState(false)
  const notify = useNotify()
  const form = useForm()
  const translate = useTranslate()
  const dirtyFields = form.mutators.getFormDirtyFields()
  const formDirty = dirtyFields.length > 0
  const systemsReady: boolean = !useField('simulate_first_year_only', {
    subscription: { value: true },
  }).input.value
  const disableDuplication = formDirty || !systemsReady

  const toolTipMessage = () => {
    if (formDirty) return 'Save changes to enable project duplication'
    if (!systemsReady) return 'System calculations ongoing, duplication will be available in a few seconds'
    return 'Project duplication disabled'
  }

  const sendDuplicateRequest = () => {
    const restClientInstance = restClient(window.API_ROOT + '/api')
    const url = 'orgs/' + orgId + '/projects/' + projectId + '/duplicate/'
    setIsLoading(true)
    restClientInstance('CUSTOM_POST', 'custom', {
      url,
    })
      .then((content) => {
        setIsLoading(false)
        window.open(`${window.location.origin}/#/projects/${content.data.project_id}/manage`, '_blank')
      })
      .catch(() => {
        setIsLoading(false)
        notify('Project duplication failed', 'warning')
      })
  }

  const duplicateButton = (
    <Button
      variant="text"
      color="secondary"
      startIcon={<FileCopyOutlinedIcon />}
      onClick={sendDuplicateRequest}
      disabled={disableDuplication}
      style={style}
    >
      {translate('Duplicate Project')}
    </Button>
  )
  return (
    <>
      {disableDuplication ? (
        <Tooltip title={toolTipMessage()} enterDelay={300}>
          <span>{duplicateButton}</span>
        </Tooltip>
      ) : (
        duplicateButton
      )}
      <LoadingDialog displayText={translate('Duplicating project...')} open={isLoading || !systemsReady} />
    </>
  )
}

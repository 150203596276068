import classNames from 'classnames'
import { Box } from 'opensolar-ui'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import useCommonStyles from './useCommonStyles'

const useStyles = makeOpenSolarStyles(() => ({
  nameContainer: {
    display: 'flex',
    rowGap: '18px',
  },
  helperIcon: {
    marginLeft: '10px',
    '& > div': {
      display: 'flex',
    },
  },
  seriesText: {
    display: '-webkit-box',
    lineClamp: 2,
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
}))

interface NameBlockProps {
  code?: string
  name?: string
  series?: string
  helperIcon?: React.ReactNode
}

const NameBlock: React.FC<NameBlockProps> = ({ code, name, series, helperIcon }) => {
  const commonClasses = useCommonStyles()
  const classes = useStyles()

  return (
    <Box display="flex" className={commonClasses.textsContainer} flexDirection="column">
      <Box alignItems="center" display="flex" className={classes.nameContainer}>
        {name ? (
          <>
            <span className={commonClasses.mainText}>{name}</span>
            <span className={commonClasses.lightText}>{code}</span>
          </>
        ) : (
          <span className={commonClasses.mainText}>{code}</span>
        )}
        {!!helperIcon && <span className={classes.helperIcon}>{helperIcon}</span>}
      </Box>
      {series && (
        <span
          className={classNames([commonClasses.subText, classes.seriesText])}
          onMouseEnter={({ currentTarget: target }) => {
            const currentTitle = target.getAttribute('title')
            if (target.offsetHeight < target.scrollHeight && !currentTitle) {
              target.setAttribute('title', series)
            }
          }}
        >
          {series}
        </span>
      )}
    </Box>
  )
}

export default NameBlock

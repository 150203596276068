// @ts-nocheck
import { Dialog, DialogContent, useMediaQuery } from '@material-ui/core'
import React from 'react'
import { useTranslate } from 'react-admin'
import { StipulationActionType } from './types'
import UploadStipulationForm from './UploadStipulationForm'

type PropTypes = {
  open: boolean
  onClose: () => void
  stipulation: StipulationActionType
}

const UploadStipulationDialog: React.FunctionComponent<PropTypes> = (props) => {
  const isMobile = useMediaQuery('(max-width:800px)')
  const translate = useTranslate
  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth={isMobile}>
      <div style={{ textAlign: 'center' }}>
        <h1 style={{ fontSize: isMobile ? undefined : '24px' }}>{translate('Upload Documentation for Financing')}</h1>
      </div>
      <DialogContent>
        <div>
          <UploadStipulationForm stipulation={props.stipulation} closeDialog={props.onClose} />
        </div>
      </DialogContent>
    </Dialog>
  )
}
export default UploadStipulationDialog

import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orderSelectors } from 'ducks/orderComponents'
import Button from 'elements/button/Button'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { OrderStateContext } from './HdmCheckoutProvider'

const buttonMessages = {
  order: 'Order',
  order_from_credit: 'Order from Credit',
  order_disconnected: 'Order',
}

const HdmPlaceOrderButton = ({ onClick, trackSource }: { onClick(): void; trackSource: string }) => {
  const { submitting, isReady, orderType } = useContext(OrderStateContext)
  const lineItemsConfirmed = useSelector(orderSelectors.getConfirmedLineItems)

  const buttonText = `Place ${buttonMessages[orderType || 'order']}`

  return (
    <Button
      color="primary"
      fullWidth={true}
      loading={submitting}
      disabled={!isReady}
      onClick={(event) => {
        event.preventDefault()
        onClick()
        logAmplitudeEvent('hardware_place_order_cta_clicked', {
          distributor: 'hdm',
          source: trackSource,
          codes: lineItemsConfirmed.map((lineItem) => lineItem.code),
        })
      }}
    >
      {buttonText}
    </Button>
  )
}

export default HdmPlaceOrderButton

import { ComponentVersions_3_0 } from 'constants/uxVersions'
import { addAccordionFormsAndSort } from 'customForm/card/addAccordionFormsAndSort'
import useGetCustomForms from 'customForm/hooks/useGetCustomForms'
import { permissionsSelectors } from 'ducks/permissions'
import { useShowTourBanner } from 'hooks/useShowTourBanner'
import { ComponentVersionsInherit, styled } from 'opensolar-ui'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { FormPlacementEnum } from 'resources/customForms/types'
import SectionContainer from '../SectionContainer'
import ProjectDetails from './details'
import ActivitiesSection from './details/ActivitiesSection'
import { LeaderboardAd } from './details/elements/ads/LeaderboardAd'
import { TripleWidescreenAd } from './details/elements/ads/TripleWidescreenAd'
import FilesAndTransactionsSection from './details/elements/filesAndTransactions/FilesAndTransactionsSection'
import TourBanner from './fuji/TourBanner'
import InstallationAccordion from './info/InstallationAccordion'
import MarketingAccordion from './info/MarketingAccordion'
import SaleAccordion from './info/SaleAccordion'
import ServicesAccordion from './info/ServicesAccordion'
import SiteAccordion from './info/SiteAccordion'
import SystemAccordion from './info/SystemAccordion'
import IntegrationsSection from './integrations/IntegrationsSection'
import { ContentBg, getProjectSectionStyles } from './styles'
import ActionsSection from './workflow/ActionsSection'
import WorkflowSection from './workflow/WorkflowSection'

const ContentWrapper = styled('div', { name: 'ContentWrapper' })<{
  padding: object
}>(({ padding }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'stretch',
  width: '100%',
  maxWidth: 1440,
  boxSizing: 'border-box',
  gap: 30,
  '& > *': {
    maxWidth: '100%',
  },
  ...padding,
}))

const STANDARD_ACCORDIONS = [
  {
    component: ProjectDetails,
    shouldRender: true,
    priority: 10,
    props: {},
  },
  {
    component: WorkflowSection,
    shouldRender: true,
    priority: 20,
    props: {},
  },
  {
    component: ActionsSection,
    shouldRender: true,
    priority: 30,
    props: {},
  },
  {
    component: ActivitiesSection,
    shouldRender: true,
    priority: 40,
    props: {},
  },
  {
    component: LeaderboardAd,
    shouldRender: true,
    priority: 50,
    props: {},
  },
  {
    component: FilesAndTransactionsSection,
    shouldRender: true,
    priority: 60,
    props: {},
  },
  {
    component: TripleWidescreenAd,
    shouldRender: true,
    priority: 70,
    props: {},
  },
  {
    component: IntegrationsSection,
    shouldRender: true,
    priority: 80,
    props: {},
  },
  {
    component: MarketingAccordion,
    shouldRender: true,
    priority: 90,
    props: {},
  },
  {
    component: SiteAccordion,
    shouldRender: true,
    priority: 100,
    props: {},
  },
  {
    component: SystemAccordion,
    shouldRender: true,
    priority: 110,
    props: {},
  },
  {
    component: SaleAccordion,
    shouldRender: true,
    priority: 120,
    props: {},
  },
  {
    component: InstallationAccordion,
    shouldRender: true,
    priority: 130,
    props: {},
  },
  {
    component: ServicesAccordion,
    shouldRender: true,
    priority: 140,
    props: {},
  },
]

const Manage = ({ width }) => {
  const classes = getProjectSectionStyles()
  const shouldShowBanner = useShowTourBanner(width, 'manage')
  const customForms = useGetCustomForms({ locations: [FormPlacementEnum.manage_page, FormPlacementEnum.info_page] }) //TODO: Remove Info for public launch

  const [sortAndRenderSections, sortAndRenderAccordions] = useMemo(() => {
    let allComponents = addAccordionFormsAndSort(STANDARD_ACCORDIONS, customForms)
    const splitIndex = allComponents.findIndex((item: any) => item.props.data?.priority === 90)
    return [allComponents.slice(0, splitIndex), allComponents.slice(splitIndex)]
  }, [customForms])
  const { allowView } = useSelector(permissionsSelectors.getProjectPermissionByKey('manage'))
  if (!allowView) {
    return null
  }

  return (
    <SectionContainer section="manage" mode="background">
      <ComponentVersionsInherit versions={ComponentVersions_3_0}>
        {shouldShowBanner.show && <TourBanner />}
        <ContentBg>
          <div className={classes.sectionWrapper}>{sortAndRenderSections}</div>
          <ContentWrapper id={'Project-Marketing-Section'} padding={{ padding: 40, paddingTop: 0 }}>
            <ContentWrapper padding={{ paddingBottom: 20 }}>{sortAndRenderAccordions}</ContentWrapper>
          </ContentWrapper>
        </ContentBg>
      </ComponentVersionsInherit>
    </SectionContainer>
  )
}

export default Manage

import {
  AlertCircleOutlineIcon,
  ArrowRightOutlineIcon,
  CheckBrokenOutlineIcon,
  FileCheckOutlineIcon,
  SendOutlineIcon,
  UserProfileIcon,
} from 'opensolar-ui'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import { EventActions, EventFormVariant, FormVariations } from 'types/events'
import { formatDate } from 'util/date'
import NotesContent from './formVariants/NotesContent'
import SpecialEventContent from './formVariants/SpecialEventContent'
import TaskFormContent from './formVariants/TaskFormContent'

export const getEventsData = (events) => {
  if (!events) {
    return {}
  }

  const today = new Date()
  const yesterday = new Date(new Date().setDate(today.getDate() - 1))
  const olderEvents = events.filter((x) => {
    const eventDate = new Date(x.created_date)
    return yesterday.getDate() !== eventDate.getDate() && today.getDate() !== eventDate.getDate()
  })

  const eventsByDate = olderEvents.reduce((grouped, object) => {
    const { created_date } = object
    const formattedDate = formatDate(created_date)
    if (!grouped[formattedDate]) {
      grouped[formattedDate] = []
    }
    grouped[formattedDate].push(object)
    return grouped
  }, {})

  return {
    today: events.filter((x) => {
      const eventDate = new Date(x.created_date)
      return today.getDate() === eventDate.getDate()
    }),
    yesterday: events.filter((x) => {
      const eventDate = new Date(x.created_date)
      return yesterday.getDate() === eventDate.getDate()
    }),
    ...eventsByDate,
  }
}

export const EVENT_FORM_VARIATIONS: FormVariations = {
  //format: [included_field_name]: [is_editable]
  3: {
    label: 'Task',
    content: <TaskFormContent />,
    actions: [EventActions.archive, EventActions.edit, EventActions.delete],
    validate: (values) => {
      let errors: any = {}
      if (values.start && values.end) {
        const startDate = new Date(values.start)
        const endDate = new Date(values.end)
        if (startDate > endDate) errors.start = 'Start date cannot be greater than the end date.'
      }
      return errors
    },
  },
  4: {
    content: <SpecialEventContent />,
    actions: [EventActions.edit],
  },
  6: {
    label: 'Note',
    content: <NotesContent />,
    actions: [EventActions.edit, EventActions.delete],
  },
}

export const getEventIcon = (ref, isXs = false) => {
  const defaultSize = isXs ? 12 : 14
  const icons = {
    1: <SendOutlineIcon variant={1} size={isXs ? 18 : 20} color={COLOR_PALETTE.darkGrey} />,
    2: <UserProfileIcon size={defaultSize} color={COLOR_PALETTE.darkGrey} />,
    3: <CheckBrokenOutlineIcon size={defaultSize} color={COLOR_PALETTE.darkGrey} />,
    4: <FileCheckOutlineIcon variant={1} size={defaultSize} color={COLOR_PALETTE.darkGrey} />,
    5: <AlertCircleOutlineIcon size={defaultSize} color={COLOR_PALETTE.darkRed} />,
    6: <ArrowRightOutlineIcon size={defaultSize} color={COLOR_PALETTE.darkGrey} />,
  }
  return icons[ref]
}

export const EVENT_HIDE_NOTES = [EventFormVariant.project_action, EventFormVariant.special_action]

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import createStandardIcon from './createStandardIcon';
var InformationCircleIconSvg = function (props) {
    var fill = props.fill;
    return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" }, props, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.0004 19.5999C15.3023 19.5999 19.6004 15.3018 19.6004 9.9999C19.6004 4.69797 15.3023 0.399902 10.0004 0.399902C4.69846 0.399902 0.400391 4.69797 0.400391 9.9999C0.400391 15.3018 4.69846 19.5999 10.0004 19.5999ZM11.2004 5.1999C11.2004 4.53716 10.6631 3.9999 10.0004 3.9999C9.33765 3.9999 8.80039 4.53716 8.80039 5.1999C8.80039 5.86264 9.33765 6.3999 10.0004 6.3999C10.6631 6.3999 11.2004 5.86264 11.2004 5.1999ZM11.2004 9.3999C11.2004 8.73716 10.6631 8.1999 10.0004 8.1999C9.33765 8.1999 8.80039 8.73716 8.80039 9.3999V14.1999C8.80039 14.8626 9.33765 15.3999 10.0004 15.3999C10.6631 15.3999 11.2004 14.8626 11.2004 14.1999V9.3999Z", fill: fill }) })));
};
export var InformationCircleIcon = createStandardIcon(InformationCircleIconSvg);

var Utils = {
  iOS: function () {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
  },
  bearing: function (p1, p2) {
    var dx = p2.x - p1.x
    var dy = p2.y - p1.y

    //full working
    // var angleInDegreesFromHorizontalCounterClockwise = Math.atan2(dy, dx) * 180 / Math.PI
    // var angleInDegreesFromNorthClockwise = -(angleInDegreesFromHorizontalCounterClockwise-90)
    // var angleInDegreesFromNorthClockwiseClamped = (angleInDegreesFromNorthClockwise + 360) % 360
    // return angleInDegreesFromNorthClockwiseClamped

    //compact
    return (-((Math.atan2(dy, dx) * 180) / Math.PI - 90) + 360) % 360
  },
}

export default Utils

export const dispatchSignalObjectChanged = (uuid) => {
  if (uuid) {
    var object = window.editor.objectByUuid(uuid)

    if (object) {
      try {
        window.editor.signals.objectChanged.dispatch(object)
      } catch (err) {}
      //this.setState(this.stateFromObject(object))
    }
  }
}

//copied from PanelModuleGrid - isn't there a way to resuse this code?
export const applyToModuleGrid = (moduleGrid, newAzimuth, newSlope, newPanelConfiguration) => {
  var newPanelTiltOverride

  if (newAzimuth < 0) {
    newAzimuth = null
  }

  if (newSlope < 0) {
    newSlope = null
  }

  if (newPanelConfiguration === 'TILT_RACK' && newPanelConfiguration !== moduleGrid.panelConfiguration) {
    if (!moduleGrid.panelTiltOverride) {
      // We only want to set a default if not yet being used. Do not modify the value if already in use
      newPanelTiltOverride = 20
    }
  }

  if (newPanelConfiguration === 'STANDARD' && newPanelConfiguration !== moduleGrid.panelConfiguration) {
    newPanelTiltOverride = null
  }

  // if (newPanelConfiguration === 'STANDARD') {
  //   //if floating on facet and disabling tilt racks, force clearing of azimuth and slope overrides
  //   if (moduleGrid.facet && moduleGrid.autoAzimuth) {
  //     newAzimuth = null
  //     newSlope = null
  //   } else {
  //     //if not floating on facet and disabling tilt racks, detect azimuth & slope, apply as overrides
  //     newAzimuth = moduleGrid.getAzimuth()
  //     newSlope = moduleGrid.getSlope()
  //   }
  // } else if (newuseTiltRack === true) {
  //   //if clicking on tilt racks, force applying existing values of azimuth and slope, if set
  //   if (moduleGrid.facet) {
  //     newAzimuth = 0
  //     newSlope = 0
  //   } else {
  //     newAzimuth = moduleGrid.getAzimuth()
  //     newSlope = moduleGrid.getSlope()
  //   }
  // } else {
  //   //don't force an update if tilt racks not explicitly supplied
  // }

  //var redrawGrid = false
  var commandUUID = window.Utils.generateCommandUUIDOrUseGlobal()

  if (typeof newAzimuth !== 'undefined') {
    window.editor.execute(new window.SetPanelConfigurationCommand(moduleGrid, 'azimuth', newAzimuth, commandUUID))
  }
  if (typeof newSlope !== 'undefined') {
    window.editor.execute(new window.SetPanelConfigurationCommand(moduleGrid, 'slope', newSlope, commandUUID))
  }
  if (typeof newPanelConfiguration !== 'undefined') {
    window.editor.execute(
      new window.SetPanelConfigurationCommand(moduleGrid, 'panelConfiguration', newPanelConfiguration, commandUUID)
    )
    //redrawGrid = true
  }

  if (typeof newPanelTiltOverride !== 'undefined') {
    window.editor.execute(
      new window.SetPanelConfigurationCommand(moduleGrid, 'panelTiltOverride', newPanelTiltOverride, commandUUID)
    )
  }

  // var azimuth = moduleGrid.getAzimuth()
  // var slope = moduleGrid.useTiltRack ? 0 : moduleGrid.getSlope()

  // window.Utils.applyOrientation(moduleGrid, azimuth, slope)

  // if (redrawGrid) {
  //   moduleGrid.draw(true)
  // }

  dispatchSignalObjectChanged(moduleGrid.uuid)
}
window.applyToModuleGrid = applyToModuleGrid

// Method for preventing textfield-typing-in-progress
// from being overwritten during reloading other data
// Must be called with .call(this, _state)
//
// Requirements include:
//
//     1. Each TextField needs:
//     onFocus={() => {
//       markFieldActive.call(_this, 'addersRaw', objectBeingUpdated)
//     }}
//
//     2. Each TextField needs:
//     onBlur={() => {
//       markFieldInactive.call(_this)
//     }}
//
//     3. In stateFromObject() add stripKeyForActiveTextField.call(this, _state, objectBeingUpdated)
//
//     5. In render() add var _this = this
//
//     6. Add to componentWillUnmount: clearTypingInFieldOnUnmount.call(this)
//

export const stripKeyForActiveTextField = function (_state, object) {
  if (
    this.state &&
    this.state.typingInField &&
    this.state.hasOwnProperty(this.state.typingInField) &&
    this.state.typingInFieldForObjectUuid === object.uuid
  ) {
    // Typing in field matching state.{object}.field.
    // Remove field from state, retain current textfield value
    delete _state[this.state.typingInField]
  }
}

function preventDefault(e) {
  e = e || window.event
  if (e.preventDefault) e.preventDefault()
  e.returnValue = false
}
export const disableScroll = () => {
  if (window.addEventListener)
    // older FF
    // window.addEventListener('DOMMouseScroll', preventDefault, { capture: false, passive: false })
    window.isScrollDisabled = true
  document.addEventListener('wheel', preventDefault, { capture: false, passive: false }) // Disable scrolling in Chrome
  // window.onwheel = preventDefault // modern standard
  // window.onmousewheel = document.onmousewheel = preventDefault // older browsers, IE
}

export const enableScroll = () => {
  if (window.removeEventListener)
    // window.removeEventListener('DOMMouseScroll', preventDefault, { capture: false, passive: false })
    window.isScrollDisabled = false
  document.removeEventListener('wheel', preventDefault, { capture: false, passive: false }) // Enable scrolling in Chrome
  // window.onmousewheel = document.onmousewheel = null
  // window.onwheel = null
}

export const markFieldActive = function (fieldName, object) {
  this &&
    this.setState({
      typingInField: fieldName,
      typingInFieldForObjectUuid: object ? object.uuid : null,
    })
  disableScroll()
  window.editor.signals.typingInField.dispatch(true)
}

export const markFieldInactive = function () {
  this &&
    this.setState({
      typingInField: null,
      typingInFieldForObjectUuid: null,
    })
  enableScroll()
  window.editor.signals.typingInField.dispatch(false)
}

export const clearTypingInFieldOnUnmount = function () {
  // We triggered typingInField but never cleared it and now we are unmounting!
  // Clear it now
  if (this.state.typingInField) {
    window.editor.signals.typingInField.dispatch(false)
  }
  //enable scrolling
  enableScroll()
}

// export const referenceSave = function (name, refreshOnSignalTypes) {
//   window.Designer.uiRefs[name] = this
//   this.uiRef = name

//   if (refreshOnSignalTypes && refreshOnSignalTypes.length > 0) {
//     referenceRefreshOnSignalTypes.call(this, true, refreshOnSignalTypes)
//   }
// }

// export const referenceClear = function (name) {
//   if (this) referenceRefreshOnSignalTypes.call(this, false)
//   if (this?.uiRef) name = this?.uiRef

// Do not clear uiRefs['Panel'] because otherwise a timing issue can cause another correctly
// created Panel to clear the shared uiRef['Panel'] i.e. Each panel registers uiRefs['Panel']
// for itself. Ideally we should re-instate clearing references for unmounted components
// but fix the order so a mounted component will never have it's reference cleared
// by another component being mounted.
// Best solution is probably to manually switch to find the correct panel
// rather than using a shared reference.
//   if (name && name !== 'Panel') {
//     window.Designer.uiRefs[name] = null
//   }
// }

export const referenceRefreshOnSignalTypes = function (active, signalTypes) {
  if (!window.editor) {
    throw new Error('Trying to add signals but window.editor not ready')
  }

  // When removing we auto-detect signalTypes to remove, no need to supply again
  if (typeof signalTypes === 'undefined' && this?.signalTypes?.length > 0) {
    signalTypes = this.signalTypes
  } else {
    //signal types are supplied so save them locally so they can be removed later
    this.signalTypes = signalTypes
  }

  // We need to bind refresh to `this`, so store it on the element the first time
  // Need to only bind once to ensure we can remove it
  if (active === true && !this.refreshPanelBound) {
    this.refreshPanelBound = this.refreshPanel.bind(this)
  }

  if (signalTypes) {
    for (var i = 0; i < signalTypes.length; i++) {
      //if signals not present skip binding the actual signals. e.g. In Storybooks
      if (!window.editor || !window.editor.signals || !window.editor.signals[signalTypes[i]]) {
        continue
      }

      if (active) {
        window.editor.signals[signalTypes[i]].add(this.refreshPanelBound)
      } else {
        window.editor.signals[signalTypes[i]].remove(this.refreshPanelBound)
      }
    }
  }
}

export const refreshPanelGeneric = function (objectType, newState) {
  if (window.Designer && !window.Designer.allowUiUpdates()) {
    return
  }

  if (window.editor.uiPauseLocks.ui.length > 0) {
    // react rendering paused
    return
  }

  // Optimisation: We could let this flow through to stateFromObject but this allows
  // completely avoiding setState() if already invisible
  // If we should be invisible
  // Ignore if currently not visible and no reason to become visible

  var objectTypeMatches = false

  if (!objectType) {
    objectTypeMatches = true
  } else if (window.editor && window.editor.selected) {
    if (objectType.constructor === Array) {
      objectTypeMatches = objectType.indexOf(window.editor.selected.type) !== -1
    } else {
      objectTypeMatches = window.editor.selected.type === objectType
    }
  }

  if (objectTypeMatches !== true) {
    //we should be invisible
    if (this.state.visible === false) {
      //already invisible, no need to change any state
      return
    } else {
      this.setState({ visible: false })
      markFieldInactive.call(this)
      return
    }
  }

  // If we should be visible
  if (newState) {
    this.setState(newState)
  } else {
    this.setState(this.stateFromObject(window.editor.selected))
  }
}

export const classifyDirection = (inclination, bearing) => {
  return window.Designer.classifyDirection(inclination, bearing)
}

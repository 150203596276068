import { makeStyles } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import { generateDocument, generateDocumentSelectors } from 'ducks/generateDocument'
import Alert from 'elements/Alert'
import Button from 'elements/proUXButtons/ProUXButton'
import LoadingDots from 'layout/widgets/LoadingDots'
import { Switch } from "opensolar-ui"
import React, { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { ProjectType } from 'types/projects'
import { formatCurrency } from '../../../util/misc'
import { greenLancerServices, ironRidgeGreenLancerServices } from './constants'
import { AccountFormToShowType } from './types'

const useStyles = makeStyles({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: '10px 0px',
    borderBottom: '1px solid #ececec',
  },
  rowTitles: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    fontWeight: 900,
    padding: '10px 0px',
  },
  title: {
    flex: 4,
  },
  price: {
    flex: 1,
  },
  check: {
    flex: 1,
  },
  buttonView: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  error: {
    width: '80%',
    textAlign: 'center',
    color: 'red',
    margin: '10px 0px',
  },
  sampleFileWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '30px 0px',
    minHeight: '70px',
  },
})

type serviceType = {
  title: string
  id: string
  price: number
}

type PropTypes = {
  saveProject: (ids: string[]) => void
  projectLoading: boolean
  accountFormToShow: AccountFormToShowType
  errorMsg: string | null
  project: ProjectType
  isIronRidge: boolean
}

const GreenLancerServicesTable: React.FunctionComponent<PropTypes> = (props) => {
  const [selectedServiceIds, setSelectedServiceIds] = useState<string[]>([])
  const [services, setServices] = useState<serviceType[]>([])

  useEffect(() => {
    if (props.isIronRidge) setServices(ironRidgeGreenLancerServices)
    else setServices(greenLancerServices)
  }, [props.isIronRidge])

  const translate = useTranslate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const docType = 'greenlancer_project_summary'

  const [docId, setDocId] = useState<number | undefined>()

  const orgId = useSelector((state: any) => state.auth.org_id)

  const doc = useSelector(generateDocumentSelectors.getDocById)(docId)

  const handleChange = (event: any) => {
    if (event?.target.checked) {
      if (!selectedServiceIds.includes(event.target.name))
        setSelectedServiceIds([...selectedServiceIds, event.target.name])
    } else {
      setSelectedServiceIds(selectedServiceIds.filter((serviceId) => serviceId !== event.target.name))
    }
  }

  const generateSamplePDF = (event) => {
    event.preventDefault()
    const doc = generateDocument(orgId, props.project.id, docType)
    setDocId(doc.payload.id)
    dispatch(doc)
  }

  return (
    <div style={{ width: '90%', marginBottom: '20px' }}>
      <div className={classes.rowTitles}>
        <div className={classes.title}>Service Name</div>
        <div className={classes.price}>Price</div>
        <div className={classes.check}></div>
      </div>
      {services.map((service) => {
        return (
          <div key={service.id} className={classes.row}>
            <div className={classes.title}>{service.title}</div>
            <div className={classes.price}>${formatCurrency(service.price)}</div>
            <div className={classes.check}>
              <Switch checked={selectedServiceIds.includes(service.id)} onChange={handleChange} name={service.id} />
            </div>
          </div>
        )
      })}
      {doc?.status !== 'loading' && doc?.status !== 'success' && (
        <div>
          <div style={{ margin: '30px 0px 30px 0px' }}>
            <Alert severity="info">
              OpenSolar will share basic Project and Contact data with GreenLancer for the purpose of streamlining the
              planset process.
            </Alert>
            <p>
              <Link href="#" onClick={generateSamplePDF} underline="always" color="inherit">
                Click here to see the project summary we will share with GreenLancer
              </Link>
            </p>
          </div>
        </div>
      )}
      {doc?.status === 'loading' && (
        <div className={classes.sampleFileWrapper}>
          <p style={{ opacity: 0.6 }}>
            <i>Generating a sample project summary file...</i>
          </p>
          <LoadingDots />
        </div>
      )}
      {doc?.status === 'success' && (
        <div className={classes.sampleFileWrapper}>
          <a href={doc?.downloadUrl} target="_blank" rel="noopener noreferrer">
            Click here to view the sample project summary
          </a>
        </div>
      )}
      <div className={classes.buttonView}>
        {props.errorMsg && <div className={classes.error}>{props.errorMsg}</div>}
        <Button
          label={translate('Send Project')}
          type="primary"
          onClick={() => props.saveProject(selectedServiceIds)}
          loading={props.projectLoading}
          disabled={props.projectLoading || !props.project.system_sold}
        />
        {!props.project.system_sold && (
          <p>
            <i>{translate('Planset can only be ordered after a sold system has been selected')}</i>
          </p>
        )}
      </div>
    </div>
  )
}
export default GreenLancerServicesTable

import { BomGeneratorAbstract } from '../../bom_generators/BomGeneratorAbstract'
import {
  HorizontalRailPosition,
  PerRowRailBomGeneratorAbstract,
} from '../../bom_generators/PerRowRailBomGeneratorAbstract'
import {
  BasicSpacingLayoutAdjusterAbstract,
  SpacingRuleSet,
} from '../../layout_adjusters/BasicSpacingLayoutAdjusterAbstract'
import { MountingSystemAbstract } from '../../MountingSystemAbstract'
import { CutAndSplicerAbstract } from '../../post_processors/CutAndSplicerAbstract'
import { RoofHookAdderAbstract } from '../../post_processors/RoofHookAdderAbstract'
import {
  CompatibilityParameters,
  Component,
  Direction,
  Fastener,
  Item,
  MountingCalcInput,
  MountingCalcResult,
  MountingComponentType,
  Panel,
  PanelBlock,
  RailComponent,
  RailType,
} from '../../types'
import { filterByType, findProd } from '../MSPitchedProLine/MSPitchedProLine_products'
import { gmbhTrapProducts } from './MSTrapezeProLine_products'

const spacingRuleSet: SpacingRuleSet = {
  interPanel: { horizontalSpacing: 19, verticalSpacing: 19 },
}
class MSTrapezeProLineSpacingAdjuster extends BasicSpacingLayoutAdjusterAbstract {
  spacingRuleSet = spacingRuleSet
}

const railOverhang = 100 // TODO
class MSTrapezeProLineBomGenerator extends BomGeneratorAbstract {
  async generateBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    const starterBom = await this.generateStarterBom(result)
    return this.postProcess(starterBom)
  }

  generateStarterBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    return this.generateBomFrom(MSTrapezeProLineHorizontalRailBomGenerator, result)
  }

  postProcess(result: MountingCalcResult): MountingCalcResult {
    const resultWithRoofHooks = new MSTrapezeProLineRoofHookAdder(this.input).process(result)
    const prodType = findProd(gmbhTrapProducts, this.input.options.mountingRail).sub_type
    if (prodType === 'continuous') {
      return new MSTrapezeProLineRailSplicer(this.input).process(resultWithRoofHooks)
    } else {
      return resultWithRoofHooks
    }
  }
}
export class MSTrapezeProLine extends MountingSystemAbstract {
  layoutAdjusterClass = MSTrapezeProLineSpacingAdjuster
  bomGeneratorClass = MSTrapezeProLineBomGenerator

  getFastener(): Fastener {
    return chooseFastener()
  }

  getCompatibilityParameters(): CompatibilityParameters {
    return {
      integrations: ['HDM'],
      roofTypes: ['Kliplock', 'Metal Standing Seam', 'Metal Tin'],
      roofTypeRequired: false,
      slopeRange: [5, 45],
      featureFlag: 'mounting_MSTrapezeProLine',
      moduleThicknessRange: [30, 40],
    } as CompatibilityParameters
  }
}

class MSTrapezeProLineHorizontalRailBomGenerator extends PerRowRailBomGeneratorAbstract {
  chooseRail() {
    return chooseRail(this.input, 'horizontal')
  }

  chooseMidClamp() {
    return chooseMidClamp(this.input)
  }

  chooseEndClamp() {
    return chooseEndClamp(this.input)
  }

  chooseEndCap() {
    return null
  }

  getRailPositions(panel: Panel): HorizontalRailPosition[] {
    const distanceFromRailToPanelEdge = (panel.height * 0.5) / 2

    return [
      {
        top: distanceFromRailToPanelEdge,
        left: -railOverhang,
        right: railOverhang,
      },
      {
        top: panel.height - distanceFromRailToPanelEdge,
        left: -railOverhang,
        right: railOverhang,
      },
    ]
  }
}

function chooseRail(input: MountingCalcInput, direction: Direction): RailComponent {
  const prod = findProd(gmbhTrapProducts, input.options.mountingRail) as any
  return {
    type: MountingComponentType.RAIL,
    railType: prod.sub_type,
    direction,
    name: prod.value,
    length: 0,
    top: 0,
    left: 0,
  }
}

function chooseMidClamp(input: MountingCalcInput): Item {
  const prod = filterByType(gmbhTrapProducts, 'midClamp')[0]
  return {
    name: prod.value,
    components: [
      {
        name: prod.value,
        type: MountingComponentType.CLAMP,
        left: -10,
        top: -10,
      },
    ],
  }
}

function chooseEndClamp(input: MountingCalcInput): Item {
  const prod = filterByType(gmbhTrapProducts, 'endClamp')[0]
  return {
    name: prod.value,
    components: [
      {
        name: prod.value,
        type: MountingComponentType.CLAMP,
        left: -10,
        top: -10,
      },
    ],
  }
}

function chooseHook(input: MountingCalcInput, panelBlock: PanelBlock | null, rail: RailComponent) {
  const prod = filterByType(gmbhTrapProducts, 'roofHook')[0]
  return [
    {
      name: prod.value,
      components: [
        {
          name: prod,
          type: MountingComponentType.ROOF_HOOK,
          left: -10,
          top: -10,
        },
      ],
    },
    {
      name: prod.value,
      components: [
        {
          name: prod.value,
          type: MountingComponentType.ROOF_HOOK,
          left: -10,
          top: -10,
        },
      ],
    },
  ]
}

function chooseFastener(): Fastener {
  const prod = filterByType(gmbhTrapProducts, 'fastener')[0]
  return {
    includedInRoofHookProduct: false,
    name: prod.value,
    qtyPerRoofHook: 1,
    length: 25,
    diameter: 5.5,
    components: [],
  }
}

class MSTrapezeProLineRoofHookAdder extends RoofHookAdderAbstract {
  spacingRuleSet = spacingRuleSet
  railOverhang = railOverhang

  getRailType(input: MountingCalcInput) {
    const prod = findProd(gmbhTrapProducts, input.options.mountingRail)

    return prod.sub_type as RailType
  }

  chooseHook(input: MountingCalcInput, panelBlock: PanelBlock, rail: RailComponent) {
    return chooseHook(input, panelBlock, rail)
  }

  chooseFastener(hook: Item[]) {
    return chooseFastener()
  }
}

class MSTrapezeProLineRailSplicer extends CutAndSplicerAbstract {
  isTarget(component: Component): boolean {
    return component?.type === MountingComponentType.RAIL
  }

  chooseSplice(railName: string, input: MountingCalcInput): Item[] {
    const prod = filterByType(gmbhTrapProducts, 'railSplice')[0]
    return [
      {
        name: prod.value,
        components: [
          {
            type: MountingComponentType.SPLICE,
            name: '',
            top: 0,
            left: 0,
          },
        ],
      },
    ]
  }

  getFullLength(railName: string): number {
    return 6000
  }
}

import { useCallback, useState } from 'react'
import { useDataProvider } from 'react-admin'
import composeUrlWithQueryParams from 'util/fetch/composeUrlWithQueryParams'
import type { ActivityDataType } from '../type'

export type LoadActivityDataFilters = {
  q: string
}

export type LoadActivityDataOptions = {
  filters?: LoadActivityDataFilters
}

type InventoryDataResponse = {
  loadingActivityData: boolean
  activityData: ActivityDataType | undefined
  loadActivityData: (options?: LoadActivityDataOptions) => Promise<void>
}

const useActivityData = (): InventoryDataResponse => {
  const [activityData, setActivityData] = useState<ActivityDataType | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dataProvider = useDataProvider()

  const loadActivityData = useCallback(async (options?: LoadActivityDataOptions) => {
    setIsLoading(true)
    await dataProvider
      .CUSTOM_GET(
        'inventory_activity/',
        {
          url: composeUrlWithQueryParams({
            baseUrl: 'inventory_activity',
            optionalParams: { search: options?.filters?.q },
          }),
        },
        {
          onSuccess: ({ data }: { data: ActivityDataType }) => {
            setActivityData(data)
          },
          onFailure: (e) => {
            console.warn('e', e)
          },
        }
      )
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return {
    activityData,
    loadingActivityData: isLoading,
    loadActivityData,
  }
}

export default useActivityData

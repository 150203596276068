import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles((theme) => ({
  paperStyle: {
    padding: 20,
    marginBottom: 20,
    marginTop: 20,
  },
  defaultFieldStyle: {
    marginBottom: 0,
    marginTop: 0,
  },
  tableStyles: {
    '& .MuiTableCell-root': {
      border: 'none',
      padding: 5,
    },
  },
}))

export default useStyle

import { useCallback, useEffect, useRef, useState } from 'react'

const useIsFirstRender = () => {
  const [renderCount, setRenderCount] = useState<number>(0)
  const isFirstRender = useRef<boolean>(true)

  useEffect(() => {
    if (renderCount === 0) {
      setRenderCount(renderCount + 1)
    } else {
      isFirstRender.current = false
    }
  })

  return useCallback(() => {
    return isFirstRender.current
  }, [])
}

export default useIsFirstRender

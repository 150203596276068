var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import createStandardIcon from './createStandardIcon';
var LockOutlineIconSVG = function (props) {
    var fill = props.fill;
    return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" }, props, { children: _jsx("path", { d: "M6.75306 21.5C6.2544 21.5 5.8284 21.3234 5.47506 20.9703C5.1219 20.6169 4.94531 20.1909 4.94531 19.6923V10.3077C4.94531 9.80908 5.1219 9.38308 5.47506 9.02975C5.8284 8.67658 6.2544 8.5 6.75306 8.5H7.94531V6.5C7.94531 5.25133 8.38315 4.18917 9.25881 3.3135C10.1345 2.43783 11.1966 2 12.4453 2C13.694 2 14.7561 2.43783 15.6318 3.3135C16.5075 4.18917 16.9453 5.25133 16.9453 6.5V8.5H18.1376C18.6362 8.5 19.0622 8.67658 19.4156 9.02975C19.7687 9.38308 19.9453 9.80908 19.9453 10.3077V19.6923C19.9453 20.1909 19.7687 20.6169 19.4156 20.9703C19.0622 21.3234 18.6362 21.5 18.1376 21.5H6.75306ZM6.75306 20H18.1376C18.2274 20 18.3011 19.9712 18.3588 19.9135C18.4165 19.8558 18.4453 19.7821 18.4453 19.6923V10.3077C18.4453 10.2179 18.4165 10.1442 18.3588 10.0865C18.3011 10.0288 18.2274 10 18.1376 10H6.75306C6.66323 10 6.58948 10.0288 6.53181 10.0865C6.47415 10.1442 6.44531 10.2179 6.44531 10.3077V19.6923C6.44531 19.7821 6.47415 19.8558 6.53181 19.9135C6.58948 19.9712 6.66323 20 6.75306 20ZM12.4453 16.75C12.9311 16.75 13.3443 16.5798 13.6848 16.2395C14.0251 15.899 14.1953 15.4858 14.1953 15C14.1953 14.5142 14.0251 14.101 13.6848 13.7605C13.3443 13.4202 12.9311 13.25 12.4453 13.25C11.9595 13.25 11.5463 13.4202 11.2058 13.7605C10.8655 14.101 10.6953 14.5142 10.6953 15C10.6953 15.4858 10.8655 15.899 11.2058 16.2395C11.5463 16.5798 11.9595 16.75 12.4453 16.75ZM9.44531 8.5H15.4453V6.5C15.4453 5.66667 15.1536 4.95833 14.5703 4.375C13.987 3.79167 13.2786 3.5 12.4453 3.5C11.612 3.5 10.9036 3.79167 10.3203 4.375C9.73698 4.95833 9.44531 5.66667 9.44531 6.5V8.5Z", fill: fill }) })));
};
export var LockOutlineIcon = createStandardIcon(LockOutlineIconSVG);

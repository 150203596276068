import TourTooltip from 'elements/tooltip/TourTooltip'
import { TourConfigType } from 'persistentContent/tours/types'
import { useTranslate } from 'ra-core'
import { FC } from 'react'

const StepTooltip: FC = (props) => {
  const translate = useTranslate()
  return (
    <TourTooltip
      title={translate('Your Activities')}
      subtext={translate(
        "Track all your project activities, tasks, and notes in the Project History. View your project's complete history or sort updates by type in dedicated tabs."
      )}
      {...props}
    />
  )
}

const Activities: TourConfigType = {
  spotlights: [{ targetId: 'Project-Activities-Section', autoScroll: true }],
  tooltips: [
    {
      targetId: 'Project-Activities-Section',
      placement: 'bottom-start',
      component: StepTooltip,
      options: { accountScroll: true },
    },
  ],
}

export default Activities

import { SystemContext } from 'Designer/designRules/types'
import { handleAddComponent as addComponent } from 'projectSections/sections/design/util/handleAddComponent'
import { showNotification, SHOW_NOTIFICATION } from 'ra-core'
import { AnyComponentType } from 'types/components'
import { ComponentTypes, NonModuleComponentTypes } from 'types/selectComponent'
import { EffectFactory } from '.'
import { getActiveComponentByCode } from '../utils'

export type AddComponentEffectType = {
  type: 'add-component-by-code'
  componentType: NonModuleComponentTypes
  code: string
  hideNotifications?: boolean

  // Currently 'quantity' is only supported to 'other' components, and adds a static amount of components to the system (regardless of how many already exist)
  quantity?: number
}

export const effect_addComponentByCode: EffectFactory<AddComponentEffectType> = (def) => {
  return {
    execute: async (context: SystemContext) => {
      let quantity = def.quantity || 1
      if (def.componentType !== 'other' && quantity > 1) {
        console.warn(
          "DesignRules: add-component-by-code effect currently only supports quantity for 'other' components."
        )
        quantity = 1
      }
      try {
        await activateAndAddComponentToSystem(def.code, def.componentType, quantity, context, !def.hideNotifications)
      } catch (err) {
        // Error activating component, already handled upstream
      }
    },
  }
}

export const activateAndAddComponentToSystem = (
  code: string,
  type: ComponentTypes,
  quantity: number,
  context: SystemContext,
  showNotifications: boolean,
  callback?: Function
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const component = getActiveComponentByCode(type, code, context)
    if (component) {
      // component is found for account
      addComponentToSystem(component, type, quantity, context.system.uuid)

      if (showNotifications)
        context.dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            message: 'Component assigned to system',
          },
        })

      if (callback) callback()
      resolve()
    } else if (context.role?.is_admin) {
      // component is not found for account, attempt to activate then add
      activateComponentForAccount(
        code,
        () => {
          const component = getActiveComponentByCode(type, code, context)
          addComponentToSystem(component, type, quantity, context.system.uuid)
          if (callback) callback()
          resolve()
        },
        () => {
          reject()
        }
      )

      if (showNotifications) context.dispatch(showNotification('Component activated on account & assigned to system.'))
    } else {
      if (window.debugDesignRules)
        console.debug(`\tDesignRules: Skipping adding of un-activated component: code: ${code}`)
      reject()
    }
  })
}

const addComponentToSystem = (
  component: AnyComponentType | undefined,
  type: ComponentTypes,
  quantity: number,
  systemUuid: string
) => {
  if (window.editor && component) {
    addComponent(type, systemUuid, { [type + 'Id']: component.id, quantity })
  }
}

const activateComponentForAccount = (code: string, callback: () => void, callbackError: () => void) => {
  window.AccountHelper.activateComponents([code], callback, callbackError)
}

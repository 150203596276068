import { Tooltip, useMediaQuery } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import { permissionsSelectors } from 'ducks/permissions'
import { setSelectComponentDialog } from 'ducks/selectComponent'
import { Button } from 'opensolar-ui'
import React, { FC, useCallback } from 'react'
import { changeListParams, useNotify, useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { AnyComponentType } from 'types/components'
import { ComponentTypes } from 'types/selectComponent'
import { RootState } from 'types/state'
import { Theme } from 'types/themes'
import { DialogHelper } from 'util/misc'
import { useAddComponentToSystem } from '../../hooks/components/useAddComponentToSystem'
import useTranslateWithVariable from '../../projectSections/hooks/useTranslateWithVariable'
import { RECORDS_PER_PAGE, plural } from './HardwareSelector'

type PropsType = {
  record: any
  activeComponentsData: AnyComponentType[]
}

const SelectButton: FC<PropsType> = ({ record, activeComponentsData }) => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const notify = useNotify()
  const translate = useTranslate()
  const translateWithVariable = useTranslateWithVariable()
  const addComponentToSystem = useAddComponentToSystem()
  const { allowCreate } = useSelector(permissionsSelectors.getProjectPermissionByKey('components'))
  const buttonSize = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs')) ? 'small' : 'medium'

  const { componentType, org, targetUuids, slotKey } = useSelector((state: RootState) => ({
    componentType: state.selectComponent?.componentType as ComponentTypes,
    targetUuids: state.selectComponent && state.selectComponent.targetUuids,
    org: orgSelectors.getOrg(state),
    slotKey: state.selectComponent?.slotKey,
  }))

  const handleSelect = useCallback(
    (componentActivationId, componentId) => {
      function closeDialog() {
        dispatch(setSelectComponentDialog(false, null, null, []))
      }
      return addComponentToSystem({
        componentType,
        componentActivationId,
        componentId,
        activeComponentsData,
        targetUuids,
        slotKey,
        closeDialog,
      })
    },
    [componentType, targetUuids, activeComponentsData]
  )

  if (allowCreate || record.componentActivationId) {
    return (
      <Button
        variant="contained"
        size={buttonSize}
        onClick={() => {
          DialogHelper.beforeClose()
          handleSelect(record.componentActivationId, record.id).then(
            () => {
              history.push(location.pathname)
              dispatch(
                changeListParams('component_' + plural(componentType as string), {
                  sort: null,
                  order: null,
                  page: 1,
                  perPage: RECORDS_PER_PAGE,
                  filter: {},
                })
              )
            },
            () => {
              //do nothing, leave dialog open
            }
          )
        }}
      >
        <span>{translate('Select')}</span>
      </Button>
    )
  } else {
    return (
      <Tooltip
        title={translate('Request admin to activate this component or to update your role permissions')}
        PopperProps={{ style: { zIndex: 2001 } }}
        placement="top"
        arrow
      >
        <Button
          variant="outlined"
          style={{ background: 'rgba(0, 0, 0, 0.12)', color: 'rgba(0, 0, 0, 0.26)', cursor: 'unset' }}
          size={buttonSize}
          onClick={() => {
            // Required for touch devices who will not see the tooltip and will probably click the button
            notify(
              translateWithVariable('Please contact your admin at %{org_name} to activate a component', {
                org_name: org?.name || '',
              }),
              'warning'
            )
          }}
        >
          <span>{translate('Select')}</span>
        </Button>
      </Tooltip>
    )
  }
}

export default SelectButton

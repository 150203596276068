import { electricalSelectors, setElectricalDialog } from 'ducks/electrical'
import { StyledDialog, StyledDialogContent, StyledDialogTitle } from 'layout/StyledDialog'
import React, { FC, useCallback, useEffect } from 'react'
import { useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { DialogHelper } from 'util/misc'
import InverterElectricalComponents from './InverterElectricalComponents'

const useStyles = makeOpenSolarStyles((theme) => ({
  paper: {
    width: '50%',
    [theme.breakpoints.down('lg')]: {
      width: '70%',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '10px',
      width: '90%',
    },
  },
  dialog: {
    margin: 0,
    width: '100%',
  },
  closeIconButton: {
    top: 10,
    right: 10,
    position: 'absolute',
    minWidth: 40,
    backgroundColor: 'rgba(255, 255, 255, 0)',
  },
  closeIcon: {
    color: '#000000',
  },
}))

const ElectricalDialog: FC = () => {
  const classes = useStyles()
  const translate = useTranslate()
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const isOpen = useSelector(electricalSelectors.getIsOpen)
  const inverter = useSelector(electricalSelectors.getInverter)
  const system = inverter?.getSystem()
  useEffect(() => {
    if (isOpen) {
      DialogHelper.afterOpen('DialogElectrical')
    }
  }, [isOpen])
  const handleClose = useCallback(() => {
    DialogHelper.beforeClose()
    dispatch(setElectricalDialog(false, inverter))
    history.push(location.pathname)
  }, [location.pathname])
  return (
    <StyledDialog
      id="electrical-dialog"
      maxWidth="lg"
      open={isOpen}
      onBackdropClick={handleClose}
      classes={{ paper: classes.paper }}
    >
      <div id="electrical-header">
        <StyledDialogTitle onClose={handleClose}>{translate('Electrical components')}</StyledDialogTitle>
      </div>
      <StyledDialogContent>
        {system && inverter && <InverterElectricalComponents system={system} inverterUuid={inverter.uuid} />}
      </StyledDialogContent>
    </StyledDialog>
  )
}

export default ElectricalDialog

import { PaymentRequestType } from 'pages/cashFlow/types'
import { ReduxActionType } from 'types/global'
import { ExpectedMilestonePaymentType } from 'types/paymentOptions'
import { RootState } from 'types/state'

export const SET_REFRESH_MILESTONE_TRIGGER = 'SET_REFRESH_MILESTONE_TRIGGER'
export const SET_SDK_TOKEN_RERESH = 'SET_SDK_TOKEN_RERESH'
export const SET_PROJECT_MILESTONES_LOADING = 'SET_PROJECT_MILESTONES_LOADING'
export const SAVE_PROJECT_MILESTONES = 'SAVE_PROJECT_MILESTONES'
export const CLEAR_PROJECT_MILESTONES = 'CLEAR_PROJECT_MILESTONES'
export const SAVE_EXPECTED_MILESTONE_PAYMENTS = 'EXPECTED_MILESTONE_PAYMENTS'

export type ProjectMilstonesReduxType = {
  refreshMilestoneTrigger: string | undefined
  sdkTokenRefresh: string | undefined
  projectMilestones: PaymentRequestType[] | undefined
  expectedMilestonePayments: ExpectedMilestonePaymentType[] | undefined
  milestonesAreLoading: boolean
}

const initialState: ProjectMilstonesReduxType = {
  refreshMilestoneTrigger: undefined,
  sdkTokenRefresh: undefined,
  projectMilestones: undefined,
  expectedMilestonePayments: undefined,
  milestonesAreLoading: false,
}

export const triggerMilestoneRefresh = () => {
  return {
    type: SET_REFRESH_MILESTONE_TRIGGER,
  }
}

export const triggerSDKTokenRefresh = () => {
  return {
    type: SET_SDK_TOKEN_RERESH,
  }
}

export const saveProjectMilestones = (milestones: PaymentRequestType[]) => {
  return {
    type: SAVE_PROJECT_MILESTONES,
    payload: { milestones },
  }
}

export const clearProjectMilestones = () => {
  return {
    type: CLEAR_PROJECT_MILESTONES,
  }
}

export const setProjectMilestonesLoading = () => {
  return {
    type: SET_PROJECT_MILESTONES_LOADING,
  }
}

export const saveExpectedMilestonePayments = (expectedMilestonePayments: ExpectedMilestonePaymentType[]) => {
  return {
    type: SAVE_EXPECTED_MILESTONE_PAYMENTS,
    payload: { expectedMilestonePayments },
  }
}

export default (state = initialState, action: ReduxActionType) => {
  switch (action.type) {
    case SET_REFRESH_MILESTONE_TRIGGER:
      return {
        ...state,
        refreshMilestoneTrigger: `${new Date()}`,
        projectMilestones: undefined,
        milestonesAreLoading: false,
      }
    case SET_SDK_TOKEN_RERESH:
      return {
        ...state,
        sdkTokenRefresh: `${new Date()}`,
      }
    case CLEAR_PROJECT_MILESTONES:
      return {
        ...state,
        projectMilestones: undefined,
        milestonesAreLoading: false,
      }
    case SAVE_PROJECT_MILESTONES:
      return {
        ...state,
        projectMilestones: action.payload.milestones,
        milestonesAreLoading: false,
      }
    case SET_PROJECT_MILESTONES_LOADING:
      return {
        ...state,
        milestonesAreLoading: true,
      }
    case SAVE_EXPECTED_MILESTONE_PAYMENTS:
      return {
        ...state,
        expectedMilestonePayments: action.payload.expectedMilestonePayments
      }
    default:
      return { ...state }
  }
}

export const projectMilestonesSelectors = {
  getRefreshMilestonesTrigger: (state: RootState) => {
    return state.projectMilestones.refreshMilestoneTrigger
  },
  getSDKTokenRefreshTrigger: (state: RootState) => {
    return state.projectMilestones.sdkTokenRefresh
  },
  getProjectMiletones: (state: RootState) => {
    return state.projectMilestones.projectMilestones
  },
  getMilestonesAreLoading: (state: RootState) => {
    return state.projectMilestones.milestonesAreLoading
  },
  getExpectedMilestonePayments: (state: RootState) => {
    return state.projectMilestones.expectedMilestonePayments
  },
}

import { Tooltip } from '@material-ui/core'
import Grow from '@material-ui/core/Grow'
import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/EditOutlined'
import LinkOffIcon from '@material-ui/icons/LinkOffOutlined'
import MailOutlineIcon from '@material-ui/icons/MailOutlineOutlined'
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined'
import PhoneIcon from '@material-ui/icons/PhoneOutlined'
import ViewIcon from '@material-ui/icons/VisibilityOutlined'
import { authSelectors } from 'ducks/auth'
import { IconButton } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { ContactType } from '../../../../types/contacts'

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '10px 0px 20px',
  },
  fullName: {
    marginBottom: '0px',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
  },
  contactActions: {
    display: 'flex',
    flexDirection: 'row',
    flex: 4,
    flexWrap: 'wrap',
    fontWeight: 300,
    fontSize: 12,
  },
  contactIconWrapper: {
    marginRight: '5px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  contactAction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  formActions: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  formActionIconWrapper: {},
  divider: {
    padding: '0px 10px',
  },
  iconButton: {
    color: 'rgba(0, 0, 0, 0.87)',
    padding: 8,
  },
}))

type PropTypes = {
  contact: ContactType
  isNew: boolean
  disableDelete: boolean
  disableEdit: boolean
  removeContact: () => void
  startContactEdit: () => void
}

const ContactDisplayRow: React.FunctionComponent<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const hasEmail = props.contact.email && props.contact.email.length > 0
  const hasPhone = props.contact.phone && props.contact.phone.length > 0
  const myOrgId = useSelector(authSelectors.getOrgId)
  const isOwner = myOrgId === props.contact.org_id

  return (
    <Grow in={true} timeout={props.isNew ? 300 : 0}>
      <div className={classes.main}>
        <div className={classes.fullName}>{props.contact.display}</div>
        <div className={classes.actions}>
          <div className={classes.contactActions}>
            {hasEmail && (
              <div className={classes.contactAction}>
                <div className={classes.contactIconWrapper}>
                  <MailOutlineIcon style={{ width: 18, height: 18 }} />
                </div>
                <a href={`mailto:${props.contact.email}`}>{props.contact.email}</a>
                {hasPhone && <span className={classes.divider}>|</span>}
              </div>
            )}
            {hasPhone && (
              <div className={classes.contactAction}>
                <div className={classes.contactIconWrapper}>
                  <PhoneIcon style={{ width: 18, height: 18 }} />
                </div>
                <a href={`tel:${props.contact.phone}`}>{props.contact.phone}</a>
                <span className={classes.divider}>|</span>
              </div>
            )}
            {hasPhone && (
              <div className={classes.contactAction}>
                <div className={classes.contactIconWrapper}>
                  <MessageOutlinedIcon style={{ width: 18, height: 18 }} />
                </div>
                <a href={`sms:${props.contact.phone}`}>{translate('Send Text')}</a>
              </div>
            )}
          </div>
          <div className={classes.formActions}>
            <div className={classes.formActionIconWrapper}>
              <Tooltip title={translate(isOwner ? 'Edit Contact' : 'View Contact')}>
                <IconButton
                  disabled={props.disableEdit}
                  onClick={props.startContactEdit}
                  className={classes.iconButton}
                >
                  {isOwner ? (
                    <EditIcon style={{ width: 20, height: 20, margin: 0 }} />
                  ) : (
                    <ViewIcon style={{ width: 20, height: 20, margin: 0 }} />
                  )}
                </IconButton>
              </Tooltip>
            </div>
            <div className={classes.formActionIconWrapper}>
              <Tooltip title={translate('Unassign contact from project')}>
                <IconButton disabled={props.disableDelete} onClick={props.removeContact} className={classes.iconButton}>
                  <LinkOffIcon style={{ width: 20, height: 20, margin: 0 }} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </Grow>
  )
}

export default ContactDisplayRow

export const FINANCE_PRODUCT_CHOICES = [
  { id: 'loan', label: 'Loan' },
  { id: 'lease', label: 'Lease' },
  { id: 'bnpl', label: 'BNPL' },
  { id: 'ppa', label: 'PPA' },
]

export const ROLE_VALIDATION_TYPE_CHOICES = [
  { id: null, label: 'Default (Must have an assigned role w/ valid contact info)' },
  { id: 'free_text_email', label: 'Email Required (manually typed in)' },
  { id: 'email_from_api_list', label: 'Select Email from API' },
  { id: 'optional', label: 'Optional (no assigned roles are required at all)' },
]

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
export var DownloadIcon = function (_a) {
    var _b = _a.color, color = _b === void 0 ? 'currentColor' : _b;
    var iconAttributes = {
        stroke: color,
    };
    return (_jsx("svg", __assign({ width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, iconAttributes, { children: _jsx("path", { d: "M2.66669 13.5885C2.93649 13.852 3.30243 14 3.68399 14H12.3161C12.6976 14 13.0635 13.852 13.3334 13.5885M8.00082 2V9.96164M8.00082 9.96164L11.2892 6.91953M8.00082 9.96164L4.71242 6.91953", strokeWidth: "1.2", stroke: color }) })));
};

import appStorage from 'storage/appStorage'
import { STAR_ADD_LOADING, STAR_REMOVE_LOADING } from './stars'
const UPDATE_RECENT_PROJECT = 'UPDATE_RECENT_PROJECT'

//UX2 method
export const updateRecentProject = (projectData) => ({
  type: UPDATE_RECENT_PROJECT,
  meta: { resource: 'projects' },
  payload: {
    data: projectData,
  },
})

// const projectToUpdate = (projects, data) => {
//   var projectsToUpdate = projects.filter((j) => j.id === data.id && j.address !== data.address)
//   return projectsToUpdate.length > 0 ? projectsToUpdate[0] : null
// }

const getInitialState = () => appStorage.getJSON('recentProjects') || []

export default function reducer(projects = getInitialState(), { type, meta, payload }) {
  var project = null
  let doUpdate = false

  if (type === 'RA/CRUD_GET_ONE_SUCCESS' || type === 'RA/CRUD_UPDATE_SUCCESS' || type === UPDATE_RECENT_PROJECT) {
    if (meta.resource === 'projects') {
      // Handle both single and multiple records
      // We don't actually implement any multiple-record situations because we only save as
      // recentProject if we viewed it specifically
      var payload_data_records = payload.data.id ? [payload.data] : payload.data
      if (Array.isArray(payload_data_records)) {
        payload_data_records.forEach((payload_data_record) => {
          if (payload_data_record.id && payload_data_record.address) {
            // If id present but other fields don't match then update it
            //
            // Projects to update
            var _projectToUpdate = payload_data_record

            // Remove any projects which need to be updated, they will be re-added below
            if (_projectToUpdate) {
              projects = projects.filter((j) => j.id !== _projectToUpdate.id)
            }

            if (projects.every((j) => j.id !== payload_data_record.id)) {
              project = {
                org_id: payload_data_record.org_id,
                id: payload_data_record.id,
                contacts_data: payload_data_record.contacts_data,
                address: payload_data_record.address,
                stars: payload_data_record.stars,
                state: payload_data_record.state,
                zip: payload_data_record.zip,
                locality: payload_data_record.locality,
                workflow: payload_data_record.workflow,
              }
              doUpdate = true
            }
          }
        })
      }
    }
  }

  if (type === STAR_REMOVE_LOADING) {
    //listen to star change and update recentProjects
    const projectId = payload.id
    const project = projects.find((p) => p.id === projectId)
    if (project) {
      project.stars = []
      doUpdate = true
    }
  }

  if (type === STAR_ADD_LOADING) {
    //listen to star change and update recentProjects
    const projectId = payload.id
    const project = projects.find((p) => p.id === projectId)
    if (project) {
      project.stars = ['Fake_Star_URl']
      doUpdate = true
    }
  }

  if (project) {
    projects.unshift(project)
    doUpdate = true
  }

  if (doUpdate) {
    console.debug('Update recent projects:', projects.length)
    appStorage.setJSON('recentProjects', projects)
  }

  return projects
}

import { Divider } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { orderSelectors } from 'ducks/orderComponents'
import { orgSelectors } from 'ducks/orgs'
import { getOrderCost } from 'pages/ordering/utils'
import { useTranslate } from 'ra-core'
import { memo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { currencySymbolForCountry, formatCurrency } from 'util/misc'
import { CartDiscount, getCartDiscount, getCartDiscountValue } from '../order/CartDiscounts'

type Breakdown = {
  label: string
  value: string
  isTextBold: boolean
}

const useStyles = makeOpenSolarStyles((theme) => ({
  textWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    margin: '4px 0px 16px 0px',
    backgroundColor: '#000000',
  },
  text: {
    fontSize: '14px',
    lineHeight: '22px',
  },
  discountText: {
    fontSize: '14px',
    lineHeight: '22px',
    color: '#018030',
  },
  textBold: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '22px',
  },
}))

const PriceBreakdown = ({ vatPercentage }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const currencySymbol = useSelector((state: any) => {
    return currencySymbolForCountry(orgSelectors.getOrgIso2(state))
  })
  const orderableLineItems = useSelector(orderSelectors.getOrderableLineItems)
  const selectedSupplier = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const [cartDiscount, setCartDiscount] = useState<CartDiscount>(null)

  useEffect(() => {
    getCartDiscount(orderableLineItems, selectedSupplier, (cartDiscount) => {
      setCartDiscount(cartDiscount)
    })
  }, [orderableLineItems, selectedSupplier])

  const cost = getOrderCost(orderableLineItems, false)
  const costWithDiscounts = getOrderCost(orderableLineItems, true)
  const tradeDiscountValue = cost - costWithDiscounts
  const cartDiscountValue = getCartDiscountValue(costWithDiscounts, cartDiscount)
  const subtotal = costWithDiscounts - cartDiscountValue
  const quantity = orderableLineItems.reduce((accumulator, item) => accumulator + item.quantity, 0)

  const breakdown: Breakdown[] = [
    {
      label: 'Total before Tax:',
      value: `${currencySymbol} ${formatCurrency(subtotal)}`,
      isTextBold: true,
    },
    {
      label: `VAT (${vatPercentage}%):`,
      value: `${currencySymbol} ${formatCurrency(subtotal * (vatPercentage / 100))}`,
      isTextBold: false,
    },
    { label: 'Estimated Shipping Cost', value: 'TBC', isTextBold: false },
  ]

  return (
    <>
      <div className={classes.textWrapper}>
        <Typography variant="h6" gutterBottom className={classes.textWrapper}>
          {translate('shopping_cart_action_subtotal', { smart_count: quantity })}
        </Typography>
      </div>
      <div className={classes.textWrapper} style={{ marginTop: '24px' }}>
        <Typography gutterBottom className={classes.text}>
          {translate('Subtotal')}:
        </Typography>
        <Typography gutterBottom className={classes.text}>
          {`${currencySymbol} ${formatCurrency(cost)}`}
        </Typography>
      </div>
      {Boolean(tradeDiscountValue) && (
        <div className={classes.textWrapper}>
          <Typography gutterBottom className={classes.discountText}>
            {translate('Trade Discount')}:
          </Typography>
          <Typography gutterBottom className={classes.discountText}>
            {`- ${currencySymbol} ${formatCurrency(tradeDiscountValue)}`}
          </Typography>
        </div>
      )}
      {cartDiscount && Boolean(cartDiscountValue) && (
        <div className={classes.textWrapper}>
          <Typography gutterBottom className={classes.discountText}>
            {translate(cartDiscount.discountName)}:
          </Typography>
          <Typography gutterBottom className={classes.discountText}>
            {`- ${currencySymbol} ${formatCurrency(cartDiscountValue)}`}
          </Typography>
        </div>
      )}
      <Divider className={classes.divider} />
      <>
        {breakdown.map((item: Breakdown) => (
          <div className={classes.textWrapper}>
            <Typography gutterBottom className={item.isTextBold ? classes.textBold : ''}>
              {item.label}
            </Typography>
            <Typography gutterBottom className={classes.text}>
              {item.value}
            </Typography>
          </div>
        ))}
      </>
    </>
  )
}

export default memo(PriceBreakdown)

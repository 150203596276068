import { Theme, useMediaQuery } from '@material-ui/core'
import SelectableCard from 'elements/SelectableCard'
import LightbulbIcon from 'icons/LightbulbIcon'
import ListIcon from 'icons/ListIcon'
import LoadingDots from 'layout/widgets/LoadingDots'
import { Box, Button } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { useGetListFromStore } from 'react-admin'
import ActivateMoreBatteriesAlert from '../../components/ActivateMoreBatteriesAlert'
import BatterySalesAssistantContainer from '../../components/BatterySalesAssistantContainer'
import ExistingAppliedBatteryAlert from '../../components/ExistingAppliedBatteryAlert'
import { BatterySalesAssistantFlowNodeComponentStandardPropsType, BSAModeSelectionType } from '../../utility/types'
import { checkIsBdaDisabled } from '../../utility/utils'
import useModeNodePresenter from './useModeNodePresenter'

interface BSASelectionProps extends BatterySalesAssistantFlowNodeComponentStandardPropsType {}

const BSAModeNode: React.FC<BSASelectionProps> = ({ flowController, flowStore }) => {
  const translate = useTranslate()
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const presenter = useModeNodePresenter({ defaultSelectedMode: flowStore.mode, flowController, flowStore })

  // Check if BDA option should be disabled
  const { total: activeBatteriesTotal, loading } = useGetListFromStore(
    'component_battery_activations',
    undefined,
    undefined,
    {}
  )
  const isBdaDisabled = useMemo(() => checkIsBdaDisabled(window.editor?.selectedSystem, activeBatteriesTotal ?? 0), [
    activeBatteriesTotal,
  ])

  const BSA_MODE_SELECTIONS: BSAModeSelectionType[] = useMemo(
    () => [
      {
        description: translate(
          'OpenSolar’s Auto-Battery Selection tool generates recommended battery configurations based on a few key inputs about customer motivation and appliance usage in the home.'
        ),
        icon: <LightbulbIcon />,
        key: 'auto',
        title: translate('Battery Design Assistant'),
      },
      {
        description: translate(
          'Browse through our catalog of battery options. Select the one that best aligns with your needs and preferences.'
        ),
        icon: <ListIcon />,
        key: 'manual',
        title: translate('Manual Battery Selection'),
      },
    ],
    []
  )

  return (
    <BatterySalesAssistantContainer
      footerActions={
        <Button
          color="primary"
          disabled={!presenter.selectedMode}
          variant="contained"
          onClick={presenter.handleContinue}
        >
          {translate('Next')}
        </Button>
      }
      title={translate('What would you like to do?')}
    >
      {loading ? (
        <Box display="flex" justifyContent="center" margin="24px 0px">
          <LoadingDots />
        </Box>
      ) : (
        <>
          <Box display="flex" gridGap={16} flexWrap={isSmallScreen ? 'wrap' : 'nowrap'}>
            {BSA_MODE_SELECTIONS.map(({ chipText, description, icon, key, title }) => {
              return (
                <SelectableCard
                  cardKey={key}
                  childGap={8}
                  chipText={chipText}
                  description={description}
                  gap={16}
                  key={key}
                  icon={icon}
                  isSelected={presenter.selectedMode === key}
                  onSelect={() => presenter.handleSelectMode(key)}
                  title={title}
                  disabled={key === 'auto' && isBdaDisabled}
                />
              )
            })}
          </Box>
          <ExistingAppliedBatteryAlert />
          <ActivateMoreBatteriesAlert isBdaDisabled={isBdaDisabled} />
        </>
      )}
    </BatterySalesAssistantContainer>
  )
}

export default BSAModeNode

import { Divider, FormControlLabel, Grid, Paper, TextField } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import { orgSelectors } from 'ducks/orgs'
import { permissionsSelectors } from 'ducks/permissions'
import { Button, Checkbox, Switch } from 'opensolar-ui'
import { useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { markFieldActive, markFieldInactive } from 'Studio/Utils'
import { currencySymbolForCountry } from 'util/misc'
import { inputValueToFloat, valueOrEmptyStringIfZero } from '../../PanelSystem'
import ESIncentiveField from './ESIncentiveField'
import IncentiveCheckBoxes from './IncentiveCheckBoxes'
import ShowAutoAppliedIncentiveGeneric from './ShowAutoAppliedIncentiveGeneric'
import SwedenGreenIncentiveField from './SwedenGreenIncentiveField'

const toggleIncentiveOverrides = (uuid, enabled) => {
  var system = window.editor.objectByUuid(uuid)
  if (enabled) {
    if (!system.incentives || system.incentives === null) {
      window.editor.execute(new window.SetValueCommand(system, 'incentives', []))
      //system.incentives = []
    }
  } else {
    window.editor.execute(new window.SetValueCommand(system, 'incentives', null))
    //system.incentives = null
  }
  //window.editor.signals.objectChanged.dispatch(system)
}

const handleObjectChanged = (uuid) => {
  /*
  This is a temporary hack to trigger system re-calc when setting incentives value. Because the first arg of SetValueCommand is not an OS design object.
  Todo:
  replace the hack and trigger the system calc properly.
  */
  if (uuid) {
    var object = window.editor.objectByUuid(uuid)

    if (object) {
      object.refreshUserData()

      window.editor.signals.objectChanged.dispatch(object)
    }
  }
}

const handleAddIncentive = (uuid, incentiveType) => {
  var system = window.editor.objectByUuid(uuid)
  var newValue = [
    ...system['incentive_to_' + incentiveType],
    { title: '', value: 0, use_for_loan_pay_down: false, new: true },
  ]
  window.editor.execute(new window.SetValueCommand(system, 'incentive_to_' + incentiveType, newValue))
}

const handleRemoveIncentive = (uuid, incentiveType, incentiveIndex) => {
  var system = window.editor.objectByUuid(uuid)
  var newValue = [...system['incentive_to_' + incentiveType]]
  newValue.splice(incentiveIndex, 1)
  window.editor.execute(new window.SetValueCommand(system, 'incentive_to_' + incentiveType, newValue))
}

const Incentives = ({ pricingIsLocked, system, panelSystem }) => {
  const translate = useTranslate()
  const formState = useFormState()
  const orgCountryIso2 = useSelector(orgSelectors.getOrgIso2)
  const countryIso2 = formState.values.country_iso2
  const { allowView, allowCreate, allowEdit, allowDelete } = useSelector(
    permissionsSelectors.getProjectPermissionByKey('design_incentives')
  )
  const currencySymbol = currencySymbolForCountry(countryIso2 || orgCountryIso2)
  if (!allowView) {
    return null
  }

  return (
    <div style={{ position: 'relative', width: '100%', padding: 0 }}>
      <div style={{ width: '100%', margin: '6px 0 10px', position: 'relative' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={system.incentives !== null}
              size="small"
              onChange={(event) => {
                toggleIncentiveOverrides(system.uuid, event.target.checked)
              }}
              disabled={pricingIsLocked || !allowEdit}
            />
          }
          label={<span style={{ fontSize: 12 }}>{translate('Override Default Incentives')}</span>}
        />
        {system.incentives !== null && <IncentiveCheckBoxes system={system} />}
      </div>
      <Divider style={{ height: 2, margin: '20px 0' }} />
      {[
        {
          type: 'installer',
          label: 'Installer',
          incentives: system.incentive_to_installer,
        },
        {
          type: 'customer',
          label: 'Customer',
          incentives: system.incentive_to_customer,
        },
      ].map((incentiveType, incentiveTypeIndex) => {
        const claimedLabel = translate(`Claimed By ${incentiveType.label}`)
        return (
          <div key={incentiveTypeIndex} style={{ width: '100%', margin: '6px 0 10px', position: 'relative' }}>
            <div style={{ alignItems: 'center', justifyContent: 'space-between', display: 'flex' }}>
              <h2>{claimedLabel}</h2>
              <Button
                style={{
                  width: 20,
                  minWidth: 20,
                  height: 20,
                  fontSize: 20,
                }}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  handleAddIncentive(system.uuid, incentiveType.type)
                }}
                disabled={pricingIsLocked || !allowCreate}
              >
                {'+'}
              </Button>
            </div>
            {incentiveType.incentives.map((incentiveData, incentiveIndex) => (
              <Paper style={{ marginBottom: 10, marginTop: 10, paddingTop: 15, paddingBottom: 15 }}>
                <Grid container spacing={0.5}>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      label={translate('Title')}
                      underlineStyle={{ borderColor: 'rgb(108, 108, 108)' }}
                      name="Title"
                      value={incentiveData.title}
                      onFocus={() => {
                        markFieldActive.call(
                          panelSystem,
                          'incentiveTo' + incentiveType.label + 'RawTitle' + incentiveIndex,
                          system
                        )
                      }}
                      onBlur={() => {
                        markFieldInactive.call(panelSystem)
                      }}
                      onChange={(event) => {
                        const value = event.target.value
                        window.editor.execute(
                          new window.SetValueCommand(incentiveType.incentives[incentiveIndex], 'title', value)
                        )
                        //incentiveType.incentives[incentiveIndex].title = value
                        handleObjectChanged(system.uuid)
                      }}
                      errorText={
                        !incentiveType.incentives[incentiveIndex].title ||
                        incentiveType.incentives[incentiveIndex].title.length === 0
                          ? translate('Supply an incentive title')
                          : null
                      }
                      style={{ float: 'left', width: '70%', marginLeft: '5%', marginRight: '5%' }}
                      disabled={pricingIsLocked || (!allowEdit && !incentiveData.new)}
                    />
                    <Button
                      style={{
                        width: 20,
                        minWidth: 0,
                        height: 20,
                        backgroundColor: 'rgba(233, 233, 233, 1)',
                        float: 'right',
                        marginLeft: '5%',
                        marginRight: '5%',
                      }}
                      labelStyle={{ padding: '0px 0px', fontSize: 28, fontWeight: 300 }}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        handleRemoveIncentive(system.uuid, incentiveType.type, incentiveIndex)
                      }}
                      disabled={pricingIsLocked || !allowDelete}
                    >
                      {'-'}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      type="number"
                      label={translate('Value')}
                      underlineStyle={{ borderColor: 'rgb(108, 108, 108)' }}
                      name="Value"
                      value={valueOrEmptyStringIfZero(incentiveType.incentives[incentiveIndex].value)}
                      onFocus={() => {
                        markFieldActive.call(
                          panelSystem,
                          'incentiveTo' + incentiveType.label + 'RawValue' + incentiveIndex,
                          system
                        )
                      }}
                      onBlur={() => {
                        markFieldInactive.call(panelSystem)
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                      }}
                      onChange={(event) => {
                        const value = event.target.value
                        var valueAsFloat = inputValueToFloat(value)

                        if (!isNaN(valueAsFloat)) {
                          window.editor.execute(
                            new window.SetValueCommand(incentiveType.incentives[incentiveIndex], 'value', valueAsFloat)
                          )
                          //incentiveType.incentives[incentiveIndex].value = valueAsFloat
                          handleObjectChanged(system.uuid)
                        }
                      }}
                      errorText={
                        isNaN(inputValueToFloat(incentiveType.incentives[incentiveIndex].value))
                          ? translate('Supply incentive value as a number or leave blank')
                          : null
                      }
                      style={{ float: 'left', width: '70%', marginLeft: '5%', marginRight: '5%' }}
                      disabled={pricingIsLocked || (!allowEdit && !incentiveData.new)}
                    />
                  </Grid>
                  {incentiveType.type === 'customer' && (
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={incentiveType.incentives[incentiveIndex].use_for_loan_pay_down}
                            onClick={(event) => {
                              const value = event.target.checked
                              window.editor.execute(
                                new window.SetValueCommand(
                                  incentiveType.incentives[incentiveIndex],
                                  'use_for_loan_pay_down',
                                  value
                                )
                              )
                              //incentiveType.incentives[incentiveIndex].title = value
                              handleObjectChanged(system.uuid)
                            }}
                          />
                        }
                        label={translate('Use for Loan Pay Down')}
                      />
                    </Grid>
                  )}
                </Grid>
              </Paper>
            ))}

            <ShowAutoAppliedIncentiveGeneric
              system={system}
              currencySymbol={currencySymbol}
              incentiveType={incentiveType.type}
            />
            <Divider style={{ height: 2, margin: '20px 0' }} />
          </div>
        )
      })}
      {countryIso2 === 'ES' && (
        <ESIncentiveField
          currencySymbol={currencySymbol}
          pricingIsLocked={pricingIsLocked}
          allowEdit={allowEdit}
          system={system}
        />
      )}
      {countryIso2 === 'SE' && (
        <SwedenGreenIncentiveField
          currencySymbol={currencySymbol}
          pricingIsLocked={pricingIsLocked}
          allowEdit={allowEdit}
          system={system}
          handleObjectChanged={handleObjectChanged}
        />
      )}
    </div>
  )
}

export default Incentives

import { applyComponentQuantities } from 'projectSections/sections/design/systems/EnphaseBomGenerator'
import restClient from 'restClient'
import { StudioSystemType } from 'types/global'
import { OtherComponentType } from 'types/otherComponent'
import { parseIntegrationJson, randomLonLatOffsetForPrivacy } from 'util/misc'
import { SegenPartsPayload } from './types'

const API_URL = window.API_ROOT + '/api'

export const clearRenusolDesign = (system) => {
  // We clear the design by incrementing the version number
  let integrationObj = parseIntegrationJson(system.integration_json)

  if (!integrationObj) {
    integrationObj = {}
  }

  if (!integrationObj.renusol) {
    integrationObj.renusol = {}
  }

  if (!integrationObj?.renusol?.version) {
    integrationObj.renusol.version = 1
  }

  integrationObj.renusol.version += 1

  if (integrationObj.renusol?.parts) {
    delete integrationObj.renusol.parts
  }

  window.editor.execute(new window.SetValueCommand(system, 'integration_json', integrationObj))
}

export const storeRenusolParts = (system: StudioSystemType, parts: SegenPartsPayload) => {
  // We clear the design by incrementing the version number
  let integrationObj = parseIntegrationJson(system.integration_json)

  if (!integrationObj) {
    integrationObj = {}
  }

  if (!integrationObj.renusol) {
    integrationObj.renusol = {}
  }

  integrationObj.renusol.parts = parts

  window.editor.execute(new window.SetValueCommand(system, 'integration_json', integrationObj))
}

export const hasRenusolDesign = (system) => {
  // We clear the design by incrementing the version number
  return parseIntegrationJson(system.integration_json)?.renusol?.parts
}

export const getExternalDesignIdentifier = (project, system) => {
  var version = parseIntegrationJson(system.integration_json)?.renusol?.version || 1
  return String(project.id) + '_' + system.uuid + '_' + version
}

const descriptiveNameForModuleGrid = (mg) => {
  var index = mg.parent.children.indexOf(mg) + 1
  var azimuth = Math.round(mg.getAzimuth())
  var moduleQuantity = mg.moduleQuantity()
  var orientation = mg.moduleLayout() === 'landscape' ? 'Landscape' : 'Portrait'
  return `Roof ${index} (Panels: ${moduleQuantity} ${orientation}, Azimuth: ${azimuth})`
}

export const launchRenusolPayload = (userId) => {
  var project = window.projectForm.getState().values
  var moduleType = window.editor.selectedSystem.moduleType()
  var system = window.editor.selectedSystem
  var projectNameParts = [window.projectForm.getState().values.locality, window.projectForm.getState().values.id]
  var projectName = projectNameParts.filter(Boolean).join(' ')

  var externalDesignIdentifier = getExternalDesignIdentifier(project, system)

  // Use mg.size not syste.moduleType().size
  // const calcRoofLength = (mg) => {
  //   //This is the essentially the flat length of the ridge or gutter of the facet (whichever is longer)
  //   // we could fill this based on the total panel group width (to hack it to work)
  //   var cols = mg.getCols()
  //   return 1000 * cols * mg.size[0] + 1000 + Math.floor(cols / 12) * 100 + (cols + 1) * 30
  // }

  // const calcRoofSegmentWidth = (mg) => {
  //   //This is essentially the non-flat roof length from gutter to ridge, we could fill this out based on
  //   // the total panel group height (to hack it to work)
  //   var rows = mg.getRows()
  //   return 1000 * rows * mg.size[1] + 1000 + Math.floor(rows / 12) * 100 + (rows + 1) * 30
  // }

  // random offset for privacy
  // Add 1 and 2 to the project id to avoid using the same offset for lon and lat
  // 1.0 ~= 100km, 0.01 ~= 1km, 0.001 ~= 100m
  const offsetLonLat = randomLonLatOffsetForPrivacy(project.id)
  // window.randomLonLatOffsetForPrivacy = randomLonLatOffsetForPrivacy
  var payload = {
    user: {
      userident: `OpenSolar${userId}`,
    },
    project: {
      name: projectName,
      projectident: externalDesignIdentifier, // "xyz987654 115",
      products: system.moduleGrids().map((mg, mgIndex) => ({
        configuration: {
          fastCalc: false,
          location: {
            latitude: window.SceneHelper.getLatitude() + offsetLonLat[1],
            longitude: window.SceneHelper.getLongitude() + offsetLonLat[0],
            country: project.country_iso2,
            city: project.locality,
            postCode: project.zip,
          },
          design: {
            moduleRows: mg.getRows(),
            moduleColumns: mg.getCols(),
          },
          // "surroundingArea": {
          //   "elevationAboveNormalHeight": 13000,
          //   "norm": "BG",
          //   "terrainCategory": "BG_3",
          //   "snowLoad": 1.2,
          //   "baseWindPressure": 0.6,
          //   "reduceWindSpeedRC": true,
          //   "operatingLife": 25,
          //   "failureConsequenceClass": 2,
          //   "partialSafetyFactor": 1,
          //   "topography": {
          //     "type": "None"
          //   }
          // },
          roof: {
            // In order to define the roofLength you need to send this for some reason, even if it as blank
            roofType: '',
            roofFormEntry: 'manual',

            // Do not send roof dimensions unless we can ensure they are exact
            //This is the essentially the flat length of the ridge or gutter of the facet (whichever is longer), we could fill this based on the total panel group width (to hack it to work)
            // roofLength: calcRoofLength(mg),

            // Do not send roof dimensions unless we can ensure they are exact
            //This is essentially the non-flat roof length from gutter to ridge, we could fill this out based on the total panel group height (to hack it to work)
            // roofSegmentWidth: calcRoofSegmentWidth(mg),

            //Not sure we can fill this out with a roof facet defined in OS since it is truly the roof pitch and you can't use the module pitch
            roofPitch: Math.round(mg.getPanelTilt()),

            //Possibly we could fill it out but it makes more sense to leave it blank
            // "buildingHeight": 10000
          },
          // "roofCoverage": {
          //   //this relates to the roof type (Pantile roof, Plain tile roof, etc.) not sure what the options here are... leave it for the pro to fill in the Renusol UI
          //   "type": "frankfurterPfanne",
          //   "battens": {
          //     "spacing": 320,
          //     "firstAt": 100
          //   },
          //   "battenHeight": 30,
          //   "battenWidth": 50
          // },
          // "substructure": {
          //   "substructureType": "rafters",
          //   "material": "wood",
          //   "spacing": 600,
          //   "firstAt": 300,
          //   "thickness": 200
          // },
          module: {
            manufacturer: moduleType.manufacturer_name,
            name: moduleType.code,

            // "height" of panel in OS
            length: moduleType.size[1] * 1000,

            // "width" of panel in OS
            width: moduleType.size[0] * 1000,

            // "thickness" of the panel in OS
            height: moduleType.thickness || 35,

            // "weight" of the panel in OS
            weight: moduleType.weight || 20,

            ratedOutput: Math.round(moduleType.kw_stc * 1000),

            //not captured in OS
            black: true,
          },
          // "fastener": {
          //   "fastenerSystem": "VarioSole",
          //   "fastenerType": "roofHookRH1",
          //   "screwingType": "automatic",
          //   "fasteningRails": "singleLayer",
          //   "railType": "50x37",
          //   "bearingOnlyUnderModuleSurface": false,
          //   "alternatingRoofHook": false
          // },
          moduleMounting: {
            orientation: mg.moduleLayout(),
            // "clamps": {
            //   "clampType": "clampsPlus",
            //   "clampColor": "black"
            // }
          },
          // "rails": {
          //   "moduleRail": {
          //     "railLength": [
          //       3300
          //     ],
          //     "cantileverMaxLength": 300,
          //     "railColor": "black",
          //     "railCount": 2,
          //     "optimizeFasteners": "roofWise"
          //   }
          // }
        },
        name: descriptiveNameForModuleGrid(mg),
      })),
    },
    returnUrl: `${window.location.origin}/renusol_returned.html`,
  }
  return payload
}

// window.launchRenusolPayload = launchRenusolPayload

export const launchRenusol = (userId: number, orgId: number, ident: string, translate: (str: string) => string) => {
  return new Promise((resolve: (found: boolean) => void, reject: (found: boolean) => void) => {
    const identWithDashes = ident.replace(' ', '-')
    const restClientInstance = restClient(API_URL)
    var moduleType = window.editor.selectedSystem.moduleType()

    let payload

    try {
      payload = launchRenusolPayload(userId)
    } catch (e) {
      console.error(e)
    }

    const { thickness, weight } = moduleType

    if (!thickness && weight) {
      window.Designer.showNotification(
        translate(
          `Using default values for panel thickness (35mm). These can be configured inside Renusol Configurator.`
        ),
        'info'
      )
    }
    if (thickness && !weight) {
      window.Designer.showNotification(
        translate(`Using default values for panel weight (20kg). These can be configured inside Renusol Configurator.`),
        'info'
      )
    }
    if (!thickness && !weight) {
      window.Designer.showNotification(
        translate(
          'Using default values for panel thickness (35mm) and panel weight (20kg). These can be configured inside Renusol Configurator.'
        ),
        'info'
      )
    }

    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${orgId}/renusol/send/${identWithDashes}/`,
      data: payload,
    })
      .then((response: any) => {
        resolve(response?.data?.jumpurl)
      })
      .catch((err: any) => {
        reject(false)
        console.log('error', err)
      })
  })
}

export const loadParts = (orgId: number, ident: string) => {
  return new Promise<SegenPartsPayload>(
    (resolve: (found: SegenPartsPayload) => void, reject: (found: boolean) => void) => {
      const identWithDashes = ident.replace(' ', '_')
      const restClientInstance = restClient(API_URL)

      restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${orgId}/renusol/parts/${identWithDashes}/`,
      })
        .then((response: { data: SegenPartsPayload }) => {
          resolve(response?.data)
        })
        .catch((err: any) => {
          reject(false)
          console.log('error', err)
        })
    }
  )
}

// Fields for renusolCalculationResult:
// - parlist
//    - parts: [...]

// Fields for Part:
// article: "R200007"
// count: 1
// countAbsolute: 1
// countCalculated: 1
// countCalculatedAbsolute: 1
// description: "Service for InterSole layout"
// orderingUnit: 1
// recommended: false

const partToComponentData = (part) => ({
  // use segenProductCode if populated, because this is a mapping from supplier code to a
  // segen product code which is guaranteed to match the segen catalog when ordering
  code: part.segenProductCode || part.article,
  title: part.description,
  description: '',
  manufacturer_name: 'Renusol',

  // Note: part.recommended is counter-intuitive.
  // When recommended == false that means it is mandatory
  quantity: !part.recommended ? part.countCalculated : 0,
})

// const bomLineItemToComponentData = (item: BomLineItemType) => ({
//   code: item.segen_code || item.mfr_code,
//   title: item.name,
//   description: item.description,
//   manufacturer_name: 'Renusol',
//   quantity: item.suggested_count,
// })

export const partsToApply = (renusolCalculationResult) => {
  var parts = renusolCalculationResult?.partlist?.parts
  return renusolCalculationResult.partlist.parts.map(partToComponentData)
}

export const applyPartsToDesign = (renusolCalculationResult) => {
  // Replaced by applyOtherComponentsToDesign

  var parts = renusolCalculationResult?.partlist?.parts
  if (!parts) {
    throw new Error('Parts not found')
  }

  var system = window.editor.selectedSystem
  if (system) {
    var ephemeralComponentsData = renusolCalculationResult.partlist.parts.map(partToComponentData)

    var componentQuantities = {}

    ephemeralComponentsData.forEach((c) => {
      componentQuantities[c.code] = c.quantity
    })

    applyComponentQuantities(system, componentQuantities, ephemeralComponentsData)
  } else {
    console.error('System not found')
  }
}

export const applyOtherComponentsToDesign = (components: OtherComponentType[]) => {
  var system = window.editor.selectedSystem
  if (system) {
    var ephemeralComponentsData = components

    var componentQuantities = {}

    components.forEach((c) => {
      componentQuantities[c.code] = c.quantity
    })

    applyComponentQuantities(system, componentQuantities, ephemeralComponentsData)
  } else {
    console.error('System not found')
  }
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNestedWindow = void 0;
function isNestedWindow() {
    try {
        return window.self !== window.top;
    }
    catch (e) {
        return true;
    }
}
exports.isNestedWindow = isNestedWindow;

import React from 'react'
import { LENDER_LOGO_PATHS } from 'resources/financeIntegrations/constants'
import { AvailableFinanceIntegrationType } from 'types/orgs'

type PropTypes = {
  integration: AvailableFinanceIntegrationType
}

const IntegrationPromoImage: React.FC<PropTypes> = (props) => {
  return (
    <img
      alt={props.integration.display_name}
      src={
        props.integration.logo_large_contents ||
        `${window.PUBLIC_URL}/images/${LENDER_LOGO_PATHS[props.integration.integration_name]?.large}`
      }
      style={{ height: props.integration?.banner_content?.logo_height || 35 }}
    />
  )
}
export default IntegrationPromoImage

import { useMediaQuery } from '@material-ui/core'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutlineOutlined'
import { authSelectors } from 'ducks/auth'
import { DefaultBreadCrumbs } from 'elements/BreadCrumbs'
import { List } from 'elements/react-admin/List'
import { Button, Switch } from "opensolar-ui"
import { useState } from 'react'
import {
  ChipField,
  Datagrid,
  FunctionField,
  ReferenceArrayField,
  SimpleList,
  SingleFieldList,
  useRefresh,
  useTranslate,
  withTranslate
} from 'react-admin'
import { connect, useSelector } from 'react-redux'
import compose from 'recompose/compose'
import restClient from 'restClient'

const restClientInstance = restClient(window.API_ROOT + '/api')

const getProjectId = (text) => {
  return text.split('/')[7]
}

const getResourceLinkPath = (basePath, id, linkType) => {
  return `/projects/${encodeURIComponent(id)}/info`
}
const _ProposalViewersList = ({ accessRights: { allowEdit, allowDelete }, ...props }) => {
  const translate = useTranslate()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  const orgId = useSelector(authSelectors.getOrgId)

  return (
    <List
      showButtons={true}
      title="Proposal Viewers"
      breadCrumbs={<DefaultBreadCrumbs />}
      titleStyle={{ fontSize: 16, lineHeight: '16px', paddingTop: 0 }}
      hasSearch={true}
      perPage={20}
      {...props}
      filter={{ type: 1, is_active: true }}
      location={{
        hash: '',
        pathname: '/contacts',
      }}
      resource={'contacts'}
    >
      {isMobile ? (
        <SimpleList
          primaryText={(record) =>
            (record.first_name && record.first_name.length) || (record.family_name && record.family_name.length)
              ? `${record.first_name} ${record.family_name}`
              : translate('(Unnamed)')
          }
          linkTo={(basePath, id) => `${basePath}/${id}`}
        />
      ) : (
        <Datagrid>
          {/* <TextField source="display" label="Contacts" /> */}
          {isDesktop && (
            <FunctionField
              source={'projects'}
              label="Project"
              render={(record, source, rest) => (
                <div>
                  <ReferenceArrayField record={record} source={source} reference="projects" {...rest}>
                    <SingleFieldList getResourceLinkPath={getResourceLinkPath}>
                      <ChipField style={{ maxWidth: 140 }} source="title" />
                    </SingleFieldList>
                  </ReferenceArrayField>
                </div>
              )}
              sortable={false}
            />
          )}

          <FunctionField
            source={null}
            label="Enabled"
            render={(record) => <div>{allowEdit && <Toggle record={record} orgId={orgId} />}</div>}
            sortable={false}
          />

          <FunctionField
            source={null}
            label="Actions"
            render={(record) => <div>{allowDelete && <DeleteContactButton record={record} orgId={orgId} />}</div>}
            sortable={false}
          />
        </Datagrid>
      )}
    </List>
  )
}
const mapStateToPropsList = (state) => {
  return {
    recentProjects: state.recentProjects,
  }
}
export default compose(withTranslate, connect(mapStateToPropsList, {}))(_ProposalViewersList)

const Toggle = ({ record, orgId }) => {
  const [enable, setEnable] = useState({
    toggle: !!record.share_urls.length,
  })

  const onEnableOrDisableSharing = () => {
    if (enable.toggle) {
      return restClientInstance('CUSTOM_PUT', 'custom', {
        url: 'orgs/' + orgId + '/contacts/' + record.id + '/share_link/',
        data: {
          enable: false,
        },
      })
    } else {
      return restClientInstance('CUSTOM_PUT', 'custom', {
        url: 'orgs/' + orgId + '/contacts/' + record.id + '/share_link/',
        data: {
          enable: true,
        },
      })
    }
  }

  const handleToggleChange = (event) => {
    onEnableOrDisableSharing()
    setEnable({ ...enable, [event.target.name]: event.target.checked })
  }
  return <Switch key={record.id} name="toggle" onChange={handleToggleChange} checked={enable.toggle} />
}

const DeleteContactButton = ({ record, orgId }) => {
  const refresh = useRefresh()
  const translate = useTranslate()

  const deleteContact = () => {
    return restClientInstance('CUSTOM_PUT', 'custom', {
      url: 'orgs/' + orgId + '/projects/' + getProjectId(record.projects[0]) + '/share_link/',
      data: {
        enable: false,
      },
    })
  }

  const handleDelete = () => {
    const promise = deleteContact()
    promise.finally(() => refresh())
  }

  return (
    <Button startIcon={<DeleteOutlineIcon />} onClick={handleDelete}>
      {translate('Delete')}
    </Button>
  )
}

import Grid from '@material-ui/core/Grid'
import CustomField from 'elements/field/CustomField'
import ChipsInput from 'elements/input/ChipsInput'
import DateTimeInput from 'elements/input/DateTimeInput'
import moment from 'moment'
import { NotifyTeamMembersField } from 'projectSections/elements/fields/AssigneeMultiSelectField'
import React from 'react'
import {
  AutocompleteInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  useTranslate,
} from 'react-admin'
import Calendar from 'react-big-calendar'
import { EVENT_TYPE_CHOICES, TASK_STATUS_CHOICES } from '../constants'

Calendar.setLocalizer(Calendar.momentLocalizer(moment))

type PropTypes = {
  showNotifyTeamMembers?: boolean
  mode?: 'future_appointment' | 'future_task' | 'historical_activity'
}

//@ts-ignore react-admin type issues
const IncompleteEventForm: React.FunctionComponent<PropTypes> = (props: PropTypes & { record: any }) => {
  const eventInputStyle = {
    width: '100%',
  }
  const translate = useTranslate()
  return (
    <>
      <Grid item xs={6}>
        <CustomField
          style={{ ...eventInputStyle }}
          component={SelectInput}
          //label="Activity Type"
          name="event_type_id"
          source="event_type_id"
          choices={EVENT_TYPE_CHOICES}
        />
      </Grid>

      <Grid item xs={12}>
        <CustomField
          component={TextInput}
          fullWidth={true}
          multiline={true}
          label="resources.events.fields.notes"
          name="notes"
          source="notes"
        />
      </Grid>
      <Grid item xs={12}>
        {/*@ts-ignore*/}
        <ReferenceArrayInput
          name="team_members"
          source="team_members"
          reference="roles"
          optionValueField="url"
          label="resources.events.fields.assigned_to"
          defaultValue={[]}
          renderChildrenBeforeRecord={true}
        >
          {/* @ts-ignore */}
          <ChipsInput
            fullWidth={true}
            margin="dense"
            source="display"
            optionText="display"
            optionValue="url"
            useSmallChip={true}
            disabled={!props.record}
            showSpinnerWhenRefreshing={true}
          />
        </ReferenceArrayInput>
      </Grid>

      <Grid item xs={6}>
        <CustomField style={{ width: '100%' }} component={DateTimeInput} {...props} name="start" source="start" />
      </Grid>

      <Grid item xs={6}>
        <CustomField style={{ width: '100%' }} component={DateTimeInput} {...props} name="end" source="end" />
      </Grid>

      <Grid item xs={6}>
        <CustomField
          component={SelectInput}
          name="task_status"
          source="task_status"
          choices={TASK_STATUS_CHOICES}
          fullWidth={true}
          style={{ width: '100%' }}
        />
      </Grid>

      <Grid item xs={6}>
        <ReferenceInput
          source="contact"
          reference="contacts"
          allowEmpty
          resource="contacts"
          renderChildrenBeforeRecord={true}
          filter={{ type: 0 }}
        >
          <AutocompleteInput
            sortChoices={true}
            source="display"
            optionText="display"
            optionValue="url"
            options={{ fullWidth: true, placeholder: translate('Enter a contact') }}
            showSpinnerWhenRefreshing={true}
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12}>
        {props.showNotifyTeamMembers && <NotifyTeamMembersField />}
      </Grid>
    </>
  )
}

export default IncompleteEventForm

import React, { useState } from 'react'
import MarketingPage from './MarketingPage'
import PaymentsOnboardingWrapper from './PaymentsOnboardingWrapper'

type PropTypes = {}

const LaunchPaymentsOnboardingDialog: React.FC<PropTypes> = (props) => {
  const [showDialog, setShowDialog] = useState<boolean>(false)

  const closeDialog = () => {
    setShowDialog(false)
  }
  return (
    <div>
      <MarketingPage setShowDialog={setShowDialog} />
      {showDialog && <PaymentsOnboardingWrapper closeDialog={closeDialog} />}
    </div>
  )
}
export default LaunchPaymentsOnboardingDialog

import type { FilterType, GetDefaultFilterValuePropsType } from 'types/selectComponent'

type MpptQuantityOptionType = {
  quantity: number
  value: string
  title: string
}

const MPPT_QUANTITY_OPTIONS: MpptQuantityOptionType[] = [
  {
    quantity: 1,
    value: '1',
    title: '1',
  },
  {
    quantity: 2,
    value: '2',
    title: '2',
  },
  {
    quantity: 3,
    value: '3',
    title: '3',
  },
  {
    quantity: 4,
    value: '4',
    title: '4',
  },
]

const getMatchedValue = (target: number | string, options: MpptQuantityOptionType[]) => {
  for (let i = 0; i < options.length; i++) {
    const { quantity, value } = options[i]
    if (target === quantity) {
      return value
    }
  }
}

export const mpptQuantityFilter: FilterType = {
  key: 'mppt_quantity',
  label: 'MPPT Quantity',
  options: MPPT_QUANTITY_OPTIONS.map((option: MpptQuantityOptionType) => ({
    title: option.title,
    value: option.value,
  })),
  getDefaultValue({ specsData }: GetDefaultFilterValuePropsType) {
    if (specsData.mppt_quantity == null) {
      return
    }
    return getMatchedValue(specsData.mppt_quantity, MPPT_QUANTITY_OPTIONS)
  },
}

export default {
  "%{label} Custom Field ${field_num}": "%{label} Custom Field %{field_num}",
  "%{label} Environmentals CO2 Tons Avoided (Annual)": "%{label} Environmentals CO2 Tonnes Avoided (Annual)",
  "%{label} Tariff Summary Table (Current)": "Tariff Summary Table (Current)",
  "%{label} Tariff Summary Table (Proposed)": "Tariff Summary Table (Proposed)",
  "%{smart_count} minutes": "1 minute |||| %{smart_count} minutes",
  "By switching from Full to Lite, you will lose access to the full range of OpenSolar features. You may switch back at any time.": "By switching from Advanced to Lite, you will lose access to the full range of OpenSolar features. You may switch back at any time.",
  "By switching from Lite to Full, you will gain access to the full range of OpenSolar features. You may switch back at any time.": "By switching from Lite to Advanced, you will gain access to the full range of OpenSolar features. You may switch back at any time.",
  "Create Applied Tariff Details": "Applied Tariff Details",
  "Create Exhibit Component": "Create Showcase Content",
  "Customise price by system and payment option": "Customize price by system and payment option",
  "DC Optimizer Efficiency": "DC Optimiser Efficiency",
  "Disable 2FA?": "Disable Two-factor authentication?",
  "Disabling 2FA will remove all currently verified authentication methods. Are you sure?": "Disabling Two-factor authentication will remove all currently verified authentication methods. Are you sure?",
  "Edit Exhibit Component": "Edit Showcase Content",
  "Energy Page": "Energy page",
  "Escalator: percentage per year": "Escalator: %{percentage}% per year",
  "Example systems price": "Example systems price:",
  "Frequency Loan Repayments (over term units)": "%{frequency} Loan Repayments (over %{term} %{units})",
  "Home [App Navigation]": "Home",
  "Including tax amount": "Including %{currencySymbol}%{amount} %{taxName}",
  "Info Page": "Info page",
  "Initial Loan Repayments": "Initial Loan Repayments (over first %{term} %{frequency})",
  "Is Public": "Is public",
  "Kliplock": "Kliplok",
  "Lease Total Payments": " Payments over %{term} %{frequency}",
  "Levelized Cost of Energy": "Levelised Cost of Energy",
  "Loan Repayments (over years)": " Loan Repayments (over %{time} years)",
  "Manage Page": "Manage page",
  "Not set, default to utility_tariff_name": "Not set, default to %{utility_tariff_name}",
  "Not set, using utility_tariff_name": "Not set, using %{utility_tariff_name}",
  "Number of Stories": "Number of Storeys",
  "Over years": "Over %{time} years",
  "Price of Electricity": " Price of Electricity",
  "Regular Payment Total": " Payments over %{term} years",
  "Roof Hook": "Roof hook",
  "Roof Type Adder number": "Roof Type Adder %{number}",
  "Solar Rate": "Solar Rate:",
  "Someone is making changes to this project on the following fields:": "%{name} is making changes to this project on the following fields:",
  "Something went wrong and we were unable to generate your recovery codes, please try again soon.": "Something went wrong and we were unable to generate your recovery codes, please try again in %{seconds} seconds.",
  "Switch Interface from Full to Lite?": "Switch Interface from Advanced to Lite?",
  "Switch Interface from Lite to Full?": "Switch Interface from Lite to Advanced?",
  "Three+ Stories": "Three+ Storeys",
  "Tons of CO\u2082 reduced per year": "Tonnes of CO\u2082 reduced per year",
  "Total Payments over years": "Total Payments over %{years} years",
  "Two Stories": "Two Storeys",
  "User with this email already exists.": " User with this email already exists.",
  "Utility savings based on switch": "Utility savings based on switch from %{from} to %{to}",
  "We just sent an SMS message to the phone number you provided (...phoneNumber). Please enter the verification code from that message below.": "We just sent an SMS message to the phone number you provided (...%{phoneNumber}). Please enter the verification code from that message below.",
  "You have remaining recovery codes remaining.": "You have %{remaining} recovery codes remaining.",
  "Youtube Video Link": "YouTube Video Link",
  "centrix_credit_check": "Centrix Credit Check",
  "checkout_total_items": "Checkout (%{smart_count} item) |||| Checkout (%{smart_count} items)",
  "company_name": "Company Name",
  "connected": "Connected",
  "connecting": "Connecting",
  "credit_card_stripe": "Credit Card Stripe",
  "data_sharing_consent": "Authorise OpenSolar to share certain data with %{exhibitName} as described in the Data Sharing Authorisation document linked below.",
  "default_email_body_content": "<p>Dear {{contacts.0.full_name}},</p><p>Thank you for accepting the proposal. We will get in touch with you in 24 business hours. Please find attached a copy of the proposal and contract.</p><p>Regards,</p><p>{{assigned_team_member.display}}</p>",
  "description_and_show_customer": "This will update the Description and Show Customer fields for all other annotations %{linkedLength} with the same label.",
  "email": "Email",
  "enabled_partner_features": "Enabled Partner Features",
  "energy_ease_application": "Energy Ease Application",
  "expired": "Expired",
  "field_is_invalid": "%{field} is not valid",
  "field_is_required": "%{field} is required",
  "incentive_type": {
    "customer": "Customer",
    "incentive_type": null,
    "installer": "Installer"
  },
  "is required": "%{item} is required",
  "none": "None",
  "not_support_shading": "Automated shading is not supported by %{calculatorName}. Clearing shading simulations.",
  "not_support_shading_switch": "Automated shading is not supported by %{calcName}. Switch to SAM to enable automated shading simulations.",
  "offline": "Offline",
  "panels_system": "Now that you have placed the panels, let's set the system price. Click \"Back to %{panelQuantity} Panels System\" to proceed.",
  "password": "Password",
  "plenti_bnpl_application": "Plenti Buy Now, Pay Later Application",
  "plenti_loan_application": "Plenti Loan Application",
  "pos": {
    "configuration": "Configuration",
    "dashboard": {
      "dashboard": null,
      "order": {
        "items": "by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items",
        "order": null
      },
      "welcome": {
        "subtitle": "This is the admin of an imaginary poster shop. Fell free to explore and modify the data - it's local to your computer, and will  each time you reload.",
        "title": "Welcome to admin-on-rest demo",
        "welcome": null
      }
    },
    "language": "Language",
    "lead_source": "Lead Source",
    "pos": null,
    "search": "Search",
    "theme": {
      "dark": "Dark",
      "light": "Light",
      "name": "Theme",
      "theme": null
    }
  },
  "price_with_currencySymbol": "Price (%{currencySymbol})",
  "ra": {
    "action": {
      "action": null,
      "add": "Add",
      "add_filter": "Add filter",
      "back": "Go Back",
      "bulk_actions": "1 item selected |||| %{smart_count} items selected",
      "cancel": "Cancel",
      "clear_input_value": "Clear value",
      "clone": "Clone",
      "close": "Close",
      "close_menu": "Close menu",
      "confirm": "Confirm",
      "create": "Create",
      "delete": "Delete",
      "edit": "Edit",
      "expand": "Expand",
      "export": "Export",
      "list": "List",
      "open_menu": "Open menu",
      "refresh": "Refresh",
      "remove": "Remove",
      "remove_filter": "Remove this filter",
      "save": "Save",
      "search": "Search",
      "show": "Show",
      "sort": "Sort",
      "undo": "Undo"
    },
    "auth": {
      "auth": null,
      "auth_check_error": "Please login to continue",
      "email_change_form_error": "Change password failed.",
      "logout": "Logout",
      "password": "Password",
      "password_change_form_error": "Change password failed.",
      "password_reset_error": "Reset password failed.",
      "password_reset_form_error": "Reset password failed.",
      "register_error": "Register failed",
      "reload_error": "Reload failed.",
      "sign_in": "Sign in",
      "sign_in_error": "Authentication failed, please retry",
      "user_menu": "Profile",
      "username": "Username"
    },
    "boolean": {
      "boolean": null,
      "false": "No",
      "true": "Yes"
    },
    "input": {
      "file": {
        "file": null,
        "upload_several": "Drop some files to upload, or click to select one.",
        "upload_single": "Drop a file to upload, or click to select it."
      },
      "image": {
        "image": null,
        "upload_several": "Drop some pictures to upload, or click to select one.",
        "upload_single": "Drop a picture to upload, or click to select it."
      },
      "input": null,
      "password": {
        "password": null,
        "toggle_hidden": "Show password",
        "toggle_visible": "Hide password"
      },
      "references": {
        "all_missing": "Unable to find references data.",
        "many_missing": "At least one of the associated references no longer appears to be available.",
        "references": null,
        "single_missing": "Associated reference no longer appears to be available."
      }
    },
    "message": {
      "about": "About",
      "are_you_sure": "Are you sure?",
      "bulk_delete_content": "Are you sure you want to delete this %{name}? |||| Are you sure you want to delete these %{smart_count} items?",
      "bulk_delete_title": "Delete %{name} |||| Delete %{smart_count} %{name}",
      "delete_content": "Are you sure you want to delete this item?",
      "delete_title": "Delete %{name}: %{id}",
      "details": "Details",
      "error": "A client error occurred and your request couldn't be completed.",
      "invalid_form": "The form is not valid. Please check for errors",
      "loading": "The page is loading, just a moment please",
      "message": null,
      "no": "No",
      "not_found": "Either you typed a wrong URL, or you followed a bad link.",
      "unsaved_changes": "Some of your changes weren't saved. Are you sure you want to ignore them?",
      "yes": "Yes"
    },
    "navigation": {
      "navigation": null,
      "next": "Next",
      "no_more_results": "The page number %{page} is out of boundaries. Try the previous page.",
      "no_results": "No results found",
      "page_out_from_begin": "Cannot go before page 1",
      "page_out_from_end": "Cannot go after last page",
      "page_out_of_boundaries": "Page number %{page} out of boundaries",
      "page_range_info": "%{offsetBegin}-%{offsetEnd} of %{total}",
      "page_rows_per_page": "Rows per page:",
      "prev": "Prev"
    },
    "notification": {
      "bad_item": "Incorrect element",
      "canceled": "Action cancelled",
      "created": "Data created",
      "data_provider_error": "dataProvider error. Check the console for details.",
      "deleted": "Data deleted",
      "http_error": "Server communication error",
      "i18n_error": "Cannot load the translations for the specified language",
      "item_doesnt_exist": "Element does not exist",
      "logged_out": "Your session has ended, please reconnect.",
      "notification": null,
      "updated": "Data updated"
    },
    "page": {
      "breadCrumb_create": "Create %{name}",
      "breadCrumb_edit": "Edit %{name}",
      "breadCrumb_list": "%{name}",
      "create": "Create %{name}",
      "dashboard": "Dashboard",
      "edit_title": "Edit %{name}",
      "empty": "No %{name} yet.",
      "empty_new": "Add New %{name}",
      "error": "Something went wrong",
      "invite": "Do you want to add one?",
      "list": "%{name}",
      "loading": "Loading",
      "new": "Add %{name}",
      "not_found": "Not Found",
      "page": null,
      "show": "%{name} #%{id}"
    },
    "ra": null,
    "validation": {
      "email": "Must be a valid email",
      "maxLength": "Must be %{max} characters or less",
      "maxValue": "Must be %{max} or less",
      "minLength": "Must be %{min} characters at least",
      "minValue": "Must be at least %{min}",
      "number": "Must be a number",
      "oneOf": "Must be one of: %{options}",
      "regex": "Must match a specific format (regexp): %{pattern}",
      "required": "Required",
      "validation": null
    }
  },
  "register": "Register",
  "required": "Required",
  "required_for_project": "required for project |||| required for projects",
  "resources": {
    "actions": {
      "actions": null,
      "fields": {
        "fields": null,
        "order": "Order",
        "q": "Search",
        "stage": "Stage",
        "title": "Title"
      },
      "name": "Action |||| Actions"
    },
    "adders": {
      "adders": null,
      "name": "Adder |||| Adders"
    },
    "battery_schemes": {
      "battery_schemes": null,
      "name": "Battery Control Scheme |||| Battery Control Schemes"
    },
    "categories": {
      "categories": null,
      "fields": {
        "fields": null,
        "products": "Products"
      },
      "name": "Category |||| Categories"
    },
    "commands": {
      "commands": null,
      "fields": {
        "basket": {
          "basket": null,
          "delivery": "Delivery",
          "quantity": "Quantity",
          "reference": "Reference",
          "sum": "Sum",
          "tax_rate": "Tax Rate",
          "total": "Total"
        },
        "date_gte": "Passed Since",
        "date_lte": "Passed Before",
        "fields": null
      },
      "name": "Order |||| Orders"
    },
    "commissions": {
      "commissions": null,
      "name": "Commission |||| Commissions"
    },
    "component_batteries": {
      "component_batteries": null,
      "fields": {
        "code": "Code",
        "component_batteries": "Component Batteries",
        "component_inverters": "Component Inverters",
        "component_modules": "Component Modules",
        "countries_exclude_csv": "Countries Exclude CSV",
        "countries_include_csv": "Countries Include CSV",
        "custom_data_field_aging_factor": "Aging Factor",
        "custom_data_field_code": "Code",
        "custom_data_field_depth_of_discharge_factor": "Depth of Discharge",
        "custom_data_field_efficiency_factor": "Roundtrip Efficiency",
        "custom_data_field_end_of_life_capacity": "End of Life Capacity",
        "custom_data_field_kwh_optimal": "Total Energy",
        "custom_data_field_manufacturer": "Manufacturer",
        "custom_data_field_power_max_continuous": "Max Continuous Power",
        "custom_data_field_product_warranty": "Product Warranty",
        "custom_data_field_skus": "SKUs",
        "custom_data_field_voltage": "Nominal Voltage",
        "custom_data_field_warranty_kwh_1_cycle_per_day": "Total Warranted kWh",
        "data": "Data",
        "fields": null,
        "manufacturer": "Manufacturer",
        "manufacturer_name": "Manufacturer",
        "q": "Search"
      },
      "name": "Component Battery |||| Batteries"
    },
    "component_battery_activations": {
      "component_battery_activations": null,
      "fields": {
        "_sku": "SKU (override)",
        "annual_degradation_override": "Annual Degradation",
        "battery": "Battery",
        "code": "Code",
        "component_battery_activations": "Component Battery Activations",
        "component_inverter_activations": "Component Inverter Activations",
        "component_module_activations": "Component Module Activations",
        "component_other_activations": "Component Other Activations",
        "custom_data_field_aging_factor": "Aging Factor",
        "custom_data_field_code": "Code",
        "custom_data_field_depth_of_discharge_factor": "Depth of Discharge",
        "custom_data_field_efficiency_factor": "Roundtrip Efficiency",
        "custom_data_field_end_of_life_capacity": "End of Life Capacity",
        "custom_data_field_kwh_optimal": "Total Energy",
        "custom_data_field_manufacturer": "Manufacturer",
        "custom_data_field_manufacturer_name": "Manufacturer",
        "custom_data_field_power_max_continuous": "Max Continuous Power",
        "custom_data_field_product_warranty": "Product Warranty",
        "custom_data_field_skus": "SKUs",
        "custom_data_field_voltage": "Nominal Voltage",
        "custom_data_field_warranty_kwh_1_cycle_per_day": "Total Warranted kWh",
        "data": "Data",
        "fields": null,
        "inverter": "Inverter",
        "is_archived": "Archive (hide component)",
        "is_default": "Is Default",
        "lookup_in_component_database": "Lookup In Component Database",
        "module": "Module",
        "performance_warranty": "Performance Warranty",
        "product_warranty": "Product Warranty",
        "q": "Search",
        "show_exhibitor_content": "Show Exhibitor Content",
        "show_only_archived": "Show Archived"
      },
      "name": "Battery |||| Batteries"
    },
    "component_content": {
      "component_content": null,
      "fields": {
        "associated_component_model_codes": "Model Codes",
        "component_content": "Component Content",
        "component_type": "Component Type",
        "fields": null,
        "is_approved": "Is Approved",
        "logo_image": "Logo Image",
        "model_codes": "Model Codes",
        "module_texture": "Module Texture",
        "promotional_image": "Promotional Image",
        "q": "Search",
        "spec_sheet_pdf": "Spec Sheet Pdf",
        "src": "Src",
        "title": "Title",
        "type": "Type",
        "video_url": "Video Url"
      },
      "name": "Component Content |||| Component Contents"
    },
    "component_inverter_activations": {
      "component_inverter_activations": null,
      "fields": {
        "_sku": "SKU (override)",
        "annual_degradation_override": "Annual Degradation",
        "battery": "Battery",
        "code": "Code",
        "component_battery_activations": "Component Battery Activations",
        "component_inverter_activations": "Component Inverter Activations",
        "component_module_activations": "Component Module Activations",
        "component_other_activations": "Component Other Activations",
        "custom_data_field_additional_parts_warranty": "Additional Parts Warranty",
        "custom_data_field_code": "Code",
        "custom_data_field_efficiency": "Efficiency",
        "custom_data_field_hybrid": "Hybrid",
        "custom_data_field_manufacturer": "Manufacturer",
        "custom_data_field_manufacturer_name": "Manufacturer",
        "custom_data_field_max_power_rating": "Rated Output Power",
        "custom_data_field_microinverter": "Microinverter",
        "custom_data_field_mppt_quantity": "Number of MPPTs",
        "custom_data_field_mppt_voltage_max": "MPPT Maximum Input Voltage",
        "custom_data_field_nominal_input_voltage": "Rated Input Voltage",
        "custom_data_field_phase_type": "Phase Type",
        "custom_data_field_power_consumption_at_night": "Night-time Consumption",
        "custom_data_field_product_warranty": "Product Warranty",
        "custom_data_field_skus": "SKUs",
        "custom_data_field_voltage_max": "Maximum Input Voltage",
        "custom_data_field_voltage_minimum": "Minimum Input Voltage",
        "custom_data_field_voltage_nominal": "Rated Output Voltage",
        "data": "Data",
        "default_inverter": "Default Inverter Range (replaces existing default)",
        "fields": null,
        "inverter": "Inverter",
        "is_archived": "Archive (hide component)",
        "is_default": "Is Default",
        "lookup_in_component_database": "Lookup In Component Database",
        "module": "Module",
        "performance_warranty": "Performance Warranty",
        "product_warranty": "Product Warranty",
        "q": "Search",
        "show_exhibitor_content": "Show Exhibitor Content",
        "show_only_archived": "Show Archived"
      },
      "name": "Inverter |||| Inverters"
    },
    "component_inverters": {
      "component_inverters": null,
      "fields": {
        "code": "Code",
        "component_batteries": "Component Batteries",
        "component_inverters": "Component Inverters",
        "component_modules": "Component Modules",
        "countries_exclude_csv": "Countries Exclude CSV",
        "countries_include_csv": "Countries Include CSV",
        "custom_data_field_additional_parts_warranty": "Additional Parts Warranty",
        "custom_data_field_code": "Code",
        "custom_data_field_efficiency": "Efficiency",
        "custom_data_field_hybrid": "Hybrid",
        "custom_data_field_manufacturer": "Manufacturer",
        "custom_data_field_max_power_rating": "Rated Output Power",
        "custom_data_field_microinverter": "Microinverter",
        "custom_data_field_mppt_quantity": "Number of MPPTs",
        "custom_data_field_mppt_voltage_max": "MPPT Maximum Input Voltage",
        "custom_data_field_mppt_voltage_min": "MPPT Minimum Input Voltage",
        "custom_data_field_nominal_input_voltage": "Rated Input Voltage",
        "custom_data_field_phase_type": "Phase Type",
        "custom_data_field_power_consumption_at_night": "Night-time Consumption",
        "custom_data_field_product_warranty": "Product Warranty",
        "custom_data_field_skus": "SKUs",
        "custom_data_field_voltage_max": "Maximum Input Voltage",
        "custom_data_field_voltage_minimum": "Minimum Input Voltage",
        "custom_data_field_voltage_nominal": "Rated Output Voltage",
        "data": "Data",
        "fields": null,
        "manufacturer": "Manufacturer",
        "manufacturer_name": "Manufacturer",
        "q": "Search"
      },
      "name": "Component Inverter |||| Inverters"
    },
    "component_module_activations": {
      "component_module_activations": null,
      "fields": {
        "_sku": "SKU (override)",
        "annual_degradation_override": "Annual Degradation",
        "battery": "Battery",
        "code": "Code",
        "component_battery_activations": "Component Battery Activations",
        "component_inverter_activations": "Component Inverter Activations",
        "component_module_activations": "Component Module Activations",
        "component_other_activations": "Component Other Activations",
        "custom_data_field_annual_degradation_override": "Annual Degradation",
        "custom_data_field_bifaciality": "Bifaciality",
        "custom_data_field_cells_in_series": "Cells In Series",
        "custom_data_field_code": "Code",
        "custom_data_field_colour": "Colour",
        "custom_data_field_first_year_degradation": "First Year Degradation",
        "custom_data_field_height": "Height",
        "custom_data_field_imp": "Maximum power Current (Imp)",
        "custom_data_field_isc": "Short Circuit Current (Isc)",
        "custom_data_field_kw_stc": "Rating",
        "custom_data_field_manufacturer": "Manufacturer",
        "custom_data_field_manufacturer_name": "Manufacturer",
        "custom_data_field_max_power_voltage": "Maximum Power Voltage (Vmp)",
        "custom_data_field_noct": "Nominal operating cell temperature",
        "custom_data_field_performance_warranty": "Performance Warranty",
        "custom_data_field_product_warranty": "Product Warranty",
        "custom_data_field_sandia_specs": "Sandia Specifications",
        "custom_data_field_skus": "SKUs",
        "custom_data_field_technology": "Technology",
        "custom_data_field_temp_coefficient_isc": "Temp. Coefficient Isc",
        "custom_data_field_temp_coefficient_voc": "Temp. Coefficient Voc",
        "custom_data_field_temp_coefficient_vpmax": "Temp. Coefficient Pmax",
        "custom_data_field_thickness": "Thickness",
        "custom_data_field_transmission": "Transmission (For Bifacial Transmission Factor Calculations)",
        "custom_data_field_voc": "Open Circuit Voltage (Voc)",
        "custom_data_field_weight": "Weight",
        "custom_data_field_width": "Width",
        "data": "Data",
        "default_module": "Default Module (replaces existing default)",
        "fields": null,
        "inverter": "Inverter",
        "is_archived": "Archive (hide component)",
        "is_default": "Is Default",
        "lookup_in_component_database": "Lookup In Component Database",
        "module": "Module",
        "performance_warranty": "Performance Warranty",
        "price_adjustment": "Price Adjustment",
        "product_warranty": "Product Warranty",
        "q": "Search",
        "show_exhibitor_content": "Show Exhibitor Content",
        "show_only_archived": "Show Archived"
      },
      "name": "Module |||| Modules"
    },
    "component_modules": {
      "component_modules": null,
      "fields": {
        "code": "Code",
        "component_batteries": "Component Batteries",
        "component_inverters": "Component Inverters",
        "component_modules": "Component Modules",
        "countries_exclude_csv": "Countries Exclude CSV",
        "countries_include_csv": "Countries Include CSV",
        "custom_data_field_annual_degradation_override": "Annual Degradation",
        "custom_data_field_bifaciality": "Bifaciality",
        "custom_data_field_cells_in_series": "Cells In Series",
        "custom_data_field_code": "Code",
        "custom_data_field_colour": "Colour",
        "custom_data_field_first_year_degradation": "First Year Degradation",
        "custom_data_field_height": "Height",
        "custom_data_field_imp": "Maximum power Current (Imp)",
        "custom_data_field_isc": "Short Circuit Current (Isc)",
        "custom_data_field_kw_stc": "Rating",
        "custom_data_field_manufacturer": "Manufacturer",
        "custom_data_field_max_power_voltage": "Maximum Power Voltage (Vmp)",
        "custom_data_field_noct": "Nominal operating cell temperature",
        "custom_data_field_performance_warranty": "Performance Warranty",
        "custom_data_field_product_warranty": "Product Warranty",
        "custom_data_field_sandia_specs": "Sandia Specifications",
        "custom_data_field_skus": "SKUs",
        "custom_data_field_technology": "Technology",
        "custom_data_field_temp_coefficient_isc": "Temp. Coefficient Isc",
        "custom_data_field_temp_coefficient_voc": "Temp. Coefficient Voc",
        "custom_data_field_temp_coefficient_vpmax": "Temp. Coefficient Pmax",
        "custom_data_field_thickness": "Thickness",
        "custom_data_field_transmission": "Transmission (For Bifacial Transmission Factor Calculations)",
        "custom_data_field_voc": "Open Circuit Voltage (Voc)",
        "custom_data_field_weight": "Weight",
        "custom_data_field_width": "Width",
        "data": "Data",
        "fields": null,
        "manufacturer": "Manufacturer",
        "manufacturer_name": "Manufacturer",
        "q": "Search"
      },
      "name": "Component Module |||| Modules"
    },
    "component_other_activations": {
      "component_other_activations": null,
      "fields": {
        "_sku": "SKU (override)",
        "annual_degradation_override": "Annual Degradation",
        "battery": "Battery",
        "code": "Code",
        "component_battery_activations": "Component Battery Activations",
        "component_inverter_activations": "Component Inverter Activations",
        "component_module_activations": "Component Module Activations",
        "component_other_activations": "Component Other Activations",
        "custom_data_field_additional_costs_enabled": "Enable Additional Costs",
        "custom_data_field_annual_adjustment_percentage": "Annual Adjustment",
        "custom_data_field_cable_thickness": "Cable Thickness",
        "custom_data_field_code": "code",
        "custom_data_field_current_rating": "Current Rating",
        "custom_data_field_current_type": "Current Type",
        "custom_data_field_cycle_units": "Cycle Units",
        "custom_data_field_dc_optimizer_efficiency": "DC Optimizer Efficiency",
        "custom_data_field_dc_optimizer_max_input_current": "DC Optimizer Max Input Current",
        "custom_data_field_dc_optimizer_max_input_power": "DC Optimizer Max Input Power",
        "custom_data_field_dc_optimizer_max_input_voltage": "DC Optimizer Max Input Voltage",
        "custom_data_field_dc_optimizer_max_output_current": "DC Optimizer Max Output Current",
        "custom_data_field_dc_optimizer_max_output_voltage": "DC Optimizer Max Output Voltage",
        "custom_data_field_description": "Description",
        "custom_data_field_end": "End Cycle",
        "custom_data_field_formula": "Formula",
        "custom_data_field_heat_pump_rated_current": "Heat Pump Rated Current",
        "custom_data_field_heat_pump_rated_power": "Heat Pump Rated Power",
        "custom_data_field_manufacturer": "Manufacturer",
        "custom_data_field_manufacturer_name": "Manufacturer",
        "custom_data_field_other_component_type": "Type",
        "custom_data_field_phase_type": "Phase Type",
        "custom_data_field_product_warranty": "Product Warranty",
        "custom_data_field_show_customer": "Show Customer",
        "custom_data_field_single_phase_max_output_power": "Single-Phase Max Output Power",
        "custom_data_field_skus": "SKUs",
        "custom_data_field_start": "Start Cycle",
        "custom_data_field_three_phase_max_output_power": "Three-Phase Max Output Power",
        "custom_data_field_title": "Title",
        "custom_data_field_value": "Value",
        "custom_data_field_voltage_rating": "Voltage Rating",
        "custom_data_field_voltage_to_current_rating": "Voltage to current rating",
        "custom_data_field_weight": "Weight",
        "data": "Data",
        "fields": null,
        "inverter": "Inverter",
        "is_archived": "Archive (hide component)",
        "is_default": "Is Default",
        "lookup_in_component_database": "Lookup In Component Database",
        "module": "Module",
        "performance_warranty": "Performance Warranty",
        "product_warranty": "Product Warranty",
        "q": "Search",
        "show_exhibitor_content": "Show Exhibitor Content",
        "show_only_archived": "Show Archived"
      },
      "name": "Other Component |||| Other Components"
    },
    "component_others": {
      "component_others": null,
      "fields": {
        "code": "Code",
        "countries_exclude_csv": "Countries Exclude CSV",
        "countries_include_csv": "Countries Include CSV",
        "custom_data_field_additional_costs_enabled": "Enable Additional Costs",
        "custom_data_field_annual_adjustment_percentage": "Annual Adjustment",
        "custom_data_field_cable_thickness": "Cable Thickness",
        "custom_data_field_code": "Code",
        "custom_data_field_current_rating": "Current Rating",
        "custom_data_field_current_type": "Current Type",
        "custom_data_field_cycle_units": "Cycle Units",
        "custom_data_field_dc_optimizer_efficiency": "DC Optimizer Efficiency",
        "custom_data_field_dc_optimizer_max_input_current": "DC Optimizer Max Input Current",
        "custom_data_field_dc_optimizer_max_input_power": "DC Optimizer Max Input Power",
        "custom_data_field_dc_optimizer_max_input_voltage": "DC Optimizer Max Input Voltage",
        "custom_data_field_dc_optimizer_max_output_current": "DC Optimizer Max Output Current",
        "custom_data_field_dc_optimizer_max_output_voltage": "DC Optimizer Max Output Voltage",
        "custom_data_field_description": "Description",
        "custom_data_field_end": "End Cycle",
        "custom_data_field_formula": "Formula",
        "custom_data_field_heat_pump_rated_current": "Heat Pump Rated Current",
        "custom_data_field_heat_pump_rated_power": "Heat Pump Rated Power",
        "custom_data_field_manufacturer": "Manufacturer",
        "custom_data_field_manufacturer_name": "Manufacturer",
        "custom_data_field_other_component_type": "Type",
        "custom_data_field_phase_type": "Phase Type",
        "custom_data_field_product_warranty": "Product Warranty",
        "custom_data_field_show_customer": "Show Customer",
        "custom_data_field_single_phase_max_output_power": "Single-Phase Max Output Power",
        "custom_data_field_skus": "SKUs",
        "custom_data_field_start": "Start Cycle",
        "custom_data_field_three_phase_max_output_power": "Three-Phase Max Output Power",
        "custom_data_field_title": "Title",
        "custom_data_field_value": "Value",
        "custom_data_field_voltage_rating": "Voltage Rating",
        "custom_data_field_voltage_to_current_rating": "Voltage to current rating",
        "custom_data_field_weight": "Weight",
        "fields": null,
        "manufacturer": "Manufacturer",
        "manufacturer_name": "Manufacturer"
      },
      "name": "Others |||| Other Components"
    },
    "contacts": {
      "contacts": null,
      "fields": {
        "contact": "Contact",
        "date_of_birth": "Date of Birth",
        "email": "Email",
        "family_name": "Family Name",
        "fields": null,
        "first_name": "First Name",
        "gender": "Gender",
        "is_active": "Is Active",
        "licence_number": "Licence Number",
        "middle_name": "Middle Name",
        "passport_number": "Passport Number",
        "phone": "Phone",
        "projects": "Projects",
        "q": "Search"
      },
      "name": "Contact |||| Contacts"
    },
    "contracts": {
      "contracts": null,
      "name": "Contract |||| Contracts"
    },
    "costings": {
      "costings": null,
      "name": "Cost Scheme |||| Cost Schemes"
    },
    "countries": {
      "countries": null,
      "fields": {
        "fields": null,
        "id": "Id",
        "iso2": "Iso2",
        "name": "Name"
      }
    },
    "custom_fields": {
      "custom_fields": null,
      "fields": {
        "field": "Field",
        "field_type": "Field Type",
        "fields": null,
        "label": "Label"
      },
      "name": "Custom Field |||| Custom Fields"
    },
    "custom_forms": {
      "custom_forms": null,
      "name": "Form |||| Forms"
    },
    "customers": {
      "customers": null,
      "fields": {
        "commands": "Orders",
        "fields": null,
        "groups": "Segments",
        "last_seen_gte": "Visited Since",
        "name": "Name"
      },
      "name": "Customer |||| Customers",
      "notification": {
        "notification": null,
        "send_invitation": "Sending...",
        "send_invitation_error": "Error sending Invitation(s)",
        "send_invitation_success": "Invitation(s) sent"
      },
      "page": {
        "delete": "Delete Customer",
        "page": null
      },
      "tabs": {
        "address": "Address",
        "identity": "Identity",
        "orders": "Orders",
        "reviews": "Reviews",
        "stats": "Stats",
        "tabs": null
      }
    },
    "distributors": {
      "distributors": null,
      "fields": {
        "country": "Country",
        "fields": null,
        "name": "Name",
        "q": "Search",
        "states_csv": "States Csv",
        "zips_csv": "Zips Csv"
      }
    },
    "document_templates": {
      "document_templates": null,
      "fields": {
        "content": "Content",
        "description": "Description",
        "document_templates": "Document Templates",
        "fields": null,
        "q": "Search",
        "title": "Title",
        "type": "Type"
      },
      "name": "Document Template |||| Document Templates"
    },
    "events": {
      "events": null,
      "fields": {
        "action": "Action Completion",
        "actions": "Actions",
        "assigned_to": "Assigned To",
        "contact": "Contact",
        "contacts": "Contacts",
        "display": "Display",
        "duration": "Duration",
        "end": "End",
        "event_type_id": " Activity Type",
        "events": "Events",
        "fields": null,
        "is_archived": "Archived",
        "is_complete": "Is Complete",
        "is_scheduled": "Scheduled Activity",
        "is_unscheduled": "Unscheduled Activity",
        "modified_date": "Modified Date",
        "name": "Name",
        "notes": "Notes",
        "project": "Project",
        "project__stage": "Project  Stage",
        "start": "Start",
        "task_status": "Task Status",
        "team_members": "Team Members",
        "title": "Title"
      }
    },
    "file_tags": {
      "fields": {
        "fields": null,
        "file_contents": "File Contents",
        "file_tags": "File Tags",
        "src": "Src",
        "title": "Title"
      },
      "file_tags": null
    },
    "incentives": {
      "fields": {
        "auto_apply": "Auto-apply",
        "auto_apply_enabled": "Auto-apply Enabled",
        "auto_apply_max_system_size": "Auto-apply Max System Size",
        "auto_apply_min_system_size": "Auto-apply Min System Size",
        "auto_apply_only_specified_states": "Auto-apply only to specified states",
        "auto_apply_only_specified_zips": "Auto-apply only to specified postcodes",
        "auto_apply_sector": "Auto-apply Sector",
        "cap_amount": "Cap Amount",
        "description": "Description",
        "fields": null,
        "incentive_paid_to": "Incentive Paid To",
        "incentive_type": "Incentive Type",
        "incentives": "Incentives",
        "is_archived": "Archive (hide component)",
        "q": "Search",
        "settings": "Settings",
        "show_only_archived": "Show Archived",
        "title": "Title",
        "use_for_loan_pay_down": "Use for Loan Pay Down",
        "variable_annual_value_adjustment_percentage": "Annual Adjustment Percentage",
        "variable_depreciation_basis": "Depreciation Basis",
        "variable_depreciation_percent_per_year": "Depreciation Per Year",
        "variable_incentive_price": "Incentive Price",
        "variable_number_of_years": "Number Of Years",
        "variable_percent_of_basis": "Percentage of Basis",
        "variable_price_based_incentive_type": "Price Based Incentive Type",
        "variable_size_based_incentive_type": "Size Based Incentive Type",
        "variable_tax_jurisdiction": "Tax Jurisdiction",
        "variable_tax_rate_percent": "Default Tax Rate",
        "variable_use_non_discounted_value_in_quotation": "Use Non-discounted Incentive Amount in Quotation Table",
        "variable_value_by_year": "Value By Year",
        "variable_years_remaining": "Years Remaining"
      },
      "incentives": null,
      "name": "Incentive |||| Incentives"
    },
    "inventory_transfers": {
      "fields": {
        "fields": null,
        "id": "Id",
        "line_item_code": "Code",
        "line_item_quantity": "Quantity",
        "line_item_unit_cost": "Unit Cost",
        "name": "Name",
        "projects": "Projects",
        "transfer_date": "Transfer Date",
        "transfer_from": "Transfer From",
        "transfer_to": "Transfer To"
      },
      "inventory_transfers": null,
      "name": "Inventory Transfer |||| Inventory Transfers"
    },
    "lead_capture_forms": {
      "fields": {
        "assign_to_team_member": "Assign To Team Member",
        "fields": null,
        "form_heading": "Form Heading",
        "form_subheading": "Form Subheading",
        "format": "Format",
        "last_name_setting": "Last Name Setting",
        "lead_capture_forms": "Lead Captures",
        "lead_source": "Lead Source",
        "notes_setting": "Notes Setting",
        "number_of_phases_setting": "Number of Phases Setting",
        "phone_setting": "Phone Setting",
        "recaptcha_secret_key": "Recaptcha Secret Key",
        "recaptcha_site_key": "Recaptcha Site Key",
        "roles": "Roles",
        "roof_type_setting": "Roof Type Setting",
        "submit_button_label": "Submit Button Label",
        "thank_you_message": "Thank You Message",
        "title": "Title"
      },
      "lead_capture_forms": null,
      "name": "Lead Capture |||| Lead Capture"
    },
    "orgs": {
      "fields": {
        "about_content": "About Content",
        "accept_credit_card_stripe": "Accept Credit Card (Stripe)",
        "accept_offline": "Accept Offline",
        "address": "Address",
        "allow_sms_mfa": "Allow SMS MFA",
        "api_key_bing": "Bing Map API Key",
        "api_key_cyclomedia": "Cyclomedia API Key",
        "api_key_google": "Google Map API Key",
        "api_key_metromap": "MetroMap API Key",
        "api_key_nearmap": "Nearmap API Key",
        "api_key_utility_api": "UtilityAPI API Key",
        "business_name": "Business Name",
        "color_highlight": "Color Highlight",
        "company_email": "Business Email",
        "company_website": "Website",
        "country": "Country",
        "credit_card_stripe_publishable_key": "Stripe Publishable Key",
        "credit_card_stripe_secret_key": "Stripe Secret Key",
        "credit_card_stripe_surcharge_fixed": "Credit Card Surcharge Fixed",
        "credit_card_stripe_surcharge_percentage": "Credit Card Surcharge (%)",
        "enable_checkout": "Enable Checkout",
        "enable_exhibit": "Enable Exhibit",
        "enable_other_imagery": "Enable Other Imagery",
        "exhibit_activation_codes": "Exhibit Activation Codes",
        "exhibit_is_public": "Exhibit Is Public",
        "fields": null,
        "invitation_email_custom_message": "Invitation Email Custom Message",
        "invitation_email_custom_subject": "Invitation Email Custom Subject",
        "locality": "City/Locality",
        "logo_file_contents": "Logo File Contents",
        "measurement_units_override": "Measurement Units Override",
        "name": "Business Name",
        "offline_content": "Offline Payment Details",
        "offline_surcharge_fixed": "Offline Surcharge Fixed",
        "offline_surcharge_percentage": "offline surcharge",
        "offline_title": "Offline Title",
        "quote_acceptance_content": "Quote Acceptance Content",
        "quote_acceptance_heading": "Quote Acceptance Title",
        "sales_phone_number": "Business Phone Number",
        "src": "Src",
        "state": "State",
        "terms_and_conditions": "Terms And Conditions",
        "zip": "Postcode"
      },
      "orgs": null
    },
    "payment_options": {
      "fields": {
        "actions": "Actions",
        "auto_apply": "Auto-apply",
        "auto_apply_enabled": "Auto-apply Enabled",
        "auto_apply_only_specified_states": "Auto-apply Only Specified States",
        "auto_apply_only_specified_zips": "Auto-apply Only Specified Zips",
        "configuration_json": "Configuration Json",
        "contract_template": "Contract Template",
        "description": "Description",
        "fields": null,
        "is_archived": "Archive (hide component)",
        "payment_options": "Payment Options",
        "payment_type": "Payment Type",
        "priority": "Priority",
        "q": "Search",
        "show_only_archived": "Show Archived",
        "title": "Title",
        "utility_tariff_override": "Utility Tariff Override",
        "variable_additional_details": "Additional Details",
        "variable_brokerage_rate": "Brokerage Rate",
        "variable_cap_total_kwh": "Cap Total",
        "variable_collect_signature": " Collect Signature on Checkout",
        "variable_dealer_fee": "Dealer Fee",
        "variable_dealer_fee_fixed": "Dealer Fee Fixed",
        "variable_dealer_fee_percentage": "Dealer Fee",
        "variable_delayed_down_payment_duration": "Duration",
        "variable_deposit_max": "Deposit Maximum",
        "variable_deposit_min": "Deposit Minimum",
        "variable_deposit_percentage": "Deposit",
        "variable_down_payment_dealer_fee_fixed": "Dealer Fee",
        "variable_down_payment_dealer_fee_percentage": "Dealer Fee",
        "variable_down_payment_enabled": "Enable Down Payment",
        "variable_down_payment_interest_rate": "Interest Rate",
        "variable_down_payment_max": "Down Payment Maximum",
        "variable_down_payment_min": "Down Payment Minimum",
        "variable_down_payment_percentage": "Down Payment",
        "variable_escalator_annual": "Lease Payment Escalator",
        "variable_escalator_percentage": "Escalator Percentage",
        "variable_final_bullet_enabled": "Bullet/Balloon Payment",
        "variable_final_dealer_fee_fixed": "Dealer Fee",
        "variable_final_dealer_fee_percentage": "Dealer Fee",
        "variable_final_interest_rate": "Interest Rate",
        "variable_final_payment_frequency": "Payment Frequency",
        "variable_final_term": "Term",
        "variable_initial_bullet_enabled": "Bullet/Balloon Payment",
        "variable_initial_dealer_fee_fixed": "Dealer Fee",
        "variable_initial_dealer_fee_percentage": "Dealer Fee",
        "variable_initial_interest_rate": "Interest Rate",
        "variable_initial_loan_enabled": "Enable Initial Loan Term",
        "variable_initial_payment_frequency": "Payment Frequency",
        "variable_initial_principal_reduction": "Amount",
        "variable_initial_principal_reduction_max": "Maximum",
        "variable_initial_principal_reduction_min": "Minimum",
        "variable_initial_term": "Term",
        "variable_interest_only_period": "Interest Only Period",
        "variable_interest_rate": "Interest Rate",
        "variable_lender_name": "Lender Name",
        "variable_loan_term": "Loan Term",
        "variable_loan_type": "Loan Type",
        "variable_no_interest_period": "No Interest Period",
        "variable_no_payment_period": "No Interest Period",
        "variable_payment_amount": "Payment Amount",
        "variable_payment_frequency": "Payment Frequency",
        "variable_payment_timing": "Payment Timing",
        "variable_ppa_dollars_per_kwh": "PPA",
        "variable_ppa_dollars_per_kwh_exports": "PPA Exports",
        "variable_ppa_escalator_annual": "PPA Escalator",
        "variable_ppa_escalator_annual_exports": "PPA Export Escalator",
        "variable_ppa_upfront_payment": "PPA Upfront Payment",
        "variable_prepayment_formula": "Prepayment Formula",
        "variable_prepayment_percentage": "Prepayment Percentage",
        "variable_prepayment_period": "Prepayment Period",
        "variable_term": "Term",
        "variable_term_years": "Term Years"
      },
      "name": "Payment Option |||| Payment Options",
      "payment_options": null
    },
    "pricing_schemes": {
      "fields": {
        "auto_apply": "Auto-apply",
        "auto_apply_enabled": "Auto-apply Enabled",
        "auto_apply_only_specified_states": "Auto-apply Only to Specified States",
        "auto_apply_only_specified_zips": "Auto-apply Only to Specified Zips",
        "configuration_json": "Configuration Json",
        "fields": null,
        "is_archived": "Archive (hide component)",
        "pricing_formula": "Pricing Formula",
        "pricing_schemes": "Pricing Schemes",
        "priority": "Priority",
        "q": "Search",
        "show_only_archived": "Show Archived",
        "title": "Title",
        "variable_battery_price_per_kwh": "Battery Price Per kWh",
        "variable_battery_price_per_kwh_0_5": "Battery Price Per kWh (0-5)",
        "variable_battery_price_per_kwh_100_plus": "Battery Price Per kWh (100+)",
        "variable_battery_price_per_kwh_10_100": "Battery Price Per kWh (10-100)",
        "variable_battery_price_per_kwh_5_10": "Battery Price Per kWh (5-10)",
        "variable_deduct_incentive_to_installer_before_markup": "Apply markup after applying incentives to installer",
        "variable_markup_percentage": "Markup Percentage",
        "variable_price": "Price",
        "variable_price_per_watt": "Price Per Watt",
        "variable_price_per_watt_0_1": "Price Per Watt (0-1kW)",
        "variable_price_per_watt_100_plus": "Price Per Watt (100kW+)",
        "variable_price_per_watt_10_12": "Price Per Watt (10kW-12kW)",
        "variable_price_per_watt_12_14": "Price Per Watt (12kW-14kW)",
        "variable_price_per_watt_14_16": "Price Per Watt (14kW-16kW)",
        "variable_price_per_watt_16_18": "Price Per Watt (16kW-18kW)",
        "variable_price_per_watt_18_20": "Price Per Watt (18kW-20kW)",
        "variable_price_per_watt_1_2": "Price Per Watt (1kW-2kW)",
        "variable_price_per_watt_20_30": "Price Per Watt (20kW-30kW)",
        "variable_price_per_watt_2_3": "Price Per Watt (2kW-3kW)",
        "variable_price_per_watt_30_40": "Price Per Watt (30kW-40kW)",
        "variable_price_per_watt_3_4": "Price Per Watt (3kW-4kW)",
        "variable_price_per_watt_40_50": "Price Per Watt (40kW-50kW)",
        "variable_price_per_watt_4_5": "Price Per Watt (4kW-5kW)",
        "variable_price_per_watt_50_60": "Price Per Watt (50kW-60kW)",
        "variable_price_per_watt_5_6": "Price Per Watt (5kW-6kW)",
        "variable_price_per_watt_60_70": "Price Per Watt (60kW-70kW)",
        "variable_price_per_watt_6_7": "Price Per Watt (6kW-7kW)",
        "variable_price_per_watt_70_80": "Price Per Watt (70kW-80kW)",
        "variable_price_per_watt_7_8": "Price Per Watt (7kW-8kW)",
        "variable_price_per_watt_80_90": "Price Per Watt (80kW-90kW)",
        "variable_price_per_watt_8_9": "Price Per Watt (8kW-9kW)",
        "variable_price_per_watt_90_100": "Price Per Watt (90kW-100kW)",
        "variable_price_per_watt_9_10": "Price Per Watt (9kW-10kW)",
        "variable_tax_mode": "Including/Excluding Tax",
        "variable_tax_percentage_included": "Tax"
      },
      "name": "Pricing scheme |||| Pricing schemes",
      "pricing_schemes": null
    },
    "private_files": {
      "fields": {
        "fields": null,
        "file_contents": "File Contents",
        "file_tags": "File Tags",
        "file_tags_data": "File Tags",
        "filesize": "filesize",
        "modified_date": "Modified Date",
        "project": "Project",
        "src": "Src",
        "title": "Title"
      },
      "private_files": null
    },
    "products": {
      "fields": {
        "category_id": "Category",
        "fields": null,
        "height": "Height",
        "image": "Image",
        "inventory": "Inventory",
        "price": "Price",
        "reference": "Reference",
        "thumbnail": "Thumbnail",
        "width": "Width"
      },
      "name": "Poster |||| Posters",
      "products": null,
      "tabs": {
        "description": "Description",
        "details": "Details",
        "image": "Image",
        "reviews": "Reviews",
        "tabs": null
      }
    },
    "project_configurations": {
      "fields": {
        "apply_tilt_racks_below_slope": "Apply Tilt Racks Below Slope",
        "description": "Description",
        "discount_rate": "Discount Rate",
        "feed_in_tariff_inflation": "Feed-in Tariff Inflation",
        "feed_in_tariff_inflation_annual": "Feed In Tariff Inflation Annual",
        "fields": null,
        "inverter_modelling_automation": "Inverter Modelling Automation",
        "performance_adjustment": "Performance Adjustment",
        "priority": "Priority",
        "project_configurations": "Project Configurations",
        "proposed_usage_adjustment": "Proposed Usage Adjustment",
        "setbacks_arrays": "Setbacks Arrays",
        "setbacks_default": "Setbacks Default",
        "setbacks_dormer": "Setbacks Dormer",
        "setbacks_flat_gutter": "Setbacks Flat Gutter",
        "setbacks_gutter": "Setbacks Gutter",
        "setbacks_hip": "Setbacks Hip",
        "setbacks_objects": "Setbacks Objects",
        "setbacks_rake": "Setbacks Rake",
        "setbacks_ridge": "Setbacks Ridge",
        "setbacks_shared": "Setbacks Shared",
        "setbacks_skylight": "Setbacks Skylight",
        "setbacks_valley": "Setbacks Valley",
        "steep_pitch_1_slope": "Steep Pitch Slope",
        "steep_pitch_2_slope": "Very Steep Pitch Slope",
        "tilt_rack_default_orientation": "Tilt Rack Default Orientation",
        "tilt_rack_default_tilt": "Tilt Rack Default Tilt",
        "title": "Title",
        "usage_adjustment_after_sale": "Usage Adjustment after sale",
        "utility_inflation_annual": "Utility Inflation Annual",
        "years_to_simulate": "Years To Simulate"
      },
      "name": "Setback & Design Settings |||| Presets",
      "project_configurations": null
    },
    "projects": {
      "fields": {
        "action_complete": "Action Complete",
        "action_incomplete": "Action Incomplete",
        "actions": "Actions",
        "add_more_details": "Add More Details",
        "address": "Address",
        "assigned_designer_role_id": "Assigned Designer",
        "assigned_installer_role_id": "Assigned Installer",
        "assigned_role_id": "Assigned Team Member",
        "assigned_salesperson_role_id": "Assigned Salesperson",
        "assigned_site_inspector_role_id": "Assigned Site Inspector",
        "bill_annual": "Bill Annual",
        "business_identifier": "Business Identifier",
        "business_name": "Business Name",
        "contacts": "Contacts",
        "contacts_new": "Contacts New",
        "controlled_load_daily_kwh_0": "Controlled load 1 daily usage",
        "controlled_load_daily_kwh_1": "Controlled load 2 daily usage",
        "controlled_load_daily_kwh_2": "Controlled load 3 daily usage",
        "country": "Country",
        "country_iso2": "Country",
        "county": "County",
        "created_date_gte": "Created On/After",
        "created_date_lte": "Created On/Before",
        "curve_weekday": "Weekday Usage Curve",
        "curve_weekend": "Weekend Usage Curve",
        "demand_60min": "Hourly Demand (8760 kW CSV)",
        "federal_income_tax_rate_percentage": "Federal Income Tax Rate",
        "fields": null,
        "filter": "Recently Viewed",
        "has_cellular_coverage": "Cellular Coverage",
        "has_installed_system": "Has Installed System",
        "has_premium_imagery_activation": "Has Premium Imagery",
        "has_sold_system": "Has Sold System",
        "has_system_larger_than": "With System Larger Than",
        "has_system_without_pricing_scheme": "Has System Without Pricing Scheme",
        "has_systems": "Has Systems",
        "installation_date_gte": "Install On/After",
        "installation_date_lte": "Install On/Before",
        "interval_60min": "Hourly Usage (8760 kWh CSV)",
        "is_unassigned": "Unassigned",
        "kwh_annual": "Annual kWh",
        "lat": "Latitude",
        "lead_source": "Lead Source",
        "locality": "Locality",
        "lon": "Longitude",
        "modified_date_gte": "Modified On/After",
        "modified_date_lte": "Modified On/Before",
        "notes": "Notes",
        "power_factor": "Power Factor",
        "priority": "Priority",
        "proposal_template": "Proposal Template",
        "q": "Search",
        "roles": "Assigned Team Member",
        "scale_weekend": "Scale weekend relative to weekday",
        "sold_on_or_after": "Sold On/After",
        "sold_on_or_before": "Sold On/Before",
        "stage": "Stage",
        "starred": "Starred",
        "stars": "Stars",
        "state": "State",
        "state_income_tax_rate_percentage": "State/Local Income Tax Rate",
        "states": "State",
        "system_installed": "System Installed",
        "systems__pricing_scheme": "With system using Pricing Scheme",
        "tags": "Tags",
        "testimonials": "Override defaults",
        "title": "Title",
        "usage_data_source": "Data source",
        "utility_tariff_current": "Utility Tariff Current",
        "utility_tariff_current__utility": "Utility Tariff Current  Utility",
        "utility_tariff_proposed": "Utility Tariff Proposed",
        "zip": "Postcode",
        "zips": "Zip"
      },
      "name": "Project |||| Projects",
      "projects": null
    },
    "proposal_templates": {
      "fields": {
        "about_us_content": "About Us",
        "business_video_link": "Youtube Video Link",
        "content_block_1": "Content Block 1",
        "content_block_2": "Content Block 2",
        "content_block_3": "Content Block 3",
        "disclaimer_daily_energy_flows": "Daily Energy Flows",
        "disclaimer_environmental_benefits": "Environmental Benefits",
        "disclaimer_financial_impact": "Financial Impact",
        "disclaimer_payment_option_cash": "Financial Impact - Cash",
        "disclaimer_payment_option_lease": "Financial Impact - Lease",
        "disclaimer_payment_option_loan": "Financial Impact - Loan",
        "disclaimer_payment_option_ppa": "Financial Impact - PPA",
        "disclaimer_payment_option_regular_payment": "Financial Impact - Regular Payment",
        "disclaimer_system_hardware_components": "System Hardware Components",
        "disclaimer_system_performance": "Bill Savings",
        "disclaimer_system_performance_new": "System Performance",
        "disclaimer_system_price": "System Quotation",
        "exhibit_content": "Exhibit Content",
        "featured_product_types": "Featured Product Types",
        "fields": null,
        "footer_column_1": "Footer Column 1 of 4",
        "footer_column_2": "Footer Column 2 of 4",
        "footer_column_3": "Footer Column 3 of 4",
        "footer_column_4": "Footer Column 4 of 4",
        "footer_column_left": "Footer Column Left",
        "header_column_1": "Header Column 1 of 3",
        "header_column_2": "Header Column 2 of 3",
        "header_column_3": "Header Column 3 of 3",
        "heading_case_studies": "Case Study",
        "heading_cover": "Cover Page Title",
        "heading_daily_energy_flows": "Daily Energy Flows",
        "heading_electricity_bill_savings": "Electricity Bill Savings",
        "heading_environmental_benefits": "Environmental Benefits",
        "heading_how_your_system_works": "How Your System Works",
        "heading_net_financial_impact": "Net Financial Impact",
        "heading_next_steps": "Next Steps",
        "heading_payment_option": "Payment Option",
        "heading_recommended_system_option": "Recommended System Option",
        "heading_system_hardware": "Your Solution",
        "heading_system_performance": "System Performance",
        "highlight_rows": "Featured Figures",
        "is_archived": "Archive",
        "is_default": "Is Default",
        "letter_page_content": "Letter Page Content",
        "next_steps_content": "Next Steps",
        "pdf_payment_options_to_include": "Payment Options to include in PDF",
        "pdf_systems_to_include": "Systems to include in PDF",
        "proposal_checkout_buttons": "Checkout Buttons",
        "proposal_templates": "Proposal Templates",
        "q": "Search",
        "qr_code_toggle": "Proposal QR Code",
        "quotation_custom_content": "Quotation Content",
        "sections": "Sections",
        "settings": "Settings",
        "show_exhibitor_content": "Show Exhibitor Content",
        "show_only_archived": "Show Archived",
        "show_product_types": "Show Product Types",
        "src": "Src",
        "title": "Title",
        "valid_for_days": "Default Proposal Validity (days)"
      },
      "name": "Proposal Template |||| Proposal Templates",
      "proposal_templates": null
    },
    "public_files": {
      "fields": {
        "fields": null,
        "file_contents": "File Contents",
        "file_tags": "File Tags",
        "project": "Project",
        "src": "Src",
        "title": "Title"
      },
      "public_files": null
    },
    "public_orgs": {
      "fields": {
        "exhibit_data_shares": "Exhibit Data Sharing",
        "fields": null
      },
      "public_orgs": null
    },
    "resources": null,
    "reviews": {
      "action": {
        "accept": "Accept",
        "action": null,
        "reject": "Reject"
      },
      "fields": {
        "comment": "Comment",
        "date": "Date",
        "date_gte": "Posted since",
        "date_lte": "Posted before",
        "fields": null,
        "product_id": "Product",
        "rating": "Rating"
      },
      "name": "Review |||| Reviews",
      "reviews": null
    },
    "roles": {
      "fields": {
        "accreditation": "Accreditation",
        "allow_email_notifications": "Allow Email Notifications",
        "api_key_chat": "Olark Chat API Key",
        "display": "Name",
        "email": "Display Email (optional)",
        "family_name": "Last Name",
        "fields": null,
        "first_name": "First Name",
        "is_admin": "Is Admin",
        "job_title": "Job Title",
        "phone": "Phone",
        "portrait_image": "Upload Portrait",
        "q": "Search",
        "schedule_meeting_label": "Button Label (optional)",
        "schedule_meeting_url": "External URL",
        "src": "Src",
        "user_email": "User Email"
      },
      "name": "Team Member |||| Team Members",
      "roles": null
    },
    "roof_types": {
      "fields": {
        "fields": null,
        "name": "Name"
      },
      "name": "Roof Type |||| Roof Types",
      "roof_types": null
    },
    "sales_territories": {
      "fields": {
        "counties": "Counties (CSV)",
        "countries": "Countries (ISO2, CSV)",
        "fields": null,
        "lat": "Latitude",
        "lead_recipient": "Assigned Team Member",
        "localities": "Cities/Localities (CSV)",
        "lon": "Longitude",
        "radius": "Radius",
        "states": "States (CSV)",
        "title": "Sales Territories Title",
        "weighting": "Weighting",
        "zips": "Zips (CSV)"
      },
      "name": "Sales Territory |||| Sales Territories",
      "sales_territories": null
    },
    "segments": {
      "data": {
        "collector": "Collector",
        "compulsive": "Compulsive",
        "data": null,
        "ordered_once": "Ordered once",
        "regular": "Regular",
        "returns": "Returns",
        "reviewer": "Reviewer"
      },
      "fields": {
        "customers": "Customers",
        "fields": null,
        "name": "Name"
      },
      "name": "Segment |||| Segments",
      "segments": null
    },
    "tags": {
      "fields": {
        "allow_fetch": "Allow Fetch",
        "create_activity_when_fetched": "Create activity when fetched",
        "fields": null,
        "remove_tag_when_fetched": "Remove tag when fetched",
        "title": "Title"
      },
      "name": "Tag |||| Project Tags",
      "tags": null
    },
    "testimonials": {
      "fields": {
        "auto_apply_enabled": "Auto-apply Enabled",
        "content": "Content",
        "fields": null,
        "image_file_contents": "Upload Image",
        "name": "Name",
        "order": "Order",
        "src": "Src",
        "title": "Title"
      },
      "name": "Case Study |||| Case Studies",
      "testimonials": null
    },
    "transactions": {
      "fields": {
        "amount": "Amount",
        "details": "Details",
        "fields": null,
        "funds_confirmed": "Funds Confirmed",
        "id": "Id",
        "is_active": "Is Active",
        "is_complete": "Is Complete",
        "project": "Project",
        "project_name": "Project Name",
        "q": "Search",
        "surcharge_amount": "Surcharge Amount",
        "tax_included": "Tax Included",
        "transaction_date_gte": "Transaction On/After",
        "transaction_date_lte": "Transaction On/Before",
        "transaction_datetime": "Transaction Datetime",
        "transaction_type": "Transaction Type"
      },
      "name": "Project Transaction |||| Project Transactions",
      "transactions": null
    },
    "users": {
      "fields": {
        "email": "Email",
        "fields": null,
        "phone": "Phone"
      },
      "name": "User |||| Users",
      "users": null
    },
    "utilities": {
      "fields": {
        "country": "Country",
        "fields": null,
        "name": "Name",
        "phone": "Phone",
        "q": "Search",
        "states_csv": "States Csv",
        "website": "Website",
        "zips_csv": "Zips Csv"
      },
      "utilities": null
    },
    "utility_tariffs": {
      "name": "Utility Tariff |||| Utility Tariffs",
      "utility_tariffs": null
    }
  },
  "self_consumption_override": "Self Consumption Override",
  "shopping_cart_action_subtotal": "Subtotal (%{smart_count} item) |||| Subtotal (%{smart_count} items)",
  "show_only_archived": "Show Archived (%{quantityArchived})",
  "sign_in": "Sign In",
  "sunlight_loan_application": "Sunlight Loan Application",
  "sunlight_loan_prequal": "Sunlight Loan Prequal",
  "tons": "tonnes"
}
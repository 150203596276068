import type BrandingConfig from './types'

const IronridgeBrandingConfig: BrandingConfig = {
  name: 'ironridge',
  header: {
    headerState: 'ironridge',
  },
  homeOverride: {
    placeholderLogo: 'ironridge_logo.png',
  },
  meta: {
    favicon: 'atlas-logo-only.svg',
    title: 'Atlas Proposal',
  },
}

export default IronridgeBrandingConfig

import Grid from '@material-ui/core/Grid'
import type { OpenSolarThemeType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import Button from 'elements/button/Button'
import useForecastDetailDialogController from 'pages/inventory/forecast/detail/useForecastDetailDialogController'
import React, { memo, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import ComponentForecastDialog from '../inventory/modal/ComponentForecastDialog'
import EditableInventoryTable from '../inventory/table/edit/EditableInventoryTable'
import InventoryListActions from '../inventory/table/list/InventoryListActions'
import StatelessInventoryTable from '../inventory/table/list/StatelessInventoryTable'
import type { InventoryType } from '../inventory/type'
import type { InventoryLocationType } from '../location/type'

const useStyles = makeOpenSolarStyles(
  (theme: OpenSolarThemeType) => ({
    table: {
      tableLayout: 'fixed',
    },
    container: {},
  }),
  { name: 'Inventory-Table-Container' }
)

type InventoryTableProps = {
  title: React.ReactNode
  inventoryDataLoaded: boolean // initial data loaded
  inventoryData: InventoryType[] | undefined
  loadingInventoryData: boolean
  reloadInventoryTable(): void
  location: InventoryLocationType | undefined
}

const InventoryToolbarList = ({ title, handleAdd }) => (
  <Grid container justify="space-between">
    <Grid item>{title}</Grid>
    <Grid item>
      <Button
        size="small"
        onClick={() => {
          handleAdd('on')
        }}
      >
        Search for components to add
      </Button>
    </Grid>
  </Grid>
)

const InventoryToolbarEdit = ({ title }) => <div>{title}</div>

// Cannot unify it with inventory list because the toolbar is different
const InventoryTable = ({
  title,
  inventoryData,
  loadingInventoryData,
  reloadInventoryTable,
  inventoryDataLoaded,
  location,
}: InventoryTableProps) => {
  const history = useHistory()
  const [editing, setEditing] = useState(false)
  const [editMin, setEditMin] = useState(false)
  const [editOnHand, setEditOnHand] = useState(false)
  const forecastDetailDialogController = useForecastDetailDialogController({ afterClose: reloadInventoryTable })
  const limitedDataToDisplay = inventoryData?.slice(0, 10).sort((a, b) => b.allocated - a.allocated)
  const openForecastDialog = useCallback(({ code }: { code: string }) => {
    forecastDetailDialogController.setFilter({ code })
    forecastDetailDialogController.onOpen()
  }, [])

  const toggleEditOn = useCallback((arg) => {
    switch (arg) {
      case 'min':
        setEditMin(true)
        break
      case 'onHand':
        setEditOnHand(true)
        break
      default:
        setEditing(true)
        break
    }
    logAmplitudeEvent('generic_button_clicked', {
      source: 'edit_stock',
      context: 'inventory_list_table',
    })
  }, [])

  const toggleEditOff = useCallback(() => {
    setEditing(false)
    setEditMin(false)
    setEditOnHand(false)
  }, [])

  const onSaveSuccess = useCallback(async () => {
    setEditing(false)
    setEditMin(false)
    setEditOnHand(false)
    await reloadInventoryTable()
  }, [reloadInventoryTable])

  if (location === undefined) {
    // TODO: optimise this
    return <div>Primary warehouse not found</div>
  }

  return (
    <>
      {editing || editMin || editOnHand ? (
        <EditableInventoryTable
          toolbar={<InventoryToolbarEdit title={title} />}
          location={location}
          loadedInventoryData={limitedDataToDisplay}
          afterSave={onSaveSuccess}
          afterCancel={toggleEditOff}
          editMin={editMin}
          editOnHand={editOnHand}
        />
      ) : (
        <StatelessInventoryTable
          editable={true}
          handleAvailableIconClicked={openForecastDialog}
          loading={loadingInventoryData}
          inventoryData={limitedDataToDisplay}
          toggleEditOn={toggleEditOn}
          toolbar={<InventoryToolbarList title={title} handleAdd={toggleEditOn} />}
          actions={<InventoryListActions actionTitle="See all" onClick={() => history.push('/inventory/location')} />}
        />
      )}
      {!!inventoryData && (
        <ComponentForecastDialog controller={forecastDetailDialogController} inventoryData={inventoryData} />
      )}
    </>
  )
}

export default memo(InventoryTable)

export const projectSchema = {
  properties: {
    project: {
      properties: {
        // Address
        address: {
          type: ['string', 'null'],
        },
        locality: {
          type: ['string', 'null'],
        },
        state: {
          type: ['string', 'null'],
        },
        zip: {
          type: ['string', 'null'],
        },
        county: {
          type: ['string', 'null'],
        },
        country: {
          type: 'string',
        },
        country_name: {
          type: 'string',
        },
        country_iso2: {
          type: 'string',
        },
        lat: {
          type: 'number',
        },
        lon: {
          type: 'number',
        },

        custom_project_info_1: {
          type: ['string', 'null'],
        },
        custom_project_info_2: {
          type: ['string', 'null'],
        },
        custom_project_info_3: {
          type: ['string', 'null'],
        },
        custom_project_info_4: {
          type: ['string', 'null'],
        },
        custom_project_info_5: {
          type: ['string', 'null'],
        },

        allow_email_notifications: {
          type: 'boolean',
        },
        auto_apply_max_simulate_years: {
          type: 'boolean',
        },
        business_identifier: {
          type: ['string', 'null'],
        },
        business_name: {
          type: ['string', 'null'],
        },
        timezone_offset: {
          type: 'number',
        },
        roof_type: {
          type: ['string', 'null'],
        },
      },
    },
  },
}

import { DialogActions, DialogContentText, DialogTitle, withStyles } from '@material-ui/core'
import { StyledDialog, StyledDialogContent } from 'layout/StyledDialog'
import { Button } from 'opensolar-ui'
import React, { useEffect, useState } from 'react'

const StyledButton = withStyles({
  root: {
    margin: '0 5px',
  },
})(Button)

export default function CustomImageryDialog() {
  const [isOpen, setIsOpen] = useState(false)
  const [isListeningToEditor, setisListeningtoEditor] = useState(false)
  const [imageRaw, setImageRaw] = useState<HTMLImageElement | null>(null)
  const [imageDataURL, setImageDataURL] = useState('')
  const [isInManualMode, setIsInManualMode] = useState(false)

  useEffect(() => {
    if (!isListeningToEditor && window.editor) {
      window.editor.signals.customImageryUploaded.add(onImageDrop)
      setisListeningtoEditor(true)
    }
  }, [])

  const onImageDrop = (image: HTMLImageElement, imageDataURL: string, isInManualMode: boolean) => {
    setImageRaw(image)
    setImageDataURL(imageDataURL)
    setIsInManualMode(isInManualMode)
    setIsOpen(true)
  }

  const handleOptionSelect = (selection: string) => {
    switch (selection) {
      case '2D': {
        window.ViewHelper.addImageView(imageRaw as HTMLImageElement, imageDataURL)
        break
      }
      case '3D': {
        window.SceneHelper.setCustomImageAsGround(imageRaw as HTMLImageElement, imageDataURL)
        break
      }
      default: {
        discardImage()
        break
      }
    }
    setIsOpen(false)
  }

  const handleClose = () => {
    discardImage()
    setIsOpen(false)
  }

  const discardImage = () => {
    setImageRaw(null)
    setImageDataURL('')
  }

  return (
    <StyledDialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Custom Imagery Upload'}</DialogTitle>
      <StyledDialogContent>
        <DialogContentText id="alert-dialog-description">
          Please choose your preferred design mode using custom imagery.
        </DialogContentText>
        {!isInManualMode && (
          <p style={{ fontSize: '0.9em', color: 'red' }}>
            Designing in 3D using custom imagery is only available in Manual Mode.
          </p>
        )}
      </StyledDialogContent>
      <DialogActions>
        <StyledButton onClick={() => handleOptionSelect('')}>Cancel</StyledButton>
        <StyledButton onClick={() => handleOptionSelect('2D')}>Design in 2D</StyledButton>
        <StyledButton onClick={() => handleOptionSelect('3D')} disabled={!isInManualMode}>
          Design in 3D
        </StyledButton>
      </DialogActions>
    </StyledDialog>
  )
}

// in src/users.js
import { List } from 'elements/react-admin/List'
import React from 'react'
import { Datagrid, TextField } from 'react-admin'

export const CountryList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="iso2" />
    </Datagrid>
  </List>
)

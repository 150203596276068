import * as Sentry from '@sentry/react'
import { ModuleGridType } from 'types/global'
import { MeasurementUnits } from 'types/orgs'
import { fromMeters, toMeters } from '../helpers'
import type { BasicAccessorConfig } from './types'

const setter = ({ moduleGrid, value }: { moduleGrid: ModuleGridType; value: [number, number] }) => {
  window.editor.execute(new window.SetPanelConfigurationCommand(moduleGrid, 'groupSpacing', value))
}

const getter = (moduleGrid: ModuleGridType): [number, number] => {
  if (Array.isArray(moduleGrid.groupSpacing)) {
    return [...moduleGrid.groupSpacing]
  }
  Sentry.captureException(`GroupSpacing is not an array: ${moduleGrid.groupSpacing}`)
  return moduleGrid.groupSpacing
}

const parse = (value: number | number[] | undefined, options: { measurements: MeasurementUnits }) => {
  if (value === undefined) return undefined
  const measurements = options?.measurements
  if (measurements === undefined) return value
  if (Array.isArray(value)) {
    return value.map((v) => fromMeters(v, measurements))
  }
  return fromMeters(value, measurements)
}

const format = (value: number | number[] | undefined, options: { measurements: MeasurementUnits }) => {
  if (value === undefined) return undefined
  const measurements = options?.measurements
  if (measurements === undefined) return value
  // what we save to studio MUST always be in meters
  if (Array.isArray(value)) {
    return value.map((v) => toMeters(v, measurements))
  }
  return toMeters(value, measurements)
}

type ConfigType = BasicAccessorConfig<[number, number]> & {
  format: typeof format
  parse: typeof parse
}

export const groupSpacingAccessorConfig: ConfigType = {
  get: getter,
  set: setter,
  format,
  parse,
}

import { orgSelectors } from 'ducks/orgs'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import type { FactoryHookType } from '../type'
import maybeCreateAvailabilityFilterNode from './AvailabilityFilterNodeFactory'

const useAvailabilityFilterNode: FactoryHookType = ({ RendererComponent, options }) => {
  const distributor = useSelector(orgSelectors.getSelectedHardwareSupplier)

  return useMemo(() => {
    return maybeCreateAvailabilityFilterNode({
      distributor,
      RendererComponent,
    })
  }, [distributor, RendererComponent])
}

export default useAvailabilityFilterNode

export const styles = {
  divider: {
    marginTop: 5,
    marginBottom: 5,
  },
  wrapper: { display: 'flex', alignItems: 'baseline' },
  label: { minWidth: '120px', display: 'inline-block', marginRight: 5, marginBottom: 5 },
  button: {
    minWidth: 30, //required to override default 88 for material-ui
    marginRight: 5,
    marginBottom: 5,

    //for small screens
    height: 24,
  },
  overlayLayoutTablet: {
    position: 'absolute',
    bottom: 10,
    width: 'calc(100% - 360px)',
    left: 310,
    zIndex: 100000,
    padding: 20,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    pointerEvents: 'all',
  },

  overlayLayoutMobile: {
    position: 'absolute',
    bottom: 10,
    zIndex: 100000,
    padding: 20,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    pointerEvents: 'all',
    left: 10,
    right: 10,
  },
}

export default styles

import { authSelectors } from 'ducks/auth'
import { rolesSelectors } from 'ducks/auth_roles'
import { splitSelectors } from 'ducks/split'
import React from 'react'
import { useSelector } from 'react-redux'

export const withFeatureFlag = (BaseComponent: any): any => {
  if (typeof BaseComponent === 'string') throw new Error('Incorrect usage')

  const RefComponent = React.forwardRef((props, ref) => {
    const splitIsReady = useSelector(splitSelectors.getSplitIsReady)
    const client = useSelector(splitSelectors.getClient)
    const roleId = useSelector(rolesSelectors.getCurrentRoleId)
    const userId = useSelector(authSelectors.getCurrentUserId) || -1
    const orgId = useSelector(authSelectors.getOrgId) || -1
    const orgCountry = useSelector(authSelectors.getCurrentOrgCountry) || ''
    const isStaff = useSelector(authSelectors.getIsStaff)
    const isAdmin = useSelector(authSelectors.getIsAdmin)
    const useFeatureFlag = (splitName: string, targetValue: string) => {
      if (!splitIsReady) return undefined
      const attributeMap = {
        roleId,
        userId,
        orgId,
        orgCountry,
        isStaff,
        isAdmin,
      }
      const value = client?.getTreatment(splitName, attributeMap)
      return targetValue === value
    }

    return <BaseComponent {...props} ref={ref} useFeatureFlag={useFeatureFlag} />
  })

  RefComponent.defaultProps = BaseComponent.defaultProps

  return RefComponent
}

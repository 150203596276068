import { MenuItem } from '@material-ui/core'
import classNames from 'classnames'
import { ComponentVersions_3_0 } from 'constants/uxVersions'
import { orgSelectors } from 'ducks/orgs'
import {
  Alert,
  Box,
  Button,
  CloseIcon,
  ComponentVersionsInherit,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Select,
  Typography,
} from 'opensolar-ui'
import { getSupplierConfig } from 'pages/ordering/configs'
import useEnabledHardwareSuppliers from 'pages/ordering/hooks/useEnabledHardwareSuppliers'
import { ComponentCost, CostSource, useComponentCost } from 'projectSections/hooks/useComponentCost'
import useHardwareDetailsDynamic from 'projectSections/sections/design/sideDrawer/bom/useHardwareDetailsDynamic'
import { useTranslate } from 'ra-core'
import { FC, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { useFeatureFlag } from 'util/split'
import CustomiseHardwareCostTable from './CustomiseHardwareCostTable'
import useDistributorPricing from './useDistributorPricing'

type PropsType = {
  onClose: () => void
}

const useStyles = makeOpenSolarStyles((theme) => ({
  icon: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
    minWidth: 40,
    maxHeight: 40,
    backgroundColor: theme.headerBackgroundColor,
  },
  topRow: {
    marginTop: 16,
    marginBottom: 16,
  },
  topRowElement: {
    margin: 'auto',
    marginRight: 16,
    marginLeft: 0,
  },
  topRowSelect: {
    margin: 'auto',
    marginRight: 16,
    marginLeft: 0,
    width: 285,
  },
  pricingWarning: {
    marginBottom: 16,
  },
  supplierImg: {
    width: 50,
    height: 32,
    marginRight: 8,
    border: '1px solid #E6E6E6',
    borderRadius: 4,
  },
  supplierName: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
  },
  supplierWrapper: {
    padding: '8px',
  },
}))

/**
 * We keep a copy of the modified cost source state here so that we only save it when the user clicks save.
 */
const CustomiseHardwareCostModal: FC<PropsType> = ({ onClose }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const hardwareDetails = useHardwareDetailsDynamic()
  const { codesToDistributorPricing, loading } = useDistributorPricing()
  const { componentCosts, updateComponentCosts } = useComponentCost()
  const [showPricesNotChangedWarning, setShowPricesNotChangedWarning] = useState(false)
  const [canSave, setCanSave] = useState(true)

  // Keep state here to deal with the save button function.
  const [componentCostSources, setComponentCostSources] = useState<ComponentCost[]>(componentCosts)
  const selectedHardwareSupplier = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const enabledSuppliers = useEnabledHardwareSuppliers()
  const multiDistributor = useFeatureFlag('design_multi_distributor', 'on')
  const supplierConfig = getSupplierConfig(selectedHardwareSupplier)

  const supplierList = useMemo(() => {
    if (multiDistributor) return enabledSuppliers
    else return [selectedHardwareSupplier]
  }, [multiDistributor, enabledSuppliers, selectedHardwareSupplier])

  // If there are any existing cost sources from the supplier, use that as the default.
  const initialCostSource = componentCostSources.some((source) => source.source === supplierConfig?.filterKey)
    ? supplierConfig?.filterKey ?? 'component'
    : 'component'

  // For the top select.
  const [primaryCostSource, setPrimaryCostSource] = useState<CostSource>(initialCostSource)

  const componentsWithZeroPricing = useMemo(() => {
    return componentCostSources.some((costSource) => costSource.price === 0)
  }, [componentCostSources])

  // Update the cost source for a component. Handle each type of cost source separately.
  const updateCostSourceForComponent = (code: string, costSource: CostSource, value: number) => {
    setShowPricesNotChangedWarning(false)
    // If the cost source is distributor, set the cost price to equal the distributor price
    let newCostSource

    if (costSource === 'component') {
      // Price from activation
      const type = hardwareDetails.find((hardwareDetail) => hardwareDetail.code === code)?.type
      if (!type) return
      const componentActivation = window.AccountHelper.getComponentActivationFromCode(code, type)
      newCostSource = {
        code,
        source: 'component',
        price: componentActivation?.cost,
      }
    } else if (costSource === 'manual') {
      // User specified price
      newCostSource = {
        code,
        source: 'manual',
        price: value,
      }
    } else {
      // Distributor price
      newCostSource = {
        code,
        source: costSource,
        price: codesToDistributorPricing[code]?.find((pricing) => pricing.distributor === costSource)?.price,
      }
    }

    // I guess we could have avoided some of this state management if we used a dictionary instead of an array.
    const newCostSources = componentCostSources.map((source) => {
      if (source.code === code) {
        return newCostSource
      }
      return source
    })
    setComponentCostSources(newCostSources)
  }

  // Again there's a bit of state management faffing about.
  const handleClickRefreshPrice = () => {
    if (primaryCostSource === 'unknown') return

    const newCostSources: ComponentCost[] = []

    hardwareDetails.forEach((hardwareDetail) => {
      if (primaryCostSource === 'component') {
        const type = hardwareDetail?.type
        // If we have an activation for this component with a non-zero price, we update the cost source
        const componentActivation = window.AccountHelper.getComponentActivationFromCode(
          hardwareDetail.code,
          hardwareDetail.type
        )
        if (componentActivation && componentActivation.cost > 0) {
          newCostSources.push({
            code: hardwareDetail.code,
            source: 'component',
            price: componentActivation.cost,
          })
        }
      } else if (primaryCostSource !== 'manual') {
        // Distributor price
        const distributorPricing = codesToDistributorPricing[hardwareDetail.code]?.find(
          (pricing) => pricing.distributor === primaryCostSource
        )
        if (distributorPricing) {
          newCostSources.push({
            code: hardwareDetail.code,
            source: primaryCostSource,
            price: distributorPricing.price,
          })
        }
      }
    })

    if (newCostSources.length > 0) {
      // I'm sure there's a neater way of doing this using delete on the array but this works.
      const costSourceUpdates: ComponentCost[] = []
      hardwareDetails.forEach((hardwareDetail) => {
        const update = newCostSources.find((source) => source.code === hardwareDetail.code)
        const previous = componentCosts.find((source) => source.code === hardwareDetail.code)

        if (update) {
          costSourceUpdates.push(update)
        } else if (previous) {
          costSourceUpdates.push(previous)
        }
      })
      setComponentCostSources(costSourceUpdates)
    } else {
      setShowPricesNotChangedWarning(true)
    }
  }

  const onSave = () => {
    updateComponentCosts(componentCostSources)
    onClose()
  }

  return (
    <ComponentVersionsInherit versions={ComponentVersions_3_0}>
      <Dialog open={true} fullWidth maxWidth={'lg'} style={{ zIndex: 1400 }}>
        <Box style={{ overflow: 'hidden' }}>
          <DialogTitle>
            {translate('Hardware Cost Override')}
            <Box className={classes.icon}>
              <IconButton onClick={onClose}>
                <CloseIcon color="#ADACB0" />
              </IconButton>
            </Box>
          </DialogTitle>

          <DialogContent style={{ padding: 24 }}>
            <Box color={'#4F4D55'}>
              {translate(
                'Use this modal to review and update the pricing source for each selected component.' +
                  'You can make bulk changes by selecting a pricing source in the dropdown below and clicking ' +
                  "'Refresh Price' or manually adjust each component’s source in the table below."
              )}
            </Box>
            <Box display="flex" justifyContent="flex-start" className={classes.topRow}>
              <Box className={classes.topRowElement}>Hardware Pricing Source</Box>
              <Box className={classes.topRowSelect}>
                <Select
                  onChange={(e) => {
                    setPrimaryCostSource(e.target.value as CostSource)
                    setShowPricesNotChangedWarning(false)
                  }}
                  value={primaryCostSource}
                >
                  {supplierList.map((supplier) => {
                    const config = getSupplierConfig(supplier)
                    return (
                      <MenuItem key={config?.filterKey} value={config?.filterKey}>
                        <div style={{ display: 'inline-flex', alignContent: 'center', alignItems: 'center' }}>
                          <div className={classes.supplierImg}>
                            <img
                              src={config?.logoUrl || ''}
                              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                            />
                          </div>
                          <Typography className={classes.supplierName}>{config?.name}</Typography>
                        </div>
                      </MenuItem>
                    )
                  })}
                  <MenuItem value="component">
                    <div className={classNames(classes.supplierWrapper, classes.supplierName)}>
                      Manual (Business Control Settings)
                    </div>
                  </MenuItem>
                </Select>
              </Box>
              <Box className={classes.topRowElement}>
                <Button variant="outlined" onClick={handleClickRefreshPrice}>
                  Refresh Price
                </Button>
              </Box>
            </Box>
            {showPricesNotChangedWarning && (
              <Alert severity="warning" className={classes.pricingWarning}>
                Pricing is not available for all components from the selected source.
              </Alert>
            )}
            {!showPricesNotChangedWarning && componentsWithZeroPricing && (
              <Alert severity="warning" className={classes.pricingWarning}>
                Some components have a zero-valued price set in Business Control Settings.
              </Alert>
            )}
            <Box display="flex" style={{ overflow: 'hidden' }}>
              <CustomiseHardwareCostTable
                hardwareDetails={hardwareDetails}
                componentPricing={componentCostSources}
                onCostSourceChange={updateCostSourceForComponent}
                onEditChange={(isEditing) => setCanSave(!isEditing)}
                loading={loading}
              />
            </Box>
          </DialogContent>
        </Box>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={onSave} disabled={!canSave}>
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </ComponentVersionsInherit>
  )
}

export default CustomiseHardwareCostModal

import { FormPlacementInfo } from 'customForm/util/findFormPlacements'
import { cloneDeep } from 'lodash'
import { RootState } from 'types/state'
export const FORM_DIALOG = 'FORM_DIALOG'

export const setFormDialog = (isOpen: boolean, customForm: FormPlacementInfo | null, systemUuid: string | null) => ({
  type: FORM_DIALOG,
  payload: {
    isOpen,
    customForm,
    systemUuid,
  },
})

export type ReduxFormStateType = {
  isOpen: boolean
  customForm: FormPlacementInfo | null
  systemUuid: string | null
}
const defaultState: ReduxFormStateType = {
  isOpen: false,
  customForm: null,
  systemUuid: null,
}

type ReduxFormArgs = {
  type: string
  payload: any
}

export default (previousState: ReduxFormStateType = defaultState, { type, payload }: ReduxFormArgs) => {
  switch (type) {
    case FORM_DIALOG:
      if (previousState === defaultState) previousState = cloneDeep(previousState)
      previousState = {
        ...previousState,
        isOpen: payload.isOpen,
        customForm: payload.customForm,
        systemUuid: payload.systemUuid,
      } as ReduxFormStateType
      break
  }

  return previousState
}

export const formSelectors = {
  getForm(state: RootState): FormPlacementInfo | null {
    var form
    try {
      form = state.formDialog.customForm
    } catch (e) {
      form = null
    }
    return form
  },
  getSystemUuid(state: RootState): string | null {
    var systemUuid
    try {
      systemUuid = state.formDialog.systemUuid
    } catch (e) {
      systemUuid = null
    }
    return systemUuid
  },
  getIsOpen(state: RootState): boolean {
    var isOpen
    try {
      isOpen = Boolean(state.formDialog.isOpen)
    } catch (e) {
      isOpen = false
    }
    return isOpen
  },
}

import { useCallback } from 'react'
import { useDataProvider } from 'react-admin'
import type { Transfer } from 'resources/inventoryTransfer/type'

type PartiallyAcceptTransferArgs = { transferId: number; data: Transfer }

const usePartiallyAcceptTransfer = () => {
  const dataProvider = useDataProvider()
  const partiallyAcceptTransfer = useCallback(async ({ transferId, data }: PartiallyAcceptTransferArgs) => {
    await dataProvider.CUSTOM_POST('inventory_transfers', {
      url: `inventory_transfers/${transferId}/partially_accept/`,
      data: data,
    })
  }, [])

  return {
    partiallyAcceptTransfer,
  }
}

export default usePartiallyAcceptTransfer

import { Card, CardActions, makeStyles } from '@material-ui/core'
import { passwordResetForm as passwordResetFormAction } from 'actions/authActions'
import { Button } from 'opensolar-ui'
import { useCallback } from 'react'
import { useTranslate } from 'react-admin'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import AuthModalWrapper from './AuthModalWrapper'
import { pathnameAndParamsWithUrlFragment } from './common'
import PasswordInput from './components/fields/PasswordInput'
import PasswordResetLinks from './components/reset/PasswordResetLinks'

const useStyles = makeStyles((theme) => ({
  card: {
    width: 280,
    minWidth: 210,
    padding: '0.5em 1.5em 1em 1.5em',
  },
  input: {
    margin: '10px 0 20px',
    '& .MuiFormHelperText-root': {
      marginTop: 0,
      height: 0,
    },
  },
  button: {
    color: theme.headerFontColor,
    background: theme.themeColor,
    '&:hover': {
      cursor: 'pointer',
      background: theme.themeColorDark,
    },
  },
}))

const PasswordResetForm = () => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const location = useLocation()
  const classes = useStyles()
  const history = useHistory()

  const onCreateAccountClick = useCallback(() => {
    history.push(pathnameAndParamsWithUrlFragment('/register', location, location.params?.urlFragment))
  }, [])

  const onSignInClick = useCallback(() => {
    history.push(pathnameAndParamsWithUrlFragment('/login', location, location.params?.urlFragment))
  }, [])

  const onChangeSuccess = useCallback(() => {
    history.push('/email-change-complete')
  }, [])

  const handleSubmitForm = ({ new_password }) => {
    var parts = location.pathname.split('/')
    var uid = parts[2]
    var token = parts[3]

    dispatch(
      passwordResetFormAction({
        payload: {
          uid,
          token: token,
          new_password,
        },
        callbacks: {
          onSuccess: onChangeSuccess,
        },
      })
    )
  }

  return (
    <AuthModalWrapper>
      <Form
        onSubmit={handleSubmitForm}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <Card elevation={0} classes={{ root: classes.card }}>
            <h1 style={{ fontWeight: 500 }}>
              {location.pathname.startsWith('/password-set-form') ? 'Set' : 'Reset'} Password
            </h1>
            <p style={{ fontWeight: 300 }}>
              {location.pathname.startsWith('/password-set-form')
                ? translate('Welcome to OpenSolar! Before we get started, please set a password for your new account.')
                : translate('Enter your new password.')}
            </p>
            <form onSubmit={handleSubmit}>
              <div>
                <div className={classes.input}>
                  <PasswordInput source={'new_password'} label={'New Password'} />
                </div>
              </div>
              <CardActions>
                <Button classes={{ root: classes.button }} type="submit" disabled={submitting} fullWidth>
                  <span>
                    {translate((location.pathname.startsWith('/password-set-form') ? 'Set' : 'Reset') + ' Password')}
                  </span>
                </Button>
              </CardActions>
            </form>
            {location.pathname.startsWith('/password-set-form') ? (
              ''
            ) : (
              <PasswordResetLinks onCreateAccount={onCreateAccountClick} onSignIn={onSignInClick} />
            )}
          </Card>
        )}
      />
    </AuthModalWrapper>
  )
}
export default PasswordResetForm

export const SET_WELCOME_PRO = 'SET_WELCOME_PRO'
export const SET_WELCOME_CUSTOMER = 'SET_WELCOME_CUSTOMER'
export const SHOW_WELCOME_DIALOG = 'SHOW_WELCOME_DIALOG'

export const setWelcomePro = value => ({
  type: SET_WELCOME_PRO,
  payload: value,
})

export const setWelcomeCustomer = value => ({
  type: SET_WELCOME_CUSTOMER,
  payload: value,
})

export const setWelcomeDialogOpen = value => ({
  type: SHOW_WELCOME_DIALOG,
  payload: value,
})


export default function reducer(previousState = null, { type, payload }) {
  if (!previousState) {
    previousState = {
      pro: false,
      customer: false,
      show_welcome_dialog: false
    }
  }

  if (type === 'SET_WELCOME_PRO') {
    return Object.assign({}, payload, { pro: payload })
  }
  if (type === 'SET_WELCOME_CUSTOMER') {
    return Object.assign({}, payload, { customer: payload })
  }
  if (type === 'SHOW_WELCOME_DIALOG') {
    return { ...previousState, show_welcome_dialog: payload }
  }
  return previousState
}

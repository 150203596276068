var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { BaseTheme } from '../theme/BaseTheme';
// Need to use this version of ThemeProvider to support makeStyles
import { ThemeProvider as ThemeProvider2 } from '@mui/styles';
import { ThemeProvider } from '@mui/material';
// Wrapper on <ThemeProvider> for opensolar-ui
// At some point this will handle theme switching
export var ThemeContext = function (_a) {
    var children = _a.children;
    var theme = BaseTheme;
    return (_jsx(ThemeProvider, __assign({ theme: theme }, { children: _jsx(ThemeProvider2, __assign({ theme: theme }, { children: children })) })));
};

import { makeStyles } from '@material-ui/core'
import { ArrowForwardIos, ExpandMore } from '@material-ui/icons'
import { Button } from 'opensolar-ui'
import React, { useState } from 'react'
import { Theme } from 'types/themes'
const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    marginBottom: 20,
  },
  icon: {
    height: 10,
  },
}))

type PropTypes = {
  sectionTitle: string | ((fieldsShown: boolean) => string)
  defaultExpanded?: boolean
  startIcon?: React.ReactNode
}

export const ContactFormExpandSection: React.FC<PropTypes> = ({
  sectionTitle,
  startIcon,
  children,
  defaultExpanded = false,
}) => {
  const classes = useStyles()
  const [showFields, setShowFields] = useState(defaultExpanded)

  return (
    <div className={classes.wrapper}>
      <Button
        startIcon={startIcon}
        variant="text"
        color="info"
        onClick={() => {
          setShowFields(!showFields)
        }}
        endIcon={showFields ? <ExpandMore /> : <ArrowForwardIos className={classes.icon} />}
      >
        {typeof sectionTitle === 'function' ? sectionTitle(showFields) : sectionTitle}
      </Button>
      {showFields && children}
    </div>
  )
}

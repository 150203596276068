"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.genid = void 0;
function genid(length) {
    if (length === void 0) { length = 5; }
    return Math.random()
        .toString(36)
        .substring(2, 2 + length);
}
exports.genid = genid;

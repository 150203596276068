import React, { useCallback, useMemo, useState } from 'react'

type FlowConfigsBasicType = Record<string, React.FC<any>>
type FlowStoreBasicType = Record<string, any>

type FlowControllerType<T extends FlowConfigsBasicType, R extends FlowStoreBasicType> = {
  goTo: (nodeConfig: NodeConfigType<T, R>) => void
  setFlowStore: React.Dispatch<React.SetStateAction<R>>
}

type FlowNodeComponentStandardPropsType<T extends FlowConfigsBasicType, R extends FlowStoreBasicType> = {
  flowController: FlowControllerType<T, R>
  flowStore: R
}

// This force a strong type when options is undefined, because Omit<{},''> can be assign to any object value
type ReturnUndefinedIfEmptyObject<T> = keyof T extends never ? undefined : T

export type NodeConfigType<T extends FlowConfigsBasicType, R extends FlowStoreBasicType> = {
  [K in keyof T]: {
    title?: string // optional title for the node
    currentNodeKey: K
    options: ReturnUndefinedIfEmptyObject<
      Omit<React.ComponentProps<T[K]>, keyof FlowNodeComponentStandardPropsType<T, R>>
    > // additional props for the node
  }
}[keyof T]

/*****  Usage Example 
 * 
const StartNode = (props: { propsA: number }) => null
const EndNode = () => null

const FLOW_CONFIGS = {
  START_NODE: StartNode,
  END_NODE: EndNode,
} as const

type StoreType = {
  stateA?: string
  stateB: string
}
const Example = () => {
  const { flow } = useContextFlow<typeof FLOW_CONFIGS, StoreType>({
    initialNodeConfig: {
      currentNodeKey: 'START_NODE', // strong typed steps
      options: { propsA: 123 }, // strong typed options
    },
    initialStore: {
      stateB: '123', // strong typed store state
    },
    flowConfigs: FLOW_CONFIGS,
  })
  return flow
}
*/

const useFlow = <T extends FlowConfigsBasicType, R extends FlowStoreBasicType>({
  initialNodeConfig,
  initialStore,
  flowConfigs,
}: {
  initialNodeConfig: NodeConfigType<T, R>
  initialStore: R
  flowConfigs: T
}): {
  currentNodeConfig: NodeConfigType<T, R>
  flow: React.ReactNode
  flowStore: R
  flowController: FlowControllerType<T, R>
} => {
  const [currentNodeConfig, setCurrentNodeConfig] = useState<NodeConfigType<T, R>>(initialNodeConfig)
  const [flowStore, setFlowStore] = useState<R>(initialStore)
  const goTo = useCallback((nodeConfig: NodeConfigType<T, R>) => {
    setCurrentNodeConfig(nodeConfig)
  }, [])
  const flowController = useMemo(() => ({ goTo, setFlowStore }), [])
  const nodeKey = currentNodeConfig.currentNodeKey
  const options = currentNodeConfig.options !== undefined ? currentNodeConfig.options : {}

  const flow = useMemo(() => {
    const renderProps = { flowStore, flowController, ...options }
    return <NodeRender Component={flowConfigs[nodeKey]} renderProps={renderProps} />
  }, [nodeKey])

  return useMemo(
    () => ({
      currentNodeConfig,
      flow,
      flowStore,
      flowController,
    }),
    [currentNodeConfig, flow, flowController]
  )
}

const NodeRender = ({ Component, renderProps }) => {
  return <Component {...renderProps} />
}

export default useFlow

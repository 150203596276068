import { EventType, PublicClientApplication } from '@azure/msal-browser'
import { getMsalConfig } from 'services/segen/authConfig'
import SegenAuthenticationService from '../segen/SegenAuthenticationService'

type MsalCacheType = {
  tenant?: string
  clientId?: string
  instance?: PublicClientApplication
}

const msalInstanceCache: MsalCacheType = {
  tenant: undefined,
  clientId: undefined,
  instance: undefined,
}

export const getMsalSegenSingletonInstance = (): PublicClientApplication => {
  if (msalInstanceCache.instance) {
    return msalInstanceCache.instance
  }
  const { tenant, clientId } = window.getSegenConfig()
  const msalInstance = new PublicClientApplication(getMsalConfig(tenant, clientId))

  // Listen to msal event first time connect success
  const callBackId = msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
      SegenAuthenticationService.onConnectSuccess({ method: 'silent', source: 'onload' })
      if (callBackId) {
        msalInstance.removeEventCallback(callBackId)
      }
    } else if (event.eventType === EventType.LOGIN_SUCCESS) {
      SegenAuthenticationService.onConnectSuccess({ method: 'redirect', source: 'onload' })
      if (callBackId) {
        msalInstance.removeEventCallback(callBackId)
      }
    }
  })

  msalInstanceCache.instance = msalInstance
  msalInstanceCache.tenant = tenant
  msalInstanceCache.clientId = clientId
  return msalInstance
}

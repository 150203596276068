import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { reloadEntireApp } from 'util/misc'

var restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  isOpen: boolean
  onClose: () => void
}

const DividendConnectAccountDialog: React.FC<PropTypes> = (props) => {
  const [installerId, setInstallerId] = useState<undefined | string>(undefined)
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)

  const orgId = useSelector(authSelectors.getCurrentOrg)?.id

  const submit = () => {
    if (!installerId) {
      setErrorMsg('Installer Id is required')
    } else {
      setLoading(true)
      restClientInstance('CUSTOM_PUT', 'custom', {
        url: `orgs/${orgId}/dividend/connect_account/`,
        data: {
          installer_id: installerId,
        },
      })
        .then((res: { data: { products_added: number } }) => {
          logAmplitudeEvent('dividend_account_connected', {})
          setErrorMsg(undefined)
          window.location.href =
            window.location.origin +
            `/#/payment_options?products_added=${res.data.products_added || 0}&partner=Dividend`
          reloadEntireApp()
        })
        .catch((err: any) => {
          console.log('err', err)
          setErrorMsg('Unable to connect Dividend account, please make sure the installer id is correct')
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  return (
    <Dialog open={props.isOpen} onClose={props.onClose}>
      <DialogTitle>Connect your Dividend account</DialogTitle>
      <DialogContent>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p>
            Enter your Dividend Installer Id below and then click "Submit" to activate the Dividend integration on your
            account.
          </p>
          <TextField
            onChange={(e) => setInstallerId(e.target.value)}
            label="Dividend Installer Id"
            value={installerId}
            fullWidth={true}
          />
          {errorMsg && <p style={{ color: 'red', textAlign: 'center' }}>{errorMsg}</p>}
        </div>
      </DialogContent>
      <DialogActions>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ margin: '20px' }}>
            <ProUXButton type="secondary" label="Cancel" onClick={props.onClose} />
          </div>
          <div style={{ margin: '20px' }}>
            <ProUXButton
              type="primary"
              label="Submit"
              disabled={!installerId || loading}
              onClick={submit}
              loading={loading}
            />
          </div>
        </div>
      </DialogActions>
    </Dialog>
  )
}
export default DividendConnectAccountDialog

import { Typography } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orderSelectors } from 'ducks/orderComponents'
import { memo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import CheckoutPageLayout from '../PageLayout'
import OrdersTable from '../order/OrdersTable'
import CartActions from './CartActions'

const CartPage = () => {
  const lineItems = useSelector(orderSelectors.getOrderableLineItems)

  useEffect(() => {
    logAmplitudeEvent('page_viewed', {
      page: 'shopping_cart',
    })
  }, [])

  return (
    <CheckoutPageLayout
      title={
        <Typography variant="h4" gutterBottom>
          Shopping Cart
        </Typography>
      }
      mainContent={<OrdersTable lineItems={lineItems} editable={true} />}
      sideAction={<CartActions />}
    />
  )
}

export default memo(CartPage)

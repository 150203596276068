import { paymentOptionSelectionSelectors } from 'ducks/paymentOptionSelection'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { StudioSystemType } from 'types/global'
import { PaymentOptionDataType } from 'types/paymentOptions'
import { summaryMessage } from './automcspanel/SummaryMessage'
import { BATTERY_CONTROL_SCHEME_NAMES, CALCULATOR_CONFIGS, CALCULATOR_MESSAGES } from './constants'
import { Condition, SystemMCSInfo } from './types'

const DEFAULT_SIMULATE_YEAR = 20
const INTEGRATIONS_LIFETIME = {
  lightreach: 25,
}

export const getSystemYearSimulationYear = (system: StudioSystemType, requiredLifetime: number | null) => {
  if (requiredLifetime) return requiredLifetime
  // if auto default simulate year is enabled then use the product warranty from module
  const isAutoDefaultSimulateYearEnabled = window.WorkspaceHelper?.project?.auto_apply_max_simulate_years
  if (isAutoDefaultSimulateYearEnabled) {
    return Math.min(system.moduleType()?.product_warranty || DEFAULT_SIMULATE_YEAR, 40)
  } else {
    return window.WorkspaceHelper.getYearsToSimulate()
  }
}

export const useGetRequiredLifetimeByIntegration = () => {
  const refreshSystemsTrigger = useSelector(paymentOptionSelectionSelectors.getSystemRefreshTrigger)

  const requiredLifetime = useMemo(() => {
    let pmtWithRequiredLifetime: PaymentOptionDataType | undefined = undefined
    window.editor.getSystems()?.forEach((system) => {
      let pmtWithTargetIntegration = system?.payment_options?.find((pmt) => {
        return pmt.integration && INTEGRATIONS_LIFETIME[pmt.integration]
      })

      if (pmtWithTargetIntegration) pmtWithRequiredLifetime = pmtWithTargetIntegration
    })

    // @ts-ignore
    return pmtWithRequiredLifetime ? INTEGRATIONS_LIFETIME[pmtWithRequiredLifetime?.integration] : null
  }, [refreshSystemsTrigger])

  return requiredLifetime
}

export const useGetMaxSystemLifetime = () => {
  const [maxLifetime, setMaxLifetime] = useState(25)
  const requiredLifetime = useGetRequiredLifetimeByIntegration()

  const refreshSystemsTrigger = useSelector(paymentOptionSelectionSelectors.getSystemRefreshTrigger)

  useEffect(() => {
    if (requiredLifetime) setMaxLifetime(requiredLifetime)
    else {
      const maxPanelWarrantyYear = Math.max(
        ...window.editor.getSystems()?.map((sys) => getSystemYearSimulationYear(sys, requiredLifetime))
      )
      setMaxLifetime(maxPanelWarrantyYear)
    }
  }, [requiredLifetime, refreshSystemsTrigger])

  return maxLifetime
}

export const useGetSystemLifetimeLocked = () => {
  const requiredLifetime = useGetRequiredLifetimeByIntegration()
  return Boolean(requiredLifetime)
}

export const getDynamicCalculatorData = (
  kwStc: number = 0,
  annualUsage: number = 0,
  annualGeneration: number = 0,
  batteryControlScheme: string | null = null
): SystemMCSInfo => {
  const mappedBatteryScheme = BATTERY_CONTROL_SCHEME_NAMES[batteryControlScheme ?? ''] || batteryControlScheme

  const conditions: Condition[] = [
    {
      condition: kwStc > 50,
      data: {
        id: 'sam',
        dynamicCalculatorData: {
          ...CALCULATOR_CONFIGS.SAM,
          batteryControlScheme: mappedBatteryScheme,
        },
      },
    },
    {
      condition: annualUsage > 5999 && annualGeneration > 5999,
      data: {
        id: 'mcs_and_opensolar',
        dynamicCalculatorData: {
          ...CALCULATOR_CONFIGS.MCS_OUT_OF_BOUNDS,
          batteryControlScheme: mappedBatteryScheme,
        },
      },
    },
    {
      condition: annualUsage > 5999,
      data: {
        id: 'mcs_and_opensolar',
        dynamicCalculatorData: {
          ...CALCULATOR_CONFIGS.MCS_OUT_OF_BOUNDS,
          batteryControlScheme: mappedBatteryScheme,
        },
      },
    },
    {
      condition: annualGeneration > 5999,
      data: {
        id: 'mcs_and_opensolar',
        dynamicCalculatorData: {
          ...CALCULATOR_CONFIGS.MCS_OUT_OF_BOUNDS,
          batteryControlScheme: mappedBatteryScheme,
        },
      },
    },
    {
      condition: batteryControlScheme !== 'solar_charged' && !!batteryControlScheme,
      data: {
        id: 'mcs_ignoring_bcs',
        alert: {
          type: 'Warning',
          message: summaryMessage([CALCULATOR_MESSAGES.MCS_BATTERY_SCHEME_MODIFIED]),
        },
        dynamicCalculatorData: {
          ...CALCULATOR_CONFIGS.MCS,
          batteryControlScheme: 'Not Supported',
        },
      },
    },
  ]

  for (const { condition, data } of conditions) {
    if (condition) return data
  }

  return {
    id: 'mcs',
    dynamicCalculatorData: {
      ...CALCULATOR_CONFIGS.MCS,
      batteryControlScheme: 'Self-Consumption',
    },
  }
}

export const getOpenSolarCalculatorData = (
  isWithinMCSGuidelines: boolean = false,
  batteryControlScheme: string | null = null
): SystemMCSInfo => {
  const mappedBatteryScheme =
    BATTERY_CONTROL_SCHEME_NAMES[batteryControlScheme ?? 'solar_charged'] || batteryControlScheme

  const messages = [
    CALCULATOR_MESSAGES.OPENSOLAR_OVERRIDE_WHEN_WITHIN_MCS_BOUNDS,
    ...(batteryControlScheme && batteryControlScheme !== 'solar_charged'
      ? [CALCULATOR_MESSAGES.OPENSOLAR_BATTERY_OVERRIDE(mappedBatteryScheme)]
      : []),
    CALCULATOR_MESSAGES.recommendation,
  ]

  return {
    id: 'opensolar_override_in_mcs_conditions',
    ...(isWithinMCSGuidelines && {
      alert: {
        type: 'Note',
        message: summaryMessage(messages),
      },
    }),
    dynamicCalculatorData: {
      ...CALCULATOR_CONFIGS.OPENSOLAR_OVERRIDE,
      batteryControlScheme: mappedBatteryScheme,
    },
  }
}

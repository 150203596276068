import { MenuItem } from '@material-ui/core'
import lodash from 'lodash'
import { FormControl, Select } from 'opensolar-ui'
import { translate } from 'ra-core'
import { SelectInput } from 'ra-ui-materialui'
import { ReactNode, useMemo } from 'react'
interface PropTypes {
  source: string
  project: any
  choices: any[]
  submitSource?: string
  color?: any
  renderValue?: (choice) => ReactNode
  renderChoiceValue?: (choice) => ReactNode
  handlePriorityChange: any
}
const ManageSelectDropdown: React.FC<PropTypes> = ({
  source,
  project,
  choices,
  color,
  renderValue,
  renderChoiceValue,
  handlePriorityChange,
}) => {
  const getChoiceValue = (item) => {
    if (!item) return ''
    if (renderChoiceValue) return renderChoiceValue(item)
    return translate(item.name || item.title || '')
  }
  const recordValue = useMemo(() => lodash.get(project, source), [project, source])

  return (
    <FormControl variant="outlined" size="small" color={color}>
      <SelectInput choices={choices} style={{ display: 'none' }} source={source} />
      <Select
        value={recordValue}
        onChange={handlePriorityChange}
        renderValue={() => {
          const item = choices?.find((choice) => choice.id === recordValue)
          if (!item) return ''
          if (renderValue) return renderValue(item)
          return translate(item.name || item.title || '')
        }}
      >
        {choices.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {getChoiceValue(item)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
export default ManageSelectDropdown

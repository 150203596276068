import { Typography } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orgSelectors } from 'ducks/orgs'
import Alert from 'elements/Alert'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { getSupplierConfig } from '../configs'

const useAlertStyles = makeOpenSolarStyles((theme) => ({
  heading: {
    fontSize: 24,
    lineHeight: '20px',
    marginBottom: 10,
  },
  wrapper: {
    margin: '15px 0px',
    padding: '10px 10px',
    border: '1px solid #FFB945',
  },
}))

const OutOfStockItemsAlert = () => {
  const classes = useAlertStyles()
  const selectedDistributor = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const supplierConfig = getSupplierConfig(selectedDistributor)

  useEffect(() => {
    logAmplitudeEvent('hardware_outOfStock_items_warning', {
      action: 'viewed',
      context: 'warning',
    })
  }, [])

  return (
    <Alert severity="warning" classes={{ wrapper: classes.wrapper }}>
      <div className={classes.heading}>Out of Stock Items</div>
      <Typography>
        {`Items below are out of stock. They may still be added to your order. Your supplier ${
          supplierConfig ? supplierConfig.name : ''
        } will work to fulfill them when your order is processed.`}
      </Typography>
    </Alert>
  )
}

export default OutOfStockItemsAlert

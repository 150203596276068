import { featureConfigSelectors, getPublicFeatureConfig } from 'ducks/featureConfig'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'types/state'

export const usePublicFeatureConfig = (key: string, forceLoad?: boolean) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getPublicFeatureConfig(key, forceLoad))
  }, [])

  return useSelector((state: RootState) => featureConfigSelectors.getFeatureConfigData(state, key))
}

import { orgSelectors } from 'ducks/orgs'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from 'opensolar-ui'
import { useNotify } from 'ra-core'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useGetOauthIntegrationIsVisible } from 'resources/paymentOptions/financialIntegrations/genericPromoContent/utils'
import restClient from 'restClient'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  integrationKey: string
  lenderDisplayName: string
  logoURL: string
}

const useStyles = makeOpenSolarStyles((theme) => ({
  actionsRow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  btnWrapper: {
    margin: '10px',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const IntegrationOauthDialog: React.FC<PropTypes> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [oauthURL, setOauthURL] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)

  const classes = useStyles()
  const notify = useNotify()
  const orgId = useSelector(orgSelectors.getOrg)?.id
  const isVisible = useGetOauthIntegrationIsVisible(props.integrationKey)

  useEffect(() => {
    if (oauthURL) {
      window.location.href = oauthURL
    }
  }, [oauthURL])

  const closeDialog = () => {
    setIsOpen(false)
  }

  const openDialog = () => {
    setIsOpen(true)
  }

  const launchOauth = () => {
    setLoading(true)
    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${orgId}/finance/${props.integrationKey}/oauth_url/`,
    })
      .catch((res) => {
        setOauthURL(res.data.oauth_url)
      })
      .finally(() => {
        notify('Unable to start account connection process.', 'warning')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  if (!isVisible) return null
  return (
    <>
      <div>
        <h2>{props.lenderDisplayName} Integration</h2>
        <Button onClick={openDialog} variant="contained" color="primary">
          Activate {props.lenderDisplayName}
        </Button>
      </div>
      <Dialog open={isOpen} onClose={closeDialog}>
        <DialogTitle>Connect your {props.lenderDisplayName} account</DialogTitle>
        <DialogContent>
          <div className={classes.contentWrapper}>
            <img height="75px" src={props.logoURL} alt={props.lenderDisplayName} />
            <p>
              To activate your {props.lenderDisplayName} integration you'll have to connect OpenSolar to your{' '}
              {props.lenderDisplayName} account. Clicking "Connect Account" below will bring you to the{' '}
              {props.lenderDisplayName} site where you'll be asked to log in and share data with OpenSolar.
            </p>
            <p>
              After your account has been connected you will be brought back to OpenSolar and your{' '}
              {props.lenderDisplayName} will automatically be imported into your account
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          <div className={classes.actionsRow}>
            <div className={classes.btnWrapper}>
              <Button onClick={closeDialog} variant="contained">
                Cancel
              </Button>
            </div>
            <div className={classes.btnWrapper}>
              <Button onClick={launchOauth} variant="contained" color="primary">
                Connect Account
              </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default IntegrationOauthDialog

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import Inbox from '@material-ui/icons/Inbox';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { useTranslate } from 'ra-core';
import { CreateButton } from '../button';
import inflection from 'inflection';
var useStyles = makeStyles(function (theme) { return ({
    message: {
        textAlign: 'center',
        opacity: theme.palette.type === 'light' ? 0.5 : 0.8,
        margin: '48px 0px',
        color: theme.palette.type === 'light' ? 'inherit' : theme.palette.text.primary,
    },
    icon: {
        width: '9em',
        height: '9em',
    },
    toolbar: {
        textAlign: 'center',
        marginTop: '2em',
    },
}); }, { name: 'RaEmpty' });
var Empty = function (props) {
    var resource = props.resource, basePath = props.basePath;
    var classes = useStyles(props);
    var translate = useTranslate();
    var resourceName = inflection.humanize(translate("resources.".concat(resource, ".name"), {
        smart_count: 0,
        _: inflection.pluralize(resource),
    }), true);
    var emptyMessage = translate('ra.page.empty', { name: resourceName });
    var inviteMessage = translate('ra.page.invite');
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: classes.message }, { children: [_jsx(Inbox, { className: classes.icon }), _jsx(Typography, __assign({ variant: 'h4', paragraph: true }, { children: translate("resources.".concat(resource, ".empty"), {
                        _: emptyMessage,
                    }) })), _jsx(Typography, __assign({ variant: 'body1' }, { children: translate("resources.".concat(resource, ".invite"), {
                        _: inviteMessage,
                    }) }))] })) }));
};
export default Empty;

import { appStateSelectors } from 'ducks/app'
import { useSelector } from 'react-redux'

const EmptyWrapper = ({ children }) => <>{children}</>

/**
 * Why we need this wrapper:
 * on react-admin ui viewVersion update suppose to trigger a re-render of the app and re-fetch the resource data cached in redux.
 * This works well for the most components built with react-admin, but not for the components that are not connected to the redux store.
 * This wrapper is used to force a re-mount of the app. So that all components will be re-mounted.
 * This is faster and better than reload entire app.
 */
const RefreshViewWrapper = ({ children }) => {
  const viewVersion = useSelector(appStateSelectors.getAppViewVersion)
  // The app will re-mount when the view version changes
  return <EmptyWrapper key={viewVersion}>{children}</EmptyWrapper>
}

export default RefreshViewWrapper

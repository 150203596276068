import { makeStyles } from '@material-ui/core'
import { Button } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    borderRadius: 5,
    margin: 'auto',
    width: 250,
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.54)',
    border: '1px solid rgba(0, 0, 0, 0.3)',
  },
}))

export const NoUserActivities = () => {
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()
  return (
    <div className={classes.container}>
      <p style={{ marginTop: 0 }}>{translate('No assigned activities yet')}!</p>
      <Button variant="outlined" color="default" onClick={() => history.push('/events/create')}>
        <span>{translate('Create Activity')}</span>
      </Button>
    </div>
  )
}

export const NoCustomerActivities = () => {
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <div className={classes.container}>
      <span>{translate('No customer activities yet')}!</span>
      <span>{translate('Start sending proposals to customers to monitor their activities')}.</span>
    </div>
  )
}

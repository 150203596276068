import { and, ControlProps, rankWith, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import * as React from 'react'

const tester = rankWith(100, and(uiTypeIs('Image')))

const Renderer: React.FC<ControlProps> = ({ uischema, visible }) => {
  if (!visible) return <></>

  const src = uischema['src']
  const style = uischema['style']
  return <img src={src} style={style || {}} />
}

const renderer = withJsonFormsControlProps(Renderer)

export default {
  tester,
  renderer,
}

import type { ComponentTypesV2 } from 'types/selectComponent'
import BasicFilter from '../basic/BasicFilter'
import type {
  ConfigurableFilterOptionsType,
  FilterComponentNodeV2,
  FilterGenericPropsType,
  OptionGenericType,
} from '../typeV2'
import { parseStringifiedListFilterValue } from '../utils/utils'
import { MPPT_QUANTITY_OPTIONS } from './MpptQuantityFilter'
export const MPPT_QUANTITY_FILTER_KEY_V2 = 'mppt_quantity'

export const createMpptQuantityFilterNode = <T,>({
  label = 'Component Type',
  ...restConfigs
}: ConfigurableFilterOptionsType<string, T>): FilterComponentNodeV2 => {
  const FilterComponent = (filterProps: FilterGenericPropsType) => {
    const types = parseStringifiedListFilterValue<ComponentTypesV2>({
      filterValues: filterProps.allFilterValues,
      key: 'types',
    })

    if (!types.has('inverter')) {
      return null
    }

    return (
      <BasicFilter
        label={label}
        defaultSelectedOptionValue={''}
        options={MPPT_QUANTITY_OPTIONS as OptionGenericType<string>[]}
        filterKey={MPPT_QUANTITY_FILTER_KEY_V2}
        {...restConfigs}
        {...filterProps}
      />
    )
  }

  return {
    key: MPPT_QUANTITY_FILTER_KEY_V2,
    component: FilterComponent,
  }
}

export default createMpptQuantityFilterNode

import { useEffect, useState } from 'react'
import { AnyComponentType } from 'types/components'
import { ComponentTypes } from 'types/selectComponent'
import useActivatedComponents from './useActivatedComponents'
import { useFetchComponentFromDB } from './useFetchComponentFromDB'

type Args = {
  type: ComponentTypes
  code: string
  useActivated?: boolean
  useDatabase?: boolean
}

export const useComponentDataByCode = ({
  type,
  code,
  useActivated = true,
  useDatabase = true,
}: Args): AnyComponentType | undefined => {
  const [componentData, setComponentData] = useState<AnyComponentType | undefined>(undefined)
  const activatedComponents = useActivatedComponents({ type })
  const fetchComponentFromDB = useFetchComponentFromDB()

  useEffect(() => {
    if (useActivated) {
      const activated = activatedComponents.find((component) => component.code === code)
      if (activated) {
        setComponentData(activated)
        return
      }
    }

    if (useDatabase) {
      fetchComponentFromDB(type, code).then((response) => {
        setComponentData(response.data[0] as AnyComponentType | undefined)
      })
    }
  }, [useActivated, useDatabase, code, activatedComponents.length])

  return componentData
}

import { authSelectors } from 'ducks/auth'
import { openOrderCart, orderSelectors } from 'ducks/orderComponents'
import type LineItemType from 'pages/ordering/OrderLineItem'
import useProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/useProjectOrderPresenter'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import ProjectHelper from 'resources/projects/ProjectHelper'
import { ProjectType } from 'types/projects'
import { DialogHelper } from 'util/misc'
import SectionButtonCTA from '../ctaAccordion/SectionButtonCTA'
import MainCTA from './MainCTA'

type PropTypes = {
  isMainCTA?: boolean
  disabled?: boolean
}

const SegenCTA: React.FunctionComponent<PropTypes> = (props) => {
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const orgId = useSelector(authSelectors.getOrgId)
  const orderingPresenter = useProjectOrderPresenter()
  const orderProjects = useSelector(orderSelectors.getOrderProjectIds)
  const form = useForm()
  const record = form.getState().values as ProjectType
  const dispatch = useDispatch()

  const openCart = useCallback(() => {
    dispatch(openOrderCart(true))
    DialogHelper.afterOpen()
  }, [])

  const addOrderItemsToCart = async (
    lineItems: LineItemType[] | undefined,
    projectId: number,
    projectAddress: string | null
  ) => {
    if (!lineItems || !orgId) {
      setErrorMsg('Unable to access this feature')
      return
    }

    if (!orderProjects.includes(projectId)) {
      openCart()
      return
    }

    await orderingPresenter.addLineItems(lineItems)
    openCart()
  }

  const handleClick = () => {
    const soldSystemData = ProjectHelper.getSoldSystemData(record, window.editor.sceneAsJSON())

    if (!soldSystemData) {
      setErrorMsg('This project must have a sold system first')
      return
    }

    addOrderItemsToCart(orderingPresenter.getLineItemsFromSystem(soldSystemData, 'segen'), record.id, record.address)
  }

  var systems =
    window.editor && window.editor.getSystems()?.length > 0
      ? window.editor.getSystems().map((s) => s.userData)
      : record.systems

  return (
    <>
      {props.isMainCTA ? (
        <MainCTA
          handleClick={handleClick}
          errorMsg={errorMsg}
          // @ts-ignore
          systems={systems}
          hasSoldSystem={!!record?.system_sold}
          projectId={record.id}
        />
      ) : (
        <SectionButtonCTA handleClick={handleClick} disabled={props.disabled} label="Order with Segen" />
      )}
    </>
  )
}
export default SegenCTA

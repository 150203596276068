import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import Button from 'elements/button/Button'
import CustomField from 'elements/field/CustomField'
import { useCountryListsTranslated } from 'hooks/useCountryListsTranslated'
import { useState } from 'react'
import { useTranslate } from 'react-admin'
import { Form } from 'react-final-form'
import { required } from 'validations'

type Address = {
  address?: string
  address_2?: string
  locality?: string
  state?: string
  zip?: string
  country_iso2?: string
}

type Props = {
  handleUpdateAddress: (values: Address) => void
  initialValues: Address
  requiredFields?: string[]
}

const renderInput = ({ label, input, meta: { touched, invalid, error }, fullWidth, ...custom }) => (
  <TextField
    label={label}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    margin="dense"
    {...input}
    {...custom}
    fullWidth={fullWidth}
  />
)

const renderSelectField = ({
  input,
  label,
  required = true,
  meta: { touched, error },
  children,
  onChange,
  ...custom
}) => {
  return (
    <FormControl style={{ width: '100%' }} margin="dense" error={touched && error}>
      <InputLabel id="demo-simple-select-label" required={required}>
        {label}
      </InputLabel>
      <Select
        {...input}
        MenuProps={{ disableScrollLock: true }}
        onChange={(event) => {
          onChange(event.target.value, input)
        }}
        children={children}
        {...custom}
      />
      <FormHelperText>{touched && error}</FormHelperText>
    </FormControl>
  )
}

const AddressCompletionForm = ({ handleUpdateAddress, initialValues, requiredFields }: Props) => {
  const [showAllCountries, setAllCountries] = useState(false)
  const [keepCountrySelectOpen, setKeepCountrySelectOpen] = useState(false)
  const translate = useTranslate()

  const handleSubmitForm = (values) => {
    handleUpdateAddress(values)
  }
  const { countriesMinimal, countriesAll, otherCountry } = useCountryListsTranslated()
  const countryList = showAllCountries === true ? [...countriesAll, otherCountry] : countriesMinimal

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
      render={({ handleSubmit, form, submitting, pristine }) => (
        // Field mapping is based on AddressAutocomplete mapping
        <Grid item xs={12} sm={12} lg={12}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} lg={12}>
                <CustomField
                  id="address"
                  name="address"
                  validate={requiredFields?.includes('address') ? required : null}
                  required={requiredFields?.includes('address')}
                  component={renderInput}
                  label={'Address Line 1'}
                  fullWidth={true}
                  helperText="Street address, P.O box, Company Name, c/o"
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <CustomField
                  id="address_2"
                  name="address_2"
                  validate={requiredFields?.includes('address_2') ? required : null}
                  required={requiredFields?.includes('address_2')}
                  component={renderInput}
                  label={'Address Line 2'}
                  fullWidth={true}
                  helperText="Apartment, suite, unit, building, floor, etc."
                />
              </Grid>
              <Grid container xs={12} sm={12} lg={12} style={{ gap: '10px' }}>
                <Grid item xs={3} sm={3} lg={3}>
                  <CustomField
                    id="locality"
                    name="locality"
                    validate={requiredFields?.includes('locality') ? required : null}
                    required={requiredFields?.includes('locality')}
                    component={renderInput}
                    label={'City'}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={3} sm={3} lg={3}>
                  <CustomField
                    id="state"
                    name="state"
                    validate={requiredFields?.includes('state') ? required : null}
                    required={requiredFields?.includes('state')}
                    component={renderInput}
                    label={'State / Province / Region'}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={3} sm={3} lg={3}>
                  <CustomField
                    id="zip"
                    name="zip"
                    validate={requiredFields?.includes('zip') ? required : null}
                    required={requiredFields?.includes('zip')}
                    component={renderInput}
                    label={'ZIP / Postal Code'}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
              <Grid container xs={12} sm={12} lg={12} style={{ gap: '10px' }}>
                <Grid item xs={3} sm={3} lg={3}>
                  <CustomField
                    id="country_iso2"
                    name="country_iso2"
                    validate={requiredFields?.includes('country_iso2') ? required : null}
                    required={requiredFields?.includes('country_iso2')}
                    component={renderSelectField}
                    onChange={(value, input) => {
                      if (value !== 'change_view') {
                        input.onChange(value)
                        setKeepCountrySelectOpen(false)
                      }
                    }}
                    onClick={(e) => {
                      setKeepCountrySelectOpen(!keepCountrySelectOpen)
                      if (e.target.value === 'change_view') {
                        setAllCountries(!showAllCountries)
                        form.change('country_iso2', '')
                        setKeepCountrySelectOpen(true)
                      } else {
                        setKeepCountrySelectOpen(!keepCountrySelectOpen)
                      }
                    }}
                    label={translate('Country')}
                    open={keepCountrySelectOpen}
                  >
                    <MenuItem value={''} key={0}></MenuItem>
                    {countryList.map((c) => (
                      <MenuItem id={'Country' + c.iso2} value={c.iso2} key={c.iso2}>
                        {c.translatedName}
                      </MenuItem>
                    ))}
                    <MenuItem value={'change_view'} id={'change_view'}>
                      {showAllCountries ? translate('Show less...') : translate('Show more...')}
                    </MenuItem>
                  </CustomField>
                </Grid>
              </Grid>
            </Grid>
            <Button id="create_account_btn" type="submit" color="default" loading={submitting} disabled={pristine}>
              <span>Save changes</span>
            </Button>
          </form>
        </Grid>
      )}
    />
  )
}

export default AddressCompletionForm

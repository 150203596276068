import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddOutlined'
import DependentInput from 'elements/input/DependentInput'
import { IconButton } from 'opensolar-ui'
import React, { memo, useState } from 'react'
import { SelectInput, TextInput, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { FIELD_TYPES, PREDEFINED_MODELS_CONFIG, PREDEFINED_MODELS_TYPES, getModelByFieldName } from './PredefinedModels'

const inputStyle = {
  width: '100%',
  maxWidth: 256,
}

type ModelType = 'Project' | 'Contact' | 'COGS'

type InputPropsType = {
  model?: ModelType
  record: any
  disableLocationSelector?: boolean
  disableFieldSelector?: boolean
}

const getDefaultModel = (record: any, model: ModelType | undefined) => {
  if (model) {
    return model
  } else {
    //@ts-ignore
    return getModelByFieldName(record.field)
  }
}

const SelectFieldChoices = memo(({ source }: any) => {
  const [count, setCount] = useState(0)
  const form = useForm()
  const choices = form.getState().values[source] || []
  const translate = useTranslate()
  const addItem = () => {
    form.change(`${source}.${choices.length}`, '')
    //rerender
    setCount(count + 1)
  }

  return (
    <div>
      <h2>
        {translate('Select Field Choices')}{' '}
        <IconButton onClick={addItem}>
          <AddIcon style={{ verticalAlign: 'text-bottom', height: 20, width: 20, color: '#000000' }} />
        </IconButton>
      </h2>
      {choices &&
        choices.map((choice: string, index: number) => (
          <TextInput
            key={choice + index}
            source={`${source}.${index}`}
            label={`${translate('Select Field Choice')} ${index + 1}`}
            style={inputStyle}
          />
        ))}
    </div>
  )
})

const CustomFieldInputs: React.FC<InputPropsType> = ({
  record,
  model,
  disableLocationSelector,
  disableFieldSelector,
}) => {
  const translate = useTranslate()
  const [selectedModel, setSelectedMode] = useState(getDefaultModel(record, model))
  const { availableFields, keys = [] } = (selectedModel && PREDEFINED_MODELS_CONFIG[selectedModel]) || {}
  return (
    <Grid style={{ padding: '40px 0' }} container>
      {
        <Grid item xs={12}>
          <FormControl margin="dense" style={inputStyle} disabled={disableLocationSelector}>
            <InputLabel>{translate('Field Location')}</InputLabel>
            <Select
              value={selectedModel}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setSelectedMode(event.target.value as ModelType)
              }}
            >
              {PREDEFINED_MODELS_TYPES.map((model) => (
                <MenuItem key={model} value={model}>
                  {translate(PREDEFINED_MODELS_CONFIG[model]?.label)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      }
      {keys.map((key: string) => {
        switch (key) {
          case 'field':
            return (
              selectedModel && (
                <Grid item xs={12} md={6}>
                  <SelectInput
                    resource={'custom_fields'}
                    options={{ style: inputStyle }}
                    disabled={disableFieldSelector}
                    key={key}
                    source={key}
                    choices={availableFields}
                  />
                </Grid>
              )
            )
          case 'label':
            return (
              <Grid item xs={12} md={6}>
                <TextInput resource={'custom_fields'} key={key} source={key} style={inputStyle} />
              </Grid>
            )
          case 'field_type':
            return (
              <Grid item xs={12} md={6}>
                <SelectInput
                  options={{ style: inputStyle }}
                  resource={'custom_fields'}
                  key={key}
                  source={key}
                  choices={FIELD_TYPES}
                />
              </Grid>
            )
          case 'select_field_choices':
            return (
              <DependentInput dependsOn="field_type" resolve={(value: number) => value === 1 || value === 4}>
                <Grid item xs={12} md={12}>
                  <SelectFieldChoices key={key} source={key} />
                </Grid>
              </DependentInput>
            )
          default:
            return (
              <Grid item xs={12} md={6}>
                <TextInput key={key} source={key} style={inputStyle} />
              </Grid>
            )
        }
      })}
    </Grid>
  )
}

export default CustomFieldInputs

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { default as Mui4RadioGroup } from '@material-ui/core/RadioGroup';
import { default as Mui5RadioGroup } from '@mui/material/RadioGroup';
import { useComponentVersion } from '../hooks/useComponentVersion';
import { OsRadioVersion } from './Radio';
export var RadioGroup = function (props) {
    var version = useComponentVersion('radio');
    switch (version) {
        case OsRadioVersion.V2:
            return _jsx(Mui5RadioGroup, __assign({}, props));
        default:
            return _jsx(Mui4RadioGroup, __assign({}, props));
    }
};

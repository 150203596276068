import { styled } from 'opensolar-ui'
import { Datagrid } from 'react-admin'

export const SelectTitle = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '& img': {
    marginRight: 10,
  },
  '& p': {
    margin: 0,
  },
}))

interface TableViewStyledDatagridProps {
  bulkActionsDisabled: boolean
}

export const TableViewStyledDatagrid = styled(Datagrid)<TableViewStyledDatagridProps>(
  ({ theme, bulkActionsDisabled }) => {
    const checkboxStyle = bulkActionsDisabled ? 'none !important' : 'auto'
    return {
      '& .MuiTableCell-head': {
        backgroundColor: 'transparent',
      },
      '& .MuiTableCell-root': {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        minHeight: 70,
        padding: 10,
        width: '100%',
        boxSizing: 'border-box',
        borderBottom: '.5px solid #EFF0F2',
        [theme.breakpoints.down('sm')]: {
          borderBottom: '0px solid #EFF0F2',
          minHeight: 40,
          padding: 5,
        },
        '& >div:first-of-type': {
          width: '100%',
        },
      },
      '& .MuiTableCell-root>span': {
        // items within cell are wrapped in span
        width: '100%',
      },
      '& .MuiTableHead-root': {
        backgroundColor: '#F6F9FC',
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
        '& tr, & tr:hover': {
          backgroundColor: '#EFF0F2 !important',
          borderRadius: '8px 8px 0px 0px',
          display: 'flex',
          alignItems: 'center',
        },
      },
      '& .MuiTableRow-root': {
        display: 'flex',
        position: 'relative',
      },
      '& tr:hover, & td:hover': {
        backgroundColor: 'white !important',
      },
      '& td:first-of-type, & th:first-of-type': {
        // checkbox col
        width: 60,
        minWidth: 60,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'transparent',
        '&>span:hover': {
          backgroundColor: 'transparent',
          borderRadius: 4,
        },
      },

      '& th:first-of-type > span, & td:first-of-type > span': {
        // checkbox cell
        display: checkboxStyle,
      },
      '& th:nth-of-type(2), & td:nth-of-type(2)': {
        // title col
        width: 'calc(45% - 185px)',
        minWidth: 'calc(45% - 185px)',
      },
      '& th:nth-of-type(3), & td:nth-of-type(3)': {
        // tags col
        width: 'calc(25% - 15px)',
        minWidth: 'calc(25% - 15px)',
        display: 'flex',
        flexDirection: 'row',
        gap: 5,
      },
      '& th:nth-of-type(4), & td:nth-of-type(4)': {
        // filsize col
        width: '10%',
        minWidth: '10%',
      },

      '& th:nth-of-type(5), & td:nth-of-type(5)': {
        // modified date col
        width: '20%',
        minWidth: '20%',
      },
      '& td:nth-of-type(6)': {
        // row actions col
        width: '144px',
        minWidth: '144px !important',
      },
      '& th:nth-of-type(6)': {
        // row actions header cell
        display: 'none',
      },
      //mobile styling
      '& tbody > tr': {
        //affects only body cells
        [theme.breakpoints.down('sm')]: {
          flexWrap: 'wrap',
          justifyContent: 'end',
          borderBottom: '.5px solid #EFF0F2',
          padding: '10px 0px',
        },
      },
      '& td:nth-of-type(2),& td:nth-of-type(3), & td:nth-of-type(4)': {
        // title and tags column take full row minus
        [theme.breakpoints.down('sm')]: {
          width: 'calc(100% - 60px)',
          minWidth: 'calc(100% - 60px)',
        },
      },
      '& td:nth-of-type(5)': {
        // title and tags column take full row minus
        [theme.breakpoints.down('sm')]: {
          width: 'calc(100% - 204px)',
          minWidth: 'calc(100% - 204px)',
        },
      },
    }
  }
)

import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { useUxVersion } from 'hooks/useUxVersion'
import FullScreenLoading from 'layout/widgets/FullScreenLoading'
import React, { memo } from 'react'
import Skeleton from 'react-loading-skeleton'
import getProjectSectionStyles from '../../styles'
import LoadingSkeleton from './LoadingSkeleton'

type PropTypes = {
  showTopNav: boolean
}
//used for UX2 Pages
const useStyles = makeStyles(getProjectSectionStyles)
const PageLoadingSkeleton: React.FunctionComponent<PropTypes> = (props) => {
  const classes = useStyles()
  const uxVersion = useUxVersion()
  const ui3Enabled = uxVersion === 3
  let columnHeights: [number[], number[]] | undefined
  let fullscreen = true

  const parts = window.location.hash.split('/')
  switch (parts[parts.length - 1]) {
    case 'info':
      fullscreen = false
      columnHeights = [
        [291, 48, 48, 48, 48, 48, 48, 48],
        [114, 128, 142],
      ]
      break
    case 'energy':
      fullscreen = false
      columnHeights = [[741, 344], []]
      break
    case 'manage':
      fullscreen = false
      columnHeights = [
        [293, 264],
        [584, 153],
      ]
      break
  }
  return (
    <div>
      {props.showTopNav && (
        <div style={{ height: '50px', width: '100%' }}>
          <Skeleton height={'50px'} />
        </div>
      )}
      {fullscreen && <FullScreenLoading />}
      {ui3Enabled ? (
        <LoadingSkeleton />
      ) : (
        <>
          {!fullscreen && columnHeights && (
            <div className={classes.formPage}>
              <div className={classes.sectionWrapper}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={8}>
                    <Grid container spacing={3}>
                      {columnHeights[0].map((height, idx) => (
                        <Grid item xs={12} key={idx}>
                          <div style={{ height: height + 'px', width: '100%' }}>
                            <Skeleton height={height + 'px'} />
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Grid container spacing={3}>
                      {columnHeights[1].map((height, idx) => (
                        <Grid item xs={12} key={idx}>
                          <div style={{ height: height + 'px', width: '100%' }}>
                            <Skeleton height={height + 'px'} />
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}
export default memo(PageLoadingSkeleton)

import { logAmplitudeEvent } from 'amplitude/amplitude'
import Button from 'elements/button/Button'
import React, { memo, useCallback, useState } from 'react'
import { Confirm } from 'react-admin'
import { useHistory } from 'react-router-dom'

const ViewYourProject = ({ projectId }: { projectId: number }) => {
  const history = useHistory()
  const [showConfirm, setShowConfirm] = useState(false)

  const handleViewProject = useCallback(() => {
    logAmplitudeEvent('hardware_project_redirect', {
      action: 'clicked',
      context: 'view_your_project',
      source: 'replace_item_dialog',
    })
    setShowConfirm(true)
  }, [projectId])

  const onConfirm = useCallback(() => {
    logAmplitudeEvent('generic_confirmation_interacted', {
      action: 'confirmed',
    })
    history.push(`/projects/${projectId}/design`)
  }, [projectId])

  const onClose = useCallback(() => {
    setShowConfirm(false)
    logAmplitudeEvent('generic_confirmation_interacted', {
      action: 'closed',
    })
  }, [])
  return (
    <>
      <Button onClick={handleViewProject}>View Your Project</Button>
      {showConfirm && (
        <Confirm
          isOpen={showConfirm}
          title={'Leave order?'}
          content="Your cart progress will be lost if you navigate away from this page. Please finalise any orders if you do not wish to lose your progress."
          confirmColor="warning"
          cancel="Stay on page"
          confirm="Leave this page"
          onConfirm={onConfirm}
          onClose={onClose}
        />
      )}
    </>
  )
}

export default memo(ViewYourProject)

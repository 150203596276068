import React, { FC, useCallback } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { StageActionEventType, StageActionType } from 'types/projects'
import DialogEditor from '../../../../elements/DialogEditor'
import EditEventDialogForm from './EditEventDialogFormWrapper'

interface PropTypes {
  setShowEditDialog: Function
  showEditDialog: boolean
  eventToEdit: StageActionEventType
  actionToEdit: StageActionType
  onDismiss: () => void
  onDelete: (id?: number) => void
}

const EditContactDialog: FC<PropTypes> = (props) => {
  //could we move showEditDialog logic into this component??
  const form = useForm()
  const formState = form.getState()
  const notify = useNotify()
  const translate = useTranslate()
  const onSuccess = useCallback((response: any) => {
    notify('Success', 'success')
    let newEvents = [] as StageActionEventType[]
    // update the events array for this action with the newly saved event
    if (props.actionToEdit && props.actionToEdit.events) {
      newEvents = props.actionToEdit.events.map((actionEvent) => {
        if (actionEvent.id === props.eventToEdit.id) {
          actionEvent = response.data
        }
        return actionEvent
      })
    }

    //now update form state with the new actions array that contains the newly saved event

    let actions: StageActionType[] = formState.values.actions
    actions = actions?.map((action) => {
      if (action.id === props.actionToEdit.id) action.events = newEvents
      return action
    })
    formState.initialValues.actions = actions
    form.change('actions', actions)
    props.setShowEditDialog(false)
  }, [])
  const onFailure = useCallback((e: any) => notify(e.message, 'warning'), [])

  return (
    <DialogEditor
      //@ts-ignore
      isOpen={props.showEditDialog}
      handleClose={() => props.setShowEditDialog(false)}
      id={props.eventToEdit.id}
      title={translate('Edit Event')}
      editorComponent={
        <EditEventDialogForm
          //@ts-ignore
          basePath={'/events'}
          resource={'events'}
          redirect={false}
          hideTitle={true}
          hideCancelButton={true}
          hideBreadCrumbs={true}
          style={{ padding: 0 }}
          onDismiss={props.onDismiss}
          onSuccess={onSuccess}
          onFailure={onFailure}
          onDelete={props.onDelete}
        />
      }
    />
  )
}
export default EditContactDialog

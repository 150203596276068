import { authSelectors } from 'ducks/auth'
import type { SponsoredContentType } from 'persistentContent/inAppPromo/types'
import { filterPromos } from 'persistentContent/inAppPromo/utils'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { usePromos } from './usePromos'

const useAvailableSponsoredContents = () => {
  const org = useSelector(authSelectors.getCurrentOrg)
  const orgCountry = useSelector(authSelectors.getCurrentOrgCountry) || ''

  const { promos, state: promosLoadingState } = usePromos('sponsored_content')

  return useMemo(() => {
    const filtersData = {
      requireMeetsOne: false,
      org,
      orgCountry,
      user: undefined, // not required
      role: undefined, // not required
      isAdmin: undefined, // not required
      acceptedTermsDate: undefined, // not required
    }
    const filteredPromos = filterPromos(promos, filtersData)
    const sponsoredContents: SponsoredContentType[] = []

    filteredPromos.forEach((promo) => {
      if (promo.sponsoredContent) {
        sponsoredContents.push(promo.sponsoredContent)
      }
    })

    return {
      availableConfigs: filteredPromos,
      promosLoadingState,
      sponsoredContents,
    }
  }, [org, orgCountry, promos, promosLoadingState])
}

export default useAvailableSponsoredContents

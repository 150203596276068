import ProUxButton from 'elements/proUXButtons/ProUXButton'
import React from 'react'
import { formatCurrency } from 'util/misc'

type PropTypes = {
  decision:
    | 'Conditionally approved'
    | 'Approved'
    | 'Conditionally approved for reduced loan amount'
    | 'Approved for reduced loan amount'
    | 'Declined'
    | 'Declined - Credit Report Not Found'
    | 'Declined - Credit Report Frozen'
  onClose: () => void
  amountApproved?: number
  amountRequested?: number
  approvedContacts?: number[]
  appliedWithCoborrower: boolean
  stips: string[]
}

const DividendDecisionExplanation: React.FunctionComponent<PropTypes> = (props) => {
  return (
    <div
      style={{
        margin: '10px 0px 20px 0px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      {props.decision === 'Approved' && (
        <div>
          <img
            src={`${window.PUBLIC_URL}/images/dividend-logo-wide.svg`}
            alt=""
            height={35}
            style={{ marginBottom: '30px' }}
          />
          <h2>Loan Decision - Approved</h2>
          <p>Your application for financing has been approved! Please check your email for more details.</p>
        </div>
      )}
      {props.decision?.toLowerCase() === 'pending' && (
        <div>
          <img
            src={`${window.PUBLIC_URL}/images/dividend-logo-wide.svg`}
            alt=""
            height={35}
            style={{ marginBottom: '30px' }}
          />
          <h2>Loan Decision - Pending</h2>
          <p>
            Your application for financing is under review. Dividend is reviewing your application and will send you an
            email with your credit decision.
          </p>
        </div>
      )}
      {props.decision === 'Declined' && (
        <div>
          <h2>Loan Decision - Declined</h2>
          <p>
            Your application for financing has been declined. Please check your email and contact your salesperson for
            more details.
          </p>
        </div>
      )}
      {props.decision === 'Declined - Credit Report Not Found' && (
        <div>
          <h2>Loan Decision: Declined - Credit Report Not Found</h2>
          <p>
            Dividend was unable to find your credit report with the information you provided and your application for
            financing has been declined. Please check your email and contact your salesperson for more details.
          </p>
        </div>
      )}
      {props.decision === 'Declined - Credit Report Frozen' && (
        <div>
          <h2>Loan Decision: Declined - Credit Report Frozen</h2>
          <p>
            Dividend has indicated that your credit report is frozen and they are unable to pull credit. Your
            application for financing has been declined. Please check your email and contact your salesperson for more
            details.
          </p>
        </div>
      )}
      {props.decision === 'Conditionally approved' && (
        <div>
          <img
            src={`${window.PUBLIC_URL}/images/dividend-logo-wide.svg`}
            alt=""
            height={45}
            style={{ marginBottom: '30px' }}
          />
          <h2>Loan Decision - Conditionally Approved</h2>
          <p>
            Your application for financing has been conditionally approved pending verification of certain
            documentation. Please check your email for information on next steps.
          </p>
        </div>
      )}
      {props.decision === 'Conditionally approved for reduced loan amount' && (
        <div>
          <img
            src={`${window.PUBLIC_URL}/images/dividend-logo-wide.svg`}
            alt=""
            height={45}
            style={{ marginBottom: '30px' }}
          />
          <h2>Loan Decision - Conditionally Approved for ${formatCurrency(props.amountApproved)}</h2>
          <p>
            Your application for financing has been conditionally approved for a maximum loan amount of $
            {formatCurrency(props.amountApproved)} pending verification of certain documentation. Please note that this
            maximum approval amount is less than the total financed amount associated with the current system design.
            Please consult your installer representative on next steps.
          </p>
        </div>
      )}
      {props.decision === 'Approved for reduced loan amount' && (
        <div>
          <img
            src={`${window.PUBLIC_URL}/images/dividend-logo-wide.svg`}
            alt=""
            height={45}
            style={{ marginBottom: '30px' }}
          />
          <h2>Loan Decision - Approved for ${formatCurrency(props.amountApproved)}</h2>
          <p>
            Your application for financing has been approved for a maximum loan amount of $
            {formatCurrency(props.amountApproved)} pending verification of certain documentation. Please note that this
            maximum approval amount is less than the total financed amount associated with the current system design.
            Please consult your installer representative on next steps.
          </p>
        </div>
      )}
      <div style={{ marginTop: '30px' }}>
        <ProUxButton type="secondary" onClick={props.onClose} label="Continue" />
      </div>
    </div>
  )
}
export default DividendDecisionExplanation

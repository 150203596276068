var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var UserProfileGroup = function (_a) {
    var _b = _a.size, size = _b === void 0 ? '1rem' : _b, _c = _a.color, color = _c === void 0 ? 'currentColor' : _c, rest = __rest(_a, ["size", "color"]);
    var iconAttributes = {
        width: size,
        height: size,
        stroke: color,
    };
    return (_jsxs("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", fill: "none" }, rest, iconAttributes, { children: [_jsx("path", { d: "M9.33268 10.6667C11.1736 10.6667 12.666 9.17428 12.666 7.33333C12.666 5.49238 11.1736 4 9.33268 4C7.49173 4 5.99935 5.49238 5.99935 7.33333C5.99935 9.17428 7.49173 10.6667 9.33268 10.6667Z", fill: color }), _jsx("path", { d: "M18.666 8.66667C18.666 9.77124 17.7706 10.6667 16.666 10.6667C15.5614 10.6667 14.666 9.77124 14.666 8.66667C14.666 7.5621 15.5614 6.66667 16.666 6.66667C17.7706 6.66667 18.666 7.5621 18.666 8.66667Z", fill: color }), _jsx("path", { d: "M17.4994 17.7383C17.3663 18.1244 17.6453 18.5722 18.048 18.5046C20.3223 18.1226 21.3327 16.8295 21.3327 15.293C21.3327 13.4561 19.1989 11.9669 16.5667 11.9669C16.1798 11.9669 15.8037 11.9991 15.4435 12.0598C15.3431 12.0768 15.3206 12.2091 15.4063 12.2642C16.6567 13.0669 17.7582 14.3884 17.7582 16.153C17.7582 16.7103 17.6703 17.2426 17.4994 17.7383Z", fill: color }), _jsx("path", { d: "M9.4539 20.303C14.1848 20.303 16.2418 18.4434 16.2418 16.1494C16.2418 13.8554 13.2027 11.9958 9.4539 11.9958C5.70505 11.9958 2.66602 13.8554 2.66602 16.1494C2.66602 18.4434 4.72295 20.303 9.4539 20.303Z", fill: color })] })));
};

import { Button, List, ListItem } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { useFormState } from 'react-final-form'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import useTranslateParse from 'util/useTranslateParse'
import ContactCardWrapper from '../components/ContactCardWrapper'
import { FormRouteInfoPropTypes } from '../types'
import { contactIsNotEmpty } from '../utils'

const useStyles = makeOpenSolarStyles((theme) => ({
  header: { fontSize: 20, fontWeight: 500, marginTop: 0, marginBottom: 0 },
  description: {
    lineHeight: 1.5,
    marginBottom: 0,
  },
  list: {
    padding: 0,
  },
  item: {
    display: 'block',
    lineHeight: 1.5,
  },
  repsContainer: {
    padding: '1rem',
    margin: '1rem 0',
    background: theme.greyLight3,
    textAlign: 'center',
  },
  addBtnContainer: {
    textAlign: 'center',
  },
}))

const ControlDescriptionTop: React.FC<FormRouteInfoPropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const translateParse = useTranslateParse()

  const formVals = useFormState().values
  const companyRep = formVals?.companyRep
  const owner1 = formVals.ownershipInfoArr?.[0]
  const owner2 = formVals.ownershipInfoArr?.[1]
  const owner3 = formVals.ownershipInfoArr?.[2]
  const owner4 = formVals.ownershipInfoArr?.[3]

  const contactIsFilled = useMemo(() => {
    return (
      (owner1 && contactIsNotEmpty(owner1)) ||
      (owner2 && contactIsNotEmpty(owner2)) ||
      (owner3 && contactIsNotEmpty(owner3)) ||
      (owner4 && contactIsNotEmpty(owner4)) ||
      (companyRep && contactIsNotEmpty(companyRep))
    )
  }, [companyRep, owner1, owner2, owner3, owner4])

  const allContactsFilled = useMemo(() => {
    return (
      owner1 &&
      contactIsNotEmpty(owner1) &&
      owner2 &&
      contactIsNotEmpty(owner2) &&
      owner3 &&
      contactIsNotEmpty(owner3) &&
      owner4 &&
      contactIsNotEmpty(owner4) &&
      companyRep &&
      contactIsNotEmpty(companyRep)
    )
  }, [companyRep, owner1, owner2, owner3, owner4])

  const onAddContact = () => {
    props.navigateToPage('personInformation')
    props.setFieldPrefixFilter(null)
    logAmplitudeEvent('psp_onboarding_add_contact_clicked', {})
  }

  return (
    <div>
      <h1 className={classes.header}>{translate('Who controls your business?')}</h1>
      <p className={classes.description}>
        {translate('To protect you and our community, we need to verify the information of two types of key contacts:')}
      </p>
      <List className={classes.list}>
        <ListItem className={classes.item}>
          {translateParse(
            '<strong>Owner(s)<strong> - All individuals who own 25% or more of your business must be added. If the Owner(s) also serve as the Company Representative, you do not need to enter a Company Representative.',
            {
              strong: (label: string) => <strong>{label}</strong>,
            }
          )}
        </ListItem>
        <ListItem className={classes.item}>
          {translateParse(
            '<strong>Company Representative<strong> - The person who oversees the business and has significant control over the entity (e.g. a CEO, COO, CFO, or Director).',
            {
              strong: (label: string) => <strong>{label}</strong>,
            }
          )}
        </ListItem>
      </List>
      <p className={classes.description}>
        {translateParse(
          'All Information provided in this section must be the personal information of the representative, not for the business. Up to four contacts can be entered. Owner Info and Bank Info <strong>must be completed in a single session<strong> - please ensure you have all of the information available.',
          {
            strong: (label: string) => <strong>{label}</strong>,
          }
        )}
      </p>

      {!contactIsFilled ? (
        <div className={classes.repsContainer}>
          <h2>{translate("You haven't added any contacts yet")}</h2>
          <Button variant="outlined" color="primary" startIcon={<AddIcon />} onClick={onAddContact}>
            {translate('Add Contact')}
          </Button>
        </div>
      ) : (
        <>
          <div className={classes.repsContainer}>
            <ContactCardWrapper
              ownersData={formVals.ownershipInfoArr}
              companyRepData={companyRep}
              setFieldPrefixFilter={props.setFieldPrefixFilter}
              navigateToPage={props.navigateToPage}
            />
          </div>
          {!allContactsFilled && (
            <div className={classes.addBtnContainer}>
              <Button variant="outlined" color="primary" startIcon={<AddIcon />} onClick={onAddContact}>
                {translate('Add Contact')}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default ControlDescriptionTop

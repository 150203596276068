import { createContext, memo, useMemo, useState } from 'react'
import type { AccountDetailType, SegenAddressType, SegenCountryDetailType, SegenQuoteResult } from 'services/segen/type'
import useSegenService from 'services/segen/useSegenServiceV2'
import SegenCheckoutPresenter from './SegenCheckoutPresenter'

export type QuoteDetail = {
  loading: boolean
  quote?: SegenQuoteResult
}

export type AccountDetail = {
  loading: boolean
  account?: AccountDetailType
}

export type OrderDetail = {
  submitting: boolean
  isReady: boolean
  order?: unknown
}

export type CheckoutForm = {
  initialing: boolean
  segenOptions: {
    countryIds: SegenCountryDetailType[]
    deliveryMethodOptions: string[]
  }
  address?: SegenAddressType
  hasLiftingEquipment?: boolean
  deliveryMethodPreference: string
}

const defaultQuoteDetailState = { loading: false, quote: undefined }
const defaultAccountDetailState = { loading: false, account: undefined }
const defaultOrderDetailState = {
  submitting: false,
  isReady: false,
}
const defaultCheckoutForm = {
  initialing: true,
  deliveryMethodPreference: 'Cheapest',
  hasLiftingEquipment: true,
  segenOptions: {
    countryIds: [],
    deliveryMethodOptions: [],
  },
}

export const QuoteContext = createContext<QuoteDetail>(defaultQuoteDetailState)

export const OrderContext = createContext<OrderDetail>(defaultOrderDetailState)

export const AccountContext = createContext<AccountDetail>(defaultAccountDetailState)

export const CheckoutFormContext = createContext<CheckoutForm>(defaultCheckoutForm)

export const CheckoutPresenterContext = createContext<SegenCheckoutPresenter | undefined>(undefined)

export const ShowAddressCompletionFormContext = createContext<boolean>(false)

const SegenCheckoutProvider = ({ children }) => {
  const [quoteDetail, setQuoteDetail] = useState<QuoteDetail>(defaultQuoteDetailState)
  const [accountDetail, setAccountDetail] = useState<AccountDetail>(defaultAccountDetailState)
  const [orderDetail, setOrderDetail] = useState<OrderDetail>(defaultOrderDetailState)
  const [checkoutForm, setCheckoutForm] = useState<CheckoutForm>(defaultCheckoutForm)
  const [showAddressCompletionForm, setShowAddressCompletionForm] = useState<boolean>(false)

  const segenService = useSegenService()
  const segenCheckoutPresenter = useMemo(
    () =>
      new SegenCheckoutPresenter(
        segenService,
        setQuoteDetail,
        setAccountDetail,
        setOrderDetail,
        setCheckoutForm,
        setShowAddressCompletionForm
      ),
    []
  )
  return (
    <QuoteContext.Provider value={quoteDetail}>
      <AccountContext.Provider value={accountDetail}>
        <OrderContext.Provider value={orderDetail}>
          <CheckoutFormContext.Provider value={checkoutForm}>
            <CheckoutPresenterContext.Provider value={segenCheckoutPresenter}>
              <ShowAddressCompletionFormContext.Provider value={showAddressCompletionForm}>
                {children}
              </ShowAddressCompletionFormContext.Provider>
            </CheckoutPresenterContext.Provider>
          </CheckoutFormContext.Provider>
        </OrderContext.Provider>
      </AccountContext.Provider>
    </QuoteContext.Provider>
  )
}

export default memo(SegenCheckoutProvider)

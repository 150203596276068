// @ts-nocheck
import Alert from 'elements/Alert'
import React from 'react'
import { useTranslate } from 'react-admin'

const Icon = () => (
  <svg
    t="1626843995487"
    class="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="10932"
    width="14"
    height="14"
    style={{ verticalAlign: 'middle' }}
  >
    <path d="M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z" p-id="10933" fill="#dc0000"></path>
    <path
      d="M512 744.727273m-46.545455 0a46.545455 46.545455 0 1 0 93.09091 0 46.545455 46.545455 0 1 0-93.09091 0Z"
      p-id="10934"
      fill="#dc0000"
    ></path>
  </svg>
)

export default ({ system }: { system: StudioSystemType }) => {
  const translate = useTranslate()
  return system && system.hasIncompleteStringing() ? (
    <Alert severity="warning">
      {translate('Unstrung panels shown by')} <Icon />
    </Alert>
  ) : null
}

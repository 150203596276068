var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var ShopCartIcon = function (props) {
    var _a = props.size, size = _a === void 0 ? 20 : _a, _b = props.color, color = _b === void 0 ? 'currentColor' : _b;
    var viewBox = "0 0 ".concat(size, " ").concat(size);
    return (_jsxs("svg", __assign({ width: size, height: size, viewBox: viewBox, fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("g", __assign({ "clip-path": "url(#clip0_5453_1294)" }, { children: _jsx("path", { d: "M9.16683 7.50016H10.8335V5.00016H13.3335V3.3335H10.8335V0.833496H9.16683V3.3335H6.66683V5.00016H9.16683V7.50016ZM5.8335 15.0002C4.91683 15.0002 4.17516 15.7502 4.17516 16.6668C4.17516 17.5835 4.91683 18.3335 5.8335 18.3335C6.75016 18.3335 7.50016 17.5835 7.50016 16.6668C7.50016 15.7502 6.75016 15.0002 5.8335 15.0002ZM14.1668 15.0002C13.2502 15.0002 12.5085 15.7502 12.5085 16.6668C12.5085 17.5835 13.2502 18.3335 14.1668 18.3335C15.0835 18.3335 15.8335 17.5835 15.8335 16.6668C15.8335 15.7502 15.0835 15.0002 14.1668 15.0002ZM5.97516 12.2918L6.00016 12.1918L6.75016 10.8335H12.9585C13.5835 10.8335 14.1335 10.4918 14.4168 9.97516L17.6335 4.1335L16.1835 3.3335H16.1752L15.2585 5.00016L12.9585 9.16683H7.1085L7.00016 8.94183L5.1335 5.00016L4.34183 3.3335L3.5585 1.66683H0.833496V3.3335H2.50016L5.50016 9.6585L4.37516 11.7002C4.24183 11.9335 4.16683 12.2085 4.16683 12.5002C4.16683 13.4168 4.91683 14.1668 5.8335 14.1668H15.8335V12.5002H6.1835C6.07516 12.5002 5.97516 12.4085 5.97516 12.2918Z", fill: "white" }) })), _jsx("defs", { children: _jsx("clipPath", __assign({ id: "clip0_5453_1294" }, { children: _jsx("rect", { width: "20", height: "20", fill: "white" }) })) })] })));
};

import React from 'react'
import type { FilterComponentNode, FilterComponentRendererType, FilterGenericPropsType } from '../type'
import OwnerOrgFilter, { OwnerOrgFilterOptionsType } from './OwnerOrgFilter'

export const OWNER_ORG_FILTER_KEY = 'owner_org'

const createOwnerOrgFilterNode = ({
  RendererComponent,
}: {
  RendererComponent: FilterComponentRendererType<OwnerOrgFilterOptionsType>
}): FilterComponentNode | undefined => {
  const FilterComponent = (props: FilterGenericPropsType) => {
    return <OwnerOrgFilter filterKey={OWNER_ORG_FILTER_KEY} RendererComponent={RendererComponent} {...props} />
  }
  return {
    key: OWNER_ORG_FILTER_KEY,
    component: FilterComponent,
  }
}

export default createOwnerOrgFilterNode

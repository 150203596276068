var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import createStandardIcon from './createStandardIcon';
var BarChartIconSvg = function (props) {
    var fill = props.fill, height = props.height, width = props.width;
    return (_jsxs("svg", __assign({ viewBox: "0 0 ".concat(width, " ").concat(height), fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("g", __assign({ id: "bar_chart", clipPath: "url(#clip0_2314_5132)" }, { children: _jsx("path", { id: "Vector", d: "M6.6665 12.2667H10.6665V25.3334H6.6665V12.2667ZM14.1332 6.66675H17.8665V25.3334H14.1332V6.66675ZM21.5998 17.3334H25.3332V25.3334H21.5998V17.3334Z", fill: "#757575" }) })), _jsx("defs", { children: _jsx("clipPath", __assign({ id: "clip0_2314_5132" }, { children: _jsx("rect", { width: width, height: height, fill: fill }) })) })] })));
};
export var BarChartIcon = createStandardIcon(BarChartIconSvg);

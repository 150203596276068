import { TextField, makeStyles } from '@material-ui/core'
import type { OpenSolarThemeType } from 'Themes'
import React, { memo, useEffect, useState } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& >:first-child': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    '& >:last-child': {
      borderBottomRightRadius: 4,
      borderTopRightRadius: 4,
    },
  },
  leftItem: {
    width: 75,
    minWidth: 75,
  },
  rightItem: {
    overflow: 'hidden',
  },
}))

const useQuantityInputStyles = makeStyles<OpenSolarThemeType>((theme: OpenSolarThemeType) => ({
  input: {
    borderRadius: 'inherit',
    fontSize: theme.typography.subtitle2.fontSize,
  },
  root: {
    borderRadius: 'inherit',
  },
}))

type QuantityInputProps = {
  quantity: number
  disabled: boolean
  onEditQuantity?(arg: boolean): void
  onUpdateQuantity(quantity: number): void
}

const QuantityInput = memo(({ quantity, disabled, onEditQuantity, onUpdateQuantity }: QuantityInputProps) => {
  const classes = useQuantityInputStyles()
  const [internalValue, setInternalValue] = useState<number>(quantity)
  const [editing, setEditing] = useState<boolean>(false)

  useEffect(() => {
    if (!editing && internalValue !== quantity) {
      setInternalValue(quantity)
    }
  }, [quantity])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let quantity = event.target.value
    setEditing(true)
    onEditQuantity?.(true)
  
    const parsedQuantity = typeof quantity === 'string' ? parseInt(quantity) : quantity
    const finalQuantity = isNaN(parsedQuantity) || parsedQuantity < 0 ? 0 : parsedQuantity
  
    setInternalValue(finalQuantity)
  }
  

  const handleBlur = () => {
    setEditing(false)
    onUpdateQuantity(internalValue)
  }

  return (
    <TextField
      onBlur={handleBlur}
      InputProps={{
        classes: { root: classes.input },
      }}
      classes={{ root: classes.root }}
      value={internalValue}
      onChange={handleChange}
      variant="outlined"
      type="number"
      size="small"
      disabled={disabled}
    />
  )
})

const QuantityField = ({
  quantity,
  Selector,
  Message,
  QuantityErrorMessage,
  onEditQuantity,
  onUpdateQuantity,
}: {
  quantity: number
  Selector?: React.ReactNode
  Message?: React.ReactNode
  QuantityErrorMessage?: React.ReactNode
  onEditQuantity?(arg: boolean): void
  onUpdateQuantity(quantity: number): void
}) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.row}>
        <div className={classes.leftItem}>
          <QuantityInput
            quantity={quantity}
            disabled={false}
            onUpdateQuantity={onUpdateQuantity}
            onEditQuantity={onEditQuantity}
          />
        </div>
        {Selector && <div className={classes.rightItem}>{Selector}</div>}
        {QuantityErrorMessage && <div>{QuantityErrorMessage}</div>}
      </div>
      <div className={classes.row}>
        <div className={classes.leftItem} />
        {Message && <div className={classes.rightItem}>{Message}</div>}
      </div>
    </>
  )
}

export default QuantityField

// @ts-nocheck
import { COMPONENT_ACTIVATION_END, COMPONENT_ACTIVATION_REQUEST, COMPONENT_ACTIVATION_START } from 'actions/designer'
import { showNotification } from 'react-admin'
import { call, cancel, fork, put, take } from 'redux-saga/effects'

export interface ComponentActivationSideEffect {
  payload: { codes: string[]; callback: Function }
}

export const activeComponentImmediately = (codes: string[], callback: Function) =>
  window.AccountHelper.activateComponents(codes, callback)

export function* handleActiveComponentRequest({ payload }: ComponentActivationSideEffect) {
  const { callback, codes } = payload
  try {
    yield put({ type: COMPONENT_ACTIVATION_START, payload: { codes } })
    //@ts-ignore
    yield call(activeComponentImmediately, payload.codes, payload.callback)
  } catch (e: any) {
    const detail = e.message || window.Designer.getErrorDetail(e, 'Unspecified Error.')
    yield put(showNotification('Error activating components: ' + detail, 'warning'))
  } finally {
    yield put({ type: COMPONENT_ACTIVATION_END, payload: { codes } })
    yield cancel()
  }
}

const takeFirst = (pattern: any, saga: any, ...args: any) =>
  fork(function* () {
    let firstTask
    while (true) {
      const action = yield take(pattern)
      if (!firstTask || firstTask.isCancelled()) {
        // starts the task
        firstTask = yield fork(saga, ...args.concat(action))
      } else {
        //skip new request
      }

      if (firstTask && firstTask.isCancelled()) {
        // clear reference
        firstTask = undefined
      }
    }
  })

export function* watchActiveComponentRequest() {
  //@ts-ignore
  yield takeFirst(COMPONENT_ACTIVATION_REQUEST, handleActiveComponentRequest)
}

import { makeStyles } from '@material-ui/core'
import { Theme } from 'types/themes'
import { COLOR_PALETTE } from '../styles'

type Props = {
  url: string
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${COLOR_PALETTE.grey6}`,
    borderRadius: '4px',
    background: COLOR_PALETTE.white,
  },
  logo: {
    height: '24px',
    width: '41px',
  },
}))

const DistributorLogo = ({ url }: Props) => {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      <img src={url} alt="logo" className={classes.logo} />
    </div>
  )
}

export default DistributorLogo

import { DeleteOutlined } from '@material-ui/icons'
import { DatePicker } from '@material-ui/pickers'
import { useTranslate } from 'ra-core'
import { Button } from 'ra-ui-materialui'
import React, { FC, useState } from 'react'
import { monthNames } from 'util/misc'
import { dateRangeStyles } from './styles'
import { DateRange } from './types'
import {
  DEFAULT_YEAR,
  dateDiffInDays,
  dayOfYear,
  firstDayOfCurrentYear,
  isStartDateAfterEndDate,
  lastDayOfCurrentYear,
} from './utils'

type PropsType = {
  source?: string
  unavailableRanges?: any[]
  dateRanges?: DateRange[]
  onChange: Function
}

const getStyleValue = (value) => {
  const computedWidth = (value / 364) * 100
  return computedWidth > 100 ? '100%' : `${computedWidth}%`
}

const DateRangeSelector: FC<PropsType> = ({ source, unavailableRanges, dateRanges = [], onChange }) => {
  const classes = dateRangeStyles()
  const translate = useTranslate()
  const [hoveringRange, setHoveringRange] = useState<number | undefined>()
  const addDateRange = () => {
    onChange([...dateRanges, { start_date: undefined, end_date: undefined }])
  }
  const handleDateChange = (value, index, field) => {
    let updatedRanges = [...dateRanges]
    let dateValue = value ? new Date(value) : new Date()
    dateValue.setFullYear(DEFAULT_YEAR)
    updatedRanges[index] = { ...updatedRanges[index], [field]: dateValue }
    onChange(updatedRanges)

    setTimeout(() => {
      if (field === 'start_date' && !updatedRanges[index].end_date) {
        // Focus end date
        //TODO: find a better way to do this
        const endEl = document.getElementById(`end-date-input-${index}`)
        if (endEl) endEl.click()
      }
    }, 0)
  }
  return (
    <div className={classes.dateRangeContainer}>
      <div className={classes.dateRangeBar}>
        {unavailableRanges &&
          unavailableRanges.map((schedule) => {
            return schedule.map((x) => {
              if (x.start_date && x.end_date) {
                const day = dayOfYear(x.start_date) - 1
                if (isStartDateAfterEndDate(x.start_date, x.end_date)) {
                  const rangeStart = dateDiffInDays(firstDayOfCurrentYear, x.end_date) + 1
                  const rangeEnd = dateDiffInDays(x.start_date, lastDayOfCurrentYear) + 1
                  return (
                    <>
                      <div
                        className={classes.dateRangeUnavailable}
                        style={{ width: getStyleValue(rangeStart), left: 0 }}
                      />
                      <div
                        className={classes.dateRangeUnavailable}
                        style={{ width: getStyleValue(rangeEnd), left: getStyleValue(day) }}
                      />
                    </>
                  )
                } else {
                  const range = dateDiffInDays(x.start_date, x.end_date) + 1
                  return (
                    <div
                      className={classes.dateRangeUnavailable}
                      style={{ width: getStyleValue(range), left: getStyleValue(day) }}
                    />
                  )
                }
              } else return ''
            })
          })}
        {dateRanges &&
          dateRanges.map((x, index) => {
            if (x.start_date && x.end_date) {
              const day = dayOfYear(x.start_date) - 1
              const hoverClass = hoveringRange !== undefined && hoveringRange !== index ? ' ' + classes.noHovered : ''
              if (isStartDateAfterEndDate(x.start_date, x.end_date)) {
                const rangeStart = dateDiffInDays(firstDayOfCurrentYear, x.end_date) + 1
                const rangeEnd = dateDiffInDays(x.start_date, lastDayOfCurrentYear) + 1
                return (
                  <>
                    <div
                      onMouseOver={() => setHoveringRange(index)}
                      onMouseOut={() => setHoveringRange(undefined)}
                      className={classes.dateRangeAssignedWrapper}
                      style={{ width: getStyleValue(rangeStart), left: 0, borderLeft: 'none' }}
                    >
                      <div className={classes.dateRangeAssigned} />
                      <div className={classes.pointLabel + hoverClass} style={{ right: -25 }}>
                        <p>{`${x.end_date.getDate()} ${translate(monthNames[x.end_date.getMonth()])}`}</p>
                      </div>
                    </div>
                    <div
                      className={classes.dateRangeAssignedWrapper}
                      style={{
                        width: getStyleValue(rangeEnd),
                        left: getStyleValue(day),
                        borderRight: 'none',
                      }}
                    >
                      <div className={classes.dateRangeAssigned} />
                      <div className={classes.pointLabel + hoverClass} style={{ left: -25 }}>
                        <p>{`${x.start_date.getDate()} ${translate(monthNames[x.start_date.getMonth()])}`}</p>
                      </div>
                    </div>
                  </>
                )
              } else {
                const range = dateDiffInDays(x.start_date, x.end_date) + 1
                return (
                  <div
                    onMouseOver={() => setHoveringRange(index)}
                    onMouseOut={() => setHoveringRange(undefined)}
                    className={classes.dateRangeAssignedWrapper}
                    style={{ width: getStyleValue(range), left: getStyleValue(day) }}
                  >
                    <div className={classes.dateRangeAssigned} />
                    <div className={classes.pointLabel + hoverClass} style={{ left: -25 }}>
                      <p>{`${x.start_date.getDate()} ${translate(monthNames[x.start_date.getMonth()])}`}</p>
                    </div>
                    <div className={classes.pointLabel + hoverClass} style={{ right: -25 }}>
                      <p>{`${x.end_date.getDate()} ${translate(monthNames[x.end_date.getMonth()])}`}</p>
                    </div>
                  </div>
                )
              }
            } else return ''
          })}
      </div>
      <div className={`${classes.dateRangeLabels} ${classes.row}`}>
        {monthNames.map((month, index) => {
          let style = { width: `100%` }
          return <p style={style}>{month}</p>
        })}
      </div>
      {dateRanges && (
        <div className={classes.dateRangeInputContainer}>
          {dateRanges.map((x, index) => {
            return (
              <div
                className={
                  `${classes.row} ${classes.dateRangeInput} ` +
                  (hoveringRange !== undefined && hoveringRange !== index ? classes.noHovered : '')
                }
                onMouseOver={() => setHoveringRange(index)}
                onMouseOut={() => setHoveringRange(undefined)}
              >
                <p>{translate('Date Range') + ` ${index + 1}`}</p>
                <DatePicker
                  id={`start-date-input-${index}`}
                  variant="inline"
                  emptyLabel="Select Date"
                  label={translate('Start Date')}
                  value={x.start_date ? x.start_date : null}
                  onChange={(value) => {
                    handleDateChange(value, index, 'start_date')
                  }}
                  minDate={firstDayOfCurrentYear}
                  maxDate={lastDayOfCurrentYear}
                  initialFocusedDate={x.start_date || firstDayOfCurrentYear}
                  autoOk={!x.end_date}
                />
                <DatePicker
                  id={`end-date-input-${index}`}
                  variant="inline"
                  emptyLabel="Select Date"
                  label={translate('End Date')}
                  value={x.end_date ? x.end_date : null}
                  onChange={(value) => {
                    handleDateChange(value, index, 'end_date')
                  }}
                  minDate={firstDayOfCurrentYear}
                  maxDate={lastDayOfCurrentYear}
                  initialFocusedDate={x.end_date || x.start_date || firstDayOfCurrentYear}
                />
                <Button
                  disabled={dateRanges.length === 1}
                  className={classes.clearBtn}
                  onClick={() => {
                    let updatedList = [...dateRanges]
                    updatedList.splice(index, 1)
                    onChange(updatedList)
                  }}
                >
                  <DeleteOutlined className={classes.removeMargin} />
                </Button>
              </div>
            )
          })}
        </div>
      )}
      <Button label={'Add Date Range'} variant="outlined" className={classes.addDateRange} onClick={addDateRange} />
    </div>
  )
}
export default DateRangeSelector

import { CircularProgress, Dialog, DialogContent, makeStyles, withTheme } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import Alert from 'elements/Alert'
import { Button } from 'opensolar-ui'
import { useCallback, useEffect, useState } from 'react'
import { useLogoutIfAccessDenied, useNotify, useTranslate, withTranslate } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import appStorage from 'storage/appStorage'
import { reloadEntireApp } from 'util/misc'
import { authReload as authReloadAction } from '../../actions/authActions'
import {
  setMaintenanceMode as setMaintenanceModeAction,
  setVersion as setVersionAction,
  setVersionMismatch as setVersionMismatchAction,
  setVersionMismatchDismissed as setVersionMismatchDismissedAction,
} from '../../ducks/access'

// const buttonStyle = { margin: '10px 10px 10px 0' }

const useStyles = makeStyles((theme) => ({
  circleProgressWrapper: {
    textAlign: 'center',
  },
  circleProgress: {
    opacity: 0.5,
    display: 'inline-block',
    float: 'none',
    width: 30,
    height: 30,
    marginLeft: 5,
    marginTop: 10,
    position: 'relative',
  },
  button: {
    background: theme.themeColor,
    color: theme.headerFontColor,
    margin: '0px 10px 10px 10px',
    '&:hover': {
      background: theme.themeColorDark,
    },
  },
  actionWrapper: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
  launchIcon: {
    width: 16,
    height: 16,
    verticalAlign: 'middle',
  },
  linkWrapper: {
    textAlign: 'center',
    padding: 10,
  },
  logoWrapper: {
    textAlign: 'center',
  },
  logo: {
    margin: 0,
    height: 60,
  },
}))

const MobileAppProposedMessage = () => (
  <Alert severity="info">
    <small>
      If the latest mobile App is not yet available please use the web App at{' '}
      <Link href={'https://app.opensolar.com'} target="_blank" rel="noreferrer" color="initial">
        https://app.opensolar.com
      </Link>
    </small>
  </Alert>
)
const AccessDialog = (props) => {
  const {
    mode,
    modal,
    auth,
    setVersion,
    restClientInstance,
    setVersionMismatch,
    setVersionMismatchDismissed,
    setMaintenanceMode,
    authReload,
  } = props
  const [loading, setLoading] = useState(false)
  const [versionLoaded, setVersionLoaded] = useState(null)
  const translate = useTranslate()
  const notify = useNotify()
  const logoutIfAccessDenied = useLogoutIfAccessDenied()
  const classes = useStyles()
  useEffect(() => {
    //@TODO: Use Redux to broadcast message instead of this hack
    window.setVersion = (newVersion) => {
      if (newVersion !== versionLoaded) {
        setVersion(newVersion)
        setVersionLoaded(newVersion)
      }
    }
    window.setVersionMismatch = setVersionMismatch
    window.setVersionMismatchDismissed = setVersionMismatchDismissed
    window.setMaintenanceMode = setMaintenanceMode

    var autoLoginInProgress = Boolean(
      window.location.href.indexOf('token=') !== -1 && window.location.href.indexOf('myenergy') !== -1
    )

    if (auth?.loadedFromLocalStorage === true) {
      const hasOssdkToken = (appStorage.getToken() || '').startsWith('ossdk_')
      if (hasOssdkToken && window.parent !== window) {
        console.log(
          'Skip AUTH_RELOAD, using ossdk token in iframe so we want to avoid making this call automatically - we will make it manually with loginWithToken() later'
        )
      } else if (!autoLoginInProgress) {
        if (auth?.orgs?.length > 0) {
          // assume this is a pro (or staff user)
          authReload()
        } else {
          // assume this is a customer since they cannot access any orgs, do not reload auth for customers
        }
      } else {
        console.log('Skip AUTH_RELOAD, auto-login is in progress based on URL')
      }
    }
  }, [versionLoaded, auth?.loadedFromLocalStorage])

  const dismissVersionUpdate = useCallback(() => {
    setVersionMismatchDismissed(true)
  }, [])

  const checkMaintenanceMode = () => {
    setLoading(true)

    restClientInstance('CUSTOM_GET', 'custom', {
      url: '',
    }).then(
      (response) => {
        setLoading(false)
        if (setMaintenanceMode) {
          setMaintenanceMode(false)
        } else {
          console.log('setMaintenanceMode not available. No update')
        }
      },
      (error) => {
        setLoading(false)
        if (error?.status === 503) {
          //backend stilling maintaining
          //keep the dialog open
        } else {
          //receive other error
          //turn off the dialog avoid creating infinite loop
          setMaintenanceMode(false)
          notify(error.message, 'error')
          logoutIfAccessDenied(error)
        }
      }
    )
  }
  return (
    <Dialog
      maxWidth="xs"
      open={Boolean(mode)}
      onClose={() => {
        if (modal !== true && mode && mode.indexOf('versionUpdate') !== -1) {
          dismissVersionUpdate()
        }
      }}
    >
      <DialogContent style={{ padding: '10px 24px' }}>
        <div className={classes.logoWrapper}>
          <img
            className={classes.logo}
            alt="OpenSolar Logo"
            src={window.PUBLIC_URL + '/images/logo_inline_336x96.png'}
          />
        </div>

        {mode === 'maintenanceMode' && !loading && (
          <div>
            <p>
              <b>{translate('Maintenance In Progress')}</b> - {translate('click button to retry')}.
            </p>
            <div className={classes.actionWrapper}>
              <Button classes={{ root: classes.button }} fullWidth={true} onClick={checkMaintenanceMode}>
                <span>{translate('Retry')}</span>
              </Button>
            </div>
          </div>
        )}
        {mode === 'versionUpdateMandatoryWeb' && !loading && (
          <div>
            <p>
              <b>{translate('Update now available')}</b> - {translate('refresh your browser for the latest version')}.
            </p>
            <div className={classes.actionWrapper}>
              <Button classes={{ root: classes.button }} fullWidth={true} onClick={() => reloadEntireApp()}>
                <span>{translate('Refresh')}</span>
              </Button>
            </div>
          </div>
        )}
        {mode === 'versionUpdateMandatoryIos' && !loading && (
          <div>
            <p>
              <b>{translate('Update now available')}</b> - {translate('download and install our latest IOS App')}.
            </p>
            <div className={classes.actionWrapper}>
              <Button
                fullWidth={true}
                classes={{ root: classes.button }}
                onClick={() => window.open('https://itunes.apple.com/SG/app/id1410085935?mt=8', '_blank')}
              >
                <span style={{ textTransform: 'none' }}>{translate('View in App Store')}</span>
              </Button>
            </div>
            <MobileAppProposedMessage />
          </div>
        )}
        {mode === 'versionUpdateMandatoryAndroid' && !loading && (
          <div>
            <p>
              <b>{translate('Update now available')}</b> - {translate('download and install our latest Android App')}.
            </p>
            <div className={classes.actionWrapper}>
              <Button
                fullWidth={true}
                classes={{ root: classes.button }}
                onClick={() => window.open('https://play.google.com/store/apps/details?id=com.opensolar.app', '_blank')}
              >
                <span style={{ textTransform: 'none' }}>{translate('View in Google Play Store')}</span>
              </Button>
            </div>

            <MobileAppProposedMessage />
          </div>
        )}
        {mode === 'versionUpdateOptionalWeb' && !loading && (
          <div>
            <p>
              <b>{translate('Update now available')}</b> - {translate('refresh your browser for the latest version')}.
            </p>
            <div className={classes.actionWrapper}>
              <Button classes={{ root: classes.button }} fullWidth={true} onClick={() => reloadEntireApp()}>
                <span>{translate('Refresh')}</span>
              </Button>
              <Button classes={{ root: classes.button }} fullWidth={true} onClick={dismissVersionUpdate}>
                <span>{translate('Not Now')}</span>
              </Button>
            </div>
          </div>
        )}
        {mode === 'versionUpdateOptionalIos' && !loading && (
          <div>
            <p>
              <b>{translate('Update now available')}</b> - {translate('download and install our latest IOS App')}.
            </p>
            <div className={classes.actionWrapper}>
              <Button
                classes={{ root: classes.button }}
                fullWidth={true}
                onClick={() => window.open('https://itunes.apple.com/SG/app/id1410085935?mt=8', '_blank')}
              >
                <span style={{ textTransform: 'none' }}>{translate('View in App Store')}</span>
              </Button>

              <Button classes={{ root: classes.button }} fullWidth={true} onClick={dismissVersionUpdate}>
                <span>{translate('Not Now')}</span>
              </Button>
            </div>
            <MobileAppProposedMessage />
          </div>
        )}
        {mode === 'versionUpdateOptionalAndroid' && !loading && (
          <div>
            <p>
              <b>{translate('Update now available')}</b> - {translate('download and install our latest Android App')}.
            </p>
            <div className={classes.actionWrapper}>
              <Button
                classes={{ root: classes.button }}
                fullWidth={true}
                onClick={() => window.open('https://play.google.com/store/apps/details?id=com.opensolar.app', '_blank')}
              >
                <span style={{ textTransform: 'none' }}>{translate('View in Google Play Store')}</span>
              </Button>
              <Button
                classes={{ root: classes.button }}
                style={{ width: 200 }}
                fullWidth={true}
                onClick={dismissVersionUpdate}
              >
                <span>{translate('Not Now')}</span>
              </Button>
            </div>
            <MobileAppProposedMessage />
          </div>
        )}
        {loading && (
          <div className={classes.circleProgressWrapper}>
            <CircularProgress classes={{ root: classes.circleProgress }} />
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

const mapStateToProps = (state) => {
  var mode = null
  var modal = false

  if (!state.access) {
    return { mode: mode, modal: modal, version: null }
  }

  //web, ios, android
  var device = window.RUNNING_AS_APP_DEVICE || 'web'

  if (state.access.maintenanceMode === true) {
    modal = true
    mode = 'maintenanceMode'
  } else if (state.access.versionMismatch === 'mandatory') {
    modal = true
    if (device === 'web') {
      mode = 'versionUpdateMandatoryWeb'
    } else if (device === 'ios') {
      mode = 'versionUpdateMandatoryIos'
    } else if (device === 'android') {
      mode = 'versionUpdateMandatoryAndroid'
    }
  } else if (state.access.versionMismatch === 'optional') {
    if (state.access.versionMismatchDismissed === true) {
      //Version update available but message dismissed, do not show dialog
      mode = null
    } else if (device === 'web') {
      mode = 'versionUpdateOptionalWeb'
    } else if (device === 'ios') {
      mode = 'versionUpdateOptionalIos'
    } else if (device === 'android') {
      mode = 'versionUpdateOptionalAndroid'
    }
  }

  return {
    mode: mode,
    modal: modal,
    auth: state.auth,
    version: state.access.version,
  }
}

const enhance = compose(
  withTheme,
  withTranslate,
  connect(mapStateToProps, {
    setVersion: setVersionAction,
    setVersionMismatch: setVersionMismatchAction,
    setVersionMismatchDismissed: setVersionMismatchDismissedAction,
    setMaintenanceMode: setMaintenanceModeAction,
    authReload: authReloadAction,
  })
)
export default enhance(AccessDialog)

import ChipsInput from 'elements/input/ChipsInput'
import React, { FC } from 'react'
import { ReferenceArrayInput } from 'react-admin'

type PropsType = {
  disabled: boolean
  className?: string
}

const AccessControl: FC<PropsType> = ({ disabled, className }) => {
  return (
    // @ts-ignore
    <ReferenceArrayInput
      name="access_projects_from_users"
      source="access_projects_from_users"
      resource="roles"
      label="Grant Access To Projects From These Users"
      reference="roles___aliases"
      optionValueField="url"
      filter={{ is_admin: false }}
      margin="dense"
      helperText="Enter a non-admin team member"
    >
      {/* @ts-ignore */}
      <ChipsInput
        optionText={'display'}
        optionValue="id"
        fullWidth={true}
        disabled={disabled}
        options={{ disabled }}
        translateChoice={false}
        useSmallChip={true}
      />
    </ReferenceArrayInput>
  )
}

export default AccessControl

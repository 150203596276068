import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { CustomNumberField } from 'elements/field/CustomNumberField'
import _ from 'lodash'
import { Typography, UserProfileGroupIcon } from 'opensolar-ui'
import { useOtherOrgIds, useProjectOwnerDetails } from 'projectSections/sections/info/orgSharing/util'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import { useEffect } from 'react'
import {
  DatagridBody,
  DatagridHeaderCell,
  FunctionField,
  PureDatagridRow,
  useListController,
  useNotify,
  useTranslate,
} from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { ADDER_TYPE_CHOICES, PRICE_ONLY_ADDER_FORMULA_CHOICES } from 'resources/adders/AddersForm'
import { AdderData } from 'types/global'
import {
  StyledDatagrid,
  StyledDatagridCell,
  StyledFilterComp,
  StyledPagination,
  StyledSearchInput,
  TitleField,
} from './SelectableAddersTableStyles'

const SelectableRow = (props) => {
  const { onToggleItem, lineItems = [], handleToggle, selected, ...rest } = props
  const handleToggleItem = () => handleToggle({ isRemove: selected, record: props.record })
  return <PureDatagridRow selected={selected} onToggleItem={handleToggleItem} {...rest} />
}

const AdderFilter = (props) => (
  <StyledFilterComp {...props}>
    <StyledSearchInput source="q" placeholder="Search" alwaysOn={true} options={{ variant: 'outlined' }} />
  </StyledFilterComp>
)

// quantity is not applied when formula is per panel or per watt
const qtyLocked = ({ formula }) => [1, 2].includes(formula)

// adding this check because the values in Pricing.js can be compiled as string or number
// and Number(null) or Number(false) return 0
const isNumeric = (value: any): boolean => {
  if (typeof value === 'boolean' || value === null || _.isSymbol(value) || _.isNaN(value) || Array.isArray(value)) {
    return false
  }
  const num = Number(value)
  return _.isNumber(num) && Number.isFinite(num)
}

const parseIntSafe = (v: unknown): number => {
  return isNumeric(v) ? Number(v) : 0
}

const SelectableAddersTable = () => {
  const form = useForm()
  const orgId = useSelector(authSelectors.getOrgId) as number
  const sharedWith = form.getState().values.shared_with
  const ownerOrg = useProjectOwnerDetails(useSelector(orgSelectors.getOrg), form.getState().values.org_id)
  const otherOrgIds = useOtherOrgIds(orgId, ownerOrg?.org_id, sharedWith)
  const filterIds = [...otherOrgIds, orgId]
  const system = window.editor.selectedSystem
  const translate = useTranslate()
  const lineItems = system?.line_items ? [...system.line_items] : []
  const notify = useNotify()

  const {
    onToggleItem,
    data,
    loading,
    loaded,
    filterValues,
    setFilters,
    selectedIds,
    ...listProps
    // @ts-ignore
  } = useListController({
    resource: 'adders',
    filter: { visible_to: orgId, owned_by: `${filterIds}` },
    perPage: 10,
    basePath: 'adders',
    hasSearch: true,
  })

  useEffect(() => {
    if (!system) {
      console.error('No system selected')
      return
    }
    if (system.autoSync) {
      system.autoSync.adders = false
    }
  }, [])

  const handleToggle = ({ isRemove, record }) => {
    if (!isNumeric(record.id)) {
      notify('Something went wrong"', 'warning')
      return
    }
    const lineItemMap = new Map(lineItems.map((item) => [item.adder_id, item]))
    const numId = parseIntSafe(record.id)
    if (isRemove && numId) {
      lineItemMap.delete(numId)
      const lineItemsAr = Array.from(lineItemMap.values())
      window.editor.execute(new window.SetValueCommand(system, 'line_items', lineItemsAr, undefined, true))
    } else {
      let libraryAdder = window.AccountHelper.getAdderById(record.id) as any
      if (!libraryAdder) {
        //add adder to library if it doesn't exist
        const formattedAdderData: AdderData = {
          ...record,
          adder_id: numId,
          label: record.title,
          quantity: 1,
        }
        libraryAdder = new Adder(formattedAdderData)
        window.AccountHelper.addAdderToLibrary(libraryAdder)
      }
      Adder.addToSystem(system, { ...libraryAdder, quantity: 1 })
    }
  }

  const handleUpdateQuantity = (record, quantity) => {
    if (!isNumeric(record.id)) {
      notify('Something went wrong"', 'warning')
      return
    }
    const lineItemMap = new Map(lineItems.map((item) => [item.adder_id, item]))
    const numId = parseIntSafe(record.id)
    const selectedItem = lineItemMap.get(numId) as AdderData
    lineItemMap.set(numId, { ...selectedItem, quantity })
    const lineItemsAr = Array.from(lineItemMap.values())
    window.editor.execute(new window.SetValueCommand(system, 'line_items', lineItemsAr, undefined, true))
  }

  return (
    <>
      <AdderFilter context="form" filterValues={filterValues} setFilters={setFilters} />
      <StyledDatagrid
        {...listProps}
        data={data}
        headerCell={<DatagridHeaderCell header={true} />}
        hasBulkActions={true}
        loaded={loaded}
        selectedIds={lineItems.map((i) => i.adder_id)}
        //@ts-ignore
        body={
          //@ts-ignore
          <DatagridBody
            row={
              <SelectableRow
                handleToggle={handleToggle}
                //@ts-ignore
                cell={<StyledDatagridCell />}
              />
            }
          />
        }
      >
        <FunctionField
          source="title"
          label="Title"
          render={(record) => (
            <TitleField>
              {record.title} {record.org_id !== orgId && <UserProfileGroupIcon color={COLOR_PALETTE.neutralGrey700} />}
            </TitleField>
          )}
        />
        <FunctionField
          label="Type"
          source="type"
          render={(record) => ADDER_TYPE_CHOICES.find((a) => a.id === record['type'])?.name}
        />
        <FunctionField
          label="Qty"
          source="id"
          render={(record) => {
            const itemInSystem = lineItems.find(
              (i) =>
                isNumeric(record?.id) && isNumeric(i?.adder_id) && parseIntSafe(record.id) === parseIntSafe(i.adder_id)
            ) || {
              quantity: 0,
            }
            const quantity = parseIntSafe(itemInSystem?.quantity)

            return (
              <>
                <CustomNumberField
                  value={quantity}
                  onChange={(v) => handleUpdateQuantity(record, v)}
                  name={record.id + '-qty--field'}
                  minValue={quantity > 0 ? 1 : 0}
                  maxValue={qtyLocked(record) ? 1 : Number.POSITIVE_INFINITY}
                  variant="outlined"
                  minErrorMsg={translate('Minimum is %{min}', { min: 1 })}
                />
                {qtyLocked(record) && (
                  <Typography textVariant={'caption2'}>
                    {PRICE_ONLY_ADDER_FORMULA_CHOICES.find((a) => a.id === record.formula)?.name}
                  </Typography>
                )}
              </>
            )
          }}
        />
      </StyledDatagrid>
      <StyledPagination {...listProps} />
    </>
  )
}

export default SelectableAddersTable

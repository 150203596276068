import { Link } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import GetAppIcon from '@material-ui/icons/GetAppOutlined'
import WithPermissionsCheck from 'elements/WithPermissionsCheck'
import DeleteButton from 'elements/button/DeleteButton'
import { Chip, IconButton } from 'opensolar-ui'
import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { PrivateFileType } from 'types/privateFile'
import { formatTimeStringBaseOnLocale } from 'util/misc'

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 12,
  },
  chipsView: {
    marginTop: '5px',
    maxWidth: '100%',
  },
  iconWrapper: {
    '& .MuiIconButton-root': {
      padding: 6,
    },
    '& .MuiSvgIcon-root': {
      width: 20,
      height: 20,
      margin: 0,
    },
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flex: 2,
    maxWidth: '85%',
  },
  titleText: {
    fontSize: '17px',
    fontWeight: 400,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
  },
  actionWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  dateText: {
    fontSize: '13px',
    fontWeight: 300,
  },
}))

type PropTypes = {
  file: PrivateFileType
  recordUrl: String
}

const FileRowLite: React.FunctionComponent<PropTypes> = (props) => {
  const translate = useTranslate()
  const classes = useStyles()
  const form = useForm()
  const [deleted, setDeleted] = useState<boolean>(false)

  if (deleted) return null

  const updateFormOnDelete = () => {
    const newFiles = form
      .getState()
      .values.private_files_data?.filter((file: PrivateFileType) => file.id !== props.file.id)
    form.getState().initialValues.private_files_data = newFiles
    form.change('private_files_data', newFiles)
    setDeleted(true)
  }

  return (
    <Grid item xs={12}>
      <div className={classes.row}>
        <div className={classes.textWrapper}>
          <span className={classes.titleText}>{props.file.title}</span>
          <span className={classes.dateText}>
            {translate('Created At')}: {formatTimeStringBaseOnLocale(props.file.created_date)}
          </span>
          <div className={classes.chipsView}>
            {props.file.file_tags_data.map((file_tag, i) => (
              <Chip
                key={i}
                style={{
                  float: 'left',
                  marginRight: 5,
                  marginBottom: 5,
                  maxWidth: '100%',
                }}
                size="small"
                label={file_tag.title}
              />
            ))}
          </div>
        </div>
        <div className={classes.actionWrapper}>
          <div className={classes.iconWrapper}>
            <Link href={props.file.file_contents} target="_blank" rel="noopener noreferrer">
              <IconButton>
                <GetAppIcon />
              </IconButton>
            </Link>
          </div>
          <WithPermissionsCheck permissionToCheck="info_documents" permissions={['allowDelete']}>
            <div className={classes.iconWrapper}>
              <DeleteButton
                size="small"
                record={props.file}
                startIcon={<DeleteIcon htmlColor="rgba(0,0,0,0.87)" />}
                resource={'private_files'}
                hideLabel={true}
                fullWidth={true}
                onSuccess={updateFormOnDelete}
                style={{ padding: '6px' }}
                title={''}
                label={''}
                confirmMode={'tooltip'}
              />
            </div>
          </WithPermissionsCheck>
        </div>
      </div>
    </Grid>
  )
}
export default FileRowLite

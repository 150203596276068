import { makeStyles } from '@material-ui/core/styles'
import { CloseRounded } from '@material-ui/icons'
import { permissionsSelectors } from 'ducks/permissions'
import UserIcon from 'elements/UserIcon'
import { CloseOutlineIcon, IconButton, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { RoleType } from 'types/roles'
import { Theme } from 'types/themes'
import CustomDropdownInput from '../../../common/CustomDropdownInput'
import { COLOR_PALETTE, getProjectSectionStyles } from '../../../styles'

const useStyles = makeStyles((theme: Theme) => ({
  userRow: {
    marginTop: 10,
    marginBottom: 20,
    flexWrap: 'wrap',
    rowGap: 10,
    '& p': {
      margin: 0,
      marginLeft: 10,
      marginRight: 'auto',
      '& span': {
        fontSize: 12,
        color: COLOR_PALETTE.darkGrey,
      },
    },
  },
  actionsRow: {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
  },
  actionLabel: {
    color: COLOR_PALETTE.darkRed,
    '& .MuiSvgIcon-root': {
      height: 20,
      marginRight: 5,
    },
  },
  roleInput: {
    minWidth: '220px',
  },
}))
interface PropTypes {
  member: RoleType
  role: string
  source: string
  roleChoices: { title: string; id: string }[]
}

const StyledIconBtn = styled(IconButton)({
  marginLeft: 'auto',
})

const ProjectTeamMember: React.FC<PropTypes> = ({ member, role, roleChoices, source }) => {
  const form = useForm()
  const classes = useStyles()
  const translate = useTranslate()
  const formValues = useFormState().values
  const sectionStyles = getProjectSectionStyles()
  const { allowEdit, allowDelete } = useSelector(
    permissionsSelectors.getProjectPermissionByKey('manage_assigned_users')
  )

  const handleRemove = (role) => {
    form.change('internalTeam', [...formValues.internalTeam.filter((x) => x.projectRoleId !== role)])
  }
  const handleRoleChange = (newRole) => {
    if (newRole) form.change(`${source}.projectRoleId`, newRole)
  }
  const getActions = () => {
    if (!allowDelete) return []

    return [
      {
        label: (
          <div className={`${classes.actionLabel} ${sectionStyles.row}`}>
            <CloseRounded />
            {translate('Remove Team Member')}
          </div>
        ),
        onClick: (e) => {
          handleRemove(role)
        },
      },
    ]
  }

  return (
    <div className={`${sectionStyles.row} ${classes.userRow}`}>
      <UserIcon user={member} />
      <p>
        {member.first_name} {member.family_name} {(member.first_name || member.family_name) && <br />}{' '}
        <span>{member.email || member.user_email}</span>
      </p>
      <div className={classes.actionsRow}>
        <CustomDropdownInput
          source={`${source}.projectRoleId`}
          onChange={handleRoleChange}
          className={classes.roleInput}
          variant="outlined"
          choices={roleChoices}
          actions={[
            {
              label: (
                <div className={`${classes.actionLabel} ${sectionStyles.row}`}>
                  <CloseRounded />
                  {translate('Remove Team Member')}
                </div>
              ),
              onClick: (e) => {
                handleRemove(role)
              },
            },
          ]}
        />
        <StyledIconBtn
          onClick={() => {
            handleRemove(role)
          }}
        >
          <CloseOutlineIcon size={12} color={COLOR_PALETTE.red2} />
        </StyledIconBtn>
      </div>
    </div>
  )
}

export default ProjectTeamMember

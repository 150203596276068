import { ConditionFactory, SystemContext } from 'Designer/designRules/types'
import { BaseConditionDef } from '.'
import { CheckOperator, checkScope, ValidCheckOp } from '../utils'

export type CheckContextValueDef = BaseConditionDef & {
  type: 'check'
  filter: Record<string, any>
}
/**
 * This is a generic condition, designed to be able to check any part of the context.
 * It takes a filter object, where the key is the path to the value in the context, and the value is the expected value.
 * Each key can also contain an operator, separated by '__'.
 * valid operators are: 'eq', 'neq', 'gt', 'lt', 'gte', 'lte', 'in', 'nin'.
 * Multiple filters are combined with AND.
 *
 * Example:
 * ```
 * {
 *    "type": "check",
 *    "filter": {
 *        "project.is_lite__neq": true,
 *        "role.is_admin": true
 *    }
 * }
 * ```
 */
export const condition_checkContext: ConditionFactory<CheckContextValueDef> = (def) => {
  return {
    getProjectFields: () => [],
    getStudioSignals: () => [],
    check: (context: SystemContext) => {
      for (var i in def.filter) {
        const parts = i.split('__')
        if (parts.length !== 1 && parts.length !== 2) {
          if (window.debugDesignRules) console.debug(`\tDesignRules: Invalid filter key: `, i)
          return false
        }
        const [prop, op] = parts
        if (op && !ValidCheckOp(op)) {
          if (window.debugDesignRules) console.debug(`\tDesignRules: Invalid operator: `, op)
          return false
        }
        if (!checkScope(context, { prop, op: op as CheckOperator, value: def.filter[i] })) return false
      }
      return true
    },
  }
}

import { authSelectors } from 'ducks/auth'
import { List } from 'elements/react-admin/List'
import { styled } from 'opensolar-ui'
import { getProjectSectionStyles } from 'projectSections/sections/manage3/styles'
import { useMemo, useState } from 'react'
import { FunctionField, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import appStorage from 'storage/appStorage'
import Pagination from '../projects3/elements/Pagination'
import ProjectActionsButton from './elements/ProjectActionsButton'
import ProjectDatagrid from './elements/ProjectDatagrid'
import ProjectsHeader from './ProjectsHeader'
import SearchToolbar from './SearchToolbar'
import { ListColumnBase, PROJECTS_LIST_COLUMNS_DEFAULT } from './views/utils'
import ViewToolbar from './ViewToolbar'

const ProjectList = styled(List)({
  padding: 0,
  width: '100%',
  '& .Ra-List-Main': {
    overflowX: 'auto',
  },
})

const HeaderWrapper = styled('div')({
  width: '100%',
})

const ProjectsTable = (props) => {
  const translate = useTranslate()
  const orgId = useSelector(authSelectors.getOrgId)
  const [isTableView, setTableView] = useState(true)
  const defaultCols = useMemo(() => PROJECTS_LIST_COLUMNS_DEFAULT(translate), [])
  const [savedColData, setColData] = useState<undefined | ListColumnBase[]>(appStorage.getJSON('projects_columns'))
  const [selectedView, setSelectedView] = useState(0)
  const columns = useMemo(() => {
    if (savedColData)
      return savedColData.map((x) => {
        const findColData = defaultCols.find((colData) => colData.id === x.id)
        return { ...(findColData || {}), enabled: x.enabled }
      })
    return defaultCols
  }, [savedColData])
  const displayCols = useMemo(() => columns.filter((x) => x.enabled), [columns])

  const handleViewChange = (newView) => {
    setSelectedView(newView)
  }

  return (
    <ProjectList
      id={orgId}
      perPage={10}
      hasCreate={false}
      resource={'projects'}
      basePath={'projects'}
      title={
        <ProjectHeader
          handleViewChange={handleViewChange}
          viewSettings={{ selectedView, isTableView, setTableView, columns, setColData }}
        />
      }
      breadCrumbs={<></>}
      hasSearch={false}
      hasBulkActions={false}
      //@ts-ignore
      pagination={<Pagination />}
      {...props}
    >
      {isTableView && displayCols ? (
        <ProjectDatagrid>
          {displayCols.map((column: any) => (
            <FunctionField key={'col-' + column.id} label={column.display} {...column.props} />
          ))}
          <FunctionField source="" textAlign={'right'} render={(record) => <ProjectActionsButton project={record} />} />
        </ProjectDatagrid>
      ) : (
        <>kanban</>
      )}
    </ProjectList>
  )
}

const ProjectHeader = (props) => {
  const classes = getProjectSectionStyles()
  return (
    <HeaderWrapper className={classes.column}>
      <ProjectsHeader {...props} />
      <ViewToolbar {...props} />
      <SearchToolbar {...props} />
    </HeaderWrapper>
  )
}

export default ProjectsTable

import { useCallback } from 'react'
import { ExternalDataType } from 'types/components'
import { OtherComponentTypes } from 'types/otherComponent'
import { isMountingComponent } from 'util/misc'
import { useLyraPermitPackEnabled } from './useLyraPermitPackEnabled'

export enum ComponentLyraStatus {
  NOT_MAPPED,
  MAPPED_NOT_SUPPORTED,
  MAPPED_AND_SUPPORTED,
  MAPPING_UNREQUIRED, // Components which don't get sent to Lyra
}

// TODO: use real type once they're untangled
export type LyraComponentType = {
  code: string
  external_data: ExternalDataType | null
  other_component_type?: OtherComponentTypes
}

export const useLyraComponentStatus = () => {
  const lyraEnabled = useLyraPermitPackEnabled()
  return useCallback(
    (component?: LyraComponentType) => {
      if (!lyraEnabled || !component) return undefined

      const lyraData = component?.external_data?.lyra
      //is lyra mapped
      if (!lyraData || lyraData.uuid === null) {
        if (
          !!component.other_component_type &&
          component.other_component_type !== 'dc_optimizer' &&
          !isMountingComponent(component.other_component_type)
        )
          return ComponentLyraStatus.MAPPING_UNREQUIRED
        else return ComponentLyraStatus.NOT_MAPPED
      } else
        return lyraData?.support_status === true ||
          //@ts-ignore - This is due to some bad data in the backend, can be removed once the data is cleaned up (see https://github.com/open-solar/opensolar-todo/issues/14239)
          lyraData?.support_status === 'true'
          ? ComponentLyraStatus.MAPPED_AND_SUPPORTED
          : ComponentLyraStatus.MAPPED_NOT_SUPPORTED
    },
    [lyraEnabled]
  )
}

import { makeStyles } from '@material-ui/core'
export const styles = makeStyles((theme) => {
  return {
    textLink: {
      textDecoration: 'underline',
      color: '#0000EE',
    },
    helpText: {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: 14,
    },
    outlineBtn: {
      border: '1px solid rgba(224, 224, 224, 1)',
      backgroundColor: 'rgba(230, 230, 230, 0.2)',
    },
    chipContainer: {
      display: 'flex',
    },
    chip: {
      display: 'flex',
      alignItems: 'center',
      border: '1px solid rgba(224, 224, 224, 1)',
      backgroundColor: 'rgba(230, 230, 230, 0.5)',
      borderRadius: 15,
      padding: '5px 10px',
      margin: 5,
    },
    removeBtn: {
      padding: 0,
      border: 'none',
    },
    removeIcon: {
      marginLeft: 3,
      height: 20,
    },
    shareLinkContainer: {
      backgroundColor: '#EDF3FF',
      color: '#3954A3',
      borderRadius: 6,
      padding: '0 10px',
      width: 350,
      paddingBottom: 20,
      marginTop: 20,
    },
    info: {
      display: 'flex',
      alignItems: 'center',
    },
    shareLinkText: {
      marginLeft: 10,
    },
    shareLinkField: {
      background: '#fff',
      width: 250,
      marginRight: 10,
    },
    pendingConnectionItem: {
      display: 'flex',
      alignItems: 'center',
    },
    pendingContainer: {
      margin: '30px 0',
    },
    pendingTitle: {
      fontSize: 18,
    },
    table: {
      tableLayout: 'fixed',
      border: '1px solid #EAEAEA',
      '& .MuiTableCell-root': {
        fontSize: 14,
        padding: '6px 12px 6px 10px',
      },
      '& th:last-child': {
        textAlign: 'end',
      },
      '& td:last-child': {
        textAlign: 'end',
      },
    },
    tableHeading: {
      backgroundColor: '#EAEAEA',
    },
    selectorContainer: {
      margin: '20px 0',
    },
  }
})

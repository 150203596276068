import { useTranslate } from 'ra-core'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { FormRouteInfoPropTypes } from '../types'

const useStyles = makeOpenSolarStyles((theme) => ({
  header: { fontSize: 20, fontWeight: 500, marginTop: 0, marginBottom: 0 },
  description: {
    lineHeight: 1.5,
  },
}))
const BusinessInfoTop: React.FC<FormRouteInfoPropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <div data-testid="bluesnap-business-info-top">
      <h1 className={classes.header}>{translate('Tell us more about your business')}</h1>
      <p className={classes.description}>
        {translate('Confirming your business identity helps us to better protect you and our OpenSolar community.')}
      </p>
    </div>
  )
}
export default BusinessInfoTop

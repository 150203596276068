import { makeStyles } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import React from 'react'
import { FilterComp, SearchInput, SelectInput } from 'react-admin'
import { styles as standardStyles } from 'styles'

type PropTypes = {}

const STATUS_CHOICES = [
  {
    id: 'requested,viewed,payment failed,transfer instructions viewed',
    name: 'Outstanding',
  },
  {
    id: 'paid in full,pending,partially paid',
    name: 'In Progress',
  },
  { id: 'expired', name: 'Overdue' },
  { id: 'completed', name: 'Paid' },
  { id: 'cancelled', name: 'Cancelled' },
]

const useStyles = makeStyles<OpenSolarThemeType>((theme) => ({
  searchBar: {
    marginTop: 5,
    margin: 0,
  },
}))

const InvoicesFilter: React.FC<PropTypes> = (props) => {
  const classes = useStyles()

  return (
    <FilterComp {...props}>
      <SearchInput
        source="q"
        placeholder="Search Invoices"
        alwaysOn={true}
        options={{ variant: 'outlined' }}
        className={classes.searchBar}
      />
      <SelectInput
        options={{ style: standardStyles.FILTER_FIELD_STYLE }}
        source="status"
        choices={STATUS_CHOICES}
        translateChoice={true}
        label="Invoice Status"
      />
    </FilterComp>
  )
}

export default InvoicesFilter

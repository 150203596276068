import { Query } from 'elements/controller/query/query'
import { getAppliedFilters, hardwareFilterTransformer } from 'elements/hardwareSelector/Filters'
import type { LineItemLoadedDataType } from 'pages/ordering/type'
import { useEffect, useMemo } from 'react'
import { useListParams } from 'react-admin'
import { useLocation } from 'react-router-dom'
import { useFeatureFlag } from 'util/split'
import type { ControlledCollectionHookLikeType } from './type'
import useOrderComponentsListBasic from './useOrderComponentsListBasic'
import usePersistentOrderingQueryParams from './usePersistentOrderingQueryParams'

const resource = 'components'

/*
  This hook returns a list of hardware component collections along with the associated data and handlers used for obtaining the results.
  The filter values also managed by url query params. It's bi-directional controller
*/
const useQueryControlledCollection: ControlledCollectionHookLikeType = ({
  catalog,
  showExhibitedFirst,
  componentType,
  limit,
  defaultFilterValues,
}) => {
  const location = useLocation()
  const enableHardwareFilterV2 = useFeatureFlag('hardware_filter_v2', 'on')

  const [paramQuery, queryModifiers] = useListParams({
    resource,
    location,
    filterDefaultValues: defaultFilterValues,
    perPage: limit,
    debounce: 500,
  })

  const persistentFilterValues = usePersistentOrderingQueryParams({
    showExhibitedFirst,
    catalog,
    componentType,
  })

  const modifiableFilterValues = paramQuery.filterValues

  const query = useMemo(
    () =>
      new Query({
        page: paramQuery.page,
        perPage: paramQuery.perPage,
      }),
    [paramQuery.page, paramQuery.perPage]
  )

  const allFilterValues = useMemo(
    () => ({
      page: query.page,
      perPage: query.perPage,
      sort: query.sort,
      order: query.order,
      ...persistentFilterValues,
      ...modifiableFilterValues,
    }),
    [query, persistentFilterValues, modifiableFilterValues]
  )

  useEffect(() => {
    return () => queryModifiers.clearParams()
  }, [])

  const filterValues = {
    ...persistentFilterValues,
    ...paramQuery.filterValues,
  }

  const { data, ids, total, loading, loaded } = useOrderComponentsListBasic({
    resource,
    page: query.page,
    perPage: query.perPage,
    sort: query.sort,
    order: query.order,
    filterValues: enableHardwareFilterV2
      ? filterValues
      : hardwareFilterTransformer({ filterValues: getAppliedFilters(filterValues), componentType }),
  })

  const productData: LineItemLoadedDataType[] = []
  ids?.forEach((id) => {
    data?.[id] && productData.push(data[id])
  })

  return {
    setPage: queryModifiers.setPage,
    setPerPage: queryModifiers.setPerPage,
    setFilters: queryModifiers.setFilters,
    page: paramQuery.page,
    perPage: paramQuery.perPage,
    total,
    productData,
    loading,
    persistentFilterValues,
    modifiableFilterValues,
    allFilterValues,
  }
}

export default useQueryControlledCollection

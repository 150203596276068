import type { OpenSolarThemeType } from 'Themes'
import React, { memo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  title: {
    fontSize: 14,
    lineHeight: '19px',
    margin: '10px 0',
  },
  code: {
    color: theme.greyMid1,
    fontSize: 12,
    margin: '10px 0',
  },
}))

const CatalogItemTitle = memo(
  ({
    code,
    short_description,
    manufacturer_name,
  }: {
    short_description?: string
    code: string
    manufacturer_name?: string
  }) => {
    const classes = useStyles()
    return (
      <>
        <div className={classes.title}>{short_description || manufacturer_name}</div>
        <div className={classes.code}> {code}</div>
      </>
    )
  }
)

export default CatalogItemTitle

// @ts-nocheck
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import withWidth, { WithWidthOptions } from '@material-ui/core/withWidth'
import LaunchIcon from '@material-ui/icons/LaunchOutlined'
import FullScreenLoading from 'layout/widgets/FullScreenLoading'
import React, { useEffect, useMemo, useState } from 'react'
import { NotFound } from 'react-admin'
import { useSelector } from 'react-redux'
import { getOrgFromState } from 'util/org'

const getContent = (data: any, orgData: any) => {
  if (data.content) {
    return data.content.filter((config: any) => {
      return (
        config.countries.includes(orgData.country?.iso2) &&
        (config.states.includes(orgData.state) || config.states.includes('**'))
      )
    })
  }
}

const useStyles = makeStyles((theme) => ({
  tabs: {
    minWidth: 375,
    '& .MuiTabs-scrollable': {
      backgroundColor: 'white',
    },
    '& .MuiTabScrollButton-root': {
      display: 'none',
    },
    '& .MuiTabs-flexContainer': {
      maxWidth: 800,
      margin: '0 auto',
    },
  },
  wrapper: { zIndex: 1100 },
  tabsWrapper: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: ({ coBrandingData }) => ({
    backgroundColor: coBrandingData.themecolor,
  }),
  tab: ({ coBrandingData }) => ({
    fontSize: 14,
    fontWeight: 'normal',
    color: 'rgba(0,0,0,0.87)',
    textTransform: 'none',
    '&:hover': {
      color: coBrandingData.themecolor,
      opacity: 0.8,
    },
    '&$selected': {
      color: coBrandingData.themecolor,
      opacity: 1,
      fontWeight: 500,
    },
    '&:focus': {
      color: coBrandingData.themecolor,
      opacity: 0.8,
    },
  }),
  selected: {},
}))

const ZonePage = ({ coBrandingData, currentOrgData, width }: any) => {
  const [displaySection, setDisplaySection] = useState('')
  const classes = useStyles({ coBrandingData })
  const content = useMemo(() => getContent(coBrandingData, currentOrgData), [coBrandingData, currentOrgData])

  const currentSelected = displaySection || content[0]?.title
  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    const config = content.find((item: any) => item.title === newValue)
    if (config.type === 'launch') {
      window.open(config.content?.url, '_blank')
    } else {
      setDisplaySection(newValue)
    }
  }
  const selectedContent = content?.find((item: any) => item.title === currentSelected)
  const s3Root = currentOrgData?.zone_org?.s3_root
  return (
    <div className={classes.wrapper}>
      {content.length > 0 && (
        <div className={classes.tabsWrapper}>
          <Box>
            <Tabs
              value={currentSelected}
              onChange={handleChange}
              classes={{ root: classes.tabs, indicator: classes.indicator }}
              variant="scrollable"
              // centered
            >
              {content?.map((item: any, index: React.Key | null | undefined) => {
                if (item.type === 'launch') {
                  return (
                    <Tab
                      key={index}
                      classes={{ root: classes.tab, selected: classes.selected }}
                      label={
                        <span>
                          <span style={{ verticalAlign: 'middle' }}>{item.title}</span>
                          <LaunchIcon style={{ width: 14, height: 14, verticalAlign: 'middle' }} />
                        </span>
                      }
                      value={item.title}
                    />
                  )
                } else {
                  return (
                    <Tab
                      key={index}
                      classes={{ root: classes.tab, selected: classes.selected }}
                      label={item.title}
                      value={item.title}
                    />
                  )
                }
              })}
            </Tabs>
          </Box>
        </div>
      )}
      {selectedContent?.content?.url && (
        <iframe
          style={{
            padding: 0,
            border: 0,
            margin: 0,
            marginTop:
              selectedContent.content.mobilemarginTop &&
              (width === 'md' || width === 'sm' || width === 'xs' || width === 'xxs')
                ? selectedContent.content.mobilemarginTop
                : selectedContent.content.marginTop,
            width: '100%',
            height: selectedContent.content.marginTop
              ? selectedContent.content.mobilemarginTop
                ? width === 'xs' || width === 'xxs'
                  ? `calc(100vh - 150px - ${selectedContent.content.mobilemarginTop}px)`
                  : width === 'md' || width === 'sm'
                  ? `calc(100vh - 100px - ${selectedContent.content.mobilemarginTop}px)`
                  : `calc(100vh - 100px - ${selectedContent.content.marginTop}px)`
                : `calc(100vh - 100px - ${selectedContent.content.marginTop}px)`
              : 'calc(100vh - 100px)',
          }}
          src={selectedContent.type === 'iframe' ? selectedContent.content.url : s3Root + selectedContent.content.url}
        />
      )}
    </div>
  )
}

type PropTypes = {
  width: WithWidthOptions
}

const PageZone: React.FunctionComponent<PropTypes> = (props) => {
  const [loadedData, setLoadedData] = useState()
  const orgData = useSelector((state) => getOrgFromState(state))
  useEffect(() => {
    if (orgData?.zone_org?.json_url) {
      fetch(orgData.zone_org?.json_url)
        .then((response) => response.json())
        .then((data) => setLoadedData(data))
        .catch((e) => console.log(e))
    }
  }, [])
  if (!orgData?.zone_org) {
    return <NotFound />
  }
  if (loadedData) {
    return <ZonePage coBrandingData={loadedData} currentOrgData={orgData} width={props.width} />
  } else {
    return <FullScreenLoading />
  }
}

export default withWidth()(PageZone as any)

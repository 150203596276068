import { MenuItem } from '@material-ui/core'
import { FormControl, Select } from 'opensolar-ui'
import { FC, useState } from 'react'

const choices = [
  { title: 'Choice 1', id: 'test-1' },
  { title: 'Choice 2', id: 'test-2' },
  { title: 'Choice 3', id: 'test-3' },
]

export const SelectBasic: FC = () => {
  const [value, setValue] = useState('test-1')
  const handleChange = (e) => {
    setValue(e.target.value)
  }
  return (
    <>
      {/* SNIPPET START 1 */}
      <Select value={value} onChange={handleChange}>
        {choices?.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
      {/* SNIPPET END 1 */}
    </>
  )
}

export const SelectSizes: FC = () => {
  const [value, setValue] = useState('test-1')
  const handleChange = (e) => {
    setValue(e.target.value)
  }
  return (
    <>
      {/* SNIPPET START 2 */}
      <FormControl size="small">
        <Select value={value} onChange={handleChange}>
          {choices?.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl size="medium">
        <Select value={value} onChange={handleChange}>
          {choices?.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* SNIPPET END 2 */}
    </>
  )
}

export const SelectVariants: FC = () => {
  const [value, setValue] = useState('test-1')
  const handleChange = (e) => {
    setValue(e.target.value)
  }
  return (
    <>
      {/* SNIPPET START 3 */}
      <FormControl variant="standard">
        <Select value={value} onChange={handleChange}>
          {choices?.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined">
        <Select value={value} onChange={handleChange}>
          {choices?.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="filled">
        <Select value={value} onChange={handleChange}>
          {choices?.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* SNIPPET END 3 */}
    </>
  )
}

export const SelectColors: FC = () => {
  const [value, setValue] = useState('test-1')
  const handleChange = (e) => {
    setValue(e.target.value)
  }
  return (
    <>
      {/* SNIPPET START 4 */}
      <FormControl color="info">
        <Select value={value} onChange={handleChange}>
          {choices?.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl color="warning">
        <Select value={value} onChange={handleChange}>
          {choices?.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl color="error">
        <Select value={value} onChange={handleChange}>
          {choices?.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl color="success">
        <Select value={value} onChange={handleChange}>
          {choices?.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* SNIPPET END 4 */}
    </>
  )
}

import { Typography } from '@material-ui/core'
import { useTranslate } from 'ra-core'

import React from 'react'
import { ModulePropTypes, ModuleReleaseMessageWarning } from '../types'

export const IAPModule_ReleaseMessageWarning: React.FC<ModulePropTypes<ModuleReleaseMessageWarning>> = ({
  module,
  translationSubs,
}) => {
  const translate = useTranslate()

  let message: string | undefined

  message =
    module.msg ||
    'OpenSolar is getting even better with our later release. Please save your changes before the update starts.'

  if (!message) return null
  return (
    <p>
      {message && (
        <Typography variant="body1" display="inline">
          {translate(message, translationSubs)}
        </Typography>
      )}
    </p>
  )
}

// @ts-nocheck
import FlashIcon from '@material-ui/icons/FlashOnOutlined'
import { authSelectors } from 'ducks/auth'
import { permissionsSelectors } from 'ducks/permissions'
import SharedEntitySelectInput from 'elements/field/SharedEntitySelectInput'
import { ManageIconButton } from 'projectSections/sections/design/systems/PanelSystem'
import React, { FC, useCallback, useEffect } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { CostingDataType } from 'types/costings'
import { SystemDataType } from 'types/systems'

type PropsType = {
  system: SystemDataType
  pricingIsLocked: boolean
  sharedWith?: ProjectShare[]
}

const CostingSelectInput: FC<PropsType> = ({ system, pricingIsLocked, sharedWith }) => {
  const { allowView, allowCreate, allowEdit, allowDelete } = useSelector(
    permissionsSelectors.getProjectPermissionByKey('design_costing_override')
  )
  const handleChooseCosting = useCallback((value, uuid) => {
    var system = window.editor.objectByUuid(uuid)
    window.editor.execute(new window.SetValueCommand(system, 'costing_override', value))

    // Any manual update should break auto-sync
    system.autoSync.costing = false
  }, [])
  const translate = useTranslate()
  const costingSchemes = window.AccountHelper.loadedData.costings
    .filter((costing: CostingDataType) => !costing.is_archived || system.costing_override === costing.id)
    .map((costing: CostingDataType) => ({
      id: costing.id,
      title: costing.is_archived ? costing.title + ' (' + translate('Archived') + ')' : costing.title,
      org: costing.org,
      share_with_orgs: costing.share_with_orgs,
    }))

  const orgId = useSelector(authSelectors.getOrgId)

  // If costing is deleted, set costing_override to null and enable autoSync
  useEffect(() => {
    const costingIds = new Set(
      window.AccountHelper.loadedData.costings.map((costing: CostingDataType) => String(costing.id))
    )
    const allowAutoApply = !sharedWith?.find((x) => x.org_id === orgId)
    //disable auto applying for external orgs
    if (allowAutoApply && system.costing_override && !costingIds.has(String(system.costing_override))) {
      window.editor.execute(new window.SetValueCommand(system, 'costing_override', null))
      system.autoSync.costing = true
    }
  }, [])

  const costingOverride = system.costing_override
  if (!allowView) {
    return null
  }
  return (
    <div style={{ display: 'flex' }}>
      <SharedEntitySelectInput
        variant="outlined"
        choices={costingSchemes}
        style={{ flex: '1 1', width: 'auto' }}
        label={
          <span>
            {translate('Costing Scheme')}
            {window.editor.selectedSystem?.autoSync?.costing === true && (
              <FlashIcon style={{ height: 20, verticalAlign: 'top' }} />
            )}
          </span>
        }
        MenuProps={{ style: { zIndex: 2500 } }}
        value={costingOverride}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
          const value = event.target.value as string
          handleChooseCosting(value, system.uuid)
        }}
        disabled={pricingIsLocked || !costingSchemes.length || !allowEdit}
        emptyText={costingSchemes.length ? 'Auto-apply' : 'No Costing Schemes'}
        standardPadding={true}
      />
      {
        <ManageIconButton
          heading={translate('Leave Studio to manage available Costing Scheme?')}
          tooltipLabel={translate('Manage Costing Scheme')}
          url={'/costings'}
        />
      }
    </div>
  )
}

export default CostingSelectInput

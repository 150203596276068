export const STAR_ADD = 'STAR_ADD'
export const STAR_ADD_LOADING = 'STAR_ADD_LOADING'
export const STAR_ADD_FAILURE = 'STAR_ADD_FAILURE'
export const STAR_ADD_SUCCESS = 'STAR_ADD_SUCCESS'

export const starAdd = (id) => ({
  type: STAR_ADD,
  payload: { id, url: 'projects/' + id + '/star/1' },
  meta: { resource: 'projects', fetch: 'CUSTOM_POST', cancelPrevious: false },
})

export const STAR_REMOVE = 'STAR_REMOVE'
export const STAR_REMOVE_LOADING = 'STAR_REMOVE_LOADING'
export const STAR_REMOVE_FAILURE = 'STAR_REMOVE_FAILURE'
export const STAR_REMOVE_SUCCESS = 'STAR_REMOVE_SUCCESS'

export const starRemove = (id) => ({
  type: STAR_REMOVE,
  payload: { id, url: 'projects/' + id + '/star/0' },
  meta: { resource: 'projects', fetch: 'CUSTOM_POST', cancelPrevious: false },
})
/*
@TODO: Fix this. We should optimistically update to actual resource in the state
rather than storing them here, but how to we injecti into admin.resources.data[id][field]
*/

export default (previousState = {}, { type, payload }) => {
  if (type === STAR_ADD_LOADING) {
    previousState[payload.id] = true
  }

  if (type === STAR_REMOVE_LOADING) {
    previousState[payload.id] = false
  }

  return previousState
}

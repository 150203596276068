import React, { FC } from 'react'

import withMediaQuery from 'layout/withMediaQuery'
import ProUXPanel from 'projectSections/sections/design/Panel'
import { withTranslate } from 'react-admin'
import compose from 'recompose/compose'
import { StudioItem } from 'types/global'

type StudioPanelContextType = {
  context: React.Component
  object: StudioItem
}

export const StudioPanelContext = React.createContext<StudioPanelContextType | null>(null)

type PanelPropsType = {
  title?: string
  summary?: React.ReactNode
  feature?: React.ReactNode
  content?: React.ReactNode
  showTools: boolean
  disableTools: boolean
  onClose?: () => void
}

const enhance = compose(withTranslate, withMediaQuery) // TODO: should be okay to remove, but I suspect panel may relies on withMediaQuery to force a re-render

const EnhancedPanel = enhance(ProUXPanel)

const Panel: FC<PanelPropsType> = (props) => {
  return <EnhancedPanel {...props} />
}

export default Panel

import { ModuleGridType, PanelPlacement } from 'types/global'
import type { BasicAccessorConfig } from './types'

const setter = ({ moduleGrid, value }: { moduleGrid: ModuleGridType; value: PanelPlacement }) => {
  window.editor.execute(
    new window.SetPanelConfigurationCommand(
      moduleGrid,
      'panelPlacement',
      value,
      window.Utils.generateCommandUUIDOrUseGlobal()
    )
  )
  let system = moduleGrid.getSystem()
  window.editor.execute(new window.SetValueCommand(system, 'systemPanelPlacement', system.getSystemPanelPlacement()))
}

const getter = (moduleGrid: ModuleGridType): PanelPlacement => {
  return moduleGrid.panelPlacement || 'roof'
}

export const panelPlacementAccessorConfig: BasicAccessorConfig<PanelPlacement> = {
  get: getter,
  set: setter,
}

import { makeStyles } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import { LockOutlineIcon } from 'opensolar-ui'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import { Theme } from 'types/themes'
import { useTranslate } from 'ra-core'
import { formatCurrency } from 'util/misc'
import { getShippingFeeCurrencySymbol } from '../helper'
import { COLOR_PALETTE, getHardwareSectionPreOrderStyles } from '../styles'

type Props = {
  totalItems: number
  subtotal: string
  shippingFee: string
  isPriceLocked?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: 'absolute',
    right: 50,
    width: '50%',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  sectionWrapper: {
    gap: 10,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  subTotalWrapper: {
    width: '60%',
  },
  shippingFeeWrapper: {
    width: '40%',
  },
  sectionTitle: {
    fontSize: 14,
    color: COLOR_PALETTE.grey4,
  },
  sectionValue: {
    fontSize: 16,
    fontWeight: 600,
  },
}))

const AccordionCardSummaryContent = ({ totalItems, subtotal, shippingFee, isPriceLocked }: Props) => {
  const classes = useStyles()
  const sectionStyles = getHardwareSectionPreOrderStyles()
  const currencySymbol = useSelector(orgSelectors.getCurrencySymbolFromOrg)
  const shippingFreeCurrencySymbol = getShippingFeeCurrencySymbol(shippingFee, currencySymbol)
  const translate = useTranslate()

  return (
    <div className={`${sectionStyles.row} ${classes.wrapper}`}>
      <div className={`${sectionStyles.row} ${classes.sectionWrapper} ${classes.subTotalWrapper}`}>
        {isPriceLocked && <LockOutlineIcon fill={COLOR_PALETTE.blue} height="24" width="24" />}
        <span className={classes.sectionTitle}>{translate(`Subtotal (${totalItems} items)`)}:</span>
        <span className={classes.sectionValue}>{`${currencySymbol}${formatCurrency(subtotal)}`}</span>
      </div>
      <div className={`${sectionStyles.row} ${classes.sectionWrapper} ${classes.shippingFeeWrapper}`}>
        <span className={classes.sectionTitle}>{translate('Shipping Cost')}:</span>
        <span className={classes.sectionValue}>{`${shippingFreeCurrencySymbol}${shippingFee}`}</span>
      </div>
    </div>
  )
}

export default memo(AccordionCardSummaryContent)

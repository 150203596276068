import Typography from '@material-ui/core/Typography'
import type { OpenSolarThemeType } from 'Themes'
import type LineItemType from 'pages/ordering/OrderLineItem'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useBulkOrderMessageStyle = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  bulkAvailable: {
    color: theme.errorRed,
    margin: 5,
  },
}))

const BulkQuantityMessage = ({ lineItem }: { lineItem: LineItemType }) => {
  const classes = useBulkOrderMessageStyle()
  const { quantity } = lineItem;

  if (quantity === 0 || isNaN(quantity)) {
    return (
      <Typography className={classes.bulkAvailable} variant="body1" gutterBottom>
        Please enter 1 or more as the quantity
      </Typography>
    )
  }
  return null
}

export default BulkQuantityMessage
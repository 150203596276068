import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { transactionSelectors } from 'ducks/transaction'
import LoadingDots from 'layout/widgets/LoadingDots'
import { useTranslate } from 'ra-core'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ProposalDataType } from 'types/proposals'
import { getCompletedRecipients } from './docusignCallouts'

type PropTypes = {
  proposalData: ProposalDataType
  setSelectedDocusignCustomer: (val: string) => void
  showDocusignUserSelection: boolean
}

const DocuSignRedirectDialog: React.FC<PropTypes> = (props) => {
  const [docusignCompletedCustomerIndices, setDocusignCompletedCustomerIndices] = useState<string[]>([])
  const [docusignUserSelectionLoading, setDocusignUserSelectionLoading] = useState<boolean>(false)

  useEffect(() => {
    if (props.showDocusignUserSelection) {
      getCompletedRecipientIndices()
    }
  }, [props.showDocusignUserSelection])

  const translate = useTranslate()
  const transactionRequestData = useSelector(transactionSelectors.getTransactionRequestData)

  const selectDocusignCustomer = (selectedIndexString: string) => {
    props.setSelectedDocusignCustomer(selectedIndexString)
  }

  const getCompletedRecipientIndices = () => {
    setDocusignUserSelectionLoading(true)
    getCompletedRecipients(transactionRequestData)
      .then((res: any) => {
        if (res.data?.success) {
          if (res?.data?.completed_indices) setDocusignCompletedCustomerIndices(res.data.completed_indices)
        }
      })
      .catch(() => {})
      .finally(() => setDocusignUserSelectionLoading(false))
  }

  if (
    window.location.href.includes('event=id_check_failed') &&
    // @ts-ignore
    props.proposalData?.selectedProject?.docusign_contract_auth_failures < 3
  ) {
    return (
      <div style={{ marginTop: '50px' }}>
        <p>
          {translate(
            'You were unable to confirm your identity with Docusign. Click "Review and Sign Contract" to try again. If you are unable to confirm your identity again the document will lock itself.'
          )}
        </p>
      </div>
    )
  } else {
    if (props.showDocusignUserSelection && props.proposalData?.selectedProject?.contacts) {
      if (docusignUserSelectionLoading) {
        return (
          <div
            style={{ height: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
            data-testid="docusign-recipient-selection-loading"
          >
            <LoadingDots text="One moment while we load your contract" />
          </div>
        )
      }
      return (
        <div style={{ marginTop: '50px' }} id="docusign-applicant-choice">
          <p>
            {translate(
              'When you are ready to review and sign your contract select your name and click the button below to do so via Docusign. After the document has been signed by all parties you will be emailed a copy for your records'
            )}
          </p>
          <div>
            <RadioGroup
              onChange={(e) => selectDocusignCustomer(e.target.value)}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <FormControlLabel
                label={props.proposalData?.selectedProject?.contacts[0]?.full_name}
                value="0"
                disabled={docusignCompletedCustomerIndices.includes('0')}
                control={<Radio />}
                data-testid="docusign-recipient-selection-index-0"
              />
              <FormControlLabel
                label={props.proposalData?.selectedProject?.contacts[1]?.full_name}
                value="1"
                disabled={docusignCompletedCustomerIndices.includes('1')}
                control={<Radio data-testid="docusign-recipient-selection-index-1" />}
              />
            </RadioGroup>
          </div>
        </div>
      )
    } else {
      return (
        <div style={{ marginTop: '50px' }}>
          <p>
            {translate(
              'When you are ready to review and sign your contract click the button below to do so via Docusign. After the document has been signed you will be emailed a copy for your records'
            )}
          </p>
        </div>
      )
    }
  }
}
export default DocuSignRedirectDialog

import { authSelectors } from 'ducks/auth'
import Alert from 'elements/Alert'
import CustomField from 'elements/field/CustomField'
import React, { useCallback } from 'react'
import { BooleanInput, NumberInput, RadioButtonGroupInput, SelectInput, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import DependentInput from '../../../elements/input/DependentInput'
import { toggle_inputs_and_extra_fields } from '../common'
import { styles } from '../styles.js'

export const Prepayment = ({ resource }) => {
  const translate = useTranslate()
  const form = useForm()
  const isStaff = useSelector(authSelectors.getIsStaff)
  const formState = useFormState()
  const isVariablePrepaymentEnabled = formState.values['variable_prepayment_enabled']
  const isPlentiBNPL =
    formState.values['variable_integration'] === 'plenti' &&
    formState.values['variable_loan_type'] === 'buy_now_pay_later'
  const disabled = ['sunlight', 'dividend', 'sungage', 'mosaic', 'loanpal', 'phoenix'].includes(
    formState.values['variable_integration']
  )

  const getLoanTypeChoices = useCallback(() => {
    if (isPlentiBNPL)
      return [
        {
          id: 'buy_now_pay_later',
          label: 'Zero Interest Loan',
        },
      ]
    else {
      return [
        {
          id: 'solar_style_loan',
          label:
            'Solar-Style Loan - Offers a fixed monthly payment over the life of the loan, assuming the customer makes a one-time prepayment (typically equal to the value of the ITC). Failure to make this prepayment leads to a higher monthly payment for the remainder of the loan term.',
        },
        {
          id: 'regular_installment_loan',
          label:
            'Mortgage-Style Loan - Offers a fixed monthly payment over the life of the loan, unless the customer makes a one-time prepayment (typically equal to the value of the ITC). Making this prepayment leads to a lower monthly payment for the remainder of the loan term.',
        },
      ]
    }
  }, [isPlentiBNPL])
  return (
    <div style={Boolean(isVariablePrepaymentEnabled) ? styles.loanWrapperWithBoxShadow : styles.loanWrapper}>
      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', height: 50 }}>
        <BooleanInput
          resource={resource}
          formControlLabelStyle={styles.formControlLabelStyle}
          onChange={(value) => {
            if (!value) {
              const selectedToggle =
                toggle_inputs_and_extra_fields.find((a) => a.key === 'variable_prepayment_enabled') || {}
              const extraFields = selectedToggle.extraFields || []
              extraFields.forEach((field) => {
                form.mutators.updateField(field, null)
              })
            }
          }}
          label="Prepayment"
          name="variable_prepayment_enabled"
          source="variable_prepayment_enabled"
          disabled={disabled}
        />
        <DependentInput dependsOn="variable_prepayment_enabled" resolve={(value) => !value}>
          <i style={styles.toggleInputHint}>e.g. Tax Credit Paydown</i>
        </DependentInput>
      </div>
      <DependentInput dependsOn="variable_prepayment_enabled" value={true}>
        <i style={styles.toggleInputHint}>e.g. Tax Credit Paydown</i>
        <p>
          If proposed financing assumes a prepayment will be paid (typically an amount equal to ITC and/or other
          customer-facing incentives), enter info here to show monthly payments before and after prepayment.
        </p>

        <CustomField
          component={RadioButtonGroupInput}
          name="variable_loan_type"
          source="variable_loan_type"
          resource={resource}
          style={{ color: 'rgb(162, 160, 160)' }}
          label={false}
          choices={getLoanTypeChoices()}
          optionText="label"
          optionValue="id"
          disabled={disabled}
        />

        {isStaff && (
          <DependentInput dependsOn="variable_payment_factors_enabled" value={true}>
            <Alert severity="info">
              When using solar-style loan, payment factor calculations will be based off of the initial loan amount
              minus the prepayment. When using Mortgage-Style Loan the payment factor calculations will just be based
              off of the initial loan amount.
            </Alert>
          </DependentInput>
        )}

        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <CustomField
            component={SelectInput}
            resource={resource}
            label={translate('Prepayment Formula')}
            source={'variable_prepayment_formula'}
            name={'variable_prepayment_formula'}
            choices={[
              { id: 'none', name: 'None' },
              { id: 'customer_incentives_only_marked', name: 'Customer Incentives (only marked)' },
              { id: 'percentage_of_post_dealer_fee_loan_amount', name: 'Percentage of total loan amount' },
            ]}
            disabled={disabled}
          />
          <DependentInput dependsOn="variable_prepayment_formula" value={'percentage_of_post_dealer_fee_loan_amount'}>
            <NumberInput
              resource={resource}
              style={styles.inputFieldMediumWide}
              label={translate('Prepayment')}
              source={'variable_prepayment_percentage'}
              name={'variable_prepayment_percentage'}
              endAdornment={'%'}
              disabled={disabled}
            />
          </DependentInput>
          <NumberInput
            step={1}
            resource={resource}
            style={styles.inputFieldMediumWide}
            label={translate('Prepayment Month')}
            source={'variable_prepayment_period'}
            name={'variable_prepayment_period'}
            disabled={disabled}
          />
        </div>
      </DependentInput>
    </div>
  )
}

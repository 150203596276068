import { authSelectors } from 'ducks/auth'
import { useEditController } from 'ra-core'
import { EditView, NotFound } from 'ra-ui-materialui'
import React, { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { AccessRightsType } from 'types/global'
import { urlToId } from 'util/misc'

const getBasePath = (location) => location.pathname.split('/').slice(0, -1).join('/')

// This is a customised version of the <Edit> component from react-admin
// It checks whether the entity loaded actually belongs to the user's org
// If not, it forces the permissions to be view-only

export const EditShareable = ({
  basePath = undefined,
  id = undefined,
  hasDelete = true,
  undoable = undefined,
  accessRights,
  setAccess,
  ...props
}) => {
  // set basePath and id automatically
  // disable undoable behavior
  const actionsUndoable = false
  const newProps = {
    ...props,
    basePath: basePath || getBasePath(props.location),
    id: id || decodeURIComponent(props.match.params.id),
    undoable: undoable || actionsUndoable,
    hasDelete,
  }
  //@ts-ignore
  const controller = useEditController(newProps)

  const userOrgId = useSelector(authSelectors.getOrgId)
  const entityOrgId = urlToId(controller.record?.org)
  const isShared = entityOrgId !== undefined && userOrgId !== entityOrgId

  setAccess(isShared ? SHARED_ACCESS_RIGHTS : accessRights)

  //@ts-ignore
  return <EditView {...newProps} {...controller} />
}

const SHARED_ACCESS_RIGHTS: AccessRightsType = {
  allowView: true,
  allowEdit: false,
  allowCreate: false,
  allowDelete: false,
}

type HookProps = {
  accessRights: AccessRightsType
  render: (props: { access: AccessRightsType }) => ReactNode
  editProps: any
  suppressNotFound?: boolean
}

export const useEditShareable = ({ accessRights, render, editProps, suppressNotFound }: HookProps) => {
  const [access, setAccess] = React.useState(accessRights)
  return {
    access,
    editPage:
      !suppressNotFound && !access.allowView ? (
        <NotFound />
      ) : (
        <EditShareable {...editProps} hasDelete={access.allowDelete} setAccess={setAccess} accessRights={accessRights}>
          {render({ access })}
        </EditShareable>
      ),
  }
}

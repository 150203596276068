/**
 * Allows for for components to work out whether they're in a CustomForm, and the settings
 * of that CustomForm.
 *
 */

import { createContext } from 'react'
import { CustomFormProps } from './CustomForm'

export const CustomFormContext = createContext<CustomFormProps | undefined>(undefined)

import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import { useNotify, useTranslate } from 'ra-core'
import React, { useState } from 'react'
import { useFormState } from 'react-final-form'
import restClient from 'restClient'
import { AvailableFinanceIntegrationType } from 'types/orgs'
import { reloadEntireApp } from 'util/misc'

const restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  integration: AvailableFinanceIntegrationType
  onClose: () => void
  orgId: number | undefined
}

const GenericConnectRoleDialog: React.FC<PropTypes> = (props) => {
  const [email, setEmail] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const formState = useFormState()
  const translate = useTranslate()
  const notify = useNotify()

  const onSubmit = () => {
    setIsLoading(true)
    restClientInstance('CUSTOM_PUT', 'custom', {
      url: `orgs/${props.orgId}/finance/${props.integration.integration_name}/connect_roles/`,
      data: {
        assigned_role_id: formState.values?.id,
        assigned_role_email: email,
      },
    })
      .then((res) => {
        notify(translate('Your updates have been saved'), 'success')
        props.onClose()
        reloadEntireApp()
      })
      .catch((err) => {
        if (err?.body?.message) notify(err?.body?.message, 'warning')
        else notify(translate('Something went wrong'), 'warning')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Dialog open={true} onClose={props.onClose}>
      <DialogTitle>
        {translate('Email for %{partnerName}', { partnerName: props.integration.display_name })}
      </DialogTitle>
      <DialogContent>
        <div>
          <p>
            {translate("Please enter the email address that this user uses to log into %{partnerName}'s site.", {
              partnerName: props.integration.display_name,
            })}
          </p>
          <div style={{ width: '100%', marginTop: '20px' }}>
            <TextField
              label={translate('Lender Email Address')}
              name="Email Address"
              value={email}
              style={{ width: '100%' }}
              fullWidth
              onChange={(event) => {
                const value = event.target.value
                setEmail(value)
              }}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <div style={{ margin: 20 }}>
            <ProUXButton type="primary" label="Save Changes" onClick={onSubmit} loading={isLoading} />
          </div>
        </div>
      </DialogActions>
    </Dialog>
  )
}
export default GenericConnectRoleDialog

import { orgSelectors } from 'ducks/orgs'
import InfoTooltip from 'elements/tooltip/InfoTooltip'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { getSupplierEnumByFilterKey } from 'pages/ordering/configs'
import { DISTRIBUTOR_LABEL, STOCK_LEVEL_STATUS } from 'pages/ordering/constants'
import ProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/projectOrderPresenter'
import {
  HardwareSupplierEnum,
  HardwareSupplierFilterKeyType,
  StockLevelStatus,
  StockLevelType,
} from 'pages/ordering/type'
import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { numberWithCommas } from 'util/misc'
import { HasTradePricingContext, HasTradePricingContextType } from '../HardwareSelectorCore'
import { DistributorDataTypeV2 } from '../types'
import EmptyColumnItem from './EmptyColumnItem'
import useCommonStyles from './useCommonStyles'

interface QuantityBlockProps {
  distributors: DistributorDataTypeV2[]
}

type StatusPerDistributorType = {
  status: StockLevelStatus
  infoText: string
  orderValue: number
  distributor: HardwareSupplierFilterKeyType
}

const CONTACT_SEGEN_TEXT = 'Stock Availability on Request! Contact Segen!'

const useStyles = makeOpenSolarStyles(() => ({
  container: {
    padding: '2px 0px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}))

const availableStatuses = [
  STOCK_LEVEL_STATUS.AVAILABLE,
  STOCK_LEVEL_STATUS.AVAILABLE_FOR_PRE_ORDER,
  STOCK_LEVEL_STATUS.LOW_STOCK,
  STOCK_LEVEL_STATUS.PARTIAL_AVAILABLE,
]

const getIncomingStocksInfo = (incomingStocks: StockLevelType[]): string =>
  incomingStocks?.reduce(
    (accumulator, item) => accumulator + `\n${moment(item.planned_date).format('D/M/YYYY')} (${item.quantity})`,
    ''
  )

const getStockStatusPerDistributor = (
  distributors: DistributorDataTypeV2[],
  enabledDistributors: HardwareSupplierEnum[]
): StatusPerDistributorType[] => {
  // Mapping distributors data to it corresponding stats. Should return an array of object containing
  // {
  //   isAvailable: // Check availability for each distributor. In some cases, segen special logic is applied.
  //   stockLevels: // Accumulating all stock levels for each distributor
  //   quantity: // Mapping of quantity
  // }
  const statsPerDistributor = distributors
    .filter((item) => {
      const supplierEnum = getSupplierEnumByFilterKey(item.distributor)
      return enabledDistributors.includes(supplierEnum)
    })
    .reduce((accumulator: any, item) => {
      let isAvailable =
        item.distributor === 'segen' ? item.is_available || item.stock_levels.length === 0 : item.is_available
      let stockLevels = item.stock_levels
      const stockLevel = item.stock_levels?.find(({ in_stock: inStock }) => inStock)
      let quantity = stockLevel?.quantity || 0
      if (accumulator.hasOwnProperty(item.distributor)) {
        isAvailable = accumulator[item.distributor]['isAvailable'] || item.is_available
        stockLevels = [...accumulator[item.distributor]['stockLevels'], ...item.stock_levels]
        quantity = accumulator[item.distributor]['quantity'] + (stockLevel?.quantity || 0)
        if (item.distributor === 'segen') {
          isAvailable =
            accumulator[item.distributor]['isAvailable'] || item.is_available || item.stock_levels.length === 0
        }
      }
      accumulator[item.distributor] = { ...accumulator[item.distributor], isAvailable, stockLevels, quantity }
      return accumulator
    }, {})

  // Returns an object containing the stock status of each distributor. Used getStockLevelStatusMain() to match the stock status
  // from hardware page. InfoText is used in the stock tooltip and OrderValue is used to sort and show in stock on top.
  const statusPerDistributor = Object.entries(statsPerDistributor).map((item: any) => {
    const [key, value] = item
    let infoText = ''
    let orderValue = 3
    const stockLevels: StockLevelType[] | undefined = value?.stockLevels
      ? Object.values(value?.stockLevels).map((item: any) => {
          const { is_available, ...restItems } = item
          return restItems
        })
      : undefined
    const status: StockLevelStatus = ProjectOrderPresenter.getStockLevelStatusMain({
      quantity: value.quantity,
      isAvailable: value.isAvailable,
      stockLevels: stockLevels,
    })
    if (availableStatuses.includes(status)) {
      infoText = 'In stock'
      orderValue = 1
      if (key === 'segen') {
        const incomingStocks = stockLevels?.filter((stock) => !stock.in_stock && stock.planned_date)
        if (stockLevels?.length === 0 || !stockLevels) {
          infoText = CONTACT_SEGEN_TEXT
          orderValue = 2
        } else if (incomingStocks && !isEmpty(incomingStocks)) {
          infoText = infoText + getIncomingStocksInfo(incomingStocks)
        }
      }
    } else {
      infoText = 'Out of stock'
      if (key === 'segen') {
        const incomingStocks = stockLevels?.filter((stock) => !stock.in_stock && stock.planned_date)
        if (incomingStocks && !isEmpty(incomingStocks)) {
          infoText = infoText + getIncomingStocksInfo(incomingStocks)
        }
      }
    }

    return { status, infoText, orderValue, distributor: key }
  })

  return statusPerDistributor.sort((a, b) => a.orderValue - b.orderValue)
}

const QuantityBlock: React.FC<QuantityBlockProps> = ({ distributors }) => {
  const commonClasses = useCommonStyles()
  const classes = useStyles()
  const enabledDistributors = useSelector(orgSelectors.getEnabledHardwareSuppliers)
  const hasTradePricing = useContext<HasTradePricingContextType>(HasTradePricingContext).hasTradePricing
  const selectedDistributor = useSelector(orgSelectors.getSelectedHardwareSupplier)

  const quantityAvailable = useMemo(
    () =>
      distributors
        .filter((item) => {
          const supplierEnum = getSupplierEnumByFilterKey(item.distributor)
          return item.is_available && enabledDistributors.includes(supplierEnum)
        })
        .reduce((currentQuantity, v) => {
          const stockLevel = v.stock_levels?.find(({ in_stock: inStock }) => inStock)
          return currentQuantity + (stockLevel?.quantity || 0)
        }, 0),
    [distributors]
  )
  const stockStatusPerDistributor: StatusPerDistributorType[] = useMemo(
    () => getStockStatusPerDistributor(distributors, enabledDistributors),
    [distributors]
  )

  const stockText =
    !isEmpty(stockStatusPerDistributor) && stockStatusPerDistributor[0].infoText === CONTACT_SEGEN_TEXT
      ? 'TBC'
      : numberWithCommas(quantityAvailable, true)
  const stockTextColor =
    !isEmpty(stockStatusPerDistributor) && availableStatuses.includes(stockStatusPerDistributor[0].status)
      ? '#019939'
      : '#E12121'

  if (isEmpty(stockStatusPerDistributor) || (selectedDistributor === HardwareSupplierEnum.Segen && !hasTradePricing))
    return <EmptyColumnItem />

  return (
    <div className={classes.container}>
      <span className={commonClasses.mainText} style={{ color: stockTextColor }}>
        {stockText}
      </span>
      {!isEmpty(stockStatusPerDistributor) && (
        <InfoTooltip
          severity={'info'}
          title={
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              {stockStatusPerDistributor.map(({ infoText, distributor }) => (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                    {DISTRIBUTOR_LABEL[distributor]}
                  </span>
                  <span style={{ whiteSpace: 'pre-line' }}>{infoText}</span>
                </div>
              ))}
            </div>
          }
          placement="right"
          style={{ marginLeft: 5 }}
        />
      )}
    </div>
  )
}

export default QuantityBlock

import { FormApi } from 'final-form'
import { Action, SaveState, ShallowState, Value, ValueArray } from 'opensolar-sdk'
import { useEffect, useRef } from 'react'

interface Opts<T> {
  active?: Value<boolean>
  save_state?: Value<SaveState>
  dirty_fields?: ValueArray<string[]>
  values?: ShallowState<Partial<Record<string, any>>>
  save?: Action
  discard?: Action
  setValues?: Action<[Record<string, any>]>
  onValuesChange?: (values: T) => void
  onFormRemoved?: () => void
}

/**
 * This binds some part of the SDK state tree into a react-admin form.
 * This allows us to quickly expose the internal state of the form (e.g. dirty fields, values, etc)
 * as well as expose the actions of the form (e.g. save, discard).
 */

export function useBindReactAdminForm<T = any>(form: FormApi<any> | undefined, opts: Opts<T>) {
  const { active, save_state, dirty_fields, values, save, discard, setValues, onValuesChange, onFormRemoved } = opts

  const formRef = useRef<FormApi<any> | undefined>()
  formRef.current = form

  useEffect(() => {
    if (!form) {
      if (active) active.value = false
      if (save_state) save_state.value = 'none'
      if (dirty_fields) dirty_fields.value = []
      if (values) values.value = []
      if (onFormRemoved) onFormRemoved()
    } else {
      let listener = () => {
        const formState = form.getState()
        const formStateValues = formState.values
        const isDirty = form.mutators.getFormDirtyFields
          ? !!form.mutators.getFormDirtyFields()?.length
          : formState.dirty

        if (save_state)
          save_state.value =
            formStateValues.id === undefined || formStateValues.id === 'new'
              ? 'new'
              : formState.submitting
                ? 'saving'
                : isDirty
                  ? 'unsaved'
                  : 'saved'
        if (dirty_fields)
          dirty_fields.value = form.mutators.getFormDirtyFields
            ? form.mutators.getFormDirtyFields()
            : Object.keys(formState.dirtyFields)

        if (values) values.value = form.getState().values

        if (onValuesChange) onValuesChange(formStateValues)
      }

      let unsub = form.subscribe(listener, {

        submitting: true, visited: true,

        // only subscribe to dirty/dirtyFields if we are actually listening to the values
        dirty: !!dirty_fields,
        dirtyFields: !!dirty_fields,

        // only subscribe to all value changes if we are listening to values otherwise we will receive a lot of
        // unnecessary updates
        values: !!values,
      })
      listener()
      if (active) active.value = true
      return unsub
    }
  }, [form])

  const waitForFormToMount = async (maxAttempts = 20, delay = 1000): Promise<FormApi<any>> => {
    let attempts = 0

    while (attempts < maxAttempts) {
      let form = formRef.current

      if (form) {
        const formValues = form.getState().values

        if (formValues && Object.keys(formValues).length > 0) {
          return form // Form is populated
        }
      }

      await new Promise((resolve) => setTimeout(resolve, delay))
      attempts += 1
    }

    // return undefined // Form did not populate in time
    throw new Error('Form not mounted, form action aborted')
  }

  if (setValues) {
    setValues.own(async (values) => {
      let form = await waitForFormToMount()
      for (const key in values) {
        form.registerField(key, () => { }, {})
        form.mutators.updateField(key, values[key])
      }
    })
  }

  if (save) {
    save.own(async () => {
      // wait for give form a chance to mount
      return (await waitForFormToMount()).submit()
    })
  }

  if (discard) {
    discard.own(async () => {
      let form = await waitForFormToMount()
      // Use customised logic, if available
      if (form.mutators.discardChanges) {
        form.mutators.discardChanges(form)
      } else {
        form.reset()
      }
    })
  }

  if (active) {
    active.own()
  }

  if (dirty_fields) {
    dirty_fields.own()
  }

  if (values) {
    values.own()
  }

}

import { StripeCardElementChangeEvent } from '@stripe/stripe-js'
import { transactionSelectors } from 'ducks/transaction'
import { ActionDataType } from 'myenergy/selectionComponent/loanApplicationButton/types'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useField, useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { CustomerActionType } from 'types/customerActions'
import { ProposalDataType } from 'types/proposals'
import StripePaymentElementWrapper from '../../../transactionDialog/StripePaymentElement'
import { STRIPE_PAYMENT_METHOD } from '../../constants'
import { StripeRef } from './StripeRef'

type PropTypes = {
  proposalData: ProposalDataType
}

const CheckoutPayment: React.FC<PropTypes> = (props) => {
  const transactionRequestData = useSelector(transactionSelectors.getTransactionRequestData) as ActionDataType
  // this will be the available action that the user has chosen when/if they change their preferred payment method
  // this should take priority over transactionRequestData since it has been chosen by the user, but we will revert to
  // transactionRequestData if selectedAction is either undefined or missing fields we need
  const [selectedAction, setSelectedAction] = useState<undefined | CustomerActionType>(undefined)

  const translate = useTranslate()
  const form = useForm()
  const formState = useFormState()
  // useFormState was not always resulting in the updated value of selected_payment_method being available in this component
  // but useField ensures this component re-renders when the field changes
  const selectedPaymentMethodField = useField('selected_payment_method')
  StripeRef.ref = useRef(null)

  useEffect(() => {
    const targetPaymentMethod = formState.values.selected_payment_method
    if (props.proposalData?.selectedProject?.available_customer_actions) {
      let actionMatchingPaymentMethod = undefined as undefined | CustomerActionType
      // loop through available actions to find the one that matches the currently selected payment method
      props.proposalData?.selectedProject.available_customer_actions
        .filter((ca) => ca.system_uuid === transactionRequestData?.system_uuid)
        ?.forEach((systemAction) => {
          systemAction?.actions_available?.forEach((action) => {
            if (action.payment_method === targetPaymentMethod) actionMatchingPaymentMethod = action
          })
        })
      if (actionMatchingPaymentMethod) {
        setSelectedAction(actionMatchingPaymentMethod)
      } else if (!targetPaymentMethod && transactionRequestData && transactionRequestData.status_code === 'complete') {
        // if no target payment method is selected and the transactionRequest data is complete then it means the user is viewing
        // a completed statement. To show the offline details we go find the completed customer action
        props.proposalData?.selectedProject.available_customer_actions
          .filter((ca) => ca.system_uuid === transactionRequestData?.system_uuid)
          ?.forEach((systemAction) => {
            systemAction?.actions_available?.forEach((action) => {
              if (action.status_code === 'complete') actionMatchingPaymentMethod = action
            })
          })
        setSelectedAction(actionMatchingPaymentMethod)
      }
    }
  }, [formState])

  const onCardChange = (changeObject: StripeCardElementChangeEvent) => {
    form.change('card_is_complete', !!changeObject.complete)
  }

  if (!transactionRequestData || !selectedAction?.payment_amount) return null
  return (
    <div data-testid="stripe-checkout-wrapper">
      <div>
        <h2>
          {translate('Payment Method')}: {selectedAction?.payment_title || transactionRequestData?.payment_title}
        </h2>
        <p
          style={{ whiteSpace: 'pre-wrap' }}
          dangerouslySetInnerHTML={{
            __html: selectedAction?.payment_content || transactionRequestData.payment_content,
          }}
        />
      </div>
      {transactionRequestData.payment_amount > 0 &&
        selectedAction?.payment_stripe_key &&
        transactionRequestData.payment_method === STRIPE_PAYMENT_METHOD &&
        formState.values?.selected_payment_method === STRIPE_PAYMENT_METHOD && (
          <StripePaymentElementWrapper
            ref={StripeRef.ref}
            showStripe={true}
            payment_stripe_key={selectedAction?.payment_stripe_key}
            onChange={onCardChange}
          />
        )}
    </div>
  )
}
export default CheckoutPayment

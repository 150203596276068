import React from 'react'
import { Grid, Button, Typography, ArrowRightSmIcon, ArrowLeftSmIcon } from 'opensolar-ui'
import { styled } from '@material-ui/core'

interface PaginationProps {
  page: number
  limit: number
  total?: number
  onNexPage: (page: number) => void
  onPreviousPage: (page: number) => void
}

const Container = styled('div')({
  padding: ' 0',
})

const ShopPagination = ({ page, limit, total, onPreviousPage, onNexPage }: PaginationProps) => {
  const hasTotal = total && total > 0

  const pageStart = page > 1 ? (page - 1) * limit : 1
  let pageEnd = pageStart + limit - 1
  pageEnd = hasTotal && pageEnd > total ? total : pageEnd

  return (
    <Container>
      <Grid container justify="space-between" alignContent="center" xs={12}>
        <Grid item xs={6}>
          <Typography>
            Showing <strong>{pageStart}</strong> to <strong>{pageEnd}</strong> of <strong>{total}</strong> results
          </Typography>
        </Grid>

        <Grid item container spacing={1} xs={6} justify="flex-end" alignContent="center" alignItems="center">
          <Grid item>
            <Button
              size="medium"
              variant="contained"
              startIcon={<ArrowLeftSmIcon color="black" />}
              disabled={page <= 1}
              onClick={() => onPreviousPage(page - 1)}
            >
              Previous
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="medium"
              variant="contained"
              endIcon={<ArrowRightSmIcon color="black" />}
              disabled={pageEnd === total}
              onClick={() => onNexPage(page + 1)}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ShopPagination

import { Grid, Paper } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import CustomField from 'elements/field/CustomField'
import DependentInput from 'elements/input/DependentInput'
import React from 'react'
import { BooleanInput, FormDataConsumer, SelectInput, SimpleForm, TextInput, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import ShareabilitySelector from 'resources/connectedOrgs/ShareabilitySelector'
import AutoApplyInputs from '../autoApply/AutoApplyInputs'
import CostAdderInputs from './CostAdderInputs'
import PriceAdderInputs from './PriceAdderInputs'

const ADDER_TYPE_INPUTS = {
  PriceAdderInputs: PriceAdderInputs,
  CostAdderInputs: CostAdderInputs,
}

export const ADDER_FORMULA_CHOICES = [
  { id: 0, name: 'Fixed' },
  { id: 1, name: 'Per Watt' },
  { id: 2, name: 'Per Panel' },
]

export const PRICE_ONLY_ADDER_FORMULA_CHOICES = [
  { id: 0, name: 'Fixed' },
  { id: 1, name: 'Per Watt' },
  { id: 2, name: 'Per Panel' },
  { id: 3, name: 'Price Including Tax (%)' },
  { id: 4, name: 'Price Excluding Tax (%)' },
  { id: 5, name: 'Price Payable Including Tax (%)' },
  { id: 6, name: 'Price Payable Excluding Tax (%)' },
]

export const ADDER_PRICE_TREATMENT_CHOICES = [
  { id: 0, name: 'Solar' },
  { id: 1, name: 'Roofing' },
  { id: 2, name: 'Generic Non-Solar' },
]

export const ADDER_TYPE_CHOICES = [
  { id: 0, name: 'Price', inputs: ['PriceAdderInputs'] },
  { id: 1, name: 'Cost', inputs: ['CostAdderInputs'] },
  { id: 2, name: 'Price & Cost', inputs: ['PriceAdderInputs', 'CostAdderInputs'] },
]

const formatSubmitValues = (values: any) => {
  if (values.type === 0) {
    // type is Price
    // clear cost related fields
    values.cost_value = null
  } else if (values.type === 1) {
    // type is Cost
    // clear price related fields
    values.value = null
    values.tax_included = null
  }
  return values
}

const parseDefaultValues = (record: any) => {
  return record
}
//check the selected option's type(number), if it's the Price, then show PRICE_ONLY_ADDER_FORMULA_CHOICES,
//otherwise, show ADDER_FORMULA_CHOICES
const outputAdderFormulaChoices = (type: number) => {
  if (type === 0) {
    return PRICE_ONLY_ADDER_FORMULA_CHOICES
  } else {
    return ADDER_FORMULA_CHOICES
  }
}

const AddersForm = (props: any) => {
  const translate = useTranslate()
  const isUs = useSelector(orgSelectors.getOrgIso2) === 'US'
  return (
    <SimpleForm formatSubmitValues={formatSubmitValues} defaultValue={parseDefaultValues} {...props}>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <TextInput source="title" label="Title" fullWidth={true} />
        </Grid>
        <Grid item xs={12}>
          <CustomField
            style={{ width: '100%' }}
            name={'auto_apply_enabled'}
            label="Auto-apply"
            component={BooleanInput}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <CustomField
            name={'type'}
            source="type"
            label="Type of adder/discount"
            component={SelectInput}
            choices={ADDER_TYPE_CHOICES}
            fullWidth
            style={{ width: '100%' }}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <FormDataConsumer>
            {({ formData, ...rest }: any) => {
              return (
                <CustomField
                  name={'formula'}
                  source="formula"
                  label="Formula"
                  component={SelectInput}
                  choices={outputAdderFormulaChoices(formData.type)}
                  fullWidth
                  style={{ width: '100%' }}
                  required={true}
                  {...rest}
                />
              )
            }}
          </FormDataConsumer>
        </Grid>

        <DependentInput dependsOn={'formula'} resolve={(value: number) => value === 0}>
          <Grid item xs={12} sm={6} lg={4}>
            <CustomField
              name={'unit_label'}
              source="unit_label"
              label="Unit label (optional)"
              component={TextInput}
              fullWidth
              style={{ width: '100%' }}
            />
          </Grid>
        </DependentInput>
        {isUs && (
          <Grid item xs={12} sm={6} lg={6}>
            <CustomField
              name={'price_treatment'}
              source="price_treatment"
              label="Price Treatment (optional)"
              component={SelectInput}
              choices={ADDER_PRICE_TREATMENT_CHOICES}
              fullWidth
              style={{ width: '100%' }}
              required={false}
            />

            <div className="small">
              {translate(
                'This field is only used to validate the solar $/w for various finance integrations. Your selection here will not affect incentive calculations.'
              )}
            </div>
          </Grid>
        )}
      </Grid>
      <FormDataConsumer>
        {({ formData, ...rest }: any) => {
          const adderConfig = ADDER_TYPE_CHOICES.find((choice) => choice.id === formData.type)
          return (
            <>
              {adderConfig &&
                adderConfig.inputs.map((name: string) => {
                  return (
                    <Paper key={name} style={{ padding: 20, margin: '40px 0' }}>
                      {React.createElement(ADDER_TYPE_INPUTS[name as keyof typeof ADDER_TYPE_INPUTS])}
                    </Paper>
                  )
                })}
            </>
          )
        }}
      </FormDataConsumer>
      <DependentInput dependsOn={'auto_apply_enabled'} resolve={(value: boolean) => !!value}>
        <AutoApplyInputs />
      </DependentInput>
      <ShareabilitySelector dependsOn="type" />
      <div style={{ marginBottom: 40 }} />
    </SimpleForm>
  )
}

export default AddersForm

import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import { useTranslate } from 'ra-core'
import React, { useState } from 'react'
import PromoDialog from 'resources/paymentOptions/financialIntegrations/genericPromoContent/PromoDialog'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { AvailableFinanceIntegrationType } from 'types/orgs'
import IntegrationFilterButtons from '../paymentOptionSelection/IntegrationFilterButtons'

type PropTypes = {
  onClose: () => void
}

const useStyles = makeOpenSolarStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingBottom: '40px',
    paddingTop: '20px',
  },
  titleText: {
    textAlign: 'center',
    fontWeight: 200,
  },
}))

const PromoBannerDialog: React.FC<PropTypes> = (props) => {
  const [showFirstDialog, setShowFirstDialog] = useState<boolean>(true)
  const [showSecondDialog, setShowSecondDialog] = useState<boolean>(false)
  const [selectedIntegration, setSelectedIntegrtaion] = useState<AvailableFinanceIntegrationType | undefined>(undefined)

  const classes = useStyles()
  const translate = useTranslate()

  const goToSecondDialog = (integration: AvailableFinanceIntegrationType) => {
    setShowFirstDialog(false)
    setShowSecondDialog(true)
    setSelectedIntegrtaion(integration)
  }

  const backToFirstdialog = () => {
    setShowSecondDialog(false)
    setShowFirstDialog(true)
    setSelectedIntegrtaion(undefined)
  }

  if (showSecondDialog && selectedIntegration) {
    return (
      <PromoDialog
        isOpen={showSecondDialog}
        integration={selectedIntegration}
        onClose={backToFirstdialog}
        isPaymentsPage={true}
      />
    )
  }
  return (
    <Dialog open={showFirstDialog} onClose={props.onClose} maxWidth="xl">
      <DialogTitle>
        <div className={classes.titleText}>{translate('Activate Finance Integrations')}</div>
      </DialogTitle>
      <DialogContent>
        <div className={classes.content}>
          <p>
            {translate(
              'OpenSolar finance partner integrations unlock the fastest way to sell solar and storage systems. Offer your customers auto-updated rates & products, lender-compliant payment options, and near-instant credit decisions directly from your proposal. To top it off, using our finance integrations helps OpenSolar grow and stay free for all.'
            )}
          </p>
          <p>{translate('Click on a finance integration below to set it up.')}</p>
          <div>
            <IntegrationFilterButtons
              setTargetIntegrations={() => null}
              targetIntegrations={[]}
              onInactiveClick={goToSecondDialog}
              hideFilterText={true}
              isPromo={false}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
export default PromoBannerDialog

import useActivateComponent from 'hooks/components/useActivateComponent'
import useLoadComponentSpecs from 'hooks/components/useLoadComponentSpecs'
import { useNotify, useTranslate } from 'ra-core'
import { useCallback } from 'react'
import { mapComponentTypesV2ToV1 } from 'util/misc'
import type { ComponentLikeType } from '../types'

const useMaybeActivateAndReload = () => {
  const translate = useTranslate()
  const notify = useNotify()
  const activateComponent = useActivateComponent()
  const reloadComponentSpecs = useLoadComponentSpecs()

  const onActivateError = useCallback(() => {
    notify(translate('Error activating component on account.'), 'warning')
  }, [])

  const onActivateSuccess = useCallback(async () => {
    await reloadComponentSpecs() // Refresh the component specs
  }, [])

  return useCallback(async ({ componentsSet }: { componentsSet: ComponentLikeType[] }) => {
    const inactivatedComponents = componentsSet.filter((component) => !component.is_my_list)

    if (inactivatedComponents.length === 0) {
      return
    }

    // Temporary solution until BE build new endpoint to support activating multiple components at once
    const newActivatedComponents = await Promise.all(
      inactivatedComponents.map((component) =>
        activateComponent({ componentType: mapComponentTypesV2ToV1(component.type), componentId: component.model_id })
      )
    ).catch((e) => {
      console.warn('Error activating components:', e)
      return
    })

    if (newActivatedComponents === undefined) {
      onActivateError()
      return
    }

    await onActivateSuccess()
  }, [])
}

export default useMaybeActivateAndReload

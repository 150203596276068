import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import SaveButton from 'elements/proUXButtons/SaveButton'
import React, { FC, useEffect, useState } from 'react'
import { useCreate, useNotify, useTranslate } from 'react-admin'
import { Form, useForm, useFormState } from 'react-final-form'
import { WalletTransactionType } from 'types/wallet'
import StandardWalletTransactionForm from './StandardWalletTransactionForm'
import WalletReversalForm from './WalletReveralForm'

type PropTypes = {
  isOpen: boolean
  close: () => void
  invoiceToReverse?: WalletTransactionType
}

const CreateTransactionDialog: React.FunctionComponent<PropTypes> = (props) => {
  const translate = useTranslate()
  const notify = useNotify()
  const form = useForm()
  const formState = useFormState()

  const { previous_transaction_type, project_title, product_purchased_name, ...keepValues } = formState.values
  const [create, { data, loading, error }] = useCreate('global_wallet_line_items', keepValues)

  const [listenToSaveResult, setListenToSaveResult] = useState<boolean>(false)

  // Hidden fields
  form.registerField('org_id', () => {}, {})
  form.registerField('project_id', () => {}, {})
  form.registerField('product_purchased_id', () => {}, {})
  form.registerField('transaction_to_reverse', () => {}, {})
  form.registerField('units', () => {}, {})

  useEffect(() => {
    if (listenToSaveResult) {
      if (data) {
        notify('Transaction added to org', 'success')
        logAmplitudeEvent('global_wallet_transaction_created', {
          org_id: `${data.org_id}`,
          amount_inc_tax: `${data.amount_inc_tax}`,
          transaction_type: data.transaction_type || 'none',
          notes: data.notes || 'none',
        })
        props.close()
      } else if (error) {
        notify('Unable to add transaction to org', 'warning')
      }
    }
  }, [listenToSaveResult, data, error])

  const onSave = () => {
    /*if (props.invoiceToReverse) {
      let requestBody = {
        org_id: props.invoiceToReverse.org_id,
        transaction_type: 'reversal',
        amount_ex_tax: props.invoiceToReverse ? -1 * props.invoiceToReverse.amount_ex_tax : 0,
        amount_inc_tax: props.invoiceToReverse ? -1 * props.invoiceToReverse.amount_inc_tax : 0,
        tax_combined: props.invoiceToReverse ? -1 * props.invoiceToReverse.tax_combined : 0,
        project_id: props.invoiceToReverse.project_id,
        currency: formState.values?.currency ? formState.values?.currency : props.invoiceToReverse.currency,
        notes: formState.values?.notes,
      }
      const restClientInstance = restClient(window.API_ROOT + '/api')
      restClientInstance('CUSTOM_POST', 'custom', {
        url: 'global_wallet_line_items/',
        data: { ...requestBody },
      })
        .then((response: any) => {
          notify('Your change has been saved', 'success')
          props.close()
        })
        .catch((err: any) => {
          console.log('We were unable to save your change', 'warning')
          console.log('err', err)
        })
    } else {*/
    create()
    setListenToSaveResult(true)
    //}
  }

  return (
    <Dialog open={props.isOpen} onClose={props.close}>
      <DialogTitle>{translate('Create a Wallet Transaction')}</DialogTitle>
      <DialogContent>
        <div style={{ width: '95%' }}>
          <Grid container spacing={1}>
            {props.invoiceToReverse ? <WalletReversalForm /> : <StandardWalletTransactionForm />}
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <div style={{ marginLeft: '20px' }}>
          <ProUXButton label="Cancel" onClick={props.close} type="secondary" />
        </div>
        <div style={{ marginLeft: '20px' }}>
          <SaveButton
            label={props.invoiceToReverse ? 'Issue Reversal' : 'Save'}
            handleSubmit={onSave}
            handleSubmitWithRedirect={onSave}
            onSave={props.close}
            invalid={false}
            saving={loading}
            pristine={true}
          />
        </div>
      </DialogActions>
    </Dialog>
  )
}

type Props = {
  open: boolean
  setOpen: (value: boolean) => void
  initialValues?: Partial<WalletTransactionType>
  invoiceToReverse?: WalletTransactionType
}

export const CreateTransactionDialogWithForm: FC<Props> = ({ open, setOpen, initialValues, invoiceToReverse }) => {
  return (
    <Form
      initialValues={initialValues}
      onSubmit={(e) => console.log('e', e)}
      render={(formProps) => (
        <CreateTransactionDialog
          isOpen={open}
          close={() => setOpen(false)}
          invoiceToReverse={invoiceToReverse}
          {...formProps}
        />
      )}
    />
  )
}

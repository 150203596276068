// @ts-nocheck
import { makeStyles, useTheme } from '@material-ui/core'
import Fade from '@material-ui/core/Fade'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { Form, useForm } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { getIsValidEmail, getRoleIdFromState } from '../../../util/misc'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined'
import ChevronRight from '@material-ui/icons/ChevronRightOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { updateRoleWithGreenLancerToken } from 'ducks/auth_roles'
import GenericButton from 'elements/button/GenericButton'
import Button from 'elements/proUXButtons/ProUXButton'
import restClient from 'restClient'
import { ProjectType } from 'types/projects'
import { RoleType } from 'types/roles'
import GreenLancerAccountForm from './GreenLancerAccountForm'
import GreenLancerServicesTable from './GreenLancerServicesTable'
import { AccountFormToShowType, ThemeType } from './types'

const restClientInstance = restClient(window.API_ROOT + '/api')

const useStyles = makeStyles({
  sectionView: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logosView: {
    backgroundColor: '#ececec',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorMsg: {
    color: 'red',
    textAlign: 'center',
    margin: '10px 0px',
    maxWidth: '66%',
  },
  connectedAccountView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 20px 20px 20px',
  },
  urlView: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
  },
  projectSuccessTextView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
  },
})
type FormFieldsType = {
  email: string
  password?: string
  first_name?: string
  last_name?: string
  phone?: string
}

type PropTypes = {
  dismiss: () => void
  isOpen: boolean
  project: ProjectType
  forceShowConnected?: boolean
  onAccountConnected?: () => void
  onProjectCreated?: () => void
  isIronRidge: boolean
}

const GreenLancer: React.FunctionComponent<PropTypes> = (props) => {
  const proUxForm = useForm()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const [accountFormToShow, setAccountFormToShow] = useState<AccountFormToShowType>(null)
  const [showPasswordNextStep, setShowPasswordNextStep] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string | null>(null)
  const [createProjectError, setCreateProjectError] = useState<string | null>(null)
  const [projectURL, setProjectURL] = useState<string | undefined>(undefined)

  const orgId: number = useSelector((state: any) => {
    return state.auth ? state.auth.org_id : null
  })

  const roleId: number | undefined = useSelector(getRoleIdFromState)

  //select from redux
  const hasValidToken = useSelector((state: any) => {
    if (props.forceShowConnected) return true
    if (state.auth && state.auth.roles && state.auth.roles.length > 0) {
      const role = state.auth.roles.find((role: RoleType) => role.id === roleId)
      //  if it's a child account then we are using our token which from the client's perspective is always valid
      if (role.is_greenlancer_child_account) return true
      // if it's not a child account then we need to check the token expiration date
      const tokenDate = role.greenlancer_token_expiration
      if (!tokenDate) return false
      return moment().isBefore(moment(tokenDate))
    } else return false
  })

  // used to determine whether once the account is connected we should still display the account section. It's useful to keep it right after the connection to make it clear
  const [accountConnectedOnMount, setAccountConnectedOnMount] = useState<boolean>(hasValidToken)
  const [isConnected, setIsConnected] = useState<boolean | undefined>(hasValidToken)

  const projectId: number = props.project?.id

  useEffect(() => {
    logAmplitudeEvent('greenlancer_cta_clicked', {
      page: window.location.hash?.includes('/manage') ? 'Manage Page' : 'Info Page',
      is_ironridge: props.isIronRidge ? 'yes' : 'no',
    })
  }, [])

  useEffect(() => {
    if (hasValidToken && !isConnected) setIsConnected(true)
  }, [hasValidToken])

  const classes = useStyles()
  const translate = useTranslate()
  const theme: ThemeType = useTheme()

  const renderConnectedAccount = () => {
    return (
      <>
        <div className={classes.connectedAccountView}>
          <Fade in={isConnected} timeout={1000}>
            <CheckCircleIcon style={{ color: 'rgb(85, 172, 87)', height: 40, width: 40 }} />
          </Fade>
          <span style={{ marginLeft: '15px' }}>
            {showPasswordNextStep
              ? translate(
                  'Your GreenLancer account has been connected! Check your email inbox for instructions to finalize your account setup.'
                )
              : translate('Your GreenLancer account has been connected')}
          </span>
        </div>
      </>
    )
  }

  const renderAccountButtons = () => {
    return (
      <>
        <div style={{ margin: 20 }}>
          <Button
            type="secondary"
            label={translate('Sign in')}
            loading={loading}
            disabled={loading}
            onClick={() => setAccountFormToShow('existing')}
          />
        </div>
        <div style={{ margin: 20 }}>
          <Button
            type="secondary"
            label={translate('Create account')}
            loading={loading}
            disabled={loading}
            onClick={() => setAccountFormToShow('new')}
          />
        </div>
      </>
    )
  }

  const validateAccountForm = (fields: FormFieldsType) => {
    let error = null

    if (accountFormToShow === 'new') {
      if (!fields.first_name) error = 'First Name is required'
      else if (!fields.last_name) error = 'Last Name is required'
      else if (!fields.email) error = 'Email is required'
      else if (!getIsValidEmail(fields.email)) error = 'Invalid email'
      else if (!fields.phone) error = 'Phone is required'
    } else if (accountFormToShow === 'existing') {
      if (!fields.email) error = 'Email is required'
      else if (!getIsValidEmail(fields.email)) error = 'Invalid email'
      if (!fields.password) error = 'Password is required'
    } else {
      error = 'Unable to connect GreenLancer account'
    }
    return error
  }

  const onAccountFormSubmit = (fields: FormFieldsType) => {
    const error = validateAccountForm(fields)
    if (error) {
      setErrorMsg(error)
      logAmplitudeEvent('greenlancer_account_form_invalid', {
        page: window.location.hash?.includes('/manage') ? 'Manage Page' : 'Info Page',
        is_ironridge: props.isIronRidge ? 'yes' : 'no',
      })
      return
    }
    let url = `orgs/${orgId}/roles/${roleId}/`
    let data = { ...fields }
    if (accountFormToShow === 'new') {
      url += 'create_greenlancer_account/'
    } else if (accountFormToShow === 'existing') {
      url += 'connect_greenlancer_account/'
    }
    setLoading(true)
    restClientInstance('CUSTOM_POST', 'custom', {
      url,
      data,
    })
      .then((res: any) => {
        setErrorMsg(null)
        if (res.data.success) {
          setIsConnected(true)
          if (props.onAccountConnected) props.onAccountConnected()
          if (accountFormToShow === 'new') setShowPasswordNextStep(true)
          if (accountFormToShow === 'new') {
            logAmplitudeEvent('greenlancer_account_created', {
              page: window.location.hash?.includes('/manage') ? 'Manage Page' : 'Info Page',
              is_ironridge: props.isIronRidge ? 'yes' : 'no',
            })
          } else {
            logAmplitudeEvent('greenlancer_account_connected', {
              page: window.location.hash?.includes('/manage') ? 'Manage Page' : 'Info Page',
              is_ironridge: props.isIronRidge ? 'yes' : 'no',
            })
          }
          dispatch(updateRoleWithGreenLancerToken(roleId))
        } else {
          setErrorMsg('Unable to connect GreenLancer account')
        }
      })
      .catch((err: any) => {
        setErrorMsg(err?.body?.error_message || 'Unable to login')
        logAmplitudeEvent('greenlancer_account_connection_error', {
          page: window.location.hash?.includes('/manage') ? 'Manage Page' : 'Info Page',
          is_ironridge: props.isIronRidge ? 'yes' : 'no',
        })
      })
      .finally(() => setLoading(false))
  }

  const renderNoConnection = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
        {accountFormToShow === null ? (
          renderAccountButtons()
        ) : (
          <Form
            onSubmit={onAccountFormSubmit}
            render={(formProps) => (
              <GreenLancerAccountForm
                accountFormToShow={accountFormToShow}
                loading={loading}
                switchMode={(newMode: 'new' | 'existing') => setAccountFormToShow(newMode)}
                {...formProps}
              />
            )}
          ></Form>
        )}
      </div>
    )
  }

  const saveProject = (ids: string[]) => {
    if (!ids || ids.length === 0) {
      setCreateProjectError('Please select at least one service before creating a project')
      return
    }
    let url = `orgs/${orgId}/roles/${roleId}/projects/${projectId}/create_greenlancer_project/`
    setLoading(true)
    restClientInstance('CUSTOM_POST', 'custom', {
      url,
      data: { service_ids: ids },
    })
      .then((res: any) => {
        if (res && res.data && res.data.url) {
          setProjectURL(res.data.url)
          // if this is proUX update form state to show that this project now has a GL id
          if (res.data.greenlancer_project_id) {
            const glProjectId = res.data.greenlancer_project_id
            proUxForm.change('greenlancer_project_id', glProjectId)
            proUxForm.mutators.markFieldAsClean('greenlancer_project_id')
          }
          logAmplitudeEvent('greenlancer_project_created', {
            page: window.location.hash?.includes('/manage') ? 'Manage Page' : 'Info Page',
            is_ironridge: props.isIronRidge ? 'yes' : 'no',
          })
          if (props.onProjectCreated) props.onProjectCreated()
        }
      })
      .catch((err: any) => {
        if (err && err.body && err.body.error_message) {
          if (err.body.show_reconnect) {
            // token either expired or is just invalid. Show the user the login form and ask them to re-connect
            // GL tokens expire after 30 days and there is no ability to progrmatically refresh
            setErrorMsg(err.body.error_message)
            setIsConnected(false)
            setAccountFormToShow('existing')
            logAmplitudeEvent('greenlancer_account_connection_lost', {
              page: window.location.hash?.includes('/manage') ? 'Manage Page' : 'Info Page',
              is_ironridge: props.isIronRidge ? 'yes' : 'no',
            })
          } else {
            setCreateProjectError(err.body.error_message)
            logAmplitudeEvent('greenlancer_project_creation_error', {
              page: window.location.hash?.includes('/manage') ? 'Manage Page' : 'Info Page',
              type: 'handled',
            })
          }
        } else {
          setCreateProjectError('Unable to send project')
          logAmplitudeEvent('greenlancer_project_creation_error', {
            page: window.location.hash?.includes('/manage') ? 'Manage Page' : 'Info Page',
            type: 'unknown',
            is_ironridge: props.isIronRidge ? 'yes' : 'no',
          })
        }
      })
      .finally(() => setLoading(false))
  }

  const navToProject = () => {
    logAmplitudeEvent('greenlancer_view_project_clicked', {
      page: window.location.hash?.includes('/manage') ? 'Manage Page' : 'Info Page',
      type: 'unknown',
    })
    window.open(projectURL)
  }

  return (
    <Dialog onClose={props.dismiss} open={props.isOpen}>
      <DialogTitle id="simple-dialog-title">Send Project to GreenLancer</DialogTitle>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ flex: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {(!isConnected || !accountConnectedOnMount) && (
            <div className={classes.sectionView}>
              <h2>Connect a GreenLancer account</h2>
              {errorMsg && <div className={classes.errorMsg}>{translate(errorMsg)}</div>}
              {isConnected ? renderConnectedAccount() : renderNoConnection()}
            </div>
          )}
          {isConnected && (
            <div className={classes.sectionView}>
              <h2>Select GreenLancer services</h2>
              {isConnected && !projectURL && (
                <GreenLancerServicesTable
                  projectLoading={loading}
                  saveProject={saveProject}
                  accountFormToShow={accountFormToShow}
                  errorMsg={createProjectError}
                  project={props.project}
                  isIronRidge={props.isIronRidge}
                />
              )}
              {projectURL && (
                <div className={classes.urlView}>
                  <div className={classes.projectSuccessTextView}>
                    <Fade in={true} timeout={500}>
                      <CheckCircleIcon style={{ color: 'rgb(85, 172, 87)', height: 40, width: 40 }} />
                    </Fade>
                    <p style={{ width: '80%', textAlign: 'center' }}>
                      Your project has been created on GreenLancer's site. Click the button below to proceed
                    </p>
                  </div>
                  <GenericButton
                    style={{ overflow: 'inherit', width: 250 }}
                    label={translate('View your Project')}
                    name="raEditButton"
                    backgroundColor={theme.themeColor || '#ffda00'}
                    backgroundColorOnHover={theme.themeColorDark || '#F3B800'}
                    labelColor={null}
                    tooltipLabel={null}
                    onClick={navToProject}
                    disabled={loading}
                    endIcon={<ChevronRight />}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Dialog>
  )
}

// if this is in UX2.0 it will be wrapped in a form already. If not it needs to be or else useForm will throw an error and we can't conditionally call useForm
const FormWrapper: React.FunctionComponent = (props: any) => {
  return <GreenLancer {...props} />
}

export default FormWrapper

import { FC } from 'react'
import { SelectInput } from 'react-admin'

interface PropTypes {
  fieldId: string
  source: string
  choices: {
    id: boolean
    name: string
  }[]
  onChange?: (value) => object
}

const BooleanFilter: FC<PropTypes> = ({ source, choices, fieldId, onChange }) => {
  return (
    <SelectInput
      choices={choices}
      label={false}
      source={`${source}.value.${fieldId}`}
      variant="outlined"
      margin={'none'}
      onChange={onChange}
    />
  )
}

export default BooleanFilter

import { Paper } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import type { OpenSolarThemeType } from 'Themes'
import { inventoryFilterSelectors, setCurrentPage, setRowsPerPage } from 'ducks/inventoryFilter'
import { memo, useCallback, useEffect, useState } from 'react'
import { DatagridLoading, PaginationComp } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import type { ForecastDetailDialogController } from './detail/useForecastDetailDialogController'
import ForecastTableContent from './table/ForecastTableContent'
import ForecastTableHeader from './table/ForecastTableHeader'
import type { ForecastedProjectInventory } from './type'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  container: {},
  table: {
    tableLayout: 'fixed',
  },
}))

const ForecastTable = ({
  forecastDetailDialogController,
  forecastedProjectInventoryData,
  loading,
  refreshInventoryDashboard,
}: {
  forecastDetailDialogController: ForecastDetailDialogController
  forecastedProjectInventoryData: ForecastedProjectInventory[] | undefined
  loading: boolean
  refreshInventoryDashboard(): void
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [dataToDisplay, setDataToDisplay] = useState<ForecastedProjectInventory[]>([])
  const [total, setTotal] = useState<number>(0)
  const currentPage = useSelector(inventoryFilterSelectors.getCurrentPage)
  const rowsPerPage = useSelector(inventoryFilterSelectors.getRowsPerPage)

  useEffect(() => {
    if (forecastedProjectInventoryData) {
      setDataToDisplay(getDataToDisplay(forecastedProjectInventoryData))
      setTotal(forecastedProjectInventoryData.length)
    }
  }, [forecastedProjectInventoryData, currentPage, rowsPerPage])

  const getDataToDisplay = useCallback(
    (data): ForecastedProjectInventory[] => {
      const sliceStart = rowsPerPage * (currentPage - 1)
      const sliceEnd = sliceStart + rowsPerPage
      return data.slice(sliceStart, sliceEnd)
    },
    [currentPage, rowsPerPage]
  )

  const handleChangePage = (newPage: number) => {
    dispatch(setCurrentPage(newPage))
  }

  const handleChangeRowsPerPage = (newPerPage: number) => {
    dispatch(setRowsPerPage(newPerPage))
    dispatch(setCurrentPage(0))
  }

  if (loading) {
    return <DatagridLoading hasBulkActions={true} nbChildren={5} />
  }

  if (forecastedProjectInventoryData === undefined) {
    return null //TODO
  }

  return (
    <Paper elevation={1}>
      <Table className={classes.table} size="small">
        <ForecastTableHeader />
        <ForecastTableContent
          forecastDetailDialogController={forecastDetailDialogController}
          refreshInventoryDashboard={refreshInventoryDashboard}
          forecastedProjectInventoryData={dataToDisplay}
        />
      </Table>
      <PaginationComp
        total={total}
        page={currentPage}
        perPage={rowsPerPage}
        setPage={handleChangePage}
        setPerPage={handleChangeRowsPerPage}
        loading={loading}
        rowsPerPageOptions={[5, 10, 25]}
        actions={undefined}
        ids={undefined}
        limit={undefined}
      />
    </Paper>
  )
}

export default memo(ForecastTable)

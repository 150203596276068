// @ts-nocheck
import { useMediaQuery } from '@material-ui/core'
import Fade from '@material-ui/core/Fade'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutlineOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { getAvailableActions } from 'ducks/myEnergy'
import ProUxButton from 'elements/proUXButtons/ProUXButton'
import { Button, Switch } from "opensolar-ui"
import React, { useEffect, useState } from 'react'
import { useNotify } from 'react-admin'
import { useDispatch } from 'react-redux'
import restClient from 'restClient'
import appStorage from 'storage/appStorage'
import { LOANPAL_OR_GOODLEAP } from '../loanApplicationButton/loanpal/constants'
import { StipulationActionType } from './types'

var restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  stipulation: StipulationActionType
  orgId?: number
  closeDialog?: () => void
  showSuccessOnPage?: () => void
  integration?: 'mosaic' | 'loanpal' //only used in page form, myE btn send this data in stip
}

const UploadStipulationForm: React.FunctionComponent<PropTypes> = (props) => {
  const [acceptableDocs, setAcceptableDocs] = useState<any[]>([])
  const [selectedDocType, setSelectedDocType] = useState<string | undefined>(undefined)
  const [files, setFiles] = useState<any>([])
  const [uploading, setUploading] = useState<boolean>(false)

  const notify = useNotify()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery('(max-width:800px)')
  const isMosaic: boolean =
    props?.stipulation?.payment_method === 'mosaic_application' || props.integration === 'mosaic'
  const lenderName = isMosaic ? 'Mosaic' : LOANPAL_OR_GOODLEAP

  useEffect(() => {
    if (selectedDocType) logAmplitudeEvent('stip_doc_type_chosen', { integration: 'loanpal' })
  }, [selectedDocType])

  useEffect(() => {
    if (props.stipulation?.stipulation_acceptable_docs) {
      let docsRaw = props.stipulation?.stipulation_acceptable_docs
      let docsStr = JSON.stringify(docsRaw)
      // need to parse this twice because it can come with characters that don't play nicely with json.parse
      let docsObj = JSON.parse(JSON.parse(docsStr))
      let asArray = Object.keys(docsObj).map((docName: string) => {
        return { name: docName, description: docsObj[docName] }
      })
      setAcceptableDocs(asArray)
    }
  }, [props.stipulation?.stipulation_acceptable_docs])

  const onUpload = (e: any) => {
    let theseFiles = e.target.files
    setFiles([...files, ...theseFiles])
    // doUpload(thisFile)
  }

  const submit = () => {
    if (isMosaic) doMosaicSubmit()
    else doLoanpalSubmit()
  }

  const doLoanpalSubmit = () => {
    setUploading(true)
    const promises = []
    files.forEach((file: any) => {
      promises.push(doUpload(file))
    })
    Promise.all(promises)
      .then((postPromise) => {
        logAmplitudeEvent('stip_upload_success', { integration: 'loanpal', count: files.length })

        onSuccessfulUpload(postPromise ? postPromise[0] : undefined)
        setFiles([])
      })
      .catch((postPromiseErr) => {
        logAmplitudeEvent('stip_upload_fail', { integration: 'loanpal' })
        console.log('err', postPromiseErr)
      })
      .finally(() => setUploading(false))
  }

  const doMosaicSubmit = () => {
    setUploading(true)
    const formData = new FormData()
    formData.append('title', files[0].name)
    formData.append('file_contents', files[0])
    formData.append('stipName', props.stipulation?.stipulation_name)
    fetch(
      `${window.API_ROOT}/api/orgs/${props.stipulation.org_id}/projects/${props.stipulation.project_id}/mosaic/stipulation/`,
      {
        body: formData,
        headers: {
          // "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${appStorage.getToken()}`,
        },
        method: 'post',
      }
    )
      .then((res: any) => {
        if (res?.status === 200) {
          notify('Your documents have been uploaded to ' + lenderName, 'success')
          logAmplitudeEvent('stip_upload_success', { integration: 'mosaic', count: 1 })
          if (props.closeDialog) {
            dispatch(getAvailableActions(props.stipulation.org_id, props.stipulation.project_id))
            props.closeDialog()
          } else if (props.showSuccessOnPage) props.showSuccessOnPage()
        } else {
          notify(
            'We were unable to upload this file. Please contact ' +
            lenderName +
            ' to upload this file directly to them',
            'warning'
          )
          logAmplitudeEvent('stip_upload_fail', { integration: 'mosaic' })
        }
      })
      .catch((err: any) => {
        console.log('err', err)
        notify(
          'We were unable to upload this file. Please contact ' + lenderName + ' to upload this file directly to them',
          'warning'
        )
      })
      .finally(() => setUploading(false))
  }

  const doUpload = (thisFile: any) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('file', thisFile, thisFile.name)
      formData.append('stip_name', props.stipulation.stipulation_name)
      formData.append('file_type', thisFile.type)
      fetch(
        `${window.API_ROOT}/api/orgs/${props.stipulation.org_id}/projects/${props.stipulation.project_id
        }/stipulations/${props.stipulation.stipulation_external_id || 'none'}/loanpal_upload_url/`,
        {
          method: 'POST',
          body: formData,
        }
      )
        .then((response: any) => {
          response.json().then((res: any) => {
            let url = res?.url
            let formData = new FormData()
            formData.append('file', thisFile, res.fileName)
            if (url) {
              fetch(url, {
                method: 'PUT',
                body: thisFile,
                headers: {
                  'x-amz-acl': 'private',
                  'x-amz-server-side-encryption': 'AES256',
                },
              })
                .then((uploadRes) => {
                  console.log('uploadRes', uploadRes)
                  if (uploadRes.status === 200) {
                  }
                  resolve(res.stip_id)
                })
                .catch((err) => {
                  console.log('err', err)
                  reject(false)
                })
            }
          })
        })
        .catch((error) => {
          reject(false)
          console.log('error', error)
        })
    })
  }

  const onSuccessfulUpload = (stipId: string | undefined) => {
    // call out to our server to udpate the stip and mark it as uploaded, then refresh available actions and close the dialog
    restClientInstance('CUSTOM_PUT', 'custom', {
      url: `orgs/${props.stipulation.org_id}/projects/${props.stipulation.project_id}/stipulations/${stipId || props.stipulation.stipulation_external_id
        }/mark_as_uploaded/`,
    })
      .then((res: any) => {
        if (props.closeDialog) {
          dispatch(getAvailableActions(props.stipulation.org_id, props.stipulation.project_id))
          props.closeDialog()
        } else if (props.showSuccessOnPage) props.showSuccessOnPage()
        notify('Your documents have been uploaded to ' + lenderName, 'success')
      })
      .catch((err: any) => {
        console.log('err', err)
      })
  }

  const handleTypeSelection = (doc: any, e: any) => {
    if (e.target.checked) {
      setSelectedDocType(doc.name)
    } else {
      setSelectedDocType(undefined)
    }
  }

  const removeFile = (index: number) => {
    const newFiles = files.filter((file: any, i: number) => i !== index)
    setFiles(newFiles)
  }
  if (!props.stipulation) return null
  return (
    <div>
      {props.stipulation.stipulation_description && <p>{props.stipulation.stipulation_description}</p>}
      <div>
        <h2 style={{ marginBottom: '5px', textAlign: 'center' }}>{props.stipulation.stipulation_name}</h2>
        <p style={{ textAlign: 'center' }}>
          Any of the following documents can be used for {props.stipulation.stipulation_name}. Please click the document
          type you plan on uploading first, then click the upload button
        </p>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          {acceptableDocs.map((doc) => {
            const isSelected = selectedDocType === doc.name

            const hide = !isSelected && !!selectedDocType
            if (hide) return null
            return (
              <Fade in={!hide}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: 7 }}>
                    <strong>{doc.name}</strong>
                    <i>{doc.description}</i>
                  </div>
                  <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                    <Switch onChange={(e) => handleTypeSelection(doc, e)} />
                  </div>
                </div>
              </Fade>
            )
          })}
        </div>
        {selectedDocType && (
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            {files && files.length > 0 && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  marginTop: '20px',
                }}
              >
                {files.map((file, i) => {
                  return (
                    <div
                      key={file.lastModified}
                      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                    >
                      <span style={{ textDecoration: 'underline' }}>{file.name}</span>
                      <div style={{ marginLeft: '15px', cursor: 'pointer' }} onClick={() => removeFile(i)}>
                        <DeleteOutlineIcon />
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'center',
                marginTop: '20px',
              }}
            >
              <div
                style={{
                  margin: '0px 10px',
                  width: isMobile ? '100%' : undefined,
                  marginBottom: isMobile ? '15px' : undefined,
                }}
              >
                <input
                  accept="image/*,application/pdf"
                  style={{ display: 'none' }}
                  id="raised-button-file"
                  onChange={onUpload}
                  disabled={isMosaic && files?.length > 0}
                  multiple={!isMosaic}
                  type="file"
                />
                <label htmlFor="raised-button-file">
                  <Button variant="outlined" component="span" fullWidth disabled={isMosaic && files?.length > 0}>
                    <span>
                      Add <span style={{ textTransform: 'none' }}>{props.stipulation.stipulation_name}</span>{' '}
                      Document(s)
                    </span>
                  </Button>
                </label>
              </div>
              <div
                style={{
                  margin: '0px 10px',
                  width: isMobile ? '100%' : undefined,
                  marginBottom: isMobile ? '15px' : undefined,
                }}
              >
                <ProUxButton
                  type="primary"
                  onClick={submit}
                  label={`Send to ${lenderName}`}
                  disabled={!files || files.length === 0}
                  loading={uploading}
                  fullWidth
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default UploadStipulationForm

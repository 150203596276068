import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orgSelectors } from 'ducks/orgs'
import { useTranslate } from 'ra-core'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import appStorage from 'storage/appStorage'
import { useFeatureFlag } from 'util/split'
import { useGetSortedIntegrations } from '../hooks'
import PaymentOptionCard from './PaymentOptionCard'

type PropTypes = {}

// eventually we'll want to make the contents and the version of this card dynamic, but for now I think there's enough risk that this concept will change
// that we shouldn't spend the time making this dynamic
const GHOST_CARD_VERSION = 1
const STORAGE_KEY = `hide_ghost_card_version_${GHOST_CARD_VERSION}`

const GhostCard: React.FC<PropTypes> = (props) => {
  const [hideCard, setHideCard] = useState<boolean>(!!appStorage.getBool(STORAGE_KEY))
  const availableIntegrations = useSelector(orgSelectors.getAvailableFinanceIntegrations)
  const activeIntegrations = useGetSortedIntegrations(true, false, false, true)
  const isEnabled = useFeatureFlag('finance_ghost_card', 'on')
  const translate = useTranslate()

  const onHide = () => {
    logAmplitudeEvent('payment_ghost_card_dismissed', { version: GHOST_CARD_VERSION })
    appStorage.setBool(STORAGE_KEY, true)
    setHideCard(true)
  }

  if (!availableIntegrations?.length || activeIntegrations === undefined || !!activeIntegrations?.length || !isEnabled)
    return null
  else if (hideCard) return null
  else {
    return (
      <PaymentOptionCard
        paymentType="loan"
        title={translate('SAMPLE - Integrated Payment Option 20 years 2.99%')}
        integration={null}
        monthlyPayment={null}
        ppaPricePerUnit={null}
        paymentFrequency={null}
        paymentPerPeriod={null}
        totalPrice={null}
        upfrontPayment={0}
        term={20}
        interestRate={2.99}
        id={-1}
        isCalculating={false}
        systemUuid={null}
        systemId={null}
        setPmtIdBeingDragged={() => null}
        isGhost={true}
        quotationLines={[]}
        messages={[]}
        disableDrag={true}
        hideGhostCard={onHide}
        interestRateDisclaimer={undefined}
      />
    )
  }
}
export default GhostCard

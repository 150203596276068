import { makeStyles } from '@material-ui/core'
import React from 'react'
import { useTranslate } from 'react-admin'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    borderRadius: 5,
    margin: '20px auto',
    width: 250,
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.54)',
    border: '1px solid rgba(0, 0, 0, 0.3)',
  },
}))

export const NotAvailable = () => {
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <div className={classes.container}>
      <span>{translate('No project statistics yet')}!</span>
      <span>{translate('Start creating projects to view your project statistics')}.</span>
    </div>
  )
}

import { FileQuestionIcon, styled } from 'opensolar-ui'
import Notice from 'projectSections/sections/manage3/common/Notice'
import { useTranslate } from 'ra-core'
import { FC } from 'react'

interface PropTypes {
  filteredSearch?: boolean
}

const StyledSubtext = styled('span')(({ theme }) => ({
  color: theme.palette.grey[500],
  maxWidth: 650,
  lineHeight: '20px',
}))

const EmptyResultsPage: FC<PropTypes> = ({ filteredSearch = false }) => {
  const translate = useTranslate()
  if (filteredSearch)
    return (
      <Notice
        icon={<FileQuestionIcon width={'20px'} height={'20px'} />}
        mainText={translate("We couldn't find any projects that match your filter criteria")}
        subText={
          <StyledSubtext>
            {translate(
              'Try adjusting your filters, checking for any typos, or clearing all filters to see all projects.'
            )}
          </StyledSubtext>
        }
      />
    )
  return (
    <Notice
      icon={<FileQuestionIcon width={'20px'} height={'20px'} />}
      mainText={translate('You have no projects at the moment')}
      subText={
        <StyledSubtext>
          {translate(
            "It looks like you don't have any projects yet. Start your first project by clicking the 'Create Project' button above. If you need help, check out our guide."
          )}
        </StyledSubtext>
      }
    />
  )
}

export default EmptyResultsPage

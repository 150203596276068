//@ts-nocheck
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlined from '@material-ui/icons/CheckCircleOutlined'
import { Chip } from 'opensolar-ui'
import { FC } from 'react'

export const ChipBasic: FC = () => {
  return (
    <>
      {/* SNIPPET START 1 */}
      <Chip label="This is a Chip" />
      {/* SNIPPET END 1 */}
    </>
  )
}

export const ChipColours: FC = () => {
  return (
    <>
      {/* SNIPPET START 2 */}
      <Chip label="Default" color="default" />
      <Chip label="Primary" color="primary" />
      <Chip label="Secondary" color="secondary" />
      <Chip label="Info" color="info" />
      <Chip label="Warning" color="warning" />
      <Chip label="Error" color="error" />
      <Chip label="Success" color="success" />
      {/* SNIPPET END 2 */}
    </>
  )
}

export const ChipSizes: FC = () => {
  return (
    <>
      {/* SNIPPET START 3 */}
      <Chip label="Small" size="small" />
      <Chip label="Medium" size="medium" />
      <Chip label="Small" size="small" variant="rounded" />
      <Chip label="Medium" size="medium" variant="rounded" />
      {/* SNIPPET END 3 */}
    </>
  )
}

export const ChipIcons: FC = () => {
  return (
    <>
      {/* SNIPPET START 4 */}
      <Chip label="Icon" color="success" icon={<CheckCircleOutlined />} />
      <Chip label="Icon" color="success" size="medium" icon={<CheckCircleOutlined />} />

      {/* Must include an onDelete handler for the deleteIcon to show */}
      <Chip label="Delete Icon" color="error" deleteIcon={<CancelOutlined />} onDelete={() => {}} />
      <Chip label="Delete Icon" color="error" size="medium" onDelete={() => {}} />
      {/* SNIPPET END 4 */}
    </>
  )
}

export const ChipVariants: FC = () => {
  return (
    <>
      {/* SNIPPET START 5 */}
      <Chip label="Default" color="info" variant="default" />
      <Chip label="Outlined" color="info" variant="outlined" />
      <Chip label="Rounded" color="info" variant="rounded" />
      {/* SNIPPET END 5 */}
    </>
  )
}

import { Box, Card } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { FieldTitle } from 'react-admin'
import { useResourceStyles } from 'resources/styles'

export const styles = {
  label: {
    minWidth: 100,
    maxWidth: 100,
    marginRight: 10,
  },
  field: {
    display: 'flex',
    minHeight: 45,
    alignItems: 'center',
    color: '#a2a0a0',
  },
}

const OverviewInputs = ({ children, basePath, record, resource, ...props }) => {
  const resourceStyles = useResourceStyles()
  const WrapperComp = props?.noBorder ? Box : Card
  const wrapperClass = props?.noBorder ? null : resourceStyles.formSection
  return (
    <WrapperComp className={wrapperClass}>
      {props.heading && <h2 style={{ color: 'rgb(0,0,0)' }}>{props.heading}</h2>}
      {React.Children.map(
        children,
        (field, index) =>
          field && (
            <div style={field.props.style ? field.props.style : styles.field}>
              {field.props.source && (
                <span style={styles.label}>
                  <FieldTitle label={field.props.label} source={field.props.source} resource={resource} />
                </span>
              )}
              {React.cloneElement(field, {
                record,
                basePath,
                label: false,
                resource,
                style: field.props.inputStyle,
              })}
            </div>
          )
      )}
    </WrapperComp>
  )
}

OverviewInputs.propTypes = {
  basePath: PropTypes.string,
  record: PropTypes.object,
  resource: PropTypes.any,
}

export default OverviewInputs

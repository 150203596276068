import { SelectInputProps } from 'ra-ui-materialui'

export const UNIT_VALUE_CHOICES: SelectInputProps['choices'] = [
  { id: 'none', name: 'Select Unit', disabled: true },
  { id: 'therms', name: 'Therms' },
  { id: 'cubic_feet', name: 'Cubic Feet' },
  { id: 'gj', name: 'GJ' },
  { id: 'mmbtu', name: 'MMBTU' },
  { id: 'btu', name: 'BTU' },
  { id: 'mj', name: 'MJ' },
  { id: 'kwh', name: 'kWh' },
]

export const USAGE_CHOICES: SelectInputProps['choices'] = [
  { id: 'annual_consumption', name: 'Annual Consumption', disabled: true },
]

export const CONVERSION_FACTORS_TO_KWH = {
  kwh: 1,
  // sources: https://www.nrg.com/resources/energy-tools/energy-conversion-calculator.html
  cubic_feet: 0.2931,
  therms: 29.3,
  mmbtu: 293,
  btu: 0.000293,
  gj: 277.78,
  // sources: https://www.eia.gov/energyexplained/units-and-calculators/energy-conversion-calculators.php
  mj: 0.278,
}

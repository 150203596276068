import Button from 'elements/button/Button'
import CustomField from 'elements/field/CustomField'
import { renderInput } from 'pages/auth/common'
import { useNotify, useTranslate } from 'react-admin'
import { Form } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import useHdmService from 'services/hdm/useHdmService'
import { composeValidators, emailFormat, required } from 'validations'

const HdmConnectForm = () => {
  const initialValues = {
    email: '',
  }
  const translate = useTranslate()
  const hdmService = useHdmService()
  const history = useHistory()
  const notify = useNotify()

  const handleSubmit = async (values) => {
    const { email } = values
    const success = await hdmService.verifyEmail(email)
    if (success) {
      history.push('/shop/connect_distributor/hdm/verify_code')
    } else {
      notify('Could not verify HDM customer email', 'warning')
    }
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting }) => {
        return (
          <>
            <CustomField
              name="email"
              type="email"
              validate={composeValidators(required, emailFormat)}
              component={renderInput}
              label={translate('email')}
              fullWidth={true}
              inputProps={{
                id: 'hdm-connect-form-email',
              }}
            />
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              disabled={submitting}
              loading={submitting}
            >
              Connect to HDM
            </Button>
          </>
        )
      }}
    />
  )
}

export default HdmConnectForm

import { cloneDeep } from 'lodash'
import { StudioInverterType } from 'types/global'
import { RootState } from 'types/state'
export const ELECTRICAL_DIALOG = 'ELECTRICAL_DIALOG'

export const setElectricalDialog = (isOpen: boolean, inverter: StudioInverterType | null) => ({
  type: ELECTRICAL_DIALOG,
  payload: {
    isOpen,
    inverter,
  },
})

export type ReduxElectricalStateType = {
  isOpen: boolean
  inverter: StudioInverterType | null
}
const defaultState: ReduxElectricalStateType = {
  isOpen: false,
  inverter: null,
}

type ReduxElectricalArgs = {
  type: string
  payload: any
}

export default (previousState: ReduxElectricalStateType = defaultState, { type, payload }: ReduxElectricalArgs) => {
  switch (type) {
    case ELECTRICAL_DIALOG:
      if (previousState === defaultState) previousState = cloneDeep(previousState)
      previousState = {
        ...previousState,
        isOpen: payload.isOpen,
        inverter: payload.inverter,
      } as ReduxElectricalStateType
      break
  }

  return previousState
}

export const electricalSelectors = {
  getInverter(state: RootState): StudioInverterType | null {
    var inverter
    try {
      inverter = state.electrical.inverter
    } catch (e) {
      inverter = null
    }
    return inverter
  },
  getIsOpen(state: RootState): boolean {
    var isOpen
    try {
      isOpen = Boolean(state.electrical.isOpen)
    } catch (e) {
      isOpen = false
    }
    return isOpen
  },
}

import LineItem from 'pages/ordering/OrderLineItem'

const getGroupedOrders = (lineItems: LineItem[]): LineItem[][] => {
  const groupedOrders: LineItem[][] = []
  // <code, index>
  const hashMapHelper: Record<string, number> = {}
  lineItems.forEach((lineItem: LineItem) => {
    const existingRecords: LineItem[] | undefined =
      hashMapHelper[lineItem.code] != null ? groupedOrders[hashMapHelper[lineItem.code]] : undefined
    if (existingRecords == null) {
      // Create new line item avoid updating original line item
      const groupedLineItem = new LineItem({ ...lineItem })
      // Assign same uuid, need uuid to delete original line item records
      groupedLineItem.uuid = lineItem.uuid
      groupedOrders.push([groupedLineItem])
      hashMapHelper[groupedLineItem.code] = groupedOrders.length - 1
      return
    }
    const sameVariantIdRecord = existingRecords.find((record) => record.variantId === lineItem.variantId)
    if (!sameVariantIdRecord) {
      existingRecords.push(lineItem)
    } else {
      // Aggregate quantity and allocation for same line items with same variant id
      sameVariantIdRecord.quantity += lineItem.quantity
      if (sameVariantIdRecord.projectOrder) {
        sameVariantIdRecord.projectOrder.push(...(lineItem.projectOrder || []))
      } else {
        sameVariantIdRecord.projectOrder = [...(lineItem.projectOrder || [])]
      }
    }
  })
  return groupedOrders
}

export default getGroupedOrders

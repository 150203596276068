import { Box, makeStyles } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import ProjectName from 'elements/ProjectName'
import PreviewInvoiceLink from 'projectSections/sections/payments/cashFlowTransactions/invoiceActions/PreviewInvoiceLink'
import React from 'react'
import { Datagrid, FunctionField, List } from 'react-admin'
import { Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { OpenSolarThemeType } from 'Themes'
import { currencySymbolForCountry, formatCurrencyWithSymbol } from 'util/misc'
import DownloadInvoiceDataButton from './DownloadInvoiceDataButton'
import InvoicesFilter from './InvoicesFilter'
import InvoicesSummarySection from './InvoicesSummarySection'
import InvoiceTableHeader from './InvoicesTableHeader'
import InvoiceStatusChip from './InvoiceStatusChip'
import InvoiceTableActions from './InvoiceTableActions'

type PropTypes = {}

const useStyles = makeStyles<OpenSolarThemeType>((theme) => ({
  projectCell: {
    lineHeight: 1.5,
    margin: '8px 0',
  },
  projectAddress: {
    fontWeight: 'bold',
  },
  contact: {
    fontSize: 'small',
  },
}))

const Invoices: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const countryIso2 = useSelector(orgSelectors.getOrgIso2)
  const currencySymbol = currencySymbolForCountry(countryIso2)

  return (
    <Form
      onSubmit={() => {}}
      render={() => (
        <Box>
          <InvoicesSummarySection />
          <DownloadInvoiceDataButton />
          <List
            hasSearch={true}
            sort={{ field: 'issued_date', order: 'ASC' }}
            perPage={20}
            toolbar={<div></div>}
            filters={<InvoicesFilter />}
            hideBreadCrumbs={true}
            resource="cashflow_invoices"
            basePath="cashflow_invoices"
            id="cashflow_invoices"
            hideTitle={true}
          >
            <Datagrid
              // @ts-ignore
              header={(stuff) => <InvoiceTableHeader />}
            >
              <FunctionField
                source="invoice_number"
                sortable={false}
                label={'Invoice Number'}
                render={(record) => (
                  <PreviewInvoiceLink invoiceDetails={{ id: record.id, invoice_num: record.invoice_number }} />
                )}
              />
              <FunctionField
                source="issued_date"
                sortable={false}
                label={'Project'}
                render={(record) => (
                  <div className={classes.projectCell}>
                    <span className={classes.projectAddress}>
                      <Link to={`/projects/${record?.project?.id}/payments`} target="_blank" rel="noopener noreferrer">
                        <ProjectName address={record?.project?.address} />
                      </Link>
                    </span>
                    <span className={classes.contact}>
                      {record?.contact?.recipient_first_name?.charAt(0)} {record?.contact?.recipient_last_name}
                    </span>
                  </div>
                )}
              />

              <FunctionField
                source="payment_milestone"
                sortable={true}
                label={'Milestone'}
                render={(record) => <div>{record.payment_milestone}</div>}
              />
              <FunctionField
                source="status"
                sortable={true}
                label={'Status'}
                render={(record) => record.status && <InvoiceStatusChip status={record.status} />}
              />

              <FunctionField
                source="issued_date"
                sortable={true}
                label={'Issue Date'}
                render={(record) => (
                  <div>{record?.issued_date && new Date(record?.issued_date).toLocaleDateString()}</div>
                )}
              />
              <FunctionField
                source="due_date"
                sortable={true}
                label={'Due Date'}
                render={(record) => <div>{record?.due_date && new Date(record?.due_date).toLocaleDateString()}</div>}
              />
              <FunctionField
                source="date_paid_out"
                sortable={true}
                label={'Paid Date'}
                render={(record) => (
                  <div>{record?.date_paid_out && new Date(record?.date_paid_out).toLocaleDateString()}</div>
                )}
              />

              <FunctionField
                source="amount_requested"
                sortable={true}
                label={'Amount Invoiced'}
                render={(record) => <div>{formatCurrencyWithSymbol(record.amount_requested, currencySymbol)}</div>}
              />

              <FunctionField
                source="amount_paid_out"
                sortable={true}
                label={'Payment Received'}
                render={(record) => <div>{formatCurrencyWithSymbol(record.amount_paid_out, currencySymbol)}</div>}
              />

              <FunctionField
                source=""
                sortable={false}
                label={'Actions'}
                render={(record) => <InvoiceTableActions record={record} />}
              />
            </Datagrid>
          </List>
        </Box>
      )}
    />
  )
}

export default Invoices

import React, { memo, useEffect } from 'react'

import { Divider, Typography } from '@material-ui/core'
import type { OpenSolarThemeType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { clearLineItems } from 'ducks/orderComponents'
import Button from 'elements/button/Button'
import { useDispatch } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

// This is identical to Segen - we can consider making it common if we start doing it again.
const useDialogContentStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  container: {
    textAlign: 'center',
    padding: '30px 80px',
  },
  divider: {
    margin: '20px 0',
  },
  buttonWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: 50,
  },
  button: {
    margin: '10px 20px',
  },
}))

const { storefront_domain } = window.getCityPlumbingConfig()

const CityPlumbingCheckout = (props) => {
  const classes = useDialogContentStyles()
  const dispatch = useDispatch()

  const jobListId = props?.match?.params?.jobListId

  useEffect(() => {
    logAmplitudeEvent('hardware_order_placed_success', {
      distributor: 'city_plumbing',
      type: 'job_list',
    })
    return () => {
      // clear cart
      dispatch(clearLineItems())
    }
  }, [])

  const handleViewJobListOnCityPlumbingSite = () => {
    const storefront_url = `${storefront_domain}/job-list/${jobListId}`
    window.open(storefront_url)
  }

  return (
    <div className={classes.container}>
      <Typography variant="h3" gutterBottom>
        Quote Complete
      </Typography>
      <Divider className={classes.divider} />
      <Typography variant="body1" gutterBottom>
        Thank you for placing your City Plumbing quote, please use the navigation links below to view your quote
        details, track your order and contact support.
      </Typography>
      <div className={classes.buttonWrapper}>
        <Button color={'primary'} className={classes.button} onClick={() => handleViewJobListOnCityPlumbingSite()}>
          <span>View your quote on the City Plumbing portal</span>
        </Button>
      </div>
    </div>
  )
}

export default memo(CityPlumbingCheckout)

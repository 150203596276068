import { Dialog, DialogTitle, makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import { IconButton } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useState } from 'react'

type PropTypes = {
  url: string | undefined
  contents: string | undefined
  isOpen: boolean
  onClose: () => void
  paymentOptionTitle: string
}

const useStyles = makeStyles(() => ({
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '10px',
    height: '100%',
  },
  titleTopRow: { display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' },
  expandWrapper: { width: '100%', display: 'flex', justifyContent: 'center' },
  expandLink: { marginTop: '10px', cursor: 'pointer', textDecoration: 'underline', color: 'rgb(10, 40, 93)' },
  closeButton: {
    borderRadius: '25px',
    border: '1px solid black',
    cursor: 'pointer',
  },
  iframe: {
    padding: 0,
    border: 0,
    margin: 0,
    width: '100%',
    height: '100%',
    minHeight: '500px',
  },
}))

const ApplicationIframeDialog: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const [isFullScreen, setIsFullScreen] = useState(true)

  if (!props.url && !props.contents) return null
  return (
    <Dialog open={props.isOpen} onClose={props.onClose} fullScreen={isFullScreen} maxWidth="xl">
      <DialogTitle>
        <div className={classes.titleWrapper}>
          <div className={classes.titleTopRow}>
            {`${translate('Apply for Finance')} - ${props.paymentOptionTitle}`}
            <IconButton
              id="application-iframe-close-btn"
              aria-label="close"
              className={classes.closeButton}
              color="primary"
              onClick={props.onClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>

          <div className={classes.expandWrapper}>
            {!isFullScreen && (
              <span className={classes.expandLink} onClick={() => setIsFullScreen(true)}>
                {translate('Expand to Full Screen')}
              </span>
            )}
            {isFullScreen && (
              <span className={classes.expandLink} onClick={() => setIsFullScreen(false)}>
                {translate('Return to Minimized View')}
              </span>
            )}
          </div>
        </div>
      </DialogTitle>
      {props.url && (
        <iframe id="iframe-dialog" className={classes.iframe} src={props.url} allow="camera; microphone"></iframe>
      )}
      {/* @ts-ignore */}
      {props.contents && <div dangerouslySetInnerHTML={{ __html: props.contents }} />}
    </Dialog>
  )
}
export default ApplicationIframeDialog

// @ts-nocheck
import { Divider, Grid, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CustomField from 'elements/field/CustomField'
import CheckboxInput from 'elements/input/CheckboxInput'
import DependentInput from 'elements/input/DependentInput'
import React, { useEffect, useState } from 'react'
import { SelectInput, TextInput, useTranslate } from 'react-admin'
import { useField, useForm } from 'react-final-form'
import { getIsValidEmail } from 'util/misc'
import {
  COBORROWER_PREFIX,
  DAYS_BY_MONTH,
  DURATION_CHOICES,
  EMPLOYMENT_STATUS,
  getYearChoices,
  MONTHS,
  PHONE_TYPES,
  PROPERTY_TYPE_CHOICES,
} from './constants'
import { DisclosureType } from './types'

type ChoiceType = {
  name: string
  id: string
}

type PropTypes = {
  disclosures: DisclosureType
}

const useStyles = makeStyles({
  applicantHeaderView: {},
  applicantHeaderText: {
    fontWeight: 900,
    fontSize: '18px',
  },
  applicantSection: {
    border: '1px solid #ececec',
    borderRadius: '5px',
    padding: '10px',
    width: '100%',
  },
})

const SungageApplicationForm: React.FunctionComponent<PropTypes> = (props) => {
  const primaryDobMonth = useField('dob_month', { subscription: { value: true } }).input.value
  const secondaryDobMonth = useField(`${COBORROWER_PREFIX}dob_month`, { subscription: { value: true } }).input.value

  const getDayChoices = (isPrimary: boolean) => {
    const selectedMonth = isPrimary ? primaryDobMonth : secondaryDobMonth
    return selectedMonth &&
      !isNaN(parseInt(selectedMonth)) &&
      parseInt(selectedMonth) > 0 &&
      parseInt(selectedMonth) < 13
      ? // @ts-ignore
        DAYS_BY_MONTH[selectedMonth]
      : DAYS_BY_MONTH['none']
  }

  const [primaryAppDayChoices, setPrimaryAppDayChoices] = useState<ChoiceType[]>(getDayChoices(true))
  const [secondaryAppDayChoices, setSecondaryAppDayChoices] = useState<ChoiceType[]>(getDayChoices(false))
  const [expandPrimaryTerms, setExpandPrimaryTerms] = useState<boolean>(false)
  const [expandSecondaryTerms, setExpandSecondaryTerms] = useState<boolean>(false)
  const [expandPrimaryEsign, setExpandPrimaryEsign] = useState<boolean>(false)
  const [expandSecondaryEsign, setExpandSecondaryEsign] = useState<boolean>(false)
  const [expandAppTerms, setExpandAppTerms] = useState<boolean>(false)

  useEffect(() => {
    setPrimaryAppDayChoices(getDayChoices(true))
  }, [primaryDobMonth])

  useEffect(() => {
    setSecondaryAppDayChoices(getDayChoices(false))
  }, [secondaryDobMonth])

  const translate = useTranslate()
  const form = useForm()
  const classes = useStyles()
  const isMobile = useMediaQuery('(max-width:900px)')

  const validatePhone = (raw: string, otherFields: any, meta: any) => {
    if (!raw) {
      return 'Phone Number is required'
    } else {
      const justNumbers = raw.replace(/[^0-9]/g, '')
      if (justNumbers.length !== 10) return 'Invalid phone number'
    }
    return undefined
  }

  const validateEmail = (raw: string) => {
    const isValid = getIsValidEmail(raw)
    if (!raw) return 'Required Field'
    else if (!isValid) return 'Invalid Email Address'
    else return undefined
  }

  const parsePhone = (formatted: string) => {
    if (!formatted) return undefined
    else {
      const deletedParens = formatted.length === 4 && formatted.startsWith('(') && !formatted.endsWith(')')
      let justNumbers = formatted.replace(/[^0-9]/g, '')
      if (justNumbers.length > 0) {
        justNumbers = justNumbers.substring(0, 10)
        if (deletedParens) justNumbers = justNumbers.substring(0, 2)
      }
      return justNumbers
    }
  }

  const formatPhone = (phone: string) => {
    if (!phone) return null
    phone = phone.replace(/[^\d]/g, '')

    if (phone.length > 0) {
      let chunk1 = phone.substring(0, 3)
      let chunk2 = phone.substring(3, 6)
      let chunk3 = phone.substring(6)
      if (chunk1 && chunk2 && chunk3) {
        return `(${chunk1}) ${chunk2} - ${chunk3}`
      } else if (chunk1 && chunk2) {
        return `(${chunk1}) ${chunk2}`
      } else if (chunk1 && chunk1.length === 3) {
        return `(${chunk1})`
      } else return chunk1
    }
    return null
  }

  const parseSSN = (formatted: string) => {
    if (!formatted) return undefined
    else {
      let justNumbers = formatted.replace(/[^0-9]/g, '')
      if (justNumbers.length > 0) {
        justNumbers = justNumbers.substring(0, 9)
      }
      return justNumbers
    }
  }

  const formatSSN = (ssn: string) => {
    if (!ssn) return null
    ssn = ssn.replace(/[^\d]/g, '')

    if (ssn.length > 0) {
      let chunk1 = ssn.substring(0, 3)
      let chunk2 = ssn.substring(3, 5)
      let chunk3 = ssn.substring(5)
      if (chunk1 && chunk2 && chunk3) {
        return `${chunk1}-${chunk2}-${chunk3}`
      } else if (chunk1 && chunk2) {
        return `${chunk1}-${chunk2}`
      } else return chunk1
    }
    return null
  }

  const parseIncome = (formatted: string) => {
    if (!formatted) return undefined
    else {
      let justNumbers = formatted.replace(/[^0-9,$]/g, '')
      if (justNumbers.length > 0) {
        justNumbers = justNumbers.substring(0, 9)
      }
      return justNumbers
    }
  }

  const toggleLegalLanguage = (isSecondary: boolean, termsType: 'esign' | 'terms') => {
    if (isSecondary) {
      if (termsType === 'esign') setExpandSecondaryEsign(!expandSecondaryEsign)
      else setExpandSecondaryTerms(!expandSecondaryTerms)
    } else {
      if (termsType === 'esign') setExpandPrimaryEsign(!expandPrimaryEsign)
      else setExpandPrimaryTerms(!expandPrimaryTerms)
    }
  }

  const renderContactFields = (isSecondary: boolean) => {
    const fieldPrefix = isSecondary ? COBORROWER_PREFIX : ''

    return (
      <div className={classes.applicantSection}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div className={classes.applicantHeaderView}>
              <span className={classes.applicantHeaderText}>
                {isSecondary ? 'Co-Borrower Information' : 'Primary Borrower Information'}
              </span>
            </div>
          </Grid>
          <Grid item xs={6}>
            <CustomField
              name={`${fieldPrefix}first_name`}
              source="first_name"
              component={TextInput}
              fullWidth
              style={{ width: '100%' }}
              required={true}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomField
              name={`${fieldPrefix}family_name`}
              source="family_name"
              component={TextInput}
              fullWidth
              style={{ width: '100%' }}
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <CustomField
              name={`${fieldPrefix}phone_number`}
              source="phone_number"
              label={translate('Phone Number')}
              component={TextInput}
              fullWidth
              style={{ width: '100%' }}
              validateOnBlur={validatePhone}
              parse={parsePhone}
              format={formatPhone}
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CustomField
              name={`${fieldPrefix}phone_type`}
              source="phone_type"
              label={translate('Phone Type')}
              component={SelectInput}
              choices={PHONE_TYPES}
              fullWidth
              style={{ width: '100%' }}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <span style={{ fontWeight: 900 }}>Date of Birth</span>
              <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ flex: 1, padding: '0px 4px' }}>
                  <CustomField
                    name={`${fieldPrefix}dob_month`}
                    source="dob_month"
                    label={translate('Month')}
                    component={SelectInput}
                    choices={MONTHS}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                  />
                </div>
                <div style={{ flex: 1, padding: '0px 4px' }}>
                  <CustomField
                    name={`${fieldPrefix}dob_day`}
                    source="dob_day"
                    label={translate('Day')}
                    component={SelectInput}
                    choices={isSecondary ? secondaryAppDayChoices : primaryAppDayChoices}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                    // parse={parseDate}
                  />
                </div>
                <div style={{ flex: 1, padding: '0px 4px' }}>
                  <CustomField
                    name={`${fieldPrefix}dob_year`}
                    source="dob_year"
                    label={translate('Year')}
                    component={SelectInput}
                    choices={getYearChoices()}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                    // parse={parseDate}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomField
              name={`${fieldPrefix}ssn`}
              source="ssn"
              label={translate('Social Security Number')}
              component={TextInput}
              fullWidth
              style={{ width: '100%' }}
              parse={parseSSN}
              format={formatSSN}
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomField
              name={`${fieldPrefix}email`}
              source="email"
              label={translate('Email Address')}
              component={TextInput}
              fullWidth
              style={{ width: '100%' }}
              required={true}
              validateOnBlur={validateEmail}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomField
              name={`${fieldPrefix}income`}
              source="income"
              label={translate('Pre-Tax Annual Income')}
              component={TextInput}
              fullWidth
              parse={parseIncome}
              style={{ width: '100%' }}
              required={true}
            />
            <span className="small">{props.disclosures.incomePrompt}</span>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomField
              name={`${fieldPrefix}employment_status`}
              source="employment_status"
              label={translate('Employment Status')}
              component={SelectInput}
              choices={EMPLOYMENT_STATUS}
              fullWidth
              style={{ width: '100%' }}
              required={true}
            />
          </Grid>
          <DependentInput
            dependsOn={`${fieldPrefix}employment_status`}
            resolve={(val: string) =>
              val !== 'Unemployed' && val !== 'Retired' && val !== 'Retired Military' && val !== 'Homemaker'
            }
          >
            <>
              <Grid item xs={6} md={4}>
                <CustomField
                  name={`${fieldPrefix}employer`}
                  source="employer"
                  label={translate('Employer')}
                  component={TextInput}
                  fullWidth
                  style={{ width: '100%' }}
                  required={false}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <CustomField
                  name={`${fieldPrefix}occupation`}
                  source="occupation"
                  label={translate('Job Title')}
                  component={TextInput}
                  fullWidth
                  style={{ width: '100%' }}
                  required={false}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomField
                  name={`${fieldPrefix}employment_duration`}
                  source="employment_duration"
                  label={translate('Employment Duration')}
                  component={SelectInput}
                  choices={DURATION_CHOICES}
                  fullWidth
                  style={{ width: '100%' }}
                  required={true}
                />
              </Grid>
            </>
          </DependentInput>
          <Grid item xs={12}>
            <CustomField
              name={`${fieldPrefix}owns_property`}
              source="owns_property"
              label={'I own the property where the solar system will be installed'}
              component={(checkboxProps: any) => <CheckboxInput {...checkboxProps} elStyle={{ maxWidth: '900px' }} />}
              fullWidth
              required
              style={{ width: '100%' }}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomField
              name={`${fieldPrefix}e_sign`}
              source="e_sign"
              label={
                <p>
                  I AGREE to the terms about electronic communication{' '}
                  <a style={{ textDecoration: 'underline' }} onClick={() => toggleLegalLanguage(isSecondary, 'esign')}>
                    terms and conditions
                  </a>
                </p>
              }
              component={(checkboxProps: any) => <CheckboxInput {...checkboxProps} elStyle={{ maxWidth: '900px' }} />}
              fullWidth
              required
              style={{ width: '100%' }}
            />
            {(isSecondary && expandSecondaryEsign) ||
              (!isSecondary && expandPrimaryEsign && (
                <div
                  className="small"
                  dangerouslySetInnerHTML={{
                    __html: props.disclosures.electronicCommunications.replace(
                      '{EMAIL}',
                      form.getState().values[`${fieldPrefix}email`]
                    ),
                  }}
                />
              ))}
          </Grid>
          <Grid item xs={12}>
            <CustomField
              name={`${fieldPrefix}terms`}
              source="terms"
              label={
                <p>
                  I understand and agree that by continuing I am providing{' '}
                  <a style={{ textDecoration: 'underline' }} onClick={() => toggleLegalLanguage(isSecondary, 'terms')}>
                    written instructions
                  </a>{' '}
                  to Sungage Financial, Inc. to obtain credit reports about me, and a co-applicant if applicable, from
                  one or more consumer reporting agencies to (1) check if I/we prequalify for financing and (2) verify
                  my/our identity and the information in the application.
                </p>
              }
              component={(checkboxProps: any) => <CheckboxInput {...checkboxProps} elStyle={{ maxWidth: '900px' }} />}
              fullWidth
              required
              style={{ width: '100%' }}
            />
            {(isSecondary && expandSecondaryTerms) ||
              (!isSecondary && expandPrimaryTerms && (
                <div className="small" dangerouslySetInnerHTML={{ __html: props.disclosures.termsAndConditions }} />
              ))}
          </Grid>
          <Divider />
        </Grid>
      </div>
    )
  }
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={`${window.PUBLIC_URL}/images/sungage-logo-wide.png`} alt="" height={isMobile ? 45 : 90} />
        </Grid>
        {renderContactFields(false)}
        <Grid item xs={12} style={{ marginTop: '20px' }}>
          <CustomField
            name="has_coborrower"
            source="has_coborrower"
            label={translate('Add Co-Borrower')}
            component={CheckboxInput}
            fullWidth
            style={{ width: '100%' }}
          />
        </Grid>
        <DependentInput dependsOn="has_coborrower" value={true}>
          <>{renderContactFields(true)}</>
        </DependentInput>
        <h4 style={{ maragin: '20px 0px 0px 0px' }}>Property Information</h4>
        <div className={classes.applicantSection}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <CustomField
                name="address"
                source="address"
                label={translate('Street (no P.O. Boxes)')}
                component={TextInput}
                fullWidth
                style={{ width: '100%' }}
                required={true}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomField
                name="locality"
                source="locality"
                label={translate('City')}
                component={TextInput}
                fullWidth
                style={{ width: '100%' }}
                required={true}
              />
            </Grid>
            <Grid item xs={3}>
              <CustomField
                name="state"
                source="state"
                component={TextInput}
                fullWidth
                style={{ width: '100%' }}
                required={true}
              />
            </Grid>
            <Grid item xs={3}>
              <CustomField
                name="zip"
                source="zip"
                component={TextInput}
                fullWidth
                style={{ width: '100%' }}
                required={true}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomField
                name="has_reverse_mortgage"
                source="has_reverse_mortgage"
                label={'This property has an active reverse mortgage'}
                component={(checkboxProps: any) => <CheckboxInput {...checkboxProps} elStyle={{ maxWidth: '900px' }} />}
                fullWidth
                required
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomField
                name="property_type"
                source="property_type"
                label={'Property Type'}
                component={SelectInput}
                choices={PROPERTY_TYPE_CHOICES}
                fullWidth
                required
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomField
                name="residence_type"
                source="residence_type"
                label={translate('Residence Type')}
                component={SelectInput}
                choices={[
                  { name: translate('Primary Residence'), id: 'Primary' },
                  { name: translate('Second Home'), id: 'Secondary' },
                  { name: translate('Rental'), id: 'Rental' },
                ]}
                fullWidth
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomField
                name="years_at_address"
                source="years_at_address"
                label={translate('Years at Address')}
                component={SelectInput}
                choices={DURATION_CHOICES}
                fullWidth
                style={{ width: '100%' }}
                required={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomField
                name="mortgage_pmt"
                source="mortgage_pmt"
                label={translate('Monthly Mortgage Payment')}
                component={TextInput}
                parse={parseIncome}
                fullWidth
                style={{ width: '100%' }}
                required={true}
              />
            </Grid>
            <DependentInput dependsOn="residence_type" resolve={(val: string) => !!val && val !== 'Primary'}>
              <>
                <Grid item xs={12}>
                  <CustomField
                    name="primary_address"
                    source="primary_address"
                    label={translate('Primary Address Street (no P.O. Boxes)')}
                    component={TextInput}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomField
                    name="primary_locality"
                    source="primary_locality"
                    label={translate('Primary Address City')}
                    component={TextInput}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomField
                    name="primary_state"
                    source="primary_state"
                    label={translate('Primary Address State')}
                    component={TextInput}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomField
                    name="primary_zip"
                    source="primary_zip"
                    label={translate('Primary Address Zip')}
                    component={TextInput}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                  />
                </Grid>
              </>
            </DependentInput>
            <Grid item xs={12}>
              <CustomField
                name="has_mailing_address"
                source="has_mailing_address"
                label={translate('I prefer to receive my mail at a different address')}
                component={(checkboxProps: any) => <CheckboxInput {...checkboxProps} elStyle={{ maxWidth: '900px' }} />}
                fullWidth
                required
                style={{ width: '100%' }}
              />
            </Grid>
            <DependentInput dependsOn="has_mailing_address" value={true}>
              <>
                <Grid item xs={12}>
                  <CustomField
                    name="mailing_address"
                    source="mailing_address"
                    label={translate('Mailing Address Street (no P.O. Boxes)')}
                    component={TextInput}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomField
                    name="mailing_locality"
                    source="mailing_locality"
                    label={translate('Mailing Address City')}
                    component={TextInput}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomField
                    name="mailing_state"
                    source="mailing_state"
                    label={translate('Mailing Address State')}
                    component={TextInput}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomField
                    name="mailing_zip"
                    source="mailing_zip"
                    label={translate('Mailing Address Zip')}
                    component={TextInput}
                    fullWidth
                    style={{ width: '100%' }}
                    required={true}
                  />
                </Grid>
              </>
            </DependentInput>
          </Grid>
        </div>
        <Grid item xs={12}>
          <CustomField
            name="app_terms"
            source="app_terms"
            label={
              <p>
                By submitting this application, each applicant named in this application agrees to the{' '}
                <a style={{ textDecoration: 'underline' }} onClick={() => setExpandAppTerms(!expandAppTerms)}>
                  Terms and Conditions and the Terms of the Disclosures
                </a>
              </p>
            }
            component={(checkboxProps: any) => <CheckboxInput {...checkboxProps} elStyle={{ maxWidth: '900px' }} />}
            fullWidth
            required
            style={{ width: '100%' }}
          />
        </Grid>
        {expandAppTerms && (
          <Grid item xs={12}>
            <div className="small" dangerouslySetInnerHTML={{ __html: props.disclosures.creditAuthorization }} />
            <div className="small" dangerouslySetInnerHTML={{ __html: props.disclosures.disclosures }} />
          </Grid>
        )}
      </Grid>
    </div>
  )
}
export default SungageApplicationForm

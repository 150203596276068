import { makeStyles } from '@material-ui/core'
import { changeTheme } from 'ducks/theme'
import { Button } from 'opensolar-ui'
import { useTranslate } from 'react-admin'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import appStorage from 'storage/appStorage'
import AuthModalWrapper from './AuthModalWrapper'
import { NearmapLogo } from './common'

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    width: 280,
    marginTop: 0,
    minWidth: 210,
    padding: '0.5em 1em 1em 1em',
  },
  button: {
    color: theme.headerFontColor,
    background: theme.themeColor,
    '&:hover': {
      cursor: 'pointer',
      background: theme.themeColorDark,
    },
  },
}))

const LoginNearmapProductionNotProvisioned = () => {
  const translate = useTranslate()
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  return (
    <AuthModalWrapper>
      <div className={classes.wrapper}>
        <div style={{ textAlign: 'center' }}>
          <NearmapLogo />
        </div>
        <p>{translate('Please contact Nearmap about linking your Nearmap Subscription to Nearmap on OpenSolar.')}</p>
        <div style={{ marginBottom: 10 }}>
          <Button
            classes={{ root: classes.button }}
            onClick={(e) => {
              e.preventDefault()
              window.open('https://support.nearmap.com/hc/en-us/requests/new', '_blank')
            }}
          >
            <span style={{ textTransform: 'none' }}>{translate('Contact Nearmap support')}</span>
          </Button>
        </div>
        <p>
          <a
            href="#"
            style={{
              color: '#4D4D4D',
              whiteSpace: 'nowrap',
            }}
            onClick={(e) => {
              e.preventDefault()
              appStorage.clear('nearmap_token')
              dispatch(changeTheme('opensolar'))
              history.push({ pathname: '/login' })
            }}
          >
            {translate('Login to OpenSolar without Nearmap')}
          </a>
        </p>
      </div>
    </AuthModalWrapper>
  )
}
export default LoginNearmapProductionNotProvisioned

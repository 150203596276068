import { makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
  },
}))

export const ButtonsRow: React.FC = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.row}>{children}</div>
}

import { useTranslate } from 'ra-core'

import React from 'react'
import { ModulePropTypes, ModuleUnorderedList } from '../types'

export const IAPModule_UnorderedList: React.FC<ModulePropTypes<ModuleUnorderedList>> = ({
  module,
  translationSubs,
}) => {
  const translate = useTranslate()

  if (!module.msgs?.length) return null
  return (
    <ul>
      {module.msgs.map((s, i) => {
        return <li key={i}>{translate(s, translationSubs)}</li>
      })}
    </ul>
  )
}

import LoadingDots from 'layout/widgets/LoadingDots'
import { ArrowLeftSmIcon, ArrowRightSmIcon, IconButton, styled } from 'opensolar-ui'
import React, { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'

const PdfRendererWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  overflow: 'hidden',
})

const Canvas = styled('canvas')({
  maxHeight: '100%',
  maxWidth: '100%',
})

const PageNavbar = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 10,
  bottom: 0,
  backgroundColor: theme.palette.grey[100],
}))

type PropTypes = {
  pdfUrl: string
}

export const PdfRenderer: React.FunctionComponent<PropTypes> = (props) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [docLoading, setDocLoading] = useState<boolean>(false)
  const [libraryLoading, setLibraryLoading] = useState<boolean>(false)

  const [pdfObj, setPdfObj] = useState<any>(undefined)
  const [pageNum, setPageNum] = useState<number>(0)
  const [pageCount, setPageCount] = useState<number | undefined>(undefined)

  const translate = useTranslate()

  useEffect(() => {
    let script: HTMLScriptElement | null = null
    const loadPdfJs = () => {
      setLibraryLoading(true)
      const minUrl = 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.7.570/build/pdf'
      script = document.createElement('script')
      script.addEventListener('load', () => {
        window['pdfjsLib'] = window['pdfjs-dist/build/pdf']
        window['pdfjsLib'].GlobalWorkerOptions.workerSrc = `${minUrl}.worker.min.js`
        setLibraryLoading(false)
      })
      script.addEventListener('error', () => {
        console.error('Failed to load pdf.js library')
        setLibraryLoading(false)
      })
      script.src = `${minUrl}.min.js`
      script.id = 'temp-target'
      document.head.appendChild(script)
    }
    if (!window['pdfjsLib']) {
      loadPdfJs()
    } else {
      setLibraryLoading(false)
    }
    return () => {
      if (script) {
        script.remove()
      }
    }
  }, [])

  useEffect(() => {
    setLoading(libraryLoading || docLoading)
  }, [libraryLoading, docLoading])

  useEffect(() => {
    let didCancel = false
    if (!libraryLoading && props.pdfUrl) {
      setDocLoading(true)
      const checkPdfJsLib = () => {
        const pdfjsLib = window['pdfjsLib']
        if (pdfjsLib && pdfjsLib.getDocument) {
          const loadingTask = pdfjsLib.getDocument(props.pdfUrl)
          loadingTask.promise.then(
            (pdf: any) => {
              if (!didCancel) {
                setPdfObj(pdf)
                setPageCount(pdf.numPages)
                setPageNum(1)
                setDocLoading(false)
              }
            },
            (reason: any) => {
              if (!didCancel) {
                console.error('PDF loading error:', reason)
                setDocLoading(false)
              }
            }
          )
        } else {
          setTimeout(checkPdfJsLib, 100)
        }
      }
      checkPdfJsLib()
    }
    return () => {
      didCancel = true
    }
  }, [libraryLoading, props.pdfUrl])

  useEffect(() => {
    if (pdfObj && pageNum) loadPage()
  }, [pdfObj, pageNum])

  const loadPage = () => {
    const pageNumber = pageNum
    setDocLoading(true)
    pdfObj.getPage(pageNumber).then((page: any) => {
      const scale = 1
      const viewport = page.getViewport({ scale })
      const canvas = document.getElementById('pdf-target') as HTMLCanvasElement
      if (canvas) {
        const context = canvas.getContext('2d')
        if (context) {
          canvas.height = viewport.height
          canvas.width = viewport.width
          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          }
          const renderTask = page.render(renderContext)
          renderTask.promise.then(() => {
            setDocLoading(false)
          })
        } else {
          setDocLoading(false)
          console.error('Failed to get canvas context')
        }
      } else {
        setDocLoading(false)
        console.error('Canvas element not found')
      }
    })
  }

  return (
    <PdfRendererWrapper>
      <Canvas id="pdf-target" />
      {!loading && (
        <PageNavbar>
          <IconButton onClick={() => setPageNum(Math.max(1, pageNum - 1))} disabled={pageNum <= 1}>
            <ArrowLeftSmIcon />
          </IconButton>

          <span>{translate('Page %{pageNum} of %{pageCount}', { pageNum, pageCount })}</span>
          <IconButton
            onClick={() => setPageNum(Math.min(pageNum + 1, pageCount || pageNum))}
            disabled={pageNum >= (pageCount || 1)}
          >
            <ArrowRightSmIcon />
          </IconButton>
        </PageNavbar>
      )}
      {loading && <LoadingDots />}
    </PdfRendererWrapper>
  )
}

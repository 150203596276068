import { makeStyles } from '@material-ui/core'
import { useTranslate } from 'react-admin'

import lodash from 'lodash'
import { filterShareMessage } from 'projectSections/errors/filterShareMessage'
import { SHARE_FIELD_LABELS } from 'projectSections/form/fields'
import React from 'react'
import { EntityErrorInfo, SharingErrorMsg, SharingErrorType } from 'reducer/project/projectErrorsReducer'
import EntityError from './EntityError'

const useStyles = makeStyles({
  itemList: {
    fontSize: 12,
  },
})

const SharingError = ({
  error,
  sharingSection,
  connectedOrg,
}: {
  error: SharingErrorType
  sharingSection?: string
  connectedOrg: string | undefined
}) => {
  const translate = useTranslate()
  return Object.keys(error).includes('tariffs') ? (
    <>{translate(error['tariffs'])}</>
  ) : (
    <>
      {translate('There are saved items on this page that have not been shared to ') + connectedOrg}:
      <ShareWarning warnings={filterShareMessage(error.message, sharingSection)} />
    </>
  )
}

const ShareWarning = ({
  warnings,
}: {
  warnings?:
    | SharingErrorMsg
    | EntityErrorInfo
    | EntityErrorInfo[]
    | { [key: string]: EntityErrorInfo[] | EntityErrorInfo }
}) => {
  const classes = useStyles()
  const translate = useTranslate()

  if (!warnings) return <></>

  if (Array.isArray(warnings)) {
    return (
      <>
        {warnings.map((value, index) => (
          <>
            <ShareWarning warnings={value} key={index} />
          </>
        ))}
      </>
    )
  } else if (warnings.hasOwnProperty('url')) {
    const warningCast: EntityErrorInfo = warnings as EntityErrorInfo
    return <EntityError error={warningCast} />
  } else {
    return (
      <ul className={classes.itemList}>
        {lodash.map(warnings, (value, key) => {
          if (!value) return null
          if (Array.isArray(value)) {
            if ((value as unknown[]).length === 0) return null
          }
          return (
            <li>
              {translate(SHARE_FIELD_LABELS[key])}:&nbsp;
              {key === 'tariffs' ? translate(value) : <ShareWarning warnings={value} key={key} />}
            </li>
          )
        })}
      </ul>
    )
  }
}

export default SharingError

import { StreetviewOutlined } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/AddOutlined'
import BrightnessIcon from '@material-ui/icons/Brightness4Outlined'
import PanMapIcon from '@material-ui/icons/CenterFocusStrongOutlined'
import RemoveIcon from '@material-ui/icons/RemoveOutlined'
import { orgSelectors } from 'ducks/orgs'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { useStudioSignalsLazy } from 'Studio/signals/useStudioSignalsLazy'
import Utils from 'Studio/Utils'
import { ProjectType } from 'types/projects'
import { openStreetView } from '../../util/openStreetView'
import { zoomIn } from '../../util/zoomIn'
import { zoomOut } from '../../util/zoomOut'
import { useStyles } from './ToolbarWidgets'
import ToolButton from './ToolButton'

type PropTypes = {
  hideAlign?: boolean
  hideStreeView?: boolean
}

const getUseHeatmap = () => {
  const terrain = window.editor.getTerrain()
  return terrain && terrain.useHeatmap()
}

const ViewControllers: React.FC<PropTypes> = ({ hideAlign, hideStreeView }) => {
  const [controlMode, setControlMode] = useState(window.Designer.controlMode)
  const [is2DImagery, setIs2DImagery] = useState(window.MapData?.is2D(window.MapHelper?.activeMapInstance?.mapData))
  const [is3DImagery, setIs3DImagery] = useState(window.MapData?.is3D(window.MapHelper?.activeMapInstance?.mapData))
  const [useHeatmap, setUseHeatmap] = useState(getUseHeatmap())
  const translate = useTranslate()
  const classes = useStyles()
  const refreshState = useCallback(() => {
    setIs2DImagery(window.MapData?.is2D(window.MapHelper?.activeMapInstance?.mapData))
    setIs3DImagery(window.MapData?.is3D(window.MapHelper?.activeMapInstance?.mapData))
    setUseHeatmap(getUseHeatmap())
  }, [])

  const enableAutoDesign = useSelector(orgSelectors.getEnableAutoDesign);

  useStudioSignalsLazy(refreshState, ['viewsChanged'])

  useStudioSignalsLazy(() => {
    setControlMode(window.Designer.controlMode)
  }, ['controlModeChanged'])

  const zoomDisabled = useMemo(
    () =>
      window.Designer.controlMode === 'map' &&
      window.MapHelper &&
      window.MapHelper.activeMapInstance &&
      window.MapHelper.activeMapInstance.mapData.mapType !== 'Image',
    []
  )

  const form = useForm()
  const project = form.getState().values as ProjectType

  return (
    <>

      {is3DImagery && (
        <ToolButton
          id="ViewShadeButton"
          title={translate('View Shade')}
          onClick={() => {
            // We must create a new reference to terrain which may be cached at top level by react
            const _terrain = window.editor.getTerrain()
            if (_terrain) {
              _terrain.useHeatmap(!useHeatmap)
              window.editor.signals.viewsChanged.dispatch()
            }
          }}
          selected={useHeatmap}
          uiKey="studio.view_controls.ViewShadeButton"
        >
          <BrightnessIcon classes={{ root: classes.icon }} />
        </ToolButton>
      )}
      {!hideAlign && (
        <ToolButton
          id="AlignMapButton"
          title={translate('Align Map') + (Utils.iOS() ? '' : ' (A)')}
          onClick={() => {
            if (controlMode !== 'map') {
              window.Designer.changeControl('map')
            } else {
              window.Designer.changeControl('both')
            }

            //hide tool tips
            if (!window.ViewHelper.selectedView().isAligned) {
              window.ViewHelper.selectedView().isAligned = true
              window.ViewHelper.saveView()
            }
          }}
          selected={controlMode === 'map'}
          disabled={!is2DImagery}
          uiKey="studio.view_controls.AlignMapButton"
        >
          <PanMapIcon classes={{ root: classes.icon }} />
        </ToolButton>
      )}
      {!hideStreeView && (
        <ToolButton
          id="StreetViewButton"
          title={translate('Street View')}
          onClick={() => {
            openStreetView(project)
          }}
          uiKey="studio.view_controls.StreetViewButton"
        >
          <StreetviewOutlined classes={{ root: classes.icon }} />
        </ToolButton>
      )}
      <ToolButton id="ZoomOutButton" title={translate('Zoom Out')} onClick={zoomOut} disabled={zoomDisabled} uiKey="studio.view_controls.ZoomOutButton">
        <RemoveIcon classes={{ root: classes.icon }} />
      </ToolButton>

      <ToolButton id="ZoomInButton" title={translate('Zoom In')} onClick={zoomIn} disabled={zoomDisabled} uiKey="studio.view_controls.ZoomInButton">
        <AddIcon classes={{ root: classes.icon }} />
      </ToolButton>
    </>
  )
}

export default ViewControllers

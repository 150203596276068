export const SET_Quick_Start_ACTION = 'SET_Quick_Start_ACTION'

export const setQuickStartAction = (payload) => ({
  type: SET_Quick_Start_ACTION,
  payload,
})

export default function reducer(previousState = { finished: false, stepIndex: 0 }, { type, payload }) {
  if (type === 'SET_Quick_Start_ACTION') {
    return payload
  }
  return previousState
}

import { useMemo } from 'react'
import { useDispatch, useStore } from 'react-redux'
import restClient from 'restClient'
import ProjectOrderPresenter from './projectOrderPresenter'

const useProjectOrderPresenter = () => {
  const store = useStore()
  const dispatch = useDispatch()
  const presenter = useMemo(() => new ProjectOrderPresenter(store, dispatch, restClient(window.API_ROOT + '/api')), [
    store,
    dispatch,
  ])
  return presenter
}

export default useProjectOrderPresenter

// @ts-nocheck
import { EditLogo } from 'persistentContent/elements/UploadLogoDialog'
import React from 'react'
import CustomizedToolbar from './CustomizedToolbar'

const UploadLogo = (props: any) => {
  const { onDismiss, onSuccess, onFailure, onDelete, ...rest } = props
  return (
    <EditLogo
      toolbar={
        <CustomizedToolbar onDismiss={onDismiss} onSuccess={onSuccess} onFailure={onFailure} onDelete={onDelete} />
      }
      {...rest}
    />
  )
}

export default UploadLogo

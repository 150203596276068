import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { orgSelectors, orgsActions } from 'ducks/orgs'
import Alert from 'elements/Alert'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'
import { GetActionsResponseType, IntegrationEnabledType, IntegrationWithOrgNamesType } from 'types/orgs'
import { PaymentOptionDataType, US_FINANCE_INTEGRATION_ENUM } from 'types/paymentOptions'
import { useFeatureFlag } from 'util/split'
import RefreshReminderRow from './RefreshReminderRow'

const restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  availablePaymentOptions: PaymentOptionDataType[]
}

type IntegrationToStatusType = {
  [integration in US_FINANCE_INTEGRATION_ENUM]: {
    status: IntegrationEnabledType
    enabled: boolean
  }
}

const ACTION_SUFFIX = '_rate_refresh'

const PaymentOptionRefreshChecklist: React.FC<PropTypes> = (props) => {
  const [integrationsToRefresh, setIntegrationsToRefresh] = useState<IntegrationWithOrgNamesType[]>([])
  const [requiresExternalRefresh, setRequiresExternalRefresh] = useState<boolean>(false)
  const [requiresInternalRefresh, setRequiresInternalRefresh] = useState<boolean>(false)
  const [hasQueried, setHasQueried] = useState<boolean>(false)

  const integrationToStatus: IntegrationToStatusType = {
    sungage: {
      status: useSelector(orgSelectors.getSungageIntegrationStatus),
      enabled: !!useFeatureFlag('sungage_rate_change', 'on'),
    },
    sunlight: {
      status: useSelector(orgSelectors.getSunlightIntegrationStatus),
      enabled: !!useFeatureFlag('sunlight_rate_change', 'on'),
    },
    mosaic: {
      status: useSelector(orgSelectors.getMosaicIntegrationStatus),
      enabled: !!useFeatureFlag('mosaic_rate_change', 'on'),
    },
    loanpal: {
      status: useSelector(orgSelectors.getDividendIntegrationStatus),
      enabled: !!useFeatureFlag('dividend_rate_change', 'on'),
    },
    dividend: {
      status: useSelector(orgSelectors.getDividendIntegrationStatus),
      enabled: !!useFeatureFlag('loanpal_rate_change', 'on'),
    },
  }

  const dispatch = useDispatch()
  const orgId = useSelector(authSelectors.getOrgId)
  const orgName = useSelector(orgSelectors.getOrg)?.name
  const cachedResult = useSelector(orgSelectors.getCachedRefreshReminder)

  const getHourLimitedString = () => {
    let now = new Date()
    return `${now.getMonth()}-${now.getDate()}-${now.getFullYear()} : ${now.getHours()}`
  }

  const getMinuteLimitedString = () => {
    let now = new Date()
    return `${now.getMonth()}-${now.getDate()}-${now.getFullYear()} : ${now.getHours()}:${now.getMinutes()}`
  }

  const getShouldPreventCallout = () => {
    // check if we have received a response in the past hour that says no refreshes are needed
    // or if in the past minute we've received a response with integrations that do need a refresh
    if (!cachedResult) return false
    return (
      cachedResult?.last_all_clear === getHourLimitedString() || cachedResult.last_fetched === getMinuteLimitedString()
    )
  }

  const fetchData = () => {
    if (!getShouldPreventCallout()) {
      let integrationsToCheck: US_FINANCE_INTEGRATION_ENUM[] = []
      const mapKeys = Object.keys(integrationToStatus) as Array<keyof typeof integrationToStatus>
      mapKeys?.forEach((integrationName) => {
        if (integrationToStatus[integrationName].enabled) {
          integrationsToCheck.push(integrationName)
        }
      })

      if (integrationsToCheck?.length) {
        const actionNames = integrationsToCheck.map((integration) => `${integration}${ACTION_SUFFIX}`)
        return restClientInstance('CUSTOM_POST', 'custom', {
          url: `orgs/${orgId}/rate_refresh_reminders/`,
          data: { action_names: actionNames },
        })
      }
    } else {
      return Promise.resolve(cachedResult?.cached_response)
    }
    return Promise.reject()
  }

  useEffect(() => {
    if (props.availablePaymentOptions && !hasQueried) {
      setHasQueried(true)
      fetchData()
        .then((res: GetActionsResponseType) => {
          if (res.data.requires_external_refresh) setRequiresExternalRefresh(true)
          if (res.data.requires_internal_refresh) setRequiresInternalRefresh(true)
          let needsRefresh: IntegrationWithOrgNamesType[] = []
          Object.values(res.data.integrations)?.forEach((integration: IntegrationWithOrgNamesType) => {
            if (integration?.required_org_names?.length) needsRefresh.push(integration)
          })
          setIntegrationsToRefresh(needsRefresh)
          if (needsRefresh?.length) {
            logAmplitudeEvent('rate_change_reminder_shown', {
              integrations: needsRefresh.map((int) => int.integration_name).join(','),
            })
          }
          if (!needsRefresh?.length) {
            // if nothing requires a refresh, don't call this endpoint again for an hour
            dispatch(orgsActions.storeRateRefreshData(res, getMinuteLimitedString(), getHourLimitedString()))
          }
          dispatch(orgsActions.storeRateRefreshData(res, getMinuteLimitedString(), undefined))
        })
        .catch((err) => {
          console.log('err', err)
          dispatch(orgsActions.storeRateRefreshData(undefined, getMinuteLimitedString(), undefined))
        })
    }
  }, [props.availablePaymentOptions, hasQueried, integrationToStatus])

  const isAdmin = useSelector(authSelectors.getIsAdmin)

  const getMessage = useMemo(() => {
    if (!integrationsToRefresh?.length) return null

    let lastSentencePart = ''
    if (!requiresExternalRefresh) {
      if (isAdmin) lastSentencePart = 'Please refresh products in '
      else lastSentencePart = 'Ask an admin to refresh products'
    } else {
      if (requiresInternalRefresh) {
        if (isAdmin)
          lastSentencePart =
            'For integrations that are shared with your org, please ask an admin from the connected org to refresh products. For integrations in your org, please refresh products in '
        else
          lastSentencePart =
            'For integrations that are shared with your org, please ask an admin from the connected org to refresh products. For integrations in your org, please ask an admin in your org to refresh products'
      } else {
        lastSentencePart = 'Please ask an admin from your connected org to refresh products'
      }
    }
    let message = ''
    if (integrationsToRefresh.length > 1) {
      message = `Rates have changed for these integrations. Dealer fees and/or APRs may be out of date. ${lastSentencePart} `
    } else if (integrationsToRefresh.length > 0) {
      message = `Rates have changed for this integration. Dealer fees and/or APRs may be out of date. ${lastSentencePart} `
    }
    return message
  }, [isAdmin, integrationsToRefresh, requiresExternalRefresh, requiresInternalRefresh])

  const logClick = () => {
    logAmplitudeEvent('rate_change_reminder_clicked', { integrations: integrationsToRefresh.join(',') })
  }

  if (!integrationsToRefresh?.length) return null
  return (
    <Alert severity="warning">
      <div>
        {getMessage}
        {isAdmin && requiresInternalRefresh && (
          <a href="/#/payment_options" target="_blank" onClick={logClick}>
            Payment Options
          </a>
        )}
      </div>
      <ul>
        {integrationsToRefresh?.map((integration) => (
          <RefreshReminderRow
            integration_name={integration.integration_name}
            required_org_names={integration.required_org_names}
            key={integration.integration_name}
            orgName={orgName}
            requiresExternalRefresh={requiresExternalRefresh}
          />
        ))}
      </ul>
    </Alert>
  )
}
export default PaymentOptionRefreshChecklist

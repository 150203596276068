import ProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/projectOrderPresenter'
import StatusMessage, { StatusMessageTypography } from 'pages/ordering/StatusMessage'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import { memo } from 'react'
import { formatInt } from 'util/misc'
import { STOCK_LEVEL_STATUS } from '../constants'
import { StockLevelStatus, StockLevelType } from '../type'

export const getCatalogItemStatus = ({
  selectedSupplier,
  hasVariant,
  isDistributorAvailable,
  stockLevels,
}: {
  selectedSupplier: HardwareSupplierEnum | undefined
  hasVariant: boolean
  isDistributorAvailable: boolean
  stockLevels: StockLevelType[] | undefined
}): StockLevelStatus | 'no_data' => {
  if (!hasVariant && selectedSupplier === HardwareSupplierEnum.CityPlumbing) return 'no_data'
  const status: StockLevelStatus = ProjectOrderPresenter.getStockLevelStatusMain({
    quantity: 0,
    stockLevels,
    isAvailable: isDistributorAvailable,
  })
  return status
}

const CatalogItemStatus = ({
  supplier,
  status,
  stockQuantity,
  lastPricingRefreshDate,
  plannedDateDays,
}: {
  supplier: HardwareSupplierEnum | undefined
  status: StockLevelStatus | 'no_data'
  stockQuantity?: number
  lastPricingRefreshDate?: string | undefined
  plannedDateDays?: number | undefined
}) => {
  if (supplier === HardwareSupplierEnum.Segen) {
    return (
      <SegenStockStatus status={status} stockQuantity={stockQuantity} lastPricingRefreshDate={lastPricingRefreshDate} />
    )
  }
  if (supplier === HardwareSupplierEnum.SolarOutlet) {
    return <OutletStockStatus status={status} plannedDateDays={plannedDateDays} />
  }
  if (supplier === HardwareSupplierEnum.CityPlumbing) {
    return <CityPlumbingStockStatus status={status} stockQuantity={stockQuantity} />
  }
  if (supplier === HardwareSupplierEnum.Hdm) {
    return <HdmStockStatus status={status} stockQuantity={stockQuantity} />
  }
  if (supplier === HardwareSupplierEnum.VtacUk || supplier === HardwareSupplierEnum.VtacPoland) {
    return <VtacStockStatus status={status} stockQuantity={stockQuantity} />
  }

  return null
}

const CityPlumbingStockStatus = ({
  status,
  stockQuantity,
}: {
  status: StockLevelStatus | 'not_authenticated' | 'no_data'
  stockQuantity?: number
}) => {
  if (status === 'loading' || status === 'not_authenticated' || status === 'no_data') return null

  if (status === 'not_available') {
    return <StatusMessage status={status} />
  }

  if (status === 'out_of_stock') {
    return <StatusMessage status={status} />
  }

  return <StatusMessageTypography type="success" message={`${formatInt(stockQuantity)} available`} />
}

const OutletStockStatus = ({
  status,
  plannedDateDays,
}: {
  status: StockLevelStatus | 'not_authenticated' | 'no_data'
  plannedDateDays?: number
}) => {
  if (status === 'loading' || status === 'not_authenticated' || status === 'no_data') return null

  if (status === 'not_available') {
    return <StatusMessageTypography type="error" message="Not currently available" />
  }

  if (status === 'out_of_stock') {
    return <StatusMessageTypography type="error" message="Out of Stock" />
  }

  if (status === STOCK_LEVEL_STATUS.AVAILABLE_FOR_PRE_ORDER) {
    return <StatusMessageTypography type="success" message={`Pre Order is Available`} />
  }

  return <StatusMessageTypography type="success" message="In Stock" />
}

const SegenStockStatus = ({
  status,
  stockQuantity,
  lastPricingRefreshDate,
}: {
  status: StockLevelStatus | 'no_data'
  stockQuantity?: number
  lastPricingRefreshDate?: string | undefined
}) => {
  // If we've never synced a price, we don't show anything
  if (!lastPricingRefreshDate) {
    return null
  }

  if (status === 'not_available') {
    return <StatusMessage status={status} />
  }

  if (status === 'out_of_stock') {
    return <StatusMessage status={status} />
  }

  if (!stockQuantity || stockQuantity === 0) {
    return <StatusMessage status="available" />
  }

  return <StatusMessageTypography type="success" message={`${formatInt(stockQuantity)} available`} />
}

const HdmStockStatus = ({
  status,
  stockQuantity,
}: {
  status: StockLevelStatus | 'no_data'
  stockQuantity?: number
}) => {
  if (status === 'available')
    return <StatusMessageTypography type="success" message={`${formatInt(stockQuantity)} available`} />
  if (status === 'out_of_stock') return <StatusMessage status={status} />
  return null
}

const VtacStockStatus = ({
  status,
  stockQuantity,
}: {
  status: StockLevelStatus | 'no_data'
  stockQuantity?: number
}) => {
  if (status === 'available')
    return <StatusMessageTypography type="success" message={`${formatInt(stockQuantity)} available`} />
  if (status === 'out_of_stock') return <StatusMessage status={status} />
  return null
}

export default memo(CatalogItemStatus)

import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import React, { memo } from 'react'
import { useTranslate } from 'react-admin'

const ListResultNotFound = memo(() => {
  const translate = useTranslate()

  return (
    <CardContent>
      <Typography variant="body2">{translate('ra.navigation.no_results')}</Typography>
    </CardContent>
  )
})

export default ListResultNotFound

import { viewModeSelectors } from 'ducks/viewMode'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useFeatureFlag } from 'util/split'

export const useUxVersion = () => {
  const ui3Enabled = useFeatureFlag('ux_version_3', 'on')
  const isUserLite = useSelector(viewModeSelectors.isUserLite)
  const uxPreference = useSelector(viewModeSelectors.getUxPreference)

  const uxVersion = useMemo(() => {
    if (isUserLite || !ui3Enabled) return 2
    // If disabled in split, or user is lite, use UI2
    else if (uxPreference !== 2 && uxPreference !== 3) return 3
    // if not a valid preference, default to UI3
    else return uxPreference // otherwise, usse the user's preference
  }, [ui3Enabled, uxPreference, isUserLite])

  // Not really used for much, gets saved into the design data though
  window.uxVersion = uxVersion

  return uxVersion
}

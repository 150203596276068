import { Grid } from '@material-ui/core'
import AlertError from '@material-ui/icons/ErrorOutlineOutlined'
import ContentSave from '@material-ui/icons/SaveOutlined'
import Button from 'elements/button/Button'
import React, { memo } from 'react'

const InventoryTableActions = ({ onCancel, onSave, saving }) => {
  return (
    <Grid container xs={12} alignItems={'center'} justify={'flex-end'}>
      <Button
        startIcon={<AlertError />}
        style={{ margin: 10 }} // remove inline style
        onClick={onCancel}
        disabled={saving}
        variant="contained"
        color="default"
      >
        <span>Cancel</span>
      </Button>

      <Button
        startIcon={<ContentSave />}
        loading={saving}
        style={{ margin: 10 }} // remove inline style
        onClick={onSave}
        variant="contained"
        color="primary"
      >
        <span>Save</span>
      </Button>
    </Grid>
  )
}

export default memo(InventoryTableActions)

// @ts-nocheck
import React from 'react'

const ArrowIcon = () => (
  <svg width="32" height="16" version="1.1">
    <polygon points={'0,0 16,16 32,0'} fill="#fff"></polygon>
  </svg>
)

const BottomArrow = () => (
  <span style={{ position: 'absolute', left: '50%', bottom: -16, transform: 'translate(-50%)' }}>
    <ArrowIcon />
  </span>
)

const TopArrow = () => (
  <span style={{ position: 'absolute', left: '50%', top: -16, transform: 'translate(-50%) rotate(180deg)' }}>
    <ArrowIcon />
  </span>
)

const RightArrow = () => (
  <span style={{ position: 'absolute', right: -22, top: '50%', transform: 'rotate(-90deg)' }}>
    <ArrowIcon />
  </span>
)

const LeftArrow = () => (
  <span style={{ position: 'absolute', left: -22, top: '50%', transform: 'rotate(90deg)' }}>
    <ArrowIcon />
  </span>
)

export const PlacementAndArrows = {
  left: LeftArrow,
  bottom: BottomArrow,
  top: TopArrow,
  right: RightArrow,
}

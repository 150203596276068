import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import React, { useMemo } from 'react'
import { useTranslate } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { AvailableFinanceIntegrationType } from 'types/orgs'

const useStyles = makeOpenSolarStyles((theme) => ({
  contentWrapper: {},
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    padding: '10px 20px',
  },
}))

type PropTypes = {
  onClose: () => void
  integration: AvailableFinanceIntegrationType
  onRefresh: () => void
}

const ImportDialog: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()

  const onImport = () => {
    props.onRefresh()
  }

  const defaultTitle = useMemo(() => {
    return translate(`Import %{partnerName} Payment Options`, { partnerName: props.integration.display_name })
  }, [props.integration?.display_name])

  if (!props.integration.banner_content.import_dialog_content) return null

  return (
    <Dialog open={true} onClose={props.onClose}>
      <DialogTitle>
        <h2>{props.integration?.banner_content?.import_dialog_title || defaultTitle}</h2>
      </DialogTitle>
      <DialogContent>
        <div
          dangerouslySetInnerHTML={{
            __html: props.integration.banner_content.import_dialog_content,
          }}
        ></div>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttonsWrapper}>
          <ProUXButton type="secondary" label="Dismiss" onClick={props.onClose} />
          <ProUXButton type="primary" label="Import products" onClick={onImport} />
        </div>
      </DialogActions>
    </Dialog>
  )
}
export default ImportDialog

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import createStandardIcon from './createStandardIcon';
var BatteryAltIconSvg = function (props) {
    var fill = props.fill;
    return (_jsxs("svg", __assign({ width: "48", height: "48", viewBox: "0 0 48 48", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("mask", __assign({ id: "mask0_5679_114013", style: { maskType: 'alpha' }, maskUnits: "userSpaceOnUse", x: "0", y: "0", width: "48", height: "48" }, { children: _jsx("rect", { width: "48", height: "48", fill: "#D9D9D9" }) })), _jsx("g", __assign({ mask: "url(#mask0_5679_114013)" }, { children: _jsx("path", { d: "M33.0773 37.846H31.0043C30.7039 37.846 30.4884 37.7275 30.3578 37.4905C30.2271 37.2532 30.2323 37.0077 30.3733 36.754L34.2453 30.0155C34.3436 29.8515 34.4811 29.7912 34.6578 29.8345C34.8348 29.8782 34.9233 30.0017 34.9233 30.205V34.154H36.9963C37.2966 34.154 37.5121 34.2725 37.6428 34.5095C37.7734 34.7468 37.7683 34.9923 37.6273 35.246L33.7553 42.0155C33.6569 42.1795 33.5194 42.2408 33.3428 42.1995C33.1658 42.1578 33.0773 42.0397 33.0773 41.845V37.846ZM16.3273 43C15.9186 43 15.5761 42.8618 15.2998 42.5855C15.0231 42.3088 14.8848 41.9662 14.8848 41.5575V9.3345C14.8848 8.9385 15.0231 8.59917 15.2998 8.3165C15.5761 8.03383 15.9186 7.8925 16.3273 7.8925H20.3078V6.4425C20.3078 6.03383 20.4461 5.69117 20.7228 5.4145C20.9991 5.13817 21.3416 5 21.7503 5H26.2503C26.6589 5 27.0014 5.13817 27.2778 5.4145C27.5544 5.69117 27.6928 6.03383 27.6928 6.4425V7.8925H31.6773C32.0866 7.8925 32.4286 8.03383 32.7033 8.3165C32.9783 8.59917 33.1158 8.9385 33.1158 9.3345V23.7345C33.1158 24.0562 33.0061 24.3257 32.7868 24.543C32.5674 24.7603 32.2956 24.869 31.9713 24.869C31.6469 24.869 31.3784 24.7603 31.1658 24.543C30.9528 24.3257 30.8463 24.0562 30.8463 23.7345V10.1425H17.1543V40.7305H24.7388C25.0601 40.7305 25.3296 40.8403 25.5473 41.06C25.7646 41.2793 25.8733 41.551 25.8733 41.875C25.8733 42.1993 25.7646 42.4678 25.5473 42.6805C25.3296 42.8935 25.0601 43 24.7388 43H16.3273Z", fill: "#4F4D55" }) }))] })));
};
export var BatteryAltIcon = createStandardIcon(BatteryAltIconSvg);

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from 'react';
import { useMenu } from '../hooks/useMenu';
import { Menu } from './Menu';
export var PopoutMenu = function (_a) {
    var anchorEl = _a.anchorEl, menuItems = _a.children, menuId = _a.menuId, restMenuProps = _a.menuProps;
    var _b = useMenu(menuId), anchorElementAriaProps = _b.anchorElementAriaProps, menuController = _b.menuController, menuProps = _b.menuProps;
    var anchorElementRef = useRef(null);
    var anchorElementWithRef = React.cloneElement(anchorEl, __assign({ onClick: function () {
            var _a, _b;
            menuController.handleToggleMenu();
            (_b = (_a = anchorEl.props).onClick) === null || _b === void 0 ? void 0 : _b.call(_a);
        }, ref: anchorElementRef }, anchorElementAriaProps));
    return (_jsxs(_Fragment, { children: [anchorElementWithRef, _jsx(Menu, __assign({ anchorEl: anchorElementRef.current }, restMenuProps, menuProps, { children: React.Children.map(menuItems, function (menuItem) {
                    return menuItem
                        ? React.cloneElement(menuItem, {
                            onClick: function (e) {
                                var _a, _b;
                                menuController.handleToggleMenu();
                                (_b = (_a = menuItem.props).onClick) === null || _b === void 0 ? void 0 : _b.call(_a, e);
                            },
                        })
                        : null;
                }) }))] }));
};

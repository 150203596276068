import { Dialog, DialogContent, makeStyles } from '@material-ui/core'
import { ComponentVersions_3_0 } from 'constants/uxVersions'
import { useUxVersion } from 'hooks/useUxVersion'
import {
  AlertTriangleOutlineIcon,
  Button,
  ComponentVersionsInherit,
  DialogActions,
  DialogTitle,
  OsButtonProps,
} from 'opensolar-ui'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import { useTranslate } from 'ra-core'
import React from 'react'
import { Theme } from 'types/themes'
import Notice from './Notice'

interface DialogProps {
  open: boolean
  setOpen: (open: boolean) => void
  handleSubmit: (e) => void
  title?: string
  mainText?: string
  subText?: string
  submitBtnLabel?: string
  submitBtnProps?: OsButtonProps
}

const useStyles = makeStyles((theme: Theme) => ({
  discardBtn: {
    marginRight: 5,
  },
}))
const ConfirmModal: React.FC<DialogProps> = (props) => {
  const ui3Enabled = useUxVersion() === 3
  if (ui3Enabled)
    return (
      <ComponentVersionsInherit versions={ComponentVersions_3_0}>
        <ConfirmModalContent {...props} />
      </ComponentVersionsInherit>
    )
  return <ConfirmModalContent {...props} />
}

const ConfirmModalContent: React.FC<DialogProps> = ({
  open,
  setOpen,
  handleSubmit,
  title = '',
  mainText = '',
  subText = '',
  submitBtnLabel = '',
  submitBtnProps,
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Notice
          icon={<AlertTriangleOutlineIcon size={23} color={COLOR_PALETTE.darkGrey} />}
          mainText={mainText}
          subText={subText}
        />
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.discardBtn}
          variant="outlined"
          color="default"
          onClick={() => {
            setOpen(false)
          }}
        >
          {translate('Cancel')}
        </Button>

        <Button onClick={handleSubmit} {...submitBtnProps}>
          {submitBtnLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default ConfirmModal

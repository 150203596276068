import { Divider } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { clearLineItems, orderSelectors } from 'ducks/orderComponents'
import { orgSelectors } from 'ducks/orgs'
import Button from 'elements/button/Button'
import { getOrderCost } from 'pages/ordering/utils'
import { memo, useCallback, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import useSolarOutletService from 'services/outlet/useSolarOutletService'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { currencySymbolForCountry, formatCurrency } from 'util/misc'
import { useFeatureFlag } from '../../../../util/split'
import CustomPricingNotAvailableModal from './CustomPricingNotAvailableModal'

type Breakdown = {
  label: string
  value: string
  isTextBold: boolean
}

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    boxSizing: 'border-box',
    border: theme.border.default,
    borderRadius: '8px',
    padding: 15,
    marginBottom: 15,
  },
  textWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    margin: '4px 0px 16px 0px',
  },
  header: {
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '22px',
  },
  text: {
    fontSize: '14px',
    lineHeight: '22px',
  },
  textBold: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '22px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 0px 16px 0px',
    gap: '16px',
  },
  footerText: {
    fontSize: '14px',
    lineHeight: '22px',
    color: theme.greyMid1,
  },
}))

const OutletCartActions = () => {
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()
  const notify = useNotify()
  const dispatch = useDispatch()
  const usingCustomPricing = useSelector(orderSelectors.getIsCustomPricing)
  const currencySymbol = useSelector((state: any) => {
    return currencySymbolForCountry(orgSelectors.getOrgIso2(state))
  })
  const orderableLineItems = useSelector(orderSelectors.getOrderableLineItems)
  const cost = getOrderCost(orderableLineItems)
  const [showCustomPricingNotAvailableModal, setShowCustomPricingNotAvailableModal] = useState<boolean>(false)
  const solarOutletService = useSolarOutletService()
  const enable_solar_juice_checkout = useFeatureFlag('enable_solar_juice_checkout', 'on')
  const quantity = orderableLineItems.reduce((accumulator, item) => accumulator + item.quantity, 0)
  const breakdown: Breakdown[] = [
    {
      label: 'Total before Tax:',
      value: usingCustomPricing ? 'TBC' : `${currencySymbol} ${formatCurrency(cost)}`,
      isTextBold: true,
    },
    {
      label: 'GST (10%):',
      value: usingCustomPricing ? 'TBC' : `${currencySymbol} ${formatCurrency(cost * 0.1)}`,
      isTextBold: false,
    },
    { label: 'Estimated Shipping Cost', value: 'TBC', isTextBold: false },
  ]
  const handleClickSolarJuice = () => {
    logAmplitudeEvent('hardware_solar_outlet_pay_using_solar_juice_credit_cta_clicked', {
      distributor: 'outlet',
      source: 'shopping_cart',
      codes: orderableLineItems.map((lineItem) => lineItem.code),
    })
    history.push(`/shop/cart/outlet`)
  }

  const redirectToShopifyUrl = useCallback((shopifyUrl: string) => {
    logAmplitudeEvent('hardware_order_placed_success', {
      distributor: 'outlet',
      type: 'quote',
      method: 'redirect',
    })
    // Use redirect to avoid new tab launches in a background tab
    // and popup can get blocked by popup blockers
    // See ticket: https://github.com/open-solar/opensolar-todo/issues/9080
    window.location.href = shopifyUrl
  }, [])

  const checkout = async () => {
    logAmplitudeEvent('hardware_place_order_cta_clicked', {
      distributor: 'outlet',
      source: 'shopping_cart',
      codes: orderableLineItems.map((lineItem) => lineItem.code),
    })

    if (usingCustomPricing) {
      setShowCustomPricingNotAvailableModal(false)
    }

    if (!solarOutletService) {
      return
    }
    const checkout = await solarOutletService.checkout(orderableLineItems)
    if (checkout === null) {
      notify('Failed to create SolarOutlet order.', 'warning')
      return
    }
    dispatch(clearLineItems())
    redirectToShopifyUrl(checkout.webUrl)
  }

  const handleClickPayNow = () => {
    if (usingCustomPricing) {
      setShowCustomPricingNotAvailableModal(true)
    } else {
      checkout()
    }
  }

  const handleCloseModal = () => {
    setShowCustomPricingNotAvailableModal(false)
  }
  return (
    <div className={classes.wrapper}>
      <div className={classes.textWrapper}>
        <Typography gutterBottom className={classes.header}>
          {translate('shopping_cart_action_subtotal', { smart_count: quantity })}
        </Typography>
      </div>
      <div className={classes.textWrapper} style={{ marginTop: '24px' }}>
        <Typography gutterBottom className={classes.text}>
          Subtotal:
        </Typography>
        <Typography gutterBottom className={classes.text}>
          {usingCustomPricing ? 'TBC' : `${currencySymbol} ${formatCurrency(cost)}`}
        </Typography>
      </div>
      <Divider className={classes.divider} />
      <>
        {breakdown.map((item: Breakdown) => (
          <div className={classes.textWrapper}>
            <Typography gutterBottom className={item.isTextBold ? classes.textBold : ''}>
              {item.label}
            </Typography>
            <Typography gutterBottom className={classes.text}>
              {item.value}
            </Typography>
          </div>
        ))}
      </>
      <div className={classes.buttonContainer}>
        {enable_solar_juice_checkout && (
          <Button color="primary" fullWidth onClick={handleClickSolarJuice} disabled={orderableLineItems.length === 0}>
            Pay using Solar Juice Credit
          </Button>
        )}

        <Button fullWidth color="default" onClick={handleClickPayNow} disabled={orderableLineItems.length === 0}>
          {enable_solar_juice_checkout ? 'Pay with Other Methods' : 'Pay Now'}
        </Button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography gutterBottom className={classes.footerText}>
          *Shipping calculated on the next page
        </Typography>
      </div>
      <CustomPricingNotAvailableModal
        open={showCustomPricingNotAvailableModal}
        handleClose={handleCloseModal}
        handleProceedCheckout={checkout}
      />
    </div>
  )
}

export default memo(OutletCartActions)

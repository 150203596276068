import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import ContactsOutlinedIcon from '@material-ui/icons/ContactsOutlined'
import useGetCustomForms from 'customForm/hooks/useGetCustomForms'
import { authSelectors } from 'ducks/auth'
import { permissionsSelectors } from 'ducks/permissions'
import CRUDRecordDialog from 'projectSections/sections/common/CRUDRecordDialog'
import React, { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useField, useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { ContactForms } from 'resources/contacts/form/ContactForms'
import { FormPlacementEnum } from 'resources/customForms/types'
import { ContactType, NORMAL_CONTACT } from '../../../../types/contacts'
import ContactBottomActions from './ContactBottomActions'
import ContactDisplayRow from './ContactDisplayRow'
import ContactNew from './ContactNew'

// @ts-ignore
const BLANK_CONTACT: ContactType = {
  first_name: '',
  family_name: '',
  email: '',
  phone: '',
  full_name: '',
  org_id: null,
  display: '',
}

const useStyles = makeStyles((theme) => ({
  content: {
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 0',
    margin: '0!important',
    cursor: 'initial',
  },
  root: {
    '& .MuiAccordionSummary-root': {
      cursor: 'initial!important',
    },
  },
  headingIcon: {
    verticalAlign: 'middle',
  },
  headingTitle: {
    margin: '0 10px',
    verticalAlign: 'middle',
  },
  accordionContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  savedContacts: {
    width: '100%',
  },
}))

type PropTypes = {}

const ContactsAccordion: React.FunctionComponent<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const form = useForm()

  const contacts_data: ContactType[] = form.getState().values['contacts_data'] || []
  const contactsNewValue: ContactType[] | undefined = useField('contacts_new', { subscription: { value: true } })?.input
    ?.value
  const contactsNew: ContactType[] = contactsNewValue
    ? contactsNewValue.filter((con: ContactType) => !!con && !con.id)
    : []

  const [contacts, setContacts] = useState<ContactType[]>(contacts_data || [])
  const [contactToEdit, setContactToEdit] = useState<ContactType | null>(null)
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false)

  const myOrgId = useSelector(authSelectors.getOrgId)

  // initialize contacts_new with one empty contact to ensure form shows up. Only do this when contacts_new has no data in it
  useEffect(() => {
    const shownContacts = contacts_data?.filter((c) => c.type === NORMAL_CONTACT) || []
    if (shownContacts.length === 0 && contactsNew.length === 0) {
      const populatedNewContacts = contactsNew?.filter((con) => {
        return con.email || con.first_name || con.family_name || con.phone || con.id
      })
      if (populatedNewContacts?.length === 0) form.change('contacts_new', [BLANK_CONTACT])
    }
  }, [contacts_data, contactsNew])

  useEffect(() => {
    if (contacts_data) {
      const noSubstantiveChange = contacts_data?.length === 0 && contacts?.length === 0
      if (noSubstantiveChange) return
      else setContacts(contacts_data)
    }
  }, [contacts_data])

  const removeContact = (contact: ContactType) => {
    const newContacts = contacts_data.filter((con) => con.id !== contact.id)
    form.registerField('contacts_data', () => {}, {})
    form.change('contacts_data', newContacts)

    form.change(
      'contacts',
      newContacts.map((c) => c.url)
    )
    form.mutators.markFieldAsDirty('contacts')

    // We must call setContacts() in order to ensure the UI re-renders to remove the contact,
    // otherwise the removed contact will still display until the next render
    setContacts(newContacts)
  }

  const discardContact = (discardIndex: number) => {
    const newArray = contactsNew.filter((con, i) => i !== discardIndex)
    form.change('contacts_new', newArray)
  }

  const onAddNew = () => {
    if (contactsNew) {
      form.change('contacts_new', [...contactsNew, BLANK_CONTACT])
    } else {
      form.change('contacts_new', [BLANK_CONTACT])
    }
  }

  const onContactDelete = (conToDelete) => {
    const newArray = contacts_data?.filter((con) => con.id !== conToDelete)
    form.change('contacts_data', newArray)
    form.change(
      'contacts',
      newArray.map((c) => c.url)
    )
    setShowEditDialog(false)
    setContactToEdit(null)
  }

  const startEditContact = (con: ContactType) => {
    setContactToEdit(con)
    setShowEditDialog(true)
  }

  const onContactEditSuccess = (responseData) => {
    const formValues = form.getState().values
    const contactsData = formValues.contacts_data
    const index = contactsData.findIndex((contact: ContactType) => contact.id === responseData.id)
    contactsData[index] = responseData
    form.change('contacts_data', [...contactsData]) // spread to trigger subscription updates, but won't dirty the form
  }

  const onDismiss = () => {
    setContactToEdit(null)
    setShowEditDialog(false)
  }
  const customForms = useGetCustomForms({ location: FormPlacementEnum.info_page_contact, isCreateForm: false })

  const { allowView, allowCreate, allowEdit, allowDelete } = useSelector(
    permissionsSelectors.getProjectPermissionByKey('info_contact_info_basic')
  )

  const showBottomDivider: boolean = (contactsNew && contactsNew.length > 0) || (contacts && contacts.length > 0)
  if (!allowView) {
    return null
  }

  return (
    <Accordion id="customer-contact-accordion" classes={{ root: classes.root }} expanded={true}>
      <AccordionSummary
        aria-controls={'expansion-content-contacts'}
        id={'expansion-header-contacts'}
        classes={{ content: classes.content }}
      >
        <div>
          <ContactsOutlinedIcon className={classes.headingIcon} />
          <span className={classes.headingTitle}>{translate('Customer Contacts')}</span>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className={classes.accordionContent}>
          <div className={classes.savedContacts}>
            {contacts_data
              .filter((c) => c.type === NORMAL_CONTACT)
              .map((con) => {
                return (
                  <ContactDisplayRow
                    contact={con}
                    key={con.id || con.display}
                    isNew={!con.id}
                    disableEdit={!allowEdit}
                    disableDelete={!allowDelete}
                    removeContact={() => removeContact(con)}
                    startContactEdit={() => startEditContact(con)}
                  />
                )
              })}
          </div>
          {contacts && contacts.length > 0 && contactsNew && contactsNew.length > 0 && (
            <Divider style={{ margin: '20px 0px' }} />
          )}
          {contactsNew?.map((con, i) => {
            if (Object.keys(con).length === 0) return null
            return <ContactNew key={i} contact={con} discardContact={() => discardContact(i)} index={i} />
          })}
          {showBottomDivider && <Divider style={{ margin: '5px 0px' }} />}
          {allowCreate && (
            <ContactBottomActions
              onAddNew={onAddNew}
              buttonLabel={contactsNew && contactsNew.length > 0 ? 'Add Another' : 'Add Customer'}
            />
          )}
        </div>
      </AccordionDetails>
      <CRUDRecordDialog
        record={contactToEdit}
        isOpen={showEditDialog && !!contactToEdit}
        resource="contacts"
        basePath="/contacts"
        onDismiss={onDismiss}
        formContent={<ContactForms customForms={customForms} uiKey="projects.project.info.contact" />}
        notificationOnSuccess={true}
        customSuccessNotificationText={translate('Success')}
        removeRecordFromForm={onContactDelete}
        deleteDisabled={contactToEdit?.org_id !== myOrgId}
        saveDisabled={contactToEdit?.org_id !== myOrgId}
        dialogTitle={contactToEdit?.display}
        updateFormValsFn={onContactEditSuccess}
        width={'xl'}
      />
    </Accordion>
  )
}

export default ContactsAccordion

import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import SuccessIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutlineOutlined';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import { forwardRef } from 'react';
import { Inspector } from '../debug/Inspector';
import { useComponentVersion } from '../hooks';
import { AlertTriangleIcon } from '../icons/AlertTriangleIcon';
import { CheckContainedIcon } from '../icons/CheckContainedIcon';
import { InformationCircleIcon } from '../icons/InformationCircleIcon';
import { OsAlertVersion } from './Alert';
export var AlertIcon = function (props) {
    return (_jsx(Inspector, { name: "AlertIcon", propDefs: AlertIconProps, showExample: true, component: OsAlertIcon, props: props }));
};
var VersionIcons = function (version) {
    if (version === OsAlertVersion.V2)
        return {
            warning: AlertTriangleIcon,
            error: InformationCircleIcon,
            info: InformationCircleIcon,
            success: CheckContainedIcon,
        };
    return {
        warning: WarningIcon,
        error: ErrorIcon,
        info: InfoIcon,
        success: SuccessIcon,
    };
};
var v1Styles = makeStyles(function (theme) { return ({
    icon: function (_a) {
        var severity = _a.severity;
        return ({
            width: 22,
            height: 22,
            color: theme['alertIcon_' + severity],
            marginRight: 10,
        });
    },
}); });
var OsAlertIcon = forwardRef(function (props, ref) {
    var _a = props.severity, severity = _a === void 0 ? 'info' : _a;
    var version = useComponentVersion('alert');
    var Icon = VersionIcons(version)[severity];
    switch (version) {
        case OsAlertVersion.V2:
            return _jsx(Icon, { ref: ref, fill: 'inherit', width: '18px', height: '18px' });
        default:
            var classes = v1Styles({ severity: severity });
            return _jsx(Icon, { ref: ref, className: classes.icon });
    }
});
var AlertIconProps = {
    severity: { default: 'info' },
};

import type LineItemType from 'pages/ordering/OrderLineItem'
import OrderLineItem from 'pages/ordering/OrderLineItem'
import SolarOutletService from 'services/outlet/SolarOutletService'
import { OrgType } from 'types/orgs'
import { ShippingDetailType } from './type'

type FeesType = {
  tax: number
  shippingFee: number
  subTotalCost: number
  totalCost: number
}

const SHIPPING_METHOD_VALUES = {
  commercial_delivery: 'Request Commercial Delivery Quote',
  residential_delivery: 'Request Residential Delivery Quote',
  pick_up: 'Pickup at warehouse',
  mainfreight: 'Mainfreight Shipping (Commercial Delivery Address)',
}

class CheckoutPresenter {
  private org: OrgType
  private lineItems: OrderLineItem[]
  private service: SolarOutletService | undefined
  private setShippingDetail: React.Dispatch<React.SetStateAction<ShippingDetailType>>
  private setIsFetchingShippingFee: React.Dispatch<React.SetStateAction<boolean>>

  constructor(
    org: OrgType,
    lineItems: OrderLineItem[],
    service: SolarOutletService | undefined,
    setShippingDetail: React.Dispatch<React.SetStateAction<ShippingDetailType>>,
    setIsFetchingShippingFee: React.Dispatch<React.SetStateAction<boolean>>
  ) {
    this.org = org
    this.lineItems = lineItems
    this.service = service
    this.setShippingDetail = setShippingDetail
    this.setIsFetchingShippingFee = setIsFetchingShippingFee
  }

  updateShippingDetail = (ShippingDetail: ShippingDetailType) => {
    this.setShippingDetail(ShippingDetail)
  }

  updateIsFetchingShippingFee = (value: boolean) => {
    this.setIsFetchingShippingFee(value)
  }

  placeOrder = async (
    shippingDetail: ShippingDetailType,
    fees: FeesType,
    projectIds: number[],
    usingCustomPricing: boolean
  ) => {
    const lineItemNodes = this.lineItems.map((item) => {
      const { afterDiscount } = item.getBestDiscountOffer()
      return {
        title: item.data?.short_description,
        code: item.data?.code,
        variant: {
          sku: item.data?.code,
          price: {
            currencyCode: 'AUD',
            amount: item.pricePerUnit?.toFixed(2),
          },
          baseSku: item.data?.base_sku,
          imageUrl: item.data?.image_url,
        },
        quantity: item.quantity,
        totalPrice: ((item.pricePerUnit || 0) * item.quantity).toFixed(2),
        discountedPrice: afterDiscount?.toFixed(2),
      }
    })

    const shippingMethod =
      shippingDetail.shippingMethod === 'pick_up'
        ? `Pickup at ${shippingDetail.warehouse ? `${shippingDetail.warehouse} ` : ''}warehouse`
        : SHIPPING_METHOD_VALUES[shippingDetail.shippingMethod]

    const checkout = {
      usingCustomPricing,
      totalTax: {
        amount: fees.tax,
        currencyCode: 'AUD',
      },
      shippingFee: {
        amount: fees.shippingFee,
        currencyCode: 'AUD',
      },
      subtotalPrice: {
        amount: fees.subTotalCost,
        currencyCode: 'AUD',
      },
      totalPrice: {
        amount: fees.totalCost,
        currencyCode: 'AUD',
      },
      lineItems: {
        edges: lineItemNodes.map((item) => ({ node: item })),
      },
      shippingDetail: {
        ...shippingDetail,
        shippingMethod,
      },
    }

    return await this.service?.createSolarJuiceOrder({ checkout, projectIds })
  }

  getShippingRates = async (shippingDetail: ShippingDetailType) => {
    const items = this.lineItems.map((item: LineItemType) => ({
      name: item.data?.short_description || '',
      sku: item.data?.base_sku || '',
      quantity: item.quantity,
    }))
    const destination = {
      city: shippingDetail.contactAddress.locality,
      province: shippingDetail.contactAddress.state,
      suburb: shippingDetail.contactAddress.locality,
      address1: shippingDetail.contactAddress.address,
      postal_code: shippingDetail.contactAddress.zip,
    }
    const data = {
      rate: {
        destination,
        items,
      },
    }

    return await this.service?.getShippingRates(data)
  }
}

export default CheckoutPresenter

import CheckboxMultiSelectInput from 'elements/input/CheckboxMultiSelectInput'
import lodash from 'lodash'
import { FC, useEffect, useMemo } from 'react'
import { ReferenceInput, SelectInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { BOOLEAN_CHOICES } from '../fields'

interface PropTypes {
  source: string
}

const Tags: FC<PropTypes> = ({ source }) => {
  const form = useForm()
  const formValues = useFormState().values
  const filterField = useMemo(() => lodash.get(formValues, `${source}.fields`) || [], [formValues, source])

  useEffect(() => {
    if (filterField?.conditional !== undefined && filterField?.filters) {
      let newValue = {}
      newValue[filterField?.conditional ? 'tagged' : 'not_tagged'] = filterField?.filters
      form.change(`${source}.value`, newValue)
    }
  }, [filterField])
  return (
    <>
      <SelectInput
        choices={BOOLEAN_CHOICES}
        label={false}
        source={`${source}.fields.conditional`}
        variant="outlined"
        margin={'none'}
      />
      <ReferenceInput source={`${source}.fields.filters`} reference="tags" perPage={100} label="">
        <TagsMultiSelect selected={filterField.filters} />
      </ReferenceInput>
    </>
  )
}
const TagsMultiSelect = (props) => {
  const { source, choices, selected } = props
  const formatChoices = useMemo(() => (selected && !selected.includes(',') ? choices.slice(1) : choices), [
    choices,
    selected,
  ])
  return (
    <CheckboxMultiSelectInput
      label={false}
      variant={'outlined'}
      source={source}
      choices={formatChoices}
      style={{ width: '100%' }}
      color={'secondary'}
      optionText={'title'}
    />
  )
}

export default Tags

import { orgSelectors } from 'ducks/orgs'
import { useGetConnectedOrgsThatSharedIntegration } from 'projectSections/sections/payments/utils'
import { useTranslate } from 'ra-core'
import React from 'react'
import { useSelector } from 'react-redux'
import { AvailableFinanceIntegrationType } from 'types/orgs'
import GenericFinanceRoleConnection from './GenericFinanceRoleConnection'

type PropTypes = {
  integration: AvailableFinanceIntegrationType
}

const GenericFinanceRoleConnectionsWrapper: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()

  const org = useSelector(orgSelectors.getOrg)
  const orgsThatSharedIntegration = useGetConnectedOrgsThatSharedIntegration(
    props.integration.integration_name,
    org?.connected_orgs,
    org
  )

  // if this org isn't connected to the integration through another org and it's not active on their org, don't show anything
  if (!orgsThatSharedIntegration?.length && org?.[`enable_${props.integration?.integration_name}`] !== 'active')
    return null
  return (
    <div>
      <h2>{props.integration.display_name} Integration</h2>
      <p>
        {translate(
          "This integration requires all credit applications to be associated with a user in the lender's database. You can manage this User's connection to the lender below"
        )}
      </p>
      {org?.id && org[`enable_${props.integration?.integration_name}`] === 'active' && (
        <GenericFinanceRoleConnection
          integration={props.integration}
          orgId={org.id}
          //   if this org isn't using teams don't pass the org name, no need to make it clear which org the button corresponds to
          orgName={org?.connected_orgs?.length ? org.name : undefined}
        />
      )}
      {orgsThatSharedIntegration?.map((connectedOrg) => (
        <div>
          <GenericFinanceRoleConnection
            key={connectedOrg.id}
            integration={props.integration}
            orgId={connectedOrg.id}
            orgName={connectedOrg.name}
          />
        </div>
      ))}
    </div>
  )
}
export default GenericFinanceRoleConnectionsWrapper

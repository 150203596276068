import { InputAdornment, makeStyles, TextField, useMediaQuery } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/SearchOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { viewModeSelectors } from 'ducks/viewMode'
import UiContainer from 'elements/UiContainer'
import lodashDebounce from 'lodash/debounce'
import { IconButton } from 'opensolar-ui'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import type { OpenSolarThemeType } from 'Themes'

const uiKey = 'projects.search'
const DEFAULT_DEBOUNCE = 500

const useStyles2 = makeStyles(
  (theme: OpenSolarThemeType) => ({
    field: {
      minWidth: 270,
      [theme.breakpoints.up('sm')]: { width: 400 },
    },
    button: {
      minWidth: 36,
      padding: 0,
      height: 36,
    },
    icon: {
      height: 24,
      width: 24,
      color: theme.searchIconColor,
    },
  }),
  { name: 'project-list-search-field' }
)

export const ProjectListSearchTitle = ({
  setFilters,
  filterValues,
  endAdornment,
}: {
  setFilters: Function
  filterValues: { [key: string]: unknown }
  endAdornment?: React.ReactNode
}) => {
  const [searchText, setSearchText] = useState(filterValues.q)
  const isTypingRef = useRef(false)
  const isMobile = useMediaQuery((theme: OpenSolarThemeType) => theme.breakpoints.down('xs'))
  const isOsLite = useSelector(viewModeSelectors.isUserLite)

  useEffect(() => {
    if (isTypingRef.current) {
      //ignore filterValues when typing
    } else {
      setSearchText(filterValues.q)
    }
  }, [filterValues.q])
  const translate = useTranslate()
  const classes = useStyles2()

  const trackValueChanged = useCallback(() => {
    logAmplitudeEvent('generic_filter_interacted', {
      action: 'updated',
      key: 'q',
    })
  }, [])

  const debouncedTrackValueChanged = useMemo(() => {
    return lodashDebounce(trackValueChanged, DEFAULT_DEBOUNCE)
  }, [trackValueChanged])

  const handleChange = (event) => {
    debouncedTrackValueChanged()
    setFilters({ ...filterValues, q: event.target.value })
    setSearchText(event.target.value)
  }

  const handleSubmit = () => {
    setFilters({ ...filterValues, q: searchText })
  }

  return (
    <UiContainer uiKey={uiKey}>
      <TextField
        size="small"
        classes={{ root: classes.field }}
        value={searchText}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                onClick={(e) => {
                  e.preventDefault()
                  handleSubmit()
                }}
                classes={{ root: classes.button }}
              >
                <SearchIcon classes={{ root: classes.icon }} />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment,
        }}
        onFocus={() => {
          isTypingRef.current = true
        }}
        onBlur={() => (isTypingRef.current = false)}
        onChange={handleChange}
        variant="outlined"
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            ev.preventDefault()
            handleSubmit()
          }
        }}
        placeholder={translate(
          isOsLite ? 'Search projects' : isMobile ? 'Search...' : 'Search projects or contacts...'
        )}
      />
    </UiContainer>
  )
}

import { validateTiersInput } from '../common'

export const validateModuleData = (values, translate) => {
  const errors = {}
  if (values.lookup_in_component_database === false) {
    if (!values.custom_data_field_code) {
      errors.custom_data_field_code = [translate('Code is required.')]
    }
    if (!values.custom_data_field_manufacturer_name) {
      errors.custom_data_field_manufacturer_name = [translate('Manufacturer Name is required.')]
    }
    if (!values.custom_data_field_kw_stc) {
      errors.custom_data_field_kw_stc = [translate('Module Rating cannot be 0.')]
    } else if (isNaN(values.custom_data_field_kw_stc)) {
      errors.custom_data_field_kw_stc = [translate('Module Rating should be a number.')]
    }
    if (!values.custom_data_field_width) {
      errors.custom_data_field_width = [translate('Panel Width is required.')]
    } else if (values.custom_data_field_width > 5) {
      errors.custom_data_field_width = [translate('Please ensure the width is in meters.')]
    } else if (isNaN(values.custom_data_field_width)) {
      errors.custom_data_field_width = [translate('Panel Width should be a number.')]
    }
    if (!values.custom_data_field_thickness && values.custom_data_field_thickness !== 0) {
      errors.custom_data_field_thickness = [translate('Thickness is required.')]
    } else if (values.custom_data_field_thickness <= 0) {
      errors.custom_data_field_thickness = [translate('Please ensure the thickness is greater than 0mm')]
    } else if (isNaN(values.custom_data_field_width)) {
      errors.custom_data_field_thickness = [translate('Thickness should be a number.')]
    }
    if (!values.custom_data_field_height) {
      errors.custom_data_field_height = [translate('Panel Height is required.')]
    } else if (values.custom_data_field_height > 5) {
      errors.custom_data_field_height = [translate('Please ensure the height is in meters.')]
    } else if (isNaN(values.custom_data_field_height)) {
      errors.custom_data_field_height = [translate('Panel Height should be a number.')]
    }
    if (!values.custom_data_field_max_power_voltage) {
      errors.custom_data_field_max_power_voltage = [translate('Maximum Power Voltage is required.')]
    } else if (isNaN(values.custom_data_field_max_power_voltage)) {
      errors.custom_data_field_max_power_voltage = [translate('Value should be a number.')]
    } else if (values.custom_data_field_max_power_voltage < 0) {
      errors.custom_data_field_max_power_voltage = [translate('Value should be greater than or equal to 0.')]
    }

    if (values.custom_data_field_product_warranty) {
      if (isNaN(values.custom_data_field_product_warranty)) {
        errors.custom_data_field_product_warranty = [translate('Product Warranty should be a number.')]
      } else if (values.custom_data_field_product_warranty < 0) {
        errors.custom_data_field_product_warranty = [translate('Value should be greater than or equal to 0.')]
      }
    }

    if (values.custom_data_field_cells_in_series) {
      if (isNaN(values.custom_data_field_cells_in_series)) {
        errors.custom_data_field_cells_in_series = [translate('Cells In Series should be a number.')]
      } else if (values.custom_data_field_cells_in_series < 0) {
        errors.custom_data_field_cells_in_series = [translate('Value should be greater than or equal to 0.')]
      }
    }

    if (values.custom_data_field_performance_warranty) {
      if (isNaN(values.custom_data_field_performance_warranty)) {
        errors.custom_data_field_performance_warranty = [translate('Performance Warranty should be a number.')]
      } else if (values.custom_data_field_performance_warranty < 0) {
        errors.custom_data_field_performance_warranty = [translate('Value should be greater than or equal to 0.')]
      }
    }

    if (values.custom_data_field_imp) {
      if (isNaN(values.custom_data_field_imp)) {
        errors.custom_data_field_imp = [translate('Imp should be a number.')]
      } else if (values.custom_data_field_imp < 0) {
        errors.custom_data_field_imp = [translate('Value should be greater than or equal to 0.')]
      }
    }

    if (values.custom_data_field_annual_degradation_override) {
      if (isNaN(values.custom_data_field_annual_degradation_override)) {
        errors.custom_data_field_annual_degradation_override = [translate('Annual Degradation should be a number.')]
      } else if (values.custom_data_field_annual_degradation_override < 0) {
        errors.custom_data_field_annual_degradation_override = [
          translate('Value should be greater than or equal to 0.'),
        ]
      }
    }

    if (!values.custom_data_field_weight && values.custom_data_field_weight !== 0) {
      errors.custom_data_field_weight = [translate('Weight is required.')]
    } else if (values.custom_data_field_weight <= 0) {
      errors.custom_data_field_weight = [translate('Please ensure the weight is greater than 0kg')]
    } else if (isNaN(values.custom_data_field_width)) {
      errors.custom_data_field_weight = [translate('Weight should be a number.')]
    }

    if (values.custom_data_field_first_year_degradation) {
      if (isNaN(values.custom_data_field_first_year_degradation)) {
        errors.custom_data_field_first_year_degradation = [translate('First Year Degradation should be a number.')]
      } else if (values.custom_data_field_first_year_degradation < 0) {
        errors.custom_data_field_first_year_degradation = [translate('Value should be greater than or equal to 0.')]
      }
    }

    if (values.custom_data_field_isc) {
      if (isNaN(values.custom_data_field_isc)) {
        errors.custom_data_field_isc = [translate('Isc should be a number.')]
      } else if (values.custom_data_field_isc < 0) {
        errors.custom_data_field_isc = [translate('Value should be greater than or equal to 0.')]
      }
    }

    if (values.custom_data_field_bifaciality) {
      if (isNaN(values.custom_data_field_bifaciality)) {
        errors.custom_data_field_bifaciality = [translate('Bifaciality should be a number.')]
      } else if (values.custom_data_field_bifaciality < 0 || values.custom_data_field_bifaciality > 1) {
        errors.custom_data_field_bifaciality = [translate('Value should be between 0-1 (inclusive).')]
      }
    }

    if (values.custom_data_field_noct) {
      if (isNaN(values.custom_data_field_noct)) {
        errors.custom_data_field_noct = [translate('Nominal operating cell temperature should be a number.')]
      } else if (values.custom_data_field_noct < 0) {
        errors.custom_data_field_noct = [translate('Value should be greater than or equal to 0.')]
      }
    }

    if (values.custom_data_field_transmission) {
      if (isNaN(values.custom_data_field_transmission)) {
        errors.custom_data_field_transmission = [translate('Transmission should be a number.')]
      } else if (values.custom_data_field_transmission < 0 || values.custom_data_field_transmission > 1) {
        errors.custom_data_field_transmission = [translate('Value should be between 0-1 (inclusive).')]
      }
    }

    if (values.custom_data_field_temp_coefficient_isc) {
      if (isNaN(values.custom_data_field_temp_coefficient_isc)) {
        errors.custom_data_field_temp_coefficient_isc = [translate('Temp. Coefficient Isc should be a number.')]
      } else if (values.custom_data_field_temp_coefficient_isc < 0) {
        errors.custom_data_field_temp_coefficient_isc = [translate('Value should be greater than or equal to 0.')]
      }
    }

    if (!values.custom_data_field_voc) {
      errors.custom_data_field_voc = [translate('Voc is required.')]
    } else if (isNaN(values.custom_data_field_voc)) {
      errors.custom_data_field_voc = [translate('Voc should be a number.')]
    } else if (values.custom_data_field_voc < 0) {
      errors.custom_data_field_voc = [translate('Value should be greater than or equal to 0.')]
    }
    if (!values.custom_data_field_temp_coefficient_voc && values.custom_data_field_temp_coefficient_voc !== 0) {
      errors.custom_data_field_temp_coefficient_voc = [translate('Temp. Coefficient Voc is required.')]
    } else if (isNaN(values.custom_data_field_temp_coefficient_voc)) {
      errors.custom_data_field_temp_coefficient_voc = [translate('Temp. Coefficient Voc should be a number.')]
    } else if (values.custom_data_field_temp_coefficient_voc > 0) {
      errors.custom_data_field_temp_coefficient_voc = [translate('Value should be less than or equal to 0.')]
    }

    if (!values.custom_data_field_temp_coefficient_vpmax && values.custom_data_field_temp_coefficient_vpmax !== 0) {
      errors.custom_data_field_temp_coefficient_vpmax = [translate('Temp. Coefficient Pmax is required.')]
    } else if (isNaN(values.custom_data_field_temp_coefficient_vpmax)) {
      errors.custom_data_field_temp_coefficient_vpmax = [translate('Temp. Coefficient Pmax should be a number.')]
    } else if (values.custom_data_field_temp_coefficient_vpmax > 0) {
      errors.custom_data_field_temp_coefficient_vpmax = [translate('Value should be less than or equal to 0.')]
    }
  } else {
    if (!values.module) {
      errors.module = [translate('Please select a module from dropdown.')]
    }
  }
  validateTiersInput(values, errors)
  return errors
}

export const validateModuleDataLite = (values, translate) => {
  const errors = {}

  if (!values.code) {
    errors.code = [translate('Code is required.')]
  }
  if (!values.manufacturer_name) {
    errors.manufacturer_name = [translate('Manufacturer Name is required.')]
  }
  if (!values.kw_stc) {
    errors.kw_stc = [translate('Module Rating cannot be 0.')]
  } else if (isNaN(values.kw_stc)) {
    errors.kw_stc = [translate('Module Rating should be a number.')]
  }
  if (!values.width) {
    errors.width = [translate('Panel Width is required.')]
  } else if (isNaN(values.width)) {
    errors.width = [translate('Panel Width should be a number.')]
  }
  if (!values.height) {
    errors.height = [translate('Panel Height is required.')]
  } else if (isNaN(values.height)) {
    errors.height = [translate('Panel Height should be a number.')]
  }
  if (values.noct) {
    if (isNaN(values.noct)) {
      errors.noct = [translate('Nominal operating cell temperature should be a number.')]
    } else if (values.noct < 0) {
      errors.noct = [translate('Value should be greater than or equal to 0.')]
    }
  }
  if (!values.temp_coefficient_vpmax && values.temp_coefficient_vpmax !== 0) {
    errors.temp_coefficient_vpmax = [translate('Temp. Coefficient Pmax is required.')]
  } else if (isNaN(values.temp_coefficient_vpmax)) {
    errors.temp_coefficient_vpmax = [translate('Temp. Coefficient Pmax should be a number.')]
  } else if (values.temp_coefficient_vpmax > 0) {
    errors.temp_coefficient_vpmax = [translate('Value should be less than or equal to 0.')]
  }
  if (!values.voc) {
    errors.voc = [translate('Voc is required.')]
  } else if (isNaN(values.voc)) {
    errors.voc = [translate('Voc should be a number.')]
  } else if (values.voc < 0) {
    errors.voc = [translate('Value should be greater than or equal to 0.')]
  }
  if (!values.temp_coefficient_voc && values.temp_coefficient_voc !== 0) {
    errors.temp_coefficient_voc = [translate('Temp. Coefficient Voc is required.')]
  } else if (isNaN(values.temp_coefficient_voc)) {
    errors.temp_coefficient_voc = [translate('Temp. Coefficient Voc should be a number.')]
  } else if (values.temp_coefficient_voc > 0) {
    errors.temp_coefficient_voc = [translate('Value should be less than or equal to 0.')]
  }
  if (!values.max_power_voltage) {
    console.log('hi ? ')
    errors.max_power_voltage = [translate('Maximum Power Voltage is required.')]
  } else if (isNaN(values.max_power_voltage)) {
    errors.max_power_voltage = [translate('Value should be a number.')]
  } else if (values.max_power_voltage < 0) {
    errors.max_power_voltage = [translate('Value should be greater than or equal to 0.')]
  }
  return errors
}

import { useCallback } from 'react'
import restClient from 'restClient'

var restClientInstance = restClient(window.API_ROOT + '/api')

export const useFetchComponentFromDB = () => {
  return useCallback((componentType, componentCode) => {
    const componentTypeToEndpoint = {
      battery: 'component_batteries',
      inverter: 'component_inverters',
      module: 'component_modules',
      other: 'component_others',
    }
    return restClientInstance('CUSTOM_GET', 'custom', {
      url: `${componentTypeToEndpoint[componentType]}/?code=${componentCode}`,
    })
  }, [])
}

import { updateProjectSection } from 'actions/project'
import PropTypes from 'prop-types'
import { memo, useEffect, useState } from 'react'
import { useForm } from 'react-final-form'
import { useDispatch } from 'react-redux'
import PageLoadingSkeleton from '../elements/displayComponents/PageLoadingSkeleton'

//Hack to force map render in the background
const forceMapResizing = (mode) => {
  const MODES_TO_SKIP_HACKS = ['background', 'hidden']
  //For all onWidowResize events pass forceRenderOnFinished=true to force clearing of artifacts
  if (MODES_TO_SKIP_HACKS.includes(mode)) return
  window.Designer.onWindowResize(null, true)

  setTimeout(function () {
    if (window.logger) window.logger('window.Designer.onWindowResize() after timeout 1000')
    window.Designer.onWindowResize(null, true)
  }, 1000)

  //Added as hack when loading MyEnergy after Studio
  setTimeout(function () {
    if (window.logger) window.logger('window.Designer.onWindowResize() after timeout 3000')
    window.Designer.onWindowResize(null, true)
  }, 3000)
}

const SectionContainer = ({ section, mode, children, className = '' }) => {
  const [initializing, setInitializing] = useState(true)
  const form = useForm()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(updateProjectSection(section))
  }, [])

  useEffect(() => {
    // do we need to check current studioMode?
    window.setStudioMode(mode)
    forceMapResizing(mode)
    setInitializing(false)
  }, [mode])

  useEffect(() => {
    if (!initializing) {
      //have to set invalid field touched in order to show error message
      form.mutators.touchInvalidFields()
    }
  }, [initializing])

  if (initializing) return <PageLoadingSkeleton showTopNap={false} />

  if (!children) {
    return null
  }
  return <div className={className}>{children}</div>
}
SectionContainer.propTypes = {
  mode: PropTypes.string.isRequired,
}
export default memo(SectionContainer)

import { useTranslate } from 'react-admin'
import { numberWithCommas } from 'util/misc'

const getTitleSuffix = (incentiveData, translate) => {
  // handle old inventive data which doesn't have incentive_id
  const availableIncentives = window.AccountHelper.getIncentivesAvailable()
  const incentiveIds = window.AccountHelper.loadedData?.incentives?.map((x) => x.id) || []
  const isArchived = Boolean(incentiveData.incentive_id)
    ? //find incentive in availableIncentives
      !availableIncentives.some((incentive) => incentiveData.incentive_id === incentive.id)
    : false
  const isOwnIncentive = incentiveIds.includes(incentiveData.incentive_id)
  return isArchived && isOwnIncentive ? ` (${translate('archived')})` : ''
}

const incentiveSummary = (incentiveData, currencySymbol, translate, extraLeft = '') => {
  const incentiveValue = Number.isNaN(incentiveData.inc_tax) ? incentiveData.value : incentiveData.inc_tax
  return (
    <li className="extra-small-studio-list">
      {incentiveData.title +
        getTitleSuffix(incentiveData, translate) +
        ': ' +
        currencySymbol +
        numberWithCommas(incentiveValue) +
        ' ' +
        extraLeft}
    </li>
  )
}

export const getBillIncentives = (system) => {
  // This works based on the fact that incentives applied to a system option will always apply to that system
  // option regardless of which utility tariff gets applied
  const bills = system?.bills?.proposed
  if (!bills) return []

  const firstProposedBillData = bills?.[Object.keys(bills)[0]]
  return firstProposedBillData?.incentives ? firstProposedBillData?.incentives : []
}

export const getIncentivesAppliedOverTime = (system) => {
  // This works based on the fact that incentives applied to a system option will always apply to that system
  // option regardless of which payment option gets applied
  const paymentOptions = system?.payment_options
  const firstPaymentOption = paymentOptions ? paymentOptions[Object.keys(paymentOptions)[0]] : undefined
  return firstPaymentOption?.incentives ? firstPaymentOption?.incentives : []
}

const ShowAutoAppliedIncentiveGeneric = ({ system, currencySymbol, incentiveType }) => {
  const translate = useTranslate()

  const billIncentives = getBillIncentives(system)
  const incentivesAppliedOverTime = getIncentivesAppliedOverTime(system)
  const upfrontIncentives = system?.pricing?.['incentive_to_' + incentiveType]?.incentives || []

  const numIncentivesApplied = billIncentives.length + incentivesAppliedOverTime.length + upfrontIncentives.length
  return numIncentivesApplied > 0 ? (
    <>
      <p>{translate(`Applied %{type} Incentives`, { type: translate(`incentive_type.${incentiveType}`) }) + ':'}</p>
      <ul>
        {upfrontIncentives
          .filter((i) => i.automatic === true)
          .map((incentiveData) => incentiveSummary(incentiveData, currencySymbol, translate))}
        {incentiveType === 'customer' &&
          billIncentives.map((incentiveData) => incentiveSummary(incentiveData, currencySymbol, translate, '(Year 1)'))}
        {incentiveType === 'customer' &&
          incentivesAppliedOverTime.map((incentiveData) =>
            incentiveSummary(incentiveData, currencySymbol, translate, '(Year 1)')
          )}
      </ul>
    </>
  ) : null
}

export default ShowAutoAppliedIncentiveGeneric

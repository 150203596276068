import { CommonStipulationType } from 'ducks/myEnergy'
import ProUxButton from 'elements/proUXButtons/ProUXButton'
import React, { useMemo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type PropTypes = {
  decision: 'Pending' | 'Conditionally approved' | 'Approved'
  onClose: () => void
  stips?: CommonStipulationType[]
}

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    margin: '10px 0px 20px 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
}))

const GenericDecisionExplanation: React.FunctionComponent<PropTypes> = (props) => {
  const classes = useStyles()
  const header = useMemo(() => {
    return `Financing Decision - ${props.decision}`
  }, [props.decision])

  const paragraph = useMemo(() => {
    return `Your application for financing is ${props.decision?.toLowerCase()}. Please check your email and contact your salesperson for more details and next steps.`
  }, [props.decision])

  return (
    <div className={classes.wrapper}>
      <div>
        <h2>{header}</h2>
        <p>{paragraph}</p>
      </div>

      {/* this is currently only used for lightreach and is a bit of a stopgap until we support stip uploads from our proposal */}
      {props.stips?.length ? (
        <div>
          <h3>Required Documentation</h3>
          <p>
            The lender will require the following documentation before a full approval is granted. Please work with your
            sales representative to send these documents to the lender directly:
          </p>
          {props.stips.map((stip, index) => (
            <div key={index}>{stip.description}</div>
          ))}
        </div>
      ) : null}

      <div style={{ marginTop: '30px' }}>
        <ProUxButton type="secondary" onClick={props.onClose} label="Continue" />
      </div>
    </div>
  )
}
export default GenericDecisionExplanation

import { Switch } from 'opensolar-ui'
import { FC } from 'react'
import { CodeBlock } from '../CodeBlock'
import ComponentPage from '../ComponentPage'
import { FeatureGrid } from '../FeatureGrid'
import { SwitchBasic, SwitchColours, SwitchDisabled, SwitchIcons, SwitchSizes } from './SwitchUsage'

const SwitchUsage_code = require('!!raw-loader!./SwitchUsage').default

export const SwitchDemo: FC = () => {
  return (
    <ComponentPage
      name={'Switch'}
      import="import { Switch } from 'opensolar-ui'"
      description={'Switch is used for Boolean input fields.'}
      links={{
        mui4: 'https://v4.mui.com/components/switches/',
        mui5: 'https://mui.com/material-ui/react-switch/',
      }}
    >
      <CodeBlock name="Switch Basic Usage" code={SwitchUsage_code} codePart={1}>
        <SwitchBasic />
      </CodeBlock>
      <CodeBlock name="Switch Colors" code={SwitchUsage_code} codePart={2} description="How to use the color prop">
        <SwitchColours />
      </CodeBlock>
      <CodeBlock name="Switch Sizes" code={SwitchUsage_code} codePart={3} description="How to use the size prop">
        <SwitchSizes />
      </CodeBlock>
      <CodeBlock name="Switch Icons" code={SwitchUsage_code} codePart={4} description="How to use the icons props">
        <SwitchIcons />
      </CodeBlock>
      <CodeBlock name="Switch Disabled" code={SwitchUsage_code} codePart={5} description="How to use the disabled prop">
        <SwitchDisabled />
      </CodeBlock>

      <CodeBlock name="Switch Feature Grid" hideGrid={true}>
        <FeatureGrid
          component={Switch}
          colHeaders={[]}
          rowHeaders={[]}
          cols={[
            { color: 'default' },
            { color: 'primary' },
            { color: 'secondary' },
            { color: 'info' },
            { color: 'success' },
            { color: 'warning' },
            { color: 'error' },
          ]}
          rows={[
            { _type: 'heading', _title: 'Checked', defaultChecked: true },
            { size: 'small' },
            { size: 'medium' },
            { _type: 'heading', _title: 'Not Checked', defaultChecked: false },
            { size: 'small' },
            { size: 'medium' },
            { _type: 'heading', _title: 'Disabled Checked', disabled: true, defaultChecked: true },
            { size: 'small' },
            { size: 'medium' },
            { _type: 'heading', _title: 'Disabled Not Checked', disabled: true, defaultChecked: false },
            { size: 'small' },
            { size: 'medium' },
          ]}
        />
      </CodeBlock>
    </ComponentPage>
  )
}

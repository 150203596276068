import { deleteUnconfirmedLineItems } from 'ducks/orderComponents'
import type LineItemType from 'pages/ordering/OrderLineItem'
import ProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/projectOrderPresenter'
import { memo, useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import type OrderLineItem from '../OrderLineItem'
import { STOCK_LEVEL_STATUS } from '../constants'
import { FeatureConfigDiscountNotification } from './CartDiscounts'
import OutOfStockItemsAlert from './OutOfStockItemsAlert'
import UnavailableItemsAlert from './UnavailableItemsAlert'
import OrderTableContainer from './table/OrderTableContainer'
import OrderTableContent from './table/OrderTableContent'
import OrderTableHeader from './table/OrderTableHeader'

export const getOrderTableAvailabilityCategory = (lineItem: LineItemType) => {
  const stockLevelStatus = ProjectOrderPresenter.getStockLevelStatusFromLineItem(lineItem)
  switch (stockLevelStatus) {
    case 'loading':
      return 'available'
    case 'available':
      return 'available'
    case 'low_stock':
      return 'available'
    case 'partial_available':
      return 'unavailable'
    case 'not_available':
      return 'unavailable'
    case 'out_of_stock':
      return 'out_of_stock'
    case STOCK_LEVEL_STATUS.AVAILABLE_FOR_PRE_ORDER:
      return 'available'
  }
}

const OrdersTable = ({
  editable,
  lineItems,
  trackSource,
}: {
  editable: boolean
  lineItems: LineItemType[]
  trackSource?: string
}) => {
  const [availableLineItems, outOfStockLineItems, unavailableLineItems] = useMemo(() => {
    const availableLineItems: OrderLineItem[] = []
    const unavailableLineItems: OrderLineItem[] = []
    const outOfStockLineItems: OrderLineItem[] = []

    lineItems.forEach((lineItem: OrderLineItem) => {
      const availability = getOrderTableAvailabilityCategory(lineItem)
      if (availability === 'available') availableLineItems.push(lineItem)

      if (availability === 'out_of_stock') outOfStockLineItems.push(lineItem)

      if (availability === 'unavailable') unavailableLineItems.push(lineItem)
    })

    return [availableLineItems, outOfStockLineItems, unavailableLineItems]
  }, [lineItems])

  const dispatch = useDispatch()
  const clearUnconfirmedOrder = useCallback(() => {
    dispatch(deleteUnconfirmedLineItems())
  }, [])

  useEffect(() => {
    return clearUnconfirmedOrder
  }, [])

  return (
    <>
      <FeatureConfigDiscountNotification />
      <OrderTableContainer isAvailable={true}>
        <OrderTableHeader />
        <OrderTableContent editable={editable} lineItems={availableLineItems} trackSource={trackSource} />
      </OrderTableContainer>
      {outOfStockLineItems.length > 0 && (
        <section>
          <OutOfStockItemsAlert />
          <OrderTableContainer isAvailable={false}>
            <OrderTableContent editable={editable} lineItems={outOfStockLineItems} />
          </OrderTableContainer>
        </section>
      )}
      {unavailableLineItems.length > 0 && (
        <section>
          <UnavailableItemsAlert unavailableLineItems={unavailableLineItems} />
          <OrderTableContainer isAvailable={false}>
            <OrderTableContent editable={editable} lineItems={unavailableLineItems} trackSource={trackSource} />
          </OrderTableContainer>
        </section>
      )}
    </>
  )
}

export default memo(OrdersTable)

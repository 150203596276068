import { ContiguousPanelColumn, ContiguousPanelRow, HasPosition, MountingCalcResult } from '../types'
import { BomGeneratorAbstract } from './BomGeneratorAbstract'

export abstract class PerBlockEdgeBomGeneratorAbstract extends BomGeneratorAbstract {
  abstract generateBom(result: MountingCalcResult): Promise<MountingCalcResult>
  getRightEdges(): Edge[] {
    const panelBlock = this.block
    var panelsWithExposedRightEdges: ContiguousPanelColumn[] = []
    //iterate through columns
    const columnQty = panelBlock.rows.reduce((acc, curr) => (curr.length > acc ? curr.length : acc), 0)
    for (let columnIndex = 0; columnIndex < columnQty; columnIndex++) {
      let currentPanelSet: ContiguousPanelColumn = []
      panelBlock.rows.forEach((row) => {
        var panelRightEdgeExposed: boolean
        const currentPanel = row[columnIndex]
        if (currentPanel.isActive) {
          if (columnIndex + 1 === columnQty) {
            panelRightEdgeExposed = true // all right-most panels will have exposed right edge
          } else {
            const nextPanel = row[columnIndex + 1]
            panelRightEdgeExposed = !nextPanel.isActive
          }
        } else {
          panelRightEdgeExposed = false
        }
        if (panelRightEdgeExposed) {
          currentPanelSet.push(currentPanel)
        } else {
          panelsWithExposedRightEdges.push(currentPanelSet)
          currentPanelSet = []
        }
      })

      if (currentPanelSet.length > 0) panelsWithExposedRightEdges.push(currentPanelSet)
    }

    const rightEdges: Edge[] = panelsWithExposedRightEdges.flatMap((panelSet) => {
      if (panelSet.length) {
        const firstPanel = panelSet[0]
        const lastPanel = panelSet[panelSet.length - 1]
        return {
          top: firstPanel.top,
          left: firstPanel.left,
          length:
            lastPanel.top +
            (lastPanel.orientation === 'portrait' ? lastPanel.height : lastPanel.width) -
            firstPanel.top,
        }
      } else {
        return []
      }
    })

    return rightEdges
  }

  getLeftEdges(): Edge[] {
    const panelBlock = this.block
    var panelsWithExposedLeftEdges: ContiguousPanelColumn[] = []
    //iterate through columns
    const columnQty = panelBlock.rows.reduce((acc, curr) => (curr.length > acc ? curr.length : acc), 0)
    for (let columnIndex = 0; columnIndex < columnQty; columnIndex++) {
      let currentPanelSet: ContiguousPanelColumn = []
      panelBlock.rows.forEach((row) => {
        var panelLeftEdgeExposed: boolean
        const currentPanel = row[columnIndex]
        if (currentPanel.isActive) {
          if (columnIndex === 0) {
            panelLeftEdgeExposed = true // all left-most panels will have exposed left edge
          } else {
            const previousPanel = row[columnIndex - 1]
            panelLeftEdgeExposed = !previousPanel.isActive
          }
        } else {
          panelLeftEdgeExposed = false
        }
        if (panelLeftEdgeExposed) {
          currentPanelSet.push(currentPanel)
        } else {
          panelsWithExposedLeftEdges.push(currentPanelSet)
          currentPanelSet = []
        }
      })

      if (currentPanelSet.length > 0) panelsWithExposedLeftEdges.push(currentPanelSet)
    }

    const leftEdges = panelsWithExposedLeftEdges.flatMap((panelSet) => {
      if (panelSet.length) {
        const firstPanel = panelSet[0]
        const lastPanel = panelSet[panelSet.length - 1]
        return {
          top: firstPanel.top,
          left: firstPanel.left,
          length:
            lastPanel.top +
            (lastPanel.orientation === 'portrait' ? lastPanel.height : lastPanel.width) -
            firstPanel.top,
        }
      } else {
        return []
      }
    })

    return leftEdges
  }

  getTopEdges(): Edge[] {
    const panelBlock = this.block
    var panelsWithExposedTopEdges: ContiguousPanelRow[] = []
    //iterate through rows
    panelBlock.rows.forEach((row, rowIndex) => {
      let currentPanelSet: ContiguousPanelRow = []
      for (let columnIndex = 0; columnIndex < row.length; columnIndex++) {
        var panelTopEdgeExposed: boolean
        const currentPanel = row[columnIndex]
        if (currentPanel.isActive) {
          if (rowIndex === 0) {
            panelTopEdgeExposed = true // all top row panels with have exposed top edge
          } else {
            const panelAbove = panelBlock.rows[rowIndex - 1][columnIndex]
            panelTopEdgeExposed = !panelAbove.isActive
          }
        } else {
          panelTopEdgeExposed = false
        }
        if (panelTopEdgeExposed) {
          currentPanelSet.push({ ...currentPanel, row: rowIndex, col: columnIndex })
        } else {
          panelsWithExposedTopEdges.push(currentPanelSet)
          currentPanelSet = []
        }
      }

      if (currentPanelSet.length > 0) panelsWithExposedTopEdges.push(currentPanelSet)
    })

    const topEdges = panelsWithExposedTopEdges.flatMap((panelSet) => {
      if (panelSet.length) {
        const firstPanel = panelSet[0]
        const lastPanel = panelSet[panelSet.length - 1]
        const row = firstPanel.row as number
        const firstCol = firstPanel.col as number
        const lastCol = lastPanel.col as number
        const hasInnerCorners = Boolean(
          panelBlock.rows[row - 1]?.[firstCol - 1] || panelBlock.rows[row - 1]?.[lastCol + 1]
        )
        return {
          top: firstPanel.top,
          left: firstPanel.left,
          length:
            lastPanel.left +
            (lastPanel.orientation === 'portrait' ? lastPanel.width : lastPanel.height) -
            firstPanel.left,
          hasInnerCorners,
        }
      } else {
        return []
      }
    })

    return topEdges
  }

  getBottomEdges(): Edge[] {
    const panelBlock = this.block
    var panelsWithExposedBottomEdges: ContiguousPanelRow[] = []
    //iterate through rows
    panelBlock.rows.forEach((row, rowIndex) => {
      let currentPanelSet: ContiguousPanelRow = []
      for (let columnIndex = 0; columnIndex < row.length; columnIndex++) {
        var panelBottomEdgeExposed: boolean
        const currentPanel = row[columnIndex]
        if (currentPanel.isActive) {
          if (rowIndex + 1 === panelBlock.rows.length) {
            panelBottomEdgeExposed = true // all bottom row panels with have exposed bottom edge
          } else {
            const panelBelow = panelBlock.rows[rowIndex + 1][columnIndex]
            panelBottomEdgeExposed = !panelBelow.isActive
          }
        } else {
          panelBottomEdgeExposed = false
        }
        if (panelBottomEdgeExposed) {
          currentPanelSet.push(currentPanel)
        } else {
          panelsWithExposedBottomEdges.push(currentPanelSet)
          currentPanelSet = []
        }
      }

      if (currentPanelSet.length > 0) panelsWithExposedBottomEdges.push(currentPanelSet)
    })

    const bottomEdges = panelsWithExposedBottomEdges.flatMap((panelSet) => {
      if (panelSet.length) {
        const firstPanel = panelSet[0]
        const lastPanel = panelSet[panelSet.length - 1]
        return {
          top: firstPanel.top,
          left: firstPanel.left,
          length:
            lastPanel.left +
            (lastPanel.orientation === 'portrait' ? lastPanel.width : lastPanel.height) -
            firstPanel.left,
        }
      } else {
        return []
      }
    })

    return bottomEdges
  }
}

export interface Edge extends HasPosition {
  length: number
  hasInnerCorners?: boolean // Currently only calculated for top edges. Eg. if you imagine this is the top edge --__-- of an array, the middle section has two inner corners on each end, whereas the outer two don't have any
}

export interface Corner extends HasPosition {}

// @ts-nocheck
import React from 'react'
import { Edit } from 'react-admin'
import EditTransactionDialogFormContent from './EditTransactionDialogFormContent'

type PropTypes = {
  toolbar: any
  hideCancelButton: boolean
  redirect?: string
}

const EditTransactionDialogFormWrapper: React.FunctionComponent<PropTypes> = (props) => {
  return (
    <Edit {...props} renderChildrenBeforeRecord={true} hideTitle={true}>
      <EditTransactionDialogFormContent {...props} />
    </Edit>
  )
}

export default EditTransactionDialogFormWrapper

import React from 'react'
import { useMediaQuery } from '@material-ui/core'

const styles = {
  mobile: {
    margin: 0,
    display: 'inline-block',
    lineHeight: '10px',
  },
  desktop: {
    textAlign: 'center',
    margin: '15px 0px 15px 0px',
  },
}

const Divider = ({ myeStyles, showOr, loanType }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const style = isMobile && loanType === 'sunlight_loan_application' ? styles.mobile : styles.desktop
  return (
    <div style={{ color: myeStyles.textColor, ...style }}>
      <div>
        {showOr !== false && <span style={{ backgroundColor: 'white', padding: 10 }}>or</span>}
        <hr style={{ marginTop: -7, borderBottom: 'none', borderTop: '1px solid #d3d3d3' }} />
      </div>
    </div>
  )
}

export default Divider

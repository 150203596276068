import { Paper } from '@material-ui/core'
import { default as EdgeTypeIcon, default as FillRectangleIcon } from '@material-ui/icons/BorderStyle'
import BrightnessIcon from '@material-ui/icons/Brightness4Outlined'
import MergeIcon from '@material-ui/icons/CallMergeOutlined'
import CloseIcon from '@material-ui/icons/CloseOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import DuplicateIcon from '@material-ui/icons/FileCopyOutlined'
import FullDesignIcon from '@material-ui/icons/GridOnOutlined'
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined'
import FloatIcon from '@material-ui/icons/LinearScaleOutlined'
import LinkedCameraIcon from '@material-ui/icons/LinkedCameraOutlined'
import LockIcon from '@material-ui/icons/LockOutlined'
import MoveDownIcon from '@material-ui/icons/LowPriorityOutlined'
import FixSkewIcon from '@material-ui/icons/PanoramaHorizontalOutlined'
import RedoIcon from '@material-ui/icons/RedoOutlined'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import PortraitLandscapeIcon from '@material-ui/icons/Rotate90DegreesCcwOutlined'
import { default as RotateIcon } from '@material-ui/icons/RotateRightOutlined'
import ScreenRotationIcon from '@material-ui/icons/ScreenRotationOutlined'
import PlansetIcon from '@material-ui/icons/SelectAllOutlined'
import SlopeIcon from '@material-ui/icons/SignalCellular4BarOutlined'
import RotateDisabledIcon from '@material-ui/icons/SyncDisabledOutlined'
import UndoIcon from '@material-ui/icons/UndoOutlined'
import Alert from 'elements/Alert'
import { PrimaryButton } from 'elements/button/GenericButton'
import { Button } from 'opensolar-ui'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { withTranslate } from 'react-admin'
import { connect } from 'react-redux'

import { MagicWandIcon } from 'icons/MagicWandIcon'
import compose from 'recompose/compose'
import { setAutoStringModuleUuids } from 'reducer/designer/autoString'
import { setSelectedDesignTab } from 'reducer/designer/view'
import appStorage from 'storage/appStorage'
import withStudioSignals from 'Studio/signals/withStudioSignals'
import { getMeasurementsFromState, measurementsToUnitLabel, metersToFeet, trimDecimalPlaces } from '../util/misc'

const defaultBackgroundColor = 'rgba(250,250,250, 0.95)'
const defaultBackgroundColorOnHover = 'rgba(250,250,250,1)'
export const styles = {
  container: {
    zIndex: 10000,
    marginBottom: 10,

    // // Add negative padding to container and move padding on individual buttons to left
    // // This ensures there is no padding on the right of the last element in each row
    // marginLeft: -10,
  },
  button: {
    minWidth: 70,
    height: 45,
    marginTop: 5,
    marginLeft: 10,
    padding: '6px 16px',
    fontSize: '0.875rem',
    fontWeight: '500',
    lineHeight: 1.75,
  },
  iconStyle: {
    marginLeft: 0,
    marginBottom: 10,
    marginRight: 'unset',
    width: 24,
    height: 24,
  },
  mobileIconStyle: {
    marginLeft: 0,
    width: 24,
    height: 24,
    marginTop: 0,
    marginBottom: 5,
    marginRight: 'unset',
  },
  mobileButton: {
    minWidth: 60,
    // backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderRadius: 1,
    width: '100%',
    margin: 0,
    paddingTop: 0,
    // paddingLeft: 4,
  },
  buttonWithMargin: {
    minWidth: 40,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    margin: 3,
  },
  mobileButtonLabel: {
    minWidth: 0,
    top: 24,
    // left: 6,
    // right: 0,
    position: 'absolute',
    fontSize: '7px',
    fontWeight: 400,
    // marginLeft: -4,
    // marginRight: -4,
    paddingRight: 0,
    paddingLeft: 0,
  },
  buttonLabel: {
    minWidth: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    fontSize: '7px',
    fontWeight: 400,
    // marginLeft: -4,
    // marginRight: -4,
    paddingRight: 0,
    paddingLeft: 0,
  },
  panelModal: {
    position: 'absolute',
    left: '50%',
    right: '50%',
    top: 100,
    width: 300,
    marginLeft: -150,
  },
}

class Tools extends Component {
  constructor(props) {
    super(props)
    // var _state = {
    //   visible: true,
    //   selectedObject: window.editor ? window.editor.selected : null,
    //   selectedObjectType:
    //     window.editor && window.editor.selected
    //       ? window.editor.selected.type
    //       : null,
    //   buttonVisible: true,
    //   transformMode: null,
    //   deviceOrientationMode: false,
    //   toolsActive: true,
    //   panelActive: null,
    // }

    var _state = this.stateFromObject()

    //Override with any supplied state - for use in storybook
    if (props.state) {
      for (var key in props.state) {
        _state[key] = props.state[key]
      }
    }

    this.state = _state
  }

  componentDidMount() {
    this.props.useStudioSignalsLazy(
      this.refreshPanel,
      [
        'objectChanged',
        'objectSelected',
        'sceneGraphChanged',
        'transformModeChanged',
        'systemCalculationsAddedToQueue',
        'systemCalculationsRemovedFromQueue',
        'controlModeChanged',

        // @TODO: Reinstate this to fix the bottom right toolbar, but disable for now because otherwise
        // react renders whenever we orbit the view
        //'cameraChanged',
      ],
      this
    )

    //Use props.name instead of hard-coding because multiple tools instances can exist
    // referenceSave.call(this, this.props.name, [
    // 'objectChanged',
    // 'objectSelected',
    // 'sceneGraphChanged',
    // 'transformModeChanged',
    // 'systemCalculationsAddedToQueue',
    // 'systemCalculationsRemovedFromQueue',
    // 'controlModeChanged',
    // @TODO: Reinstate this to fix the bottom right toolbar, but disable for now because otherwise
    // react renders whenever we orbit the view
    //'cameraChanged',
    // ])
  }

  componentWillUnmount() {
    // referenceClear.call(this)
  }

  handleToggleVisible = () => {
    if (window.togglePropertiesVisibility) {
      window.togglePropertiesVisibility(this.state.visible)
    } else {
      console.log('Warning: window.togglePropertiesVisibility not found')
    }

    this.setState({ visible: !this.state.visible })
  }

  toolIsActive(toolName) {
    var toolsActive =
      window.editor && window.editor.selected && window.editor.selected.toolsActive
        ? window.editor.selected.toolsActive()
        : true

    if (toolsActive === true) {
      return true
    } else if (toolsActive === false) {
      return false
    } else if (toolsActive.hasOwnProperty(toolName)) {
      return toolsActive[toolName]
    } else {
      //default to `enabled` unless overridden
      return true
    }
  }

  stateFromObject() {
    return {
      visible: window.editor && window.editor.selected ? true : false,
      transformMode:
        window.editor && window.editor.viewport && window.editor.viewport.transformControls
          ? window.editor.viewport.transformControls.transformMode
          : null,
      selectedObject: window.editor ? window.editor.selected : null,
      selectedObjectType: window.editor && window.editor.selected ? window.editor.selected.type : null,
      buttonVisible: true,
      deviceOrientationMode: false,
      toolsActive: true,
      panelActive: null,
      controlMode: window.Designer.controlMode,
      modelEnabled: window.Designer.controlMode === 'both' || window.Designer.controlMode === 'scene',
      imageryEnabled: window.Designer.controlMode === 'both' || window.Designer.controlMode === 'map',
      extraToolsOpen: appStorage.getString('extraViewTools'),
      undoDisabled: window.editor.history.undos.length === 0,
      redoDisabled: window.editor.history.redos.length === 0,
      enableTilt: window.ViewHelper.selectedView() && window.ViewHelper.selectedView().allowTilt,
      viewShade: window.editor.getTerrain() ? window.editor.getTerrain().useHeatmap() : false,
      // hideTools: false,
    }
  }

  refreshPanel() {
    if (window.Designer && !window.Designer.allowUiUpdates()) {
      return
    }
    this.setState(this.stateFromObject())
  }

  render() {
    var _this = this
    if (!this.state.buttonVisible) {
      return null
    }

    if (!this.state.visible) {
      return null
    }

    const { isMobile, translate } = this.props
    const { undoDisabled, redoDisabled } = this.state

    const raisedButtonButtonStyle = isMobile ? {} : { height: 48 }
    const raisedButtonLabelStyle = isMobile ? styles.mobileButtonLabel : styles.buttonLabel
    const raisedButtonStyle = isMobile ? styles.mobileButton : styles.button
    const raisedButtonIconStyle = isMobile ? styles.mobileIconStyle : styles.iconStyle

    const disable3DTools = window.ViewHelper.disable3DTools()
    const startIconStyle = { margin: 0, width: 'unset', height: 'unset' }

    return (
      <div className="HideScrollBar" style={this.props.style ? this.props.style : {}}>
        <div style={styles.container}>
          {!isMobile && disable3DTools && this.state.selectedObjectType === 'ViewDummy' && (
            <Alert severity="error">When 3D data is loaded, editing 3D objects is locked in other views.</Alert>
          )}
          {isMobile && (
            <PrimaryButton
              startIcon={<UndoIcon style={raisedButtonIconStyle} />}
              disabled={Boolean(undoDisabled)}
              buttonStyle={raisedButtonButtonStyle}
              labelWrapperStyle={raisedButtonLabelStyle}
              style={raisedButtonStyle}
              onClick={() => {
                !undoDisabled && window.editor.undo()
              }}
              label="UNDO (Ctrl Z)"
            />
          )}
          {isMobile && (
            <PrimaryButton
              startIcon={<RedoIcon style={raisedButtonIconStyle} />}
              disabled={Boolean(redoDisabled)}
              buttonStyle={raisedButtonButtonStyle}
              labelWrapperStyle={raisedButtonLabelStyle}
              style={raisedButtonStyle}
              onClick={() => {
                !redoDisabled && window.editor.redo()
              }}
              label="REDO (Ctrl Y)"
            />
          )}
          {window.editor.filter('type', 'OsFacet').length > 0 &&
            this.state.selectedObject.type === 'OsFacet' &&
            !this.state.selectedObject.isNonSpatial() && (
              <PrimaryButton
                id="PanelPropertiesAutoDesign"
                startIcon={
                  <FullDesignIcon
                    style={{
                      ...raisedButtonIconStyle,
                      color:
                        this.state.selectedObject.moduleQuantity &&
                        this.state.selectedObject.moduleQuantity() === 0 &&
                        window.editor.filter('type', 'OsFacet').length > 0
                          ? '#1890ff'
                          : '#000000',
                    }}
                  />
                }
                buttonStyle={raisedButtonButtonStyle}
                labelWrapperStyle={{
                  ...raisedButtonLabelStyle,
                  color:
                    this.state.selectedObject.moduleQuantity &&
                    this.state.selectedObject.moduleQuantity() === 0 &&
                    window.editor.filter('type', 'OsFacet').length > 0
                      ? '#1890ff'
                      : '#000000',
                }}
                style={raisedButtonStyle}
                disabled={window.editor.filter('type', 'OsFacet').length === 0}
                onClick={() => {
                  if (window.editor.filter('type', 'OsFacet').length > 0) {
                    window.SceneHelper.designForFacets(window.editor, false)
                  }
                }}
                label="Auto-layout"
              />
            )}

          {window.editor.filter('type', 'OsFacet').length > 0 && this.state.selectedObject.type === 'OsFacet' && (
            <PrimaryButton
              id="PanelPropertiesAutoDesign"
              startIcon={
                <LockIcon
                  style={{
                    ...raisedButtonIconStyle,
                    color: this.state.selectedObject.isLocked ? '#1890ff' : '#000000',
                  }}
                />
              }
              buttonStyle={raisedButtonButtonStyle}
              labelWrapperStyle={{
                ...raisedButtonLabelStyle,
                color: this.state.selectedObject.isLocked ? '#1890ff' : '#000000',
              }}
              style={raisedButtonStyle}
              disabled={false}
              onClick={() => {
                window.editor.execute(
                  new window.SetValueCommand(this.state.selectedObject, 'isLocked', !this.state.selectedObject.isLocked)
                )
              }}
              label="Lock"
            />
          )}

          {this.state.selectedObject.type === 'OsGround' && this.state.selectedObject.groundType === 'upload' && (
            <PrimaryButton
              startIcon={<CloseIcon style={raisedButtonIconStyle} />}
              buttonStyle={raisedButtonButtonStyle}
              labelWrapperStyle={raisedButtonLabelStyle}
              style={raisedButtonStyle}
              onClick={() => {
                window.editor.unlockSelection()
                window.editor.deselect()
              }}
              label="Close"
            />
          )}

          {this.state.selectedObject.type === 'OsNode' && this.toolIsActive('translateXY') && (
            <PrimaryButton
              startIcon={<MergeIcon style={raisedButtonIconStyle} />}
              buttonStyle={raisedButtonButtonStyle}
              labelWrapperStyle={raisedButtonLabelStyle}
              style={raisedButtonStyle}
              disabled={!this.state.selectedObject.hasMergableNodes}
              onClick={() => {
                this.state.selectedObject.mergeWithNearbyNodes()
              }}
              label="Merge"
            />
          )}
          {this.state.selectedObject.type === 'OsNode' && this.toolIsActive('translateXY') && (
            <PrimaryButton
              startIcon={<FloatIcon style={raisedButtonIconStyle} />}
              buttonStyle={raisedButtonButtonStyle}
              labelWrapperStyle={raisedButtonLabelStyle}
              style={raisedButtonStyle}
              isActive={Boolean(this.state.selectedObject.floatingOnFacet)}
              onClick={() => {
                window.editor.execute(new window.SetFloatingNodeCommand(this.state.selectedObject))
              }}
              label="Float"
            />
          )}
          {this.state.selectedObject.type === 'OsEdge' && this.toolIsActive('translateXY') && (
            <PrimaryButton
              startIcon={<EdgeTypeIcon style={raisedButtonIconStyle} />}
              buttonStyle={raisedButtonButtonStyle}
              labelWrapperStyle={raisedButtonLabelStyle}
              style={raisedButtonStyle}
              onClick={() => {
                if (this.state.panelActive !== 'EdgeType') {
                  this.setState({ panelActive: 'EdgeType' })
                } else {
                  this.setState({ panelActive: null })
                }
              }}
              label={this.state.selectedObject.isWire() ? 'Wire Type' : 'Edge Type'}
            />
          )}
          {isMobile && this.state.selectedObject.type === 'OsModuleGrid' && (
            <PrimaryButton
              startIcon={<PortraitLandscapeIcon style={raisedButtonIconStyle} />}
              buttonStyle={raisedButtonButtonStyle}
              labelWrapperStyle={raisedButtonLabelStyle}
              style={raisedButtonStyle}
              onClick={() => {
                var moduleGrid = window.editor.selected
                var value = moduleGrid.moduleLayout() !== 'landscape' ? 'landscape' : 'portrait'
                window.editor.execute(
                  new window.RedrawModulesCommand(moduleGrid, 'moduleLayout', value, moduleGrid.moduleLayout())
                )
                // moduleGrid.moduleLayout(moduleGrid.moduleLayout() !== 'landscape' ? 'landscape' : 'portrait')
                // window.editor.signals.objectChanged.dispatch(moduleGrid)
              }}
              label="Panel Orient'n"
            />
          )}

          {isMobile && this.state.selectedObject.type === 'OsModuleGrid' && (
            <PrimaryButton
              startIcon={<ScreenRotationIcon style={raisedButtonIconStyle} />}
              buttonStyle={raisedButtonButtonStyle}
              labelWrapperStyle={raisedButtonLabelStyle}
              backgroundColor={defaultBackgroundColor}
              backgroundColorOnHover={defaultBackgroundColorOnHover}
              style={raisedButtonStyle}
              disabled={this.state.selectedObject.facet && !this.state.selectedObject.useTiltRack}
              onClick={() => {
                window.setOverlay('SetAzimuth')
              }}
              label="Set Azimuth"
            />
          )}

          {isMobile && this.state.selectedObject.type === 'OsModuleGrid' && (
            <PrimaryButton
              startIcon={<SlopeIcon style={raisedButtonIconStyle} />}
              buttonStyle={raisedButtonButtonStyle}
              labelWrapperStyle={raisedButtonLabelStyle}
              style={raisedButtonStyle}
              disabled={this.state.selectedObject.facet && !this.state.selectedObject.useTiltRack}
              onClick={() => {
                window.setOverlay('SetSlope')
              }}
              label="Set Slope"
            />
          )}

          {this.props.showElements.FillRectangle && this.state.selectedObject.type === 'OsModuleGrid' && (
            <PrimaryButton
              startIcon={<FillRectangleIcon style={raisedButtonIconStyle} />}
              buttonStyle={raisedButtonButtonStyle}
              labelWrapperStyle={raisedButtonLabelStyle}
              style={raisedButtonStyle}
              startIconStyle={startIconStyle}
              onClick={() => {
                window.editor.execute(
                  new window.SetModuleGridActiveModulesCommand(
                    this.state.selectedObject,
                    this.state.selectedObject.fillRectangle()
                  )
                )
                // moduleGrid.moduleLayout(moduleGrid.fillRectangle())
                // window.editor.signals.objectChanged.dispatch(moduleGrid)
              }}
              label="Fill Rectangle"
            />
          )}

          {this.props.showElements.FillFacet && this.state.selectedObject.type === 'OsModuleGrid' && (
            <PrimaryButton
              startIcon={<FillRectangleIcon style={raisedButtonIconStyle} />}
              buttonStyle={raisedButtonButtonStyle}
              labelWrapperStyle={raisedButtonLabelStyle}
              style={raisedButtonStyle}
              startIconStyle={startIconStyle}
              disabled={!window.editor.getTerrain() && !this.state.selectedObject.facet}
              onClick={() => {
                this.state.selectedObject.growOnTerrain(true)
              }}
              label="Fill Facet"
            />
          )}

          {this.props.showElements.SelectFacet &&
            this.state.selectedObject.type === 'OsModuleGrid' &&
            this.state.selectedObject.facet && (
              <PrimaryButton
                startIcon={<HomeOutlinedIcon style={raisedButtonIconStyle} />}
                buttonStyle={raisedButtonButtonStyle}
                labelWrapperStyle={raisedButtonLabelStyle}
                style={raisedButtonStyle}
                startIconStyle={startIconStyle}
                onClick={() => window.editor.select(this.state.selectedObject.facet, true)}
                label="Select Facet"
              />
            )}

          {this.props.showElements.DetachFacet &&
            this.state.selectedObject.type === 'OsModuleGrid' &&
            this.state.selectedObject.facet?.isNonSpatial() && (
              <PrimaryButton
                startIcon={<RemoveCircleOutlineIcon style={raisedButtonIconStyle} />}
                buttonStyle={raisedButtonButtonStyle}
                labelWrapperStyle={raisedButtonLabelStyle}
                style={raisedButtonStyle}
                startIconStyle={startIconStyle}
                onClick={() => {
                  this.state.selectedObject.unsnapFromCurrentFacet({ newFacet: undefined })
                  window.editor.signals.objectChanged.dispatch(this.state.selectedObject, 'facet')
                }}
                label="Detach Facet"
              />
            )}

          {this.props.showElements.Duplicate && this.state.selectedObject.duplicate && this.toolIsActive('duplicate') && (
            <PrimaryButton
              startIcon={<DuplicateIcon style={raisedButtonIconStyle} />}
              buttonStyle={raisedButtonButtonStyle}
              labelWrapperStyle={raisedButtonLabelStyle}
              style={raisedButtonStyle}
              startIconStyle={startIconStyle}
              onClick={() => {
                const keepPosition = false
                const useCommand = true
                this.state.selectedObject?.duplicate(window.editor, keepPosition, useCommand)
              }}
              label="Duplicate"
            />
          )}

          {(this.props.showElements.AutoString && this.state.selectedObject.type === 'OsModuleGrid') ||
          (this.state.selectedObject.type === 'OsGroup' &&
            this.state.selectedObject.objects.every((object) => object.type === 'OsModuleGrid')) ? (
            <PrimaryButton
              startIcon={<MagicWandIcon style={raisedButtonIconStyle} />}
              buttonStyle={raisedButtonButtonStyle}
              labelWrapperStyle={raisedButtonLabelStyle}
              style={raisedButtonStyle}
              startIconStyle={startIconStyle}
              isActive={false}
              disabled={false}
              onClick={() => {
                if (window.Designer) {
                  var moduleGrids =
                    this.state.selectedObject.type === 'OsModuleGrid'
                      ? [this.state.selectedObject]
                      : this.state.selectedObject.objects

                  var autoStringModuleUuids = moduleGrids
                    .map((moduleGrid) => moduleGrid.getModules().map((osModule) => osModule.uuid))
                    .reduce((a, b) => [...a, ...b])

                  window.editor.select(null)
                  this.props.setSelectedDesignTab('inverters')
                  this.props.setAutoStringModuleUuids(autoStringModuleUuids)
                }
              }}
              label="Auto-String..."
            />
          ) : null}

          {this.props.showElements.Duplicate && this.state.selectedObject.type === 'ViewDummy' && (
            <PrimaryButton
              startIcon={<DuplicateIcon style={raisedButtonIconStyle} />}
              buttonStyle={raisedButtonButtonStyle}
              labelWrapperStyle={raisedButtonLabelStyle}
              style={raisedButtonStyle}
              startIconStyle={startIconStyle}
              onClick={() => {
                if (window.ViewHelper) {
                  window.editor.execute(
                    new window.DuplicateViewCommand(window.ViewHelper.selectedView().uuid, window.ViewHelper)
                  )
                }
              }}
              label="Duplicate"
            />
          )}

          {window.ViewHelper.views.length > 1 && this.state.selectedObject.type === 'ViewDummy' && (
            <PrimaryButton
              startIcon={<MoveDownIcon style={raisedButtonIconStyle} />}
              buttonStyle={raisedButtonButtonStyle}
              labelWrapperStyle={raisedButtonLabelStyle}
              style={raisedButtonStyle}
              startIconStyle={startIconStyle}
              isActive={false}
              disabled={window.ViewHelper && window.ViewHelper.views && window.ViewHelper.views.length === 1}
              onClick={() => {
                if (window.ViewHelper) {
                  //window.ViewHelper.moveView(window.ViewHelper.selectedView(), 'down')
                  window.editor.execute(
                    new window.ReorderViewsCommand(window.ViewHelper, window.ViewHelper.selectedView(), 'down')
                  )
                }
              }}
              label="Reorder"
            />
          )}

          {window.ViewHelper.views.length > 1 && this.state.selectedObject.type === 'ViewDummy' && (
            <PrimaryButton
              startIcon={<DeleteIcon style={raisedButtonIconStyle} />}
              buttonStyle={raisedButtonButtonStyle}
              labelWrapperStyle={raisedButtonLabelStyle}
              style={raisedButtonStyle}
              startIconStyle={startIconStyle}
              isActive={false}
              disabled={
                window.ViewHelper && window.ViewHelper.views && window.ViewHelper.views.length > 1 ? false : true
              }
              onClick={() => {
                if (window.ViewHelper && window.ViewHelper.views && window.ViewHelper.views.length > 1) {
                  if (window.Designer.controlMode && window.Designer.controlMode !== 'both') {
                    window.Designer.showNotification('Unable to delete a view while in align-map mode', 'danger')
                  } else {
                    // window.editor.execute(
                    //   new window.RemoveViewCommand(window.ViewHelper.selectedView().uuid, window.ViewHelper)
                    // )
                    const object = window.editor.selected
                    var confirmationPrompt = object.confirmBeforeDelete
                      ? object.confirmBeforeDelete()
                      : 'Delete this view?'

                    if (window.confirm(confirmationPrompt) === false) return
                    if (object.type === 'ViewDummy') window.editor.deleteObject(object)
                  }
                }
              }}
              label="Delete"
            />
          )}

          {!(this.state.selectedObject.type === 'OsModuleGrid' && this.props.showElements.Delete === false) &&
            this.toolIsActive('delete') &&
            this.state.selectedObject.type !== 'OsSystem' &&
            this.state.selectedObject.type !== 'ViewDummy' && (
              <PrimaryButton
                startIcon={<DeleteIcon style={raisedButtonIconStyle} />}
                buttonStyle={raisedButtonButtonStyle}
                labelWrapperStyle={raisedButtonLabelStyle}
                style={raisedButtonStyle}
                startIconStyle={startIconStyle}
                isActive={false}
                disabled={!this.toolIsActive('delete')}
                onClick={() => {
                  if (window.Designer) {
                    window.Designer.requestDelete()
                  }
                }}
                label="Delete"
              />
            )}

          {this.state.selectedObject.type === 'ViewDummy' && !isMobile && (
            <PrimaryButton
              isActive={this.state.extraToolsOpen}
              labelWrapperStyle={{
                minWidth: 0,
                top: 12,
                left: 0,
                right: 0,
                fontSize: '11px',
                fontWeight: 'bold',
                marginLeft: -4,
                marginRight: -4,
                paddingRight: 0,
                paddingLeft: 0,
              }}
              buttonStyle={{ ...raisedButtonButtonStyle, lineHeight: '25px' }}
              style={raisedButtonStyle}
              disabled={false}
              onClick={() => {
                appStorage.setString('extraViewTools', !this.state.extraToolsOpen)
                this.setState({ extraToolsOpen: !this.state.extraToolsOpen })
              }}
              label={
                <div
                  style={{
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'column',
                    transform: 'translate(-50%, -50%)',
                    top: '50%',
                    left: '50%',
                  }}
                >
                  <span style={{ lineHeight: '13px' }}>Extra</span>
                  <span style={{ lineHeight: '13px' }}>Tools</span>
                </div>
              }
            />
          )}

          {window.MapHelper.activeMapInstance &&
            window.MapHelper.activeMapInstance.mapType.includes('Google') &&
            this.state.selectedObject.type === 'ViewDummy' &&
            (this.state.extraToolsOpen || isMobile) && (
              <PrimaryButton
                startIcon={<LinkedCameraIcon style={raisedButtonIconStyle} />}
                buttonStyle={raisedButtonButtonStyle}
                labelWrapperStyle={raisedButtonLabelStyle}
                style={raisedButtonStyle}
                onClick={async () => {
                  // window.SceneHelper.addGoogleObliques()
                  var originalEditorMode = window.editor.displayMode ? window.editor.displayMode : 'hidden'
                  var discardChanges = true

                  await window.OsGoogle.addGoogleObliquesAfterDetection(window.editor.scene.sceneOrigin4326, true)

                  // Mark loading complete only when views are finished being added
                  window.editor.setMode(originalEditorMode, discardChanges)

                  window.editor.signals.escapePressed.dispatch()
                }}
                label={translate('Add Obliques')}
              />
            )}

          {this.state.selectedObject.type === 'ViewDummy' && (this.state.extraToolsOpen || isMobile) && (
            <PrimaryButton
              startIcon={<FixSkewIcon style={raisedButtonIconStyle} />}
              buttonStyle={raisedButtonButtonStyle}
              labelWrapperStyle={raisedButtonLabelStyle}
              style={raisedButtonStyle}
              onClick={(e) =>
                window.editor.execute(
                  new window.SetSkewCommand(window.ViewHelper, undefined, undefined, {}, {}, 'open')
                )
              }
              label={translate('Fix Skew')}
            />
          )}
          {this.state.selectedObject.type === 'ViewDummy' && (this.state.extraToolsOpen || isMobile) && (
            <PrimaryButton
              startIcon={
                this.state.enableTilt ? (
                  <RotateIcon style={raisedButtonIconStyle} />
                ) : (
                  <RotateDisabledIcon style={raisedButtonIconStyle} />
                )
              }
              buttonStyle={raisedButtonButtonStyle}
              labelWrapperStyle={raisedButtonLabelStyle}
              style={raisedButtonStyle}
              onClick={(e) =>
                window.editor.execute(
                  new window.SetViewValueCommand(
                    window.ViewHelper.selectedView(),
                    'allowTilt',
                    !this.state.enableTilt,
                    window.ViewHelper
                  )
                )
              }
              isActive={this.state.enableTilt}
              label={translate('Allow Tilt')}
            />
          )}

          {this.state.selectedObject.type === 'ViewDummy' && (this.state.extraToolsOpen || isMobile) && (
            <PrimaryButton
              startIcon={<PlansetIcon style={raisedButtonIconStyle} />}
              buttonStyle={raisedButtonButtonStyle}
              labelWrapperStyle={raisedButtonLabelStyle}
              style={raisedButtonStyle}
              onClick={(e) =>
                window.ViewHelper.toggleStyleForView(
                  window.ViewHelper.getViewByUuid(window.ViewHelper.selectedViewUuid())
                )
              }
              isActive={window.ViewHelper.getViewByUuid(window.ViewHelper.selectedViewUuid()).style === 'planset'}
              label={translate('Planset Style')}
            />
          )}

          {this.state.selectedObject.type === 'ViewDummy' &&
            window.MapHelper.activeMapInstance &&
            window.MapData.is3D(window.MapHelper.activeMapInstance.mapData) && (
              <PrimaryButton
                startIcon={<BrightnessIcon style={raisedButtonIconStyle} />}
                buttonStyle={raisedButtonButtonStyle}
                labelWrapperStyle={raisedButtonLabelStyle}
                style={raisedButtonStyle}
                isActive={this.state.viewShade}
                onClick={(e) => {
                  window.editor.getTerrain().useHeatmap(!this.state.viewShade)
                  // This should probably trigger viewsChanged but we use transformModeChanged to avoid
                  // needing to listen to viewsChanged which fires all the time and would need
                  // optimization
                  window.editor.signals.transformModeChanged.dispatch()
                }}
                label={translate('View Shade')}
              />
            )}

          {window.WorkspaceHelper.developerMode() && (
            <div>
              <Button
                style={raisedButtonStyle}
                onClick={(e) =>
                  window.ViewHelper.toggleShowTexturesForView(
                    window.ViewHelper.getViewByUuid(window.ViewHelper.selectedViewUuid())
                  )
                }
                isActive={this.state.object && this.state.object.showTextures}
              >
                <span>{translate('Show Textures')}</span>
              </Button>
              <Button
                style={raisedButtonStyle}
                onClick={(e) =>
                  window.ViewHelper.toggleShowGroundForView(
                    window.ViewHelper.getViewByUuid(window.ViewHelper.selectedViewUuid())
                  )
                }
                isActive={this.state.object && this.state.object.showGround}
              >
                <span>{translate('Toggle Ground')}</span>
              </Button>
            </div>
          )}
        </div>
        {this.state.panelActive === 'EdgeType' && (
          <div>
            <Paper style={styles.panelModal}>
              <h5>
                {this.state.selectedObject?.isWire && this.state.selectedObject?.isWire()
                  ? translate('Wire Type')
                  : translate('Edge Type')}
              </h5>
              {(this.state.selectedObject?.isWire && this.state.selectedObject?.isWire()
                ? window.OsEdge.wireTypes
                : Object.keys(window.SetbacksHelper.getSetbackDistances())
              ).map(function (edgeType) {
                var selectedEdge = this.state.selectedObject

                var distanceValue, distanceInMeters, units, setbackDistanceForLabel

                if (this.state.selectedObject.isWire()) {
                  setbackDistanceForLabel = ''
                } else {
                  distanceValue =
                    this.props.measurements === 'metric' ? distanceInMeters : metersToFeet(distanceInMeters)
                  units = measurementsToUnitLabel(this.props.measurements)
                  setbackDistanceForLabel = ` (${trimDecimalPlaces(distanceValue, 4)}${units})`

                  distanceInMeters = window.SetbacksHelper.getEdgeType(edgeType).setbackDistance
                  distanceValue =
                    this.props.measurements === 'metric' ? distanceInMeters : metersToFeet(distanceInMeters)
                  units = measurementsToUnitLabel(this.props.measurements)
                  setbackDistanceForLabel = ` (${trimDecimalPlaces(distanceValue, 4)}${units})`
                }

                return (
                  <Button
                    key={edgeType}
                    style={{
                      minWidth: 40,
                      left: 5,
                      right: 5,
                      width: '97%',
                      backgroundColor: '#' + window.SetbacksHelper.getEdgeType(edgeType).colorHex + '33', //20% opacity
                      margin: 5,
                    }}
                    buttonStyle={{ textAlign: 'left' }}
                    startIcon={<EdgeTypeIcon color={'#' + window.SetbacksHelper.getEdgeType(edgeType).colorHex} />}
                    isActive={edgeType === selectedEdge.edgeType}
                    onClick={() => {
                      if (selectedEdge.isWire()) {
                        window.OsEdge.setEdgeTypesForAllLinkedEdge(selectedEdge, edgeType)
                      } else {
                        window.editor.execute(new window.SetEdgeTypeCommand(selectedEdge, edgeType))
                      }
                      _this.setState({ panelActive: null })
                    }}
                  >
                    <span>{translate(edgeType) + setbackDistanceForLabel}</span>
                  </Button>
                )
              }, this)}
            </Paper>
          </div>
        )}
      </div>
    )
  }
}
Tools.propTypes = {
  state: PropTypes.object,
}

export default compose(
  withTranslate,
  withStudioSignals,
  connect(
    (state) => ({
      measurements: getMeasurementsFromState(state),
    }),
    {
      setSelectedDesignTab,
      setAutoStringModuleUuids,
    }
  )
)(Tools)

import { TextField, makeStyles } from '@material-ui/core'
import { PortraitOutlined } from '@material-ui/icons'
import LoadingDots from 'layout/widgets/LoadingDots'
import { Alert, BatteryIcon, FormControl, InverterIcon, MountingIcon, PanelIcon } from 'opensolar-ui'
import { useSystem } from 'projectSections/sections/payments/hooks'
import React, { ChangeEvent, useMemo } from 'react'
import { useFormState } from 'react-final-form'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { useDebouncedCallback } from 'util/Debounce'
import { parseIntegrationJson } from 'util/misc'
import LightReachEquipmentDropdown from './LightReachEquipmentDropdown'
import { LightReachVendorMap } from './types'
import { useValidateLightReachEquipment } from './utils'

type PropTypes = {
  systemUuid: string
  licenseNumber: string | null | undefined
  setLicenseNumber: (string) => void
  vendors: LightReachVendorMap | undefined
  vendorsAreBeingFetched: boolean
}

const useStyles = makeOpenSolarStyles((theme) => ({
  headerContainer: {
    display: 'flex',
    flexFlow: 'column',
    gap: 10,
  },

  productContainer: {
    background: '#FBFAFC',
    padding: '5px 35px 10px 20px',
    borderRadius: 8,
    margin: '15px 0',
  },

  productHeaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  productHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },

  selectedProduct: {
    background: '#F1F1F1',
    padding: '2px 8px',
    borderRadius: 5,
  },

  approvedLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    color: theme.alert_success,
  },

  icon: {
    fontSize: 16,
  },

  selectField: {
    width: '100%',
    margin: '10px 0',
  },

  selectLabel: {
    marginTop: 5,
    marginBottom: 0,
  },

  errorMsg: {
    color: theme.alertIcon_error,
    marginTop: '-5px',
    fontSize: 'smaller',
  },
}))

const useCustomStyles = makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgb(230, 37, 0) !important',
      borderWidth: '2px',
    },
  },
}))

const LightReachConfirmEquipment: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const state = useFormState()?.values.state
  const validationErrors = useValidateLightReachEquipment(props.systemUuid, props.vendors, props.licenseNumber)
  const errorClass = useCustomStyles()

  const system = useSystem(props.systemUuid)
  const moduleSelected = system?.moduleType()
  const inverterSelected = system?.inverters()[0]
  const batterySelecteed = system?.batteries()[0]

  useMemo(() => {
    const system = window.editor.getSystems().find((sys) => sys.uuid === props.systemUuid)
    const newIntegrationJSON = parseIntegrationJson(system?.integration_json) || {}
    if (!props.vendorsAreBeingFetched) {
      if (!newIntegrationJSON.lightreach) {
        newIntegrationJSON.lightreach = {}
      }

      if (!newIntegrationJSON.lightreach.panelManufacturer)
        newIntegrationJSON.lightreach.panelManufacturer = props.vendors?.matching_panel_manufacturer || ''
      if (!newIntegrationJSON.lightreach.panelModel)
        newIntegrationJSON.lightreach.panelModel = props.vendors?.matching_panel_model?.id || ''
      if (!newIntegrationJSON.lightreach.inverterManufacturer)
        newIntegrationJSON.lightreach.inverterManufacturer = props.vendors?.matching_inverter_manufacturer || ''
      if (!newIntegrationJSON.lightreach.inverterModel)
        newIntegrationJSON.lightreach.inverterModel = props.vendors?.matching_inverter_model?.id || ''
      if (!newIntegrationJSON.lightreach.storageManufacturer)
        newIntegrationJSON.lightreach.storageManufacturer = props.vendors?.matching_storage_manufacturer || ''
      if (!newIntegrationJSON.lightreach.mountingManufacturer)
        newIntegrationJSON.lightreach.mountingManufacturer = props.vendors?.matching_mounting_manufacturer || ''
      if (!newIntegrationJSON.lightreach.storageModel)
        newIntegrationJSON.lightreach.storageModel = props.vendors?.matching_storage_model?.id || ''
      if (!newIntegrationJSON.lightreach.salesperson_license_number)
        newIntegrationJSON.lightreach.salesperson_license_number = props.vendors?.salesperson_license_number || ''
    }
  }, [props.vendorsAreBeingFetched])

  const onLicenseNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    doDebouncedOnFieldChange(e.target.value)
  }

  const doDebouncedOnFieldChange = useDebouncedCallback((val: string) => {
    props.setLicenseNumber(val)
    if (system) {
      if (!system?.integration_json?.lightreach) system.integration_json = { lightreach: {} }
      if (!system.integration_json) system.integration_json = {}
      system.integration_json.lightreach = { ...system.integration_json.lightreach, salesperson_license_number: val }
    }
  }, 500)

  return (
    <div>
      {props.vendorsAreBeingFetched ? (
        <LoadingDots />
      ) : (
        <>
          <div className={classes.headerContainer}>
            {validationErrors.length !== 0 && (
              <Alert severity="error">
                System must include Lightreach-approved hardware. Select from picklists below to proceed to pricing.
              </Alert>
            )}

            <p>To get Lightreach pricing we need to confirm the system details below.</p>
          </div>

          <LightReachEquipmentDropdown
            systemUuid={props.systemUuid}
            icon={<PanelIcon />}
            label="Modules"
            selectedProduct={moduleSelected?.code || ''}
            manufacturerMatch={props.vendors?.matching_panel_manufacturer}
            skuMatch={props.vendors?.matching_panel_model?.id}
            manufacturerChoices={props.vendors?.approved_manufacturers.panel}
            skuChoices={props.vendors?.approved_models.panel}
            vendors={props.vendors}
          />

          <LightReachEquipmentDropdown
            systemUuid={props.systemUuid}
            icon={<InverterIcon />}
            label="Inverters"
            selectedProduct={
              inverterSelected
                ? `${inverterSelected?.manufacturer_name} ${inverterSelected?.code}`
                : 'Not included in design'
            }
            manufacturerMatch={props.vendors?.matching_inverter_manufacturer}
            skuMatch={props.vendors?.matching_inverter_model?.id}
            manufacturerChoices={props.vendors?.approved_manufacturers.inverter}
            skuChoices={props.vendors?.approved_models.inverter}
            vendors={props.vendors}
          />

          <LightReachEquipmentDropdown
            systemUuid={props.systemUuid}
            icon={<BatteryIcon />}
            label="Batteries"
            selectedProduct={
              batterySelecteed
                ? `${batterySelecteed?.manufacturer_name} ${batterySelecteed?.code}`
                : 'Not included in design'
            }
            manufacturerMatch={props.vendors?.matching_storage_manufacturer}
            skuMatch={props.vendors?.matching_storage_model?.id}
            manufacturerChoices={props.vendors?.approved_manufacturers.storage}
            skuChoices={props.vendors?.approved_models.storage}
            vendors={props.vendors}
          />

          <LightReachEquipmentDropdown
            systemUuid={props.systemUuid}
            icon={<MountingIcon />}
            label="Mounting"
            selectedProduct=""
            manufacturerMatch={props.vendors?.matching_mounting_manufacturer}
            skuMatch={''}
            manufacturerChoices={props.vendors?.approved_models.mounting}
            skuChoices={[]}
            vendors={props.vendors}
          />

          {props.vendors?.salesperson_license_required && (
            <div className={classes.productContainer}>
              <div className={classes.productHeaderWrapper}>
                <div className={classes.productHeader}>
                  <PortraitOutlined />
                  <h3>{`${state} Sales Rep. License Number - ${props.vendors?.salesperson_name}`}</h3>
                </div>
              </div>

              <FormControl
                sx={{ m: 1, width: '100%' }}
                classes={validationErrors.includes('licenseNumber') ? errorClass : ''}
              >
                <p className={classes.selectLabel}>State Rep. License Number</p>
                <TextField
                  placeholder="Enter your Sales Rep. License Number. Eg. NCS-123456"
                  variant="outlined"
                  size="small"
                  className={classes.selectField}
                  defaultValue={props.vendors?.salesperson_license_number || props.licenseNumber}
                  onChange={onLicenseNumberChange}
                  error
                />
                {validationErrors.includes('licenseNumber') && (
                  <p className={classes.errorMsg}>Valid {state} Sales Rep License Number Required</p>
                )}
              </FormControl>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default LightReachConfirmEquipment

import { Divider, TextField } from '@material-ui/core'
import { markFieldActive, markFieldInactive } from 'Studio/Utils'
import { setSelectComponentDialog } from 'ducks/selectComponent'
import { withHardwareSelectorV2 } from 'elements/hardwareSelectorV2/withHardwareSelectorV2'
import { useRef } from 'react'
import { useTranslate } from 'react-admin'
import { useDispatch } from 'react-redux'
import { COMPONENT_TYPE_OPTIONS } from 'resources/components/others/constants'
import { inputValueToInt } from 'util/misc'
import { ExternalLink } from '../../PanelSystem'
import ComponentWarningBox from '../../warning/ComponentWarningBox'
import EvergenWarningBox from '../../warning/EvergenComplianceMessage'
import { ComponentSelectorDropdown } from '../common/ComponentSelectorDropdown'
import { RemoveComponentButton } from '../common/RemoveComponentButton'

const OtherBlock = ({
  handleChange,
  state,
  handleSelect,
  _this,
  item: other,
  disabled = false,
  dragHandle,
  enableHardwareDialogV2,
  handleOpenHardwareSelector,
}) => {
  const inputRef = useRef()
  const dispatch = useDispatch()
  const translate = useTranslate()
  const openHardwareSelector = () => {
    if (enableHardwareDialogV2)
      handleOpenHardwareSelector({
        title: 'Select Other Components',
        componentTypes: COMPONENT_TYPE_OPTIONS.map((option) => option.id),
        targetUuids: [other.uuid],
      })
    else {
      dispatch(
        setSelectComponentDialog(
          true,
          window.WorkspaceHelper.project.org_id,
          window.WorkspaceHelper.project.id,
          [other.uuid],
          'other'
        )
      )
    }
  }
  return (
    <div>
      <div onClick={(e) => handleSelect(e, other)}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ flexGrow: 1 }}>
            <ComponentSelectorDropdown
              componentType="other"
              component={
                other
                  ? other
                  : new window.OsOther(
                      // Support other components which reference a component activation
                      // and also dynamically-created components created by plugins
                      // We instantiate an OsOther component to ensure exhibit logo shows.
                      other.other_id ? { other_id: other.other_id, show_customer: false } : other
                    )
              }
              availableComponents={window.AccountHelper.getComponentOtherSpecsAvailable()}
              disabled={disabled}
              select={openHardwareSelector}
            />
          </div>
          <RemoveComponentButton
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              window.editor.deleteObject(other)
            }}
          />
          {dragHandle}
        </div>
      </div>
      <TextField
        type="number"
        variant="outlined"
        label={translate('Quantity')}
        name="quantity"
        inputRef={inputRef}
        value={state['otherQuantityRaw' + other.uuid] !== 0 ? state['otherQuantityRaw' + other.uuid] : 1}
        onClick={() => inputRef.current.focus()}
        onFocus={() => {
          markFieldActive.call(_this, 'otherQuantityRaw' + other.uuid, state['otherQuantityRaw' + other.uuid])
        }}
        onBlur={() => {
          markFieldInactive.call(_this)
        }}
        onChange={(event) => handleChange(event, other)}
        style={{ width: 65, marginRight: 15 }}
        inputProps={{ style: { padding: '10px 12px' }, min: 1 }}
        errorText={
          isNaN(inputValueToInt(other.quantity)) ? translate('Supply quantity as a number or leave blank') : null
        }
        disabled={
          disabled || other.other_component_type === 'dc_optimizer' || state['otherQuantityRaw' + other.uuid] === 0
        }
      />
      <ComponentWarningBox componentIds={[other.uuid]} />
      <ExternalLink componentData={other.getComponentData()} />
      <EvergenWarningBox category="other" />
      <Divider style={{ height: 2, marginTop: 20 }} />
    </div>
  )
}
const OtherBlockWithHardwareSelector = withHardwareSelectorV2(OtherBlock)

export default OtherBlockWithHardwareSelector

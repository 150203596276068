import { Dialog, DialogContent } from '@material-ui/core'
import { push as pushAction } from 'connected-react-router'
import Alert from 'elements/Alert'
import { Button } from 'opensolar-ui'
import { Component } from 'react'
import { withTranslate } from 'react-admin'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import withStudioSignals from 'Studio/signals/withStudioSignals'
import { getRoleFromState } from '../../util/misc'

class InvalidProjectDialog extends Component {
  constructor(props) {
    super(props)
    var state = {
      isOpen: false,
    }

    var injectState = props.state ? props.state : null

    //Override with any supplied state - for use in storybook
    if (injectState) {
      for (var key in injectState) {
        state[key] = injectState[key]
      }
    }

    this.state = state
  }
  componentDidMount() {
    this.props.useStudioSignals(
      (visible, msg) => {
        this.setState({
          ...this.state,
          isOpen: visible,
          message: msg || '',
        })
      },
      ['projectInvalidChanged'],
      this
    )
    // referenceSave.call(this, 'InvalidProjectDialog')
  }

  render() {
    const { translate } = this.props
    return this.props.zone === 'studio' ? (
      <Dialog
        title={translate('Error')}
        open={Boolean(this.props.projectId && Boolean(this.props.isPro) && this.state.isOpen)}
      >
        <DialogContent>
          <Alert severity="error" options={{ style: { marginBottom: 15 } }}>
            {translate(this.state.message)}
          </Alert>
          <Button
            style={{ float: 'right' }}
            variant="contained"
            onClick={() => {
              this.props.push('/projects/' + this.props.projectId)
            }}
          >
            <span>{translate('Edit Address')}</span>
          </Button>
        </DialogContent>
      </Dialog>
    ) : null
  }
}

const mapStateToProps = (state) => {
  return {
    orgId: state.auth ? state.auth.org_id : null,
    projectId: state.projectId,
    recentProjects: state.recentProjects.filter((project) => state.auth && project.org_id === state.auth.org_id),
    zone: state.zone,
    isPro: Boolean(getRoleFromState(state)),
  }
}

export default compose(
  withTranslate,
  withStudioSignals,
  connect(mapStateToProps, { push: pushAction })
)(InvalidProjectDialog)

import { makeStyles } from '@material-ui/core'
import { Button } from 'opensolar-ui'
import React from 'react'
import { useHistory } from 'react-router'
import { doNotTranslate } from 'util/misc'
import AuthModalWrapper from './AuthModalWrapper'
import { NearmapLogo } from './common'

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    width: 280,
    marginTop: 0,
    minWidth: 210,
    padding: '0.5em 1em 1em 1em',
  },
  button: {
    color: theme.headerFontColor,
    background: theme.themeColor,
    '&:hover': {
      cursor: 'pointer',
      background: theme.themeColorDark,
    },
  },
}))

const LoginNearmapAdminRequired = () => {
  const classes = useStyles()
  const history = useHistory()
  return (
    <AuthModalWrapper>
      <div className={classes.wrapper}>
        <div style={{ textAlign: 'center' }}>
          <NearmapLogo />
        </div>
        <p>{doNotTranslate('Only Nearmap administrators can activate Nearmap on OpenSolar.')}</p>
        <div style={{ marginBottom: 10 }}>
          <Button
            classes={{ root: classes.button }}
            onClick={(e: any) => {
              e.preventDefault()
              window.open('https://support.nearmap.com/hc/en-us/requests/new', '_blank')
            }}
          >
            <span style={{ textTransform: 'none' }}>{doNotTranslate('Contact Nearmap support')}</span>
          </Button>
        </div>
        <div style={{ marginBottom: 10 }}>
          <Button
            classes={{ root: classes.button }}
            onClick={(e: any) => {
              e.preventDefault()
              history.push('/login')
            }}
          >
            <span style={{ textTransform: 'none' }}>{doNotTranslate('Login to OpenSolar')}</span>
          </Button>
        </div>
      </div>
    </AuthModalWrapper>
  )
}
export default LoginNearmapAdminRequired

import { SelectedMenu } from './constant'
import Typography from '@material-ui/core/Typography'
import MenuItemLink from './elements/MenuItemLink'
import { Divider } from '@material-ui/core'
import React from 'react'
import { HardwareFilterSidebarMenu } from '../../pages/ordering/catalog/HardwareFilterSidebar'
import { SelectedMenuType, SubMenuConfigType } from './type'
import { logAmplitudeEvent } from '../../amplitude/amplitude'

const SideBarNavigationSubMenu = ({
  title,
  subMenus,
  activeMenu,
}: {
  title: string
  subMenus: SubMenuConfigType[]
  activeMenu: SelectedMenuType
}) => {
  const onClick = (amplitudeEventSource?: string) => {
    if (!amplitudeEventSource) return
    logAmplitudeEvent('hardware_sidebar_navigation_submenu_clicked', {
      source: amplitudeEventSource,
    })
  }

  const hasSubMenu = subMenus.length > 0

  return (
    <>
      <Typography gutterBottom variant="h6">
        {hasSubMenu && title}
      </Typography>
      {subMenus.map((menu: { title: string; url: string; amplitudeEventSource?: string }) => (
        <MenuItemLink
          key={menu.title}
          title={menu.title}
          id={menu.title}
          url={menu.url}
          exact={true}
          onClick={() => onClick(menu.amplitudeEventSource)}
        />
      ))}
      {hasSubMenu && <Divider />}
      {activeMenu === SelectedMenu.BUY_HARDWARE && <HardwareFilterSidebarMenu />}
    </>
  )
}

export default SideBarNavigationSubMenu

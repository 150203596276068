// in src/users.js
import { Dialog } from '@material-ui/core'
import MuiDialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import { PrimaryButton } from 'elements/button/GenericButton'
import CustomField from 'elements/field/CustomField'
import { List } from 'elements/react-admin/List'
import inflection from 'inflection'
import { IconButton } from 'opensolar-ui'
import React, { useState } from 'react'
import {
  Create,
  Datagrid,
  Edit,
  FormDataConsumer,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  showNotification as showNotificationAction,
  useTranslate,
} from 'react-admin'
import { useForm } from 'react-final-form'
import { connect, useSelector } from 'react-redux'
import AddressAutocomplete from 'resources/projects/elements/AddressAutocomplete.js'
import EditOrImportButton from '../../elements/button/EditOrImportButton'
import { getMeasurementsFromState, getRoleFromState, kmToMiles, milesToKm, trimDecimalPlaces } from '../../util/misc'

const styles = {
  floatLeft: {
    float: 'left',
    marginRight: 10,
  },
  inputWidth: {
    width: 256,
  },
}

export const SalesTerritoryList = ({ accessRights: { allowView, allowCreate, allowEdit, allowDelete }, ...props }) => {
  const isAdmin = useSelector((state) => Boolean(getRoleFromState(state)?.is_admin))
  const translate = useTranslate()

  const resourceName = translate(`resources.${props.resource}.name`, {
    smart_count: 1,
    _: inflection.humanize(inflection.singularize(props.resource)),
  })

  return (
    <List
      extraCreateButtonLabel={isAdmin ? translate('ra.page.empty_new', { name: resourceName }) : null}
      {...props}
      hasCreate={allowCreate}
    >
      <Datagrid isAdmin={isAdmin}>
        <TextField source="title" label="Title" displayInCard={true} />
        <ReferenceField
          source="lead_recipient_role"
          displayInCard={true}
          skipFromGrid={true}
          sortable={false}
          reference="roles"
          linkType="list"
        >
          <TextField source="display" />
        </ReferenceField>
        <TextField source="weighting" displayInCard={true} skipFromGrid={true} sortable={true} />
        <EditOrImportButton
          source="actions"
          label={translate('Actions')}
          org_id={props.org_id}
          resource="sales_territories"
          allowDuplicate={false}
          sortable={false}
          isAdmin={isAdmin}
          allowEdit={allowEdit}
          allowDelete={allowDelete}
        />
      </Datagrid>
    </List>
  )
}

const _LocationSearch = (props) => {
  const [isOpen, setOpen] = useState(false)
  const form = useForm()
  const translate = useTranslate()
  return (
    <div>
      <PrimaryButton
        labelWrapperStyle={{ padding: 0 }}
        style={{ marginRight: 0 }}
        label="Set location using an address..."
        wrapperStyle={{}}
        onClick={() => setOpen(true)}
      />
      <Dialog maxWidth="xs" open={isOpen}>
        <IconButton
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
          }}
          aria-label="close"
          onClick={() => setOpen(false)}
        >
          <CloseIcon />
        </IconButton>
        <MuiDialogTitle style={{ paddingBottom: 0 }}>{translate('Set location using an address')}</MuiDialogTitle>
        <MuiDialogContent>
          <p>{translate('Enter the address:')} </p>
          <AddressAutocomplete
            onPlaceSelected={(detail) => {
              const lat = detail?.lat
              const lon = detail?.lon
              if (lat && lon) {
                form.mutators.updateField('lat', lat)
                form.mutators.updateField('lon', lon)
              } else {
                props.showNotification('Unable to get coordinates from this address', 'warning')
              }
              setOpen(false)
            }}
            api_key_google={props.api_key_google}
            types={['address']}
          />
        </MuiDialogContent>
        <MuiDialogActions />
      </Dialog>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    api_key_google: state.auth && state.auth.api_key_google ? state.auth.api_key_google : '',
  }
}

export const LocationSearch = connect(mapStateToProps, { showNotification: showNotificationAction })(_LocationSearch)

const measurementsProps = (measurements) =>
  measurements === 'imperial'
    ? {
        endAdornment: 'mi',
        // format(): record -> input
        // parse(): input -> record
        // Warning: This is EXTREMELY delicate. So easy to screw it up.
        // Known issue: Trying to enter more than 4 decimal places gives strange behavior but at least it's not unsfae.
        // format: (v) => trimDecimalPlaces(kmToMiles(v), 4),
        // parse: (v) => trimDecimalPlaces(milesToKm(v), 4),
      }
    : { endAdornment: 'km' }

const Inputs = connect(
  (state) => ({
    measurements: getMeasurementsFromState(state),
  }),
  {}
)((props) => {
  const translate = useTranslate()
  const form = useForm()
  return (
    <div className="sales-territory-form-wrapper">
      <CustomField
        component={TextInput}
        label="resources.sales_territories.fields.title"
        name="title"
        source="title"
        {...props}
      />

      <CustomField
        component={ReferenceInput}
        label="resources.sales_territories.fields.lead_recipient"
        source="lead_recipient_role"
        name="lead_recipient_role"
        {...props}
        reference="roles"
        allowEmpty
        style={{}}
      >
        <SelectInput sortChoices={true} optionText="display" optionValue="url" />
      </CustomField>

      <h2>{translate('Address Filters')}</h2>
      <p>
        {translate(
          'Enter comma-separated values into one or more filters to restrict the sales territory. Empty filters will be ignored.'
        )}
      </p>

      {['countries', 'states', 'counties', 'localities', 'zips'].map((field) => (
        <CustomField
          key={field}
          component={TextInput}
          style={styles.inputWidth}
          label={'resources.sales_territories.fields.' + field}
          name={field}
          source={field}
          {...props}
        />
      ))}

      <h2>{translate('Location and Radius')}</h2>
      <p>{translate('Enter a location and a radius to restrict the sales territory (optional).')}</p>

      {!props.disabled && <LocationSearch />}

      <CustomField
        key={'rawRadiusValue'}
        component={NumberInput}
        label={'resources.sales_territories.fields.radius'}
        name={'rawRadiusValue'}
        source={'rawRadiusValue'}
        style={{ width: 100 }}
        {...props}
        {...measurementsProps(props.measurements)}
      />

      <CustomField
        key={'lon'}
        component={NumberInput}
        label={'resources.sales_territories.fields.lon'}
        name={'lon'}
        source={'lon'}
        style={{ width: 100 }}
        {...props}
      />

      <CustomField
        key={'lat'}
        component={NumberInput}
        label={'resources.sales_territories.fields.lat'}
        name={'lat'}
        source={'lat'}
        style={{ width: 100 }}
        {...props}
      />

      {!props.disabled && (
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (formData.lat || formData.lon || formData.rawRadiusValue) {
              return (
                <PrimaryButton
                  labelWrapperStyle={{ padding: 0 }}
                  style={{ marginRight: 0 }}
                  label="Clear Location & Radius"
                  wrapperStyle={{}}
                  onClick={() => {
                    form.mutators.updateField('rawRadiusValue', '')
                    form.mutators.updateField('lat', null)
                    form.mutators.updateField('lon', null)
                  }}
                />
              )
            } else {
              return null
            }
          }}
        </FormDataConsumer>
      )}

      <h2>{translate('Weighting')}</h2>

      <p>
        {translate(
          'If you have two or more overlapping Sales Territories, you can use the "Weighting" field to determine how many leads each Sales Territory will receive. If all the weightings are the same then leads will be split evenly among the Sales Territories. But if one sales territory has a weighting that is higher than the other, it will receive proportionally more leads. (For example if Sales Territory A has a weighting of 200 and Sales Territory B has a weighting of 100, Sales Territory A will receive twice as many of the leads that are in the overlapping area.)'
        )}
      </p>
      <p>
        {translate(
          'This feature can be very useful if you want to use a single Lead Capture Form to distribute leads to multiple sales reps on your team. You can assign each sales rep an overlapping Sales Territory and set the Weighting to be equal (or unequal, if appropriate) and the leads will automatically be distributed as described above. (If you do choose to take this approach, please ensure that you do not set an "Assigned Team Member" when configuring the Lead Capture Form. If you do, all leads will be assigned to that user, regardless of which Sales Territory they are in.)'
        )}
      </p>
      <CustomField component={NumberInput} name="weighting" source="weighting" {...props} />
    </div>
  )
})

const formatSubmitValues = (values, measurements) => {
  values.radius =
    measurements === 'imperial' ? trimDecimalPlaces(milesToKm(values.rawRadiusValue), 4) : values.rawRadiusValue
  return values
}

const defaultValueEdit = (record, measurements) => {
  var defaultValues = Object.assign(record, {
    rawRadiusValue:
      record.radius === 0
        ? ''
        : measurements === 'imperial'
        ? trimDecimalPlaces(kmToMiles(record.radius), 4)
        : record.radius,
  })
  return defaultValues
}

export const SalesTerritoryEdit = connect(
  (state) => ({
    measurements: getMeasurementsFromState(state),
  }),
  {}
)(({ accessRights: { allowView, allowCreate, allowEdit, allowDelete }, ...props }) => {
  const translate = useTranslate()
  let withToolBar = allowEdit ? {} : { toolbar: false }

  return (
    <Edit {...props} hasDelete={allowDelete}>
      <SimpleForm
        defaultValue={(record) => defaultValueEdit(record, props.measurements)}
        formatSubmitValues={(values) => formatSubmitValues(values, props.measurements)}
        validate={(values) => validateSalesTerritory(values, translate)}
        {...withToolBar}
        disabled={!allowEdit}
      >
        <Inputs {...props} disabled={!allowEdit} />
      </SimpleForm>
    </Edit>
  )
})

export const SalesTerritoryCreate = connect(
  (state) => ({
    measurements: getMeasurementsFromState(state),
  }),
  {}
)((props) => {
  const translate = useTranslate()
  return (
    <Create {...props}>
      <SimpleForm
        formatSubmitValues={(values) => formatSubmitValues(values, props.measurements)}
        validate={(values) => validateSalesTerritory(values, translate)}
        defaultValue={{ weighting: 100 }}
      >
        <Inputs {...props} />
      </SimpleForm>
    </Create>
  )
})

const validateSalesTerritory = (values, translate) => {
  const errors = {}

  // If supplying any values for location and radius, ensure they are all supplied
  if (values.lat || values.lon || values.rawRadiusValue || values.rawRadiusValue === '0') {
    if (!values.lat) {
      errors.lat = [translate('Incomplete')]
    }
    if (!values.lon) {
      errors.lon = [translate('Incomplete')]
    }
    if (!values.rawRadiusValue || values.rawRadiusValue === '0') {
      errors.rawRadiusValue = [translate('Incomplete')]
    }
  }

  // Allow title to be empty for now
  // if (!values.title) {
  //   errors.title = [translate('Title is required.')]
  // }
  return errors
}

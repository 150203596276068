import { RootState } from 'types/state'

export const SET_HELP_CENTER_OPEN = 'SET_HELP_CENTER_OPEN'

export const helpCenterActions = {
  setHelpCenterOpen: (helpCenterOpen: boolean) => ({
    type: SET_HELP_CENTER_OPEN,
    helpCenterOpen,
  }),
}

interface HelpCenterAction {
  type: typeof SET_HELP_CENTER_OPEN
  helpCenterOpen: boolean
}

const initialState: HelpCenterState = {
  helpCenterOpen: false,
}

export default (previousState: HelpCenterState = initialState, action: HelpCenterAction) => {
  if (action.type === SET_HELP_CENTER_OPEN) {
    previousState = {
      ...previousState,
      ...{
        helpCenterOpen: action.helpCenterOpen,
      },
    }
  }
  return previousState
}

export type HelpCenterState = {
  helpCenterOpen: boolean
}

export const helpCenterSelectors = {
  getHelpCenterOpen(state: RootState): boolean {
    return state.helpCenter.helpCenterOpen
  },
}

import React, { memo, FC, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { LinearProgress } from '@material-ui/core'
const useStyles = makeStyles((theme) => ({
  root: {
    height: 5,
    backgroundColor: '#E2F3FF',
  },
  placeHolder: {
    height: 5,
    width: '100%',
  },
  queueing: {
    background: '#E2F3FF',
    height: 5,
    width: '100%',
  },
  bar1Indeterminate: {
    background: '#0094FF',
  },
  bar2Indeterminate: {
    background: '#0094FF',
  },
}))

type PropTypes = {
  isLoading: boolean
  isCalculating: boolean
  style?: any
}

const SystemProgressBar: FC<PropTypes> = memo(({ isLoading, style, isCalculating }) => {
  const classes = useStyles()
  if (isCalculating) {
    return (
      <LinearProgress
        classes={{
          root: classes.root,
          bar1Indeterminate: classes.bar1Indeterminate,
          bar2Indeterminate: classes.bar2Indeterminate,
        }}
        style={style}
      />
    )
  } else if (isLoading) {
    return <div className={classes.queueing} style={style} />
  } else {
    return <div className={classes.placeHolder} style={style} />
  }
})

export default SystemProgressBar

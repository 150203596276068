// @ts-nocheck
import { makeStyles } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import Alert from 'elements/Alert'
import GenericButton from 'elements/button/GenericButton.js'
import React, { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { OtherComponentType } from 'types/otherComponent'
import { formatCurrency, parseIntegrationJson, systemNameOrDefault } from 'util/misc'
import { BomResponseType, IronRidgeBOMItemType } from '../../../types/integrations/ironRidge'
import IronRidgeBomRow from './IronRidgeBomRow'
import { API_URL, IR_MFR_NAME } from './constants'

declare var editor: editor

const useStyles = makeStyles({
  bomModalContentWrapper: {
    padding: '5px 30px 30px 30px',
    textAlign: 'center',
    position: 'relative',
    justifyContent: 'center',
  },
  bomTable: {
    textAlign: 'left',
  },
  bomId: {
    fontWeight: 900,
  },
  bomProductTitleView: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  bomOtherCol: {
    flex: 1,
    textAlign: 'right',
    opacity: 1.0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bomSection: {
    display: 'flex',
    flexDirection: 'column',
  },
  bomSectionTitleView: {
    display: 'flex',
    backgroundColor: '#ececec',
    padding: '5px 0px',
    margin: '10px 0px 0px 0px',
  },
  bomSectionTitle: {
    flex: 2,
    margin: '2px',
  },
  bomFieldSubLabelWrapper: {
    flex: 3,
    display: 'flex',
  },
  bomFieldSubLabelView: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  bomTableLabelRow: {
    flex: 2,
    fontWeight: 900,
    fontSize: '18px',
    display: 'flex',
    flexDirection: 'row',
  },
  bomFieldLabelsView: {
    flex: 3,
    display: 'flex',
    flexDirection: 'row',
  },
  tooltipIcon: {
    marginLeft: '5px',
    backgroundColor: '#ececec',
    padding: '3px 5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    fontSize: '12px',
  },
  bomButtonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: '10px',
  },
  totalsTableWrapper: {
    margin: '5px 0px 20px 0px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end',
  },
  totalsTable: {
    display: 'flex',
    flexDirection: 'column',
  },
  totalsTableTitle: {
    fontWeight: 900,
    fontSize: '18px',
    width: '100%',
    textAlign: 'center',
    padding: '5px 0px',
  },
  totalsTableRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '5px',
    borderBottom: '1px solid #ececec',
  },
  totalsTitle: {
    fontWeight: 900,
  },
  totalsValue: {
    marginLeft: '50px',
  },
  input: {
    backgroundColor: '#fff',
    maxWidth: '70%',
    borderRadius: 5,
    textAlign: 'center',
    borderColor: '#ffda00',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    boxShadow: '2px 2px #ececec',
  },
  warning: {
    textAlign: 'center',
    color: 'black',
    margin: '0px 10px',
    padding: '4px',
  },
  warningWrapper: {
    borderRadius: '5px',
    backgroundColor: '#fcf4c5',
    opacity: 0.7,
    marginBottom: '15px',
  },
  highPriorityWarningWrapper: {
    borderRadius: '5px',
    backgroundColor: 'rgb(246, 204, 209)',
    opacity: 0.7,
    marginBottom: '15px',
  },
})

type PropTypes = {
  newBOM: BomResponseType
  onClose: () => void
  isFirstBOMForSystem: boolean
  warnings: string[]
  highPriorityWarnings: string[]
}

const IronRidgeBom: React.FC<PropTypes> = (props) => {
  const getSystemName = () => {
    let systemName = undefined
    // @ts-ignore
    if (editor.selectedSystem?.name?.length > 0) systemName = editor.selectedSystem.name
    else {
      // @ts-ignore
      let systemClone = { ...editor.selectedSystem }
      // @ts-ignore
      systemClone.module_quantity = editor.selectedSystem.moduleQuantity()
      systemName = systemNameOrDefault(systemClone)
    }
    return systemName
  }

  const [bomToSave, setBomToSave] = useState<any>(props.newBOM)
  const [systemName, setSystemName] = useState<string | undefined>(undefined)
  const [integrationConfig, setIntegrationConfig] = useState<any>({})
  const [showCOGSWarning, setShowCOGSWarning] = useState<boolean>(false)
  const [isCalculatingTotal, setIsCalculatingTotal] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)

  useEffect(() => {
    setSystemName(getSystemName())
  }, [])

  useEffect(() => {
    if (window.editor.selectedSystem.integration_json) {
      // wait for the field to be set, pretty hacky though
      setTimeout(() => {
        setIntegrationConfig(parseIntegrationJson(window.editor.selectedSystem.integration_json))
      }, 1000)
    }
  }, [props.newBOM])

  const classes = useStyles()
  const translate = useTranslate()

  const roleId = useSelector(authSelectors.getCurrentRole)?.id

  const existingIRComponents = editor.selectedSystem?.children?.filter(
    (component: any) => component.type === 'OsOther' && component.manufacturer_name === IR_MFR_NAME
  )
  const orgComponents = window.AccountHelper.getComponentOtherSpecsAvailable()?.filter(
    (comp: any) => comp.manufacturer_name === IR_MFR_NAME
  )

  const getInputCostForItem = (
    bomLineItem: IronRidgeBOMItemType,
    category: string,
    existingComponent: OtherComponentType
  ): number | null => {
    if (!bomToSave || !bomLineItem) return null
    const lineItem = bomToSave.categories[category].lineItems.find((item: any) => {
      return item.partNumber === bomLineItem.partNumber
    })
    if (!lineItem) return null
    else if (lineItem.cogs) return lineItem.cogs
    else return Math.round((parseFloat(lineItem.totalPrice) / lineItem.quantity) * 100) / 100
  }

  const confirmBeforeClose = () => {
    if (
      window.confirm(
        translate('Are you sure you want to close this form? All progress will be lost and your BOM will not be saved')
      )
    )
      props.onClose()
  }

  const addOtherComponentsFromBom = () => {
    setSaving(true)
    let componentIntegrationConfig: any = {}
    //first go get any existing IronRidge other components on the project and remove them
    if (existingIRComponents && existingIRComponents.length > 0) {
      existingIRComponents.forEach((existingComp) => window.editor.deleteObject(existingComp))
    }
    //get list of other components on the org to decide if we need to add any to the org before putting it on the system
    let orgComponentMap: any = {}
    if (orgComponents && orgComponents.length > 0) {
      orgComponents.forEach((component: any) => {
        orgComponentMap[component.title] = component
      })
    }

    let systemsOtherComponents: any[] = []
    let newOrgOtherComponents: any[] = []
    let orgOtherComponentstoUpdate: any[] = []
    Object.keys(bomToSave.categories).forEach((category: string) => {
      let val = bomToSave.categories[category]
      val.lineItems.forEach((item: any) => {
        const orgComponent = orgComponentMap[item.partNumber]
        const componentId = orgComponent?.id ? orgComponent.id : null
        const externalData = orgComponent?.external_data ? orgComponent.external_data : null
        const newCost = getInputCostForItem(item, category, orgComponent)
        let newOtherComponent = {
          code: item.partNumber,
          manufacturer_name: IR_MFR_NAME,
          title: item.partNumber,
          external_data: externalData,
          other_component_type: category,
          dc_optimizer_efficiency: null,
          dc_optimizer_max_input_power: null,
          dc_optimizer_max_input_voltage: null,
          dc_optimizer_max_input_current: null,
          description: item.partDescription || '',
          show_customer: false,
          show_in_your_solution: false,
          show_in_quotation_table: false,
          additional_costs_enabled: false,
          formula: '',
          value: null,
          cycle_units: '',
          start: null,
          end: null,
          annual_adjustment_percentage: null,
          product_warranty: null,
          skus: '',
          quantity: item.quantity,
          other_id: componentId,
          cost: newCost,
        }
        if (!componentId) newOrgOtherComponents.push(newOtherComponent)
        else {
          if (newCost !== orgComponentMap[item.partNumber].cost) {
            let toUpdate = orgComponentMap[item.partNumber]
            toUpdate.cost = newCost
            orgOtherComponentstoUpdate.push(toUpdate)
          }
          componentIntegrationConfig[componentId] = newCost
        }
        if (componentId) systemsOtherComponents.push(newOtherComponent)
      })
    })

    const restClientInstance = restClient(API_URL)
    restClientInstance('CUSTOM_POST', 'custom', {
      url:
        'orgs/' +
        //@ts-ignore
        WorkspaceHelper.project.org_id +
        '/roles/' +
        roleId +
        '/systems/' +
        window.editor.selectedSystem.uuid +
        '/ironridge/components/',
      data: { components: [...newOrgOtherComponents, ...orgOtherComponentstoUpdate, ...systemsOtherComponents] },
    })
      .then((res: any) => {
        if (res.data.success) {
          // get the id and other_id of newly activated components so we can use that when adding the components to the system
          newOrgOtherComponents?.forEach((comp) => {
            let newlySavedComponent = res.data.activations?.find((act) => act.code === comp.code)
            comp.other_id = newlySavedComponent.id
            comp.id = newlySavedComponent.id
            comp.manufacturer_name = IR_MFR_NAME
            comp.show_customer = newOrgOtherComponents.show_customer
            componentIntegrationConfig[newlySavedComponent.id] = formatCurrency(comp.cost)
            systemsOtherComponents.push(comp)
          })

          window.AccountHelper.addComponentOtherSpecs(newOrgOtherComponents)

          systemsOtherComponents.forEach((componentToAdd: any) => {
            addOtherComponentToSystem(componentToAdd)
          })

          logAmplitudeEvent('ironridge_bom_saved', {})
          setSaving(false)
          props.onClose()
        }
      })
      .catch((err: any) => err)
  }

  const addOtherComponentToSystem = (componentToAdd: any) => {
    var target = window.editor.objectByUuid(editor.selectedSystem.uuid)
    window.editor.execute(
      //@ts-ignore
      new window.AddObjectCommand(new window.OsOther(componentToAdd), target, false)
    )
  }

  const saveValues = () => {
    if (!saving) addOtherComponentsFromBom()
  }

  const onFieldChange = (newVal: string, i: number, fieldName: 'quantity' | 'cogs', categoryName: string) => {
    if (fieldName === 'cogs' && !showCOGSWarning) setShowCOGSWarning(true)
    const updatedBom = { ...bomToSave }
    // if they are changing quantity but haven't touched cost we need to calculate the initial unit cost and add that to cogs to make it easier to do downtream calcs
    if (fieldName === 'quantity' && !updatedBom.categories[categoryName].lineItems[i]['cogs']) {
      let initialUnitPrice =
        parseFloat(updatedBom.categories[categoryName].lineItems[i].totalPrice) /
        parseFloat(updatedBom.categories[categoryName].lineItems[i].quantity)
      updatedBom.categories[categoryName].lineItems[i].cogs = initialUnitPrice
    }
    updatedBom.categories[categoryName].lineItems[i][fieldName] = parseFloat(newVal)
    setBomToSave(updatedBom)
  }

  const getTotalBomPrice = () => {
    if (bomToSave) {
      let totalPrice = 0
      Object.keys(bomToSave?.categories)?.forEach((categoryName) => {
        let category = bomToSave.categories[categoryName]
        category?.lineItems?.forEach((item) => {
          if (item.cogs >= 0 && item.quantity >= 0) {
            totalPrice += item.cogs * item.quantity
          } else totalPrice += parseFloat(item.totalPrice)
        })
      })
      return totalPrice
    } else return 0
  }

  const getTotalBOMQuantity = () => {
    if (bomToSave) {
      let totalQty = 0
      Object.keys(bomToSave?.categories)?.forEach((categoryName) => {
        let category = bomToSave.categories[categoryName]
        category?.lineItems?.forEach((item) => {
          totalQty += item.quantity
        })
      })
      return totalQty
    } else return 0
  }

  return (
    <div className={classes.bomModalContentWrapper}>
      <h1>
        Bill of Materials for {systemName} at {WorkspaceHelper.project.address}
      </h1>
      <div className={classes.bomTable}>
        <div className={classes.bomId}>IronRidge project ID: {props.newBOM?.projectId}</div>
        <div className={classes.bomTableLabelRow}>
          <div className={classes.bomProductTitleView}>Part</div>
          <div className={classes.bomFieldLabelsView}>
            <div className={classes.bomOtherCol}>Quantity</div>
            <div className={classes.bomOtherCol} style={{ flex: 2 }}>
              COGS
              {/* <span className={classes.tooltipIcon}>?</span> */}
            </div>
            <div className={classes.bomOtherCol} style={{ flex: 1, justifyContent: 'flex-end' }}>
              Total $
            </div>
          </div>
        </div>
        {Object.keys(props.newBOM.categories).map((categoryName: string) => {
          let category = props.newBOM.categories[categoryName]

          return (
            <div className={classes.bomSection}>
              <div className={classes.bomSectionTitleView}>
                <h4 className={classes.bomSectionTitle}>{categoryName}</h4>
                <div className={classes.bomFieldSubLabelWrapper}>
                  <div className={classes.bomFieldSubLabelView}>
                    <div style={{ flex: 1 }}>{props.isFirstBOMForSystem ? 'Old' : ''}</div>
                    <div style={{ flex: 1 }}>New</div>
                  </div>
                  <div className={classes.bomFieldSubLabelView}>
                    <div style={{ flex: 1 }}>MSRP</div>
                    <div style={{ flex: 1 }}>COGS</div>
                  </div>
                  <div className={classes.bomFieldSubLabelView}></div>
                </div>
              </div>
              {category?.lineItems?.map((item: any, i: number) => {
                return (
                  <IronRidgeBomRow
                    key={item.partId}
                    i={i}
                    item={item}
                    bomToSave={bomToSave}
                    onFieldChange={onFieldChange}
                    categoryName={categoryName}
                    isFirstBOMForSystem={props.isFirstBOMForSystem}
                    integrationConfig={integrationConfig}
                    existingIRComponents={existingIRComponents}
                    orgComponents={orgComponents}
                    setIsCalculatingTotal={setIsCalculatingTotal}
                  />
                )
              })}
            </div>
          )
        })}
        <div className={classes.totalsTableWrapper}>
          <div className={classes.totalsTable}>
            <div className={classes.totalsTableTitle}>Totals</div>
            <div className={classes.totalsTableRow}>
              <span className={classes.totalsTitle}>Price</span>
              <span
                className={classes.totalsValue}
                style={{ opacity: isCalculatingTotal ? 0.5 : 1.0 }}
              >{`$${formatCurrency(parseFloat(getTotalBomPrice()))}`}</span>
            </div>
            <div className={classes.totalsTableRow}>
              <span className={classes.totalsTitle}>Quantity</span>
              <span className={classes.totalsValue} style={{ opacity: isCalculatingTotal ? 0.5 : 1.0 }}>
                {parseInt(getTotalBOMQuantity())}
              </span>
            </div>
          </div>
        </div>
      </div>
      {showCOGSWarning && (
        <Alert severity="warning">
          Editing the price of components here will affect the price of the component itself and can therefore affect
          future calculations on other projects.
        </Alert>
      )}
      <div className={classes.highPriorityWarningWrapper}>
        {props.highPriorityWarnings?.length > 0 && (
          <div style={{ fontWeight: 900, marginBottom: '5px', marginTop: '5px', textDecoration: 'underline' }}>
            Your project design was returned with {props.highPriorityWarnings?.length}{' '}
            {props.highPriorityWarnings?.length > 1 ? 'warnings' : 'warning'}
          </div>
        )}
        {props.highPriorityWarnings?.map((warning, i) => {
          return (
            <div key={i} className={classes.warning}>
              {warning}
            </div>
          )
        })}
      </div>
      <div className={classes.warningWrapper}>
        {props.warnings?.length > 0 && (
          <div style={{ fontWeight: 900, marginBottom: '5px', marginTop: '5px', textDecoration: 'underline' }}>
            Your project design was returned with {props.warnings?.length}{' '}
            {props.warnings?.length > 1 ? 'alerts' : 'alert'}
          </div>
        )}
        {props.warnings?.map((warning, i) => {
          return (
            <div key={i} className={classes.warning}>
              {warning}
            </div>
          )
        })}
      </div>
      <div className={classes.bomButtonsWrapper}>
        <GenericButton
          onClick={confirmBeforeClose}
          label="Dismiss"
          disabled={false}
          backgroundColor="#ececec"
          labelColor="rgb(77,77,77)"
          tooltipLabel={null}
        />
        <GenericButton
          onClick={saveValues}
          label="Save Changes"
          backgroundColor="#ffda00"
          labelColor="rgb(77,77,77)"
          tooltipLabel={null}
          disabled={saving}
        />
      </div>
      <p>
        Clicking "Save Changes" will add the above line items as Other Components on this system. The components will
        also be saved to your account for future use.
      </p>
      <p>
        To download your Bill of Materials click save changes and save your project then click the Download BOM” button
        the Project page.
      </p>
    </div>
  )
}

export default IronRidgeBom

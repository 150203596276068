import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'
import { OpenSolarThemeType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orderSelectors } from 'ducks/orderComponents'
import { setStudioSavePrompt } from 'ducks/studioPrompt'
import ProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/projectOrderPresenter'
import useTranslateWithVariable from 'projectSections/hooks/useTranslateWithVariable'
import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  linkText: {
    textDecoration: 'none',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.subtitle1.fontSize,
  },
  cartIcon: {
    paddingRight: '5px',
    paddingLeft: '10px',
    verticalAlign: 'middle',
  },
  hardwareContainer: ({ totalQuantity }: { totalQuantity: number }) => ({
    color: totalQuantity > 0 ? theme.themeColorDark : '#ffffff',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  }),
  inventoryContainer: {
    color: theme.greyDark2,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
}))

const ShopCartButton = ({ isInventory = false }) => {
  const cartItems = useSelector(orderSelectors.getConfirmedLineItems)
  const totalQuantity = ProjectOrderPresenter.getLineItemsTotalQuantity(cartItems)
  const classes = useStyles({ totalQuantity })
  const history = useHistory()
  const dispatch = useDispatch()
  const translate = useTranslateWithVariable()
  const cart = '/shop/cart'

  const onClick = () => {
    logAmplitudeEvent('hardware_cart_button_clicked', {
      source: 'hardware_header',
    })
    if (!window.disableAutoSave && window.projectForm?.mutators.getFormDirtyFields().length > 0) {
      dispatch(
        setStudioSavePrompt({
          show_prompt: true,
          redirect: cart,
          prompt_heading: translate('Leave Project to %{label}?', { label: translate('Cart') }),
        })
      )
    } else {
      history.push(cart)
    }
  }

  return (
    <div className={isInventory ? classes.inventoryContainer : classes.hardwareContainer}>
      <div className={classes.linkText}>{totalQuantity}</div>
      <span className={classes.linkText} onClick={onClick}>
        {isInventory && totalQuantity > 0 ? (
          <ShoppingCartIcon className={classes.cartIcon} />
        ) : (
          <ShoppingCartOutlinedIcon className={classes.cartIcon} />
        )}
        Cart
      </span>
    </div>
  )
}

export default memo(ShopCartButton)

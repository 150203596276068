import React from 'react'
import { useTranslate } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  base: {
    flex: 1,
    borderBottom: '2px solid rgba(0, 0, 0, 0.12)',
    color: 'rgba(0, 0, 0, 0.12)',
    fontWeight: 700,
    textTransform: 'uppercase',
    fontSize: '12px',
    padding: '16px 0px',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'color 0.5s ease, border-bottom-color 0.5s ease',
  },
  selected: {
    borderBottom: '2px solid #000',
    color: '#000',
  },
}))

type PropTypes = {
  activeTabIndex: number
  setActiveTabIndex: (newTab: number) => void
}

const ProjectHistoryNavBar: React.FunctionComponent<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()

  const onTabClick = (i: number) => props.setActiveTabIndex(i)

  const getClassName = (i: number): string => {
    if (i !== props.activeTabIndex) return classes.base
    else return `${classes.base} ${classes.selected}`
  }
  return (
    <Grid item xs={12}>
      <div className={classes.main}>
        <div onClick={() => onTabClick(0)} className={getClassName(0)}>
          <span>{translate('All')}</span>
        </div>
        <div onClick={() => onTabClick(1)} className={getClassName(1)}>
          <span>{translate('Milestones')}</span>
        </div>
        <div onClick={() => onTabClick(2)} className={getClassName(2)}>
          <span>{translate('Customer')}</span>
        </div>
        <div onClick={() => onTabClick(3)} className={getClassName(3)}>
          <span>{translate('Audit')}</span>
        </div>
      </div>
    </Grid>
  )
}
export default ProjectHistoryNavBar

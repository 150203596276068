import { authSelectors } from 'ducks/auth'
import { permissionsSelectors } from 'ducks/permissions'
import { useSelector } from 'react-redux'
import { PermissionKey } from 'types/roles'

export const useProjectEntityPermissions = (
  entityOrgId: number | undefined,
  permissionKey: PermissionKey | undefined
) => {
  const orgId = useSelector(authSelectors.getOrgId)
  const rolePerm = useSelector(permissionsSelectors.getPermissionByKey(permissionKey))
  const projPerm = useSelector(permissionsSelectors.getProjectPermissionByKey(permissionKey))
  return orgId === entityOrgId ? rolePerm : projPerm
}

import { orgSelectors } from 'ducks/orgs'
import Alert from 'elements/Alert'
import moment from 'moment'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import useSegenAuthenticationStatus from 'services/segen/useSegenAuthenticationStatus'
import useSegenService from 'services/segen/useSegenServiceV2'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import OrderLineItem from '../OrderLineItem'
import ProjectOrderPresenter from '../ProjectOrderPresenter/projectOrderPresenter'
import { SegenPresetConfig, getSupplierConfig } from '../configs'
import { HardwareSupplierEnum, LineItemLoadedDataType } from '../type'

const useStyles = makeOpenSolarStyles((theme) => ({
  pricingAlert: {
    textAlign: 'left',
  },
  loginLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}))

const getLastRefreshDateFromLineItems = (lineItems: OrderLineItem[], distributor) => {
  const lineItemLoadedData: LineItemLoadedDataType[] = lineItems
    .map((li: OrderLineItem) => {
      return li.data
    })
    .filter((f): f is LineItemLoadedDataType => !!f)

  const distributorConfig = getSupplierConfig(distributor)
  const firstComponentWithOrdering = lineItemLoadedData?.find(
    (component) => component?.distributors && component?.distributors.length > 0
  )
  const firstOrderingForThisDistributor = firstComponentWithOrdering?.distributors.find(
    (distributor) => distributor.distributor === distributorConfig?.filterKey
  )

  const lastPricingRefreshDate = firstOrderingForThisDistributor?.refresh_date
    ? new Date(Date.parse(firstOrderingForThisDistributor?.refresh_date))
    : null

  return lastPricingRefreshDate
}

const PricingRefreshAlert = ({ lineItems }: { lineItems: OrderLineItem[] }) => {
  const isLoading = useMemo(() => lineItems.some((li) => li.status === 'loading'), [lineItems])
  const distributorSuppliedLineItems = useMemo(
    () => lineItems.filter((li) => ProjectOrderPresenter.getStockLevelStatusFromLineItem(li) !== 'not_available'),
    [lineItems]
  )
  const segenConnectStatus = useSegenAuthenticationStatus()
  const classes = useStyles()
  const segenService = useSegenService()
  const selectedDistributor = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const lastPricingRefreshDate = getLastRefreshDateFromLineItems(distributorSuppliedLineItems, selectedDistributor)

  // Only have this for segen now.
  if (
    selectedDistributor !== HardwareSupplierEnum.Segen ||
    isLoading ||
    distributorSuppliedLineItems.length === 0 ||
    segenConnectStatus === 'authenticated'
  )
    return null
  // Only show the warning if a certain number of days have passed.
  // TODO this should probably be configurable.
  const rightNow = moment()
  const daysDiff = lastPricingRefreshDate ? rightNow.diff(lastPricingRefreshDate, 'days') : 0

  if (lastPricingRefreshDate && daysDiff <= 3) {
    return null
  }

  const SegenLoginLink = (
    <a
      className={classes.loginLink}
      onClick={() => segenService.connect({ enableRedirect: true, source: 'PricingRefreshWarning' })}
    >
      Log in to {SegenPresetConfig.name}
    </a>
  )

  return (
    <Alert severity="warning">
      <div className={classes.pricingAlert}>
        {lastPricingRefreshDate !== null ? (
          <>
            Your organisation's {SegenPresetConfig.name} discounts were last refreshed on{' '}
            {lastPricingRefreshDate.toLocaleDateString()}. {SegenLoginLink} to refresh pricing.
          </>
        ) : (
          <>{SegenLoginLink} to see pricing.</>
        )}
      </div>
    </Alert>
  )
}

export default PricingRefreshAlert

import { Grid } from '@material-ui/core'
import { useTranslate } from 'ra-core'
import React from 'react'
import { tableStyles } from '../../styles'
import PricingRates from './PricingRates'

type PropTypes = {
  disabled?: boolean
}

const AdjustmentTable: React.FC<PropTypes> = ({ disabled }) => {
  const classes = tableStyles()
  const translate = useTranslate()

  return (
    <div className={classes.tableContainer}>
      <h2>{translate('Percentage Adjustments')}</h2>
      <Grid container spacing={1} className={classes.tableHeader}>
        <Grid item xl={3} className={classes.row}>
          <span>{translate('Name')}</span>
        </Grid>
        <Grid item xl={4} className={`${classes.row} ${classes.adjustmentHeaders}`}>
          <span>{translate('Amount %')}</span>
          <span>{translate('Adjustment To')}</span>
        </Grid>
        <Grid item xl={4}></Grid>
        <div style={{ width: 32 }} />
      </Grid>
      <Grid container spacing={1} justify="space-between" className={classes.tableRow}>
        <p>{translate('CHARGES')}</p>
        <PricingRates source="tariffData.percentage_adjustments_charges" disabled={disabled} />

        <hr className="light" />
      </Grid>
      <Grid container spacing={1} justify="space-between" className={`${classes.tableRow} ${classes.lastRow}`}>
        <p>{translate('DISCOUNTS')}</p>
        <PricingRates source="tariffData.percentage_adjustments_discounts" disabled={disabled} />
      </Grid>
    </div>
  )
}

export default AdjustmentTable

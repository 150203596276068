// @ts-nocheck
import { makeStyles } from '@material-ui/core/styles'
import MuiTooltip from '@material-ui/core/Tooltip'
import React from 'react'
import { ToolTipConfigType } from '../types'
import usePosition from './usePosition'

const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: 2500,
  },
  arrow: {
    color: 'rgb(255,255,255,0)',
  },
  tooltip: {
    background: 'rgb(255,255,255,0)',
    maxWidth: 400,
    fontSize: 14,
    padding: 0,
    borderRadius: 8,
    margin: 20,
  },
}))

const ToolTip = (props: ToolTipConfigType) => {
  const {
    placement = 'bottom',
    targetId,
    maxWait,
    component,
    handleNext,
    handlePrev,
    handleNextLabel,
    handlePrevLabel,
    handleCancel,
    keepObserve,
    options = {},
  } = props
  const { style: overrideStyling = {}, accountScroll = false, ...rest } = options
  const classes = useStyles()
  const position = usePosition(targetId, maxWait, undefined, keepObserve)

  return position ? (
    <MuiTooltip
      PopperProps={{
        disablePortal: true,
      }}
      open={true}
      classes={{ popper: classes.popper, arrow: classes.arrow, tooltip: classes.tooltip }}
      arrow={false}
      placement={placement}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={React.createElement(component, { handleNext, handlePrev, handleCancel, handleNextLabel, handlePrevLabel })}
    >
      <div
        style={{
          top: position?.y + (accountScroll ? window.scrollY : 0),
          left: position?.x,
          width: position?.width,
          height: position?.height,
          position: 'absolute',
          zIndex: -1,
          ...overrideStyling,
        }}
      ></div>
    </MuiTooltip>
  ) : null
}

export default ToolTip

/*

Handy code for sorting segen categories alphabetically...

data.sort((a, b) => a.category.localeCompare(b.category))
data.forEach(category => category.subCategories.sort((a, b) => (a.subcategory) ? a.subcategory.localeCompare(b.subcategory) : -1))

*/

export const segenCategories = [
  {
    category: 'AC Components',
    id: 54,
    subCategories: [
      { id: 358, subcategory: 'AC Accessories' },
      { id: 355, subcategory: 'AC Cable' },
      { id: 356, subcategory: 'Circuit Breakers' },
      { id: 357, subcategory: 'DIN Enclosures' },
      { id: 362, subcategory: 'Electrical Accessories' },
      { id: 363, subcategory: 'Garage Unit' },
    ],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/ACComponents',
  },
  {
    category: 'Accessories',
    id: 16,
    subCategories: [
      { id: 66, subcategory: '.s' },
      { id: 61, subcategory: 'Fittings' },
      { id: 91, subcategory: 'Fixings' },
      { id: 56, subcategory: 'Flow Meters' },
      { id: 63, subcategory: 'Fluids' },
      { id: 57, subcategory: 'Hoses' },
      { id: 74, subcategory: 'Interconnectors' },
      { id: 90, subcategory: 'Pool Exchangers' },
      { id: 67, subcategory: 'Sensors' },
      { id: 60, subcategory: 'Tools' },
    ],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/thermal/Accessories',
  },
  {
    category: 'Accessories',
    id: 27,
    subCategories: [
      { id: 148, subcategory: 'Accessories' },
      { id: 184, subcategory: 'Bypass Values' },
      { id: 165, subcategory: 'Connector Cables' },
      { id: 187, subcategory: 'Deaerators' },
      { id: 186, subcategory: 'Expansion Vessels' },
      { id: 188, subcategory: 'Flexible Hosess' },
      { id: 139, subcategory: 'Flow Meters' },
      { id: 185, subcategory: 'Glycols' },
      { id: 202, subcategory: 'Heat Meter' },
      { id: 147, subcategory: 'Heat Pump Pre-Programmings' },
      { id: 68, subcategory: 'Hot Water Cylinders' },
      { id: 164, subcategory: 'Immersion Elements' },
      { id: 141, subcategory: 'Isolation Valves' },
      { id: 290, subcategory: 'Low Loss Header' },
      { id: 166, subcategory: 'Manifolds' },
      { id: 146, subcategory: 'MCS Calculation Services' },
      { id: 132, subcategory: 'Motorised Valves' },
      { id: 167, subcategory: 'Pumps' },
      { id: 190, subcategory: 'System Cleaners' },
      { id: 142, subcategory: 'Temperature Sensors' },
      { id: 133, subcategory: 'Thermostats' },
      { id: 180, subcategory: 'Water FIlters' },
      { id: 140, subcategory: 'Water Pumps' },
    ],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/hp/Accessories',
  },
  {
    category: 'Accessories',
    id: 37,
    subCategories: [
      { id: 352, subcategory: 'Bird Protection' },
      { id: 183, subcategory: 'Bulk Packs' },
      { id: 337, subcategory: 'Cable termination caps' },
      { id: 233, subcategory: 'DC Battery Storage' },
      { id: 237, subcategory: 'DC Connectors' },
      { id: 173, subcategory: 'Demonstrations' },
      { id: 248, subcategory: 'Garage Units' },
      { id: 159, subcategory: 'Immersion Controllers' },
      { id: 381, subcategory: 'Large Bulk Packs' },
      { id: 232, subcategory: 'LED' },
      { id: 336, subcategory: 'Phase Couplers' },
      { id: 122, subcategory: 'Single Phase Voltage Optimisations' },
      { id: 161, subcategory: 'Smart Plugs' },
      { id: 201, subcategory: 'Special Bulk Pack' },
      { id: 204, subcategory: 'Three Phase Voltage Optimisation' },
    ],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Accessories',
  },
  {
    category: 'Bespoke Orders',
    id: 41,
    subCategories: [{ id: 168, subcategory: 'Special Orders' }],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/BespokeOrders',
  },
  {
    category: 'Buffer Tanks',
    id: 39,
    subCategories: [{ id: 149, subcategory: 'Buffer Tanks' }],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/hp/BufferTanks',
  },
  {
    category: 'Cables',
    id: 9,
    subCategories: [
      { id: 235, subcategory: null },
      { id: 111, subcategory: 'AC Cables' },
      { id: 114, subcategory: 'Accessories' },
      { id: 299, subcategory: 'Battery Cable' },
      { id: 191, subcategory: 'Bulk Packs' },
      { id: 110, subcategory: 'Cable Flashings' },
      { id: 236, subcategory: 'Cables' },
      { id: 113, subcategory: 'Connectors' },
      { id: 109, subcategory: 'DC Cables' },
      { id: 192, subcategory: 'Large Bulk Packs' },
      { id: 368, subcategory: 'MC4 Jumper Cable' },
      { id: 112, subcategory: 'Pre Terminated Cables' },
    ],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Cables',
  },
  {
    category: 'Charging Stations',
    id: 52,
    subCategories: [],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/ChargingStation',
  },
  {
    category: 'Collectors',
    id: 14,
    subCategories: [
      { id: 59, subcategory: 'Evacuated Tubes' },
      { id: 58, subcategory: 'Flat Plates' },
    ],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/thermal/Collectors',
  },
  {
    category: 'Connectors',
    id: 28,
    subCategories: [
      { id: 83, subcategory: 'Array Connectors' },
      { id: 84, subcategory: 'Other Connectors' },
    ],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/thermal/Connectors',
  },
  {
    category: 'Controllers',
    id: 31,
    subCategories: [
      { id: 53, subcategory: 'Control Units' },
      { id: 82, subcategory: 'Remote Display Units' },
    ],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/thermal/Controllers',
  },
  {
    category: 'Controllers',
    id: 38,
    subCategories: [
      { id: 153, subcategory: 'Bulk Packs' },
      { id: 162, subcategory: 'Control Boxes' },
    ],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/hp/Controllers',
  },
  {
    category: 'Cylinders',
    id: 29,
    subCategories: [{ id: 52, subcategory: 'Hot Water Cylinders' }],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/thermal/Cylinders',
  },
  {
    category: 'Cylinders',
    id: 30,
    subCategories: [
      { id: 137, subcategory: 'With Buffers' },
      { id: 138, subcategory: 'Without Buffers' },
    ],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/hp/Cylinders',
  },
  {
    category: 'Delivery',
    id: 18,
    subCategories: [],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Delivery',
  },
  {
    category: 'Delivery',
    id: 53,
    subCategories: [{ id: 354, subcategory: 'Delivery Error' }],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Delivery',
  },
  {
    category: 'Display',
    id: 22,
    subCategories: [
      { id: 213, subcategory: 'Accessories' },
      { id: 209, subcategory: 'Bluetooth Repeaters' },
      { id: 128, subcategory: 'Bulk Packs' },
      { id: 22, subcategory: 'Communications' },
      { id: 214, subcategory: 'Current Sensor Clamps' },
      { id: 286, subcategory: 'Data Package' },
      { id: 212, subcategory: 'Dataloggers' },
      { id: 171, subcategory: 'Demonstrations' },
      { id: 131, subcategory: 'Display Interfaces' },
      { id: 266, subcategory: 'Energy Meter' },
      { id: 215, subcategory: 'Firefighter Controllers' },
      { id: 293, subcategory: 'Large Bulk Pack' },
      { id: 403, subcategory: 'Meter Enclosure' },
      { id: 76, subcategory: 'Meter Units' },
      { id: 115, subcategory: 'Personal Displays' },
      { id: 211, subcategory: 'Plant Communications' },
      { id: 210, subcategory: 'Plant Controllers' },
      { id: 217, subcategory: 'Power Management' },
      { id: 116, subcategory: 'Public Displays' },
      { id: 350, subcategory: 'Remote GSM Monitoring' },
      { id: 287, subcategory: 'Single Phase Remote GSM Monitoring' },
      { id: 117, subcategory: 'Three Phase Remote GSM Monitoring' },
      { id: 23, subcategory: 'Web Interfaces' },
      { id: 396, subcategory: 'WiFi Dongle' },
    ],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Display',
  },
  {
    category: 'Environmental Sensors',
    id: 36,
    subCategories: [
      { id: 208, subcategory: 'Weather Sensor Controllers' },
      { id: 120, subcategory: 'Weather Sensors' },
    ],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Environmental%20Sensors',
  },
  {
    category: 'EV Charging',
    id: 56,
    subCategories: [
      { id: 377, subcategory: 'Access Control' },
      { id: 378, subcategory: 'Book' },
      { id: 369, subcategory: 'Bulk Packs' },
      { id: 371, subcategory: 'Charge Point Connection Unit' },
      { id: 380, subcategory: 'Complete Pallet Purchases' },
      { id: 387, subcategory: 'Cover' },
      { id: 342, subcategory: 'EV Charge Cable' },
      { id: 340, subcategory: 'EV Charger' },
      { id: 379, subcategory: 'EV Charging Inverter' },
      { id: 367, subcategory: 'EV Charging Package' },
      { id: 370, subcategory: 'EV Monitoring and Control' },
      { id: 374, subcategory: 'Large Bulk Packs' },
      { id: 385, subcategory: 'Mounting' },
      { id: 388, subcategory: 'Signs' },
      { id: 372, subcategory: 'Wall Dock' },
      { id: 373, subcategory: 'Warranty Extension' },
    ],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/EVCharging',
  },
  {
    category: 'Expansion Vessels',
    id: 32,
    subCategories: [
      { id: 54, subcategory: 'Expansion Vessels' },
      { id: 89, subcategory: 'Fixings' },
      { id: 88, subcategory: 'Heating Expansion Vessels' },
    ],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/thermal/ExpansionVessels',
  },
  {
    category: 'Foundation',
    id: 6,
    subCategories: [],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/wind/Foundation',
  },
  {
    category: 'Generator Integration ',
    id: 57,
    subCategories: [{ id: 404, subcategory: 'Generator Integration ' }],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Three%20Phase',
  },
  {
    category: 'Heat Pumps',
    id: 25,
    subCategories: [
      { id: 69, subcategory: 'Air Sources' },
      { id: 154, subcategory: 'Bulk Packs' },
      { id: 289, subcategory: 'Complete System' },
      { id: 70, subcategory: 'Ground Sources' },
      { id: 169, subcategory: 'Warranty Extensions' },
    ],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/hp/HeatPumps',
  },
  {
    category: 'Installation',
    id: 48,
    subCategories: [{ id: 258, subcategory: 'Installation Service' }],
    portalUrl: null,
  },
  {
    category: 'Inverters',
    id: 4,
    subCategories: [
      { id: 226, subcategory: null },
      { id: 230, subcategory: null },
      { id: 189, subcategory: 'Additional Units' },
      { id: 205, subcategory: 'Aerial Extensions' },
      { id: 78, subcategory: 'Bulk Packs' },
      { id: 40, subcategory: 'Complete Pallet Purchases' },
      { id: 85, subcategory: 'Composite Packs' },
      { id: 359, subcategory: 'DC Fuse Holders' },
      { id: 33, subcategory: 'Demonstrations' },
      { id: 251, subcategory: 'Eigenverbrauch' },
      { id: 341, subcategory: 'EV Cable' },
      { id: 260, subcategory: 'Inverter Accessories' },
      { id: 247, subcategory: 'Inverter Control Unit' },
      { id: 35, subcategory: 'Junction Boxes' },
      { id: 107, subcategory: 'Large Bulk Packs' },
      { id: 19, subcategory: 'Main Units' },
      { id: 206, subcategory: 'Micro Inverter Gateway' },
      { id: 292, subcategory: 'Micro Inverter Mounting' },
      { id: 46, subcategory: 'Micro Inverter Units' },
      { id: 72, subcategory: 'Optimisers' },
      { id: 172, subcategory: 'Packages' },
      { id: 175, subcategory: 'Safety and Monitoring' },
      { id: 178, subcategory: 'Service Packages' },
      { id: 195, subcategory: 'Special Bulk Pack' },
      { id: 26, subcategory: 'Special Purchases' },
      { id: 108, subcategory: 'String Controls' },
      { id: 21, subcategory: 'String Fuses' },
      { id: 174, subcategory: 'Universal Optimisers' },
      { id: 177, subcategory: 'Warranty Cards' },
      { id: 24, subcategory: 'Warranty Extensions' },
    ],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Inverters',
  },
  {
    category: 'Labels',
    id: 23,
    subCategories: [
      { id: 344, subcategory: 'Bulk Pack' },
      { id: 121, subcategory: 'Hazard Labels' },
      { id: 345, subcategory: 'Large Bulk Pack' },
      { id: 364, subcategory: 'Schematic' },
    ],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Labels',
  },
  {
    category: 'Maintenance',
    id: 46,
    subCategories: [{ id: 253, subcategory: 'Maintenance Agreement' }],
    portalUrl: null,
  },
  {
    category: 'Marketing Services',
    id: 44,
    subCategories: [
      { id: 218, subcategory: 'Event' },
      { id: 176, subcategory: 'Marketing Packs' },
      { id: 219, subcategory: 'Paid for activity' },
    ],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/MarketingServices',
  },
  {
    category: 'Meters',
    id: 12,
    subCategories: [
      { id: 42, subcategory: 'Bulk Packs' },
      { id: 75, subcategory: 'Covers' },
      { id: 294, subcategory: 'Large Bulk Pack' },
      { id: 339, subcategory: 'Single Phase BiDirectional Generation Meters' },
      { id: 126, subcategory: 'Single Phase Generation Meters' },
      { id: 395, subcategory: 'Three Phase BiDirectional Meters' },
      { id: 127, subcategory: 'Three Phase Generation Meters' },
    ],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Meters',
  },
  {
    category: 'Monitoring',
    id: 55,
    subCategories: [],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Monitoring',
  },
  {
    category: 'Mounting',
    id: 5,
    subCategories: [
      { id: 375, subcategory: 'Accessories' },
      { id: 136, subcategory: 'Alignment Connectors' },
      { id: 308, subcategory: 'AluGrid' },
      { id: 93, subcategory: 'Anti Slip Devices' },
      { id: 47, subcategory: 'Anti Slips' },
      { id: 94, subcategory: 'Anti Theft Devices' },
      { id: 223, subcategory: 'Bulk Pack' },
      { id: 10, subcategory: 'Cable Clips' },
      { id: 134, subcategory: 'Cable Flashings' },
      { id: 95, subcategory: 'Cruciforms' },
      { id: 16, subcategory: 'Facades' },
      { id: 27, subcategory: 'Field Mount' },
      { id: 351, subcategory: 'FixGrid' },
      { id: 3, subcategory: 'Fixings' },
      { id: 96, subcategory: 'FixZ' },
      { id: 15, subcategory: 'Flat Roof' },
      { id: 97, subcategory: 'Flat Roof Array' },
      { id: 335, subcategory: 'Flat Roof Bifacial' },
      { id: 98, subcategory: 'Flat Roof Brackets' },
      { id: 155, subcategory: 'Flat Roof Trays' },
      { id: 6, subcategory: 'Ground Mount' },
      { id: 7, subcategory: 'In Roof' },
      { id: 222, subcategory: 'Large Bulk Pack' },
      { id: 269, subcategory: 'Lorenz Siplex' },
      { id: 267, subcategory: 'Lorenz Solo' },
      { id: 268, subcategory: 'Lorenz Trapeo' },
      { id: 77, subcategory: 'Metal Roof' },
      { id: 45, subcategory: 'Module Clips' },
      { id: 2, subcategory: 'Mounting Rails' },
      { id: 4, subcategory: 'Others' },
      { id: 408, subcategory: 'Pitched Roof ' },
      { id: 409, subcategory: 'Pitched Roof ' },
      { id: 25, subcategory: 'Rail Connectors' },
      { id: 49, subcategory: 'Rail End Caps' },
      { id: 229, subcategory: 'Roof Anchor - Corrugated' },
      { id: 100, subcategory: 'Roof Anchor- Hanger Bolts' },
      { id: 102, subcategory: 'Roof Anchor- Seam Clamps' },
      { id: 101, subcategory: 'Roof Anchor- Tile & Slates' },
      { id: 103, subcategory: 'Roof Anchor- Trapezoidals' },
      { id: 1, subcategory: 'Roof Anchors' },
      { id: 163, subcategory: 'Roof Flashings' },
      { id: 104, subcategory: 'Sample Cases' },
      { id: 250, subcategory: 'Special Bulk Pack' },
      { id: 179, subcategory: 'Storm Washers' },
      { id: 135, subcategory: 'Structural Rail Connectors' },
      { id: 105, subcategory: 'Washers' },
      { id: 160, subcategory: 'Wood Screws' },
    ],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Mounting',
  },
  {
    category: 'Mounting',
    id: 15,
    subCategories: [
      { id: 64, subcategory: 'Canopys' },
      { id: 65, subcategory: 'Facades' },
      { id: 51, subcategory: 'Flat Roof' },
      { id: 43, subcategory: 'In Roof' },
      { id: 44, subcategory: 'On Roof' },
      { id: 87, subcategory: 'Pipe Flashings' },
    ],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/thermal/Mounting',
  },
  {
    category: 'Off-Grid',
    id: 45,
    subCategories: [
      { id: 249, subcategory: 'Accessories' },
      { id: 244, subcategory: 'Battery 6V - 12V' },
      { id: 274, subcategory: 'Battery OPzS solar.power' },
      { id: 273, subcategory: 'Battery OPzV solar.power' },
      { id: 298, subcategory: 'Battery OPzV.Bloc' },
      { id: 243, subcategory: 'Charger' },
      { id: 254, subcategory: 'Off-Grid PV Module' },
      { id: 245, subcategory: 'Off-Grid Wechselrichter' },
      { id: 252, subcategory: 'Water Pumps' },
    ],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/OffGrid',
  },
  {
    category: 'Other',
    id: 7,
    subCategories: [
      { id: 37, subcategory: 'Meter Accessories' },
      { id: 39, subcategory: 'Table Tops' },
      { id: 36, subcategory: 'Total Generation Meters' },
      { id: 38, subcategory: 'Wall Mounted' },
    ],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Other',
  },
  {
    category: 'Panels',
    id: 3,
    subCategories: [
      { id: 129, subcategory: 'AC Modules' },
      { id: 349, subcategory: 'Bifacial' },
      { id: 32, subcategory: 'Complete Pallet Purchases' },
      { id: 240, subcategory: 'Daily Deals' },
      { id: 170, subcategory: 'Demonstrations' },
      { id: 80, subcategory: 'Full Containers' },
      { id: 30, subcategory: 'Hybrids' },
      { id: 332, subcategory: 'Hybrids Black' },
      { id: 365, subcategory: 'Insurance' },
      { id: 106, subcategory: 'Large Bulk Packs' },
      { id: 327, subcategory: 'Maxim Smart' },
      { id: 50, subcategory: 'Mini Modules' },
      { id: 331, subcategory: 'Monocrystalline BiFacial' },
      { id: 29, subcategory: 'Monocrystallines' },
      { id: 310, subcategory: 'Off-Grid' },
      { id: 361, subcategory: 'PERC Panels' },
      { id: 28, subcategory: 'Polycrystalline' },
      { id: 338, subcategory: 'Polycrystalline Bifacial' },
      { id: 347, subcategory: 'Price Guide' },
      { id: 346, subcategory: 'Smart' },
      { id: 348, subcategory: 'Smart Optimised' },
      { id: 228, subcategory: 'SolarEdge Smart' },
      { id: 196, subcategory: 'Special Bulk Pack' },
      { id: 31, subcategory: 'Thin Film' },
      { id: 328, subcategory: 'Tigo Smart' },
    ],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Module',
  },
  {
    category: 'Power Management',
    id: 51,
    subCategories: [
      { id: 304, subcategory: 'Export Limitation 1ph' },
      { id: 305, subcategory: 'Export Limitation 3ph' },
    ],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Power%20Management',
  },
  {
    category: 'Protection',
    id: 42,
    subCategories: [
      { id: 407, subcategory: '3ph AC Protection Box' },
      { id: 353, subcategory: 'AC Protection Box' },
      { id: 389, subcategory: 'Battery Protection' },
      { id: 319, subcategory: 'DC Protection Box' },
      { id: 225, subcategory: 'DC Surge Protection' },
      { id: 182, subcategory: 'Lightning Protections' },
      { id: 291, subcategory: 'Network Protection' },
      { id: 207, subcategory: 'Single Phase Surge Protection' },
      { id: 181, subcategory: 'Three Phase Surge Protections' },
    ],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Protection',
  },
  {
    category: 'Radiators',
    id: 26,
    subCategories: [{ id: 71, subcategory: 'Fan Convectors' }],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/hp/Radiators',
  },
  {
    category: 'Rebate',
    id: 43,
    subCategories: [],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Rebate',
  },
  {
    category: 'Special Offers',
    id: 24,
    subCategories: [
      { id: 227, subcategory: null },
      { id: 203, subcategory: 'Accessory Package' },
      { id: 193, subcategory: 'Bulk Pack' },
      { id: 200, subcategory: 'Commercial Packages' },
      { id: 239, subcategory: 'Commercial Project Pricing' },
      { id: 221, subcategory: 'Component Pack' },
      { id: 238, subcategory: 'Daily Deal' },
      { id: 242, subcategory: 'Distributor' },
      { id: 197, subcategory: 'Domestic Packages' },
      { id: 386, subcategory: 'EV Packages' },
      { id: 220, subcategory: 'Mounting Kit Package' },
      { id: 284, subcategory: 'New Build Packages' },
      { id: 241, subcategory: 'Optimiser Package' },
      { id: 329, subcategory: 'PV and Storage Package' },
      { id: 198, subcategory: 'Special Bulk Pack' },
      { id: 194, subcategory: 'Special Pack' },
      { id: 199, subcategory: 'System' },
      { id: 216, subcategory: 'Three Phase Power Management Packages' },
      { id: 123, subcategory: 'Tools' },
    ],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/SpecialOffers',
  },
  {
    category: 'Storage Systems',
    id: 47,
    subCategories: [
      { id: 295, subcategory: 'AC Battery Charger' },
      { id: 313, subcategory: 'AC Cable' },
      { id: 279, subcategory: 'AC Sensor' },
      { id: 399, subcategory: 'AC Storage Kits' },
      { id: 257, subcategory: 'Accessory' },
      { id: 397, subcategory: 'Backup Only Kits' },
      { id: 288, subcategory: 'Battery Accessory' },
      { id: 322, subcategory: 'Battery Base' },
      { id: 320, subcategory: 'Battery Cabinet' },
      { id: 281, subcategory: 'Battery Cable' },
      { id: 300, subcategory: 'Battery Fuse Holder' },
      { id: 297, subcategory: 'Battery Fuses' },
      { id: 326, subcategory: 'Battery Hazard Labels' },
      { id: 255, subcategory: 'Battery Inverter' },
      { id: 366, subcategory: 'Battery Isolator' },
      { id: 278, subcategory: 'Battery Monitor' },
      { id: 301, subcategory: 'Battery Protect' },
      { id: 324, subcategory: 'Battery Rack' },
      { id: 316, subcategory: 'Battery Temperature Sensor' },
      { id: 282, subcategory: 'Bulk Pack' },
      { id: 309, subcategory: 'Cable Lug' },
      { id: 307, subcategory: 'Charger-Inverter' },
      { id: 382, subcategory: 'Charger-Inverter-3ph' },
      { id: 280, subcategory: 'Communication Adaptor' },
      { id: 276, subcategory: 'Communication Cable' },
      { id: 277, subcategory: 'Communication Module' },
      { id: 264, subcategory: 'DC String Battery Controller' },
      { id: 263, subcategory: 'DC String Storage System' },
      { id: 302, subcategory: 'DC-DC Converter' },
      { id: 325, subcategory: 'Demonstration Unit' },
      { id: 275, subcategory: 'Display' },
      { id: 314, subcategory: 'Electrolyte' },
      { id: 318, subcategory: 'Garage Unit' },
      { id: 390, subcategory: 'Grid Backup Kits' },
      { id: 271, subcategory: 'Grid Backup Packages' },
      { id: 343, subcategory: 'Hybrid 3Phase Inverter' },
      { id: 261, subcategory: 'Hybrid Inverter' },
      { id: 311, subcategory: 'Hybrid Inverter - Off-Grid' },
      { id: 398, subcategory: 'Hybrid Storage Kits' },
      { id: 406, subcategory: 'Inverter Cabinet' },
      { id: 283, subcategory: 'Large Bulk Pack' },
      { id: 270, subcategory: 'Lead Acid Battery Pack' },
      { id: 262, subcategory: 'Li-ion Battery Pack' },
      { id: 265, subcategory: 'Li-ion Battery System' },
      { id: 384, subcategory: 'Load Shedding Kit' },
      { id: 334, subcategory: 'Maintenance' },
      { id: 360, subcategory: 'Marketing' },
      { id: 285, subcategory: 'MPPT Charge Controller' },
      { id: 405, subcategory: 'Off-Grid Inverter' },
      { id: 392, subcategory: 'Off-Grid Kits' },
      { id: 306, subcategory: 'Offgrid Packages' },
      { id: 393, subcategory: 'PV System Kits' },
      { id: 402, subcategory: 'PV System Kits Commercial' },
      { id: 401, subcategory: 'PV System Kits New Build' },
      { id: 400, subcategory: 'PV System Kits Residential' },
      { id: 303, subcategory: 'PWM Charge Controller' },
      { id: 317, subcategory: 'Remote Control' },
      { id: 272, subcategory: 'Self Consumption Packages' },
      { id: 391, subcategory: 'Self-Consumption Kits' },
      { id: 312, subcategory: 'Shunt' },
      { id: 376, subcategory: 'Special Bulk Pack' },
      { id: 256, subcategory: 'Storage' },
      { id: 383, subcategory: 'Storage Energy Meter' },
      { id: 394, subcategory: 'UPS System Kits' },
      { id: 321, subcategory: 'Wall Bracket' },
      { id: 315, subcategory: 'Warranty Extension' },
      { id: 333, subcategory: 'Wholegreen Storage Package' },
    ],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/StorageSystems',
  },
  {
    category: 'Switches',
    id: 10,
    subCategories: [
      { id: 234, subcategory: null },
      { id: 9, subcategory: 'AC Isolators' },
      { id: 41, subcategory: 'Bulk Packs' },
      { id: 8, subcategory: 'DC Isolators' },
      { id: 158, subcategory: 'Large Bulk Packs' },
    ],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Switches',
  },
  {
    category: 'Thermal Pump Stations',
    id: 34,
    subCategories: [{ id: 55, subcategory: 'Pump Stations' }],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/thermal/ThermalPumpStations',
  },
  {
    category: 'Tools',
    id: 13,
    subCategories: [
      { id: 119, subcategory: 'Accessories' },
      { id: 224, subcategory: 'Inverter Installation' },
      { id: 125, subcategory: 'Mounting Accessories' },
      { id: 99, subcategory: 'Mounting Tools' },
    ],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Tools',
  },
  {
    category: 'Tower',
    id: 2,
    subCategories: [],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/wind/Tower',
  },
  {
    category: 'Training',
    id: 19,
    subCategories: [],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Training',
  },
  {
    category: 'Training',
    id: 35,
    subCategories: [
      { id: 150, subcategory: 'Domestic Heat Pumps' },
      { id: 144, subcategory: 'Installation Trainings' },
      { id: 156, subcategory: 'Introduction Courses' },
      { id: 143, subcategory: 'MCS Calculation Trainings' },
      { id: 157, subcategory: 'Regulation Awareness' },
      { id: 151, subcategory: 'WPM Controlled Heat Pumps' },
    ],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/hp/Training',
  },
  {
    category: 'Training',
    id: 40,
    subCategories: [{ id: 152, subcategory: 'Solar Thermal Product Specs' }],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/thermal/Training',
  },
  {
    category: 'Training',
    id: 50,
    subCategories: [{ id: 296, subcategory: 'Battery Training' }],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Training',
  },
  {
    category: 'Valves',
    id: 33,
    subCategories: [
      { id: 86, subcategory: 'Motorised Valves' },
      { id: 62, subcategory: 'Valves' },
    ],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/thermal/Valves',
  },
  {
    category: 'Water heating',
    id: 49,
    subCategories: [{ id: 330, subcategory: 'Interface' }],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Water%20heating',
  },
  {
    category: 'Wind Cable',
    id: 21,
    subCategories: [],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/wind/WindCable',
  },
  {
    category: 'Wind Inverter',
    id: 8,
    subCategories: [],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/wind/WindInverter',
  },
  {
    category: 'Wind Other',
    id: 17,
    subCategories: [],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/wind/WindOther',
  },
  {
    category: 'Wind Study',
    id: 20,
    subCategories: [],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/wind/WindStudy',
  },
  {
    category: 'Wind Turbine',
    id: 1,
    subCategories: [],
    portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/wind/WindTurbine',
  },
]

// Sorted by categoryId
// export const segenCategories = [
//   {
//     category: 'Wind Turbine',
//     id: 1,
//     subCategories: [],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/wind/WindTurbine',
//   },
//   {
//     category: 'Tower',
//     id: 2,
//     subCategories: [],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/wind/Tower',
//   },
//   {
//     category: 'Panels',
//     id: 3,
//     subCategories: [
//       { id: 129, subcategory: 'AC Modules' },
//       { id: 349, subcategory: 'Bifacial' },
//       { id: 32, subcategory: 'Complete Pallet Purchases' },
//       { id: 240, subcategory: 'Daily Deals' },
//       { id: 170, subcategory: 'Demonstrations' },
//       { id: 80, subcategory: 'Full Containers' },
//       { id: 30, subcategory: 'Hybrids' },
//       { id: 332, subcategory: 'Hybrids Black' },
//       { id: 365, subcategory: 'Insurance' },
//       { id: 106, subcategory: 'Large Bulk Packs' },
//       { id: 327, subcategory: 'Maxim Smart' },
//       { id: 50, subcategory: 'Mini Modules' },
//       { id: 29, subcategory: 'Monocrystallines' },
//       { id: 331, subcategory: 'Monocrystalline BiFacial' },
//       { id: 310, subcategory: 'Off-Grid' },
//       { id: 361, subcategory: 'PERC Panels' },
//       { id: 28, subcategory: 'Polycrystalline' },
//       { id: 338, subcategory: 'Polycrystalline Bifacial' },
//       { id: 347, subcategory: 'Price Guide' },
//       { id: 346, subcategory: 'Smart' },
//       { id: 228, subcategory: 'SolarEdge Smart' },
//       { id: 348, subcategory: 'Smart Optimised' },
//       { id: 196, subcategory: 'Special Bulk Pack' },
//       { id: 31, subcategory: 'Thin Film' },
//       { id: 328, subcategory: 'Tigo Smart' },
//     ],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Module',
//   },
//   {
//     category: 'Inverters',
//     id: 4,
//     subCategories: [
//       { id: 189, subcategory: 'Additional Units' },
//       { id: 205, subcategory: 'Aerial Extensions' },
//       { id: 230, subcategory: null },
//       { id: 78, subcategory: 'Bulk Packs' },
//       { id: 40, subcategory: 'Complete Pallet Purchases' },
//       { id: 85, subcategory: 'Composite Packs' },
//       { id: 359, subcategory: 'DC Fuse Holders' },
//       { id: 33, subcategory: 'Demonstrations' },
//       { id: 341, subcategory: 'EV Cable' },
//       { id: 260, subcategory: 'Inverter Accessories' },
//       { id: 226, subcategory: null },
//       { id: 247, subcategory: 'Inverter Control Unit' },
//       { id: 35, subcategory: 'Junction Boxes' },
//       { id: 107, subcategory: 'Large Bulk Packs' },
//       { id: 19, subcategory: 'Main Units' },
//       { id: 206, subcategory: 'Micro Inverter Gateway' },
//       { id: 292, subcategory: 'Micro Inverter Mounting' },
//       { id: 46, subcategory: 'Micro Inverter Units' },
//       { id: 72, subcategory: 'Optimisers' },
//       { id: 172, subcategory: 'Packages' },
//       { id: 175, subcategory: 'Safety and Monitoring' },
//       { id: 251, subcategory: 'Eigenverbrauch' },
//       { id: 178, subcategory: 'Service Packages' },
//       { id: 195, subcategory: 'Special Bulk Pack' },
//       { id: 26, subcategory: 'Special Purchases' },
//       { id: 108, subcategory: 'String Controls' },
//       { id: 21, subcategory: 'String Fuses' },
//       { id: 174, subcategory: 'Universal Optimisers' },
//       { id: 177, subcategory: 'Warranty Cards' },
//       { id: 24, subcategory: 'Warranty Extensions' },
//     ],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Inverters',
//   },
//   {
//     category: 'Mounting',
//     id: 5,
//     subCategories: [
//       { id: 136, subcategory: 'Alignment Connectors' },
//       { id: 308, subcategory: 'AluGrid' },
//       { id: 47, subcategory: 'Anti Slips' },
//       { id: 93, subcategory: 'Anti Slip Devices' },
//       { id: 94, subcategory: 'Anti Theft Devices' },
//       { id: 223, subcategory: 'Bulk Pack' },
//       { id: 10, subcategory: 'Cable Clips' },
//       { id: 134, subcategory: 'Cable Flashings' },
//       { id: 95, subcategory: 'Cruciforms' },
//       { id: 16, subcategory: 'Facades' },
//       { id: 27, subcategory: 'Field Mount' },
//       { id: 351, subcategory: 'FixGrid' },
//       { id: 3, subcategory: 'Fixings' },
//       { id: 96, subcategory: 'FixZ' },
//       { id: 15, subcategory: 'Flat Roof' },
//       { id: 97, subcategory: 'Flat Roof Array' },
//       { id: 335, subcategory: 'Flat Roof Bifacial' },
//       { id: 98, subcategory: 'Flat Roof Brackets' },
//       { id: 155, subcategory: 'Flat Roof Trays' },
//       { id: 6, subcategory: 'Ground Mount' },
//       { id: 7, subcategory: 'In Roof' },
//       { id: 222, subcategory: 'Large Bulk Pack' },
//       { id: 269, subcategory: 'Lorenz Siplex' },
//       { id: 267, subcategory: 'Lorenz Solo' },
//       { id: 268, subcategory: 'Lorenz Trapeo' },
//       { id: 77, subcategory: 'Metal Roof' },
//       { id: 45, subcategory: 'Module Clips' },
//       { id: 2, subcategory: 'Mounting Rails' },
//       { id: 375, subcategory: 'Accessories' },
//       { id: 4, subcategory: 'Others' },
//       { id: 408, subcategory: 'Pitched Roof ' },
//       { id: 409, subcategory: 'Pitched Roof ' },
//       { id: 25, subcategory: 'Rail Connectors' },
//       { id: 49, subcategory: 'Rail End Caps' },
//       { id: 1, subcategory: 'Roof Anchors' },
//       { id: 229, subcategory: 'Roof Anchor - Corrugated' },
//       { id: 100, subcategory: 'Roof Anchor- Hanger Bolts' },
//       { id: 102, subcategory: 'Roof Anchor- Seam Clamps' },
//       { id: 101, subcategory: 'Roof Anchor- Tile & Slates' },
//       { id: 103, subcategory: 'Roof Anchor- Trapezoidals' },
//       { id: 163, subcategory: 'Roof Flashings' },
//       { id: 104, subcategory: 'Sample Cases' },
//       { id: 250, subcategory: 'Special Bulk Pack' },
//       { id: 179, subcategory: 'Storm Washers' },
//       { id: 135, subcategory: 'Structural Rail Connectors' },
//       { id: 105, subcategory: 'Washers' },
//       { id: 160, subcategory: 'Wood Screws' },
//     ],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Mounting',
//   },
//   {
//     category: 'Foundation',
//     id: 6,
//     subCategories: [],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/wind/Foundation',
//   },
//   {
//     category: 'Other',
//     id: 7,
//     subCategories: [
//       { id: 37, subcategory: 'Meter Accessories' },
//       { id: 39, subcategory: 'Table Tops' },
//       { id: 36, subcategory: 'Total Generation Meters' },
//       { id: 38, subcategory: 'Wall Mounted' },
//     ],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Other',
//   },
//   {
//     category: 'Wind Inverter',
//     id: 8,
//     subCategories: [],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/wind/WindInverter',
//   },
//   {
//     category: 'Cables',
//     id: 9,
//     subCategories: [
//       { id: 111, subcategory: 'AC Cables' },
//       { id: 114, subcategory: 'Accessories' },
//       { id: 299, subcategory: 'Battery Cable' },
//       { id: 191, subcategory: 'Bulk Packs' },
//       { id: 110, subcategory: 'Cable Flashings' },
//       { id: 113, subcategory: 'Connectors' },
//       { id: 109, subcategory: 'DC Cables' },
//       { id: 192, subcategory: 'Large Bulk Packs' },
//       { id: 368, subcategory: 'MC4 Jumper Cable' },
//       { id: 112, subcategory: 'Pre Terminated Cables' },
//       { id: 235, subcategory: null },
//       { id: 236, subcategory: 'Cables' },
//     ],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Cables',
//   },
//   {
//     category: 'Switches',
//     id: 10,
//     subCategories: [
//       { id: 9, subcategory: 'AC Isolators' },
//       { id: 41, subcategory: 'Bulk Packs' },
//       { id: 8, subcategory: 'DC Isolators' },
//       { id: 234, subcategory: null },
//       { id: 158, subcategory: 'Large Bulk Packs' },
//     ],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Switches',
//   },
//   {
//     category: 'Meters',
//     id: 12,
//     subCategories: [
//       { id: 42, subcategory: 'Bulk Packs' },
//       { id: 75, subcategory: 'Covers' },
//       { id: 294, subcategory: 'Large Bulk Pack' },
//       { id: 339, subcategory: 'Single Phase BiDirectional Generation Meters' },
//       { id: 126, subcategory: 'Single Phase Generation Meters' },
//       { id: 395, subcategory: 'Three Phase BiDirectional Meters' },
//       { id: 127, subcategory: 'Three Phase Generation Meters' },
//     ],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Meters',
//   },
//   {
//     category: 'Tools',
//     id: 13,
//     subCategories: [
//       { id: 119, subcategory: 'Accessories' },
//       { id: 224, subcategory: 'Inverter Installation' },
//       { id: 125, subcategory: 'Mounting Accessories' },
//       { id: 99, subcategory: 'Mounting Tools' },
//     ],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Tools',
//   },
//   {
//     category: 'Collectors',
//     id: 14,
//     subCategories: [
//       { id: 59, subcategory: 'Evacuated Tubes' },
//       { id: 58, subcategory: 'Flat Plates' },
//     ],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/thermal/Collectors',
//   },
//   {
//     category: 'Mounting',
//     id: 15,
//     subCategories: [
//       { id: 64, subcategory: 'Canopys' },
//       { id: 65, subcategory: 'Facades' },
//       { id: 51, subcategory: 'Flat Roof' },
//       { id: 43, subcategory: 'In Roof' },
//       { id: 44, subcategory: 'On Roof' },
//       { id: 87, subcategory: 'Pipe Flashings' },
//     ],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/thermal/Mounting',
//   },
//   {
//     category: 'Accessories',
//     id: 16,
//     subCategories: [
//       { id: 66, subcategory: '.s' },
//       { id: 61, subcategory: 'Fittings' },
//       { id: 91, subcategory: 'Fixings' },
//       { id: 56, subcategory: 'Flow Meters' },
//       { id: 63, subcategory: 'Fluids' },
//       { id: 57, subcategory: 'Hoses' },
//       { id: 74, subcategory: 'Interconnectors' },
//       { id: 90, subcategory: 'Pool Exchangers' },
//       { id: 67, subcategory: 'Sensors' },
//       { id: 60, subcategory: 'Tools' },
//     ],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/thermal/Accessories',
//   },
//   {
//     category: 'Wind Other',
//     id: 17,
//     subCategories: [],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/wind/WindOther',
//   },
//   {
//     category: 'Delivery',
//     id: 18,
//     subCategories: [],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Delivery',
//   },
//   {
//     category: 'Training',
//     id: 19,
//     subCategories: [],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Training',
//   },
//   {
//     category: 'Wind Study',
//     id: 20,
//     subCategories: [],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/wind/WindStudy',
//   },
//   {
//     category: 'Wind Cable',
//     id: 21,
//     subCategories: [],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/wind/WindCable',
//   },
//   {
//     category: 'Display',
//     id: 22,
//     subCategories: [
//       { id: 213, subcategory: 'Accessories' },
//       { id: 209, subcategory: 'Bluetooth Repeaters' },
//       { id: 128, subcategory: 'Bulk Packs' },
//       { id: 22, subcategory: 'Communications' },
//       { id: 214, subcategory: 'Current Sensor Clamps' },
//       { id: 286, subcategory: 'Data Package' },
//       { id: 212, subcategory: 'Dataloggers' },
//       { id: 171, subcategory: 'Demonstrations' },
//       { id: 131, subcategory: 'Display Interfaces' },
//       { id: 266, subcategory: 'Energy Meter' },
//       { id: 215, subcategory: 'Firefighter Controllers' },
//       { id: 293, subcategory: 'Large Bulk Pack' },
//       { id: 403, subcategory: 'Meter Enclosure' },
//       { id: 76, subcategory: 'Meter Units' },
//       { id: 115, subcategory: 'Personal Displays' },
//       { id: 211, subcategory: 'Plant Communications' },
//       { id: 210, subcategory: 'Plant Controllers' },
//       { id: 217, subcategory: 'Power Management' },
//       { id: 116, subcategory: 'Public Displays' },
//       { id: 350, subcategory: 'Remote GSM Monitoring' },
//       { id: 287, subcategory: 'Single Phase Remote GSM Monitoring' },
//       { id: 117, subcategory: 'Three Phase Remote GSM Monitoring' },
//       { id: 23, subcategory: 'Web Interfaces' },
//       { id: 396, subcategory: 'WiFi Dongle' },
//     ],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Display',
//   },
//   {
//     category: 'Labels',
//     id: 23,
//     subCategories: [
//       { id: 344, subcategory: 'Bulk Pack' },
//       { id: 121, subcategory: 'Hazard Labels' },
//       { id: 345, subcategory: 'Large Bulk Pack' },
//       { id: 364, subcategory: 'Schematic' },
//     ],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Labels',
//   },
//   {
//     category: 'Special Offers',
//     id: 24,
//     subCategories: [
//       { id: 203, subcategory: 'Accessory Package' },
//       { id: 193, subcategory: 'Bulk Pack' },
//       { id: 200, subcategory: 'Commercial Packages' },
//       { id: 221, subcategory: 'Component Pack' },
//       { id: 238, subcategory: 'Daily Deal' },
//       { id: 242, subcategory: 'Distributor' },
//       { id: 197, subcategory: 'Domestic Packages' },
//       { id: 386, subcategory: 'EV Packages' },
//       { id: 220, subcategory: 'Mounting Kit Package' },
//       { id: 284, subcategory: 'New Build Packages' },
//       { id: 241, subcategory: 'Optimiser Package' },
//       { id: 239, subcategory: 'Commercial Project Pricing' },
//       { id: 329, subcategory: 'PV and Storage Package' },
//       { id: 227, subcategory: null },
//       { id: 198, subcategory: 'Special Bulk Pack' },
//       { id: 194, subcategory: 'Special Pack' },
//       { id: 199, subcategory: 'System' },
//       { id: 216, subcategory: 'Three Phase Power Management Packages' },
//       { id: 123, subcategory: 'Tools' },
//     ],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/SpecialOffers',
//   },
//   {
//     category: 'Heat Pumps',
//     id: 25,
//     subCategories: [
//       { id: 69, subcategory: 'Air Sources' },
//       { id: 154, subcategory: 'Bulk Packs' },
//       { id: 289, subcategory: 'Complete System' },
//       { id: 70, subcategory: 'Ground Sources' },
//       { id: 169, subcategory: 'Warranty Extensions' },
//     ],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/hp/HeatPumps',
//   },
//   {
//     category: 'Radiators',
//     id: 26,
//     subCategories: [{ id: 71, subcategory: 'Fan Convectors' }],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/hp/Radiators',
//   },
//   {
//     category: 'Accessories',
//     id: 27,
//     subCategories: [
//       { id: 148, subcategory: 'Accessories' },
//       { id: 184, subcategory: 'Bypass Values' },
//       { id: 165, subcategory: 'Connector Cables' },
//       { id: 187, subcategory: 'Deaerators' },
//       { id: 186, subcategory: 'Expansion Vessels' },
//       { id: 188, subcategory: 'Flexible Hosess' },
//       { id: 139, subcategory: 'Flow Meters' },
//       { id: 185, subcategory: 'Glycols' },
//       { id: 202, subcategory: 'Heat Meter' },
//       { id: 147, subcategory: 'Heat Pump Pre-Programmings' },
//       { id: 68, subcategory: 'Hot Water Cylinders' },
//       { id: 164, subcategory: 'Immersion Elements' },
//       { id: 141, subcategory: 'Isolation Valves' },
//       { id: 290, subcategory: 'Low Loss Header' },
//       { id: 166, subcategory: 'Manifolds' },
//       { id: 146, subcategory: 'MCS Calculation Services' },
//       { id: 132, subcategory: 'Motorised Valves' },
//       { id: 167, subcategory: 'Pumps' },
//       { id: 190, subcategory: 'System Cleaners' },
//       { id: 142, subcategory: 'Temperature Sensors' },
//       { id: 133, subcategory: 'Thermostats' },
//       { id: 180, subcategory: 'Water FIlters' },
//       { id: 140, subcategory: 'Water Pumps' },
//     ],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/hp/Accessories',
//   },
//   {
//     category: 'Connectors',
//     id: 28,
//     subCategories: [
//       { id: 83, subcategory: 'Array Connectors' },
//       { id: 84, subcategory: 'Other Connectors' },
//     ],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/thermal/Connectors',
//   },
//   {
//     category: 'Cylinders',
//     id: 29,
//     subCategories: [{ id: 52, subcategory: 'Hot Water Cylinders' }],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/thermal/Cylinders',
//   },
//   {
//     category: 'Cylinders',
//     id: 30,
//     subCategories: [
//       { id: 137, subcategory: 'With Buffers' },
//       { id: 138, subcategory: 'Without Buffers' },
//     ],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/hp/Cylinders',
//   },
//   {
//     category: 'Controllers',
//     id: 31,
//     subCategories: [
//       { id: 53, subcategory: 'Control Units' },
//       { id: 82, subcategory: 'Remote Display Units' },
//     ],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/thermal/Controllers',
//   },
//   {
//     category: 'Expansion Vessels',
//     id: 32,
//     subCategories: [
//       { id: 54, subcategory: 'Expansion Vessels' },
//       { id: 89, subcategory: 'Fixings' },
//       { id: 88, subcategory: 'Heating Expansion Vessels' },
//     ],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/thermal/ExpansionVessels',
//   },
//   {
//     category: 'Valves',
//     id: 33,
//     subCategories: [
//       { id: 86, subcategory: 'Motorised Valves' },
//       { id: 62, subcategory: 'Valves' },
//     ],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/thermal/Valves',
//   },
//   {
//     category: 'Thermal Pump Stations',
//     id: 34,
//     subCategories: [{ id: 55, subcategory: 'Pump Stations' }],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/thermal/ThermalPumpStations',
//   },
//   {
//     category: 'Training',
//     id: 35,
//     subCategories: [
//       { id: 150, subcategory: 'Domestic Heat Pumps' },
//       { id: 144, subcategory: 'Installation Trainings' },
//       { id: 156, subcategory: 'Introduction Courses' },
//       { id: 143, subcategory: 'MCS Calculation Trainings' },
//       { id: 157, subcategory: 'Regulation Awareness' },
//       { id: 151, subcategory: 'WPM Controlled Heat Pumps' },
//     ],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/hp/Training',
//   },
//   {
//     category: 'Environmental Sensors',
//     id: 36,
//     subCategories: [
//       { id: 120, subcategory: 'Weather Sensors' },
//       { id: 208, subcategory: 'Weather Sensor Controllers' },
//     ],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Environmental%20Sensors',
//   },
//   {
//     category: 'Accessories',
//     id: 37,
//     subCategories: [
//       { id: 352, subcategory: 'Bird Protection' },
//       { id: 183, subcategory: 'Bulk Packs' },
//       { id: 337, subcategory: 'Cable termination caps' },
//       { id: 233, subcategory: 'DC Battery Storage' },
//       { id: 237, subcategory: 'DC Connectors' },
//       { id: 173, subcategory: 'Demonstrations' },
//       { id: 248, subcategory: 'Garage Units' },
//       { id: 159, subcategory: 'Immersion Controllers' },
//       { id: 381, subcategory: 'Large Bulk Packs' },
//       { id: 232, subcategory: 'LED' },
//       { id: 336, subcategory: 'Phase Couplers' },
//       { id: 122, subcategory: 'Single Phase Voltage Optimisations' },
//       { id: 161, subcategory: 'Smart Plugs' },
//       { id: 201, subcategory: 'Special Bulk Pack' },
//       { id: 204, subcategory: 'Three Phase Voltage Optimisation' },
//     ],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Accessories',
//   },
//   {
//     category: 'Controllers',
//     id: 38,
//     subCategories: [
//       { id: 153, subcategory: 'Bulk Packs' },
//       { id: 162, subcategory: 'Control Boxes' },
//     ],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/hp/Controllers',
//   },
//   {
//     category: 'Buffer Tanks',
//     id: 39,
//     subCategories: [{ id: 149, subcategory: 'Buffer Tanks' }],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/hp/BufferTanks',
//   },
//   {
//     category: 'Training',
//     id: 40,
//     subCategories: [{ id: 152, subcategory: 'Solar Thermal Product Specs' }],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/thermal/Training',
//   },
//   {
//     category: 'Bespoke Orders',
//     id: 41,
//     subCategories: [{ id: 168, subcategory: 'Special Orders' }],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/BespokeOrders',
//   },
//   {
//     category: 'Protection',
//     id: 42,
//     subCategories: [
//       { id: 353, subcategory: 'AC Protection Box' },
//       { id: 389, subcategory: 'Battery Protection' },
//       { id: 319, subcategory: 'DC Protection Box' },
//       { id: 225, subcategory: 'DC Surge Protection' },
//       { id: 182, subcategory: 'Lightning Protections' },
//       { id: 291, subcategory: 'Network Protection' },
//       { id: 207, subcategory: 'Single Phase Surge Protection' },
//       { id: 407, subcategory: '3ph AC Protection Box' },
//       { id: 181, subcategory: 'Three Phase Surge Protections' },
//     ],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Protection',
//   },
//   {
//     category: 'Rebate',
//     id: 43,
//     subCategories: [],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Rebate',
//   },
//   {
//     category: 'Marketing Services',
//     id: 44,
//     subCategories: [
//       { id: 218, subcategory: 'Event' },
//       { id: 176, subcategory: 'Marketing Packs' },
//       { id: 219, subcategory: 'Paid for activity' },
//     ],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/MarketingServices',
//   },
//   {
//     category: 'Off-Grid',
//     id: 45,
//     subCategories: [
//       { id: 249, subcategory: 'Accessories' },
//       { id: 244, subcategory: 'Battery 6V - 12V' },
//       { id: 274, subcategory: 'Battery OPzS solar.power' },
//       { id: 273, subcategory: 'Battery OPzV solar.power' },
//       { id: 298, subcategory: 'Battery OPzV.Bloc' },
//       { id: 243, subcategory: 'Charger' },
//       { id: 245, subcategory: 'Off-Grid Wechselrichter' },
//       { id: 254, subcategory: 'Off-Grid PV Module' },
//       { id: 252, subcategory: 'Water Pumps' },
//     ],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/OffGrid',
//   },
//   {
//     category: 'Maintenance',
//     id: 46,
//     subCategories: [{ id: 253, subcategory: 'Maintenance Agreement' }],
//     portalUrl: null,
//   },
//   {
//     category: 'Storage Systems',
//     id: 47,
//     subCategories: [
//       { id: 295, subcategory: 'AC Battery Charger' },
//       { id: 313, subcategory: 'AC Cable' },
//       { id: 279, subcategory: 'AC Sensor' },
//       { id: 399, subcategory: 'AC Storage Kits' },
//       { id: 257, subcategory: 'Accessory' },
//       { id: 397, subcategory: 'Backup Only Kits' },
//       { id: 322, subcategory: 'Battery Base' },
//       { id: 320, subcategory: 'Battery Cabinet' },
//       { id: 281, subcategory: 'Battery Cable' },
//       { id: 300, subcategory: 'Battery Fuse Holder' },
//       { id: 297, subcategory: 'Battery Fuses' },
//       { id: 326, subcategory: 'Battery Hazard Labels' },
//       { id: 255, subcategory: 'Battery Inverter' },
//       { id: 366, subcategory: 'Battery Isolator' },
//       { id: 278, subcategory: 'Battery Monitor' },
//       { id: 301, subcategory: 'Battery Protect' },
//       { id: 324, subcategory: 'Battery Rack' },
//       { id: 316, subcategory: 'Battery Temperature Sensor' },
//       { id: 282, subcategory: 'Bulk Pack' },
//       { id: 309, subcategory: 'Cable Lug' },
//       { id: 307, subcategory: 'Charger-Inverter' },
//       { id: 382, subcategory: 'Charger-Inverter-3ph' },
//       { id: 280, subcategory: 'Communication Adaptor' },
//       { id: 276, subcategory: 'Communication Cable' },
//       { id: 277, subcategory: 'Communication Module' },
//       { id: 264, subcategory: 'DC String Battery Controller' },
//       { id: 263, subcategory: 'DC String Storage System' },
//       { id: 302, subcategory: 'DC-DC Converter' },
//       { id: 325, subcategory: 'Demonstration Unit' },
//       { id: 275, subcategory: 'Display' },
//       { id: 314, subcategory: 'Electrolyte' },
//       { id: 318, subcategory: 'Garage Unit' },
//       { id: 390, subcategory: 'Grid Backup Kits' },
//       { id: 271, subcategory: 'Grid Backup Packages' },
//       { id: 343, subcategory: 'Hybrid 3Phase Inverter' },
//       { id: 261, subcategory: 'Hybrid Inverter' },
//       { id: 311, subcategory: 'Hybrid Inverter - Off-Grid' },
//       { id: 398, subcategory: 'Hybrid Storage Kits' },
//       { id: 406, subcategory: 'Inverter Cabinet' },
//       { id: 283, subcategory: 'Large Bulk Pack' },
//       { id: 270, subcategory: 'Lead Acid Battery Pack' },
//       { id: 262, subcategory: 'Li-ion Battery Pack' },
//       { id: 265, subcategory: 'Li-ion Battery System' },
//       { id: 384, subcategory: 'Load Shedding Kit' },
//       { id: 334, subcategory: 'Maintenance' },
//       { id: 360, subcategory: 'Marketing' },
//       { id: 285, subcategory: 'MPPT Charge Controller' },
//       { id: 405, subcategory: 'Off-Grid Inverter' },
//       { id: 392, subcategory: 'Off-Grid Kits' },
//       { id: 306, subcategory: 'Offgrid Packages' },
//       { id: 393, subcategory: 'PV System Kits' },
//       { id: 402, subcategory: 'PV System Kits Commercial' },
//       { id: 401, subcategory: 'PV System Kits New Build' },
//       { id: 400, subcategory: 'PV System Kits Residential' },
//       { id: 303, subcategory: 'PWM Charge Controller' },
//       { id: 317, subcategory: 'Remote Control' },
//       { id: 272, subcategory: 'Self Consumption Packages' },
//       { id: 391, subcategory: 'Self-Consumption Kits' },
//       { id: 312, subcategory: 'Shunt' },
//       { id: 376, subcategory: 'Special Bulk Pack' },
//       { id: 288, subcategory: 'Battery Accessory' },
//       { id: 256, subcategory: 'Storage' },
//       { id: 383, subcategory: 'Storage Energy Meter' },
//       { id: 394, subcategory: 'UPS System Kits' },
//       { id: 321, subcategory: 'Wall Bracket' },
//       { id: 315, subcategory: 'Warranty Extension' },
//       { id: 333, subcategory: 'Wholegreen Storage Package' },
//     ],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/StorageSystems',
//   },
//   {
//     category: 'Installation',
//     id: 48,
//     subCategories: [{ id: 258, subcategory: 'Installation Service' }],
//     portalUrl: null,
//   },
//   {
//     category: 'Water heating',
//     id: 49,
//     subCategories: [{ id: 330, subcategory: 'Interface' }],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Water%20heating',
//   },
//   {
//     category: 'Training',
//     id: 50,
//     subCategories: [{ id: 296, subcategory: 'Battery Training' }],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Training',
//   },
//   {
//     category: 'Power Management',
//     id: 51,
//     subCategories: [
//       { id: 304, subcategory: 'Export Limitation 1ph' },
//       { id: 305, subcategory: 'Export Limitation 3ph' },
//     ],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Power%20Management',
//   },
//   {
//     category: 'Charging Stations',
//     id: 52,
//     subCategories: [],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/ChargingStation',
//   },
//   {
//     category: 'Delivery',
//     id: 53,
//     subCategories: [{ id: 354, subcategory: 'Delivery Error' }],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Delivery',
//   },
//   {
//     category: 'AC Components',
//     id: 54,
//     subCategories: [
//       { id: 358, subcategory: 'AC Accessories' },
//       { id: 355, subcategory: 'AC Cable' },
//       { id: 356, subcategory: 'Circuit Breakers' },
//       { id: 357, subcategory: 'DIN Enclosures' },
//       { id: 362, subcategory: 'Electrical Accessories' },
//       { id: 363, subcategory: 'Garage Unit' },
//     ],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/ACComponents',
//   },
//   {
//     category: 'Monitoring',
//     id: 55,
//     subCategories: [],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Monitoring',
//   },
//   {
//     category: 'EV Charging',
//     id: 56,
//     subCategories: [
//       { id: 377, subcategory: 'Access Control' },
//       { id: 378, subcategory: 'Book' },
//       { id: 369, subcategory: 'Bulk Packs' },
//       { id: 371, subcategory: 'Charge Point Connection Unit' },
//       { id: 380, subcategory: 'Complete Pallet Purchases' },
//       { id: 387, subcategory: 'Cover' },
//       { id: 342, subcategory: 'EV Charge Cable' },
//       { id: 379, subcategory: 'EV Charging Inverter' },
//       { id: 367, subcategory: 'EV Charging Package' },
//       { id: 374, subcategory: 'Large Bulk Packs' },
//       { id: 340, subcategory: 'EV Charger' },
//       { id: 370, subcategory: 'EV Monitoring and Control' },
//       { id: 385, subcategory: 'Mounting' },
//       { id: 388, subcategory: 'Signs' },
//       { id: 372, subcategory: 'Wall Dock' },
//       { id: 373, subcategory: 'Warranty Extension' },
//     ],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/EVCharging',
//   },
//   {
//     category: 'Generator Integration ',
//     id: 57,
//     subCategories: [{ id: 404, subcategory: 'Generator Integration ' }],
//     portalUrl: 'https://legacy-portal-uat-as.azurewebsites.net/nav/pv/Three%20Phase',
//   },
// ]

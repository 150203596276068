import { makeStyles } from '@material-ui/core'
import React from 'react'
import { useTranslate } from 'react-admin'
import type { PasswordResetLinksProps } from './PasswordResetLinks'
import PasswordResetLinks from './PasswordResetLinks'

const useStyles = makeStyles((theme: any) => ({
  card: {
    width: 308,
    minWidth: 210,
    padding: '0.5em 1em 1em 1em',
  },
  button: {
    color: theme.headerFontColor,
    background: theme.themeColor,
    '&:hover': {
      cursor: 'pointer',
      background: theme.themeColorDark,
    },
  },
}))

const PasswordResetRequested = ({ onCreateAccount, onSignIn }: PasswordResetLinksProps) => {
  const translate = useTranslate()
  const classes = useStyles()
  return (
    <div>
      <h1>{translate('Password Reset Email Sent')}</h1>
      <p>{translate('Click the link in the email to reset your password.')}</p>
      <PasswordResetLinks onCreateAccount={onCreateAccount} onSignIn={onSignIn} />
    </div>
  )
}
export default PasswordResetRequested

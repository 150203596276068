// in src/users.js
import { authSelectors } from 'ducks/auth'
import TextFieldWithSharedEntityIcon from 'elements/TextFieldWithSharedEntityIcon'
import EditOrImportButton from 'elements/button/EditOrImportButton'
import { useBulkActionButtons } from 'elements/hooks/useBulkActionButtons'
import { useEditShareable } from 'elements/react-admin/EditShareable'
import { List } from 'elements/react-admin/List'
import ListActions from 'elements/react-admin/ListActions'
import React from 'react'
import { Create, Datagrid, NotFound, TextField } from 'react-admin'
import { useSelector } from 'react-redux'
import ShareabilityFilters from 'resources/connectedOrgs/ShareabilityFilters'
import BatterySchemesForm from './BatterySchemesForm'

export const BatterySchemesList = ({
  accessRights: { allowView, allowCreate, allowEdit, allowDelete },
  ...props
}: any) => {
  const isAdmin = useSelector(authSelectors.getIsAdmin)
  const orgId = useSelector(authSelectors.getOrgId)
  const bulkActionButtons = useBulkActionButtons(props, allowEdit, allowDelete, undefined)

  if (!allowView) {
    return <NotFound />
  }

  return (
    <List
      hasSearch={true}
      actions={<ListActions hasArchived={true} />}
      perPage={20}
      {...props}
      hasCreate={allowCreate}
      filters={<ShareabilityFilters {...props} />}
      bulkActionButtons={bulkActionButtons}
    >
      <Datagrid>
        <TextFieldWithSharedEntityIcon source="name" label="Name" />
        <TextField source="description" label="Description" />
        <EditOrImportButton
          source="actions"
          label="Actions"
          org_id={orgId}
          resource="battery_schemes"
          allowDuplicate={false}
          sortable={false}
          isAdmin={isAdmin}
          allowEdit={allowEdit}
          allowDelete={allowDelete}
        />
      </Datagrid>
    </List>
  )
}

export const BatterySchemesEdit = (props: any) => {
  return useEditShareable({
    accessRights: props.accessRights,
    editProps: props,
    render: ({ access }) => <BatterySchemesForm disabled={!access.allowEdit} />,
  }).editPage
}

export const BatterySchemesCreate = (props: any) => {
  return (
    <Create {...props}>
      <BatterySchemesForm />
    </Create>
  )
}

import { memo } from 'react'
import Checkout from './Checkout'
import CheckoutProvider from './CheckoutProvider'

const SolarJuiceCheckoutPage = () => (
  <CheckoutProvider>
    <Checkout />
  </CheckoutProvider>
)

export default memo(SolarJuiceCheckoutPage)

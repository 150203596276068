import CustomField from 'elements/field/CustomField'
import { SelectInput, useTranslate } from 'react-admin'
import { RoundingPreferenceEnum } from 'types/orgs'

const RoundingPreferenceInput = () => {
  const translate = useTranslate()

  const CHOICES = [
    { id: null, name: 'Default (will not round)' },
    { id: RoundingPreferenceEnum.ROUND_TO_NEAREST, name: 'Round to nearest whole number' },
    { id: RoundingPreferenceEnum.ROUND_DOWN, name: 'Round down' },
    { id: RoundingPreferenceEnum.ROUND_UP, name: 'Round up' },
  ]
  return (
    <div>
      <h2>{translate('System Price Rounding Preference')}</h2>
      <div>
        {translate(
          'By Default, OpenSolar does not round system prices to the nearest whole number. If you would prefer to have system prices always be rounded you can choose your preferred rounding methodolgy below.'
        )}
      </div>
      <CustomField
        component={SelectInput}
        name="rounding_preference"
        source="rounding_preference"
        label={false}
        choices={CHOICES}
      />
    </div>
  )
}

export default RoundingPreferenceInput

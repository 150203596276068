import { orgSelectors } from 'ducks/orgs'
import { useNotify } from 'ra-core'
import { createContext, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import restClient from 'restClient'
import { delay } from 'util/delay'
import { BlueSnapOnboardingDocument, TermsAndConditionsAgreement } from './types'

const restClientInstance = restClient(window.API_ROOT + '/api')

export const fileToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = reject
  })

export const useGetCashFlowDocuments = (): [BlueSnapOnboardingDocument[], boolean, () => void] => {
  const notify = useNotify()
  const orgId = useSelector(orgSelectors.getOrg)?.id
  const [isLoading, setIsLoading] = useState(true)
  const [results, setResults] = useState<BlueSnapOnboardingDocument[]>([])

  const fetchDocuments = useCallback(
    async (ms: number | undefined = undefined) => {
      setIsLoading(true)

      if (ms) await delay(ms) // when refreshing documents for newly uploaded documents, we'll give a few seconds for PSP to process and update statuses

      restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${orgId}/cashflow_documents/`,
      })
        .then((res) => {
          if (res?.data?.documentation) {
            setResults(res.data.documentation)
          } else {
            notify(res.data)
          }
        })
        .catch((err) => {
          const errorMessage = err?.body?.detail || err
          notify(`Failed to get documents: ${errorMessage}`, 'warning')
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [orgId]
  )

  useEffect(() => {
    if (orgId) {
      fetchDocuments()
    }
  }, [orgId])

  return [results, isLoading, fetchDocuments]
}

export const useGetSignedTermsAndConditions = (): [TermsAndConditionsAgreement[], boolean] => {
  const notify = useNotify()
  const orgId = useSelector(orgSelectors.getOrg)?.id
  const [isLoading, setIsLoading] = useState(true)
  const [results, setResults] = useState<TermsAndConditionsAgreement[]>([])

  const fetchSignedTermsAndConditions = useCallback(() => {
    setIsLoading(true)

    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${orgId}/cashflow_documents/terms_and_conditions/`,
    })
      .then((res) => {
        setResults(res.data.signed_agreements)
      })
      .catch((err) => {
        const errorMessage = err?.body?.detail || err
        notify(`Failed to fetch terms and conditions agreements: ${errorMessage}`, 'warning')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [orgId])

  useEffect(() => {
    if (orgId) {
      fetchSignedTermsAndConditions()
    }
  }, [orgId])

  return [results, isLoading]
}

export const generateUploadDocumentUrl = (orgId, document: BlueSnapOnboardingDocument) => {
  const payload = {
    document: {
      docType: document.docType,
      docUnderwriterNotes: document.docUnderwriterNotes,
      status: document.status,
      docGenericDescription: document.docGenericDescription,
      docsReceived: document.docsReceived,
      docLimit: document.docLimit,
    }
  }
  return restClientInstance('CUSTOM_POST', 'custom', {
    url: `orgs/${orgId}/cashflow_documents/qr_code/`,
    data: payload,
  })
}

export const uploadCashFlowDocuments = (orgId, requestBody) => {
  return restClientInstance('CUSTOM_POST', 'custom', {
    url: `orgs/${orgId}/cashflow_documents/`,
    data: requestBody,
  })
}

type DocumentPageProps = {
  refreshDocuments: (ms: number | undefined) => void
}

export const DocumentPageContext = createContext<DocumentPageProps>({
  refreshDocuments: () => {},
})

import { makeStyles } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIosOutlined'
import React from 'react'
import { useTranslate } from 'react-admin'
import { getRoleId } from 'util/misc'

const useStyles = makeStyles((theme) => ({
  header: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
  },
  headerLeft: {
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.54)',
    verticalAlign: 'middle',
    fontWeight: 500,
  },
  headerRight: {
    fontSize: 14,
    textDecoration: 'none',
  },
  viewAll: {
    verticalAlign: 'middle',
  },
  viewAllIcon: {
    width: 18,
    height: 18,
    verticalAlign: 'middle',
  },
}))

export const UpComingActivitySubHeader = () => {
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <div className={classes.header} style={{ margin: '10px 16px' }}>
      <span className={classes.headerLeft}>{translate('My Upcoming Activities')}</span>
      <a
        className={classes.headerRight}
        style={{ color: 'rgba(24, 145, 255, 1)' }}
        href={`#/events?filter=%7B%22team_members%22%3A${getRoleId()}%2C%22task_status%22%3A1%7D&order=DESC&page=1&perPage=20&sort=modified_date`}
      >
        <span className={classes.viewAll}>{translate('View All')}</span>{' '}
        <ArrowForwardIosIcon className={classes.viewAllIcon} />
      </a>
    </div>
  )
}

export const CustomerActivitySubHeader = () => {
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <div className={classes.header} style={{ margin: '10px 16px' }}>
      <span className={classes.headerLeft}>{translate('Customer Activities')}</span>
    </div>
  )
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { DialogContent as Mui4DialogContent } from '@material-ui/core';
import { default as Mui5DialogContent } from '@mui/material/DialogContent';
import { forwardRef } from 'react';
import { Inspector } from '../debug/Inspector';
import { useComponentVersion } from '../hooks';
import { OsDialogVersion } from './Dialog';
export var DialogContent = function (props) {
    return (_jsx(Inspector, { name: "DialogContent", propDefs: DialogContentProps, showExample: true, component: OsDialogContent, props: props }));
};
var OsDialogContent = forwardRef(function (props, ref) {
    if (ref === void 0) { ref = null; }
    var version = useComponentVersion('dialog');
    switch (version) {
        case OsDialogVersion.V2:
            return _jsx(Mui5DialogContent, __assign({ ref: ref }, props));
        default:
            return _jsx(Mui4DialogContent, __assign({ ref: ref }, props));
    }
});
var DialogContentProps = {};

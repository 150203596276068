import { authSelectors } from 'ducks/auth'
import { rolesSelectors } from 'ducks/auth_roles'
import { permissionsSelectors } from 'ducks/permissions'
import { viewModeSelectors } from 'ducks/viewMode'
import PageDashboard from 'pages/dashboard/PageDashboard'
import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { RootState } from 'types/state'

export const DashboardRedirect = () => {
  const roleId = useSelector(rolesSelectors.getCurrentRoleId)
  const orgId = useSelector(authSelectors.getOrgId)
  const hasAssessRight = useSelector((state: RootState) => permissionsSelectors.hasAssessRight(state))
  const hasBusinessInfoAccess = hasAssessRight('business_info')
  const hasTeamAccess = hasAssessRight('team')
  const isOsLite = useSelector(viewModeSelectors.isUserLite)

  if (isOsLite) {
    if (!hasBusinessInfoAccess && !hasTeamAccess) return <Redirect to={`/myroles/${roleId}`} />
    else return <Redirect to={`/content/${orgId}`} />
  } else return <PageDashboard />
}

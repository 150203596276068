import { HelpLink } from 'elements/link/HelpLink'
import { useTranslate } from 'react-admin'
import { ProjectConfigurationCreate, ProjectConfigurationEdit, ProjectConfigurationList } from './ProjectConfigurations'

const ProjectConfigurationTitle = ({ record, translate, style }) => {
  return (
    <span style={style}>
      {translate('Setbacks & Design Settings')} {record?.title || ''}
    </span>
  )
}

const Subtitle = () => {
  const translate = useTranslate()
  return (
    <span>
      {translate(
        'Enter setback parameters and design settings here. You can create multiple sets of setbacks and design settings to cover different jurisdictions and/or project types (e.g. commercial vs. residential). You can enable one set as a default and select an alternative set for a given project under Site Details on the Info page in the Projects zone'
      )}
      .
      <HelpLink href="https://support.opensolar.com/hc/en-us/articles/4407235645465" />
    </span>
  )
}
export default {
  list: ProjectConfigurationList,
  edit: ProjectConfigurationEdit,
  create: ProjectConfigurationCreate,
  options: {
    create: {
      title: 'Create Setbacks & Design Setting',
      subtitle: null,
      breadCrumb: 'Create Setting',
    },
    list: {
      title: 'My Setbacks & Design Settings',
      subtitle: <Subtitle />,
      breadCrumb: 'Setbacks & Design Settings',
    },
    edit: {
      title: <ProjectConfigurationTitle />,
      subtitle: null,
      breadCrumb: 'Edit Setting',
    },
  },
}

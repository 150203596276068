//TODO: move this file to replace existing projectsections

import { Tooltip, makeStyles, withStyles } from '@material-ui/core'
import { Theme } from 'types/themes'

export const COLOR_PALETTE = {
  bgColor: '#F6F9FC',
  blue: '#F5F8FD',
  blue2: '#074BD8',
  darkBlue: '#113B98',
  darkBlue2: '#1751D0',
  red: '#FFE3E3',
  red2: '#E12121',
  darkRed: '#961616',
  lightGrey: '#ECECED',
  lightGrey2: '#FBFAFC',
  lightGrey3: '#FAFBFC',
  lightGrey4: '#B4B4B4',
  grey2: '#F8F8F8',
  darkGrey: '#4F4D55',
  iconColor: '#7F7D83',
  yellow: '#FFF6E7',
  darkYellow: '#C47E0A',
  teal: '#DFF1F7',
  darkTeal: '#005D7A',
  green: '#E1FAEA',
  darkGreen: '#016626',
  black: '#0A090B',
  green2: '#00D855',
}

export const getSeverityStyles = makeStyles({
  info: {
    background: COLOR_PALETTE.blue,
    color: COLOR_PALETTE.darkBlue,
    border: '1px solid #EBF0FB',
  },
  warning: {
    background: COLOR_PALETTE.yellow,
    color: COLOR_PALETTE.darkYellow,
    border: '1px solid #FFE9BA',
  },
  error: {
    background: COLOR_PALETTE.red,
    color: COLOR_PALETTE.darkRed,
  },
  success: {
    background: COLOR_PALETTE.green,
    color: COLOR_PALETTE.darkGreen,
  },
})

export const getProjectSectionStyles = makeStyles((theme: Theme) => ({
  contentBg: {
    //temporary until all content is updated
    backgroundColor: COLOR_PALETTE.bgColor,
  },
  sectionWrapper: {
    padding: 40,
  },
  sectionCardTop: {
    backgroundColor: theme.white,
    padding: 20,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottom: '1px solid ' + COLOR_PALETTE.lightGrey,
  },
  sectionCardBottom: {
    backgroundColor: theme.white,
    padding: 20,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  detailChip: {
    background: theme.white,
    padding: '0px 8px',
    border: '1px solid ' + theme.greyMid2,
    borderRadius: 24,
    marginRight: 5,
    '& .MuiChip-label': {
      padding: 0,
    },
    '& .MuiSvgIcon-root': {
      height: 16,
    },
  },
  inputStyle: {
    margin: '20px 0',
    marginTop: 5,
    width: '100%',
    '& .MuiInputBase-root': {
      fontSize: 14,
      backgroundColor: theme.white,
      height: 40,
      '& .MuiSvgIcon-root': {
        fill: COLOR_PALETTE.iconColor,
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 8,
      border: '1px solid ' + theme.greyMid2,
      boxShadow: '0px 1.5px 4px -1px rgba(10, 9, 11, 0.07);',
    },
  },
  dialogHeader: {
    padding: '16px 20px',
    '& h2': {
      fontSize: 18,
      fontWeight: 700,
    },
  },
  dialogFooter: {
    borderTop: '1px solid ' + COLOR_PALETTE.lightGrey,
    padding: '16px 20px',
  },
  booleanRestyle: {
    paddingRight: 10,
    '& .MuiSwitch-root': {
      width: 36,
      height: 23,
      padding: '2px 0',
    },
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 3,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(15px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.openSolarYellow,
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 16,
      height: 16,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1,
    },
  },
}))

export const PopoverTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#FFF',
    padding: 0,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 400,
    margin: 0,
  },
}))(Tooltip)

export const IconButtonTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: COLOR_PALETTE.black,
    color: '#FFF',
    borderRadius: 8,
    padding: '8px 10px',
    fontWeight: 400,
  },
}))(Tooltip)

import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography, withStyles } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { orgSelectors } from 'ducks/orgs'
import Button from 'elements/button/Button'
import { getSupplierConfig, HardwareSupplierPresetConfig } from 'pages/ordering/configs'
import { CartDiscount, getCartDiscount, getCartDiscountValue } from 'pages/ordering/order/CartDiscounts'
import LineItemType from 'pages/ordering/OrderLineItem'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import { getOrderCost } from 'pages/ordering/utils'
import { useNotify, useTranslate } from 'ra-core'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { currencySymbolForCountry, formatCurrencyWithSymbol } from 'util/misc'
import DistributorBreakdown from './DistributorBreakdown'

export type DistributorBreakdownProps = {
  supplier: HardwareSupplierPresetConfig | undefined
  totalItemQty: number
  cost: number
  costWithDiscounts: number
  tradeDiscountValue: number
  cartDiscount: CartDiscount
  overallTotal: number
}

const CenteredAccordionSummary = withStyles({
  content: {
    flexGrow: 0,
  },
})(AccordionSummary)

const useStyles = makeOpenSolarStyles((theme) => ({
  accordionSummary: {
    color: '#1751D0',
  },
  grandTotalContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px',
    alignItems: 'center',
  },
  breakdown: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  info: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 550,
    lineHeight: '20px',
    marginTop: 16,
  },
}))

const BomDrawerFooter = ({ lineItems: orderableLineItems }: { lineItems: LineItemType[] }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const notify = useNotify()
  const translate = useTranslate()
  const history = useHistory()
  const projectId = useSelector((state: any) => state.projectId)
  const enabledSuppliers = useSelector(orgSelectors.getEnabledHardwareSuppliers)
  const currencySymbol = useSelector((state: any) => {
    return currencySymbolForCountry(orgSelectors.getOrgIso2(state))
  })

  const [cartDiscount, setCartDiscount] = useState<CartDiscount>(null)
  const [grandTotal, setGrandTotal] = useState<number>(0)
  const [distributorBreakdown, setDistributorBreakdown] = useState<DistributorBreakdownProps[]>([])

  useEffect(() => {
    const segenConfig = getSupplierConfig(HardwareSupplierEnum.Segen)
    const segenItems = orderableLineItems.filter((item) => item.selectedDistributor === segenConfig?.filterKey)

    if (segenItems.length !== orderableLineItems.length) return

    getCartDiscount(
      orderableLineItems,
      segenConfig?.type,
      (cartDiscount) => {
        setCartDiscount(cartDiscount)
      },
      'project'
    )
  }, [enabledSuppliers, orderableLineItems])

  useEffect(() => {
    let total = 0
    const breakdownDetails: DistributorBreakdownProps[] = []

    enabledSuppliers.forEach((supplier) => {
      const supplierConfig = getSupplierConfig(supplier)
      const itemsBySupplier = orderableLineItems.filter(
        (item) => item.selectedDistributor === supplierConfig?.filterKey
      )

      if (itemsBySupplier.length === 0) return

      const cost = getOrderCost(itemsBySupplier, false)
      const costWithDiscounts = getOrderCost(itemsBySupplier, true)
      const tradeDiscountValue = cost - costWithDiscounts
      const cartDiscountValue = getCartDiscountValue(cost, cartDiscount)
      const overallTotal = cartDiscountValue ? costWithDiscounts - cartDiscountValue : costWithDiscounts
      total += overallTotal

      breakdownDetails.push({
        supplier: supplierConfig,
        totalItemQty: itemsBySupplier.reduce((acc, item) => acc + item.quantity, 0),
        cost,
        costWithDiscounts,
        tradeDiscountValue,
        cartDiscount,
        overallTotal,
      })
    })

    total += total * 0.2 // VAT
    setGrandTotal(total)
    setDistributorBreakdown(breakdownDetails)
  }, [orderableLineItems, cartDiscount])

  const handleAddToCart = useCallback(() => {
    history.push(`/projects/${projectId}/hardware`)
  }, [orderableLineItems])

  return (
    <>
      <Accordion>
        <CenteredAccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon className={classes.accordionSummary} />}
        >
          <Typography>{translate('Price Details')}</Typography>
        </CenteredAccordionSummary>
        <AccordionDetails>
          <Grid container direction="row">
            {distributorBreakdown.map((breakdown) => (
              <DistributorBreakdown {...breakdown} />
            ))}
            <Grid container direction="row" justify="space-between">
              <Typography className={classes.info}>{`Total VAT (20%)`}</Typography>
              <Typography className={classes.info}>{`${formatCurrencyWithSymbol(
                grandTotal * 0.2,
                currencySymbol
              )}`}</Typography>
            </Grid>
            <Grid container>
              <Typography className={classes.info}>{`Shipping options will be available during checkout`}</Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Grid container direction="row" className={classes.grandTotalContainer}>
        <Grid direction="column">
          <Grid>
            <Typography variant="subtitle2">{`Grand Total:`}</Typography>
          </Grid>
          <Grid>
            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
              {formatCurrencyWithSymbol(grandTotal, currencySymbol)}
            </Typography>
          </Grid>
        </Grid>
        <Button color="primary" disabled={orderableLineItems?.length === 0} onClick={handleAddToCart}>
          Add to cart
        </Button>
      </Grid>
    </>
  )
}

export default BomDrawerFooter

import { makeStyles, TextField } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'util/Debounce'
import { formatCurrency } from 'util/misc'
import { IronRidgeBOMItemType } from '../../../types/integrations/ironRidge'

const useStyles = makeStyles({
  bomProductRow: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #ececec',
  },
  bomProductTitleView: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  bomProductFieldsView: {
    flex: 3,
    display: 'flex',
    flexDirection: 'column',
  },
  fieldValuesRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
  },
  bomOtherCol: {
    flex: 1,
    textAlign: 'right',
    opacity: 1.0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bomOtherColInput: {
    flex: 1,
    textAlign: 'right',
    opacity: 1.0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: '1px dotted gray',
  },
  bomTotalCostCol: {
    flex: 2,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bomProductNumber: {
    fontSize: '18px',
  },
  input: {
    backgroundColor: '#fff',
    maxWidth: '70%',
    borderRadius: 5,
    textAlign: 'center',
    borderColor: '#ffda00',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    boxShadow: '2px 2px #ececec',
  },
})

type PropTypes = {
  item: IronRidgeBOMItemType
  categoryName: string
  isFirstBOMForSystem: boolean
  existingIRComponents: any[]
  orgComponents: any[]
  integrationConfig: any
  onFieldChange: Function
  i: number
  bomToSave: any
  setIsCalculatingTotal: (val: boolean) => void
}

const IronRidgeBomRow: React.FunctionComponent<PropTypes> = (props) => {
  const getInitialUnitPrice = () => {
    const total = parseFloat(props.item.totalPrice)
    const qty = props.item.quantity
    if (!isNaN(total) && !isNaN(qty) && qty > 0) {
      return total / qty
    } else return 0
  }

  const getMSRPPerUnit = () => {
    if (!props.item.msrp || !props.item.quantity) return '0'
    else if (typeof props.item.msrp === 'string') {
      return (parseFloat(props.item.msrp) / props.item.quantity).toFixed(2)
    } else {
      return (props.item.msrp / props.item.quantity).toFixed(2)
    }
  }

  const getNewCOGs = useCallback(() => {
    if (props.item.cogs !== undefined) return formatCurrency(props.item.cogs)
    if (!props.item.savedPrice) {
      return props.item.cogs !== undefined
        ? formatCurrency(props.item.cogs)
        : formatCurrency(parseFloat(props.item.totalPrice) / props.item.quantity)
    } else {
      return formatCurrency(props.item.savedPrice)
    }
  }, [props.item?.savedPrice, props.item?.cogs, props.item?.totalPrice, props.item?.quantity])

  const classes = useStyles()
  const [isCalculating, setIsCalculating] = useState<boolean>(false)
  const [initialUnitPrice, setInitialUnitPrice] = useState<number>(getInitialUnitPrice())
  const [MSRPPerUnit, setMSRPPerUnit] = useState<string>(getMSRPPerUnit())

  useEffect(() => {
    setInitialUnitPrice(getInitialUnitPrice())
  }, [props.item])

  useEffect(() => {
    setMSRPPerUnit(getMSRPPerUnit())
  }, [])

  const getMatchingExistingComponent = (bomLineItem: IronRidgeBOMItemType) => {
    if (!props.existingIRComponents || !bomLineItem) return null
    return props.existingIRComponents.find((existing: any) => existing.title === bomLineItem.partNumber)
  }

  const doDebouncedOnFieldChange = useDebouncedCallback((...args) => {
    props.onFieldChange(...args)
    setIsCalculating(false)
    props.setIsCalculatingTotal(false)
  }, 300)

  const debouncedOnFieldChange = (...args) => {
    setIsCalculating(true)
    props.setIsCalculatingTotal(true)
    doDebouncedOnFieldChange(...args)
  }

  const getExistingCogs = (bomLineItem: IronRidgeBOMItemType): string | null => {
    if (!props.orgComponents || !bomLineItem) return null
    // first get the actual component on the org so we have the record id
    const componentsOnOrg = props.orgComponents.filter((existing: any) => existing.title === bomLineItem.partNumber)
    let componentOnOrg = componentsOnOrg?.length === 1 ? componentsOnOrg[0] : undefined
    return componentOnOrg?.cost !== undefined && componentOnOrg.cost > 0 ? componentOnOrg.cost : 'None'
  }

  const getTotalPriceForItem = (category: string, item: any) => {
    const editedPart = props.bomToSave?.categories[category]?.lineItems?.find(
      (editedItem: any) => editedItem.partId === item.partId
    )
    if (editedPart && editedPart.cogs >= 0 && editedPart.quantity >= 0) {
      return `$${formatCurrency(editedPart.cogs * editedPart.quantity)}`
    } else {
      return `$${formatCurrency(initialUnitPrice * item.quantity)}`
    }
  }

  let existingComponent = getMatchingExistingComponent(props.item)
  let existingCOGs = getExistingCogs(props.item)
  let newCOGs = getNewCOGs()

  if (props.item.partNumber === 'XR-100-204B') {
    console.log('existingCOGs', formatCurrency(existingCOGs))
    console.log('newCOGs', newCOGs)
  }

  let isDifferentCOGS =
    existingCOGs !== 'None' && formatCurrency(existingCOGs) !== newCOGs && !props.isFirstBOMForSystem
  let isDifferentQuantity =
    props.item.quantity !== existingComponent?.quantity && !props.isFirstBOMForSystem && existingComponent?.quantity
  existingCOGs = existingCOGs && existingCOGs !== 'None' ? `$${formatCurrency(existingCOGs)}` : 'N/A'
  return (
    <div className={classes.bomProductRow}>
      <div className={classes.bomProductTitleView}>
        <span className={classes.bomProductNumber}>{props.item.partNumber}</span>
        <span>{props.item.partDescription}</span>
      </div>
      <div className={classes.bomProductFieldsView}>
        <div className={classes.fieldValuesRow}>
          <div className={classes.bomOtherCol} style={{ borderLeft: '1px dotted gray' }}>
            {existingComponent ? existingComponent.quantity : 'N/A'}
          </div>
          <div className={classes.bomOtherColInput}>
            <TextField
              defaultValue={props.item.quantity}
              onChange={(e) => debouncedOnFieldChange(e.target.value, props.i, 'quantity', props.categoryName)}
              fullWidth={false}
              className={classes.input}
              inputProps={{
                style: {
                  marginBottom: '2px',
                  textAlign: 'center',
                  backgroundColor: isDifferentQuantity ? '#ffda00' : undefined,
                },
              }}
              type="number"
            />
          </div>
          <div className={classes.bomOtherCol}>{MSRPPerUnit}</div>
          <div className={classes.bomOtherColInput}>
            <span style={{ marginRight: '5px' }}>$</span>
            <TextField
              defaultValue={newCOGs}
              onChange={(e) => debouncedOnFieldChange(e.target.value, props.i, 'cogs', props.categoryName)}
              fullWidth={false}
              className={classes.input}
              inputProps={{
                style: {
                  marginBottom: '2px',
                  textAlign: 'center',
                  backgroundColor: isDifferentCOGS ? '#ffda00' : undefined,
                },
              }}
              type="number"
            />
          </div>
          <div
            className={classes.bomTotalCostCol}
            style={{ justifyContent: 'flex-end', opacity: isCalculating ? 0.5 : 1.0 }}
          >
            {getTotalPriceForItem(props.categoryName, props.item)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default IronRidgeBomRow

import { AppBar, makeStyles } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import { UiSwitch } from 'elements/UiSwitch'
import SocketStatusIcon from 'elements/websocket/SocketStatusIcon'
import useLayout from 'layout/useLayout'
import SupplierWidget from 'pages/ordering/subheader/SupplierWidget'
import { useSelector } from 'react-redux'
import { useFeatureFlag } from 'util/split'
import { Actions, ActionsMobile } from './actions/index'
import { Navigation } from './Navigation'
import ProjectSummary from './Summary'

const useStyles = makeStyles({
  appBar: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'rgb(255,255,255)',
    color: '#000000',
    top: 0,
    height: 50,
    padding: '0 20px',
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%)',
    borderTop: '1px solid #0000001a',
  },
})

const ProjectSubHeader = (props) => {
  const layout = useLayout()
  const classes = useStyles()
  const enabledDistributors = useSelector(orgSelectors.getEnabledHardwareSuppliers)
  const multiDistributor = useFeatureFlag('design_multi_distributor', 'on')

  return (
    <UiSwitch uiKey="forms.project.header">
      <AppBar position="sticky" className={classes.appBar}>
        <SocketStatusIcon />
        <ProjectSummary layout={layout} />
        {layout !== 1 && <Navigation layout={layout} />}
        {enabledDistributors.length > 1 && !multiDistributor && <SupplierWidget mode="text" />}
        {layout >= 3 ? (
          <Actions layout={layout} submitting={props.submitting} />
        ) : (
          <ActionsMobile layout={layout} submitting={props.submitting} />
        )}
      </AppBar>
    </UiSwitch>
  )
}

export default ProjectSubHeader

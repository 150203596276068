import { restartDesignMode } from './restartDesignMode'

export async function handleRestart() {
  if (window.confirm(window.translate('Any unsaved data will be lost. Are you sure?'))) {
    window.editor.clear()
    window.SceneHelper.addAnchorAndLights(window.editor)
    window.SceneHelper.createFirstSystem()
    restartDesignMode()
  }
}

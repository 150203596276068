import Typography from '@material-ui/core/Typography'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { AVAILABILITY_FILTER_KEY } from 'elements/hardwareFilter/stockAvailability/AvailabilityFilterNodeFactory'
import HardwareCatalog from 'pages/ordering/catalog/HardwareCatalog'
import useControlledCollection from 'pages/ordering/hooks/useControlledCollection'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import type { ComponentTypes } from 'types/selectComponent'

const useStyles = makeOpenSolarStyles((theme) => ({
  container: {
    padding: 20,
    maxWidth: 1500,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'baseline',
  },
  viewMore: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#1751D0',
    marginLeft: 20,
  },
}))

const CollectionBlock = ({
  title,
  trackSource,
  componentType,
  viewMoreLink,
}: {
  title: string
  trackSource: string
  viewMoreLink: string
  componentType: ComponentTypes
}) => {
  const classes = useStyles()
  const history = useHistory()

  const { productData, loading, total = 0 } = useControlledCollection({
    showExhibitedFirst: true,
    catalog: 'full',
    componentType,
    limit: 5,
    defaultFilterValues: {
      [AVAILABILITY_FILTER_KEY]: 'true',
    },
  })
  const handleClick = useCallback(() => {
    history.push(viewMoreLink)
    logAmplitudeEvent('hardware_collection_interacted', {
      context: 'view_more_button',
      source: trackSource,
      action: 'clicked',
      type: componentType,
    })
  }, [viewMoreLink])

  const onNext = useCallback((encodedCode: string) => {
    history.push({
      pathname: `/shop/product/${encodedCode}`,
      state: { from: `${history.location.pathname}${history.location.search}` },
    })
  }, [])

  return (
    <div className={classes.container}>
      <div className={classes.titleWrapper}>
        <Typography variant="h6">{title}</Typography>
        <span className={classes.viewMore} onClick={handleClick}>
          View all
        </span>
      </div>
      <HardwareCatalog
        trackSource={trackSource}
        justify="flex-start"
        data={productData}
        onNext={onNext}
        loading={loading}
        total={total}
      />
    </div>
  )
}

export default CollectionBlock

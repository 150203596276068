import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { exhibitorsSelectors } from 'ducks/exhibitors'
import { featureConfigSelectors, getPublicFeatureConfig } from 'ducks/featureConfig'
import { orgSelectors } from 'ducks/orgs'
import { useLoadExhibitors } from 'hooks/useLoadExhibitors'
import { getSupplierConfig } from 'pages/ordering/configs'
import React, { ChangeEvent, FC, useEffect } from 'react'
import { useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { ProjectShare } from 'types/projects'
import { FilterOption, FilterType } from 'types/selectComponent'
import { Theme } from 'types/themes'
import { HardwareSelectionPropsType } from '../HardwareSelectionDialog'

const useStyles = makeStyles(
  (theme: Theme) => ({
    filter: {
      border: theme.border.default,
      borderRadius: '4px',
      margin: '2px',
      flexFlow: 'row',
      columnGap: '4px',
      cursor: 'pointer',
    },
    filterLabel: {
      paddingLeft: '5px',
      paddingBottom: '2px',
      transform: 'none',
      margin: 'auto',
      fontSize: '14px',
      color: 'inherit',
      position: 'inherit',
      cursor: 'pointer',
    },
    filterSelect: {
      marginTop: '0!important',
      color: theme.secondaryColor,
      width: 'fit-content',
      maxWidth: '150px',
      '&::before': {
        content: 'unset',
      },
      '&::after': {
        content: 'unset',
      },
    },
    filterItem: {
      textAlign: 'right',
      width: '100%',
      display: 'block',
    },
  }),
  { name: 'HardwareComponentFilter' }
)

type FilterPropsType = {
  schema: FilterType
  setFilters: Function
  exhibitors: {}
  defaultValue: string
  trackSource: string
  sharedWith: ProjectShare[]
}

const exhibitorsToOptions = (exhibitors, componentTypeId) => {
  return exhibitors
    .filter((exhibitor) => exhibitor.component_types.includes(componentTypeId))
    .map((exhibitor) => ({ title: exhibitor.name, value: exhibitor.id }))
}

const onlyEnabledSuppliers = (enabledSuppliers) => {
  const realOptions: FilterOption[] = []
  enabledSuppliers.forEach((s) => {
    const supplierConfig = getSupplierConfig(s)
    realOptions.push({ title: supplierConfig?.name || '', value: (supplierConfig?.filterKey || '') as string })
  })
  return realOptions
}

const FilterSelect: FC<FilterPropsType & HardwareSelectionPropsType> = ({
  trackSource,
  schema,
  defaultValue,
  setFilters,
  exhibitors,
  sharedWith,
  projectOwnerId,
}) => {
  const { label, options, useExhibitorsAsOptionsWithComponentType, useFeatureConfigAsOptions } = schema
  const classes = useStyles()
  const translate = useTranslate()
  const org = useSelector(orgSelectors.getOrg)
  const orgIso2 = useSelector(orgSelectors.getOrgIso2)
  const enabledSuppliers = useSelector(orgSelectors.getEnabledHardwareSuppliers)
  const connectedOrgs = org?.connected_orgs || []

  if (typeof useFeatureConfigAsOptions !== 'undefined') {
    const featureConfig = featureConfigSelectors.getFeatureConfigData(
      window.reduxStore.getState(),
      useFeatureConfigAsOptions.featureConfig
    )
    if (featureConfig)
      var featureConfigOptions: FilterOption[] | undefined = featureConfig[useFeatureConfigAsOptions.key].reduce(
        (options, option) => {
          if (option.include_country && !option.include_country.includes(orgIso2)) {
            return options
          }
          options.push({ title: option.title, value: option.value })
          return options
        },
        []
      )
  }
  var optionsToUse =
    typeof useFeatureConfigAsOptions !== 'undefined' && featureConfigOptions
      ? featureConfigOptions
      : typeof useExhibitorsAsOptionsWithComponentType !== 'undefined'
      ? exhibitorsToOptions(exhibitors, useExhibitorsAsOptionsWithComponentType)
      : schema.key === 'require_distributor'
      ? onlyEnabledSuppliers(enabledSuppliers)
      : options

  useEffect(() => {
    if (schema.key === 'owner_org') {
      if (sharedWith && sharedWith?.length > 0) {
        for (const i in sharedWith) {
          if (!optionsToUse.find((x) => x.value === sharedWith[i].org_id)) {
            optionsToUse.push({ title: sharedWith[i].org_name, value: sharedWith[i].org_id })
          }
        }
      }
      if (!optionsToUse.find((x) => x.value === projectOwnerId)) {
        if (org?.id !== projectOwnerId) {
          const findOwner = connectedOrgs?.find((x) => x.org_id === projectOwnerId)
          optionsToUse.unshift({ title: findOwner?.org_name, value: findOwner?.org_id })
        } else if (org) {
          optionsToUse.unshift({ title: org?.name, value: org?.id })
        }
      }
    }
  }, [schema, enabledSuppliers])

  const [open, setOpen] = React.useState(false)
  const clickFunc = () => {
    setOpen(!open)
  }
  const [filterValue, setFilterValue] = React.useState('all')

  useEffect(() => {
    setFilterValue(defaultValue)
  }, [defaultValue])

  const handleChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    const value = event.target.value as string
    logAmplitudeEvent('hardware_select_filters', {
      action: 'selected',
      context: 'select_item',
      source: trackSource,
      key: schema.key,
      value,
    })
    setFilterValue(value)
    if (schema.key) {
      let filters = {}
      // TODO: we shouldn't create new filter object when handle change
      // It's a little bit weird to have 'all' as the default value
      filters[schema.key] = value !== 'all' ? value : undefined
      setFilters(filters)
    } else {
      console.error(`${schema.label} filter doesn't have a corresponding key so cannot be executed`)
    }
  }

  return (
    <FormControl className={classes.filter} onClick={clickFunc}>
      <InputLabel className={classes.filterLabel} id={label + '-label'}>
        {translate(label)}
      </InputLabel>
      <Select
        className={classes.filterSelect}
        id={label + '-select'}
        labelId={label + '-label'}
        open={open}
        onChange={handleChange}
        value={filterValue}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          getContentAnchorEl: null,
        }}
      >
        <MenuItem className={classes.filterItem} value="all">
          All
        </MenuItem>
        {optionsToUse.map((option, i) => {
          return (
            <MenuItem key={i} className={classes.filterItem} value={option.value}>
              {option.title}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

const ComponentFilter = ({ filtersSchema, filterSettings, setFilters, sharedWith, projectOwnerId, trackSource }) => {
  const dispatch = useDispatch()
  const exhibitors = useSelector((state) => exhibitorsSelectors.getExhibitors(state))
  useLoadExhibitors()

  // Load dynamic filter options from feature configs in advance
  useEffect(() => {
    dispatch(getPublicFeatureConfig('manufacturer_filter'))
  }, [])

  return (
    <>
      {filtersSchema.map((schema, i) => {
        if (
          schema.isHidden &&
          schema.isHidden({
            filterSettings,
            setFilters,
            sharedWith,
            projectOwnerId,
          })
        )
          return null
        if (
          !schema?.options.length &&
          schema?.useExhibitorsAsOptionsWithComponentType === undefined &&
          schema?.useFeatureConfigAsOptions === undefined
        )
          return null
        else
          return (
            <FilterSelect
              trackSource={trackSource}
              key={schema.key}
              schema={schema}
              defaultValue={(filterSettings && filterSettings[schema?.key]) || 'all'}
              setFilters={setFilters}
              exhibitors={exhibitors}
              sharedWith={sharedWith}
              projectOwnerId={projectOwnerId}
            />
          )
      })}
    </>
  )
}

export default ComponentFilter

import React from 'react'
import type { ComponentTypes } from 'types/selectComponent'
import type { FilterComponentNode, FilterComponentRendererType, FilterGenericPropsType } from '../type'
import OtherComponentTypeFilter, { FILTER_OPTIONS, FilterOptionsType } from './OtherComponentTypeFilter'

export const OTHER_COMPONENT_FILTER_KEY = 'other_component_type'

const getDefaultValue = (args?: { specsData: { [key: string]: string | number }; componentType?: ComponentTypes }) => {
  if (!args) {
    return
  }
  if (args.specsData.other_component_type == null) {
    return
  }
  const selectedValue = FILTER_OPTIONS.find((option) => option.value === args.specsData.other_component_type)?.value
  if (!!selectedValue) {
    return { [OTHER_COMPONENT_FILTER_KEY]: selectedValue }
  }
}

const maybeCreateOtherComponentTypeFilterNode = ({
  RendererComponent,
  componentType,
}: {
  RendererComponent: FilterComponentRendererType<FilterOptionsType>
  componentType?: ComponentTypes
}): FilterComponentNode | undefined => {
  if (componentType !== 'other') {
    return undefined
  }

  const FilterComponent = (props: FilterGenericPropsType) => {
    return (
      <OtherComponentTypeFilter
        filterKey={OTHER_COMPONENT_FILTER_KEY}
        RendererComponent={RendererComponent}
        {...props}
      />
    )
  }
  return {
    key: OTHER_COMPONENT_FILTER_KEY,
    component: FilterComponent,
    getDefaultValue,
  }
}

export default maybeCreateOtherComponentTypeFilterNode

// @ts-nocheck
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Grow,
  Radio,
  RadioGroup, TextField,
  useMediaQuery
} from '@material-ui/core'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import AlertError from '@material-ui/icons/ErrorOutlineOutlined'
import SendIcon from '@material-ui/icons/SendOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { sendInvitations as sendInvitationsAction } from 'ducks/invitation'
import { orgSelectors } from 'ducks/orgs'
import { paymentOptionSelectionSelectors } from 'ducks/paymentOptionSelection'
import Alert from 'elements/Alert'
import { Button, Switch } from "opensolar-ui"
import { AU_INTEGRATIONS } from 'projectSections/sections/info/ctaAccordion/auFinance/AuFinanceCtaDialog'
import { useGetSoldRecords } from 'projectSections/sections/payments/cashFlowTransactions/utils'
import { useIsProjectUsingCashFlow } from 'projectSections/sections/payments/hooks'
import React, { useEffect, useState } from 'react'
import { FieldTitle, useTranslate } from 'react-admin'
import { connect, useSelector } from 'react-redux'
import { PaymentOptionSunlightQuickCreateType } from 'resources/integrations/sunlight/types'
import { ProjectRecordType } from 'resources/paymentOptions/swapPaymentOptions/types'
import { ContactType, NORMAL_CONTACT } from 'types/contacts'
import { PaymentOptionType } from 'types/paymentOptions'
import { RootState } from 'types/state'
import { SystemDataType } from 'types/systems'
import { ContactName, getIntegrationsStringFromActions } from 'util/misc'
import { useFeatureFlag } from 'util/split'
import { EditorField } from '../wysiwyg/EditorComponentWrapper'

const US_INTEGRATIONS = ['sunlight', 'dividend', 'sungage', 'mosaic']

function filterRealContacts(contacts) {
  const ret = []
  for (const contact of contacts) {
    if (contact.type === NORMAL_CONTACT) {
      ret.push(contact)
    }
  }
  return ret
}

type PropTypes = {
  handleClose: () => void
  projectId: number
  sendInvitations: (projectId: number, options?: Object) => void
  contacts: ContactType[]
  defaultMessage: string | undefined
  defaultSubject: string | undefined
  project: ProjectRecordType
  applicationSystemUuid?: number
  applicationPaymentOptionId?: number
  onSuccess?: () => void
}

const _InvitationDialog: React.FC<PropTypes> = (props) => {
  const isUsAppLinkEnabled: boolean = useFeatureFlag('send_us_direct_app_link', 'on')

  const getIntegratedPaymentOptions = () => {
    if (availableSystems && availableSystems.length > 0) {
      let allOptions: PaymentOptionSunlightQuickCreateType[] = []
      availableSystems.forEach((design) => {
        if (design.paymentOptionsData && design.paymentOptionsData.length > 0) {
          design.paymentOptionsData
            .filter((opt) => {
              if (AU_INTEGRATIONS.includes(opt.integration) && opt.loan_type !== 'buy_now_pay_later') return true
              else if (US_INTEGRATIONS.includes(opt.integration) && isUsAppLinkEnabled) return true
              else return false
            })
            .forEach((opt) => allOptions.push(opt))
        }
      })
      return allOptions
    } else return []
  }

  const { handleClose, projectId, sendInvitations, contacts, defaultMessage, defaultSubject, project } = props
  const realContacts = filterRealContacts(contacts)
  const translate = useTranslate()
  const [isCustomised, setCustomisation] = useState(false)
  const [message, setMessage] = useState('')
  const [subject, setSubject] = useState('')
  const [warning, setWarning] = useState(null)
  const [hasPlentiBNPL, setHasPlentiBNPL] = useState(false)
  const [availableSystems, setAvailableSystems] = useState<SystemDataType[]>([])
  const [availablePaymentOptions, setAvailablePaymentOptions] = useState<PaymentOptionType[]>([])
  const [selectedSystemUuid, setSelectedSystemUuid] = useState<number | undefined>(props.applicationSystemUuid)
  const [selectedPaymentOptionId, setSelectedPaymentOptionId] = useState<number | undefined>(
    props.applicationPaymentOptionId
  )
  const [useApplicationParams, setUseApplicationParams] = useState<boolean>(!!props.applicationSystemUuid)

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down(722))
  const org = useSelector(orgSelectors.getOrg)

  const [soldSystem, soldPaymentOption] = useGetSoldRecords(project)
  const refreshSystemsTrigger = useSelector(paymentOptionSelectionSelectors.getSystemRefreshTrigger)
  const isProjectUsingCashflow = useIsProjectUsingCashFlow(soldSystem, soldPaymentOption, refreshSystemsTrigger)

  useEffect(() => {
    const systemsFromStudio = window.editor.getSystems()?.map((sys) => {
      sys.paymentOptionsData = sys.payment_options
      sys.title = sys.name && sys.name.length > 0 ? sys.name : `${sys.moduleQuantity()} ${translate('Panels System')}`
      return sys
    })
    if (systemsFromStudio && systemsFromStudio.length > 0) setAvailableSystems(systemsFromStudio)
    if (systemsFromStudio?.length === 1) setSelectedSystemUuid(systemsFromStudio[0]?.uuid)
  }, [])

  useEffect(() => {
    if (availableSystems?.length > 0) setAvailablePaymentOptions(getIntegratedPaymentOptions())
  }, [availableSystems])

  useEffect(() => {
    if (selectedSystemUuid && availableSystems?.length > 0) {
      //dedupe titles present on multiple systems
      const availablePmtOptions = availableSystems
        .find((sys) => sys.uuid === selectedSystemUuid)
        .paymentOptionsData.filter((pmtOption) => {
          if (AU_INTEGRATIONS.includes(pmtOption.integration) && pmtOption.loan_type !== 'buy_now_pay_later')
            return true
          else if (US_INTEGRATIONS.includes(pmtOption.integration) && isUsAppLinkEnabled) return true
          else return false
        })
      setAvailablePaymentOptions(availablePmtOptions)
      if (!availablePmtOptions || availablePmtOptions.length === 0) setSelectedPaymentOptionId(undefined)
    } else if (availablePaymentOptions && availablePaymentOptions.length > 0) setAvailablePaymentOptions([])
  }, [selectedSystemUuid, availableSystems])

  useEffect(() => {
    if (
      org &&
      org.docusign_config &&
      org.docusign_config.docusign_contract_sms_auth_enabled &&
      !props.applicationSystemUuid
    ) {
      if (!realContacts || realContacts.length < 1 || !realContacts[0].phone || realContacts[0].phone.length < 1) {
        setWarning(
          translate(
            'Your org has SMS authorization enabled for Docusign contracts but this customer does not have a phone number saved. The customer will not be able to sign their contract in this state'
          )
        )
      }
    }
  }, [org])

  useEffect(() => {
    if (project?.available_customer_actions) {
      let foundBNPL = false

      Object.values(project?.available_customer_actions)?.forEach((parent) => {
        parent?.actions_available.forEach((action) => {
          if (action.payment_method === 'plenti_bnpl_application') foundBNPL = true
        })
      })
      if (foundBNPL && !hasPlentiBNPL) setHasPlentiBNPL(true)
      else if (!foundBNPL && hasPlentiBNPL) setHasPlentiBNPL(false)
    }
  }, [project?.available_customer_actions?.actions_available])

  useEffect(() => {
    setMessage(defaultMessage)
    setSubject(defaultSubject)
  }, [])
  const handleRequestClose = () => {
    handleClose()
    setCustomisation(false)
  }

  const handleInvite = () => {
    if (isCustomised) {
      sendInvitations(projectId, {
        isCustom: true,
        subject,
        content: message,
        applicationSystemUuid: selectedSystemUuid,
        applicationPaymentOptionId: selectedPaymentOptionId,
      })
    } else {
      sendInvitations(projectId, {
        applicationSystemUuid: selectedSystemUuid,
        applicationPaymentOptionId: selectedPaymentOptionId,
      })
    }
    logAmplitudeEvent('proposal_emailed', {
      project_id: `${projectId}`,
      integrated_payment_options: getIntegrationsStringFromActions(project?.available_customer_actions),
      page: 'Project',
      used_credit_app: useApplicationParams,
      has_cashflow: isProjectUsingCashflow,
    })
    if (props.onSuccess) props.onSuccess()
  }

  const handleSystemChange = (e: any) => {
    if (availableSystems && availableSystems.length > 0) {
      let uuidMatches = availableSystems.filter((system) => system.uuid === e.target.value)
      if (uuidMatches && uuidMatches.length > 0) setSelectedSystemUuid(uuidMatches[0].uuid)
      setSelectedPaymentOptionId(undefined)
    }
  }

  const handlePmtOptionChange = (e: any) => {
    setSelectedPaymentOptionId(e.target.value)
  }

  return (
    <Dialog open={true} style={{ maxHeight: 'calc(100vh - 56px)' }}>
      <DialogTitle>
        {translate('Send Email Invite to %{contacts}?', {
          contacts: realContacts && realContacts.map((contact) => ContactName(contact)).join(', '),
        })}
      </DialogTitle>
      <DialogContent>
        {hasPlentiBNPL && (
          <div
            style={{
              borderRadius: '5px',
              backgroundColor: '#fcf4c5',
              opacity: 0.7,
              marginBottom: '15px',
            }}
          >
            <Alert severity="info">
              {translate(
                "This project has a Plenti Buy Now Pay Later product. This product currently does not support direct customer application from the interactive proposal. You can however submit the application through the Online Proposal page when logged in as your org's team member."
              )}
            </Alert>
          </div>
        )}
        <TextField
          label={translate('Email Subject')}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={!isCustomised}
          fullWidth={true}
          value={subject}
          name="subject"
          onChange={(event) => {
            const value = event.target.value
            setSubject(value)
          }}
        />
        {isCustomised ? (
          <EditorField
            label={translate('Project Specific Email Custom Content')}
            value={message}
            fieldName={'content'}
            handleChange={(value) => setMessage(value)}
            allowUploadImage={false}
            mentionContext={'org_and_project'}
          />
        ) : (
          <div>
            <div
              style={{
                marginTop: 12,
                fontSize: '12px',
                lineHeight: '22px',
                color: 'rgb(204, 204, 204)',
              }}
            >
              <FieldTitle translate={translate} label={'Project Specific Email Custom Content'} />
            </div>
            <div
              style={{
                border: '1px solid rgb(204 204 204)',
                borderRadius: 10,
                padding: 20,
                minHeight: 100,
                maxHeight: 300,
                overflow: 'scroll',
                cursor: 'not-allowed',
                opacity: 0.38,
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: message,
                }}
              />
            </div>
          </div>
        )}
        {availableSystems?.length > 0 && availablePaymentOptions?.length > 0 && (
          <div style={{ marginTop: '20px' }}>
            <FormControlLabel
              control={
                <Switch
                  checked={useApplicationParams}
                  onChange={(e: MouseEvent) => setUseApplicationParams(!useApplicationParams)}
                />
              }
              label={translate('Send a link directly to a finance application')}
            />
            {useApplicationParams && (
              <Grow in={true}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className="small">
                      {translate(
                        'Enabling this feature will result in the proposal that your customer is sent automatically opening the application form for the system and payment option you select below'
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <h2>{translate('Select a System')}</h2>
                    <RadioGroup
                      style={{ alignItems: 'flex-start' }}
                      name="pmt-options"
                      value={selectedSystemUuid}
                      onChange={handleSystemChange}
                    >
                      {availableSystems &&
                        availableSystems.map((system) => {
                          return (
                            <FormControlLabel
                              key={system.uuid}
                              value={system.uuid}
                              control={<Radio />}
                              label={<span style={{ fontSize: 13 }}>{system.title}</span>}
                            />
                          )
                        })}
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <h2>{translate('Select a Payment Option')}</h2>
                    <RadioGroup value={`${selectedPaymentOptionId}`} onChange={handlePmtOptionChange}>
                      {availablePaymentOptions.map((pmtOption) => {
                        if (!selectedSystemUuid) return null
                        return (
                          <FormControlLabel
                            key={pmtOption.id}
                            value={`${pmtOption.id}`}
                            control={
                              <Radio
                                disabled={!selectedSystemUuid}
                                checked={`${pmtOption.id}` === `${selectedPaymentOptionId}`}
                              />
                            }
                            label={<span style={{ fontSize: 13 }}>{pmtOption.title}</span>}
                          />
                        )
                      })}
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Grow>
            )}
          </div>
        )}
      </DialogContent>
      {warning && <Alert severity="warning">{warning}</Alert>}
      <DialogActions style={{ flexWrap: 'wrap', padding: '8px 24px' }}>
        <Button
          startIcon={<AlertError />}
          style={{ margin: isSmall ? '0 0 10px 0' : '0 0 2px 0' }}
          variant="contained"
          color="default"
          // style={{ padding: '6px 8px' }}
          onClick={handleRequestClose}
        >
          <span>{translate('Cancel')}</span>
        </Button>
        {!isCustomised && (
          <Button
            startIcon={<BorderColorIcon />}
            style={{ margin: isSmall ? '0 0 10px 10px' : '0 0 2px 10px' }}
            variant="contained"
            color="default"
            // style={{ padding: '6px 8px' }}
            onClick={() => setCustomisation(true)}
          >
            <span>{translate('Customise')}</span>
          </Button>
        )}
        <Button
          id="Confirm_and_Send_Proposal"
          startIcon={<SendIcon style={{ color: '#FFFFFF' }} />}
          style={{ marginLeft: 10, marginBottom: 2 }}
          onClick={() => {
            handleInvite()
            handleRequestClose()
          }}
          variant="contained"
          color="secondary"
          disabled={useApplicationParams && (!selectedSystemUuid || !selectedPaymentOptionId)}
        >
          <span>
            {useApplicationParams ? translate('Confirm and Send Link') : translate('Confirm and Send Proposal')}
          </span>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state: RootState) => ({
  defaultMessage: orgSelectors.getOrg(state)?.invitation_email_custom_message,
  defaultSubject: orgSelectors.getOrg(state)?.invitation_email_custom_subject,
})

export default connect(mapStateToProps, {
  sendInvitations: sendInvitationsAction,
})(_InvitationDialog)

import TourTooltip from 'elements/tooltip/TourTooltip'
import { TourConfigType } from 'persistentContent/tours/types'
import { useTranslate } from 'ra-core'
import { FC } from 'react'

const StepTooltip: FC = (props) => {
  const translate = useTranslate()
  return (
    <TourTooltip
      title={translate('Centralized Project Details')}
      subtext={translate(
        'The hub for all our project specifics. Easily add, review, or modify the fundamental project information here.'
      )}
      {...props}
    />
  )
}

const ProjectDetails: TourConfigType = {
  spotlights: [{ targetId: 'Project-Details-Section', autoScroll: true }],
  tooltips: [
    {
      targetId: 'Project-Details-Section',
      placement: 'bottom-start',
      component: StepTooltip,
      options: { style: { marginTop: 10 } },
    },
  ],
}

export default ProjectDetails
